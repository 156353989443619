import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Pharmacovigilance } from '@omni/interfaces/pharmacovigilance-reporting/pharmacovigilance.interface';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { IndKeyControlsAreaModel } from '@omni/models/indKeyControlsAreaModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { DeviceService } from '@omni/services/device/device.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { PageViewMode, PharmacovigilanceService } from '@omni/services/pharmacovigilance-reporting/pharmacovigilance.service';
import { UIService } from '@omni/services/ui/ui.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PharmacovigilanceDetail } from '../detail/pharmacovigilance-detail';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

@Component({
  selector: 'pharmacovigilance-list',
  templateUrl: 'pharmacovigilance-list.html',
  styleUrls: ['pharmacovigilance-list.scss'],
})
export class PharmacovigilanceList implements OnInit, OnDestroy {
  indHeaderLeftModel: IndHeaderLeftDataModel;
  keyControlAreaModel: IndKeyControlsAreaModel;
  searchText: string = '';
  viewState: 'List' | 'NoData' | 'EmptySearchResult' = 'NoData';
  sectionHeaderModel: IndSectionHeaderViewDataModel;
  sortBy: { text: string, value: string, asc: boolean };
  mainList: Pharmacovigilance[] = [];
  displayList: Pharmacovigilance[] = [];

  private selectedRecord: Pharmacovigilance;
  private ngDestroy$ = new Subject<boolean>();
  private isOffline: boolean = false;
  private isDataLoaded: boolean = false;
  private viewMode: PageViewMode = 'ReadOnly';

  constructor(
    private translate: TranslateService,
    private device: DeviceService,
    private uiService: UIService,
    private navService: NavigationService,
    private agendaFooterService: AgendaFooterService,
    public pharmacovigilanceService: PharmacovigilanceService,
    private faService: FeatureActionsService,
  ) {}

  private initHeader() {
    this.indHeaderLeftModel = {
      id: 'pharmacovigilance-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('PHARMACOVIGILANCE_REPORTING'),
      mode: true,
      controls: [{
        id: 'close',
        imgSrc: 'assets/imgs/back_to_home_btn.svg',
        isDisabled: false,
        align: 'left',
      }],
    };
  }
  private initKeyControl() {
    this.keyControlAreaModel = {
      id: 'pharmacovigilance-list-key-controls-area',
      controls: [{
        id: 'create-btn',
        text: this.translate.instant('CREATE'),
        isDisabled: this.isCreateButtonDisabled(),
        leftAligned: false,
      }],
      eventHandler: this.onCreateBtnClick,
    }
  }
  private initSectionHeader() {
    this.sectionHeaderModel = {
      id: 'my-pharmacovigilance-records',
      title: `${this.translate.instant('MY_REPORTINGS')} (${this.displayList.length})`,
      controls: [],
    };
  }
  private updateViewState() {
    if (this.displayList.length > 0) {
      this.viewState = 'List';
    } else {
      this.viewState = this.searchText ? 'EmptySearchResult' : 'NoData';
    }
  }


  ngOnInit(): void {
    this.device.isOfflineObservable.pipe(
      takeUntil(this.ngDestroy$),
    ).subscribe(isOffline => {
      this.isOffline = isOffline;

      this.updateCreateButton();
    });
    this.pharmacovigilanceService.recordDataReadState.stateObservable.pipe(
      takeUntil(this.ngDestroy$),
    ).subscribe(state => {
      this.isDataLoaded = state === 'Success';
      if (this.isDataLoaded) {
        this.updateViewState();
        this.initSectionHeader();
      }
    });
    this.pharmacovigilanceService.records$.pipe(
      takeUntil(this.ngDestroy$),
    ).subscribe(records => {
      this.mainList = this.displayList = records;

      if (this.isDataLoaded) {
        this.updateViewState();
        this.initSectionHeader();
      }
    });
    this.pharmacovigilanceService.detailPageViewMode$.pipe(
      takeUntil(this.ngDestroy$),
    ).subscribe(viewMode => {
      this.viewMode = viewMode;

      this.updateCreateButton();
    });
    this.pharmacovigilanceService.selectedRecord$.pipe(
      takeUntil(this.ngDestroy$),
    ).subscribe(record => {
      this.selectedRecord = record;
    });

    this.initHeader();
    this.initKeyControl();
    this.initSectionHeader();
  }
  ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.pharmacovigilanceService.setDetailPageViewMode('ReadOnly');
    this.pharmacovigilanceService.unloadRecords();
    this.pharmacovigilanceService.unloadInfoButtonsData();
  }


  private closePage() {
    this.uiService.activeView = this.uiService.prevView;
    this.navService.popWithPageTracking().then(() => {
      // Short call feature requires to bring the tab back to short call home
      if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
        this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
        this.uiService.setAgendaTab('shortCallHome');
      }
    });
  }

  onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closePage();
        break;

      default:
        break;
    }
  }
  onSearch() {
    if (this.searchText?.trim()?.length > 1) {
      this.displayList = this.mainList.filter(r =>
        r.indskr_name.toLowerCase().includes(
          this.searchText.toLowerCase()
      ));
    } else {
      this.displayList = this.mainList;
    }
    this.updateViewState();
    this.initSectionHeader();
  }
  onSearchCancel(ev) {
    this.searchText = '';
    this.displayList = this.mainList;
    this.updateViewState();
    this.initSectionHeader();
  }


  private async openDetailPageIfNotAlready() {
    if (!this.navService.isActiveChildNavRightPaneViewDisplayed) {
      await this.navService.setChildNavRoot(
        PharmacovigilanceDetail,
        PageName.PharmacovigilanceDetail,
        PageName.PharmacovigilancePage,
        {}
      );
      this.navService.setChildNavRightPaneView(true);
    }
  }
  private onCreateBtnClick = () => {
    this.pharmacovigilanceService.setDetailPageViewMode('Create');

    this.openDetailPageIfNotAlready();
  }
  private onRecordClick = (id: string, event, eventName, targetViewData) => {
    if (id && eventName === 'mainItem') {
      const record = this.displayList.find(a => a.indskr_pharmacovigilancereportingid === id);
      if (record) {
        this.pharmacovigilanceService.setSelectedRecord(record);
        this.pharmacovigilanceService.setDetailPageViewMode('ReadOnly');

        this.openDetailPageIfNotAlready();
      }
    }
  }
  getMainCardModel(record: Pharmacovigilance): MainCardViewDataModel {
    return {
      id: record.indskr_pharmacovigilancereportingid,
      fixedHeight: true,
      primaryTextRight: record.indskr_name,
      isSelected: record.indskr_pharmacovigilancereportingid === this.selectedRecord?.indskr_pharmacovigilancereportingid,
      clickHandler: this.onRecordClick,
    };
  }


  private isCreateButtonDisabled(): boolean {
    return this.isOffline || this.viewMode === 'Create';
  }
  private updateCreateButton() {
    const control = this.keyControlAreaModel?.controls?.find(c => c.id === 'create-btn');
    if (control) {
      control.isDisabled = this.isCreateButtonDisabled();
    }
  }
}
