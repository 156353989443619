
//num_buckets: NUM_BUCKETS_PER_POSITION * this.authService.get_num_positions(), bucket_key: DB_KEY_PREFIXES.CONTACT, hash_key

self.onmessage = function(event) {
  console.log('--data-partition-worker '+ event)
  if(event.data && event.data.type && event.data.type =='partitionDatatoBuckets'){
    self.partitionData(event.data.rawList, event.data.num_buckets, event.data.bucket_key, event.data.hash_key)
  }
};

self.addEventListener('install', (event) => {
  console.log('Service Worker installing.');
});

self.addEventListener('activate', (event) => {
  console.log('Service Worker activated.');
});

function partitionData(data, num_buckets, bucket_key,hash_key) {

  bucketedData = {}

  const chunkSize = 500;

  // Sort data by hash key first for faster bucketing
  data.sort((a, b) => a[hash_key] - b[hash_key]);

  for (let i = 0; i < data.length; i += chunkSize) {
    const chunk = data.slice(i, i + chunkSize);
  
    chunk.forEach(con => {
      const bucketId = getBucketId(con[hash_key], num_buckets);
    
      const key = `${bucket_key}_${bucketId}`;
  
      // Initialize the bucket if it doesn't exist
      if (!bucketedData[key]) {
        bucketedData[key] = [];
      }
  
      // Push the contact into the appropriate bucket
      bucketedData[key].push(con);
      });
  }
  
  self.postMessage(bucketedData);

}

 function simpleHash(str,num_buckets) {
  const chunkSize = 1024;
  let hash = 0;
  let chunk;

  for (let i = 0; i < str.length; i += chunkSize) {
    chunk = str.slice(i, i + chunkSize);
    for (let j = 0; j < chunk.length; j++) {
      const char = chunk.charCodeAt(j);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }
  }

  return Math.abs(hash);
}

function getBucketId(contactId,num_buckets) {
    const bucketNumber = simpleHash(contactId) % num_buckets; 
   return `bucket_${bucketNumber}`;
}


// module.exports={getBucketId}