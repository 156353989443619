<ion-item *ngIf="viewData && !viewData.isForcedHide" class="accordion-header" [ngClass]="{'expanded':viewData.isExpanded, 'collapsed':!viewData.isExpanded, 'indentSubCategory':viewData.isSubCategory}">
  <div slot="start" class="no-margin">
    <ion-checkbox *ngIf="viewData.scrapFromList" [checked]="viewData.isCheckedForScrap" (click)="handleCheckBoxClick($event, viewData, 'scrapFromList')"></ion-checkbox>
  </div>
  <ion-icon slot="start" *ngIf="viewData.showFavouriteIcon" class="favourite button-foreground" [ngClass]="{'offline': device.isOffline && !viewData.isFavourite}" [name]="viewData.isFavourite ? 'star':'star-outline'" (click)="favourite(viewData)"></ion-icon>
  <ion-label (click)="handlePrimaryLabelClick($event, 'mainLabel')">
    <!-- <div [innerHTML]="linkableText(viewData.primaryText)"></div> -->
      <div [innerHTML]="viewData.primaryText | transformLinkableText"></div>
      <div *ngIf="viewData.hasCustomSecondary" class='main-card-secondary-right'>
      <p *ngIf="viewData.customSecondaryTextRight" class="products-text">
        {{viewData.customSecondaryTextRight}}
      </p>
      <p class="consents-text">
        {{viewData.customSecondaryTextRightTwo}}
      </p>
    </div>
  </ion-label>
  <ion-icon *ngIf='viewData.showRightIcon' (click)="handleRightIconCLick($event, 'rightIcon')" [src]="viewData.rightIconSrc" [tooltip]="viewData.rightIconTooltip"[display]="viewData.rightIconTooltip ? true : false" slot="end" class='right-icon' [ngClass]="{'rightIconTooltip': viewData.rightIconTooltip}"></ion-icon>
  <ion-icon *ngIf='viewData.showExpandIcon && !viewData.isExpanded && !viewData.disableEditArrow' (click)="expandChildItems($event,true)" src='assets/imgs/accordion-add.svg' slot="end" class='expand-icon'></ion-icon>

  <ion-icon *ngIf='viewData.showCollapseIcon && viewData.isExpanded && !viewData.disableEditArrow' (click)="expandChildItems($event,false)" src='assets/imgs/accordion-minus.svg' slot="end" class='collapse-icon'></ion-icon>

</ion-item>
<ion-list *ngIf="viewData && viewData.isExpanded && viewData.childItems && viewData.childItems.length != 0 && !viewData.isSelectable && !viewData.isForcedHide" class="child-items-list" [ngClass]="{'indentSubCategory':viewData.isSubCategory}">
  <ion-item *ngFor="let item of viewData.childItems">

      <ion-label class='child-item-label'>
        <p class="child-label-text">
          <ion-text>{{item.label}}</ion-text>
        </p>
        <!-- <div class='child-item-value' [innerHTML]="linkableText(item.value)" (click)="openUrl($event,item.value)"></div> -->
          <div class='child-item-value' [innerHTML]="item.value | transformLinkableText" (click)="openUrl($event,item.value)"></div>
      </ion-label>

  </ion-item>
</ion-list>
<ion-list *ngIf="viewData && viewData.isExpanded && viewData.isSelectable && ((viewData.childItems && viewData.childItems.length != 0) || searchText) && !viewData.isForcedHide" class="child-items-list" [ngClass]="{'indentSubCategory':viewData.isSubCategory}">
      <ion-item-group *ngIf="viewData.childItems.length > viewData.searchActiveAt">
        <ion-searchbar placeholder="{{'SEARCH' | translate}} {{viewData.primaryText.split('(')[0]}}"
                       [(ngModel)]="searchText" 
                       (ionInput)="_onSearchInput(viewData, $event)">
        </ion-searchbar>
      </ion-item-group>
      <div *ngIf="!searching">
        <ion-item-group *ngFor="let item of viewData.childItems">
          <ng-container *ngIf="item.category !== 'Teamusers'">
            <ion-item (click)="handleCheckboxClick(item,$event)">
              <ion-label class="selectable-item"> {{ item.label }}</ion-label>
              <ion-checkbox *ngIf='!item.hideCheckbox'
                slot="start"
                [(ngModel)]="item.isChecked"
                [checked]="item.isChecked"
                (ionChange)="toggleItem(item, $event)"
              ></ion-checkbox>
              <p *ngIf="item.isDefaultFilter" class="section-header-button-text ">Default</p>
              <ion-icon *ngIf='this.viewData.id.includes("saved_filter")' name="options" class="insights arrow-icon icon-size" src="assets/imgs/personalize-quick-glance-options.svg" (click)="dropDown($event ,item)"></ion-icon>              
              <ion-icon *ngIf='item.showArrow' class="insights arrow-icon icon-size {{item.arrowType}}"
                (click)="handleRightIconCLick($event, 'endArrow', item)" [name]='item.arrowType' slot="end"
                [src]="(item.isSrcAttribute)?item.iconName:''"></ion-icon>
            </ion-item>
            <div class="separationLine"></div>
          </ng-container>
          <ng-container *ngIf="item.category === 'Teamusers' && !device.isOffline">
            <omni-accordion-item [item]="item" [viewData]="viewData" [depth]="depth + 1" (onToggleChildItem)="emitToggleItem(item, $event)" (onToggleChildItemClick)="emitToggleItemClick(item,$event)">
            </omni-accordion-item>
          </ng-container>
        </ion-item-group>
        <ion-item-group *ngIf="viewData.showMoreButtonEnabled">
          <ion-item (click)="handleLableCLick($event,'loadmoreoptions')">
            <ion-label class="selectable-item" > {{ 'SHOW_MORE' | translate }}</ion-label>
            <ion-icon class="insights arrow-icon chevron-forward" name='chevron-forward' slot="end"></ion-icon>
          </ion-item>
          <div class="separationLine"></div>
        </ion-item-group>
      </div>
      <div *ngIf="searching">
        <ion-item-group *ngFor="let item of tempViewData.childItems">
          <ng-container *ngIf="item.category !== 'Teamusers'">
            <ion-item (click)="handleCheckboxClick(item,$event)">
              <ion-label class="selectable-item"> {{ item.label }}</ion-label>
              <ion-checkbox *ngIf='!item.hideCheckbox'
                slot="start"
                [(ngModel)]="item.isChecked"
                [checked]="item.isChecked"
                (ionChange)="toggleItem(item, $event)"
              ></ion-checkbox>
              <p *ngIf="item.isDefaultFilter" class="section-header-button-text">Default</p>
              <ion-icon *ngIf='this.tempViewData.id.includes("saved_filter")' name="options" class="insights arrow-icon icon-size" src="assets/imgs/personalize-quick-glance-options.svg" (click)="dropDown($event ,item)"></ion-icon>            
              <ion-icon *ngIf='item.showArrow' class="insights arrow-icon icon-size {{item.arrowType}}"
              (click)="handleRightIconCLick($event, 'endArrow', item)" [name]='item.arrowType' slot="end"
              [src]="(item.isSrcAttribute)?item.iconName:''"></ion-icon>
            </ion-item>
            <div class="separationLine"></div>
          </ng-container>
          <ng-container *ngIf="item.category === 'Teamusers' && !device.isOffline">
            <omni-accordion-item [item]="item" [viewData]="viewData" [depth]="depth + 1" (onToggleChildItem)="emitToggleItem(item, $event)" (onToggleChildItemClick)="emitToggleItemClick(item,$event)">
            </omni-accordion-item>
          </ng-container>
        </ion-item-group>
        <ion-item-group *ngIf="viewData.showMoreButtonEnabled">
          <ion-item (click)="handleLableCLick($event,'loadmoreoptions')">
            <ion-label class="selectable-item" > {{ 'SHOW_MORE' | translate }}</ion-label>
            <ion-icon class="insights arrow-icon chevron-forward" name='chevron-forward' slot="end"></ion-icon>
          </ion-item>
          <div class="separationLine"></div>
              </ion-item-group>
    </div>
    <ind-no-search-result *ngIf="searchText && viewData.childItems.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
</ion-list>
