import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AccountPlanMode } from '@omni/classes/account-management/account-plan.class';
import { ActivityType } from '@omni/classes/activity/activity.class';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { OrderActivity } from '@omni/classes/activity/order.activity.class';
import { PhoneActivity } from '@omni/classes/activity/phone.activity.class';
import { ProcedureTrackerActivity } from '@omni/classes/activity/procedure-tracker.activity.class';
import { StoreCheckActivity } from '@omni/classes/activity/store-check.activity.class';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { CaseActivity } from '@omni/classes/case-intake/case-activity.class';
import { AssessmentTemplate, SurveyCategory, SurveyFrequency, SurveyStatus, TemplateType } from '@omni/classes/customer-assessment/assessment-template.class';
import { CustomerAssessment } from '@omni/classes/customer-assessment/customer-assessment.class';
import { EventActivity } from '@omni/classes/events-tool/event.class';
import { Quotes } from '@omni/classes/quotes/quotes.class';
import { AccountPlanDetails } from '@omni/components/account-management/account-plan-details/account-plan-details';
import { ActivitiesDetailsPaneComponent } from '@omni/components/activity/activities-details-pane/activities-details-pane';
import { StoreCheckDetailsComponent } from '@omni/components/activity/store-check-activity/store-check-details';
import { CaseManagementDetailComponent } from '@omni/components/case-management/case-management-detail/case-management-detail';
import { EventDetailsComponent } from '@omni/components/events-tool/event-details/event-details';
import { MultiSelectPopover } from '@omni/components/multi-select-popover/multi-select-popover';
import { OrderDetailsComponent } from '@omni/components/order-management/order-details/order-details';
import { PhoneCallDetailsComponent } from '@omni/components/phonecall/phone-call-details/phone-call-details';
import { QuoteDetailsComponent } from '@omni/components/quote/quote-details/quote-details.component';
import { AffiliationExplorerComponent } from '@omni/components/shared/affiliation-explorer/affiliation-explorer';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { IndFilterMenuModalComponent, IndFilterMenuModalDataModel } from '@omni/components/shared/ind-filter-menu-modal/ind-filter-menu-modal';
import { ProcedureTrackerDetailsComponent } from '@omni/components/surgery-order/procedure-tracker-details/procedure-tracker-details';
import { SurgeryOrderDetailsComponent } from '@omni/components/surgery-order/surgery-order-details/surgery-order-details';
import { SurveyDetailsComponent } from '@omni/components/survey/survey-details/survey-details';
import { TimeLineItem } from '@omni/components/timeline-item/timeline-item.component';
import { AccountManagementDataService } from '@omni/data-services/account-management/account-management.data.service';
import { AccountDataService } from '@omni/data-services/accounts/account.data.service';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';
import { CaseManagementDataService } from '@omni/data-services/case-management/case-management.data.service';
import { EventsToolDataService } from '@omni/data-services/event/events-tool.data.service';
import { OrderActivityDataService } from '@omni/data-services/order-activity/order-activity.data.service';
import { GlanceCardViewDataModel } from '@omni/models/glanceCardViewDataModel';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { AccountManagementOfflineService } from '@omni/services/account-management/account-management.service';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { ActivityService } from '@omni/services/activity/activity.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { AccesingMode, CaseManagementService } from '@omni/services/case-management/case-management.service';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { CustomerSurveyService } from '@omni/services/customer-survey/customer-survey.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { DeviceService } from '@omni/services/device/device.service';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { EventName, EventsService } from '@omni/services/events/events.service';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { QuotesService } from '@omni/services/quote/quote-tool.service';
import { SearchConfigService } from '@omni/services/search/search-config.service';
import { Customer360UIService } from '@omni/services/ui/customer360ui.service';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import { Activity } from 'botframework-directlinejs';
import { isValid } from 'date-fns';
import _ from 'lodash';
import { Subject } from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, first, map, takeUntil, tap} from 'rxjs/operators';
import {FieldMaterialManagementService} from "@omni/services/field-materials/field-material-management.service";
import {DatePipe} from "@angular/common";
import {CustomerAsset} from "@omni/classes/field-materials/customer-asset.class";
import {AssetDetailsComponent} from "@omni/components/field-material-management/asset-details/asset-details";

@Component({
  selector: 'app-account-timeline-page',
  templateUrl: 'account-timeline-page.component.html',
  styleUrls: ['account-timeline-page.component.scss'],
})
export class AccountTimelinePageComponent implements OnInit {
  @Input() filterEnabled = true;
  @Input() searchFilterEnabled = true;
  @Input() from = 'timeline';

  private accountId: string;
  private ownerId: string;
  public timelineSectionHeader: IndSectionHeaderViewDataModel;
  public timelineItems: TimeLineItem[] = [];
  public pageTitleViewData: IndPageTitleViewDataModel;
  private selectedDate: { startDate: string, endDate: string } = { startDate: '', endDate: '' };
  filterButtonBadgeCount: number = 0;
  public _allTimelineActivities: Array<any> = [];
  private _filteredTimelineActivities: Array<any> = [];
  private _filteredTimelineViewActivities: Array<TimeLineItem> = [];
  public accountTimelineFilterFormView: IndFilterMenuModalDataModel[] = [];
  public accountTimelineFilter: string = ActivityType.AllActivity;
  public filterOptions: Array<{ value: string, isSelected: boolean, displayText: string }> = [];
  isTimelineFiltered = false;
  public selectedFilters: any = [];
  userName: any;
  currentStartIndex = 30;
  public accountGlanceViewData: GlanceCardViewDataModel;
  public searchPlaceholder: string;
  private formattedSurveysList: any = [];
  private filters: any = {};
  public salesOrderFilterOptions = [];
  public tempProductFilterData;
  private quoteListFilterOptions: ({
    handler: (selectedItem, item, itemRef) => void;
    text: string;
    value: string;
    items: { text: any; value: string }[]
  } | { handler: (selectedItem, item, itemRef) => void; text: any; value: string; items: unknown[] } | {
    handler: (selectedItem, item, itemRef) => void;
    text: any;
    value: string;
    items: any
  })[];
  public searchInput: string = '';
  private ngDestroy$ = new Subject<boolean>();
  private searchInputSubject: Subject<string> = new Subject<string>();

  constructor(
    public authenticationService: AuthenticationService,
    public accountOfflineService: AccountOfflineService,
    public accountDataService: AccountDataService,
    public device: DeviceService,
    public uiService: UIService,
    public activityService: ActivityService,
    public activityDataService: ActivityDataService,
    public navCtrl: NavController,
    public footerService: FooterService,
    private navService: NavigationService,
    public caseService: CaseManagementService,
    public caseDataService: CaseManagementDataService,
    private translate: TranslateService,
    private notifiationService: NotificationService,
    private events: EventsService,
    public eventsToolDataService: EventsToolDataService,
    public eventsToolService: EventsToolService,
    private dynamicFormService: DynamicFormsService,
    private c360Service: Customer360UIService,
    public readonly dateTimeFormatsService: DateTimeFormatsService,
    public modalCtrl: ModalController,
    private readonly utilityService: GlobalUtilityService,
    private readonly popoverCtrl: PopoverController,
    // private readonly presentationService: PresentationService,
    // private readonly resourceService: ResourceService,
    private readonly searchConfigService: SearchConfigService,
    private customerAssessService: CustomerAssessService,
    private customerSurveyService: CustomerSurveyService,
    private quotesOfflineService: QuotesService,
    private readonly accountManagementDataService: AccountManagementDataService,
    public accountmanagementOfflineService: AccountManagementOfflineService,
    private quotesService: QuotesService,
    private fieldMaterialManagementService: FieldMaterialManagementService,
    private orderService : OrderActivityDataService,
  ) { }

  ngOnInit() {
    // this.uiService.timelineRefreshRequired = true;
    this.accountId = this.accountOfflineService.selected.id;
    this.ownerId = this.authenticationService.user.systemUserID;
    this._initPageTitle();
    this._initSectionHeadersView();
    this._initGlanceCardData();
    this.initList();
    if (this.from === 'salesorder-section') {
      this.setupSalesOrderFilteroptions();
    }
    this.navService.childNavPopObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(page => {
      if (page && this.navService.getActiveChildNavViewPageName() == PageName.AccountTimelinePageComponent && (page == PageName.ActivitiesDetailsPaneComponent || page == PageName.EmailDetailsPageComponent || page == PageName.CaseManagementDetailComponent || page == PageName.SurgeryOrderDetailsComponent || page == PageName.PhoneCallDetailsComponent || page == PageName.EventDetailsPageComponent)) {
        this._initTimelineItemsViewData();
      }
    });
    this.accountmanagementOfflineService.accountPlans$.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      if (data && data.length) {
        // let accountPlans = data.find(o => o.ID === this.accountService.selected.id);
        this._getTimelineActivities();
        this._initTimelineItemsViewData();
      }
    });
    if (this.from === 'quotes-section') {
      this.setupQuoteListFilterOptions();
    }
    if (this.from === 'asset-management-section') {
      this.setupAssetManagementFilterOptions();
    }

    this.navService.childNavPopObserver.pipe(
      takeUntil(this.ngDestroy$),
      filter(page => page && this.navService.getActiveChildNavViewPageName() === PageName.AccountTimelinePageComponent),
      filter(page => [
        PageName.ActivitiesDetailsPaneComponent,
        PageName.EmailDetailsPageComponent,
        PageName.CaseManagementDetailComponent,
        PageName.SurgeryOrderDetailsComponent,
        PageName.PhoneCallDetailsComponent,
        PageName.EventDetailsPageComponent,
        PageName.AssetDetailsComponent
      ].includes(page)),
      tap(() => {
        this._getTimelineActivities();
        this._initTimelineItemsViewData()
      })
    ).subscribe();

    this.searchInputSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this.ngDestroy$)
    ).subscribe(searchText => {
      this.searchInput = searchText;
      this._initTimelineItemsViewData();
    });
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  private _initPageTitle() {
    this.pageTitleViewData = {
      id: "account-timeline-details",
      title: this.accountOfflineService?.selected?.accountName,
      controls: [{
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      }]
    };

    switch (this.from) {
      case 'salesorder-section':
        this.searchPlaceholder = this.translate.instant('SEARCH_SALES_ORDERS');
        break;
      case 'quotes-section':
        this.searchPlaceholder = this.translate.instant('SEARCH_QUOTES');
        break;
      case 'asset-management-section':
        this.searchPlaceholder = this.translate.instant('SEARCH_MY_ASSETS');
        break;
      default:
        this.searchPlaceholder = this.translate.instant('SEARCH_TIMELINE');
    }
  }

  private async _initGlanceCardData(updateButtonsOnly: boolean = false) {
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER360UI, true)) {
      let glanceCardButtons = [];
      let account = this.accountOfflineService.getAccountById(this.accountId);
      if (account) {
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) {
          glanceCardButtons.push({
            id: "phone-call",
            imgSrc: 'assets/imgs/omni_quick_glance_call.svg',
            name: this.translate.instant('NEW_ACTIVITY_PHONECALL'),
            isDisabled: false,
            align: "right"
          });
        }
        glanceCardButtons.push({
          id: "quick-meeting",
          imgSrc: 'assets/imgs/omni_quick_glance_meeting.svg',
          name: this.translate.instant('MEETING'),
          isDisabled: false,
          align: "right"
        });
      }
      if (this.accountOfflineService.isAffiliationEnabled) {
        glanceCardButtons.push({
          id: "affiliation-explorer",
          imgSrc: 'assets/imgs/glance-affiliation-explorer.svg',
          name: this.translate.instant('AFFILIATION_EXPLORER'),
          isDisabled: !((this.accountOfflineService.linkEntityAccountFrom && this.accountOfflineService.linkEntityAccountFrom.length) || (this.accountOfflineService.linkEntityAccountTo && this.accountOfflineService.linkEntityAccountTo.length)
            || (this.accountOfflineService.brandAffiliationByAccountId && this.accountOfflineService.brandAffiliationByAccountId.length) || (this.accountOfflineService.linkedAccountContact && this.accountOfflineService.linkedAccountContact.length)),
          align: "right"
        });
      }
      if (updateButtonsOnly) {
        this.accountGlanceViewData.buttons = glanceCardButtons;
        return;
      }
      const values = await this.accountOfflineService._getQuickGlanceValues();
      if (!this.device.isOffline) {
        this.accountDataService.getEntityImageStringForAccount(this.accountOfflineService.selected).then((entityImageString => {
          if (entityImageString) {
            this.accountOfflineService.selected.entityImage = entityImageString;
            if (this.accountGlanceViewData) {
              this.accountGlanceViewData.avatarURL = this.accountOfflineService.selected.entityImage
            }
          }
        }));
      }
      const address = await this.accountOfflineService.getPrimaryAddressOfAccount(this.accountOfflineService?.selected?.id);
      this.accountGlanceViewData = {
        name: this.accountOfflineService?.selected?.accountName,
        locationText: address ? address.compositeAdd : '',
        avatarURL: this.accountOfflineService.selected.entityImage,
        thirdText: (values && values.length && values[0]) ? values[0].value : '',
        fourthText: (values && values.length && values[1]) ? values[1].value : '',
        fifthText: (values && values.length && values[2]) ? values[2].value : '',
        sixthText: (values && values.length && values[3]) ? values[3].value : '',
        thirdTextLabel: (values && values.length && values[0]) ? values[0].label : '',
        fourthTextLabel: (values && values.length && values[1]) ? values[1].label : '',
        fifthTextLabel: (values && values.length && values[2]) ? values[2].label : '',
        sixthTextLabel: (values && values.length && values[3]) ? values[3].label : '',
        buttons: glanceCardButtons,
        enableExpand: true,
        isExpanded: false,
      };
    }
  }

  public async onQuickGlanceControlClick(data) {
    if (data.id) {
      switch (data.id) {
        case 'affiliation-explorer':
          this._handleAffiliationExplorer();
          break;
        case 'phone-call':
          this.events.publish(EventName.QUICKGLANCECACCOUNTREATEPHONECALL, true);
          break;
        case 'quick-meeting':
          this.events.publish(EventName.QUICKGLANCECACCOUNTREATEMEETING, true);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  private _handleAffiliationExplorer() {
    this.navService.pushChildNavPageWithPageTracking(AffiliationExplorerComponent, PageName.AffiliationExplorerComponent, PageName.AccountPageComponent,
      {
        from: 'AccountPageComponent', parent: this.accountOfflineService.selected,
        relatedFrom: this.accountOfflineService.linkEntityAccountFrom,
        relatedTo: this.accountOfflineService.linkEntityAccountTo,
        contactAccountAffiliation: this.accountOfflineService.linkedAccountContact,
        accountToBrand: this.accountOfflineService.brandAffiliationByAccountId
      });
  }

  // Number in the parentheses
  private _initSectionHeadersView() {
    let controls = [];
    let title = this.translate.instant('TIMELINE')

    if (this.filterEnabled && this.from != 'accountPlan-section') {
      controls.push({
        id: 'account-timeline-page-filter',
        text: this.getText(),
        isDisabled: this._allTimelineActivities.length == 0 || false,
        bubbleUpEvent: true,
        icon: this.filterButtonBadgeCount && this.filterButtonBadgeCount > 0 ? '' : 'assets/imgs/customer_360_activity_type_filter.svg'
      });
    }

    if (this.from === 'salesorder-section') {
      title = this.translate.instant('ALL_ORDERS_S');
    } else if (this.from === 'quotes-section') {
      title = this.translate.instant('ALL_QUOTES');
    } else if (this.from === 'asset-management-section'){
      title = this.translate.instant('ALL') + ' ' + this.translate.instant('FIELD_MATERIAL_MANAGEMENT');
    }
    else if (this.from === 'accountPlan-section') {
      title = this.translate.instant('ALL_ACCOUNT_PLANS');
    }

    this.timelineSectionHeader = {
      id: 'account-timeline-section',
      title,
      controls
    }

  }

  private getText() {
    const filterTextMap = {
      'salesorder-section': () => this.orderService.getFilterText(this.filters, this.timelineItems.length, this.tempProductFilterData),
      'quotes-section': () => this.quotesService.getFilterText(this.filters, this.timelineItems.length, this.tempProductFilterData),
      'asset-management-section': () => {}
    };

    return filterTextMap[this.from] ? filterTextMap[this.from]() : this.getSelectedText(this.accountTimelineFilter);
  }

  public async onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this._closePage();
    }
  }

  private _closePage() {
    this.navService.popChildNavPageWithPageTracking();
  }

  async ngDoCheck() {
    if (this.uiService.timelineRefreshRequired) {
      this.uiService.timelineRefreshRequired = false;
      try {
        this.uiService.displayLoader({}, true);
        await this.accountDataService.getAccountTimelineInfo(this.accountOfflineService.selected).then(() => {
          this.initList();
          this.uiService.dismissLoader();
        });
      } catch (error) {
        console.log("Error getting account activities for timeline", error);
        this.uiService.dismissLoader();
      }
    }
  }

  // Called when clicking on filter
  public onSectionHeaderControlClick(data: any) {
    if (data?.button?.id === 'account-timeline-page-filter') {
      if (this.filterButtonBadgeCount > 0) return;

      switch (this.from) {
        case 'salesorder-section':
          this._handleSalesOrderFilter(data.event);
          break;
        case 'quotes-section':
          this._handleQuotesSectionFilter(data.event);
          break;
        case 'asset-management-section':
          this._handleAssetManagementSectionFilter(data.event);
          break;
        default:
          this._handleActivityTypeFilter(data.event);
      }
    }
  }

  setupQuoteListFilterOptions() {
    this.quoteListFilterOptions =  [
      {
        text: '',
        value: 'All',
        items: [{ text: this.translate.instant('ALL_QUOTES'), value: 'All' }],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          });
          item.value = selectedItem.value;
          this.filters = {}
          this.filterQuotes();
          this._initTimelineItemsViewData();
        }
      },
      {
        text: this.translate.instant('STATUS'),
        value: '',
        items: _.uniqBy(this._allTimelineActivities.map(quote => ({ text: this.quotesService.getFormattedStatus(quote.statuscode), value: quote.statuscode })), 'text'),
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          this.filters = { ...this.filters, statusFilter: selectedItem.value };
          this.filterQuotes();
        }
      },
      {
        text: this.translate.instant('PRODUCT'),
        value: '',
        items: this.getQuotesForFilterPopover(this._allTimelineActivities),
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          this.filters = { ...this.filters, productFilter: selectedItem.value };
          this.filterQuotes();
        }
      }
    ];
  }

  setupSalesOrderFilteroptions() {
    this.salesOrderFilterOptions = [
      {
        text: '',
        value: 'All',
        items: [{ text: this.translate.instant('ALL_ORDERS_S'), value: 'All' }],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          });
          item.value = selectedItem.value;
          this.filters = { ...this.filters, statusString: '', products: '' };
          this.filterOrders();
          this._initTimelineItemsViewData();
        }
      },
      {
        text: this.translate.instant('STATUS'),
        value: '',
        items: [
          { text: this.translate.instant('OM_DRAFT'), value: 'Draft' },
          { text: this.translate.instant('FOR_REVIEW'), value: 'For Review' },
          { text: this.translate.instant('APPROVED'), value: 'Approved' },
          { text: this.translate.instant('OM_FULFILLED'), value: 'Fulfilled' },
          { text: this.translate.instant('CANCELLED'), value: 'Canceled' }
        ],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          this.filters = { ...this.filters, statusString: item.value };
          this.filterOrders();
          this._initTimelineItemsViewData();
        }
      },
      {
        text: this.translate.instant('PRODUCT'),
        value: '',
        items: this.getProductForFilterPopover(this._allTimelineActivities),
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          this.filters.products = item.value;
          this.filters = { ...this.filters, products: item.value };
          this.filterOrders();
          this._initTimelineItemsViewData();
        }
      }
    ];
  }

  private async _handleSalesOrderFilter(ev) {
    let dropdownPopover = await this.popoverCtrl.create({ component: MultiSelectPopover, componentProps: { root: this.salesOrderFilterOptions }, event });
    dropdownPopover.present();
  }

  private async _handleQuotesSectionFilter(ev) {
    let dropdownPopover = await this.popoverCtrl.create({ component: MultiSelectPopover, componentProps: { root: this.quoteListFilterOptions }, event });
    dropdownPopover.present();
  }

  getProductForFilterPopover(orders : OrderActivity[]) {
    let products = _.flatMap(orders, (order) => {
      return order.products.length ? order.products.map((prod) => ({ text: prod.productName, value: prod.productId })) : [];
    });

    this.tempProductFilterData = _.uniqBy(products, 'text')
    return this.tempProductFilterData;
  }

  getQuotesForFilterPopover(quotes) {
    let products = _.flatMap(quotes, (quote) => {
      return quote.products.length ? quote.products.map((prod) => ({ text: prod.name, value: prod.id })) : [];
    });
    this.tempProductFilterData = _.uniqBy(products, 'text')
    return _.uniqBy(products, 'text');
  }

  // Filter Quotes
  filterOrders() {
    this._filteredTimelineActivities = this._allTimelineActivities.filter((order: OrderActivity) => {
      let statusMatch = this.filters.statusString ? order.orderStatusString === this.filters.statusString : true;
      let productMatch = this.filters.products ? order.products.some(prod => prod.productId === this.filters.products) : true
      return statusMatch && productMatch;
    });

    if(this.filters.statusString){
      this.accountTimelineFilter = this.filters.statusString;
    }
    this._initTimelineItemsViewData();
  }

  filterQuotes() {
    this._filteredTimelineActivities = this._allTimelineActivities.filter((quote: Quotes) => {
      let statusMatch = this.filters.statusFilter ? quote.statuscode === this.filters.statusFilter : true;
      let productMatch = this.filters.productFilter ? quote.products.some(prod => prod.id === this.filters.productFilter) : true
      return statusMatch && productMatch;
    });

    if(this.filters.statusString){
      this.accountTimelineFilter = this.filters.statusString;
    }
    // this.initMyQuotesHeaderModel();
    this.getFilterButtonBadgeCount();
    this._initTimelineItemsViewData()
  }

  getFilterButtonBadgeCount() {
    return Object.keys(this.filters).length;
  }

  private async _handleActivityTypeFilter(ev) {
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'account-timeline-activity-type-filter-select',
      data: this.filterOptions.map(a => {
        return {
          title: a.displayText,
          id: a.value,
          isSelected: a.value == this.accountTimelineFilter,
        }
      }),
    };
    let dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {viewData: dropdownListDetail},
      cssClass: 'dropdown-list-view',
      event: ev
    });
    dropdownPopover.onDidDismiss().then(async (data: any) => {
      data = data.data;
      if (data && data.selectedItems && data.selectedItems.length == 1 && (!this.accountTimelineFilter || (this.accountTimelineFilter && data.selectedItems[0].title != this.accountTimelineFilter))) {
        this.accountTimelineFilter = data.selectedItems[0].id;
        this.onTimelineTypeChange(data.selectedItems[0].id)
      }
      dropdownPopover = undefined;
    });
    dropdownPopover.present();
  }

  private _getTimelineItemViewForActivities(activities: Array<any>) {
    let itemsView: Array<TimeLineItem> = [];
    if (activities && activities.length > 0) {
      activities.forEach(item => {
        let activity;
        // if(item.type == ActivityType.Appointment || item.type == ActivityType.PhoneCall || item.type == ActivityType.Sample || item.type == ActivityType.CaseIntake || item.type == ActivityType.Email || item.type == ActivityType.SurgeryOrder){
        //   activity = this.activityService.getActivityByID(item.ID)
        // }else
        if (item.type == ActivityType.Event) {
          activity = this.eventsToolService.getEventDetailsbyId(item.ID);
          if (activity) {
            activity.type = ActivityType.Event;
            if (!isValid(activity.scheduledStart)) {
              activity.scheduledStart = item.scheduledStart;
            }
          }
        }
        let view = this.c360Service.getViewForJourneyItem(false, activity ? activity : item);
        if (view) {
          if (item.type == ActivityType.Event && !activity) {
            view.disableEndArrow = true;
          }
          itemsView.push(view);
        }
      })
    }
    return itemsView;
  }

  public doInfinite(event) {
    this.timelineItems.push(...this.sliceActivities(this.currentStartIndex + 1, this.currentStartIndex + 30));
    this.currentStartIndex += 30;
    event.target.complete();
  }

  private sliceActivities(startIndex: number, count: number) {
    return this._filteredTimelineViewActivities.length < count ? this._filteredTimelineViewActivities.slice(startIndex) : this._filteredTimelineViewActivities.slice(startIndex, count);
  }

  // Card structure
  private _initTimelineItemsViewData() {
    if (this.accountId) {
      if (this.from === 'quotes-section') {
        this._filteredTimelineViewActivities = this._filteredTimelineActivities.map((quote) => this.c360Service.getTimelineItemViewForQuotes(quote));
      } else if (this.from === 'salesorder-section') {
        this._filteredTimelineViewActivities = this._getTimelineItemViewForActivities(this._filteredTimelineActivities);
      } else if (this.from === 'asset-management-section') {
        this._filteredTimelineViewActivities = this._filteredTimelineActivities.map(this.c360Service.assetManagementTimeline());
      }else if (this.from === 'accountPlan-section') {
        this._filteredTimelineViewActivities = this._filteredTimelineActivities.map((accountPlan) => this.c360Service._getProfileItemViewForAccountPlan(accountPlan))
      }
      else {
        this._filteredTimelineViewActivities = this._getTimelineItemViewForActivities(this._filteredTimelineActivities);
      }

      // Search Logic
      if (this.searchInput && this.searchInput.length >= 2) {
        this._filteredTimelineViewActivities = this._filteredTimelineViewActivities.filter(item => {
          let flag: boolean = false;
          if (item.headerText) {
            flag = (item.headerText.toLocaleLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1);
          }
          if (!flag && item.subheaderText) {
            flag = (item.subheaderText.toLocaleLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1);
          }
          if (!flag && item.subheaderText2) {
            flag = (item.subheaderText2.toLocaleLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1);
          }
          if (!flag && item.dataToDisplay && item.dataToDisplay.length > 0) {
            for (let i = 0; i < item.dataToDisplay.length; i++) {
              const dtdItem = item.dataToDisplay[i];
              if (dtdItem && dtdItem.value) {
                flag = (dtdItem.value.toLocaleLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1);
              }
              if (flag) {
                break;
              }
            }
          }
          return flag;
        });
      }
      this.timelineItems = this._filteredTimelineViewActivities.slice(0, this.currentStartIndex);
      this._initSectionHeadersView();
    }
  }



// private get activities(): Array<any> {
  //   return this._filteredTimelineActivities.sort((a, b) => {
  //     if (a.scheduledStart > b.scheduledStart) return -1;
  //     if (a.scheduledStart < b.scheduledStart) return 1;
  //     return 0;
  //   });
  // }

  handleSearch() {
    this.searchInputSubject.next(this.searchInput);
  }

  public onTimelineTypeChange(activityType) {
    if (!this._allTimelineActivities) return;
    switch (activityType) {
      case ActivityType.AllActivity: {
        this._filteredTimelineActivities = this._allTimelineActivities;
        break;
      }
      case ActivityType.MyActivity: {
        let items: any = [];
        this._filteredTimelineActivities = this._allTimelineActivities.filter(item => item.ownerId == this.ownerId);
        break;
      }
      case ActivityType.Appointment: {
        this._filteredTimelineActivities = this._allTimelineActivities.filter(activity => activity.type == ActivityType.Appointment && activity.location != 'LiveMeet')
        break;
      }
      case ActivityType.CaseIntake: {
        this._filteredTimelineActivities = this._allTimelineActivities.filter(activity => activity.type == ActivityType.CaseIntake)
        break;
      }
      case ActivityType.Order: {
        this._filteredTimelineActivities = this._allTimelineActivities.filter(activity => activity.type == ActivityType.Order)
        break;
      }
      case ActivityType.LiveMeet: {
        this._filteredTimelineActivities = this._allTimelineActivities.filter(activity => (activity.type == ActivityType.Appointment && activity.location === 'LiveMeet'));
        break;
      }
      case ActivityType.PhoneCall: {
        this._filteredTimelineActivities = this._allTimelineActivities.filter(activity => (activity.type == ActivityType.PhoneCall));
        break;
      }
      case ActivityType.SurgeryOrder: {
        this._filteredTimelineActivities = this._allTimelineActivities.filter(activity => (activity.type == ActivityType.SurgeryOrder));
        break;
      }
      case ActivityType.Event: {
        this._filteredTimelineActivities = this._allTimelineActivities.filter(activity => (activity.type == ActivityType.Event));
        break;
      }
      case ActivityType.StoreCheck: {
        this._filteredTimelineActivities = this._allTimelineActivities.filter(activity => (activity.type == ActivityType.StoreCheck));
        break;
      }
      case ActivityType.CustomerSurvey: {
        this._filteredTimelineActivities = this._allTimelineActivities.filter(activity => (activity.type == ActivityType.CustomerSurvey));
        break;
      }
      default: {
        this._filteredTimelineActivities = this._allTimelineActivities;
      }
    }
    this._initTimelineItemsViewData();
  }

  _getTimelineActivities() {
    let items: any = [];
    switch (this.from) {
      case 'quotes-section':
        this.quotesService.quotesList$.pipe(
          takeUntil(this.ngDestroy$),
          map(quotes => quotes.filter(quote => quote.accountId === this.accountId)),
          first(),
          tap(filteredQuotes => {
            items = filteredQuotes;
            this._updateTimelineActivities(items);
          })
        ).subscribe();
        break;
      case 'salesorder-section':
        items = this.activityService.activities
          .filter(order => order instanceof OrderActivity && order.type === ActivityType.Order && !order.isHardDeleted && order.accountId == this.accountId)
          .sort((a: any, b: any) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()) as Array<OrderActivity>;
        this._updateTimelineActivities(items);
        break;
      case 'asset-management-section':
        items = this.fieldMaterialManagementService.customerAssets
          .filter(asset => asset.msdyn_account === this.accountId)
          .sort((a, b) => new Date(b.createdon).getTime() - new Date(a.createdon).getTime());
        this._updateTimelineActivities(items)
        break;
      case 'accountPlan-section':
        items = this.accountmanagementOfflineService.accountPlans.filter((accountPlan) => accountPlan.accountId === this.accountId).sort((a, b) => {
          if (a.startDate == "" && b.startDate == "") return 0;
          if (a.startDate == "") return 1
          if (b.startDate == "") return -1
          return parseInt(b.startDate) - parseInt(a.startDate)
        });
       this._updateTimelineActivities(items);
      break;
      default:
        items = this.combineEventsAndActivities(items);
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_SURVEY)) {
          items = this.combineSurveysAndActivities(items);
        }
        items.sort((a, b) => new Date(b.scheduledStart).getTime() - new Date(a.scheduledStart).getTime());
        this._updateTimelineActivities(items);
        break;
    }
  }

  private _updateTimelineActivities(items: any) {
    this._filteredTimelineActivities = this._allTimelineActivities = items;
    this.onTimelineTypeChange(this.accountTimelineFilter || ActivityType.AllActivity);
  }

  private combineEventsAndActivities(items: any) {
    if (this.accountOfflineService.selected.activitesByAccount) {
      items = [...this.accountOfflineService.selected.activitesByAccount];
    }
    if (this.accountOfflineService.selected.events && this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL)) {
      this.accountOfflineService.selected.events.forEach(er => {
        er.type = ActivityType.Event;
        er['scheduledStart'] = er.startDate;
        items.push(er);
      });
    }
    return items;
  }

  private combineSurveysAndActivities(items: any) {
    this.formattedSurveysList = [];
    let surveysByAccountId: any = this.customerAssessService.accountSurveysForTimeline.filter(survey => survey.surveyStatus != SurveyStatus.SAVED) || [];
    if (!_.isEmpty(surveysByAccountId)) {
      let groupedResponsesByDate: any = [];
      groupedResponsesByDate = _.groupBy(surveysByAccountId, 'indskr_assessmentdate');
      groupedResponsesByDate = _.values(groupedResponsesByDate);

      groupedResponsesByDate.forEach((resp) => {
        let activity: any = {};

        const templateId: string = resp[0]['_indskr_template_value'];
        const foundTemplate: AssessmentTemplate = this.customerAssessService.surveytemplates.find(survey => survey.indskr_assessmenttemplateid == templateId);

        let surveyFrequency: string = foundTemplate['indskr_surveyfrequency'] ? foundTemplate['indskr_surveyfrequency'] == SurveyFrequency.ONCE ? this.translate.instant("ONE_TIME") : this.translate.instant("RECURRING") : '';
        activity['type'] = ActivityType.CustomerSurvey;
        activity['subject'] = resp[0]['indskr_name'] || '';
        activity['assessmentId'] = resp[0]['indskr_customerassessmentid'];
        activity['scheduledStart'] = resp[0]['indskr_assessmentdate'];
        activity['surveyFrequency'] = surveyFrequency;
        activity['surveyedBy'] = resp[0]['_modifiedby_value_Formatted'] || '';
        activity['templateId'] = templateId;
        activity['category'] = SurveyCategory.ACCOUNT;
        // activity['rawData'] = resp;
        items.push(activity);
        this.formattedSurveysList.push(activity);
      });
    }
    return items;
  }

  public onFilterClick(ev) {
    if (this._allTimelineActivities.length == 0) return;
    this.openTimelineFilter(ev);
  }

  openActivityDetails = async (ev) => {
    let item: any = this.timelineItems.find(a => ev && a.id == ev.value);
    if (item) {
      let selectedItem = item.refObject;

      if (this.from === 'quotes-section') {
        this.openQuotesDetailsPage(selectedItem);
        return;
      } else if(this.from === 'salesorder-section') {
        this.displayActivityDetails(selectedItem as Activity, true);
        return;
      } else if (this.from === 'asset-management-section'){
        this.openAssetManagementPage(selectedItem);
        return;
      }
      else if(this.from === 'accountPlan-section') {
          this.openAccountPlanDetailsPage(item.refObject)
        return;
      }

      //prevent navigation issue
      if ((this.accountOfflineService.accessedAccountListFrom == PageName.ContactDetailsComponent && this.accountOfflineService.accountPageMode === ComponentViewMode.READONLY)
        || this.accountOfflineService.accountPageMode === ComponentViewMode.PREVIEW
        || this.dynamicFormService.isOpenedAffiliatedContactOnAccount
        || this.dynamicFormService.isOpenedAffiliatedAccountOnAccount
        || this.caseService.accessedFrom == AccesingMode.INMEETING) {
        console.log("The current activity cannot be opened to avoid possible problems with navigation loop");
        return;
      }

      if (this.device.isOffline) {
        this.notifiationService.notify(this.translate.instant('ACCOUNT_TIMELINE_NO_INTERNET'), 'Account Timeline', 'top', ToastStyle.DANGER);
        return;
      }

      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'ActivitySkeleton';
      setTimeout(() => {
        this.displayActivityDetails(selectedItem as Activity);
      }, 0);
    }
  }

  openAssetManagementPage(asset: CustomerAsset) {
    this.fieldMaterialManagementService.selectedAsset = asset;
    this.navService.pushChildNavPageWithPageTracking(
      AssetDetailsComponent,
      PageName.AssetDetailsComponent,
      PageName.AccountPageComponent,
      {
        selectedAsset: asset,
        nestedRoute: true
      }
    );
  }

  openQuotesDetailsPage(quote: Quotes) {
    this.navService.pushChildNavPageWithPageTracking(QuoteDetailsComponent,
      PageName.QuoteDetailsComponent,
      PageName.AccountPageComponent, {
        selectedQuote: quote,
        nestedRoute: true,
      });
  }

  openAccountPlanDetailsPage(item) {
    let view =this.accountmanagementOfflineService.selectedMode = AccountPlanMode.VIEW
    this.navService.pushChildNavPageWithPageTracking(AccountPlanDetails, PageName.AccountPlanDetailsComponent, PageName.AccountPageComponent,{
      data:item,
      selectedMode:view,
      from: 'AccountTimeline'
    });
      this.accountmanagementOfflineService.selectedAccountPlan$.next(item);
  }

  async displayActivityDetails(selActivity, overrideOfflineCheck = false) {
    if (this.device.isOffline && !overrideOfflineCheck) {
      this.notifiationService.notify(this.translate.instant('ACCOUNT_TIMELINE_NO_INTERNET'), 'Account Timeline', 'top', ToastStyle.DANGER);
      return;
    }
    if (selActivity instanceof CaseActivity) {
      this.uiService.displayLoader();
      await this.caseDataService.findCaseById(selActivity.ID).then(async res => {
        if (res) {
          for (let key in res) {
            if (key.charAt(0) === "_") {
              var a = key.substring(1, key.length);
              res[a] = res[key];
              delete res[key];
            }
          }
          let iCase: CaseActivity;
          iCase = new CaseActivity(res);
          iCase = await this.caseService.reInitDataDependency(iCase);
          this.activityService.selectedActivity = iCase;
          this.caseService.accessedFrom = AccesingMode.ACCOUNT_TIMELINE;
          this.navService.pushChildNavPageWithPageTracking(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent, PageName.AccountPageComponent, { from: 'AccountTimeline' });
          this.caseService.assignSelectedCase(iCase);
          this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
        }
        this.uiService.dismissLoader();
      }).catch(() => {
        console.log("Failed to load customer inquiry:" + selActivity.ID);
        this.uiService.dismissLoader();
      });
    } else if (selActivity instanceof OrderActivity) {
      let foundActivity = this.activityService.getActivityByID(selActivity.ID);
      if (foundActivity) {
        this.activityService.selectedActivity = foundActivity;
      } else {
        this.uiService.displayLoader();
        foundActivity = await this.activityDataService.getRealTimeActivityDetails(selActivity);
        this.uiService.dismissLoader();
      }
      this.activityService.selectedActivity = (foundActivity) ? foundActivity : selActivity;
      this.navService.pushChildNavPageWithPageTracking(OrderDetailsComponent, PageName.OrderDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTimeline', activity: this.activityService.selectedActivity });
    } else if (selActivity instanceof ProcedureTrackerActivity) {
      let foundActivity = this.activityService.getActivityByID(selActivity.ID);
      if (foundActivity) {
        this.activityService.selectedActivity = foundActivity;
      } else {
        this.uiService.displayLoader();
        foundActivity = await this.activityDataService.getRealTimeActivityDetails(selActivity);
        this.uiService.dismissLoader();
      }
      if (!foundActivity) return;
      this.navService.pushChildNavPageWithPageTracking(ProcedureTrackerDetailsComponent, PageName.ProcedureTrackerDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTimeline', activity: this.activityService.selectedActivity });
    } else if (selActivity instanceof AppointmentActivity) {

      let activity = this.activityService.getActivityByID(selActivity.ID);
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;

      if (activity) {
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selected = activity;
      }
      this.uiService.displayLoader();
      // activity = await this.activityDataService.getRealTimeActivityDetails(selActivity);
      // let tempActivity: AppointmentActivity = new AppointmentActivity({
      //   activityid: selActivity.ID,
      //   activitytypecode: "appointment",
      //   indskr_ownerfullname: selActivity.meetingOwner,
      //   indskr_ownerid: selActivity.ownerId,
      //   location: selActivity.ownerId,
      //   scheduledend: selActivity.scheduledEnd.getTime().toString(),
      //   scheduledstart: selActivity.scheduledStart.getTime().toString(),
      //   statuscode: selActivity.status,
      //   statecode: selActivity.state,
      //   subject: selActivity.subject,
      // });
      activity = await this.activityDataService.updateActivityDetails(activity ? activity : selActivity);
      this.accountOfflineService.tempSelected = _.cloneDeep(this.accountOfflineService.selected);
      this.uiService.dismissLoader();
      this.activityService.selected = (activity) ? activity : selActivity;
      // fix to display missing info in detail page
      // this.activityService.selected = (this.activityService.selectedActivity) ? this.activityService.selectedActivity : tempActivity;
      this.activityService.selectedActivity.accessedFrom = PageName.AccountTimelineComponent;
      this.uiService.showNewActivity = false;
      this.activityDataService.activityDetailsLoaded = true;
      this.uiService.showNewActivity = false;
      this.activityDataService.activityDetailsLoaded = true;
      await this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.AccountPageComponent, { from: 'AccountTimeline' });
      //this.activityDataService.updateActivityDetails(activity);
      this.events.publish("detectChangesOnActivityDetails");
      if (this.activityService.selectedActivity) {
        switch (selActivity.type) {
          case ActivityType.Appointment:
            this.footerService.initButtons(FooterViews.Activities);
            break;
          default:
            this.footerService.initButtons('');
        }
      }

    } else if (selActivity instanceof StoreCheckActivity) {
      let activity = this.activityService.getActivityByID(selActivity.ID);
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;

      if (activity) {
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selected = activity;
      }
      await this.uiService.displayLoader();
      let tempActivity: StoreCheckActivity = new StoreCheckActivity({
        activityId: selActivity.ID,
        activitytypecode: "appointment",
        indskr_ownerfullname: selActivity.meetingOwner,
        indskr_ownerid: selActivity.ownerId,
        location: selActivity.ownerId,
        scheduledend: selActivity.scheduledEnd.getTime().toString(),
        scheduledstart: selActivity.scheduledStart.getTime().toString(),
        statuscode: selActivity.status,
        statecode: selActivity.state,
        subject: selActivity.subject,
        indskr_type: ActivityType.StoreCheck
      });
      this.activityService.selected = (activity) ? activity : tempActivity;
      await this.activityDataService.updateActivityDetails(activity ? activity : tempActivity);
      this.accountOfflineService.tempSelected = _.cloneDeep(this.accountOfflineService.selected);
      this.uiService.dismissLoader();
      this.activityService.selectedActivity.accessedFrom = PageName.AccountTimelineComponent;
      this.uiService.showNewActivity = false;
      this.activityDataService.activityDetailsLoaded = true;
      this.uiService.showNewActivity = false;
      this.activityDataService.activityDetailsLoaded = true;
      await this.navService.pushChildNavPageWithPageTracking(StoreCheckDetailsComponent, PageName.StoreCheckDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTimeline' });
    } else if (selActivity instanceof PhoneActivity) {
      let activity = this.activityService.getActivityByID(selActivity.ID);
      if (activity) {
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selected = activity;
      }
      await this.uiService.displayLoader();
      //activity = await this.activityDataService.getRealTimeActivityDetails(selActivity);
      // await this.activityDataService.updateActivityDetails(activity);
      await this.activityDataService.getPhonecallActivityByIdOnline(selActivity.ID, selActivity.scheduledStart.getTime().toString(), selActivity.scheduledEnd.getTime().toString(), selActivity.scheduledStart.getTime().toString()).then(async (response) => {
        activity = new PhoneActivity(response);
        await this.activityDataService.updateActivityForConfigField(activity, response, ActivityType.PhoneCall);
        await this.activityDataService._appendPhoneCallDetailsToActivity(activity, response, false);
        this.accountOfflineService.tempSelected = _.cloneDeep(this.accountOfflineService.selected);
        this.activityDataService.activityDetailsLoaded = true;
      })
      await this.uiService.dismissLoader();
      this.activityService.selected = activity;
      this.activityService.selectedActivity.accessedFrom = PageName.AccountTimelineComponent;
      this.uiService.showNewActivity = false;
      this.activityDataService.activityDetailsLoaded = true;
      await this.navService.pushChildNavPageWithPageTracking(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTimeline' });

      this.events.publish("detectChangesOnPhoneCallActivityDetails");
      this.footerService.initButtons(FooterViews.PHONE_CALL);
    } else if (selActivity instanceof SurgeryOrderActivity) {
      let foundActivity = this.activityService.getActivityByID(selActivity.ID);
      if (foundActivity) {
        this.activityService.selectedActivity = foundActivity;
      } else {
        this.uiService.displayLoader();
        foundActivity = await this.activityDataService.getRealTimeActivityDetails(selActivity);
        this.uiService.dismissLoader();
      }
      this.activityService.selectedActivity = (foundActivity) ? foundActivity : selActivity;
      this.navService.pushChildNavPageWithPageTracking(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTimeline', activity: this.activityService.selectedActivity });
      this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
    } else if (selActivity instanceof EventActivity) {
      const eventsToolData = this.eventsToolService.eventsToolData;
      const foundEvent = eventsToolData.find(e => {
        if (e.ID) {
          return e.ID === selActivity.ID;
        } else if (e.offlineId) {
          return e.offlineId === selActivity.ID;
        } else {
          return false;
        }
      });
      if (!_.isEmpty(foundEvent)) {
        selActivity = foundEvent;
        this.eventsToolService.selectedEventOnEventsTool = foundEvent;
      } else {
        return;
      }
      if (!this.device.isOffline) {
        this.uiService.displayLoader();
        await this.eventsToolDataService.fetchEventRealTimeDetails(selActivity as EventActivity).then((updatedEvent: EventActivity) => {
          selActivity = updatedEvent;
          this.uiService.dismissLoader();
        }).catch(err => {
          console.log(err);
          this.uiService.dismissLoader();
        });
      }
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
      this.activityService.selectedActivity = selActivity;
      this.navService.pushChildNavPageWithPageTracking(EventDetailsComponent, PageName.EventDetailsPageComponent, PageName.AccountPageComponent, {
        from: 'AccountTimeline', event: this.activityService.selectedActivity,
      });
      this.eventsToolService.setSelectedEventUpdates(this.activityService.selectedActivity as EventActivity);
      this.footerService.initButtons(FooterViews.EVENTS_DETAILS);
    } else if (selActivity.type == ActivityType.CustomerSurvey) {
      if (this.device.isOffline) {
        this.notifiationService.notify(this.translate.instant("SURVEY_ACTIVITIES_NOTIFY_ONLINE"), "survey-activities", "top", ToastStyle.DANGER, 3000, true);
        return;
      }
      if (!_.isEmpty(selActivity)) {
        this.openSubmittedSurveyDetails(selActivity);
      }
    }
  }

  public async openTimelineFilter(event) {
    const modal = await this.modalCtrl.create({
      component: IndFilterMenuModalComponent,
      cssClass: 'filter-menu-right-modal',
      backdropDismiss: true,
      componentProps: {
        viewData: {
          from: PageName.AccountTimelineComponent,
          data: this.accountTimelineFilterFormView,
          filterTitle: this.translate.instant('ALL_FILTERS'),
          selectedFilters: this.selectedFilters,
          selectedDate: this.selectedDate
        },
      }
    });
    await modal.present().then(() => { });
    modal.onDidDismiss().then(async (res) => {
      //Show Result
      if (res && res.data && res.data.isDone) {
        if (!_.isEmpty(res.data.selectedItems) || !_.isEmpty(res.data.selectedDate)) {
          this.accountTimelineFilterFormView = res.data.selectedItems;
          this.selectedFilters = res.data.selectedItemsAccordion;

          /******* filtered activities by duration *******/
          let isFilteredByDate: boolean = !_.isEmpty(res.data.selectedDate) && !_.isEmpty(res.data.selectedDate.startDate) && !_.isEmpty(res.data.selectedDate.endDate) || false;
          if (isFilteredByDate) {
            this.selectedDate = {
              startDate: res.data.selectedDate.startDate,
              endDate: res.data.selectedDate.endDate,
            };
          } else {
            this.selectedDate = {
              startDate: '',
              endDate: '',
            };
          }
          this._applyAdvancedFilterOnTimelineActivities();
        }
        console.log(res);
      }
    })
  }

  private _applyAdvancedFilterOnTimelineActivities() {
    if (!_.isEmpty(this.accountTimelineFilterFormView) || !_.isEmpty(this.selectedDate)) {
      this.filterButtonBadgeCount = 0;
      this._filteredTimelineActivities = [];
      //this.accountTimelineFilterFormView = res.data.selectedItems;
      this.isTimelineFiltered = true;
      //this.selectedFilters = res.data.selectedItemsAccordion;
      this.filterButtonBadgeCount = this.selectedFilters.length;
      if (!_.isEmpty(this.selectedDate) && (!_.isEmpty(this.selectedDate.startDate) || !_.isEmpty(this.selectedDate.endDate))) {
        this.filterButtonBadgeCount = this.filterButtonBadgeCount + 1;
      }
      let selectedActivitiesFromMainFilter = [];
      this.selectedFilters.forEach(item => {
        if (item.type == 'filter-main') {
          selectedActivitiesFromMainFilter.push(item.category);
        }
      });

      /******* filtered activities by duration *******/
      let isFilteredByDate: boolean = !_.isEmpty(this.selectedDate) && !_.isEmpty(this.selectedDate.startDate) && !_.isEmpty(this.selectedDate.endDate) || false;
      this.onTimelineTypeChange(this.accountTimelineFilter);
      let targetTimelineActivities = _.cloneDeep(this._filteredTimelineActivities);
      if (isFilteredByDate) {
        targetTimelineActivities = this._filterTimelineActivitiesByDate(targetTimelineActivities, this.selectedDate);
      } else {

      }

      /******* filtered activities by selected activity and options *******/
      const isSelectedMyActivity: boolean = !_.isEmpty(selectedActivitiesFromMainFilter) && selectedActivitiesFromMainFilter.includes(ActivityType.MyActivity);
      if (isSelectedMyActivity) targetTimelineActivities = targetTimelineActivities.filter(activity => activity.ownerId == this.ownerId);
      if (!_.isEmpty(selectedActivitiesFromMainFilter)) {
        let activities = [];
        selectedActivitiesFromMainFilter.forEach(mainFilterValue => {
          if (mainFilterValue != ActivityType.MyActivity) {
            let filteredTargetTimelineActivity = [];
            if (mainFilterValue == ActivityType.LiveMeet) {
              filteredTargetTimelineActivity = targetTimelineActivities.filter(activity => activity.type == ActivityType.Appointment && activity.location == 'LiveMeet');
            } else {
              filteredTargetTimelineActivity = targetTimelineActivities.filter(activity => activity.type == mainFilterValue);
            }
            const filteredSelectedItemsByActivity = this.selectedFilters.filter(item => item.parentId == mainFilterValue);
            const groupedCategory = _.groupBy(filteredSelectedItemsByActivity, 'category');
            const groupedCategoryList = _.values(groupedCategory);
            if (!_.isEmpty(filteredSelectedItemsByActivity)) {
              filteredTargetTimelineActivity.forEach(act => {
                let isMatchedData: boolean = true;
                groupedCategoryList.forEach(category => {
                  if (mainFilterValue == ActivityType.Appointment && act.location != 'LiveMeet') {
                    if (category[0]['type'] == 'appointment-status') {
                      isMatchedData = category.some(item => item.id == ActivityType.Appointment + '_' + act.statusString) && isMatchedData;
                    } else if (category[0]['type'] == 'appointment-address') {
                      const formattedAddressId = act.location != 'No Location' ? ActivityType.Appointment + '_' + act.location : ActivityType.Appointment + '_' + 'noAddress';
                      isMatchedData = category.some(item => item.id == formattedAddressId) && isMatchedData;
                    } else if (category[0]['type'] == 'appointment-product') {
                      isMatchedData = this._matchedProduct(ActivityType.Appointment, category, act) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.Email) {
                    if (category[0]['type'] == 'messages-channelType') {
                      isMatchedData = category.some(item => item.id == ActivityType.Email + '_' + act.channelTypeName) && isMatchedData;
                    } else if (category[0]['type'] == 'messages-status') {
                      let formattedStatus: string = '';
                      if (act.status == 548910000) {
                        if (act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp') {
                          formattedStatus = act.meetingOwnerName == this.userName ? this.translate.instant('CONTACT_SHARED_BY_ME') : this.translate.instant('CONTACT_SHARED_BY_OWNER', { meetingOwnerName: act.meetingOwnerName });
                        }
                      } else {
                        if (act.channelTypeName == 'Email' || act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp' || act.channelTypeName == undefined) {
                          formattedStatus = act.meetingOwnerName == this.userName ? this.translate.instant('CONTACT_SENT_BY_ME') : this.translate.instant('CONTACT_SENT_BY_OWNER', { meetingOwnerName: act.meetingOwnerName });
                        }
                      }
                      isMatchedData = category.some(item => item.id == ActivityType.Email + '_' + formattedStatus) && isMatchedData;
                    } else if (category[0]['type'] == 'messages-transfer-status') {
                      let formattedTransferStatus: string = '';
                      if (act.status == 548910000) {
                        if (act.actualEnd && (act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp')) {
                          formattedTransferStatus = this.translate.instant('SHARED');
                        }
                      } else {
                        if (act.channelTypeName == 'Email' || act.channelTypeName == undefined) {
                          if (act.senton && !act.readOn) {
                            formattedTransferStatus = this.translate.instant('DELIVERED');
                          } else if (act.readOn) {
                            formattedTransferStatus = this.translate.instant('READ');
                          }
                        } else if (act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp') {
                          if (act.senton && !act.readOn) {
                            formattedTransferStatus = this.translate.instant('SENT');
                          } else if (act.readOn) {
                            formattedTransferStatus = this.translate.instant('DELIVERED');
                          }
                        }
                      }
                      isMatchedData = category.some(item => item.id == ActivityType.Email + '_' + formattedTransferStatus) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.Sample) {
                    if (category[0]['type'] == 'sample-address') {
                      isMatchedData = category.some(item => item.id == ActivityType.Sample + '_' + act.addressID) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.CaseIntake) {
                    if (category[0]['type'] == 'caseIntake-status') {
                      isMatchedData = category.some(item => item.id == ActivityType.CaseIntake + '_' + act._case_status_value) && isMatchedData;
                    } else if (category[0]['type'] == 'caseIntake-stage') {
                      isMatchedData = category.some(item => item.id == ActivityType.CaseIntake + '_' + act._case_stage_value) && isMatchedData;
                    } else if (category[0]['type'] == 'caseIntake-assigned-to') {
                      isMatchedData = category.some(item => item.id == ActivityType.CaseIntake + '_' + act.meetingOwnerId) && isMatchedData;
                    } else if (category[0]['type'] == 'caseIntake-product') {
                      isMatchedData = category.some(item => item.id == ActivityType.CaseIntake + '_' + act._case_trans_product_name) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.Event) {
                    if (category[0]['type'] == 'event-status') {
                      let formattedStatus: string = '';
                      if (!_.isEmpty(act.completedby)) {
                        formattedStatus = act.completedby == this.userName ? this.translate.instant('EVENT_COMPLETED_BY_ME') : this.translate.instant('EVENT_COMPLETED_BY_OWNER', { text: act.completedby });
                      } else if (!_.isEmpty(act.createdby)) {
                        formattedStatus = act.completedby == this.userName ? this.translate.instant('EVENT_CHECKED_BY_ME') : this.translate.instant('EVENT_CHECKED_BY_OWNER', { text: act.createdby });
                      }
                      if (this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_REGISTRATION) || this.authenticationService.user.eventTimeLine) {
                        if (_.isEmpty(act.registrationresponseId) && !_.isEmpty(act.eventRegistrationId) && !_.isEmpty(act.registeredBy)) {
                          formattedStatus = act.registeredBy == this.userName ? this.translate.instant('CONTACT_REGISTERED_BY_ME') : this.translate.instant('CONTACT_REGISTERED_BY_OWNER', { text: act.registeredBy });
                        } else if (!_.isEmpty(act.registrationresponseId) && !_.isEmpty(act.indskr_ownername)) {
                          formattedStatus = act.indskr_ownername == this.userName ? this.translate.instant('CONTACT_SIGNEDUP_BY_ME') : this.translate.instant('CONTACT_SIGNEDUP_BY_OWNER', { text: act.indskr_ownername });
                        }
                      }
                      isMatchedData = category.some(item => item.id == ActivityType.Event + '_' + formattedStatus) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.LiveMeet) {
                    if (category[0]['type'] == 'liveMeeting-status') {
                      isMatchedData = category.some(item => item.id == ActivityType.LiveMeet + '_' + act.statusString) && isMatchedData;
                    } else if (category[0]['type'] == 'liveMeeting-product') {
                      isMatchedData = this._matchedProduct(ActivityType.LiveMeet, category, act) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.PhoneCall) {
                    if (category[0]['type'] == 'phoneCall-phoneNumber') {
                      isMatchedData = category.some(item => item.id == ActivityType.PhoneCall + '_' + act.phonecallphonenumber) && isMatchedData;
                    } else if (category[0]['type'] == 'phoneCall-product') {
                      isMatchedData = this._matchedProduct(ActivityType.PhoneCall, category, act) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.SurgeryOrder) {
                    if (category[0]['type'] == 'surgeryOrder-owner') {
                      isMatchedData = category.some(item => item.id == ActivityType.SurgeryOrder + '_' + act.ownerId) && isMatchedData;
                    } else if (category[0]['type'] == 'surgeryOrder-product') {
                      isMatchedData = this._matchedProduct(ActivityType.SurgeryOrder, category, act) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.WebsiteAccess) {
                    if (category[0]['type'] == 'websiteAccessLogs-channels') {
                      isMatchedData = category.some(item => item.id == ActivityType.WebsiteAccess + '_' + act.channel) && isMatchedData;
                    } else if (category[0]['type'] == 'websiteAccessLogs-department') {
                      isMatchedData = category.some(item => item.id == ActivityType.WebsiteAccess + '_' + act.department) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.Order) {
                    if (category[0]['type'] == 'salesOrder-status') {
                      isMatchedData = category.some(item => item.id == ActivityType.Order + '_' + act.statusString) && isMatchedData;
                    } else if (category[0]['type'] == 'salesOrder-owner') {
                      isMatchedData = category.some(item => item.id == ActivityType.Order + '_' + act.ownerId) && isMatchedData;
                    } else if (category[0]['type'] == 'salesOrder-product') {
                      isMatchedData = this._matchedProduct(ActivityType.Order, category, act) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.CustomerSurvey) {
                    if (category[0]['type'] == 'customerSurvey-surveyType') {
                      isMatchedData = category.some(item => item.id == ActivityType.CustomerSurvey + '_' + act.type) && isMatchedData;
                    } else if (category[0]['type'] == 'customerSurvey-surveyFrequency') {
                      isMatchedData = category.some(item => item.id == ActivityType.CustomerSurvey + '_' + act.surveyFrequency) && isMatchedData;
                    } else if (category[0]['type'] == 'customerSurvey-surveyedBy') {
                      isMatchedData = category.some(item => item.id == ActivityType.CustomerSurvey + '_' + act.surveyedBy) && isMatchedData;
                    }
                  }
                });

                if (isMatchedData) activities.push(act);

              });
            } else {
              let filteredActivityMainOnly = targetTimelineActivities.filter(activity => activity.type == mainFilterValue);
              filteredActivityMainOnly.forEach(act => {
                activities.push(act);
              });
            }
          }
        });
        if (!isSelectedMyActivity) targetTimelineActivities = activities;
      }

      /******* grouped filtered activities *******/
      this._filteredTimelineActivities = targetTimelineActivities;
      this._initTimelineItemsViewData();
    } else {
      this.onTimelineTypeChange(this.accountTimelineFilter);
    }
  }

  private _matchedProduct(activityType: ActivityType, category: any[], act: any): boolean {
    let isMatchedData: boolean = false;
    isMatchedData = category.some(item => {
      let isFoundProduct: boolean = false;
      if (!_.isEmpty(act.products)) {
        act.products.forEach(product => {
          const temId: string = product.productId || product.ID;
          const formattedId = activityType + '_' + temId;
          if (item.id == formattedId) return isFoundProduct = true;
        });
      }
      return isFoundProduct
    })
    return isMatchedData;
  }

  private _filterTimelineActivitiesByDate(targetTimelineActivities: any, selectedDate: any) {
    const startDate: Date = new Date(selectedDate.startDate);
    const endDate: Date = new Date(selectedDate.endDate);
    targetTimelineActivities.map(activity => {
      if (activity.hasOwnProperty('scheduledStart') && activity['scheduledStart'] != '' && activity['scheduledStart'] != "Invalid Date" && activity['scheduledStart'] != undefined) {
        activity['isValidStartDate'] = true;
        activity['scheduledStart'] = new Date(activity['scheduledStart']);
      }
      if (activity.hasOwnProperty('scheduledEnd') && activity['scheduledEnd'] != '' && activity['scheduledEnd'] != "Invalid Date" && activity['scheduledEnd'] != undefined) {
        activity['isValidEndDate'] = true;
        activity['scheduledEnd'] = new Date(activity['scheduledEnd']);
      }
    });
    let filteredTimelineActivities = [];
    targetTimelineActivities.forEach(activity => {
      if (activity.hasOwnProperty('scheduledStart') && activity['isValidStartDate'] && activity.hasOwnProperty('scheduledEnd') && activity['isValidEndDate']) {
        if (activity['scheduledStart'] >= startDate && activity['scheduledEnd'] <= endDate) {
          filteredTimelineActivities.push(activity);
        }
      } else if (activity.hasOwnProperty('scheduledStart') && activity['isValidStartDate']) {
        if (activity['scheduledStart'] >= startDate && activity['scheduledStart'] <= endDate) {
          filteredTimelineActivities.push(activity);
        }
      } else if (activity.hasOwnProperty('scheduledEnd') && activity['isValidEndDate']) {
        if (activity['scheduledEnd'] <= endDate) {
          filteredTimelineActivities.push(activity);
        }
      }
    });
    // targetTimelineActivities = filteredTimelineActivities;
    return filteredTimelineActivities;
  }

  private _initFilterOptions(): void {
    this.filterOptions = [];
    if ((this.accountOfflineService.selected && this.accountOfflineService.selected.activitesByAccount && this.accountOfflineService.selected.activitesByAccount.length > 0) || (this.accountOfflineService.selected.events && this.accountOfflineService.selected.events.length > 0)) {
      this.filterOptions.push({ value: ActivityType.AllActivity, isSelected: true, displayText: this.translate.instant('ACCOUNT_TIMELINE_OPTION_ALL_ACTIVITY') });
      this.filterOptions.push({ value: ActivityType.MyActivity, isSelected: false, displayText: this.translate.instant('MY_ACTIVITIES') });
      if (this.accountOfflineService.selected.activitesByAccount.some(activity => activity.type == ActivityType.Appointment)) {
        this.filterOptions.push({ value: ActivityType.Appointment, isSelected: false, displayText: this.translate.instant('ACCOUNT_TIMELINE_OPTION_MEETINGS') })
      }
      if (this.accountOfflineService.selected.activitesByAccount.some(activity => activity.type == ActivityType.CaseIntake)) {
        this.filterOptions.push({ value: ActivityType.CaseIntake, isSelected: false, displayText: this.translate.instant('CUSTOMER_INQUIRIES_WITH_TEXT', { globalCustomerText: this.utilityService.globalCustomerText }) })
      }
      if (this.accountOfflineService.selected.activitesByAccount.some(activity => activity.type == ActivityType.Order)) {
        this.filterOptions.push({ value: ActivityType.Order, isSelected: false, displayText: this.translate.instant('ACCOUNT_TIMELINE_OPTION_ORDERS') })
      }
      if (this.accountOfflineService.selected.activitesByAccount.some(activity => activity.type == ActivityType.PhoneCall)) {
        this.filterOptions.push({ value: ActivityType.PhoneCall, isSelected: false, displayText: this.translate.instant('PHONE_CALLS') })
      }
      if (this.accountOfflineService.selected.activitesByAccount.some(activity => activity.type == ActivityType.SurgeryOrder || activity.type == ActivityType.ProcedureTracker)) {
        this.filterOptions.push({ value: ActivityType.SurgeryOrder || ActivityType.ProcedureTracker, isSelected: false, displayText: this.translate.instant('PROCEDURE_LOGS') })
      }
      if (!_.isEmpty(this.accountOfflineService.selected.events) && this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL)) {
        this.filterOptions.push({ value: ActivityType.Event, isSelected: false, displayText: this.translate.instant('EVENTS') })
      }
      if (!_.isEmpty(this.formattedSurveysList) && this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_SURVEY)) {
        this.filterOptions.push({ value: ActivityType.CustomerSurvey, isSelected: false, displayText: this.translate.instant('SURVEY') })
      }
      // if (this.accountOfflineService.selected.activitesByAccount.some(activity => activity.type == ActivityType.StoreCheck)) {
      //   this.filterOptions.push({ value: ActivityType.StoreCheck, isSelected: false, displayText: this.translate.instant('STORE_CHECKS') })
      // }
    }
  }

  getSelectedText(value) {
    const tot = this._filteredTimelineViewActivities.length;
    if ((this.filterButtonBadgeCount && this.filterButtonBadgeCount > 0) || (this.searchInput && this.searchInput.length >= 2)) {
      return this.translate.instant('ACCOUNT_RESULTS') + ` ` + `(${tot})`;
    }
    switch (value) {
      case ActivityType.AllActivity:
        return `${this.translate.instant('ALL_ACTIVITIES')} (${tot})`;
      case ActivityType.MyActivity:
        return `${this.translate.instant('MY_ACTIVITIES')} (${tot})`;
      case ActivityType.Appointment:
        return `${this.translate.instant('MEETINGS')} (${tot})`;
      case ActivityType.Email:
        return `${this.translate.instant('MESSAGES')} (${tot})`;
      case ActivityType.Sample:
        return `${this.translate.instant('ALLOWCATION_ORDERS')} (${tot})`;
      case ActivityType.CaseIntake:
        return `${this.utilityService.globalCustomerText} Inquiries (${tot})`;
      case ActivityType.Order:
        return `${this.translate.instant('SALES_ORDERS')} (${tot})`;
      case ActivityType.Event:
        return `${this.translate.instant('EVENTS')} (${tot})`;
      case ActivityType.SurgeryOrder:
        return `${this.translate.instant('PROCEDURE_LOGS')} (${tot})`;
      case ActivityType.PhoneCall:
        return `${this.translate.instant('PHONE_CALLS')} (${tot})`;
      case ActivityType.ProcedureTracker:
        return `${this.translate.instant('BULK_PROCEDURE_LOGS')} (${tot})`;
      case ActivityType.CustomerSurvey:
        return `${this.translate.instant('SURVEY')} (${tot})`;
      default:
        return `${this.translate.instant('ALL_ACTIVITIES')} (${tot})`;
    }
  }

  private _initFilterFormViews() {
    this.accountTimelineFilterFormView = [];
    if (!_.isEmpty(this._allTimelineActivities)) {
      let filterFormViewMain: IndFilterMenuModalDataModel = {
        id: 'filter-main',
        displayName: this.translate.instant('ACTIVITY'),
        isMultiSelect: true,
        options: []
      }
      this.accountTimelineFilterFormView.push(filterFormViewMain);

      let myActivitesDisplayText = this.translate.instant('MY_ACTIVITIES');
      let meetingsDisplayText = this.translate.instant('MEETINGS');
      if (this.translate.currentLang == 'it') {
        myActivitesDisplayText = this.translate.instant('MY_ACTIVITIES_TIMELINE');
        meetingsDisplayText = this.translate.instant('MEETINGS_TIMELINE');
      }
      //default filter options - my activity
      filterFormViewMain.options.push({ id: 'myActivities-main', group: ActivityType.MyActivity, isSelected: false, displayName: myActivitesDisplayText });
      //add filter options based on the timelineActivities
      if (this._allTimelineActivities.some(activity => (activity.type == ActivityType.Appointment && activity.location != 'LiveMeet'))) {
        filterFormViewMain.options.push({ id: 'meeting-main', group: ActivityType.Appointment, isSelected: false, displayName: meetingsDisplayText });
        let appointmentActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.Appointment && activity.location != 'LiveMeet');
        appointmentActs.forEach((act) => {
          //status
          if (!_.isEmpty(act.statusString)) {
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'appointment-status');
            let option = {
              group: this.translate.instant('STATUS'),
              id: ActivityType.Appointment + '_' + act.statusString,
              displayName: act.statusString,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Appointment + '_' + act.statusString);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'appointment-status',
                parentId: ActivityType.Appointment,
                displayName: this.translate.instant('MEETINGS') + ' - ' + this.translate.instant('STATUS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
          //address
          if (!_.isEmpty(act.location)) {
            const formattedAddressId = act.location != 'No Location' ? ActivityType.Appointment + '_' + act.location : ActivityType.Appointment + '_' + 'noAddress';
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'appointment-address');
            let option = {
              group: this.translate.instant('ADDRESS'),
              id: formattedAddressId,
              displayName: act.location,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == formattedAddressId);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'appointment-address',
                parentId: ActivityType.Appointment,
                displayName: this.translate.instant('MEETINGS') + ' - ' + this.translate.instant('ADDRESS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
          //Products
          if (!_.isEmpty(act.products)) {
            act.products.forEach(product => {
              const idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'appointment-product');
              let option = {
                group: this.translate.instant('PRODUCT'),
                id: ActivityType.Appointment + '_' + product.ID,
                displayName: product.name,
                isSelected: false,
              }
              if (idx > -1) {
                let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Appointment + '_' + product.ID);
                if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
              } else {
                let viewData = {
                  id: 'appointment-product',
                  parentId: ActivityType.Appointment,
                  displayName: this.translate.instant('MEETINGS') + ' - ' + this.translate.instant('PRODUCT'),
                  isMultiSelect: true,
                  options: []
                }
                viewData.options.push(option);
                this.accountTimelineFilterFormView.push(viewData);
              }
            })
          }
        });
      }
      if (this._allTimelineActivities.some(activity => activity.type == ActivityType.Email)) {
        filterFormViewMain.options.push({ id: 'email-main', group: ActivityType.Email, isSelected: false, displayName: this.translate.instant('MESSAGES') });
        let messagesActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.Email);
        messagesActs.forEach((act) => {
          //channel type
          if (!_.isEmpty(act.channelTypeName)) {
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'messages-channelType');
            let option = {
              group: this.translate.instant('CHANNELS'),
              id: ActivityType.Email + '_' + act.channelTypeName,
              displayName: act.channelTypeName,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Email + '_' + act.channelTypeName);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'messages-channelType',
                parentId: ActivityType.Email,
                displayName: this.translate.instant('MESSAGES') + ' - ' + this.translate.instant('CHANNELS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
          //status
          if (act.status != '' || act.status != undefined || act.status != null) {
            let formattedStatus: string = '';
            if (act.status == 548910000) {
              if (act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp') {
                formattedStatus = act.meetingOwnerName == this.userName ? this.translate.instant('CONTACT_SHARED_BY_ME') : this.translate.instant('CONTACT_SHARED_BY_OWNER', { meetingOwnerName: act.meetingOwnerName });
              }
            } else {
              if (act.channelTypeName == 'Email' || act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp' || act.channelTypeName == undefined) {
                formattedStatus = act.meetingOwnerName == this.userName ? this.translate.instant('CONTACT_SENT_BY_ME') : this.translate.instant('CONTACT_SENT_BY_OWNER', { meetingOwnerName: act.meetingOwnerName });
              }
            }
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'messages-status');
            let option = {
              group: this.translate.instant('STATUS'),
              id: ActivityType.Email + '_' + formattedStatus,
              displayName: formattedStatus,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Email + '_' + formattedStatus);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'messages-status',
                parentId: ActivityType.Email,
                displayName: this.translate.instant('MESSAGES') + ' - ' + this.translate.instant('STATUS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
          //transfer status
          if (act.status != '' || act.status != undefined || act.status != null) {
            let formattedTransferStatus: string = '';
            if (act.status == 548910000) {
              if (act.actualEnd && (act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp')) {
                formattedTransferStatus = this.translate.instant('SHARED');
              }
            } else {
              if (act.channelTypeName == 'Email' || act.channelTypeName == undefined) {
                if (act.senton && !act.readOn) {
                  formattedTransferStatus = this.translate.instant('DELIVERED');
                } else if (act.readOn) {
                  formattedTransferStatus = this.translate.instant('READ');
                }
              } else if (act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp') {
                if (act.senton && !act.readOn) {
                  formattedTransferStatus = this.translate.instant('SENT');
                } else if (act.readOn) {
                  formattedTransferStatus = this.translate.instant('DELIVERED');
                }
              }
            }
            if (formattedTransferStatus) {
              let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'messages-transfer-status');
              let option = {
                group: this.translate.instant('TRANSFER_STATUS'),
                id: ActivityType.Email + '_' + formattedTransferStatus,
                displayName: formattedTransferStatus,
                isSelected: false,
              }
              if (idx > -1) {
                let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Email + '_' + formattedTransferStatus);
                if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
              } else {
                let viewData = {
                  id: 'messages-transfer-status',
                  parentId: ActivityType.Email,
                  displayName: this.translate.instant('MESSAGES') + ' - ' + this.translate.instant('TRANSFER_STATUS'),
                  isMultiSelect: true,
                  options: []
                }
                viewData.options.push(option);
                this.accountTimelineFilterFormView.push(viewData);
              }
            }
          }
        });
      }
      if (this._allTimelineActivities.some(activity => activity.type == ActivityType.Sample)) {
        filterFormViewMain.options.push({ id: 'sample-main', group: ActivityType.Sample, isSelected: false, displayName: this.translate.instant('ALLOWCATION_ORDERS') });
        let sampleActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.Sample);
        sampleActs.forEach((act) => {
          //address
          if (!_.isEmpty(act.location)) {
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'sample-address');
            let option = {
              group: this.translate.instant('ADDRESS'),
              id: ActivityType.Sample + '_' + act.addressID,
              displayName: act.location,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Sample + '_' + act.addressID);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'sample-address',
                parentId: ActivityType.Sample,
                displayName: this.translate.instant('ALLOWCATION_ORDERS') + ' - ' + this.translate.instant('ADDRESS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
        });
      }
      if (this._allTimelineActivities.some(activity => activity.type == ActivityType.CaseIntake)) {
        filterFormViewMain.options.push({ id: 'customerInquiries-main', group: ActivityType.CaseIntake, isSelected: false, displayName: this.translate.instant('CUSTOMER_INQUIRIES', { globalCustomerText: this.utilityService.globalCustomerText }) });
        let caseIntakeActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.CaseIntake);
        caseIntakeActs.forEach((act) => {
          //status
          if (!_.isEmpty(act._case_status_value)) {
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'caseIntake-status');
            let option = {
              group: this.translate.instant('STATUS'),
              id: ActivityType.CaseIntake + '_' + act._case_status_value,
              displayName: act._case_status_value,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CaseIntake + '_' + act._case_status_value);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'caseIntake-status',
                parentId: ActivityType.CaseIntake,
                displayName: this.translate.instant('INQUIRY') + ' - ' + this.translate.instant('STATUS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
          //stage
          if (!_.isEmpty(act._case_stage_value)) {
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'caseIntake-stage');
            let option = {
              group: this.translate.instant('STAGE'),
              id: ActivityType.CaseIntake + '_' + act._case_stage_value,
              displayName: act._case_stage_value,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CaseIntake + '_' + act._case_stage_value);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'caseIntake-stage',
                parentId: ActivityType.CaseIntake,
                displayName: this.translate.instant('INQUIRY') + ' - ' + this.translate.instant('STAGE'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
          //assign to
          if (!_.isEmpty(act.meetingOwnerId)) {
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'caseIntake-assigned-to');
            let option = {
              group: this.translate.instant('ASSIGNED_TO'),
              id: ActivityType.CaseIntake + '_' + act.meetingOwnerId,
              displayName: act.meetingOwnerName,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CaseIntake + '_' + act.meetingOwnerId);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'caseIntake-assigned-to',
                parentId: ActivityType.CaseIntake,
                displayName: this.translate.instant('INQUIRY') + ' - ' + this.translate.instant('ASSIGNED_TO'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
          //Products
          if (!_.isEmpty(act._case_trans_product_name) && act._case_trans_product_name != 'Product') {
            const idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'caseIntake-product');
            let option = {
              group: this.translate.instant('PRODUCT'),
              id: ActivityType.CaseIntake + '_' + act._case_trans_product_name,
              displayName: act._case_trans_product_name,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CaseIntake + '_' + act._case_trans_product_name);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'caseIntake-product',
                parentId: ActivityType.CaseIntake,
                displayName: this.translate.instant('INQUIRY') + ' - ' + this.translate.instant('PRODUCT'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
        });
      }
      if (this._allTimelineActivities.some(activity => activity.type == ActivityType.Event)) {
        filterFormViewMain.options.push({ id: 'event-main', group: ActivityType.Event, isSelected: false, displayName: this.translate.instant('EVENTS') });
        let eventActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.Event);
        eventActs.forEach((act) => {
          //status
          if (!_.isEmpty(act.createdby) || !_.isEmpty(act.completedby) || (_.isEmpty(act.registrationresponseId) && !_.isEmpty(act.eventRegistrationId) && !_.isEmpty(act.registeredBy)) || (!_.isEmpty(act.registrationresponseId) && !_.isEmpty(act.indskr_ownername))) {
            let formattedStatus: string = '';
            if (!_.isEmpty(act.completedby)) {
              formattedStatus = act.completedby == this.userName ? this.translate.instant('EVENT_COMPLETED_BY_ME') : this.translate.instant('EVENT_COMPLETED_BY_OWNER', { text: act.completedby });
            } else if (!_.isEmpty(act.createdby)) {
              formattedStatus = act.completedby == this.userName ? this.translate.instant('EVENT_CHECKED_BY_ME') : this.translate.instant('EVENT_CHECKED_BY_OWNER', { text: act.createdby });
            }
            if (this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_REGISTRATION) || this.authenticationService.user.eventTimeLine) {
              if (_.isEmpty(act.registrationresponseId) && !_.isEmpty(act.eventRegistrationId) && !_.isEmpty(act.registeredBy)) {
                formattedStatus = act.registeredBy == this.userName ? this.translate.instant('CONTACT_REGISTERED_BY_ME') : this.translate.instant('CONTACT_REGISTERED_BY_OWNER', { text: act.registeredBy });
              } else if (!_.isEmpty(act.registrationresponseId) && !_.isEmpty(act.indskr_ownername)) {
                formattedStatus = act.indskr_ownername == this.userName ? this.translate.instant('CONTACT_SIGNEDUP_BY_ME') : this.translate.instant('CONTACT_SIGNEDUP_BY_OWNER', { text: act.indskr_ownername });
              }
            }
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'event-status');
            let option = {
              group: this.translate.instant('STATUS'),
              id: ActivityType.Event + '_' + formattedStatus,
              displayName: formattedStatus,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Event + '_' + formattedStatus);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'event-status',
                parentId: ActivityType.Event,
                displayName: this.translate.instant('Event') + ' - ' + this.translate.instant('STATUS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
        });
      }
      if (this._allTimelineActivities.some(activity => (activity.type == ActivityType.Appointment && activity.location === 'LiveMeet'))) {
        filterFormViewMain.options.push({ id: 'liveMeeting-main', group: ActivityType.LiveMeet, isSelected: false, displayName: this.translate.instant('LIVE_MEETING') });
        let liveMeetingActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.Appointment && activity.location == 'LiveMeet');
        liveMeetingActs.forEach((act) => {
          //status
          if (!_.isEmpty(act.statusString)) {
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'liveMeeting-status');
            let option = {
              group: this.translate.instant('STATUS'),
              id: ActivityType.LiveMeet + '_' + act.statusString,
              displayName: act.statusString,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.LiveMeet + '_' + act.statusString);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'liveMeeting-status',
                parentId: ActivityType.LiveMeet,
                displayName: this.translate.instant('LIVE_MEETING') + ' - ' + this.translate.instant('STATUS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
          //Products
          if (!_.isEmpty(act.products)) {
            act.products.forEach(product => {
              const idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'liveMeeting-product');
              let option = {
                group: this.translate.instant('PRODUCT'),
                id: ActivityType.LiveMeet + '_' + product.ID,
                displayName: product.name,
                isSelected: false,
              }
              if (idx > -1) {
                let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.LiveMeet + '_' + product.ID);
                if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
              } else {
                let viewData = {
                  id: 'liveMeeting-product',
                  parentId: ActivityType.LiveMeet,
                  displayName: this.translate.instant('LIVE_MEETING') + ' - ' + this.translate.instant('PRODUCT'),
                  isMultiSelect: true,
                  options: []
                }
                viewData.options.push(option);
                this.accountTimelineFilterFormView.push(viewData);
              }
            })
          }
        });
      }
      if (this._allTimelineActivities.some(activity => activity.type == ActivityType.Order)) {
        filterFormViewMain.options.push({ id: 'salesOrder-main', group: ActivityType.Order, isSelected: false, displayName: this.translate.instant('ACCOUNT_TIMELINE_OPTION_ORDERS') });
        let salesOrderActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.Order);
        salesOrderActs.forEach((act) => {
          //status
          if (!_.isEmpty(act.statusString)) {
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'salesOrder-status');
            let option = {
              group: this.translate.instant('STATUS'),
              id: ActivityType.Order + '_' + act.statusString,
              displayName: act.statusString,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Order + '_' + act.statusString);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'salesOrder-status',
                parentId: ActivityType.Order,
                displayName: this.translate.instant('ACCOUNT_TIMELINE_OPTION_ORDERS') + ' - ' + this.translate.instant('STATUS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
          //Owner
          if (!_.isEmpty(act.ownerId) && !_.isEmpty(act.ownerNameString)) {
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'salesOrder-owner');
            let option = {
              group: this.translate.instant('OM_OWNER'),
              id: ActivityType.Order + '_' + act.ownerId,
              displayName: act.ownerNameString,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Order + '_' + act.ownerId);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'salesOrder-owner',
                parentId: ActivityType.Order,
                displayName: this.translate.instant('ACCOUNT_TIMELINE_OPTION_ORDERS') + ' - ' + this.translate.instant('OM_OWNER'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
          //Products
          if (!_.isEmpty(act.products)) {
            act.products.forEach(product => {
              const idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'salesOrder-product');
              let option = {
                group: this.translate.instant('PRODUCT'),
                id: ActivityType.Order + '_' + product.productId,
                displayName: product.productName,
                isSelected: false,
              }
              if (idx > -1) {
                let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Order + '_' + product.productId);
                if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
              } else {
                let viewData = {
                  id: 'salesOrder-product',
                  parentId: ActivityType.Order,
                  displayName: this.translate.instant('ACCOUNT_TIMELINE_OPTION_ORDERS') + ' - ' + this.translate.instant('PRODUCT'),
                  isMultiSelect: true,
                  options: []
                }
                viewData.options.push(option);
                this.accountTimelineFilterFormView.push(viewData);
              }
            })
          }
        });
      }
      if (this._allTimelineActivities.some(activity => (activity.type == ActivityType.PhoneCall))) {
        filterFormViewMain.options.push({ id: 'phoneCall-main', group: ActivityType.PhoneCall, isSelected: false, displayName: this.translate.instant('PHONE_CALLS') });
        let phoneCallActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.PhoneCall);
        phoneCallActs.forEach((act) => {
          //phone number
          if (!_.isEmpty(act.phonecallphonenumber)) {
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'phoneCall-phoneNumber');
            let option = {
              group: this.translate.instant('PHONE_NUMBER'),
              id: ActivityType.PhoneCall + '_' + act.phonecallphonenumber,
              displayName: act.phonecallphonenumber,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.PhoneCall + '_' + act.phonecallphonenumber);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'phoneCall-phoneNumber',
                parentId: ActivityType.PhoneCall,
                displayName: this.translate.instant('PHONE_CALLS') + ' - ' + this.translate.instant('PHONE_NUMBER'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
          //Products
          if (!_.isEmpty(act.products)) {
            act.products.forEach(product => {
              const idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'phoneCall-product');
              let option = {
                group: this.translate.instant('PRODUCT'),
                id: ActivityType.PhoneCall + '_' + product.ID,
                displayName: product.name,
                isSelected: false,
              }
              if (idx > -1) {
                let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.PhoneCall + '_' + product.ID);
                if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
              } else {
                let viewData = {
                  id: 'phoneCall-product',
                  parentId: ActivityType.PhoneCall,
                  displayName: this.translate.instant('PHONE_CALLS') + ' - ' + this.translate.instant('PRODUCT'),
                  isMultiSelect: true,
                  options: []
                }
                viewData.options.push(option);
                this.accountTimelineFilterFormView.push(viewData);
              }
            })
          }
        });
      }
      if (this._allTimelineActivities.some(activity => (activity.type == ActivityType.SurgeryOrder))) {
        filterFormViewMain.options.push({ id: 'surgeryOrder-main', group: ActivityType.SurgeryOrder, isSelected: false, displayName: this.translate.instant('PROCEDURE_LOGS') });
        let surgeryOrderActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.SurgeryOrder);
        surgeryOrderActs.forEach((act) => {
          //Owner
          if (!_.isEmpty(act.ownerId) && !_.isEmpty(act.ownerNameString)) {
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'surgeryOrder-owner');
            let option = {
              group: this.translate.instant('OM_OWNER'),
              id: ActivityType.SurgeryOrder + '_' + act.ownerId,
              displayName: act.ownerNameString,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.SurgeryOrder + '_' + act.ownerId);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'surgeryOrder-owner',
                parentId: ActivityType.SurgeryOrder,
                displayName: this.translate.instant('PROCEDURE_LOGS') + ' - ' + this.translate.instant('OM_OWNER'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
          //Products
          if (!_.isEmpty(act.products)) {
            act.products.forEach(product => {
              const idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'surgeryOrder-product');
              let option = {
                group: this.translate.instant('PRODUCT'),
                id: ActivityType.SurgeryOrder + '_' + product.productId,
                displayName: product.productName,
                isSelected: false,
              }
              if (idx > -1) {
                let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.SurgeryOrder + '_' + product.productId);
                if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
              } else {
                let viewData = {
                  id: 'surgeryOrder-product',
                  parentId: ActivityType.SurgeryOrder,
                  displayName: this.translate.instant('PROCEDURE_LOGS') + ' - ' + this.translate.instant('PRODUCT'),
                  isMultiSelect: true,
                  options: []
                }
                viewData.options.push(option);
                this.accountTimelineFilterFormView.push(viewData);
              }
            })
          }
        });
      }
      if (this._allTimelineActivities.some(activity => (activity.type == ActivityType.CustomerSurvey))) {
        filterFormViewMain.options.push({ id: 'customerSurvey-main', group: ActivityType.CustomerSurvey, isSelected: false, displayName: this.translate.instant('SURVEY') });
        let surveyActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.CustomerSurvey);
        surveyActs.forEach((act) => {
          //Survey type
          if (!_.isEmpty(act.type)) {
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'customerSurvey-surveyType');
            let option = {
              group: this.translate.instant('SURVEY_TYPE'),
              id: ActivityType.CustomerSurvey + '_' + act.type,
              displayName: act.type == ActivityType.CustomerSurvey ? this.translate.instant('SURVEY_ACCOUNT_SURVEY') : act.type,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CustomerSurvey + '_' + act.type);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'customerSurvey-surveyType',
                parentId: ActivityType.CustomerSurvey,
                displayName: this.translate.instant('SURVEY') + ' - ' + this.translate.instant('SURVEY_TYPE'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
          //Type
          if (!_.isEmpty(act.surveyFrequency)) {
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'customerSurvey-surveyFrequency');
            let option = {
              group: this.translate.instant('TYPE'),
              id: ActivityType.CustomerSurvey + '_' + act.surveyFrequency,
              displayName: act.surveyFrequency,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CustomerSurvey + '_' + act.surveyFrequency);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'customerSurvey-surveyFrequency',
                parentId: ActivityType.CustomerSurvey,
                displayName: this.translate.instant('SURVEY') + ' - ' + this.translate.instant('TYPE'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
          //Surveyed By
          if (!_.isEmpty(act.surveyedBy)) {
            let idx = this.accountTimelineFilterFormView.findIndex((view) => view.id == 'customerSurvey-surveyedBy');
            let option = {
              group: this.translate.instant('SURVEYED_BY'),
              id: ActivityType.CustomerSurvey + '_' + act.surveyedBy,
              displayName: act.surveyedBy,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.accountTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CustomerSurvey + '_' + act.surveyedBy);
              if (optionIdx < 0) this.accountTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'customerSurvey-surveyedBy',
                parentId: ActivityType.CustomerSurvey,
                displayName: this.translate.instant('SURVEY') + ' - ' + this.translate.instant('SURVEYED_BY'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.accountTimelineFilterFormView.push(viewData);
            }
          }
        });
      }
    }
  }

  initList() {
    this._allTimelineActivities = [];
    this._filteredTimelineActivities = [];
    if (!_.isEmpty(this.accountOfflineService?.selected)) {

      this._getTimelineActivities();
      this._initFilterOptions();  // filter on the sub section title
      this._initFilterFormViews(); // for the filter button

      if (this.accountOfflineService.isOpenedSurveyTimelineFromAccountProfile && !_.isEmpty(this.filterOptions)) {
        this.filterOptions.map(option => {
          option.isSelected = option.value == ActivityType.CustomerSurvey;
        });
        this.accountTimelineFilter = ActivityType.CustomerSurvey;
      }
      this.onTimelineTypeChange(this.accountTimelineFilter);

      this.authenticationService.getOfflineUser().then((user) => {
        if (user) {
          this.userName = user.displayName;
        }
        return;
      });
      //Filter pre-selection based on survey, filter menu modal is not implemented yet
      let refDataFilterMenuList = this.searchConfigService.timelineFilterMenuList;
      if (!_.isEmpty(this.accountOfflineService.selectedActivitiesFromAccountFilter) && !_.isEmpty(refDataFilterMenuList)) {
        this.isTimelineFiltered = true;
        let targetTimelineActivities = _.cloneDeep(this._allTimelineActivities);
        let activities = [];
        let filterBadgeCount: number = 0;
        let isSelectedMeetingStatus: boolean = false;

        this.accountOfflineService.selectedActivitiesFromAccountFilter.forEach(mainFilterValue => {
          //Update the value of the selected Activity displayed in the timeline filter menu(RHS)
          let foundFilterActivityValue = refDataFilterMenuList.find(d => d.category == mainFilterValue);
          if (!_.isEmpty(foundFilterActivityValue) && !this.selectedFilters.some(d => d.category == mainFilterValue)) {
            this.selectedFilters.push(foundFilterActivityValue);
          }
          if (!_.isEmpty(this.accountTimelineFilterFormView)) {
            this.accountTimelineFilterFormView.map(d => {
              if (d.id == "filter-main" && !_.isEmpty(d.options)) {
                let idx = d.options.findIndex(o => o.group == mainFilterValue);
                if (idx > -1) {
                  d.options[idx].isSelected = true;
                  filterBadgeCount++;
                }
              }
            });
          }
          //Update Filtered activities
          targetTimelineActivities.forEach(activity => {
            if (mainFilterValue == ActivityType.LiveMeet && activity.type == ActivityType.Appointment && activity.location == "LiveMeet") {
              activities.push(activity);
            } else if (mainFilterValue == ActivityType.Appointment && activity.type == ActivityType.Appointment && activity.location != "LiveMeet") {
              //Check Meeting activity option value
              if (!_.isEmpty(this.accountOfflineService.selectedSubOptionsFromAccountFilter.meetingStatus)) {
                if (this.accountOfflineService.selectedSubOptionsFromAccountFilter.meetingStatus.includes(activity.status)) {
                  //Update the value of the selected Option displayed in the timeline filter menu(RHS)
                  let formattedOptionId = ActivityType.Appointment + '_' + activity.statusString;
                  let foundFilterOptionValue = refDataFilterMenuList.find(d => d.parentId == mainFilterValue && d.id == formattedOptionId);
                  if (!_.isEmpty(foundFilterOptionValue) && !this.selectedFilters.some(d => d.id == formattedOptionId)) {
                    this.selectedFilters.push(foundFilterOptionValue);
                  }
                  if (!_.isEmpty(this.accountTimelineFilterFormView)) {
                    this.accountTimelineFilterFormView.map(d => {
                      if (d.id == "appointment-status" && !_.isEmpty(d.options)) {
                        let idx = d.options.findIndex(o => o.id == formattedOptionId);
                        if (idx > -1) {
                          d.options[idx].isSelected = true;
                          isSelectedMeetingStatus = true;
                        }
                      }
                    });
                  }
                  activities.push(activity);
                }
              } else {
                activities.push(activity);
              }
            } else if (mainFilterValue == activity.type) {
              activities.push(activity);
            }
          });
        });
        //Update filter badge count from selected option
        if (isSelectedMeetingStatus) {
          let idx = this.accountTimelineFilterFormView.findIndex(d => d.id == "appointment-status");
          if (idx > -1) {
            this.accountTimelineFilterFormView[idx].options.forEach(o => {
              if (o.isSelected) filterBadgeCount++;
            });
          }
        }

        targetTimelineActivities = activities;
        this.filterButtonBadgeCount = filterBadgeCount;
        /******* grouped filtered activities *******/
        this._allTimelineActivities = targetTimelineActivities
        this._filteredTimelineActivities = this._allTimelineActivities;
        this._initTimelineItemsViewData();
        console.log("Applied filter pre-selection");
      }
    } else {
      this._allTimelineActivities = [];
      this._filteredTimelineActivities = [];
      this._initTimelineItemsViewData();
    }
  }

  public async openSubmittedSurveyDetails(survey: any) {
    if (this.device.isOffline) {
      this.notifiationService.notify(this.translate.instant("SURVEY_ACTIVITIES_NOTIFY_ONLINE"), "contact-timeline", "top", ToastStyle.DANGER, 3000, true);
      return;
    }
    //check selected survey tempalte id
    const filteredSurveyList = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY);
    const templateId: string = survey['templateId'];
    const foundTemplate: AssessmentTemplate = filteredSurveyList.find(survey => survey.indskr_assessmenttemplateid == templateId);
    this.customerSurveyService.setCurrentSurvey(foundTemplate);
    try {
      await this.navService.pushChildNavPageWithPageTracking(SurveyDetailsComponent, PageName.SurveyDetailsComponent, PageName.AccountDetailsComponent, { appointmentId: '', from: PageName.AccountDetailsComponent }).then(async () => {
        this.navService.setChildNavRightPaneView(true);
        this.uiService.dismissLoader();
        this.customerSurveyService.selectedAccountForSurvey = this.accountOfflineService.selected;
        try {
          //map survey based on survey responses
          this.uiService.displayLoader();
          const responseData = await this.customerAssessService.fetchContactAccountSurveyForDetails(survey, SurveyCategory.ACCOUNT);
          if (!_.isEmpty(responseData)) {
            const formattedSurvey: CustomerAssessment = await this.customerAssessService.mapCustomerSurveyRespForTimeline(responseData);
            if (!_.isEmpty(formattedSurvey)) {
              this.customerSurveyService.setSurveyPreviewMode(true, formattedSurvey, 'timeline');
            }
          }
          this.uiService.dismissLoader();
        } catch (error) {
          console.log("Error open survey details from surve-timeline ", error);
          this.uiService.dismissLoader();
        }
      });
    } catch (error) {
      console.log("failed to open survey ", error);
      this.uiService.dismissLoader();
    }

  }

  private setupAssetManagementFilterOptions() {

  }

  private _handleAssetManagementSectionFilter(event) {

  }

}
