import {
  MDMType,
  ChangeRequestStatusCode,
  ChangeRequestType,
  MDMOptionSet,
  MDMRequestType,
  SourceTypeOptionset,
  ChangeRequestStateCode
} from "@omni/classes/mdm/source-type-optionset.class";
import {MdmDataService} from "@omni/data-services/mdm/mdm.data.service";
import {Injectable, NgZone} from "@angular/core";
import {EventName, EventsService} from "@omni/services/events/events.service";
import {AuthenticationService} from "@omni/services/authentication.service";
import {FeatureActionsMap} from "@omni/classes/authentication/user.class";
import {DeviceService} from "@omni/services/device/device.service";
import {DB_ALLDOCS_QUERY_OPTIONS, DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS} from "@omni/config/pouch-db.config";
import {DiskService} from "@omni/services/disk/disk.service";
import {DeltaService, EntityNames, EntitySyncInfo} from "@omni/data-services/delta/delta.service";
import {Endpoints} from "../../../config/endpoints.config";
import {BehaviorSubject} from "rxjs";
import _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {Control, DynamicForm, FormType, BusinessProcessType, OneCRMGender} from "@omni/classes/dynamic-form/dynamic-form.class";
import {DynamicFormType} from "@omni/models/dynamic-form-component.model";
import {DynamicFormsService} from "@omni/services/dynamic-forms/dynamic-forms-service";
import {fetchQueries} from "@omni/config/dynamics-fetchQueries";
import * as XML2JS from 'xml2js';
import {RequiredContactCRAttributes} from "@omni/config/dynamic-forms/default-contact/default-contact-create";
import {differenceInHours, format, isBefore} from "date-fns";
import {
  ACCOUNT_CONTACT_AFFILIATION_BY_MDMID,
  FETCH_CONTACT_CR_LINK_ENTITIES
} from '@omni/config/fetch-xml/contact-fetchXMLs';
import {
  FETCH_ACCOUNT_CR_LINK_ENTITIES,
  RequiredAccountCRAttributes
} from '@omni/config/dynamic-forms/default-account-display';
import {DynamicsClientService} from "@omni/data-services/dynamics-client/dynamics-client.service";
import {MyAssistantService, NOTIFICATION} from "@omni/services/my-assistant/my-assistant.service";
import {DateTimeFormatsService} from "@omni/services/date-time-formats/date-time-formats.service";
import {GlobalUtilityService} from "@omni/services/global-utility.service";
import {IntegrationType} from "@omni/classes/onekey/ok-integration.class";
import {
  LinkedEntity, OKEntityType,
  OKVRType, OKWorkplaceSearchKeys,
  REQUEST_PROCESS,
  VALIDATION_PROCESS,
  VRProcess
} from '@omni/classes/onekey/ok-searchkeys.class';
import {OnekeyApiDataService} from "@omni/data-services/onekey/onekey-api.data.service";
import {IndNotificationDataModel} from "@omni/models/indNotificationDataModel";
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { LocalizationService } from '../localization/localization.service';
import { ContactOfflineService } from "../contact/contact.service";

@Injectable({
  providedIn: 'root'
})
export class MdmService {

  public sourceTypeOptionSet: SourceTypeOptionset[] = [];
  public sourceTypeResponse: any;
  public mdmResponse: any;
  public mdmOptionSet: MDMOptionSet[] = [];
  public contactCRs = new BehaviorSubject<any[]>([]);
  contactCRsObs$ = this.contactCRs.asObservable();
  public accountCRs = new BehaviorSubject<any[]>([]);
  accountCRsObs$ = this.accountCRs.asObservable();
  public currentSelected = new BehaviorSubject<any>(undefined);
  currentSelectedObs$ = this.currentSelected.asObservable();

  public chnagerequestApproved$ = new BehaviorSubject<boolean>(false);

  public contactCRList: any[] = [];
  public accountCRList: any[] = [];
  private linkEntitiesReqPayload: any = [];
  public contactLinkEntities: Array<string> = [];
  public accountLinkEntities: Array<string> = [];
  private _defaultLinkedEntityMappingData:Array<any> = [];
  private _defaultLinkedEntityDataMappingServiceWorker;
  private TYPE: string = "";
  private cRNotificationModel: IndNotificationDataModel;


  constructor(
    private authService: AuthenticationService,
    private dynamicFormsService: DynamicFormsService,
    private events: EventsService,
    public mdmDataService: MdmDataService,
    public deviceService: DeviceService,
    public diskService: DiskService,
    private ngZone: NgZone,
    private deltaService: DeltaService,
    private translate: TranslateService,
    private dynamics: DynamicsClientService,
    private myAssistantService: MyAssistantService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public utilityService:GlobalUtilityService,
    public okDataService: OnekeyApiDataService,
    public accountOfflineService: AccountOfflineService,
    public languageService:LocalizationService,
    public contactService: ContactOfflineService,
  ) {
    // this._initDefaultLinkedEntityDataMappingServiceWorker();
    this.events
      .observe("sync:completed")
      .subscribe(() => {
        this.mapSourceTypeOptionset();
        this.loadMapMDMDataFromDB();
      });
  }

  private _initDefaultLinkedEntityDataMappingServiceWorker() {
    this._defaultLinkedEntityDataMappingServiceWorker = new Worker('./assets/workers/contact-linked-entity-mapping-worker.js');
    this._defaultLinkedEntityDataMappingServiceWorker.onmessage = (event) => {
      this.ngZone.run(() => {
        if(event && event.data && Array.isArray(event.data) && event.data.length != 0){
          event.data.forEach(item => {
            if(item.hasOwnProperty('indskr_contactcrid')){
              let idx = this.contactCRList.findIndex(con => con.indskr_contactcrid == item['indskr_contactcrid']);
              if(idx >= 0){
                if(item.hasOwnProperty('emailAddressList')){
                  this.contactCRList[idx].emailAddressList = item['emailAddressList'];
                }
                if(item.hasOwnProperty('addressesList')){
                  this.contactCRList[idx].addressesList = item['addressesList'];
                }
                if(item.hasOwnProperty('accountRelationships')){
                  this.contactCRList[idx].accountRelationships = item['accountRelationships'];
                }
                /*if(this.contactInformation && this.contactInformation.ID == item['contactid']){
                  this.contactInformation = contactCRs[idx];
                }*/
              }
            }
          })
          // this.contactCRs.next(this.contactCRList);
          this.events.publish(EventName.FINISHEDSERVICEWORKERMAPPING);
        }
      })
    };
  }

  public addLinkEntities(linkEntity) {
    if (_.isEmpty(linkEntity))
      this.linkEntitiesReqPayload = [];
    else {
      const linkEntityPayload = {
        "values": linkEntity["values"],
        "entity": linkEntity["entity"]
      }
      this.linkEntitiesReqPayload.push(linkEntityPayload);
    }
  }

  public deleteLinkEntities(entityName) {
    if (!_.isEmpty(this.linkEntitiesReqPayload)) {
      let idx = this.linkEntitiesReqPayload.findIndex(pl => pl.entity == entityName);
      if (idx >= 0) {
        this.linkEntitiesReqPayload.splice(idx,1);
      }
    }
  }

  public getReqPayloadLinkEntities(): [] {
    return this.linkEntitiesReqPayload;
  }

  public getStatusText(status) {
    let statusText = ''
    switch (status) {
      case ChangeRequestStatusCode.OPEN:
        statusText = this.translate.instant('CR_STATUS_OPEN');
        break;
      case ChangeRequestStatusCode.IN_PROGRESS:
        statusText = this.translate.instant('IN_PROCESS');
        break;
      case ChangeRequestStatusCode.SUBMITTED:
        statusText = this.translate.instant('SUBMITTED');
        break;
      case ChangeRequestStatusCode.APPROVED:
        statusText = this.translate.instant('APPROVED');
        break;
      case ChangeRequestStatusCode.REJECTED:
        statusText = this.translate.instant('REJECTED');
        break;
      case ChangeRequestStatusCode.PENDING_APPROVAL:
        statusText = this.translate.instant('PENDING_APPROVAL');
        break;
      case ChangeRequestStatusCode.FAILED:
        statusText = this.translate.instant('FAILED');
        break;
    }
    return statusText;
  }

  async getSourceTypeOptions(loadFromDBOnly = false) {
    try {
      let offlineFallback: boolean = this.deviceService.isOffline || loadFromDBOnly;
      if (offlineFallback) {
        await this.loadSourceTypeOptionsFromDB();
        console.log('fetching source type options from db');
      }
      else {
        this.sourceTypeResponse = await this.mdmDataService.getSourceTypeOptionsets();
        await this.diskService.updateOrInsert(DB_KEY_PREFIXES.SOURCE_TYPE_OPTIONS, doc => {
          doc = {
            raw: this.sourceTypeResponse
          };
          return doc;
        });
        this.mdmResponse = await this.mdmDataService.getMDMOptionsets();
        await this.diskService.updateOrInsert(DB_KEY_PREFIXES.MDM_OPTIONS, doc => {
          doc = {
            raw: this.mdmResponse
          };
          return doc;
        });
      }
    } catch (error) {
      console.log('MDM service failed', error)
    }
  }

  private async loadSourceTypeOptionsFromDB() {
    await this.loadMDMOptionsFromDB();
    console.log('fetching mdm options from db');
    await this.diskService.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_SOURCE_TYPE_OPTIONS).then((data: any[]) => {
      try {
        if (Array.isArray(data) && data[0] && data[0]['raw']) {
          this.sourceTypeResponse = data[0]['raw'];
          this.mapSourceTypeOptionset();
          console.log(`source type options from disk : ${data[0]['raw'] ? data[0]['raw'].length : 0}`);
        }
      } catch (e) {
        console.log("error occurred while fetching source type options from db: ", e);
      }
    });
  }

  private async loadMDMOptionsFromDB() {
    await this.diskService.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_MDM_OPTIONS).then((data: any[]) => {
      try {
        if (Array.isArray(data) && data[0] && data[0]['raw']) {
          this.mdmResponse = data[0]['raw'];
          console.log(`mdm options from disk : ${data[0]['raw'] ? data[0]['raw'].length : 0}`);
        }
      } catch (e) {
        console.log("error occurred while fetching mdm options from db: ", e);
      }
    });
  }

  mapSourceTypeOptionset() {
    this.sourceTypeOptionSet = [];
    if (this.sourceTypeResponse && this.sourceTypeResponse['Options'] && Array.isArray(this.sourceTypeResponse['Options'])) {
      this.sourceTypeResponse['Options'].map(item => {

        //Default label - english
        let calcLocalizedLabel = 'Business';

        if (item['Value'] == ChangeRequestType.BUSINESS) {
          calcLocalizedLabel = this.translate.instant('BUSINESS');
        }

        let sourceOptionSet: SourceTypeOptionset = new SourceTypeOptionset(item['Value'], calcLocalizedLabel, []);
        if (item['Value'] == ChangeRequestType.MDM) {
          this.mapMdmOptionset();
          sourceOptionSet.mdmOptionSet.push(...this.mdmOptionSet);
        }
        this.sourceTypeOptionSet.push(sourceOptionSet);
      });
    }
  }

  mapMdmOptionset() {
    this.mdmOptionSet = [];
    if (this.mdmResponse && this.mdmResponse['Options'] && Array.isArray(this.mdmResponse['Options'])) {
      this.mdmResponse['Options'].map(item => {
        let categoriesOptionSet: MDMOptionSet = new MDMOptionSet(item['Value'], item['Label']['LocalizedLabels'][0].Label);
        this.mdmOptionSet.push(categoriesOptionSet);
      });
    }
  }

  translateSourceTypeOptionSet(label: string): string {
    let translatedLabel: string = label;
    switch(label) {
      case 'Business':
        translatedLabel = this.translate.instant('BUSINESS');
        break;
      case 'MDM':
        translatedLabel = this.translate.instant('AFFILIATION_MDM');
        break;
      default:
        console.log('need to check source type option set');
    }
    return translatedLabel;
  }

  public async syncMDMMasterData(dataRange: { from: string, to: string }, loadFromDbOnly = false) {
    if (this.authService.hasFeatureAction(FeatureActionsMap.CONTACTS_CREATE_REQUEST) || this.authService.hasFeatureAction(FeatureActionsMap.CONTACTS_EDIT_REQUEST) || this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_CHANGE_REQUESTS)) {
      await this.syncContactChangeRequests(dataRange, loadFromDbOnly);
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_CREATE_REQUEST) || this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_EDIT_REQUEST) || this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_CHANGE_REQUESTS)) {
      await this.syncAccountChangeRequests(dataRange, loadFromDbOnly);
    }
  }

  private async syncContactChangeRequests(dataRange: { from: string, to: string }, loadFromDbOnly = false) {
    let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
    if (offlineFallback) {
      await this.loadContactCRFromDB();
      console.log("load CCRs from DB");
    }
    else {
      const syncState = await this.diskService.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CONTACT_CR);
      const isInitialSync = !syncState || !syncState.lastUpdatedTime;
      /*const contactCRSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.contactCR,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };*/
      if (isInitialSync) {
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACT_CR);
      }
      /*let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.GET_CONTACT_CRS
        .replace('{startDate}', dataRange.from);
      url = isInitialSync ? url : url + '&lastUpdatedTime=' + syncState.lastUpdatedTime;*/
      await this.fetchContactCRConfiguredDisplay(isInitialSync, loadFromDbOnly);
      /*await this.mdmDataService.getContactChangeRequests(url).then(
        async (data: any) => {
          const newLastUpdatedTime = new Date().getTime().toString();
          console.log("Saving Contact CR's to disk...");
          if (isInitialSync) {
            await this.mapFullSyncedContactChangeRequests(data, newLastUpdatedTime);
          }
          else {
            await this.mapDeltaSyncedContactChangeRequests(data, newLastUpdatedTime);
          }
          syncState.lastUpdatedTime = newLastUpdatedTime;
          await this.diskService.updateSyncState(syncState);
          if (Array.isArray(data)) {
            contactCRSyncInfo.totalSynced = data.length;
          }
          this.deltaService.addEntitySyncInfo(contactCRSyncInfo);
          console.log(`Sync status : ${JSON.stringify(syncState)} initial sync : ${isInitialSync}`);
        }
      ).catch(err => {
        console.error("Error occurred while fetching contact CRs..." + err)
        this.deltaService.addSyncErrorToEntitySyncInfo(contactCRSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.GET_CONTACT_CRS, err);
        this.deltaService.addEntitySyncInfo(contactCRSyncInfo);
      })*/
    }
  }

  private async syncAccountChangeRequests(dataRange: { from: string, to: string }, loadFromDbOnly = false) {
    let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
    if (offlineFallback) {
      await this.loadAccountCRFromDB();
      console.log("load CCRs from DB");
    }
    else {
      const syncState = await this.diskService.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ACCOUNT_CR);
      const isInitialSync = !syncState || !syncState.lastUpdatedTime;
      /*const accountCRSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.accountCR,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };*/
      if (isInitialSync) {
        await this.diskService.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACCOUNT_CR);
      }
      /*let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.GET_ACCOUNT_CRS
        .replace('{startDate}', dataRange.from);
      url = isInitialSync ? url : url + '&lastUpdatedTime=' + syncState.lastUpdatedTime;*/
      await this.fetchAccountCRConfiguredDisplay(isInitialSync, loadFromDbOnly);
      /*await this.mdmDataService.getAccountChangeRequests(url).then(
        async (data: any) => {
          const newLastUpdatedTime = new Date().getTime().toString();
          console.log("Saving Account CR's to disk...");
          if (isInitialSync) {
            await this.mapFullSyncedAccountChangeRequests(data, newLastUpdatedTime);
          }
          else {
            await this.mapDeltaSyncedAccountChangeRequests(data, newLastUpdatedTime);
          }
          syncState.lastUpdatedTime = newLastUpdatedTime;
          await this.diskService.updateSyncState(syncState);
          if (Array.isArray(data)) {
            accountCRSyncInfo.totalSynced = data.length;
          }
          this.deltaService.addEntitySyncInfo(accountCRSyncInfo);
          console.log(`Sync status : ${JSON.stringify(syncState)} initial sync : ${isInitialSync}`);
        }
      ).catch(err => {
        console.error("Error occurred while fetching account CRs..." + err)
        this.deltaService.addSyncErrorToEntitySyncInfo(accountCRSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.GET_ACCOUNT_CRS, err);
        this.deltaService.addEntitySyncInfo(accountCRSyncInfo);
      })*/
    }
  }

  /*private async mapFullSyncedContactChangeRequests(raw: any[], newLastUpdatedTime: string) {
    raw.forEach(data => {
      data._id = DB_KEY_PREFIXES.CONTACT_CR + data.indskr_contactcrid;
      data.lastUpdatedTime = newLastUpdatedTime;
    });
    await this.diskService.bulk(raw);
  }

  private async mapDeltaSyncedContactChangeRequests(rawData: any[], newLastUpdatedTime: string) {
    for (let data of rawData) {
      const key = DB_KEY_PREFIXES.CONTACT_CR + data.indskr_contactcrid;;
      data._id = key;
      await this.diskService.updateOrInsert(key, (doc) => { return data; });
    }
  }

  private async mapFullSyncedAccountChangeRequests(raw: any[], newLastUpdatedTime: string) {
    raw.forEach(data => {
      data._id = DB_KEY_PREFIXES.ACCOUNT_CR + data.indskr_accountcrid;
      data.lastUpdatedTime = newLastUpdatedTime;
    });
    await this.diskService.bulk(raw);
  }

  private async mapDeltaSyncedAccountChangeRequests(rawData: any[], newLastUpdatedTime: string) {
    for (let data of rawData) {
      const key = DB_KEY_PREFIXES.ACCOUNT_CR + data.indskr_accountcrid;
      data._id = key;
      await this.diskService.updateOrInsert(key, (doc) => { return data; });
    }
  }*/

  private loadMapMDMDataFromDB() {
    let contactCRFromDB = (this.authService.hasFeatureAction(FeatureActionsMap.CONTACTS_CREATE_REQUEST) || this.authService.hasFeatureAction(FeatureActionsMap.CONTACTS_EDIT_REQUEST) || this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_CHANGE_REQUESTS)) ? this.loadContactCRFromDB() : Promise.resolve();
    let accountCRFromDB = (this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_CREATE_REQUEST) || this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_EDIT_REQUEST) || this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_CHANGE_REQUESTS)) ? this.loadAccountCRFromDB() : Promise.resolve();
    Promise.all(
      [
        contactCRFromDB,
        accountCRFromDB
      ]
    ).then(() => {
      console.log("db fetching:completed");
    });
  }

  private async loadContactCRFromDB() {
    await this.diskService.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACT_CR).then((data: any[]) => {
      try {
        if (Array.isArray(data) && data[0] && data[0]['raw']) {
          this.contactCRs.next(data[0]['raw']);
          console.log(`contact CRs from disk : ${data[0]['raw'] ? data[0]['raw'].length : 0}`);
        }
      } catch (e) {
        console.log("error occurred while fetching contact CR from db: ", e);
      }
    });
  }

  private async loadAccountCRFromDB() {
    await this.diskService.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACCOUNT_CR).then((data: any[]) => {
      try {
        if (Array.isArray(data) && data[0] && data[0]['raw']) {
          this.accountCRs.next(data[0]['raw']);
          console.log(`account CRs from disk : ${data[0]['raw'] ? data[0]['raw'].length : 0}`);
        }
      } catch (e) {
        console.log("error occurred while fetching account CR from db: ", e);
      }
    });
  }

  public async createOneKeyContactCR(data: any) {
    //add mandatory fields
    data['indskr_mdm'] = data['indskr_mdm'] ? data['indskr_mdm'] : MDMType.ONEKEY;
    data['indskr_contactsourcetype'] = ChangeRequestType.MDM;
    data['statuscode'] = ChangeRequestStatusCode.IN_PROGRESS;
    data['statecode'] = ChangeRequestStateCode.INACTIVE;
    if (data['indskr_mdm'] == BusinessProcessType.SanofiChina) {
      data['statuscode'] = ChangeRequestStatusCode.OPEN;
      data['statecode'] = ChangeRequestStateCode.ACTIVE;
    }
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.CREATE_CONTACT_CR;
    return await this.mdmDataService.createChangeRequest(url, data).then(async (res) => {
      if (res) {
        data['indskr_contactcrid'] = res['indskr_contactcrid'];
        data['indskr_firstname'] = res['indskr_firstname'] ? res['indskr_firstname'] : data['indskr_firstname'] ? data['indskr_firstname'] : '';
        data['modifiedon'] = new Date().getTime();
        data['createdon'] = new Date().getTime();
        if (data['indskr_mdm'] != BusinessProcessType.SanofiChina) {
          data['statuscode'] = ChangeRequestStatusCode.SUBMITTED;
        }
        if (res['indskr_hcponeid']) data['indskr_hcponeid'] = res['indskr_hcponeid'];
        if (this.authService.okIntegrationSettings && this.authService.okIntegrationSettings.typeOfIntegration == IntegrationType.ONE_KEY) {
          await this.submitContactCRInOk(data);
        }

        await this.upsertContactCRInPouchDB(data);
        // await this.diskService.upsertContactCRs(data);
        let contactCRs = this.contactCRs.getValue();
        contactCRs.push(data);
        this.contactCRs.next(contactCRs);
        this.contactCRList = contactCRs;
        return res;
      }
    })
  }

  public async createBusinessContactDataCR(data: any) {
    //add mandatory fields
    data['indskr_contactsourcetype'] = BusinessProcessType.Omnipresence;
    //data['statuscode'] = 548910004;
    //data['statecode'] = 1;

    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.CREATE_CONTACT_CR;
    if (this.authService.user.customerAvailability) {
      url = url+'?customerAvailability=true';
    }
    return await this.mdmDataService.createChangeRequest(url, data).then(async (res) => {
      if (res) {
        data['indskr_contactcrid'] = res['indskr_contactcrid'];
        data['modifiedon'] = new Date().getTime();
        data['createdon'] = new Date().getTime();
        data['statecode'] = 1;
        data['statuscode'] = ChangeRequestStatusCode.OPEN;
        await this.upsertContactCRInPouchDB(data);
        // await this.diskService.upsertContactCRs(data);
        let contactCRs = this.contactCRs.getValue();
        contactCRs.push(data);
        this.contactCRs.next(contactCRs);
        return res;
      }
    })
  }

  public async editBusinessContactDataCR(data: any,contactcrid:string) {
    // add url
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.EDIT_CONTACT_CR;
    url = url.replace('{contactId}', contactcrid);
    return await this.mdmDataService.editChangeRequest(url, data).then(async (res) => {
      if (!res) {
        res = {
          'indskr_contactcrid': contactcrid
        }
      }
      data['indskr_contactcrid'] = res['indskr_contactcrid'];
      data['modifiedon'] = new Date().getTime();
      data['createdon'] = new Date().getTime();
      data['statuscode'] = ChangeRequestStatusCode.OPEN;
      await this.upsertContactCRInPouchDB(data);
      // await this.diskService.upsertContactCRs(data);
      let contactCRs = this.contactCRs.getValue();
      let idx;
      if (Array.isArray(contactCRs) && contactCRs.length) {
        idx = contactCRs.findIndex(a => a['indskr_contactcrid'] == data['indskr_contactcrid']);
      }
      if (idx >= 0) {
        contactCRs[idx] = data;
      } else {
        contactCRs.push(data);
      }
      this.contactCRs.next(contactCRs);
      return res;
    })
  }

  public async editBusinessAccountDataCR(data: any, crid: string) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.UPDATE_ACCOUNT_CR;
    url = url.replace('{accountId}', crid);
    return await this.mdmDataService.editChangeRequest(url, data).then(async (res) => {
      if (!res) {
        res = {
          'indskr_accountcrid': crid
        }
      }
      data['indskr_accountcrid'] = res['indskr_accountcrid'];
      data['modifiedon'] = new Date().getTime();
      data['createdon'] = new Date().getTime();
      data['statuscode'] = ChangeRequestStatusCode.OPEN;
      await this.upsertAccountCRInPouchDB(data);
      let accountCRs = this.accountCRs.getValue();
      let idx;
      if (Array.isArray(accountCRs) && accountCRs.length) {
        idx = accountCRs.findIndex(a => a['indskr_accountcrid'] == data['indskr_accountcrid']);
      }
      if (idx >= 0) {
        accountCRs[idx] = data;
      } else {
        accountCRs.push(data);
      }
      this.accountCRs.next(accountCRs);
      return res;
    })
  }

  public async scrapBusinessContactDataCR(data:any, contactcrid:string,) {
    // add url
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.SCRAP_CONTACT_CR;
    url = url.replace('{contactId}', contactcrid);

    // scrap
    return await this.mdmDataService.scrapChangeRequest(url, data).then(async (res) => {
      if (!res) {
        res = {
          'indskr_contactcrid': contactcrid
        }
      };

      await this.deleteContactCRInPouchDB(res['indskr_contactcrid']);
      let contactCRs = this.contactCRs.getValue();
      let idx;
      if (Array.isArray(contactCRs) && contactCRs.length) {
        idx = contactCRs.findIndex(a => a['indskr_contactcrid'] == contactcrid);
      }
      if (idx >= 0) {
        contactCRs.splice(idx,1);
      }
      this.contactCRs.next(contactCRs);
      this.currentSelected.next(undefined);
      return res;
    })
  }


  public async scrapBusinessAccountCR(data: any, accountcrid:string,) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.SCRAP_ACCOUNT_CR;
    url = url.replace('{accountId}', accountcrid);
    return await this.mdmDataService.scrapChangeRequest(url, data).then(async (res) => {
      if (!res) {
        res = { 'indskr_accountcrid': accountcrid };
      }
      await this.deleteAccountCRInPouchDB(res['indskr_accountcrid']);
      let accountCRs = this.accountCRs.getValue();
      let idx;
      if (Array.isArray(accountCRs) && accountCRs.length) {
        idx = accountCRs.findIndex(a => a['indskr_accountcrid'] == accountcrid);
      }
      if (idx >= 0) {
        accountCRs.splice(idx,1);
      }
      this.accountCRs.next(accountCRs);
      this.currentSelected.next(undefined);
      return res;
    })
  }

  async deleteContactCRInPouchDB(data) {
    let dbContactsCRs = [];
    try {
      let rawContactsDoc: any = await this.diskService.retrieve(DB_KEY_PREFIXES.CONTACT_CR);
      if (rawContactsDoc && rawContactsDoc.raw && Array.isArray(rawContactsDoc.raw)) {
        dbContactsCRs = rawContactsDoc.raw;
        let idx;
        if(Array.isArray(dbContactsCRs) && dbContactsCRs.length){
          idx = dbContactsCRs.findIndex(a=> a['indskr_contactcrid'] == data);
        }
        if(idx>=0) {
          dbContactsCRs.splice(idx,1);
        }
      }
      await this.diskService.updateOrInsert(DB_KEY_PREFIXES.CONTACT_CR, (doc) => {
        if (!doc || !doc.raw) {
          doc = {
            raw: []
          };
        }
        doc.raw = dbContactsCRs;
        return doc;
      });
    } catch (err) {
      console.warn('mapAppCreatedContactCRs: No data to delete');
    }
  }

  async deleteAccountCRInPouchDB(data) {
    let dbCRs = [];
    try {
      let rawCRs: any = await this.diskService.retrieve(DB_KEY_PREFIXES.ACCOUNT_CR);
      if (rawCRs && rawCRs.raw && Array.isArray(rawCRs.raw)) {
        dbCRs = rawCRs.raw;
        let idx;
        if (Array.isArray(dbCRs) && dbCRs.length){
          idx = dbCRs.findIndex(a=> a['indskr_accountcrid'] == data);
        }
        if(idx>=0) {
          dbCRs.splice(idx,1);
        }
      }
      await this.diskService.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT_CR, (doc) => {
        if (!doc || !doc.raw) {
          doc = {
            raw: []
          };
        }
        doc.raw = dbCRs;
        return doc;
      });
    } catch (err) {
      console.warn('mapAppCreatedContactCRs: No data to delete');
    }
  }

  async upsertContactCRInPouchDB(data) {
    let dbContactsCRs = [];
    try {
      let rawContactsDoc: any = await this.diskService.retrieve(DB_KEY_PREFIXES.CONTACT_CR);
      if (rawContactsDoc && rawContactsDoc.raw && Array.isArray(rawContactsDoc.raw)) {
        dbContactsCRs = rawContactsDoc.raw;
        let idx;
        if(Array.isArray(dbContactsCRs) && dbContactsCRs.length){
          idx = dbContactsCRs.findIndex(a=> a['indskr_contactcrid'] == data['indskr_contactcrid']);
        }
        if(idx >= 0){
          dbContactsCRs[idx] = data;
        }else{
          dbContactsCRs.push(data);
        }
        await this.diskService.updateOrInsert(DB_KEY_PREFIXES.CONTACT_CR, (doc) => {
          if (!doc || !doc.raw) {
            doc = {
              raw: []
            };
          }
          doc.raw = dbContactsCRs;
          return doc;
        });
      }
    } catch (error) {
      console.warn('mapAppCreatedContactCRs: No data to load');
    }
  }

  async updateContactCRInPouchDBWithContactCRId(cResponse) {
    let dbContactsCRs = [];
    try {
      let rawContactsDoc: any = await this.diskService.retrieve(DB_KEY_PREFIXES.CONTACT_CR);
      if (rawContactsDoc && rawContactsDoc.raw && Array.isArray(rawContactsDoc.raw)) {
        dbContactsCRs = rawContactsDoc.raw;
        let idx;
        if(Array.isArray(dbContactsCRs) && dbContactsCRs.length){
          idx = dbContactsCRs.findIndex(a=> a['addedNewOneKeyCRId'] == cResponse['addedNewOneKeyCRId']);
        }
        if(idx >= 0){
          let selectedDbContactsCR = dbContactsCRs[idx];
          selectedDbContactsCR['indskr_contactcrid'] = cResponse['contactChangeRequestId'];

          dbContactsCRs[idx] = selectedDbContactsCR;
        }
        await this.diskService.updateOrInsert(DB_KEY_PREFIXES.CONTACT_CR, (doc) => {
          if (!doc || !doc.raw) {
            doc = {
              raw: []
            };
          }
          doc.raw = dbContactsCRs;
          return doc;
        });
      }
    } catch (error) {
      console.warn('updateContactCRInPouchDBWithContactCRId: No data to load');
    }
  }

  /*public async updateOneKeyContactCR(data: any) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.UPDATE_ACCOUNT_CR;
    url = url.replace('{contactId}', data.ID);
    await this.mdmDataService.updateChangeRequest(url, data).then(async (res) => {
      if (res) {
        data['indskr_contactcrid'] = res['indskr_contactcrid'];
        data['modifiedon'] = new Date().getTime();
        await this.diskService.upsertContactCRs(data);
        let contactCRs = this.contactCRs.getValue();
        contactCRs.push(data);
        this.contactCRs.next(contactCRs);
      }
    })
  }*/

  public async createBusinessAccountDataCR(data: any) {
    //add mandatory fields
    data['statuscode'] = 548910004;
    data['statecode'] = 1;

    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.CREATE_ACCOUNT_CR;
    return await this.mdmDataService.createChangeRequest(url, data).then(async (res) => {
      if (res) {
        data['indskr_accountcrid'] = res['indskr_accountcrid'];
        data['modifiedon'] = new Date().getTime();
        data['createdon'] = new Date().getTime();
        data['statuscode'] = ChangeRequestStatusCode.OPEN;
        await this.upsertAccountCRInPouchDB(data);
        let accountCRs = this.accountCRs.getValue();
        accountCRs.push(data);
        this.accountCRs.next(accountCRs);
        return res;
      }
    })
  }

  public async createOneKeyAccountCR(data: any) {
    //add mandatory fields
    data['indskr_mdm'] = MDMType.ONEKEY;
    data['indskr_accountsourcetype'] = ChangeRequestType.MDM;
    data['statuscode'] = ChangeRequestStatusCode.IN_PROGRESS;
    data['statecode'] = ChangeRequestStateCode.INACTIVE;

    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.CREATE_ACCOUNT_CR;
    return await this.mdmDataService.createChangeRequest(url, data).then(async (res) => {
      if (res) {
        data['indskr_accountcrid'] = res['indskr_accountcrid'];
        data['modifiedon'] = new Date().getTime();
        data['createdon'] = new Date().getTime();
        data['statuscode'] = ChangeRequestStatusCode.SUBMITTED;
        if (this.authService.okIntegrationSettings && this.authService.okIntegrationSettings.typeOfIntegration == IntegrationType.ONE_KEY) {
          await this.submitAccountCRInOk(data);
        }
        await this.upsertAccountCRInPouchDB(data);
        let accountCRs = this.accountCRs.getValue();
        accountCRs.push(data);
        this.accountCRs.next(accountCRs);
        this.accountCRList = accountCRs;
        return res;
      }
    })
  }

  async submitContactCRInOk(data: any) {
    if (!this.authService.user.iqviaCodBase) return;
    await this.constructContactVRPayload(data).then((payload) => {
      if (payload) {
        console.log(payload);
        this.okDataService.submitVR(payload).then(async (res) => {
          if (res && res['response'] && res['response']['status'] == 'SUCCESS') {
            await this.updateCRDynamics(data, 'indskr_contactcrs', data['indskr_contactcrid']);
          }
          console.log(res);
          this.dynamicFormsService.okAddressMapper = {};
          this.dynamicFormsService.okLookUpMapper = [];
        })
        .catch(err => {
          console.warn("error occured while submit Contact VR payload", err);
          this.dynamicFormsService.okAddressMapper = {};
          this.dynamicFormsService.okLookUpMapper = [];
        });
      }
    });
  }

  async submitAccountCRInOk(data: any) {
    if (!this.authService.user.iqviaCodBase) return;
    await this.constructAccountVRPayload(data).then((payload) => {
      if (payload) {
        console.log(payload);
        this.okDataService.submitVR(payload).then(async (res) => {
          if (res && res['response'] && res['response']['status'] == 'SUCCESS') {
            await this.updateCRDynamics(data, 'indskr_accountcrs', data['indskr_accountcrid']);
          }
          console.log(res);
          this.dynamicFormsService.okAddressMapper = {};
          this.dynamicFormsService.okLookUpMapper = [];
        })
        .catch(err => {
          console.warn("error occured while submit Account VR payload: ", err);
          this.dynamicFormsService.okAddressMapper = {};
          this.dynamicFormsService.okLookUpMapper = [];
        });
      }
    });
  }

  async updateCRDynamics(data, entity, guid) {
    let payload = {
      "statecode": ChangeRequestStateCode.INACTIVE,
      "statuscode": ChangeRequestStatusCode.SUBMITTED,
      "indskr_submittedon": new Date()
    }
    await this.dynamics.update(guid, entity, payload).then(() => {
      data['statecode'] = ChangeRequestStateCode.INACTIVE;
      data['statuscode'] = ChangeRequestStatusCode.SUBMITTED;
      data['indskr_submittedon'] = new Date();
    });
  }

  async constructContactVRPayload(data): Promise<any> {
    let clientRequestId;
    // replaceAll method is not available on chrome versions below 85
    try {
      clientRequestId = data['indskr_contactcrid'] ? data['indskr_contactcrid'].replaceAll('-', '') : null;
    } catch (error) {
      clientRequestId = data['indskr_contactcrid'] ? data['indskr_contactcrid'].split('-').join('') : null;
    }
    let isoCodFormatted: string = this.authService.user.iqviaCodBase.substring(1,3) || '';
    if (isoCodFormatted === 'UK') isoCodFormatted = 'GB';
    let payload = {
      "entityType": 'ACTIVITY',
      "codBase": this.authService.user.iqviaCodBase,
      "isoCod2": isoCodFormatted,
      "validation.clientRequestId": clientRequestId,
      "validation.requestDate": new Date(),
      "validation.requestProcess": data['indskr_mdmrequesttype'] == MDMRequestType.CREATE ? OKVRType.CREATE : (data['indskr_mdmrequesttype'] == MDMRequestType.UPDATE) ? OKVRType.UPDATE : OKVRType.DELETE,
      "validation.process": VRProcess.DIRECT,
      "address.country": isoCodFormatted,
      "validation.requestComment": data['indskr_requestcomments'] ? data['indskr_requestcomments'] : null,

      "individual.firstName": data['indskr_firstname'] ? data['indskr_firstname'] : null,
      "individual.lastName": data['indskr_lastname'] ? data['indskr_lastname'] : null,
      // "individual.genderCode": data['indskr_Gendercode'] ? data['indskr_Gendercode'] : null,
      "individual.mobilePhone": data['indskr_mobilephone'] ? data['indskr_mobilephone'] : null,
      "individual.graduationYear": data['omnione_graduationyear'] ? new Date(data['omnione_graduationyear']).getFullYear() : null,
      "individual.birthYear": data['omnione_yearofbirth'] ? new Date(data['omnione_yearofbirth']).getFullYear() : null
    };
    let birthDate = new Date(data['omnione_dayandmonthofbirth'])
    let formattedBirthDate = data['omnione_dayandmonthofbirth'] ? ('0'+(birthDate.getMonth()+1)).slice(-2) + '-' + ('0'+(birthDate.getDate()+1)).slice(-2) : null;
    payload["individual.birthDay"] = formattedBirthDate ? formattedBirthDate : null;
    //mandatory field connections for France, Germany, Belgium, UK
    const targetCountries: string[] = ["FR","DE","BE","GB"];
    const isTargetCountry: boolean = targetCountries.includes(isoCodFormatted);
    if (this.dynamicFormsService.okLookUpMapper.length) {
      let formattedLookups = this.dynamicFormsService.okLookUpMapper;
      let titleCode = formattedLookups.find(e => e['entity'] == 'omnione_lu_titlecode');
      payload['individual.titleCode'] = titleCode ? titleCode['codIdOnekey'] : null;
      let title = formattedLookups.find(e => e['entity'] == 'omnione_lu_title');
      payload['individual.prefixNameCode'] = title ? title['codIdOnekey'] : null;
      let profDesignations = formattedLookups.find(e => e['entity'] == 'omnione_lu_professionaldesignation');
      payload['individual.typeCode'] = profDesignations ? profDesignations['codIdOnekey'] : null;
      let genderCode = formattedLookups.find(e => e['entity'] == 'omnione_lu_gender');
      payload['individual.genderCode'] = genderCode ? genderCode['codIdOnekey'] : null;
      // let jobTitle = formattedLookups.find(e => e['entity'] == 'omnione_lu_jobtitle');
      // payload['individual.qualification1'] = profDesignations ? profDesignations['codIdOnekey'] : null;
    }
    if (data['indskr_mdmrequesttype'] == MDMRequestType.UPDATE) {
      payload['individual.individualEid'] = data['indskr_mdmid'] ? data['indskr_mdmid'] : null;
    }

    if (data['linkEntities'] && Array.isArray(data['linkEntities']) && data['linkEntities'].length) {
      await this.workplaceAddresses(payload, data, isTargetCountry);
      await this.mapSpecialities(payload, data, "individual");
      await this.mapWorkplaceAffiliations(payload, data);
    }

    console.log(payload);
    // await this.hospitalBedDetails(payload, data);
    // await this.workplaceStatusDetails(payload, data);
    return payload;
  }

  async constructAccountVRPayload(data): Promise<any> {
    let clientRequestId;
    // replaceAll method is not available on chrome versions below 85
    try {
      clientRequestId = data['indskr_accountcrid'] ? data['indskr_accountcrid'].replaceAll('-', '') : null;
    } catch (error) {
      clientRequestId = data['indskr_accountcrid'] ? data['indskr_accountcrid'].split('-').join('') : null;
    }
    let isoCodFormatted: string = this.authService.user.iqviaCodBase.substring(1,3) || '';
    if (isoCodFormatted === 'UK') isoCodFormatted = 'GB';
    let payload = {
      "entityType": 'WORKPLACE',
      "codBase": this.authService.user.iqviaCodBase,
      "isoCod2": isoCodFormatted,
      "validation.clientRequestId": clientRequestId,
      "validation.requestDate": new Date(),
      "validation.requestProcess": data['indskr_mdmrequesttype'] == MDMRequestType.CREATE ? OKVRType.CREATE : (data['indskr_mdmrequesttype'] == MDMRequestType.UPDATE) ? OKVRType.UPDATE : OKVRType.DELETE,
      "validation.process": VRProcess.DIRECT,
      "address.country": isoCodFormatted,
      "validation.requestComment": data['indskr_requestcomments'] ? data['indskr_requestcomments'] : null,

      "workplace.usualName": data['indskr_name'] ? data['indskr_name'] : null,
      // "workplace.usualName2": null,
      // "workplace.typeCode": "TET.WFR.CAC",
      "workplace.officialName": data['indskr_name'] ? data['indskr_name'] : null,
      // "workplace.officialName2": null,
      // "workplace.externalIdE1": null
    };
    //mandatory field connections for France, Germany, Belgium, UK
    const targetCountries: string[] = ["FR","DE","BE","GB"];
    const isTargetCountry: boolean = targetCountries.includes(isoCodFormatted);
    if (this.dynamicFormsService.okLookUpMapper.length) {
      let formattedLookups = this.dynamicFormsService.okLookUpMapper;
      let activityLocation = formattedLookups.find(e => e['entity'].toLowerCase() == 'omnione_lu_activitylocation');
      payload['workplace.activityLocationCode'] = activityLocation ? activityLocation['codIdOnekey'] : null;
      let workplaceType = formattedLookups.find(e => e['entity'].toLowerCase() == 'omnione_lu_workplacetypemostdetailedcategory');
      if (isTargetCountry) payload['workplace.typeCode'] = workplaceType ? workplaceType['codIdOnekey'] : null;
    }
    if (data['indskr_mdmrequesttype'] == MDMRequestType.UPDATE) {
      payload['workplace.workplaceEid'] = data['indskr_mdmid'] ? data['indskr_mdmid'] : null;
    }
    if (data['linkEntities'] && Array.isArray(data['linkEntities']) && data['linkEntities'].length) {
      await this.parentWorkPlaceDetails(payload, data);
      await this.workplaceAddresses(payload, data, isTargetCountry);
      await this.mapSpecialities(payload, data, "workplace");
    }
    await this.workplaceCommunicationDetails(payload, data);

    console.log(payload);
    // await this.hospitalBedDetails(payload, data);
    // await this.workplaceStatusDetails(payload, data);
    return payload;
  }

  async parentWorkPlaceDetails(payload, data) {
      /*"workplace.parentUsualName": "UNICANCER",
      "workplace.parentUsualName2": null,
      "workplace.parentOfficialName": null,
      "workplace.parentOfficialName2": null,
      "workplace.parentWorkplaceEid": null,*/
    let affiliatedAccount = data['linkEntities'].filter(o=> o['entity'] == LinkedEntity.AFFILIATED_TO_PARENT_ACCOUNT);
    if (Array.isArray(affiliatedAccount) && affiliatedAccount.length) {
      let affAccs = affiliatedAccount['values'];
      if (Array.isArray(affAccs) && affAccs.length) {
        let parentAccount = affAccs[0];
        payload['workplace.parentUsualName'] = parentAccount['indskr_name'];
        payload['workplace.parentOfficialName'] = parentAccount['indskr_name'];
        payload['workplace.relations.validityBeginDate'] = parentAccount['indskr_startdate'];
        payload['workplace.relations.validityEndDate'] = parentAccount['indskr_enddate'];
        let affAccObj = parentAccount['lookupfields'] ? parentAccount['lookupfields'].filter(o=> o['name'] == 'indskr_affiliatedtoaccountid') : null;
        if(affAccObj) {
          payload['workplace.relations.parent'] = affAccObj[0]['id'];
        }
      }
    }

  }

  async mapWorkplaceAffiliations(payload, data) {
    let affiliatedAccount = data['linkEntities'].filter(o=> o['entity'] == LinkedEntity.ACCOUNT_CONTACT_AFFILIATION);
    if (Array.isArray(affiliatedAccount) && affiliatedAccount.length) {
      let affAccs = affiliatedAccount[0]['values'];
      if (Array.isArray(affAccs) && affAccs.length) {
        let lookUps = affAccs[0]['lookupfields'];
        let accountId = lookUps.filter(acc => acc['entity'] == "accounts")[0].id;
        let affiliatedAccount = this.accountOfflineService.getAccountById(accountId);
        if (data['indskr_mdmrequesttype'] == MDMRequestType.UPDATE) {
          let affiliatedAccountFetchXML = ACCOUNT_CONTACT_AFFILIATION_BY_MDMID.replace('{0}', accountId);
          await this.dynamics.executeFetchQuery('indskr_accountcontactaffiliations', affiliatedAccountFetchXML).then(async (res)=>{
            if(res && Array.isArray(res) && res.length) {
              let affilitedAccount = res[0];
              if (affilitedAccount)
                payload['activity.activityEid'] = affilitedAccount['indskr_mdmid'] ? affilitedAccount['indskr_mdmid'] : null;
            }
          });
        }
        if (affiliatedAccount) {
          payload['workplace.workplaceEid'] = affiliatedAccount.indskr_mdmid;
          await this.downloadActivity(affiliatedAccount.indskr_mdmid, payload)
        }
      }
    }
  }

  async downloadActivity(mdmId, payload) {
    let searchQuery = {
      "codBase": [this.authService.user.iqviaCodBase],
      "entityType": OKEntityType.ACTIVITY,
      "fields": [{
        "name": OKWorkplaceSearchKeys.WORKPLACE_ID,
        "values": [mdmId]
      }]
    }
    await this.okDataService.searchOneKey(searchQuery).then(async (result) => {
      if (result && result['response'] && result['response']['results'] && Array.isArray(result['response']['results'])) {
        console.log(result['response']['results']);
        const okActivity = result['response']['results'][0];
        // payload['activity.activityEid'] = okActivity['activity']['activityEid'];
        const workplaceAddresses = okActivity && okActivity['workplace'] && okActivity['workplace']['workplaceAddresses'] || '';
        if (workplaceAddresses) {
          const primaryAddress = workplaceAddresses['P,1'] && workplaceAddresses['P,1']['address'] || '';
          const postalTownReference = primaryAddress && primaryAddress['postalTownReference'] || '';
          const villageLabel = postalTownReference && postalTownReference['villageLabel'] || '';
          payload['address.city'] = villageLabel ? villageLabel : null;
        }
      }
    }).catch(err => {
      console.warn("error occured while searching activity from okData service: ", err);
    });
  }

  async hospitalBedDetails(payload, data) {
      /*"workplace.numberOfBeds": "15",
      "workplace.numberOfPatients": null,
      "workplace.numberOfMedicalEmployees": null,*/
  }

  async workplaceCommunicationDetails(payload, data) {
      /*"workplace.email": null,
      "workplace.website": null,
      "workplace.telephone": null,
      "workplace.fax": null,*/
    payload['workplace.telephone'] = data['indskr_telephone1'] ? data['indskr_telephone1'] : null;
    payload['workplace.website'] = data['indskr_websiteurl'] ? data['indskr_websiteurl'] : null;
  }

  async mapSpecialities(payload, data, type) {
      /*"workplace.speciality1": "SP.WFR.HE",
      "workplace.speciality2": "SP.WFR.ON",
      "workplace.speciality3": null,*/
    if (this.dynamicFormsService.okLookUpMapper.length) {
      let formattedLookups = this.dynamicFormsService.okLookUpMapper;
      let specialties = formattedLookups.filter(e => e['entity'] == 'omnione_lu_specialties');
      if (specialties.length) {
        //maximum specialties is 3
        const maxNumSpecialties: number = 3;
        specialties.slice(0, maxNumSpecialties).forEach((sp, i) => {
          if (type == "workplace")
            payload['workplace.speciality' + (i+1)]  = sp['codIdOnekey'] ? sp['codIdOnekey'] : null;
          else
            payload['individual.speciality' + (i+1)]  = sp['codIdOnekey'] ? sp['codIdOnekey'] : null;
        })
      }
    }
  }

  async workplaceAddresses(payload, data, isTargetCountry) {
      /*"address.buildingLabel": null,
      "address.specificPostalLabel": null,
      "address.longLabel": "33 AVENUE DE VALOMBROSE",
      "address.longLabel2": null,
      "address.extensionLabel": null,
      "address.city": "NICE CEDEX 2",
      "address.city2": null,
      "address.longPostalCode": "06189",
      "address.postalCityName": "NICE CEDEX 2",
      "address.postalCityName2": null,
      "address.countyCode": "DPT.WFR.0006",
      "address.regionCode": null,
      "address.brick": "UG2.WFR.06NC5"*/
    if (this.dynamicFormsService.okAddressMapper && this.dynamicFormsService.okAddressMapper.custom) {
      let customAddressMapper = this.dynamicFormsService.okAddressMapper.custom
      payload['address.longLabel'] = customAddressMapper['indskr_line1'] ? customAddressMapper['indskr_line1'] : null;
      payload['address.postalCityName'] = customAddressMapper['omnione_addresspostalcityname'] ? customAddressMapper['omnione_addresspostalcityname'] : null;
    }
    if (this.dynamicFormsService.okAddressMapper && this.dynamicFormsService.okAddressMapper.formatted) {
      let formattedAddressMapper = this.dynamicFormsService.okAddressMapper.formatted
      payload['address.city'] = formattedAddressMapper['indskr_city_lu'] ? formattedAddressMapper['indskr_city_lu'] : null;
      payload['address.longPostalCode'] = formattedAddressMapper['indskr_postalcode_lu'] ? formattedAddressMapper['indskr_postalcode_lu'] : null;
    }
    if (isTargetCountry && this.dynamicFormsService.okLookUpMapper.length) {
      let formattedLookups = this.dynamicFormsService.okLookUpMapper;
      let countyCode = formattedLookups.find(e => e['entity'] == 'indskr_district');
      payload['address.countyCode'] = countyCode ? countyCode['codIdOnekey'] : null;
    }
  }

  async workplaceStatusDetails(payload, data) {
      /*"workplace.activityLocationCode": "LEX.WFR.B",
      "workplace.statusCode": "ACTV",
      "workplace.stateCode": "STE.OPE",*/
  }

  async upsertAccountCRInPouchDB(data) {
    let dbAccountsCRs = [];
    try {
      let rawAccountsDoc: any = await this.diskService.retrieve(DB_KEY_PREFIXES.ACCOUNT_CR);
      if (rawAccountsDoc && rawAccountsDoc.raw && Array.isArray(rawAccountsDoc.raw)) {
        dbAccountsCRs = rawAccountsDoc.raw;
        dbAccountsCRs.push(data);
        await this.diskService.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT_CR, (doc) => {
          if (!doc || !doc.raw) {
            doc = {
              raw: []
            };
          }
          doc.raw = dbAccountsCRs;
          return doc;
        });
      }
    } catch (error) {
      console.warn('mapAppCreatedAccountCRs: No data to load');
    }
  }

  /*public async updateOneKeyAccountCR(data: any) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.UPDATE_ACCOUNT_CR;
    url = url.replace('{accountId}', data.ID);
    await this.mdmDataService.updateChangeRequest(url, data).then(async (res) => {
      if (res) {
        data['indskr_accountcrid'] = res['indskr_accountcrid'];
        data['modifiedon'] = new Date().getTime();
        await this.diskService.upsertContactCRs(data);
        let contactCRs = this.contactCRs.getValue();
        contactCRs.push(data);
        this.contactCRs.next(contactCRs);
      }
    })
  }*/

  private _postMessageOnDefaultLinkedEntityDataMappingWorker(data){
    this._defaultLinkedEntityDataMappingServiceWorker.postMessage(data);
  }

  async fetchContactCRConfiguredDisplay(fullsync = false, loadFromDB = false, contact = null) {
    this._defaultLinkedEntityMappingData = [];
    this.contactLinkEntities = [];
    this.TYPE = 'CONTACT_CR';
    const contactid = contact ? contact['indskr_contactcrid'] : null;
    let businessProcess = undefined;
    if (contact && contact['indskr_mdm'] && contact['indskr_mdm'] == BusinessProcessType.SanofiChina) {
      businessProcess = BusinessProcessType.SanofiChina;
    }
    let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_contactcr", FormType.DISPLAYFORM, businessProcess);
    let formType = DynamicFormType.CONFIGUREDFORM;
    const fetchMultilingualFields:boolean = this.authService.user.securityRoles.some(a=> a.name == 'iO OneKey User' || a.name == 'iO OneKey Admin');

    let formDef = contactForm;

    if (!contactForm) {
      //If form is not configure, fetch only manadatory fields to show in list view
      formType = DynamicFormType.DEFAULTFORM;
    }

    let offlineContactRawData = await this.diskService.retrieve(DB_KEY_PREFIXES.CONTACT_CR);
    if(fetchMultilingualFields && offlineContactRawData && offlineContactRawData.lastLocaleId && offlineContactRawData.lastLocaleId != this.authService.user.localeId){
      fullsync = true;
    } else if (offlineContactRawData && offlineContactRawData.formType && offlineContactRawData.formType == DynamicFormType.DEFAULTFORM && formType == DynamicFormType.CONFIGUREDFORM) {
      fullsync = true;
    } else if (formDef && offlineContactRawData && offlineContactRawData.formType &&
      offlineContactRawData.formType == DynamicFormType.CONFIGUREDFORM &&
      offlineContactRawData.lastInitialSyncTime &&
      isBefore(new Date(offlineContactRawData.lastInitialSyncTime), new Date(formDef.modiefiedOn))) {
      fullsync = true;
    }

    let parentEntityFetchXml = fetchQueries.configuredFormFetchXMLs.fetchConfiguredFormEntity;
    //create attributes
    let parentEntityAttributesStr = '<attribute name="indskr_contactcrid"/>';
    // Default Parent level Attributes
    let requiredContactCRAttributes = RequiredContactCRAttributes;
    if (formDef && formDef['entityName'] == "indskr_contactcr" && formDef.raw['indskr_businessprocess'] == BusinessProcessType.SanofiChina) {
      requiredContactCRAttributes.push('indskr_hcponeid');
    }
    if (businessProcess == BusinessProcessType.SanofiChina) {
      requiredContactCRAttributes.push('indskr_attachment1', 'indskr_attachment2');
      if (this.authService.user.buConfigs && (this.authService.user.buConfigs.indskr_businessline == 1 || this.authService.user.buConfigs.indskr_businessline == 3)) {
        requiredContactCRAttributes.push('indskr_pharmarequesttype');
      }
    }
    requiredContactCRAttributes.forEach(attr => {
      parentEntityAttributesStr += '<attribute name="' + attr + '"/>'
    });

    let linkEntityAttributesArray = [];
    if (formDef) {
      formDef.metadata.forEach(tab => {
        tab.controls.forEach(control => {
          if (control.dataType) {
            let linkedAttrFetchXML = '';
            if(fetchMultilingualFields && control.lookupEntityPrimaryId && control.lookupEntityPrimaryId == "omnione_onekeycodeslabelsid"){
              let key = this.languageService.getOneKeyTableAttributes();
              linkedAttrFetchXML = `
                <link-entity name="omnione_onekeycodeslabels" from="omnione_onekeycodeslabelsid" to="${control.attributeName}" visible="false" link-type="outer">
                  <attribute name="${key}" alias="${control.attributeName}_value"/>
                  ${this.authService.user.localeId != '1033' ? `<attribute name="omnione_en_long_label" alias="${control.attributeName}_fallbackvalue"/>`:''}
                  <attribute name="omnione_onekeycodeslabelsid" alias="_${control.attributeName}_value"/>
                </link-entity>
              `;
              parentEntityAttributesStr += linkedAttrFetchXML;
              if(this.authService.user.localeId != '1033'){

              }
            }else{
              parentEntityAttributesStr += '<attribute name="' + control.attributeName + '"/>';
            }
          }else if (!control.dataType && control.subgrid) {
            linkEntityAttributesArray.push(control);
          }
        });
      });
    }

    if (loadFromDB) {
      this.mapDFContactCR("", offlineContactRawData.raw, true);
    }

    parentEntityFetchXml = parentEntityFetchXml.replace('{parentLevelAttributes}', parentEntityAttributesStr);
    parentEntityFetchXml = parentEntityFetchXml.replace('{parentEntityName}', 'indskr_contactcr');
    parentEntityFetchXml = parentEntityFetchXml.replace('{linkEntityPlaceholder}', '');
    parentEntityFetchXml = parentEntityFetchXml.replace('{secondaryInfoPlaceholder}', '');

    //create delta sync filter
    let syncState = await this.diskService.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CONTACT_CR);
    const contactsSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.contactCR,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    let isInitialSync = !syncState || !syncState.lastUpdatedTime || fullsync;
    let hourDifference;
    if (isInitialSync) parentEntityFetchXml = parentEntityFetchXml.replace('{deltasyncFilterLevel1}', '');
    else {
      let now = new Date();
      hourDifference = differenceInHours(
        now,
        new Date(syncState.lastUpdatedTime)
      )
      //add one to make sure we take care of fractional difference in hours
      hourDifference += 1;

      let lastModifiedFilter = `<filter type="or">
              <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" />
            </filter>`

      if (contactid) {
        lastModifiedFilter = `<filter type="or">
                    <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" />
                </filter>`
        if (contact.hasOwnProperty('approvalActivityId') && contact['approvalActivityId']) {
          lastModifiedFilter = "";
        }
      }
      parentEntityFetchXml = parentEntityFetchXml.replace('{deltasyncFilterLevel1}', lastModifiedFilter);
    }

    let positionFilterString;
    if (contactid) {
      let contactidFilter = `<filter type="and"><condition attribute="indskr_contactcrid" operator="eq" value="` + contactid + `" /></filter>`
      parentEntityFetchXml = parentEntityFetchXml.replace('{positionFilterlevel1}', '');
      parentEntityFetchXml = parentEntityFetchXml.replace('{customFilterLevel1}', contactidFilter);
    }
    else {
      positionFilterString = `<filter type="and">
              <condition attribute="ownerid" operator="eq-userid" />
            </filter>`
      parentEntityFetchXml = parentEntityFetchXml.replace('{positionFilterlevel1}', positionFilterString);
      parentEntityFetchXml = parentEntityFetchXml.replace('{customFilterLevel1}', '');
    }
    //call dynamics

    let deletedContactRes;
    if(loadFromDB){
      if(formType === DynamicFormType.CONFIGUREDFORM)
        await this.fetchContactsLinkedEntityData(linkEntityAttributesArray, positionFilterString, hourDifference, formType, contactid, loadFromDB, isInitialSync);
        await this.fetchContactsCRDefaultLinkEntityData(positionFilterString, hourDifference, linkEntityAttributesArray, contactid, loadFromDB);
    }
    else{
      const result = await this.dynamics.executeFetchQuery('indskr_contactcrs',parentEntityFetchXml).then(async (res)=>{
        if (!isInitialSync) this.addNotificationsToGo(res, offlineContactRawData);
        let dataToSave = res;
        let lastInitialSyncTime;
        if (res && Array.isArray(res)) {
          res.forEach((o) => {
            if (o['createdon']) {
              o['createdon'] = new Date(o['createdon']).getTime().toString();
              o['indskr_firstname'] = o['indskr_firstname'] ? o['indskr_firstname'] : "";
              o['indskr_lastname'] = o['indskr_lastname'] ? o['indskr_lastname'] : "";
              o['attachment1'] = o['indskr_attachment1'] ? o['indskr_attachment1'] : undefined;
              o['attachment2'] = o['indskr_attachment2'] ? o['indskr_attachment2'] : undefined;
            }
          });
          if (contactid) {
            const response = !_.isEmpty(res) ? res[0] : contact;
            await this.traceVR(contactid, response, 'indskr_contactcrs').then(() => {
              const index = this.contactCRs.getValue().findIndex(cr => cr.indskr_contactcrid == contactid);
              if (index >= 0 && response.statuscode != this.contactCRs.getValue()[index].statuscode) {
                this.contactCRs.getValue()[index] = response;
              }
              dataToSave[0] = res[0] = response;
            }).catch(err => {
              console.log(err);
            });
          }
        }
        dataToSave = await this.setDeltaObjects(isInitialSync,contactid,res, offlineContactRawData,dataToSave);
        await this.diskService.updateOrInsert(DB_KEY_PREFIXES.CONTACT_CR, doc => {
          doc = {
            raw: dataToSave,
            lastInitialSyncTime: lastInitialSyncTime,
            lastLocaleId: this.authService.user.localeId,
            formType: formType,
          };
          return doc;
        }).catch(error => console.error('Save Forms to DB error: ', error));
        if(formType === DynamicFormType.CONFIGUREDFORM)
          await this.fetchContactsLinkedEntityData(linkEntityAttributesArray, positionFilterString, hourDifference, formType, contactid, loadFromDB, isInitialSync,fetchMultilingualFields);
          await this.fetchContactsCRDefaultLinkEntityData(positionFilterString, hourDifference, linkEntityAttributesArray, contactid, loadFromDB);
        this.mapDFContactCR(res, dataToSave, isInitialSync && !contactid, deletedContactRes);
        if (contactsSyncInfo.syncStatus && !contactid) {
          syncState.lastUpdatedTime = new Date().getTime();
          contactsSyncInfo.totalSynced = res.length;
          await this.diskService.updateSyncState(syncState);
        }
        return res;
      }).catch(err => {
        console.log(err);
        this.deltaService.addSyncErrorToEntitySyncInfo(contactsSyncInfo, 'contactcrs', err);
      })
      if (_.isEmpty(result)) {
        return this.contactCRs.getValue().find(cr => cr.indskr_contactcrid == contactid);
      } else {
        return result;
      }
    }
  }

  async fetchContactCRConfiguredDisplayOffline(data:any, selectedCR: any, linkEntities: any) {
    this._defaultLinkedEntityMappingData = [];
    this.contactLinkEntities = [];
    this.TYPE = 'CONTACT_CR';
    const contactcrid = selectedCR ? selectedCR['indskr_contactcrid'] : null;

    let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_contactcr", FormType.DISPLAYFORM);
    let formType = DynamicFormType.CONFIGUREDFORM;

    if (!contactForm) {
      //If form is not configure, fetch only manadatory fields to show in list view
      formType = DynamicFormType.DEFAULTFORM;
    }

    let offlineContactRawData = await this.diskService.retrieve(DB_KEY_PREFIXES.CONTACT_CR);

    let lastInitialSyncTime = new Date().getTime();

    let localRaw = [...offlineContactRawData.raw];

    let idx = localRaw.findIndex(a=> a.indskr_contactcrid == contactcrid);

    let editedBusinessCRRawData = selectedCR;

    /*************Update Business CR Display form for LookupFields*************/
    let offlineEditedBusinessCR = data.formStringValue;
      
    for (const key in offlineEditedBusinessCR) {
      let customKey = `_${key}_value@OData.Community.Display.V1.FormattedValue`;

      if (editedBusinessCRRawData.hasOwnProperty(customKey)) {
        console.log(`${key} : ${editedBusinessCRRawData[customKey]}`);
        console.warn(`${key} : ${offlineEditedBusinessCR[key]}`);

        editedBusinessCRRawData[customKey] = offlineEditedBusinessCR[key];
      } 
      // else {
      //   console.log(`New Key ${key} : ${editedBusinessCRRawData[customKey]}`);
      //   console.warn(`New Key ${key} : ${offlineEditedBusinessCR[key]}`);

      //   editedBusinessCRRawData[customKey] = offlineEditedBusinessCR[key];
      // }

      customKey = `_${key}_value_Formatted`;

      if (editedBusinessCRRawData.hasOwnProperty(customKey)) {
        console.log(`${key} : ${editedBusinessCRRawData[customKey]}`);
        console.warn(`${key} : ${offlineEditedBusinessCR[key]}`);

        editedBusinessCRRawData[customKey] = offlineEditedBusinessCR[key];
      } 
      // else {
      //   console.log(`New Key ${key} : ${editedBusinessCRRawData[customKey]}`);
      //   console.warn(`New Key ${key} : ${offlineEditedBusinessCR[key]}`);

      //   editedBusinessCRRawData[customKey] = offlineEditedBusinessCR[key];
      // }
    }

    let offlineEditedBusinessCRLookups = data.formValue.lookupfields;

    if(offlineEditedBusinessCRLookups) {
      offlineEditedBusinessCRLookups.forEach((item) => {
        let customKey = `_${item.name.toLowerCase()}_value`;

        if (editedBusinessCRRawData.hasOwnProperty(customKey)) {
            editedBusinessCRRawData[customKey] = item.id;
            console.warn(`${customKey} : ${editedBusinessCRRawData[customKey]}`);
        }
        // else {
        //   console.log(`New Key ${customKey} : ${editedBusinessCRRawData[customKey]}`);
        //   console.warn(`New Key ${customKey} : ${item.id}`);

        //   editedBusinessCRRawData[customKey] = item.id;
        // }
      });
    }
    /*************Update Business CR Display form for LookupFields*************/

    /*************Update Business CR Display form for TextFields*************/
    let offlineEditedBusinessCRTextFields = data.formValue;
      
    for (const key in offlineEditedBusinessCRTextFields) {

      if (editedBusinessCRRawData.hasOwnProperty(key)) {
        console.log(`${key} : ${editedBusinessCRRawData[key]}`);
        console.warn(`${key} : ${offlineEditedBusinessCRTextFields[key]}`);

        editedBusinessCRRawData[key] = offlineEditedBusinessCRTextFields[key];
      }
      // else {
      //   console.log(`New Key ${key} : ${editedBusinessCRRawData[key]}`);
      //   console.warn(`New Key ${key} : ${offlineEditedBusinessCRTextFields[key]}`);

      //   editedBusinessCRRawData[key] = offlineEditedBusinessCRTextFields[key];
      // }
    }
    /*************Update Business CR Display form for TextFields*************/

    /*************Update Business CR Display form for Submitted Status*************/
    editedBusinessCRRawData['statecode@OData.Community.Display.V1.FormattedValue'] = "Inactive";
    editedBusinessCRRawData['statecode'] = 1;
    editedBusinessCRRawData['statecode_Formatted'] = "Inactive";

    editedBusinessCRRawData['statuscode@OData.Community.Display.V1.FormattedValue'] = "Submitted";
    editedBusinessCRRawData['statuscode'] = 548910005;
    editedBusinessCRRawData['statuscode_Formatted'] = "Submitted";
    /*************Update Business CR Display form for Submitted Status*************/

     //Disk save for Pouch DB entries
    if(idx >= 0) {
      localRaw[idx] = editedBusinessCRRawData;
    }

    //contactCRs list find that Business CR and update
    let newIdx = this.contactCRs.getValue().findIndex(cr => cr.indskr_contactcrid == contactcrid);
    if(newIdx >= 0){
      this.contactCRs.getValue()[newIdx] = editedBusinessCRRawData;
    }

    await this.diskService.updateOrInsert(DB_KEY_PREFIXES.CONTACT_CR, doc => {
      doc = {
        raw: localRaw,
        lastInitialSyncTime: lastInitialSyncTime,
        lastLocaleId: this.authService.user.localeId,
        formType: formType,
      };
      return doc;
    }).catch(error => console.error('Save offlineEditedBusinessCR to DB error: ', error));

    if(linkEntities) {
      await linkEntities.forEach(async linkedEntity => {
      
      const linkedEntityDBPrefix = DB_KEY_PREFIXES.CONTACT_CR_LINKED_ENTITY;
      let dbKey = linkedEntity.entity;
      
      let linkEntitiesArr = linkedEntity.values;

      if(linkedEntity.entity == 'indskr_emailaddresscrs')
        dbKey = 'indskr_emailaddresscr';
      else if(linkedEntity.entity == 'indskr_accountcontactaffiliationcrs') {
        dbKey = 'indskr_accountcontactaffiliationcr';
      }
      else if(linkedEntity.entity == 'indskr_customerspecialtychangerequests') {
        dbKey = 'indskr_customerspecialtychangerequest';
      }

      let rawLEData = await this.diskService.retrieve(linkedEntityDBPrefix + dbKey);

      await linkEntitiesArr.forEach(async linkedEntity => {

      if(rawLEData && rawLEData.raw) {

        if(dbKey === 'indskr_emailaddresscr') {
          let existingLinkedEntity = rawLEData.raw.find(item => item['indskr_emailaddresscr.indskr_emailaddresscrid'] === linkedEntity['id']);
          let index = rawLEData.raw.indexOf(existingLinkedEntity);

          if(existingLinkedEntity) {

            if(linkedEntity) {
              for (const key in linkedEntity) {
                  let customKey = `${dbKey}.${key}`;
                
                  if (existingLinkedEntity.hasOwnProperty(customKey)) {
                    console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                    console.warn(`${customKey} : ${linkedEntity[key]}`);
                
                    existingLinkedEntity[customKey] = linkedEntity[key];
                  }

                  if(customKey === 'indskr_emailaddresscr.indskr_isprimary')
                  {
                    let value = linkedEntity[key] === true ? 'Yes':'No' ;

                    let newCustomKey = `${dbKey}.indskr_isprimary_Formatted`;

                    if (existingLinkedEntity.hasOwnProperty(newCustomKey)) {
                      console.log(`${newCustomKey} : ${existingLinkedEntity[newCustomKey]}`);
                      console.warn(`${newCustomKey} : ${value}`);
                  
                      existingLinkedEntity[newCustomKey] = value;
                    }

                    newCustomKey = `${dbKey}.indskr_isprimary@OData.Community.Display.V1.FormattedValue`;

                    if (existingLinkedEntity.hasOwnProperty(newCustomKey)) {
                      console.log(`${newCustomKey} : ${existingLinkedEntity[newCustomKey]}`);
                      console.warn(`${newCustomKey} : ${value}`);
                  
                      existingLinkedEntity[newCustomKey] = value;
                    }
                  }

                  customKey = `indskr_contactcrid`;
                  
                  if (existingLinkedEntity.hasOwnProperty(customKey)) {
                    console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                    console.warn(`${customKey} : ${contactcrid}`);
                
                    existingLinkedEntity[customKey] = contactcrid;
                  }
                }//end of for (const key in values)
            }
            
            rawLEData.raw[index] = existingLinkedEntity;
          }//if(existingLinkedEntity)
          else {

            let newLinkEntityData = {
            "@odata.etag": "W/\"1025458734\"",
            "indskr_contactcrid": "e2cfb02e-e213-ed11-b83e-000d3a85f157",
            "indskr_emailaddresscr.statuscode@OData.Community.Display.V1.AttributeName": "statuscode",
            "indskr_emailaddresscr.statuscode@OData.Community.Display.V1.FormattedValue": "Open",
            "indskr_emailaddresscr.statuscode": 1,
            "indskr_emailaddresscr.createdon@OData.Community.Display.V1.AttributeName": "createdon",
            "indskr_emailaddresscr.createdon@OData.Community.Display.V1.FormattedValue": "08/10/2022 04:35 PM",
            "indskr_emailaddresscr.createdon": "2022-08-10T11:05:22.000Z",
            "indskr_emailaddresscr.indskr_emailaddresscrid@OData.Community.Display.V1.AttributeName": "indskr_emailaddresscrid",
            "indskr_emailaddresscr.indskr_emailaddresscrid": "3c46054f-9c18-ed11-b83f-000d3a85f157",
            "indskr_emailaddresscr.indskr_name@OData.Community.Display.V1.AttributeName": "indskr_name",
            "indskr_emailaddresscr.indskr_name": "og",
            "indskr_emailaddresscr.indskr_isprimary@OData.Community.Display.V1.AttributeName": "indskr_isprimary",
            "indskr_emailaddresscr.indskr_isprimary@OData.Community.Display.V1.FormattedValue": "No",
            "indskr_emailaddresscr.indskr_isprimary": false,
            "indskr_emailaddresscr.indskr_type@OData.Community.Display.V1.AttributeName": "indskr_type",
            "indskr_emailaddresscr.indskr_type@OData.Community.Display.V1.FormattedValue": "Work",
            "indskr_emailaddresscr.indskr_type": 100000001,
            "indskr_emailaddresscr.indskr_emailaddress@OData.Community.Display.V1.AttributeName": "indskr_emailaddress",
            "indskr_emailaddresscr.indskr_emailaddress": "og@gmail.com",
            "indskr_emailaddresscr.statecode@OData.Community.Display.V1.AttributeName": "statecode",
            "indskr_emailaddresscr.statecode@OData.Community.Display.V1.FormattedValue": "Active",
            "indskr_emailaddresscr.statecode": 0,
            "indskr_emailaddresscr.statuscode_Formatted": "Open",
            "indskr_emailaddresscr.createdon_Formatted": "08/10/2022 04:35 PM",
            "indskr_emailaddresscr.indskr_isprimary_Formatted": "No",
            "indskr_emailaddresscr.indskr_type_Formatted": "Work",
            "indskr_emailaddresscr.statecode_Formatted": "Active"
            };

            if(linkedEntity) {
              for (const key in linkedEntity) {
                  let customKey = `${dbKey}.${key}`;
                
                  if (newLinkEntityData.hasOwnProperty(customKey)) {
                    console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                    console.warn(`${customKey} : ${linkedEntity[key]}`);
                
                    newLinkEntityData[customKey] = linkedEntity[key];
                  }

                  if(customKey === 'indskr_emailaddresscr.indskr_isprimary')
                  {
                    let value = linkedEntity[key] === true ? 'Yes':'No' ;

                    let newCustomKey = `${dbKey}.indskr_isprimary_Formatted`;

                    if (newLinkEntityData.hasOwnProperty(newCustomKey)) {
                      console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                      console.warn(`${newCustomKey} : ${value}`);
                  
                      newLinkEntityData[newCustomKey] = value;
                    }

                    newCustomKey = `${dbKey}.indskr_isprimary@OData.Community.Display.V1.FormattedValue`;

                    if (newLinkEntityData.hasOwnProperty(newCustomKey)) {
                      console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                      console.warn(`${newCustomKey} : ${value}`);
                  
                      newLinkEntityData[newCustomKey] = value;
                    }
                  }

                  customKey = `indskr_contactcrid`;
                  
                  if (newLinkEntityData.hasOwnProperty(customKey)) {
                    console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                    console.warn(`${customKey} : ${contactcrid}`);
                
                    newLinkEntityData[customKey] = contactcrid;
                  }
                }//end of for (const key in values)
            }

            rawLEData.raw.push(newLinkEntityData);
          }
        }//end of if(dbKey === 'indskr_email_address')
        else if(dbKey === 'indskr_accountcontactaffiliationcr') {
          let existingLinkedEntity = rawLEData.raw.find(item => item['indskr_accountcontactaffiliationcr.indskr_accountcontactaffiliationcrid'] === linkedEntity['id']);
          let index = rawLEData.raw.indexOf(existingLinkedEntity);
          
          if(existingLinkedEntity) {

            if(linkedEntity) {
                for (const key in linkedEntity) {
                  if(key === 'lookupfields') {
                    let indskr_contactcrs = linkedEntity[key].find(item => item['entity'] === 'indskr_contactcrs');
                    let accounts = linkedEntity[key].find(item => item['entity'] === 'accounts');
                    let customKey = '';
  
                    if(indskr_contactcrs && accounts) {
                      customKey = `indskr_contactcrid`;
                  
                      if (existingLinkedEntity.hasOwnProperty(customKey)) {
                        console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                        console.warn(`${customKey} : ${indskr_contactcrs['id']}`);
                    
                        existingLinkedEntity[customKey] = indskr_contactcrs['id'];
                      }
  
                      customKey = `indskr_accountid`;
  
                      if (existingLinkedEntity.hasOwnProperty(customKey)) {
                        console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                        console.warn(`${customKey} : ${accounts['id']}`);
                    
                        existingLinkedEntity[customKey] = accounts['id'];
                      }
  
                      const affiliatedAccount = this.accountOfflineService.getAccountById(accounts['id']);
  
                      customKey = `${dbKey}.indskr_accountid@OData.Community.Display.V1.FormattedValue`;
  
                      if (existingLinkedEntity.hasOwnProperty(customKey)) {
                        console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                        console.warn(`${customKey} : ${affiliatedAccount.accountName}`);
                    
                        existingLinkedEntity[customKey] = affiliatedAccount.accountName;
                      }
  
                      customKey = `${dbKey}.indskr_accountid_Formatted`;
  
                      if (existingLinkedEntity.hasOwnProperty(customKey)) {
                        console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                        console.warn(`${customKey} : ${affiliatedAccount.accountName}`);
                    
                        existingLinkedEntity[customKey] = affiliatedAccount.accountName;
                      }
                    }//end of if(indskr_contactcrs && accounts)

                    const omnione_lu_affiliationrole = linkedEntity[key].find(item => item['name'] === 'omnione_lu_affiliationrole');
                    const selectedOmnione_lu_affiliationrole = this.dynamicFormsService.allLookUpFieldsForOfflineEdit['omnione_lu_affiliationrole'].find(item => item['id'] == omnione_lu_affiliationrole['id']);
  
                    if(omnione_lu_affiliationrole && selectedOmnione_lu_affiliationrole) {
                      customKey = `${dbKey}.omnione_lu_affiliationrole`;
                  
                      // if (existingLinkedEntity.hasOwnProperty(customKey)) {
                        console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                        console.warn(`${customKey} : ${omnione_lu_affiliationrole['id']}`);
                    
                        existingLinkedEntity[customKey] = omnione_lu_affiliationrole['id'];
                      // }
  
                      customKey = `${dbKey}.omnione_lu_affiliationrole_value`;
  
                      // if (existingLinkedEntity.hasOwnProperty(customKey)) {
                        console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                        console.warn(`${customKey} : ${selectedOmnione_lu_affiliationrole['nameForSearch']}`);
                    
                        existingLinkedEntity[customKey] = selectedOmnione_lu_affiliationrole['nameForSearch'];
                      // }
                    }//if(omnione_lu_affiliationrole && selectedOmnione_lu_affiliationrole)
                  }//end of if(key === 'lookupfields')
                }//end of for (const key in values)
            }

            rawLEData.raw[index] = existingLinkedEntity;
          }//if(existingLinkedEntity)
          else {

            let newLinkEntityData = {
              "@odata.etag": "W/\"1025458734\"",
              "indskr_contactcrid": "e2cfb02e-e213-ed11-b83e-000d3a85f157",
              "indskr_accountcontactaffiliationcr.indskr_accountid@OData.Community.Display.V1.AttributeName": "indskr_accountid",
              "indskr_accountcontactaffiliationcr.indskr_accountid@OData.Community.Display.V1.FormattedValue": "Account Exeevo IN",
              "indskr_accountcontactaffiliationcr.indskr_accountid@Microsoft.Dynamics.CRM.lookuplogicalname": "account",
              "indskr_accountcontactaffiliationcr.indskr_accountid": "a5e5c253-2dc6-ec11-a7b5-000d3a85f915",
              "indskr_accountcontactaffiliationcr.indskr_isprimaryaccount@OData.Community.Display.V1.AttributeName": "indskr_isprimaryaccount",
              "indskr_accountcontactaffiliationcr.indskr_isprimaryaccount@OData.Community.Display.V1.FormattedValue": "No",
              "indskr_accountcontactaffiliationcr.indskr_isprimaryaccount": false,
              "indskr_accountcontactaffiliationcr.indskr_accountcontactaffiliationcrid@OData.Community.Display.V1.AttributeName": "indskr_accountcontactaffiliationcrid",
              "indskr_accountcontactaffiliationcr.indskr_accountcontactaffiliationcrid": "bcf716a5-9018-ed11-b83f-000d3a85f157",
              "indskr_accountcontactaffiliationcr.statecode@OData.Community.Display.V1.AttributeName": "statecode",
              "indskr_accountcontactaffiliationcr.statecode@OData.Community.Display.V1.FormattedValue": "Active",
              "indskr_accountcontactaffiliationcr.statecode": 0,
              "indskr_accountcontactaffiliationcr.createdon@OData.Community.Display.V1.AttributeName": "createdon",
              "indskr_accountcontactaffiliationcr.createdon@OData.Community.Display.V1.FormattedValue": "08/10/2022 03:11 PM",
              "indskr_accountcontactaffiliationcr.createdon": "2022-08-10T09:41:46.000Z",
              "indskr_accountcontactaffiliationcr.statuscode@OData.Community.Display.V1.AttributeName": "statuscode",
              "indskr_accountcontactaffiliationcr.statuscode@OData.Community.Display.V1.FormattedValue": "Open",
              "indskr_accountcontactaffiliationcr.statuscode": 1,
              "indskr_accountcontactaffiliationcr.indskr_accountid_Formatted": "Account Exeevo IN",
              "indskr_accountcontactaffiliationcr.indskr_accountid_LogicalName": "account",
              "indskr_accountcontactaffiliationcr.indskr_isprimaryaccount_Formatted": "No",
              "indskr_accountcontactaffiliationcr.statecode_Formatted": "Active",
              "indskr_accountcontactaffiliationcr.createdon_Formatted": "08/10/2022 03:11 PM",
              "indskr_accountcontactaffiliationcr.statuscode_Formatted": "Open"
            };
  
            if(linkedEntity) {
                for (const key in linkedEntity) {
                  if(key === 'lookupfields') {
                    let indskr_contactcrs = linkedEntity[key].find(item => item['entity'] === 'indskr_contactcrs');
                    let accounts = linkedEntity[key].find(item => item['entity'] === 'accounts');
                    let customKey = '';
  
                    if(indskr_contactcrs && accounts) {
                      customKey = `indskr_contactcrid`;
                  
                      if (newLinkEntityData.hasOwnProperty(customKey)) {
                        console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                        console.warn(`${customKey} : ${indskr_contactcrs['id']}`);
                    
                        newLinkEntityData[customKey] = indskr_contactcrs['id'];
                      }
  
                      customKey = `indskr_accountid`;
  
                      if (newLinkEntityData.hasOwnProperty(customKey)) {
                        console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                        console.warn(`${customKey} : ${accounts['id']}`);
                    
                        newLinkEntityData[customKey] = accounts['id'];
                      }
  
                      const affiliatedAccount = this.accountOfflineService.getAccountById(accounts['id']);
  
                      customKey = `${dbKey}.indskr_accountid@OData.Community.Display.V1.FormattedValue`;
  
                      if (newLinkEntityData.hasOwnProperty(customKey)) {
                        console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                        console.warn(`${customKey} : ${affiliatedAccount.accountName}`);
                    
                        newLinkEntityData[customKey] = affiliatedAccount.accountName;
                      }
  
                      customKey = `${dbKey}.indskr_accountid_Formatted`;
  
                      if (newLinkEntityData.hasOwnProperty(customKey)) {
                        console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                        console.warn(`${customKey} : ${affiliatedAccount.accountName}`);
                    
                        newLinkEntityData[customKey] = affiliatedAccount.accountName;
                      }
                    }//end of if(indskr_contactcrs && accounts)

                    const omnione_lu_affiliationrole = linkedEntity[key].find(item => item['name'] === 'omnione_lu_affiliationrole');
                    const selectedOmnione_lu_affiliationrole = this.dynamicFormsService.allLookUpFieldsForOfflineEdit['omnione_lu_affiliationrole'].find(item => item['id'] == omnione_lu_affiliationrole['id']);
  
                    if(omnione_lu_affiliationrole && selectedOmnione_lu_affiliationrole) {
                      customKey = `${dbKey}.omnione_lu_affiliationrole`;
                  
                      // if (existingLinkedEntity.hasOwnProperty(customKey)) {
                        console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                        console.warn(`${customKey} : ${omnione_lu_affiliationrole['id']}`);
                    
                        newLinkEntityData[customKey] = omnione_lu_affiliationrole['id'];
                      // }
  
                      customKey = `${dbKey}.omnione_lu_affiliationrole_value`;
  
                      // if (existingLinkedEntity.hasOwnProperty(customKey)) {
                        console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                        console.warn(`${customKey} : ${selectedOmnione_lu_affiliationrole['nameForSearch']}`);
                    
                        newLinkEntityData[customKey] = selectedOmnione_lu_affiliationrole['nameForSearch'];
                      // }
                    }//if(omnione_lu_affiliationrole && selectedOmnione_lu_affiliationrole)

                  }//end of if(key === 'lookupfields')
                }//end of for (const key in values)
            }

            rawLEData.raw.push(newLinkEntityData);
          }
        }//end of if(dbKey === 'indskr_accountcontactaffiliationcr')
        else if(dbKey === 'indskr_customerspecialtychangerequest') {
          let existingLinkedEntity = rawLEData.raw.find(item => item['indskr_customerspecialtychangerequest.indskr_customerspecialtychangerequestid'] === linkedEntity['id']);
          let index = rawLEData.raw.indexOf(existingLinkedEntity);
          
          if(existingLinkedEntity) {

            if(linkedEntity) {
              for (const key in linkedEntity) {
                  if(key === 'lookupfields') {
                    const omnione_lu_specialties = linkedEntity[key].find(item => item['name'] === 'omnione_lu_specialties');
                    const selectedOmnione_lu_specialties = this.dynamicFormsService.allLookUpFieldsForOfflineEdit['omnione_lu_specialties'].find(item => item['id'] == omnione_lu_specialties['id']);
  
                    let customKey = '';

                    if(omnione_lu_specialties && selectedOmnione_lu_specialties) {
                      customKey = `${dbKey}.omnione_lu_specialties`;
                  
                      if (existingLinkedEntity.hasOwnProperty(customKey)) {
                        console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                        console.warn(`${customKey} : ${omnione_lu_specialties['id']}`);
                    
                        existingLinkedEntity[customKey] = omnione_lu_specialties['id'];
                      }
  
                      customKey = `${dbKey}.omnione_lu_specialties_value`;
  
                      if (existingLinkedEntity.hasOwnProperty(customKey)) {
                        console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                        console.warn(`${customKey} : ${selectedOmnione_lu_specialties['nameForSearch']}`);
                    
                        existingLinkedEntity[customKey] = selectedOmnione_lu_specialties['nameForSearch'];
                      }
                    }
                  }// end of if(key === 'lookupfields')

                  let customKey = `${dbKey}.${key}`;
                
                  if (existingLinkedEntity.hasOwnProperty(customKey)) {
                    console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                    console.warn(`${customKey} : ${linkedEntity[key]}`);
                
                    existingLinkedEntity[customKey] = linkedEntity[key];
                  }

                  customKey = `indskr_contactcrid`;
                  
                  if (existingLinkedEntity.hasOwnProperty(customKey)) {
                    console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                    console.warn(`${customKey} : ${contactcrid}`);
                
                    existingLinkedEntity[customKey] = contactcrid;
                  }
                }//end of for (const key in values)
            }
            
            rawLEData.raw[index] = existingLinkedEntity;
          }//if(existingLinkedEntity)
          else {

            let newLinkEntityData = {
              "@odata.etag": "W/\"1047595223\"",
              "indskr_contactcrid": "0b6d29f2-9224-ed11-9db2-000d3a85f157",
              "indskr_customerspecialtychangerequest.omnione_lu_specialties@OData.Community.Display.V1.AttributeName": "omnione_onekeycodeslabelsid",
              "indskr_customerspecialtychangerequest.omnione_lu_specialties": "85ea8ab4-12ca-ec11-a7b6-000d3a85783d",
              "indskr_customerspecialtychangerequest.statecode@OData.Community.Display.V1.AttributeName": "statecode",
              "indskr_customerspecialtychangerequest.statecode@OData.Community.Display.V1.FormattedValue": "Active",
              "indskr_customerspecialtychangerequest.statecode": 0,
              "indskr_customerspecialtychangerequest.statecode_Formatted": "Active",
              "indskr_customerspecialtychangerequest.indskr_customerspecialtychangerequestid@OData.Community.Display.V1.AttributeName": "indskr_customerspecialtychangerequestid",
              "indskr_customerspecialtychangerequest.indskr_customerspecialtychangerequestid": "8ab821f8-9224-ed11-9db2-000d3a85f157",
              "indskr_customerspecialtychangerequest.omnione_lu_specialties_value@OData.Community.Display.V1.AttributeName": "omnione_en_long_label",
              "indskr_customerspecialtychangerequest.omnione_lu_specialties_value": "Dental Implants",
              "indskr_customerspecialtychangerequest.statuscode@OData.Community.Display.V1.AttributeName": "statuscode",
              "indskr_customerspecialtychangerequest.statuscode@OData.Community.Display.V1.FormattedValue": "Open",
              "indskr_customerspecialtychangerequest.statuscode": 1,
              "indskr_customerspecialtychangerequest.statuscode_Formatted": "Open"
            };

            if(linkedEntity) {
              for (const key in linkedEntity) {
                if(key === 'lookupfields') {
                  const omnione_lu_specialties = linkedEntity[key].find(item => item['name'] === 'omnione_lu_specialties');
                  const selectedOmnione_lu_specialties = this.dynamicFormsService.allLookUpFieldsForOfflineEdit['omnione_lu_specialties'].find(item => item['id'] == omnione_lu_specialties['id']);

                  let customKey = '';

                  if(omnione_lu_specialties && selectedOmnione_lu_specialties) {
                    customKey = `${dbKey}.omnione_lu_specialties`;
                
                    if (newLinkEntityData.hasOwnProperty(customKey)) {
                      console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                      console.warn(`${customKey} : ${omnione_lu_specialties['id']}`);
                  
                      newLinkEntityData[customKey] = omnione_lu_specialties['id'];
                    }

                    customKey = `${dbKey}.omnione_lu_specialties_value`;

                    if (newLinkEntityData.hasOwnProperty(customKey)) {
                      console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                      console.warn(`${customKey} : ${selectedOmnione_lu_specialties['nameForSearch']}`);
                  
                      newLinkEntityData[customKey] = selectedOmnione_lu_specialties['nameForSearch'];
                    }
                  }
                }// end of if(key === 'lookupfields')

                let customKey = `${dbKey}.${key}`;
              
                if (newLinkEntityData.hasOwnProperty(customKey)) {
                  console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                  console.warn(`${customKey} : ${linkedEntity[key]}`);
              
                  newLinkEntityData[customKey] = linkedEntity[key];
                }

                customKey = `indskr_contactcrid`;
                
                if (newLinkEntityData.hasOwnProperty(customKey)) {
                  console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                  console.warn(`${customKey} : ${contactcrid}`);
              
                  newLinkEntityData[customKey] = contactcrid;
                }
              }//end of for (const key in values)
            }

            rawLEData.raw.push(newLinkEntityData);
          }
        }//end of if(dbKey === 'indskr_customerspecialtychangerequest')

        await this.diskService.updateOrInsert(linkedEntityDBPrefix + dbKey, (doc)=>rawLEData)
        .catch(error => console.error('Save offlineEditedBusinessCR LinkEntities to DB error:', error))
      }

      });//end of await linkEntitiesArr.forEach
      
    });//end of await linkEntities.forEach
   }

    // this.mapDFContact([editedContactRawData], localRaw, false && !contact.ID);

    return editedBusinessCRRawData;
  }

  async fetchOneKeyCRConfiguredDisplayOffline(data:any, linkEntities: any) {
    let dbContactsCRs = [];
    try {
      let rawContactsDoc: any = await this.diskService.retrieve(DB_KEY_PREFIXES.CONTACT_CR);
      if (rawContactsDoc && rawContactsDoc.raw && Array.isArray(rawContactsDoc.raw)) {
        dbContactsCRs = rawContactsDoc.raw;
        let idx;
        if(Array.isArray(dbContactsCRs) && dbContactsCRs.length){
          idx = dbContactsCRs.findIndex(a=> a['indskr_contactcrid'] == data['indskr_contactcrid']);
        }
        if(idx >= 0){
          dbContactsCRs[idx] = data;
        }else{
          dbContactsCRs.push(data);
        }
        await this.diskService.updateOrInsert(DB_KEY_PREFIXES.CONTACT_CR, (doc) => {
          if (!doc || !doc.raw) {
            doc = {
              raw: []
            };
          }
          doc.raw = dbContactsCRs;
          return doc;
        });
      }
    } catch (error) {
      console.warn('mapAppCreatedContactCRs: No data to load');
    }

    if(linkEntities) {
      await linkEntities.forEach(async linkedEntity => {
      
      const linkedEntityDBPrefix = DB_KEY_PREFIXES.CONTACT_CR_LINKED_ENTITY;
      let dbKey = linkedEntity.entity;
      
      let linkEntitiesArr = linkedEntity.values;

      if(linkedEntity.entity == 'indskr_emailaddresscrs')
        dbKey = 'indskr_emailaddresscr';
      else if(linkedEntity.entity == 'indskr_accountcontactaffiliationcrs') {
        dbKey = 'indskr_accountcontactaffiliationcr';
      }

      let rawLEData = await this.diskService.retrieve(linkedEntityDBPrefix + dbKey);

      await linkEntitiesArr.forEach(async linkedEntity => {

      if(rawLEData && rawLEData.raw) {

        if(dbKey === 'indskr_emailaddresscr') {
          let newLinkEntityData = {
          "@odata.etag": "W/\"1025458734\"",
          "indskr_contactcrid": data['indskr_contactcrid'],
          "indskr_emailaddresscr.statuscode@OData.Community.Display.V1.AttributeName": "statuscode",
          "indskr_emailaddresscr.statuscode@OData.Community.Display.V1.FormattedValue": "Open",
          "indskr_emailaddresscr.statuscode": 1,
          "indskr_emailaddresscr.createdon@OData.Community.Display.V1.AttributeName": "createdon",
          "indskr_emailaddresscr.createdon@OData.Community.Display.V1.FormattedValue": "08/10/2022 04:35 PM",
          "indskr_emailaddresscr.createdon": "2022-08-10T11:05:22.000Z",
          "indskr_emailaddresscr.indskr_emailaddresscrid@OData.Community.Display.V1.AttributeName": "indskr_emailaddresscrid",
          "indskr_emailaddresscr.indskr_emailaddresscrid": "3c46054f-9c18-ed11-b83f-000d3a85f157",
          "indskr_emailaddresscr.indskr_name@OData.Community.Display.V1.AttributeName": "indskr_name",
          "indskr_emailaddresscr.indskr_name": "og",
          "indskr_emailaddresscr.indskr_isprimary@OData.Community.Display.V1.AttributeName": "indskr_isprimary",
          "indskr_emailaddresscr.indskr_isprimary@OData.Community.Display.V1.FormattedValue": "No",
          "indskr_emailaddresscr.indskr_isprimary": false,
          "indskr_emailaddresscr.indskr_type@OData.Community.Display.V1.AttributeName": "indskr_type",
          "indskr_emailaddresscr.indskr_type@OData.Community.Display.V1.FormattedValue": "Work",
          "indskr_emailaddresscr.indskr_type": 100000001,
          "indskr_emailaddresscr.indskr_emailaddress@OData.Community.Display.V1.AttributeName": "indskr_emailaddress",
          "indskr_emailaddresscr.indskr_emailaddress": "og@gmail.com",
          "indskr_emailaddresscr.statecode@OData.Community.Display.V1.AttributeName": "statecode",
          "indskr_emailaddresscr.statecode@OData.Community.Display.V1.FormattedValue": "Active",
          "indskr_emailaddresscr.statecode": 0,
          "indskr_emailaddresscr.statuscode_Formatted": "Open",
          "indskr_emailaddresscr.createdon_Formatted": "08/10/2022 04:35 PM",
          "indskr_emailaddresscr.indskr_isprimary_Formatted": "No",
          "indskr_emailaddresscr.indskr_type_Formatted": "Work",
          "indskr_emailaddresscr.statecode_Formatted": "Active"
          };

          if(linkedEntity) {
            for (const key in linkedEntity) {
                let customKey = `${dbKey}.${key}`;
              
                if (newLinkEntityData.hasOwnProperty(customKey)) {
                  console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                  console.warn(`${customKey} : ${linkedEntity[key]}`);
              
                  newLinkEntityData[customKey] = linkedEntity[key];
                }

                if(customKey === 'indskr_emailaddresscr.indskr_isprimary')
                {
                  let value = linkedEntity[key] === true ? 'Yes':'No' ;

                  let newCustomKey = `${dbKey}.indskr_isprimary_Formatted`;

                  if (newLinkEntityData.hasOwnProperty(newCustomKey)) {
                    console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                    console.warn(`${newCustomKey} : ${value}`);
                
                    newLinkEntityData[newCustomKey] = value;
                  }

                  newCustomKey = `${dbKey}.indskr_isprimary@OData.Community.Display.V1.FormattedValue`;

                  if (newLinkEntityData.hasOwnProperty(newCustomKey)) {
                    console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                    console.warn(`${newCustomKey} : ${value}`);
                
                    newLinkEntityData[newCustomKey] = value;
                  }
                }
              }//end of for (const key in values)
          }

          rawLEData.raw.push(newLinkEntityData);
        }//end of if(dbKey === 'indskr_email_address')
        else if(dbKey === 'indskr_accountcontactaffiliationcr') {
          let newLinkEntityData = {
            "@odata.etag": "W/\"1025458734\"",
            "indskr_contactcrid": data['indskr_contactcrid'],
            "indskr_accountcontactaffiliationcr.indskr_accountid@OData.Community.Display.V1.AttributeName": "indskr_accountid",
            "indskr_accountcontactaffiliationcr.indskr_accountid@OData.Community.Display.V1.FormattedValue": "Account Exeevo IN",
            "indskr_accountcontactaffiliationcr.indskr_accountid@Microsoft.Dynamics.CRM.lookuplogicalname": "account",
            "indskr_accountcontactaffiliationcr.indskr_accountid": "a5e5c253-2dc6-ec11-a7b5-000d3a85f915",
            "indskr_accountcontactaffiliationcr.indskr_isprimaryaccount@OData.Community.Display.V1.AttributeName": "indskr_isprimaryaccount",
            "indskr_accountcontactaffiliationcr.indskr_isprimaryaccount@OData.Community.Display.V1.FormattedValue": "No",
            "indskr_accountcontactaffiliationcr.indskr_isprimaryaccount": false,
            "indskr_accountcontactaffiliationcr.indskr_accountcontactaffiliationcrid@OData.Community.Display.V1.AttributeName": "indskr_accountcontactaffiliationcrid",
            "indskr_accountcontactaffiliationcr.indskr_accountcontactaffiliationcrid": "bcf716a5-9018-ed11-b83f-000d3a85f157",
            "indskr_accountcontactaffiliationcr.statecode@OData.Community.Display.V1.AttributeName": "statecode",
            "indskr_accountcontactaffiliationcr.statecode@OData.Community.Display.V1.FormattedValue": "Active",
            "indskr_accountcontactaffiliationcr.statecode": 0,
            "indskr_accountcontactaffiliationcr.createdon@OData.Community.Display.V1.AttributeName": "createdon",
            "indskr_accountcontactaffiliationcr.createdon@OData.Community.Display.V1.FormattedValue": "08/10/2022 03:11 PM",
            "indskr_accountcontactaffiliationcr.createdon": "2022-08-10T09:41:46.000Z",
            "indskr_accountcontactaffiliationcr.statuscode@OData.Community.Display.V1.AttributeName": "statuscode",
            "indskr_accountcontactaffiliationcr.statuscode@OData.Community.Display.V1.FormattedValue": "Open",
            "indskr_accountcontactaffiliationcr.statuscode": 1,
            "indskr_accountcontactaffiliationcr.indskr_accountid_Formatted": "Account Exeevo IN",
            "indskr_accountcontactaffiliationcr.indskr_accountid_LogicalName": "account",
            "indskr_accountcontactaffiliationcr.indskr_isprimaryaccount_Formatted": "No",
            "indskr_accountcontactaffiliationcr.statecode_Formatted": "Active",
            "indskr_accountcontactaffiliationcr.createdon_Formatted": "08/10/2022 03:11 PM",
            "indskr_accountcontactaffiliationcr.statuscode_Formatted": "Open"
          };

          if(linkedEntity) {
              for (const key in linkedEntity) {
                if(key === 'lookupfields') {
                  let indskr_contactcrs = linkedEntity[key].find(item => item['entity'] === 'indskr_contactcrs');
                  let accounts = linkedEntity[key].find(item => item['entity'] === 'accounts');
                  let customKey = '';

                  if(indskr_contactcrs && accounts) {
                    customKey = `indskr_contactcrid`;
                
                    if (newLinkEntityData.hasOwnProperty(customKey)) {
                      console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                      console.warn(`${customKey} : ${data['indskr_contactcrid']}`);
                  
                      newLinkEntityData[customKey] = data['indskr_contactcrid'];
                    }

                    customKey = `indskr_accountid`;

                    if (newLinkEntityData.hasOwnProperty(customKey)) {
                      console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                      console.warn(`${customKey} : ${accounts['id']}`);
                  
                      newLinkEntityData[customKey] = accounts['id'];
                    }

                    const affiliatedAccount = this.accountOfflineService.getAccountById(accounts['id']);

                    customKey = `${dbKey}.indskr_accountid@OData.Community.Display.V1.FormattedValue`;

                    if (newLinkEntityData.hasOwnProperty(customKey)) {
                      console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                      console.warn(`${customKey} : ${affiliatedAccount.accountName}`);
                  
                      newLinkEntityData[customKey] = affiliatedAccount.accountName;
                    }

                    customKey = `${dbKey}.indskr_accountid_Formatted`;

                    if (newLinkEntityData.hasOwnProperty(customKey)) {
                      console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                      console.warn(`${customKey} : ${affiliatedAccount.accountName}`);
                  
                      newLinkEntityData[customKey] = affiliatedAccount.accountName;
                    }
                  }//end of if(indskr_contactcrs && accounts)

                  const omnione_lu_affiliationrole = linkedEntity[key].find(item => item['name'] === 'omnione_lu_affiliationrole');
                  const selectedOmnione_lu_affiliationrole = this.dynamicFormsService.allLookUpFieldsForOfflineEdit['omnione_lu_affiliationrole'].find(item => item['id'] == omnione_lu_affiliationrole['id']);

                  if(omnione_lu_affiliationrole && selectedOmnione_lu_affiliationrole) {
                    customKey = `${dbKey}.omnione_lu_affiliationrole`;
                
                    // if (existingLinkedEntity.hasOwnProperty(customKey)) {
                      console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                      console.warn(`${customKey} : ${omnione_lu_affiliationrole['id']}`);
                  
                      newLinkEntityData[customKey] = omnione_lu_affiliationrole['id'];
                    // }

                    customKey = `${dbKey}.omnione_lu_affiliationrole_value`;

                    // if (existingLinkedEntity.hasOwnProperty(customKey)) {
                      console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                      console.warn(`${customKey} : ${selectedOmnione_lu_affiliationrole['nameForSearch']}`);
                  
                      newLinkEntityData[customKey] = selectedOmnione_lu_affiliationrole['nameForSearch'];
                    // }
                  }//if(omnione_lu_affiliationrole && selectedOmnione_lu_affiliationrole)

                }//end of if(key === 'lookupfields')
              }//end of for (const key in values)
          }

          rawLEData.raw.push(newLinkEntityData);
        }//end of if(dbKey === 'indskr_accountcontactaffiliationcr')

        await this.diskService.updateOrInsert(linkedEntityDBPrefix + dbKey, (doc)=>rawLEData)
        .catch(error => console.error('Save offlineEditedBusinessCR LinkEntities to DB error:', error))
      }

      });//end of await linkEntitiesArr.forEach
      
    });//end of await linkEntities.forEach
   }

    // this.mapDFContact([editedContactRawData], localRaw, false && !contact.ID);
  }

  private async fetchContactsLinkedEntityData(linkedEntityDefArray: Control[], positionFilterString, hourDifference, dynamicFormType:DynamicFormType, contactid, loadFromDB, isInitialSync,fetchMultilingualFields:boolean = false) {
    // let contactLinkEntities = this.contact

    for (var i = 0; i < linkedEntityDefArray.length; i++) {
      let linkEntity = linkedEntityDefArray[i];
      this.contactLinkEntities.push(linkEntity.subgrid.referencingEntity);
      let offlineContactCRLinkEntityData = await this.diskService.retrieve(DB_KEY_PREFIXES.CONTACT_CR_LINKED_ENTITY + linkEntity.subgrid.referencingEntity);
      //#region fetchxml replace
      let fetchXML = fetchQueries.configuredFormFetchXMLs.fetchConfiguredFormEntityPaging;
      fetchXML = fetchXML.replace('{parentEntityName}', 'indskr_contactcr');
      fetchXML = fetchXML.replace('{parentLevelAttributes}', '<attribute name="indskr_contactcrid"/>');
      fetchXML = fetchXML.replace('{linkEntityPlaceholder}', fetchQueries.configuredFormFetchXMLs.linkEntityPlaceholder);
      fetchXML = fetchXML.replace('{linkEntityName}', linkEntity.subgrid.referencingEntity);
      fetchXML = fetchXML.replace('{linkEntityAttr}', linkEntity.subgrid.referencingAttribute);
      fetchXML = fetchXML.replace('{prentEntityAttr}', linkEntity.subgrid.parentAttribute);
      fetchXML = fetchXML.replace('{linkEntityAlias}', linkEntity.subgrid.referencingEntity);
      fetchXML = fetchXML.replace('{customFilterLevel2}', '');
      fetchXML = fetchXML.replace('{secondaryInfoPlaceholder}', '');
      fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', '');

      if(contactid) {
        let contactidFilter = `<filter type="and"><condition attribute="indskr_contactcrid" operator="eq" value="` + contactid + `" /></filter>`
        fetchXML = fetchXML.replace('{customFilterLevel1}', contactidFilter);
        fetchXML = fetchXML.replace('{positionFilterlevel1}', '');
      } else {
        fetchXML = fetchXML.replace('{positionFilterlevel1}', positionFilterString);
        fetchXML = fetchXML.replace('{customFilterLevel1}', '');
      }

      //modifiedon filter
      if (hourDifference && !contactid) {
        let lastModifiedFilter =
          `<filter type="and">
                        <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" />
                    </filter>`
        fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', lastModifiedFilter);
      }
      else {
        fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', '');
      }

      //#endregion


      //check if it linkentity is also a mandatory data
      let requiredLinkEntity = FETCH_CONTACT_CR_LINK_ENTITIES.find(x => x.entityName == linkEntity.subgrid.referencingEntity)
      let shouldMerge = false;
      let requiredJSONQuery;
      if (requiredLinkEntity) {
        if (requiredLinkEntity.entityFetchXML) {
          shouldMerge = true;
          XML2JS.parseString(requiredLinkEntity.entityFetchXML, (err, data) => {
            requiredJSONQuery = data;
          })
        }
      }

      //generate attributes list and next level link-entities
      let queryString = linkEntity.subgrid.subgridQuery;
      let JSONQuery;
      let linkEntityAttributesStr = '';
      XML2JS.parseString(queryString, (err, data) => {
        JSONQuery = data;
      })

      let multilingualAttributes = [];
      if (fetchMultilingualFields && linkEntity.subgrid && linkEntity.subgrid.subgridLayout && linkEntity.subgrid.subgridLayout.length > 0) {
        multilingualAttributes = linkEntity.subgrid.subgridLayout.filter(a => a.targetEntity && a.targetEntity == 'omnione_onekeycodeslabels').map(a => a.attribute);
      }

      JSONQuery.fetch.entity[0].attribute.forEach(attr => {
        let idx;
        if (multilingualAttributes.length > 0) {
          idx = multilingualAttributes.findIndex(a => a == attr.$.name);
        }
        if (idx >= 0) {
          let key = this.languageService.getOneKeyTableAttributes();
          linkEntityAttributesStr += `
                  <link-entity name="omnione_onekeycodeslabels" from="omnione_onekeycodeslabelsid" to="${attr.$.name}" visible="false" link-type="outer" >
                    <attribute name="${key}" alias="${JSONQuery.fetch.entity[0].$.name}.${attr.$.name}_value"/>
                    ${this.authService.user.localeId != '1033' ? `<attribute name="omnione_en_long_label" alias="${JSONQuery.fetch.entity[0].$.name}.${attr.$.name}_fallbackvalue"/>` : ''}
                    <attribute name="omnione_onekeycodeslabelsid" alias="${JSONQuery.fetch.entity[0].$.name}.${attr.$.name}"/>
                  </link-entity>
                  `;
        } else {
          linkEntityAttributesStr += '<attribute name="' + attr.$.name + '"/>'
        }
      });

      let numOfLinkEntity = 0; //keep track the number of link entity. Needed for building global search fetchxml
      //Do it for the required data
      if (shouldMerge) {
        requiredJSONQuery.fetch.entity[0].attribute.forEach(attr => {
          linkEntityAttributesStr += '<attribute name="' + attr.$.name + '"/>'
        });
        if (JSONQuery.fetch.entity[0]['link-entity'] && JSONQuery.fetch.entity[0]['link-entity'].length) {
          JSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
            numOfLinkEntity ++;
            try {
              linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
                + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";

              linEnt.attribute.forEach(linEntAttr => {
                linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
              });

              if (Array.isArray(requiredJSONQuery.fetch.entity[0]['link-entity'])) {
                let reqLE = requiredJSONQuery.fetch.entity[0]['link-entity'].find(x => x.$.name == linEnt.$.name);
                if (reqLE) {
                  reqLE.attribute.forEach(reqLinEntAttr => {
                    if (!linEnt.attribute.some(x => x.$.name == reqLinEntAttr.$.name)) {
                      linkEntityAttributesStr += '<attribute name="' + reqLinEntAttr.$.name + '"/>'
                    }
                  });
                }
              }
              linkEntityAttributesStr += "</link-entity>"
            } catch (error) {
              console.log(error);
            }
          });
        }

        if (requiredJSONQuery.fetch.entity[0]['link-entity'] && requiredJSONQuery.fetch.entity[0]['link-entity'].length) {
          requiredJSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
            numOfLinkEntity ++;
            try {
              if (Array.isArray(JSONQuery.fetch.entity[0]['link-entity']) && JSONQuery.fetch.entity[0]['link-entity'].some(x => x.$.name == linEnt.$.name)) return;

              linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
                + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";

              if(linEnt.attribute) {
                linEnt.attribute.forEach(linEntAttr => {
                  linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
                });
              }
              linkEntityAttributesStr += "</link-entity>"
            } catch (error) {
              console.log(error);
            }
          });
        }
      } else {
        // if no reqired data
        if (JSONQuery.fetch.entity[0]['link-entity'] && JSONQuery.fetch.entity[0]['link-entity'].length) {
          JSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
            numOfLinkEntity ++;
            try {
              linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
                + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";
              if(linEnt.attribute) {
                linEnt.attribute.forEach(linEntAttr => {
                  linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
                });
              }

              linkEntityAttributesStr += "</link-entity>"
            } catch (error) {
              console.log(error);
            }

          });
        }
      }

      linkEntityAttributesStr += `<attribute name="statecode"/><attribute name="statuscode"/>`;
      fetchXML = fetchXML.replace('{linkEntityAttributes}', linkEntityAttributesStr);
      if (!loadFromDB) {
        let pagingInfo: any = null;
        let rawAllData = [];
      
        do {
            try {
              let response = await this.dynamics.executeFetchXml(
                'indskr_contactcrs',
                fetchXML,
                undefined,
                pagingInfo?.nextPage ?? 0,
                pagingInfo?.cookie ?? null,
              );
        
              if (response) {
                pagingInfo = response.PagingInfo ?? null;
                
                if (Array.isArray(response.value)) {
                  rawAllData.push(...response.value);
                }
              }
        
            } catch (error) {
              console.log("Error retreiving linked entity data: ", error);
              pagingInfo = null;
              rawAllData = null;
            }
        } while (pagingInfo !== null);
        
        // mapping responses
        let dataToSave = rawAllData;
        const leIdAttrName = linkEntity.subgrid.referencingEntity + "." + linkEntity.subgrid.referencingEntity + "id";
        dataToSave = await this.setDeltaObjectsForLinkEntity(isInitialSync, contactid, rawAllData, offlineContactCRLinkEntityData, dataToSave, leIdAttrName);
        await this.diskService.updateOrInsert(DB_KEY_PREFIXES.CONTACT_CR_LINKED_ENTITY + linkEntity.subgrid.referencingEntity, doc => {
          doc = {
            raw: dataToSave,
          };
          return doc;
        }).catch(error => console.error('Save Forms LE to DB error: ', error));

      }
    }
    await this.purgeUnusedLinkEntity(this.contactLinkEntities);

  }

  async setDeltaObjectsForLinkEntity(isInitialSync, ID, res, offlineData, result, linkEntityAttrName): Promise<any> {
    if((!isInitialSync || ID) && Array.isArray(res)) {
      let localRaw = offlineData ? offlineData.raw : [];
      res.forEach(dRes => {
        if (dRes) {
          let idx;
          if (this.TYPE === 'CONTACT_CR') {
            idx = localRaw.findIndex(a => a && a.hasOwnProperty('indskr_contactcrid') && a['indskr_contactcrid'] == dRes["indskr_contactcrid"] && a[linkEntityAttrName] == dRes[linkEntityAttrName]);
          } else {
            idx = localRaw.findIndex(a => a && a.hasOwnProperty('indskr_accountcrid') && a['indskr_accountcrid'] == dRes["indskr_accountcrid"] && a[linkEntityAttrName] == dRes[linkEntityAttrName]);
          }
          if (idx >= 0) {
            localRaw[idx] = dRes;
          }
          else {
            localRaw.push(dRes);
          }
        }
      });
      result = localRaw;
    }
    return result;
  }

  async setDeltaObjects(isInitialSync, ID, res, offlineData, result): Promise<any> {
    if((!isInitialSync || ID) && Array.isArray(res)) {
      let localRaw = offlineData ? offlineData.raw : [];
      res.forEach(dRes => {
        if (dRes) {
          let idx;
          if (this.TYPE === 'CONTACT_CR') {
            idx = localRaw.findIndex(a => a && a.hasOwnProperty('indskr_contactcrid') && a['indskr_contactcrid'] == dRes["indskr_contactcrid"]);
          } else {
            idx = localRaw.findIndex(a => a && a.hasOwnProperty('indskr_accountcrid') && a['indskr_accountcrid'] == dRes["indskr_accountcrid"]);
          }
          if (idx >= 0) {
            localRaw[idx] = dRes;
          }
          else {
            localRaw.push(dRes);
          }
        }
      });
      result = localRaw;
    }
    return result;
  }

  private async fetchAccountsLinkedEntityData(linkedEntityDefArray: Control[], positionFilterString, hourDifference, dynamicFormType:DynamicFormType, accountid, loadFromDB, isInitialSync,fetchMultilingualFields:boolean = false) {

    for (let i = 0; i < linkedEntityDefArray.length; i++) {
      let linkEntity = linkedEntityDefArray[i];
      this.accountLinkEntities.push(linkEntity.subgrid.referencingEntity);
      let offlineAccountCRLinkEntityData = await this.diskService.retrieve(DB_KEY_PREFIXES.ACCOUNT_CR_LINKED_ENTITY + linkEntity.subgrid.referencingEntity);
      //#region fetchxml replace
      let fetchXML = fetchQueries.configuredFormFetchXMLs.fetchConfiguredFormEntityPaging;
      fetchXML = fetchXML.replace('{parentEntityName}', 'indskr_accountcr');
      fetchXML = fetchXML.replace('{parentLevelAttributes}', '<attribute name="indskr_accountcrid"/>');
      fetchXML = fetchXML.replace('{linkEntityPlaceholder}', fetchQueries.configuredFormFetchXMLs.linkEntityPlaceholder);
      fetchXML = fetchXML.replace('{linkEntityName}', linkEntity.subgrid.referencingEntity);
      if (linkEntity.subgrid.referencingEntity === 'indskr_accountaccountaffiliationcr') {
        fetchXML = fetchXML.replace('{linkEntityAttr}', 'indskr_affiliatedfrom');
      } else {
        fetchXML = fetchXML.replace('{linkEntityAttr}', linkEntity.subgrid.referencingAttribute);
      }
      fetchXML = fetchXML.replace('{prentEntityAttr}', linkEntity.subgrid.parentAttribute);
      fetchXML = fetchXML.replace('{linkEntityAlias}', linkEntity.subgrid.referencingEntity);
      fetchXML = fetchXML.replace('{customFilterLevel2}', '');
      fetchXML = fetchXML.replace('{secondaryInfoPlaceholder}', '');
      fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', '');

      if(accountid) {
        let accountidFilter = `<filter type="and"><condition attribute="indskr_accountcrid" operator="eq" value="` + accountid + `" /></filter>`
        fetchXML = fetchXML.replace('{customFilterLevel1}', accountidFilter);
        fetchXML = fetchXML.replace('{positionFilterlevel1}', '');
      } else {
        fetchXML = fetchXML.replace('{positionFilterlevel1}', positionFilterString);
        fetchXML = fetchXML.replace('{customFilterLevel1}', '');
      }

      //modifiedon filter
      if (hourDifference && !accountid) {
        let lastModifiedFilter =
          `<filter type="and">
                        <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" />
                    </filter>`
        fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', lastModifiedFilter);
      }
      else {
        fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', '');
      }

      //#endregion


      //check if it linkentity is also a mandatory data
      let requiredLinkEntity = FETCH_ACCOUNT_CR_LINK_ENTITIES.find(x => x.entityName == linkEntity.subgrid.referencingEntity)
      let shouldMerge = false;
      let requiredJSONQuery;
      if (requiredLinkEntity) {
        if (requiredLinkEntity.entityFetchXML) {
          shouldMerge = true;
          XML2JS.parseString(requiredLinkEntity.entityFetchXML, (err, data) => {
            requiredJSONQuery = data;
          })
        }
      }

      //generate attributes list and next level link-entities
      let queryString = linkEntity.subgrid.subgridQuery;
      let JSONQuery;
      let linkEntityAttributesStr = '';
      XML2JS.parseString(queryString, (err, data) => {
        JSONQuery = data;
      })

      let multilingualAttributes = [];
      if (fetchMultilingualFields && linkEntity.subgrid && linkEntity.subgrid.subgridLayout && linkEntity.subgrid.subgridLayout.length > 0) {
        multilingualAttributes = linkEntity.subgrid.subgridLayout.filter(a => a.targetEntity && a.targetEntity == 'omnione_onekeycodeslabels').map(a => a.attribute);
      }

      JSONQuery.fetch.entity[0].attribute.forEach(attr => {
        let idx;
        if (multilingualAttributes.length > 0) {
          idx = multilingualAttributes.findIndex(a => a == attr.$.name);
        }
        if (idx >= 0) {
          let key = this.languageService.getOneKeyTableAttributes();
          linkEntityAttributesStr += `
                  <link-entity name="omnione_onekeycodeslabels" from="omnione_onekeycodeslabelsid" to="${attr.$.name}" visible="false" link-type="outer" >
                    <attribute name="${key}" alias="${JSONQuery.fetch.entity[0].$.name}.${attr.$.name}_value"/>
                    ${this.authService.user.localeId != '1033' ? `<attribute name="omnione_en_long_label" alias="${JSONQuery.fetch.entity[0].$.name}.${attr.$.name}_fallbackvalue"/>` : ''}
                    <attribute name="omnione_onekeycodeslabelsid" alias="${JSONQuery.fetch.entity[0].$.name}.${attr.$.name}"/>
                  </link-entity>
                  `;
        } else {
          linkEntityAttributesStr += '<attribute name="' + attr.$.name + '"/>'
        }
      });

      let numOfLinkEntity = 0; //keep track the number of link entity. Needed for building global search fetchxml
      //Do it for the required data
      if (shouldMerge) {
        requiredJSONQuery.fetch.entity[0].attribute.forEach(attr => {
          linkEntityAttributesStr += '<attribute name="' + attr.$.name + '"/>'
        });
        if (JSONQuery.fetch.entity[0]['link-entity'] && JSONQuery.fetch.entity[0]['link-entity'].length) {
          JSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
            numOfLinkEntity ++;
            try {
              linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
                + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";

              linEnt.attribute.forEach(linEntAttr => {
                linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
              });

              if (Array.isArray(requiredJSONQuery.fetch.entity[0]['link-entity'])) {
                let reqLE = requiredJSONQuery.fetch.entity[0]['link-entity'].find(x => x.$.name == linEnt.$.name);
                if (reqLE) {
                  reqLE.attribute.forEach(reqLinEntAttr => {
                    if (!linEnt.attribute.some(x => x.$.name == reqLinEntAttr.$.name)) {
                      linkEntityAttributesStr += '<attribute name="' + reqLinEntAttr.$.name + '"/>'
                    }
                  });
                }
              }
              linkEntityAttributesStr += "</link-entity>"
            } catch (error) {
              console.log(error);
            }
          });
        }

        if (requiredJSONQuery.fetch.entity[0]['link-entity'] && requiredJSONQuery.fetch.entity[0]['link-entity'].length) {
          requiredJSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
            numOfLinkEntity ++;
            try {
              if (Array.isArray(JSONQuery.fetch.entity[0]['link-entity']) && JSONQuery.fetch.entity[0]['link-entity'].some(x => x.$.name == linEnt.$.name)) return;

              linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
                + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";

              if(linEnt.attribute) {
                linEnt.attribute.forEach(linEntAttr => {
                  linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
                });
              }
              linkEntityAttributesStr += "</link-entity>"
            } catch (error) {
              console.log(error);
            }
          });
        }
      } else {
        // if no reqired data
        if (JSONQuery.fetch.entity[0]['link-entity'] && JSONQuery.fetch.entity[0]['link-entity'].length) {
          JSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
            numOfLinkEntity ++;
            try {
              linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
                + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";
              if(linEnt.attribute) {
                linEnt.attribute.forEach(linEntAttr => {
                  linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
                });
              }

              linkEntityAttributesStr += "</link-entity>"
            } catch (error) {
              console.log(error);
            }

          });
        }
      }

      linkEntityAttributesStr += `<attribute name="statecode"/><attribute name="statuscode"/>`;
      fetchXML = fetchXML.replace('{linkEntityAttributes}', linkEntityAttributesStr);
      if (!loadFromDB) {
        let pagingInfo: any = null;
        let rawAllData = [];
      
        do {
            try {
              let response = await this.dynamics.executeFetchXml(
                'indskr_accountcrs',
                fetchXML,
                undefined,
                pagingInfo?.nextPage ?? 0,
                pagingInfo?.cookie ?? null,
              );
        
              if (response) {
                pagingInfo = response.PagingInfo ?? null;
                
                if (Array.isArray(response.value)) {
                  rawAllData.push(...response.value);
                }
              }
        
            } catch (error) {
              console.log("Error retreiving linked entity data: ", error);
              pagingInfo = null;
              rawAllData = null;
            }
        } while (pagingInfo !== null);
        
        // mapping responses
        let dataToSave = rawAllData;
        const leIdAttrName = linkEntity.subgrid.referencingEntity + "." + linkEntity.subgrid.referencingEntity + "id";
        dataToSave = await this.setDeltaObjectsForLinkEntity(isInitialSync, accountid, rawAllData, offlineAccountCRLinkEntityData, dataToSave, leIdAttrName);
        await this.diskService.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT_CR_LINKED_ENTITY + linkEntity.subgrid.referencingEntity, doc => {
          doc = {
            raw: dataToSave,
          };
          return doc;
        }).catch(error => console.error('Save Forms LE to DB error: ', error));
      }
    }
    await this.purgeUnusedLinkEntityAccountCR(this.accountLinkEntities);

  }

  async purgeUnusedLinkEntity(contactLinkEntities) {
    //Todo: Purge linked entites that's not in the form anymorwe


    await this.diskService.updateOrInsert("contactCRLinkEntities", doc => {
      doc = {
        raw: contactLinkEntities,
      };
      return doc;
    }).catch(error => console.error('Saving list of link entities ', error));

  }

  async purgeUnusedLinkEntityAccountCR(accountLinkEntities) {
    //Todo: Purge linked entites that's not in the form anymorwe


    await this.diskService.updateOrInsert("accountCRLinkEntities", doc => {
      doc = {
        raw: accountLinkEntities,
      };
      return doc;
    }).catch(error => console.error('Saving list of link entities ', error));

  }

  async fetchContactsCRDefaultLinkEntityData(positionFilter, hourDifference, linkedEntityFromForm:any[], contactid, loadFromDB){
    for(var i=0; i < FETCH_CONTACT_CR_LINK_ENTITIES.length; i++) {
      //(linkEntity => {
      let linkEntity = FETCH_CONTACT_CR_LINK_ENTITIES[i];
      // if(!linkEntity.isFetch || linkedEntityFromForm.find(l=> l.subgrid.referencingEntity == linkEntity.entityName)) continue;
      let fetchXML = linkEntity.fetchXML;
      fetchXML = positionFilter ? fetchXML.replace('{PositionFilterPH}', positionFilter) : fetchXML.replace('{PositionFilterPH}', '');

      //modifiedon filter
      if(hourDifference) {
        let lastModifiedFilter = `
                <filter type="and">
                    <condition attribute="modifiedon" operator="last-x-hours" value="`+hourDifference+`" />
                </filter>`
        fetchXML = fetchXML.replace('{DeltaSyncFilter}', lastModifiedFilter);
      }
      else {
        fetchXML = fetchXML.replace('{DeltaSyncFilter}', '');
      }

      if(loadFromDB){
        let dataToSave = await this.diskService.retrieve(linkEntity.entityName);
        // Add to default Linked entity data for mapping to contact object
        if(linkEntity.entityName == 'indskr_emailaddresscr' || linkEntity.entityName == 'indskr_customeraddresscr' || linkEntity.entityName == 'indskr_accountcontactaffiliationcr'){
          this._defaultLinkedEntityMappingData.push(...dataToSave);
        }
      }else{
        await this.dynamics.executeFetchQuery("indskr_contactcrs", fetchXML).then( async (res) => {
            let dataToSave = res;
            if ((hourDifference || contactid) && Array.isArray(res)) {

              let temp = await this.diskService.retrieve(linkEntity.entityName);
              let leIdAttrName = linkEntity.entityName + "." + linkEntity.entityName + "id";
              let localRaw;
              if (temp) {
                localRaw = temp.raw
                res.forEach(rawRes => {
                  if (rawRes && rawRes.hasOwnProperty('indskr_contactcrid') && rawRes.hasOwnProperty(leIdAttrName)) {

                    let idx = localRaw.findIndex(a => a && a.hasOwnProperty('indskr_contactcrid') && a[leIdAttrName] == rawRes[leIdAttrName]);
                    if (idx >= 0) {
                      localRaw[idx] = rawRes;
                    } else {
                      localRaw.push(rawRes);
                    }
                  }
                });
                dataToSave = localRaw;
              }
            }
            // Add to default Linked entity data for mapping to contact object
            if(linkEntity.entityName == 'indskr_emailaddresscr' || linkEntity.entityName == 'indskr_customeraddresscr' || linkEntity.entityName == 'indskr_accountcontactaffiliationcr'){
              this._defaultLinkedEntityMappingData.push(...dataToSave);
            }
            await this.diskService.updateOrInsert(linkEntity.entityName, doc => {
              doc = {
                raw: dataToSave,
              };
              return doc;
            })
              .catch(error => console.error('Save Default LE to DB error: ', error));
          },
          (err) => {
            console.log(err);
          })
      }
    }
  }

  public mapDFContactCR(raw, localData, isResetMemoryData, contactsToDelete?) {
    if(isResetMemoryData) {
      this.contactCRList = [];
    }
    else {
      if(contactsToDelete && Array.isArray(contactsToDelete)) {
        contactsToDelete.forEach(con => {
          if (con) {
            let idx = this.contactCRList.findIndex(a => a && a.hasOwnProperty('indskr_contactcrid') && a['indskr_contactcrid'] == con["indskr_entityid"]);
            if (idx >= 0) {
              this.contactCRList.splice(idx, 1);
            }
          }
        })
      }
    }

    let dataTomMap = Array.isArray(this.contactCRList) && this.contactCRList.length > 0 ? raw : localData;
    dataTomMap = _.uniqBy(dataTomMap,'indskr_contactcrid');
    dataTomMap.forEach(el => {
      if(el && el.hasOwnProperty('indskr_contactcrid')){
        let tempCon = el;
        if(isResetMemoryData) {
          this.contactCRList.push(tempCon);
        } else {
          let idx = this.contactCRList.findIndex(a=> a.indskr_contactcrid == el['indskr_contactcrid']);
          if(idx >= 0){
            tempCon['accountRelationships'] = this.contactCRList[idx]['accountRelationships'];
            tempCon['addressesList'] = this.contactCRList[idx]['addressesList'];
            tempCon['emailAddressList'] = this.contactCRList[idx]['emailAddressList'];
            tempCon['contactRelationships'] = this.contactCRList[idx]['contactRelationships'];
            this.contactCRList[idx] = tempCon;
          } else {
            this.contactCRList.push(tempCon);
          }
        }
      }
    });
    this.contactCRs.next(this.contactCRList);
  }

  public mapDFAccountCR(raw, localData, isResetMemoryData, accountsToDelete?) {
    if(isResetMemoryData) {
      this.accountCRList = [];
    }
    else {
      if(accountsToDelete && Array.isArray(accountsToDelete)) {
        accountsToDelete.forEach(con => {
          if (con) {
            let idx = this.accountCRList.findIndex(a => a && a.hasOwnProperty('indskr_accountcrid') && a['indskr_accountcrid'] == con["indskr_entityid"]);
            if (idx >= 0) {
              this.accountCRList.splice(idx, 1);
            }
          }
        })
      }
    }

    let dataTomMap = Array.isArray(this.accountCRList) && this.accountCRList.length > 0 ? raw : localData;
    dataTomMap = _.uniqBy(dataTomMap,'indskr_accountcrid');
    dataTomMap.forEach(el => {
      if(el && el.hasOwnProperty('indskr_accountcrid')){
        let tempCon = el;
        if(isResetMemoryData) {
          this.accountCRList.push(tempCon);
        } else {
          let idx = this.accountCRList.findIndex(a=> a.indskr_accountcrid == el['indskr_accountcrid']);
          if(idx >= 0){
            tempCon['accountRelationships'] = this.accountCRList[idx]['accountRelationships'];
            tempCon['addressesList'] = this.accountCRList[idx]['addressesList'];
            tempCon['emailAddressList'] = this.accountCRList[idx]['emailAddressList'];
            this.accountCRList[idx] = tempCon;
          } else {
            this.accountCRList.push(tempCon);
          }
        }
      }
    });
    this.accountCRs.next(this.accountCRList);
  }

  async fetchAccountCRConfiguredDisplay(fullsync = false, loadFromDB = false, account = null) {

    this._defaultLinkedEntityMappingData = [];
    this.accountLinkEntities = [];
    this.TYPE = 'ACCOUNT_CR';
    const accountid = account ? account['indskr_accountcrid'] : null;

    let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_accountcr", FormType.DISPLAYFORM);
    let formType = DynamicFormType.CONFIGUREDFORM;
    const fetchMultilingualFields:boolean = this.authService.user.securityRoles.some(a=> a.name == 'iO OneKey User' || a.name == 'iO OneKey Admin');

    let formDef = contactForm;

    if (!contactForm) formType = DynamicFormType.DEFAULTFORM;

    let offlineAccountRawData = await this.diskService.retrieve(DB_KEY_PREFIXES.ACCOUNT_CR);
    if(fetchMultilingualFields && offlineAccountRawData && offlineAccountRawData.lastLocaleId && offlineAccountRawData.lastLocaleId != this.authService.user.localeId){
      fullsync = true;
    } else if(offlineAccountRawData && offlineAccountRawData.formType  && offlineAccountRawData.formType == DynamicFormType.DEFAULTFORM && formType == DynamicFormType.CONFIGUREDFORM){
      fullsync = true;
    }
    else if(formDef && offlineAccountRawData && offlineAccountRawData.formType &&
      offlineAccountRawData.formType == DynamicFormType.CONFIGUREDFORM &&
      offlineAccountRawData.lastInitialSyncTime &&
      isBefore(new Date(offlineAccountRawData.lastInitialSyncTime),new Date(formDef.modiefiedOn))){
      fullsync = true;
    }

    let parentEntityFetchXml = fetchQueries.configuredFormFetchXMLs.fetchConfiguredFormEntity;
    //create attributes
    let parentEntityAttributesStr = '<attribute name="indskr_accountcrid"/>';
    // Default Parent level Attributes

    RequiredAccountCRAttributes.forEach(attr => {
      parentEntityAttributesStr += '<attribute name="' + attr + '"/>'
    });

    let linkEntityAttributesArray = [];
    if (formDef) {
      formDef.metadata.forEach(tab => {
        tab.controls.forEach(control => {
          if (control.dataType) {
            let linkedAttrFetchXML = '';
            if(fetchMultilingualFields && control.lookupEntityPrimaryId && control.lookupEntityPrimaryId == "omnione_onekeycodeslabelsid"){
              let key = this.languageService.getOneKeyTableAttributes();
              linkedAttrFetchXML = `
                <link-entity name="omnione_onekeycodeslabels" from="omnione_onekeycodeslabelsid" to="${control.attributeName}" visible="false" link-type="outer">
                  <attribute name="${key}" alias="${control.attributeName}_value"/>
                  ${this.authService.user.localeId != '1033' ? `<attribute name="omnione_en_long_label" alias="${control.attributeName}_fallbackvalue"/>`:''}
                  <attribute name="omnione_onekeycodeslabelsid" alias="_${control.attributeName}_value"/>
                </link-entity>
              `;
              parentEntityAttributesStr += linkedAttrFetchXML;
              if(this.authService.user.localeId != '1033'){

              }
            }else{
              parentEntityAttributesStr += '<attribute name="' + control.attributeName + '"/>';
            }
          }else if (!control.dataType && control.subgrid) {
            linkEntityAttributesArray.push(control);
          }
        })
      });
    }

    if(loadFromDB) {
      this.mapDFAccountCR("", offlineAccountRawData.raw, true);
    }

    parentEntityFetchXml = parentEntityFetchXml.replace('{parentLevelAttributes}', parentEntityAttributesStr);
    parentEntityFetchXml = parentEntityFetchXml.replace('{parentEntityName}', 'indskr_accountcr');
    parentEntityFetchXml = parentEntityFetchXml.replace('{linkEntityPlaceholder}', '');
    parentEntityFetchXml = parentEntityFetchXml.replace('{secondaryInfoPlaceholder}', '');

    //create delta sync filter
    let syncState = await this.diskService.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ACCOUNT_CR);
    const accountsSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.accountCR,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    let isInitialSync = !syncState || !syncState.lastUpdatedTime || fullsync;
    let hourDifference;
    if (isInitialSync) parentEntityFetchXml = parentEntityFetchXml.replace('{deltasyncFilterLevel1}', '');
    else {
      let now = new Date();
      hourDifference = differenceInHours(
        now,
        new Date(syncState.lastUpdatedTime)
      )
      //add one to make sure we take care of fractional difference in hours
      hourDifference += 1;

      let lastModifiedFilter = `<filter type="or">
              <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" />
            </filter>`

      if(accountid) {
        lastModifiedFilter = `<filter type="or">
                    <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" />
                </filter>`
      }
      parentEntityFetchXml = parentEntityFetchXml.replace('{deltasyncFilterLevel1}', lastModifiedFilter);
    }

    let positionFilterString;
    if(accountid) {
      let accountidFilter = `<filter type="and"><condition attribute="indskr_accountcrid" operator="eq" value="` + accountid + `" /></filter>`
      parentEntityFetchXml = parentEntityFetchXml.replace('{positionFilterlevel1}', '');
      parentEntityFetchXml = parentEntityFetchXml.replace('{customFilterLevel1}', accountidFilter);
    }
    else {
      positionFilterString = `<filter type="and">
              <condition attribute="ownerid" operator="eq-userid" />
            </filter>`
      parentEntityFetchXml = parentEntityFetchXml.replace('{positionFilterlevel1}', positionFilterString);
      parentEntityFetchXml = parentEntityFetchXml.replace('{customFilterLevel1}', '');
    }
    //call dynamics

    let deletedAccountRes;
    if(loadFromDB){
      if(formType === DynamicFormType.CONFIGUREDFORM)
        await this.fetchAccountsLinkedEntityData(linkEntityAttributesArray, positionFilterString, hourDifference, formType, accountid, loadFromDB, isInitialSync);
    }else{
      const result =  await this.dynamics.executeFetchQuery('indskr_accountcrs',parentEntityFetchXml).then(async (res)=>{
        if (!isInitialSync) this.addNotificationsToGo(res, offlineAccountRawData);
        let dataToSave = res;
        let lastInitialSyncTime;
        if (res && Array.isArray(res)) {
          res.forEach((o) => {
            if (o['createdon']) {
              o['createdon'] = new Date(o['createdon']).getTime().toString();
            }
          })
          if (accountid) {
            const response = !_.isEmpty(res) ? res[0] : account;
            await this.traceVR(accountid, response, 'indskr_accountcrs').then(() => {
              const index = this.accountCRs.getValue().findIndex(cr => cr.indskr_accountcrid == accountid);
              if (index >= 0 && response.statuscode != this.accountCRs.getValue()[index].statuscode) {
                this.accountCRs.getValue()[index] = response;
              }
              dataToSave[0] = res[0] = response;
            }).catch(err => {
              console.log(err);
            });
          }
        }
        dataToSave = await this.setDeltaObjects(isInitialSync, accountid, res, offlineAccountRawData, dataToSave);
        await this.diskService.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT_CR, doc => {
          doc = {
            raw: dataToSave,
            lastInitialSyncTime: lastInitialSyncTime,
            lastLocaleId: this.authService.user.localeId,
            formType: formType,
          };
          return doc;
        })
          .catch(error => console.error('Save Forms to DB error: ', error));


        if(formType === DynamicFormType.CONFIGUREDFORM)
          await this.fetchAccountsLinkedEntityData(linkEntityAttributesArray, positionFilterString, hourDifference, formType, accountid, loadFromDB, isInitialSync,fetchMultilingualFields);
        this.mapDFAccountCR(res, dataToSave, isInitialSync && !accountid, deletedAccountRes);

        if (accountsSyncInfo.syncStatus && !accountid) {
          syncState.lastUpdatedTime = new Date().getTime();
          accountsSyncInfo.totalSynced = res.length;
          await this.diskService.updateSyncState(syncState);
        }
        return res;
      }).catch(err => {
        console.log(err);
        this.deltaService.addSyncErrorToEntitySyncInfo(accountsSyncInfo, 'accountcrs', err);
      })
      if(_.isEmpty(result)) {
        return this.accountCRs.getValue().find(cr => cr.indskr_accountcrid == accountid);
      } else {
        return result;
      }
    }

  }

  async traceVR(clientId, dynResp, entity) {
    if (clientId && this.authService.okIntegrationSettings && this.authService.okIntegrationSettings.typeOfIntegration == IntegrationType.ONE_KEY) {
      let _clientId;
      // replaceAll method is not available on chrome versions below 85
      try {
        _clientId = clientId.replaceAll('-','');
      } catch (error) {
        _clientId = clientId.split('-').join('');
      }
      let payload = {
        "validation.clientRequestId": _clientId,
        "codBase": this.authService.user.iqviaCodBase
      }
      await this.okDataService.traceVR(payload).then(async (res) => {
        if (res && res['response'] && res['response']['status'] == 'SUCCESS') {
          let okResponse = res['response']['results'] ? res['response']['results'][0] : null;
          if (okResponse && (okResponse['processStatus'] != VALIDATION_PROCESS.REQUEST_PROCESSED
            && okResponse['processStatus'] != VALIDATION_PROCESS.REQUEST_PENDING_JMS
          && okResponse['processStatus'] != VALIDATION_PROCESS.REQUEST_PENDING_OKE)) {
            let dynResponse = dynResp ? dynResp : null;
            if (dynResponse && (dynResp['statuscode'] != ChangeRequestStatusCode.APPROVED || dynResp['statuscode'] != ChangeRequestStatusCode.REJECTED)) {
              if (okResponse['processStatus'] == VALIDATION_PROCESS.REQUEST_RESPONDED && (okResponse['requestStatus'] == REQUEST_PROCESS.VAS_FOUND || okResponse['requestStatus'] == REQUEST_PROCESS.VAS_FOUND_BUT_INVALID)) {
                dynResp['statuscode'] = ChangeRequestStatusCode.APPROVED;
              } else if (okResponse['processStatus'] == VALIDATION_PROCESS.REQUEST_RESPONDED
                && (okResponse['requestStatus'] == REQUEST_PROCESS.VAS_NOT_FOUND || okResponse['requestStatus'] == REQUEST_PROCESS.VAS_INCOHERENT_REQUEST || okResponse['requestStatus'] == REQUEST_PROCESS.VAS_DUPLICATE_PROCESS)){
                dynResp['statuscode'] = ChangeRequestStatusCode.REJECTED;
              }
              dynResp['indskr_responsecomments'] = okResponse['responseComment'];
              dynResp['omnione_responsedate'] = okResponse['lastResponseDate']
              let payload = {
                'statuscode': dynResp['statuscode'],
                'indskr_responsecomments': dynResp['indskr_responsecomments'] ? dynResp['indskr_responsecomments'] : "",
                'omnione_responsedate': dynResp['omnione_responsedate']
              }
              await this.dynamics.update(clientId, entity, payload);
            }
          }
        }
      })
    }
  }

  addNotificationsToGo(current: any, prev: any) {

    let localRaw = prev ? prev.raw : [];
    let globalCustomerText = '';

    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        globalCustomerText = this.translate.instant('CUSTOMER');
        break;
      case 'Stakeholder':
        globalCustomerText = this.translate.instant('STAKEHOLDER');
        break;
      default:
        globalCustomerText = this.utilityService.globalCustomerText;
        break;
    }

    current.forEach(dRes => {
      if (dRes) {
        if(dRes['indskr_mdm'] === BusinessProcessType.SanofiChina && this.TYPE === 'CONTACT_CR') {
          if (dRes['statuscode'] == ChangeRequestStatusCode.APPROVED) {
            this.cRNotificationModel = {
              type: NOTIFICATION.ONECRM_DCR_APPROVED,
              name: this.translate.instant("ONECRM_DCR_APPROVED"),
              DateTime: Date.now(),
              id: NOTIFICATION.ONECRM_DCR_APPROVED + Date.now(),
              data: dRes,
              icon: '',
              isRed: false,
              params: {}
            };
          } else if (dRes['statuscode'] == ChangeRequestStatusCode.REJECTED) {
            this.cRNotificationModel = {
              type: NOTIFICATION.ONECRM_DCR_REJECTED,
              name: this.translate.instant("ONECRM_DCR_REJECTED"),
              DateTime: Date.now(),
              id: NOTIFICATION.ONECRM_DCR_REJECTED + Date.now(),
              data: dRes,
              icon: '',
              isRed: false,
              params: {}
            };
          }
        } else {
          let idx, crType, crTypeValue, formattedStatus, lastUpdated, toFrom;
          if (this.TYPE === 'CONTACT_CR') {
            idx = localRaw.findIndex(a => a && a.hasOwnProperty('indskr_contactcrid') && a['indskr_contactcrid'] == dRes["indskr_contactcrid"]);
            crType = globalCustomerText.toLowerCase();
            crTypeValue = dRes.indskr_firstname + " " + dRes.indskr_lastname;
          } else {
            idx = localRaw.findIndex(a => a && a.hasOwnProperty('indskr_accountcrid') && a['indskr_accountcrid'] == dRes["indskr_accountcrid"]);
            crType = 'account';
            if (dRes.indskr_accountsourcetype === ChangeRequestType.BUSINESS) crType = "business_account";
            crTypeValue = dRes.indskr_name;
          }

          if (idx >= 0) {
            switch (dRes['statuscode']) {
              case ChangeRequestStatusCode.SUBMITTED:
                formattedStatus = 'submitted';
                toFrom = 'to';
                break;
              case ChangeRequestStatusCode.APPROVED:
                formattedStatus = 'approved';
                toFrom = 'from';
                break;
              case ChangeRequestStatusCode.REJECTED:
                formattedStatus = 'rejected';
                toFrom = 'from';
                break;
              case ChangeRequestStatusCode.IN_PROGRESS:
                formattedStatus = 'inprogress';
                toFrom = 'from';
                break;
              default:
                formattedStatus = dRes['statuscode'];
                toFrom = 'to';
                break;
            }
            lastUpdated = format(dRes['modifiedon'], this.dateTimeFormatsService.dateTimeToUpper);
            let localStatus = localRaw[idx]['statuscode'];
            let resStatus = dRes['statuscode'];
            let notName = "";
            let crID = "";
            let statusTypename = ""

            if (localStatus != resStatus) {
              if (crType === 'business_account') {
                crID = localRaw[idx]['indskr_accountcrid'];
                switch (dRes['statuscode']) {
                  case ChangeRequestStatusCode.APPROVED:
                    statusTypename = "BUSINESS_ACCOUNT_CHANGE_REQUEST_APPROVED";
                    notName = this.translate.instant("BUSINESS_ACCOUNT_CHANGE_REQUEST_APPROVED", { accountName: `${localRaw[idx]['indskr_name']}` });
                    break;
                  case ChangeRequestStatusCode.REJECTED:
                  case ChangeRequestStatusCode.PENDING_APPROVAL:
                    statusTypename = "BUSINESS_ACCOUNT_CHANGE_REQUEST_REJECTED";
                    notName = this.translate.instant("BUSINESS_ACCOUNT_CHANGE_REQUEST_REJECTED", { accountName: `${localRaw[idx]['indskr_name']}` });
                    break;
                  case ChangeRequestStatusCode.SUBMITTED:
                    statusTypename = "BUSINESS_ACCOUNT_CHANGE_REQUEST_SUBMITTED";
                    notName = this.translate.instant("BUSINESS_ACCOUNT_CHANGE_REQUEST_SUBMITTED", { accountName: `${localRaw[idx]['indskr_name']}` });
                    break;
                  case ChangeRequestStatusCode.OPEN:
                    return;
                  case ChangeRequestStatusCode.IN_PROGRESS:
                    statusTypename = "BUSINESS_ACCOUNT_CHANGE_REQUEST_OPEN";
                    notName = this.translate.instant("BUSINESS_ACCOUNT_CHANGE_REQUEST_OPEN", { accountName: `${localRaw[idx]['indskr_name']}` });
                    break;
                }
              } else if (crType == 'account') { 
                crID = localRaw[idx]['indskr_accountcrid'];
                notName = this.translate.instant("ACCOUNT_CHANGE_REQUEST_STATUS", { accountName: crTypeValue, status: formattedStatus });
              } else {
                crID = localRaw[idx]['indskr_contactcrid'];

                switch(dRes['statuscode']) {
                  case ChangeRequestStatusCode.APPROVED:
                    ({ statusTypename, notName } = this.getApprovedCRNotification(dRes, statusTypename, notName, localRaw, idx));
                  
                    break;
                  case ChangeRequestStatusCode.REJECTED:
                  case ChangeRequestStatusCode.PENDING_APPROVAL:
                    ({ statusTypename, notName } = this.getRejectedORPendingApprovalNotification(dRes, statusTypename, notName, localRaw, idx));
                  
                    break;
                  case ChangeRequestStatusCode.SUBMITTED:
                    ({ statusTypename, notName } = this.getSubmitteCRdNotification(dRes, statusTypename, notName, localRaw, idx));
                    break;

                  case ChangeRequestStatusCode.OPEN:
                    statusTypename = "BUSINESS_CHANGE_REQUEST_OPEN";
                    notName = this.translate.instant("BUSINESS_CHANGE_REQUEST_OPEN", {fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}`})

                    break;
                  case ChangeRequestStatusCode.IN_PROGRESS: 
                  ({ statusTypename, notName } = this.getInProgressNotification(dRes, statusTypename, notName, localRaw, idx));
                  break;
                }
              }
              this.cRNotificationModel = {
                type: crType === 'account' ? NOTIFICATION.ACCOUNT_CHANGE_REQUEST_STATUS : statusTypename,
                name: notName,
                DateTime: Date.now(),
                id: crType === 'account' ? NOTIFICATION.ACCOUNT_CHANGE_REQUEST_STATUS + Date.now() : statusTypename + Date.now(),
                data: dRes,
                icon: '',
                isRed: false,
                params: crType === 'account' ? { accountName: crTypeValue, contactName: crTypeValue, status: formattedStatus, crID: crID } : (crType === 'business_account' ? { accountName: crTypeValue } : {fullname: crTypeValue})
              };
              // this.myAssistantService.addNewNotification(this.translate.instant('CHANGE_REQUEST_GO_NOTIFICATION', { crType: crType, crTypeLowerCase: crType, crTypeValue: crTypeValue, status: formattedStatus, toFrom: toFrom, dateTime: lastUpdated } ));
            }
          }
        }
        if (this.cRNotificationModel) {
          this.myAssistantService.saveNotificationToDisk(this.cRNotificationModel);
        }
      }
    });
  }


  private getInProgressNotification(dRes: any, statusTypename: string, notName: string, localRaw: any, idx: any) {
    if (dRes.indskr_contactsourcetype === ChangeRequestType.BUSINESS) {
      statusTypename = "BUSINESS_CHANGE_REQUEST_OPEN";
      notName = this.translate.instant("BUSINESS_CHANGE_REQUEST_OPEN", { fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}` });
    } else if (dRes.indskr_contactsourcetype === ChangeRequestType.MDM) {
      if (dRes.indskr_mdmrequesttype === MDMRequestType.UPDATE) {// UPdate Case 
        if (this.authService.okIntegrationSettings && this.authService.okIntegrationSettings.typeOfIntegration == IntegrationType.ONE_KEY) {
          statusTypename = "ONE_KEY_CHANGE_REQUEST_UPDATE_STAGED";
          notName = this.translate.instant("ONE_KEY_CHANGE_REQUEST_UPDATE_STAGED", { fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}` });
        } else {
          statusTypename = "ONE_KEY_CHANGE_REQUEST_STAGED";
          notName = this.translate.instant("ONE_KEY_CHANGE_REQUEST_STAGED", { fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}` });
        }
      } else { // CREATE Case
        if (this.authService.okIntegrationSettings && this.authService.okIntegrationSettings.typeOfIntegration == IntegrationType.ONE_KEY) {
          statusTypename = "ONE_KEY_CHANGE_REQUEST_STAGED";
          notName = this.translate.instant("ONE_KEY_CHANGE_REQUEST_STAGED", { fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}` });
        } else {
          statusTypename = "ONE_KEY_APPROVAL_AND_NEW_CONTACT_ADDEDD";
          notName = this.translate.instant("ONE_KEY_APPROVAL_AND_NEW_CONTACT_ADDEDD", { fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}` });
        }
      }
    }
    return { statusTypename, notName };
  } 

  private getApprovedCRNotification(dRes: any, statusTypename: string, notName: string, localRaw: any, idx: any) {
    if (dRes.indskr_contactsourcetype === ChangeRequestType.BUSINESS) {
      statusTypename = "BUSINESS_CHANGE_REQUEST_APPROVED";
      notName = this.translate.instant("BUSINESS_CHANGE_REQUEST_APPROVED", { fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}` });
    } else if (dRes.indskr_contactsourcetype === ChangeRequestType.MDM) {
      if (dRes.indskr_mdmrequesttype === MDMRequestType.UPDATE) {
        statusTypename = "ONE_KEY_CHANGE_REQUEST_UPDATE_APPROVED_CONTACT_BE_UPDATED";
        notName = this.translate.instant("ONE_KEY_CHANGE_REQUEST_UPDATE_APPROVED_CONTACT_BE_UPDATED", { fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}` });
      } else {
        statusTypename = "ONE_KEY_APPROVAL_AND_NEW_CONTACT_ADDEDD";
        notName = this.translate.instant("ONE_KEY_APPROVAL_AND_NEW_CONTACT_ADDEDD", { fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}` });
      }
    }
    return { statusTypename, notName };
  }

  private getRejectedORPendingApprovalNotification(dRes: any, statusTypename: string, notName: string, localRaw: any, idx: any) {
    if (dRes.indskr_contactsourcetype === ChangeRequestType.BUSINESS) {
      statusTypename = "BUSINESS_CHNAGE_REUEST_REJECTED";
      notName = this.translate.instant("BUSINESS_CHNAGE_REUEST_REJECTED", { fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}` });
    } else if (dRes.indskr_contactsourcetype === ChangeRequestType.MDM) {
      if (dRes.indskr_mdmrequesttype === MDMRequestType.UPDATE) {
        statusTypename = "ONE_KEY_CHANGE_REQUEST_UPDATE_REJECTED_SEE_COMMENTS";
        notName = this.translate.instant("ONE_KEY_CHANGE_REQUEST_UPDATE_REJECTED_SEE_COMMENTS", { fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}` });
      } else {
        statusTypename = "ONE_KEY_REJECTED_SEE_COMMENTS";
        notName = this.translate.instant("ONE_KEY_REJECTED_SEE_COMMENTS", { fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}` });
      }
    }
    return { statusTypename, notName };
  }

  private getSubmitteCRdNotification(dRes: any, statusTypename: string, notName: string, localRaw: any, idx: any) {
    if (dRes.indskr_contactsourcetype === ChangeRequestType.BUSINESS) {
      statusTypename = "BUSINESS_CHANGE_REQUEST_SUBMITTED";
      notName = this.translate.instant("BUSINESS_CHANGE_REQUEST_SUBMITTED", { fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}` });
    } else if (dRes.indskr_contactsourcetype === ChangeRequestType.MDM) {

      if (this.authService.okIntegrationSettings && this.authService.okIntegrationSettings.typeOfIntegration == IntegrationType.ONE_KEY) {
        if (dRes.indskr_mdmrequesttype === MDMRequestType.UPDATE) {
          statusTypename = "ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED";
          notName = this.translate.instant("ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED", { fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}` });
        } else {
          statusTypename = "ONE_KEY_CHANGE_REQUEST_SUBMITTED";
          notName = this.translate.instant("ONE_KEY_CHANGE_REQUEST_SUBMITTED", { fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}` });
        }
      } else {

        if (dRes.indskr_mdmrequesttype === MDMRequestType.UPDATE) {
          statusTypename = "ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED";
          notName = this.translate.instant("ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED", { fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}` });

        } else {
          statusTypename = "ONE_KEY_CHANGE_REQUEST_SUBMITTED";
          notName = this.translate.instant("ONE_KEY_CHANGE_REQUEST_SUBMITTED", { fullname: `${localRaw[idx]['indskr_firstname']} ${localRaw[idx]['indskr_lastname']}` });
        }
      }
    }
    return { statusTypename, notName };
  }

// Add notification in case of contacts/ Accounts 
//data is teh form data 
// response is the response recieved after 


  async addNotification(data: any, response, type: string, name: string, status) {

    console.log(data);

    data['indskr_contactcrid'] = response.indskr_contactcrid;

    if (data.formValue) {
      let cRNotificationModel = {
        type: type,
        name: name,
        DateTime: Date.now(),
        id: type + Date.now(),
        data: {
          data:  type === NOTIFICATION.ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED || type === NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_STAGED || type === NOTIFICATION.BUSINESS_CHANGE_REQUEST_SUBMITTED || type === NOTIFICATION.ONE_KEY_CHANGE_REQUEST_STAGED ||type === NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUBMITTED|| type === NOTIFICATION.BUSINESS_CHANGE_REQUEST_OPEN || type === NOTIFICATION.ONECRM_DCR_REQUEST_STAGED || type === NOTIFICATION.ONECRM_UPDATE_DCR_REQUEST_STAGED ? response.indskr_contactcrid : response.indskr_accountcrid,
          offlineData: response.addedNewOneKeyCRId ?  response.addedNewOneKeyCRId : '',
        },
        icon: '',
        isRed: false,
        params: { fullname: data.formValue.indskr_firstname + " " + data.formValue.indskr_lastname}
      };
      await this.myAssistantService.saveNotificationToDisk(cRNotificationModel);
    }
  }

  async addAccountCrNotification(data: any, response, type: string, name: string) {
    if (data.formValue) {
      const time = Date.now();
      let cRNotificationModel = {
        type: type,
        name: name,
        DateTime: Date.now(),
        id: type + Date.now(),
        data: { data: response.indskr_accountcrid },
        icon: '',
        isRed: false,
        params: { accountName: data.formValue.indskr_name }
      };
      await this.myAssistantService.saveNotificationToDisk(cRNotificationModel);
    }
  }

  public getHopQrCode(hcpOneId: string) {
    let userEmail = this.authService.user.mail;
    if (userEmail.includes('sanofi.net.cn'))
      userEmail = userEmail.replace('sanofi.net.cn', 'sanofi.com');
    let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.GET_HCP_QRCODE;
    url = url + "?mrEmail=" + userEmail + "&hcpOneId=" + hcpOneId;
    return this.mdmDataService.getHopQrCode(url);
  }

  public getHcpQrCode(payload) {
    console.log(payload);
    let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.GET_HCP_EXCLUSICE_QR_CODE;
    url = url.replace('{useremail}', payload.userid);
    url = url.replace('{useretmscode}', payload.businessCode);
    url = url.replace('{userbusinessline}', payload.type);
    url = url.replace('{accountetmscode}', payload.hcoCode);
    return this.mdmDataService.getHopQrCode(url);
  }

  public async fetchMaterial(payload) {
    const userEmail = this.authService.user.mail;
    payload['userEmail'] = userEmail;
    let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.FETCH_MDM_MATERIAL_CR;
    return this.mdmDataService.getMaterial(url, payload);
  }

  public setNows(mdmPayload, businessLine) {
    const contact = this.contactService.contactInformation;
    const affl = contact.accountRelationships;
    let jobTitle = "";
    let sa_dept_nm = "";
    let dept_nm = "";
    let indskr_externalid = "";
    let indskr_hcovid = "";
    let accountName = "";
    if (!_.isEmpty(affl)) {
      jobTitle = affl.map(aff => aff['indskr_jobtitle'])[0];
      dept_nm = affl.map(aff => aff['indskr_department'])[0];
      sa_dept_nm = affl.map(aff => aff['indskr_sanofispecialty'])[0];
      let account = this.accountOfflineService.getAccountById(affl[0].accountId);
      if (account) {
        indskr_externalid = account.raw['indskr_externalid'] ? account.raw['indskr_externalid'] : '';
        indskr_hcovid = account.raw['indskr_hcovid'] ? account.raw['indskr_hcovid'] : "";
        accountName = account.accountName;
      } 
    }
    if (businessLine == 3) {
      jobTitle = contact.raw['indskr_position_Formatted'] ? contact.raw['indskr_position_Formatted'] : "";
    }
    mdmPayload['now_hcp_oneid'] = contact.indskr_hcpid ? contact.indskr_hcpid : "";
    mdmPayload['now_hcp_etms'] = contact.indskr_externalid ? contact.indskr_externalid : "";
    mdmPayload['now_hcp_vid'] = contact.indskr_mdmid ? contact.indskr_mdmid : "";
    mdmPayload['now_hcp_first_nm'] = contact.firstName ? contact.firstName : "";
    mdmPayload['now_hcp_status'] = "ACTV";
    mdmPayload['now_gender'] = contact.gendercode && contact.gendercode == 1 ? OneCRMGender.MALE : contact.gendercode == 2 ? OneCRMGender.FEMALE : '';
    mdmPayload['now_title'] = contact.title ? contact.title : "";
    mdmPayload['now_position'] = jobTitle ? jobTitle : "";
    mdmPayload['now_specialty'] = contact.primarySpecialty ? contact.primarySpecialty.name : "";
    mdmPayload['now_sa_dept_nm'] = sa_dept_nm ? sa_dept_nm : "";
    mdmPayload['now_dept_nm'] = dept_nm ? dept_nm : "";
    mdmPayload['now_hco_etms'] = indskr_externalid ? indskr_externalid : "";
    mdmPayload['now_hco_vid'] = indskr_hcovid ? indskr_hcovid : "";
    mdmPayload['now_hco_nm'] = accountName ? accountName : "";
  }

  public setEmptyNows(mdmPayload) {
    mdmPayload['now_hcp_oneid'] = "";
    mdmPayload['now_hcp_etms'] = "";
    mdmPayload['now_hcp_vid'] = "";
    mdmPayload['now_hcp_first_nm'] = "";
    mdmPayload['now_hcp_status'] = "";
    mdmPayload['now_gender'] = '';
    mdmPayload['now_title'] = "";
    mdmPayload['now_position'] = "";
    mdmPayload['now_specialty'] = "";
    mdmPayload['now_sa_dept_nm'] = "";
    mdmPayload['now_dept_nm'] = "";
    mdmPayload['now_hco_etms'] = "";
    mdmPayload['now_hco_vid'] = "";
    mdmPayload['now_hco_nm'] = "";
  }
}
