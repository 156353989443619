import { OpportunityManagementService } from './../../../services/opportunity-management/opportunity-management.service';
import { ProcedureTrackerActivityDataService } from './../../../data-services/procedure-tracker-activity/procedure-tracker-activity.data.service';
import { Opportunities, ProcedureTrackerActivity } from './../../../classes/activity/procedure-tracker.activity.class';
import { ProcedureTrackerDetailsComponent } from './../../surgery-order/procedure-tracker-details/procedure-tracker-details';
import { NewTimeOffComponent } from './../../time-off/new-time-off/new-time-off';
import { debounceTime } from 'rxjs/operators';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { SurgeryOrderActivityDataService } from '../../../data-services/surgery-order-activity/surgery-order-activity.data.service';
import { ContactConsentCaptureComponent } from './../../contact/contact-consent-capture/contact-consent-capture';
import { QuoteDataService } from './../../../data-services/quote/quote.data.service';
import { OpportunityQuoteDetailsComponent } from './../../opportunity-management/opportunity-quote-details/opportunity-quote-details';
import { Quote, Opportunity, OrderCreationType, OpportunityPrimaryTraget } from './../../../classes/opportunity-management/opportunity.class';
import { ChannelType, EmailActivityChannelDetails, ChannelActivityType } from './../../../classes/consent/channel.class';
import { Component, Output, EventEmitter, Input, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, Injector, ElementRef } from '@angular/core';
import { format, addMinutes, differenceInDays, isSameDay, isToday, subDays, differenceInCalendarDays, isPast, differenceInMinutes, isThisSecond, isFuture, isWithinRange, endOfISOWeek, startOfISOWeek, isValid } from 'date-fns';
import { UIService, PresentationView, ComponentViewMode } from '../../../services/ui/ui.service';
import {
  MeetingDataService,
  InitiateMeetingPayload,
  UpdateMeetingPayload,
} from '../../../data-services/meeting/meeting.data.service';
import { LoadingController, ModalController, IonNav } from '@ionic/angular';
import { Events } from '@omni/events';
import { LogService } from '../../../services/logging/log-service';
import { DeviceService, VIEW_MODE_CHANGE } from '../../../services/device/device.service';
import { ActivityService, ActivitySource } from '../../../services/activity/activity.service';
import { ActivityDataService } from '../../../data-services/activity/activity.service';
import { AppointmentActivity, OFFLINE_ID_PREFIX } from '../../../classes/activity/appointment.activity.class';
import { AuthenticationService } from '../../../services/authentication.service';
import { CallPlanOfflineService } from '../../../services/call-plan/call-plan.offline.service';
import { Contact, ContactMeetingState } from '../../../classes/contact/contact.class';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { NavigationService, PageName, ChildNavNames } from '../../../services/navigation/navigation.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { SampleService, SamplingDetailsViewMode } from '../../../services/sample/sample.service';
import { SampleActivity, CreateSampleDropRequestBody } from '../../../classes/activity/sample.activity.class';
import { SampleDataService } from '../../../data-services/sample/sample.data.service';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import * as moment from 'moment';
import { EmailService } from '../../../services/email-templates/email.service';
import { AccountManagementOfflineService } from '../../../services/account-management/account-management.service';
import { FollowUpActionDetailComponent } from '../follow-up-action-detail/follow-up-action-detail';
import { FollowUpActivity, FOLLOW_UP_TYPE } from '../../../classes/activity/follow-up-action.activity.class';
import { FollowUpActivityDataService } from '../../../data-services/follow-up-activity/follow-up-activity.data.service';
import { EmailActivity, EmailViewType, EmailActivityParty, EmailAddress } from '../../../classes/activity/email.activity.class';
import { ActivitiesDetailsPaneComponent } from '../activities-details-pane/activities-details-pane';
import { AccountOfflineService } from '../../../services/account/account.offline.service';
import { PopoverController } from '@ionic/angular';
import { MSEventsService } from '../../../services/ms-events.service';
import { OrderActivity } from '../../../classes/activity/order.activity.class';
import { OrderDetailsComponent } from '../../order-management/order-details/order-details';
import { OperationDetail, OrderActivityDataService } from '../../../data-services/order-activity/order-activity.data.service';
import { ActivityType, Activity, EmbeddedInteraction, EmbeddedInteractionType, ActivityTypeCode, MeetingActivityTypeCode, FormatType } from '../../../classes/activity/activity.class';
import { CaseManagementService, AccesingMode } from '../../../services/case-management/case-management.service';
import { CaseType } from '../../../classes/case-intake/case-utility.class';
import { CaseActivity } from '../../../classes/case-intake/case-activity.class';
import { ScientificPlanFollowUpTask } from '../scientific-activity/scientific-plan-task/scientific-plan-task';
import { NothingSelectedView } from '../../shared/nothing-selected-view/nothing-selected-view';
import { EventsService } from '../../../services/events/events.service';
import { DirectMessagingService } from '../../../services/direct-messaging/direct-messaging.service';
import { CustomerInsightComponent } from '../../contact/customer-insight/customer-insight';
import { TrendingCustomer } from '../../../classes/xperiences/trending.customer.class';
import { XperiencesService, INSIGHT_TYPE } from '../../../services/xperiences/xperiences.service';
import { Utility } from '../../../utility/util';
import { ContentMatchingService } from '../../../services/content-matching/content-matching.service';
import { EmailActivityDetailComponent } from '../email-activity-detail/email-activity-detail';
import { TranslateService } from '@ngx-translate/core';
import { GlobalUtilityService } from '../../../services/global-utility.service';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { DateTimeFormatsService, DATE_TIME_CONSTANTS } from '../../../services/date-time-formats/date-time-formats.service';
import { IndPageTitleComponent } from '../../shared/ind-page-title/ind-page-title';
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { ToastStyle, NotificationService } from '../../../services/notification/notification.service';
import * as _ from 'lodash';
import { PresentationPageComponent } from '../../../pages/presentation/presentation';
import { PresentationService } from '../../../services/presentation/presentation.service';
import { EmailTemplateType } from '../../../classes/email-templates/email-template.class';
import { InMeetingAllocationComponent } from '../../../components/in-meeting-allocation/in-meeting-allocation';
import { InMeetingCasesComponent } from '../../../components/case-management/in-meeting-cases/in-meeting-cases';
import { SharedDataService } from './../../../data-services/shared-data/shared.data.service';
import { MeetingStructureService } from './../../../services/meeting-structure/meeting-structure.service';
import { NewSampleActivityComponent } from './../new-sample-activity/new-sample-activity';
import { CaseManagementDetailComponent } from './../../case-management/case-management-detail/case-management-detail';
import { Subscription, Subject, combineLatest } from 'rxjs';
import { PhoneCallMeetingStructureService } from '../../../services/phone-call-meeting-structure/phone-call-meeting-structure.service'
import { PhoneCallDetailsComponent } from '../../../components/phonecall/phone-call-details/phone-call-details';
import { InitiatePhoneCallPayload, PhoneCallDataService } from '../../../data-services/phone-call/phonecall.data.service';
import { OFFLINE_PHONE_CALL_ID_PREFIX, PhoneActivity } from '../../../classes/activity/phone.activity.class';
import { takeUntil, map, first, filter } from 'rxjs/operators';
import { GeneralInsightsPlusComponent } from '@omni/components/general-insights-plus/general-insights-plus';
import { SurgeryOrderActivity, OrderContact } from '../../../classes/activity/surgery-order.activity.class';
import { SurgeryOrderDetailsComponent } from '@omni/components/surgery-order/surgery-order-details/surgery-order-details';
import { AgendaFooterService } from '@omni/services/footer/agenda-footer.service';
import { OpportunityManagementDataService } from '@omni/data-services/opportunity-management/opportunity-management.data.service';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { DatePipe } from '@angular/common';
import { AccountManagementDataService } from '@omni/data-services/account-management/account-management.data.service';
import { Guid } from 'typescript-guid';
import { OpportunityManagementPageComponent } from '@omni/components/opportunity-management/opportunity-management-page/opportunity-management-page';
import { BrandOfflineService } from '@omni/services/brand/brand.service';
import { newActivityConflictCheck, newActivityProximityWarningCheck } from '../../../utility/activity.utility';
import { Account } from '@omni/classes/account/account.class';
import { SurveyPageComponent } from '@omni/components/survey/survey-page/survey-page';
import { CustomerSurveyService } from '@omni/services/customer-survey/customer-survey.service';
import { EventActivity, EventParticipant } from '@omni/classes/events-tool/event.class';
import { EventRegistrationStatus } from '@omni/enums/event/event.enum';
import { StoreCheckDetailsComponent } from '../store-check-activity/store-check-details';
import { StoreCheckService } from '@omni/services/store-check/store-check.service';
import { Brand } from '@omni/classes/brand/brand.class';
import { DynamicForm, FormType } from '@omni/classes/dynamic-form/dynamic-form.class';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { DynamicFormComponentModel, DynamicFormType } from '@omni/models/dynamic-form-component.model';
import { DynamicFormComponent } from '@omni/components/shared/ind-dynamic-form/ind-dynamic-form';
import { AlertService } from '@omni/services/alert/alert.service';
import { SetBookingActivity, SetBookingStatus } from '@omni/classes/activity/set-booking.activity.class';
import { SetBookingDataService } from '@omni/data-services/set-booking/set-booking.data.service';
import { SecInfoConfigDataService } from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import { DiskService } from '@omni/services/disk/disk.service';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { EmailDataService } from '@omni/data-services/email/email.data.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';
import { uniqBy } from 'lodash';
import { QuotesService } from '@omni/services/quote/quote-tool.service';
import { NewQuoteComponent } from '@omni/components/quote/new-quote/new-quote.component';

/**
 * Generated class for the ActivityNewActivityComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: "new-activity",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'new-activity.html',
  styleUrls: ['new-activity.scss']
})
export class NewActivityComponent implements OnInit, OnDestroy {
  private _newTimeOffCmp: NewTimeOffComponent;
  @ViewChild('timeOffCmp') set timeOffCmp(cmp: NewTimeOffComponent) {
    this._newTimeOffCmp = cmp;
  }
  public subject: string;
  public location: string;
  public startTime: string;
  public endTime: string;
  public duration: any;
  public notes: string;
  public cellData: { allDay?: boolean, startDate: Date, endDate: Date };
  public hasTimeoffEnabled: boolean = false;
  public hasSamplingEnabled: boolean = false;
  public hasPhonecallEnabled: boolean = false;
  public hasLiveMeetEnabled: boolean = false;
  public hasProcedureLogEnabled: boolean = false;
  public hasProcedureLogConfigEnabled: boolean = false;
  public hasBulkProcedureLogConfigEnabled: boolean = false;
  public hasOpportunityEnabled: boolean = false;
  public hasStoreCheckEnabled: boolean = false;
  public isMeetingEnabled: boolean = true;
  public isAccountVisitEnabled: boolean = false;
  public hasMeetingDurationEnabled = false;
  public hasMeetingDurationRequired = false;
  public hasMeetingEndDateEnabled = false;
  public hasKitBookingEnabled:boolean = false;
  public isSurveyEnabled = false;
  public hasQuoteEnabled = false;
  private currentMasterPage: PageName;
  private prevStartTime: string;
  private prevEndTime: string;
  public minStartDate: string;
  private hasEmailActivityEnabled: boolean = false;
  hasDirectMessagingEnabled: boolean = false;
  public hasCustomerInquiryEnabled: boolean = false;
  public hasXperincesEnabled: boolean = false;
  public selectedType: string = null;
  public selectedMessagingChannelType: EmailActivityChannelDetails;
  public selectedConsentCustomer: Contact;
  public newActivityTitle: string = 'NEW_ACTIVITY';
  public forContactName: string = '';
  public isConfirmEnabled: boolean = true;
  public navCtrl: IonNav;
  public isMeetingPresentation: boolean = false;
  public selectedInsightType: INSIGHT_TYPE = INSIGHT_TYPE.CUSTOMER_INSIGHT;
  public insightType = INSIGHT_TYPE;

  private _orientationHandler: (modeChange: VIEW_MODE_CHANGE, curPageName: PageName, curMasterPageName: PageName) => void;

  private followUpMeetingContacts: Array<Contact> = []; //Contacts to add for follow up activity
  private followupMeetingSubject: string = '';

  @Input() NewActivitySource: string;
  public confirmTimeoff: boolean = false;

  @Output() onCancelNewActivity = new EventEmitter();
  noEligibleAllocation: boolean;

  private pageModal: HTMLIonModalElement;

  public isFollowUpActionEnabled: boolean = false;
  public isAccountPlanTaskEnabled: boolean = false;
  public isMarketingPlanTaskEnabled: boolean = false;
  public isEventFollowUpActionEnabled: boolean = false;
  public isScientificPlanTaskEnabled: boolean = false;
  public isOpportunityTaskEnabled: boolean = false;
  public isNoneTaskEnabled: boolean = false;
  public isMeetingTaskEnabled: boolean = false;
  public followUpActionCategory: string = '';
  public procedureCategory: string = '';
  public procedureLogSelection: string = '';
  public isOrderCreationEnabled: boolean = false;
  isQuoteCreationEnabled = false;
  private isLayoverPushView: boolean = false;
  private layoverViewPushedFrom: any;
  private layoverViewPassedData: any;
  public newActivityHeader: IndSectionHeaderViewDataModel;
  isConsentCreationEnabled = false;
  public newActivityPageTitle: IndPageTitleViewDataModel;
  private IcurrentMeetingActivitySubscription: Subscription;
  private selectedMeetingActivity: EmbeddedInteraction;
  public backgroundUploadInProgress: boolean = false;

  private _updateDayClick: () => void;
  private _updateCellClick: () => void;
  private _updateCurrentDate: () => void;

  public isLoaderOn: boolean = false;
  private orgDiffMinutes = 0;
  type: CaseType;
  //@ViewChild(Content, {static:true}) content: Content;
  noPhoneNumber: boolean;
  @Input() from: any
  @Input() plan: any
  @Input() pres: any
  @Input() account: any
  @Input() opportunity: any
  @Input() contact: any
  @Input() surgeryOrder:any
  @Input() setBooking:any
  private ngDestroy$ = new Subject<boolean>();
  private ItranslatedCustomerTextSubscription: Subscription;
  public translatedCustomerInquiryText: string = "";
  public translatedCustomerInsightPlsText: string = "";

  public startDateField: IndDateTimeFormViewDataModel
  public startTimeField: IndDateTimeFormViewDataModel;
  public endDateField: IndDateTimeFormViewDataModel;
  public endTimeField: IndDateTimeFormViewDataModel;
  public durationField: IndDateTimeFormViewDataModel;
  private startDateTime: string;
  private startDateValue: string;
  private startTimeValue: string;
  private endDateTime: string;
  private endDateValue: string;
  private endTimeValue: string;
  dateFormatSubscription: Subscription;
  timeFormatSubscription: Subscription;
  ContactStatusDisplay = {
    100000000: "assets/imgs/contact_conn.png",
    100000002: "assets/imgs/contact_offline.svg",
    100000003: "assets/imgs/contact_disc.png"
  }
  public displayConflictAlert: boolean = false;
  public conflictMsg: string;
  public readonly uuid: number = new Date().getTime();

  private linkEntitiesReqPayload: any = [];
  
  public customerInquiryDescription: string;
  public hasOpportunityDisabledOffline:boolean = false;

  constructor(
    public readonly authService: AuthenticationService,
    public uiService: UIService,
    private meetingService: MeetingDataService,
    private contactService: ContactOfflineService,
    private logService: LogService,
    public device: DeviceService,
    private activityService: ActivityDataService,
    public activityOfflineService: ActivityService,
    private emailService: EmailService,
    private loadingController: LoadingController,
    private events: Events,
    private authenticationOfflineService: AuthenticationService,
    private callPlanOfflineService: CallPlanOfflineService,
    private footerService: FooterService,
    private trackingService: TrackService,
    private navService: NavigationService,
    public repService: RepServices,
    public samplingService: SampleDataService,
    public samplingOfflineService: SampleService,
    public accountManagementService: AccountManagementOfflineService,
    private followUpActivityDataService: FollowUpActivityDataService,
    private accountService: AccountOfflineService,
    private popoverCtrl: PopoverController,
    public msEventService: MSEventsService,
    private orderActivityDataService: OrderActivityDataService,
    public caseManagementService: CaseManagementService,
    private injector: Injector,
    public directMessagingService: DirectMessagingService,
    private eventService: EventsService,
    private xperiencesService: XperiencesService,
    private contentMatchingService: ContentMatchingService,
    public translate: TranslateService,
    public utilityService: GlobalUtilityService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private notificationService: NotificationService,
    private callPlanService: CallPlanOfflineService,
    private presentationService: PresentationService,
    private modalCtrl: ModalController,
    private sampleDataService: SampleDataService,
    private sharedDataService: SharedDataService,
    private meetingStructureService: MeetingStructureService,
    public _cd: ChangeDetectorRef,
    public phoneCallDataService: PhoneCallDataService,
    private phoneCallMeetingStructureService: PhoneCallMeetingStructureService,
    private surgeryOrderActivityDataService: SurgeryOrderActivityDataService,
    private procedureTrackerActivityDataService: ProcedureTrackerActivityDataService,
    private agendaFooterService: AgendaFooterService,
    private opportunityDataService: OpportunityManagementDataService,
    private readonly opportunityService: OpportunityManagementService,
    private datePipe: DatePipe,
    private eventsToolService: EventsToolService,
    private accountManagementDataService: AccountManagementDataService,
    private brandService: BrandOfflineService,
    private customerSurveyService: CustomerSurveyService,
    private storeCheckService: StoreCheckService,
    private readonly dynamicFormService: DynamicFormsService,
    private readonly alertService: AlertService,
    private readonly setBookingDataService: SetBookingDataService,
    private secondaryInfoService: SecInfoConfigDataService,
    private readonly diskService: DiskService,
    private emailDataService:EmailDataService,
    private featureActionService: FeatureActionsService,
    private quotesOfflineService : QuotesService
  ) {


    // Cleaning up the time initialization.
    // We are assuming that there's no cell selection.
    // Single click on a cell invokes new meeting creation instead of selecting it.
    // It looks like it's designed to pass cell data through the uiService so I'm going to stick with it. (setCellData)
    // [UiService] cellData : { view: string, data: { startDate: Date, endDate: Date } }
    // Start time init:
    //  - Default: Today's next available time
    //  - If a cell data exists and coming from 'day-views' (day/3-day/week): set accroding to the cell data
    //  - If a cell data exists and coming from 'month-views' (month/year): set the time to 8 AM
    //    - If cell data is today's, use the Default.

    this.setCellData();
    // this._setCellData = () => {
    //   console.log('New Activity: update cell');
    //   this.setCellData();
    // }
    this._updateCellClick = () => {
      console.log('New Activity: update cell click');
      this.setCellData();
      this.initDateTimeFormFields();
      this._cd.detectChanges();
    }

    this._updateDayClick = () => {
      console.log('New Activity: update day click');
      this._initTimeForCalendarPage();
      this.initDateTimeFormFields();
      this._cd.detectChanges();
    }

    this._updateCurrentDate = () => {
      this.uiService.cellData = undefined;
      this._initTimeForCalendarPage();
      this.initDateTimeFormFields();
      this._cd.detectChanges();
    }

    this.events.subscribe('updatedCellClick', this._updateCellClick);
    this.events.subscribe('updatedDayClick', this._updateDayClick);
    this.events.subscribe('updateCurrentDate', this._updateCurrentDate);
    // subscribe to newTimeOff datetime and use it to check validateTimeOffDate
    this.events.subscribe("updateNewTimeOffDateTime", (newTimeOff) => {
      if (newTimeOff.totStartTime) {
        this.startTime = newTimeOff.totStartTime;
      } else if (newTimeOff['preSelectedTime'] && newTimeOff['preSelectedTime'].startTime) {
        this.startTime = newTimeOff['preSelectedTime'].startTime;
      }
      if (newTimeOff.totEndTime) {
        this.endTime = newTimeOff.totEndTime;
      } else if (newTimeOff['preSelectedTime'] && newTimeOff['preSelectedTime'].endTime) {
        this.endTime = newTimeOff['preSelectedTime'].endTime;
      }
      if(this.from == PageName.SetBookingActivityDetailsComponent) {
        let dateTime = { startDateTime: this.startTime, endDateTime: this.endTime };
        this.dateTimeFormatsService.updateLimitedMinTime.next(dateTime);
        this.initDateTimeFormFields();
        this._cd.detectChanges();
      }
    });
    this.IcurrentMeetingActivitySubscription = this.meetingStructureService.selectedMeetingActivityObserver.subscribe(activity => {
      this.selectedMeetingActivity = activity;
    });
    this.dateFormatSubscription = this.dateTimeFormatsService.dateFormat.subscribe(df => {
      if (df) {
        this.initDateTimeFormFields();
        this._cd.detectChanges();
      }
    });
    this.timeFormatSubscription = this.dateTimeFormatsService.timeFormat.subscribe(tf => {
      if (tf) {
        this.initDateTimeFormFields();
        this._cd.detectChanges();
      }
    });

    combineLatest([
      this.activityOfflineService.conflictCheckState$,
      this.activityOfflineService.signalConflictCheck$.asObservable(),
    ]).pipe(
      takeUntil(this.ngDestroy$),
      debounceTime(0),
      filter(([state, signalPayload]) => (
        signalPayload?.componentUuid === this.uuid
        && signalPayload?.signal === true
        && state === 'Done'
        && ((this.uiService.activeViewNewMeeting === 'meeting' && !this.uiService.activeViewNewMeetingType)
          || this.uiService.activeViewNewMeeting === 'accountVisit'
          || this.uiService.activeViewNewMeeting === 'phonecall'
          || this.uiService.activeViewNewMeeting === 'timeoff')
      )),
    ).subscribe(([state, signalPayload]) => {
      this.activityOfflineService.signalConflictCheck$.next({ componentUuid: this.uuid, signal: false, start: null, end: null });
      const startDateToCheck = signalPayload?.start instanceof Date ? signalPayload.start : new Date(this.startDateTime);
      const endDateToCheck = signalPayload?.end instanceof Date ? signalPayload.end : new Date(this.endDateTime);
      const hasConflict = newActivityConflictCheck(
        this.activityOfflineService.activities,
        this.activityOfflineService.conflictCheckStartIdx,
        this.eventsToolService.eventsToolData,
        this.eventsToolService.conflictCheckLastIdx,
        startDateToCheck,
        endDateToCheck,
        this.authenticationOfflineService.user.xSystemUserID,
      );
      let hasWarning = false;
      if (this.contactService.contactInformation?.ID) {
        hasWarning = newActivityProximityWarningCheck(
          this.activityOfflineService.activities,
          startDateToCheck,
          endDateToCheck,
          this.authenticationOfflineService.user.xSystemUserID,
          this.authenticationOfflineService.user.meetingProximityWarningPeriod,
          this.contactService.contactInformation?.ID
        );
      }
      this.conflictMsg = hasWarning ? this.translate.instant('PROXIMITY_MEETING_WARNING') : this.translate.instant('ACTIVITY_CONFLICT_MSG');
      this.displayConflictAlert = hasConflict || hasWarning;
      this._cd.detectChanges();
    });
  }



  ngOnInit() {
    this.translate.onLangChange.subscribe(data => {
      this.resetNewActivityOptions();
      this.initNewActivityScreenFormFields();
      this.initNewActivityPagetitle();
      this.getFormattedTimeInterval();
      this.initGlobalCustomerText();
      this.initDateTimeFormFields();
      this.initDescriptions();
    });

    if (this.from) {
      if (this.from == 'AccountManagementTool') {
        this.isLayoverPushView = true;
        this.layoverViewPushedFrom = 'AccountManagementTool';
        this.layoverViewPassedData = this.plan;
      } else if (this.from == 'ScientificActivityPage') {
        this.isLayoverPushView = true;
        this.layoverViewPushedFrom = 'ScientificActivityPage';
        this.layoverViewPassedData = this.plan;
      } else if (this.from == 'PresentationToolsPage') {
        this.isLayoverPushView = true;
        this.layoverViewPushedFrom = 'PresentationToolsPage';
        this.layoverViewPassedData = this.pres;
      } else if (this.from == 'AccountTool') {
        this.isLayoverPushView = true;
        this.layoverViewPushedFrom = 'AccountTool';
        this.layoverViewPassedData = this.account;
        this.hasQuoteEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.QUOTES)
      }
      else if (this.from == 'OpportunityManagementTool'
        || this.from == 'AccountProfileOpportunities'
        || this.from == 'AccountPlanOpportunities'
        || this.from == 'MarketingPlanInfoopportunities') {
        this.isLayoverPushView = true;
        this.layoverViewPushedFrom = this.from;
        this.layoverViewPassedData = this.opportunity;
        if (this.from == 'OpportunityManagementTool' || this.from == 'MarketingPlanInfoopportunities') {
          this.hasQuoteEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.QUOTES) && this.opportunity.statusCode === 1;
        }
      } else if (this.from === 'ActivitiesDetailsPane'
        || this.from === PageName.PhoneCallDetailsComponent
        || this.from === 'ContactPageComponent'
        || this.from === 'EventDetailsPane'
        || this.from === PageName.SetBookingActivityDetailsComponent) {
        this.isLayoverPushView = true;
        this.layoverViewPushedFrom = this.from;
        this.NewActivitySource = this.activityOfflineService.activitySource;
      }
      else if (this.from == 'ProcedureLogFrom') {
        this.isLayoverPushView = true;
        this.layoverViewPushedFrom = 'ProcedureLogFrom';
        this.layoverViewPassedData = this.surgeryOrder;
      }
      else if (this.from === PageName.CustomerCallPlanDetails) {
        this.isLayoverPushView = true;
        this.layoverViewPushedFrom = PageName.CustomerCallPlanDetails;
        this.layoverViewPassedData = this.account ? this.account : undefined;
      }
    } else {
      this.activityOfflineService.activitiesPaneNewActivityComponentUUID = this.uuid;
      if (this.activityOfflineService.activitySource === ActivitySource.MEETING_PRESENTATION || this.activityOfflineService.activitySource === ActivitySource.AGENDA) {
        this.NewActivitySource = this.activityOfflineService.activitySource;
      }
    }
    this.hasMeetingDurationEnabled = this.authenticationOfflineService.hasFeatureAction({ featureAction: FeatureActionsMap.MEETING_DURATION, skipLDCheck: true });
    this.hasMeetingDurationRequired = this.authenticationOfflineService.hasFeatureAction({ featureAction: FeatureActionsMap.MEETING_DURATION_REQUIRED, skipLDCheck: true });
    this.hasMeetingEndDateEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_REMOVE_END_DATE) ? false : !this.hasMeetingDurationEnabled;


    this.uiService.activeViewNewMeetingType = '';
    // Get the master page that called this new activity component.
    this.currentMasterPage = this.navService.getCurrentMasterPageName();
    console.log(this.currentMasterPage);
    //whenever the user click + icon default view is agenda
    if (this.NewActivitySource === ActivitySource.MEETING_PRESENTATION) {
      this.uiService.setActiveViewForNewMeeting('MeetingPresentation');
    } else if (this.NewActivitySource == ActivitySource.CONTACT_INFO) {
      if (this.contactService.contactInformation) {
        this.newActivityTitle = 'NEW_ACTIVITY_FOR';
        this.forContactName = this.contactService.contactInformation.fullName;
        let contactCustomeAllocations = this.samplingOfflineService.contactCustomerSampleAllocations.find(o => {
          return o.contactId == this.contactService.contactInformation.ID
        });
        if (!contactCustomeAllocations ||
          !contactCustomeAllocations.currentCustomerSampleAllocations ||
          contactCustomeAllocations.currentCustomerSampleAllocations.length == 0) {
          this.noEligibleAllocation = true;
        }
      }
      else {
        this.noEligibleAllocation = false;
      }
    } else if (this.NewActivitySource !== ActivitySource.MEETING_DETAIL) {
      this.uiService.setActiveViewForNewMeeting( this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG) ? 'surgeryorder' : 'meeting');
    }

    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)
      && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ORDER_ACTIVITY)
    ) {
      if (this.currentMasterPage === PageName.ActivitiesPageComponent || this.NewActivitySource === ActivitySource.MEETING_PRESENTATION
        || this.NewActivitySource === ActivitySource.MEETING_DETAIL
        || this.NewActivitySource === ActivitySource.EVENT_DETAIL
        || this.currentMasterPage === PageName.ContactPageComponent) {
        this.hasSamplingEnabled = true;
      }
    }
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT)) {
      this.isAccountVisitEnabled = true;
    }
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL)
      && (!this.NewActivitySource || (this.NewActivitySource && this.NewActivitySource != 'ContactInfo'))) {
      this.hasTimeoffEnabled = true;
    }
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.LIVEMEET)) {
      this.hasLiveMeetEnabled = true;
    }
    if(this.featureActionService.isShortCallLauncherEnabledInMobileDevice && this.authService.hasFeatureAction(FeatureActionsMap.SHOR_CALL_LAUNCHER_DISABLE_APPOINTMENT_IN_NEW_ACTIVITY)) {
      this.isMeetingEnabled = this.isAccountVisitEnabled = false;
    }

    this.prevStartTime = format(this.startTime);
    this.prevEndTime = format(this.endTime);
    let dt = new Date();
    dt.setHours(0, 0)
    this.minStartDate = format(subDays(dt, this.authenticationOfflineService.user.maxMeetingStartDays));

    // Update start/end time field with selected time from the time picker
    this.dateTimeFormatsService.isNewActivity = true;
    this.dateTimeFormatsService.updateSelectedTime$.pipe(takeUntil(this.ngDestroy$)).subscribe((timeData) => {
      if (timeData) {
        if (timeData.id == DateTimeFieldType.StartTimeField) {
          this.startTime = timeData.time;
          this.handleStartDateTimeValue();
        } else if (timeData.id == DateTimeFieldType.EndTimeField) {
          this.endTime = timeData.time;
          this.handleEndDateTimeValue();
        }
      }
    });
    this.initDateTimeFormFields();

    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY)) {
      this.hasDirectMessagingEnabled = true
    }

    // Follow up action : the user will have the category 'None' as a default, regardless of FA.
    this.isFollowUpActionEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY);
    this.isNoneTaskEnabled = true;
    this.followUpActionCategory = 'none';
    this.isAccountPlanTaskEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT) && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNT_FOLLOW_UP_TASK);
    this.isMarketingPlanTaskEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN);
    this.isEventFollowUpActionEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EVENT_FOLLOW_UP_ACTION);
    this.isScientificPlanTaskEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_TOOL_PLAN) && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_FOLLOW_UP_TASK);
    this.isOpportunityTaskEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT_TASK);

    // if (this.isAccountPlanTaskEnabled) {
    //   this.followUpActionCategory = 'accountplantask';
    // } else if (this.isScientificPlanTaskEnabled) {
    //   this.followUpActionCategory = 'scientificplantask';
    // } else if (this.isOpportunityTaskEnabled) {
    //   this.followUpActionCategory = 'opportunitytask';
    // }
    // if (this.isAccountPlanTaskEnabled || this.isScientificPlanTaskEnabled || this.isOpportunityTaskEnabled) {
    //   this.isFollowUpActionEnabled = true;
    // }

    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT)) {
      this.isOrderCreationEnabled = true;
    }
    if (!this.isOrderCreationEnabled && this.from == 'OpportunityManagementTool') {
      const selectedOppurtunityType = this.opportunityService.opportunityTypes.find(ot => ot.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
      if (selectedOppurtunityType && selectedOppurtunityType.indskr_ordercreation && selectedOppurtunityType.indskr_ordercreationprocess == OrderCreationType.MANUAL) {
        this.isOrderCreationEnabled = true;
      }
    }
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CONSENT_TOOL)) {
      this.isConsentCreationEnabled = true;
    }
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)) {
      this.hasCustomerInquiryEnabled = true;
    }
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) {
      this.hasPhonecallEnabled = true;
    }
    if (this.authenticationOfflineService.user.hasBulkProcedureLog && !this.authenticationOfflineService.user.hasProcedureLog) {
      this.procedureCategory = "procedure_tracker"
    }
    if (this.authenticationOfflineService.user.hasProcedureLog && !this.authenticationOfflineService.user.hasBulkProcedureLog && this.authService.user.buSettings['indskr_noprocedureday']) {
      this.procedureCategory = "procedureDay"
    }
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.XPERIENCES)
      && (this.layoverViewPushedFrom !== 'ScientificActivityPage')
      && (this.layoverViewPushedFrom !== 'AccountManagementTool')
      && (this.layoverViewPushedFrom !== 'CallPlansPage')
      && this.uiService.activitiesPagePlanTabSelectedPlan !== 'goalsPlansTab'
      && ((this.currentMasterPage === PageName.ActivitiesPageComponent && this.footerService.isActivitiesButton) || this.currentMasterPage === PageName.ContactPageComponent)) {
      this.hasXperincesEnabled = true;
    }

    if (this.hasPhonecallEnabled && this.contactService.contactInformation && (this.navService.getCurrentPageName() == PageName.ContactPageComponent || this.currentMasterPage === PageName.CallPlanDetails || this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent
      || (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans' && this.uiService.activitiesPagePlanTabSelectedPlan === 'goalsPlansTab') || this.currentMasterPage === PageName.CustomerCallPlanDetails || this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage)) {
      if (_.isEmpty(this.contactService.contactInformation.mobilePhone) && _.isEmpty(this.contactService.contactInformation.telephone)
        && _.isEmpty(this.contactService.contactInformation.indskr_alternatephone1) && _.isEmpty(this.contactService.contactInformation.indskr_alternatephone2)
        && _.isEmpty(this.contactService.contactInformation.indskr_alternatephone3)) {
        this.noPhoneNumber = true;
      } else {
        this.noPhoneNumber = false;
      }
      if (this.authService.hasFeatureAction({ featureAction: FeatureActionsMap.ENABLE_PHONE_CALL_CONSENT_CHECK, skipLDCheck: true })
        && this.contactService.checkIfContactIsAMADoNotContact()) {
        this.hasPhonecallEnabled = false;
      }
    }
    // Have to implement the condition for the surgery order
    this.hasProcedureLogEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG);
    this.hasProcedureLogConfigEnabled = this.authenticationOfflineService.user.hasProcedureLog;
    this.hasBulkProcedureLogConfigEnabled = this.authenticationOfflineService.user.hasBulkProcedureLog;
    this.hasStoreCheckEnabled = this.authService.hasFeatureAction(FeatureActionsMap.STORE_CHECK);
    this.hasKitBookingEnabled = this.authService.hasFeatureAction(FeatureActionsMap.KIT_BOOKING);
    this.hasOpportunityDisabledOffline = this.device.isOffline && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_REVENUE_USER_PROVIDED)
    // Call it last to manually enable or disable activity options depending upon the view it is being created
    this.resetNewActivityOptions();
    this.initNewActivityScreenFormFields();
    this.initNewActivityPagetitle();
    this.initDescriptions();
    this.activityOfflineService.invokeCallCenterActivity$.subscribe((value) => {
      if (value) {
        // @ts-ignore
        this.setActiveView('meeting');
        this.handleNewActivity();
      }
    }).unsubscribe();
    this.activityOfflineService.invokeCallCenterActivity$.next(false);
    this.events.subscribe('createphonecall', () => {
      this.uiService.activeViewNewMeeting = 'phonecall'
      this.handleNewActivity()
    })
    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this._cd.detectChanges();
    });
    this.ItranslatedCustomerTextSubscription = this.authenticationOfflineService.globalCustomerTxtUpdatedObservable.subscribe(val => {
      this.translatedglobalCustomerText();
    });
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      if (this.activityOfflineService.selectedActivity) {
        if (this.NewActivitySource == ActivitySource.MEETING_DETAIL || this.NewActivitySource === ActivitySource.MEETING_PRESENTATION) {
          this.backgroundUploadInProgress = inProgress && this.activityOfflineService.hasOfflineMeetingData(this.activityOfflineService.selectedActivity.ID);
        } else if (this.NewActivitySource == ActivitySource.PHONE_CALL_ACTIVITY_DETAIL) {
          this.backgroundUploadInProgress = inProgress && this.activityOfflineService.hasOfflinePhoneCallData(this.activityOfflineService.selectedActivity.ID);
        }
        this.initNewActivityPagetitle();
      }
    });
    this.eventService.subscribe('remote_meeting_participant_Joined_left', this.handleContactConnectionLost.bind(this));
  }

  public handleContactConnectionLost(data) {
    if (this.uiService.activeViewNewMeeting == 'consent') {
      this.setActiveView('consent')
    }
  }

  public translatedglobalCustomerText() {
    this.initGlobalCustomerText();
    this._cd.detectChanges();
  }

  private initGlobalCustomerText() {
    let translatedglobaltext;
    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        translatedglobaltext = this.translate.instant('CUSTOMER');
        break;
      case 'Stakeholder':
        translatedglobaltext = this.translate.instant('STAKEHOLDER');
        break;
      default:
        translatedglobaltext = this.utilityService.globalCustomerText;
        break;
    }
    this.translatedCustomerInquiryText = this.translate.instant('CUSTOMER_INQUIRY_WITH_TEXT', { globalCustomerText: translatedglobaltext });
    this.translatedCustomerInsightPlsText = this.translate.instant('XPERIENCES_CUSTOMER_INSIGHTS_PLUS', { globalCustomerText: translatedglobaltext });
  }

  public resetNewActivityOptions(): void {
    this.isQuoteCreationEnabled = false;
    this.isConsentCreationEnabled = false;
    if (this.layoverViewPushedFrom) {
      if (this.layoverViewPushedFrom == 'AccountManagementTool' || this.layoverViewPushedFrom == 'ScientificActivityPage') {
        this.hasSamplingEnabled = false;
        this.hasEmailActivityEnabled = false;
        this.isAccountVisitEnabled = false;
        this.hasTimeoffEnabled = false;
        this.hasStoreCheckEnabled = false;
        this.hasPhonecallEnabled = (this.layoverViewPushedFrom == 'ScientificActivityPage') ? false : this.hasPhonecallEnabled;
        this.isOrderCreationEnabled = false;
        this.hasCustomerInquiryEnabled = false;
        this.hasLiveMeetEnabled = false;
        this.hasProcedureLogEnabled = false;
        this.hasProcedureLogConfigEnabled = false;
        this.hasBulkProcedureLogConfigEnabled = false;
        this.layoverViewPushedFrom == 'ScientificActivityPage' ? this.hasDirectMessagingEnabled = true : this.hasDirectMessagingEnabled = false;
        this.isOpportunityTaskEnabled = false;
        this.hasKitBookingEnabled = false;
        if (this.layoverViewPassedData &&
          ((this.layoverViewPassedData.state == 0 && this.layoverViewPassedData.status == 'Open' && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY)) ||
            (this.layoverViewPassedData.statusCode == 548910003 && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_FOLLOW_UP_TASK)))) {
          this.isFollowUpActionEnabled = true;
          this.isNoneTaskEnabled = false;
          if (this.layoverViewPushedFrom == 'AccountManagementTool') this.isScientificPlanTaskEnabled = false;
          // Maybe only show the specific category type
          this.followUpActionCategory = 'accountplantask';
          if (this.layoverViewPushedFrom == 'ScientificActivityPage' && this.isScientificPlanTaskEnabled) {
            this.isAccountPlanTaskEnabled = false;
            this.isMarketingPlanTaskEnabled = false;
            this.followUpActionCategory = 'scientificplantask';
          }
        } else {
          this.isFollowUpActionEnabled = false;
        }
      } else if (this.layoverViewPushedFrom == 'AccountTool') {
        this.hasSamplingEnabled = false;
        this.hasEmailActivityEnabled = false;
        this.hasTimeoffEnabled = false;
        this.hasDirectMessagingEnabled = false;
        this.hasCustomerInquiryEnabled = false;
        this.hasLiveMeetEnabled = false;
        this.isScientificPlanTaskEnabled = false;
        this.hasOpportunityEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT);
        //this.hasKitBookingEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.KIT_BOOKING);
      }
      else if (this.layoverViewPushedFrom == 'OpportunityManagementTool'
        || this.layoverViewPushedFrom == 'AccountProfileOpportunities'
        || this.from == 'AccountPlanOpportunities'
        || this.from == 'MarketingPlanInfoopportunities') {
        if (!this.isOpportunityTaskEnabled) this.isFollowUpActionEnabled = false;
        this.isQuoteCreationEnabled = !this.device.isOffline && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT_QUOTES);
        this.hasSamplingEnabled = false;
        this.hasEmailActivityEnabled = false;
        this.isAccountVisitEnabled = false;
        this.hasTimeoffEnabled = false;
        this.hasCustomerInquiryEnabled = false;
        this.hasLiveMeetEnabled = false;
        this.hasPhonecallEnabled = false;
        this.hasDirectMessagingEnabled = false;
        this.isAccountPlanTaskEnabled = false;
        this.isMarketingPlanTaskEnabled = false;
        this.isScientificPlanTaskEnabled = false;
        this.isNoneTaskEnabled = false;
        this.hasStoreCheckEnabled = false;
        this.hasKitBookingEnabled = false;
        this.followUpActionCategory = 'opportunitytask';
        if (this.layoverViewPushedFrom != 'OpportunityManagementTool' && this.layoverViewPushedFrom !== 'MarketingPlanInfoopportunities') {
          this.isMeetingEnabled = false;
        }
        // User is taken to Meeting Details page when there is only meeting action enabled
        if (!this.isFollowUpActionEnabled && !this.isOrderCreationEnabled && !this.isQuoteCreationEnabled && !this.hasProcedureLogEnabled) {
          this.handleNewActivity();
        }
      }
      else if (this.layoverViewPushedFrom == 'PresentationToolsPage') {
        this.hasSamplingEnabled = false;
        this.hasEmailActivityEnabled = false;
        this.isAccountVisitEnabled = false;
        this.hasTimeoffEnabled = false;
        this.hasCustomerInquiryEnabled = false;
        this.hasLiveMeetEnabled = false;
        this.hasDirectMessagingEnabled = false;
        this.isAccountPlanTaskEnabled = false;
        this.isMarketingPlanTaskEnabled = false;
        this.isScientificPlanTaskEnabled = false;
        this.isFollowUpActionEnabled = false;
        this.isOrderCreationEnabled = false;
        this.hasStoreCheckEnabled = false;
        this.hasKitBookingEnabled = false;
      }
      else if (this.layoverViewPushedFrom == 'ContactPageComponent') {
        this.hasLiveMeetEnabled = false;
        this.isOrderCreationEnabled = false;
        this.isAccountVisitEnabled = false;
        this.hasStoreCheckEnabled = false;
        this.hasOpportunityEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT);
        if (this.hasProcedureLogEnabled) {
          this.uiService.setActiveViewForNewMeeting('surgeryorder');
        }
        //this.hasKitBookingEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.KIT_BOOKING);
      }
      else if (this.layoverViewPushedFrom == 'ProcedureLogFrom') {
        this.hasSamplingEnabled = false;
        this.hasEmailActivityEnabled = false;
        this.isAccountVisitEnabled = false;
        this.hasTimeoffEnabled = false;
        this.hasCustomerInquiryEnabled = false;
        this.hasLiveMeetEnabled = false;
        this.hasDirectMessagingEnabled = false;
        this.isAccountPlanTaskEnabled = false;
        this.isMarketingPlanTaskEnabled = false;
        this.isScientificPlanTaskEnabled = false;
        this.isFollowUpActionEnabled = false;
        this.isOrderCreationEnabled = false;
        this.hasProcedureLogEnabled = false;
        this.hasProcedureLogConfigEnabled = false;
        this.hasBulkProcedureLogConfigEnabled = false;
        this.hasPhonecallEnabled = false;
        this.hasStoreCheckEnabled = false;
        this.hasKitBookingEnabled = false;
        if (this.isMeetingEnabled) {
          this.uiService.setActiveViewForNewMeeting('meeting');
        }
      }
    }

    if (this.uiService.isConsentFromToolDrawer) {
      this.hasProcedureLogEnabled = false;
      this.hasProcedureLogConfigEnabled = false;
      this.hasBulkProcedureLogConfigEnabled = false;
      this.hasKitBookingEnabled = false;
    }
    if ((this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent ||this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage &&
      (this.uiService.showNewActivity || this.navService.getCurrentPageName() == PageName.NewActivityComponent)
      && this.NewActivitySource !== ActivitySource.MEETING_DETAIL)
      || (this.uiService.activeView === 'ActivitiesPageRightPaneNav'&& this.layoverViewPushedFrom !==  'AccountManagementTool' && this.uiService.activitiesPageTab === 'plans' && this.uiService.activitiesPagePlanTabSelectedPlan == 'goalsPlansTab' && this.NewActivitySource !== ActivitySource.MEETING_DETAIL)) {
      this.hasSamplingEnabled = false;
      this.isAccountVisitEnabled = false;
      this.hasTimeoffEnabled = false;
      this.hasCustomerInquiryEnabled = false;
      this.hasLiveMeetEnabled = false;
      this.isAccountPlanTaskEnabled = false;
      this.isMarketingPlanTaskEnabled = false;
      this.isEventFollowUpActionEnabled = false;
      this.isScientificPlanTaskEnabled = false;
      this.isFollowUpActionEnabled = false;
      this.isOrderCreationEnabled = false;
      this.hasProcedureLogEnabled = false;
      this.hasProcedureLogConfigEnabled = false;
      this.hasBulkProcedureLogConfigEnabled = false;
      this.hasStoreCheckEnabled = false;
      this.hasKitBookingEnabled = false;
      if (this.isMeetingEnabled) {
        this.uiService.setActiveViewForNewMeeting('meeting');
      }
    }
    if (this.NewActivitySource === ActivitySource.MEETING_PRESENTATION) {
      this.isMeetingEnabled = false;
      this.isAccountVisitEnabled = false;
      this.hasTimeoffEnabled = false;
      this.hasLiveMeetEnabled = false;
      this.isAccountPlanTaskEnabled = false;
      this.isMarketingPlanTaskEnabled = false;
      this.isEventFollowUpActionEnabled = false;
      this.isScientificPlanTaskEnabled = false;
      this.isOpportunityTaskEnabled = false;
      this.isNoneTaskEnabled = false;
      //this.isFollowUpActionEnabled = true;
      this.isMeetingTaskEnabled = true;
      this.followUpActionCategory = 'meetingtask';
      this.isOrderCreationEnabled = false;
      this.hasPhonecallEnabled = false;
      this.hasStoreCheckEnabled = false;
      this.hasKitBookingEnabled = false;
      //this.hasProcedureLogEnabled = false;
      if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CONSENT_TOOL)) {
        this.isConsentCreationEnabled = true;
      }
      if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)) {
        this.hasSamplingEnabled = true;
      }
      if (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SURVEY) || this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_SURVEY)) {
        this.customerSurveyService.selectedContactFromContactTool = this.contactService.contactInformation || null;
        this.isSurveyEnabled = true;
      }
      if (this.activityOfflineService.selectedActivity instanceof AppointmentActivity &&
        this.activityOfflineService.selectedActivity.isLiveMeet) {
        this.hasSamplingEnabled = false;
        this.hasCustomerInquiryEnabled = false;
        this.hasDirectMessagingEnabled = false;
      }
    }

    if (this.NewActivitySource === ActivitySource.EVENT_DETAIL) {
      this.newActivityOptionsInEventDetails();
    } else {
      this.isEventFollowUpActionEnabled = false;
    }

    if (this.NewActivitySource === ActivitySource.MEETING_DETAIL) {
      this.newActivityOptionsInMeetingDetails();
    }
    if (this.NewActivitySource === ActivitySource.PHONE_CALL_ACTIVITY_DETAIL) {
      this.isMeetingEnabled = false;
      this.isSurveyEnabled = false;
      this.isAccountVisitEnabled = false;
      this.hasTimeoffEnabled = false;
      this.hasLiveMeetEnabled = false;
      this.isAccountPlanTaskEnabled = false;
      this.isMarketingPlanTaskEnabled = false;
      this.isEventFollowUpActionEnabled = false;
      this.isScientificPlanTaskEnabled = false;
      this.isFollowUpActionEnabled = false;
      this.isOrderCreationEnabled = false;
      this.hasXperincesEnabled = false;
      this.hasSamplingEnabled = false;
      this.hasPhonecallEnabled = false;
      this.hasProcedureLogEnabled = false;
      this.hasProcedureLogConfigEnabled = false;
      this.hasBulkProcedureLogConfigEnabled = false;
      this.hasStoreCheckEnabled = false;
      this.hasKitBookingEnabled = false;
      if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)) {
        this.hasCustomerInquiryEnabled = true;
      }
      if (this.hasDirectMessagingEnabled) {
        this.uiService.setActiveViewForNewMeeting('email');
        this.trackingService.tracking('Select Message', TrackingEventNames.PHONECALL);
      }
      else if (this.hasCustomerInquiryEnabled) {
        this.uiService.setActiveViewForNewMeeting('customerinquiry');
        this.trackingService.tracking('Select inquiry', TrackingEventNames.PHONECALL);
      }
    }
    if (this.NewActivitySource === ActivitySource.SET_BOOKING_ACTIVITY_DETAIL) {
      this.isMeetingEnabled = true;
      this.uiService.setActiveViewForNewMeeting('meeting');
      if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)) {
        this.hasCustomerInquiryEnabled = true;
        //this.checkNumOfCaseList();
        //this.uiService.setActiveViewForNewMeeting('customerinquiry');
        //this.trackingService.tracking('Select inquiry', TrackingEventNames.SETBOOKING);
      }
      this.isSurveyEnabled = false;
      this.isAccountVisitEnabled = false;
      this.hasTimeoffEnabled = false;
      this.hasLiveMeetEnabled = false;
      this.isAccountPlanTaskEnabled = false;
      this.isMarketingPlanTaskEnabled = false;
      this.isEventFollowUpActionEnabled = false;
      this.isScientificPlanTaskEnabled = false;
      this.isFollowUpActionEnabled = false;
      this.isOrderCreationEnabled = false;
      this.hasXperincesEnabled = false;
      this.hasSamplingEnabled = false;
      this.hasPhonecallEnabled = false;
      this.hasProcedureLogEnabled = false;
      this.hasProcedureLogConfigEnabled = false;
      this.hasBulkProcedureLogConfigEnabled = false;
      this.hasStoreCheckEnabled = false;
      this.hasKitBookingEnabled = false;
      this.hasDirectMessagingEnabled = false;
    }

    if(this.featureActionService.isShortCallLauncherEnabledInMobileDevice && this.authService.hasFeatureAction(FeatureActionsMap.SHOR_CALL_LAUNCHER_DISABLE_APPOINTMENT_IN_NEW_ACTIVITY)) {
      this.setActiveViewForNewMeeting();
      this.isMeetingEnabled = this.isAccountVisitEnabled = false;
    }
  }

  private setActiveViewForNewMeeting() {
    if (this.hasTimeoffEnabled) {
      this.uiService.setActiveViewForNewMeeting('timeoff');
    } else if (this.hasSamplingEnabled && !this.noEligibleAllocation) {
      this.uiService.setActiveViewForNewMeeting('allocation');
    } else if (this.hasDirectMessagingEnabled) {
      this.uiService.setActiveViewForNewMeeting('email');
    } else if (this.isFollowUpActionEnabled) {
      this.uiService.setActiveViewForNewMeeting('follow-up-action');
    } else if (this.isOrderCreationEnabled) {
      this.uiService.setActiveViewForNewMeeting('order');
    } else if (this.hasPhonecallEnabled && !this.noPhoneNumber) {
      this.uiService.setActiveViewForNewMeeting('phonecall');
    } else if (this.hasProcedureLogEnabled) {
      this.uiService.setActiveViewForNewMeeting('surgeryorder');
    } else if (this.hasOpportunityEnabled) {
      this.uiService.setActiveViewForNewMeeting('opportunity');
    } else if (this.isSurveyEnabled) {
      this.uiService.setActiveViewForNewMeeting('survey');
    } else if (this.hasStoreCheckEnabled && !this.device.isOffline) {
      this.uiService.setActiveViewForNewMeeting('storecheck');
    }
  }

  private newActivityOptionsInMeetingDetails() {
    this.isMeetingEnabled = false;
    this.isAccountVisitEnabled = false;
    this.hasTimeoffEnabled = false;
    this.hasLiveMeetEnabled = false;
    this.isAccountPlanTaskEnabled = false;
    this.isMarketingPlanTaskEnabled = false;
    this.isEventFollowUpActionEnabled = false;
    this.isScientificPlanTaskEnabled = false;
    this.isOpportunityTaskEnabled = false;
    this.isNoneTaskEnabled = false;
    //this.isFollowUpActionEnabled = true;
    this.isMeetingTaskEnabled = true;
    this.followUpActionCategory = 'meetingtask';
    this.isOrderCreationEnabled = false;
    this.hasXperincesEnabled = false;
    this.hasPhonecallEnabled = false;
    this.hasStoreCheckEnabled = false;
    this.hasKitBookingEnabled = false;
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)) {
      this.uiService.setActiveViewForNewMeeting('allocation');
      this.hasSamplingEnabled = true;
    }
    else if (this.hasDirectMessagingEnabled) {
      this.uiService.setActiveViewForNewMeeting('email');
    }
    else if (this.hasCustomerInquiryEnabled) {
      this.uiService.setActiveViewForNewMeeting('customerinquiry');
    }
    else if (this.hasProcedureLogEnabled) {
      this.uiService.setActiveViewForNewMeeting('surgeryorder');
    }
    else if (this.isFollowUpActionEnabled){
      this.uiService.setActiveViewForNewMeeting('follow-up-action');
    }

    if (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SURVEY) || this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_SURVEY)) {
      this.isSurveyEnabled = true;
      this.customerSurveyService.selectedContactFromContactTool = this.contactService.contactInformation || null;
    }

    // User is taken to Activity selection page when there is only one activity
    if ((this.hasSamplingEnabled || this.hasProcedureLogEnabled) && !this.hasDirectMessagingEnabled && !this.hasCustomerInquiryEnabled && !this.isFollowUpActionEnabled) {
      this.handleNewActivity();
    }
    // If there is only one channel/case in the Message/Customer inquiry, it will be selected automatically
    if (this.hasDirectMessagingEnabled) {
      this.checkNumOfChannelList();
    }
    if (this.hasCustomerInquiryEnabled) {
      this.checkNumOfCaseList();
    }
  }
  private newActivityOptionsInEventDetails() {
    this.isMeetingEnabled = false;
    this.isAccountVisitEnabled = false;
    this.hasTimeoffEnabled = false;
    this.hasLiveMeetEnabled = false;
    this.isAccountPlanTaskEnabled = false;
    this.isMarketingPlanTaskEnabled = false;
    this.isScientificPlanTaskEnabled = false;
    this.isOpportunityTaskEnabled = false;
    this.isNoneTaskEnabled = false;
    this.isMeetingTaskEnabled = false;
    this.isOrderCreationEnabled = false;
    this.hasXperincesEnabled = false;
    this.hasPhonecallEnabled = false;
    this.isSurveyEnabled = false;
    this.hasProcedureLogEnabled = false;
    this.hasProcedureLogConfigEnabled = false;
    this.hasBulkProcedureLogConfigEnabled = false;
    this.hasCustomerInquiryEnabled = false;
    this.hasDirectMessagingEnabled = false;
    this.hasStoreCheckEnabled = false;
    this.hasKitBookingEnabled = false;
    this.followUpActionCategory = 'eventtask';
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)) {
      this.uiService.setActiveViewForNewMeeting('allocation');
      this.hasSamplingEnabled = true;
    } else if (this.isFollowUpActionEnabled && this.isEventFollowUpActionEnabled) {
      this.uiService.setActiveViewForNewMeeting('follow-up-action');
    }
  }

  async checkNumOfChannelList() {
    const channelList = await this.directMessagingService.channelList.pipe(map(channels => channels.map(channel => (channel))), first()).toPromise();
    if (_.size(channelList) == 1) {
      this.selectMessagingType(channelList[0]);
    }
  }

  checkNumOfCaseList() {
    const caseList = this.caseManagementService.caseTypeList;
    // if (_.size(caseList) == 1) {
    //   this.selectCaseManagementType(caseList[0]);
    // }
    if (caseList.length === 1) {
      this.selectCaseManagementType(caseList[0]);
    }
  }


  ngOnDestroy() {
    // this.activityOfflineService.activitySource = null;
    this.dateTimeFormatsService.isNewActivity = false;
    this.events.unsubscribe('updatedCellClick', this.setCellData);
    this.events.unsubscribe('updatedCellClick', this._updateCellClick);
    if (this._updateDayClick) this.events.unsubscribe('updatedDayClick', this._updateDayClick);
    this.events.unsubscribe('updateCurrentDate', this._updateCurrentDate);
    this.events.unsubscribe('updateNewTimeOffDateTime');
    this.eventService.unsubscribe('remote_meeting_participant_Joined_left', this.handleContactConnectionLost);
    this.IcurrentMeetingActivitySubscription.unsubscribe();
    if (this.ItranslatedCustomerTextSubscription) this.ItranslatedCustomerTextSubscription.unsubscribe();
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.dateFormatSubscription.unsubscribe();
    this.timeFormatSubscription.unsubscribe();
  }

  ionViewWillEnter() {
    this._orientationHandler = (modeChange: VIEW_MODE_CHANGE, curPageName: PageName, curMasterPageName: PageName) => {
      if (curPageName == PageName.OrderAddQuantityComponent) return;
      if (!this.uiService.showNewActivity) this.uiService.showNewActivity = true;

      /**
       * Slowly move to CSS instead of ionic navigation
       */
      if (curMasterPageName === PageName.ActivitiesPageComponent) {
        if (modeChange === VIEW_MODE_CHANGE['mobile-to-normal']) {
          this.uiService.showRightPane = false;
        }
      } else if (curMasterPageName === PageName.CallPlanComponent) {
        if (modeChange === VIEW_MODE_CHANGE['mobile-to-normal']) {
          this.navService.popWithPageTracking();
        }
      } else if (curMasterPageName === PageName.ContactPageComponent) {
        if (modeChange === VIEW_MODE_CHANGE['mobile-to-normal']) {
          this.navService.popWithPageTracking();
        }
      }
    };
    this.events.subscribe('device:view-mode-change', this._orientationHandler);
  }

  ionViewDidEnter() {
    //this.content.resize();
  }

  ionViewWillLeave() {
    if (this._orientationHandler) {
      this.events.unsubscribe('device:view-mode-change', this._orientationHandler);
    }
  }

  public initNewActivityScreenFormFields(): void {
    let buttons = []
    if (this.activityOfflineService.activitySource === ActivitySource.MEETING_PRESENTATION) {
      buttons.push({
        id: 'new_activity_cancel_button',
        text: this.translate.instant('CANCEL'),
        isDisabled: false,
      },
        {
          id: 'new_activity_confirm_button',
          text: this.translate.instant('CONFIRM'),
          isDisabled: !this.isConfirmEnabled,
        })
    }

    this.isMeetingPresentation = (this.activityOfflineService.activitySource === ActivitySource.MEETING_PRESENTATION) ? true : false;
    this.newActivityHeader = {
      id: 'new-activity-details-header',
      title: (<String>this.translate.instant('MEETING_ACTIVITIES')).toUpperCase(),
      controls: buttons,
    };
  }
  private initNewActivityPagetitle(): void {
    if (this.activityOfflineService.activitySource === ActivitySource.MEETING_DETAIL ||
      this.activityOfflineService.activitySource === ActivitySource.MEETING_PRESENTATION
    ) {
      this.newActivityTitle = this.translate.instant('MEETING_ACTIVITIES');
    }
    if (this.activityOfflineService.activitySource === ActivitySource.EVENT_DETAIL) {
      this.newActivityTitle = this.translate.instant('EVENT_ACTIVITIES');
    }
    if (this.activityOfflineService.activitySource === ActivitySource.PHONE_CALL_ACTIVITY_DETAIL) {
      this.newActivityTitle = this.translate.instant('CALL_ACTIVITIES');
    }
    if (this.activityOfflineService.activitySource === ActivitySource.SET_BOOKING_ACTIVITY_DETAIL) {
      this.newActivityTitle = this.translate.instant('KIT_BOOKING_ACTIVITIES');
    }
    this.newActivityPageTitle = {
      id: "new-activity-page-title",
      title: this.translate.instant(this.newActivityTitle) + " " + this.forContactName,
      controls: [
        {
          id: 'new_activity_cancel_button',
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('CANCEL'),
          isDisabled: false,
          align: "right"
        },
        {
          id: 'new_activity_confirm_button',
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('CONFIRM'),
          isDisabled: this.backgroundUploadInProgress ? true : !this.isConfirmEnabled,
          align: "right"
        }
      ]
    }
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'new_activity_cancel_button':
        this.cancelNewActivity();
        break;
      case 'new_activity_confirm_button':
        if (this.isConfirmEnabled && this.checkCustomValidation()) {
          this.handleNewActivity();
        }
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private checkCustomValidation(): boolean {
    let flag = true;
    if (this.uiService.activeViewNewMeeting == 'surgeryorder' && this.procedureCategory == 'noProcedureDay') {
      if (this.NewActivitySource === ActivitySource.MEETING_PRESENTATION || this.activityOfflineService.activitySource === ActivitySource.MEETING_DETAIL && this.activityOfflineService.selectedActivity) {
        if (isFuture((this.activityOfflineService.selectedActivity as AppointmentActivity).scheduledStart.getTime())
          && !isToday((this.activityOfflineService.selectedActivity as AppointmentActivity).scheduledStart.getTime()) && this.procedureCategory == 'noProcedureDay') {
          this.notificationService.notify(this.translate.instant('SHEDULE_PROCEDURE_LOG_FUTURE'), 'New Activity', 'top', ToastStyle.DANGER);
          flag = false;
          return flag;
        }
      }
    }
    return flag;
  }

  ngDoCheck() {
    this.initHeaderOnOptionSelection();
  }

  private initHeaderOnOptionSelection() {
    let prevConfirmEnabledFlag = this.isConfirmEnabled;
    this.isConfirmEnabled = true;
    switch (this.uiService.activeViewNewMeeting) {
      case 'email':
        if (!this.selectedMessagingChannelType) {
          this.isConfirmEnabled = false;
        }
        break;
      case 'meeting':
        if (this.uiService.activeViewNewMeetingType === 'live' && !this.selectedEvent) {
          this.isConfirmEnabled = false;
        }
        break;
      case 'customerinquiry':
        if (!this.type) {
          this.isConfirmEnabled = false;
        }
        break;
      case 'follow-up-action':
        this.isConfirmEnabled = this.followUpActionCategory ? true : false;
        break;
      case 'new-insight':
        this.isConfirmEnabled = this.NewActivitySource === ActivitySource.AGENDA && !this.selectedInsightType ? false : true;
        break;
      case 'surgeryorder':
        this.isConfirmEnabled = this.procedureCategory ? true : false;
        if (this.device.isOffline && this.procedureCategory == 'procedure_tracker') this.isConfirmEnabled = false;
        break;
      case 'consent':
        if (!this.selectedConsentCustomer) {
          this.isConfirmEnabled = false;
        }
        break;
    }
    if (this.isConfirmEnabled != prevConfirmEnabledFlag) {
      if (this.activityOfflineService.activitySource === ActivitySource.MEETING_PRESENTATION) {
        this.initNewActivityScreenFormFields();
      } else {
        this.initNewActivityPagetitle();
      }
      this._cd.detectChanges();
    }
  }

  /**
   *
   *
   * @memberof NewActivityComponent
   */
  cancelNewActivity = () => {
    if (this.uiService.activeViewNewMeeting == 'phonecall' ||
      this.activityOfflineService.activitySource === ActivitySource.PHONE_CALL_ACTIVITY_DETAIL) {
      this.trackingService.tracking('Select Cancel', TrackingEventNames.PHONECALL);
    }
    if (this.isLayoverPushView && this.layoverViewPushedFrom) {
      this.activityOfflineService.activitySource = null;
      this.navService.popChildNavPageWithPageTracking();
    }
    if (this.NewActivitySource === ActivitySource.MEETING_PRESENTATION) {
      if ((!this.selectedMeetingActivity) || (this.selectedMeetingActivity && this.selectedMeetingActivity.type === EmbeddedInteractionType.Content)) {
        this.footerService.initButtons(FooterViews.PreviewMeeting);
      }
      this.presentationService.showNewActivity = false;
      this.navService.popChildNavPageWithPageTracking();
    }
    this.uiService.showNewActivity = false;
    this.onCancelNewActivity.emit();
    this.uiService.setCellData = null;
    const curPage = this.navService.getCurrentPageName();
    const curMasterPageName = this.navService.getCurrentMasterPageName();
    if (curPage === PageName.NewActivityComponent) {
      /*setTimeout(() => {
        this.viewCtrl.dismiss().then(() => {
          this.footerService.initButtons(FooterViews.Calendar);
        }); // the data bind error in the third party library
      }, 100);*/
      this.navService.popWithPageTracking();
    } else if (curMasterPageName == PageName.CallPlanComponent) {
      this.uiService.showNewActivity = false;
      if (this.NewActivitySource === ActivitySource.MEETING_DETAIL) {
        this.callPlanService.callPlanPageView = 'CallPlanNewMeetingDetails';
        this.navService.popChildNavPageWithPageTracking();
        this.uiService.activeView = 'Appointment';
      } else if (this.NewActivitySource === ActivitySource.PHONE_CALL_ACTIVITY_DETAIL) {
        this.callPlanService.callPlanPageView = 'CallPlanPhoneCallDetails';
        this.navService.popChildNavPageWithPageTracking();
        this.uiService.activeView = 'phoneCallTimelineDetails';
      } else {
        this.callPlanService.callPlanPageView = 'CallPlanDetail';
        this.navService.popChildNavPageWithPageTracking();
        this.uiService.activeView = 'CallPlanDetails';
      }
    } else if (curMasterPageName == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab == 'plans') {
      this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      this.uiService.showRightPane = true;
      if (this.navService.getActiveChildNavViewPageName() == PageName.NewActivityComponent)
        this.navService.popChildNavPageWithPageTracking();
    } else if (this.NewActivitySource === ActivitySource.MEETING_DETAIL) {
      if (curMasterPageName === PageName.ContactPageComponent) {
        this.uiService.activeView = 'ContactPageRightPaneNav';
      } else {
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      }
      this.footerService.initButtons(FooterViews.Activities);
      this.activityOfflineService.activitySource = null;
    }
    else if (this.NewActivitySource === ActivitySource.PHONE_CALL_ACTIVITY_DETAIL) {
      if (curMasterPageName === PageName.ContactPageComponent) {
        this.uiService.activeView = 'phoneCallTimelineDetails';
      } else {
        this.uiService.activeView = 'PhoneCall';
      }
      this.footerService.initButtons(FooterViews.PHONE_CALL);
      this.activityOfflineService.activitySource = null;
    } else if (this.navService.getActiveChildNavViewPageName() === PageName.NewActivityComponent) {
      this.navService.popChildNavPageWithPageTracking();
    }


    /**
     * Handles Mobile View
     * Should switch back to left pane
     */
    if (this.device.shouldBeMobileView && (this.NewActivitySource !== ActivitySource.MEETING_DETAIL) && this.NewActivitySource !== ActivitySource.PHONE_CALL_ACTIVITY_DETAIL && this.uiService.activitiesPageTab !== 'plans') {
      this.uiService.showRightPane = false;
    }
  };

  // canConfirmNewActivity() {
  //   switch (this.uiService.activeViewNewMeeting) {
  //     case 'email':
  //       if (!this.selectedMessagingChannelType) return false;
  //     case 'meeting':
  //       if (this.uiService.activeViewNewMeetingType === 'live' && !this.selectedEvent) {
  //         return false;
  //       }
  //       break;
  //     case 'customerinquiry':
  //       if (!this.type) {
  //         return false;
  //       }
  //       break;
  //   }
  //   return true;
  // }
  /**
   *
   *
   * @private
   * @memberof NewActivityComponent
   */
  public async handleNewActivity() {
    switch (this.uiService.activeViewNewMeeting) {
      case 'MeetingPresentation':
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = 'Presentation';
        this.presentationService.viewMode = PresentationView.ADDTOMEETING;
        this.navService.pushWithPageTracking(PresentationPageComponent, PageName.PresentationPageComponent);
        this.uiService.showNewActivity = false;
        break;

      case 'meeting':
        if (this.agendaFooterService.actSegment === 'week') {
          this.trackingService.tracking('CalendarNewActivity', TrackingEventNames.ACTIVITY);
        }
        else {
          this.trackingService.tracking('AgendaMeetingCreated', TrackingEventNames.ACTIVITY)
        }
        if (!this.activityOfflineService.validateMeetingDate(this.startTime)) {
          return;
        }
        // await this.uiService.displayLoader();
        let x = await this.createNewMeeting(this.uiService.activeViewNewMeetingType);
        // await this.uiService.dismissLoader();
        // update the Plan tab
        if (this.agendaFooterService.actSegment === 'agenda' && this.agendaFooterService.planTabSegment === 'planTabTodo') {
          if (this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.status !== 3 && this.activityOfflineService.selectedActivity.status !== 4) {
            this.events.publish('aciviiyCreatedRefreshTODO', this.activityOfflineService.selectedActivity);
          }
        }

        if (this.isLayoverPushView && this.layoverViewPushedFrom) {
          try {
            this.navService.popChildNavPageWithPageTracking();
            this.uiService.showCancelDoneOnActivityDetails = true;
            if (this.layoverViewPushedFrom == 'AccountManagementTool') {
              this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.AccountManagementPageComponent, { from: 'AccountManagementTool', isNewlyCreatedActivity: true });
              return;
            } else if (this.layoverViewPushedFrom == 'AccountTool') {
              this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.AccountPageComponent, { from: 'AccountTool', isNewlyCreatedActivity: true });
            } else if (this.layoverViewPushedFrom == 'OpportunityManagementTool') {
              this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.AccountPageComponent, { from: 'OpportunityManagementTool', isNewlyCreatedActivity: true });
            } else if (this.layoverViewPushedFrom == 'ScientificActivityPage') {
              this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ScientificActivityPage, { from: 'ScientificActivityPage', isNewlyCreatedActivity: true });
            } else if (this.layoverViewPushedFrom == 'PresentationToolsPage') {
              this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.PresentationPageComponent, { from: 'PresentationToolsPage', isNewlyCreatedActivity: true });
            } else if (this.layoverViewPushedFrom === 'ContactPageComponent') {
              this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ContactPageComponent, { from: this.layoverViewPushedFrom, isNewlyCreatedActivity: true });
            }
            else if (this.layoverViewPushedFrom == 'ProcedureLogFrom') {
              this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.SurveyPageComponent, { from: 'ProcedureLogFrom', isNewlyCreatedActivity: true });
            }
            else if(this.layoverViewPushedFrom == 'MarketingPlanInfoopportunities'){
              this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.AccountPageComponent, { from: 'marketingPlanTool', isNewlyCreatedActivity: true });
              return;
            } else if (this.layoverViewPushedFrom == PageName.SetBookingActivityDetailsComponent) {
              this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.SetBookingActivityDetailsComponent, { from: this.layoverViewPushedFrom, isNewlyCreatedActivity: true });
              this.activityOfflineService.isNewActivityCreated = true;
              this.activityOfflineService.isLayOverPushedMeetingFromOtherActivities = true;
            } else if (this.layoverViewPushedFrom == PageName.CustomerCallPlanDetails) {
              this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.CustomerCallPlanPage, { from: PageName.CustomerCallPlanDetails, isNewlyCreatedActivity: true });
            }
          } catch (error) {
            console.log(error);
          }
        }
        else if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab !== 'plans') {
          this.uiService.showCancelDoneOnActivityDetails = false;
        }
        this.activityOfflineService.isNewMeetingFlow = true;
        this.activityOfflineService.selectedActivityAtHome = this.activityOfflineService.selectedActivity;

        break;
      case 'accountVisit': {
        if (!this.activityOfflineService.validateMeetingDate(this.startTime)) {
          return;
        }

        // Prevent offline for account visit
        if (this.activityOfflineService.accountVisitOfflineCheck(
          {
            isAccountVisitEnabled: true,
            isAccountVisitRecord: true,
            isAccountVisitNestedMeeting: false,
          },
          true,
        )) {
          return;
        }

        await this.createNewAccountVisit();
        break;
      }
      case 'email':
        this.trackingService.tracking('EmailActivityFromNewActivity', TrackingEventNames.EMAIL, true);
        if (this.isLayoverPushView) {
          this.navService.popChildNavPageWithPageTracking();
        }
        if (this.activityOfflineService.activitySource === ActivitySource.MEETING_DETAIL ||
          this.activityOfflineService.activitySource === ActivitySource.MEETING_PRESENTATION) {
          await this.createEmailInMeeting();
        } else if (this.activityOfflineService.activitySource === ActivitySource.PHONE_CALL_ACTIVITY_DETAIL) {
          await this.createEmailInPhoneCallActivity();
        } else {
          await this.createDraftEmail();
        }
        this.activityOfflineService.selectedActivityAtHome = this.activityOfflineService.selectedActivity;
        break;

      case 'timeoff':
        if (!this.activityOfflineService.validateTimeOffDate(this.startTime)) {
          return;
        }
        this.createNewTimeOff();
        if (this.agendaFooterService.actSegment === 'week') {
          this.trackingService.tracking('TimeOffCalendarNew', TrackingEventNames.TIMEOFF, true);
        }
        else {
          this.trackingService.tracking('AgendaTimeOffCreated', TrackingEventNames.ACTIVITY);
          // return;
        }
        break;

      case 'allocation':
        if (this.isLayoverPushView && this.from != 'ContactPageComponent') {
          this.navService.popChildNavPageWithPageTracking();
        }
        if (this.activityOfflineService.activitySource === ActivitySource.MEETING_DETAIL ||
          this.activityOfflineService.activitySource === ActivitySource.MEETING_PRESENTATION) {
          this.trackingService.tracking('AllocationOrderCreatedMeetingDetails', TrackingEventNames.ALLOCATIONS)
          await this.createOrEditSampleOrderFromMeeting();
        } else {
          if (this.NewActivitySource === ActivitySource.EVENT_DETAIL) {
            await this.createOrEditSampleOrderFromEvent();
          } else {
            this.trackingService.tracking('AgendaAllocationCreated', TrackingEventNames.ACTIVITY)
            await this.createNewSampleRequest();
          }
        }
        this.activityOfflineService.selectedActivityAtHome = this.activityOfflineService.selectedActivity;
        break;

      case 'follow-up-action':
        //To Do : Handle new follow-up-action creation
        if (
          this.followUpActionCategory == 'none'
          || this.followUpActionCategory == 'accountplantask'
          || this.followUpActionCategory == 'meetingtask'
          || this.followUpActionCategory == 'marketingplantask'
          || this.followUpActionCategory === 'eventtask'
        ) {
          const loader = await this.loadingController.create({});
          let inMeetingActivity: Activity;
          loader.present();
          await this.handleNewFollowUpActionTaskCreation().then(success => {
            if(this.followUpActionCategory == 'meetingtask' || this.followUpActionCategory === 'eventtask'){
              // Publish Activity event to add to calendar
              this.activityOfflineService.publishActivityEvent({ action: "Create", activity: this.activityOfflineService.selectedActivity });
              inMeetingActivity = this.followUpActivityDataService.inMeetingFollowupActionActivity
            }
            loader.dismiss();
          }).catch(error => {
            loader.dismiss();
            //Handle error in new follow up creation for UI
          });
          if (this.NewActivitySource === ActivitySource.MEETING_PRESENTATION) {
            this.meetingStructureService.createInMeetingEmbededActivity(inMeetingActivity);
            this.handleNewActivityCreatedUpdatePlanTab(inMeetingActivity);
            this.meetingStructureService.openInMeetingActivitiesModal(inMeetingActivity, true);
          } else if (this.activityOfflineService.activitySource === ActivitySource.MEETING_DETAIL) {
            this.meetingStructureService.createInMeetingEmbededActivity(inMeetingActivity);
            this.handleNewActivityCreatedUpdatePlanTab(inMeetingActivity);
            this.meetingStructureService.openInMeetingActivitiesModal(inMeetingActivity, true);
            if (this.isLayoverPushView) {
              this.navService.popChildNavPageWithPageTracking();
            }
          } else if (
            this.NewActivitySource === ActivitySource.EVENT_DETAIL
            || this.activityOfflineService.activitySource === ActivitySource.EVENT_DETAIL
          ) {
            this.meetingStructureService.createInMeetingEmbededActivity(inMeetingActivity);
            this.handleNewActivityCreatedUpdatePlanTab(inMeetingActivity);
            this.meetingStructureService.openInMeetingActivitiesModal(inMeetingActivity, true);
            if (this.isLayoverPushView) {
              this.navService.popChildNavPageWithPageTracking();
            }
          }
          this.activityOfflineService.selectedActivityAtHome = this.activityOfflineService.selectedActivity;
          const targetActivity = inMeetingActivity || this.activityOfflineService.selectedActivity;
          this.activityOfflineService.publishActivityEvent({ action: "Create", activity: targetActivity });
          return;
        } else if (this.followUpActionCategory == 'scientificplantask') {
          const loader = await this.loadingController.create({});
          loader.present();
          await this.handleNewSAPFollowUpAction().then(success => {
            loader.dismiss();
          }).catch(error => {
            loader.dismiss();
          });
        } else if (this.followUpActionCategory == 'opportunitytask') {
          const loader = await this.loadingController.create({});
          loader.present();
          await this.handleNewFollowUpActionTaskCreation().then(success => {
            loader.dismiss();
          }).catch(error => {
            loader.dismiss();
            //Handle error in new follow up creation for UI
          });
        } else {
          this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_SELECT_FOLLOW_UP'), 'New Activity');
        }
        if (this.agendaFooterService.actSegment === 'agenda' && this.agendaFooterService.planTabSegment === 'planTabTodo') {
          if (this.activityOfflineService.selectedActivity) {
            this.events.publish('aciviiyCreatedRefreshTODO', this.activityOfflineService.selectedActivity);
          }
        }
        this.activityOfflineService.selectedActivityAtHome = this.activityOfflineService.selectedActivity;
        this.activityOfflineService.publishActivityEvent({ action: "Create", activity: this.activityOfflineService.selectedActivity });
        return;
      case 'order':
        const loader = await this.loadingController.create({});
        loader.present();
        await this.handleNewOrderCreation().then(success => {
          this.activityOfflineService.publishActivityEvent({ action: "Create", activity: this.activityOfflineService.selectedActivity });
          loader.dismiss();
        }).catch(error => {
          loader.dismiss();
          //Handle error in new follow up creation for UI
        });
        this.activityOfflineService.selectedActivityAtHome = this.activityOfflineService.selectedActivity;
        return;
      case 'surgeryorder':
        {
          const loader = await this.loadingController.create({});
          let procedureError = false;
          loader.present();
          let inMeetingActivity: Activity;
          if (this.procedureCategory == 'procedure_tracker') {
            if (this.device.isOffline) {
              loader.dismiss();
              return;
            }
            await this.handleNewProcedureTrackerCreation().then(success => {
              this.activityOfflineService.publishActivityEvent({ action: "Create", activity: this.activityOfflineService.selectedActivity });
              inMeetingActivity = this.procedureTrackerActivityDataService.inMeetingProcedureTrackerActivity;
              loader.dismiss();
            }).catch(error => {
              loader.dismiss();
              procedureError = true;
            });
            if (procedureError) return;
          } else {
            await this.handleNewSurgeryOrderCreation().then(success => {
              this.activityOfflineService.publishActivityEvent({ action: "Create", activity: this.activityOfflineService.selectedActivity });
              inMeetingActivity = this.surgeryOrderActivityDataService.inMeetingSurgeryOrderActivity
              loader.dismiss();
            }).catch(error => {
              loader.dismiss();
            });
          }


          if (this.NewActivitySource === ActivitySource.MEETING_PRESENTATION) {
            this.meetingStructureService.createInMeetingEmbededActivity(inMeetingActivity);
            this.handleNewActivityCreatedUpdatePlanTab(inMeetingActivity);
            this.meetingStructureService.openInMeetingActivitiesModal(inMeetingActivity, true);
          } else if (this.activityOfflineService.activitySource === ActivitySource.MEETING_DETAIL) {
            this.meetingStructureService.createInMeetingEmbededActivity(inMeetingActivity);
            this.handleNewActivityCreatedUpdatePlanTab(inMeetingActivity);
            this.meetingStructureService.openInMeetingActivitiesModal(inMeetingActivity, true);
            if (this.isLayoverPushView) {
              this.navService.popChildNavPageWithPageTracking();
            }
          }
          return;
        }
      case 'quote':
        {
          const loader = await this.loadingController.create({});
          try {
            loader.present();
            await this.handleNewQuoteCreation().then(success => {
              this.activityOfflineService.publishActivityEvent({ action: "Create", activity: this.activityOfflineService.selectedActivity });
            });
          }
          finally {
            loader.dismiss();
          }
          return;
        }
      case 'consent':
        {
          try {
            await this.handleNewConsentCreation()
          }
          finally {
          }
          return;
        }
      case 'new-insight':
        this.uiService.showNewActivity = false;
        if (this.NewActivitySource == ActivitySource.AGENDA) {
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.activityOfflineService.selectedActivity = null;
          this.activityOfflineService.selectedActivityAtHome = null;
          this.uiService.showRightPane = true;
          if (this.selectedInsightType === INSIGHT_TYPE.CUSTOMER_INSIGHT) {
            this.xperiencesService.newInsight = new TrendingCustomer();
            this.navService.pushChildNavPageWithPageTracking(CustomerInsightComponent, PageName.CustomerInsightComponent, PageName.ActivitiesPageComponent, { from: PageName.ActivitiesPageComponent });
          } else {
            this.xperiencesService.generalInsight = null;
            this.navService.pushChildNavPageWithPageTracking(GeneralInsightsPlusComponent, PageName.GeneralInsightsPlusComponent, PageName.ActivitiesPageComponent, { from: PageName.ActivitiesPageComponent });
          }
        } else if (this.layoverViewPushedFrom === 'ContactPageComponent') {
          this.navService.popChildNavPageWithPageTracking();
          let newInsight: TrendingCustomer = new TrendingCustomer();
          this.xperiencesService.newInsight = newInsight;
          this.uiService.activeView = 'ContactPageRightPaneNav';
          newInsight.contactid = this.contactService.contactInformation.ID;
          newInsight.fullname = this.contactService.contactInformation.fullname;
          this.navService.pushChildNavPageWithPageTracking(CustomerInsightComponent, PageName.CustomerInsightComponent, PageName.ContactPageComponent, { from: PageName.ContactPageComponent });
        }
        return;
      case 'customerinquiry':
        let iCase: CaseActivity;
        if (this.activityOfflineService.activitySource === ActivitySource.MEETING_DETAIL ||
          this.activityOfflineService.activitySource === ActivitySource.MEETING_PRESENTATION) {
          await this.createCustomerInquiryForMeeting(AccesingMode.INMEETING);
          if (this.isLayoverPushView) {
            this.navService.popChildNavPageWithPageTracking();
          }
          if (this.caseManagementService.currentCase) this.uiService.showNewActivity = false;
        } else if (this.activityOfflineService.activitySource == ActivitySource.PHONE_CALL_ACTIVITY_DETAIL) {
          await this.createCustomerInquiryForMeeting(AccesingMode.IN_PHONE_CALL);
          if (this.isLayoverPushView) {
            this.navService.popChildNavPageWithPageTracking();
          }
          if (this.caseManagementService.currentCase) this.uiService.showNewActivity = false;
        } else if(this.activityOfflineService.activitySource == ActivitySource.SET_BOOKING_ACTIVITY_DETAIL){
          await this.createCustomerInquiryForMeeting(AccesingMode.IN_SET_BOOKING);
          if(this.isLayoverPushView) {
            this.activityOfflineService.prevSelectedActivity = this.activityOfflineService.selectedActivity;
            this.navService.popChildNavPageWithPageTracking();
          }
        } else {
          this.trackingService.tracking('InquiryActivityConfirm', TrackingEventNames.CUSTOMER_INQUIRY);
          if (this.navService.getCurrentMasterPageName() != PageName.ContactPageComponent) {
            let preSelectedData: Object = {};
            preSelectedData['startTime'] = this.startTime ? new Date(this.startTime).getTime() : new Date().getTime();
            try {
              iCase = await this.caseManagementService.initNewCaseActivity(this.type, true, preSelectedData);
            } catch (error) {
              console.log(error);
              console.log('inquiry created but unable to update calendar plugin');
            }

            if (iCase) {
              this.caseManagementService.assignSelectedCase(iCase);
              this.uiService.showNewActivity = false;
              this.uiService.showRightPane = true;
              this.activityOfflineService.selectedActivityAtHome = this.activityOfflineService.selectedActivity = iCase;
              this.uiService.prevView = this.uiService.activeView;
            }
            else {
              return;
            }
          }

          if
            (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent) {
            this.caseManagementService.accessedFrom = AccesingMode.AGENDA;
            this.uiService.activeView = 'ActivitiesPageRightPaneNav';
            // //This should be removed once new-activity component is replaced with ion-nav
            // if(this.navService.getActiveChildNavViewPageName() === PageName.CaseManagementDetailComponent) {
            //   await this.navService.popChildNavPageWithPageTracking();
            // }
            // this.navService.setChildNavRoot(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent, PageName.ActivitiesPageComponent);
            this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
            this.navService.pushChildNavPageWithPageTracking(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent, PageName.ActivitiesPageComponent, { from: PageName.ActivitiesPageComponent });

          }
          // if(this.navService.getCurrentMasterPageName() === PageName.CalendarPage) {
          //   this.caseManagementService.accessedFrom = AccesingMode.CALENDAR;
          // }
          else if (this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent ||
            (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent && !this.footerService.isActivitiesButton)) {
            let preSelectedData: Object = {};
            if (this.contactService.contactInformation) {
              preSelectedData['contacts'] = new Array(1).fill(this.contactService.contactInformation);
            }
            let iCase: CaseActivity = await this.caseManagementService.initNewCaseActivity(this.type, true, preSelectedData);
            this.caseManagementService.assignSelectedCase(iCase);
            this.uiService.showNewActivity = false;
            this.activityOfflineService.selectedActivity = iCase;
            await this.navService.popChildNavPageWithPageTracking();
            // this.uiService.activeView = 'ContactPageRightPaneNav';
            this.caseManagementService.accessedFrom = AccesingMode.CONTACT;
            this.navService.pushChildNavPageWithPageTracking(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent, PageName.ContactPageComponent, { from: PageName.ContactDetailsComponent });
          }
          else {
            this.uiService.activeView = 'customer_inquiry';
          }
          try {
            this.eventService.publish('weekview:RefreshUI');
          } catch (error) {
            console.log('error publishing new event');
          }
        }
        break;
      case 'phonecall':
        if (this.agendaFooterService.actSegment === 'week') {
          this.trackingService.tracking('PhoneCallCalendarNew', TrackingEventNames.PHONECALL, true);
        }
        else {
          this.trackingService.tracking('Select Confirm', TrackingEventNames.PHONECALL);
        }
        const loadIndicator = await this.loadingController.create();
        loadIndicator.present();

        await this.createNewPhoneCall();
        loadIndicator.dismiss();
        if (this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.PhoneCall)
          this.footerService.initButtons(FooterViews.PHONE_CALL);

        if (this.isLayoverPushView) {
          try {
            if (this.layoverViewPushedFrom == 'AccountManagementTool') {
              if (this.uiService.activitiesPagePlanTabSelectedPlan === 'accountPlansTab') {
                this.uiService.activeView = 'ActivitiesPageRightPaneNav';
              }
              this.navService.popChildNavPageWithPageTracking();
              this.uiService.showCancelDoneOnActivityDetails = true;
              this.navService.pushChildNavPageWithPageTracking(PhoneCallDetailsComponent,
                PageName.PhoneCallDetailsComponent, PageName.AccountManagementPageComponent, { from: 'AccountManagementTool', account: this.layoverViewPassedData.accounts, isNewlyCreatedActivity: true });
              return;
            } else if (this.layoverViewPushedFrom == 'AccountTool') {
              this.navService.popChildNavPageWithPageTracking();
              this.uiService.showCancelDoneOnActivityDetails = true;
              this.navService.pushChildNavPageWithPageTracking(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTool', account: this.layoverViewPassedData, isNewlyCreatedActivity: true });
            } else if (this.layoverViewPushedFrom == 'ContactPageComponent') {
              this.navService.popChildNavPageWithPageTracking();
              this.uiService.showCancelDoneOnActivityDetails = true;
              this.navService.pushChildNavPageWithPageTracking(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.ContactPageComponent, { from: 'ContactPageComponent', isNewlyCreatedActivity: true });
            }
          } catch (error) {
            console.log(error);
          }
        }
        this.activityOfflineService.selectedActivityAtHome = this.activityOfflineService.selectedActivity;
        break;
      case 'opportunity':
        this._handleNewOpportunity();
        return;
      case 'survey':
        if (this.activityOfflineService.activitySource === ActivitySource.MEETING_DETAIL ||
          this.activityOfflineService.activitySource === ActivitySource.MEETING_PRESENTATION) {
          this._openSurvey();
        } else {

        }
        return;
      case 'storecheck':
        this.handleNewStoreCheck();
        break;
      case 'kit_booking':
        this.handleNewKitBooking();
        break;
      case 'quotes':
        this.handleNewQuotes();
        break;

      default:
        console.error(
          'Unhandled switch case for activeViewNewMeeting:',
          this.uiService.activeViewNewMeeting
        );
    }

    if (this.NewActivitySource === ActivitySource.MEETING_DETAIL) {
      const currentPage: PageName = this.navService.getCurrentPageName();
      if (currentPage == PageName.CallPlanComponent) {
        this.callPlanService.callPlanPageView = 'CallPlanNewMeetingDetails';
        this.footerService.initButtons(FooterViews.Activities);
      }
    }

    if (this.uiService.activeViewNewMeeting == 'allocation') {
      return
    }
    if (this.uiService.activeViewNewMeeting != 'email') {
      this.emailService.selectedActivity = null;
      this.emailService.setCurrentEmail(null);
    }

    this.uiService.setCellData = null;
    const curPage = this.navService.getCurrentPageName();
    if (curPage === PageName.NewActivityComponent) {
      //meeting request
      if (!this.confirmTimeoff) {
        this.activityOfflineService.isNewActivityCreated = true;
        this.uiService.activeView = 'Appointment';
        // For now, we assume that default behaviour after creating a new activity from a
        // pushed new-activity page is to go to the activities detail page on activities master page
        if (this.currentMasterPage !== PageName.ActivitiesPageComponent) {
          // We want to show 'agenda' tab by default when we come from other pages
          this.uiService.activitiesPageTab = 'agenda';
        }
        this.navService.popToRootWithPageTracking();
      }
    }
    else if (curPage === PageName.ContactPageComponent && this.uiService.activeViewNewMeeting != 'customerinquiry') {
      /* != 'customerinquiry as we have already handled the logic in the above switch statement */
      if (this.uiService.activeViewNewMeeting == 'email' && this.NewActivitySource !== ActivitySource.MEETING_DETAIL) {
        this.uiService.activeView = 'Email';
      }
      else if (this.uiService.activeViewNewMeeting == 'phonecall'
        && this.NewActivitySource === ActivitySource.CONTACT_INFO) {
        this.uiService.activeView = 'phoneCallTimelineDetails'
      }
    }
    else if (curPage === PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab != 'plans') {
      if (this.activityOfflineService.selectedActivity instanceof EmailActivity) {
        this.uiService.activeView = 'Email';
        return;
      } else if (
        this.uiService.activeViewNewMeeting == 'meeting'
        || this.uiService.activeViewNewMeeting === 'accountVisit'
      ) {
        this.activityOfflineService.isNewActivityCreated = true;
      }
    }

    // Some activity publishes activity creation event within their logic.
    // Might need to filter those features as we find..
    if (this.uiService.activeViewNewMeeting !== 'timeoff') {
      this.activityOfflineService.publishActivityEvent({ action: "Create", activity: this.activityOfflineService.selectedActivity });
    }

    this.handleNewActivityCreatedUpdatePlanTab(this.activityOfflineService.selectedActivity);
  }

  private async handleNewStoreCheck() {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('STORE_CHECK_OFFLINE'), 'Store Check Activity', 'top', ToastStyle.DANGER);
      return;
    }
    if (this.isLayoverPushView) {
      await this.navService.popChildNavPageWithPageTracking();
    }
    await this.uiService.displayLoader();
    await this.createStoreCheck();
    await this.uiService.dismissLoader();
  }

  private async createStoreCheck() {
    const payload = {
      scheduledend: new Date(this.endTime).getTime(),
      scheduledstart: new Date(this.startTime).getTime(),
      offlineActivityId: 'offline_store_check_' + new Date().getTime(),
      subject: this.translate.instant("STORE_CHECK"),
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.PROMO_CHECK)) {
      payload['subject'] = this.translate.instant('STORE_PROMO_CHECK');
    }
    if (this.layoverViewPushedFrom == 'AccountTool') {
      payload['accounts'] = [{
        indskr_accountid: this.layoverViewPassedData.id,
        accountName: this.layoverViewPassedData.accountName,
        accountId: this.layoverViewPassedData.id
      }];
      payload['subject'] = payload['subject'] + ' - ' + this.layoverViewPassedData.accountName;
    }
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('STORE_CHECK_OFFLINE'), 'Store Check Activity', 'top', ToastStyle.DANGER);
      return;
    }
    const defaultCategories = this.storeCheckService.productCategories?.filter(pc => pc.indskr_default);
    if (!_.isEmpty(defaultCategories)) {
      const defaultCategory = defaultCategories[0];
      payload['categoryId'] = defaultCategory.indskr_productfamilycategoryid;
      payload['categoryName'] = defaultCategory.indskr_name;
      if (this.authService.hasFeatureAction(FeatureActionsMap.PROMO_CHECK)) {
        payload['subject'] += ` - ${defaultCategory.indskr_name}`;
      }
    }
    const activity = await this.storeCheckService.createStoreCheck(payload);
    if (activity) {
      if (!this.uiService.toolsActivityActive) {
        this.events.publish("refreshAgenda");
      }
      else {
        this.uiService.agendaRefreshRequired = true;
      }
      this.activityOfflineService.selectedActivityAtHome = this.activityOfflineService.selectedActivity = activity;
      this.activityOfflineService.publishActivityEvent({ action: "Create", activity: this.activityOfflineService.selectedActivity });
      if (this.navService.getActiveChildNavName() === ChildNavNames.AccountPageNavigation) {
        this.navService.pushChildNavPageWithPageTracking(StoreCheckDetailsComponent, PageName.StoreCheckDetailsComponent, PageName.AccountPageComponent);
      } else {
        this.navService.setChildNavRoot(StoreCheckDetailsComponent, PageName.StoreCheckDetailsComponent, PageName.ActivitiesPageComponent);
      }
    } else {
      this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_ERROR_CREATING_NEW_STORE_CHECK'), 'New Activity', 'top', ToastStyle.DANGER);
    }
  }

  private handleNewActivityCreatedUpdatePlanTab(activity: any) {
    // Note: for surgery order have to call it from the place where it is created as there is a 'return' in the case handling.
    if (this.agendaFooterService.actSegment === 'agenda' && this.agendaFooterService.planTabSegment === 'planTabTodo') {
      // We only have to update if the activity is older then today midnight.
      if(activity){
        if (isPast(activity.scheduledStart) && this.authenticationOfflineService.user.offlineDataDuration && this.authenticationOfflineService.user.offlineDataDuration > 0) {
          let dateAdjustedWithOfflineDataDuration = new Date(new Date((new Date().setDate(new Date().getDate() - this.authenticationOfflineService.user.offlineDataDuration))).setHours(0, 0, 0, 0));
          let todayStart = new Date(new Date().setHours(0, 0, 0, 0));
          if (isWithinRange(activity.scheduledStart, dateAdjustedWithOfflineDataDuration, todayStart)) {
            this.events.publish('newActivityIsCreatedUpdatePlanTab', activity);
          }
        }
      }
      }
  }

  async createOrEditSampleOrderFromMeeting() {
    let loader = await this.loadingController.create({})
    loader.present();
    let activity: SampleActivity = await this.sharedDataService.createInMeetingAllocationOrder();
    loader.dismiss();
    if (activity) {
      this.samplingOfflineService.samplingDetailsViewMode = SamplingDetailsViewMode.CREATE_FROM_MEETING;
      this.samplingOfflineService.inMeetingAllocationActivity = activity;
      this.activityService.activityDetailsLoaded = true;
      if (!this.uiService.toolsActivityActive) {
        this.events.publish('refreshAgenda');
      }
      else this.uiService.agendaRefreshRequired = true;
      this.uiService.showNewActivity = false;
      this.activityOfflineService.samplingToInMeetingActivityMapping.set(this.activityOfflineService.selectedActivity.ID, activity.ID)
      this.meetingStructureService.createInMeetingEmbededActivity(activity);
      this.meetingStructureService.openInMeetingActivitiesModal(activity, true);
    }
  }

  async createOrEditSampleOrderFromEvent() {
    let loader = await this.loadingController.create({})
    loader.present();
    let activity: SampleActivity = await this.sharedDataService.createEventAllocationOrder();
    loader.dismiss();
    if (activity) {
      this.samplingOfflineService.samplingDetailsViewMode = SamplingDetailsViewMode.CREATE_FROM_EVENTS;
      this.samplingOfflineService.inMeetingAllocationActivity = activity;
      this.activityService.activityDetailsLoaded = true;
      if (!this.uiService.toolsActivityActive) {
        this.events.publish('refreshAgenda');
      }
      else this.uiService.agendaRefreshRequired = true;
      this.uiService.showNewActivity = false;
      this.activityOfflineService.samplingToInMeetingActivityMapping.set(this.activityOfflineService.selectedActivity.ID, activity.ID)
      this.meetingStructureService.createInMeetingEmbededActivity(activity);
      this.meetingStructureService.openInMeetingActivitiesModal(activity, true);
    }
  }


  private async createEmailInMeeting() {
    try {
      this.trackingService.tracking('EmailActivityFromMeeting', TrackingEventNames.EMAIL, true);

      let activity: EmailActivity = await this.sharedDataService.createInMeetingEmail(this.selectedMessagingChannelType);
      if (activity) {
        this.meetingStructureService.createInMeetingEmbededActivity(activity);
        let data = {
          id: activity.ID, subject: activity.subject, info: activity.info, type: activity.type,
          scheduledStart: activity.scheduledStart, scheduledEnd: activity.scheduledEnd,
          messageType: EmailTemplateType.Resource,
        };
        this.activityOfflineService.publishActivityEvent({ action: 'Create', activity });
        if (!this.uiService.toolsActivityActive) {
          this.events.publish('refreshAgenda');
        } else this.uiService.agendaRefreshRequired = true;
        this.createEmailModal(this.emailService.selectedActivity);
      }
    } catch (ex) {
      console.log('Error creating email in meeting : ' + ex);
    }
  }

  private async createEmailInPhoneCallActivity() {
    try {
      this.trackingService.tracking('SelectConfirm', TrackingEventNames.PHONECALL, true);

      let activity: EmailActivity = await this.sharedDataService.
        createInMeetingEmail(this.selectedMessagingChannelType);
      if (activity) {
        this.phoneCallMeetingStructureService.createInPhoneCallMeetingEmbededActivity(activity);
        let data = {
          id: activity.ID, subject: activity.subject, info: activity.info, type: activity.type,
          scheduledStart: activity.scheduledStart, scheduledEnd: activity.scheduledEnd,
          messageType: EmailTemplateType.Resource,
        };
        this.activityOfflineService.publishActivityEvent({ action: 'Create', activity });
        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
        this.createEmailModal(this.emailService.selectedActivity);
      }
    } catch (ex) {
      console.log('Error creating email in meeting : ' + ex);
    }
  }


  private createEmailModal(activity: EmailActivity) {
    this.uiService.showNewActivity = false;
    this.emailService.setCurrentEmail(activity);
    this.emailService.viewType = EmailViewType.CREATE_FROM_MEETING;
    if (this.NewActivitySource == ActivitySource.PHONE_CALL_ACTIVITY_DETAIL) {
      this.emailService.viewType = EmailViewType.CREATE_FROM_PHONE_CALL
      this.phoneCallMeetingStructureService.openInPhoneCallMeetingActivitiesModal(activity, true)
      return
    }
    this.meetingStructureService.openInMeetingActivitiesModal(activity, true);
  }

  public toggle(selected, event) {
    if (event.checked) {
      this.selectedType = selected.id;
      this.type = selected;
    }
    else {
      if (selected.id == this.selectedType) event.checked = true;
    }
  }

  private getPlanType() {
    switch (this.followUpActionCategory) {
      case 'accountplantask': return FOLLOW_UP_TYPE.ACCOUNT_PLAN;
      case 'none': return FOLLOW_UP_TYPE.NONE;
      case 'meetingtask': return FOLLOW_UP_TYPE.MEETINGTASK;
      case 'opportunitytask': return FOLLOW_UP_TYPE.OPPORTUNITY_TASK;
      case 'marketingplantask': return FOLLOW_UP_TYPE.MARKETING_BUSINESS_PLAN;
      case 'eventtask': return FOLLOW_UP_TYPE.EVENT_TASK;
      default: return null;
    }
  }

  private filterDeletedOrCanceledParticipants(participants: EventParticipant[]): EventParticipant[] {
    let filtered = [];
    if (Array.isArray(participants)) {
      filtered = participants.filter(p => !(
        p.isDeleted || p.indskr_reasons === EventRegistrationStatus.Cancelled
      ));
    }

    return filtered;
  }

  private async handleNewFollowUpActionTaskCreation(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let errorOccured: boolean = false;
      let currentTime = new Date().getTime();
      let start = format(new Date().setHours(0, 0, 0, 0));

      if (this.agendaFooterService.actSegment === 'week' && this.uiService.selectedCalendarDateInWeekView && this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent) {
        start = format(this.uiService.selectedCalendarDateInWeekView.setHours(0, 0, 0, 0));
      }
      let payload = {
        activityId: 'offline_followup_' + currentTime,
        activitytypecode: 'followuptask',
        subject: 'Follow-up Action',
        offlineId: 'offline_followup_' + currentTime,
        scheduledstart: new Date(start).getTime(),
        //scheduledend: new Date(start).getTime(),
        indskr_plantype: this.getPlanType()//this.followUpActionCategory == 'accountplantask' ? FOLLOW_UP_TYPE.ACCOUNT_PLAN : this.followUpActionCategory == 'none' ? FOLLOW_UP_TYPE.NONE : this.followUpActionCategory == 'meetingtask' ? FOLLOW_UP_TYPE.MEETINGTASK : FOLLOW_UP_TYPE.OPPORTUNITY_TASK,
      };
      if (this.isLayoverPushView) {
        if (this.layoverViewPushedFrom == 'AccountManagementTool') {
          this.trackingService.tracking('AccountPlanFollowupAction', TrackingEventNames.ACCOUNTPLANNING)
          if (this.layoverViewPassedData.accounts) {
            if (this.layoverViewPassedData && this.layoverViewPassedData.accounts.length == 1) {
              payload['indskr_account'] = this.layoverViewPassedData.accounts[0].id;
              payload['accountName'] = this.layoverViewPassedData.accounts[0].name;
              payload['indskr_accountplan'] = this.layoverViewPassedData.ID;
              payload['accountplanName'] = this.layoverViewPassedData.accountPlanName;
            }
          }
        }
        if (this.layoverViewPushedFrom == 'AccountTool') {
          this.trackingService.tracking('AccountsFollowupAction', TrackingEventNames.ACCOUNTPLANNING)
          if (this.layoverViewPassedData) {
            payload['indskr_account'] = this.layoverViewPassedData.id;
            payload['accountName'] = this.layoverViewPassedData.accountName;
          }
        }
        if (this.layoverViewPushedFrom == 'OpportunityManagementTool'
          || this.layoverViewPushedFrom == 'AccountProfileOpportunities'
          || this.from == 'AccountPlanOpportunities'
          || this.from == 'MarketingPlanInfoopportunities') {
          if (this.layoverViewPassedData) {
            payload['indskr_account'] = this.layoverViewPassedData.accountID;
            payload['accountName'] = this.layoverViewPassedData.accountName;
            payload['indskr_accountplan'] = this.layoverViewPassedData.accountPlanID;
            payload['accountplanName'] = this.layoverViewPassedData.accountPlanName;
            payload['opportunityid'] = this.layoverViewPassedData.ID;
            payload['opportunityName'] = this.layoverViewPassedData.opportunityName;
          }
        }
        if(this.layoverViewPushedFrom == 'ActivitiesDetailsPane'){
          let parentMeetingActivity = (this.activityOfflineService.selectedActivity as AppointmentActivity);
          if(parentMeetingActivity.contacts.length==1){
            payload['indskr_contact'] = parentMeetingActivity.contacts[0].ID;
            payload['contactName'] = parentMeetingActivity.contacts[0].fullName;
          }
          if(parentMeetingActivity.accounts.length==1){
            payload['indskr_account'] = parentMeetingActivity.accounts[0].id;
            payload['accountName'] = parentMeetingActivity.accounts[0].accountName;
          }
          if(parentMeetingActivity.products.length>0){
            payload['products'] = [];
            parentMeetingActivity.products.filter(o=>o.isSelected).forEach(p=>{
              payload['products'].push(
                {
                  productId : p.ID,
                  productName: p.name,
                }
              )
            })
          }
        }
        if (this.layoverViewPushedFrom === 'EventDetailsPane') {
          const currentEvent = (this.eventsToolService.selectedEventOnEventsTool as EventActivity);
          if (!currentEvent) {
            throw new Error(`Current event is not found!`);
          }
          payload['indskr_event'] = currentEvent.ID || currentEvent.offlineId;
          payload['indskr_eventname'] = currentEvent.name;

          const filteredParticipants = this.filterDeletedOrCanceledParticipants(currentEvent.participants);
          if (filteredParticipants.length === 1) {
            payload['indskr_contact'] = filteredParticipants[0].id;
            payload['contactName'] = filteredParticipants[0].name;
          }
          if (currentEvent.accounts.length === 1) {
            payload['indskr_account'] = currentEvent.accounts[0].id;
            payload['accountName'] = currentEvent.accounts[0].name;
          }
          if (currentEvent.products.length > 0) {
            payload['products'] = [];
            currentEvent.products.forEach(p =>
              payload['products'].push(
                {
                  productId : p.id,
                  productName: p.name,
                }
              )
            );
          }
        }
      }
      if ((this.currentMasterPage == PageName.ContactPageComponent ||
        (this.currentMasterPage == PageName.ActivitiesPageComponent && !this.footerService.isActivitiesButton))
        && this.contactService.contactInformation) {
        this.trackingService.tracking('ContatcsFollowupAction', TrackingEventNames.ACCOUNTPLANNING)
        payload['indskr_contact'] = this.contactService.contactInformation.ID;
        payload['contactName'] = this.contactService.contactInformation.fullName;
      }
      else {
        this.trackingService.tracking('AgendaFollowupAction', TrackingEventNames.ACCOUNTPLANNING);
      }
      let newFollowUp = new FollowUpActivity(payload);
      newFollowUp.state = 0;
      newFollowUp.statusString = 'Open';
      newFollowUp.ownerId = this.authenticationOfflineService.user.systemUserID;
      newFollowUp.ownerNameString = this.authenticationOfflineService.user.displayName;
      newFollowUp.pendingPushToDynamics = true;
      if(this.followUpActionCategory == 'meetingtask' && this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity instanceof AppointmentActivity){
        if (!this.activityOfflineService.selectedActivity.ID.includes('offline')) {
          newFollowUp.appointmentId = this.activityOfflineService.selectedActivity.ID;
        }
        newFollowUp.offlineMeetingId = (this.activityOfflineService.selectedActivity as AppointmentActivity).offlineMeetingId;
        newFollowUp.appointmentSubject = this.activityOfflineService.selectedActivity.subject;
        if((this.activityOfflineService.selectedActivity as AppointmentActivity).products){
          newFollowUp.products = [];
          (this.activityOfflineService.selectedActivity as AppointmentActivity).products.forEach(selPro => {
            if(selPro.isSelected || selPro.isAutoSelected){
              let foundProduct = this.brandService.getBrandByID(selPro.ID);
              if(foundProduct){
                newFollowUp.products.push({ productID: foundProduct.ID, productName: foundProduct.name });
              }
            }
          });
        }
      }
      await this.followUpActivityDataService.createFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalCopy: true, onLocalDatabase: true }, [newFollowUp], currentTime, false).then(succ => {
        //Successfully created new follow up activity
        if (
          this.activityOfflineService.activitySource !== ActivitySource.MEETING_DETAIL
          && this.NewActivitySource !== ActivitySource.MEETING_PRESENTATION
          && this.activityOfflineService.activitySource !== ActivitySource.EVENT_DETAIL
        ) {
          this.activityOfflineService.selectedActivity = newFollowUp;
        } else {
          this.followUpActivityDataService.inMeetingFollowupActionActivity = newFollowUp;
        }
      }).catch(err => {
        // Handle any error occured during follow up activity creation
        reject('error occured while creating new follow up');
        errorOccured = true;
      });
      if (this.activityOfflineService.activitySource === ActivitySource.MEETING_DETAIL || this.NewActivitySource === ActivitySource.MEETING_PRESENTATION) {
        if (this.uiService.activitiesPageTab == 'day') {
          let data = {
            id: newFollowUp.ID,
            subject: newFollowUp.subject,
            location: newFollowUp.location,
            scheduledStart: newFollowUp.scheduledStart,
            scheduledEnd: newFollowUp.scheduledEnd,
            type: newFollowUp.type,
          };
          this.uiService.setUIServiceData({
            view: 'new-activity', data,
          });
        }
        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
        resolve('');
        return;
      }
      if (!errorOccured) {
        let from: string = 'AgendaView';
        let pageName: PageName = PageName.ActivitiesPageComponent;
        if (this.isLayoverPushView) {
          this.navService.popChildNavPageWithPageTracking();
          if (this.layoverViewPushedFrom == 'AccountManagementTool') {
            from = 'AccountManagementTool';
            pageName = PageName.AccountManagementPageComponent;
            this.navService.pushChildNavPageWithPageTracking(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent, PageName.AccountManagementPageComponent, { from: from, activity: newFollowUp });
          }
          else if (this.currentMasterPage == PageName.AccountPageComponent && this.layoverViewPushedFrom == 'AccountTool') {
            from = 'AccountTool';
            pageName = PageName.AccountPageComponent;
            this.uiService.showNewActivity = false;
            this.navService.pushChildNavPageWithPageTracking(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent, PageName.AccountPageComponent, { from: from, activity: newFollowUp });
          }
          else if (this.layoverViewPushedFrom == 'OpportunityManagementTool'
            || this.layoverViewPushedFrom == 'AccountProfileOpportunities'
            || this.from == 'AccountPlanOpportunities'
            || this.from == 'MarketingPlanInfoopportunities') {
            from = this.layoverViewPushedFrom;
            pageName = this.layoverViewPushedFrom == 'OpportunityManagementTool' ? PageName.OpportunityManagementPage
              : this.layoverViewPushedFrom == 'AccountProfileOpportunities' ? PageName.AccountPageComponent : this.layoverViewPushedFrom == 'MarketingPlanInfoopportunities' ? PageName.MarketingPlanManagementPageComponent :  PageName.AccountManagementPageComponent;
            this.navService.pushChildNavPageWithPageTracking(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent, pageName, { from: from, activity: newFollowUp });
          } else if (this.layoverViewPushedFrom === 'ContactPageComponent') {
            from = 'ContactTool';
            pageName = PageName.ContactPageComponent;
            this.navService.pushChildNavPageWithPageTracking(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent, PageName.ContactPageComponent, { from: from, activity: newFollowUp });
          }
        } else { // Assume follow up actiion is being created from agenda
          if (this.footerService.isActivitiesButton) {
            this.navService.setChildNavRoot(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent, PageName.ActivitiesPageComponent, { from: from, activity: newFollowUp });
          } else {
            this.navService.pushChildNavPageWithPageTracking(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent, PageName.ActivitiesPageComponent, { from: from, activity: newFollowUp });
          }
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.uiService.showNewActivity = false;
          this.uiService.showRightPane = true;
          if (this.uiService.activitiesPageTab == 'day') {
            let data = {
              id: newFollowUp.ID,
              subject: newFollowUp.subject,
              location: newFollowUp.location,
              scheduledStart: newFollowUp.scheduledStart,
              scheduledEnd: newFollowUp.scheduledEnd,
              type: newFollowUp.type,
            };
            this.uiService.setUIServiceData({
              view: 'new-activity', data,
            });
          }
        }
        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
        resolve('');
      }
    });
  }


  private async handleNewQuoteCreation() {
    try {

      let newQuote = await this.injector.get(QuoteDataService)
        .generateQuoteFromOpportunity(this.layoverViewPassedData.ID);


      if (this.isLayoverPushView) {
        if (this.currentMasterPage === PageName.OpportunityManagementPage && this.layoverViewPushedFrom == 'OpportunityManagementTool') {
          const from = 'OpportunityManagementTool';
          const pageName: PageName = PageName.ActivitiesPageComponent;
          this.uiService.showNewActivity = false;
          this.navService.popChildNavPageWithPageTracking();
          this.navService.pushChildNavPageWithPageTracking(OpportunityQuoteDetailsComponent, PageName.OpportunityQuoteDetailsComponent, pageName, { from: from, quote: newQuote });
        }else if (this.currentMasterPage === PageName.MarketingPlanManagementPageComponent && this.layoverViewPushedFrom == 'MarketingPlanInfoopportunities') {
          const from = 'MarketingPlanOpportunities';
          const pageName: PageName = PageName.MarketingPlanManagementPageComponent;
          this.uiService.showNewActivity = false;
          this.navService.popChildNavPageWithPageTracking();
          this.navService.pushChildNavPageWithPageTracking(OpportunityQuoteDetailsComponent, PageName.OpportunityQuoteDetailsComponent, pageName, { from: from, quote: newQuote });
        }
      }
    }
    catch (ex) {
      throw 'Error occured while creating a new quote';
    }
  }
  private async handleNewConsentCreation() {
    try {
      this.navService.popChildNavPageWithPageTracking();
      this.navService.pushChildNavPageWithPageTracking(ContactConsentCaptureComponent, PageName.ContactConsentCaptureComponent, PageName.PresentationMeetingComponent, { from: 'InMeetingConsent' });
      //let contact = (this.activityOfflineService.selectedActivity as AppointmentActivity).contacts[0]
      //if(contact) this.contactService.contactInformation = contact
    }
    catch (ex) {
      throw 'Error occured while creating a new quote';
    }
  }

  private async handleNewOrderCreation(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let errorOccured: boolean = false;
      let currentTime = new Date().getTime();
      let start, end;
      start = format(Utility.getRoundedDownTime(new Date()));
      end = format(addMinutes(start, 30));
      let payload = {
        salesorderid: 'offline_order_' + currentTime,
        indskr_externalid: 'offline_order_' + currentTime,
        indskr_ordertype: 548910000,
        ordernumber: 'New Order',
        subject: 'New Order',
        name: 'New Order',
        pendingPushToDynamics: true,
        statuscode: 1,
        statecode: 0,
        activitytypecode: 'indskr_order',
        createdon: new Date(currentTime).getTime(),
        scheduledstart: new Date(start).getTime(),
        scheduledend: new Date(end).getTime(),
      }

      if (this.layoverViewPushedFrom == 'AccountTool') {
        if (this.layoverViewPassedData) {
          payload['customerid'] = this.layoverViewPassedData.id;
          payload['customerName'] = this.layoverViewPassedData.accountName;
        }
      }

      if (this.layoverViewPushedFrom == 'OpportunityManagementTool'
        || this.layoverViewPushedFrom == 'AccountProfileOpportunities'
        || this.from == 'AccountPlanOpportunities'
        || this.from == 'MarketingPlanInfoopportunities') {
        if (this.layoverViewPassedData) {
          payload['indskr_country'] = this.layoverViewPassedData.countryId || '';
          payload['countryName'] = this.layoverViewPassedData.countryName || '';
          payload['customerid'] = this.layoverViewPassedData.accountID || '';
          payload['customerName'] = this.layoverViewPassedData.accountName || '';
          payload['opportunityid'] = this.layoverViewPassedData.ID;
          payload['opportunityName'] = this.layoverViewPassedData.opportunityName;
          payload['accountplanid'] = this.layoverViewPassedData.accountPlanID;
          payload['accountplanName'] = this.layoverViewPassedData.accountPlanName;
        }
      }

      let newOrder = new OrderActivity(payload);
      if (this.layoverViewPushedFrom == 'OpportunityManagementTool') {
        const selectedOpportunityType = this.opportunityService.opportunityTypes.find(ot => ot.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
        const opp: Opportunity = this.opportunity;
        if (this.opportunity && !_.isEmpty(opp.products) && selectedOpportunityType && selectedOpportunityType.indskr_ordercreationprocess === OrderCreationType.MANUAL) {
          const products = opp.products.filter(pr => !pr.isDeleted);
          if (!_.isEmpty(products)) {
            products.forEach(pr => {
              newOrder.products.push({
                productId: pr.productID,
                productName: pr.productName,
                quantity: 1,
                uomid: pr.uomid,
                isDeleted: false,
                unitPrice: 0,
                formattedUnitPrice: "0$",
                total: 1,
                formattedTotal: "1",
              });
            });
          }
        }
      }
      newOrder.statusString = 'Open';
      newOrder.ownerId = this.authenticationOfflineService.user.systemUserID;
      newOrder.ownerNameString = this.authenticationOfflineService.user.displayName;

      await this.orderActivityDataService.createOrderActivity({ onDynamics: !this.device.isOffline, onLocalCopy: true, onLocalDatabase: true }, [newOrder], currentTime, false).then(succ => {
        //Successfully created new order activity
        this.activityOfflineService.selectedActivity = newOrder;

      }).catch(err => {
        // Handle any error occured during order activity creation
        reject('error occured while creating new order');
        errorOccured = true;
      });
      if (!errorOccured) {
        let from: string = 'AgendaView';
        let pageName: PageName = PageName.ActivitiesPageComponent;
        if (this.isLayoverPushView) {
          // Handle cases if any
          if (this.currentMasterPage == PageName.AccountPageComponent && this.layoverViewPushedFrom == 'AccountTool') {
            from = 'AccountTool';
            pageName = PageName.AccountPageComponent;
            this.uiService.showNewActivity = false;
            this.trackingService.tracking('AccountNewOrder', TrackingEventNames.ORDERMANAGEMENT);
            this.navService.popChildNavPageWithPageTracking();
            this.navService.pushChildNavPageWithPageTracking(OrderDetailsComponent, PageName.OrderDetailsComponent, pageName, { from: from, activity: newOrder });
          }
          if (this.currentMasterPage == PageName.OpportunityManagementPage && this.layoverViewPushedFrom == 'OpportunityManagementTool') {
            from = 'OpportunityManagementTool';
            pageName = PageName.OpportunityManagementPage;
            this.uiService.showNewActivity = false;
            this.uiService.isFromOpportunites = true;
            this.navService.popChildNavPageWithPageTracking();
            this.navService.pushChildNavPageWithPageTracking(OrderDetailsComponent, PageName.OrderDetailsComponent, pageName, { from: from, activity: newOrder });
          }
          if (this.currentMasterPage == PageName.AccountPageComponent && this.layoverViewPushedFrom == 'AccountProfileOpportunities') {
            from = 'AccountProfileOpportunities';
            pageName = PageName.AccountPageComponent;
            this.uiService.showNewActivity = false;
            this.navService.popChildNavPageWithPageTracking();
            this.navService.pushChildNavPageWithPageTracking(OrderDetailsComponent, PageName.OrderDetailsComponent, pageName, { from: from, activity: newOrder });
          }
          if (this.currentMasterPage == PageName.AccountManagementPageComponent && this.layoverViewPushedFrom == 'AccountPlanOpportunities') {
            from = 'AccountPlanOpportunities';
            pageName = PageName.AccountManagementPageComponent;
            this.uiService.showNewActivity = false;
            this.navService.popChildNavPageWithPageTracking();
            this.navService.pushChildNavPageWithPageTracking(OrderDetailsComponent, PageName.OrderDetailsComponent, pageName, { from: from, activity: newOrder });
          }
          if (this.currentMasterPage == PageName.ActivitiesPageComponent && this.layoverViewPushedFrom == 'AccountPlanOpportunities' && this.uiService.activitiesPagePlanTabSelectedPlan === 'accountPlansTab') {
            from = 'AccountPlanOpportunities';
            pageName = PageName.AccountManagementPageComponent;
            this.uiService.showNewActivity = false;
            this.uiService.activeView = 'ActivitiesPageRightPaneNav';
            this.navService.popChildNavPageWithPageTracking();
            this.navService.pushChildNavPageWithPageTracking(OrderDetailsComponent, PageName.OrderDetailsComponent, pageName, { from: from, activity: newOrder });
          }
          if (this.currentMasterPage == PageName.MarketingPlanManagementPageComponent && this.layoverViewPushedFrom == 'MarketingPlanInfoopportunities') {
            from = 'MarketingPlanOpportunities';
            pageName = PageName.MarketingPlanManagementPageComponent;
            this.uiService.showNewActivity = false;
            this.navService.popChildNavPageWithPageTracking();
            this.navService.pushChildNavPageWithPageTracking(OrderDetailsComponent, PageName.OrderDetailsComponent, pageName, { from: from, activity: newOrder });
          }
        } else {
          this.trackingService.tracking('AgendaNewOrder', TrackingEventNames.ORDERMANAGEMENT);
          this.navService.setChildNavRoot(OrderDetailsComponent, PageName.OrderDetailsComponent, PageName.ActivitiesPageComponent, { from: from, activity: newOrder });
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.uiService.showNewActivity = false;
          this.uiService.showRightPane = true;
          if (this.uiService.activitiesPageTab == 'day') {
            let data = {
              id: newOrder.ID,
              subject: newOrder.subject,
              location: (newOrder.accountId) ? newOrder.accountNameString : 'No Account',
              scheduledStart: newOrder.scheduledStart,
              scheduledEnd: newOrder.scheduledEnd,
              type: newOrder.type,
            };
            this.uiService.setUIServiceData({
              view: 'new-activity', data,
            });
          }
        }
        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
        if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
        resolve('')
      } else {
        ;
        reject('Error occured while creating a new order');
      }
    });
  }

  private async handleNewSurgeryOrderCreation(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let errorOccured: boolean = false;
      let currentTime = new Date().getTime();
      let start, end;
      let isWeeklyFlag = ('Weekly' === this.authenticationOfflineService.user.procedureLogDuration);
      let autoSetOwnerAsCoowner = this.authenticationOfflineService.user.buSettings && this.authenticationOfflineService.user.buSettings['indskr_setownerascoowner'];

      if (!isWeeklyFlag && !this.activityOfflineService.validateProcedureDate(this.startTime, 'DATETIME_P_CANT_SHEDULE_PROCEDURE_LOG_DAYS_AGO')) {
        reject('you cannot schedule a procedure log for more than the past activity grace period.');
        return;
      }
      if (this.agendaFooterService.actSegment === 'week') {
        if (isFuture(this.startTime) && !isToday(this.startTime) && this.procedureCategory == 'noProcedureDay') {
          this.notificationService.notify(this.translate.instant('SHEDULE_PROCEDURE_LOG_FUTURE'), 'New Activity', 'top', ToastStyle.DANGER);
          reject('no procedure day should be created in today and in past');
          return;
        }
        start = format(Utility.getRoundedDownTime(new Date(this.startTime)));
      } else {
        start = format(Utility.getRoundedDownTime(new Date()));
      }
      end = format(addMinutes(start, 30));
      let startOfWeek, endOfWeek;
      if (isWeeklyFlag) {
        startOfWeek = moment(start).startOf('week').toDate();
        endOfWeek = moment(start).endOf('week').toDate();
        const firstDay = moment(start).startOf('week').format('MMM DD, YYYY');
        const lastDay = moment(start).endOf('week').format('MMM DD, YYYY');
        this.notificationService.notify(this.translate.instant('PROCEDURE_LOG_FOR_WEEK', { startDate: firstDay, endDate: lastDay }), 'New Activity', 'top', ToastStyle.INFO);
      }




      let payload: any = {
        salesorderid: 'offline_surgeryorder_' + currentTime,
        indskr_externalid: 'offline_surgeryorder_' + currentTime,
        indskr_ordertype: 548910001,
        ordernumber: 'Surgery Order',
        name: 'Procedure Log',
        indskr_noprocedureday: (this.procedureCategory === 'noProcedureDay') ? true : false,
        indskr_ctmergednew: false,
        pendingPushToDynamics: true,
        statuscode: 1,
        statecode: 0,
        activitytypecode: 'SurgeryOrder',
        createdon: new Date(currentTime).getTime(),
        modifiedByName: this.authService.user.displayName,
        modifiedon: new Date().getTime() + '',
        scheduledstart: (isWeeklyFlag) ? startOfWeek : new Date(start).getTime(),
        scheduledend: (isWeeklyFlag) ? endOfWeek : new Date(end).getTime(),
      }

      if(autoSetOwnerAsCoowner){
        let coOwners = [{
          userId: this.authenticationOfflineService.user.xSystemUserID,
          userFullName: this.authenticationOfflineService.user.displayName,
          indskr_procedurelogcoownerid: Guid.create().toString()
        }]

        payload = { ...payload, coOwners }
      }

      if (this.currentMasterPage == PageName.ContactPageComponent && this.procedureCategory === 'procedureDay' && this.contactService.contactInformation
        && !(this.NewActivitySource === ActivitySource.MEETING_PRESENTATION || this.activityOfflineService.activitySource == ActivitySource.MEETING_DETAIL)) {
        let contact = [
          {
            "id": this.contactService.contactInformation.ID,
            "firstName": this.contactService.contactInformation.firstName,
            "title": this.contactService.contactInformation.title,
            "middleName": this.contactService.contactInformation.middleName,
            "lastName": this.contactService.contactInformation.lastName,
            "statusCode": this.contactService.contactInformation.statusCode
          }]
        payload['contactorders'] = contact;
        payload['name'] = 'Procedure Log - ' + this.contactService.contactInformation.fullName;

       // adding primary affiliated account information
        const primaryaccount = await this.accountService.findPrimaryAccountOfContact(this.contactService.contactInformation.ID);
        if(primaryaccount){
          payload['customerid'] = primaryaccount.accountid;
          payload['customerName'] = primaryaccount["indskr_accountcontactaffiliation.indskr_accountid_Formatted"];
        }
      }
      if (this.currentMasterPage === PageName.AccountPageComponent && this.procedureCategory === 'procedureDay' && this.layoverViewPassedData
        && !(this.NewActivitySource === ActivitySource.MEETING_PRESENTATION || this.activityOfflineService.activitySource == ActivitySource.MEETING_DETAIL)) {
        payload['customerid'] = this.layoverViewPassedData.id;
        payload['customerName'] = this.layoverViewPassedData.accountName;
      }

      if (this.layoverViewPushedFrom == 'OpportunityManagementTool'
        || this.layoverViewPushedFrom == 'AccountProfileOpportunities'
        || this.from == 'AccountPlanOpportunities'
        || this.from == 'MarketingPlanInfoopportunities') {
        if (this.layoverViewPassedData) {
          payload['opportunityid'] = this.layoverViewPassedData.ID;
          payload['opportunityName'] = this.layoverViewPassedData.opportunityName;
          payload['customerid'] = this.layoverViewPassedData.accountID || '';
          payload['customerName'] = this.layoverViewPassedData.accountName || '';

          let oppObj:Opportunities ={
                  indskr_opportunity : this.layoverViewPassedData.ID,
                  indskr_procedurelogopportunityid : this.layoverViewPassedData.salesorderid,
                  deleted : false
          }
          payload['opportunities'] =[oppObj];
        }
      }

      console.log(this.layoverViewPassedData);

      if (!isWeeklyFlag && (this.NewActivitySource === ActivitySource.MEETING_PRESENTATION || this.activityOfflineService.activitySource === ActivitySource.MEETING_DETAIL && this.activityOfflineService.selectedActivity)) {
        payload['scheduledstart'] = (this.activityOfflineService.selectedActivity as AppointmentActivity).scheduledStart.getTime();
        payload['scheduledend'] = (this.activityOfflineService.selectedActivity as AppointmentActivity).scheduledEnd.getTime();
      }
      let newSurgeryOrder = new SurgeryOrderActivity(payload);
      if (isWeeklyFlag) {
        newSurgeryOrder.scheduledEnd = endOfWeek;
      }
      newSurgeryOrder.statusString = 'Draft';
      newSurgeryOrder.ownerId = this.authenticationOfflineService.user.systemUserID;
      newSurgeryOrder.ownerNameString = this.authenticationOfflineService.user.displayName;
      if (this.NewActivitySource === ActivitySource.MEETING_PRESENTATION || this.activityOfflineService.activitySource == ActivitySource.MEETING_DETAIL) {
        if (!this.activityOfflineService.selectedActivity.ID.includes('offline')) {
          newSurgeryOrder.appointmentId = this.activityOfflineService.selectedActivity.ID;
        }
        newSurgeryOrder.offlineMeetingId = (this.activityOfflineService.selectedActivity as AppointmentActivity).offlineMeetingId;
      }

      // Prefill Meeting Account and Contact if any
      if ((this.NewActivitySource === ActivitySource.MEETING_PRESENTATION || this.activityOfflineService.activitySource === ActivitySource.MEETING_DETAIL) && this.procedureCategory === 'procedureDay') {
        if (this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.Appointment && (this.activityOfflineService.selectedActivity as AppointmentActivity).accounts && (this.activityOfflineService.selectedActivity as AppointmentActivity).accounts.length == 1) {
          newSurgeryOrder.accountId = (this.activityOfflineService.selectedActivity as AppointmentActivity).accounts[0].id;
          newSurgeryOrder.accountNameString = (this.activityOfflineService.selectedActivity as AppointmentActivity).accounts[0].accountName;
        }
        if (this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.Appointment && (this.activityOfflineService.selectedActivity as AppointmentActivity).contacts && (this.activityOfflineService.selectedActivity as AppointmentActivity).contacts.length >= 1) {
          (this.activityOfflineService.selectedActivity as AppointmentActivity).contacts.forEach(meetingContact => {
            newSurgeryOrder.customers.push(new OrderContact({
              id: meetingContact.ID,
              title: meetingContact.title,
              firstName: meetingContact.firstName,
              middleName: meetingContact.middleName,
              lastName: meetingContact.lastName,
              fullName: meetingContact.fullName,
              stateCode: meetingContact.isActive ? 1 : 0,
              statusCode: meetingContact.statusCode
            }));
          });
          newSurgeryOrder.customersNameString = this.surgeryOrderActivityDataService.getCustomerString;
          newSurgeryOrder.subject = 'Procedure Log - ' + newSurgeryOrder.customersNameString;
        }
      }
      const procedureLogAction = {
        onDynamics: (this.device.isOffline) ? false : ((this.NewActivitySource === ActivitySource.MEETING_PRESENTATION || this.activityOfflineService.activitySource == ActivitySource.MEETING_DETAIL) ? !!newSurgeryOrder.appointmentId : true),
        onLocalCopy: true,
        onLocalDatabase: true
      }
      await this.surgeryOrderActivityDataService.createOrderActivity(procedureLogAction, [newSurgeryOrder], currentTime, false).then(succ => {
        //Successfully created new surgery order activity
        if (this.activityOfflineService.activitySource !== ActivitySource.MEETING_DETAIL && this.NewActivitySource !== ActivitySource.MEETING_PRESENTATION) {
          this.activityOfflineService.selectedActivity = newSurgeryOrder;
        } else {
          this.surgeryOrderActivityDataService.inMeetingSurgeryOrderActivity = newSurgeryOrder;
        }
      }).catch(err => {
        // Handle any error occured during order activity creation
        reject('error occured while creating new order');
        errorOccured = true;
      });
      if (this.activityOfflineService.activitySource === ActivitySource.MEETING_DETAIL || this.NewActivitySource === ActivitySource.MEETING_PRESENTATION) {
        if (this.uiService.activitiesPageTab == 'day') {
          let data = {
            id: newSurgeryOrder.ID,
            subject: newSurgeryOrder.subject,
            location: (newSurgeryOrder.accountId) ? newSurgeryOrder.accountNameString : 'No Account',
            scheduledStart: newSurgeryOrder.scheduledStart,
            scheduledEnd: newSurgeryOrder.scheduledEnd,
            type: newSurgeryOrder.type,
          };
          this.uiService.setUIServiceData({
            view: 'new-activity', data,
          });
        }
        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
        resolve('');
        return;
      }
      if (!errorOccured) {
        let from: string = 'AgendaView';
        let pageName: PageName = PageName.ActivitiesPageComponent;
        if (this.isLayoverPushView) {
          if (this.currentMasterPage == PageName.AccountPageComponent) {
            from = 'ProcedureLogFromAccountTool';
            pageName = PageName.AccountPageComponent;
            this.uiService.activeView = 'AccountPageRightPaneNav';
            this.uiService.showNewActivity = false;
            await this.navService.popChildNavPageWithPageTracking();
            this.navService.pushChildNavPageWithPageTracking(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent, PageName.AccountPageComponent, { from: from, activity: newSurgeryOrder });
          }
          else if (this.currentMasterPage == PageName.ContactPageComponent) {
            from = 'ProcedureLogFromContactTool';
            pageName = PageName.ContactPageComponent;
            this.uiService.activeView = 'ContactPageRightPaneNav';
            this.uiService.showNewActivity = false;
            await this.navService.popChildNavPageWithPageTracking();
            this.navService.pushChildNavPageWithPageTracking(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent, PageName.ContactPageComponent, { from: from, activity: newSurgeryOrder });
          }
          else if (this.currentMasterPage == PageName.OpportunityManagementPage && this.layoverViewPushedFrom == 'OpportunityManagementTool') {
            from = 'OpportunityManagementTool';
            pageName = PageName.OpportunityManagementPage;
            this.uiService.showNewActivity = false;
            this.uiService.isFromOpportunites = true;
            await this.navService.popChildNavPageWithPageTracking();
            this.navService.pushChildNavPageWithPageTracking(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent, PageName.OpportunityManagementPage, { from: from, activity: newSurgeryOrder });
          }
          else if (this.currentMasterPage == PageName.MarketingPlanManagementPageComponent && this.layoverViewPushedFrom == 'MarketingPlanInfoopportunities') {
            from = 'MarketingPlanOpportunities';
            pageName = PageName.MarketingPlanManagementPageComponent;
            this.uiService.showNewActivity = false;
            await this.navService.popChildNavPageWithPageTracking();
            this.navService.pushChildNavPageWithPageTracking(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent, PageName.MarketingPlanManagementPageComponent, { from: from, activity: newSurgeryOrder });
          }
        } else {
          this.trackingService.tracking('AgendaNewSurgeryOrder', TrackingEventNames.SURGERY_ORDER);
          this.navService.setChildNavRoot(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent, PageName.ActivitiesPageComponent, { from: from, activity: newSurgeryOrder });
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.uiService.showNewActivity = false;
          this.uiService.showRightPane = true;
          this.activityOfflineService.selectedActivityAtHome = this.activityOfflineService.selectedActivity;
          if (this.uiService.activitiesPageTab == 'day') {
            let data = {
              id: newSurgeryOrder.ID,
              subject: newSurgeryOrder.subject,
              location: (newSurgeryOrder.accountId) ? newSurgeryOrder.accountNameString : 'No Account',
              scheduledStart: newSurgeryOrder.scheduledStart,
              scheduledEnd: newSurgeryOrder.scheduledEnd,
              type: newSurgeryOrder.type,
            };
            this.uiService.setUIServiceData({
              view: 'new-activity', data,
            });
          }
        }
        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
        resolve('');
      } else {
        reject('Error occured while creating a new order');
      }
    });
  }

  private async handleNewProcedureTrackerCreation(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let errorOccured: boolean = false;
      let currentTime = new Date().getTime();
      let start, end;
      let isWeeklyFlag = ('Weekly' === this.authenticationOfflineService.user.procedureLogDuration);
      if (!isWeeklyFlag && !this.activityOfflineService.validateProcedureDate(this.startTime, 'DATETIME_P_CANT_SHEDULE_PROCEDURE_LOG_DAYS_AGO')) {
        reject('you cannot schedule a procedure log for more than the past activity grace period.');
        return;
      }
      if (this.agendaFooterService.actSegment === 'week') {
        if (isFuture(this.startTime) && !isToday(this.startTime) && this.procedureCategory == 'noProcedureDay') {
          this.notificationService.notify(this.translate.instant('SHEDULE_PROCEDURE_LOG_FUTURE'), 'New Activity', 'top', ToastStyle.DANGER);
          reject('no procedure day should be created in today and in past');
          return;
        }
        if (isFuture(this.startTime) && !isToday(this.startTime) && !isWeeklyFlag) {
          this.notificationService.notify(this.translate.instant('DAILY_PROCEDURE_TRACKER_FUTURE'), 'New Activity', 'top', ToastStyle.DANGER);
          reject('bulk procedure log should not be created in future dates');
          return;
        }
        start = format(Utility.getRoundedDownTime(new Date(this.startTime)));
      } else {
        start = format(Utility.getRoundedDownTime(new Date()));
      }
      end = format(addMinutes(start, 30));
      let startOfWeek, endOfWeek;
      if (isWeeklyFlag) {
        startOfWeek = moment(start).startOf('week').toDate();
        endOfWeek = moment(start).endOf('week').toDate();
        const currentWeek: Date = moment(new Date()).startOf('week').toDate();
        if (moment(startOfWeek).isAfter(currentWeek)) {
          this.notificationService.notify(this.translate.instant('WEEKLY_PROCEDURE_TRACKER_FUTURE'), 'New Activity', 'top', ToastStyle.DANGER);
          reject('bulk procedure log should not be created in future week');
          return;
        }
        const firstDay = moment(start).startOf('week').format('MMM DD, YYYY');
        const lastDay = moment(start).endOf('week').format('MMM DD, YYYY');
        this.notificationService.notify(this.translate.instant('PROCEDURE_LOG_FOR_WEEK', { startDate: firstDay, endDate: lastDay }), 'New Activity', 'top', ToastStyle.INFO);
      }
      let orderName: string = 'Bulk Procedure Log';
      let detailsComponent: any = ProcedureTrackerDetailsComponent;
      let detailsPageName: PageName = PageName.ProcedureTrackerDetailsComponent;
      let payload: any = {
        salesorderid: 'offline_proceduretracker_' + currentTime,
        indskr_externalid: 'offline_proceduretracker_' + currentTime,
        indskr_ordertype: 548910002,
        ordernumber: 'Surgery Order',
        name: orderName,
        pendingPushToDynamics: true,
        statuscode: 1,
        statecode: 0,
        activitytypecode: 'ProcedureTracker',
        createdon: currentTime,
        modifiedon: currentTime + '',
        scheduledstart: (isWeeklyFlag) ? startOfWeek : new Date(start).getTime(),
        scheduledend: (isWeeklyFlag) ? endOfWeek : new Date(end).getTime(),
        divisionName: this.authService.user.businessUnitName,
        divisionId: this.authService.user.xBusinessUnitId,
      }


      if (this.currentMasterPage === PageName.AccountPageComponent && this.layoverViewPassedData
        && !(this.NewActivitySource === ActivitySource.MEETING_PRESENTATION || this.activityOfflineService.activitySource == ActivitySource.MEETING_DETAIL)) {
        payload['customerid'] = this.layoverViewPassedData.id;
        payload['customerName'] = this.layoverViewPassedData.accountName;
        if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.AUTO_SUBJECT_PROCEDURE_TRACKER)) {
          payload['name'] = moment(startOfWeek).format('YYYY-MM-DD') + ' ' + this.layoverViewPassedData.accountName;
        }
      }
      if (this.layoverViewPushedFrom == 'OpportunityManagementTool'
      || this.layoverViewPushedFrom == 'AccountProfileOpportunities'
      || this.from == 'AccountPlanOpportunities'
      || this.from == 'MarketingPlanInfoopportunities') {
      if (this.layoverViewPassedData) {
        payload['opportunityid'] = this.layoverViewPassedData.ID;
        payload['opportunityName'] = this.layoverViewPassedData.opportunityName;
        payload['customerid'] = this.layoverViewPassedData.accountID || '';
        payload['customerName'] = this.layoverViewPassedData.accountName || '';
        let oppObj:Opportunities ={
          indskr_opportunity : this.layoverViewPassedData.ID,
          indskr_procedurelogopportunityid : this.layoverViewPassedData.salesorderid,
          deleted : false
        }
        payload['opportunities'] =[oppObj];
      }
      }
      if (!isWeeklyFlag && (this.NewActivitySource === ActivitySource.MEETING_PRESENTATION || this.activityOfflineService.activitySource === ActivitySource.MEETING_DETAIL && this.activityOfflineService.selectedActivity)) {
        payload['scheduledstart'] = (this.activityOfflineService.selectedActivity as AppointmentActivity).scheduledStart.getTime();
        payload['scheduledend'] = (this.activityOfflineService.selectedActivity as AppointmentActivity).scheduledEnd.getTime();
      }
      let newProcedureTracker = new ProcedureTrackerActivity(payload);
      if (isWeeklyFlag) {
        newProcedureTracker.scheduledEnd = endOfWeek;
      }
      newProcedureTracker.statusString = 'Draft';
      newProcedureTracker.modifiedby = newProcedureTracker.ownerId = this.authenticationOfflineService.user.systemUserID;
      newProcedureTracker.modifiedByName = newProcedureTracker.ownerNameString = this.authenticationOfflineService.user.displayName;
      newProcedureTracker.ownerNameString = this.authenticationOfflineService.user.displayName;
      if (this.NewActivitySource === ActivitySource.MEETING_PRESENTATION || this.activityOfflineService.activitySource == ActivitySource.MEETING_DETAIL) {
        if (!this.activityOfflineService.selectedActivity.ID.includes('offline')) {
          newProcedureTracker.appointmentId = this.activityOfflineService.selectedActivity.ID;
        }
        newProcedureTracker.offlineMeetingId = (this.activityOfflineService.selectedActivity as AppointmentActivity).offlineMeetingId;
      }

      // Prefill Meeting Account and Contact if any
      if ((this.NewActivitySource === ActivitySource.MEETING_PRESENTATION || this.activityOfflineService.activitySource === ActivitySource.MEETING_DETAIL)) {
        if (this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.Appointment && (this.activityOfflineService.selectedActivity as AppointmentActivity).accounts && (this.activityOfflineService.selectedActivity as AppointmentActivity).accounts.length == 1) {
          newProcedureTracker.accountId = (this.activityOfflineService.selectedActivity as AppointmentActivity).accounts[0].id;
          newProcedureTracker.accountNameString = (this.activityOfflineService.selectedActivity as AppointmentActivity).accounts[0].accountName;
        }
      }
      const procedureLogAction = {
        onDynamics: (this.device.isOffline) ? false : ((this.NewActivitySource === ActivitySource.MEETING_PRESENTATION || this.activityOfflineService.activitySource == ActivitySource.MEETING_DETAIL) ? !!newProcedureTracker.appointmentId : true),
        onLocalCopy: true,
        onLocalDatabase: true
      }

      // validation here;
      if (newProcedureTracker.accountId) {
        const procedureTrackerExists = await this.procedureTrackerActivityDataService.isDuplicateProceureTrackerExists(newProcedureTracker.accountId, {
          scheduledStart: payload.scheduledstart,
          scheduledEnd: payload.scheduledend
        }).catch(err => {
          this.notificationService.notify(this.translate.instant('ADDING_ACCOUNTS_TO_PROCEDURE_TRACKER_FAILED'), 'Order Details', 'top', ToastStyle.DANGER);
        });

        if (procedureTrackerExists === undefined) {  // if there is dynamics error
          return reject();
        }

        if (procedureTrackerExists) {
          this.notificationService.notify(this.translate.instant('DUPLICATE_PROCEDURE_TRACKER', {
            accountName: newProcedureTracker.accountNameString,
            startDate: format(payload.scheduledstart, 'DD MMM YYYY'),
            endDate: format(payload.scheduledend, 'DD MMM YYYY')
          }),
            'Order Details', 'top', ToastStyle.INFO, 10000);
          return reject();
        }
      }
      // validation here


      await this.procedureTrackerActivityDataService.createOrderActivity(procedureLogAction, [newProcedureTracker], currentTime, false).then(succ => {
        //Successfully created new surgery order activity
        if (this.activityOfflineService.activitySource !== ActivitySource.MEETING_DETAIL && this.NewActivitySource !== ActivitySource.MEETING_PRESENTATION) {
          this.activityOfflineService.selectedActivity = newProcedureTracker;
        } else {
          this.procedureTrackerActivityDataService.inMeetingProcedureTrackerActivity = newProcedureTracker;
        }
      }).catch(err => {
        // Handle any error occured during order activity creation
        reject('error occured while creating new order');
        errorOccured = true;
      });
      if (this.activityOfflineService.activitySource === ActivitySource.MEETING_DETAIL || this.NewActivitySource === ActivitySource.MEETING_PRESENTATION) {
        if (this.uiService.activitiesPageTab == 'day') {
          let data = {
            id: newProcedureTracker.ID,
            subject: newProcedureTracker.subject,
            location: (newProcedureTracker.accountId) ? newProcedureTracker.accountNameString : 'No Account',
            scheduledStart: newProcedureTracker.scheduledStart,
            scheduledEnd: newProcedureTracker.scheduledEnd,
            type: newProcedureTracker.type,
          };
          this.uiService.setUIServiceData({
            view: 'new-activity', data,
          });
        }
        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
        resolve('');
        return;
      }
      if (!errorOccured) {
        let from: string = 'AgendaView';
        let pageName: PageName = PageName.ActivitiesPageComponent;
        if (this.isLayoverPushView) {
          if (this.currentMasterPage == PageName.AccountPageComponent) {
            from = 'ProcedureLogFromAccountTool';
            pageName = PageName.AccountPageComponent;
            this.uiService.activeView = 'AccountPageRightPaneNav';
          }
          else if (this.currentMasterPage == PageName.ContactPageComponent) {
            from = 'ProcedureLogFromContactTool';
            pageName = PageName.ContactPageComponent;
            this.uiService.activeView = 'ContactPageRightPaneNav';
          } else if(this.currentMasterPage == PageName.OpportunityManagementPage) {
            from = 'OpportunityManagementTool';
          }else if(this.currentMasterPage == PageName.MarketingPlanManagementPageComponent) {
            from = 'MarketingPlanOpportunities';
          }
          this.uiService.showNewActivity = false;
          await this.navService.popChildNavPageWithPageTracking();
          this.navService.pushChildNavPageWithPageTracking(detailsComponent, detailsPageName, pageName, { from: from, activity: newProcedureTracker });
        } else {
          this.trackingService.tracking('AgendanewProcedureTracker', TrackingEventNames.PROCEDURE_TRACKER);
          this.navService.setChildNavRoot(detailsComponent, detailsPageName, pageName, { from: from, activity: newProcedureTracker });
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.uiService.showNewActivity = false;
          this.uiService.showRightPane = true;
          this.activityOfflineService.selectedActivityAtHome = this.activityOfflineService.selectedActivity;
          if (this.uiService.activitiesPageTab == 'day') {
            let data = {
              id: newProcedureTracker.ID,
              subject: newProcedureTracker.subject,
              location: (newProcedureTracker.accountId) ? newProcedureTracker.accountNameString : 'No Account',
              scheduledStart: newProcedureTracker.scheduledStart,
              scheduledEnd: newProcedureTracker.scheduledEnd,
              type: newProcedureTracker.type,
            };
            this.uiService.setUIServiceData({
              view: 'new-activity', data,
            });
          }
        }
        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
        resolve('');
      } else {
        reject('Error occured while creating a new order');
      }
    });
  }

  public async createDraftEmail() {
    this.trackingService.tracking('AgendaEmailCreated', TrackingEventNames.ACTIVITY);
    let scheduleTime = new Date().getTime().toString();
    let request: any
    let activity: any;
    if (this.agendaFooterService.actSegment === "week" && this.startTime && this.cellData) {
      const selectedDate: Date = new Date(this.startTime);
      if (isPast(selectedDate)) {
        let channelType: string = (this.selectedMessagingChannelType.channelType) ? this.selectedMessagingChannelType.channelType : this.translate.instant("MESSAGE");
        this.notificationService.notify(this.translate.instant("MESSAGE_ACTIVITY_UNABLE_SCHEDULE_EMAIL", { type: channelType }), 'New Activity', 'top', ToastStyle.DANGER);
        return;
      }
      scheduleTime = selectedDate.getTime().toString();
    }
    let emailParties: EmailActivityParty[] = [];
    if (this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent
      || (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans' && this.uiService.activitiesPagePlanTabSelectedPlan === 'goalsPlansTab')) {
      this.callPlanService.callPlanPageView = 'CallPlanNewEmailDetails';
      let selectedContactId = '';
      if(!_.isEmpty(this.callPlanOfflineService.selectedRepCallPlan)) {
        selectedContactId = this.callPlanOfflineService.selectedRepCallPlan['contactId'];
      }else if(this.uiService.activitiesPagePlanTabSelectedPlan === 'goalsPlansTab' && !_.isEmpty(this.contactService.contactInformation)) {
        selectedContactId = this.contactService.contactInformation['ID'];
      }
      const contacts = selectedContactId ? this.contactService.getContactByID(selectedContactId) : false;
      if (contacts) {
        if (!this.isActiveContact(contacts)) return;
        let credentailsNotFoud = await this.validateChannelCredentails(contacts)
        if (credentailsNotFoud) {
          this.cancelNewActivity();
          // stop execution when there is no credentails for selected channel.
          this.navService.popChildNavPageWithPageTracking();
          return;
        }
        emailParties = await this.getEmailParties(contacts)
      }
    }
    if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent || this.navService.getCurrentMasterPageName() == PageName.ScientificActivityPage || this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage
      || (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans' && this.uiService.activitiesPagePlanTabSelectedPlan === 'scientificPlans')) {
      this.callPlanService.callPlanPageView = '';
      const contacts = this.contactService.contactInformation ? this.contactService.getContactByID(this.contactService.contactInformation['ID']) : false;
      if (contacts) {
        if (!this.isActiveContact(contacts)) return;
        let credentailsNotFoud = await this.validateChannelCredentails(contacts)
        if (credentailsNotFoud) {
          this.navService.popChildNavPageWithPageTracking();
          return;
        }
        emailParties = await this.getEmailParties(contacts)
      }
    }
    let payload = {
      scheduledstart: scheduleTime,
      scheduledend: scheduleTime,
      subject: this.selectedMessagingChannelType.channelType,
      statuscode: 1,
      channelType: this.selectedMessagingChannelType.channelType,
      channelId: this.selectedMessagingChannelType.id,
      channelActivityType: this.selectedMessagingChannelType.activityType
    };
    // this.emailService.selectedMessagingChannelType.next(request.channelType)
    activity = await this.emailService.createNewEmailActivity(payload);
    if (activity) {
      let loader = await this.loadingController.create();
      loader.present();

      if (this.navService.getCurrentMasterPageName() !== PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan !== 'goalsPlansTab') this.uiService.activeView = 'Email';
      let data = {
        id: activity.ID, subject: activity.subject, info: activity.info, type: activity.type,
        scheduledStart: activity.scheduledStart, scheduledEnd: activity.scheduledEnd,
      };
      this.activityOfflineService.addActivity(activity);
      if (this.uiService.activitiesPageTab === 'day') {
        this.uiService.setUIServiceData({
          view: 'new-activity', data
        });
      }
      this.emailService.selectedActivity = activity;

      if (this.layoverViewPushedFrom === PageName.CustomerCallPlanDetails && this.authService.hasFeatureAction(FeatureActionsMap.EMAIL_ACCOUNT_INFORMATION_CAPTURE)) {
        if (this.layoverViewPassedData) {
          const accountId = this.layoverViewPassedData.id || '';
          const foundAccount = this.accountService.getAccountById(accountId);
          if (foundAccount) {
            this.emailService.selectedActivity['accounts'] = [];
            this.emailService.selectedActivity.accounts.push(foundAccount);
            let selectedAccount = [];
            if (this.emailService.selectedActivity.accounts) {
              this.emailService.selectedActivity.accounts.map(account => {
                selectedAccount.push({
                  indskr_accountid: account.id,
                });
              });
            }
            const payloadSelectedAccount = {
              accounts : selectedAccount
            }
            if (!this.device.isOffline) {
              try {
                await this.emailDataService.sendEmailExternalActivity(payloadSelectedAccount, this.emailService.selectedActivity.activityid);
              } catch(error) {
                console.log(error);
              }
            }
          }
        }
      }

      this.emailService.setCurrentEmail(this.emailService.selectedActivity);
      this.emailService.viewType = EmailViewType.FROM_AGENDA;
      this.uiService.showNewActivity = false;
      this.activityService.activityDetailsLoaded = true;
      if (!this.uiService.toolsActivityActive) {
        this.events.publish("refreshAgenda");
      }
      else {
        this.uiService.agendaRefreshRequired = true;
      }
      this.activityOfflineService.publishActivityEvent({ action: "Create", activity: this.emailService.selectedActivity });
      this.emailService.setCurrentEmail(activity);
      if (!_.isEmpty(emailParties)) {
        await this.emailService.updateEmailActivityParties(emailParties);
      }
      await loader?.dismiss();
    }
    if (this.NewActivitySource === 'MeetingPresentation') {
      this.emailService.viewType = EmailViewType.EMAIL_FROM_MEETING_PRESENTATION;
      this.navService.setChildNavRoot(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.PresentationMeetingComponent);
    } else {
      this.activityOfflineService.selectedActivity = activity;
    }
    if (this.layoverViewPushedFrom == 'ScientificActivityPage') {
      this.emailService.viewType = EmailViewType.FROM_SCIENTIFIC_PLAN;
      if (this.uiService.activitiesPagePlanTabSelectedPlan === 'scientificPlans') {
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        this.navService.popChildNavPageWithPageTracking();
      }
      this.navService.pushChildNavPageWithPageTracking(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ScientificActivityDetail, { modalView: {} });
    } else if (this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent) {
      this.callPlanService.callPlanPageView = 'CallPlanNewEmailDetails';
      this.navService.popChildNavPageWithPageTracking();
      this.navService.pushChildNavPageWithPageTracking(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.CallPlanComponent, { from: PageName.CallPlanDetails });
    }
    else if (this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage) {
      this.callPlanService.callPlanPageView = 'CallPlanNewEmailDetails';
      this.navService.popChildNavPageWithPageTracking();
      this.navService.pushChildNavPageWithPageTracking(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.CustomerCallPlanPage, { from: PageName.CustomerCallPlanDetails });
    }
    else if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent) {
      this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      this.uiService.showNewActivity = false;
      this.uiService.showRightPane = true;
      if (this.uiService.activitiesPagePlanTabSelectedPlan === 'goalsPlansTab') {
        this.navService.popChildNavPageWithPageTracking();
        this.navService.pushChildNavPageWithPageTracking(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ActivitiesPageComponent, { from: PageName.CallPlanDetails });
      }
      else {
        this.navService.setChildNavRoot(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ActivitiesPageComponent, { from: PageName.ActivitiesPageComponent });
      }
    } else if (this.layoverViewPushedFrom === 'ContactPageComponent') {
      //this.navService.popChildNavPageWithPageTracking();
      this.navService.pushChildNavPageWithPageTracking(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ContactPageComponent, { from: PageName.ContactPageComponent });
    }
  }

  private isActiveContact(contact): boolean {
    if (!contact.isActive) {
      this.notificationService.notify(this.translate.instant('CONTACT_NO_LONGER_ACCESS'), 'Contact list', 'top', ToastStyle.DANGER);
      return false;
    }
    return true;
  }

  async validateChannelCredentails(contactDetails) {
    switch (this.selectedMessagingChannelType.activityType) {
      case ChannelActivityType.EMAIL:
        if (_.isEmpty(contactDetails.emailAddressList)) {
          this.notificationService.notify(this.translate.instant('NO_EMAIL_ADDRESS_ASSOCIATED'), 'New Activity', 'top', ToastStyle.INFO)
          return true;
        }
        break;

      case ChannelActivityType.FACEBOOK:
        if (_.isEmpty(contactDetails.indskr_facebookpsid)) {
          this.notificationService.notify(this.translate.instant('NO_FACE_ID_ASSOCIATED'), 'New Activity', 'top', ToastStyle.INFO)
          return true;
        }
        break;

      case ChannelActivityType.SMS:
        if ((_.isEmpty(contactDetails.indskr_whatsappid)) && (_.isEmpty(contactDetails.mobilePhone))) {
          this.notificationService.notify(this.translate.instant('NO_MOBILE_NUMBER_ASSOCIATED'), 'New Activity', 'top', ToastStyle.INFO)
          return true;
        }
        break;

      case ChannelActivityType.WHATSAPP:
        if ((_.isEmpty(contactDetails.indskr_whatsappid)) && (_.isEmpty(contactDetails.mobilePhone))) {
          this.notificationService.notify(this.translate.instant('NO_MOBILE_NUMBER_ASSOCIATED'), 'New Activity', 'top', ToastStyle.INFO)
          return true;
        }
        break

    }
  }

  async getEmailParties(contactDetails) {
    let emailAddress: EmailAddress[] = [];
    let emailAddresses: string[] = [];
    let emailParties: EmailActivityParty[] = [];
    for (let email of contactDetails.emailAddressList) {
      if (email.isPrimary) {
        email.isSelected = true;
        if(!emailAddresses.includes(email.emailAddressId)) {
          emailAddresses.push(email.emailAddressId);
          emailAddress.push(new EmailAddress(email.emailAddressId, email.emailAddress));
        }

      } else {
        email.isSelected = false;
      }
    }
    let emailParty: EmailActivityParty = new EmailActivityParty();
    emailParty.allowToRemove = true;
    emailParty.contact_firstname = contactDetails.firstName;
    emailParty.contact_lastname = contactDetails.lastName;
    emailParty.contact_mobilephone = contactDetails.mobilePhone;
    emailParty.contact_indskr_facebookpsid = contactDetails.indskr_facebookpsid;
    emailParty.indskr_contactid = contactDetails.ID;
    emailParty.emailAddresses = emailAddress;
    emailParties.push(emailParty);
    return emailParties;
  }

  public setActiveView(viewType, subViewType?) {
    this.displayConflictAlert = false;

    if ((viewType == 'new-insight' || viewType == 'general-insight') && this.device.isOffline) return;

    this.selectedEvent = null;
    if (viewType == 'meeting' || viewType == 'timeoff') {
      //const view = this.navCtrl.getActive();
      let eventname = ''
      if (this.agendaFooterService.actSegment !== 'week') {
        setTimeout(() => {
          this.setCellData();
          this.initDateTimeFormFields();
        }, 0);
        if (this.navService.getCurrentPageName() == PageName.NewActivityComponent) {
          //mobile view confirmed
          if (this.currentMasterPage === PageName.ActivitiesPageComponent) {
            //mobile view and agenda
            eventname = viewType == 'meeting' ? 'Schedule Activity from Activity screen' : 'Schedule Time Off from Activity screen';
          }
        }
        else if (this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent) {
          //agenda view on browser
          eventname = viewType == 'meeting' ? 'Schedule Activity from Activity screen' : 'Schedule Time Off from Activity screen';
        }
      }
      else {
        //calendar view on browser
        setTimeout(() => {
          this._initTimeForCalendarPage(true);
          this.initDateTimeFormFields();
        }, 0);
        eventname = viewType == 'meeting' ? 'Schedule Activity from Calendar' : 'Schedule Time Off from Calendar';
      }
      this.trackingService.tracking(eventname, TrackingEventNames.ACTIVITY);
    }
    else if (viewType == 'phonecall') {
      this.trackingService.tracking(' Select Phone call', TrackingEventNames.PHONECALL, true);
      setTimeout(() => {
        this.setCellData();
        this._initTimeForCalendarPage(true);
        this.initDateTimeFormFields();
      }, 0);
    }
    else if (viewType == 'customerinquiry'){
      this.checkNumOfCaseList();
      if(this.activityOfflineService.activitySource === ActivitySource.PHONE_CALL_ACTIVITY_DETAIL)
        this.trackingService.tracking('Select inquiry', TrackingEventNames.PHONECALL);
      if(this.activityOfflineService.activitySource === ActivitySource.SET_BOOKING_ACTIVITY_DETAIL){
        this.checkNumOfCaseList();
        this.trackingService.tracking('Select inquiry', TrackingEventNames.KITBOOKING);
      }
    }
    else if (viewType == 'email' && this.activityOfflineService.activitySource === ActivitySource.PHONE_CALL_ACTIVITY_DETAIL) {
      this.trackingService.tracking('Select Message', TrackingEventNames.PHONECALL);
    }
    else if (viewType == 'consent' && (this.activityOfflineService.selectedActivity as AppointmentActivity).contacts.length == 1) {
      this.selectConsentCustomer((this.activityOfflineService.selectedActivity as AppointmentActivity).contacts[0], true);
    } else if (viewType === 'accountVisit') {
      let eventDetail = 'Schedule Account Visit from Activity screen';
      if (this.agendaFooterService.actSegment !== 'week') {
        setTimeout(() => {
          this.setCellData();
          this.initDateTimeFormFields();
        }, 0);
      } else {
        eventDetail = 'Schedule Account Visit from Calendar';
        setTimeout(() => {
          this._initTimeForCalendarPage(true);
          this.initDateTimeFormFields();
        }, 0);
      }
      this.trackingService.tracking(eventDetail, TrackingEventNames.ACCOUNT_VISIT, true);
    } else if (viewType === 'storecheck') {
      setTimeout(() => {
        this.setCellData();
        this._initTimeForCalendarPage(true);
        this.initDateTimeFormFields();
      }, 0);
    } else if (viewType == 'kit_booking'){
      if(this.device.isOffline) return;
    }

    // if(viewType == 'email') {
    //   this.emailService.setCurrentEmail(undefined);
    //   this.emailService.selectedActivity = undefined;
    // }
    if (this.uiService.activeViewNewMeeting === 'timeoff' && viewType === 'timeoff') {
      // handle click again scenario
      this._newTimeOffCmp?.initDateTimeFormFields();
    }
    this.uiService.setActiveViewForNewMeeting(viewType, subViewType);
    if (
      viewType === 'meeting'
      || viewType === 'phonecall'
      || viewType === 'accountVisit'
    ) {
      this.activityOfflineService.signalConflictCheck$.next({ componentUuid: this.uuid, signal: true, start: new Date(this.startDateTime), end: new Date(this.endDateTime) });
    }

    this._cd.detectChanges();
    this.initHeaderOnOptionSelection();
  }

  async createNewPhoneCall() {
    const payload: InitiatePhoneCallPayload = this._preparePhoneCallPayload();
    const newPhoneCall = await this.phoneCallDataService.createPhoneCallActivity(payload);
    //this.navService.pushChildNavPageWithPageTracking(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent,
    //PageName.ActivitiesPageComponent, { from: PageName.ActivitiesPageComponent });
    if (newPhoneCall) {
      let data;
      if (!newPhoneCall.createdOffline) {
        // Online created meeting
        data = { id: newPhoneCall.ID };

        if (this.navService.getPreviousActiveChildNavViewPageName() === PageName.AccountPlanDetailsComponent) {
          let payload = [{
            activityPhoneCallId: Guid.create().toString(),
            activityId: newPhoneCall.ID
          }]
          this.uiService.displayLoader();
          this.accountManagementDataService.savePhoneCallsForAccountPlan(this.layoverViewPassedData.ID, payload).finally(() => {
            this.uiService.dismissLoader();
          })
        }
      } else {
        // Offline created meeting
        data = {
          id: newPhoneCall.ID,
          subject: newPhoneCall.subject,
          location: newPhoneCall.location,
          scheduledStart: newPhoneCall.scheduledStart,
          scheduledEnd: newPhoneCall.scheduledEnd,
        };
      }
      this.uiService.setUIServiceData({
        view: 'new-activity', data,
      }); // this is an observable stream




      //Add the same phone number if creating follow up
      let phoneNumber = undefined
      if (this.followUpMeetingContacts.length > 0 && this.activityOfflineService.selectedActivity) {
        phoneNumber = (this.activityOfflineService.selectedActivity as PhoneActivity).selectedMobileNumber
      }


      if(this.navService.getPreviousActiveChildNavViewPageName() ===   PageName.AccountPlanDetailsComponent){
        newPhoneCall.accountPlanId = this.layoverViewPassedData.ID;
      }

      this.activityOfflineService.selectedActivity = newPhoneCall;



      let fieldsUpdated:boolean = false;

      // Set Default Values For IO Config Fields
      this.activityOfflineService.addIoConfigDefaultValuesToActivity(newPhoneCall);
      if(newPhoneCall instanceof PhoneActivity && newPhoneCall.appConfigFields && newPhoneCall.appConfigFields.length > 0){
        if (!this.device.isOffline && !this.activityOfflineService.hasOfflinePhoneCallData(newPhoneCall.ID)) {
          await this.phoneCallDataService.updateappConfigFields((newPhoneCall as PhoneActivity), newPhoneCall.appConfigFields, true);
        } else {
          await this.activityOfflineService.upsertPhoneCallOfflineData(this.activityOfflineService.selectedActivity as PhoneActivity);
        }
      }
      if (this.currentMasterPage == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab !== 'plans') {
        this.uiService.showRightPane = true;
        this.uiService.activeView = 'PhoneCall';
        this.navService.setChildNavRoot(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.ActivitiesPageComponent, { isNewlyCreatedActivity: true });
      }
      else if (this.currentMasterPage === PageName.ContactPageComponent) {
        if (this.contactService.contactInformation) { //add contact to new activity if created from contact profile
          let contact = this.contactService.getContactByID(this.contactService.contactInformation['ID']);
          if (contact) {
            //newPhoneCall['contacts'].push(contact);
            this.contactService.moveContactToSelected(contact)
            newPhoneCall.selectedMobileNumber = contact.mobilePhone
            await this.phoneCallDataService.addContactsToPhoneCallActivity(newPhoneCall);
            let mobileNumberList = [];
            if (contact.mobilePhone || contact.telephone || contact.indskr_alternatephone1 || contact.indskr_alternatephone2 || contact.indskr_alternatephone3) {
              mobileNumberList.push({ 'phoneNumber': contact.mobilePhone ? contact.mobilePhone : '', 'identity': 'mobilePhone' })
              mobileNumberList.push({ 'phoneNumber': contact.indskr_alternatephone1 ? contact.indskr_alternatephone1 : '', 'identity': 'alternatephone1' })
              mobileNumberList.push({ 'phoneNumber': contact.indskr_alternatephone2 ? contact.indskr_alternatephone2 : '', 'identity': 'alternatephone2' })
              mobileNumberList.push({ 'phoneNumber': contact.indskr_alternatephone3 ? contact.indskr_alternatephone3 : '', 'identity': 'alternatephone3' })
              mobileNumberList.push({ 'phoneNumber': contact.telephone ? contact.telephone : '', 'identity': 'telephone' })
            }
            if (mobileNumberList.length) {
              const isNotEmpty = (element) => !_.isEmpty(element.phoneNumber);
              let index = mobileNumberList.findIndex(isNotEmpty);
              mobileNumberList[index].isChecked = true;
              (this.activityOfflineService.selectedActivity as PhoneActivity).selectedMobileNumber = mobileNumberList[index].phoneNumber
            }
            this.uiService.prevView = 'contactDetails';
            this.uiService.activeView = 'phoneCallTimelineDetails';
            this.uiService.showCancelDoneOnActivityDetails = true;
            this.footerService.initButtons(FooterViews.PHONE_CALL);
          }
        }
      } else if (this.currentMasterPage === PageName.AccountManagementPageComponent && this.layoverViewPassedData.accounts?.length == 1) {
        const accounts = [{ accountId: this.layoverViewPassedData.accounts[0].id, accountName: this.layoverViewPassedData.accounts[0].name }];
        newPhoneCall['accounts'].push(...accounts)
        this.activityOfflineService.selectedActivity['accounts'] = [];
        this.activityOfflineService.selectedActivity['accounts'].push(...accounts);
        fieldsUpdated = true;
      } else if (this.currentMasterPage === PageName.AccountPageComponent) {
        newPhoneCall['accounts'].push(this.layoverViewPassedData)
        this.activityOfflineService.selectedActivity['accounts'] = [];
        this.activityOfflineService.selectedActivity['accounts'].push(this.layoverViewPassedData);
        fieldsUpdated = true;
      } else if ((this.currentMasterPage === PageName.CallPlanComponent || this.currentMasterPage === PageName.CustomerCallPlanPage
        || (this.currentMasterPage == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans' && this.uiService.activitiesPagePlanTabSelectedPlan == 'goalsPlansTab'))) {
        let contact = this.contactService.contactInformation ? this.contactService.getContactByID(this.contactService.contactInformation['ID']) : false;
        if (contact) {
          this.contactService.moveContactToSelected(contact);
          newPhoneCall.selectedMobileNumber = contact.mobilePhone;
          newPhoneCall.subject = `${contact.firstName} ${contact.lastName} - ${this.translate.instant('NEW_ACTIVITY_PHONECALL')}`
          await this.phoneCallDataService.addContactsToPhoneCallActivity(newPhoneCall);
          let mobileNumberList = [];
          if (contact.mobilePhone || contact.telephone || contact.indskr_alternatephone1 || contact.indskr_alternatephone2 || contact.indskr_alternatephone3) {
            mobileNumberList.push({ 'phoneNumber': contact.mobilePhone ? contact.mobilePhone : '', 'identity': 'mobilePhone' })
            mobileNumberList.push({ 'phoneNumber': contact.indskr_alternatephone1 ? contact.indskr_alternatephone1 : '', 'identity': 'alternatephone1' })
            mobileNumberList.push({ 'phoneNumber': contact.indskr_alternatephone2 ? contact.indskr_alternatephone2 : '', 'identity': 'alternatephone2' })
            mobileNumberList.push({ 'phoneNumber': contact.indskr_alternatephone3 ? contact.indskr_alternatephone3 : '', 'identity': 'alternatephone3' })
            mobileNumberList.push({ 'phoneNumber': contact.telephone ? contact.telephone : '', 'identity': 'telephone' })
          }
          if (mobileNumberList.length) {
            const isNotEmpty = (element) => !_.isEmpty(element.phoneNumber);
            let index = mobileNumberList.findIndex(isNotEmpty);
            mobileNumberList[index].isChecked = true;
            (this.activityOfflineService.selectedActivity as PhoneActivity).selectedMobileNumber = mobileNumberList[index].phoneNumber
          }
        }

        if (this.layoverViewPushedFrom === PageName.CustomerCallPlanDetails && this.layoverViewPassedData) {
          const accountId = this.layoverViewPassedData.id || '';
            const foundAccount = this.accountService.getAccountById(accountId);
            if (foundAccount) {
              newPhoneCall['accounts'].push(this.layoverViewPassedData)
              this.activityOfflineService.selectedActivity['accounts'] = [];
              this.activityOfflineService.selectedActivity['accounts'].push(this.layoverViewPassedData);
              fieldsUpdated = true;
            }
        }

        if (this.currentMasterPage == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans' && this.uiService.activitiesPagePlanTabSelectedPlan == 'goalsPlansTab') {
          this.uiService.showRightPane = true;
        } else {
          this.uiService.prevView = 'callPlanDetails';
          this.uiService.activeView = 'phoneCallTimelineDetails';
          this.uiService.showRightPane = false;
        }
        // this.callPlanService.callPlanPageView = 'CallPlanPhoneCallDetails';
        this.navService.popChildNavPageWithPageTracking();
        if (this.currentMasterPage === PageName.CallPlanComponent) {
          this.navService.pushChildNavPageWithPageTracking(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.CallPlanComponent, { from: PageName.CallPlanDetails, isNewlyCreatedActivity: true });
        } else {
          this.navService.pushChildNavPageWithPageTracking(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.CustomerCallPlanPage, { from: PageName.CustomerCallPlanDetails, isNewlyCreatedActivity: true });
        }
        this.uiService.showCancelDoneOnActivityDetails = true;
        this.footerService.initButtons(FooterViews.PHONE_CALL);
      }

      //Add contacts from selected Activity to new created follow up activity
      if (this.followUpMeetingContacts.length > 0) {
        this.activityOfflineService.selectedActivity['contacts'] = [];
        this.followUpMeetingContacts.map(contact => {
          this.contactService.moveContactToSelected(contact);
        });
        newPhoneCall.selectedMobileNumber = phoneNumber
        await this.phoneCallDataService.addContactsToPhoneCallActivity(newPhoneCall);

        if (this.uiService.activitiesPageTab == 'agenda') {
          this.activityOfflineService.selectedActivityOnAgenda = this.activityOfflineService.selectedActivity;
        }

        this.contactService.followUpMeetingContacts = [];
      }

      if(fieldsUpdated){
        let phoneCallPayLoad = this.phoneCallDataService.setPhoneCallPayload(this.activityOfflineService.selectedActivity as PhoneActivity)
        if (!this.device.isOffline && !this.activityOfflineService.hasOfflinePhoneCallData(this.activityOfflineService.selectedActivity.ID)) {
          await this.phoneCallDataService.updatePhoneCall(this.activityOfflineService.selectedActivity as PhoneActivity, phoneCallPayLoad); //CWD-2414 Update the activity subject if auto subject feature action is enabled
          // this.activityService.isActivityUpdate = true;
        }
        else {
          await this.activityOfflineService.upsertPhoneCallOfflineData(this.activityOfflineService.selectedActivity as PhoneActivity);
        }
      }


      this.isLoaderOn = false;
      this.uiService.showNewActivity = false;
      this.activityService.activityDetailsLoaded = true;
      if (!this.uiService.toolsActivityActive) {
        this.events.publish("refreshAgenda");
        if (this.agendaFooterService.actSegment === 'week') {
          this.events.publish("weekview:RefreshUI"); // Refreshing the weekview for phone call activity.
        }
      }
      else {
        this.uiService.agendaRefreshRequired = true;
        this.activityOfflineService.signalActivityConflictCheck$.next(this.activityOfflineService.selectedActivity);
      }
      this.phoneCallMeetingStructureService.createEmbeddedInteractions(newPhoneCall);
      this.phoneCallDataService.saveMeeting(newPhoneCall);
    }
  }

  private clearContactJoinStatusOfCurrentSelectedMeeting() {
    if (this.activityOfflineService.selectedActivity instanceof AppointmentActivity && this.activityOfflineService.selectedActivity.contacts && this.activityOfflineService.selectedActivity.contacts.length > 0) {
      this.activityOfflineService.selectedActivity.contacts.map(contact => {
        if (this.contactService.getContactByID(contact.ID)) {
          this.contactService.getContactByID(contact.ID).connectionState = ContactMeetingState.NOTJOINED;
        }
      });
    }
  }

  /**
   * Creates a new meeting payload by concatenating data from the form and calling our meeting service initiate meeting fn
   *
   * @private
   * @memberof NewActivityComponent
   */
  async createNewMeeting(subtype?: string) {
    let payload: InitiateMeetingPayload = this._prepareMeetingPayload();
    if (subtype === 'live') {
      payload.location = 'LiveMeet';
      payload.subject = this.selectedEvent && this.selectedEvent.name || 'Live Meeting';
      payload.eventId = this.selectedEvent.id;
    }

    this.isLoaderOn = true;
    try {
      // TODO This flag is just a quick fix for CWD-4761 at the moment. Need to address this appropriately later.
      const shouldWaitForMeetingCreationResponse =
        (this.isLayoverPushView
          && ((this.layoverViewPushedFrom === 'AccountManagementTool' && this.layoverViewPassedData && this.layoverViewPassedData.accountId)
            || (this.layoverViewPushedFrom == 'AccountTool' && this.layoverViewPassedData && this.layoverViewPassedData.id)))
        || (this.currentMasterPage === PageName.CallPlanDetails || this.currentMasterPage === PageName.CallPlanComponent) || this.currentMasterPage === PageName.ScientificActivityPage
        || ((this.currentMasterPage === PageName.ContactDetailsComponent || this.currentMasterPage === PageName.ContactPageComponent) && this.uiService.activeViewNewMeeting != 'customerinquiry')
        || (this.currentMasterPage === PageName.ActivitiesPageComponent && !this.footerService.isActivitiesButton)
        ||(this.currentMasterPage === PageName.CustomerCallPlanDetails || this.currentMasterPage === PageName.CustomerCallPlanPage)
        ||this.layoverViewPushedFrom == 'ProcedureLogFrom'
        || (this.layoverViewPushedFrom === 'MarketingPlanInfoopportunities')
        const fromTools: boolean = (this.isLayoverPushView && (this.currentMasterPage === PageName.AccountPageComponent || this.currentMasterPage === PageName.ContactPageComponent))
        const newMeeting = await this.meetingService.createNewMeeting(payload, shouldWaitForMeetingCreationResponse, !fromTools);
      //this.uiService.showRightPane = true;
      if (subtype === 'live' && this.selectedEvent && !newMeeting.createdOffline) {
        let eventParticipants = this.selectedEvent && await this.msEventService.getParticipantRegistrations(this.selectedEvent.id);
        let eventContacts = eventParticipants && eventParticipants.map(p => this.contactService.getContactByID(p)).filter(p => !!p);
        if (eventContacts && eventContacts.length > 0) {
          newMeeting['contacts'].push(...eventContacts);
          if (!this.device.isOffline && !this.activityOfflineService.hasOfflineMeetingData(newMeeting.ID)) {
            await this.meetingService.addContactsToMeeting(newMeeting, true);
          } else {
            await this.activityOfflineService.upsertMeetingsOfflineData(newMeeting);
          }
        }
      }


      // Set Default Values For IO Config Fields
      this.activityOfflineService.addIoConfigDefaultValuesToActivity(newMeeting);
      if(newMeeting.appConfigFields && newMeeting.appConfigFields.length > 0){
        if (!this.device.isOffline && !this.activityOfflineService.hasOfflineMeetingData(newMeeting.ID)) {
          try {
            await this.meetingService.updateappConfigFields(newMeeting, newMeeting.appConfigFields, true);
          } catch (error) {
            console.error('saveAdditionalConfigFields: ', error);
            this.activityOfflineService.upsertMeetingsOfflineData(newMeeting, false, true);
          }
        }
        else {
          // this.activityService.isActivityUpdate = true;
          await this.activityOfflineService.upsertMeetingsOfflineData(newMeeting);
          this.activityOfflineService.publishActivityEvent({ action: "Create", activity: this.activityOfflineService.selectedActivity });
        }
      }

      if (newMeeting && !this.meetingService.isErrorStartDateCreateMeeting) {
        let data;
        if (!newMeeting.createdOffline) {
          // Online created meeting
          data = { id: newMeeting.ID, ...payload }

          if (this.navService.getPreviousActiveChildNavViewPageName() === PageName.AccountPlanDetailsComponent) {
            let meetingPayload = [{
              activityMeetingId: Guid.create().toString(),
              activityId: newMeeting.ID
            }]
            this.uiService.displayLoader();
            this.accountManagementDataService.saveMeetingsForAccountPlan(this.layoverViewPassedData.ID, meetingPayload).finally(() => {
              this.uiService.dismissLoader();
            })
          }

        } else {
          // Offline created meeting
          data = {
            id: newMeeting.ID,
            subject: newMeeting.subject,
            location: newMeeting.location,
            scheduledStart: newMeeting.scheduledStart,
            scheduledEnd: newMeeting.scheduledEnd,
          };
        }

        this.uiService.setUIServiceData({
          view: 'new-activity', data,
        }); // this is an observable stream

        const isPlanViewFlow: boolean = this.currentMasterPage === PageName.CallPlanDetails || this.currentMasterPage === PageName.CallPlanComponent
          || (this.currentMasterPage === PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans' && this.uiService.activitiesPagePlanTabSelectedPlan === 'goalsPlansTab')
          || this.currentMasterPage === PageName.CustomerCallPlanDetails || this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage;
        // Should check for both cases (when pushed from detail page / opened in split view)
        if (this.isLayoverPushView && !isPlanViewFlow) {
          if (this.layoverViewPushedFrom == 'AccountManagementTool') {
            // if(this.navService.getPreviousActiveChildNavViewPageName() ===   PageName.AccountPlanDetailsComponent){
              newMeeting.accountPlanId = this.layoverViewPassedData.ID;
            // }
            this.activityOfflineService.selectedActivity = newMeeting;
            if (this.layoverViewPassedData && this.layoverViewPassedData.accounts.length == 1) {
              this.activityOfflineService.selectedActivity['accounts'] = [];
              let account = this.accountService.getAccountById(this.layoverViewPassedData.accounts[0].id);
              this.accountService.moveAccountToSelected(account);
              (this.activityOfflineService.selectedActivity as AppointmentActivity).associatedAccountPlans.push({
                accountPlanId: this.layoverViewPassedData.ID,
                accountPlanName: this.layoverViewPassedData.accountPlanName
              });
              if (this.activityOfflineService.selectedActivity instanceof AppointmentActivity) {
                await this.activityOfflineService.upsertMeetingsOfflineData(this.activityOfflineService.selectedActivity);
                if (!this.device.isOffline && !this.activityOfflineService.hasOfflineMeetingData(this.activityOfflineService.selectedActivity.ID)) {
                  await this.meetingService.addAccountsToMeeting(this.activityOfflineService.selectedActivity);
                }
                // Call AccountPlan update service to update appointment ID on the AccountPlan
                if (!newMeeting.ID.includes('offline')) {
                  // (this.layoverViewPassedData as Opportunity).appointmentId = newMeeting.ID;
                  const accPlanPayload = (this.activityOfflineService.selectedActivity as AppointmentActivity).associatedAccountPlans.map(a=> a.accountPlanId);
                  this.meetingService.addAccountPlansToMeeting(this.activityOfflineService.selectedActivity as AppointmentActivity, accPlanPayload).then(async (accPlans) => {

                  });
                }
              }
            }
          }
          //  else if (this.layoverViewPushedFrom == 'MarketingPlanInfoopportunities' && this.layoverViewPassedData) {
          //   newMeeting.marketingBusinessPlanId = this.layoverViewPassedData.ID;
          //   this.activityOfflineService.selectedActivity = newMeeting;
          //   if (this.layoverViewPassedData && this.layoverViewPassedData.accounts.length == 1) {
          //     this.activityOfflineService.selectedActivity['accounts'] = [];
          //     let account = this.accountService.getAccountById(this.layoverViewPassedData.accounts[0].id);
          //     this.accountService.moveAccountToSelected(account);
          //     // (this.activityOfflineService.selectedActivity as AppointmentActivity).associatedAccountPlans.push({
          //     //   accountPlanId: this.layoverViewPassedData.ID,
          //     //   accountPlanName: this.layoverViewPassedData.accountPlanName
          //     // });
          //     if (this.activityOfflineService.selectedActivity instanceof AppointmentActivity) {
          //       await this.activityOfflineService.upsertMeetingsOfflineData(this.activityOfflineService.selectedActivity);
          //       if (!this.device.isOffline && !this.activityOfflineService.hasOfflineMeetingData(this.activityOfflineService.selectedActivity.ID)) {
          //         await this.meetingService.addAccountsToMeeting(this.activityOfflineService.selectedActivity);
          //       }
          //       // Call AccountPlan update service to update appointment ID on the AccountPlan
          //       // if (!newMeeting.ID.includes('offline')) {
          //         // (this.layoverViewPassedData as Opportunity).appointmentId = newMeeting.ID;
          //         // const accPlanPayload = (this.activityOfflineService.selectedActivity as AppointmentActivity).associatedAccountPlans.map(a=> a.accountPlanId);
          //         // this.meetingService.addAccountPlansToMeeting(this.activityOfflineService.selectedActivity as AppointmentActivity, accPlanPayload).then(async (accPlans) => {

          //         // });
          //       // }
          //     }
          //   }

          // }
          else if (this.layoverViewPushedFrom == 'AccountTool' && this.layoverViewPassedData && this.layoverViewPassedData.id) {
            this.activityOfflineService.selectedActivity = newMeeting;
            this.activityOfflineService.selectedActivity['accounts'] = [];
            let account = this.accountService.getAccountById(this.layoverViewPassedData.id);
            this.accountService.moveAccountToSelected(account);
            if (this.activityOfflineService.selectedActivity instanceof AppointmentActivity) {
              if ( this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_populateaccountaddress']) {
                const selectedAddress = await this.accountService.getPrimaryAddressOfAccount(this.activityOfflineService.selectedActivity['accounts'][0].id);
                await this.accountService.addLocationToMeeting(this.activityOfflineService.selectedActivity, selectedAddress);
              }
                await this.activityOfflineService.upsertMeetingsOfflineData(this.activityOfflineService.selectedActivity);
              if (!this.device.isOffline && !this.activityOfflineService.hasOfflineMeetingData(this.activityOfflineService.selectedActivity.ID)) {
                await this.meetingService.addAccountsToMeeting(this.activityOfflineService.selectedActivity);
              }
              let updateContactsForMeetingFlag: boolean = false;
              let contactsToUpdate: Contact[] = [];
              //Check for add
              let affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([account]);
              if (affiliatedContacts.length === 1) {
                // Add the only affiliated contact
                updateContactsForMeetingFlag = true;
                contactsToUpdate = affiliatedContacts;
              }
              if (updateContactsForMeetingFlag) {
                await this._updateContactsOfSelectedMeeting(contactsToUpdate);
              }
            }
          } else if ((this.layoverViewPushedFrom == 'OpportunityManagementTool' || this.layoverViewPushedFrom == 'MarketingPlanInfoopportunities')&& this.layoverViewPassedData) {
            this.activityOfflineService.selectedActivity = newMeeting;
            this.activityOfflineService.selectedActivity['accounts'] = [];
            (this.activityOfflineService.selectedActivity as AppointmentActivity).regardingObjectId = this.layoverViewPassedData.ID;
            (this.activityOfflineService.selectedActivity as AppointmentActivity).regardingObjectIdValue = this.layoverViewPassedData.opportunityName;
            if (this.layoverViewPassedData.accountID) {
              let account = this.accountService.getAccountById(this.layoverViewPassedData.accountID);
              this.accountService.moveAccountToSelected(account);
            }
            (this.activityOfflineService.selectedActivity as AppointmentActivity).associatedOpportunities.push({
              opportunityId: this.layoverViewPassedData.ID,
              opportunityName: this.layoverViewPassedData.opportunityName
            });

            const opportunity:Opportunity = this.layoverViewPassedData ? this.layoverViewPassedData : null;
            if(opportunity){
              // Copy Contacts From Opportunity
              let contactsToUpdate: Contact[] = [];
              if(this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_PRIMARY_TARGET) && opportunity.primaryTargetValue == OpportunityPrimaryTraget.CONTACT && opportunity.contactID){
                let con = this.contactService.getContactByID(opportunity.contactID);
                if(con){
                  contactsToUpdate.push(con)
                }
              }
              if(opportunity.stakeholders){
                opportunity.stakeholders.forEach(stakeholder=> {
                  let con = this.contactService.getContactByID(stakeholder.ID);
                  if(con && !contactsToUpdate.some(a=> a.ID == con.ID)){
                    contactsToUpdate.push(con)
                  }
                })
              }
              if (contactsToUpdate.length > 0) {
                await this._updateContactsOfSelectedMeeting(contactsToUpdate);
              }

              // Copy Products From Opportunity
              if(opportunity.products.length && !this.authService.user.buConfigs?.indskr_disableproductsection){
                opportunity.products.forEach(prod=> {
                  if(!(this.activityOfflineService.selectedActivity as AppointmentActivity).products){
                    let brands: Array<Brand> = JSON.parse(JSON.stringify(this.brandService.brands.filter(b=> !b.productApplicability.length || b.productApplicability.includes('100000000'))));
                    let brand = brands.filter(value => {
                      return value.isSelected === undefined || !value.isSelected;
                    });
                    brand.forEach((el, index) => el.priority = brands.length + index + 1);
                    //sort the products and return it to UI
                    //now sort the products based on the sequence(product priority) , if the sequence is not available default
                    brands.sort((item1, item2) => {
                      //sort criteria
                      if (item1.priority && item2.priority) {
                        return item1.priority - item2.priority;
                      }
                    });
                    (this.activityOfflineService.selectedActivity as AppointmentActivity).products = brands;
                  }
                  let productIndexInSelectedActivity = (this.activityOfflineService.selectedActivity as AppointmentActivity).products.findIndex(q => q.ID == prod.productID);
                  if (productIndexInSelectedActivity >= 0) {
                    let foundProduct = (this.activityOfflineService.selectedActivity as AppointmentActivity).products[productIndexInSelectedActivity];
                    if (!foundProduct.isSelected) {
                      foundProduct.isSelected = true;
                    }
                  }
                });

                if (!this.device.isOffline) {
                  await this.meetingService.updateMeetingProductKeyMessages(this.activityOfflineService.selectedActivity as AppointmentActivity);
                  this.activityOfflineService.selectedActivity.products = this.activityOfflineService.selectedActivity.products ? this.activityOfflineService.selectedActivity.products.sort((item1: any, item2: any) => {
                    if (item1.priority && item2.priority) {
                      return item1.priority - item2.priority;
                    }
                  }) : []
                }
              } else {
                if (!(this.activityOfflineService.selectedActivity as AppointmentActivity).products) {
                  let brands: Array<Brand> = JSON.parse(JSON.stringify(this.brandService.brands.filter(b=> !b.productApplicability.length || b.productApplicability.includes('100000000'))));
                  (this.activityOfflineService.selectedActivity as AppointmentActivity).products = brands;
                }
              }

              // Copy Procedures From Opportunity
              if(this.authService.hasFeatureAction(FeatureActionsMap.MEETING_PROCEDURE) && opportunity.surgeryId){
                let foundProcedure = this.surgeryOrderActivityDataService.procedureTrackerProductHierarchies ? this.surgeryOrderActivityDataService.procedureTrackerProductHierarchies.find(s => s.surgeryId == opportunity.surgeryId) : null;
                if (foundProcedure) {
                  const activityProcedure = new Brand({
                    productid: foundProcedure.surgeryId,
                    name: foundProcedure.surgeryName,
                    productStructure: 3
                  });
                  (this.activityOfflineService.selectedActivity as AppointmentActivity).activityProcedures.push(activityProcedure);
                  if(!this.device.isOffline){
                    await this.meetingService.updateMeetingProcedures( (this.activityOfflineService.selectedActivity as AppointmentActivity));
                  }
                }
              }
            }
            if (this.activityOfflineService.selectedActivity instanceof AppointmentActivity) {
              await this.activityOfflineService.upsertMeetingsOfflineData(this.activityOfflineService.selectedActivity);
              if (!this.device.isOffline && !this.activityOfflineService.hasOfflineMeetingData(this.activityOfflineService.selectedActivity.ID) && this.layoverViewPassedData.accountID) {
                await this.meetingService.addAccountsToMeeting(this.activityOfflineService.selectedActivity);
              }
              // Call Opportunity update service to update appointment ID on the opportunity
              if (!newMeeting.ID.includes('offline')) {
                (this.layoverViewPassedData as Opportunity).appointmentId = newMeeting.ID;
                // await this.opportunityDataService.updateOpportunity({ onDynamics: !this.device.isOffline, onLocalDatabase: false, onLocalCopy: false, operationDetail: { code: 'updateappointmentid', message: 'Link Appointment to Opportunity' } }, [this.layoverViewPassedData]).then(success => {
                //   //Handle successfull response
                // }).catch(async error => {
                //   // Handle error occured
                // });
                const oppPayload = (this.activityOfflineService.selectedActivity as AppointmentActivity).associatedOpportunities.map(a=> a.opportunityId);
                this.meetingService.addOpportunitiesToMeeting(this.activityOfflineService.selectedActivity as AppointmentActivity, oppPayload).then(async (opportunities) => {

                });
              }
            }
          }
          else if (this.layoverViewPushedFrom == 'ProcedureLogFrom') {
            this.activityOfflineService.selectedActivity = newMeeting;
            if (this.activityOfflineService.selectedActivity instanceof AppointmentActivity) {
              await this.activityOfflineService.upsertMeetingsOfflineData(this.activityOfflineService.selectedActivity);
              if (this.device.isOffline) {
                (this.layoverViewPassedData as SurgeryOrderActivity).offlineMeetingId = newMeeting.ID;
              } else {
                (this.layoverViewPassedData as SurgeryOrderActivity).appointmentId = newMeeting.ID;
              }
              const action = {
                onDynamics: !this.device.isOffline,
                onLocalDatabase: true,
                onLocalCopy: true,
                operationDetail: {
                  code: 'surgerOrderUpdate',
                  message: 'surgerOrderUpdate',
                }
              };
              await this.uiService.displayLoader();
              let hasOfflineChanges = (this.layoverViewPassedData as SurgeryOrderActivity).pendingPushToDynamics || false;
              (this.layoverViewPassedData as SurgeryOrderActivity).pendingPushToDynamics = true;
              await this.surgeryOrderActivityDataService.updateOrderActivity(action, [(this.layoverViewPassedData)], new Date().getTime(), hasOfflineChanges);
              await this.uiService.dismissLoader();
            }
          }

          else if (this.layoverViewPushedFrom == 'ScientificActivityPage') {
            this.activityOfflineService.selectedActivity = newMeeting;
            if (this.contact) {
              this.activityOfflineService.selectedActivity['contacts'].push(this.contactService.contactInformation)
            }
            if (this.activityOfflineService.selectedActivity instanceof AppointmentActivity) {
              if (this.device.isOffline) {
                await this.activityOfflineService.upsertMeetingsOfflineData(this.activityOfflineService.selectedActivity);
              }
              if (!this.device.isOffline && !this.activityOfflineService.hasOfflineMeetingData(this.activityOfflineService.selectedActivity.ID)) {
                await this.meetingService.addContactsToMeeting(this.activityOfflineService.selectedActivity);
              }
            }
          } else if (this.layoverViewPushedFrom == 'PresentationToolsPage') {
            this.activityOfflineService.selectedActivity = newMeeting;
            if (this.pres) {

              if (this.activityOfflineService.selectedActivity instanceof AppointmentActivity) {
                let loader = await this.loadingController.create();
                loader.present();
                if (!this.activityOfflineService.selectedActivity['presentations']) this.activityOfflineService.selectedActivity['presentations'] = [];
                this.activityOfflineService.selectedActivity['presentations'].push(this.pres);
                await this.activityOfflineService.upsertMeetingsOfflineData(this.activityOfflineService.selectedActivity);
                if (!this.device.isOffline && !this.activityOfflineService.hasOfflineMeetingData(this.activityOfflineService.selectedActivity.ID)) {
                  try {
                    await this.meetingService.addContentToMeeting(this.activityOfflineService.selectedActivity.presentations, this.activityOfflineService.selectedActivity)
                  } catch (error) {
                    this.notificationService.notify(this.translate.instant('ERROR_UPDATING_MEETING_PRESENTATIONS'), 'New Activity');
                  }
                }
                loader.dismiss();
              }
            }
          } else if (this.layoverViewPushedFrom == 'ContactPageComponent') {
            await this.addContactToMeeting(newMeeting);
          } else if (this.layoverViewPushedFrom == PageName.SetBookingActivityDetailsComponent) {
            let currentSetBookingActivity = _.cloneDeep(this.activityOfflineService.selectedActivity) as SetBookingActivity;
            let contact: Contact = undefined;
            let account: Account = undefined;
            let requestSuccess;
            this.activityOfflineService.prevSelectedActivity = this.activityOfflineService.selectedActivity;
            this.activityOfflineService.selectedActivity = newMeeting;
            if(currentSetBookingActivity && currentSetBookingActivity.rawData) {
              let accountId = currentSetBookingActivity.rawData['_indskr_accountnamesoldto_value'];
              if(accountId) account = this.accountService.getAccountById(accountId);
              let contactId = currentSetBookingActivity.rawData['_indskr_surgeoncontact_value'];
              if(contactId) contact = this.contactService.getContactByID(contactId);
            }
            if(contact) {
              this.activityOfflineService.selectedActivity['contacts'].push(contact);
              if(contact.addressesList && contact.addressesList.length){
                const primaryAddress = contact.addressesList.find((address) => address.isPrimary).compositeAdd;
                this.activityOfflineService.selectedActivity.location = primaryAddress;
              }
            }
            if(account) {
              this.accountService.moveAccountToSelected(account);
              let affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([account]);
              if (affiliatedContacts.length === 1) {
                this.activityOfflineService.selectedActivity['contacts'].push(...affiliatedContacts);
              }
              if (!this.device.isOffline && !this.activityOfflineService.hasOfflineMeetingData(newMeeting.ID)) {
                await this.meetingService.addAccountsToMeeting(this.activityOfflineService.selectedActivity as AppointmentActivity);
              }
            }
            if(this.activityOfflineService.selectedActivity['contacts']) {
              requestSuccess = await this.meetingService.addContactsToMeeting(this.activityOfflineService.selectedActivity, true);
              this.contentMatchingService.isNewContactAddedToMeeting = true;
            }
            let payload = new UpdateMeetingPayload(
              this.activityOfflineService.selectedActivity.subject,
              this.activityOfflineService.selectedActivity.location
            );
            if (!requestSuccess || this.device.isOffline || this.activityOfflineService.hasOfflineMeetingData(this.activityOfflineService.selectedActivity.ID)){
                await this.activityOfflineService.upsertMeetingsOfflineData(this.activityOfflineService.selectedActivity as AppointmentActivity);
            } else{
                try {
                  await this.meetingService.updateMeeting((this.activityOfflineService.selectedActivity as AppointmentActivity), payload);
                } catch (error) {
                  console.error('Set Booking DetailsComponent: addNewMeeting: ', error);
                  this.activityOfflineService.upsertMeetingsOfflineData(this.activityOfflineService.selectedActivity as AppointmentActivity, false, true);
                }
            }
            // this.uiService.agendaRefreshRequired = true;
            // this.events.publish("refreshAgenda");
            // this.activityOfflineService.signalActivityConflictCheck$.next(this.activityOfflineService.selectedActivity);
            // this.meetingStructureService.createEmbeddedInteractions(<AppointmentActivity>this.activityOfflineService.selectedActivity);
            // this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent, {from: PageName.SetBookingActivityDetailsComponent});
            // if(this.setBooking) this.activityOfflineService.prevSelectedActivity = this.setBooking;
            this.activityService.activityDetailsLoaded = true;
          }
        } else if (isPlanViewFlow) {

          let contactId;
          let accountId;

          if(this.callPlanOfflineService.selectedCallPlan){
            contactId = this.callPlanOfflineService.selectedCallPlan?.contactId || '';
            accountId = this.callPlanOfflineService.selectedCallPlan?.accountId || '';
          }

          const contact = this.contactService.getContactByID(contactId);
          //let contact = this.contactService.getContactByID(this.contactService.contactInformation['ID']);

          // create meeting from call plan - account
          if (accountId) {
            const selectedAccount = this.accountService.getAccountById(accountId);
            this.activityOfflineService.selectedActivity = newMeeting;

            if (selectedAccount) {
              let accountsToUpdate: Account[] = [];
              accountsToUpdate = [selectedAccount];
              await this._updateAccountsOfSelectedMeeting(accountsToUpdate);
            }

            this.uiService.showNewActivity = false;
              this.uiService.prevView = 'callPlanDetails';
              if (this.currentMasterPage === PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans') {
                this.uiService.activeView = 'ActivitiesPageRightPaneNav';
                this.uiService.showRightPane = true;
              } else {
                this.uiService.activeView = 'activityTimelineDetails';
                this.uiService.showRightPane = false;
              }
              this.navService.popChildNavPageWithPageTracking();
              if (this.currentMasterPage === PageName.CallPlanComponent) {
                this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.CallPlanComponent, { from: PageName.CallPlanDetails });
              } else {
                this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.CustomerCallPlanPage, { from: PageName.CustomerCallPlanDetails });
              }
              this.uiService.showCancelDoneOnActivityDetails = true;
              this.footerService.initButtons(FooterViews.Activities);
          } else if (contact) {
            newMeeting['contacts'].push(contact);
            const requestSuccess = await this.meetingService.addContactsToMeeting(newMeeting, true);
            this.contentMatchingService.isNewContactAddedToMeeting = true;
            // TODO: Only hybrid app does offline stuff in future
            if (!requestSuccess/* && this.device.isHybridApp*/) {
              await this.activityOfflineService.upsertMeetingsOfflineData(newMeeting);
            }
            this.activityOfflineService.selectedActivity = newMeeting;
            if (contact.addressesList && contact.addressesList.length && !this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) &&  (this.authService.user.buConfigs && !this.authService.user.buConfigs['indskr_populateaccountaddress'])) {
              await this.contactService.setLocationField(contact);
              // const primaryAddress = contact.addressesList.find((address) => address.isPrimary).compositeAdd;
              // this.activityOfflineService.selectedActivity.location = primaryAddress;
              let payload = new UpdateMeetingPayload(
                this.activityOfflineService.selectedActivity.subject,
                this.activityOfflineService.selectedActivity.location,
                this.activityOfflineService.selectedActivity.scheduledStart,
                this.activityOfflineService.selectedActivity.scheduledEnd
              );
              if (this.device.isOffline) {
                await this.activityOfflineService.upsertMeetingsOfflineData(this.activityOfflineService.selectedActivity as AppointmentActivity);
              } else {
                await this.meetingService.updateMeeting((this.activityOfflineService.selectedActivity as AppointmentActivity), payload);
              }
            }
            this.uiService.showNewActivity = false;
            this.uiService.prevView = 'callPlanDetails';
            if (this.currentMasterPage === PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans') {
              this.uiService.activeView = 'ActivitiesPageRightPaneNav';
              this.uiService.showRightPane = true;
            } else {
              this.uiService.activeView = 'activityTimelineDetails';
              this.uiService.showRightPane = false;
            }
            // this.callPlanService.callPlanPageView = 'CallPlanNewMeetingDetails';
            this.navService.popChildNavPageWithPageTracking();
            if (this.currentMasterPage === PageName.CallPlanComponent) {
              this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.CallPlanComponent, { from: PageName.CallPlanDetails });
            } else {
              this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.CustomerCallPlanPage, { from: PageName.CustomerCallPlanDetails });
            }
            this.uiService.showCancelDoneOnActivityDetails = true;
            this.footerService.initButtons(FooterViews.Activities);
          }
        } else if (this.currentMasterPage === PageName.ActivitiesPageComponent && !this.footerService.isActivitiesButton) {
          if (this.contactService.contactInformation) { //add contact to new activity if created from xperiences
            let contact = this.contactService.getContactByID(this.contactService.contactInformation['ID']);
            if (contact) {
              newMeeting['contacts'].push(contact);
              const requestSuccess = await this.meetingService.addContactsToMeeting(newMeeting, true);
              if (!requestSuccess) {
                await this.activityOfflineService.upsertMeetingsOfflineData(newMeeting);
              }
              this.activityOfflineService.selectedActivity = newMeeting;
              this.uiService.showNewActivity = false;
              this.uiService.showCancelDoneOnActivityDetails = true;
              this.footerService.initButtons(FooterViews.Activities);
            }
          }
        }
        else if (this.currentMasterPage === PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab !== 'plans') {
          // Make it fall under here if the created activity just needs to be marked as selected.

          // Clear the Join status for participants of the previous meeting CWD-2538
          this.clearContactJoinStatusOfCurrentSelectedMeeting();
          this.activityOfflineService.selectedActivity = newMeeting;

          //Add contacts from selected Activity to new created follow up activity
          if (this.followUpMeetingContacts.length > 0) {
            if (this.activityOfflineService.selectedActivity instanceof AppointmentActivity) {
              this.activityOfflineService.selectedActivity['contacts'] = [];
              this.followUpMeetingContacts.map(contact => {
                this.contactService.moveContactToSelected(contact);
              });

              const requestSuccess = await this.meetingService.addContactsToMeeting(newMeeting, true);
              // TODO: Only hybrid app does offline stuff in future
              if (!requestSuccess/* && this.device.isHybridApp*/) {
                await this.activityOfflineService.upsertMeetingsOfflineData(newMeeting);
              }

              if (this.uiService.activitiesPageTab == 'agenda') {
                this.activityOfflineService.selectedActivityOnAgenda = this.activityOfflineService.selectedActivity;
              }
            }

            this.contactService.followUpMeetingContacts = [];
          }
          this.activityOfflineService.selectedActivity = newMeeting;
          this.activityOfflineService.isNewActivityFlow = true;
          //TC-728
          // this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent, { isNewlyCreatedActivity: true });
          if (this.device.isMobileDevicePortrait && this.navService.getActiveChildNavViewPageName() === PageName.NewActivityComponent) {
            this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent, { isNewlyCreatedActivity: true });
          }
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.uiService.showNewActivity = false;
          this.uiService.showRightPane = true;
        }
        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
          this.activityOfflineService.signalActivityConflictCheck$.next(this.activityOfflineService.selectedActivity);
        }
        if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
        this.meetingStructureService.createEmbeddedInteractions(newMeeting);
        this.activityService.activityDetailsLoaded = true;
        // this.meetingStructureService.addFilteredContentToMeeting();
      }
      else {
        console.error('createNewMeeting: No response!');
        if (!this.meetingService.isErrorStartDateCreateMeeting) {
          // Failed to create a meeting on non app environment. Alert user.
          this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_ERROR_CREATING_NEW_MEETING'), 'New Activity', 'top', ToastStyle.DANGER);
        }
        if (!this.meetingService.isErrorStartDateCreateMeeting) {
          this.meetingStructureService.createEmbeddedInteractions(null);
        }
        if (this.navService.getPreviousActiveChildNavViewPageName() != PageName.NothingSelectedView) {
          this.activityOfflineService.activitySource = null;
          this.uiService.showNewActivity = false;
          setTimeout(() => {
            if (this.uiService.activitiesPagePlanTabSelectedPlan == 'goalsPlansTab'
              && this.navService.getPreviousActiveChildNavViewPageName() == PageName.AccountPlanDetailsComponent) {
              // Nothing to do here
            } else if (this.layoverViewPushedFrom == 'OpportunityManagementTool') {
              if (this.navService.getActiveChildNavViewPageName() == PageName.ActivitiesDetailsPaneComponent) {
                this.navService.popChildNavPageWithPageTracking();
              }
            } else {
              this.navService.popChildNavPageWithPageTracking();
            }
            // Update Footer
            if (this.navService.getActiveChildNavViewPageName() == PageName.AccountDetailsComponent
              || this.navService.getActiveChildNavViewPageName() == PageName.AccountPlanDetailsComponent
              || this.navService.getActiveChildNavViewPageName() == PageName.ScientificActivityDetail
              || (this.navService.getActiveChildNavViewPageName() == PageName.ContactDetailsComponent && this.uiService.contactDetailsSegment != 'capture')) {
              this.footerService.initButtons(FooterViews.None);
            }
            if ((this.navService.getActiveChildNavViewPageName() == PageName.ContactDetailsComponent && this.uiService.contactDetailsSegment == 'capture')) {
              this.footerService.initButtons(FooterViews.ConsentCapture);
            }
            this.navService.setChildNavRightPaneView(true);
            this.uiService.showRightPane = false;

          }, 100);
        }
        this.isLoaderOn = false;
        this.uiService.dismissLoader();
        this.meetingService.isErrorStartDateCreateMeeting = false;
        return;
      }
    }
    catch (err) {
      console.log('Error occured while creating new meeting activity' + err);
      this.uiService.showCancelDoneOnActivityDetails = true;
      this.meetingStructureService.createEmbeddedInteractions(null);
    }
    this.isLoaderOn = false;
    this.uiService.dismissLoader();
    this.uiService.showNewActivity = false;
  }

  private async createNewAccountVisit() {
    this.isLoaderOn = true;
    // await this.uiService.displayLoader();
    let success = false;
    let newAccountVisit: AppointmentActivity;

    try {
      const offlineMeetingId = OFFLINE_ID_PREFIX + new Date().getTime();
      newAccountVisit = new AppointmentActivity({
        subject: this.translate.instant('ACCOUNT_VISIT'),
        scheduledstart: new Date(this.startTime).getTime(),
        scheduledend: new Date(this.endTime).getTime(),
        offlineMeetingId,
        indskr_positionid: this.authService.user.xPositionID,
        statecode: 0,
        statuscode: 1,
        activityid: offlineMeetingId,
        indskr_ownerid: this.authService.user.systemUserID,
        indskr_ownerfullname: this.authService.user.displayName,
        indskr_isparentcall: true,
        indskr_meetingtype: 600000000,
      });
      // Add account if creating from acount tool
      if (this.isLayoverPushView) {
        if (this.layoverViewPushedFrom == 'AccountTool' && this.layoverViewPassedData && this.layoverViewPassedData.id) {
          const account = this.accountService.getAccountById(this.layoverViewPassedData.id);
          newAccountVisit.accounts = [account];
          if(this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_populateaccountaddress']){
            const selectedAddress = await this.accountService.getPrimaryAddressOfAccount(newAccountVisit['accounts'][0].id);
            await this.accountService.addLocationToMeeting(newAccountVisit, selectedAddress);
          }
        }
      }

      const activityTypes = this.activityOfflineService.configuredActivityTypes(MeetingActivityTypeCode.MEETING, FormatType.ACCOUNT_VISIT);
      if (activityTypes.length !== 0) {
        const defaultActivityType = activityTypes.length == 1 ? activityTypes[0] : activityTypes.find(at => at.indskr_default);
        if (defaultActivityType) {
          newAccountVisit.indskr_activitytype = defaultActivityType.indskr_activitytypeid;
          newAccountVisit.activityTypeName = defaultActivityType.indskr_name;
          // if (defaultActivityType.indskr_name === this.translate.instant("INSTORE")) {
            newAccountVisit.subject = (_.isEmpty(newAccountVisit.accountString) ? '' : ((newAccountVisit.accountString + ' - '))) + this.translate.instant('ACCOUNT_VISIT') + ' - ' + defaultActivityType.indskr_name;
          // }
        }
      }

      success = await this.meetingService.crudMeetingsOnlineWithOfflineUploadEndpoint([newAccountVisit]);
    } catch (error) {
      console.error('createNewAccountVisit: ', error);
    }

    // Post processing
    if (success) {
      this.clearContactJoinStatusOfCurrentSelectedMeeting();
      this.activityOfflineService.selectedActivity = newAccountVisit;

      if (this.currentMasterPage === PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab !== 'plans') {
        this.activityOfflineService.isNewActivityFlow = true;

        if (this.device.isMobileDevicePortrait && this.navService.getActiveChildNavViewPageName() === PageName.NewActivityComponent) {
          this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent, { isNewlyCreatedActivity: true });
        }
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
      }

      if (!this.uiService.toolsActivityActive) {
        this.events.publish("refreshAgenda");
      } else {
        this.uiService.agendaRefreshRequired = true;
        this.activityOfflineService.signalActivityConflictCheck$.next(this.activityOfflineService.selectedActivity);
      }

      if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');

      this.meetingStructureService.createEmbeddedInteractions(newAccountVisit);
      this.activityService.activityDetailsLoaded = true;
      this.uiService.showNewActivity = false;

      if (this.agendaFooterService.actSegment === 'agenda' && this.agendaFooterService.planTabSegment === 'planTabTodo') {
        if (this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.status !== 3 && this.activityOfflineService.selectedActivity.status !== 4) {
          this.events.publish('aciviiyCreatedRefreshTODO', this.activityOfflineService.selectedActivity);
        }
      }

      if (this.isLayoverPushView && this.layoverViewPushedFrom) {
        this.navService.popChildNavPageWithPageTracking();
        this.uiService.showCancelDoneOnActivityDetails = true;

        if (this.layoverViewPushedFrom == 'AccountTool') {
          this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.AccountPageComponent, { from: 'AccountTool', isNewlyCreatedActivity: true });
        }
      } else if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab !== 'plans') {
        this.uiService.showCancelDoneOnActivityDetails = false;
      }
      this.activityOfflineService.isNewMeetingFlow = true;
      this.activityOfflineService.selectedActivityAtHome = this.activityOfflineService.selectedActivity;
    } else {
      this.notificationService.notify(
        this.translate.instant('ACCOUNT_VISIT_ERROR_CREATING_NEW_ACCOUNT_VISIT'),
        'New Activity',
        'top',
        ToastStyle.INFO
      );
    }

    this.isLoaderOn = false;
    // this.uiService.dismissLoader();
  }

  private async _updateContactsOfSelectedMeeting(contacts: Contact[]) {
    if (this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.Appointment && !(this.activityOfflineService.selectedActivity as AppointmentActivity).isCompleted) {
      this.activityOfflineService.selectedActivity['contacts'] = [];
      contacts.forEach(contact => {
        this.contactService.moveContactToSelected(contact);
      });
      const isAutoSubjectEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT);
      const isAcinoBaseUser = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT);
      let activity = (this.activityOfflineService.selectedActivity as AppointmentActivity);
      let isSubjectUpdated = false;
      if (isAutoSubjectEnabled || isAcinoBaseUser) {
        let subject: string = this.translate.instant('MEETING');
        const subPrefix = [subject, activity.activityTypeName, activity.meetingOwnerName].filter(Boolean).join(' - ');
        const selectedContacts = Array.isArray(activity.contacts)
          ? activity.contacts
          : [];
        if (isAcinoBaseUser) {
          subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}${this.translate.instant('VISIT')}`;
        }
        switch (selectedContacts.length) {
          case 0:
            if (!isAcinoBaseUser)
              subject = [this.translate.instant('MEETING'), activity.activityTypeName, activity.meetingOwnerName].filter(Boolean).join(" - ");
            break;
          case 1:
            subject = selectedContacts[0].fullName + ' - ' + subPrefix;
            if (isAcinoBaseUser) {
              subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}${this.translate.instant('VISIT')} - ${selectedContacts[0].fullName}`;
            }
            break;
          default:
            if (selectedContacts.length > 1) {
              selectedContacts.sort((contactA, contactB): number => {
                let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
                let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
                if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
                if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;

                return 0;
              });
              subject = `${selectedContacts[0].fullName} + ${selectedContacts.length - 1}` + ' - ' + subPrefix;
              if (isAcinoBaseUser) {
                subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}${this.translate.instant('VISIT')} - ${selectedContacts[0].fullName}`;
              }
            }
        }
        if (this.activityOfflineService.selectedActivity.subject !== subject) {
          this.activityOfflineService.selectedActivity.subject = subject;
          isSubjectUpdated = true;
        }

      }

      if (this.activityOfflineService.selectedActivity.location !== "LiveMeet") {
        if (!this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) &&  (this.authService.user.buConfigs && !this.authService.user.buConfigs['indskr_populateaccountaddress'])) {
          await this.contactService.setLocationField(contacts);
        }
      }

      if (!this.device.isOffline && !this.activityOfflineService.hasOfflineMeetingData(this.activityOfflineService.selectedActivity.ID)) {
        await this.meetingService.addContactsToMeeting(this.activityOfflineService.selectedActivity, true);
        this.events.publish('contentmatching:refresh');
        if (isSubjectUpdated) {
          const payload = new UpdateMeetingPayload(
            this.activityOfflineService.selectedActivity.subject,
            this.activityOfflineService.selectedActivity.location,
            this.activityOfflineService.selectedActivity.scheduledStart,
            this.activityOfflineService.selectedActivity.scheduledEnd
          );
          try {
            await this.meetingService.updateMeeting((this.activityOfflineService.selectedActivity as AppointmentActivity), payload);
          } catch (error) {
            console.error('AccountListComponent: _updateContactsOfSelectedMeeting: ', error);
            await this.activityOfflineService.upsertMeetingsOfflineData((this.activityOfflineService.selectedActivity as AppointmentActivity), false, true);
          }
        }
      } else {
        await this.activityOfflineService.upsertMeetingsOfflineData((this.activityOfflineService.selectedActivity as AppointmentActivity));
      }
    }
  }

  private async addContactToMeeting(newMeeting: AppointmentActivity) {
    if (this.contactService.contactInformation) { //add contact to new activity if created from contact profile
      let contact = this.contactService.getContactByID(this.contactService.contactInformation['ID']);
      if (contact) {
        if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
          //HCP Name - Activity - Format - Rep name
          newMeeting.subject = [contact.fullName, this.translate.instant('MEETING'), newMeeting.activityTypeName, newMeeting.meetingOwnerName].filter(Boolean).join(' - ');
        }
        newMeeting['contacts'].push(contact);
        const requestSuccess = await this.meetingService.addContactsToMeeting(newMeeting, true);
        this.contentMatchingService.isNewContactAddedToMeeting = true;
        // TODO: Only hybrid app does offline stuff in future
        if (!requestSuccess /* && this.device.isHybridApp*/) {
          await this.activityOfflineService.upsertMeetingsOfflineData(newMeeting);
        }
        this.activityOfflineService.selectedActivity = newMeeting;
        if (contact.addressesList && contact.addressesList.length && !this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) && (this.authService.user.buConfigs && !this.authService.user.buConfigs['indskr_populateaccountaddress'])) {
          await this.contactService.setLocationField(this.contactService.contactInformation);
          // const primaryAddress = contact.addressesList.find((address) => address.isPrimary);
          // if (primaryAddress) {
          //   this.activityOfflineService.selectedActivity.location = primaryAddress.compositeAdd;
          // }
          let payload = new UpdateMeetingPayload(
            this.activityOfflineService.selectedActivity.subject,
            this.activityOfflineService.selectedActivity.location,
            this.activityOfflineService.selectedActivity.scheduledStart,
            this.activityOfflineService.selectedActivity.scheduledEnd
          );
          if (this.device.isOffline) {
            await this.activityOfflineService.upsertMeetingsOfflineData(this.activityOfflineService.selectedActivity as AppointmentActivity);
          } else {
            await this.meetingService.updateMeeting((this.activityOfflineService.selectedActivity as AppointmentActivity), payload);
          }
        }
         // To Add/Remove affiliated accounts for meeting
        let updateAccountsForMeetingFlag: boolean = false;
        let accountsToUpdate: Account[] = [];
        //Check for add
        let affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting([contact]);
        if (affiliatedAccounts.length === 1) {
          // Add the only affiliated contact
          updateAccountsForMeetingFlag = true;
          accountsToUpdate = affiliatedAccounts;
        }else if(contact.primaryAccount){
          let aff = affiliatedAccounts.filter(af => af.id == contact.primaryAccount.id)
          updateAccountsForMeetingFlag = true;
          accountsToUpdate.push(...aff);
        }
        if (updateAccountsForMeetingFlag) {
          await this._updateAccountsOfSelectedMeeting(accountsToUpdate);
        }
        this.uiService.showNewActivity = false;
        this.uiService.showCancelDoneOnActivityDetails = true;
        this.footerService.initButtons(FooterViews.Activities);
      }
    }
  }

  private async _updateAccountsOfSelectedMeeting(accounts: Account[]) {
    if (this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.Appointment && !(this.activityOfflineService.selectedActivity as AppointmentActivity).isCompleted) {
      this.activityOfflineService.selectedActivity['accounts'] = [];
      accounts.forEach(account => {
        this.accountService.moveAccountToSelected(account);
      });

      if (!this.device.isOffline && !this.activityOfflineService.hasOfflineMeetingData(this.activityOfflineService.selectedActivity.ID)) {
        try {
          await this.meetingService.addAccountsToMeeting((this.activityOfflineService.selectedActivity as AppointmentActivity));
            if(this.authService.user.buConfigs['indskr_populateaccountaddress']){
              const selectedAddress = await this.accountService.getPrimaryAddressOfAccount(this.activityOfflineService.selectedActivity['accounts'][0].id);
              await this.accountService.addLocationToMeeting(this.activityOfflineService.selectedActivity, selectedAddress);
            }
        } catch (error) {
          console.error('ContactListComponent: _updateAccountsOfSelectedMeeting: ', error);
          await this.activityOfflineService.upsertMeetingsOfflineData(this.activityOfflineService.selectedActivity as AppointmentActivity, false, true);
        }
      } else {
        await this.activityOfflineService.upsertMeetingsOfflineData(this.activityOfflineService.selectedActivity as AppointmentActivity);
      }
    }
  }

  private _preparePhoneCallPayload(): InitiatePhoneCallPayload {

    const phoneCallStartTime: number = new Date(this.startTime).getTime();
    const phoneCallEndTime: number = new Date(this.endTime).getTime();
    let subject = 'Phone Call'//this.translate.instant('NEW_ACTIVITY_PHONECALL');
    if (this.followupMeetingSubject && this.followupMeetingSubject.length > 0) {
      subject = this.followupMeetingSubject
    }
    else if (this.currentMasterPage === PageName.ContactPageComponent && this.contactService.contactInformation && this.contactService.contactInformation.fullName) {
      subject = `${this.contactService.contactInformation.fullName} - ${this.translate.instant('NEW_ACTIVITY_PHONECALL')}`;
    }

    const payload: InitiatePhoneCallPayload = new InitiatePhoneCallPayload(
      phoneCallStartTime,
      phoneCallEndTime,
      subject,
      OFFLINE_PHONE_CALL_ID_PREFIX + new Date().getTime(),
      0,
      this.device._isOffline && this.navService.getPreviousActiveChildNavViewPageName() === PageName.AccountPlanDetailsComponent ? this.layoverViewPassedData.ID : undefined
    );

    return payload;
  }

  private _prepareMeetingPayload(): InitiateMeetingPayload {
    const start = this.startTime;
    const end = this.endTime;
    // const meetingDate: string = new Date(start).toDateString();
    // const meetingTime: string = new Date(start).toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    let meetingStartTime: number = new Date(start).getTime();
    let meetingEndTime: number = new Date(end).getTime();
    const isAcinoBaseUser = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT);
    const activityTypes = this.activityOfflineService.configuredActivityTypes(MeetingActivityTypeCode.MEETING, FormatType.HCP_MEETING);
    const defaultActivityType = activityTypes ? activityTypes.length == 1 && activityTypes[0].indskr_mandatory ? activityTypes[0] : activityTypes.find(at => at.indskr_default) : null;
    let kitBookingId = '';

    // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
    if(this.secondaryInfoService.isAppointmentSecInfoConfigured)
      this.subject = this.translate.instant('MEETING');
    // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
    else if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT))
      this.subject = this.translate.instant('MEETING') + ' - ' + this.authService.user.displayName;
    else
      this.subject = this.translate.instant('MEETING');
    if (isAcinoBaseUser) {
      this.subject = this.translate.instant('VISIT');
    }
    if (((this.currentMasterPage === PageName.ContactPageComponent || this.currentMasterPage === PageName.CustomerCallPlanPage)
        ||(this.currentMasterPage === PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans'
            && this.uiService.activitiesPagePlanTabSelectedPlan === 'goalsPlansTab'))
        && this.contactService.contactInformation
        && this.contactService.contactInformation.fullName) {
      this.subject = `${this.contactService.contactInformation.fullName} - ${this.translate.instant('MEETING')}`;
      // if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
      //   this.subject = `${this.contactService.contactInformation.fullName} - ${this.translate.instant('MEETING')} - ${this.authenticationOfflineService.user.displayName}`;
      // }
      if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT))
        this.subject  = [this.contactService.contactInformation.fullName, this.translate.instant('MEETING'), defaultActivityType, this.authService.user.displayName].filter(Boolean).join(' - ');
      if (isAcinoBaseUser) {
        this.subject = `${this.translate.instant('VISIT')} - ${this.contactService.contactInformation.fullName}`;
      }
    } else if (this.callPlanOfflineService.selectedRepCallPlan && (this.currentMasterPage === PageName.CallPlanDetails
            || this.currentMasterPage === PageName.CallPlanComponent
            || (this.currentMasterPage === PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans'
             && this.uiService.activitiesPagePlanTabSelectedPlan === 'goalsPlansTab')
             || this.currentMasterPage === PageName.CustomerCallPlanDetails
             || this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage )) {
      let contact = this.contactService.getContactByID(
        this.callPlanOfflineService.selectedRepCallPlan['contactId']
      );
      if (contact && contact.fullName) {
        this.subject = (contact && contact.fullName) ? `${contact.fullName} -${this.translate.instant('MEETING')}` : this.translate.instant('MEETING');
        // if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
        //   this.subject = `${contact.fullName} - ${this.translate.instant('MEETING')} - ${this.authenticationOfflineService.user.displayName}`;
        // }
        if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT))
          this.subject  = [contact.fullName, this.translate.instant('MEETING'), defaultActivityType, this.authService.user.displayName].filter(Boolean).join(' - ');
        if (isAcinoBaseUser) {
          this.subject = `${this.translate.instant('VISIT')} - ${contact.fullName}`;
        }
      }
      //this.subject = 'New Activity from Call Plan';
    } else if (this.followupMeetingSubject.length > 0) {
      this.subject = this.followupMeetingSubject;
      if (isAcinoBaseUser) {
        this.subject = `${this.translate.instant('VISIT')} - ${this.contactService.contactInformation.fullName}`;
      }
    } else if (this.layoverViewPushedFrom == 'ScientificActivityPage') {
      if (this.contact) {
        let contact = this.contactService.getContactByID(
          this.contactService.contactInformation['ID']
        );
        if (contact) {
          this.subject = `${contact.fullName} - ${this.translate.instant('MEETING')}`;
          // if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
          //   this.subject = `${contact.fullName} - ${this.translate.instant('MEETING')} -${this.authenticationOfflineService.user.displayName}`;
          // }
          if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT))
            this.subject  = [contact.fullName, this.translate.instant('MEETING'), defaultActivityType, this.authService.user.displayName].filter(Boolean).join(' - ');
          if (isAcinoBaseUser) {
            this.subject = `${this.translate.instant('VISIT')} - ${this.contactService.contactInformation.fullName}`;
          }
        }
      }
    } else if (this.layoverViewPushedFrom == PageName.SetBookingActivityDetailsComponent) {
      let startTime, endTime;
      let currentSetBookingActivity = this.activityOfflineService.selectedActivity;
      // startTime = currentSetBookingActivity['scheduledStart'] ? new Date(currentSetBookingActivity['scheduledStart']) : new Date();
      // if(currentSetBookingActivity['scheduledEnd']) endTime = new Date(currentSetBookingActivity['scheduledEnd']);
      // else {
      //   endTime = new Date();
      //   endTime = addMinutes(startTime, 30);
      // }
      // meetingStartTime = startTime.getTime();
      // meetingEndTime = endTime.getTime();
      kitBookingId = currentSetBookingActivity.ID;
    }

    const payload: InitiateMeetingPayload = new InitiateMeetingPayload(
      this.subject,
      this.location,
      meetingStartTime,
      meetingEndTime,
      this.notes,
      OFFLINE_ID_PREFIX + new Date().getTime(),
      this.device._isOffline && this.navService.getPreviousActiveChildNavViewPageName() === PageName.AccountPlanDetailsComponent ? this.layoverViewPassedData.ID : undefined,
      null,
      kitBookingId
    );

    return payload;
  }

  public async createNewSampleRequest() {
    let loader = await this.loadingController.create({});
    let start, end, orderDate: string, orderTime: string, contactID: string;
    start = format(new Date());
    //start = format(Utility.getRoundedDownTime(new Date()));
    end = format(addMinutes(start, 30));
    orderDate = new Date(start).toDateString();
    orderTime = new Date(start).toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    //msg = `Meeting is scheduled for ${meetingDate} at ${meetingTime}`;
    if (start) {
      let orderStartTime: any = new Date(start);
      let orderEndTime: any = new Date(end);
      this.logService.logDebug('start ', start);
      let contactID = '', addressID = '', location = '', subject: string = 'Allocation Order';
      if (this.NewActivitySource == 'ContactInfo') {
        contactID = this.contactService.contactInformation.ID;
        subject = 'Allocation Order with ' + this.contactService.contactInformation.fullName;
        let addressList = this.contactService.contactInformation.addressesList;
        addressList =  uniqBy(addressList, 'addressId');
        if (addressList.length == 1) {
          addressID = addressList[0].customerAddressID;
          location = addressList[0].compositeAdd;
        }
        else {
          addressID = '';
          location = ''
        }
      }
      let sampleDropPayload: CreateSampleDropRequestBody = new CreateSampleDropRequestBody({
        scheduledStart: orderStartTime,
        scheduledEnd: orderEndTime,
        offlineActivityId: 'offlineSampleOrder_' + new Date().getTime(),
        subject: subject,
        contactID: contactID,
        addressID: addressID
      });
      sampleDropPayload.indskr_ownerid = this.authenticationOfflineService.user.systemUserID;
      try {
        loader.present();
        let response = await this.samplingService.initiateSampleOrder(sampleDropPayload);

        if (response) { // this check ensures the meeting is in sync with our backend , do not take your chances with backend you will regret
          // Doing this only to update the dx-scheduler on 'activity-3day-view.ts'...
          // Need to revise this structure later on...
          let data;

          if (response['activityId']) {
            // successfully created online into dynamics
            data = { id: response.activityId, ...sampleDropPayload }
          } else {
            // Offline created meeting
            data = {
              id: response.offlineActivityId,
              subject: response.subject,
              location: response.location,
              scheduledStart: response.scheduledStart,
              scheduledEnd: response.scheduledEnd,
            };
          }

          if (data) {
            this.uiService.setUIServiceData({
              view: 'new-activity', data,
            }); // this is an observable stream
          } else {
            console.log('wrong activity creation response.. ', response);
          }
        }
        let activity: SampleActivity = response;
        if (this.currentMasterPage === PageName.ActivitiesPageComponent ||
          (this.currentMasterPage == PageName.ContactPageComponent)
        ) {
          if (this.NewActivitySource == 'ContactInfo') {
            activity.contactName = this.contactService.contactInformation.fullName;
          }
          this.activityOfflineService.selectedActivity = activity;
          await this.activityOfflineService.addActivity(activity);
        }
        if (!this.uiService.toolsActivityActive) {
          this.events.publish('refreshAgenda');
        } else this.uiService.agendaRefreshRequired = true;
        // if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
        this.activityOfflineService.publishActivityEvent({ action: "Create", activity: activity });
        loader.dismiss();
        this.activityService.activityDetailsLoaded = true;
        this.uiService.showNewActivity = false;
        this.uiService.activeView = 'Sample';
        // if (this.device.isMobileDevicePortrait && this.currentMasterPage === PageName.ActivitiesPageComponent) {
        //   this.navService.popWithPageTracking();
        //   this.navService.pushWithPageTracking(NewSampleActivityComponent, PageName.NewSampleActivityComponent, null, PageName.ActivitiesPageComponent);
        // }
        // if (this.device.isMobileDevicePortrait && this.currentMasterPage === PageName.ContactPageComponent) {
        //   this.samplingOfflineService.samplingDetailsViewMode = SamplingDetailsViewMode.CREATE_FROM_CONTACT_PROFILE;
        //   this.navService.popWithPageTracking();
        //   this.navService.pushWithPageTracking(NewSampleActivityComponent, PageName.NewSampleActivityComponent, null, PageName.ContactPageComponent);
        // }
        this.navService.popChildNavPageWithPageTracking();
        if (this.currentMasterPage === PageName.ContactPageComponent) {
          this.samplingOfflineService.samplingDetailsViewMode = SamplingDetailsViewMode.CREATE_FROM_CONTACT_PROFILE;
          // this.uiService.prevView = 'contactDetails';
          this.navService.pushChildNavPageWithPageTracking(NewSampleActivityComponent, PageName.NewSampleActivityComponent, this.currentMasterPage);
          //this.activityService.activityDetailsLoaded = true;
        } else {
          this.navService.setChildNavRoot(NewSampleActivityComponent, PageName.NewSampleActivityComponent, this.currentMasterPage);
        }
        this.footerService.initButtons(FooterViews.SampleDropDetails);
        this.footerService.disableButton(['samplingeligibilities', 'signature'])
      } catch (error) {
        this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_ERROR_CREATING_NEW_ALLOWCATION_ORDER'), 'New Activity', 'top', ToastStyle.INFO);
        loader.dismiss();
      }
      // this.activityOfflineService.isActivityUpdate = true;
    }
  }
  /**
   *
   *
   * @returns
   * @memberof NewActivityComponent
   */
  getRoundedTime(mDate) {
    if(this.authService.user.buSettings["indskr_meetingstarttimerounding"] === false) return mDate
    let d = mDate;
    if (d.getMinutes() < 30) {
      d.setMinutes(30);
      return d;
    } else {
      d.setMinutes(0);
      d.setHours(d.getHours() + 1);
      return d;
    }
  }

  private _selectedEvent;
  public get selectedEvent() {
    return this._selectedEvent;
  }

  public set selectedEvent(event) {
    this._selectedEvent = event;
    if (event) {
      this.startTime = format(event.startDate.toLocaleString());
      this.endTime = format(event.endDate.toLocaleString());
      this.prevStartTime = format(event.startDate.toLocaleString());
      this.prevEndTime = format(event.endDate.toLocaleString());
      this.orgDiffMinutes = differenceInMinutes(this.endTime, this.prevStartTime);
      this.duration = this.dateTimeFormatsService.getFormattedTimeInterval(this.startTime, this.endTime);
      this.initDateTimeFormFields();
    }
  }

  getFormattedTimeInterval(): void {
    let days: any = differenceInDays(this.endTime, this.startTime);
    let timeDifference: Date = new Date((new Date(this.endTime).setSeconds(0).valueOf() - new Date(this.startTime).setSeconds(0).valueOf()));
    console.log('UTC Time: ', new Date((new Date(this.endTime).valueOf() - new Date(this.startTime).valueOf())).toUTCString()); //toGMTString will not be supported soon for old browers we might have to
    let minutes: any = timeDifference.getUTCMinutes();
    let hours: any = timeDifference.getUTCHours();
    if (days === 0) {
      days = '';
    } else if (days <= 1) {
      days = days + ` ${this.translate.instant('DAY')}, `;
    }
    else {
      days = days + ` ${this.translate.instant('DAYS')}, `;
    }
    if (hours === 0) {
      hours = '';
    } else if (hours <= 1) {
      hours = hours + ` ${this.translate.instant('HOUR')}, `;
    }
    else {
      hours = hours + ` ${this.translate.instant('HOURS')}, `;
    }
    this.duration = days + '' + hours + '' + minutes + ` ${this.translate.instant('MINUTES')}`;
  }

  setCellData() {
    const data: { view: string, data: { startDate: Date, endDate: Date } } = this.uiService.getCellData;
    const today = new Date();
    // const isNotSameDay = (data && data.data) ? !isSameDay(data.data.startDate, today) : false;

    this.followupMeetingSubject = '';
    this.followUpMeetingContacts = [];

    if (this.navService.getCurrentMasterPageName() != PageName.ActivitiesPageComponent) {
      this.startTime = (this.getRoundedTime(today)).toString();
    } else if (data && data.view === 'day-week-views') {
      this.cellData = data.data;
      this.startTime = this.cellData.startDate.toString();
    } else if (data && data.view === 'follow-up') {
      this.startTime = data.data.startDate.toString();
      this.endTime = data.data.endDate.toString();
      this.followupMeetingSubject = (this.activityOfflineService.selectedActivity) ? 'Follow-up: ' + this.activityOfflineService.selectedActivity.subject : 'Follow-up : New Activity';
      this.followUpMeetingContacts = (this.contactService.followUpMeetingContacts && this.contactService.followUpMeetingContacts.length > 0) ? this.contactService.followUpMeetingContacts : [];

    } else {
      if (this.uiService.selectedCalendarDateInWeekView && !isToday(this.uiService.selectedCalendarDateInWeekView)) {
        let selectedDate: Date = new Date();
        selectedDate = new Date(this.uiService.selectedCalendarDateInWeekView);
        selectedDate.setHours(8);
        selectedDate.setMinutes(0);
        selectedDate.setMilliseconds(0);
        this.startTime = selectedDate.toString();
      } else {
        this.startTime = (this.getRoundedTime(today)).toString();
      }
    }
    this.startTime = format(this.startTime);
    if (this.cellData?.allDay) {
      this.endTime = data.data.endDate.toString();
    } else {
      this.endTime = format(addMinutes(this.startTime, this.uiService.activeViewNewMeeting == "meeting"?this.authService.user.meeting_duration:30));
    }
    this.duration = this.dateTimeFormatsService.getFormattedTimeInterval(this.startTime, this.endTime);
    let dateTime = { startDateTime: this.startTime, endDateTime: this.endTime };
    this.dateTimeFormatsService.updateLimitedMinTime.next(dateTime);
  }

  private _initTimeForCalendarPage(isSetActivity: boolean = false) {
    let selectedDate: Date = new Date();
    const today: Date = new Date();
    let isInCalendarView: boolean = false;
    if (this.agendaFooterService.actSegment == "week" && this.uiService.selectedCalendarDateInWeekView && isValid(this.uiService.selectedCalendarDateInWeekView)) {
      isInCalendarView = true;
      selectedDate = this.uiService.selectedCalendarDateInWeekView;
    }
    if (isInCalendarView && isSetActivity) {
      this.startTime = selectedDate.toString();
    } else {
      if (isToday(selectedDate)) {
        selectedDate.setHours(today.getHours());
        selectedDate.setMinutes(today.getMinutes());
        this.startTime = (this.getRoundedTime(selectedDate)).toString();
      } else {
        selectedDate.setHours(8);
        selectedDate.setMinutes(0);
        selectedDate.setMilliseconds(0);
        this.startTime = selectedDate.toString();
      }
    }

    this.startTime = format(this.startTime);
    this.endTime = format(addMinutes(this.startTime, this.uiService.activeViewNewMeeting == "meeting"?this.authService.user.meeting_duration:30));
    this.duration = this.dateTimeFormatsService.getFormattedTimeInterval(this.startTime, this.endTime);
    let dateTime = { startDateTime: this.startTime, endDateTime: this.endTime };
    this.dateTimeFormatsService.updateLimitedMinTime.next(dateTime);
  }

  private createNewTimeOff() {
    if (this._newTimeOffCmp instanceof NewTimeOffComponent) {
      this._newTimeOffCmp?.saveTimeOff();
    }
  }

  channelNameTranslation(channel) {
    switch (channel) {
      case 'Email':
        return this.translate.instant('EMAIL');
      case 'Phone':
        return this.translate.instant('PHONE');
      case 'Visit':
        return this.translate.instant('VISIT');
      case 'Fax':
        return this.translate.instant('FAX');
      case 'Email Address':
        return this.translate.instant('EMAIL_ADDRESS');
      case 'Phone number':
        return this.translate.instant('PHONE_NUMBER');
      case 'SMS':
        return this.translate.instant('SMS');
      case 'Facebook':
        return this.translate.instant('FACEBOOK');
      case 'WhatsApp':
        return this.translate.instant('WHATSAPP');
      case 'WeCom':
        return this.translate.instant('WECOM');
      default:
        return this.translate.instant(channel);
    }
  }


  updateFollowUpTaskCategory(category: string) {
    this.followUpActionCategory = this.followUpActionCategory != category ? category : null;
  }

  private async handleNewSAPFollowUpAction(): Promise<any> {
    return new Promise(async (resolve, reject) => {

      this.trackingService.tracking('ScientificPlanFollowupTask', TrackingEventNames.SCIENTIFICACTIVITYPLAN);
      let errorOccured: boolean = false;
      let currentTime = new Date().getTime();
      let start = format(new Date().setHours(0, 0, 0, 0));

      if (this.uiService.activitiesPageTab === 'day' && this.uiService.selectedCalendarDateInWeekView) {
        start = format(this.uiService.selectedCalendarDateInWeekView.setHours(0, 0, 0, 0));
      }
      if (this.agendaFooterService.actSegment === 'week' && this.uiService.selectedCalendarDateInWeekView && this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent) {
        start = format(this.uiService.selectedCalendarDateInWeekView.setHours(0, 0, 0, 0));
      }
      if (this.uiService.activitiesPageTab === 'agenda' && this.uiService.selectedCalendarDateInMonthView) {
        start = this.uiService.selectedCalendarDateInMonthView.toLocaleDateString();
      }

      let payload = {
        activityId: 'offline_followup_' + currentTime,
        activitytypecode: 'followuptask',
        subject: 'Follow-up Action',
        offlineId: 'offline_followup_' + currentTime,
        scheduledstart: new Date(start).getTime(),
        indskr_plantype: FOLLOW_UP_TYPE.SCIENTIFIC_PLAN,
        //scheduledend: new Date(start).getTime(),
      };
      if (this.isLayoverPushView) {
        if (this.layoverViewPushedFrom == 'ScientificActivityPage') {
          // this.trackingService.tracking('ScientificPlanFollowupAction', TrackingEventNames.SCIENTIFICACTIVITYPLAN);
          if (this.layoverViewPassedData) {
            payload['indskr_scientificplan'] = this.layoverViewPassedData.ID;
            payload['scientifiPlanName'] = this.layoverViewPassedData.scientificActivityPlanName;
          }
          if (this.contact) {
            payload['indskr_contact'] = this.contactService.contactInformation.ID;
            payload['contactName'] = this.contactService.contactInformation.fullName;
          }
        }
      }
      if (this.currentMasterPage == PageName.ContactPageComponent && this.contactService.contactInformation) {
        // this.trackingService.tracking('ContatcsFollowupAction', TrackingEventNames.SCIENTIFICACTIVITYPLAN)
        payload['indskr_contact'] = this.contactService.contactInformation.ID;
        payload['contactName'] = this.contactService.contactInformation.fullName;
      }
      else {
        // this.trackingService.tracking('AgendaFollowupAction', TrackingEventNames.ACCOUNTPLANNING);
      }
      let newFollowUp = new FollowUpActivity(payload);
      newFollowUp.state = 0;
      newFollowUp.statusString = 'Open';
      newFollowUp.ownerId = this.authenticationOfflineService.user.systemUserID;
      newFollowUp.ownerNameString = this.authenticationOfflineService.user.displayName;
      newFollowUp.pendingPushToDynamics = true;
      await this.followUpActivityDataService.createFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalCopy: true, onLocalDatabase: true }, [newFollowUp], currentTime, false).then(succ => {
        //Successfully created new follow up activity
        this.activityOfflineService.selectedActivity = newFollowUp;
      }).catch(err => {
        // Handle any error occured during follow up activity creation
        reject('Error occured while creating Scientific Activity Plan');
        errorOccured = true;
      });
      if (!errorOccured) {
        let from: string = 'AgendaView';
        let pageName: PageName = PageName.ActivitiesPageComponent;
        if (this.isLayoverPushView) {
          this.navService.popChildNavPageWithPageTracking();
          if (this.layoverViewPushedFrom == 'ScientificActivityPage') {
            from = 'ScientificActivityPage';
            pageName = PageName.ScientificActivityPage;
            if (this.uiService.activitiesPagePlanTabSelectedPlan === 'scientificPlans') {
              this.uiService.activeView = 'ActivitiesPageRightPaneNav';
              this.uiService.showNewActivity = false;
              this.uiService.showRightPane = true;
            }
            this.navService.pushChildNavPageWithPageTracking(ScientificPlanFollowUpTask, PageName.ScientificPlanFollowUpTask, PageName.ScientificActivityPage, { from: from, activity: newFollowUp });
          } else if (this.layoverViewPushedFrom === 'ContactPageComponent') {
            from = 'ContactTools';
            pageName = PageName.ContactPageComponent;
            this.uiService.showNewActivity = false;
            this.navService.pushChildNavPageWithPageTracking(ScientificPlanFollowUpTask, PageName.ScientificPlanFollowUpTask, PageName.ScientificActivityPage, { from: from, activity: newFollowUp });
          }
        }
        /**
         *  HANDLE OTHER CASES
         */
        else { // Assume follow up actiion is being created from agenda
          this.navService.setChildNavRoot(ScientificPlanFollowUpTask, PageName.ScientificPlanFollowUpTask, PageName.ActivitiesPageComponent, { from: from, activity: newFollowUp });
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.uiService.showNewActivity = false;
          this.uiService.showRightPane = true;
          if (this.uiService.activitiesPageTab == 'day') {
            let data = {
              id: newFollowUp.ID,
              subject: newFollowUp.subject,
              location: newFollowUp.location,
              scheduledStart: newFollowUp.scheduledStart,
              scheduledEnd: newFollowUp.scheduledEnd,
              type: newFollowUp.type,
            };
            this.uiService.setUIServiceData({
              view: 'new-activity', data,
            });
          }
        }
        if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent) {

          this.events.publish('refreshAgenda');
        } else {
          this.uiService.agendaRefreshRequired = true;
        }
        //this.events.publish('refreshAgenda');
        resolve('');
      }
    });
  }

  private async createCustomerInquiryForMeeting(source: AccesingMode) {
    await this.sharedDataService.createInMeetingInquiry(source, this.type);
    if (source === AccesingMode.PRESENTATION) {
      this.trackingService.tracking('InquiryActivityFromMeetingPresentation', TrackingEventNames.CUSTOMER_INQUIRY);
    }
    else if (source === AccesingMode.INMEETING) {
      this.trackingService.tracking('InquiryActivityFromMeeting', TrackingEventNames.CUSTOMER_INQUIRY);
    } else if (source == AccesingMode.IN_PHONE_CALL) {
      this.trackingService.tracking('SelectConfirm', TrackingEventNames.PHONECALL);
      this.phoneCallMeetingStructureService.createInPhoneCallMeetingEmbededActivity(this.caseManagementService.currentCase);
      await this.phoneCallMeetingStructureService.openInPhoneCallMeetingCaseModal(this.caseManagementService.currentCase);
      return
    }
    else if(source == AccesingMode.IN_SET_BOOKING) {
      //this.trackingService.tracking('InquiryActivityFromKitBooking', TrackingEventNames.SETBOOKING);
      this.setBookingDataService.createInKitBookingMeetingEmbededActivity(this.caseManagementService.currentCase);
      await this.setBookingDataService.openInKitBookingMeetingCaseModal(this.caseManagementService.currentCase);
      return
    }
    this.meetingStructureService.createInMeetingEmbededActivity(this.caseManagementService.currentCase);
    await this.meetingStructureService.openInMeetingCaseModal(this.caseManagementService.currentCase);
  }

  async openCaseDetailsView(data, source: AccesingMode) {
    /* If modal is already opened then ignore request else open a new modal and destroy instance on*/
    if (this.pageModal) return;

    this.caseManagementService.accessedFrom = source;
    this.caseManagementService.assignSelectedCase(data);
    this.pageModal = await this.modalCtrl.create({
      component: InMeetingCasesComponent, componentProps: {
        type: ActivityType.CaseIntake,
        view: 'case-details'
      }, backdropDismiss: false, cssClass: 'inMeetingCases'
    })
    this.pageModal.present();
    this.pageModal.onDidDismiss().then((data) => {
      this.events.publish('detectChangesOnActivityDetails');
      if (source === AccesingMode.PRESENTATION) {
        this.footerService.initButtons(FooterViews.PreviewMeeting);
      }
      else if (source === AccesingMode.INMEETING) {
        this.footerService.initButtons(FooterViews.Activities);
      }

      //reset the modalHandler for next flow
      this.pageModal = undefined;
    });
  }
  procedureLogSelectionType(type: string) {
    if (type == 'procedureLog') {
      this.procedureLogSelection = type;
      this.procedureCategory = null;
    }
  }
  selectProcedureCategory(procedureCategory: string) {
    if (procedureCategory == 'procedure_tracker') {
      this.procedureLogSelection = '';
    }
    this.procedureCategory = this.procedureCategory !== procedureCategory ? procedureCategory : null;
    this._cd.detectChanges();
    this.initHeaderOnOptionSelection();
  }
  selectMessagingType(type) {
    this.selectedMessagingChannelType = this.selectedMessagingChannelType != type ? type : null;
    this._cd.detectChanges();
    this.initHeaderOnOptionSelection();
  }

  selectCaseManagementType(type) {
    if(this.caseManagementService.caseTypeList && this.caseManagementService.caseTypeList.length === 1) {
      // console.log("Only one element in caseTypeList");
      this.type = this.caseManagementService.caseTypeList[0];
    } else {
      this.type = this.type != type ? type : null;
    }
    this._cd.detectChanges();
    this.initHeaderOnOptionSelection();
  }

  selectFollowupActionCategory(category) {
    this.followUpActionCategory = this.followUpActionCategory !== category ? category : null;
    this._cd.detectChanges();
    this.initHeaderOnOptionSelection();
  }

  selectInsightType(type: INSIGHT_TYPE) {
    this.selectedInsightType = this.selectedInsightType !== type ? type : null;
    this._cd.detectChanges();
    this.initHeaderOnOptionSelection();
  }

  private initDateTimeFormFields() {
    let dateTimeValue = this.getFormattedDateTimeText();
    if (dateTimeValue) {
      this.startDateTime = dateTimeValue.startDateTime;
      this.startDateValue = dateTimeValue.startDate;
      this.startTimeValue = dateTimeValue.startTime;
      this.endDateTime = dateTimeValue.endDateTime;
      this.endDateValue = dateTimeValue.endDate;
      this.endTimeValue = dateTimeValue.endTime;
      this.duration = dateTimeValue.duration;
    }
    this.startDateField = {
      isDateField: true,
      isNewActivityView: true,
      label: this.translate.instant('START_DATE'),
      inputText: this.startDateValue,
      startDateTimeValue: this.startDateTime,
      endDateTimeValue: this.endDateTime,
      fromViewPage: CurViewPageType.NewActivity,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: false,
      showArrow: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.startTimeField = {
      isTimeField: true,
      isNewActivityView: true,
      label: this.translate.instant('START_TIME'),
      inputText: this.startTimeValue,
      startDateTimeValue: this.startDateTime,
      endDateTimeValue: this.endDateTime,
      fromViewPage: CurViewPageType.NewActivity,
      id: DateTimeFieldType.StartTimeField,
      isReadOnly: true,
      isDisabled: false,
      showArrow: true,
    };
    this.endDateField = {
      isDateField: true,
      isNewActivityView: true,
      label: this.translate.instant('END_DATE'),
      inputText: this.endDateValue,
      startDateTimeValue: this.startDateTime,
      endDateTimeValue: this.endDateTime,
      fromViewPage: CurViewPageType.NewActivity,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: false,
      showArrow: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.endTimeField = {
      isTimeField: true,
      isNewActivityView: true,
      label: this.translate.instant('END_TIME'),
      inputText: this.endTimeValue,
      startDateTimeValue: this.startDateTime,
      endDateTimeValue: this.endDateTime,
      fromViewPage: CurViewPageType.NewActivity,
      id: DateTimeFieldType.EndTimeField,
      isReadOnly: true,
      isDisabled: false,
      showArrow: true,
      isHidden: !this.hasMeetingEndDateEnabled,
    };
    this.durationField = {
      isDurationField: true,
      isNewActivityView: true,
      label: this.translate.instant('DURATION'),
      inputText: this.duration,
      id: DateTimeFieldType.TimeDurationField,
      isReadOnly: true,
      isDisabled: false,
      showArrow: false,
      isHidden: !this.hasMeetingDurationEnabled && !this.hasMeetingEndDateEnabled,
    };
    if (this.hasMeetingDurationEnabled) {
      this.durationField.showArrow = true;
      this.durationField.eventHandler = (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName);
    }

    if (this.hasMeetingDurationRequired) {
      this.durationField.isRequired = true;
    }

    this.activityOfflineService.signalConflictCheck$.next({ componentUuid: this.uuid, signal: true, start: new Date(this.startDateTime), end: new Date(this.endDateTime) });
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      switch (id) {
        case 'start-date-field':
          this.openStartDatePicker(event);
          break;
        case 'end-date-field':
          this.openEndDatePicker(event);
          break;
        case DateTimeFieldType.TimeDurationField:
          this.selectDuration(event);
          break;
        default:
          break;
      }
    }
  }

  async openStartDatePicker(myEvent) {
    this.activityOfflineService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.NewActivity,
          startDateTimeValue: this.startDateTime,
          endDateTimeValue: this.endDateTime
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then((data: any) => {
      this.logService.logDebug(data);
      if (data !== null && !_.isEmpty(data.data) && data.data.startTime != '') {
        let selectedDate = data.data.startTime;
        let selectedTime = this.dateTimeFormatsService.getFormattedTimeValue(this.startDateTime);
        // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined.
        // set 'MMM dd, yyyy' and 'en-US' to combine date and time, and changed date format will be adjusted through getFormattedDateTimeText().
        let getStartDate = this.datePipe.transform(selectedDate, "MMM dd, yyyy", undefined, 'en-US');
        let setCombinedDateTime = moment(getStartDate + ' ' + selectedTime).format();
        this.startTime = setCombinedDateTime;
      }
      this.handleStartDateTimeValue();
    });
    popover.present();
  }

  async openEndDatePicker(myEvent) {
    this.activityOfflineService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.NewActivity,
          startDateTimeValue: this.startDateTime,
          endDateTimeValue: this.endDateTime
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then((data: any) => {
      this.logService.logDebug(data);
      if (data !== null && !_.isEmpty(data.data) && data.data.endTime != '') {
        let selectedDate = data.data.endTime;
        let selectedTime = this.dateTimeFormatsService.getFormattedTimeValue(this.endDateTime);
        // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined.
        // set 'MMM dd, yyyy' and 'en-US' to combine date and time, and changed date format will be adjusted through getFormattedDateTimeText().
        let getEndDate = this.datePipe.transform(selectedDate, "MMM dd, yyyy", undefined, 'en-US');
        let setCombinedDateTime = moment(getEndDate + ' ' + selectedTime).format();
        this.endTime = setCombinedDateTime;
      }
      this.handleEndDateTimeValue();
    });
    popover.present();
  }

  private async selectDuration(event) {
    const popover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: {
          id: 'duration-field',
          data: _.range(1, 61).map(option => {
            return { title: option == 60 ? '1 Hour' : `${option} Minute${option > 1 ? 's' : ''}`, id: option.toString(), isSelected: false }
          })
        }
      },
      cssClass: 'subject-popover',
      event: event
    });
    await popover.present();
    const data = (await popover.onDidDismiss())?.data?.selectedItems[0];
    if (this.backgroundUploadInProgress) return;
    if (data) {
      let selectedDuration = parseInt(data.id);
      let m = moment(this.startTime);
      if (!m.isValid()) {
        this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'New Activity', 'top', ToastStyle.DANGER);
        return;
      } else {
        this.endTime = format(addMinutes(this.startTime, selectedDuration));
        this.duration = this.dateTimeFormatsService.getFormattedTimeInterval(this.startTime, this.endTime);
        this.initDateTimeFormFields();
        this._cd.detectChanges();
      }
    }
  }

  private handleStartDateTimeValue() {
    if (this.uiService.activeViewNewMeeting == 'phonecall') {
      this.trackingService.tracking('Select Start Date', TrackingEventNames.PHONECALL)
    }
    let m = moment(this.startTime);
    if (!m.isValid()) {
      this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'New Activity', 'top', ToastStyle.DANGER);
      this.startTime = this.prevStartTime;
    } else {
      if (this.uiService.activeViewNewMeeting == 'meeting' && this.uiService.activeViewNewMeetingType === 'live') {
        this.endTime = format(addMinutes(this.startTime, this.orgDiffMinutes));
      } else {
        let addedMinutes: number = this.uiService.activeViewNewMeeting == 'meeting'? this.authService.user.meeting_duration : 30;
        this.endTime = format(addMinutes(this.startTime, addedMinutes));
      }
      this.prevStartTime = this.startTime;
      this.duration = this.dateTimeFormatsService.getFormattedTimeInterval(this.startTime, this.endTime);
      this.initDateTimeFormFields();
      this._cd.detectChanges();
    }
  }

  private handleEndDateTimeValue() {
    if (this.uiService.activeViewNewMeeting == 'phonecall') {
      this.trackingService.tracking('Select End time', TrackingEventNames.PHONECALL)
    }
    let m = moment(this.endTime);
    if (!m.isValid()) {
      this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'New Activity', 'top', ToastStyle.DANGER);
      this.endTime = this.prevEndTime;
    } else {
      this.prevEndTime = this.endTime;
      this.duration = this.dateTimeFormatsService.getFormattedTimeInterval(this.startTime, this.endTime);
      this.initDateTimeFormFields();
      this._cd.detectChanges();
    }
  }

  getFormattedDateTimeText(): any {
    let startDateTimeValue: Date = new Date(this.startTime);
    let endDateTimeValue: Date = new Date(this.endTime);
    let startDay = this.datePipe.transform(startDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let endDay = this.datePipe.transform(endDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let startTime = startDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    let endTime = endDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    let formattedDuration = this.dateTimeFormatsService.getFormattedTimeInterval(startDateTimeValue, endDateTimeValue);

    let dateTimeValue: any = {
      startDateTime: null,
      startDate: null,
      startTime: '',
      endDateTime: '',
      endDate: '',
      endTime: '',
      duration: '',
    };

    dateTimeValue = {
      startDateTime: startDateTimeValue,
      startDate: startDay,
      startTime: startTime,
      endDateTime: endDateTimeValue,
      endDate: endDay,
      endTime: endTime,
      duration: formattedDuration,
    };
    return dateTimeValue;
  }

  selectConsentCustomer(contact, forceSelect?) {
    this.selectedConsentCustomer = (forceSelect || this.selectedConsentCustomer != contact) ? contact : null;
    this.contactService.contactInformation = this.selectedConsentCustomer;
    this._cd.detectChanges();
    this.initHeaderOnOptionSelection();
  }

  private _handleNewOpportunity() {
    if (this.contact && this.contact.accountRelationships && this.contact.accountRelationships.length > 0 && this.opportunityService.stakeholderRoles.length > 0) {
      let account;
      for (let i = 0; i < this.contact.accountRelationships.length && !account; i++) {
        account = this.accountService.getAccountById(this.contact.accountRelationships[i].accountId);
      }
      if (account) {
        this.uiService.prevView = this.uiService.activeView;
        this.navService.popToRootWithPageTracking();
        this.navService.pushWithPageTracking(OpportunityManagementPageComponent, PageName.OpportunityManagementPage, { 'listMode': ComponentViewMode.LISTVIEW, contactToCreateOpportunity: this.contact }, PageName.OpportunityManagementPage);
      }
    }
    else if (this.account) {
      let account;
      account = this.accountService.getAccountById(this.account.id);
      if (account) {
        this.uiService.prevView = this.uiService.activeView;
        this.navService.popToRootWithPageTracking();
        this.navService.pushWithPageTracking(OpportunityManagementPageComponent, PageName.OpportunityManagementPage, { 'listMode': ComponentViewMode.LISTVIEW, accountToCreateOpportunity: this.account }, PageName.OpportunityManagementPage);
      }
    }
    else {
      this.notificationService.notify(this.translate.instant('NEW_OPPORTUNITY_TOAST_NO_ACCOUNT'), 'New Activity', 'top', ToastStyle.DANGER);
      return;
    }
  }

  private _openSurvey() {
    if (!this.contactService.loadedContacts || this.backgroundUploadInProgress) return;
    this.uiService.prevView = this.uiService.activeView;
    this.uiService.showNewActivity = false;
    this.customerSurveyService.inMeetingSelectedSurveyId = null;
    // this.navService.popToRootWithPageTracking();
    this.navService.pushWithPageTracking(SurveyPageComponent, PageName.SurveyPageComponent,
      { appointmentId: this.activityOfflineService.selectedActivity.ID, from: this.activityOfflineService.activitySource}, PageName.SurveyPageComponent);
  }

  private async handleNewKitBooking(){
    let kitBookingDynamicForm: DynamicForm = this.dynamicFormService.getFormDefinitionForEntity('indskr_setbooking',FormType.CREATEFORM);
    const nonVisibleFormSection = this.setBookingDataService.formSectionConfigure?.filter(config => config['_indskr_mobileappform_value'] == kitBookingDynamicForm?.mobileFormId && !config['indskr_visibility']);
    kitBookingDynamicForm?.metadata.forEach(tab => {
      tab.controls.forEach(control => {
        if (control.dataType && control.attributeName && !_.isEmpty(nonVisibleFormSection) && nonVisibleFormSection.some(sec => sec['indskr_name'].toLowerCase() == control.attributeName.toLowerCase())) {
          control.isVisible = false;
          control.forceHide = true;
        } else if (!control.dataType && control.subgrid && !_.isEmpty(nonVisibleFormSection) && nonVisibleFormSection.some(sec => sec['indskr_name'].toLowerCase() == control.attributeName.toLowerCase())) {
          control.isVisible = false;
          control.forceHide = true;
        }
      });
    });
    const formType = DynamicFormType.CONFIGUREDFORM;
    if (kitBookingDynamicForm) {
      let formDetail: DynamicFormComponentModel = {
        title: this.translate.instant('KIT_BOOKING'),
        LookupEntitySetName: 'indskr_setbookings',
        showLeftHeaderButton: true,
        leftHeaderBtnIcon: 'chevron-back-outline',
        showRightHeaderButton: true,
        rightHeaderImgSrc: 'assets/imgs/header_save.svg',
        rightHeaderBtnText: this.translate.instant('SAVE'),
        formMetadata: kitBookingDynamicForm,
        formEntityObject: null,
        mainEntityObjectId: null,
        dynamicFormType: formType,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: DynamicFormComponentModel) => this._handleKitBookingFormComponentEvent(data, eventTarget, refData),
      };
      if(this.layoverViewPushedFrom){
        let from ="";
        formDetail.formEntityObject = {};
        let pageName;
        if(this.layoverViewPushedFrom == "AccountTool"){
          formDetail.formEntityObject['_indskr_accountnamesoldto_value'] = this.layoverViewPassedData?.id;
          formDetail.formEntityObject['_indskr_accountnamesoldto_value@OData.Community.Display.V1.FormattedValue'] = this.layoverViewPassedData?.accountName;
          from = "KitBookingfromAccountTool";
          pageName = PageName.AccountPageComponent;
          this.uiService.activeView = 'AccountPageRightPaneNav';
        }
        if(this.layoverViewPushedFrom == "ContactPageComponent"){
          formDetail.formEntityObject['_indskr_surgeoncontact_value'] = this.contactService.contactInformation?.ID;
          formDetail.formEntityObject['_indskr_surgeoncontact_value@OData.Community.Display.V1.FormattedValue'] = this.contactService.contactInformation?.fullname;
          from = "KitBookingfromContactTool";
          pageName = PageName.ContactPageComponent;
          this.uiService.activeView = 'ContactPageRightPaneNav';
        }
        this.uiService.showNewActivity = false;
        await this.navService.popChildNavPageWithPageTracking();
        this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent, PageName.DynamicFormComponent, pageName, {
          MODE: 'CREATE_MODE',
          FORM_DETAIL: formDetail,
          from: from,
        });
        this.footerService.initButtons(FooterViews.Accounts);
        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
      }
      else{
        this.navService.setChildNavRoot(DynamicFormComponent, PageName.DynamicFormComponent, PageName.ActivitiesPageComponent, {
          MODE: 'CREATE_MODE',
          FORM_DETAIL: formDetail,
        });
        this.footerService.initButtons(FooterViews.Accounts);
        this.navService.setChildNavRightPaneView(true);
        this.uiService.showNewActivity = false;
        this.activityOfflineService.selected = null;
      }

    }
  }

  private async _handleKitBookingFormComponentEvent(data,eventTarget,refData:DynamicFormComponentModel){
    if(eventTarget){
      if(eventTarget == 'DONEBUTTONCLICK' || eventTarget == 'SUBMITKITBOOKING' || eventTarget == 'SAVEKITBOOKING'){
        if(data && data.formValue){
          if(eventTarget== 'SUBMITKITBOOKING'){
            this.trackingService.tracking('KitBookingSubmitClick',TrackingEventNames.KITBOOKING);
          }
          //check if any limited release products
          if(eventTarget== 'SUBMITKITBOOKING' && data.formValue["indskr_setbookingid"]){
            let setBookingProductsArray = await this.diskService.retrieve(DB_KEY_PREFIXES.SET_BOOKING_LINKED_ENTITY + "indskr_setbookingproduct");
            let linkEntityProductsData = setBookingProductsArray && (setBookingProductsArray.raw).filter(x => x["indskr_setbookingid"] === data.formValue["indskr_setbookingid"] && (!x.hasOwnProperty('indskr_setbookingproduct.statecode') || (x.hasOwnProperty('indskr_setbookingproduct.statecode') && x['indskr_setbookingproduct.statecode'] == 0)));
            let selectedProductIds = [];
            if(linkEntityProductsData || this.linkEntitiesReqPayload) {
              if(this.linkEntitiesReqPayload && this.linkEntitiesReqPayload.length>0) {
                this.linkEntitiesReqPayload.forEach(payload => {
                  if(payload.values && payload.values.lookupfields && payload.values.lookupfields.length>0) {
                    payload.values.lookupfields.forEach((field)=> {
                      if(field.name==="indskr_product") { selectedProductIds.push(field.id); }
                    });
                  }
                });
              } else if(linkEntityProductsData && linkEntityProductsData.length>0){ //case when changes are saved earlier and submitted now
                linkEntityProductsData.forEach(pro => {
                  selectedProductIds.push(pro["indskr_setbookingproduct.indskr_product"])
                });
              }
            }
            if(selectedProductIds && selectedProductIds.length>0) {
              let flag=false;
              let productLimitedRelease = await this.setBookingDataService.getLimitedReleaseInfoForSelectedProduct(selectedProductIds);
              if(productLimitedRelease && productLimitedRelease.length>0) {
                const limitedReleaseProducts = productLimitedRelease.filter(pr => pr.indskr_limitedrelease);
                flag = limitedReleaseProducts.length > 0;
                if(limitedReleaseProducts && limitedReleaseProducts.length>1){
                  this.notificationService.notify(this.translate.instant('KIT_BOOKING_LIMITED_RELEASE_ERROR_MSG'), 'New Activity', 'top', ToastStyle.DANGER);
                  return;
                }
              }
              if(flag){
                //if yes, check data.formValue has phmc field filled out
                if(data.formValue["indskr_phmc"] && this.authService.user.buSettings["indskr_limitedreleasebookingmessage"]){
                  //this.notificationService.notify(this.authService.user.buSettings["indskr_limitedreleasebookingmessage"],"setbookingnew");
                  //this.uiService.dismissLoader();
                  let res = await this.alertService.showAlert({
                    header: this.translate.instant('NOTE'),
                    message: this.authService.user.buSettings["indskr_limitedreleasebookingmessage"],
                    backdropDismiss: false,
                    //buttons:[{text:this.translate.instant('OK'), role:"ok"}]
                  },this.translate.instant('OK'));
                  if(res && res.role && res.role == "cancel") return;
                  //this.uiService.displayLoader();
                }
                //if not, display error msg and break out of the function
                else if(!data.formValue["indskr_phmc"]){
                  this.notificationService.notify(this.translate.instant('KIT_BOOKING_PHMC_ERROR_MSG'), 'New Activity', 'top', ToastStyle.DANGER);
                  //this.uiService.dismissLoader();
                  return;
                }
              }
            }
            // if(this.brandService.brands && this.brandService.brands.length != 0){
            //   let flag=false;
            //   if(linkEntityProductsData && linkEntityProductsData.length>0){ //case when changes are saved earlier and submitted now
            //     flag= linkEntityProductsData && linkEntityProductsData.some((product_obj)=>{
            //       return this.brandService.getBrandByID(product_obj["indskr_setbookingproduct.indskr_product"]).indskr_limitedrelease===true
            //     });
            //   }
            //   else{   //case when changes are directly submitted without saving
            //     flag = this.linkEntitiesReqPayload && this.linkEntitiesReqPayload.length>0 && this.linkEntitiesReqPayload.some((reqPayload)=>{
            //       if(reqPayload.values && reqPayload.values.lookupfields){
            //         return reqPayload.values.lookupfields.some((lookupfield)=>{
            //           return (
            //             lookupfield.name==="indskr_product" &&
            //             this.brandService.getBrandByID(lookupfield.id).indskr_limitedrelease===true
            //           );
            //         });
            //       }
            //       return false;
            //     });
            //   }
            //   if(flag){
            //     //if yes, check data.formValue has phmc field filled out
            //     if(data.formValue["indskr_phmc"] && this.authService.user.buSettings["indskr_limitedreleasebookingmessage"]){
            //       //this.notificationService.notify(this.authService.user.buSettings["indskr_limitedreleasebookingmessage"],"setbookingnew");
            //       //this.uiService.dismissLoader();
            //       await this.alertService.showAlert({
            //         header: this.translate.instant('NOTE'),
            //         message: this.authService.user.buSettings["indskr_limitedreleasebookingmessage"],
            //         backdropDismiss: false,
            //         buttons:[{text:this.translate.instant('OK'), role:"ok"}]
            //       })
            //       //this.uiService.displayLoader();
            //     }
            //     //if not, display error msg and break out of the function
            //     else if(!data.formValue["indskr_phmc"]){
            //       this.notificationService.notify(this.translate.instant('KIT_BOOKING_PHMC_ERROR_MSG'), 'New Activity', 'top', ToastStyle.DANGER);
            //       //this.uiService.dismissLoader();
            //       return;
            //     }
            //   }
            // }
          }
          //check if urgent kitbooking
          if(eventTarget=="SUBMITKITBOOKING" && this.authService.user.buSettings["indskr_urgentkitbookingmessage"] && data.formValue["indskr_urgent"]){
            //this.notificationService.notify(this.authService.user.buSettings["indskr_urgentkitbookingmessage"],"setbookingedit");
            await this.alertService.showAlert({
              header: this.translate.instant('NOTE'),
              message: this.authService.user.buSettings["indskr_urgentkitbookingmessage"],
              backdropDismiss: false,
              buttons:[{text:this.translate.instant('OK'), role:"ok"}]
            })
          }

          //check if late kitbooking
          if(eventTarget == 'SUBMITKITBOOKING'
            && (this.authService.user.buSettings["indskr_latekitbookingdays"]
                || this.authService.user.buSettings["indskr_latekitbookingdays"]==0)
            && this.authService.user.buSettings["indskr_latekitbookingmessage"]){
            let daysDiff = differenceInCalendarDays(data.formValue["indskr_startdate"], new Date())
            if(daysDiff >=0 && daysDiff<=this.authService.user.buSettings["indskr_latekitbookingdays"]){
              let res = await this.alertService.showAlert({
                header: "Booking required within "+this.authService.user.buSettings["indskr_latekitbookingdays"]+" days",
                message: this.authService.user.buSettings["indskr_latekitbookingmessage"],
                backdropDismiss: false
              }, this.translate.instant('SUBMIT'))
              if(res && res.role && res.role == "cancel") return;
            }
          }

          this.uiService.displayLoader();

          if (this.linkEntitiesReqPayload && this.linkEntitiesReqPayload.length > 0) {
            for (let reqPayload of this.linkEntitiesReqPayload) {
              await this.dynamicFormService.createLinkedEntity(reqPayload.values, reqPayload.entity);
            }
            this.linkEntitiesReqPayload = [];
          }
          let newSetBooking = new SetBookingActivity(data.formValue);
          newSetBooking.allDayEvent = !isSameDay(newSetBooking.scheduledStart,newSetBooking.scheduledEnd);
          newSetBooking.serviceDTO = _.cloneDeep(data.formValue);
          // Remove Default case team attribute if the user didn't change the value. Otherwise, it will trigger unnecessary workflow in Dynamics.
          let selectedActivity = this.activityOfflineService.getActivityByID(newSetBooking.ID) as SetBookingActivity;
          if(selectedActivity && selectedActivity.rawData && selectedActivity.rawData['indskr_defaultcaseteam'] != undefined && selectedActivity.rawData['indskr_defaultcaseteam'] != null) {
            if(selectedActivity.rawData['indskr_defaultcaseteam'] == data.formValue['indskr_defaultcaseteam'])
              delete newSetBooking.serviceDTO['indskr_defaultcaseteam'];
          }
          // For Kit Booking Create and Edit form Hardcode the attribute names for start date and end date fields as all the validation and logics are hardcoded to specific attribute names
          // These hardcoded attribute names are reverted when form is closed on Save operation
          if (newSetBooking.serviceDTO['indskr_startdate']) {
            newSetBooking.serviceDTO['indskr_operationstartdate'] = newSetBooking.serviceDTO['indskr_startdate']
            delete newSetBooking.serviceDTO.indskr_startdate;
          }
          if (newSetBooking.serviceDTO['indskr_enddate']) {
            newSetBooking.serviceDTO['indskr_operationenddate'] = newSetBooking.serviceDTO['indskr_enddate']
            delete newSetBooking.serviceDTO.indskr_enddate;
          }
          if (data.isFormSaved && eventTarget == 'SUBMITKITBOOKING') {
            newSetBooking.serviceDTO['indskr_status'] = SetBookingStatus.PENDINGSYNC;
            newSetBooking.statusString = 'Pending Sync';
          }else{
            newSetBooking.serviceDTO['indskr_status'] = SetBookingStatus.DRAFT;
            // Hard Coding the Source to Go App when Kit Booking is created from Go App
            newSetBooking.serviceDTO['indskr_source'] = 548910001;
            newSetBooking.statusString = 'Draft';
          }
          //newSetBooking.pendingPushToDynamics = true; Can be used later for offline flow
          const action:OperationDetail = {
            onDynamics: true,
            onLocalDatabase: true,
            onLocalCopy: true,
            operationDetail: {
              code: 'CREATENEWSETBOOKINGACTIVITY',
              message: 'Create New Set Booking Activity'
            }
          }
          await this.setBookingDataService.createSetBookingActivity(action,[newSetBooking],new Date().getTime()).then(async (res)=> {
            //this.navService.setChildNavRoot(OrderDetailsComponent, PageName.OrderDetailsComponent, PageName.ActivitiesPageComponent, { from: from, activity: newOrder });
            //this.uiService.activeView = 'ActivitiesPageRightPaneNav';
            //this.uiService.showNewActivity = false;
            //this.uiService.showRightPane = true;
            await this.setBookingDataService.fetchRealTimeSetBookingDetails(newSetBooking);
            if (this.uiService.activitiesPageTab == 'day') {
              let data = {
                id: newSetBooking.ID,
                subject: newSetBooking.subject,
                location: newSetBooking.statusString,
                scheduledStart: newSetBooking.scheduledStart,
                scheduledEnd: newSetBooking.scheduledEnd,
                type: newSetBooking.type,
              };
              this.uiService.setUIServiceData({
                view: 'new-activity', data,
              });
            }
            if (!this.uiService.toolsActivityActive) {
              this.events.publish("refreshAgenda");
            }
            else {
              this.uiService.agendaRefreshRequired = true;
            }
            if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
            //this.events.publish('activity-pane:selectActivity', newSetBooking);
            if(data && data.eventsHandler) {
              let rawDetails = {
                indskr_setbookingid: newSetBooking.ID,
              };
              // Add code to fetch real time details
              if(rawDetails) {
                this.activityOfflineService.selected = newSetBooking;
              }
              this.dynamicFormService.linkedEntityFormIsDirtyTracker = false;
              this.setBookingDataService._isCreateEditKitBookingFormDirty = false;
              if (data.isFormSaved) {
                if(data.layoverViewPushedFrom && (data.layoverViewPushedFrom == 'KitBookingfromAccountTool' || data.layoverViewPushedFrom == 'KitBookingfromContactTool')){
                  if(eventTarget == 'SUBMITKITBOOKING'){
                    if(data.layoverViewPushedFrom == 'KitBookingfromAccountTool'){
                      this.uiService.activeView = 'accountDetails';
                      this.footerService.initButtons(FooterViews.Accounts);
                      this.navService.popChildNavPageWithPageTracking();
                    }
                    else if(data.layoverViewPushedFrom == 'KitBookingfromContactTool'){
                      this.uiService.activeView = 'contactDetails';
                      this.footerService.initButtons(FooterViews.Contacts);
                      this.navService.popChildNavPageWithPageTracking();
                    }
                  }
                } else{
                  this.events.publish('activity-pane:selectActivity', newSetBooking);
                }
                data.eventsHandler(rawDetails, 'NEWDATAUPDATED');
                if(eventTarget == "SUBMITKITBOOKING") this.notificationService.notify( this.translate.instant('KIT_BOOKING_SUBMITTED'), "ContactInfoComponent", "top", ToastStyle.INFO, 3000);
                else  this.notificationService.notify( this.translate.instant('UPDATED_SUCCESS'), "ContactInfoComponent", "top", ToastStyle.INFO, 3000);
              } else {
                // this.highlightContact(null,tempC);
                data.eventsHandler(rawDetails, 'NEWDATACREATED');
                this.notificationService.notify( this.translate.instant('KIT_BOOKING_NEW_CREATED'), "DynamicFormComponent", "top", ToastStyle.INFO, 3000);
              }
            }
            this.uiService.dismissLoader();
          });
        }
      }else if(eventTarget == 'CANCELBUTTONCLICK'){
        if(data && data.displayConfirmation){
          let flag = await this.setBookingDataService.checkSetBookingDiscardConfirmationRequired().catch(err=>{});
          if(flag){
            this.dynamicFormService.linkedEntityFormIsDirtyTracker = false;
            this.dynamicFormService.okAddressMapper = {};
            this.dynamicFormService.okLookUpMapper = [];
            this.linkEntitiesReqPayload = [];
            if(data.layoverViewPushedFrom && data.layoverViewPushedFrom == 'KitBookingfromAccountTool'){
              this.uiService.activeView = 'accountDetails';
              this.footerService.initButtons(FooterViews.Accounts);
              this.navService.popChildNavPageWithPageTracking();
            }else if(data.layoverViewPushedFrom && data.layoverViewPushedFrom == 'KitBookingfromContactTool'){
              this.uiService.activeView = 'contactDetails';
              this.footerService.initButtons(FooterViews.Contacts);
              this.navService.popChildNavPageWithPageTracking();
            }else{
              this.navService.popToRootChildNavPageWithPageTracking();
              this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
              this.uiService.showRightPane = false;
              this.navService.setChildNavRightPaneView(false);
            }
          }
        }else{
          if(data.layoverViewPushedFrom && data.layoverViewPushedFrom == 'KitBookingfromAccountTool'){
            this.uiService.activeView = 'accountDetails';
            this.footerService.initButtons(FooterViews.Accounts);
            this.navService.popChildNavPageWithPageTracking();
          }else if(data.layoverViewPushedFrom && data.layoverViewPushedFrom == 'KitBookingfromContactTool'){
            this.uiService.activeView = 'contactDetails';
            this.footerService.initButtons(FooterViews.Contacts);
            this.navService.popChildNavPageWithPageTracking();
          }else{
            this.navService.popToRootChildNavPageWithPageTracking();
            this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
            this.uiService.showRightPane = false;
            this.navService.setChildNavRightPaneView(false);
          }
        }
      }
      // else if(eventTarget == 'SCRAPKITBOOKING'){
      //   if(!this.device.isOffline && data && data.formValue) {
      //     this.events.publish('deleteMeeting', new SetBookingActivity(data.formValue));
      //   }
      // }
      else if(eventTarget == 'ADDEDLINKEDENTITY' || eventTarget == 'SCRAPLINKEDENTITY'){
        this.trackingService.tracking('DoneSelected', TrackingEventNames.ACCOUNT_EDIT,true);
        if(data && data.entity && data.values) {
          this.addLinkEntitiesForKitBooking(data);
        }
      }
      else if(eventTarget == 'ISFORMDIRTY'){
        this.setBookingDataService._isCreateEditKitBookingFormDirty = data;
      }
    }
  }

  private addLinkEntitiesForKitBooking(linkEntity) {
    if (linkEntity && linkEntity['entity'] == 'indskr_setbookingproducts' && linkEntity['linkedEntityAddedNewDataId']
        && linkEntity['values'] && linkEntity['values']['statecode'] == 1
        && !_.isEmpty(this.linkEntitiesReqPayload)) {
        let idx = this.linkEntitiesReqPayload.findIndex(le => le.addedNewDataId == linkEntity['linkedEntityAddedNewDataId']);
        if(idx>-1) {
          this.linkEntitiesReqPayload.splice(idx,1);
      }
    } else if (_.isEmpty(linkEntity))
      this.linkEntitiesReqPayload = [];
    else {
      const linkEntityPayload = {
        "values": linkEntity["values"],
        "entity": linkEntity["entity"],
        "linkedEntityId": linkEntity["linkedEntityExistingDataId"],
        "addedNewDataId": linkEntity["linkedEntityAddedNewDataId"]
      }
      if (!_.isEmpty(this.linkEntitiesReqPayload)) {
        let index;
          if (linkEntity["linkedEntityExistingDataId"]) {
            index = this.linkEntitiesReqPayload.findIndex(x => x.linkedEntityId == linkEntity["linkedEntityExistingDataId"]);
          } else {
            index = this.linkEntitiesReqPayload.findIndex(x => x.addedNewDataId == linkEntity["linkedEntityAddedNewDataId"]);
          }
          if (index != -1) {
            if(linkEntity["linkedEntityAddedNewDataId"] && !linkEntity["linkedEntityExistingDataId"] && linkEntityPayload.values.statecode == 1) this.linkEntitiesReqPayload.splice(index, 1);
            else this.linkEntitiesReqPayload[index] = linkEntityPayload;
          } else this.linkEntitiesReqPayload.push(linkEntityPayload);
        } else {
          this.linkEntitiesReqPayload.push(linkEntityPayload);
        }
    }
  }

  private async handleNewQuotes() {
    try {
      let options = null;

      if (this.from === 'AccountTool') {
        options = this.createQuotesPreloadDataFromAccountTool();
      } else if (this.from === 'OpportunityManagementTool') {
        options = this.createQuotesPreloadDataFromQpportunity();
        this.quotesOfflineService.createdFrom = PageName.OpportunityManagementPage;
      }else if (this.from == 'MarketingPlanInfoopportunities') {
        options = this.createQuotesPreloadDataFromQpportunity();
        this.quotesOfflineService.createdFrom = PageName.MarketingPlanManagementPageComponent;
      }

      this.quotesOfflineService.preloadedData = options.preloadData;
      this.navService.popChildNavPageWithPageTracking();
      this.navService.pushChildNavPageWithPageTracking(NewQuoteComponent, PageName.NewQuoteComponent, PageName.QuotePageComponent, {
        nestedRoute: true,
        ...options
      });
    } catch (error) {
      console.log('Error during creation of quote')
    }
  }

  createQuotesPreloadDataFromQpportunity() {
    try {
      const { accountID, accountName, ID, opportunityName, transactionCurrencyID } = this.opportunity;
      const priceLists = this.quotesOfflineService.priceLists;
      const selectedPriceList = priceLists.find((s) => s.currencyId === transactionCurrencyID);

      let preloadData: any = {
        accountId: accountID,
        accountName,
        opportunityId: ID,
        opportunityName,
        transactionCurrencyId: transactionCurrencyID,
      };

      if (selectedPriceList) {
        preloadData = {
          ...preloadData,
          currencyName: selectedPriceList.currencyName,
        }
      }

      return { preloadData };
    } catch (error) {
      console.log('Error while setting preload data for quotes from opporunity tool');
    }
  }

  createQuotesPreloadDataFromAccountTool() {
    const { id, accountName } = this.account;

    let preloadData: any = {
      accountId: id,
      accountName
    }

    return { preloadData };
  }

  openUrl(event:any, contentBody: any) {
    if(event && event.target && (event.target.id == '' || event.target.id == undefined) || this.device.isOffline) return;
    this.activityOfflineService.openUrl(event, contentBody);
  }

  initDescriptions() {
    this.customerInquiryDescription = this.authService.user.buConfigs['indskr_customerinquirydescription'];
  }

}
