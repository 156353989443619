<ion-header>
    <ion-toolbar><label>{{ 'COMPLETE_MEETING' | translate }}</label></ion-toolbar>
</ion-header>
<ion-content #content>
  <div #contentWrapper class="wrapper">
    <div class="fields-contatiner">
      <div class="sc-field" *ngFor="let field of fields">
        <div class="field-label">{{field.label | translate}}</div>
        <div class="field-value"> {{field.value}}</div>
      </div>
    </div>
    <div *ngIf="keyMessagesData.length > 0" class="key-message-container">
      <div class="key-message-sentiment-header">
        {{'KEY_MESSAGE_SENTIMENT' | translate}}
      </div>
      <div class="key-message-sentiment-cards">
        <ion-slides #swiper  pager="true" [options]="slideOptions">
          <ion-slide *ngFor="let kmData of keyMessagesData">
            <key-message-sentiment-card
              [id]="kmData.id"
              [keyMessage]="kmData.keyMessage"
              [sentiment]="kmData.sentiment"
              (onSentimentChange)="onSentimentChange($event)"
            ></key-message-sentiment-card>
          </ion-slide>
        </ion-slides>
      </div>
    </div>
    <div class="feedback-container">
      <label class="feedback-title">{{'ACTIVITY_FEEDBACK_TITLE' | translate}}</label>
      <div class="feedback-section">
        <div class="feedback-action" [ngClass]="{'default': selectedActivityFeedback?.value != feedback.value}" *ngFor="let feedback of activityFeedbacks" (click)="selectActivityFeedback(feedback)">
          <ion-label class="feedback-label">{{feedback.label | translate}}</ion-label>
          <img class="feedback-icon" src="assets/imgs/feedback_{{feedback.icon}}.svg" alt="{{feedback.label}}" />
        </div>
      </div>
    </div>
  </div>
</ion-content>
<div class="action-bar">
  <ion-button slot="end" (click)="closeModal('submit')">
    <ion-icon class="submit-icon" src="assets/imgs/short_call_submit.svg" alt="Complete"></ion-icon> 
    {{(activityService.selectedActivity['indskr_parentcallid'] != null ? 'COMPLETE_NESTED_MEETING': 'COMPLETE') | translate}}
  </ion-button>
  <ion-button class="cncl-btn" (click)="closeModal('cancel')" fill="clear" slot="end"><ion-icon class="cncl-icon" src="assets/imgs/short_call_cancel.svg" alt="Close"></ion-icon> {{'CLOSE' | translate}}</ion-button>
</div>
