import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "../../services/authentication.service";
import { Endpoints } from "../../../config/endpoints.config";
import { ContactEvent, EventRegistration, EventRegistrationResponse } from "../../classes/customer-event/customer-event.class";
import { Contact } from "../../classes/contact/contact.class";
import { DeviceService } from "../../services/device/device.service";
import { FETCH_CONTACT_CHECKEDIN_EVENTS_QUERIES, FETCH_CONTACT_CHECKED_IN_EVENTS_CALLPLAN_TIMELINE, FETCH_CONTACT_COMPLETED_EVENTS_QUERIES } from "@omni/config/fetch-xml/contact-fetchXMLs";
import { DynamicsClientService } from "../dynamics-client/dynamics-client.service";
import { DB_KEY_PREFIXES } from "@omni/config/pouch-db.config";
import { DiskService } from "@omni/services/disk/disk.service";
import _ from "lodash";
import { EventActivity } from "@omni/classes/events-tool/event.class";
import { format, isValid } from "date-fns";
import { EventsToolService } from "@omni/services/events-tool/events-tool.service";


@Injectable({
  providedIn: 'root'
})
export class CustomerEventsDataService {
  constructor(
    private http: HttpClient, 
    private authService: AuthenticationService, 
    private device: DeviceService,
    private dynamics: DynamicsClientService,
    private disk: DiskService,
    private readonly eventsToolService: EventsToolService,
    ) {

  }

  public async getContactEventRegistrations(url: string): Promise<ContactEvent[]> {
    url = url.replace('{{positionIDs}}', this.authService.getLoggedInUserPositions().toString())
    url = url.replace('{{startDate}}', new Date().getTime().toString())
    return await this.http.get<ContactEvent[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
  }

  public async captureEventRegistrationResponse(request: EventRegistrationResponse[]): Promise<any[]> {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.customerEvents.CAPTURE_EVENT_REGISTRATION_RESPONSE;
    return await this.http.post<any[]>(url, request).toPromise();
  }

  public async getContactEventTimeline(contact: Contact): Promise<EventRegistration[]> {
    if(!this.device.isOffline) {
      let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.customerEvents.FETCH_EVENTS_AND_REGISTRATIONS.replace('{{contactId}}', contact.ID);
      await this.http.get<EventRegistration[]>(url).toPromise().then(async (response: EventRegistration[]) => {
        if (Array.isArray(response)) {
          contact.mapEvents(response);
          //save data in local DB
          const id = DB_KEY_PREFIXES.CONTACT_REGISTRATION_EVENTS_TIMELINE + contact.ID;
          if(!_.isEmpty(response) && response.length > 0) {
            const dateWithOfflineDataDuration = this.authService.getPastOfflineDataLimitDateInUTCMillisecond();
            let filteredOfflineData: EventRegistration[] = [];
            filteredOfflineData = response.filter((res)=> {
              let startDate = new Date(parseInt(res['eventStartDate'])) || undefined;
              if(isValid(startDate)) return startDate > new Date(dateWithOfflineDataDuration);
            });
            try {
              await this.disk.updateOrInsert(id, (doc) => { 
                if(!doc || !doc.raw) {
                  doc= {
                    raw:[]
                  }
                }
                doc.raw = filteredOfflineData;
                console.log(`Saved registration events for contact timeline in DB: ${response.length}`);  
                return doc;
              });
            } catch (error) {
              console.log("Error saving registration events for contact timeline in DB", error);
            }
          }
        }
      }).catch((error) => {
        console.log('Error while fetching timeline event data', error);
      });
      return;
    }
  }
  
  public async getContactCheckedInEventTimeline(contact: Contact) {
    if(!this.device.isOffline) {
      try {
        const fetchXml = FETCH_CONTACT_CHECKEDIN_EVENTS_QUERIES.fetchCheckedInEventsForContact.replace("{contactid}", contact.ID);
        const contactCheckedEventDetailsResponse = await this.dynamics.executeFetchQuery('msevtmgt_events', fetchXml);
        contact.mapCheckInEvents(contactCheckedEventDetailsResponse);
        //save data in local DB
        const id = DB_KEY_PREFIXES.CONTACT_CHECKIN_EVENTS_TIMELINE + contact.ID;
        if(!_.isEmpty(contactCheckedEventDetailsResponse) && contactCheckedEventDetailsResponse.length > 0) {
          const dateWithOfflineDataDuration = this.authService.getPastOfflineDataLimitDateInUTCMillisecond();
          let filteredOfflineData: EventActivity[] = [];
          filteredOfflineData = contactCheckedEventDetailsResponse.filter((res)=> {            
            let checkInTime = new Date(res['ac.msevtmgt_checkintime@OData.Community.Display.V1.FormattedValue']) || new Date(res['ac.msevtmgt_checkintime']) || undefined;
            if(isValid(checkInTime)) return checkInTime > new Date(dateWithOfflineDataDuration);
          });
          try {
            await this.disk.updateOrInsert(id, (doc) => { 
              if(!doc || !doc.raw) {
                doc= {
                  raw:[]
                }
              }
              doc.raw = filteredOfflineData;
              console.log(`Saved check-in events for contact timeline in DB: ${contactCheckedEventDetailsResponse.length}`);  
              return doc;
            });
          } catch (error) {
            console.log("Error saving check-in events for contact timeline in DB", error);
          }
        }
      }
      catch(error){
        console.log("Invaild Customer Timeline checked In Events data",error);
      }
    }
  }

  public async getContactCompletedEventTimeline(contact: Contact) {
    if(!this.device.isOffline) {
      try {
        const fetchXml = FETCH_CONTACT_COMPLETED_EVENTS_QUERIES.fetchCompletedEventsForContact.replace("{contactid}", contact.ID);
        const contactCompletedEventDetailsResponse = await this.dynamics.executeFetchQuery('msevtmgt_events', fetchXml);
        contact.mapCompletedEvents(contactCompletedEventDetailsResponse);
        //save data in local DB
        const id = DB_KEY_PREFIXES.CONTACT_COMPLETED_EVENTS_TIMELINE + contact.ID;
        if(!_.isEmpty(contactCompletedEventDetailsResponse) && contactCompletedEventDetailsResponse.length > 0) {
          const dateWithOfflineDataDuration = this.authService.getPastOfflineDataLimitDateInUTCMillisecond();
          let filteredOfflineData: EventActivity[] = [];
          filteredOfflineData = contactCompletedEventDetailsResponse.filter((res)=> {
            let startDate = new Date(res['msevtmgt_eventstartdate@OData.Community.Display.V1.FormattedValue']) || new Date(res['msevtmgt_eventstartdate']) || undefined;
            if(isValid(startDate)) return startDate > new Date(dateWithOfflineDataDuration);
          });
          try {
            await this.disk.updateOrInsert(id, (doc) => { 
              if(!doc || !doc.raw) {
                doc= {
                  raw:[]
                }
              }
              doc.raw = filteredOfflineData;
              console.log(`Saved completed events for contact timeline in DB: ${contactCompletedEventDetailsResponse.length}`);  
              return doc;
            });
          } catch (error) {
            console.log("Error saving completed events for contact timeline in DB", error);
          }
        }  
      }
      catch(error) {
        console.log("Invaild Customer Timeline completed Events data",error);
      }
    }
  }

  private _escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  private replaceAll(str, find, replace) {
    return str.replace(new RegExp(this._escapeRegExp(find), 'g'), replace);
  }

  public async getContactCheckedInEventCallPlanTimeline(dataRange: { from: string, to: string }, contact: Contact, repCallPlanIDs: string[], positionIDs: string[]) {
    if(!this.device.isOffline) {
      try {
        let fetchXml = FETCH_CONTACT_CHECKED_IN_EVENTS_CALLPLAN_TIMELINE.replace("{contactid}", contact.ID);
        //filter - data range
        const startDate = format(parseInt(dataRange.from),'YYYY-MM-DD');
        const endDate = format(parseInt(dataRange.to),'YYYY-MM-DD');
        fetchXml =  this.replaceAll(fetchXml,'{startDate}', startDate);
        fetchXml = this.replaceAll(fetchXml, '{endDate}', endDate);
        //filter - repCallPlanIDs
        let repCallPlanIDsString = '';
        if (!_.isEmpty(repCallPlanIDs)) {
          repCallPlanIDs.forEach(cId=> {
            repCallPlanIDsString += '<value>' + cId + '</value>';
          });
        }
        fetchXml = fetchXml.split('{filterRepCallPlanIDs}').join(repCallPlanIDsString);
        //filter - position
        let positionIDsString = '';
        if (!_.isEmpty(positionIDs)) {
          positionIDs.forEach(pId=> {
            positionIDsString += '<value>' + pId + '</value>';
          });
        }
        fetchXml = fetchXml.split('{filterPositionIDs}').join(positionIDsString);

        const contactCheckedInEventCallPlanActivities = await this.dynamics.executeFetchQuery('indskr_meetingcallplans', fetchXml);
        if (!_.isEmpty(contactCheckedInEventCallPlanActivities) && contactCheckedInEventCallPlanActivities.length > 0) {
          const eventsToolData = this.eventsToolService.eventsToolData;
          contact.callplanCheckedInEventsTimeline = [];
          contactCheckedInEventCallPlanActivities.forEach(res=>{
            const activityId = res['ac.msevtmgt_event'];
            const foundEvent = eventsToolData.find(e=> e.ID === activityId);
            if (!_.isEmpty(foundEvent)) {
              let updatedResponse = _.cloneDeep(foundEvent);
              updatedResponse['customerCallPlanId'] = res['customerCallPlanId'];
              updatedResponse['createdby'] = res['ac.createdby@OData.Community.Display.V1.FormattedValue'] || '';
              try {
                updatedResponse['_checkinTime'] = res['ac.msevtmgt_checkintime@OData.Community.Display.V1.FormattedValue'] ? new Date(res['ac.msevtmgt_checkintime@OData.Community.Display.V1.FormattedValue']) : null;
              } catch {
                updatedResponse['_checkinTime'] = res['ac.msevtmgt_checkintime@OData.Community.Display.V1.FormattedValue'] ? new Date(parseInt(res['ac.msevtmgt_checkintime@OData.Community.Display.V1.FormattedValue'])) : null;
              }
              try {
                updatedResponse['_createdOn'] = res['ac.createdon@OData.Community.Display.V1.FormattedValue'] ? new Date(res['ac.createdon@OData.Community.Display.V1.FormattedValue']) : null;
              } catch {
                updatedResponse['_createdOn'] = res['ac.createdon@OData.Community.Display.V1.FormattedValue'] ? new Date(parseInt(res['ac.createdon@OData.Community.Display.V1.FormattedValue'])) : null;
              }
              contact.callplanCheckedInEventsTimeline.push(updatedResponse);
            }
          });
        }
      }
      catch(error){
        console.log("Invaild contact Timeline checked-in events data for call plan timeline", error);
      }
    }
  }

  public async getOfflineContactEventTimeline(contact: Contact): Promise<EventRegistration[]> {
    try {
      const dbKey: string = DB_KEY_PREFIXES.CONTACT_REGISTRATION_EVENTS_TIMELINE + contact.ID;
      await this.disk.retrieve(dbKey).then((rawData) => {
        if(!_.isEmpty(rawData)) {
          //TODO: update offline timeline with updated events activity
          const registrationEvents: EventRegistration[] = rawData.raw;
          contact.mapEvents(registrationEvents);
          console.log(`Retrieved registration events for contact timeline from DB: ${registrationEvents.length}`);
        }
      });
    } catch(error) {
      console.log("Error fetching offline registration events timeline data");
    }
    return;
  }

  public async getOfflineContactCheckedInEventTimeline(contact: Contact) {
    try{
        const dbKey: string = DB_KEY_PREFIXES.CONTACT_CHECKIN_EVENTS_TIMELINE + contact.ID;
        await this.disk.retrieve(dbKey).then((rawData) => {
          if(!_.isEmpty(rawData)) {
            //TODO: update offline timeline with updated events activity
            const checkInEvents: EventActivity[] = rawData.raw;
            contact.mapCheckInEvents(checkInEvents);
            console.log(`Retrieved check-in events for contact timeline from DB: ${checkInEvents.length}`);  
          }
        });
    }catch(error) {
      console.log("Error fetching offline check-in events timeline data");
    }
    return;
  }

  public async getOfflineContactCompletedEventTimeline(contact: Contact) {
    try {
      const dbKey: string = DB_KEY_PREFIXES.CONTACT_COMPLETED_EVENTS_TIMELINE + contact.ID;
      await this.disk.retrieve(dbKey).then((rawData) => {
        if(!_.isEmpty(rawData)) {
          //TODO: update offline timeline with updated events activity
          const completedEvents: EventActivity[] = rawData.raw;
          contact.mapCompletedEvents(completedEvents);
          console.log(`Retrieved completed events for contact timeline from DB: ${completedEvents.length}`);  
        }
      });
    } catch(error) {
      console.log("Error fetching offline completed events timeline data");
    }
    return;
  }
  
}
