import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { DeviceService } from "@omni/services/device/device.service";
import * as _ from 'lodash';
import { ModalController, PopoverController } from '@ionic/angular';
import { AuthenticationService } from "@omni/services/authentication.service";
import { FormFieldType, IndFormFieldViewDataModel } from "@omni/models/indFormFieldDataModel";
import { IndDropdownListDetailModel } from "@omni/models/indDropdownListModel";
import { IndDropdownListComponent } from "../ind-dropdown-list/ind-dropdown-list";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { CustomerAssessService } from "@omni/services/customer-assess/customer-assess.service";
import { PageName } from "@omni/services/navigation/navigation.service";
import { OmniAccordionViewDataModel } from "@omni/models/omniAccordionViewDataModel";
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from "../ind-datetime-form/ind-datetime-form";
import { IndDateTimeFormViewDataModel } from "@omni/models/indDateTimeFormDataModel";
import { ActivityService } from "@omni/services/activity/activity.service";
import { DatePipe } from "@angular/common";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import moment from "moment";

@Component({
  selector: 'ind-filter-menu-modal',
  templateUrl: 'ind-filter-menu-modal.html',
  styleUrls:['ind-filter-menu-modal.scss']
})
export class IndFilterMenuModalComponent {
  @Input() viewData: any;
  ngDestroy$: Subject<boolean> = new Subject<boolean>();
  public filterMenuSectionHeader: IndSectionHeaderViewDataModel;
  public allFilterFormView: IndFilterMenuModalDataModel[] = [];
  public allFilterFormViewAccordion: OmniAccordionViewDataModel[] = [];
  public isDropDownField: boolean = false;
  public isAccordionField: boolean = false;
  public isDisplayDateField: boolean = false;
  public backgroundUploadInProgress: boolean = false;
  private _selectedItemsForAccordion: any[] = [];
  private _selectedItemsAtHome: any[] = [];
  private _selectedDate: {startDate: string, endDate: string};
  public startDateField: IndDateTimeFormViewDataModel;
  public endDateField: IndDateTimeFormViewDataModel;
  private startDateTimeValue: string;
  private startDateValue: string;
  private endDateTimeValue: string;
  private endDateValue: string;
  private isEnabledForcedHide: boolean;
  private addNumOfFilterFromDateField: number = 0;
  public prevFilterFormView: IndFilterMenuModalDataModel[] = [];

  constructor(
    public translate: TranslateService,
    public device: DeviceService,
    private readonly modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private screenOrientation: ScreenOrientation,
    public authService:AuthenticationService,
    public customerAssessService: CustomerAssessService,
    private _cd: ChangeDetectorRef,
    public activityService: ActivityService,
    private datePipe: DatePipe,
    public dateTimeFormatsService: DateTimeFormatsService,
  ){
    this.screenOrientation
    .onChange()
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
      this.modalCtrl.dismiss();
    });
  }

  ngOnInit() {
    if(!_.isEmpty(this.viewData) && !_.isEmpty(this.viewData.data)) {
      this.allFilterFormView = _.cloneDeep(this.viewData.data);
      if(this.viewData.from == PageName.CustomerAssessComponent || this.viewData.from == PageName.CustomerAssessTeamviewComponent) {
        this.isDropDownField = true;
      } else if(this.viewData.from == PageName.ConsentDetailsComponent || this.viewData.from == PageName.ContactOmnichannelEventsComponent || this.viewData.from == PageName.SurveyHistoryComponent || this.viewData.from == PageName.ContactOmnichannelOpportunitiesComponent) {
        this.isAccordionField = true;
        this.getFilterMenuOmniAccordion(this.allFilterFormView);
        // Hard Coding the isExpanded to true as there is only one category on this filter right now
        if(this.viewData.from != PageName.SurveyHistoryComponent) this.allFilterFormViewAccordion[0].isExpanded = true;
      } else if(this.viewData.from == PageName.ContactTimelineComponent || this.viewData.from === PageName.AccountTimelineComponent) {
        this.isEnabledForcedHide = true;
        this.isDisplayDateField = true;
        this.isAccordionField = true;
        this._selectedItemsAtHome = this.viewData.selectedFilters;
        this._selectedDate = this.viewData.selectedDate;
        if(!_.isEmpty(this._selectedDate.startDate) || !_.isEmpty(this._selectedDate.endDate)) {
          this.addNumOfFilterFromDateField = 1;
        }
        this.getFilterMenuOmniAccordion(this.allFilterFormView);
      }
    }
    this._initFilterMenuSectionHeader();
    if(this.isDisplayDateField) {
      this._initDateFormFields();
      //update filter main - number of filter
      this._updateFilterMainSelectedCount();
    }
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      this.backgroundUploadInProgress = inProgress;
      this._cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.unsubscribe();
  }

  // get isDirty() {
  //   return this._selectedItemsAtHome != this._selectedItemsForAccordion;
  // }

  private _initFilterMenuSectionHeader() {
    let buttons = [];
    if(this.viewData.from == PageName.CustomerAssessComponent || this.viewData.from == PageName.CustomerAssessTeamviewComponent) {
      buttons.push({
        id: "show-result",
        text: this.translate.instant('SHOW_RESULT'),
        isDisabled: this.device.isOffline,
        align: "right"
      });
      this.filterMenuSectionHeader = {
        id: 'filter-menu-section-header',
        title: this.viewData.filterTitle,
        controls: buttons
      }
    } else if(this.viewData.from == PageName.ConsentDetailsComponent) {
      buttons.push({
        id: "show-result",
        text: this.translate.instant('SHOW_RESULT'),
        isDisabled: this._isDisabledButtons(),
        align: "right"
      });
      this.filterMenuSectionHeader = {
        id: 'filter-menu-section-header',
        title: !_.isEmpty(this._selectedItemsForAccordion) ? `${this.viewData.filterTitle} (${this._selectedItemsForAccordion.length})` : this.viewData.filterTitle,
        controls: buttons
      }
    }else if(this.viewData.from == PageName.ContactOmnichannelEventsComponent) {
      buttons.push(
        {
          id: "clear-filter",
          text: this.translate.instant('CLEAR_FILTER'),
          isDisabled: this._isDisabledButtons(),
          align: "right"
        },
        {
          id: "show-result",
          text: this.translate.instant('SHOW_RESULT'),
          isDisabled: false,
          align: "right"
        });
      this.filterMenuSectionHeader = {
        id: 'filter-menu-section-header',
        title: !_.isEmpty(this._selectedItemsForAccordion) ? `${this.viewData.filterTitle} (${this._selectedItemsForAccordion.length})` : this.viewData.filterTitle,
        controls: buttons
      }
    } else if(this.viewData.from == PageName.ContactTimelineComponent || this.viewData.from === PageName.AccountTimelineComponent || this.viewData.from == PageName.SurveyHistoryComponent) {
      buttons.push({
        id: "clear-filter",
        text: this.translate.instant('CLEAR_FILTER'),
        isDisabled: this._isDisabledButtons(),
        align: "right"
      }, {
        id: "show-result",
        text: this.translate.instant('APPLY'),
        isDisabled: this._isDisabledButtons() && _.isEqual(this.allFilterFormView, this.prevFilterFormView),
        align: "right"
      });
      //count number of filter
      let titleText = this.viewData.filterTitle;
      if(!_.isEmpty(this._selectedItemsForAccordion) || this.addNumOfFilterFromDateField !=0) {
        let numOfFilter = this._selectedItemsForAccordion.length + this.addNumOfFilterFromDateField;
        titleText = `${this.viewData.filterTitle} (${numOfFilter})`;
      }
      this.filterMenuSectionHeader = {
        id: 'filter-menu-section-header',
        title: titleText,
        controls: buttons
      }
    }
    else if(this.viewData.from == PageName.ContactOmnichannelOpportunitiesComponent) {
      buttons.push(
        {
          id: "clear-filter",
          text: this.translate.instant('CLEAR_FILTER'),
          isDisabled: this._isDisabledButtons(),
          align: "right"
        },
        {
          id: "show-result",
          text: this.translate.instant('SHOW_RESULT'),
          isDisabled: false,
          align: "right"
        });
      this.filterMenuSectionHeader = {
        id: 'filter-menu-section-header',
        title: !_.isEmpty(this._selectedItemsForAccordion) ? `${this.viewData.filterTitle} (${this._selectedItemsForAccordion.length})` : this.viewData.filterTitle,
        controls: buttons
      }
    }
  }

  private _isDisabledButtons(): boolean {
    let isDisabled: boolean = false;
    if(!this.isDisplayDateField) {
      isDisabled = _.isEmpty(this._selectedItemsForAccordion);
    }else {
      isDisabled = _.isEmpty(this._selectedItemsForAccordion) && _.isEmpty(this._selectedDate.startDate) && _.isEmpty(this._selectedDate.endDate);
    }
    return isDisabled;
  }

  public onSectionHeaderControlClick(id: string) {
    switch(id) {
      case 'show-result':
        this._showResult();
        break;
      case 'clear-filter':
        this._clearFilter();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  public getFilterMenuFormField(category: IndFilterMenuModalDataModel): IndFormFieldViewDataModel {
    let selectedItemName = [];
    category.options.forEach(option=>{
      if(option.isSelected) selectedItemName.push(option.displayName);
    })
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: category.displayName,
      inputText: selectedItemName && selectedItemName.length > 1 ? `${selectedItemName[0]} +${selectedItemName.length - 1}` : selectedItemName[0],
      placeholderLabel: category.displayName,
      id: category.id,
      isReadOnly: true,
      isDisabled: this.device.isOffline || this.backgroundUploadInProgress,
      showArrow: true,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event) => this._handleFormFieldEvent(id, event, category.isMultiSelect),
    };
    return viewData;
  }

  public getFilterMenuOmniAccordion(categories: IndFilterMenuModalDataModel[]) {
    this.prevFilterFormView = _.cloneDeep(categories);
    this._selectedItemsForAccordion = [];
    categories.forEach((category) => {
      let childItems = [];
      let isExpanded: boolean = false;
      let needTranslation = category.id == "appointment-status"
      category.options.forEach(option=>{
        let item = {
          id: option.id,
          parentId: category.parentId,
          label: needTranslation ? this.translate.instant(option.displayName.toUpperCase()) : option.displayName,
          value: option.displayName,
          isChecked: option.isSelected,
          type: category.id,
          category: option.group,
        }
        if(option.isSelected) {
          this._selectedItemsForAccordion.push(item);
          isExpanded = true;
        }
        childItems.push(item);
      });
      if(category.id != 'filter-main') childItems = _.sortBy(childItems, 'label');
      
      let viewData: OmniAccordionViewDataModel;
      viewData = {
        displayType: 'Accordion',
        id: category.id,
        parentId: category.parentId,
        primaryText: category.displayName,
        isExpanded: isExpanded,
        showExpandIcon: true,
        showCollapseIcon: true,
        isSelectable: true,
        searchActiveAt: (this.viewData.from == PageName.ContactTimelineComponent || this.viewData.from === PageName.AccountTimelineComponent || this.viewData.from == PageName.SurveyHistoryComponent) ? 10 : childItems?.length + 1,
        childItems: childItems,
        isForcedHide: false,
        category: category.displayName
      };
      if(this.isEnabledForcedHide){
        if(_.isEmpty(this._selectedItemsForAccordion)) {
          if(viewData.id == 'filter-main') {
            viewData.isExpanded = true;
          }else {
            viewData.isForcedHide = true;
          }
        }else {
          if(viewData.id != 'filter-main' && this._selectedItemsForAccordion.some(item=>item.category != category.parentId)) {
            viewData.isForcedHide = true;
          }
        }
      } 
      this.allFilterFormViewAccordion.push(viewData);
    });
  }

  public toggleCategoryValue(viewData, selected) {
    let selectedIdx = this._selectedItemsForAccordion.findIndex(item => item.id == selected.id);
    if(selected.isChecked) {
      if(selectedIdx<0) this._selectedItemsForAccordion.push(selected);
    } else {
      if(selectedIdx>-1) this._selectedItemsForAccordion.splice(selectedIdx, 1);
    }
    let idxAcc = this.allFilterFormViewAccordion.findIndex((view) => view.id == viewData.id);
    let idx = this.allFilterFormView.findIndex((view) => view.id == viewData.id);
    this.allFilterFormViewAccordion[idxAcc].childItems.find(item=>item.id==selected.id).isChecked = selected.isChecked;
    this.allFilterFormView[idx].options.find(option=> option.id == selected.id).isSelected = selected.isChecked;

    if(this.isEnabledForcedHide && viewData.id == 'filter-main') {
      if(selected.isChecked) {
        this.allFilterFormViewAccordion.map(viewData=>{
          if(viewData.parentId == selected.category) {
            viewData.isForcedHide = false;
          }
        });
      }else {
        this.allFilterFormViewAccordion.map(viewData=>{
          if(viewData.parentId == selected.category) {
            viewData.isForcedHide = true;
            if(!_.isEmpty(viewData.childItems)) {
              viewData.childItems.map(item => item.isChecked = false)
            }
            viewData.primaryText = viewData.category;
          }
        });
        this.allFilterFormView.map(viewData=>{
          if(viewData.parentId == selected.category) {
            if(!_.isEmpty(viewData.options)) {
              viewData.options.map(item => item.isSelected = false)
            }
          }
        });
        this._selectedItemsForAccordion = this._selectedItemsForAccordion.filter(item => item.parentId != selected.category);
      }
    } 
    //count number of filter
    let numOfFilter: number = 0;
    if(this.isDisplayDateField) {
      if(viewData.id == 'filter-main') {
        if(!_.isEmpty(this._selectedDate.startDate) || !_.isEmpty(this._selectedDate.endDate)) {
          this.addNumOfFilterFromDateField = 1;
        }
        numOfFilter = this.allFilterFormViewAccordion[idxAcc].childItems.filter(o=>o.isChecked)?.length + this.addNumOfFilterFromDateField || 0 + this.addNumOfFilterFromDateField;
      }else {
        numOfFilter = this.allFilterFormViewAccordion[idxAcc].childItems.filter(o=>o.isChecked)?.length || 0;
      }
    }else {
      numOfFilter = this.allFilterFormViewAccordion[idxAcc].childItems.filter(o=>o.isChecked)?.length || 0;
    }    
    if(numOfFilter == 0) {
      this.allFilterFormViewAccordion[idxAcc].primaryText = `${this.allFilterFormViewAccordion[idxAcc].category}`;
    }else {
      this.allFilterFormViewAccordion[idxAcc].primaryText = `${this.allFilterFormViewAccordion[idxAcc].category} (${numOfFilter})`;
    }
    
    this._initFilterMenuSectionHeader();
  }

  private _handleFormFieldEvent(id, event, isMultiSelect: boolean = false) {
    if (id) {
      let categoryId: string = id;
      this._openDropDownList(categoryId, event, isMultiSelect);
    }
  }

  async _openDropDownList(categoryId: string, event, isMultiSelect: boolean = false) {
    let options = [];
    const targetIdx = this.allFilterFormView.findIndex(v=>v.id == categoryId);
    if(targetIdx > -1) {
      this.allFilterFormView[targetIdx].options.forEach((o) => {
        options.push({ id: o.id, title: o.displayName, isSelected: o.isSelected });
      });  
    }
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'dropDown-select',
      data: options,
      showCancelandDoneBtn: isMultiSelect,
      isMultipleSelectionEnabled: isMultiSelect,
      isReadOnly: false,
      showClearAllBtn: false,
    };
    const dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: dropdownListDetail
      },
      cssClass: 'filter-menu-dropdown-list-view',
      event: event
    });

    dropdownPopover.onDidDismiss().then((popoverData: any) => {
      const data = popoverData.data;
      if (data && data?.selectedItems && data.isDone) {
        const targetIdx = this.allFilterFormView.findIndex(v=>v.id == categoryId);
        if(targetIdx > -1) {
          this.allFilterFormView[targetIdx].options.forEach(o=>{
            o.isSelected = false;
            data.selectedItems.forEach(item => {
              if(item.isSelected && item.id == o.id) {
                o.isSelected = true;
              }
            });  
          });
        }
      }
    });
    dropdownPopover.present();
  }

  private _showResult() {
    const data = { selectedItems: this.allFilterFormView, isDone: true, selectedItemsAccordion: this._selectedItemsForAccordion, selectedDate: this._selectedDate }
    this.modalCtrl.dismiss(data);
  }

  private _clearFilter() {
    if(this.isAccordionField) {
      this._selectedItemsForAccordion = [];
      this.allFilterFormViewAccordion.forEach(accordionView => {
        if(this.isEnabledForcedHide) {
          if(accordionView.id == 'filter-main') {
            accordionView.isExpanded = true;
            accordionView.primaryText = accordionView.category;
          }else {
            accordionView.isForcedHide = true;
          }
        }    
        accordionView.childItems.forEach(item => item.isChecked = false)
      });
      this.allFilterFormView.forEach(formView => formView.options.forEach(item => item.isSelected = false));
    }
    if(this.isDisplayDateField) {
      this.addNumOfFilterFromDateField = 0;
      this._selectedDate = { startDate: '', endDate: '' };
      this._initDateFormFields();
    }
    this._initFilterMenuSectionHeader();
  }

  private _initDateFormFields() {
    let dateTimeValue = this.getFormattedDateTimeText();
    if (dateTimeValue) {
      this.startDateTimeValue = dateTimeValue.startDateTime != undefined ? dateTimeValue.startDateTime : undefined;
      this.startDateValue = dateTimeValue.startDate != undefined ? dateTimeValue.startDate : undefined;
      this.endDateTimeValue = dateTimeValue.endDateTime != undefined ? dateTimeValue.endDateTime : undefined;
      this.endDateValue = dateTimeValue.endDate != undefined ? dateTimeValue.endDate : undefined;
    }
    this.startDateField = {
      isDateField: true,
      label: this.translate.instant('DATE_FROM'),
      inputText: this.startDateValue != undefined ? this.startDateValue : '',
      customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.FilterMenuModal,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress,
      showArrow: true,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.endDateField = {
      isDateField: true,
      label: this.translate.instant('DATE_TO'),
      inputText: this.endDateValue != undefined ? this.endDateValue : '',
      customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.FilterMenuModal,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress,
      showArrow: true,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      if (this.backgroundUploadInProgress) return;
      switch (id) {
        case 'start-date-field':
          this.openStartDatePicker(event);
          break;
        case 'end-date-field':
          this.openEndDatePicker(event);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  async openStartDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.FilterMenuModal,
          startDateTimeValue: this.startDateTimeValue,
          endDateTimeValue: this.endDateTimeValue
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then((data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.startTime !='') {
        this.addNumOfFilterFromDateField = 1;

        //update filter main - number of filter
        this._updateFilterMainSelectedCount();
     
        const selectedStartDate = data.data.startTime;
        const selectedStartTime = this.dateTimeFormatsService.getFormattedTimeValue(selectedStartDate);
        // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined.
        // set 'MMM dd, yyyy' and 'en-US' to combine date and time, and changed date format will be adjusted through getFormattedDateTimeText().
        const getStartDate = this.datePipe.transform(selectedStartDate, "MMM dd, yyyy", undefined, 'en-US');
        const setCombinedStartDateTime = moment(getStartDate + ' ' + selectedStartTime).format();
        this.startDateTimeValue = setCombinedStartDateTime;
        // set end date time value
        let setCombinedEndDateTime: string;
        if(data.data.endTime !='') {
          const selectedEndDate = data.data.endTime;
          const selectedEndTime = this.dateTimeFormatsService.getFormattedTimeValue(selectedEndDate);
          const getEndDate = this.datePipe.transform(selectedEndDate, "MMM dd, yyyy", undefined, 'en-US');
          setCombinedEndDateTime = moment(getEndDate + ' ' + selectedEndTime).format();
          this.endDateTimeValue = setCombinedEndDateTime;
        }else {
          setCombinedEndDateTime = this.startDateTimeValue;
          this.endDateTimeValue = setCombinedEndDateTime;
        }
        this._selectedDate = {
          startDate: setCombinedStartDateTime,
          endDate: setCombinedEndDateTime,
        }
        this._initDateFormFields();
        this._initFilterMenuSectionHeader();
        this._cd.detectChanges();
        this._cd.markForCheck();
      }
    });
    popover.present();
      
    
  }

  async openEndDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.FilterMenuModal,
          startDateTimeValue: this.startDateTimeValue,
          endDateTimeValue: this.endDateTimeValue
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then((data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.endTime !='') {
        this.addNumOfFilterFromDateField = 1;

        //update filter main - number of filter
        this._updateFilterMainSelectedCount();
        
        const selectedEndDate = data.data.endTime;
        const selectedEndTime = this.dateTimeFormatsService.getFormattedTimeValue(selectedEndDate);
        // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined.
        // set 'MMM dd, yyyy' and 'en-US' to combine date and time, and changed date format will be adjusted through getFormattedDateTimeText().
        const getEndDate = this.datePipe.transform(selectedEndDate, "MMM dd, yyyy", undefined, 'en-US');
        const setCombinedEndDateTime = moment(getEndDate + ' ' + selectedEndTime).format();
        this.endDateTimeValue = setCombinedEndDateTime;
        // set start date time value
        let setCombinedStartDateTime: string;
        if(data.data.startTime !='') {
          const selectedStartDate = data.data.startTime;
          const selectedStartTime = this.dateTimeFormatsService.getFormattedTimeValue(selectedStartDate);
          const getStartDate = this.datePipe.transform(selectedStartDate, "MMM dd, yyyy", undefined, 'en-US');
          setCombinedStartDateTime = moment(getStartDate + ' ' + selectedStartTime).format();
          this.startDateTimeValue = setCombinedStartDateTime;
        }else {
          setCombinedStartDateTime = this.startDateTimeValue;
          this.startDateTimeValue = setCombinedStartDateTime;
        }
        this._selectedDate = {
          startDate: setCombinedStartDateTime,
          endDate: setCombinedEndDateTime,
        }
        this._initDateFormFields();
        this._initFilterMenuSectionHeader();
        this._cd.detectChanges();
        this._cd.markForCheck();
      }
    });
    popover.present();
  }

  getFormattedDateTimeText(): any {
    let startDateTimeValue: Date = this._selectedDate && this._selectedDate.startDate ? new Date(this._selectedDate.startDate) : undefined;
    let endDateTimeValue: Date = this._selectedDate && this._selectedDate.endDate ? new Date(this._selectedDate.endDate) : undefined;
    let startDay = startDateTimeValue ? this.datePipe.transform(startDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : undefined;
    let endDay = endDateTimeValue ? this.datePipe.transform(endDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : undefined;

    let dateTimeValue: any = {
      startDateTime: undefined,
      startDate: undefined,
      endDateTime: undefined,
      endDate: undefined,
    };

    dateTimeValue = {
      startDateTime: startDateTimeValue,
      startDate: startDay,
      endDateTime: endDateTimeValue,
      endDate: endDay,
    };
    return dateTimeValue;
  }

  private _updateFilterMainSelectedCount() {
    let numOfFilter: number = 0;
    if(this.isDisplayDateField) {
      let idxAcc = this.allFilterFormViewAccordion.findIndex((view) => view.id == 'filter-main');
      numOfFilter = this.allFilterFormViewAccordion[idxAcc].childItems.filter(o=>o.isChecked)?.length + this.addNumOfFilterFromDateField || 0 + this.addNumOfFilterFromDateField;
      if(numOfFilter == 0) {
        this.allFilterFormViewAccordion[idxAcc].primaryText = `${this.allFilterFormViewAccordion[idxAcc].category}`;
      }else {
        this.allFilterFormViewAccordion[idxAcc].primaryText = `${this.allFilterFormViewAccordion[idxAcc].category} (${numOfFilter})`;
      }
    }
  }
}

export interface IndFilterMenuModalDataModel {
  [x: string]: any;
  id: string;
  displayName: string;
  isMultiSelect: boolean;
  isExpanded?: boolean;
  parentId?: string;
  options: {
    id: string,
    displayName: string,
    isSelected: boolean,
    group?: string
  }[];
  controls?:{
    id: string;
    isDisabled?: boolean;
    icon?: string;
  }[];
}
  