import { Injectable, NgZone } from "@angular/core";
import { DiskService } from "../../services/disk/disk.service";
import { EventsService } from "../../services/events/events.service";
import { AuthenticationService } from "../../services/authentication.service";
import { OrderManagementService } from "../../services/order-management/order-management.service";
import { DynamicsClientService } from "../dynamics-client/dynamics-client.service";
import { fetchQueries } from "../../config/dynamics-fetchQueries";
import { DB_KEY_PREFIXES } from "../../config/pouch-db.config";
import { Country } from "../../classes/shared/country-list.class";
import { Language } from "../../classes/shared/common.class";
import { Endpoints } from "../../../config/endpoints.config";
import { HttpClient } from "@angular/common/http";
import { ChannelType, EmailActivityChannelDetails, ChannelActivityType, Channel } from "../../classes/consent/channel.class";
import { EmailActivity, EmailViewType } from "../../classes/activity/email.activity.class";
import { AppointmentActivity } from "../../classes/activity/appointment.activity.class";
import { EmailActivityParty, EmailAddress } from "../../classes/activity/email.activity.class";
import { EmailTemplateType } from "../../classes/email-templates/email-template.class";
import _, { uniqBy } from "lodash";
import { EmailService } from "../../services/email-templates/email.service";
import { ActivityService } from "../../services/activity/activity.service";
import { InMeetingAllocationComponent } from "../../components/in-meeting-allocation/in-meeting-allocation";
import { ActivityType } from "../../classes/activity/activity.class";
import { PageName, NavigationService } from "../../services/navigation/navigation.service";
import { FooterService, FooterViews } from '../../services/footer/footer.service';
import { UIService, PresentationView } from "../../services/ui/ui.service";
import { SampleActivity, CreateSampleDropRequestBody } from './../../classes/activity/sample.activity.class';
import { format, addMinutes } from "date-fns";
import { LoadingController, ModalController } from '@ionic/angular';
import { SampleDataService } from "../../data-services/sample/sample.data.service";
import { SampleService } from './../../services/sample/sample.service';
import { AccesingMode } from "../../services/case-management/case-management.service";
import { CaseActivity } from "../../classes/case-intake/case-activity.class";
import { CaseManagementService } from "../../services/case-management/case-management.service";
import { CaseType } from './../../classes/case-intake/case-utility.class';
import { ContactOfflineService } from './../../services/contact/contact.service';
import { PhoneActivity } from "../../classes/activity/phone.activity.class";
import { ConsentService } from "@omni/services/consent/consent.service";
import { EventsToolService } from "@omni/services/events-tool/events-tool.service";
import { LocalizationService } from "@omni/services/localization/localization.service";

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private countriesList: Array<Country> = [];
  public countriesWithPriceList: Country[] = [];

  private languageList: Language[] = [];
  businessUnits: {[x:string]:any,businessunitid: string,businessunitname:string}[];

  constructor(
    private disk: DiskService,
    private events: EventsService,
    public authService: AuthenticationService,
    public orderMgmtOfflineService: OrderManagementService,
    public dynamics: DynamicsClientService,
    public authenticationOfflineService: AuthenticationService,
    public http: HttpClient,
    private _ngZone: NgZone,
    private emailService: EmailService,
    private activityOfflineService: ActivityService,
    private loadingController: LoadingController,
    private sampleDataService: SampleDataService,
    private samplingOfflineService: SampleService,
    private uiService: UIService,
    private navService: NavigationService,
    private modalCtrl: ModalController,
    public caseManagementService: CaseManagementService,
    public contactService: ContactOfflineService,
    private consentService: ConsentService,
    private eventsToolService: EventsToolService,
    private localizationService: LocalizationService
  ) {

  }

  // async loadOlapData(){
  //   this.olapFacts = this.formatOlapData(this.olapData.slice())
  // }

  // formatOlapData(arrayData:any){
  //   let propArr = ['id','year',	'month',	'day',	'delay',	'contentshared',	'contentduration',	'city',	'product',	'segment','CPA','slidecount']
  //   let olapJsonData = []
  //   arrayData.forEach(meeting => {
  //     let meetingJson= {}
  //     for(var i=0;i<propArr.length;i++){
  //       meetingJson[propArr[i]] = meeting[i]
  //     }
  //     olapJsonData.push(meetingJson);
  //   });
  //   return olapJsonData;
  // }

  async getAllCountries(fullSync?: boolean, loadFromDbOnly = false) {
    if (loadFromDbOnly) {
      await this.loadOfflineDataForCountries();
    } else {
    try {
      let fetchXML = fetchQueries.countries;
      await this.dynamics.executeFetchQuery('indskr_lu_countries', fetchXML).then(async (res) => {
        console.log('countries data', res);
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.COUNTRIES, (doc) => {
          doc = {
            raw: []
          };
          doc.raw = res;
          //doc.raw.map(r=>{
          //r.lastUpdated = now.getTime();
          //})
          //doc.lastModified = now.getTime();
          return doc;
        }).then(succ => {
          // Now save to session variable
          this.mapCountriesList(res);
        }).catch(err => {
          // To DO Error Handling
        });
      }, async (error) => {
        // Check in Offline DB
        let rawCountries;
        try{
          rawCountries = await this.disk.retrieve(DB_KEY_PREFIXES.COUNTRIES);
        }catch(err){
          // To DO Error Handling
        }
        if(rawCountries && rawCountries.raw){
          this.mapCountriesList(rawCountries.raw);
        }
      })
    } catch (error) {
      // To DO Error Handling
    }
    }
  }

  async loadOfflineDataForCountries(){
    let rawCountries
    try{
      rawCountries = await this.disk.retrieve(DB_KEY_PREFIXES.COUNTRIES);
    }catch(err){
      // To DO Error Handling
      rawCountries = {raw: []}
    }
    if(rawCountries && rawCountries.raw){
      this.mapCountriesList(rawCountries.raw);
    }
  }

  private mapCountriesList(raw){
    if (Array.isArray(raw) && raw.length > 0) {
      this.countriesList = [];
      raw.forEach(rawCountry => {
        this.countriesList.push(new Country(rawCountry));
      })
    }
    this.localizationService.countryList = this.countriesList;
  }

  public get allCountriesList():Array<Country>{
    return this.countriesList;
  }

  public async fetchLanguages(loadFromDbOnly = false) {
    if (loadFromDbOnly) {
      const dbData = await this.disk.retrieve(DB_KEY_PREFIXES.LANGUAGE, true);
      if (dbData && Array.isArray(dbData.raw)) {
        for (let i = 0; i < dbData.raw.length; i++) {
          const raw = dbData.raw[i];
          let language: Language = {
              id: raw.indskr_lu_languageid,
              name: raw.indskr_languagename,
              code: raw.indskr_languagecodealpha2,
              createdon: raw.createdon,
          }
          this.languageList.push(language);
        }
      }
    } else {
    let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.GET_LANGUAGES;

    await this.http.get(url)
    .toPromise()
    .then((res) => {
        const lastUpdatedTime = new Date().getTime();
        this.mapLanguages(res, lastUpdatedTime);
    })
    .catch(() => {
        console.log("error Getting Languages")
    });
    }
  }

  public async mapLanguages(rawLanguages: any, newLastSyncedTime: number, forceFullSync = true) {
    await this._ngZone.runOutsideAngular(async() => {
        if (rawLanguages && Array.isArray(rawLanguages)) {
            if (forceFullSync) {
                //this.clear();
                    await this.disk.remove(DB_KEY_PREFIXES.LANGUAGE);
                    // TODO: handle error..
                // }
            }

            for (let i = 0; i < rawLanguages.length; i++) {
                const raw = rawLanguages[i];
                raw.lastSyncedTime = newLastSyncedTime;
                let language: Language = {
                    id: raw.indskr_lu_languageid,
                    name: raw.indskr_languagename,
                    code: raw.indskr_languagecodealpha2,
                    createdon: raw.createdon,
                }
                this.languageList.push(language);
            }
            this.localizationService.languageList = this.languageList;
            try {
                await this.disk.updateOrInsert(DB_KEY_PREFIXES.LANGUAGE, doc => ({ raw: rawLanguages }));
            } catch (error) {
                console.error('mapLanguages: ', error);
                // TODO: handle error..
            }
        } else {
            console.error('mapLanguages: Invalid raw data provided');
            // TODO: handle error..
        }
    });
  }

  public get languages() {
    return this.languageList;
  }

  public async createInMeetingEmail(selectedMessagingChannel: EmailActivityChannelDetails) : Promise<EmailActivity> {
      const scheduleTime: string = new Date().getTime().toString();
      const request: any = {
        scheduledstart: scheduleTime,
        scheduledend: scheduleTime,
        subject: 'Message',
        statuscode: 1,
        channelType: selectedMessagingChannel.channelType,
        channelId: selectedMessagingChannel.id,
        channelActivityType: selectedMessagingChannel.activityType,
        //appointmentid: this.activityOfflineService.selectedActivity.ID
      }
      if(this.activityOfflineService.selectedActivity.type == ActivityType.PhoneCall){
        request['phoneCallActivityId'] = this.activityOfflineService.selectedActivity.ID
      }
      else{
        request['appointmentid'] = this.activityOfflineService.selectedActivity.ID
      }

      const contacts = (<AppointmentActivity | PhoneActivity>this.activityOfflineService.selectedActivity).contacts;
      const contactHasMobilePhone = contacts.filter(c => c.mobilePhone !='');
      let emailAddresses: string[] = [];
      let emailParties: EmailActivityParty[] = [];
      if (contacts && contacts.length > 0) {
        for (let contact of contacts) {
          let emailAddress: EmailAddress[] = [];
          contact.emailAddressList = contact.emailAddressList || [];
          for (let ea of contact.emailAddressList) {
            if (ea.isPrimary) {
              ea.isSelected = true;
              emailAddresses.push(ea.emailAddressId);
              emailAddress.push(new EmailAddress(ea.emailAddressId, ea.emailAddress));
            } else {
              ea.isSelected = false;
            }
          }
          if (emailAddress.length === 0 && contact.emailAddressList.length > 0) {
            let ea = contact.emailAddressList[0];
            ea.isSelected = true;
            emailAddresses.push(ea.emailAddressId);
            emailAddress.push(new EmailAddress(ea.emailAddressId, ea.emailAddress));
          }
          if (this.consentService.savedChannels && this.consentService.savedChannels.length > 0 && selectedMessagingChannel.id) {
            const foundChannel: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === selectedMessagingChannel.id);
            // Share Message (Non-native and Enabled preview) can have only one customer who has mobile phone number.
            if (contactHasMobilePhone.length > 1 && foundChannel && foundChannel.indskr_externalchannel && foundChannel.indskr_enablepreview) break;
          }
          if (selectedMessagingChannel.activityType === ChannelActivityType.EMAIL && emailAddress.length === 0) continue;
          if (selectedMessagingChannel.activityType  === ChannelActivityType.FACEBOOK && (contact.indskr_facebookpsid || '') === '') continue;
          if ((selectedMessagingChannel.activityType  === ChannelActivityType.WHATSAPP || selectedMessagingChannel.activityType  === ChannelActivityType.SMS) && (contact.mobilePhone || '') === '') continue;

          let emailParty: EmailActivityParty = new EmailActivityParty();
          emailParty.allowToRemove = true;
          emailParty.contact_firstname = contact.firstName;
          emailParty.contact_lastname = contact.lastName;
          emailParty.contact_mobilephone = contact.mobilePhone;
          emailParty.contact_indskr_facebookpsid = contact.indskr_facebookpsid;
          emailParty.indskr_contactid = contact.ID;
          emailParty.emailAddresses = emailAddress;
          emailParties.push(emailParty);
        }
      }
      if (emailAddresses.length > 0) {
        request.emailAddresses = emailAddresses;
      }
      request.channelType = selectedMessagingChannel.channelType;
      if (selectedMessagingChannel.activityType != ChannelActivityType.EMAIL) {
        request.emailActivityParties = emailParties;
      }
      request.emailType = EmailTemplateType.Resource;
      let activity: EmailActivity = await this.emailService.createNewEmailActivity(request);
      this.emailService.selectedActivity = activity;
      if (activity.isSocialChannel) {
        await this.emailService.updateEmailActivityContactParties(emailParties,selectedMessagingChannel.channelType, activity.ID, selectedMessagingChannel.activityType);
      }
      if(!_.isEmpty(emailParties)){
        await this.emailService.updateEmailActivityParties(emailParties);
      }
      this.emailService.setCurrentEmail(activity);
      this.activityOfflineService.addActivity(activity);
      return activity;
  }

  public async createInMeetingAllocationOrder()  : Promise<SampleActivity> {
    let start: any = new Date(), end, orderDate: string, orderTime: string, contactID: string, addressID;
    start = format(new Date());
    if(this.authService.user && this.authService.user.buSettings && this.authService.user.buSettings['indskr_setmeetingdateallocationorderdate']) {
      if(this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.scheduledStart) {
        start = format(new Date(this.activityOfflineService.selectedActivity.scheduledStart));
      }
    }
    //start = format(Utility.getRoundedDownTime(new Date()));
    end = format(addMinutes(start, 30));
    orderTime = new Date(start).toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    let subject: string = 'Allocation Order';
    if (this.activityOfflineService.selectedActivity instanceof AppointmentActivity) {
      contactID = this.activityOfflineService.selectedActivity.contacts.length == 1 ?
        this.activityOfflineService.selectedActivity.contacts[0].ID : '';

      let thisContactHasAllocation = this.samplingOfflineService.contactCustomerSampleAllocations.some((o) => {
        if (o.contactId == contactID) {
          subject = 'Allocation Order with ' + o.contactName;
          return true;
        } else {
          return false;
        }
      })
      contactID = thisContactHasAllocation ? contactID : '';
      if (contactID) {
        let contact = this.contactService.getContactByID(contactID);
        contact.addressesList =  uniqBy( contact.addressesList, 'addressId');
        if (contact.addressesList && contact.addressesList.length == 1) {
          addressID = contact.addressesList[0].customerAddressID;
        }
        else addressID = '';
      }
    }
    if (start) {
      let orderStartTime: any = new Date(start);
      let orderEndTime: any = new Date(end);
      let sampleDropPayload: CreateSampleDropRequestBody = new CreateSampleDropRequestBody({
        scheduledStart: orderStartTime,
        scheduledEnd: orderEndTime,
        contactID: contactID,
        appointmentID: this.activityOfflineService.selectedActivity.ID,
        offlineActivityId: 'offlineSampleOrder_' + new Date().getTime(),
        subject: subject,
        addressID: addressID
      });
      sampleDropPayload.indskr_ownerid = this.authenticationOfflineService.user.systemUserID;
      let response =  await this.sampleDataService.initiateSampleOrder(sampleDropPayload);
      if(response) {
        let activity: SampleActivity = <SampleActivity>response;
        if (contactID) {
          activity.contactName = (this.activityOfflineService.selectedActivity as AppointmentActivity).contacts[0].fullName;
        }
        this.activityOfflineService.addActivity(activity);
        return activity;
      }
    }
  }

  public async createEventAllocationOrder()  : Promise<SampleActivity> {
    let start: any = new Date(), end, orderDate: string, orderTime: string, contactID: string, addressID;
    start = format(new Date());
    //start = format(Utility.getRoundedDownTime(new Date()));
    end = format(addMinutes(start, 30));
    orderTime = new Date(start).toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    let subject: string = 'Allocation Order';
    if (this.eventsToolService.selectedEventOnEventsTool) {
      contactID = this.eventsToolService.selectedEventOnEventsTool.participants.length == 1 ?
      this.eventsToolService.selectedEventOnEventsTool.participants[0].customerId : '';

      let thisContactHasAllocation = this.samplingOfflineService.contactCustomerSampleAllocations.some((o) => {
        if (o.contactId == contactID) {
          subject = 'Allocation Order with ' + o.contactName;
          return true;
        } else {
          return false;
        }
      })
      contactID = thisContactHasAllocation ? contactID : '';
      if (contactID) {
        let contact = this.contactService.getContactByID(contactID);
        contact.addressesList =  uniqBy( contact.addressesList, 'addressId');
        if (contact.addressesList && contact.addressesList.length == 1) {
          addressID = contact.addressesList[0].customerAddressID;
        }
        else addressID = '';
      }
    }
    if (start) {
      let orderStartTime: any = new Date(start);
      let orderEndTime: any = new Date(end);
      let sampleDropPayload: CreateSampleDropRequestBody = new CreateSampleDropRequestBody({
        scheduledStart: orderStartTime,
        scheduledEnd: orderEndTime,
        contactID: contactID,
        eventId: this.eventsToolService.selectedEventOnEventsTool.ID,
        offlineActivityId: 'offlineSampleOrder_' + new Date().getTime(),
        subject: subject,
        addressID: addressID
      });
      sampleDropPayload.indskr_ownerid = this.authenticationOfflineService.user.systemUserID;
      let response =  await this.sampleDataService.initiateSampleOrder(sampleDropPayload);
      if(response) {
        let activity: SampleActivity = <SampleActivity>response;
        if (contactID) {
          activity.contactName = (this.activityOfflineService.selectedActivity as AppointmentActivity).contacts[0].fullName;
        }
        this.activityOfflineService.addActivity(activity);
        return activity;
      }
    }
  }

  public async createInMeetingInquiry(source: AccesingMode, caseType: CaseType) {
    this.caseManagementService.accessedFrom = source;
    let preSelectedData: Object = {};
    preSelectedData['ID'] = this.activityOfflineService.selectedActivity.ID;
    /*
      Creating case from meeting with some data
    */
    if(this.activityOfflineService.selectedActivity['contacts'] && this.activityOfflineService.selectedActivity['contacts'].length === 1){
      preSelectedData['contacts'] = this.activityOfflineService.selectedActivity['contacts'];
    }
    if(this.activityOfflineService.selectedActivity['accounts'] && this.activityOfflineService.selectedActivity['accounts'].length === 1){
      preSelectedData['accounts'] = this.activityOfflineService.selectedActivity['accounts'];
    }
    if(source== AccesingMode.IN_SET_BOOKING){
      preSelectedData['contacts'] = this.activityOfflineService.selectedActivity["rawData"]["_indskr_surgeoncontact_value"];
      preSelectedData['accounts'] = this.activityOfflineService.selectedActivity["rawData"]["_indskr_accountnamesoldto_value"];
      preSelectedData['description'] = this.activityOfflineService.selectedActivity["subject"];
    }
    let iCase: CaseActivity = await this.caseManagementService.initNewCaseActivity(caseType, true, preSelectedData);
    this.caseManagementService.currentCase = iCase;

    this.activityOfflineService.publishActivityEvent({ action: 'Create', activity: iCase });
    // this.activityOfflineService.prevSelectedActivity = this.activityOfflineService.selectedActivity;
    // this.activityOfflineService.selectedActivity = iCase;
  }
}
