import { AgendaFooterService } from './../../../services/footer/agenda-footer.service';
import { Component, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { format, addMinutes, differenceInMinutes, subDays, differenceInCalendarDays, isFuture, isToday, isPast, differenceInMonths, isThisMonth, isThisYear } from 'date-fns';
import { DomController, PopoverController } from '@ionic/angular';
import { Events } from '@omni/events';
import { MeetingDataService, UpdateMeetingPayload } from '../../../data-services/meeting/meeting.data.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { AppointmentActivity } from '../../../classes/activity/appointment.activity.class';
import { DurationType, TimeOffActivity } from '../../../classes/activity/timeoff.class';
import { TimeOffService } from '../../../services/time-off/time-off.service';
import { UIService } from '../../../services/ui/ui.service';
import * as moment from 'moment';
import { Activity, ActivityType } from '../../../classes/activity/activity.class';
import { DeviceService } from '../../../services/device/device.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeFormatsService } from "../../../services/date-time-formats/date-time-formats.service";
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { PhoneActivity } from '../../../classes/activity/phone.activity.class';
import { PhoneCallDataService } from '../../../data-services/phone-call/phonecall.data.service';
import { DatePipe } from '@angular/common';
import { CalendarComponentOptions, DayConfig } from '@exeevo/ion2-calendar';
import { IndDateTimeFormViewDataModel, IndDateTimeFormViewErrorMessage } from '@omni/models/indDateTimeFormDataModel';
import { EventsService } from '@omni/services/events/events.service';
import { TimeOffUtilityService } from '@omni/services/time-off/time-off-utility.service';
import { EmailActivity } from '@omni/classes/activity/email.activity.class';
import { EmailService } from '@omni/services/email-templates/email.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import _ from 'lodash';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';

export enum DateTimeFieldType {
  StartDateField = 'start-date-field',
  StartTimeField = 'start-time-field',
  EndDateField = 'end-date-field',
  EndTimeField = 'end-time-field',
  TimePeriodField = 'time-period-field',
  TimeDurationField = 'time-duration-field',
  ErrorMessageField = 'error-message-field'
};
export enum CurViewPageType {
  NewActivity = 'newActivity',
  NewTimeOff = 'newTimeOff',
  Appointment = 'appointment',
  PhoneCall = 'phoneCall',
  TimeOff = 'timeOff',
  Email = 'email',
  FollowUp = 'followUp',
  SurgeryOrder = 'surgeryOrder',
  Scheduler ='scheduler',
  MarketScan = 'marketScan',
  Coaching = 'coaching',
  Asset = 'asset',
  OpportunityInfo = 'opportunityInfo',
  OpportunityQuote = 'opportunityQuote',
  Event = 'event',
  ConfiguredForm = 'configuredForm',
  None = 'none',
  AccountPlan = 'accountPlan',
  MarketingBusinessPlan = 'MarketingBusinessPlan',
  Quote = 'quote',
  FilterMenuModal = 'filterMenuModal',
  Others = 'others',
  MedicalInsight = 'medicalinsight',
  oceTracking = 'oceTracking',
  AllocationOrder = 'allocationOrder',
  StoreCheck = 'storeCheck',
  ProcedureContract ='ProcedureContract'
};

@Component({
  selector: 'ind-datetime-form',
  templateUrl: 'ind-datetime-form.html',
  styleUrls:['ind-datetime-form.scss']
})
export class IndDatetimeFormComponent {

  public startTime: string;
  public endTime: string;
  public prevStartTime: string;
  public prevEndTime: string;
  public duration: string;
  @Input() currentEvent: string = "Other";
  @Input() currentViewPage: string = "";
  public showLoader: boolean = false;
  public minStartDate: string;
  public datepickerTitle : string = this.translate.instant('DATE');
  private dateText: string = '';
  private targetDateText: string = '';

  //Date/Time Picker
  @Output() onUpdate = new EventEmitter<any>();
  @Output() onInputClick = new EventEmitter<any>();
  @Output() timeSelected = new EventEmitter<any>();
  @Input() viewData: IndDateTimeFormViewDataModel;
  @Input() errorMessageData: IndDateTimeFormViewErrorMessage;
  @Input() selectedStartDate: string;
  @Input() selectedEndDate: string;
  @Input() startDateTimeValue; // data through popover componentProps - Date Picker
  @Input() endDateTimeValue; // data through popover componentProps - Date Picker
  @Input() controlId: string = ""; // control Id from configured form - Date Picker
  @ViewChild('timePickerWithDefaultMinGap', {static:false}) timePickerWithDefaultMinGapValue; // min gap is 1 min

  optionsForStartDate: CalendarComponentOptions = {
    monthFormat: 'YYYY',
    monthPickerFormat: [this.translate.instant('JAN'), this.translate.instant('FEB'), this.translate.instant('MAR'), this.translate.instant('APR'),
                        this.translate.instant('MAY'), this.translate.instant('JUN'), this.translate.instant('JUL'), this.translate.instant('AUG'),
                        this.translate.instant('SEP'), this.translate.instant('OCT'), this.translate.instant('NOV'), this.translate.instant('DEC'),
    ],
    showMonthPicker: false
  };
  optionsForEndDate: CalendarComponentOptions = {
    monthFormat: 'YYYY',
    monthPickerFormat: [this.translate.instant('JAN'), this.translate.instant('FEB'), this.translate.instant('MAR'), this.translate.instant('APR'),
                        this.translate.instant('MAY'), this.translate.instant('JUN'), this.translate.instant('JUL'), this.translate.instant('AUG'),
                        this.translate.instant('SEP'), this.translate.instant('OCT'), this.translate.instant('NOV'), this.translate.instant('DEC'),
    ],
    showMonthPicker: false
  };
  public isSyncInProgress: boolean = false;
  public currentPickerType: string;
  public selectedDurationType: string;
  public defaultLimitedMinTimeValue: string = this.dateTimeFormatsService.timeToUpperForTimePicker == 12 ? '00:00 AM' : '00:00';
  public isEnabledDoneBtn: boolean = true;
  public ngDestroy$ = new Subject<boolean>();
  private _timeOpenedTimePicker: number;
  private _isSelectedInvalidDate: boolean = false;
  private _isUpdateDate: boolean = true;

  constructor(
    public activityService: ActivityService,
    private events: Events,
    private timeOffService: TimeOffService,
    public meetingDataService: MeetingDataService,
    private uiService: UIService,
    private device: DeviceService,
    private userService: AuthenticationService,
    public trackingService: TrackService,
    public translate:TranslateService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private notificationService: NotificationService,
    private navService: NavigationService,
    private phoneCallDataService: PhoneCallDataService,
    public popoverCtrl:PopoverController,
    private datePipe: DatePipe,
    public dom: DomController,
    public _cd: ChangeDetectorRef,
    public eventsService: EventsService,
    private timeOffUtilityService: TimeOffUtilityService,
    private emailService: EmailService,
    private agendaFooterService: AgendaFooterService,
    private authService: AuthenticationService
  ) {
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      this.isSyncInProgress = inProgress ? true : false;
      if (this.isSyncInProgress) {
        if (this.timePickerWithDefaultMinGapValue) {
          setTimeout(() => { this.timePickerWithDefaultMinGapValue.close(); }, 0);
        } else if (this.popoverCtrl) {
          setTimeout(() => { this.popoverCtrl.dismiss(null); }, 0);
        }
      }
    });
    this.dateTimeFormatsService.updateLimitedMinTime$.pipe(takeUntil(this.ngDestroy$)).subscribe((dateTime) => {
      if (dateTime && this.viewData && this.viewData.id == DateTimeFieldType.EndTimeField) {
        this.setLimitedMinutesTime(dateTime.startDateTime, dateTime.endDateTime);
      }
    });
  }

  ngOnInit() {
    if ((this.viewData && this.viewData.isNewActivityView) || this.currentViewPage == CurViewPageType.NewActivity || this.currentViewPage == CurViewPageType.NewTimeOff) {
      this.dateTimeFormatsService.isNewActivity = true;
    } else {
      this.dateTimeFormatsService.isNewActivity = false;
    }
    // new activity case
    if (this.dateTimeFormatsService.isNewActivity) {
      // update this start/end time through viewData
      if (this.viewData && this.viewData.fromViewPage == CurViewPageType.NewActivity && this.viewData.id == DateTimeFieldType.StartTimeField && this.viewData.startDateTimeValue && this.viewData.endDateTimeValue) {
        this.startTime = format(new Date(this.viewData.startDateTimeValue));
        this.endTime = format(new Date(this.viewData.endDateTimeValue));
        this.prevStartTime = format(new Date(this.viewData.startDateTimeValue));
        this.prevEndTime = format(new Date(this.viewData.endDateTimeValue));
      } else {
        // update this start/end time when opening the Date Picker
        if (this.currentViewPage == CurViewPageType.NewActivity && this.startDateTimeValue) {
            this.startTime = format(new Date(this.startDateTimeValue));
            this.prevStartTime = format(new Date(this.startDateTimeValue));
        }
        if (this.currentViewPage == CurViewPageType.NewActivity && this.endDateTimeValue) {
          this.endTime = format(new Date(this.endDateTimeValue));
          this.prevEndTime = format(new Date(this.endDateTimeValue));
        }
      }
      if (this.uiService && this.uiService.activeViewNewMeeting) {
        const currentViewPage = this._convertActiveViewToViewPageType(this.uiService.activeViewNewMeeting);
        if (currentViewPage != CurViewPageType.None) {
          this.setMinStartDate(currentViewPage);
        }
      }
      // new time off
      if (this.currentViewPage == CurViewPageType.NewTimeOff || this.uiService.activeViewNewMeeting == 'timeoff' && this.viewData && this.viewData.fromViewPage == CurViewPageType.NewTimeOff) {
        this.selectedDurationType = this.timeOffUtilityService.checkCurrentDurationType(this.timeOffUtilityService.currentDurationType);
        this.startTime = moment(this.activityService.selectedActivity['totStartTime']).format();
        this.endTime = moment(this.activityService.selectedActivity['totEndTime']).format();
        this.prevStartTime = this.startTime;
        this.prevEndTime = this.endTime;
        this.computeEventBasedDate(this.selectedDurationType);
        this.setMinStartDate(CurViewPageType.TimeOff);
      }
      this._setSelectedDateAndTime();
    }
    // selected activity case (from detail page)
    // currentViewPage is available when the date picker is opened, fromViewPage is available when the page is reloaded
    else {
      let getStartDateTimeValue = format((new Date()).setHours(0,0,0,0));
      let getEndDateTimeValue = format((new Date()).setHours(23,59,59,59));
      // set start date and time
      if (this.currentViewPage && this.startDateTimeValue) {
        getStartDateTimeValue = this.startDateTimeValue;
      } else if (this.viewData && this.viewData.startDateTimeValue) {
        getStartDateTimeValue = this.viewData.startDateTimeValue;
      }
      // set end date and time
      if (this.currentViewPage && this.endDateTimeValue) {
        getEndDateTimeValue = this.endDateTimeValue;
      } else if (this.viewData && this.viewData.endDateTimeValue) {
        getEndDateTimeValue = this.viewData.endDateTimeValue;
      } else if (this.currentViewPage == CurViewPageType.ConfiguredForm && _.isEmpty(this.endDateTimeValue) && !_.isEmpty(this.startDateTimeValue)) {
        getEndDateTimeValue = format((new Date(this.startDateTimeValue)).setHours(23,59,59,59));
      }
      this.startTime = format(new Date(getStartDateTimeValue));
      this.endTime = format(new Date(getEndDateTimeValue));
      this.prevStartTime = format(new Date(getStartDateTimeValue));
      this.prevEndTime = format(new Date(getEndDateTimeValue));

      //Accountplan
      if(this.currentViewPage == CurViewPageType.AccountPlan &&  _.isEmpty(this.endDateTimeValue)) this.prevEndTime = null;
      // Configured Form
      if (this.currentViewPage == CurViewPageType.ConfiguredForm && this.controlId != 'indskr_startdate' && this.controlId != 'indskr_enddate' ) {
        this.optionsForStartDate.showMonthPicker = true;
      } else {
        this.optionsForStartDate.showMonthPicker = false;
      }

      // Message
      if (this.viewData && this.viewData.fromViewPage == CurViewPageType.Email || this.currentViewPage == CurViewPageType.Email) {
        this.currentEvent = 'Email';
        this.endTime = this.startTime;
        this.setMinStartDate(CurViewPageType.Email);
        if (this.viewData && (this.viewData.id == DateTimeFieldType.StartDateField || this.viewData.id == DateTimeFieldType.StartTimeField)) {
          this.setLimitedMinutesTimeForMessage(this.startTime);
        }
      } else {
        // Meeting, PhoneCall
        if (this.viewData && (this.viewData.fromViewPage == CurViewPageType.Appointment || this.viewData.fromViewPage == CurViewPageType.PhoneCall)
            || this.currentViewPage == CurViewPageType.Appointment || this.currentViewPage == CurViewPageType.PhoneCall) {
          if (!(this.uiService.activeViewNewMeeting == 'surgeryorder' || this.navService.getActiveChildNavViewPageName() == PageName.SurgeryOrderDetailsComponent)) {
            this.currentEvent = "None";
          }
        }
        // Time Off
        else if (this.viewData && this.viewData.fromViewPage == CurViewPageType.TimeOff || this.currentViewPage == CurViewPageType.TimeOff) {
          this.selectedDurationType = this.timeOffUtilityService.checkCurrentDurationType(this.timeOffUtilityService.currentDurationType);
          this.computeEventBasedDate(this.selectedDurationType);
        }
        // Filter Menu Modal Show
        else if (this.currentViewPage == CurViewPageType.FilterMenuModal) {
          this.optionsForStartDate.showMonthPicker = true;
          this.optionsForEndDate.showMonthPicker = true;
        }
        // set minimum start date
        const currentViewPage = this.viewData && this.viewData.fromViewPage ? this.viewData.fromViewPage : this.currentViewPage ? this.currentViewPage : '';
        if (currentViewPage) {
          this.setMinStartDate(currentViewPage);
        }
      }
      this._setSelectedDateAndTime();
    }
    // to avoid error in selecting a time in the time picker
    if (this.dateTimeFormatsService.limitedMinTimeValue == "Invalid Date") {
      this.dateTimeFormatsService.limitedMinTimeValue = this.defaultLimitedMinTimeValue;
    }
    // set date title-month in the Date Picker view and current event
    if (this.activityService.dateTimePickerType == DateTimeFieldType.StartDateField) {
      let selectedStartDateMonth = format(this.selectedStartDate, "MMM").toUpperCase();
      if (selectedStartDateMonth == "INVALID DATE") {
        this.datepickerTitle = this.translate.instant("DATE");
      } else {
        this.datepickerTitle = this.translate.instant(selectedStartDateMonth);
      }
      this.currentPickerType = "startDateCalendarPicker";
    } else if (this.activityService.dateTimePickerType == DateTimeFieldType.EndDateField){
      let selectedEndDateMonth = format(this.selectedEndDate, "MMM").toUpperCase();
      if (selectedEndDateMonth == "INVALID DATE") {
        this.datepickerTitle = this.translate.instant("DATE");
      } else {
        this.datepickerTitle = this.translate.instant(selectedEndDateMonth);
      }
      this.currentPickerType = "endDateCalendarPicker";
    }

    this.events.subscribe("initTimePickerLimitedMinTime", (dateTime)=>{
      if (dateTime && dateTime?.status == "message_sent") {
        this.dateTimeFormatsService.limitedMinTimeValueForMessage = this.defaultLimitedMinTimeValue;
      } else if (dateTime && dateTime?.status == "message_open" && this.viewData && this.viewData.id == DateTimeFieldType.StartTimeField) {
        if (isToday(dateTime.startTime)) {
          this.setLimitedMinutesTimeForMessage(dateTime.startTime);
        } else {
          this.dateTimeFormatsService.limitedMinTimeValueForMessage = this.defaultLimitedMinTimeValue;
        }
      } else if (this.viewData && this.viewData.id == DateTimeFieldType.EndTimeField && this.viewData.fromViewPage == CurViewPageType.NewActivity) {
        this.setLimitedMinutesTime(dateTime.startDateTime, dateTime.endDateTime);
      } else if (this.viewData && this.viewData.id == DateTimeFieldType.EndTimeField && this.viewData.fromViewPage == CurViewPageType.NewTimeOff && this.selectedDurationType == DurationType.Hourly) {
        this.setLimitedMinutesTime(dateTime.startDateTime, dateTime.endDateTime);
      } else {
        if (this.viewData && this.viewData.fromViewPage != CurViewPageType.NewActivity && this.viewData.fromViewPage != CurViewPageType.NewTimeOff && this.activityService.selectedActivity && (this.activityService.selectedActivity.type == ActivityType.Appointment || this.activityService.selectedActivity.type == ActivityType.PhoneCall)) {
          this.setLimitedMinutesTime(this.activityService.selectedActivity.scheduledStart, this.activityService.selectedActivity.scheduledEnd);
        }
      }
    });
  }

  ngOnDestroy() {
    this.events.unsubscribe("initTimePickerLimitedMinTime");
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  getRoundedTime(mDate) {
    let d = mDate;
    if (d.getMinutes() < 30) {
      d.setMinutes(30);
      return d;
    } else {
      d.setMinutes(0);
      d.setHours(d.getHours() + 1);
      return d;
    }
  }

  // Time-off Handler - All Day
  updateAllDayChange(isEndDate: boolean) {
    if (isEndDate) {
      //End time is updated and now check if its valid, else revert to old state
      if(new Date(this.endTime).getTime() > new Date(this.startTime).getTime()) {
        this.startTime = moment(new Date(this.startTime).setHours(0, 0, 0 )).format();
        this.endTime = moment(new Date(this.endTime).setHours(23, 59, 59 )).format();
        this.getAllDayTimeInterval();
      }
      else {
        // this.notificationService.notify(this.translate.instant('DATE_PICKER_END_DATE_CAN_NOT_BEFORE_START_DATE'),'Date Time Picker','top',ToastStyle.DANGER);
        this.startTime = moment(this.activityService.selectedActivity['totStartTime']).format();
        this.endTime = moment(this.activityService.selectedActivity['totEndTime']).format();
        this.computeEventBasedDate(this.selectedDurationType);
      }
    }
    else {
      if (this.activityService.validateTimeOffDate(this.startTime) === false) {
        this.startTime = moment(this.activityService.selectedActivity['totStartTime']).format();
        this.endTime = moment(this.activityService.selectedActivity['totEndTime']).format();
        this.computeEventBasedDate(this.selectedDurationType);
        this._isSelectedInvalidDate = true;
      } else {
        this.startTime = moment(new Date(this.startTime).setHours(0, 0, 0 )).format();
        this.endTime = moment(new Date(this.startTime).setHours(23, 59, 59 )).format();
        this.getAllDayTimeInterval();
      }
    }
  }
  // Time-off Handler - Half Day
  handleStartHalfDayDateTime() {
    if (this.activityService.validateTimeOffDate(this.startTime) === false) {
      this.startTime = moment(this.activityService.selectedActivity['totStartTime']).format();
      this.endTime = moment(this.activityService.selectedActivity['totEndTime']).format();
      this.computeEventBasedDate(this.selectedDurationType);
      this._isSelectedInvalidDate = true;
    } else {
      let start = moment(new Date(this.startTime));
      let meridian: string = start.format('A');
      this.duration = "0.5"+" "+this.translate.instant('DAYS')

      if (meridian === "AM") {
        this.startTime = start.set('hour', 0).set('minute', 0).set('second', 0).format();
        this.endTime = start.set('hour', 11).set('minute', 59).set('second', 59).format();
      }
      else if (meridian === "PM") {
        this.startTime = start.set('hour', 12).set('minute', 0).set('second', 0).format();
        this.endTime = start.set('hour', 23).set('minute', 59).set('second', 59).format();
      }
    }
  }
  computeEventBasedDate(currentEvent: string) {
    if (currentEvent == 'All Day') {
      this.getAllDayTimeInterval();
    }
    else if (currentEvent == 'Half Day') {
      this.duration = "0.5" + this.translate.instant('DAY')
    }
    else if (currentEvent != 'Half Day' && currentEvent != 'All Day') {
      this.duration = this.dateTimeFormatsService.getFormattedTimeInterval(this.startTime, this.endTime);
    }
  }
  // All Day Time Off - Duration
  getAllDayTimeInterval() {
    let days: any = moment(new Date(this.endTime)).diff(moment(new Date(this.startTime)), 'days') + 1;
    if (days === 0 || days === 1) {
      days = "1" + this.translate.instant('DAY');
      this.duration = days;
    }
    else if (days > 1) {
      days = days + " "+ this.translate.instant('DAYS');
      this.duration = days;
    }
    else if (days < 0) {
      days = this.translate.instant('INVALID_DATE_RANGE');
      this.duration = days;
    }
  }

  // Date Picker Handler
  // month change to detect selected month for title
  public monthChange(ev) {
    let selectedMonth = ev.newMonth.months;
    switch (selectedMonth) {
      case 1:
        this.datepickerTitle = this.translate.instant('JAN');
        break;
      case 2:
        this.datepickerTitle = this.translate.instant('FEB');
        break;
      case 3:
        this.datepickerTitle = this.translate.instant('MAR');
        break;
      case 4:
        this.datepickerTitle = this.translate.instant('APR');
        break;
      case 5:
        this.datepickerTitle = this.translate.instant('MAY');
        break;
      case 6:
        this.datepickerTitle = this.translate.instant('JUN');
        break;
      case 7:
        this.datepickerTitle = this.translate.instant('JUL');
        break;
      case 8:
        this.datepickerTitle = this.translate.instant('AUG');
        break;
      case 9:
        this.datepickerTitle = this.translate.instant('SEP');
        break;
      case 10:
        this.datepickerTitle = this.translate.instant('OCT');
        break;
      case 11:
        this.datepickerTitle = this.translate.instant('NOV');
        break;
      case 12:
        this.datepickerTitle = this.translate.instant('DEC');
        break;
      default:
        this.datepickerTitle = this.translate.instant('DATE');
        break;
    }
  }
  // handle to select Start Date in the Date Picker view
  public handleStartDate(ev) {
    this._isSelectedInvalidDate = false;
    // update startTime/endTime with combined selected date and time
    const isProcedureWeekly = (this.userService.user.procedureLogDuration === 'Weekly' && (this.userService.user.hasBulkProcedureLog || this.userService.user.hasProcedureLog ));
    let tempStartDate = new Date(moment(this.selectedStartDate).format());
    let tempStartTime = new Date(this.startTime);
    tempStartDate.setHours(tempStartTime.getHours());
    tempStartDate.setMinutes(tempStartTime.getMinutes());
    tempStartDate.setSeconds(tempStartTime.getSeconds());
    tempStartDate.setMilliseconds(tempStartTime.getMilliseconds());
    this.startTime = moment(tempStartDate).format();
    let tempEndDate = new Date(moment(this.selectedEndDate).format());
    let tempEndTime = new Date(this.endTime);
    tempEndDate.setHours(tempEndTime.getHours());
    tempEndDate.setMinutes(tempEndTime.getMinutes());
    tempEndDate.setSeconds(tempEndTime.getSeconds());
    tempEndDate.setMilliseconds(tempEndTime.getMilliseconds());
    this.endTime = moment(tempEndDate).format();
    // Check selected duration type - Time Off
    this.selectedDurationType = this.timeOffUtilityService.checkCurrentDurationType(this.timeOffUtilityService.currentDurationType);
    let m = moment(this.startTime);
    if (!m.isValid()) {
      this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'Date Time Picker', 'top', ToastStyle.DANGER);
    } else {
      this._isUpdateDate = true;
      // new time off in new activity or time off in detail page
      if (this.currentViewPage == CurViewPageType.NewTimeOff || this.currentViewPage == CurViewPageType.TimeOff) {
        if (this.selectedDurationType == DurationType.AllDay) {
          this.updateAllDayChange(false);
          return;
        } else if (this.selectedDurationType == DurationType.HalfDay) {
          this.handleStartHalfDayDateTime();
          return;
        } else {
          if (this.activityService.validateTimeOffDate(this.startTime) === false) {
            this.startTime = moment(this.activityService.selectedActivity['totStartTime']).format();
            this.endTime = moment(this.activityService.selectedActivity['totEndTime']).format();
            this.computeEventBasedDate(this.selectedDurationType);
            this._isSelectedInvalidDate = true;
            return;
          }
          if (this.selectedDurationType == DurationType.Hourly) {
            this.endTime = format(addMinutes(this.startTime, 60));
          } else {
            this.endTime = format(addMinutes(this.startTime, 30));
          }
          this.duration = this.dateTimeFormatsService.getFormattedTimeInterval(this.startTime, this.endTime);
          return;
        }
      }
      // new activity - appointment, phoneCall
      else if (this.dateTimeFormatsService.isNewActivity) {
        this.setMinStartDate(CurViewPageType.Appointment);
        const diffDays = differenceInCalendarDays(this.minStartDate, this.startTime);
        if (diffDays > 0 && this.uiService.activeViewNewMeeting != "phonecall") {
          this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_CAN_NOT_SCHEDULE_MEETING', { maxMeetingStartDays: this.userService.user.maxMeetingStartDays }), 'Date Time Picker', 'top', ToastStyle.DANGER);
          // set startTime as prevStartTime due to invalid date
          this.startTime = this.prevStartTime;
        }
      }
      // selected activity
      else {
        // message
        if (this.currentViewPage == CurViewPageType.Email) {
          const diffTime: number = Math.ceil(moment(new Date(this.startTime)).diff(moment(new Date()), 'seconds'));
          if (diffTime < 0) {
            const email: EmailActivity = this.emailService.selectedActivity;
            const channelType: string = email.channelType;
            this.notificationService.notify(this.translate.instant("MESSAGE_ACTIVITY_UNABLE_SCHEDULE_EMAIL", { type: channelType }), 'Date Time Picker', 'top', ToastStyle.DANGER);
            this.startTime = this.prevStartTime;
          }
          this.endTime = this.startTime;
        }
        // procedure log
        else if (this.currentViewPage == CurViewPageType.SurgeryOrder && this.currentEvent && this.currentEvent == 'NO_PROCEDURE_START_END_TIME_SELECTION' &&
          this.startTime != "Invalid Date" && this.startTime != undefined && this.endTime != "Invalid Date" && this.endTime != undefined) {
          if (isFuture(this.startTime) && !isToday(this.startTime)) {
            this.notificationService.notify(this.translate.instant('SHEDULE_PROCEDURE_LOG_FUTURE'), 'Date Time Picker', 'top', ToastStyle.DANGER);
            this.startTime = this.prevStartTime;
          } else if (differenceInCalendarDays(this.minStartDate, this.startTime) > 0) {
            this.notificationService.notify(this.translate.instant('DATETIME_P_CANT_SHEDULE_PROCEDURE_LOG_DAYS_AGO', { Eventstartdays: this.userService.user.maxMeetingStartDays }), 'Date Time Picker', 'top', ToastStyle.DANGER);
            this.startTime = this.prevStartTime;
          } else {
            if (this.startTime != this.prevStartTime) {
              this.endTime = format(addMinutes(this.startTime, 30));
              this.computeEventBasedDate(this.currentEvent);
            }
          }
          return;
        } else if (this.currentViewPage == CurViewPageType.SurgeryOrder && isProcedureWeekly && this.startTime != "Invalid Date"
          && this.startTime != undefined && this.endTime != "Invalid Date" && this.endTime != undefined) {
          if (this.startTime != this.prevStartTime) {
            this.startTime = moment(this.startTime).startOf('week').format();
            this.endTime = moment(this.startTime).endOf('week').format();
          }
          return;
        }
        // scheduler
        else if (this.currentViewPage == CurViewPageType.Scheduler) {
          this.startTime = moment(new Date(this.startTime)).set('hour', 0).set('minute', 0).set('second', 0).format();
          const currentDateStamp = moment(new Date()).set('hour', 0).set('minute', 0).set('second', 0).format();
          const diffCurrentDays = differenceInCalendarDays(currentDateStamp, this.startTime);
          const maxStartDate = moment(new Date()).add(1, 'month').endOf('month').format();
          const diffMaxDays = differenceInCalendarDays(maxStartDate, this.startTime);
          if (diffCurrentDays > 0) {
            this.dateText = _.capitalize(this.translate.instant('START_DATE').toLowerCase());
            this.targetDateText = this.translate.instant('CURRENT_DATE').toLowerCase();;
            this.notificationService.notify(this.translate.instant('DATE_PICKER_DATE_CAN_NOT_BEFORE_TARGET_DATE', { dateText: this.dateText, targetDateText: this.targetDateText }), 'Scheduler Details', 'top', ToastStyle.DANGER);
            this.startTime = this.prevStartTime;
            return;
          } else if (diffMaxDays < 0) {
            this.dateText = _.capitalize(this.translate.instant('START_DATE').toLowerCase());
            this.targetDateText = this.translate.instant('NEXT_MONTH').toLowerCase();;
            this.notificationService.notify(this.translate.instant('DATE_PICKER_DATE_CAN_NOT_AFTER_TARGET_DATE', { dateText: this.dateText, targetDateText: this.targetDateText }), 'Scheduler Details', 'top', ToastStyle.DANGER);
            this.startTime = this.prevStartTime;
            return;
          }
          // end date is 1 week from start date
          this.endTime = moment(this.startTime).add(6, 'd').format();
          return;
        }
        // market scan
        else if (this.currentViewPage == CurViewPageType.MarketScan) {
          this.startTime = moment(new Date(this.startTime)).set('hour', 0).set('minute', 0).set('second', 0).format();
          const currentDateStamp = moment(new Date()).set('hour', 0).set('minute', 0).set('second', 0).format();
          const diffDays = differenceInCalendarDays(currentDateStamp, this.startTime);
          // if future month date is selected
          if (diffDays < 0 && !isThisMonth(this.startTime) || !isThisYear(this.startTime)) {
            this.notificationService.notify(this.translate.instant("SELECT_DATE_CURRENT_PREVIOUS_MONTH"), 'MarketScan Details', 'top', ToastStyle.DANGER);
            this.startTime = this.prevStartTime;
            return;
          }
          this.endTime = this.startTime; // endDate will be handled in market-scan-details
          return;
        }
        // coaching
        else if (this.currentViewPage == CurViewPageType.Coaching) {
          this.startTime = moment(new Date(this.startTime)).set('hour', 0).set('minute', 0).set('second', 0).format();
          this.endTime = moment(new Date(this.endTime)).set('hour', 23).set('minute', 59).set('second', 59).format();
          const diffEndDays = differenceInCalendarDays(this.endTime, this.startTime);
          if (this.authService.user.buConfigs.hasOwnProperty("indskr_futurecoaching") && this.authService.user.buConfigs.indskr_futurecoaching == true) {
            if (diffEndDays < 0) {
              this.endTime = moment(new Date(this.startTime)).set('hour', 23).set('minute', 59).set('second', 59).format();
              this.prevEndTime = this.endTime;
            }
          } else {
            const currentDateStamp = moment(new Date()).set('hour', 0).set('minute', 0).set('second', 0).format();
            const diffCurrentDays = differenceInCalendarDays(currentDateStamp, this.startTime);
            // if future month date is selected
            if (diffCurrentDays < 0) {
              this.dateText = _.capitalize(this.translate.instant('CP_PERIOD_START_DATE').toLowerCase());
              this.targetDateText = this.translate.instant('CURRENT_DATE').toLowerCase();
              this.notificationService.notify(this.translate.instant("DATE_PICKER_DATE_CAN_NOT_AFTER_TARGET_DATE", { dateText: this.dateText, targetDateText: this.targetDateText }), 'Coaching Details', 'top', ToastStyle.DANGER);
              this.startTime = this.prevStartTime;
            } else if (diffEndDays < 0) {
              this.endTime = moment(new Date(this.startTime)).set('hour', 23).set('minute', 59).set('second', 59).format();
              this.prevEndTime = this.endTime;
            }
          }
          return;
        }
        // Opportunity Quote
        else if (this.currentViewPage == CurViewPageType.OpportunityQuote) {
          this.startTime = moment(new Date(this.startTime)).set('hour', 0).set('minute', 0).set('second', 0).format();
          const diffInDays = differenceInCalendarDays(this.startTime, new Date());
          if (diffInDays < 0) {
            this.dateText = _.capitalize(this.translate.instant('EFFECTIVE_FROM').toLowerCase());
            this.targetDateText = this.translate.instant('CURRENT_DATE').toLowerCase();;
            this.notificationService.notify(this.translate.instant('DATE_PICKER_DATE_CAN_NOT_BEFORE_TARGET_DATE', { dateText: this.dateText, targetDateText: this.targetDateText }), 'Opportunity', 'top', ToastStyle.DANGER);
            this.startTime = this.prevStartTime;
            this._isSelectedInvalidDate = true;
            return;
            // selectable date is within 3 years
          } else if (diffInDays > 1096) {
            this.dateText = _.capitalize(this.translate.instant('EFFECTIVE_FROM').toLowerCase());
            this.targetDateText = this.translate.instant('THREE_YEARS').toLowerCase();;
            this.notificationService.notify(this.translate.instant('DATE_PICKER_DATE_CAN_NOT_AFTER_TARGET_DATE', { dateText: this.dateText, targetDateText: this.targetDateText }), 'Opportunity', 'top', ToastStyle.DANGER);
            this.startTime = this.prevStartTime;
            this._isSelectedInvalidDate = true;
            return;
          } else {
            this.prevStartTime = this.startTime;
            return;
          }
        }
        // Event
        else if (this.currentViewPage == CurViewPageType.Event) {
          this.setMinStartDate(CurViewPageType.Event);
          const diffDays = differenceInCalendarDays(this.minStartDate, this.startTime);
          if (diffDays > 0) {
            this.notificationService.notify(this.translate.instant('DATETIME_P_CANT_SHEDULE_EVENT_DAYS_AGO', { Eventstartdays: this.userService.user.maxMeetingStartDays }), 'Event details', 'top', ToastStyle.DANGER);
            this.startTime = this.prevStartTime;
            return;
          } else {
            if (this.startTime != this.prevStartTime) {
              this.prevStartTime = this.startTime;
              this.endTime = format(addMinutes(this.startTime, 30));
            }
            return;
          }
        }
        // Configured Form
        else if (this.currentViewPage == CurViewPageType.ConfiguredForm) {
          this.setMinStartDate(CurViewPageType.ConfiguredForm);
          if (this.startTime != this.prevStartTime) {
            this.prevStartTime = this.startTime;
            if (this.controlId == 'indskr_startdate') {
              const diffInMin = differenceInMinutes(this.endTime, this.startTime);
              if (diffInMin <= 0) {
                this.endTime = format(addMinutes(this.startTime, 10));
              }
            } else {
              this.endTime = this.startTime;
            }
          }
          return;
        }
        // Account plan
        else if (this.currentViewPage == CurViewPageType.AccountPlan) {
          this.setMinStartDate(CurViewPageType.AccountPlan);
          if (this.startTime != this.prevStartTime) {
            this.prevStartTime = this.startTime;
            if (this.controlId == 'indskr_startdate') {
              const diffInMin = differenceInMinutes(this.endTime, this.startTime);
              if (diffInMin <= 0) {
                this.endTime = format(addMinutes(this.startTime, 10));
              }
            } else {
              this.endTime = this.startTime;
            }
          }
          return;
        }
        // Quote
        else if (this.currentViewPage == CurViewPageType.Quote) {
          this.setMinStartDate(CurViewPageType.Quote);
          if (this.startTime != this.prevStartTime) {
            this.prevStartTime = this.startTime;
            if (this.controlId == 'indskr_startdate') {
              const diffInMin = differenceInMinutes(this.endTime, this.startTime);
              if (diffInMin <= 0) {
                this.endTime = format(addMinutes(this.startTime, 10));
              }
            } else {
              this.endTime = this.startTime;
            }
          }
          return;
        }
        // Filter Menu Modal View
        else if (this.currentViewPage == CurViewPageType.FilterMenuModal) {
          this.setMinStartDate(CurViewPageType.Others);
          this.startTime = moment(new Date(this.startTime)).set('hour', 0).set('minute', 0).set('second', 0).format();
          this.endTime = moment(new Date(this.endTime)).set('hour', 23).set('minute', 59).set('second', 59).format();
          const diffEndDays = differenceInCalendarDays(this.endTime, this.startTime);
          if (this.startTime != this.prevStartTime) {
            this.prevStartTime = this.startTime;
            if (diffEndDays < 0) {
              this.endTime = moment(new Date(this.startTime)).set('hour', 23).set('minute', 59).set('second', 59).format();
              this.prevEndTime = this.endTime;
            }
          }
          return;
        }
        else {
          // check min start date
          if (this.currentViewPage == CurViewPageType.PhoneCall) {
            this.setMinStartDate(CurViewPageType.PhoneCall);
          }else if(this.currentViewPage == CurViewPageType.Appointment) {
            this.setMinStartDate(CurViewPageType.Appointment);
          }else {
            this.setMinStartDate(CurViewPageType.Others);
          }
          const diffDays = differenceInCalendarDays(this.minStartDate, this.startTime);
          if (diffDays > 0 ) {
            if (this.currentViewPage == CurViewPageType.SurgeryOrder) {
              this.notificationService.notify(this.translate.instant('DATETIME_P_CANT_SHEDULE_PROCEDURE_LOG_DAYS_AGO', { Eventstartdays: this.userService.user.maxMeetingStartDays }), 'Date Time Picker', 'top', ToastStyle.DANGER);
            } else if (this.currentViewPage == CurViewPageType.Appointment) {
              this.notificationService.notify(this.translate.instant('DATETIME_P_CANT_SHEDULE_MEETING_DAYS_AGO', { Meetingstartdays: this.userService.user.maxMeetingStartDays }), 'Date Time Picker', 'top', ToastStyle.DANGER);
            }
            // set startTime as prevStartTime due to invalid date
            this.startTime = this.prevStartTime;
            this._isUpdateDate = false;
          } else {
            if (this.startTime != this.prevStartTime) {
                let addedMinutes: number = this.currentViewPage == CurViewPageType.Appointment? this.authService.user.meeting_duration : 30;
                this.endTime = format(addMinutes(this.startTime, addedMinutes));
                this.computeEventBasedDate(this.currentEvent);
            }
          }
          return;
        }
      }
    }
  }
  // handle to select end date in the Date Picker view
  public handleEndDate(ev) {
    this._isSelectedInvalidDate = false;
    // update startTime/endTime with combined selected date and time
    let tempStartDate = new Date(moment(this.selectedStartDate).format());
    let tempStartTime = new Date(this.startTime);
    tempStartDate.setHours(tempStartTime.getHours());
    tempStartDate.setMinutes(tempStartTime.getMinutes());
    tempStartDate.setSeconds(tempStartTime.getSeconds());
    tempStartDate.setMilliseconds(tempStartTime.getMilliseconds());
    this.startTime = moment(tempStartDate).format();
    let tempEndDate = new Date(moment(this.selectedEndDate).format());
    let tempEndTime = new Date(this.endTime);
    tempEndDate.setHours(tempEndTime.getHours());
    tempEndDate.setMinutes(tempEndTime.getMinutes());
    tempEndDate.setSeconds(tempEndTime.getSeconds());
    tempEndDate.setMilliseconds(tempEndTime.getMilliseconds());
    this.endTime = moment(tempEndDate).format();
    // Check selected duration type - Time Off
    this.selectedDurationType = this.timeOffUtilityService.checkCurrentDurationType(this.timeOffUtilityService.currentDurationType);
    let m = moment(this.endTime);
    if (!m.isValid()) {
      this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'Date Time Picker', 'top', ToastStyle.DANGER);
    } else {
      this._isUpdateDate = true;
      // new time off in new activity or time off in detail page
      if (this.currentViewPage == CurViewPageType.NewTimeOff || this.currentViewPage == CurViewPageType.TimeOff) {
        if (this.selectedDurationType == DurationType.AllDay) {
          this.updateAllDayChange(true);
          return;
        } else if (this.activityService.selectedActivity && this.selectedDurationType == DurationType.Hourly) {
          const diffTime: number = Math.ceil(moment(new Date(this.endTime)).diff(moment(new Date(this.startTime)), 'seconds'));
          if (diffTime < 0) {
            this.endTime = this.prevEndTime;
            return;
          } else if (diffTime == 0) {
            this.notificationService.notify(this.translate.instant('EQUAL_DATE'), 'Date Time Picker', 'top', ToastStyle.DANGER);
            this.endTime = this.prevEndTime;
            return;
          } else {
            if (this.endTime != this.prevEndTime){
              this.prevEndTime = this.endTime;
            }
            return;
          }
        }
      }
      // new activity - appointment, phoneCall
      else if (this.dateTimeFormatsService.isNewActivity || this.currentViewPage == CurViewPageType.Appointment || this.currentViewPage == CurViewPageType.PhoneCall) {
        const diffTime: number = Math.ceil(moment(new Date(this.endTime)).diff(moment(new Date(this.startTime)), 'seconds'));
          if (diffTime < 0) {
            this.dateText =  _.capitalize(this.translate.instant('END_DATE').toLowerCase());
            this.targetDateText = this.translate.instant('START_DATE').toLowerCase();
            this.notificationService.notify(this.translate.instant('DATE_PICKER_DATE_CAN_NOT_BEFORE_TARGET_DATE', {dateText: this.dateText, targetDateText: this.targetDateText}), 'Meeting', 'top', ToastStyle.DANGER);
            this.endTime = this.prevEndTime;
            return;
          } else if (diffTime == 0) {
            this.notificationService.notify(this.translate.instant('EQUAL_DATE'), 'Date Time Picker', 'top', ToastStyle.DANGER);
            this.endTime = this.prevEndTime;
            return;
          } else {
            if (this.endTime != this.prevEndTime){
              this.prevEndTime = this.endTime;
            }
            return;
          }
      }
      // follow up
      else if (this.currentViewPage == CurViewPageType.FollowUp) {
        this.startTime = this.endTime;
        const diffInDays = differenceInCalendarDays(this.endTime, new Date());
        if (diffInDays < 0) {
          this.dateText =  _.capitalize(this.translate.instant('DUE_DATE').toLowerCase());
          this.targetDateText = this.translate.instant('CURRENT_DATE').toLowerCase();
          this.notificationService.notify(this.translate.instant('DATE_PICKER_DATE_CAN_NOT_BEFORE_TARGET_DATE', {dateText: this.dateText, targetDateText: this.targetDateText}), 'Date Time Picker', 'top', ToastStyle.DANGER);
          this.endTime = this.prevEndTime;
          return;
        } else {
          if (this.endTime != this.prevEndTime){
            this.prevEndTime = this.endTime;
          }
          return;
        }
      }
      // scheduler
      else if (this.currentViewPage == CurViewPageType.Scheduler) {
        this.startTime = moment(new Date(this.startTime)).set('hour', 0).set('minute', 0).set('second', 0).format();
        this.endTime = moment(new Date(this.endTime)).set('hour', 0).set('minute', 0).set('second', 0).format();
        const diffDays = differenceInCalendarDays(this.endTime, this.startTime);
        const maxEndDays = 28; // the end date is within 4 weeks from the start date
        if (diffDays >= maxEndDays) {
          this.notificationService.notify(this.translate.instant("SCHEDULER_MAX_DURATION_WARNING"), 'Scheduler Details', 'top', ToastStyle.DANGER);
          this.endTime = this.prevEndTime;
          return;
        } else {
          if (this.endTime != this.prevEndTime){
            this.prevEndTime = this.endTime;
          }
          return;
        }
      }
      // coaching
      else if (this.currentViewPage == CurViewPageType.Coaching) {
        this.endTime = moment(new Date(this.endTime)).set('hour', 23).set('minute', 59).set('second', 59).format();
        if (this.authService.user.buConfigs.hasOwnProperty("indskr_futurecoaching") && this.authService.user.buConfigs.indskr_futurecoaching == true) {
          if (this.endTime != this.prevEndTime){
            this.prevEndTime = this.endTime;
          }
          return;
        } else {
          const currentDateStamp = moment(new Date()).set('hour', 23).set('minute', 59).set('second', 59).format();
          const diffCurrentDays = differenceInCalendarDays(currentDateStamp, this.endTime);
          if (diffCurrentDays < 0) {
            this.dateText =  _.capitalize(this.translate.instant('CP_PERIOD_END_DATE').toLowerCase());
            this.targetDateText = this.translate.instant('CURRENT_DATE').toLowerCase();
            this.notificationService.notify(this.translate.instant("DATE_PICKER_DATE_CAN_NOT_AFTER_TARGET_DATE", {dateText: this.dateText, targetDateText: this.targetDateText}), 'Coaching Details', 'top', ToastStyle.DANGER);
            this.endTime = this.prevEndTime;
            return;
          } else {
            if (this.endTime != this.prevEndTime){
              this.prevEndTime = this.endTime;
            }
            return;
          }
        }
      }
       // Account plan
       else if (this.currentViewPage == CurViewPageType.AccountPlan) {
        this.endTime = moment(new Date(this.endTime)).set('hour', 23).set('minute', 59).set('second', 59).format();
        const currentDateStamp = moment(new Date()).set('hour', 23).set('minute', 59).set('second', 59).format();
        const diffCurrentDays = differenceInCalendarDays(currentDateStamp, this.endTime);
        if (diffCurrentDays > 0) {
          this.dateText =  _.capitalize(this.translate.instant('END_DATE').toLowerCase());
          this.targetDateText = this.translate.instant('CURRENT_DATE').toLowerCase();
          this.notificationService.notify(this.translate.instant("ACCOUNT_PLAN_CANNOT_BE_CREATED_PAST"), 'New Account Plan', 'top', ToastStyle.DANGER);
          this.endTime = this.prevEndTime;
          return;
        } else {
          if (this.endTime != this.prevEndTime){
            this.prevEndTime = this.endTime;
          }
          return;
        }
      }
      else if (this.currentViewPage == CurViewPageType.MarketingBusinessPlan) {
        this.endTime = moment(new Date(this.endTime)).set('hour', 23).set('minute', 59).set('second', 59).format();
        const currentDateStamp = moment(new Date()).set('hour', 23).set('minute', 59).set('second', 59).format();
        const diffCurrentDays = differenceInCalendarDays(currentDateStamp, this.endTime);
        if (diffCurrentDays > 0) {
          this.dateText = _.capitalize(this.translate.instant('END_DATE').toLowerCase());
          this.targetDateText = this.translate.instant('CURRENT_DATE').toLowerCase();
          this.endTime = this.prevEndTime;
          return;
        } else {
          if (this.endTime != this.prevEndTime) {
            this.prevEndTime = this.endTime;
          }
          return;
        }
      }
      // Quote
      else if (this.currentViewPage == CurViewPageType.Quote) {
        this.endTime = moment(new Date(this.endTime)).set('hour', 23).set('minute', 59).set('second', 59).format();
        const currentDateStamp = moment(new Date()).set('hour', 23).set('minute', 59).set('second', 59).format();
        const diffCurrentDays = differenceInCalendarDays(currentDateStamp, this.endTime);
        if (diffCurrentDays > 0) {
          this.dateText =  _.capitalize(this.translate.instant('END_DATE').toLowerCase());
          this.targetDateText = this.translate.instant('CURRENT_DATE').toLowerCase();
          this.notificationService.notify(this.translate.instant("QUOTE_CANNOT_BE_CREATED_PAST"), 'Quote', 'top', ToastStyle.DANGER);
          this.endTime = this.prevEndTime;
          return;
        } else {
          if (this.endTime != this.prevEndTime){
            this.prevEndTime = this.endTime;
          }
          return;
        }
      }


      // Opportunity Info/Quote
      else if (this.currentViewPage == CurViewPageType.OpportunityInfo || this.currentViewPage == CurViewPageType.OpportunityQuote) {
        this.endTime = moment(new Date(this.endTime)).set('hour', 23).set('minute', 59).set('second', 59).format();
        if (this.currentViewPage == CurViewPageType.OpportunityInfo) this.startTime = this.endTime;
        const currentDateStamp = moment(new Date()).set('hour', 23).set('minute', 59).set('second', 59).format();
        const diffCurrentDays = differenceInCalendarDays(this.endTime, currentDateStamp);
        if (diffCurrentDays < 0) {
          const formmatedDateText: string = this.currentViewPage == CurViewPageType.OpportunityInfo ? 'ESTIMATED_CLOSE_DATE' : 'EFFECTIVE_TO';
          this.dateText = _.capitalize(this.translate.instant(formmatedDateText).toLowerCase());
          this.targetDateText = this.translate.instant('CURRENT_DATE').toLowerCase();
          this.notificationService.notify(this.translate.instant('DATE_PICKER_DATE_CAN_NOT_BEFORE_TARGET_DATE', {dateText: this.dateText, targetDateText: this.targetDateText}), 'Opportunity', 'top', ToastStyle.DANGER);
          this.endTime = this.prevEndTime;
          this._isSelectedInvalidDate = true;
          return;
          // selectable date is within 3 years
        } else if (diffCurrentDays > 1096) {
          const formmatedDateText: string = this.currentViewPage == CurViewPageType.OpportunityInfo ? 'ESTIMATED_CLOSE_DATE' : 'EFFECTIVE_TO';
          this.dateText = _.capitalize(this.translate.instant(formmatedDateText).toLowerCase());
          this.targetDateText = this.translate.instant('THREE_YEARS').toLowerCase();
          this.notificationService.notify(this.translate.instant('DATE_PICKER_DATE_CAN_NOT_AFTER_TARGET_DATE', {dateText: this.dateText, targetDateText: this.targetDateText}), 'Opportunity', 'top', ToastStyle.DANGER);
          this.endTime = this.prevEndTime;
          this._isSelectedInvalidDate = true;
          return;
        } else {
          if (this.endTime != this.prevEndTime){
            this.prevEndTime = this.endTime;
          }
          return;
        }
      }
      // Event
      else if (this.currentViewPage == CurViewPageType.Event) {
        this.setMinStartDate(CurViewPageType.Event);
        const diffDays = differenceInCalendarDays(this.minStartDate, this.endTime);
        if (diffDays > 0) {
          this.notificationService.notify(this.translate.instant('DATETIME_P_CANT_SHEDULE_EVENT_DAYS_AGO', {Eventstartdays : this.userService.user.maxMeetingStartDays}),'Event details', 'top', ToastStyle.DANGER);
          this.endTime = this.prevEndTime;
          return;
        } else {
          if (this.endTime != this.prevEndTime){
            this.prevEndTime = this.endTime;
          }
          return;
        }
      }
      // Configured Form
      else if (this.currentViewPage == CurViewPageType.ConfiguredForm) {
        this.setMinStartDate(CurViewPageType.ConfiguredForm);
        const diffTime: number = Math.ceil(moment(new Date(this.endTime)).diff(moment(new Date(this.startTime)), 'seconds'));
        if (diffTime < 0) {
          this.dateText = _.capitalize(this.translate.instant('END_DATE').toLowerCase());
          this.targetDateText = this.translate.instant('START_DATE').toLowerCase();
          this.notificationService.notify(this.translate.instant('DATE_PICKER_DATE_CAN_NOT_BEFORE_TARGET_DATE', {dateText: this.dateText, targetDateText: this.targetDateText}), 'Configured Form', 'top', ToastStyle.DANGER);
          this.endTime = this.prevEndTime;
          return;
        }
        if (this.endTime != this.prevEndTime){
          this.prevEndTime = this.endTime;
        }
        return;
      }
      // Customer Medical Insight
      else if (this.currentViewPage == CurViewPageType.MedicalInsight) {
        this.setMinStartDate(CurViewPageType.Others);
        this.startTime = moment(new Date(this.startTime)).set('hour', 0).set('minute', 0).set('second', 0).format();
        this.endTime = moment(new Date(this.endTime)).set('hour', 23).set('minute', 59).set('second', 59).format();
        const diffEndDays = differenceInCalendarDays(this.endTime, this.startTime);
        if (diffEndDays < 0) {
          this.dateText = _.capitalize(this.translate.instant('END_DATE').toLowerCase());
          this.targetDateText = this.translate.instant('START_DATE').toLowerCase();
          this.notificationService.notify(this.translate.instant('DATE_PICKER_DATE_CAN_NOT_BEFORE_TARGET_DATE', {dateText: this.dateText, targetDateText: this.targetDateText}), 'Filter Menu Modal View', 'top', ToastStyle.DANGER);
          this.endTime = this.prevEndTime;
          return;
        }
        if (this.endTime != this.prevEndTime){
          this.prevEndTime = this.endTime;
        }
        return;
      }
      else if (this.currentViewPage == CurViewPageType.FilterMenuModal) {
        this.setMinStartDate(CurViewPageType.Others);
        this.startTime = moment(new Date(this.startTime)).set('hour', 0).set('minute', 0).set('second', 0).format();
        this.endTime = moment(new Date(this.endTime)).set('hour', 23).set('minute', 59).set('second', 59).format();
        const diffEndDays = differenceInCalendarDays(this.endTime, this.startTime);
        if (diffEndDays < 0) {
          this.dateText = _.capitalize(this.translate.instant('END_DATE').toLowerCase());
          this.targetDateText = this.translate.instant('START_DATE').toLowerCase();
          this.notificationService.notify(this.translate.instant('DATE_PICKER_DATE_CAN_NOT_BEFORE_TARGET_DATE', {dateText: this.dateText, targetDateText: this.targetDateText}), 'Filter Menu Modal View', 'top', ToastStyle.DANGER);
          this.endTime = this.prevEndTime;
          return;
        }
        if (this.endTime != this.prevEndTime){
          this.prevEndTime = this.endTime;
        }
        return;
      }else if(this.currentViewPage == CurViewPageType.ProcedureContract){
        this.startTime = moment(new Date(this.startTime)).set('hour', 0).set('minute', 0).set('second', 0).format();
        this.endTime = moment(new Date(this.endTime)).set('hour', 23).set('minute', 59).set('second', 59).format();
      }

      else {
        const diffDays = differenceInCalendarDays(new Date(), this.startTime);
        if ((diffDays <= this.userService.user.maxMeetingStartDays) || (this.uiService.activeViewNewMeeting == 'timeoff')) {
          if (new Date(this.endTime).getTime() < new Date(this.startTime).getTime()) {
            this.endTime = this.prevEndTime;
            return;
          }
        } else {
          if (this.currentViewPage == CurViewPageType.SurgeryOrder) {
            this.notificationService.notify(this.translate.instant('DATETIME_P_CANT_SHEDULE_PROCEDURE_LOG_DAYS_AGO', {Eventstartdays : this.userService.user.maxMeetingStartDays}),'Date Time Picker', 'top', ToastStyle.DANGER);
          } else if (this.currentViewPage == CurViewPageType.Appointment || this.currentViewPage == CurViewPageType.PhoneCall) {
            this.notificationService.notify(this.translate.instant('DATETIME_P_CANT_SHEDULE_MEETING_DAYS_AGO', {Meetingstartdays : this.userService.user.maxMeetingStartDays}),'Date Time Picker', 'top', ToastStyle.DANGER);
          }
          this.endTime = this.prevEndTime;
          return;
        }
      }
    }
  }
  // handle to selected date in the Date Picker view when done button is clicked
  async onDismiss(isDone: boolean) {
    if (!this._isUpdateDate) {
      // to avoid duplicated error message
      this.popoverCtrl.dismiss(null);
      return;
    }
    this.showLoader = true;
    let data: any = {
      'startTime': '',
      'endTime': '',
      'prevStartTime': '',
      'prevEndTime': '',
    };
    // new activity
    if (this.dateTimeFormatsService.isNewActivity) {
      if (isDone) {
        data = {
          'startTime': this.startTime,
          'endTime': this.endTime
        };
        // new time off case
        if (this.currentViewPage == CurViewPageType.NewTimeOff && !this._isSelectedInvalidDate) {
          this.activityService.selectedActivity['totStartTime'] = new Date(this.startTime);
          this.activityService.selectedActivity['totEndTime'] = new Date(this.endTime);
        }
        if (this.startTime != "Invalid Date" && this.startTime != undefined && this.endTime != "Invalid Date" && this.endTime !=undefined) {
          if (this.activityService.dateTimePickerType == DateTimeFieldType.StartDateField) {
            let tempEndTime;
            tempEndTime = format(addMinutes(this.startTime, 30)); // to set limited minutes time with updated endTime
            if (this.currentViewPage != CurViewPageType.Email) {
              this.setLimitedMinutesTime(this.startTime, tempEndTime);
            }
          } else {
            if (this.currentViewPage != CurViewPageType.Email) {
              this.setLimitedMinutesTime(this.startTime, this.endTime);
            }
          }
        }
        this.showLoader = false;
        this.popoverCtrl.dismiss(data);
        return;
      } else {
        this.showLoader = false;
        this.popoverCtrl.dismiss(null);
        return;
      }
    }
    // selected activity
    const diff: number = Math.ceil(moment(new Date(this.endTime)).diff(moment(new Date(this.startTime)), 'seconds'));
    const skipDiffCheckList: string[] = [CurViewPageType.AllocationOrder];
    const isSkipDiffCheck: boolean = skipDiffCheckList.includes(this.currentViewPage);
    // end date cannot be less than start  date
    if (isDone && (diff >= 0 || isSkipDiffCheck)) {
      if (this._isSelectedInvalidDate) {
        this.showLoader = false;
        this.popoverCtrl.dismiss(null);
        return;
      }
      data = {
        'startTime': this.startTime,
        'endTime': this.endTime
      }
      // procedure log - no procedure day
      if (this.currentEvent && this.currentEvent == 'NO_PROCEDURE_START_END_TIME_SELECTION') {
        if (this.startTime != "Invalid Date" && this.startTime != undefined && this.endTime != "Invalid Date" && this.endTime != undefined) {
          if (isFuture(this.startTime) && !isToday(this.startTime)) {
            this.showLoader = false;
            this.popoverCtrl.dismiss(null);
            return;
          }
          else {
            if (this.currentViewPage != CurViewPageType.Email) {
              this.setLimitedMinutesTime(this.startTime, this.endTime);
            }
            this.showLoader = false;
            this.popoverCtrl.dismiss(data);
            return;
          }
        }
      }
      // procedure log - general
      else if (this.currentEvent && (this.currentEvent == 'GENERAL_START_AND_END_TIME_SELECTION')) {
        if (this.startTime != "Invalid Date" && this.startTime != undefined && this.endTime != "Invalid Date" && this.endTime !=undefined) {
          if (this.currentViewPage != CurViewPageType.Email) {
            this.setLimitedMinutesTime(this.startTime, this.endTime);
          }
        }
        this.showLoader = false;
        this.popoverCtrl.dismiss(data);
        return;
      }
      // appointment detail
      else if (this.currentViewPage == CurViewPageType.Appointment || this.currentViewPage == CurViewPageType.StoreCheck) {
        this.trackingService.tracking('MeetingTimeUpdate', TrackingEventNames.ACTIVITY)
        // const isUpdateStartDate: boolean = this.currentPickerType === "startDateCalendarPicker";
        data = {
          'startTime': this.startTime,
          'endTime': this.endTime,
          'prevStartTime': this.prevStartTime,
          'prevEndTime': this.prevEndTime,
        }
        this.showLoader = false;
        this.popoverCtrl.dismiss(data);
        return;
      }
      // phone call detail
      else if (this.currentViewPage == CurViewPageType.PhoneCall){
        this.showLoader = false;
        this.trackingService.tracking('PhoneCallUpdateDate', TrackingEventNames.PHONECALL)
        this.activityService.selectedActivity['totStartTime'] = new Date(this.startTime);
        this.activityService.selectedActivity['totEndTime'] = new Date(this.endTime);
        this.activityService.selectedActivity.scheduledStart = new Date(this.startTime);
        this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledStart = new Date(this.startTime);
        this.activityService.selectedActivity.scheduledEnd = new Date(this.endTime);
        this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledEnd = new Date(this.endTime);
        let phoneCallPayLoad = this.phoneCallDataService.setPhoneCallPayload(this.activityService.selectedActivity as PhoneActivity)
        await this.uiService.displayLoader();
        if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
          await this.phoneCallDataService.updatePhoneCall(this.activityService.selectedActivity as PhoneActivity, phoneCallPayLoad);
          await this.uiService.dismissLoader();
          // this.activityService.isActivityUpdate = true;
        } else {
            if (this.device.isOffline) {
              await this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity);
              await this.uiService.dismissLoader();
            }
        }
        this.activityService.selectedActivity.scheduledStartLocale = this.activityService.selectedActivity.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
        if (!this.uiService.toolsActivityActive){
          this.events.publish("refreshAgenda");
        } else this.uiService.agendaRefreshRequired = true;
        if (this.startTime != "Invalid Date" && this.startTime != undefined && this.endTime != "Invalid Date" && this.endTime !=undefined) {
          this.setLimitedMinutesTime(this.startTime, this.endTime);
        }
        this.showLoader = false;
        this.popoverCtrl.dismiss(data);
        return;
      }
      // time off detail
      else if (this.currentViewPage == CurViewPageType.TimeOff) {
        this.trackingService.tracking('TimeOffUpdateDate', TrackingEventNames.TIMEOFF)
        this.activityService.selectedActivity['totStartTime'] = new Date(this.startTime);
        this.activityService.selectedActivity['totEndTime'] = new Date(this.endTime);
        if (this.startTime != "Invalid Date" && this.startTime != undefined && this.endTime != "Invalid Date" && this.endTime !=undefined) {
          this.setLimitedMinutesTime(this.startTime, this.endTime);
        }
        this.showLoader = false;
        this.popoverCtrl.dismiss(data);
        return;
      }
      // message
      else if (this.currentViewPage == CurViewPageType.Email) {
        this.setLimitedMinutesTimeForMessage(this.startTime);
        this.showLoader = false;
        this.popoverCtrl.dismiss(data);
        return;
      }
      // Allocation order
      else if (this.currentViewPage == CurViewPageType.AllocationOrder) {
        this.endTime = format(addMinutes(this.startTime, 30));
        data = {
          'startTime': this.startTime,
          'endTime': this.endTime,
        }
        this.showLoader = false;
        this.popoverCtrl.dismiss(data);
        return;
      }else if(this.currentViewPage == CurViewPageType.ProcedureContract){
        // this.endTime = format(addMinutes(this.startTime, 30));
        data = {
          'startTime': this.startTime,
          'endTime': this.endTime,
        }
        this.showLoader = false;
        this.popoverCtrl.dismiss(data);
        return;
      }
      else {
        if (this.startTime != "Invalid Date" && this.startTime != undefined && this.endTime != "Invalid Date" && this.endTime !=undefined) {
          if (this.currentViewPage != CurViewPageType.Email && this.currentViewPage != CurViewPageType.ConfiguredForm) {
            this.setLimitedMinutesTime(this.startTime, this.endTime);
          } else if (this.currentViewPage == CurViewPageType.ConfiguredForm) {
            if (this.controlId == 'indskr_startdate' || this.controlId == 'indskr_enddate') {
              this.setLimitedMinutesTime(this.startTime, this.endTime);
            }
          }
        }
        this.showLoader = false;
        this.popoverCtrl.dismiss(data);
        return;
      }
    }
    else {
      if (isDone && diff < 0) {
        if (this._isSelectedInvalidDate) {
          this.showLoader = false;
          this.popoverCtrl.dismiss(null);
          return;
        }
        this.notificationService.notify(this.translate.instant('INVALID_DURATION'),'Date Time Picker','top',ToastStyle.DANGER);
      }
      if (this.currentViewPage == CurViewPageType.StoreCheck || (this.currentEvent && (this.currentEvent == 'GENERAL_START_AND_END_TIME_SELECTION' || this.currentEvent == 'Email'))) {
        this.showLoader = false;
        if (!isDone) {
          this.popoverCtrl.dismiss(null);
        }
        return;
      }
      if (this.currentEvent && this.currentEvent == 'NO_PROCEDURE_START_END_TIME_SELECTION') {
        this.showLoader = false;
        this.popoverCtrl.dismiss(null);
        return;
      }
      if (this.activityService.selectedActivity) {
        if (!isDone) {
          this.showLoader = false;
          this.popoverCtrl.dismiss(null);
          return;
        }
        let activity: Activity = this.activityService.selectedActivity;
        if (activity instanceof AppointmentActivity || activity instanceof PhoneActivity) {
          data = {
            'startTime': this.activityService.selectedActivity.scheduledStart,
            'endTime': this.activityService.selectedActivity.scheduledEnd
          };
        }
        else if (activity instanceof TimeOffActivity) {
          if (isDone) {
            data = {
              'startTime': activity["totStartTime"],
              'endTime': activity["totEndTime"]
            };
          } else{
            data = null;
          }
        }
      }
      if (this.startTime != "Invalid Date" && this.startTime != undefined && this.endTime != "Invalid Date" && this.endTime !=undefined) {
        if (this.currentViewPage != CurViewPageType.Email && this.currentViewPage != CurViewPageType.ConfiguredForm) {
            this.setLimitedMinutesTime(this.startTime, this.endTime);
        } else if (this.currentViewPage == CurViewPageType.ConfiguredForm) {
          if (this.controlId == 'indskr_startdate' || this.controlId == 'indskr_enddate') {
            this.setLimitedMinutesTime(this.startTime, this.endTime);
          }
        }
      }
      this.showLoader = false;
      this.popoverCtrl.dismiss(data);
      return;
    }
    this.showLoader = false;
  }

  /**  Time Picker Handler
   * ngx material time picker - if the End time is less than the limited minutes time(limitedMinTimeValue = Start time + 5min), the time picker gets broken (hour hand gets removed)
   * To avoid this issue, when set the End date, the end time is greather than the Start time + 5min should be set
   * So, if a user change the End date, the End time will be updated (meeting is +30min, time off is +60min)
   * Set limited minutes time for End time picker with selected Start time (this can make a disable to select a time before the Start time in the End time picker)
  */
  private setLimitedMinutesTime(startDateTime, endDateTime) {
    if (this.viewData && this.viewData.fromViewPage == CurViewPageType.ConfiguredForm && this.viewData.controlId != 'indskr_startdate') return;
    const start = moment(new Date(startDateTime));
    const end = moment(new Date(endDateTime));
    const isEndDateSameAsStartDate = end.isSame(start, 'days');
    const addMinForLimitedMin = format(addMinutes(startDateTime, 5)); // the end time can be set 5 minutes after the start time
    const calculatedLimiteMinTime = this.dateTimeFormatsService.getFormattedTimeValue(addMinForLimitedMin);
    this.dateTimeFormatsService.limitedMinTimeValue = isEndDateSameAsStartDate ? calculatedLimiteMinTime : this.defaultLimitedMinTimeValue ;
    this._cd.detectChanges();
  }

  private setLimitedMinutesTimeForMessage(dateTime: string) {
    const currentDateTime = moment(new Date());
    const currentDateTimeStr = currentDateTime.format();
    const scheduledDateTime = moment(new Date(dateTime));
    const isScheduledDateSameAsCurrentDate = scheduledDateTime.isSame(currentDateTime, 'days');
    let addMinForLimitedMin;
    if (isScheduledDateSameAsCurrentDate) {
      let diffTime: number = Math.ceil(moment(new Date(dateTime)).diff(moment(new Date()), 'minutes'));
      if (diffTime <= 0) {
        addMinForLimitedMin = dateTime;
      } else {
        addMinForLimitedMin = format(addMinutes(currentDateTimeStr, 1)); // the scheduled time can be set 1 minutes after the current time
      }
    }
    const calculatedLimiteMinTime = this.dateTimeFormatsService.getFormattedTimeValue(addMinForLimitedMin);
    this.dateTimeFormatsService.limitedMinTimeValueForMessage = isScheduledDateSameAsCurrentDate? calculatedLimiteMinTime : this.defaultLimitedMinTimeValue ;
    this._cd.detectChanges();
  }

  // hour selected
  public hourSelectedTimePicker(ev) {
    this.isEnabledDoneBtn = false;
    if (ev >= 0) {
      setTimeout(()=>{ this.isEnabledDoneBtn = true }, 500);
    }
  }

  // open time picker
  public openedTimePicker(viewData): void {
    this._timeOpenedTimePicker = new Date().getTime();
    this.dateTimeFormatsService.isNewActivity = viewData.isNewActivityView ? true : false;
    if (viewData && viewData.id == DateTimeFieldType.StartTimeField && viewData.fromViewPage != CurViewPageType.Email) {
      // reset the limited minutes value to avoid error related to updating end time
      this.dateTimeFormatsService.limitedMinTimeValue = this.defaultLimitedMinTimeValue;
    }
    this.startTime = (viewData && viewData.startDateTimeValue) ? format(new Date(viewData.startDateTimeValue)) : format(new Date());
    this.endTime = (viewData && viewData.endDateTimeValue) ? format(new Date(viewData.endDateTimeValue)) : format(new Date());
  }
  // close time picker
  public closedTimePicker(id: string): void {
    if (id && (id == DateTimeFieldType.StartTimeField || id == DateTimeFieldType.EndTimeField)) {
      // set limited minutes time for end time picker with selected start time
      let start;
      let end;
      // new activity
      if (this.dateTimeFormatsService.isNewActivity && this.viewData && this.viewData.startDateTimeValue && this.viewData.endDateTimeValue) {
        if (this.uiService.activeViewNewMeeting == "timeoff") {
          start = moment(new Date(this.activityService.selectedActivity['totStartTime']));
          end = moment(new Date(this.activityService.selectedActivity['totEndTime']));
        } else {
          start = moment(new Date(this.startTime));
          end = moment(new Date(this.endTime));
        }
      }
      // selected activity
      else if (!this.dateTimeFormatsService.isNewActivity && this.activityService.selectedActivity && this.activityService.selectedActivity.scheduledStart && this.activityService.selectedActivity.scheduledEnd) {
        if (this.activityService.selectedActivity.type === ActivityType.TimeOff || this.activityService.selectedActivity.type === ActivityType.TimeOffRequest) {
          start = moment(new Date(this.activityService.selectedActivity['totStartTime']));
          end = moment(new Date(this.activityService.selectedActivity['totEndTime']));
        } else {
          start = moment(new Date(this.startTime));
          end = moment(new Date(this.endTime));
        }
      }
      // Configured Form
      else if (!this.dateTimeFormatsService.isNewActivity && this.viewData && this.viewData.fromViewPage == CurViewPageType.ConfiguredForm) {
        if (this.startTime != "Invalid Date" && this.startTime != undefined) {
          start = moment(new Date(this.startTime));
          end = moment(new Date(this.endTime));
        } else {
          if (this.viewData.startDateTimeValue) start = moment(new Date(this.viewData.startDateTimeValue));
          if (this.viewData.endDateTimeValue) end = moment(new Date(this.viewData.endDateTimeValue));
        }
      }
      if (start.isValid() && end.isValid() && this.viewData.fromViewPage != CurViewPageType.Email) {
        const isEndDateSameAsStartDate = end.isSame(start, 'days')
        const addMinForLimitedMin = format(addMinutes(start, 5));
        const calculatedLimiteMinTime = this.dateTimeFormatsService.getFormattedTimeValue(addMinForLimitedMin);
        this.dateTimeFormatsService.limitedMinTimeValue = isEndDateSameAsStartDate ? calculatedLimiteMinTime : this.defaultLimitedMinTimeValue;
      }
      // Message
      if (id == DateTimeFieldType.StartTimeField && !this.dateTimeFormatsService.isNewActivity && this.activityService.selectedActivity && this.activityService.selectedActivity.type === ActivityType.Email) {
        this.setLimitedMinutesTimeForMessage(this.viewData.startDateTimeValue);
      }
      // to avoid error in selecting a time in the time picker
      if (this.dateTimeFormatsService.limitedMinTimeValue == "Invalid Date") {
        this.dateTimeFormatsService.limitedMinTimeValue = this.defaultLimitedMinTimeValue;
      }
    }
  }

  public setTimePickerValue(time: any, id: string) {
    if (!this.isEnabledDoneBtn) { return; }
    let timeData: any = {
      id: id,
      time: time,
    };
    // start time
    if (id && id == DateTimeFieldType.StartTimeField) {
      // new activity, new time-off
      if (this.dateTimeFormatsService.isNewActivity) {
        // update startTime/endTime with combined selected date and time
        // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined and set 'MMM dd, yyyy' and 'en-US' to combine date and time,
        const getStartDate = this.datePipe.transform(this.viewData.startDateTimeValue, "MMM dd, yyyy", undefined, 'en-US');
        const setCombinedDateTime = moment(getStartDate + ' ' + time).format();
        const addedTimeToCombinedDateTime = format(addMinutes(setCombinedDateTime, 30));
        let tempStartDate = new Date(setCombinedDateTime);
        let tempStartTime = new Date(setCombinedDateTime);
        tempStartDate.setHours(tempStartTime.getHours());
        tempStartDate.setMinutes(tempStartTime.getMinutes());
        tempStartDate.setSeconds(tempStartTime.getSeconds());
        tempStartDate.setMilliseconds(tempStartTime.getMilliseconds());
        this.startTime = moment(tempStartDate).format();
        // set end time
        let tempEndDate = new Date(addedTimeToCombinedDateTime);
        let tempEndTime = new Date(addedTimeToCombinedDateTime);
        tempEndDate.setHours(tempEndTime.getHours());
        tempEndDate.setMinutes(tempEndTime.getMinutes());
        tempEndDate.setSeconds(tempEndTime.getSeconds());
        tempEndDate.setMilliseconds(tempEndTime.getMilliseconds());
        this.endTime = moment(tempEndDate).format();

        timeData = {
          id: id,
          time: setCombinedDateTime
        }
        if (this.uiService.activeViewNewMeeting == "timeoff" && this.viewData && this.viewData.fromViewPage == CurViewPageType.NewTimeOff) {
          this.dateTimeFormatsService.updateSelectedNewTimeTimeOffRequest.next(timeData);
        }
        else {
          this.dateTimeFormatsService.updateSelectedTime.next(timeData);
        }
      // selected activity (in detail page)
      } else {
        /** update startTime/endTime with combined selected date and time
          * moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined and set 'MMM dd, yyyy' and 'en-US' to combine date and time,
          * value from configured form has already been formatted, thus need to convert into a valid format for moment()
        */
        let getStartDate: string = '';
        let addedMinutes: number = this.viewData && this.viewData.fromViewPage == CurViewPageType.Appointment? this.authService.user.meeting_duration : 30;
        getStartDate = this.datePipe.transform(this.viewData.startDateTimeValue, "MMM dd, yyyy", undefined, 'en-US');
        if (this.viewData && this.viewData.fromViewPage == CurViewPageType.ConfiguredForm) {
          addedMinutes = 10;
        }
        const setCombinedDateTime = moment(getStartDate + ' ' + time).format();
        const addedTimeToCombinedDateTime = format(addMinutes(setCombinedDateTime, addedMinutes));
        let tempStartDate = new Date(setCombinedDateTime);
        let tempStartTime = new Date(setCombinedDateTime);
        tempStartDate.setHours(tempStartTime.getHours());
        tempStartDate.setMinutes(tempStartTime.getMinutes());
        tempStartDate.setSeconds(tempStartTime.getSeconds());
        tempStartDate.setMilliseconds(tempStartTime.getMilliseconds());
        this.startTime = moment(tempStartDate).format();
        // set end time
        let tempEndDate = new Date(addedTimeToCombinedDateTime);
        let tempEndTime = new Date(addedTimeToCombinedDateTime);
        tempEndDate.setHours(tempEndTime.getHours());
        tempEndDate.setMinutes(tempEndTime.getMinutes());
        tempEndDate.setSeconds(tempEndTime.getSeconds());
        tempEndDate.setMilliseconds(tempEndTime.getMilliseconds());
        this.endTime = moment(tempEndDate).format();

        if(this.viewData.fromViewPage == CurViewPageType.oceTracking){
          let newFormatDate = this.datePipe.transform(new Date(), "MMM dd, yyyy", undefined, 'en-US');
          const setCombinedDateTime = moment(newFormatDate + ' ' + time).format();
          this.timeSelected.emit(setCombinedDateTime);
        }


        // procedure log
        if (this.viewData && this.viewData.fromViewPage == CurViewPageType.SurgeryOrder) {
          timeData = {
            id: id,
            time: setCombinedDateTime
          }
          this.dateTimeFormatsService.updateSelectedTimeSurgeryOrder.next(timeData);
        }
        // message
        else if (this.viewData && this.viewData.fromViewPage == CurViewPageType.Email) {
          timeData = {
            id: id,
            time: time,
            timeOpened: this._timeOpenedTimePicker
          }
          this.dateTimeFormatsService.updateSelectedTimeMessage.next(timeData);
        }
        // time-off
        else if (this.viewData && this.viewData.fromViewPage == CurViewPageType.TimeOff) {
          timeData = {
            id: id,
            time: setCombinedDateTime
          }
          this.dateTimeFormatsService.updateSelectedTimeTimeOffRequest.next(timeData);
        }
        // appointment detail
        else if (this.viewData && this.viewData.fromViewPage == CurViewPageType.Appointment) {
          this.trackingService.tracking('MeetingTimeUpdate', TrackingEventNames.ACTIVITY)
          this.activityService.selectedActivity.scheduledStart = new Date(this.startTime);
          this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledStart = new Date(this.startTime);
          this.activityService.selectedActivity.scheduledEnd = new Date(this.endTime);
          this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledEnd = new Date(this.endTime);

          let currentAppointmentActivity = this.activityService.selectedActivity;
          let payload = new UpdateMeetingPayload(
            currentAppointmentActivity.subject,
            currentAppointmentActivity.location,
            currentAppointmentActivity.scheduledStart,
            currentAppointmentActivity.scheduledEnd,
            this.authService.hasFeatureAction(FeatureActionsMap.MEETING_NOTES) ? currentAppointmentActivity.notes : null ,
            false
          );
          if (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
            this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity);
          } else {
            try {
              this.meetingDataService.updateMeeting(this.activityService.selectedActivity as AppointmentActivity, payload, false, true);
            } catch (error) {
              console.error('IndDatetimeFormComponent: setTimePickerValue: ', error);
              this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity, false, true);
            }
          }
          this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity);
          this.onUpdate.emit();
        }
        // phone call detail
        else if (this.viewData && this.viewData.fromViewPage == CurViewPageType.PhoneCall) {
          this.trackingService.tracking('PhoneCallUpdateDate', TrackingEventNames.PHONECALL)
          this.activityService.selectedActivity.scheduledStart = new Date(this.startTime);
          this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledStart = new Date(this.startTime);
          this.activityService.selectedActivity.scheduledEnd = new Date(this.endTime);
          this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledEnd = new Date(this.endTime);
          let phoneCallPayLoad = this.phoneCallDataService.setPhoneCallPayload(this.activityService.selectedActivity as PhoneActivity)
          this.uiService.displayLoader();
          if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
            this.phoneCallDataService.updatePhoneCall(this.activityService.selectedActivity as PhoneActivity, phoneCallPayLoad).then(()=>{
              this.uiService.dismissLoader();
            })
          } else {
            if (this.device.isOffline) {
              this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity).then(()=>{
                this.uiService.dismissLoader();
              })
            }
          }
          this.onUpdate.emit();
          this.activityService.selectedActivity.scheduledStartLocale = this.activityService.selectedActivity.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
          this.eventsService.publish('detectChangesOnPhoneCallActivityDetails');
        }
        // Event
        else if (this.viewData && this.viewData.fromViewPage == CurViewPageType.Event) {
          timeData = {
            id: id,
            time: setCombinedDateTime
          }
          this.dateTimeFormatsService.updateSelectedTimeEvent.next(timeData);
        }
        // Configured Form
        else if (this.viewData && this.viewData.fromViewPage == CurViewPageType.ConfiguredForm) {
          timeData = {
            id: id,
            time: setCombinedDateTime,
            addedTime: addedTimeToCombinedDateTime,
            controlId: this.viewData.controlId,
            sectionId: this.viewData.sectionId,
          }
          if (this.navService.getActiveChildNavViewPageName() === PageName.SectionSpecificComponent) {
            timeData['dataRef'] = this.viewData;
            this.dateTimeFormatsService.updateSelectedTimeConfiguredSectionForm.next(timeData);
          } else {
            this.dateTimeFormatsService.updateSelectedTimeConfiguredForm.next(timeData);
          }
        }    else if (this.viewData && this.viewData.fromViewPage == CurViewPageType.AllocationOrder) {
          timeData = {
            id: id,
            time: time,
            timeOpened: this._timeOpenedTimePicker
          }
          this.dateTimeFormatsService.updateSelectedTimeAllocationOrder.next(timeData);
        }
        // common flow
        else if ((this.startTime != "Invalid Date" && this.startTime != "Invalid date") && this.startTime != undefined) {
          /** update startTime/endTime with combined selected date and time
          * moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined and set 'MMM dd, yyyy' and 'en-US' to combine date and time,
          * value from configured form has already been formatted, thus need to convert into a valid format for moment()
          */
          const getStartDate: string = this.datePipe.transform(this.startTime, "MMM dd, yyyy", undefined, 'en-US');
          const setCombinedDateTime = moment(getStartDate + ' ' + time).format();
          this.activityService.selectedActivity.scheduledStart = new Date(setCombinedDateTime);
          // auto set end time when start time is selected
          const addedTimeToCombinedDateTime = format(addMinutes(setCombinedDateTime, 30));
          this.activityService.selectedActivity.scheduledEnd = new Date(addedTimeToCombinedDateTime);
        }
        // refresh agenda list
        if (!this.uiService.toolsActivityActive && (this.viewData?.fromViewPage !== CurViewPageType.TimeOff)) {
          this.events.publish('refreshAgenda');
        } else {
          this.uiService.agendaRefreshRequired = true;
        }
      }
    }
    // End time
    else if (id && id == DateTimeFieldType.EndTimeField) {
      // new activity, new activity-new time off
      if (this.dateTimeFormatsService.isNewActivity) {
        if (this.uiService.activeViewNewMeeting == "timeoff" && this.viewData && this.viewData.fromViewPage == CurViewPageType.NewTimeOff) {
          // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined and set 'MMM dd, yyyy' and 'en-US' to combine date and time,
          const getEndDate = this.datePipe.transform(this.viewData.endDateTimeValue, "MMM dd, yyyy", undefined, 'en-US');
          let setCombinedDateTime = moment(getEndDate + ' ' + time).format();
          const diffInMin = differenceInMinutes(setCombinedDateTime, this.viewData.startDateTimeValue);
           if (diffInMin <= 0) {
            this.notificationService.notify(this.translate.instant('TIME_PICKER_END_TIME_CAN_NOT_BEFORE_START_TIME'),'Date Time Picker','top',ToastStyle.DANGER);
            setCombinedDateTime = this.viewData.endDateTimeValue;
           }
          timeData = {
            id: id,
            time: setCombinedDateTime
          }
          this.dateTimeFormatsService.updateSelectedNewTimeTimeOffRequest.next(timeData);
        } else {
          // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined and set 'MMM dd, yyyy' and 'en-US' to combine date and time,
          const getEndDate = this.datePipe.transform(this.viewData.endDateTimeValue, "MMM dd, yyyy", undefined, 'en-US');
          let setCombinedDateTime = moment(getEndDate + ' ' + time).format();
          const diffInMin = differenceInMinutes(setCombinedDateTime, this.viewData.startDateTimeValue);
           if (diffInMin <= 0) {
            this.notificationService.notify(this.translate.instant('TIME_PICKER_END_TIME_CAN_NOT_BEFORE_START_TIME'),'Date Time Picker','top',ToastStyle.DANGER);
            setCombinedDateTime = this.viewData.endDateTimeValue;
           }
          timeData = {
            id: id,
            time: setCombinedDateTime
          }
          this.dateTimeFormatsService.updateSelectedTime.next(timeData);
        }
      // selected activity (in detail page)
      }
      // Configured Form
      else if (this.viewData && this.viewData.fromViewPage == CurViewPageType.ConfiguredForm){
        /** update startTime/endTime with combined selected date and time
          * moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined and set 'MMM dd, yyyy' and 'en-US' to combine date and time,
          * value from configured form has already been formatted, thus need to convert into a valid format for moment()
        */
        const getEndDate = this.datePipe.transform(this.viewData.endDateTimeValue, "MMM dd, yyyy", undefined, 'en-US');
        let setCombinedDateTime = moment(getEndDate + ' ' + time).format();
        let tempEndDate = new Date(setCombinedDateTime);
        let tempEndTime = new Date(setCombinedDateTime);
        tempEndDate.setHours(tempEndTime.getHours());
        tempEndDate.setMinutes(tempEndTime.getMinutes());
        tempEndDate.setSeconds(tempEndTime.getSeconds());
        tempEndDate.setMilliseconds(tempEndTime.getMilliseconds());
        this.endTime = moment(tempEndDate).format();
        this.startTime = moment(this.viewData.startDateTimeValue).format();

        timeData = {
          id: id,
          time: setCombinedDateTime,
          controlId: this.viewData.controlId
        }
        this.dateTimeFormatsService.updateSelectedTimeConfiguredForm.next(timeData);
      }
      else {
        /** update startTime/endTime with combined selected date and time
                  * moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined and set 'MMM dd, yyyy' and 'en-US' to combine date and time,
                  * value from configured form has already been formatted, thus need to convert into a valid format for moment()
                */
        let getEndDate: string = '';
        getEndDate = this.datePipe.transform(this.viewData.endDateTimeValue, "MMM dd, yyyy", undefined, 'en-US');
        let setCombinedDateTime = moment(getEndDate + ' ' + time).format();
        const diffInMin = differenceInMinutes(setCombinedDateTime, this.viewData.startDateTimeValue);
        if (diffInMin <= 0) {
          this.notificationService.notify(this.translate.instant('TIME_PICKER_END_TIME_CAN_NOT_BEFORE_START_TIME'),'Date Time Picker','top',ToastStyle.DANGER);
          setCombinedDateTime = this.viewData.endDateTimeValue;
          let tempEndDate = new Date(setCombinedDateTime);
          let tempEndTime = new Date(setCombinedDateTime);
          tempEndDate.setHours(tempEndTime.getHours());
          tempEndDate.setMinutes(tempEndTime.getMinutes());
          tempEndDate.setSeconds(tempEndTime.getSeconds());
          tempEndDate.setMilliseconds(tempEndTime.getMilliseconds());
          this.endTime = moment(tempEndDate).format();
          this.startTime = moment(this.viewData.startDateTimeValue).format();
          return;
        }
        let tempEndDate = new Date(setCombinedDateTime);
        let tempEndTime = new Date(setCombinedDateTime);
        tempEndDate.setHours(tempEndTime.getHours());
        tempEndDate.setMinutes(tempEndTime.getMinutes());
        tempEndDate.setSeconds(tempEndTime.getSeconds());
        tempEndDate.setMilliseconds(tempEndTime.getMilliseconds());
        this.endTime = moment(tempEndDate).format();
        this.startTime = moment(this.viewData.startDateTimeValue).format();
        // procedure log
        if (this.viewData && this.viewData.fromViewPage == CurViewPageType.SurgeryOrder) {
          timeData = {
            id: id,
            time: setCombinedDateTime
          }
          this.dateTimeFormatsService.updateSelectedTimeSurgeryOrder.next(timeData);
        }
        // time-off
        else if (this.viewData && this.viewData.fromViewPage == CurViewPageType.TimeOff) {
          timeData = {
            id: id,
            time: setCombinedDateTime
          }
          this.dateTimeFormatsService.updateSelectedTimeTimeOffRequest.next(timeData);
        }
        // appointment detail
        else if (this.viewData && this.viewData.fromViewPage == CurViewPageType.Appointment) {
          this.trackingService.tracking('MeetingTimeUpdate', TrackingEventNames.ACTIVITY)
          this.activityService.selectedActivity.scheduledStart = new Date(this.startTime);
          this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledStart = new Date(this.startTime);
          this.activityService.selectedActivity.scheduledEnd = new Date(this.endTime);
          this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledEnd = new Date(this.endTime);
          let currentAppointmentActivity = this.activityService.selectedActivity;

          let payload = new UpdateMeetingPayload(
            currentAppointmentActivity.subject,
            currentAppointmentActivity.location,
            currentAppointmentActivity.scheduledStart,
            currentAppointmentActivity.scheduledEnd,
            currentAppointmentActivity.notes,
            false
          );
          // this.activityService.isActivityUpdate = true;
          if (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
            this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity);
          } else {
            try {
              this.meetingDataService.updateMeeting(this.activityService.selectedActivity as AppointmentActivity, payload, false, true);
            } catch (error) {
              console.error('IndDatetimeFormComponent: setTimePickerValue: ', error);
              this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity, false, true);
            }
          }

          this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity);
          this.onUpdate.emit();
        }
        // phone call detail
        else if (this.viewData && this.viewData.fromViewPage == CurViewPageType.PhoneCall) {
          this.trackingService.tracking('PhoneCallUpdateDate', TrackingEventNames.PHONECALL)
          this.activityService.selectedActivity.scheduledStart = new Date(this.startTime);
          this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledStart = new Date(this.startTime);
          this.activityService.selectedActivity.scheduledEnd = new Date(this.endTime);
          this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledEnd = new Date(this.endTime);
          let phoneCallPayLoad = this.phoneCallDataService.setPhoneCallPayload(this.activityService.selectedActivity as PhoneActivity)
          this.uiService.displayLoader();
          if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
            this.phoneCallDataService.updatePhoneCall(this.activityService.selectedActivity as PhoneActivity, phoneCallPayLoad).then(()=>{
              this.uiService.dismissLoader();
            })
            // this.activityService.isActivityUpdate = true;
          } else {
            if (this.device.isOffline) {
              this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity).then(()=>{
                this.uiService.dismissLoader();
              })
            }
          }
          this.onUpdate.emit();
          this.activityService.selectedActivity.scheduledStartLocale = this.activityService.selectedActivity.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
          this.eventsService.publish('detectChangesOnPhoneCallActivityDetails');
        }
        // Event
        else if (this.viewData && this.viewData.fromViewPage == CurViewPageType.Event) {
          timeData = {
            id: id,
            time: setCombinedDateTime
          }
          this.dateTimeFormatsService.updateSelectedTimeEvent.next(timeData);
        }
        // common flow
        else if (this.activityService.selectedActivity && this.activityService.selectedActivity.scheduledEnd) {
          // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined and set 'MMM dd, yyyy' and 'en-US' to combine date and time,
          const getEndDate = this.datePipe.transform(this.activityService.selectedActivity.scheduledEnd, "MMM dd, yyyy", undefined, 'en-US');
          const setCombinedDateTime = moment(getEndDate + ' ' + time).format();
          this.activityService.selectedActivity.scheduledEnd = new Date(setCombinedDateTime);
        }
      }
    }
    // refresh agenda list
    if (!this.uiService.toolsActivityActive && (this.viewData?.fromViewPage !== CurViewPageType.TimeOff)) {
      this.events.publish('refreshAgenda');
    } else {
      this.uiService.agendaRefreshRequired = true;
      if (this.activityService.selectedActivity) {
        this.activityService.signalActivityConflictCheck$.next(this.activityService.selectedActivity);
      }
    }
  }

  // set meeting minStartDate
  private setMinStartDate(type: string) {
    const dt = new Date().setHours(0, 0);
    let maxStartDays: number = 7300; // 20 years
    // set available maximum days
    if ((type == CurViewPageType.Appointment || type == CurViewPageType.Event) && this.userService.user && this.userService.user.maxMeetingStartDays) {
      maxStartDays = this.userService.user.maxMeetingStartDays;
    } else if (type == CurViewPageType.TimeOff && this.userService.user && this.userService.user.pastTimeOffGracePeriod) {
      maxStartDays = this.userService.user.pastTimeOffGracePeriod;
    } else if (type == CurViewPageType.PhoneCall) {
      maxStartDays = 7300; // there is no past phone call grace period
    }else if(type == CurViewPageType.SurgeryOrder){
      maxStartDays = (this.userService.user.procedureLogDuration === 'Weekly') ? 90 : this.userService.user.maxMeetingStartDays;
    }
    // set a minimum start date
    if (type == CurViewPageType.FollowUp || type == CurViewPageType.Scheduler || type == CurViewPageType.OpportunityInfo || type == CurViewPageType.OpportunityQuote) {
      this.minStartDate = format(new Date());
    } else if (type == CurViewPageType.MarketScan) {
      const minDate: string = moment().startOf('year').format();
      this.minStartDate = minDate;
    } else {
      this.minStartDate = format(subDays(dt, maxStartDays));
    }
    //set Start Date options
    this.optionsForStartDate.from = new Date(this.minStartDate);
    this.optionsForStartDate.to = 0;
    //set End Date options
    if (type == CurViewPageType.FollowUp || type == CurViewPageType.OpportunityInfo) {
      this.optionsForEndDate.from = new Date(this.minStartDate);
    } else {
      if (this.startTime != "Invalid Date" && this.startTime != undefined) {
        this.optionsForEndDate.from = new Date(this.startTime); // disable moving to the previous month based on the start date
      } else this.optionsForEndDate.from = new Date(this.minStartDate);
    }
    this.optionsForEndDate.to = 0;
    // set disabled days in the end date
    if (type != CurViewPageType.FollowUp && type != CurViewPageType.Email && type != CurViewPageType.OpportunityInfo && type != CurViewPageType.AccountPlan) {
      if (this.startTime != "Invalid Date" && this.startTime != undefined && this.endTime != "Invalid Date" && this.endTime != undefined) {
        this.optionsForEndDate.daysConfig = this._disableDaysOnEndDate(this.startTime, this.endTime);
      }
    }
  }

  private _disableDaysOnEndDate(startTime: string, endTime: string): DayConfig[] {
    let _daysConfig: DayConfig[] = [];
    const formattedStartDateTime = new Date(startTime).setSeconds(0,0);
    const formattedEndDateTime = new Date(endTime).setSeconds(0,0);
    const diffDays = differenceInCalendarDays(formattedEndDateTime, formattedStartDateTime);
    const diffTime: number = Math.ceil(moment(new Date(formattedEndDateTime)).diff(moment(formattedStartDateTime), 'minutes'));
    const curStartDateTime = new Date(startTime);
    const curYear: number = curStartDateTime.getFullYear();
    const curMonth: number = curStartDateTime.getMonth();
    const curDay: number = curStartDateTime.getDate();
    const disabledMaxDay: number = (diffDays !=0 && diffTime <= 1440) ? curDay + 1 : curDay;
    const disabledMaxDayOnPrevMonth: number = new Date(curYear, curMonth, 0).getDate(); // if the date is 0, a month is set as the previous month
    // disable dates on the previous month which are displayed on the current month view
    for (let d = 20; d <= disabledMaxDayOnPrevMonth; d++) {
      _daysConfig.push({
        date: new Date(curYear, curMonth-1, d),
        disable: true,
      });
    }
    // disable dates on the current month
    if (curDay > 1) {
      for (let d = 1; d < disabledMaxDay; d++) {
        _daysConfig.push({
          date: new Date(curYear, curMonth, d),
          disable: true,
        });
      }
    } else {
      // scenario: 1st day of Month is covered by this.optionsForEndDate.from
    }
    return _daysConfig;
  }

  //Input text handler
  public onInputTextClick(event) {
    // if (!this.viewData.eventHandler) return;
    if (this.isSyncInProgress) return;
    if (this.viewData && this.viewData.id) {
      if (!this.viewData.isDisabled) {
        if (this.viewData.isReadOnly) {
          let data = {
            event: event,
            id: this.viewData.id,
          }
          this.onInputClick.emit(data);
          if (this.viewData.eventHandler) {
            this.viewData.eventHandler(this.viewData.id, event, 'main_click_event', this.viewData);
          }
        }
      } else {
        if (this.viewData.infoTextEventHandler) {
          this.viewData.displayInfoText = true;
          this.viewData.infoTextEventHandler(this.viewData.controlId, event.value, event);
        }
      }
    }
  }
  //Icon click handler - because input with template is used in ngx-material-timepicker
  public onIconClick(event) {
    if (this.viewData && this.viewData.isDisabled || this.isSyncInProgress) return;
    if (this.viewData && this.viewData.id && !this.viewData.isDisabled) {
      if (this.viewData.isReadOnly) {
        this.timePickerWithDefaultMinGapValue.open();
      }
    }
  }
  // ngx-material-timepicker: value from 24:01 to 24:59 cannot be used, so formatted value is required
  public formattedTimeValue(viewData): string {
    let formattedTime: string;
    let dateTimeValue;
    if (viewData && viewData.id == DateTimeFieldType.StartTimeField) {
      dateTimeValue = new Date(viewData.startDateTimeValue);
    } else if (viewData && viewData.id == DateTimeFieldType.EndTimeField) {
      dateTimeValue = new Date(viewData.endDateTimeValue);
    }
    // 24 hours format
    if (this.dateTimeFormatsService.timeToUpperForTimePicker == 24) {
      if (dateTimeValue.getHours() == 0) {
        formattedTime = "00:" + dateTimeValue.getMinutes();
      } else {
        formattedTime = viewData.inputText;
      }
    }
    // 12 hours format
    else if(!_.isEmpty(viewData.inputText)) {
      // inputText still has 24:00 values
      if (viewData.inputText.length == 5) {
        formattedTime = dateTimeValue.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
      } else {
        formattedTime = viewData.inputText;
      }
    }
    return formattedTime;
  }
  // set display selected date in the Date Picker, set time value for combined date time - when opened Date Picker
  private _setSelectedDateAndTime() {
    if (this.activityService.dateTimePickerType == DateTimeFieldType.StartDateField || this.activityService.dateTimePickerType == DateTimeFieldType.EndDateField) {
      if (this.startTime != "Invalid Date" && this.startTime != undefined) {
        this.selectedStartDate = format(this.startTime, "YYYY-MM-DD");
      }
      if (this.endTime != "Invalid Date" && this.endTime != undefined) {
        this.selectedEndDate = format(this.endTime, "YYYY-MM-DD");
      }
    }
    // set display selected date in the Date Picker, set time value for combined date time - when opened Time Picker
    if (this.viewData && this.viewData.id == DateTimeFieldType.StartTimeField) {
      if (this.startTime != "Invalid Date" && this.startTime != undefined) {
        this.selectedStartDate = format(this.startTime, "YYYY-MM-DD");
      }
    } else if (this.viewData && this.viewData.id == DateTimeFieldType.EndTimeField) {
      if (this.endTime != "Invalid Date" && this.endTime != undefined) {
        this.selectedEndDate = format(this.endTime, "YYYY-MM-DD");
      }
    }
    // set limited minutes time for end time picker with selected start time
    if (this.startTime != "Invalid Date" && this.startTime != undefined && this.endTime != "Invalid Date" && this.endTime !=undefined) {
      this.duration = this.dateTimeFormatsService.getFormattedTimeInterval(this.startTime, this.endTime);
      if (this.viewData && this.viewData.fromViewPage == CurViewPageType.Email || this.currentViewPage == CurViewPageType.Email) {
        // no update for limited minutes
      } else {
        this.setLimitedMinutesTime(this.startTime, this.endTime);
      }
    }
  }
  private _convertActiveViewToViewPageType(activeView: string): CurViewPageType {
    let viewPageType: CurViewPageType;
    switch(activeView) {
      case 'meeting':
        viewPageType = CurViewPageType.Appointment;
      break;
      case 'timeoff':
        viewPageType = CurViewPageType.TimeOff;
      break;
      case 'email':
        viewPageType = CurViewPageType.Email;
      break;
      case 'follow-up-action':
        viewPageType = CurViewPageType.FollowUp;
      break;
      case 'phonecall':
        viewPageType = CurViewPageType.PhoneCall;
      break;
      case 'surgeryorder':
        viewPageType = CurViewPageType.SurgeryOrder;
      break;
      default:
        viewPageType = CurViewPageType.None;
      break;
    }
    return viewPageType;
  }
  onClickOfToolTipIcon(event) {
    this.viewData.eventHandler(this.viewData.id, event, 'toolTipiconClick', this.viewData);
  }
}
