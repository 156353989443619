import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Endpoints } from '../../../config/endpoints.config';
import {FeatureActionsMap, User} from '../../classes/authentication/user.class';
import { AuthenticationService } from '../../services/authentication.service';
import { DiskService } from '../../services/disk/disk.service';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { InterceptorSkipHeader } from '../../interceptors/token.interceptor';
import {OKConfig, OkIntegration} from "@omni/classes/onekey/ok-integration.class";
import DynamicsWebApi from 'dynamics-web-api'
import { TeamsConfig } from '@omni/classes/authentication/teams.config.class';
import { TokenCredential } from '@azure/core-auth';
import { LocationOfflineService } from '@omni/services/location/location.service';
import { DeviceService } from '@omni/services/device/device.service';
import { NotificationService } from '@omni/services/notification/notification.service';
import { SecureStorage } from '@awesome-cordova-plugins/secure-storage/ngx';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';
import { IO_CONFIGURATION_ENTITY_NAME } from '@omni/config/fetch-xml/shared-fetchXML-entity-names';

export interface OAuthTokenRequest {
  tentantID: string;
  clientID: string;
  grantType: string;
  resource: string;
  code: string;
  redirectURI: string;
}

declare var Microsoft: any;

/**
 * This service provides http requests for various user authentication endpoints
 *
 * @export
 * @class AuthenticationDataService
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationDataService {
  lastUserLastModified: any;
  isOfflineLoginFlow: boolean = false;
  credential: TokenCredential;
  // currentUserDTo:any;
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private disk: DiskService,
    private deviceService: DeviceService,
    private locationService: LocationOfflineService,
    private faService: FeatureActionsService,
  ) {
    this.deviceService.onResumeObservable.subscribe(() => {
      if (this.locationService.leftToLocationSettingFrom === 'authService') {
        this.fetchUserCurrentLocation(true);
      }
    });
  }

  async getToken(scopes: string | string[]) {
    return await this.credential.getToken(scopes)
  }

  async getTokenForResource(resource: string) {
    return await this.getToken(defaultScopeForResource(resource))
  }
  async getTokenForSelectedInstance() {
    if (!this.authenticationService.userConfig?.activeInstance?.url) return undefined;
    return await this.getTokenForResource(this.authenticationService.userConfig.activeInstance.url);
  }

  async getEncryptedTokenForSelectedInstance() {
    if (this.authenticationService.encryptedAccess) {
      return this.authenticationService.encryptedAccess;
    }
    let accessToken = await this.getTokenForSelectedInstance();
    const url: string = Endpoints.authentication.ENCRYPT_CLIENT_SECRET;
    let payload: HttpParams = new HttpParams();
    payload = payload.set('client_Secret', accessToken.token);
    const option = Endpoints.authentication.AUTHENTICATE_USER_HEADERS;
    option.headers = option.headers.set(InterceptorSkipHeader, 'true');
    try {
      let response: any = await this.http.post(url, payload, option).toPromise();
      if (!response || !response.encryptClientSecret) {
        console.error('encryptAccessToken: invalid response', response);
        return;
      }
      this.authenticationService.encryptedAccess =
        response.encryptClientSecret;
      return this.authenticationService.encryptedAccess;
    }
    catch (err) {
      console.error('encryptAccessToken: ', err);
    }
  }

  async getTokenForMsGraph() {
    return await this.credential.getToken(defaultScopeForResource('https://graph.microsoft.com'));
  }

  private async fetchFeatureActions() {
    let dwa = new DynamicsWebApi({
      webApiUrl: `${this.authenticationService.userConfig.activeInstance.url}/api/data/v9.1/`,
      onTokenRefresh: tokenRefresh => this.getTokenForSelectedInstance().then(accessToken => accessToken.token).then(tokenRefresh),
    });
    let fetchXml1 = `<?xml version="1.0" encoding="UTF-8"?>`
      + `<fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">`
      + `<entity name="indskr_featureaction">`
      + `<attribute name="indskr_name" />`
      + `<order descending="false" attribute="indskr_name" />`
      + `<filter type="and">`
      + `<condition attribute="statecode" value="0" operator="eq" />`
      + `</filter>`
      + `<link-entity name="indskr_featureactionrole" alias="bm" to="indskr_featureactionid" from="indskr_featureactionid">`
      + `<filter type="and">`
      + `<condition attribute="statecode" value="0" operator="eq" />`
      + `</filter>`
      + `<link-entity name="role" to="indskr_securityroleid" from="roleid">`

    let fetchXml2 = `</link-entity></link-entity></entity></fetch>`

    let userFeatureFetchXml = fetchXml1
      + `<link-entity name="systemuserroles" to="roleid" from="roleid" intersect="true" visible="false">`
      + `<filter type="and">`
      + `<condition attribute="systemuserid" operator="eq-userid" />`
      + `</filter>`
      + `</link-entity>`
      + fetchXml2;

    let teamFeatureFetchXml = fetchXml1
      + `<link-entity name="teamroles" to="roleid" from="roleid">`
      + `<filter type="and">`
      + `<condition attribute="teamid" operator="eq-userteams" />`
      + `</filter>`
      + `</link-entity>`
      + fetchXml2;
    let [userFeatureActions, teamFeatureActions] = await Promise.all([
      dwa.executeFetchXmlAll('indskr_featureactions', userFeatureFetchXml),
      dwa.executeFetchXmlAll('indskr_featureactions', teamFeatureFetchXml)]);
    return [...new Set([...userFeatureActions?.value?.map(x => x.indskr_name as string) || [], ...teamFeatureActions?.value?.map(x => x.indskr_name as string) || []])].map(x => ({indskr_name: x}));
  }

  private async fetchTeams() {
    let dwa = new DynamicsWebApi({
      webApiUrl: `${this.authenticationService.userConfig.activeInstance.url}/api/data/v9.1/`,
      onTokenRefresh: tokenRefresh => this.getTokenForSelectedInstance().then(accessToken => accessToken.token).then(tokenRefresh),
    });
    let teamFetchXml = `<?xml version="1.0" encoding="UTF-8"?>`
      + `<fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0">`
      + `<entity name="team">`
      + `<attribute name="name" />`
      + `<attribute name="isdefault" />`
      + `<attribute name="teamid" />`
      + `<attribute name="indskr_excludefromomninotes" />`
      + `<order descending="false" attribute="name" />`
      + `<filter type="and">`
      + `<condition attribute="businessunitid" operator="eq-businessid" />`
      + `<condition attribute="isdefault" value="0" operator="eq" />`
      + `<condition attribute="teamtype" operator="eq" value="0" />`
      + `</filter>`
      + `<link-entity name="teammembership" intersect="true" visible="false" to="teamid" from="teamid">`
      + `<link-entity name="systemuser" to="systemuserid" from="systemuserid" alias="mb">`
      + `<attribute name="systemuserid" />`
      + `<attribute name="fullname" />`
      + `</link-entity></link-entity></entity></fetch>`

    let teams = await dwa.executeFetchXmlAll('teams', teamFetchXml);
    return (this.authenticationService.aggregateTeams(teams?.value) || []);
  }

  private async fetchOrgSettings() {
    const dwa = new DynamicsWebApi({
      webApiUrl: `${this.authenticationService.userConfig.activeInstance.url}/api/data/v9.1/`,
      onTokenRefresh: tokenRefresh => this.getTokenForSelectedInstance().then(accessToken => accessToken.token).then(tokenRefresh),
    });
    const orgSettings = await dwa.executeFetchXmlAll('systemusers', fetchQueries.fetchOrgSettings);
    localStorage.setItem("notificationDay",orgSettings?.value[0]["os.indskr_offlinenotificationindays"])
    localStorage.setItem("notificationMessage",orgSettings?.value[0]["os.indskr_offlinenotificationmessages"])
    localStorage.setItem("recurringNotificationTime",orgSettings?.value[0]["os.indskr_recurringofflinenotificationindays"])
    localStorage.setItem("contactdisplaysequence",orgSettings?.value[0]["os.indskr_contactdisplaysequence"])
    return (orgSettings?.value || [])[0];
  }

  private async fetchBUSettings() {
    // let select = [
    //   'indsyn_amasubscription',
    //   'indsyn_onetimeveevameeting',
    // ];
    let select = [];
    let dwa = new DynamicsWebApi({
      webApiUrl: `${this.authenticationService.userConfig.activeInstance.url}/api/data/v9.1/`,
      onTokenRefresh: tokenRefresh => this.getTokenForSelectedInstance().then(accessToken => accessToken.token).then(tokenRefresh),
    });
    // const data = await dwa.retrieveAttributes('LogicalName=\'businessunit\'', undefined, ['LogicalName'], `Microsoft.Dynamics.CRM.In(PropertyName='LogicalName',PropertyValues=[${select.map(s => `'${s}'`).join(',')}])`);
    // if (!data!.value?.length) return;
    // select = data.value.map(a => a?.LogicalName);
    const buSettings = await dwa.retrieveAll('businessunits', select, `Microsoft.Dynamics.CRM.EqualBusinessId(PropertyName='businessunitid')`).catch(() => undefined);
    return (buSettings?.value || [])[0];
  }

  private async fetchBUConfigurations() {
    let select = ['indskr_callplanprogress', 'indskr_displaygoalsbeyond100', 'indskr_displayallactivitiesprogress', 'indskr_retroactivemeetingcompletionlimit','indskr_allowconsentedhcptoaccountvisit',
      'indskr_businessline', 'indskr_followupactionsubjecteditable', 'indskr_messageactivitysubjecteditable', 'indskr_showcontactconsents','indskr_populateaccountaddress',
      'indskr_futurecoaching', 'indskr_timeoffreopen','indskr_primarysubspecialty','indskr_multicalendar','indskr_multicalendardataduration', 'indskr_accessallproductcategoryandproduct', 'indskr_displayallprocedures',
      'indskr_competitorproduct', 'indskr_meetinglinkedtomarketingbusinessplan','indskr_displaymarketingbusinessplanonprocedurelo','indskr_retroactivemeetingcompletionlimitindays',
      'indskr_displaymarketingbusinessplanonopportunity', 'indskr_proceduretype', 'indskr_proceduresubtype', 'indskr_approvalsubmissionperiod', 'indskr_approvalsongoapp', 'indskr_capturehcpinfoforaccountsurvey',
      'indskr_globalsearchlimittobulevel', 'indskr_offtakecheckpreviousmonthlimit', 'indskr_applyabaccountlevelfilter', 'indskr_disableproductsection', 'indskr_onekeytokenreplacementlanguage',
      'indskr_addaffiliatedcontactsonaccountadd','indskr_procedurelogsubjecteditable','indskr_customerinquirydescription',
    ];
    let dwa = new DynamicsWebApi({
      webApiUrl: `${this.authenticationService.userConfig.activeInstance.url}/api/data/v9.1/`,
      onTokenRefresh: tokenRefresh => this.getTokenForSelectedInstance().then(accessToken => accessToken.token).then(tokenRefresh),
    });
    let buConfig = await dwa.retrieveAll('indskr_bulevelconfigurations', select, `Microsoft.Dynamics.CRM.EqualBusinessId(PropertyName='indskr_bid')`).catch(() => undefined);
    return (buConfig?.value || [])[0];
  }

  private async fetchIOConfigurations() {
    const dwa = new DynamicsWebApi({
      webApiUrl: `${this.authenticationService.userConfig.activeInstance.url}/api/data/v9.1/`,
      onTokenRefresh: tokenRefresh => this.getTokenForSelectedInstance().then(accessToken => accessToken.token).then(tokenRefresh),
    });
    let fetchXML = fetchQueries.fetchIoConfigurations;
    const filter = `<filter type="and">
      <condition attribute="indskr_configname" operator="eq" value="PatientIDKey" />
    </filter>`;
    fetchXML = fetchXML.replace(
      '{filter}',
      filter,
    );
    const ioConfig = await dwa.executeFetchXmlAll(IO_CONFIGURATION_ENTITY_NAME, fetchXML);
    return (ioConfig?.value || []);
  }

  /**
   * Get endpoint to get user information once we have our access token
   *
   * @returns {Observable<User>}
   *
   * @memberOf AuthenticationDataService
   */
  public async getUser(
    doNotSave: boolean = false,
    isForSync: boolean = false,
    didOfflineDataUploadFail: boolean = false
  ): Promise<User> {
    let url: string = Endpoints.authentication.GET_USER;
    let lastSavedUserData;
    if (isForSync) {
      lastSavedUserData = await this.authenticationService.loadLastSavedUserData();
      if (
        lastSavedUserData.lastModified &&
        !this.authenticationService.shouldFullSync
      ) {
        url = url + '?lastUpdatedTime=' + lastSavedUserData.lastModified;
      }
    }
    
    let parallelCalls = [
      this.http.get(url, { headers: { 'Sync-Service': 'true' }}).toPromise(),
      this.fetchFeatureActions(),
      this.fetchBUSettings(),
      this.fetchBUConfigurations(),
      this.fetchTeams(),
      this.http.get(Endpoints.authentication.GET_CHILD_USERS, Endpoints.GLOBAL_SYNC_HEADER).toPromise().then((res: any) => res.users).catch((err) => []),
      this.fetchOrgSettings(),
      this.fetchIOConfigurations(),
    ];

    parallelCalls.push(parallelCalls[0].then(async res => {
      const defaultCurrencyUrl = this.authenticationService.userConfig.activeInstance.url + Endpoints.accountManagement.DEFAULT_CURRENCY_SYMBOL.replace("{userId}", res.systemUserId);
      return this.http.get<any[]>(defaultCurrencyUrl).toPromise().catch((err) => [])
    }));


    let parallelRes = await Promise.all(parallelCalls);

    let res = parallelRes[0];
    res.featureActions = parallelRes[1];
    res.buSettings = parallelRes[2];
    res.buConfigs = parallelRes[3];
    res.teams = parallelRes[4];
    res.childUsers = parallelRes[5];
    res.orgsettings = parallelRes[6];
    res.appIoConfigurations = parallelRes[7];
    let res2 = parallelRes[8];
    res.currencysymbol = res2?.currencysymbol;
    if ((res2 || {})['_transactioncurrencyid_value']) {
      //If user has personalized currecy set
      const personalCurrecyUrl = this.authenticationService.userConfig.activeInstance.url + Endpoints.accountManagement.PERSONAL_SETTING_CURRENCY_SYMBOL.replace("{transactioncurrencyid}", res2['_transactioncurrencyid_value']);
      let res3: any = await this.http.get<any[]>(personalCurrecyUrl).toPromise().catch((err) => [])
      res.currencysymbol = res3.currencysymbol;
      res['_transactioncurrencyid_value'] = res2['_transactioncurrencyid_value'];
    }
    if(res.buSettings["indskr_homepageteamview"]===548910001 && res["positions"] && res["positions"].length>0){
      const siblings = await this.fetchSiblingPositionsAndUsers(res);
      res.siblingpositions = siblings.siblingPositions;
      res.siblinguserscommonparent = siblings.siblingUsers;
      res.siblinguserscommonpositions = siblings.usersFromMyPositions;
    } 
    await this.authenticationService.userDataChangeDetection(res, isForSync, didOfflineDataUploadFail, lastSavedUserData);
    this.authenticationService.mapUser(res);
          // Run FA value update on the FA master service
          this.faService.updateFaValues();
          await this.fetchUserCurrentLocation()
    await this.authenticationService.saveUser(doNotSave);
    // this.currentUserDTo = res;
    return this.authenticationService.user;
  }

  async fetchUserCurrentLocation(isRetry = false) {
    if(this.faService.isShortCallLauncherEnabledInMobileDevice && (!this.locationService.isFetchingLocationAlready || isRetry)) {
      this.locationService.isFetchingLocationAlready = true;
      this.authenticationService.user.currentLocation = await this.locationService.getCurrentLocationName('authService');
    } else this.authenticationService.user.currentLocation = null;
  }
  public async fetchSiblingPositionsAndUsers(currentUser: any){
    let arr=[], arr2=[], arr3=[];
    let positionIDstring="";
    let dwa = new DynamicsWebApi({
      webApiUrl: `${this.authenticationService.userConfig.activeInstance.url}/api/data/v9.1/`,
      onTokenRefresh: tokenRefresh => this.getTokenForSelectedInstance().then(accessToken => accessToken.token).then(tokenRefresh),
    });
    try{
      let fetchXML = fetchQueries.fetchAllSiblingPositionsBasedOnPositionId;
      //fetchXML = fetchXML.replace('{positionIDs}', `${currentUser?.positions?.map(x=> x["positionId"])}`);
      currentUser?.positions?.forEach((pos)=>{
        positionIDstring+= '<value>'+ pos["positionId"] +'</value>'
      });
      fetchXML = fetchXML.split('{positionIDstring1}').join(positionIDstring);
      fetchXML = fetchXML.split('{positionIDstring2}').join(positionIDstring);
      let responseSiblingPositions = await dwa.executeFetchXmlAll('positions', fetchXML);
      if(responseSiblingPositions && responseSiblingPositions?.value?.length>0){
        arr = responseSiblingPositions?.value;
        let siblingPositionIds="";
        let fetchXML2 = fetchQueries.fetchAllSiblingUsersBasedOnPositionId;
        //const userIdsArray = userId.split(",");
        siblingPositionIds = arr.map(sibling => `<value>${sibling["siblingposition.positionid"]}</value>`).join('');
        fetchXML2 = fetchXML2.split('{siblingPositionIds}').join(siblingPositionIds);
        let responseSiblingUsers = await dwa.executeFetchXmlAll('indskr_userpositions', fetchXML2);
        if(responseSiblingUsers && responseSiblingUsers?.value?.length>0){
          arr2 = responseSiblingUsers?.value;
        }
      }
      //fetch all sibling users from positions that are common to current logged in user
      let fetchXML3 = fetchQueries.fetchAllSiblingUsersBasedOnPositionId;
      fetchXML3 = fetchXML3.split('{siblingPositionIds}').join(positionIDstring);
      let responseSiblingUsers = await dwa.executeFetchXmlAll('indskr_userpositions', fetchXML3);
      if(responseSiblingUsers && responseSiblingUsers?.value?.length>0){
        arr3 = responseSiblingUsers?.value;
      }
    } catch(error){
      console.error('fetchSiblingPositions error:', error);
    }
    return {siblingPositions: arr, siblingUsers:arr2, usersFromMyPositions:arr3};
  }

  async fetchNotesAssistantConfiguration(loadFromDbOnly = false) {
    if (loadFromDbOnly) {
      const dbData = await this.disk.retrieve(
        DB_KEY_PREFIXES.NOTE_ASSISTANT_CONFIG,
        true
      );
      if (dbData && dbData.raw) {
        this.mapConfiguration(dbData.raw);
      }
    } else {
      let url = Endpoints.notes_assisstant.configuration;
      try {
        this.http
          .get(url)
          .toPromise()
          .catch((err) => [])
          .then((res) => {
            this.mapConfiguration(res);
            this.disk.updateOrInsert(
              DB_KEY_PREFIXES.NOTE_ASSISTANT_CONFIG,
              (doc) => ({ raw: res })
            );
          });
      } catch (error) {
        console.log('error in notes assitant confg service', error);
      }
    }
  }

  private mapConfiguration(raw: any) {
    this.authenticationService.notesAssistantConfig = {
      speechSDKSubscriptionID: raw['speechSubscriptionKey'] || '',
      speechRegion: raw['speechRegion'] || '',
      speechLanguage: raw['speechLanguage'] || '',
      speechCID: raw['speechCustomEndpointId'] || '',
      LUISAppID: raw['luisAppId'] || '',
      LUISRegion: raw['luisRegion'] || '',
      LUISSubscriptionKey: raw['luisSubscriptionKey'] || '',
    };
    (<any>window).bing_config = {
      cid: this.authenticationService.notesAssistantConfig.speechCID,
      subscriptionKey: this.authenticationService.notesAssistantConfig
        .speechSDKSubscriptionID,
      region: this.authenticationService.notesAssistantConfig.speechRegion,
    };
  }

  async fetchOKIntegrationConfig(loadFromDbOnly = false) {
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ONEKEY_ACCOUNT_SEARCH)
    || this.authenticationService.hasFeatureAction(FeatureActionsMap.ONEKEY_CONTACT_SEARCH)
      || this.authenticationService.hasFeatureAction(FeatureActionsMap.CONTACTS_CREATE_REQUEST)
      || this.authenticationService.hasFeatureAction(FeatureActionsMap.CONTACTS_EDIT_REQUEST)
      || this.authenticationService.hasFeatureAction(FeatureActionsMap.CONTACTS_ONEKEY_SELECTION)
      || this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_CREATE_REQUEST)
      || this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_EDIT_REQUEST)
      || this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_ONEKEY_SELECTION)) {
      if (loadFromDbOnly) {
        const dbData = await this.disk.retrieve(
          DB_KEY_PREFIXES.OK_API_CONFIG,
          true
        );
        if (dbData && dbData.raw) {
          if (dbData.raw.okConfigDTO) dbData.raw.okConfigDTO = new OKConfig(dbData.raw.okConfigDTO)
          this.authenticationService.okIntegrationSettings = new OkIntegration(dbData.raw);
        }
      } else {
        let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.authentication.GET_OK_API_SETTINGS;
        try {
          this.http
            .get(url)
            .toPromise()
            .catch((err) => [])
            .then((res: OkIntegration) => {
              if (res.okConfigDTO) res.okConfigDTO = new OKConfig(res.okConfigDTO, true);
              this.authenticationService.okIntegrationSettings = new OkIntegration(res);
              this.disk.updateOrInsert(
                DB_KEY_PREFIXES.OK_API_CONFIG,
                (doc) => ({ raw: res })
              );
            });
        } catch (error) {
          console.log('error in OK API config service', error);
        }
      }
    }
  }
}


function defaultScopeForResource(url: string) {
  return `${url}${url.endsWith('/') ? '' : '/'}.default`
}
