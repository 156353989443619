import { Injectable } from '@angular/core';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { log } from 'console';
import { DeviceService } from '../device/device.service';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

export type BarcodeResponse = {
  data,
  status
}

export enum BarcodeStatus{
  NOT_AUTHORIZED = 0,
  USER_CANCELLED = 2,
  ERROR_OCCURED = 3,
  SUCCESS = 4
}

@Injectable({
  providedIn: 'root'
})
export class BarcodescannerService {
  scanningInProgress = false;
  constructor(
    private barcodeScanner: BarcodeScanner,
    private diagnostic: Diagnostic,
    public alertController: AlertController,
    public translate: TranslateService,
    public device : DeviceService,
    private androidPermission : AndroidPermissions
    ) { }

  async openBarCodeScanner(): Promise<BarcodeResponse> {
    if (!this.scanningInProgress) {
      this.scanningInProgress = true;

      if (this.device.isAndroid) {
        const permission = await this.androidPermission.requestPermission(this.androidPermission.PERMISSION.CAMERA);
        if (!permission.hasPermission) {
          this.scanningInProgress = false;
          return {
            status: BarcodeStatus.NOT_AUTHORIZED,
            data: null
          }
        }
      }

      const result : BarcodeResponse = await new Promise((resolve, reject) => {
        setTimeout(() => {
          this.scanningInProgress = false;
        }, 1000);
  
        setTimeout(() => {
          return this.barcodeScanner.scan().then(barcodeData => {
            this.scanningInProgress = false;

            if (barcodeData.cancelled || barcodeData.text === '') {
              resolve ({
                status: BarcodeStatus.USER_CANCELLED,
                data: null
              });
            }
            console.log('Barcode data', barcodeData);
            resolve ( {
              status: BarcodeStatus.SUCCESS,
              data: barcodeData.text
            })
          }).catch(err => {
            this.scanningInProgress = false;
            if (err == "Illegal access") {
              this.openRequestAlert();
            } else {
              resolve ({
                status: BarcodeStatus.ERROR_OCCURED,
                data: null
              })
            }
          });
        },400);
      })

      return result;
    }
  }

  async hasCameraPermission(){
    return await this.diagnostic.isCameraAuthorized().then((authorized) => {
      return authorized;
    }).catch((error) => {
      console.log(error);
      return false;
    })
  }

  async requestCameraPermission(){
    return await this.diagnostic.requestCameraAuthorization().then((aut) => {
      if(aut === 'DENIED_ALWAYS' && this.device.deviceFlags.android){
        this.openRequestAlert();
      } else if(aut !== 'GRANTED' && this.device.deviceFlags.ios){
        this.openRequestAlert();
      }
      console.log(aut);
      // DENIED_ALWAYS
    }).catch((error) => {
      console.log(error);
      
    })
  }

  async openRequestAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('ENABLE_CAMERA'),
      message: this.translate.instant('REQUEST_PERMISSION_CAMERA'),
      buttons: [{
        text: this.translate.instant('CONFIRM'),
        role: 'confirm',
        handler: () => {
          this.diagnostic.switchToSettings();
        },
      },{
        text: this.translate.instant('CANCEL'),
        role: 'cancel',
        handler: () => {
          
        },
      }],
    });

    await alert.present();

    await alert.onDidDismiss();
    // this.roleMessage = `Dismissed with role: ${role}`;
  }


}
