import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConsentSignatureModalComponent } from '@omni/components/contact/consent-signature-modal/consent-signature-modal';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { ChildNavNames, NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { ModalController, PopoverController } from '@ionic/angular';
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import { DeviceService } from '@omni/services/device/device.service';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from '@omni/services/disk/disk.service';
import _ from 'lodash';
import { DisplayValue } from '@omni/components/display-value/display-value.component';
import { PdfService } from '@omni/services/pdf.service';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { CONTRACT_SUPPORTED_MIME_TYPES_SUPPORTED_REGEX, MAXIMUM_NOTE_ATTACHMENT_SIZE, Utility } from '@omni/utility/util';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { ProcedureContractService } from '@omni/data-services/procedure-contract/procedure-contract.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '@omni/models/mainToolTemplateDetail.model';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { MainToolTemplateComponent } from '@omni/components/shared/main-tool-template/main-tool-template';
import { ActivityService } from '@omni/services/activity/activity.service';
import { AlertService } from '@omni/services/alert/alert.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { addMinutes, addMonths, differenceInDays, endOfDay, format, lastDayOfMonth, startOfDay, subDays } from 'date-fns';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OmniYearMonthPickerComponent } from '@omni/components/shared/omni-year-month-picker/omni-year-month-picker.component';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { customerAddress } from '@omni/classes/account/account.class';
import { CONTRACT_SECTION_NAMES, CONTRACT_SIGNATURE_MODE, ContractType, PROCEDURE_CONTRACT_EMAIL_ATTACHEMENTS, PROCEDURE_CONTRACT_HEIRACRCHY, PROCEDURE_CONTRACT_STATUS, PROCEDURE_CONTRACT_TYPES, ProcedureContract } from '@omni/classes/procedure-contract/procedure-contract.class';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { Specialty } from '@omni/classes/contact/contact.class';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { ContactPageComponent } from '@omni/components/contact/contact-page/contact-page';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { Guid } from 'typescript-guid';
import { AlertInfoTypes, OmniInfoAlertComponent } from '@omni/components/shared/omni-info-alert/omni-info-alert.component';
import { ConfiguredFields } from '@omni/classes/authentication/configured.field.class';
import { getConfigFormFieldIsReadOnly, getConfigFormFieldType, getConfigFormFieldViewDataModelId, getConfigFormInputType } from '@omni/utility/common.utility';
import { SelectListData } from '@omni/components/popover/popover';
import { ReadOnlyEventConfiguredFieldNames } from '@omni/config/configuredField.config';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { SurgeryOrderDetailsComponent } from '../surgery-order-details/surgery-order-details';
import { Events } from '@omni/events';

/**
 * child contract and summary report are same
 */

@Component({
  selector: 'app-surgery-order-contract-details',
  templateUrl: './surgery-order-contract-details.component.html',
  styleUrls: ['./surgery-order-contract-details.component.scss'],
})
export class SurgeryOrderContractDetailsComponent implements OnInit, OnDestroy {
  @Input() procedureContract: ProcedureContract;
  @Input() viewMode: ComponentViewMode;
  @ViewChild('attachInput') attachInput: ElementRef;

  // ---- add title section here ---- //
  public procedureContractPageTitle: IndPageTitleViewDataModel;
  public procedureDeatialsHeader: IndSectionHeaderViewDataModel;
  public contractDetailsHeader: IndSectionHeaderViewDataModel;
  public preSignatureHeader: IndSectionHeaderViewDataModel;
  public postSignatureHeader: IndSectionHeaderViewDataModel;
  public procedureCoveredHeader: IndSectionHeaderViewDataModel;
  public childContractHeader: IndSectionHeaderViewDataModel;

  // ---- add Form fields here ---- //

  //---- Pre surgery form fields ----//
  public preSignModeField: IndFormFieldViewDataModel;
  public preValidatorFormField: IndFormFieldViewDataModel;
  public rulesField: IndFormFieldViewDataModel;
  public customerFormField: IndFormFieldViewDataModel;

  //---- Post surgery form fields ----//
  public postValidatorFormField: IndFormFieldViewDataModel;
  public preSurgeryDateField: IndDateTimeFormViewDataModel;
  public postSurgeryDateField: IndDateTimeFormViewDataModel;
  public postSignModeField: IndFormFieldViewDataModel;

  //---- variable declaration from here ----//
  private requiredFields: { [key: string]: { isEvaluated: boolean } } = {};
  private _isClicked: { [x: string]: boolean } = {};
  private autoNamingDisabled = false;
  private ngUnSubscribe$ = new Subject<boolean>();
  private globalCustomerText: string;
  private isFullReadOnlyMode = false;
  private associatedProcedureLogs: any = [];
  private childContracts = [];
  private specialities = [];
  private customerAddresses = [];
  private partialReadOnlyMode = false;
  private hideProcedure = false;
  private isProcedureSubtypeAvailable = false;
  private procedureContractPositionGroupProducts = [];
  private lookupConfigFieldsData = [];
  private lookupFieldsDataInitialized = false;
  private contractDetailsFields: IndFormFieldViewDataModel[] = [];
  private procedureDetailsFields: IndFormFieldViewDataModel[] = [];
  private presurgeryDetailsFields: IndFormFieldViewDataModel[] = [];
  public contractType: ContractType | null = null;

  public procedureLogItems: ProcedureLogData[] = [];
  public displayChildContracts = [];
  public shouldShowPresurgerySection = false;
  public shouldShowPostsurgerySection = false;
  public usageType = null;
  public presurgerytext = null;
  public postsurgerytext = null;
  public groupedFields = {};
  public shouldShowChildContractSection = false
  private shouldAllowDuplicateChildContract = false;
  private signType = null;
  private products: any;

  constructor(
    private activityDataService: ActivityDataService,
    private modalCtrl: ModalController,
    private accountService: AccountOfflineService,
    private contactService: ContactOfflineService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private utilityService: GlobalUtilityService,
    private contractService: ProcedureContractService,
    public navService: NavigationService,
    public translate: TranslateService,
    public footerService: FooterService,
    public popoverCtrl: PopoverController,
    public dateTimeFormatsService: DateTimeFormatsService,
    public surgeryOrderDataService: SurgeryOrderActivityDataService,
    public uiService: UIService,
    public device: DeviceService,
    public disk: DiskService,
    public pdfService: PdfService,
    public notificationService: NotificationService,
    private modalController: ModalController,
    public activityService: ActivityService,
    public authenticationService: AuthenticationService,
    private events: Events
  ) { }

  ngOnInit() {
    this.autoNamingDisabled = this.contractService.generateContractName(this.procedureContract) !== this.procedureContract.indskr_name;
    this.hideProcedure = this.authenticationService.user.buSettings?.['indskr_hideprocedureproductcategory'];
    this.contractType = this.contractService.getContractType(this.procedureContract.indskr_contracttypes);
    this.specialities = this.setSpecialityOptions();
    this.customerAddresses = this.accountService.customerAddresses;
    this.initView();

    this.navService.childNavPopObserver.pipe(
      takeUntil(this.ngUnSubscribe$),
      // filter(page => page && this.navService.getActiveChildNavViewPageName() === PageName.AccountTimelinePageComponent),
      filter(page => [
        PageName.SurgeryOrderDetailsComponent
      ].includes(page)),
      tap(() => {
        this.syncProcedureContract();
      })
    ).subscribe();

    this.contractService.refreshProcedureContract.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((res) => {
      this.getProcedureCoveredHeader();
    });
  }

  public get areAllRequiredFieldsFilled() {
    const { accountName, indskr_maximumnoofassistance, indskr_enddate, indskr_startdate, contractTypeString, proceudreSubTypeString } = this.procedureContract;
    let requiredFieldsFilled = Boolean(accountName && indskr_maximumnoofassistance && indskr_startdate && contractTypeString);

    if (!indskr_enddate && this.usageType !== PROCEDURE_CONTRACT_TYPES.PAID_SINGLE_DAY_CONTRACT) requiredFieldsFilled = false;
    if (this.isProcedureSubtypeAvailable && !proceudreSubTypeString) requiredFieldsFilled = false;

    if (!_.isEmpty(this.groupedFields)) {
      const allFields: any = Object.values(this.groupedFields['Contract Details']).flat();
      if (allFields.some((field) => field.isRequired && !field.inputText)) {
        requiredFieldsFilled = false;
      }
    }

    if (requiredFieldsFilled && this.contractType?.indskr_displaykcodefields) {
      requiredFieldsFilled = this.checkKCodeFields();
    }

    return requiredFieldsFilled;
  }

  private get shouldDisable() {
    if (this.usageType === PROCEDURE_CONTRACT_TYPES.PAID_SINGLE_DAY_CONTRACT) return true;
    if (this.usageType === PROCEDURE_CONTRACT_TYPES.PAID_MULTIPLE_DAY_CONTRACT && this.procedureContract.indskr_parentprocedurecontractid) return true;
    return false;
  }

  public get isProcedureSectionVisible() {
    if (!this.contractType) return false;

    const { indskr_parentprocedurecontractid } = this.procedureContract;
    const { indskr_allowchildcontractcreation, indskr_procedurelogassociation } = this.contractType;

    if (!indskr_allowchildcontractcreation) return true;

    return (
      indskr_allowchildcontractcreation &&
      (
        indskr_parentprocedurecontractid ||
        (!indskr_parentprocedurecontractid && indskr_procedurelogassociation === PROCEDURE_CONTRACT_HEIRACRCHY.PARENT)
      )
    );
  }

  public get isPreSectionMandatoryFieldsFilled() {
    let isPreSectionMandatoryFieldsFilled = Boolean(this.procedureContract.indskr_contact && this.procedureContract.indskr_validatornamepresurgerysignature
      && this.procedureContract.indskr_consenttaken && this.procedureContract.indskr_complywithhospitalrulesandregulations
    );

    if (this.procedureContract.indskr_signaturecapturemode === CONTRACT_SIGNATURE_MODE.Manual) {
      isPreSectionMandatoryFieldsFilled = isPreSectionMandatoryFieldsFilled && this.procedureContract.indskr_presurgerysignaturecapturedate != null;
    }

    if (!_.isEmpty(this.groupedFields) && this.groupedFields[CONTRACT_SECTION_NAMES.PRE_SURGERY_SIGNATURE]) {
      const allFields: any = Object.values(this.groupedFields[CONTRACT_SECTION_NAMES.PRE_SURGERY_SIGNATURE]).flat();
      if (allFields.some((field) => field.isRequired && !field.inputText)) {
        isPreSectionMandatoryFieldsFilled = false;
      }
    }

    return isPreSectionMandatoryFieldsFilled;
  }

  public get isPostSectionMandatoryFieldsFilled() {
    let isPostSectionMandatoryFieldsFilled = Boolean(this.procedureContract.indskr_validatornamepostsurgerysignature);

    if (this.procedureContract.indskr_postsignaturecapturemode === CONTRACT_SIGNATURE_MODE.Manual) {
      isPostSectionMandatoryFieldsFilled = isPostSectionMandatoryFieldsFilled && this.procedureContract.indskr_postsurgerysignaturecapturedate != null;
    }

    if (!_.isEmpty(this.groupedFields) && this.groupedFields[CONTRACT_SECTION_NAMES.POST_SURGERY_SIGNATURE]) {
      const allFields: any = Object.values(this.groupedFields[CONTRACT_SECTION_NAMES.POST_SURGERY_SIGNATURE]).flat();
      if (allFields.some((field) => field.isRequired && !field.inputText)) {
        isPostSectionMandatoryFieldsFilled = false;
      }
    }

    return isPostSectionMandatoryFieldsFilled;
  }

  public get isSummaryReport() {
    return this.contractType?.indskr_allowchildcontractcreation && this.procedureContract.indskr_parentprocedurecontractid
  }

  private setSpecialityOptions() {
    const filteredSpecialities = this.contactService.specialties
      .filter(({ buAssociation }) => buAssociation?.some((bu) => bu.businessunit === this.authenticationService.user.xBusinessUnitId))
      .sort((a, b) => {
        const currentBuConfig = a.buAssociation.find((bu) => bu.businessunit === this.authenticationService.user.xBusinessUnitId);
        const currentBuConfig1 = b.buAssociation.find((bu) => bu.businessunit === this.authenticationService.user.xBusinessUnitId);

        if (currentBuConfig.order === undefined && currentBuConfig1.order !== undefined) return -1;
        if (currentBuConfig.order !== undefined && currentBuConfig1.order === undefined) return 1;

        // If both have an `order`, sort by it
        if (currentBuConfig.order !== currentBuConfig1.order) return currentBuConfig.order - currentBuConfig1.order;

        // If orders are the same, sort alphabetically by speciality name
        return a.name.localeCompare(b.name);
      });
    return filteredSpecialities;
  }

  private checkKCodeFields(): boolean {
    const {
      k_code_one_id,
      k_code_two_id,
      k_code_three_id,
      k_code_other_name,
    } = this.procedureContract;

    if (!k_code_one_id || !k_code_two_id || !k_code_three_id) {
      return false;
    }

    if (!k_code_other_name) {
      const foundKcode = this.contractService?.KCodesData?.find((a) => a.jnjjapan_kcodeid == k_code_three_id);
      if (foundKcode?.jnjjapan_valuetype === 731840000) {
        return false;
      }
    }

    return true;
  }

  public shouldEnableUploadButton(mode) {
    if (this.device.isOffline || this.isFullReadOnlyMode) return true;

    if (mode == 'presign') {
      return this.procedureContract.indskr_presurgerysignaturecaptured || this.procedureContract.indskr_postsurgerysignaturecaptured;
    } else if (mode == 'postsign') {
      return this.procedureContract.indskr_postsurgerysignaturecaptured || 
      ((this.procedureContract.indskr_signaturecapturemode === CONTRACT_SIGNATURE_MODE.Digital || this.procedureContract.indskr_postsignaturecapturemode === CONTRACT_SIGNATURE_MODE.Digital) && 
      this.contractType.indskr_presignature && !this.procedureContract.indskr_presurgerysignaturecaptured);
    }
    return false;
  }

  public getSignatureFileName(signatureType: string) {
    if (_.isEmpty(this.procedureContract)) return '';
    const regex = /\/([^/]+)$/;
    let fileName = this.procedureContract.indskr_presurgerysignaturedocument ? regex.exec(this.procedureContract.indskr_presurgerysignaturedocument)[1] : '';
    if (signatureType === 'Postsign') {
      fileName = this.procedureContract.indskr_postsurgerysignaturedocument ? regex.exec(this.procedureContract.indskr_postsurgerysignaturedocument)[1] : '';
    }
    return fileName;
  }

  private setGlobalCustomerText() {
    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        this.globalCustomerText = this.translate.instant('CUSTOMER');
        break;
      case 'Stakeholder':
        this.globalCustomerText = this.translate.instant('STAKEHOLDER');
        break;
      default:
        this.globalCustomerText = this.utilityService.globalCustomerText;
        break;
    }
  }

  private procedureSubTypeHasFrequency() {
    if (!this.procedureContract.indskr_contracttypes || !this.procedureContract.indskr_proceduresubtype) {
      return false;
    }
    const selectedProcedureSubType = this.surgeryOrderDataService.findProcedureSubTypeById(this.procedureContract.indskr_proceduresubtype);
    if (!selectedProcedureSubType?.indskr_procedureallocated) return false;
    return true;
  }

  private procedureSubTypeHasDuration() {
    if (!this.procedureContract.indskr_contracttypes || !this.procedureContract.indskr_proceduresubtype) {
      return false;
    }
    const selectedProcedureSubType = this.surgeryOrderDataService.findProcedureSubTypeById(this.procedureContract.indskr_proceduresubtype);
    if (!selectedProcedureSubType?.indskr_validity) return false;
    return true;
  }

  private _initHeaderView(): void {
    let titleButtons: any = [{
      id: "close",
      icon: "chevron-back-outline",
      isDisabled: false,
      align: "left"
    }];

    // activate and cancel buttons are not available to child contracts
    if (!this.procedureContract.indskr_parentprocedurecontractid) {
      titleButtons.push({
        id: "activate",
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('ACTIVATE'),
        isDisabled: this.procedureContract.statuscode !== PROCEDURE_CONTRACT_STATUS.DRAFT || !this.areAllRequiredFieldsFilled,
        align: "right"
      });

      if (this.contractType && !this.contractType.indskr_allowchildcontractcreation || this.procedureContract.indskr_noofassistanceavailed == 0) {
        titleButtons.push({
          id: "cancel",
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('CANCEL'),
          isDisabled:
            this.device.isOffline ||
            this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.EXPIRED ||
            this.associatedProcedureLogs.length > 0 ||
            this.viewMode === ComponentViewMode.PREVIEW,
          align: "right"
        })
      }
    }

    titleButtons.splice(2, 0, {
      id: "refresh",
      imgSrc: 'assets/imgs/refreshIcon.svg',
      name: this.translate.instant('SYNC'),
      isDisabled: this.device.isOffline || this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.EXPIRED,
      align: "right"
    })

    this.procedureContractPageTitle = {
      id: 'contract_page_title',
      title: this.procedureContract.indskr_name,
      controls: titleButtons,
    };
  }

  private initFooter() {
    this.footerService.initButtons(FooterViews.PROCEDURE_CONTRACT);
    const { indskr_emailfromgoapp, indskr_attachmenttypes } = this.contractType;
    const isDeviceSupported = this.device.deviceFlags.android || this.device.deviceFlags.ios || this.device.deviceFlags.electron;

    let canSendEmail = this.canSendEmail(indskr_emailfromgoapp, indskr_attachmenttypes);

    if (!canSendEmail || !isDeviceSupported) {
      this.footerService.updateButtons(['downloadPDF'], true);
    }

    if (this.device.isOffline) {
      this.footerService.disableButton(['downloadPDF', 'sendEmailContract']);
      return;
    }

    const shouldDisableDownload = this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.DRAFT ||
      (this.contractType.indskr_presignature && !this.procedureContract.indskr_signaturecapturemode) ||
      (!this.contractType.indskr_presignature && this.contractType.indskr_postsignature && !this.procedureContract.indskr_postsignaturecapturemode);

    if (shouldDisableDownload) {
      this.footerService.disableButton(['downloadPDF']);
    }
  }

  private canSendEmail(indskr_emailfromgoapp: boolean, indskr_attachmenttypes: any[]): boolean {
    if (!indskr_emailfromgoapp || this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.DRAFT ||
      indskr_attachmenttypes.length === 0) {
      return false;
    }

    const preSignIncluded = indskr_attachmenttypes.includes(PROCEDURE_CONTRACT_EMAIL_ATTACHEMENTS.PRE_SIGNED_COPY);
    const postSignIncluded = indskr_attachmenttypes.includes(PROCEDURE_CONTRACT_EMAIL_ATTACHEMENTS.POST_SIGNED_COPY);
    const preSignCaptured = this.procedureContract.indskr_presurgerysignaturecaptured;
    const postSignCaptured = this.procedureContract.indskr_postsurgerysignaturecaptured;

    if (indskr_attachmenttypes.length === 1) {
      return !((preSignIncluded && !preSignCaptured) || (postSignIncluded && !postSignCaptured));
    } else if (indskr_attachmenttypes.length === 2 && preSignIncluded && postSignIncluded) {
      return preSignCaptured || postSignCaptured;
    }

    return true;
  }

  private initAllDataModels() {
    // ? to make contract details and procedure details section readonly
    this.partialReadOnlyMode = this.procedureContract.statuscode !== PROCEDURE_CONTRACT_STATUS.DRAFT;
    this.isFullReadOnlyMode = this.viewMode === ComponentViewMode.PREVIEW || this.procedureContract.statecode === 1;

    if (!this.autoNamingDisabled) {
      this.procedureContract.indskr_name = this.contractService.generateContractName(this.procedureContract);
    }

    if (this.contractType) {
      this.usageType = this.contractType.indskr_usagetype;
      this.shouldShowChildContractSection = this.contractType.indskr_allowchildcontractcreation && this.procedureContract.statuscode !== PROCEDURE_CONTRACT_STATUS.DRAFT && !this.procedureContract.indskr_parentprocedurecontractid;
      this.shouldAllowDuplicateChildContract = this.contractType.indskr_allowduplicatechildcontractcreation;

    }

    this.groupedFields = {};
    this.contractDetailsFields = [];
    this.procedureDetailsFields = [];
    this.presurgeryDetailsFields = [];

    //---- Contract details section ----//
    this.initContractDetailSection();

    //---- K-Code Fields ----//
    this.initKCodeFields();

    //---- Procedure detail section ----//
    if (!this.hideProcedure) this.getProcedureFormField();
    this.getproductFormField();
    this.getproductIdFormField();
    this.getProcedureDetailsHeader();
    this.setupContractView(CONTRACT_SECTION_NAMES.PROCEDURE_DETAILS, this.procedureDetailsFields);

    //---- Pre surgery section ----//
    this.getSignatureMethodFormField();
    this.getPreValidatorFormField();
    this.getpreSignatureDateField();
    this.getCustomerFormField();
    this.createConsentFormField();
    this.createComplyFormField();
    this.setupContractView(CONTRACT_SECTION_NAMES.PRE_SURGERY_SIGNATURE, this.presurgeryDetailsFields);

    //---- post surgery section ----// 
    this.getPostSignatureModeFormField();
    this.getPostValidatorFormField();
    this.getpostSignatureDateField();
    this.setupContractView(CONTRACT_SECTION_NAMES.POST_SURGERY_SIGNATURE, []);
    this.setupContractView(CONTRACT_SECTION_NAMES.PROCEDURE_COVERED, []);

    //---- section headers ----//
    this.getPreSignatureHeader();
    this.getPostSignatureHeader();
    this.getProcedureCoveredHeader();
    this.getChildContractHeader();

    if (this.procedureContract.indskr_parentprocedurecontractid || (this.contractType && !this.contractType.indskr_allowchildcontractcreation)) {
      this.initFooter();
    } else {
      this.footerService.initButtons('');
    }

    this._initHeaderView();
  }

  private initContractDetailSection() {
    this.getStatusFormField();
    this.getNameFormField();

    if (this.isSummaryReport) {
      this.getParentNameFormField();
    }

    this.getAccountFormField();
    this.getSpecialitiesFormField();
    this.getAddressFormField();

    if (!this.procedureContract.indskr_parentprocedurecontractid) {
      this.getprocedureAllocatedFormField();
    }

    if (this.usageType !== PROCEDURE_CONTRACT_TYPES.PAID_SINGLE_DAY_CONTRACT && !this.procedureContract.indskr_parentprocedurecontractid) {
      this.getProceduresUsedFormField();
      this.getProceduresAvailableFormField();
    }

    this.getcontractStartDateField();

    if (this.usageType !== PROCEDURE_CONTRACT_TYPES.PAID_SINGLE_DAY_CONTRACT) {
      this.getcontractEndDateField();
    }

    this.getDurationFormField();
    this.getcontractTypeFormField();

    if (this.isProcedureSubtypeAvailable) this.getprocedureSubTypeFormField();

    this.getContractDetailsHeader();
    this.setupContractView(CONTRACT_SECTION_NAMES.CONTRACT_DETAILS, this.contractDetailsFields);
  }

  private async fetchAssociatedProcedureLogs() {
    if (!this.contractType) return;

    const { statuscode, indskr_procedurecontractid, indskr_startdate, indskr_enddate, indskr_parentprocedurecontractid, indskr_postsurgerysignaturecaptured, indskr_postsurgerysignaturecapturedate } = this.procedureContract;
    const { indskr_allowchildcontractcreation, indskr_procedurelogassociation, indskr_allowduplicatechildcontractcreation } = this.contractType;
    const isDuplicateChildContracts = indskr_allowduplicatechildcontractcreation && indskr_procedurelogassociation === PROCEDURE_CONTRACT_HEIRACRCHY.CHILD;

    if (statuscode === PROCEDURE_CONTRACT_STATUS.DRAFT) return;

    await this.uiService.displayLoader();
    try {
      let procedureContractId: any = indskr_procedurecontractid;
      let dates: any = {};

      if (indskr_allowchildcontractcreation) {
        dates.start = new Date(indskr_startdate)
        dates.end = indskr_postsurgerysignaturecaptured && indskr_postsurgerysignaturecapturedate && new Date(indskr_enddate).getTime() > new Date(indskr_postsurgerysignaturecapturedate).getTime() ? new Date(indskr_postsurgerysignaturecapturedate) : new Date(indskr_enddate);
      }

      if (indskr_allowchildcontractcreation && indskr_procedurelogassociation === PROCEDURE_CONTRACT_HEIRACRCHY.PARENT && indskr_parentprocedurecontractid) {
        procedureContractId = indskr_parentprocedurecontractid;
      }

      const response = await this.contractService.getSurgeryInfoAssociatedWithContract(procedureContractId, isDuplicateChildContracts, dates?.start, dates?.end);
      this.associatedProcedureLogs = [];
      this.procedureLogItems = [];

      if (response?.length) {
        response.forEach(element => {
          const index = this.associatedProcedureLogs.findIndex((procLog) => procLog.orderId === element.salesorderid);
          if (index >= 0) {
            this.associatedProcedureLogs[index].coOwners.push(element['ac.fullname']);
          } else {
            this.associatedProcedureLogs.push({
              startDate: element.indskr_scheduleddate,
              ownerName: element._ownerid_value_Formatted,
              name: element.name,
              orderId: element.salesorderid,
              createdon: element['createdon'],
              coOwners: element['ac.fullname'] ? [element['ac.fullname']] : []
            });
          }
        });

        this.associatedProcedureLogs.sort((a, b) => {
          const dateComparison = new Date(b.startDate).getTime() - new Date(a.startDate).getTime();

          if (dateComparison !== 0) {
            return dateComparison;
          }

          return new Date(b.createdon).getTime() - new Date(a.createdon).getTime();
        });
      }

      this.initViewForprocedureLog(this.associatedProcedureLogs);
      this._initHeaderView();
      this.uiService.dismissLoader();
    } catch (error) {
      this.uiService.dismissLoader();
      console.log('Error while fetching associated procedure log');
    }

  }

  private initViewForprocedureLog(associatedProcedureLogs) {
    associatedProcedureLogs.forEach((item) => {
      let newProcedurelog: ProcedureLogData
      let contractStartDayValue = this.datePipe.transform(item.startDate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);

      newProcedurelog = {
        headerText: item.name,
        values: [
          {
            label: this.translate.instant('PROCEDURE_DATE'),
            value: contractStartDayValue,
            placeholderText: this.translate.instant('NO_PROCEDURE_DATE'),
            showLines: true,
          },
          {
            label: this.translate.instant('START_TIME'),
            value: item.startDate.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' }),
            placeholderText: this.translate.instant('NO_START_TIME'),
            showLines: true,
          },
          {
            label: this.translate.instant('USER'),
            value: item.ownerName,
            placeholderText: this.translate.instant('NO_USER'),
            showLines: true,
          },
          {
            label: this.translate.instant('CO_OWNERS'),
            value: item.coOwners.length >= 1 ? item.coOwners[0] : '',
            placeholderText: this.translate.instant('NO_CO_OWNERS'),
            showLines: true,
            valuePopupText: (item.coOwners && item.coOwners.length > 1) ? '+ ' + (item.coOwners.length - 1) : '',
            popupListData: (item.coOwners && item.coOwners.length > 1) ? item.coOwners.slice(1).map(a => {
              let obj = {
                id: Guid.create(),
                title: a,
              };
              return obj;
            }) : [],
          }
        ]
      }

      this.procedureLogItems.push(newProcedurelog);
    })
  }

  private async initView() {
    try {
      await this.uiService.displayLoader();
      let configuredFields = await this.contractService.addIoConfigDefaultValuesToEventActivity(this.procedureContract);
      configuredFields = { ...configuredFields, ...this.procedureContract.configuredFields };
      this.procedureContract.configuredFields = configuredFields;

      this.setGlobalCustomerText();
      this.initProcedureContractPositionProducts();
      this.setFlags();
      this.initAllDataModels();
      this.fetchAssociatedProcedureLogs();

      if (!this.procedureContract.indskr_parentprocedurecontractid) {
        this.contractService.setChildContracts(this.procedureContract.indskr_procedurecontractid);
        this.initChildContractList();
      }

      this.uiService.dismissLoader();
    } catch (error) {
      console.log('error from init contract', error);
      this.uiService.dismissLoader();
    }
  }

  // ---- section headers ---- //
  private getProcedureDetailsHeader() {
    this.procedureDeatialsHeader = {
      id: 'procedure-details-header',
      title: this.translate.instant('PROCEDURE_DETAILS'),
      controls: []
    };
  }

  private getContractDetailsHeader() {
    let title = this.isSummaryReport ? 'SUMMARY_REPORT' : 'CONTRACT_DETAILS';

    this.contractDetailsHeader = {
      id: 'contract-details-header',
      title: this.translate.instant(title),
      controls: []
    };
  }

  private getPreSignatureHeader() {
    const defaultTitle = this.translate.instant('PRE_SIGNATURE');
    const title = (this.procedureContract.indskr_contracttypes && this.contractType?.indskr_presignaturesectionname) ? this.contractType.indskr_presignaturesectionname : defaultTitle;
    this.presurgerytext = this.contractType?.indskr_presurgerytext ? this.contractType.indskr_presurgerytext.split("\n") : this.presurgerytext;

    this.preSignatureHeader = {
      id: 'preSignature-header',
      title,
      doNotModifyTitleCase: true,
      controls: []
    };

  }

  private getPostSignatureHeader() {
    const defaultTitle = this.translate.instant('POST_SIGNATURE');
    const title = (this.procedureContract.indskr_contracttypes && this.contractType?.indskr_postsignaturesectionname) ? this.contractType.indskr_postsignaturesectionname : defaultTitle;
    this.postsurgerytext = this.contractType?.indskr_postsurgerytext ? this.contractType.indskr_postsurgerytext.split("\n") : this.postsurgerytext;

    this.postSignatureHeader = {
      id: 'postSignature-header',
      title,
      doNotModifyTitleCase: true,
      controls: []
    };
  }

  private getProcedureCoveredHeader() {
    const title = this.isSummaryReport ? 'PROCEDURE_COVERED' : 'PROCEDURE_COVERED_IN_THIS_CONTRACT';

    const buttons = this.isSummaryReport ? [] : [{
      id: "pluse-icon",
      iconClass: 'pluse-icon',
      isDisabled: this.isSurgeryCreationDisabled()
    }];

    this.procedureCoveredHeader = {
      id: 'procedure-covered-header',
      title: this.translate.instant(title),
      controls: buttons
    };
  }

  private isSurgeryCreationDisabled() {
    const currentTime = new Date().getTime();
    const start = new Date(this.getStartTime(currentTime));
    const isOffline = this.device.isOffline;

    const isFreeContractExhausted = this.usageType === PROCEDURE_CONTRACT_TYPES.FREE_CONTRACT &&
      this.procedureContract.indskr_noofassistanceavailed === this.procedureContract.indskr_maximumnoofassistance;
  
    const isProcedureLogApplicable = this.contractType && this.contractService.isProcedureLogApplicable(this.contractType, this.procedureContract);

    let isSummaryReportApplicable = true;

    if (this.contractType?.indskr_allowchildcontractcreation) {
      const summaryReports = this.contractService.findSummaryReportByContractId(this.procedureContract.indskr_procedurecontractid);
      if (summaryReports.length == 0) {
        isSummaryReportApplicable = false;
      }
    }
  
    return isOffline || isFreeContractExhausted || !isProcedureLogApplicable || !isSummaryReportApplicable;
  }

  private getChildContractHeader() {
    const isDisabled = this.shouldDisablePlusIcon();
    const buttons = [{
      id: "plus-icon",
      iconClass: 'pluse-icon',
      isDisabled: isDisabled
    }];
 
    this.childContractHeader = {
      id: 'child-contract-header',
      title: this.translate.instant('SUMMARY_REPORT'),
      controls: buttons
    };
  }

  private shouldDisablePlusIcon(): boolean {
    const allowDuplicateCreation = this.contractType?.indskr_allowduplicatechildcontractcreation;
    const monthsDifference = this.dateTimeFormatsService.differenceInMonthsIncludingPartial(
      new Date(this.procedureContract.indskr_startdate),
      new Date(this.procedureContract.indskr_enddate)
    );
    const isChildContractLimitReached = monthsDifference <= this.childContracts.length;
    
    return !allowDuplicateCreation && (isChildContractLimitReached || this.isFullReadOnlyMode);
  }

  private initChildContractList() {
    this.contractService.childContracts.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((childContracts) => {
      if (childContracts && childContracts.length > 0) {
        this.childContracts = childContracts.sort((a, b) => new Date(b.indskr_startdate).getTime() - new Date(a.indskr_startdate).getTime());
        this.initViewForChildContract();
        this.getChildContractHeader();
        this.getProcedureCoveredHeader();
      }
    });
  }

  // ---- contract details fields ----//
  private getAddressFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('ADDRESS'),
      inputText: this.procedureContract['customerAddressName'] ?? '',
      customPlaceholderLabel: this.translate.instant('SELECT_ADDRESS'),
      id: 'address-field',
      isReadOnly: true,
      isRequired: false,
      isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode,
      showArrow: !(this.isFullReadOnlyMode || this.partialReadOnlyMode),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.contractDetailsFields.push(viewData);
  }

  private getSpecialitiesFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('SPECIALITY'),
      inputText: this.procedureContract['specialityName'] ?? '',
      customPlaceholderLabel: this.translate.instant('XPERIENCES_SPECIALITY'),
      id: 'speciality-field',
      isReadOnly: true,
      isRequired: false,
      isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode,
      showArrow: !(this.isFullReadOnlyMode || this.partialReadOnlyMode),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.contractDetailsFields.push(viewData);
  }

  private getprocedureSubTypeFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PROCEDURE_CONTRACT_SUB_TYPE'),
      inputText: this.procedureContract['proceudreSubTypeString'],
      customPlaceholderLabel: this.translate.instant('SELECT_PROCEDURE_SUB_TYPE'),
      id: 'procedure-subType-field',
      isReadOnly: true,
      isRequired: this.usageType === PROCEDURE_CONTRACT_TYPES.FREE_CONTRACT && !this.isFullReadOnlyMode ? true : !this.isFullReadOnlyMode,
      isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode,
      showArrow: !(this.isFullReadOnlyMode || this.partialReadOnlyMode),
      formFieldType: FormFieldType.POPOVER_SELECT,
      isHidden: !this.isProcedureSubtypeAvailable,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.contractDetailsFields.push(viewData);
  }

  private getprocedureAllocatedFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PROCEDURES_ALLOCATED'),
      inputText: this.procedureContract['indskr_maximumnoofassistance']?.toString() ?? "0",
      inputValue: this.procedureContract['indskr_maximumnoofassistance'],
      customPlaceholderLabel: this.translate.instant('NO_PROCEDURES_ALLOCATED'),
      id: 'procedure-allocation-field',
      isRequired: !this.isFullReadOnlyMode,
      isReadOnly: false,
      isDisabled: this.isFullReadOnlyMode || this.shouldDisable || this.procedureSubTypeHasFrequency() || this.partialReadOnlyMode,
      showArrow: !(this.isFullReadOnlyMode || this.shouldDisable || this.procedureSubTypeHasFrequency() || this.partialReadOnlyMode),
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.contractDetailsFields.push(viewData);
  }

  private getProceduresUsedFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PROCEDURES_USED'),
      inputText: this.procedureContract['indskr_noofassistanceavailed']?.toString(),
      placeholderLabel: this.translate.instant('NO_PROCEDURES_USED'),
      id: 'procedures-used-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isRequired: false
    }
    this.contractDetailsFields.push(viewData);
  }

  private getProceduresAvailableFormField() {
    let contractAvailabe = this.procedureContract['indskr_maximumnoofassistance'];
    let contractUsed = this.procedureContract['indskr_noofassistanceavailed'];
    let proceduresContractAvailables = contractAvailabe - contractUsed || 0;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PROCEDURES_AVAILABLE'),
      inputText: proceduresContractAvailables + '',
      placeholderLabel: this.translate.instant('NO_PROCEDURES_AVAILABLE'),
      id: 'procedures-available-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isRequired: false,
    }
    this.contractDetailsFields.push(viewData);
  }

  private getcontractStartDateField() {
    let contractStartDayValue = this.datePipe.transform(this.procedureContract['indskr_startdate'], this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let date = this.datePipe.transform(this.procedureContract?.indskr_startdate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
    const viewData: IndDateTimeFormViewDataModel = {
      label: this.translate.instant('START_DATE'),
      inputText: this.isFullReadOnlyMode ? contractStartDayValue : date,
      customPlaceholderLabel: this.translate.instant('SELECT_START_PERIOD'),
      fromViewPage: CurViewPageType.ProcedureContract,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode,
      showArrow: !(this.isFullReadOnlyMode || this.partialReadOnlyMode),
      isRequired: !this.isFullReadOnlyMode,
      isEmptyRequiredField: this._isClicked && _.isEmpty(this.procedureContract?.indskr_startdate) && this._isClicked[DateTimeFieldType.StartDateField],
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.contractDetailsFields.push(viewData);
  }

  private getcontractEndDateField() {
    let contractEndDayValue = this.datePipe.transform(this.procedureContract['indskr_enddate'], this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let date = this.datePipe.transform(this.procedureContract.indskr_enddate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
    const viewData: IndDateTimeFormViewDataModel = {
      label: this.translate.instant('END_DATE'),
      inputText: this.isFullReadOnlyMode ? contractEndDayValue : date,
      customPlaceholderLabel: this.translate.instant('SELECT_END_PERIOD'),
      fromViewPage: CurViewPageType.ProcedureContract,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isRequired: !(this.isFullReadOnlyMode || this.usageType === PROCEDURE_CONTRACT_TYPES.PAID_SINGLE_DAY_CONTRACT),
      isDisabled: this.isFullReadOnlyMode || this.shouldDisable || this.procedureSubTypeHasDuration() || this.partialReadOnlyMode,
      showArrow: !(this.isFullReadOnlyMode || this.shouldDisable || this.procedureSubTypeHasDuration() || this.partialReadOnlyMode),
      isEmptyRequiredField: this._isClicked && _.isEmpty(this.procedureContract?.indskr_startdate) && this._isClicked[DateTimeFieldType.StartDateField],
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.contractDetailsFields.push(viewData);
  }

  private getAccountFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNT'),
      inputText: this.procedureContract['accountName'],
      placeholderLabel: this.translate.instant('NO_ACCOUNT'),
      id: 'account-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isRequired: !this.isFullReadOnlyMode,
    };
    this.contractDetailsFields.push(viewData);
  }

  private getNameFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PROCEDURE_CONTRACT_NAME'),
      inputText: this.procedureContract.indskr_name,
      inputValue: this.procedureContract.indskr_name,
      placeholderLabel: this.translate.instant('NAME'),
      id: 'name-field',
      isReadOnly: false,
      isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode,
      showArrow: !(this.isFullReadOnlyMode || this.partialReadOnlyMode),
      isRequired: false,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.contractDetailsFields.push(viewData);
  }

  private getStatusFormField() {
    const statusString = this.contractService.getStatusString(this.procedureContract.statuscode) ?? this.procedureContract.statusString;
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('STATUS'),
      inputText: statusString,
      inputValue: this.procedureContract.statuscode,
      placeholderLabel: this.translate.instant('STATUS'),
      id: 'status-field',
      isReadOnly: false,
      isDisabled: true,
      formFieldType: FormFieldType.INLINE_INPUT,
    };
    this.contractDetailsFields.push(viewData);
  }

  private getParentNameFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PROCEDURE_CONTRACT'),
      inputText: this.procedureContract.parentProcedureContractName,
      inputValue: this.procedureContract.parentProcedureContractName,
      placeholderLabel: this.translate.instant('PROCEDURE_CONTRACT'),
      id: 'parent-name-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isRequired: false,
      formFieldType: FormFieldType.INLINE_INPUT
    };
    this.contractDetailsFields.push(viewData);
  }

  private getcontractTypeFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('CONTRACT_TYPE'),
      inputText: this.procedureContract['contractTypeString'],
      customPlaceholderLabel: this.translate.instant('SELECT_CONTRACT_TYPE'),
      id: 'contract-type-field',
      isReadOnly: true,
      isRequired: !(this.isFullReadOnlyMode || this.partialReadOnlyMode),
      isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode,
      showArrow: !(this.isFullReadOnlyMode || this.partialReadOnlyMode),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.contractDetailsFields.push(viewData);
  }

  private getDurationFormField() {
    let viewData: IndFormFieldViewDataModel;
    const durationInDays = !this.procedureContract.indskr_enddate || !this.procedureContract.indskr_startdate ? 0 : differenceInDays(new Date(this.procedureContract.indskr_enddate), new Date(this.procedureContract.indskr_startdate)) + 1;
    viewData = {
      label: this.translate.instant('DURATION_CONTRACT_DAYS'),
      inputText: durationInDays >= 0 ? durationInDays.toString() : '0',
      inputValue: durationInDays,
      placeholderLabel: this.translate.instant('DURATION_CONTRACT_DAYS'),
      id: 'duration-field',
      isReadOnly: true,
      formFieldType: FormFieldType.INLINE_INPUT,
    };
    this.contractDetailsFields.push(viewData);
  }

  // ---- Procedure details fields ----//

  private getproductFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PRODUCT'),
      inputText: this.procedureContract['productName'],
      customPlaceholderLabel: this.translate.instant('SELECT_PRODUCT'),
      id: 'product-field',
      isReadOnly: true,
      isRequired: false,
      isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode,
      showArrow: !(this.isFullReadOnlyMode || this.partialReadOnlyMode),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.procedureDetailsFields.push(viewData);
  }

  private getproductIdFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PRODUCT_ID'),
      inputText: this.procedureContract['indskr_productid'],
      customPlaceholderLabel: this.translate.instant('SELECT_PRODUCT'),
      id: 'product-id-field',
      isReadOnly: true,
      isRequired: false,
      isDisabled: true,
      showArrow: false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    this.procedureDetailsFields.push(viewData);
  }

  private getProcedureFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PROCEDURE'),
      inputText: this.procedureContract['procedureName'],
      customPlaceholderLabel: this.translate.instant('SELECT_PROCEDURE'),
      id: 'procedure-field',
      isReadOnly: true,
      isRequired: false,
      isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode,
      showArrow: !(this.isFullReadOnlyMode || this.partialReadOnlyMode),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isHidden: this.hideProcedure,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.procedureDetailsFields.push(viewData);
  }

  // ---- pre signature fields ----//
  private getpreSignatureDateField() {
    let contractStartDayValue = this.datePipe.transform(this.procedureContract['indskr_presurgerysignaturecapturedate'], this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let date = this.datePipe.transform(this.procedureContract?.indskr_presurgerysignaturecapturedate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
    const viewData: IndDateTimeFormViewDataModel = {
      label: this.translate.instant('SIGNATURE_DATE'),
      inputText: this.isFullReadOnlyMode ? contractStartDayValue : date,
      customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
      fromViewPage: CurViewPageType.ProcedureContract,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: this.isFullReadOnlyMode || this.procedureContract.indskr_signaturecapturemode === CONTRACT_SIGNATURE_MODE.Digital || this.procedureContract.indskr_presurgerysignaturecaptured || !this.procedureContract.indskr_signaturecapturemode || this.procedureContract.indskr_postsurgerysignaturecaptured,
      showArrow: !(this.isFullReadOnlyMode || this.procedureContract.indskr_signaturecapturemode === CONTRACT_SIGNATURE_MODE.Digital || this.procedureContract.indskr_presurgerysignaturecaptured || !this.procedureContract.indskr_signaturecapturemode || this.procedureContract.indskr_postsurgerysignaturecaptured),
      isRequired: this.contractType?.indskr_presignature && this.procedureContract.indskr_signaturecapturemode === CONTRACT_SIGNATURE_MODE.Manual,
      eventHandler: (id: string, event, eventName) => this.openPresignatureDatePicker(),
    }
    this.preSurgeryDateField = viewData
  }

  private getPreValidatorFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('VALIDATOR_NAME'),
      inputText: this.procedureContract['indskr_validatornamepresurgerysignature'],
      inputValue: this.procedureContract['indskr_validatornamepresurgerysignature'],
      placeholderLabel: this.isFullReadOnlyMode ? this.translate.instant('NO_VALIDATOR') : this.translate.instant('ENTER_VALIDATOR_NAME'),
      id: 'prevalidator-field',
      isReadOnly: false,
      isDisabled: this.procedureContract.indskr_presurgerysignaturecaptured || this.isFullReadOnlyMode || !this.procedureContract.indskr_signaturecapturemode || this.procedureContract.indskr_postsurgerysignaturecaptured,
      showArrow: !(this.procedureContract.indskr_presurgerysignaturecaptured || this.isFullReadOnlyMode || !this.procedureContract.indskr_signaturecapturemode || this.procedureContract.indskr_postsurgerysignaturecaptured),
      isRequired: this.contractType?.indskr_presignature,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.preValidatorFormField = viewData;
  }

  private getCustomerFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.globalCustomerText,
      inputText: this.procedureContract.contactName ?? '',
      customPlaceholderLabel: this.procedureContract.contactName == null && this.isFullReadOnlyMode ? this.translate.instant('NO_CUSTOMER') : this.translate.instant('SELECT_WITH_GLOBALCUSTOMER', { globalCustomerText: this.globalCustomerText }),
      id: 'customer-field',
      isReadOnly: true,
      isDisabled: this.isFullReadOnlyMode || this.procedureContract.indskr_presurgerysignaturecaptured || !this.procedureContract.indskr_signaturecapturemode || this.procedureContract.indskr_postsurgerysignaturecaptured,
      showArrow: !(this.isFullReadOnlyMode || this.procedureContract.indskr_presurgerysignaturecaptured || !this.procedureContract.indskr_signaturecapturemode || this.procedureContract.indskr_postsurgerysignaturecaptured),
      isRequired: this.contractType?.indskr_presignature,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.customerFormField = viewData;
  }

  private createConsentFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CONSENT_TAKEN'),
      inputText: "true",
      inputValue: this.procedureContract.indskr_consenttaken,
      id: 'consent-field',
      isReadOnly: true,
      isDisabled: this.isFullReadOnlyMode || this.procedureContract.indskr_presurgerysignaturecaptured || !this.procedureContract.indskr_signaturecapturemode || this.procedureContract.indskr_postsurgerysignaturecaptured,
      showArrow: !(this.isFullReadOnlyMode || this.procedureContract.indskr_presurgerysignaturecaptured || !this.procedureContract.indskr_signaturecapturemode || this.procedureContract.indskr_postsurgerysignaturecaptured),
      isRequired: false,
      formFieldType: FormFieldType.CHECK_BOX,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.presurgeryDetailsFields.push(viewData);
  }

  private createComplyFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('COMPLY_WITH_RULES'),
      inputText: "true",
      inputValue: this.procedureContract.indskr_complywithhospitalrulesandregulations,
      id: 'comply-field',
      isReadOnly: true,
      isDisabled: this.isFullReadOnlyMode || this.procedureContract.indskr_presurgerysignaturecaptured || !this.procedureContract.indskr_signaturecapturemode || this.procedureContract.indskr_postsurgerysignaturecaptured,
      showArrow: !(this.isFullReadOnlyMode || this.procedureContract.indskr_presurgerysignaturecaptured || !this.procedureContract.indskr_signaturecapturemode || this.procedureContract.indskr_postsurgerysignaturecaptured),
      isRequired: false,
      formFieldType: FormFieldType.CHECK_BOX,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.presurgeryDetailsFields.push(viewData);
  }

  private getSignatureMethodFormField() {
    let viewData: IndFormFieldViewDataModel;
    let procedureSignatureType = this.contractService.findProcedureSignatureTypeById(this.procedureContract.indskr_signaturecapturemode);
    const hideField = ((this.contractType && this.contractType.indskr_allowchildcontractcreation) && !this.procedureContract.indskr_parentprocedurecontractid);

    const procedureTypeLabel = procedureSignatureType?.label || '';
    viewData = {
      label: this.translate.instant('PROCEDURE_CONTRACT_SIGNATURE_MODE'),
      inputText: procedureTypeLabel,
      customPlaceholderLabel: false ? this.translate.instant('PROCEDURE_CONTRACT_SIGNATURE_MODE') : this.translate.instant('SELECT_PROCEDURE_CONTRACT_SIGNATURE_MODE'),
      id: 'pre-contract-sign-method',
      isReadOnly: true,
      isHidden: hideField,
      isDisabled: this.viewMode == ComponentViewMode.PREVIEW || this.isFullReadOnlyMode || this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.DRAFT || this.procedureContract.indskr_presurgerysignaturecaptured || this.procedureContract.indskr_postsurgerysignaturecaptured,
      showArrow: !(this.viewMode == ComponentViewMode.PREVIEW || this.isFullReadOnlyMode || this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.DRAFT || this.procedureContract.indskr_presurgerysignaturecaptured || this.procedureContract.indskr_postsurgerysignaturecaptured),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };

    this.preSignModeField = viewData;
  }

  // ---- post signature fields ---- //

  private getpostSignatureDateField() {
    let contractStartDayValue = this.datePipe.transform(this.procedureContract['indskr_postsurgerysignaturecapturedate'], this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let date = this.datePipe.transform(this.procedureContract?.indskr_postsurgerysignaturecapturedate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);

    const viewData: IndDateTimeFormViewDataModel = {
      label: this.translate.instant('SIGNATURE_DATE'),
      inputText: this.isFullReadOnlyMode ? contractStartDayValue : date,
      customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
      fromViewPage: CurViewPageType.ProcedureContract,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: this.isFullReadOnlyMode || this.procedureContract.indskr_postsignaturecapturemode === CONTRACT_SIGNATURE_MODE.Digital || this.procedureContract.indskr_postsurgerysignaturecaptured || !this.procedureContract.indskr_postsignaturecapturemode || (this.contractType.indskr_presignature && !this.isPreSectionMandatoryFieldsFilled),
      showArrow: !(this.isFullReadOnlyMode || this.procedureContract.indskr_postsurgerysignaturecaptured || !this.procedureContract.indskr_postsignaturecapturemode || (this.contractType.indskr_presignature && !this.isPreSectionMandatoryFieldsFilled)),
      isRequired: this.contractType?.indskr_postsignature && this.procedureContract.indskr_postsignaturecapturemode === CONTRACT_SIGNATURE_MODE.Manual,
      eventHandler: (id: string, event, eventName) => this.openPostsignatureDatePicker(),
    }
    this.postSurgeryDateField = viewData
  }

  private getPostValidatorFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('VALIDATOR_NAME'),
      inputText: this.procedureContract['indskr_validatornamepostsurgerysignature'],
      inputValue: this.procedureContract['indskr_validatornamepostsurgerysignature'],
      placeholderLabel: this.isFullReadOnlyMode ? this.translate.instant('NO_VALIDATOR') : this.translate.instant('ENTER_VALIDATOR_NAME'),
      id: 'postvalidator-field',
      isReadOnly: false,
      isDisabled: this.isFullReadOnlyMode || this.procedureContract.indskr_postsurgerysignaturecaptured || !this.procedureContract.indskr_postsignaturecapturemode || (this.contractType.indskr_presignature && !this.isPreSectionMandatoryFieldsFilled),
      showArrow: !(this.isFullReadOnlyMode || this.procedureContract.indskr_postsurgerysignaturecaptured || !this.procedureContract.indskr_postsignaturecapturemode || (this.contractType.indskr_presignature && !this.isPreSectionMandatoryFieldsFilled)),
      isRequired: this.contractType?.indskr_postsignature,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.postValidatorFormField = viewData;
  }

  private getPostSignatureModeFormField() {
    let viewData: IndFormFieldViewDataModel;
    let procedureSignatureType = this.contractService.findProcedureSignatureTypeById(this.procedureContract.indskr_postsignaturecapturemode);
    const hideField = ((this.contractType && this.contractType.indskr_allowchildcontractcreation) && !this.procedureContract.indskr_parentprocedurecontractid);

    const procedureTypeLabel = procedureSignatureType?.label || '';
    viewData = {
      label: this.translate.instant('PROCEDURE_CONTRACT_SIGNATURE_MODE'),
      inputText: procedureTypeLabel,
      customPlaceholderLabel: false ? this.translate.instant('PROCEDURE_CONTRACT_SIGNATURE_MODE') : this.translate.instant('SELECT_PROCEDURE_CONTRACT_SIGNATURE_MODE'),
      id: 'post-contract-sign-method',
      isReadOnly: true,
      isHidden: hideField,
      isDisabled: this.viewMode == ComponentViewMode.PREVIEW || this.isFullReadOnlyMode || this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.DRAFT || this.procedureContract.indskr_postsurgerysignaturecaptured || (this.contractType?.indskr_presignature && !this.isPreSectionMandatoryFieldsFilled),
      showArrow: !(this.viewMode == ComponentViewMode.PREVIEW || this.isFullReadOnlyMode || this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.DRAFT || this.procedureContract.indskr_postsurgerysignaturecaptured || (this.contractType?.indskr_presignature && !this.isPreSectionMandatoryFieldsFilled)),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };

    this.postSignModeField = viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (!id) return;
    switch (id) {
      case 'prevalidator-field':
      case 'postvalidator-field':
        if (eventName && eventName === 'input_value_confirm') {
          this.handleValidatorField(event, id);
        }
        break;
      case 'pre-contract-sign-method':
        this.openSignOptions(event, 'presign');
        break;
      case 'procedure-field':
        this.openSurgerySelect();
        break;
      case 'procedure-subType-field':
        this.handleProcedureSubTypesField(event);
        break;
      case DateTimeFieldType.StartDateField:
        this.openStartDatePicker();
        break;
      case DateTimeFieldType.EndDateField:
        this.openEndDatePicker();
        break;
      case 'product-field':
        this.openProductSelect();
        break;
      case 'procedure-allocation-field':
        if (eventName && eventName == 'input_value_confirm') {
          this.handleProcedureAllocatedField(event);
        }
        break;
      case 'name-field':
        if (eventName && eventName == 'input_value_confirm') {
          this.handleProcedureContractNameField(event);
        }
        break;
      case 'speciality-field':
        this.openSpeciality();
        break;
      case 'address-field':
        this.openCustomerAddresses();
        break;
      case 'contract-type-field':
        this.handleContractTypesField(event);
        break;
      case 'customer-field':
        this.openCustomerSelect();
        break;
      case 'consent-field':
        this.onConsent(event);
        break;
      case 'comply-field':
        this.onComply(event);
        break;
      case 'procedure-field-k-code-1':
        this.handleKCode1Field(event);
        break;
      case 'procedure-field-k-code-2':
        this.handleKCode2Field(event);
        break;
      case 'procedure-field-k-code-3':
        this.handleKCode3Field(event);
        break;
      case 'procedure-field-other-k-code-name':
        if (eventName && eventName == 'input_value_confirm') {
          this.handleOtherKCodeField(event);
        }
        break;
      case 'post-contract-sign-method':
        this.openSignOptions(event, 'postsign');
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private async handleProcedureAllocatedField(event) {
    if (event && event.target.value !== undefined && this.procedureContract.indskr_maximumnoofassistance != event.target.value) {
      const payload = {
        indskr_maximumnoofassistance: event.target.value
      }
      await this.updateProcedureContractOnlineOrOffline(payload);
      this.procedureContract.indskr_maximumnoofassistance = parseInt(event.target.value);
      this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
      this.initAllDataModels();
    }
  }

  private async handleProcedureContractNameField(event) {
    if (event && event.target.value !== undefined && this.procedureContract.indskr_name != event.target.value) {
      let payload = {
        indskr_name: event.target.value
      }
      await this.updateProcedureContractOnlineOrOffline(payload);
      this.procedureContract.indskr_name = event.target.value;
      this.autoNamingDisabled = this.contractService.generateContractName(this.procedureContract) !== this.procedureContract.indskr_name;
      this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
      this.initAllDataModels();
    }
  }

  private async handleValidatorField(event, id) {
    // const letters = /^[^\d!@#$%^&*()_+={}\[\]:;'"<>,.?/\\`~|“”‘’„“«»-]*$/;
    let payload;
    // if (event.target.value.match(letters)) {
      if (id === 'prevalidator-field') {
        payload = {
          indskr_validatornamepresurgerysignature: event.target.value
        }
      } else if (id === 'postvalidator-field') {
        payload = {
          indskr_validatornamepostsurgerysignature: event.target.value
        }
      }
      await this.updateProcedureContractOnlineOrOffline(payload);
      this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, payload);
      if (id === 'prevalidator-field') {
        this.procedureContract.indskr_validatornamepresurgerysignature = event.target.value;
      } else if (id === 'postvalidator-field') {
        this.procedureContract.indskr_validatornamepostsurgerysignature = event.target.value;
      }
      this.initAllDataModels();
    // }
  }

  public async onPageTitleControlClick(id: string) {
    switch (id) {
      case "close":
        this._closePage();
        break;
      case "refresh":
        this.syncProcedureContract();
        break;
      case "cancel": {
        const resp = await this.showCancelProcedureContractPopover();
        if (resp.role == 'ok') this.cancelProcedureContract();
        break;
      }
      case 'activate': {
        const activateResp = await this.showAcitvateProcedureContractPopover();
        if (activateResp) this.activateProcedureContract();
        break;
      }
      default:
        console.log('no case');
    }
  }

  public syncProcedureContract() {
    this.uiService.displayLoader();
    this.contractService.fetchProcedureContracts(false, false, this.procedureContract.indskr_procedurecontractid).then((r) => {
      this.procedureContract = this.contractService.procedureContracts.find((pr) => pr.indskr_procedurecontractid === this.procedureContract.indskr_procedurecontractid);
      if (!this.procedureContract) {
        this._closePage();
        this.uiService.dismissLoader();
        return;
      }
      this.initAllDataModels();
      this.setFlags();
      if (this.procedureContract.statuscode === PROCEDURE_CONTRACT_STATUS.DRAFT) this.uiService.dismissLoader();
      this.fetchAssociatedProcedureLogs();
      if (!this.procedureContract.indskr_parentprocedurecontractid) {
        this.contractService.setChildContracts(this.procedureContract.indskr_procedurecontractid);
        this.initChildContractList();
      }
    });
  }

  private _closePage(): void {
    if (this.navService.getActiveChildNavName() === ChildNavNames.SurgeryContractNavigation) {
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.SurgeryOrderDetailsComponent);
      this.uiService.showRightPane = false;
      this.contractService.isRightPaneNavActive = false;
    } else {
      if (this.procedureContract?.indskr_parentprocedurecontractid) this.footerService.initButtons('');
      this.navService.popChildNavPageWithPageTracking();
    }
  }

  public async launchSignaturePad(signatureValue) {
    if (this.viewMode === ComponentViewMode.PREVIEW) return;
    let currentDate = new Date().getTime();
    const isPreSignature = signatureValue === "preSignature";
    const isPostSignature = signatureValue === "postSignature";

    if (isPreSignature && this.contractType?.indskr_presignature && !this.isPreSectionMandatoryFieldsFilled) {
      this.notificationService.notify(this.translate.instant('PROCEDURE_CONTRACT_SIGN_NOTIFICATION'), ToastStyle.INFO);
      return;
    }

    if (isPostSignature && this.contractType?.indskr_postsignature && !this.isPostSectionMandatoryFieldsFilled) {
      this.notificationService.notify(this.translate.instant('PROCEDURE_CONTRACT_SIGN_NOTIFICATION'), ToastStyle.INFO);
      return;
    }

    if (isPostSignature) {
      const preSignatureDate = new Date(this.procedureContract.indskr_presurgerysignaturecapturedate).getTime();
      const contractStartDate = new Date(this.procedureContract.indskr_startdate).getTime();

      if (this.contractType?.indskr_presignature && currentDate < preSignatureDate) {
        this.notificationService.notify(this.translate.instant('PRE_SIGNATURE_DATE_WARNING'), 'Procedure contract', 'top', ToastStyle.DANGER);
        return;
      }

      if (contractStartDate > currentDate) {
        this.notificationService.notify(this.translate.instant('SURGERY_DATE_WARNING'), 'Procedure contract', 'top', ToastStyle.DANGER);
        return;
      }
    }

    //signature capture
    let popover = await this.popoverCtrl.create({
      component: ConsentSignatureModalComponent,
      componentProps: {},
      cssClass: 'signature-pad-procedure-contract',
      backdropDismiss: false,
    });
    popover.present();
    popover.onDidDismiss().then(async (signData: any) => {
      let data = signData.data;
      if (data?.completedFlow) {
        let procedureContract = this.contractService.findProcedureContractById(this.procedureContract.indskr_procedurecontractid);
        let payload = this.createPayload(procedureContract, data.signatureData, signatureValue);

        if (this.procedureContract.indskr_signaturecapturemode === CONTRACT_SIGNATURE_MODE.Digital ||
          this.procedureContract.indskr_postsignaturecapturemode === CONTRACT_SIGNATURE_MODE.Digital) {
          this.handleDigitalSignatureCapture(signatureValue, payload);
        }

        await this.updateProcedureContractOnlineOrOffline(payload);
        this.updateContractSignatures(procedureContract);
        this.finalizeProcedureContractUpdates(procedureContract);
      }
    })
  }

  private createPayload(procedureContract: any, signatureData: any, signatureValue: string) {
    if (signatureValue === "preSignature") {
      procedureContract.indskr_presurgerysignature = signatureData;
      return {
        indskr_presurgerysignature: procedureContract.indskr_presurgerysignature,
        statuscode: PROCEDURE_CONTRACT_STATUS.PRE_SIGNED,
        indskr_signaturecapturemode: this.procedureContract.indskr_signaturecapturemode
      };
    } else if (signatureValue === "postSignature") {
      procedureContract.indskr_postsurgerysignature = signatureData;
      return {
        indskr_postsurgerysignature: procedureContract.indskr_postsurgerysignature,
        statuscode: PROCEDURE_CONTRACT_STATUS.POST_SIGNED,
        statecode: 0,
        indskr_postsignaturecapturemode: this.procedureContract.indskr_postsignaturecapturemode
      };
    }
  }

  private handleDigitalSignatureCapture(signatureValue: string, payload: any) {
    const currentDate = new Date();
    if (signatureValue === "preSignature") {
      this.procedureContract.indskr_presurgerysignaturecapturedate = currentDate;
      this.procedureContract.statuscode = PROCEDURE_CONTRACT_STATUS.PRE_SIGNED;
      payload.indskr_presurgerysignaturecapturedate = currentDate.getTime();
    } else if (signatureValue === "postSignature") {
      this.procedureContract.statuscode = PROCEDURE_CONTRACT_STATUS.POST_SIGNED;
      this.procedureContract.indskr_postsurgerysignaturecapturedate = currentDate;
      this.procedureContract.statecode = 0;
      payload.indskr_postsurgerysignaturecapturedate = currentDate.getTime();
    }
  }

  private async updateProcedureContractOnlineOrOffline(payload: any) {
    if (!this.device.isOffline) {
      await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
    } else {
      this.procedureContract.pendingPushToDynamics = true;
      this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
    }
  }

  private updateContractSignatures(procedureContract: any) {
    if (procedureContract.indskr_presurgerysignature) {
      this.procedureContract.indskr_presurgerysignature = procedureContract.indskr_presurgerysignature;
      this.procedureContract.indskr_presurgerysignaturecaptured = true;
    }

    if (procedureContract.indskr_postsurgerysignature) {
      this.procedureContract.indskr_postsurgerysignature = procedureContract.indskr_postsurgerysignature;
      this.procedureContract.indskr_postsurgerysignaturecaptured = true;
    }
  }

  private finalizeProcedureContractUpdates(procedureContract: any) {
    this.surgeryOrderDataService.updateProcedureLogsWithContractStatus(
      procedureContract.indskr_procedurecontractid,
      this.procedureContract.statuscode
    );
    this.contractService.updateProcedureContract(
      this.procedureContract.indskr_procedurecontractid,
      this.procedureContract
    );
    this.initAllDataModels();
  }

  private async openSignOptionPopover(myEvent, selectedValue) {
    const contractMethods = this.contractService.procedureSignatureTypes;
    if (contractMethods && contractMethods.length > 0) {
      const dropdownListDetail: IndDropdownListDetailModel = {
        id: 'procedure-signature-types',
        isMultipleSelectionEnabled: false,
        data: contractMethods.map(signatureType => {
          return {
            title: signatureType.label,
            id: signatureType.value,
            isSelected: signatureType.value == selectedValue,
          }
        }),
      };

      const procedureTypePopover = await this.popoverCtrl.create({
        component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'account-plan-select', event: myEvent
      });

      await procedureTypePopover.present();

      return await procedureTypePopover.onDidDismiss().then(async ({ data }) => {
        if (!data?.selectedItems?.length || !_.isArray(data.selectedItems)) return;
        return data;
      });
    }
  }

  private async openSignOptions(myEvent, mode) {
    const signatureCaptureMode = mode === 'presign' ? 'indskr_signaturecapturemode' : 'indskr_postsignaturecapturemode';
    const data = await this.openSignOptionPopover(myEvent, this.procedureContract[signatureCaptureMode]);
    if (!data) return;
    let payload = {};

    if (mode === 'presign') {
      payload = {
        indskr_signaturecapturemode: data.selectedItems[0].id,
        indskr_presurgerysignaturecapturedate: "",
        indskr_postsurgerysignaturecapturedate: "",
        indskr_validatornamepresurgerysignature: "",
        indskr_validatornamepostsurgerysignature: "",
        indskr_contact: "",
      };
    } else {
      payload = {
        indskr_postsignaturecapturemode: data.selectedItems[0].id,
        indskr_postsurgerysignaturecapturedate: "",
        indskr_validatornamepostsurgerysignature: "",
      }
    }

    await this.updateProcedureContractOnlineOrOffline(payload);
    if (mode === 'presign') {
      this.resetPresurgerySection();
    } else {
      this.resetPostsurgerySection();
    }

    this.procedureContract[signatureCaptureMode] = data.selectedItems[0].id;
    this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
    if (data.selectedItems[0].id === CONTRACT_SIGNATURE_MODE.Manual) {
      this.footerService.enableButtons(['downloadPDF']);
    }

    this.initAllDataModels();
    this.setFlags();
  }

  private async openOptionModal(notificationMessage: string): Promise<any> {
    const modal = await this.modalController.create({
      component: OmniInfoAlertComponent,
      cssClass: 'omni-notification-modal-md',
      componentProps: {
        alertType: AlertInfoTypes.NOTIFICATION_MESSAGE,
        header: this.translate.instant('UPLOAD_DOCUMENT'),
        notificationMessage: notificationMessage,
        note: this.translate.instant('NOTE_CONTRACT'),
        notificationIcon: 'assets/imgs/consent-signature.svg',
        buttons: [{
          label: this.translate.instant('CONFIRM'),
          role: 'confirm'
        }]
      }
    });

    await modal.present();
    return await modal.onDidDismiss().then(({ data }) => {
      if (!data || data.length === 0) return false;
      return data;
    });
  }

  public uploadButtonClicked(mode) {
    this.signType = mode
    this.attachInput.nativeElement.click();
  }

  public async footerButtonClicked(buttonId: string) {
    if (buttonId === 'downloadPDF') {
      this.downloadGeneratedContract();
    } else if (buttonId === 'sendEmailContract') {
      this.contractService.openEmailChooser(this.procedureContract);
    }
  }

  // to download pre and post contract
  public async downloadContracts(flag) {
    try {
      let resp;
      let fileName;
      const regex = /\/([^/]+)$/;
      if (flag == 'presign') {
        fileName = regex.exec(this.procedureContract.indskr_presurgerysignaturedocument)[1];
      } else {
        fileName = regex.exec(this.procedureContract.indskr_postsurgerysignaturedocument)[1];
      }
      await this.uiService.displayLoader();
      const url = await this.contractService.generateContractDocumentUrl(this.procedureContract, fileName);
      resp = await this.contractService.downloadContract(url);
      await this.uiService.dismissLoader();
      if (resp) this.notificationService.notify(this.translate.instant('FILE_DOWNLOADED'), 'Procedure contract', 'top', ToastStyle.INFO);
    } catch (error) {
      this.uiService.dismissLoader();
      console.log('error in comp', error)
    }
  }

  public async downloadGeneratedContract() {
    try {
      let resp;

      const preSignEnabled = this.contractType?.indskr_presignature;
      const postSignEnabled = this.contractType?.indskr_postsignature;

      if ((preSignEnabled && !this.isPreSectionMandatoryFieldsFilled) ||
        (!preSignEnabled && postSignEnabled && !this.isPostSectionMandatoryFieldsFilled)) {
        this.notificationService.notify(this.translate.instant('PROCEDURE_CONTRACT_SIGN_NOTIFICATION'), ToastStyle.INFO);
        return;
      }

      await this.uiService.displayLoader();
      const url = await this.contractService.generateUrl(this.procedureContract);
      resp = await this.contractService.downloadContract(url);
      await this.uiService.dismissLoader();
      if (resp) this.notificationService.notify(this.translate.instant('FILE_DOWNLOADED'), 'Procedure contract', 'top', ToastStyle.INFO);
    } catch (error) {
      this.uiService.dismissLoader();
      console.log('error in comp', error)
    }
  }

  async loadContractFromDevice(event) {
    if (!event?.target?.files?.length) return;
    const file = event.target.files[0];

    if (!this.isValidFileSize(file.size) || !this.isValidFileType(file.name)) return;

    const isConfirmed = await this.openOptionModal(file.name);
    if (!isConfirmed) return;

    if (!this.checkMandatoryFields(this.signType)) return;

    this.updateProcedureContractSignatures(this.signType);

    const payload = this.createFileUploadPayload(file.name);

    try {
      await this.uploadFile(file, payload);
    } catch (error) {
      console.error(error);
    }
  }

  private isValidFileSize(size) {
    if (size / 1000 < MAXIMUM_NOTE_ATTACHMENT_SIZE) return true;
    this.notificationService.notify(
      this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION', { size: MAXIMUM_NOTE_ATTACHMENT_SIZE }),
      'Procedure contract',
      'top',
      ToastStyle.INFO
    );
    return false;
  }

  private isValidFileType(name) {
    if (CONTRACT_SUPPORTED_MIME_TYPES_SUPPORTED_REGEX.test(name)) return true;
    this.notificationService.notify(
      this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'),
      'Procedure contract',
      'top',
      ToastStyle.INFO
    );
    return false;
  }

  private checkMandatoryFields(mode) {
    if (((mode === 'presign' && !this.isPreSectionMandatoryFieldsFilled) || (mode === 'postsign' && !this.isPostSectionMandatoryFieldsFilled))) {
      this.notificationService.notify(
        this.translate.instant('PROCEDURE_CONTRACT_SIGN_NOTIFICATION'),
        ToastStyle.INFO
      );
      return false;
    }
    return true;
  }

  private updateProcedureContractSignatures(mode) {
    if (mode === 'presign') {
      this.procedureContract.indskr_presurgerysignaturecaptured = true;
    } else if (mode === 'postsign') {
      this.procedureContract.indskr_postsurgerysignaturecaptured = true;
    }
  }

  private createFileUploadPayload(name) {
    const extension = name.substring(name.lastIndexOf("."));
    const statuscode = this.signType === 'presign' ? PROCEDURE_CONTRACT_STATUS.PRE_SIGNED : PROCEDURE_CONTRACT_STATUS.POST_SIGNED;

    let payload: any = {
      indskr_name: this.procedureContract.indskr_name,
      statuscode,
      statecode: 0,
      usageType: this.contractType?.indskr_usagetype,
      contentType: extension
    }

    if (this.signType === 'postsign') {
      payload = { ...payload, indskr_presurgerysignaturecaptured: this.procedureContract.indskr_presurgerysignaturecaptured, indskr_postsurgerysignaturecaptured: this.procedureContract.indskr_postsurgerysignaturecaptured };
    } else if (this.signType === 'presign') {
      payload = { ...payload, indskr_presurgerysignaturecaptured: this.procedureContract.indskr_presurgerysignaturecaptured, indskr_postsurgerysignaturecaptured: false };
    }

    return payload
  }

  private async uploadFile(file, payload) {
    this.uiService.displayLoader();
    const response: any = await this.contractService.uploadContractDocument(file, payload, this.procedureContract.indskr_procedurecontractid);
    this.uiService.dismissLoader();

    if (response) {
      if (payload.statuscode === PROCEDURE_CONTRACT_STATUS.PRE_SIGNED) {
        this.procedureContract.indskr_presurgerysignaturedocument = response.blobUrl;
      } else {
        this.procedureContract.indskr_postsurgerysignaturedocument = response.blobUrl;
      }
      this.procedureContract.statecode = payload.statecode;
      this.procedureContract.statuscode = payload.statuscode;
      this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
      this.notificationService.notify(this.translate.instant('UPLOAD_SUCCESS_CONTRACT'), 'Procedure contract', 'top', ToastStyle.INFO);
      this.initAllDataModels();
      return;
    }

    this.notificationService.notify(this.translate.instant('UPLOAD_FAILED'), 'Procedure contract', 'top', ToastStyle.DANGER);
  }

  private async openSurgerySelect(): Promise<void> {
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('PROCEDURE'),
      dividerTitle: this.translate.instant('ALL_PROCEDURE'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      hideAllItemsList: false,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProductComponentEvent(data, eventTarget, refData, 'surgery'),
      data: this.getSurgeriesSelectionData(),
    };
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isHierarchyView: true }, PageName.MainToolTemplateComponent);
  }

  private getSurgeriesSelectionData() {
    let data: Array<MainCardViewDataModel> = [];
    let masterList = this.getSortedMasterList();

    masterList.forEach(item => {
      const surgeryItem = this.createSurgeryItem(item, this.procedureContract.indskr_procedure_value);
      if (item.subProducts) {
        surgeryItem.childItems = this.getSubProducts(item.subProducts, item.surgeryId, data);
      }
      data.push(surgeryItem);
    });

    this.addUnmappedProcedure(data, masterList);

    // for (let item of masterList) {
    //   let isSelected = item.surgeryId == this.procedureContract.indskr_procedure_value;
    //   let obj = {
    //     id: item.surgeryId,
    //     primaryTextLeft: '',
    //     secondaryTextLeft: '',
    //     showEndIcon: true,
    //     mainItemCssClass: 'selector-item',
    //     isItemSelectedForSelectionView: isSelected,
    //     endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
    //     endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
    //     primaryTextRight: item.surgeryName,
    //     showArrow: false,
    //     childItems: [],
    //   };

    //   if (item.subProducts) {
    //     for (let subProduct of item.subProducts) {
    //       let childSubProduct: any = getProductFromList(subProduct.productId, item.surgeryId, this.procedureContract.indskr_procedure_value, this.isFullReadOnlyMode);
    //       if (childSubProduct) {
    //         obj.childItems.push(childSubProduct);
    //       }
    //     }
    //   }
    //   data.push(obj);
    // }

    // function getProductFromList(toBeAddedProductId: string, parentID: string, selectedId: string, isFullReadOnlyMode: boolean) {
    //   const foundSurgeryIdx = masterList.findIndex(a => a.surgeryId == toBeAddedProductId);
    //   let foundSurgery = masterList[foundSurgeryIdx];
    //   let prodIdxInTree = data.findIndex(p => p.id == toBeAddedProductId);
    //   if (prodIdxInTree >= 0) {
    //     let tobeaddedproduct = data[prodIdxInTree];
    //     tobeaddedproduct.parentId = parentID;
    //     data.splice(prodIdxInTree, 1);
    //     return tobeaddedproduct;
    //   }
    //   if (foundSurgery) {
    //     let isSelected = foundSurgery.surgeryId == selectedId;
    //     let obj = {
    //       id: foundSurgery.surgeryId,
    //       parentID: parentID,
    //       primaryTextLeft: '',
    //       secondaryTextLeft: '',
    //       showEndIcon: true,
    //       mainItemCssClass: 'selector-item',
    //       isItemSelectedForSelectionView: isSelected,
    //       endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
    //       endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
    //       primaryTextRight: foundSurgery.surgeryName,
    //       showArrow: false,
    //       childItems: [],
    //       arrowType: '',
    //     };
    //     let subProducts = foundSurgery.subProducts;
    //     masterList.splice(foundSurgeryIdx, 1);
    //     if (subProducts) {
    //       for (let subProduct of foundSurgery.subProducts) {
    //         let childSubProduct: any = getProductFromList(subProduct.productId, foundSurgery.surgeryId, selectedId, isFullReadOnlyMode);
    //         if (childSubProduct) {
    //           obj.childItems.push(childSubProduct);
    //         }
    //       }
    //     }
    //     return obj;
    //   } else {
    //     return null;
    //   }
    // }


    // if (this.procedureContract.indskr_procedure_value && !this.surgeryOrderDataService.productHierarchies.some(pro => this.procedureContract.indskr_procedure_value == pro.surgeryId)) {
    //   console.log(this.currentSurgeryOrderActivity);
    //   data.push({
    //     id: this.procedureContract.indskr_procedure_value,
    //     primaryTextLeft: '',
    //     secondaryTextLeft: '',
    //     showEndIcon: true,
    //     mainItemCssClass: 'selector-item',
    //     isItemSelectedForSelectionView: true,
    //     endIconType: 'indegene-selectors-checkmark-icon',
    //     endIconCssClass: 'checkmark-icon',
    //     primaryTextRight: this.procedureContract.procedureName + '(Inactive)',
    //     showArrow: false,
    //     arrowType: '',
    //   })
    // }
    return data;
  }

  private createSurgeryItem(item: any, selectedProcedureId: string): any {
    const isSelected = item.surgeryId === selectedProcedureId;
    return {
      id: item.surgeryId,
      primaryTextLeft: '',
      secondaryTextLeft: '',
      showEndIcon: true,
      mainItemCssClass: 'selector-item',
      isItemSelectedForSelectionView: isSelected,
      endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
      endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
      primaryTextRight: item.surgeryName,
      showArrow: false,
      childItems: []
    };
  }

  private getSortedMasterList(): any[] {
    return _.cloneDeep(this.surgeryOrderDataService.productHierarchies).sort((a, b): number => {
      return a.surgeryName.localeCompare(b.surgeryName);
    });
  }

  private getSubProducts(subProducts: any[], parentId: string, data: any[]): any[] {
    return subProducts
      .map(subProduct => this.getProductFromList(subProduct.productId, parentId, data))
      .filter(product => product !== null);
  }

  private getProductFromList(toBeAddedProductId: string, parentId: string, data: any[]): any {
    const masterList = this.surgeryOrderDataService.productHierarchies;
    const foundSurgery = masterList.find(surgery => surgery.surgeryId === toBeAddedProductId);
    const prodIdxInTree = data.findIndex(p => p.id === toBeAddedProductId);

    if (prodIdxInTree >= 0) {
      let tobeaddedproduct = data[prodIdxInTree];
      tobeaddedproduct.parentId = parentId;
      data.splice(prodIdxInTree, 1);  // Remove to reinsert
      return tobeaddedproduct;
    }

    if (foundSurgery) {
      let productItem = this.createSurgeryItem(foundSurgery, this.procedureContract.indskr_procedure_value);
      productItem.parentID = parentId;

      if (foundSurgery.subProducts) {
        productItem.childItems = this.getSubProducts(foundSurgery.subProducts, foundSurgery.surgeryId, data);
      }

      return productItem;
    }

    return null;
  }

  private addUnmappedProcedure(data: any[], masterList: any[]): void {
    if (
      this.procedureContract.indskr_procedure_value &&
      !masterList.some(pro => this.procedureContract.indskr_procedure_value === pro.surgeryId)
    ) {
      data.push({
        id: this.procedureContract.indskr_procedure_value,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: true,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: true,
        endIconType: 'indegene-selectors-checkmark-icon',
        endIconCssClass: 'checkmark-icon',
        primaryTextRight: `${this.procedureContract.procedureName} (Inactive)`,
        showArrow: false,
        arrowType: '',
      });
    }
  }

  private async handleContractTypesField(myEvent) {
    const contractTypes = this.contractService.contractTypes.filter(e => e.indskr_allowcreationbyuser);
    if (contractTypes && contractTypes.length > 0) {
      const dropdownListDetail: IndDropdownListDetailModel = {
        id: 'contract-types',
        isMultipleSelectionEnabled: false,
        data: contractTypes.map(type => {
          return {
            title: type.indskr_name,
            id: type.indskr_contracttypeid,
            isSelected: type.indskr_contracttypeid == this.procedureContract.indskr_contracttypes,
          }
        }),
      };

      const contractTypePopover = await this.popoverCtrl.create({
        component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'drop-down-list-view', event: myEvent
      });

      await contractTypePopover.present();

      await contractTypePopover.onDidDismiss().then(async ({ data }) => {
        let payload: any = {};
        if (!data?.selectedItems?.length || !Array.isArray(data.selectedItems) || this.procedureContract.indskr_contracttypes === data.selectedItems[0]?.id) return;

        this.procedureContract.contractTypeString = data.selectedItems[0].title;
        const newContractType = this.contractService.getContractType(data.selectedItems[0].id);

        if (!this.autoNamingDisabled) {
          this.procedureContract.indskr_name = this.contractService.generateContractName(this.procedureContract);
          payload = { ...payload, indskr_name: this.procedureContract.indskr_name };
        }

        payload = {
          ...payload,
          indskr_maximumnoofassistance: 1,
          indskr_enddate: "",
          indskr_contracttypes: data.selectedItems[0].id,
          indskr_proceduresubtypes: "",
          indskr_presurgerysignaturecapturedate: "",
          indskr_signaturecapturemode: "",
          indskr_validatornamepresurgerysignature: "",
          indskr_postsignaturecapturemode: ""
        }

        if (this.contractType && this.contractType.indskr_displaykcodefields) {
          // Clear Kcode Fields
          this.procedureContract.k_code_one_name = '';
          this.procedureContract.k_code_one_id = '';
          this.procedureContract.k_code_two_name = '';
          this.procedureContract.k_code_two_id = '';
          this.procedureContract.k_code_three_name = '';
          this.procedureContract.k_code_three_id = '';
          this.procedureContract.k_code_other_name = '';
          payload['appconfigfields'] = [
            this.contractService.getServicePayloadForKCodeField('jnjjapan_kcode_1', this.procedureContract),
            this.contractService.getServicePayloadForKCodeField('jnjjapan_kcode_2', this.procedureContract),
            this.contractService.getServicePayloadForKCodeField('jnjjapan_kcode_3', this.procedureContract),
            this.contractService.getServicePayloadForKCodeField('jnjjapan_otherkcodename', this.procedureContract),
          ];
        }

        await this.updateProcedureContractOnlineOrOffline(payload);
        this.procedureContract.indskr_maximumnoofassistance = payload.indskr_maximumnoofassistance;
        this.procedureContract.indskr_enddate = payload.indskr_enddate;
        this.procedureContract.indskr_contracttypes = data.selectedItems[0].id;
        this.procedureContract.indskr_proceduresubtype = null;
        this.procedureContract.proceudreSubTypeString = "";
        this.usageType = newContractType.indskr_usagetype;
        this.contractType = newContractType;

        this.procedureContract.indskr_signaturecapturemode = null;
        this.procedureContract.indskr_postsignaturecapturemode = null;
        this.resetPresurgerySection();
        this.resetPostsurgerySection();
        this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
        this.initView();
      });
    }
  }

  private async handleProcedureSubTypesField(myEvent) {
    if (!this.procedureContract.indskr_contracttypes) return;
    const procedureSubTypes = this.surgeryOrderDataService.showAssociatedProcedureSubTypes(this.procedureContract.indskr_contracttypes).sort((a, b) => {
      const orderA = Number(a.indskr_order);
      const orderB = Number(b.indskr_order);

      if (orderA < orderB) return -1;
      if (orderA > orderB) return 1;
      return a.indskr_name.localeCompare(b.indskr_name);
    });
    if (procedureSubTypes && procedureSubTypes.length > 0) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'procedure-sub-types',
        isMultipleSelectionEnabled: false,
        data: procedureSubTypes.map(type => {
          return {
            title: type.indskr_name,
            id: type.indskr_proceduresubtypeid,
            isSelected: type.indskr_proceduresubtypeid == this.procedureContract.indskr_proceduresubtype,
          }
        }),
      };

      let procedureSubTypePopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'account-plan-select', event: myEvent });
      await procedureSubTypePopover.present();

      await procedureSubTypePopover.onDidDismiss().then(async ({ data }) => {
        if (!data?.selectedItems?.length || !_.isArray(data.selectedItems) || this.procedureContract.indskr_proceduresubtype === data.selectedItems[0]?.id) return;
        let payload: any = {}

        const response = this.setAllocationAndDuration(data.selectedItems[0].id);
        if (response) {
          payload = {
            indskr_proceduresubtypes: data.selectedItems[0].id,
            indskr_maximumnoofassistance: response.indskr_maximumnoofassistance,
            indskr_enddate: response.indskr_enddate ? response.indskr_enddate.getTime() : "",
            indskr_startdate: response.indskr_startdate ? response.indskr_startdate.getTime() : "",
          }
        }

        await this.updateProcedureContractOnlineOrOffline(payload);
        this.procedureContract.indskr_proceduresubtype = data.selectedItems[0].id;
        this.procedureContract['proceudreSubTypeString'] = data.selectedItems[0].title;
        this.procedureContract.indskr_maximumnoofassistance = payload.indskr_maximumnoofassistance;
        this.procedureContract.indskr_enddate = payload.indskr_enddate;
        this.procedureContract.indskr_startdate = payload.indskr_startdate;
        this.procedureContract.indskr_signaturecapturemode = null;
        this.procedureContract.indskr_postsignaturecapturemode = null;

        this.resetPresurgerySection();
        this.resetPostsurgerySection();
        this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
        this.initAllDataModels();
      });
    }
  }

  private async openStartDatePicker() {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.getDateTimePopover(this.procedureContract.indskr_startdate, this.procedureContract.indskr_enddate);
    popover.present();
    popover.onDidDismiss().then(async (obj: any) => {
      if (obj?.data && !_.isEmpty(obj.data.startTime)) {
        const valueDate = new Date(obj.data.startTime);
        this.setStartDate(valueDate);
      }
    });
  }

  private async setStartDate(valueDate) {
    if (this.usageType === PROCEDURE_CONTRACT_TYPES.PAID_SINGLE_DAY_CONTRACT) {
      this.procedureContract.indskr_startdate = startOfDay(valueDate);
      this.procedureContract.indskr_enddate = endOfDay(valueDate);
    } else {
      if (this.procedureContract.indskr_enddate && valueDate > this.procedureContract.indskr_enddate) {
        this.notificationService.notify(this.translate.instant('AFFILIATION_END_DATE_CAN_NOT_BEFORE_START_DATE'), 'Procedure contract', 'top', ToastStyle.DANGER);
        return;
      }
      this.procedureContract.indskr_startdate = startOfDay(valueDate);
      const procedureSubtype = this.surgeryOrderDataService.findProcedureSubTypeById(this.procedureContract.indskr_proceduresubtype);
      if (procedureSubtype?.indskr_validity) {
        this.procedureContract.indskr_enddate = endOfDay(subDays(addMonths(this.procedureContract.indskr_startdate, procedureSubtype.indskr_validity), 1));
      }
    }

    let payload: any = {
      indskr_startdate: this.procedureContract.indskr_startdate.getTime(),
      indskr_enddate: this.procedureContract.indskr_enddate ? this.procedureContract.indskr_enddate.getTime() : ""
    }

    if (!this.autoNamingDisabled) {
      this.procedureContract.indskr_name = this.contractService.generateContractName(this.procedureContract);
      payload = { ...payload, indskr_name: this.procedureContract.indskr_name };
    }

    try {
      await this.updateProcedureContractOnlineOrOffline(payload);
      this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
      this.initAllDataModels();
    } catch (error) {
      console.log('Procedure contract date updation failed');
    }
  }

  private async openEndDatePicker() {
    if (!this.procedureContract.indskr_startdate) return;
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let popover = await this.getDateTimePopover(this.procedureContract.indskr_startdate, this.procedureContract.indskr_enddate);
    popover.present();
    popover.onDidDismiss().then(async (obj: any) => {
      if (obj?.data && !_.isEmpty(obj.data.endTime)) {
        const valueDate = new Date(obj.data.endTime);
        this.setEndDate(valueDate);
      }
    })
  }

  private async setEndDate(valueDate) {
    if (this.procedureContract.indskr_startdate > valueDate) {
      this.notificationService.notify(this.translate.instant('AFFILIATION_END_DATE_CAN_NOT_BEFORE_START_DATE'), 'New Account Plan', 'top', ToastStyle.DANGER);
    } else {
      this.procedureContract.indskr_enddate = valueDate;
      let payload = {
        indskr_enddate: this.procedureContract.indskr_enddate.getTime()
      }
      try {
        await this.updateProcedureContractOnlineOrOffline(payload);
        this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
        this.initAllDataModels();
      } catch (error) {
        console.log('Procedure contract date updation failed');
      }
    }
  }

  private async openPresignatureDatePicker() {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.getDateTimePopover(this.procedureContract.indskr_presurgerysignaturecapturedate);
    popover.present();
    popover.onDidDismiss().then(async (obj: any) => {
      if (obj?.data && !_.isEmpty(obj.data.startTime)) {
        const valueDate = startOfDay(new Date(obj.data.startTime));
        let procedureStartDate = new Date(this.procedureContract.indskr_startdate).getTime();
        const postsignatureDate = new Date(this.procedureContract.indskr_postsurgerysignaturecapturedate).getTime();

        if (postsignatureDate && postsignatureDate < valueDate.getTime()) {
          this.notificationService.notify(this.translate.instant('PRE_SIGNATURE_DATE_WARNING'), 'Procedure contract', 'top', ToastStyle.DANGER);
          return;
        }

        if (this.contractType?.indskr_usagetype !== PROCEDURE_CONTRACT_TYPES.FREE_CONTRACT && procedureStartDate > valueDate.getTime()) {
          this.notificationService.notify(this.translate.instant('SURGERY_DATE_WARNING'), 'Procedure contract', 'top', ToastStyle.DANGER);
          return;
        }

        // if (this.contractType.indskr_usagetype === PROCEDURE_CONTRACT_TYPES.FREE_CONTRACT && procedureStartDate <= valueDate.getTime()) {
        await this.updateProcedureContractOnlineOrOffline({ indskr_presurgerysignaturecapturedate: valueDate.getTime() });
        this.procedureContract.indskr_presurgerysignaturecapturedate = valueDate;
        this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, {
          indskr_presurgerysignaturecapturedate: valueDate.getTime(),
        });

        this.initAllDataModels();
        // } else {
        // this.notificationService.notify(this.translate.instant('SURGERY_DATE_WARNING'), 'Procedure contract', 'top', ToastStyle.DANGER);
        // }
      }
    })
  }

  private async openPostsignatureDatePicker() {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.getDateTimePopover(this.procedureContract.indskr_postsurgerysignaturecapturedate);
    popover.present();
    popover.onDidDismiss().then(async (obj: any) => {
      if (obj?.data && !_.isEmpty(obj.data.startTime)) {
        const valueDate = endOfDay(new Date(obj.data.startTime));
        let procedureStartDate = new Date(this.procedureContract.indskr_startdate).getTime();
        const presignatureDate = new Date(this.procedureContract.indskr_presurgerysignaturecapturedate).getTime();

        if (presignatureDate > valueDate.getTime()) {
          this.notificationService.notify(this.translate.instant('POST_SIGNATURE_DATE_WARNING'), 'Procedure contract', 'top', ToastStyle.DANGER);
          return;
        }

        if (procedureStartDate <= valueDate.getTime()) {
          await this.updateProcedureContractOnlineOrOffline({ indskr_postsurgerysignaturecapturedate: valueDate.getTime() });
          this.procedureContract.indskr_postsurgerysignaturecapturedate = valueDate;
          this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, {
            indskr_postsurgerysignaturecapturedate: valueDate.getTime(),
          });

          this.initAllDataModels();
        } else {
          this.notificationService.notify(this.translate.instant('SURGERY_DATE_WARNING'), 'Procedure contract', 'top', ToastStyle.DANGER);
        }
      }
    })
  }

  private async getDateTimePopover(startDate, endDate?) {
    return await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.ProcedureContract,
          startDateTimeValue: startDate ? format(startDate, "YYYY-MM-DDTHH:mm:ssZ") : null,
          endDateTimeValue: endDate ? format(endDate, "YYYY-MM-DDTHH:mm:ssZ") : null,
        },
        cssClass: "datetime-popover"
      }
    );
  }

  private openProductSelect(): void {
    if (this.isFullReadOnlyMode || this.partialReadOnlyMode) return;
    let products = this.getBrandProductsSelectionData();

    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('PRODUCTS'),
      dividerTitle: this.translate.instant('ALL_PRODUCTS_CAP'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      hideAllItemsList: false,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProductComponentEvent(data, eventTarget, refData, 'product'),
      data: products
    };
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail }, PageName.MainToolTemplateComponent);
  }

  private async _handleProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail, fieldName: string) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      if (data && data.isDone) {
        if (data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems.length > 0) {
          switch (fieldName) {
            case 'surgery':
              let surgeryProd = this.surgeryOrderDataService.productHierarchies.find(p => p.surgeryId == data.selectedItems[0].id)
              if (surgeryProd && this.procedureContract.indskr_procedure_value !== surgeryProd.surgeryId) {
                const payload = {
                  indskr_procedure_value: surgeryProd.surgeryId
                }
                await this.updateProcedureContractOnlineOrOffline(payload);
                this.procedureContract.indskr_procedure_value = surgeryProd.surgeryId;
                this.procedureContract.procedureName = surgeryProd.surgeryName;
                this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
                this.initAllDataModels();
              }
              break;
            case 'product':
              data.selectedItems.forEach(async item => {
                let foundProduct = this.procedureContractPositionGroupProducts.find(prod => prod.ID == item.id);
                if (foundProduct) {
                  const payload = {
                    indskr_product_value: foundProduct.ID,
                    indskr_productid: foundProduct.productnumber
                  }
                  await this.updateProcedureContractOnlineOrOffline(payload);
                  this.procedureContract.indskr_product_value = foundProduct.ID;
                  this.procedureContract.productName = foundProduct.name;
                  this.procedureContract.indskr_productid = foundProduct.productnumber.toString();
                  this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
                  this.initAllDataModels();
                }
              });
              break;
          }
        }
        else if (data.selectedItems.length == 0) {
          let payload: any;
          switch (fieldName) {
            case 'surgery':
              payload = {
                indskr_procedure_value: "",
              }
              await this.updateProcedureContractOnlineOrOffline(payload);
              this.procedureContract.indskr_procedure_value = '';
              this.procedureContract.procedureName = ''
              break;
            case 'product':
              payload = {
                indskr_product_value: "",
                indskr_productid: ""
              }
              await this.updateProcedureContractOnlineOrOffline(payload);
              this.procedureContract.indskr_product_value = '';
              this.procedureContract.productName = '';
              this.procedureContract.indskr_productid = '';
              this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
              break;
          }
        }
        this.initAllDataModels();
      }
    }
  }

  public setAllocationAndDuration(procedureSubtTypeId) {
    const procedureSubtype = this.surgeryOrderDataService.findProcedureSubTypeById(procedureSubtTypeId);
    let indskr_enddate = null;
    let indskr_startdate = this.procedureContract.indskr_startdate ? new Date(this.procedureContract.indskr_startdate) : null;
    let indskr_maximumnoofassistance = 1;

    if (!procedureSubtype) {
      return ({ indskr_maximumnoofassistance, indskr_startdate, indskr_enddate })
    }

    if (procedureSubtype.indskr_procedureallocated) {
      indskr_maximumnoofassistance = procedureSubtype.indskr_procedureallocated;
    }

    if (procedureSubtype.indskr_validity) {
      indskr_startdate = startOfDay(indskr_startdate ? new Date(this.procedureContract.indskr_startdate) : new Date());
      indskr_enddate = endOfDay(subDays(addMonths(indskr_startdate, procedureSubtype.indskr_validity), 1));
    }

    return ({ indskr_maximumnoofassistance, indskr_startdate, indskr_enddate });
  }

  private async createSummaryReport(startDate) {
    let newSummaryReport = this.contractService.generateContract(
      this.procedureContract.indskr_account_value, this.procedureContract.accountName, this.procedureContract);

    newSummaryReport.indskr_startdate = startDate;
    newSummaryReport.statuscode = PROCEDURE_CONTRACT_STATUS.ACTIVE;
    newSummaryReport.indskr_enddate = lastDayOfMonth(newSummaryReport.indskr_startdate);
    newSummaryReport.indskr_name = this.contractService.generateContractName(newSummaryReport);

    // if monthly summary is creating for the end month of the contract, we have to set end date from parent contract
    if (startDate.getMonth() === new Date(this.procedureContract.indskr_enddate).getMonth()) {
      newSummaryReport.indskr_enddate = new Date(this.procedureContract.indskr_enddate);
    }

    return newSummaryReport;
  }

  private async createSummaryReportAndNavigate(startDate) {
    try {
      let newSummaryReport: any = await this.createSummaryReport(startDate);
      let payload: any = this.contractService.generatePayload(newSummaryReport);

      if (this.contractType && this.contractType.indskr_allowduplicatechildcontractcreation) {
        payload = { ...payload, isDuplicateContractAllowed: true }
      }

      if (!this.device.isOffline) {
        this.uiService.displayLoader();
        const response: any = await this.contractService.createProcedureContractOnline(payload);
        if (response) {
          newSummaryReport.indskr_procedurecontractid = response.indskr_procedurecontractid;
          this.uiService.dismissLoader();
        }
      }

      newSummaryReport = new ProcedureContract(newSummaryReport);
      if (this.device.isOffline) {
        newSummaryReport.isNew = true;
        newSummaryReport.pendingPushToDynamics = true;
        this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
      }

      await this.contractService.CreateProcedureContractInLocal(newSummaryReport);
      this.contractService.isProcedureContractCreationActive = true;
      this.navService.pushChildNavPageWithPageTracking(SurgeryOrderContractDetailsComponent,
        PageName.SurgeryOrderContractDetailsComponent, PageName.AccountPageComponent,
        {
          procedureContract: newSummaryReport,
          viewMode: ComponentViewMode.ADDNEW
        });
    } catch ({ error }) {
      console.log('Error while creating child contract', error.errorCode);
      if (error && error.errorCode === 'PROCEDURECONTRACTDUPLICATE') {
        this.notificationService.notify(this.translate.instant('DUPLICATE_CHILD_CONTRACT'), 'Procedure contract', 'top', ToastStyle.DANGER);
      }
      this.uiService.dismissLoader();
    }
  }

  public onChildContractHeaderClick(id) {
    if (id === 'plus-icon') {
      this.showYearMonthPopup();
    }
  }

  public viewChildContract(index) {
    this.navService.pushChildNavPageWithPageTracking(SurgeryOrderContractDetailsComponent, PageName.SurgeryOrderContractDetailsComponent, PageName.AccountPageComponent,
      {
        procedureContract: this.childContracts[index],
        viewMode: ComponentViewMode.READONLY
      });
  }

  private async showYearMonthPopup() {
    try {
      let alradyCreatedMonths = [];

      if (!this.shouldAllowDuplicateChildContract) {
        alradyCreatedMonths = this.childContracts.map((cont) => {
          let startDate = new Date(cont.indskr_startdate);
          startDate.setHours(0, 0, 0);
          return startDate.getTime();
        });
      }

      let parentCoveredYears = [];
      let minYear = new Date(this.procedureContract.indskr_startdate).getFullYear();
      let maxYear = new Date(this.procedureContract.indskr_enddate).getFullYear();
      for (let i = minYear; i <= maxYear; i++) {
        parentCoveredYears.push(i);
      }

      let minDate = new Date(this.procedureContract.indskr_startdate);
      minDate.setHours(0, 0, 0);
      let maxDate = new Date(this.procedureContract.indskr_enddate);
      maxDate.setHours(0, 0, 0);

      const modal = await this.modalController.create({
        component: OmniYearMonthPickerComponent,
        cssClass: 'omni-notification-modal',
        componentProps: {
          header: this.translate.instant('CREATE_SUMMARY_REPORT'),
          buttonLabel: this.translate.instant('CONFIRM'),
          isMultiSelect: false,
          params: {
            selectedMonths: alradyCreatedMonths,
            selectedYear: null,
            inputYears: parentCoveredYears,
            min: minDate,
            max: maxDate
          }
        }
      });

      await modal.present();

      await modal.onDidDismiss().then(({ data }) => {
        if (data) {
          this.createSummaryReportAndNavigate(new Date(data[0]));
        }
      });
    } catch (error) {
      console.log('error while opening month year popup in contract', error);
    }
  }

  private initViewForChildContract() {
    this.displayChildContracts = [];
    this.childContracts.forEach((item) => {
      let newChildContract: ProcedureLogData
      newChildContract = {
        headerText: item.indskr_name,
        values: [
          {
            label: this.translate.instant('START_DATE'),
            value: this.datePipe.transform(item.indskr_startdate),
            placeholderText: this.translate.instant('NO_START_DATE'),
            showLines: true,
          },
          {
            label: this.translate.instant('END_DATE'),
            value: this.datePipe.transform(item.indskr_enddate),
            placeholderText: this.translate.instant('NO_END_DATE'),
            showLines: true,
          }
        ]
      }
      this.displayChildContracts.push(newChildContract);
    })
  }

  private openSpeciality() {
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('SPECIALITY'),
      dividerTitle: this.translate.instant('ALL_SPECIALTIES'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      hideAllItemsList: false,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleSpecialityComponentEvent(data, eventTarget),
      data: this.getSpecialitySelectionData(),
    };
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isHierarchyView: true }, PageName.MainToolTemplateComponent);
  }

  private getSpecialitySelectionData() {
    let data = [];
    if (this.specialities.length == 0) return [];
    let filteredSpecialities = this.specialities;

    filteredSpecialities.forEach((speciality: Specialty) => {
      let isSelected = speciality.id == this.procedureContract.indskr_Specialty;
      data.push({
        id: speciality.id,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: true,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: speciality.name,
        showArrow: false,
        arrowType: '',
      });
    });

    // if (this.procedureContract.indskr_Specialty && data.every((item) => item.id !== this.procedureContract.indskr_Specialty)) {
    //   data.push({
    //     id: this.procedureContract.indskr_Specialty,
    //     primaryTextLeft: '',
    //     secondaryTextLeft: '',
    //     showEndIcon: true,
    //     mainItemCssClass: 'selector-item',
    //     isItemSelectedForSelectionView: true,
    //     endIconType: 'indegene-selectors-checkmark-icon',
    //     endIconCssClass: 'checkmark-icon',
    //     primaryTextRight: this.procedureContract.specialityName,
    //     showArrow: false,
    //     arrowType: '',
    //   });
    // }

    return data;
  }

  private async _handleSpecialityComponentEvent(data: any, eventTarget: string) {
    try {
      if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
        if (data?.isDone) {
          let payload = {
            indskr_Specialty: data.selectedItems[0] ? data.selectedItems[0].id : ""
          }

          await this.updateProcedureContractOnlineOrOffline(payload);
          this.procedureContract.indskr_Specialty = data.selectedItems[0] ? data.selectedItems[0].id : "";
          this.procedureContract.specialityName = data.selectedItems[0] ? data.selectedItems[0].primaryTextRight : "";
          this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
          this.initAllDataModels();
        }
      }
    } catch (error) {
      console.log('Customer speciality update failed in procedure contract');
    }
  }

  private openCustomerAddresses() {
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('ADDRESS'),
      dividerTitle: this.translate.instant('ALL_ADDRESS'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      hideAllItemsList: false,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleCustomerAddressComponentEvent(data, eventTarget),
      data: this.getCustomerAddressSelectionData(),
    };
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isHierarchyView: true }, PageName.MainToolTemplateComponent);
  }

  private getCustomerAddressSelectionData() {
    let data = [];
    let filteredCustomerAddress = this.customerAddresses.filter((speciality: customerAddress) => speciality.accountId === this.procedureContract.indskr_account_value);

    filteredCustomerAddress.forEach((customerAddress: customerAddress) => {
      let isSelected = customerAddress.customerAddressId == this.procedureContract.indskr_Address;
      data.push({
        id: customerAddress.customerAddressId,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: true,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: customerAddress.customerAddressString,
        showArrow: false,
        arrowType: '',
      });
    })
    return data;
  }

  private async _handleCustomerAddressComponentEvent(data: any, eventTarget: string) {
    try {
      if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
        if (data?.isDone) {
          const payload = {
            indskr_Address: data.selectedItems[0] ? data.selectedItems[0].id : ""
          }
          await this.updateProcedureContractOnlineOrOffline(payload);
          this.procedureContract.indskr_Address = data.selectedItems[0] ? data.selectedItems[0].id : "";
          this.procedureContract.customerAddressName = data.selectedItems[0] ? data.selectedItems[0].primaryTextRight : "";
          this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
          this.initAllDataModels();
        }
      }
    } catch (error) {
      console.log('error while updating customer address in procedure contract')
    }
  }

  public async onConsent(ev) {
    if (this.isFullReadOnlyMode) return;
    let consent = ev;
    await this.updateProcedureContractOnlineOrOffline({ indskr_consenttaken: consent });
    this.procedureContract.indskr_consenttaken = consent;
    this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
    this.initAllDataModels();
  }

  public async onComply(ev) {
    if (this.isFullReadOnlyMode) return;
    let comply = ev;
    await this.updateProcedureContractOnlineOrOffline({ indskr_complywithhospitalrulesandregulations: comply });
    this.procedureContract.indskr_complywithhospitalrulesandregulations = comply
    this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
    this.initAllDataModels();
  }

  public async showCancelProcedureContractPopover() {
    return await this.alertService.showAlert({
      title: this.translate.instant('CANCEL_PROCEDURE_CONTRACT'),
      message: this.translate.instant('PROCEDURE_CONTRACT_CANCEL'),
    }, this.translate.instant('OK')
    ).then(res => {
      return res;
    });
  }

  private async cancelProcedureContract() {
    if (this.device.isOffline) return;

    const payload = {
      statuscode: PROCEDURE_CONTRACT_STATUS.CANCELLED,
      statecode: 1
    }
    try {
      await this.contractService.UpdateProcedureContractOnline(payload, this.procedureContract.indskr_procedurecontractid);
      this.contractService.removeProcedureContract(this.procedureContract.indskr_procedurecontractid);
      if (this.contractType && this.contractType.indskr_allowchildcontractcreation) {
        this.contractService.removeChildProcedureContracts(this.procedureContract.indskr_procedurecontractid);
      }
      this._closePage();
    } catch (error) {
      console.log('Error while cancelling procedure contract', error);
    }
  }

  public getBrandProductsSelectionData() {
    let data = [];
    this.procedureContractPositionGroupProducts.forEach(prod => {
      let isSelected = false;
      if (this.procedureContract && this.procedureContract.indskr_product_value
        && this.procedureContract.indskr_product_value === prod.ID) {
        isSelected = true;
      }
      data.push({
        id: prod.ID,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: !this.isFullReadOnlyMode || !this.partialReadOnlyMode,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: prod.name,
        showArrow: false,
        arrowType: '',
      });
    })
    return data;
  }

  private openCustomerSelect(): void {
    if (this.isFullReadOnlyMode) return;

    this.uiService.prevView = this.uiService.activeView;
    this.uiService.activeView = 'contactDetails';
    this.uiService.showNewActivity = false;
    this.contactService.accessedContactListFrom = PageName.SurgeryOrderContractDetailsComponent;
    this.contactService.contactPageMode = ComponentViewMode.SELECTION;
    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent,
      {
        type: 'PushedContactSelectionView',
        selectedContactIds: [this.procedureContract.indskr_contact],
        selectedAccountIds: [{ id: this.procedureContract.indskr_account_value }],
        callbackEvent: (data: any) => this._handleContactComponentCallback(data)
      }, PageName.DynamicFormComponent, { animate: false }, null);
  }

  private async _handleContactComponentCallback(data) {
    try {
      this.initFooter();
      if (!data || !data.selectedItems) return;
      let payload;

      if (data.selectedItems.length == 0) {
        payload = { indskr_contact: "" }
        // if (this.procedureContract.indskr_validatornamepresurgerysignature == this.procedureContract.contactName) {
        //   payload = { ...payload, indskr_validatornamepresurgerysignature: "" }
        // }
      } else {
        payload = { indskr_contact: data.selectedItems[0].ID, indskr_validatornamepresurgerysignature: data.selectedItems[0].fullname };
      }

      await this.updateProcedureContractOnlineOrOffline(payload);
      if (data.selectedItems.length == 0) {
        // if (this.procedureContract.indskr_validatornamepresurgerysignature == this.procedureContract.contactName) {
        //   this.procedureContract.indskr_validatornamepresurgerysignature = null;
        // }
        this.procedureContract.indskr_contact = null;
        this.procedureContract.contactName = null;
      } else {
        this.procedureContract.indskr_contact = data.selectedItems[0].ID;
        this.procedureContract.contactName = data.selectedItems[0].fullname;
        this.procedureContract.indskr_validatornamepresurgerysignature = data.selectedItems[0].fullname;
      }
      this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
      this.initAllDataModels();
    } catch (error) {
      console.log(error);
    }
  }

  private setFlags() {
    if (!this.procedureContract.indskr_contracttypes) return;
    if (!this.contractType) return;
    const procedureSubTypes = this.surgeryOrderDataService.showAssociatedProcedureSubTypes(this.procedureContract.indskr_contracttypes);
    this.shouldShowPresurgerySection = this.contractType.indskr_presignature;
    this.shouldShowPostsurgerySection = this.contractType.indskr_postsignature && ((this.contractType.indskr_allowchildcontractcreation && this.procedureContract.indskr_parentprocedurecontractid != '') || !this.contractType.indskr_allowchildcontractcreation);
    this.isProcedureSubtypeAvailable = procedureSubTypes.length > 0;
  }

  private resetPresurgerySection() {
    this.procedureContract.contactName = null;
    this.procedureContract.indskr_contact = null;
    this.procedureContract.indskr_validatornamepresurgerysignature = null;
    this.procedureContract.indskr_presurgerysignaturecapturedate = null;
  }

  private resetPostsurgerySection() {
    this.procedureContract.indskr_validatornamepostsurgerysignature = null;
    this.procedureContract.indskr_postsurgerysignaturecapturedate = null;
    this.procedureContract.indskr_postsignaturecapturemode = null;
  }

  public async showAcitvateProcedureContractPopover() {
    const modal = await this.modalCtrl.create({
      component: OmniInfoAlertComponent,
      cssClass: 'omni-notification-modal',
      componentProps: {
        header: this.translate.instant('ACTIVATE_PROCEDURE_CONTRACT'),
        alertType: AlertInfoTypes.NOTIFICATION_MESSAGE,
        notificationMessage: this.translate.instant('ACTIVATE_PROCEDURE_CONTRACT_MESSAGE')
      }
    });

    modal.componentProps.buttons = [{ label: this.translate.instant('CONFIRM'), role: 'ok' }]
    await modal.present();

    return await modal.onDidDismiss().then(({ data }) => {
      if (!data) return false;
      if (data.role === 'ok') return true;
      else return false;
    });
  }

  private async activateProcedureContract() {
    const payload = {
      statuscode: PROCEDURE_CONTRACT_STATUS.ACTIVE,
      statecode: 0
    }
    try {
      await this.updateProcedureContractOnlineOrOffline(payload);
      this.procedureContract.statuscode = PROCEDURE_CONTRACT_STATUS.ACTIVE;
      this.procedureContract.statecode = 0;
      this.procedureContract.statusString = this.translate.instant('ACTIVE')
      this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);

      this.initAllDataModels();

    } catch (error) {
      console.log('Error while cancelling procedure contract', error);
    }
  }

  // ----------- config fields ------------ //

  private initConfigFields(configuration) {
    const configuredField: ConfiguredFields = configuration;
    const id = getConfigFormFieldViewDataModelId(configuredField.fieldType, configuredField.fieldName);
    const { inputText, inputValue } = this.contractService.getConfigFieldInputTextAndValue(configuredField.fieldType, configuredField.fieldName, this.procedureContract.configuredFields[configuredField.fieldName]);
    const formFieldType = getConfigFormFieldType(configuredField.fieldType);
    const forceReadOnly: boolean = ReadOnlyEventConfiguredFieldNames.includes(configuredField.fieldName);
    const isReadOnly: boolean = forceReadOnly || getConfigFormFieldIsReadOnly(configuredField.fieldType, configuredField.readOnly) || this.shouldConfiguredFieldReadonly(configuredField);
    const isDisabled: boolean = formFieldType === FormFieldType.POPOVER_SELECT && configuredField.readOnly
      ? true
      : (this.shouldConfiguredFieldReadonly(configuredField) || configuredField.readOnly);
    const isRequired: boolean = !this.isFullReadOnlyMode && configuredField.mandatory === 'true';
    const isEmptyRequiredField: boolean = this.requiredFields[id]?.isEvaluated === true
      && (inputText === '' || inputText === undefined || inputText === null);
    const field: IndFormFieldViewDataModel = {
      id,
      formFieldType,
      label: configuredField.fieldLabel,
      inputType: getConfigFormInputType(configuredField.fieldType),
      inputText,
      inputValue,
      placeholderLabel: this.shouldConfiguredFieldReadonly(configuredField) ? `${this.translate.instant('NO')} ${configuredField.fieldLabel}` : configuredField.fieldLabel,
      isReadOnly: isReadOnly || configuredField.fieldType === 'DateTime',
      isDisabled,
      isRequired,
      showArrow: !this.isFullReadOnlyMode && !isDisabled && !forceReadOnly,
      errorMessage: isRequired ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField,
      eventHandler: (id: string, event, eventName) => this._handleConfigFieldEvent(
        id,
        event,
        eventName,
        configuredField.fieldType,
        configuredField.fieldName,
      ),
    }
    return field;
  }

  private async _handleConfigFieldEvent(id, event, eventName, fieldType, fieldName) {
    if (id) {
      const currentValue = this.procedureContract.configuredFields[fieldName] ?? null;

      try {
        switch (fieldType) {
          case 'String':
          case 'Memo':
            if (eventName === 'input_value_confirm') {
              const newValue = event?.target?.value?.length > 0 ? event.target.value : null;
              if (currentValue !== newValue) {
                this.updateConfigFieldsValues(fieldType, fieldName, newValue);
              }
              this._configFieldMandatoryCheck(fieldType, fieldName);
            }
            break;
          case 'Integer':
          case 'BigInt':
          case 'Decimal':
          case 'Money':
          case 'Currency':
            if (eventName === 'input_value_confirm') {
              let newValue: any = null;
              if (
                event?.target?.value !== null
                && event?.target?.value !== undefined
                && event?.target?.value !== ''
              ) {
                try {
                  newValue = Number(event.target.value);
                } catch (error) {
                  console.error('_handleConfigFieldEvent: not a number: ', error, event, fieldType, fieldName);
                }
              }
              this.updateConfigFieldsValues(fieldType, fieldName, newValue);
              this._configFieldMandatoryCheck(fieldType, fieldName);

            }
            break;
          case 'Boolean':
          case 'Picklist':
          case 'Virtual':
            this._openConfigFieldOptionSetPopover(event, fieldType, fieldName);
            break;

          case 'DateTime':
            let popover = await this.getDateTimePopover(currentValue);
            this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
            popover.componentProps.currentViewPage = CurViewPageType.ConfiguredForm
            popover.present();

            popover.onDidDismiss().then(async (obj: any) => {
              if (obj && obj.data && !_.isEmpty(obj.data.startTime)) {
                this.updateConfigFieldsValues(fieldType, fieldName, obj.data.startTime);
                this._configFieldMandatoryCheck(fieldType, fieldName);
              }
            });
            break;
          case 'Lookup':
            this.openLookup(event, fieldName);
            break;
          default:
            break;
        }
      } catch (error) {
        console.error('_handleConfigFieldEvent: ', error, event, fieldType, fieldName);
      }
    }
  }

  private async _openConfigFieldOptionSetPopover(event, fieldType: string, fieldName: string) {
    let booleanConfigFieldOptionValues = [
      {
        label: this.translate.instant('YES'),
        value: true,
      },
      {
        label: this.translate.instant('NO'),
        value: false,
      },
    ];

    const configuredFieldOptionValues = fieldType === 'Boolean' ? booleanConfigFieldOptionValues : this.contractService.configFieldOptionsValues[fieldName] ?? [];

    if (Array.isArray(configuredFieldOptionValues)) {
      let currentSelectedValue = this.procedureContract.configuredFields[fieldName];
      let currentSelectedMultiOptionValues: number[] = [];
      let data: SelectListData[];

      try {
        switch (fieldType) {
          case 'Boolean':
          case 'Picklist':
            data = configuredFieldOptionValues.map(option => (
              {
                id: option.label,
                title: option.label,
                isSelected: currentSelectedValue === option.value,
                value: option.value,
              }
            ));
            break;
          case 'Virtual': {
            if (currentSelectedValue) {
              currentSelectedMultiOptionValues = currentSelectedValue.split(',').map(str => Number(str));
            }

            data = configuredFieldOptionValues.map(option => (
              {
                id: option.label,
                title: option.label,
                isSelected: currentSelectedMultiOptionValues.some(v => v === option.value),
                value: option.value,
              }
            ));
            break;
          }

          default:
            console.warn(
              'event-details: _openConfigFieldOptionSetPopover: unsupported fieldType: ',
              fieldType, fieldName, event
            );
            break;
        }
      } catch (error) {
        console.error('_openConfigFieldOptionSetPopover: ', error, event, fieldType, fieldName);
      }

      if (Array.isArray(data)) {
        const isMultiSelect = fieldType === 'Virtual';
        const viewData: IndDropdownListDetailModel = {
          id: 'configuredPicklist',
          data,
          isMultipleSelectionEnabled: isMultiSelect,
          showCancelandDoneBtn: isMultiSelect,
        };

        const popover = await this.popoverCtrl.create({
          component: IndDropdownListComponent,
          componentProps: { viewData },
          cssClass: 'dropdown-list-view',
          event: event,
        });
        await popover.present();
        const response = await popover.onDidDismiss();

        if (response?.data?.isDone === true) {
          if (isMultiSelect) {
            //         if (Array.isArray(response.data.selectedItems)) {
            //           const newValues: number[] = response.data.selectedItems.map(i => i.value);
            //           const diff = difference(currentSelectedMultiOptionValues, newValues)
            //             .concat(difference(newValues, currentSelectedMultiOptionValues));
            //           if (Array.isArray(diff) && diff.length > 0) {
            //             const newValueStr: string = newValues.join();
            //             this.originalFieldValues[fieldName] = this.currentEvent.configuredFields[fieldName];
            //             this.currentEvent.configuredFields[fieldName] = newValueStr;
            //             this._updateConfigFieldInputValues(fieldType, fieldName);
            //             shouldCheckForMandatoryField = false;
            //           }
            //         }
          } else {
            if (
              Array.isArray(response.data.selectedItems)
              && response.data.selectedItems.length > 0
              && currentSelectedValue !== response.data.selectedItems[0].value
            ) {
              this._configFieldMandatoryCheck(fieldType, fieldName);
              this.updateConfigFieldsValues(fieldType, fieldName, response.data.selectedItems[0].value);
            }
          }
        }
      }
    }
  }

  private async updateConfigFieldsValues(fieldType: string, fieldName: string, inputValue: any) {
    let payload: any = {
      "appconfigfields": [{
        "fieldname": fieldName,
        "datatype": fieldType,
        "value": inputValue
      }]
    }

    if (fieldType === 'DateTime') {
      const offsetDate = new Date(inputValue);
      const tzIndependentDateObj = new Date(
        offsetDate.getUTCFullYear(),
        offsetDate.getUTCMonth(),
        offsetDate.getUTCDate(),
        offsetDate.getUTCHours(),
        offsetDate.getUTCMinutes(),
        0,
        0
      );

      payload.appconfigfields[0].value = inputValue ? format(tzIndependentDateObj, this.dateTimeFormatsService.dateTimeToUpper) : '';
    } else if (fieldType === 'Lookup') {
      payload.appconfigfields[0] = { ...payload.appconfigfields[0], ...payload.appconfigfields[0].value };
    }

    try {
      await this.updateProcedureContractOnlineOrOffline(payload);
      this.procedureContract.configuredFields[fieldName] = inputValue;
      this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
      this.initAllDataModels();
    } catch (err) {
      console.log('config field updation failed in procedure contract', err);
    }
  }

  private async initConfigFieldsLookupData() {
    let lookupFields = [];
    if (this.authenticationService.user.procedureContractConfiguredFields && this.authenticationService.user.procedureContractConfiguredFields.length > 0) {
      lookupFields = this.authenticationService.user.procedureContractConfiguredFields.filter(a => a.fieldType == 'Lookup');
    }
    if (lookupFields && lookupFields.length > 0) {
      await this.disk.retrieve(DB_KEY_PREFIXES.IO_CONFIG_ALL_LOOKUP_FIELDS, true).then((doc) => {
        if (doc?.raw) {
          this.lookupConfigFieldsData = doc.raw['indskr_procedurecontract'];
        }
      })
    }
    this.lookupFieldsDataInitialized = true;
  }

  public async openLookup(event, fieldName) {
    const configField = this.authenticationService.user.procedureContractConfiguredFields.find(a => a.fieldName == fieldName);
    if (configField) {
      let results = [];
      if (this.device.isOffline) {
        if (!this.lookupFieldsDataInitialized) {
          await this.initConfigFieldsLookupData();
        }
        if (this.lookupConfigFieldsData?.[configField.fieldName]) {
          results = this.lookupConfigFieldsData[configField.fieldName]
        }
      } else {
        results = await this.activityDataService.getConfigFieldLookupResults(configField, null);
      }
      let currentSelectedValue: string;
      let lookupPopoverModel: IndDropdownListDetailModel = {
        id: 'config-fields-lookup-select',
        isSearchEnabled: true,
        searchInputPlaceholder: this.translate.instant('SEARCH'),
        data: results.map(item => {
          let data: SelectListData = {
            title: item[configField.indskr_lookupentityprimarynameattribute],
            id: item[configField.indskr_lookupentityprimaryidattribute],
            isSelected: currentSelectedValue == item[configField.indskr_lookupentityprimaryidattribute],
          }
          return data;
        })
      };
      let lookupPopover = await this.popoverCtrl.create({
        component: IndDropdownListComponent,
        componentProps: { viewData: lookupPopoverModel },
        cssClass: 'dropdown-list-view',
        event: event
      });
      lookupPopover.onDidDismiss().then(async (data: any) => {
        data = data.data;
        if (data?.selectedItems?.length == 1 && data.selectedItems[0].id != currentSelectedValue) {
          let selectedPopOverValue = data.selectedItems[0].id;
          let selectedPopOverStringValue = data.selectedItems[0].title;
          const input = {
            id: selectedPopOverValue,
            name: fieldName,
            entity: configField.indskr_lookupentitysetname,
            stringValue: selectedPopOverStringValue,
            indskr_referencingentitynavigationpropertyname: configField.indskr_referencingentitynavigationpropertyname,
            fieldname: fieldName,
            datatype: configField.fieldType,
            value: selectedPopOverValue,
            indskr_lookupentitysetname: configField.indskr_lookupentitysetname,
          }
          this._configFieldMandatoryCheck(configField.fieldType, fieldName);
          this.updateConfigFieldsValues(configField.fieldType, configField.fieldName, input);
        }
        lookupPopover = undefined;
      });
      lookupPopover.present();
    }
  }

  private _configFieldMandatoryCheck(fieldType: string, fieldName: string) {
    const fieldId = getConfigFormFieldViewDataModelId(fieldType, fieldName);
    const configFormFieldData = this.findFieldFromGroup(fieldId);

    if (configFormFieldData?.isRequired) {
      this.requiredFields[configFormFieldData.id] = { isEvaluated: true };
      configFormFieldData.isEmptyRequiredField = !configFormFieldData.inputText;
    }
  }

  private findFieldFromGroup(fieldId) {
    if (this.groupedFields) {
      const allFields: any = Object.values(this.groupedFields).flat();
      return allFields.find(f => f.id === fieldId);
    }
  }

  private shouldConfiguredFieldReadonly(configuredField: ConfiguredFields) {
    if (this.isFullReadOnlyMode) return true;
    if (!configuredField.indskr_sectionname) return false;

    if ((configuredField.indskr_sectionname === CONTRACT_SECTION_NAMES.CONTRACT_DETAILS ||
      configuredField.indskr_sectionname === CONTRACT_SECTION_NAMES.PROCEDURE_DETAILS) &&
      this.partialReadOnlyMode
    ) {
      return true;
    }

    if ((configuredField.indskr_sectionname === CONTRACT_SECTION_NAMES.PRE_SURGERY_SIGNATURE &&
      this.procedureContract.indskr_presurgerysignaturecaptured || this.procedureContract.indskr_postsurgerysignaturecaptured) ||
      (configuredField.indskr_sectionname === CONTRACT_SECTION_NAMES.POST_SURGERY_SIGNATURE &&
        this.procedureContract.indskr_postsurgerysignaturecaptured)
    ) {
      return true;
    }

    return false;
  }

  // -------- Product ------- //

  private async initProcedureContractPositionProducts() {
    this.procedureContractPositionGroupProducts = [];
    this.products = await this.disk.retrieve(DB_KEY_PREFIXES.PROCEDURE_CONTRACT_POSITION_GROUP_PRODUCTS);
    if (this.products && this.products.raw && this.products.raw.length > 0) {
      this.products.raw.forEach(item => {
        if (item && item['product.productid']) {
          let idx = this.procedureContractPositionGroupProducts.findIndex(p => p.ID == item['product.productid']);
          if (idx < 0) {
            this.procedureContractPositionGroupProducts.push({
              ID: item['product.productid'],
              name: item['product.name'],
              productnumber: item['product.productnumber'],
            })
          }
        }
      })
    }
  }

  //----------- setting up view -------------//

  private setupContractView(sectionName: string, coreFieldArray: any) {
    try {
      const coreContractDetailsFields = coreFieldArray;
      let contractConfigFields = [];
      let customFieldWithOrder = [];
      let customFieldWithOutOrder = [];
      let coreFieldsIndex = 0;
      let maxLength = coreContractDetailsFields.length;
      if (this.contractType) {
        contractConfigFields = this.authenticationService.user.procedureContractConfiguredFields.filter((configuredField) => {

          // if (this.procedureContract.indskr_parentprocedurecontractid && configuredField.indskr_recordtype === PROCEDURE_CONTRACT_HEIRACRCHY.CHILD) {
          return this.contractType.configuredFields.includes(configuredField.appFieldId) && configuredField.indskr_sectionname === sectionName;
          // } else if (!this.procedureContract.indskr_parentprocedurecontractid && (configuredField.indskr_recordtype === PROCEDURE_CONTRACT_HEIRACRCHY.PARENT || !configuredField.indskr_recordtype)) {
          //   return this.contractType.configuredFields.includes(configuredField.appFieldId) && configuredField.indskr_sectionname === sectionName;
          // }
          // return false;
        });

        customFieldWithOrder = contractConfigFields.filter((item) => item.order);
        customFieldWithOutOrder = contractConfigFields.filter((item) => !item.order || item.order === 10000);
        maxLength = coreContractDetailsFields.length + contractConfigFields.length;
      }

      for (let i = 1; i <= maxLength; i++) {
        let itemsWithOrder = customFieldWithOrder.filter((item) => item.order === i);
        if (!this.groupedFields[sectionName]) this.groupedFields[sectionName] = [];

        if (itemsWithOrder.length > 0) {
          itemsWithOrder.forEach((itemWithOrder) => {
            this.groupedFields[sectionName].push(this.initConfigFields(itemWithOrder));
          });
        } else if (coreFieldsIndex < coreContractDetailsFields.length) {
          this.groupedFields[sectionName].push(coreContractDetailsFields[coreFieldsIndex++]);
        }
      }
      if (customFieldWithOutOrder.length) {
        this.groupedFields[sectionName].push(...customFieldWithOutOrder.map((item) => this.initConfigFields(item)));
      }
    } catch (error) {
      console.log('Error while creating field array in procedure contract', error);
    }
  }

  //------- k code --------- //
  private initKCodeFields() {
    if (this.contractType && this.contractType.indskr_displaykcodefields) {
      let showOtherKCodeField = false;
      let hideKTwoField = false;
      let hideKThreeField = false;
      if (this.contractService.KCodesData && this.contractService.KCodesData.length > 0) {
        if (this.procedureContract.k_code_one_id) {
          let foundKcode = this.contractService.KCodesData.find(k => k.jnjjapan_kcodeid == this.procedureContract.k_code_one_id);
          if (foundKcode && foundKcode.jnjjapan_valuetype == 731840000) {
            showOtherKCodeField = true;
            hideKTwoField = true;
            hideKThreeField = true;
          }
        }
        if (!showOtherKCodeField && this.procedureContract.k_code_two_id) {
          let foundKcode = this.contractService.KCodesData.find(k => k.jnjjapan_kcodeid == this.procedureContract.k_code_two_id);
          if (foundKcode && foundKcode.jnjjapan_valuetype == 731840000) {
            showOtherKCodeField = true;
            hideKThreeField = true;
          }
        }
        if (!showOtherKCodeField && this.procedureContract.k_code_three_id) {
          let foundKcode = this.contractService.KCodesData.find(k => k.jnjjapan_kcodeid == this.procedureContract.k_code_three_id);
          if (foundKcode && foundKcode.jnjjapan_valuetype == 731840000) {
            showOtherKCodeField = true;
          }
        }
      }

      let k_code_one_viewData: IndFormFieldViewDataModel = {
        label: this.translate.instant('K_CODE_1'),
        inputText: this.procedureContract.k_code_one_name,
        customPlaceholderLabel: (this.contractService.KCodesData && this.contractService.KCodesData.some(k => !k.jnjjapan_parentkcode)) ? this.translate.instant('SELECT_K_CODE_1') : this.translate.instant('NO_K_CODE_1'),
        id: 'procedure-field-k-code-1',
        isReadOnly: true,
        isRequired: true,
        isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode || !(this.contractService.KCodesData && this.contractService.KCodesData.some(k => !k.jnjjapan_parentkcode)) ? true : false,
        showArrow: this.isFullReadOnlyMode || this.partialReadOnlyMode || !(this.contractService.KCodesData && this.contractService.KCodesData.some(k => !k.jnjjapan_parentkcode)) ? false : true,
        formFieldType: FormFieldType.POPOVER_SELECT,
        isHidden: false,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };
      let k_code_two_viewData: IndFormFieldViewDataModel = {
        label: this.translate.instant('K_CODE_2'),
        inputText: this.procedureContract.k_code_two_name,
        customPlaceholderLabel: (this.contractService.KCodesData && this.procedureContract.k_code_one_id && this.contractService.KCodesData.some(k => k.jnjjapan_parentkcode == this.procedureContract.k_code_one_id)) ? this.translate.instant('SELECT_K_CODE_2') : this.translate.instant('NO_K_CODE_2'),
        id: 'procedure-field-k-code-2',
        isReadOnly: true,
        isRequired: true,
        isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode || !this.procedureContract.k_code_one_id || !((this.contractService.KCodesData && this.contractService.KCodesData.some(k => k.jnjjapan_parentkcode == this.procedureContract.k_code_one_id))) ? true : false,
        showArrow: this.isFullReadOnlyMode || this.partialReadOnlyMode || !this.procedureContract.k_code_one_id || !(this.contractService.KCodesData && this.contractService.KCodesData.some(k => k.jnjjapan_parentkcode == this.procedureContract.k_code_one_id)) ? false : true,
        formFieldType: FormFieldType.POPOVER_SELECT,
        isHidden: hideKTwoField,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };
      let k_code_three_viewData: IndFormFieldViewDataModel = {
        label: this.translate.instant('K_CODE_3'),
        inputText: this.procedureContract.k_code_three_name,
        customPlaceholderLabel: (this.contractService.KCodesData && this.procedureContract.k_code_two_id && this.contractService.KCodesData.some(k => k.jnjjapan_parentkcode == this.procedureContract.k_code_two_id)) ? this.translate.instant('SELECT_K_CODE_3') : this.translate.instant('NO_K_CODE_3'),
        id: 'procedure-field-k-code-3',
        isReadOnly: true,
        isRequired: true,
        isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode || !this.procedureContract.k_code_two_id || !(this.contractService.KCodesData && this.contractService.KCodesData.some(k => k.jnjjapan_parentkcode == this.procedureContract.k_code_two_id)) ? true : false,
        showArrow: this.isFullReadOnlyMode || this.partialReadOnlyMode || !this.procedureContract.k_code_two_id || !(this.contractService.KCodesData && this.contractService.KCodesData.some(k => k.jnjjapan_parentkcode == this.procedureContract.k_code_two_id)) ? false : true,
        formFieldType: FormFieldType.POPOVER_SELECT,
        isHidden: hideKThreeField,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };
      let k_code_other_viewData: IndFormFieldViewDataModel = {
        label: this.translate.instant('OTHER_KCODE_NAME'),
        inputText: this.procedureContract.k_code_other_name,
        inputValue: this.procedureContract.k_code_other_name,
        customPlaceholderLabel: this.translate.instant('ENTER_OTHER_KCODE_NAME'),
        id: 'procedure-field-other-k-code-name',
        isReadOnly: this.isFullReadOnlyMode || this.partialReadOnlyMode ? true : false,
        isRequired: true,
        isDisabled: this.isFullReadOnlyMode || this.partialReadOnlyMode ? true : false,
        showArrow: this.isFullReadOnlyMode || this.partialReadOnlyMode ? false : true,
        formFieldType: FormFieldType.INLINE_INPUT,
        isHidden: !showOtherKCodeField,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };
      this.procedureDetailsFields.push(k_code_one_viewData);
      if (!hideKTwoField) {
        this.procedureDetailsFields.push(k_code_two_viewData);
      }
      if (!hideKThreeField) {
        this.procedureDetailsFields.push(k_code_three_viewData);
      }
      if (showOtherKCodeField) {
        this.procedureDetailsFields.push(k_code_other_viewData);
      }
    }
  }

  private async handleKCode1Field(myEvent) {
    const KCodes = this.contractService.KCodesData.filter(k => !k.jnjjapan_parentkcode || k.jnjjapan_valuetype);
    if (KCodes && KCodes.length > 0) {
      const dropdownListDetail: IndDropdownListDetailModel = {
        id: 'k-codes-1',
        isMultipleSelectionEnabled: false,
        data: _.orderBy(KCodes, ['jnjjapan_order','jnjjapan_name']).map(k => {
          return {
            title: k.jnjjapan_name,
            id: k.jnjjapan_kcodeid,
            isSelected: k.jnjjapan_kcodeid == this.procedureContract.k_code_one_id,
          }
        }),
      };

      const KCode1Popover = await this.popoverCtrl.create({
        component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'drop-down-list-view', event: myEvent
      });

      await KCode1Popover.present();

      await KCode1Popover.onDidDismiss().then(async ({ data }) => {
        let payload: any = {};
        if (!data || !data.selectedItems || !_.isArray(data.selectedItems) || data.selectedItems.length == 0 || this.procedureContract.k_code_one_id === data.selectedItems[0].id) return;
        this.procedureContract.k_code_one_name = data.selectedItems[0].title;
        this.procedureContract.k_code_one_id = data.selectedItems[0].id;
        payload = {
          indskr_procedurecontractid: this.procedureContract.indskr_procedurecontractid,
          appconfigfields: [this.contractService.getServicePayloadForKCodeField('jnjjapan_kcode_1', this.procedureContract)],
          //jnjjapan_kcode_1: this.procedureContract.k_code_one_id,
        };
        let foundKcode = this.contractService.KCodesData.find(k => k.jnjjapan_kcodeid == this.procedureContract.k_code_one_id);
        if (foundKcode && foundKcode.jnjjapan_valuetype == 731840000) {
          this.procedureContract.k_code_two_name = data.selectedItems[0].title;
          this.procedureContract.k_code_two_id = data.selectedItems[0].id;
          this.procedureContract.k_code_three_name = data.selectedItems[0].title;
          this.procedureContract.k_code_three_id = data.selectedItems[0].id;
          //payload['jnjjapan_kcode_2'] = this.procedureContract.k_code_two_id;
          payload.appconfigfields.push(this.contractService.getServicePayloadForKCodeField('jnjjapan_kcode_2', this.procedureContract));
          //payload['jnjjapan_kcode_3'] = this.procedureContract.k_code_three_id;
          payload.appconfigfields.push(this.contractService.getServicePayloadForKCodeField('jnjjapan_kcode_3', this.procedureContract));
        } else {
          this.procedureContract.k_code_two_name = '';
          this.procedureContract.k_code_two_id = '';
          this.procedureContract.k_code_three_name = '';
          this.procedureContract.k_code_three_id = '';
          this.procedureContract.k_code_other_name = '';

          payload.appconfigfields.push(this.contractService.getServicePayloadForKCodeField('jnjjapan_kcode_2', this.procedureContract));
          payload.appconfigfields.push(this.contractService.getServicePayloadForKCodeField('jnjjapan_kcode_3', this.procedureContract));
          payload.appconfigfields.push(this.contractService.getServicePayloadForKCodeField('jnjjapan_otherkcodename', this.procedureContract));
        }

        await this.updateProcedureContractOnlineOrOffline(payload);
        this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
        this.initAllDataModels();
      });
    }
  }

  private async handleKCode2Field(myEvent) {
    const KCodes = this.contractService.KCodesData.filter(k => k.jnjjapan_parentkcode == this.procedureContract.k_code_one_id || k.jnjjapan_valuetype);
    if (KCodes && KCodes.length > 0) {
      const dropdownListDetail: IndDropdownListDetailModel = {
        id: 'k-codes-2',
        isMultipleSelectionEnabled: false,
        data: _.orderBy(KCodes, ['jnjjapan_order','jnjjapan_name']).map(k => {
          return {
            title: k.jnjjapan_name,
            id: k.jnjjapan_kcodeid,
            isSelected: k.jnjjapan_kcodeid == this.procedureContract.k_code_two_id,
          }
        }),
      };

      const KCode1Popover = await this.popoverCtrl.create({
        component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'drop-down-list-view', event: myEvent
      });

      await KCode1Popover.present();

      await KCode1Popover.onDidDismiss().then(async ({ data }) => {
        let payload: any = {};
        if (!data || !data.selectedItems || !_.isArray(data.selectedItems) || data.selectedItems.length == 0 || this.procedureContract.k_code_one_id === data.selectedItems[0].id) return;
        this.procedureContract.k_code_two_name = data.selectedItems[0].title;
        this.procedureContract.k_code_two_id = data.selectedItems[0].id;
        payload = {
          indskr_procedurecontractid: this.procedureContract.indskr_procedurecontractid,
          appconfigfields: [this.contractService.getServicePayloadForKCodeField('jnjjapan_kcode_2', this.procedureContract)],
          //jnjjapan_kcode_2: this.procedureContract.k_code_two_id,
        };
        let foundKcode = this.contractService.KCodesData.find(k => k.jnjjapan_kcodeid == this.procedureContract.k_code_two_id);
        if (foundKcode && foundKcode.jnjjapan_valuetype == 731840000) {
          this.procedureContract.k_code_three_name = data.selectedItems[0].title;
          this.procedureContract.k_code_three_id = data.selectedItems[0].id;
          //payload['jnjjapan_kcode_3'] = this.procedureContract.k_code_three_id;
          payload.appconfigfields.push(this.contractService.getServicePayloadForKCodeField('jnjjapan_kcode_3', this.procedureContract));
        } else {
          this.procedureContract.k_code_three_name = '';
          this.procedureContract.k_code_three_id = '';
          //payload['jnjjapan_kcode_3'] = '';
          payload.appconfigfields.push(this.contractService.getServicePayloadForKCodeField('jnjjapan_kcode_3', this.procedureContract));
          this.procedureContract.k_code_other_name = '';
          //payload['jnjjapan_otherkcodename'] = '';
          payload.appconfigfields.push(this.contractService.getServicePayloadForKCodeField('jnjjapan_otherkcodename', this.procedureContract));
        }

        await this.updateProcedureContractOnlineOrOffline(payload);
        this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
        this.initAllDataModels();
      });
    }
  }

  private async handleKCode3Field(myEvent) {
    const KCodes = this.contractService.KCodesData.filter(k => k.jnjjapan_parentkcode == this.procedureContract.k_code_two_id || k.jnjjapan_valuetype);

    if (KCodes && KCodes.length > 0) {
      const dropdownListDetail: IndDropdownListDetailModel = {
        id: 'k-codes-3',
        isMultipleSelectionEnabled: false,
        data: _.orderBy(KCodes, ['jnjjapan_order','jnjjapan_name']).map(k => {
          return {
            title: k.jnjjapan_name,
            id: k.jnjjapan_kcodeid,
            isSelected: k.jnjjapan_kcodeid == this.procedureContract.k_code_three_id,
          }
        }),
      };

      const KCode1Popover = await this.popoverCtrl.create({
        component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'drop-down-list-view', event: myEvent
      });

      await KCode1Popover.present();

      await KCode1Popover.onDidDismiss().then(async ({ data }) => {
        let payload: any = {};
        if (!data || !data.selectedItems || !_.isArray(data.selectedItems) || data.selectedItems.length == 0 || this.procedureContract.k_code_one_id === data.selectedItems[0].id) return;
        this.procedureContract.k_code_three_name = data.selectedItems[0].title;
        this.procedureContract.k_code_three_id = data.selectedItems[0].id;
        payload = {
          indskr_procedurecontractid: this.procedureContract.indskr_procedurecontractid,
          appconfigfields: [this.contractService.getServicePayloadForKCodeField('jnjjapan_kcode_3', this.procedureContract)]
        };

        let foundKcode = this.contractService.KCodesData.find(k => k.jnjjapan_kcodeid == this.procedureContract.k_code_three_id);
        if (foundKcode && foundKcode.jnjjapan_valuetype == 731840000) {
        } else {
          this.procedureContract.k_code_other_name = '';
          payload.appconfigfields.push(this.contractService.getServicePayloadForKCodeField('jnjjapan_otherkcodename', this.procedureContract));
        }

        await this.updateProcedureContractOnlineOrOffline(payload);
        this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
        this.initAllDataModels();
      });
    }
  }

  private async handleOtherKCodeField(event) {
    if (event && event.target.value !== undefined && this.procedureContract.k_code_other_name != event.target.value) {
      this.procedureContract.k_code_other_name = event.target.value;
      let payload = {
        appconfigfields: [this.contractService.getServicePayloadForKCodeField('jnjjapan_otherkcodename', this.procedureContract)]
      }

      await this.updateProcedureContractOnlineOrOffline(payload);
      this.contractService.updateProcedureContract(this.procedureContract.indskr_procedurecontractid, this.procedureContract);
      this.initAllDataModels();
    }
  }

  // procedure log creation from contract

  async createNewSurgeryOrder() {
    try {
      const currentTime = new Date().getTime();
      const start = this.getStartTime(currentTime);
      const end = this.getEndTime(start, 30);
     
      const procedureTypes = this.surgeryOrderDataService.findProcedureTypesByContractTypeId(this.procedureContract.indskr_contracttypes);
      let payload = this.createSurgeryOrderPayload(currentTime, start, end, procedureTypes);
  
      const affiliatedContacts = await this.contactService.getAffiliatedContactsByAccountId(this.procedureContract.indskr_account_value);
      if (affiliatedContacts.length === 1) {
        payload.contactorders = this.mapAffiliatedContacts(affiliatedContacts);
      }
  
      const newSurgeryOrder = new SurgeryOrderActivity(payload);
      newSurgeryOrder.statusString = 'Draft';
      newSurgeryOrder.ownerId = this.authenticationService.user.systemUserID;
      newSurgeryOrder.ownerNameString = this.authenticationService.user.displayName;

      let allCriteriaMet = this.contractService.isContractMeetCriteria(this.procedureContract, newSurgeryOrder, this.contractType, this.isProcedureSubtypeAvailable);
      
      if (!allCriteriaMet) {
        newSurgeryOrder.indskr_procedurecontract = "";
        newSurgeryOrder.indskr_procedurecontractname = "";
      }
  
      await this.uiService.displayLoader();
      await this.surgeryOrderDataService.createOrderActivity({
        onDynamics: !this.device.isOffline,
        onLocalCopy: true,
        onLocalDatabase: true
      }, [newSurgeryOrder], currentTime, false);
      this.activityService.selectedActivity = newSurgeryOrder;
  
      await this.uiService.dismissLoader();
      this.navigateToSurgeryOrderDetails(newSurgeryOrder);
      this.refreshAgendaIfNeeded();
  
    } catch (error) {
      await this.uiService.dismissLoader();
    }
  }

  private getStartTime(currentTime: number): number {
    const isOutsideContract = this.contractService.isSurgeryOutsideContractPeriod(
      new Date(this.procedureContract.indskr_startdate).getTime(),
      new Date(this.procedureContract.indskr_enddate).getTime(),
      currentTime
    );
    return isOutsideContract ? new Date(this.procedureContract.indskr_startdate).getTime() : currentTime;
  }

  private getEndTime(start: number, durationMinutes: number): number {
    return addMinutes(start, durationMinutes).getTime();
  }

  private createSurgeryOrderPayload(currentTime: number, start: number, end: number, procedureTypes: any[]): any {
    const autoSetOwnerAsCoowner = this.authenticationService.user.buSettings?.['indskr_setownerascoowner'];
    const hideProcedureAndPorductCategory = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_hideprocedureproductcategory'];
    
    let payload: any = {
      salesorderid: `offline_surgeryorder_${currentTime}`,
      indskr_externalid: `offline_surgeryorder_${currentTime}`,
      indskr_ordertype: 548910001,
      ordernumber: 'Surgery Order',
      name: 'Procedure Log',
      indskr_noprocedureday: false,
      indskr_ctmergednew: false,
      pendingPushToDynamics: true,
      statuscode: 1,
      statecode: 0,
      activitytypecode: 'SurgeryOrder',
      createdon: currentTime,
      modifiedByName: this.authenticationService.user.displayName,
      modifiedon: currentTime,
      scheduledstart: start,
      scheduledend: end,
      customerid: this.accountService.selected.id,
      customerName: this.accountService.selected.accountName,
      indskr_procedurecontract: this.procedureContract.indskr_procedurecontractid,
      indskr_procedurecontractname: this.procedureContract.indskr_name,
      contractStatus: this.procedureContract.statuscode
    };

    if (autoSetOwnerAsCoowner) {
      payload.coOwners = [{
        userId: this.authenticationService.user.xSystemUserID,
        userFullName: this.authenticationService.user.displayName,
        indskr_procedurelogcoownerid: Guid.create().toString()
      }];
    }

    if (procedureTypes.length === 1) {
      payload.indskr_proceduretype = procedureTypes[0].indskr_proceduretypeid;
      payload.indskr_proceduretypename = this.procedureContract.procedureTypeString;
    }

    if (this.procedureContract.indskr_proceduresubtype != null) {
      payload.indskr_proceduresubtype = this.procedureContract.indskr_proceduresubtype;
      payload.indskr_proceduresubtypename = this.procedureContract.proceudreSubTypeString;
    }

    // if (hideProcedureAndPorductCategory && this.procedureContract.indskr_product_value) {
    //   let product;
    //   if (this.products && this.products.raw && this.products.raw.length > 0) {
    //     product = this.products.raw.find(item => item['product.productid'] === this.procedureContract.indskr_product_value);
    //   }

    //   if (product) {
    //     payload.products = [{
    //       productid: this.procedureContract.indskr_product_value,
    //       quantity: 1,
    //       uomid: product['product.defaultuomid'],
    //       indskr_productCategoryId: ""
    //     }]
    //   }
    // } else 
    if (!hideProcedureAndPorductCategory && this.procedureContract.indskr_procedure_value) {
      payload = {
        ...payload,
        indskr_surgeryproduct: this.procedureContract.indskr_procedure_value,
        indskr_surgeryproductName: this.procedureContract.procedureName
      }
    }

    return payload;
  }

  private mapAffiliatedContacts(affiliatedContacts: any[]): any[] {
    return affiliatedContacts.map(contact => ({
      id: contact.ID,
      title: contact.title,
      firstName: contact.firstName,
      middleName: contact.middleName,
      lastName: contact.lastName,
      statusCode: contact.statusCode
    }));
  }

  private navigateToSurgeryOrderDetails(newSurgeryOrder: SurgeryOrderActivity): void {
    const from = 'ProcedureLogFromProcedureContract';
    const pageName = PageName.SurgeryOrderContractDetailsComponent;
  
    this.uiService.activeView = 'AccountPageRightPaneNav';
    this.uiService.showNewActivity = false;
  
    this.navService.pushChildNavPageWithPageTracking(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent, pageName, {
      from,
      activity: newSurgeryOrder
    });
  }

  private refreshAgendaIfNeeded(): void {
    if (!this.uiService.toolsActivityActive) {
      this.events.publish("refreshAgenda");
    } else {
      this.uiService.agendaRefreshRequired = true;
    }
  }

  ngOnDestroy() {
    this.ngUnSubscribe$.next(true);
    this.ngUnSubscribe$.complete();
    this.footerService.disableButton(['downloadPDF']);
  }

}

export type ProcedureLogData = {
  headerText: string;
  values: DisplayValue[];
}
