import { ProcedureTrackerDetailsComponent } from './../../components/surgery-order/procedure-tracker-details/procedure-tracker-details';
import { ProcedureTrackerActivity } from './../../classes/activity/procedure-tracker.activity.class';
import { Injectable } from "@angular/core";
import { EmbeddedInteraction, Activity, EmbeddedInteractionType, ActivityType } from "./../../classes/activity/activity.class";
import { BehaviorSubject } from "rxjs";
import { ActivityService, ActivitySource } from "../activity/activity.service";
import { AuthenticationService } from "../authentication.service";
import { FeatureActionsMap } from "./../../classes/authentication/user.class";
import { SampleActivity } from "./../../classes/activity/sample.activity.class";
import { EmailActivity } from "./../../classes/activity/email.activity.class";
import { CaseActivity } from "./../../classes/case-intake/case-activity.class";
import { AppointmentActivity, CovisitorAccess } from "./../../classes/activity/appointment.activity.class";
import { Presentation } from "./../../classes/presentation/presentation.class";
import { FooterService, FooterViews } from "../footer/footer.service";
import { ModalController, LoadingController } from "@ionic/angular";
import { Events } from '@omni/events';
import _ from "lodash";
import { UIService } from "../ui/ui.service";
import { PageName, NavigationService } from "../navigation/navigation.service";
import { InMeetingAllocationComponent } from "../../components/in-meeting-allocation/in-meeting-allocation";
import { AccesingMode, CaseManagementService } from "../case-management/case-management.service";
import { InMeetingCasesComponent } from "../../components/case-management/in-meeting-cases/in-meeting-cases";
import { TranslateService } from "@ngx-translate/core";
import { NewSampleActivityComponent } from "../../components/activity/new-sample-activity/new-sample-activity";
import { EmailActivityDetailComponent } from "../../components/activity/email-activity-detail/email-activity-detail";
import { DiskService } from "../disk/disk.service";
import { DB_KEY_PREFIXES } from "../../config/pouch-db.config";
import { CaseManagementDetailComponent } from './../../components/case-management/case-management-detail/case-management-detail';
import { ActivityDataService } from "../../data-services/activity/activity.service";
import { ChannelType } from "../../classes/consent/channel.class";
import { PresentationService } from "../presentation/presentation.service";
import { EmailTemplateType } from "../../classes/email-templates/email-template.class";
import { Resource } from "../../classes/resource/resource.class";
import { ResourceService } from "../resource/resource.service";
import { ContentMatchingService, CONTENTMATCHTYPE } from "../content-matching/content-matching.service";
import { MeetingDataService } from "../../data-services/meeting/meeting.data.service";
import { NotificationService } from "../notification/notification.service";
import { DeviceService} from "../device/device.service";
import { EventActivity, EventPresentations } from './../../classes/events-tool/event.class';
import { PresentationView } from './../ui/ui.service';
import { EventsService } from '../events/events.service';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { SurgeryOrderDetailsComponent } from '@omni/components/surgery-order/surgery-order-details/surgery-order-details';
import { FollowUpActivity, FOLLOW_UP_TYPE } from '@omni/classes/activity/follow-up-action.activity.class';
import { FollowUpActionDetailComponent } from '@omni/components/activity/follow-up-action-detail/follow-up-action-detail';
import { CustomerAssessment, InternalSurvey } from '@omni/classes/customer-assessment/customer-assessment.class';
import { CustomerAssessService } from '../customer-assess/customer-assess.service';
import { SurveyCategory, SurveyStatus } from '@omni/classes/customer-assessment/assessment-template.class';
import { ContactOfflineService } from '../contact/contact.service';
import { AccountOfflineService } from '../account/account.offline.service';
import { SecInfoConfigDataService } from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import { SecondaryInfoEntityName } from '@omni/classes/sec-info-config/sec-info.class';


@Injectable({
  providedIn: 'root'
})
export class MeetingStructureService {

    private currentMeetingStructureSource = new BehaviorSubject<EmbeddedInteraction[]>(undefined);
    public currentMeetingStructureObserver = this.currentMeetingStructureSource.asObservable();
    // public selectedMeetingActivity: EmbeddedInteraction;
    private selectedMeetingActivitySource = new BehaviorSubject<EmbeddedInteraction>(null);
    public selectedMeetingActivityObserver = this.selectedMeetingActivitySource.asObservable();
    public accessedFrom: AccesingMode = AccesingMode.MEETING_DETAIL;
    private modalOpened: boolean = false;

    constructor(private activityService: ActivityService,
        private authenticationService: AuthenticationService,
        private modalCtrl: ModalController,
        private footerService: FooterService,
        private uiService: UIService,
        private navService: NavigationService,
        private translate: TranslateService,
        private caseManagementService: CaseManagementService,
        private disk: DiskService,
        private loadingController: LoadingController,
        private activityDataService: ActivityDataService,
        private presentationService: PresentationService,
        private resourceService: ResourceService,
        private authenticationOfflineService: AuthenticationService,
        private contentMatchService: ContentMatchingService,
        private meetingDataService: MeetingDataService,
        private device: DeviceService,
        private notificationService: NotificationService,
        public events: EventsService,
        public customerAssessService: CustomerAssessService,
        private contactService: ContactOfflineService,
        private accountService: AccountOfflineService,
        private secondaryInfoService: SecInfoConfigDataService
        ) {}

    private reInitializeSequence(embeddedInteractions: EmbeddedInteraction[]) {
        if (embeddedInteractions && embeddedInteractions.length > 0) {
            let sequence = embeddedInteractions.length - 1;
            embeddedInteractions = _.orderBy(embeddedInteractions, "sequence", "desc");
            embeddedInteractions.forEach(eI => {
              if(eI) {
                eI.sequence = sequence;
                sequence--;
              }
            });
        }
        return embeddedInteractions;
    }

    public removeActivity(activity: Activity, parentActivityType: ActivityType = ActivityType.Appointment, params?: any) {
        if (
          (parentActivityType == ActivityType.Appointment && this.activityService.selectedActivity instanceof AppointmentActivity)
          || parentActivityType === ActivityType.Event
        ) {
            const currentEmbeddedInteractions = this.currentMeetingStructureSource.getValue();
            if (currentEmbeddedInteractions && Array.isArray(currentEmbeddedInteractions)) {
                let filteredEmbeddedInteractions = currentEmbeddedInteractions.filter(embeddedInteraction => embeddedInteraction.id != this.getActivityId(activity))
                filteredEmbeddedInteractions = _.orderBy(filteredEmbeddedInteractions, "sequence", "desc");
                if (currentEmbeddedInteractions.length == 1) {
                    this.setCurrentMeetingActivity(null);
                } else {
                    const deletedActivityIndex = currentEmbeddedInteractions.findIndex(ei => ei.id === this.getActivityId(activity) && ei.type === EmbeddedInteractionType.Activity);
                    //check deleted activity is at last
                    if (deletedActivityIndex >= 0 && currentEmbeddedInteractions.length == deletedActivityIndex + 1) {
                        this.setCurrentMeetingActivity(currentEmbeddedInteractions[deletedActivityIndex - 1]);
                    } else {
                        this.setCurrentMeetingActivity(filteredEmbeddedInteractions[deletedActivityIndex]);
                    }
                }
                this.updateEmbeddedInteraction(this.reInitializeSequence(filteredEmbeddedInteractions));
            }
        }
    }

    public removeSurvey(survey: CustomerAssessment) {
      if (this.activityService.selectedActivity instanceof AppointmentActivity) {
        const currentEmbeddedInteractions = this.currentMeetingStructureSource.getValue();
        if (currentEmbeddedInteractions && Array.isArray(currentEmbeddedInteractions)) {
            let filteredEmbeddedInteractions = currentEmbeddedInteractions.filter(embeddedInteraction => embeddedInteraction.id != survey.indskr_customerassessmentid)
            filteredEmbeddedInteractions = _.orderBy(filteredEmbeddedInteractions, "sequence", "desc");
            if (currentEmbeddedInteractions.length == 1) {
                this.setCurrentMeetingActivity(null);
            } else {
                const deletedActivityIndex = currentEmbeddedInteractions.findIndex(ei => ei.id === survey.indskr_customerassessmentid && ei.type === EmbeddedInteractionType.Survey);
                //check deleted activity is at last
                if (deletedActivityIndex >= 0 && currentEmbeddedInteractions.length == deletedActivityIndex + 1) {
                    this.setCurrentMeetingActivity(currentEmbeddedInteractions[deletedActivityIndex - 1]);
                } else {
                    this.setCurrentMeetingActivity(filteredEmbeddedInteractions[deletedActivityIndex]);
                }
            }
            this.updateEmbeddedInteraction(this.reInitializeSequence(filteredEmbeddedInteractions));
        }
      }
    }

    private updateEmbeddedInteraction(embeddedInteractions: EmbeddedInteraction[]) {
        this.setCurrentMeetingStructure(embeddedInteractions);
        if((this.navService.getCurrentMasterPageName()==PageName.EventsToolPageComponent || this.presentationService.viewMode==PresentationView.ADDTOEVENT)) this.saveEventToDisk(embeddedInteractions);
        else this.saveToDisk(embeddedInteractions);
    }

    public updateSequenceOnEmbeddedInteractionDrag(embeddedInteractions: EmbeddedInteraction[]) {
        if (embeddedInteractions && embeddedInteractions.length > 0) {
            let sequence = embeddedInteractions.length - 1;
            embeddedInteractions.forEach(eI => {
                eI.sequence = sequence;
                sequence--;
            });
        }
        this.updateEmbeddedInteraction(embeddedInteractions);
    }

    public updateContents(contents: (Presentation | Resource)[], selectDefault?: boolean) {
        let currentEmbeddedInteraction: EmbeddedInteraction[] = this.currentMeetingStructureSource.getValue() ? this.currentMeetingStructureSource.getValue() : [];
        const selectedActivity: EmbeddedInteraction = this.selectedMeetingActivitySource.getValue();
        if (contents.length === 0) {
            //Clear all contents from embedded interaction
            currentEmbeddedInteraction = this.clearContents(currentEmbeddedInteraction, selectedActivity);
            this.presentationService.clearCurrentPresentation();
        } else {
            //To add contents
            currentEmbeddedInteraction = this.addContents(contents, currentEmbeddedInteraction);
            //To remove contents
            currentEmbeddedInteraction = this.removeContents(currentEmbeddedInteraction, contents, selectedActivity);
        }
        this.updateEmbeddedInteraction(currentEmbeddedInteraction);
        if (!selectedActivity && selectDefault) {
            if (currentEmbeddedInteraction.length == 0) {
                this.setCurrentMeetingActivity(null);
            } else {
                this.setCurrentMeetingActivity(currentEmbeddedInteraction[0]);
                this.presentationService.setCarouselBriefcase(this.contents);
            }
        }
    }

    private removeContents(currentEmbeddedInteraction: EmbeddedInteraction[], contents: (Presentation | Resource)[], selectedActivity: EmbeddedInteraction) {
        let contentsToRemove: EmbeddedInteraction[] = [];
        // let selectedActivityRemoved: boolean = false;
        currentEmbeddedInteraction.filter(embeddedInteraction => embeddedInteraction.type === EmbeddedInteractionType.Content)
            .forEach(embeddedInteraction => {
                const exist: Presentation | Resource = contents.find(content => (embeddedInteraction.id === content['ioPresentationId'] ||
                    embeddedInteraction.id === content['ioResourceId'] || embeddedInteraction.id === content['ioDocumentId']));
                if (!exist) {
                    contentsToRemove = contentsToRemove.concat(embeddedInteraction);
                    // if (selectedActivity && selectedActivity.type === EmbeddedInteractionType.Content && selectedActivity.interaction === exist) {
                    //     selectedActivityRemoved = true;
                    // }
                }
            });
        if (contentsToRemove.length > 0) {
            currentEmbeddedInteraction = currentEmbeddedInteraction.filter(embeddedInteraction => contentsToRemove.indexOf(embeddedInteraction) === -1);
            currentEmbeddedInteraction = this.reInitializeSequence(currentEmbeddedInteraction);
            // if (selectedActivityRemoved)
            //     this.setCurrentMeetingActivity(currentEmbeddedInteraction[0]);
        }
        return currentEmbeddedInteraction;
    }

    private addContents(contents: (Presentation | Resource)[], currentEmbeddedInteraction: EmbeddedInteraction[]) {
      this.events.publish('contentIsAdded', contents);
        contents.forEach(content => {
            let index = currentEmbeddedInteraction.findIndex(embeddedInteraction => (embeddedInteraction.type === EmbeddedInteractionType.Content &&
                (embeddedInteraction.id === content['ioPresentationId'] || embeddedInteraction.id === content['ioResourceId'] ||
                    embeddedInteraction.id === content['ioDocumentId'])));
            if (index === -1) {
                let seq = this.getMeetingActivitySequence(currentEmbeddedInteraction);
                let embeddedInteraction: EmbeddedInteraction = this.getMeetingActivity(content, seq);
                currentEmbeddedInteraction.push(embeddedInteraction);
            }
        });
        currentEmbeddedInteraction = this.reInitializeSequence(currentEmbeddedInteraction);
        // select content if not selected
        const selectedActivity: EmbeddedInteraction = this.selectedMeetingActivitySource.getValue();

        if (!selectedActivity || selectedActivity.type !== EmbeddedInteractionType.Content) {
            if (this.presentationService.activePresentation) {
                const id = this.presentationService.activePresentation['ioPresentationId'] ? this.presentationService.activePresentation['ioPresentationId'] : (this.presentationService.activePresentation['ioDocumentId']
                    ? this.presentationService.activePresentation['ioDocumentId'] : this.presentationService.activePresentation['ioResourceId']);
                const ePresentation: EmbeddedInteraction = currentEmbeddedInteraction.find(ei => ei.id === id);
                this.setCurrentMeetingActivity(ePresentation);
            }
        }
        return currentEmbeddedInteraction;
    }

    private clearContents(currentEmbeddedInteraction: EmbeddedInteraction[], selectedActivity: EmbeddedInteraction) {
        currentEmbeddedInteraction = currentEmbeddedInteraction.filter(embeddedInteraction => embeddedInteraction.type !== EmbeddedInteractionType.Content);
        if (selectedActivity && selectedActivity.type === EmbeddedInteractionType.Content) {
            this.setCurrentMeetingActivity(currentEmbeddedInteraction.length > 0 ? currentEmbeddedInteraction[0] : null);
        }
        return currentEmbeddedInteraction;
    }

    public async createEmbeddedInteractions(activity: AppointmentActivity) {
        if (!activity) {
            this.setCurrentMeetingStructure([]);
            return;
        }
        let inMeetingActivities = this.getInMeetingActivities(activity);
        let contents = activity.presentations ? activity.presentations : [];
        let currentEmbeddedInteractions: EmbeddedInteraction[] = [];
        let surveys: any[] = this.customerAssessService.allContactSurveysByAssessment.filter(asmt => 
          asmt['_indskr_appointment_value'] && asmt['_indskr_appointment_value'] == activity.ID || asmt['indskr_appointmentid'] && asmt['indskr_appointmentid'] == activity.ID);

        let sequence = 0;
        if (inMeetingActivities.length === 0 && contents.length === 0 && _.isEmpty(surveys)) {
            this.updateEmbeddedInteraction(currentEmbeddedInteractions)
        } else {
            if (inMeetingActivities && inMeetingActivities.length > 0) {
                inMeetingActivities.forEach(inMeetingActivity => {
                    const embeddedInteraction: EmbeddedInteraction = new EmbeddedInteraction(this.getActivityId(inMeetingActivity), this.activityTitle(inMeetingActivity), this.activityService.getActivityIcon(inMeetingActivity), EmbeddedInteractionType.Activity, sequence++, inMeetingActivity, this.getActivityIconName(inMeetingActivity));
                    currentEmbeddedInteractions.push(embeddedInteraction);
                });
            }

            if (contents && contents.length > 0) {
                contents.forEach(content => {
                    let embeddedInteraction: EmbeddedInteraction;
                    if (content instanceof Presentation) {
                        embeddedInteraction = new EmbeddedInteraction(content.ioPresentationId, content.name, content.thumbnailUrl, EmbeddedInteractionType.Content, sequence++, content, '');
                    } else if (content instanceof Resource) {
                        const id = content.ioDocumentId ? content.ioDocumentId : content.ioResourceId;
                        embeddedInteraction = new EmbeddedInteraction(id, content.title, content.thumbnailURL, EmbeddedInteractionType.Content, sequence++, content, '');
                    }
                    currentEmbeddedInteractions.push(embeddedInteraction);
                });
            }

            if (!_.isEmpty(surveys)) {
              surveys.forEach(survey => {
                let embeddedInteraction: EmbeddedInteraction;
                embeddedInteraction = new EmbeddedInteraction(survey.indskr_customerassessmentid, this.getSurveyTitle(survey), "assets/imgs/survey-icon.svg", EmbeddedInteractionType.Survey, sequence++, survey, 'survey-icon');
                currentEmbeddedInteractions.push(embeddedInteraction);
              })
            }

            const activityId = activity.offlineMeetingId && activity.offlineMeetingId.length > 0 ? activity.offlineMeetingId : activity.ID;
            await this.saveMeetingActivitiesInDB(activityId, currentEmbeddedInteractions);
        }
    }

    public async createEmbeddedInteractionsEvents(activity: EventActivity) {
      if (!activity) {
          this.setCurrentMeetingStructure([]);
          return;
      }

      let contents= activity.presentations ? activity.presentations : [];
      let currentEmbeddedInteractions: EmbeddedInteraction[] = [];
      let sequence = 0;
      if (contents.length === 0) {
          this.updateEmbeddedInteraction(currentEmbeddedInteractions)
      } else {
          contents.forEach(content => {
              let embeddedInteraction: EmbeddedInteraction;
              embeddedInteraction = new EmbeddedInteraction(content.id, content.name, content.thumbnailUrl, EmbeddedInteractionType.Content, sequence++, content, '')
              currentEmbeddedInteractions.push(embeddedInteraction);
          });
      }
      const eventId = activity.offlineId ? activity.offlineId : activity.ID;
      await this.saveMeetingActivitiesInDB(eventId, currentEmbeddedInteractions);
      
      let inMeetingActivities = this.getInEventActivities(activity);
      if (inMeetingActivities && inMeetingActivities.length > 0) {
        inMeetingActivities.forEach(inMeetingActivity => {
            const embeddedInteraction: EmbeddedInteraction = new EmbeddedInteraction(this.getActivityId(inMeetingActivity), this.activityTitle(inMeetingActivity), this.activityService.getActivityIcon(inMeetingActivity), EmbeddedInteractionType.Activity, sequence++, inMeetingActivity, this.getActivityIconName(inMeetingActivity));
            currentEmbeddedInteractions.push(embeddedInteraction);
        });
      }
      this.setCurrentMeetingStructure(currentEmbeddedInteractions);
    }

    public async createEmbeddedInteractionsForEventCheckIn(event: EventActivity){
        if (!event) {
            this.setCurrentMeetingStructure([]);
            return;
        }
        let contents= event.presentations ? event.presentations : [];
        let currentEmbeddedInteractions: EmbeddedInteraction[] = [];
        let sequence = 0;
        if (contents.length === 0) {
            this.updateEmbeddedInteraction(currentEmbeddedInteractions)
        } else {
            contents.forEach(content => {
                let embeddedInteraction: EmbeddedInteraction;
                embeddedInteraction = new EmbeddedInteraction(content.id, content.name, content.thumbnailUrl, EmbeddedInteractionType.Content, sequence++, content, '')
                currentEmbeddedInteractions.push(embeddedInteraction);
            });
        }
        const eventId = event.offlineId ? event.offlineId : event.ID;
        await this.saveMeetingActivitiesInDB(eventId, currentEmbeddedInteractions);
    }

    private getActivityIconName(activity: Activity) {
        let iconName: string = '';
        switch (activity.type) {
            case ActivityType.Email:
                iconName = "email";
                if (activity.status === 1) {
                    iconName = "email";
                }
                else if (activity.status === 3 || activity.status === 548910000) {
                    iconName = "email_blue";
                }
                else if (activity.status === 6 || activity.status === 9) {
                    iconName = "email_orange"
                }
                else if (activity.status === 8) {
                    iconName = "email_red"
                    if (activity instanceof EmailActivity && activity.channelType !== ChannelType.EMAIL && activity.status == 8 && activity.emailActivityParties.length > 1) {
                        iconName = "email";
                    }
                }
                else if (activity.status === 0) {
                    iconName = "email_green"
                }
                break;
            case ActivityType.Sample:
                iconName =
                    activity.state != 1 ? "sample_activity" : "sample_activity_completed";
                break;
            case ActivityType.CaseIntake:
                iconName = (<CaseActivity>activity)._case_status_value === 'Open' ? "case-intake" : "case-intake-completed";
                break;
            case ActivityType.SurgeryOrder:
                if(activity.status == 0 || activity.status == 54891 || activity.status == 4 || activity.status == 1) {
                  iconName = "order_open";
                }else if(activity.status == 100001 || activity.status == 548910001) {
                  iconName = "orders_complete";
                }
                break;
            case ActivityType.ProcedureTracker:
                if(activity.status == 0 || activity.status == 54891 || activity.status == 4 || activity.status == 1) {
                  iconName = "order_open";
                }else if(activity.status == 100001 || activity.status == 548910001) {
                  iconName = "orders_complete";
                }
                break;
            case ActivityType.FollowUp:
                iconName = activity.state != 1 ? "follow-up_32x32" : "follow-up-complete";
                break;
            default:
                console.log("Unhandled activity icon case");
        }
        return iconName;
    }

    private getActivityId(activity: Activity) {
        let id = '';
        switch (activity.type) {
            case ActivityType.CaseIntake:
                id = (<CaseActivity>activity).offline_ID;
                break;
            case ActivityType.Email:
                id = (<EmailActivity>activity).offlineActivityId;
                break;
            case ActivityType.Sample:
                id = (<SampleActivity>activity).offlineActivityId;
                break;
            case ActivityType.SurgeryOrder:
                id = (<SurgeryOrderActivity>activity).offlineId;
                break;
            case ActivityType.ProcedureTracker:
                id = (<ProcedureTrackerActivity>activity).offlineId;
                break;
            case ActivityType.FollowUp:
                id = (<FollowUpActivity>activity).offlineId;
                break;
            default:
                console.log("Unhandled swithc case");
        }
        return id;
    }

    private async existingEmbeddedInteractions(activityId: string, embeddedInteractions: EmbeddedInteraction[]) {
        let key = DB_KEY_PREFIXES.EMBEDDED_INTERACTIONS + activityId;
        let embeddedInteractionsRaw = await this.disk.retrieve(key);
        if (embeddedInteractionsRaw && embeddedInteractionsRaw.embeddedInteractions) {
            let embeddedInteractionsInDB: EmbeddedInteraction[] = embeddedInteractionsRaw.embeddedInteractions;
            //Add Embedded Interaction if not present in DB
            embeddedInteractionsInDB = this.addEmbeddedInteractionsIfNotPresentInDB(embeddedInteractions, embeddedInteractionsInDB);
            //Remove if not present in latest embedded interaction
            embeddedInteractionsInDB = this.removeEmbeddedInteractionsIfNotPresentInLatest(embeddedInteractionsInDB, embeddedInteractions);
            embeddedInteractions = embeddedInteractionsInDB
        }
        embeddedInteractions = this.reInitializeSequence(embeddedInteractions);
        await this.disk.updateOrInsert(key, doc => ({ embeddedInteractions }));
        return embeddedInteractions;
    }

    private async saveToDisk(embeddedInteractions: EmbeddedInteraction[]) {
        const activity: AppointmentActivity = <AppointmentActivity>this.activityService.selectedActivity;
        if(!_.isEmpty(activity)) {
          const activityId = activity.offlineMeetingId && activity.offlineMeetingId.length > 0 ? activity.offlineMeetingId : activity.ID;
          let key = DB_KEY_PREFIXES.EMBEDDED_INTERACTIONS + activityId;
          await this.disk.updateOrInsert(key, doc => ({ embeddedInteractions }));
        }
    }

    private async saveEventToDisk(embeddedInteractions: EmbeddedInteraction[]) {
        const activity: EventActivity = <EventActivity>this.activityService.selectedActivity;
        const activityId = activity.offlineId && activity.offlineId.length > 0 ? activity.offlineId : activity.ID;
        let key = DB_KEY_PREFIXES.EMBEDDED_INTERACTIONS + activityId;
        await this.disk.updateOrInsert(key, doc => ({ embeddedInteractions }));
    }

    private removeEmbeddedInteractionsIfNotPresentInLatest(embeddedInteractionsInDB: EmbeddedInteraction[], embeddedInteractions: EmbeddedInteraction[]) {
        let embeddedInteractionsToUpdate: EmbeddedInteraction[] = [];
        embeddedInteractionsInDB.forEach(embeddedInteraction => {
            let index = embeddedInteractions.findIndex(eI => eI.id === embeddedInteraction.id);
            if (index === -1) {
                embeddedInteractionsToUpdate.push(embeddedInteraction);
            }
        });
        if (embeddedInteractionsToUpdate.length > 0) {
            embeddedInteractionsInDB = embeddedInteractionsInDB.filter(embeddedInteraction => embeddedInteractionsToUpdate.indexOf(embeddedInteraction) === -1);
            embeddedInteractionsInDB = _.orderBy(embeddedInteractionsInDB, "sequence", "desc");
        }
        return embeddedInteractionsInDB;
    }

    private addEmbeddedInteractionsIfNotPresentInDB(embeddedInteractions: EmbeddedInteraction[], embeddedInteractionsInDB: EmbeddedInteraction[]) {
        let embeddedInteractionsToUpdate: EmbeddedInteraction[] = [];
        embeddedInteractions.forEach(embeddedInteraction => {
            let sequence = -1;
            const index = embeddedInteractionsInDB.findIndex(eI => eI.id === embeddedInteraction.id);
            if (index === -1) {
                //Add at the end if not present
                embeddedInteraction.sequence = sequence;
                sequence--;
                embeddedInteractionsToUpdate.push(embeddedInteraction);
            }
            else {
                //Update name, thumbnail and sequence
                const eI = embeddedInteractionsInDB[index];
                if (eI.type === EmbeddedInteractionType.Activity) {
                    eI.name = this.activityTitle(<Activity>embeddedInteraction.interaction);
                    eI.thumbnailUrl = this.activityService.getActivityIcon(<Activity>embeddedInteraction.interaction);
                    eI.iconName = this.getActivityIconName(<Activity>eI.interaction);
                } else if (eI.type === EmbeddedInteractionType.Survey) {
                    let interaction = <CustomerAssessment>embeddedInteraction.interaction;
                    eI.name  = this.getSurveyTitle(<CustomerAssessment>embeddedInteraction.interaction);
                    eI.thumbnailUrl = "assets/imgs/survey-icon.svg";
                    eI.iconName = 'survey-icon';
                } else {
                    eI.name = embeddedInteraction.interaction['name'] ? embeddedInteraction.interaction['name'] : embeddedInteraction.interaction['title'];
                    eI.thumbnailUrl = embeddedInteraction.interaction['thumbnailUrl'] ? embeddedInteraction.interaction['thumbnailUrl'] : embeddedInteraction.interaction['thumbnailURL'];
                }
                eI.interaction = embeddedInteraction.interaction;
                embeddedInteractionsInDB[index] = eI;
            }
        });
        if (embeddedInteractionsToUpdate.length > 0) {
            embeddedInteractionsInDB = embeddedInteractionsInDB.concat(embeddedInteractionsToUpdate);
            embeddedInteractionsToUpdate = [];
        }
        return embeddedInteractionsInDB;
    }

    private activityTitle(activity: Activity) {
        let title: string = "";
        switch (activity.type) {
            case ActivityType.Email:
                if(activity && activity.subject){
                  title = this.translate.instant('MESSAGE') + (activity.subject == 'Message' ? '' : ' - ' + activity.subject);
                }else{
                  let emailActivity: EmailActivity = new EmailActivity(activity);
                  title = this.translate.instant('MESSAGE') + (emailActivity.subject == 'Message' ? '' : ' - ' + emailActivity.subject);
                }

                break;
            case ActivityType.Sample:
                let sampleActivity: SampleActivity = <SampleActivity>activity;
                title = this.translate.instant('ALLOWCATION_ORDER');
                if (sampleActivity.contactName.length > 0)
                    title = title + " - " + sampleActivity.contactName;
                break;
            case ActivityType.CaseIntake:
                let caseActivity: CaseActivity = <CaseActivity>activity;
                title = this.translate.instant('INQUIRY');
                if (caseActivity._case_type) {
                    title = caseActivity._case_type.name + ((caseActivity && caseActivity._case_contact) ? (' - ' + caseActivity._case_contact.fullName) : '');
                }
                break;
            case ActivityType.SurgeryOrder:
                title = this.translate.instant('NEW_ACTIVITY_PROCEDURE_LOG')+ ((!(activity as SurgeryOrderActivity).customersNameString) ? '' : ' - '+(activity as SurgeryOrderActivity).customersNameString);

                // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
                if(this.secondaryInfoService.isProcedureLogSecInfoConfigured) {
                  title = this.secondaryInfoService.getSecondaryInfoFormatedTextForSalesOrder(activity as SurgeryOrderActivity, SecondaryInfoEntityName.SalesOrder);
                }
                // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
                break;
            case ActivityType.ProcedureTracker:
                title = this.translate.instant('BULK_PROCEDURE_LOG');
                break;
            case ActivityType.FollowUp:
                title = (<FollowUpActivity>activity).subject;
                break;
            default:
                console.log("Unhandled activity case");
        }
        return title;
    }

    private getInMeetingActivities(activity: AppointmentActivity): Activity[] {
        let activities: Activity[] = [];
        const allActivities = this.authenticationService.impersonatedUser ? this.activityService.agendaTeamViewActivities : this.activityService.activities;
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE))
            activities = activities.concat(allActivities.filter((o) => {
                return (o instanceof CaseActivity && ((o._online_meetingID === activity.ID) ||
                    (o._offline_meetingID === activity.ID) ||
                    (o._offline_meetingID && o._offline_meetingID.length > 0 && o._offline_meetingID === activity.offlineMeetingId) ||
                    (o._online_meetingID && o._online_meetingID.length > 0 && o._online_meetingID === activity.offlineMeetingId)))
            }));
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY))
            activities = activities.concat(allActivities.filter((o) => {
                return (o instanceof EmailActivity && o.emailType != EmailTemplateType.RemoteURL
                    && o.appointmentid && o.appointmentid.length > 0
                    && (o.appointmentid === activity.ID ||
                        o.appointmentid === activity.offlineMeetingId))
            }));
        if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL))
            activities = activities.concat(allActivities.filter((o) => {
                return (o instanceof SampleActivity && o.appointmentID && o.appointmentID.length > 0
                    && (o.appointmentID === activity.ID ||
                        o.appointmentID === activity.offlineMeetingId))
            }));
          if (this.authenticationService.user.hasProcedureLog)
          activities = activities.concat(allActivities.filter((o) => {
              return (o instanceof SurgeryOrderActivity && o.orderType === 548910001 && ((o.appointmentId === activity.ID) ||
                  (o.appointmentId === activity.ID) ||
                  (o.appointmentId && o.appointmentId.length > 0 && o.appointmentId === activity.offlineMeetingId) ||
                  (o.offlineMeetingId && o.offlineMeetingId.length > 0 && o.offlineMeetingId === activity.offlineMeetingId)))
          }));
          if (this.authenticationService.user.hasBulkProcedureLog)
          activities = activities.concat(allActivities.filter((o) => {
              return (o instanceof ProcedureTrackerActivity && o.orderType === 548910002 && ((o.appointmentId === activity.ID) ||
                  (o.appointmentId === activity.ID) ||
                  (o.appointmentId && o.appointmentId.length > 0 && o.appointmentId === activity.offlineMeetingId) ||
                  (o.offlineMeetingId && o.offlineMeetingId.length > 0 && o.offlineMeetingId === activity.offlineMeetingId)))
          }));
          if (this.authenticationService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY))
          activities = activities.concat(allActivities.filter((o) => {
              return (o instanceof FollowUpActivity && o.planType === FOLLOW_UP_TYPE.MEETINGTASK && ((o.appointmentId === activity.ID) ||
                  (o.appointmentId === activity.ID) ||
                  (o.appointmentId && o.appointmentId.length > 0 && o.appointmentId === activity.offlineMeetingId) ||
                  (o.offlineMeetingId && o.offlineMeetingId.length > 0 && o.offlineMeetingId === activity.offlineMeetingId)))
          }));
        return activities;
    }

    private getInEventActivities(activity: EventActivity): Activity[] {
      let activities: Activity[] = [];
      const allActivities = this.authenticationService.impersonatedUser ? this.activityService.agendaTeamViewActivities : this.activityService.activities;
      const ordersIds = activity.allocations.length ? activity.allocations.map(e => e.offlineActivityId) : [];
      const isAllocationEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL);
      const isFollowUpEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_FOLLOW_UP_ACTION);
      if (
        isAllocationEnabled || isFollowUpEnabled
      ) {
        activities = activities.concat(allActivities.filter((o) =>
          (isAllocationEnabled && o instanceof SampleActivity && ordersIds.length > 0 && ordersIds.indexOf(o.offlineActivityId) >= 0)
          || (isFollowUpEnabled && o instanceof FollowUpActivity && o.indskr_event && activity.ID && o.indskr_event === activity.ID)
        ));
      }

      return activities;
    }

    public setCurrentMeetingStructure(input) {
        this.currentMeetingStructureSource.next(input);
    }

    public setCurrentMeetingActivity(activity: EmbeddedInteraction) {
        this.selectedMeetingActivitySource.next(activity);
    }

    public removeContentFromEmbeddedInteraction(content: Presentation | Resource | EventPresentations, embeddedInteractions: EmbeddedInteraction[]) {
        let length = embeddedInteractions.length;
        let index = embeddedInteractions.findIndex(embeddedInteraction =>
            (embeddedInteraction.type === EmbeddedInteractionType.Content && embeddedInteraction.interaction === content));
        if (index < (length - 1)) {
            let interactionsBefore = [];
            if (index > 0) {
                interactionsBefore = embeddedInteractions.slice(0, index);
            }
            let interactionsAfter: EmbeddedInteraction[] = embeddedInteractions.slice(index + 1, length);
            embeddedInteractions = [];
            embeddedInteractions = embeddedInteractions.concat(interactionsBefore).concat(interactionsAfter);
        } else {
            embeddedInteractions.pop();
        }
        this.updateEmbeddedInteraction(this.reInitializeSequence(embeddedInteractions));
    }

    public get jointMeetingStatus(): boolean {
        if (this.activityService.selectedActivity != undefined && this.activityService.selectedActivity != null) {
            //If the meeting has been marked as joint meeting
            //Handle case where joint meeting flag is set as undefined.
            if (this.activityService.selectedActivity['isJointmeeting'] || (this.activityService.selectedActivity['accompaniedUserList'] && this.activityService.selectedActivity['accompaniedUserList'].length > 0)) {
                let meetingOwnerId = this.activityService.selectedActivity['meetingOwnerId'];
                let userId = this.authenticationService.user.systemUserID;
                //owner
                const isCovisitor = !_.isEmpty(this.activityService.selectedActivity['accompaniedUserList']) ? this.activityService.selectedActivity['accompaniedUserList'].some(user => user.id === this.authenticationOfflineService.user.systemUserID) : false;
                const covisitorAccess = this.authenticationOfflineService.user.buSettings && this.authenticationOfflineService.user.buSettings['indskr_covisitoraccessonmeeting'];
                if (meetingOwnerId === userId || (isCovisitor && covisitorAccess === CovisitorAccess.FULL_ACCESS)) {
                    return false;
                }
                else
                    return true;
            }
            //owner is the meeting creator
            else {
                return false;
            }
        }
        else
            return false;
    }

    public updateEmbeddedActivity(activity: Activity) {
        const currentEmbeddedInteractions = this.currentMeetingStructureSource.getValue();
        if (currentEmbeddedInteractions) {
            currentEmbeddedInteractions.filter(em => this.getActivityId(activity) === em.id)
                .forEach(eI => {
                    eI.interaction = activity;
                    eI.name = this.activityTitle(activity);
                    eI.thumbnailUrl = this.activityService.getActivityIcon(activity);
                    eI.iconName = this.getActivityIconName(activity);
                });
        }
        this.updateEmbeddedInteraction(currentEmbeddedInteractions);
    }

    public checkExistingSurveyById(id: string) {
      const currentEmbeddedInteractions = this.currentMeetingStructureSource.getValue();
      return currentEmbeddedInteractions.some(ei => ei.id == id);
    }

    public updateEmbeddedSurvey(survey: CustomerAssessment) {
      const currentEmbeddedInteractions = this.currentMeetingStructureSource.getValue();
      if (currentEmbeddedInteractions) {
          currentEmbeddedInteractions.filter(em => survey.indskr_customerassessmentid === em.id)
              .forEach(eI => {
                  eI.id = survey.indskr_customerassessmentid;
                  eI.interaction = survey;
                  eI.thumbnailUrl = "assets/imgs/survey-icon.svg";
                  eI.iconName = 'survey-icon';
                  eI.type = EmbeddedInteractionType.Survey;
              });
      }
      this.updateEmbeddedInteraction(currentEmbeddedInteractions);
    }

    public updateEmbeddedSurveyFromSurveyPage(survey: CustomerAssessment) {
      const currentEmbeddedInteractions = this.currentMeetingStructureSource.getValue();
      if (currentEmbeddedInteractions) {
          currentEmbeddedInteractions.filter(em => survey.indskr_customerassessmentid === em.id)
              .forEach(eI => {
                  eI.id = survey.indskr_customerassessmentid;
                  eI.interaction = survey;
                  eI.thumbnailUrl = "assets/imgs/survey-icon.svg";
                  eI.iconName = 'survey-icon';
                  eI.type = EmbeddedInteractionType.Survey;
              });
      }
      if((this.navService.getCurrentMasterPageName()==PageName.EventsToolPageComponent || this.presentationService.viewMode==PresentationView.ADDTOEVENT)) this.saveEventToDisk(currentEmbeddedInteractions);
      else this.saveToDisk(currentEmbeddedInteractions);
    }

  public setCurrentEmbeddedActivityOnPresentation(id: string) {
      //skip survey details for short call to track survey creation time with slide duation
      if (this.activityService.selectedActivity instanceof AppointmentActivity && this.activityService.selectedActivity.indskr_shortcall) return;
      const currentEmbeddedInteractions = this.currentMeetingStructureSource.getValue();
      let currentActivity = currentEmbeddedInteractions.find(ei => ei.id == id);
      if(!_.isEmpty(currentActivity)) this.setCurrentMeetingActivity(currentActivity);
    }

    private async openInMeetingAllocationModal(activity: Activity, didDismissFn: (dismissData) => void, isNewlyCreated: boolean = false, from?: string) {
      if (this.modalOpened) return;
      this.modalOpened = true;
      if (!isNewlyCreated) {
          this.activityDataService.updateActivityDetails(activity, true, false);
      }
      const componentProps = { type: activity.type };
      if (from) {
        componentProps['from'] = from;
      }
      if (activity) {
        componentProps['activity'] = activity;
      }
      const modal = await this.modalCtrl.create({component: InMeetingAllocationComponent,componentProps, backdropDismiss: false, cssClass: 'inMeetingAllocation' })
      await modal.present();
      const dismissData = await modal.onDidDismiss();
      didDismissFn && didDismissFn(dismissData);
    }

    //For sample and message activity
    public async openInMeetingActivitiesModal(activity: Activity, isNewlyCreated: boolean = false) {
        this.uiService.showNewActivity = false;
        if (this.activityService.activitySource === ActivitySource.MEETING_PRESENTATION) {
            if (this.selectedMeetingActivitySource.getValue() && this.selectedMeetingActivitySource.getValue().interaction === activity) return;
            this.navService.popToRootChildNavPageWithPageTracking();
            switch (activity.type) {
                case ActivityType.Sample:
                    this.navService.pushChildNavPageWithPageTracking(NewSampleActivityComponent, PageName.NewSampleActivityComponent);
                    break;
                case ActivityType.Email:
                    this.navService.pushChildNavPageWithPageTracking(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent);
                    break;
                case ActivityType.SurgeryOrder:
                    this.navService.pushChildNavPageWithPageTracking(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent,PageName.PresentationMeetingComponent,{from: 'PresentationMeetingComponent', activity: activity});
                    break;
                case ActivityType.ProcedureTracker:
                    this.navService.pushChildNavPageWithPageTracking(ProcedureTrackerDetailsComponent, PageName.ProcedureTrackerDetailsComponent,PageName.PresentationMeetingComponent,{from: 'PresentationMeetingComponent', activity: activity});
                    break;
                case ActivityType.FollowUp:
                    this.navService.pushChildNavPageWithPageTracking(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent,PageName.PresentationMeetingComponent,{from: 'PresentationMeetingComponent', activity: activity});
                    break;
                default: break;
            }
        } else if (this.activityService.activitySource === ActivitySource.EVENT_DETAIL) {
          const dismissFn = (dismissData) => {
            this.modalOpened = false;
          };
          let from = 'EventDetailsPane';
          await this.openInMeetingAllocationModal(
            activity,
            dismissFn,
            isNewlyCreated,
            from,
          );
        } else {
          await this.openInMeetingAllocationModal(
            activity,
            (dismissData) => {
              this.modalOpened = false;
              this.footerService.initButtons(FooterViews.Activities);
            },
            isNewlyCreated,
          );
        }
    }

    public async openInMeetingCaseModal(activity: Activity) {
        this.caseManagementService.assignSelectedCase(<CaseActivity>activity);
        if (this.activityService.activitySource === ActivitySource.MEETING_PRESENTATION) {
            if (this.selectedMeetingActivitySource.getValue() && this.selectedMeetingActivitySource.getValue().interaction === activity) return;
            this.caseManagementService.accessedFrom = AccesingMode.PRESENTATION;
            this.navService.popToRootChildNavPageWithPageTracking();
            this.navService.pushChildNavPageWithPageTracking(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent);
        }
        else {
          if (this.modalOpened) return;
          this.modalOpened = true;
            let source = AccesingMode.INMEETING;
            if(this.activityService.activitySource === ActivitySource.PHONE_CALL_ACTIVITY_DETAIL){
                source = AccesingMode.IN_PHONE_CALL
            }
            this.caseManagementService.accessedFrom = source;
            let loader = this.loadingController.create();
            if(!this.authenticationOfflineService.impersonatedUser){
              this.activityDataService.fetchCaseDetails(activity);
            }
            let modal =await this.modalCtrl.create({component: InMeetingCasesComponent,componentProps: {
                type: activity.type,
                view: 'case-details'
            }, backdropDismiss: false, cssClass: this.device.isOffline ? "inMeetingCases offline-padding" : "inMeetingCases" })
            modal.present();
            modal.onDidDismiss().then((data) => {
              this.modalOpened = false;
                if (source === AccesingMode.INMEETING || source == AccesingMode.IN_PHONE_CALL) {
                    this.footerService.initButtons(FooterViews.Activities);
                    this.caseManagementService.accessedFrom = null;
                }
            });
        }
    }

    public createInMeetingEmbededActivity(embeddedActivity: Activity | Presentation | Resource | CustomerAssessment | InternalSurvey) {
        let currentEmbeddedInteraction = this.currentMeetingStructureSource.getValue() ? this.currentMeetingStructureSource.getValue() : [];
        let seq = this.getMeetingActivitySequence(currentEmbeddedInteraction);
        let embeddedInteraction: EmbeddedInteraction = this.getMeetingActivity(embeddedActivity, seq);
        if(!_.isEmpty(embeddedInteraction)) currentEmbeddedInteraction.push(embeddedInteraction);
        this.setCurrentMeetingActivity(embeddedInteraction);
        this.updateEmbeddedInteraction(this.reInitializeSequence(currentEmbeddedInteraction))
    }

    public createInMeetingEmbededSurveyFromPresentation(embeddedActivity: CustomerAssessment | InternalSurvey) {
      let currentEmbeddedInteraction = this.currentMeetingStructureSource.getValue() ? this.currentMeetingStructureSource.getValue() : [];
      let seq = this.getMeetingActivitySequence(currentEmbeddedInteraction);
      let embeddedInteraction: EmbeddedInteraction = this.getMeetingActivity(embeddedActivity, seq);
      if(!_.isEmpty(embeddedInteraction)) currentEmbeddedInteraction.push(embeddedInteraction);
      this.updateEmbeddedInteraction(this.reInitializeSequence(currentEmbeddedInteraction))
  }

    private getMeetingActivity(embeddedActivity: Activity | Presentation | Resource | CustomerAssessment | InternalSurvey, seq: number) {
        let embeddedInteraction: EmbeddedInteraction;
        if (embeddedActivity instanceof Activity) {
            embeddedInteraction = new EmbeddedInteraction(this.getActivityId(embeddedActivity), this.activityTitle(embeddedActivity), this.activityService.getActivityIcon(embeddedActivity), EmbeddedInteractionType.Activity, seq, embeddedActivity, this.getActivityIconName(embeddedActivity));
        }
        else if (embeddedActivity instanceof Presentation) {
            embeddedInteraction = new EmbeddedInteraction(embeddedActivity.ioPresentationId, embeddedActivity.name, embeddedActivity.thumbnailUrl, EmbeddedInteractionType.Content, seq, embeddedActivity, '');
        } else if (embeddedActivity instanceof Resource) {
            let id = embeddedActivity.ioDocumentId ? embeddedActivity.ioDocumentId : embeddedActivity.ioResourceId;
            embeddedInteraction = new EmbeddedInteraction(id, embeddedActivity.title, embeddedActivity.thumbnailURL, EmbeddedInteractionType.Content, seq, embeddedActivity, '');
        } else if(embeddedActivity instanceof CustomerAssessment) {
          embeddedInteraction = new EmbeddedInteraction(embeddedActivity.indskr_customerassessmentid, this.getSurveyTitle(embeddedActivity), "assets/imgs/survey-icon.svg", EmbeddedInteractionType.Survey, seq, embeddedActivity, 'survey-icon', embeddedActivity.surveyStatus === SurveyStatus.SUBMITTED, embeddedActivity.surveyStatus === SurveyStatus.SUBMITTED);
        }
        return embeddedInteraction;
    }

    private getSurveyTitle(embeddedActivity: CustomerAssessment): string {
      let surveyFor = embeddedActivity.indskr_entity == SurveyCategory.CONTACT ? SurveyCategory.CONTACT : SurveyCategory.ACCOUNT;
      let formattedTitle: string = embeddedActivity && embeddedActivity.indskr_name;
      let contactName: string = surveyFor ==  SurveyCategory.CONTACT ? this.contactService.getContactByID(embeddedActivity.indskr_entityid).fullName : this.accountService.getAccountById(embeddedActivity.indskr_entityid)?.accountName; 
      if(!_.isEmpty(contactName)) {
        formattedTitle = formattedTitle + ' - ' + contactName;
      }
      return formattedTitle;
    }

    private getMeetingActivitySequence(embeddedActivities: EmbeddedInteraction[]) {
        let seq = 0;
        const selectedMeetingActivity: EmbeddedInteraction = this.selectedMeetingActivitySource.getValue();
        if (!selectedMeetingActivity) {
            // get the highest sequence
            if (embeddedActivities && embeddedActivities.length > 0) {
                seq = embeddedActivities
                    .filter(embed => embed.sequence != null)
                    .sort((a, b) => (b.sequence) - (a.sequence))[0].sequence;
                seq++;
            }
        }
        else {
            seq = selectedMeetingActivity.sequence;
            if (embeddedActivities && embeddedActivities.length > 0) {
                embeddedActivities.filter(embed => embed.sequence < seq).forEach(embed => embed.sequence = --embed.sequence);
                this.setCurrentMeetingStructure(embeddedActivities);
            }
            seq = seq - 1;
        }
        return seq;
    }

    private async saveMeetingActivitiesInDB(activityId: string, embeddedActivities: EmbeddedInteraction[]) {
        await this.existingEmbeddedInteractions(activityId, embeddedActivities).then((data: EmbeddedInteraction[]) => {
            if (data && data.length > 0) {
                data = _.orderBy(data, "sequence", "desc");
            }
            this.setCurrentMeetingStructure(data);
        }).catch((er) => {
            console.error("Error occurred in existingEmbeddedInteractions() ", er);
            if (embeddedActivities && embeddedActivities.length > 0) {
                embeddedActivities = _.orderBy(embeddedActivities, "sequence", "desc");
            }
            embeddedActivities = this.reInitializeSequence(embeddedActivities);
            this.setCurrentMeetingStructure(embeddedActivities);
        });
    }

    public get contents(): Array<Presentation | Resource> {
        const activities = this.currentMeetingStructureSource.getValue();
        if (activities && activities.length) {
            return activities.filter(a => a.type === EmbeddedInteractionType.Content).map(fa => <Presentation | Resource>fa.interaction);
        }
        return [];
    }

    public get activities(): Activity[] {
        const activities = this.currentMeetingStructureSource.getValue();
        if (activities && activities.length) {
            return activities.filter(a => a.type === EmbeddedInteractionType.Activity).map(fa => <Activity>fa.interaction);
        }
        return [];
    }
  
  public get surveys(): CustomerAssessment[] {
    const activities = this.currentMeetingStructureSource.getValue();
    if (activities && activities.length) {
      return activities.filter(a => a.type === EmbeddedInteractionType.Survey).map(fa => <CustomerAssessment>fa.interaction);
    }
    return [];
  }

    public get embeddedActivities(): EmbeddedInteraction[] {
        const activities = this.currentMeetingStructureSource.getValue();
        if (activities && activities.length) {
            return activities.filter(a => a.type === EmbeddedInteractionType.Activity);
        }
        return [];
    }

    public get embeddedIntreactions(): EmbeddedInteraction[] {
        const activities = this.currentMeetingStructureSource.getValue();
        return _.isEmpty(activities) ? [] : activities;
    }

    public initSelectedMeetingActivity(selected?: EmbeddedInteraction) {
        if (selected) {
            this.setCurrentMeetingActivity(selected);
        } else if (this.activities.length > 0) {
            this.setCurrentMeetingActivity(this.embeddedActivities[0]);
        } else {
            this.setCurrentMeetingActivity(null);
        }
    }

    //Content Matching Rules
    async addFilteredContentToMeeting(selectDefault?: boolean) {
        if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CONTENT_MATCHING)) {
            if (this.activityService.selectedActivity instanceof AppointmentActivity && !this.activityService.selectedActivity.isReopened && this.activityService.selectedActivity['contacts'].length > 0) {
                this.activityService.selectedActivity['presentations'] = this.getActivityContents();
                const validPresentations = this.presentationService.filterExpiredPresentations(this.presentationService.initialPres);
                const matchedContents = this.contentMatchService.getAllMatchedContents(validPresentations, this.resourceService.allResources, this.activityService.selectedActivity['contacts']);
                this.activityService.selectedActivity['presentations'] = _.unionBy(this.activityService.selectedActivity['presentations'], matchedContents, 'contentId');

                if (Array.isArray(this.activityService.selectedActivity['presentations'])) {
                    let loader =await this.loadingController.create();
                    loader.present();
                    try {
                        await this.meetingDataService.addContentToMeeting(this.activityService.selectedActivity.presentations, this.activityService.selectedActivity)
                        this.updateContents(this.activityService.selectedActivity.presentations, selectDefault);
                    } catch (error) {
                        this.notificationService.notify(this.translate.instant("ERROR_UPDATING_MEETING_PRESENTATIONS"), 'Meeting Details');
                    }
                    loader.dismiss();
                }
            }
        }
    }

    private getActivityContents() {
        if (!this.activityService.selectedActivity['presentations']) return [];
        let activityContents = [];
        const activityPresentations = this.activityService.selectedActivity['activityPresentations'];
        if (activityPresentations && Array.isArray(activityPresentations)) {
            activityContents = activityPresentations.map(pres => pres.presentationId);
        }
        const activityResources = this.activityService.selectedActivity['activityResources'];
        if (activityResources && Array.isArray(activityResources)) {
            activityContents = _.concat(activityResources.filter(ar => ar.indskr_ioresourceid).map(res => res.indskr_ioresourceid),
                activityResources.filter(ar => ar.indskr_iodocumentid).map(res => res.indskr_iodocumentid), activityContents);
            return this.activityService.selectedActivity['presentations'].filter(content =>
                (activityContents.indexOf(content.ioPresentationId) !== -1) || (activityContents.indexOf(content.ioResourceId) !== -1) ||
                (activityContents.indexOf(content.ioDocumentId) !== -1) || !content.isAutoLoaded);
        }
    }

}
