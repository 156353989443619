
  <ind-section-header [viewData]="detailHeaderModel"></ind-section-header>

  
  <ind-form-field [viewData]="getNameFormField()" *ngIf="selectedMode === 1"></ind-form-field>

  <ind-form-field [viewData]="getAccountsFormField()"></ind-form-field>
  <ind-form-field [viewData]="getContactFormField()"></ind-form-field>
  <ind-form-field [viewData]="getProductFormField()"></ind-form-field>
  
  <ind-datetime-form [viewData]="getStartPeriodFormField()"></ind-datetime-form>
  <ind-datetime-form [viewData]="getEndPeriodFormField()"></ind-datetime-form>
  
  <!-- <ind-form-field [viewData]="plannedBudgetFormField()"></ind-form-field> -->
  <ng-container *ngIf="selectedMode != 1">
    <ind-form-field [viewData]="getEstimatedRevenueFormField()"></ind-form-field>
    <ind-form-field [viewData]="getActualRevenueFormField()"></ind-form-field>
  </ng-container>
  
  <!-- stategy -->
  
  <ind-section-header [viewData]="strategyHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
  </ind-section-header>
  <ind-text-area *ngIf="selectedMode != 0 && planDataSetReference" [placeHolder]="translate.instant('ENTER_STRATEGY')" [maxLength]="1000"
    [(value)]="planDataSetReference.strategy" (indBlur)="onStrategyUpdate(planDataSetReference?.strategy)"
    (ionFocus)="onTextAreaFocus(planDataSetReference?.strategy)" [ws]="4" [cols]="10" [autoGrow]="true">
  </ind-text-area>
  <ion-item *ngIf="selectedMode === 0" [ngClass]="{'empty-list':!planDataSetReference?.strategy}">
    <div class="strategy-text" [ngClass]="showMore?'show-more':''">
      {{ showMore? planDataSetReference?.strategy : planDataSetReference?.strategy |
      slice:0:strategy_Text_default_length}}
      <a class="showEllipsis" *ngIf="planDataSetReference?.strategy.length > strategy_Text_default_length && !showMore">...
      </a>
      <a *ngIf="planDataSetReference?.strategy.length > strategy_Text_default_length" (click)="setShowMore($event)"
        class="show-more">{{ !showMore ? ('SHOW_MORE' | translate) : ('SHOW_LESS' | translate)}}</a>
    </div>
  </ion-item>
  
  <!--Objectives-->
  <ng-container *ngIf="selectedMode != 1">
    <ind-section-header [viewData]="objectivesHeaderModel"></ind-section-header>
    <ion-item class="placeholderEmptyDiv"
      *ngIf="!planDataSetReference?.accountPlanObjectives || planDataSetReference?.accountPlanObjectives.length==0">
    </ion-item>
    <main-card *ngFor="let objective of allModelObjectives" [viewData]="objective"></main-card>
  </ng-container>
  
  <!-- events -->
  <ng-container *ngIf="isEventsEnabled">
    <ind-section-header [viewData]="eventsSectionHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
    </ind-section-header>
    <ion-item class="placeholderEmptyDiv" *ngIf="associatedEvents.length=== 0"></ion-item>
    <div [ngClass]="{'events-container':!associatedEvents.length}" lines="none">
      <ion-item *ngFor=" let event of associatedEvents">
        <ion-label text-wrap>{{ event.name}}</ion-label>
      </ion-item>
    </div>
  </ng-container>
  
  <!--Expense Details-->
  <ng-container *ngIf="isExpensesEnabled">
    <ind-section-header [viewData]="expensesHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
    </ind-section-header>
    <ion-item class="placeholderEmptyDiv"
      *ngIf="!planDataSetReference?.expenses || planDataSetReference?.expenses.length == 0">
    </ion-item>
    <ion-item *ngFor="let expense of planDataSetReference?.expenses">
      <ion-label>
        <ion-text class="primary-text">{{expense.description}}</ion-text><br>
        <ion-text class="secondary-text">{{dateTimeFormatsService.formattedCurrency(expense.amountFormatted)}}
        </ion-text>
      </ion-label>
      <ion-checkbox [ngClass]="{'round-disabled': deviceOffline}" mode="ios" slot="start" [(ngModel)]="expense.isChecked"
        (ionChange)="expensesIsSelected($event)">
      </ion-checkbox>
    </ion-item>
  </ng-container>

