import { distinctUntilChanged } from 'rxjs/operators';
import { Component, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { ActivityDataService } from '../../data-services/activity/activity.service';
import { Activity, ActivityType } from '../../classes/activity/activity.class';
import { ActivityService, ActivitySource, ActivityScrapStatus } from '../../services/activity/activity.service';
import { PopoverController, MenuController,  IonNav, LoadingController, ModalController } from '@ionic/angular';
import { Events } from '@omni/events';
import { RepServices } from '../../data-services/rep/rep.services';
import { UIService, TabOptions, ComponentViewMode } from '../../services/ui/ui.service';
import { DeviceService, VIEW_MODE_CHANGE } from '../../services/device/device.service';
import { NewActivityComponent } from '../../components/activity/new-activity/new-activity';
import { FooterViews, FooterService } from '../../services/footer/footer.service';
import { TrackService, TrackingEventNames } from '../../services/logging/tracking.service';
import { NavigationService, PageName, ChildNavNames } from '../../services/navigation/navigation.service';
import { ActivitiesDetailsPaneComponent } from '../../components/activity/activities-details-pane/activities-details-pane';
import { ParticipantListComponent } from '../../components/resource/participant-list/participant-list';
import { REP_STATUS } from '../../models/rep-status-model';
import { CoachingReportService } from '../../services/coaching/coaching.report.service';
import { Subscription } from 'rxjs';
import { TimeOffService } from '../../services/time-off/time-off.service';
import { EmailService } from '../../services/email-templates/email.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { MeetingStructureService } from '../../services/meeting-structure/meeting-structure.service';
import { PhoneActivity } from '../../classes/activity/phone.activity.class';
import { PhoneCallMeetingStructureService } from '../../services/phone-call-meeting-structure/phone-call-meeting-structure.service';
import { TranslateService } from '@ngx-translate/core';
import { GeneeNotificationPopoverComponent } from '../../components/genee-notification-popover/genee-notification-popover';
import { OfflineSyncUtility } from '../../utility/offline-sync.utility';
import { XperiencesService } from '@omni/services/xperiences/xperiences.service';
import { FooterToolbarComponent } from '@omni/components/footer-toolbar/footer-toolbar';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { AllocationFeatureService } from '../../services/sample/allocation.feature.service';
import { ActivitiesPaneComponent } from '@omni/components/activity/activities-pane/activities-pane';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { TimeOffUtilityService } from '@omni/services/time-off/time-off-utility.service';
import { WebsocketDataService } from '@omni/data-services/websocket/websocket.data.service';
import { MeetingStatus } from '@omni/classes/meeting/meeting.class';
import { MeetingDataService } from '@omni/data-services/meeting/meeting.data.service';
import { AlertService } from '@omni/services/alert/alert.service';
import { CreateSampleDropRequestBody, SampleActivity } from '@omni/classes/activity/sample.activity.class';
import { SampleDataService } from '@omni/data-services/sample/sample.data.service';
import { CallPlanOfflineService } from '@omni/services/call-plan/call-plan.offline.service';
import { FollowUpActivity } from '@omni/classes/activity/follow-up-action.activity.class';
import { FollowUpActivityDataService } from '@omni/data-services/follow-up-activity/follow-up-activity.data.service';
import { OrderActivity } from '@omni/classes/activity/order.activity.class';
import { OperationDetail, OrderActivityDataService } from '@omni/data-services/order-activity/order-activity.data.service';
import { CaseActivity } from '@omni/classes/case-intake/case-activity.class';
import { CaseManagementService } from '@omni/services/case-management/case-management.service';
import { PhonecallStatus, PhoneCallDataService } from '@omni/data-services/phone-call/phonecall.data.service';
import { AgendaFooterService } from '@omni/services/footer/agenda-footer.service';
import { EventsService } from '@omni/services/events/events.service';
import { IndNotificationDataModel } from '@omni/models/indNotificationDataModel';
import { MyAssistantService } from '@omni/services/my-assistant/my-assistant.service';
import * as _ from 'lodash';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { StoreCheckService } from '@omni/services/store-check/store-check.service';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { ProcedureContractService } from '@omni/data-services/procedure-contract/procedure-contract.service';
import { SetBookingDataService } from '@omni/data-services/set-booking/set-booking.data.service';
import { SetBookingActivity, SetBookingStatus } from '@omni/classes/activity/set-booking.activity.class';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';
import { VoiceCallService } from '@omni/services/voice-call/voice-call.sertvice';
import { addResponseMessage, isWidgetOpened, Widget, toggleWidget, setQuickButtons, addUserMessage, toggleMsgLoader } from "react-chat-widget";
import React from "react";
import ReactDOM from "react-dom";
import { HttpClient } from '@angular/common/http';
import { DirectLine } from 'botframework-directlinejs';
import { IoEventListener } from '@omni/services/io-event-listener.decorator';
import { ContactDetailsComponent } from '@omni/components/contact/contact-details/contact-details';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { Contact } from '@omni/classes/contact/contact.class';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { AccountDataService } from '@omni/data-services/accounts/account.data.service';
import { Account } from '@omni/classes/account/account.class';
import { AccountDetailsComponent } from '@omni/components/account/account-details/account-details';
import { Geolocation } from "@awesome-cordova-plugins/geolocation/ngx";
/**
 * Generated class for the ActivitiesPageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'activities-page[base-page]',
  templateUrl: 'activities-page.html',
  styleUrls:['activities-page.scss']
})
export class ActivitiesPageComponent {

  // private title: string;
  // private tempStatus: string;
  private lastActivityType: string;
  public isActivitySelected: boolean = false;
  public isNewActivityView: boolean = false;
  public scheduleRefresh: boolean = false;
  IallNotificationsSubscription : Subscription;
  private _orientationHandler: (modeChange: VIEW_MODE_CHANGE, curPageName: PageName, curMasterPageName: PageName) => void;

  private _screenOrientation: Subscription;
  public isPortrait: boolean = false;

  public participantListComponent: any = ParticipantListComponent;
  public hasBaselineEnabled: boolean = false;
  public selectedTot: any;
  public nothingSelectedPageMessage = '';
  @ViewChild(ActivitiesPaneComponent, {static: false}) activitiesPaneComponent: ActivitiesPaneComponent;
  directline: any;
  currentCoords: { latitude: number; longitude: number; };
  isBotTyping: boolean = false;
  public get participantListComponentParams() {
    return {
      appointment: this.activityService.selectedActivity,
      resource: this.activityService.selectedSharedResource,
    }
  }

  public activitiesDetailsPaneRoot: any = ActivitiesDetailsPaneComponent;
  @ViewChild('activitiespagerightpane', {static: true}) public navCtrl: IonNav;
  @ViewChild(FooterToolbarComponent) footerToolbarComponent: FooterToolbarComponent;
  selectedView: 'plans' | 'activities' | 'xperiences' | 'edgeAnalytics' | 'notifications' | 'shortCallHome';
  leftPaneView: 'default' | 'shortCallLauncher' | undefined;
  speechRecognizer: any;

  /**
   * Creates an instance of HomePage.
   *
   * @param {AuthenticationService} authenticationService
   * @param {ActivityDataService} activityService
   *
   * @memberOf HomePage
   */
  agendaTabSubscription: Pick<Subscription, 'unsubscribe'>;
  constructor(
    public activityService: ActivityService,
    public popoverCtrl: PopoverController,
    public uiService: UIService,
    public device: DeviceService,
    public authenticationOfflineService: AuthenticationService,
    public trackingService: TrackService,
    private navService: NavigationService,
    public footerService: FooterService,
    private events: Events,
    private repService:RepServices,
    public coachingReportService: CoachingReportService,
    private timeOffService: TimeOffService,
    private meetingStructureService: MeetingStructureService,
    private phoneCallMeetingStructureService : PhoneCallMeetingStructureService,
    public authenticationService: AuthenticationService,
    private translate: TranslateService,
    public emailService: EmailService,
    private offlineSyncUtility: OfflineSyncUtility,
    private xpService: XperiencesService,
    private allocFeaturetService: AllocationFeatureService,
    private notificationService: NotificationService,
    private timeOffUtilityService: TimeOffUtilityService,
    private websocket: WebsocketDataService,
    private meetingService: MeetingDataService,
    private alertService: AlertService,
    private sampleDataService: SampleDataService,
    private callplanService: CallPlanOfflineService,
    private followUpDataService: FollowUpActivityDataService,
    private orderActivityDataService: OrderActivityDataService,
    private caseManagementService: CaseManagementService,
    private phonecallDataService: PhoneCallDataService,
    public agendaFooterService: AgendaFooterService,
    public eventsService: EventsService,
    public assistantService: MyAssistantService,
    public deviceService : DeviceService,
    private activityDataService: ActivityDataService,
    private voiceCallservice: VoiceCallService,
    private storeCheckService: StoreCheckService,
    private surgeryOrderService : SurgeryOrderActivityDataService,
    private procedureContractService: ProcedureContractService,
    private readonly setBookingDataService: SetBookingDataService,
    private loadingCtrl: LoadingController,
    private faService: FeatureActionsService,
    private http: HttpClient,
    public modalCtrl: ModalController,
    private contactService: ContactOfflineService,
    private accountService: AccountOfflineService,
    private accountDataService: AccountDataService,
    private geoLocation: Geolocation
  ) {

    this.IallNotificationsSubscription = this.assistantService.notificationsObs$.subscribe((data: IndNotificationDataModel[]) => {
      if (data) {
        this.assistantService.hasAnyUnRedNotifications = _.some(data, ['isRed', false]);
      }
    });

    this.eventsService.observe("FA Refreshed")
    .subscribe(() => {
      this.setDefaultLeftPane();
      this.setSegmentTab();
    });

    this.eventsService.observe("sync:completed")
    .subscribe(() => {
      const currentActivePageName = this.navService.getActiveChildNavViewPageName();
      const skipFunctionOnThisActivePageList = [PageName.GeneralSettingsComponent];
      if(!skipFunctionOnThisActivePageList.includes(currentActivePageName)) {
        this.setSegmentTab();
      }
    });

    // this._screenOrientation = this.device.screenOrientation.subscribe((orientation: number) => {
    //   if (orientation === DEVICE_ORIENTATION["landscape-primary"] || orientation === DEVICE_ORIENTATION["landscape-secondary"]) {
    //     this.isPortrait = false;
    //   } else {
    //     this.isPortrait = true;
    //   }
    // });

    // this.isPortrait = this.device.isOrientationPortrait();


    this.events.unsubscribe('deleteMeeting')
    this.events.subscribe('deleteMeeting', (activity:Activity, forcePopup) => {
      this.deleteActivity(activity?activity:this.activityService.selectedActivity, false, forcePopup);
    });

    this.events.subscribe('deleteMeetingNoConfirm', () => {
      this.deleteActivity(this.activityService.selectedActivity, true);
    });
    this.events.subscribe("window:message",(data)=>this.handleChatClick(data));

    this.events.subscribe('sync:completed', () => {
      this.faService.updateFaValues();
    });

  }

  ngOnInit(){
    this.agendaTabSubscription = this.uiService.agendaTabObserver
      .subscribe((selectedTab) => {
        this.manageSegmentChange(selectedTab);
    });


    this.setDefaultLeftPane();
    this.hasBaselineEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.BASELINE) ? true : false;
    // this.navService.initRightPaneChildNav(this.activitiesDetailsNavCtrl,ChildNavNames.ActivitiesPageNavigation,PageName.ActivitiesPageComponent);
    this.activityService.activitySource = this.footerService.isActivitiesButton ? ActivitySource.AGENDA : ActivitySource.CONTACT_INFO;
    this.events.subscribe('deletedEmbeddedActivity', activity => {
      if(this.activityService.selectedActivity instanceof PhoneActivity){
        this.phoneCallMeetingStructureService.removeActivity(activity)
        return
      }
      this.meetingStructureService.removeActivity(activity);
    });

    this.events.subscribe('updateEmbeddedActivity', activity => {
      if(this.activityService.selectedActivity instanceof PhoneActivity){
        this.phoneCallMeetingStructureService.updateEmbeddedActivity(activity);
        return;
      }
      this.meetingStructureService.updateEmbeddedActivity(activity);
    });
    // this.events.subscribe('syncPopup', (data: any) => {
    //   this.refreshData ()
    //   }
    // )
    this.events.subscribe('openNewActivityPage', () => {
      this.onAddNewActivityClick();
    });
    this.events.subscribe('hideNewActivityScreen', () => {
      this.hideNewActivityScreen();
    });
    this.events.subscribe('updateActivitiesRHSEmptyPage', (shouldUpdate) => {
      if (shouldUpdate) {
        this.nothingSelectedPageMessage = '';
      } else {
        this.nothingSelectedPageMessage = this.translate.instant('NOTHING_SELECTED');
      }
    });

    this.events.subscribe('SegmentChnaged', () => {
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
      this.showNewActivity();
    });

    this.events.subscribe('setAgendaTabForPlans', () => {
      this.manageSegmentChange("plans");
    });

    // this.voiceCallservice.initClinkToolBar();

    setTimeout(()=> {
      this.setDefaultLeftPane();
      this.setSegmentTab();
      if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EXEEVO_ALPHA_COPILOT))this.integrateChat();
    }, 200);
  }

  private isEligibleDefaultTab(tab){
    if(!this.authenticationService.user.defaultMeasureTab) return false;

    if(tab === 'xperiences'){
      return (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.XPERIENCES) && !this.deviceService.isOffline)
    }else if(tab === 'edgeAnalytics'){
     return (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EDGE_ANALYTICS_MEETING)
      || this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG))
    }
    return true;
  }

  // Select default left pane components between normal and short call launcher
  private setDefaultLeftPane() {
    const shortCallLauncherEnabled = this.faService.isShortCallLauncherEnabledInMobileDevice;
    const newLeftPaneView = shortCallLauncherEnabled
      ? 'shortCallLauncher'
      : 'default';
    if (this.leftPaneView !== newLeftPaneView) {
      this.leftPaneView = newLeftPaneView;

      if (shortCallLauncherEnabled) {
        this.device.lockDeviceOrientationPotrait();
      }
    }
  }
  // Select default segment tab
  private setDefaultSegmentTab() {
    if (this.leftPaneView === 'default' || this.leftPaneView === undefined) {
      const newSelectedView = this.isEligibleDefaultTab(TabOptions[this.authenticationService.user.defaultMeasureTab]) ? TabOptions[this.authenticationService.user.defaultMeasureTab] : 'plans';
      if (this.selectedView !== newSelectedView) {
        this.selectedView = newSelectedView;
      }
    } else if (this.leftPaneView === 'shortCallLauncher') {
      this.selectedView = 'shortCallHome';
    }
  }

  setSegmentTab(){
    this.setDefaultSegmentTab();
    if (this.selectedView === 'xperiences') {
      this.uiService.setAgendaTab('Impact');
      this.uiService.activitiesPageTab = 'Impact';
    } else if (this.selectedView === 'activities') {
      this.uiService.setAgendaTab('agenda');
      this.uiService.activitiesPageTab = 'agenda';
    } else if (this.selectedView === 'shortCallHome') {
    } else {
      this.uiService.setAgendaTab(this.selectedView);
      this.uiService.activitiesPageTab = this.selectedView;
    }
  }

  manageSegmentChange(selectedTab: string) {
    this.uiService.isSetAgendaTab = true;
    if (selectedTab === 'agenda') {
      this.activityService.activitySource = ActivitySource.AGENDA;
      this.footerToolbarComponent.openActivities('activities');
    } else if (selectedTab === 'Impact') {
      this.footerToolbarComponent.openTrendingCustomers('xperiences');
    } else if (selectedTab === 'edgeAnalytics') {
      this.trackingService.tracking('MeasureTabClick', TrackingEventNames.EDGE_ANALYTICS);
      this.footerToolbarComponent.openEdgeAnalytics(selectedTab);
    } else if (selectedTab === 'plans') {
      this.footerToolbarComponent.openPlans(selectedTab);
    } else if (selectedTab === 'notifications') {
      this.footerToolbarComponent.openNotifications(selectedTab);
    } else if (selectedTab === 'shortCallHome') {
      // Work around to emulate the bottom tab navigation (sub level nav) to
      // work as segment navigation (Top level nav) in activities page
      this.footerToolbarComponent.setSelectedView(selectedTab);
    } else if (selectedTab === 'callPlanDashboard') {
      this.footerToolbarComponent.setSelectedView(selectedTab);
    }
  }

  private showNewActivity() {
    if (!this.device.isMobileDevicePortrait && !this.authenticationOfflineService.impersonatedUser) {
      this.activityService.activitySource = ActivitySource.AGENDA;
      this.uiService.showNewActivity = true;
      this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.ActivitiesPageComponent);
    }
  }

  ngOnDestroy(){
    // this._screenOrientation.unsubscribe();
    this.events.unsubscribe('hideNewActivityScreen');
    this.events.unsubscribe('updateActivitiesRHSEmptyPage');
    this.IallNotificationsSubscription.unsubscribe();
    if(this.agendaTabSubscription) {
      this.agendaTabSubscription.unsubscribe();
    }
    this.events.unsubscribe('setAgendaTabForPlans');
  }

  // public async refreshData() {
  //   // this.trackingService.tracking('SyncClicked', TrackingEventNames.SYNC);
  //   if (this.activityService.teamViewActive) {
  //     this.activityService.teamViewActive = false;
  //     this.activityService.selectedActivity = undefined;
  //     this.authenticationService.impersonatedUser = undefined;
  //     this.activityService.agendaTeamViewActivities = [];
  //     this.activityService.activityFilter = ActivityType.AllActivity;
  //     this.events.publish('filterActivitiesDataForSelectedUser');
  //   }

  //   let data = {
  //     heading: this.translate.instant('GENEE_SYNC_CONFIRMATION_CAP'),
  //     notificationText: this.translate.instant('GENEE_HAVE_OFFLINE_STATE_UPDATES',{offlineTime:this.offlineSyncUtility.totalOfflineHours}),
  //     mainButtontext: this.translate.instant('GENEE_SYNC_NOW'),
  //     secondaryButtontext: this.translate.instant('REMIND_LATER',{remindTime:this.offlineSyncUtility.totalRemindHours})
  //   }
  //   if (!this.uiService.geneeSyncPopover) {
  //     this.uiService.geneeSyncPopover = await this.popoverCtrl.create({component: GeneeNotificationPopoverComponent,componentProps: data,
  //        cssClass: 'geneeSyncConfirmation', showBackdrop: true, backdropDismiss: false })
  //   }
  //   else {
  //     this.uiService.geneeSyncPopover = undefined;
  //     this.uiService.geneeSyncPopover = await this.popoverCtrl.create({component: GeneeNotificationPopoverComponent,componentProps: data,
  //        cssClass: 'geneeSyncConfirmation', showBackdrop: true, backdropDismiss: false })
  //   }
  //   this.uiService.geneeSyncPopover.present();

  // }

  // showSyncPopup() {
  //   if (!this.device.isDeviceRealOffline && this.device.isUserStateOffline && !this.device._isDeviceOfflineFromLogin) {
  //     if (this.offlineSyncUtility.isSyncRequire()) {
  //       this.events.publish('syncPopup');
  //     }
  //   }
  // }

  ngAfterViewInit() {
    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.ActivitiesPageNavigation, PageName.ActivitiesPageComponent, false, { deviceString: this.device.deviceFlags.ios ? 'ios' : 'other' });
    // this.showNewActivity();
  }

  ionViewWillEnter() {
    this.uiService.toolsActivityActive = false;
    // this.showSyncPopup();
    // Update status to in meeting if not.
    if (!this.device.isOffline && this.repService.getCurrentUserState() === REP_STATUS.IN_MEETING.userState) {
        let newStateData = { userState: REP_STATUS.ONLINE.userState };
        this.repService.setCurrentState(newStateData);
    }
    if (!this._orientationHandler) {
      this._orientationHandler = (modeChange: VIEW_MODE_CHANGE, curPageName: PageName, curMasterPageName: PageName) => {
        if (curMasterPageName === PageName.ActivitiesPageComponent) {
          if (modeChange === VIEW_MODE_CHANGE['normal-to-mobile']) {
            if (this.uiService.showNewActivity) {
              // this.navService.pushWithPageTracking(NewActivityComponent, PageName.NewActivityComponent);
              this.uiService.showRightPane = true;
            }
          }
        }
      };
      this.events.subscribe('device:view-mode-change', this._orientationHandler);
    }
    /*
    // Check if Meeting detail is opened and push if it is.
    if (this.device.shouldBeMobileView && !this.uiService.showNewActivity &&
        this.uiService.activeView === 'Appointment' && this.activityService.selectedActivity &&
        !this.activityService.isNewActivityCreated) {

      // this.navService.pushWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent);
      this.uiService.showRightPane = !this.device.isMobileDevicePortrait;
    }
    */
    if(this.uiService.agendaRefreshRequired){
      this.events.publish('refreshAgenda');
      this.uiService.agendaRefreshRequired = false;
    }
  }

  ionViewWillLeave() {
    if (this._orientationHandler) {
      this.events.unsubscribe('device:view-mode-change', this._orientationHandler);
      this._orientationHandler = null;
    }
  }

  ionViewDidEnter(){
    // if (this.uiService.activitiesPageTab == 'agenda' && this.activityService.selectedActivityAtHome) {
    //   if (!this.activityService.selectedActivity || this.activityService.selectedActivity.ID !== this.activityService.selectedActivityAtHome.ID) {
    //     this.activitiesPaneComponent.openActivityDetails(this.activityService.selectedActivityAtHome);
    //   }
    // }
    // adding this condition remove meeting icons in the footer toobar for email acitivity created from agenda
    // and accessing it after capturing consent by using flip button on consent details page

    setTimeout(() => {
      this.setFooter();

    },10)
    this.timeOffService.setMobileTracker('agenda');
    this.events.publish('scrollToDate');
    if (this.agendaFooterService.footerViewAtHome) {
      this.agendaFooterService.initButtons(this.agendaFooterService.footerViewAtHome);
      this.agendaFooterService.footerViewAtHome = null;
    }
    if(this.uiService.activitiesPagePlanTabSelectedPlan != 'scientificPlans'
        && this.uiService.activitiesPagePlanTabSelectedPlan != 'accountPlansTab'
        && this.uiService.activitiesPagePlanTabSelectedPlan != 'goalsPlansTab') {
      this.uiService.showCancelDoneOnActivityDetails = false;
    }
  }

  private setFooter() {
    if(this.uiService.activeView !== 'Email' && this.uiService.activitiesPageTab !== 'plans') {
      this.initFooter();
    } else if(this.uiService.activitiesPageTab === 'plans') {
      if (this.uiService.activitiesPagePlanTabSelectedPlan === 'accountPlansTab'){
        if(this.uiService.activeViewOnPlanTab === 'planProgressReports'){
          this.events.publish('resetPlanProgressReportFooter');
        }
      } else if (this.uiService.activitiesPagePlanTabSelectedPlan === 'goalsPlansTab'){
        if(this.uiService.activeViewOnPlanTab === 'customerCallPlanDetails'){
          this.footerService.initButtons(FooterViews.CustomerCallPlan);
        }
      } else if (this.uiService.activitiesPagePlanTabSelectedPlan === 'planTabTodo') {
        if (this.allocFeaturetService.selectedShipmentAtHome) {
          this.allocFeaturetService.selectedShipment = this.allocFeaturetService.selectedShipmentAtHome;
          this.footerService.initButtons(FooterViews.ShipmentDetails);
        } else {
          this.footerService.initButtons(FooterViews.Activities);
        }
      }
    }
  }
  private initFooter() {
    if(this.navService.getActiveChildNavViewPageName() == PageName.DynamicFormComponent && this.navService.getActiveChildNavViewMasterPageName() == PageName.ActivitiesPageComponent){
      this.footerService.initButtons(FooterViews.Accounts);
    } else if(this.navService.getActiveChildNavViewPageName() == PageName.SetBookingActivityDetailsComponent && this.navService.getActiveChildNavViewMasterPageName() == PageName.ActivitiesPageComponent){
      this.footerService.initButtons(FooterViews.SetBooking);
    } else if (this.uiService.activitiesPageTab === 'edgeAnalytics') {
      this.footerService.initButtons(FooterViews.None);
    } else if (this.uiService.activeView === 'Sample') {
      this.footerService.initButtons(FooterViews.SampleDropDetails);
    } else if (this.uiService.activeView === 'PhoneCall') {
      this.footerService.initButtons(FooterViews.PHONE_CALL);
    } else if (this.uiService.activeView === 'customer_inquiry' || this.activityService.selectedActivity?.type === ActivityType.CaseIntake) {
      this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
    } else if(this.activityService.selectedActivity?.type === ActivityType.StoreCheck){
      this.footerService.initButtons(FooterViews.STORE_CHECK);
    } else if(this.uiService.activeView === 'surveyDetails'){
      this.footerService.initButtons(FooterViews.SAVE_SURVEY_DETAILS);
    } else {
      if (this.navService.getActiveChildNavViewPageName() != PageName.AppealDetailsComponent)
        this.footerService.initButtons(FooterViews.Activities);
    }
  }

  /**
   * Simple flag set to trigger new activity view
   *
   * @memberof ActivitiesPageComponent
   */
  onAddNewActivityClick() {
    this.lastActivityType = this.uiService.activeView;
    this.activityService.activitySource = ActivitySource.AGENDA;
    if (this.device.shouldBeMobileView) {
      //TC-728, need to set the logic in this flow only
      this.uiService.showNewActivity = false;
    }
    this.uiService.setShowNewActivity(!this.uiService.showNewActivity);
    this.trackingService.tracking('AgendaNewActivity', TrackingEventNames.ACTIVITY)

    if (this.device.shouldBeMobileView) {
      this.uiService.showRightPane = true;
    }
    if (this.uiService.showNewActivity && this.navService.getActiveChildNavViewPageName() != PageName.NewActivityComponent) {
      this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.ActivitiesPageComponent);
    }
    if (this.agendaFooterService.actSegment === 'agenda') {
      setTimeout(()=> { this.events.publish("updateCurrentDate"); }, 20);
    }
  }

  private hideNewActivityScreen() {
    this.uiService.setShowNewActivity(false);
    this.navService.popChildNavPageWithPageTracking();
    if (this.device.shouldBeMobileView) {
      this.uiService.showRightPane = false;
    }
  }

  /**
   * Close new activity component and go back to previously open component
   *
   * @memberof ActivitiesPageComponent
   */
  onCancelNewActivity() {
    this.uiService.activeView = this.lastActivityType;
    this.uiService.showNewActivity = false;
    this.uiService.setnewActivityRequest(this.uiService.showNewActivity);
    this.events.publish("go-back-to-xperiences", PageName.ActivitiesPageComponent);
  }

  scheduleSelected(event) {
    this.activityService.selectedActivity = event;
    this.uiService.showNewActivity = false;
    this.uiService.activeView = 'Scheduler';
    if (this.device.isMobileDevice) {
      this.uiService.showRightPane = true;
    }
  }

  closeScheduler(event): void {
    if(event) {
      this.activityService.selectedActivity = undefined;
      this.uiService.showNewActivity = false;
      this.uiService.activeView = '';
      this.scheduleRefresh = !this.scheduleRefresh;
      if (this.device.isMobileDevice) {
        this.uiService.showRightPane = false;
      }
    }
    // this is just to refresh the list view or someother weird stuff we can do later
    else{
      this.scheduleRefresh = !this.scheduleRefresh;
    }
  }

  buttonClicked() {

  }


  async footerButtonClicked(buttonId: string) {
    let flag = await this.setBookingDataService.checkSetBookingDiscardConfirmationRequired().catch(err=>{});
    if(!flag) return;
    switch(buttonId) {
      case 'impact-feed' :
        this.xpService.impactTabChanged('all');
        break;
      case'impact-contacts':
        this.xpService.impactTabChanged('trending');
        break;
      case 'impact-feed' :
        this.xpService.impactTabChanged('all');
        break;
      case'impact-accounts':
        this.xpService.impactTabChanged('accounts');
        break;
      case'impact-saved-contact':
        this.xpService.impactTabChanged('saved');
        break;
      case'impact-location':
        this.xpService.impactTabChanged('location');
        break;
      default:
        break;
    }
  }

  private getAlertMessage(activity: any, hasAccountVisitNestedMeetings = false): string {
    let message: string;
    switch (activity.type) {
      case ActivityType.Sample:
        message = this.translate.instant('POP_R_U_SURE_SCRAP_ALLOC_O');
        break;
      case ActivityType.Email:
        message = this.translate.instant('R_U_SURE_SCRAP_MESG');
        break;
      case ActivityType.PhoneCall:
        message = this.translate.instant('R_U_SURE_SCRAP_PHONE');
        break;
      case ActivityType.FollowUp:
        message = this.translate.instant('POP_R_U_SURE_SCRAP_FOLLOW_UP');
        break;
      case ActivityType.Order:
        message = this.translate.instant('R_U_SURE_SCRAP_O');
        break;
      case ActivityType.SurgeryOrder:
        message = this.translate.instant('R_U_SURE_SCRAP_SO');
        break;
      case ActivityType.ProcedureTracker:
        message = this.translate.instant('R_U_SURE_SCRAP_TRACKER');
        break;
      default: {
        // Appointment
        if (
          hasAccountVisitNestedMeetings
        ) {
          message = this.translate.instant('ACCOUNT_VISIT_NESTED_MEETING_WILL_BE_SCRAPPED_CONFIRM');
        } else {
          message = this.translate.instant('POP_R_U_SURE_SCRAP_MEETING');
        }
        break;
      }
    }
    return message;
  }

  deleteActivity(activity, force?: boolean, forcePopup?: boolean) {
    if (!activity || activity.isCompleted) return;
    const accountVisitRecordCheckResponse = this.activityService.accountVisitRecordCheck(activity);
    // Prevent account visit offline request
    if (this.activityService.accountVisitOfflineCheck(
      accountVisitRecordCheckResponse,
      true,
    )) {
      return;
    }

    const scrapStatusResponse = this.activityService.getActivityScrapStatus(activity);
    let scrapStatus: ActivityScrapStatus = scrapStatusResponse[activity.ID] ?? undefined;
    let shouldHaltScrap = scrapStatus
      ? this.activityService.checkAndNotifyScrapProcessHalt(scrapStatus)
      : false;
    const accountVisitNestedMeetings = accountVisitRecordCheckResponse.isAccountVisitRecord
      ? this.activityService.getAccountVisitNestedMeetings(activity.ID)
      : [];

    if (
      !shouldHaltScrap
      && activity instanceof AppointmentActivity
      && activity.indskr_isparentcall
      && Array.isArray(accountVisitNestedMeetings)
      && accountVisitNestedMeetings.length > 0
    ) {
      // Check for account visit nested meetings
      for (let i = 0; i < accountVisitNestedMeetings.length; i++) {
        const nestedMeeting = accountVisitNestedMeetings[i];
        const nestedMeetingScrapStatus = scrapStatusResponse[nestedMeeting.ID] ?? undefined;
        shouldHaltScrap = !nestedMeetingScrapStatus
          ? shouldHaltScrap
          : this.activityService.checkAndNotifyScrapProcessHalt(nestedMeetingScrapStatus);

        if (shouldHaltScrap) {
          break;
        }
      }
    }

    // Not scrap-able
    if (shouldHaltScrap) {
      return;
    }

    if (activity.type == ActivityType.FollowUp && activity.ownerId != this.authenticationOfflineService.user.systemUserID) {
      return;
    }
    //CWD-3077
    if (activity.type == 'TimeOffRequest') {
      this.trackingService.tracking('TimeOffScrapAgenda', TrackingEventNames.TIMEOFF);
      this.timeOffService.setSelectedTot(activity);
      this.timeOffUtilityService.scrapTimeOff(activity, "agenda");
    }
    else {
      if (force) {

        if (activity.type == ActivityType.PhoneCall) {
          this.deletePhoneCall(activity, true);
          return
        }

        if (this.activityService.selectedActivity) {
          this.websocket.sendRemoteMeetingEnd(this.activityService.selectedActivity, true);
          this.websocket.removeSubscription(this.activityService.selectedActivity);
          this.websocket.meetingInProgress = false;
        }

        this.meetingService.updateMeetingStatus(activity, MeetingStatus.CANCELED)
          .then(() => {
            if (this.navService.getCurrentPageName() === PageName.ActivitiesDetailsPaneComponent && this.device.isMobileDevice) {
              this.navService.popWithPageTracking();
            } else if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
              this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
              if (this.footerService.isActivitiesButton && this.uiService.showRightPane) this.uiService.showRightPane = false;
              else this.events.publish("go-back-to-xperiences", PageName.ActivitiesPageComponent);
            } else if (this.navService.getCurrentMasterPageName() == PageName.OpportunityManagementPage
              || ((this.navService.getCurrentPageName() === PageName.ContactPageComponent || this.navService.getCurrentPageName() === PageName.AccountPageComponent)
                && (this.navService.getPreviousActiveChildNavViewPageName() != undefined))) {
              this.navService.popChildNavPageWithPageTracking();
            } else if (this.uiService.prevView == 'callPlanDetails' || this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent || this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage
              ||(this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab == 'plans' && this.uiService.activitiesPagePlanTabSelectedPlan == 'goalsPlansTab')) {
              this.events.publish('refresh-callplan-activity');
              this.uiService.activeView = 'callPlanDetails';
              this.uiService.showNewActivity = false;
              this.navService.popChildNavPageWithPageTracking();
              this.footerService.initButtons(FooterViews.Contacts);
            }

            this.activityService.removeActivity(activity);
            if (!this.uiService.toolsActivityActive) { this.events.publish('refreshAgenda'); }
            else this.uiService.agendaRefreshRequired = true;
            if (this.uiService.activitiesPageTab === 'day') {
              this.events.publish('activityDeleted');
            }
            this.activityService.publishActivityEvent({ action: "Delete", activity: activity });
            // if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
            // Deleted.
            // Now we need to pop out if we pushed the detail pane
            this.activityService.selectedActivity = undefined;
            if (this.activityService.selectedActivityOnAgenda && activity && this.activityService.selectedActivityOnAgenda.ID === activity.ID) {
              this.activityService.selectedActivityOnAgenda = undefined;
            }
            this.events.publish('initTodo');
          })
          .catch((err) => {
            // this.notificationService.notify("Meeting scrap failed",'Activities List','top',ToastStyle.DANGER);
          });

        return;
      }
      let popupTitle: string;
      let popupMessage: string = this.getAlertMessage(activity, accountVisitNestedMeetings.length > 0);
      let popupAction = this.translate.instant('SCRAP');
      let cancelButtonText = null;

      const isCancelEnabled = this.authenticationOfflineService.user.buSettings && this.authenticationOfflineService.user.buSettings['indskr_cancelprocedurelogwithreason'];
      const isCancelMeetingEnabled = this.authenticationOfflineService.user.buSettings && this.authenticationOfflineService.user.buSettings['indskr_cancelmeetingwithreason'];
      const isdeleteMeetingEnabled =this.authenticationOfflineService.user.buSettings && this.authenticationOfflineService.user.buSettings['indskr_deletemeeting'];

      if (activity instanceof SurgeryOrderActivity && activity.indskr_procedurecontract !== "") {
        this.notificationService.notify(this.translate.instant('PROCEDURE_LOG_SCRAP_NOTIFICATION'), 'procedure log', 'top', 'info');
        return;
      }

      switch (activity.type) {
        case ActivityType.Sample:
          popupTitle = this.translate.instant('SCRAP_ALLOCATION_ORDER');
          break;
        case ActivityType.Email:
          popupTitle = this.translate.instant('EMAIL_ACTIVITY_ALERT_TITLE_SCRAP_MESSGE');
          break;
        case ActivityType.FollowUp:
          popupTitle = this.translate.instant('SCRAP_FOLLOW_UP');
          break;
        case ActivityType.Order:
          popupTitle = this.translate.instant('SCRAP_ORDER');
          break;
        case ActivityType.SurgeryOrder:
          popupTitle = this.translate.instant('OM_SCRAP_LOG');
          if (isCancelEnabled) {
            popupTitle = this.translate.instant('DELETE_OR_CANCEL');
            popupMessage = this.translate.instant('DELETE_OR_CANCEL_PROCEDURE_LOG');
            popupAction = this.translate.instant('DELETE');
          }
          break;
        case ActivityType.ProcedureTracker:
          popupTitle = this.translate.instant('OM_SCRAP_TRACKER');
          break;
        case ActivityType.PhoneCall:
          popupTitle = this.translate.instant('SCRAP_PHONECALL');
          break;
        case ActivityType.CaseIntake:
          popupTitle = this.translate.instant('POP_SCRAP_INQUIRY');
          popupMessage = this.translate.instant('POP_R_U_SURE_SCRAP_SELECTED_INQUIRY');
          break;
        case ActivityType.StoreCheck:
          popupTitle = this.translate.instant('SCRAP_STORE_CHECK');
          popupMessage = this.translate.instant('SCRAP_STORE_CHECK_CONFIRMATION_MSG');
          break;
        case ActivityType.SetBooking:
          popupTitle = this.translate.instant('SCRAP_KIT_BOOKING');
          popupMessage = this.translate.instant('SCRAP_KIT_BOOKING_CONFIRMATION_MSG');
          break;
        default: {
          const isAccountVisitRecordWithNestedMeetings = accountVisitRecordCheckResponse.isAccountVisitNestedMeeting
            && accountVisitNestedMeetings.length > 0;
          popupTitle = isAccountVisitRecordWithNestedMeetings ? this.translate.instant('SCRAP_ACCOUNT_VISIT') : this.translate.instant('SCRAP_MEETING');

          if ((isCancelMeetingEnabled && isdeleteMeetingEnabled) || (!isCancelMeetingEnabled && isdeleteMeetingEnabled)) {
            popupTitle = this.translate.instant('DELETE_OR_CANCEL');
            popupMessage = this.translate.instant('DELETE_OR_CANCEL_MEETING');
            popupAction = this.translate.instant('DELETE_PROCEDURE_LOG_BTN_TEXT');
            cancelButtonText = this.translate.instant('CANCEL_PROECEUDURE_LOG_BTN_TEXT');
          }

          if (scrapStatus && scrapStatus == ActivityScrapStatus.HASOPENINMEETINGALLOCATIONS) {
            if (isAccountVisitRecordWithNestedMeetings) {
              popupMessage = popupMessage + ' ' + this.translate.instant('DRAFT_MEETING_HAS_OPEN_ALLOCATIONS_POPUP_MESSAGE');
            } else {
              popupMessage = this.translate.instant('DRAFT_MEETING_HAS_OPEN_ALLOCATIONS_POPUP_MESSAGE');
            }
          }
          break;
        }
      }

      this.alertService.showAlert({
        header: popupTitle,
        message: popupMessage
      }, popupAction, cancelButtonText
      ).then(async (res) => {


        if (isCancelEnabled && activity.type == ActivityType.SurgeryOrder && res.role === 'cancel') {
          this.cancelSurgeryOrder(activity);
          return;
        }

        if ((isCancelMeetingEnabled && isdeleteMeetingEnabled && activity.type == ActivityType.Appointment && res.role === 'cancel')) {
          this.cancelMeeting(activity);
          return;
        }
        if ((!isCancelMeetingEnabled && isdeleteMeetingEnabled && activity.type == ActivityType.Appointment && res.role === 'cancel')) {
          this.scrapOrCancelWithOutReason(activity, scrapStatus, accountVisitRecordCheckResponse);
          return;
        }

        if (res.role == "ok") {
          this.activityService.selectedActivityAtHome = undefined;
          if (activity.type == ActivityType.Sample) {
            if (this.device.isBackgroundUploadInProgress && this.activityService.hasOfflineSampleOrderData(activity.ID)) return;
            this.uiService.displayLoader();
            let payload: CreateSampleDropRequestBody = new CreateSampleDropRequestBody(activity);
            payload.statecode = 2;
            payload.statuscode = 3;
            (activity as SampleActivity).state = 2;
            (activity as SampleActivity).status = 3;
            this.sampleDataService.updateSampleActivity(payload, activity).then(() => {
              this.events.publish("allocationOrderActivityDeleted", activity);
              if ((activity as SampleActivity).appointmentID && this.activityService.samplingToInMeetingActivityMapping.has((activity as SampleActivity).appointmentID)) {
                this.activityService.samplingToInMeetingActivityMapping.delete((activity as SampleActivity).appointmentID);
              }
              if (this.navService.getCurrentPageName() === PageName.NewSampleActivityComponent && this.device.isMobileDevice) {
                this.navService.popWithPageTracking();
              }
              if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
                this.activityService.selectedActivity = undefined;
                this.uiService.showNewActivity = false;
                this.navService.popChildNavPageWithPageTracking();
                this.footerService.initButtons(FooterViews.Contacts);
              }
              if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
                // if (this.footerService.isActivitiesButton &&  this.uiService.showRightPane) {
                this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
                if (this.footerService.isActivitiesButton) {
                  this.uiService.showRightPane = false;
                  this.events.publish('scrollToDate');
                } else {
                  this.events.publish("go-back-to-xperiences", PageName.ActivitiesPageComponent);
                }
              }
              //this.activityService.removeActivity(activity);
              if (!this.uiService.toolsActivityActive) {
                this.events.publish("refreshAgenda");
              }
              else {
                this.uiService.agendaRefreshRequired = true;
              }
              this.activityService.publishActivityEvent({ action: "Delete", activity: activity });
              // if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
              if (this.activityService.selectedActivity && activity && this.activityService.selectedActivity.ID === activity.ID) {
                this.activityService.selectedActivity = undefined;
                this.activityService.selectedActivityOnAgenda = undefined;
                this.uiService.activeView = undefined;
              }
              if (this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent || this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage) {
                this.uiService.showNewActivity = false;
                this.uiService.activeView = 'callPlanDetails';
                this.callplanService.callPlanPageView = 'CallPlanDetail';
                this.navService.popChildNavPageWithPageTracking();
              }
              this.uiService.dismissLoader();
              return;
            }).catch(() => {
              this.uiService.dismissLoader();
              // this.notificationService.notify("Allocation scrap failed",'Activities List','top',ToastStyle.DANGER);
              return;
            })
          }
          else if (activity.type == ActivityType.Email) {
            this.uiService.displayLoader();
            if (this.device.isBackgroundUploadInProgress && this.activityService.hasOfflineEmailData(activity.ID)) return;
            this.emailService.scrapEmailActivity(activity).then(() => {
              if (this.navService.getCurrentPageName() === PageName.EmailDetailsPageComponent && this.device.isMobileDevice) {
                this.navService.popWithPageTracking();
              }
              if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent && this.uiService.showRightPane) {
                this.uiService.showRightPane = false;
                this.events.publish('scrollToDate');
                this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
              }
              this.activityService.removeActivity(activity);
              if (!this.uiService.toolsActivityActive) {
                this.events.publish('refreshAgenda');

              } else {
                this.uiService.agendaRefreshRequired = true;
              }
              if (this.uiService.activitiesPageTab === 'day') {
                this.events.publish('activityDeleted', this.activityService.selectedActivity);
              }
              this.activityService.publishActivityEvent({ action: "Delete", activity: activity });
              // if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
              if (this.activityService.selectedActivity && activity && this.activityService.selectedActivity.ID === activity.ID) {
                this.activityService.selectedActivity = undefined;
                this.activityService.selectedActivityOnAgenda = undefined;
                this.uiService.activeView = undefined;
                this.events.publish('scrollToDate');
                this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
              }
              this.uiService.dismissLoader();
              return;
            }).catch(() => {
              this.uiService.dismissLoader();
              return;
            });
          }
          else if (activity.type === ActivityType.FollowUp) {
            this.uiService.displayLoader();
            if (this.device.isBackgroundUploadInProgress && activity.pendingPushToDynamics) return;
            let id = activity.ID;
            (<FollowUpActivity>activity).pendingPushToDynamics = true;
            this.followUpDataService.scrapFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true }, (<FollowUpActivity>activity)).then(async succ => {
              this.activityService.publishActivityEvent({ action: "Delete", activity: activity });
              this.events.publish('followupActionDeleted', activity);
              this.closeFollowUpDetailPageAfterOperation();
              this.uiService.dismissLoader();
            }).catch(err => {
              this.uiService.dismissLoader();
              //Handle error scenario
            })
          } else if (activity.type == ActivityType.Order ||  activity.type == ActivityType.ProcedureTracker) {
            this.uiService.displayLoader();
            if (this.device.isBackgroundUploadInProgress && activity.pendingPushToDynamics) return;
            (<OrderActivity>activity).pendingPushToDynamics = true;
            this.orderActivityDataService.updateOrderActivityStatus({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true, operationDetail: { code: 'scraporderactivity', message: 'Scrap Order' } }, (<OrderActivity>activity)).then(async succ => {
              if (!this.uiService.toolsActivityActive) {
                this.events.publish('refreshAgenda');
              } else {
                this.uiService.agendaRefreshRequired = true;
              }

              this.uiService.activeView = '';
              this.uiService.showRightPane = false;
              if (this.uiService.activitiesPageTab === 'day') {
                this.events.publish('activityDeleted', activity);
              }
              this.activityService.publishActivityEvent({ action: "Delete", activity: activity });
              // if (activity.type == ActivityType.SurgeryOrder) { this.events.publish('surgeryOrderActivityDeleted', activity); }
              if (activity.type == ActivityType.ProcedureTracker) { this.events.publish('procedureTrackerActivityDeleted', activity); }
              // if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
              if (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID) {
                this.activityService.selectedActivity = null;
                this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, null, {
                  deviceString: this.device.deviceFlags.ios ? 'ios' : 'android'
                });
              }
            }).catch(err => {
              //Handle error scenario
            });
            this.uiService.dismissLoader();
          } else if(activity.type == ActivityType.SurgeryOrder){
            if(activity.indskr_procedurecontract) return;
            this.uiService.displayLoader();
            if (this.device.isBackgroundUploadInProgress && activity.pendingPushToDynamics) return;
            (<SurgeryOrderActivity>activity).pendingPushToDynamics = true;
            const isSelected = this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID;
            await this.surgeryOrderService.updateOrderActivityStatus({
              onDynamics: !this.device.isOffline,
              onLocalDatabase: true,
              onLocalCopy: true,
              operationDetail: { code: 'scrapsurgeryorderactivity', message: this.translate.instant('OM_UPDATE_O_ACTIVITY'), }
            }, (<SurgeryOrderActivity>activity)).then((success) => {
              if (!this.uiService.toolsActivityActive) {
                this.events.publish('refreshAgenda');
              } else {
                this.uiService.agendaRefreshRequired = true;
              }
              this.uiService.activeView = '';
              this.uiService.showRightPane = false;
              if (this.uiService.activitiesPageTab === 'day') {
                this.events.publish('activityDeleted', activity);
              }
              this.activityService.publishActivityEvent({ action: "Delete", activity: (<SurgeryOrderActivity>activity) });
              if (isSelected) {
                this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, null, {
                  deviceString: this.device.deviceFlags.ios ? 'ios' : 'android'
                });
              }
              this.uiService.dismissLoader();
            }).catch(err => {
              //Handle error scenario
            });
          }
          else if (activity.type == ActivityType.PhoneCall) {
            // (<PhoneActivity>activity).pendingPushToDynamics = true;
            if (this.device.isBackgroundUploadInProgress && this.activityService.hasOfflinePhoneCallData(activity.ID)) return;
            this.deletePhoneCall(activity)
          }
          else if (activity.type == ActivityType.CaseIntake) {
            this.uiService.displayLoader()
            await this.caseManagementService.scrapCustomerInquiry(activity as CaseActivity);
            this.closeActivityDetailsPane();
            this.uiService.dismissLoader();
          } else if (activity.type === ActivityType.StoreCheck) {
            if (this.device.isOffline) {
              this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'Store Check Activity', 'top', ToastStyle.DANGER);
              return;
            }
            await this.uiService.displayLoader();
            await this.storeCheckService.scrapStoreCheck(activity);
            this.storeCheckService.setCurrentStoreCheckActivity(null);
            if (!this.uiService.toolsActivityActive) {
              this.events.publish("refreshAgenda");
            } else this.uiService.agendaRefreshRequired = true;
            this.activityService.publishActivityEvent({ action: 'Delete', activity });
            if (this.activityService.selectedActivity?.ID == activity.ID) {
              this.uiService.showRightPane = false;
              this.activityService.selectedActivity = null;
              this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
            }
            await this.uiService.dismissLoader();
          } else if (activity.type === ActivityType.SetBooking) {
            if (this.device.isOffline) {
              this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'Store Check Activity', 'top', ToastStyle.DANGER);
              return;
            }
            await this.uiService.displayLoader();
            const action:OperationDetail = {
              onDynamics: true,
              onLocalDatabase: true,
              onLocalCopy: true,
              operationDetail: {
                code: 'scrapsetbooking',
                message: 'Scrap Set Booking Activity'
              }
            };
            let draftkitbookingscrapflow:boolean = false;
            if((activity as SetBookingActivity).indskr_status == SetBookingStatus.DRAFT){
              draftkitbookingscrapflow = true;
              (activity as SetBookingActivity).status = 1;
              (activity as SetBookingActivity).serviceDTO = {
                indskr_setbookingid: activity.ID,
                statecode: 1
              };
            }
            else{
              (activity as SetBookingActivity).indskr_status = SetBookingStatus.PENDINGCANCEL;
              (activity as SetBookingActivity).statusString = "Pending Cancel";
              (activity as SetBookingActivity).serviceDTO = {
                indskr_setbookingid: activity.ID,
                indskr_status: SetBookingStatus.PENDINGCANCEL,
              };
            }
            await this.setBookingDataService.updateSetBookingActivity(action,[activity],new Date().getTime()).then(async (res)=> {
              if(draftkitbookingscrapflow){
                this.activityService.removeActivity(activity);
              }
              if (!this.uiService.toolsActivityActive) {
                this.events.publish("refreshAgenda");
              } else this.uiService.agendaRefreshRequired = true;
              if(!draftkitbookingscrapflow && this.authenticationService.user.buSettings['indskr_displaycancelledkitbookingoncalendar']) {
                if(forcePopup) this.navService.popChildNavPageWithPageTracking();
                this.events.publish('initSetBookingDisplayForm');
              } else {
                if (this.navService.getActiveChildNavViewPageName() == PageName.SetBookingActivityDetailsComponent) {
                  this.uiService.showRightPane = false;
                  this.activityService.selectedActivity = null;
                  this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
                }
                if(this.navService.getActiveChildNavViewPageName() == PageName.DynamicFormComponent){
                  this.uiService.showRightPane = false;
                  this.activityService.selectedActivity = null;
                  this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
                }
              }
              this.uiService.dismissLoader();
            });
          }
          else {
            if ((isdeleteMeetingEnabled && activity.type == ActivityType.Appointment)) {
              this.deleteMeetingnewFlow(activity)
              return;
            }
            if ((isCancelMeetingEnabled && !isdeleteMeetingEnabled && activity.type == ActivityType.Appointment)) {
              this.cancelMeeting(activity);
              return;
            }
            this.scrapOrCancelWithOutReason(activity,scrapStatus,accountVisitRecordCheckResponse);
          }
        }
      });
    }
  }

  /**
 *
 * @param activity
 * Deletes the Phone call Activity
 */
  private deletePhoneCall(activity, force?: boolean) {

    if (!force) {
      this.uiService.displayLoader();
    }
    const newDate = new Date().getTime();

    this.phonecallDataService.updatePhonecallActivityStatus(activity, PhonecallStatus.CANCELED, newDate)
      .then(() => {
        this.events.publish('phoneCallActivityDeleted', activity);
        if ((this.navService.getCurrentMasterPageName() === PageName.AccountManagementPageComponent &&
          this.navService.getActiveChildNavViewPageName() === PageName.PhoneCallDetailsComponent) ||
          (this.navService.getCurrentMasterPageName() === PageName.AccountPageComponent &&
            this.navService.getActiveChildNavViewPageName() === PageName.PhoneCallDetailsComponent)) {
          this.uiService.dismissLoader();
          this.navService.popChildNavPageWithPageTracking();
          this.activityService.removeActivity(activity);
          if (!this.uiService.toolsActivityActive) {
            this.events.publish("refreshAgenda");
          }
          else {
            this.uiService.agendaRefreshRequired = true;
            this.events.publish('phonecall:refresh');
          }
          this.footerService.initButtons('');
        }
        else {

          this.activityService.removeActivity(activity);
          if (!this.uiService.toolsActivityActive) {
            this.events.publish("refreshAgenda");
          }
          else {
            this.uiService.agendaRefreshRequired = true;
            this.events.publish('phonecall:refresh');
          }
          if (this.uiService.activitiesPageTab === 'day') {
            this.events.publish('activityDeleted', activity);
          }
          this.activityService.publishActivityEvent({ action: "Delete", activity: activity });
          // if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
          if (!force) {
            this.uiService.dismissLoader();
            if (this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent) {
              if (this.uiService.showCancelDoneOnActivityDetails) {
                this.uiService.showCancelDoneOnActivityDetails = false;
              }
              this.uiService.showNewActivity = false;
              this.uiService.activeView = 'contactDetails';
              this.navService.popChildNavPageWithPageTracking();
              this.footerService.initButtons(FooterViews.Contacts);
              return
            } else if (this.navService.getCurrentMasterPageName() === PageName.CallPlanComponent || this.navService.getCurrentMasterPageName() === PageName.CallPlanDetails ||this.navService.getCurrentMasterPageName() === PageName.CustomerCallPlanPage || this.navService.getCurrentMasterPageName() === PageName.CustomerCallPlanDetails) {
              if (this.uiService.showCancelDoneOnActivityDetails) {
                this.uiService.showCancelDoneOnActivityDetails = false;
              }
              this.uiService.showNewActivity = false;
              this.uiService.activeView = 'callPlanDetails';
              this.callplanService.callPlanPageView = 'CallPlanDetail';
              this.navService.popChildNavPageWithPageTracking();
              this.footerService.initButtons(FooterViews.CallPlanDetails);
              return
            } else if (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans') {
              this.uiService.activeView = 'ActivitiesPageRightPaneNav';
              this.navService.popChildNavPageWithPageTracking();
              this.footerService.initButtons('');
              return
            }
            this.uiService.activeView = '';
            this.uiService.showRightPane = false;
            if (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID) {
              this.activityService.selectedActivity = null;
              this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
            }

          }
        }
      }).catch(err => {
        //Handle error scenario
        if (!force) {
          this.uiService.dismissLoader();
        }
      });
  }

  private closeActivityDetailsPane() {
    if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent && this.activityService.prevSelectedActivity as SetBookingActivity) {
      this.navService.popChildNavPageWithPageTracking();
      this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      this.activityService.selectedActivity = this.activityService.prevSelectedActivity;
      this.activityService.prevSelectedActivity = undefined;
      this.events.publish('refresh-kit-booking-activities');
    } else if (this.navService.getCurrentPageName() === PageName.ActivitiesDetailsPaneComponent && this.device.isMobileDevice) {
      this.navService.popWithPageTracking();
    } else if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
      if (this.footerService.isActivitiesButton) { // && this.uiService.showRightPane) {
        this.uiService.showRightPane = false;
        this.events.publish('scrollToDate');
        if (this.uiService.activitiesPageTab != 'plans') {
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        }
        else {
          this.navService.popChildNavPageWithPageTracking().then(() => {
            this.footerService.initButtons('');
          });
        }
      }
      else this.events.publish("go-back-to-xperiences", PageName.ActivitiesPageComponent);
    }
    if (this.navService.getCurrentPageName() == PageName.ContactPageComponent) {
      if (this.uiService.showCancelDoneOnActivityDetails) {
        this.uiService.showCancelDoneOnActivityDetails = false;
      }
      this.uiService.timelineRefreshRequired = true;
      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'contactDetails';
      this.navService.popChildNavPageWithPageTracking();
      this.footerService.initButtons(FooterViews.Contacts);
    } else if (this.navService.getCurrentMasterPageName() == PageName.AccountManagementPageComponent && this.navService.getActiveChildNavViewPageName() == PageName.ActivitiesDetailsPaneComponent) {
      this.footerService.initButtons("accountPlansDetail");
      this.navService.popChildNavPageWithPageTracking();
    } else if (this.navService.getCurrentMasterPageName() == PageName.AccountPageComponent &&
      this.navService.getActiveChildNavViewPageName() == PageName.ActivitiesDetailsPaneComponent) {
      this.navService.popChildNavPageWithPageTracking();
    } else if (this.navService.getCurrentMasterPageName() == PageName.ScientificActivityPage) {
      this.footerService.initButtons('');
      this.navService.popChildNavPageWithPageTracking();
    } else if (this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent || this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage) {
      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'callPlanDetails';
      this.callplanService.callPlanPageView = 'CallPlanDetail';
      this.events.publish('activity-scrapped-created-from-call-plan');
      this.navService.popChildNavPageWithPageTracking();
    } else if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab == 'plans') {
      // this.footerService.initButtons('');
      // this.navService.popChildNavPageWithPageTracking();
      // this.uiService.activeView = 'ActivitiesPageRightPaneNav';
    } else if (this.navService.getCurrentMasterPageName() == PageName.OpportunityManagementPage) {
      this.footerService.initButtons('');
      this.navService.popChildNavPageWithPageTracking();
    } else if (this.navService.getCurrentMasterPageName() == PageName.MarketingPlanManagementPageComponent) {
      this.footerService.initButtons('');
      this.navService.popChildNavPageWithPageTracking();
    }
  }

  private async _scrapDraftAllocationOrdersInMeeting(activity: Activity): Promise<any> {
    return new Promise((resolve, reject) => {
      if (activity.type == ActivityType.Appointment && activity.ID) {
        // Check the status of In Meeting Allocations
        for (let idx = 0; idx < this.activityService.sampleActivities.length; idx++) {
          let sampleOrder: SampleActivity = this.activityService.sampleActivities[idx];
          if (sampleOrder.appointmentID && sampleOrder.appointmentID === activity.ID && sampleOrder.statusString !== 'Completed') {
            let payload: CreateSampleDropRequestBody = new CreateSampleDropRequestBody(sampleOrder);
            payload.statecode = 2;
            payload.statuscode = 3;
            sampleOrder.state = 2;
            sampleOrder.status = 3;
            this.sampleDataService.updateSampleActivity(payload, sampleOrder).catch(error => {
              // To Do Error Handling
            });
          }
        }
        resolve('');
      }
    });
  }

  private closeFollowUpDetailPageAfterOperation(): void {
    let activChildNav: ChildNavNames = this.navService.getActiveChildNavName();
    if(this.followUpDataService.inMeetingFollowupActionActivity) return;
    switch (activChildNav) {
      case ChildNavNames.ActivitiesPageNavigation:
        this.activityService.publishActivityEvent({ action: "Delete", activity: this.activityService.selectedActivity });
        this.activityService.selectedActivity = null;
        if (this.uiService.activitiesPagePlanTabSelectedPlan === 'scientificPlans' || this.uiService.activitiesPagePlanTabSelectedPlan === 'accountPlansTab' || this.uiService.activitiesPagePlanTabSelectedPlan === 'goalsPlansTab') {
          this.navService.popChildNavPageWithPageTracking().then(() => {
            this.events.publish("refreshFollowup");
          });
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.footerService.initButtons('');
        }
        else if (this.footerService.isActivitiesButton) {
          this.uiService.activeView = '';
          this.uiService.showRightPane = false;
          if (this.uiService.activitiesPageTab === 'day') {
            this.events.publish('activityDeleted', this.activityService.selectedActivity);
          }
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        } else {
          this.navService.popChildNavPageWithPageTracking();
          this.events.publish("go-back-to-xperiences", PageName.FollowUpActionDetailComponent);
        }
        break;
      case ChildNavNames.AccountManagementNavigation:
        this.navService.popChildNavPageWithPageTracking();
        if (this.navService.getActiveChildNavViewPageName() == PageName.NewActivityComponent) {
          this.navService.popChildNavPageWithPageTracking();
        }
        this.activityService.selectedActivity = null;
        this.footerService.initButtons(FooterViews.AccountPlanObjectives)
        break;
      case ChildNavNames.ContactPageNavigation:
        this.navService.popChildNavPageWithPageTracking();
        this.footerService.initButtons(FooterViews.Contacts)
        break;
      case ChildNavNames.AccountPageNavigation:
        this.navService.popChildNavPageWithPageTracking();
        this.footerService.initButtons(FooterViews.Accounts)
        break;
      case ChildNavNames.ScientificActivityNavigation:
        this.navService.popChildNavPageWithPageTracking().then(() => {
          this.events.publish("refreshFollowup");
        });
        this.footerService.initButtons('');
        break;
      case ChildNavNames.OpportunityManagementNavigation:
        this.navService.popChildNavPageWithPageTracking();
        this.footerService.initButtons('');
        break;
      case ChildNavNames.MarketingPlanNavigation:
        this.navService.popChildNavPageWithPageTracking();
        this.footerService.initButtons('');
        break;
    }
    // this.events.publish('refreshAgenda');
    if (!this.uiService.toolsActivityActive) {
      this.events.publish("refreshAgenda");
    }
    else {
      this.uiService.agendaRefreshRequired = true;
    }
  }

  private async scrapAccountVisit(activity: AppointmentActivity): Promise<boolean> {
    let success = false;

    const payload: AppointmentActivity[] = [];
    if (activity.indskr_isparentcall) {
      // Parent Account Visit
      // Scrap parent
      payload.push(new AppointmentActivity({
        _id: activity._id ?? DB_KEY_PREFIXES.MEETING_ACTIVITY + activity.ID,
        _rev: activity._rev ?? undefined,
        activityid: activity.ID,
        offlineMeetingId: activity.offlineMeetingId,
        subject: activity.subject,
        scheduledstart: activity.scheduledStart,
        scheduledend: activity.scheduledEnd,
        indskr_isparentcall: true,
        stateCode: 2,
      }));

      const nestedMeetings = this.activityService.getAccountVisitNestedMeetings(activity.ID);
      if (
        Array.isArray(nestedMeetings)
        && nestedMeetings.length > 0
      ) {
        // Scrap children
        payload.push(
          ...nestedMeetings.map(a => {
            return new AppointmentActivity({
              _id: a._id ?? DB_KEY_PREFIXES.MEETING_ACTIVITY + a.ID,
              _rev: a._rev ?? undefined,
              activityid: a.ID,
              offlineMeetingId: a.offlineMeetingId,
              subject: a.subject,
              scheduledstart: a.scheduledStart,
              scheduledend: a.scheduledEnd,
              indskr_parentcallid: a.indskr_parentcallid,
              stateCode: 2,
            });
          })
        );
      }
    } else if (activity.indskr_parentcallid) {
      // Scrap nested meeting
      payload.push(
        new AppointmentActivity({
          _id: activity._id ?? DB_KEY_PREFIXES.MEETING_ACTIVITY + activity.ID,
          _rev: activity._rev ?? undefined,
          activityid: activity.ID,
          offlineMeetingId: activity.offlineMeetingId,
          subject: activity.subject,
          scheduledstart: activity.scheduledStart,
          scheduledend: activity.scheduledEnd,
          indskr_parentcallid: activity.indskr_parentcallid,
          stateCode: 2,
        })
      );

      // Remove contact from parent
      // Since the crud function requires fully loaded activity object as payload,
      // we have to make sure to append the meeting details to the activity object.
      const parent = await this.activityDataService
        .getNewDetailAppendedAppointmentActivityInstanceFromLocalDB(activity.indskr_parentcallid);
      if (
        !parent
        || !parent.contacts
      ) {
        console.error('scrapAccountVisit: Could not find the parent account visit or contact is not available.', activity, parent);
        return success;
      }

      // ! meed
      const contact = Array.isArray(activity.contacts) ? activity.contacts[0] : undefined;
      if (contact) {
        const contactIdx = parent.contacts?.findIndex(c => c.ID === contact.ID) ?? -1;
        if (contactIdx >= 0) {
          parent.contacts.splice(contactIdx, 1);
        }
      }
      // if (!contact) {
      //   console.error('scrapAccountVisit: Could not find the contact from the nested meeting.', activity, parent);
      //   // return success;
      // }else{
      //   const contactIdx = parent.contacts?.findIndex(c => c.ID === contact.ID) ?? -1;
      //   if (contactIdx < 0) {
      //     console.error('scrapAccountVisit: Could not find the contact from the parent.', activity, parent);
      //     // return success;
      //   } else {
      //     parent.contacts.splice(contactIdx, 1);
      //   }
      // }

      payload.unshift(parent);
    } else {
      console.error('scrapAccountVisit: not an account visit?', activity);
    }

    try {
      success = payload.length > 0 ? await this.meetingService.crudMeetingsOnlineWithOfflineUploadEndpoint(payload) : false;
    } catch (error) {
      console.error('scrapAccountVisit: ', error);
    }

    return success;
  }

  invokeFooterButtonClick({buttonId, isAgendaFooter}: { buttonId: string, isAgendaFooter?: boolean }) {
    const buttons = isAgendaFooter ? this.agendaFooterService.actionButtons : this.footerService.actionButtons;
    const button = buttons.find(b => b.id === buttonId);
    button && this.footerToolbarComponent.buttonEventHandler(button);
  }

  private async cancelSurgeryOrder(activity: SurgeryOrderActivity) {
    try {
      if(activity.indskr_procedurecontract) return;

      const response = await this.surgeryOrderService.showCancelSurgeryOrderPopover('');
      const isSelected = this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID;
      if (response && response.role && response.role == 'ok') {
        this.uiService.displayLoader();
        if (response.selectedOtherItem) {
          activity.indskr_cancellationreasonother = response.selectedOtherItem
        } else {
          activity.indskr_reasonforcancellation = response.inputs.value;
          activity.cancellationreason = response.inputs.name
        }
        await this.surgeryOrderService.updateOrderActivityStatus({
          onDynamics: !this.device.isOffline,
          onLocalDatabase: true,
          onLocalCopy: true,
          operationDetail: { code: 'cancelsurgeryorder', message: this.translate.instant('OM_UPDATE_O_ACTIVITY'), }
        }, activity);
        if (!this.uiService.toolsActivityActive) { this.events.publish('refreshAgenda'); }
        else this.uiService.agendaRefreshRequired = true;

        if (this.authenticationOfflineService.user.buSettings &&
          !this.authenticationOfflineService.user.buSettings['indskr_displaycancelledprocedurelogoncalendar']) {
          this.uiService.activeView = '';
          this.uiService.showRightPane = false;
          if (this.uiService.activitiesPageTab === 'day') this.events.publish('activityDeleted', activity);
          this.activityService.publishActivityEvent({ action: "Delete", activity: (<SurgeryOrderActivity>activity) });
          if (isSelected) {
            this.activityService.selectedActivityAtHome = null;
            this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, null, {
              deviceString: this.device.deviceFlags.ios ? 'ios' : 'android'
            });
          }
        }
        this.uiService.dismissLoader();
      }
    } catch (error) {
      console.log('Error white showing cancel popover', error);
    }
  }

  private async cancelMeeting(activity: AppointmentActivity) {
    try {
      const response = await this.activityService.showCancelMeetingPopover('');
      const isSelected = this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID;
      if (response && response.role && response.role == 'ok') {
        this.uiService.displayLoader();
        if (response.selectedOtherItem) {
          activity.indskr_reasonforcancellation = response.inputs.value;
          activity.indskr_cancellationreasonother = response.selectedOtherItem
        } else {
          activity.indskr_reasonforcancellation = response.inputs.value;
          activity.cancellationreason = response.inputs.name
        }
        this.meetingService.updateMeetingStatus(activity, MeetingStatus.CANCELED)
          .then(() => {
            if (!this.uiService.toolsActivityActive) { this.events.publish('refreshAgenda'); }
            else this.uiService.agendaRefreshRequired = true;
            this.events.publish("detectChangesOnActivityDetails");
            this.footerService.initButtons(FooterViews.Activities);
            if (this.authenticationOfflineService.user.buSettings &&
              !this.authenticationOfflineService.user.buSettings['indskr_displaycancelledmeetingoncalendar']) {
              this.uiService.activeView = '';
              this.uiService.showRightPane = false;
              this.activityService.publishActivityEvent({ action: "Delete", activity: activity });
              this.activityService.selectedActivity = undefined;
              if (this.activityService.selectedActivityOnAgenda && activity && this.activityService.selectedActivityOnAgenda.ID === activity.ID) {
                this.activityService.selectedActivityOnAgenda = undefined;
              }
            }
            this.uiService.dismissLoader();

          })
          .catch((err) => {
            // this.notificationService.notify("Meeting scrap failed",'Activities List','top',ToastStyle.DANGER);
          });

      }
    } catch (error) {
      console.log('Error white showing cancel popover', error);
    }
  }

  async deleteMeetingnewFlow(activity) {
    if (!this.device.isOffline) {
      this.uiService.displayLoader();
      await this.activityDataService.deleteMeeting(activity.ID).then(async (success) => {
        await this.activityService.removeActivity(activity).catch(err => {
          this.uiService.dismissLoader();
          console.error('deleteActivity: ', err);
        });
        if (!this.uiService.toolsActivityActive) {
          this.events.publish('refreshAgenda');
        } else this.uiService.agendaRefreshRequired = true;
        // this.uiService.activeView = '';
        this.activityService.selectedActivity = null;
        if (this.uiService.activitiesPageTab === 'day' || this.uiService.activitiesPageTab === 'plans') {
          this.events.publish('activityDeleted', activity);
        }
        this.activityService.publishActivityEvent({ action: "Delete", activity: activity });
        // if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
        if (this.activityService.selectedActivityOnAgenda && activity && this.activityService.selectedActivityOnAgenda.ID === activity.ID) {
          this.activityService.selectedActivityOnAgenda = undefined;
        }
        this.closeActivityDetailsPane();
        this.uiService.dismissLoader();
      }).catch(err => {
        //Handle error scenario
      });
    } else {
      this.uiService.displayLoader();
      this.meetingService.updateMeetingStatus(activity, MeetingStatus.CANCELED, null, true)
        .then(async () => {
          await this.activityService.removeActivity(activity).catch(err => {
            this.uiService.dismissLoader();
            console.error('deleteActivity: ', err);
          });
          if (!this.uiService.toolsActivityActive) {
            this.events.publish('refreshAgenda');
          } else this.uiService.agendaRefreshRequired = true;
          // this.uiService.activeView = '';
          this.activityService.selectedActivity = null;
          if (this.uiService.activitiesPageTab === 'day' || this.uiService.activitiesPageTab === 'plans') {
            this.events.publish('activityDeleted', activity);
          }
          this.activityService.publishActivityEvent({ action: "Delete", activity: activity });
          // if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
          if (this.activityService.selectedActivityOnAgenda && activity && this.activityService.selectedActivityOnAgenda.ID === activity.ID) {
            this.activityService.selectedActivityOnAgenda = undefined;
          }
          this.closeActivityDetailsPane();
          this.uiService.dismissLoader();


        })
        .catch((err) => {
          // this.notificationService.notify("Meeting scrap failed",'Activities List','top',ToastStyle.DANGER);
        });


    }

  }

  async scrapOrCancelWithOutReason(activity, scrapStatus, accountVisitRecordCheckResponse) {
    if (this.device.isBackgroundUploadInProgress && this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) return;
    this.uiService.displayLoader();
    if (scrapStatus && scrapStatus == ActivityScrapStatus.HASOPENINMEETINGALLOCATIONS) {
      // Scrap Open Allocation Orders
      await this._scrapDraftAllocationOrdersInMeeting(activity).catch(error => {
        // To Do Error Handling
      });
    }
    if (this.activityService.selectedActivity) {
      this.websocket.sendRemoteMeetingEnd(this.activityService.selectedActivity, true);
      this.websocket.removeSubscription(this.activityService.selectedActivity);
      this.websocket.meetingInProgress = false;
    }

    if (
      accountVisitRecordCheckResponse.isAccountVisitRecord
      || accountVisitRecordCheckResponse.isAccountVisitNestedMeeting
    ) {
      await this.scrapAccountVisit(activity);
    } else {
      await this.meetingService.updateMeetingStatus(activity, MeetingStatus.CANCELED).catch((err) => {
        this.uiService.dismissLoader();
        console.error('deleteActivity: ', err);
      });
    }
    if (this.authenticationOfflineService.user.buSettings &&
      !this.authenticationOfflineService.user.buSettings['indskr_displaycancelledmeetingoncalendar']) {
      await this.activityService.removeActivity(activity).catch(err => {
        this.uiService.dismissLoader();
        console.error('deleteActivity: ', err);
      });
      this.activityService.selectedActivity = null;
      if (this.uiService.activitiesPageTab === 'day' || this.uiService.activitiesPageTab === 'plans') {
        this.events.publish('activityDeleted', activity);
      }
      this.activityService.publishActivityEvent({ action: "Delete", activity: activity });
      // if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
      if (this.activityService.selectedActivityOnAgenda && activity && this.activityService.selectedActivityOnAgenda.ID === activity.ID) {
        this.activityService.selectedActivityOnAgenda = undefined;
      }
      this.closeActivityDetailsPane();
      this.uiService.dismissLoader();
    }
    if (!this.uiService.toolsActivityActive) {
      this.events.publish('refreshAgenda');
    } else this.uiService.agendaRefreshRequired = true;
    this.events.publish("detectChangesOnActivityDetails");
    this.footerService.initButtons(FooterViews.Activities);
    this.events.publish('initTodo');
    this.uiService.dismissLoader();
  }

  private async integrateChat() {
    try {
      this.geoLocation.getCurrentPosition({ maximumAge: 0, enableHighAccuracy: true, timeout: 5000 }).then((posData) => {
        if (posData) {
          this.currentCoords = {
            latitude: posData.coords.latitude,
            longitude: posData.coords.longitude
          }
        }
      }, (error) => {
        this.currentCoords = undefined;
        console.log('geolocation error', error);
      })
      ReactDOM.render(React.createElement(Widget, {
        title: 'Exeevo Copilot',
        subtitle: '',
        loading: true,
        profileAvatar: './assets/imgs/copilot.png',
        emojis: false,
        handleToggle: toggle =>{
          console.log(toggle);
          if(toggle){
            //@ts-ignore
            this.speechRecognizer = new webkitSpeechRecognition();
            this.speechRecognizer.continuous = true;
            this.speechRecognizer.lang = "en-US";
            this.speechRecognizer.interimResults = true;
            this.speechRecognizer.maxAlternatives = 1;
            if(!this.directline){
              this.initDirectLine();
            }
            setTimeout(() => {
              this.switchMicButon();
            }, 0);
          }
          else{
            this.speechRecognizer.stop();
            this.switchMicButon();
          }
        },
        handleNewUserMessage: message => {
          if (this.directline) {
            this.sendMessagetoBot(message);
            setQuickButtons([]);
          } else {
            alert("Chat connection not yet established.");
          }
        },
        handleQuickButtonClicked: (data)=>{
          addUserMessage(data);
          this.sendMessagetoBot(data);
          setQuickButtons([]);
        },
        launcherOpenImg: './assets/imgs/copilot.png',
        launcherCloseImg: './assets/imgs/closeGeneeScreen.svg',
      }), document.getElementById('chat'));
    } catch (e) {
      console.log("Connection failed: ", e);
    }
  }

  public initDirectLine(){
    try{
      let directlineAPI = "https://18849895160f4521846bfb0954418f.b2.environment.api.powerplatform.com/powervirtualagents/botsbyschema/crc3b_demoCopilotBot/directline/token?api-version=2022-03-01-preview"
      //let directlineAPI = "https://18849895160f4521846bfb0954418f.b2.environment.api.powerplatform.com/powervirtualagents/botsbyschema/cr415_tableCopilot/directline/token?api-version=2022-03-01-preview"
      //let directlineAPI = "https://18849895160f4521846bfb0954418f.b2.environment.api.powerplatform.com/powervirtualagents/botsbyschema/crc3b_exeevoOmnipresenceCopilotBot/directline/token?api-version=2022-03-01-preview"
      this.http.get(directlineAPI)
      .toPromise().then((res)=>{
        this.directline = new DirectLine({
          token: res['token'],
        })
        addResponseMessage('Hello, I am your copilot assistant. How can I help you?');
        this.directline.activity$.subscribe(activity => {
          if(activity.from?.name.toLowerCase().includes("bot") && activity['text']){
            if(this.isBotTyping){
              toggleMsgLoader();
              this.isBotTyping = false;
            }
            if(activity['suggestedActions']&&activity['suggestedActions']['actions']){
              let quickButtons = []
              for(let button in activity['suggestedActions']['actions']){
                let buttonvalue = Object.values(activity['suggestedActions']['actions'])[button];
                quickButtons.push({
                  label: buttonvalue['title'],
                  value: buttonvalue['value']
                })
              }
              setQuickButtons(quickButtons);
            }
            addResponseMessage(`${activity['text']}`);
          }
          if(activity.from?.name.toLowerCase().includes("bot") && activity['type']=="typing"){
            if(!this.isBotTyping){
              toggleMsgLoader();
              this.isBotTyping = true;
            }
          }
        });
      },(err)=> console.log('error in creating directline link with co-pilot', err))
    } catch(e){
      console.log(e);
    }
  }

  public sendMessagetoBot(message){
    try {
      this.speechRecognizer.stop();
      this.switchMicButon();
      setTimeout(()=>{
        document.getElementsByClassName("rcw-input")[0].innerHTML = "";
      },0)
      let fromName;
      if(this.currentCoords){
        fromName = this.authenticationOfflineService.user.mail+","+this.currentCoords.latitude+","+this.currentCoords.longitude
      }
      else fromName = this.authenticationOfflineService.user.mail
      this.directline.postActivity({
        from: { id: this.authenticationOfflineService.user.xSystemUserID, name: fromName},
        type: 'message',
        text: message,
      }).subscribe(
        (id)=> {
        },
        error => console.log('Error posting activity', error)
      );
    } catch (e) {
      console.log(e);
    }
  }
  public async handleChatClick(data:any){
    if (data && data.data && data.data.etn && data.data.id) {
      switch (data.data.etn) {
        case 'contact' :
          await this.openContactFromChat(data.data.id);
          break;
        case 'account' :
          await this.openAccountFromChat(data.data.id);
          break;
        case 'appointment' :
          await this.openAppointmentFromChat(data.data.id);
          break;
        default :
          this.notificationService.notify(this.translate.instant('SCHEDULER_UNABLE_TO_COMPLETE_ACTION'), 'chat', 'top', 'info');
          break;
      }
    }
    if(data?.type && data?.type == "copilotVoiceToText"){
      this.speechRecognizer.start();
      this.switchMicButon(true);
      this.speechRecognizer.onresult = (event) => {
        console.log(event);
        let interimString = [];
        for(let res in event.results){
          let speechresult = Object.values(event.results)[res];
          if(speechresult && speechresult["isFinal"]){
            interimString.push(speechresult[0]["transcript"])
          }
        }
        document.getElementsByClassName("rcw-input")[0].innerHTML = interimString.join()
      };
    }
    if(data?.type && data?.type == "copilotVoiceToTextStop"){
      this.speechRecognizer.stop();
      this.switchMicButon();
    }
    else {
      return;
    }
  }

  private switchMicButon (active: boolean = false){
    let elem = document.getElementsByClassName('rcw-picker-icon')[0];
    if(!active){
      elem.setAttribute("src", "assets/imgs/geneeListenerGIF.svg");
      elem.setAttribute("id", "rcw-voiceToText");
    }
    else{
      elem.setAttribute("src", "assets/imgs/geneeListenerGIF.gif");
      elem.setAttribute("id", "rcw-voiceToTextActive");
    }
  }

  private async openContactFromChat(contactId) {
    await Promise.all([
      this.getContactInfo(contactId)
    ]).then(async (res) => {
      if(_.isEmpty(this.contactService.contactInformation) || (this.contactService.contactInformation && this.contactService.contactInformation.ID != contactId)) {
        this.notificationService.notify(this.translate.instant('SCHEDULER_UNABLE_TO_COMPLETE_ACTION'), 'chat', 'top', 'info');
        return;
      } else {
        if(isWidgetOpened()) toggleWidget();
        this.contactService.contactPageMode = ComponentViewMode.PREVIEW;
        const modal = await this.modalCtrl.create({
          component: ContactDetailsComponent,
          cssClass: 'modal-from-chat',
          backdropDismiss: true,
          componentProps: {
            viewData: {
              from: 'openChat'
            },
          }
        });
        modal.present();
        modal.onDidDismiss().then(async(res)=>{
          console.log("dismiss modal");
        });
      }
    })
  }

  private async getContactInfo(contactId) {
    let selectedContact = this.contactService.getContactByID(contactId);
    if(selectedContact) this.contactService.contactInformation = selectedContact;
    else {
      if(this.device.isOffline) {
        this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'chat', 'top', 'info');
        return;
      }
      this.uiService.displayLoader();
      await this.contactService.fetchContactsForConfiguredDisplay(true, false, contactId, true, false, null, true).then((rawDetails) => {
        if (rawDetails && rawDetails[0]) {
          const foundContact = new Contact(rawDetails[0]);
          this.contactService.isInGlobalSearch = false;
          if (foundContact) this.contactService.contactInformation = foundContact;
          this.uiService.dismissLoader();
        } else {
          this.uiService.dismissLoader();
        }
      });
    }
  }

  private async openAccountFromChat(accountId) {
    await Promise.all([
      this.getAccountInfo(accountId)
    ]).then(async () => {
      if(_.isEmpty(this.accountService.selected) || (this.accountService.selected && this.accountService.selected.id != accountId)) {
        this.notificationService.notify(this.translate.instant('SCHEDULER_UNABLE_TO_COMPLETE_ACTION'), 'chat', 'top', 'info');
        return;
      } else {
        if(isWidgetOpened()) toggleWidget();
        this.accountService.accountPageMode = ComponentViewMode.READONLY;
        const modal = await this.modalCtrl.create({
          component: AccountDetailsComponent,
          cssClass: 'modal-from-chat',
          backdropDismiss: true,
          componentProps: {
            viewData: {
              from: 'openChat'
            },
          }
        });
        modal.present();
        modal.onDidDismiss().then(async(res)=>{
          console.log("dismiss modal");
        });
      }
    })
  }

  private async getAccountInfo(accountId) {
    let selectedAccount = this.accountService.getAccountById(accountId);
    if(selectedAccount) {
      this.accountService.selected = selectedAccount;
    }
    else {
      if(this.device.isOffline) {
        this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'chat', 'top', 'info');
        return;
      }
      this.uiService.displayLoader();
      const rawDetails =  await this.accountDataService.getRealTimeAccountDetailsOnline(accountId, true);
      if (rawDetails) {
        const foundAccount = new Account(rawDetails);
        if (foundAccount) this.accountService.selected = foundAccount;
        this.uiService.dismissLoader();
      }
    }
  }

  private async openAppointmentFromChat(appintmentId) {
    await Promise.all([
      this.getAppointmentInfo(appintmentId)
    ]).then(async (res) => {
      if(_.isEmpty(this.activityService.selectedActivity) || (this.activityService.selectedActivity && this.activityService.selectedActivity.ID != appintmentId)) {
        this.notificationService.notify(this.translate.instant('SCHEDULER_UNABLE_TO_COMPLETE_ACTION'), 'chat', 'top', 'info');
        return;
      } else {
        if(isWidgetOpened()) toggleWidget();
        const modal = await this.modalCtrl.create({
          component: ActivitiesDetailsPaneComponent,
          cssClass: 'modal-from-chat',
          backdropDismiss: true,
          componentProps: {
            viewData: {
              from: 'openChat'
            },
          }
        });
        modal.present();
        modal.onDidDismiss().then(async(res)=>{
          console.log("dismiss modal");
        });
      }
    })
  }

  private async getAppointmentInfo(apptId) {
    let selectedActivity = this.activityService.getActivityByID(apptId);
    if(selectedActivity) this.activityService.selectedActivity = selectedActivity;
    else {
      if(this.device.isOffline) {
        this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'chat', 'top', 'info');
        return;
      }
      this.uiService.displayLoader();
      await this.activityDataService.getActivityByIdOnline(apptId, 'appointment').then((rawDetails) => {
        if (rawDetails) {
          let foundActivity = new AppointmentActivity(rawDetails);
          if (foundActivity) this.activityService.selectedActivity = foundActivity;
          this.uiService.dismissLoader();
        } else {
          this.uiService.dismissLoader();
        }
      });
    }
  }

}
