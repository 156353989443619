import { TranslateService } from '@ngx-translate/core';
import { SearchConfigService } from './../search/search-config.service';
import { Injectable } from '@angular/core';
import { Presentation, Page, OfflinePresentation, ContentMode, SwipeStatus } from '../../classes/presentation/presentation.class';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from '../disk/disk.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PresentationView, UIService } from '../../services/ui/ui.service'
import { DeviceService } from '../../services/device/device.service'
import { EventsService } from '../events/events.service';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { SelectedSuggestionPillDataModel } from '../../models/search-config-data-model';
import { Resource } from '../../classes/resource/resource.class';
import { ResourceService } from '../resource/resource.service';
import { Utility } from '../../utility/util';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { EventActivity } from '../../classes/events-tool/event.class';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { AlertController, ModalController } from '@ionic/angular';
import { ReferenceViewerComponent } from '../../components/presentation/reference-viewer/reference-viewer.component';
import { CustomerTagStateCode } from '../contact/contact.service';
import { NotificationService } from '../notification/notification.service';
import { Endpoints } from 'src/config/endpoints.config';
import { TagEntityType, UserTag, UserTagService } from '../user-tag/user-tag.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../authentication.service';
import { ChildTag, ParentTag, PresentationTagGroup, PresentationUserTag, UserTagData } from '@omni/classes/short-call/short-call.class';
import { endOfDay, isAfter, isWithinRange } from 'date-fns';
import { ActivityPresentationGpsData, PresentationGpsData } from '@omni/interfaces/presentation/gps-data/presentation-gps-data.interface';
import { LocationCoordinates } from '@omni/interfaces/shared/shared.interface';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';

@Injectable({
  providedIn: 'root'
})
export class PresentationService {
    /**
     * Pres == Presentation
     */
    // List of all Presentation unmodified
    public initialPres: Presentation[] = [];
    // List of all Presentation
    public presentation: Presentation[] = [];
    // List of all Downloaded Presentation
    public offlinePres: OfflinePresentation[] = [];
    // List of all pages of selected presentation
    public presPages: Page[] = [];
    // List of all resources of selected presentation
    public presResources: Page[] = [];
    // List of searched presentation
    public searchPresentation: Presentation[];
    // List of searched presentation
    public sortedPresentation: Presentation[];

    public showNewActivity: boolean = false;


    public hideCarousel: boolean = false;
    public isFullScreenMode: boolean = false;
    public isPrevFullScreenMode: boolean = false;

    private _swipeStatus: SwipeStatus;
    get swipeStatus(): SwipeStatus {
        return this._swipeStatus;
    }
    set swipeStatus(status: SwipeStatus) {
        this._swipeStatus = status;
        this.swipeStatus$.next(status);
    }
    private swipeStatus$: BehaviorSubject<SwipeStatus> = new BehaviorSubject<SwipeStatus>(null);
    public readonly swipeStatusObservable: Observable<SwipeStatus> = this.swipeStatus$.asObservable();

    // HIDE or SHOW preview area of presentation;
    public presShowRightPane: boolean = false;

    // Select mode in presentation list
    public isSelectMode: boolean = false;

    /**
     *  To keep track current presentation view
     */
    public viewMode: PresentationView;
    public prevViewMode: PresentationView; // to handle enter/exist pesentation fullscreen
    public contentMode: ContentMode;
    public selPresentation: (Presentation | Resource)[] = [];

    // Observable for currently selected page of presentation
    private carBriefcase = new BehaviorSubject<(Presentation | Resource)[]>(undefined);
    carouselBriefcase = this.carBriefcase.asObservable();

    // Observable for currently selected presentation
    private selectedPresentation = new BehaviorSubject<Presentation | Resource>(undefined);
    currentSelectedPres = this.selectedPresentation.asObservable();
    public activePresentation: Presentation | Resource; // If observable is not needed

    private selectedPresPageSlide = new BehaviorSubject<Page>(undefined);
    currentSelectedPresPageSlide = this.selectedPresPageSlide.asObservable();

    // Observable for currently selected page of presentation
    private selectedPresPage = new BehaviorSubject<Page>(undefined);
    currentSelectedPresPage = this.selectedPresPage.asObservable();
    public activePresPage: Page; // If observable is not needed

    // Observable for currently selected of view type
    private selectedPresView = new BehaviorSubject<any>(undefined);
    currentselectedPresView = this.selectedPresView.asObservable();

    private searchFilter: string = "";
    private brandsFilter: string = "All Products";
    private sortby: string = "Title";

    private activityPlayList: (Presentation | Resource)[] = [];
    private meetingPresentations: (Presentation | Resource)[] = [];

    public isFilterApplied = false;
    public getPresentationById(id: string): Presentation {
        return this.initialPres.find(pres => pres.ioPresentationId === id);
    }

    recentSearches: SelectedSuggestionPillDataModel[] = [];
    contentRecentSearches: SelectedSuggestionPillDataModel[] = [];
    public videoResourceCurrentTime = 0;

    public isReferenceVisible = false;
    private referenceModalsMap: Map<string, any> = new Map();
    private referenceBeingShared: { id: string, referenceUrl: string, referenceType: 'pdf' | 'url', isVisible: boolean }[] = [];
    private isReferenceFetching: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private cachedReferenceData: Map<string, { [key: number]: { pageId: string, references: any[] } }> = new Map();
    private references: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public readonly isReferenceFetching$: Observable<boolean> = this.isReferenceFetching.asObservable();
    public readonly references$: Observable<any[]> = this.references.asObservable();
    public currentReferenceChangedSignal: Subject<any> = new Subject<any>();
    public selectedActivityId: string = '';
    // public preTags: UserTagForPresentation[] = [];
    public userTagData: UserTagData;

    constructor(
        private disk: DiskService,
        private deviceService: DeviceService,
        private events:EventsService,
        private searchConfigService: SearchConfigService,
        private resourceService: ResourceService,
        private inAppBrowser: InAppBrowser,
        private fileOpener: FileOpener,
        private modalCtrl: ModalController,
        private alertCtrl: AlertController,
        private translate: TranslateService,
        private uiService: UIService,
        public toast: NotificationService,
        private authService: AuthenticationService,
        private http: HttpClient,
        private readonly userTagService: UserTagService,
    ) {
        this.presentation = [];
        this.offlinePres = [];
        this.searchPresentation = [];
        this.selectedPresView.next({ name: "list", code: "ios-list" });
    }

    public get loadedPresentation(): boolean {
        return (this.presentation) ? this.presentation.length > 0 : false;
    }

    public set activtyPlaylist(list: (Presentation | Resource)[]) {
        this.activityPlayList = list;
    }

    public set meetingPresentationList(list: (Presentation | Resource)[]) {
        this.meetingPresentations = list;
    }


    // Reference data observables
    public setIsReferenceFetching(isLoading: boolean) {
      this.isReferenceFetching.next(isLoading);
    }
    public setPageReferences(references: any[]) {
      this.references.next(references);
    }
    public clearPageReferences() {
      this.references.next([]);
    }

    public getReferenceType(reference: any) {
      if (reference?.['_indskr_document_value_Formatted']) {
        return 'pdf';
      } else if (reference?.indskr_url) {
        return 'url';
      }
      return undefined;
    }

    // Reference data cache management
    addReferencesToCache(presentationId: string, pageIdx: number, pageId: string, references: any[]) {
      if (!this.cachedReferenceData.has(presentationId)) {
        this.cachedReferenceData.set(presentationId, {});
      }
      this.cachedReferenceData.get(presentationId)[pageIdx] = { pageId, references };
    }
    getReferencesFromCache(presentationId: string, pageIdx: number): { pageId: string, references: any[] } {
      if (this.cachedReferenceData.has(presentationId)) {
        return this.cachedReferenceData.get(presentationId)[pageIdx];
      }
      return null;
    }
    clearReferencesCache(presentationId?: string) {
      if (presentationId) {
        this.cachedReferenceData.delete(presentationId);
      } else {
        this.cachedReferenceData.clear();
      }
    }

    // Reference modal status tracking for minimize/maximize
    async createReferenceModal(
      reference: any,
      activityId: string,
      presentationId: string,
      pageIdx: number,
      pageId: string,
      isMeeting: boolean,
    ) {
      const options = {
        component: ReferenceViewerComponent,
        componentProps: {
          reference,
          activityId,
          presentationId,
          pageId,
          isMeeting,
        },
        backdropDismiss: false,
        cssClass: 'reference-viewer-modal',
      };
      const modal = await this.modalCtrl.create(options);
      this.referenceModalsMap.set(reference.indskr_referencesid, modal);
      await modal.present();
    }
    getReferenceModalById(referenceId: string) {
      return this.referenceModalsMap.get(referenceId) || null;
    }
    hideReferenceModal(referenceId: string) {
      const modal = this.referenceModalsMap.get(referenceId);
      if (modal) {
        modal.classList.add('hide');
      }
    }
    showReferenceModal(referenceId: string) {
      const modal = this.referenceModalsMap.get(referenceId);
      if (modal) {
        modal.classList.remove('hide');
      }
    }
    dismissReferenceModal(referenceId: string) {
      const modal = this.referenceModalsMap.get(referenceId);
      if (modal) {
        modal.dismiss();
        this.referenceModalsMap.delete(referenceId);
      }
    }
    closeAllReferenceModals() {
      this.referenceModalsMap.forEach(modal => {
        modal.dismiss();
      });
      this.referenceModalsMap.clear();
    }

    // Reference sharing status tracking for broadcasting to
    // contacts joining in the middle
    addToReferenceBeingShared(reference: any) {
      this.referenceBeingShared.push({
        id: reference.indskr_referencesid,
        referenceUrl: reference.indskr_url,
        referenceType: this.getReferenceType(reference),
        isVisible: true,
      });
      reference.isShared = true;
    }
    removeFromReferenceBeingShared(reference: any) {
      this.referenceBeingShared = this.referenceBeingShared.filter(item => item.id !== reference.indskr_referencesid);
      if (reference.hasOwnProperty('isShared')) {
        delete reference.isShared;
      }
    }
    showReferenceBeingShared(id: string) {
      for (const refBeingShared of this.referenceBeingShared) {
        if (refBeingShared.id === id) {
          refBeingShared.isVisible = true;
        } else {
          refBeingShared.isVisible = false;
        }
      }
    }
    hideReferenceBeingShared(id: string) {
      const index = this.referenceBeingShared.findIndex(ref => ref.id === id);
      if (index > -1) {
        this.referenceBeingShared[index].isVisible = false;
      }
    }
    clearReferenceBeingShared() {
      this.referenceBeingShared.length = 0;
    }
    isReferenceBeingShared() {
      return this.referenceBeingShared.length > 0;
    }
    getReferenceBeingShared() {
      return JSON.parse(JSON.stringify(this.referenceBeingShared));
    }


  

  filterExpiredPresentations(presenationList): Presentation[] {
    let today = new Date();
    return presenationList.filter(pres => {
      if ((!pres.availableFrom && !pres.availableUntil)) {
        return true;
      } else if (pres.availableFrom && pres.availableUntil)
        return pres.availableFrom <= today && pres.availableUntil >= today
      else if (pres.availableFrom && !pres.availableUntil) {
        return pres.availableFrom <= today;
      } else if (!pres.availableFrom && pres.availableUntil) {
        return pres.availableUntil >= today;
      }
    });
  }


    

    filterPresentations(query: string, reApply?: boolean) {
        if (this.searchFilter !== query) {
            // this.presentation = this.initialPres.slice();
            this.presentation = this.presentation.slice(); //filter presentations from content filtered array
            if (this.brandsFilter !== "All Products") {
                this.filterByBrand(this.brandsFilter);
            }
            if (this.sortby !== "Title") {
                this.sortPresentations(this.sortby);
            }
        }
        this.searchFilter = query;
        if (query.length === 0) {
            this.isFilterApplied = false;
            return;
        }
        this.presentation = this.presentation.filter(pres => {
            return ((pres.name.toLocaleLowerCase()).includes(query.toLocaleLowerCase()));
        });
        this.isFilterApplied = true;
    }


    filterByBrand(brand: string, reApply?: boolean) {
        // if(brand === "All Products"){
        //     //reset CWD-1488
        //     this.presentation = this.initialPres.slice()
        //     if(this.searchFilter !== "") {
        //         this.filterPresentations(this.searchFilter);
        //     }
        //     if(this.sortby !== "Title") {
        //         this.sortPresentations(this.sortby);
        //     }
        //     return;
        // }
        if (this.brandsFilter !== brand) {
            // this.presentation = this.initialPres.slice();
            if (this.searchFilter !== "") {
                this.filterPresentations(this.searchFilter);
            }
            if (this.sortby !== "Title") {
                this.sortPresentations(this.sortby);
            }
        }
        this.brandsFilter = brand;
        if (brand === "All Products") {
            this.isFilterApplied = false;
            return;
        }

        this.presentation = this.presentation.filter(pres => {
            if (!pres.brands) {
                return
            }
            for (let x of pres.brands) {

                return ((x.name.toLocaleLowerCase()).startsWith(brand.toLocaleLowerCase()));
            }
        });
        this.isFilterApplied = true;
    }

    sortPresentations(comp: any) {
        this.sortby = comp;

        if (comp === "Date Created") {
            this.presentation.sort((a, b) => parseInt(b.createdOn) - parseInt(a.createdOn));
        } else if (comp === "Date Updated") {
            this.presentation.sort((a, b) => parseInt(b.modifiedOn) - parseInt(a.modifiedOn));
        } else if (comp === "Title") {
            this.presentation.sort(function (a, b) {
                if (a.name && b.name) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (b.name.toLowerCase() < a.name.toLowerCase()) {
                        return 1;
                    }
                } else {
                    if (a.name) {
                        return 1;
                    } else {
                        return -1;
                    }
                }
            });
        } else if (comp === "Favourites") {
            this.presentation.sort(function (a, b) {
                return (a.favourite === b.favourite) ? 0 : a.favourite ? -1 : 1;
            });
        } else if (comp === "Downloaded") {

            //downloaded presentation sorted here
            let sortedDownloadedPresentation: Presentation[] = this.presentation.filter(e => {
                return e.downloaded;
            }).sort(this.sortAlphaNumerical);

            //Non-downloaded presentation sorted here
            let sortedNondownloadedPresentation: Presentation[] = this.presentation.filter(e => {
                return !e.downloaded;
            }).sort(this.sortAlphaNumerical);

            //combine presentation list with downloaded data on the top of the stack
            this.presentation = sortedDownloadedPresentation.concat(sortedNondownloadedPresentation);

        }
    }

    /**
     * Update list of all presentation
     * Tag presentations that are already downloaded
     *
     * @param raw
     */
    public refreshPresentationList(raw: any) {
        console.log("online presentation");
        //const downloadedPresIdsToBeRemoved = [];
        this.presentation = [];
        if (Array.isArray(raw)) {
            if (!this.offlinePres || this.offlinePres.length < 1) {
                raw.map(rawJSON => {
                    this.presentation.push(new Presentation(rawJSON));
                });
            } else {
                raw.map(rawJSON => {
                    let pres = new Presentation(rawJSON);
                    if (this.offlinePres.some(x => x.ioPresentationId === pres.ioPresentationId)) {
                        pres.downloaded = true;
                    }

                    this.presentation.push(pres);
                });

                // Delete downloaded presentation if not exist in raw.
                // At the moment, raw provided by service is the whole list of presentations.
                // Thus, if offline pres does not exist in raw anymore, it should be deleted from local.
                // However, if service changes later to provide delta data only, this whole logic should be changed.
                // for (let i = 0; i < this.offlinePres.length; i++) {
                //     const offlineP = this.offlinePres[i];
                //     if (!raw.some(p => p.indskr_iopresentationid === offlineP.ioPresentationId)) {
                //         downloadedPresIdsToBeRemoved.push(offlineP.ioPresentationId);
                //     }
                // }
            }
        } else {
            console.error('Unsupported data format for presentations!');
        }
      // map user tag to presentation
      // this.presentation.forEach(y=>{
      //     this.userTagService.PresentationTags.forEach((x) => {
      //       let c = x.entityRecords.filter((z) => z.id == y.ioPresentationId);
      //       if(c.length>0){y.userTag.push(x)}
      // })
      // })
        this.presentation.forEach(presentation => this.mapPresentationFieldsToSearchIndex(presentation));
        this.initialPres = [...this.presentation];
        //return downloadedPresIdsToBeRemoved;
    }
    //might be a better way of doing this quick fix for Jun 21st 2019
    public setDownloadedPres() {
        if (this.offlinePres) {
            for (let pres of this.offlinePres) {
                let tempPres = this.presentation.find(x => x.ioPresentationId == pres.ioPresentationId);
                if (tempPres) {
                    tempPres.downloaded = true;
                }
                //Probably don't need to do to both arrays
                let tempPres2 = this.initialPres.find(x => x.ioPresentationId == pres.ioPresentationId);
                if (tempPres2) {
                    tempPres2.downloaded = true;
                }
            }
        }
    }

    /**
     * Update list of offline presentation
     *
     * @param raw
     */
    public refreshOfflinePresentationList(raw: any) {
        console.log("offline presentation");
        if (Array.isArray(raw)) {
            // if(isOffline) {
            //     this.presentation = [];
            //     raw.map(rawJSON => {
            //         this.presentation.push(new Presentation(rawJSON));
            //     });
            //     this.initialPres = this.presentation.slice();
            // } else {
            this.offlinePres = [];
            raw.map(rawJSON => {
                this.offlinePres.push(rawJSON);
            });
            // }
        } else {
            console.error('OFFLINE Unsupported data format for presentations!');
        }
    }

    public resetPresentationList() {
        this.presentation = [...this.initialPres];
    }

    public mapPages(slides: Page[]) {
        if (!slides) {
            this.presPages = [];
            return;
        }

        let tempPages = [];
        let tempResources = [];

        slides.forEach(pag => {
            // if(pag.page.resource === false ){
            tempPages.push(pag);
            // }
            // else if(pag.page.resource === true) {
            //     tempResources.push(pag);
            // }
        });

        this.presPages = tempPages;
        this.presResources = tempResources;
    }

    public updatePresentationList(presArray: Presentation[], field: string, status: boolean) {

        for (let pres of presArray) {
            let tempPres = this.presentation.find(x => x.ioPresentationId === pres.ioPresentationId);
            if (tempPres) {
                tempPres[field] = status;
            }
        }
    }

    //#region Behaviour Subject

    /**
     * Set current selected presentation
     */
    public setCurrentSelectedPres(content: Presentation | Resource): void {
        if (!content) {
            this.contentMode = null;
            this.clearCurrentPresentation();
        } else {
            if (content instanceof Presentation) {
                this.contentMode = ContentMode.PRESENTATION;
                this.mapPages(content.presentationPages);
            } else {
                this.contentMode = ContentMode.RESOURCE;
                const resource: Resource = <Resource>content;
                this.presPages = resource.page && resource.page != null ? [resource.page] : [];
            }
        }
        this.selectedPresentation.next(content);
        this.activePresentation = content;
    }

    /**
     * Set current selected presentation page
     */
    public setCurrentSelectedPresPage(page: Page): void {
        // if(!url) {
        //     return;
        // }
        this.activePresPage = page;
        this.selectedPresPage.next(page);
    }

    /**
     * Set current selected presentation page slide
     */
    public setCurrentSelectedPresPageSlide(page: Page): void {
      this.activePresPage = page;
      this.selectedPresPageSlide.next(page);
    }

    /**
     * Set current selected presentation view type
     * "list" or "grid"
     */
    public setCurrentSelectedView(type: any): void {
        this.selectedPresView.next(type);
    }

    public setCarouselBriefcase(presList: (Presentation | Resource)[]): void {
        this.carBriefcase.next(presList);
    }

    //#endregion Behaviour Subject

    public clear() {
        this.presentation = [];
        this.searchPresentation = [];
    }

    //Clear current presentation pages
    public clearCurrentPresentation() {
        this.presPages = [];
        this.setCurrentSelectedPresPage(null);
    }

    public getPresObject(presID: string): Presentation {
        let foundPres = this.presentation.find(pres => pres.ioPresentationId === presID);
        return foundPres;
    }

    public getOfflinePresObject(presID: string): OfflinePresentation {
        let foundPres = this.offlinePres.find(pres => pres.ioPresentationId === presID);
        return foundPres;
    }

    public changePageBySequence(num: number) {
        if (!this.selectedPresPage.value && num === 1) {
            this.setCurrentSelectedPresPage(this.presPages.find(x => x.pageSequence == "1"));
            return;
        }
        if (!this.selectedPresPage.value) return;

        let currenPageSeq: number = Number(this.selectedPresPage.value.pageSequence);
        let totalPages: number = this.presPages.length;

        let pageNum;
        // if (currenPageSeq === 1 && num === -1) return;
        if (currenPageSeq === 1 && num === -1) {
            const prevPres = this.getPreviousPres();
            if (prevPres) this.events.publish("changePresentation", prevPres, true);
            return;
        }
        // if (num === 1 && currenPageSeq  === totalPages) return;
        if (num === 1 && currenPageSeq === totalPages) {
            const nextPres = this.getNextPres();
            if (nextPres) this.events.publish("changePresentation", nextPres);
            return;
        }

        if (num < 0) {
            pageNum = currenPageSeq - 1;
        } else {
            pageNum = currenPageSeq + 1;
        }

        this.setCurrentSelectedPresPage(this.presPages.find(x => x.pageSequence === pageNum));

    }

    getPreviousPres(): Presentation | Resource {
        if (this.viewMode !== PresentationView.MEETING) return null;
        const contents: (Presentation | Resource)[] = this.viewMode === PresentationView.MEETING ? this.meetingPresentations : this.activityPlayList;
        let idx = this.getCurrentContnetIndex(contents);
        let prevPres: Presentation | Resource;
        if (this.deviceService.isNativeApp && this.deviceService.isOffline) {
            for (let i = idx - 1; i >= 0; i--) {
                prevPres = contents[i];
                if (this.isPresentationDownloaded(prevPres)) break;
                prevPres = undefined;
            }
        } else {
            if (idx > 0) {
                prevPres = contents[--idx];
            } else {
                return undefined;
            }
        }
        return prevPres;
    }

    getNextPres(): Presentation | Resource {
        if (this.viewMode !== PresentationView.MEETING) return null;
        const contents: (Presentation | Resource)[] = this.viewMode === PresentationView.MEETING ? this.meetingPresentations : this.activityPlayList;
        let idx = this.getCurrentContnetIndex(contents);
        let nextPres: Presentation | Resource;
        if (this.deviceService.isNativeApp && this.deviceService.isOffline) {
            for (let i = idx + 1; i < contents.length; i++) {
                nextPres = contents[i];
                if (this.isPresentationDownloaded(nextPres)) break;
                nextPres = undefined;
            }
        } else {
            if (idx < contents.length - 1) {
                nextPres = contents[++idx];
            } else {
                return undefined;
            }
        }
        return nextPres;
    }

    public async canChangeSlideOrPresentation(): Promise<boolean> {
      let canChange = true;

      if (this.isReferenceBeingShared()) {
        canChange = await new Promise<boolean>(async resolve => {
          const alert = await this.alertCtrl.create({
            header: this.translate.instant('REFERENCE'),
            subHeader: this.translate.instant('REFERENCE_SLIDE_CHANGE_WARNING'),
            backdropDismiss: false,
            buttons: [
              {
                text: this.translate.instant('CANCEL'),
                role: 'cancel',
                handler: () => resolve(false),
              },
              {
                text: this.translate.instant('CONFIRM'),
                handler: () => {
                  this.closeAllReferenceModals();
                  this.clearReferenceBeingShared();
                  resolve(true);
                },
              },
            ],
          });
          alert.present();
        });
      }

      return canChange;
    }

    getCurrentContnetIndex(contents: (Presentation | Resource)[]): number {
        let id:  string = this.contentMode === ContentMode.PRESENTATION ? this.activePresentation['ioPresentationId'] :
            this.activePresentation['ioResourceId'] || this.activePresentation['ioDocumentId'];
        return contents.findIndex(content => {
                return (content['ioResourceId'] === id || content['ioDocumentId'] === id || content['ioPresentationId'] === id);
        });
    }

    // public updateSelPresList(pres: Presentation) {
    //     let element = this.selectedPresList.find(p => p.ioPresentationId === pres.ioPresentationId);
    //     if (!element) {
    //         // pres.isSelected = true;
    //         this.selectedPresList.push(pres);

    //     } else {
    //         // pres.isSelected = false;
    //         let index =  this.selectedPresList.indexOf(pres);
    //         this.selectedPresList.splice(index, 1);
    //     }
    // }

    // public remSelPresList(pres: Presentation) {
    //     this.selectedPresList
    // }

    /**
     * Check if presentation is Downloaded
     */

    public isPresentationDownloaded(content: Presentation | Resource) {
        if (!this.deviceService.isOffline && !this.deviceService.isNativeApp) {
            return;
        }
        if (content['ioPresentationId']) {
            return this.offlinePres.some(p => p.ioPresentationId === content['ioPresentationId']);
        } else {
            return this.resourceService.offlineResources.some(p => p.ioResourceId === content['ioResourceId'] || p.ioResourceId === content['ioDocumentId']);
        }
    }

    public isMeetingPresAvailableOffline(contents: (Presentation | Resource)[]) {
        for (let content of contents) {
            let element = this.offlinePres.some(p => p.ioPresentationId === content['ioPresentationId']) ||
            this.resourceService.offlineResources.some(p => p.ioResourceId === content['ioResourceId'] || p.ioResourceId === content['ioDocumentId']);
            if (element) {
                return true;
            }
        }
        return false;
    }

    public getThumbFileName() {
        if (this.activePresentation instanceof Presentation && !this.activePresentation.thumbnailZipUrl) {
            return;
        }
        let zipFileName = ((<Presentation>this.activePresentation).thumbnailZipUrl.split("/")).pop()
        return zipFileName.substring(0, zipFileName.indexOf('.'));
    }

    //custom sorting algorithm for alphanumeric values as requested by test team
    //can be reused to sort by title.
    private sortAlphaNumerical(a, b) {

        //order for sorting as requested by QA team
        //priority order ==>> special character >> numerals >> alphabets
        let sortOrder: string = '*!@_.()#^&%-=+01234567989abcdefghijklmnopqrstuvwxyz';

        // first character of the name of x
        let index_x = sortOrder.indexOf(a.name.toLowerCase()[0]);

        // first character of the name of y
        let index_y = sortOrder.indexOf(b.name.toLowerCase()[0]);

        if (index_x === index_y) {
            // same first character, sort regular
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        }
        else {
            //return in the order as set on the sortOrder list
            return index_x - index_y;
        }
    }

    async updateNewPresStatus(newPres: Presentation[]) {
        if (newPres && newPres.length > 0) {
            let offlineSaved = await this.getPresetationsFromDB();
            if (offlineSaved && offlineSaved && Array.isArray(offlineSaved)) {
                newPres.forEach(pres => {
                    let foundSavedIndex = offlineSaved.findIndex(savedPres => savedPres.indskr_iopresentationid === pres.ioPresentationId);
                    if (foundSavedIndex >= 0) {
                        offlineSaved[foundSavedIndex]['isNew'] = false;
                    }
                });
                await this.disk.saveOrUpdatePresToDB(offlineSaved);
            }
        }
    }

    public async getPresetationsFromDB() {
    let dbPresentations = [];
    try {
      let dbPresentationsDoc: any = await this.disk.retrieve(DB_KEY_PREFIXES.PRESENTATION);
      if (dbPresentationsDoc && dbPresentationsDoc.raw && Array.isArray(dbPresentationsDoc.raw)) {
        dbPresentations = dbPresentationsDoc.raw;
      }
    }
    catch (error) {
      console.warn('getPresetationsFromDB: No data to load');
    }
    return dbPresentations;
    }

    public mapPresentationFieldsToSearchIndex(newPres:Presentation){
        // console.log(this.userTagService.PresentationTags);
        if(!this.searchConfigService.isConfigInitiated){
            this.searchConfigService.initSearchConfigs();
            this.searchConfigService.isConfigInitiated = true;
        }
        newPres.brands.forEach(brand=>{
            if(brand.name && !this.searchConfigService.presentationsSearchIndexesConfig.find(config=> config.categoryName == 'Products').values.some(o=> o==brand.name))
              this.searchConfigService.presentationsSearchIndexesConfig.find(config=>config.categoryName=='Products').values.push(brand.name);
          })
        newPres.presentationTherapeuticAreas.forEach(ta=>{
          if(ta.indskr_name && !this.searchConfigService.presentationsSearchIndexesConfig.find(config=> config.categoryName == 'Therapeutic Areas').values.some(o=> o==ta.indskr_name))
            this.searchConfigService.presentationsSearchIndexesConfig.find(config=>config.categoryName=='Therapeutic Areas').values.push(ta.indskr_name);
        })
        newPres.specialties.forEach(specialty=>{
            if(specialty.name && !this.searchConfigService.presentationsSearchIndexesConfig.find(config=> config.categoryName == 'Specialties').values.some(o=> o==specialty.name))
              this.searchConfigService.presentationsSearchIndexesConfig.find(config=>config.categoryName=='Specialties').values.push(specialty.name);
          })
        if(newPres.languageName && !this.searchConfigService.presentationsSearchIndexesConfig.find(config=> config.categoryName == 'Languages').values.some(o=> o == newPres.languageName)){
            this.searchConfigService.presentationsSearchIndexesConfig.find(config=> config.categoryName == 'Languages').values.push(newPres.languageName);
          }
        newPres.diseaseStates.forEach(diseaseState=>{
          if(diseaseState && !this.searchConfigService.presentationsSearchIndexesConfig.find(config=> config.categoryName == 'Disease State').values.some(o=> o == diseaseState.indskr_name))
            this.searchConfigService.presentationsSearchIndexesConfig.find(config=> config.categoryName == 'Disease State').values.push(diseaseState.indskr_name);
          })
        newPres.keyMessages.forEach(km=>{
            if(km && !this.searchConfigService.presentationsSearchIndexesConfig.find(config=> config.categoryName == 'Key Messages').values.some(o=> o==km.keyMessageName))
              this.searchConfigService.presentationsSearchIndexesConfig.find(config=>config.categoryName=='Key Messages').values.push(km.keyMessageName);
          })
          if(newPres.description && !this.searchConfigService.presentationsSearchIndexesConfig.find(config=> config.categoryName == 'description').values.some(o=> o == newPres.description)){
            this.searchConfigService.presentationsSearchIndexesConfig.find(config=> config.categoryName == 'description').values.push(newPres.description);
          }
          newPres.presentationPages.forEach(presentationPageName=>{
            if(presentationPageName && !this.searchConfigService.presentationsSearchIndexesConfig.find(config=> config.categoryName == 'Pages Name').values.some(o=> o==presentationPageName.name))
              this.searchConfigService.presentationsSearchIndexesConfig.find(config=>config.categoryName=='Pages Name').values.push(presentationPageName.name);
          })
          if(newPres.categoryName && !this.searchConfigService.presentationsSearchIndexesConfig.find(config=> config.categoryName == 'Category').values.some(o=> o == newPres.categoryName)){
            this.searchConfigService.presentationsSearchIndexesConfig.find(config=> config.categoryName == 'Category').values.push(newPres.categoryName);
          }
        // newPres.userTag.forEach(tg=>{
        //   if(tg.indskr_name && !this.searchConfigService.presentationsSearchIndexesConfig.find(config=> config.categoryName == 'Tags').values.some(o=> o == tg.indskr_name)){
        //     this.searchConfigService.presentationsSearchIndexesConfig.find(config=> config.categoryName == 'Tags').values.push(tg.indskr_name);
        //   }
        // })
    }

    public mapContentFieldsToSearchIndex(content:(Presentation | Resource)){
      if(!this.searchConfigService.isConfigInitiated){
        this.searchConfigService.initSearchConfigs();
        this.searchConfigService.isConfigInitiated = true;
      }

      if(content['category'] && !this.searchConfigService.contentsSearchIndexesConfig.find(config => config.categoryName == 'Content Category').values.some(o => o == content['category'])){
        this.searchConfigService.contentsSearchIndexesConfig.find(config=> config.categoryName == 'Content Category').values.push(content['category']);
      }

      // @ts-ignore
      content.brands.forEach(brand=>{
        if(brand && brand.name && !this.searchConfigService.contentsSearchIndexesConfig.find(config=> config.categoryName == 'Products').values.some(o=> o==brand.name))
          this.searchConfigService.contentsSearchIndexesConfig.find(config=>config.categoryName=='Products').values.push(brand.name);
      });

      // @ts-ignore
      /*content.brands.forEach(brand=>{
        if(brand && !this.searchConfigService.contentsSearchIndexesConfig.find(config=> config.categoryName == 'Products').values.some(o=> o==brand))
          this.searchConfigService.contentsSearchIndexesConfig.find(config=>config.categoryName=='Products').values.push(brand);
      })*/

      if (content['presentationTherapeuticAreas']) {
        content['presentationTherapeuticAreas'].forEach(ta => {
          if (ta.indskr_name && !this.searchConfigService.contentsSearchIndexesConfig.find(config => config.categoryName == 'Therapeutic Areas').values.some(o => o == ta.indskr_name))
            this.searchConfigService.contentsSearchIndexesConfig.find(config => config.categoryName == 'Therapeutic Areas').values.push(ta.indskr_name);
        })
      }

      content.specialties.forEach(specialty=>{
        if(specialty.name && !this.searchConfigService.contentsSearchIndexesConfig.find(config=> config.categoryName == 'Specialties').values.some(o=> o==specialty.name))
          this.searchConfigService.contentsSearchIndexesConfig.find(config=>config.categoryName=='Specialties').values.push(specialty.name);
      });

      if(!(content instanceof Resource) && content.languageName && !this.searchConfigService.contentsSearchIndexesConfig.find(config => config.categoryName == 'Languages').values.some(o => o == content.languageName)){
        this.searchConfigService.contentsSearchIndexesConfig.find(config=> config.categoryName == 'Languages').values.push(content.languageName);
      }

      if (!(content instanceof Resource) && content.diseaseStates) {
        content.diseaseStates.forEach(diseaseState => {
          if (diseaseState && !this.searchConfigService.contentsSearchIndexesConfig.find(config => config.categoryName == 'Disease State').values.some(o => o == diseaseState.indskr_name))
            this.searchConfigService.contentsSearchIndexesConfig.find(config => config.categoryName == 'Disease State').values.push(diseaseState.indskr_name);
        })
      }

      if (!(content instanceof Resource) && content.keyMessages) {
        content.keyMessages.forEach(km => {
          if (km && !this.searchConfigService.contentsSearchIndexesConfig.find(config => config.categoryName == 'Key Messages').values.some(o => o == km.keyMessageName))
            this.searchConfigService.contentsSearchIndexesConfig.find(config => config.categoryName == 'Key Messages').values.push(km.keyMessageName);
        })
      }

      if(!(content instanceof Presentation) && content.assetType && !this.searchConfigService.contentsSearchIndexesConfig.find(config => config.categoryName == 'Types').values.some(o => o == content.assetType)){
        this.searchConfigService.contentsSearchIndexesConfig.find(config=> config.categoryName == 'Types').values.push(content.assetType);
      }

      /*if(!(content instanceof Presentation) && content.raw.brands) content.raw.brands.forEach(brand => {
        if(brand && brand.therapeuticAreas && brand.therapeuticAreas.length > 0)
          brand.therapeuticAreas.forEach(ta=>{
            if(ta.therapeuticAreaName && !this.searchConfigService.contentsSearchIndexesConfig.find(config=> config.categoryName == 'Therapeutic Areas').values.some(o=> o==ta.therapeuticAreaName))
              this.searchConfigService.contentsSearchIndexesConfig.find(config=>config.categoryName=='Therapeutic Areas').values.push(ta.therapeuticAreaName);
          })
      })*/
    }

    handleVirtualPageRedirect(presentation: Presentation, eventData): boolean {
        let shouldBoradcastEvent: boolean = false;
        if (eventData.type === 'local') {
            // Slide changed. Find the correct slide and change to it.
            // Supports top level html files only. Sub-folder html files won't work for now.

            // Find slide, and set that to be a selected slide.
            if (presentation instanceof Presentation && Array.isArray(presentation.presentationPages)) {
                let page: Page;
                for (let i = 0; i < presentation.presentationPages.length; i++) {
                    const iPage = presentation.presentationPages[i];
                    let pageUrlParts = iPage.pageUrl.split('/');
                    let slideSrcName = pageUrlParts[pageUrlParts.length - 1];
                    if (slideSrcName === eventData.href) {
                        page = iPage;
                        break;
                    }
                }
                if (page) {
                    this.setCurrentSelectedPresPage(page);
                }
            }
        } else if (eventData.type === 'outside') {
            // Trying to reach external site within iframe..
            // Make it open on a new tab / window. We don't track / sync external pages.
            if (eventData.href) {
                this.inAppBrowser.create(eventData.href, '_blank', { location: 'yes' });
                shouldBoradcastEvent = true;
            }
        } else if (eventData.type === 'openpdf') {
          if (eventData.href) {
            // this.inAppBrowser.create(eventData.href, '_system');
            if(this.deviceService.isAndroid()) {
              this.fileOpener.open(eventData.href,'application/pdf')
              .then(sucess => console.log(sucess))
              .catch(err => console.log(err));
            } else {
              this.inAppBrowser.create(eventData.href, '_blank', { location: 'yes' });
            }
            shouldBoradcastEvent = true;
          }
        }

        return shouldBoradcastEvent;
    }

  public async mapPresentationUserTagGgroupData(usertags: PresentationUserTag[]) {
    let presentationTags: PresentationTagGroup[] = []
    let parentTags: ParentTag[] = [];
    if (usertags && Array.isArray(usertags)) {
      const groupByPresentationId = usertags.reduce((result: Record<string, PresentationTagGroup>, item: PresentationUserTag) => {
        const presentationId = item.presentation_id;
        if (!result[presentationId]) {
          result[presentationId] = { presentation_id: presentationId, tags: [] };
        }
        result[presentationId].tags.push({
          indskr_name: item.indskr_name,
          indskr_usertagid: item.indskr_usertagid
        });
        return result;
      }, {});
      presentationTags = Object.values(groupByPresentationId);
      this.userTagData.presentationTags = presentationTags;
      const groupByParentTagId = usertags.reduce((result: Record<string, ParentTag>, item: PresentationUserTag) => {
        const parentTagId = item.parent_tag_id;
        if (!result[parentTagId]) {
          result[parentTagId] = {
            parent_tag_id: parentTagId,
            parent_tag_name: item.parent_tag_name,
            childTags: [],
          };
        }
        const existingChildTags = result[parentTagId].childTags;
        if (existingChildTags.findIndex((tag: ChildTag) => tag.indskr_usertagid === item.indskr_usertagid) === -1) {
          existingChildTags.push({
            indskr_name: item.indskr_name,
            indskr_usertagid: item.indskr_usertagid
          });
        }
        return result;
      }, {});

      parentTags = Object.values(groupByParentTagId);
      this.userTagData.parentTags = parentTags;
    }

    await this.disk.updateOrInsert(DB_KEY_PREFIXES.PRESENTATION_USER_TAGS, (doc) => {
      doc = this.userTagData;
      return doc;
    });
  }

    public mapDownloadedPresentationsToEvent(event:EventActivity){
        let presentations = this.filterExpiredPresentations(this.presentation);
        let eventPres = event.presentations;
        if(eventPres && eventPres.length>0){
            presentations.forEach(p => {
                let idx = eventPres.findIndex(ep => p.ioPresentationId == ep.id);
                if(idx>=0) {
                    eventPres[idx].downloaded = p.downloaded;
                }
            })
        }
    }

    toastNotificationForPresTags(tagData : UserTag , create : boolean , deleteTag = false){
      if(deleteTag) {
        this.toast.notify(`'${tagData.indskr_name}' ${this.translate.instant("DELETED_SUCCESSFULLY")}`, "");
        return;
      }
    if(create) {
      this.toast.notify(`'${tagData.indskr_name}' ${this.translate.instant("SAVED_SUCCESSFULLY")}`, "");
    } else {
      this.toast.notify(`'${tagData.indskr_name}' ${this.translate.instant("UPDATED_SUCCESSFULLY")}`, "");
    }
  }
  savePresTags(reqBody: UserTag): Observable<any> {
    if (this.deviceService.isOffline) {
      if (reqBody[0].pendingPushToDynamics) {
        this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PRESENTATION_TAG, 1);
        reqBody[0].pendingPushToDynamics = true;
      }
    } else {
      let headers = new HttpHeaders();
      let payload;
      payload = {
        indskr_externalid: reqBody.indskr_externalid,
        indskr_usertagid: reqBody.indskr_usertagid,
        deleted: reqBody.deleted,
        indskr_name: reqBody.indskr_name,
        presentations: reqBody.entityRecords ? reqBody.entityRecords.map(a => { return { 'presentationId': a.id, 'deleted': a.deleted } }) : [],
        pendingPushToDynamics: reqBody.pendingPushToDynamics,
        stateCode: reqBody.stateCode,
        indskr_taggedfor: 'Presentation',
        entity:reqBody.entity
      }
      let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.CONTACT_TAGGING;
      // payload = _.cloneDeep(reqBody);
      // payload['indskr_taggedfor'] = 'PRESENTATION';
      return this.http.post(url, [payload], { headers });
    }
  }

  async createOrUpdatePresTag(tagData: UserTag, create: boolean = false ) : Promise<any>{
      this.uiService.displayLoader();
     return  new Promise(async (resolve , reject )=> {
       tagData.pendingPushToDynamics = true;
       if(create){
        tagData.indskr_externalid = `offlineUserTag_${new Date().getTime()}`;
       }
       tagData.stateCode = CustomerTagStateCode.Active
       console.log("requestObj", tagData)
       if (this.deviceService.isOffline) {
         this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PRESENTATION_TAG, 1);
         await this.disk.upsertUserTag(tagData,TagEntityType.PRESENTATION);
         this.uiService.dismissLoader();
         this.toastNotificationForPresTags(tagData , create);
         resolve([{
          indskr_usertagid : tagData.indskr_usertagid,
          indskr_externalid : tagData.indskr_externalid
         }]);
       } else {
         await this.savePresTags(tagData).subscribe(async response => {
           console.log("data", response);
           response.forEach(async res => {
             tagData.pendingPushToDynamics = false;
             await this.disk.upsertUserTag(tagData,TagEntityType.PRESENTATION);
             if (tagData.indskr_externalid === res.indskr_externalid) {
               tagData.indskr_usertagid = res.indskr_usertagid;
               this.toastNotificationForPresTags(tagData , create);
               if(res["indskr_usertagid"]) {
                this.uiService.dismissLoader();
                 resolve(response);
               } else {
                this.uiService.dismissLoader();
                 reject(response);
               }
             }
           });
         }, async error => {
           console.log("Fails to create tag due to", error);
           tagData.pendingPushToDynamics = true;
           this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PRESENTATION_TAG, 1);
           await this.disk.upsertUserTagPresentation(tagData,TagEntityType.PRESENTATION);
           // error toast notification
           reject(error);
         });
       }
     });
    }

  public async loadOfflinePresentaionUserTagHierarchy() {
    await this.disk.retrieve(DB_KEY_PREFIXES.PRESENTATION_USER_TAGS, true).then((doc: UserTagData) => {
      if (doc) {
        this.userTagData.parentTags = doc.parentTags;
        this.userTagData.presentationTags = doc.presentationTags;
      }
    });
    console.log("Oflline user tags: ", this.userTagData);
  }
  private async loadActivityPresentationGpsDataFromDB(): Promise<ActivityPresentationGpsData[]> {
    try {
      const doc = await this.disk.retrieve(DB_KEY_PREFIXES.PRESENTATION_GPS_DATA, true);
      return doc?.raw ? doc.raw : undefined;
    } catch (error) {
      console.error('getActivityPresentationGpsDataFromDB: ', error);
    }
  }

  // Get ActivityPresentationGpsData
  // Init PresentationGpsData if doesn't exist?
  async getActivityPresentationGpsData(
    meetingOfflineId: string,
  ): Promise<ActivityPresentationGpsData> {
    let data: ActivityPresentationGpsData;
    try {
      const dbData = await this.loadActivityPresentationGpsDataFromDB();
      if (Array.isArray(dbData)) {
        const foundData = dbData.find(d => d.meetingOfflineId === meetingOfflineId);
        if (foundData) {
          data = foundData;
        }
      }
    } catch (error) {
      console.error('getPresentationGpsData: ', error);
    }
    return data;
  }
  async getMultiActivityPresentationGpsData(
    meetingOfflineIds: string[],
  ): Promise<{ [key: string]: ActivityPresentationGpsData }> {
    let data: { [key: string]: ActivityPresentationGpsData };
    try {
      const dbData = await this.loadActivityPresentationGpsDataFromDB();
      if (
        Array.isArray(dbData)
        && Array.isArray(meetingOfflineIds)
      ) {
        data = {};
        for (const offlineId of meetingOfflineIds) {
          const foundData = dbData.find(d => d.meetingOfflineId === offlineId);
          if (foundData) {
            data[offlineId] = foundData;
          }
        }
      }
    } catch (error) {
      console.error('getPresentationGpsData: ', error);
    }
    return data;
  }

  getPresentationGpsData(activityPresentationGpsData: ActivityPresentationGpsData, presentationId: string): PresentationGpsData {
    let gpsData: PresentationGpsData;

    if (Array.isArray(activityPresentationGpsData?.presentationGpsData)) {
      gpsData = activityPresentationGpsData.presentationGpsData.find(d => d.presentationId === presentationId);
    }

    return gpsData;
  }

  addPresentationGpsData(activityPresentationGpsData: ActivityPresentationGpsData, gpsData: PresentationGpsData) {
    try {
      if (Array.isArray(activityPresentationGpsData?.presentationGpsData)) {
        const idx = activityPresentationGpsData.presentationGpsData.findIndex(d => d.presentationId === gpsData.presentationId);
        if (idx >= 0) {
          activityPresentationGpsData.presentationGpsData[idx] = gpsData;
        } else {
          activityPresentationGpsData.presentationGpsData.push(gpsData);
        }
      }
    } catch (error) {
      console.error('addPresentationGpsData: ', error);
    }
  }
  removePresentationGpsData(activityPresentationGpsData: ActivityPresentationGpsData, presentationId: string): boolean {
    let success = false;
    try {
      if (Array.isArray(activityPresentationGpsData?.presentationGpsData)) {
        const idx = activityPresentationGpsData.presentationGpsData.findIndex(d => d.presentationId === presentationId);
        if (idx >= 0) {
          activityPresentationGpsData.presentationGpsData.splice(idx, 1);
          success = true;
        }
      }
    } catch (error) {
      console.error('removePresentationGpsData: ', error);
    }
    return success;
  }

  async saveActivityPresentationGpsData(activityPresentationGpsData: ActivityPresentationGpsData): Promise<boolean> {
    let success = false;
    try {
      if (activityPresentationGpsData?.meetingOfflineId) {
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.PRESENTATION_GPS_DATA, doc => {
          if (
            !doc
            || !Array.isArray(doc.raw)
          ) {
            doc = { raw: [] };
          }

          const idx = doc.raw.findIndex(d => d.meetingOfflineId === activityPresentationGpsData.meetingOfflineId);
          if (idx >= 0) {
            doc.raw[idx] = activityPresentationGpsData;
          } else {
            doc.raw.push(activityPresentationGpsData);
          }

          return doc;
        },)
      }
    } catch (error) {
      console.error('saveActivityPresentationGpsData: ', error);
    }
    return success;
  }

  isNewSessionForGpsData(presentationSessionId: number, presentationId: string, activityPresentationGpsData: ActivityPresentationGpsData): undefined | boolean {
    let isNewSession;

    // Check for session
    if (
      presentationSessionId
      && presentationId
      && Array.isArray(activityPresentationGpsData?.presentationGpsData)
    ) {
      const gpsData = activityPresentationGpsData.presentationGpsData.find(d => d.presentationId === presentationId);
      if (gpsData) {
        isNewSession = gpsData.sessionId !== presentationSessionId;
      } else {
        isNewSession = false;
      }
    }

    return isNewSession;
  }

  getActivityPresentationGpsDataPayload(meeting: AppointmentActivity, activityPresentationGpsData: ActivityPresentationGpsData) {
    let payload;
    const presentations: Presentation[] = meeting?.presentations as Presentation[] ?? null;

    try {
      if (
        Array.isArray(activityPresentationGpsData?.presentationGpsData)
        && Array.isArray(presentations)
      ) {
        let distanceMarked: PresentationGpsData;
        let failedToCapture: PresentationGpsData;
        for (let i = 0; i < activityPresentationGpsData.presentationGpsData.length; i++) {
          const gpsData = activityPresentationGpsData.presentationGpsData[i];
          const isActivePres = presentations.some(p => p.ioPresentationId === gpsData.presentationId);
          if (!isActivePres) {
            // Use only active presentation's gps data
            continue;
          }
          if (!isNaN(gpsData?.meetingDistance)) {
            if (distanceMarked) {
              distanceMarked = distanceMarked.meetingDistance > gpsData.meetingDistance ? gpsData : distanceMarked;
            } else {
              distanceMarked = gpsData;
            }
          } else if (gpsData.reasonForCaptureFailure) {
            if (failedToCapture) {
              failedToCapture = failedToCapture.lastUpdated < gpsData.lastUpdated ? gpsData : failedToCapture;
            } else {
              failedToCapture = gpsData;
            }
          }
        }

        const finalGpsData: PresentationGpsData = distanceMarked ? distanceMarked : failedToCapture ?? undefined;

        if (finalGpsData) {
          payload = {};
          if (finalGpsData.meetingDistance) {
            payload.indskr_meetingdistance = finalGpsData.meetingDistance;
          } else if (finalGpsData.reasonForCaptureFailure) {
            payload.indskr_reasonforlocationcapturefailure = finalGpsData.reasonForCaptureFailure;
          }
        }
      }
    } catch (error) {
      console.error('getActivityPresentationGpsDataPayload: ', error);
    }

    return payload;
  }
}
