import { PRODUCT_DISPLAY, SurgeryOrderActivity } from './../../../classes/activity/surgery-order.activity.class';
import { AuthenticationService } from './../../../services/authentication.service';
import { takeUntil } from 'rxjs/operators';
import { TrackService, TrackingEventNames} from './../../../services/logging/tracking.service';
import { FollowUpActionDetailComponent } from './../../activity/follow-up-action-detail/follow-up-action-detail';
import { NavigationService, PageName } from './../../../services/navigation/navigation.service';
import { MainCardViewDataModel } from './../../../models/MainCardViewDataModel';
import { FollowUpActivity } from './../../../classes/activity/follow-up-action.activity.class';
import { ActivityService } from './../../../services/activity/activity.service';
import { Component, ElementRef, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalController, PopoverController, AlertController } from '@ionic/angular';
import { IndFormFieldViewDataModel, FormFieldType } from '../../../models/indFormFieldDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { CollaboratorType, Opportunity, OpportunityCollaborators, OpportunityActivities, OpportunityProduct, EstimatedRevenueCalculationBasedOn, ProgressStatus, OpportunityPrimaryTraget, EstimatedClosedDateMandatory, ProductsMandatory } from '../../../classes/opportunity-management/opportunity.class';
import { format, isPast, isFuture, isValid, isEqual, isBefore, isSameDay } from 'date-fns';
import { SharedDataService } from '../../../data-services/shared-data/shared.data.service';
import { ConfirmationAlertData } from '../../../classes/shared/confirmation-alert-data.class';
import { SelectListDetail } from '../../../models/select-list-detail-model';
import { SelectListComponent } from '../../shared/select-list/select-list';
import { SelectListData } from '../../../models/select-list-data-model';
import { UIService, ComponentViewMode } from '../../../services/ui/ui.service';
import { OpportunityManagementDataService } from '../../../data-services/opportunity-management/opportunity-management.data.service';
import { DeviceService } from '../../../services/device/device.service';
import { OpportunityManagementService } from '../../../services/opportunity-management/opportunity-management.service';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { AccountManagementOfflineService } from '../../../services/account-management/account-management.service';
import { PopoverComponent } from '../../popover/popover';
import * as moment from'moment';
import { ContactOfflineService, ContactSelectionObservableModel } from '../../../services/contact/contact.service';
import { ContactPageComponent } from '../../contact/contact-page/contact-page';
import { Subject } from 'rxjs';
import { Contact, Specialty } from '../../../classes/contact/contact.class';
import { AccountOfflineService, AccountSelectionObservableModel } from '../../../services/account/account.offline.service';
import { OrderAddQuantityComponent } from '../../order-management/order-add-quantity/order-add-quantity';
import { OrderManagementService, FindPriceListModelData } from '../../../services/order-management/order-management.service';
import { PriceList } from '../../../classes/order-management/price-list.class';
import { OrderProductClass } from '../../../classes/activity/order.activity.class';
import { Product } from '../../../classes/consent/product.class';
import { Utility, MAXIMUM_NOTE_ATTACHMENT_SIZE, NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX } from '../../../utility/util';
import { OperationDetail, FollowUpActivityDataService } from '../../../data-services/follow-up-activity/follow-up-activity.data.service';
import { TranslateService } from '@ngx-translate/core';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { EventsService, EventName } from '../../../services/events/events.service';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { AlertService } from '../../../services/alert/alert.service';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { IndDropdownListDetailModel } from '../../../models/indDropdownListModel';
import { IndDropdownListComponent } from '../../../components/shared/ind-dropdown-list/ind-dropdown-list';
import { ActivityDataService } from '../../../data-services/activity/activity.service';
import { ActivityType } from '../../../classes/activity/activity.class';
import _ from 'lodash';
import { IONote } from '@omni/classes/io/io-note.class';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '@omni/models/mainToolTemplateDetail.model';
import { MainToolTemplateComponent } from '@omni/components/shared/main-tool-template/main-tool-template';
import { toBase64 } from '@omni/utility/util';
import { OpportunityCollaboratorsComponent } from '../opportunity-collaborators/opportunity-collaborators';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { ActivitiesDetailsPaneComponent } from '@omni/components/activity/activities-details-pane/activities-details-pane';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { AccountManagementDataService } from '@omni/data-services/account-management/account-management.data.service';
import { SurveyService } from '@omni/services/survey/survey.service';
import { CaseManagementService } from '@omni/services/case-management/case-management.service';
import { Brand } from '@omni/classes/brand/brand.class';
import { BrandOfflineService } from '@omni/services/brand/brand.service';
import { SurgeryProductHierarchy } from '@omni/classes/activity/surgery-order.activity.class';
import { MarketingPlansManagementOfflineService } from '@omni/services/marketing-management/marketing-management.service';
import { MarketingPlanManagementDataService } from '@omni/data-services/marketing-plan/marketing-plan-management.data.service';
import { MarketingPlan } from '@omni/classes/marketing-management/marketing-plan.class';
import { MarketingPlanSelectionViewDataModel } from '@omni/models/marketing-plan-selection-page-model';
import { MarketingPlanManagementPageComponent } from '@omni/pages/marketing-plan-page/marketing-plan-page';
import { ProcedureTrackerActivityDataService } from '@omni/data-services/procedure-tracker-activity/procedure-tracker-activity.data.service';
import { ProcedureTrackerActivity, UserTypes } from '@omni/classes/activity/procedure-tracker.activity.class';
import { SurgeryOrderDetailsComponent } from '@omni/components/surgery-order/surgery-order-details/surgery-order-details';
import { ProcedureTrackerDetailsComponent } from '@omni/components/surgery-order/procedure-tracker-details/procedure-tracker-details';

@Component({
    selector: 'opportunity-info',
    templateUrl: 'opportunity-info.html',
  styleUrls:['opportunity-info.scss']
})
export class OpportunityInfoComponent {

    public isLayoverPushView = false;
    public layoverViewPushedFrom = '';
    //public opportunityFormFieldValues: Array<IndFormFieldViewDataModel> = [];
    public statusFormField: IndFormFieldViewDataModel;
    public reasonFormField: IndFormFieldViewDataModel;
    public stageFormField: IndFormFieldViewDataModel;
    public progressFormField: IndFormFieldViewDataModel;
    public progressStageFormField: IndFormFieldViewDataModel;
    public descriptionFormField: IndFormFieldViewDataModel;
    public ownerFormField: IndFormFieldViewDataModel;
    public businessUnitFormField: IndFormFieldViewDataModel;
    public typeFormField: IndFormFieldViewDataModel;
    public subTypeFormField: IndFormFieldViewDataModel;
    public parentFormField: IndFormFieldViewDataModel;
    public accountFormField: IndFormFieldViewDataModel;
    public accountPlanFormField: IndFormFieldViewDataModel;
    public marketingPlanFormField: IndFormFieldViewDataModel;
    public accountPlanObjectiveFormField: IndFormFieldViewDataModel;
    public countryFormField: IndFormFieldViewDataModel;
    public estimatedCloseDateFormField: IndDateTimeFormViewDataModel;
    public estimatedValueFormField: IndFormFieldViewDataModel;
    public actualValueFormField: IndFormFieldViewDataModel;
    public discountFormField: IndFormFieldViewDataModel;
    public sourceFormField: IndFormFieldViewDataModel;
    public gemFormField: IndFormFieldViewDataModel;
    public affordabilityFormField: IndFormFieldViewDataModel;
    public userRatingFormField: IndFormFieldViewDataModel;
    public systemRatingFormField: IndFormFieldViewDataModel;
    public sourceOthersFormField: IndFormFieldViewDataModel;
    public procedureFormField: IndFormFieldViewDataModel;
    public productCategoryFormField: IndFormFieldViewDataModel;
    public productsFormField: IndFormFieldViewDataModel;
    public productManagerFormField : IndFormFieldViewDataModel;

    public competitorsFormField: IndFormFieldViewDataModel;
    public competitorProducsFormField: IndFormFieldViewDataModel;
    public competitorBusinessValueFormField: IndFormFieldViewDataModel;
    public specialtyFormField: IndFormFieldViewDataModel;
    public subSpecialtyFormField: IndFormFieldViewDataModel;
    public primaryTargetFormField: IndFormFieldViewDataModel;
    public contactFormField: IndFormFieldViewDataModel;
    public stakeholderValueFormField: IndFormFieldViewDataModel;
    public focusAreaFormField: IndFormFieldViewDataModel;

    public opportunityDetailsHeader: IndSectionHeaderViewDataModel;
    public productLineItemDetailsHeader: IndSectionHeaderViewDataModel;
    public stakeholderLineItemDetailsHeader: IndSectionHeaderViewDataModel;
    public collaboratorsLineItemDetailsHeader: IndSectionHeaderViewDataModel;
    public followUpActionsHeader: IndSectionHeaderViewDataModel;
    public opportunitySurveyHeaderModel: IndSectionHeaderViewDataModel;
    public competitorDetailsHeader: IndSectionHeaderViewDataModel;
    @Input() public opportunity: Opportunity;
    @Output() handleUpdates: EventEmitter<string> = new EventEmitter();
    @Output() isSurveyValidEvent = new EventEmitter<any>();
    public applicableFollowUps: FollowUpActivity[] =[];
    public applicableSystemFollowUps: OpportunityActivities[] =[];
    public applicableMeetings: AppointmentActivity[] = [];
    public applicableTeamActivities: OpportunityActivities[] = [];
    public applicableSurgeryOrder :SurgeryOrderActivity[]=[];
    public applicableProcedureTrackers :ProcedureTrackerActivity[]=[];
    public allModelActivities: MainCardViewDataModel[];
    public allModelProducts: MainCardViewDataModel[];
    public allModelStakeholder: MainCardViewDataModel[];
    public allModelCollaborator: MainCardViewDataModel[];
    public isReadOnlyMode = true;
    private isAddProductEnabled = false;
    private isAccountHaveAffiliatedContacts = false;
    public currentMatchedPriceList: PriceList;
    public notesHeaderModel:IndSectionHeaderViewDataModel;

    public currentOpportunityEstimatedDate: string;

    private contactSelectionSubscriptionFlag = false;
    private _selectCountryModal;
    private _accountPlanPopover;
    private _accountObjectivePopover;
    private _purchaseProcessPopover;
    private _sourcePopover;
    private _affordabilityPopover;
    private _userRatingPopover;
    private _businessUnitPopover;

    private isMyOpportunity = false;
    public isOpportunityClosed = false;
    public tempNoteText: string = '';
    public notesPlaceholder:string = '';
    public isSaveNotesEnabled:boolean = false;
    public attachmentTitle:string = '';
    public isNotesAttachmentEnabled:boolean = false;
    public isAttachmentAdded:boolean = false;
    private attachmentFile:any;
    private base64str;

    private readonly  ngDestroy$ = new Subject<boolean>();

    isOpportunityData = true;
    isAccountPlanEnabled = false;
    private isMarketingPlanEnabled = false;
    @Input() from: any;
    //private _opportunityTypePopover: any;
    private isConfirmFlowEnabled: boolean = false;
    private isCancelFlowEnabled: boolean = false;
    private opportunityBusinessProcessStages:any;
    private isClickedEstimatedValue: boolean = false;
    public isSurveyConfigured: boolean = false;
    // opportunitySurveyResponseDataModel: IndFormFieldViewDataModel[] = [];
    public surveyLookupResults: Array<any>=[];
    public surveyTemplate: any;
    private isSurveyValid:boolean = false;

    private isOpportunityByProductsEnabled: boolean = false;
    private isOpportunityCompetitorDataEnabled: boolean = false;
    public isCapitalSalesType: boolean = false;
    //public isSalesType: boolean = false;
    public isCompetitorSectionVisible:boolean = false;

    private allCompetitorProducts:Array<Brand> = [];
  private _startTakingQuestionUpdatesForSurveyJS: boolean = false;
  //private optionSets = [];
  private productHierarchies: SurgeryProductHierarchy[] = [];
  private products: Brand[] = [];
  private productDisplay: PRODUCT_DISPLAY = PRODUCT_DISPLAY.ALL_PRODUCTS;
  private selectedOpportunityType;

  private autoSubjectEnabled = false;
  private isSpecialtyFieldsEnabled:boolean = false;
  private currentSpecialties:Array<any> = [];
  private currentSubSpecialties:Array<any> = [];
  private isStakeholderValueEnabled:boolean = false;
  private _isClicked: { [x: string]: boolean } = {};

    constructor(
        public activityService: ActivityService,
        public navService: NavigationService,
        private readonly sharedDataService: SharedDataService,
        private readonly modalCtrl: ModalController,
        private readonly uiService: UIService,
        private readonly opportunityDataService: OpportunityManagementDataService,
        private readonly opportunityService: OpportunityManagementService,
        private readonly notificationService: NotificationService,
        public readonly device: DeviceService,
        private readonly accountManagementService: AccountManagementOfflineService,
        private readonly popoverCtrl: PopoverController,
        private readonly contactService: ContactOfflineService,
        private readonly accountService: AccountOfflineService,
        private readonly orderManagementService: OrderManagementService,
        private readonly translate: TranslateService,
        private readonly alertService: AlertService,
        private readonly footerService: FooterService,
        private readonly eventService: EventsService,
        private readonly authService: AuthenticationService,
        public readonly dateTimeFormatsService: DateTimeFormatsService,
        private readonly trackingService: TrackService,
        private readonly activityDataService: ActivityDataService,
        private readonly followUpActivityDataService: FollowUpActivityDataService,
        private readonly surgeryOrderDataService: SurgeryOrderActivityDataService,
        private readonly accountManagementDataService: AccountManagementDataService,
        public readonly surveyService: SurveyService,
        private readonly caseManagementService: CaseManagementService,
        private readonly brandService: BrandOfflineService,
        private readonly marketingPlansManagementOfflineService : MarketingPlansManagementOfflineService,
        private readonly marketingPlanManagementDataService: MarketingPlanManagementDataService,
        private procedureTrackerDataService: ProcedureTrackerActivityDataService,
    ) {

        // this.eventService.observe(EventName.OPPORTUNITYSCRAPBUTTON).pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
        //     this.handleOpportuntiyScrap();
        // });
        this.contactService.selectedContactObserver.pipe(
        takeUntil(this.ngDestroy$))
        .subscribe( (data:ContactSelectionObservableModel) => {
            if(data && data.for == 'OpportunityDetails' && data.id == this.opportunity.ID){
              this.opportunity.stakeholders = [];
              this.opportunity.stakeholderValue = 0;
              this.opportunity.stakeholderValueFormatted = (this.opportunity.transactionCurrencySymbol ? this.opportunity.transactionCurrencySymbol : '')+'0.00';
              data.contacts.forEach(con => {
                if(con.stakeholderValue >= 0){
                  this.opportunity.stakeholderValue += con.stakeholderValue;
                }
                con.stakeholderRolesList.forEach(role => {
                  this.opportunity.stakeholders.push({
                    ID: con.ID,
                    contactId: con.ID,
                    firstName: con.firstName,
                    fullName: con.fullName,
                    stakeholderValue: con.stakeholderValue,
                    isActive: con.isActive,
                    lastName: con.lastName,
                    role: {roleid: role.roleid, name: role.name}
                  });
                });
              })
              this.opportunity.stakeholderValueFormatted = (this.opportunity.transactionCurrencySymbol ? this.opportunity.transactionCurrencySymbol : '')+this.opportunity.stakeholderValue;
              if(this.stakeholderValueFormField){
                this.stakeholderValueFormField.inputText = this.opportunity.stakeholderValueFormatted;
              }
              // this.opportunity.stakeholders = data.contacts;
                this.opportunity.pendingPushToDynamics = true;
                this.uiService.displayLoader();
                if (this.autoSubjectEnabled) {
                  this.opportunity.opportunityName = this.opportunityService.setOpportunityDescription(this.opportunity);
                }
                this.opportunityDataService.updateOpportunity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true ,operationDetail: { code: 'updatecustomers', message: 'Update Opportunity Stakeholders'}}, [this.opportunity]).then(success => {
                    //Handle successfull response
                    this.handleUpdates.emit();
                    this.initAllModelStakeHolder();
                    this.uiService.dismissLoader();
                   
                }).catch(async error => {
                    // Handle error occured
                    this.handleUpdates.emit();
                    this.uiService.dismissLoader();
                });
            }
        });

        this.accountService.selectedAccountObserver.pipe(
          takeUntil(this.ngDestroy$))
          .subscribe( (data:AccountSelectionObservableModel) => {
              if(data && data.for == 'OpportunityDetails' && data.id == this.opportunity.ID){
                this.opportunity.stakeholders = [];
                this.opportunity.stakeholderValue = 0;
                this.opportunity.stakeholderValueFormatted = (this.opportunity.transactionCurrencySymbol ? this.opportunity.transactionCurrencySymbol : '')+'0.00';
                data.accounts.forEach(acc => {
                  if(acc.stakeholderValue >= 0){
                    this.opportunity.stakeholderValue += acc.stakeholderValue;
                  }
                  acc.stakeholderRolesList.forEach(role => {
                    this.opportunity.stakeholders.push({
                      ID: acc.id,
                      accountId: acc.id,
                      firstName: '',
                      fullName: acc.accountName,
                      stakeholderValue: acc.stakeholderValue,
                      isActive: (acc.status && (acc.status===1 || acc.status===548910000))? true : false,
                      lastName: '',
                      role: {roleid: role.roleid, name: role.name}
                    });
                  });
                })
                this.opportunity.stakeholderValueFormatted = (this.opportunity.transactionCurrencySymbol ? this.opportunity.transactionCurrencySymbol : '')+this.opportunity.stakeholderValue;
                if(this.stakeholderValueFormField){
                  this.stakeholderValueFormField.inputText = this.opportunity.stakeholderValueFormatted;
                }
                // this.opportunity.stakeholders = data.contacts;
                  this.opportunity.pendingPushToDynamics = true;
                  this.uiService.displayLoader();
                  if (this.autoSubjectEnabled) {
                    this.opportunity.opportunityName = this.opportunityService.setOpportunityDescription(this.opportunity);
                  }
                  this.opportunityDataService.updateOpportunity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true ,operationDetail: { code: 'updatecustomers', message: 'Update Opportunity Stakeholders'}}, [this.opportunity]).then(success => {
                      //Handle successfull response
                      this.handleUpdates.emit();
                      this.initAllModelStakeHolder();
                      this.uiService.dismissLoader();
                     
                  }).catch(async error => {
                      // Handle error occured
                      this.handleUpdates.emit();
                      this.uiService.dismissLoader();
                  });
              }
          });

        this.opportunityService.selectedCollaboratorsObserver.pipe(
          takeUntil(this.ngDestroy$))
          .subscribe( (data: OpportunityCollaborators) => {
            if (data) {
              this.opportunity.collaborators.push({
                    ID: data.ID,
                    fullName: data.fullName,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    isActive: true,
                    role: {roleid: data.role.roleid, name: data.role.name}
              });
              this.opportunity.pendingPushToDynamics = true;
              this.uiService.displayLoader();
              this.opportunityDataService.updateOpportunity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true ,operationDetail: { code: 'updatecollaborators', message: 'Update Opportunity Collaborators'}}, [this.opportunity]).then(success => {
              //Handle successfull response
              this.uiService.dismissLoader();
              }).catch(async error => {
              // Handle error occured
              this.uiService.dismissLoader();
              });
            }
        });

        this.eventService.subscribe('reloadSurveyTemplate', () => {
          this.loadSurveyTemplate();
        })

        if(this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT)) this.isAccountPlanEnabled = true;
        if(this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_BY_PRODUCTS)) this.isOpportunityByProductsEnabled = true;
      this.isMarketingPlanEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN);
    }

    ngDoCheck() {
      if (this.navService.getActiveChildNavViewPageName() == PageName.OpportunityDetailsComponent && !(this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent)) {
        if (!this.footerService.getActiveButtonsList().some(id => id == 'opportunityscrap')) {
          this.initFooterButtons();
        }
        if (this.opportunityDataService.isSelectedOpportunityUpdated) {
          this.opportunityDataService.isSelectedOpportunityUpdated = false;
          this.opportunity = this.opportunityService.getOpportunityDetailsbyId(this.opportunity.ID);
          this.opportunityService.selectedCollaboratorList = [];
          this.opportunityService.selectedCollaboratorList = this.opportunity.collaborators.filter(c=>c.isActive);
          if (this.opportunity.state != "Open" || this.navService.getCurrentMasterPageName() == PageName.OpportunitySelectionPage) {
            this.isReadOnlyMode = true;
            this.isAddProductEnabled = false;
          }
          this.initOpportunityBusinessProcessStages();
          this.initOpportunityInfoFormFields();
          this.initSurveyData();
        }
      }
    }

    ngAfterViewInit(){
      // if(this.scrollTop){
      //   setTimeout(() => {
      //     this.scrollTop.nativeElement.scrollIntoView(false);
      //   }, 200);
      // }
    }

    ngOnDestroy() {
        this.ngDestroy$.next(true);
        this.ngDestroy$.complete();
        this.eventService.unsubscribe('reloadSurveyTemplate');
        this.opportunityService.selectedOpportunityOwnerID = '';
        this.footerService.initButtons(FooterViews.Contacts);
        this.surveyService.surveyLookupResults = this.surveyLookupResults = [];
        this.surveyService.assignLookupResult(undefined);
    }

    public getState(opportunitystate, opportunitystatus):string{

        switch (opportunitystate) {
            case 'Open':
                opportunitystate = this.translate.instant('OPEN');
                break;
            case 'Approved':
                opportunitystate = this.translate.instant('APPROVE');
                break;
            case 'For Review':
                opportunitystate = this.translate.instant('FOR_REVIEW');
                break;
            case 'Lost':
              opportunitystate = opportunitystatus==548910002?this.translate.instant('OPPORTUNITY_STATE_DISCONTINUED'):this.translate.instant('OPPORTUNITY_STATE_LOST');
                break;
            case 'Won':
              opportunitystate = this.translate.instant('WON');
              break;
            default:
                opportunitystate = opportunitystate;
                break;
            }
            return opportunitystate;
    }

  public async initOpportunityInfoFormFields() {
    if (!this.opportunity) return;
    let isSurgeryDataAvailable = this.isOpportunityByProductsEnabled ? !_.isEmpty(this.surgeryOrderDataService.procedureTrackerProductHierarchies) : this.surgeryOrderDataService.productHierarchies.length > 0
    let isAccountPlanData = true;
    let isMarketingPlanData = true;
    this.isOpportunityData = true;
    let isSelectedAccountPlan: boolean = this.opportunity.accountPlanID ? true : false;

    // if (this.isAccountPlanEnabled) {
    //   this.opportunity.opportunityName = this.setOpportunityDescription();
    // }

    if (this.opportunity.accountID) {
      // let affiliatedContacts: Contact[] = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([this.accountService.getAccountById(this.opportunity.accountID)]);
      // if (affiliatedContacts.length !== 0) {
      //   this.isAccountHaveAffiliatedContacts = true;
      // }
      let accountPlans = this.accountManagementService.accountPlans?.filter(plan => {
        plan.accountPlanTimeFrame = isPast(parseInt(plan.endDate)) ? 'Past'
          : isFuture(parseInt(plan.startDate)) ? 'Future' : 'Present';
        return (plan.accountId == this.opportunity.accountID && plan.accountPlanTimeFrame != 'Past' && plan.status == 'Open');
      });
      if (!accountPlans || accountPlans.length == 0) {
        isAccountPlanData = false;
        this.isOpportunityData = false;
      }
      let marketingPlans = this.marketingPlansManagementOfflineService.marketingPlans?.filter(plan => {
        plan.brandPlanTimeFrame = isPast(parseInt(plan.endDate)) ? 'Past'
          : isFuture(parseInt(plan.startDate)) ? 'Future' : 'Present';
        return plan.brandPlanTimeFrame != 'Past' && plan.status == 'Open';
      });
      if (_.isEmpty(marketingPlans)) {
        isMarketingPlanData = false;
      }
      if (this.opportunity.accountPlanID) {
        let accountObjectives = this.accountManagementService.getAccountPlanObjectiveByPlanId(this.opportunity.accountPlanID);
        if (accountObjectives.length == 0) {
          this.isOpportunityData = false
        }
      }
    }

    if (this.opportunity.primaryTargetValue && this.opportunity.primaryTargetName) {
      this.primaryTargetFormField = {
        label: this.translate.instant('PRIMARY_TARGET'),
        inputText: (this.opportunity.primaryTargetName) ? this.opportunity.primaryTargetName.toString() : '',
        id: 'primary-target-field',
        isReadOnly: true,
        isDisabled: true,
        customPlaceholderLabel: '',
        showArrow: false,
        formFieldType: FormFieldType.POPOVER_SELECT,
      }
      if (this.opportunity.primaryTargetValue == OpportunityPrimaryTraget.CONTACT && this.opportunity.contactName) {
        this.contactFormField = {
          label: this.translate.instant('CONTACT_FORM'),
          inputText: this.opportunity.contactName,
          id: 'contact-field',
          isReadOnly: true,
          isDisabled: true,
          customPlaceholderLabel: '',
          showArrow: false,
          formFieldType: FormFieldType.NEW_PAGE_SELECT,
          isEmptyRequiredField: false,
        }
      }
    }

    this.statusFormField = {
      label: this.translate.instant('STATUS'),
      inputText: this.getState(this.opportunity.state, this.opportunity.statusCode) + ((this.opportunity.pendingPushToDynamics && !this.isReadOnlyMode) ? ' - ' + this.translate.instant('PENDING_SYNC') : ''),
      id: 'status-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: false,
    };

    this.reasonFormField = {
      label: this.translate.instant('REASON'),
      inputText: this.getOpportunityReasonString(),
      id: 'reason-field',
      isReadOnly: true,
      isHidden: _.isEmpty(this.opportunity.opportunityReason) && _.isEmpty(this.opportunity.opportunityCloseReasons),
      isDisabled: this.opportunity.opportunityCloseReasons.length <= 1,
      showArrow: this.opportunity.opportunityCloseReasons.length > 1,
    };

    if(this.opportunity.opportunityCloseReasons.length > 1){
      this.reasonFormField.formFieldType = FormFieldType.POPOVER_SELECT;
      this.reasonFormField.eventHandler = (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName);
    }

    this.stageFormField = {
      label: this.translate.instant('STAGE'),
      inputText: _.startCase(this.opportunity.opportunityStageValue),
      id: 'stage-field',
      isReadOnly: true,
      customPlaceholderLabel: this.translate.instant('SELECT_STAGE'),
      isDisabled: (!this.isReadOnlyMode && !this.device.isOffline) && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? false : true,
      showArrow: (!this.isReadOnlyMode && !this.device.isOffline) && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
      isRequired: (!this.isReadOnlyMode && !this.device.isOffline),
      formFieldType: FormFieldType.POPOVER_SELECT,
    };

    this.progressFormField = {
      label: this.translate.instant('OPPORTUNITY_PROGRESS_PERCENT'),
      inputText: this.opportunity.opportunityProgressPercent + '%',
      id: 'progress-percent-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: false,
    };

    this.progressStageFormField = {
      label: this.translate.instant('PROGRESS_STAGE'),
      inputText: this.opportunity.indskr_progressstatus === ProgressStatus.NO_PROGRESS ? this.translate.instant("NO_PROGRESS") : this.translate.instant("IN_PROGRESS"),
      id: 'progress-stage-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      isHidden: this.selectedOpportunityType ? this.selectedOpportunityType['indskr_nostageprogressafterdays'] == null : true
    };

    this.descriptionFormField = {
      label: this.translate.instant('DESCRIPTION'),
      inputText: this.opportunity.opportunityName,
      inputValue: this.opportunity.opportunityName,
      id: 'desc-field',
      isReadOnly: (!this.isReadOnlyMode) ? false : true,
      isDisabled: this.isReadOnlyMode || this.autoSubjectEnabled,
      placeholderLabel: (this.isReadOnlyMode) ? this.translate.instant('NO_DESCRIPTION') : this.translate.instant('DESCRIPTION'),
      showArrow: !this.isReadOnlyMode && !this.autoSubjectEnabled,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !this.isReadOnlyMode,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };

    this.ownerFormField = {
      label: this.translate.instant('OM_OWNER'),
      inputText: this.opportunity.ownerName,
      id: 'created-by-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: false,
    };

    this.businessUnitFormField = {
      label: this.translate.instant('BUSINESS_UNIT'),
      inputText: (this.opportunity.businessUnitFormatted) ? this.opportunity.businessUnitFormatted.toString() : '',
      id: 'business-unit-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };

    this.typeFormField = {
      label: this.translate.instant('TYPE'),
      inputText: (this.opportunity.opportunityTypename) ? this.opportunity.opportunityTypename.toString() : '',
      id: 'oportunity-type-field',
      isReadOnly: true,
      isDisabled: true,
      customPlaceholderLabel: this.translate.instant('NO_TYPE'),
      showArrow: false,
      formFieldType: FormFieldType.POPOVER_SELECT,
    };

    this.subTypeFormField = {
      label: this.translate.instant('SUB_TYPE'),
      inputText: (this.opportunity.opportunitySubTypename) ? this.opportunity.opportunitySubTypename.toString() : '',
      id: 'oportunity-Sub-type-field',
      isReadOnly: true,
      isHidden: this.opportunity.opportunitySubTypename ? false : true,
      customPlaceholderLabel: this.translate.instant('NO_SUBTYPE'),
      showArrow: false,
      formFieldType: FormFieldType.POPOVER_SELECT,
    };

    this.parentFormField = {
      label: this.translate.instant('PARENT_OPPORTUNITY'),
      inputText: this.opportunity.parentOpportunityName,
      customPlaceholderLabel: this.translate.instant('SELECT_PARENT_OPPORTUNITY'),
      id: 'parent-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode && !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
      showArrow: !this.isReadOnlyMode && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };

    this.accountFormField = {
      label: this.translate.instant('ACCOUNT'),
      inputText: this.opportunity.accountName,
      id: 'account-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };

    this.accountPlanFormField = {
      label: this.translate.instant('ACCOUNT_PLAN'),
      inputText: this.opportunity.accountPlanName,
      id: 'account-plan-field',
      isReadOnly: true,
      isDisabled: (!this.isReadOnlyMode) ? false : true,
      placeholderLabel: (this.isReadOnlyMode) ? this.translate.instant('NO_ACCOUNT_PLAN_SELECTED') : isAccountPlanData ? this.translate.instant('ACCOUNT_PLAN') : this.translate.instant('NO_ACCOUNT_PLANS_EXIST'),
      showArrow: !this.isReadOnlyMode && isAccountPlanData,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isHidden: !this.isAccountPlanEnabled
    };
    if(this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_displaymarketingbusinessplanonopportunity']){
      const marketingPlans =  _.isEmpty(this.opportunity.marketingBusinessPlans) ? [] : _.orderBy(this.opportunity.marketingBusinessPlans, 'brandPlanName');
      this.marketingPlanFormField = {
        label: this.translate.instant('MARKETING_BUSINESS_PLANS'),
        inputText: _.isEmpty(marketingPlans) ? "" : marketingPlans[0].brandPlanName + (this.opportunity.marketingBusinessPlans.length > 1 ? (" +" + (this.opportunity.marketingBusinessPlans.length - 1)) : ""),
        id: 'marketing-plan-field',
        isReadOnly: true,
        placeholderLabel: (this.isReadOnlyMode) ? this.translate.instant('NO_MARKETING_PLANS_SELECTED') : isMarketingPlanData ? this.translate.instant('MARKETING_BUSINESS_PLANS') : this.translate.instant('NO_MARKETING_PLANS_EXIST'),
        showArrow: !_.isEmpty(marketingPlans) || !this.isReadOnlyMode && isMarketingPlanData,
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        isHidden: !this.isMarketingPlanEnabled
      }
    }

    this.accountPlanObjectiveFormField = {
      label: this.translate.instant('ACCOUNT_PLAN_OBJECTIVE'),
      inputText: this.opportunity.opportunityObjectiveName,
      id: 'account-plan-objective-field',
      isReadOnly: true,
      isDisabled: (!this.isReadOnlyMode && this.opportunity.accountPlanID) ? false : true,
      placeholderLabel: (this.isReadOnlyMode) ? this.translate.instant('NO_OBJECTIVE_SELECTED') : (this.isOpportunityData && isSelectedAccountPlan) ? this.translate.instant('ACCOUNTS_OBJECTIVE') : this.translate.instant('NO_OBJECTIVES_EXIST'),
      showArrow: !this.isReadOnlyMode && this.isOpportunityData && isSelectedAccountPlan,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isHidden: !this.isAccountPlanEnabled
    };

    this.countryFormField = {
      label: this.translate.instant('COUNTRY'),
      inputText: this.opportunity.countryName,
      id: 'country-field',
      isReadOnly: true,
      isDisabled: (!this.isReadOnlyMode) ? false : true,
      customPlaceholderLabel: (this.isReadOnlyMode) ? this.translate.instant('NO_COUNTRY') : this.translate.instant('SELECT_COUNTRY'),
      showArrow: !this.isReadOnlyMode,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isHidden: !this.opportunity.isrevenuesystemcalculated
    };

    this.estimatedCloseDateFormField = {
      isDateField: true,
      label: this.translate.instant('ESTIMATED_CLOSE_DATE'),
      customPlaceholderLabel: (this.isReadOnlyMode) ? this.translate.instant('NO_ESTIMATED_CLOSE_DATE') : this.translate.instant('SELECTE_ESTIMATED_CLOSE_DATE'),
      inputText: (this.opportunity.estimatedDate) ? format(new Date(parseInt(this.opportunity.estimatedDate)), this.dateTimeFormatsService.dateToUpper) : '',
      fromViewPage: CurViewPageType.OpportunityInfo,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: (!this.isReadOnlyMode) && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? false : true,
      showArrow: !this.isReadOnlyMode && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
      isRequired: this.selectedOpportunityType ? this.selectedOpportunityType['indskr_estimatedcloseddatemandatory'] == EstimatedClosedDateMandatory.MANDATORY : false,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClicked[DateTimeFieldType.EndDateField] && this.opportunity.estimatedDate==''
    };

    if (this.opportunity.isrevenuesystemcalculated) {
      this.estimatedValueFormField = {
        label: this.translate.instant('ESTIMATED_VALUE'),
        inputText: this.opportunity.estimatedValueFormatted ? this.dateTimeFormatsService.formattedCurrency(this.opportunity.estimatedValueFormatted) : '',
        id: 'estimated-value-field',
        isReadOnly: true,
        isDisabled: this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? true :false,
        showArrow: this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? true :false,
      };

      this.actualValueFormField = {
        label: this.translate.instant('ACTUAL_REVENUE'),
        inputText: this.opportunity.closedValueFormatted ? this.dateTimeFormatsService.formattedCurrency(this.opportunity.closedValueFormatted) : '',
        id: 'actual-value-field',
        isReadOnly: true,
        isDisabled: this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? true :false,
        showArrow: this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? true :false,
      };

      this.discountFormField = {
        label: this.translate.instant('OM_ELLIGIBLE_DISCOUNT'),
        inputText: ((this.opportunity.elligibleDiscount) ? this.opportunity.elligibleDiscount + '' : '0') + '%',
        inputType: 'number',
        inputValue: (this.opportunity.elligibleDiscount) ? this.opportunity.elligibleDiscount : 0,
        formattingSymbol: '%',
        id: 'discount-field',
        isReadOnly: (!this.isReadOnlyMode) ? false : true,
        isDisabled: (!this.isReadOnlyMode) ? false : true,
        placeholderLabel: this.translate.instant('DISCOUNT'),
        showArrow: !this.isReadOnlyMode,
        formFieldType: FormFieldType.INLINE_INPUT,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };

    } else {
      this.estimatedValueFormField = {
        label: this.translate.instant('ESTIMATED_VALUE'),
        inputText: this.opportunity.estimatedValueFormatted ? this.dateTimeFormatsService.formattedCurrency(this.opportunity.estimatedValueFormatted) : '',
        inputType: 'number',
        inputValue: (this.opportunity.estimatedValue) ? this.opportunity.estimatedValue : 0,
        id: 'estimated-value-field',
        isReadOnly: (!this.isReadOnlyMode && this.opportunity.statusCode == 1 && this.opportunity.collaboratorType == CollaboratorType.OWNER && !this.opportunity.isestimatedrevenuebasedonprocedureandproducts) && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? false : true,
        isDisabled: (!this.isReadOnlyMode && this.opportunity.statusCode == 1 && this.opportunity.collaboratorType == CollaboratorType.OWNER && !this.opportunity.isestimatedrevenuebasedonprocedureandproducts) && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? false : true,
        customPlaceholderLabel: this.translate.instant('ENTER_ESTIMATED_VALUE'),
        showArrow: !this.isReadOnlyMode && this.opportunity.statusCode == 1 && this.opportunity.collaboratorType == CollaboratorType.OWNER && !this.opportunity.isestimatedrevenuebasedonprocedureandproducts && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
        formFieldType: FormFieldType.INLINE_INPUT,
        isRequired: !this.isReadOnlyMode && this.opportunity.statusCode == 1 && this.opportunity.collaboratorType == CollaboratorType.OWNER,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: this.isClickedEstimatedValue && this.opportunity.estimatedValue <= 0,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };

      // const opportunityType = this.opportunityService.opportunityTypes.find(opp => opp.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
      if (this.selectedOpportunityType?.actualRevenueAutoCalculated && this.selectedOpportunityType.actualrevenuecalculationbasedon === 548910000) {
        this.actualValueFormField = {
          label: this.translate.instant('ACTUAL_REVENUE'),
          inputText: this.opportunity.closedValueFormatted ? this.dateTimeFormatsService.formattedCurrency(this.opportunity.closedValueFormatted) : '',
          id: 'actual-value-field',
          isReadOnly: true,
          isDisabled: this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? true :false,
          showArrow: this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? true :false,
        };
      } else {
        this.actualValueFormField = {
          label: this.translate.instant('ACTUAL_REVENUE'),
          inputText: this.opportunity.closedValueFormatted ? this.dateTimeFormatsService.formattedCurrency(this.opportunity.closedValueFormatted) : '',
          inputType: 'number',
          inputValue: (this.opportunity.closedValue) ? this.opportunity.closedValue : 0,
          id: 'actual-value-field',
          isReadOnly: (!this.isReadOnlyMode && this.opportunity.collaboratorType == CollaboratorType.OWNER) && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? false : true,
          isDisabled: (!this.isReadOnlyMode && this.opportunity.collaboratorType == CollaboratorType.OWNER) && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? false : true,
          customPlaceholderLabel: this.translate.instant('ENTER_ACTUAL_REVENUE'),
          showArrow: !this.isReadOnlyMode && this.opportunity.collaboratorType == CollaboratorType.OWNER && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
          formFieldType: FormFieldType.INLINE_INPUT,
          eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
        };
      }

      this.procedureFormField = {
        label: this.translate.instant('PROCEDURE'),
        inputText: this.opportunity.surgeryNameString,
        customPlaceholderLabel: !isSurgeryDataAvailable ? this.translate.instant('NO_PROCEDURE') : this.translate.instant('SELECT_PROCEDURE'),
        id: 'surgery-field',
        isReadOnly: true,
        isDisabled: this.isReadOnlyMode || !isSurgeryDataAvailable || !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
        showArrow: (!this.isReadOnlyMode && isSurgeryDataAvailable) && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };

      this.productCategoryFormField = {
        isHidden: this.isOpportunityByProductsEnabled || this.opportunity.isrevenuesystemcalculated,
        label: this.translate.instant('PRODUCT_CATEGORY'),
        inputText: this._currentProductCategoriesString,
        customPlaceholderLabel: !isSurgeryDataAvailable ? this.translate.instant('NO_PRODUCT_CATEGORY') : this.translate.instant('SELECT_PRODUCT_CATEGORY'),
        id: 'product-category-field',
        isReadOnly: true,
        isDisabled: !isSurgeryDataAvailable || (!this.opportunity.surgeryId && !this.isOpportunityByProductsEnabled) || !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
        showArrow: isSurgeryDataAvailable && (!!this.opportunity.surgeryId || this.isOpportunityByProductsEnabled) || this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };

      this.productsFormField = {
        isHidden: this.opportunity.isrevenuesystemcalculated || this.opportunity.isestimatedrevenuebasedonprocedureandproducts,
        label: this.translate.instant('PRODUCTS'),
        inputText: this._currentProductsString,
        customPlaceholderLabel: (!isSurgeryDataAvailable && !this.isOpportunityByProductsEnabled) ? this.translate.instant('NO_PRODUCTS') : this.translate.instant('SELECT_PRODUCTS'),
        id: 'product-field',
        isReadOnly: true,
        isDisabled: this.isReadOnlyMode || (!isSurgeryDataAvailable && !this.isOpportunityByProductsEnabled) || (this.opportunity.productCategory.length == 0 && !this.isOpportunityByProductsEnabled) && !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
        showArrow: (!this.isReadOnlyMode && (isSurgeryDataAvailable || this.isOpportunityByProductsEnabled) && (this.opportunity.productCategory.length > 0 || this.isOpportunityByProductsEnabled) && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT)),
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        isRequired: this.selectedOpportunityType ? this.selectedOpportunityType['indskr_productsmandatory'] == ProductsMandatory.MANDATORY : false,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: this._isClicked['product-field'] && _.isEmpty(this._currentProductsString),
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };
    }

    this.productManagerFormField = {
      isHidden: !this.selectedOpportunityType || !this.selectedOpportunityType.indskr_productmanager,
      label: this.translate.instant('PRODUCT_MANAGER'),
      inputText: this.opportunity.productManager ? this.opportunity.productManager.fullName : '',
      customPlaceholderLabel: this.translate.instant('SELECT_PRODUCT_MANAGER'),
      id: 'product-manager-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };

    this.specialtyFormField = {
      label: this.translate.instant('CONTACT_PRIMARY_SPECIALTY'),
      inputText: _.startCase(this.opportunity.primarySpecialtyName),
      id: 'specialty-field',
      isReadOnly: true,
      customPlaceholderLabel: (!this.isReadOnlyMode && this.contactService.specialties.length > 0)?this.translate.instant('SELECT_PRIMARY_SPECIALTY'):this.translate.instant('NO_PRIMARY_SPECIALTY'),
      isDisabled: (!this.isReadOnlyMode)  || this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT)? false : true,
      showArrow: (!this.isReadOnlyMode && this.contactService.specialties.length > 0 && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT)),
      isRequired: false,
      isHidden: !this.isSpecialtyFieldsEnabled,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
    };

    this.subSpecialtyFormField = {
      label: this.translate.instant('SUB_SPECIALTY'),
      inputText: _.startCase(this.opportunity.subSpecialtyName),
      id: 'sub-specialty-field',
      isReadOnly: true,
      customPlaceholderLabel: (!this.isReadOnlyMode && this.currentSubSpecialties.length > 0)?this.translate.instant('SELECT_SUB_SPECIALTY'):this.translate.instant('NO_SUB_SPECIALTY'),
      isDisabled: (!this.isReadOnlyMode) ? false : true,
      showArrow: (!this.isReadOnlyMode && this.currentSubSpecialties.length > 0),
      isRequired: false,
      isHidden: !this.isSpecialtyFieldsEnabled,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
    };

    if(this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_STAKEHOLDER_VALUE)){
      this.stakeholderValueFormField = {
        label: this.translate.instant('STAKEHOLDERS_ACCOUNTS_VALUE'),
        inputText: this.opportunity.stakeholderValueFormatted,
        id: 'stakeholder-value-field',
        isReadOnly: true,
        isDisabled: true,
        customPlaceholderLabel: '',
        showArrow: false,
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        isEmptyRequiredField: false,
      }
    }
    this.focusAreaFormField = {
      isHidden: !this._isFocusAreaEnabled,
      label: this.translate.instant('FOCUS_AREA'),
      inputText: this.opportunity.focusArea,
      inputValue: this.opportunity.focusArea,
      id: 'focus-area-field',
      isReadOnly: (!this.isReadOnlyMode) ? false : true,
      isDisabled: this.isReadOnlyMode,
      placeholderLabel: (this.isReadOnlyMode) ? this.translate.instant('NO_FOCUS_AREA') : this.translate.instant('ENTER_FOCUS_AREA'),
      showArrow: !this.isReadOnlyMode,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: true,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };

    this.opportunityDetailsHeader = {
      id: 'opportunity-details-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: [],
    };

    this.stakeholderLineItemDetailsHeader = {
      id: 'stakeholder-line-items-header',
      title: `${this.translate.instant('STAKEHOLDERS')}/${this.translate.instant('ACCOUNTS')}`,
      doNotModifyTitleCase: true,
      isRequired: !this.isReadOnlyMode && this.opportunity.statusCode == 1 && this.opportunity.collaboratorType == CollaboratorType.OWNER && !this.opportunity.isrevenuesystemcalculated,
      controls: [
        {
          id: 'stakeholder_line_item_add',
          text: this.translate.instant('ADD'),
          isDisabled: this.isReadOnlyMode || this.opportunity.statusCode != 1 || this.opportunity.collaboratorType != CollaboratorType.OWNER || !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
        }
      ],
    }

    this.collaboratorsLineItemDetailsHeader = {
      id: 'collaborator-line-items-header',
      title: this.translate.instant('COLLABORATORS'),
      controls: [
        {
          id: 'collaborator_line_item_add',
          text: this.translate.instant('ADD'),
          isDisabled: this.isReadOnlyMode || !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
        }
      ],
    }

    if (this.opportunity.isestimatedrevenuebasedonprocedureandproducts) {
      this.isAddProductEnabled = (!this.isReadOnlyMode && isSurgeryDataAvailable && (this.opportunity.productCategory.length > 0 || this.isOpportunityByProductsEnabled));
    }

    this.productLineItemDetailsHeader = {
      id: 'product-line-items-header',
      title: this.translate.instant('PRODUCT_LINE_ITEMS'),
      controls: [
        {
          id: 'product_line_item_add',
          text: this.translate.instant('PRODUCTS'),
          isDisabled: (this.isAddProductEnabled) ? false : true,
        }
      ],
    };

    this.initOpportunityNotesModel();

    if (this.opportunity.state != "Open" && !this.opportunity.opportunityNotes.some(a => a.noteId == 'description-note')) {
      this.opportunity.opportunityNotes.push(new IONote({
        annotationid: 'description-note',
        createdon: this.opportunity.closedDate,
        notetext: this.opportunity.closeDescriptionText,
        ownerName: this.opportunity.state,
      }));
    }

    this._sortOpportunityNotes();

    this.followUpActionsHeader = {
      id: 'follow-up-actions-header',
      title: this.translate.instant('FOLLOW_UP_ACTIONS_AND_MEETINGS_CAPS'),
      controls: [],
    };
    this.applicableFollowUps = [];
    this.applicableMeetings = [];
    this.applicableTeamActivities = [];
    this.applicableSystemFollowUps = [];
    this.applicableSurgeryOrder =[];
    this.applicableProcedureTrackers = [];
    this.activityService.activities.map((o) => {
      if (o instanceof FollowUpActivity && ((o.opportunityId && o.opportunityId == this.opportunity.ID)
        || (o.offlineOpportunityId && o.offlineOpportunityId == this.opportunity.offlineId))) {
        this.applicableFollowUps.push(o);
      } else if (o instanceof AppointmentActivity && o.associatedOpportunities && o.associatedOpportunities.some(opp=> opp.opportunityId == this.opportunity.ID)) {
        this.applicableMeetings.push(o);
      }
      else if (o instanceof SurgeryOrderActivity && ((o.opportunityId && o.opportunityId == this.opportunity.ID))) {
        this.applicableSurgeryOrder.push(o);
      }
      else if (o instanceof ProcedureTrackerActivity && ((o.opportunityId && o.opportunityId == this.opportunity.ID))) {
        this.applicableProcedureTrackers.push(o);
      }
    })
    this.opportunity.activitiesByOpportunity.forEach(act => {
      if (!this.applicableMeetings.some(o => o.ID == act.ID) && !this.applicableFollowUps.some(o => o.ID == act.ID)) {
        if (act.ownerid == this.authService.user.systemUserID && act.type == ActivityType.FollowUp) {
          if (!this.applicableSystemFollowUps.some(o => o.ID == act.ID)) {
            this.applicableSystemFollowUps.push(act);
          }
        } else {
          this.applicableTeamActivities.push(act);
        }
      }
    })
    this.allModelActivities = [];
    this.applicableFollowUps.forEach((f) => {
      let viewModel: MainCardViewDataModel = {
        id: f.ID,
        fixedHeight: true,
        primaryTextLeft: f.subject,
        secondaryTextLeft: f.statusString,
        sortDate: f.scheduledEnd,
        secondaryTextLeftTwo: isValid(f.scheduledEnd) ? format(new Date(f.scheduledEnd), this.dateTimeFormatsService.dateToUpper) : '',
        showArrow: true,
        arrowType: 'chevron-forward-outline',
        clickHandler: (id: string, event) => this.goToActivityDetails(id)
      }
      this.allModelActivities.push(viewModel);
    });
    this.applicableSystemFollowUps.forEach((f) => {
      let viewModel: MainCardViewDataModel = {
        id: f.ID,
        fixedHeight: true,
        primaryTextLeft: f.subject,
        secondaryTextLeft: f.state == 0 ? 'Open' : (f.state == 1 ? 'Completed' : (f.state == 2 ? 'Canceled' : (f.state == 3 ? 'Scheduled' : 'N/A'))),
        sortDate: f.scheduledEnd,
        secondaryTextLeftTwo: isValid(f.scheduledEnd) ? format(new Date(f.scheduledEnd), this.dateTimeFormatsService.dateToUpper) : '',
        showArrow: true,
        arrowType: 'chevron-forward-outline',
        clickHandler: (id: string, event) => this.goToSystemFollowUpActivityDetails(id)
      }
      this.allModelActivities.push(viewModel);
    });
    this.applicableMeetings.forEach((f: AppointmentActivity) => {
      let viewModel: MainCardViewDataModel = {
        id: f.ID,
        fixedHeight: true,
        primaryTextLeft: f.subject,
        secondaryTextLeft: f.getStatusString,
        sortDate: f.scheduledEnd,
        secondaryTextLeftTwo: isValid(f.scheduledEnd) ? format(new Date(f.scheduledEnd), this.dateTimeFormatsService.dateToUpper) : '',
        showArrow: true,
        arrowType: 'chevron-forward-outline',
        clickHandler: (id: string, event) => this.goToActivityDetails(id)
      }
      this.allModelActivities.push(viewModel);
    });
    this.applicableSurgeryOrder.forEach((f) => {

      // Hiding canceled procedure log in Opportunities section
      if(f.state == 2) {
            return;
      }

      let viewModel: MainCardViewDataModel = {
        id: f.ID,
        fixedHeight: true,
        primaryTextLeft: f.subject,
        secondaryTextLeft:  f.state == 0 ? 'Open' : (f.state == 1 ? 'Completed' : (f.state == 2 ? 'Canceled' : (f.state == 3 ? 'Scheduled' : 'N/A'))),
        sortDate: f.scheduledEnd,
        secondaryTextLeftTwo: isValid(f.scheduledEnd) ? format(new Date(f.scheduledEnd), this.dateTimeFormatsService.dateToUpper) : '',
        showArrow: true,
        arrowType: 'chevron-forward-outline',
        clickHandler: (id: string, event) => this.goToActivityDetails(id)
      }
      this.allModelActivities.push(viewModel);
    });
    this.applicableProcedureTrackers.forEach((f) => {

      // Hiding canceled procedure tracker in Opportunities section
      if(f.state == 2) {
            return;
      }

      let viewModel: MainCardViewDataModel = {
        id: f.ID,
        fixedHeight: true,
        primaryTextLeft: f.subject,
        secondaryTextLeft:  f.state == 0 ? 'Open' : (f.state == 1 ? 'Completed' : (f.state == 2 ? 'Canceled' : (f.state == 3 ? 'Scheduled' : 'N/A'))),
        sortDate: f.scheduledEnd,
        secondaryTextLeftTwo: isValid(f.scheduledEnd) ? format(new Date(f.scheduledEnd), this.dateTimeFormatsService.dateToUpper) : '',
        showArrow: true,
        arrowType: 'chevron-forward-outline',
        clickHandler: (id: string, event) => this.goToActivityDetails(id)
      }
      this.allModelActivities.push(viewModel);
    });
    this.applicableTeamActivities.forEach((f: OpportunityActivities) => {
      let viewModel: MainCardViewDataModel = {
        id: f.ID,
        fixedHeight: true,
        primaryTextLeft: f.subject,
        secondaryTextLeft: f.state == 0 ? 'Open' : (f.state == 1 ? 'Completed' : (f.state == 2 ? 'Canceled' : (f.state == 3 ? 'Scheduled' : 'N/A'))),
        sortDate: f.scheduledEnd,
        secondaryTextLeftTwo: isValid(f.scheduledEnd) ? format(new Date(f.scheduledEnd), this.dateTimeFormatsService.dateToUpper) : '',
      }
      this.allModelActivities.push(viewModel);
    });

    this.allModelActivities = this.allModelActivities.sort((b, a) => {
      if (a.sortDate.getTime() === 0) return 0;
      if (b.sortDate.getTime() === 0) return 0;
      if (a.sortDate > b.sortDate) return 1;
      if (a.sortDate < b.sortDate) return -1;
      return 1;
    });

    this.initAllModelStakeHolder();

    this.allModelCollaborator = [];
    if (this.opportunity && this.opportunity.collaborators && this.opportunity.collaborators.length > 0) {
      this.allModelCollaborator = this.opportunity.collaborators.filter(c => c.isActive).map((f) => {
        return {
          id: f.ID,
          fixedHeight: true,
          primaryTextLeft: f.fullName,
          secondaryTextLeft: f.role.name,
          showEndIcon: f.ID != this.authService.user.xSystemUserID && !this.isReadOnlyMode,
          endIconType: 'indegene-selectors-remove-icon',
          endIconCssClass: 'remove-icon',
          mainItemCssClass: 'selector-item',
          clickHandler: (id: string, event, specificTarget) => {
            if (specificTarget && specificTarget == 'endIcon') {
              this.handleCollaboratorRemove(id)
            }
          }
        };
      });
    }

    this.allModelProducts = [];
    if (this.opportunity.products && this.opportunity.products.length > 0) {
      this.allModelProducts = this.opportunity.products.map((f) => {
        let formattedCompletedValue = f.totalFormatted ? this.dateTimeFormatsService.formattedCurrency(f.totalFormatted) : '';
        let viewModel: MainCardViewDataModel = {
          id: f.productID,
          fixedHeight: true,
          primaryTextLeft: f.productName,
          secondaryTextLeft: formattedCompletedValue + ' for ' + f.quantity + ' units',
          showEndIcon: true,
          endIconType: 'indegene-selectors-remove-icon',
          endIconCssClass: 'remove-icon',
          mainItemCssClass: 'selector-item',
          clickHandler: (id: string, event, specificTarget) => {
            if (specificTarget && specificTarget == 'endIcon') {
              this.handleProductRemove(id)
            }
            else {
              this.handleProductLineItemAdd(id)
            }
          }
        }
        return viewModel;
      });
    }

    if (this.isCompetitorSectionVisible) {
      this.competitorDetailsHeader = {
        id: 'competitor-details-header',
        title: this.translate.instant('COMPETITOR_INFORMATION'),
        controls: [],
      };

      this.competitorsFormField = {
        label: this.translate.instant('COMPETITORS'),
        inputText: this._currentCompetitorsString,
        customPlaceholderLabel: !this.opportunityService.opportunityCompetitors ? this.translate.instant('NO_COMPETITORS') : this.translate.instant('SELECT_COMPETITORS'),
        id: 'competitors-field',
        isReadOnly: true,
        isDisabled: this.isReadOnlyMode || !this.opportunityService.opportunityCompetitors || !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
        showArrow: (!this.isReadOnlyMode && this.opportunityService.opportunityCompetitors) && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };

      this.competitorProducsFormField = {
        label: this.translate.instant('COMPETITOR_PRODUCTS'),
        inputText: this._currentCompetitorProductsString,
        customPlaceholderLabel: !this.allCompetitorProducts ? this.translate.instant('NO_COMPETITOR_PRODUCTS') : this.translate.instant('SELECT_COMPETITOR_PRODUCTS'),
        id: 'competitor-products-field',
        isReadOnly: true,
        isDisabled: this.isReadOnlyMode || !this.allCompetitorProducts.length || !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
        showArrow: (!this.isReadOnlyMode && !!this.allCompetitorProducts.length) && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };

      this.competitorBusinessValueFormField = {
        label: this.translate.instant('COMPETITOR_BUSINESS_VALUE'),
        inputText: this.opportunity.competitorBusinessValueFormatted ? this.dateTimeFormatsService.formattedCurrency(this.opportunity.competitorBusinessValueFormatted) : '',
        inputType: 'number',
        inputValue: (this.opportunity.competitorBusinessValue) ? this.opportunity.competitorBusinessValue : 0,
        id: 'competitor-business-value-field',
        isReadOnly: (this.isReadOnlyMode)|| !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? true : false,
        isDisabled: (this.isReadOnlyMode) || !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT)? true : false,
        customPlaceholderLabel: this.translate.instant('ENTER_COMPETITOR_BUSINESS_VALUE'),
        showArrow: !this.isReadOnlyMode && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
        formFieldType: FormFieldType.INLINE_INPUT,
        isRequired: false,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };

    }
    this.initFooterButtons();
    if(this.isSurveyConfigured){
      this.isSurveyValidEvent.emit(this.isSurveyValid);
    }
    if (this.opportunity.accountID) {
      let affiliatedContacts: Contact[] = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([this.accountService.getAccountById(this.opportunity.accountID)]);
      if (affiliatedContacts.length !== 0) {
        this.isAccountHaveAffiliatedContacts = true;
      }
    }
  }

  private initAllModelStakeHolder() {
    this.allModelStakeholder = [];
    if (this.opportunity.stakeholders && this.opportunity.stakeholders.length > 0) {
      let aggrStakeholder = [];
      this.opportunity.stakeholders.forEach(sh => {
        const stakeholder = aggrStakeholder.find(con => con.ID === sh.ID);
        if (!stakeholder) {
          aggrStakeholder.push(_.cloneDeep(sh));
        } else {
          stakeholder.role.name += ", " + sh.role.name;
        }
      });

      this.allModelStakeholder = aggrStakeholder.map((f) => {
        return {
          id: f.ID,
          fixedHeight: true,
          primaryTextLeft: f.fullName + (this.isStakeholderValueEnabled && f.stakeholderValue? ' ('+(this.opportunity.transactionCurrencySymbol?this.opportunity.transactionCurrencySymbol:'')+f.stakeholderValue+')' : ''),
          secondaryTextLeft: f.role.name,
          showEndIcon: false,
          endIconType: 'indegene-selectors-remove-icon',
          endIconCssClass: 'remove-icon',
          mainItemCssClass: 'selector-item',
          clickHandler: (id: string, event, specificTarget) => {
            // if(specificTarget && specificTarget=='endIcon'){
            // }
          }
        };
      });
    }
  }

    private initSurveyData(){
      this.isSurveyValidEvent.emit(true);
      if(this.opportunity.opportunityTypeId){
        // const selectedOpportunityType = this.opportunityService.opportunityTypes.find(a=> a.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
        if(this.selectedOpportunityType?.surveyTemplateId){
          // Load Survey Response Data
          this.loadSurveyResults();
          this.loadSurveyTemplate();
          this.isSurveyConfigured = true;
          this.surveyService.surveyActiveFor = 'Opportunity';
          //if(this.opportunity.statusCode == 1 || (this.opportunitySurveyResponseDataModel && this.opportunitySurveyResponseDataModel.length) || this.device.isOffline){
          if(this.isSurveyConfigured){
            this.opportunitySurveyHeaderModel = {
              id: 'survey-detail-header',
              title: this.translate.instant('OTHER_DETAILS'),
              controls: []
            }
          }else{
            this.opportunitySurveyHeaderModel = null;
          }
        }
      }
    }

    public initFooterButtons(){
      if(this.isConfirmFlowEnabled && !this.opportunity.isrevenuesystemcalculated && this.opportunity.statusCode==1){
        this.footerService.initButtons(FooterViews.OPPORTUNITY_INFO_OPEN);
        if(this.isReadOnlyMode){
          this.footerService.disableButton(['opportunityscrap','confirmopportunity', 'assignOwneropportunity']);
        }else if(this.device.isOffline ){
          this.footerService.disableButton(['confirmopportunity', 'assignOwneropportunity']);
          this.footerService.enableButtons(['opportunityscrap']);
        }else if(!this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT)){
          this.footerService.disableButton(['confirmopportunity', 'assignOwneropportunity','opportunityscrap']);
        }
        else{
          this.footerService.enableButtons(['opportunityscrap' , 'assignOwneropportunity']);
          if(!this.opportunity.estimatedValue ||
             !this.opportunity.stakeholders.length || 
            (this.isSurveyConfigured && !this.isSurveyValid) || 
            (this.selectedOpportunityType['indskr_estimatedcloseddatemandatory'] == EstimatedClosedDateMandatory.MANDATORY && !this.opportunity.estimatedDate) ||
            (!(this.opportunity.isrevenuesystemcalculated || this.opportunity.isestimatedrevenuebasedonprocedureandproducts) && this.selectedOpportunityType['indskr_productsmandatory'] == EstimatedClosedDateMandatory.MANDATORY && !this.opportunity.products.length
          )){
            this.footerService.disableButton(['confirmopportunity'])
          }     
          else this.footerService.enableButtons(['confirmopportunity']);
        }
      }
      else if(this.isConfirmFlowEnabled && !this.opportunity.isrevenuesystemcalculated && this.opportunity.statusCode==548910001){
        this.footerService.initButtons(FooterViews.OPPORTUNITY_INFO_CONFIRMED);
        if(!this.isCancelFlowEnabled) this.footerService.hideButton('closeasusercanceled');
        if(this.isReadOnlyMode){
          this.footerService.disableButton(['opportunityscrap','closeaswon','closeaslost','closeasusercanceled','assignOwneropportunity']);
        }else if(this.device.isOffline){
          this.footerService.disableButton(['opportunityscrap','closeaswon','closeaslost','closeasusercanceled', 'assignOwneropportunity']);
        }else{
          this.footerService.enableButtons(['closeasusercanceled', 'assignOwneropportunity']);
          this.footerService.disableButton(['opportunityscrap']);
          if((!this.opportunity.isrevenuesystemcalculated && !this.opportunity.estimatedValue) ||
            (!this.opportunity.isrevenuesystemcalculated && !this.opportunity.stakeholders.length) ||
            (this.isSurveyConfigured && !this.isSurveyValid) || 
            (this.selectedOpportunityType['indskr_estimatedcloseddatemandatory'] == EstimatedClosedDateMandatory.MANDATORY && !this.opportunity.estimatedDate) ||
            (!(this.opportunity.isrevenuesystemcalculated || this.opportunity.isestimatedrevenuebasedonprocedureandproducts) && this.selectedOpportunityType['indskr_productsmandatory'] == EstimatedClosedDateMandatory.MANDATORY && !this.opportunity.products.length)
            )
            this.footerService.disableButton(['opportunityscrap','closeaswon', 'closeaslost']);
          else this.footerService.enableButtons(['closeaswon', 'closeaslost']);
        }
      }
      else {
        this.footerService.initButtons(FooterViews.OPPORTUNITY_INFO_CONFIRMED);
        if(!this.isCancelFlowEnabled) this.footerService.hideButton('closeasusercanceled');
        if(this.isReadOnlyMode){
          this.footerService.disableButton(['opportunityscrap','closeaswon','closeaslost','closeasusercanceled', 'assignOwneropportunity']);
        }else if(this.device.isOffline){
          this.footerService.disableButton(['closeaswon','closeaslost','closeasusercanceled', 'assignOwneropportunity']);
          this.footerService.enableButtons(['opportunityscrap']);
        }else{
          this.footerService.enableButtons(['opportunityscrap','closeasusercanceled', 'assignOwneropportunity']);
          if((!this.opportunity.isrevenuesystemcalculated && !this.opportunity.estimatedValue) ||
             (!this.opportunity.isrevenuesystemcalculated && !this.opportunity.stakeholders.length) ||
             (this.isSurveyConfigured && !this.isSurveyValid) || 
             (this.selectedOpportunityType['indskr_estimatedcloseddatemandatory'] == EstimatedClosedDateMandatory.MANDATORY && !this.opportunity.estimatedDate) ||
             (!(this.opportunity.isrevenuesystemcalculated || this.opportunity.isestimatedrevenuebasedonprocedureandproducts) && this.selectedOpportunityType['indskr_productsmandatory'] == EstimatedClosedDateMandatory.MANDATORY && !this.opportunity.products.length)
            ) 
            this.footerService.disableButton(['closeaswon', 'closeaslost']);
          else this.footerService.enableButtons(['closeaswon', 'closeaslost']);
        }
      }
      // if(!this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT)){
      if(this.navService.getCurrentMasterPageName() == PageName.OpportunitySelectionPage && !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT)){     
        // this.footerService.disableButton(['closeaswon','closeaslost','opportunityscrap', 'closeasusercanceled','confirmopportunity']);
        this.footerService.hideAllButtons();
      }
    }

    private initOpportunityNotesModel(){
      this.notesHeaderModel = {
        id: 'task-notes-header',
        title: this.translate.instant('NOTES'),
        controls: [
          {
            id:'attach_note',
            text: this.translate.instant('ATTACH'),
            isDisabled: this.device.isOffline || this.isReadOnlyMode || !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
            isVisible: this.isNotesAttachmentEnabled && !this.isReadOnlyMode,
          },
          {
            id:'save_note',
            text: this.translate.instant('SAVE'),
            isDisabled: !this.isSaveNotesEnabled || this.device.isOffline || this.isReadOnlyMode,
            isVisible: !this.isReadOnlyMode,
          }
        ]
      };
      let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
      this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
    }

    public notesChanged(ev): void {
      if(ev && ev.target){
        this.tempNoteText = ev.target.value;
      }else{
        this.tempNoteText = '';
      }
      if(this.tempNoteText.length > 0 || this.attachmentFile){
        this.isSaveNotesEnabled = true;
      }else{
        this.isSaveNotesEnabled = false;
      }
      this.initOpportunityNotesModel();
    }
  
  async goToSystemFollowUpActivityDetails(id) {
    if (this.device.isOffline) return;
    this.uiService.displayLoader();
    let realTimeDetails = await this.activityDataService.getActivityByIdOnline(id, 'followuptask');
    let activity = new FollowUpActivity(realTimeDetails);
    this.activityDataService._appendFollowUpDetailsToActivity(activity, realTimeDetails);
    this.activityService.selectedActivity = activity;
    await this.followUpActivityDataService.updateFollowUpActivity({ onDynamics: false, onLocalDatabase: true, onLocalCopy: true, appendActivityDetails: true }, [activity as FollowUpActivity], new Date().getTime())
      .then(succ => {}).catch(err => {});
    this.uiService.dismissLoader();
    this.trackingService.tracking('SelectOpportunityfollowup', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    this.navService.pushChildNavPageWithPageTracking(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent,
      this.navService.getCurrentMasterPageName(), { from: 'OpportunityManagementTool', activity: activity });
  }

    async goToActivityDetails(id) {
        //let followUp = this.applicableFollowUps.find(f=>f.ID == id);
        // this.activityService.selectedActivity = this.activityService.activities.find(o=> o.ID == id);
        //online call to get the latest data
        const index = this.activityService.activities.findIndex(o => o.ID == id);
        let activity;
        if (index >= 0) {
            activity = this.activityService.activities[index];
            this.activityService.selectedActivity = this.activityService.activities[index];
        }else{
            return;
        }
        if (!this.device.isOffline && !id.includes('offline')) {
            this.uiService.displayLoader();
            if(activity instanceof FollowUpActivity){
              let realTimeDetails = await this.activityDataService.getActivityByIdOnline(id, 'followuptask');
              const index = this.activityService.activities.findIndex(o => o.ID == id);
              if (index >= 0 && realTimeDetails) {
                  this.activityDataService._appendFollowUpDetailsToActivity(activity, realTimeDetails);
                  this.activityService.selectedActivity = activity;
                  await this.followUpActivityDataService.updateFollowUpActivity({ onDynamics: false, onLocalDatabase: true, onLocalCopy: true, appendActivityDetails: true }, [activity as FollowUpActivity], new Date().getTime()).then(succ => {
                      if (!this.uiService.toolsActivityActive) {
                          this.eventService.publish("refreshAgenda");
                      }
                      else {
                          this.uiService.agendaRefreshRequired = true;
                      }
                  }).catch(err => {
                      //To Do error Handling
                  });
              }
            }else if( activity instanceof AppointmentActivity){
              await this.activityDataService.updateActivityDetails(activity as AppointmentActivity);
            }
            else if (activity instanceof SurgeryOrderActivity) {
              await this.activityDataService.updateActivityDetails(activity as SurgeryOrderActivity);
            }
            else if (activity instanceof ProcedureTrackerActivity) {
              await this.activityDataService.updateActivityDetails(activity as ProcedureTrackerActivity);
            }
            this.uiService.dismissLoader();
        }
        let fromIdentifier: string = (this.layoverViewPushedFrom == 'OpportunityManagementTool') ? 'OpportunityManagementTool' :
            (this.layoverViewPushedFrom == 'AccountProfileOpportunities' ? 'AccountProfileOpportunities' : 'AccountPlanOpportunities');
        let masterPage = this.navService.getCurrentMasterPageName();
        if( activity instanceof FollowUpActivity){
          this.trackingService.tracking('SelectOpportunityfollowup', TrackingEventNames.OPPORTUNITYMANAGEMENT)
          this.navService.pushChildNavPageWithPageTracking(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent,
            masterPage, { from: fromIdentifier, activity: activity })
        }else if (activity instanceof AppointmentActivity){
          this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent,
            masterPage, { from: 'OpportunityManagementTool', activity: activity }).then(succ=> {
              this.eventService.publish("detectChangesOnActivityDetails");
            })
        }
        else if (activity instanceof SurgeryOrderActivity) {
          this.navService.pushChildNavPageWithPageTracking(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent,
            masterPage, { from: 'OpportunityManagementTool', activity: activity }).then(succ => {
              this.eventService.publish("detectChangesOnActivityDetails");
            })
        }
        else if (activity instanceof ProcedureTrackerActivity) {
          this.navService.pushChildNavPageWithPageTracking(ProcedureTrackerDetailsComponent, PageName.ProcedureTrackerDetailsComponent,
            masterPage, { from: 'OpportunityManagementTool', activity: activity }).then(succ => {
              this.eventService.publish("detectChangesOnActivityDetails");
            })
        }

    }

    ngOnInit() {
      // if (this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_OPTIONSET_FIELDS)) {
      //   if (!_.isEmpty(this.opportunityService.opportunityTypes)) {
      //     const captitalSalesType = this.opportunityService.opportunityTypes.find(ot => ot.indskr_description == 'CAPITAL_SALES');
      //     this.isCapitalSalesType = this.opportunity.opportunityTypename == 'Capital Sales' || (captitalSalesType && captitalSalesType.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
      //     const salesType = this.opportunityService.opportunityTypes.find(ot => ot.indskr_description == 'SALES');
      //     this.isSalesType = this.opportunity.opportunityTypename == 'Sales' || (salesType && salesType.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
      //     if (this.isSalesType) {
      //       this.isSalesType = false;
      //       if (this.opportunity.accountID) {
      //         const acc = this.accountService.getAccountById(this.opportunity.accountID);
      //         if (acc && acc.extendedDetails && acc.extendedDetails.accountType == 'Government') this.isSalesType = true;
      //       }
      //     }
      //   }
      // }
      //if (this.isCapitalSalesType || this.isSalesType) this.optionSets = _.cloneDeep(this.opportunityService.opportunityOptionSets);
      if (this.from) {
        this.isLayoverPushView = true;
        this.layoverViewPushedFrom = this.from;
      }
      if (this.opportunity) {
        this.initOpportunityBusinessProcessStages();
        this.selectedOpportunityType = this.opportunityService.opportunityTypes.find(ot => ot.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
        this.products = !_.isEmpty(this.brandService?.brands) ? this.brandService?.brands : [];
        if (this.selectedOpportunityType?.indskr_productdisplay == PRODUCT_DISPLAY.NPI_PRODUCTS_ONLY) {
          this.productDisplay = PRODUCT_DISPLAY.NPI_PRODUCTS_ONLY;
          this.products = this.products.filter(p => p.indskr_newproductintroduction);
        } else if (this.selectedOpportunityType?.indskr_productdisplay == PRODUCT_DISPLAY.ALL_EXCLUDING_NPI_PRODUCTS) {
          this.productDisplay = PRODUCT_DISPLAY.ALL_EXCLUDING_NPI_PRODUCTS;
          this.products = this.products.filter(p => !p.indskr_newproductintroduction);
        }
        if (this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_COMPETITOR_DATA)) {
          this.isCompetitorSectionVisible = true;
          this.isOpportunityCompetitorDataEnabled = true;
          if (this.caseManagementService.caseProductSKU) {
            this.allCompetitorProducts = this.caseManagementService.caseProductSKU.filter(p => p.isCompetitorProduct && p.isCompetitorProduct == 'true');
          }
        } else {
          if ((this.opportunity.state != "Open" || this.navService.getCurrentMasterPageName() == PageName.OpportunitySelectionPage) && (this.opportunity.competitorBusinessValue || this.opportunity.competitors.length > 0 || this.opportunity.competitorProducts.length > 0)) {
            this.isCompetitorSectionVisible = true;
          }
        }
        if (this.opportunity.createdByUserID === this.authService.user.xSystemUserID) this.isMyOpportunity = true;
        if (this.opportunity.state != 'Open') this.isOpportunityClosed = true;
        if (this.opportunity.state == 'Open' && this.opportunity.collaboratorType != CollaboratorType.BEHOLDER) {
          this.isReadOnlyMode = false;
        }
        this.isNotesAttachmentEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_ATTACHMENT_UPLOAD);
        if (!this.opportunity.isrevenuesystemcalculated && this.opportunity.collaboratorType != CollaboratorType.BEHOLDER) {
          if (this.device.isOffline) {
            this.isReadOnlyMode = true;
          } else {
            this.isReadOnlyMode = false;
          }
        }
        if (!this.isReadOnlyMode) {
          if ((this.opportunity.accountID && this.opportunity.countryId) || this.opportunity.isestimatedrevenuebasedonprocedureandproducts) {
            this.isAddProductEnabled = true;
          }
          if (this.opportunity.estimatedDate) {
            let estimatedDate: Date = new Date(parseInt(this.opportunity.estimatedDate))
            //var date_utc = Date.UTC(estimatedDate.getFullYear(), estimatedDate.getMonth(), estimatedDate.getDate(), 0, 0, 0);
            this.currentOpportunityEstimatedDate = estimatedDate.toISOString();
          } else {
            this.currentOpportunityEstimatedDate = '';
          }
        }
        if (this.navService.getCurrentMasterPageName() == PageName.OpportunitySelectionPage) {
          this.isReadOnlyMode = true;
          this.isAddProductEnabled = false;
        }
        this.initSurveyData();
      }
      this.productHierarchies = this.isOpportunityByProductsEnabled ? this.surgeryOrderDataService.procedureTrackerProductHierarchies : this.surgeryOrderDataService.productHierarchies;
      if (this.authService.hasFeatureAction(FeatureActionsMap.DISCONTINUE_OPPORTUNTIY)) this.isCancelFlowEnabled = true;
      if (this.authService.hasFeatureAction(FeatureActionsMap.CONFIRM_OPPORTUNTIY)) this.isConfirmFlowEnabled = true;
      this.isStakeholderValueEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_STAKEHOLDER_VALUE);
      if(this.authService.user.buConfigs['indskr_primarysubspecialty']){
        this.initSpecialtiesData();
        // this.isSpecialtyFieldsEnabled = true;
      }else{
        // this.isSpecialtyFieldsEnabled = false;
      }
      if(this.authService.user.buConfigs['indskr_primarysubspecialty']){// && this.authService.hasFeatureAction(FeatureActionsMap.MBP_FIELDS)){
        // this.initSpecialtiesData();
        this.isSpecialtyFieldsEnabled = true;
      }else{
        this.isSpecialtyFieldsEnabled = false;
      }
      // if(this.authService.hasFeatureAction(FeatureActionsMap.MBP_FIELDS)){
      //   // this.initSpecialtiesData();
      //   this.isFocusAreaEnabled = true;
      // }else{
      //   this.isFocusAreaEnabled = false;
      // }
      
      this.initOpportunityInfoFormFields();
      this.opportunityService.selectedOpportunityOwnerID = this.opportunity.createdByUserID;
      this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(async (data) => {
        if (!this.opportunity.isrevenuesystemcalculated && this.opportunity.collaboratorType != CollaboratorType.BEHOLDER) {
          if (this.device.isOffline || this.navService.getCurrentMasterPageName() == PageName.OpportunitySelectionPage) {
            this.isReadOnlyMode = true;
          } else {
            this.isReadOnlyMode = false;
          }
          this.initOpportunityInfoFormFields();
        }
        if (this.opportunity.isrevenuesystemcalculated && this.device.isOffline) {
          this.isAddProductEnabled = false;
          this.initOpportunityInfoFormFields();
        }
        this.initOpportunityNotesModel();
        if (this.opportunity.opportunityTypeId && !this.device.isOffline && !this.opportunity.surveyResponseData) {
          // const selectedOpportunityType = this.opportunityService.opportunityTypes.find(a=> a.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
          if (this.selectedOpportunityType?.surveyTemplateId) {
            const foundTemplate = this.surveyService.surveyForms.find(form => form.surveyFormId == this.selectedOpportunityType.surveyTemplateId);
            if (foundTemplate) {
              this.uiService.displayLoader();
              await this.opportunityDataService.fetchSurveyJSAtrributesForOpportunity(this.opportunity, foundTemplate);
              this.initSurveyData();
              this.uiService.dismissLoader();
            }
          }
        }
      });
      this.autoSubjectEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_AUTO_SUBJECT);
      setTimeout(() => {
        this._startTakingQuestionUpdatesForSurveyJS = true;
      }, 100);
    }

    public onFieldClick(event){
        if(event && event.id){
            switch(event.id){
                case 'country-field':
                    this.handleCountrySelection();
                    break;
                case 'account-plan-field':
                  this.handleAccountPlanSelect(event);
                  break;
                case 'marketing-plan-field':
                  this.openMarketingPlans(event);
                  break;
                case 'account-plan-objective-field':
                    this.handleObjectiveSelect(event);
                    break;
                case 'end-date-field':
                    this.openEndDatePicker(event);
                    break;
                case 'desc-field':
                    //this.handleEditDescription(event);
                    break;
                case 'discount-field':
                    //this.handleEditDiscount(event);
                    break;
                case 'stakeholder-field':
                    this.handleStakeholderField(event);
                    break;
                case 'stage-field':
                    this.handleStageFieldSelection(event);
                    break;
                case 'specialty-field':
                    this.handleSpecialtySelect(event);
                    break;
                case 'sub-specialty-field':
                    this.handleSubSpecialtySelect(event);
                    break;  

                default:
                    console.log("Unhandled switch case statement");
                    break;
            }
            this._isClicked[event.id] = true;
        }
    }

    public onSectionHeaderControlClick(id:string){
        switch(id){
            case 'product_line_item_add':
                this.handleProductLineItemAdd();
                break;
            case 'stakeholder_line_item_add':
                this.handleStakeholderField();
                break;
            case 'collaborator_line_item_add':
                this.handleCollaboratorField();
                break;
            case 'save_note':
                this.handleSaveNote();
                break;
            case 'attach_note':
                this.handleNoteAttachment();
                break;
            default:
                console.log('Unhandled switch case statement');
                break;
        }
    }

    private async handleCountrySelection() {
        if (this.isReadOnlyMode) return;
        let countriesList = this.opportunity.isrevenuesystemcalculated? this.sharedDataService.countriesWithPriceList : this.sharedDataService.allCountriesList;
        let isConfirmationRequired: boolean = (this.opportunity.isrevenuesystemcalculated && this.opportunity.products && this.opportunity.products.length > 0)
        let confirmationData;
        if (isConfirmationRequired) {
            confirmationData = new ConfirmationAlertData({
                message: this.translate.instant('OM_CHANGING_SELECTION_WILL_CLEAR_PRODUTCS'),
                title: this.translate.instant('OM_CHANGE_COUNTRY')+'?',
                cancelButtonText: this.translate.instant('CANCEL'),
                confirmButtonText: this.translate.instant('YES'),
            });
        }
        if (countriesList && countriesList.length > 0) {
            let selectListDetail: SelectListDetail = {
                isMultipleSelectionEnabled: false,
                isSearchEnabled: false,
                searchTitle: this.translate.instant('SEARCH'),
                title: this.translate.instant('COUNTRIES'),
                dividerTitle: this.translate.instant('OM_ALL_COUNTRIES'),
                confirmationCheckRequired: isConfirmationRequired,
                confirmationAlertData: confirmationData,
                data: countriesList.map(country => {
                    return {
                        id: country.countryId,
                        title: country.countryName,
                        isSelected: (country.countryId == this.opportunity.countryId) ? true : false,
                    }
                })
            };
            selectListDetail.data = countriesList.map(country => {
                return {
                    id: country.countryId,
                    title: country.countryName,
                    isSelected: (country.countryId == this.opportunity.countryId) ? true : false,
                }
            });
            selectListDetail.isMultipleSelectionEnabled = false;
            selectListDetail.isSearchEnabled = true;
            selectListDetail.title = this.translate.instant('COUNTRIES');
            selectListDetail.dividerTitle = this.translate.instant('COUNTRIES_WITH_PRICE_LIST');
            this._selectCountryModal = await this.modalCtrl.create({component: SelectListComponent,componentProps: { viewData: selectListDetail }, backdropDismiss: false })
            this._selectCountryModal.present();
            this._selectCountryModal.onDidDismiss().then(async (data: any) => {
                data=data.data;
                if (data && data.isDone) {
                    if ((data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.countryId)
                        || ((!data.selectedItems || data.selectedItems.length == 0) && this.opportunity.countryId)) {
                        if (data.selectedItems && data.selectedItems.length == 1) {
                            this.opportunity.priceList = '';
                            this.opportunity.transactionCurrencySymbol = '';
                            this.opportunity.transactionCurrencyID = '';
                            this.opportunity.countryId = data.selectedItems[0].id;
                            this.opportunity.countryName = data.selectedItems[0].title;
                            if(this.opportunity.isrevenuesystemcalculated && !this.opportunity.isestimatedrevenuebasedonprocedureandproducts){
                              this.opportunity.products = [];
                            }
                            if(this.opportunity.accountID){
                                this.isAddProductEnabled = true;
                            }
                        } else {
                            this.opportunity.priceList = '';
                            this.opportunity.countryId = '';
                            this.opportunity.countryName = '';
                            if(this.opportunity.isrevenuesystemcalculated && !this.opportunity.isestimatedrevenuebasedonprocedureandproducts){
                              this.opportunity.products = [];
                            }
                            if(!this.opportunity.isestimatedrevenuebasedonprocedureandproducts){
                              this.isAddProductEnabled = false;
                            }
                        }
                        if(this.opportunity.isrevenuesystemcalculated && !this.opportunity.isestimatedrevenuebasedonprocedureandproducts){
                          this.opportunity.estimatedValue = 0;
                          this.opportunity.estimatedValueFormatted = ((this.opportunity.transactionCurrencySymbol)?this.opportunity.transactionCurrencySymbol:'')+'0.00';
                        }
                        // this.opportunity.products.map((o) => {
                        //     o.isDeleted = true;
                        // })
                        this.opportunity.pendingPushToDynamics = true;
                        this.uiService.displayLoader();
                        await this.opportunityDataService.updateOpportunity({ onDynamics: !this.device.isOffline, onLocalCopy: true, onLocalDatabase: true, operationDetail: { code: 'updateopportunitycountry', message: 'Update Opportunity Country'} }, [this.opportunity]).then(success => {
                            //Successfull Action
                            this.uiService.dismissLoader();
                        }).catch(error => {
                            //Handle error occured during follow up activity updation
                            this.uiService.dismissLoader();
                        });
                    }
                }
            });
        }
    }

    private async handleAccountPlanSelect(data){
        if(this.isReadOnlyMode) return;
        this.trackingService.tracking('SelectAccountPlan', TrackingEventNames.OPPORTUNITYMANAGEMENT)
        if(!this.opportunity.accountID){
            this.notificationService.notify(this.translate.instant('FOLLOW_UP_NOTIFY_SELECT_ACCOUNT'),'Opportunity-Details','top','danger');
        }else{
            let accountPlans = this.accountManagementService.accountPlans?.filter(plan => {
              plan.accountPlanTimeFrame = isPast(parseInt(plan.endDate)) ? 'Past'
                                : isFuture(parseInt(plan.startDate)) ? 'Future' : 'Present';
              return (plan.accountId == this.opportunity.accountID && plan.accountPlanTimeFrame != 'Past' && plan.status == 'Open');
            });
            if(Array.isArray(accountPlans) && accountPlans.length > 0){
                let dropdownListDetail:IndDropdownListDetailModel = {
                    id: 'Opportunity-Accountplan-Select',
                    data: accountPlans.map(plan=>{
                        let obj = {
                            title: plan.accountPlanName,
                            id: plan.ID,
                            isSelected: false,
                        };
                        if(this.opportunity.accountPlanID && plan.ID == this.opportunity.accountPlanID){
                            obj['isSelected'] = true;
                        }
                        return obj;
                    }),
                };
                this._accountPlanPopover = await this.popoverCtrl.create({component: IndDropdownListComponent,componentProps: {viewData:dropdownListDetail}, cssClass:'dropdown-list-view', event:data.event});
                this._accountPlanPopover.onDidDismiss().then(async (data)=>{
                    data=data.data;
                    if(data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.accountPlanID){
                        this.opportunity.accountPlanID = data.selectedItems[0].id;
                        this.opportunity.accountPlanName = data.selectedItems[0].title;
                        this.opportunity.opportunityObjectiveID = '';
                        this.opportunity.opportunityObjectiveName = '';
                        this.opportunity.pendingPushToDynamics = true;
                        this.uiService.displayLoader();
                        await this.opportunityDataService.updateOpportunity({
                        onDynamics:!this.device.isOffline,
                        onLocalCopy:true,
                        onLocalDatabase:true,
                        operationDetail: {
                          code: 'updateopportunityaccountplan',
                          message: 'Update Opportunity Account Plan',
                        }
                        }, [this.opportunity]).then(succ=>{
                            // Successfull action
                            // Calculation not required on app side // Account Plan Estimated Revenue Calculation For Offline View Details
                            // if(this.opportunity.accountPlanID){
                            //   this.accountManagementDataService.updateEstimatedAndActualRevenueOfAccountPlans();
                            // }
                            this.uiService.dismissLoader();
                        }).catch(async error => {
                            // To Do Error Handling
                            this.uiService.dismissLoader();
                        });
                    }
                    this._accountPlanPopover = undefined;
                });
                this._accountPlanPopover.present();
            }
        }
    }

    private async openMarketingPlans(data) {
      if (this.isReadOnlyMode && _.isEmpty(this.opportunity.marketingBusinessPlans)) return;
      this.trackingService.tracking('SelectMarketingPlan', TrackingEventNames.OPPORTUNITYMANAGEMENT)
      if (!this.opportunity.accountID) {
        this.notificationService.notify(this.translate.instant('FOLLOW_UP_NOTIFY_SELECT_ACCOUNT'), 'Opportunity-Details', 'top', 'danger');
      } else {
        let marketingPlans = this.marketingPlansManagementOfflineService.marketingPlans?.filter(plan => {
          plan.brandPlanTimeFrame = isPast(parseInt(plan.endDate)) ? 'Past'
            : isFuture(parseInt(plan.startDate)) ? 'Future' : 'Present';
          return plan.brandPlanTimeFrame != 'Past' && plan.status == 'Open';
        });
        if (!_.isEmpty(marketingPlans)) {
          const marketingPlansSelectionViewData: MarketingPlanSelectionViewDataModel = {
            isReadOnlyMode: this.isReadOnlyMode,
            selectedMarketingPlans: this.opportunity.marketingBusinessPlans,
            disableDoneButtonInOffline: false,
            viewMode: this.isReadOnlyMode ? ComponentViewMode.READONLY : ComponentViewMode.ADDNEW,
            callbackFromSelectionComponent: (event: string, newSelectedMarketingPlans: MarketingPlan[], data: any) => this._handledMarketingPlansSelection(event, newSelectedMarketingPlans, data),
          };
          this.navService.pushWithPageTracking(MarketingPlanManagementPageComponent, PageName.MarketingPlanManagementPageComponent, { viewData: marketingPlansSelectionViewData, from: PageName.OpportunityInfoComponent }, PageName.MarketingPlanManagementPageComponent);
        }
      }
    }

    private async _handledMarketingPlansSelection(ev, selectedMarketingPlans, data) {
      if (ev && ev == 'DONEBUTTONCLICK') {
        let marketingPlans = this.marketingPlansManagementOfflineService.marketingPlans?.filter(plan => {
          plan.brandPlanTimeFrame = isPast(parseInt(plan.endDate)) ? 'Past'
            : isFuture(parseInt(plan.startDate)) ? 'Future' : 'Present';
          return plan.brandPlanTimeFrame != 'Past' && plan.status == 'Open';
        });
        const data = selectedMarketingPlans;
        // if (data && data.selectedItems) {
        if (_.isEmpty(selectedMarketingPlans)) this.opportunity.marketingBusinessPlans.forEach(mbp => mbp['isDeleted'] = true);
        else {
          selectedMarketingPlans.forEach(item => {
            const mbp = marketingPlans.find(mp => mp.ID == item.ID);
            if (mbp) this.opportunity.marketingBusinessPlans.push(mbp);
          });
          this.opportunity.marketingBusinessPlans.forEach(mbp => {
            if (!selectedMarketingPlans.map(item => item.ID).includes(mbp.ID)) mbp['isDeleted'] = true;
          })
        }
        this.opportunity.marketingBusinessPlans = _.uniqBy(this.opportunity.marketingBusinessPlans, 'ID');
        this.opportunity.pendingPushToDynamics = true;
        await this.uiService.displayLoader();
        await this.opportunityDataService.updateOpportunity({
          onDynamics: !this.device.isOffline,
          onLocalCopy: true,
          onLocalDatabase: true,
          operationDetail: {
            code: 'updateopportunitymarketingplan',
            message: 'Update Opportunity Marketing Plan',
          }
        }, [this.opportunity]).then(succ => {
          this.opportunity.marketingBusinessPlans = this.opportunity.marketingBusinessPlans.filter(mbp => !mbp['isDeleted']);
          this.opportunity.marketingBusinessPlans = _.orderBy(this.opportunity.marketingBusinessPlans, 'brandPlanName');
          this.opportunity.marketingBusinessPlans.forEach(async oppMbp => {
            const index = this.marketingPlansManagementOfflineService.marketingPlans.findIndex(mbp => mbp.ID == oppMbp.ID);
            if (index > -1 && !this.marketingPlansManagementOfflineService.marketingPlans[index].accounts.some(acc => acc.id == this.opportunity.accountID)) {
              const payload = { accounts: [{ "id": this.opportunity.accountID }] };
              await this.marketingPlanManagementDataService.saveMarketPlan(payload, this.marketingPlansManagementOfflineService.marketingPlans[index].ID);
              this.marketingPlansManagementOfflineService.marketingPlans[index].accounts.push({ id: this.opportunity.accountID, name: this.opportunity.accountName, statecode: 1 });
              oppMbp.accounts = this.marketingPlansManagementOfflineService.marketingPlans[index].accounts;
            }
            await this.uiService.dismissLoader();
          })
        }).catch(async error => {
          console.error("Failed to save marketing plan for opportunities: ", error);
          await this.uiService.dismissLoader();
        });
        // }
      }
    }

    private async handleObjectiveSelect(data){
        if(this.isReadOnlyMode) return;
        this.trackingService.tracking('SelectAccountPlanObjective', TrackingEventNames.OPPORTUNITYMANAGEMENT)
        // if(!this.opportunity.accountPlanID){
        //     this.notificationService.notify('Please select an account plan first.','Opportunity-Details','top','danger');
        // }else{
            let accountObjectives = this.accountManagementService.getAccountPlanObjectiveByPlanId(this.opportunity.accountPlanID);
            if(Array.isArray(accountObjectives) && accountObjectives.length > 0){
                let dropdownListDetail:IndDropdownListDetailModel = {
                    id: 'Opportunity-Accountobjective-Select',
                    data: accountObjectives.map(objective=>{
                        let obj = {
                            title: objective.name,
                            id: objective.objectiveID,
                            isSelected: false,
                        };
                        if(this.opportunity.opportunityObjectiveID && objective.objectiveID == this.opportunity.opportunityObjectiveID){
                            obj['isSelected'] = true;
                        }
                        return obj;
                    }),
                };
                this._accountObjectivePopover = await this.popoverCtrl.create({component: IndDropdownListComponent,componentProps: {viewData:dropdownListDetail}, cssClass:'dropdown-list-view', event:data.event});
                this._accountObjectivePopover.onDidDismiss().then(async (data)=>{
                    data = data.data;
                    if(data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.opportunityObjectiveID){
                        this.opportunity.opportunityObjectiveID = data.selectedItems[0].id;
                        this.opportunity.opportunityObjectiveName = data.selectedItems[0].title;
                        this.opportunity.pendingPushToDynamics = true;
                        this.uiService.displayLoader();
                        await this.opportunityDataService.updateOpportunity({
                        onDynamics:!this.device.isOffline,
                        onLocalCopy:true,
                        onLocalDatabase:true,
                        operationDetail: {
                          code: 'updateopportunityobjective',
                          message: 'Update Opportunity Objective'
                        }
                        }, [this.opportunity]).then(succ=>{
                            // Successfull action
                            this.uiService.dismissLoader();
                        }).catch(async error => {
                            // To Do Error Handling
                            this.uiService.dismissLoader();
                        });
                    }
                    this._accountObjectivePopover = undefined;
                })
                this._accountObjectivePopover.present();
            }
        //}
    }

    private async handleEditDescription(data) {
        if (!this.isReadOnlyMode) {
          this.trackingService.tracking('EnterDescription', TrackingEventNames.OPPORTUNITYMANAGEMENT)
          if (data && data.target.value != this.opportunity.opportunityName) {
            this.opportunity.opportunityName = data.target.value;
            this.handleUpdates.emit(this.opportunity.opportunityName);
            if (!data.target.value || data.target.value == '') {
              this.opportunity.opportunityName = 'Opportunity';
            }
            this.opportunity.pendingPushToDynamics = true;
            this.uiService.displayLoader();
            await this.opportunityDataService.updateOpportunity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true, operationDetail: { code: 'updateopportunitydesc', message: 'Update Opportuntiy Description'} }, [this.opportunity]).then(success => {
              //Handle successfull response
              this.uiService.dismissLoader();
            }).catch(async error => {
              // Handle error occured
              this.uiService.dismissLoader();
            });
          }
        }
    }
  
    private async handleEditDiscount (data) {
      if (!this.isReadOnlyMode) {
        this.trackingService.tracking('SelectEligibleDiscount', TrackingEventNames.OPPORTUNITYMANAGEMENT)
        if (data && parseInt(data.target.value) != this.opportunity.elligibleDiscount && parseInt(data.target.value) >= 0 && parseInt(data.target.value) <= 100 && !data.target.value.includes('.')) {
          this.opportunity.elligibleDiscount = parseInt(data.target.value);
          let total: number = this.opportunity.products.map(product => product.total).reduce((a, b) => (a - 0) + (b - 0), 0);
          this.opportunity.estimatedValue = parseFloat((total * (1 - (this.opportunity.elligibleDiscount / 100))).toFixed(2))
          this.opportunity.estimatedValueFormatted = this.opportunity.transactionCurrencySymbol + Utility.getFormattedNumber(this.opportunity.estimatedValue);
          this.opportunity.pendingPushToDynamics = true;
          this.uiService.displayLoader();
          await this.opportunityDataService.updateOpportunity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true, operationDetail : { code: 'updateopportunitydiscount', message: 'Update Opportunity Discount'}}, [this.opportunity]).then(success => {
            //Handle successfull response
            this.uiService.dismissLoader();
          }).catch(async error => {
            // Handle error occured
            this.uiService.dismissLoader();
          });
        } else {
          this.initOpportunityInfoFormFields();
        }
      }
    }

    private async handleEditActualValue (data){
      if (!this.isReadOnlyMode && !this.opportunity.isrevenuesystemcalculated) {
        if (data && parseInt(data.target.value) != this.opportunity.closedValue && parseInt(data.target.value) >= 0 && !data.target.value.includes('.')) {
          this.opportunity.closedValue = parseInt(data.target.value);
          this.opportunity.closedValueFormatted = this.opportunity.transactionCurrencySymbol + Utility.getFormattedNumber(this.opportunity.closedValue);
          this.opportunity.pendingPushToDynamics = true;
          this.uiService.displayLoader();
          await this.opportunityDataService.updateOpportunity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true, operationDetail: { code: 'updateactualvalue', message : 'Update Opportunity Actual Value'} }, [this.opportunity]).then(success => {
            //Handle successfull response
            this.uiService.dismissLoader();
          }).catch(async error => {
            // Handle error occured
            this.uiService.dismissLoader();
          });
        } else {
          this.initOpportunityInfoFormFields();
        }
      }
    }

    private async handleEditEstimatedValue (data){
      if (!this.isReadOnlyMode && !this.opportunity.isrevenuesystemcalculated) {
        this.isClickedEstimatedValue = true;
        if (data && parseInt(data.target.value) != this.opportunity.estimatedValue && parseInt(data.target.value) >= 0 && !data.target.value.includes('.')) {
          let previousEstimatedValue = this.opportunity.estimatedValue;
          let newEstimatedValue = parseInt(data.target.value);
          this.opportunity.estimatedValue = parseInt(data.target.value);
          this.opportunity.estimatedValueFormatted = this.opportunity.transactionCurrencySymbol + Utility.getFormattedNumber(this.opportunity.estimatedValue);
          this.opportunity.pendingPushToDynamics = true;
          this.handleUpdates.emit();
          this.uiService.displayLoader();
          await this.opportunityDataService.updateOpportunity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true , operationDetail: { code: 'updateestimatedvalue', message : 'Update Opportunity Estimated Value'} }, [this.opportunity]).then(success => {
            //Handle successfull response
            // Calculation not required on app side // Account Plan Estimated Revenue Calculation For Offline View Details
            // if(this.opportunity.accountPlanID){
            //   this.accountManagementDataService.updateEstimatedAndActualRevenueOfAccountPlans();
            // }
            this.uiService.dismissLoader();
          }).catch(async error => {
            // Handle error occured
            this.uiService.dismissLoader();
          });
        } else {
          this.initOpportunityInfoFormFields();
        }
      }
    }

    private handleStakeholderField(event?){
        // if(this.isReadOnlyMode || !this.isAccountHaveAffiliatedContacts) return;
        // this.trackingService.tracking('SelectStakeholder', TrackingEventNames.OPPORTUNITYMANAGEMENT)
        // this.uiService.prevView = this.uiService.activeView;
        // this.uiService.activeView = '';
        // this.contactService.isSchedulerInvoked = false;
        // this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
        // this.contactService.accessedContactListFrom = PageName.OpportunityDetailsComponent;
        // let passedData = {
        //     type:'PushedContactSelectionView',
        //     contactListData:
        //     {
        //         for:'OpportunityStakeholderSelection',
        //         opportunity: this.opportunity
        //     }
        // };
        // this.contactSelectionSubscriptionFlag = true;
        // this.navService.pushWithPageTracking(ContactPageComponent,PageName.ContactPageComponent,passedData,PageName.OpportunityDetailsComponent,{animate:false},null);
        // if(!this.isAccountHaveAffiliatedContacts) {
        //   this.notificationService.notify(this.translate.instant('NO_AFFILIATED_STAKEHOLDERS_FOR_ACCOUNT'),'Opportunity-Details','top','danger');
        //   return;
        // }
        this.trackingService.tracking('SelectStakeholder', TrackingEventNames.OPPORTUNITYMANAGEMENT)
        let passedData = {
            type:'PushedContactSelectionView',
            contactListData:
            {
                for:'OpportunityStakeholderSelection',
                opportunity: this.opportunity
            }
        };
        this.contactSelectionSubscriptionFlag = true;

        if(this.isOpportunityClosed && this.opportunity.stakeholders.length > 0) {
            this.uiService.prevView = this.uiService.activeView;
            this.uiService.activeView = '';
            this.contactService.isSchedulerInvoked = false;
            this.contactService.contactPageMode = ComponentViewMode.READONLY;
            this.contactService.accessedContactListFrom = PageName.OpportunityDetailsComponent;
            this.navService.pushWithPageTracking(ContactPageComponent,PageName.ContactPageComponent,passedData,PageName.OpportunityDetailsComponent,{animate:false},null);
        } else if (!this.isOpportunityClosed) {
            this.uiService.prevView = this.uiService.activeView;
            this.uiService.activeView = '';
            this.contactService.isSchedulerInvoked = false;
          if (this.selectedOpportunityType?.singlestakeholder && this.selectedOpportunityType.singlestakeholder === true) {
            this.contactService.contactPageMode = ComponentViewMode.SELECTION;
          } else {
            this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
          }
          this.contactService.accessedContactListFrom = PageName.OpportunityDetailsComponent;
          this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, passedData, PageName.OpportunityDetailsComponent, { animate: false }, null);
        } else
            return;
    }

    private handleCollaboratorField(event?){
      this.trackingService.tracking('SelectCollaborator', TrackingEventNames.OPPORTUNITYMANAGEMENT)
      if(this.opportunityService.collaboratorRoles?.length){
        this.uiService.prevView = this.uiService.activeView;
        this.uiService.activeView = '';
        this.navService.pushChildNavPageWithPageTracking(OpportunityCollaboratorsComponent, PageName.OpportunityCollaboratorsComponent, PageName.OpportunityDetailsComponent);
      }
      else{
        this.notificationService.notify(this.translate.instant("NO_COLLABORATOR_ROLE"), 'Opportunity Collaborators', 'top', ToastStyle.DANGER);
      }
    }

    // private async handleSourceSelection(data) {
    //     if (this.isReadOnlyMode) return;
    //     this.trackingService.tracking('SelectSource', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    //     const sourceOption = this.optionSets.find(os => os.key == 'indskr_source');
    //     if (!_.isEmpty(sourceOption) && !_.isEmpty(sourceOption.options)) {
    //         let dropdownListDetail:IndDropdownListDetailModel = {
    //             id: 'Opportunity-Source-Select',
    //             data: sourceOption.options.map(option => {
    //                 let obj = {
    //                     title: option.label,
    //                     id: option.value,
    //                     isSelected: false,
    //                 };
    //                 if(this.opportunity.indskr_source_Formatted && option.label == this.opportunity.indskr_source_Formatted){
    //                     obj['isSelected'] = true;
    //                 }
    //                 return obj;
    //             }),
    //         };
    //         this._sourcePopover = await this.popoverCtrl.create({component: IndDropdownListComponent,componentProps: {viewData:dropdownListDetail},cssClass:'dropdown-list-view', event: data.event});
    //         this._sourcePopover.onDidDismiss().then(async (data) => {
    //           data = data.data;
    //           console.log(data);
    //           if(data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.indskr_source){
    //             this.opportunity.indskr_source = data.selectedItems[0].id;
    //             this.opportunity.indskr_source_Formatted = data.selectedItems[0].title;
    //             this.opportunity.surveyResponseDTO['indskr_source'] = data.selectedItems[0].id;
    //             this._updateOpportunity('updateSurveyResponse');
    //             await this.opportunityDataService.updateOpportunity({
    //                 onDynamics: false,
    //                 onLocalCopy: true,
    //                 onLocalDatabase: true,
    //                 operationDetail: {
    //                   code: 'updatesource',
    //                   message: 'Opportunity Source Update'
    //                 }
    //             }, [this.opportunity]).then(succ => {
    //             }).catch(async error => {
    //             });
    //             this.initOpportunityInfoFormFields();
    //           }
    //           this._sourcePopover = undefined;
    //         })
    //         this._sourcePopover.present();
    //     }
    // }
  
    // private async handleGemSelection(data) {
    //     if (this.isReadOnlyMode) return;
    //     this.trackingService.tracking('SelectGem/Non-Gem', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    //     const gemOption = this.optionSets.find(os => os.key == 'indskr_gemnongem');
    //     if (!_.isEmpty(gemOption) && !_.isEmpty(gemOption.options)) {
    //         let dropdownListDetail:IndDropdownListDetailModel = {
    //             id: 'Opportunity-Gem-Select',
    //             data: gemOption.options.map(option => {
    //                 let obj = {
    //                     title: option.label,
    //                     id: option.value,
    //                     isSelected: false,
    //                 };
    //                 if(this.opportunity.indskr_gemnongem_Formatted && option.label == this.opportunity.indskr_gemnongem_Formatted){
    //                     obj['isSelected'] = true;
    //                 }
    //                 return obj;
    //             }),
    //         };
    //         this._sourcePopover = await this.popoverCtrl.create({component: IndDropdownListComponent,componentProps: {viewData:dropdownListDetail},cssClass:'dropdown-list-view', event: data.event});
    //         this._sourcePopover.onDidDismiss().then(async (data) => {
    //           data = data.data;
    //           console.log(data);
    //           if(data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.indskr_gemnongem){
    //             this.opportunity.indskr_gemnongem = data.selectedItems[0].id;
    //             this.opportunity.indskr_gemnongem_Formatted = data.selectedItems[0].title;
    //             this.opportunity.surveyResponseDTO['indskr_gemnongem'] = data.selectedItems[0].id;
    //             this._updateOpportunity('updateSurveyResponse');
    //             await this.opportunityDataService.updateOpportunity({
    //                 onDynamics: false,
    //                 onLocalCopy: true,
    //                 onLocalDatabase: true,
    //                 operationDetail: {
    //                   code: 'updategem',
    //                   message: 'Opportunity Gem/Non-Gem Update'
    //                 }
    //             }, [this.opportunity]).then(succ => {
    //             }).catch(async error => {
    //             });
    //             this.initOpportunityInfoFormFields();
    //           }
    //           this._sourcePopover = undefined;
    //         })
    //         this._sourcePopover.present();
    //     }
    // }
  
    // private async handleAfforabilitySelection(data) {
    //     if (this.isReadOnlyMode) return;
    //     this.trackingService.tracking('SelectAffordability', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    //     const affordabilityOption = this.optionSets.find(os => os.key == 'indskr_affordability');
    //     if (!_.isEmpty(affordabilityOption) && !_.isEmpty(affordabilityOption.options)) {
    //       const dropdownListDetail:IndDropdownListDetailModel = {
    //           id: 'Affordability-Select',
    //           data: affordabilityOption.options.map(option => {
    //               let obj = {
    //                   title: option.label,
    //                   id: option.value,
    //                   isSelected: false,
    //               };
    //               if(this.opportunity.indskr_affordability_Formatted && option.label == this.opportunity.indskr_affordability_Formatted){
    //                   obj['isSelected'] = true;
    //               }
    //               return obj;
    //           }),
    //       };
    //       this._affordabilityPopover = await this.popoverCtrl.create({component: IndDropdownListComponent,componentProps: {viewData:dropdownListDetail},cssClass:'dropdown-list-view', event: data.event});
    //       this._affordabilityPopover.onDidDismiss().then(async (data) => {
    //         data = data.data;
    //         console.log(data);
    //         if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.indskr_affordability) {
    //           this.opportunity.indskr_affordability = data.selectedItems[0].id;
    //           this.opportunity.indskr_affordability_Formatted = data.selectedItems[0].title;
    //           this.opportunity.surveyResponseDTO['indskr_affordability'] = data.selectedItems[0].id;
    //           this._updateOpportunity('updateSurveyResponse');
    //           await this.opportunityDataService.updateOpportunity({
    //             onDynamics: false,
    //             onLocalCopy: true,
    //             onLocalDatabase: true,
    //             operationDetail: {
    //               code: 'updateaffordability',
    //               message: 'Opportunity Affordability Update'
    //             }
    //           }, [this.opportunity]).then(succ => {
    //           }).catch(async error => {
    //           });
    //           this.initOpportunityInfoFormFields();
    //         }
    //         this._affordabilityPopover = undefined;
    //       });
    //       this._affordabilityPopover.present();
    //     }
    // }
  
    // private async handleUserRatingSelection(data) {
    //     if (this.isReadOnlyMode) return;
    //     this.trackingService.tracking('SelectUserRating', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    //     const userRatingOption = this.optionSets.find(os => os.key == 'opportunityratingcode');
    //     if (!_.isEmpty(userRatingOption) && !_.isEmpty(userRatingOption.options)) {
    //         let dropdownListDetail:IndDropdownListDetailModel = {
    //             id: 'User-Rating-Select',
    //             data: userRatingOption.options.map(option => {
    //                 let obj = {
    //                     title: option.label,
    //                     id: option.value,
    //                     isSelected: false,
    //                 };
    //                 if(this.opportunity.opportunityratingcode_Formatted && option.label == this.opportunity.opportunityratingcode_Formatted){
    //                     obj['isSelected'] = true;
    //                 }
    //                 return obj;
    //             }),
    //         };
    //         this._userRatingPopover = await this.popoverCtrl.create({component: IndDropdownListComponent,componentProps: {viewData:dropdownListDetail},cssClass:'dropdown-list-view', event: data.event});
    //         this._userRatingPopover.onDidDismiss().then(async (data) => {
    //           data = data.data;
    //           console.log(data);
    //             if(data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.opportunityratingcode){
    //               this.opportunity.opportunityratingcode = data.selectedItems[0].id;
    //               this.opportunity.opportunityratingcode_Formatted = data.selectedItems[0].title;
    //               this.opportunity.surveyResponseDTO['opportunityratingcode'] = data.selectedItems[0].id;
    //               this._updateOpportunity('updateSurveyResponse');
    //               await this.opportunityDataService.updateOpportunity({
    //                 onDynamics: false,
    //                 onLocalCopy: true,
    //                 onLocalDatabase: true,
    //                 operationDetail: {
    //                   code: 'updateuserrating',
    //                   message: 'Opportunity User Rating Update'
    //                 }
    //               }, [this.opportunity]).then(succ => {
    //               }).catch(async error => {
    //               });
    //               this.initOpportunityInfoFormFields();
    //             }
    //             this._userRatingPopover = undefined;
    //         })
    //         this._userRatingPopover.present();
    //     }
    // }

    // private async handleBusinessUnitSelection (data) {
    //   if (this.isReadOnlyMode) return;
    //     let businessUnitOptions = this.sharedDataService.businessUnits;
    //     if (Array.isArray(businessUnitOptions) && businessUnitOptions.length > 0) {
    //         let dropdownListDetail:IndDropdownListDetailModel = {
    //             id: 'Opportunity-BusinessUnit-Select',
    //             data: businessUnitOptions.map(option => {
    //                 let obj = {
    //                     title: option.businessunitname,
    //                     id: option.businessunitid,
    //                     isSelected: false,
    //                 };
    //                 if(this.opportunity.businessUnitId && obj.id == this.opportunity.businessUnitId){
    //                     obj['isSelected'] = true;
    //                 }
    //                 return obj;
    //             }),
    //         };
    //         this._businessUnitPopover = await this.popoverCtrl.create({component: IndDropdownListComponent,componentProps: {viewData:dropdownListDetail},cssClass:'dropdown-list-view', event: data.event});
    //         this._businessUnitPopover.onDidDismiss().then(async (data) => {
    //             data=data.data;
    //             if(data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.businessUnitId){
    //                 this.opportunity.businessUnitId = data.selectedItems[0].id;
    //                 this.opportunity.businessUnitFormatted = data.selectedItems[0].title;
    //                 this.opportunity.pendingPushToDynamics = true;
    //                 this.uiService.displayLoader();
    //                 await this.opportunityDataService.updateOpportunity({
    //                     onDynamics: !this.device.isOffline,
    //                     onLocalCopy: true,
    //                     onLocalDatabase: true,
    //                     operationDetail: {
    //                       code: 'updateopportunitybu',
    //                       message: 'Update Opportunity BU'
    //                     }
    //                 }, [this.opportunity]).then(succ => {
    //                     // Successfull action
    //                     this.uiService.dismissLoader();
    //                 }).catch(async error => {
    //                     // To Do Error Handling
    //                     this.uiService.dismissLoader();
    //                 });
    //             }
    //             this._businessUnitPopover = undefined;
    //         })
    //         this._businessUnitPopover.present();
    //     }
    // }

    // private async handleOpportunityTypeSelection (data) {
    //   if (this.isReadOnlyMode) return;
    //     let opportunityTypeOptions = this.opportunityService.opportunityTypes;
    //     if (Array.isArray(opportunityTypeOptions) && opportunityTypeOptions.length > 0) {
    //         let dropdownListDetail:IndDropdownListDetailModel = {
    //             id: 'Opportunity-Type-Select',
    //             data: opportunityTypeOptions.map(option => {
    //                 let obj = {
    //                     title: option.indskr_name,
    //                     id: option.indskr_opportunitytypeid,
    //                     isSelected: false,
    //                 };
    //                 if(this.opportunity.opportunityTypeId && obj.id == this.opportunity.opportunityTypeId){
    //                     obj['isSelected'] = true;
    //                 }
    //                 return obj;
    //             }),
    //         };
    //         this._opportunityTypePopover = await this.popoverCtrl.create({component: IndDropdownListComponent,componentProps: {viewData:dropdownListDetail},cssClass:'dropdown-list-view', event: data.event});
    //         this._opportunityTypePopover.onDidDismiss().then(async (data) => {
    //             data=data.data;
    //             if(data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.opportunityTypeId){
    //                 this.opportunity.opportunityTypeId = data.selectedItems[0].id;
    //                 this.opportunity.opportunityTypename = data.selectedItems[0].title;
    //                 this.opportunity.pendingPushToDynamics = true;
    //                 this.uiService.displayLoader();
    //                 await this.opportunityDataService.updateOpportunity({
    //                     onDynamics: !this.device.isOffline,
    //                     onLocalCopy: true,
    //                     onLocalDatabase: true,
    //                     operationDetail: {
    //                       code: 'updateopportunitytype',
    //                       message: 'Update Opportunity Type'
    //                     }
    //                 }, [this.opportunity]).then(succ => {
    //                     // Successfull action
    //                     this.uiService.dismissLoader();
    //                 }).catch(async error => {
    //                     // To Do Error Handling
    //                     this.uiService.dismissLoader();
    //                 });
    //             }
    //             this._opportunityTypePopover = undefined;
    //         })
    //         this._opportunityTypePopover.present();
    //     }
    // }

    public async handleProductLineItemAdd(productId?:any){
      this.trackingService.tracking('SelectAdd', TrackingEventNames.OPPORTUNITYMANAGEMENT)
        let product:OrderProductClass;
        if(productId){
            let foundProduct = this.opportunity.products.find(product => product.productID == productId);
            if(foundProduct){
                product = new OrderProductClass({
                    productId: foundProduct.productID,
                    productName: foundProduct.productName,
                    total: foundProduct.total,
                    formattedTotal: foundProduct.totalFormatted,
                    unitPrice: foundProduct.unitPrice,
                    formattedUnitPrice: foundProduct.unitPriceFormatted,
                    quantity: foundProduct.quantity,
                    isDeleted: foundProduct.isDeleted,
                    uomid:foundProduct.uomid
                });
            }
        }
        if(this.isAddProductEnabled){
          if(this.opportunity.isestimatedrevenuebasedonprocedureandproducts){
            this.navService.pushWithPageTracking(OrderAddQuantityComponent, PageName.OrderAddQuantityComponent, { from: 'OpportunityDetailsPage', opportunity: this.opportunity,selectedProduct:product }, PageName.OpportunityDetailsComponent);
          }else{
            await this.checkForMatchingPriceList().then(()=>{
              this.navService.pushWithPageTracking(OrderAddQuantityComponent, PageName.OrderAddQuantityComponent, { from: 'OpportunityDetailsPage', opportunity: this.opportunity,selectedProduct:product, matchedPriceList: this.currentMatchedPriceList }, PageName.OpportunityDetailsComponent);
            }).catch((error)=>{
              // To do error handler
            })
          }
        }
    }

    private async checkForMatchingPriceList() {
        return new Promise(async (resolve, reject) => {
            let flag = false;
            let data:FindPriceListModelData = {
                countryId: this.opportunity.countryId,
                accountId: this.opportunity.accountID,
                priceListId: this.opportunity.priceList,
            };
            await this.orderManagementService.findMatchingPriceListForOrder(data).then((priceList: PriceList) => {
                if (priceList && priceList.products && priceList.products.length > 0) {
                    this.currentMatchedPriceList = priceList;
                    flag = true
                }
                else flag = false
            }).catch((error) => {
                if (error) {
                    this.notificationService.notify(this.translate.instant('OM_NO_AVAILABLE_PRICE'), '', 'top', '')
                }
                if (error && error == 'Select a country to find matching price list') {
                    this.isAddProductEnabled = false;
                }
                flag = false
            })
            if (flag) resolve(true)
            else reject(false)
        })
    }

    public async handleProductRemove(id:string) {
        let product: OpportunityProduct;
        if (id) {
            product = this.opportunity.products.find(product => product.productID == id);
        }
        if (this.isAddProductEnabled && product) {
            let idx = this.opportunity.products.findIndex(p => p.productID == product.productID);
            if (idx >= 0) {
                this.opportunity.products.splice(idx, 1);
                if(this.opportunity.isrevenuesystemcalculated || this.opportunity.isestimatedrevenuebasedonprocedureandproducts){
                  this.opportunity.estimatedValue = this.opportunity.products.map(product => product.total).reduce((a, b) => (a - 0) + (b - 0), 0);
                  if (this.opportunity.elligibleDiscount) {
                      this.opportunity.estimatedValue = parseFloat((this.opportunity.estimatedValue * (1 - (this.opportunity.elligibleDiscount / 100))).toFixed(2))
                  }
                  this.opportunity.estimatedValueFormatted = (this.opportunity.transactionCurrencySymbol ? this.opportunity.transactionCurrencySymbol : (this.currentMatchedPriceList ? this.currentMatchedPriceList.currencySymbol : '')) + Utility.getFormattedNumber(this.opportunity.estimatedValue);
                }

                this.opportunity.pendingPushToDynamics = true;
                let action: OperationDetail = {
                    onDynamics: !this.device.isOffline,
                    onLocalDatabase: true,
                    onLocalCopy: true,
                    operationDetail: {
                        code: 'OPPORTUNITYPRODUCTADD',
                        message: 'Update products quantities for an opportunity'
                    }
                };
                this.uiService.displayLoader();
                if (this.autoSubjectEnabled) {
                  this.opportunity.opportunityName = this.opportunityService.setOpportunityDescription(this.opportunity);
                }
                await this.opportunityDataService.updateOpportunity(action, [this.opportunity]).then(succ => {
                    //Handle Successful response
                }).catch(error => {
                    //Handle error scenarios
                });
                this.uiService.dismissLoader();
            }
        }
    }

    public async handleCollaboratorRemove(id:string) {
      let collaborator: OpportunityCollaborators;
      if (id) {
        collaborator = this.opportunity.collaborators.find(co => co.ID == id);
      }
      if (collaborator) {
          let idx = this.opportunity.collaborators.findIndex(co => co.ID == collaborator.ID);
          if (idx >= 0) {
              this.opportunity.collaborators.splice(idx, 1);

              this.opportunity.pendingPushToDynamics = true;
              let action: OperationDetail = {
                  onDynamics: !this.device.isOffline,
                  onLocalDatabase: true,
                  onLocalCopy: true,
                  operationDetail: {
                      code: 'updatecollaborators',
                      message: 'Update collaborators for an opportunity'
                  }
              };
              this.uiService.displayLoader();
              await this.opportunityDataService.updateOpportunity(action, [this.opportunity]).then(succ => {
                  //Handle Successful response
              }).catch(error => {
                  //Handle error scenarios
              });
              this.uiService.dismissLoader();
          }
      }
  }

    private onClosePage():void {
        this.navService.popChildNavPageWithPageTracking();
    }

    private handleFormFieldEvent(id,event,eventName){
        if(eventName == 'input_value_confirm' && id){
            switch(id){
                case 'desc-field':
                    this.handleEditDescription(event);
                    break;
                case 'discount-field':
                    this.handleEditDiscount(event);
                    break;
                case 'estimated-value-field':
                    this.handleEditEstimatedValue(event);
                    break;
                case 'actual-value-field':
                    this.handleEditActualValue(event);
                    break;
                // case 'source-others-field':
                //     this.handleSourceOthersValue(event);
                //     break;
                case 'competitor-business-value-field':
                    this.handleEditCompetitorBusinessValue(event);
                    break;
                 case 'focus-area-field':
                    this.handleFocusAreaField(event);
                    break;
                default:
                    console.log('Unhandled switch case statement');
                    break;
            }
        }else if(id){
          switch (id) {
            case 'surgery-field':
              this.openSurgerySelect();
              break;
            case 'product-category-field':
              this.openProductCategorySelect();
              break;
            case 'product-field':
              this.openProductSelect();
              break;
            case 'competitors-field':
              this.openCompetitorsSelect();
              break;
            case 'competitor-products-field':
              this.openCompetitorProductsSelect();
              break;
            case 'product-manager-field':
              this.openProductManagersList();
              break;
            case 'parent-field':
              this.openParentOpportunitySelect(event);
              break;
            case 'reason-field':
              this.showOpportunityCloseReasons(event);
              break;
            default:
              console.log('Unhandled switch case statement');
              break;
          }
        }
        this._isClicked[id] = true;
    }

    private get _currentProductCategoriesString(): string {
      let str = '';
      let currentProducts = this.opportunity.productCategory;
      if (currentProducts) {
          if (currentProducts.length === 1) {
              str = `${currentProducts[0].productCategoryName}`;
          } else if (currentProducts.length >= 2) {
              currentProducts.sort((a, b): number => {
                  if (a.productCategoryName > b.productCategoryName) return 1;
                  if (a.productCategoryName < b.productCategoryName) return -1;
                  return 0;
              });
              str = `${currentProducts[0].productCategoryName} +${currentProducts.length - 1}`;
          }
      }
      return str;
    }

    private get _currentProductsString(): string {
      let str = '';
      let currentProducts = this.opportunity.products.filter(a=> !a.isDeleted);
      if (currentProducts) {
          if (currentProducts.length === 1) {
              str = `${currentProducts[0].productName}`;
          } else if (currentProducts.length >= 2) {
              currentProducts.sort((a, b): number => {
                  if (a.productName > b.productName) return 1;
                  if (a.productName < b.productName) return -1;
                  return 0;
              });
              str = `${currentProducts[0].productName} +${currentProducts.length - 1}`;
          }
      }
      return str;
    }

    private get _currentCompetitorsString(): string {
      let str = '';
      let currentCompetitors = this.opportunity.competitors.filter(a=> !a.isDeleted);
      if (currentCompetitors) {
          if (currentCompetitors.length === 1) {
              str = `${currentCompetitors[0].name}`;
          } else if (currentCompetitors.length >= 2) {
            currentCompetitors.sort((a, b): number => {
                  if (a.name > b.name) return 1;
                  if (a.name < b.name) return -1;
                  return 0;
              });
              str = `${currentCompetitors[0].name} +${currentCompetitors.length - 1}`;
          }
      }
      return str;
    }

    private get _currentCompetitorProductsString(): string {
      let str = '';
      let currentCompetitorProducts = this.opportunity.competitorProducts.filter(a=> !a.isDeleted);
      if (currentCompetitorProducts) {
          if (currentCompetitorProducts.length === 1) {
              str = `${currentCompetitorProducts[0].name}`;
          } else if (currentCompetitorProducts.length >= 2) {
            currentCompetitorProducts.sort((a, b): number => {
                  if (a.name > b.name) return 1;
                  if (a.name < b.name) return -1;
                  return 0;
              });
              str = `${currentCompetitorProducts[0].name} +${currentCompetitorProducts.length - 1}`;
          }
      }
      return str;
    }

    private openSurgerySelect(): void {
      if(!this.isReadOnlyMode){
        const listDetail: MainToolTemplateDetail = {
          title: this.translate.instant('PROCEDURE'),
          dividerTitle:this.translate.instant('ALL_PROCEDURE'),
          isSearchEnabled: !this.isReadOnlyMode,
          showLeftHeaderButton: true,
          leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
          leftHeaderBtnText: this.translate.instant('CANCEL'),
          showRightHeaderButton: !this.isReadOnlyMode,
          rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
          rightHeaderBtnText: this.translate.instant('DONE'),
          orderByPropertyName: 'primaryTextRight',
          searchTitle: this.translate.instant('PROCEDURE'),
          hideAllItemsList: this.isReadOnlyMode,
          isListSelectionEnabled: !this.isReadOnlyMode,
          listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
          navOptions: { animate: false },
          eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=> this._handleProductComponentEvent(data,eventTarget,refData, 'surgery'),
          searchHandler:(text: string) => this._handleProductsComponentSearch(text, 'surgery'),
          data: this.getSurgeries(),
        };
        if(this.isReadOnlyMode){
            this.opportunity.products.forEach(pro=>{
                const idx = listDetail.data.findIndex(it=> it.id === pro.productID);
                if(idx<0){
                    const viewData: MainCardViewDataModel = {
                        id: pro.productID,
                        primaryTextLeft: '',
                        secondaryTextLeft: '',
                        showEndIcon: !this.isReadOnlyMode,
                        mainItemCssClass: 'selector-item',
                        isItemSelectedForSelectionView: true,
                        endIconType: 'indegene-selectors-checkmark-icon',
                        endIconCssClass: 'checkmark-icon',
                        primaryTextRight: pro.productName,
                        showArrow: false,
                        arrowType: '',
                      };
                    listDetail.data.push(viewData);
                }
            });
        }
        this.navService.pushWithPageTracking(MainToolTemplateComponent,PageName.NothingSelectedView,{viewData:listDetail},PageName.MainToolTemplateComponent);
      }
    }

    private getSurgeries(): MainCardViewDataModel[] {
      return this.productHierarchies?.map(pro => {
        let isSelected = false;
        if (this.opportunity && this.opportunity.surgeryId && this.opportunity.surgeryId == pro.surgeryId) {
          isSelected = true;
        }
        return {
          id: pro.surgeryId,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: !this.isReadOnlyMode,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: pro.surgeryName,
          showArrow: false,
          arrowType: '',
          eventOwnerId: this.opportunity.ownerID
        };
      });
    }

    private openProductCategorySelect(): void {
      // if(!this.isReadOnlyMode){
        let dataSet = [];
        if(this.isOpportunityByProductsEnabled){
          dataSet = this.opportunityService.productsHierarchyByPosition;
        }else{
          dataSet = this.surgeryOrderDataService.productHierarchies.find(p => p.surgeryId == this.opportunity.surgeryId).productCategories;
          if (!_.isEmpty(dataSet) && this.productDisplay != PRODUCT_DISPLAY.ALL_PRODUCTS) {
            if (this.productDisplay == PRODUCT_DISPLAY.NPI_PRODUCTS_ONLY) {
              dataSet = dataSet.filter(pc => pc.indskr_newproductintroduction && pc.products.some(pr => pr.indskr_newproductintroduction));
            } else {
              dataSet = dataSet.filter(pc => !pc.indskr_newproductintroduction && pc.products.some(pr => !pr.indskr_newproductintroduction));
            }
          }
        }
        const listDetail: MainToolTemplateDetail = {
          title: this.translate.instant('PRODUCT_CATEGORY'),
          dividerTitle:this.translate.instant('ALL_PRODUCT_CATEGORY'),
          isSearchEnabled: !this.isReadOnlyMode,
          showLeftHeaderButton: true,
          leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
          leftHeaderBtnText: this.translate.instant('CANCEL'),
          showRightHeaderButton: !this.isReadOnlyMode,
          rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
          rightHeaderBtnText: this.translate.instant('DONE'),
          orderByPropertyName: 'primaryTextRight',
          searchTitle: this.translate.instant('PRODUCT_CATEGORY'),
          hideAllItemsList: false, //this.isReadOnlyMode,
          isListSelectionEnabled: !this.isReadOnlyMode,
          listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
          navOptions: { animate: false },
          eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=> this._handleProductComponentEvent(data,eventTarget,refData, 'productcategory'),
          searchHandler:(text: string) => this._handleProductsComponentSearch(text,'productcategory'),
          data: dataSet.map(pro => {
            let isSelected = false;
            if(this.opportunity && this.opportunity.productCategory && this.opportunity.productCategory.some(sopro => (sopro.productCategoryId === pro.productCategoryId))){
              isSelected = true;
            }
            return {
              id: pro.productCategoryId,
              primaryTextLeft: '',
              secondaryTextLeft: '',
              showEndIcon: !this.isReadOnlyMode,
              mainItemCssClass: 'selector-item',
              isItemSelectedForSelectionView: isSelected,
              endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
              endIconCssClass: isSelected?'checkmark-icon':'add-icon',
              primaryTextRight: pro.productCategoryName,
              showArrow: false,
              arrowType: '',
              eventOwnerId: this.opportunity.ownerID
            };
          }),
        };
        this.navService.pushWithPageTracking(MainToolTemplateComponent,PageName.NothingSelectedView,{viewData:listDetail},PageName.MainToolTemplateComponent);
      // }
    }

    private openProductSelect(): void {
      if (!this.isReadOnlyMode) {
        let selectedProdCategories;
        if (this.isOpportunityByProductsEnabled) {
          selectedProdCategories = [];
          /*
          this.opportunity.productCategory.forEach(category => {
            let foundCategory = this.opportunityService.productsHierarchyByPosition.find(a => a.productCategoryId == category.productCategoryId);
            if (foundCategory) {
              selectedProdCategories.push(foundCategory);
            }
          })
          */
        } else {
          selectedProdCategories = this.surgeryOrderDataService.productHierarchies
            .find(p => p.surgeryId == this.opportunity.surgeryId)
            ?.productCategories.filter(c => this.opportunity.productCategory.some(o => o.productCategoryId == c.productCategoryId))
        }
        const listDetail: MainToolTemplateDetail = {
          title: this.translate.instant('PRODUCTS'),
          dividerTitle: this.translate.instant('ALL_PRODUCTS_CAP'),
          isSearchEnabled: !this.isReadOnlyMode,
          showLeftHeaderButton: true,
          leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
          leftHeaderBtnText: this.translate.instant('CANCEL'),
          showRightHeaderButton: !this.isReadOnlyMode,
          rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
          rightHeaderBtnText: this.translate.instant('DONE'),
          orderByPropertyName: 'primaryTextRight',
          searchTitle: this.translate.instant('SEARCH_PRODUCTS'),
          hideAllItemsList: this.isReadOnlyMode,
          isListSelectionEnabled: !this.isReadOnlyMode,
          listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
          navOptions: { animate: false },
          eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProductComponentEvent(data, eventTarget, refData, 'product'),
          searchHandler: (text: string) => this._handleProductsComponentSearch(text, 'product'),
          data: this.getProductsSelectionData(selectedProdCategories)
        };
        if (this.isReadOnlyMode) {
          this.opportunity.products.forEach(pro => {
            const idx = listDetail.data.findIndex(it => it.id === pro.productID);
            if (idx < 0) {
              const viewData: MainCardViewDataModel = {
                id: pro.productID,
                primaryTextLeft: '',
                secondaryTextLeft: '',
                showEndIcon: !this.isReadOnlyMode,
                mainItemCssClass: 'selector-item',
                isItemSelectedForSelectionView: true,
                endIconType: 'indegene-selectors-checkmark-icon',
                endIconCssClass: 'checkmark-icon',
                primaryTextRight: pro.productName,
                showArrow: false,
                arrowType: '',
              };
              listDetail.data.push(viewData);
            }
          });
        }
        this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isGroupedView: !this.isOpportunityByProductsEnabled }, PageName.MainToolTemplateComponent);
      }
    }

    private _handleProductsComponentSearch(text: string, formField:string): string[] {
      let ids: Array<string> = [];
      switch (formField){
        case 'surgery':
          if (text.length >= 1) {
            ids = this.productHierarchies?.filter(pro => {
                return pro.surgeryName.trim().toLowerCase().includes(text.trim().toLowerCase());
            }).map(pro => pro.surgeryId);
          } else {
              ids = this.productHierarchies.map(pro => pro.surgeryId);
          }
          break;
        case 'productcategory':
          if (text.length >= 1) {
            ids = this.surgeryOrderDataService.productHierarchies
                  && this.surgeryOrderDataService.productHierarchies.find(p=>p.surgeryId==this.opportunity.surgeryId).productCategories.filter(pro => {
                return pro.productCategoryName.trim().toLowerCase().includes(text.trim().toLowerCase());
            }).map(pro => pro.productCategoryId);
          } else {
              ids = this.surgeryOrderDataService.productHierarchies.find(p=>p.surgeryId==this.opportunity.surgeryId).productCategories.map(pro => pro.productCategoryId);
          }
          break;
        case 'product':
          if (this.isOpportunityByProductsEnabled) {
            ids = this.products.filter(product => {
              return product.name.trim().toLowerCase().includes(text.trim().toLowerCase());
            }).map(prod => prod.ID);
          } else {
            let selectedProdCategories = this.surgeryOrderDataService.productHierarchies
              .find(p => p.surgeryId == this.opportunity.surgeryId)
              .productCategories.filter(c => this.opportunity.productCategory.some(o => o.productCategoryId == c.productCategoryId))
            let selectedProdCategoriesProducts = [];
            selectedProdCategories.forEach(pc => {
              selectedProdCategoriesProducts = selectedProdCategoriesProducts.concat(pc.products);
            })
            if (text.length >= 1) {
              ids = selectedProdCategoriesProducts.filter(product => {
                return product.productName.trim().toLowerCase().includes(text.trim().toLowerCase());
              }).map(prod => prod.productId);
            }
          }
          break;
          case 'competitors':
            if (text.length >= 1) {
              ids = this.opportunityService.opportunityCompetitors && this.opportunityService.opportunityCompetitors.filter(com => {
                return com.name.trim().toLowerCase().includes(text.trim().toLowerCase());
              }).map(pro => pro.surgeryId);
            } else {
              ids = this.opportunityService.opportunityCompetitors && this.opportunityService.opportunityCompetitors.map(com => com.id);
            }
          break;
          case 'competitorproducts':
            if (text.length >= 1) {
              ids = this.allCompetitorProducts && this.allCompetitorProducts.filter(com => {
                return com.name.trim().toLowerCase().includes(text.trim().toLowerCase());
              }).map(pro => pro.ID);
            } else {
              ids = this.allCompetitorProducts && this.allCompetitorProducts.map(com => com.ID);
            }
          break;
      }
      return ids;
    }

    private _handleProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail, fieldName:string) {
      if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
          if (this.opportunity.status == 'Open' && data && data.isDone) {
              let operationDetailCode: string;
              if (data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems.length > 0) {
                  switch (fieldName){
                    case 'surgery':
                      let surgeryProd = this.productHierarchies.find(p=>p.surgeryId == data.selectedItems[0].id)
                      if(surgeryProd && this.opportunity.surgeryId !== surgeryProd.surgeryId){
                        this.opportunity.surgeryId = surgeryProd.surgeryId;
                        this.opportunity.surgeryNameString = surgeryProd.surgeryName;
                        if(!this.isOpportunityByProductsEnabled){
                          this.opportunity.productCategory = [];
                          this.opportunity.products = [];
                          operationDetailCode = 'updatesurgeryproducts';
                        }else{
                          operationDetailCode = 'updatesurgery';
                        }
                        this._updateOpportunity(operationDetailCode);
                      }
                      break;
                    case 'productcategory':
                      this.opportunity.productCategory = [];
                      let applicableProducts = [];
                      data.selectedItems.forEach(item => {
                        let category;
                        if(this.isOpportunityByProductsEnabled){
                          category = this.opportunityService.productsHierarchyByPosition.find(p=>p.productCategoryId == item.id);
                        }else{
                          category = this.surgeryOrderDataService.productHierarchies.find(p=>p.surgeryId == this.opportunity.surgeryId).productCategories
                          .find(c=>c.productCategoryId == item.id)
                        }
                        if(category){
                          this.opportunity.productCategory.push({
                            productCategoryId:category.productCategoryId,
                            productCategoryName:category.productCategoryName
                          })
                          applicableProducts = applicableProducts.concat(category.products);
                        }
                      });
                      _.remove(this.opportunity.products,(prod)=>{
                        return !applicableProducts.some(o=>o.productId == prod.productID && o.productCategoryId == prod.productCategoryID);
                      });
                      operationDetailCode = 'updateproductcategory';
                      this._updateOpportunity(operationDetailCode);
                      break;
                    case 'product':
                      let selectedProdCategories = [];
                      let updatedCategoriesFlag: boolean = false;
                      if (!this.isOpportunityByProductsEnabled) {
                        selectedProdCategories = this.surgeryOrderDataService.productHierarchies
                          .find(p => p.surgeryId == this.opportunity.surgeryId)
                          .productCategories.filter(c => this.opportunity.productCategory.some(o => o.productCategoryId == c.productCategoryId))
                      }

                      let selectedProdCategoriesProducts = [];
                      selectedProdCategories.forEach(pc => {
                        selectedProdCategoriesProducts = selectedProdCategoriesProducts.concat(pc.products);
                      })
                      let alreadySelectedProducts = this.opportunity.products;
                      this.opportunity.products = [];
                      data.selectedItems.forEach(item => {
                        let foundProduct = selectedProdCategoriesProducts.find(prod => prod.productId == item.id && prod.productCategoryId == item.parentID);
                        if (foundProduct) {
                          let alreadySelectedProd = alreadySelectedProducts.find(p => p.productID == item.id && p.productCategoryID == item.parentID);
                          if (alreadySelectedProd) {
                            this.opportunity.products.push(alreadySelectedProd);
                          }
                          else {
                            this.opportunity.products.push({
                              productID: foundProduct.productId,
                              productName: foundProduct.productName,
                              quantity: 1,
                              uomid: foundProduct.uomid,
                              isDeleted: false,
                              unitPrice: 0,
                              unitPriceFormatted: "0$",
                              total: 1,
                              totalFormatted: "1",
                              productCategoryID: foundProduct.productCategoryId,
                              productCategoryName: foundProduct.productCategoryName,
                            });
                          }
                        } else if (this.isOpportunityByProductsEnabled) {
                          let alreadySelectedProd = alreadySelectedProducts.find(p => p.productID == item.id);
                          if (alreadySelectedProd) {
                            this.opportunity.products.push(alreadySelectedProd);
                          }
                          else {
                            foundProduct = this.products.find(p => p.ID == item['id']);
                            if (foundProduct) {
                              this.opportunity.products.push({
                                productID: foundProduct.ID,
                                productName: foundProduct.name,
                                quantity: 1,
                                uomid: foundProduct.defaultuomid,
                                isDeleted: false,
                                unitPrice: 0,
                                unitPriceFormatted: "0$",
                                total: 1,
                                totalFormatted: "1",
                              });
                            }
                          }
                        }
                      });
                      if (this.autoSubjectEnabled) {
                        this.opportunity.opportunityName = this.opportunityService.setOpportunityDescription(this.opportunity);
                      }
                      this.initOpportunityInfoFormFields();
                      operationDetailCode = 'updateproducts';
                      if (updatedCategoriesFlag) {
                        operationDetailCode = 'updateproductcategory';
                      }
                      this._updateOpportunity(operationDetailCode);
                     
                      break;
                    case 'competitors':
                      this.opportunity.competitors = this.opportunity.competitors.map(c => {
                        c.isDeleted = true;
                        return c;
                      })
                      data.selectedItems.forEach(item => {
                        let idx;
                        if(this.opportunity.competitors){
                          idx = this.opportunity.competitors.findIndex(c => c.ID == item.id)
                        }
                        if(idx >= 0){
                          this.opportunity.competitors[idx] = {
                            ID:item.id,
                            name:item.primaryTextRight,
                            isDeleted: false,
                          }
                        }else{
                          this.opportunity.competitors.push({
                            ID:item.id,
                            name:item.primaryTextRight,
                          })
                        }
                      });
                      operationDetailCode = 'updatecompetitors';
                      this._updateOpportunity(operationDetailCode);
                    break;
                    case 'competitorproducts':
                      this.opportunity.competitorProducts = this.opportunity.competitorProducts.map(c => {
                        c.isDeleted = true;
                        return c;
                      })
                      data.selectedItems.forEach(item => {
                        let idx;
                        if(this.opportunity.competitorProducts){
                          idx = this.opportunity.competitorProducts.findIndex(c => c.ID == item.id)
                        }
                        if(idx >= 0){
                          this.opportunity.competitorProducts[idx] = {
                            ID:item.id,
                            name:item.primaryTextRight,
                            isDeleted: false,
                          }
                        }else{
                          this.opportunity.competitorProducts.push({
                            ID:item.id,
                            name:item.primaryTextRight,
                          })
                        }
                      });
                      operationDetailCode = 'updatecompetitorproducts';
                      this._updateOpportunity(operationDetailCode);
                    break;
                  }

              }
              else if(data.selectedItems.length ==0){
                switch (fieldName){
                  case 'surgery':
                    this.opportunity.surgeryId = undefined;
                    this.opportunity.surgeryNameString = '';
                    if(!this.isOpportunityByProductsEnabled){
                      this.opportunity.productCategory = [];
                      this.opportunity.products = [];
                      operationDetailCode = 'updatesurgeryproducts';
                    }else{
                      operationDetailCode = 'updatesurgery';
                    }
                    this._updateOpportunity(operationDetailCode);
                    break;
                  case 'productcategory':
                    this.opportunity.productCategory = [];
                    this.opportunity.products = [];
                    operationDetailCode = 'updateproductcategory';
                    this._updateOpportunity(operationDetailCode);
                    break;
                  case 'product':
                    this.opportunity.products = [];
                    operationDetailCode = 'updateproducts';
                    if (this.autoSubjectEnabled) {
                      this.opportunity.opportunityName = this.opportunityService.setOpportunityDescription(this.opportunity);
                    }
                    this._updateOpportunity(operationDetailCode);
                    break;
                  case 'competitors':
                    this.opportunity.competitors = this.opportunity.competitors.map(c => {
                      c.isDeleted = true;
                      return c;
                    });
                    operationDetailCode = 'updatecompetitors';
                    this._updateOpportunity(operationDetailCode);
                    break;
                  case 'competitorproducts':
                    this.opportunity.competitorProducts = this.opportunity.competitorProducts.map(c => {
                      c.isDeleted = true;
                      return c;
                    });
                    operationDetailCode = 'updatecompetitorproducts';
                    this._updateOpportunity(operationDetailCode);
                    break;
                }
              }
              this.initOpportunityInfoFormFields();
          }
      }
    }

    getProductsSelectionData(selectedProdCategories) {
      let data = [];
      if (this.isOpportunityByProductsEnabled) {
        const brands = _.cloneDeep(_.sortBy(this.products, [function (o) { return o.name; }]));
        brands.forEach(prod => {
          let isSelected = false;
          if (this.opportunity && this.opportunity.products
            && this.opportunity.products.some(sopro => (sopro.productID === prod.ID))) {
            isSelected = true;
          }
          data.push({
            id: prod.ID,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: !this.isReadOnlyMode,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isSelected,
            endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
            endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
            primaryTextRight: prod.name,
            showArrow: false,
            arrowType: '',
            eventOwnerId: this.opportunity.ownerID
          })
        })
      } else {
        selectedProdCategories.forEach(pro => {
          data.push({
            id: pro.productCategoryId,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: false,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: false,
            primaryTextRight: pro.productCategoryName,
            showArrow: false,
            eventOwnerId: this.opportunity.ownerID
          });
          let categoryProducts = !_.isEmpty(pro.products) ? pro.products : [];
          if (this.productDisplay == PRODUCT_DISPLAY.NPI_PRODUCTS_ONLY) {
            categoryProducts = categoryProducts.filter(p => p.indskr_newproductintroduction);
          } else if (this.productDisplay == PRODUCT_DISPLAY.ALL_EXCLUDING_NPI_PRODUCTS) {
            categoryProducts = categoryProducts.filter(p => !p.indskr_newproductintroduction);
          }
          categoryProducts.forEach(prod => {
            let isSelected = false;
            if (this.opportunity && this.opportunity.products
              && this.opportunity.products.some(sopro => (sopro.productID === prod.productId && sopro.productCategoryID === prod.productCategoryId))) {
              isSelected = true;
            }
            data.push({
              id: prod.productId,
              parentID: prod.productCategoryId,
              primaryTextLeft: '',
              secondaryTextLeft: '',
              showEndIcon: !this.isReadOnlyMode,
              mainItemCssClass: 'selector-item',
              isItemSelectedForSelectionView: isSelected,
              endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
              endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
              primaryTextRight: prod.productName,
              showArrow: false,
              arrowType: '',
              eventOwnerId: this.opportunity.ownerID
            });
          })
        })
      }
      return data;
    }

    private async _updateOpportunity(operationDetailCode:string){
      let action: OperationDetail = {
        onDynamics: !this.device.isOffline,
        onLocalDatabase: true,
        onLocalCopy: true,
        operationDetail: {
          code: operationDetailCode,
          message: "Delta Sync Opportuntiy Update",
        }
      };
      this.opportunity.pendingPushToDynamics = true;
      if(!this.device.isOffline){
        this.uiService.displayLoader();
      }
      await this.opportunityDataService.updateOpportunity(action,[this.opportunity]).then((succ)=>{
        //this.initOpportunityInfoFormFields();
      }).catch((err)=>{
        // Error Handler
      })
      if(!this.device.isOffline){
        this.uiService.dismissLoader();
      }
    }

    private async handleSaveNote(){
      if (this.isSaveNotesEnabled) {
        this.isSaveNotesEnabled = false;
        //const now = new Date().getTime().toString();
        let offlinePayload = {
          "objecttypecode" : "opportunity",
          //createdon: now,
          notetext: this.tempNoteText ? this.tempNoteText : "",
          ownerid: this.authService.user.systemUserID,
          deleted: false,
        };
        if(this.base64str){
          offlinePayload['isdocument'] = true;
          offlinePayload['documentbody'] = this.base64str;
          offlinePayload['filename'] = this.attachmentFile.name;
          offlinePayload['filesize'] = this.attachmentFile.size;
          offlinePayload['mimetype'] = this.attachmentFile.type;
        }else{
          offlinePayload['isdocument'] = false;
        }
        let currentNote = new IONote(offlinePayload);

        if(!this.device.isOffline){
          this.uiService.displayLoader();
          let res = await this.opportunityDataService.updateOpportunityNoteOnline(offlinePayload,this.opportunity.ID);
          if(res && res['annotationid']){
            currentNote.noteId = res['annotationid'];
            currentNote.documentDataURL = '';
            currentNote.ownerName = this.authService.user.displayName,
            currentNote.createdTime = new Date();
            this.opportunity.opportunityNotes.push(currentNote);
            await this._updateOpportunityNoteInDB('UPDATEOPPORTUNITYNOTE');
            this._sortOpportunityNotes();
            this.tempNoteText = '';
            this.isSaveNotesEnabled = false;
            this.removeAttachment(null);
            this.initOpportunityNotesModel();
          }
          this.uiService.dismissLoader();
        }

      }
    }

    public async loadImageFromDevice(event){
      if(event && event.target && event.target.files){
        try {
          if((event.target.files[0].size/1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE){
            if(NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(event.target.files[0].name)){
              this.attachmentFile = event.target.files[0];
              this.attachmentTitle = event.target.files[0].name;
              this.isAttachmentAdded = true;
              this.base64str = await toBase64(this.attachmentFile);
              const dataURLPattern = /^data:[^;]+;base64,/;
              this.base64str = this.base64str.replace(dataURLPattern, '');
              this.isSaveNotesEnabled = true;
              this.initOpportunityNotesModel();
            }else{
              this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'),'Opportunity Info','top','info');
              this.removeAttachment(false);
            }
          }else{
            this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION',{size:MAXIMUM_NOTE_ATTACHMENT_SIZE}),'Opportunity Info','top','info');
            this.removeAttachment(false);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }

    private handleNoteAttachment(){
      if(this.isNotesAttachmentEnabled){
        try {
          (document.getElementById('file-input') as any).click();
        } catch (error) {
          console.log(error);
        }
      }
    }

    public isNoteControlsEnabled(note: IONote): boolean {
      return !!(note.ownerId == this.authService.user.systemUserID) && !this.device.isOffline && note.noteId != 'description-note' && !this.isReadOnlyMode;
    }

    public async updateNote(ev) {
      if (ev && ev.action) {
        let idx = this.opportunity.opportunityNotes.findIndex(note => note.noteId == ev.noteId);
        let payload = {
          "annotationid" : this.opportunity.opportunityNotes[idx].noteId,
          "objecttypecode" : "opportunity",
        };
        if (ev.action == 'DELETE' && idx >= 0) {
          payload['deleted'] = true;
        } else if (ev.action == 'SAVE' && idx >= 0) {
          payload['notetext'] = ev.updatedText;
          if(ev.attachmentFileUpdated){
            payload['isdocument'] = true;
            payload['documentbody'] = ev.attachmentFileDataUrl;
            payload['filename'] = ev.documentName;
            payload['filesize'] = ev.documentSize;
            payload['mimetype'] = ev.documentMimeType;
          }else if(ev.isAttachmentRemoved){
            payload['isdocument'] = false;
            payload['documentbody'] = '';
            payload['filename'] = '';
            payload['filesize'] = 0;
            payload['mimetype'] = '';
          }
        }
        // Update Service
        this._sortOpportunityNotes();
        if (!this.device.isOffline) {
          this.uiService.displayLoader();
          let res = await this.opportunityDataService.updateOpportunityNoteOnline(payload,this.opportunity.ID);
          if(res && res['annotationid']){
            if (ev.action == 'DELETE' && idx >= 0) {
              this.opportunity.opportunityNotes.splice(idx, 1);
            } else if (ev.action == 'SAVE' && idx >= 0) {
              this.opportunity.opportunityNotes[idx].noteText = ev.updatedText;
              if(ev.attachmentFileUpdated){
                this.opportunity.opportunityNotes[idx].hasDocument = true;
                this.opportunity.opportunityNotes[idx].documentDataURL = '';
                this.opportunity.opportunityNotes[idx].documentName = ev.documentName;
                this.opportunity.opportunityNotes[idx].documentSize = ev.documentSize;
                this.opportunity.opportunityNotes[idx].documentMimeType = ev.documentMimeType;
              }else if(ev.isAttachmentRemoved){
                this.opportunity.opportunityNotes[idx].hasDocument = false;
                this.opportunity.opportunityNotes[idx].documentDataURL = '';
                this.opportunity.opportunityNotes[idx].documentName = '';
                this.opportunity.opportunityNotes[idx].documentSize = 0;
                this.opportunity.opportunityNotes[idx].documentMimeType = '';
              }
            }
            await this._updateOpportunityNoteInDB('UPDATEOPPORTUNITYNOTE');
            this.uiService.dismissLoader();
          }
        }
      }
    }

    private _sortOpportunityNotes(){
      if(this.opportunity.opportunityNotes && this.opportunity.opportunityNotes.length > 1){
        this.opportunity.opportunityNotes = this.opportunity.opportunityNotes.sort((a,b)=>{
          return (isBefore(a.createdTime, b.createdTime) ? 1 : -1);
        });
      }
    }

    private async _updateOpportunityNoteInDB(operationDetailCode:string){
      let action: OperationDetail = {
        onDynamics: false,
        onLocalDatabase: true,
        onLocalCopy: true,
        operationDetail: {
          code: operationDetailCode,
          message: "Delta Sync Opportuntiy Update",
        }
      };
      await this.opportunityDataService.updateOpportunity(action,[this.opportunity]).then((succ)=>{
        // Success
      }).catch((err)=>{
        // Error Handler
      })
    }

    public removeAttachment(event){
      try {
        (document.getElementById('file-input') as any).value = null;
      } catch (error) {
        console.log(error);
      }
      this.attachmentTitle = '';
      this.isAttachmentAdded = false;
      this.attachmentFile = null;
      this.base64str = null;
      if(this.tempNoteText.length > 0 || this.attachmentFile){
        this.isSaveNotesEnabled = true;
      }else{
        this.isSaveNotesEnabled = false;
      }
      this.initOpportunityNotesModel();
    }

  private async initOpportunityBusinessProcessStages() {
    if (this.device.isOffline) return;
    if (this.opportunity && !this.opportunity.opportunityStage) {
      let res = await this.opportunityDataService.getOpportunityActiveStageIdByProcessInstanceId(this.opportunity.opportunityBusinessProcessInstanceId, this.opportunity.opportunityBusinessProcessSchemaName);
      if (res && Array.isArray(res)) {
        if (res[0] && res[0]['_activestageid_value']) {
          this.opportunity.opportunityStage = res[0]['_activestageid_value'];
          this.opportunity.opportunityStageValue = res[0]['_activestageid_value@OData.Community.Display.V1.FormattedValue'];
          this.stageFormField = {
            label: this.translate.instant('STAGE'),
            inputText: _.startCase(this.opportunity.opportunityStageValue),
            id: 'stage-field',
            isReadOnly: true,
            customPlaceholderLabel: this.translate.instant('SELECT_STAGE'),
            isDisabled: (!this.isReadOnlyMode && !this.device.isOffline) && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) ? false : true,
            showArrow: (!this.isReadOnlyMode && !this.device.isOffline) && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT),
            isRequired: (!this.isReadOnlyMode && !this.device.isOffline),
            formFieldType: FormFieldType.POPOVER_SELECT,
          };
        }
      }
    }
    if (this.opportunity && this.opportunity.opportunityBusinessProcessInstanceId) {
      this.opportunityBusinessProcessStages = await this.opportunityDataService.getOpportunityBusinessProcessStagesByProcessInstanceId(this.opportunity.opportunityBusinessProcessInstanceId);
    }
  }
  private async handleStageFieldSelection(event) {
    if(this.device.isOffline) return;
    if(!this.opportunityBusinessProcessStages || !this.opportunity.opportunityStage){
      await this.initOpportunityBusinessProcessStages();
    }
    if(Array.isArray(this.opportunityBusinessProcessStages.value) && this.opportunityBusinessProcessStages.value.length){
      let listData = [];
      let currentStageIndex:number;
      for(let i=0;i< this.opportunityBusinessProcessStages.value.length;i++){
        const stage = this.opportunityBusinessProcessStages.value[i];
        let obj = {
          id: stage['processstageid'],
          title: stage['stagename'],
        }
        if(stage['processstageid'] == this.opportunity.opportunityStage){
          currentStageIndex = i;
          obj['isSelected'] = true;
        }
        listData.push(obj);
      }
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'opportunity-stages-options-select',
        showClearAllBtn: false,
        data: listData,
      };
      let stagesPopover = await this.popoverCtrl.create({
        component: IndDropdownListComponent,
        componentProps: { viewData: dropdownListDetail },
        cssClass: 'dropdown-list-view',
        event: event.event,
      });
      stagesPopover.onDidDismiss().then(async (data: any) => {
        if (data.data) data = data.data;
        if (data && data.selectedItems && data.selectedItems.length == 1) {
          if (data.selectedItems[0].id) {
            let travesedPath= '';
            const foundIdx = this.opportunityBusinessProcessStages.value.findIndex(a=> a['processstageid'] == data.selectedItems[0].id)
            if (foundIdx >= 0) {
              if ((foundIdx == (currentStageIndex + 1)) || (foundIdx == (currentStageIndex - 1))) {
                if (foundIdx > currentStageIndex && !this.opportunity.indskr_allowstagemovementbyuser) {
                  this.notificationService.notify(this.translate.instant('FWD_STAGE_MOVEMENT_NOT_ALLOWED'), 'Opportunity-Details', 'top', 'danger');
                  return;
                }
                if (foundIdx < currentStageIndex && this.opportunity.indskr_allowbackwardstagemovement == false) {
                  this.notificationService.notify(this.translate.instant('BKWD_STAGE_MOVEMENT_NOT_ALLOWED'), 'Opportunity-Details', 'top', 'danger');
                  return;
                }
                this.uiService.displayLoader();
                for (let i = 0; i <= foundIdx; i++) {
                  if (i == foundIdx) {
                    travesedPath = travesedPath + this.opportunityBusinessProcessStages.value[i]['processstageid'];
                  } else {
                    travesedPath = travesedPath + this.opportunityBusinessProcessStages.value[i]['processstageid'] + ',';
                  }
                }
                this.opportunity.stageMoved =  {
                  "opportunitySalesProcessesId": this.opportunity.opportunityBusinessProcessInstanceId,
                  "activeStageId": data.selectedItems[0].id,
                  "traversedPath": travesedPath,
                };
                if (this.opportunity.opportunityBusinessProcessSchemaName !== "opportunitysalesprocess") {
                  this.opportunity.stageMoved['schemaName'] = this.opportunity.opportunityBusinessProcessSchemaName + 's';
                }
                let action: OperationDetail = {
                  onDynamics: true,
                  onLocalDatabase: true,
                  onLocalCopy: true,
                  operationDetail: {
                    code: 'updateopportunitystage',
                    message: "Opportuntiy Stage Update",
                  }
                };
                let oldStage = this.opportunity.opportunityStage;
                let oldStageValue = this.opportunity.opportunityStageValue;
                let oldProgressStatus = this.opportunity.indskr_progressstatus;
                let oldPercentage = this.opportunity.opportunityProgressPercent;
                this.opportunity.opportunityStageValue = data.selectedItems[0].title;
                this.opportunity.opportunityStage = data.selectedItems[0].id;
                if (this.selectedOpportunityType['indskr_nostageprogressafterdays'] != null) {
                  this.opportunity.indskr_progressstatus = ProgressStatus.IN_PROGRESS;
                }
                this.opportunity.opportunityProgressPercent = (((foundIdx+1)/this.opportunityBusinessProcessStages.value.length)*100).toFixed(0);
                await this.opportunityDataService.updateOpportunity(action, [this.opportunity]).then((succ) => {
                  //Success
                }).catch((err) => {
                  this.opportunity.opportunityStage = oldStage;
                  this.opportunity.opportunityStageValue = oldStageValue;
                  this.opportunity.opportunityProgressPercent = oldPercentage;
                  if (this.selectedOpportunityType['indskr_nostageprogressafterdays'] != null)
                    this.opportunity.indskr_progressstatus = oldProgressStatus;
                  this.notificationService.notify(this.translate.instant('CANNOT_UPDATE_OPPORTUNITY_STAGE'),'Opportunity-Details','top','danger');
                })
                this.uiService.dismissLoader();
              }else if(foundIdx == currentStageIndex){
                // Already selected stage
              }else{
                this.notificationService.notify(this.translate.instant('CANNOT_SKIP_OPPORTUNITY_STAGE'),'Opportunity-Details','top','danger');
              }
            }
          } 
        } 
      })
      stagesPopover.present();
    }
  }

  async openEndDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;  
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.OpportunityInfo,
          startDateTimeValue: this.currentOpportunityEstimatedDate ? this.currentOpportunityEstimatedDate : moment(new Date()).format(),
          endDateTimeValue: this.currentOpportunityEstimatedDate ? this.currentOpportunityEstimatedDate : moment(new Date()).format(),
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then( async (data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.endTime !='') {
        const selectedEndDate = new Date(data.data.endTime);
        selectedEndDate.setHours(0,0,0,0);
        const endDate: string = this.currentOpportunityEstimatedDate;
        if (!isSameDay(selectedEndDate, endDate)) {
          this.opportunity.estimatedDate = selectedEndDate.getTime().toString();
          this.currentOpportunityEstimatedDate = (new Date(parseInt(this.opportunity.estimatedDate))).toISOString();
          if (this.autoSubjectEnabled) {
            this.opportunity.opportunityName = this.opportunityService.setOpportunityDescription(this.opportunity);
          }
          this.uiService.displayLoader();
          await this.opportunityDataService.updateOpportunity({
            onDynamics:!this.device.isOffline,
            onLocalDatabase:true,
            onLocalCopy:true, 
            operationDetail: {code: 'updatecloseddate', message: 'Update Opportunity Close Date'}},
            [this.opportunity]).then(success=>{
              //Handle successfull response
              this.uiService.dismissLoader();
            }).catch(async error => {
              // Handle error occured
              this.uiService.dismissLoader();
            });
        }
      }
      else if(this.estimatedCloseDateFormField.isRequired){
        this.initOpportunityInfoFormFields();
       }
    });
    popover.present();
  }

  private loadSurveyResults() {
    //if(this.opportunity.statusCode == 1){
      if(this.opportunity.surveyResponseData) {
        if(!_.isEqual(this.opportunity.surveyResponseData,this.surveyService.surveyStaticResults)){
          this.surveyService.surveyStaticResults = this.opportunity.surveyResponseData;
        }
      } else {
        this.surveyService.surveyStaticResults = '';
      }
      if(this.opportunity.surveyResponseLookupData && this.opportunity.surveyResponseLookupData.length>0){
        if(!_.isEqual(this.opportunity.surveyResponseLookupData,this.surveyLookupResults)){
          this.surveyService.surveyLookupResults = this.surveyLookupResults = this.opportunity.surveyResponseLookupData;
        }
      } else {
        if(this.surveyLookupResults.length != 0){
          this.surveyService.surveyLookupResults = this.surveyLookupResults = [];
        }
      }
    //} else if(this.opportunity.surveyResponse) {
      //this.opportunitySurveyResponseDataModel = [];
    //   this.surveyService.surveyStaticResults = this.opportunity.surveyResponse;
    //   this.surveyService.surveyStaticResults.forEach(result => {
    //     let viewData: IndFormFieldViewDataModel;
    //     viewData = {
    //       label: result.indskr_question,
    //       inputText: (result.indskr_answer != null && result.indskr_answer != undefined && result.indskr_answer !== '') ? result.indskr_answer.toString() : result.indskr_answer,
    //       id: 'survey-result-field',
    //       isReadOnly: true
    //     }
    //     //if(result.indskr_answer != null && result.indskr_answer != undefined && result.indskr_answer !== ''){
    //       try {
    //         let foundQuestion = this.surveyTemplate.pages[0].elements.find(a=> a.title == result.indskr_question);
    //         if(foundQuestion && foundQuestion.type == "dropdown"){
    //           let choice = foundQuestion.choices.find(c=> c.value == result.indskr_answer);
    //           if(choice){
    //             viewData.inputText = choice.text;
    //           }
    //         }
    //       } catch (error) {
    //         console.log('Error mapping option set value'+error);
    //       }
    //       this.opportunitySurveyResponseDataModel.push(viewData);
    //     //}
    //   });
    // }
  }

  private loadSurveyTemplate() {
    // let selectedType = this.opportunityService.opportunityTypes.find(a=> a.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
    let form = this.surveyService.surveyForms.find(form => form.surveyFormId == this.selectedOpportunityType?.surveyTemplateId);
    if(form) {
      this.surveyTemplate = _.cloneDeep(form.surveyConfiguration);
      try {
        if(this.opportunity.statusCode != 1){
          this.surveyService.markSurveyElementsReadonly(this.surveyTemplate.pages[0].elements);
          // this.surveyTemplate.pages[0].elements.forEach(ele => {
          //   ele.readOnly = true;
          // });
        }
      } catch (error) {
        console.log('Error while marking fields readonly'+error);
      }
    }
    this.eventService.publish('loadSurveyTemplate', this.surveyTemplate);
    if(this.surveyTemplate) this.validateSurveyForReview(this.surveyService.surveyStaticResults);
  }

  private validateSurveyForReview(surveyData) {
    this.isSurveyValid = true;
    this.surveyTemplate.pages[0].elements.forEach((question) => {
      if(question.isRequired) {
        this.isSurveyValid = this.isSurveyValid && surveyData.hasOwnProperty(question.name) ? true : false;
        if(question.type == 'panel' && question.elements && question.elements.length > 0 ){
          this.validateSurveyElementsForReview(question.elements,surveyData);
        }
      }
    })
    this.isSurveyValidEvent.emit(this.isSurveyValid);
  }

  private validateSurveyElementsForReview(elements,surveyData){
    if(elements && elements.length > 0){
      for(let i=0;i<elements.length;i++){
        this.isSurveyValid = this.isSurveyValid && surveyData.hasOwnProperty(elements[i].name) ? true : false;
        if(elements[i].type == 'panel' && elements[i].elements && elements[i].elements.length > 0){
          this.validateSurveyElementsForReview(elements[i].elements,surveyData)
        }
      }
    }
  }

  public async updateSurveyResult(surveyResponse) {
    this.opportunity.surveyResponse = surveyResponse;
  }

  public async updateSurveyData(surveyData){
    for(var propt in surveyData){
      if(this.opportunity.surveyResponseData && this.opportunity.surveyResponseData.hasOwnProperty(propt)){
        if(this.opportunity.surveyResponseData[propt] != surveyData[propt]){
          this.updateSurveyQuestion({name:propt,value:surveyData[propt]});
        }
      }else{
        this.updateSurveyQuestion({name:propt,value:surveyData[propt]});
      }
    }
    this.surveyService.surveyStaticResults = this.opportunity.surveyResponseData = surveyData;
    this.validateSurveyForReview(surveyData);
    this.initSurveyData();
    this.initFooterButtons();
  }

  public async updateSurveyQuestion(question){
    try {
      if(this._startTakingQuestionUpdatesForSurveyJS && question && question.name && this.surveyTemplate && this.surveyTemplate.pages && this.surveyTemplate.pages[0]){
        const element = this.surveyService.getElementFromSurveyTemplateElements(this.surveyTemplate.pages[0].elements,question);
        if(element && element.type && element.metadata){
          if(element.type == "lookup" && question.value){

            let idx;
            if(!this.opportunity.surveyResponseDTO.hasOwnProperty('lookupfields')){
              this.opportunity.surveyResponseDTO['lookupfields'] = [];
            }
            idx = this.opportunity.surveyResponseDTO['lookupfields'].findIndex(a=> a.name == element.metadata.logicalName);
            if(idx >= 0){
              if(question.value != this.opportunity.surveyResponseDTO['lookupfields'][idx].id){
                this.opportunity.surveyResponseDTO['lookupfields'][idx] = {
                  name: element.metadata.logicalName,
                  entity: element.metadata.target.setName,
                  id: question.value,
                };
                this._updateOpportunity('updateSurveyResponse');
              }
            }else{
              this.opportunity.surveyResponseDTO['lookupfields'].push({
                name: element.metadata.logicalName,
                entity: element.metadata.target.setName,
                id: question.value,
              });
              this._updateOpportunity('updateSurveyResponse');
            }
          }else{
            if(!this.opportunity.surveyResponseDTO || (question.value != this.opportunity.surveyResponseDTO[element.metadata.logicalName])){
              this.opportunity.surveyResponseDTO[element.metadata.logicalName] = question.value;
              this._updateOpportunity('updateSurveyResponse')
            }
          }
        }
      }
    } catch (error) {
      
    }
    
  }

  private openCompetitorsSelect(): void {
    // if(!this.isReadOnlyMode){
      const listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('COMPETITORS'),
        dividerTitle:this.translate.instant('ALL_COMPETITORS'),
        isSearchEnabled: !this.isReadOnlyMode,
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: !this.isReadOnlyMode,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        orderByPropertyName: 'primaryTextRight',
        searchTitle: this.translate.instant('COMPETITORS'),
        hideAllItemsList: false, //this.isReadOnlyMode,
        isListSelectionEnabled: !this.isReadOnlyMode,
        listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=> this._handleProductComponentEvent(data,eventTarget,refData, 'competitors'),
        searchHandler:(text: string) => this._handleProductsComponentSearch(text,'competitors'),
        data: this.opportunityService.opportunityCompetitors.map(c => {
          let isSelected = false;
          if(this.opportunity && this.opportunity.competitors && this.opportunity.competitors.some(oco => (oco.ID === c.id))){
            isSelected = true;
          }
          return {
            id: c.id,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: !this.isReadOnlyMode,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isSelected,
            endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
            endIconCssClass: isSelected?'checkmark-icon':'add-icon',
            primaryTextRight: c.name,
            showArrow: false,
            arrowType: '',
            eventOwnerId: this.opportunity.ownerID
          };
        }),
      };
      this.navService.pushWithPageTracking(MainToolTemplateComponent,PageName.NothingSelectedView,{viewData:listDetail},PageName.MainToolTemplateComponent);
    // }
  }

  private openCompetitorProductsSelect(): void {
    // if(!this.isReadOnlyMode){
      const listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('COMPETITOR_PRODUCTS'),
        dividerTitle:this.translate.instant('ALL_COMPETITOR_PRODUCTS'),
        isSearchEnabled: !this.isReadOnlyMode,
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: !this.isReadOnlyMode,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        orderByPropertyName: 'primaryTextRight',
        searchTitle: this.translate.instant('COMPETITOR_PRODUCTS'),
        hideAllItemsList: false, //this.isReadOnlyMode,
        isListSelectionEnabled: !this.isReadOnlyMode,
        listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=> this._handleProductComponentEvent(data,eventTarget,refData, 'competitorproducts'),
        searchHandler:(text: string) => this._handleProductsComponentSearch(text,'competitorproducts'),
        data: this.allCompetitorProducts.map(c => {
          let isSelected = false;
          if(this.opportunity && this.opportunity.competitorProducts && this.opportunity.competitorProducts.some(oco => (oco.ID === c.ID))){
            isSelected = true;
          }
          return {
            id: c.ID,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: !this.isReadOnlyMode,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isSelected,
            endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
            endIconCssClass: isSelected?'checkmark-icon':'add-icon',
            primaryTextRight: c.name,
            showArrow: false,
            arrowType: '',
            eventOwnerId: this.opportunity.ownerID
          };
        }),
      };
      this.navService.pushWithPageTracking(MainToolTemplateComponent,PageName.NothingSelectedView,{viewData:listDetail},PageName.MainToolTemplateComponent);
    // }
  }

  private async handleEditCompetitorBusinessValue (data){
    if (!this.isReadOnlyMode) {
      if (data && parseInt(data.target.value) != this.opportunity.competitorBusinessValue && parseInt(data.target.value) >= 0 && !data.target.value.includes('.')) {
        //let previousCompetitorBusinessValue = this.opportunity.competitorBusinessValue;
        //let newCompetitorBusinessValue = parseInt(data.target.value);
        this.opportunity.competitorBusinessValue = parseInt(data.target.value);
        this.opportunity.competitorBusinessValueFormatted = this.opportunity.transactionCurrencySymbol + Utility.getFormattedNumber(this.opportunity.competitorBusinessValue);
        this.opportunity.pendingPushToDynamics = true;
        this.handleUpdates.emit();
        this.uiService.displayLoader();
        await this.opportunityDataService.updateOpportunity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true , operationDetail: { code: 'updatecompetitorbusinessvalue', message : 'Update Opportunity Competitor Business Value'} }, [this.opportunity]).then(success => {
          //Handle successfull response
          this.uiService.dismissLoader();
        }).catch(async error => {
          // Handle error occured
          this.uiService.dismissLoader();
        });
      } else {
        this.initOpportunityInfoFormFields();
      }
    }
  }

  private async openProductManagersList() {
    let data = [];
    if (this.procedureTrackerDataService.users) {
      data = this.procedureTrackerDataService.users.filter((user) => user.userType && user.userType.includes(UserTypes.PRODUCT_MANAGER)).map(user => {
        return {
          id: user.userId,
          title: user.userFullName,
          isSelected: this.opportunity.productManager && this.opportunity.productManager.id === user.userId
        }
      })
    }
    data = _.orderBy(data, 'title');
    const productManagerModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: data,
          isMultipleSelectionEnabled: false,
          title: this.translate.instant('USERS'),
          dividerTitle: this.translate.instant('ALL_USERS'),
          isSearchEnabled: true,
          isReadOnly: this.isReadOnlyMode
        }
      },
      backdropDismiss: false
    });
    productManagerModal.present();
    productManagerModal.onDidDismiss().then(async (obj) => {
      if (obj && obj.data && obj.data.isDone) {
        const data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          this.opportunity.productManager = {
            fullName: "",
            id: ""
          };
        } else {
          this.opportunity.productManager = {
            id: data.selectedItems[0].id,
            fullName: data.selectedItems[0].title
          }
        }
        this._updateOpportunity('updateProductManager');
        this.initOpportunityInfoFormFields();
      }
    })
  }

  private async initSpecialtiesData(){
    await this.contactService.getOfflineSpecialtiesData().then(data=> {
      if(data && Array.isArray(data)){
        this.currentSpecialties = data;
        this.initSubSpecialtiesData();
      }
    });
  }

  private initSubSpecialtiesData(){
    this.currentSubSpecialties = [];
    if(this.opportunity && this.opportunity.primarySpecialtyId){
      let idx = this.currentSpecialties.findIndex(a=> a.indskr_lu_specialtyid == this.opportunity.primarySpecialtyId);
      if(idx >= 0 && this.currentSpecialties[idx].subSpecialties && Array.isArray(this.currentSpecialties[idx].subSpecialties)){
        this.currentSubSpecialties = this.currentSpecialties[idx].subSpecialties;
      }
    }
  }

  private async openParentOpportunitySelect(data) {
    if (this.isReadOnlyMode) return;
    await this.uiService.displayLoader();
    setTimeout(() => {
      if(Array.isArray(this.opportunityService.opportunities) && this.opportunityService.opportunities.length > 0){
        let listData = [];
        for(let i=0;i< this.opportunityService.opportunities.length;i++){
          const opp = this.opportunityService.opportunities[i];
          if(opp.ID && opp.opportunityName && opp.ID != this.opportunity.ID){
            let isSelected:boolean = false;
            if(opp.ID == this.opportunity.parentOpportunityId){
              isSelected = true;
            }
            let obj = {
              title: opp.opportunityName,
              id: opp.ID,
              primaryTextRight: opp.opportunityName ? opp.opportunityName : '',
              secondaryTextLeft: '',
              showEndIcon: !this.isReadOnlyMode,
              mainItemCssClass: 'selector-item',
              isItemSelectedForSelectionView: isSelected,
              endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
              endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
              showArrow: false,
              arrowType: '',
              eventOwnerId: this.opportunity.ownerID,
            }
            listData.push(obj);
          }
        }
  
        const listDetail: MainToolTemplateDetail = {
          title: this.translate.instant('OPPORTUNITIES'),
          dividerTitle: this.translate.instant('ALL_OPPORTUNITIES'),
          isSearchEnabled: !this.isReadOnlyMode,
          showLeftHeaderButton: true,
          leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
          leftHeaderBtnText: this.translate.instant('CANCEL'),
          showRightHeaderButton: !this.isReadOnlyMode,
          rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
          rightHeaderBtnText: this.translate.instant('DONE'),
          orderByPropertyName: 'primaryTextRight',
          searchTitle: '',
          hideAllItemsList: this.isReadOnlyMode,
          isListSelectionEnabled: !this.isReadOnlyMode,
          listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
          navOptions: { animate: false },
          eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleParentOppComponentEvent(data, eventTarget, refData, 'parentopportunity'),
          searchHandler: (text: string) => this._handleParentoppComponentSearch(text, 'parentopportunity'),
          data: listData
        };
  
        this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isGroupedView: false }, PageName.MainToolTemplateComponent);
      }
    }, 10);
  }

  private async _handleParentOppComponentEvent(data:any,eventTarget:string, refData:MainToolTemplateDetail, fieldName:string){
    if (!this.isReadOnlyMode && eventTarget && eventTarget === 'RightHeaderButtonClick' && data.isDone) {
      if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.parentOpportunityId) {
        this.opportunity.parentOpportunityId = data.selectedItems[0].id;
        this.opportunity.parentOpportunityName = data.selectedItems[0].title;
        this._updateOpportunity('updateparentopportunity');
        this.initOpportunityInfoFormFields();
      }else if(data && data.hasOwnProperty('selectedItems') && data.selectedItems.length == 0 && this.opportunity.parentOpportunityId){
        this.opportunity.parentOpportunityId = '';
        this.opportunity.parentOpportunityName = '';
        this._updateOpportunity('updateparentopportunity');
        this.initOpportunityInfoFormFields();
      }
    }
  }

  private _handleParentoppComponentSearch(text: string, formField: string): string[] {
    return this.opportunityService.opportunities.filter(opp => {
      return opp.opportunityName && opp.ID && opp.ID != this.opportunity.ID && opp.opportunityName.trim().toLowerCase().includes(text.trim().toLowerCase());
    }).map(opp1 => opp1.ID);
  }

  private async handleSpecialtySelect(data) {
    if (this.isReadOnlyMode) return;
    setTimeout(() => {
      if(Array.isArray(this.contactService.specialties) && this.contactService.specialties.length > 0){
        this.uiService.displayLoader();
        let listData = [];
        for(let i=0;i< this.contactService.specialties.length;i++){
          const spec = this.contactService.specialties[i];
          if(spec.id && spec.name){
            let isSelected:boolean = false;
            if(spec.id == this.opportunity.primarySpecialtyId){
              isSelected = true;
            }
            let obj = {
              title: spec.name,
              id: spec.id,
              primaryTextRight: spec.name ? spec.name : '',
              secondaryTextLeft: '',
              showEndIcon: !this.isReadOnlyMode,
              mainItemCssClass: 'selector-item',
              isItemSelectedForSelectionView: isSelected,
              endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
              endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
              showArrow: false,
              arrowType: '',
              eventOwnerId: this.opportunity.ownerID,
            }
            listData.push(obj);
          }
        }
  
        const listDetail: MainToolTemplateDetail = {
          title: this.translate.instant('SPECIALTIES'),
          dividerTitle: this.translate.instant('ALL_SPECIALTIES'),
          isSearchEnabled: !this.isReadOnlyMode,
          showLeftHeaderButton: true,
          leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
          leftHeaderBtnText: this.translate.instant('CANCEL'),
          showRightHeaderButton: !this.isReadOnlyMode,
          rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
          rightHeaderBtnText: this.translate.instant('DONE'),
          orderByPropertyName: 'primaryTextRight',
          searchTitle: '',
          hideAllItemsList: this.isReadOnlyMode,
          isListSelectionEnabled: !this.isReadOnlyMode,
          listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
          navOptions: { animate: false },
          eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handlePrimarySpecialtyComponentEvent(data, eventTarget, refData, 'primaryspecialty'),
          searchHandler: (text: string) => this._handlePrimarySpecialtyComponentSearch(text, 'primaryspecialty'),
          data: listData
        };
        this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isGroupedView: false }, PageName.MainToolTemplateComponent);
      }
    }, 10);
  }

  private async _handlePrimarySpecialtyComponentEvent(data:any,eventTarget:string, refData:MainToolTemplateDetail, fieldName:string){
    if (!this.isReadOnlyMode && eventTarget && eventTarget === 'RightHeaderButtonClick' && data.isDone) {
      if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.primarySpecialtyId) {
        this.opportunity.primarySpecialtyId = data.selectedItems[0].id;
        this.opportunity.primarySpecialtyName = data.selectedItems[0].title;
        this.opportunity.subSpecialtyId = '';
        this.opportunity.subSpecialtyName = '';
        this._updateOpportunity('updatespecialty');
        this.initSubSpecialtiesData();
        this.initOpportunityInfoFormFields();
      }else if(data && data.hasOwnProperty('selectedItems') && data.selectedItems.length == 0 && this.opportunity.primarySpecialtyId){
        this.opportunity.primarySpecialtyId = '';
        this.opportunity.primarySpecialtyName = '';
        this.opportunity.subSpecialtyId = '';
        this.opportunity.subSpecialtyName = '';
        this._updateOpportunity('updatespecialty');
        this.initSubSpecialtiesData();
        this.initOpportunityInfoFormFields();
      }
    }
  }

  private _handlePrimarySpecialtyComponentSearch(text: string, formField: string): string[] {
    return this.contactService.specialties.filter(spec => {
      return spec.name && spec.id && spec.name.trim().toLowerCase().includes(text.trim().toLowerCase());
    }).map(spec1 => spec1.id);
  }

  private async handleSubSpecialtySelect(data) {
    if (this.isReadOnlyMode) return;
    setTimeout(() => {
      if(Array.isArray(this.currentSubSpecialties) && this.currentSubSpecialties.length > 0){
        this.uiService.displayLoader();
        let listData = [];
        for(let i=0;i< this.currentSubSpecialties.length;i++){
          const spec = this.currentSubSpecialties[i];
          if(spec.indskr_subspecialtiesid && spec.indskr_name){
            let isSelected:boolean = false;
            if(spec.indskr_subspecialtiesid == this.opportunity.subSpecialtyId){
              isSelected = true;
            }
            let obj = {
              title: spec.indskr_name,
              id: spec.indskr_subspecialtiesid,
              primaryTextRight: spec.indskr_name ? spec.indskr_name : '',
              secondaryTextLeft: '',
              showEndIcon: !this.isReadOnlyMode,
              mainItemCssClass: 'selector-item',
              isItemSelectedForSelectionView: isSelected,
              endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
              endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
              showArrow: false,
              arrowType: '',
              eventOwnerId: this.opportunity.ownerID,
            }
            listData.push(obj);
          }
        }
  
        const listDetail: MainToolTemplateDetail = {
          title: this.translate.instant('SUB_SPECIALTIES'),
          dividerTitle: this.translate.instant('ALL_SUB_SPECIALTIES'),
          isSearchEnabled: !this.isReadOnlyMode,
          showLeftHeaderButton: true,
          leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
          leftHeaderBtnText: this.translate.instant('CANCEL'),
          showRightHeaderButton: !this.isReadOnlyMode,
          rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
          rightHeaderBtnText: this.translate.instant('DONE'),
          orderByPropertyName: 'primaryTextRight',
          searchTitle: '',
          hideAllItemsList: this.isReadOnlyMode,
          isListSelectionEnabled: !this.isReadOnlyMode,
          listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
          navOptions: { animate: false },
          eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleSubSpecialtyComponentEvent(data, eventTarget, refData, 'subspecialty'),
          searchHandler: (text: string) => this._handleSubSpecialtyComponentSearch(text, 'subspecialty'),
          data: listData
        };
        this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isGroupedView: false }, PageName.MainToolTemplateComponent);
      }
    }, 10);
  }

  private async _handleSubSpecialtyComponentEvent(data:any,eventTarget:string, refData:MainToolTemplateDetail, fieldName:string){
    if (!this.isReadOnlyMode && eventTarget && eventTarget === 'RightHeaderButtonClick' && data.isDone) {
      if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.subSpecialtyId) {
        this.opportunity.subSpecialtyId = data.selectedItems[0].id;
        this.opportunity.subSpecialtyName = data.selectedItems[0].title;
        this._updateOpportunity('updatespecialty');
        this.initOpportunityInfoFormFields();
      }else if(data && data.hasOwnProperty('selectedItems') && data.selectedItems.length == 0 && this.opportunity.subSpecialtyId){
        this.opportunity.subSpecialtyId = '';
        this.opportunity.subSpecialtyName = '';
        this._updateOpportunity('updatespecialty');
        this.initOpportunityInfoFormFields();
      }
    }
  }

  private _handleSubSpecialtyComponentSearch(text: string, formField: string): string[] {
    return this.currentSubSpecialties.filter(spec => {
      return spec.indskr_name && spec.indskr_subspecialtiesid && spec.indskr_name.trim().toLowerCase().includes(text.trim().toLowerCase());
    }).map(spec1 => spec1.indskr_subspecialtiesid);
  }
  private async handleFocusAreaField(data) {
    if (!this.isReadOnlyMode) {
      if (data && data.target.value != this.opportunity.focusArea) {
        if(data.target.value.length >= 1){
          this.opportunity.focusArea = data.target.value;
          this.opportunity.pendingPushToDynamics = true;
          const OppName = this.opportunity.opportunityName;
          if (this.autoSubjectEnabled) {
            this.opportunity.opportunityName = this.opportunityService.setOpportunityDescription(this.opportunity);
          }
          this.uiService.displayLoader();
          await this.opportunityDataService.updateOpportunity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true, operationDetail: { code: 'updatefocusarea', message: 'Update Opportuntiy Description'} }, [this.opportunity]).then(success => {
            //Handle successfull response
            this.uiService.dismissLoader();
          }).catch(async error => {
            // Handle error occured
            this.uiService.dismissLoader();
          });
          if(OppName != this.opportunity.opportunityName){
            this.handleUpdates.emit(this.opportunity.opportunityName);
          }
        }else{
          this.initOpportunityInfoFormFields();
        }
      }
    }
  }

  private getOpportunityReasonString():string {
    let value:string = this.opportunity.opportunityReason;
    if(this.opportunity.opportunityCloseReasons && this.opportunity.opportunityCloseReasons.length > 0){
      value = this.opportunity.opportunityCloseReasons[0].reasonName;
      if(this.opportunity.opportunityCloseReasons.length > 1){
        value+= ` +${this.opportunity.opportunityCloseReasons.length-1}`;
      }
    }
    return value;
  }

  private async showOpportunityCloseReasons(event) {
    if(Array.isArray(this.opportunity.opportunityCloseReasons) && this.opportunity.opportunityCloseReasons.length){
      let listData = [];
      for(let i=0;i< this.opportunity.opportunityCloseReasons.length;i++){
        if(i!=0){
          const reason = this.opportunity.opportunityCloseReasons[i];
          let obj = {
            id: reason.reasonId,
            title: reason.reasonName,
          }
          listData.push(obj);
        }
      }
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'opportunity-close-reasons-display',
        showClearAllBtn: false,
        isReadOnly: true,
        data: listData,
      };
      let reasonsPopover = await this.popoverCtrl.create({
        component: IndDropdownListComponent,
        componentProps: { viewData: dropdownListDetail },
        cssClass: 'dropdown-list-view',
        event: event,
      });
      reasonsPopover.present();
    }
  }

  private get _isFocusAreaEnabled():boolean {
    let flag:boolean = false;
    if(this.opportunity.opportunityTypeId){
      const opportunityType = this.opportunityService.opportunityTypes.find(a => a.indskr_opportunitytypeid == this.opportunity.opportunityTypeId)
      if (opportunityType.indskr_focusarea) {
        flag = true;
      }
    }
    return flag;
  }
  
}
