import { StoreCheckActivity } from '@omni/classes/activity/store-check.activity.class';
import { UIService } from './../../services/ui/ui.service';
import { WebsocketDataService } from './../../data-services/websocket/websocket.data.service';
import { Component, OnInit, OnDestroy, Input, Injector } from '@angular/core';
import { PageName, NavigationService } from '../../services/navigation/navigation.service';
import { DeviceService } from '../../services/device/device.service';
import { ActivityService } from '../../services/activity/activity.service';
import { SampleService, SamplingDetailsViewMode } from '../../services/sample/sample.service';
import { SampleDataService } from '../../data-services/sample/sample.data.service';
import { PopoverController,  LoadingController, ModalController, NavController, IonNav } from '@ionic/angular';
import { Events } from '@omni/events';
import { CaptureSignaturePopoverComponent } from '../capture-signature-modal/capture-signature-popover';
import { FooterService, FooterViews } from '../../services/footer/footer.service';
import { AuthenticationService } from '../../services/authentication.service';
import { SampleActivity, CreateSampleDropRequestBody } from '../../classes/activity/sample.activity.class';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { SampleDetailInSampleActivity, LotDetailInSampleActivity } from '../../models/sample-model';
import { NotificationService } from '../../services/notification/notification.service';
import { RepServices } from '../../data-services/rep/rep.services';
import { ActivityColorCode } from '../../classes/activity/activity.class';
import { TrackService, TrackingEventNames } from '../../services/logging/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../services/alert/alert.service';
import { ContactOfflineService } from '../../services/contact/contact.service';
import { AppointmentActivity } from './../../classes/activity/appointment.activity.class';
import { Endpoints } from '../../../config/endpoints.config';
import { IndPageTitleViewDataModel } from '../../models/indPageTitleDataModel';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '../../services/date-time-formats/date-time-formats.service';
import { ConsentService } from '@omni/services/consent/consent.service';
import { ConsentTerm, TermTokens } from '@omni/classes/consent/consent-term.class';
import { Product } from '@omni/classes/consent/product.class';
import { Contact, ContactMeetingState } from '@omni/classes/contact/contact.class';
import { Channel, ChannelType, ChannelValue } from '@omni/classes/consent/channel.class';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { ConsentDataService } from '@omni/data-services/consent/consent.data.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { isValid, subDays } from 'date-fns';
import { Utility } from '@omni/utility/util';

/**
 * Generated class for the CaptureAllocationOrderComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'capture-allocation-order[base-page]',
  templateUrl: 'capture-allocation-order.html',
  styleUrls:['capture-allocation-order.scss']
})

export class CaptureAllocationOrderComponent implements OnInit, OnDestroy {

  text: string;
  public pageName = PageName;
  isCompletedActivity = false;
  activity: SampleActivity;
  inMeetingFlow = false;
  inMeetingPresentationFlow = false;
  nonDeletedSamplesData: SampleDetailInSampleActivity[];
  remoteSignatureFlowComplete: boolean;
  isSignatureAllowed = false;
  navCtrlIonNav: IonNav;
  public pageTitleControls = [];
  captureAllocationOrder: IndPageTitleViewDataModel;
  @Input() orderCompletionEventHandler: any;
  public termBody: string;
  public termBodyView: SafeHtml;
  public consentTerm: ConsentTerm = null;
  private contact: Contact = null;
  public remoteConnectionLost: boolean;
  private waitingForRemoteConsentResponse: boolean = false;
  private remoteConsentWaitTimer: any;
  public isViewLess: boolean = true;
  public detailsHeaderModel: IndSectionHeaderViewDataModel;
  public termsHeaderModel: IndSectionHeaderViewDataModel;
  public signatureHeaderModel: IndSectionHeaderViewDataModel;
  private isLimitedView: boolean = false;
  public isLastDispatchDate: boolean = false;
  private sampleLastDispatchLimitNum: number = 0;
  
  
  constructor(
    public navService: NavigationService,
    public device: DeviceService,
    public activityService: ActivityService,
    public popoverCtrl: PopoverController,
    public footerService: FooterService,
    public events: Events,
    public authenticationService: AuthenticationService,
    public loadingCtrl: LoadingController,
    public sampleDataService: SampleDataService,
    public sampleService: SampleService,
    public navCtrl: NavController,
    public notificationService: NotificationService,
    public repService: RepServices,
    private readonly alertService: AlertService,
    public trackingService: TrackService,
    public readonly translate: TranslateService,
    private readonly contactService: ContactOfflineService,
    public webSocketService: WebsocketDataService,
    public uiService: UIService,
    public modalCtrl: ModalController,
    private readonly injector: Injector,
    private datePipe: DatePipe,
    private dateTimeFormatsService: DateTimeFormatsService,
    public consentService: ConsentService,
    public consentDataService: ConsentDataService,
    private readonly sanitizer: DomSanitizer,
  ) {
    console.log('Hello CaptureAllocationOrderComponent Component');
    this.getAllConsentTermsForAllocationOrders();
  }
  getAllConsentTermsForAllocationOrders() {
      this.consentService.allConsentTermsAllocationOrdersSubject.subscribe(
        (terms: ConsentTerm[]) => {
          if(terms) {
            const allAllocConsentTerms: ConsentTerm[] = terms.filter(t=>t.indskr_allocationordersubmission == true);
            if(allAllocConsentTerms) {
             allAllocConsentTerms.sort((a, b) => {
               if( new Date(a['modifiedon']).getTime() > new Date(b['modifiedon']).getTime()) return -1; else return 1;
              });
             this.consentTerm = allAllocConsentTerms[0] || null;
            }
          }
        }
      );
  }
  ngOnInit(){
    this.sampleLastDispatchLimitNum = this.authenticationService.user.sampleLastDispatchLimit;

    //Check meeting/presentation flow
    this.inMeetingFlow = this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING || 
    this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_EVENTS;
    this.inMeetingPresentationFlow = this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION;
    if(this.inMeetingFlow || this.inMeetingPresentationFlow){
      this.activity = this.sampleService.inMeetingAllocationActivity;
      const contactID = this.activity.contactID;
      if (this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_EVENTS) {
        this.contact = this.activityService.selectedActivity['participants'].find(o => o['id'] == contactID);
      } else {
        this.contact = this.activityService.selectedActivity['contacts'].find(o => o.ID == contactID);
      }
      if(this.inMeetingPresentationFlow) {
        this.consentService.allocationRemoteFlow = this.contact.connectionState == ContactMeetingState.JOINED
        this.remoteConnectionLost = this.contact.connectionState != ContactMeetingState.JOINED;
      } else{
        this.consentService.allocationRemoteFlow = false;
      }
    } else {
      this.activity = this.activityService.selectedActivity as SampleActivity;
      if (this.activity instanceof StoreCheckActivity) {
        this.activity = this.activityService.selectedActivityAtHome as SampleActivity;
      }
      this.contact = this.contactService.getContactByID(this.activity.contactID);
      this.consentService.allocationRemoteFlow = false; 
    }

    if(!this.consentTerm) this.consentTerm = this._getDefaultConsentForm();
    this._updateTokens(this.consentTerm);

    if(this.activity.statusString === 'Completed') this.isCompletedActivity = true;
    if(this.consentTerm) this.consentTerm.isAcceptedTerms = this.isCompletedActivity;
    //CWD 3549- close expanded section on agenda in case it was open before marking complete
    this.activity.expandIcon = 'accordion-add';
    this.activity.expanded = false;
    //check sample data
    const customerAllocIds = [];
    //update the last dispatch date from the latest lots
    if (!_.isEmpty(this.activity.samples)) {
      this.activity.samples.forEach(s=>{
        if(!_.isEmpty(s.lots) && !_.isEmpty(this.sampleService.lots)) {
          s.lots.map(slot=>{
            const idx = this.sampleService.lots.findIndex(lot=>lot.id == slot.indskr_lotid);
            if (idx > -1) {
              //update the payload is used in the remote meeting.
              if (this.sampleService.lots[idx].validTo && isValid(this.sampleService.lots[idx].validTo)) {
                const tmpValidToDateStr: string = new Date(this.sampleService.lots[idx].validTo).toString();
                slot['formattedValidToDate'] = this.getFormattedAndLocalisedDate(tmpValidToDateStr);
              }
              if (this.sampleService.lots[idx].lastdispatchdate) {
                slot['isLastDispatchDate'] = true;
                const tmpLastDispatchDateStr: string = new Date(this.sampleService.lots[idx].lastdispatchdate).toString();
                slot['indskr_lastdispatchdate'] = tmpLastDispatchDateStr;
                slot['formattedLastDispatchDate'] = this.getFormattedAndLocalisedDate(tmpLastDispatchDateStr);  
              } else if (this.sampleLastDispatchLimitNum > 0 && this.sampleService.lots[idx].validTo) {
                slot['isLastDispatchDate'] = true;
                const formattedDate = subDays(new Date(this.sampleService.lots[idx].validTo), this.sampleLastDispatchLimitNum);
                slot['formattedLastDispatchDate'] = this.getFormattedAndLocalisedDate(formattedDate);
              } else {
                slot['isLastDispatchDate'] = false;
              }
            } else {
              //update the payload is used in the remote meeting.
              if (slot.indskr_lotvalidtodate) {
                slot['formattedValidToDate'] = this.getLotExpirationDate(slot);
              }
              if (slot.indskr_lastdispatchdate || this.sampleLastDispatchLimitNum > 0 && slot.indskr_lotvalidtodate) {
                slot['isLastDispatchDate'] = true;
                slot['formattedLastDispatchDate'] = this.getLotLastDispatchDate(slot);
              } else {
                slot['isLastDispatchDate'] = false;
              }
            }
          });
        }
      });

      let sampleData = _.cloneDeep(this.activity.samples);
    
      this.nonDeletedSamplesData = sampleData.filter(o=>{
        o.lots = o.lots.filter(l=>{
          return (!l.deleted && l['indskr_quantity'] && l['indskr_quantity'] > 0);
        }).sort((a,b) => {
          return (a.indskr_lotname.toLowerCase() > b.indskr_lotname.toLowerCase()) ? 1 : -1;
        });

        if (!o.deleted && o.totalQuantity > 0) {
          customerAllocIds.push(o.indskr_customersampleproductid);
          return true;
        }
        return false;
      }).sort((a,b) => {
        return (a.indskr_skuname.toLowerCase() > b.indskr_skuname.toLowerCase()) ? 1 : -1;
      });
      if (this.sampleService.samplingDetailsViewMode !== SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION) {
        setTimeout(()=> this.footerService.initButtons(FooterViews.CaptureSampleOrder),100);
      }
      if(this.nonDeletedSamplesData.some(n => n.indskr_esignaturenecessaryflag)){
        this.isSignatureAllowed = true;
      }
      if (!this.isCompletedActivity) {
        this.sampleService.validatedLicenses = this.sampleService.validatedLicenses.filter(vl => customerAllocIds.includes(vl.customerAllocId));
      }

      if(this.nonDeletedSamplesData.some(sample => sample.lots.some(lot=> lot.indskr_lastdispatchdate && lot.indskr_lastdispatchdate !='')) || this.sampleLastDispatchLimitNum > 0){
        this.isLastDispatchDate = true;
      }
    }
    
    //websocket service response - reject/accepted/completed/timeout
    this.events.subscribe('remote_allocation:rejected',(data)=>{
      if(this.waitingForRemoteConsentResponse){
        this.notificationService.notify(this.translate.instant('SIGNATURE_REQUEST_DECLINED'),'');
        this.waitingForRemoteConsentResponse = false;
        this.remoteSignatureFlowComplete = false;
        if(this.remoteConsentWaitTimer) clearTimeout(this.remoteConsentWaitTimer);
        this.uiService.dismissLoader();
      }
      else{
        this.webSocketService.sendMessageToTopic(Endpoints.websockets.REMOTE_ACTION.replace('{activityId}',
                                                this.activityService.selectedActivity.ID),
                                                {consentTerm:this.consentTerm, channels:this.consentService.savedChannels,
                                                  type:'remote_allocation:timeout', contactID: this.contact.ID
                                                },true
        );
      }
    });
    this.events.subscribe('remote_allocation:accepted',()=>{
      if(this.waitingForRemoteConsentResponse){
        this.notificationService.notify(this.translate.instant('SIGNATURE_REQUEST_ACCEPTED'),'');
        if(this.remoteConsentWaitTimer) clearTimeout(this.remoteConsentWaitTimer);
        this.remoteConsentWaitTimer = setTimeout(()=>{
          this.notificationService.notify(this.translate.instant('SIGNATURE_REQUEST_TIMEOUT'),'');
          this.waitingForRemoteConsentResponse = false;
          this.uiService.dismissLoader();
          this.webSocketService.sendMessageToTopic(Endpoints.websockets.REMOTE_ACTION.replace('{activityId}',
                                                this.activityService.selectedActivity.ID),
                                                {consentTerm:this.consentTerm, channels:this.consentService.savedChannels,
                                                  type:'remote_allocation:timeout', contactID: this.contact.ID
                                                },true
          );
        },60000)
      }
      else{
        this.webSocketService.sendMessageToTopic(Endpoints.websockets.REMOTE_ACTION.replace('{activityId}',
                                                this.activityService.selectedActivity.ID),
                                                {consentTerm:this.consentTerm, channels:this.consentService.savedChannels,
                                                  type:'remote_allocation:timeout', contactID: this.contact.ID
                                                },true
        );
      }
    })
    this.events.subscribe('remote_allocation:completed',(data)=>{
      if(this.waitingForRemoteConsentResponse){
        const targetNameText : string = this.translate.instant('ALLOWCATION_ORDER').toLowerCase();
        const allocSignatureCompletedText = this.translate.instant('SIGNATURE_REQUEST_COMPLETE_MESSAGE', {targetToolName:targetNameText});
        this.notificationService.notify(data.contactName +' '+ allocSignatureCompletedText,'');
        this.waitingForRemoteConsentResponse = false;
        if(this.remoteConsentWaitTimer) clearTimeout(this.remoteConsentWaitTimer);
        const payload = new CreateSampleDropRequestBody(this.activity);
        const now = new Date().getTime().toString();
        payload.indskr_signature = data.signature;
        payload.statecode = 1;
        payload.statuscode = 2;
        payload.actualend = now;
        this.activity.state = 1;
        this.activity.status = 2;
        this.activity.actualEnd = now;
        this.sampleDataService.updateSampleActivity(payload, this.activity, this.contactService.getContactByID(payload.indskr_contactid)).then(
          async ()=>{
            this.activity.state = 1;
            this.activity.status = 2;
            this.activity.statusString = 'Completed';
            this.activity.color = ActivityColorCode.ActivityCompleted;
            this.activity.signature = data.signature;
            this.activityService.addNewActivityToSampleActivityMetasIndexedBySKU(this.activity);
            this.sampleService.updateSampleEligibilities(this.activity,null);
            if(this.authenticationService.hasFeatureAction(FeatureActionsMap.RECIEPTS_TAB)){
              this.sampleService._addToLotDroppedQuantityDetail(this.activity);
            }
            this.uiService.dismissLoader();
            this.remoteSignatureFlowComplete = true;
            this.events.publish('selectedActivityChangedInBackround');
            this.navService.popChildNavPageWithPageTracking();
          },
          async (error)=>{
            this.activity.state = 0;
            this.activity.status = 1;
            this.uiService.dismissLoader();
            this.remoteSignatureFlowComplete = true;
          }
        );
      }
      else{
        this.webSocketService.sendMessageToTopic(Endpoints.websockets.REMOTE_ACTION.replace('{activityId}',
                                                this.activityService.selectedActivity.ID),
                                                {consentTerm:this.consentTerm, channels:this.consentService.savedChannels,
                                                  type:'remote_allocation:timeout', contactID: this.contact.ID
                                                },true
        );
      }
    });
    this.events.subscribe('remote_meeting_participant_Joined_left',this._handleContactConnectionLost.bind(this));
    this._initPageTitle();
    this._initSectionHeader();
  }

  ngOnDestroy() {
    this.events.unsubscribe('remote_allocation:rejected');
    this.events.unsubscribe('remote_allocation:accepted');
    this.events.unsubscribe('remote_allocation:completed');
    this.events.unsubscribe("remote_allocation:timeout");
    this.events.unsubscribe('remote_meeting_participant_Joined_left',this._handleContactConnectionLost)
  }

  async launchSignaturePad(){
    if(!this.consentService.allocationRemoteFlow) {
      const confirmMessage = this.consentTerm.indskr_confirmationmessage || '';
      const popover = await this.popoverCtrl.create({
                                                    component: CaptureSignaturePopoverComponent,
                                                    componentProps: {
                                                      confirmMessage: confirmMessage
                                                    },
                                                    cssClass: 'signature-pad',
                                                    backdropDismiss:false});
      popover.present();
      popover.onDidDismiss().then((signData: any)=>{
        let data = signData.data;
        //if popover dismissed by submitted signature
        if(data && data.completedFlow) {
          this.orderCompletionEventHandler(data.signatureData);
          if(this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING || this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_EVENTS) {
            this.navCtrl.pop().then(()=>{
            this.footerService.initButtons(FooterViews.SampleDropInMeetingDetails);
            this.modalCtrl.dismiss({isCompleted: true});
            this.sampleService.samplingDetailsViewMode = undefined;
            this.footerService.samplingInModalViewActive = false;
            this.sampleService.inMeetingAllocationActivity = undefined;});
          } else if(this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION) {
            this.navService.popChildNavPageWithPageTracking();
          } else{
            this.navService.popWithPageTracking().then(()=>{
            this.footerService.initButtons(FooterViews.SampleDropDetails);});
          }
        }
      });
    }
    else {
      if(this.activityService.isThereConnectedContactsForCurrentMeeting(this.activityService.selectedActivity as AppointmentActivity)) {
        this.remoteSignatureFlowComplete = false;
        this.uiService.displayLoader({},true);
        let isReadOnly = false;
        let contactInfoObj = {title:this.contact.title, firstName:this.contact.firstName, lastName:this.contact.lastName, fullName:this.contact.fullname, fax:this.contact.fax};
        this.webSocketService.sendMessageToTopic(
          Endpoints.websockets.REMOTE_ACTION.replace('{activityId}', this.activityService.selectedActivity.ID),
          {type:'allocation_signature_capture',
           contactID: this.contact.ID,
           consentTerm: this.consentTerm,
           allocActivity: this.activity, 
           readonly: isReadOnly,
           contactInfo:contactInfoObj,
           isSignatureAllowed: true,
          },
        true);
        this.waitingForRemoteConsentResponse = true;
        this.notificationService.notify(this.translate.instant('SIGNATURE_REQUEST_SENT'),'');
        this.remoteConsentWaitTimer = setTimeout(()=>{
          this.notificationService.notify(this.translate.instant('SIGNATURE_REQUEST_TIMEOUT'),'');
          this.waitingForRemoteConsentResponse = false;
          this.uiService.dismissLoader();
          this.webSocketService.sendMessageToTopic(Endpoints.websockets.REMOTE_ACTION.replace('{activityId}',
                                                  this.activityService.selectedActivity.ID),
                                                  {consentTerm:this.consentTerm, channels:this.consentService.savedChannels,
                                                    type:'remote_allocation:timeout', contactID: this.contact.ID
                                                  },true
          );
        },60000)
      }
    }
  }

  openConfirmationPopup() {
    if(!this.consentService.allocationRemoteFlow) {
      this.alertService.showAlert({
        title: this.translate.instant('CONFIRM_THE_ORDER'),
        message: this.consentTerm.indskr_confirmationmessage ? this.consentTerm.indskr_confirmationmessage : this.translate.instant('ONCE_ORDER_CONF_NO_MODIFIED')
      }, this.translate.instant('CONFIRM')
      ).then (res => {
        if(res.role === 'ok') {
          this.submitWithoutSignature();
        }
      });
    } else {
      if(this.activityService.isThereConnectedContactsForCurrentMeeting(this.activityService.selectedActivity as AppointmentActivity)) {
        this.remoteSignatureFlowComplete = false;
        this.uiService.displayLoader({},true);
        let isReadOnly = false;
        let contactInfoObj = {title:this.contact.title, firstName:this.contact.firstName, lastName:this.contact.lastName, fullName:this.contact.fullname, fax:this.contact.fax};
        this.webSocketService.sendMessageToTopic(
          Endpoints.websockets.REMOTE_ACTION.replace('{activityId}', this.activityService.selectedActivity.ID),
          {type:'allocation_signature_capture',
           contactID: this.contact.ID,
           consentTerm: this.consentTerm,
           allocActivity: this.activity, 
           readonly: isReadOnly,
           contactInfo:contactInfoObj,
           isSignatureAllowed: false,
          },
        true);
        this.waitingForRemoteConsentResponse = true;
        this.notificationService.notify(this.translate.instant('SIGNATURE_REQUEST_SENT'),'');
        this.remoteConsentWaitTimer = setTimeout(()=>{
          this.notificationService.notify(this.translate.instant('SIGNATURE_REQUEST_TIMEOUT'),'');
          this.waitingForRemoteConsentResponse = false;
          this.uiService.dismissLoader();
          this.webSocketService.sendMessageToTopic(Endpoints.websockets.REMOTE_ACTION.replace('{activityId}',
                                                  this.activityService.selectedActivity.ID),
                                                  {consentTerm:this.consentTerm, channels:this.consentService.savedChannels,
                                                    type:'remote_allocation:timeout', contactID: this.contact.ID
                                                  },true
          );
        },60000)
      } 
    }
  }

  async submitWithoutSignature() {
    //possible to submit without signature if no feature action fro signature
    this.trackingService.tracking('AllocationOrderSubmit',TrackingEventNames.ALLOCATIONS, null, true);
    if(this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING || this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_EVENTS){
      this.sampleService.inMeetingAllocationActivity = undefined;
      this.modalCtrl.dismiss({isCompleted: true});
    } else if (this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION) {
      this.navService.popChildNavPageWithPageTracking();
    } else{
      this.navService.popWithPageTracking().then(()=>{
        this.footerService.initButtons(FooterViews.SampleDropDetails);
      });
    }
    this.orderCompletionEventHandler();
  }

  closepage(){
      this.trackingService.tracking('AllocationAcknowledgementBack', TrackingEventNames.ALLOCATIONS)
      if (this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION) {
        this.navService.popChildNavPageWithPageTracking();
        return;
      } else if (this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING || this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_EVENTS) {
        this.footerService.initButtons(FooterViews.SampleDropInMeetingDetails);
        this.navCtrlIonNav = this.injector.get(IonNav);
        this.navCtrlIonNav.pop();
      } else {
        if(this.activity.statusString === 'Completed'){
          this.modalCtrl.dismiss({isCompleted: true});
        }
        else{
          this.modalCtrl.dismiss();
        }
      }
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
        case 'close':
            this.closepage();
            break;
        default:
            console.log('Unhandled switch case statement');
            break;
    }
  }

  private _initPageTitle(): void {
    let buttons = [];
    if(this.inMeetingFlow || this.inMeetingPresentationFlow) {
    buttons.push({
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      });
    } else buttons = [];
    this.captureAllocationOrder = {
      id: 'capture-allocation-order',
      title: this.translate.instant('CAPTURE_ALLOCATION_ORDER_ACKNOWLEDGEMENT'),
      controls: buttons,
      isHCPFacing: true
    };
  }
  private _initSectionHeader() {
    let detailsTitleText = this.translate.instant('DETAILS');
    if(this.translate.currentLang == 'es') {
      detailsTitleText = this.translate.instant('DETAILS_ALLOCATION_ORDER_ACKNOWLEDGMENT');
    }
    this.detailsHeaderModel = {
      id: 'sample-details-header',
      title: detailsTitleText,
      controls: [],
    }
    this.termsHeaderModel = {
      id: 'terms-header',
      title: this.translate.instant('TERMS_CAP'),
      controls: [
        {
          id: 'view-expandable',
          text: this.isViewLess ? this.translate.instant('VIEW_MORE') : this.translate.instant('VIEW_LESS'),
          isVisible: this.termBodyView ? true : false,
          isDisabled: this.isLimitedView ? true : false,
        },
      ]
    };
    this.signatureHeaderModel = {
      id: 'signature-header',
      title: this.translate.instant('SIGNATURE_CAP'),
      controls: [],
    };
  }
  public onSectionHeaderControlClick(id:string) {
    if (id == 'view-expandable') {
      this._checkContainerHeight();
    }
  }
  private _checkContainerHeight(){
    const termBodyContainer = document.getElementById('termBodyContainer');
    const containerHeight = termBodyContainer ? termBodyContainer.getBoundingClientRect().height : null;
    this.isLimitedView = false;
    const pTagInTermBody = termBodyContainer.getElementsByTagName('p');
    let totalPtagHeight: number = 0;
    for(let p of pTagInTermBody) {
      totalPtagHeight += p.getBoundingClientRect().height;
    }
    if(containerHeight && containerHeight > 51) {
      this.isViewLess = true;
    } else {
      this.isViewLess = false;
      if(pTagInTermBody && totalPtagHeight < 30) this.isLimitedView = true;
    }
    this._initSectionHeader();
  }

  getFormattedAndLocalisedDate(value: any) {
     try {
      return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
     } catch (error) {
       return '';
     }
  }

  getFormattedAndLocalisedDateAndTime(value: any) {
    try {
     return this.datePipe.transform(value, this.dateTimeFormatsService.dateTime, undefined, this.translate.currentLang);
    } catch (error) {
      return '';
    }
 }

  getLotExpirationDate(lot: LotDetailInSampleActivity): string {
    let formattedDate: Date = undefined;
    if (lot.indskr_lotvalidtodate) {
      const tmpDate: Date = Utility.changeUTCDateToLocalDateWith0Time(parseInt(lot.indskr_lotvalidtodate),true);
      formattedDate = new Date(tmpDate);
    }
    return this.getFormattedAndLocalisedDate(formattedDate);
  }

  getLotLastDispatchDate(lot: LotDetailInSampleActivity): string {
    let formattedDate: Date = undefined;
    if (lot.indskr_lastdispatchdate) {
      const isTimestamp: boolean = this.dateTimeFormatsService.isTimestampFormat(lot.indskr_lastdispatchdate);
      if (isTimestamp) {
        formattedDate = new Date(parseInt(lot.indskr_lastdispatchdate));
      } else {
        const tmpDate: Date = new Date(lot.indskr_lastdispatchdate);
          if (isValid(tmpDate)) {
            formattedDate = new Date(tmpDate);
          }
      }
    } else if (this.sampleLastDispatchLimitNum > 0 && lot.indskr_lotvalidtodate) {
      const tmpDate: Date = Utility.changeUTCDateToLocalDateWith0Time(parseInt(lot.indskr_lotvalidtodate),true);
      formattedDate = subDays(new Date(tmpDate), this.sampleLastDispatchLimitNum);
    }
    return this.getFormattedAndLocalisedDate(formattedDate);
  }

  //Update termBody
  private _updateTokens(term: ConsentTerm) {
    const cTerm: ConsentTerm = JSON.parse(JSON.stringify(term));
    let termBody: string = cTerm.indskr_body ? cTerm.indskr_body.toString() : "";
    if (termBody) {
      for (let token in TermTokens) {
        if (TermTokens.hasOwnProperty(token)) {
          if (termBody.indexOf(TermTokens[token]) >= 0) {
            termBody = this._replaceTokens(termBody, TermTokens[token]);
          }
        }
      }
      this.termBody = termBody;
      let formattedBody = this._replacePropertyInTag(termBody);
      this.termBodyView = this.sanitizer.bypassSecurityTrustHtml(formattedBody);
    }
  }
  private _replaceTokens(termBody: string, token: string): string {
    switch (token) {
      case TermTokens.PRODUCT_NAME_TOKEN:
        let pNames = this._stringifyProductNames();
        termBody = termBody.split(token).join(pNames ? pNames : "");
        break;
      case TermTokens.CONTACT_TITLE_TOKEN:
        termBody = termBody.split(token).join(this.contact && this.contact.title ? this.contact.title : "");
        break;
      case TermTokens.CONTACT_FIRST_NAME_TOKEN:
        termBody = termBody.split(token).join(this.contact && this.contact.firstName ? this.contact.firstName : "");
        break;
      case TermTokens.CONTACT_LAST_NAME_TOKEN:
        termBody = termBody.split(token).join(this.contact && this.contact.lastName ? this.contact.lastName : "");
        break;
      case TermTokens.CONTACT_FULL_NAME_TOKEN:
        termBody = termBody.split(token).join(this.contact && this.contact.fullname ? this.contact.fullname : "");
        break;
      case TermTokens.CONTACT_EMAIL_TOKEN:
        let eNames = this._stringifyEmails();
        termBody = termBody.split(token).join(eNames ? eNames : "");
        break;
      case TermTokens.CONTACT_ADDRESS_TOKEN:
        let aNames = this._stringifyAddresses();
        termBody = termBody.split(token).join(aNames ? aNames : "");
        break;
      case TermTokens.CONTACT_PHONE_TOKEN:
        let phoneNums = this._stringifyPhoneNumbers();
        termBody = termBody.split(token).join(phoneNums ? phoneNums : "");
        break;
      case TermTokens.CONTACT_FAX_TOKEN:
        termBody = termBody.split(token).join(this.contact && this.contact.fax ? this.contact.fax : "");
        break;
      default:
    }
    return termBody;
  }
  private _stringifyProductNames(): string {
    let products: Product[] = this.consentTerm.products.filter(
      product => product.isChecked
    );
    return Array.prototype.map.call(products, s => s.indskr_productname).toString();
  }
  private _stringifyEmails(): string {
    let values: ChannelValue[] = [];
    this.consentTerm.channels.forEach(channel => {
      if (channel.indskr_consentType == (ChannelType.EMAIL || ChannelType.FACEBOOK)) {
        if (channel.values) {
          channel.values.forEach(value => {
            if (value.isChecked) values.push(value);
          });
        }
      }
    });
    return Array.prototype.map.call(values, s => s.value).toString();
  }
  private _stringifyPhoneNumbers(): string {
    let values: ChannelValue[] = [];
    this.consentTerm.channels.forEach(channel => {
      if (channel.indskr_consentType == (ChannelType.PHONE || ChannelType.SMS || ChannelType.WHATSAPP)) {
        if (channel.values) {
          channel.values.forEach(value => {
            if (value.isChecked) values.push(value);
          });
        }
      }
    });
    return Array.prototype.map.call(values, s => s.value).toString();
  }
  private _stringifyAddresses(): string {
    let values: ChannelValue[] = [];
    this.consentTerm.channels.forEach(channel => {
      if (channel.indskr_consentType == ChannelType.VISIT) {
        if (channel.values) {
          channel.values.forEach(value => {
            if (value.isChecked) values.push(value);
          });
        }
      }
    });
    return Array.prototype.map.call(values, s => s.value).toString();
  }
  
  private _handleContactConnectionLost(data){
    if(this.inMeetingPresentationFlow && data.contactID && data.contactID == this.contact.ID){
      if (data.joinedStatus === 'joined') {
        this.consentService.allocationRemoteFlow = true;
        this.remoteConnectionLost = false;
      } else if (data.joinedStatus === 'left') {
        if (data.contactID && data.contactID == this.contact.ID) {
          this.consentService.allocationRemoteFlow = false;
          this.remoteConnectionLost = true;
        }
      }
    }
  }

  private _getDefaultConsentForm(): ConsentTerm {
    let defaultConsentTerm: ConsentTerm = {
      _id: "",
      _rev: "",
      indskr_consenttermsid: "",
      indskr_consentid:"",
      indskr_consentTermName: "Default Term",
      indskr_fromdate: 1646073000000,
      indskr_untildate: 1656527400000,
      createdon: 1646073000000,
      modifiedon: 1646073000000,
      indskr_alwaysrequired: true,
      indskr_recaptureconsent: true,
      indskr_body: "",
      indskr_contactid: "",
      offlineId: "",
      indskr_signature: "",
      indskr_positionid: "",
      userid: "",
      indskr_signaturerequired: 548910001,
      products: Product[""],
      channels: Channel[""],
      track_action: 11,
      indskr_confirmationmessage: this.translate.instant('ONCE_ORDER_CONF_NO_MODIFIED'),
      indskr_agreementmessage: this.translate.instant('I_AGREE_ON_TERMS'),
      indskr_allocationordersubmission: true
     }
     return defaultConsentTerm;
  }
  private _replacePropertyInTag(contentBody: any): any {
    return this.activityService.replacePropertyInTag(contentBody);
  }

  openUrl(event:any) {
    if (this.device.isOffline && !event || !event.target) return;
    const target = event.target;
    this.activityService.openExternalLink(target);
  }
}
