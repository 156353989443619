import { ChangeDetectorRef, Component, ElementRef, Input, SimpleChanges, ViewChild } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import {
  MbscCalendarEvent,
  MbscCalendarMarked,
  MbscCellClickEvent,
  MbscEventClickEvent,
  MbscEventcalendar,
  MbscEventcalendarOptions,
  MbscEventcalendarView,
  MbscLocale,
  MbscPageChangeEvent,
  MbscPageLoadedEvent,
  MbscPageLoadingEvent,
  MbscSelectedDateChangeEvent,
  MbscSelectedEventsChangeEvent,
  locale,
} from "@mobiscroll/angular";
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectPopover } from "@omni/components/multi-select-popover/multi-select-popover";
import { Events } from "@omni/events";
import { ActivityService } from "@omni/services/activity/activity.service";
import { AuthenticationService } from "@omni/services/authentication.service";
import { DeviceService } from "@omni/services/device/device.service";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { UIService } from "@omni/services/ui/ui.service";
import { debounceTime } from "rxjs/operators";
import { NewActivityComponent } from "../new-activity/new-activity";
import { addDays, addMinutes, addMonths, addWeeks, isFuture, isSameDay, isToday, isValid, subWeeks } from "date-fns";
import { Subscription } from "rxjs";
import { IActivityEvent, IActivityFilterEvent } from "@omni/interfaces/activity/activity-event.interface";
import { Activity, ActivityType } from "@omni/classes/activity/activity.class";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { Appointment } from "@omni/models/appointment-data-model";
import _ from 'lodash';
import { GlobalUtilityService } from "@omni/services/global-utility.service";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { unsubscribeSubscriptionArray } from "@omni/utility/common.utility";
import { TimeOffService } from "@omni/services/time-off/time-off.service";
import { AppointmentActivity } from "@omni/classes/activity/appointment.activity.class";
import { StoreCheckActivity } from "@omni/classes/activity/store-check.activity.class";
import { SampleActivity } from "@omni/classes/activity/sample.activity.class";
import { TimeOffActivity } from "@omni/classes/activity/timeoff.class";
import { PhoneActivity } from "@omni/classes/activity/phone.activity.class";
import { CaseActivity } from "@omni/classes/case-intake/case-activity.class";
import { EmailActivity, EmailActivityParty, EmailStatusCodes } from "@omni/classes/activity/email.activity.class";
import { Channel, ChannelActivityType, ChannelType } from "@omni/classes/consent/channel.class";
import { OrderActivity } from "@omni/classes/activity/order.activity.class";
import { Utility } from "@omni/utility/util";
import { ConsentService } from "@omni/services/consent/consent.service";
import { SetBookingActivity } from "@omni/classes/activity/set-booking.activity.class";
import { SecInfoConfigDataService } from "@omni/data-services/sec-info-config/sec-info-config-data-service";
import { SecondaryInfoEntityName } from "@omni/classes/sec-info-config/sec-info.class";
import { FOLLOW_UP_TYPE } from "@omni/classes/activity/follow-up-action.activity.class";

// Custom types
type CalendarViewTypes = 'Month' | 'Week' | '3 Days' | 'Day';
type CalendarViewTransition = 'day-3days' | 'day-week' | 'day-month' | '3days-day' | '3days-week' | '3days-month'
                              | 'week-day' | 'week-3days' | 'week-month' | 'month-day' | 'month-3days' | 'month-week';
type MbscCalendarViewTypes = 'month' | 'week';
type MbscSchedulerViewTypes = 'month' | 'week' | 'day';


@Component({
  selector: 'activity-calendar',
  templateUrl: 'activity-calendar.html',
  styleUrls: ['activity-calendar.scss'],
})
export class ActivityCalendarComponent {
  // Search text value input from parent/sibling
  @Input() calendarSearchText: string = '';
  // Event Calendar instance
  @ViewChild('calendar', { static: true }) calendar: MbscEventcalendar;

  currentViewType: CalendarViewTypes = 'Day';
  currentViewLabel: string = this.getViewLabel(this.currentViewType);

  selectedDate: Date = new Date();
  // This variable holds the current date that user intended to be.
  // selectedDate variable sometimes changes in unwanted way during
  // view/page transition due to two-way binding nature.
  // masterSelectionDate will be used to correct in such scenarios.
  masterSelectedDate: Date = new Date(this.selectedDate);

  // Event Calendar view options
  view: MbscEventcalendarView = {
    calendar: {
      type: this.getCalendarViewType(this.currentViewType),
      labels: false,
      popover: false,
    },
    schedule: {
      type: this.getSchedulerViewType(this.currentViewType),
      timeCellStep: 30,
      maxEventStack: this.getSchedulerViewType(this.currentViewType) == 'day' ? 'all' : 1
    },
  };

  // Event Calendar default options
  options: MbscEventcalendarOptions = {
    theme: 'ios',
    themeVariant: 'light',
  };

  myLocale: MbscLocale;
  myTimeFormat: string = 'h A';
  allDayTextValue: string = this.translate.instant("ALL_DAY");

  // Activity data for the calendar
  activities: MbscCalendarEvent[] = [];
  activitiesForSearch: MbscCalendarEvent[] = [];
  marked: MbscCalendarMarked[] = [];

  viewOptionsPopoverHandle: any;
  viewOptionsPopoverData: any;

  private transition: CalendarViewTransition;
  private viewInTransition = false;
  private pageInTransition = false;

  private hasSuggestedMeetingsEnabled: boolean;
  private hasSuggestedWeComMessagesEnabled: boolean;
  private _subscriptions: Subscription[] = [];

  private isSearching: boolean = false;
  private isActivityEventHandler: boolean = false;

  constructor(
    private device: DeviceService,
    private translate: TranslateService,
    private authService: AuthenticationService,
    private activityService: ActivityService,
    private uiService: UIService,
    private events: Events,
    private popoverCtrl: PopoverController,
    protected navService: NavigationService,
    public utilityService: GlobalUtilityService,
    private cd: ChangeDetectorRef,
    private readonly el: ElementRef,
    public dateTimeFormatsService: DateTimeFormatsService,
    private readonly timeOffService: TimeOffService,
    private consentService: ConsentService,
    private secInfoConfigDataService: SecInfoConfigDataService
  ) {
    this.setLocale();
    this.initViewOptionsPopoverData();
    this.allDayTextValue = this.translate.instant("ALL_DAY");
    this.myTimeFormat = this.dateTimeFormatsService.is12HourFormat ? 'h A' : 'HH';

    // Language change event subscription
    this.translate.onLangChange.pipe(
      debounceTime(100)
    ).subscribe(data => {
      if (data) {
        this.setLocale();
        this.initViewOptionsPopoverData();
        this.allDayTextValue = this.translate.instant("ALL_DAY");
      }
    });

    this.hasSuggestedMeetingsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_MEETINGS);
    
    // Handle activity CRUD events
    this._subscriptions.push(
      this.activityService.activityEvent$
        .pipe(
          debounceTime(250),
        )
        .subscribe((event: IActivityEvent) => {
          if (event?.activity &&
            ((event.activity.type === ActivityType.Appointment
              && event.activity['indskr_suggest']
              && !this.hasSuggestedMeetingsEnabled)
              || (event.activity.type === ActivityType.Email
                && event.activity['indskr_suggest'] && !this.hasSuggestedWeComMessagesEnabled))) {
          } else {
            this._activityEventHandler(event);
          }
        })
      );

    // Handle activity data refresh event
    this._subscriptions.push(
        this.activityService.activityDataFilterEvent$
        .pipe(
          debounceTime(250),
        )
        .subscribe(async (event: IActivityFilterEvent) => {
          if (event.filterType === 'activityType' || event.filterType === 'teamView') {
            const startDate = isValid(new Date(this.selectedDate)) ? new Date(this.selectedDate) : new Date();
            await this.reloadDataWithinRange(startDate);
          }
        })
      );
      
    // Time format change event subscription
    this._subscriptions.push(
      this.dateTimeFormatsService.timeFormat.subscribe(tf => {
        if (tf) {
          this.myTimeFormat = this.dateTimeFormatsService.is12HourFormat ? 'h A' : 'HH';
          this.cd.detectChanges();
        }
      })
    );
  }


  /**
   *
   *  Init
   */
  private initViewOptionsPopoverData() {
    this.currentViewLabel = this.getViewLabel(this.currentViewType);
    this.viewOptionsPopoverData = [
      {
        text: '',
        expanded: true,
        value: this.currentViewType,
        items: [
          { text: this.getViewLabel('Day'), value: 'Day' },
          { text: this.getViewLabel('3 Days'), value: '3 Days' },
          { text: this.getViewLabel('Week'), value: 'Week' },
          { text: this.getViewLabel('Month'), value: 'Month' },
        ],
        handler: this.viewChangeHandler,
      }
    ];
  }

  ngOnInit() {
    this.timeOffService.setMobileTracker('week');
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes) {
        if (changes.calendarSearchText
          && changes.calendarSearchText.previousValue !== undefined
          && (changes.calendarSearchText.currentValue !== changes.calendarSearchText.previousValue || changes.calendarSearchText.firstChange)
        ) {
          const searchTxt: string = changes?.calendarSearchText?.currentValue ?? '';
          this._searchByActivitySubject(searchTxt);
        }
      }
    }
    catch (err) {
      console.error(err);
    }
  }

  ngOnDestroy() {
    unsubscribeSubscriptionArray(this._subscriptions);
  }

  /**
   *
   *  Helpers
   */
  private setLocale() {
    const langCode = this.translate.currentLang === 'zh_CN' ? 'zh' : this.translate.currentLang;
    this.myLocale = locale[langCode];
  }
  // Gets supported view type option for the Calendar component of Event Calendar
  private getCalendarViewType(viewType: CalendarViewTypes): MbscCalendarViewTypes {
    switch (viewType) {
      case 'Month':
        return 'month';
      default:
        return 'week';
    }
  }
  // Gets supported view type option for the Scheduler component of Event Calendar
  private getSchedulerViewType(viewType: CalendarViewTypes): MbscSchedulerViewTypes {
    switch (viewType) {
      case 'Week':
        return 'week';
      case 'Month':
        return 'month';
      default:
        return 'day';
    }
  }
  // Gets view size option for the Scheduler component of Event Calendar
  private getSchedulerViewSize(viewType: CalendarViewTypes): 1 | 3 {
    return viewType === '3 Days' ? 3 : viewType === 'Day' ? undefined : 1;
  }
  // private isDateBetween(start: Date, end: Date, dateToCheck: Date) {
  //   if (
  //     start?.getTime() <= dateToCheck.getTime()
  //     && dateToCheck.getTime() < end.getTime()
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }
  private isSameDate(date1: Date, date2: Date) {
    if (
      date1.getFullYear() === date2.getFullYear()
      && date1.getMonth() === date2.getMonth()
      && date1.getDate() === date2.getDate()
    ) {
      return true;
    }
    return false;
  }
  private getViewLabel(view: CalendarViewTypes): string {
    let label: string;
    switch (view) {
      case 'Day':
        label = this.translate.instant('DAY');
        break;
      case '3 Days':
        label = '3 ' + this.translate.instant('DAYS');
        break;
      case 'Week':
        label = this.translate.instant('WEEK');
        break;
      case 'Month':
        label = this.translate.instant('MONTH');
        break;
      default:
        break;
    }
    return label;
  }
  // Gets the CalendarViewTransition value that tells from where and to the view changes
  private getTransition(prev: CalendarViewTypes, curr: CalendarViewTypes): CalendarViewTransition {
    let transition: CalendarViewTransition;

    if (prev === 'Day') {
      switch (curr) {
        case '3 Days':
          transition = 'day-3days';
          break;
        case 'Week':
          transition = 'day-week';
          break;
        case 'Month':
          transition = 'day-month';
          break;
        default:
          break;
      }
    } else if (prev === '3 Days') {
      switch (curr) {
        case 'Day':
          transition = '3days-day';
          break;
        case 'Week':
          transition = '3days-week';
          break;
        case 'Month':
          transition = '3days-month';
          break;
        default:
          break;
      }
    } else if (prev === 'Week') {
      switch (curr) {
        case '3 Days':
          transition = 'week-3days';
          break;
        case 'Day':
          transition = 'week-day';
          break;
        case 'Month':
          transition = 'week-month';
          break;
        default:
          break;
      }
    } else if (prev === 'Month') {
      switch (curr) {
        case '3 Days':
          transition = 'month-3days';
          break;
        case 'Week':
          transition = 'month-week';
          break;
        case 'Day':
          transition = 'month-day';
          break;
        default:
          break;
      }
    }

    return transition;
  }
  private getFirstDayOfWeek(d: Date, prev: boolean): Date {
    const day = d.getDay();
    const diff = d.getDate() - day + (prev ? -7 : 7);
    return new Date(d.setDate(diff));
  }


  /**
   *
   *  Mobiscroll Event Calendar Events
   *  Refer to https://docs.mobiscroll.com/angular/eventcalendar
   */
  onPageChange(event: MbscPageChangeEvent) {
    // console.warn("onPageChange");
    if (!this.transition) {
      // Flag page changing within the same view
      this.pageInTransition = true;
    }
  }
  onPageLoading(event: MbscPageLoadingEvent) {
    // console.warn("onPageLoading");
    // Load data dynamically
    setTimeout(async () => {
      const fromDay = event.firstDay;
      await this.reloadDataWithinRange(fromDay);
    });
  }

  onPageLoaded(event: MbscPageLoadedEvent) {
    // console.warn("onPageLoaded");
    // Flag view transition from / to Month view
    // to skip one extra onPageLoaded call before
    // updating selectedDate
    if (
      !this.viewInTransition
      && (
        this.transition?.includes('month-')
        || this.transition?.includes('-month')
      )
    ) {
      this.viewInTransition = true;
      if (this.currentViewType === "Day") {
        this._scrollToFirstActivity();
      }
      return;
    } else if (this.viewInTransition) {
      // Clear the flag
      this.viewInTransition = false;
    }

    // Page loading is done. Scroll to the time.
    if (this.transition) {
      // Page changed with the view change
      this.transition = undefined;
      if (this.currentViewType === "Day") {
        this._scrollToFirstActivity();
      } else {
        setTimeout(() => {
          const now = new Date();
          const newDate = new Date(this.masterSelectedDate);
          newDate.setHours(
            now.getHours(),
            now.getMinutes(),
            now.getSeconds(),
            now.getMilliseconds(),
          );
          this.selectedDate = newDate;
          this.masterSelectedDate = new Date(newDate);
        }, 100);
      }
    } else {
      // Page changed within current view
      if (this.pageInTransition) {
        // console.warn("onPageLoaded - skipped");
        this.pageInTransition = false;
        return;
      } else {
        // console.warn("onPageLoaded - set selected date");
        this._scrollToFirstActivity();
      }
    }
  }
  onSelectedDateChange(event: MbscSelectedDateChangeEvent) {
    // console.warn("onSelectedDateChange");
  }
  onSelectedEventChange(event: MbscSelectedEventsChangeEvent) {
    // console.warn("onPageLoading");
  }
  onCellClick(event: MbscCellClickEvent) {
    // console.warn("onCellClick");

    let newDate = new Date(event.date);
    if (event.source === "timeline" || event.source === "schedule") {
      // css for selected cell
      let prevSelectedTargetCell = this.el.nativeElement.querySelector(".ind-mbsc-scheduler-selected-cell");
      if (prevSelectedTargetCell) {
        prevSelectedTargetCell.classList.remove("ind-mbsc-scheduler-selected-cell");
      }
      let selectedTargetCell = event.target;
      if (selectedTargetCell) {
        selectedTargetCell.classList.add("ind-mbsc-scheduler-selected-cell");
      }
      // set cellData
      const endDate = addMinutes(new Date(event.date), 30);
      const targetCellDate = { startDate: newDate, endDate: endDate }
      this.uiService.cellData = { view: 'day-week-views', data: targetCellDate };
      this.uiService.selectedCalendarDateInWeekView = new Date(newDate);

      if (this.device.isMobileDevice && this.device.deviceOrientation === 'PORTRAIT') {
        this.uiService.showRightPane = true;
      }
      if (!this.activityService.teamViewActive || this.activityService.isMyUserSelectedInTeamView) {
        this.uiService.setShowNewActivity(true);
        if (this.navService.getActiveChildNavViewPageName() != PageName.NewActivityComponent)
          this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.ActivitiesPageComponent);
        // update date and time in new activity
        this.events.publish('updatedCellClick');
      }
      if (this.currentViewType === '3 Days' || this.currentViewType === 'Week') {
        this.selectedDate = new Date(newDate);
        this.masterSelectedDate = new Date(newDate);
      }
    } else {
      if (this.currentViewType === 'Month') {
        // Clicked a date from the month view.
        // Set the default scroll time.
        newDate.setHours(8);
      } else {
        // Day, 3 Days, Week views
        if (this.isSameDate(newDate, this.selectedDate)) {
          // Ignore selecting same date
          return;
        }
        if (
          event.source === 'calendar'
          && this.currentViewType === 'Day'
        ) {
          //Required to reload data because all day activities are not displayed after selecting a date on the calendar
          const startDate = new Date(this.selectedDate);
          this.reloadDataWithinRange(startDate);
        }
      }
      // First, giving timeout for the selectedDate change
      setTimeout(() => {
        this.uiService.selectedCalendarDateInWeekView = new Date(newDate);
        // Giving another timeout for Month-to-Day view change
        setTimeout(() => {
          // Move into Day view if date cell clicked from Month view
          if (this.currentViewType === 'Month') {
            this.currentViewType = 'Day';
            this.currentViewLabel = this.getViewLabel(this.currentViewType);
            this.view = {
                schedule: {
                    type: 'day',
                    timeCellStep: 30,
                },
                calendar: {
                    type: this.getCalendarViewType(this.currentViewType),
                    labels: false,
                },
            };
            this.viewOptionsPopoverData[0].value = this.currentViewType;
            this.transition = this.getTransition('Month', this.currentViewType);
          }
          this.events.publish('updatedDayClick');
        });
      });
    }
  }


  /**
   *
   *  Other Event Handlers
   */
  // Calendar view change handler
  private viewChangeHandler = async (selectedItem: { text: string, value: CalendarViewTypes }, popoverData: { value: string }) => {
    if (popoverData.value !== selectedItem.value) {
      popoverData.value = selectedItem.value;
      const prevViewType = this.currentViewType;
      this.currentViewType = selectedItem.value;
      this.currentViewLabel = selectedItem.text;
      this.transition = this.getTransition(prevViewType, this.currentViewType);

      setTimeout(() => {
        // Config and update the view option for the selected view
        if (this.currentViewType === 'Month') {
          // Month view
          this.view = {
            calendar: {
              type: 'month',
              labels: false,
              popover: false,
            },
          };
        } else {
          // Day, 3 Days, Week views
          if (this.currentViewType === 'Day') {
            const tempView: MbscEventcalendarView = {
              calendar: {
                type: this.getCalendarViewType(this.currentViewType),
                labels: false,
              },
              schedule: {
                type: this.getSchedulerViewType(this.currentViewType),
                timeCellStep: 30,
                maxEventStack: 'all'
              },
            };
            this.view = tempView;
          } else {
            // 3 Days and Week views have the scheduler only. 
            // If you use both a calendar and a scheduler, there is an issue with UI alignment.
            const tempView: MbscEventcalendarView = {
              schedule: {
                type: this.getSchedulerViewType(this.currentViewType),
                timeCellStep: 30,
                maxEventStack: 1
              },
            };
            if (this.currentViewType === '3 Days') {
              tempView.schedule.size = this.getSchedulerViewSize(this.currentViewType);
            }
            this.view = tempView;
          }

          // Record the current selectedDate as a reference
          // in case selectedDate changes in unwanted way during the view transition
          this.selectedDate = new Date(this.selectedDate);
          this.masterSelectedDate = new Date(this.selectedDate);
        }
        if (this.viewOptionsPopoverHandle) {
          this.viewOptionsPopoverHandle.dismiss();
        }
      });
    } else {
      if (this.viewOptionsPopoverHandle) {
        this.viewOptionsPopoverHandle.dismiss();
      }
    }
  };
  // Click activity on Scheduler view
  async onEventClick(ev: MbscEventClickEvent) {
    // console.warn("onEventClick");
    // Remove the selected cell
    let prevSelectedTargetCell = this.el.nativeElement.querySelector(".ind-mbsc-scheduler-selected-cell");
    if (prevSelectedTargetCell) {
      prevSelectedTargetCell.classList.remove("ind-mbsc-scheduler-selected-cell");
    }
    // Display activity details
    const selActivity = ev && ev.event;
    if(_.isEmpty(selActivity)) return;
    this.uiService.cellData = { view: 'activity-day-week-views', data: {startDate: new Date(selActivity.startDate), endDate: new Date(selActivity.startDate)} };
    const selActivityId: string = selActivity?.id.toString() || '';
    const activity = this.activityService.getActivityByID(selActivityId);
    if(activity) {
      this.events.publish('selectedActivityChangedInBackround');
      this.events.publish('activity-pane:selectActivity', activity);
    }
  }
  // Calendar view option selection component click handler - Day, 3 Days, Week, Month
  async onCalendarViewOptionBtnClick(event) {
    if (!this.viewOptionsPopoverHandle) {
      this.viewOptionsPopoverHandle = await this.popoverCtrl.create(
        {
          component: MultiSelectPopover,
          componentProps: {
            root: this.viewOptionsPopoverData
          },
          event
        }
      );

      await this.viewOptionsPopoverHandle.present();
      await this.viewOptionsPopoverHandle.onDidDismiss();
      this.viewOptionsPopoverHandle = null;
    }
  }
  // Left and right arrow navigation handler
  // Change 'selectedDate' by offset of each view type
  // to make the calendar to navigate to next/prev page
  navigatePage(prev: boolean): void {
    // console.warn("navigatePage click");
    const currentDate = new Date(this.masterSelectedDate);
    if (this.currentViewType === 'Month') {
      const prevNextPage = new Date(currentDate.getFullYear(), currentDate.getMonth() + (prev ? -1 : 1), 1);
      this.selectedDate = prevNextPage;
      this.masterSelectedDate = new Date(this.selectedDate);
    } else {
      if (this.currentViewType === '3 Days') {
        const diff = currentDate.getDate() + (prev ? -3 : 3);
        const prevNextPage = new Date(currentDate.setDate(diff));
        this.selectedDate = prevNextPage;
        this.masterSelectedDate = new Date(this.selectedDate);
      } else {
        const prevNextSunday = this.getFirstDayOfWeek(currentDate, prev);
        const targetMonday = new Date(addDays(prevNextSunday,1));
        this.selectedDate = targetMonday;
        this.masterSelectedDate = new Date(this.selectedDate);
      }
    }
    if(this.currentViewType != '3 Days') {
      this.uiService.selectedCalendarDateInWeekView = new Date(this.selectedDate);
      this.events.publish('updatedDayClick');
    }
  }

  private async _activityEventHandler(event: IActivityEvent) {
    if (event?.action === 'Update') {
      if (event?.activity?.ID) {
        const newActivityObj: Appointment = this.formatActivityForCalendar(event.activity);
        if(!_.isEmpty(newActivityObj)) {
          this.isActivityEventHandler = true;
          const targetIndex = this.activities.findIndex(a=>a.id == newActivityObj.id);
          if(targetIndex > -1) {
            const scrollToTargetDate = newActivityObj.startDate;
            this.selectedDate = new Date(scrollToTargetDate);
            this.masterSelectedDate = new Date(scrollToTargetDate);

            const startDate = event.activity.startDate;
            await this.reloadDataWithinRange(startDate);
           
          } else {
            console.warn("There is no avaiable data to update");
          }
        }
      }
    } else if (event?.action === 'Create') {
      if (event?.activity) {
        const target: MbscCalendarEvent = await this._getActivityById(event.activity.ID);
        if (!target) {
          this.isActivityEventHandler = true;
          const startDate = event.activity.startDate;
          await this.reloadDataWithinRange(startDate);
        } else {
          console.warn("Received creation event of existing activity");
        }
        //show activity details after creating the activity      
        if (this.activityService.isNewActivityCreated && this.activityService.selectedActivity) {
          this.activityService.isNewActivityCreated = false;
          this.events.publish('activity-pane:selectActivity', this.activityService.selectedActivity);
        }
      }
    } else if (event?.action === 'Delete') {
      if (event?.activity?.ID) {
        const foundActivityObj: MbscCalendarEvent = await this._getActivityById(event.activity.ID);
        if (foundActivityObj) {
          this.isActivityEventHandler = true;
          const startDate = event.activity.startDate;
          await this.reloadDataWithinRange(startDate);
        } else {
          console.warn("There is no available data to delete");
        }
      }
    }
  }

  private async _getActivityById(id: string): Promise<MbscCalendarEvent> {
    const foundActivity = this.activities.find(a =>a.id == id) || undefined;
    return foundActivity;
  }

  private async _getDataWithinRange(curDate: Date = new Date()): Promise<Appointment[]> {
    // - Data loading range
    // - from: cur month first day - 6 weeks or cur date - 8 weeks, which ever is earlier
    // - to: cur next month first day + 6 weeks or cur date + 8 weeks, which ever is later
    const curMonthFirstDay: Date = new Date(curDate);
    curMonthFirstDay.setDate(1);
    curMonthFirstDay.setHours(0, 0, 0, 0);
    const nextMonthFirstDay: Date = addMonths(curMonthFirstDay, 1);
  
    const curDateMinus8Weeks: Date = subWeeks(curDate, 8);
    const curMonthFirstDayMinus6Weeks: Date = subWeeks(curMonthFirstDay, 6);
    const curDatePlus8Weeks: Date = addWeeks(curDate, 8);
    const nextMonthFirstDayPlus6Weeks: Date = addWeeks(nextMonthFirstDay, 6);
  
    let dataRangeFrom = curDateMinus8Weeks.getTime() < curMonthFirstDayMinus6Weeks.getTime()
      ? curDateMinus8Weeks : curMonthFirstDayMinus6Weeks;
    let dataRangeTo = curDatePlus8Weeks.getTime() > nextMonthFirstDayPlus6Weeks.getTime()
      ? curDatePlus8Weeks : nextMonthFirstDayPlus6Weeks;
  
    return await this.getCalendarDataInDateRange(dataRangeFrom, dataRangeTo);
  }

  async reloadDataWithinRange(startDate: Date) {
    // console.warn("reloadDataWithinRange");
    if(this.isSearching) return;
    const fromDay = new Date(startDate);
    this.activities = await this._getDataWithinRange(fromDay);
    this.activitiesForSearch = _.cloneDeep(this.activities);
    this.cd.detectChanges();
  }

  // private _binarySearchDates(dateToCheck: Date): boolean {
  //   let leftIndex = 0;
  //   let rightIndex = this.activities.length - 1;

  //   while (leftIndex <= rightIndex) {
  //     const midIndex = Math.floor((leftIndex + rightIndex) / 2);
  //     const midDate = this.activities[midIndex]['startDate'];
  //     if (midDate.getTime() === dateToCheck.getTime()) {
  //       return true;
  //     } else if (midDate.getTime() < dateToCheck.getTime()) {
  //       leftIndex = midIndex + 1;
  //     } else {
  //       rightIndex = midIndex - 1;
  //     }
  //   }
  //   return false;
  // }


  // search function
  private async _searchByActivitySubject(searchTxt: string) {
    this.activities = _.cloneDeep(this.activitiesForSearch);
    if(!_.isEmpty(searchTxt)) {
      this.isSearching = true;
      let tempActivites = _.cloneDeep(this.activities);
      tempActivites = tempActivites.filter(activity => {
        let text:string = searchTxt.trim().toLocaleLowerCase();
        let str:string = '';
        if(activity.text){
          str = activity.text.trim().toLowerCase();
        }
        //ToDO: search in the other secondary info??
        if(activity.location){
          str = str + ' ' + activity.location.trim().toLowerCase();
        }
        return str.includes(text);
      });

      this.activities = tempActivites;
      this._scrollToFirstActivity();
      
    }else {
      this.isSearching = false;
      const startDate = isValid(new Date(this.selectedDate)) ? new Date(this.selectedDate) : new Date();
      await this.reloadDataWithinRange(startDate);
    }
  }

  private _scrollToFirstActivity() {
    // console.warn("_scrollToFirstActivity");
    //this.calendar.eventMap -- check event on the specific date
    //3day -- the range can be determined by this.calendar._lastDay - 3
    //weekday -- the range can be determined by this.calendar._lastDay - 7
    if (!this.isActivityEventHandler) {
      if (this.currentViewType === "Day") {
        let targetDateToScroll: Date = new Date(this.selectedDate) || new Date();
        let filterItemsForScroll = _.cloneDeep(this.activities) || [];
  
        filterItemsForScroll = filterItemsForScroll.filter(rawData => {
          const checkDate = new Date(rawData.start as Date);
          return !rawData.allDay && isSameDay(checkDate, this.selectedDate);
        });

        const isSelectedToday: boolean = isToday(this.selectedDate);
  
        if(isSelectedToday) {
          setTimeout(() => {
            const now = new Date();
            const newDate = new Date(this.selectedDate);
            newDate.setHours(
              now.getHours(),
              now.getMinutes(),
              now.getSeconds(),
              now.getMilliseconds(),
            );
            this.selectedDate = newDate;
            this.masterSelectedDate = new Date(newDate);
          }, 100);
        } else if (!_.isEmpty(filterItemsForScroll)) {
          // Set the first activity scroll time.
          filterItemsForScroll = _.orderBy(filterItemsForScroll, [d=>d.startDate.getHours(), d=>d.startDate.getMinutes()], 'asc');
          targetDateToScroll = filterItemsForScroll[0].startDate;
          setTimeout(() => {
            this.selectedDate = new Date(targetDateToScroll);
            this.masterSelectedDate = new Date(targetDateToScroll);
          },100);
        } else {
          if (!this.isSearching) {
            const now = new Date();
            targetDateToScroll.setHours(8);
            setTimeout(() => {
              this.selectedDate = new Date(targetDateToScroll);
              this.masterSelectedDate = new Date(targetDateToScroll);
            },100);
          }
        }
      } else if (this.currentViewType === "3 Days" || this.currentViewType === "Week") {
        //ToDO: scroll handling
      }
    } else {
      // keep tracking the current updated activity
      this.isActivityEventHandler = false;
      if (isValid(this.selectedDate)) {
        const targetDateToScroll: Date = new Date(this.selectedDate);
        setTimeout(() => {
          this.selectedDate = new Date(targetDateToScroll);
          this.masterSelectedDate = new Date(targetDateToScroll);
          this.calendar.navigate(targetDateToScroll);
        },100);
      }
    }
  }

  /**
   * Returns the Appointment objects for the selected date range (For Calendar)
   */
  async getCalendarDataInDateRange(from: Date, to: Date): Promise<Appointment[]> {
    const appointments: Appointment[] = [];
    const fromTimestamp: number = from?.getTime();
    const toTimestamp: number = to?.getTime();
    const hasSuggestedMeetingsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_MEETINGS);
    const hasSuggestedWeComMessagesEnabled = this.authService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_WECOM_MESSAGES);
    const hasSuggestedAlertsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_TASKS);
    
    this.activityService.filterDisplayActivity();
    
    for (let i = 0; i < this.activityService.displayActivities.length; i++) {
      const activity: Activity = this.activityService.displayActivities[i];
      const start: number = activity?.scheduledStart?.getTime();
      const noEnd: boolean = !isValid(activity?.scheduledEnd);
      const end: number = activity?.scheduledEnd?.getTime();

      if (
        (start >= fromTimestamp && start <= toTimestamp)
        || (start < fromTimestamp && (noEnd || end > fromTimestamp))
      ) {
        let mappedObject;

        if (
          activity
          && (
            (
              activity.type === ActivityType.Appointment
              && (
                (
                  activity['indskr_suggest']
                  && !hasSuggestedMeetingsEnabled
                )
                || (activity as AppointmentActivity).indskr_parentcallid
              )
            )
            || (
              activity.type === ActivityType.Email
              && activity['indskr_recommendedmessage']
              && !hasSuggestedWeComMessagesEnabled
            )
            || (
              activity.type === ActivityType.FollowUp
              && activity['planType'] == FOLLOW_UP_TYPE.SUGGESTED
              && !hasSuggestedAlertsEnabled
            ) 
            || (
              activity.type === ActivityType.FollowUp
              && activity['planType'] == FOLLOW_UP_TYPE.SUGGESTED
              && activity.state == 2 && activity.status == 6
            )
          )
        ) {
        } else {
          mappedObject = this.formatActivityForCalendar(activity);
          if(mappedObject){
            appointments.push(mappedObject);
          }
        }
      }
    }

    return appointments;
  }

  formatActivityForCalendar(activity: Activity) {
    if (activity) {
      let endTime = activity.scheduledEnd;

      let mappedObject: Appointment = {
        id: activity.ID,
        text: this.getActivitysubject(activity),
        type: this.activityService.getActivityTypeForCalendarFilter(activity),
        location: activity.location && activity.location != 'No Location' ? activity.location : this.activityService.getNoLocationDefaultMessage(activity),
        priorityId: this.activityService.assignEmbeddedPriorityBasedOnScheduleDates(activity),
        start: activity.scheduledStart,
        startDate: activity.scheduledStart,
        end: endTime,
        endDate: activity.scheduledEnd,
        timeOffReason: activity['reason'] ? activity['reason'] : '',
        allDay: activity.allDayEvent && isFuture(new Date(activity.scheduledEnd)),
        state: activity.state,
        info: activity.info,
        tooltip: this.getActivitysubject(activity),
      };
      mappedObject.color = this.activityService.getActivityColorForPriority(mappedObject.priorityId);

      if (activity.type === ActivityType.Appointment) {
        mappedObject.text = _.isEqual(mappedObject.text, "Meeting") ? this.translate.instant("MEETING") : mappedObject.text;
        if (this.authService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT)) {
          mappedObject.text = _.isEqual(mappedObject.text, "Visit") ? this.translate.instant("VISIT") : mappedObject.text;
        }
        mappedObject.location = _.isEqual(mappedObject.location, "No Location") ? this.translate.instant("NO_LOCATION") : mappedObject.location;
        mappedObject.proximityWarn = activity?.closeByActivityIds?.size > 0;
        mappedObject.recommended = activity['indskr_suggest'];
        const startDateTimeValue: Date = new Date(activity.scheduledStart);
        const meetingStartTime = startDateTimeValue.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
        mappedObject.startTime = meetingStartTime;
        const endDateTimeValue: Date = new Date(activity.scheduledEnd);
        const meetingEndTime = endDateTimeValue.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
        mappedObject.endTime = meetingEndTime;
      }
      else if ( activity.type === ActivityType.StoreCheck) {
        const storeCheckActivity = activity as StoreCheckActivity;
        mappedObject.text = mappedObject.text;
        mappedObject.location = _.isEmpty(storeCheckActivity.categoryId) ? this.translate.instant('XPERIENCES_RELATIONSHIP_NO_CATEGORY') : storeCheckActivity.categoryName;
        const startDateTimeValue: Date = new Date(activity.scheduledStart);
        const meetingStartTime = startDateTimeValue.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
        mappedObject.startTime = meetingStartTime;
        const endDateTimeValue: Date = new Date(activity.scheduledEnd);
        const meetingEndTime = endDateTimeValue.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
        mappedObject.endTime = meetingEndTime;
      }
      let isActivityPartOfMultiSelectFilter:boolean = false;
      if(this.activityService.multiOptionActivityFilter && this.activityService.multiOptionActivityFilter.length > 0){
        isActivityPartOfMultiSelectFilter = this.activityService.multiOptionActivityFilter.some(a=>a.value == ActivityType.AllActivity) || this.activityService.multiOptionActivityFilter.some(a=> a.value == activity.type) || ((activity.type == ActivityType.TimeOffRequest && this.activityService.multiOptionActivityFilter.some(a=> a.value == 'TimeOff'))) || (activity instanceof AppointmentActivity && activity.isJointmeeting && this.activityService.multiOptionActivityFilter.some(a=> a.value == ActivityType.JointMeeting)) || (activity instanceof AppointmentActivity && activity.isRemoteDetailing && this.activityService.multiOptionActivityFilter.some(a=> a.value == ActivityType.RemoteMeeting)) || (activity instanceof AppointmentActivity && activity.location == 'LiveMeet' && this.activityService.multiOptionActivityFilter.some(a=> a.value == ActivityType.LiveMeet)) || (activity instanceof PhoneActivity && activity.jointphonecall && this.activityService.multiOptionActivityFilter.some(a=> a.value == ActivityType.JointPhoneCall))
      }
      if (this.activityService.activityFilter == ActivityType.AllActivity || activity.type === this.activityService.activityFilter || 
        (activity.type == ActivityType.TimeOffRequest && this.activityService.activityFilter == 'TimeOff') || isActivityPartOfMultiSelectFilter) {
        let subject = this.getActivitysubject(activity);

        if (activity.type === ActivityType.Sample) {
          mappedObject.text = subject;
          mappedObject.contactName = (activity as SampleActivity).contactName;
        }
        if (activity.type === ActivityType.TimeOffRequest) {
          mappedObject.text = _.isEqual(mappedObject.text, "Time Off") ? this.translate.instant("TIME_OFF") : mappedObject.text;
          mappedObject.allDay = (<TimeOffActivity>activity).totIsAlldayEvent;
          if (mappedObject.allDay) {
            mappedObject.startDate = new Date(new Date(activity.scheduledStart).setHours(0,0,0,0));
            mappedObject.endDate = new Date(new Date(activity.scheduledEnd).setHours(23,59,59,0));
            const startDateTimeValue: Date = mappedObject.startDate;
            const meetingStartTime = startDateTimeValue.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
            mappedObject.startTime = meetingStartTime;
            const endDateTimeValue: Date =mappedObject.endDate;
            const meetingEndTime = endDateTimeValue.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
            mappedObject.endTime = meetingEndTime;
          }
        }
        if (activity.type === ActivityType.TimeOff) {
          mappedObject.text = _.isEqual(mappedObject.text, "Time Off") ? this.translate.instant("TIME_OFF") : mappedObject.text;
          mappedObject.allDay = activity.allDayEvent != undefined ? activity.allDayEvent : (activity as TimeOffActivity).totIsAlldayEvent;
          if (mappedObject.allDay) {
            mappedObject.startDate = new Date(new Date(activity.scheduledStart).setHours(0,0,0,0));
            mappedObject.endDate = new Date(new Date(activity.scheduledEnd).setHours(23,59,59,0));
            const startDateTimeValue: Date = mappedObject.startDate;
            const meetingStartTime = startDateTimeValue.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
            mappedObject.startTime = meetingStartTime;
            const endDateTimeValue: Date =mappedObject.endDate;
            const meetingEndTime = endDateTimeValue.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
            mappedObject.endTime = meetingEndTime;
          }
        }
        if (activity.type === ActivityType.FollowUp) {
          mappedObject.text = _.isEqual(mappedObject.text, "Follow-up Action") ? this.translate.instant("FOLLOW_UP_ACTION") : mappedObject.text;
          //Untill we have a date only field for due date, update start and end time manually
          mappedObject.allDay = true;
          mappedObject.startDate = new Date(new Date(activity.scheduledStart).setHours(0,0,0,0));
          mappedObject.endDate = (isValid(activity.scheduledEnd))?new Date(new Date(activity.scheduledEnd).setHours(23,59,59,0)):new Date(new Date(activity.scheduledStart).setHours(23,59,59,0));
          const startDateTimeValue: Date = mappedObject.startDate;
          const meetingStartTime = startDateTimeValue.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
          mappedObject.startTime = meetingStartTime;
          const endDateTimeValue: Date =mappedObject.endDate;
          const meetingEndTime = endDateTimeValue.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
          mappedObject.endTime = meetingEndTime;
        }
        if(activity.type === ActivityType.PhoneCall){
          mappedObject.text = subject;
          mappedObject.selectedMobileNumber = (activity as PhoneActivity).selectedMobileNumber;;
        }
        if (activity.type === ActivityType.CaseIntake) {
          if(activity instanceof CaseActivity) {
            let contact = activity._case_contact;
            let contactName = contact ? contact.fullName : (activity._case_trans_customer_name && activity._case_trans_customer_name != Utility.globalCustomerText) ? activity._case_trans_customer_name : this.translate.instant('NO_GLOBALECUSTOMER', { globalCustomerText: this.utilityService.globalCustomerText });
            let status = this.activityService.getCaseState(activity);
            mappedObject.text = subject;
            mappedObject.info = status +"   " +contactName;
          }
        }
        if (activity.type === ActivityType.Order) {
          mappedObject.text = subject;
          mappedObject.location = ((activity as OrderActivity).accountId) ? (activity as OrderActivity).accountNameString : this.translate.instant('NO_ACCOUNT');
        }
        if (activity.type === ActivityType.SurgeryOrder) {
          mappedObject.text = activity.subject;
          mappedObject.account = this.activityService.getSecondaryInfoProcedureLog(activity);
        }
        if (activity.type === ActivityType.ProcedureTracker) {
          mappedObject.text = activity.subject;
          mappedObject.account = this.activityService.getSecondaryInfoProcedureTracker(activity);
        }
        if (activity.type === ActivityType.Email) {
          mappedObject.text = subject
          if (activity instanceof EmailActivity) {
            let filteredStartDateTime: Date = activity.scheduledStart;
            if ((activity.emailStatus == EmailStatusCodes.Sent || activity.emailStatus == EmailStatusCodes.Failed || activity.emailStatus == EmailStatusCodes.Shared)
              && activity.emailActivityParties && activity.emailActivityParties.length) {
              let sentDateList: number[] = [];
              if(activity.channelType == ChannelType.SMS || activity.channelType == ChannelType.WHATSAPP || activity.channelActivityType == ChannelActivityType.SMS || activity.channelActivityType == ChannelActivityType.WHATSAPP
                || activity.channelType.toLowerCase().includes('sms') || activity.channelType.toLowerCase().includes('whatsapp')) {
                  activity.emailActivityParties.forEach(epa => {
                  if (epa.email_senton) {
                    sentDateList.push(new Date(parseFloat(epa.email_senton.toString())).getTime());
                  }
                });
              } else {
                activity.emailActivityParties.forEach(epa => {
                  if (epa.emailAddresses) {
                    sentDateList.push(...epa.emailAddresses.map(ea => ea.email_senton && new Date(parseFloat(ea.email_senton.toString())).getTime()));
                  }
                });
              }
              if (sentDateList.length > 0) {
                const minSentDate = Math.min(...sentDateList);
                if (minSentDate) filteredStartDateTime = new Date(minSentDate);
              }
            }
            mappedObject.startDate = new Date(new Date(filteredStartDateTime));
            mappedObject.endDate = addMinutes(new Date(filteredStartDateTime), 30);
            mappedObject.start = new Date(mappedObject.startDate);
            mappedObject.end = new Date(mappedObject.endDate);
            mappedObject.recommended = activity['indskr_recommendedmessage'];
            if (activity['indskr_recommendedmessage'] && activity.channelType === ChannelType.WECOM && activity.emailStatus == EmailStatusCodes.Draft) {
              mappedObject.allDay = true;
            } 
            const startDateTimeValue: Date = mappedObject.startDate;
            const meetingStartTime = startDateTimeValue.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
            mappedObject.startTime = meetingStartTime;
            const endDateTimeValue: Date =mappedObject.endDate;
            const meetingEndTime = endDateTimeValue.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
            mappedObject.endTime = meetingEndTime;
          }
        }
        if (activity.type === ActivityType.SetBooking) {
          mappedObject.text = subject;
          mappedObject.location = this.secInfoConfigDataService.isKitBookingSecInfoConfigured
              ? this.secInfoConfigDataService.getSecondaryInfoFormatedTextForKitBooking(activity as SetBookingActivity, SecondaryInfoEntityName.Kitbooking)
              : activity.statusString;
          mappedObject.tooltip = this.secInfoConfigDataService.isKitBookingSecInfoConfigured
          ? this.secInfoConfigDataService.getSecondaryInfoFormatedTextForKitBooking(activity as SetBookingActivity, SecondaryInfoEntityName.Kitbooking)
          : mappedObject.text;
        }
        if(activity.type !== ActivityType.SetBooking)
          mappedObject.tooltip = mappedObject.text;
        return mappedObject;
      }
      //Condition for Joint Meeting/ Remote Meeting
      if (activity.type === ActivityType.Appointment && (this.activityService.activityFilter === ActivityType.JointMeeting || this.activityService.activityFilter === ActivityType.RemoteMeeting)) {
        mappedObject.tooltip = mappedObject.text;
        return mappedObject;
      } else if (activity.type === ActivityType.PhoneCall && this.activityService.activityFilter === ActivityType.JointPhoneCall) {
        //Condition for Joint PhoneCalls
        mappedObject.text = activity.subject;
        mappedObject.tooltip = mappedObject.text;
        mappedObject.selectedMobileNumber = (activity as PhoneActivity).selectedMobileNumber;
        return mappedObject;
      }
    }

    return undefined;
  }

  getActivitysubject(activity: Activity) {

    let subject = ''
    switch (activity.type) {
      case ActivityType.Appointment:
        subject = ((activity && activity.subject) ? (activity.subject == 'Meeting' ? this.translate.instant('MEETING') : this.authService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT) && activity.subject == 'Visit' ? this.translate.instant('VISIT') :activity.subject) + '' : '')
        break;
      case ActivityType.StoreCheck:
        subject = activity?.subject == 'Store Check' ? this.translate.instant('STORE_CHECK') : activity?.subject;
        break;
      case ActivityType.Sample:
        if (activity instanceof SampleActivity && activity.subject) {
          subject =  activity.subject.includes('Order') ? activity.subject.replace('Order', this.translate.instant('ALLOWCATION_ORDER')): activity.subject
        }
        break;
      case ActivityType.TimeOffRequest:
        //OMNI-6073
        let timeoffRequestActivity = <TimeOffActivity>activity;

        subject = (timeoffRequestActivity && timeoffRequestActivity.name) ? (timeoffRequestActivity.name == 'Time Off' ? this.translate.instant('TIME_OFF') : timeoffRequestActivity.name) : this.translate.instant('TIME_OFF');

        break;
      case ActivityType.TimeOff:
        let timeoffActivity = <TimeOffActivity>activity;
        subject = (timeoffActivity && timeoffActivity.name) ? (timeoffActivity.name == 'Time Off' ? this.translate.instant('TIME_OFF') : timeoffActivity.name) : this.translate.instant('TIME_OFF');
        break;
      case ActivityType.Email:
        subject = this.setPrimaryTextRightForMessages(activity as EmailActivity);
        break;
      case ActivityType.FollowUp:
        subject = (activity && activity.subject) ? (activity.subject === 'Follow-up Action' ? this.translate.instant('FOLLOW_UP_ACTION') : activity.subject) : this.translate.instant('FOLLOW_UP_ACTION')
        break;
      case ActivityType.Order:
        subject =(activity && activity.subject) ? (activity.subject.includes(this.translate.instant('NEW_ACTIVITY_SALES_ORDER')) ?  activity.subject : this.translate.instant('NEW_ACTIVITY_SALES_ORDER') + ' ' + activity.subject) : this.translate.instant('NEW_ACTIVITY_SALES_ORDER');
        break;
      case ActivityType.CaseIntake:
        if(activity instanceof CaseActivity) {
           subject = activity.caseName;
          let contact = activity._case_contact;
          let contactName = contact ? contact.fullName : (activity._case_trans_customer_name && activity._case_trans_customer_name != Utility.globalCustomerText) ? activity._case_trans_customer_name : `${this.translate.instant('NO')} ${this.utilityService.globalCustomerText}`;
          let status = activity.state === 1 ? 'Resolve' : activity.state === 2 ? 'Cancelled' : activity._case_stage_value ? activity._case_stage_value : 'Open';
        }
        break;
      case ActivityType.SurgeryOrder:
        subject = activity.subject
        break;
      case ActivityType.PhoneCall:
        let phonecallSubject = activity.subject;
        subject = (activity && activity.subject) && (activity.subject == 'Phone Call' || activity.subject.includes(' - Phone Call') ? phonecallSubject.replace('Phone Call', this.translate.instant('NEW_ACTIVITY_PHONECALL')) : phonecallSubject);
        break;
      case ActivityType.SetBooking:
        subject = activity.subject;
        break;
      default:
        subject = '';
        break;
    }
    return subject;
  }

  public setPrimaryTextRightForMessages(activity: EmailActivity): string {
    let channelNameText: string = this.setSecondaryTextForMessages(activity) || '';
    if (_.isEmpty(activity?.emailActivityParties)) {
      return channelNameText;
    } else {
      let fullName;
      if (activity.emailActivityParties && activity.emailActivityParties.length == 1) {
        if (activity.emailActivityParties[0]?.contact_firstname) {
          fullName = this.getContactName(activity.emailActivityParties[0]);
          return `${fullName} - ${channelNameText}`;
        } else return channelNameText + '';
      } else if (activity.emailActivityParties && activity.emailActivityParties.length >= 2) {
        let cntName = 0;
        let cntNameStr = '';
        if (activity.emailActivityParties[0]?.contact_firstname) {
          fullName = this.getContactName(activity.emailActivityParties[0]);;
        }
        for (let emailActivityParty in activity.emailActivityParties) {
          if (activity.emailActivityParties[emailActivityParty]?.contact_firstname) {
            cntName = cntName + 1;
          }
        }
        cntNameStr = cntName == 0 ? '' : String(cntName = cntName - 1);
        return `${fullName} + ${cntNameStr} - ${channelNameText}`;
      } else return channelNameText + '';
    }
  }

  private getContactName(emailParty: EmailActivityParty) {
    return `${emailParty.contact_firstname} ${_.isEmpty(emailParty.contact_lastname) ? '' : emailParty.contact_lastname}`;
  }

  public setSecondaryTextForMessages(activity: EmailActivity): string {
    const channel: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === activity.indskr_channelid);
    let channelTypeText;
    if (channel != undefined) {
      channelTypeText = String(channel?.indskr_consentType);
    } else if(activity.channelName){
      channelTypeText = activity.channelName;
    }
     else {
      channelTypeText = activity.channelType;
    }
    return channelTypeText ? ((channelTypeText === 'Email') ? this.translate.instant('EMAIL') : channelTypeText) : channelTypeText;
  }
}
