import { filter, debounceTime } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { SurgeryOrderMeasureData } from './../../../interfaces/edge-analytics/surgery-order-report.interface';
import { ReportDataManagementService } from './../../services/reports/report-data-management.service';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { DynamicsClientService } from './../dynamics-client/dynamics-client.service';
import { Injectable, NgZone } from "@angular/core";
import { DB_ALLDOCS_QUERY_OPTIONS, DB_KEY_PREFIXES, PREFIX_SEARCH_ENDKEY_UNICODE, DB_SYNC_STATE_KEYS } from "../../config/pouch-db.config";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from "../../services/disk/disk.service";
import { ActivityService } from "../../services/activity/activity.service";
import { Endpoints } from "../../../config/endpoints.config";
import { AuthenticationService } from "../../services/authentication.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventsService } from "../../services/events/events.service";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { ActivityCancellationReson, ActivityOutcome, ActivityType } from '../../classes/activity/activity.class';
import { UIService } from '../../services/ui/ui.service';
import { TrackAction } from '../../utility/common-enums';
import { TranslateService } from '@ngx-translate/core';
import { PRODUCT_CATEGORY_SKU, ProcedureSubType, ProcedureType, ShoreiMaster, SurgeryCategoryProduct, SurgeryNewProductHierarchy, SurgeryOrderActivity, SurgeryProductHierarchy } from '@omni/classes/activity/surgery-order.activity.class';
import { EntitySyncInfo, EntityNames, DeltaService } from '../delta/delta.service';
import { OperationDetail } from '../follow-up-activity/follow-up-activity.data.service';
import { OrderActivity } from '@omni/classes/activity/order.activity.class';
import _ from 'lodash';
import { differenceInHours, isBefore, isAfter, differenceInCalendarDays, isPast } from 'date-fns';
import { MeasureType } from '../../enums/edge-analytics/edge-analytics.enum';
import { procedureUpdateMeasureData, procedureGenerateMeasureData } from '../../services/reports/functions/surgery-order/surgery-order-report.functions';
import { fetchXmlEntity } from '../../config/dynamics-fetchQueries';
import { format } from "date-fns";
import { MyAssistantService, NOTIFICATION } from '@omni/services/my-assistant/my-assistant.service';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { Guid } from 'typescript-guid';
import { DeviceService } from '@omni/services/device/device.service';
import { NotificationService } from '@omni/services/notification/notification.service';
import { IoFileService } from '@omni/services/io-file-service/io-file-service';
import { ProcedureContractService } from '../procedure-contract/procedure-contract.service';
import { AlertWithInput } from '@omni/components/shared/alert-with-input/alert-with-input';
import { PopoverController } from '@ionic/angular';
import { AlertService } from '@omni/services/alert/alert.service';
import { PROCEDURE_CONTRACT_STATUS, PROCEDURE_CONTRACT_TYPES } from '@omni/classes/procedure-contract/procedure-contract.class';
import { PageName } from '@omni/services/navigation/navigation.service';
import { CovisitorAccess } from '@omni/classes/activity/appointment.activity.class';
import { LocalizationService } from '@omni/services/localization/localization.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
/**
 * Offline/Online data operations service for Order Activity
 *
 *
 */


@Injectable({
  providedIn: 'root'
})
export class SurgeryOrderActivityDataService {

  private _isInitialMappingDone: boolean = false;
  public isSelectedSurgeryOrderActivityUpdated: boolean = false;
  public productHierarchies: SurgeryProductHierarchy[] = [];
  public procedureTrackerProductHierarchies: SurgeryProductHierarchy[] = [];
  public inMeetingSurgeryOrderActivity: SurgeryOrderActivity;
  private _surgeryOrderSyncCompleted$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _procedureLogDataReadyToBeLoadedSub: Subscription;
  public isInMeetingProcedureLogFlow: boolean = false;
  public validateProductHierachyForRetiredProducts = true;
  public activityOutcomes: Array<ActivityOutcome> = [];
  public procedureTypes: any[] = [];
  public procedureSubTypes: any[] = [];
  public shoreiMasterData: any[] =[];

  private _productHierarchyInitialSyncServiceWorker;
  private _checkedForProcedureProductMapping: boolean = false;
  private _isProcedureProductHierarchyDataMappingInProgress: boolean = false;

  constructor(
    private disk: DiskService,
    private activityOfflineService: ActivityService,
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private events: EventsService,
    public dynamics: DynamicsClientService,
    private uiService: UIService,
    private translate: TranslateService,
    private deltaService: DeltaService,
    private reportDataMgmService: ReportDataManagementService,
    private readonly myAssistantService: MyAssistantService,
    private accountService: AccountOfflineService,
    private dateService: DateTimeFormatsService,
    private deviceService: DeviceService,
    private dateTimeFormatsService: DateTimeFormatsService,
    private ngZone: NgZone,
    private readonly notificationService: NotificationService,
    private fileservice: IoFileService,
    private contractService: ProcedureContractService,
    private readonly popoverCtrl: PopoverController,
    private alertService: AlertService,
    public languageService: LocalizationService,
    public contactService: ContactOfflineService,
  ) {
    combineLatest([this._surgeryOrderSyncCompleted$.asObservable(), this.reportDataMgmService.configurationsLoaded$])
      .pipe(
        debounceTime(0),
        filter(([syncCompleted, configsLoaded]) => syncCompleted && configsLoaded),
      ).subscribe(async ([syncCompleted, configsLoaded]) => {
        const isEnabled = this.authenticationService.user.hasProcedureLog;
        if (isEnabled) {
          this.reportDataMgmService.setProcedureLogDataReadyToBeLoaded(true);
          this.reportDataMgmService.deRegisterSyncTask(MeasureType.procedure);

          const isMeasureInitDone = this.reportDataMgmService.hasMeasure(MeasureType.procedure);
          if (isMeasureInitDone) {
            // Data needs to be refreshed
            this.reportDataMgmService.requestLocalDataRefresh(MeasureType.procedure);
          }
        }
      });
    this.reportDataMgmService.reportDataRefreshRequest$.subscribe(async (measureType: MeasureType) => {
      const isEnabled = this.authenticationService.user.hasProcedureLog;
      if (!isEnabled) {
        if (this.reportDataMgmService.hasMeasure(MeasureType.procedure)) {
          this.reportDataMgmService.removeMeasure(MeasureType.procedure);
        }
      }

      if (measureType === MeasureType.procedure && isEnabled) {
        const isMeasureInitDone = this.reportDataMgmService.hasMeasure(MeasureType.procedure);
        const isDataReadyToBeLoaded = this.reportDataMgmService.getProcedureLogDataReadyToBeLoaded() || !isMeasureInitDone;
        if (isDataReadyToBeLoaded) {
          await this.setupSurgeryOrderMeasureData();
        } else {
          if (!this._procedureLogDataReadyToBeLoadedSub) {
            this._procedureLogDataReadyToBeLoadedSub = this.reportDataMgmService.procedureLogDataReadyToBeLoaded$
              .subscribe(async isReady => {
                if (isReady) {
                  await this.setupSurgeryOrderMeasureData();
                  this._procedureLogDataReadyToBeLoadedSub.unsubscribe();
                  this._procedureLogDataReadyToBeLoadedSub = undefined;
                }
              });
          }
        }
      }
    });

    this._initProductHierarchyInitialSyncServiceWorker();
  }

  public setSurgeryOrderSyncCompletedSubjectValue(value: boolean) {
    this._surgeryOrderSyncCompleted$.next(value);
  }

  public get isProcedureProductHierarchyDataMappingInProgress(): boolean {
    this._checkedForProcedureProductMapping = true;
    return this._isProcedureProductHierarchyDataMappingInProgress;
  }

  private async createOrderActivityOnline(payload: object): Promise<any> {
    const { timeZone } = Intl?.DateTimeFormat()?.resolvedOptions();
        const httpOptions = {
          headers: new HttpHeaders({ 'X-Zone-Id': timeZone })
        }
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.orderManagement.CREATE_UPDATE_ORDER;
    // Create new order activity on dynamics and return promise with success or failure
    return this.http.post(url, payload, httpOptions).toPromise();
  }

  private UpdateOrderActivityOnline(payload: object): Promise<any> {
    const { timeZone } = Intl?.DateTimeFormat()?.resolvedOptions();
        const httpOptions = {
          headers: new HttpHeaders({ 'X-Zone-Id': timeZone })
        }
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.orderManagement.UPDATE_ORDER;
    return this.http.patch(url, payload, httpOptions).toPromise();
  }

  private scrapOrderActivityOnline(order: SurgeryOrderActivity): Promise<any> {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.orderManagement.DELETE_ORDER_ACTIVITY;
    url = url.replace('{{salesorderid}}', order.ID)
    return this.http.delete(url).toPromise();
  }

  public loadOfflineSurgeryOrderActivities(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let option = {
        selector: {
          '_id': {
            $gte: DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY,
            $lte: DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
          },
        }
      };
      option.selector['pendingPushToDynamics'] = {
        $eq: true
      };
      try {
        // Fetch from DB
        const offlineSurgeryOrders: any[] = await this.disk.find(option);
        if (offlineSurgeryOrders && Array.isArray(offlineSurgeryOrders) && offlineSurgeryOrders.length > 0) {
          let raworderspayload = [];
          raworderspayload = await this.setOfflinePayload(offlineSurgeryOrders);
          // Track Offline data count
          this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SURGERY_ORDER, offlineSurgeryOrders.length);
          resolve(raworderspayload);
        } else {
          resolve([]);
        }
      } catch (error) {
        reject('Error occured while fetching order activities data from offline db');
      }
    });
  }

  private async setOfflinePayload(offlineSurgeryOrders) {
    try {
      let raworderspayload = [];
      for (let i = 0; i < offlineSurgeryOrders.length; i++) {
        let newOfflineOrder = new SurgeryOrderActivity(offlineSurgeryOrders[i]);
        if (newOfflineOrder.assets.length > 0) {
          await this.removeAssetInUse(newOfflineOrder);
        }
        raworderspayload.push(newOfflineOrder.serviceDTO);
      }
      return raworderspayload;
    } catch (error) {
      console.log(error);
    }
  }

  public getProcedureLogDataStartDate(userOfflineDataStartFrom: string): string {
    let procedureLogDataStartDate = userOfflineDataStartFrom;
    try {
      const userOfflineDataDurationStartDate: Date = new Date(parseInt(userOfflineDataStartFrom));
      const now: Date = new Date();
      const currentYear = now.getFullYear();
      const startDateOfThisYear = new Date(currentYear, 0, 1);

      if (!isBefore(userOfflineDataDurationStartDate, startDateOfThisYear)) {
        procedureLogDataStartDate = '' + startDateOfThisYear.getTime();
      }
    } catch (error) {
      console.log('getProcedureLogDataStartDate: ', error);
    }

    return procedureLogDataStartDate;
  }

  // public async syncSurgeryOrderActivities(dataRange: { from: string, to: string }, forceFullSync: boolean = false, loadFromDBOnly: boolean = false): Promise<any> {
  //   let isSurgeryOrderEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG);
  //   // Flag to check whether procedure log (surgery order) data start date is different from user's
  //   // offline data duration config.
  //   let hasSurgeryOrderDataPastOfflineDataDuration = false;
  //   const userOfflineDataDurationStartDate: Date = new Date(parseInt(dataRange.from));

  //   if (!isSurgeryOrderEnabled) {
  //     if (this.reportDataMgmService.hasMeasure(MeasureType.procedure)) {
  //       this.reportDataMgmService.removeMeasure(MeasureType.procedure);
  //     }
  //   }
  //   if (!isSurgeryOrderEnabled && !this.authenticationService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT)) return;
  //   if (loadFromDBOnly) {
  //     await this.orderActivityDataService.loadOrderActivitiesFromDb(dataRange);
  //     // Sync state management for edge analytics report
  //     if (isSurgeryOrderEnabled) {
  //       this.reportDataMgmService.checkFeatureActionAndRegisterSyncTask(MeasureType.procedure);
  //       await this.loadSurgeryOrderActivitiesFromDb(dataRange);
  //       this.reportDataMgmService.setProcedureLogDataReadyToBeLoaded(true);
  //     }

  //     this._isInitialMappingDone = true;
  //   } else {
  //     let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ORDER_ACTIVITIES);
  //     const isInitialSync = !syncState || !syncState.lastUpdatedTime;
  //     const doFullSync = forceFullSync || isInitialSync;
  //     const lastUpdatedTime = isInitialSync ? null : syncState.lastUpdatedTime;
  //     const orderActivitySyncInfo: EntitySyncInfo = {
  //       entityName: EntityNames.order,
  //       totalFailed: 0,
  //       totalSynced: 0,
  //       errors: [],
  //       syncStatus: true
  //     };
  //     const positions = this.authenticationService.user.positions.map((o) => {
  //       return o.ID
  //     })
  //     let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.orderManagement.GET_ALL_ORDERS
  //       .replace('{{startDate}}', dataRange.from)
  //       .replace('{{endDate}}', dataRange.to)
  //       .replace('{{positionIds}}', positions.toString())
  //       .replace('&lastUpdatedTime={{lastUpdatedTime}}', !doFullSync ? '&lastUpdatedTime='+lastUpdatedTime : '');

  //     if (isSurgeryOrderEnabled) {
  //       const procedureLogStartDate = this.getProcedureLogDataStartDate(dataRange.from);
  //       if (procedureLogStartDate && dataRange.from !== procedureLogStartDate) {
  //         url = url + '&procedureLogStartDate=' + procedureLogStartDate;
  //         hasSurgeryOrderDataPastOfflineDataDuration = true;
  //       }
  //     }

  //     let headers = new HttpHeaders();
  //     headers = headers.set('Sync-Service', 'true');
  //     headers = headers.set('X-SystemUserId', this.authenticationService.user.xSystemUserID)
  //     try {
  //       let response;
  //       // Sync state management for edge analytics report
  //       if (isSurgeryOrderEnabled) {
  //         this.reportDataMgmService.checkFeatureActionAndRegisterSyncTask(MeasureType.procedure);
  //         this._surgeryOrderSyncCompleted$.next(false);
  //         this.reportDataMgmService.setProcedureLogDataReadyToBeLoaded(false);
  //       }

  //       response = await this.http.get(url, { headers }).toPromise();
  //       if(response){
  //         const newLastUpdatedTime = new Date().getTime();
  //         if(!doFullSync){
  //           if(this._isInitialMappingDone == false){
  //             await this.orderActivityDataService.loadOrderActivitiesFromDb(dataRange);
  //             await this.loadSurgeryOrderActivitiesFromDb(dataRange);
  //             this._isInitialMappingDone = true;
  //           }
  //         }
  //         if(this.authenticationService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT)){
  //           if(doFullSync){
  //             let orders: Array<OrderActivity> = [];
  //             if (response['myOrders'] && Array.isArray(response['myOrders']) && response['myOrders'].length > 0) {
  //               orderActivitySyncInfo.totalSynced += response['myOrders'].length;
  //               response['myOrders'].map(rawOrder => {
  //                 let orderObj = new OrderActivity(rawOrder);
  //                 orderObj.type = ActivityType.Order;
  //                 //if(!(orderObj.state == 2 && orderObj.status == 4)){// Don't add scrapped order activities
  //                 if (orderObj.offlineDBId && orderObj.ownerId) {
  //                   orders.push(orderObj);
  //                 }
  //                 //}
  //               });
  //             }
  //             if (response['teamOrders'] && Array.isArray(response['teamOrders']) && response['teamOrders'].length > 0) {
  //               orderActivitySyncInfo.totalSynced += response['teamOrders'].length;
  //               response['teamOrders'].map(rawOrder => {
  //                 let orderObj = new OrderActivity(rawOrder);
  //                 orderObj.type = ActivityType.Order;
  //                 if (orderObj.ownerId != this.authenticationService.user.systemUserID) {
  //                   orderObj.isTeamOrder = true;
  //                 }
  //                 //if(!(orderObj.state == 2 && orderObj.status == 4)){// Don't add scrapped order activities
  //                 if (orderObj.offlineDBId && orderObj.ownerId) {
  //                   orders.push(orderObj);
  //                 }
  //                 //}
  //               });
  //             }
  //             if (orders.length >= 1) {
  //               let action: OperationDetail = {
  //                 onDynamics: false,
  //                 onLocalDatabase: true,
  //                 onLocalCopy: true,
  //               };
  //               await this.orderActivityDataService.createOrderActivity(action, orders, newLastUpdatedTime, true);
  //             }
  //           }else{
  //             if (!this._isInitialMappingDone) {
  //               await this.orderActivityDataService.loadOrderActivitiesFromDb(dataRange);
  //             }
  //             if (response['deletedOrders'] && Array.isArray(response['deletedOrders']) && response['deletedOrders'].length > 0) {
  //               response['deletedOrders'].map(rawOrder => {
  //                 if (rawOrder['salesorderid'] && rawOrder['track_action'] == TrackAction.Deleted) {
  //                   this.orderActivityDataService.handleDeletedTrackActionForOrderActivity(rawOrder);
  //                 }
  //               });
  //             }
  //             if (response['myOrders'] && Array.isArray(response['myOrders']) && response['myOrders'].length > 0) {
  //               let orders: Array<OrderActivity> = [];
  //               response['myOrders'].map(rawOrder => {
  //                 let orderObj = new OrderActivity(rawOrder);
  //                 orderObj.type = ActivityType.Order;
  //                 if (rawOrder['track_action'] && rawOrder['track_action'] == TrackAction.Deleted) {
  //                   this.orderActivityDataService.handleDeletedTrackActionForOrderActivity(rawOrder);
  //                 } else {
  //                   //if(!(orderObj.state == 2 && orderObj.status == 4)){// Don't add scrapped order activities
  //                   if (orderObj.offlineDBId && orderObj.ownerId) {
  //                     orders.push(orderObj);
  //                   }
  //                   //}
  //                 }
  //               });
  //               let action: OperationDetail = {
  //                 onDynamics: false,
  //                 onLocalDatabase: true,
  //                 onLocalCopy: true,
  //                 operationDetail: {
  //                   code: 'OIADEL101',
  //                   message: this.translate.instant('DELTA_SYNC_ORDERS_UPDATE')
  //                 }
  //               };
  //               if (orders.length > 0) {
  //                 await this.orderActivityDataService.updateOrderActivity(action, orders, newLastUpdatedTime);
  //               }
  //             }
  //             if (response['teamOrders'] && Array.isArray(response['teamOrders']) && response['teamOrders'].length > 0) {
  //               let teamOrders: Array<OrderActivity> = [];
  //               response['teamOrders'].map(rawOrder => {
  //                 let orderObj = new OrderActivity(rawOrder);
  //                 orderObj.type = ActivityType.Order;
  //                 if (orderObj.ownerId != this.authenticationService.user.systemUserID) {
  //                   orderObj.isTeamOrder = true;
  //                 }
  //                 if (rawOrder['track_action'] && rawOrder['track_action'] == TrackAction.Deleted) {
  //                   if (rawOrder['ownerid'] != this.authenticationService.user.systemUserID) {
  //                     this.orderActivityDataService.handleDeletedTrackActionForOrderActivity(rawOrder);
  //                   }
  //                 } else {
  //                   if (!(orderObj.state == 2 && orderObj.status == 4)) {// Don't add scrapped order activities
  //                     if (orderObj.offlineDBId && orderObj.ownerId) {
  //                       teamOrders.push(orderObj);
  //                     }
  //                   }
  //                 }
  //               });
  //               let action: OperationDetail = {
  //                 onDynamics: false,
  //                 onLocalDatabase: true,
  //                 onLocalCopy: true,
  //                 operationDetail: {
  //                   code: 'OIADEL101',
  //                   message: this.translate.instant('DELTA_SYNC_ORDERS_UPDATE')
  //                 }
  //               };
  //               if (teamOrders.length > 0) {
  //                 await this.orderActivityDataService.updateOrderActivity(action, teamOrders, newLastUpdatedTime);
  //               }
  //             }
  //           }
  //         }

  //         if (isSurgeryOrderEnabled) {
  //           if (doFullSync) {
  //             let orders: Array<SurgeryOrderActivity> = [];
  //             let pastOrdersForEdgeAnalytics: SurgeryOrderActivity[] = [];

  //             if(response['mySurgeryOrders'] && Array.isArray(response['mySurgeryOrders']) && response['mySurgeryOrders'].length > 0){
  //               orderActivitySyncInfo.totalSynced += response['mySurgeryOrders'].length;
  //               response['mySurgeryOrders'].map(rawOrder => {
  //                 let orderObj = new SurgeryOrderActivity(rawOrder);
  //                 orderObj.type = ActivityType.SurgeryOrder;
  //                 //if(!(orderObj.state == 2 && orderObj.status == 4)){// Don't add scrapped order activities
  //                 if (orderObj.offlineDBId && orderObj.ownerId) {
  //                   if (!hasSurgeryOrderDataPastOfflineDataDuration) {
  //                     orders.push(orderObj);
  //                   } else {
  //                     if (isAfter(orderObj.createdDate, userOfflineDataDurationStartDate)) {
  //                       orders.push(orderObj);
  //                     } else {
  //                       pastOrdersForEdgeAnalytics.push(orderObj);
  //                     }
  //                   }
  //                 }
  //                 //}
  //               });
  //             }
  //             if (response['teamSurgeryOrders'] && Array.isArray(response['teamSurgeryOrders']) && response['teamSurgeryOrders'].length > 0) {
  //               orderActivitySyncInfo.totalSynced += response['teamSurgeryOrders'].length;
  //               response['teamSurgeryOrders'].map(rawOrder => {
  //                 let orderObj = new SurgeryOrderActivity(rawOrder);
  //                 orderObj.type = ActivityType.SurgeryOrder;
  //                 if (orderObj.ownerId != this.authenticationService.user.systemUserID) {
  //                   orderObj.isTeamOrder = true;
  //                 }
  //                 //if(!(orderObj.state == 2 && orderObj.status == 4)){// Don't add scrapped order activities
  //                 if (orderObj.offlineDBId && orderObj.ownerId) {
  //                   if (!hasSurgeryOrderDataPastOfflineDataDuration) {
  //                     orders.push(orderObj);
  //                   } else {
  //                     if (isAfter(orderObj.createdDate, userOfflineDataDurationStartDate)) {
  //                       orders.push(orderObj);
  //                     } else {
  //                       pastOrdersForEdgeAnalytics.push(orderObj);
  //                     }
  //                   }
  //                 }
  //                 //}
  //               });
  //             }
  //             if (orders.length >= 1) {
  //               let action: OperationDetail = {
  //                 onDynamics: false,
  //                 onLocalDatabase: true,
  //                 onLocalCopy: true,
  //               };
  //               await this.createOrderActivity(action, orders, newLastUpdatedTime, true);
  //             }
  //             if (pastOrdersForEdgeAnalytics.length > 0) {
  //               await this.createOrderActivity({
  //                 onDynamics: false,
  //                 onLocalDatabase: true,
  //                 onLocalCopy: false
  //               }, pastOrdersForEdgeAnalytics, newLastUpdatedTime, orders.length === 0);
  //             }
  //             this._isInitialMappingDone = true;
  //           } else {
  //             let orders: Array<SurgeryOrderActivity> = [];
  //             let pastOrdersForEdgeAnalytics: SurgeryOrderActivity[] = [];
  //             if (response['deletedSurgeryOrder'] && Array.isArray(response['deletedSurgeryOrder']) && response['deletedSurgeryOrder'].length > 0) {
  //               response['deletedSurgeryOrder'].map(rawOrder => {
  //                 if (rawOrder['salesorderid'] && rawOrder['track_action'] == TrackAction.Deleted) {
  //                   this.handleDeletedTrackActionForSurgeryOrderActivity(rawOrder);
  //                 }
  //               });
  //             }
  //             response['mySurgeryOrders'].map(rawOrder => {
  //               let orderObj = new SurgeryOrderActivity(rawOrder);
  //               orderObj.type = ActivityType.SurgeryOrder;
  //               if (rawOrder['track_action'] && rawOrder['track_action'] == TrackAction.Deleted) {
  //                 this.handleDeletedTrackActionForSurgeryOrderActivity(rawOrder);
  //               } else {
  //                 //if(!(orderObj.state == 2 && orderObj.status == 4)){// Don't add scrapped order activities
  //                 if (orderObj.offlineDBId && orderObj.ownerId) {
  //                   if (!hasSurgeryOrderDataPastOfflineDataDuration) {
  //                     orders.push(orderObj);
  //                   } else {
  //                     if (isAfter(orderObj.createdDate, userOfflineDataDurationStartDate)) {
  //                       orders.push(orderObj);
  //                     } else {
  //                       pastOrdersForEdgeAnalytics.push(orderObj);
  //                     }
  //                   }
  //                 }
  //                 //}
  //               }
  //             });
  //             response['teamSurgeryOrders'].map(rawOrder => {
  //               let orderObj = new SurgeryOrderActivity(rawOrder);
  //               orderObj.type = ActivityType.SurgeryOrder;
  //               if (rawOrder['track_action'] && rawOrder['track_action'] == TrackAction.Deleted) {
  //                 this.handleDeletedTrackActionForSurgeryOrderActivity(rawOrder);
  //               } else {
  //                 //if(!(orderObj.state == 2 && orderObj.status == 4)){// Don't add scrapped order activities
  //                 if (orderObj.ownerId != this.authenticationService.user.systemUserID) {
  //                   orderObj.isTeamOrder = true;
  //                 }
  //                 if (orderObj.offlineDBId && orderObj.ownerId) {
  //                   if (!hasSurgeryOrderDataPastOfflineDataDuration) {
  //                     orders.push(orderObj);
  //                   } else {
  //                     if (isAfter(orderObj.createdDate, userOfflineDataDurationStartDate)) {
  //                       orders.push(orderObj);
  //                     } else {
  //                       pastOrdersForEdgeAnalytics.push(orderObj);
  //                     }
  //                   }
  //                 }
  //                 //}
  //               }
  //             });
  //             let action: OperationDetail = {
  //               onDynamics: false,
  //               onLocalDatabase: true,
  //               onLocalCopy: true,
  //               operationDetail: {
  //                 code: 'SOIADEL101',
  //                 message: this.translate.instant('DELTA_SYNC_ORDERS_UPDATE')
  //               }
  //             };
  //             if (orders.length > 0) {
  //               await this.updateOrderActivity(action, orders, newLastUpdatedTime);
  //             }
  //             if (pastOrdersForEdgeAnalytics.length > 0) {
  //               await this.updateOrderActivity({
  //                 onDynamics: false,
  //                 onLocalDatabase: true,
  //                 onLocalCopy: false,
  //               }, pastOrdersForEdgeAnalytics, newLastUpdatedTime);
  //             }
  //           }
  //         }
  //         syncState.lastUpdatedTime = newLastUpdatedTime;
  //         await this.disk.updateSyncState(syncState);
  //         await this.deltaService.addEntitySyncInfo(orderActivitySyncInfo);
  //       }
  //     } catch (error) {
  //       if(this._isInitialMappingDone == false){
  //         await this.orderActivityDataService.loadOrderActivitiesFromDb(dataRange);
  //         await this.loadSurgeryOrderActivitiesFromDb(dataRange);
  //         this._isInitialMappingDone = true;
  //       }
  //       this.deltaService.addSyncErrorToEntitySyncInfo(orderActivitySyncInfo, url, error);
  //       await this.deltaService.addEntitySyncInfo(orderActivitySyncInfo);
  //     } finally {
  //       if (isSurgeryOrderEnabled) {
  //         this._surgeryOrderSyncCompleted$.next(true);
  //       }
  //     }
  //   }
  // }

  async setupSurgeryOrderMeasureData() {
    if (!this.reportDataMgmService.hasMeasure(MeasureType.procedure)) {
      let measureData;
      measureData = procedureGenerateMeasureData(
        [],
        this.authenticationService.user.systemUserID,
        this.reportDataMgmService.getConfiguration('Procedures')
      );

      if (measureData) {
        this.reportDataMgmService.addMeasure(MeasureType.procedure, measureData);

        const orders: SurgeryOrderActivity[] = await this.loadProcedureLogsFromDBForEdgeAnalytics();
        procedureUpdateMeasureData(
          measureData,
          orders, this.authenticationService.user.systemUserID,
          this.reportDataMgmService.getConfiguration('Procedures')
        );
      }
    } else {
      let measureData;
      const orders: SurgeryOrderActivity[] = await this.loadProcedureLogsFromDBForEdgeAnalytics();
      const curMeasureData: SurgeryOrderMeasureData = this.reportDataMgmService.getMeasure(MeasureType.procedure);
      measureData = procedureUpdateMeasureData(
        curMeasureData,
        orders, this.authenticationService.user.systemUserID,
        this.reportDataMgmService.getConfiguration('Procedures')
      );

      if (measureData) {
        this.reportDataMgmService.addMeasure(MeasureType.procedure, measureData);
      }
    }
  }

  public async handleDeletedTrackActionForSurgeryOrderActivity(raw) {
    if (raw && raw['salesorderid']) {
      let order = (this.activityOfflineService.getActivityByID(raw['salesorderid']) as SurgeryOrderActivity);
      if (!order) {// Search for the activity in offline db
        let option = {
          selector: {
            '_id': {
              $gte: DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY,
              $lte: DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
            },
            'salesorderid': {
              $eq: raw['salesorderid']
            },
          }
        };
        const rawOrders: any[] = await this.disk.find(option);
        if (rawOrders && Array.isArray(rawOrders) && rawOrders.length > 0) {
          order = new SurgeryOrderActivity(rawOrders[0]);
          order.isHardDeleted = true;
        }
      }
      if (order) {
        this.updateOrderActivityStatus({ onDynamics: false, onLocalDatabase: true, onLocalCopy: true, operationDetail: { code: 'scrapsurgeryorderactivity', message: 'Scrap Surgery Order' } }, order, true);
      }
    }
  }

  public handleSurgeryOrdersOfflinePushResponse(rawResponse: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let updatedOfflineOrders = [];
      for (var i = 0; i < rawResponse.length; i++) {
        let orderActivity = rawResponse[i];

        //Meeting Failed  - Procedure log Success
        if (orderActivity['errorCode'] && orderActivity['errorCode'] == 'ERR_IO_PL01') {
          let idtobeused = (orderActivity.indskr_externalid) ? DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY + orderActivity.indskr_externalid : DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY + orderActivity.salesorderid
          let savedOfflineObject = await this.disk.retrieve(idtobeused);
          if (savedOfflineObject) {
            savedOfflineObject.salesorderid = orderActivity.salesorderid;
            savedOfflineObject.pendingPushToDynamics = true;
            if (savedOfflineObject.statecode == 2 && savedOfflineObject.statuscode == 4 && savedOfflineObject.isHardDeleted) {
              updatedOfflineOrders.push(getDeletedPayloadObejct(savedOfflineObject));
            } else {
              updatedOfflineOrders.push(savedOfflineObject);
            }
            //Set note id
            if (savedOfflineObject['notes'] && savedOfflineObject['notes'].length > 0) {
              for (let i = 0; i < savedOfflineObject['notes'].length; i++) {
                let offnote = savedOfflineObject.notes[i];
                savedOfflineObject['notes'][i].updated = false;
                if (offnote && !(offnote.annotationid && !offnote.annotationid.includes('offline'))) {
                  savedOfflineObject['notes'][i].annotationid = orderActivity['notes'][i].annotationid;
                }
              }
              savedOfflineObject['notes'] = savedOfflineObject['notes'].filter(a => !a.deleted);
            }
          }
          const order = this.activityOfflineService.getActivityByID(orderActivity.indskr_externalid ? orderActivity.indskr_externalid : orderActivity.salesorderid);
          if (order) {
            (<SurgeryOrderActivity>order).ID = orderActivity.salesorderid;
            (<SurgeryOrderActivity>order).pendingPushToDynamics = true;
            //Set note id
            if ((<SurgeryOrderActivity>order).procedureNotes && (<SurgeryOrderActivity>order).procedureNotes.length > 0) {
              for (let i = 0; i < (<SurgeryOrderActivity>order).procedureNotes.length; i++) {
                (<SurgeryOrderActivity>order).procedureNotes[i].updated = false;
                if ((<SurgeryOrderActivity>order).procedureNotes[i] && !((<SurgeryOrderActivity>order).procedureNotes[i].noteId && !(<SurgeryOrderActivity>order).procedureNotes[i].noteId.includes('offline'))) {
                  (<SurgeryOrderActivity>order).procedureNotes[i].noteId = orderActivity['notes'][i].annotationid;
                }
              }
              (<SurgeryOrderActivity>order).procedureNotes = (<SurgeryOrderActivity>order).procedureNotes.filter(a => !a.isDeleted);
            }
          } else {
            const order2 = this.activityOfflineService.getActivityByID(orderActivity.salesorderid);
            if (order2) {
              (<SurgeryOrderActivity>order2).ID = orderActivity.salesorderid;
              (<SurgeryOrderActivity>order2).pendingPushToDynamics = true;
            }
            //Set note id
            if ((<SurgeryOrderActivity>order2).procedureNotes && (<SurgeryOrderActivity>order2).procedureNotes.length > 0) {
              for (let i = 0; i < (<SurgeryOrderActivity>order2).procedureNotes.length; i++) {
                //if (offnote && !(offnote.annotationid && !offnote.annotationid.includes('offline'))) {
                (<SurgeryOrderActivity>order2).procedureNotes[i].updated = false;
                if ((<SurgeryOrderActivity>order2).procedureNotes[i] && !((<SurgeryOrderActivity>order2).procedureNotes[i].noteId && !(<SurgeryOrderActivity>order2).procedureNotes[i].noteId.includes('offline'))) {
                  (<SurgeryOrderActivity>order2).procedureNotes[i].noteId = orderActivity['notes'][i].annotationid;
                }
              }
              (<SurgeryOrderActivity>order2).procedureNotes = (<SurgeryOrderActivity>order2).procedureNotes.filter(a => !a.isDeleted);
            }
          }
          if (this.activityOfflineService.selectedActivity
            && this.activityOfflineService.selectedActivity.type == ActivityType.SurgeryOrder
            && (this.activityOfflineService.selectedActivity.ID == orderActivity['salesorderid']
              || this.activityOfflineService.selectedActivity.ID == orderActivity['indskr_externalid'])) {
            this.isSelectedSurgeryOrderActivityUpdated = true;
          }
          //Meeting Success - Procedure Log Failed
        } else if (orderActivity['errorCode'] && orderActivity['errorCode'] == 'ERR_IO_PL02') {
          let idtobeused = (orderActivity.indskr_externalid) ? DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY + orderActivity.indskr_externalid : DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY + orderActivity.salesorderid
          let savedOfflineObject = await this.disk.retrieve(idtobeused);
          if (savedOfflineObject) {
            if (orderActivity['salesorderid']) {
              savedOfflineObject.salesorderid = orderActivity.salesorderid;
            }
            if (orderActivity['appointmentId']) {
              savedOfflineObject['appointmentId'] = orderActivity['appointmentId'];
            }
            savedOfflineObject.pendingPushToDynamics = true;
            if (savedOfflineObject.statecode == 2 && savedOfflineObject.statuscode == 4 && savedOfflineObject.isHardDeleted) {
              updatedOfflineOrders.push(getDeletedPayloadObejct(savedOfflineObject));
            } else {
              updatedOfflineOrders.push(savedOfflineObject);
            }
          }
          const order = this.activityOfflineService.getActivityByID(orderActivity.indskr_externalid ? orderActivity.indskr_externalid : orderActivity.salesorderid);
          if (order) {
            if (orderActivity['salesorderid']) {
              (<SurgeryOrderActivity>order).ID = orderActivity.salesorderid;
            }
            if (orderActivity['appointmentId']) {
              (<SurgeryOrderActivity>order).appointmentId = orderActivity['appointmentId'];
            }
            (<SurgeryOrderActivity>order).pendingPushToDynamics = true;
          } else {
            const order2 = this.activityOfflineService.getActivityByID(orderActivity.salesorderid);
            if (order2) {
              if (orderActivity['salesorderid']) {
                (<SurgeryOrderActivity>order2).ID = orderActivity.salesorderid;
              }
              if (orderActivity['appointmentId']) {
                (<SurgeryOrderActivity>order2).appointmentId = orderActivity['appointmentId'];
              }
              (<SurgeryOrderActivity>order2).pendingPushToDynamics = true;
            }
          }
          if (this.activityOfflineService.selectedActivity
            && this.activityOfflineService.selectedActivity.type == ActivityType.SurgeryOrder
            && (this.activityOfflineService.selectedActivity.ID == orderActivity['salesorderid']
              || this.activityOfflineService.selectedActivity.ID == orderActivity['indskr_externalid'])) {
            this.isSelectedSurgeryOrderActivityUpdated = true;
          }
        } else if (orderActivity.hasOwnProperty('salesorderid') && orderActivity.hasOwnProperty('indskr_externalid') && !(orderActivity['errorCode'] || orderActivity['errorId'])) {
          let idtobeused = (orderActivity.indskr_externalid) ? DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY + orderActivity.indskr_externalid : DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY + orderActivity.salesorderid
          let savedOfflineObject = await this.disk.retrieve(idtobeused);
          if (savedOfflineObject) {
            savedOfflineObject.salesorderid = orderActivity.salesorderid;
            if (orderActivity['appointmentId']) {
              savedOfflineObject['appointmentId'] = orderActivity['appointmentId'];
            }
            //Set note id
            if (savedOfflineObject['notes'] && savedOfflineObject['notes'].length > 0) {
              for (let i = 0; i < savedOfflineObject['notes'].length; i++) {
                let offnote = savedOfflineObject.notes[i];
                savedOfflineObject['notes'][i].updated = false;
                if (offnote && !(offnote.annotationid && !offnote.annotationid.includes('offline'))) {
                  savedOfflineObject['notes'][i].annotationid = orderActivity['notes'][i].annotationid;
                }
              }
              savedOfflineObject['notes'] = savedOfflineObject['notes'].filter(a => !a.deleted);
            }
            //savedOfflineObject.ordernumber = (orderActivity['ordernumber']) ? orderActivity['ordernumber'] : 'New Order';
            //savedOfflineObject.subject = savedOfflineObject.ordernumber;
            //savedOfflineObject.name = (orderActivity['name']) ? orderActivity['name'] : 'Surgery Order';
            savedOfflineObject.pendingPushToDynamics = false;
            if (savedOfflineObject.statecode == 2 && savedOfflineObject.statuscode == 4 && savedOfflineObject.isHardDeleted) {
              updatedOfflineOrders.push(getDeletedPayloadObejct(savedOfflineObject));
            } else {
              updatedOfflineOrders.push(savedOfflineObject);
            }
          }
          const order = this.activityOfflineService.getActivityByID(orderActivity.indskr_externalid ? orderActivity.indskr_externalid : orderActivity.salesorderid);
          if (order) {
            (<SurgeryOrderActivity>order).ID = orderActivity.salesorderid;
            if (orderActivity['appointmentId']) {
              (<SurgeryOrderActivity>order).appointmentId = orderActivity['appointmentId'];
            }
            //Set note id
            if ((<SurgeryOrderActivity>order).procedureNotes && (<SurgeryOrderActivity>order).procedureNotes.length > 0) {
              for (let i = 0; i < (<SurgeryOrderActivity>order).procedureNotes.length; i++) {
                (<SurgeryOrderActivity>order).procedureNotes[i].updated = false;
                if ((<SurgeryOrderActivity>order).procedureNotes[i] && !((<SurgeryOrderActivity>order).procedureNotes[i].noteId && !(<SurgeryOrderActivity>order).procedureNotes[i].noteId.includes('offline'))) {
                  (<SurgeryOrderActivity>order).procedureNotes[i].noteId = orderActivity['notes'][i].annotationid;
                }
              }
              (<SurgeryOrderActivity>order).procedureNotes = (<SurgeryOrderActivity>order).procedureNotes.filter(a => !a.isDeleted);
            }

            if (this.activityOfflineService.selectedActivity
              && this.activityOfflineService.selectedActivity.type == ActivityType.SurgeryOrder
              && (this.activityOfflineService.selectedActivity.ID == orderActivity['salesorderid']
                || this.activityOfflineService.selectedActivity.ID == orderActivity['indskr_externalid'])) {
              (<SurgeryOrderActivity>this.activityOfflineService.selectedActivity).assets = (<SurgeryOrderActivity>order).assets;
            }

            //(<SurgeryOrderActivity>order).orderNumber = (orderActivity['ordernumber']) ? orderActivity['ordernumber'] : 'New Order';
            //(<SurgeryOrderActivity>order).subject = (orderActivity['ordernumber']) ? orderActivity['ordernumber'] : 'New Order';
            //(<SurgeryOrderActivity>order).name = (orderActivity['name']) ? orderActivity['name'] : 'New Order';
            (<SurgeryOrderActivity>order).pendingPushToDynamics = false;
          } else {
            const order2 = this.activityOfflineService.getActivityByID(orderActivity.salesorderid);
            if (order2) {
              (<SurgeryOrderActivity>order2).ID = orderActivity.salesorderid;
              if (orderActivity['appointmentId']) {
                (<SurgeryOrderActivity>order2).appointmentId = orderActivity['appointmentId'];
              }
              //Set note id
              if ((<SurgeryOrderActivity>order2).procedureNotes && (<SurgeryOrderActivity>order2).procedureNotes.length > 0) {
                for (let i = 0; i < (<SurgeryOrderActivity>order2).procedureNotes.length; i++) {
                  (<SurgeryOrderActivity>order2).procedureNotes[i].updated = false;
                  if ((<SurgeryOrderActivity>order2).procedureNotes[i] && !((<SurgeryOrderActivity>order2).procedureNotes[i].noteId && !(<SurgeryOrderActivity>order2).procedureNotes[i].noteId.includes('offline'))) {
                    (<SurgeryOrderActivity>order2).procedureNotes[i].noteId = orderActivity['notes'][i].annotationid;
                  }
                }
                (<SurgeryOrderActivity>order2).procedureNotes = (<SurgeryOrderActivity>order2).procedureNotes.filter(a => !a.isDeleted);
              }
              //(<SurgeryOrderActivity>order2).orderNumber = (orderActivity['ordernumber']) ? orderActivity['ordernumber'] : 'New Order';
              //(<SurgeryOrderActivity>order2).subject = (orderActivity['ordernumber']) ? orderActivity['ordernumber'] : 'New Order';
              //(<SurgeryOrderActivity>order2).name = (orderActivity['name']) ? orderActivity['name'] : 'New Order';
              (<SurgeryOrderActivity>order2).pendingPushToDynamics = false;

              if (this.activityOfflineService.selectedActivity
                && this.activityOfflineService.selectedActivity.type == ActivityType.SurgeryOrder
                && (this.activityOfflineService.selectedActivity.ID == orderActivity['salesorderid']
                  || this.activityOfflineService.selectedActivity.ID == orderActivity['indskr_externalid'])) {
                (<SurgeryOrderActivity>this.activityOfflineService.selectedActivity).assets = (<SurgeryOrderActivity>order2).assets;
              }
            }
          }
          if (this.activityOfflineService.selectedActivity
            && this.activityOfflineService.selectedActivity.type == ActivityType.SurgeryOrder
            && (this.activityOfflineService.selectedActivity.ID == orderActivity['salesorderid']
              || this.activityOfflineService.selectedActivity.ID == orderActivity['indskr_externalid'])) {
            this.isSelectedSurgeryOrderActivityUpdated = true;
          }
        }
      }
      if (updatedOfflineOrders.length > 0) {
        try {
          // Bulk save docs to DB
          await this.disk.bulk(updatedOfflineOrders);

          // Track Offline data count
          const newCount = this.disk.getOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SURGERY_ORDER) - updatedOfflineOrders.length;
          this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SURGERY_ORDER, newCount >= 0 ? newCount : 0);
          resolve('Successfully updated in offline DB');
        } catch (error) {
          reject('Error Occured while updating offline data' + error);
        }
      } else {
        resolve('No order found to be updated');
      }
    });
  }

  public async loadSurgeryOrderActivitiesFromDb(dataRange: { from: string, to: string }): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const userOfflineDataDurationStartDate: Date = new Date(parseInt(dataRange.from));
      let option = {
        selector: {
          '_id': {
            $gte: DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY,
            $lte: DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
          },
        }
      };
      // Don't consider the offline data duration field for orders as of now
      // if (dataRange && dataRange.from) {
      //     option.selector['scheduledstart'] = {
      //         $gte: dataRange.from
      //     };
      // }
      // if (dataRange && dataRange.to) {
      //     option.selector['scheduledstart'] = Object.assign({},
      //         option.selector['scheduledstart'],
      //         { $lte: dataRange.to }
      //     );
      // }
      try {
        // Fetch from DB and do mapping
        const rawOrders: any[] = await this.disk.find(option);
        if (rawOrders && Array.isArray(rawOrders) && rawOrders.length > 0) {
          let orders = [];
          rawOrders.forEach(rawOrder => {
            let newOrder = new SurgeryOrderActivity(rawOrder);
            //if(!(newOrder.state == 2 && newOrder.status == 4)){// Don't add scrapped order activities
            if (newOrder.ownerId != this.authenticationService.user.systemUserID) {
              newOrder.isTeamOrder = true;
            }
            if (isAfter(newOrder.createdDate, userOfflineDataDurationStartDate)) {
              orders.push(newOrder);
            }
            //}
          });
          if (orders.length > 0) {
            let action: OperationDetail = {
              onDynamics: false,
              onLocalDatabase: false,
              onLocalCopy: true,
              operationDetail: {
                code: 'SOODBDM101',
                message: 'Surgery Orders Offline Db data mapping'
              }
            };
            // Track Offline data count
            let offlineDataCount = orders.filter(o => o.pendingPushToDynamics === true).length;
            this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SURGERY_ORDER, offlineDataCount);
            await this.createOrderActivity(action, orders, null, false).then(success => {
              resolve();
              return;
            });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      } catch (error) {
        reject('Error occured while fetching surgery order activities data from offline db');
      }
    });
  }

  async loadProcedureLogsFromDBForEdgeAnalytics(includeTeamOrders: boolean = false): Promise<SurgeryOrderActivity[]> {
    let option = {
      selector: {
        '_id': {
          $gte: DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY,
          $lte: DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
        },
      }
    };
    let procedureLogs: SurgeryOrderActivity[] = [];

    try {
      const rawProcedureLogs: any[] = await this.disk.find(option);
      if (Array.isArray(rawProcedureLogs)) {
        const currentYear = new Date().getFullYear();

        for (let i = 0; i < rawProcedureLogs.length; i++) {
          const rawProcedureLog = rawProcedureLogs[i];
          if (rawProcedureLog.statuscode !== 548910001
            || rawProcedureLog.indskr_noprocedureday === true
            || (!includeTeamOrders && rawProcedureLog.ownerid !== this.authenticationService.user.systemUserID)) {
            continue;
          }
          const procedureLog = new SurgeryOrderActivity(rawProcedureLog);
          if (procedureLog.year === currentYear) {
            procedureLogs.push(procedureLog);
          }
        }
      }
    } catch (error) {
      console.error('loadProcedureLogsFromDBForEdgeAnalytics: ', error);
    }

    return procedureLogs;
  }

  // private async fetchRealTimeOrderActivityStatus(id): Promise<any> {
  //   return new Promise(async (resolve, reject) => {
  //     let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.activites.GET_ACTIVITY_DETAILS;
  //     url = url.replace("{activity_id}", id);
  //     url = url.replace("{activity_type}", 'order');
  //     url = url.replace('&configFields={{configFields}}', '');

  //     let headers = Endpoints.headers.content_type.json;
  //     headers.headers = headers.headers.set(
  //       'No-Retry', 'true'
  //     );
  //     headers.headers = headers.headers.set(
  //       'X-Feature-Id', 'Fetch_Activity_Appointment'
  //     );
  //     let rawActivity: any = await this.http.get(url, headers).toPromise();
  //     if (rawActivity) {
  //       resolve({
  //         statecode: rawActivity['statecode'],
  //         statuscode: rawActivity['statuscode'],
  //       });
  //     } else {
  //       reject();
  //     }
  //   });
  // }

  public async updateOrderActivityStatus(action: OperationDetail, order: SurgeryOrderActivity, force: boolean = false): Promise<any> {
    if (order && action && action.operationDetail && action.operationDetail.code) {
      switch (action.operationDetail.code) {
        case 'scrapsurgeryorderactivity': {
          // Check for deletion conditions
          if ((order.ownerId != this.authenticationService.user.systemUserID && order.coOwners.some((coOwner) => coOwner.userId !== this.authenticationService.user.systemUserID)) && !force) {
            return Promise.reject(this.translate.instant('NOT_AUTHORIZED_TO_SCRAP_ACTIVITY'));
          } else if ((order.status == 548910001 || order.status == 100001 || order.status == 548910000) && !force) {
            return Promise.reject('Cannot scrap completed activity');
          } else if (order.state == 2 && !force) {
            return Promise.reject('Cannot scrap already cancelled activity');
          } else if (order.state == 0 || force) {
            order.state = 2;
            order.status = 4;
            order.isHardDeleted = true;
            order.pendingPushToDynamics = true;
            if (order.ID.includes('offline')) {
              order.pendingPushToDynamics = false;
            }
            if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT) && order.indskr_procedurecontract && !force) {
              const proceduerContract = this.contractService.findProcedureContractById(order.indskr_procedurecontract);
              if (proceduerContract) {
                this.handleContractInScrap(order)
              }
            }
            return this.updateOrderActivity(action, [order], new Date().getTime());
          } else {
            return Promise.reject('Error Occured while scraping order activity');
          }
        }
        case 'markcomplete': {
          //order.state = 3;
          //order.status = 100001;
          order.state = 1;
          order.status = 548910001;
          order.pendingPushToDynamics = true;
          order.indskr_datecompleted = new Date().getTime().toString();
          return this.updateOrderActivity(action, [order], new Date().getTime());
        }
        case 'markcompleteagain': {
          //order.state = 3;
          //order.status = 100001;
          order.state = 1;
          order.status = 548910001;
          order.pendingPushToDynamics = true;
          order.indskr_datecompleted = new Date().getTime().toString();
          return this.updateOrderActivity(action, [order], new Date().getTime());
        }
        case 'reopensurgeryactivity': {
          order.state = 0;
          order.status = 1;
          order.pendingPushToDynamics = true;
          return this.updateOrderActivity(action, [order], new Date().getTime());
        }
        case 'cancelsurgeryorder':
          order.state = 2;
          order.status = 548910005;
          order.pendingPushToDynamics = true;
          return this.updateOrderActivity(action, [order], new Date().getTime());
        default: {
          return Promise.reject('Not a valid option for updating status');
        }
      }
    } else {
      return Promise.reject('No activity passed for status updation');
    }
  }

  public async createOrderActivity(action: OperationDetail, data: Array<SurgeryOrderActivity>, newLastUpdatedTime: number, isInitialSync: boolean = false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (data.length == 0) reject(this.translate.instant('NO_DATA_PASSED_FOR_OPERATION'));
      let checkNextAction: boolean = true;
      if (action.onDynamics) {
        if (data && data.length == 1) { //Realtime order creation on dynamics
          let serviceDTO = data[0].serviceDTO;
          delete serviceDTO.opportunities;
          await this.createOrderActivityOnline(serviceDTO).then(info => {
            // Succesfully created on dynamics
            if (info && info['salesorderid']) {
              data[0].ID = info['salesorderid'];
              data[0].pendingPushToDynamics = false;
              data[0].subject = data[0].subject;
            }
            if (!action.onLocalDatabase && !action.onLocalCopy) {
              resolve(this.translate.instant('NO_SUCCESSFULLY_CREATED_ON_SERVER'));
              checkNextAction = false;
            }
          }).catch(error => {
            // Handle any error scenario
            checkNextAction = true; // As per current expected behaviour still create order in offline db
          });
        } else {
          console.log('Got offline data as:' + data);
          resolve('');
          // can be used forbulk data upload on dynamics
        }
      }
      if (action.onLocalDatabase && checkNextAction) {
        if (isInitialSync) {
          try {
            await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_SURGERY_ORDER_ACTIVITIES);
            this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SURGERY_ORDER, 0);
          } catch (error) {
            reject(this.translate.instant('ERROR_WHILE_CLEARING_OFFLINE_DB') + error);
            return;
          }
        }
        //Initialise document id and get offline DTO for each activity
        if (data) {
          let offlineData = [];
          for (let i = 0; i < data.length; i++) {
            let order = data[i];
            let offlineDTO = order.offlineDataDTO;
            offlineDTO._id = order.offlineDBId;
            if (newLastUpdatedTime) {
              offlineDTO.lastUpdatedTime = newLastUpdatedTime;
            }
            offlineData.push(offlineDTO);
          };

          if (offlineData && Array.isArray(offlineData)) {
            try {
              // Bulk save docs to DB
              await this.disk.bulk(offlineData);

              // Track offline data count
              const count: number = offlineData.filter(o => o.pendingPushToDynamics).length;
              if (count > 0) {
                this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.SURGERY_ORDER, count);
              }
            } catch (error) {
              reject(this.translate.instant('ERROR_WHILE_SAVING_OFFLINE_DATA') + error);
              checkNextAction = false;
            }
            if (!action.onLocalCopy) {
              resolve(this.translate.instant('SUCCESSFULLY_CREATED_ON_OFFLINE_DB'));
              checkNextAction = false;
            }
          }
        }
      }
      if (action.onLocalCopy && checkNextAction) {
        // push into local array of order-activities
        if (data) {
          let callFilterActivities: boolean = true;
          if (action.operationDetail && action.operationDetail.code && (action.operationDetail.code == 'SOODBDM101' || action.operationDetail.code == 'SOAISDM101')) {
            callFilterActivities = false;
          }
          data.forEach(order => {
            this.activityOfflineService.addActivity(order, false, false, null, callFilterActivities).then(success => {
              resolve('Successfully created order activity');
            }).catch(err => {
              reject('Error Occured while saving order activity into local array' + err);
            })
          })
          // Need to refresh UI on the week view tab
          // this.events.publish('weekview:RefreshUI');
        }
      }
    });
  }

  public async updateOrderActivity(action: OperationDetail, data: Array<SurgeryOrderActivity>, newLastUpdatedTime, hasOfflineChanges: boolean = false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (data.length == 0) reject(this.translate.instant('NO_DATA_PASSED_FOR_OPERATION'));
      let checkNextAction: boolean = true;
      if (action.onDynamics) {
        if (data && data.length == 1) { //Realtime order-activity creation on dynamics
          if (!data[0].ID.includes('offline')) {
            let serviceDTO = data[0].serviceDTO;
            if (data[0].state == 2 && data[0].status == 4) {
              await this.scrapOrderActivityOnline(data[0]).then(async info => {
                data[0].pendingPushToDynamics = false;
                // Succesfully updated on dynamics
                if (info && info['salesorderid']) {
                  data[0].pendingPushToDynamics = false;
                }
                if (!action.onLocalDatabase && !action.onLocalCopy) {
                  resolve('Successfully deleted on server');
                  checkNextAction = false;
                }

                // if (data[0].indskr_procedurecontract) {
                //  await this.handleContractInScrap(data[0]);
                // }
              }).catch(err => {
                // Handle any error scenario
                if (err && err['error']) {
                  let errorDetail = err['error'];
                  if (errorDetail['errorCode']) {
                    // Handle error codes
                  }
                }
                checkNextAction = true;
              });
            } else {
              // if (action.operationDetail && action.operationDetail.code
              //   && (action.operationDetail.code == 'markcomplete' || action.operationDetail.code == 'reopensurgeryactivity')
              //   && !hasOfflineChanges) {
              //     serviceDTO = data[0].statusUpdateDTO;
              // }
              if (action.operationDetail && action.operationDetail.code) {
                if ((action.operationDetail.code == 'markcomplete' || action.operationDetail.code == 'reopensurgeryactivity' || action.operationDetail.code === 'markcompleteagain' || action.operationDetail.code === 'cancelsurgeryorder') && !hasOfflineChanges) {
                  serviceDTO = data[0].statusUpdateDTO;
                } else if (action.operationDetail.code == 'coOwnerUpdate') {
                  serviceDTO = data[0].coOwnersUpdateDTO;
                } else if (action.operationDetail.code == 'coVisitorUpdate') {
                  serviceDTO = data[0].coVisitorsUpdateDTO;
                } else if (action.operationDetail.code == 'updateOpportunity') {
                  serviceDTO = data[0].opportunitiesUpdateDTO;
                }

                if (action.operationDetail.code === 'markcompleteagain') {
                  serviceDTO.procedureContract_forceComplete = true // to bypass the post surgery signature check
                }
              }
              switch (action.operationDetail?.code) {
                case 'updatequantities':
                case 'updateproducts':
                  delete serviceDTO.product_category;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.notes;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.assets;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.coOwners;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_noofassistanceavailed;
                  delete serviceDTO.indskr_maximumnoofassistance;
                  delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  break;
                case 'updatecustomers':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.notes;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.assets;
                  delete serviceDTO.coOwners;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  break;
                case 'updatesurgery':
                case 'updateproductcategory':
                  delete serviceDTO.contactorders;
                  delete serviceDTO.notes;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.assets;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.coOwners;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_noofassistanceavailed;
                  delete serviceDTO.indskr_maximumnoofassistance;
                  delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  break;
                case 'updatelocation':
                case 'updatetime':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.notes;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.assets;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.coOwners;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_noofassistanceavailed;
                  delete serviceDTO.indskr_maximumnoofassistance;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                // delete serviceDTO.indskr_procedurecontract;
                case 'updateaccounts':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.notes;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.assets;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.coOwners;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_noofassistanceavailed;
                  delete serviceDTO.indskr_maximumnoofassistance;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  // delete serviceDTO.indskr_procedurecontract;
                  break;
                case 'updateaccountswithaffliation':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.notes;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.assets;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.coOwners;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  break;
                case 'updatenotes':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.assets;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.coOwners;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  break;
                case 'assetsUpdate':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.notes;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.coOwners;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  break;
                case 'updateSurveyResponse':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.notes;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.assets;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.coOwners;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  break;
                case 'updateactivityoutcome':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.notes;
                  delete serviceDTO.assets;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.coOwners;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  break;
                case 'updateOwner':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.notes;
                  delete serviceDTO.assets;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  break;
                case 'updateMarketBusinessPlan':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.notes;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.assets;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.coOwners;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  break;
                case 'updateCoachingPlan':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.notes;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.assets;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.coOwners;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  break;
                case 'updateOpportunity':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.notes;
                  delete serviceDTO.assets;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.coOwners;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.indskr_subjectedited;
                  break;
                case 'surgerOrderUpdate':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.notes;
                  delete serviceDTO.assets;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  break;
                case 'procedureStatsUpdate':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.notes;
                  delete serviceDTO.assets;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.coOwners;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  break;
                case 'updatecompetitorproducts':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.notes;
                  delete serviceDTO.assets;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  break;
                case 'updatecompetitorproducts':
                case 'updateProcedureType':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.notes;
                  delete serviceDTO.assets;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_noofassistanceavailed;
                  delete serviceDTO.indskr_maximumnoofassistance;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  // delete serviceDTO.indskr_procedurecontract;
                  break;
                case 'updateactivitysubject':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.notes;
                  delete serviceDTO.assets;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_noofassistanceavailed;
                  delete serviceDTO.indskr_maximumnoofassistance;
                  delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.opportunities;
                  break;
                case 'updatePreSignature':
                case 'updatePostSignature':
                case 'updatePreValidatorName':
                case 'updatePostValidatorName':
                case 'updateProcedureSubType':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.notes;
                  delete serviceDTO.assets;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_noofassistanceavailed;
                  delete serviceDTO.indskr_maximumnoofassistance;
                  // delete serviceDTO.indskr_procedurecontract;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  break;
                case 'updateProcedureContract':
                  delete serviceDTO.product_category;
                  delete serviceDTO.products;
                  delete serviceDTO.contactorders;
                  delete serviceDTO.surveyResponses;
                  delete serviceDTO.opportunityid;
                  delete serviceDTO.notes;
                  delete serviceDTO.assets;
                  delete serviceDTO.indskr_marketingbusinessplan;
                  delete serviceDTO.indskr_coachingplan;
                  delete serviceDTO.competitorProducts;
                  delete serviceDTO.indskr_superiorleft;
                  delete serviceDTO.indskr_superiorright;
                  delete serviceDTO.indskr_surgeryproduct;
                  delete serviceDTO.indskr_totalmappingpoints;
                  delete serviceDTO.indskr_transseptalaccess;
                  delete serviceDTO.indskr_transseptalaccessfluorotime;
                  delete serviceDTO.indskr_posteriorleft;
                  delete serviceDTO.indskr_posteriorright;
                  delete serviceDTO.indskr_procedurecomplete;
                  delete serviceDTO.indskr_procedurecompletefluorotime;
                  delete serviceDTO.indskr_procstartneedle;
                  delete serviceDTO.indskr_ptinroom;
                  delete serviceDTO.indskr_ptoutofroom;
                  delete serviceDTO.indskr_ptready;
                  delete serviceDTO.indskr_ridge;
                  delete serviceDTO.indskr_roofleft;
                  delete serviceDTO.indskr_roofright;
                  delete serviceDTO.indskr_fluiddelivered;
                  delete serviceDTO.indskr_interiorleft;
                  delete serviceDTO.indskr_interiorright;
                  delete serviceDTO.indskr_mappingcomplete;
                  delete serviceDTO.indskr_mappingcompletefluorotime;
                  delete serviceDTO.indskr_ablpoints;
                  delete serviceDTO.indskr_anteriorleft;
                  delete serviceDTO.indskr_anteriorright;
                  delete serviceDTO.indskr_averagerfpowerwatts;
                  delete serviceDTO.indskr_bwreparrives;
                  delete serviceDTO.indskr_bwrepleaves;
                  delete serviceDTO.indskr_ctmergednew;
                  delete serviceDTO.indskr_noofassistanceavailed;
                  delete serviceDTO.indskr_maximumnoofassistance;
                  delete serviceDTO.opportunities;
                  delete serviceDTO.indskr_subjectedited;
                  break;
                  case 'updateShoreiMaster':
                    delete serviceDTO.product_category;
                    delete serviceDTO.products;
                    delete serviceDTO.contactorders;
                    delete serviceDTO.surveyResponses;
                    delete serviceDTO.opportunityid;
                    delete serviceDTO.notes;
                    delete serviceDTO.assets;
                    delete serviceDTO.indskr_marketingbusinessplan;
                    delete serviceDTO.indskr_coachingplan;
                    delete serviceDTO.competitorProducts;
                    delete serviceDTO.indskr_superiorleft;
                    delete serviceDTO.indskr_superiorright;
                    delete serviceDTO.indskr_surgeryproduct;
                    delete serviceDTO.indskr_totalmappingpoints;
                    delete serviceDTO.indskr_transseptalaccess;
                    delete serviceDTO.indskr_transseptalaccessfluorotime;
                    delete serviceDTO.indskr_posteriorleft;
                    delete serviceDTO.indskr_posteriorright;
                    delete serviceDTO.indskr_procedurecomplete;
                    delete serviceDTO.indskr_procedurecompletefluorotime;
                    delete serviceDTO.indskr_procstartneedle;
                    delete serviceDTO.indskr_ptinroom;
                    delete serviceDTO.indskr_ptoutofroom;
                    delete serviceDTO.indskr_ptready;
                    delete serviceDTO.indskr_ridge;
                    delete serviceDTO.indskr_roofleft;
                    delete serviceDTO.indskr_roofright;
                    delete serviceDTO.indskr_fluiddelivered;
                    delete serviceDTO.indskr_interiorleft;
                    delete serviceDTO.indskr_interiorright;
                    delete serviceDTO.indskr_mappingcomplete;
                    delete serviceDTO.indskr_mappingcompletefluorotime;
                    delete serviceDTO.indskr_ablpoints;
                    delete serviceDTO.indskr_anteriorleft;
                    delete serviceDTO.indskr_anteriorright;
                    delete serviceDTO.indskr_averagerfpowerwatts;
                    delete serviceDTO.indskr_bwreparrives;
                    delete serviceDTO.indskr_bwrepleaves;
                    delete serviceDTO.indskr_ctmergednew;
                    delete serviceDTO.indskr_noofassistanceavailed;
                    delete serviceDTO.indskr_maximumnoofassistance;
                    delete serviceDTO.indskr_procedurecontract;
                    delete serviceDTO.opportunities;
                    delete serviceDTO.indskr_subjectedited;
                    break;
                case 'reopensurgeryactivity':
                  delete serviceDTO.indskr_procedurecontract;
                  break;
                case 'cancelorder':
                  serviceDTO = data[0].statusUpdateDTO;
                  break;
                default:
              }
              if (action.operationDetail?.code && action.operationDetail.code == 'updatequantities') {
                delete serviceDTO.product_category;
              }
              await this.UpdateOrderActivityOnline(serviceDTO).then(info => {
                // Succesfully updated on dynamics
                if (info && info['salesorderid']) {
                  data[0].pendingPushToDynamics = false;
                  if (action.operationDetail.code == 'updatenotes') {
                    if (info['notes']) {
                      for (let i = 0; i < data[0].procedureNotes.length; i++) {
                        data[0].procedureNotes[i].updated = false;
                        if (!(data[0].procedureNotes[i].noteId && !data[0].procedureNotes[i].noteId.includes('offline'))) {
                          data[0].procedureNotes[i].noteId = info['notes'][i]['annotationid'];
                          data[0].procedureNotes[i].ownerName = this.authenticationService.user.displayName;
                          data[0].procedureNotes[i].createdTime = new Date();
                        }
                      }
                      data[0].procedureNotes = data[0].procedureNotes.filter(a => !a.isDeleted);
                    }
                  } else if (action.operationDetail.code == 'coOwnerUpdate') {
                    data[0].coOwners = data[0].coOwners.filter(coOwner => !coOwner['deleted']);
                  } else if (action.operationDetail.code == 'coVisitorUpdate') {
                    data[0].coVisitors = data[0].coVisitors.filter(coVisitor => !coVisitor['deleted']);
                  }else if (action.operationDetail.code == 'updateOpportunity') {
                    data[0].opportunities = data[0].opportunities.filter(opp => !opp['deleted']);
                  }

                  if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT)) {
                    this.handleContractScenarios(serviceDTO, data[0], info);
                  }
                }
                if (!action.onLocalDatabase && !action.onLocalCopy) {
                  resolve('Successfully updated on server');
                  checkNextAction = false;
                }
              }).catch(err => {
                // Handle any error scenario
                //Check for online only actions
                if (action.operationDetail && action.operationDetail.code && (action.operationDetail.code == 'markcomplete' || action.operationDetail.code === 'markcompleteagain')) {
                  checkNextAction = false;
                  //data[0].pendingPushToDynamics = true;

                  if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT)) {
                    reject({
                      errorCode: 'PROCEDURECONTRACTNOTSIGNED',
                      operationCode: action.operationDetail.code,
                      errorMessage: 'Failed to perform the operation on dynamics',
                      errorDetails: err,
                    })
                    data[0].pendingPushToDynamics = false;
                  }


                  reject({
                    errorCode: 'ONLINEONLYUPDATEFAILED',
                    operationCode: action.operationDetail.code,
                    errorMessage: 'Failed to perform the operation on dynamics',
                    errorDetails: err,
                  })
                } else {
                  checkNextAction = true; // As per current expected behaviour still push the updates to offline db

                  if(this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT) && action.operationDetail.code === "updateProcedureContract" ){
                    if(err && err.error) {
                      data[0].pendingPushToDynamics = false;
                    }
                  }

                  reject({
                    errorCode: 'ONLINEUPDATEFAILED',
                    errorDetails: err
                  })
                }
              });
            }
          } else {
            // Check whether to push activity online or not
            if (data[0].state == 2 && data[0].status == 4 && action.operationDetail && action.operationDetail.code && action.operationDetail.code == 'scrapsurgeryorderactivity') {
              // Deleting an order activity that doesn't have dynamics id should be deleted directly from app without sending it to dynamics
              data[0].pendingPushToDynamics = false;
            }
          }
        } else {
          // can be used forbulk data upload on dynamics
        }
      }
      if (action.onLocalDatabase && checkNextAction) {
        let offlineData = [];
        for (let i = 0; i < data.length; i++) {
          let order = data[i];
          let offlineDTO = order.offlineDataDTO;
          offlineDTO._id = order.offlineDBId;
          let savedOfflineObject = await this.disk.retrieve(order.offlineDBId);
          if (action.operationDetail && action.operationDetail.code == 'SOIADEL101' && savedOfflineObject && savedOfflineObject.pendingPushToDynamics) {
            // In delta Sync order activity update, do not update activities that have pending changes to be pushed
            //checkNextAction = false;
            data[i].pendingPushToDynamics = true;
            resolve([]);
          }
          else if (savedOfflineObject) {
            // offline create and delete
            if (data[0].state == 2 && data[0].status == 4 && action.operationDetail && action.operationDetail.code && action.operationDetail.code == 'scrapsurgeryorderactivity' && data[0].ID.includes('offline')) {
              // Deleting an order activity that doesn't have dynamics id should be deleted directly from app without sending it to dynamics
              offlineData.push(getDeletedPayloadObejct(savedOfflineObject));
            }
            // Handle Deleted activities and track action
            if (action.operationDetail && action.operationDetail.code && action.operationDetail.code == 'scrapsurgeryorderactivity' && order.pendingPushToDynamics == false && order.state == 2 && order.status == 4 && order.isHardDeleted) { // Will reach here only if the updates have been pushed to dynamics and we can remove the object from offline db
              offlineData.push(getDeletedPayloadObejct(savedOfflineObject));
            } else {
              offlineDTO._id = savedOfflineObject['_id'];
              offlineDTO._rev = savedOfflineObject['_rev'];
              offlineDTO.lastUpdatedTime = newLastUpdatedTime;
              offlineData.push(offlineDTO);

              // If a new update in offline, add the offline data count
              if (!savedOfflineObject.pendingPushToDynamics && offlineDTO.pendingPushToDynamics) {
                this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ORDER, 1);
              }
            }
          } else if (action.operationDetail && action.operationDetail.code == 'SOIADEL101') {
            offlineDTO.lastUpdatedTime = newLastUpdatedTime;
            offlineData.push(offlineDTO);

            // If a new update in offline, add the offline data count
            if (offlineDTO.pendingPushToDynamics) {
              this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ORDER, 1);
            }
          }
        }
        if (offlineData.length > 0) {
          try {
            // Bulk save docs to DB
            await this.disk.bulk(offlineData);
          } catch (error) {
            reject('Error Occured while updating offline data' + error);
            checkNextAction = false;
          }
          if (!action.onLocalCopy) {
            resolve('Successfully updated in offline DB');
            checkNextAction = false;
          }
        }
      }
      if (action.onLocalCopy && checkNextAction) {
        if (data) {
          let callFilterActivities: boolean = true;
          if (action.operationDetail && action.operationDetail.code && (action.operationDetail.code == 'SODSDM101')) {
            callFilterActivities = false;
          }
          data.forEach(order => {
            // CHeck for deletion case
            if (action.operationDetail && action.operationDetail.code == 'SOIADEL101' && order.pendingPushToDynamics) {
              // In delta Sync order activity update, do not update activities that have pending changes to be pushed
              //checkNextAction = false;
              resolve('');
            } else {
              if (order.state == 2 && order.status == 4 && order.isHardDeleted) {// If surgery order activity is deleted or track action is deletion one
                if (this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.SurgeryOrder && this.activityOfflineService.selectedActivity.ID == order.ID) {
                  this.activityOfflineService.selectedActivity = null;
                  this.uiService.activeView = '';
                }
                this.activityOfflineService.removeActivity(order, false, null, callFilterActivities).then(success => {
                  resolve('Successfully updated order activity');
                }).catch(err => {
                  reject('Error Occured while updating order activity into local array' + err);
                })
              } else if (order.state == 2 &&
                order.status === 548910005 &&
                this.authenticationService.user.buSettings &&
                !this.authenticationService.user.buSettings['indskr_displaycancelledprocedurelogoncalendar']) {
                if (this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.SurgeryOrder && this.activityOfflineService.selectedActivity.ID == order.ID) {
                  this.activityOfflineService.selectedActivity = null;
                  this.uiService.activeView = '';
                }
                this.activityOfflineService.removeActivity(order, false, null, callFilterActivities).then(success => {
                  resolve('Successfully updated order activity');
                }).catch(err => {
                  reject('Error Occured while updating order activity into local array' + err);
                })
              } else {
                this.activityOfflineService.addActivity(order, true, false, null, callFilterActivities).then(success => {
                  resolve('Successfully updated order activity');
                }).catch(err => {
                  reject('Error Occured while updating order activity into local array' + err);
                })
              }// interaction update for account completed order
              // if (order.status === 100001) {
              //   this.globalUtility.updateInteractionAccount(this.accountService.getAccountById(order.accountId), 'Order');
              // }
              // Need to refresh UI on the week view tab
              if (action.operationDetail?.code == 'updateaccounts'
                || action.operationDetail?.code == 'updatecustomers'
                || action.operationDetail?.code == 'updateaccountswithaffliation'
                || action.operationDetail?.code == 'updatetime') {
                // this.events.publish('weekview:RefreshUI');
                this.activityOfflineService.publishActivityEvent({ action: "Update", activity: order });
              }

              if (this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity.type == ActivityType.SurgeryOrder && this.activityOfflineService.selectedActivity.ID == order.ID) {
                this.isSelectedSurgeryOrderActivityUpdated = true;
              }
            }
          })
        }
      }
    });
  }

  public async getProductsHierarchy(forProcedureTracker: boolean, fullSync: boolean, loadFromDBOnly: boolean = false) {
    let lastModifiedForDeltaSync, hourDifference;

    if (forProcedureTracker) {
      await this.disk.retrieve(DB_KEY_PREFIXES.PROCEDURE_TRACKER_SURGERY_PRODUCT_HIERARCHY).then((doc) => {
        if (doc && doc.raw && doc.raw.length) {
          this.procedureTrackerProductHierarchies = doc.raw;
          lastModifiedForDeltaSync = doc.lastUpdatedTime;
        }
        else {
          this.procedureTrackerProductHierarchies = [];
          fullSync = true;
        }
      });

    } else {
      const prodHierarchyDoc = await this.disk.retrieve(DB_KEY_PREFIXES.SURGERY_PRODUCT_HIERARCHY, true);
      if (prodHierarchyDoc?.raw?.length) {
        this.productHierarchies = prodHierarchyDoc.raw;
        lastModifiedForDeltaSync = prodHierarchyDoc.lastUpdatedTime;
      } else {
        this.productHierarchies = [];
        fullSync = true;
      }
    }

    if (loadFromDBOnly) {
      if (!forProcedureTracker) {
        const surgeryCategoryProductPositionRelationshipDoc = await this.disk.retrieve(
          DB_KEY_PREFIXES.SURGERY_CATEGORY_PRODUCT_POSITION,
          true,
        );
        this.filterCategoryProducts(surgeryCategoryProductPositionRelationshipDoc?.raw);
      }
      return;
    }
    // Implemented separately to resolve performance issue while fetching large number of procedures data records
    if (fullSync && !forProcedureTracker) {
      this._doProductHierarchyInitialSync();
      return;
    }
    let fetchXML = forProcedureTracker ? fetchQueries.surgeryOrders.fetchProductHierarchyProcedureTracker : fetchQueries.surgeryOrders.fetchProductHierarchy;
    let secondFetchXML = forProcedureTracker ? null : fetchQueries.surgeryOrders.fetchProductHierarchySecond;
    //add delta sync filter
    if (fullSync) {
      let deltaSyncFilter = `<filter type="and">
                                <condition attribute="indskr_type" operator="eq" value="548910000" />
                              </filter>`
      fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter);
      if (secondFetchXML) {
        secondFetchXML = secondFetchXML.replace('{deltaSyncFilter1}', deltaSyncFilter);
        secondFetchXML = secondFetchXML.replace('{deltaSyncFilter2}', '');
      }
    }
    else {
      let deltaSyncFilter;
      let now = new Date();
      if (lastModifiedForDeltaSync) {
        hourDifference = differenceInHours(
          now,
          new Date(lastModifiedForDeltaSync)
        )
        //add one to make sure we take care of fractional difference in hours
        hourDifference += 1
        deltaSyncFilter = `<filter type="and">
                              <condition attribute="indskr_type" operator="eq" value="548910000" />
                              <condition attribute="modifiedon" operator="last-x-hours" value="${hourDifference}" />
                            </filter>`
        if (secondFetchXML) {
          secondFetchXML = secondFetchXML.replace('{deltaSyncFilter2}', `<filter type="and">
          <condition attribute="modifiedon" operator="last-x-hours" value="${hourDifference}" />
        </filter>`);
        }
      }
      else {
        deltaSyncFilter = `<filter type="and">
                                <condition attribute="indskr_type" operator="eq" value="548910000" />
                              </filter>`;
        secondFetchXML = secondFetchXML.replace('{deltaSyncFilter2}', ``);
      }
      fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter);
      if (secondFetchXML) {
        secondFetchXML = secondFetchXML.replace('{deltaSyncFilter1}', `<filter type="and">
        <condition attribute="indskr_type" operator="eq" value="548910000" />
      </filter>`);
      }
    }
    fetchXML = fetchXML.replace('{UserID}', this.authenticationService.user.systemUserID);
    fetchXML = fetchXML.replace('{userBU}', this.authenticationService.user.xBusinessUnitId);
    if (secondFetchXML) {
      secondFetchXML = secondFetchXML.replace('{UserID}', this.authenticationService.user.systemUserID);
      secondFetchXML = secondFetchXML.replace('{userBU}', this.authenticationService.user.xBusinessUnitId);
    }
    let arrDeletedMappings = []
    let retiredProducts = []
    await this.dynamics.executeFetchQuery('indskr_positiongroupses', fetchXML).then(async (data) => {
      if (!fullSync) {
        let deletedMappings = await this.fetchDeletedPositionGroupProductMapping(lastModifiedForDeltaSync);
        // data.push(...deletedMappings);
        deletedMappings.sort((a, b) => {
          if (a['track_action_CreatedOn'] > b['track_action_CreatedOn']) return 1;
          else return -1;
        });

        arrDeletedMappings = deletedMappings;
      }

      const surgeryCategoryProductPositionRelationship = forProcedureTracker ? [] : await this.fetchSurgeryCategoryProductPositionRelationship();
      const surgerySKUProductRelationship = forProcedureTracker ? [] : await this.fetchSurgerySKUProductRelationship(lastModifiedForDeltaSync);
      let newProductHierarchies = this.aggregateProductHierarchies(data, surgerySKUProductRelationship, arrDeletedMappings, forProcedureTracker);
      console.log(data);
      if (forProcedureTracker) {
        this.updateOrInsertProductHierarchyForProcedureTracker(newProductHierarchies);
      } else {
        await this.dynamics.executeFetchQuery('indskr_positiongroupses', secondFetchXML).then(async (subProdData) => {
          if (subProdData && Array.isArray(subProdData)) {
            let checkIds: Array<string> = [];
            subProdData.forEach(record => {
              if (record['surgery.productid']) {
                let currentObj = (fullSync) ? newProductHierarchies : this.productHierarchies;
                let idx = currentObj.findIndex(a => a.surgeryId == record['surgery.productid']);
                if (idx >= 0) {
                  if (!checkIds.some(a => a == record['surgery.productid'])) {
                    currentObj[idx].subProducts = [];
                    checkIds.push(record['surgery.productid']);
                  }
                  if (record['subProducts.productid']) {
                    if (currentObj[idx].subProducts) {
                      currentObj[idx].subProducts.push({
                        productId: record['subProducts.productid'],
                        productName: record['subProducts.name'],
                      })
                    } else {
                      currentObj[idx].subProducts = [{
                        productId: record['subProducts.productid'],
                        productName: record['subProducts.name'],
                      }];
                    }
                  }
                }
              }
            })
          }
        }, (errror) => console.log(errror))
        if (!fullSync) {
          let trackChangesFetchXML = fetchQueries.surgeryOrders.fetchDeletedMobileAppTemplateAndProductMapping;
          trackChangesFetchXML = trackChangesFetchXML.replace('{hourDifference}', hourDifference);
          await this.dynamics.executeFetchQuery('indskr_trackchanges', trackChangesFetchXML).then(async (records) => {
            if (records && Array.isArray(records)) {
              records.forEach(item => {
                if (item['productid'] && item['mobileapptemplateid'] && item['track_action']) {
                  let idx = this.productHierarchies.findIndex(a => a.surgeryId == item['productid']);
                  if (idx >= 0) {
                    if (item['track_action'] == TrackAction.Deleted) {
                      this.productHierarchies[idx].surveyTemplateId = '';
                    } else if (item['track_action'] == TrackAction.Download) {
                      this.productHierarchies[idx].surveyTemplateId = item['mobileapptemplateid'];
                    }
                    if (this.activityOfflineService.selectedActivity && this.activityOfflineService.selectedActivity instanceof SurgeryOrderActivity && (this.activityOfflineService.selectedActivity as SurgeryOrderActivity).surgeryId == item['productid']) {
                      this.isSelectedSurgeryOrderActivityUpdated = true;
                    }
                  }
                }
              });
            }
          }, (errror) => console.log(errror))
        }
        this.updateOrInsertProductHierarchy(newProductHierarchies, surgeryCategoryProductPositionRelationship);
      }
    },
      (errror) => {
        console.log('error from procedure', errror)
      });
  }

  public async getProductsHierarchyForSurgeryOrder(fullSync, loadFromDBOnly = false) {
    let isSurgeryOrderEnabled = this.authenticationService.user.hasProcedureLog;
    let isProcedureTrackerEnabled = this.authenticationService.user.hasBulkProcedureLog;
    let isMessageProductBundleEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.MESSAGE_PRODUCT_BUNDLE);
    let isOpportunityUserProvidedEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_REVENUE_USER_PROVIDED);
    let isOpportunityByProductsEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_BY_PRODUCTS);
    let isMeetingProcedureEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_PROCEDURE);
    if (!isMessageProductBundleEnabled && !isSurgeryOrderEnabled && !isOpportunityUserProvidedEnabled && !isProcedureTrackerEnabled && !isOpportunityByProductsEnabled && !isMeetingProcedureEnabled) return;
    await this.getProductsHierarchy(false, fullSync, loadFromDBOnly);
    if (isProcedureTrackerEnabled || isOpportunityByProductsEnabled) {
      await this.getProductsHierarchy(true, fullSync, loadFromDBOnly);
    }
  }

  /*public async getProductsHierarchyForSurgeryOrder(fullSync, loadFromDBOnly = false){
    let isSurgeryOrderEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG);
    let isProcedureTrackerEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.PROCEDURE_TRACKER);
    let isMessageProductBundleEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.MESSAGE_PRODUCT_BUNDLE);
    let isOpportunityUserProvidedEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_REVENUE_USER_PROVIDED);
    if ( !isMessageProductBundleEnabled && !isSurgeryOrderEnabled && !isOpportunityUserProvidedEnabled && !isProcedureTrackerEnabled) return;
    let lastModifiedForDeltaSync, hourDifference;

    const prodHierarchyDoc = await this.disk.retrieve(DB_KEY_PREFIXES.SURGERY_PRODUCT_HIERARCHY, true)
    if (prodHierarchyDoc?.raw?.length) {
      this.productHierarchies = prodHierarchyDoc.raw;
      lastModifiedForDeltaSync = prodHierarchyDoc.lastUpdatedTime;
    } else {
      this.productHierarchies = [];
      fullSync = true;
    }

    if (loadFromDBOnly) {
      const surgeryCategoryProductPositionRelationshipDoc = await this.disk.retrieve(
        DB_KEY_PREFIXES.SURGERY_CATEGORY_PRODUCT_POSITION,
        true,
      );

      this.filterCategoryProducts(surgeryCategoryProductPositionRelationshipDoc?.raw);
      return;
    }
    let fetchXML = fetchQueries.surgeryOrders.fetchProductHierarchy;
    //add delta sync filter
    if (fullSync) {
      let deltaSyncFilter = `<filter type="and">
                                <condition attribute="indskr_type" operator="eq" value="548910000" />
                              </filter>`
      fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter);
    }
    else {
      let deltaSyncFilter;
      let now = new Date();
      if (lastModifiedForDeltaSync) {
        hourDifference = differenceInHours(
          now,
          new Date(lastModifiedForDeltaSync)
        )
        //add one to make sure we take care of fractional difference in hours
        hourDifference += 1
        deltaSyncFilter = `<filter type="and">
                              <condition attribute="indskr_type" operator="eq" value="548910000" />
                              <condition attribute="modifiedon" operator="last-x-hours" value="${hourDifference}" />
                            </filter>`
      }
      else deltaSyncFilter = `<filter type="and">
                                <condition attribute="indskr_type" operator="eq" value="548910000" />
                              </filter>`
      fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter);
    }
    fetchXML = fetchXML.replace('{UserID}', this.authenticationService.user.systemUserID);

    let arrDeletedMappings = []
    let retiredProducts = []
    await this.dynamics.executeFetchQuery('indskr_positiongroupses', fetchXML).then(async (data)=>{
      if(!fullSync) {
        let deletedMappings = await this.fetchDeletedPositionGroupProductMapping(lastModifiedForDeltaSync);
        // data.push(...deletedMappings);
        deletedMappings.sort((a,b)=>{
          if(a['track_action_CreatedOn']>b['track_action_CreatedOn']) return 1;
          else return -1;
        });

        arrDeletedMappings = deletedMappings;
      }

      const surgeryCategoryProductPositionRelationship = await this.fetchSurgeryCategoryProductPositionRelationship();
      const surgerySKUProductRelationship = await this.fetchSurgerySKUProductRelationship(lastModifiedForDeltaSync);
      const newProductHierarchies = this.aggregateProductHierarchies(
        data,
        surgerySKUProductRelationship,
        arrDeletedMappings
      );

      // console.log(data);
      this.updateOrInsertProductHierarchy(newProductHierarchies, surgeryCategoryProductPositionRelationship);
    }, (errror)=>console.log(errror));
  }*/

  private filterCategoryProducts(surgeryCategoryProductPositionRelationship) {
    if (
      Array.isArray(surgeryCategoryProductPositionRelationship)
      && Array.isArray(this.productHierarchies)
    ) {
      try {
        for (let i = 0; i < this.productHierarchies.length; i++) {
          const prodH = this.productHierarchies[i];
          if (Array.isArray(prodH.productCategories)) {
            _.remove(prodH.productCategories, prodC =>
              surgeryCategoryProductPositionRelationship
                .findIndex(cppr => cppr['categoryProduct.productid'] === prodC.productCategoryId)
              < 0
            );
          }
        }

        _.remove(this.productHierarchies, prodH =>
          prodH.productCategories.length === 0
        );
      } catch (error) {
        console.error('filterCategoryProducts: ', error);
      }
    }
  }

  private updateOrInsertProductHierarchy(
    newProductHierarchies: SurgeryProductHierarchy[],
    surgeryCategoryProductPositionRelationship
  ) {
    this.upsertLocalProdHierarchy(newProductHierarchies);
    const masterHierarchy = JSON.parse(JSON.stringify(this.productHierarchies));
    this.filterCategoryProducts(surgeryCategoryProductPositionRelationship);

    this.disk.updateOrInsert(DB_KEY_PREFIXES.SURGERY_PRODUCT_HIERARCHY, (doc) => {
      if (!doc || !doc.raw) {
        doc = {
          raw: []
        };
      }
      doc.raw = masterHierarchy;
      doc.lastUpdatedTime = new Date().getTime();
      return doc;
    });
    this.disk.updateOrInsert(DB_KEY_PREFIXES.SURGERY_CATEGORY_PRODUCT_POSITION, doc => {
      if (!doc || !doc.raw) {
        doc = {
          raw: []
        };
      }
      doc.raw = surgeryCategoryProductPositionRelationship;
      return doc;
    });
  }

  private updateOrInsertProductHierarchyForProcedureTracker(newProductHierarchies: SurgeryProductHierarchy[]) {
    this.upsertLocalProdHierarchyForProcedureTracker(newProductHierarchies);
    this.disk.updateOrInsert(DB_KEY_PREFIXES.PROCEDURE_TRACKER_SURGERY_PRODUCT_HIERARCHY, (doc) => {
      if (!doc || !doc.raw) {
        doc = {
          raw: []
        };
      }
      doc.raw = this.procedureTrackerProductHierarchies;
      doc.lastUpdatedTime = new Date().getTime();
      return doc;
    });
  }

  public aggregateProductHierarchies(rawData: any, surgerySKUProductRelationship, arrDeletedMappings, forProcedureTracker: boolean) {
    if (!_.isEmpty(rawData)) this.validateProductHierachyForRetiredProducts = true;
    let prodHierarchies: SurgeryProductHierarchy[] = [];
    rawData.map(a => {
      if (a && a['surgery.productid']) {
        let prodHr: SurgeryProductHierarchy;
        prodHr = prodHierarchies.find(o => o.surgeryId == a['surgery.productid'])
        if (!prodHr) {
          prodHr = new SurgeryProductHierarchy(a, surgerySKUProductRelationship);
          prodHierarchies.push(prodHr);
        }
        else {
          if (prodHr.buproductstatecode == undefined) prodHr.buproductstatecode = a['positiongroupProduct.statecode'];
          if (prodHr.surgeryName == '') prodHr.surgeryName = a['surgery.name'] || '';
          if (prodHr.surgeryStatecode == undefined) prodHr.surgeryStatecode = a['surgery.statecode'];
          if (prodHr.surgeryStatuscode == undefined) prodHr.surgeryStatuscode = a['surgery.statuscode'];
          let prodCategory = prodHr.productCategories.find(c => c.productCategoryId == a['categoryProduct.productid']);

          if (!_.isEmpty(prodHr.psoitiongroupProductIDs)) {
            // To avoid duplication,
            let businessID = a['positiongroupProduct.indskr_positiongroupproductid'];
            let index = prodHr.psoitiongroupProductIDs.findIndex(pgpid => businessID === pgpid);
            if (index <= -1) {
              prodHr.psoitiongroupProductIDs.push();
            }
          } else {
            prodHr.psoitiongroupProductIDs = [a['positiongroupProduct.indskr_positiongroupproductid']]
          }

          if (!_.isEmpty(prodHr.positionGroups)) {
            // To avoid duplication,
            let positiongroupid = a['indskr_positiongroupsid'];
            let index = prodHr.positionGroups.findIndex(pg => pg.positiongroupid === positiongroupid);
            let positiongroup = { positiongroupid: positiongroupid, statecode: a['statecode'], state: a['statecode@OData.Community.Display.V1.FormattedValue'] };

            if (index > -1) {
              prodHr.positionGroups[index] = positiongroup;
            } else {
              prodHr.positionGroups.push(positiongroup);
            }
          } else {
            prodHr.positionGroups = [{ positiongroupid: a['indskr_positiongroupsid'], statecode: a['statecode'], state: a['statecode@OData.Community.Display.V1.FormattedValue'] }]
          }

          if (!prodCategory) {

            let filteredRecords = surgerySKUProductRelationship.filter(o => o['productid'] === prodHr.surgeryId && o['skuProduct.productid'] === a['skuProduct.productid']);

            let product
            if (!_.isEmpty(filteredRecords)) {
              product = {
                productId: a['skuProduct.productid'] || '',
                productName: a['skuProduct.name'] || '',
                productStatecode: a['skuProduct.statecode'],
                uomid: a['skuProduct.defaultuomid'] || '',
                productCategoryId: a['categoryProduct.productid'] || '',
                productCategoryName: a['categoryProduct.name'] || '',
                productNumber: a['skuProduct.productnumber'] || '',
                indskr_newproductintroduction: a['skuProduct.indskr_newproductintroduction'] ? a['skuProduct.indskr_newproductintroduction'] : false
              }
            }

            prodHr.productCategories.push({
              productCategoryId: a['categoryProduct.productid'] || '',
              productCategoryName: a['categoryProduct.name'] || '',
              productCategoryStatecode: a['categoryProduct.statecode'],
              products: product ? [product] : [],
              indskr_newproductintroduction: a['categoryProduct.indskr_newproductintroduction'] ? a['categoryProduct.indskr_newproductintroduction'] : false
            });
          }
          else {
            let product = prodCategory.products.find(p => p.productId == a['skuProduct.productid']);
            let filteredRecords = surgerySKUProductRelationship.filter(o => o['productid'] === prodHr.surgeryId && o['skuProduct.productid'] === a['skuProduct.productid'])

            if (!product && !_.isEmpty(filteredRecords)) {
              prodCategory.products.push({
                productId: a['skuProduct.productid'] || '',
                productName: a['skuProduct.name'] || '',
                productStatecode: a['skuProduct.statecode'],
                uomid: a['skuProduct.defaultuomid'] || '',
                productCategoryId: a['categoryProduct.productid'] || '',
                productCategoryName: a['categoryProduct.name'] || '',
                productNumber: a['skuProduct.productnumber'] || '',
                indskr_newproductintroduction: a['skuProduct.indskr_newproductintroduction'] ? a['skuProduct.indskr_newproductintroduction'] : false
              })
            }
          }
          if (a['subProducts.productid']) {
            if (prodHr.subProducts) {
              prodHr.subProducts.push({
                productId: a['subProducts.productid'],
                productName: a['subProducts.name'],
              })
            } else {
              prodHr.subProducts = [{
                productId: a['subProducts.productid'],
                productName: a['subProducts.name'],
              }];
            }
          }
        }
        if (a.hasOwnProperty('track_action')) {
          prodHr.trackAction = a['track_action'];
        }
      }
    });

    // Iterate over deleted relationships and remove the
    this.updateTrackAction(arrDeletedMappings, forProcedureTracker ? this.procedureTrackerProductHierarchies : this.productHierarchies);
    this.updateTrackAction(arrDeletedMappings, prodHierarchies);
    this.updatePositiongroups(prodHierarchies, forProcedureTracker ? this.procedureTrackerProductHierarchies : this.productHierarchies);

    //Track Action Remove : 548910001
    let filteredHierarchies = forProcedureTracker ? this.procedureTrackerProductHierarchies.filter(prodHR => prodHR.trackAction === 548910001) : this.productHierarchies.filter(prodHR => prodHR.trackAction === 548910001)
    if (!_.isEmpty(filteredHierarchies)) prodHierarchies.concat(filteredHierarchies);

    return prodHierarchies;
  }

  private updatePositiongroups(prodHierarchies: SurgeryProductHierarchy[], existingProdHierarchies: SurgeryProductHierarchy[]) {

    prodHierarchies.forEach(ph => {
      let index = existingProdHierarchies.findIndex(p => p.surgeryId === ph.surgeryId);
      if (index > -1) {

        let productHierarchy = existingProdHierarchies[index];

        productHierarchy.positionGroups.forEach(pg => {

          let innerIndex = ph.positionGroups.findIndex(innerPg => innerPg.positiongroupid === pg.positiongroupid);
          if (innerIndex <= -1) {
            ph.positionGroups.push(pg)

          }
        });
      }
    });
  }

  private updateTrackAction(arrDeletedMappings: any, prodHierarchies: any) {
    if (!_.isEmpty(arrDeletedMappings)) {
      arrDeletedMappings.forEach(deletedMapping => {
        if (!_.isEmpty(prodHierarchies)) {
          prodHierarchies.forEach(prodHR => {
            let index = prodHR.psoitiongroupProductIDs.findIndex(id => id === deletedMapping['positiongroupproductID']);
            if (index > -1) {
              prodHR.psoitiongroupProductIDs.splice(index, 1);
            }
            if (_.isEmpty(prodHR.psoitiongroupProductIDs)) {
              prodHR.trackAction = deletedMapping['track_action'];
            }
          });
        }
      });
    }
  }

  upsertLocalProdHierarchy(newData) {
    newData.forEach(ph => {
      ph.productCategories.forEach(pc => {
        _.remove(pc.products, (prod: any) => prod && prod.productStatecode != 0);
      })
      _.remove(ph.productCategories, (prod: any) => {
        return (prod && (prod.productCategoryStatecode != 0 || prod.products.length < 1))
      });

      let prodIDX = this.productHierarchies.findIndex(p => p.surgeryId == ph.surgeryId)
      if (prodIDX >= 0) {
        this.productHierarchies[prodIDX] = ph;
      }
      else this.productHierarchies.push(ph);
    });

    _.remove(this.productHierarchies, o => {

      let activePostiongroups = o.positionGroups.filter(positiongroup => positiongroup.statecode === 0);

      return (this.authenticationService.user.buConfigs && !this.authenticationService.user.buConfigs['indskr_displayallprocedures'] ? (o.surgeryStatecode != 0 || o.surgeryStatuscode != 1) : (o.surgeryStatecode == 1 || o.surgeryStatecode == 3)
        || o.buproductstatecode != 0 || o.productCategories.length < 1
        || o.trackAction == 548910001 || _.isEmpty(activePostiongroups)
      )
    })
  }

  upsertLocalProdHierarchyForProcedureTracker(newData) {
    newData.forEach(ph => {
      ph.productCategories.forEach(pc => {
        _.remove(pc.products, (prod: any) => prod && prod.productStatecode != 0);
      })
      _.remove(ph.productCategories, (prod: any) => {
        return (prod && (prod.productCategoryStatecode != 0 || prod.products.length < 1))
      });

      let prodIDX = this.procedureTrackerProductHierarchies.findIndex(p => p.surgeryId == ph.surgeryId)
      if (prodIDX >= 0) {
        this.procedureTrackerProductHierarchies[prodIDX] = ph;
      }
      else this.procedureTrackerProductHierarchies.push(ph);
    });

    _.remove(this.procedureTrackerProductHierarchies, o => {

      let activePostiongroups = o.positionGroups.filter(positiongroup => positiongroup.statecode === 0);

      return (this.authenticationService.user.buConfigs && !this.authenticationService.user.buConfigs['indskr_displayallprocedures'] ? (o.surgeryStatecode != 0 || o.surgeryStatuscode != 1) : (o.surgeryStatecode == 1 || o.surgeryStatecode == 3)
        || o.buproductstatecode != 0
        || o.trackAction == 548910001 || _.isEmpty(activePostiongroups)
      )
    })
  }

  async fetchSurgeryCategoryProductPositionRelationship() {
    let relationship;

    try {
      let fetchXML = this.authenticationService.user.buConfigs && this.authenticationService.user.buConfigs['indskr_accessallproductcategoryandproduct'] ? fetchQueries.surgeryOrders.allSurgeryCategoryProductPositionRelationship : fetchQueries.surgeryOrders.surgeryCategoryProductPositionRelationship;
      fetchXML = fetchXML.replace('{UserID}', this.authenticationService.user.systemUserID);
      const positionFilterValueList: string[] = this.authenticationService.user.positions
        .map(p => `<value>${p.ID}</value>`);
      if (positionFilterValueList?.length > 0) {
        fetchXML = fetchXML.replace('{positionIds}', positionFilterValueList.join(''));
        relationship = await this.dynamics.executeFetchQuery('products', fetchXML);
      }
    } catch (error) {
      console.error('fetchSurgeryCategoryProductPositionRelationship: ', error);
    }

    return relationship;
  }

  async fetchSurgerySKUProductRelationship(lastModifiedDate) {
    if(!lastModifiedDate && this.authenticationService.user.buConfigs['indskr_accessallproductcategoryandproduct']){
      return [];
    }
    let hourDifference;
    let now = new Date();
    let surgerySKUProductRelationship;
    let fetchXML = fetchQueries.surgeryOrders.surgerySKUProductRelationship;
    fetchXML = fetchXML.replace('{UserID}', this.authenticationService.user.systemUserID);

    let deltaSyncFilter = `<filter type="and">
      <condition attribute="productstructure" operator="eq" value="3" />
    </filter>`
    if (lastModifiedDate) {
      hourDifference = differenceInHours(
        now,
        new Date(lastModifiedDate)
      )
      hourDifference += 1
      deltaSyncFilter = `<filter type="and">
      <condition attribute="productstructure" operator="eq" value="3" />
      <condition attribute="modifiedon" operator="last-x-hours" value="${hourDifference}" />
    </filter>`
    }

    fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter);

    //add one to make sure we take care of fractional difference in hours

    await this.dynamics.executeFetchQuery('products', fetchXML).then((data) => {
      console.log('Surgery SKU product Relationship', data);
      surgerySKUProductRelationship = data;
    })
    return surgerySKUProductRelationship;
  }

  async fetchDeletedPositionGroupProductMapping(lastModifiedDate) {
    let hourDifference;
    let now = new Date();
    let deletedRecords;
    let fetchXML = fetchQueries.surgeryOrders.fetchDeletedPositiongroupProductMapping;
    hourDifference = differenceInHours(
      now,
      new Date(lastModifiedDate)
    )
    //add one to make sure we take care of fractional difference in hours
    hourDifference += 1
    fetchXML = fetchXML.replace('{hourDifference}', hourDifference);
    await this.dynamics.executeFetchQuery('indskr_trackchanges', fetchXML).then((data) => {
      console.log('deleted bu products', data);
      deletedRecords = data;
    })
    return deletedRecords;
  }

  async fetchRetiredBundleCategoryAndSKUProducts(fullSync, loadFromDBOnly = false) {

    let now = new Date();
    let retiredProducts;
    let fetchXML = fetchQueries.surgeryOrders.retiredRevisedBundleCategorySKUProduct;
    let lastModifiedForDeltaSync, hourDifference;

    await this.disk.retrieve(DB_KEY_PREFIXES.SURGERY_PRODUCT_HIERARCHY).then((doc) => {
      if (doc && doc.raw && doc.raw.length) {
        this.productHierarchies = doc.raw;
        lastModifiedForDeltaSync = doc.lastUpdatedTime;
      }
      else {
        this.productHierarchies = [];
        fullSync = true;
      }
    });

    hourDifference = differenceInHours(
      now,
      new Date(lastModifiedForDeltaSync)
    )
    //add one to make sure we take care of fractional difference in hours
    if (lastModifiedForDeltaSync) {
      hourDifference += 1
      fetchXML = fetchXML.replace('{hourDifference}', hourDifference);
      await this.dynamics.executeFetchQuery('products', fetchXML).then((data) => {
        console.log('deleted bu products', data);
        retiredProducts = data;
      });

      this.disk.updateOrInsert(DB_KEY_PREFIXES.SURGERY_RETIRED_PRODUCTS, (doc) => {
        if (!doc || !doc.raw) {
          doc = {
            raw: []
          };
        }
        doc.raw = retiredProducts;
        doc.lastUpdatedTime = new Date().getTime();
        return doc;
      });
    }

  }

  async purgeData(maxEndDateUnixTimestamp: number) {
    await Promise.all([
      this.purgeProcedureLogs(maxEndDateUnixTimestamp),
    ]);
  }

  private async purgeProcedureLogs(maxEndDateUnixTimestamp: number) {
    let option = {
      selector: {
        '_id': {
          $gte: DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY,
          $lte: DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY + PREFIX_SEARCH_ENDKEY_UNICODE
        },
      }
    };

    try {
      const rawProcedureLogs = await this.disk.find(option);

      if (Array.isArray(rawProcedureLogs)) {
        const { from } = this.authenticationService.getFromToDateRangeInUTCMiliSec(undefined);
        const localDataStartDate: number = Number(this.getProcedureLogDataStartDate(from));
        if (!isNaN(localDataStartDate)) {
          const deletedRawProcedureLogs = [];
          for (let i = 0; i < rawProcedureLogs.length; i++) {
            const rawProcedureLog = rawProcedureLogs[i];
            const scheduedStart: number = Number(rawProcedureLog.indskr_scheduleddate);
            if (isNaN(scheduedStart) || (!isNaN(scheduedStart) && scheduedStart < localDataStartDate) && rawProcedureLog._id && rawProcedureLog._rev) {
              const tempProcedureLog = { ID: rawProcedureLog.salesorderid, type: ActivityType.SurgeryOrder };
              this.activityOfflineService.removeActivity(tempProcedureLog as SurgeryOrderActivity, false, null);
              deletedRawProcedureLogs.push({
                _id: rawProcedureLog._id,
                _rev: rawProcedureLog._rev,
                _deleted: true
              });
            }
          }

          if (deletedRawProcedureLogs.length > 0) {
            await this.disk.bulk(deletedRawProcedureLogs);
          }
        }
      }
    } catch (error) {
      console.error('purgeProcedureLogs: ', error);
    }
  }

  // async validateRetiredProducts() {
  //   if (this.validateProductHierachyForRetiredProducts) {
  //     let retiredProducts
  //     await this.disk.retrieve(DB_KEY_PREFIXES.SURGERY_RETIRED_PRODUCTS).then((doc) => {
  //       if (doc && doc.raw && !_.isEmpty(doc.raw)) {
  //         retiredProducts = doc.raw;
  //       }

  //       if (retiredProducts && !_.isEmpty(retiredProducts)) {
  //         retiredProducts.forEach(aRetiredProduct => {
  //           if (aRetiredProduct['productstructure'] === 3) {
  //             let index = this.productHierarchies.findIndex(ph => ph.surgeryId === aRetiredProduct['productid'])
  //             if (index > -1) {
  //               let productBundle = this.productHierarchies[index];
  //               productBundle.trackAction = 548910001;
  //               productBundle.surgeryStatecode = aRetiredProduct['statecode'];
  //               productBundle.surgeryStatuscode = aRetiredProduct['statuscode'];
  //               this.productHierarchies[index] = productBundle;

  //             }
  //           } else {
  //             this.productHierarchies.forEach(ph => {
  //               if (ph.trackAction !== 548910001) {
  //                 if (aRetiredProduct['indskr_producttype'] === 548910000) { //Category product
  //                   let index = ph.productCategories.findIndex(pc => pc.productCategoryId === aRetiredProduct['productid']);
  //                   if (index > -1) ph.productCategories[index].productCategoryStatecode = aRetiredProduct['statecode'];
  //                 } else if (aRetiredProduct['indskr_producttype'] === 100000003) {
  //                   // SKU Product
  //                   ph.productCategories.forEach(pc => {
  //                     pc.products.forEach(product => {
  //                       let index = pc.products.findIndex(sku => sku.productId === aRetiredProduct['productid']);
  //                       if (index > -1) pc.products[index].productStatecode = aRetiredProduct['statecode'];
  //                     });
  //                   });
  //                 }
  //               }
  //             });
  //           }
  //         });

  //         this.updateOrInsertProductHierarchy(this.productHierarchies);
  //       }

  //     });
  //     this.validateProductHierachyForRetiredProducts = false;
  //   }
  // }

  private _getAttributeFetchXMLFromElements(elements){
    let attributes = '';
    elements.forEach((element) => {
      if (element.metadata && element.metadata.logicalName) {
        attributes += `<attribute name="${element.metadata.logicalName}"/>`;
      }
      if(element.type == 'panel' && element.elements && element.elements.length > 0){
        attributes += this._getAttributeFetchXMLFromElements(element.elements);
      }
    });
    return attributes;
  }

  public async fetchSurveyJSAtrributesForProcedure(procedureLog: SurgeryOrderActivity, template: any) {
    if (template.surveyConfiguration) {
      let fetchXML = fetchQueries.surgeryOrders.fetchConfiguredAttributesForSurveyJSInfo;
      fetchXML = fetchXML.replace('{salesOrderId}', procedureLog.ID);
      let attributes: string = '';
      template.surveyConfiguration.pages[0].elements.forEach((element) => {
        if (element.metadata && element.metadata.logicalName) {
          attributes += `<attribute name="${element.metadata.logicalName}"/>`;
        }
        if(element.type == 'panel' && element.elements && element.elements.length > 0){
          const attr = this._getAttributeFetchXMLFromElements(element.elements);
          if(attr){
            attributes+= attr;
          }
        }
      });
      fetchXML = fetchXML.replace('{ATTRIBUTES}', attributes);
      const response = await this.dynamics.executeFetchQuery(
        'salesorders',
        fetchXML
      );
      if (response && Array.isArray(response)) {
        procedureLog.surveyResponse = [];
        procedureLog.surveyResponseData = {};
        procedureLog.surveyResponseAdditionalData = {};
        procedureLog.surveyResponseLookupData = [];
        // procedureLog.surveyResponseDTO = {
        //   "lookupfields": [],
        // };
        template.surveyConfiguration.pages[0].elements.forEach((element) => {
          if(element.metadata){
            this._updateSurveyResponseForElement(element,procedureLog,response);
          }else if(element.type == 'panel' && element.elements && element.elements.length > 0){
            this._updateSurveyResponseForElements(element.elements,procedureLog,response);
          }
        });
      }
    }
  }

  private _updateSurveyResponseForElements(elements,procedureLog,response){
    elements.forEach((element) => {
      if(element.metadata){
        this._updateSurveyResponseForElement(element,procedureLog,response);
      }else if(element.type == 'panel' && element.elements && element.elements.length > 0){
        this._updateSurveyResponseForElements(element.elements,procedureLog,response);
      }
    });
  }

  private _updateSurveyResponseForElement(element,procedureLog,response){
    if (element.metadata) {
      let answer: string = '';
      if (element.type == "lookup" && response[0].hasOwnProperty(`_${element.metadata.logicalName}_value@OData.Community.Display.V1.FormattedValue`)) {
        answer = response[0][`_${element.metadata.logicalName}_value@OData.Community.Display.V1.FormattedValue`];
        procedureLog.surveyResponseAdditionalData[`${element.metadata.schemaName}@odata.bind`] = `/${element.metadata.target.setName}(${response[0][`_${element.metadata.logicalName}_value`]})`;
        procedureLog.surveyResponseLookupData.push({
          id: response[0][`_${element.metadata.logicalName}_value`],
          name: response[0][`_${element.metadata.logicalName}_value@OData.Community.Display.V1.FormattedValue`],
          targetEntity: element.metadata.target.name,
          questionName: element.name,
        });
        // procedureLog.surveyResponseDTO['lookupfields'].push({
        //   name: element.metadata.schemaName,
        //   entity: element.metadata.target.setName,
        //   id: response[0][`_${element.metadata.logicalName}_value`],
        // });
        procedureLog.surveyResponseData[element.name] = response[0][`_${element.metadata.logicalName}_value`];
      } else if (response[0].hasOwnProperty(element.metadata.logicalName)) {
        // if(response[0].hasOwnProperty(element.metadata.logicalName+`@OData.Community.Display.V1.FormattedValue`)){
        //   answer = response[0][element.metadata.logicalName+`@OData.Community.Display.V1.FormattedValue`];
        // }else{
        //   answer = response[0][element.metadata.logicalName];
        // }
        answer = response[0][element.metadata.logicalName];
        if (element.inputType == "date") {
          answer = format(new Date(answer), 'YYYY-MM-DD');
        }
        //procedureLog.surveyResponseDTO[element.metadata.logicalName] = answer;
        procedureLog.surveyResponseData[element.name] = answer;
      }
      procedureLog.surveyResponse.push({
        indskr_question: element.title,
        indskr_answer: answer,
      })
    }
  }

  async validateAssetInUse(selectedAssetIds, startDate, endDate, orderId): Promise<any> {
    try {
      let assetIdString = '';
      let assetsInUseIds = [];
      let fetchXml = fetchQueries.salesOrders.validateAssetAssociation;
      fetchXml = fetchXml.replace('{startDate}', format(new Date(startDate), 'YYYY-MM-DD'));
      fetchXml = fetchXml.replace('{endDate}', format(new Date(endDate), 'YYYY-MM-DD'));

      if (!orderId.includes('offline')) {
        fetchXml = fetchXml.replace('{orderIdCondition}', `<condition attribute="salesorderid" operator="ne" value="${orderId}"/>`);
      } else {
        fetchXml = fetchXml.replace('{orderIdCondition}', '');
      }

      selectedAssetIds.forEach(p => {
        assetIdString += '<value>' + p + '</value>'
      });

      fetchXml = fetchXml.split('{assetIds}').join(assetIdString);
      await this.uiService.displayLoader();
      const assetsInUse = await this.dynamics.executeFetchQuery('salesorders', fetchXml);
      if (assetsInUse.length > 0) {
        assetsInUseIds = assetsInUse.map((asset) => asset['am.msdyn_customerassetid']);
      }
      this.uiService.dismissLoader();
      return assetsInUseIds;
    } catch (error) {
      return selectedAssetIds;
    }
  }

  async removeAssetInUse(newOfflineOrder) {
    try {
      const { assets, scheduledStart, scheduledEnd, ID } = newOfflineOrder;
      const selectedAssetIds = assets.map((selectedAsset) => selectedAsset.msdyn_customerassetid);
      const assetInUseIds = await this.validateAssetInUse(selectedAssetIds, scheduledStart, scheduledEnd, ID);
      newOfflineOrder.assets = assets.filter((selectedAsset) => !assetInUseIds.includes(selectedAsset.msdyn_customerassetid));
      if (assetInUseIds.length > 0) {
        this.updateOrderActivity({
          onDynamics: false,
          onLocalCopy: true,
          onLocalDatabase: true
        }, [newOfflineOrder], new Date().getTime(), false);
        this.addAssetInUseNotification(newOfflineOrder)
      }
      return;
    } catch (error) {
      console.log("Remove asset in use failed")
    }
  }

  async addAssetInUseNotification(order: SurgeryOrderActivity) {
    const notification = {
      type: NOTIFICATION.ASSET_REMOVED,
      name: this.translate.instant("ASSET_REMOVED_NOTIFICATION"),
      DateTime: Date.now(),
      id: NOTIFICATION.ADJUSTMENT_REJECTED + Date.now(),
      data: { data: this.translate.instant("ASSET_IN_USE_NOTIFICATION", { procedure: order.subject }) },
      icon: 'assets/imgs/order_open.svg',
      isRed: false,
      params: {}
    };

    this.myAssistantService.saveNotificationToDisk(notification);
    await this.myAssistantService.loadAndMapNotificationsFromDB();
  }

  public async getActivityOutcomes(loadFromDBOnly: boolean = false) {
    if (loadFromDBOnly) {
      await this.disk.retrieve(DB_KEY_PREFIXES.ACTIVITY_OUTCOMES, true).then((doc) => {
        if (doc && doc.raw) {
          this.activityOutcomes = doc.raw
        }
        else {
          this.activityOutcomes = [];
        }
      })
      return;
    }
    try {
      let fetchXML: string = fetchQueries.surgeryOrders.fetchActivityOutcomes;
      fetchXML = fetchXML.replace('{SystemUserId}', this.authenticationService.user.systemUserID);
      let res = await this.dynamics.executeFetchQuery(
        'indskr_activityoutcomes',
        fetchXML
      );
      this.activityOutcomes = [];
      if (res && Array.isArray(res)) {
        res.forEach(item => {
          this.activityOutcomes.push(new ActivityOutcome(item))
        })
      }
      this.disk.updateOrInsert(DB_KEY_PREFIXES.ACTIVITY_OUTCOMES, (doc) => {
        doc = {
          raw: []
        };
        doc.raw = this.activityOutcomes;
        return doc;
      })
    } catch (error) {
      console.log('activity outcomes fetch failed', error)
    }
  }

  public async fetchProceduerTypes(loadFromDBOnly) {
    if (!this.authenticationService.user.buConfigs['indskr_proceduretype']) return;
    try {
      if (loadFromDBOnly) {
        this.procedureTypes = await this.loadProcedureTypesFromLocalDB();
        return;
      }

      const fetchXML = await fetchQueries.salesOrders.fetchProcedureTypes;
      await this.dynamics.executeFetchQuery('indskr_proceduretypes', fetchXML).then((response) => {
        if (response && response.length > 0) {
          let ptypes = [];
          response.forEach(procedureType => {
            const index = ptypes.findIndex((r) => r.indskr_proceduretypeid === procedureType.indskr_proceduretypeid);
            if (index >= 0) {
              ptypes[index].procedureSubtypes.push(procedureType["ah.indskr_proceduresubtypeid"]);
            } else {
              ptypes.push(new ProcedureType(procedureType));
            }
          });
          // const ptypes = response.map((r) => new ProcedureType(r));


          this.saveProcedureTypesInLocalDB(ptypes);
          this.procedureTypes = ptypes;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  private async saveProcedureTypesInLocalDB(procedureTypes: ProcedureType[]) {
    await this.disk.updateOrInsert(DB_KEY_PREFIXES.PROCEDURE_TYPES, (doc) => {
      doc = {
        raw: [],
        lastModified: doc.lastModified
      };
      doc.raw = procedureTypes;
      return doc;
    });
  }

  public async loadProcedureTypesFromLocalDB() {
    let offlineDataStored;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.PROCEDURE_TYPES, true).then((doc) => {
        offlineDataStored = doc?.raw ? doc.raw : [];
      });
    }
    catch (er) {
      console.error("Failed to load procedure types from local db!: ", er)
      offlineDataStored = [];
    }
    return offlineDataStored;
  }

  public async fetchProcedureSubTypes(loadFromDBOnly) {
    if (!this.authenticationService.user.buConfigs['indskr_proceduresubtype']) return;

    if (loadFromDBOnly) {
      this.procedureSubTypes = await this.loadProcedureSubTypesFromLocalDB();
      return;
    }

    const fetchXML = await fetchQueries.salesOrders.fetchProcedureSubTypes;
    await this.dynamics.executeFetchQuery('indskr_proceduresubtypes', fetchXML).then((response) => {
      if (response && response.length > 0) {
        const procedureSubTypes = response.map((r) => new ProcedureSubType(r));
        this.saveProcedureSubTypesInLocalDB(procedureSubTypes);
        this.procedureSubTypes = procedureSubTypes;
      }
    });
  }

  private async saveProcedureSubTypesInLocalDB(ProcedureSubTypes: ProcedureType[]) {
    await this.disk.updateOrInsert(DB_KEY_PREFIXES.PROCEDURE_SUB_TYPES, (doc) => {
      doc = {
        raw: [],
        lastModified: doc.lastModified
      };
      doc.raw = ProcedureSubTypes;
      return doc;
    });
  }

  public async loadProcedureSubTypesFromLocalDB() {
    let offlineDataStored;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.PROCEDURE_SUB_TYPES, true).then((doc) => {
        offlineDataStored = doc?.raw ? doc.raw : [];
      });
    }
    catch (er) {
      console.error("Failed to load procedure sub types from local db!: ", er)
      offlineDataStored = [];
    }
    return offlineDataStored;
  }

  // ? not all procedure type has relation with procedure contract, it is based on configuration
  public shouldShowProcedureContract(surgeryOrder: SurgeryOrderActivity) {
    if (!surgeryOrder.indskr_proceduretype) return;
    const selectedProcedureType = this.findProcedureTypeById(surgeryOrder.indskr_proceduretype);
    if (!selectedProcedureType) return;
    return selectedProcedureType.indskr_isprocedurecontractenabled;
  }

  findProcedureTypeById(procedureTypeId) {
    return this.procedureTypes.find((type) => type.indskr_proceduretypeid === procedureTypeId);
  }

  findShoreiMasterDataById(procedureTypeId) {
    return this.shoreiMasterData.find((type) => type.jnjjapan_shorei === procedureTypeId);
  }

  findProcedureSubTypeById(procedureSubTypeId) {
    return this.procedureSubTypes.find((type) => type.indskr_proceduresubtypeid === procedureSubTypeId);
  }

  findProcedureTypesByContractTypeId(contractId) {
    const filteredProcedureTypes = this.procedureTypes.filter((type: ProcedureType) => type.indskr_contracttype === contractId);
    return filteredProcedureTypes;
  }

  findContractTypeByProcedureTypeId(procedureTypeId) {
    const procedureType = this.findProcedureTypeById(procedureTypeId);
    if (!procedureType) return null;
    const contractType = this.contractService.findContractTypeById(procedureType.indskr_contracttype);
    if (!contractType) return null;
    return contractType;
  }

  // ? this method will return the list of procedureSubtypes which is associated with procedure type
  public showAssociatedProcedureSubTypes(contracTypetId) {
    const procedureTypes = this.findProcedureTypesByContractTypeId(contracTypetId);
    if (!procedureTypes) return [];
    const assocaitedProcedureSubtypeIds = procedureTypes.map(procedureType =>  procedureType.procedureSubtypes).flat();
    if (!assocaitedProcedureSubtypeIds.length) return [];
    return this.procedureSubTypes.filter((proceudreSubType) => assocaitedProcedureSubtypeIds.includes(proceudreSubType.indskr_proceduresubtypeid));
  }

  public findAssociatedProcedureSubtypes(procedureTypeId) {
    const procedureType: ProcedureType = this.findProcedureTypeById(procedureTypeId);
    if (!procedureType) return [];
    const assocaitedProcedureSubtypeIds = procedureType.procedureSubtypes;
    if (!assocaitedProcedureSubtypeIds.length) return [];
    return this.procedureSubTypes.filter((proceudreSubType) => assocaitedProcedureSubtypeIds.includes(proceudreSubType.indskr_proceduresubtypeid));
  }

  // Implemented to resolve performance issue while fetching large number of procedures data records

  private _doProductHierarchyInitialSync() {
    let fetchXML = this.authenticationService.user.buConfigs['indskr_accessallproductcategoryandproduct'] ? fetchQueries.surgeryOrders.fetchSurgeryAndCategoryRelationship : fetchQueries.surgeryOrders.fetchProductHierarchy;
    let secondFetchXML = fetchQueries.surgeryOrders.fetchCategoryAndSKUProductRelationship;
    let deltaSyncFilter = `<filter type="and">
                                <condition attribute="indskr_type" operator="eq" value="548910000" />
                              </filter>`
    fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter);
    secondFetchXML = secondFetchXML.replace('{deltaSyncFilter}', deltaSyncFilter);

    fetchXML = fetchXML.replace('{UserID}', this.authenticationService.user.systemUserID);
    fetchXML = fetchXML.replace('{userBU}', this.authenticationService.user.xBusinessUnitId);

    secondFetchXML = secondFetchXML.replace('{UserID}', this.authenticationService.user.systemUserID);
    secondFetchXML = secondFetchXML.replace('{userBU}', this.authenticationService.user.xBusinessUnitId);

    this._isProcedureProductHierarchyDataMappingInProgress = true;
    Promise.all([
      this.dynamics.executeFetchQuery('indskr_positiongroupses', fetchXML),
      this.fetchSurgeryCategoryProductPositionRelationship(),
      this.fetchSurgerySKUProductRelationship(null),
      this.authenticationService.user.buConfigs['indskr_accessallproductcategoryandproduct'] ? this.dynamics.executeFetchQuery('indskr_positiongroupses',secondFetchXML) : []
    ]).then((response) => {
      if (response && Array.isArray(response)) {
        response.push({indskr_accessallproductcategoryandproduct: this.authenticationService.user.buConfigs['indskr_accessallproductcategoryandproduct']})
        this._postMessageOnProductHierarchyInitialSyncServiceWorker({ type: 'aggregateProductHierarchies', rawResponse: response });
      }
    }).catch(err => {
      console.log('Error while fetching product hierarchy initial sync data' + err);
    });
  }

  private async _doProductHierarchySecondFetchXMLInitialSync(newProductHierarchies) {
    let secondFetchXML = fetchQueries.surgeryOrders.fetchProductHierarchySecond;
    let deltaSyncFilter = `<filter type="and">
                                <condition attribute="indskr_type" operator="eq" value="548910000" />
                              </filter>`;
    if (secondFetchXML) {
      secondFetchXML = secondFetchXML.replace('{deltaSyncFilter1}', deltaSyncFilter);
      secondFetchXML = secondFetchXML.replace('{deltaSyncFilter2}', '');
      secondFetchXML = secondFetchXML.replace('{UserID}', this.authenticationService.user.systemUserID);
      secondFetchXML = secondFetchXML.replace('{userBU}', this.authenticationService.user.xBusinessUnitId);
    }
    await this.dynamics.executeFetchQuery('indskr_positiongroupses', secondFetchXML).then(async (subProdData) => {
      if (subProdData && Array.isArray(subProdData)) {
        let checkIds: Array<string> = [];
        subProdData.forEach(record => {
          if (record['surgery.productid']) {
            let currentObj = newProductHierarchies;
            let idx = currentObj.findIndex(a => a.surgeryId == record['surgery.productid']);
            if (idx >= 0) {
              if (!checkIds.some(a => a == record['surgery.productid'])) {
                currentObj[idx].subProducts = [];
                checkIds.push(record['surgery.productid']);
              }
              if (record['subProducts.productid']) {
                if (currentObj[idx].subProducts) {
                  currentObj[idx].subProducts.push({
                    productId: record['subProducts.productid'],
                    productName: record['subProducts.name'],
                  })
                } else {
                  currentObj[idx].subProducts = [{
                    productId: record['subProducts.productid'],
                    productName: record['subProducts.name'],
                  }];
                }
              }
            }
          }
        });

      }
    }, (errror) => {
      console.log(errror)
    })
  }

  private _initProductHierarchyInitialSyncServiceWorker() {
    this._productHierarchyInitialSyncServiceWorker = new Worker('./assets/workers/product-hierarchy-worker.js');
    let hideProcedure = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_hideprocedureproductcategory'];
    this._productHierarchyInitialSyncServiceWorker.onmessage = (event) => {
      this.ngZone.run(async () => {
        if (event && event.data) {
          console.timeEnd('ProductHierarchyWorker');
          await this._doProductHierarchySecondFetchXMLInitialSync(event.data.prodHierarchies);
          this.updateOrInsertProductHierarchy(event.data.prodHierarchies, event.data.surgeryCategoryProductPositionRelationship);
          this._isProcedureProductHierarchyDataMappingInProgress = false;
          if (this._checkedForProcedureProductMapping && !hideProcedure) {
            this.notificationService.notify('Procedure Log Products Data Ready Now', 'SurgeryOrderDetailsComponent', "top");
          }
          this._checkedForProcedureProductMapping = false;
        }
      })
    };
  }

  private _postMessageOnProductHierarchyInitialSyncServiceWorker(data) {
    console.time('ProductHierarchyWorker');
    this._productHierarchyInitialSyncServiceWorker.postMessage(data);
  }

  private handleContractScenarios(serviceDTO, data, response) {
    if(response.indskr_noofassistanceavailed == undefined && response.indskr_maximumnoofassistance_parent == undefined) return;

    const contractType = this.findContractTypeByProcedureTypeId(data.indskr_proceduretype);
    if (serviceDTO.indskr_procedurecontract) {
      const procedureContract = this.contractService.findProcedureContractById(data.indskr_procedurecontract);

      if (procedureContract && contractType && contractType.indskr_allowchildcontractcreation) {
        this.contractService.updatePaidMultipleDayContract(procedureContract.indskr_procedurecontractid, response, contractType.indskr_procedurelogassociation)
      }

      if (procedureContract && contractType && contractType.indskr_usagetype === PROCEDURE_CONTRACT_TYPES.FREE_CONTRACT) {
        if (response.indskr_maximumnoofassistance) {
          this.contractService.updateProcedureContract(procedureContract.indskr_procedurecontractid, {
            indskr_noofassistanceavailed: response.indskr_noofassistanceavailed
          });
        }
      }
    } else {
      if (contractType && contractType && contractType.indskr_allowchildcontractcreation) {
        this.contractService.updatePaidMultipleDayContract(this.contractService.contractTempid, response, contractType.indskr_procedurelogassociation)
      } else {
        if (response.indskr_maximumnoofassistance) {
          this.contractService.updateProcedureContract(this.contractService.contractTempid, {
            indskr_noofassistanceavailed: response.indskr_noofassistanceavailed
          });
        }
      }
    }
  }

  private async handleContractInScrap(procedureLog) {
    const contractType = this.findContractTypeByProcedureTypeId(procedureLog.indskr_proceduretype);
    const contract = this.contractService.findProcedureContractById(procedureLog.indskr_procedurecontract);
    if (contractType && contractType.indskr_usagetype === PROCEDURE_CONTRACT_TYPES.FREE_CONTRACT) {
      this.contractService.updateProcedureContract(procedureLog.indskr_procedurecontract, {
        indskr_noofassistanceavailed: contract.indskr_noofassistanceavailed - 1
      })
    } else if (contractType && contractType.indskr_allowchildcontractcreation) {
      const month = new Date(procedureLog.scheduledStart).getMonth();
      const year = new Date(procedureLog.scheduledStart).getFullYear();
      const childContract = this.contractService.getChildContractByMonthAndYear(month, year, procedureLog.indskr_procedurecontract);
      if (childContract) {
        let procedureLogs = await this.contractService.getSurgeryInfoAssociatedWithContract(childContract.indskr_parentprocedurecontractid, contractType.indskr_allowduplicatechildcontractcreation, new Date(childContract.indskr_startdate), new Date(childContract.indskr_enddate));
        if (procedureLogs.length > 0) {
          const scrappedProcedureLogDate = new Date(procedureLog.scheduledStart);
          scrappedProcedureLogDate.setHours(0, 0, 0, 0);
          let procedureLogExistForthesamedate = procedureLogs.some((prl) => {
            const prlDate = new Date(prl.indskr_scheduleddate);
            prlDate.setHours(0, 0, 0, 0);
            if (scrappedProcedureLogDate.getTime() === prlDate.getTime()) return true;
          });
          if (!procedureLogExistForthesamedate) {
            this.contractService.updateProcedureContract(procedureLog.indskr_procedurecontract, {
              indskr_noofassistanceavailed: contract.indskr_noofassistanceavailed - 1
            });
            this.contractService.updateProcedureContract(childContract.indskr_procedurecontractid, {
              indskr_noofassistanceavailed: childContract.indskr_noofassistanceavailed - 1
            });
          }
        } else {
          this.contractService.updateProcedureContract(procedureLog.indskr_procedurecontract, {
            indskr_noofassistanceavailed: contract.indskr_noofassistanceavailed - 1
          });
          this.contractService.updateProcedureContract(childContract.indskr_procedurecontractid, {
            indskr_noofassistanceavailed: childContract.indskr_noofassistanceavailed - 1
          });
        }
      }
    }
  }

  public findSurgeryOrderByDate(date) {
    const unFilteredSurgeryOrder = this.activityOfflineService.activities.filter((activty) => activty.type === ActivityType.SurgeryOrder);
    const startDate = this.dateService.formatDateForWithStartTime(date);
    const surgeryOrders = unFilteredSurgeryOrder.filter((surgeryOrder) => {
      return (this.dateService.formatDateForWithStartTime(surgeryOrder.startDate).getTime() === startDate.getTime())
    });
    return surgeryOrders;
  }

  public async showCancelSurgeryOrderPopover(event) {
    let altCancelText: string = this.languageService.selectedLanguage.code == "ja"  ? this.translate.instant('CANCEL_BTN_ALERT') : this.translate.instant('CANCEL');
    let popoverOptions = {
      header: this.translate.instant('CANCEL_SURGERY_ORDER'),
      message: this.translate.instant('CANCEL_SURGEY_ORDER_SUB_TEXT'),
      inputs: this.fetchCancellationReasonsScrap(),
      cancelText: altCancelText,
      confirmText: this.languageService.selectedLanguage.code == "ja" ? this.translate.instant('CONFIRM_BTN_ALERT') : this.translate.instant('CONFIRM'),
      Placeholder: this.translate.instant('ENTER_DESCRIPTION'),
      enableMultiSelect: false,
      isMandatory: true
    }

    let cancellationPopover = await this.popoverCtrl.create({
      component: AlertWithInput,
      componentProps: popoverOptions,
      cssClass: this.deviceService.isNativeApp ? 'native-alert-with-input-list-view' : 'alert-with-input-list-view',
      backdropDismiss: true,
      event: this.deviceService.isNativeApp ? ((event && event.target && event.target.getBoundingClientRect) ? event : undefined) : undefined,
    });

    cancellationPopover.present();

    const { data } = await cancellationPopover.onDidDismiss();
    return data
  }

  private fetchCancellationReasonsScrap() {
    if (_.isEmpty(this.activityOfflineService.activityCancellationResons)) return [];
    return this.activityOfflineService.activityCancellationResons.filter((reason: ActivityCancellationReson) => reason.indskr_activitytype === 548910002).map((reason: ActivityCancellationReson) => {
      return {
        type: 'radio',
        name: reason.indskr_reason,
        label: reason.indskr_reason,
        value: reason.reasonId,
        isSelected: false,
      }
    })
  }

  public async handleHeaderFooterBtnClick(buttonId: string, activity: SurgeryOrderActivity, backgroundUploadInProgress = false) {
    let popupTitle = '';
    let popupMessage = '';
    let popupAction = '';
    let cancetButtonText = ''
    if (buttonId != 'close' && backgroundUploadInProgress) return;
    const isCancelEnabled = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_cancelprocedurelogwithreason'];

    switch (buttonId) {
      case 'scrapsurgeryorderactivity': {
        popupTitle = this.translate.instant('OM_SCRAP_LOG');
        popupMessage = this.translate.instant('R_U_SURE_SCRAP_SO');
        popupAction = this.translate.instant('SCRAP');

        if (isCancelEnabled) {
          popupTitle = this.translate.instant('DELETE_OR_CANCEL');
          popupMessage = this.translate.instant('DELETE_OR_CANCEL_PROCEDURE_LOG');
          popupAction = this.translate.instant('DELETE_PROCEDURE_LOG_BTN_TEXT');
          cancetButtonText = this.translate.instant('CANCEL_PROECEUDURE_LOG_BTN_TEXT')
        }
        break;
      }
      case 'markcomplete': {
        popupTitle = this.translate.instant('ACTIVITY_COMPLETE_LOG');
        popupMessage = this.translate.instant('OM_ARE_SURE_COMPLETE_PL');
        popupAction = this.translate.instant('COMPLETE');
        break;
      }
      case 'reopensurgeryactivity': {
        popupTitle = this.translate.instant('REOPEN_PROCDURE_LOG');
        popupMessage = this.translate.instant('OM_ARE_SURE_REOPEN_PL');
        popupAction = this.translate.instant('REOPEN');
        break;
      }
      default:
        return;
    }

    if (activity) {
      return await this.alertService.showAlert({
        title: popupTitle,
        message: popupMessage,
      }, popupAction, cancetButtonText
      ).then(res => {
        return res;
      });
    }
  }

  public isReopenButtonEnabled(selectedSurgeryOrder: SurgeryOrderActivity, from, params): boolean {
    let flag: boolean = false;

    if (!this.deviceService.isOffline && selectedSurgeryOrder?.surgeryOrderStatusString === 'Completed'
      && !(selectedSurgeryOrder.status === 548910005)
      && (params.isOwner || params.isCoowner || params.isCovisitor)
      && this.authenticationService.user.pastProcedureLogReopenPeriod >= differenceInCalendarDays(new Date(), selectedSurgeryOrder.createdDate)
      && from !== PageName.AccountPlanActivities) {
      flag = true;
    }

    if (!params.isOwner && !params.isCoowner &&
      (params.isCovisitor && params.covisitorAccess == CovisitorAccess.READ_ACCESS || params.covisitorAccess == CovisitorAccess.MINIMUM_ACCESS)) {
      flag = false;
    }
    return flag;
  }

  public isScrapButtonEnabled(selectedSurgeryOrder: SurgeryOrderActivity, from, params): boolean {
    let flag: boolean = false;
    if (selectedSurgeryOrder.surgeryOrderStatusString === 'Draft'
      && (params.isOwner || params.isCoowner || params.isCovisitor)
      && from !== PageName.AccountPlanActivities
    ) {
      flag = true;
    }
    
    if (!params.isOwner && !params.isCoowner &&
      (params.isCovisitor && (params.covisitorAccess == CovisitorAccess.READ_ACCESS || params.covisitorAccess == CovisitorAccess.MINIMUM_ACCESS))) {
      flag = false;
    }
    return flag;
  }

  public isCompleteButtonEnabled(selectedSurgeryOrder: SurgeryOrderActivity, from, params): boolean {
    let flag: boolean = false;
    if (from === PageName.AccountPlanActivities) return false;

    const procedureTypeMandatory = this.authenticationService.user.buConfigs['indskr_proceduretype'] ? selectedSurgeryOrder.indskr_proceduretype !== null && selectedSurgeryOrder.indskr_proceduretype !== "" : true;
    // const procedureSubTypeMandatory = this.authenticationService.user.buConfigs['indskr_proceduresubtype'] && params.procedureContractType === 548910000 ? selectedSurgeryOrder.indskr_proceduresubtypename !== null && selectedSurgeryOrder.indskr_proceduresubtypename !== "" : true;
    const procedureContractMandatory = this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT) && this.shouldShowProcedureContract(selectedSurgeryOrder) ? selectedSurgeryOrder.indskr_procedurecontract !== "" : true;
    const hideQunatityField = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_hidequantity'];
    const hideProcedureAndPorductCategory = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_hideprocedureproductcategory'];

    if (params.noProcedureDay && selectedSurgeryOrder.surgeryOrderStatusString === 'Draft' && (this.activityOfflineService.selectedActivity.ownerId == this.authenticationService.user.systemUserID || selectedSurgeryOrder.coOwners.some((coOwner) => coOwner.userId === this.authenticationService.user.systemUserID))) {
      return true;
    }

    const disableCompleteButton = this.deviceService.isOffline && this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT);
    if (disableCompleteButton) return false;

    if (selectedSurgeryOrder.surgeryOrderStatusString === 'Draft'
      && (params.isOwner || params.isCoowner || params.isCovisitor)
      && selectedSurgeryOrder.customers.length > 0
      && selectedSurgeryOrder.accountId
      && isPast(selectedSurgeryOrder.scheduledStart)
      && (hideProcedureAndPorductCategory || selectedSurgeryOrder.products.length > 0)
      && (hideQunatityField || !selectedSurgeryOrder.products.some(p => !p.quantity || p.quantity == 0))
      && procedureTypeMandatory
      && procedureContractMandatory) {
      flag = true;
    }

    if (flag && params.activityOutcomeisMandatory) {
      flag = flag && !!selectedSurgeryOrder.activityOutcomeId;
    }

    if (!params.isOwner && !params.isCoowner &&
      (params.isCovisitor && params.covisitorAccess == CovisitorAccess.READ_ACCESS || params.covisitorAccess == CovisitorAccess.MINIMUM_ACCESS)) {
      flag = false;
    }
    return flag;
  }

  public async fetchShoreiMasterData(loadFromDBOnly) {
    if (!this.authenticationService.user.buSettings['indskr_displayshorei']) return;
    try {
      if (loadFromDBOnly) {
        this.shoreiMasterData = await this.loadShoreiMasterFromLocalDB();
        return;
      }

      const fetchXML = await fetchQueries.salesOrders.fetchShoreiMaster;
      await this.dynamics.executeFetchQuery('jnjjapan_shoreimasters', fetchXML).then((response) => {
        if (response && response.length > 0) {
          const shoreiMasterData = response.map((r) => new ShoreiMaster(r));
          this.saveShoreiMasterInLocalDB(shoreiMasterData);
          this.shoreiMasterData = shoreiMasterData;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  private async saveShoreiMasterInLocalDB(shoreiMasterData: ShoreiMaster[]) {
    await this.disk.updateOrInsert(DB_KEY_PREFIXES.PROCEDURE_SHOREI_MASTER, (doc) => {
      doc = {
        raw: [],
        lastModified: doc.lastModified
      };
      doc.raw = shoreiMasterData;
      return doc;
    });
  }

  public async loadShoreiMasterFromLocalDB() {
    let offlineDataStored;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.PROCEDURE_SHOREI_MASTER, true).then((doc) => {
        offlineDataStored = doc?.raw ? doc.raw : [];
      });
    }
    catch (er) {
      console.error("Failed to load procedure sub types from local db!: ", er)
      offlineDataStored = [];
    }
    return offlineDataStored;
  }

  public async updateProcedureLogsWithContractStatus(contractId: string, status : number) {
    let surgeryArray : any = this.activityOfflineService.activities.filter((activity) => {
      if (activity instanceof SurgeryOrderActivity) {
        return activity.indskr_procedurecontract === contractId;
      }
      return false;
    }).map((activity: SurgeryOrderActivity) => {
      activity.contractStatus = status;
      if(this.activityOfflineService.selectedActivity instanceof SurgeryOrderActivity && this.activityOfflineService.selectedActivity.ID === activity.ID){
        this.activityOfflineService.selectedActivity = activity;
      }
      return activity;
    });

    const action = {
      onDynamics: !this.deviceService.isOffline,
      onLocalDatabase: true,
      onLocalCopy: true,
      operationDetail: {
        code: 'surgerOrderUpdate',
        message: 'surgerOrderUpdate',
      }
    };

    await this.updateOrderActivity(action, surgeryArray, new Date().getTime());
  }

  public get getCustomerString(): string {
    let str = '';
    const currentCustomers = (this.activityOfflineService.selectedActivity as SurgeryOrderActivity).customers;
    if (currentCustomers) {
        if (currentCustomers.length === 1) {
            str = `${currentCustomers[0].fullname}`;
            if(currentCustomers[0].statusCode !== 1 && currentCustomers[0].statusCode !== 548910000){
                str += ' (Inactive)';
            }
        } else if (currentCustomers.length >= 2) {
          currentCustomers.sort((a, b): number => {
                if (a.fullname > b.fullname) return 1;
                if (a.fullname < b.fullname) return -1;
                return 0;
            });
            let frstStr = '';
            let scndStr = '';
            if(currentCustomers[0].statusCode !== 1 && currentCustomers[0].statusCode !== 548910000){
                frstStr = ' (Inactive)';
            }else if(currentCustomers.some(a=> a.statusCode !== 1 && a.statusCode !== 548910000)){
                scndStr = ' (Inactive)';
            }
            str = `${this.contactService.getFullNameForContactDisplaySequence(currentCustomers[0])}${frstStr} +${currentCustomers.length - 1}${scndStr}`;
        }
    }
    return str;
  }

  public async createUpdateAssetBooking(payload) {
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.orderManagement.CREATE_UPDATE_ASSET_BOOKING;
    // Create new asset booking on dynamics and return promise with success or failure
    return this.http.put(url, payload).toPromise();
  }

}

function getDeletedPayloadObejct(raw) {
  let responsePayload = {
    _id: raw._id,
    _rev: raw._rev,
    offlineId: raw.offlineId,
    statecode: raw.statecode,
    statuscode: raw.statuscode,
    track_action: TrackAction.Deleted,
    _deleted: true,
    pendingPushToDynamics: false,
  };
  if (raw['salesorderid']) {
    responsePayload['salesorderid'] = raw['salesorderid'];
  }
  return responsePayload;
}
