import { PopoverController } from '@ionic/angular';
import { ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventActivity } from '../../../classes/events-tool/event.class';
import { EventsToolDataService } from '../../../data-services/event/events-tool.data.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { IndKeyControlsAreaModel } from '../../../models/indKeyControlsAreaModel';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { AuthenticationService } from '../../../services/authentication.service';
import { DeviceService } from '../../../services/device/device.service';
import { EventsToolService } from '../../../services/events-tool/events-tool.service';
import { MeetingStructureService } from '../../../services/meeting-structure/meeting-structure.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { PresentationService } from '../../../services/presentation/presentation.service';
import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { EventDetailsComponent } from '../event-details/event-details';
import { IndHeaderLeftDataModel } from './../../../models/indHeaderLeftDataModel';
import { ActivityService } from './../../../services/activity/activity.service';
import { EventsService } from './../../../services/events/events.service';
import { Events } from '@omni/events';
import { checkTimeConflictWithOtherActivities, removeConflictIdFromOtherActivities } from '../../../utility/activity.utility';
import { EventsSelectionViewDataModel } from '@omni/models/events-selection-page-model';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { NavParams } from '@ionic/angular';
import _, { uniqBy } from 'lodash';
import { DatePipe } from '@angular/common';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { MultiSelectPopover } from '@omni/components/multi-select-popover/multi-select-popover';
import { EventStateCode, EventStatus } from '../../../enums/event/event.enum';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';

/**
 * Generated class for the EventsListPageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'events-list',
  templateUrl: 'events-list.html',
  styleUrls: ['events-list.scss']
})
export class EventsListComponent {

  public selectedItems: Array<MainCardViewDataModel> = [];
  public isReadOnlyMode: boolean = false;
  public selectedEventsHeader: IndSectionHeaderViewDataModel;
  private _isDirty: boolean = false;

  public searchInput = '';
  public filteredEventsList: Array<MainCardViewDataModel> = [];
  public eventsListKeyControlAreaModel: IndKeyControlsAreaModel;
  private selectedEventId = '';
  private visibleCount = 0;
  public readonly eventsData: Array<EventActivity> = [];

  private readonly ngUnsubscribe$ = new Subject<boolean>();
  indHeaderLeftModel: IndHeaderLeftDataModel;

  eventFromGenee: MainCardViewDataModel = null;
  public viewData: EventsSelectionViewDataModel;

  public startDate;
  public endDate;
  from: string;
  filterPopoverData: { text: string; value: string; items: any; handler: (selectedItem: any, item: any, parent: any) => void; }[];
  typeFilter: any;
  eventsFilterPopover: any;

  constructor(
    private readonly navService: NavigationService,
    public repService: RepServices,
    private readonly uiService: UIService,
    public translate: TranslateService,
    private readonly eventsToolDataService: EventsToolDataService,
    private readonly eventsToolService: EventsToolService,
    private readonly device: DeviceService,
    private readonly _cd: ChangeDetectorRef,
    private readonly event: EventsService,
    private readonly presentationService: PresentationService,
    private readonly meetingStructureService: MeetingStructureService,
    private readonly activityService: ActivityService,
    private readonly authService: AuthenticationService,
    private events: Events,
    private navParams: NavParams,
    private eventService: EventsService,
    private datePipe: DatePipe,
    private footerService: FooterService,
    private readonly popoverCtrl: PopoverController,
    private agendaFooterService: AgendaFooterService,
    private faService: FeatureActionsService,
  ) {
    this.eventsData = uniqBy(this.eventsToolService.eventsToolData,'ID');
    if (this.navParams.data && this.navParams.data.viewData) {
      this.viewData = this.navParams.data.viewData;
      this.startDate = this.navParams.data.startDate; // associated with accounts plan
      this.endDate = this.navParams.data.endDate; // associated with accounts plan
      this.from = this.navParams.data.from; // from which component event opened
      this.isReadOnlyMode = this.viewData.isReadOnlyMode;
      this._initSelectedViewItems();
    }

    this.eventsToolService.eventsUpdateObserver$
      .pipe(
        takeUntil(this.ngUnsubscribe$)
      ).subscribe((updates: Array<EventActivity>) => {
        if (updates && updates.length !== 0) {
          updates.forEach(value => {
            const idx = this.eventsData.findIndex(item => {
              if (item.ID) {
                return item.ID === value.ID;
              } else if (item.offlineId) {
                return item.offlineId === value.offlineId;
              } else {
                return false;
              }
            });
            if (idx >= 0) {
              if (!(
                (value.statecode === 0 && value.statuscode === EventStatus.DRAFT)
                || (value.statecode === 0 && value.statuscode === EventStatus.REJECTED)
                || (value.statecode === EventStateCode.Inactive && value.statuscode === EventStatus.FOR_REVIEW)
                || (value.statecode === 0 && value.statuscode === EventStatus.APPROVED)
                || (value.statecode === 1 && value.statuscode === EventStatus.COMPLETED))
              ) {//Hidden Statuses
                // Remove conflict ID from other activities
                removeConflictIdFromOtherActivities(
                  this.eventsData[idx],
                  this.activityService.activities,
                  this.eventsToolService.eventsToolData,
                );
                this.eventsData.splice(idx, 1);
              } else if (
                !this.authService.user.positions.some(pos => pos.ID === value.positionId) && //Event Mapped to user position
                !this.authService.user.childPositions.some(childpos => childpos.childPositionId === value.positionId) && // Event Mapped to child user position
                !(value.covisitors && value.covisitors.length !== 0 && value.covisitors.some(cov => cov.teamMemberStatecode === 0 && cov.id === this.authService.user.systemUserID)) // Event Mapped as Covisitor
              ) {
                // Remove conflict ID from other activities
                removeConflictIdFromOtherActivities(
                  this.eventsData[idx],
                  this.activityService.activities,
                  this.eventsToolService.eventsToolData,
                );
                this.eventsData.splice(idx, 1);
              } else {
                const shouldReCheckTimeConflict = this.eventsToolService.shouldReCheckTimeConflict(this.eventsData[idx], value, this.authService.user.xSystemUserID);
                if (shouldReCheckTimeConflict) {
                  // Remove conflict ID from other activities
                  removeConflictIdFromOtherActivities(
                    this.eventsData[idx],
                    this.activityService.activities,
                    this.eventsToolService.eventsToolData,
                  );
                  // Re-check conflict
                  checkTimeConflictWithOtherActivities(
                    value,
                    this.activityService.activities,
                    this.activityService.conflictCheckStartIdx,
                    this.eventsToolService.eventsToolData,
                    this.eventsToolService.conflictCheckLastIdx,
                    this.authService.user.xSystemUserID,
                  );
                  this.eventsData[idx] = value;
                } else {
                  value.conflictingActivityIds = this.eventsData[idx].conflictingActivityIds;
                  value.conflictingEventIds = this.eventsData[idx].conflictingEventIds;
                  this.eventsData[idx] = value;
                }
              }
            } else if ((
              (value.statecode === 0 && value.statuscode === EventStatus.DRAFT)
              || (value.statecode === 0 && value.statuscode === EventStatus.REJECTED)
              || (value.statecode === 1 && value.statuscode === EventStatus.FOR_REVIEW)
              || (value.statecode === 0 && value.statuscode === EventStatus.APPROVED)
              || (value.statecode === 1 && value.statuscode === EventStatus.COMPLETED))
            ) {//Hidden Statuses
              if (
                !this.authService.user.positions.some(pos => pos.ID === value.positionId) && //Event Mapped to user position
                !this.authService.user.childPositions.some(childpos => childpos.childPositionId === value.positionId) && // Event Mapped to child user position
                !(value.covisitors && value.covisitors.length !== 0 && value.covisitors.some(cov => cov.teamMemberStatecode === 0 && cov.id === this.authService.user.systemUserID)) // Event Mapped as Covisitor
              ) { } else {
                this.eventsData.push(value);
              }
            }
          });
          this.initViewData();
          this.initiateFilter();
        }
      });
    this.eventService.observe("sync:completed").pipe(takeUntil(this.ngUnsubscribe$)).subscribe((status) => {
      this.initEventsHeaderLeft();
    });
    // this.activityService.selectedActivityAtHome = this.activityService.selectedActivity;
    this.uiService.updateNothingSelectedScreenMessageFor(this.eventsData.length);
    this.initViewData();
    this.initEventsHeaderLeft();
    this.initiateFilter();
    this.filterPopoverData[0].value = 'All';
  }

  initiateFilter(){
    let itemsForTypeFilter :{text:string, value:string}[] = [{
      text: this.translate.instant('NONE'),
      value: "None"
    }];
    this.eventsData.map(e=>{
      if(e.eventType && !itemsForTypeFilter.some(o=>o.value == e.eventType)){
        itemsForTypeFilter.push({
          text: e.eventTypeFormatted,
          value: e.eventType
        })
      }
    })
    this.filterPopoverData = [
      {
        text: '',
        value: 'All',
        items: [
          { text: this.translate.instant('ALL_EVENTS'), value: 'All' }
        ],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          });
          item.value = selectedItem.value;
          this.typeFilter = '';
          this.updateVisibleRecords();
        }
      },
      {
        text: this.translate.instant('EVENT_TYPE'),
        value: '',
        items: itemsForTypeFilter,
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = ''
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          this.typeFilter = item.value;
          this.updateVisibleRecords();
          if (item.value == '' && !this.typeFilter) {
            itemRef.parent.items[0].value = 0;
          }
        }
      }
    ];
  }

  private async initViewData() {
    this.updateVisibleRecords();
    this._cd.detectChanges();
  }
  async openFilter(event) {
    this.eventsFilterPopover = await this.popoverCtrl.create({
      component: MultiSelectPopover, componentProps: { root: this.filterPopoverData },
      event: event
    });
    this.eventsFilterPopover.present();
    this.eventsFilterPopover.onDidDismiss().then(() => {
      this.eventsFilterPopover = null;
    });
  }

  ngOnInit() {
    this.events.subscribe('open-event-from-genee', (params) => {
      this.openEventFromGenee(params);
    });
  }

  private _initSelectedViewItems() {
    if (this.viewData.selectedEvents) {
      let selectedIds = this.viewData.selectedEvents.map(event => event.ID);

      selectedIds.forEach((eventId) => {
        // const foundItem = this.eventsData.find(event => event.ID === eventId);
        const index = this.eventsData.findIndex(event => event.ID === eventId);
        const foundItem = index > -1 ? this.eventsData[index] : this.viewData.selectedEvents.find(event => event.ID === eventId);
          if (foundItem) {
            this.selectedItems.push({
              id: foundItem.ID,
              primaryTextLeft: format(foundItem.startDate, 'MMM DD'),
              secondaryTextLeft: foundItem.formattedStatus,
              showEndIcon: !this.isReadOnlyMode,
              mainItemCssClass: 'selector-item',
              isItemSelectedForSelectionView: true,
              endIconType: 'indegene-selectors-remove-icon',
              endIconCssClass: 'remove-icon',
              primaryTextRight: foundItem.name,
              showArrow: false,
              arrowType: '',
              clickHandler: (id: string, event, eventName, refData) => this._handleEventItemClick(id, event, eventName, refData),
            })
          }
      });
      this._initSelectedHeader();
    }
  }

  private _initSelectedHeader() {
    this.selectedEventsHeader = {
      id: 'selected-events-header',
      title: this.translate.instant('SELECTED') + ' (' + this.selectedItems.length + ')',
      controls: [],
    };
  }

  private async openEventFromGenee(params: any) {
    if (params) {
      const eventId = params.eventId;
      if (eventId) {
        if (this.isInFirstTwenties(eventId)) {
          await this.scrollToRequestedCard(eventId);
        } else {
          await this.highlightEventRequestedFromGenee(eventId);
        }
        await this._handleEventItemClick(eventId, null, null, null);
      }
    }
  }

  private scrollToRequestedCard(eventId: string) {
    const selectorString = "[id=" + "'" + eventId + "'" + "]" + "";
    const list = document.querySelectorAll(selectorString);
    if (list && list.length > 0) {
      const lastIndex = list.length - 1;
      const lastMainCardElement = list[lastIndex];
      if (lastMainCardElement) {
        lastMainCardElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      }
    }
  }

  private isInFirstTwenties(eventId: string): boolean {
    // Nothing to do here. Ignore request.
    const eventObj = this.filteredEventsList.find(a => { return a.id === eventId; });
    return eventObj ? true : false;
  }

  private highlightEventRequestedFromGenee(id: string) {

    this.eventFromGenee = null;

    // Check if id is not in filteredEventsList to prevent further check , if it is already loaded in first 20 records
    // if not, then go further, find the event from the main source and update the variables to show it.

    const ev = this.eventsData.find(a => {
      if (a.ID) {
        return a.ID === id;
      } else if (a.offlineId) {
        return a.offlineId === id;
      } else {
        return false;
      }
    });

    if (ev) {
      this.eventFromGenee = {
        id: ev.ID || ev.offlineId,
        primaryTextLeft: format(ev.startDate, 'MMM DD'),
        isSelected: true,
        primaryTextRight: ev.name,
        secondaryTextRight: ev.formattedStatus,
        showArrow: false,
        clickHandler: (id: string, event, eventName, refData) => this._handleEventItemClick(id, event, eventName, refData),
      };

    } else {
      console.warn("Event from Genee: event doesn't exist.");
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(true);
    this.ngUnsubscribe$.complete();
    this.event.unsubscribe('open-event-from-genee');
  }

  public handlePlusButton(): void {
    this.uiService.displayLoader();
    this.eventsToolDataService.createNewEvent({ onDynamics: (!this.device.isOffline), onLocalDatabase: true, onLocalCopy: false }).then((event: EventActivity) => {
      if (event) {
        // Re-check conflicts
        checkTimeConflictWithOtherActivities(
          event,
          this.activityService.activities,
          this.activityService.conflictCheckStartIdx,
          this.eventsToolService.eventsToolData,
          this.eventsToolService.conflictCheckLastIdx,
          this.authService.user.xSystemUserID,
        );
        this.eventsToolService.addEventData(event);
        this.selectedEventId = event.ID ? event.ID : event.offlineId;
        this.eventsToolService.selectedEventOnEventsTool = event;
        this.initViewData();
        this.activityService.selectedActivity = event;
        this.meetingStructureService.createEmbeddedInteractionsForEventCheckIn(null);
        this.navService.setChildNavRoot(EventDetailsComponent, PageName.EventDetailsPageComponent, PageName.EventsToolPageComponent, { event, isNewlyCreatedActivity: true }).then(() => {
          this.navService.setChildNavRightPaneView(true);
          this.uiService.dismissLoader();
        });
      } else {
        console.log('Some error occured while creating new event activity');
        this.uiService.dismissLoader();
      }
    }).catch(err => {
      console.log('Some error occured while creating new event activity');
      this.uiService.dismissLoader();
    });
  }

  public handleSearch(): void {
    if (this.searchInput && this.searchInput.trim().length > 0) {
      this.typeFilter = '';
      this.filterPopoverData[0].value = 'All';
      this.filteredEventsList = this.filteredEvents.filter(c => c.name && c.name.toLowerCase().includes(this.searchInput.toLowerCase())).sort((a, b) => {
        return b.startDate.getTime() - a.startDate.getTime();
      }).map(ev => {
        return this._setMainCardSelectableProps(ev);
      });
    } else {
      // if (this.filteredEventsList.length > 0) {
      //   const existingIds = this.filteredEventsList.map(e => e.id);
      //   this.filteredEventsList = this.filteredEvents.filter(c => existingIds.includes(c.ID || c.offlineId)).sort((a, b) => {
      //     return b.startDate.getTime() - a.startDate.getTime();
      //   }).map(ev => {
      //     return this._setMainCardSelectableProps(ev);
      //   });
      // } else {
      this.filteredEventsList = this.filteredEvents.sort((a, b) => {
        return b.startDate.getTime() - a.startDate.getTime();
      }).map(ev => {
        return this._setMainCardSelectableProps(ev);
      });
      // }
    }
    // this.filteredEventsList = uniqBy(this.filteredEventsList,'id');
  }

  private _handleEventItemClick(id, event, eventName, refData) {
    if (id && eventName) {
      if (eventName == 'mainItem') {
        const foundItem = this.eventsData.find(a => {
          if (a.ID) {
            return a.ID === id;
          } else if (a.offlineId) {
            return a.offlineId === id;
          } else {
            return false;
          }
        });
        if (foundItem) {
          if (!this.device.isOffline) {
            this.eventsToolDataService.fetchEventRealTimeDetails(foundItem).then((updatedEvent: EventActivity) => {
              if (updatedEvent && updatedEvent.ID === this.selectedEventId) {
                const idx = this.eventsData.findIndex(item => item.ID === updatedEvent.ID);
                if (idx >= 0) {
                  if (updatedEvent.statecode === EventStateCode.Inactive && updatedEvent.statuscode === EventStatus.CANCELED) {//Deleted Event
                    this.eventsData.splice(idx, 1);
                  } else {
                    this.eventsData[idx] = updatedEvent;
                  }
                }
                this.eventsToolService.selectedEventOnEventsTool = updatedEvent;
                this.presentationService.mapDownloadedPresentationsToEvent(this.eventsToolService.selectedEventOnEventsTool);
                this.activityService.selectedActivity = this.eventsToolService.selectedEventOnEventsTool;
                this.eventsToolService.setSelectedEventUpdates(this.eventsToolService.selectedEventOnEventsTool);
              }
            }).catch(err => {
              console.log(err);
            });
          }
          if (foundItem.ID) {
            this.selectedEventId = foundItem.ID;
          } else if (foundItem.offlineId) {
            this.selectedEventId = foundItem.offlineId;
          }
          this.eventsToolService.selectedEventOnEventsTool = foundItem;
          this.presentationService.mapDownloadedPresentationsToEvent(this.eventsToolService.selectedEventOnEventsTool);
          this.activityService.selectedActivity = this.eventsToolService.selectedEventOnEventsTool;
          try {
            this.filteredEventsList.forEach(a => {
              a.isSelected = false;
              return a;
            });
            this.filteredEventsList.find(a => a.id === id).isSelected = true;
          } catch (error) {
            console.log(error);
          }
          if (!this.navService.isActiveChildNavRightPaneViewDisplayed) {
            this.navService.setChildNavRoot(EventDetailsComponent, PageName.EventDetailsPageComponent, PageName.EventsToolPageComponent,
              { from: this.viewData && this.viewData.viewMode === ComponentViewMode.ADDNEW ? 'EventSelectionPage' : '', event: foundItem }).then(() => {
                this.navService.setChildNavRightPaneView(true);
              });
          } else {
            this.eventsToolService.setSelectedEventUpdates(this.eventsToolService.selectedEventOnEventsTool);
          }
        }
      } else if (eventName == 'endIcon') {
        if (refData && refData.showEndIcon) {
          switch (refData.endIconType) {
            case 'indegene-selectors-add-icon':
              this._handleAddEvent(id);
              break;
            case 'indegene-selectors-remove-icon':
              this._handleRemoveEvent(id);
              break;
          }
        }
      }
    }
  }

  private _handleAddEvent(id) {
    let foundItem;
    foundItem = this.filteredEventsList.find(a => a.id == id);
    if (foundItem) {
      let isAlreadyAddedIndex = this.selectedItems.findIndex(a => a.id == id);
      if (isAlreadyAddedIndex < 0) {
        foundItem.endIconType = 'indegene-selectors-checkmark-icon';
        foundItem.endIconCssClass = 'checkmark-icon';
        this.selectedItems.push({
          id: foundItem.id,
          primaryTextLeft: foundItem.primaryTextLeft,
          secondaryTextLeft: foundItem.secondaryTextLeft,
          showEndIcon: true,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: true,
          endIconType: 'indegene-selectors-remove-icon',
          endIconCssClass: 'remove-icon',
          primaryTextRight: foundItem.primaryTextRight,
          showArrow: false,
          arrowType: '',
          clickHandler: (id: string, event, target, data) => this._handleEventItemClick(id, event, target, data),
        });
        this._resetIsDirty();
        this._initSelectedHeader();
        this.initEventsHeaderLeft();
        this._cd.detectChanges();
      }
    }
  }

  private _handleRemoveEvent(id) {
    let foundItemIndex;
    foundItemIndex = this.selectedItems.findIndex(a => a.id == id);
    if (foundItemIndex >= 0) {
      this.selectedItems.splice(foundItemIndex, 1);
      let listItem = this.filteredEventsList.find(a => a.id == id);
      if (listItem) {
        listItem.endIconType = 'indegene-selectors-add-icon';
        listItem.endIconCssClass = 'add-icon';
      }
      this._resetIsDirty();
      this._initSelectedHeader();
      this.initEventsHeaderLeft();
      this.initiateFilter();
      this._cd.detectChanges();
    }
  }

  private _resetIsDirty() {
    this._isDirty = _.xor(this.viewData.selectedEvents.map(a => a.ID), this.selectedItems.map(b => b.id)).length !== 0;
  }

  public closePage(): void {
    this.activityService.signalConflictCheck$.next({ componentUuid: this.activityService.activitiesPaneNewActivityComponentUUID, signal: true, start: null, end: null });
    this.uiService.activeView = this.uiService.prevView;
    this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
    this.navService.popWithPageTracking().then(() => {
      // Short call feature requires to bring the tab back to short call home
      if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
        this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
        this.uiService.setAgendaTab('shortCallHome');
      }
    });
  }

  private updateVisibleRecords() {
    this.visibleCount = 20;
    if (this.filteredEvents.length > 20) {
      this.filteredEventsList = this.filteredEvents.slice(0, this.visibleCount).map(ev => {
        return this._setMainCardSelectableProps(ev);
      });
    } else {
      this.filteredEventsList = this.filteredEvents.map(ev => {
        return this._setMainCardSelectableProps(ev);
      });
    }
    // this.filteredEventsList = uniqBy(this.filteredEventsList,'id');
    this.resetVisibleEventFromGeneeCard();
    this.handleSearch();
  }

  // check first date is coming after second date
  isAfter(firstDate: Date, secondDate: Date) {
    return format(firstDate, "YYYY-MM-DD") >= format(secondDate, "YYYY-MM-DD");
  }

  get filteredEvents(): EventActivity[] {
    let filteredObjs: EventActivity[] = [];
    if (this.eventsData.length) {
      filteredObjs = this.eventsData.sort((a, b) => b.startDate.getTime() - a.startDate.getTime());
      if(this.typeFilter){
        filteredObjs = filteredObjs.filter(f=> f.eventType == this.typeFilter || (!f.eventType && this.typeFilter == "None"))
      }
      if (this.from === 'account-plan') { // when events opened from accounts plan.
        filteredObjs = filteredObjs.filter(({ startDate, endDate, statuscode }) => {
          // to list the events which comes inside the duration and status either open or approved;
          if (this.startDate && this.endDate) {
            return (this.isAfter(startDate, new Date(parseInt(this.startDate))) && this.isAfter(new Date(parseInt(this.endDate)), endDate)) && (statuscode === EventStatus.APPROVED);
          } else if (this.startDate) {
            return this.isAfter(startDate, new Date(parseInt(this.startDate))) && (statuscode === EventStatus.APPROVED);
          } else {
            return statuscode === EventStatus.APPROVED;
          }
        });
      } else if (this.from === 'brand-plan') {
        filteredObjs = filteredObjs.filter(({ startDate, endDate }) =>
          // to list the events which comes inside the duration and status either open or approved;
          (this.isAfter(startDate, new Date(parseInt(this.startDate))) && this.isAfter(new Date(parseInt(this.endDate)), endDate))
        );
      }
    }
    return filteredObjs;
  }

  public doInfinite(event) {
    this.visibleCount = this.filteredEventsList.length;
    this.filteredEventsList.push(...this.filteredEvents.slice(this.visibleCount, this.visibleCount + 20).map(ev => {
      return this._setMainCardSelectableProps(ev);
    }));
    this.handleSearch();
    event.target.complete();
    this.resetVisibleEventFromGeneeCard();
  }

  private resetVisibleEventFromGeneeCard() {
    // If requested event will be available then we can hide the temporary added (highlighted) event.
    if (this.isVisibleCardFoundInCurrentList()) {
      this.eventFromGenee = null;
    }
    //
  }

  private _setMainCardSelectableProps(ev: EventActivity) {
    const event = {
      id: ev.ID || ev.offlineId,
      primaryTextLeft: this.datePipe.transform(ev.startDate, 'MMM dd', undefined, this.translate.currentLang),
      isSelected: (ev.ID && this.selectedEventId === ev.ID || (ev.offlineId && ev.offlineId === this.selectedEventId)),
      primaryTextRight: this.eventService.getTranslatedSubject(ev),
      secondaryTextRight: this.eventService.getFormattedStatus(
        ev,
        ev.offlineApprovalSubmit ? EventStatus.FOR_REVIEW : undefined,
      ),
      isConflicting: this.eventsToolService.hasTimeConflict(ev),
      showArrow: false,
      clickHandler: (id: string, event, eventName, refData) => this._handleEventItemClick(id, event, eventName, refData),
    };
    if (this.viewData && this.viewData.viewMode === ComponentViewMode.ADDNEW) {
      const isSelected = this.selectedItems.some(a => a.id == ev.ID);
      event['mainItemCssClass'] = 'selector-item';
      event['isItemSelectedForSelectionView'] = true;
      event['endIconType'] = isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon';
      event['endIconCssClass'] = isSelected ? 'checkmark-icon' : 'add-icon';
      event['showEndIcon'] = !this.isReadOnlyMode;
    }
    return event;
  }

  private isVisibleCardFoundInCurrentList(): boolean {
    if (this.eventFromGenee) {
      if (this.filteredEventsList.length) {
        const events = (this.filteredEventsList.filter(e => e.id === this.eventFromGenee.id));
        return events.length > 0 ? true : false;
      }
    }
    return false;
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closePage();
        break;
      case 'back':
        this.closeSelectionPage();
        break;
      case 'plusNew':
        this.handlePlusButton();
        break;
      case 'done':
        this._mapEvents();
        break;
      default:
        break;
    }
  }

  private _mapEvents() {
    let newSelectedEvents = this.selectedItems.map(a => {
      const event = this.eventsToolService.getEventDetailsbyId(a.id);
      if (event) return event;
      else return { ID: a.id, name: a.primaryTextRight } as EventActivity;
    });
    this.viewData.callbackFromSelectionComponent("DONEBUTTONCLICK", newSelectedEvents, null);
    this.closeSelectionPage();
  }

  public closeSelectionPage(): void {
    // this.navService.popChildNavCtrlFromStack(ChildNavNames.EventSelectionNavigation);

    this.navService.popWithPageTracking().then(() => {
      if(this.from === 'brand-plan') this.footerService.initButtons(FooterViews.None);
    });
  }

  private initEventsHeaderLeft(): void {
    const buttons = [];
    if (this.viewData && this.viewData.viewMode === ComponentViewMode.ADDNEW) {
      buttons.push({
        id: "back",
        imgSrc: 'assets/imgs/header_cancel.svg',
        cssClass: 'seventyPercentWidth',
        isDisabled: false,
        align: "left",
      },
        {
          id: "done",
          imgSrc: 'assets/imgs/header_complete.svg',
          cssClass: 'seventyPercentWidth',
          isDisabled: !this._isDirty || (this.device.isOffline && this.viewData.disableDoneButtonInOffline),
          align: "right",
        });
    } else {
      buttons.push({
        id: 'close',
        imgSrc: 'assets/imgs/back_to_home_btn.svg',
        isDisabled: false,
        align: 'left',
      })
      if(this.authService.hasFeatureAction(FeatureActionsMap.EVENT_CREATION_ON_APP)) {
        buttons.push({
            id: 'plusNew',
            imgSrc: 'assets/imgs/ios_add_3x.svg',
            isDisabled: false,
            align: 'right',
            tooltip: "Create Event"
          })
      } 
    }
    this.indHeaderLeftModel = {
      id: 'events-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('EVENTS'),
      mode: true,
      controls: buttons,
    };
  }
}
