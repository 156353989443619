
import { takeUntil } from 'rxjs/operators';
import { TrackService, TrackingEventNames } from './../../../services/logging/tracking.service';
import { NavigationService, PageName } from './../../../services/navigation/navigation.service';
import { ActivityService } from './../../../services/activity/activity.service';
import { Component, Input } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { IndFormFieldViewDataModel, FormFieldType } from "../../../models/indFormFieldDataModel";
import { IndSectionHeaderViewDataModel } from "../../../models/indSectionHeaderDataModel";
import { EstimatedRevenueCalculationBasedOn, Opportunity, OpportunityPrimaryTraget, ProgressStatus } from "../../../classes/opportunity-management/opportunity.class";
import { OpportunityManagementDataService } from "../../../data-services/opportunity-management/opportunity-management.data.service";
import { OpportunityManagementService } from "../../../services/opportunity-management/opportunity-management.service";
import { PopoverComponent } from '../../popover/popover';
import { RepServices } from '../../../data-services/rep/rep.services';
import { OpportunitiesListComponent } from '../opportunities-list/opportunities-list';
import { AccountOfflineService, AccountSelectedFor, AccountSelectionObservableModel } from '../../../services/account/account.offline.service';
import { DeviceService } from '../../../services/device/device.service';
import { AccountPageComponent } from '../../account/account-page/account-page';
import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { Subject } from 'rxjs';
import { EventsService, EventName } from '../../../services/events/events.service';
import { OpportunityDetailsComponent } from '../opportunity-details/opportunity-details';
import { TranslateService } from '@ngx-translate/core';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { AuthenticationService } from '@omni/services/authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import _ from 'lodash';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { ContactOfflineService, ContactSelectionObservableModel } from '@omni/services/contact/contact.service';
import { Contact } from '@omni/classes/contact/contact.class';
import { NotificationService } from '@omni/services/notification/notification.service';
import { ContactPageComponent } from '@omni/components/contact/contact-page/contact-page';
import { Account } from '@omni/classes/account/account.class';
import { MarketingPlansManagementOfflineService } from '@omni/services/marketing-management/marketing-management.service';
import { MarketingPlan } from '@omni/classes/marketing-management/marketing-plan.class';

@Component({
  selector: 'new-opportunity',
  templateUrl: 'new-opportunity.html',
  styleUrls: ['new-opportunity.scss']
})
export class NewOpportunityComponent {

  public isLayoverPushView: boolean = false;
  public layoverViewPushedFrom: string = '';
  public newOpportunityFormFieldValues: Array<IndFormFieldViewDataModel> = [];
  public opportunityDetailsHeader: IndSectionHeaderViewDataModel;
  public stakeholderLineItemDetailsHeader: IndSectionHeaderViewDataModel;
  public opportunity: Opportunity;

  public allModelStakeholder: MainCardViewDataModel[];

  private ngDestroy$ = new Subject<boolean>();
  @Input() from: any;
  @Input() mbp: MarketingPlan;
  public pageTitle: IndPageTitleViewDataModel;
  private isrevenuesystemcalculated: boolean = true;
  private isClickedAccount: boolean = false;
  private _opportunityTypePopover: any;
  private _opportunitySubTypePopover: any;
  opportunitySubTypeOptions: any[];
  private isAccountHaveAffiliatedContacts = false;
  private autoSubjectEnabled = false;
  private isPrimaryTargetFieldEnabled: boolean = false;
  private isClickedContact: boolean = false;
  private _opportunityPrimaryTargetPopover: any;
  private isStakeholderValueEnabled:boolean = false;

  constructor(
    public activityService: ActivityService,
    public navService: NavigationService,
    private opportunityDataService: OpportunityManagementDataService,
    private opportunityService: OpportunityManagementService,
    private popoverCtrl: PopoverController,
    public repService: RepServices,
    private accountService: AccountOfflineService,
    private device: DeviceService,
    private uiService: UIService,
    private events: EventsService,
    private translate: TranslateService,
    private trackingService: TrackService,
    private authService: AuthenticationService,
    private readonly contactService: ContactOfflineService,
    private readonly notificationService: NotificationService,
  ) {

    this.opportunityService.newOpportunityObserver.pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((data: Opportunity) => {
        if (data) {
          this.opportunity = data;
          this.createStakeHolderModel();
          this.initNewOpportunityInfoFormFields();
        }
      });

    this.events.observe('updateMandatoryField').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((field) => {
        if (field == PageName.AccountPageComponent) this.isClickedAccount = true;
        this.initNewOpportunityInfoFormFields();
      });

    this.contactService.selectedContactObserver.pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((data: ContactSelectionObservableModel) => {
        if (data && data.for == 'OpportunityDetails' && data.id == this.opportunity.ID) {
          this.opportunity.stakeholders = [];
          this.opportunity.stakeholderValue = 0;
          this.opportunity.stakeholderValueFormatted = (this.opportunity.transactionCurrencySymbol ? this.opportunity.transactionCurrencySymbol : '')+'0.00';
          data.contacts.forEach(con => {
            if(con.stakeholderValue >= 0){
              this.opportunity.stakeholderValue += con.stakeholderValue;
            }
            con.stakeholderRolesList.forEach(role => {
              this.opportunity.stakeholders.push({
                ID: con.ID,
                contactId: con.ID,
                firstName: con.firstName,
                fullName: con.fullName,
                isActive: con.isActive,
                stakeholderValue: con.stakeholderValue,
                lastName: con.lastName,
                role: { roleid: role.roleid, name: role.name }
              });
            });
          })
          this.opportunity.stakeholderValueFormatted = (this.opportunity.transactionCurrencySymbol ? this.opportunity.transactionCurrencySymbol : '')+this.opportunity.stakeholderValue;
          // if (this.opportunity.stakeholders && this.opportunity.stakeholders.length > 0) {
            this.uiService.displayLoader();
            this.opportunity.pendingPushToDynamics = true;
            this.createStakeHolderModel();
            this.initNewOpportunityInfoFormFields();
          // } 
        } 
      });

      this.accountService.selectedAccountObserver.pipe(
        takeUntil(this.ngDestroy$))
        .subscribe((data: AccountSelectionObservableModel) => {
          if (data && data.for == 'OpportunityDetails' && data.id == this.opportunity.ID) {
            this.opportunity.stakeholders = [];
            this.opportunity.stakeholderValue = 0;
            this.opportunity.stakeholderValueFormatted = (this.opportunity.transactionCurrencySymbol ? this.opportunity.transactionCurrencySymbol : '')+'0.00';
            data.accounts.forEach(acc => {
              if(acc.stakeholderValue >= 0){
                this.opportunity.stakeholderValue += acc.stakeholderValue;
              }
              acc.stakeholderRolesList.forEach(role => {
                this.opportunity.stakeholders.push({
                  ID: acc.id,
                  accountId: acc.id,
                  firstName: '',
                  fullName: acc.accountName,
                  isActive: (acc.status && (acc.status===1 || acc.status===548910000))? true : false,
                  stakeholderValue: acc.stakeholderValue,
                  lastName: '',
                  role: { roleid: role.roleid, name: role.name }
                });
              });
            })
            this.opportunity.stakeholderValueFormatted = (this.opportunity.transactionCurrencySymbol ? this.opportunity.transactionCurrencySymbol : '')+this.opportunity.stakeholderValue;
            // if (this.opportunity.stakeholders && this.opportunity.stakeholders.length > 0) {
              this.uiService.displayLoader();
              this.opportunity.pendingPushToDynamics = true;
              this.createStakeHolderModel();
              this.initNewOpportunityInfoFormFields();
            // } 
          } 
        });
  }

  ngOnInit() {
    if (this.from) {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
    }
    this.isrevenuesystemcalculated = !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_REVENUE_USER_PROVIDED);
    this.autoSubjectEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_AUTO_SUBJECT);
    this.isPrimaryTargetFieldEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_PRIMARY_TARGET);
    this.isStakeholderValueEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_STAKEHOLDER_VALUE);
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.opportunityService.newOpportunity$.next(null);
  }

  public initNewOpportunityInfoFormFields() {
    if (!this.opportunity) return;

    if(this.autoSubjectEnabled){
      this.opportunity.opportunityName = this.opportunityService.setOpportunityDescription(this.opportunity);
    }

    let enableConfirm =  (this.opportunity.accountID && this.opportunity.opportunityTypeId && (_.isEmpty(this.opportunitySubTypeOptions) || this.opportunity.opportunitySubTypeId))
    if(!this.isrevenuesystemcalculated){
      enableConfirm = (this.opportunity.accountID && this.opportunity.opportunityTypeId && (_.isEmpty(this.opportunitySubTypeOptions) || this.opportunity.opportunitySubTypeId) && (!this.isrevenuesystemcalculated && this.opportunity.stakeholders.length > 0))
    }
    if(this.isPrimaryTargetFieldEnabled){
      enableConfirm = enableConfirm && !!this.opportunity.primaryTargetValue;
    }
    if(this._isFocusAreaEnabled){
      enableConfirm = enableConfirm && !!this.opportunity.focusArea;
    }

    const opportunityType = this.opportunityService.opportunityTypes.find(opp => opp.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
    this.newOpportunityFormFieldValues = [
      {
        label: this.translate.instant('ACCOUNT'),
        inputText: this.opportunity.accountName,
        id: 'account-field',
        isReadOnly: true,
        isDisabled: false,
        customPlaceholderLabel: this.translate.instant('SELECT_ACCOUNT'),
        showArrow: true,
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        isRequired: true,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: this.isClickedAccount && _.isEmpty(this.opportunity.accountName)
      },
      {
        label: this.translate.instant('DESCRIPTION'),
        inputText: this.opportunity.opportunityName,
        id: 'desc-field',
        isDisabled: this.autoSubjectEnabled,
        placeholderLabel: this.translate.instant('DESCRIPTION'),
        showArrow: !this.autoSubjectEnabled,
        formFieldType: FormFieldType.INLINE_INPUT,
        inputValue: this.opportunity.opportunityName,
        isReadOnly: false,
        isRequired: true,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      },
      {
        label: this.translate.instant('OM_OWNER'),
        inputText: this.opportunity.ownerName,
        id: 'created-by-field',
        isReadOnly: true,
        isDisabled: false,
        showArrow: false,
      },
      {
        label: this.translate.instant('TYPE'),
        inputText: (this.opportunity.opportunityTypename) ? this.opportunity.opportunityTypename.toString() : '',
        id: 'oportunity-type-field',
        isReadOnly: true,
        isRequired: true,
        isDisabled: false,
        customPlaceholderLabel: this.translate.instant('SELECT_TYPE'),
        showArrow: true,
        formFieldType: FormFieldType.POPOVER_SELECT,
      },
      {
        label: this.translate.instant('SUB_TYPE'),
        inputText: (this.opportunity.opportunitySubTypename) ? this.opportunity.opportunitySubTypename.toString() : '',
        id: 'oportunity-Sub-Type-field',
        isReadOnly: true,
        isRequired: true,
        isHidden: _.isEmpty(this.opportunitySubTypeOptions) ? true : false,
        customPlaceholderLabel: this.translate.instant('SELECT_SUB_TYPE'),
        showArrow: true,
        formFieldType: FormFieldType.POPOVER_SELECT,
      },
      {
        isHidden: !this._isFocusAreaEnabled,
        label: this.translate.instant('FOCUS_AREA'),
        inputText: this.opportunity.focusArea,
        inputValue: this.opportunity.focusArea,
        id: 'focus-area-field',
        isReadOnly: false,
        isDisabled: false,
        placeholderLabel:this.translate.instant('ENTER_FOCUS_AREA'),
        showArrow: true,
        formFieldType: FormFieldType.INLINE_INPUT,
        isRequired: true,
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      },
    ];

    if(this.isPrimaryTargetFieldEnabled){
      const primaryTargetFormField = {
        label: this.translate.instant('PRIMARY_TARGET'),
        inputText: (this.opportunity.primaryTargetName) ? this.opportunity.primaryTargetName.toString() : '',
        id: 'primary-target-field',
        isReadOnly: true,
        isRequired: true,
        isDisabled: false,
        customPlaceholderLabel: this.translate.instant('SELECT_PRIMARY_TARGET'),
        showArrow: true,
        formFieldType: FormFieldType.POPOVER_SELECT,
      }
      if(this.opportunity.primaryTargetValue == OpportunityPrimaryTraget.CONTACT){
        const contactFormField = {
          label: this.translate.instant('CONTACT_FORM'),
          inputText: this.opportunity.contactName,
          id: 'contact-field',
          isReadOnly: true,
          isDisabled: false,
          customPlaceholderLabel: this.translate.instant('SELECT_CONTACT'),
          showArrow: true,
          formFieldType: FormFieldType.NEW_PAGE_SELECT,
          isRequired: true,
          errorMessage: this.activityService.getErrorMessageRequiredField(),
          isEmptyRequiredField: this.isClickedContact && _.isEmpty(this.opportunity.contactName)
        }
        this.newOpportunityFormFieldValues.unshift(contactFormField);
      }
      this.newOpportunityFormFieldValues.unshift(primaryTargetFormField);
      if(!this.opportunity.primaryTargetValue){
        let contactField = this.newOpportunityFormFieldValues.find(a=> a.id == 'contact-field');
        if(contactField){
          contactField.showArrow = false;
          contactField.isDisabled = true;
        }
        let accountField = this.newOpportunityFormFieldValues.find(a=> a.id == 'account-field');
        if(accountField){
          accountField.showArrow = false;
          accountField.isDisabled = true;
        }
      }
    }

    this.opportunityDetailsHeader = {
      id: 'opportunity-details-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: [

      ],
    };

    this.stakeholderLineItemDetailsHeader = {
      id: 'stakeholder-line-items-header',
      title: `${this.translate.instant('STAKEHOLDERS')}/${this.translate.instant('ACCOUNTS')}`,
      doNotModifyTitleCase: true,
      isRequired: !this.isrevenuesystemcalculated,
      controls: [
        {
          id: 'stakeholder_line_item_add',
          text: this.translate.instant('ADD'),
          isDisabled: _.isEmpty(opportunityType),
        }
      ],
    }

    this.pageTitle = {
      id: 'new-opportunity',
      title: this.translate.instant('OPPORTUNITY'),
      controls: [{
        id: 'opportunity_confirm',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('CREATE'),
        isDisabled: !enableConfirm,
        align: "right"
      },
      {
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      }],
    };

  }

  ngAfterViewInit() {
    this.opportunity = this.opportunityService.getNewOpportunityObject();
    this.opportunityService.newOpportunity$.next(this.opportunity);
    if(this.from == 'MarketingPlanInfo' && this.opportunity && this.mbp){
      this.opportunity.focusArea = this.mbp.focusArea;
    }
  }

  public onClosePage() {
    this.navService.popChildNavPageWithPageTracking();
    if (this.navService.getActiveChildNavViewPageName() == PageName.NothingSelectedView) {
      this.navService.setChildNavRightPaneView(false);
    }
  }

  public onFieldClick(event) {
    if (event && event.id) {
      switch (event.id) {
        case 'account-field':
          this.handleAccountSelection(event);
          this.isClickedAccount = true;
          break;
        case 'desc-field':
          this.handleEditDescription(event);
          break;
        case 'oportunity-type-field':
          this.handleOpportunityTypeSelection(event);
          break;
        case 'oportunity-Sub-Type-field':
          this.handleOpportunitySubTypeSelection(event);
          break;
        case 'primary-target-field':
          this.handlePrimaryTargetField(event);
          break;
        case 'contact-field':
          this.handleContactSelection(event);
          this.isClickedContact = true;
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'opportunity_confirm':
        this.handleConfirmOpportunity();
        break;
      case 'close':
        this.onClosePage();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  private async handleAccountSelection(event) {
    if(this.opportunity.primaryTargetValue && this.opportunity.primaryTargetValue == OpportunityPrimaryTraget.CONTACT && this.opportunity.contactID){
      let affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting([this.contactService.getContactByID(this.opportunity.contactID)]);
      if(!(affiliatedAccounts && affiliatedAccounts.length > 0)){
        this.notificationService.notify(this.translate.instant('NO_AFFILIATED_ACCOUNTS_FOR_CONTACT'), 'Opportunity-Details', 'top', 'danger');
        return;
      }
    }
    this.accountService.selectedFor = AccountSelectedFor.OPPORTUNITY_SELECTION;
    this.accountService.accessedAccountListFrom = PageName.NewOpportunityComponent;
    this.accountService.isMarketingBusinessPlanInvoked = this.from == 'MarketingPlanInfo' || false;
    this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { 'listMode': ComponentViewMode.ADDNEW, type: 'PushedAccountSelectionView' }, PageName.NewOpportunityComponent, { animate: false }, null);
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (eventName == 'input_value_confirm' && id) {
      switch (id) {
        case 'desc-field':
          this.handleEditDescription(event);
          break;
        case 'focus-area-field':
          this.handleFocusAreaField(event);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  private handleEditDescription(data): void {
    this.trackingService.tracking('EnterDescription', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    if (data && data.target.value != this.opportunity.opportunityName) {
      if (data.target.value == '') this.opportunity.opportunityName = this.opportunity.opportunityName;
      else this.opportunity.opportunityName = data.target.value;
      this.initNewOpportunityInfoFormFields();
    }
  }

  private handleConfirmOpportunity(): void {
    this.trackingService.tracking('SelectConfirm', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    if (!this.opportunity.accountID) return;
    if (!this.opportunity.opportunityName || this.opportunity.opportunityName == '') {
      this.opportunity.opportunityName = 'Opportunity';
    }
    const opportunityType = this.opportunityService.opportunityTypes.find(a => a.indskr_opportunitytypeid == this.opportunity.opportunityTypeId)
    if (opportunityType && opportunityType.estimatedRevenueAutoCalculated && opportunityType.estimatedRevenueCalculationBasedOn == EstimatedRevenueCalculationBasedOn.PROCEDUREANDPRODUCTS) {
      this.opportunity.isestimatedrevenuebasedonprocedureandproducts = true;
    }
    this.opportunity.businessUnitId = this.authService.user.xBusinessUnitId;
    this.opportunity.businessUnitFormatted = this.authService.user.businessUnitName;
    this.opportunity.pendingPushToDynamics = true;
    this.opportunity.isrevenuesystemcalculated = this.isrevenuesystemcalculated;
    if (opportunityType['indskr_nostageprogressafterdays'] != null) {
      this.opportunity.indskr_progressstatus = ProgressStatus.IN_PROGRESS;
    }
    if(this.from == 'MarketingPlanInfo'){
      //this.opportunity.focusArea = this.mbp.focusArea;
      this.opportunity.primarySpecialtyId = this.mbp.primarySpecialtyId;
      this.opportunity.primarySpecialtyName = this.mbp.primarySpecialtyName;
      this.opportunity.subSpecialtyId = this.mbp.subSpecialtyId;
      this.opportunity.subSpecialtyName = this.mbp.subSpecialtyName;
      this.opportunity.marketingBusinessPlans.push(this.mbp);
    }

    this.uiService.displayLoader();
    this.opportunityDataService.createOpportunity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true }, [this.opportunity]).then(async data => {
      this.events.publish(EventName.NEWOPPORTUNITYCONFIRMED, this.opportunity);
      let account = this.accountService.getAccountById(this.opportunity.accountID);
      
      if (!this.opportunity.ID.includes('offline') && this.opportunity.appointmentId && this.activityService.selectedActivity && this.activityService.selectedActivity instanceof AppointmentActivity) {
        (this.activityService.selectedActivity as AppointmentActivity).regardingObjectId = this.opportunity.ID;
        (this.activityService.selectedActivity as AppointmentActivity).regardingObjectIdValue = this.opportunity.opportunityName;
        await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
      }
      if (!this.device.isOffline) await this.opportunityDataService.getOpportunitiesData(false, account.id, false, true);
      
      if(this.from == 'MarketingPlanInfo') {
        this.navService.pushChildNavPageWithPageTracking(OpportunityDetailsComponent, PageName.OpportunityDetailsComponent,
          PageName.OpportunityManagementPage, { from: 'MarketingPlanInfo', opportunity: this.opportunity });
          this.events.publish(EventName.OPPORTUNITY_CREATED_FROM_MARKETING_PLAN, this.opportunity);
      }else {
        this.navService.setChildNavRoot(OpportunitiesListComponent, PageName.OpportunitiesListComponent, PageName.OpportunityManagementPage, { from: 'OpportunityTool', account: account });
        this.navService.setChildNavRightPaneView(true);
        this.navService.pushChildNavPageWithPageTracking(OpportunityDetailsComponent, PageName.OpportunityDetailsComponent,
          PageName.OpportunityManagementPage, { from: 'OpportunityTool', opportunity: this.opportunity });
      }
      
      this.uiService.dismissLoader();
    }).catch(err => {
      // To Do error handling
      console.log(err);
      this.uiService.dismissLoader();
    })

  }

  private async handleOpportunityTypeSelection(data) {
    let opportunityTypeOptions = this.opportunityService.opportunityTypes;
    if (Array.isArray(opportunityTypeOptions) && opportunityTypeOptions.length > 0) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'Opportunity-Type-Select',
        data: opportunityTypeOptions.map(option => {
          let obj = {
            title: option.indskr_name,
            id: option.indskr_opportunitytypeid,
            isSelected: false,
          };
          if (this.opportunity.opportunityTypeId && obj.id == this.opportunity.opportunityTypeId) {
            obj['isSelected'] = true;
          }
          return obj;
        }),
      };
      this._opportunityTypePopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data.event });
      this._opportunityTypePopover.onDidDismiss().then(async (data) => {
        data = data.data;
        if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.opportunityTypeId) {
          this.opportunity.opportunityTypeId = data.selectedItems[0].id;
          this.opportunity.opportunityTypename = data.selectedItems[0].title;
          this.opportunity.opportunitySubTypeId = '';
          this.opportunity.opportunitySubTypename = '';
          const opportunityType = this.opportunityService.opportunityTypes.find(a => a.indskr_opportunitytypeid == this.opportunity.opportunityTypeId)
          if (opportunityType.singlestakeholder) {
            if (this.opportunity.stakeholders.length > 1) {
              this.opportunity.stakeholders = [];
              this.allModelStakeholder = [];
            }
          }
          this.fetchOpportunitiesSubTypes();
          this.initNewOpportunityInfoFormFields();
        }
        this._opportunityTypePopover = undefined;
      });
      this._opportunityTypePopover.present();
    }
  }

  private async fetchOpportunitiesSubTypes() {
    const opportunityType = this.opportunityService.opportunityTypes.find(a => a.indskr_opportunitytypeid == this.opportunity.opportunityTypeId)
    if (opportunityType) {
      this.opportunitySubTypeOptions = opportunityType.opportunitySubTypes;
    }
  }

  private async handleOpportunitySubTypeSelection(data) {
    if (Array.isArray(this.opportunitySubTypeOptions) && this.opportunitySubTypeOptions.length > 0) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'oportunity-Sub-Type-field',
        data: this.opportunitySubTypeOptions.map(option => {
          let obj = {
            title: option.indskr_name,
            id: option.indskr_opportunitysubtypeid,
            isSelected: false,
          };
          if (this.opportunity.opportunitySubTypeId && obj.id == this.opportunity.opportunitySubTypeId) {
            obj['isSelected'] = true;
          }
          return obj;
        }),
      };
      this._opportunitySubTypePopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data.event });
      this._opportunitySubTypePopover.onDidDismiss().then(async (data) => {
        data = data.data;
        if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.opportunitySubTypeId) {
          this.opportunity.opportunitySubTypeId = data.selectedItems[0].id;
          this.opportunity.opportunitySubTypename = data.selectedItems[0].title;
          this.initNewOpportunityInfoFormFields();
        }
        this._opportunitySubTypePopover = undefined;
      });
      this._opportunitySubTypePopover.present();
    }
  }

  async checkAffliatedContacts() {
    if (!this.opportunity) return;

    if (this.opportunity.accountID) {
      let affiliatedContacts: Contact[] = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([this.accountService.getAccountById(this.opportunity.accountID)]);
      if (affiliatedContacts.length !== 0){
        this.isAccountHaveAffiliatedContacts = true;
      } else{
        this.isAccountHaveAffiliatedContacts = false;
      }
    }
  }

  async handleStakeholderField() {
    await this.checkAffliatedContacts();
    if (!this.isAccountHaveAffiliatedContacts) {
      this.notificationService.notify(this.translate.instant('NO_AFFILIATED_STAKEHOLDERS_FOR_ACCOUNT'), 'Opportunity-Details', 'top', 'danger');
      return;
    }
    this.trackingService.tracking('SelectStakeholder', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    let passedData = {
      type: 'PushedContactSelectionView',
      contactListData:
      {
        for: 'OpportunityStakeholderSelection',
        opportunity: this.opportunity
      }
    };
    // this.contactSelectionSubscriptionFlag = true;
    this.uiService.prevView = this.uiService.activeView;
    this.uiService.activeView = '';
    this.contactService.isSchedulerInvoked = false;
    const opportunityType = this.opportunityService.opportunityTypes.find(opp => opp.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
    if (opportunityType.singlestakeholder && opportunityType.singlestakeholder === true) {
      this.contactService.contactPageMode = ComponentViewMode.SELECTION;
    } else {
      this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
    }
    this.contactService.accessedContactListFrom = PageName.NewOpportunityComponent;
    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, passedData, PageName.OpportunityManagementPage, { animate: false }, null);
  }

  createStakeHolderModel() {
    this.allModelStakeholder = [];

    let aggrStakeholder = [];
    this.opportunity.stakeholders.forEach(sh => {
      const stakeholder = aggrStakeholder.find(con => con.ID === sh.ID);
      if (!stakeholder) {
        aggrStakeholder.push(_.cloneDeep(sh));
      } else {
        stakeholder.role.name += ", " + sh.role.name;
      }
    });

    this.allModelStakeholder = aggrStakeholder.map((f) => {
      return {
        id: f.ID,
        fixedHeight: true,
        primaryTextLeft: f.fullName + (this.isStakeholderValueEnabled && f.stakeholderValue ? ' ('+(this.opportunity.transactionCurrencySymbol?this.opportunity.transactionCurrencySymbol:'')+f.stakeholderValue+')' : ''),
        secondaryTextLeft: f.role.name,
        showEndIcon: false,
        endIconType: 'indegene-selectors-remove-icon',
        endIconCssClass: 'remove-icon',
        mainItemCssClass: 'selector-item',
        clickHandler: (id: string, event, specificTarget) => {}
      };
    });
    setTimeout(() => {
      this.uiService.dismissLoader();
    }, 200);
  }

  private async handlePrimaryTargetField(data){
    if (this.isPrimaryTargetFieldEnabled) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'oportunity-primary-target-field',
        data: [
          {
            title: this.translate.instant('ACCOUNT'),
            id: OpportunityPrimaryTraget.ACCOUNT.toString(),
            isSelected: this.opportunity.primaryTargetValue == OpportunityPrimaryTraget.ACCOUNT,
          },
          {
            title: this.translate.instant('CONTACT_FORM'),
            id: OpportunityPrimaryTraget.CONTACT.toString(),
            isSelected: this.opportunity.primaryTargetValue == OpportunityPrimaryTraget.CONTACT,
          },
        ],
      };
      this._opportunityPrimaryTargetPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data.event });
      this._opportunityPrimaryTargetPopover.onDidDismiss().then(async (data) => {
        data = data.data;
        if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.opportunity.primaryTargetValue) {
          this.opportunity.primaryTargetValue = data.selectedItems[0].id;
          this.opportunity.primaryTargetName = data.selectedItems[0].title;
          this.initNewOpportunityInfoFormFields();
        }
        this._opportunityPrimaryTargetPopover = undefined;
      });
      this._opportunityPrimaryTargetPopover.present();
    }
  }

  private async handleContactSelection(event) {
    await this.checkAffliatedContacts();
    if (!this.isAccountHaveAffiliatedContacts && this.opportunity.accountID) {
      this.notificationService.notify(this.translate.instant('NO_AFFILIATED_CONTACTS_FOR_ACCOUNT'), 'Opportunity-Details', 'top', 'danger');
      return;
    }
    this.uiService.prevView = this.uiService.activeView;
    this.uiService.activeView = '';
    this.contactService.contactPageMode = ComponentViewMode.SELECTION;
    this.contactService.accessedContactListFrom = PageName.NewOpportunityComponent;
    let passedData = {
      type: 'PushedContactSelectionView', 
      callbackEvent: (data: any) => this._handleContactComponentCallback(data),
      contactListData:
      {
          for:'NewOpportunityContactSelection',
          opportunity: this.opportunity
      }
    };
    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, passedData, PageName.KOLDetailsComponent);
  }

  private async _handleContactComponentCallback(data) {
    if (data?.isDone) {
      if (data && data.selectedItem && data.selectedItem.ID != this.opportunity.contactID) {
        this.opportunity.contactID = data.selectedItem.ID;
        this.opportunity.contactName = data.selectedItem.fullName;    
        let affiliatedAccounts:Account[] = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting([this.contactService.getContactByID(this.opportunity.contactID)]);   
        if(!this.opportunity.accountID){
          if(affiliatedAccounts && affiliatedAccounts.length == 1){
            this.opportunity.accountID = affiliatedAccounts[0].id;
            this.opportunity.accountName = affiliatedAccounts[0].accountName;  
          }
        }else {
          if(!(affiliatedAccounts && affiliatedAccounts.some(a=> a.id == this.opportunity.accountID))){
            this.opportunity.accountID = '';
            this.opportunity.accountName = '';  
          }
        }
        this.initNewOpportunityInfoFormFields();
      }else if(data && data.clearValue){
        this.opportunity.contactID = '';
        this.opportunity.contactName = '';
        this.initNewOpportunityInfoFormFields();
      }
      
    }
  }

  private get _isFocusAreaEnabled():boolean {
    let flag:boolean = false;
    if(this.opportunity.opportunityTypeId){
      const opportunityType = this.opportunityService.opportunityTypes.find(a => a.indskr_opportunitytypeid == this.opportunity.opportunityTypeId)
      if (opportunityType.indskr_focusarea) {
        flag = true;
      }
    }
    return flag;
  }

  private async handleFocusAreaField(data) {
    if (data && data.target.value != this.opportunity.focusArea) {
      this.opportunity.focusArea = data.target.value;
      this.opportunity.pendingPushToDynamics = true;
      const OppName = this.opportunity.opportunityName;
      if (this.autoSubjectEnabled) {
        this.opportunity.opportunityName = this.opportunityService.setOpportunityDescription(this.opportunity);
      }
      this.initNewOpportunityInfoFormFields();
    }
  }

}
