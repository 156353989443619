<ion-header>
    <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]=true [(searchText)]="callPlanSearchText"
        (onControlClick)="onCloseModal()" (onSearchChange)="fromPlanTab ? onInputForGeneralSearch($event.detail) : onInput($event.detail)"
        (onSearchFocus)="ionSearchFocus($event)" (onSearchBarClick)="clickSearchArea($event)" (onEnterClick)="onClickEnter($event)"></ind-header-left>

    <ion-item-divider *ngIf="!fromPlanTab" sticky='true' class="keyControlsArea" color="light">
        <ion-button *ngIf="callPlanOfflineService.contactPageMode!=compViewMode.READONLY" item-left icon-only fill="clear"
          color="primary" (click)="saveAdvancedSearch()" [disabled]="!searching || disableSaveSearch">
            {{ 'SAVE_SEARCH' | translate}}
        </ion-button>
    </ion-item-divider>
    <div class="selectedSearchSuggestions" color="light" *ngIf="selectedSuggestionsData && selectedSuggestionsData.length">
        <ion-icon (click)="removeAllSelectedSuggestions()" class="clearSuggestions"></ion-icon>
        <span *ngFor="let suggestion of selectedSuggestionsData" class="suggestionNameBubble">
            {{suggestion.selectedFacet}}
            <img src="assets/imgs/close_pill.svg" class="removeButton" (click)="removeSelectedSuggestion(suggestion)"/>
        </span>
    </div>
    <!-- Segment for Agenda Page -->
    <ion-toolbar *ngIf="fromPlanTab" class="container-c">
        <ind-tabs [data]="agendaPlanTabs" [(selectedTab)]="uiService.activitiesPageTab"
          (selectedTabChange)="onSelectedTabChange($event)"></ind-tabs>
    </ion-toolbar>
    <!-- Segment for my call plans or team plans -->
    <ion-toolbar *ngIf="!fromPlanTab" no-padding>
        <ind-tabs [data]="tabsData" [(selectedTab)]="callPlanMode" (selectedTabChange)="segmentChange($event)"></ind-tabs>
    </ion-toolbar>
</ion-header>

<!--div class="leftPaneContainer"-->
<ion-content #Content
  [ngClass]="{'vectorimageleft': (!searching && this.callPlanMode == 'mine' && (this.myFormattedCallPlans
                                  && (this.myFormattedCallPlans?.past?.length <= 0 && this.myFormattedCallPlans?.present?.length <= 0
                                  && this.myFormattedCallPlans?.future?.length <= 0)))
                                  || (!searching && this.callPlanMode == 'team' && (this.teamFormattedCallPlans
                                    && (this.teamFormattedCallPlans?.past?.length <= 0 && this.teamFormattedCallPlans?.present?.length <= 0
                                    && this.teamFormattedCallPlans?.future?.length <= 0)))}">
  <div [ngSwitch]="callPlanMode" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
    <!-- My Call Plans -->
    <div *ngSwitchCase="'mine'">
      <ng-container *ngIf="!searching">
        <ion-item-group *ngIf="callPlanOfflineService.loadedCallPlans">
          <ion-list class="call-plan-list" *ngIf="callPlanOfflineService.loadedCallPlans && (myFormattedCallPlans?.past?.length > 0
                                            || myFormattedCallPlans?.present?.length > 0 || myFormattedCallPlans?.future?.length > 0)">
            <ind-section-header [viewData]="mycallplanPastHeaderModel"></ind-section-header>
            <ion-item *ngIf="myFormattedCallPlans?.past?.length === 0">
                <p class="no-call-plan">{{'NO_PAST_CALL_PLANS' | translate}}</p>
            </ion-item>
            <ion-item-group *ngFor="let plan of myFormattedCallPlans?.past | orderBy:'cycleplanname':true"
              (click)="handleCallPlanSelection(plan)"
              [ngClass]="{'itemSelected': plan?.cycleplanid === callPlanOfflineService.selectedCallPlan?.cycleplanid}">
              <ion-item>
                <ion-label class="text-wrapper">
                  <ion-text class="primary-text">
                      <h3>{{plan.cycleplanname}}</h3>
                  </ion-text>
                </ion-label>
                <div class="green-dot">
                  <img slot="end" *ngIf="plan.allRepCallPlansApproved" src="assets/imgs/contact_conn.png" width="14"
                    class="contact-state-icon" />
                </div>
                <div class="percent-div-width">
                    <span slot="end" class="compPercent">
                      {{ plan.actualCalls ? ((plan.actualCalls / plan.totalGoalCalls) * 100).toFixed(0) : 0 }}%
                    </span>
                </div>
              </ion-item>
                <div class="separationLine"></div>
            </ion-item-group>

            <ind-section-header [viewData]="mycallplanCurrentHeaderModel"></ind-section-header>
            <ion-item *ngIf="myFormattedCallPlans?.present?.length === 0">
              <p class="no-call-plan">{{'NO_CURRENT_CALL_PLANS' | translate}}</p>
            </ion-item>
            <ion-item-group *ngFor="let plan of myFormattedCallPlans?.present | orderBy:'cycleplanname':true"
              (click)="handleCallPlanSelection(plan)"
              [ngClass]="{'itemSelected': plan?.cycleplanid === callPlanOfflineService.selectedCallPlan?.cycleplanid}">
              <ion-item>
                <ion-label class="text-wrapper">
                <ion-text class="primary-text">
                  <h3>{{plan.cycleplanname}}</h3>
                </ion-text>
                </ion-label>
                <div class="green-dot">
                  <img slot="end" *ngIf="plan.allRepCallPlansApproved" src="assets/imgs/contact_conn.png" width="14"
                  class="contact-state-icon" />
                </div>
                <div class="percent-div-width">
                  <span slot="end" class="compPercent">
                    {{ plan.actualCalls ? ((plan.actualCalls / plan.totalGoalCalls) * 100).toFixed(0) : 0 }}%
                  </span>
                </div>
              </ion-item>
              <div class="separationLine"></div>
            </ion-item-group>

            <ind-section-header [viewData]="mycallplanFutureHeaderModel"></ind-section-header>
            <ion-item *ngIf="myFormattedCallPlans?.future?.length === 0">
                <p class="no-call-plan">{{'NO_FUTURE_CALL_PLANS' | translate}}</p>
            </ion-item>
            <ion-item-group *ngFor="let plan of myFormattedCallPlans?.future | orderBy:'cycleplanname':true"
                (click)="handleCallPlanSelection(plan)"
                [ngClass]="{'itemSelected': plan?.cycleplanid === callPlanOfflineService.selectedCallPlan?.cycleplanid}">
                <ion-item>
                    <ion-label class="text-wrapper">
                        <ion-text class="primary-text">
                            <h3>{{plan.cycleplanname}}</h3>
                        </ion-text>
                    </ion-label>
                    <div class="green-dot">
                        <img slot="end" *ngIf="plan.allRepCallPlansApproved" src="assets/imgs/contact_conn.png" width="14"
                        class="contact-state-icon" />
                    </div>
                    <div class="percent-div-width">
                        <span slot="end" class="compPercent">
                          {{ plan.actualCalls ? ((plan.actualCalls / plan.totalGoalCalls) * 100).toFixed(0) : 0 }}%
                        </span>
                    </div>
                </ion-item>
                <div class="separationLine"></div>
            </ion-item-group>
          </ion-list>
          <div *ngIf="myFormattedCallPlans?.past?.length == 0 && myFormattedCallPlans?.present?.length == 0
                      && myFormattedCallPlans?.future?.length == 0">
            <ind-section-header [viewData]="mycallplanPastHeaderModel"></ind-section-header>
            <ion-item *ngIf="myFormattedCallPlans?.past?.length === 0">
                <p class="no-call-plan">{{'NO_PAST_CALL_PLANS' | translate}}</p>
            </ion-item>

            <ind-section-header [viewData]="mycallplanCurrentHeaderModel"></ind-section-header>
            <ion-item *ngIf="myFormattedCallPlans?.present?.length === 0">
                <p class="no-call-plan">{{'NO_CURRENT_CALL_PLANS' | translate}}</p>
            </ion-item>

            <ind-section-header [viewData]="mycallplanFutureHeaderModel"></ind-section-header>
            <ion-item *ngIf="myFormattedCallPlans?.future?.length === 0">
                <p class="no-call-plan">{{'NO_FUTURE_CALL_PLANS' | translate}}</p>
            </ion-item>
          </div>
        </ion-item-group>
        <ion-item-group *ngIf="!callPlanOfflineService.loadedCallPlans">
            <ind-section-header [viewData]="mycallplanPastHeaderModel"></ind-section-header>
            <ion-item *ngIf="myFormattedCallPlans?.past?.length === 0">
                <p class="no-call-plan">{{'NO_PAST_CALL_PLANS' | translate}}</p>
            </ion-item>
            <ind-section-header [viewData]="mycallplanCurrentHeaderModel"></ind-section-header>
            <ion-item *ngIf="myFormattedCallPlans?.present?.length === 0">
                <p class="no-call-plan">{{'NO_CURRENT_CALL_PLANS' | translate}}</p>
            </ion-item>
            <ind-section-header [viewData]="mycallplanFutureHeaderModel"></ind-section-header>
            <ion-item *ngIf="myFormattedCallPlans?.future?.length === 0">
                <p class="no-call-plan">{{'NO_FUTURE_CALL_PLANS' | translate}}</p>
            </ion-item>
        </ion-item-group>
      </ng-container>
      <!-- My Call Plans Advanced Search -->
      <ng-container *ngIf="searching">
          <ng-container *ngIf="!(filteredList?.past?.length === 0 && filteredList?.present?.length === 0
                                && filteredList?.future?.length === 0) else noSearchResult;">
              <ion-item-group *ngIf="callPlanOfflineService.loadedCallPlans">
                  <ion-list class="call-plan-list" *ngIf="callPlanOfflineService.loadedCallPlans && (filteredList?.past?.length > 0
                                                          || filteredList?.present?.length > 0 || filteredList?.future?.length > 0)">
                      <ind-section-header [viewData]="filteredListPastHeaderModel"></ind-section-header>
                      <ion-item *ngIf="filteredList?.past?.length===0">
                          <p class="no-call-plan">{{'NO_PAST_CALL_PLANS' | translate}}</p>
                      </ion-item>
                      <ion-item-group *ngFor="let plan of filteredList?.past | orderBy:'cycleplanname':true"
                        (click)="handleCallPlanSelection(plan)"
                        [ngClass]="{'itemSelected': plan?.cycleplanid === callPlanOfflineService.selectedCallPlan?.cycleplanid}">
                          <ion-item>
                              <ion-label class="text-wrapper">
                                  <ion-text class="primary-text">
                                      <h3>{{plan.cycleplanname}}</h3>
                                  </ion-text>
                              </ion-label>
                              <div class="green-dot">
                                  <img slot="end" *ngIf="plan.allRepCallPlansApproved" src="assets/imgs/contact_conn.png" width="14"
                                    class="contact-state-icon" />
                              </div>
                              <div class="percent-div-width">
                                  <span slot="end" class="compPercent">
                                    {{ plan.actualCalls ? ((plan.actualCalls / plan.totalGoalCalls) * 100).toFixed(0) : 0 }}%
                                  </span>
                              </div>
                          </ion-item>
                          <div class="separationLine"></div>
                      </ion-item-group>

                      <ind-section-header [viewData]="filteredListCurrentHeaderModel"></ind-section-header>
                      <ion-item *ngIf="filteredList?.present?.length === 0">
                          <p class="no-call-plan">{{'NO_CURRENT_CALL_PLANS' | translate}}</p>
                      </ion-item>
                      <ion-item-group *ngFor="let plan of filteredList?.present | orderBy:'cycleplanname':true"
                       (click)="handleCallPlanSelection(plan)"
                       [ngClass]="{'itemSelected': plan?.cycleplanid === callPlanOfflineService.selectedCallPlan?.cycleplanid}">
                          <ion-item>
                              <ion-label class="text-wrapper">
                                  <ion-text class="primary-text">
                                      <h3>{{plan.cycleplanname}}</h3>
                                  </ion-text>
                              </ion-label>
                              <div class="green-dot">
                                  <img slot="end" *ngIf="plan.allRepCallPlansApproved" src="assets/imgs/contact_conn.png" width="14"
                                   class="contact-state-icon" />
                              </div>
                              <div class="percent-div-width">
                                  <span slot="end" class="compPercent">
                                    {{ plan.actualCalls ? ((plan.actualCalls / plan.totalGoalCalls) * 100).toFixed(0) : 0 }}%
                                  </span>
                              </div>
                          </ion-item>
                          <div class="separationLine"></div>
                      </ion-item-group>

                      <ind-section-header [viewData]="filteredListFutureHeaderModel"></ind-section-header>
                      <ion-item *ngIf="filteredList?.future?.length === 0">
                          <p class="no-call-plan">{{'NO_FUTURE_CALL_PLANS' | translate}}</p>
                      </ion-item>
                      <ion-item-group *ngFor="let plan of filteredList?.future | orderBy:'cycleplanname':true"
                       (click)="handleCallPlanSelection(plan)"
                       [ngClass]="{'itemSelected': plan?.cycleplanid === callPlanOfflineService.selectedCallPlan?.cycleplanid}">
                          <ion-item>
                              <ion-label class="text-wrapper">
                                  <ion-text class="primary-text">
                                      <h3>{{plan.cycleplanname}}</h3>
                                  </ion-text>
                              </ion-label>
                              <div class="green-dot">
                                  <img slot="end" *ngIf="plan.allRepCallPlansApproved" src="assets/imgs/contact_conn.png" width="14"
                                   class="contact-state-icon" />
                              </div>
                              <div class="percent-div-width">
                                  <span slot="end" class="compPercent">
                                    {{ plan.actualCalls ? ((plan.actualCalls / plan.totalGoalCalls) * 100).toFixed(0) : 0 }}%
                                  </span>
                              </div>
                          </ion-item>
                          <div class="separationLine"></div>
                      </ion-item-group>
                  </ion-list>
                  <div *ngIf="filteredList?.past?.length == 0 && filteredList?.present?.length == 0 && filteredList?.future?.length == 0">
                      <div class="no-data-message">{{'NO_RESULTS_FOR_THIS_SEARCH' | translate}}</div>
                  </div>
              </ion-item-group>
              <ion-item-group *ngIf="!callPlanOfflineService.loadedCallPlans">
                  <ind-section-header [viewData]="filteredListPastHeaderModel"></ind-section-header>
                  <ion-item *ngIf="filteredList?.past?.length === 0">
                      <p class="no-call-plan">{{'NO_PAST_CALL_PLANS' | translate}}</p>
                  </ion-item>
                  <ind-section-header [viewData]="filteredListCurrentHeaderModel"></ind-section-header>
                  <ion-item *ngIf="filteredList?.present?.length === 0">
                      <p class="no-call-plan">{{'NO_CURRENT_CALL_PLANS' | translate}}</p>
                  </ion-item>
                  <ind-section-header [viewData]="filteredListFutureHeaderModel"></ind-section-header>
                  <ion-item *ngIf="filteredList?.future?.length === 0">
                      <p class="no-call-plan">{{'NO_FUTURE_CALL_PLANS' | translate}}</p>
                  </ion-item>
              </ion-item-group>
          </ng-container>
      </ng-container>
    </div>

    <!-- Team Call Plans -->
    <div *ngSwitchCase="'team'">
        <ng-container *ngIf="!searching">
            <ion-item-group *ngIf="waitingForTeamPlans">
              <ion-item>
                <p class="no-call-plan">{{(device.isOffline?'TEAM_CALLPLANS_ONLINE_ONLY':'NO_TEAM_CALL_PLANS') | translate}}</p>
              </ion-item>
            </ion-item-group>
            <ion-item-group *ngIf="!waitingForTeamPlans && !callPlanOfflineService.teamSegmentCallPlans.length">
                <ion-item>
                    <p class="no-call-plan">{{(device.isOffline?'TEAM_CALLPLANS_ONLINE_ONLY':'NO_TEAM_CALL_PLANS') | translate}}</p>
                </ion-item>
            </ion-item-group>
            <ion-item-group *ngIf="!waitingForTeamPlans && callPlanOfflineService.teamSegmentCallPlans.length">
                <ion-list class="call-plan-list" *ngIf="callPlanOfflineService.loadedTeamCallPlans
                                                  && (teamFormattedCallPlans?.past?.length > 0
                                                  || teamFormattedCallPlans?.present?.length > 0
                                                  || teamFormattedCallPlans?.future?.length > 0)">
                    <ind-section-header [viewData]="teamCallplanPastHeaderModel"></ind-section-header>
                    <ion-item *ngIf="teamFormattedCallPlans?.past?.length === 0">
                        <p class="no-call-plan">{{'NO_TEAM_PAST_CALL_PLANS' | translate}}</p>
                    </ion-item>
                    <ion-item-group *ngFor="let plan of teamFormattedCallPlans?.past | orderBy:'cycleplanname':true"
                        (click)="handleCallPlanSelection(plan)"
                        [ngClass]="{'itemSelected': plan === callPlanOfflineService.selectedCallPlan}">
                        <ion-item>
                            <ion-label class="text-wrapper">
                                <ion-text class="primary-text">
                                    <h3>{{plan.cycleplanname}}</h3>
                                </ion-text>
                            </ion-label>
                            <div class="green-dot">
                                <img slot="end" *ngIf="plan.allRepCallPlansApproved" src="assets/imgs/contact_conn.png" width="14"
                                  class="contact-state-icon" />
                            </div>
                            <div class="percent-div-width">
                                <span slot="end" class="compPercent">
                                  {{ plan.actualCalls ? ((plan.actualCalls / plan.totalGoalCalls) * 100).toFixed(0) : 0 }}%
                                </span>
                            </div>
                        </ion-item>
                        <div class="separationLine"></div>
                    </ion-item-group>

                    <ind-section-header [viewData]="teamCallplanCurrentHeaderModel"></ind-section-header>
                    <ion-item *ngIf="teamFormattedCallPlans?.present?.length === 0">
                        <p class="no-call-plan">{{'NO_TEAM_CURRENT_CALL_PLANS' | translate}}</p>
                    </ion-item>
                    <ion-item-group *ngFor="let plan of teamFormattedCallPlans?.present | orderBy:'cycleplanname':true"
                      (click)="handleCallPlanSelection(plan)"
                      [ngClass]="{'itemSelected': plan === callPlanOfflineService.selectedCallPlan}">
                        <ion-item>
                            <ion-label class="text-wrapper">
                                <ion-text class="primary-text">
                                    <h3>{{plan.cycleplanname}}</h3>
                                </ion-text>
                            </ion-label>
                            <div class="green-dot">
                                <img slot="end" *ngIf="plan.allRepCallPlansApproved" src="assets/imgs/contact_conn.png" width="14"
                                  class="contact-state-icon" />
                            </div>
                            <div class="percent-div-width">
                                <span slot="end" class="compPercent">
                                  {{ plan.actualCalls ? ((plan.actualCalls / plan.totalGoalCalls) * 100).toFixed(0) : 0 }}%
                                </span>
                            </div>
                        </ion-item>
                        <div class="separationLine"></div>
                    </ion-item-group>

                    <ind-section-header [viewData]="teamCallplanFutureHeaderModel"></ind-section-header>
                    <ion-item *ngIf="teamFormattedCallPlans?.future?.length === 0">
                        <p class="no-call-plan">{{'NO_TEAM_FUTURE_CALL_PLANS' | translate}}</p>
                    </ion-item>
                    <ion-item-group *ngFor="let plan of teamFormattedCallPlans?.future | orderBy:'cycleplanname':true"
                      (click)="handleCallPlanSelection(plan)"
                      [ngClass]="{'itemSelected': plan === callPlanOfflineService.selectedCallPlan}">
                        <ion-item>
                            <ion-label class="text-wrapper">
                                <ion-text class="primary-text">
                                    <h3>{{plan.cycleplanname}}</h3>
                                </ion-text>
                            </ion-label>
                            <div class="green-dot">
                                <img slot="end" *ngIf="plan.allRepCallPlansApproved" src="assets/imgs/contact_conn.png" width="14"
                                class="contact-state-icon" />
                            </div>
                            <div class="percent-div-width">
                                <span slot="end" class="compPercent">
                                  {{ plan.actualCalls ? ((plan.actualCalls / plan.totalGoalCalls) * 100).toFixed(0) : 0 }}%
                                </span>
                            </div>
                        </ion-item>
                        <div class="separationLine"></div>
                    </ion-item-group>
                </ion-list>
                <div *ngIf="teamFormattedCallPlans?.past?.length == 0 && teamFormattedCallPlans?.present?.length == 0
                            && teamFormattedCallPlans?.future?.length == 0">
                    <ind-section-header [viewData]="teamCallplanPastHeaderModel"></ind-section-header>
                    <ion-item *ngIf="teamFormattedCallPlans?.past?.length === 0">
                        <p class="no-call-plan">{{'NO_TEAM_PAST_CALL_PLANS' | translate}}</p>
                    </ion-item>

                    <ind-section-header [viewData]="teamCallplanCurrentHeaderModel"></ind-section-header>
                    <ion-item *ngIf="teamFormattedCallPlans?.present?.length === 0">
                        <p class="no-call-plan">{{'NO_TEAM_CURRENT_CALL_PLANS' | translate}}</p>
                    </ion-item>

                    <ind-section-header [viewData]="teamCallplanFutureHeaderModel"></ind-section-header>
                    <ion-item *ngIf="teamFormattedCallPlans?.future?.length === 0">
                        <p class="no-call-plan">{{'NO_TEAM_FUTURE_CALL_PLANS' | translate}}</p>
                    </ion-item>
                </div>
            </ion-item-group>
        </ng-container>
        <!-- Team Call Plans Advanced Search -->
        <ng-container *ngIf="searching">
            <ion-item-group *ngIf="!callPlanOfflineService.teamSegmentCallPlans">
                <ion-item>
                    <p class="no-data-message">{{'NO_TEAM_CALL_PLANS' | translate}}</p>
                </ion-item>
            </ion-item-group>
            <ion-item-group *ngIf="callPlanOfflineService.teamSegmentCallPlans">
                <ng-container *ngIf="!(filteredList?.past?.length === 0 && filteredList?.present?.length === 0
                                      && filteredList?.future?.length === 0) else noSearchResult;">
                    <ion-list class="call-plan-list" *ngIf="callPlanOfflineService.loadedTeamCallPlans
                                                      && (filteredList?.past?.length > 0 || filteredList?.present?.length > 0
                                                      || filteredList?.future?.length > 0)">
                        <ind-section-header [viewData]="filteredListPastHeaderModel"></ind-section-header>
                        <ion-item *ngIf="filteredList?.past?.length === 0">
                            <p class="no-call-plan">{{'NO_TEAM_PAST_CALL_PLANS' | translate}}</p>
                        </ion-item>
                        <ion-item-group *ngFor="let plan of filteredList?.past | orderBy:'cycleplanname':true"
                          (click)="handleCallPlanSelection(plan)"
                          [ngClass]="{'itemSelected': plan === callPlanOfflineService.selectedCallPlan}">
                            <ion-item>
                                <ion-label class="text-wrapper">
                                    <ion-text class="primary-text">
                                        <h3>{{plan.cycleplanname}}</h3>
                                    </ion-text>
                                </ion-label>
                                <div class="green-dot">
                                    <img slot="end" *ngIf="plan.allRepCallPlansApproved" src="assets/imgs/contact_conn.png" width="14"
                                    class="contact-state-icon" />
                                </div>
                                <div class="percent-div-width">
                                    <span slot="end" class="compPercent">
                                      {{ plan.actualCalls ? ((plan.actualCalls / plan.totalGoalCalls) * 100).toFixed(0) : 0 }}%
                                    </span>
                                </div>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>

                        <ind-section-header [viewData]="filteredListCurrentHeaderModel"></ind-section-header>
                        <ion-item *ngIf="filteredList?.present?.length === 0">
                            <p class="no-call-plan">{{'NO_TEAM_CURRENT_CALL_PLANS' | translate}}</p>
                        </ion-item>
                        <ion-item-group *ngFor="let plan of filteredList?.present | orderBy:'cycleplanname':true"
                          (click)="handleCallPlanSelection(plan)"
                          [ngClass]="{'itemSelected': plan === callPlanOfflineService.selectedCallPlan}">
                            <ion-item>
                                <ion-label class="text-wrapper">
                                    <ion-text class="primary-text">
                                        <h3>{{plan.cycleplanname}}</h3>
                                    </ion-text>
                                </ion-label>
                                <div class="green-dot">
                                    <img slot="end" *ngIf="plan.allRepCallPlansApproved" src="assets/imgs/contact_conn.png" width="14"
                                      class="contact-state-icon" />
                                </div>
                                <div class="percent-div-width">
                                    <span slot="end" class="compPercent">
                                      {{ plan.actualCalls ? ((plan.actualCalls / plan.totalGoalCalls) * 100).toFixed(0) : 0 }}%
                                    </span>
                                </div>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>

                        <ind-section-header [viewData]="filteredListFutureHeaderModel"></ind-section-header>
                        <ion-item *ngIf="filteredList?.future?.length === 0">
                            <p class="no-call-plan">{{'NO_TEAM_FUTURE_CALL_PLANS' | translate}}</p>
                        </ion-item>
                        <ion-item-group *ngFor="let plan of filteredList?.future | orderBy:'cycleplanname':true"
                          (click)="handleCallPlanSelection(plan)"
                          [ngClass]="{'itemSelected': plan === callPlanOfflineService.selectedCallPlan}">
                            <ion-item>
                                <ion-label class="text-wrapper">
                                    <ion-text class="primary-text">
                                        <h3>{{plan.cycleplanname}}</h3>
                                    </ion-text>
                                </ion-label>
                                <div class="green-dot">
                                    <img slot="end" *ngIf="plan.allRepCallPlansApproved" src="assets/imgs/contact_conn.png" width="14"
                                     class="contact-state-icon" />
                                </div>
                                <div class="percent-div-width">
                                    <span slot="end" class="compPercent">
                                      {{ plan.actualCalls ? ((plan.actualCalls / plan.totalGoalCalls) * 100).toFixed(0) : 0 }}%
                                    </span>
                                </div>
                            </ion-item>
                            <div class="separationLine"></div>
                        </ion-item-group>
                    </ion-list>
                    <div *ngIf="filteredList?.past?.length == 0 && filteredList?.present?.length == 0 && filteredList?.future?.length == 0">
                        <div class="no-data-message">{{'NO_RESULTS_FOR_THIS_SEARCH' | translate}}</div>
                    </div>
                </ng-container>
            </ion-item-group>
        </ng-container>
    </div>
  </div>
</ion-content>

<!-- <ion-footer *ngIf="!fromPlanTab"> -->
    <footer-toolbar *ngIf="!fromPlanTab" [footerView]="'masterView'" [selectedView]="'callplans'"></footer-toolbar>
<!-- </ion-footer> -->
<!-- Search Suggestion Popover -->
<search-suggestion-popover (click)="clickedInSuggestionsArea($event)" (selectionMade)="handleFacetSelection($event)"
    [suggestionsData]="suggestionsData" [searchKey]="searchKey" *ngIf='suggestionsActive && !fromPlanTab'
    (selectSavedSearch)="handleSavedSearchSelection($event)">
</search-suggestion-popover>


<ng-template #noSearchResult>
    <ind-section-header [viewData]='noSearchResultHeaderModel'></ind-section-header>
    <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
</ng-template>
