import { TrackingEventNames } from './../../../services/logging/tracking.service';
import { OpportunityManagementService } from './../../../services/opportunity-management/opportunity-management.service';
import { OpportunityManagementDataService } from './../../../data-services/opportunity-management/opportunity-management.data.service';
import { Component, ViewChild, Input } from '@angular/core';
import { NavController,  IonNav } from '@ionic/angular';
import { Events } from '@omni/events';
import { NavigationService, ChildNavNames, PageName } from '../../../services/navigation/navigation.service';
import { UIService, ComponentViewMode } from '../../../services/ui/ui.service';
import { DeviceService } from '../../../services/device/device.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { TrackService } from '../../../services/logging/tracking.service';
import { OrderManagementService } from '../../../services/order-management/order-management.service';
import { AccountOfflineService } from '../../../services/account/account.offline.service';
import { OpportunitiesListComponent } from '../opportunities-list/opportunities-list';
import { NewOpportunityComponent } from '../new-opportunity/new-opportunity';
import { Account } from '../../../classes/account/account.class';
import { ActivityService } from '@omni/services/activity/activity.service';
import { ActivityType } from '@omni/classes/activity/activity.class';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { Contact } from '@omni/classes/contact/contact.class';
import { OpportunityPrimaryTraget } from '@omni/classes/opportunity-management/opportunity.class';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@omni/services/authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';

/**
 * Generated class for the OpportunityManagementPageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'opportunity-management-page[base-page]',
  templateUrl: 'opportunity-management-page.html',
  styleUrls:['opportunity-management-page.scss']
})
export class OpportunityManagementPageComponent {

  public accountListMode: ComponentViewMode;
  @Input() invokeCreateOpportunity: boolean;
  @Input() contactToCreateOpportunity: Contact;
  @Input () accountToCreateOpportunity : Account;
  @ViewChild('opportunitymanagementrightpane', {static:true}) public navCtrl: IonNav;
  private isPrimaryTargetFieldEnabled: boolean = false;

  constructor(
    public navService: NavigationService,
    public uiService: UIService,
    public device: DeviceService,
    private footerService: FooterService,
    public trackingService: TrackService,
    public accountService: AccountOfflineService,
    private events: Events,
    public opportunityDataService: OpportunityManagementDataService,
    private opportunityService: OpportunityManagementService,
    private activityService: ActivityService,
    private translate: TranslateService,
    public authService: AuthenticationService,
  ) {
    this.accountListMode = ComponentViewMode.LISTVIEW;
  }

  ngOnInit() {
    this.accountService.accessedAccountListFrom = PageName.OpportunityManagementPage;
    this.navService.initRightPaneChildNav(this.navCtrl,ChildNavNames.OpportunityManagementNavigation,PageName.OpportunityManagementPage,false,{deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'});
    this.footerService.initButtons('');
    if(this.invokeCreateOpportunity || this.contactToCreateOpportunity || this.accountToCreateOpportunity){
      this.createNewOpportunity(false);
    }
    //if(this.opportunityService.preSelectedAccount) this.openAccountDetail(this.opportunityService.preSelectedAccount)
    this.isPrimaryTargetFieldEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_PRIMARY_TARGET);
  }

  ionViewDidEnter() {
  }

  public isSplitView(): boolean {
    return (window.innerWidth > 500);
  }

  public closeAccountModal(data:boolean):void {
    this.navService.popChildNavCtrlFromStack(ChildNavNames.OpportunityManagementNavigation);
    this.navService.popToRootWithPageTracking();
  }

  ngOnDestroy(){
    this.navService.popChildNavCtrlFromStack(ChildNavNames.OpportunityManagementNavigation).then(() => {
      this.events.publish("tools-back-clicked", PageName.OpportunityManagementPage);
    });
  }

  public openAccountDetail(account:Account):void {
    this.trackingService.tracking('SelectAccount', TrackingEventNames.OPPORTUNITYMANAGEMENT);
    if(!this.device.isOffline)this.opportunityDataService.getOpportunitiesData(false, account.id);
    this.navService.setChildNavRoot(OpportunitiesListComponent, PageName.OpportunitiesListComponent, PageName.OpportunityManagementPage, { from: 'OpportunityTool', account: account });
    this.footerService.initButtons(FooterViews.Accounts);
    this.navService.setChildNavRightPaneView(true);
  }

  public createNewOpportunity(flag):void {
    if(this.navService.getActiveChildNavViewPageName() != PageName.NewOpportunityComponent){
      if(flag){
        this.trackingService.tracking('CreateClicked', TrackingEventNames.OPPORTUNITYMANAGEMENT);
      }
      this.navService.pushChildNavPageWithPageTracking(NewOpportunityComponent,PageName.NewOpportunityComponent,PageName.OpportunityManagementPage, {from: 'OpportunityTool'}).then(() => {
        this.navService.setChildNavRightPaneView(true);
        if(this.invokeCreateOpportunity && this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Appointment && (this.activityService.selectedActivity as AppointmentActivity).accounts && (this.activityService.selectedActivity as AppointmentActivity).accounts.length == 1){
          let opportunity = this.opportunityService.newOpportunity$.value;
          opportunity.accountID = (this.activityService.selectedActivity as AppointmentActivity).accounts[0].id
          opportunity.accountName = (this.activityService.selectedActivity as AppointmentActivity).accounts[0].accountName;
          opportunity.appointmentId = (this.activityService.selectedActivity as AppointmentActivity).ID;
          this.opportunityService.newOpportunity$.next(opportunity);
        }else if(this.contactToCreateOpportunity && this.contactToCreateOpportunity.accountRelationships && this.contactToCreateOpportunity.accountRelationships[0]){
          let opportunity = this.opportunityService.newOpportunity$.value;
          let account;
          for(let i = 0; i < this.contactToCreateOpportunity.accountRelationships.length && !account; i++) {
            account  = this.accountService.getAccountById(this.contactToCreateOpportunity.accountRelationships[i].accountId);
          }
          if(account) {
            opportunity.accountID = account.id;
            opportunity.accountName = account.accountName;
          }
          opportunity.stakeholders.push({
            ID: this.contactToCreateOpportunity.ID,
            firstName: this.contactToCreateOpportunity.firstName,
            fullName: this.contactToCreateOpportunity.fullName,
            isActive: this.contactToCreateOpportunity.isActive,
            lastName: this.contactToCreateOpportunity.lastName,
            role: {roleid: this.opportunityService.stakeholderRoles[0].connectionroleid, name: this.opportunityService.stakeholderRoles[0].name}
          });
          if (this.isPrimaryTargetFieldEnabled) {
            opportunity.primaryTargetValue = OpportunityPrimaryTraget.ACCOUNT;
            opportunity.primaryTargetName = this.translate.instant('ACCOUNT');
          } 
          this.opportunityService.newOpportunity$.next(opportunity);
        }
        else if (this.accountToCreateOpportunity) {
          let opportunity = this.opportunityService.newOpportunity$.value;
          let account;
          account = this.accountService.getAccountById(this.accountToCreateOpportunity.id);
          if (account) {
            opportunity.accountID = account.id;
            opportunity.accountName = account.accountName;
          }
          if (this.isPrimaryTargetFieldEnabled) {
            opportunity.primaryTargetValue = OpportunityPrimaryTraget.ACCOUNT;
            opportunity.primaryTargetName = this.translate.instant('ACCOUNT');
          }
          this.opportunityService.newOpportunity$.next(opportunity);
        }
      })
    }
  }


}
