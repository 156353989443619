<ion-header>
  <ion-toolbar>
    <label>{{'FILTER' | translate}}</label>
    <img  src="assets/imgs/action_button_cancel.png" class="" alt="Close" (click)="closeModal('close')">
  </ion-toolbar>
</ion-header>
<ion-content #content>
  <div #contentWrapper class="wrapper">
    <div class="fields-contatiner" *ngIf="tags?.length != 0; else noContentTags">
      <div class="sc-field" *ngFor="let parentTag of tags">
        <div class="field-label" (click)="selectParentTag($event, parentTag)">
          <label>{{parentTag.label}}</label>
        </div>
        <div class="field-value"  *ngFor="let childTag of parentTag.tags" [ngClass]="{'selected': childTag.selected}" (click)="selectChildTag($event, parentTag, childTag)">
          <label>{{childTag.label}}</label>
        </div>
      </div>
    </div>
  </div>
</ion-content>
<div class="action-bar">
  <ion-button slot="end" (click)="closeModal('confirm')" [disabled]="confirmDisabled">
    <ion-icon class="submit-icon" src="assets/imgs/filter-white-horz.svg" alt="Confirm"></ion-icon>
    {{'CONFIRM' | translate}}
  </ion-button>
  <ion-button class="cncl-btn" (click)="closeModal('reset')" fill="clear" slot="end" [disabled]="resetDisabled">
    {{'RESET' | translate}}
  </ion-button>
</div>

<ng-template #noContentTags>
  <div class="no-tags">{{'NO_CONTENT_TAGS' | translate}}</div>
</ng-template>
