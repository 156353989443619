
import { Component } from "@angular/core";
import { ModalController, NavParams, PopoverController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { MultiSelectPopover } from "@omni/components/multi-select-popover/multi-select-popover";
import { IndHeaderLeftDataModel } from "@omni/models/indHeaderLeftDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { MainCardViewDataModel } from "@omni/models/MainCardViewDataModel";
import { CustomerAssessService } from "@omni/services/customer-assess/customer-assess.service";
import { EventsService } from "@omni/services/events/events.service";
import { FooterService } from "@omni/services/footer/footer.service";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { ComponentViewMode, UIService } from "@omni/services/ui/ui.service";
import _ from "lodash";
import { differenceInCalendarDays } from 'date-fns';
import { CustomerSurveyService } from "@omni/services/customer-survey/customer-survey.service";
import { SurveyDetailsComponent } from "../survey-details/survey-details";
import { AssessmentTemplate, InternalSurveyStatus, SurveyCategory, SurveyFrequency, SurveyType, TemplateType } from "@omni/classes/customer-assessment/assessment-template.class";
import { IndFilterMenuComponent } from "@omni/components/shared/ind-filter-menu/ind-filter-menu";
import { SelectedSuggestionPillDataModel } from "@omni/models/search-config-data-model";
import { ActivityService, ActivitySource } from "@omni/services/activity/activity.service";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { DatePipe } from "@angular/common";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SearchConfigService } from "@omni/services/search/search-config.service";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { AuthenticationService } from "@omni/services/authentication.service";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { AgendaFooterService, AgendaFooterView } from "@omni/services/footer/agenda-footer.service";
import { FeatureActionsService } from "@omni/services/feature-actions/feature-actions.service";
import { AppointmentActivity } from "@omni/classes/activity/appointment.activity.class";


@Component({
  selector: 'survey-list',
  templateUrl: 'survey-list.html',
  styleUrls:['survey-list.scss']
})
export class SurveyListComponent {

  indHeaderLeftModel: IndHeaderLeftDataModel;
  surveyListSectionHeader: IndSectionHeaderViewDataModel;
  surveySearchText: string = '';
  sortBy: any;
  sortPopoverData: ({ text: string; expanded: boolean; value: string; items: { text: string; value: string; asc: boolean; }[]; handler: (selectedItem: any, item: any) => void; })[] | ({ text: string; value: string; items: { text: String; value: String; }[]; handler: (selectedItem: any, item: any) => void; expanded?: undefined; })[];
  public statistics: { id: string, label: string, value: number }[] = [];
  filteredSurveyList: AssessmentTemplate[] = [];
  filteredSurveyListToDisplay: MainCardViewDataModel[] = [];
  searchedSurveyListToDisplay: MainCardViewDataModel[] = [];
  filterPopoverData: { text: string; value: string; items: any; handler: (selectedItem: any, item: any, parent: any) => void; }[];
  public selectedFilterData: SelectedSuggestionPillDataModel[] = [];
  public appointmentId: string;
  public from: any;
  private selectedSurveyTemplateId: string;
  private IallSurveyedContactInfoSubscription: Subscription;
  private ngDestroy$ = new Subject<boolean>();
  public searching: boolean = false;
  private filterOptionData: { text: string; value: string; items: any; }[];
  public isFilteredSurvey: boolean = false;
  private selectedDate: {startDate: string, endDate: string} = {startDate: '', endDate: ''};
  private isContactSurveyEnabled: boolean = false;
  private isAccountSurveyEnabled: boolean = false;
  
  constructor(
    public footerService: FooterService,
    private uiService: UIService,
    public translate: TranslateService,
    public events: EventsService,
    private navService: NavigationService,
    private popoverCtrl: PopoverController,
    public customerAssessService: CustomerAssessService,
    private customerSurveyService: CustomerSurveyService,
    private modalCtrl:ModalController,
    private readonly navParams: NavParams,
    public dateTimeFormatsService: DateTimeFormatsService,
    private datePipe: DatePipe,
    public searchConfigService: SearchConfigService,
    private contactService: ContactOfflineService,
    private authService: AuthenticationService,
    private agendaFooterService: AgendaFooterService,
    private faService: FeatureActionsService,
    private readonly activityService: ActivityService
  ) {


  }

  ngOnInit() {
    this.appointmentId = (this.navParams.data && this.navParams.data.appointmentId) || '';
    this.from = (this.navParams.data && this.navParams.data.from) || '';
    this.isAccountSurveyEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_SURVEY);
    this.isContactSurveyEnabled = this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SURVEY);
    this._initHeaderLeft();
    this.filteredSurveyList = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY && new Date(template.indskr_validfrom) <= new Date() && new Date(template.indskr_validto) >= new Date());
    if(this.from == ActivitySource.MEETING_DETAIL || this.from == ActivitySource.MEETING_PRESENTATION) {
      this.filteredSurveyList = this.filteredSurveyList.filter(list => list.indskr_surveytype != SurveyType.INTERNAL);
      this._initSurveyListForMeeting();
    }
    if((this.isAccountSurveyEnabled && !this.isContactSurveyEnabled) || this.from == PageName.AccountDetailsComponent) this.filteredSurveyList = this.filteredSurveyList.filter(template => template.indskr_entity =='account' && template.indskr_surveytype != SurveyType.INTERNAL);
    else if(!this.isAccountSurveyEnabled && this.isContactSurveyEnabled) {
      this.filteredSurveyList = this.filteredSurveyList.filter(template => template.indskr_entity =='contact');
      if(this.from == PageName.ContactDetailsComponent) this.filteredSurveyList = this.filteredSurveyList.filter(template => template.indskr_surveytype != SurveyType.INTERNAL);
    }
    this.filteredSurveyListToDisplay = [];
    this.searchedSurveyListToDisplay = [];
    this._initViewData();
    this.sortPopoverData = [
      {
        text: "",
        expanded: true,
        value: "surveyName",
        items: [
          { text: this.translate.instant('NAME'), value: "surveyName", asc: true },
          { text: this.translate.instant('EXPIRES_ON'), value: "expireson", asc: false },
          { text: this.translate.instant('USE_TYPE'), value: "use-type", asc: true },
          { text: this.translate.instant('SURVEY_TYPE'), value: "type", asc: true }
        ],
        handler: (selectedItem, item) => {
          item.value = selectedItem.value;
          this.sortBy = selectedItem;
          this.surveyListSectionHeader.controls[1].text = selectedItem.text;
          this._sortSurveyList()
        }
      }
    ];
    this.sortBy = this.sortPopoverData[0].items[0];
    this._sortSurveyList()
    this.customerAssessService.isSurveyFormDirty = false;
    this.setCustomerStatistics();
    this._initSectionHeader();

    this.IallSurveyedContactInfoSubscription = this.customerAssessService.numOfSurveyedInfo$.pipe(takeUntil(this.ngDestroy$)).subscribe(value => {
      if (!_.isEmpty(value)) {
        this._initViewData();
        this._sortSurveyList()
      }
    });
  }

  ngOnDestroy() {
    this.customerSurveyService.selectedContactForSurvey = null;
    this.customerSurveyService.selectedAccountForSurvey = null;
    if (this.IallSurveyedContactInfoSubscription) this.IallSurveyedContactInfoSubscription.unsubscribe();
  }

  private _initSurveyListForMeeting() {
    const meeting = this.activityService.selectedActivity;
    if (meeting instanceof AppointmentActivity && (meeting.contacts?.length == 1 || meeting.accounts?.length == 1)) {
      const accountSurveys = this.filteredSurveyList.filter(template => template.indskr_entity == 'account');
      const contactSurveys = this.filteredSurveyList.filter(template => template.indskr_entity == 'contact');
      let eligibleContactSurveys = [];
      let eligibleAccountSurveys = [];
      if (meeting.contacts?.length == 1) {
        contactSurveys.forEach(survey => {
          const templateId: string = survey.indskr_assessmenttemplateid;
          const selectedContactId = meeting.contacts[0].ID;
          let isEligibleCustomer: boolean = false;
          //check eligible customers
          if (survey.indskr_metadata.hasOwnProperty('EligibleCustomers') && !_.isEmpty(survey.indskr_metadata.EligibleCustomers)) {
            let eligibleCustomersIDs: string[] = [];
            const eligibleCustomersFrommetaData = survey.indskr_metadata['EligibleCustomers'];
            eligibleCustomersIDs = eligibleCustomersFrommetaData.map(customer => customer['ID']);
            isEligibleCustomer = eligibleCustomersIDs.some(contactId => contactId == selectedContactId);
          } else {
            isEligibleCustomer = true;
          }
          const isCapturedOneTimeSurvey: boolean = this.customerAssessService.isCapturedOneTimeSurvey(templateId, selectedContactId, 'contact');
        
          if (isEligibleCustomer) {
            //check captured one-time survey
            if (survey.indskr_surveyfrequency == SurveyFrequency.ONCE) {
              if (!isCapturedOneTimeSurvey) {
                eligibleContactSurveys.push(survey);
              }
            } else {
              eligibleContactSurveys.push(survey);
            }
          }
        });
      } else {
        eligibleContactSurveys = contactSurveys;
      }
      if (meeting.accounts?.length == 1) {
        accountSurveys.forEach(survey => {
          const templateId: string = survey.indskr_assessmenttemplateid;
          const selectedAccountId = meeting.accounts[0].id;
          let isEligibleAccount: boolean = false;
          //check eligible customers
          if (survey.indskr_metadata.hasOwnProperty('EligibleCustomers') && !_.isEmpty(survey.indskr_metadata.EligibleCustomers)) {
            let eligibleAccountIDs: string[] = [];
            const eligibleAccountsFromMetaData = survey.indskr_metadata['EligibleCustomers'];
            eligibleAccountIDs = eligibleAccountsFromMetaData.map(account => account['ID']);
            isEligibleAccount = eligibleAccountIDs.some(accoountId => accoountId == selectedAccountId);
          } else {
            isEligibleAccount = true;
          }
          const surveyedAccountsByTemplate = this.customerAssessService.surveyedAccountsForOnetimeSurvey[templateId];
          let isCapturedOneTimeSurvey: boolean = false;
          if (!_.isEmpty(surveyedAccountsByTemplate)) {
            isCapturedOneTimeSurvey = surveyedAccountsByTemplate.some((template) => template['indskr_entityid'] == selectedAccountId);
          }

          if (isEligibleAccount) {
            //check captured one-time survey
            if (survey.indskr_surveyfrequency == SurveyFrequency.ONCE) {
              if (!isCapturedOneTimeSurvey) {
                eligibleAccountSurveys.push(survey);
              }
            } else {
              eligibleAccountSurveys.push(survey);
            }
          }
        })
      } else {
        eligibleAccountSurveys = accountSurveys;
      }
      this.filteredSurveyList = [...eligibleContactSurveys, ...eligibleAccountSurveys];
    }
  }

  private _initViewData() {
    this.filteredSurveyListToDisplay = this.filteredSurveyList.map(survey => {
      return this._getMainCardDataModel(survey);
    });
  }

  private _updateViewData(templateId: string) {
    let selectedSurveyTemplate: MainCardViewDataModel;
    if(!this.searching) {
      this.filteredSurveyListToDisplay.map(t=>t.isSelected = t.id == templateId);
      selectedSurveyTemplate = this.filteredSurveyListToDisplay.find(t=>t.id == templateId);
    }else {
      this.searchedSurveyListToDisplay.map(t=>t.isSelected = t.id == templateId);
      selectedSurveyTemplate = this.searchedSurveyListToDisplay.find(t=>t.id == templateId);
    }
    const foundSurveyTemplate = this.filteredSurveyList.find(template=>template.indskr_assessmenttemplateid == templateId);
    if(!_.isEmpty(foundSurveyTemplate)) {
      selectedSurveyTemplate.gridItems = [];

      let gridItems: any[];

      if (foundSurveyTemplate.indskr_description) {
        gridItems = [
          {
            id: 'description',
            label: '',
            value: foundSurveyTemplate.indskr_description,
            descriptionField: true,
          },
          {
            id: 'type',
            label: this.translate.instant('SURVEY_TYPE'),
            value: this.getSurveyType(foundSurveyTemplate),
            rawValue: foundSurveyTemplate['indskr_surveytype'],
          },
          {
            id: 'use-type',
            label: this.translate.instant('USE_TYPE'),
            value: this.getSurveyFrequency(foundSurveyTemplate),
            rawValue: foundSurveyTemplate['indskr_surveyfrequency'],
          },
        ];
      } else {
        gridItems = [
          {
            id: 'type',
            label: this.translate.instant('SURVEY_TYPE'),
            value: this.getSurveyType(foundSurveyTemplate),
            rawValue: foundSurveyTemplate['indskr_surveytype'],
          },
          {
            id: 'use-type',
            label: this.translate.instant('USE_TYPE'),
            value: this.getSurveyFrequency(foundSurveyTemplate),
            rawValue: foundSurveyTemplate['indskr_surveyfrequency'],
          },
          {
            id: 'status',
            // label: survey.indskr_surveytype == SurveyType.INTERNAL ? this.translate.instant("STATUS") : survey.indskr_entity == 'contact' ? this.translate.instant("SURVEY_CUSTOMER_SUVEYED") : this.translate.instant("SURVEY_ACCOUNT_SURVEYED"),
            label: foundSurveyTemplate.indskr_surveytype == SurveyType.INTERNAL ? this.translate.instant("STATUS") : foundSurveyTemplate.indskr_entity == 'contact' ? this.translate.instant("CONTACT_ELIGIBLE") : this.translate.instant("ACCOUNT_ELIGIBLE"),
            value: foundSurveyTemplate.indskr_surveytype == SurveyType.INTERNAL ? this.getStatus(foundSurveyTemplate) : foundSurveyTemplate.indskr_entity == 'contact' ? this.getCustomerSurveyed(foundSurveyTemplate) : this.getAccountSurveyed(foundSurveyTemplate),
            rawValue: foundSurveyTemplate.indskr_surveytype == SurveyType.INTERNAL ? this.getStatusId(foundSurveyTemplate) : undefined,
          },
        ];
      }

      selectedSurveyTemplate.gridItems = gridItems;
    }
  }

  private _getMainCardDataModel(survey: AssessmentTemplate) {
    const viewData: MainCardViewDataModel = {
      id: survey.indskr_assessmenttemplateid,
      fixedHeight: true,
      primaryTextRight: survey.indskr_name,
      isSubPrimaryTextRight: true,
      subPrimaryTextRight: this.translate.instant('EXPIRES_ON') + ' ' + this._getFormattedDate(survey['indskr_validto']),
      primaryTextRightIconName: 'chevron-forward-outline',
      primaryTextRightIconClass: 'arrow-icon',
      isSecondaryGrid: true,
      gridItems: [],
      isSelected: this.selectedSurveyTemplateId == survey.indskr_assessmenttemplateid,
      arrowType: 'chevron-forward-outline',
      startIconInline: this.getIconNameByStatus(survey),
      clickHandler: (id: string, event, specificTarget) => {
        this.viewSurvey(id);
      },
      surveyType: survey['indskr_surveytype'],
      surveyUseType: survey['indskr_surveyfrequency'],
      surveyCategory: this.customerAssessService.getSurveyCategoryValue(survey),
      expiresOn: new Date(survey['indskr_validto']),
    };
    let gridItems: any[];

    if (survey.indskr_description) {
      gridItems = [
        {
          id: 'description',
          label: '',
          value: survey.indskr_description,
          descriptionField: true,
        },
        {
          id: 'type',
          label: this.translate.instant('SURVEY_TYPE'),
          value: this.getSurveyType(survey),
          rawValue: survey['indskr_surveytype'],
        },
        {
          id: 'use-type',
          label: this.translate.instant('USE_TYPE'),
          value: this.getSurveyFrequency(survey),
          rawValue: survey['indskr_surveyfrequency'],
        },
      ];
    } else {
      gridItems = [
        {
          id: 'type',
          label: this.translate.instant('SURVEY_TYPE'),
          value: this.getSurveyType(survey),
          rawValue: survey['indskr_surveytype'],
        },
        {
          id: 'use-type',
          label: this.translate.instant('USE_TYPE'),
          value: this.getSurveyFrequency(survey),
          rawValue: survey['indskr_surveyfrequency'],
        },
        {
          id: 'status',
          // label: survey.indskr_surveytype == SurveyType.INTERNAL ? this.translate.instant("STATUS") : survey.indskr_entity == 'contact' ? this.translate.instant("SURVEY_CUSTOMER_SUVEYED") : this.translate.instant("SURVEY_ACCOUNT_SURVEYED"),
          label: survey.indskr_surveytype == SurveyType.INTERNAL ? this.translate.instant("STATUS") : survey.indskr_entity == 'contact' ? this.translate.instant("CONTACT_ELIGIBLE") : this.translate.instant("ACCOUNT_ELIGIBLE"),
          value: survey.indskr_surveytype == SurveyType.INTERNAL ? this.getStatus(survey) : survey.indskr_entity == 'contact' ? this.getCustomerSurveyed(survey) : this.getAccountSurveyed(survey),
          rawValue: survey.indskr_surveytype == SurveyType.INTERNAL ? this.getStatusId(survey) : undefined,
        },
      ];
    }
    viewData.gridItems = gridItems;

    return viewData;
  }

  private _getFormattedDate(date: any): string {
    const formattedDate = this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    return formattedDate;
  }

  private _initHeaderLeft(): void {
    let buttons = [];
    if(this.from == ActivitySource.MEETING_DETAIL || this.from == ActivitySource.MEETING_PRESENTATION) {
      buttons.push({
        id: "close",
        imgSrc: 'assets/imgs/header_cancel.svg',
        cssClass: 'seventyPercentWidth',
        isDisabled: false,
        align: "left",
      });
    } else {
      buttons.push({
        id: "home",
        isDisabled: false,
        align: "left",
        imgSrc: "assets/imgs/back_to_home_btn.svg"
      });
    }
    this.indHeaderLeftModel = {
      id: 'survey-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('SURVEY'),
      mode: true,
      controls: buttons,
    };
  }

  private _initSectionHeader() {
    this.surveyListSectionHeader = {
      id: 'survey_list_section_header',
      title: this.getFilterText(),
      controls: [
        {
          id: "clear-filter",
          text: this.translate.instant('CLEAR_FILTER'),
          isVisible: this.isFilteredSurvey,
          isDisabled: _.isEmpty(this.selectedFilterData) && _.isEmpty(this.selectedDate)
        },
        {
          id: 'survey-list-sort',
          text: this.sortBy.text,
          isDisabled: false,
          icon: "assets/imgs/sort_with_double_arrows.svg"
      }],
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'home':
        this.onClosePage();
        break;
      case 'close':
        this.onClosePage();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  private getFilterText(): string {
    let filterText: string = `${this.translate.instant("ALL")} ${this.translate.instant('SURVEY')}`;
    if(this.searching) {
      filterText = `${this.translate.instant("ALL")} ${this.translate.instant('SURVEY')} (${this.searchedSurveyListToDisplay.length})`
    }else {
      filterText = `${this.translate.instant("ALL")} ${this.translate.instant('SURVEY')} (${this.filteredSurveyListToDisplay.length})`
    }    
    return filterText;
  }

  public onClosePage(): void {
    this.surveySearchText = '';
    if(this.uiService.activitiesPageTab != 'plans') this.events.publish("tools-back-clicked", PageName.SurveyPageComponent);
    this.uiService.activeView = this.uiService.prevView;
    this.navService.popWithPageTracking().then(() => {
      // Short call feature requires to bring the tab back to short call home
      if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
        this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
        this.uiService.setAgendaTab('shortCallHome');
      }
    });
    if (this.navService.getActiveChildNavViewPageName() === PageName.NewActivityComponent) {
      this.navService.popChildNavPageWithPageTracking();
      return;
    }
    if(this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent) {
      this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
      this.contactService.accessedContactListFrom = PageName.ToolsDrawer;
      this.contactService.contactInformation = this.customerSurveyService.selectedContactFromContactTool;
    }
  }

  public async handleSearch() {
    this.searching = true;
    let tempFilteredSurveyListToDisplay = [];
    let tempSearchedSurveyListToDisplay = [];
    tempFilteredSurveyListToDisplay = _.cloneDeep(this.filteredSurveyListToDisplay);
    if(this.surveySearchText && this.surveySearchText.trim().length > 0) {
      let searchText = this.surveySearchText;
      const formattedSearchText = this.searchConfigService.convertFormattedString(searchText).toLowerCase();
      if(!_.isEmpty(tempFilteredSurveyListToDisplay)) {
        tempFilteredSurveyListToDisplay.forEach(survey => {
          let templateName: string = survey.primaryTextRight.toLowerCase() || '';
          let gridItemType: string = '';
          let gridItemUseType: string = '';
          if(!_.isEmpty(survey.gridItems)) {
            const typeIdx = survey.gridItems.findIndex(item=>item.id =='type');
            const useTypeIdx = survey.gridItems.findIndex(item=>item.id =='use-type');
            if(typeIdx > -1) gridItemType = survey.gridItems[typeIdx].value.toLowerCase();
            if(useTypeIdx > -1) gridItemUseType = survey.gridItems[useTypeIdx].value.toLowerCase();
          }
          if(templateName.includes(formattedSearchText) || gridItemType.includes(formattedSearchText) || gridItemUseType.includes(formattedSearchText)) {
            tempSearchedSurveyListToDisplay.push(survey);
          }
        });
      }

      this.searchedSurveyListToDisplay = tempSearchedSurveyListToDisplay;

    }else {
      this.searching = false;
      this.searchedSurveyListToDisplay = [];
      if(!this.isFilteredSurvey) {
        this._initViewData();
      }
    }
    if(this.surveyListSectionHeader) {
      this.surveyListSectionHeader.title = this.getFilterText();
    }
    this._initSectionHeader();
    this.updateEmptyMessage();
  }

  onSectionHeaderControlClick(id: string) {
    if(id === 'survey-list-sort') {
      this._sortSurveyListPopover();
    }else if(id === 'clear-filter') {
      this._clearFilters();
    } 
  }

  private _sortSurveyListPopover() {
    this.popoverCtrl
    .create({component: MultiSelectPopover, componentProps: { root: this.sortPopoverData }, event:event})
    .then((data)=>{
      data.present();
    })
  }

  private _sortSurveyList() {
    const sortByValue: string = this.sortBy.value || '';
    switch(sortByValue) {
      case 'surveyName':
        if(this.searching) {
          if(!_.isEmpty(this.searchedSurveyListToDisplay)) {
            this.searchedSurveyListToDisplay = _.orderBy(this.searchedSurveyListToDisplay, 'primaryTextRight');
          }
        }else {
          if(!_.isEmpty(this.filteredSurveyListToDisplay)) {
            this.filteredSurveyListToDisplay = _.orderBy(this.filteredSurveyListToDisplay, 'primaryTextRight');
          }
        }
        break;
      case 'expireson':
        if(this.searching) {
          if(!_.isEmpty(this.searchedSurveyListToDisplay)) {
            this.searchedSurveyListToDisplay = _.orderBy(this.searchedSurveyListToDisplay, ['expiresOn','primaryTextRight']);
          }
        }else {
          if(!_.isEmpty(this.filteredSurveyListToDisplay)) {
            this.filteredSurveyListToDisplay = _.orderBy(this.filteredSurveyListToDisplay, ['expiresOn','primaryTextRight']);
          }
        }
        break;
      case 'use-type':
        if(this.searching) {
          if(!_.isEmpty(this.searchedSurveyListToDisplay)) {
            this.searchedSurveyListToDisplay = _.orderBy(this.searchedSurveyListToDisplay, ['surveyUseType','primaryTextRight']);
          }
        }else {
          if(!_.isEmpty(this.filteredSurveyListToDisplay)) {
            this.filteredSurveyListToDisplay = _.orderBy(this.filteredSurveyListToDisplay, ['surveyUseType','primaryTextRight']);
          }
        }
        break;
      case 'type':
        if(this.searching) {
          if(!_.isEmpty(this.searchedSurveyListToDisplay)) {
            this.searchedSurveyListToDisplay = _.orderBy(this.searchedSurveyListToDisplay, ['surveyCategory','primaryTextRight']);
          }
        }else {
          if(!_.isEmpty(this.filteredSurveyListToDisplay)) {
            this.filteredSurveyListToDisplay = _.orderBy(this.filteredSurveyListToDisplay, ['surveyCategory','primaryTextRight']);
          }
        }
        break;
      default:
        console.error("Unhanled survey sort value");
        break;
    }
  }

  private getIconNameByStatus(surveyTemplate: AssessmentTemplate): string {
    const iconName: string = surveyTemplate.indskr_surveytype == SurveyType.INTERNAL ? "assets/imgs/survey_blue.svg" : "assets/imgs/survey_blue_bluedot.svg";
    return iconName;
  }

  private getCustomerSurveyed(survey: AssessmentTemplate): string {
    return this.customerAssessService.getNumOfCustomerSurveyed(survey);
  }

  private getAccountSurveyed(survey: AssessmentTemplate): string {
    return this.customerAssessService.getNumOfAccountSurveyed(survey);
  }

  private getStatus(survey: AssessmentTemplate): string {
    const getCurrentStatus: InternalSurveyStatus = this.customerAssessService.getInternalSurveyStatus(survey);
    const currentStatus: string = getCurrentStatus == InternalSurveyStatus.PENDING ? this.translate.instant("PENDING") :this.translate.instant("COMPLETED");
    return currentStatus;
  }

  private getStatusId(survey: AssessmentTemplate): InternalSurveyStatus {
    return this.customerAssessService.getInternalSurveyStatus(survey);
  }

  private getSurveyType(survey: AssessmentTemplate) {
    return survey.indskr_surveytype === SurveyType.IN_MEETING ? this.translate.instant('IN_MEETING') : survey.indskr_surveytype === SurveyType.INTERNAL ? this.translate.instant('INTERNAL') : survey.indskr_entity == 'account' ? this.translate.instant('ACCOUNT') : this.translate.instant('CONTACT');
  }

  private getSurveyFrequency(survey: AssessmentTemplate) {
    return survey.indskr_surveyfrequency == SurveyFrequency.ONCE ? this.translate.instant("ONE_TIME") : this.translate.instant("RECURRING");
  }

  public async viewSurvey(id) {
    if(this.customerAssessService.isSurveyFormDirty && !(await this.customerAssessService.discardChagesForSurvey())) return;
    await this.uiService.displayLoader(); 
    this.selectedSurveyTemplateId = id;
    // this._initViewData();
    this._updateViewData(id);
    this._sortSurveyList()
    this.customerAssessService.isSurveyFormDirty = false;
    let foundTemplate: AssessmentTemplate = this.filteredSurveyList.find(survey=> survey.indskr_assessmenttemplateid == id);
    this.customerSurveyService.setCurrentSurvey(foundTemplate);
    this.customerSurveyService.setSurveyPreviewMode(true);
    const onSaveOrSubmitSurvey = () => {
      this._initSurveyListForMeeting();
      this._initViewData();
      this._sortSurveyList();
    };
    await this.navService.setChildNavRoot(SurveyDetailsComponent, PageName.SurveyDetailsComponent, PageName.SurveyComponent, { appointmentId: this.appointmentId, from: this.from, onSaveOrSubmitSurvey: onSaveOrSubmitSurvey });
    this.navService.setChildNavRightPaneView(true);

    this.uiService.dismissLoader();
  }

  updateEmptyMessage() {
    this.uiService.updateNothingSelectedScreenMessageFor(this.filteredSurveyListToDisplay.length)
  }

  private setCustomerStatistics() {
    this.statistics = [];
    let isActiveSurvey = 0;
    let isExpiringSoon = 0;
    this.filteredSurveyList.forEach((survey) => {
      if(differenceInCalendarDays(new Date(survey['indskr_validto']), new Date()) >= 0) isActiveSurvey += 1
      if(differenceInCalendarDays(new Date(survey['indskr_validto']), new Date()) <= 15) isExpiringSoon += 1
    })
    const activeSurvey = {
      id : 'active-survey',
      label: this.translate.instant('ACTIVE_SURVEY'),
      value: isActiveSurvey
    }
    const expiringSoon = {
      id : 'expiring-soon',
      label: this.translate.instant('EXPIRING_SOON'),
      value: isExpiringSoon
    }
    this.statistics = [activeSurvey, expiringSoon];
  }

  private _clearFilters() {
    this.searching = false;
    this.surveySearchText = '';
    this.searchedSurveyListToDisplay = [];
    this.isFilteredSurvey = false;
    this.selectedDate = {startDate: '', endDate: ''};
    this.selectedFilterData = [];
    this._initViewData();
    this._sortSurveyList();
    this._initSectionHeader();
  }

  getFilterButtonBadgeCount() {
    let badgeCount: number = this.selectedFilterData?.length || 0;
    if(!_.isEmpty(this.selectedDate) && !_.isEmpty(this.selectedDate.startDate) && !_.isEmpty(this.selectedDate.endDate)) {
      badgeCount = badgeCount + 1;
    }
    return badgeCount;
  }

  private _initFilterOptionData(){
    
    this.filterOptionData = [
      {
        text: this.translate.instant('SURVEY_TYPE'),
        value: 'surveyType',
        items: []
      },
      {
        text: this.translate.instant('USE_TYPE'),
        value: 'useType',
        items: [
          { text: this.translate.instant('ONE_TIME'), value: SurveyFrequency.ONCE },
          { text: this.translate.instant('RECURRING'), value: SurveyFrequency.RECURRING },
        ]
      },
      {
        text: this.translate.instant('STATUS'),
        value: 'status',
        items: [
          { text: this.translate.instant('PENDING'), value: InternalSurveyStatus.PENDING },
          { text: this.translate.instant('COMPLETED'), value: InternalSurveyStatus.COMPLETED },
        ]
      },
    ];
    if(this.isContactSurveyEnabled) {
      this.filterOptionData.find(data => data['value'] == 'surveyType').items.push(...[
        { text: this.translate.instant('SURVEY_INTERNAL_SURVEY'), value: SurveyCategory.INTERNAL },
        { text: this.translate.instant('SURVEY_CUSTOMER_SURVEY'), value: SurveyCategory.CONTACT }
      ])
    }
    if(this.isAccountSurveyEnabled) {
      this.filterOptionData.find(data => data['value'] == 'surveyType').items.push(
        { text: this.translate.instant('SURVEY_ACCOUNT_SURVEY'), value: SurveyCategory.ACCOUNT }
      )
    }
  }

  async openFilterMenu(event) {
    this._initFilterOptionData();
    const filterMenuModal = await this.modalCtrl.create({
      component: IndFilterMenuComponent,
      componentProps: {
        viewData: {
          data: this.filterOptionData,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('FILTERS'),
          isSearchEnabled: true,
          isDisplayDateField: true,
          selectedDate: this.selectedDate
        },
        selectedFilter: this.selectedFilterData,
        from: PageName.SurveyPageComponent,
      },
      backdropDismiss: false
    });
    filterMenuModal.present();
    filterMenuModal.onDidDismiss().then(async (obj: any) => {
     if (obj && obj.data && obj.data.isDone) {
       let data = obj.data;
       if(this.searching) {
        this.searching = false;
        this.surveySearchText = '';
        this.searchedSurveyListToDisplay = [];
       }
       const isFilteredByDate: boolean = !_.isEmpty(data.selectedDate) && !_.isEmpty(data.selectedDate.startDate) && !_.isEmpty(data.selectedDate.endDate) || false;
       if(!_.isEmpty(data.selectedItems)) {
         this.isFilteredSurvey = true;
         this.selectedFilterData = [];
         let selectedValue:{ surveyType: any[], useType: any[], status: any[]} = {surveyType:[], useType:[], status:[]};
   
         data.selectedItems.forEach((selected) => {
           this.selectedFilterData.push(selected);
           if(selected.categoryPath == 'survey_type') selectedValue['surveyType'].push(selected.value);
           else if(selected.categoryPath == 'survey_useType') selectedValue['useType'].push(selected.value);
           else if(selected.categoryPath == 'survey_status') selectedValue['status'].push(selected.value);
         });
        
         /******* filtered survey list by duration *******/
         if(isFilteredByDate) {
           this.selectedDate = {
             startDate: data.selectedDate.startDate,
             endDate: data.selectedDate.endDate,
           };
         }else {
          this.selectedDate = {startDate: '', endDate: ''};
         }

         this.filterSurveyList(selectedValue, isFilteredByDate);

         this.selectedFilterData.sort((a,b)=>{
           if(a.createdOn < b.createdOn) return 1
           else return -1
         });
       }else if(isFilteredByDate) {
        this.isFilteredSurvey = true;
        this.selectedFilterData = [];
        let selectedValue:{ surveyType: any[], useType: any[], status: any[]} = {surveyType:[], useType:[], status:[]};
        this.selectedDate = {
          startDate: data.selectedDate.startDate,
          endDate: data.selectedDate.endDate,
        };
        this.filterSurveyList(selectedValue, isFilteredByDate);
       }else {
         this._clearFilters();
       }
       //reset sorted list
       this.sortBy = this.sortPopoverData[0].items[0];
       this._sortSurveyList();
     }
    });
  }

  private filterSurveyList(selected: any, isFilteredByDate: boolean = false) {
    if(!_.isEmpty(this.filteredSurveyList)) {
      let tempFilteredSurveyList = [];
      this.filteredSurveyList.forEach(survey => {
        //Filtered by duration
        if(isFilteredByDate) {
          if(new Date(survey['indskr_validto']) >= new Date(this.selectedDate.startDate) && new Date(survey['indskr_validto']) <= new Date(this.selectedDate.endDate)) {
            let mainCardData = this._getMainCardDataModel(survey);
            tempFilteredSurveyList.push(mainCardData);
          }
        }else {
          let mainCardData = this._getMainCardDataModel(survey);
          tempFilteredSurveyList.push(mainCardData);
        }
      });
      if(!_.isEmpty(tempFilteredSurveyList)) {
        if(!_.isEmpty(selected['surveyType'])) {
          const idx = tempFilteredSurveyList[0].gridItems.findIndex(item=>item.id=='status');
          let tempSurveyTypeFilteredSurveyList = [];
          if(selected['surveyType'].includes('internal')) {
            tempFilteredSurveyList.forEach(survey=> {
              if(!_.isEmpty(selected['status'])) {
                if(survey.surveyType == 600000000 && selected['status'].includes(survey.gridItems[idx].rawValue)) {
                  tempSurveyTypeFilteredSurveyList.push(survey);
                }
              }else {
                if(survey.surveyType == 600000000) {
                  tempSurveyTypeFilteredSurveyList.push(survey);
                }
              }
            });
          }
          if(selected['surveyType'].includes(SurveyCategory.ACCOUNT)) {
            tempFilteredSurveyList.forEach(survey=> {
              if(survey.surveyCategory == SurveyCategory.ACCOUNT) {
                tempSurveyTypeFilteredSurveyList.push(survey);
              }
            });
          }
          if(selected['surveyType'].includes(SurveyCategory.CONTACT)) {
            tempFilteredSurveyList.forEach(survey=> {
              if(survey.surveyCategory == SurveyCategory.CONTACT) {
                tempSurveyTypeFilteredSurveyList.push(survey);
              }
            });
          }
          tempFilteredSurveyList = tempSurveyTypeFilteredSurveyList;
        }
        if(!_.isEmpty(selected['useType'])) {
          tempFilteredSurveyList = tempFilteredSurveyList.filter(survey => selected['useType'].includes(survey.surveyUseType));
        }
      }
      this.filteredSurveyListToDisplay = tempFilteredSurveyList;
    }
    this._initSectionHeader();
  }

}