import {  ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {
  AccesingMode,
  CaseManagementService,
  DETAILS_OPTION
} from '../../../services/case-management/case-management.service';
import {DeviceService} from '../../../services/device/device.service';
import {  LoadingController, ModalController, PopoverController, IonNav} from '@ionic/angular';
import { Events } from '@omni/events';
import {CasePopoverComponent, CasePopupEvent} from '../case-popover/case-popover';
import {AccountOfflineService, AccountSelectedFor} from '../../../services/account/account.offline.service';
import {ContactOfflineService} from '../../../services/contact/contact.service';
import {CaseActivity} from '../../../classes/case-intake/case-activity.class';
import {ContactPageComponent} from '../../contact/contact-page/contact-page';
import {NavigationService, PageName} from '../../../services/navigation/navigation.service';
import {ComponentViewMode, UIService} from '../../../services/ui/ui.service';
import {AccountPageComponent} from '../../account/account-page/account-page';
import {CaseManagementDataService} from '../../../data-services/case-management/case-management.data.service';
import {FooterService, FooterViews} from '../../../services/footer/footer.service';
import {EventsService} from '../../../services/events/events.service';
import {DiskService} from '../../../services/disk/disk.service';
import {Contact, ContactDTO, Email} from '../../../classes/contact/contact.class';
import {ActivityService, ActivitySource} from '../../../services/activity/activity.service';
import {NavController} from '@ionic/angular';
import {RepServices} from '../../../data-services/rep/rep.services';
import {TrackingEventNames, TrackService} from '../../../services/logging/tracking.service';
import {format, getDate, isToday, isTomorrow, isValid, isYesterday} from 'date-fns';
import {AuthenticationService} from '../../../services/authentication.service';
import {FeatureActionsMap} from '../../../classes/authentication/user.class';
import {NotificationService, ToastStyle} from '../../../services/notification/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {GlobalUtilityService} from '../../../services/global-utility.service';
import {FormFieldType, IndFormFieldViewDataModel} from '../../../models/indFormFieldDataModel';
import {DateTimeFormatsService} from '../../../services/date-time-formats/date-time-formats.service';
import * as moment from 'moment';
import {AlertService} from '../../../services/alert/alert.service';
import {CallPlanOfflineService} from '../../../services/call-plan/call-plan.offline.service';
import {default as _} from 'lodash';

import { CaptureCustomerInquireComponent } from '../capture-customer-inquire/capture-customer-inquire';
import { Account } from '../../../classes/account/account.class';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { PhoneCallMeetingStructureService } from './../../../services/phone-call-meeting-structure/phone-call-meeting-structure.service';
import { PhoneActivity } from '../../../classes/activity/phone.activity.class';
import { PhoneCallDetailsComponent } from '../../../components/phonecall/phone-call-details/phone-call-details';
import { DatePipe } from '@angular/common';
import { Utility } from '@omni/utility/util';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { SurveyService } from '@omni/services/survey/survey.service';
import { takeUntil } from 'rxjs/operators';
import { AppointmentActivity, CovisitorAccess } from '../../../classes/activity/appointment.activity.class';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from "@omni/models/mainToolTemplateDetail.model";
import { MainToolTemplateComponent } from "@omni/components/shared/main-tool-template/main-tool-template";
import { ActivityType } from '../../../classes/activity/activity.class';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { SetBookingActivityDetailsComponent } from '@omni/components/activity/set-booking-activity-detail/set-booking-activity-detail';
import { SetBookingActivity, SetBookingStatus } from '@omni/classes/activity/set-booking.activity.class';
import { CaseAssignee, CaseAssigneeType } from '@omni/classes/case-intake/case-utility.class';

/**
 * Generated class for the CaseManagementDetailComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'case-management-detail',
  templateUrl: 'case-management-detail.html',
  styleUrls:['case-management-detail.scss']
})
export class CaseManagementDetailComponent implements OnInit, OnDestroy {

  public IcurrentCaseSubscription: Subscription;
  selectionObs: Subscription;
  public selectedCase: CaseActivity;

  public categoryName: string;

  public customerEmailList: string[] = [];

  public isCaseDisabled = true;
  private isLayoverPushView = false;
  public isXperienceTab = false;
  public layoverViewPushedFrom: string;

  //caseHeader: string = this.translate.instant('INQUIRY');
  responseType = 'Email';
  contactDetails: string = this.responseType;
  notes = '';
  public groupedActivities = [];
  public months: Array<String> = ['January', 'Febuary', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];
  public weekDay: Array<String> = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  public getday = getDate;
  public offlineFAEnabled = false;
  openInquiryAckModal: ModalController;
  isModalView = false;
  userName = '';
  globalCustomerText: string;
  @Input() from: any;
  @Input() viewMode: any;
  public taskDetailsHeaderModel: IndSectionHeaderViewDataModel;
  public caseManagementInquiryHeaderModel : IndSectionHeaderViewDataModel;
  public caseInquirySurveyHeaderModel: IndSectionHeaderViewDataModel;
  public caseInquiryTimeLineHeaderModel: IndSectionHeaderViewDataModel;
  public caseInquirySurveyResponseDataModel: IndFormFieldViewDataModel[] = [];
  private backgroundUploadInProgress: boolean = false;
  public isKitBookingFAEnabled: boolean = false;
  public isNoProductSelection: boolean = false;
  public isHideCustomerField: boolean = false;
  public isAccountFieldMandatory: boolean = false;
  //@ViewChild(Content, {static:true}) content: Content;

  private readonly footerHandler: (eventName: string) => void = (eventName: string) => {
    if (this.backgroundUploadInProgress) return;
    switch (eventName) {
      case 'scrapcase':
        this.scrapTrigger();
        break;

      case 'submitcase': {
        this.sendCaseForReview(true);
        break;
      }
      default:
        break;
    }
  }

  public surveyLookupResults: Array<any>=[];
  public surveyTemplate: any;
  public isSurveyConfigured: boolean = false;
  @ViewChild('scrollTop', { read: ElementRef, static: false }) scrollTop: ElementRef;
  private ngDestroy$: any = new Subject<boolean>();
  private isClickedCategory: boolean = false;
  private isClickedProduct: boolean = false;
  private isClickedContact: boolean = false;
  private isClickedAssignee: boolean = false;
  private isClickedResponsePreference: boolean = false;

  public isCovisitor: boolean = false;
  public coVisitorAccess;

  constructor(
    public caseManagementService: CaseManagementService,
    public device: DeviceService,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    public accountService: AccountOfflineService,
    public contactService: ContactOfflineService,
    public navService: NavigationService,
    public caseManagementDataService: CaseManagementDataService,
    private readonly loadingController: LoadingController,
    private readonly callPlanService: CallPlanOfflineService,
    public readonly footerService: FooterService,
    private readonly eventService: EventsService,
    private readonly diskService: DiskService,
    private readonly activityService: ActivityService,
    public uIService: UIService,
    private readonly alertService: AlertService,
    private readonly loadingCtrl: LoadingController,
    public navCtrl: IonNav,
    public repService: RepServices,
    private readonly trackingService: TrackService,
    private readonly events: Events,
    private readonly authenticationService: AuthenticationService,
    public notificationService: NotificationService,
    public translate: TranslateService,
    public utilityService: GlobalUtilityService,
    private readonly dateTimeFormatsService: DateTimeFormatsService,
    private phoneCallMeetingStructureService: PhoneCallMeetingStructureService,
    private datePipe: DatePipe,
    public surveyService: SurveyService,
    private _cd: ChangeDetectorRef,
  ) {
    this.responseType = this.translate.instant('EMAIL');
    this.contactDetails = this.responseType;
    if ((this.viewMode === 'modalView') ||
      this.caseManagementService.accessedFrom === AccesingMode.INMEETING
      || this.caseManagementService.accessedFrom === AccesingMode.IN_PHONE_CALL
      || this.caseManagementService.accessedFrom === AccesingMode.IN_SET_BOOKING) {
      this.isModalView = true;
    }
    if (this.from) {
      this.isLayoverPushView = true;
      if (this.from === 'AccountTimeline') {
        this.layoverViewPushedFrom = 'AccountTimeline';
      }
    }
    this.globalCustomerText = this.utilityService.globalCustomerText;

    if(this.authenticationService.hasFeatureAction(FeatureActionsMap.KIT_BOOKING))
      this.isKitBookingFAEnabled = true
    // switch (this.utilityService.globalCustomerText) {
    //   case 'Customer':
    //     this.globalCustomerText = this.translate.instant('CUSTOMER');
    //     break;
    //   case 'Stakeholder':
    //     this.globalCustomerText = this.translate.instant('STAKEHOLDER');
    //     break;
    //   default:
    //     this.globalCustomerText = this.utilityService.globalCustomerText;
    //     break;
    //   }
    this.events.subscribe('InquiryAcknowledgement:footerEvents', async (data) => {
      if (data) {
        if (
          this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_INQUIRY_OFFLINE)
        ) {
          this.offlineFAEnabled = true;
        }
        //populate customer field with account information in case hide customer is true
        if(this.isHideCustomerField && this.selectedCase._case_account_id){
          this.selectedCase._case_contact_id = this.selectedCase._case_account_id;
          this.selectedCase._case_contact = undefined;
          this.selectedCase._case_trans_customer_name = this.selectedCase._case_trans_account_name;
          // if (!this.uIService.toolsActivityActive) {
          //   this.events.publish("refreshAgenda");
          // } else this.uIService.agendaRefreshRequired = true;
        }
        if (this.isSurveyConfigured) this.events.publish('completeSurvey_sendCaseForReview');
        if (this.device.isOffline) {
          if (this.offlineFAEnabled) {
            const model = await this.modalCtrl.create({
              component: CaptureCustomerInquireComponent, componentProps: { selectedCase: this.selectedCase }, backdropDismiss: false
            });
            model.present();
            model.onDidDismiss().then(data => {
              this.initFooter();
            });
          } else {
            this.notificationService.notify(
              this.translate.instant('CASE_CU_INQ_SUB_ALLOW_ONLY_ONLINE_WITH_TEXT', { text: this.globalCustomerText }),
              'Customer Inquiry',
              'top',
              ToastStyle.DANGER
            );
          }
        } else {
          const model = await this.modalCtrl.create({
            component: CaptureCustomerInquireComponent, componentProps: { selectedCase: this.selectedCase }, backdropDismiss: false
          });
          model.present();
          model.onDidDismiss().then(data => {
            this.initFooter();
            this.initSectionHeader();
          });
        }
      }
    });

    this.events.subscribe('surveyLookupEvent', (data) => {
      this.handleFormFieldEvent(data, event, 'lookup_click_event');
    })

    this.events.subscribe('reloadSurveyTemplate', () => {
      this.loadSurveyTemplate();
    })

    this.events.subscribe('updateMandatoryField', (field) => {
      if(field == PageName.ContactPageComponent) this.isClickedContact = true;
    })

    // this.isCovisitor = !_.isEmpty(this.activityService.selectedActivity['accompaniedUserList']) ? this.activityService.selectedActivity['accompaniedUserList'].some(user => user.id === this.authenticationService.user.systemUserID) : false;
    this.isCovisitor = !_.isEmpty(this.activityService.selectedActivity) ? this.activityService.selectedActivity['accompaniedUserList']?.some(user => user.id === this.authenticationService.user.systemUserID) : false;
    this.coVisitorAccess = this.authenticationService.user.buSettings && this.authenticationService.user.buSettings['indskr_covisitoraccessonmeeting'];
  }
  public titleControls = [];
  ngOnInit() {

    this.IcurrentCaseSubscription = this.caseManagementService.currentSelectedCase.subscribe(
      (value: any) => {
        this.titleControls = [];
        if (value) {
          this.groupedActivities = [];
          this.loadCaseDetails(value);
          this.isXperienceTab = this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent && !this.footerService.isActivitiesButton;
          this.addControllsTitle();
          this.caseManagementDetailsHeaderModel();
          if(this.isSurveyConfigured) this.loadSurveyTemplate();
        }
        else {
          this.caseManagementService.currentCase = undefined;
          this.accountService.isCaseManagementInvoked = false;
          this.contactService.isCaseManagementInvoked = false;
          // assuming that the request was initiated from the non case tools
          if (!this.isModalView && this.caseManagementService.accessedFrom === AccesingMode.AGENDA) {
            if (this.device.shouldBeMobileView) {
              this.activityService.selectedActivity = undefined;
              this.uIService.activeView = '';
              this.uIService.showRightPane = false;
            }
          }
          else if (this.caseManagementService.accessedFrom === AccesingMode.INMEETING) {
            this.modalCtrl.dismiss();
          }
          // else if (this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
          //   this.viewCtrl.dismiss();
          // }
          else if (
            this.caseManagementService.accessedFrom === AccesingMode.CONTACT_TIMELINE ||
            this.caseManagementService.accessedFrom === AccesingMode.ACCOUNT_TIMELINE ||
            this.caseManagementService.accessedFrom === AccesingMode.XPERIENCE_INSIGHTS ||
            this.caseManagementService.accessedFrom === AccesingMode.CONTACT) {
            this.uIService.activeView = this.uIService.prevView;
            this.footerService.initButtons(FooterViews.Contacts);

            if (this.caseManagementService.accessedFrom === AccesingMode.CONTACT_TIMELINE) this.contactService.isCaseManagementInvoked = true;
            if (this.caseManagementService.accessedFrom === AccesingMode.ACCOUNT_TIMELINE) this.accountService.isCaseManagementInvoked = true;
            if (this.caseManagementService.accessedFrom === AccesingMode.XPERIENCE_INSIGHTS) this.accountService.isCaseManagementInvoked = true;
          }
          else if (this.isModalView && this.caseManagementService.accessedFrom === AccesingMode.AGENDA) {
            try {
              this.modalCtrl.dismiss();
            }
            catch (error) { }
          }
        }
        this.isClickedCategory = this.isClickedProduct = this.isClickedContact = this.isClickedResponsePreference = this.isClickedAssignee = false;
    });

    this.selectionObs = this.caseManagementService.detailOptionObservable.subscribe(res => {
      if (res && res['data']) {
        console.log(res);
        this.setCaseData(res);
      }
    });

    this.eventService.subscribe("case:footerEvents", this.footerHandler);

    this.authenticationService.getOfflineUser().then((user) => {
      if (user) {
        this.userName = user.displayName;
        console.log('see this' + this.userName);
      }
      return;
    });
    this.initSectionHeader();

    this.translate.onLangChange.subscribe(()=>{
      this.initSectionHeader();
      this.addControllsTitle();
      this.globalCustomerText = this.utilityService.globalCustomerText;

    });

    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      if (this.selectedCase && (this.navService.getActiveChildNavViewPageName() === PageName.CaseManagementDetailComponent || this.navService.getCurrentPageName() === PageName.CaseManagementHomePage)) {
        const state: string = String(this.selectedCase._case_status_value).toLocaleLowerCase();
        this.backgroundUploadInProgress = inProgress && state === 'pending sync';
        this.initFooter();
        this.caseManagementDetailsHeaderModel();
        this._cd.detectChanges();
        this.isKitBookingFAEnabled= this.authenticationService.hasFeatureAction(FeatureActionsMap.KIT_BOOKING);

      }
    });
  }

  ionViewDidEnter() {
    this.initFooter();
  }

  ngAfterViewInit(){
    setTimeout(() => {
      if(this.scrollTop) this.scrollTop.nativeElement.scrollIntoView(false);
    }, 500);
  }

  initSectionHeader(){
    this.caseManagementDetailsHeaderModel();
    this.caseInquiryHeaderModel();
    this.caseInquirySurveyHeader();
    this.caseInquiryTimeLineHeader();
  }

  private caseManagementDetailsHeaderModel() {

    let buttons = [];

    if (this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
      buttons.push({
        id: "scrap",
        text: this.translate.instant('SCRAP'),
        isDisabled: !this.isScrapEnabled,
      });
      if ((this.selectedCase.raw) ? (this.selectedCase.raw.indskr_signature ? !this.selectedCase.raw.indskr_signature : !this.selectedCase.signature) : true) {
        buttons.push({
          id: "submit",
          text: this.translate.instant('SUBMIT'),
          isDisabled: !(this.selectedCase._case_status_value === 'Open' && this.validateCaseForReview),
        });
      }
      else if ((this.selectedCase.raw) ? (this.selectedCase.raw.indskr_signature ? this.selectedCase.raw.indskr_signature : this.selectedCase.signature) : false) {
        buttons.push({
          id: "ack",
          text: this.translate.instant('SHOW'),
          isDisabled: false,
        });
      }
    }
    this.taskDetailsHeaderModel = {
      id:'details-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: buttons
    };
  }

  private caseInquiryHeaderModel(){
    this.caseManagementInquiryHeaderModel = {
      id: 'case-detail-header',
      title: this.translate.instant('INQUIRY'),
      controls: [],
      isRequired: !(this.selectedCase._case_status_value != 'Open' && this.isCaseDisabled)
    };
  }

  private caseInquirySurveyHeader() {
    this.caseInquirySurveyHeaderModel = {
      id: 'case-detail-header',
      title: this.translate.instant('INQUIRY_QUESTIONNAIRE'),
      controls: []
    }
  }

  private caseInquiryTimeLineHeader(){
    this.caseInquiryTimeLineHeaderModel = {
      id: 'case-detail-header',
      title: this.translate.instant('CASE_INQUIRY_TIMELINE'),
      controls: []
    };
  }

  private addControllsTitle() {
    this.titleControls = []
    if (this.isModalView || (this.caseManagementService.accessedFrom === AccesingMode.IN_PHONE_CALL)
      ||(this.caseManagementService.accessedFrom === AccesingMode.IN_SET_BOOKING)
      || this.layoverViewPushedFrom === 'AccountTimeline' || this.from === 'AccountTimeline'
      || this.isXperienceTab || this.caseManagementService.accessedFrom === AccesingMode.CONTACT_TIMELINE
      || this.caseManagementService.accessedFrom === AccesingMode.CONTACT) {
      this.titleControls.push({
        id: "close",
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: "left"
      });
    }
    if (this.caseManagementService.accessedFrom !== AccesingMode.PRESENTATION) {
      this.titleControls.push({
        id: "scrap",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('SCRAP'),
        isDisabled: !this.isScrapEnabled,
        align: "right"
      });
      if ((this.selectedCase.raw) ? (this.selectedCase.raw.indskr_signature ? !this.selectedCase.raw.indskr_signature : !this.selectedCase.signature) : true) {
        this.titleControls.push({
          id: "submit",
          imgSrc: 'assets/imgs/header_send.svg',
          name: this.translate.instant('SUBMIT'),
          isDisabled: this.isCaseDisabled,
          align: "right"
        });
      } else if ((this.selectedCase.raw) ? (this.selectedCase.raw.indskr_signature ? this.selectedCase.raw.indskr_signature : this.selectedCase.signature) : false) {
        this.titleControls.push({
          id: "ack",
          imgSrc: 'assets/imgs/header_acknowledgement.svg',
          name: this.translate.instant('SHOW'),
          isDisabled: false,
          align: "right"
        });
      }
    }
    else {
      this.titleControls = [];
    }
  }

  public onPageTitleControlClick(id: string) {
    if (this.backgroundUploadInProgress) return;
    switch (id) {
      case 'close':
        this.goBack();
        break;
      case 'scrap':
        this.scrapInquiry();
        break;
      case 'submit':
        this.sendCaseForReview();
        break;
      case 'ack':
        this.openAcknowledgement();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  public onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'close':
        this.goBack();
        break;
      case 'scrap':
        this.scrapInquiry();
        break;
      case 'submit':
        this.sendCaseForReview();
        break;
      case 'ack':
        this.openAcknowledgement();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  ngOnDestroy(): void {

    this.eventService.unsubscribe('case:footerEvents', this.footerHandler);

    this.IcurrentCaseSubscription.unsubscribe();

    this.selectionObs.unsubscribe();

    this.events.unsubscribe('InquiryAcknowledgement:footerEvents');
    this.events.unsubscribe('surveyLookupEvent');
    this.events.unsubscribe('reloadSurveyTemplate');
    this.events.unsubscribe('updateMandatoryField');
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  public get isScrapEnabled(): boolean {
    if (this.backgroundUploadInProgress) return false;
    const state: string = String(this.selectedCase._case_status_value).toLocaleLowerCase();
    if (state !== 'open' && state !== 'pending sync') {
      return false;
    } else {
      if (this.activityService.teamViewActive) {
        return false;
      } else {
        return true;
      }
    }
  }

  public get caseHeader(): string {
    // Setting the header for the details view
    let str: string = this.translate.instant('INQUIRY');
    if (this.selectedCase._case_type || this.selectedCase._case_trans_category) {
      str = this.selectedCase.caseName;
    }
    return str;
  }

  public goBack() {
    this.surveyService.surveyLookupResults = this.surveyLookupResults = [];
    this.caseManagementService.assignSelectedCase(undefined);
    this.surveyService.assignLookupResult(undefined);
    if (this.device.shouldBeMobileView) {
      this.caseManagementService.showRightPane = false;
      this.caseManagementService.casesSource$.next([...this.caseManagementService.casesSource$.value]);
      if (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent && this.uIService.activitiesPageTab !== 'plans')
        this.events.publish('scrollToDate');
    }

    if (this.navService.getCurrentPageName() == PageName.ContactPageComponent) {
      if (this.caseManagementService.accessedFrom === AccesingMode.INMEETING || this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
        this.uIService.activeView = 'ContactPageRightPaneNav';
        this.modalCtrl.dismiss();
        return;
      }
      else if(this.caseManagementService.accessedFrom === AccesingMode.IN_PHONE_CALL){
        this.goBackToPhoneCall();
        this.modalCtrl.dismiss();
        return;
      }
      else {
        this.navService.popChildNavPageWithPageTracking();
        this.uIService.activeView = 'contactDetails';
        this.activityService.selectedActivity = undefined;
      }
      this.uIService.showNewActivity = false;
      this.footerService.initButtons(FooterViews.Contacts);
    }

    else if ((this.isLayoverPushView && this.layoverViewPushedFrom === 'AccountTimeline') || (this.from && this.from === 'AccountTimeline')) {
      // if (this.layoverViewPushedFrom == 'AccountTimeline') {
        this.navService.popChildNavPageWithPageTracking();
      // }
    }

    else if (this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent) {
      if (this.caseManagementService.accessedFrom === AccesingMode.INMEETING ||
        this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
        this.uIService.activeView = 'ActivitiesPageRightPaneNav';
      } else if(this.uIService.activitiesPageTab == 'plans') {
        this.uIService.activeView = 'ActivitiesPageRightPaneNav';
        this.uIService.showNewActivity = false;
        this.uIService.showRightPane = true;
        this.footerService.initButtons('');
        if(this.uIService.activitiesPagePlanTabSelectedPlan == 'goalsPlansTab') {
          this.navService.popChildNavPageWithPageTracking();
          this.navService.pushChildNavPageWithPageTracking(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.CallPlanComponent);
          this.goBackToPhoneCall();
          this.modalCtrl.dismiss();
        } else if (this.uIService.activitiesPagePlanTabSelectedPlan === 'planTabTodo') {
          if (this.device.shouldBeMobileView) {
            this.activityService.selectedActivity = undefined;
            this.uIService.showRightPane = false;
          }
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        } else {
          this.navService.popChildNavPageWithPageTracking();
        }
      } else if(this.uIService.activitiesPageTab == 'agenda' &&  this.caseManagementService.accessedFrom === AccesingMode.CALENDAR){
        if (this.device.shouldBeMobileView) {
          this.activityService.selectedActivity = undefined;
          this.uIService.activeView = '';
          this.uIService.showRightPane = false;
        }
      } else if (!this.footerService.isActivitiesButton) {
        this.events.publish('go-back-to-xperiences', PageName.CaseManagementDetailComponent);
      } else if(this.caseManagementService.accessedFrom === AccesingMode.IN_PHONE_CALL){
        this.goBackToPhoneCall();
        this.modalCtrl.dismiss();
      }  else if(this.caseManagementService.accessedFrom === AccesingMode.IN_SET_BOOKING){
        this.goBackToKitBooking();
        this.modalCtrl?.dismiss();
      } else if(this.navService.getActiveChildNavViewPageName() === PageName.CaseManagementDetailComponent) {
        this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
      }
    }

    else if (this.navService.getCurrentPageName() == PageName.PresentationMeetingComponent) {
      this.footerService.initButtons(FooterViews.PreviewMeeting);
    }
    else if(this.navService.getCurrentPageName() == PageName.CallPlanComponent) {
      if (this.caseManagementService.accessedFrom === AccesingMode.IN_PHONE_CALL){
        this.goBackToPhoneCall();
        this.modalCtrl.dismiss();
      } else {
        this.callPlanService.callPlanPageView = 'CallPlanNewMeetingDetails';
        this.footerService.initButtons(FooterViews.Activities);
      }
    } else if (this.navService.getCurrentPageName() == PageName.ScientificActivityPage) {
      this.footerService.initButtons('');
      if(this.from === PageName.ContactDetailsComponent){
        this.navService.popChildNavPageWithPageTracking();
      }
    }

    else if(this.activityService.activitySource === ActivitySource.MEETING_DETAIL) {
      this.goBackToAppointment();
    }
    else if (this.caseManagementService.accessedFrom === AccesingMode.IN_PHONE_CALL) {
      this.goBackToPhoneCall();
      this.modalCtrl.dismiss();
    }
    else if(this.caseManagementService.accessedFrom === AccesingMode.IN_SET_BOOKING){
      this.goBackToKitBooking();
      this.modalCtrl?.dismiss();
    }
  }

  private goBackToAppointment() {
    this.uIService.activeView = 'Appointment';
    this.uIService.showNewActivity = false;
    this.footerService.initButtons(FooterViews.Activities);
    this.activityService.activitySource = null;
  }
  private goBackToPhoneCall() {
    if (this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent) {
      this.uIService.activeView = 'phoneCallTimelineDetails';
    } else if(this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent) {
      this.callPlanService.callPlanPageView = 'CallPlanPhoneCallDetails';
    } else if(this.uIService.activitiesPageTab !== 'plans') {
      this.uIService.activeView = 'PhoneCall';
      this.uIService.showRightPane = true;
    }
    this.phoneCallMeetingStructureService.createEmbeddedInteractions(this.activityService.selectedActivity as PhoneActivity)
    this.uIService.showNewActivity = false;
    this.footerService.initButtons(FooterViews.PHONE_CALL);
    this.activityService.activitySource = null;
    this.events.publish('detectChangesOnPhoneCallActivityDetails');
  }

  private goBackToKitBooking(){
    // this.uIService.activeView = 'SetBooking';
    // this.uIService.showNewActivity = false;
    //this.uIService.showRightPane = true;
    this.activityService.selectedActivity = this.activityService.prevSelectedActivity;
    this.navService.popChildNavPageWithPageTracking();
    this.events.publish('refresh-kit-booking-activities');
    this.events.publish('initSetBookingFooter');
    //this.activityService.activitySource = null;
    //this.events.publish('detectChangesOnSetBookingActivityDetails');
  }

  public async productSelection(e) {
    this.footerService.forceHideMasterFooter = true;
    if (this.selectedCase._case_status_value !== 'Open' && this.isCaseDisabled) return;

    let modal = await this.modalCtrl.create({
      component: CasePopoverComponent,
      componentProps: {
        value: {
          field: CasePopupEvent.PRODUCTSELECT,
          case: this.selectedCase
        }
      },
      cssClass: this.device.isOffline ? 'case-product-popover offline-padding' : 'case-product-popover'
    }
    );
    modal.present();
    modal.onDidDismiss().then(
      async (resp: any) => {
        this.footerService.forceHideMasterFooter = false;
        this.isClickedProduct = true;
        const obj = resp.data;
        if (obj) {
          if (!obj.isDone) return;
          const data = obj.selectedItems;
          if (!data) {
            this.selectedCase._case_therapeutic_area = null;
            this.selectedCase._case_trans_therapeutic_name = this.translate.instant('THERAPEUTIC_AREA');
            this.selectedCase._case_therapeutic_id = null;
            if(this.selectedCase._case_inquiry_additional_attributes) this.selectedCase._case_inquiry_additional_attributes['productid@odata.bind'] = null;
          }
          this.selectedCase._case_product = data ? data : undefined;
          this.selectedCase._case_product_id = data ? data.ID : undefined;
          /*surveyComponent*/
          if(this.isSurveyConfigured) {
            let lookupQuestions = [];
            this.surveyTemplate.pages[0].elements.forEach(element => {
              if(element.type == 'lookup'){
                lookupQuestions.push(element)
              }else if(element.type == 'panel' && element.elements && element.elements.length > 0){
                let foundElements = this.getLookupElementsRecursively(element.elements);
                if(foundElements && foundElements.length > 0){
                  lookupQuestions.push(...foundElements);
                }
              }
            });
            const metadata = lookupQuestions.find((question) => question.metadata.target.name == 'product').metadata
            if(data && data.ID) this.selectedCase._case_inquiry_additional_attributes['productid@odata.bind'] = '/'+ metadata.target.setName + '(' + data.ID + ')'
            let product = {
              targetEntity: 'product',
              id: data? data.ID : '',
              name: data? String(data.name) : ''
            }
            this.surveyService.assignLookupResult(product);
            this.mapSurveyLookupResults(product);
          } else {
            this.selectedCase._case_trans_product_name = this.selectedCase._case_product ? String(this.selectedCase._case_product.name) : this.translate.instant('PRODUCT');
          }
          this.isCaseDisabled = (!(this.selectedCase._case_status_value === 'Open' && this.validateCaseForReview));
          this.initFooter();
          this.caseManagementDetailsHeaderModel();
          await this.updateDataOnPouch();
        }
      });
  }

  private getLookupElementsRecursively(elements){
    let foundElements = [];
    elements.forEach(element => {
      if(element.type == 'lookup'){
        foundElements.push(element)
      }else if(element.type == 'panel' && element.elements && element.elements.length > 0){
        let foundElements = this.getLookupElementsRecursively(element.elements);
        if(foundElements && foundElements.length > 0){
          foundElements.push(...foundElements);
        }
      }
    });
    return foundElements;
  }

  public async therapeuticSelection(event) {
    if (this.selectedCase._case_status_value !== 'Open' && this.isCaseDisabled) return;

    const popover = await this.popoverCtrl.create({
      component: CasePopoverComponent,
      componentProps:{
        value: {
          field: CasePopupEvent.THERAPEUTICAREA,
          case: this.selectedCase
        }
      },
      cssClass: 'case-type-popover', event:event }
    );

    popover.present();
    popover.onDidDismiss().then(
      async (data: any) => {
        data = data.data;
        if (data) {
          this.selectedCase._case_therapeutic_area = data;
          this.selectedCase._case_therapeutic_id = data.therapeuticareaid;
          this.selectedCase._case_trans_therapeutic_name = this.selectedCase._case_therapeutic_area ? String(this.selectedCase._case_therapeutic_area.therapeuticarea_name) : this.translate.instant('THERAPEUTIC_AREA');
          this.isCaseDisabled = (!(this.selectedCase._case_status_value === 'Open' && this.validateCaseForReview));
          this.initFooter();
          this.caseManagementDetailsHeaderModel();
          await this.updateDataOnPouch();
        }
      });
    // }
  }

  /*
    Kinda an init function for loading the data on the the screnn for the selected case
  */
  public loadCaseDetails(value) {
    this.contactDetails = this.responseType;
    this.selectedCase = value;
    this.notes = '';
    this.isSurveyConfigured = false;
    /* If case exist */
    if (this.selectedCase) {
      try {
        this.notes = this.selectedCase._case_notes ? this.selectedCase._case_notes : '';
        const account = this.accountService.accounts.find(e => { return e.id === this.selectedCase._case_account_id; });
        if (account) {
          this.selectedCase._case_account = account;
        } else if (this.selectedCase._case_status_value != 'Open' && this.selectedCase._case_account_id) {
          //For inactive accounts
          const rawAccount: any = {
            accountid: this.selectedCase._case_account_id,
            name: this.selectedCase._case_trans_account_name,
            statuscode: 2
          };
          this.selectedCase._case_account = new Account(rawAccount);
        }
        //_case_contact_id is populated with _case_account_id when HideCustomer is true during submitting the case
        if(this.selectedCase._case_contact_id !== this.selectedCase._case_account_id) {
          let selectedContact: Contact = _.cloneDeep(this.contactService.getContactByID(this.selectedCase._case_contact_id));
          if (this.selectedCase._case_status_value != 'Open' && this.selectedCase._case_contact_id && !selectedContact) {
            //If Contact is deactivated/ removed / not part of user position
            const inActiveContact: ContactDTO = {
              contactid: this.selectedCase._case_contact_id,
              firstname: this.selectedCase._case_trans_customer_name
            };
            selectedContact = new Contact(inActiveContact);
            selectedContact.isActive = false;
          }
          this.selectedCase._case_contact = selectedContact;
        }
        
        this.selectedCase._case_type = this.caseManagementService.caseTypeList.find(e => { return e.id === this.selectedCase._case_type_id; });
        this.selectedCase._case_category = (this.selectedCase._case_type && this.selectedCase._case_category_id) ? this.selectedCase._case_type.categoryTypes.find(c => { return c.id === this.selectedCase._case_category_id; }) : undefined;
        this.selectedCase._case_response_preference = this.selectedCase._case_response_preference ? this.caseManagementService.responsePreferenceList.find(e => { return e.id === this.selectedCase._case_response_preference_id; }) : undefined;
        if((this.selectedCase._case_status_value == 'Open' && this.selectedCase._case_type.surveyTemplate)
        || (this.selectedCase._case_status_value != 'Open' && !_.isEmpty(this.selectedCase._case_inquiry_response))){
          this.surveyService.surveyActiveFor = 'CaseManagement';
          this.isSurveyConfigured = true;
        }
        if(!this.isSurveyConfigured && this.caseManagementService.getCurrentCaseCategoryTypes() && this.caseManagementService.getCurrentCaseCategoryTypes().length == 1 && !(this.selectedCase._case_status_value != 'Open' && this.isCaseDisabled)){
          let data = this.caseManagementService.getCurrentCaseCategoryTypes()[0];
          if (data) {
            this.onCategorySelectedAction(data);
            this.isClickedCategory = true;
          }
        }
        if(this.selectedCase._case_category){
          this.setFlagsOnCategorySelection();
        }

        /* SKU related changes */
        this.selectedCase._case_product = undefined;
        this.caseManagementService.caseProductSKU.map(e => {
          if (e.ID === this.selectedCase._case_product_id) {
            this.selectedCase._case_product = e;
          }
          else {
            if (e.skus && Array.isArray(e.skus)) {
              let innerProduct = e.skus.find(s => s.ID === this.selectedCase._case_product_id);
              if (innerProduct) {
                this.selectedCase._case_product = innerProduct;
              }
            }
          }
        });


        this.selectedCase._case_therapeutic_area = (this.selectedCase._case_product && this.selectedCase._case_product.therapeuticAreas) ? this.selectedCase._case_product.therapeuticAreas.find(e => { return e['therapeuticareaid'] === this.selectedCase._case_therapeutic_id; }) : undefined;
        this.selectedCase._case_trans_product_name = this.selectedCase._case_product ? String(this.selectedCase._case_product.name) : this.selectedCase._case_trans_product_name ? this.selectedCase._case_trans_product_name : this.translate.instant('PRODUCT');
        this.selectedCase._case_trans_customer_name = this.selectedCase._case_contact ? this.selectedCase._case_contact.fullName : this.selectedCase._case_trans_customer_name ? this.selectedCase._case_trans_customer_name : this.utilityService.globalCustomerText;
        this.selectedCase._case_trans_account_name = this.selectedCase._case_account ? this.selectedCase._case_account.accountName : this.selectedCase._case_trans_account_name ? this.selectedCase._case_trans_account_name : this.translate.instant('ACCOUNT');
        this.categoryName = this.selectedCase._case_category ? this.selectedCase._case_category.name : this.translate.instant('INQUIRY_TYPE');
        this.selectedCase._case_trans_therapeutic_name = this.selectedCase._case_therapeutic_area ? this.selectedCase._case_therapeutic_area['therapeuticarea_name'] : this.selectedCase._case_trans_therapeutic_name ? this.selectedCase._case_trans_therapeutic_name : this.translate.instant('THERAPEUTIC_AREA');
        this.isCaseDisabled = (!(this.selectedCase._case_status_value === 'Open' && this.validateCaseForReview));

        //this.contactDetailSetting();

        if (this.selectedCase._case_response_preference) {
          if (String(this.selectedCase._case_response_preference.name).toLowerCase().includes('email')) {
            this.responseType = this.translate.instant('EMAIL');
            this.contactDetails = this.selectedCase._selected_email_id ? this.selectedCase._selected_email_id : this.responseType;
            if (this.selectedCase._case_contact) {
              this.selectedCase._selected_email = this.selectedCase._case_contact.emailAddressList.find(c => c.emailAddress == this.selectedCase._selected_email_id);
            }
          }
          if (String(this.selectedCase._case_response_preference.name).toLowerCase().includes('phone')) {
            this.responseType = this.translate.instant('PHONE');
            this.contactDetails = this.selectedCase._selected_phone ? this.selectedCase._selected_phone : this.responseType;
          }
          if (String(this.selectedCase._case_response_preference.name).toLowerCase().includes('visit')) {
            this.responseType = this.translate.instant('ADDRESS');
            this.contactDetails = this.selectedCase._selected_address ? this.selectedCase._selected_address : this.responseType;
          }
        }

        if (this.selectedCase._case_timeline.length) {
          this.groupedActivities = this.getGroupedActivitiesByMonth(this.selectedCase._case_timeline);
        }

        if(this.isSurveyConfigured) this.loadSurveyResults(this.selectedCase);

      } catch (error) {
        console.log(error);
      }

    }
    else {
      console.log('Woah!!! Serious Error detected, case doesn\'t exist. Guess i messed up somewhere');
      this.goBack();
    }

    this.initFooter();
    this._cd.detectChanges()

  }

  public contactSelection(e) {

    if (this.caseManagementService.accessedFrom === AccesingMode.ACCOUNT_TIMELINE
      || this.caseManagementService.accessedFrom === AccesingMode.CONTACT_TIMELINE
      || this.caseManagementService.accessedFrom === AccesingMode.XPERIENCE_INSIGHTS) {
      return;
    }
    //If No contacs mapped to completed case
    if (this.selectedCase._case_status_value != 'Open' && !this.selectedCase._case_contact) return;
    if (this.selectedCase._case_status_value !== 'Open' && this.isCaseDisabled) {
      this.contactService.contactPageMode = ComponentViewMode.READONLY;
    }
    else {
      this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
    }
    this.contactService.accessedContactListFrom = PageName.CaseManagementDetailComponent;
    this.isClickedContact = true;
    /* Just for hiding details during selection  */
    if (this.isModalView) {
      if (this.caseManagementService.accessedFrom === AccesingMode.INMEETING ||
        this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION ||
        this.caseManagementService.accessedFrom === AccesingMode.IN_PHONE_CALL ||
        this.caseManagementService.accessedFrom === AccesingMode.IN_SET_BOOKING) {
      if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        setTimeout(()=>{
          document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
        });
      }

        if (this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent) {
          /* Trying to create inmeeting from contact page */
          this.contactService.isCaseManagementInvoked = true;
        } else if (this.navService.getCurrentMasterPageName() === PageName.CallPlanComponent) {
          this.contactService.isCaseManagementInvoked = true;
          this.uIService.prevView = this.uIService.activeView;
        }
        else {
          this.contactService.isCaseManagementInvoked = true;
        }
        if (this.caseManagementService.accessedFrom === AccesingMode.IN_PHONE_CALL) {
          this.contactService.accessedContactListFrom = PageName.CaseManagementDetailComponent;
        }
          this.navCtrl.push(ContactPageComponent, null, { progressAnimation: false });
      }
      console.log(this.navService.getCurrentPageName());
    }
    else {
      this.contactService.isCaseManagementInvoked = true;
      this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, null, PageName.ContactPageComponent);
    }
  }

  public accountSelection(e) {
    if (this.caseManagementService.accessedFrom === AccesingMode.ACCOUNT_TIMELINE
      || this.caseManagementService.accessedFrom === AccesingMode.CONTACT_TIMELINE
      || this.caseManagementService.accessedFrom === AccesingMode.XPERIENCE_INSIGHTS) {
      return;
    }
    //If No accounts mapped to completed case
    if (this.selectedCase._case_status_value != 'Open' && !this.selectedCase._case_account) return;
    if (this.isModalView) {
      if (this.caseManagementService.accessedFrom === AccesingMode.INMEETING ||
        this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION ||
        this.caseManagementService.accessedFrom == AccesingMode.IN_PHONE_CALL ||
        this.caseManagementService.accessedFrom == AccesingMode.IN_SET_BOOKING) {
        if (this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent) {
          this.accountService.selectedFor = AccountSelectedFor.CASE_INTAKE_SELECTOR;
          this.accountService.isCaseManagementInvoked = true;
          /* Just for hiding details during selection  */
          if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
            setTimeout(()=>{
              document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
            });
          }

          this.accountService.accessedAccountListFrom = PageName.CaseManagementDetailComponent;
          if (this.selectedCase._case_status_value != 'Open' && this.isCaseDisabled) {
            this.navCtrl.push(AccountPageComponent, { 'listMode': ComponentViewMode.READONLY }, { progressAnimation: false });
          }
          else {
            this.navCtrl.push(AccountPageComponent, { 'listMode': ComponentViewMode.ADDNEW }, { progressAnimation: false });
          }
          return;
        }
        else {
          this.accountService.selectedFor = AccountSelectedFor.CASE_INTAKE_SELECTOR;
          this.accountService.isCaseManagementInvoked = true;
          if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
            setTimeout(()=>{
              document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
            });
          }

          this.accountService.accessedAccountListFrom = PageName.CaseManagementDetailComponent;
          if (this.selectedCase._case_status_value != 'Open' && this.isCaseDisabled) {
            this.navCtrl.push(AccountPageComponent, { 'listMode': ComponentViewMode.READONLY }, { progressAnimation: false });
          }
          else {
            this.navCtrl.push(AccountPageComponent, { 'listMode': ComponentViewMode.ADDNEW }, { progressAnimation: false });
          }
          return;
        }
      }
    }
    else {
      this.accountService.selectedFor = AccountSelectedFor.CASE_INTAKE_SELECTOR;
      this.accountService.isCaseManagementInvoked = true;
      /* Just for hiding details during selection  */
      this.accountService.accessedAccountListFrom = PageName.CaseManagementDetailComponent;
      if (this.selectedCase._case_status_value != 'Open' && this.isCaseDisabled) {
        this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { 'listMode': ComponentViewMode.READONLY }, PageName.AccountPageComponent);
      }
      else {
        this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { 'listMode': ComponentViewMode.ADDNEW }, PageName.AccountPageComponent);
      }

    }
  }

  private openKitBooking() {
    if(this.caseManagementService.accessedFrom == AccesingMode.IN_SET_BOOKING) return;
    const isReadOnlyMode = (this.selectedCase._case_status_value !== 'Open' && this.isCaseDisabled);
    // const isReadOnlyMode = (!((this.activityService.selectedActivity as CaseActivity)._case_status_value.toLocaleLowerCase().includes('open'))
    // || this.activityService.selectedActivity.isCompleted || this.activityService.selectedActivity?.isDiffPosition)
    // || (this.activityService.selectedActivity.ownerId != this.authenticationService.user.systemUserID
    //   && (this.isCovisitor && this.coVisitorAccess !== CovisitorAccess.FULL_ACCESS));
    const selectedKitBooking = this.activityService.getActivityByID(this.selectedCase['indskr_kitbooking']);
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('KIT_BOOKINGS'),
      dividerTitle: this.translate.instant('KIT_BOOKINGS'),
      isSearchEnabled: !isReadOnlyMode,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: !isReadOnlyMode,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'secondaryTextLeft',
      hideAllItemsList: false,
      isListSelectionEnabled: !isReadOnlyMode || !this.device.isOffline,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleKitBookingComponentEvent(data, eventTarget, refData),
      data: this.getKitBookingSelectionData(isReadOnlyMode, selectedKitBooking),
    };
    if (this.isModalView && (this.caseManagementService.accessedFrom === AccesingMode.INMEETING ||
      this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION ||
      this.caseManagementService.accessedFrom === AccesingMode.IN_PHONE_CALL)) {
        if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          setTimeout(() => {
            document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
          });
        }
       this.navCtrl.push(MainToolTemplateComponent, { viewData: listDetail, isNavWithinModalView: true });
    } else {
      this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, disableSorting: true }, PageName.MainToolTemplateComponent);
    }
  }

    private getKitBookingSelectionData(isReadOnlyMode, selectedKitBooking) {
      let setBookingActivities = _.cloneDeep(this.activityService.activities.filter(o => o.type == ActivityType.SetBooking) as SetBookingActivity[]);
      setBookingActivities = setBookingActivities.filter(setbooking => (setbooking.indskr_status!= SetBookingStatus.CANCELLED && setbooking.indskr_status!= SetBookingStatus.CANCELERROR && setbooking.indskr_status!= SetBookingStatus.PENDINGCANCEL) || setbooking.ID==selectedKitBooking?.ID )
      setBookingActivities = setBookingActivities.sort((a, b): number => {
        if (a.scheduledStart > b.scheduledStart) return 1;
        if (a.scheduledStart < b.scheduledStart) return -1;
        return 0;
      });
      const data: Array<MainCardViewDataModel> = setBookingActivities.map(item => {
      let isSelected = !_.isEmpty(selectedKitBooking) ? item.ID == selectedKitBooking?.ID : false;
      return {
        id: item.ID,
        primaryTextLeft: item.statusString ? `${this.translate.instant('STATUS')} - ${item.statusString}` : '',
        secondaryTextLeft: item.scheduledStart ? `${format(new Date(item.scheduledStart), this.dateTimeFormatsService.dateToUpper)}` : '',
        showEndIcon: !isReadOnlyMode,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isSelected,
        endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: item.subject ? item.subject : this.translate.instant('KIT_BOOKING'),
        showArrow: false,
        isSelected: false,
        eventOwnerId: (this.activityService.selectedActivity as CaseActivity).ownerId
      };
    });
    return data;
  }

  private async _handleKitBookingComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      // let caseActivity = this.activityService.selectedActivity as CaseActivity;
      let caseActivity = this.activityService.getActivityByID(data.selectedItems[0].id) as CaseActivity;
      let payload = [];
      if (_.isEmpty(data.selectedItems)) {
        this.selectedCase.indskr_kitbooking = '';
        if(!_.isEmpty(caseActivity)) caseActivity.indskr_kitbooking = '';
      } else {
        this.selectedCase.indskr_kitbooking = data.selectedItems[0].id;
        if(!_.isEmpty(caseActivity)) caseActivity.indskr_kitbooking = data.selectedItems[0].id;
        payload.push({
          'indskr_kitbooking': data.selectedItems[0].id
        });
      }
      this.updateDataOnPouch();
      // await this.caseManagementService.upsertMyCases(caseActivity);

      // if (this.device.isOffline) // || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)
      // {
      //   await this.caseManagementService.upsertMyCases(caseActivity);
      // }
      // else{
      //   try {
      //     await this.caseManagementDataService.updateKitBookings(caseActivity, payload);
      //   } catch (error) {
      //     console.error('Adding Set Booking to Customer Inquiry: ', error);
      //     this.caseManagementService.upsertMyCases(caseActivity);
      //   }
      // }
      //this.initFormFields();
    } else if (eventTarget && eventTarget === 'LeftHeaderButtonClick') {
      await this.navService.popWithPageTracking();
    } else if (eventTarget && eventTarget === 'MainItemEvents-mainItem') {
      if(data && data.id) {
        let linkedKitBooking = this.activityService.getActivityByID(data.id);
        if(linkedKitBooking) {
          this.navService.pushChildNavPageWithPageTracking(SetBookingActivityDetailsComponent, PageName.SetBookingActivityDetailsComponent, PageName.ActivitiesPageComponent, { from: 'CaseManagement', activity: linkedKitBooking });
          this.navService.setChildNavRightPaneView(true);
          this.uIService.showRightPane = true;
        } else {
          this.notificationService.notify(this.translate.instant("SOMETHING_REALLY_BAD_HAPPENED"), 'Case Management Details', 'top', ToastStyle.DANGER);
          return;
        }
      }
    }
  }

  public async setCaseData(obj) {
    this.initFooter();
    switch (obj.action) {
      case DETAILS_OPTION.ACCOUNT_SELECTION: {
        console.log('Account Selected');
        if (obj.data && obj.data.length) {
          this.selectedCase._case_account = obj.data[0];
          /*surveyComponent*/
          if(this.isSurveyConfigured) {
            let lookupQuestions = [];
            this.surveyTemplate.pages[0].elements.forEach(element => {
              if(element.type == 'lookup'){
                lookupQuestions.push(element)
              }else if(element.type == 'panel' && element.elements && element.elements.length > 0){
                let foundElements = this.getLookupElementsRecursively(element.elements);
                if(foundElements && foundElements.length > 0){
                  lookupQuestions.push(...foundElements);
                }
              }
            });
            const metadata = lookupQuestions.find((question) => question.metadata.target.name == 'account').metadata
            this.selectedCase._case_inquiry_additional_attributes['indskr_Account@odata.bind'] = '/'+ metadata.target.setName + '(' + obj.data[0].id + ')'
            let account = {
              targetEntity: 'account',
              id: obj.data[0] ? obj.data[0].id : undefined,
              name: obj.data[0] ? obj.data[0].accountName : undefined
            }
            this.surveyService.assignLookupResult(account);
            this.mapSurveyLookupResults(account);
          } else {
            this.selectedCase._case_account_id = obj.data[0].id;
          }
        }
        else {
          this.selectedCase._case_account = undefined;
          this.selectedCase._case_account_id = undefined;
          /*surveyComponent*/
          if(this.isSurveyConfigured) {
            if(this.selectedCase._case_inquiry_additional_attributes) this.selectedCase._case_inquiry_additional_attributes['indskr_Account@odata.bind'] = null;
            let account = {
              targetEntity: 'account',
              id: undefined,
              name: undefined
            }
            this.surveyService.assignLookupResult(account);
            this.mapSurveyLookupResults(account);
          }
        }
        this.selectedCase._case_trans_account_name = this.selectedCase._case_account ? this.selectedCase._case_account.accountName : this.translate.instant('ACCOUNT');
        this.isCaseDisabled = (!(this.selectedCase._case_status_value === 'Open' && this.validateCaseForReview));
        this.caseManagementService.setDetailsOption({
          data: undefined,
          action: DETAILS_OPTION.RESET_SELECTION
        });
        this.initFooter();

        await this.updateDataOnPouch();
        break;
      }
      case DETAILS_OPTION.CONTACT_SELECTION: {
        console.log('Contact Selected');
        if (obj.data && obj.data.length) {
          this.selectedCase._case_contact = obj.data[0];
          this.selectedCase._case_contact_id = obj.data[0].ID;
          this.isClickedContact = false;
          this.contactDetailSetting();
        }
        else {
          this.selectedCase._case_contact = undefined;
          this.selectedCase._case_contact_id = undefined;
          this.contactDetails = this.responseType;
        }

        /* surveyComponent - commented out. Contact moved to Static field */
        // let contact = {
        //   targetEntity: 'contact',
        //   id: this.selectedCase._case_contact_id,
        //   name: this.selectedCase._case_contact ? this.selectedCase._case_contact.fullName : ''
        // }
        // this.surveyService.assignLookupResult(contact);
        // this.mapSurveyLookupResults(contact);

        /* Display name on HTML */
        this.selectedCase._case_trans_customer_name = this.selectedCase._case_contact ? this.selectedCase._case_contact.fullName : this.utilityService.globalCustomerText;

        /* recheck disable */
        this.isCaseDisabled = (!(this.selectedCase._case_status_value === 'Open' && this.validateCaseForReview));

        /* reset the set data value */
        this.caseManagementService.setDetailsOption({
          data: undefined,
          action: DETAILS_OPTION.RESET_SELECTION
        });
        if (this.selectedCase._offline_meetingID || this.selectedCase._online_meetingID)
          this.events.publish('updateEmbeddedActivity', this.selectedCase);

        /* footer reset */
        this.initFooter();
        this.caseManagementDetailsHeaderModel();
        /* update pouch */
        await this.updateDataOnPouch();
        break;
      }

      default:
        break;
    }
  }

  private selectAssignee() {
    if (this.selectedCase._case_status_value !== 'Open' && this.isCaseDisabled) return;
    const { users, teams } = this.getUsersAndTeams();
    const hasUsers: boolean = users.length > 0;
    const hasTeams: boolean = teams.length > 0
    if (!hasUsers && !hasTeams) return;
    this.isClickedAssignee = true;
    let doNotCheckSelected = false;
    const generateViewData = (assignees: CaseAssignee[], checkSelected?: boolean): MainCardViewDataModel[] => {
      return assignees.map(assignee => {
        const isSelected = checkSelected && !doNotCheckSelected ? assignee.id == this.selectedCase._case_owner_id : false;
        return {
          id: assignee.id,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: true,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: assignee.indskr_name,
          showArrow: false,
          arrowType: '',
        };
      })
    };
    const getListData = (typeUser: boolean) => {
      let basicData: any = {};
      if (typeUser) {
        basicData.title = this.translate.instant('USERS');
        basicData.dividerTitle = this.translate.instant('ALL_USERS');
        basicData.data = generateViewData(users, true);
        basicData.isFor = CaseAssigneeType.USER;
      } else {
        basicData.title = this.translate.instant('TEAMS');
        basicData.dividerTitle = this.translate.instant('ALL_TEAMS');
        basicData.data = generateViewData(teams, true);
        basicData.isFor = CaseAssigneeType.TEAM;
      }
      return basicData;
    }
    const isTeamSelected = this.selectedCase._case_owner_id != null && this.selectedCase._case_owner_id != '' && hasUsers && hasTeams && teams.some(assignee => assignee.id == this.selectedCase._case_owner_id);
    const basicDetails = getListData(isTeamSelected ? false : hasUsers);
    let listDetail: MainToolTemplateDetail = {
      ...basicDetails,
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      hideAllItemsList: false,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: async (data: any, eventTarget: string, refData: MainToolTemplateDetail) => {
        if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
          let assignee = null;
          if (data.selectedItems && data.selectedItems.length > 0) {
            assignee = data.selectedItems[0];
          }
          this.selectedCase._case_owner_id = this.selectedCase['ownerid_value'] = assignee ? assignee.id : '';
          this.selectedCase._case_assignee = this.selectedCase['ownerid_value@OData.Community.Display.V1.FormattedValue'] = assignee ? assignee.primaryTextRight : '';
          this.selectedCase.owner_ref = listDetail.isFor as CaseAssigneeType;
          this.isCaseDisabled = (!(this.selectedCase._case_status_value === 'Open' && this.validateCaseForReview));
          this.initFooter();
          this.caseManagementDetailsHeaderModel();
          await this.updateDataOnPouch();
        }
      },
      searchHandler: (text: string) => {
        let ids: Array<string> = [];
        let assigneeArr = listDetail.isFor == CaseAssigneeType.USER ? users : teams;
        if (text.length >= 1) {
          ids = assigneeArr.filter(assignee => {
            return assignee.indskr_name.trim().toLowerCase().includes(text.trim().toLowerCase());
          }).map(tag => tag.id);
        } else {
          ids = assigneeArr?.map(assignee => assignee.id);
        }
        return ids;
      }
    };

    if (hasTeams && hasUsers) {
      listDetail.isTabEnabled = true;
      listDetail.tabData = [
        {
          displayText: this.translate.instant('USERS'),
          value: CaseAssigneeType.USER
        },
        {
          displayText: this.translate.instant('TEAMS'),
          value: CaseAssigneeType.TEAM
        }
      ];
      listDetail.isFor = isTeamSelected ? CaseAssigneeType.TEAM : CaseAssigneeType.USER;
      listDetail.tabChangeHandler = (tab: CaseAssigneeType): MainToolTemplateDetail => {
        listDetail = { ...listDetail, ...getListData(listDetail.isFor == CaseAssigneeType.USER) };
        doNotCheckSelected = true;
        return listDetail;
      }
    }
    if (this.isModalView && (this.caseManagementService.accessedFrom === AccesingMode.INMEETING ||
      this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION ||
      this.caseManagementService.accessedFrom === AccesingMode.IN_PHONE_CALL ||
      this.caseManagementService.accessedFrom === AccesingMode.IN_SET_BOOKING)) {
        if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          setTimeout(() => {
            document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
          });
        }
       this.navCtrl.push(MainToolTemplateComponent, { viewData: listDetail, isNavWithinModalView: true });
    } else {
      this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail }, PageName.MainToolTemplateComponent);
    }
  }

  private getUsersAndTeams() {
    const users = this.caseManagementService.getCategoryUsers(this.selectedCase._case_type_id);
    const teams = this.caseManagementService.getCategoryTeams(this.selectedCase._case_type_id);
    return { users, teams };
  }

  public contactDetailSetting() {
    this.contactDetails = this.responseType;
    if (this.selectedCase && this.selectedCase._case_response_preference) {
      if (String(this.selectedCase._case_response_preference.name).toLowerCase().includes('email')) {
        let temp: Email;
        if (this.selectedCase._case_contact) {
          temp = this.selectedCase._case_contact.emailAddressList.find(c => c.isPrimary);
        }

        this.selectedCase._selected_email = temp;
        this.selectedCase._selected_phone = undefined;
        this.selectedCase._selected_address = undefined;

        this.selectedCase._selected_email_id = (temp && temp.emailAddress) ? temp.emailAddress : temp;
        this.contactDetails = temp ? temp.emailAddress : this.responseType;
      }
      if (this.selectedCase._case_contact && String(this.selectedCase._case_response_preference.name).toLowerCase().includes('phone')) {
        this.contactDetails = this.selectedCase._case_contact.mobilePhone ? this.selectedCase._case_contact.mobilePhone : this.selectedCase._case_contact.telephone ? this.selectedCase._case_contact.telephone : this.responseType;
        this.selectedCase._selected_phone = this.contactDetails;

        this.selectedCase._selected_email = undefined;
        this.selectedCase._selected_address = undefined;

      }
      if (this.selectedCase._case_contact && String(this.selectedCase._case_response_preference.name).toLowerCase().includes('visit')) {
        this.contactDetails = this.selectedCase._case_contact.getPrimaryAddress ? this.selectedCase._case_contact.getPrimaryAddress : this.responseType;
        this.selectedCase._selected_address = this.selectedCase._case_contact.getPrimaryAddress;

        this.selectedCase._selected_email = undefined;
        this.selectedCase._selected_phone = undefined;
      }
    }
  }

  public async selectResponsePref(e) {
    if (this.selectedCase._case_status_value != 'Open' && this.isCaseDisabled) return;

    let popover = await this.popoverCtrl.create({
      component: CasePopoverComponent,
      componentProps:{
        value: {
          field: CasePopupEvent.RESPONSEPREFENCE,
          case: this.selectedCase
        }
      },
      cssClass: 'case-type-popover',
      event:e}
    );
    popover.present();
    popover.onDidDismiss().then(
      async (data: any) => {
        this.isClickedResponsePreference = true;
        data = data.data;
        if (data) {
          this.selectedCase._case_response_preference = data;
          this.selectedCase._case_response_preference_id = data.id;
          this.isCaseDisabled = (!(this.selectedCase._case_status_value === 'Open' && this.validateCaseForReview));
          this.initFooter();
          this.contactDetails = this.responseType;

          if (String(data.name).toLowerCase().includes('email')) {
            this.responseType = this.translate.instant('EMAIL');

            let temp: Email;
            if (this.selectedCase._case_contact) {
              temp = this.selectedCase._case_contact.emailAddressList.find(c => c.isPrimary);
            }
            this.selectedCase._selected_email = temp;
            this.selectedCase._selected_email_id = (temp && temp.emailAddress) ? temp.emailAddress : temp;
            this.contactDetails = temp ? temp.emailAddress : this.responseType;
          }
          if (String(data.name).toLowerCase().includes('phone')) {
            this.responseType = this.translate.instant('PHONE');
            console.log(this.selectedCase._case_contact);
            if (this.selectedCase._case_contact) {
              this.contactDetails = this.selectedCase._case_contact.mobilePhone ? this.selectedCase._case_contact.mobilePhone : this.selectedCase._case_contact.telephone ? this.selectedCase._case_contact.telephone : this.responseType;
            } else {
              this.contactDetails = this.responseType;
            }
            this.selectedCase._selected_phone = this.contactDetails;
          }
          if (String(data.name).toLowerCase().includes('visit')) {
            this.responseType = this.translate.instant('ADDRESS');
            console.log(this.selectedCase._case_contact);
            if (this.selectedCase._case_contact) {
              this.contactDetails = this.selectedCase._case_contact.getPrimaryAddress ? this.selectedCase._case_contact.getPrimaryAddress : this.responseType;
              this.selectedCase._selected_address = this.selectedCase._case_contact.getPrimaryAddress;
            }  else {
              this.contactDetails = this.responseType;
            }
          }
          this.caseManagementDetailsHeaderModel();
          await this.updateDataOnPouch();

        }
      });
  }

  public async selectCategory(e) {
    if (this.selectedCase._case_status_value != 'Open' && this.isCaseDisabled || (this.caseManagementService.getCurrentCaseCategoryTypes()
         && this.caseManagementService.getCurrentCaseCategoryTypes().length === 0)) return;

    let popover = await this.popoverCtrl.create({
      component: CasePopoverComponent,
      componentProps:{
        value: {
          field: CasePopupEvent.CASECATEGORY,
          case: this.selectedCase
        }
      },
      cssClass: 'case-type-popover',
      event:e}
    );
    popover.present();
    popover.onDidDismiss().then(
      async (data: any) => {
        data = data.data;
        if (data) {
          await this.onCategorySelectedAction(data);
        }
        this.isClickedCategory = true;
      });
  }

  public async onCategorySelectedAction(data){
    if (data) {
      this.selectedCase._case_category = data;
      this.selectedCase._case_category_id = data.id;
      this.categoryName = this.selectedCase._case_category ? this.selectedCase._case_category.name : this.translate.instant('INQUIRY_TYPE');
      this.isCaseDisabled = (!(this.selectedCase._case_status_value === 'Open' && this.validateCaseForReview));
      this.isClickedCategory = false;
      if(this.selectedCase._case_category)
        this.setFlagsOnCategorySelection();
      this.initFooter();
      this.caseManagementDetailsHeaderModel();
      await this.updateDataOnPouch();
    }
  }

  public setFlagsOnCategorySelection(){
    //hide product selection if the flag is set in dynamics
    this.isNoProductSelection = this.selectedCase._case_category["indskr_productselection"] && this.selectedCase._case_category["indskr_productselection"] === 548910000 ? true : false; 
    //make customer field hidden if the flag is set in dynamics(the field behaviour was altered and label renamed to Hide Customer on Dynamics)
    this.isHideCustomerField = this.selectedCase._case_category["indskr_customermandatory"] === true ? true : false;
    this.isAccountFieldMandatory = this.selectedCase._case_category["indskr_makeaccountmandatory"] === true ? true : false;
    //auto fill response preference in case customer field is hidden
    if(this.isHideCustomerField){
      let data = this.caseManagementService.responsePreferenceList?.filter(c => c.name.toLowerCase().includes('required'))[0];
      if(data){
        this.selectedCase._case_response_preference = data;
        this.selectedCase._case_response_preference_id = data.id;
        this.isClickedResponsePreference = true;
      }
    }
    else{
      this.selectedCase._case_response_preference = null;
      this.selectedCase._case_response_preference_id = null;
      this.isClickedResponsePreference = false;
    }
    if(!this.isHideCustomerField && !this.selectedCase._case_contact && this.selectedCase._case_contact_id === this.selectedCase._case_account_id){
      // when case is submitted
      // when case is created in offline mode
    }
  }

  public async sendCaseForReview(fromFooter?: boolean) {
    if (this.isCaseDisabled) return;
    if (this.validateCaseForReview) {
      if (this.isSurveyConfigured) this.events.publish('completeSurvey_sendCaseForReview');
      //populate customer field with account information in case hide customer is true
      if(this.isHideCustomerField && this.selectedCase._case_account_id){
        this.selectedCase._case_contact_id = this.selectedCase._case_account_id;
        this.selectedCase._case_contact = undefined;
        this.selectedCase._case_trans_customer_name = this.selectedCase._case_trans_account_name;
        // if (!this.uIService.toolsActivityActive) {
        //   this.events.publish("refreshAgenda");
        // } else this.uIService.agendaRefreshRequired = true;
      }
      if (this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_SIGNATURE)) {
        if (
          this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_INQUIRY_OFFLINE)
        ) {
          this.offlineFAEnabled = true;
        }
        if (this.device.isOffline) {
          if (this.offlineFAEnabled) {
            if (this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
              await this.navService.pushChildNavPageWithPageTracking(CaptureCustomerInquireComponent, PageName.CaptureCustomerInquireComponent, PageName.PresentationMeetingComponent, this.selectedCase);
            } else {
              const model = await this.modalCtrl.create({
                component: CaptureCustomerInquireComponent, componentProps: { selectedCase: this.selectedCase }, animated: false, backdropDismiss: false
              });
              model.present();
              model.onDidDismiss().then(data => {
                this.initFooter();
                this.initSectionHeader();
              });
            }
          } else {
            this.notificationService.notify(
              this.translate.instant('CASE_CU_INQ_SUB_ALLOW_ONLY_ONLINE_WITH_TEXT', { text: this.globalCustomerText }),
              'Customer Inquiry',
              'top',
              ToastStyle.DANGER
            );
          }
        } else {
          if (this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
            await this.navService.pushChildNavPageWithPageTracking(CaptureCustomerInquireComponent, PageName.CaptureCustomerInquireComponent, PageName.PresentationMeetingComponent, this.selectedCase);
          } else {
            const model = await this.modalCtrl.create({
              component: CaptureCustomerInquireComponent, componentProps: { selectedCase: this.selectedCase }, animated: false, backdropDismiss: false
            });
            model.present();
            model.onDidDismiss().then(data => {
              this.initFooter();
              this.initSectionHeader();
            });
          }
        }
      }
      else {
        if (
          this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_INQUIRY_OFFLINE)
        ) {
          this.offlineFAEnabled = true;
        }
        if (fromFooter) {
          this.trackingService.tracking('InquiryActivitySendActionBar', TrackingEventNames.CUSTOMER_INQUIRY);
        }
        else {
          this.trackingService.tracking('InquiryActivitySend', TrackingEventNames.CUSTOMER_INQUIRY);
        }

        if (this.device.isOffline) {
          if (this.offlineFAEnabled) {
            //Generate case in offline mode
            // if (this.isSurveyConfigured) this.events.publish('completeSurvey_sendCaseForReview');
            let res: CaseActivity = await this.caseManagementService.generateOfflineCase(this.selectedCase);
            this.caseManagementService.assignSelectedCase(res);
            this.selectedCase = res;
            this.events.publish('customerInquiryActivitySubmitted', this.selectedCase);
            if (this.selectedCase._offline_meetingID || this.selectedCase._online_meetingID ||
              this.selectedCase.indskr_phonecallid)
              this.events.publish('updateEmbeddedActivity', this.selectedCase);
            this.initFooter();
            this.initSectionHeader();
          } else {
            this.notificationService.notify(
              this.translate.instant('CASE_CU_INQ_SUB_ALLOW_ONLY_ONLINE_WITH_TEXT', { text: this.globalCustomerText }),
              'Customer Inquiry',
              'top',
              ToastStyle.DANGER
            );
          }
        }
        else {
          const loader = await this.loadingController.create();
          loader.present();
          // if (this.isSurveyConfigured) this.events.publish('completeSurvey_sendCaseForReview');
          await this.caseManagementDataService.createCaseOnline(this.selectedCase).then(
            async value => {
              if (!value.hasOwnProperty('error')) {
                await this.caseManagementService.addNewCase(value, this.selectedCase).then(
                  res => {
                    this.caseManagementService.assignSelectedCase(res);
                    this.selectedCase = res;
                    this.events.publish('customerInquiryActivitySubmitted', this.selectedCase);
                    if (this.selectedCase._offline_meetingID || this.selectedCase._online_meetingID ||
                      this.selectedCase.indskr_phonecallid)
                      this.events.publish('updateEmbeddedActivity', this.selectedCase);
                    loader.dismiss();
                    this.initFooter();
                    this.initSectionHeader();
                  },
                  async err => {
                    loader.dismiss();
                  }
                );
              }
              else {
                loader.dismiss();
                let res: CaseActivity = await this.caseManagementService.generateOfflineCase(this.selectedCase);
                this.caseManagementService.assignSelectedCase(res);
                this.selectedCase = res;
                this.events.publish('customerInquiryActivitySubmitted', this.selectedCase);
                if (this.selectedCase._offline_meetingID || this.selectedCase._online_meetingID)
                  this.events.publish('updateEmbeddedActivity', this.selectedCase);
                this.initFooter();
                this.initSectionHeader();
              }
            }
          ).catch(
            async err => {
              loader.dismiss();
              let res: CaseActivity = await this.caseManagementService.generateOfflineCase(this.selectedCase);
              this.caseManagementService.assignSelectedCase(res);
              this.selectedCase = res;
              this.events.publish('customerInquiryActivitySubmitted', this.selectedCase);
              if (this.selectedCase._offline_meetingID || this.selectedCase._online_meetingID)
                this.events.publish('updateEmbeddedActivity', this.selectedCase);
              this.initFooter();
              this.initSectionHeader();
            });
        }
      }
    }
    else {
      return;
    }
  }

  async openAcknowledgement(){
    if(this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION){

      this.navService.pushChildNavPageWithPageTracking(CaptureCustomerInquireComponent, PageName.CaptureCustomerInquireComponent, PageName.PresentationMeetingComponent,
        { inquiryRef: this.selectedCase }).then(() => {

        });
     } else{
      const model = await this.modalCtrl.create({component: CaptureCustomerInquireComponent,componentProps: { selectedCase: this.selectedCase }, animated:false, backdropDismiss: false });
      model.present();
      model.onDidDismiss().then(data => {
        this.initFooter();
       });
     }

  }

  public get validateCaseForReview(): boolean {
    if(this.isSurveyConfigured){
      let isAllDefaultFieldsVaid = this.selectedCase._case_response_preference && this.selectedCase._case_contact && this.selectedCase._case_owner_id ? true : false;
      return this.caseManagementService.isSurveyValid && isAllDefaultFieldsVaid;
    } else {
      return (this.selectedCase._case_product || this.isNoProductSelection) &&
        (this.selectedCase._case_contact || this.isHideCustomerField) &&
        (this.selectedCase._case_account || !this.isAccountFieldMandatory) &&
        this.selectedCase._case_response_preference &&
        this.selectedCase._case_type &&
        this.selectedCase._case_owner_id &&
        this.selectedCase._case_category &&
        (this.selectedCase._case_notes && this.selectedCase._case_notes.length) &&
        this.entitiesExistOnDynamics;
    }
  }

  /*
    General logic for checking that the selected product, account, response preference, category, type
  */
  get entitiesExistOnDynamics(): boolean {
    let validator: boolean = true;

    /* Product mapped on selected case but deleted after sync */
    /* Contact mapped on selected case but deleted after sync */
    if (this.selectedCase._case_contact && !this.contactService.contacts.find(p => p.ID === this.selectedCase._case_contact.ID)) {
      this.selectedCase._case_contact = undefined;
      this.selectedCase._case_contact_id = undefined;
      this.selectedCase._case_trans_customer_name = this.utilityService.globalCustomerText;
      validator = false;
      this.isCaseDisabled = true;
    }

    return validator;
  }

  async selectContactDetails(e) {
    if (this.selectedCase._case_status_value !== 'Open' && this.isCaseDisabled) return;

    let popover = await this.popoverCtrl.create(
      {component: CasePopoverComponent,
      componentProps:{
        value: {
          field: CasePopupEvent.CONTACTDETAILS,
          case: this.selectedCase
        }
      },
      cssClass: 'case-type-popover',
      event:e}
    );
    popover.present();
    popover.onDidDismiss().then(
      async (data: any) => {
        data = data.data;
        if (data) {

          if (this.selectedCase._case_contact && String(this.selectedCase._case_response_preference.name).toLowerCase().includes('email')) {
            this.contactDetails = this.selectedCase._selected_email_id = data.emailAddress;
            this.selectedCase._selected_phone = undefined;
            this.selectedCase._selected_address = undefined;
          }
          if (this.selectedCase._case_contact && String(this.selectedCase._case_response_preference.name).toLowerCase().includes('phone')) {
            this.contactDetails = this.selectedCase._selected_phone = data;
            this.selectedCase._selected_email_id = undefined;
            this.selectedCase._selected_address = undefined;
          }
          if (this.selectedCase._case_contact && String(this.selectedCase._case_response_preference.name).toLowerCase().includes('visit')) {
            this.contactDetails = this.selectedCase._selected_address = data.compositeAdd;
            this.selectedCase._selected_phone = undefined;
            this.selectedCase._selected_email_id = undefined;
          }

          this.isCaseDisabled = (!(this.selectedCase._case_status_value === 'Open' && this.validateCaseForReview));
          this.initFooter();
          this.caseManagementDetailsHeaderModel();
          await this.updateDataOnPouch();
        }
      });
  }

  async updateDataOnPouch() {
    if (this.backgroundUploadInProgress) return;
    try {
      await this.diskService.addCaseToCollection(this.selectedCase);
    } catch (error) {
      console.log(error);
    }

    try {
      await this.caseManagementService.upsertMyCases(this.selectedCase);
    } catch (error) {
      console.log(error);
    }

    // this.eventService.publish('weekview:RefreshUI');
    this.activityService.publishActivityEvent({action: "Update", activity: this.selectedCase});

    this.eventService.publish("customerInquiryActivityIsUpdated", this.selectedCase);

    //this.eventService.publish("activityUpdated",this.selectedCase);

    //this.caseManagementService.assignSelectedCase(this.selectedCase);
  }

  async scrapTrigger() {
    this.alertService.showAlert({
      title: this.translate.instant('POP_SCRAP_INQUIRY'),
      message: this.translate.instant('POP_R_U_SURE_SCRAP_SELECTED_INQUIRY')
    }, this.translate.instant('SCRAP')
    ).then(res => {
      if (res.role === 'ok') {
        if (this.backgroundUploadInProgress) return;
        this.trackingService.tracking('InquiryActivityScrapActionBar', TrackingEventNames.CUSTOMER_INQUIRY);
        this.caseManagementService.scrapCustomerInquiry(this.selectedCase, null, null, this.isModalView);
        this.goBack();
      }
    });
  }

  inquiryNotesUpdated(value: string) {
    this.selectedCase._case_notes = value.trim();
    this.isCaseDisabled = (!(this.selectedCase._case_status_value === 'Open' && this.validateCaseForReview));
    this.initFooter();
    this.titleControls = [];
    this.addControllsTitle();
    this.caseManagementDetailsHeaderModel();
  }

  isDataAvailable() {
    return this.selectedCase._case_timeline && this.selectedCase._case_timeline.length > 0;
  }

  public getGroupedActivitiesByMonth(activities): Array<Object> {
    const events: any[] = [];
    const groupedElements: any = {};
    let groupByKey = 'scheduledStart';
    if (activities) {
      let value: any = activities;
      if (value.length > 0) {
        if (value.length > 1) {
          value.sort((a, b) => (a.scheduledStart.getTime()) - (b.scheduledStart.getTime()));
          value = value.reduce((total, current) => {
            let x = false;
            for(let i = total.length - 1 ; i >= 0; i--) {
              if(total.length > 0) {
                if(total[i].currentStatus.length === 0) {
                  continue;
                }
                if(total[i].currentStatus === current.currentStatus) {
                  x = true;
                  if (current.currentStatus) {
                    current.description = '';
                  }
                  current.currentStatus = '';
                }
                break;
              }
            }

            return total.concat([current]);
          }, []);
        }

        value.sort((a, b) => (b.scheduledStart.getTime()) - (a.scheduledStart.getTime()));
        value.forEach((obj: any) => {
          let item = this.datePipe.transform(obj[groupByKey], 'MMMM y', undefined, this.translate.currentLang);
          if (!(item in groupedElements)) {
            groupedElements[item] = [];
          }
          groupedElements[item].push(obj);
        });

        let uniqObj = {};
          value.forEach((item, index, obj) => {
            if (item.status) {
              item.status = this.caseManagementService.translateCaseStatus(item.status);
            }
            if (item.currentStatus) {
              item.description = '';
              if (item.currentStatus.indexOf(this.translate.instant('INQUIRY_TIMELINE_STATUS_CHANGED_TO')) >= 0) {
                return;
              }
              else {

                item.currentStatus = this.translate.instant('INQUIRY_TIMELINE_STATUS_CHANGED_TO') + ' ' + item.currentStatus;
              }
            } else {
              if (item.description && item.description.indexOf('Status -') !== -1) {
              item.currentStatus = item.description.replace('Status -', this.translate.instant('INQUIRY_TIMELINE_STATUS_CHANGED_TO'));
              } else if (item.description && item.description.indexOf('Assigned to') !== -1) {
                item.currentStatus = item.description.replace('Assigned to', this.translate.instant('ASSIGNED_TO'));
              }
            }

            if (value[index].hasOwnProperty('activityId') && value[index].activityId) {
              let o = value[index].activityId;
              uniqObj[o] = value[index];
              let uObj = uniqObj[value[index].activityId];
              if (uObj && uObj.activityId == value[index].activityId) {
                value[index].contactNames.push(uObj.contactName);
                value[index].contactNames.push(value[index].contactName);
              }
            }
          });
          console.log(uniqObj);
          console.log(value);

        for (let prop in groupedElements) {
          if (groupedElements.hasOwnProperty(prop)) {
            events.push({
              key: prop,
              list: groupedElements[prop]
            });
          }
        }
      }
    }
    return events;
  }

  public formatHeader(value): string {
    // return value;
    return this.datePipe.transform(value, 'MMMM y', undefined, this.translate.currentLang)
  }

  parseDate(activityDate) {
    return this.getday(activityDate.toString());
  }

  getFormattedTime = (longTime: string): string => {
    let timeToFormat: Date;
    if (isValid(new Date(longTime))) {
      timeToFormat = new Date(longTime);
    } else {
      timeToFormat = new Date(parseFloat(longTime));
    }
    let scheduleDay = isToday(timeToFormat) ? 'Today' : isTomorrow(timeToFormat) ? 'Tomorrow'
      : isYesterday(timeToFormat) ? 'Yesterday' : format(timeToFormat, 'dddd MMM DD');
    let startDate: Date = new Date(timeToFormat);
    let scheduleTime = startDate.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    return scheduleDay + ' ' + scheduleTime;
  }

  scrapInquiry() {
    if (this.isScrapEnabled) {
      this.events.publish('case:footerEvents', 'scrapcase');
    }
  }
  public getState(caseState):string{

    switch (caseState) {
        case 'Open':
          caseState = this.translate.instant('OPEN');
            break;
        case 'Approved':
          caseState = this.translate.instant('APPROVE');
            break;
        case 'For Review':
            caseState = this.translate.instant('FOR_REVIEW');
            break;
        case 'In Progress':
            caseState = this.translate.instant('IN_PROGRESS');
            break;
        case 'Intake':
              caseState = this.translate.instant('INTAKE');
              break;
        default:
            caseState = caseState;
            break;
        }
        return caseState;
}

  public getStatusFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
    label: this.translate.instant('STATUS'),
      inputText: (this.selectedCase && this.selectedCase._case_status_value) ? this.getState(this.selectedCase._case_status_value) : '',
      id: 'status-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getStageFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('STAGE'),
      inputText: (this.selectedCase && this.selectedCase._case_stage_value) ? this.getState(this.selectedCase._case_stage_value) : '',
      id: 'stage-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getCaseNumberFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CASE_INQUIRY_NUMBER'),
      inputText: (this.selectedCase && this.selectedCase._case_number) ? this.selectedCase._case_number : '--',
      id: 'stage-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getCreatedDateTimeFormField(type: string): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    let formattedIntakeCreated = '';
    if (this.selectedCase._case_status_value === 'Open' && this.selectedCase && this.selectedCase.scheduledStart) {
      if (type == 'date') {
        formattedIntakeCreated = this.datePipe.transform(this.selectedCase.scheduledStart, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
      } else if (type == 'time') {
        formattedIntakeCreated = this.selectedCase.scheduledStart.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
      }
    } else {
      if (this.selectedCase && !isNaN(Number(this.selectedCase._indskr_intakecreated))) {
        this.selectedCase._indskr_intakecreated = new Date(Number(this.selectedCase._indskr_intakecreated));
        if (type == 'date') {
          formattedIntakeCreated = this.datePipe.transform(this.selectedCase._indskr_intakecreated, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
        } else if (type == 'time') {
          formattedIntakeCreated = this.selectedCase._indskr_intakecreated.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
        }
      }
    }
    viewData = {
      label: type == 'date' ? this.translate.instant('CASE_INTAKE_CREATED_DATE') : this.translate.instant('CASE_INTAKE_CREATED_TIME'),
      inputText: formattedIntakeCreated ? formattedIntakeCreated : '--',
      id: type == 'date' ? 'created-date-field' : 'created-time-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getCompletedDateTimeFormField(type: string): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    let formattedIntakeCompleted = '';
    if (this.selectedCase && this.selectedCase._case_overriddencreatedon) {
      if (type == 'date') {
        formattedIntakeCompleted = this.datePipe.transform(this.selectedCase._case_overriddencreatedon, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
      } else if (type == 'time') {
        formattedIntakeCompleted = this.selectedCase._case_overriddencreatedon.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
      }
    }
    viewData = {
      label: type == 'date' ? this.translate.instant('CASE_INTAKE_COMPLETED_DATE') : this.translate.instant('CASE_INTAKE_COMPLETED_TIME'),
      inputText: formattedIntakeCompleted ? formattedIntakeCompleted : '--',
      id: type == 'date' ? 'completed-date-field' : 'completed-time-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getCategoryFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('INQUIRY_TYPE'),
      inputText: (this.selectedCase && this.selectedCase._case_category) ? this.categoryName : this.selectedCase._case_category_id ? this.selectedCase._case_trans_category_type : "",
      //placeholderLabel: (this.selectedCase && !this.selectedCase._case_category) ? (this.categoryName == 'Select Inquiry Type'? this.translate.instant('SELECT_INQUIRY_TYPE'):this.categoryName) : '',
      placeholderLabel: this.categoryFieldPlaceholder,
      skipPlaceholderTranslation: true,
      id: 'category-field',
      isReadOnly: true,
      isDisabled: (this.selectedCase._case_status_value != 'Open' && this.isCaseDisabled) ||  _.isEmpty(this.caseManagementService.getCurrentCaseCategoryTypes()),
      showArrow: !(this.selectedCase._case_status_value != 'Open' && this.isCaseDisabled),
      formFieldType: this.selectedCase._case_status_value != 'Open' ? FormFieldType.DATA_SUBMITTED :
                      this.caseManagementService.getCurrentCaseCategoryTypes()  && this.caseManagementService.getCurrentCaseCategoryTypes().length === 0 ?
                      FormFieldType.OPTIONS_NOT_AVAILABLE : FormFieldType.POPOVER_SELECT,
      isRequired : !(this.selectedCase._case_status_value != 'Open' && this.isCaseDisabled),
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedCategory && this.selectedCase && _.isEmpty(this.selectedCase._case_category) && _.isEmpty(this.selectedCase._case_category_id) && (_.isEmpty(this.categoryName) || this.categoryName == this.translate.instant('INQUIRY_TYPE')),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  get categoryFieldPlaceholder() {
    if(this.selectedCase) {
      if((this.selectedCase._case_status_value != 'Open' && !this.selectedCase._case_category) || _.isEmpty(this.caseManagementService.getCurrentCaseCategoryTypes()) ) {
        return this.translate.instant('NO_INQUIRY_TYPE');
      }
      return this.translate.instant('SELECT_INQUIRY_TYPE');
    }
    return '';
  }

  public getProductFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PRODUCT'),
      // deactivated flow need to be taken care and bit of code cleanup
      inputText: (this.selectedCase && (this.selectedCase._case_product || (this.selectedCase._case_status_value !== 'Open' && this.selectedCase._case_trans_product_name))) ? this.selectedCase._case_trans_product_name : '',
      customPlaceholderLabel: (this.selectedCase && !this.selectedCase._case_product) ? this.translate.instant('SELECT_PRODUCT') : '',
      id: 'product-field',
      isReadOnly: true,
      isDisabled: (this.selectedCase._case_status_value !== 'Open' && this.isCaseDisabled),
      showArrow: !(this.selectedCase._case_status_value !== 'Open' && this.isCaseDisabled),
      formFieldType: this.selectedCase._case_status_value !== 'Open' ? FormFieldType.DATA_SUBMITTED :
                      (this.caseManagementService.caseProductSKU.length === 0 ? FormFieldType.OPTIONS_NOT_AVAILABLE : FormFieldType.NEW_PAGE_SELECT),
      isRequired : !(this.selectedCase._case_status_value != 'Open' && this.isCaseDisabled),
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedProduct && this.selectedCase && _.isEmpty(this.selectedCase._case_product) && _.isEmpty(this.selectedCase._case_product_id) && (_.isEmpty(this.selectedCase._case_trans_product_name) || this.selectedCase._case_trans_product_name == this.translate.instant('PRODUCT')),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getTherapeuticAreaFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('THERAPEUTIC_AREA'),
      inputText: (this.selectedCase && this.selectedCase._case_therapeutic_area) ? this.selectedCase._case_trans_therapeutic_name : '',

      placeholderLabel: (this.selectedCase && !this.selectedCase._case_therapeutic_area) ?
      ((this.selectedCase._case_status_value != 'Open' && this.isCaseDisabled) ?
      this.translate.instant('CASE_NO_THERAPEUTIC_AREA') :this.translate.instant('CASE_SELECT_THERAPEUTIC_AREA')): '',

      skipPlaceholderTranslation: true,
      id: 'therapeutic-area-field',
      isReadOnly: true,
      isHidden: (!this.selectedCase._case_type || this.selectedCase._case_type.name.toLowerCase() != 'medical'),
      isDisabled: (this.selectedCase._case_status_value != 'Open' && this.isCaseDisabled),
      showArrow: !(this.selectedCase._case_status_value != 'Open' && this.isCaseDisabled),
      formFieldType: this.selectedCase._case_status_value != 'Open' ? FormFieldType.DATA_SUBMITTED :
                      (this.selectedCase._case_product && this.selectedCase._case_product.therapeuticAreas &&
                       this.selectedCase._case_product.therapeuticAreas.length === 0 ? FormFieldType.OPTIONS_NOT_AVAILABLE : FormFieldType.POPOVER_SELECT),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }


  public getContactFormField(): IndFormFieldViewDataModel {
    let customerName =this.selectedCase._case_contact?  this.selectedCase._case_trans_customer_name :  '';
    if (this.selectedCase._case_contact && customerName && !this.selectedCase._case_contact.isActive) {
      customerName = this.selectedCase._case_trans_customer_name.concat(' ').concat('(').concat(this.translate.instant('INACTIVE')).concat(')');
    }
    let isDisabled =  (this.caseManagementService.accessedFrom === AccesingMode.ACCOUNT_TIMELINE
      || this.caseManagementService.accessedFrom === AccesingMode.CONTACT_TIMELINE
      || this.caseManagementService.accessedFrom === AccesingMode.XPERIENCE_INSIGHTS
      || (this.selectedCase && !this.selectedCase._case_contact && this.activityService.teamViewActive));
    let customPlaceHolder = (this.selectedCase && !this.selectedCase._case_contact)
      ? (isDisabled || this.selectedCase._case_status_value !== 'Open') ? this.translate.instant('NO_CUSTOMER') : this.translate.instant('SELECT_WITH_GLOBALCUSTOMER', { globalCustomerText: this.globalCustomerText })
      : '';
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.globalCustomerText,
      inputText: (this.selectedCase && (this.selectedCase._case_contact || (this.selectedCase._case_status_value != 'Open' && customerName))) ? customerName : '',
      customPlaceholderLabel: customPlaceHolder,
      id: 'contact-field',
      isReadOnly: true,
      isDisabled: isDisabled,
      isHidden: this.isHideCustomerField,
      showArrow: !(this.caseManagementService.accessedFrom === AccesingMode.ACCOUNT_TIMELINE
        || this.caseManagementService.accessedFrom === AccesingMode.CONTACT_TIMELINE
        || this.caseManagementService.accessedFrom === AccesingMode.XPERIENCE_INSIGHTS
        || (this.selectedCase && !this.selectedCase._case_contact && this.activityService.teamViewActive)),
      formFieldType: this.selectedCase._case_status_value != 'Open' ? FormFieldType.DATA_SUBMITTED : FormFieldType.NEW_PAGE_SELECT,
      isRequired: !(this.caseManagementService.accessedFrom === AccesingMode.ACCOUNT_TIMELINE
        || this.caseManagementService.accessedFrom === AccesingMode.CONTACT_TIMELINE
        || this.caseManagementService.accessedFrom === AccesingMode.XPERIENCE_INSIGHTS
        || (this.selectedCase && !this.selectedCase._case_contact && this.activityService.teamViewActive))
        && !(this.selectedCase._case_status_value != 'Open' && this.isCaseDisabled),
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedContact && this.selectedCase && _.isEmpty(this.selectedCase._case_contact) && _.isEmpty(this.selectedCase._case_contact_id),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  private get isFormDisabled() {
    return this.caseManagementService.accessedFrom === AccesingMode.ACCOUNT_TIMELINE
      || this.caseManagementService.accessedFrom === AccesingMode.CONTACT_TIMELINE
      || this.caseManagementService.accessedFrom === AccesingMode.XPERIENCE_INSIGHTS
      || (this.selectedCase && !this.selectedCase._case_account && this.activityService.teamViewActive);
  }

  public getAccountFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    const accountPlaceHolder = this.selectedCase._case_trans_account_name;
    let inputText = '';
    if (this.selectedCase && this.selectedCase._case_account && (this.selectedCase._case_account.status === 2 || this.selectedCase._case_account.status == 548910003 || this.selectedCase._case_account.status == 548910010 || this.selectedCase._case_account.status == 548910011 || this.selectedCase._case_account.status == 548910012 || this.selectedCase._case_account.status == 548910013)) {
      inputText = this.selectedCase._case_account.accountName.concat(' ').concat('(').concat(this.translate.instant('INACTIVE')).concat(')');
    } else if (this.selectedCase && this.selectedCase._case_account) {
      inputText = this.selectedCase._case_trans_account_name;
    }

    let isDisabled = (this.caseManagementService.accessedFrom === AccesingMode.ACCOUNT_TIMELINE
      || this.caseManagementService.accessedFrom === AccesingMode.CONTACT_TIMELINE
      || this.caseManagementService.accessedFrom === AccesingMode.XPERIENCE_INSIGHTS
      || (this.selectedCase && !this.selectedCase._case_account && this.activityService.teamViewActive));

    viewData = {
      label: this.translate.instant('ACCOUNT'),
      // show  _case_trans_account_name as inputText if it is other than 'Account' as for some records the account name is present in _case_trans_account_name
      // even though the account is not present
      inputText: inputText,
      customPlaceholderLabel: (isDisabled || this.selectedCase._case_status_value !== 'Open') ? this.translate.instant('NO_ACCOUNT') : this.translate.instant('SELECT_ACCOUNT'),
      id: 'account-field',
      isRequired: this.isAccountFieldMandatory,
      isReadOnly: true,
      isDisabled: isDisabled,
      showArrow: !isDisabled,
      formFieldType: this.selectedCase._case_status_value !== 'Open' ? FormFieldType.DATA_SUBMITTED : FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getKitBookingFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    let linkedKitBooking;
    if (this.selectedCase && this.selectedCase.indskr_kitbooking) {
      linkedKitBooking = this.activityService.getActivityByID(this.selectedCase.indskr_kitbooking) as SetBookingActivity;
    }
    viewData = {
        label: this.translate.instant('KIT_BOOKING'),
        inputText: linkedKitBooking ? linkedKitBooking['subject'] : '',
        id: 'kit-booking-field',
        customPlaceholderLabel: this.from !== PageName.SetBookingActivityDetailsComponent ? this.translate.instant('SELECT_KIT_BOOKING') :this.translate.instant('NO_KIT_BOOKING'),
        isReadOnly: true,
        // isDisabled: isViewReadOnly || (this.activityService.selectedActivity.ownerId != this.authService.user.systemUserID && (this.isCovisitor && this.coVisitorAccess !== CovisitorAccess.FULL_ACCESS)),
        isDisabled: this.from === PageName.SetBookingActivityDetailsComponent,
        showArrow: this.from !== PageName.SetBookingActivityDetailsComponent && this.caseManagementService.accessedFrom !== AccesingMode.IN_SET_BOOKING,
        formFieldType: FormFieldType.NEW_PAGE_SELECT,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName)
      };
    return viewData;
  }

  public getResponsePreferenceFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CASE_RESPONSE_PREFERENCE'),
      inputText: (this.selectedCase && this.selectedCase._case_response_preference) ? this.selectedCase._case_response_preference.name : '',
      placeholderLabel: this.translate.instant('SELECT_RESPONSE_PREFERENCE'),
      skipPlaceholderTranslation: true,
      id: 'response-preference-field',
      isReadOnly: true,
      isDisabled: (this.selectedCase._case_status_value !== 'Open' && this.isCaseDisabled),
      showArrow: !(this.selectedCase._case_status_value !== 'Open' && this.isCaseDisabled),
      formFieldType: this.selectedCase._case_status_value !== 'Open' ? FormFieldType.DATA_SUBMITTED : FormFieldType.POPOVER_SELECT,
      isRequired: !(this.selectedCase._case_status_value !== 'Open' && this.isCaseDisabled),
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedResponsePreference && this.selectedCase && _.isEmpty(this.selectedCase._case_response_preference) && _.isEmpty(this.selectedCase._case_response_preference_id),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getResponsePreferenceTypeFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: !(this.selectedCase && this.selectedCase._case_response_preference) || String(this.selectedCase?._case_response_preference?.name).toLowerCase().includes('required'),
      label: this.translate.instant(this.utilityService.globalCustomerText, { globalCustomerText: this.utilityService.globalCustomerText }) + ' ' + this.responseType,
      inputText: (this.contactDetails != this.responseType) ? this.contactDetails : '',
      placeholderLabel: this.selectedCase._case_status_value !== 'Open' ?  this.responseType : (this.contactDetails == this.responseType ? (this.translate.instant('SELECT') + ' ' + this.responseType) : ''),
      id: 'response-preference-type-field',
      isReadOnly: true,
      isDisabled: (this.selectedCase._case_status_value !== 'Open' && this.isCaseDisabled),
      showArrow: !(this.selectedCase._case_status_value !== 'Open' && this.isCaseDisabled),
      formFieldType: this.selectedCase._case_status_value !== 'Open' ? FormFieldType.DATA_SUBMITTED :
                      !this.isResponsePreferenceDataAvailable() ? FormFieldType.OPTIONS_NOT_AVAILABLE : FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getAssignedToFormField(): IndFormFieldViewDataModel {
    const { users, teams } = this.getUsersAndTeams();
    const hasUsersOrTeams: boolean = users.length > 0 || teams.length > 0;
    const viewData: IndFormFieldViewDataModel = {
      isHidden: this.selectedCase && this.selectedCase._case_status_value !== 'Open' && (this.selectedCase._case_assignee == null || this.selectedCase._case_assignee == ""),
      label: this.translate.instant('ASSIGNED_TO'),
      inputText: (this.selectedCase && this.selectedCase._case_assignee != 'No Expert') ? this.selectedCase._case_assignee : '',
      id: 'assigned-to-field',
      customPlaceholderLabel: this.selectedCase._case_assignee ? this.selectedCase._case_assignee : this.translate.instant('SELECT_USERS_OR_TEAMS'),
      isReadOnly: true,
      isDisabled: this.isFormDisabled || !hasUsersOrTeams,
      showArrow: !this.isFormDisabled && hasUsersOrTeams,
      formFieldType: this.selectedCase._case_status_value !== 'Open' ? FormFieldType.DATA_SUBMITTED : FormFieldType.NEW_PAGE_SELECT,
      isRequired: !(this.selectedCase._case_status_value != 'Open' && this.isCaseDisabled),
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedAssignee && this.selectedCase && (this.selectedCase._case_owner_id == null || this.selectedCase._case_owner_id == ''),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName)
    };
    return viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      switch (id) {
        case 'category-field':
          this.selectCategory(event);
          break;
        case 'product-field':
          this.productSelection(event);
          break;
        case 'therapeutic-area-field':
          this.therapeuticSelection(event);
          break;
        case 'contact-field':
          this.contactSelection(event);
          break;
        case 'account-field':
          this.accountSelection(event);
          break;
        case 'response-preference-field':
          this.selectResponsePref(event);
          break;
        case 'response-preference-type-field':
          this.selectContactDetails(event);
          break;
        case 'assigned-to-field':
          this.selectAssignee();
          break;
        case 'kit-booking-field':
          this.openKitBooking();
        case '':

          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
  }

  private initFooter() {
    if (this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
      this.footerService.initButtons(FooterViews.PREVIEW_MEETING_ACTIVITY);
    } else {
      this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
    }
    this.titleControls.forEach(btn => {
      if (btn.id == 'submit') {
        btn.isDisabled = !(this.selectedCase._case_status_value === 'Open' && this.validateCaseForReview);
      } else if (btn.id == 'scrap') {
        btn.isDisabled = !this.isScrapEnabled;
      }
    });
  }

  isResponsePreferenceDataAvailable() {
    if (this.selectedCase && this.selectedCase._case_response_preference && this.selectedCase._case_response_preference.name) {
      if (String(this.selectedCase._case_response_preference.name).toLowerCase().includes('email')) {
        if (this.selectedCase._case_contact && this.selectedCase._case_contact.emailAddressList && this.selectedCase._case_contact.emailAddressList.length > 0)
          return true;
        }
      if (String(this.selectedCase._case_response_preference.name).toLowerCase().includes('phone')) {
        if (this.selectedCase._case_contact && this.selectedCase._case_contact.mobilePhone) {
            return true;
        }
        if (this.selectedCase._case_contact && this.selectedCase._case_contact.telephone) {
            return true;
        }
      }
      if (String(this.selectedCase._case_response_preference.name).toLowerCase().includes('visit')) {
        if (this.selectedCase._case_contact && this.selectedCase._case_contact.addressesList && this.selectedCase._case_contact.addressesList.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  formattedDate(val) {
    return format(val, this.dateTimeFormatsService.timeToUpper) || '';
  }
  get enterEnquiryPlaceholder() {
    return this.translate.instant('ENTER_INQUIRY')
  }

  private loadSurveyTemplate() {
    let form = this.surveyService.surveyForms.find(form => form.surveyFormId == this.selectedCase._case_type.surveyTemplate);
    if(form) {
      this.surveyTemplate = form.surveyConfiguration;
    }
    this.events.publish('loadSurveyTemplate', this.surveyTemplate);
    if(this.surveyTemplate) this.validateSurveyForReview(this.surveyService.surveyStaticResults);
    this.isCaseDisabled = (!(this.selectedCase._case_status_value === 'Open' && this.validateCaseForReview));
  }

  public async updateSurveyResult(surveyResponse) {
    this.selectedCase._case_inquiry_response = surveyResponse;
    await this.updateDataOnPouch();
  }

  public async updateSurveyData(surveyData){
    this.surveyService.surveyStaticResults = this.selectedCase._case_inquiry_response_surveyData = surveyData;
    this.validateSurveyForReview(surveyData);
    this.isCaseDisabled = (!(this.selectedCase._case_status_value === 'Open' && this.validateCaseForReview));
    this.addControllsTitle();
    this.initFooter();
    this.caseManagementDetailsHeaderModel();
    this._cd.detectChanges()
    await this.updateDataOnPouch();
  }

  private loadSurveyResults(selectedCase) {
    if(selectedCase._case_status_value === 'Open') {
      if(selectedCase._case_inquiry_response_surveyData) {
        this.surveyService.surveyStaticResults = selectedCase._case_inquiry_response_surveyData;
      } else {
        this.surveyService.surveyStaticResults = '';
      }
      if(selectedCase._case_inquiry_response_surveyLookupData && selectedCase._case_inquiry_response_surveyLookupData.length>0){
        this.surveyService.surveyLookupResults = this.surveyLookupResults = selectedCase._case_inquiry_response_surveyLookupData;
      } else {
        this.surveyService.surveyLookupResults = this.surveyLookupResults = [];
      }
    } else if(selectedCase._case_status_value !== 'Open' && selectedCase._case_inquiry_response) {
      this.caseInquirySurveyResponseDataModel = [];
      this.surveyService.surveyStaticResults = selectedCase._case_inquiry_response;
      this.surveyService.surveyStaticResults.forEach(result => {
        let viewData: IndFormFieldViewDataModel;
        viewData = {
          label: result.indskr_question,
          inputText: result.indskr_answer,
          id: 'survey-result-field',
          isReadOnly: true
        }
        this.caseInquirySurveyResponseDataModel.push(viewData);
      });
    }
  }

  private mapSurveyLookupResults(response) {
    if(this.selectedCase._case_inquiry_response_surveyLookupData) {
      let index = this.selectedCase._case_inquiry_response_surveyLookupData.findIndex(result => result.targetEntity == response['targetEntity'])
      if(index>-1) {
        this.selectedCase._case_inquiry_response_surveyLookupData[index] = response;
      } else {
        this.selectedCase._case_inquiry_response_surveyLookupData.push(response);
      }
    } else {
      this.selectedCase._case_inquiry_response_surveyLookupData.push(response);
    }
  }

  private validateSurveyForReview(surveyData) {
    this.caseManagementService.isSurveyValid = true;
    this.surveyTemplate.pages[0].elements.forEach((question) => {
      if (question.isRequired) {
        this.caseManagementService.isSurveyValid = this.caseManagementService.isSurveyValid && surveyData.hasOwnProperty(question.name) ? true : false;
        if(question.type == 'panel' && question.elements && question.elements.length > 0 ){
          this.validateSurveyElementsForReview(question.elements,surveyData);
        }
      }
    })
  }

  private validateSurveyElementsForReview(elements,surveyData){
    if(elements && elements.length > 0){
      for(let i=0;i<elements.length;i++){
        this.caseManagementService.isSurveyValid = this.caseManagementService.isSurveyValid && surveyData.hasOwnProperty(elements[i].element.name) ? true : false;
        if(elements[i].type == 'panel' && elements[i].elements && elements[i].elements.length > 0){
          this.validateSurveyElementsForReview(elements[i].elements,surveyData)
        }
      }
    }
  }
}
