<ion-grid [ngClass]="(!!navService.isActiveChildNavRightPaneViewDisplayed)?'preview-active':'preview-inactive'">
  <ion-row>
    <ion-col class="marketing-mgmt-left-pane">
      <marketing-plan-list [marketingListMode]="marketingListMode" (selectedMarketingPlan)="openMarketingPlanDetail($event)">
      </marketing-plan-list>
    </ion-col>
    <ion-col class="marketing-mgmt-right-pane">
      <ion-nav #marketingplanrightpane></ion-nav>
    </ion-col>
  </ion-row>
</ion-grid>