import { Component, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { MarketingPlanDetails } from '@omni/components/marketing-business/marketing-plan-details/marketing-plan-details';
import { Events } from '@omni/events';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { DeviceService } from '@omni/services/device/device.service';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { MarketingPlansManagementOfflineService } from '@omni/services/marketing-management/marketing-management.service';
import { ChildNavNames, NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';



@Component({
  selector: 'marketing-plan-page',
  templateUrl: 'marketing-plan-page.html',
  styleUrls: ['marketing-plan-page.scss']
})

export class MarketingPlanManagementPageComponent {

  public marketingListMode: ComponentViewMode;
  @ViewChild('marketingplanrightpane', { static: true }) public navCtrl: IonNav;

  constructor(
    public navService: NavigationService,
    public accountService: AccountOfflineService,
    public uiService: UIService,
    public marketingPlansManagementOfflineService: MarketingPlansManagementOfflineService,
    public device: DeviceService,
    private footerService: FooterService,
    private events: Events
  ) {
    this.marketingListMode = ComponentViewMode.LISTVIEW;
  }

  ngOnInit() {
    this.accountService.accessedAccountListFrom = PageName.MarketingPlanManagementPageComponent;
    this.navService.initRightPaneChildNav(this.navCtrl, ChildNavNames.MarketingPlanNavigation, PageName.MarketingPlanManagementPageComponent, false, { deviceString: this.device.deviceFlags.ios ? 'ios' : 'other' });
    this.footerService.initButtons('');
    this.marketingPlansManagementOfflineService.getMarketingBusinessPlanTypeDataOffline();
  }

  ionViewDidEnter() {
    if (this.uiService.isFlipButtonClicked) {
      this.uiService.isFlipButtonClicked = false;
    }
  }

  public isSplitView(): boolean {
    return (window.innerWidth > 500);
  }

  ngOnDestroy() {
    this.marketingPlansManagementOfflineService.marketingBusinessPlanTypes = [];
    this.navService.setChildNavRightPaneView(false);
    this.navService.popChildNavCtrlFromStack(ChildNavNames.MarketingPlanNavigation).then(() => {
      this.events.publish("tools-back-clicked", PageName.MarketingPlanManagementPageComponent);
    });
  }

  public openMarketingPlanDetail(event): void {
    if (event?.plan) {
      this.navService.setChildNavRightPaneView(true);
      this.navService.setChildNavRoot(MarketingPlanDetails, PageName.MarketingPlanDetails, PageName.MarketingPlanManagementPageComponent, { data: event.plan, selectedMode: event.selectedMode });
      // this.footerService.initButtons(FooterViews.AccountPlanObjectives);
    }
  }

}