import { Component, Input } from '@angular/core';

@Component({
  selector: 'progress-with-text',
  templateUrl: 'progress-with-text.html',
  styleUrls: ['progress-with-text.scss']
})
export class ProgressWithTextComponent {
  @Input() inputData : any;
}
