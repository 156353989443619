import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ActivityType } from '@omni/classes/activity/activity.class';
import { DeviceService } from '@omni/services/device/device.service';
import _ from 'lodash';

export type PopoListData = {
  id: string;
  title: string;
  detailObjectType?:string;
  detailObjectId?:string;
  thumbnailURL?: string;
  secondLineText?: string;
  thirdLineText?: string;
  activityType?: ActivityType;
  offlineMessage? : string;
  previewHtml?: string;
}

@Component({
  selector: 'app-list-preview',
  templateUrl: './list-preview.component.html',
  styleUrls: ['./list-preview.component.scss'],
})
export class ListPreviewComponent implements OnInit {
  @Input() popupListData : PopoListData[];
  public showOfflineMessage: boolean = false;
  public offlineMessage: string = '';

  constructor(
    public popoverController : PopoverController,
    private deviceService: DeviceService,
  ) { }

  ngOnInit() {
    console.log(this.popupListData);
    if (!_.isEmpty(this.popupListData)) {
      if (this.deviceService.isOffline) {
        this.showOfflineMessage = true;
        const foundEmailActivity = this.popupListData.find(d=>d.activityType && d.activityType == ActivityType.Email);
        if (foundEmailActivity && foundEmailActivity.offlineMessage) {
          this.offlineMessage = foundEmailActivity.offlineMessage;
        }
      } else {
        this.showOfflineMessage = false;
      } 
    }
  }

  openContent(item: PopoListData){
    let data = { contentType: item.detailObjectType, contentId: item.detailObjectId };
    this.popoverController.dismiss(data);
  }

}
