import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AccountPlan, AccountPlanMode } from '@omni/classes/account-management/account-plan.class';
import { Account } from '@omni/classes/account/account.class';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { ScientificActivityPlan } from '@omni/classes/scientific-activity/scientific-activity.class';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { AccountManagementDataService } from '@omni/data-services/account-management/account-management.data.service';
import { CallPlanDataService } from '@omni/data-services/call-plan/call-plan.data.service';
import { ScientificActivityDataService } from '@omni/data-services/scientific-activity/scientific-activity.data.service';
import { TherapeuticAreaDataService } from '@omni/data-services/therapeutic-area/therapeutic-area.data.service';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { SelectedSuggestionPillDataModel, SuggestionPillType } from '@omni/models/search-config-data-model';
import { OrderByPipe } from '@omni/pipes/orderby.pipe';
import { ScientificActivityPlanFilter } from '@omni/pipes/scientific-activity-plan.pipe';
import { AccountManagementOfflineService } from '@omni/services/account-management/account-management.service';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { ActivityService } from '@omni/services/activity/activity.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { BrandOfflineService } from '@omni/services/brand/brand.service';
import { CallPlanOfflineService } from '@omni/services/call-plan/call-plan.offline.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { DeviceService } from '@omni/services/device/device.service';
import { DiskService } from '@omni/services/disk/disk.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { TrackingEventNames, TrackService } from '@omni/services/logging/tracking.service';
import { MyAssistantService } from '@omni/services/my-assistant/my-assistant.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { ScientificActivityService } from '@omni/services/scientific-activity/scientific-activity.service';
import { SearchConfigService } from '@omni/services/search/search-config.service';
import { UIService } from '@omni/services/ui/ui.service';
import { Utility } from '@omni/utility/util';
import { isFuture, isPast } from 'date-fns';
import { data, param } from 'jquery';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountPlanDetails } from '../account-management/account-plan-details/account-plan-details';
import { MultiSelectPopover } from '../multi-select-popover/multi-select-popover';
import { NothingSelectedView } from '../shared/nothing-selected-view/nothing-selected-view';
import { CallPlanCustomerType } from '@omni/classes/call-plan/rep-call-plan.class';


@Component({
  selector: 'goals-plans',
  templateUrl: './goals-plans.component.html',
  styleUrls: ['./goals-plans.component.scss'],
  providers: [ScientificActivityPlanFilter]
})
export class GoalsPlansComponent implements OnInit {
  selectedPlanId: string;
  tabs: IndTabsDataModel[] = [];
  public currentHeaderModel: IndSectionHeaderViewDataModel;
  plansData: any;
  callPlansData: { past: any[], present: any[], future: any[] } = { past: [], present: [], future: [] };
  public accountHeaderModel: IndSectionHeaderViewDataModel;
  public callHeaderModel: IndSectionHeaderViewDataModel;
  public scientificHeaderModel: IndSectionHeaderViewDataModel;
  @Output() selectAccount = new EventEmitter<any>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Output() onCallPlanSelectOnPlanTab: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectSAP: EventEmitter<ScientificActivityPlan> = new EventEmitter<ScientificActivityPlan>();
  rawPlanData: any[];
  public scientificPlansData = [];
  public scientificActivities: ScientificActivityPlan[] = [];
  private originalList: ScientificActivityPlan[] = [];
  public currentSAP: ScientificActivityPlan[] = [];
  private currentUserID: string;
  public isAccountPlansExpanded = true;
  public isCallPlansExpanded = true;
  public isScientificPlansExpanded = true;
  private allAccountsForAccountPlans: Account[];
  preUpdatedSubAccountNumber: number;
  public isSearched: boolean = false;
  private searchedDataAfterFilter: any;
  callPlanSearchText: string;
  suggestionsActive: boolean = false;
  searchKey: string = '';
  shouldFireSearchChangeEvent: boolean = true;
  public searching: boolean = false;
  disableSaveSearch: boolean = false;
  selectedSuggestionsData: SelectedSuggestionPillDataModel[] = [];
  filteredList: { present: any[] } = { present: [] };
  suggestionsData: {
    header: IndSectionHeaderViewDataModel,
    listData: MainCardViewDataModel[]
  }[] = [];
  public filteredCallPlans: any[]=[];
  public filteredAccountPlans: any[];
  public filteredScientificPlans = [];
  public isAccountPlansFA: boolean = false;
  public isCallPlansFA: boolean = false;
  public isScientificPlansFA: boolean = false;
  private goalsPlansFilterPopover;
  filterPopoverData: any[];
  public accountPlansFilter = { status: "", account: [] };
  public accountFilterList: any[] = []
  filterBadgeCount: number = 0
  filteredAccountPlansData: any;
  private isFiltered: boolean = false;
  indHeaderLeftModel: IndHeaderLeftDataModel;
  public productPopoverData: any[];
  public sortBy: { value: any, asc: any };
  public scientificActivityFilter = { status: "", products: [], therapeuticAreaList: [] };
  public callPlansFilter = { status: "", products: [], }
  public accountPlansProductFilter = { status: "", products: [], }
  public productFilterList: any[] = []
  public therapeuticAreaFilterList: any[] = [];
  filterValueSelected: boolean = false;
  AccountPlansData: any[];
  private searchedData: any;
  public filteredAccountList: any[]
  private displaygoalsbeyond100: boolean = false;


  constructor(
    public translate: TranslateService,
    public uiService: UIService,
    public accountManagementOfflineService: AccountManagementOfflineService,
    private readonly accountService: AccountOfflineService,
    public accountManagementDataService: AccountManagementDataService,
    private readonly navService: NavigationService,
    public trackingService: TrackService,
    public activityService: ActivityService,
    public footerService: FooterService,
    public callPlanOfflineService: CallPlanOfflineService,
    private callDataService: CallPlanDataService,
    public device: DeviceService,
    private authService: AuthenticationService,
    private loadingController: LoadingController,
    private sapService: ScientificActivityService,
    private sapDataService: ScientificActivityDataService,
    public searchConfigService: SearchConfigService,
    public disk: DiskService,
    private popover: PopoverController,
    private brandService: BrandOfflineService,
    private therapeuticservice: TherapeuticAreaDataService,
    private sapFilter: ScientificActivityPlanFilter,
    public assistantService: MyAssistantService,
    private contactService: ContactOfflineService,
  ) {
  }

  async ngOnInit() {
    this.initaccountPlans();
    this.initcallPlans();
    this.initscientificPlans();
    this.tabs = this.uiService.getAgendaTabs();
    this.translate.onLangChange.subscribe(data =>{
      this.tabs = this.uiService.getAgendaTabs();
    });
    this.filterAllGoalsPlans();
    this.initGoalsPlansHeaderLeft();
    this.intializeheaders();

    this.accountManagementOfflineService.accountPlans$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.initaccountPlans();
    });
    this.callPlanOfflineService.segmentCallPlansList$.pipe(takeUntil(this.destroy$)).subscribe((data)=>{
      if(data && data.hasUpdatedData){
        this.initcallPlans();
      }
    });

    
    this.accountManagementOfflineService.selectedAccountPlan$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data) this.openAccountPlan(data);
    })
  }


  initaccountPlans() {
    if (this.accountManagementOfflineService.accountPlans) {
      this.accountService.createAccountplanAccountsTree(this.accountManagementOfflineService.accountPlans);
      this.allAccountsForAccountPlans = this.accountService.accountsTreeForOtherTool;
      this.recursiveAccountList();
      let accountPlansData = [];
      this.allAccountsForAccountPlans.forEach(ac => {
        accountPlansData.push(...this.accountManagementOfflineService.accountPlans.filter(ap => ap.accountId == ac.id && !accountPlansData.includes(ap)));
      })
      this.rawPlanData = accountPlansData;
      this.updateAccountPlansList(accountPlansData);
      this.initializeAccountHeader();
      this.accountService.mapMyAccountPlansToSearchIndex(this.rawPlanData);
    }
  }

  initcallPlans() {
    this.disk.retrieve(DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS).then(res => {
      if (res && res.raw && res.raw.length) {
        this.callPlanOfflineService.segmentCallPlans = res.raw
      }
      else this.callPlanOfflineService.segmentCallPlans = [];
      this.getFormattedCallPlans();
      this.initializeCallHeader();
      this.callPlanOfflineService.mapMyGoalsPlansToSearchIndex(this.callPlanOfflineService.segmentCallPlans);
      if (this.searchConfigService.configUpdateRequired) {
        this.searchConfigService.updateSearchConfigsForSelectedLanguage();
        this.searchConfigService.configUpdateRequired = false;
      }
    });
    if(this.authService.user.buConfigs['indskr_displaygoalsbeyond100']) this.displaygoalsbeyond100 = true;
  }

  async initscientificPlans() {
    this.currentUserID = this.authService.user.systemUserID;
    const currentDay: Date = new Date();
    await this.uiService.displayLoader();
    this.sapDataService.getScientificActivities(false, true).then(() => {
      this.scientificActivities = [...this.sapService.sapActivities];
      this.scientificActivities.sort((x, y) => {
        if (x.createdon && y.createdon) {
          return (new Date(x.createdon).getTime()) - (new Date(y.createdon).getTime());
        }
      })
      this.originalList = [...this.scientificActivities];
      this.currentSAP = this.scientificActivities.filter((sap) => {
        return Number(sap.startDate) < currentDay.getTime() && Number(sap.endDate) > currentDay.getTime();
      });
      this.uiService.dismissLoader();
      this.updateScientificActivitiesList();
      this.initializeScientificHeader();
      this.sapDataService.mapMyScientificPlansToSearchIndex(this.scientificActivities);
    });
  }

  private goalOrCompletedValue(goal, completed) {
    let value = 0;
    if (completed) {
      if(!this.displaygoalsbeyond100) value = completed > goal ? goal : completed;
      else value = completed;
    }
    return value;
  }

  updateAccountPlansList(data: any) {
    this.plansData = this.getGroupedData(data.sort((a, b) => {
      a.accountPlanName > b.accountPlanName ? 1 : -1;
    }));
    this.AccountPlansData = this.plansData.present.filter((plan) => {
      return this.applyAccountPlansProductFilter(plan);
    });
    this.filteredAccountPlans = this.AccountPlansData;
    this.initializeAccountHeader();
  }

  getGroupedData(list) {
    const formattedAccountPlanObj = {
      past: [],
      present: [],
      future: []
    };
    if (list && list.length > 0) {
      list.map(o => {
        o.accountPlanTimeFrame = isPast(parseInt(o.endDate)) ? 'Past'
          : isFuture(parseInt(o.startDate)) ? 'Future' : 'Present';
        if (o.accountPlanTimeFrame === 'Past') {
          formattedAccountPlanObj.past.push(o);
          return;
        }
        if (o.accountPlanTimeFrame === 'Present') {
          formattedAccountPlanObj.present.push(o);
          return;
        }
        formattedAccountPlanObj.future.push(o);
      });
    }
    return formattedAccountPlanObj;
  }

  private recursiveAccountList() {
    if (this.allAccountsForAccountPlans) {
      let foundSubAccounts = [];
      let subAccountsNumber: number = 0;
      this.allAccountsForAccountPlans.forEach(ac => {
        if (ac.subAccounts && ac.subAccounts.length != 0) {
          ac.subAccounts.map(subAc => {
            foundSubAccounts.push(subAc);
          });
        }
        if (foundSubAccounts.length != 0) {
          foundSubAccounts = _.uniqBy(foundSubAccounts, 'id');
          subAccountsNumber = foundSubAccounts.length;
        }
      });
      if (this.preUpdatedSubAccountNumber != subAccountsNumber) {
        this.preUpdatedSubAccountNumber = subAccountsNumber;
        this.allAccountsForAccountPlans.push(...foundSubAccounts);
        this.allAccountsForAccountPlans = _.uniqBy(this.allAccountsForAccountPlans, 'id');
        this.recursiveAccountList();
      }
    }
    this.allAccountsForAccountPlans = _.uniqBy(this.allAccountsForAccountPlans, 'id');
    this.preUpdatedSubAccountNumber = 0;
  }

  onSelectedTabChange(selectedTab: string) {
    this.uiService.setAgendaTab(selectedTab);
    this.uiService.activitiesPageTab = selectedTab;
    this.callPlanOfflineService.selectedCallPlan = undefined;
  }

  public getAccountPlanmainCardModel(accountPlan: AccountPlan): MainCardViewDataModel {
    let totalCompletedPercent = 0;
    let numberOfaccountPlanObj = 0;
    let avgTotalCompletedPercent = 0;
    if (accountPlan.accountPlanObjectives.length != 0) {
      for (let i = 0; i < accountPlan.accountPlanObjectives.length; i++) {
        if (accountPlan.accountPlanObjectives[i].completedPercent > 0) {
          totalCompletedPercent = accountPlan.accountPlanObjectives[i].completedPercent + totalCompletedPercent;
          numberOfaccountPlanObj++;
        }
      }
      avgTotalCompletedPercent = (totalCompletedPercent / numberOfaccountPlanObj);
    }
    return {
      id: accountPlan.ID,
      fixedHeight: true,
      primaryTextLeft: '',
      primaryTextRight: accountPlan.accountPlanName,
      isPrimaryTextRightPillText: (avgTotalCompletedPercent ? avgTotalCompletedPercent.toFixed(0) : 0) + '%',
      isPrimaryTextRightPillType: true,
      secondaryTextRight: accountPlan.accountName,
      isSecondaryTextRightPillType: false,
      isSelected: this.selectedPlanId == accountPlan.ID,
      showArrow: false,
      showEndIcon: accountPlan.status == 'Completed',
      endIconCssClass: 'completed-icon',
      isSecondSrcAttribute:true,
      showSecondEndIcon : !this.device.isOffline,
      secondEndIconType: 'assets/imgs/horizontal-menu-filled.svg',
      secondEndIconCssClass : 'showOnHover',
      // arrowType: 'chevron-forward',
      clickHandler: async (id: string, event, target) => {
        this.callPlanOfflineService.selectedCallPlan = undefined;
        const clonePopover = await this.accountManagementOfflineService.showCloneDropDown(id, event);
        
        if (this.accountManagementOfflineService.selectedMode === AccountPlanMode.EDIT && this.accountManagementOfflineService.tempReqPayload !== undefined) {
          let response = await this.accountManagementOfflineService.showConfirmAlert();
          if (!response) return;
        }
        if (target === 'mainItem') {
          this.accountManagementOfflineService.selectedMode = AccountPlanMode.VIEW;
          this.accountManagementOfflineService.selectedAccountPlan$.next(accountPlan);
        } else if (target === 'secondEndIcon') {
          this.openCloneDropDown(id, clonePopover)
        }
        // this.openAccountPlan(accountPlan)
      },
    };
  }

  async openCloneDropDown(id, clonePopover) {
    // const clonePopover = await this.accountManagementOfflineService.showCloneDropDown(id, event);

    clonePopover.onDidDismiss().then(async ({ data }: any) => {
      if (data && data.isDone) this.cloneAccountPlan(id);
    });

    clonePopover.present();
  }

  async cloneAccountPlan(id){
    let reqPayload = await this.accountManagementOfflineService.cloneAccountPlan(id);
    this.uiService.displayLoader();
    this.accountManagementDataService.saveAccountPlan(reqPayload).then(async (accountPlan) => {
      await this.accountManagementDataService.getAccountPlans(false, accountPlan.ID);
      accountPlan = _.cloneDeep(this.accountManagementOfflineService.accountPlans.find(o => o.ID === accountPlan.ID));
      this.accountManagementOfflineService.selectedMode = AccountPlanMode.EDIT;
      this.accountManagementOfflineService.selectedAccountPlan$.next(accountPlan);
      // this.events.publish('account-plan-cloned');
      this.uiService.dismissLoader();
    });
  }

  public getCallPlanmainCardModel(callPlan): MainCardViewDataModel {
    return {
      id: callPlan.customerType == CallPlanCustomerType.CONTACT ? callPlan.contactId : callPlan.accountId,
      customerType: callPlan.customerType,
      fixedHeight: true,
      initials: callPlan.initials,
      primaryTextRight: callPlan.customerName,
      percentage:  callPlan.percentage + '%',
      isPrimaryTextRightPillType: this.displaygoalsbeyond100 ? false : true,
      isSecondaryTextRightPillType: false,
      isSelected: callPlan.customerType == CallPlanCustomerType.CONTACT ? this.selectedPlanId == callPlan.contactId : this.selectedPlanId == callPlan.accountId,
      showArrow: false,
      mainItemCssClass:'goals-plan',
      clickHandler: (id: string, event) => {
        this.handleCallPlanSelection(callPlan)
      },
    };
  }

  public getScientificPlanmainCardModel(saPlan): MainCardViewDataModel {
    return {
      id: saPlan.ID,
      fixedHeight: true,
      primaryTextLeft: '',
      primaryTextRight: saPlan.scientificActivityPlanName,
      isPrimaryTextRightPillType: true,
      isSecondaryTextRightPillType: false,
      isSelected: this.selectedPlanId == saPlan.ID,
      showArrow: false,
      showEndIcon: saPlan.status == 'Completed',
      endIconCssClass: 'completed-icon',
      clickHandler: (id: string, event) => {
        this.callPlanOfflineService.selectedCallPlan = undefined;
        this.scientificPlansDataSelected(saPlan)
      },
    };
  }

  public async openAccountPlan(plan: AccountPlan) {
    this.selectedPlanId = plan.ID;
    this.trackingService.tracking('AccountPlanClicked', TrackingEventNames.ACCOUNTPLANNING)
    if (!this.device.isOffline) {
      // await this.accountManagementDataService.getAccountPlans(false, plan.ID);
    }
    // if (this.uiService.activitiesPagePlanTabSelectedPlan == 'goalsPlansTab') {
      if (this.uiService.activitiesPageTab == 'plans') this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      if (this.uiService.activeViewOnPlanTab == 'planProgressReports') this.uiService.activeViewOnPlanTab = undefined;
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent, {
        deviceString: this.device.deviceFlags.ios ? 'ios' : 'other'
      });
      
      this.navService.pushChildNavPageWithPageTracking(AccountPlanDetails, PageName.AccountPlanDetailsComponent, PageName.AccountManagementPageComponent,
        { data: plan, selectedMode:  this.accountManagementOfflineService.selectedMode });
      this.uiService.showRightPane = true;
    // }
  }

  public async handleCallPlanSelection(callPlan) {
    if(callPlan.customerType == CallPlanCustomerType.CONTACT) {
      let foundContact = this.contactService.getContactByID(callPlan.contactId);
      this.contactService.contactInformation =foundContact;
      this.accountService.selected = undefined;
    }else {
      let foundAccount = this.accountService.getAccountById(callPlan.accountId);
      this.accountService.selected = foundAccount;
      this.contactService.contactInformation = undefined;
    }
    this.callPlanOfflineService.selectedCallPlan = callPlan;
    this.callPlanOfflineService.selectedCallPlan.isTeamCallPlan = false;
    this.selectedPlanId = callPlan.customerType == CallPlanCustomerType.CONTACT ? this.callPlanOfflineService.selectedCallPlan.contactId : this.callPlanOfflineService.selectedCallPlan.accountId;
    this.onCallPlanSelectOnPlanTab.emit(callPlan);
  }

  private updateScientificActivitiesList() {
    const currentDay: Date = Utility.changeUTCDateToLocalDateWith0Time(
      new Date().getTime()
    );

    this.scientificPlansData = this.scientificActivities.filter((sap) => {
      return (
        Number(sap.startDate) <= currentDay.getTime() &&
        Number(sap.endDate) >= currentDay.getTime() &&
        (sap.ownerID === this.currentUserID ||
          sap.users.some((x) => x.ownerid === this.currentUserID)) &&
        this.applyProductFilter(sap) &&
        this.applyTAFilter(sap)
      );
    });
    this.filteredScientificPlans = this.scientificPlansData;
    this.initializeScientificHeader();
  }

  async scientificPlansDataSelected(saPlan: ScientificActivityPlan) {
    this.selectedPlanId = saPlan.ID;
    this.trackingService.tracking('ScientificPlanClicked', TrackingEventNames.SCIENTIFICACTIVITYPLAN);

    let loader = await this.loadingController.create({});
    loader.present().then(() => {
      if (!this.device.isOffline) {
        this.sapDataService.getScientificPlanById(saPlan.ID).then(sp => {
          loader.dismiss();
          this.selectSAP.emit(sp);
        }).catch(err => {
          console.log(err);
          this.selectSAP.emit(saPlan);
          loader.dismiss();
        });
      } else {
        this.selectSAP.emit(saPlan);
        loader.dismiss();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.callPlanOfflineService.selectedCallPlan = undefined;
  }

  ionViewDidEnter() {
    this.getFormattedCallPlans();
  }

  private getFormattedCallPlans() {
    this.callPlansData = this.callPlanOfflineService.formattedCallPlans;
    this.filteredCallPlans = this.callPlanOfflineService.aggregateRepCallPlans(this.callPlansData.present)
    this.applyCallPlansProductFilter();
    this.initializeCallHeader();
  }

  onInput(event) {
    this.searchAccountPlans(event);
    this.searchScientificPlans(event);
  }

  searchAccountPlans(event) {
    if (event && event.target) {
      const searchedName = event.target.value.toLowerCase();
      if (searchedName && searchedName.trim() != '') {
        this.isSearched = true;
        if (this.isFiltered) {
          this.searchedDataAfterFilter = this.filteredAccountPlansData.filter(({ accounts, accountPlanName}) => {
            return accounts.some((account) => account.name.toLowerCase().includes(searchedName)) || accountPlanName.toLowerCase().includes(searchedName);
          })
          this.updateAccountPlansList(this.searchedDataAfterFilter);
        } else {
          this.searchedData = this.rawPlanData.filter(({ accounts, accountPlanName}) => {
            return accounts.some((account) => account.name.toLowerCase().includes(searchedName)) || accountPlanName.toLowerCase().includes(searchedName);
          })
          this.updateAccountPlansList(this.searchedData);
        }
      } else {
        this.isSearched = false;
        if (this.isFiltered) {
          this.updateAccountPlansList(this.filteredAccountPlansData);
        } else {
          this.updateAccountPlansList(this.rawPlanData);
        }
      }
    } else {
      this.isSearched = false;
      if (this.isFiltered) {
        this.updateAccountPlansList(this.filteredAccountPlansData);
      } else {
        this.updateAccountPlansList(this.rawPlanData);
      }
    }
    this.intializeheaders();
  }

  searchCallPlans(event) {
    this.searchKey = '';
    if (!this.shouldFireSearchChangeEvent) {
      this.shouldFireSearchChangeEvent = true;
      return;
    }
    let params = (event.value) ? event.value : '';
    // for input search text length > 2
    if (params.length > 2) {
      if (params.trim().length == 0) return;
      this.searching = true; // to figure out results of search
      this.searchKey = params;
      // this.searchCallPlansSuggestions(this.callPlansData, params, event);
      this.searchGoalPlansSuggestions(params, event)
    }
    // for input search text length is 0 to 2
    else {
      this.suggestionsData = [];
      if (!this.selectedSuggestionsData || !this.selectedSuggestionsData.length) {
        this.filteredList = { present: [] };
        this.searching = false;
        this.getFormattedCallPlans();
      }
      else {
        this.searchGoalsPlansList();
      }
      let searchToolName;
      if (!this.callPlanOfflineService.myRecentSearches || !this.callPlanOfflineService.myRecentSearches.length) {
        searchToolName = this.searchConfigService.toolNames.find(tool => tool.toolName == 'My Call Plans');
      }
      if (searchToolName && (this.searchConfigService.savedSearches.filter(search => search.searchToolName == searchToolName.searchToolNameID)).length == 0) {
        this.suggestionsActive = false;
      }
    }
    this.initializeCallHeader();
  }

  searchScientificPlans(event) {
    if (event && event.target) {
      const val = event.target.value;
      if (val && val.trim() !== '') {
        this.searching = true;
        this.scientificActivities = this.originalList.filter((item) => {
          return (item.scientificActivityPlanName.toLowerCase().indexOf(val.toLowerCase()) > -1);
        });
        this.filteredScientificPlans = this.scientificActivities;
      } else {
        this.searching = false;
      }
    } else {
      this.scientificActivities = this.originalList;
      this.filteredScientificPlans = this.scientificActivities;
    }
    this.updateScientificActivitiesList();
    this.initializeScientificHeader();
  }

  searchGoalsPlansList() {
    this.searching = true;
    let filteredCallPlans: any[]=[];
    filteredCallPlans= this.callPlanOfflineService.formattedCallPlans.present
    this.filteredAccountPlans = this.rawPlanData;
    this.scientificActivities = this.currentSAP;
    let selectedSuggestionsDataCopy = this.selectedSuggestionsData.slice();
    let entityLevelCharSearch = selectedSuggestionsDataCopy.find(o => o.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH);
    // entity level character search
    if (entityLevelCharSearch) {
      const formattedSearchText = entityLevelCharSearch.charSearchText ? this.searchConfigService.convertFormattedString(entityLevelCharSearch.charSearchText).toUpperCase() : '';
      const formattedSearchTextSplit = formattedSearchText ? formattedSearchText.split(" ") : '';
      if (formattedSearchTextSplit) {
        formattedSearchTextSplit.forEach(searchText => {
          filteredCallPlans = filteredCallPlans.filter(callPlan => {
            let callPlanPresentEntityLevel = this.getOjectValues(callPlan);
            return callPlanPresentEntityLevel.includes(searchText);
          });
          this.filteredAccountPlans = this.filteredAccountList.filter(accountPlan => {
            let accountPlanEntityLevel = this.getOjectValues(accountPlan);
            return accountPlanEntityLevel.includes(searchText);
          });
          this.filteredScientificPlans = this.filteredList.present.filter(scientificPlan => {
            let scientificPlanEntityLevel = this.getOjectValues(scientificPlan);
            return scientificPlanEntityLevel.includes(searchText);
          });
        });
      }
    }
    filteredCallPlans = this.searchConfigService.fetchFilteredListBasedOnSuggestions(selectedSuggestionsDataCopy, filteredCallPlans.filter(ap => ap.repPlans.length != 0).map(ap => ({ ...ap, productid_Formatted: ap.repPlans[0].productid_Formatted })));
    this.filteredAccountPlans.forEach(accountPlan => {
      const productsName = [];
      if (!_.isEmpty(accountPlan.products)) {
        productsName.push(...accountPlan.products.map(product => product.productName));
      }
      accountPlan['productid_Formatted'] = productsName;
    })
    this.filteredAccountPlans = this.searchConfigService.fetchFilteredListBasedOnSuggestions(selectedSuggestionsDataCopy, this.filteredAccountPlans);
    this.scientificActivities.forEach(sP => {
      const productsName = [];
      if (!_.isEmpty(sP.products)) {
        productsName.push(...sP.products.map(product => product.name));
      }
      sP['productid_Formatted'] = productsName;
    })
    this.filteredScientificPlans = this.searchConfigService.fetchFilteredListBasedOnSuggestions(selectedSuggestionsDataCopy, this.scientificActivities);
    this.filteredScientificPlans = this.filteredScientificPlans;
    this.filteredCallPlans = filteredCallPlans;
    this.filteredAccountPlans = this.filteredAccountPlans;
    this.initializeCallHeader();
    this.initializeAccountHeader();
    this.initializeScientificHeader();
  }

  searchGoalPlansSuggestions(params, event) {
    this.searchCallPlansSuggestions(this.filteredCallPlans, params, event);
  }

  searchCallPlansSuggestions(callPlans:any[] , searchText: string, event?) {
    if (searchText && callPlans) {
      let callPlansSearchIndexConfig = this.searchConfigService.myGoalsPlansSearchIndexesConfig
      let toolNameForEntityCharacterSearch = this.translate.instant('MY_CALL_PLANS')
      this.suggestionsData = this.searchConfigService.fetchSuggestions(callPlansSearchIndexConfig, this.selectedSuggestionsData,
        toolNameForEntityCharacterSearch, searchText)
      searchText = searchText.toUpperCase();
      if (this.suggestionsData.length) this.suggestionsActive = true;
      if (!this.selectedSuggestionsData || !this.selectedSuggestionsData.length) {
        const formattedSearchText = this.searchConfigService.convertFormattedString(searchText).trim();
        this.filteredCallPlans = callPlans.filter(callPlan => {
          let callPlanString = this.getOjectValues(callPlan);
          return callPlanString.includes(formattedSearchText);
        });
      }
    } else {
      return [];
    }
  }

  getOjectValues(callPlanObject): string {
    let objString: string[] = [];
    let callPlanInclude = ['customerName'];
    for (let callPlan in callPlanObject) {
      if (callPlanInclude.indexOf(callPlan) > -1 && callPlanObject[callPlan]) {
        if (Array.isArray(callPlanObject[callPlan])) {
          for (let i = 0; i < callPlanObject[callPlan].length; i++) {
            objString.push(this.getOjectValues(callPlanObject[callPlan][i]));
          }
        } else {
          objString.push(callPlanObject[callPlan]);
        }
      }
    }
    return this.searchConfigService.convertFormattedString(objString.toString().toUpperCase());
  }

  getAccountOjectValues(accountPlanObject): string {
    let objString: string[] = [];
    let accountPlanInclude = ['accountPlanName'];
    for (let accountPlan in accountPlanObject) {
      if (accountPlanInclude.indexOf(accountPlan) > -1 && accountPlanObject[accountPlan]) {
        if (Array.isArray(accountPlanObject[accountPlan])) {
          for (let i = 0; i < accountPlanObject[accountPlan].length; i++) {
            objString.push(this.getAccountOjectValues(accountPlanObject[accountPlan][i]));
          }
        } else {
          objString.push(accountPlanObject[accountPlan]);
        }
      }
    }
    return this.searchConfigService.convertFormattedString(objString.toString().toUpperCase());
  }

  getScientificOjectValues(scientificPlanObject): string {
    let objString: string[] = [];
    let scientificPlanInclude = ['scientificActivityPlanName'];
    for (let scientificPlan in scientificPlanObject) {
      if (scientificPlanInclude.indexOf(scientificPlan) > -1 && scientificPlanObject[scientificPlan]) {
        if (Array.isArray(scientificPlanObject[scientificPlan])) {
          for (let i = 0; i < scientificPlanObject[scientificPlan].length; i++) {
            objString.push(this.getScientificOjectValues(scientificPlanObject[scientificPlan][i]));
          }
        } else {
          objString.push(scientificPlanObject[scientificPlan]);
        }
      }
    }
    return this.searchConfigService.convertFormattedString(objString.toString().toUpperCase());
  }

  handleFacetSelection(data: SelectedSuggestionPillDataModel) {
    this.disableSaveSearch = false;
    console.log(data);
    this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(data, this.selectedSuggestionsData);
    this.selectedSuggestionsData.sort((a, b) => {
      if (a.createdOn < b.createdOn) return 1
      else return -1
    })
    //if(this.selectedSuggestionsData.length == 1) this.content.resize();
    this.suggestionsActive = false;
    this.callPlanSearchText = '';
    this.suggestionsData = [];
    this.shouldFireSearchChangeEvent = false;
    this.searchGoalsPlansList();
    if (!data.isComingFromRecentSearch) {
      let recentSearches;
      recentSearches = this.callPlanOfflineService.myRecentSearches
      if (!recentSearches.some(recSearch => recSearch.categoryName == data.categoryName && recSearch.selectedFacet == data.selectedFacet)) {
        recentSearches.unshift(data);
        _.remove(recentSearches, (o, index) => index > 2)
        this.disk.updateOrInsert(DB_KEY_PREFIXES.MY_CALL_PLANS_RECENT_SEARCHES, (doc) => {
          if (!doc || !doc.raw) {
            doc = {
              raw: []
            }
          }
          doc.raw = recentSearches;
          return doc;
        })
      }
    }
  }

  handleSavedSearchSelection(data) {
    this.selectedSuggestionsData = [];
    if (data && data.categoryValuePairs) {
      data.categoryValuePairs.forEach(catValPair => {
        this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(catValPair, this.selectedSuggestionsData);
      })
      this.selectedSuggestionsData.sort((a, b) => {
        if (a.createdOn < b.createdOn) return 1
        else return -1
      })
      this.suggestionsActive = false;
      this.callPlanSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searchGoalsPlansList();
    }
  }

  clickedInSuggestionsArea(ev) {
    ev.stopPropagation();
  }

  onCancel(event) {
    this.onAccountPlansCancel(event);
    this.onScientificPlansCancel(event);
    this.onCallPlansCancel(event);
  }

  onAccountPlansCancel(event) {
    this.updateAccountPlansList(this.rawPlanData);
    this.initializeAccountHeader();
  }

  onCallPlansCancel(event) {
    this.filteredCallPlans = this.callPlansData.present;
    this.selectedSuggestionsData = [];
    this.getFormattedCallPlans();
    this.initializeCallHeader();
  }

  onScientificPlansCancel(event) {
    this.searching = false;
    this.scientificActivities = this.originalList;
    this.filteredScientificPlans = this.scientificPlansData;
    this.updateScientificActivitiesList();
  }

  filterAllGoalsPlans() {
    let orderBy = new OrderByPipe();
    this.filterPopoverData = [
      {
        text: '',
        selectedValues: ['0'],
        value: '0',
        items: [
          { text: this.translate.instant('ALL_GOALS'), value: '0' }
        ],
        handler: (selectedItem, item, itemRef) => {
          if (itemRef && itemRef.parent && itemRef.parent.items) {
            if (itemRef.parent.items[1]) itemRef.parent.items[1].deSelectAll();
            if (itemRef.parent.items[2]) itemRef.parent.items[2].deSelectAll();
            if (itemRef.parent.items[3]) itemRef.parent.items[3].deSelectAll();
          }
          this.filterBadgeCount = 0;
          item.value = selectedItem.value;
          this.accountPlansFilter = { ...this.accountPlansFilter, account: [] };
          this.accountFilterList = [];
          this.filteredAccountPlansData = [];
          this.updateAccountPlansList(this.rawPlanData);
          this.isFiltered = false;
          this.filterBadgeCount = 0;
          item.value = selectedItem.value;
          this.scientificActivityFilter = { ...this.scientificActivityFilter, products: [], therapeuticAreaList: [] };
          this.callPlansFilter = { ...this.callPlansFilter, products: [] };
          this.accountPlansProductFilter = { ...this.accountPlansProductFilter, products: [] }
          this.productFilterList = [];
          this.therapeuticAreaFilterList = [];
          this.isFiltered = false;
          this.updateEmptyMessage();
          this.updateScientificActivitiesList();
          this.getFormattedCallPlans();
          this.updateAccountPlansList(this.rawPlanData);
          this.initGoalsPlansHeaderLeft();
          this.initializeAccountHeader();

        }
      },
      {
        text: this.translate.instant('ACCOUNT'),
        value: '',
        selectedValues: [],
        multiselect: true,
        items: orderBy.transform(this.allAccountsForAccountPlans.map(ac => ({ text: ac.accountName, value: ac.id })), "text", true),
        handler: (selectedItem, item, itemRef, selected?: boolean) => {
          this.goalsPlansHandler(itemRef)
          this.onAccountFilterChange(selectedItem, item, itemRef, selected);
          this.initGoalsPlansHeaderLeft();
          this.intializeheaders();
        }
      },
      {
        text: this.translate.instant('PRODUCTS'),
        value: '',
        selectedValues: [],
        multiselect: true,
        items: orderBy.transform(this.brandService.brands.map(b => ({ text: b.name, value: b.ID })), "text", true),
        handler: (selectedItem, item, itemRef, selected?: boolean) => {
          this.goalsPlansHandler(itemRef)
          this.onProductFilterChange(selectedItem, item, itemRef, selected);
          this.updateEmptyMessage();
          this.updateScientificActivitiesList();
          this.updateAccountPlansList(this.rawPlanData);
          this.getFormattedCallPlans();
          this.initGoalsPlansHeaderLeft();
        }
      },
      {
        text: this.translate.instant('THERAPEUTIC_AREAS'),
        value: '',
        selectedValues: [],
        multiselect: true,
        items: orderBy.transform(this.therapeuticservice.therapeuticAreas.map(b => ({ text: b.name, value: b.therapeuticareaId })), "text", true),
        handler: (selectedItem, item, itemRef, selected?: boolean) => {
          this.goalsPlansHandler(itemRef)
          this.onTherapeuticAreaFilterChange(selectedItem, item, itemRef, selected);
          this.updateEmptyMessage();
          this.updateScientificActivitiesList();
          this.initGoalsPlansHeaderLeft();
          this.intializeheaders();
        }
      },
    ];
  }

  goalsPlansHandler(itemRef) {
    if (itemRef && itemRef.parent && itemRef.parent.items && itemRef.parent.items[0] && itemRef.parent.items[0].items && itemRef.parent.items[0].items[0]) {
      itemRef.parent.items[0].value = undefined;

      itemRef.parent.items[0].items[0].checked =
        itemRef.parent.items[0].items[0].item.checked = false;

      itemRef.parent.items[0].items[0].selectedValues =
        itemRef.parent.items[0].items[0].item.selectedValues = [];
    }
  }

  private onProductFilterChange(selectedItem, item, itemRef = null, selected?: boolean) {
    if (selectedItem && selectedItem.value) {
      if (selected) {
        this.scientificActivityFilter.products.push(selectedItem.value);
        this.scientificActivityFilter.products.push(selectedItem);
        this.callPlansFilter.products.push(selectedItem.value);
        this.callPlansFilter.products.push(selectedItem);
        this.accountPlansProductFilter.products.push(selectedItem.value);
        this.accountPlansProductFilter.products.push(selectedItem);
        if (itemRef && itemRef.item && itemRef.item.selectedValues) {
          itemRef.item.selectedValues.push(selectedItem.value);
        }
      } else {
        if (itemRef && itemRef.item && itemRef.item.selectedValues) {
          let idx = itemRef.item.selectedValues.findIndex(item => item === selectedItem.value);
          if (idx >= 0) {
            itemRef.item.selectedValues.splice(idx, 1);
          }
        }
      }
    } else {
      this.scientificActivityFilter.products = [];
      this.callPlansFilter.products = [];
      this.accountPlansProductFilter.products = [];
      this.productFilterList = [];
    }
    this.productFilter(selectedItem.value);
    this.getFilterCount();
    this.isFiltered = this.productFilterList.length > 0;
    if (!this.isFiltered && itemRef && itemRef.parent && itemRef.items && itemRef.items[0]) {
      itemRef.parent._value = itemRef.parent.items[0]._value = '0';
      this.filterPopoverData[0].value = '0';
    }
  }

  private onTherapeuticAreaFilterChange(selectedItem, item, itemRef = null, selected?: boolean) {
    if (selectedItem && selectedItem.value) {
      if (selected) {
        this.scientificActivityFilter.therapeuticAreaList.push(selectedItem.value);
        this.scientificActivityFilter.therapeuticAreaList.push(selectedItem);
        if (itemRef && itemRef.item && itemRef.item.selectedValues) {
          itemRef.item.selectedValues.push(selectedItem.value);
        }
      } else {
        if (itemRef && itemRef.item && itemRef.item.selectedValues) {
          let idx = itemRef.item.selectedValues.findIndex(item => item === selectedItem.value);
          if (idx >= 0) {
            itemRef.item.selectedValues.splice(idx, 1);
          }
        }
      }
    } else {
      this.scientificActivityFilter.therapeuticAreaList = [];
      this.therapeuticAreaFilterList = [];
    }
    this.therapeuticFilter(selectedItem.value);
    if (selected == true) {
      this.filteredCallPlans = [];
      this.filteredAccountPlans = [];
    } else {
      this.updateAccountPlansList(this.rawPlanData);
      this.getFormattedCallPlans();
    }
    this.getFilterCount();
    this.isFiltered = this.therapeuticAreaFilterList.length > 0;
    if (!this.isFiltered && itemRef && itemRef.parent && itemRef.items && itemRef.items[0]) {
      itemRef.parent._value = itemRef.parent.items[0]._value = '0';
      this.filterPopoverData[0].value = '0';
    }
  }

  private applyCallPlansProductFilter() {
    if (this.productFilterList.length > 0) {
      this.filteredCallPlans = this.filteredCallPlans.filter((el) =>
      this.productFilterList.some((x) => x === el.repPlans[0].productid));
    } else {
      return true;
    }
  }

  private applyAccountPlansProductFilter(plan: AccountPlan) {
    if (this.productFilterList.length > 0) {
      return plan.products.some((el) =>
        this.productFilterList.some((x) => x === el.productID));
    }
    else {
      return true;
    }
  }

  private applyProductFilter(sap: ScientificActivityPlan) {
    if (this.productFilterList.length > 0) {
      return sap.products.some((el) =>
        this.productFilterList.some((x) => x === el.ID)
      );
    } else {
      return true;
    }
  }

  private applyTAFilter(sap: ScientificActivityPlan) {
    if (this.therapeuticAreaFilterList.length > 0) {
      return sap.therapeuticAreaList.some((el) =>
        this.therapeuticAreaFilterList.some((x) => x === el.therapeuticareaId)
      );
    } else {
      return true;
    }
  }

  public productFilter(id) {
    let index = this.productFilterList.findIndex(x => x == id);
    if (index > -1) {
      this.productFilterList.splice(index, 1);
    } else {
      this.productFilterList.push(id);
    }
  }

  public therapeuticFilter(id) {
    let index = this.therapeuticAreaFilterList.findIndex(x => x == id);
    if (index > -1) {
      this.therapeuticAreaFilterList.splice(index, 1);
    } else {
      this.therapeuticAreaFilterList.push(id);
    }
  }

  private onAccountFilterChange(selectedItem, item, itemRef = null, selected?: boolean) {
    if (selectedItem && selectedItem.value) {
      if (selected) {
        this.accountPlansFilter.account.push(selectedItem.value);
        this.accountPlansFilter.account.push(selectedItem);
        if (itemRef && itemRef.item && itemRef.item.selectedValues) {
          itemRef.item.selectedValues.push(selectedItem.value);
        }
      } else {
        if (itemRef && itemRef.item && itemRef.item.selectedValues) {
          let idx = itemRef.item.selectedValues.findIndex(item => item === selectedItem.value);
          if (idx >= 0) {
            itemRef.item.selectedValues.splice(idx, 1);
          }
        }
      }
    } else {
      this.accountPlansFilter.account = [];
      this.accountFilterList = [];

    }
    this.accountFilter(selectedItem.value);
    if (selected == true) {
      this.filteredScientificPlans = [];
      this.filteredCallPlans = [];
    } else {
      this.updateScientificActivitiesList();
      this.getFormattedCallPlans();
    }
    this.getFilterCount();
    this.isFiltered = this.accountFilterList.length > 0;
    if (!this.isFiltered && itemRef && itemRef.parent && itemRef.items && itemRef.items[0]) {
      itemRef.parent._value = itemRef.parent.items[0]._value = '0';
      this.filterPopoverData[0].value = '0';
    }
  }

  public accountFilter(id) {
    let index = this.accountFilterList.findIndex(x => x == id);
    if (index > -1) {
      this.accountFilterList.splice(index, 1);
    } else {
      this.accountFilterList.push(id);
    }
    if (this.accountFilterList.length > 0) {
      let accountPlansData = [];
       this.accountFilterList.forEach(ac => {
        accountPlansData.push(...this.accountManagementOfflineService.accountPlans.filter(accountPlan => accountPlan.accountId == ac));
       })
      this.filteredAccountPlansData = accountPlansData;
      this.isFiltered = true;
      this.updateAccountPlansList(this.filteredAccountPlansData);
    } else {
      this.isFiltered = false;
      this.updateAccountPlansList(this.rawPlanData);
    }
  }

  getFilterButtonBadgeCount() {
    return this.filterBadgeCount;
  }

  private getFilterCount() {
    this.filterBadgeCount = 0;
    this.filterBadgeCount = (this.accountFilterList.length > 0) ? this.accountFilterList.length : 0;
    if (this.productFilterList.length > 0) this.filterBadgeCount = this.productFilterList.length;
    if (this.therapeuticAreaFilterList.length > 0) this.filterBadgeCount += this.therapeuticAreaFilterList.length;
  }

  updateEmptyMessage() {
    this.uiService.updateNothingSelectedScreenMessageFor(this.sapFilter.transform(this.scientificActivities,
      this.therapeuticAreaFilterList, this.productFilterList).length);
  }

  private initGoalsPlansHeaderLeft(): void {
    this.indHeaderLeftModel = {
      id: 'goals-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.getPlaceHolder(),
      mode: true,
      isOnActPage: true,
      controls: [],
    };
  }

  getPlaceHolder(): string {
    let str = '';
    if (!this.isFiltered) {
      str = this.translate.instant('HOME')
    }
    else if (this.isFiltered && this.accountFilterList.length > 0) {
      if (this.accountFilterList.length > 0) {
        let filteredAccountName = [];
        this.accountFilterList.forEach(filteredId => {
          filteredAccountName.push(...this.allAccountsForAccountPlans.filter(ac => ac.id === filteredId));
        })
        if (filteredAccountName.length == 1) {
          str = filteredAccountName[0].accountName;
        } else {
          filteredAccountName.forEach(ac => {
            str = ac.accountName + ', ' + str;
          });
        }
        if (this.therapeuticAreaFilterList.length > 0) {
          this.therapeuticAreaFilterList.forEach(filteredId => {
            filteredAccountName.push(...this.therapeuticservice.therapeuticAreas.filter(ta => ta.therapeuticareaId === filteredId));
          })
          filteredAccountName.forEach(ta => {
            str = ta.name + ', ' + str;
          });
        }
        if (this.productFilterList.length > 0) {
          this.productFilterList.forEach(filteredId => {
            filteredAccountName.push(...this.brandService.brands.filter(brand => brand.ID === filteredId));
          })
          filteredAccountName.forEach(product => {
            str = product.name + ', ' + str;
          });

        }
      }

    }
    else if (this.isFiltered && this.productFilterList.length > 0) {
      if (this.productFilterList.length > 0) {
        let filteredName = [];
        this.productFilterList.forEach(filteredId => {
          filteredName.push(...this.brandService.brands.filter(brand => brand.ID === filteredId));
        })
        if (filteredName.length == 1) {
          str = filteredName[0].name;
        } else {
          filteredName.forEach(product => {
            str = product.name + ', ' + str;
          });
        }
        if (this.therapeuticAreaFilterList.length > 0) {
          this.therapeuticAreaFilterList.forEach(filteredId => {
            filteredName.push(...this.therapeuticservice.therapeuticAreas.filter(ta => ta.therapeuticareaId === filteredId));
          })
          filteredName.forEach(ta => {
            str = ta.name + ', ' + str;
          });
        }
      }
    }
    else if (this.isFiltered && this.therapeuticAreaFilterList.length > 0) {
      let filteredName = [];
      this.therapeuticAreaFilterList.forEach(filteredId => {
        filteredName.push(...this.therapeuticservice.therapeuticAreas.filter(ta => ta.therapeuticareaId === filteredId));
      })
      if (filteredName.length == 1) {
        str = filteredName[0].name;
      } else {
        filteredName.forEach(ta => {
          str = ta.name + ', ' + str;
        });
      }
    }
    else {
      str = this.translate.instant('HOME');
    }
    return str;
  }

  public async openGoalsPlansFilter(event) {
    this.goalsPlansFilterPopover = await this.popover.create({
      component: MultiSelectPopover, componentProps: { root: this.filterPopoverData },
      event: event
    });
    this.goalsPlansFilterPopover.present();
    this.goalsPlansFilterPopover.onDidDismiss().then(() => {
      this.goalsPlansFilterPopover = null;
    });
  }

  intializeheaders() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT)) {
      this.isAccountPlansFA = true;
      this.initializeAccountHeader();
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.CALL_PLANS)) {
      this.isCallPlansFA = true;
      this.initializeCallHeader();
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_TOOL_PLAN)) {
      this.isScientificPlansFA = true;
      this.initializeScientificHeader();
    }
  }

  public onSectionHeaderControlClick(id) {
    switch (id) {
      case 'account-plans-actions':
        this.isAccountPlansExpanded = !this.isAccountPlansExpanded
        this.initializeAccountHeader();
        break;
      case 'call-plans-actions':
        this.isCallPlansExpanded = !this.isCallPlansExpanded
        this.initializeCallHeader();
        break;
      case 'scientific-plans-actions':
        this.isScientificPlansExpanded = !this.isScientificPlansExpanded
        this.initializeScientificHeader();
      default:
        break;
    }
  }

  private initializeAccountHeader() {
    this.accountHeaderModel = {
      id: 'goals-plan-account-header',
      title: `${this.translate.instant('ACCOUNT_PLANS')} (${this.filteredAccountPlans.length})`,
      controls: [{
        id: 'account-plans-actions',
        icon: this.isAccountPlansExpanded ? 'assets/imgs/up-arrow.svg' : 'assets/imgs/down-arrow.svg',
        isDisabled: false
      }],
    };
  }

  private initializeCallHeader() {
    let titleText = '';
    if(this.filteredCallPlans?.length > 1) {
      titleText = `${this.translate.instant('CALL_PLANS')} (${this.filteredCallPlans?.length})`;
    }else {
      titleText = `${this.translate.instant('CALL_PLAN')} (${this.filteredCallPlans?.length})`;
    }
    this.callHeaderModel = {
      id: 'goals-plan-call-header',
      title: titleText,
      controls: [{
        id: 'call-plans-actions',
        icon: this.isCallPlansExpanded ? 'assets/imgs/up-arrow.svg' : 'assets/imgs/down-arrow.svg',
        isDisabled: false
      }],
    };
  }

  private initializeScientificHeader() {
    this.scientificHeaderModel = {
      id: 'goals-plan-scientific-header',
      title: `${this.translate.instant('SCIENTIFIC_PLANS_CAP')} (${this.filteredScientificPlans.length})`,
      controls: [{
        id: 'scientific-plans-actions',
        icon: this.isScientificPlansExpanded ? 'assets/imgs/up-arrow.svg' : 'assets/imgs/down-arrow.svg',
        isDisabled: false
      }],
    };
  }

  public onClickEnter(ev){
    if(ev){
      this.suggestionsActive = false;
    }
  }
}
