<ng-container *ngIf="!isSkeleton; else skeleton">
  <ion-item *ngIf="viewData" class="wrapper-item"
    [ngClass]="[viewData.mainItemCssClass ? viewData.mainItemCssClass : '',  viewData.isSelected?'itemSelected':'']"
    (click)="handleMainItemClick($event, 'mainItem')">
    
    <ion-label class="{{labelClassName}}">
      <ion-item class="item-primaryText-wrap">
        <img *ngIf="viewData.startIconInline" class="start-inline-icon" [ngClass]="{'with-grid-sub-primary-text': viewData.isSecondaryGrid && viewData.isSubPrimaryTextRight}" [src]="viewData.startIconInline">
        <ion-text *ngIf="viewData.primaryTextRight"
          [ngClass]="{
            'grid-primary-text': viewData.isSecondaryGrid,
            'grid-sub-primary-text': viewData.isSecondaryGrid && viewData.isSubPrimaryTextRight
          }">
          <div class="primary-text-wrapper">
            <h3>{{viewData.primaryTextRight}}</h3>
            <div *ngIf="viewData.subPrimaryTextRight" class="sub-primary-text">{{viewData.subPrimaryTextRight}}</div>
          </div>
          <ion-icon *ngIf="viewData.primaryTextRightIconClass || viewData.primaryTextRightIconName" class="{{viewData.primaryTextRightIconClass}}" [name]="viewData.primaryTextRightIconName"></ion-icon>
        </ion-text>
      </ion-item>

      <ion-grid *ngIf="viewData.isSecondaryGrid">
        <ion-row>
          <ion-col *ngIf="viewData.secondaryTextRight || viewData.secondaryTextRightTwo || viewData.secondaryText || viewData.secondaryTextLeft || viewData.secondaryTextLeftTwo">
            <p class="tert-margin-text secondary-text">{{viewData.secondaryTextRight}}</p>
            <p class="tert-margin-text grid-secondary-text">{{viewData.secondaryTextRightTwo}}</p>   
          </ion-col>
          <ion-col *ngIf="viewData.secondaryTextRight || viewData.secondaryTextRightTwo || viewData.secondaryText || viewData.secondaryTextLeft || viewData.secondaryTextLeftTwo">
            <p class="tert-margin-text secondary-text">{{viewData.secondaryTextLeft}}</p>
            <p class="tert-margin-text grid-secondary-text" *ngIf="viewData.secondaryTextLeftTwo">{{viewData.secondaryTextLeftTwo}} </p>   
          </ion-col>
        </ion-row>
      </ion-grid>

      <div *ngIf="viewData.isSecondaryGrid && viewData.gridItems && viewData.gridItems.length>0"class="secondary-grid">
        <div *ngFor="let item of viewData.gridItems" class="grid-item {{item.id}}"  [ngClass]="{'description-field': item.descriptionField}">
          <div class="grid-title">
            <div class="grid-secondary-text-label" *ngIf="item.label"><label>{{item.label}}</label></div>
            <div class="grid-secondary-text-value" *ngIf="item.value"><label>{{item.value}}</label></div>
          </div>
        </div>
      </div>

      <div *ngIf="viewData.buttonItems && viewData.buttonItems.length > 0" class="separationLine"></div>

      <ion-item>
        <ion-buttons slot="end" *ngIf="viewData.buttonItems && viewData.buttonItems.length > 0">
          <ng-container *ngFor="let button of viewData.buttonItems let i = index">
            <span *ngIf="i==1" class="separationVerticalLine">&#124;</span>
            <ion-button id="{{button.id}}" (click)="onButtonClick($event, button, viewData)" [disabled]="button.isDisabled">
              <div class="button-item-wrapper"><span class="button-text">{{button.name}}</span></div>
            </ion-button>
          </ng-container>
        </ion-buttons>
      </ion-item>
    </ion-label>
  </ion-item>
</ng-container>

<ng-template #skeleton>
  <ion-item class="wrapper-item">
    <ion-thumbnail slot="start" class="main-icon">
      <ion-skeleton-text class="skeleton-text-animated"></ion-skeleton-text>
    </ion-thumbnail>

    <ion-label class="text-wrapper">
      <ion-text class="primary-text">
        <h3>
          <ion-skeleton-text class="skeleton-text-animated"
            [ngStyle]="{ width: skeletonConfig?.primaryTxtWidth ? skeletonConfig.primaryTxtWidth : defaultPrimaryTxtWidth }">
          </ion-skeleton-text>
        </h3>
      </ion-text>
      <p class="secondary-text">
        <ion-skeleton-text class="skeleton-text-animated"
          [ngStyle]="{ width: skeletonConfig?.firstSecondaryTxtWidth ? skeletonConfig.firstSecondaryTxtWidth : defaultFirstSecondaryTxtWidth }">
        </ion-skeleton-text>
      </p>
      <p class="secondary-text">
        <ion-skeleton-text class="skeleton-text-animated"
          [ngStyle]="{ width: skeletonConfig?.secondSecondaryTxtWidth ? skeletonConfig.secondSecondaryTxtWidth : defaultSecondSecondaryTxtWidth }">
        </ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>

  <div class="separationLine"></div>
</ng-template>
<div *ngIf="(viewData?.isDataLoading$ | async) === true" class="data-load-indicator">
  <ion-spinner name="dots"></ion-spinner>
</div>