import { ProcedureTrackerActivityDataService } from './../../data-services/procedure-tracker-activity/procedure-tracker-activity.data.service';
import { ProcedureTrackerActivity } from './../../classes/activity/procedure-tracker.activity.class';
import { Component, HostListener, Input } from '@angular/core';
import {  LoadingController, Platform, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Events } from '@omni/events';
import _, { cloneDeep } from 'lodash';
import { Activity, ActivityType } from '../../classes/activity/activity.class';
import { AppointmentActivity } from '../../classes/activity/appointment.activity.class';
import { EmailActionType, EmailActivity, EmailActivityParty, EmailAddress } from '../../classes/activity/email.activity.class';
import { FollowupActionType, FollowUpActivity } from '../../classes/activity/follow-up-action.activity.class';
import { OrderActivity } from '../../classes/activity/order.activity.class';
import { PhoneActivity } from '../../classes/activity/phone.activity.class';
import { CreateSampleDropRequestBody, SampleActivity } from '../../classes/activity/sample.activity.class';
import { TimeOffActivity } from '../../classes/activity/timeoff.class';
import { Brand } from '../../classes/brand/brand.class';
import { CaseActivity } from '../../classes/case-intake/case-activity.class';
import { Channel, ChannelActivityType, ChannelType } from '../../classes/consent/channel.class';
import { ContactAddress, ContactSentiment } from '../../classes/contact/contact.class';
import { MeetingStatus } from '../../classes/meeting/meeting.class';
import { OptionSet } from '../../classes/xperiences/trending.customer.class';
import { TimeOffComponent } from '../../components/time-off/time-off-home/time-off-home';
import { CaseManagementDataService } from '../../data-services/case-management/case-management.data.service';
import { ContactDataService } from '../../data-services/contact/contact.data.service';
import { FollowUpActivityDataService } from '../../data-services/follow-up-activity/follow-up-activity.data.service';
import { MeetingDataService, UpdateMeetingPayload } from '../../data-services/meeting/meeting.data.service';
import { OrderActivityDataService } from '../../data-services/order-activity/order-activity.data.service';
import { RepServices } from '../../data-services/rep/rep.services';
import { SampleDataService } from '../../data-services/sample/sample.data.service';
import { TimeOffDataService } from '../../data-services/time-off/time-off.data.service';
import { ActivityScrapStatus, ActivityService } from '../../services/activity/activity.service';
import { AlertService } from '../../services/alert/alert.service';
import { AuthenticationService } from '../../services/authentication.service';
import { BrandOfflineService } from '../../services/brand/brand.service';
import { CallPlanOfflineService } from '../../services/call-plan/call-plan.offline.service';
import { AccesingMode, CaseManagementService } from '../../services/case-management/case-management.service';
import { CoachingReportService } from '../../services/coaching/coaching.report.service';
import { ConsentService } from '../../services/consent/consent.service';
import { ContactOfflineService, UserTagForContact } from "../../services/contact/contact.service";
import {
  DateTimeFormatsService, DecimalPosition,
  SymbolPosition,
  TimeFormat
} from '../../services/date-time-formats/date-time-formats.service';
import { DeviceService } from '../../services/device/device.service';
import { DiskService } from '../../services/disk/disk.service';
import { EmailService } from '../../services/email-templates/email.service';
import {GlobalUtilityService} from '../../services/global-utility.service';
import { TrackingEventNames, TrackService } from '../../services/logging/tracking.service';
import { NavigationService, PageName } from '../../services/navigation/navigation.service';
import { NotificationService } from '../../services/notification/notification.service';
import { AllocationShipmentService } from '../../services/sample/allocation-shipment.service';
import { SampleService, SamplingDetailsViewMode } from '../../services/sample/sample.service';
import { SchedulerService } from '../../services/scheduler/scheduler.service'
import { TimeOffService } from '../../services/time-off/time-off.service';
import { UIService } from '../../services/ui/ui.service';
import { PopoverMode } from '../../utility/util';
import { WebsocketDataService } from './../../data-services/websocket/websocket.data.service';
import { FooterService } from './../../services/footer/footer.service';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { UserTag } from '@omni/services/user-tag/user-tag.service';
import { LocalizationService } from '@omni/services/localization/localization.service';

@Component({
  selector: 'popover',
  templateUrl: 'popover.html',
  styleUrls:['popover.scss']
})
export class PopoverComponent {
  public subjectInput: string;
  public locationInput: string;
  @Input() textInput: string;
  @Input() selectEvent: Function;
  public textInputType: string;
  public popoverView: string;
  public scrollableListView = false;
  public contactsentiment: ContactSentiment;
  public callplanfilterselected: string;
  public isCompletion: boolean;
  public isStatus: boolean;
  public isPosition: boolean;
  public activityForCalendarPopover: AppointmentActivity | TimeOffActivity | SampleActivity | EmailActivity | FollowUpActivity | OrderActivity | CaseActivity | PhoneActivity | SurgeryOrderActivity | ProcedureTrackerActivity;
  public reasonlist;
  public showLoader = false;
  public repCallPlanDetailFilter: string;
  public priorityID = '';
  public selectListData: Array<SelectListData>;
  public thumbsDownReasons: OptionSet[] = [];
  public selectedthumbsDownReason: OptionSet;
  public timeoffReasonsConfig = this.authenticationService.user.ioConfigurations?.find(o=>o.configName=="Timeoff.Reasons.Configuration") || []

  public callPlanFlags = {
    isCompletion: false,
    isPosition: false,
    isStatus: false,
  }

  public timeOffFlags = {
    status: true,
    user: false,
    position: false,
  }
  isMeetingOwner = true;
  emailActivity: EmailActivity;
  alloToDeselectEmailAddress = true;
  isEmailAddressEditingEnable = true;

  public isSchedulePopup = false;
  public schedulerList: any[] = [];
  isCompletedActivity: boolean;
  ChannelType = ChannelType;
  ChannelActivityType = ChannelActivityType;

  public isDisabled = false;
  selectedTimeFormat: any;
  symbolPos: any;
  selectedSymbolPos: any;
  decimalPos: any;
  selectedDecimalPos: any;
  tooltipDataSource: string[];
  selectDataOrderPipeOption1 = 'title';
  selectDataOrderPipeOption2 = true;
  @Input() activity: any;
  @Input() fromCalendar: any;
  @Input() samplingAddressList: any;
  @Input() emailActivityParty: any;
  @Input() selectListPopupData: any;
  @Input() orderOption: any;
  @Input() timeFormats: any;
  @Input() symbolPositions: any;
  @Input() decimalPositions: any;
  @Input() scheduledDuration: any;
  @Input() priorityId: any;
  @Input() field: any;
  @Input() popoverTitle: any;
  @Input() tot: any;
  @Input() scheduler: any;
  @Input() reasons: any;
  @Input() mode: any;
  @Input() data: any;
  @Input() insightsPlusSources: any;
  @Input() tooltipData: any;
  @Input() journeyTouchPoints: OptionSet[];
  @Input() contentToken: any;
  @Input() pText: any;
  @Input() moreOptions: any;
  @Input() contactTags:UserTagForContact[];
  @Input() userTags:UserTag[];
  checkIfExternalChannelSelected: boolean;
  channelName: string;
  messageChannel: any;
  channelActivityType: ChannelActivityType;
  public activityLocation: string;
  public activitySubject: string;
  public filterLabelEngagements: string;
  public filterLabelMessages: string;

  public isEventActivitiesEnabled: boolean = false;

  constructor(
    public activityService: ActivityService,
    public meetingDataService: MeetingDataService,
    public authenticationService: AuthenticationService,
    public device: DeviceService,
    public platform: Platform,
    public disk: DiskService,
    public events: Events,
    private readonly alertService: AlertService,
    public uiService: UIService,
    public callPlanService: CallPlanOfflineService,
    public timeOffService: TimeOffService,
    public timeOffDataService: TimeOffDataService,
    private readonly navService: NavigationService,
    private readonly loadingCtrl: LoadingController,
    public repService: RepServices,
    public trackingServcie: TrackService,
    public schedulerService: SchedulerService,
    public shipmentsService: AllocationShipmentService,
    public sampleDataService: SampleDataService,
    public sampleService: SampleService,
    private readonly coachingReportService: CoachingReportService,
    public consentService: ConsentService,
    public productService: BrandOfflineService,
    public emailService: EmailService,
    public contactService: ContactOfflineService,
    private readonly followUpDataService: FollowUpActivityDataService,
    private readonly orderDataService: OrderActivityDataService,
    private readonly caseManagementService: CaseManagementService,
    private readonly caseManagementDataService: CaseManagementDataService,
    public translate: TranslateService,
    public utilityService: GlobalUtilityService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private readonly notificationService: NotificationService,
    public popoverCtrl: PopoverController,
    public meetingService: MeetingDataService,
    public websocket: WebsocketDataService,
    public footerService: FooterService,
    private readonly surgeryOrderDataService: SurgeryOrderActivityDataService,
    private readonly procedureTrackerDataService: ProcedureTrackerActivityDataService,
    public readonly localisationService: LocalizationService,
  ) {
    this.repCallPlanDetailFilter = 'all';
    this.selectedTimeFormat = this.dateTimeFormatsService.selectedTimeFormat;
    this.selectedSymbolPos = this.dateTimeFormatsService.selectedSymbolPos;
    this.selectedDecimalPos = this.dateTimeFormatsService.selectedDecimalPos;
  }

  @HostListener('window:keyboardWillShow', ['$event'])
  window_keyboardWillShow(e) {
    let viewportHeight = this.platform.height() - e.keyboardHeight;
    let appEl = <HTMLElement>(document.getElementsByTagName('ion-popover')[0]);
    let boundingRect = document.activeElement.getBoundingClientRect();
    let elementBottom = boundingRect.top + boundingRect.height;
    let newTop = 0;
    if (viewportHeight < elementBottom) {
      newTop = elementBottom - viewportHeight;
      appEl.style.top = `${-newTop}px`;
    }
  }

  @HostListener('window:keyboardWillHide', ['$event'])
  window_keyboardWillHide(e) {
    let appEl = <HTMLElement>(document.getElementsByTagName('ion-popover')[0]);
    appEl.style.top = '';
  }

  ngOnInit() {
      // Receiving Activity from calendar and make sure it's coming from Calendar
      // by checking 'fromCalendar' property.
      if (this.activity && this.fromCalendar) {
        this.activityForCalendarPopover = this.activity;
      }
      if (this.emailActivityParty) {
        this.emailActivity = this.emailService.selectedActivity;
        this.isEmailAddressEditingEnable = this.emailService.selectedActivity.ownerId != this.authenticationService.user.systemUserID;
        this.isCompletedActivity = (this.emailService.selectedActivity.status != 1);
        if (this.emailActivityParty.emailAddresses.length == 1) {
          this.alloToDeselectEmailAddress = false;
        }
      }
      if (this.selectListPopupData) {
        this.selectListData = this.selectListPopupData;
      }
      if (this.orderOption) {
        this.selectDataOrderPipeOption1 = this.orderOption.orderBy ? this.orderOption.orderBy : this.selectDataOrderPipeOption1;
        this.selectDataOrderPipeOption2 = this.orderOption.ascending === false ? false : true;
      }
      if (this.symbolPositions) {
        this.symbolPos = this.symbolPositions;
      }
      if (this.decimalPositions) {
        this.decimalPos = this.decimalPositions;
      }
      if (this.priorityId) {
        this.priorityID = this.priorityId;
      }

      if (this.field) {
        switch (this.field) {
          case 'text_input': {
            this.textInputType = this.popoverTitle;
            this.popoverView = 'Subject';
            break;
          }
          case 'subject': {
            this.textInput = this.activityService.selectedActivity.subject;
            this.textInputType = this.translate.instant('SUBJECT');
            this.popoverView = 'Subject';
            break;
          }
          case 'LiveTimeURL': {
            this.textInput = this.activityService.selectedActivity.meetingURL;
            this.textInputType = 'LiveTime URL';
            this.popoverView = 'LiveTimeURL';
            break;
          }
          case 'TeamsMeetingUrl': {
            this.textInput = this.activityService.selectedActivity['indskr_teamslink'];
            this.textInputType = this.translate.instant('TEAMS_MEETING_URL');
            this.popoverView = 'TeamsMeetingUrl';
            break;
          }
          case 'emailSubject': {
            this.textInput = this.emailService.selectedActivity.emailSubject;
            this.textInputType = this.translate.instant('SUBJECT');
            this.popoverView = 'EmailSubject';
            break;
          }
          case 'subjectTOT': {
            this.textInput = this.tot.name;
            this.textInputType = this.translate.instant('SUBJECT');
            this.popoverView = 'SubjectTOT';
            break;
          }
          case 'subjectScheduler': {
            this.textInput = this.scheduler.name;
            this.textInputType = 'SubjectScheduler';
            this.popoverView = 'SubjectScheduler';
            break;
          }
          case 'daySelector': {
            this.popoverView = 'daySelector';
            break;
          }
          case 'ReasonListing': {
            this.scrollableListView = true;
            this.popoverView = 'ReasonListing';
            break;
          }
          case 'location': {
            this.textInput = this.activityService.selectedActivity.location;
            this.textInputType = 'Location';
            this.popoverView = 'Location';
            break;
          }
          case 'sentiments': {
            this.popoverView = 'Sentiment';
            break;
          }
          case 'callPlansActivityFilter': {
            this.popoverView = 'CallPlansActivityFilter';
            this.isEventActivitiesEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL);
            this.filterLabelEngagements = this.translate.instant("ENGAGEMENTS");
            this.filterLabelMessages = this.translate.instant("MESSAGES");
            if(this.translate.currentLang == 'es' || this.translate.currentLang == 'it') {
              this.filterLabelEngagements = this.translate.instant("ENGAGEMENTS_CALL_PLAN");
            }
            if(this.translate.currentLang == 'es') {
              this.filterLabelMessages = this.translate.instant("MESSAGES_CALL_PLAN");
            }
            break;
          }
          case 'callPlanFilter': {
            this.popoverView = 'CallPlanFilter';
            break;
          }
          case 'timeOffFilter': {
            this.popoverView = 'TimeOffFilter';
            break;
          }
          case 'calendarMeeting': {
            let activity = this.activityService.selectedActivity;
            this.textInput = ((activity && activity.subject) ? (activity.subject == 'Meeting' ? this.translate.instant('MEETING') : (this.authenticationService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT) && activity.subject == 'Visit') ? this.translate.instant('VISIT') : activity.subject) + '' : '');
            this.textInputType = this.translate.instant('MEETING');
            this.popoverView = 'CalendarMeeting';
            break;
          }
          case 'calendarTimeOff': {
            let timeoffActivity = <TimeOffActivity>this.activityService.selectedActivity;
            this.textInput = (timeoffActivity && timeoffActivity.name) ? (timeoffActivity.name == 'Time Off' ? this.translate.instant('TIME_OFF') : timeoffActivity.name) : this.translate.instant('TIME_OFF');
            this.textInputType = this.translate.instant('TIME_OFF');
            this.popoverView = 'CalendarTimeOff';
            break;
          }
          case 'calendarSampling': {
            let activity = this.activityService.selectedActivity
            this.textInput = activity.subject.includes('Order') ? activity.subject.replace('Order', this.translate.instant('ALLOWCATION_ORDER')): activity.subject;
            this.textInputType = this.translate.instant('ALLOWCATION_ORDER');
            this.popoverView = 'CalendarMeeting';
            break;
          }
          case 'emailActivity': {
            this.textInput = this.activityService.selectedActivity.subject || '';
            let emailDetails = this.activityService.selectedActivity as EmailActivity
            this.getExternalChannel(emailDetails.indskr_channelid);
            this.textInputType = this.messageChannel + (emailDetails.subject == 'Message' ? '' : ' - ' + emailDetails.subject)
            this.activitySubject = _.isEqual(this.activityService.selectedActivity.subject, "Message") ? this.translate.instant('MESSAGE') : this.activityService.selectedActivity.subject;
            this.popoverView = 'CalendarEmail';
            this.isEmailAddressEditingEnable = !(this.activityService.selectedActivity.status != 1 || this.activityService.selectedActivity.ownerId != this.authenticationService.user.systemUserID);
            break;
          }
          case 'followupActivity': {
            let activity = this.activityService.selectedActivity;
            this.textInput = (activity && activity.subject) ? (activity.subject === 'Follow-up Action' ? this.translate.instant('FOLLOW_UP_ACTION') : activity.subject) : this.translate.instant('FOLLOW_UP_ACTION')//this.activityService.selectedActivity.subject || '';
            this.textInputType = this.translate.instant('FOLLOW_UP');
            this.popoverView = 'CalendarMeeting';
            break;
          }
          case 'surgeryOrderActivity': {
            this.textInput = this.activityService.selectedActivity.subject || '';
            this.activitySubject = this.activityService.getActivitysubject(this.activityService.selectedActivity)//this.activityService.selectedActivity.subject;
            this.textInputType = this.translate.instant('NEW_ACTIVITY_PROCEDURE_LOG');
            this.popoverView = 'CalendarMeeting';
            break;
          }
          case 'orderActivity': {
            this.textInput = this.activityService.selectedActivity.subject || '';
            this.activitySubject = this.activityService.getActivitysubject(this.activityService.selectedActivity)
            this.textInputType = this.translate.instant('NEW_ACTIVITY_SALES_ORDER');
            this.popoverView = 'CalendarMeeting';
            break;
          }
          case 'timeOffEvent': {
            this.popoverView = 'timeOffEvent';
            break;
          }
          case 'timePeriod': {
            this.popoverView = 'timePeriod';
            break;
          }
          case 'SchedulerDuration': {
            this.popoverView = 'SchedulerDuration';
            this.schedulerList = this.schedulerService.getArrayValue(this.popoverView);
            break;
          }
          case 'SchedulerPrioritize': {
            this.popoverView = 'SchedulerPrioritize';
            this.schedulerList = this.schedulerService.getArrayValue(this.popoverView);
            break;
          }
          case 'coachingFor': {
            this.textInput = this.coachingReportService.selectedCoachingFor || '';
            this.textInputType = this.translate.instant('COACHING_DETAILS_COACHING_FOR');
            this.popoverView = 'CoachingFor';
            break;
          }
          case 'shipmentsFilter': {
            this.popoverView = 'shipmentsFilter';
            break;
          }
          case 'consentFilter': {
            this.popoverView = 'consentFilter';
            break;
          }
          case 'sampling-address':
            this.popoverView = 'SampleOrderAddresses';
            break;
          case 'global-time-formats':
            this.popoverView = 'TimeSettings';
            break;
          case 'global-symbol-positions':
            this.popoverView = 'SymbolPositions';
            break;
          case 'global-decimal-positions':
            this.popoverView = 'DecimalPositions';
            break;
          case 'thumbs-down-feedback':
            this.popoverView = 'thumbsDownFeedback';
            this.textInputType = 'thumbsDownFeedback';
            this.thumbsDownReasons = this.reasons;
            this.selectedthumbsDownReason = this.thumbsDownReasons[0];
            break;
          case 'customer-insight':
            this.popoverView = 'CustomerInsight';
            const mode: PopoverMode = this.mode;
            this.textInput = this.data;
            if (mode == PopoverMode.FOR_JOURNEY_INSIGHT_DESCRIPTION || mode == PopoverMode.FOR_INTEREST_INSIGHT_DESCRIPTION) {
              this.textInputType = 'Description';
            } else {
              this.textInputType = 'Competitor Product';
            }
            break;
          case 'emailActivityParty':
            this.popoverView = this.emailService.selectedActivity?.isPreviewEnabled ? 'nonNativeChannel' : 'EmailAddresses';
            this.getExternalChannel(this.emailService.selectedActivity.indskr_channelid);
            break;
          case 'select-list-popup':
            this.popoverView = 'SelectListPopup'
            break;
          case 'caseActivity': {
            this.textInputType = 'Case';
            this.popoverView = 'CalendarCase';
            let inquiry = <CaseActivity>this.activityForCalendarPopover;
            this.textInput = this.activityService.selectedActivity['caseName'] || '';
            this.activitySubject = this.activityService.getActivitysubject(this.activityService.selectedActivity)
            let status = String(inquiry._case_status_value).toLowerCase();
            /* status is not open or pending sync,, then disabled the button */
            this.isDisabled = !(status.includes('open') || status.includes('pending sync'));
            break;
          }
          case 'XperienceInsightsPlusSources':
            this.popoverView = 'InsightsPlusSources'
            break;

            case 'tooltip': {
              this.popoverView = 'tooltip'
              this.tooltipDataSource = this.tooltipData
              break;

            }
            case 'phoneActivity': {
              this.textInput = this.activityService.selectedActivity.subject || '';
              this.textInputType = this.translate.instant('NEW_ACTIVITY_PHONECALL');
              this.popoverView = 'phoneActivity';
              break;
            }
          case 'journey-touchpoints': {
            this.popoverView = 'touchPoints';
            break;
          }
          case 'editContentToken': {
            this.textInput = this.pText ? this.pText : this.contentToken.indskr_name ;
            this.textInputType = 'Edit';
            this.popoverView = 'editContentToken';
            break;
          }
          case 'calendarInviteEmailTooltip':
            this.popoverView = 'CalendarInviteEmailTooltip';
            break;

          case 'CustomerAssessTooltip':
            this.popoverView = 'CustomerAssessTooltip';
            break;

          case 'more-options':
            this.popoverView = 'more-options'
            this.textInputType = this.translate.instant('MORE_OPTIONS');
            break;

          case 'contact_tags':
              this.popoverView = 'contact_tags'
              this.textInputType = this.translate.instant('SELECT_TAGS');
              break;
          case 'user_tags':
              this.popoverView = 'user_tags'
              this.textInputType = this.translate.instant('SELECT_TAGS');
              break;
          case 'presentation_tags':
              this.popoverView = 'presentation_tags'
              this.textInputType = this.translate.instant('SELECT_TAGS');
              break;
          case 'resource_tag':
              this.popoverView = 'resource_tag'
              this.textInputType = this.translate.instant('SELECT_TAGS');
              break;

          default: {
            break;
          }
        }
      }
    if (
      (this.activityForCalendarPopover && this.activityForCalendarPopover instanceof AppointmentActivity)
      &&
      (this.activityForCalendarPopover['isJointmeeting'] || (this.activityForCalendarPopover['accompaniedUserList'] && this.activityService.selectedActivity['accompaniedUserList'].length > 0))
    ) {

      let meetingOwnerId = this.activityService.selectedActivity['meetingOwnerId'];
      let userId = this.authenticationService.user.systemUserID;
      if (meetingOwnerId != userId) {
        this.isMeetingOwner = false;
      }
      else {
        this.isMeetingOwner = true;
      }
    } else if (this.activityForCalendarPopover && this.activityForCalendarPopover instanceof EmailActivity) {
      if (this.activityService.selectedActivity.ownerId === this.authenticationService.user.systemUserID) {
        this.isMeetingOwner = true;
      } else {
        this.isMeetingOwner = false;
      }
    } else if ((this.activityForCalendarPopover && this.activityForCalendarPopover instanceof PhoneActivity)
      && (this.activityForCalendarPopover.jointphonecall || !_.isEmpty(this.activityForCalendarPopover['accompaniedUserList']))) {
      this.isMeetingOwner = (this.authenticationService.user.systemUserID === this.activityService.selectedActivity['meetingOwnerId']);
    }
    if(!this.consentService.consentChannelFilter$ && !this.consentService.consentProductFilter$){
      this.consentService.showAllConsentsFilter = 'All';
    }
  }

  getExternalChannel(channelId: string) {
    if (channelId != undefined || channelId != "") {
      const cha: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === channelId);
      if (cha != undefined) {
        this.emailActivity = this.emailService.selectedActivity as EmailActivity;
        if (this.emailService.selectedActivity) {
          this.channelActivityType = cha.activityType;
          if (cha.indskr_externalchannel === true) {
            this.checkIfExternalChannelSelected = true
            this.channelName = String(cha.indskr_consentType);
            this.messageChannel = this.channelName? this.channelName : this.translate.instant('MESSAGE');
            this.emailService.isMessageExternal = true;
          } else {
            this.checkIfExternalChannelSelected = false
            this.emailService.isMessageExternal = false;
            this.messageChannel = this.channelName? this.channelName : this.translate.instant('MESSAGE');
          }
        } else { // reset everything to false.
          this.messageChannel = this.channelName? this.channelName : this.translate.instant('MESSAGE');
          this.checkIfExternalChannelSelected = false
          this.emailService.isMessageExternal = false;
        }
      }
    }
  }

  selectThumbsDownReason(option: OptionSet) {
    this.selectedthumbsDownReason = option;
    // let content: HTMLElement = document.getElementsByClassName('popover-content').item(0) as HTMLElement;
    // content.style.height = this.selectedthumbsDownReason.value == 'OTHER' ? '300px' : '225px';
  }

  setCallPlanFilter() {
    console.log('I wonder');
  }

  public get checkCustomScrapCondition(): boolean {
    let flag = true;
    if (this.activityForCalendarPopover && this.activityForCalendarPopover.type == ActivityType.FollowUp && this.activityForCalendarPopover.ownerId != this.authenticationService.user.systemUserID) {
      flag = false;
    } else if (this.activityForCalendarPopover && this.activityForCalendarPopover.type == ActivityType.Order) {
      flag = false;
      if(this.activityForCalendarPopover.ownerId == this.authenticationService.user.systemUserID && (this.activityForCalendarPopover as OrderActivity).orderStatusString == 'Draft'){
        flag = true;
      }
    }else if(this.activityForCalendarPopover && this.activityForCalendarPopover.type == ActivityType.SurgeryOrder
      && (this.activityForCalendarPopover as SurgeryOrderActivity).status === 548910001
      && this.activityService.selectedActivity.ownerId == this.authenticationService.user.systemUserID) {
        return false;
    }else if(this.activityForCalendarPopover && this.activityForCalendarPopover.type == ActivityType.ProcedureTracker
      && (this.activityForCalendarPopover as ProcedureTrackerActivity).status === 548910001
      && this.activityService.selectedActivity.ownerId == this.authenticationService.user.systemUserID) {
        return false;
    }
    return flag;
  }

  async onDismiss(isDone: boolean) {
    if(this.field == 'text_input'){
      this.popoverCtrl.dismiss({done: isDone,value:this.textInput});
      return;
    }
    if (isDone) {
      if (this.activityService.selectedActivity && this.activityService.selectedActivity instanceof AppointmentActivity) {
        this.textInput = (this.textInput.length > 0) ? this.textInput : 'Meeting';
        this.trackingServcie.tracking('MeetingSubjectUpdate', TrackingEventNames.ACTIVITY)
        let currentAppointmentActivity: AppointmentActivity = this.activityService.selectedActivity;

        let payload = new UpdateMeetingPayload(
          currentAppointmentActivity.subject,
          currentAppointmentActivity.location,
          currentAppointmentActivity.scheduledStart,
          currentAppointmentActivity.scheduledEnd
        );

        this.showLoader = true;

        switch (this.textInputType) {
          case 'Subject':
            payload.subject = this.textInput;
            this.activityService.selectedActivity.subject = this.textInput;
            this.activityService.getActivityByID(this.activityService.selectedActivity.ID).subject = this.textInput;
            break;

          case 'Location':
            payload.location = this.textInput;
            this.activityService.selectedActivity.location = this.textInput;
            this.activityService.getActivityByID(this.activityService.selectedActivity.ID).location = this.textInput;
            break;

          default: console.error('onDismiss: Unhandled switch case for textinpittype', this.textInputType);
        }

        // this.activityService.isActivityUpdate = true;

        if (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
          await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
          this.popoverCtrl.dismiss(this.textInput); // send the textInput in general when ever requested
        } else {
          try {
            await this.meetingDataService.updateMeeting(this.activityService.selectedActivity, payload);
          } catch (error) {
            console.error('Popover: onDismiss: ', error);
            this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity, false, true);
          }
        }
        this.popoverCtrl.dismiss(this.textInput);
      } else if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.FollowUp) {
        this.textInput = (this.textInput.length > 0) ? this.textInput : 'Follow-up Action';
        this.popoverCtrl.dismiss({ followUpSubject: this.textInput });
      } else if (this.popoverView == 'CustomerInsight') {
        this.popoverCtrl.dismiss({done: isDone, value: this.textInput});
      } else if (this.popoverView == 'thumbsDownFeedback') {
        let reason: OptionSet = cloneDeep(this.selectedthumbsDownReason);
        if (reason.value == 'OTHER') {
          reason.label = this.textInput;
        }
        this.popoverCtrl.dismiss({done: isDone, value: reason});
      } else {
        this.popoverCtrl.dismiss({done: isDone,value:this.textInput}); // send the textInput in general when ever requested
      }
    }
    else {
      if (this.textInputType === 'SubjectScheduler') {
        this.textInput = undefined;
      }
      this.popoverCtrl.dismiss();
    }
  }

  async onEmailDismiss(isDone: boolean) {
    if (isDone) {
      this.trackingServcie.tracking('EmailSubjectUpdated', TrackingEventNames.EMAIL);
      const emailSubject: string = (this.textInput.length > 0) ? this.textInput : 'Message';
      this.emailService.updateEmailActivity({ subject: emailSubject }, EmailActionType.SUBJECT);
    }
    this.popoverCtrl.dismiss();
  }

  async onDismissTot(isDone: boolean) {
    this.textInput = (this.textInput.length > 0) ? this.textInput : 'Time Off';
    //canceled subject
    if (!isDone) {
      this.textInput = this.activityService.selectedActivity['name'];
      this.textInput = (this.textInput.length > 0) ? this.textInput : 'Time Off';
    }
    this.popoverCtrl.dismiss(this.textInput);
  }

  onEventSelection(selectedVal: string) {
    this.timeOffService.popupSelectedEvent = selectedVal;
    this.trackingServcie.tracking('TimeOffUpdateDay', TrackingEventNames.TIMEOFF)
    this.popoverCtrl.dismiss(this.timeOffService.popupSelectedEvent);
  }

  onEventPeriodSelection(selectedVal: string) {
    this.timeOffService.popupSelectedTimePeriodEvent = selectedVal;
    this.popoverCtrl.dismiss(this.timeOffService.popupSelectedTimePeriodEvent);
  }

  onSchedulerDuration(selectedDuration: string) {
    this.popoverCtrl.dismiss(selectedDuration);
  }

  onSchedulerPrioritize(selectedPriority) {
    const priority = this.schedulerService.priorityList.find(e => e.id === selectedPriority);
    this.popoverCtrl.dismiss(priority);
  }

  reasonSelectedEvent(reason) {
    this.timeOffService.reasonSelectedEvent = reason.reason;
    this.popoverCtrl.dismiss(reason);
  }

  selectSentiment(sentiment: string) {
    this.popoverCtrl.dismiss(sentiment);
  }

  public closePopup() {
    this.popoverCtrl.dismiss();
    this.schedulerList = [];
  }

  expandList(item) {
    switch (item) {
      case 'callPlan:completion':
        this.callPlanFlags.isCompletion = !this.callPlanFlags.isCompletion;
        break;

      case 'callPlan:status':
        this.callPlanFlags.isStatus = !this.callPlanFlags.isStatus;
        break;

      case 'callPlan:position':
        this.callPlanFlags.isPosition = !this.callPlanFlags.isPosition;
        break;

      case 'timeOff:status':
        this.timeOffFlags.status = !this.timeOffFlags.status;
        break;

      case 'timeOff:user':
        this.timeOffFlags.user = !this.timeOffFlags.user;
        break;

      case 'timeOff:position':
        this.timeOffFlags.position = !this.timeOffFlags.position;
        break;
      case 'status':
        this.isStatus = !this.isStatus;
        break;
      case 'position':
        this.isPosition = !this.isPosition;
        break;
      case 'shipment:product':
        this.shipmentsService.isProductFilterExpanded = !this.shipmentsService.isProductFilterExpanded;
        break;
      case 'shipment:status':
        this.shipmentsService.isStatusFilterExpanded = !this.shipmentsService.isStatusFilterExpanded;
        break;
      case 'consent:product':
        this.consentService.isProductFilterExpanded = !this.consentService.isProductFilterExpanded;
        break;
      case 'consent:channel':
        this.consentService.isChannelFilterExpanded = !this.consentService.isChannelFilterExpanded;
        break;
    }
  }

  async goToActivityDetails() {
    if (this.activityForCalendarPopover) {
      this.navService.popToRootWithPageTracking().then(() => {
        this.activityService.selectedActivityAtHome = this.activityService.selectedActivity = this.activityForCalendarPopover;
        this.events.publish('activity-pane:selectActivity', this.activityService.selectedActivity);
      })
    }
    this.uiService.activitiesPageTab = 'agenda';
    this.popoverCtrl.dismiss();
  }


  scrapMeeting() {
    if (!this.activityForCalendarPopover || this.activityForCalendarPopover.isCompleted || !this.isMeetingOwner || !this.checkCustomScrapCondition) return;
    else if (this.activityForCalendarPopover.type === 'PhoneCall'){
      /**
       * To scrap the phone call activity.
       */
      this.events.publish('deleteMeeting')
      /**
       *reason for refresh agenda subscription...
        we will publish the refresh agenda, when we scrap the phone call
       so that we can dismiss the viewCtrl by subscribing it.
       */
      this.events.subscribe('phonecall:refresh', () => {
        let id = this.activityForCalendarPopover.ID;
        this.popoverCtrl.dismiss(id);
      });
    }
    else {
      let popupTitle: string;
      let popupAction = this.translate.instant('SCRAP');
      switch (this.activityForCalendarPopover.type) {
        case ActivityType.Sample:
          popupTitle = this.translate.instant('SCRAP_ALLOCATION_ORDER');
          break;
        case ActivityType.Email:
          popupTitle = this.translate.instant('EMAIL_ACTIVITY_ALERT_TITLE_SCRAP_MESSGE');
          break;
        case ActivityType.FollowUp:
          popupTitle = this.translate.instant('SCRAP_FOLLOW_UP');
          break;
        case ActivityType.Order:
          popupTitle = this.translate.instant('SCRAP_ORDER');
          break;
        case ActivityType.SurgeryOrder:
          popupTitle = this.translate.instant('OM_SCRAP_LOG');
          break;
        //@ts-ignore
        case ActivityType.PhoneCall:
          popupTitle = this.translate.instant('SCRAP_PHONE_CALL');
          break;
        default:
          popupTitle = this.translate.instant('SCRAP_MEETING');
          break;
      }


      this.alertService.showAlert({
        title: popupTitle,
        message: this.getAlertMessage(this.activityForCalendarPopover)}, popupAction
      ).then (res => {
        if(res.role == 'ok') {
          if (this.activityForCalendarPopover.type == ActivityType.Sample) {
            let activity = <SampleActivity>this.activityForCalendarPopover
            let payload: CreateSampleDropRequestBody = new CreateSampleDropRequestBody(activity);
            payload.statecode = 2;
            payload.statuscode = 3;
            (activity as SampleActivity).state = 2;
            (activity as SampleActivity).status = 3;
            this.sampleDataService.updateSampleActivity(payload, activity).then(() => {
              if (this.activityService.selectedActivity && activity && this.activityService.selectedActivity.ID === activity.ID) {
                this.activityService.selectedActivity = undefined;
                this.activityService.selectedActivityOnAgenda = undefined;
                this.uiService.activeView = undefined;
              }
            }).catch(() => {
            })
            this.popoverCtrl.dismiss(activity.ID);
          } else if (this.activityForCalendarPopover.type == ActivityType.Email) {
            let activity = <EmailActivity>this.activityForCalendarPopover;
            this.emailService.scrapEmailActivity(activity).then(() => {
              this.activityService.removeActivity(activity);
                this.uiService.agendaRefreshRequired = true;

              if (this.activityService.selectedActivity && activity && this.activityService.selectedActivity.ID === activity.ID) {
                this.activityService.selectedActivity = undefined;
                this.activityService.selectedActivityOnAgenda = undefined;
                this.uiService.activeView = undefined;
              }
            }).catch(() => {
            });
            this.popoverCtrl.dismiss(activity.ID);
          }
          else if (this.activityForCalendarPopover.type == ActivityType.FollowUp) {
            let id = this.activityForCalendarPopover.ID;
            (<FollowUpActivity>this.activityForCalendarPopover).pendingPushToDynamics = true;
            this.followUpDataService.scrapFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true }, (<FollowUpActivity>this.activityForCalendarPopover)).then(async succ => {
              this.events.publish('followupActionDeleted', this.activityForCalendarPopover);
              this.popoverCtrl.dismiss(id);
            }).catch(err => {
              //Handle error scenario
            })
          } else if (this.activityForCalendarPopover.type == ActivityType.Order) {
            let id = this.activityForCalendarPopover.ID;
            (<OrderActivity>this.activityForCalendarPopover).pendingPushToDynamics = true;
            this.orderDataService.updateOrderActivityStatus({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true, operationDetail: { code: 'scraporderactivity', message: 'Scrap Order' } }, (<OrderActivity>this.activityForCalendarPopover)).then(async succ => {
              this.popoverCtrl.dismiss(id);
            }).catch(err => {
              //Handle error scenario
            })
          } else if (this.activityForCalendarPopover.type == ActivityType.SurgeryOrder) {
            let id = this.activityForCalendarPopover.ID;
            (<SurgeryOrderActivity>this.activityForCalendarPopover).pendingPushToDynamics = true;
            this.events.publish('surgeryOrderActivityDeleted', <SurgeryOrderActivity>this.activityForCalendarPopover);
            this.surgeryOrderDataService.updateOrderActivityStatus({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true, operationDetail: { code: 'scrapsurgeryorderactivity', message: this.translate.instant('OM_UPDATE_O_ACTIVITY') } }, (<SurgeryOrderActivity>this.activityForCalendarPopover)).then(async succ => {
              this.popoverCtrl.dismiss(id);
            }).catch(err => {
              //Handle error scenario
            })
          } else if (this.activityForCalendarPopover.type == ActivityType.ProcedureTracker) {
            let id = this.activityForCalendarPopover.ID;
            (<ProcedureTrackerActivity>this.activityForCalendarPopover).pendingPushToDynamics = true;
            this.events.publish('procedureTrackerActivityDeleted', <ProcedureTrackerActivity>this.activityForCalendarPopover);
            this.procedureTrackerDataService.updateOrderActivityStatus({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true, operationDetail: { code: 'scrapproceduretrackeractivity', message: this.translate.instant('OM_UPDATE_O_ACTIVITY') } }, (<ProcedureTrackerActivity>this.activityForCalendarPopover)).then(async succ => {
              this.popoverCtrl.dismiss(id);
            }).catch(err => {
              //Handle error scenario
            })
          } else {
            let activity = <AppointmentActivity>this.activityForCalendarPopover
            let scrapStatus: ActivityScrapStatus = (this.activityService.getActivityScrapStatus(activity))[activity.ID] ?? undefined;
            if(scrapStatus && scrapStatus == ActivityScrapStatus.HASCOMPLETEDINMEETINGALLOCATIONS){
              this.notificationService.notify(this.translate.instant('DRAFT_MEETING_HAS_COMPLETED_ALLOCATIONS_SCRAP_MESSAGE'),'ActivityScrap');
            }
            else if (scrapStatus && scrapStatus == ActivityScrapStatus.HASCOMPLETEDINMEETINGFOLLOWUPS) {
              this.notificationService.notify(this.translate.instant('DRAFT_MEETING_HAS_COMPLETED_FOLLOWUP_SCRAP_MESSAGE'), 'ActivityScrap');
            }
            if (this.activityService.selectedActivity) {
              // this.websocket.sendRemoteMeetingEnd(this.activityService.selectedActivity, true);
              // this.websocket.removeSubscription(this.activityService.selectedActivity);
              // this.websocket.meetingInProgress = false;
            }
            this.meetingDataService.updateMeetingStatus(activity, MeetingStatus.CANCELED)
              .then(() => {
                this.popoverCtrl.dismiss(activity.ID);
                this.activityService.removeActivity(activity);
                this.events.publish('phonecall:refresh');
                this.uiService.agendaRefreshRequired = true;
                this.activityService.publishActivityEvent({action: "Delete", activity: activity});
                if (this.uiService.activitiesPageTab === 'day') {
                  this.events.publish('activityDeleted');
                }
                // Deleted.
                // Now we need to pop out if we pushed the detail pane
                this.activityService.selectedActivity = undefined;
                if (this.activityService.selectedActivityOnAgenda && activity && this.activityService.selectedActivityOnAgenda.ID === activity.ID) {
                  this.activityService.selectedActivityOnAgenda = undefined;
                }
              })
              .catch((err) => {
                this.popoverCtrl.dismiss('');
                // this.notificationService.notify("Meeting scrap failed",'Activities List','top',ToastStyle.DANGER);
              });
            return;
          }

        }
      });
    }

  }

  private getAlertMessage(activity: any): string {
    let message: string;
    switch (activity.type) {
      case ActivityType.Sample:
        message = this.translate.instant('POP_R_U_SURE_SCRAP_ALLOC_O');
        break;
      case ActivityType.Email:
        message = this.translate.instant('R_U_SURE_SCRAP_MESG');
        break;
      case ActivityType.FollowUp:
        message = this.translate.instant('POP_R_U_SURE_SCRAP_FOLLOW_UP');
        break;
      case ActivityType.Order:
        message = this.translate.instant('R_U_SURE_SCRAP_O');
        break;
      case ActivityType.SurgeryOrder:
        message = this.translate.instant('R_U_SURE_SCRAP_SO');
        break;
      case ActivityType.PhoneCall:
        message = this.translate.instant('R_U_SURE_SCRAP_PHONE');
        break;
      default:
        message = this.translate.instant('POP_R_U_SURE_SCRAP_MEETING');
        break;
    }
    return message;
  }

  scrapTimeOff() {
    if (!this.activityForCalendarPopover) return;
    let timeOffActivity: TimeOffActivity = <TimeOffActivity>this.activityForCalendarPopover;
    if (timeOffActivity.statuscode != 100000000) return;
    this.trackingServcie.tracking('TimeOffScrapCalendar', TrackingEventNames.TIMEOFF);

    let cancelBtnText = this.localisationService.selectedLanguage.code == "ja"  ? this.translate.instant('CANCEL_BTN_ALERT') : null;

    this.alertService.showAlert({
      title: this.translate.instant('POP_SCRAP_TIME_OFF'),
      message: this.translate.instant('POP_R_U_SURE_SCRAP_TIME_OFF')}, this.translate.instant('SCRAP'), cancelBtnText
    ).then (async res => {
      if(res.role == 'ok') {
        const loader = await  this.loadingCtrl.create();
        loader.present();

        if (!this.device.isOffline) {
          this.timeOffDataService.deleteTimeOff(timeOffActivity.timeOffRequestId).then(response => {
            this.timeOffService.setSelectedTot(null);
            this.activityService.selectedActivity = null;
            this.timeOffService.delete(true, timeOffActivity);
            this.activityService.removeActivity(this.activityForCalendarPopover);
            this.popoverCtrl.dismiss(this.activityForCalendarPopover.ID);
          }).catch((err) => {
            // this.notificationService.notify("Time off scrap failed",'Popover','top',ToastStyle.DANGER);
          });;
        }
        else {
          this.timeOffService.setSelectedTot(null);
          this.activityService.selectedActivity = null;
          this.timeOffService.delete(false, timeOffActivity);
          this.activityService.removeActivity(this.activityForCalendarPopover);
          this.popoverCtrl.dismiss(this.activityForCalendarPopover.ID);
        }
        loader.dismiss();

      }
    });
  }

  scrapCustomerInquiry() {
    console.log(this.activityForCalendarPopover);
    let inquiry: CaseActivity = <CaseActivity>this.activityForCalendarPopover;

    this.alertService.showAlert({
      title: this.translate.instant('POP_SCRAP_INQUIRY'),
      message: this.translate.instant('POP_R_U_SURE_SCRAP_SELECTED_INQUIRY')}, this.translate.instant('SCRAP')
    ).then (res => {
      if(res.role == 'ok') {
        this.trackingServcie.tracking('InquiryActivityScrapActionBar', TrackingEventNames.CUSTOMER_INQUIRY);
        this.caseManagementService.scrapCustomerInquiry(inquiry);
        this.popoverCtrl.dismiss(this.activityForCalendarPopover.ID);
      }
    });
  }

  onSettingAllShipmentsFilter() {
    this.shipmentsService.clearFilters();
  }

  onSettingAllConsentsFilter() {
    this.consentService.clearFilters();
  }

  onSettingConsentProductFilter(product: Brand) {
    if (product) {
      this.consentService.showAllConsentsFilter = '';
      if (this.consentService.consentProductFilter$ == product) {
        this.consentService.consentProductFilter$ = null;
        if (this.consentService.consentChannelFilter$) {
          this.consentService.showAllConsentsFilter$.next(false);
        } else {
          this.consentService.showAllConsentsFilter = 'All';
          this.consentService.showAllConsentsFilter$.next(true);
        }
      } else {
        this.consentService.consentProductFilter$ = product;
        this.consentService.showAllConsentsFilter$.next(false);
      }
    }
  }

  onSettingConsentChannelFilter(channel: Channel) {
    if (channel) {
      this.consentService.showAllConsentsFilter = '';
      if (this.consentService.consentChannelFilter$ == channel) {
        this.consentService.consentChannelFilter$ = null;
        if (this.consentService.consentProductFilter$) {
          this.consentService.showAllConsentsFilter$.next(false);
        } else {
          this.consentService.showAllConsentsFilter = 'All';
          this.consentService.showAllConsentsFilter$.next(true);
        }
      } else {
        this.consentService.consentChannelFilter$ = channel;
        this.consentService.showAllConsentsFilter$.next(false);
      }
    }
  }

  async removeEmailAddress(index) {
    const loader = await this.loadingCtrl.create();
    loader.present();
    let emailAddress: EmailAddress = this.emailActivityParty.emailAddresses.splice(index, 1)[0];
    this.contactService.deselectContactEmailAddress(this.emailActivityParty.indskr_contactid, emailAddress.email_addressid);
    let emailActivityParties: EmailActivityParty[] = this.emailService.selectedActivity.emailActivityParties;
    emailActivityParties[emailActivityParties.indexOf(this.emailActivityParty)].emailAddresses = this.emailActivityParty.emailAddresses;
    this.emailService.updateEmailActivityParties(emailActivityParties).then(() => {
      loader.dismiss();
    }).catch(() => loader.dismiss());
  }

  setAddressForSampleOrder(address: ContactAddress) {
    let activity
    if (this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING ||
      this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_EVENTS
      || this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION) {
      activity = this.sampleService.inMeetingAllocationActivity
    }
    else {
      activity = this.activityService.selectedActivity as SampleActivity
    }
    activity.addressID = address.customerAddressID;
    activity.location = address.compositeAdd;
    let payload: CreateSampleDropRequestBody = new CreateSampleDropRequestBody(activity)
    this.sampleDataService.updateSampleActivity(payload, activity).then(() => {
      this.popoverCtrl.dismiss({ 'contactAddress': address });
    }, (error) => {
      this.popoverCtrl.dismiss();
    });
  }

  public selectedAddress(address) {
    let activity
    if (this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING ||
      this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_EVENTS
      || this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION) {
      activity = this.sampleService.inMeetingAllocationActivity
    }
    else {
      activity = this.activityService.selectedActivity as SampleActivity
    }
    let isSelected = false;
    if(address.customerAddressID === activity.addressID) {
      isSelected =true;
    }
     return isSelected;
  }

  setTimeFormat(tF: TimeFormat) {
    this.popoverCtrl.dismiss({ 'timeFormat': tF });
  }

  setSymbolPos(sPos: SymbolPosition) {
    this.popoverCtrl.dismiss( {'symbolPos': sPos });
  }

  setDecimalPos(dPos: DecimalPosition) {
    this.popoverCtrl.dismiss( {'decimalPos': dPos });
  }

  public setSelectedItemForSelectList(ev: any , item: SelectListData) {
    if(ev) ev.stopPropagation();
    this.selectListData = this.selectListData.map(value => {
      value.isSelected = false;
      if(value.id == item.id){
        value.isSelected = true;
      }
      return value;
    });
    if (this.selectEvent) this.selectEvent(item);
    else this.popoverCtrl.dismiss({ selectedItem: item });
  }

  public getActivitySecondaryTextStringForFollowup(activity: FollowUpActivity): string {
    if (activity) {
      return this.followUpDataService.getActivitySecondaryTextStringForFollowup(activity);
    }
  }

  public getActivitySecondaryTextStringForSurgeryOrder(activity: SurgeryOrderActivity): string {
    if (activity) {
      if(activity.indskr_noprocedureday){
        return this.translate.instant("NO_PROCEDURE_DAY");
      }
      let accountName = activity.accountNameString ? activity.accountNameString : this.translate.instant('NO_ACCOUNT');
      return accountName;
    }
  }

  public getActivitySecondaryTextStringForProcedureTracker(activity: ProcedureTrackerActivity): string {
    return activity.accountNameString ? activity.accountNameString : this.translate.instant('NO_ACCOUNT');
  }

  public getCaseState():string{

    let caseState = '';

    if (this.activityService.selectedActivity['state'] === 1) {
      caseState = this.translate.instant('POP_RESOLVE');
    } else if (this.activityService.selectedActivity['state'] === 2) {
      caseState = this.translate.instant('CANCELLED');
    } else {

      switch ((<CaseActivity>this.activityService.selectedActivity)._case_stage_value) {
        case 'Open':
          caseState = this.translate.instant('OPEN');
          break;
        case 'Approved':
          caseState = this.translate.instant('APPROVE');
          break;
        case 'For Review':
          caseState = this.translate.instant('FOR_REVIEW');
          break;
        case 'In Progress':
          caseState = this.translate.instant('IN_PROGRESS');
          break;
        case 'Intake':
          caseState = this.translate.instant('INTAKE');
          break;
        default:
          caseState = caseState;
          break;
      }
    }
        return caseState;
}

  moreOptionSelection(option) {
    this.popoverCtrl.dismiss(option);
  }
  contactTagSelection(contactTag: UserTagForContact) {
    this.popoverCtrl.dismiss(contactTag);
  }
  userTagSelection(userTag: UserTag) {
    this.popoverCtrl.dismiss(userTag);
  }
  presTagSelection(userTag: UserTag) {
    this.popoverCtrl.dismiss(userTag);
  }
  resourceTagSelection(userTag: UserTag) {
    this.popoverCtrl.dismiss(userTag);
  }
public getLocalisedAndFormattedTime(value: Date) {
  return (new Date(value)).toLocaleTimeString(this.translate.currentLang, { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' })

}

  ngOnDestroy() {
  }
}

export interface SelectListData {
  id: string;
  title: string;
  isSelected?: boolean;
  value?: number;
  isReadOnly?: boolean;
  isForcehide? : boolean;
  ratingId? : string;
}
