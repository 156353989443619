import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { ActivityService } from '@omni/services/activity/activity.service';
import { EventsService } from '@omni/services/events/events.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import { MarketingPlansManagementOfflineService } from "@omni/services/marketing-management/marketing-management.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MarketingPlan } from '@omni/classes/marketing-management/marketing-plan.class';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { Account } from '@omni/classes/account/account.class';
import { DeviceService } from '@omni/services/device/device.service';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { IndKeyControlsAreaModel } from '@omni/models/indKeyControlsAreaModel';
import { format, isFuture } from 'date-fns';
import { MarketingPlanManagementDataService } from '@omni/data-services/marketing-plan/marketing-plan-management.data.service';
import _ from 'lodash';
import { AuthenticationService } from '@omni/services/authentication.service';
import { isPast } from 'date-fns';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { NavParams, PopoverController } from '@ionic/angular';
import { MarketingPlanSelectionViewDataModel } from '@omni/models/marketing-plan-selection-page-model';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';
@Component({
  selector: 'marketing-plan-list',
  templateUrl: 'marketing-plan-list.html',
  styleUrls: ['marketing-plan-list.scss']
})
export class MarketingPlanList implements OnInit {
  @Input() accountListAccessedFrom: string;
  @Output() selectedMarketingPlan = new EventEmitter<any>();
  @Input() positionfilter : boolean = false;

  private rawPlanData: any;
  private searchedData: any;
  private allAccountsForMarketingPlans: Account[];

  indHeaderLeftModel: IndHeaderLeftDataModel;
  ngUnSubscribe$ = new Subject<boolean>();
  plansData: any;
  selectedPlanId: any;
  tabs: IndTabsDataModel[] = [];
  filterPopoverData: any[];
  isNew = false;
  private from: PageName;

  public hideFilterButton: boolean = true;
  public selectedAccount: Account;
  public isSearched: boolean = false;
  public accountPlansFilter = { status: "", account: [] };
  public accountFilterList: any[] = []
  public fromPlanTab: boolean = false;
  public searchInput = '';
  public selectedItems: Array<MainCardViewDataModel> = [];
  public selectedHeader: IndSectionHeaderViewDataModel;
  public isReadOnlyMode: boolean = false;
  public viewData: MarketingPlanSelectionViewDataModel;
  public marketPlanKeyControlAreaModel: IndKeyControlsAreaModel;
  private _isDirty: boolean = false;
  private isMBPfieldsEnabled: boolean = false; //FA will be added

  constructor(
    public device: DeviceService,
    public uiService: UIService,
    private accountService: AccountOfflineService,
    public events: EventsService,
    public footerService: FooterService,
    public readonly translate: TranslateService,
    private readonly navService: NavigationService,
    public activityService: ActivityService,
    public authService: AuthenticationService,
    private popoverCtrl: PopoverController,
    public marketManagementDataService: MarketingPlanManagementDataService,
    public marketingPlansManagementOfflineService: MarketingPlansManagementOfflineService,
    private navParams: NavParams,
    private agendaFooterService: AgendaFooterService,
    private faService: FeatureActionsService,
  ) {
    if (this.navParams.data && this.navParams.data.viewData) {
      this.viewData = this.navParams.data.viewData;
      this.from = this.navParams.data.from;
      this.isReadOnlyMode = this.viewData.isReadOnlyMode;
      this.positionfilter = this.viewData.positionfilter;
      this._initSelectedViewItems();
    }
  }

  ngOnInit() {
    if (this.marketingPlansManagementOfflineService.marketingPlans) {
      this.initializeMarketingBusinessPlan(this.marketingPlansManagementOfflineService.marketingPlans);
    }

    this.marketingPlansManagementOfflineService.marketingPlans$.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((data) => {
      if (data && data.length) this.initializeMarketingBusinessPlan(data);
    });

    this.events.observe("market-plan-update").pipe(takeUntil(this.ngUnSubscribe$))
      .subscribe(async (marketingBuninessPlan: MarketingPlan) => {
        const index = this.plansData.findIndex(o => o.ID == marketingBuninessPlan.ID);
        this.plansData[index] = marketingBuninessPlan;
      });

    // this.marketingPlansManagementOfflineService.selectedMarketingPlan$.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((selectedMarketPlan) => {
    //   if (selectedMarketPlan) this.openMarketingPlan(selectedMarketPlan);
    // });

    this.device.isOfflineObservable.pipe(takeUntil(this.ngUnSubscribe$)).subscribe(() => {
      this.tabs = this.uiService.getAgendaTabs();
      this._initKeyControlsAreaModel();
    });

    // this.isMBPfieldsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MBP_FIELDS);

    this.initMarketingPlansHeaderLeft();
    this._initKeyControlsAreaModel();
  }

  private _initSelectedViewItems() {
    if (this.viewData.selectedMarketingPlans) {
      this.viewData.selectedMarketingPlans = _.orderBy(this.viewData.selectedMarketingPlans, 'brandPlanName');
      let selectedIds = this.viewData.selectedMarketingPlans.map(mbp => mbp.ID);
      selectedIds.forEach((eventId) => {
        const index = this.marketingPlansManagementOfflineService.marketingPlans.findIndex(event => event.ID === eventId);
        const foundItem = index > -1 ? this.marketingPlansManagementOfflineService.marketingPlans[index] : this.viewData.selectedMarketingPlans.find(event => event.ID === eventId);
        if (foundItem) {
          const numberOfAccounts = foundItem.accounts.length;
          const numberOfProducts = foundItem.products.length;
          this.selectedItems.push({
            id: foundItem.ID,
            primaryTextLeft: !_.isEmpty(foundItem.accounts) ? foundItem.accounts[0].name + (numberOfAccounts > 1 ? ` +${numberOfAccounts - 1}` : '') : this.translate.instant('NO_ACCOUNTS'),
            primaryTextRight: foundItem.brandPlanName,
            secondaryTextRight: numberOfProducts > 0 ? foundItem.products[0]?.productName + (numberOfProducts > 1 ? ` +${numberOfProducts - 1}` : '') : '',
            isSelected: this.selectedPlanId == foundItem.ID,
            showEndIcon: !this.isReadOnlyMode,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: true,
            endIconType: 'indegene-selectors-remove-icon',
            endIconCssClass: 'remove-icon',
            showArrow: false,
            arrowType: '',
            clickHandler: async (id: string, event, eventName, refData) => this.handleMarketingPlanItemClick(id, event, eventName, refData)
          })
        }
      });
      this._initSelectedHeader();
    }
  }

  private _initSelectedHeader() {
    this.selectedHeader = {
      id: 'selected-header',
      title: this.translate.instant('SELECTED') + ' (' + this.selectedItems.length + ')',
      controls: [],
    };
  }

  initializeMarketingBusinessPlan(rawData) {
    this.allAccountsForMarketingPlans = [];
    this.accountService.createMarketingplanAccountsTree(this.marketingPlansManagementOfflineService.marketingPlans);
    this.allAccountsForMarketingPlans = this.accountService.accountsTreeForOtherTool;
    this.rawPlanData = rawData;
    this.updateAccountPlansList(this.rawPlanData);
    this.onSearch();
  }

  updateAccountPlansList(data: any) {
    this.plansData = data.sort((a, b) => a.brandPlanName > b.brandPlanName ? 1 : -1);
    if (this.from === PageName.OpportunityInfoComponent || this.from === PageName.ActivitiesPageComponent) {
      // if (this.positionfilter) {
      //   this.plansData = this.marketingPlansManagementOfflineService.filterMarketingBusinessPlanByPosition(this.plansData);
      // }
      this.plansData = this.marketingPlansManagementOfflineService.filterMarketingBusinessPlanByTime(this.plansData)
    }
  }

  getMarketingPlanmainCardModel(marketingBuninessPlan: MarketingPlan): MainCardViewDataModel {
    const numberOfAccounts = marketingBuninessPlan.accounts.length;
    const numberOfProducts = marketingBuninessPlan.products.length;
    const card = {
      id: marketingBuninessPlan.ID,
      fixedHeight: true,
      primaryTextLeft: !_.isEmpty(marketingBuninessPlan.accounts) ? marketingBuninessPlan.accounts[0].name + (numberOfAccounts > 1 ? ` +${numberOfAccounts - 1}` : '') : this.translate.instant('NO_ACCOUNTS'),
      primaryTextRight: marketingBuninessPlan.brandPlanName,
      secondaryTextRight: numberOfProducts > 0 ? marketingBuninessPlan.products[0]?.productName + (numberOfProducts > 1 ? ` +${numberOfProducts - 1}` : '') : '',
      isSelected: this.selectedPlanId == marketingBuninessPlan.ID,
      showArrow: false,
      showEndIcon: marketingBuninessPlan.status == 'Completed',
      endIconCssClass: 'completed-icon',
      isSecondSrcAttribute: true,
      showSecondEndIcon: this.from == null && !this.device.isOffline,
      secondEndIconType: 'assets/imgs/horizontal-menu-filled.svg',
      secondEndIconCssClass: 'showOnHover',
      arrowType: 'chevron-forward',
      clickHandler: async (id: string, event, eventName, refData) => this.handleMarketingPlanItemClick(id, event, eventName, refData)
    }
    if (this.viewData && (this.viewData.viewMode === ComponentViewMode.ADDNEW || this.viewData.viewMode === ComponentViewMode.SELECTION)) {
      const isSelected = this.selectedItems.some(a => a.id == marketingBuninessPlan.ID);
      card['mainItemCssClass'] = 'selector-item';
      card['isItemSelectedForSelectionView'] = true;
      card['endIconType'] = isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon';
      card['endIconCssClass'] = isSelected ? 'checkmark-icon' : 'add-icon';
      card['showEndIcon'] = !this.isReadOnlyMode;
    }
    return card;
  }

  async handleMarketingPlanItemClick(id, event, eventName, refData) {
    let  marketingBuninessPlan = this.marketingPlansManagementOfflineService.marketingPlans.find(({ ID }) => id === ID);
    if(!marketingBuninessPlan){
      marketingBuninessPlan = this.marketingPlansManagementOfflineService.marketingPlans$.value.find(({ ID }) => id === ID);
    }
    switch (eventName) {
      case 'secondEndIcon':
        const clonePopover = await this.showCloneDropDown(event);
        clonePopover.onDidDismiss().then(({ data }: any) => {
          if (data && data.isDone) this.cloneMarketingBusinessPlan(marketingBuninessPlan);
        });
        clonePopover.present();
        break;
      case 'mainItem':
        if (!_.isEmpty(this.selectedItems)) {
          this.selectedItems.forEach(i => i.isSelected = i.id === id);
        }
        this.openMarketingPlan(marketingBuninessPlan);
        break;
      case 'endIcon':
        if (refData && refData.showEndIcon) {
          if (refData.endIconType === 'indegene-selectors-add-icon')
            this._handleAddMarketingPlan(id);
          if (refData.endIconType === 'indegene-selectors-remove-icon')
            this._handleRemoveMarketingPlan(id);
        }
    }
  }


  private _handleAddMarketingPlan(id) {
    const foundItem = this.plansData.find(a => a.ID == id);
    if (foundItem) {
      if (this.viewData && this.viewData.viewMode === ComponentViewMode.SELECTION) {
        this.selectedItems = [];
      }
      let isAlreadyAddedIndex =  this.selectedItems.findIndex(a => a.id == id);
      if (isAlreadyAddedIndex < 0) {
        const numberOfAccounts = foundItem.accounts.length;
        const numberOfProducts = foundItem.products.length;
        this.selectedItems.push({
          id: foundItem.ID,
          primaryTextLeft: !_.isEmpty(foundItem.accounts) ? foundItem.accounts[0].name + (numberOfAccounts > 1 ? ` +${numberOfAccounts - 1}` : '') : this.translate.instant('NO_ACCOUNTS'),
          secondaryTextRight: numberOfProducts > 0 ? foundItem.products[0]?.productName + (numberOfProducts > 1 ? ` +${numberOfProducts - 1}` : '') : '',
          showEndIcon: true,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: true,
          endIconType: 'indegene-selectors-remove-icon',
          endIconCssClass: 'remove-icon',
          primaryTextRight: foundItem.brandPlanName,
          showArrow: false,
          arrowType: '',
          clickHandler: (id: string, event, target, data) => this.handleMarketingPlanItemClick(id, event, target, data),
        });
        this._resetIsDirty();
        this._initSelectedHeader();
        this.initMarketingPlansHeaderLeft();
      }
    }
  }

  private _handleRemoveMarketingPlan(id) {
    let foundItemIndex;
    foundItemIndex = this.selectedItems.findIndex(a => a.id == id);
    if (foundItemIndex >= 0) {
      this.selectedItems.splice(foundItemIndex, 1);
      this._resetIsDirty();
      this._initSelectedHeader();
      this.initMarketingPlansHeaderLeft();
    }
  }

  private _resetIsDirty() {
    this._isDirty = _.xor(this.viewData.selectedMarketingPlans.map(a => a.ID), this.selectedItems.map(b => b.id)).length !== 0;
  }

  async openMarketingPlan(selectedMarketingPlan: MarketingPlan): Promise<void> {
    this.selectedPlanId = selectedMarketingPlan.ID;
    if (!this.device.isOffline && !this.isNew) {
      await this.uiService.displayLoader();
      await this.marketManagementDataService.getMarketingBusinessPlans(false, selectedMarketingPlan.ID);
      await this.uiService.dismissLoader();
    } else if(!this.device.isOffline && this.isMBPfieldsEnabled) {
      //To update association bu marketing plan id - user's bu is generated as default
      await this.uiService.displayLoader();
      await this.marketManagementDataService.getMarketingBusinessPlans(false, selectedMarketingPlan.ID);
      await this.uiService.dismissLoader();
    } else {
      this.marketingPlansManagementOfflineService.selectedMarketingPlan$.next(selectedMarketingPlan);
    }
    if(this.from === PageName.ActivitiesPageComponent){
      this.selectedMarketingPlan.emit({ plan: selectedMarketingPlan, selectedMode : ComponentViewMode.READONLY });
    }else{
      this.selectedMarketingPlan.emit({ plan: selectedMarketingPlan });
    }
    this.isNew = false;
  }

  private initMarketingPlansHeaderLeft(): void {
    let buttons = [];
    if (this.viewData && (this.viewData.viewMode === ComponentViewMode.READONLY || this.viewData.viewMode === ComponentViewMode.ADDNEW || this.viewData.viewMode === ComponentViewMode.SELECTION)) {
      buttons.push({
        id: "back",
        imgSrc: 'assets/imgs/header_cancel.svg',
        cssClass: 'seventyPercentWidth',
        isDisabled: false,
        align: "left",
      },
        {
          id: "done",
          imgSrc: 'assets/imgs/header_complete.svg',
          cssClass: 'seventyPercentWidth',
          isDisabled: !this._isDirty || this.viewData.disableDoneButtonInOffline,
          align: "right",
        });
    } else {
      buttons.push({
        id: 'close',
        imgSrc: 'assets/imgs/back_to_home_btn.svg',
        isDisabled: false,
        align: 'left',
      });
    }
    this.indHeaderLeftModel = {
      id: 'marketing-plan-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('MARKETING_BUSINESS_PLANS'),
      mode: true,
      controls: buttons,
    };
  }

  private _initKeyControlsAreaModel() {
    this.marketPlanKeyControlAreaModel = {
      id: 'accounts-list-key-controls-area',
      eventHandler: (id: string, event, eventName) => this.handleControlsAreaEvent(id),
      controls: [{
        id: 'create-btn',
        text: this.translate.instant('CREATE'),
        isDisabled: this.device.isOffline,
        leftAligned: false
      }]
    };
  }

  private async handleControlsAreaEvent(id) {
    if (id == 'create-btn') {
      const payload = await this.createPayload();
      this.createMarketPlan(payload);
    }
  }

  private async createPayload() {
    return {
      indskr_name: this.translate.instant('MARKETING_BUSINESS_PLAN'),
      indskr_validfrom: format(new Date(), 'YYYY-MM-DD'),
      indskr_validto: format(new Date(), 'YYYY-MM-DD'),
      _ownerid_value: this.authService.user.systemUserID,
      buId: this.authService.user.xBusinessUnitId,
    }
  }

  private async createMarketPlan(newMarketPlanPayload, cloneId: string = null) {
    await this.uiService.displayLoader();
    let defaultCurrencyID,defaultCurrencyName;
    if(this.authService.user.defaultCurrencyID){
      const foundCurrency = this.marketingPlansManagementOfflineService.currencies.find(a=> a.id == this.authService.user.defaultCurrencyID);
      if(foundCurrency){
        newMarketPlanPayload['transactionCurrencyId'] = defaultCurrencyID = foundCurrency.id;
        defaultCurrencyName = foundCurrency.name
      }
    }
    await this.marketManagementDataService.saveMarketPlan(newMarketPlanPayload).then(async (newMarketPlan: any) => {
      newMarketPlan.createdByString = newMarketPlan.lastModifiedByString = newMarketPlan.ownerName = this.authService.user.displayName;
      if(defaultCurrencyID){
        newMarketPlan.currencyId = defaultCurrencyID;
      }
      if(defaultCurrencyName){
        newMarketPlan.currencyName = defaultCurrencyName;
      }
      if(!(newMarketPlan.associationBusinessUnits && newMarketPlan.associationBusinessUnits.length > 0)){
        // Add Logged in User BU to App copy as it get's automatically added on dynamics
        newMarketPlan.associationBusinessUnits = [{
          associationBuName: this.authService.user.businessUnitName,
          associationBuID: this.authService.user.xBusinessUnitId,
        }];
      }
      if (cloneId) {
        let { accounts, contacts, products, currencyName } = _.cloneDeep(this.marketingPlansManagementOfflineService.marketingPlans.find(({ ID }) => ID == cloneId));
        accounts = accounts.filter(({ id }) => this.marketingPlansManagementOfflineService.isAccountMappedToUser(id));
        contacts = contacts.filter(({ contactId }) => this.marketingPlansManagementOfflineService.isContactMappedToUser(contactId));
        products = products.filter(({ productID }) => this.marketingPlansManagementOfflineService.isProductMappedToUser(productID));
        newMarketPlan = { ...newMarketPlan, accounts, contacts, products, currencyName }
      }
      this.marketingPlansManagementOfflineService.marketingPlans.push(newMarketPlan);
      await this.marketManagementDataService.saveMarketingPlanInLocal(newMarketPlan);
      this.marketingPlansManagementOfflineService.marketingPlans$.next(this.marketingPlansManagementOfflineService.marketingPlans);
      this.isNew = true;
      // this.marketingPlansManagementOfflineService.selectedMarketingPlan$.next(newMarketPlan);
      this.openMarketingPlan(newMarketPlan);
      this.uiService.dismissLoader();
    });
  }

  onPageTitleControlClick(id) {
    switch (id) {
      case 'close':
        this.goBack();
        break;
      case 'back':
        this.closeSelectionPage();
        break;
      case 'done':
        this.mapMarketingPlans();
        break;
      default:
        break;
    }
  }

  private mapMarketingPlans() {
    const selectedItems = this.selectedItems.map(item => {
      return {
        ID: item.id,
        brandPlanName: item.primaryTextRight
      } as MarketingPlan
    })
    this.viewData.callbackFromSelectionComponent("DONEBUTTONCLICK", selectedItems, null);
    this.closeSelectionPage();
  }

  public closeSelectionPage(): void {
    this.navService.popWithPageTracking().then(() => {
      if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) this.footerService.initButtons(FooterViews.Activities);
    });
  }

  async showCloneDropDown(event) {
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'activities-pane-more-options-select',
      showClearAllBtn: false,
      data: [{
        title: this.translate.instant('CLONE'),
        id: 'Clone',
        isSelected: false,
      }],
    };

    let target = {
      top: event.target.getBoundingClientRect().top,
      bottom: event.target.getBoundingClientRect().bottom,
      height: event.target.getBoundingClientRect().height,
      left: event.target.getBoundingClientRect().left,
      right: event.target.getBoundingClientRect().right,
      width: event.target.getBoundingClientRect().width,
      x: event.target.getBoundingClientRect().x,
      y: event.target.getBoundingClientRect().y,
    };

    let ev: any = {
      target: {
        getBoundingClientRect: () => {
          return target;
        }
      }
    };

    let moreOptionsPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: ev,
    });

    return moreOptionsPopover;
  }

  cloneMarketingBusinessPlan(marketingBuninessPlan: MarketingPlan) {
    const currentEndDate = new Date(parseInt(marketingBuninessPlan.endDate));
    const currentStartDate = format(new Date(parseInt(marketingBuninessPlan.startDate)), 'YYYY-MM-DD');
    const endDate = format(isPast(currentEndDate) ? new Date() : currentEndDate, 'YYYY-MM-DD');

    const newPayload = {
      indskr_name: marketingBuninessPlan.brandPlanName + '_Clone',
      accounts: marketingBuninessPlan.accounts.filter(({ id }) => this.marketingPlansManagementOfflineService.isAccountMappedToUser(id)).map(({ id }) => ({ id })),
      contacts: marketingBuninessPlan.contacts.filter(({ contactId }) => this.marketingPlansManagementOfflineService.isContactMappedToUser(contactId)).map(({ contactId }) => ({ id: contactId })),
      products: marketingBuninessPlan.products.filter(({ productID }) => this.marketingPlansManagementOfflineService.isProductMappedToUser(productID)).map(({ productID }) => ({ id: productID })),
      // accounts: marketingBuninessPlan.accounts.map(({ id }) => ({ id })),
      // contacts: marketingBuninessPlan.contacts.map(({ contactId }) => ({ id: contactId })),
      // products: marketingBuninessPlan.products.map(({ productID }) => ({ id: productID })),
      transactionCurrecnyId: marketingBuninessPlan.currencyId,
      indskr_totalbudget: marketingBuninessPlan.indskr_totalbudget,
      indskr_validfrom: currentStartDate,
      indskr_validto: endDate,
      _ownerid_value: this.authService.user.systemUserID
    }

    // console.log(newPayload);


    this.createMarketPlan(newPayload, marketingBuninessPlan.ID);
  }

  async goBack() {
    this.uiService.toolsActivityActive = false;
    this.navService.setChildNavRightPaneView(false);
    this.uiService.activeView = '';
    this.marketingPlansManagementOfflineService.selectedMarketingPlan$.next(null);
    if (this.navService.getCurrentPageName() === PageName.MarketingPlanManagementPageComponent) {
      this.accountService.accessedAccountListFrom = null;
      this.accountService.selected = undefined;
      this.uiService.showRightPane = false;
      this.uiService.activeView = this.uiService.getActiveViewBasedOnActivity(this.activityService.selectedActivity);
      await this.navService.popToRootWithPageTracking().then(() => {
        if (this.activityService.selectedActivityAtHome) {
          this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
          if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) {
            this.footerService.initButtons(FooterViews.Activities);
          } else if (this.navService.getActiveChildNavViewPageName() === PageName.EmailDetailsPageComponent) {
            this.footerService.initButtons(FooterViews.EmailDetails);
          } else if (this.navService.getActiveChildNavViewPageName() === PageName.PhoneCallDetailsComponent) {
            this.footerService.initButtons(FooterViews.PHONE_CALL);
          } else if (this.navService.getActiveChildNavViewPageName() === PageName.CaseManagementDetailComponent) {
            this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
          } else if (this.navService.getActiveChildNavViewPageName() === PageName.NewSampleActivityComponent) {
            this.footerService.initButtons(FooterViews.SampleDropDetails);
          } else {
            this.logicToRunAfterPopToRootWhenAccessedFromToolsDrawer();
          }
        } else {
          this.logicToRunAfterPopToRootWhenAccessedFromToolsDrawer();
        }
      });
    }
  }

  private logicToRunAfterPopToRootWhenAccessedFromToolsDrawer() {
    // Short call feature requires to bring the tab back to short call home
    if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
      this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
      this.uiService.setAgendaTab('shortCallHome');
    }
  }

  public isSplitView(): boolean {
    return (window.innerWidth > 500);
  }

  onSelectedTabChange(selectedTab: string) {
    this.uiService.setAgendaTab(selectedTab);
  }

  async onSearch() {
    if (this.searchInput && this.searchInput.trim().length > 0) {
      this.isSearched = true;
      let searchedName = this.searchInput.toLowerCase();
      this.searchedData = this.rawPlanData.filter(({ accounts, brandPlanName }) => {
        return accounts?.some((account) => account.name.toLowerCase().includes(searchedName)) || brandPlanName.toLowerCase().includes(searchedName);
      });
      this.updateAccountPlansList(this.searchedData);
    } else {
      this.isSearched = false;
      this.updateAccountPlansList(this.rawPlanData);
    }
  }

  onCancel() {
    this.updateAccountPlansList(this.rawPlanData);
  }

  ngOnDestroy() {
    this.ngUnSubscribe$.next(true);
    this.ngUnSubscribe$.complete();
    this.fromPlanTab = false;
  }
}