import { isThisSecond } from "date-fns";

export enum ControlDataType {
  PicklistType = "PicklistType",
  MultiSelectPicklistType = "MultiSelectPicklistType",
  StringType = "StringType",
  DoubleType = "DoubleType",
  IntegerType = "IntegerType",
  DecimalType = "DecimalType",
  DateTimeType = "DateTimeType",
  LookupType = "LookupType",
  StateType = "StateType",
  StatusType = "StatusType",
  CustomerType = "CustomerType",
  OwnerType = "OwnerType",
  BooleanType = "BooleanType",
  MoneyType = "MoneyType",
  MemoType = "MemoType",
  WebResource = "WebResource",
  LinkedEntity = 'LinkedEntity'
}

export enum FormType {
  CREATEFORM = 548910000,
  EDITFORM = 548910001,
  DISPLAYFORM = 548910002,
}

export enum BusinessProcessType {
  Omnipresence = 548910000,
  OneKey = 548910001,
  SanofiChina = 600000000
}

export enum OmniGender {
  MALE = 1,
  FEMALE = 2
}

export enum OneCRMGender {
  MALE = '男',
  FEMALE = '女'
}

export enum BusinessLine {
  PHARMA = 'Pharma',
  VACCINE = 'Vaccine',
  REATIL = "Retail"
}

export enum BusinessLineVal {
  PHARMA = 1,
  VACCINE = 2,
  RETAIL = 3,
  VACCINE_MDM = 4,
}

export enum MDM_USER_TYPE {
  PHARMA = 808210000,
  VACCINE = 808210001,
  RETAIL = 548910000,
  VACCINE_MDM = 808210002,
}

export enum DCR_CATEGORY {
  HCP = 'HCP'
}

export enum DCR_SOURCE {
  ONECRM = 'OneCRM'
}

export enum OneCRMReqType {
  ADD = "新增",
  UPDATE = "修改",
  AFFILIATION = "新增医生-身兼多院"
}

export enum VaccineReqType {
  ADD = "Add"
}

export enum EditConfig {
  ADD_NEW = 548910000,
  UPDATE_EXISTING = 548910001,
  BOTH = 548910002
}

export enum DateFormat {
  DateAndTime = "DateAndTime",
  DateOnly = "DateOnly"
}

export enum ViewType {
  POPOVER_UI = 548910000,
  LISTVIEW_UI = 548910001
}

export enum FieldConfigFilterOperatorType {
  EQ = 548910000,
  IN = 548910001,
  CONTAIN_VALUES = 548910002,
}

export class DynamicForm {

  public raw: any;
  public name: string;
  public entityName: string;
  public entitySetName: string;
  public metadata: FormTabs[] = [];
  public stateCode: number;
  public statusCode: number;
  public versionNumber: number;
  public mobileFormId: string;
  public formId: string;
  public modiefiedOn: Date;
  public owningBussinessUnit: string;
  public ownerId: string;
  public mandatoryFieldConfig : MandatoryFieldConfig[] = [];
  public editConfig: EditConfig;
  public businessRules: Array<{
    formId:string;
    logicFunction:any;
  }> = [];


  constructor(raw) {
    this.raw = raw;
    this.name = raw['indskr_name'] || '';
    this.entityName = raw['indskr_entityname'] || '';

    this.stateCode = raw['statecode'];
    this.statusCode = raw['statuscode'];
    this.versionNumber = raw['versionnumber'];
    this.mobileFormId = raw['indskr_mobileappformid'] || '';
    this.formId = raw['indskr_formid'] || '';
    this.modiefiedOn = raw['modifiedon'] ? new Date(raw['modifiedon']) : null;
    this.owningBussinessUnit = raw['_owningbusinessunit_value'] || '';
    this.ownerId = raw['_ownerid_value'] || '';
    this.editConfig = raw['indskr_editconfig'] || EditConfig.ADD_NEW;

    let tempMD;
    if(raw['indskr_metadata'] && (typeof raw['indskr_metadata'] == 'string')) {
      tempMD = JSON.parse(raw['indskr_metadata']);
    } else {
      tempMD = raw['indskr_metadata'];
    }
      this.entitySetName = tempMD['EntitySetName'] || '';
    if (tempMD && tempMD['Tabs'] && tempMD['Tabs'].length > 0) {
      for (let tabs of tempMD['Tabs']) {
        this.metadata.push(new FormTabs(tabs));
      }
    }
    if (raw['mandatory_field_config'] && raw['mandatory_field_config'].length > 0) {
      for (let config of raw['mandatory_field_config']) {
        this.mandatoryFieldConfig.push(new MandatoryFieldConfig(config));
      }
    }

    if(raw['search_entities'] && raw.search_entities.length != 0){
      this.metadata.forEach(tab=> {
        if(tab.controls && tab.controls.length != 0){
          tab.controls.forEach(control => {
            if(!control.dataType && control.subgrid && control.subgrid.referencingEntity){
              let item = raw.search_entities.find(entity=> entity.indskr_relatedentity == control.subgrid.referencingEntity);
              if(item){
                control.isSearchable = true;
                control.searchAttributeName = control.subgrid.referencingEntity+'.'+item.indskr_attribute;
              }
            }
          })
        }
      });
    }

    if(this.mandatoryFieldConfig && this.mandatoryFieldConfig.length != 0){
      this.metadata.forEach(tab=> {
        if(tab.controls && tab.controls.length != 0){
          tab.controls.forEach(control => {
            this.mandatoryFieldConfig.forEach(config => {
              if(config.mobileAppFormId == this.mobileFormId && config.fieldName == control.attributeName){
                if(!control.isRequired) {
                  control.isRequired = config.isRequired;
                }
                if(control.dataType == ControlDataType.LookupType){
                  if (config.filterby) {
                    control.filterby = config.filterby;
                  }
                  if (config.filterFieldTargetLookupEntity){
                    //define these attributes in the control
                    control.filterFieldTargetLookupEntity = config.filterFieldTargetLookupEntity;
                  }
                  if (config.targetEntityAttribute) {
                    control.targetEntityAttribute = config.targetEntityAttribute;
                  }
                  if (config.filterOperator) {
                    control.filterOperator = config.filterOperator;
                  }
                  if(config.viewType){
                    control.viewType = config.viewType;
                  }
                }
              }
            }); 
          });
        }
      });
    }

    if(raw['linked_entities_config'] && raw.linked_entities_config.length != 0){
      this.metadata.forEach(tab=> {
        if(tab.controls && tab.controls.length != 0){
          tab.controls.forEach(control => {
            if(!control.dataType && control.subgrid && control.subgrid.referencingEntity){
              let item = raw.linked_entities_config.find(entity=> entity.indskr_entityname == control.subgrid.referencingEntity);
              if(item){
                let entityConfig = new LinkedEntityConfig(item);
                control.linkedEntityConfig = entityConfig;
                control.isRequired = entityConfig.isRequired;
              }
            }
          });
        }
      });
    }

    if(raw['businessRules']){
      raw['businessRules'].forEach(rule=>{
        if(rule.processtriggerformid){
          this.businessRules.push({
            formId: rule.processtriggerformid,
            logicFunction: rule['clientdata'].substring(rule['clientdata'].indexOf('function'),rule['clientdata'].indexOf(']]></clientcode></clientdata>'))
          });
        }
      })
    }

  }

}

export class FormTabs {
  public controls: Control[] = [];
  public displayNames: DisplayText[] = [];

  constructor(raw) {
    if (raw['Controls'] && raw['Controls'].length > 0) {
      for (let con of raw['Controls']) {
        this.controls.push(new Control(con));
      }
    }

    if (raw['DisplayNames'] && raw['DisplayNames'].length > 0) {
      for (let dn of raw['DisplayNames']) {
        this.displayNames.push(new DisplayText(dn));
      }
    }
  }
}

export class Control {
  public attributeName: string;
  public schemaName: string;
  public dataType: string;
  public format:string;
  public isList: boolean;
  public maxLength: number;
  public isReadOnly: boolean;
  public isVisible:boolean;
  public isRequired: boolean;
  public dateFormat: string;
  public lookupEntityPrimaryId: string;
  public lookupEntityPrimaryName: string;
  public lookupEntitySetName: string;
  public subgrid:Subgrid;
  public optionSetName: string;
  public isCustomAttribute: boolean;
  public forceHide:boolean;
  public maxValue:number;
  public minValue:number;
  public isSearchable:boolean;
  public searchAttributeName:string;
  public dateBehavior: string;
  public filterby: string;
  public filterFieldTargetLookupEntity: string;
  public targetEntityAttribute: string;
  public filterOperator: FieldConfigFilterOperatorType;
  public viewType: number;
  public defaultValue: number;

  //Arrays
  public lookupQueries: LookupQuery;
  public descriptions: DisplayText[] = [];
  public displayNames: DisplayText[] = [];
  public optionSet: Option[] = [];

  //Custom properties for custom fields
  public isCustom: boolean;
  public dependsOn: string;

  //Linked Entity Config
  public linkedEntityConfig: LinkedEntityConfig;

  constructor(raw) {
    this.attributeName = raw['AttributeName'];
    this.schemaName = raw['SchemaName'];
    this.dataType = raw['DataType'];
    this.format = raw['Format'] || '';
    this.dateFormat = raw['DateFormat'] || '';
    this.dateBehavior = raw['DateBehavior'] || '';
    this.isList = raw['IsList'];
    this.maxLength = raw['MaxLength'];
    this.isReadOnly = raw['ReadOnly'];
    this.isRequired = raw['Required'];
    this.isVisible = raw['Visible'];
    this.lookupEntityPrimaryId = raw['LookupEntityPrimaryIdAttribute'];
    this.lookupEntityPrimaryName = raw['LookupEntityPrimaryNameAttribute'];
    this.lookupEntitySetName = raw['LookupEntitySetName'];
    //isCustomAttribute from Dynamics, flag that determines when to use SchemaName or AttributeName during creation
    this.isCustomAttribute = raw['IsCustomAttribute'] || false;
    this.optionSetName = raw['OptionSetName'] || '';

    // isCustom is a flag to determine hardcoded fields
    this.isCustom = raw["IsCustom"] || false;
    this.dependsOn = raw["DependsOn"] || '';
    this.forceHide = false;
    this.maxValue = raw.hasOwnProperty('MaxValue')?raw['MaxValue']:undefined;
    this.minValue = raw.hasOwnProperty('MinValue')?raw['MinValue']:undefined;
    this.isSearchable = raw['isSearchable'] || false;
    this.searchAttributeName = raw['searchAttributeName'] || '';
    this.defaultValue = raw['DefaultValue'];

    if (raw['LookupQueries'] && raw['LookupQueries'].length > 0) {
      this.lookupQueries = new LookupQuery(raw['LookupQueries'][0]);
    }
    if (raw['Descriptions'] && raw['Descriptions'].length > 0) {
      for (let dt of raw['Descriptions']) {
        this.descriptions.push(new DisplayText(dt));
      }
    }
    if (raw['DisplayNames'] && raw['DisplayNames'].length > 0) {
      for (let dn of raw['DisplayNames']) {
        this.displayNames.push(new DisplayText(dn));
      }
    }
    if (raw['OptionSet'] && raw['OptionSet'].Options && raw['OptionSet'].Options.length > 0) {
      for (let op of raw['OptionSet'].Options) {
        this.optionSet.push(new Option(op));
      }
    }
    if(raw['Subgrid'] && raw['Subgrid'].SubgridLayout && raw['Subgrid'].SubgridLayout.Attributes && raw['Subgrid'].SubgridLayout.Attributes.length != 0){
      this.subgrid = new Subgrid(raw['Subgrid']);
    }
  }

}

export class Subgrid {
  public parentAttribute: string;
  public parentEntity: string;
  public referencingAttribute: string;
  public referencingEntity: string;
  public referencingEntityNavigationPropertyName: string;
  public relationshipName:string;
  public subgridQuery:string;
  public targetEntityType:string;
  public viewId:string;
  public subgridLayout:Array<SubgridLayout> = [];

  constructor(raw){
    this.parentAttribute = raw['ParentAttribute'];
    this.parentEntity = raw['ParentEntity'];
    this.referencingAttribute = raw['ReferencingAttribute'];
    this.referencingEntity = raw['ReferencingEntity'];
    this.referencingEntityNavigationPropertyName = raw['ReferencingEntityNavigationPropertyName'];
    this.relationshipName = raw['RelationshipName'];
    this.subgridQuery = raw['SubgridQuery'];
    this.targetEntityType = raw['TargetEntityType'];
    this.viewId = raw['ViewId'];
    if(raw['SubgridLayout'] && raw['SubgridLayout'].Attributes && Array.isArray(raw['SubgridLayout'].Attributes)){
      this.subgridLayout = [];
      for (let dt of raw['SubgridLayout'].Attributes)  {
        let attrib = new SubgridLayout(dt);
        this.subgridLayout.push(attrib);
      }
    }
  }
}
export class SubgridLayout {

  public attribute:string;
  public targetEntity:string;
  public descriptions: DisplayText[] = [];
  public displayNames: DisplayText[] = [];

  constructor(raw){
    this.attribute = raw['Attribute'];
    this.targetEntity = raw['TargetEntity'];
    if (raw['Descriptions'] && raw['Descriptions'].length > 0) {
      for (let dt of raw['Descriptions']) {
        this.descriptions.push(new DisplayText(dt));
      }
    }
    if (raw['DisplayNames'] && raw['DisplayNames'].length > 0) {
      for (let dn of raw['DisplayNames']) {
        this.displayNames.push(new DisplayText(dn));
      }
    }
  }
}

export class Option {
  public value: number;
  public descriptions: DisplayText[] = [];
  public displayNames: DisplayText[] = [];

  constructor(raw) {
    this.value = raw['Value'];

    //For form data
    if(raw.hasOwnProperty('Descriptions')) {
      if (raw['Descriptions'] && raw['Descriptions'].length > 0) {
        for (let dt of raw['Descriptions']) {
          this.descriptions.push(new DisplayText(dt));
        }
      }
      if (raw['DisplayNames'] && raw['DisplayNames'].length > 0) {
        for (let dn of raw['DisplayNames']) {
          this.displayNames.push(new DisplayText(dn));
        }
      }
    } else if(raw.hasOwnProperty('Description')) { //For option set results
      if(raw['Description']) {
        let dtObject = raw['Description'];
        if (dtObject['LocalizedLabels'] && dtObject['LocalizedLabels'].length > 0) {
          for (let dt of dtObject['LocalizedLabels']) {
            this.descriptions.push(new DisplayText(dt));
          }
        }
      }
      if(raw['Label']) {
        let dtObject = raw['Label'];
        if (dtObject['LocalizedLabels'] && dtObject['LocalizedLabels'].length > 0) {
          for (let dt of dtObject['LocalizedLabels']) {
            this.displayNames.push(new DisplayText(dt));
          }
        }
      }
    }
  }
}

export class DisplayText {
  description: string;
  languagecode: string;

  constructor(raw) {
    /**
     * raw['Description'] is for form metadata
     * raw['Label'] is for Option Set results
     */
    this.description = raw['Description'] || raw['Label'];
    this.languagecode = raw['LanguageCode'];
  }
}

export class LookupQuery {
  entityName: string;
  fetchXml: string;
  objectTypeCode: number;

  constructor(raw) {
    this.entityName = raw['EntityName'];
    this.fetchXml = raw['FetchXml'];
    this.objectTypeCode = raw['ObjectTypeCode'];
  }
}

export class LookupSearchResult {

  name: string;
  id: string;
  stateName: string;
  stateId: string;
  countryName : string;
  countryId: string;
  cityName: string;
  cityId: string;
  nameForSearch: string;
  codIdOnekey?: string;
  countyName?: string;
  countyCode?: string;
  totalWeightedMatch?: number;

  constructor(raw, idAttr, nameAttr) {

    this.name = raw[nameAttr] || "";
    this.id = raw[idAttr] || "";
    this.stateName = raw['_indskr_state_value_Formatted'] || this.getTargetAddressData(raw, "indskr_state_Formatted") || '';
    this.stateId = raw['_indskr_state_value'] || this.getTargetAddressData(raw, "indskr_state") || '';
    this.countryName =  raw['indskr_countryname'] ||this.getTargetAddressData(raw, "indskr_lu_countryid_Formatted") || '';
    this.countryId = raw['indskr_lu_countryid'] || this.getTargetAddressData(raw, "indskr_lu_countryid") || '';
    this.cityName = this.getTargetAddressData(raw, "indskr_name") || '';
    this.cityId = this.getTargetAddressData(raw, "indskr_lu_cityid") || '';
    this.nameForSearch = raw[nameAttr] ? raw[nameAttr].toLowerCase() || "" : '';
    this.codIdOnekey = raw['omnione_cod_id_onekey'] || this.getTargetAddressData(raw, "omnione_cod_id_onekey") || '';
    this.countyName = (idAttr == 'indskr_lu_districtid') ? raw['indskr_name'] || this.getTargetAddressData(raw, "indskr_name") || '' : '';
    this.countyCode = raw['indskr_countycode'] || this.getTargetAddressData(raw, "indskr_countycode") || '';
  }

  private getTargetAddressData(raw: any, target: string) {
    let countryData = [];
    let foundValue;
    for (let r in raw) {
      countryData.push({
        key: r,
        value: raw[r]
      });
    }
    countryData.forEach(x=> {
        let mKey = x.key.replace(/\w+.\./g,'');
        if (mKey == target) {
          foundValue = x.value;
        }
      });
    return foundValue;
  }
}
export interface SelectedLookUp {
  id: string;
  name: string;

}


export class LinkedEntityConfig {

  public entityName:string;
  public linkedEntityConfigId:string;
  public maxNumber: number | undefined;
  public minNumber: number;
  public isRequired: boolean;
  public abilityToRemoveRecords: any;

  constructor(raw){
    this.entityName = raw['indskr_entityname'] || '';
    this.linkedEntityConfigId = raw['indskr_linkedentityformconfigurationsid'] || '';
    this.maxNumber = raw['indskr_maximumnumberofrecordsallowed'] !==undefined ? raw['indskr_maximumnumberofrecordsallowed'] : undefined;
    this.minNumber = raw['indskr_minimumnumberofrecordsallowed'] || '';
    this.isRequired = raw['indskr_requirementtype'] == 1 ? true : false;
    this.abilityToRemoveRecords = raw['indskr_abilitytoremoverecords'] || '';
  }
}

export class MandatoryFieldConfig {
  public mobileAppFormId: string;
  public mobileAppFormFielId: string;
  public fieldName: string;
  public isRequired: boolean;
  public filterby: string;
  public filterFieldTargetLookupEntity: string;
  public targetEntityAttribute: string;
  public filterOperator: FieldConfigFilterOperatorType;
  public viewType:  number;

  constructor(raw) {
    this.mobileAppFormId = raw['indskr_mobileappformid'];
    this.mobileAppFormFielId = raw['indskr_mobileappformfieldid'];
    this.fieldName = raw['indskr_mobileappformfieldname'];
    this.isRequired = raw['indskr_isrequired'];
    this.filterby = raw['indskr_filterby'];
    this.filterFieldTargetLookupEntity = raw['indskr_targetentityfilterfield'];
    this.targetEntityAttribute = raw['indskr_target_entity_attribute'];
    this.filterOperator = raw['indskr_operatortype'];
    this.viewType = raw['indskr_viewtype'];
  }
}

export enum SetBookingProductFilter {
  ALL_PRODUCTS = "All Products",
  ONLY_BOM = "Only BOM",
  ONLY_SURGEON = "Only Surgeon Preferences"
}
