import {takeUntil, tap} from 'rxjs/operators';
import { DateTimeFormatsService } from './../../../services/date-time-formats/date-time-formats.service';
import { OpportunityDetailsComponent } from './../../opportunity-management/opportunity-details/opportunity-details';
import { AuthenticationService } from './../../../services/authentication.service';
import { UIService, ComponentViewMode } from './../../../services/ui/ui.service';
import { NavigationService, PageName } from './../../../services/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { AccountOfflineService } from './../../../services/account/account.offline.service';
import { IndSectionHeaderViewDataModel } from './../../../models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from './../../../models/MainCardViewDataModel';
import { Opportunity } from './../../../classes/opportunity-management/opportunity.class';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { OpportunityManagementService } from '../../../services/opportunity-management/opportunity-management.service';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import {forkJoin, Subject, Subscription} from 'rxjs';
import { DeviceService } from '../../../services/device/device.service';
import { ModalController, PopoverController } from '@ionic/angular';
import { TimeLineItem } from '@omni/components/timeline-item/timeline-item.component';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { SurgeryOrderContractDetailsComponent } from '@omni/components/surgery-order/surgery-order-contract-details/surgery-order-contract-details.component';
import { DatePipe } from '@angular/common';
import { OfftakeCheckComponent } from '../offtake-check-collection/offtake-check/offtake-check.component';
import { AssessmentTemplate, SurveyFrequency, SurveyStatus, SurveyType, TemplateType } from '@omni/classes/customer-assessment/assessment-template.class';
import { CustomerSurveyService } from '@omni/services/customer-survey/customer-survey.service';
import _ from 'lodash';
import { SubCardViewDataModel } from '@omni/models/SubCardViewDataModel';
import { SurveyListCardviewComponent } from '@omni/components/survey/survey-list-cardview/survey-list-cardview';
import { CustomerAssessment } from '@omni/classes/customer-assessment/customer-assessment.class';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { SurveyDetailsComponent } from '@omni/components/survey/survey-details/survey-details';
import { EventsService } from '@omni/services/events/events.service';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { ProcedureContractService } from '@omni/data-services/procedure-contract/procedure-contract.service';
import { AccountTimelinePageComponent } from '../account-timeline-page/account-timeline-page.component';
import { IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { OffTakeCheck } from '@omni/classes/offtake-check/offtake-check.class';
import { OffTakeCheckService } from '@omni/services/offtake-check/offtake-check.service';
import moment from 'moment';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from '@omni/services/disk/disk.service';
import { ProcedureContract } from '@omni/classes/procedure-contract/procedure-contract.class';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { electronApi } from "@omni/services/electron-api";
import { QuotesService } from '@omni/services/quote/quote-tool.service';
import { QuoteDetailsComponent } from '@omni/components/quote/quote-details/quote-details.component';
import { ActivityService } from '@omni/services/activity/activity.service';
import { OrderDetailsComponent } from '@omni/components/order-management/order-details/order-details';
import { OrderActivity } from '@omni/classes/activity/order.activity.class';
import { ActivityType } from '@omni/classes/activity/activity.class';
import {FieldMaterialManagementService} from "@omni/services/field-materials/field-material-management.service";
import {AssetDetailsComponent} from "@omni/components/field-material-management/asset-details/asset-details";
import {CustomerAsset} from "@omni/classes/field-materials/customer-asset.class";
import { AccountManagementOfflineService } from '@omni/services/account-management/account-management.service';
import { AccountPlanDetails } from '@omni/components/account-management/account-plan-details/account-plan-details';
import { Customer360UIService } from '@omni/services/ui/customer360ui.service';
import { isFuture, isPast } from 'date-fns';
import { AccountPlanMode } from '@omni/classes/account-management/account-plan.class';

/**
 * Generated class for the AccountProfileComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'account-profile',
  templateUrl: 'account-profile.html',
  styleUrls: ['account-profile.scss']
})
export class AccountProfileComponent {
  @Input() readOnly: boolean;
  applicableOpportunities: Opportunity[] = [];
  openOpportunitiesViewDataModels: MainCardViewDataModel[] = [];
  wonOpportunitiesViewDataModels: MainCardViewDataModel[] = [];
  lostOpportunitiesViewDataModels: MainCardViewDataModel[] = [];
  canceledOpportunitiesViewDataModels: MainCardViewDataModel[] = [];
  openOpportunitiesHeader: MainCardViewDataModel;
  formattedOpportunities: {
    Open: Opportunity[],
    Won: Opportunity[],
    Lost: Opportunity[],
    Canceled: Opportunity[]
  } = {
      Open: [],
      Won: [],
      Lost: [],
      Canceled: []
    }
  wonOpportunitiesHeader: MainCardViewDataModel;
  lostOpportunitiesHeader: MainCardViewDataModel;
  canceledOpportunitiesHeader: MainCardViewDataModel;
  opportunitiesHeader: IndSectionHeaderViewDataModel;
  procedureContractHeader: IndSectionHeaderViewDataModel;
  quoteHeader: IndSectionHeaderViewDataModel;
  assetManagementSectionHeader: IndSectionHeaderViewDataModel;
  offtakeCheckHeader: IndSectionHeaderViewDataModel;
  public surveyListSectionHeader: IndSectionHeaderViewDataModel;
  public salesOrderHeader: IndSectionHeaderViewDataModel;
  public procedureContractEnabled: boolean = false;
  public offtakeFeatureEnabled: boolean = false;
  public isSurveyEnabled: boolean = false;
  isAssetsEnabled: boolean = false;
  public showQuoteSection: boolean = false;
  public showSalesOrderSection: boolean = false;
  expandedSection: string = 'open';
  ngUnSubscribe$ = new Subject<boolean>();
  @ViewChild('scrollTop', { read: ElementRef, static: false }) scrollTop: ElementRef;
  private isReadOnly: boolean = false;
  public hasCanceledOpportunity: boolean = false;
  procedureContractData = [];
  // offtakeCheckProductHeaders: string[] = ["Product", "SKU/SKU Code", "Inventory Offtake", "Store Inventory", "Distributed Or Not"];
  private IpreviewSurveyModeSubscription: Subscription;
  private filteredSurveyList: AssessmentTemplate[] = [];
  public filteredSurveyListToDisplay: SubCardViewDataModel[] = [];
  public AvailableFilteredSurveyList = [];
  public from: string;
  public currentMonthOffTake: OffTakeCheck;
  public salesOrderList: TimeLineItem[] = [];
  timelineItems: TimeLineItem[] = [];
  quoteList: TimeLineItem[] = [];
  assetManagementList: TimeLineItem[] = [];
  accountPlanHeader: IndSectionHeaderViewDataModel;
  public showAccountPlanSection: boolean = false;
  accountPlanList: TimeLineItem[] = [];
  selectedAccountPlans = [];
  private accountId: string;

  constructor(
    private opportunityService: OpportunityManagementService,
    private notificationService: NotificationService,
    public accountOfflineService: AccountOfflineService,
    public translate: TranslateService,
    public navService: NavigationService,
    public uiService: UIService,
    public authenticationService: AuthenticationService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public device: DeviceService,
    public surgeryOrderDataService: SurgeryOrderActivityDataService,
    private datePipe: DatePipe,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    public customerSurveyService: CustomerSurveyService,
    public customerAssessService: CustomerAssessService,
    private readonly events: EventsService,
    private contractService: ProcedureContractService,
    private offTakeService: OffTakeCheckService,
    private disk: DiskService,
    private inAppBrowser: InAppBrowser,
    private quotesOfflineService: QuotesService,
    private readonly activityService: ActivityService,
    private fieldMaterialManagementService: FieldMaterialManagementService,
    public accountmanagementOfflineService: AccountManagementOfflineService,
    private c360Service: Customer360UIService,
  ) {
    this.events.subscribe('device:deviceIsOffline', (status) => {
      this.device.isOffline = true;
      this.initViewData();
    });
  }

  ngOnInit() {
    this.procedureContractEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCESS_PROCEDURE_CONTRACT);
    this.offtakeFeatureEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.OFFTAKE_FEATURE);
    this.isSurveyEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_SURVEY);
    this.showSalesOrderSection = this.authenticationService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT);
    this.showQuoteSection = this.authenticationService.hasFeatureAction(FeatureActionsMap.QUOTES);
    this.hasCanceledOpportunity = this.authenticationService.hasFeatureAction(FeatureActionsMap.DISCONTINUE_OPPORTUNTIY);
    this.isAssetsEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.FIELD_MATERIAL_MANAGEMENT);
    this.showAccountPlanSection =this.authenticationService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT);
    this.accountId = this.accountOfflineService.selected.id;
    this.opportunityService.opportunities$.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((data) => {
      if (data?.length) {
        this.initViewData();
      }
    });

    this.IpreviewSurveyModeSubscription = this.customerSurveyService.surveyPreviewObserver.pipe(takeUntil(this.ngUnSubscribe$)).subscribe(value => {
      if (!_.isEmpty(value[1])) {
        //Submitted Survey
        if (value[1].surveyStatus == SurveyStatus.SUBMITTED) {
          this._initSurveyList();
          this._initSurveySectionHeader();
        }
      }
    });

    this.accountOfflineService.isOpenedSurveyTimelineFromAccountProfile = false;

    if (this.accountOfflineService.accessedAccountListFrom == PageName.ContactDetailsComponent && this.accountOfflineService.accountPageMode == 'readonly') {
      this.isReadOnly = true;
    }

    this.initViewData();

    this.contractService.refreshProcedureContract.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((res) => {
      this.getProcedureContractHeader();
      this.procedureContractData = this.contractService.procedureContracts.filter(item => item.indskr_account_value == this.accountId);
      this.setProcedureContractsProfileList();
    });

    this.quotesOfflineService.quotesList$.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((quotes) => {
      if (quotes) {
        quotes = quotes.filter(item => item['accountId'] == this.accountId);
        if (quotes) this.setQuoteProfileList(quotes);
      }
    });

    this.selectedAccountPlans = this.accountmanagementOfflineService.accountPlans.filter((accountPlan) => {
      if (accountPlan['accountId'] == this.accountId) return true
    });
    this.setAccountPlanProfileData(this.selectedAccountPlans);

    this.accountmanagementOfflineService.accountPlans$.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((data) => {
      if (data && data.length) {
        this.setAccountPlanProfileData(data);
      }
    });

    this.navService.childNavPopObserver.pipe(takeUntil(this.ngUnSubscribe$)).subscribe(page => {
      if (page && page == PageName.OrderDetailsComponent) this.setSalesOrderProfileList();
      if(page && page == PageName.AssetDetailsComponent) this.setAssetManagementList();
    })

    this.setSalesOrderProfileList();

    this.setAssetManagementList();

    if (this.isSurveyEnabled) {
      this._initSurveyList();
      this._initSurveySectionHeader();
    }
  }

  ngOnDestroy() {
    this.offTakeService.clear();
    this.ngUnSubscribe$.next(true);
    this.ngUnSubscribe$.complete();
  }

  public get isDataUnavailable() {
    const conditions = [
      this.applicableOpportunities.length === 0,
      this.procedureContractData.length === 0,
      this.filteredSurveyListToDisplay.length === 0,
      !this.offtakeFeatureEnabled,
      this.salesOrderList.length === 0 && !this.showSalesOrderSection,
      this.quoteList.length === 0 && !this.showQuoteSection,
      this.procedureContractData.length === 0 && !this.procedureContractEnabled,
      this.assetManagementList.length === 0 && !this.isAssetsEnabled
    ];

    return conditions.every(condition => condition);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.scrollTop) this.scrollTop.nativeElement.scrollIntoView(false);
    }, 200);
  }

  resetViewDataModels() {
    this.formattedOpportunities = {
      Open: [],
      Won: [],
      Lost: [],
      Canceled: []
    }
    this.applicableOpportunities = [];
    this.openOpportunitiesViewDataModels = [];
    this.wonOpportunitiesViewDataModels = [];
    this.lostOpportunitiesViewDataModels = [];
    this.canceledOpportunitiesViewDataModels = [];
    this.currentMonthOffTake = null;
  }

  initViewData() {
    this.resetViewDataModels()
    this.getProcedureContractHeader();
    this.getQuoteHeader();
    this.getSalesOrderHeader();
    this.getOfftakeFeatureHeader();
    this.getAssetManagementHeader();
    this.getAccountPlanHeader();

    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT)) {
      this.applicableOpportunities = this.opportunityService.opportunities.filter((oppo) => {
        return oppo.accountID == this.accountId;
      })
      this.getFormattedOpportunitiesList();
      this.formattedOpportunities.Open.forEach((opportunity) => {
        const viewModel: MainCardViewDataModel = this.getOpportunityMainCardModel(
          opportunity
        );
        viewModel.customSecondaryTextRight = this.dateTimeFormatsService.formattedCurrency(opportunity.closedValueFormatted)
        viewModel.customSecondaryTextRightTwo = (opportunity.estimatedValue) ? this.dateTimeFormatsService.formattedCurrency(opportunity.estimatedValueFormatted) : ''
        this.openOpportunitiesViewDataModels.push(viewModel)
      })
      this.formattedOpportunities.Lost.forEach((opportunity) => {
        let viewModel: MainCardViewDataModel = {
          id: opportunity.ID,
          fixedHeight: true,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showIcon: false,
          iconName: '',
          primaryTextRight: opportunity.opportunityName,
          //secondaryTextRight:'',
          //secondaryTextRightTwo: (opportunity.closedValueFormatted) ? 'Actual ' + opportunity.closedValueFormatted : '',
          isSecondaryTextRightTwoPillType: true,
          showArrow: true,
          arrowType: 'chevron-forward-outline',
          clickHandler: (id: string, event) => {
            this.openOpportunityDetail(id)
          }
        }
        viewModel.customSecondaryTextRight = this.dateTimeFormatsService.formattedCurrency(opportunity.closedValueFormatted)
        viewModel.customSecondaryTextRightTwo = (opportunity.estimatedValue) ? this.dateTimeFormatsService.formattedCurrency(opportunity.estimatedValueFormatted) : ''
        this.lostOpportunitiesViewDataModels.push(viewModel)
      })
      this.formattedOpportunities.Canceled.forEach((opportunity) => {
        let viewModel: MainCardViewDataModel = {
          id: opportunity.ID,
          fixedHeight: true,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showIcon: false,
          iconName: '',
          primaryTextRight: opportunity.opportunityName,
          //secondaryTextRight:'',
          //secondaryTextRightTwo: (opportunity.closedValueFormatted) ? 'Actual ' + opportunity.closedValueFormatted : '',
          isSecondaryTextRightTwoPillType: true,
          showArrow: true,
          arrowType: 'chevron-forward-outline',
          clickHandler: (id: string, event) => {
            this.openOpportunityDetail(id)
          }
        }
        viewModel.customSecondaryTextRight = this.dateTimeFormatsService.formattedCurrency(opportunity.closedValueFormatted)
        viewModel.customSecondaryTextRightTwo = (opportunity.estimatedValue) ? this.dateTimeFormatsService.formattedCurrency(opportunity.estimatedValueFormatted) : ''
        this.canceledOpportunitiesViewDataModels.push(viewModel)
      })
      this.formattedOpportunities.Won.forEach((opportunity) => {
        let viewModel: MainCardViewDataModel = {
          id: opportunity.ID,
          fixedHeight: true,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showIcon: false,
          iconName: '',
          primaryTextRight: opportunity.opportunityName,
          //secondaryTextRight: (opportunity.estimatedValueFormatted) ? 'Est. ' + opportunity.estimatedValueFormatted : '',
          //secondaryTextRightTwo: (opportunity.closedValueFormatted) ? 'Actual ' + opportunity.closedValueFormatted : '',
          isSecondaryTextRightTwoPillType: true,
          showArrow: true,
          arrowType: 'chevron-forward-outline',
          clickHandler: (id: string, event) => {
            this.openOpportunityDetail(id)
          }
        }
        viewModel.customSecondaryTextRight = this.dateTimeFormatsService.formattedCurrency(opportunity.closedValueFormatted)
        viewModel.customSecondaryTextRightTwo = (opportunity.estimatedValue) ? this.dateTimeFormatsService.formattedCurrency(opportunity.estimatedValueFormatted) : ''
        this.wonOpportunitiesViewDataModels.push(viewModel)
      })
      this.openOpportunitiesHeader = {
        id: 'open-opportunties',
        primaryTextLeft: this.translate.instant('OPEN_OPPORTUNITIES'),
        showArrow: true,
        arrowType: this.expandedSection == 'open' ? 'chevron-up-outline' : 'chevron-down-outline',
        clickHandler: (id: string, event, clickTarget, viewDataRef) => {
          this.setExpanded('open')
          viewDataRef.arrowType = viewDataRef.arrowType == 'chevron-up-outline' ? 'chevron-down-outline' : 'chevron-up-outline'
        }
      }
      this.wonOpportunitiesHeader = {
        id: 'won-opportunties',
        primaryTextLeft: this.translate.instant('WON_OPPORTUNITIES'),
        showArrow: true,
        arrowType: this.expandedSection == 'won' ? 'chevron-up-outline' : 'chevron-down-outline',
        clickHandler: (id: string, event, clickTarget, viewDataRef) => {
          this.setExpanded('won')
          viewDataRef.arrowType = viewDataRef.arrowType == 'chevron-up-outline' ? 'chevron-down-outline' : 'chevron-up-outline'
        }
      }
      this.lostOpportunitiesHeader = {
        id: 'lost-opportunties',
        primaryTextLeft: this.translate.instant('LOST_OPPORTUNITIES'),
        showArrow: true,
        arrowType: this.expandedSection == 'lost' ? 'chevron-up-outline' : 'chevron-down-outline',
        clickHandler: (id: string, event, clickTarget, viewDataRef) => {
          this.setExpanded('lost')
          viewDataRef.arrowType = viewDataRef.arrowType == 'chevron-up-outline' ? 'chevron-down-outline' : 'chevron-up-outline'
        }
      }
      this.opportunitiesHeader = {
        id: 'Opportunities',
        title: this.translate.instant('OPPORTUNITIES_CAPS'),
        controls: []
      }
    }
    if (this.offtakeFeatureEnabled && !this.device.isOffline) {
      this.setCurrentMonthOfftakeCheck();
    }
  }

  private setCurrentMonthOfftakeCheck() {
    this.offTakeService.getSelectedMonthOfftakeCheck(this.accountId, moment().startOf('months').toDate(), true).then(offTakeChecks => {
      if (!_.isEmpty(offTakeChecks)) {
        this.currentMonthOffTake = offTakeChecks[0];
      }
    });
  }

  getAssetManagementHeader(){
    this.assetManagementSectionHeader = {
      id: 'asset-management-section-header',
      title: this.translate.instant('FIELD_MATERIAL_MANAGEMENT'),
      controls: [{
        id: "pluse-icon",
        iconName: 'chevron-forward-outline',
        isDisabled: this.device.isOffline || this.readOnly || !this.isAssetsEnabled || this.assetManagementList.length === 0,
      }]
    };
  }

  getProcedureContractHeader() {
    let buttons = [];

    if (this.authenticationService.user.buSettings?.['indskr_procedurecontracturl']) {
      buttons.push({
        id: 'refresh-contracts',
        iconClass: 'refresh-icon ',
        isDisabled: this.device.isOffline || this.readOnly
      });
    }

    buttons.push({
      id: "pluse-icon",
      iconClass: 'pluse-icon',
      isDisabled: this.readOnly
    });

    this.procedureContractHeader = {
      id: 'contract-details-header',
      title: this.translate.instant('PROCEDURE_CONTRACTS'),
      controls: buttons
    };
  }

  getQuoteHeader() {
    this.quoteHeader = {
      id: 'quote-details-header',
      title: this.translate.instant('QUOTES'),
      controls: [{
        id: "opentimelinedetails",
        iconName: 'chevron-forward-outline',
        isDisabled: !this.showQuoteSection || this.quoteList.length === 0,
      }]
    };
  }
  getAccountPlanHeader() {
    this.accountPlanHeader = {
      id: 'accountPlan-details-header',
      title: this.translate.instant('ACCOUNTPLAN'),
      controls: [{
        id: "opentimelinedetails",
        iconName: 'chevron-forward-outline',
        isDisabled: !this.showAccountPlanSection || this.selectedAccountPlans.length === 0 || this.device._isOffline
      }]
    };
  }

  getSalesOrderHeader() {
    this.salesOrderHeader = {
      id: 'salesOrder-details-header',
      title: this.translate.instant('SALES_ORDER'),
      controls: [{
        id: "pluse-icon",
        iconName: 'chevron-forward-outline',
        isDisabled: this.salesOrderList.length === 0,
      }]
    };
  }

  getOfftakeFeatureHeader() {
    this.offtakeCheckHeader = {
      id: 'OfftakeCheck',
      title: this.translate.instant('OFFTAKECHECK_CAPS'),
      controls: [
        {
          id: 'add-offtake-check',
          text: this.translate.instant('ADD'),
          isDisabled: this.device.isOffline || this.readOnly
        },
      ]
    };
  }

  private _initSurveySectionHeader() {
    const numOfList: number = this.AvailableFilteredSurveyList.length;
    this.surveyListSectionHeader = {
      id: 'survey_list_section_header',
      // title: `${this.translate.instant("AVAILABLE")} ${this.translate.instant("SURVEY")} (${numOfList})`,
      title: `${this.translate.instant("AVAILABLE_SURVEY")} (${numOfList})`,
      isShowSperationVerticalLine: true,
      controls: [
        {
          id: "goto-account-timeline",
          text: '',
          icon: "assets/imgs/survey-icon-blue-time.svg",
          isDisabled: this.readOnly //check available survey - submitted data
        },
        {
          id: 'goto-AllSurveyTemplate-list',
          text: '',
          icon: "assets/imgs/chevron-forward-outline-blue.svg",
          isDisabled: _.isEmpty(this.filteredSurveyList) || this.readOnly,
        }],
    };
  }

  public getModifiedByFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('MODIFIED_BY'),
      inputText: _.isEmpty(this.currentMonthOffTake?.modifiedby) ? '-' : this.currentMonthOffTake.modifiedby,
      id: 'modified-by-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getModifiedDateFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('MODIFIED_DATE'),
      inputText: this.currentMonthOffTake?.modifiedOn ? this.datePipe.transform(this.currentMonthOffTake.modifiedOn, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : '',
      id: 'modified-date-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  private getOpportunityMainCardModel(opportunity: Opportunity): MainCardViewDataModel {
    return {
      id: opportunity.ID,
      fixedHeight: true,
      primaryTextLeft: '',
      secondaryTextLeft: '',
      showIcon: false,
      iconName: '',
      primaryTextRight: opportunity.opportunityName,
      //secondaryTextRight: (opportunity.estimatedValueFormatted) ? 'Est. ' + opportunity.estimatedValueFormatted : '',
      //secondaryTextRightTwo: (opportunity.closedValueFormatted) ? 'Actual ' + opportunity.closedValueFormatted : '',
      isSecondaryTextRightTwoPillType: true,
      showArrow: true,
      arrowType: 'chevron-forward-outline',
      clickHandler: (id: string, event) => {
        this.openOpportunityDetail(id);
      }
    };
  }

  setExpanded(sectionName: string) {
    if (sectionName) {
      switch (sectionName) {
        case 'open':
          this.wonOpportunitiesHeader.arrowType = 'chevron-down-outline'
          this.lostOpportunitiesHeader.arrowType = 'chevron-down-outline'
          this.expandedSection = this.expandedSection == 'open' ? '' : 'open'
          break;
        case 'won':
          this.openOpportunitiesHeader.arrowType = 'chevron-down-outline'
          this.lostOpportunitiesHeader.arrowType = 'chevron-down-outline'
          this.expandedSection = this.expandedSection == 'won' ? '' : 'won'
          break;
        case 'lost':
          this.openOpportunitiesHeader.arrowType = 'chevron-down-outline'
          this.wonOpportunitiesHeader.arrowType = 'chevron-down-outline'
          this.expandedSection = this.expandedSection == 'lost' ? '' : 'lost'
          break;
      }
    }
  }

  getFormattedOpportunitiesList() {
    this.applicableOpportunities.forEach((oppo) => {
      oppo.state == 'Open' ? this.formattedOpportunities.Open.push(oppo)
        : oppo.state == 'Won' ? this.formattedOpportunities.Won.push(oppo)
          : oppo.statusCode != 548910002 ? this.formattedOpportunities.Lost.push(oppo)
            : this.formattedOpportunities.Canceled.push(oppo)
    })
  }

  public onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'add-offtake-check':
        this.addOfftakeCheck();
        break;
      case 'goto-account-timeline':
        this.accountOfflineService.isOpenedSurveyTimelineFromAccountProfile = true;
        // if (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER360UI,true)) {
        //   this.uiService.timelineRefreshRequired = true;
        //   this.navService.pushChildNavPageWithPageTracking(AccountTimelineComponent, PageName.AccountTimelineComponent,PageName.AccountPageComponent);
        // }else {
        this.uiService.accountDataSegment = 'timeline';
        this.events.publish('refershAccountDetailSegment');
        // }
        break;
      case 'goto-AllSurveyTemplate-list':
        this._openSurveyListPage();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  openOpportunityDetail(id: string) {
    if (this.isReadOnly) return;
    this.navService.pushChildNavPageWithPageTracking(OpportunityDetailsComponent,
      PageName.OpportunityDetailsComponent, PageName.AccountPageComponent, { from: 'AccountProfileOpportunities', opportunity: this.opportunityService.getOpportunityDetailsbyId(id) })
  }

  public getOpportunityDataModel(section: string): MainCardViewDataModel {
    return {
      id: section + '-opportunities',
      fixedHeight: true,
      primaryTextRight: section === 'open' ? this.translate.instant('OPEN_OPPORTUNITIES')
        : section === 'won' ? this.translate.instant('WON_OPPORTUNITIES')
          : section === 'lost' ? this.translate.instant('LOST_OPPORTUNITIES')
            : section === 'canceled' ? this.translate.instant('DISCONTINUED_OPPORTUNITIES') : '',
      isAccordionForm: true,
      showAccordionIcon: true,
      accordionIconType: this.expandedSection === section ? 'accordion-minus' : 'accordion-add',
      isAccordionText: this.expandedSection === section,
      noSeparationLine: true,
      clickHandler: (id: string, ev) => {
        this.expandOpportunityList(ev, section);
      }
    };
  }

  expandOpportunityList(event, section: string) {
    this.expandedSection = this.expandedSection === section ? 'none' : section
  }

  setProcedureContractsProfileList() {
    this.timelineItems = [];
    const today = new Date();
    today.setHours(0, 0, 0);
    const hideProcedure = this.authenticationService.user.buSettings?.['indskr_hideprocedureproductcategory'];


    let procedureContractsForAccount = this.contractService.procedureContracts.filter((item: ProcedureContract) =>
      item.indskr_account_value == this.accountId &&
      !item.indskr_parentprocedurecontractid &&
      this.contractService.isActiveContract(item)
    ).sort((a, b) => new Date(b.createdon).getTime() - new Date(a.createdon).getTime());

    procedureContractsForAccount.forEach(async item => {
      let view;
      view = await this._getProfileItemViewForProcedureContracts(item, hideProcedure)
      if (view) {
        if (this.readOnly) view.disableEndArrow = true;
        this.timelineItems.push(view);
      }
    });
  }

  async setQuoteProfileList(quote) {
    this.quoteList = [];
    quote = quote.sort((a, b) => new Date(b.createdon).getTime() - new Date(a.createdon).getTime());
    const latestTwoQuotes = quote.slice(0, 2);

    const viewPromises = latestTwoQuotes.map(async item => {
      const view = await this._getProfileItemViewForQuote(item);
      if (view) {
        if (this.readOnly) view.disableEndArrow = true;
        return view;
      }
      return null;
    });

    const views = await Promise.all(viewPromises);
    this.quoteList = views.filter(view => view !== null);
    this.getQuoteHeader();
  }

  async setAccountPlanProfileData(accountPlan) {
    let currentAccountPlan = accountPlan.filter(acc => {
      if (acc.startDate != '' && acc.endDate != '') {
        let today = new Date().getTime()
        if (parseInt(acc.startDate) <= today && parseInt(acc.endDate) >= today) return true;
        return false;
      }
    })
    this.accountPlanList = [];
    accountPlan = currentAccountPlan.sort((a, b) => {
      if (a.startDate == "" && b.startDate == "") return 0;
      if (a.startDate == "") return 1
      if (b.startDate == "") return -1
      return parseInt(b.startDate) - parseInt(a.startDate)
    });
    const latestTwoAccountPlan = accountPlan.slice(0, 2);
    const viewPromises = latestTwoAccountPlan.map(async item => {
      const view = await this.c360Service._getProfileItemViewForAccountPlan(item);
      if (view) {
        if (this.readOnly) view.disableEndArrow = true;
        return view;
      }
      return null;
    });
    const views = await Promise.all(viewPromises);
    this.accountPlanList = views.filter(view => view !== null);
    this.getAccountPlanHeader();
  }

  async setSalesOrderProfileList() {
    this.salesOrderList = [];
    let orderlist = this.activityService.activities
      .filter(order => order instanceof OrderActivity && order.type === ActivityType.Order && !order.isHardDeleted && order.accountId == this.accountId)
      .sort((a: any, b: any) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()) as Array<OrderActivity>;

    const latestTwoOrder = orderlist.slice(0,2);
    const viewPromises = latestTwoOrder.map(async item => {
      const view = await this._getProfileItemViewForSalesOrder(item);
      if (view) {
        if (this.readOnly) view.disableEndArrow = true;
        return view;
      }
      return null;
    });

    const views = await Promise.all(viewPromises);
    this.salesOrderList = views.filter(view => view !== null);
    this.getSalesOrderHeader();
  }

  async setAssetManagementList() {
    let myAssets = this.fieldMaterialManagementService.customerAssets;
    this.assetManagementList = myAssets
      .filter(asset => asset.msdyn_account === this.accountId)
      .sort((a, b) => new Date(b.createdon).getTime() - new Date(a.createdon).getTime())
      .slice(0, 2)
      .map(asset => this._getProfileItemViewForAsset(asset));
    this.getAssetManagementHeader();
  }

  private _getProfileItemViewForAsset(asset: CustomerAsset): TimeLineItem {
    return {
      subheaderText: "", subheaderText2: "",
      id: asset.msdyn_customerassetid,
      headerText: asset.msdyn_name,
      subheaderText2Color: 'primary',
      mainIcon: 'assets/imgs/tools_field_material_gray.svg',
      customCssClass: 'normal-list',
      dataToDisplay: [
        {label: 'Status', value: asset.indskr_assetstatus},
        {label: 'Product', value: asset.productName},
        {label: 'Category', value: asset.categoryName},
        {label: 'Serial Number', value: asset.indskr_serialnumber}
      ],
      refObject: asset
    };
  }

  private _getProfileItemViewForProcedureContracts(item: ProcedureContract, hideProcedure): any {
    let view: TimeLineItem;
    let contractStartDayValue = this.datePipe.transform(item.indskr_startdate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let contractEndDayValue = this.datePipe.transform(item.indskr_enddate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    const statusString = this.contractService.getStatusString(item.statuscode) ?? item.statusString;

    view = {
      id: '',
      refObject: item,
      subheaderText: '',
      subheaderText2: '',
      subheaderText2Color: 'primary',
      headerText: item.indskr_name,
      customCssClass: 'normal-list',
      mainIcon: "assets/imgs/procedure_contract.svg",
      dataToDisplay: [
        {
          label: this.translate.instant('STATUS'),
          value: statusString,
          valuePopupText: '',
        },
        {
          label: this.translate.instant('CONTRACT_TYPE'),
          value: item.contractTypeString,
          placeholderText: this.translate.instant('NO_CONTRACT_TYPE'),
          valuePopupText: '',
        },
        {
          label: this.translate.instant('SUB_TYPE'),
          value: item.proceudreSubTypeString,
          valuePopupText: '',
        },
        {
          label: this.translate.instant('START_DATE'),
          value: contractStartDayValue,
          valuePopupText: '',
        },
        {
          label: this.translate.instant('END_DATE'),
          value: contractEndDayValue,
          valuePopupText: '',
        },
        {
          label: this.translate.instant('PRODUCT'),
          value: item.productName,
          placeholderText: this.translate.instant('NO_PRODUCTS'),
          valuePopupText: '',
        },
      ]
    };
    if (!hideProcedure) {
      view.dataToDisplay.push({
        label: this.translate.instant('PROCEDURE'),
        value: item.procedureName,
        placeholderText: this.translate.instant('NO_PROCEDURE'),
      });
    }

    return view;
  }

  private _getProfileItemViewForQuote(item): any {
    let view: TimeLineItem;
    let effectiveTo = this.datePipe.transform(item.effectiveto, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    const statusString = this.quotesOfflineService.getFormattedStatus(item.statuscode) ?? item.statusString;

    view = {
      id: '',
      refObject: item,
      subheaderText: '',
      subheaderText2: '',
      subheaderText2Color: 'primary',
      headerText: item.name,
      customCssClass: 'normal-list',
      mainIcon: "assets/imgs/tool-icon-Account-Profile.svg",
      dataToDisplay: [
        {
          label: this.translate.instant('STATUS'),
          value: statusString,
          valuePopupText: '',
        },
        {
          label: this.translate.instant('AMOUNT'),
          value: item.totalAmount ? this.quotesOfflineService.getFormattedValue(parseInt(item.totalAmount), item) : '0.00',
          placeholderText: this.translate.instant('NO_AMOUNT'),
          valuePopupText: '',
        },
        {
          label: this.translate.instant('PRODUCTS'),
          value: (item.products && item.products.length > 0) ? item.products[0].name : '',
          placeholderText: this.translate.instant('NO_PRODUCTS'),
          valuePopupText: (item.products && item.products.length > 1) ? '+ ' + (item.products.length - 1) : '',
          popupListData: (item.products && item.products.length > 1) ? item.products.slice(1).map(a => {
            let obj = {
              id: a.id,
              title: a.name,
            };
            return obj;
          }) : [],
        },
        {
          label: this.translate.instant('EFFECTIVE_TO'),
          value: effectiveTo,
          valuePopupText: '',
        }
      ]
    };
    return view;
  }
  openAccountPlanPage(item) {
    let view =this.accountmanagementOfflineService.selectedMode = AccountPlanMode.VIEW
    this.navService.pushChildNavPageWithPageTracking(AccountPlanDetails, PageName.AccountPlanDetailsComponent, PageName.AccountPageComponent, {
      data: item.refObject,
      selectedMode:view,
      from: 'AccountTimeline'
    });
    this.accountmanagementOfflineService.selectedAccountPlan$.next(item.refObject);
  }
  onAccountPlanSectionClick() {
    this.navService.pushChildNavPageWithPageTracking(AccountTimelinePageComponent, PageName.AccountTimelinePageComponent, PageName.AccountPageComponent, {
      from: 'accountPlan-section',
      searchFilterEnabled: false,
    });
  }

  private _getProfileItemViewForSalesOrder(item : OrderActivity): any {
    let view: TimeLineItem;
    view = {
      id: '',
      refObject: item,
      subheaderText: '',
      subheaderText2: '',
      subheaderText2Color: 'primary',
      headerText: item.name,
      customCssClass : 'normal-list',
      mainIcon: this.activityService.getActivityIconForTimeLine(item),
      dataToDisplay: [
        {
          label: this.translate.instant('STATUS'),
          value:  item.orderStatusString,
          valuePopupText: '',
        },
        {
          label: this.translate.instant('ORDER_NUMBER'),
          value: item.orderNumber,
          valuePopupText: '',
        },
        {
          label: this.translate.instant('PRODUCTS'),
          value: (item.products && item.products.length > 0) ? item.products[0].productName : '',
          placeholderText: this.translate.instant('NO_PRODUCTS'),
          valuePopupText: (item.products && item.products.length > 1) ? '+ ' + (item.products.length - 1) : '',
          popupListData: (item.products && item.products.length > 1) ? item.products.slice(1).map(a => {
            let obj = {
              id: a.productId,
              title: a.productName,
            };
            return obj;
          }) : [],
        },
        {
          label: this.translate.instant('TOTAL'),
          value: item.formattedTotalAmount ? this.dateTimeFormatsService.formattedCurrency(item.formattedTotalAmount) : '0',
          placeholderText: this.translate.instant('TOTAL'),
          valuePopupText: '',
        }
      ]
    };
    return view;
  }

  openProcedureContactPage(item) {
    this.contractService.isProcedureContractCreationActive = false;
    this.navService.pushChildNavPageWithPageTracking(SurgeryOrderContractDetailsComponent, PageName.SurgeryOrderContractDetailsComponent, PageName.AccountPageComponent,
      {
        procedureContract: item.refObject,
        viewMode: ComponentViewMode.READONLY
      });
  }

  openQuotePage(item) {
    this.navService.pushChildNavPageWithPageTracking(QuoteDetailsComponent, PageName.QuoteDetailsComponent, PageName.AccountPageComponent, {
      selectedQuote: item.refObject,
      nestedRoute: true,
    });
  }

  openSaleOrderPage(item) {
    const foundOrder: OrderActivity = (this.activityService.getActivityByID(item.refObject.ID) as OrderActivity);
    this.activityService.selectedActivity = foundOrder;
    this.navService.pushChildNavPageWithPageTracking(OrderDetailsComponent, PageName.OrderDetailsComponent, PageName.AccountPageComponent, {
      from: 'AccountTimeline', activity: foundOrder
    });
  }

  openAssetManagementPage(item: TimeLineItem) {
    const asset = item.refObject as CustomerAsset;
    this.fieldMaterialManagementService.selectedAsset = asset;
    this.navService.pushChildNavPageWithPageTracking(
      AssetDetailsComponent,
      PageName.AssetDetailsComponent,
      PageName.AccountPageComponent,
      {
        selectedAsset: asset,
        nestedRoute:true,
        from: 'AccountTimeline'
      }
    );
  }

  onAssetManagementSectionClick() {
    this.navService.pushChildNavPageWithPageTracking(AccountTimelinePageComponent, PageName.AccountTimelinePageComponent, PageName.AccountPageComponent, {
      searchFilterEnabled: false,
      filterEnabled: false,
      from: 'asset-management-section'
    });
  }

  onProcedureContractSectionClick(id: string) {
    switch (id) {
      case 'pluse-icon':
        this.handlePlusButton();
        break;
      case 'refresh-contracts':
        this.syncLatestContracts();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  onQuoteSectionClick() {
    this.navService.pushChildNavPageWithPageTracking(AccountTimelinePageComponent, PageName.AccountTimelinePageComponent, PageName.AccountPageComponent, {
      searchFilterEnabled: false,
      filterEnabled: true,
      from: 'quotes-section'
    });
  }

  onSalesOrderSectionClick() {
    this.navService.pushChildNavPageWithPageTracking(AccountTimelinePageComponent, PageName.AccountTimelinePageComponent, PageName.AccountPageComponent, {
      searchFilterEnabled: false,
      filterEnabled: true,
      from: 'salesorder-section'
    });
  }

  async syncLatestContracts() {
    await this.uiService.displayLoader();
    await this.contractService.fetchProcedureContracts(false, false);
    await this.uiService.dismissLoader();
  }

  async handlePlusButton() {
    const url = this.authenticationService.user.buSettings?.['indskr_procedurecontracturl'];
    if (url) {
      this.openProcedureContractUrl(url);
    } else {
      try {
        let item = this.contractService.generateContract(this.accountId, this.accountOfflineService.selected.accountName);
        const payload = this.contractService.generatePayload(item);
        if (!this.device.isOffline) {
          this.uiService.displayLoader();
          const response: any = await this.contractService.createProcedureContractOnline(payload);
          if (response) {
            item.indskr_procedurecontractid = response.indskr_procedurecontractid;
          }
          this.uiService.dismissLoader();
        }
        let newProcedureContract = new ProcedureContract(item);
        if (this.device.isOffline) {
          newProcedureContract.isNew = true;
          newProcedureContract.pendingPushToDynamics = true;
          this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PROCEDURE_CONTRACT, 1);
        }
        this.contractService.CreateProcedureContractInLocal(newProcedureContract);
        this.contractService.isProcedureContractCreationActive = true;
        this.navService.pushChildNavPageWithPageTracking(SurgeryOrderContractDetailsComponent, PageName.SurgeryOrderContractDetailsComponent, PageName.AccountPageComponent,
          {
            procedureContract: newProcedureContract,
            viewMode: ComponentViewMode.ADDNEW
          });
      } catch (error) {
        this.uiService.dismissLoader();
        console.log(error);
        //! error handling should be done notifications
      }
    }

  }

  private _initSurveyList() {
    this.filteredSurveyList = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY && template?.indskr_surveytype != SurveyType.INTERNAL && new Date(template.indskr_validfrom) <= new Date() && new Date(template.indskr_validto) >= new Date() && template.indskr_entity == 'account');
    this.customerSurveyService.surveyTemplates.filter(list => list.indskr_surveytype != SurveyType.INTERNAL);
    if (!_.isEmpty(this.filteredSurveyList)) {
      this._initViewData();
    }
  }

  private _initViewData() {
    this.filteredSurveyListToDisplay = [];
    this.AvailableFilteredSurveyList = [];
    this.filteredSurveyList = _.orderBy(this.filteredSurveyList, ['indskr_validto', 'indskr_name'], ['desc', 'asc']);
    this.filteredSurveyList.forEach((survey) => {
      const templateId: string = survey.indskr_assessmenttemplateid;
      const selectedAccountId = this.accountOfflineService.selected?.id;
      let isEligibleAccount: boolean = false;
      //check eligible customers
      if (survey.indskr_metadata.hasOwnProperty('EligibleCustomers') && !_.isEmpty(survey.indskr_metadata.EligibleCustomers)) {
        let eligibleAccountIDs: string[] = [];
        const eligibleAccountsFromMetaData = survey.indskr_metadata['EligibleCustomers'];
        eligibleAccountIDs = eligibleAccountsFromMetaData.map(account => account['ID']);
        isEligibleAccount = eligibleAccountIDs.some(accoountId => accoountId == selectedAccountId);
      } else {
        isEligibleAccount = true;
      }
      const surveyedAccountsByTemplate = this.customerAssessService.surveyedAccountsForOnetimeSurvey[templateId];
      let isCapturedOneTimeSurvey: boolean = false;
      if (!_.isEmpty(surveyedAccountsByTemplate)) {
        isCapturedOneTimeSurvey = surveyedAccountsByTemplate.some((template) => template['indskr_entityid'] == selectedAccountId);
      }
      // const isCapturedOneTimeSurvey: boolean = this.customerAssessService.isCapturedOneTimeSurvey(templateId, selectedAccountId, 'account');
      //To display top 2 available surveys based on eligible dates with eligible customers, without capturing one-time surveys
      if (this.filteredSurveyListToDisplay.length < 2) {
        if (isEligibleAccount) {
          //check captured one-time survey
          if (survey.indskr_surveyfrequency == SurveyFrequency.ONCE) {
            if (!isCapturedOneTimeSurvey) {
              this.filteredSurveyListToDisplay.push(this._getSubCardDataModel(survey));
            }
          } else {
            this.filteredSurveyListToDisplay.push(this._getSubCardDataModel(survey));
          }
        }
      }
      //update number of available survey
      if (isEligibleAccount) {
        //check captured one-time survey
        if (survey.indskr_surveyfrequency == SurveyFrequency.ONCE) {
          if (!isCapturedOneTimeSurvey) {
            this.AvailableFilteredSurveyList.push(survey);
          }
        } else {
          this.AvailableFilteredSurveyList.push(survey);
        }
      }
    });
  }

  private _getSubCardDataModel(survey: AssessmentTemplate): SubCardViewDataModel {
    const viewData: SubCardViewDataModel = {
      id: survey.indskr_assessmenttemplateid,
      primaryTextRight: survey.indskr_name,
      isSubPrimaryTextRight: true,
      subPrimaryTextRight: this.translate.instant('EXPIRES_ON') + ' ' + this._getFormattedDate(survey['indskr_validto']),
      isSecondaryGrid: true,
      gridItems: [],
      startIconInline: "assets/imgs/survey_blue_bluedot.svg",
      buttonItems: [],
      clickHandler: (id: string, event, specificTarget) => { }
    };
    //grid items
    let gridItems: any[];

    if (survey.indskr_description) {
      gridItems = [
        {
          id: 'description',
          label: '',
          value: survey.indskr_description,
          descriptionField: true,
        },
        {
          id: 'type',
          label: this.translate.instant('TYPE'),
          value: survey.indskr_surveytype === SurveyType.IN_MEETING ? this.translate.instant('IN_MEETING') : this.translate.instant('SURVEY_ACCOUNT_SURVEY'),
          rawValue: survey['indskr_surveytype'],
        },
        {
          id: 'use-type',
          label: this.translate.instant('USE_TYPE'),
          value: this.getSurveyFrequency(survey),
          rawValue: survey['indskr_surveyfrequency'],
        },
      ];
    } else {
      gridItems = [
        {
          id: 'type',
          label: this.translate.instant('TYPE'),
          value: survey.indskr_surveytype === SurveyType.IN_MEETING ? this.translate.instant('IN_MEETING') : this.translate.instant('SURVEY_ACCOUNT_SURVEY'),
          rawValue: survey['indskr_surveytype'],
        },
        {
          id: 'use-type',
          label: this.translate.instant('USE_TYPE'),
          value: this.getSurveyFrequency(survey),
          rawValue: survey['indskr_surveyfrequency'],
        },
        {
          id: 'status',
          // label: this.translate.instant("SURVEY_ACCOUNT_SURVEYED"),
          label: this.translate.instant("ACCOUNT_ELIGIBLE"),
          value: this.getAccountSurveyed(survey),
          rawValue: undefined,
        },
      ];
    }
    viewData.gridItems = gridItems;
    //buttons - If external type and one-time survey is captured, start-survey button is disabled, start-survey on preview is disabled
    viewData.buttonItems.push(
      {
        id: 'preview',
        name: this.translate.instant("PREVIEW"),
        isDisabled: this.accountOfflineService.accountPageMode != ComponentViewMode.LISTVIEW
      },
    );

    if (survey.indskr_surveytype !== SurveyType.IN_MEETING) {
      viewData.buttonItems.push(
        {
          id: 'start-survey',
        name: this.translate.instant("START_SURVEY"),
        isDisabled: this.accountOfflineService.accountPageMode != ComponentViewMode.LISTVIEW
        }
      );
    }
    return viewData;
  }

  private getAccountSurveyed(survey: AssessmentTemplate): string {
    return this.customerAssessService.getNumOfAccountSurveyed(survey);
  }

  private getSurveyFrequency(survey: AssessmentTemplate) {
    return survey.indskr_surveyfrequency == SurveyFrequency.ONCE ? this.translate.instant("ONE_TIME") : this.translate.instant("RECURRING");
  }

  private _getFormattedDate(date: any): string {
    const formattedDate = this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    return formattedDate;
  }

  async addOfftakeCheck() {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('OFFLINE_OFFTAKE_CLICK_ADD'), "Meeting Details", "top", ToastStyle.DANGER, 3000);
      return
    }
    const model = await this.modalCtrl.create({
      component: OfftakeCheckComponent, componentProps: {
        selectedAccount: this.accountOfflineService.selected,
      }, animated: false, backdropDismiss: false
    });
    model.present();
    model.onDidDismiss().then(obj => {
      if (obj?.data?.currentMonthData)
        this.currentMonthOffTake = obj.data.currentMonthData;
    });
  }

  public onButtonControlClick(event) {
    if (!_.isEmpty(event) && !_.isEmpty(event.button)) {
      const buutonId = event.button.id;
      const surveyTemplate = event.viewData;
      console.log("clicked button ", buutonId);
      if (buutonId == 'preview') {
        this.openSurvey(surveyTemplate, true);
      } else if (buutonId == 'start-survey') {
        this.openSurvey(surveyTemplate, false);
      }
    }
  }

  async openSurvey(selectedTemplateFromCardView: any, isReadOnly: boolean) {
    if (!_.isEmpty(selectedTemplateFromCardView)) {
      await this.uiService.displayLoader();
      const rawSurveyTemplateList = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY && new Date(template.indskr_validfrom) <= new Date() && new Date(template.indskr_validto) >= new Date);
      const foundTemplate: AssessmentTemplate = rawSurveyTemplateList?.find(template => template.indskr_assessmenttemplateid == selectedTemplateFromCardView.id);
      if (!_.isEmpty(foundTemplate)) {
        this.customerSurveyService.setCurrentSurvey(foundTemplate);
        try {
          await this.navService.pushChildNavPageWithPageTracking(SurveyDetailsComponent, PageName.SurveyDetailsComponent, PageName.AccountDetailsComponent, { appointmentId: '', from: PageName.AccountDetailsComponent }).then(async () => {
            this.navService.setChildNavRightPaneView(true);
            this.uiService.dismissLoader();
            this.customerSurveyService.selectedAccountForSurvey = this.accountOfflineService.selected ? this.accountOfflineService.selected : this.accountOfflineService.selectedAccounts[0] ?? null;

            if (!isReadOnly) {
              //check saved survey data from db before starting the survey
              const accountId: string = this.customerSurveyService.selectedAccountForSurvey.id || '';
              const templateId: string = foundTemplate.indskr_assessmenttemplateid || '';
              if (!_.isEmpty(accountId) && !_.isEmpty(templateId)) {
                // let surveysFromDB = await this.customerAssessService.getCustomerSurveyFromDB(templateId);
                let surveysByTemplateId = this.customerAssessService.getSurveysByTemplateId(templateId, 'account', false);
                if (!_.isEmpty(surveysByTemplateId)) {
                  let surveyDataFromDB: CustomerAssessment;
                  let savedSurvey = surveysByTemplateId.find(survey => survey.surveyStatus === SurveyStatus.SAVED && survey.indskr_entityid === accountId);
                  if (savedSurvey) {
                    surveyDataFromDB = savedSurvey;
                  }
                  // response update
                  const responseData = await this.customerAssessService.fetchContactSurveyForDetails(surveyDataFromDB, 'account', true);
                  if (!_.isEmpty(responseData)) {
                    surveyDataFromDB.responses = responseData;
                  }
                  this.customerSurveyService.setSurveyPreviewMode(false, surveyDataFromDB);
                } else {
                  //There is no saved data with the selected contact and survey template
                  this.customerSurveyService.setSurveyPreviewMode(false);
                }
              }
            } else {
              //preview template
              this.customerSurveyService.setSurveyPreviewMode(true);
            }
          });
        } catch (error) {
          console.log("failed to open survey ", error);
          this.uiService.dismissLoader();
        }
      } else {
        console.log("There is no found template before opening the survey");
        this.uiService.dismissLoader();
      }
    }
  }

  private async _openSurveyListPage() {
    await this.uiService.displayLoader();
    try {
      await this.navService.pushChildNavPageWithPageTracking(SurveyListCardviewComponent, PageName.SurveyDetailsComponent, PageName.AccountDetailsComponent, { appointmentId: '', from: PageName.AccountDetailsComponent }).then(async () => {
        this.navService.setChildNavRightPaneView(true);
        this.uiService.dismissLoader();
        this.customerSurveyService.selectedAccountForSurvey = this.accountOfflineService.selected ? this.accountOfflineService.selected : this.accountOfflineService.selectedAccounts[0] ?? null;
      });
    } catch (error) {
      console.log("failed to open survey ", error);
      this.uiService.dismissLoader();
    }
  }

  private openProcedureContractUrl(url) {
    let additionalParams = 'indskr_account={' + this.accountId + '}&indskr_accountname=' + this.accountOfflineService.selected.accountName;
    url = url + '&extraqs=' + encodeURIComponent(additionalParams);
    if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
      let browser;

      browser = this.inAppBrowser.create(url, '_system', { location: 'yes', toolbar: 'yes', zoom: 'no' });

      browser.on('loadstop').subscribe(() => {
        browser.executeScript({
          code: `let button = document.getElementById('indskr_procedurecontract|NoRelationship|Form|indskr.indskr_procedurecontract.closebutton.Command30-button'), // Replace with your button's ID
            if (button) {
                button.addEventListener('click', function() {
                    window.location.href = 'closeinappbrowser://close'; // Custom scheme to trigger close
                });
            }`

        });
      });
      browser.on('loadstart').subscribe(event => {
        if (event.url.startsWith('closeinappbrowser://')) {
          browser.close();
        }
      });
    } else if (this.device.deviceFlags.electron) {
      electronApi.openExternal(url);
    } else {
      window.open(url, "_system");
    }

  }

  public trackById(index: number, item: any): any {
    return item.id;
  }
}
