import { Component, Input } from "@angular/core";

@Component({
  selector: 'ind-simple-tooltip',
  templateUrl: 'ind-simple-tooltip.html',
  styleUrls: ['ind-simple-tooltip.scss'],
})
export class IndSimpleTooltipComponent {
  @Input() text: string;
}
