import { Component, ElementRef, HostBinding, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Contact } from '@omni/classes/contact/contact.class';
import _ from 'lodash';

const CARD_FULL_HEIGHT_PADDING_AT_TOP = 24;
const CARD_HEADER_FOOTER_HEIGHT_COMBINED = 44 + 64;
const CARD_EMPTY_CONTENT_MIN_HEIGHT = 77;

@Component({
  selector: 'io-contact-list',
  templateUrl: './io-contact-list.html',
  styleUrls: ['./io-contact-list.scss'],
})
export class IoContactList implements OnInit {
  @Input()
  public contacts: Contact[];
  @Input()
  public selectedContactIds: string[];
  @Input()
  public maxHeightPercent: number;
  public isSelectDisabled: boolean = true;
  public selectedContactCount: number;

  @HostBinding('class.ion-page') pageClass = false;
  @ViewChild('content', { read: ElementRef }) content;
  @ViewChild('contentWrapper', { read: ElementRef }) contentWrapper;

  constructor(private modalCtrl: ModalController,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit() {
    this.selectedContactCount = this.contacts?.filter(con => con.selected).length;
  }

  ngAfterViewInit() {
    setTimeout(async () => {
      // Check for the modal margin & padding
      const modalEl = await this.modalCtrl.getTop();
      const isDynamicModal = modalEl && modalEl.classList.contains('dynamic-height-modal');
      const modalWrapperEl = isDynamicModal ? modalEl.querySelector('.modal-wrapper') : null;
      const parsedParentMarginTop = modalWrapperEl ? parseFloat(window.getComputedStyle(modalWrapperEl, null)?.getPropertyValue('margin-top')) : 0;
      const parentMarginTop = !isNaN(parsedParentMarginTop) ? parsedParentMarginTop : 0;
      const parsedParentPaddingBottom = modalWrapperEl ? parseFloat(window.getComputedStyle(modalWrapperEl, null)?.getPropertyValue('padding-bottom')) : 0;
      const parentPaddingBottom = !isNaN(parsedParentPaddingBottom) ? parsedParentPaddingBottom : 0;
      const maxModalHeight = this.maxHeightPercent ? window.innerHeight * (this.maxHeightPercent / 100) : window.innerHeight - CARD_FULL_HEIGHT_PADDING_AT_TOP;

      const maxContentHeight = maxModalHeight - CARD_HEADER_FOOTER_HEIGHT_COMBINED - parentMarginTop - parentPaddingBottom;
      const contentHeight = Math.min(maxContentHeight, Math.max(CARD_EMPTY_CONTENT_MIN_HEIGHT, this.contentWrapper.nativeElement.offsetHeight));
      this.setContentHeight(contentHeight);
    }, 40);
  }

  selectCustomer(customer: Contact) {
    if (customer.hasActiveConsent) {
      customer['selected'] = !customer['selected'];
      this.isSelectDisabled = false;
      this.selectedContactCount = this.contacts.filter(con => con.selected).length;
    }
  }

  closeModal(action) {
    console.log("closeModal")
    this.modalCtrl.dismiss({ isDone: action, data: this.contacts });
  }

  private setContentHeight(height: number) {
    if (height && this.content?.nativeElement) {
      this.renderer.setStyle(
        this.content.nativeElement,
        'height',
        `${height}px`,
      );
    }
  }

}
