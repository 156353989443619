import { Activity } from './activity.class';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { addMinutes } from 'date-fns';
import { TrackAction } from '../../utility/common-enums';
import { Utility } from '../../utility/util';
import { OrderProductClass, OrderTypes } from './order.activity.class';
import * as moment from 'moment';
import { IONote } from '../io/io-note.class';
import * as _ from 'lodash'
import { Products } from '../presentation/presentation.class';
import { CoOwner, CoVisitor, Opportunities } from './procedure-tracker.activity.class';
import { ChannelType } from '../consent/channel.class';
import { ProcedureContract } from '../procedure-contract/procedure-contract.class';
export class SurgeryOrderActivity extends Activity {

    public offlineId : string;
    public ownerNameString : string;
    public accountId : string;
    public accountNameString : string;
    public isAccountActive : boolean;
    public createdDate : Date;
    public pendingPushToDynamics : boolean;
    public products : Array<OrderProductClass> = [];
    public isTeamOrder : boolean = false;
    public isHardDeleted : boolean = false;
    public customers : Array <OrderContact> = [];
    public assets : Array<OrderAsset> = [];
    public customersNameString : string;
    public surgeryId : string;
    public surgeryNameString : string;
    public productCategory : Array <OrderProductCategory> = [];
    public orderType: any;
    public orderTypeFormatted: OrderTypes;
    public weekOfYear: number;
    public year: number;
    public month: number;
    public appointmentId : string;
    public offlineMeetingId : string;
    public indskr_noprocedureday : boolean;
    public procedureNotes: Array<IONote> = [];
    public indskr_datecompleted: string;
    public surveyResponse:any;
    public surveyResponseData:any;
    public surveyResponseLookupData:any;
    public surveyResponseAdditionalData:any;
    public surveyResponseDTO:any = {};
    public coOwners: CoOwner[] = [];
    public opportunities: Opportunities[] = [];
    public modifiedOn: Date;
    public modifiedBy: string;
    public activityOutcomeId:string;
    public activityOutcomeString:string;
    public marketingBusinessPlanId : string;
    public marketingBusinessPlanName : string;
    public indskr_subjectedited: boolean;

    public indskr_coachingplan : string;
    public indskr_coachingplanname : string;
   
    public opportunityId?: string
    public opportunityNameString?: string
    public offlineOpportunityId: string;
    public competitorProducts: Array<OrderProductClass> = [];

    public indskr_bwreparrives: Date;
    public indskr_bwrepleaves: Date;
    public indskr_ptinroom: Date;
    public indskr_ptready: Date;
    public indskr_ptoutofroom: Date;
    public indskr_procstartneedle: Date;
    public indskr_transseptalaccess: Date;
    public indskr_transseptalaccessfluorotime: Number;
    public indskr_mappingcomplete: Date;
    public indskr_mappingcompletefluorotime: Number;
    public indskr_procedurecomplete: Date;
    public indskr_procedurecompletefluorotime: Number;

    public indskr_ablpoints : Number;
    public indskr_totalmappingpoints: Number;
    public indskr_averagerfpowerwatts: Number;
    public indskr_posteriorleft: Number;
    public indskr_posteriorright: Number;
    public indskr_interiorleft: Number;
    public indskr_interiorright: Number;
    public indskr_anteriorleft: Number;
    public indskr_anteriorright: Number;
    public indskr_roofleft: Number;
    public indskr_roofright: Number;
    public indskr_superiorleft: Number;
    public indskr_superiorright: Number;
    public indskr_ridge: Number;
    public indskr_fluiddelivered: Number;
    // public indskr_ctmerged: Number;
    public indskr_ctmergednew: boolean;
    public channelType: ChannelType;

    public indskr_proceduretype : any;
    public indskr_proceduresubtype : any;
    
    public indskr_proceduretypename : string;
    public indskr_proceduresubtypename: string;
    public indskr_reasonforcancellation : string
    public indskr_cancellationreasonother : string;
    public indskr_reasonforcancellationname : string;
    public cancellationreason:string;
    public coVisitors: CoVisitor[] = [];
    
    
    public indskr_procedurecontract : string;
    public contract : ProcedureContract;
    public indskr_procedurecontractname :string;
    public contractEndDate :Date;
    public contractUsageType : number;
    public contractStatus : number;
    public maximumnoofassistance :number;
    public contractStartDate :Date;
    public noofassistanceavailed :number ;
    public jnjjapan_shorei :string;
    public jnjjapan_shoreiname:string;
    public indskr_assetbookingid: string;
    
    public get isSocialChannel() {
      return [ChannelType.SURGERY_ORDER].some(c => c === this.channelType);
  }

    constructor(raw: any) {
        super(raw);
        if(!this.ID){
            this.ID = raw['salesorderid'];
            if(!this.ID){
                this.ID = raw['indskr_externalid'];
            }
        }
        this.offlineId = (raw['indskr_externalid']) ? raw['indskr_externalid'] : '';
        this.ownerId = raw['ownerid'] || '';
        this.ownerNameString = (raw['ownerName']) ? raw['ownerName'] : '';
        if(raw['statuscode']){
            this.status = parseInt(raw['statuscode']);
        }
        this.channelType = raw['channelType'] || ChannelType.SURGERY_ORDER;
        this.subject = (raw['name']) ? raw['name'] : 'Procedure Log';
        this.accountId = (raw['customerid']) ? raw['customerid'] : '';
        this.appointmentId = (raw['appointmentId']) ? raw['appointmentId'] : '';
        this.offlineMeetingId = (raw['offlineMeetingId']) ? raw['offlineMeetingId'] : '';
        this.accountNameString = (raw['customerName']) ? raw['customerName'] : '';
        this.createdDate = (raw['createdon']) ? new Date(parseInt(raw['createdon'])) : null;
        this.indskr_noprocedureday = (raw['indskr_noprocedureday']) ? raw['indskr_noprocedureday'] : false;
        this.indskr_subjectedited = (raw['indskr_subjectedited']) ? raw['indskr_subjectedited'] : false;
        if(raw['createdon'] && !raw['scheduledstart']){ //indskr_scheduleddate
            // Manually create the schedule start and scheduled end from createdon field untill MSE introduces these fields in st.
            let roundedDownTimeForAgenda = Utility.getRoundedDownTime(new Date(parseInt(raw['createdon'])));
            this.scheduledStart = roundedDownTimeForAgenda;
            this.scheduledEnd = addMinutes(roundedDownTimeForAgenda,30);
            this.scheduledStartLocale = this.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute:'2-digit'});
        }
        this.isHardDeleted = (raw['track_action'] && raw['track_action'] == TrackAction.Deleted) ? true : (raw['isHardDeleted'] ? raw['isHardDeleted'] : false) ;
        this.pendingPushToDynamics = (raw['pendingPushToDynamics']) ? raw['pendingPushToDynamics'] : null;
        if(raw['products'] && raw['products'].length > 0){
            this.products = [];
            raw['products'].forEach(product => {
              let prodObj = new OrderProductClass({
                productId: product['productid'],
                productName: (product['productName']) ? product['productName'] : '',
                total: (product.extendedamount) ? product.extendedamount : 0,
                formattedTotalAmount: (product['formattedExtendedamount'])? product['formattedExtendedamount'] : '',
                unitPrice: (product.priceperunit) ? product.priceperunit : 0,
                quantity: (product.quantity) ? product.quantity : 0,
                uomid: (product['uomid']) ? product['uomid'] : '',
                isDeleted: (product['isDeleted']) ? product['isDeleted'] : false,
                productCategoryId: (product['productCategoryId']) ? product['productCategoryId'] : '',
                productCategoryName: (product['productCategoryName']) ? product['productCategoryName'] : ''
              });
              this.products.push(prodObj);
            });
        };
  
        if(raw['productCategory'] && raw['productCategory'].length > 0){
          this.productCategory = [];
          this.competitorProducts = [];
          raw['productCategory'].forEach(product => {
            if(product['isCompetitor']){
              let prodObj = new OrderProductClass({
                productId: product['productId'],
                productName: (product['productName']) ? product['productName'] : '',
              });
              this.competitorProducts.push(prodObj);
            }else{
              let productCatObj = new OrderProductCategory({
                productCategoryId: product.productId,
                productCategoryName: product.productName,
              });
              this.productCategory.push(productCatObj);
            }
          });
        };
        if(raw['assets'] && raw['assets'].length > 0){
          this.assets = [];
          raw['assets'].forEach(asset => {
              this.assets.push(new OrderAsset({
                msdyn_customerassetid: asset.msdyn_customerassetid || '',
                msdyn_name: asset.msdyn_name || '',
                ownerid: asset.ownerid || '',
                ownerName: asset.ownerName || '',
                createdon: asset.createdon || '',
                salesorderid: asset.salesorderid || ''
              }));
          });
        };
        if(raw['contactorders'] && raw['contactorders'].length > 0){
          this.customers = [];
          raw['contactorders'].forEach(contact => {
              this.customers.push(new OrderContact({
                id: contact['id'] || '',
                firstName: contact['firstName'] || '',
                title: contact['title'] || '',
                middleName: contact['middleName'] || '',
                lastName: contact['lastName'] || '',
                stateCode: contact['stateCode'],
                statusCode: contact['statusCode'],
              }));
          });
        };
        if(raw['notes'] && raw['notes'].length > 0){
          this.procedureNotes = [];
          raw['notes'].forEach(note => {
            this.procedureNotes.push(new IONote({
              annotationid: note['annotationid'] || '',
              createdon: note['createdon'] || null,
              notetext: note['notetext'] || '',
              ownerid: note['ownerid'] || '',
              ownerName: note['ownerName'] || '',
              deleted: note['deleted'] || false,
              updated: note['updated'] || false,
              filename: note['filename'] || '',
              documentbody: note['documentbody'] || '',
              mimetype:  note['mimetype'] || '',
              filesize:  note['filesize'] || '',
              isdocument: note['isdocument'] || '',
              hasDocument: note['hasDocument'] || false
            }));
          });
        }
        this.surgeryId = raw['indskr_surgeryproduct'];
        this.surgeryNameString = raw['indskr_surgeryproductName'] || '';
        this.orderType = 548910001;
        this.orderTypeFormatted = OrderTypes.Surgery;
        //this.location = raw['indskr_location'] || this.location || '';

        if(raw['indskr_scheduleddate']){
          this.scheduledStart = new Date(parseInt(raw['indskr_scheduleddate']));
        }

        if(raw['indskr_scheduledenddate']){
          this.scheduledEnd = new Date(parseInt(raw['indskr_scheduledenddate']));
          this.setYearMonthWeek();
        } else {
          this.scheduledEnd = addMinutes(this.scheduledStart, 30);
        }
        this.customersNameString = raw['contactformattedstring'] || this.getCustomerString;
        if(raw['accountStatusCode'] === 2 || raw['accountStatusCode'] == 548910003 || raw['accountStatusCode'] == 548910010 || raw['accountStatusCode'] == 548910011 || raw['accountStatusCode'] == 548910012 || raw['accountStatusCode'] == 548910013) {
          this.isAccountActive = false;
        }else{
          this.isAccountActive = true;
        }
        this.indskr_datecompleted = raw['indskr_datecompleted'];

        this.surveyResponse = raw['surveyResponse'] || '';
        this.surveyResponseData = raw['surveyResponseData'] || '';
        this.surveyResponseLookupData = raw['surveyResponseLookupData'] || [];
        this.surveyResponseAdditionalData = raw['surveyResponseAdditionalData'] || {};

        this.coOwners = raw['coOwners'] || [];
        this.opportunities = raw['opportunities'] || [];
        this.modifiedBy = raw['modifiedByName'];
        this.modifiedOn = raw['modifiedon'] ? new Date(parseInt(raw['modifiedon'])) : null;
        this.activityOutcomeId = raw['caseOutcome'];
        this.activityOutcomeString = raw['caseOutcomeName'];
        this.marketingBusinessPlanId = raw['marketingBusinessPlanId'] ?? null;
        this.marketingBusinessPlanName = raw['marketingBusinessPlanName'] ?? "";
        this.indskr_coachingplan = raw['indskr_coachingplan'] ?? null;
        this.indskr_coachingplanname = raw['indskr_coachingplanname'] ?? "";
        this.opportunityId = raw['opportunityid'] || '';
        this.opportunityNameString = raw['opportunityName'] || '';
        this.offlineOpportunityId = this.opportunityId.indexOf('offline')>-1?this.opportunityId:'';


      this.indskr_bwreparrives = raw['indskr_bwreparrives'] || "";
      this.indskr_bwrepleaves = raw['indskr_bwrepleaves'] || "";
      this.indskr_ptinroom = raw['indskr_ptinroom'] || "";
      this.indskr_ptready = raw['indskr_ptready'] || "";
      this.indskr_ptoutofroom = raw['indskr_ptoutofroom'] || "";
      this.indskr_procstartneedle = raw['indskr_procstartneedle'] || "";
      this.indskr_transseptalaccess = raw['indskr_transseptalaccess'] || "";
      this.indskr_mappingcomplete = raw['indskr_mappingcomplete'] || "";
      this.indskr_procedurecomplete = raw['indskr_procedurecomplete'] || "";
        
      this.indskr_transseptalaccessfluorotime = raw['indskr_transseptalaccessfluorotime'] || "";
      this.indskr_mappingcompletefluorotime = raw['indskr_mappingcompletefluorotime'] || "";
      this.indskr_procedurecompletefluorotime = raw['indskr_procedurecompletefluorotime'] || "";

      // cartos stats
      this.indskr_ablpoints = raw['indskr_ablpoints'] || "";
      this.indskr_totalmappingpoints = raw['indskr_totalmappingpoints'] || "";
      this.indskr_averagerfpowerwatts = raw['indskr_averagerfpowerwatts'] || "";
      this.indskr_posteriorleft = raw['indskr_posteriorleft'] || "";
      this.indskr_posteriorright = raw['indskr_posteriorright'] || "";
      this.indskr_interiorleft = raw['indskr_interiorleft'] || "";
      this.indskr_interiorright = raw['indskr_interiorright'] || "";
      this.indskr_anteriorleft = raw['indskr_anteriorleft'] || "";
      this.indskr_anteriorright = raw['indskr_anteriorright'] || "";
      this.indskr_roofleft = raw['indskr_roofleft'] || "";
      this.indskr_roofright = raw['indskr_roofright'] || "";
      this.indskr_superiorleft = raw['indskr_superiorleft'] || "";
      this.indskr_superiorright = raw['indskr_superiorright'] || "";
      this.indskr_ridge = raw['indskr_ridge'] || "";
      this.indskr_fluiddelivered = raw['indskr_fluiddelivered'] || "";
      // this.indskr_ctmerged = raw['indskr_ctmerged'] || "";
      this.indskr_ctmergednew = raw['indskr_ctmergednew'] || false;
      
      // procedure contract
      this.indskr_proceduretype = raw['indskr_proceduretypeid'] || raw['indskr_proceduretype'] || "";
      this.indskr_proceduresubtype = raw['indskr_proceduresubtypeid'] ||  raw['indskr_proceduresubtype'] || "";
      this.indskr_procedurecontract = raw['indskr_procedurecontract'] || "";
      this.indskr_procedurecontractname = raw['indskr_procedurecontractname'] || "";
      this.noofassistanceavailed = raw['noofassistanceavailed'] || 0;
      this.contractStartDate =raw['contractStartDate'] || "" ;
      this.contractEndDate =raw['contractEndDate'] || "";
      this.maximumnoofassistance =raw['maximumnoofassistance'] || "";
      this.indskr_proceduresubtypename = raw['indskr_proceduresubtypename'] || '';
      this.indskr_proceduretypename = raw['indskr_proceduretypename'] || '';
      // procedure log cancellation reason
      this.indskr_cancellationreasonother = raw['indskr_cancellationreasonother'] || '';
      this.indskr_reasonforcancellation = raw['indskr_reasonforcancellation'] || '';
      this.indskr_reasonforcancellationname = raw['indskr_reasonforcancellationname'] || '';

      this.cancellationreason = raw['cancellationreason'] || '';
      this.coVisitors = raw['coVisitors'] || [];

      this.contractUsageType = raw['usageType'] || null;
      this.contractStatus = raw['contractStatus'] || null;

      this.jnjjapan_shorei = raw['jnjjapan_shorei'] || '';
      this.jnjjapan_shoreiname = raw['jnjjapan_shoreiname'] || '';
      this.indskr_assetbookingid = raw['indskr_assetbookingid'] || '';

    }

    public get coOwnersUpdateDTO(): any {
      if (!this.coOwners || !this.ID) return {};
      return {
        salesorderid: this.ID,
        coOwners: this.coOwners
      }
    }
    public get opportunitiesUpdateDTO(): any {
      if (!this.opportunities || !this.ID) return {};
      return {
        salesorderid: this.ID,
        opportunities: this.opportunities
      }
    }

    public get coVisitorsUpdateDTO(): any {
      if (!this.coVisitors || !this.ID) return {};
      return {
        salesorderid: this.ID,
        coVisitors: this.coVisitors
      }
    }

    public get offlineDBId():string {
        if(this.offlineId){
            return DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY + this.offlineId;
        }else{
            return DB_KEY_PREFIXES.SURGERY_ORDER_ACTIVITY + this.ID;
        }
    }

    public get surgeryOrderStatusString():string {
        let str:string = '';
        switch(this.status){
            case 100001:
            case 548910001:
                str = 'Completed';
                break;
            case 548910005:
              str = 'Canceled';
              break;
            default:
                str = 'Draft';
        }
        return str;
    }

    public get getCustomerString(): string {
      let str = '';
      const currentCustomers = this.customers;
      if (currentCustomers) {
          if (currentCustomers.length === 1) {
              str = `${currentCustomers[0].fullname}`;
              if(currentCustomers[0].statusCode !== 1 && currentCustomers[0].statusCode !== 548910000){
                  str += ' (Inactive)';
              }
          } else if (currentCustomers.length >= 2) {
            currentCustomers.sort((a, b): number => {
                  if (a.fullname > b.fullname) return 1;
                  if (a.fullname < b.fullname) return -1;
                  return 0;
              });
              let frstStr = '';
              let scndStr = '';
              if(currentCustomers[0].statusCode !== 1 && currentCustomers[0].statusCode !== 548910000){
                  frstStr = ' (Inactive)';
              }else if(currentCustomers.some(a=> a.statusCode !== 1 && a.statusCode !== 548910000)){
                  scndStr = ' (Inactive)';
              }
              str = `${currentCustomers[0].fullname}${frstStr} +${currentCustomers.length - 1}${scndStr}`;
          }
      }
      return str;
    }

    public get serviceDTO():any{
        let payloadProducts = [];
        let competitorProducts = [];
        this.products.map(product => {
            if(!product.isDeleted){
                payloadProducts.push({
                    productid: product.productId,
                    quantity: product.quantity,
                    uomid: product.uomid,
                    indskr_productCategoryId: product.productCategoryID
                });
            }
        });
        this.competitorProducts.map(compProduct => {
          if(!compProduct.isDeleted){
            competitorProducts.push({
                productid: compProduct.productId,
              });
          }
        });
        let contactorders = [];
        this.customers.map(con=>{
          contactorders.push({
            contactid:con.ID
          })
        })
        let product_category = [];
        this.productCategory.map(prod=>{
          product_category.push({
            productid:prod.productCategoryId
          })
        })
        let notes = [];
        this.procedureNotes.map(note => {
         let noteObj={
            annotationid: (note.noteId && !note.noteId.includes('offline'))?note.noteId:'',
            notetext: note.noteText,
            deleted: note.isDeleted,
            updated: note.updated,
          }
          if(note.hasDocument){
            noteObj["filename"]=note.documentName
            noteObj["filesize"]=note.documentSize
            noteObj["mimetype"]=note.documentMimeType
            noteObj["documentbody"]=note.documentDataURL
          }
          if(note.documentSize){
            noteObj["isdocument"] = true; 
          }
          notes.push(noteObj);
        })
        let assets = [];
        this.assets.map(asset => {
          assets.push({
            msdyn_customerassetid: asset.msdyn_customerassetid,
            msdyn_name: asset.msdyn_name,
            salesorderid: asset.salesorderid
          })
        })
        let payload = {
            indskr_externalid: this.offlineId  ? this.offlineId : '',
            customerid_account: (this.accountId) ? this.accountId : '',
            ownerid: this.ownerId,
            createdon: this.createdDate.getTime()+'',
            name: this.subject,
            products: payloadProducts,
            competitorProducts: competitorProducts,
            indskr_ordertype: this.orderType,
            indskr_surgeryproduct: (this.surgeryId) ? this.surgeryId: "",
            //indskr_location:this.location,
            contactorders: contactorders,
            product_category: product_category,
            indskr_scheduledenddate: this.scheduledEnd.getTime()+'',
            indskr_scheduleddate: this.scheduledStart.getTime()+'',
            indskr_noprocedureday : this.indskr_noprocedureday,
            indskr_subjectedited : this.indskr_subjectedited,
            notes: notes,
            indskr_datecompleted: this.indskr_datecompleted,
            assets: assets,
            opportunityid:this.opportunityId.indexOf('offline')>-1?"indskr_externalid='"+this.opportunityId+"',indskr_externalsource='"+this.opportunityId+"'":this.opportunityId,
            //appointmentId: this.appointmentId ? this.appointmentId : '',
            // indskr_presurgerysignature:this.preSurgerySignature,
            // indskr_postsurgerysignature :this.postSurgerySignature,
            // indskr_validatornamepresurgerysignature :this.validatorNamePreSignature,
            // indskr_validatornamepostsurgerysignature:this.validatorNamePostSignature

        };
        if(this.appointmentId){
          payload['appointmentId'] = this.appointmentId;
        }else if(this.offlineMeetingId){
          payload['offlineMeetingId'] = this.offlineMeetingId;
        }
        if(!this.ID.includes('offline')){
            payload['salesorderid'] = this.ID;
        }
        if((this.state == 2 && this.status == 4) || (this.state == 1 && this.status == 548910001) || (this.state == 2 && this.status == 548910005)){
            payload['statecode'] = this.state+'';
            payload['statuscode'] = this.status+'';
        }
        if(this.surveyResponseDTO){
          payload['surveyResponses'] = this.surveyResponseDTO;
        }

        if(this.coOwners){
          if (this.ID.includes('offline')) {
            payload['coOwners'] = this.coOwners.filter((coOwner: any) => !coOwner?.deleted);
          } else {
            payload['coOwners'] = this.coOwners;
          }
        }
        if(this.opportunities){
          if (this.ID.includes('offline')) {
            payload['opportunities'] = this.opportunities.filter((coOwner: any) => !coOwner?.deleted);
          } else {
            payload['opportunities'] = this.opportunities;
          }
        }
   
        if(this.activityOutcomeId){
          payload['indskr_caseoutcome'] = this.activityOutcomeId;
        }

        if(this.marketingBusinessPlanId !== null){
          payload['indskr_marketingbusinessplan'] = this.marketingBusinessPlanId;
        }

        if(this.indskr_coachingplan !== null){
          payload['indskr_coachingplan'] = this.indskr_coachingplan;
        }

        // procedure stats
        payload['indskr_bwreparrives'] = this.indskr_bwreparrives ? this.indskr_bwreparrives : "";
        payload['indskr_bwrepleaves'] = this.indskr_bwrepleaves ? this.indskr_bwrepleaves : "";
        payload['indskr_ptinroom'] = this.indskr_ptinroom ? this.indskr_ptinroom : "";
        payload['indskr_ptready'] = this.indskr_ptready ? this.indskr_ptready : "";
        payload['indskr_ptoutofroom'] = this.indskr_ptoutofroom ? this.indskr_ptoutofroom : "";
        payload['indskr_procstartneedle'] = this.indskr_procstartneedle ? this.indskr_procstartneedle : "";
        payload['indskr_transseptalaccess'] = this.indskr_transseptalaccess ? this.indskr_transseptalaccess : "";
        payload['indskr_mappingcomplete'] = this.indskr_mappingcomplete ? this.indskr_mappingcomplete : "";
        payload['indskr_procedurecomplete'] = this.indskr_procedurecomplete ? this.indskr_procedurecomplete : "";
        payload['indskr_transseptalaccessfluorotime'] = this.indskr_transseptalaccessfluorotime ? this.indskr_transseptalaccessfluorotime : "";
        payload['indskr_mappingcompletefluorotime'] = this.indskr_mappingcompletefluorotime ? this.indskr_mappingcompletefluorotime : "";
        payload['indskr_procedurecompletefluorotime'] = this.indskr_procedurecompletefluorotime ? this.indskr_procedurecompletefluorotime : "";

        // cartos stats
        payload['indskr_ablpoints'] = this.indskr_ablpoints ? this.indskr_ablpoints : "";
        payload['indskr_totalmappingpoints'] = this.indskr_totalmappingpoints ? this.indskr_totalmappingpoints : "";
        payload['indskr_averagerfpowerwatts'] = this.indskr_averagerfpowerwatts ? this.indskr_averagerfpowerwatts : "";
        payload['indskr_posteriorleft'] = this.indskr_posteriorleft ? this.indskr_posteriorleft : "";
        payload['indskr_posteriorright'] = this.indskr_posteriorright ? this.indskr_posteriorright : "";
        payload['indskr_interiorleft'] = this.indskr_interiorleft ? this.indskr_interiorleft : "";
        payload['indskr_interiorright'] = this.indskr_interiorright ? this.indskr_interiorright : "";
        payload['indskr_anteriorleft'] = this.indskr_anteriorleft ? this.indskr_anteriorleft : "";
        payload['indskr_anteriorright'] = this.indskr_anteriorright ? this.indskr_anteriorright : "";
        payload['indskr_roofleft'] = this.indskr_roofleft ? this.indskr_roofleft : "";
        payload['indskr_roofright'] = this.indskr_roofright ? this.indskr_roofright : "";
        payload['indskr_superiorleft'] = this.indskr_superiorleft ? this.indskr_superiorleft : "";
        payload['indskr_superiorright'] = this.indskr_superiorright ? this.indskr_superiorright : "";
        payload['indskr_ridge'] = this.indskr_ridge ? this.indskr_ridge : "";
        payload['indskr_fluiddelivered'] = this.indskr_fluiddelivered ? this.indskr_fluiddelivered : "";
        // payload['indskr_ctmerged'] = this.indskr_ctmerged ? this.indskr_ctmerged : "";
        payload['indskr_ctmergednew'] = this.indskr_ctmergednew;

        payload['indskr_procedurecontract'] = this.indskr_procedurecontract ?? "";

      if (this.indskr_reasonforcancellation) {
        payload["indskr_reasonforcancellation"] = this.indskr_reasonforcancellation;
      }

      if(this.indskr_cancellationreasonother){
        payload ['indskr_cancellationreasonother'] = this.indskr_cancellationreasonother;
      }

        if (this.indskr_proceduresubtype) {
          payload["indskr_ProcedureSubTypes@odata.bind"] = "/indskr_proceduresubtypes(" + this.indskr_proceduresubtype + ")";
        } else {
          // payload["indskr_ProcedureSubTypes@odata.bind"] = ""
          // payload["indskr_ProcedureSubTypes@odata.bind"] = "/indskr_proceduresubtypes('')";
          // TODO : should implement clearing of subtype
        }

        if(this.indskr_proceduretype){
          payload["indskr_ProcedureTypes@odata.bind"] = "/indskr_proceduretypes("+ this.indskr_proceduretype  +")";
        }

        if(this.coVisitors && this.coVisitors.length > 0){
          payload['coVisitors'] = this.coVisitors;
        }

        if(this.jnjjapan_shorei){
          payload['jnjjapan_shorei'] = this.jnjjapan_shorei;
        }
        
        return payload;
    }

    public get statusUpdateDTO():any {
        if(!this.ID) return {};
        let payload:any = 
         {
            salesorderid: this.ID,
            statecode: this.state+'',
            statuscode:this.status+'',
            indskr_datecompleted: this.indskr_datecompleted,
            indskr_procedurecontract : this.indskr_procedurecontract
        };

      if (this.indskr_reasonforcancellation) {
        payload = { ...payload, indskr_reasonforcancellation: this.indskr_reasonforcancellation }
      }

      if(this.indskr_cancellationreasonother){
        payload = { ...payload, indskr_cancellationreasonother: this.indskr_cancellationreasonother }
      }

        return payload;
    }

    public get offlineDataDTO():any{
      let productsDTO = [];
      let competitorProductsAndProductCategoryDTO = [];
      this.products.forEach(product => {
        productsDTO.push({
          productid: product.productId,
          productName: product.productName,
          extendedamount: product.total,
          formattedExtendedamount: product.formattedTotal,
          priceperunit: product.unitPrice,
          quantity: product.quantity,
          isDeleted: product.isDeleted,
          uomid: product.uomid,
          productCategoryId: product.productCategoryID,
          productCategoryName: product.productCategoryName
        });
      });
      this.productCategory.forEach (category => {
        competitorProductsAndProductCategoryDTO.push({
          productId: category.productCategoryId,
          productName: category.productCategoryName,
          isCompetitor: false
        });
      }),
      this.competitorProducts.forEach(product => {
        competitorProductsAndProductCategoryDTO.push({
          productId: product.productId,
          productName: product.productName,
          isCompetitor: true,
        });
      });
        return {
            indskr_externalid : this.offlineId,
            salesorderid: this.ID,
            ownerName : this.ownerNameString,
            customerid : this.accountId,
            customerName : this.accountNameString,
            pendingPushToDynamics : this.pendingPushToDynamics,
            isHardDeleted: this.isHardDeleted,
            statuscode : this.status,
            statecode : this.state ,
            ownerid : this.ownerId,
            activitytypecode : this.type,
            createdon : this.createdDate.getTime()+'',
            name: this.subject,
            scheduledstart: this.scheduledStart.getTime()+'',
            scheduledend: this.scheduledEnd.getTime()+'',
            indskr_ordertype: this.orderType,
            indskr_surgeryproduct: (this.surgeryId) ? this.surgeryId : "",
            indskr_surgeryproductName: this.surgeryNameString,
            //indskr_location:this.location,
            contactformattedstring: this.customersNameString,
            indskr_scheduledenddate: this.scheduledEnd.getTime()+'',
            indskr_scheduleddate: this.scheduledStart.getTime()+'',
            appointmentId: this.appointmentId ? this.appointmentId : '',
            offlineMeetingId: this.offlineMeetingId ? this.offlineMeetingId : '',
            indskr_noprocedureday : this.indskr_noprocedureday,
            indskr_subjectedited : this.indskr_subjectedited,
            indskr_datecompleted: this.indskr_datecompleted,
            opportunityid: this.opportunityId,
            opportunityName : this.opportunityNameString,
            offlineOpportunityId: this.offlineOpportunityId,
            modifiedByName: this.modifiedBy,
            modifiedon: this.modifiedOn ? this.modifiedOn.getTime()+'' : '',
            caseOutcome: this.activityOutcomeId,
            caseOutcomeName: this.activityOutcomeString,
            // indskr_presurgerysignature:this.preSurgerySignature,
            // indskr_postsurgerysignature :this.postSurgerySignature,
            // indskr_validatornamepresurgerysignature :this.validatorNamePreSignature,
            // indskr_validatornamepostsurgerysignature:this.validatorNamePostSignature,
            contactorders: this.customers.map(customer => {
              return {
                id:customer.ID,
                firstName:customer.firstName,
                title:customer.title,
                middleName:customer.middleName,
                lastName:customer.lastName,
                fullName:customer.fullname,
                stateCode:customer.isActive ? 0 : 1,
                statusCode:customer.statusCode,
              };
            }),
            productCategory: competitorProductsAndProductCategoryDTO,
            products: productsDTO,
            notes: this.procedureNotes.map(note => {
              return {
                annotationid: note.noteId,
                notetext: note.noteText,
                deleted: note.isDeleted,
                ownerid: note.ownerId,
                ownerName: note.ownerName,
                updated: note.updated,
                createdon: note.createdTime?note.createdTime.getTime().toString():0,
                filename: note.documentName,
                filesize: note.documentSize,
                mimetype: note.documentMimeType,
                documentDataURL :note.documentDataURL,
                hasDocument: note.hasDocument
              };
            }),
            marketingBusinessPlanId : this.marketingBusinessPlanId === null ? null : this.marketingBusinessPlanId == "" ? "" : this.marketingBusinessPlanId,
            marketingBusinessPlanName : this.marketingBusinessPlanName ?? "",
            indskr_coachingplan : this.indskr_coachingplan === null ? null : this.indskr_coachingplan == "" ? "" : this.indskr_coachingplan,
            indskr_coachingplanname : this.indskr_coachingplanname ?? "",
            coOwners: this.coOwners,
            opportunities: this.opportunities,
            assets: this.assets,


        indskr_bwreparrives : this.indskr_bwreparrives ??  "",
        indskr_bwrepleaves : this.indskr_bwrepleaves ?? "",
        indskr_ptinroom : this.indskr_ptinroom ?? "",
        indskr_ptready : this.indskr_ptready ?? "",
        indskr_ptoutofroom : this.indskr_ptoutofroom ?? "",
        indskr_procstartneedle : this.indskr_procstartneedle ?? "",
        indskr_transseptalaccess : this.indskr_transseptalaccess ?? "",
        indskr_mappingcomplete : this.indskr_mappingcomplete ?? "",
        indskr_procedurecomplete : this.indskr_procedurecomplete ?? "",
        indskr_transseptalaccessfluorotime : this.indskr_transseptalaccessfluorotime ?? "",
        indskr_mappingcompletefluorotime : this.indskr_mappingcompletefluorotime ?? "",
        indskr_procedurecompletefluorotime : this.indskr_procedurecompletefluorotime ?? "",

        // cartos stats
        indskr_ablpoints : this.indskr_ablpoints ?? "",
        indskr_totalmappingpoints : this.indskr_totalmappingpoints ?? "",
        indskr_averagerfpowerwatts : this.indskr_averagerfpowerwatts ?? "",
        indskr_posteriorleft : this.indskr_posteriorleft ?? "",
        indskr_posteriorright : this.indskr_posteriorright ?? "",
        indskr_interiorleft : this.indskr_interiorleft ?? "",
        indskr_interiorright : this.indskr_interiorright ?? "",
        indskr_anteriorleft : this.indskr_anteriorleft ?? "",
        indskr_anteriorright : this.indskr_anteriorright ?? "",
        indskr_roofleft : this.indskr_roofleft ?? "",
        indskr_roofright : this.indskr_roofright ?? "",
        indskr_superiorleft : this.indskr_superiorleft ?? "",
        indskr_superiorright : this.indskr_superiorright ?? "",
        indskr_ridge : this.indskr_ridge ?? "",
        indskr_fluiddelivered : this.indskr_fluiddelivered ?? "",
        // indskr_ctmerged : this.indskr_ctmerged ?? "",
        indskr_ctmergednew : this.indskr_ctmergednew,

        indskr_procedurecontract : this.indskr_procedurecontract ?? "",
        indskr_proceduretype : this.indskr_proceduretype ?? "",
        indskr_proceduresubtype : this.indskr_proceduresubtype ?? "",
        
        indskr_reasonforcancellation : this.indskr_reasonforcancellation ?? "",
        indskr_cancellationreasonother : this.indskr_cancellationreasonother ?? "",
        indskr_reasonforcancellationname : this.indskr_reasonforcancellationname ?? "",
        cancellationreason : this.cancellationreason ?? "",
        coVisitors: this.coVisitors,
        jnjjapan_shorei:this.jnjjapan_shorei,
        contractStatus : this.contractStatus
        }

    }

  public setPayload(inputValues: any[]): any {
    let payLoad: any;

    payLoad = {
      indskr_externalid: this.offlineId,
      ownerid: this.ownerId,
      createdon: this.createdDate.getTime() + '',
      name: this.subject,
      indskr_ordertype: this.orderType,
      indskr_scheduledenddate: this.scheduledEnd.getTime() + '',
      indskr_scheduleddate: this.scheduledStart.getTime() + '',
      salesorderid: this.ID,
    }

    for (let key of inputValues) {
      payLoad = { ...payLoad, [key]: this[key] ?? "" }
    }

    return payLoad;
  }


  resetScheduledStartLocale() {
    this.scheduledStartLocale = this.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute:'2-digit'});
  }

  setYearMonthWeek() {
    if (this.scheduledEnd) {
      this.weekOfYear = moment(this.scheduledEnd).week();
      this.year = this.scheduledEnd.getFullYear();
      this.month = this.scheduledEnd.getMonth() + 1;
    }
  }


}

export class OrderContact {

  public ID: string;
  public title: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public fullname: string;
  public isActive: boolean;
  public statusCode: number;

  constructor(raw:any){
    this.ID = (raw['id']) ? raw['id'] : '';
    this.title = raw["title"] || "";
    this.firstName = raw["firstName"] || "";
    this.middleName = raw['middleName'] || "";
    this.lastName = raw["lastName"] || "";
    this.fullname = raw['fullName'] ? raw['fullName'] : `${raw["firstName"]} ${raw["lastName"] ? raw["lastName"] : ''}`;
    this.isActive = !(raw['stateCode'] == 1 && (raw['statusCode'] == 2 || raw['statusCode'] == 548910003 || raw['statusCode'] == 548910010 || raw['statusCode'] == 548910011 || raw['statusCode'] == 548910012 || raw['statusCode'] == 548910013));
    this.statusCode = raw['statusCode'] || null;
  }

}

export class OrderAsset {

  public msdyn_customerassetid: string;
  public msdyn_name: string;
  public ownerid: string;
  public ownerName: string;
  public createdon: string;
  public salesorderid: string;

  constructor(raw:any){
    this.msdyn_customerassetid = (raw['msdyn_customerassetid']) ? raw['msdyn_customerassetid'] : '';
    this.msdyn_name = raw["msdyn_name"] || "";
    this.ownerid = raw["ownerid"] || "";
    this.ownerName = raw['ownerName'] || "";
    this.createdon = raw["createdon"] || "";
    this.salesorderid = raw["salesorderid"] || "";
  }

}

export class OrderProductCategory {

  public productCategoryId:string;
  public productCategoryName:string;

  constructor(raw:any){
    this.productCategoryId = (raw['productCategoryId']) ? raw['productCategoryId'] : '';
    this.productCategoryName = raw["productCategoryName"] || "";
  }

}



/**
 *
 * @param rawData
 * @odata.etag:"W/"289288839"",
indskr_name:"Positon Group 4",
indskr_positiongroupsid:"64a549f0-f951-e911-a97f-000d3af49211",
createdon@OData.Community.Display.V1.FormattedValue:"3/29/2019 1:39 PM",
createdon:"2019-03-29T08:09:17Z",
surgery.productid@OData.Community.Display.V1.AttributeName:"productid",
surgery.productid:"ee33e1ec-951b-ec11-b6e6-00224828c952",
skuandcategories.indskr_producttype@OData.Community.Display.V1.AttributeName:"indskr_producttype",
skuandcategories.indskr_producttype@OData.Community.Display.V1.FormattedValue:"SKU",
skuandcategories.indskr_producttype:100000003,
surgery.validtodate@OData.Community.Display.V1.AttributeName:"validtodate",
surgery.validtodate@OData.Community.Display.V1.FormattedValue:"12/31/2021",
surgery.validtodate:"2021-12-31",
skuandcategories.statuscode@OData.Community.Display.V1.AttributeName:"statuscode",
skuandcategories.statuscode@OData.Community.Display.V1.FormattedValue:"Active",
skuandcategories.statuscode:1,
surgery.name@OData.Community.Display.V1.AttributeName:"name",
surgery.name:"Spine Surgery",
surgery.statuscode@OData.Community.Display.V1.AttributeName:"statuscode",
surgery.statuscode@OData.Community.Display.V1.FormattedValue:"Active",
surgery.statuscode:1,
surgery.validfromdate@OData.Community.Display.V1.AttributeName:"validfromdate",
surgery.validfromdate@OData.Community.Display.V1.FormattedValue:"1/1/2021",
surgery.validfromdate:"2021-01-01",
skuandcategories.name@OData.Community.Display.V1.AttributeName:"name",
skuandcategories.name:"Inflammatory Medicine",
surgery.statecode@OData.Community.Display.V1.AttributeName:"statecode",
surgery.statecode@OData.Community.Display.V1.FormattedValue:"Active",
surgery.statecode:0,
surgery.productstructure@OData.Community.Display.V1.AttributeName:"productstructure",
surgery.productstructure@OData.Community.Display.V1.FormattedValue:"Product Bundle",
surgery.productstructure:3,
skuandcategories.productid@OData.Community.Display.V1.AttributeName:"productid",
skuandcategories.productid:"04c0e4d6-961b-ec11-b6e6-00224828c952"
 * @returns
 */

export enum PRODUCT_CATEGORY_SKU {
  category = 548910000,
  sku = 100000003
}

export interface SKUProduct {
  productId: string;
  productName: string;
  productStatecode: number;
  uomid: string;
  productCategoryId: string;
  productCategoryName: string;
  indskr_newproductintroduction: boolean;
  productNumber : string;
}

export interface PositionGroup {
  positiongroupid: string;
  statecode: number;
  state: string;
}
export class SurgeryProductHierarchy{
  buproductstatecode:number;
  surgeryId:string;
  psoitiongroupProductIDs: string[];
  positionGroups: PositionGroup[];
  surgeryName:string;
  surgeryStatecode:number;
  surgeryStatuscode:number;
  trackAction:any;
  surveyTemplateId?:string;
  hasTagAssets?:boolean;
  productCategories:{
    productCategoryId: string;
    productCategoryName: string;
    productCategoryStatecode: number;
    indskr_newproductintroduction: boolean;
    products:SKUProduct[]
  }[]
  subProducts: {
    productId:string;
    productName:string;
  }[]
  constructor(raw, surgerySKUProductRelationship){

    this.buproductstatecode = raw['positiongroupProduct.statecode'] ;
    this.surgeryId = raw['surgery.productid'];
    this.surgeryName = raw['surgery.name'] || '';
    this.surgeryStatecode = raw['surgery.statecode'];
    this.surgeryStatuscode = raw['surgery.statuscode'];
    this.trackAction = raw['track_action'] || 548910000;
    if(raw['surgeryTemplateAssets.statuscode'] == 1){
      this.surveyTemplateId = raw['surgeryTemplate.indskr_expertinquirytemplateid'] || '';
      this.hasTagAssets = raw['surgeryTemplateAssets.indskr_tagassets'] || false;
    }else{
      this.surveyTemplateId = ''
      this.hasTagAssets = false;
    }

    this.psoitiongroupProductIDs = [raw['positiongroupProduct.indskr_positiongroupproductid']];

    this.positionGroups = [{
      positiongroupid: raw['indskr_positiongroupsid'] || '',
      statecode: raw['statecode'],
      state: raw['statecode@OData.Community.Display.V1.FormattedValue']
    }];
    let products: SKUProduct[] = [];
    let filteredRecords = surgerySKUProductRelationship.filter(o => o['productid'] === this.surgeryId && o['skuProduct.productid'] === raw['skuProduct.productid'])
    if (!_.isEmpty(filteredRecords)) {
      products = [{
        productId: raw['skuProduct.productid'] || '',
        productName: raw['skuProduct.name'] || '',
        productStatecode: raw['skuProduct.statecode'],
        uomid: raw['skuProduct.defaultuomid'] || '',
        productCategoryId: raw['categoryProduct.productid'] || '',
        productCategoryName: raw['categoryProduct.name'] || '',
        productNumber : raw['skuProduct.productnumber'] || '',
        indskr_newproductintroduction: raw['skuProduct.indskr_newproductintroduction'] ? raw['skuProduct.indskr_newproductintroduction'] : false
      }]
    }

    this.productCategories = [{
      productCategoryId:raw['categoryProduct.productid'] || '',
      productCategoryName:raw['categoryProduct.name'] || '',
      productCategoryStatecode:raw['categoryProduct.statecode'],
      products: products,
      indskr_newproductintroduction: raw['categoryProduct.indskr_newproductintroduction'] ? raw['categoryProduct.indskr_newproductintroduction'] : false
    }];

    if(raw['subProducts.productid'])
    this.subProducts = [{
      productId: raw['subProducts.productid'],
      productName: raw['subProducts.name']
    }]
  }
}


export class SurgeryNewProductHierarchy{
  surgeryId:string;
  surgeryName:string;
  surgeryStatecode:number;
  surgeryStatuscode:number;
  productCategories: SurgeryCategoryProduct[]
  constructor(raw){
    this.surgeryId = raw['surgery.productid'];
    this.surgeryName = raw['surgery.name'] || '';
    this.surgeryStatecode = raw['surgery.statecode'];
    this.surgeryStatuscode = raw['surgery.statuscode'];
    this.productCategories = [new SurgeryCategoryProduct(raw)];
  }
}

export class SurgeryCategoryProduct {

  productCategoryId: string;
  productCategoryName: string;
  productCategoryStatecode: number;
  products: SurgerySKUProduct[];

  constructor(raw) {
    this.products  = [new SurgerySKUProduct(raw)];
  }
}

export class SurgerySKUProduct {
  productId: string;
  productName: string;
  productStatecode: number;
  uomid: string;
  productCategoryId: string;
  productCategoryName: string;
  constructor (raw) {
    this.productId = ''
  }
}
export class ProcedureType {
  indskr_proceduretypeid: string;
  indskr_name: string;
  indskr_isprocedurecontractenabled: boolean;
  procedureSubtypes : any;
  indskr_contracttype:string;
  constructor(raw) {
    this.indskr_proceduretypeid = raw['indskr_proceduretypeid'];
    this.indskr_name = raw['indskr_name'];
    this.indskr_isprocedurecontractenabled = raw['ac.indskr_isprocedurecontractenabled'] || false;
    this.procedureSubtypes =  raw['ah.indskr_proceduresubtypeid'] ? [raw['ah.indskr_proceduresubtypeid']] : [];
    this.indskr_contracttype = raw['ac.indskr_contracttype'] || '';
  }
}
export class ProcedureSubType {
  indskr_proceduresubtypeid: string;
  indskr_name: string;
  indskr_procedureallocated: number; // frequency
  indskr_validity: number; // duration
  indskr_order: number;
  constructor(raw) {
    this.indskr_proceduresubtypeid = raw['indskr_proceduresubtypeid'];
    this.indskr_name = raw['indskr_name'];
    this.indskr_validity = raw['indskr_validity'];
    this.indskr_procedureallocated = raw['indskr_procedureallocated'];
    this.indskr_order = raw['indskr_order'] || 0;
  }
}

export enum PRODUCT_DISPLAY {
  ALL_PRODUCTS = 548910000,
  NPI_PRODUCTS_ONLY = 548910001,
  ALL_EXCLUDING_NPI_PRODUCTS = 548910002
}

export class ShoreiMaster {
  jnjjapan_shorei: string;
  jnjjapan_shoreiname: string;
  constructor(raw) {
    this.jnjjapan_shorei = raw['jnjjapan_shoreimasterid'];
    this.jnjjapan_shoreiname = raw['jnjjapan_name'];
  }
}






