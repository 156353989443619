import { EventsService } from '../../../services/events/events.service';
import { Component } from "@angular/core";
import { RepServices } from "../../../data-services/rep/rep.services";
import { DeviceService } from "../../../services/device/device.service";
import { NavigationService, PageName } from "../../../services/navigation/navigation.service";
import { AuthenticationService } from "../../../services/authentication.service";
import { TrackingEventNames, TrackService } from "../../../services/logging/tracking.service";
import { TranslateService } from '@ngx-translate/core';
import { IndHeaderLeftDataModel } from "../../../models/indHeaderLeftDataModel";
import { IndKeyControlsAreaModel } from "../../../models/indKeyControlsAreaModel";
import { MultiSelectPopover } from "../../multi-select-popover/multi-select-popover";
import { PopoverController } from "@ionic/angular";
import { MarketScanDetailsComponent } from '../market-scan-details/market-scan-details';
import { MarketScan } from '../../../classes/market-scan/market-scan.class';
import { MarketScanService } from '../../../services/market-scan/market-scan.service';
import { MainCardViewDataModel } from "../../../models/MainCardViewDataModel";
import { Subscription } from "rxjs";
import { UIService } from '../../../services/ui/ui.service';
import { cloneDeep } from 'lodash';
import _ from 'lodash';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { DatePipe } from '@angular/common';
import { FooterService } from '@omni/services/footer/footer.service';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

@Component({
  selector: 'market-scan-list',
  templateUrl: 'market-scan-list.html',
  styleUrls:['market-scan-list.scss']
})
export class MarketScanListComponent {

  indHeaderLeftModel: IndHeaderLeftDataModel;
  listKeyControlAreaModel: IndKeyControlsAreaModel;
  sortBy: any;
  sortPopoverData: ({ text: string; expanded: boolean; value: string; items: { text: string; value: string; asc: boolean; }[]; handler: (selectedItem: any, item: any) => void; })[] | ({ text: string; value: string; items: { text: String; value: String; }[]; handler: (selectedItem: any, item: any) => void; expanded?: undefined; })[];
  marketScans = [];
  ImarketScansSubscription: Subscription;
  private visibleCount: number = 0;
  public filteredMScansList: any[] = [];
  marketScanSearchText: string = "";
  sectionHeaderData: IndSectionHeaderViewDataModel;
  constructor(
    public device: DeviceService,
    public repService: RepServices,
    public authService: AuthenticationService,
    private navService: NavigationService,
    public trackingService: TrackService,
    public translate: TranslateService,
    public events: EventsService,
    private popoverCtrl: PopoverController,
    private marketScanService: MarketScanService,
    private uiService: UIService,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    public footerService: FooterService,
    private agendaFooterService: AgendaFooterService,
    private faService: FeatureActionsService,
  ) {
  }

  ngOnInit() {
    this.initHeaderLeft();
    this._initKeyControlsAreaModel();
    this.sortPopoverData = [
      {
        text: "",
        expanded: true,
        value: "title",
        items: [
          { text: this.translate.instant('CUSTOMER_SCAN_DATE'), value: "indskr_date", asc: false },
          { text: this.translate.instant('LAST_MODIFIED_DATE'), value: "modifiedOn", asc: false }
        ],
        handler: (selectedItem, item) => {
          item.value = selectedItem.value;
          this.sortBy = selectedItem;
          this.sectionHeaderData.controls[0].text = selectedItem.text;
          this.updateVisibleRecords();
        }
      }
    ];
    this.sortBy = this.sortPopoverData[0].items[0];
    this.ImarketScansSubscription = this.marketScanService.marketScansObs$.subscribe((value) => {
      this.marketScans = value;
      this.updateVisibleRecords();
    });
    this.initSectionHeaderData();
  }

  ngOnDestroy() {
    this.ImarketScansSubscription.unsubscribe();
    this.marketScanService.setCurrentMarketScan(null);
  }

  private logicToRunAfterPopToRootWhenAccessedFromToolsDrawer = () => {
    // Short call feature requires to bring the tab back to short call home
    if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
      this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
      this.uiService.setAgendaTab('shortCallHome');
    }
  };

  public onClosePage(): void {
    this.marketScanSearchText = '';
    if(this.marketScanService.getSelectedMarketScan() && this.marketScanService.getSelectedMarketScan().isModified) {
      this.marketScanService.showDiscardChangesPopup().then (async res => {
        if(res.role === "ok") {
          await this.marketScanService.discardChanges();
          this.events.publish("tools-back-clicked", PageName.MarketScanPageComponent);
          this.navService.popWithPageTracking().then(this.logicToRunAfterPopToRootWhenAccessedFromToolsDrawer);
        }
      })
     } else {
        this.events.publish("tools-back-clicked", PageName.MarketScanPageComponent);
        this.navService.popWithPageTracking().then(this.logicToRunAfterPopToRootWhenAccessedFromToolsDrawer);
     }
  }

  private initHeaderLeft(): void {
    let buttons = [];
    buttons.push({
      id: "close",
      isDisabled: false,
      align: "left",
      imgSrc: "assets/imgs/back_to_home_btn.svg"
    });
    this.indHeaderLeftModel = {
      id: 'mscan-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('CUSTOMER_SCAN'),
      mode: true,
      controls: buttons,
    };
  }

  public getMainCardDataModel(market: MarketScan) {
    let viewData: MainCardViewDataModel;
    viewData = {
      id: market.indskr_externalid,
      fixedHeight: true,
      primaryTextLeft: this.marketScanService.getStatusText(market.statuscode),
      primaryTextRight: market.indskr_name,
      secondaryTextRight: this.getFormattedDate(market.indskr_date),
      secondaryTextRightTwo: "",
      isSelected: this.marketScanService.getSelectedMarketScan() && market.indskr_externalid === this.marketScanService.getSelectedMarketScan().indskr_externalid,
      arrowType: 'chevron-forward-outline',
      clickHandler: (id: string, event, specificTarget) => {
        if(this.marketScanService.getSelectedMarketScan() && this.marketScanService.getSelectedMarketScan().isModified) {
          this.marketScanService.showDiscardChangesPopup().then (async res => {
            if(res.role === "ok") {
              await this.marketScanService.discardChanges();
              this.viewMarketScan(id);
            }
          });
        } else {
          this.viewMarketScan(id);
        }
      }
    };
    return viewData;
  }

  async viewMarketScan(id: string) {
    await this.uiService.displayLoader();
    await this.marketScanService.getMarketScanDeltaById(id).then(async (data: MarketScan) => {
      if (data) {
        this.marketScanService.setCurrentMarketScan(data);
        this.marketScanService.marketScanCopy = cloneDeep(data);
        await this.navService.setChildNavRoot(MarketScanDetailsComponent, PageName.MarketScanDetailsComponent, PageName.MarketScanPageComponent);
        this.navService.setChildNavRightPaneView(true);
      }
    });
    this.uiService.dismissLoader();
  }


  public getFormattedDate(value): string {
    if (value) {
      let date = new Date(parseInt(value));
      return `${this.datePipe.transform(date, 'MMM', undefined, this.translate.currentLang)}  ${date.getDate()}`;
    } else
      return this.translate.instant("NO_DATE");
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.onClosePage();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  private _initKeyControlsAreaModel() {
    this.listKeyControlAreaModel = {
      id: 'mscan-list-key-controls-area',
      isHidden: false,
      eventHandler: (id: string, event, eventName) => this.handleControlsAreaEvent(id, event, eventName),
      controls: [
        {
          id: 'create-btn',
          text: this.translate.instant('CREATE'),
          isHidden: false,
          leftAligned: false
        }
      ]
    };
  }

  private handleControlsAreaEvent(id, event, eventName) {
    if (id && eventName && eventName == 'controls-click') {
      switch (id) {
        case 'create-btn':
          this.createNewMarketScan();
          break;
        default:
          console.log('Unhandled switch case statement');
      }
    }
  }

  private async createNewMarketScan() {
    if(this.marketScanService.getSelectedMarketScan() && this.marketScanService.getSelectedMarketScan().isModified) {
      this.marketScanService.showDiscardChangesPopup().then (async res => {
        if(res.role === "ok") {
          await this.marketScanService.discardChanges();
          this.viewNewMarketScan();
        }
      });
    } else {
      this.viewNewMarketScan();
    }
  }

  async viewNewMarketScan() {
    if(_.isEmpty(this.marketScanService.categories)) {
      this.notificationService.notify(this.translate.instant('NO_CUSTOMER_SCAN_CATEGORY'), 'Market Scan', 'top', ToastStyle.DANGER);
      return;
    }
    await this.uiService.displayLoader();
    this.trackingService.tracking('CustomerScanCreate', TrackingEventNames.CUSTOMER_SCAN,true);
    let mScan: MarketScan = await this.marketScanService.initNewMarketScan();
    if(mScan) {
      mScan.isModified = false;
      this.marketScanService.setCurrentMarketScan(mScan);
      this.navService.setChildNavRoot(MarketScanDetailsComponent, PageName.MarketScanDetailsComponent, PageName.MarketScanPageComponent);
      this.navService.setChildNavRightPaneView(true);
      this.updateVisibleRecords();
    }
    this.uiService.dismissLoader();
  }

  public sortMarketScans() {
    if(this.marketScanService.getSelectedMarketScan() && this.marketScanService.getSelectedMarketScan().isModified) {
      this.marketScanService.showDiscardChangesPopup().then (async res => {
        if(res.role === "ok") {
          await this.marketScanService.discardChanges();
          this.marketScanService.setCurrentMarketScan(cloneDeep(this.marketScanService.marketScanCopy));
          this.showSortPopover();
        }
      });
    } else {
      this.showSortPopover();
    }
  }

  async showSortPopover() {
    const popover = await this.popoverCtrl
    .create({ component: MultiSelectPopover, componentProps: { root: this.sortPopoverData }, event: event}).then((data) => {
      data.present();
    });
    if (this.sortBy['value'] === 'indskr_date') {
      this.sortPopoverData[0].value = this.sortBy['value'];
    }
  }

  private updateVisibleRecords() {
    this.visibleCount = 20;
    if (this.filteredMScans.length > 20) {
      this.filteredMScansList = this.filteredMScans.slice(0, this.visibleCount);
    } else {
      this.filteredMScansList = this.filteredMScans;
    }
    this.handleSearch();
    this.updateEmptyMessage();
  }

  updateEmptyMessage() {
    this.uiService.updateNothingSelectedScreenMessageFor(this.filteredMScansList.length)
  }


  get filteredMScans(): any[] {
    let filteredObjs: any[] = [];
    if (this.marketScans.length) {
      if (this.sortBy && this.sortBy['value'] && this.sortBy['value'] === "indskr_date") {
        const withoutDate = this.marketScans.filter(ms => !ms.indskr_date);
        filteredObjs.push(...withoutDate.sort((a, b) => new Date(parseInt(b.createdon)).getTime() - new Date(parseInt(a.createdon)).getTime()));
        const withDate = this.marketScans.filter(ms => ms.indskr_date);
        filteredObjs.push(...withDate.sort((a, b) => new Date(parseInt(b.indskr_date)).getTime() - new Date(parseInt(a.indskr_date)).getTime()));
      } else {
        filteredObjs = this.marketScans.sort((a, b) => new Date(parseInt(b.modifiedon)).getTime() - new Date(parseInt(a.modifiedon)).getTime());
      }
    }
    return filteredObjs;
  }

  public doInfinite(event) {
    this.visibleCount = this.filteredMScansList.length;
    this.filteredMScansList.push(...this.filteredMScans.slice(this.visibleCount, this.visibleCount + 20));
    this.handleSearch();
    event.target.complete();
  }

  public async handleSearch() {
    if (this.marketScanSearchText && this.marketScanSearchText.trim().length > 0) {
      const filteredListWithoutDate = this.filteredMScans.filter(c => c.indskr_name && c.indskr_name.toLowerCase().includes(this.marketScanSearchText.toLowerCase()) && !c.indskr_date).sort((a, b) => {
        if (this.sortBy['value'] === "indskr_date") {
          return new Date(parseInt(b.createdon)).getTime() - new Date(parseInt(a.createdon)).getTime();
        } else {
          return new Date(parseInt(b.modifiedon)).getTime() - new Date(parseInt(a.modifiedon)).getTime();
        }
      });
      const filteredListWithDate = this.filteredMScans.filter(c => c.indskr_name && c.indskr_name.toLowerCase().includes(this.marketScanSearchText.toLowerCase()) && c.indskr_date).sort((a, b) => {
        if (this.sortBy['value'] === "indskr_date") {
          return new Date(parseInt(b.indskr_date)).getTime() - new Date(parseInt(a.indskr_date)).getTime();
        } else {
          return new Date(parseInt(b.modifiedon)).getTime() - new Date(parseInt(a.modifiedon)).getTime();
        }
      });
      this.filteredMScansList = filteredListWithoutDate.concat(filteredListWithDate);
    } else {
      this.filteredMScansList = this.filteredMScans;
    }
    if(this.sectionHeaderData) {
      this.sectionHeaderData.title = `${this.getFilterText()} (${this.filteredMScansList.length})`;
    }
    this.updateEmptyMessage();
  }

  onSearchBarFocus() {
    if(this.marketScanService.getSelectedMarketScan() && this.marketScanService.getSelectedMarketScan().isModified) {
      this.marketScanService.showDiscardChangesPopup().then (async res => {
        if(res.role === "ok") {
          await this.marketScanService.discardChanges();
          this.marketScanService.setCurrentMarketScan(cloneDeep(this.marketScanService.marketScanCopy));
        }
      });
    }
  }

  getFilterText() {
    let str = '';
    if (!this.marketScanSearchText.length) {
      str = this.translate.instant('ALL_CUSTOMER_SCANS');
    } else {
      str = this.translate.instant('AL_RESULTS');
    }
    return str;
  }

  private initSectionHeaderData() {
    this.sectionHeaderData = {
      id: 'section-header',
      title: `${this.getFilterText()} (${this.filteredMScansList.length})`,
      controls: [
        {
          id: 'customer-scan-sort',
          text: this.sortBy.text,
          isDisabled: false,
          img: "assets/imgs/sort_with_double_arrows.svg",
        }
      ],
    };
  }

  onSectionHeaderControlClick(id: string) {
    if (id === 'customer-scan-sort') {
        this.sortMarketScans();
    }
  }

}
