<ion-header>
    <ind-page-title [ngClass]="{'paddingScreen': inMeetingPresentationFlow}" [viewData]='captureAllocationOrder' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
  </ion-header>
<ion-content [ngClass]="inMeetingPresentationFlow?'npPaddingScreenInPresentation':'paddingScreen'">
    <ion-grid>
        <ion-row justify-content-center>
            <ion-col class="orderCaptureScreenWrapper" size="auto">
                <ion-list>
                    <ind-section-header class="detailSectionHeader" [viewData]="detailsHeaderModel"></ind-section-header>
                    <div class="sampleInfoWrapper">
                        <ion-item *ngIf="activity.orderId">
                            {{'CAPTURE_ORD' |translate}} {{activity.orderId}} {{'ON' | translate}} {{getFormattedAndLocalisedDate(activity.orderDate)}}
                        </ion-item>
                        <ion-item *ngIf="activity.orderDateByUser">
                          {{'ALLOCATION_ORDER_DATE' |translate}}{{':'}} {{getFormattedAndLocalisedDateAndTime(activity.orderDateByUser)}}
                      </ion-item>
                        <ion-item>
                            {{activity.contactName}}
                        </ion-item>
                        <ng-container *ngIf="!isCompletedActivity">
                            <ion-item *ngFor="let license of sampleService.validatedLicenses">
                                {{ license.typeLabel + ' | ' + license.licenseNumber }}
                            </ion-item>
                        </ng-container>
                        <ion-item text-wrap>
                            {{activity.location}}
                        </ion-item>
                    </div>
                    <p></p>
                    <ion-item *ngIf="!isLastDispatchDate" class="sampleDeatilsWrapper">
                        <ion-list style="width: 100%;">
                            <ion-grid>
                                <ion-row class="detailText detailTextTitle">
                                    <ion-col size="3">{{'PRODUCT' | translate}}</ion-col>
                                    <ion-col size="3">{{'CAPTURE_LOT' | translate}}</ion-col>
                                    <ion-col size="3">{{'CAPTURE_EXPIRATION' | translate}}</ion-col>
                                    <ion-col size="3">{{'QUANTITY' | translate}}</ion-col>
                                </ion-row>
                                <div *ngFor="let sample of nonDeletedSamplesData" style="width: 100%;">
                                    <ion-row class="detailText" *ngFor="let lot of sample.lots" style="width: 100%;">
                                        <ion-col size="3">{{sample.indskr_skuname}}</ion-col>
                                        <ion-col size="3">{{lot.indskr_lotname}}</ion-col>
                                        <ion-col size="3">{{getLotExpirationDate(lot)}}</ion-col>
                                        <ion-col size="3">{{lot.indskr_quantity}}</ion-col>
                                    </ion-row>
                                </div>
                            </ion-grid>
                        </ion-list>
                    </ion-item>
                    <ion-item *ngIf="isLastDispatchDate" class="sampleDeatilsWrapper">
                      <ion-list style="width: 100%;">
                          <ion-grid>
                              <ion-row class="detailText detailTextTitle">
                                  <ion-col size="2.4">{{'PRODUCT' | translate}}</ion-col>
                                  <ion-col size="2.4">{{'CAPTURE_LOT' | translate}}</ion-col>
                                  <ion-col size="2.4">{{'AL_LAST_DISPATCH_DATE' | translate}}</ion-col>
                                  <ion-col size="2.4">{{'CAPTURE_EXPIRATION' | translate}}</ion-col>
                                  <ion-col size="2.4">{{'QUANTITY' | translate}}</ion-col>
                              </ion-row>
                              <div *ngFor="let sample of nonDeletedSamplesData" style="width: 100%;">
                                  <ion-row class="detailText" *ngFor="let lot of sample.lots" style="width: 100%;">
                                      <ion-col size="2.4">{{sample.indskr_skuname}}</ion-col>
                                      <ion-col size="2.4">{{lot.indskr_lotname}}</ion-col>
                                      <ion-col size="2.4">{{getLotLastDispatchDate(lot)}}</ion-col>
                                      <ion-col size="2.4">{{getLotExpirationDate(lot)}}</ion-col>
                                      <ion-col size="2.4">{{lot.indskr_quantity}}</ion-col>
                                  </ion-row>
                              </div>
                          </ion-grid>
                      </ion-list>
                    </ion-item>
                    <p></p>
                    <ind-section-header [viewData]="termsHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
                    <ion-grid class="ion-margin-start">
                        <ion-row class='consent-terms'>
                            <div id="termBodyContainer" [ngClass]="isViewLess?'view-less':'view-all'" *ngIf="termBodyView" [innerHTML]="termBodyView" (click)="openUrl($event)"></div>
                            <div id="termBodyContainer" *ngIf="!termBodyView"><br></div>
                        </ion-row>
                    </ion-grid>
                    <p></p>
                    <ind-section-header [viewData]="signatureHeaderModel"></ind-section-header>
                    <ion-item class="agreementContainer" *ngIf="termBodyView && (isCompletedActivity || !this.consentService.allocationRemoteFlow)">
                      <ion-label class="agreeText">{{ consentTerm.indskr_agreementmessage ||('I_AGREE_ON_TERMS' | translate)}}</ion-label>
                      <ion-toggle
                        [disabled]="isCompletedActivity"
                        [(ngModel)]="consentTerm.isAcceptedTerms"
                      ></ion-toggle>
                    </ion-item>
                    <ion-item class="confirmSignatureContainer" [ngClass]="{'confirmContainer': (!isCompletedActivity && !isSignatureAllowed)}">
                        <ion-button class="confirmTextBtn" [ngClass]="{'disabled':(isCompletedActivity || (!consentService.allocationRemoteFlow && !consentTerm.isAcceptedTerms))}"
                          *ngIf="!isSignatureAllowed" (click)="openConfirmationPopup()" color="primary">
                            {{'CONFIRM' | translate}}
                        </ion-button>
                        <div *ngIf="isSignatureAllowed && !isCompletedActivity" class="signatureImageContainer" [ngClass]="{'disabled':(!consentService.allocationRemoteFlow && !consentTerm.isAcceptedTerms)}">
                            <img [src]="'assets/imgs/omni-sign-icon.svg'" class="redSignFlag">
                            <div class="ion-text-center signatureWrapper" [ngClass]="{'forceDisabled':consentService.allocationRemoteFlow && (remoteConnectionLost || device.isOffline)}">
                                <p class="ion-text-center" (click)="launchSignaturePad()">{{(!this.consentService.allocationRemoteFlow ? 'CONSENT_TAP_TO_SIGN' : 'CAPTURE_REMOTE_SIGN') | translate}}</p>
                            </div>
                        </div>
                        <div *ngIf="isCompletedActivity && activity.signature" class="signatureImageContainer capturedSignatureImageContainer">
                            <img [src]="'assets/imgs/omni-sign-icon.svg'" class="redSignFlag">
                            <div class="capturedImage signatureWrapper ion-text-center">
                                <img [src]="activity.signature" width="100" height="100">
                            </div>
                        </div>
                    </ion-item>
                </ion-list>
            </ion-col>
            <ion-col size="2"></ion-col>
        </ion-row>
    </ion-grid>
</ion-content>

<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter() && footerService.fabNotRequestedFromPresentation('Sample')" [footerView]="'detailView'" [selectedView]="'CaptureSampleOrder'"></footer-toolbar>
<!-- </ion-footer> -->