import { debounceTime, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonContent, ModalController, NavController, NavParams, PopoverController, } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Events } from '@omni/events';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { RepServices } from '../../../data-services/rep/rep.services';
import { AccountOfflineService, EXCLUDED_ACCOUNT_GLANCE_CARD_ATTRIBUTES } from '../../../services/account/account.offline.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { DeviceService } from '../../../services/device/device.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { TrackingEventNames, TrackService } from '../../../services/logging/tracking.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { NewActivityComponent } from '../../activity/new-activity/new-activity';
import { NothingSelectedView } from '../../shared/nothing-selected-view/nothing-selected-view';
import { ComponentViewMode, UIService } from './../../../services/ui/ui.service';
import { EventName, EventsService } from '@omni/services/events/events.service';
import { AffiliationExplorerComponent } from '@omni/components/shared/affiliation-explorer/affiliation-explorer';
import { AssessmentTemplate, EntityOptions, TemplateType } from '@omni/classes/customer-assessment/assessment-template.class';
import { CustomerAssessment } from '@omni/classes/customer-assessment/customer-assessment.class';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { AccountPageComponent } from './../account-page/account-page';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { GlanceCardViewDataModel } from '@omni/models/glanceCardViewDataModel';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { PersonalizeQuickGlanceViewDataModel } from '@omni/models/personalizeQuickGlanceViewModel';
import { PersonalizeQuickGlanceComponent } from '@omni/components/shared/personalize-quick-glance/personalize-quick-glance';
import { ControlDataType, DynamicForm, FormType } from '@omni/classes/dynamic-form/dynamic-form.class';
import { DefaultAccountDisplayForm } from '@omni/config/dynamic-forms/default-account-display';
import { AccountDataService } from '@omni/data-services/accounts/account.data.service';
import { Account } from '@omni/classes/account/account.class';
import { ActivityService, ActivitySource } from '@omni/services/activity/activity.service';
import { InitiatePhoneCallPayload, PhoneCallDataService } from '@omni/data-services/phone-call/phonecall.data.service';
import { OFFLINE_PHONE_CALL_ID_PREFIX, PhoneActivity } from '@omni/classes/activity/phone.activity.class';
import { PhoneCallDetailsComponent } from '@omni/components/phonecall/phone-call-details/phone-call-details';
import { PhoneCallMeetingStructureService } from '@omni/services/phone-call-meeting-structure/phone-call-meeting-structure.service';
import { addMinutes, format } from 'date-fns';
import { Utility } from '@omni/utility/util';
import { InitiateMeetingPayload, MeetingDataService, UpdateMeetingPayload } from '@omni/data-services/meeting/meeting.data.service';
import _, { String } from 'lodash';
import { MeetingStructureService } from '@omni/services/meeting-structure/meeting-structure.service';
import { ActivitiesDetailsPaneComponent } from '@omni/components/activity/activities-details-pane/activities-details-pane';
import { AppointmentActivity, OFFLINE_ID_PREFIX } from '@omni/classes/activity/appointment.activity.class';
import { ActivityType } from '@omni/classes/activity/activity.class';
import { ContentMatchingService } from '@omni/services/content-matching/content-matching.service';
import { Contact } from '@omni/classes/contact/contact.class';
import { TerritoryManagementService } from '@omni/services/territory-management/territory-management.service';
import { ContactDataService } from '@omni/data-services/contact/contact.data.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

const DEFAULT_FORM_LANGUAGE_CODE = "0000";
/**
 * Generated class for the AccountDetailsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'account-details',
  templateUrl: 'account-details.html',
  styleUrls:['account-details.scss']
})
export class AccountDetailsComponent implements OnInit {
  public isFollowupTaskEnabled = false;
  private isLayoverPushView = false;
  private layoverViewPushedFrom: string;
  public isNewActivityCreationEnabled = false;
  @Input() from: any;
  @Input() callbackEvent: any;
  tabsData: IndTabsDataModel[];
  public accountDetailSegment: string;
  public pageTitle = '';
  public pageTitleControls = [];
  public okPageTitleControls = [];
  // public isPopPageBtnEnabled:boolean = false;
  @ViewChild(IonContent) ionContent: IonContent;
  private scrollBehaviorSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public readonly scrollObservable: Observable<any> = this.scrollBehaviorSubject.asObservable().pipe(debounceTime(100));
  private refreshAccountNotesBehaviorSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly refreshAccountNotesObservable: Observable<boolean> = this.refreshAccountNotesBehaviorSubject.asObservable();
  public hasCustomerAssessFA: boolean = false;
  public accountAssessmentTemplates: AssessmentTemplate[] = [];
  public accountAssessmentTemplatesTeamview: AssessmentTemplate[] = [];
  public assessRawData: CustomerAssessment;
  public customerAssessmentIsLoading: boolean = true;
  public customerAssessmentTeamviewIsLoading: boolean = true;
  @ViewChild('accountAssessment') accountAssessment;

  private _isDisabledGoToAccountsToolBtn: boolean = false;
  public isGoToAccountBtnEnabled: boolean = false;
  private ngDestroy$ = new Subject<boolean>();
  private isTeamViewEnabled: boolean = false;
  public isFromChat: boolean = false;
  public isOpenedUnmappedAffiliationData : boolean = false;
  public accountGlanceViewData:GlanceCardViewDataModel;

  constructor(
    public accountService: AccountOfflineService,
    private readonly accountDataService: AccountDataService,
    public device: DeviceService,
    public navCtrl: NavController,
    public uiService: UIService,
    public repService: RepServices,
    private readonly events: EventsService,
    private readonly navService: NavigationService,
    public trackingService: TrackService,
    private readonly authenticationService: AuthenticationService,
    public readonly footerService: FooterService,
    private readonly translate: TranslateService,
    private dynamicFormService: DynamicFormsService,
    private customerAssessService: CustomerAssessService,
    private contactService: ContactOfflineService,
    public notificationService: NotificationService,
    private navParams: NavParams,
    private readonly popoverCtrl: PopoverController,
    private readonly activityService: ActivityService,
    private readonly phoneCallDataService: PhoneCallDataService,
    private readonly phoneCallMeetingStructureService: PhoneCallMeetingStructureService,
    private readonly meetingService: MeetingDataService,
    private readonly meetingStructureService: MeetingStructureService,
    private readonly contentMatchingService: ContentMatchingService,
    private territoryService: TerritoryManagementService,
    private contactDataService: ContactDataService,
    private modalCtrl: ModalController,
    private featureActionService: FeatureActionsService
  ) {
    this.events.subscribe('device:deviceIsOffline', (status) => {
      this.device.isOffline = true;
    });

    this.isFromChat = this.navParams && this.navParams.data && this.navParams.data.viewData &&  this.navParams.data.viewData.from == 'openChat';
    this.isOpenedUnmappedAffiliationData = this.dynamicFormService.openedUnmappedData;
    this._initTabsData();
  }

  private _initTabsData(){
    if(this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER360UI,true)){
      this.uiService.accountDataSegment = this.accountDetailSegment = this.isFromChat ? 'info' : 'feed';
      this.tabsData = [
        {
          displayText: this.translate.instant('FEED'),
          value: 'feed',
        },
        {
          displayText: this.translate.instant('OMNICHANNEL'),
          value: 'profile',
          disable: this.accountService.isOneKeySearching,
        },
        {
          displayText: this.translate.instant('ASSESS'),
          value: "customer-assess",
          disable: this.isFromChat
        },
        
        // {
        //   displayText: this.translate.instant('TIMELINE'),
        //   value: 'timeline',
        //   disable: this.accountService.isOneKeySearching,
        // },
        {
          displayText: this.translate.instant('INFO'),
          value: 'info'
        },
      ];
    }else {
      this.uiService.accountDataSegment = this.accountDetailSegment = 'info';
      this.tabsData = [
        {
           displayText: this.translate.instant('INFO'),
           value: 'info'
        },
        {
          displayText: this.translate.instant('ASSESS'),
          value: "customer-assess",
          disable: this.isFromChat
        },
        {
         displayText: this.translate.instant('PROFILE'),
         value: 'profile',
          disable: this.accountService.isOneKeySearching,
        },
        {
          displayText: this.translate.instant('TIMELINE'),
          value: 'timeline',
          disable: this.accountService.isOneKeySearching,
         }
      ];
    }
  }


  async closepage() {
    if (this.from == 'NotificationDetails') {
      this.navService.popChildNavPageWithPageTracking();
      return;
    }
    if(this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) return;
    this.accountService.selected = undefined;
    this.navService.setChildNavRoot(NothingSelectedView,PageName.NothingSelectedView,PageName.AccountPageComponent, {
        deviceString: this.device.deviceFlags.ios ? 'ios' : 'other',
      });
    this.navService.setChildNavRightPaneView(false);
    setTimeout(()=>{
      this.events.publish('accountListContentResize');
    },0);
  }

  ngOnInit() {
    if (this.from) {
      this.isLayoverPushView = true;
      if (this.from === 'AccountManagementTool') {
        this.layoverViewPushedFrom = 'AccountManagementTool';
      } else if (this.from === 'AccountTool') {
        this.layoverViewPushedFrom = 'AccountTool';
      }
      else if(this.from == PageName.TerritoryManagementDetailsComponent){
        this.tabsData.find(a=> a.value == 'profile').disable = true;
        this.tabsData.find(a=> a.value == 'timeline').disable = true;
      }
    }
    if (this.uiService.accountDataSegment !== '') {
      this.accountDetailSegment = this.uiService.accountDataSegment;
    }
    if (this.isFromChat) this.accountService.accountPageMode = ComponentViewMode.PREVIEW;
    this.isFollowupTaskEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY);
    this.isTeamViewEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.ASSESSMENTS_TEAM_VIEW);
    this.isNewActivityCreationEnabled = this.accountService.accessedAccountListFrom === PageName.ActivitiesPageComponent;
    if (this.navParams && this.navParams.data && this.navParams.get('listMode') && (this.navParams.get('listMode') == ComponentViewMode.ADDNEW || this.navParams.get('listMode') == ComponentViewMode.SELECTION)) {
      this.isNewActivityCreationEnabled = false;
    }
    // if ((this.dynamicFormService.isOpenedAffiliatedAccountOnContact && this.navService.getActiveChildNavViewPageName() === PageName.ContactDetailsComponent)
    // || (this.dynamicFormService.isOpenedAffiliatedAccountOnAccount && this.navService.getActiveChildNavViewPageName() === PageName.AccountDetailsComponent)) {
    //   this.accountService.isEnableGoToAccountsTool = true;
    //   this.isPopPageBtnEnabled = true;
    // }

    // if(this.navService.getActiveChildNavViewPageName() === PageName.CallPlanCustDetailsComponent && this.navParams && this.navParams.data && this.navParams.get('accountListMode') == ComponentViewMode.READONLY) {
    //   this.isPopPageBtnEnabled = true;
    // }

    this.hasCustomerAssessFA = this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_ASSESS);
    this.tabsData.find(tab => tab.value == "customer-assess").hide = !this.hasCustomerAssessFA;
    this.initPageTitle();
    this.initOKPageTitle();
    this._initGlanceCardData();
    this.initCustomerAssessData();

    this.dynamicFormService.affiliatedContacts = [];

    this.events.observe('refreshAccountDetailPageTitle').pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      // if ((this.dynamicFormService.isOpenedAffiliatedAccountOnContact && this.navService.getActiveChildNavViewPageName() === PageName.ContactDetailsComponent)
      // || (this.dynamicFormService.isOpenedAffiliatedAccountOnAccount && this.navService.getActiveChildNavViewPageName() === PageName.AccountDetailsComponent)) {
      //   this.accountService.isEnableGoToAccountsTool = true;
      //   this.isPopPageBtnEnabled = true;
      // } else {
      //   this.accountService.isEnableGoToAccountsTool = false;
      //   this.isPopPageBtnEnabled = false;
      // }
      this.initPageTitle();
      this._initGlanceCardData();
    });

    this.events.observe('refershAccountDetailSegment').pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      this.accountDetailSegment = this.uiService.accountDataSegment;
      if (this.uiService.accountDataSegment != 'timeline') {
        this.accountService.isOpenedSurveyTimelineFromAccountProfile = false;
      }
    });

    this.events.observe(EventName.QUICKGLANCECACCOUNTREATEPHONECALL)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((flag) => {
        this._handleCreatePhoneCall();
      });

    this.events.observe(EventName.QUICKGLANCECACCOUNTREATEMEETING)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((flag) => {
        this._handleCreateMeeting();
      });
  }

  ionViewDidEnter(){
    if(this.isFromChat){
      this.footerService.initButtons("");
    } else if(this.navService.getActiveChildNavViewPageName() === PageName.AccountDetailsComponent){
      this.refreshAccountNotesBehaviorSubject.next(true);
      this.footerService.initButtons(FooterViews.Accounts);
    }else if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent && this.isFromChat) {
      this.footerService.initButtons(FooterViews.Accounts);
    }else if(this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent){
      this.footerService.initButtons(FooterViews.Activities);
    }else if(this.navService.getActiveChildNavViewPageName() === PageName.CaseManagementDetailComponent){
      this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
    }
  }

  ngAfterViewInit(){
    this.accountDetailSegment = this.uiService.accountDataSegment || this.tabsData[0].value;
  }

  ngOnDestroy() {
    if (this.accountService.accountPageMode === ComponentViewMode.PREVIEW) this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
    if (this.contactService.contactPageMode === ComponentViewMode.PREVIEW) this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
    this.dynamicFormService.isOpenedAffiliatedContactOnAccount = false;
    this.dynamicFormService.isOpenedAffiliatedAccountOnAccount = false;
    this.dynamicFormService.isOpenedAffiliatedAccountOnContact = false;
    this.dynamicFormService.isOpenedAffiliatedContactOnContact = false;
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  initCustomerAssessData() {
    if (this.hasCustomerAssessFA) {
      this.accountAssessmentTemplates = this.customerAssessService.getTemplatesForEntity(TemplateType.ASSESSMENT, EntityOptions.ACCOUNT);
      if(this.isTeamViewEnabled) this.accountAssessmentTemplatesTeamview = this.customerAssessService.getTemplatesTeamviewForEntity(TemplateType.ASSESSMENT, EntityOptions.ACCOUNT);
      if (this.accountAssessmentTemplates.length == 1) {
        this.customerAssessService.fetchAccountAssessment(this.accountService.selected.id, this.accountAssessmentTemplates[0].indskr_assessmenttemplateid).then((data: CustomerAssessment) => {
          this.assessRawData = data;
          this.customerAssessmentIsLoading = false;
          this.customerAssessmentTeamviewIsLoading = false;
        });
      } else {
        this.customerAssessmentIsLoading = false;
        this.customerAssessmentTeamviewIsLoading = false;
      }
    }
  }

  public handlePlusButton(){
    this.navService.pushChildNavPageWithPageTracking(NewActivityComponent,PageName.NewActivityComponent,PageName.AccountPageComponent,{from:'AccountTool',account:this.accountService.selected});
  }

  public savedCustomerAssessment(data: CustomerAssessment) {
    this.assessRawData = data;
  }

  handleAffiliationExplorer(){
    this.navService.pushChildNavPageWithPageTracking(AffiliationExplorerComponent,PageName.AffiliationExplorerComponent,PageName.AccountPageComponent ,
      { from: 'AccountPageComponent',parent:this.accountService.selected,
      relatedFrom:this.accountService.linkEntityAccountFrom,
      relatedTo: this.accountService.linkEntityAccountTo,
      contactAccountAffiliation:this.accountService.linkedAccountContact,
      accountToBrand:this.accountService.brandAffiliationByAccountId
    });
  }

  async segmentChanged(slectedTab: string) {
    if (this.hasCustomerAssessFA) {
      if (this.uiService.accountDataSegment == "customer-assess" && this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) {
        return;
      }
    }
    if (this.ionContent) {
    this.ionContent.scrollToTop();
    }
    this.uiService.accountDataSegment = this.accountDetailSegment = slectedTab;
    if (slectedTab == 'timeline') {
      this.trackingService.tracking('AccountTimeline', TrackingEventNames.ACCOUNTS);
    }
    if (slectedTab == 'info') {
      this.trackingService.tracking('AccountInfo', TrackingEventNames.ACCOUNTS);
    }
    if (slectedTab != 'timeline') {
      this.accountService.isOpenedSurveyTimelineFromAccountProfile = false;
    }
    this.initPageTitle();
  }

  isSplitView() {
    return (window.innerWidth > 620);
  }

  private _isPopPageBtnEnabled(): boolean {
    let isPopPageBtnEnabled: boolean = false;
    if ((this.dynamicFormService.isOpenedAffiliatedAccountOnContact && this.navService.getActiveChildNavViewPageName() === PageName.ContactDetailsComponent)
    || (this.dynamicFormService.isOpenedAffiliatedAccountOnAccount && this.navService.getActiveChildNavViewPageName() === PageName.AccountDetailsComponent)) {
      this.accountService.isEnableGoToAccountsTool = true;
      isPopPageBtnEnabled = true;
    }else if(this.navService.getActiveChildNavViewPageName() === PageName.CallPlanCustDetailsComponent && this.navParams && this.navParams.data && this.navParams.get('accountListMode') == ComponentViewMode.READONLY) {
      isPopPageBtnEnabled = true;
    }
    return isPopPageBtnEnabled;
  }

  public isAccountAssessmentFormDirty(isDirty: boolean) {
    const index = this.pageTitleControls.findIndex(button => button.id == 'save');
    if (index > -1) {
      this.pageTitleControls[index].isDisabled = this.device.isOffline ? true : !isDirty;
      this.customerAssessService.isAssessmentDirty = isDirty;
    }
  }

  poppage() {
    if (this.navService.getActiveChildNavViewPageName() === PageName.ContactDetailsComponent) {
      if (this.dynamicFormService.isOpenedAffiliatedAccountOnContact) {
        if (this.accountService.accountPrevPageMode === ComponentViewMode.ADDNEW || this.accountService.accountPrevPageMode === ComponentViewMode.SELECTION) {
          this.accountService.accountPageMode = this.accountService.accountPrevPageMode;
        } else {
          this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
        }
        this.dynamicFormService.isOpenedAffiliatedAccountOnContact = false;
        this._isDisabledGoToAccountsToolBtn = false;
        this.dynamicFormService.openedUnmappedData = false;
        this.events.publish('refreshDisplayForm');
      }
      // this.uiService.activeView = 'contactDetails';
      this.accountService.selected = undefined;
      this.footerService.initButtons(FooterViews.Contacts);
      this.navService.popChildNavPageWithPageTracking();
    } else if (this.dynamicFormService.isOpenedAffiliatedAccountOnContact) {
      if (this.accountService.accountPrevPageMode === ComponentViewMode.ADDNEW || this.accountService.accountPrevPageMode === ComponentViewMode.SELECTION) {
        this.accountService.accountPageMode = this.accountService.accountPrevPageMode;
      } else {
        this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
      }
        this.dynamicFormService.isOpenedAffiliatedAccountOnContact = false;
        this._isDisabledGoToAccountsToolBtn = false;
        this.dynamicFormService.openedUnmappedData = false;
        this.events.publish('refreshDisplayForm');
        this.navService.popChildNavPageWithPageTracking();
    } else if (this.dynamicFormService.isOpenedAffiliatedAccountOnAccount) {
      
      if (this.accountService.accountPrevPageMode === ComponentViewMode.ADDNEW || this.accountService.accountPrevPageMode === ComponentViewMode.SELECTION) {
        this.accountService.accountPageMode = this.accountService.accountPrevPageMode;
      } else {
        this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
      }
      if (this.accountService.prevSelected) this.accountService.selected = this.accountService.prevSelected;
      this.events.publish('highlightAccount', this.accountService.selected);
      this.dynamicFormService.isOpenedAffiliatedAccountOnAccount = false;
      this._isDisabledGoToAccountsToolBtn = false;
      this.isOpenedUnmappedAffiliationData = this.dynamicFormService.openedUnmappedData = false;
      this.uiService.accountDataSegment = this.accountDetailSegment = 'info';
      this.events.publish('refreshDisplayForm');
    } else if (this.navService.getActiveChildNavViewPageName() === PageName.CallPlanCustDetailsComponent) {
      this.navService.popChildNavPageWithPageTracking();
    } else {
      this.navService.popWithPageTracking();
    }
  }

  private initPageTitle(): void {
    let buttons = [];
    if(this.isFromChat) {
      buttons.push({
        id: 'close',
        icon: 'close-outline',
        isDisabled: false,
        align: "left"
      })
    }
    if (!this.isSplitView() || this._isPopPageBtnEnabled()) {
      buttons.push({
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      });
    }
    if (this.accountDetailSegment === 'customer-assess') {
      buttons.push({
        id: 'save',
        imgSrc: 'assets/imgs/header_save.svg',
        name: this.translate.instant('SAVE'),
        isDisabled: true,
        align: 'right'
      })
    } else if (this.isNewActivityCreationEnabled) {
      const hideNewActivityCreation = (this.featureActionService.isShortCallLauncherEnabledInMobileDevice && this.authenticationService.hasFeatureAction(FeatureActionsMap.SHOR_CALL_LAUNCHER_DISABLE_APPOINTMENT_IN_NEW_ACTIVITY)) ?
      !(this.authenticationService.hasFeatureAction(FeatureActionsMap.STORE_CHECK) ||
      this.authenticationService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT) ||
      this.authenticationService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY) ||
      this.authenticationService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG))
      : false;
      if (this.accountService.isAffiliationEnabled && !this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER360UI,true)) {
        buttons.push({
          id: "affiliation-explorer",
          imgSrc: 'assets/imgs/affiliation-explorer.svg',
          name: this.translate.instant('AFFILIATION_EXPLORER'),
          isDisabled: !((this.accountService.linkEntityAccountFrom && this.accountService.linkEntityAccountFrom.length) || (this.accountService.linkEntityAccountTo && this.accountService.linkEntityAccountTo.length)
            || (this.accountService.brandAffiliationByAccountId && this.accountService.brandAffiliationByAccountId.length) || (this.accountService.linkedAccountContact && this.accountService.linkedAccountContact.length)),
          align: "right"
        })
      }
      if (!hideNewActivityCreation) {
        buttons.push({
          id: 'pluse-icon',
          imgSrc: 'assets/imgs/ios_add_3x.svg',
          name: this.translate.instant('CREATE'),
          isDisabled: this.accountService.isOneKeySearching,
          align: 'right'
        });
      }
    }
    this.isGoToAccountBtnEnabled = this.accountDetailSegment != 'customer-assess'
                                                && (this.contactService.contactPageMode == ComponentViewMode.PREVIEW || this.accountService.accountPageMode == ComponentViewMode.PREVIEW)
                                                && (this.uiService.isAccountsFromToolDrawer || this.uiService.isContactsFromToolDrawer)
                                                && this._isPopPageBtnEnabled() && this.accountService.isEnableGoToAccountsTool
                                                && (this.dynamicFormService.isOpenedAffiliatedAccountOnContact || this.dynamicFormService.isOpenedAffiliatedAccountOnAccount)
                                                && !this.uiService.isForcedHideGoToToolBtn;
    if (this.isGoToAccountBtnEnabled || (this.from == 'NotificationDetails' && this.accountDetailSegment != 'customer-assess')) {
      buttons = [];
      buttons.push({
          id: 'close',
          icon: 'chevron-back-outline',
          isDisabled: false,
          align: 'left'
      });
      buttons.push({
        id: "goToAccountsTool",
        imgSrc: 'assets/imgs/open_in_new_white.svg',
        name: this.translate.instant('OPEN'),
        isDisabled: this._isDisabledGoToAccountsToolBtn,
        align: "right"
      });
    }
    const isAssessTeamview: boolean = this.uiService.accountDataSegment =='customer-assess-teamview';
    if(isAssessTeamview) {
      buttons = [{
        id: "close-teamview",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      }];
    }
    if (this.from && this.from == PageName.TerritoryManagementDetailsComponent) {
      buttons = [];
      buttons.push({
          id: 'gotoTerritoryMgmtDetails',
          icon: 'chevron-back-outline',
          isDisabled: false,
          align: 'left'
      });
    }
    this.pageTitleControls = buttons;
  }

  private initOKPageTitle(): void {
    let buttons = [];
    if(!this.isSplitView() || this._isPopPageBtnEnabled()) {
      buttons.push({
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      });
    }
    const isAssessTeamview: boolean = this.uiService.accountDataSegment =='customer-assess-teamview';
    if(isAssessTeamview) {
      buttons = [{
        id: "close-teamview",
          icon: "chevron-back-outline",
          isDisabled: false,
          align: "left"
      }];
    }
    this.okPageTitleControls = buttons;
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'save':
        this.saveContactAssessment();
        break;
      case 'close':
        if (this.isFromChat) {
          this.modalCtrl.dismiss();
        } else if (this._isPopPageBtnEnabled()) {
          this.poppage();
        } else {
          this.closepage();
        }
        break;
      case 'close-teamview':
        this.uiService.accountDataSegment = 'customer-assess';
        this.initOKPageTitle();
        this.initPageTitle();
        break;
      case 'pluse-icon':
        this.handlePlusButton();
        break;
      case 'affiliation-explorer':
        this.handleAffiliationExplorer();
        break;
      case 'goToAccountsTool':
        this.handleGoToAccountsTool();
        break;
        case 'gotoTerritoryMgmtDetails':
        this.handleGoToTerritoryMgmtDetails();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private saveContactAssessment() {
    if (this.accountAssessment && !this.device.isOffline) {
      this.accountAssessment.saveCustomerAssessment({ entity: 'account', id: this.accountService.selected.id });
    }
  }

  onScroll(ev) {
    this.scrollBehaviorSubject.next(ev);
  }

  handleGoToAccountsTool() {
    this.accountService.isClickedGoToAccountsTool = true;
    if (!this.accountService.isNotInMyAccountList) {
      this.uiService.isAccountsFromToolDrawer = true;
      this.navService.popWithPageTracking();
      if (this.from == 'NotificationDetails') this.navService.popChildNavPageWithPageTracking();
      this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { from: 'AccountTool', listMode: ComponentViewMode.LISTVIEW, openedToolFromAccountDetails: true }, PageName.AccountPageComponent);
      this.navService.setChildNavRightPaneView(true);
    } else if (this.accountService.errorMessageOpenAccountDetails) {
      this.accountService.isClickedGoToAccountsTool = false;
      this.notificationService.notify(this.accountService.errorMessageOpenAccountDetails, 'Account Details', 'top', ToastStyle.INFO);
      this._isDisabledGoToAccountsToolBtn = true;
      this.initPageTitle();
    }
  }

  handleGoToTerritoryMgmtDetails() {
    this.navService.popChildNavPageWithPageTracking().then(() => {
      this.callbackEvent();
    });
    this.navService.setChildNavRightPaneView(true);
  }

  private async _initGlanceCardData(updateButtonsOnly: boolean = false) {
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER360UI, true)) {
      let glanceCardButtons = [];
      let account = this.accountService.getAccountById(this.accountService.selected.id);
      if (account && !this.isFromChat) {
        if(this.authenticationService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)){
          glanceCardButtons.push({
            id: "phone-call",
            imgSrc: 'assets/imgs/omni_quick_glance_call.svg',
            name: this.translate.instant('NEW_ACTIVITY_PHONECALL'),
            isDisabled: false,
            align: "right"
          });
        }
        glanceCardButtons.push({
          id: "quick-meeting",
          imgSrc: 'assets/imgs/omni_quick_glance_meeting.svg',
          name: this.translate.instant('MEETING'),
          isDisabled: false,
          align: "right"
        });
      }
      if (this.accountService.isAffiliationEnabled && !this.isFromChat && !this.isOpenedUnmappedAffiliationData) {
        glanceCardButtons.push({
          id: "affiliation-explorer",
          imgSrc: 'assets/imgs/glance-affiliation-explorer.svg',
          name: this.translate.instant('AFFILIATION_EXPLORER'),
          isDisabled: !((this.accountService.linkEntityAccountFrom && this.accountService.linkEntityAccountFrom.length) || (this.accountService.linkEntityAccountTo && this.accountService.linkEntityAccountTo.length)
            || (this.accountService.brandAffiliationByAccountId && this.accountService.brandAffiliationByAccountId.length) || (this.accountService.linkedAccountContact && this.accountService.linkedAccountContact.length)),
          align: "right"
        });
      }
      glanceCardButtons.push({
        id: "personalize-quick-glance",
        imgSrc: 'assets/imgs/personalize-quick-glance-options.svg',
        name: 'Personalize Quick Glance',
        isDisabled: this.isFromChat,
        align: "right"
      });
      if (updateButtonsOnly) {
        this.accountGlanceViewData.buttons = glanceCardButtons;
        return;
      }
      const values = await this.accountService._getQuickGlanceValues();
      if(!this.device.isOffline){
        this.accountDataService.getEntityImageStringForAccount(this.accountService.selected).then((entityImageString => {
          if(entityImageString){
            this.accountService.selected.entityImage = entityImageString;
            if(this.accountGlanceViewData){
              this.accountGlanceViewData.avatarURL = this.accountService.selected.entityImage
            }
          }
        }));
      }
      const address = await this.accountService.getPrimaryAddressOfAccount(this.accountService?.selected?.id);
      this.accountGlanceViewData = {
        name: this.accountService?.selected?.accountName,
        locationText: address ? address.compositeAdd : '',
        avatarURL: this.accountService.selected.entityImage,
        thirdText: (values && values.length && values[0]) ? values[0].value : '',
        fourthText: (values && values.length && values[1]) ? values[1].value : '',
        fifthText: (values && values.length && values[2]) ? values[2].value : '',
        sixthText: (values && values.length && values[3]) ? values[3].value : '',
        thirdTextLabel: (values && values.length && values[0]) ? values[0].label : '',
        fourthTextLabel: (values && values.length && values[1]) ? values[1].label : '',
        fifthTextLabel: (values && values.length && values[2]) ? values[2].label : '',
        sixthTextLabel: (values && values.length && values[3]) ? values[3].label : '',
        buttons: glanceCardButtons,
        enableExpand: false,
        isExpanded: true,
      };
    }
  }

  public async onQuickGlanceControlClick(data){
    if(data.id){
      switch(data.id) {
        case 'affiliation-explorer':
          this.handleAffiliationExplorer();
          break;
        case 'personalize-quick-glance':
          this._handleQuickGlanceOptions(data);
          break;
        case 'phone-call':
          this._handleCreatePhoneCall();
          break;
        case 'quick-meeting':
          this._handleCreateMeeting();
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  private async _handleQuickGlanceOptions(data){
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'Quick-GLance-Options-Select',
      data: [
        {
          title: this.translate.instant('PERSONALIZE_QUICK_GLANCE'),
          id: 'personalize_quick_glance',
          isSelected: false,
        },
      ],
    };
    let dropdownPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data.event?data.event:null });
    dropdownPopover.onDidDismiss().then(async (data:any) => {
      data = data.data;
      if (data && data.selectedItems && data.selectedItems.length == 1) {
        if(data.selectedItems[0].id == 'personalize_quick_glance'){
          this._openPersonalizeQuickGlanceView();
        }
      }
    });
    dropdownPopover.present();
  }

  private async _openPersonalizeQuickGlanceView(){
    let options = await this._getQuickGlanceOptions();
    let personalizeQuickGlanceView:PersonalizeQuickGlanceViewDataModel = {
      id: 'personalizeQuickGlance',
      pageTitle: {
        id:"personalizeQuickGlanceTitle",
        title: this.accountService.selected.accountName,
        controls: [
          {
            id: "close",
            icon: "chevron-back-outline",
            isDisabled: false,
            align: "left"
          },
          {
            id: "save",
            imgSrc: 'assets/imgs/header_save.svg',
            name: this.translate.instant('CR_SAVE'),
            isDisabled: true,
            align: "right"
          }
        ]
      },
      pageSectionHeader: {
        id:'personalizeQuickGlanceTitleSectionHeader',
        title: this.translate.instant('PERSONALIZE_QUICK_GLANCE'),
        controls: []
      },
      infoText: this.translate.instant('SELECT_ANY_4_SECTION_TO_VIEW_ON_CARD'),
      data: options,
      callbackEvent:async (id:string, event:any, dataRef:any)=> await this._handlePersonalizeQuickGlanceSave(id,event,dataRef),
    }
    this.navService.pushChildNavPageWithPageTracking(PersonalizeQuickGlanceComponent,PageName.PersonalizedQuickGlanceViewComponent,PageName.AccountPageComponent,{viewData: personalizeQuickGlanceView});
  }

  private async _getQuickGlanceOptions(){
    let result:Array<{id:string,title:string,isChecked:boolean}> = [];
    let accountForm: DynamicForm = await this.dynamicFormService.getFormDefinitionForEntity("account", FormType.DISPLAYFORM);
    const alreadySavedOptions = await this.accountService.getQuickGlanceOptions();
    const langCode = (!accountForm) ? DEFAULT_FORM_LANGUAGE_CODE : '1033';
    if (!accountForm) {
      accountForm = new DynamicForm(DefaultAccountDisplayForm['value'][0]);
    }
    accountForm.metadata.forEach((tab) => {
      if (tab && tab.controls && tab.controls.length > 0) {
        tab.controls.forEach(control => {
          if (this.dynamicFormService.checkIfControlShouldNotBeVisible(control) || control.forceHide || control.dataType == ControlDataType.StateType || control.dataType == ControlDataType.StatusType || !control.dataType  || !control.isVisible || EXCLUDED_ACCOUNT_GLANCE_CARD_ATTRIBUTES.some(a=> a == control.attributeName)) {
          }else {
            let displayTitle:string = '';
            const dt = control.displayNames.find(value => value.languagecode == langCode);
            if (dt) {
              displayTitle = dt.languagecode == DEFAULT_FORM_LANGUAGE_CODE ? this.translate.instant(dt.description) : dt.description;
            } else {
              let en = control.displayNames.find(value => value.languagecode == "1033"); // default to english if no translation for the specific language;
              if (en) {
                displayTitle = en.description
              } else {
                //recheck the default form language code in the case of custom fields
                const defalutLang = control.displayNames.find(value => value.languagecode == '0000');
                if(defalutLang && defalutLang.description) {
                  displayTitle = this.translate.instant(defalutLang.description);
                } else {
                  displayTitle = '';
                }
              }
            }
            result.push({
              id: control.attributeName,
              title: displayTitle,
              isChecked: alreadySavedOptions.some(a=> a == control.attributeName),
            })
          }
        });
      }
    });
    return result;
  }

  private async _handlePersonalizeQuickGlanceSave(id,event,dataRef){
    if(dataRef && Array.isArray(dataRef)){
      const selectedAttributes = dataRef.filter(a=> a.isChecked)?.map(b=> b.id);
      await this.accountService.saveQuickGlanceOptions(selectedAttributes);
      this._initGlanceCardData();
    }
  }

  private async _handleCreatePhoneCall() {
    if(!this.device.isOffline) this.uiService.displayLoader();
    const payload: InitiatePhoneCallPayload = this._preparePhoneCallPayload();
    const newPhoneCall = await this.phoneCallDataService.createPhoneCallActivity(payload);
    if (newPhoneCall) {
      let data;
      if (!newPhoneCall.createdOffline) {
        // Offline created meeting
        data = {
          id: newPhoneCall.ID,
          subject: newPhoneCall.subject,
          location: newPhoneCall.location,
          scheduledStart: newPhoneCall.scheduledStart,
          scheduledEnd: newPhoneCall.scheduledEnd,
        };
      } else {
        data = { id: newPhoneCall.ID };
      }
      this.uiService.setUIServiceData({
        view: 'new-activity', data,
      }); // this is an observable stream
      this.activityService.selectedActivity = newPhoneCall;
      let fieldsUpdated:boolean = false;
      if (this.navService.getCurrentMasterPageName() === PageName.AccountPageComponent) {
        newPhoneCall['accounts'].push(this.accountService.selected)
        this.activityService.selectedActivity['accounts'] = [];
        this.activityService.selectedActivity['accounts'].push(this.accountService.selected);
        fieldsUpdated = true;
      }
      if(fieldsUpdated){
        let phoneCallPayLoad = this.phoneCallDataService.setPhoneCallPayload(this.activityService.selectedActivity as PhoneActivity)
        if (!this.device.isOffline && !this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID)) {
          await this.phoneCallDataService.updatePhoneCall(this.activityService.selectedActivity as PhoneActivity, phoneCallPayLoad); //CWD-2414 Update the activity subject if auto subject feature action is enabled
          // this.activityService.isActivityUpdate = true;
        }
        else {
          await this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity);
        }
      }
      this.uiService.showNewActivity = false;
      this.activityService.activityDetailsLoaded = true;
      this.uiService.agendaRefreshRequired = true;
      this.activityService.signalActivityConflictCheck$.next(this.activityService.selectedActivity);
      this.phoneCallMeetingStructureService.createEmbeddedInteractions(newPhoneCall);
      this.phoneCallDataService.saveMeeting(newPhoneCall);
      this.navService.pushChildNavPageWithPageTracking(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.ContactPageComponent, { from: 'ContactPageComponent', isNewlyCreatedActivity: true });
      if(!this.device.isOffline) this.uiService.dismissLoader();
    }
  }

  private _preparePhoneCallPayload(): InitiatePhoneCallPayload {
    let start, end;
    start = format(Utility.getRoundedDownTime(new Date()));
    end = format(addMinutes(start, 30));
    const phoneCallStartTime: number = new Date(start).getTime();
    const phoneCallEndTime: number = new Date(end).getTime();
    let subject = this.translate.instant('NEW_ACTIVITY_PHONECALL');

    const payload: InitiatePhoneCallPayload = new InitiatePhoneCallPayload(
      phoneCallStartTime,
      phoneCallEndTime,
      subject,
      OFFLINE_PHONE_CALL_ID_PREFIX + new Date().getTime(),
      0,
      undefined
    );
    return payload;
  }

  private async _handleCreateMeeting(){
    if(!this.device.isOffline) this.uiService.displayLoader();
    const payload: InitiateMeetingPayload = this._prepareMeetingPayload();
    try {
      const newMeeting = await this.meetingService.createNewMeeting(payload, true);
      if (newMeeting && !this.meetingService.isErrorStartDateCreateMeeting) {
        let data;
        if (!newMeeting.createdOffline) {
          // Online created meeting
          data = { id: newMeeting.ID, ...payload }
        } else {
          // Offline created meeting
          data = {
            id: newMeeting.ID,
            subject: newMeeting.subject,
            location: newMeeting.location,
            scheduledStart: newMeeting.scheduledStart,
            scheduledEnd: newMeeting.scheduledEnd,
          };
        }

        this.uiService.setUIServiceData({
          view: 'new-activity', data,
        }); // this is an observable stream

        if (this.navService.getCurrentMasterPageName() == PageName.AccountPageComponent && this.accountService.selected && this.accountService.selected.id) {
          this.activityService.selectedActivity = newMeeting;
          this.activityService.selectedActivity['accounts'] = [];
          let account = this.accountService.getAccountById(this.accountService.selected.id);
          this.accountService.moveAccountToSelected(account);
          if (this.activityService.selectedActivity instanceof AppointmentActivity) {
            if ( this.authenticationService.user.buConfigs && this.authenticationService.user.buConfigs['indskr_populateaccountaddress']) {
              const selectedAddress = await this.accountService.getPrimaryAddressOfAccount(this.activityService.selectedActivity['accounts'][0].id);
              await this.accountService.addLocationToMeeting(this.activityService.selectedActivity, selectedAddress);
            }
              await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
            if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
              await this.meetingService.addAccountsToMeeting(this.activityService.selectedActivity);
            }
            let updateContactsForMeetingFlag: boolean = false;
            let contactsToUpdate: Contact[] = [];
            //Check for add
            let affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([account]);
            if (affiliatedContacts.length === 1) {
              // Add the only affiliated contact
              updateContactsForMeetingFlag = true;
              contactsToUpdate = affiliatedContacts;
            }
            if (updateContactsForMeetingFlag) {
              await this._updateContactsOfSelectedMeeting(contactsToUpdate);
            }
          }
        }
        this.uiService.agendaRefreshRequired = true;
        this.activityService.signalActivityConflictCheck$.next(this.activityService.selectedActivity);
        this.meetingStructureService.createEmbeddedInteractions(newMeeting);
        this.activityService.activityDetailsLoaded = true;
        // this.meetingStructureService.addFilteredContentToMeeting();
        this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ContactPageComponent, { from: 'AccountTool', isNewlyCreatedActivity: true });
        if(!this.device.isOffline) this.uiService.dismissLoader();
      }
      else {
        console.error('createNewMeeting: No response!');
        if (!this.meetingService.isErrorStartDateCreateMeeting) {
          // Failed to create a meeting on non app environment. Alert user.
          this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_ERROR_CREATING_NEW_MEETING'), 'New Activity', 'top', ToastStyle.DANGER);
        }
        if (!this.meetingService.isErrorStartDateCreateMeeting) {
          this.meetingStructureService.createEmbeddedInteractions(null);
        }
        this.meetingService.isErrorStartDateCreateMeeting = false;
      }
    } catch (error) {
      console.log('Error occured while creating new meeting activity' + error);
    }

  }

  private _prepareMeetingPayload(): InitiateMeetingPayload {
    let start, end;
    start = format(Utility.getRoundedDownTime(new Date()));
    end = format(addMinutes(start, 30));
    const meetingStartTime: number = new Date(start).getTime();
    const meetingEndTime: number = new Date(end).getTime();
    const isAcinoBaseUser = this.authenticationService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT);
    let subject = this.translate.instant('MEETING');
    if (isAcinoBaseUser) {
      subject = this.translate.instant('VISIT');
    }

    const payload: InitiateMeetingPayload = new InitiateMeetingPayload(
      subject,
      '',
      meetingStartTime,
      meetingEndTime,
      '',
      OFFLINE_ID_PREFIX + new Date().getTime(),
      undefined
    );
    return payload;
  }

  private async _updateContactsOfSelectedMeeting(contacts: Contact[]) {
    if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Appointment && !(this.activityService.selectedActivity as AppointmentActivity).isCompleted) {
      this.activityService.selectedActivity['contacts'] = [];
      contacts.forEach(contact => {
        this.contactService.moveContactToSelected(contact);
      });
      const isAutoSubjectEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT);
      const isAcinoBaseUser = this.authenticationService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT);
      let activity = (this.activityService.selectedActivity as AppointmentActivity);
      let isSubjectUpdated = false;
      if (isAutoSubjectEnabled || isAcinoBaseUser) {
        let subject: string = this.translate.instant('MEETING');
        let subPrefix = ` - ${subject} - ${this.authenticationService.user.displayName}`;
        const selectedContacts = Array.isArray(activity.contacts)
          ? activity.contacts
          : [];
        if (isAcinoBaseUser) {
          subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}${this.translate.instant('VISIT')}`;
        }
        switch (selectedContacts.length) {
          case 0:
            break;
          case 1:
            subject = `${selectedContacts[0].fullName}${subPrefix}`;
            if (isAcinoBaseUser) {
              subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}${this.translate.instant('VISIT')} - ${selectedContacts[0].fullName}`;
            }
            break;
          default:
            if (selectedContacts.length > 1) {
              selectedContacts.sort((contactA, contactB): number => {
                let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
                let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
                if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
                if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;

                return 0;
              });
              subject = `${selectedContacts[0].fullName} + ${selectedContacts.length - 1}${subPrefix}`;
              if (isAcinoBaseUser) {
                subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}${this.translate.instant('VISIT')} - ${selectedContacts[0].fullName}`;
              }
            }
        }
        if (this.activityService.selectedActivity.subject !== subject) {
          this.activityService.selectedActivity.subject = subject;
          isSubjectUpdated = true;
        }

      }

      if (this.activityService.selectedActivity.location !== "LiveMeet") {
        if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) &&  (this.authenticationService.user.buConfigs && !this.authenticationService.user.buConfigs['indskr_populateaccountaddress'])) {
          await this.contactService.setLocationField(contacts);
        }
      }

      if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
        await this.meetingService.addContactsToMeeting(this.activityService.selectedActivity, true);
        this.events.publish('contentmatching:refresh');
        if (isSubjectUpdated) {
          const payload = new UpdateMeetingPayload(
            this.activityService.selectedActivity.subject,
            this.activityService.selectedActivity.location,
            this.activityService.selectedActivity.scheduledStart,
            this.activityService.selectedActivity.scheduledEnd
          );
          try {
            await this.meetingService.updateMeeting((this.activityService.selectedActivity as AppointmentActivity), payload);
          } catch (error) {
            console.error('AccountListComponent: _updateContactsOfSelectedMeeting: ', error);
            await this.activityService.upsertMeetingsOfflineData((this.activityService.selectedActivity as AppointmentActivity), false, true);
          }
        }
      } else {
        await this.activityService.upsertMeetingsOfflineData((this.activityService.selectedActivity as AppointmentActivity));
      }
    }
  }
  async getTargetContactIds() {
    if(this.device.isOffline)
      return;

    console.warn('getTargetContactIds');

    await this.uiService.displayLoader();
    let currentCustomerListPeriodId = this.territoryService.currentListPeriod ? this.territoryService.currentListPeriod.indskr_customerlistperiodid : '';
    let contactsBasedOnPositionId = await this.contactDataService.getTargetContactsBasedOnCurrentCustomerListPeriod(this.authenticationService.user.positionID, currentCustomerListPeriodId);

    if (!_.isEmpty(contactsBasedOnPositionId)) {
      contactsBasedOnPositionId = contactsBasedOnPositionId.map((contact)=> {
        contact['ID'] = contact['contactid'];
        return contact;
      });

      this.dynamicFormService.targetContactIds = contactsBasedOnPositionId;
    }

    await this.uiService.dismissLoader();
  }
}
