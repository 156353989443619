import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { format, getDate, getDay, isBefore, isValid } from 'date-fns';
import { AssignmentHistory, FollowupActionStatusReason, FollowupActionType, FollowUpActivity, FOLLOW_UP_PRIORITY } from '../../../../classes/activity/follow-up-action.activity.class';
import { IONote } from '../../../../classes/io/io-note.class';
import { FollowUpActivityDataService } from '../../../../data-services/follow-up-activity/follow-up-activity.data.service';
import { RepServices } from '../../../../data-services/rep/rep.services';
import { ActivityService } from '../../../../services/activity/activity.service';
import { AuthenticationService } from '../../../../services/authentication.service';
import { ContactOfflineService } from '../../../../services/contact/contact.service';
import { DeviceService } from '../../../../services/device/device.service';
import { EventsService } from '../../../../services/events/events.service';
import { FooterService, FooterViews } from '../../../../services/footer/footer.service';
import { TrackingEventNames, TrackService } from '../../../../services/logging/tracking.service';
import { ChildNavNames, NavigationService, PageName } from '../../../../services/navigation/navigation.service';
import { NotificationService, ToastStyle } from '../../../../services/notification/notification.service';
import { ComponentViewMode, UIService } from '../../../../services/ui/ui.service';
import { ChildUsersPageComponent, UserListObject } from '../../../child-users-page/child-users-page';
import { ContactPageComponent } from '../../../contact/contact-page/contact-page';
import { PopoverComponent } from '../../../popover/popover';
import { NothingSelectedView } from '../../../shared/nothing-selected-view/nothing-selected-view';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Contact } from '../../../../classes/contact/contact.class';
import { ScientificActivityPlan, ScientificPlansUsers } from '../../../../classes/scientific-activity/scientific-activity.class';
import { ActivityDataService } from '../../../../data-services/activity/activity.service';
import { FormFieldType, IndFormFieldViewDataModel } from '../../../../models/indFormFieldDataModel';
import { IndSectionHeaderViewDataModel } from '../../../../models/indSectionHeaderDataModel';
import { AlertService } from '../../../../services/alert/alert.service';
import { DateTimeFormatsService } from '../../../../services/date-time-formats/date-time-formats.service';
import { GlobalUtilityService } from '../../../../services/global-utility.service';
import { ScientificActivityService } from '../../../../services/scientific-activity/scientific-activity.service';
import { IndPageTitleViewDataModel } from '../../../../models/indPageTitleDataModel';
import { FollowupService } from '../../../../services/followup/followup.service';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { BrandOfflineService } from '../../../../services/brand/brand.service';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '@omni/models/mainToolTemplateDetail.model';
import { MainToolTemplateComponent } from '@omni/components/shared/main-tool-template/main-tool-template';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { MAXIMUM_NOTE_ATTACHMENT_SIZE, NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX, toBase64 } from '@omni/utility/util';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';

@Component({
  selector: 'scientific-plan-task',
  templateUrl: 'scientific-plan-task.html',
  styleUrls: ['scientific-plan-task.scss']
})

export class ScientificPlanFollowUpTask implements OnInit {

  private isLayoverPushView = false;
  private layoverViewPushedFrom: string;
  public isSaveNotesEnabled = false;

  public currentFollowUpActivity: FollowUpActivity;
  public currentFollowUpActivityDueDateString: string;

  public todayDate: string = moment(new Date()).format();
  public isReadOnlyView = false;
  private updateInProgress = false;
  public tempNoteText: string;
  public notesPlaceholder = '';

  private sapPopover;

  private currentUserID: string;
  public isScrapEnabled = false;

  @ViewChild('followUpDueDate', { static: false }) followUpDatePicker;
  @ViewChild('attachInput') attachInput: ElementRef;

  donotAllowToSelectAssignedTo: boolean;
  @Input() from: any;
  @Input() activity: any;
  public taskDetailsHeaderModel: IndSectionHeaderViewDataModel;
  public notesHeaderModel: IndSectionHeaderViewDataModel;
  public pageTitle: IndPageTitleViewDataModel;
  public pageTitleControls = [];
 private followupActionTypes: FollowupActionType[]
 private popover;

  public attachmentTitle: string = '';
  public isNotesAttachmentEnabled: boolean = false;
  public isAttachmentAdded: boolean = false;
  private attachmentFile: any;
  private base64str;


  public timeLineHeaderModel: IndSectionHeaderViewDataModel;
  public timelineData = [];
  ngDestroy$: any = new Subject<boolean>();
  public backgroundUploadInProgress: boolean = false;

  private _isClickedCustomer: boolean = false;
  private _isClickedScientificPlan: boolean = false;
  private followupActionOpenStatusReasons: FollowupActionStatusReason[] = [];
  private followupSubStatusString:string = '';

  isCancelEnbaled = false;
  constructor(
    private uiService: UIService,
    public device: DeviceService,
    public repService: RepServices,
    public readonly UIService: UIService,
    public activityService: ActivityService,
    private readonly navService: NavigationService,
    private readonly contactService: ContactOfflineService,
    private readonly followUpActivityDataService: FollowUpActivityDataService,
    private readonly popoverCtrl: PopoverController,
    private readonly notificationService: NotificationService,
    public readonly footerService: FooterService,
    private readonly authService: AuthenticationService,
    private readonly events: EventsService,
    private readonly alertService: AlertService,
    public trackingService: TrackService,
    private readonly sapService: ScientificActivityService,
    public translate: TranslateService,
    public utilityService: GlobalUtilityService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private readonly activityDataService: ActivityDataService,
    private cdRef: ChangeDetectorRef,
    private followupService: FollowupService,
    private brandService: BrandOfflineService,
    private datePipe: DatePipe
  ) {
    this.events.subscribe("updateFollowupActivityHeader",()=>{
      this.updateHeader();
      this.initTaskNotesheader();
      this._updateMandatoryField();
    })

    this.events.subscribe(
      'followupTask:updateFollowupActivity',
      () => {
        this.cdRef.detectChanges();
        this.timelineData = this.getGroupedActivities();
        this.initPageHeaderControls()
        this.footerService.initButtons('follow-up');
        // this.footerService.updateButtons(['scrapfollowup', 'markfollowupcomplete'], false);

    });
    let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
    this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
  }

  ngOnInit() {
    if (this.from) {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
    }
    if (this.activity) {
      this.currentFollowUpActivity = this.activity;
      this.isNotesAttachmentEnabled = this.authService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ATTACHMENT_UPLOAD);


      if (this.followupService.followupActionTypes && !_.isEmpty(this.followupService.followupActionTypes)) {
        this.followupActionTypes = this.followupService.followupActionTypes.filter((followup) => {
          return `${followup.planTypeID}` === this.currentFollowUpActivity.planType;
        });
      }
      if(this.followupService.followupActionStatusReasons && this.followupService.followupActionStatusReasons.length > 0){
        this.setSubStatusString();
        this.followupActionOpenStatusReasons = this.followupService.followupActionStatusReasons.filter((reason) => {
          return reason.stateCode == 0;
        });
      }

      if (this.UIService.activitiesPageTab === 'day' && this.UIService.selectedCalendarDateInWeekView) {
        this.currentFollowUpActivity.scheduledStart = this.UIService.selectedCalendarDateInWeekView;
        this.currentFollowUpActivity.scheduledEnd = this.UIService.selectedCalendarDateInWeekView;
        this.currentFollowUpActivity.allDayEvent = true;
        this.events.publish('activityUpdated', this.currentFollowUpActivity);
      }
      if (this.UIService.activitiesPageTab === 'agenda' && this.UIService.selectedCalendarDateInMonthView) {
        this.currentFollowUpActivity.scheduledStart = this.UIService.selectedCalendarDateInMonthView;
        this.currentFollowUpActivity.scheduledEnd = this.UIService.selectedCalendarDateInMonthView;
        this.currentFollowUpActivity.allDayEvent = true;
        this.events.publish('activityUpdated', this.currentFollowUpActivity);
      }

      // this.isReadOnlyView = (this.currentFollowUpActivity.state !== 0) || (this.currentFollowUpActivity.ownerId !== this.authService.user.systemUserID) || this.activityService.teamViewActive;
      // if (isValid(this.currentFollowUpActivity.scheduledEnd)) {
      //   this.currentFollowUpActivityDueDateString = this.getFormattedAndLocalisedDate(this.currentFollowUpActivity.scheduledEnd);
      // } else if (isValid(this.currentFollowUpActivity.scheduledStart)) {
      //   this.currentFollowUpActivityDueDateString = this.getFormattedAndLocalisedDate((new Date(this.currentFollowUpActivity.scheduledStart).setHours(23, 59, 59, 59)));
      // } else {
      //   const currentDueDate = new Date().setHours(0, 0, 0, 0);
      //   this.currentFollowUpActivity.scheduledEnd = new Date(currentDueDate);
      //   this.currentFollowUpActivityDueDateString = (this.isReadOnlyView) ? this.translate.instant('NONE') : this.getFormattedAndLocalisedDate(this.currentFollowUpActivity.scheduledEnd);
      // }
      this.intializeAndFormatDueDate()
      this.timelineData = this.getGroupedActivities();
    }
    this.currentUserID = this.authService.user.systemUserID;
    this.footerService.initButtons(FooterViews.FollowUpDetails);
    // this.initPageAndSectionsHeaders();
    this.events.unsubscribe("followupTask:assignmentHistoryUpdated");
    this.events.observe("followupTask:assignmentHistoryUpdated").pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => {
        this.timelineData = this.getGroupedActivities();
      });

    this.translate.onLangChange.subscribe(data => {
      this.initPageAndSectionsHeaders();
      this.intializeAndFormatDueDate();
      let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
      this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
    });
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      if (this.currentFollowUpActivity) {
        this.backgroundUploadInProgress = inProgress && this.currentFollowUpActivity.pendingPushToDynamics;
        this.initPageHeaderControls();
        this.initTaskNotesheader();
        this.updateFooterButtonStatus();
        this.cdRef.detectChanges();
      }
    });
    this.events.observe("followup:cancelfollowup").pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((id) => {
        if (this.currentFollowUpActivity.ID == id) {
          this.cancelFollowUp();
        }
      })
  }

  private intializeAndFormatDueDate() {
    this.isReadOnlyView = (this.currentFollowUpActivity.state !== 0) ||
      (this.currentFollowUpActivity.ownerId !== this.authService.user.systemUserID && !this.currentFollowUpActivity.assignedTo?.some(au => au.userId === this.authService.user.systemUserID))
      || this.activityService.teamViewActive || this.from == PageName.AccountPlanActivities;
    if (isValid(this.currentFollowUpActivity.scheduledEnd)) {
      this.currentFollowUpActivityDueDateString = this.getFormattedAndLocalisedDate(this.currentFollowUpActivity.scheduledEnd);
    } else if (isValid(this.currentFollowUpActivity.scheduledStart)) {
      this.currentFollowUpActivityDueDateString = this.getFormattedAndLocalisedDate((new Date(this.currentFollowUpActivity.scheduledStart.setHours(23, 59, 59, 59))));
    } else {
      const currentDueDate = new Date().setHours(0, 0, 0, 0);
      this.currentFollowUpActivity.scheduledEnd = new Date(currentDueDate);
      this.currentFollowUpActivityDueDateString = (this.isReadOnlyView) ? this.translate.instant('NONE') : this.getFormattedAndLocalisedDate(this.currentFollowUpActivity.scheduledEnd);
    }
  }

  ngAfterViewInit() {
    this.isScrapEnabled = (this.currentFollowUpActivity.ownerId === this.authService.user.systemUserID) && this.currentFollowUpActivity.state === 0 && !this.activityService.teamViewActive;
    this.isCancelEnbaled = ((this.currentFollowUpActivity.ownerId === this.authService.user.systemUserID || this.currentFollowUpActivity.assignedTo.some(user => user.userId === this.authService.user.systemUserID))) && this.currentFollowUpActivity.state === 0  
    this.initPageAndSectionsHeaders();
    this.updateFooterButtonStatus();
  }

  private initPageAndSectionsHeaders() {
    this.initPageHeaderControls();
    this.initTaskDetailPageTitle();
    this.initSectionHeaders();
  }



  ngDoCheck() {
    if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
      if (this.UIService.activeView !== 'ActivitiesPageRightPaneNav') return;
    } else if (this.navService.getActiveChildNavName() === ChildNavNames.ContactPageNavigation) {
      if (this.UIService.activeView !== 'ContactPageRightPaneNav') return;

    }

    if (!this.isReadOnlyView && (this.currentFollowUpActivity.state === 1)) this.isReadOnlyView = true;

    if (this.navService.getActiveChildNavViewPageName() === PageName.ScientificPlanFollowUpTask && !this.footerService.getActiveButtonsList().some(id => id === 'markfollowupcomplete' || id === 'scrapfollowup')) {
      this.footerService.initButtons(FooterViews.FollowUpDetails);
    }
    this.isScrapEnabled = (this.currentFollowUpActivity.ownerId === this.authService.user.systemUserID) && this.currentFollowUpActivity.state === 0 && !this.activityService.teamViewActive;

    /**
     *  this looks good might need modification
     *  enable and disable footer buttons
     */
    // this.updateFooterButtonStatus();
  }

  public setSubStatusString () {
    this.followupSubStatusString = '';
    if(this.followupService.followupActionStatusReasons.length > 0){
      let idx = this.followupService.followupActionStatusReasons.findIndex(x => x.statusCode === this.currentFollowUpActivity.status);
      if(idx >= 0){
        this.followupSubStatusString = this.followupService.followupActionStatusReasons[idx].name;
      }
    }
  }

  private updateFooterButtonStatus() {
    this.footerService.disableButton(['scrapfollowup','markfollowupcancel','markfollowupcomplete']);
    if (this.isScrapEnabled && !this.backgroundUploadInProgress) {
      this.footerService.enableButtons(['scrapfollowup']);
    }
    if (this.isCancelEnbaled && !this.backgroundUploadInProgress) {
      this.footerService.enableButtons(['markfollowupcancel']);
    }
    if (!this.backgroundUploadInProgress && ((this.currentFollowUpActivity.ownerId === this.authService.user.systemUserID) || (this.currentFollowUpActivity.assignedTo.some(u => u.userId === this.authService.user.systemUserID)))) {
      if (!!this.currentFollowUpActivity.subject && !!this.currentFollowUpActivity.contactId && !!this.currentFollowUpActivity.scientificPlanId && !this.activityService.teamViewActive) {
        if (this.currentFollowUpActivity.state === 0)
          this.footerService.enableButtons(['markfollowupcomplete']);
      }
    }
  }

  private initTaskDetailPageTitle(): void {
    this.pageTitle = {
      id: 'followup-details',
      title: this.getSubjectFormField().inputText,
      controls: this.pageTitleControls
    };
  }

  private initPageHeaderControls() {

    this.pageTitleControls =[];

    if (this.isBackButtonEnabled) {
      this.pageTitleControls.push({
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      });
    }
    let _this = this;
    this.pageTitleControls.push({
      id: 'scrap',
      imgSrc: 'assets/imgs/header_cancel.svg',
      name: this.translate.instant('SCRAP'),
      isDisabled: this.backgroundUploadInProgress || !this.isScrapEnabled,
      align: 'right'
    },{
      id: 'cancel',
      imgSrc: 'assets/imgs/Lost.svg',
      name: this.translate.instant('CANCEL'),
      isDisabled: this.backgroundUploadInProgress || !this.isCancelEnbaled,
      align: 'right'
    });

    this.pageTitleControls.push({
        id: 'markComplete',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('COMPLETE'),
        get isDisabled() {return this.backgroundUploadInProgress || !_this.isMarkCompleteEnabled},
        align: 'right'
      });
    this.initTaskDetailPageTitle();
  }

  private initSectionHeaders() {
    this.initTaskDetailsHeader();
    this.initTaskNotesheader();
    this.initTimelineHeader();
  }

  updateHeader() {
    this.pageTitleControls = [];
    this.initPageAndSectionsHeaders();
  }


  private initTaskDetailsHeader() {
    this.taskDetailsHeaderModel = {
      id: 'task-detail-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: []
    };
  }

  private initTaskNotesheader() {
      let controls = [
          {
            id: 'attach_note',
            text: this.translate.instant('ATTACH'),
            isDisabled: this.backgroundUploadInProgress || this.currentFollowUpActivity.state !== 0,
            isVisible: this.isNotesAttachmentEnabled,
          }
        ]

      controls.push({
        id: 'task-notes-save',
        text: this.translate.instant('SAVE'),
        isDisabled: this.backgroundUploadInProgress || !this.isSaveNotesEnabled || this.currentFollowUpActivity.state !== 0,
        isVisible: true,
      })

      this.notesHeaderModel = {
        id: 'task-notes-header',
        title:  this.translate.instant('NOTES'),
        controls: controls
      };
    }

  private initTimelineHeader() {
    this.timeLineHeaderModel = {
      id: 'timeline-header',
      title: this.translate.instant('FOLLOW_UP_ACTION_TIMELINE'),
      controls: []
    };
  }


  // private updateMarkcompleteState = () => {
  //   this.pageTitle.controls.forEach(ctr => {
  //     if (ctr.id === "markComplete") {
  //       ctr.isDisabled = !this.isMarkCompleteEnabled;
  //     }
  //   });
  // }
  public get isBackButtonEnabled(): boolean {
    if (this.isLayoverPushView) {
      if (this.layoverViewPushedFrom === 'ScientificActivityPage' || this.layoverViewPushedFrom === 'ContactTools' || this.layoverViewPushedFrom === 'CalendarPage') {
        return true;
      } else if (this.layoverViewPushedFrom === 'AgendaView' && window.innerWidth < 620) {
        return true;
      } else {
        return false;
      }
    } else {
      //Handle if used
    }
  }

  public notesChanged(ev): void {
    if (this.backgroundUploadInProgress) return;
    if (ev && ev.target) {
      this.tempNoteText = ev.target.value;
      this.isSaveNotesEnabled = true;

    } else {
      this.tempNoteText = '';
      this.isSaveNotesEnabled = true;

    }
    this.initTaskNotesheader();
  }

  // private updateNotesSaveButton = (status: boolean) => {
  //   this.notesHeaderModel.controls.forEach(ctr => {
  //     if (ctr.id === "task-notes-save") {
  //       ctr.isDisabled = status;
  //     }
  //   })
  // }
  public get isMarkCompleteEnabled(): boolean {

    if ((this.currentFollowUpActivity.ownerId === this.authService.user.systemUserID) || (this.currentFollowUpActivity.assignedTo.some(u => u.userId === this.authService.user.systemUserID))) {
      if (!!this.currentFollowUpActivity.subject && !!this.currentFollowUpActivity.contactId && !!this.currentFollowUpActivity.scientificPlanId) {
        if (this.currentFollowUpActivity.state === 0 && !this.activityService.teamViewActive) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }

  }

  public isNoteControlsEnabled(note: IONote): boolean {
    return (note.ownerId == this.authService.user.systemUserID && this.currentFollowUpActivity.state === 0);
  }

  public getAssignedToString(): string {
    let str = (this.isReadOnlyView) ? this.translate.instant('NO_ASSIGNED_USER') : this.translate.instant('AL_SELECT_USER');
    if (this.currentFollowUpActivity.assignedTo.length > 0) {
      if (this.currentFollowUpActivity.assignedTo.length === 1) {
        str = this.currentFollowUpActivity.assignedTo[0].userFullName;
      } else {
        str = this.currentFollowUpActivity.assignedTo[0].userFullName;
        str += ' +' + (this.currentFollowUpActivity.assignedTo.length - 1);
      }
    }
    return str;
  }

  public async openUserSelect() {
    if (!this.donotAllowToSelectAssignedTo && this.currentFollowUpActivity.scientificPlanNameString === '' || this.currentFollowUpActivity.scientificPlanNameString === undefined) {
      this.notificationService.notify(this.translate.instant('SELECT_SCIENTIFIC_PLAN'), 'Scientific Plan Task', 'top', ToastStyle.DANGER, 2000, false);
      return;
    } else {
      if ((this.currentFollowUpActivity.state === 1 && this.currentFollowUpActivity.status === 5)) {
        this.navService.pushWithPageTracking(ChildUsersPageComponent, PageName.ChildUsersPageComponent, { from: 'ScientificActivityPage', activity: this.currentFollowUpActivity }, PageName.ChildUsersPageComponent);
      } else {
        const users = this.getUserListBySP();
        this.navService.pushWithPageTracking(ChildUsersPageComponent, PageName.ChildUsersPageComponent, { from: 'ScientificActivityPage', activity: this.currentFollowUpActivity, users: users }, PageName.ChildUsersPageComponent);
      }
    }
  }

  getUserListBySP() {
    let users: ScientificPlansUsers[] = [];
    if (this.currentFollowUpActivity.scientificPlanId.length > 0) {
      const sap = this.sapService.sapActivities.find(sap => sap.ID === this.currentFollowUpActivity.scientificPlanId);
      if (sap) {

        users = sap.users;
      }

    } else {
      this.sapService.sapActivities.forEach(sap => {
        sap.users.forEach(user => {
          if (!users.some(x => x.ownerid === user.ownerid)) {
            users.push(user);
          }
        });
      })
    }
    return users.sort((a, b) => a.fullname.localeCompare(b.fullname));
  }
  private async openSubjectPopover(myEvent) {
    if (!this.isReadOnlyView) {
      if (myEvent.target.value === null || myEvent.target.value === '') {
        myEvent.value = 'Follow-up Action'
      }
      if (myEvent && myEvent.target.value && myEvent.target.value !== this.currentFollowUpActivity.subject) {
        this.currentFollowUpActivity.subject = myEvent.target.value;
        this.currentFollowUpActivity.pendingPushToDynamics = true;
        await this.followUpActivityDataService.updateFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true }, [this.currentFollowUpActivity], new Date().getTime()).then(success => {
          //Handle successfull response
          if (!this.uiService.toolsActivityActive){
            this.events.publish("refreshAgenda");
          } else this.uiService.agendaRefreshRequired = true;

          this.events.publish('activityUpdated', this.currentFollowUpActivity);

        }).catch(async error => {
          // Handle error occured during follow up activity updation
          if (error && error['errorCode'] && error['errorCode'] === 'ALREADYCOMPLETEDACTIVITY') {
            await this.activityDataService.updateActivityDetails(this.currentFollowUpActivity);
          }
        });
      }
    }
  }

  public openCustomerSelect(): void {
    if (this.isReadOnlyView) return;
    this.UIService.prevView = this.UIService.activeView;
    this.UIService.activeView = '';
    this.contactService.contactPageMode = ComponentViewMode.SELECTION;
    this.contactService.accessedContactListFrom = PageName.ScientificPlanFollowUpTask;

    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, { type: 'PushedContactSelectionView', filteredSPContacts: this.filterContacts() }, PageName.ScientificPlanFollowUpTask, { animate: false }, null);
    this._isClickedCustomer = true;
  }

  filterContacts() {
    let filteredContact = [];
    if (this.currentFollowUpActivity.assignedTo.length > 0 && this.currentFollowUpActivity.scientificPlanId.length === 0) {

      this.sapService.sapActivities.filter(x => {
        return x.users.some(u => this.currentFollowUpActivity.assignedTo.some(a => a.userId === u.ownerid));
      }).forEach(sp => { filteredContact = _.unionBy(filteredContact, sp.contacts, 'ID') });
      console.log(filteredContact);
    } else if (this.currentFollowUpActivity.scientificPlanId.length > 0) {
      this.sapService.sapActivities.filter(sp => sp.ID === this.currentFollowUpActivity.scientificPlanId).forEach(sp => { filteredContact = _.unionBy(filteredContact, sp.contacts, 'ID') });
    } else {
      this.sapService.sapActivities.forEach(sp => { filteredContact = _.unionBy(filteredContact, sp.contacts, 'ID') });
    }

    filteredContact.map(con => {
      if (!(con as Contact)) {
        con['fullName'] = con.fullname
      }
    });
    return filteredContact;
  }

  public async openScientificPlanSelect(myEvent) {
    if (this.isReadOnlyView) return;

    const scientifcPlans = this.filterSapbyContact();
    if (Array.isArray(scientifcPlans)) {
      const sapToDisplay = scientifcPlans.map(sap => {
        return {
          title: sap.scientificActivityPlanName,
          id: sap.ID,
        }
      });
      this.sapPopover = await this.popoverCtrl.create({
        component: PopoverComponent,
        componentProps: { selectListPopupData: sapToDisplay, field: 'select-list-popup' },
        cssClass: 'account-plan-select',
        event: myEvent
      });
      this.sapPopover.onDidDismiss().then(async (data) => {
        if (this.backgroundUploadInProgress) return;
        data = data.data;
        if (data && data.selectedItem && data.selectedItem.id !== this.currentFollowUpActivity.scientificPlanId) {
          this.currentFollowUpActivity.scientificPlanId = data.selectedItem.id;
          this.currentFollowUpActivity.scientificPlanNameString = data.selectedItem.title;
          this.currentFollowUpActivity.pendingPushToDynamics = true;
          this.initTaskDetailPageTitle();
          await this._displayDismissLoader(true);
          await this.followUpActivityDataService.updateFollowUpActivity({
            onDynamics: !this.device.isOffline,
            onLocalCopy: true,
            onLocalDatabase: true
          }, [this.currentFollowUpActivity], new Date().getTime()).then(succ => {
            // Successfull action
            this.updateFooterButtonStatus();
          }).catch(err => {
            // To do error handling
          });
          await this._displayDismissLoader(false);
        }
        if (!data || !data.selectedItem.isSelected) {
          this.donotAllowToSelectAssignedTo = false;
        } else {
          this.donotAllowToSelectAssignedTo = true;
        }
        this.sapPopover = undefined;
        this._isClickedScientificPlan = true;
      });
      this.sapPopover.present();
    }
  }

  public async updateNote(ev) {
    if (ev && ev.action) {
      const idx = this.currentFollowUpActivity.followUpNotes.findIndex(note => note.noteId === ev.noteId);
      let note = this.currentFollowUpActivity.followUpNotes[idx];
      if (ev.action === 'DELETE' && idx >= 0) {
        note.isDeleted = true;
        if (note.hasDocument)
          note = this._resetDocumentDetailsOfNote(note);
      } else if (ev.action === 'SAVE' && idx >= 0) {
        note.noteText = ev.updatedText;
        if (ev.attachmentFileUpdated) {
          note = this._updateAttachmentinNotes(note, ev);
        } else if (ev.attachmentFileUpdated) {
          note = this._updateAttachmentinNotes(note, ev)
        } else if (ev.isAttachmentRemoved) {
          note.hasDocument = false;
          note = this._resetDocumentDetailsOfNote(note);
        }
      }

      note.pendingPushForDynamics = true;
      this.currentFollowUpActivity.followUpNotes[idx] = note;
      this.currentFollowUpActivity.pendingPushToDynamics = true;
      await this._displayDismissLoader(true);
      await this.followUpActivityDataService.updateFollowUpActivity({
        onDynamics: !this.device.isOffline,
        onLocalCopy: true,
        onLocalDatabase: true,
        operationDetail: {
          code: (this.currentFollowUpActivity.status !== 5) ? 'FUAANT101' : '',
          message: this.translate.instant('FOLLOW_UP_NOTIFY_ADD_NEW_NOTES')
        }
      }, [this.currentFollowUpActivity], new Date().getTime()).then(succ => {
        // Successfull action
      }).catch(err => {
        // To do error handling
      });
      await this._displayDismissLoader(false);
      this.cdRef.detectChanges();
      this.cdRef.markForCheck();

    }
  }

  private _updateAttachmentinNotes(note: IONote, ev: any) {
    note.hasDocument = true;
    note.documentSize = ev.documentSize;
    note.documentName = ev.documentName;
    note.documentMimeType = ev.documentMimeType;
    note.documentDataURL = ev.attachmentFileDataUrl;
    return note;
  }

  private _resetDocumentDetailsOfNote(note: IONote) {
    note.documentSize = 0;
    note.documentName = '';
    note.documentMimeType = '';
    note.documentDataURL = '';
    note.hasDocument = false;
    return note;
  }

  /**
   * TODO: HANDLE OTHER FLOWS
   * SAP TOOLS - DONE
   */
  public closePage(): void {
    if (this.isLayoverPushView) {
      if (this.layoverViewPushedFrom === 'ScientificActivityPage' && this.UIService.activeView != 'ActivitiesPageRightPaneNav') {
        // From Scientifc Tools
        this.UIService.activeView = 'ScientifcDetails';
        this.events.publish('refreshFollowup');
        this.footerService.initButtons(''); // TODO SAP: SET FOOTER CORRECTLY
        this.navService.popToRootChildNavPageWithPageTracking();
      } else if (this.layoverViewPushedFrom === 'ScientificActivityPage' && this.UIService.activeView === 'ActivitiesPageRightPaneNav'){
        this.events.publish('refreshFollowup');
        this.footerService.initButtons('');
        this.navService.popToRootChildNavPageWithPageTracking();
      } else if (this.layoverViewPushedFrom === 'ContactTools') {
        this.UIService.activeView = 'contactDetails';
        this.UIService.showNewActivity = false;
        this.footerService.initButtons(FooterViews.Contacts);
        this.navService.popChildNavPageWithPageTracking();

      } else if (this.layoverViewPushedFrom === 'AgendaView') {
        this.events.publish("initTimePickerLimitedMinTime");
        this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        this.UIService.activeView = '';
        this.UIService.showRightPane = false;
      }
    }
  }

  public markFollowUpComplete(): void {
    this.trackingService.tracking('ScientificPlanFollowupTaskComplete', TrackingEventNames.SCIENTIFICACTIVITYPLAN);
    if (this.isMarkCompleteEnabled && !this.updateInProgress) {

      this.alertService.showAlert({
        title: this.translate.instant('FOLLOW_UP_ALERT_TITLE_COMPLETE_FOLLOW_UP'),
        message: this.translate.instant('FOLLOW_UP_ALERT_MESSGE_COMPLETE_FOLLOW_UP')
      }, this.translate.instant('COMPLETE')
      ).then(async res => {
        if (res.role == 'ok') {
          if (this.backgroundUploadInProgress) return;
          this.updateInProgress = true;
          this.footerService.disableButton(['markfollowupcomplete','markfollowupcancel','scrapfollowup']);
          this.isScrapEnabled = false;
          this.isCancelEnbaled=false;
          this.initPageHeaderControls();
          if (!isValid(this.currentFollowUpActivity.scheduledEnd)) { //Added for OMNI-9095 as Due Date is Invalid.
            this.currentFollowUpActivity.scheduledEnd = new Date()
          }
          this.trackingService.tracking('ScientificPlanFollowupTaskComplete', TrackingEventNames.SCIENTIFICACTIVITYPLAN);

           this._displayDismissLoader(true);
          await this.followUpActivityDataService.markFollowUpActivityComplete({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true }, this.currentFollowUpActivity).then(succ => {
            //Successfully marked complete
            this.currentFollowUpActivity.state = 1;
            this.currentFollowUpActivity.status = 5;
            this.currentFollowUpActivity.statusString = 'Completed';
            this.initTaskNotesheader();
            this.updateInProgress = false;
             this._displayDismissLoader(false);
            // this.updateMarkcompleteState();
            this.events.publish('followupactioncompleted', this.currentFollowUpActivity);
          }).catch(err => {
            //To do error handling
            this.footerService.enableButtons(['markfollowupcomplete','markfollowupcancel','scrapfollowup']);
            // this.updateMarkcompleteState();
            this.updateInProgress = false;
            this._displayDismissLoader(false);
          })
        }
      });
    }
  }

  public async saveFollowUpNotes() {
    if (this.isSaveNotesEnabled && ((this.tempNoteText && !_.isEmpty(this.tempNoteText)) || !_.isEmpty(this.base64str))) {

            this.isSaveNotesEnabled = false;
            this.initTaskNotesheader();

            let action = {
                onDynamics: !this.device.isOffline,
                onLocalCopy: true,
                onLocalDatabase: true,
                operationDetail: {
                    code: (this.currentFollowUpActivity.status !== 5) ? 'FUAANT101' : (this.currentFollowUpActivity.pendingPushToDynamics) ? '' : 'FUAANT101',
                    message: this.translate.instant('FOLLOW_UP_NOTIFY_ADD_NEW_NOTES')
                }
            };
            let tempNote: IONote = new IONote({
                annotationid: 'offline_note_' + new Date().getTime(),
                activityid: this.currentFollowUpActivity.ID,
                createdon: new Date().getTime(),
                notetext: this.tempNoteText,
                ownerName: this.authService.user.displayName,
                ownerid: this.authService.user.systemUserID,
            });

          if (this.base64str) {
            tempNote.hasDocument = true;
            tempNote.documentDataURL = this.base64str;
            tempNote.documentName = this.attachmentFile.name;
            tempNote.documentSize = this.attachmentFile.size;
            tempNote.documentMimeType = this.attachmentFile.type;
          } else {
            tempNote.hasDocument = false;
          }
          tempNote.pendingPushForDynamics = true;
          this.currentFollowUpActivity.followUpNotes.push(tempNote);
          this.currentFollowUpActivity.pendingPushToDynamics = true;
          this.removeAttachment(null);

          this.tempNoteText = '';
          this._sortFollowupNotes();
          await this._displayDismissLoader(true);
          await this.followUpActivityDataService.updateFollowUpActivity(action, [this.currentFollowUpActivity], new Date().getTime()).then(succ => {
            // Successfull action
          }).catch(err => {
            // To do error handling
          });
          await this._displayDismissLoader(false);
        }
    }

    private _sortFollowupNotes(): void {
        if (this.currentFollowUpActivity.followUpNotes && this.currentFollowUpActivity.followUpNotes.length > 1) {
            this.currentFollowUpActivity.followUpNotes.sort((a, b) => {
                return (isBefore(a.createdTime, b.createdTime) ? 1 : -1)
            })
        }
    }


  private filterSapbyContact() {
    const currentDay: Date = new Date();

    if (this.currentFollowUpActivity.contactId && this.currentFollowUpActivity.assignedTo.length === 0) {

      return this.sapService.sapActivities.filter(x => {

        return x.contacts.some(y => y.ID === this.currentFollowUpActivity.contactId)
          && ((Number(x.endDate) > currentDay.getTime()) && (x.ownerID === this.currentUserID || x.users.some(x => x.ownerid === this.currentUserID)));

      });
    } else if (this.currentFollowUpActivity.contactId && this.currentFollowUpActivity.assignedTo.length > 0) {
      return this.sapService.sapActivities.filter(x => {

        return x.contacts.some(y => y.ID === this.currentFollowUpActivity.contactId)
          && ((Number(x.endDate) > currentDay.getTime())
            && (x.users.some(x => this.currentFollowUpActivity.assignedTo.some(a => a.userId === x.ownerid))));

      });
      // return this.sapService.currentAndFutureSap;
    } else if (!this.currentFollowUpActivity.contactId && this.currentFollowUpActivity.assignedTo.length > 0) {
      return this.sapService.sapActivities.filter(x => {

        return ((Number(x.endDate) > currentDay.getTime())
          && (x.users.some(x => this.currentFollowUpActivity.assignedTo.some(a => a.userId === x.ownerid))));

      });
    } else {
      return this.sapService.currentAndFutureSap;
    }

  }

  public scrapFollowup(): void {
    if (this.isScrapEnabled) {
      this.events.publish("initTimePickerLimitedMinTime");
      this.events.publish('deleteMeeting');
    }
  }

  public getSubjectFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('SUBJECT'),
      inputText: (this.currentFollowUpActivity && this.currentFollowUpActivity.subject) ? (this.currentFollowUpActivity.subject === 'Follow-up Action' ? this.translate.instant('FOLLOW_UP_ACTION') : this.currentFollowUpActivity.subject) : this.translate.instant('FOLLOW_UP_ACTION'),
      inputValue: (this.currentFollowUpActivity && this.currentFollowUpActivity.subject) ? (this.currentFollowUpActivity.subject === 'Follow-up Action' ? this.translate.instant('FOLLOW_UP_ACTION') : this.currentFollowUpActivity.subject) : this.translate.instant('FOLLOW_UP_ACTION'),
      id: 'subject-field',
      isReadOnly: this.isReadOnlyView,
      isDisabled: this.backgroundUploadInProgress || this.isReadOnlyView,
      placeholderLabel: this.translate.instant('SUBJECT'),
      showArrow: !this.isReadOnlyView,
      isRequired: !this.isReadOnlyView,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

   private _getLocalisedString(status: string) {

      switch (status) {
        case 'Open':
        return this.translate.instant('OPEN');
        case 'Completed':
          return this.translate.instant('COMPLETED');
        case 'Canceled':
          return this.translate.instant('CANCELLED');
        default:
          return this.translate.instant('OPEN');
      }
    }

  public getStatusFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('STATUS'),
      inputText: !this.currentFollowUpActivity.pendingPushToDynamics? this._getLocalisedString(this.currentFollowUpActivity.statusString) : `${this._getLocalisedString(this.currentFollowUpActivity.statusString)} - ${this.translate.instant('PENDING_SYNC')}`,
      id: 'status-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getSubStatusFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('SUB_STATUS'),
      customPlaceholderLabel: this.translate.instant('SELECT_SUB_STATUS'),
      inputText: this.followupSubStatusString,
      id: 'sub-status-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || this.isReadOnlyView,
      showArrow: (!this.isReadOnlyView && (this.followupActionOpenStatusReasons && !_.isEmpty(this.followupActionOpenStatusReasons))),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
}

  private getFormattedAndLocalisedDate(value: any) {
    return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  public getDueDateField(): IndDateTimeFormViewDataModel {
    let viewData: IndDateTimeFormViewDataModel;
    viewData = {
        isDateField: true,
        label: this.translate.instant('DUE_DATE'),
        inputText: (this.currentFollowUpActivityDueDateString !== 'Select Due Date' && this.currentFollowUpActivityDueDateString !== 'None') ? this.currentFollowUpActivityDueDateString : '',
        fromViewPage: CurViewPageType.FollowUp,
        id: DateTimeFieldType.EndDateField,
        isReadOnly: true,
        isDisabled: this.backgroundUploadInProgress || this.isReadOnlyView,
        customPlaceholderLabel: (this.currentFollowUpActivityDueDateString.length === 0) ? (!this.isReadOnlyView) ? this.translate.instant('SELECT_DUE_DATE') : this.translate.instant('NO_DUE_DATE') : '',
        showArrow: !this.isReadOnlyView,
        isRequired: !this.isReadOnlyView,
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getCreatedByFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CREATED_BY'),
      inputText: this.currentFollowUpActivity.ownerNameString,
      id: 'created-by-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }


  private _getFollowupActiontypePlaceHolder() {
      if (this.isReadOnlyView) {
        if (!this.currentFollowUpActivity.followupActionTypeID) {
          return this.translate.instant('NO_TYPE')
        }
      } else {
          if (this.followupActionTypes && this.followupActionTypes.length > 0) {
            return this.translate.instant('SELECT_TYPE')
          } else {
            return this.translate.instant('NO_TYPE')
          }
        }
    }


  public getActionTypeFormField(): IndFormFieldViewDataModel {
    return {
      label: this.translate.instant('TYPE'),
      customPlaceholderLabel: this._getFollowupActiontypePlaceHolder(),
      inputText: this.currentFollowUpActivity.followupActionTypeName,
      id: 'followup-type-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || !(!this.isReadOnlyView || (this.followupActionTypes && this.followupActionTypes.length > 0)),
      showArrow: (!this.isReadOnlyView && (this.followupActionTypes && this.followupActionTypes.length > 0)),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

  private _getPriorityInputValue() {
    switch (this.currentFollowUpActivity.priorityID) {
      case FOLLOW_UP_PRIORITY.LOW:
        return this.translate.instant('LOW')
      case FOLLOW_UP_PRIORITY.MEDIUM:
        return this.translate.instant('MEDIUM');
      case FOLLOW_UP_PRIORITY.HIGH:
        return this.translate.instant('HIGH');
    }
  }

  public getPriorityFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('FOLLOW_UP_PRIORITY'),
      customPlaceholderLabel: !(this.assignedUsersCanEdit || this.OwnerCanEdit) ? (this.currentFollowUpActivity.priorityID ? '' : this.translate.instant('NO_PRIORITY')) : (this.currentFollowUpActivity.priorityID) ? '': this.translate.instant('SCHEDULER_SELECT_PRIORITY'),
      inputText: this._getPriorityInputValue(),
      id: 'followup-priority-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || !(this.assignedUsersCanEdit || this.OwnerCanEdit),
      showArrow: (this.assignedUsersCanEdit || this.OwnerCanEdit),
      formFieldType: FormFieldType.POPOVER_SELECT,
       eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getAssignedToFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ASSIGNED_TO'),
      inputText: (this.currentFollowUpActivity.assignedTo.length !== 0) ? this.getAssignedToString() : '',
      id: 'assigned-to-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || !(!this.isReadOnlyView || this.currentFollowUpActivity.assignedTo.length > 0),
      customPlaceholderLabel: (this.currentFollowUpActivity.assignedTo.length === 0) ? this.getAssignedToString() : '',
      showArrow: (!this.isReadOnlyView || this.currentFollowUpActivity.assignedTo.length > 0),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  get assignedUsersCanEdit() {
    let isAssignedUSer: boolean = false;
    if (this.currentFollowUpActivity) {
      if (!_.isEmpty(this.currentFollowUpActivity.assignedTo)) {
        isAssignedUSer = this.currentFollowUpActivity.assignedTo.findIndex(user => user.userId == this.authService.user.systemUserID) >= 0;
      }
      return isAssignedUSer && this.currentFollowUpActivity.state == 0
    }
    return isAssignedUSer;
  }

  get OwnerCanEdit() {
    let isEditable: boolean = false;
    if (this.currentFollowUpActivity) {
      isEditable = this.currentFollowUpActivity.ownerId === this.authService.user.systemUserID && this.currentFollowUpActivity.state == 0;
    }
    return isEditable;
  }

  private _selectedProductsInputText() {

    if (this.currentFollowUpActivity.products && !_.isEmpty(this.currentFollowUpActivity.products)) {
      if (this.currentFollowUpActivity.products.length === 1)
        return this.currentFollowUpActivity.products[0].productName;
      else {
        return `${this.currentFollowUpActivity.products[0].productName} + ${this.currentFollowUpActivity.products.length - 1}`;
      }
    }
  }

  private _productsPlaceholder() {

    if (this.isReadOnlyView) {
      if (!this.currentFollowUpActivity.products || this.currentFollowUpActivity.products.length <= 0) {
        return this.translate.instant('NO_PRODUCTS')
      }
    } else {
      if (!this.currentFollowUpActivity.products || this.currentFollowUpActivity.products.length <= 0) {
        return this.translate.instant('SELECT_PRODUCTS')
      }
    }
  }

  public getProductFormField(): IndFormFieldViewDataModel {
    return {
      label: this.translate.instant('PRODUCTS'),
      customPlaceholderLabel: this._productsPlaceholder(),
      inputText: this._selectedProductsInputText(),
      id: 'followup-products-field',
      isReadOnly: true,
      isDisabled:  this.backgroundUploadInProgress || _.isEmpty(this.brandService.brands) || (this.isReadOnlyView && _.isEmpty(this.currentFollowUpActivity.products)),
      showArrow: (!this.isReadOnlyView && !_.isEmpty(this.brandService.brands)) || (this.isReadOnlyView && !_.isEmpty(this.currentFollowUpActivity.products)),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

  public getCustomerFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant(this.utilityService.globalCustomerText, { globalCustomerText: this.utilityService.globalCustomerText }),
      inputText: (this.currentFollowUpActivity.contactId) ? this.currentFollowUpActivity.contactNameString : '',
      id: 'customer-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || this.isReadOnlyView,
      customPlaceholderLabel: (!this.currentFollowUpActivity.contactId) ? (this.isReadOnlyView) ? this.translate.instant('No ' + this.utilityService.globalCustomerText) : this.translate.instant('SELECT_WITH_GLOBALCUSTOMER', { globalCustomerText: this.utilityService.globalCustomerText }) : '',
      showArrow: !this.isReadOnlyView,
      isRequired: !this.isReadOnlyView,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedCustomer && !this.currentFollowUpActivity.contactId ? true : false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getScientificPlanFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('SCIENTIFIC_PLAN'),
      inputText: (this.currentFollowUpActivity.scientificPlanNameString) ? this.currentFollowUpActivity.scientificPlanNameString : '',
      id: 'scientific-plan-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || this.isReadOnlyView,
      customPlaceholderLabel: (!this.currentFollowUpActivity.scientificPlanNameString) ? (this.isReadOnlyView) ? this.translate.instant('NO_SCIENTIFIC_PLAN') : this.translate.instant('SELECT_SCINETIFIC_PLAN') : '',
      showArrow: !this.isReadOnlyView,
      isRequired: !this.isReadOnlyView,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedScientificPlan && !this.currentFollowUpActivity.scientificPlanId,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      if (id != 'assigned-to-field' && this.backgroundUploadInProgress) return;
      switch (id) {
        case 'subject-field':
          if (eventName && eventName === 'input_value_confirm') {
            this.openSubjectPopover(event);
          }
          break;
        case 'end-date-field':
          this.openEndDatePicker(event);
          break;
        case 'assigned-to-field':
          this.openUserSelect();
          break;
        case 'customer-field':
          this.openCustomerSelect();
          break;
        case 'scientific-plan-field':
          this.openScientificPlanSelect(event);
          break;
        case 'followup-type-field':
          if (!this.isReadOnlyView)
            this.openFollowupActionTypeSelect(event);
          break;
        case 'followup-priority-field':
          if (!this.isReadOnlyView)
            this.openPrioritySelect(event);
            break;
        case 'followup-products-field':
          this.openProductSelect();
          break;
        case 'sub-status-field':
          this.openSubStatusSelect(event);
          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
  }

  openProductSelect(){
    if(this.brandService.brands){
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('PRODUCTS'),
      dividerTitle:this.translate.instant('ALL_PRODUCTS_CAP'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton:  !this.isReadOnlyView,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      searchTitle: this.translate.instant('SEARCH_PRODUCTS'),
      hideAllItemsList: this.isReadOnlyView,
      isListSelectionEnabled: !this.isReadOnlyView,
      listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=> this._handleProductComponentEvent(data,eventTarget,refData),
      searchHandler:(text: string) => this._handleProductsComponentSearch(text),
      data: this.getProductDisplayData(),
    };
      this.navService.pushWithPageTracking(MainToolTemplateComponent,PageName.NothingSelectedView,{viewData:listDetail, isGroupedView:false},PageName.MainToolTemplateComponent);
    }
  }


   private getProductDisplayData(): MainCardViewDataModel[] {
    if (!this.isReadOnlyView) {
      // if Product is matched with the Products in Active Brand Products Pool

      let productDataModel = this.brandService.brands && this.brandService.brands.map(product => {
        let isSelected = false;
        if (this.currentFollowUpActivity.products) {
          if (this.currentFollowUpActivity.products.some(taskProduct => (taskProduct.productID === product.ID))) {
            isSelected = true;
          }
        }
        return this._getProductsMainToolDataItem({ id: product.ID, name: product.name }, !this.isReadOnlyView, isSelected);
      });

      return this.appendDeActivatedProductModel(productDataModel)
    } else {
      return this.currentFollowUpActivity.products && this.currentFollowUpActivity.products.map(product => {
        return this._getProductsMainToolDataItem({ id: product.productID, name: product.productName }, !this.isReadOnlyView, true);
      });
    }
  }

  private appendDeActivatedProductModel(productDataModel: MainCardViewDataModel[]) {

    let selectedProductsModel = productDataModel.filter(productModel => productModel.isItemSelectedForSelectionView);

    if (selectedProductsModel.length < this.currentFollowUpActivity.products.length) {

      let filteredDeactivatedproduct = this.currentFollowUpActivity.products.filter(taskProduct => {
        return !this.brandService.brands.some(brandProduct => (taskProduct.productID === brandProduct.ID));
      });

      let deactivatedDataModels = filteredDeactivatedproduct.map(filterProduct => {
        return this._getProductsMainToolDataItem({ id: filterProduct.productID, name: filterProduct.productName }, true, true);
      });

      if (!_.isEmpty(deactivatedDataModels)) {
        productDataModel = productDataModel.concat(deactivatedDataModels);
        productDataModel = productDataModel.sort((a, b) => {
          if (a.primaryTextRight.toLowerCase() > b.primaryTextRight.toLowerCase()) {
            return 1;
          }
          if (b.primaryTextRight.toLowerCase() > a.primaryTextRight.toLowerCase()) {
            return -1;
          }
        });
      }
    }
    return productDataModel;
  }


  private _getProductsMainToolDataItem(product: {id: string, name: string}, showEndIcon: boolean, isSelected: boolean) {
    return {
      id: product.id,
      primaryTextLeft: '',
      secondaryTextLeft: '',
      showEndIcon: showEndIcon,
      mainItemCssClass: 'selector-item',
      isItemSelectedForSelectionView: isSelected,
      endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
      endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
      primaryTextRight: product.name,
      showArrow: false,
      arrowType: '',
      eventOwnerId: this.activityService.selectedActivity.ownerId
    };
  }

  private _handleProductsComponentSearch(text: string): string[] {
    let ids: Array<string> = [];
    if (text.length >= 1) {
      ids = this.brandService.brands && this.brandService.brands.filter(pro => {
        return pro.name.trim().toLowerCase().includes(text.trim().toLowerCase());
      }).map(pro => pro.ID);
    } else {
      ids = this.brandService.brands && this.brandService.brands.map(pro => pro.ID);
    }
    return ids;
  }

  // this method Handles the selection of the Products after clicking of Tick(Done button on MainTool Template Page)
  private _handleProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      if (!this.isReadOnlyView && !this.backgroundUploadInProgress) {

        this.updateFollowupActivityAfterSelection(data, 'followup-products');

      }
    }
  }


  _getpriorityDropDownData() {

    let priorityData = []

    for (const [key, value] of Object.entries(FOLLOW_UP_PRIORITY)) {

      if (!Number.isNaN(Number(key))) {
        continue;
      }
      priorityData.push({ id: value, title: this.translate.instant(key), isSelected: value === this.currentFollowUpActivity.priorityID });
    }
    return priorityData
  }

  async openPrioritySelect(myEvent) {
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'followup-action-type',
      isMultipleSelectionEnabled: false,
      data: this._getpriorityDropDownData(),
    };

    this.popover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'account-plan-select', event: myEvent });

    await this.popover.present();

    await this.popover.onDidDismiss().then(async (data) => {
      if (this.backgroundUploadInProgress) return;
      data = data.data;
      this.updateFollowupActivityAfterSelection(data, 'followup-priority');
      this.popover = undefined;
    });

  }

  async openFollowupActionTypeSelect(myEvent) {

    if (Array.isArray(this.followupActionTypes) && this.followupActionTypes.length > 0) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'followup-action-type',
        isMultipleSelectionEnabled: false,
        data: this.followupActionTypes.map(type => {
          return {
            title: type.name,
            id: type.followupActionTypeID,
            isSelected: type.followupActionTypeID == this.currentFollowUpActivity.followupActionTypeID,
          }
        }),
      };

      this.popover = await this.popoverCtrl.create({component: IndDropdownListComponent,componentProps: {viewData:dropdownListDetail}, cssClass:'account-plan-select', event:myEvent});

      await this.popover.present();

      await this.popover.onDidDismiss().then(async (data) => {
        if (this.backgroundUploadInProgress) return;
        data = data.data;
        this.updateFollowupActivityAfterSelection(data, 'followup-action-type');
        this.popover = undefined;
      });
    }
  }

  private _setFieldValue( data: any,type: string) {

      switch (type)  {
        case 'followup-action-type':
          if (data.selectedItems.length == 1 && _.isArray(data.selectedItems)) {
            this.currentFollowUpActivity.followupActionTypeID = data.selectedItems[0].id;;
            this.currentFollowUpActivity.followupActionTypeName = data.selectedItems[0].title;;
          }
          break;
        case 'followup-priority':
          if (data.selectedItems.length == 1 && _.isArray(data.selectedItems)) {
            this.currentFollowUpActivity.priorityID = data.selectedItems[0].id;;
          }
          break

        case 'followup-products':

          if (Array.isArray(data.selectedItems)) {
            let followupProducts = []
            // Pick only those which are selecetd from the pool of the brand products
            data.selectedItems.forEach(item => {
              this.brandService.brands.forEach(product => {
                if (product.ID === item.id) { // If IDs match make that as a selected
                  product.isSelected = true;
                  followupProducts.push({ productID: product.ID, productName: product.name });
                }
              });
            });
            this.currentFollowUpActivity.products = followupProducts;
          }
          break;

          case 'followup-action-sub-status':
          if (data.selectedItems.length == 1 && Array.isArray(data.selectedItems)) {
            this.currentFollowUpActivity.status = parseInt(data.selectedItems[0].id);
            this.currentFollowUpActivity.state =  0;
          }
          this.setSubStatusString();
          break;
      }
  }

  private async updateFollowupActivityAfterSelection(data: any, type: string) {
    if (data && data.selectedItems && _.isArray(data.selectedItems)) {

      this._setFieldValue(data, type)
      this.currentFollowUpActivity.pendingPushToDynamics = true;
      await this._displayDismissLoader(true);
      this.followUpActivityDataService.updateFollowUpActivity({
        onDynamics: !this.device.isOffline,
        onLocalCopy: true,
        onLocalDatabase: true
      }, [this.currentFollowUpActivity], new Date().getTime()).then(succ => {
        // Successfull action
      }).catch(async (error) => {
        // Handle error occured during follow up activity updation
        if (error && error['errorCode'] && error['errorCode'] === 'ALREADYCOMPLETEDACTIVITY') {
          await this.activityDataService.updateActivityDetails(this.currentFollowUpActivity);
        }
      });

      await this._displayDismissLoader(false);
    }
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'scrap':
        this.scrapFollowup();
        break;
      case 'close':
        this.closePage();
        break;
      case 'cancel':
        this.cancelFollowUp();
        break;
      case 'markComplete':
        this.markFollowUpComplete();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }
  onSectionHeaderControlClick(id: string) {
    if (id === 'task-notes-save') {
      this.saveFollowUpNotes();
    } else if (id == 'attach_note') {
      this.handleNoteAttachment();
    }
  }


  // NOtes Handling

  private handleNoteAttachment() {
    if (this.isNotesAttachmentEnabled) {
      try {
        this.attachInput.nativeElement.click();
      } catch (error) {
        console.log(error);
      }
    }
  }

  onNotesFocus() {
}

onNotesBlur(){
    if(_.isEmpty(this.tempNoteText)){
        this.isSaveNotesEnabled = false;
        this.initTaskNotesheader();
    }
}

  public async  loadImageFromDevice(event){
      if(event && event.target && event.target.files){
        try {
          if((event.target.files[0].size/1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE){
            if(NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(event.target.files[0].name)){
              this.attachmentFile = event.target.files[0];
              this.attachmentTitle = event.target.files[0].name;
              this.isAttachmentAdded = true;
              this.base64str = await toBase64(this.attachmentFile);
              const dataURLPattern = /^data:[^;]+;base64,/;
              this.base64str = this.base64str.replace(dataURLPattern, '');
              this.isSaveNotesEnabled = true;
              this.cdRef.detectChanges();
              this.cdRef.markForCheck();
              this.initTaskNotesheader();

            }else{
              this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'),'Note Attachment','top','info');
              this.removeAttachment(false);
            }
          }else{
            this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION',{size:MAXIMUM_NOTE_ATTACHMENT_SIZE}),'Note Attachment','top','info');
            this.removeAttachment(false);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }

  public removeAttachment(event) {
    try {
       this.attachInput.nativeElement.value = null;
    } catch (error) {
      console.log(error);
    }
    this.attachmentTitle = '';
    this.isAttachmentAdded = false;
    this.attachmentFile = null;
    this.base64str = null;
  }

  get nonDeletedNotes() {
    return this.currentFollowUpActivity.followUpNotes.filter(note => !note.isDeleted)
  }

  private async _displayDismissLoader(displayLoader: boolean) {

      if (displayLoader) {
        if (!this.device.isDeviceRealOffline && this.device.isOffline) {
          await this.UIService.displayLoader();
        }
      } else {
        await this.UIService.dismissLoader();
      }
    }
  public getGroupedActivities(): Array<Object> {
    const events: any[] = [];
    const groupedElements: any = {};
    let groupByKey = 'createdon';
    if (!_.isEmpty(this.currentFollowUpActivity.assignmentHistory)) {
      let assignmentHistory: AssignmentHistory[] = _.cloneDeep(this.currentFollowUpActivity.assignmentHistory);
      assignmentHistory = _.orderBy(assignmentHistory, v => v.createdon, "desc")
      assignmentHistory.forEach((obj: AssignmentHistory, index) => {
        const item = format(new Date(parseInt(obj[groupByKey])), 'MMMM YYYY');
        if (!(item in groupedElements)) {
          groupedElements[item] = [];
        }
        const createdOn: Date = new Date(parseInt(obj.createdon));
        const users = _.orderBy(obj.users, o => o.userFullName);
        const data = {
          day: getDate(createdOn),
          formmattedDate: format(createdOn, this.dateTimeFormatsService.timeToUpper),
          createdon: createdOn,
          secondaryText: this.translate.instant('FOLLOW_UP_ASSIGNED_TO', { users: (users.length === 1 ? users[0].userFullName : (users[0].userFullName + ' +' + (users.length - 1)))}),
          text: (index == (assignmentHistory.length - 1)) ? this.translate.instant("FOLLOW_UP_CREATED") : this.translate.instant("FOLLOW_UP_UPDATED")
        }
        groupedElements[item].push(data);
      });

      for (let prop in groupedElements) {
        if (groupedElements.hasOwnProperty(prop)) {
          events.push({
            key: prop,
            list: groupedElements[prop]
          });
        }
      }
    }
    return events;
  }

  //Owner / Assigned user should be able to add notes
  get canAddNotes() {
    if (this.currentFollowUpActivity) {
      const isOwner = this.currentFollowUpActivity.ownerId === this.authService.user.systemUserID;
      if (!isOwner) {
        return this.currentFollowUpActivity.assignedTo.findIndex(user => user.userId == this.authService.user.systemUserID) >= 0
      }
      return isOwner && this.currentFollowUpActivity.state === 0;
    }
    return false;
  }

  ngOnDestroy() {
    //this.translate.onLangChange.unsubscribe();
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  async openEndDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.FollowUp,
          startDateTimeValue: this.currentFollowUpActivity.scheduledStart,
          endDateTimeValue: this.currentFollowUpActivity.scheduledStart }, // send the same scheduledStart to display current date in the date picker
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then(async (data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.endTime !='') {
        if (this.backgroundUploadInProgress) return;
        const valueDate = new Date(data.data.endTime);
        valueDate.setHours(0, 0, 0, 0);
        if (!(this.currentFollowUpActivity.scheduledEnd && this.currentFollowUpActivity.scheduledEnd.getDate() == valueDate.getDate() && this.currentFollowUpActivity.scheduledEnd.getMonth() == valueDate.getMonth() && this.currentFollowUpActivity.scheduledEnd.getFullYear() == valueDate.getFullYear())) {
          this.currentFollowUpActivity.scheduledStart = valueDate;
          this.currentFollowUpActivity.scheduledEnd = valueDate;
          this.currentFollowUpActivity.scheduledStartLocale = valueDate.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
          this.currentFollowUpActivity.pendingPushToDynamics = true;
          await this.followUpActivityDataService.updateFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true }, [this.currentFollowUpActivity], new Date().getTime()).then(success => {
            //Handle successfull response
            this.currentFollowUpActivityDueDateString = format(this.currentFollowUpActivity.scheduledEnd, this.dateTimeFormatsService.dateToUpper);
            if (!this.UIService.toolsActivityActive){
              this.events.publish("refreshAgenda");
            } else {
              this.UIService.agendaRefreshRequired = true;
            }
            this.events.publish('activityUpdated', this.currentFollowUpActivity);
          }).catch(async error => {
            // Handle error occured during follow up activity updation
            if (error && error['errorCode'] && error['errorCode'] === 'ALREADYCOMPLETEDACTIVITY') {
              await this.activityDataService.updateActivityDetails(this.currentFollowUpActivity);
            }
          });
        }
      }
    });
    popover.present();
  }

  private _updateMandatoryField() {
    this.getCustomerFormField();
  }

  async openSubStatusSelect(myEvent) {

    if ( _.isArray(this.followupActionOpenStatusReasons) && !_.isEmpty(this.followupActionOpenStatusReasons)) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'followup-action-sub-status',
        isMultipleSelectionEnabled: false,
        data: this.followupActionOpenStatusReasons.map(reason => {
          return {
            title: reason.name,
            id: reason.statusCode.toString(),
            isSelected: reason.statusCode == this.currentFollowUpActivity.status,
          }
        }),
      };

      this.popover = await this.popoverCtrl.create({component: IndDropdownListComponent,componentProps: {viewData:dropdownListDetail}, cssClass:'account-plan-select', event:myEvent});

      await this.popover.present();

      await this.popover.onDidDismiss().then(async (data) => {
        if (this.backgroundUploadInProgress) return;
        data = data.data;
        this.updateFollowupActivityAfterSelection(data, 'followup-action-sub-status');
        this.popover = undefined;
      });
    }
    this.getActionTypeFormField();
  }

  public cancelFollowUp(): void {
    if (this.isCancelEnbaled && !this.updateInProgress) {
          this.alertService.showAlert({
            header: this.translate.instant('FOLLOW_UP_ALERT_TITLE_CANCEL_FOLLOW_UP'),
            message: this.translate.instant('FOLLOW_UP_ALERT_MESSGE_CANCEL_FOLLOW_UP'),
            inputs:[{type:'text', name:"reasonforcancellation", placeholder:this.translate.instant('ENTER_REASON_FOR_CANCELLATION')}],
          }, this.translate.instant('OK')
          ).then(res => {
            if (res.role === 'ok') {
              if(res.data && res.data.values && res.data.values.reasonforcancellation == '') {
                this.notificationService.notify(this.translate.instant("ENTER_REASON_FOR_CANCELLATION"), 'Scientific Task cancel', 'top', ToastStyle.INFO);
                return;
              }
              if (this.backgroundUploadInProgress) return;
              this.updateInProgress = true;
              this.footerService.disableButton(['markfollowupcomplete','markfollowupcancel','scrapfollowup']);
              this.isScrapEnabled = false;
              this.isCancelEnbaled = false;
              this.initPageHeaderControls();
              this.events.publish("updateFollowupActivityHeader");
              this._displayDismissLoader(true);
              this.currentFollowUpActivity.state = 2;
              this.currentFollowUpActivity.status = 6;
              this.currentFollowUpActivity.pendingPushToDynamics = true;
              this.currentFollowUpActivity.reasonForCancellation = res.data.values.reasonforcancellation;
              this.isReadOnlyView = true;
              this.followUpActivityDataService.updateFollowUpActivity({
                onDynamics: !this.device.isOffline && !this.device.isDeviceRealOffline,
                onLocalCopy: true,
                onLocalDatabase: true,
                operationDetail : {
                  code : 'CANCEL_FOLLOWUP',
                  message: 'cancelled followup'
                }
              }, [this.currentFollowUpActivity], new Date().getTime()).then(succ => {
                // Successfull action
                this.setSubStatusString();
                this.isReadOnlyView = true;
                this.currentFollowUpActivity.statusString = this.translate.instant('CANCELLED');
                this.updateInProgress = false;
                this._displayDismissLoader(false);
              }).catch(async (error) => {
                // Handle error occured during follow up activity updation
                // if (error && error['errorCode'] && error['errorCode'] === 'ALREADYCOMPLETEDACTIVITY') {
                //   await this.activityDataService.updateActivityDetails(this.currentFollowUpActivity);
                // }
                // this.updateInProgress = false;
                // this._displayDismissLoader(false);
              });
             }
          });
    }
  }

  public getReasonForCancellationFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden : this.currentFollowUpActivity.status !== 6,
      label: this.translate.instant('REASON_FOR_CANCELLATION'),
      inputText: this.currentFollowUpActivity.reasonForCancellation || 'test reason',
      id: 'reason-for-cancellation-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

}
