import { Endpoints } from './../../../../config/endpoints.config';
import { HttpClient } from '@angular/common/http';
import { EventActivity, EventPresentations } from './../../../classes/events-tool/event.class';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {ListType, Page, Presentation} from '../../../classes/presentation/presentation.class';
import { LoadingController, ModalController, PopoverController} from '@ionic/angular';
import { Events } from '@omni/events';
import {PresentationService} from '../../../services/presentation/presentation.service';
import {PresentationView, UIService} from '../../../services/ui/ui.service';
import {PresentationViewPopover} from '../presentation-list/presentation-view-popover'
import {LogService} from '../../../services/logging/log-service';
import {ActivityService} from '../../../services/activity/activity.service';
import {AppointmentActivity, OFFLINE_ID_PREFIX} from '../../../classes/activity/appointment.activity.class';
import {MeetingDataService, UpdateMeetingPayload} from '../../../data-services/meeting/meeting.data.service';
import {Store} from "@ngrx/store";
import fuse from 'fuse.js';

import {fileState} from '../../../store/application.state';
import * as FileAction from '../../../store/io-file-service/actions/file.actions';
import {PresentationDataService} from '../../../data-services/presentation/presentation.data.service';
import {FooterService, FooterViews} from '../../../services/footer/footer.service';
import {BrandOfflineService} from '../../../services/brand/brand.service';
import {DeviceService} from '../../../services/device/device.service';
import {TrackingEventNames, TrackService} from '../../../services/logging/tracking.service';
import {NotificationService, ToastStyle} from '../../../services/notification/notification.service';
import {ChildNavNames, NavigationService, PageName} from '../../../services/navigation/navigation.service';
import {RepServices} from '../../../data-services/rep/rep.services';
import {Specialty} from '../../../classes/contact/contact.class';
import {DeltaService} from '../../../data-services/delta/delta.service';
import {OrderByPipe} from '../../../pipes/orderby.pipe';
import {ContactOfflineService, CustomerTagStateCode} from '../../../services/contact/contact.service';
import {MultiSelectPopover} from '../../multi-select-popover/multi-select-popover';
import * as _ from 'lodash';
import {PresentationFilterByPipe} from '../../../pipes/presentation-filter.pipe';
import {ContentCustomSortPipe} from '../../../pipes/content-custom-sort.pipe';
import {TranslateService} from "@ngx-translate/core";
import {NewActivityComponent} from '../../activity/new-activity/new-activity';
import {MeetingStructureService} from '../../../services/meeting-structure/meeting-structure.service';
import {IndListGridComponent} from '../../../components/shared/ind-list-grid/ind-list-grid';
import {SearchConfigService} from '../../../services/search/search-config.service';
import {IndSectionHeaderViewDataModel} from '../../../models/indSectionHeaderDataModel';
import {MainCardViewDataModel} from '../../../models/MainCardViewDataModel';
import {
  SelectedSuggestionPillDataModel,
  SuggestionPillType,
  UserSavedSearchTypes
} from '../../../models/search-config-data-model';
import {AlertService} from '../../../services/alert/alert.service';
import {SearchConfigDataService} from '../../../data-services/search-config/search-config-data-service';
import {DiskService} from '../../../services/disk/disk.service';
import {DB_KEY_PREFIXES} from '../../../config/pouch-db.config';
import {Resource} from '../../../classes/resource/resource.class';
import {ResourceService} from '../../../services/resource/resource.service';
import { Utility } from '../../../utility/util';
import { OperationDetail } from '../../../data-services/follow-up-activity/follow-up-activity.data.service';
import { EventsToolDataService } from '../../../data-services/event/events-tool.data.service';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { AuthenticationService } from '@omni/services/authentication.service';
import { PresentationPreviewComponent } from '../presentation-preview/presentation-preview';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { intersectionWith, xorWith } from 'lodash';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';
import { EntityTag, TagEntityType, TagStateCode, TagVisibility, UserTag, UserTagService } from '@omni/services/user-tag/user-tag.service';
import { IndFilterMenuComponent } from '@omni/components/shared/ind-filter-menu/ind-filter-menu';
import { PopoverComponent } from '@omni/components/popover/popover';
import { Guid } from 'typescript-guid';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '@omni/models/mainToolTemplateDetail.model';
import { MainToolTemplateComponent } from '@omni/components/shared/main-tool-template/main-tool-template';
import { MeetingStatus } from '@omni/classes/meeting/meeting.class';
import { addMinutes } from 'date-fns';

@Component({
  selector: 'presentation-list',
  templateUrl: 'presentation-list.html',
  styleUrls:['presentation-list.scss']
})
export class PresentationListComponent {

    @Input() viewMode: PresentationView;
    @Input() showPreviewId : string;
    @Output() closeModal = new EventEmitter<string>();
    @Input() selectedContentIds: [];
    @Input() callbackEvent:any;

    @ViewChild('listTop', {static: true}) listTop: HTMLIonContentElement;
    //@ViewChild(Content, {static: true}) content: Content;

    public activePres: Presentation | Resource;
    public presMode = PresentationView;

    public isAddToMeeting = false;
    public isAddToEvent = false;

    public presentations: Presentation[] =[];
    public presToDisplay:(Presentation | Resource)[]=[];
    public viewPres: (Presentation | Resource)[];
    public sortedContent: (Presentation | Resource)[];
    public eventContents: (Presentation | Resource)[] = [];
    private recordCount: number = 20;

    public filteredList: (Presentation | Resource)[] =[];
    public visibleFilteredList: (Presentation | Resource)[] = [];
    private filteredRecordCount: number = 30;
    // public presView: string = 'ios-list';
    // public presViews = ['ios-list', 'md-grid'];

    public isSelectAll: boolean = false;
    public LISTTYPE = ListType;
    public listType = this.LISTTYPE.LIST;
    public viewMetaData: any;

    public viewType: {
        name: "list",
        code: "ios-list",
    };

    public searchInput: string;

    // public virtualPresList$ = new BehaviorSubject<any[]>(null);

    // @ViewChild(VirtualListComponent) private virtualList: VirtualListComponent | any;


    @ViewChild('childModal', {static: true}) childModal: IndListGridComponent;

    public isDirty:boolean = false;
    public isContentMatching:boolean = false;
    // Boolean to track if changes are made to meeting pres
    private isModified = false;

    // Array to keep track of favourited or unfavourited presentation
    private favPres: Presentation[] = [];
    public newPres: Presentation[] = [];
    public selectedPresList: (Presentation | Resource)[] = [];

    public meetingContent: (Presentation | Resource | any)[] = [];
    public contents: (Presentation | Resource)[] = [];

    private windowWidth: number = 0;
    public presItemTransform = {
        mainWrapperMarginLeft: 0,
        mainWrapperMarginTop: 0,
        iframeScaleWidth: 0,
        iframeScaleHeight: 0,
    };

    public today = new Date();

    specialties: Specialty[];
    // filterPopoverData: any[];
    sortBy: { text: string, value: string, asc: boolean };
    customSortBy = { favourite: false, downloaded: false };
    presentationFilter = { name: '', specialtyId: '', brandId: '' };
    debounceTime: number = 300;

    presentationFilterByPipe: PresentationFilterByPipe = new PresentationFilterByPipe();
    contentCustomSortPipe: ContentCustomSortPipe<Presentation> = new ContentCustomSortPipe<Presentation>();
    orderByPipe: OrderByPipe = new OrderByPipe();
    sortPopoverData: { text: string; expanded: boolean; value: string; items: { text: string; value: string; asc: boolean; }[]; handler: (selectedItem: any, item: any) => void; }[];
    contentSortPopoverData: { text: string; expanded: boolean; value: string; items: { text: string; value: string; asc: boolean; }[]; handler: (selectedItem: any, item: any) => void; }[];

    public searching: boolean = false;
    presentationSearchText: string;
    shouldFireSearchChangeEvent: boolean = true;
    suggestionsData: {
        header:IndSectionHeaderViewDataModel,
        listData: MainCardViewDataModel[]
      }[] = [];
    //search key to be passed to search-suggestion-popover
    searchKey : string = '';
    selectedSuggestionsData:  SelectedSuggestionPillDataModel[] = [];
    suggestionsActive: boolean = false;
    disableSaveSearch: boolean = false;
    public filterMetadata = { count: 0 };
    private scrollingTop: boolean = false;
    private infiniteLoader: any;
    indHeaderLeftModel: IndHeaderLeftDataModel;
    allPresentationHeader: IndSectionHeaderViewDataModel;
    resultPresentationHeader: IndSectionHeaderViewDataModel;
    unmappedContent: any =[];
    hasKeywordSearchEnabled: boolean = false;
    private origContents: (Presentation | Resource)[] = [];
    private _selectedPresentationForTag:Array<Presentation> = [];
    public selectedTagData: UserTag;
    public selectedFilterData: SelectedSuggestionPillDataModel[] = [];
    public filtering: boolean = false;
    public filterNameBubble: { categoryName: string; suggestion: SelectedSuggestionPillDataModel[]}[] = [];
    public tagListPopover: HTMLIonPopoverElement;
    public showRemoveTag :boolean =false;
    public hideFilterButton :boolean =false;

    constructor(
        public presentationService: PresentationService,
        private presDataService: PresentationDataService,
        public uiService: UIService,
        public popoverCtrl: PopoverController,
        private logService: LogService,
        private activityService: ActivityService,
        private meetingDataService: MeetingDataService,
        public store: Store<fileState>,
        public footerService:FooterService,
        private brandService: BrandOfflineService,
        public deviceService: DeviceService,
        private trackingService: TrackService,
        private notificationService: NotificationService,
        private navService: NavigationService,
        public repService: RepServices,
        private loadingController:LoadingController,
        private deltaService: DeltaService,
        private contactService: ContactOfflineService,
        public _CD: ChangeDetectorRef,
        private events: Events,
        public translate:TranslateService,
        private elRef: ElementRef,
        private alertService: AlertService,
        public searchConfigService: SearchConfigService,
        public searchConfigDataService: SearchConfigDataService,
        public disk: DiskService,
        private meetingStructureService: MeetingStructureService,
        public resourceService: ResourceService,
        private eventsToolDataService: EventsToolDataService,
        private readonly authService: AuthenticationService,
        private readonly http : HttpClient,
        private readonly userTagService: UserTagService,
        private modalCtrl:ModalController,
        public popover: PopoverController,
        public agendaFooterService : AgendaFooterService,
        private faService: FeatureActionsService,
    ) {

        this.presentationService.currentselectedPresView.subscribe(type => {
            this.changeView(type);
        });

        this.footerService.initButtons(FooterViews.Presentations);
        this.presentationService.presShowRightPane = false;
    }

    initSpecialties() {
        this.specialties = _.reduce(this.contents, (result, pres, idx) => {
            const union = _.unionBy(result, pres['specialties'], 'id');
            return union;
        }, []);

        this.specialties.map(specialty => {
            const specialtyMasterData = this.contactService.specialties.find(s => s.id === specialty.id);
            if (specialtyMasterData) {
                specialty.name = specialtyMasterData.name;
            }
        });
        _.remove(this.specialties,sp=> !sp.name);
    }

    updateEmptyMessage(){
      //if(this.isAddToMeeting){
        let dataSize = this.contents.length
        if(this.searching || this.filtering){
          dataSize = this.visibleFilteredList.length
          if(this.isAddToMeeting){
            dataSize = this.visibleFilteredList.length > 0 ? this.visibleFilteredList.length :
            (this.meetingContent.length > 0 ? this.meetingContent.length : 0)
          }
        }
        else{
          if(this.isAddToMeeting){
            dataSize = this.contents.length > 0 ? this.contents.length :
            (this.meetingContent.length > 0 ? this.meetingContent.length : 0)
          }
        }
        this.uiService.updateNothingSelectedScreenMessageFor(dataSize)
      //}
    }

    ngOnInit() {
      this.presentationService.setCurrentSelectedPres(null);
      this.onDefaultFilterLoad();
      this.hasKeywordSearchEnabled = this.authService.hasFeatureAction(FeatureActionsMap.CONTENT_KEYWORD_SEARCH);
        this.presToDisplay = [];

        this.presentations = this.presentationService.filterExpiredPresentations(this.presentationService.presentation);
        this.presentations.map(pres => {
          pres['category'] = 'Presentations';
          pres.showCustomSecInfo = false;
          pres.customePageLocation = undefined;
        });

        if(this.viewMode === this.presMode.ADDTOEVENT){
          this.contents = this.presentations;
        } else {
          this.contents = _.concat(this.contents, this.presentations);
        }

         // let presentations: Presentation[] =[];
        if (this.viewMode === this.presMode.ADDTOMEETING) {
          this.isAddToMeeting = true;
          if (this.activityService.selectedActivity instanceof AppointmentActivity) {
            this.meetingContent = (this.activityService.selectedActivity.presentations ? this.activityService.selectedActivity.presentations.slice() : []);
          }
          this.meetingContent.forEach(pres=>{
            pres.showCustomSecInfo = false;
            pres.customePageLocation = undefined;
          })
        } else if (this.viewMode === this.presMode.ADDTOEVENT) {
          this.isAddToMeeting = true;
          this.isAddToEvent = true;
          if (this.activityService.selectedActivity instanceof EventActivity) {
            let eventContents: (Presentation | Resource)[] = [];
            (this.activityService.selectedActivity as EventActivity).presentations.forEach((pre) => {
              if (!pre.isDeleted) {
                let foundContent = this.contents.find(ep => ep.contentId == pre.id);
                if (foundContent) {
                  foundContent['eventOwnerId'] = this.activityService.selectedActivity.ownerId;
                  if (this.activityService.selectedActivity.ownerId === this.authService.user.systemUserID) {
                    foundContent['hideCancelButton'] = false;
                  } else {
                    foundContent['hideCancelButton'] = true;
                  }
                  if (foundContent) eventContents.push(foundContent);
                } else {
                  pre['eventOwnerId'] = this.activityService.selectedActivity.ownerId;
                  if (this.activityService.selectedActivity.ownerId === this.authService.user.systemUserID) {
                    pre['hideCancelButton'] = false;
                    pre['ioPresentationId'] = pre.id;
                  } else {
                    pre['hideCancelButton'] = true;
                    pre['ioPresentationId'] = pre.id;
                  }
                  this.unmappedContent.push(pre);
                }
                this.meetingContent = this.unmappedContent.concat(eventContents);
              }
            })
          }
        } else if (this.viewMode === PresentationView.ADDTOACCOUNTPLAN || this.viewMode == PresentationView.VIEWFROMACCOUNTPLAN) {
          this.isAddToMeeting = true;
          this.contents.forEach(con => {
            const foundContent = this.selectedContentIds.find(selC => selC['ioPresentationId'] == con.contentId);
            if (foundContent && !this.meetingContent.includes(con)) {
              this.meetingContent.push(con);
            }
          });
          this.resourceService.allResources.forEach(res => {
            const foundContent = this.selectedContentIds.find(selC => (res.ioResourceId && selC['ioResourceId'] == res.ioResourceId) || (selC['ioDocumentId'] == res.ioDocumentId && res.ioDocumentId));
            if (foundContent && !this.meetingContent.includes(res)) {
              this.meetingContent.push(res);
            }
          });
          if(this.viewMode == PresentationView.VIEWFROMACCOUNTPLAN) {
            this.contents = this.meetingContent;
            this.meetingContent = [];
          }
        } else {
          this.isContentMatching = false;
          this.presentationService.presentation = [...this.presentationService.initialPres];
        }
        this.initHeaderLeft();
        this.initSpecialties();

        //ading new presentations
        if(this.deltaService.deltaRecordsDTO.presentations.length) {
            this.presentationService.presentation.forEach(pres => {
                if(this.deltaService.deltaRecordsDTO.presentations.indexOf(pres.ioPresentationId) !=-1) {
                    this.newPres.push(pres);
                }
            });

            this.newPres = this.presentationService.filterExpiredPresentations(this.newPres);
            this.presentationService.updateNewPresStatus(this.newPres);
        }
        //Adding resources
        if (this.viewMode != PresentationView.VIEWFROMACCOUNTPLAN && (this.isAddToMeeting && !this.isAddToEvent) || (this.viewMode == PresentationView.ADDTOACCOUNTPLAN)) {
          this.addResourcesToList();
        }

        //ordering contents by name
        this.contents = this.sortedContent = this.orderByPipe.transform(this.contents, 'name', true);

        if(this.searchConfigService.configUpdateRequired){
            this.searchConfigService.updateSearchConfigsForSelectedLanguage();
            this.searchConfigService.configUpdateRequired = false;
        }


        this.sortPopoverData = [{
            text: "",
            expanded: true,
            value: "name",
            items: [
                { text: this.translate.instant('NAME'), value: "name", asc: true },
                { text: this.translate.instant('LAST_MODIFIED_DATE'), value: "modifiedOn", asc: false },
                { text: this.translate.instant('PRESENTATIONS_FAVORITES'), value: "$favourite", asc: false },
            ],
            handler: (selectedItem, item) => {
                if (item.value !== selectedItem.value) {
                    this.trackingService.tracking('PresentationsSort',TrackingEventNames.PRESENTATIONS);

                    item.value = selectedItem.value;
                    if (selectedItem.value === '$favourite') {
                        this.customSortBy = Object.assign({}, { favourite: true, downloaded: false })
                    } else if (selectedItem.value == '$downloaded' && this.contents.filter(content => content.downloaded).length > 0) {
                        this.customSortBy = Object.assign({}, this.customSortBy, { favourite: false, downloaded: true })
                    } else {
                        this.customSortBy = { favourite: false, downloaded: false };
                    }
                    this.sortBy = selectedItem;
                    if(!this.isAddToMeeting || this.isAddToEvent || this.viewMode == PresentationView.ADDTOACCOUNTPLAN || this.viewMode == PresentationView.VIEWFROMACCOUNTPLAN) {
                      this.contents = this.contentCustomSortPipe.transform(<Presentation[]>this.contents, this.customSortBy);
                      this.sortedContent = this.contentCustomSortPipe.transform(<Presentation[]>this.sortedContent, this.customSortBy);
                      this.contents = this.orderByPipe.transform(this.contents, this.sortBy.value, this.sortBy.asc);
                      this.sortedContent = this.orderByPipe.transform(this.sortedContent, this.sortBy.value, this.sortBy.asc);
                      this.filteredList = this.contentCustomSortPipe.transform(<Presentation[]>this.filteredList, this.customSortBy);
                      this.filteredList = this.orderByPipe.transform(this.filteredList, this.sortBy.value, this.sortBy.asc);
                    }
                    // this.virtualPresList$.next(this.presentations);
                    this.scrollingTop = true;
                    this.updateContactToDisplay();
                    this.scrollingTop = false;
                    this.updateEmptyMessage()
                    if(this.infiniteLoader && this.infiniteLoader.target)
                      this.infiniteLoader.target.disabled = false;
                }
            }
        }];

        this.contentSortPopoverData = [{
          text: "",
          expanded: true,
          value: "name",
          items: [
            { text: this.translate.instant('NAME'), value: "name", asc: true },
            { text: this.translate.instant('LAST_MODIFIED_DATE'), value: "modifiedOn", asc: false },
          ],
          handler: (selectedItem, item) => {
            if (item.value !== selectedItem.value) {
              item.value = selectedItem.value;
              if (selectedItem.value == '$downloaded') {
                this.customSortBy = Object.assign({}, this.customSortBy, { favourite: false, downloaded: true })
              } else {
                this.customSortBy = { favourite: false, downloaded: false };
              }
              if(selectedItem.value == '$downloaded') {
                this.filteredList = this.contentCustomSortPipe.transform(this.filteredList, { downloaded: true });
                this.sortedContent = this.contentCustomSortPipe.transform(this.sortedContent, this.customSortBy);
                this.contents = this.contentCustomSortPipe.transform(this.contents, { downloaded: true });
              }
              this.sortBy = selectedItem;

              this.contents = this.orderByPipe.transform(this.contents, this.sortBy.value, this.sortBy.asc);
              this.sortedContent = this.orderByPipe.transform(this.sortedContent, this.sortBy.value, this.sortBy.asc);
              this.filteredList = this.orderByPipe.transform(this.filteredList, this.sortBy.value, this.sortBy.asc);
              this.scrollingTop = true;
              this.updateContactToDisplay();
              this.scrollingTop = false;
              this.updateEmptyMessage()
              if(this.infiniteLoader && this.infiniteLoader.target)
                this.infiniteLoader.target.disabled = false;
            }
          }
        }];

        this.sortBy = (this.isAddToMeeting && !this.isAddToEvent) ? this.contentSortPopoverData[0].items[0] : this.sortPopoverData[0].items[0];

        if (this.deviceService.isNativeApp) {
            this.sortPopoverData[0].items.push({ text: this.translate.instant('DOWNLOADS'), value: '$downloaded', asc: true });
            this.contentSortPopoverData[0].items.push({ text: this.translate.instant('DOWNLOADS'), value: '$downloaded', asc: true });
        }
        this.contents.map(content => content.isSelected = false);

        this.updateContactToDisplay();
        this.initSectionHeader();

        //contents should be sorted alphabetically
        if(this.searching || this.filtering) {
            this.viewPres = this.visibleFilteredList;
        } else {
            this.viewPres = this.presToDisplay;
        }

        this.viewMetaData = {
            clickHandler: (event, item) => {
                // this.showPreview(item);
            }
        }
        this.updateEmptyMessage()

        if (this.showPreviewId) {
          let presentation = this.presentationService.getPresentationById(this.showPreviewId);
          if (presentation) {
            setTimeout(() => {
              this.showPreview(presentation);
            }, 500);
          }
        }
    }

  get allPresentationSectionTitle() {
    let title: string = '';
    if (this.viewMode == PresentationView.ADDTOACCOUNTPLAN || this.viewMode == PresentationView.VIEWFROMACCOUNTPLAN ) {
      title = this.translate.instant("ALL_STRATEGY_DOCUMENTS")
    } else if ((!this.isAddToMeeting || this.isAddToEvent) && ((this.presentationFilter.specialtyId === '' && this.presentationFilter.brandId === '' && this.presentationFilter.name === '') || this.presToDisplay.length == 0)) {
      title = this.translate.instant("P_ALL_PRESENTATIONS")
    } else if (this.isAddToMeeting && !this.isAddToEvent && ((this.presentationFilter.specialtyId === '' && this.presentationFilter.brandId === '' && this.presentationFilter.name === '') || this.presToDisplay.length == 0)) {
      title = this.translate.instant("ALL_CONTENT_CAPS")
    }
    return title + " (" + this.contents.length + ")";
  }

  private initSectionHeader() {
    this.allPresentationHeader = {
      id: 'all-presentation',
      title: this.allPresentationSectionTitle,
      controls: [
        {
          id: 'all-pres-sort',
          text: this.sortBy.text,
          isDisabled: false,
          img: 'assets/imgs/sort_with_double_arrows.svg',
          isVisible: !this.presentationService.isSelectMode
        }
      ]
    };

    this.resultPresentationHeader = {
      id: 'all-presentation',
      title: this.translate.instant("AL_RESULTS") + " (" + this.filterMetadata.count + ")",
      controls: [
        {
          id: "clear-filter",
          text: this.translate.instant('CLEAR_FILTER'),
          isVisible: this.filtering,
          isDisabled: _.isEmpty(this.selectedFilterData)
        },
        {
          id: 'all-pres-sort',
          text: this.sortBy.text,
          isDisabled: false,
          img: 'assets/imgs/sort_with_double_arrows.svg',
          isVisible: !this.presentationService.isSelectMode
        }
      ]
    };
  }

  private initHeaderLeft() {
    let buttons = [];
    if (this.isAddToMeeting) {
      buttons.push(...[
        {
          id: "cancel",
          imgSrc: 'assets/imgs/header_cancel.svg',
          cssClass: 'seventyPercentWidth',
          isDisabled: false,
          align: "left",
        }
      ]);
      if (this.viewMode != PresentationView.VIEWFROMACCOUNTPLAN) {
        buttons.push({
          id: "done",
          imgSrc: 'assets/imgs/header_complete.svg',
          cssClass: 'seventyPercentWidth',
          isDisabled: !this.isDirty,
          align: "right",
        })
      }
    } else {
      buttons.push({
        id: "cancel",
        imgSrc: 'assets/imgs/back_to_home_btn.svg',
        // cssClass: 'seventyPercentWidth',
        isDisabled: false,
        align: "left",
      });
    }

    this.indHeaderLeftModel = {
      id: 'presentation-list-header-left',
      cssClass: (!this.isAddToMeeting || this.isAddToEvent) ? 'main-tool-header-title' : 'leftScreenHeaderTitle',
      title: this.viewMode === this.presMode.ADDTOACCOUNTPLAN || this.viewMode === this.presMode.VIEWFROMACCOUNTPLAN ? this.translate.instant("R_STRATEGY_DOCUMENTS") : (!this.isAddToMeeting || this.isAddToEvent) ? this.translate.instant("PRESENTATIONS")  : this.translate.instant("CONTENT"),
      mode: false,
      customHeaderProps: {
        hasCancel: (this.isAddToMeeting)
      },
      controls: buttons,
    };
  }

  public onSectionHeaderControlClick(id: string) {
    if (id === 'all-pres-sort') {
      this.sort();
    }
    if (id === 'clear-filter') {
      this.clearFilters();
    }
  }

  private clearFilters() {
    this.filtering = false;
    this.selectedFilterData = [];
    this.filterNameBubble = [];
    if (this.selectedSuggestionsData.length == 0) {
      this.removeAllSelectedSuggestions();
    } else {
      this.searchPresentationsList();

    }
    this.updateContactToDisplay();
    this.initSectionHeader();
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'cancel':
        this.closePresentation();
        break;
      case 'done':
        this.addToMeeting();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

    addResourcesToList() {
      if (!_.isEmpty(this.resourceService.allResources)) {
        const allResources: Resource[] = _.cloneDeep(this.resourceService.allResources);
        let resources: Resource[] = allResources.filter(Utility.filterByDate).map(resource => {
            resource.assetType = resource.assetType || "other";
            resource.brands = resource.brands.map(brand => {
              if (brand) {
                let br = resource.raw.brands.find(b => b.productName == brand);
                if (br) {
                  resource['presentationTherapeuticAreas'] = br.therapeuticAreas && br.therapeuticAreas.length ? br.therapeuticAreas.map(ta => ta = <any>{'indskr_name': ta.therapeuticAreaName}) : [];
                  return {
                    'name': br.productName
                  }
                }
              }
            });
            resource['category'] = 'Resources';
            return resource;
          });
        this.contents = _.concat(this.contents, resources);
      }
      this.contents.forEach(content => this.presentationService.mapContentFieldsToSearchIndex(content));
    }

    ngAfterViewInit() {
      setTimeout(() => this.resizePresThumb(), 0);
    }

    getFilterText(){
      let str;
      if(this.presentationFilter.brandId){
        let brand = this.brandService.brands.find(o=>o.ID == this.presentationFilter.brandId)
        str = brand.name
        if(this.presentationFilter.specialtyId){
          let speciality = this.specialties.find(o=>o.id == this.presentationFilter.specialtyId)
          str += ', '+speciality.name
        }
      }
      else{
        if(this.presentationFilter.specialtyId){
          let speciality = this.specialties.find(o=>o.id == this.presentationFilter.specialtyId)
          str = speciality.name
        }
      }
      if(!str) str = this.isAddToMeeting ? this.translate.instant('ALL_CONTENTS') : this.translate.instant('ALL_PRESENTATIONS');
      return str;
    }

    ngOnDestroy() {
        if(this.viewMode === this.presMode.MENU) {
            this.presDataService.updatePresentationRecord(this.favPres);
        }
    }

    closePresentation() {
        this.trackingService.tracking('PresentationsBack',TrackingEventNames.PRESENTATIONS);
        if (!this.isAddToMeeting) {
            this.events.publish("tools-back-clicked", PageName.PresentationPageComponent);
        }
        this.presentationService.setCurrentSelectedPres(undefined);
        if(this.viewMode === this.presMode.ADDTOMEETING) {
            if (this.activityService.selectedActivity instanceof AppointmentActivity) {
                this.presentationService.setCarouselBriefcase(this.activityService.selectedActivity.presentations);
            }
        }else{
            this.deltaService.deltaRecordsDTO.presentations.length = 0;
        }
        
       

        this.navService.popChildNavCtrlFromStack(ChildNavNames.PresentationToolsNavigation);
        this.navService.popWithPageTracking().then(()=>{
            if(this.presentationService.isSelectMode) this.selectMode(); // CWD-3120 Fix Unsets the flag for select mode on going back from presentation list
            if(this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) this.footerService.initButtons(FooterViews.Activities);
            else if(this.navService.getActiveChildNavViewPageName() === PageName.CaseManagementDetailComponent || this.uiService.activeView === 'customer_inquiry') this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
            if(this.uiService.activeView === 'Meeting') {
              this.footerService.initButtons(FooterViews.PreviewMeeting);
            } else if(this.viewMode == PresentationView.ADDTOACCOUNTPLAN || this.viewMode == PresentationView.VIEWFROMACCOUNTPLAN) {
              this.footerService.initButtons(FooterViews.None);
            } else {
              // Short call feature requires to bring the tab back to short call home
              if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
                this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
                this.uiService.setAgendaTab('shortCallHome');
              }
            }
        });
    }


    /**
     * set val to the value of the ev target
     *
     * @param {any} ev
     * @returns
     * @memberof PresentationListComponent
     */

    sort() {
      this.popoverCtrl
          .create({component: MultiSelectPopover,componentProps: { root: this.isAddToMeeting ? this.contentSortPopoverData :this.sortPopoverData },cssClass: 'presentation-filter',event:event})
          .then((data)=> data.present());
    }

    /**
     * get full path for image
     *
     * @param {any} ev
     * @returns
     * @memberof PresentationListComponent
     */
    // public getImageUrl(imgPath): string {
    //     return + imgPath;
    // }

    /**
     * @param {Presentation} pres
     * @memberof PresentationListComponent
     */
    public async showPreview(pres: any,remove?:boolean) {
      if(!(pres instanceof Presentation || pres instanceof Resource)){
        this.presentationService.setCurrentSelectedPres(null);
        this.presentationService.presShowRightPane = false;
        if(!remove){
          this.notificationService.notify(this.translate.instant('PRESENTATION_ACCESS'), "presentation-list", "top", ToastStyle.DANGER, 3000, true);
        }
        return;
      }
        if(this.deviceService.isNativeApp && !pres.downloaded && this.deviceService.isOffline && this.isAddToMeeting) {
            this.notificationService.notify( this.translate.instant('PLEASE_DOWNLOAD_CONTENT_TO_VIEW__WHILE_OFFLINE'), "presentation-list", "top", ToastStyle.DANGER, 3000, true);
            return;
        }
          this.trackingService.tracking('PresentationPreview',TrackingEventNames.PRESENTATIONS)
          if(this.deviceService.isNativeApp && !pres.downloaded && this.deviceService.isOffline) {
              this.notificationService.notify( this.translate.instant('PLEASE_DOWNLOAD_THE_PRESENATION_TO_VIEW_WHILE_OFFLINE'), "presentation-list", "top", ToastStyle.DANGER, 3000, true);
              return;
          }
          this.navService.popToRootChildNavPageWithPageTracking();
          this.navService.pushChildNavPageWithPageTracking(PresentationPreviewComponent, PageName.PresentationPreviewComponent,PageName.PresentationPageComponent, { from: 'PresentationToolsPage', viewMode: this.viewMode });
          this.presentationService.presShowRightPane = true;
          this.logService.logDebug(pres);
          this.activePres = pres;
          this.presentationService.setCarouselBriefcase([pres]);
          this.presentationService.setCurrentSelectedPres(pres);
          let page: Page = this.presentationService.presPages && this.presentationService.presPages.length > 0 ? this.presentationService.presPages[0] : null ;
          if(this.activePres.customePageLocation){
            if(isNaN(parseInt(this.activePres.customePageLocation))){
              page = this.presentationService.presPages.find(o=> o.name == this.activePres.customePageLocation)
            }
            else{
                page = this.presentationService.presPages.find(o=> o.name == 'Slide'+this.activePres.customePageLocation)
            }
          }
          this.presentationService.setCurrentSelectedPresPage(page);
    }

    /**
     * @param {Presentation} pres
     * @memberof PresentationListComponent
     */
    public downloadPres(pres: Presentation) {
        this.trackingService.tracking('PresentationsDownlaod',TrackingEventNames.PRESENTATIONS)
        if((this.deviceService.isOffline || this.deviceService.isDeviceRealOffline) && !pres.downloaded) {
            this.notificationService.notify( this.translate.instant('PLEASE_DOWNLOAD_THE_PRESENATION_TO_VIEW_WHILE_OFFLINE'), "Presentation List", "top", ToastStyle.DANGER, 2000, true);
            return;
        }

        if(!pres.downloaded) {
            this.notificationService.notify((this.translate.instant('DOWNLOADING') +' '+ pres.name),'Presentation Download');

            if(pres.zipUrl && pres.thumbnailZipUrl) {
                this.store.dispatch(new FileAction.downloadFileEnqueue({ presentationId: pres.ioPresentationId }));
            } else {
                this.notificationService.notify( this.translate.instant('FAILED_TO_DOWNLOAD_PRESENTATION') + pres.name, "io-file-service", "top", ToastStyle.DANGER, 3000, true);
            }
        } else {
            // this.assistantService.addNewNotification(pres.name + " has finished downloading");
            this.notificationService.notify(this.translate.instant('SUCCESSFULLY_REMOVED') +' '+ pres.name, "Presentation List");
            this.presDataService.deleteDownloadedPresentation(pres.ioPresentationId);
        }
    }

    public bulkDownloadPres(presList: Presentation[]) {

        if(this.selectedPresList.length < 1 || this.deviceService.isOffline || !this.deviceService.isNativeApp) {
            return;
        }

        for(let pres of presList) {
            if(!pres.downloaded) {
                if(pres.zipUrl && pres.thumbnailZipUrl) {
                    this.store.dispatch(new FileAction.downloadFileEnqueue({ presentationId: pres.ioPresentationId }));
                } else {
                    this.notificationService.notify( this.translate.instant('FAILED_TO_DOWNLOAD_PRESENTATION')+ pres.name, "io-file-service", "top", ToastStyle.DANGER, 3000, true);
                }
            } else {
                this.presDataService.deleteDownloadedPresentation(pres.ioPresentationId);
            }
        }
    }

    public singleFavPres(pres: Presentation) {
        this.favPres.push(pres);
        this.presDataService.favouritePresentation([pres]);
    }

    /**
     * @param {Presentation} pres
     * @memberof PresentationListComponent
     */
    public async bulkFavouritePres(pres: Presentation[]) {
        if(this.selectedPresList.length < 1) {
            return;
        }

        this.favPres = [...pres];
        let tempFav = this.favPres.filter(x => x.favourite == false);

        this.uiService.displayLoader();
        await this.presDataService.favouritePresentation(tempFav.length > 0 ? tempFav : pres);
        this.uiService.dismissLoader();
    }

     public selectMode() {
        this.selectedPresList = [];
        this.presentationService.isSelectMode = !this.presentationService.isSelectMode;
        this.contents.map(pres => pres.isSelected = false);
        this.filteredList.map(pres => pres.isSelected = false);
        this.isSelectAll = false;
        this.resultPresentationHeader.controls[1].isVisible = !this.presentationService.isSelectMode;
        this.allPresentationHeader.controls[0].isVisible = !this.presentationService.isSelectMode;
        this.presentationService.selPresentation = [];
    }

    public selPres(pres: Presentation) {

    }

    /**
     * @param {any} event
     * @memberof PresentationListComponent
     */
    public async showPopover(event) {
        let popover = await this.popoverCtrl.create({component: PresentationViewPopover,event:event });
        popover.present();
    }

    /**
     * @param {*} type
     * @memberof PresentationListComponent
     */
    public changeView(type: any) {
        this.logService.logDebug(type);
        this.viewType = type;

    }

    // #region MEETING SPECIFIC functions

    private handlePresentationAddOnlineError = (error, loader) => {
      console.error('handlePresentationAddOnlineError: ', error);
      loader?.dismiss();
      this.uiService.dismissLoader();
      if (this.activityService.selectedActivity instanceof AppointmentActivity) {
        this.activityService.selectedActivity.presentations = Array.isArray(this.origContents) ? this.origContents : [];
        this.origContents = undefined;
      }

      if (error?.message === 'ignore') {
        return;
      }
      this.notificationService.notify(this.translate.instant('ERROR_UPDATING_MEETING_PRESENTATIONS'),'Presentation List', 'top', ToastStyle.DANGER);
    }
    private postPresentationAddOnline = (loader) => {
      //Removed the toast message
      //this.notificationService.notify("Successfully updated meeting presentations",'Presentation List');
      if (this.activityService.selectedActivity instanceof AppointmentActivity) {
          this.presentationService.setCarouselBriefcase(this.activityService.selectedActivity.presentations);
          if(this.isModified && this.meetingContent.length >= 1){
            if(!this.presentationService.activePresentation){
              this.presentationService.setCurrentSelectedPres(this.activityService.selectedActivity.presentations[0]);
              const page: Page = this.presentationService.presPages && this.presentationService.presPages.length > 0 ? this.presentationService.presPages[0] : null;
              this.presentationService.setCurrentSelectedPresPage(page);
            }
          }else if(this.meetingContent.length == 0){
              this.presentationService.clearCurrentPresentation();
          }
      }
      this.meetingStructureService.updateContents(this.activityService.selectedActivity['presentations']);
      this.updateMeetingTimeAsPerContentPlayed();
      loader?.dismiss();
      this.uiService.dismissLoader();
      this.navService.popChildNavCtrlFromStack(ChildNavNames.PresentationToolsNavigation);
      this.navService.popWithPageTracking().then(()=>{
          if(this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) this.footerService.initButtons(FooterViews.Activities);
          if(this.uiService.activeView === 'Meeting') {
            this.footerService.initButtons(FooterViews.PreviewMeeting);
            this.presentationService.showNewActivity = false;
            // this.navService.popChildNavPageWithPageTracking();
          }
      });
    }

    private async updateMeetingTimeAsPerContentPlayed() {
      if (this.authService.user.buSettings['indskr_showmeetingtimeascontentplaytime']) {
        const appointmentActivity = this.activityService.selectedActivity as AppointmentActivity;
        const scheduleTime = this.activityService.getLastSlideTime();
        let allPlayedContensRemoved = _.isEmpty(appointmentActivity.presentations) &&
          (!_.isEmpty(appointmentActivity.activityPresentations) ||
            !_.isEmpty(appointmentActivity.activityResources));
        if (!allPlayedContensRemoved) {
          const playlist = appointmentActivity.presentations?.map(pres => pres.contentId);
          let playedContentsAvailable = appointmentActivity.activityPresentations?.some(pres => playlist.includes(pres.presentationId));
          if (!playedContentsAvailable)
            appointmentActivity.activityResources?.forEach(res => (playlist.includes(res.indskr_iodocumentid) || playlist.includes(res.indskr_ioresourceid)));
          allPlayedContensRemoved = !playedContentsAvailable;
        }
        const changeInSlideTime = new Date(scheduleTime.endTime).getTime() != this.activityService.selectedActivity.scheduledEnd.getTime() ||
          new Date(scheduleTime.startTime).getTime() != this.activityService.selectedActivity.scheduledStart.getTime();
        if (allPlayedContensRemoved) {
          const createdOnTime = this.activityService.selectedActivity.offlineId ? new Date(parseInt(this.activityService.selectedActivity.offlineId.split(OFFLINE_ID_PREFIX)[1])) : new Date();
          this.activityService.selectedActivity.scheduledEnd = addMinutes(createdOnTime, appointmentActivity.indskr_shortcall ? 5 : this.authService.user?.meeting_duration || 30);
          this.activityService.selectedActivity.scheduledStart = createdOnTime;
        }
        else if (changeInSlideTime) {
          this.activityService.selectedActivity.scheduledEnd = new Date(scheduleTime.endTime);
          this.activityService.selectedActivity.scheduledStart = new Date(scheduleTime.startTime);
        }
        if (allPlayedContensRemoved || changeInSlideTime) {
          let payload = new UpdateMeetingPayload(
            appointmentActivity.subject,
            appointmentActivity.location,
            appointmentActivity.scheduledStart,
            appointmentActivity.scheduledEnd,
            appointmentActivity.notes
          );
          this.meetingDataService.isErrorStartDateUpdateMeeting = false;
          await this.meetingDataService.updateMeeting(appointmentActivity, payload, false, false, false, false, true);
          this.events.publish("updateMeetingDateFields");
        }
      }
    }

    /**
     * @param {Presentation} pres
     * @memberof PresentationListComponent
     */
    public async addToMeeting() {
        this.trackingService.tracking('Done from presentations',TrackingEventNames.PRESENTATIONS)
        this.presentationService.filterPresentations("");
        if(!this.isModified) {
            this.navService.popChildNavCtrlFromStack(ChildNavNames.PresentationToolsNavigation);
            this.navService.popWithPageTracking().then(()=>{
                if(this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) this.footerService.initButtons(FooterViews.Activities);
                if(this.uiService.activeView === 'Meeting') this.footerService.initButtons(FooterViews.PreviewMeeting);
            });
            return;
        }
        if (this.viewMode == PresentationView.ADDTOACCOUNTPLAN) {
          this.callbackEvent(this.meetingContent);
          this.navService.popChildNavCtrlFromStack(ChildNavNames.PresentationToolsNavigation);
          this.navService.popWithPageTracking().then(() => {
            this.footerService.initButtons(FooterViews.None);
          });
        } else if (this.isAddToMeeting && this.activityService.selectedActivity instanceof AppointmentActivity) {
            let loader = await this.loadingController.create();
            await loader.present();

            // Prevent offline for account visit
            const accountVisitRecordCheckResponse = this.activityService.accountVisitRecordCheck(this.activityService.selectedActivity);
            if (this.activityService.accountVisitOfflineCheck(
              accountVisitRecordCheckResponse, true,
            )) {
              return;
            }

            this.origContents = (this.activityService.selectedActivity.presentations ? this.activityService.selectedActivity.presentations.slice() : []);
            this.activityService.selectedActivity.presentations = this.meetingContent;
            this.presentationService.activtyPlaylist = this.meetingContent;
            if(this.deviceService.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
                try{
                    this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
                    this.presentationService.setCarouselBriefcase(this.activityService.selectedActivity.presentations);
                    if(this.isModified && this.meetingContent.length >= 1){
                      this.events.publish('contentIsAdded',this.meetingContent);
                      if(!this.presentationService.activePresentation){
                        this.presentationService.setCurrentSelectedPres(this.activityService.selectedActivity.presentations[0]);
                        const page: Page = this.presentationService.presPages && this.presentationService.presPages.length > 0 ? this.presentationService.presPages[0] : null;
                        this.presentationService.setCurrentSelectedPresPage(page);
                      }
                    }else if(this.meetingContent.length == 0){
                        this.presentationService.clearCurrentPresentation();
                    }
                    // loader.dismiss();
                    this.meetingStructureService.updateContents(this.activityService.selectedActivity.presentations);
                    await this.updateMeetingTimeAsPerContentPlayed();

                    this.navService.popChildNavCtrlFromStack(ChildNavNames.PresentationToolsNavigation);
                    this.navService.popWithPageTracking().then(()=>{
                        if(this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) this.footerService.initButtons(FooterViews.Activities);
                        if(this.uiService.activeView === 'Meeting') {
                          this.footerService.initButtons(FooterViews.PreviewMeeting);
                          this.presentationService.showNewActivity = false;
                          // this.navService.popChildNavPageWithPageTracking();
                        }
                        loader.dismiss();
                    });
                  }catch(e){
                    loader.dismiss();
                    throw new Error(e);
                  }
            } else {
                // Account visit update logic
                if (accountVisitRecordCheckResponse.isAccountVisitRecord || accountVisitRecordCheckResponse.isAccountVisitNestedMeeting) {
                  await this.meetingDataService.addRemoveContentToAccountActivity(
                    accountVisitRecordCheckResponse.isAccountVisitRecord,
                    this.origContents,
                    loader,
                  )
                  .then(() => this.postPresentationAddOnline(loader))
                  .then(() => this.updateMeetingTimeAsPerContentPlayed())
                  .catch(error => this.handlePresentationAddOnlineError(error, loader));
                  return;
                }

                this.meetingDataService.addContentToMeeting(this.activityService.selectedActivity.presentations, this.activityService.selectedActivity)
                .then(() => this.postPresentationAddOnline(loader))
                .catch((er) => {
                    this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity, false, true)
                    .then(() => loader.dismiss())
                    .catch(error => this.handlePresentationAddOnlineError(error, loader));
                });
            }
        } else if(this.isAddToMeeting && this.activityService.selectedActivity instanceof EventActivity) {
          let loader = await this.loadingController.create();
            loader.present();
            let eventPresentations:EventPresentations[] = [];
            let selEvPres:EventPresentations[] = (this.activityService.selectedActivity as EventActivity).presentations;
            if(this.meetingContent && this.meetingContent.length>0){
              this.meetingContent.forEach((pres) => {
                //if(pres instanceof Presentation){
                  eventPresentations.push({
                    id: pres.ioPresentationId,
                    name: pres.name,
                    statecode: pres.stateCode,
                    thumbnailUrl: pres.thumbnailUrl,
                    isDeleted:false,
                    downloaded: pres.downloaded
                  })
                //}
              });
              selEvPres.forEach(p => {
                if(!eventPresentations.some(ep=>p.id == ep.id)) p.isDeleted = true;
              });
              eventPresentations.forEach((ep) => {
                let idx = selEvPres.findIndex(p=> p.id == ep.id);
                if(idx>=0){
                  selEvPres[idx] = ep;
                } else {
                  selEvPres.push(ep);
                }
              });
            } else {
              if(selEvPres && selEvPres.length>0) selEvPres.forEach(pres=>{
                pres.isDeleted = true;
              });
            }
            const payload = this.eventsToolDataService.getPresentationsDTO(this.activityService.selectedActivity as EventActivity);
            let action: OperationDetail = {
              onDynamics: !this.deviceService.isOffline,
              onLocalDatabase: true,
              onLocalCopy: false,
              operationDetail: {
                code: '',
                message: '',
                payload,
              },
            };
            this.activityService.selectedActivity.modifiedOn = new Date();
            this.activityService.selectedActivity.pendingPushToDynamics = true;

            await this.eventsToolDataService.updateEventsData(action,[this.activityService.selectedActivity]).then((value:Array<EventActivity>)=>{
              this.activityService.selectedActivity = value[0];
            },(err=>{
              console.log('Error updating event activity');
            }))

            this.presentationService.activtyPlaylist = this.meetingContent;
            try{
              this.meetingStructureService.updateContents(this.meetingContent);
              this.navService.popChildNavCtrlFromStack(ChildNavNames.PresentationToolsNavigation);
              this.navService.popWithPageTracking().then(()=>{
                loader.dismiss();
              });
            }catch(e){
              loader.dismiss();
              throw new Error(e);
            }
        }

    }

    public addPresToArray(content: Presentation | Resource) {
      console.log("Adding content to meeting/event", content);
      let element: Presentation | Resource;
      const id = this.meetingDataService.getContentId(content);
      element = this.meetingContent.find(res => res['ioPresentationId'] === id ||
                                res['ioDocumentId'] === id || res['ioResourceId'] === id );
      if (!element) {
        content['isAutoLoaded'] = false;
        this.meetingContent.push(content);

      } else {
        content['isAutoLoaded'] = true;
        let index =  this.meetingContent.indexOf(content);
        this.meetingContent.splice(index, 1);
        this.events.publish('contentIsRemoved',content);
      }
      this.isDirty = this.isContentDirty();
      this.isModified = true;
      if (this.indHeaderLeftModel.controls.length > 1) {
        this.indHeaderLeftModel.controls[1].isDisabled = !this.isDirty;
      }
      this._CD.detectChanges();
      this._CD.markForCheck();
    }
    // #endregion MEETING SPECIFIC functions

    isContentDirty(): boolean {
        if(
          this.viewMode === this.presMode.ADDTOMEETING&& this.activityService.selectedActivity['presentations'] && this.meetingContent.length === this.activityService.selectedActivity['presentations'].length ){
          return this.meetingContent.some(content=>{
                    let id = this.meetingDataService.getContentId(content);
                    let found = this.activityService.selectedActivity['presentations'].some(res => res['ioPresentationId'] === id ||
                                 res['ioDocumentId'] === id || res['ioResourceId'] === id );
                    if(!found){
                      return true;
                    }
                 });
        }
        else if(this.viewMode === this.presMode.ADDTOEVENT && (this.activityService.selectedActivity as EventActivity).presentations && this.meetingContent.length === (this.activityService.selectedActivity as EventActivity).presentations.length) {
          return this.meetingContent.some(content=>{
                    let id = this.meetingDataService.getContentId(content);
                    let found = (this.activityService.selectedActivity as EventActivity).presentations.some(res => res.id === id)
                    if(!found){
                      return true;
                    }
                  });
        } else if (this.viewMode == PresentationView.ADDTOACCOUNTPLAN && this.meetingContent.length == this.selectedContentIds.length) {
          return this.meetingContent.some(content => {
            let id = this.meetingDataService.getContentId(content);
            let found = this.selectedContentIds.find(con => con['ioResourceId'] == id || con['ioPresentationId'] == id || con['ioDocumentId'] == id);
            if (!found) {
              return true;
            }
          });
        } else{
          return true;
        }
      }

    public filterByBrand(brand: string) {
        this.trackingService.tracking('PresentationProductFilter',TrackingEventNames.PRESENTATIONS)
        this.presentationService.presentation = [...this.presentationService.initialPres];
        this.presentationService.filterByBrand(brand);
    }

    public isPresAddedToMeeting(content: Presentation | Resource) {
      if(this.isAddToMeeting) {
         if(!this.meetingContent) return;
         let id = this.meetingDataService.getContentId(content);
         return  this.meetingContent.some(res => res['ioPresentationId'] === id ||
                                res['ioDocumentId'] === id || res['ioResourceId'] === id );
      }
    }

    // Select Mode
    public updateSelPresList(content: Presentation | Resource) {
        // this.presentationService.updateSelPresList(pres);
        // this.isSelectAll = false;
        let element = this.selectedPresList.find(p => p === content);
        if (!element) {
            // pres.isSelected = true;
            this.selectedPresList.push(content);
        } else {
            // pres.isSelected = false;
            let index =  this.selectedPresList.indexOf(content);
            this.selectedPresList.splice(index, 1);
        }
      this.presentationService.selPresentation = this.selectedPresList;
    }

    public hasSelectedPresList() {
        return this.selectedPresList.length > 0;
    }

    public favouriteButtonState() {
        return this.selectedPresList.some(x => x['favourite'] != true);
    }

    public downloadButtonState() {
        return this.selectedPresList.some(x => x.downloaded != true);
    }

    public selectAll(ev) {
        if(this.isSelectAll == true) {
            this.selectedPresList = [];
            if (this.filteredList.length) {
              this.filteredList.map(pres => {
                pres.isSelected = true
                this.selectedPresList.push(pres);
              });  
            } else {
              this.contents.map(pres => {
                pres.isSelected = true
                this.selectedPresList.push(pres);
              });
            }
        } else {
            this.contents.map(pres => {
                pres.isSelected = false;
            });
            this.selectedPresList = [];
        }
    }

    toggleListType(type: ListType) {
        this.updateContactToDisplay();
        this.listType = type;
        if(this.infiniteLoader && this.infiniteLoader.target)
          this.infiniteLoader.target.disabled = false;
        this._CD.detectChanges();
        this._CD.markForCheck();
    }

    public handleNewActivity(): void {
        // this.trackingService.tracking('PresentationToolsNewActivity', TrackingEventNames.PRESENTATIONS);
        this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.PresentationPageComponent, { from: 'PresentationToolsPage', pres: this.activePres })
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.resizePresThumb();
    }

    resizePresThumb() {

        // if(this.listType != 'grid') return;

        let imgHeight = 75;
        let imgWidth = 100;

        let width = (document.getElementById('pres-list-row').offsetWidth / 2) - 10;
        let height = 136 - 5;

        if(this.windowWidth == width) return;
        this.windowWidth = width;

        const scalerH = height / imgHeight;
        const scalerW = width / imgWidth;

        const scaleRatio = imgWidth / imgHeight;
        var ratio = Math.min(scalerW, scalerH);

        let mainTransform = ratio;

        if (ratio > scaleRatio) {

            this.presItemTransform.mainWrapperMarginLeft = (width - (imgWidth * scalerH)) / 2;
            this.presItemTransform.mainWrapperMarginTop = (height - (imgHeight * scalerH)) / 2;
        } else {

            this.presItemTransform.mainWrapperMarginLeft = 0;
            this.presItemTransform.mainWrapperMarginTop = (height - (imgHeight * scalerW)) / 2;
        }

        this.presItemTransform.iframeScaleWidth = imgWidth * mainTransform;
        this.presItemTransform.iframeScaleHeight = imgHeight * mainTransform;

    }

    public updateContactToDisplay() {
        this.recordCount = 0;
        this.filteredRecordCount = 0;
        if(this.searching || this.filtering) {
            this.visibleFilteredList = this.sliceSearchedPresentations(this.filteredRecordCount,20);
            this.viewPres = this.visibleFilteredList;
        } else {
            this.presToDisplay = this.slicePresentations(this.recordCount, 20);
            this.viewPres = this.presToDisplay;
        }
        try {
            if (this.listTop && !this.scrollingTop) {
              this.scrollingTop = true;
              this.listTop.scrollToTop()
                .then(res => this.scrollingTop = false)
                .catch(err => { this.scrollingTop = false; console.log("scroll error", err) });
            }
          } catch (error) {
            this.scrollingTop = false;
            console.log("scroll error", error);
          }
      if (this.resultPresentationHeader) {
        this.resultPresentationHeader.title = this.translate.instant("RESULTS") + " (" + this.filterMetadata.count + ")";
        this.resultPresentationHeader.controls[1].text = this.sortBy.text;
      }
      if (this.allPresentationHeader) {
        this.allPresentationHeader.title = this.allPresentationSectionTitle;
        this.allPresentationHeader.controls[0].text = this.sortBy.text;
      }

    }

    public doInfinite(eventDetails,event) {
      this.infiniteLoader = event;
      event.target.complete();
      if (this.searching || this.filtering) {
        this.filteredRecordCount = this.visibleFilteredList.length;
        this.visibleFilteredList.push(...this.sliceSearchedPresentations(this.filteredRecordCount, 20));
        if(this.visibleFilteredList.length === this.filteredList.length)
          event.target.disabled = true;
      } else {
        this.recordCount = this.presToDisplay.length;
        this.presToDisplay.push(...this.slicePresentations(this.recordCount, 20));
        if(this.presToDisplay.length === this.contents.length)
          event.target.disabled = true;
      }
    }

    private slicePresentations(startIndex: number, count: number) {
        return this.contents.length < startIndex + count ? this.contents.slice(startIndex) : this.contents.slice(startIndex, startIndex + count);
    }

    private sliceSearchedPresentations(startIndex: number, count: number) {
        return this.filteredList.length < startIndex + count ? this.filteredList.slice(startIndex) : this.filteredList.slice(startIndex, startIndex + count);
      };

      // start advance search functions
    @HostListener('window:click', ['$event.target'])
    onClick(targetElement: string) {
        if(this.suggestionsActive) {
            this.suggestionsActive = false
        }
        this._CD.detectChanges();
    }

    onInput(event) {
        this.searchKey = '';
        //disable search contacts if fired because we eptied the serach text programatically
        if(!this.shouldFireSearchChangeEvent){
          this.shouldFireSearchChangeEvent = true;
          return;
        }
        let params = (event.value) ? event.value : '';
        //for length > 2
        if (params.length > 2) {
          if (params.trim().length == 0) return;
          this.searching = true;
          this.searchKey = params;
          if (!this.isAddToMeeting || this.isAddToEvent)
            this.searchPresentationSuggestions(this.presentations, params, event);
          else
            this.searchContentSuggestions(this.contents, params, event);
        }
        // for length 0 to 2
        else {
            this.suggestionsData = [];
            if(!this.selectedSuggestionsData || !this.selectedSuggestionsData.length){
              this.filteredList = [];
              this.visibleFilteredList = [];
              this.searching = false;
            }
            else{
              this.searchPresentationsList();
            }
            if (!this.isAddToMeeting || this.isAddToEvent) {
              if(!this.presentationService.recentSearches || !this.presentationService.recentSearches.length){
                let searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='Presentations')
                if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
                  this.suggestionsActive = false;
                }
              }
            }
            else {
              if(!this.presentationService.contentRecentSearches || !this.presentationService.contentRecentSearches.length){
                let searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='Contents');
                if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
                  this.suggestionsActive = false;
                }
              }
            }
        }
        this.updateContactToDisplay();
        this.updateEmptyMessage()
        this._CD.detectChanges();
        this.onSearchBlur(null);
    }

    searchPresentationSuggestions(presentations: Presentation[], searchText: string, event?): Presentation[]{
          if (searchText && presentations) {
            searchText = searchText.toUpperCase();
            
            //first search categories and category values
            let value  = this.userTagService.PresentationTags.map(x=>x.indskr_name);
            this.searchConfigService.presentationsSearchIndexesConfig.find(x=>x.categoryName=="Tags" || x.categoryName == this.translate.instant("Tags")).values = value;
            let searchIndexConfig = this.searchConfigService.presentationsSearchIndexesConfig;
            this.suggestionsData = this.searchConfigService.fetchSuggestions(
                searchIndexConfig,this.selectedSuggestionsData,
                this.translate.instant("TITLE"),
                this.presentationSearchText,
                false,this.hasKeywordSearchEnabled && this.viewMode==PresentationView.MENU, this.translate.instant('PAGES')
              )
            //testing for delete
            this.suggestionsData.filter(y=>y.header).filter(y=>y.header.title=="Tags" || y.header.title == this.translate.instant("Tags")).forEach(t=>t.listData.forEach(y=>{
              let tag=this.userTagService.PresentationTags.find(x=>x.indskr_name==y.primaryTextLeft)
              if(tag){
                y.showArrow = tag.visibility == TagVisibility.PERSONAL;
                y.arrowType="indegene-ios-close-icon";
                y.tagDetails= tag;
                y.clickHandler = ((id?: string, event?: any, specificTarget?: string, tag?: any) => {
                  if (specificTarget == 'endArrow') {
                    this.deleteTag(tag.tagDetails);
                  } 
                  else if (specificTarget == 'mainItem'){
                    this.handleTagSelection(tag.tagDetails);
                  }
                })
              }  
            }));

            if(this.suggestionsData.length) this.suggestionsActive = true;
            if(!this.selectedSuggestionsData || !this.selectedSuggestionsData.length){
              this.filteredList = presentations;
            }
            const formattedSearchText = this.searchConfigService.convertFormattedString(searchText).trim();
            this.filteredList = this.filteredList.filter((prs)=>{
              let presentationString = this.getOjectValues(prs);
              return presentationString.includes(formattedSearchText);
            });
          } else {
              return [];
          }
        this.filterMetadata.count = this.filteredList.length;
        // this.visibleFilteredList = this.slicePresentations(0, this.filteredRecordCount);
        this.updateContactToDisplay();
    }

    // @ts-ignore
    searchContentSuggestions(contents: (Presentation | Resource)[], searchText: string, event?): (Presentation | Resource)[] {
      if (searchText && contents) {
        searchText = searchText.toUpperCase();
        //first search categories and category values
        let valueFromPresentationTags  = this.userTagService.PresentationTags.map(x=>x.indskr_name);
        let value = [...valueFromPresentationTags];
        if (this.viewMode === PresentationView.ADDTOMEETING) {
          let valueFromResourceTags = this.userTagService.resourceTag.map(x=>x.indskr_name);
          value = [...valueFromPresentationTags, ...valueFromResourceTags];
        }
        this.searchConfigService.contentsSearchIndexesConfig.find(x=>x.categoryName=="Tags" || x.categoryName == this.translate.instant("Tags")).values = value;

        let searchIndexConfig = this.searchConfigService.contentsSearchIndexesConfig;
        this.suggestionsData = this.searchConfigService.fetchSuggestions(
            searchIndexConfig,this.selectedSuggestionsData,
            this.translate.instant("TITLE"),this.presentationSearchText,
            false, this.hasKeywordSearchEnabled, this.translate.instant('PAGES')
          )
        
        //testing for delete
        this.suggestionsData.filter(y=>y.header).filter(y=>y.header.title=="Tags" || y.header.title == this.translate.instant("Tags")).forEach(t=>t.listData.forEach(y=>{
          let tagForPresentation = this.userTagService.PresentationTags.find(x=>x.indskr_name==y.primaryTextLeft);
          if (tagForPresentation) {
            y.showArrow = tagForPresentation.visibility == TagVisibility.PERSONAL;
            y.arrowType="indegene-ios-close-icon";
            y.tagDetails= tagForPresentation;
            y.clickHandler = ((id?: string, event?: any, specificTarget?: string, tag?: any) => {
              if (specificTarget == 'endArrow') {
                this.deleteTag(tag.tagDetails);
              } 
              else if (specificTarget == 'mainItem'){
                this.handleTagSelection(tag.tagDetails);
              }
            })
          }
          if (this.viewMode === PresentationView.ADDTOMEETING) {
            let tagForResource = this.userTagService.resourceTag.find(x=>x.indskr_name==y.primaryTextLeft);
            if (tagForResource) {
              y.showArrow = tagForResource.visibility == TagVisibility.PERSONAL;
              y.arrowType="indegene-ios-close-icon";
              y.tagDetails= tagForResource;
              y.clickHandler = ((id?: string, event?: any, specificTarget?: string, tag?: any) => {
                if (specificTarget == 'endArrow') {
                  this.deleteTag(tag.tagDetails);
                } 
                else if (specificTarget == 'mainItem'){
                  this.handleTagSelection(tag.tagDetails);
                }
              })
            }
          }
        }));

        if(this.suggestionsData.length) this.suggestionsActive = true;
        if(!this.selectedSuggestionsData || !this.selectedSuggestionsData.length){
          this.filteredList = contents;
        }
        const formattedSearchText = this.searchConfigService.convertFormattedString(searchText).trim();
        this.filteredList = this.filteredList.filter((prs)=>{
          let presentationString = this.getOjectValues(prs);
          return presentationString.includes(formattedSearchText);
        });
      } else {
        return [];
      }
      this.filterMetadata.count = this.filteredList.length;
      this.visibleFilteredList = this.slicePresentations(0, this.filteredRecordCount);
      this.updateContactToDisplay();
    }

    searchPresentationsList(isFilter?: boolean){
        //second level search for presentations
        this.searching = true;
        if (isFilter) this.filtering = true;
        else this.searching = true;
        let selectedData: SelectedSuggestionPillDataModel[] = [];
        if (this.filtering && this.searching) {
          selectedData = _.uniq(_.concat(this.selectedFilterData, this.selectedSuggestionsData));
        } else {
          selectedData = isFilter ? this.selectedFilterData : this.selectedSuggestionsData;
        }
        selectedData = this.mapForUserTag(selectedData);
        let filteredPresentations:any = (!this.isAddToMeeting||this.isAddToEvent) ? this.presentations : this.contents;
        let selectedSuggestionsDataCopy = selectedData.slice().filter(data => data.categoryName != 'tag')
        let entityLevelCharSearch = selectedSuggestionsDataCopy.find(o=> o.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH);
        const formattedSearchText = entityLevelCharSearch && entityLevelCharSearch.charSearchText ? this.searchConfigService.convertFormattedString(entityLevelCharSearch.charSearchText).toUpperCase() : '';
        const formattedSearchTextSplit = formattedSearchText ? formattedSearchText.split(" ") : '';
        if (entityLevelCharSearch && formattedSearchTextSplit) {
          formattedSearchTextSplit.forEach(searchText => {
            filteredPresentations = filteredPresentations.filter(prs => {
              let presentationEntityLevel = this.getOjectValues(prs);
              return presentationEntityLevel.includes(searchText);
            });
          });
        }
        let customEntityLevelCharSearch = selectedSuggestionsDataCopy.find(o=> o.type == SuggestionPillType.CUSTOM_ENTITY_LEVEL_CHARACTERSEARCH);
        const searchText = customEntityLevelCharSearch && customEntityLevelCharSearch.charSearchText ? this.searchConfigService.convertFormattedString(customEntityLevelCharSearch.charSearchText).toUpperCase() : '';
        if (this.hasKeywordSearchEnabled && customEntityLevelCharSearch) {
          const url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.CognitiveSearch.keywordSearch;
          const params = {
            query: searchText,
            fields: 'indskr_title,Id,indskr_positiongroupsid',
            filter:''+ this.authService.user.positionGroups?.map(o=>o.positionGroupId.toUpperCase()),
            mode: this.viewMode == PresentationView.ADDTOMEETING?'default':'indskr_iopresentation',
            resource_url: this.authService.userConfig.activeInstance.url,
            topn: '1000'
          }
          this.uiService.displayLoader();
          let newFilteredPresentations:Presentation[] = []
          this.http.get(url,{params}).toPromise().then((res)=>{
            this.uiService.dismissLoader();
            let responseData:any = res;
            responseData.value.forEach(resPres => {
              let foundMatch = filteredPresentations.find(o=> resPres.Id.toLowerCase() == o.ioPresentationId?.toLowerCase()
                                                              ||  resPres.Id.toLowerCase() == o.indskr_ioresourceid?.toLowerCase()
                                                              ||  resPres.Id.toLowerCase() == o.indskr_iodocumentid?.toLowerCase())
              if(foundMatch){
                let matches = resPres.Matches;
                matches.forEach(match=>{
                  let foundMatchCopy
                  if(foundMatch instanceof Presentation){
                    foundMatchCopy = new Presentation({});
                  }
                  else foundMatchCopy = new Resource({});
                  Object.assign(foundMatchCopy, foundMatch);
                  let key = Object.keys(match)[0]
                  foundMatchCopy.secondaryInfo = "<p><i>'"+ match[key].toString() +"'</i> found in slide "+ key +"</p>"
                  foundMatchCopy.showCustomSecInfo = true;
                  foundMatchCopy.customePageLocation =  key || '';
                  newFilteredPresentations.push(foundMatchCopy);
                })
              }
            });
            filteredPresentations = newFilteredPresentations;
            this.filteredList = filteredPresentations;
            this.filterMetadata.count = this.filteredList.length;
            this.visibleFilteredList = this.sliceSearchedPresentations(0, this.filteredRecordCount);
            this.updateContactToDisplay();
          }, (err)=>{
            filteredPresentations = newFilteredPresentations;
            this.filteredList = filteredPresentations;
            this.filterMetadata.count = this.filteredList.length;
            this.visibleFilteredList = this.sliceSearchedPresentations(0, this.filteredRecordCount);
            this.updateContactToDisplay();
            this.notificationService.notify(this.translate.instant('NETWORK_ISSUES_WHILE_COMPLETE_REQUEST'),'');
          })

        }
        else if(!this.hasKeywordSearchEnabled){

        }
        filteredPresentations = this.searchConfigService.fetchFilteredListBasedOnSuggestions(selectedSuggestionsDataCopy, filteredPresentations);
        const tagSuggestionIndex = selectedData.findIndex(data => data.categoryName === 'Tags' || data.categoryName == this.translate.instant("Tags"));
        if (tagSuggestionIndex >= 0) {
          let results = selectedData.map(x=>x.selectedFacet);
          results.forEach(id=>{
            let pres = this.presentationService.getPresentationById(id);
            if(!_.isEmpty(pres)) {
              filteredPresentations.push(pres);
            }
            if (this.viewMode == PresentationView.ADDTOMEETING) {
              const resource = this.resourceService.getResourceById(id,id);
              if(!_.isEmpty(resource)) {
                filteredPresentations.push(resource);
              }
            }
          });
        }
        this.filteredList = filteredPresentations;
        this.filterMetadata.count = this.filteredList.length;
        this.visibleFilteredList = this.sliceSearchedPresentations(0, this.filteredRecordCount);
        // const tagSuggestionIndex = selectedData.findIndex(data => data.categoryName === 'Tags');
        // if (tagSuggestionIndex >= 0) {
        //   this.selectedTagData =this.userTagService.PresentationTags.find(tag=>tag.indskr_name === selectedData[tagSuggestionIndex].selectedFacet)
        //   if (this.selectedTagData) {
        //     this.handleTagSelection(this.selectedTagData);
        //   }
        // }
        this.updateContactToDisplay();
        this.onSearchBlur(null);
    }

    getOjectValues(prsObject): string {
        let objString: string[] = [];
        let prsInclude = ['name'];
        for (let prs in prsObject) {
          if (prsInclude.indexOf(prs) > -1 && prsObject[prs]) {
            if (Array.isArray(prsObject[prs])) {
              for (let i = 0; i < prsObject[prs].length; i++) {
                objString.push(this.getOjectValues(prsObject[prs][i]));
              }
            } else {
              objString.push(prsObject[prs]);
            }
          }
        }
        return this.searchConfigService.convertFormattedString(objString.toString().toUpperCase());
    }

    clickSearchArea(ev){
        ev.stopPropagation();
        if(!this.suggestionsActive) {
          this.suggestionsActive = true;
          if (!this.isAddToMeeting || this.isAddToEvent) {
            if (!this.presentationService.recentSearches && !this.presentationService.recentSearches.length  && _.isEmpty(this.userTagService.PresentationTags)) {
              let searchToolName = this.searchConfigService.toolNames.find(tool => tool.toolName == 'Presentations')
              if (searchToolName && (this.searchConfigService.savedSearches.filter(search => search.searchToolName == searchToolName.searchToolNameID)).length == 0) {
                this.suggestionsActive = false;
              }
            }
          }
          else {
            if(!this.presentationService.contentRecentSearches || !this.presentationService.contentRecentSearches.length){
              let searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='Contents');
              if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
                this.suggestionsActive = false;
              }
            }
          }
        }
    }

    ionSearchFocus(ev) {
        try {
          let el = this.elRef.nativeElement.ownerDocument.getElementById('pre-list');
          if (el) {
            el.scrollIntoView({ behavior: 'smooth' });
          }
        } catch (error) {
          console.log("scroll error");
        }
      }

    async saveAdvancedSearch(failedText?: string){
        let saveSearchName: string
        let toolName = !this.isAddToMeeting || this.isAddToEvent ? this.searchConfigService.toolNames.find(o=>o.toolName=='Presentations') : this.searchConfigService.toolNames.find(o=>o.toolName=='Contents');
        let currentSavedSearches = this.searchConfigService.savedSearches.filter(o=> o.searchToolName==toolName.searchToolNameID);
        this.alertService.showAlert({ title:this.translate.instant('SAVE_SEARCH'),
                                      subTitle:failedText?failedText:'',
                                      cssClass:'saveSearchAlert',
                                      message:this.translate.instant('SAVE_SEARCH_POPOVER_MESSAGE'),
                                      inputs:[{type:'text', name:"name", placeholder:this.translate.instant('ENTER_NAME')}]
                                    }, this.translate.instant('SAVE'), this.translate.instant('CANCEL'))
        .then(async (pre)=>{
          if(pre.role == 'ok'){
            if(pre.data && pre.data.values.name){
              saveSearchName = pre.data.values.name.trim();
              let sameNameSearches = currentSavedSearches.filter(o=>o.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase())==0);
              let incrementNumber: number = 1;
              if(sameNameSearches.length){
                saveSearchName += ' (';
                _.each(sameNameSearches,(searchData=>{
                  if(searchData.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase()) == 0){
                    let currentIncrement = parseInt(searchData.searchName.toLowerCase().charAt(saveSearchName.length));
                    if(!isNaN(currentIncrement) && currentIncrement>=incrementNumber) incrementNumber = currentIncrement+1;
                  }
                }))
                saveSearchName+= incrementNumber+')';
              }
              this.disableSaveSearch = true;
              console.log(this.selectedFilterData);
              this.selectedFilterData.length>0?this.selectedSuggestionsData =this.selectedFilterData:"";
              await this.searchConfigDataService.saveAdvancedSearch(saveSearchName,
                                                                    toolName?toolName.searchToolNameID:'',
                                                                    UserSavedSearchTypes.OWNED,
                                                                    this.selectedSuggestionsData.slice())
            }
            else if(pre.data && pre.data.values.name == ""){
              this.saveAdvancedSearch(this.translate.instant('NAME_IS_REQUIRED'));
            }
          }
        })
      }

    removeAllSelectedSuggestions(){
        this.disableSaveSearch = true;
        this.selectedSuggestionsData = [];
        //this.content.resize();
        this.filteredList = [];
        this.visibleFilteredList = []
        this.presentationSearchText = '';
        this.shouldFireSearchChangeEvent = false;
        this.searching = false;
        this.filtering =false;
        this.contactService.isInGlobalSearch = false;
        this.showRemoveTag = false;
        this.presentationService.isSelectMode = false;
        this.updateContactToDisplay();
       // this.content.resize();
      }

    removeSelectedSuggestion(suggestion){
        this.disableSaveSearch = false;
        _.remove(this.selectedSuggestionsData,(o)=>_.isEqual(o,suggestion));
        if(suggestion.categoryName === 'tag' || suggestion.categoryName === 'Tags') {
          this.showRemoveTag = false;
          this.cancelTagSelection();
          this.presentationService.isSelectMode = false;
        }
        if(this.selectedSuggestionsData.length == 0){
          this.filteredList = [];
          this.visibleFilteredList = []
          this.presentationSearchText = '';
          this.shouldFireSearchChangeEvent = false;
          this.searching = false;
          if (this.selectedFilterData?.length > 0) {
            this.searchPresentationsList(true);
          }
        }
        else{
          this.searchPresentationsList();
        }
        this.updateContactToDisplay();
        //this.content.resize();
      }


  removefilterSelectedSuggestion(suggestion, filter) {
    this.disableSaveSearch = false;
    let selectedSuggestions = this.filterNameBubble.find((bubble) => bubble == filter).suggestion;
    _.remove(this.selectedFilterData, (o) => _.isEqual(o, suggestion));
    _.remove(this.filterNameBubble.find((bubble) => bubble == filter).suggestion, (o) => _.isEqual(o, suggestion));
    if (_.isEmpty(selectedSuggestions)) {
      let index = this.filterNameBubble.findIndex((b) => b.categoryName == filter.categoryName);
      if (index > -1) this.filterNameBubble.splice(index, 1);
    }
    _.remove(this.selectedSuggestionsData, (o) => _.isEqual(o, suggestion));
    if (this.selectedFilterData.length == 0 && this.selectedSuggestionsData?.length == 0) {
      this.filteredList = [];
      this.visibleFilteredList = []
      this.updateContactToDisplay();
      this.presentationSearchText = '';
      this.searching = false;
      this.filtering = false;
      this.shouldFireSearchChangeEvent = false;
      this.searching = false;
      this.filtering = false;
      this.presentationService.isSelectMode = false;
      this.updateContactToDisplay();
    }
    else if (this.selectedFilterData.length == 0) {
      this.filtering = false;
      this.searchPresentationsList();
    } else {
      this.searchPresentationsList(true);
    }
    this.updateContactToDisplay();
  }

    clickedInSuggestionsArea(ev){
        ev.stopPropagation();
      }

    handleFacetSelection(data:SelectedSuggestionPillDataModel){
        this.disableSaveSearch = false;
        this.selectedSuggestionsData =[];
        this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(data, this.selectedSuggestionsData)
        this.suggestionsActive = false;
        this.presentationSearchText = '';
        this.suggestionsData= [];
        this.shouldFireSearchChangeEvent = false;
        this.searchPresentationsList();
        this.selectedSuggestionsData.sort((a,b)=>{
          if(a.createdOn < b.createdOn) return 1
          else return -1
        })
        if(!data.isComingFromRecentSearch){
          if (!this.isAddToMeeting || this.isAddToEvent) {
            let recentSearches = this.presentationService.recentSearches;
            if(!recentSearches.some(recSearch=>recSearch.categoryName==data.categoryName && recSearch.selectedFacet==data.selectedFacet)){
              recentSearches.unshift(data);
              _.remove(recentSearches, (o, index)=>index>2);
              this.disk.updateOrInsert(DB_KEY_PREFIXES.PRESENTATION_RECENT_SEARCHES,(doc)=>{
                if(!doc || !doc.raw){
                  doc={
                    raw:[]
                  }
                }
                doc.raw = recentSearches;
                return doc;
              })
            }
          }
          else {
            let contentRecentSearches = this.presentationService.contentRecentSearches;
            if(!contentRecentSearches.some(recSearch=>recSearch.categoryName==data.categoryName && recSearch.selectedFacet==data.selectedFacet)){
              contentRecentSearches.unshift(data);
              _.remove(contentRecentSearches, (o, index)=>index>2);
              this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTENT_RECENT_SEARCHES,(doc)=>{
                if(!doc || !doc.raw){
                  doc={
                    raw:[]
                  }
                }
                doc.raw = contentRecentSearches;
                return doc;
              })
            }
          }
        }
        //this.content.resize();
        if(data.categoryName=="Tags" || data.categoryName == this.translate.instant("Tags")){
          // this.handleTagSelection(this.selectedTagData);
          this.suggestionsActive = false;
          this.showRemoveTag = true;
          this._selectedPresentationForTag = [];
          this.searching = true;
          this.filteredList =[];
        }
        this._CD.detectChanges();
        this._CD.markForCheck();
      }

    handleSavedSearchSelection(data){
        this.selectedSuggestionsData = [];
        if(data && data.categoryValuePairs){
          data.categoryValuePairs.forEach(catValPair=>{
            this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(catValPair, this.selectedSuggestionsData);
          })
          this.selectedSuggestionsData.sort((a,b)=>{
            if(a.createdOn < b.createdOn) return 1
            else return -1
          })
          //if(this.selectedSuggestionsData.length > 0) this.content.resize();
          this.suggestionsActive = false;
          this.presentationSearchText = '';
          this.shouldFireSearchChangeEvent = false;
          this.searchPresentationsList();
          this._CD.detectChanges();
          this._CD.markForCheck();
        }
      }

    isMobileDevice(){
        return this.deviceService.isNativeApp;
      }

  public handleTagSelection(tagData: UserTag) {
    if (!tagData) return;
    this.suggestionsActive = false;
    this.showRemoveTag = tagData.visibility == TagVisibility.PERSONAL;
    this._selectedPresentationForTag = [];
    // this.suggestionsActive = false;
    this.presentationSearchText = '';
    this.suggestionsData= [];
    this.searching = true;
    this.filteredList =[];
    tagData.entityRecords.forEach(record => {
      let presentation = this.presentationService.getPresentationById(record.id);
      if (presentation) {
        this.filteredList.push(presentation);
      }
      if (this.viewMode == PresentationView.ADDTOMEETING) {
        const resource = this.resourceService.getResourceById(record.id,record.id);
        if(!_.isEmpty(resource)) {
          this.filteredList.push(resource);
        }
      }
    });
    this.filterMetadata.count = this.filteredList.length;
    this.visibleFilteredList = this.slicePresentations(0, this.filteredRecordCount);
    this.updateContactToDisplay();
    const selectedPill: SelectedSuggestionPillDataModel = this.getTagSuggestionPill(tagData);
    //Only one tag can be filtered at a time
    this.selectedSuggestionsData = this.selectedSuggestionsData.filter(pill => pill.categoryName !== selectedPill.categoryName);
    this.selectedSuggestionsData.push(selectedPill);
    this.selectedTagData = _.cloneDeep(tagData);
  }

  private getTagSuggestionPill(tagData: UserTag): SelectedSuggestionPillDataModel {
    return {
      selectedFacet: tagData.indskr_name,
      categoryPath: 'tag',
      categoryName: 'Tags',
      categoryDisplayName: 'tag',
    };
  }

  async openFilterMenu(event) {
    this._initiateMyPresentationFilter();
  }


  private async _initiateMyPresentationFilter() {
    let options;
    const filterMenuModal = await this.modalCtrl.create({
      component: IndFilterMenuComponent,
      componentProps: {
        viewData: {
          data: options,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('FILTERS'),
          isSearchEnabled: true,
          viewMode: this.viewMode,
        },
        selectedFilter: this.selectedFilterData,
        from: PageName.PresentationPageComponent,
      },
      backdropDismiss: false
    });
    filterMenuModal.present();
    filterMenuModal.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          this.clearFilters();
          this.filtering = false;
        } else {
          this.selectedFilterData = [];
          this.filterNameBubble = [];
          this.filtering = true;
          data.selectedItems.forEach((selected) => {
            if (!this.selectedFilterData.some(o => o.categoryName == selected.categoryName && o.selectedFacet == selected.selectedFacet))
              this.selectedFilterData.unshift(selected);
          });
          this.selectedFilterData.forEach((data) => this._updateFilterNameBubble(data));
          this.suggestionsActive = false;
          (this.selectedFilterData.find(x=>x.categoryName=="Tags"))?this.disableSaveSearch=false:this.disableSaveSearch=true;
          this.searchPresentationsList(true);
          this.selectedFilterData.sort((a, b) => {
            if (a.createdOn < b.createdOn) return 1
            else return -1
          });
        }
      }
    });
  }

  
  private _updateFilterNameBubble(item) {

    let idx = this.filterNameBubble?.findIndex((bubble) => bubble.categoryName == item.categoryName);
    if (idx > -1) this.filterNameBubble[idx].suggestion.push(item);
    else {
      let selected = { categoryName: item.categoryName, suggestion: [item], isSavedFilter: false, savedFilterName: '' };
        if(item.isSavedFilter) {
          selected.isSavedFilter = true;
          selected.savedFilterName = item.savedFilterName;
        }
      this.filterNameBubble.push(selected);
    }

  }
  cancelTagSelection(){
    this.selectedPresList =[];
    this.selectMode();
  }
  createNewTag(failedText?: string) {
    this.alertService.showAlert({
      title: this.translate.instant("CREATE_TAG"),
      subTitle: failedText ? failedText : "",
      cssClass:'saveSearchAlert',
      inputs: [{ type: "text", name: "name", placeholder: this.translate.instant("ENTER_TAG_NAME") }]
    }, this.translate.instant("CREATE"), this.translate.instant("CANCEL"))
      .then(res => {
        if (res.role == "ok") {
          if (res.data && res.data.values.name) {
            let selectedPresentationtag = this.selectedPresList;
            // console.log("createNewTagAlert res", res, selectedContactsForTag);
            const selectedAccounts = selectedPresentationtag.map(account => {
              return new EntityTag(account["ioPresentationId"]);
            });
            let payload = new
              UserTag(null, null, false,
                res.data.values.name, selectedAccounts,
                false, TagStateCode.Active, TagEntityType.PRESENTATION,TagVisibility.PERSONAL);
            this.userTagService.createOrUpdateTag(payload, true).then((reponse) => {
              if(reponse.length) {
                payload.indskr_usertagid = reponse[0].indskr_usertagid;
                payload.indskr_externalid = reponse[0].indskr_externalid;
              }
              // this.presentationService.preTags.push(payload);
              // this.presentationService.preTags =  _.sortBy(this.presentationService.preTags , "indskr_name")
              this.userTagService.PresentationTags.push(payload);
              this.userTagService.PresentationTags =  _.sortBy(this.userTagService.PresentationTags , "indskr_name")
              this.mapPresentationToNewTag(payload);
              // this.searchConfigService.presentationsSearchIndexesConfig.find(config=> config.categoryName == 'Tags').values.push(payload.indskr_name);
              this.cancelTagSelection();
              console.log("createNewTagAlert response", reponse);
            }).catch(error => {
              console.log("createNewTagAlert error ", error);
            });
            console.log(payload);
          } else {
            this.createNewTag(this.translate.instant("TAG_NAME_IS_REQUIRED"));
          }
        }
      });
  }
  mapPresentationToNewTag(payload:UserTag){
    payload.entityRecords.forEach(x=>{
      if( this.presentationService.presentation.find(v=>v.ioPresentationId==x.id))
      this.presentationService.presentation.find(v=>v.ioPresentationId==x.id).userTag.push(payload);
    });
    this.presentations = this.presentationService.presentation;
  }
  updateTagWithSelectedPres(tagData: UserTag) {
    // let selectedContactsForTag = this.contactsToDisplay.filter((contact) => contact.isSelectedForTag);
    // if(this.slicedSearchedContactsToDisplay?.length) {
    //   selectedContactsForTag = this.slicedSearchedContactsToDisplay.filter((contact) => contact.isSelectedForTag);
    // }
    let selectedPresentationtag = this.selectedPresList;
    const selectedContacts = selectedPresentationtag.map(contact => {
      return new EntityTag(contact["ioPresentationId"]);
    });
    let differenceBySelectedContacts = _.differenceBy(selectedContacts, tagData.entityRecords, "id");
    tagData.entityRecords.push(...differenceBySelectedContacts);
    this.presentationService.createOrUpdatePresTag(tagData).then((response) => {
     // this.handleTagSelection(tagData);
     this.userTagService.PresentationTags.find(tag => tag.indskr_usertagid === tagData.indskr_usertagid).entityRecords = tagData.entityRecords;
     this.updatePresentationToTag(response,tagData);
     this.cancelTagSelection();
    }).catch((error) => {
      console.log("updateTagWithSelectedContacts error", error);
    })

  }
  async addToTag() {
    let filteredTagData: UserTag[] = !_.isEmpty(this.userTagService.PresentationTags) ? _.cloneDeep(this.userTagService.PresentationTags).filter(tag => tag.visibility == TagVisibility.PERSONAL) : [];
    let tagDataForModifiedTagFlag: UserTag[] = !_.isEmpty(this.userTagService.PresentationTags) ? _.cloneDeep(this.userTagService.PresentationTags).filter(tag => tag.allowUserToModifytag == true) : [];
    filteredTagData = _.concat(filteredTagData,tagDataForModifiedTagFlag);
    if (!_.isEmpty(filteredTagData)) {
      filteredTagData.sort((a, b): number => {
        if (a.indskr_name > b.indskr_name) return 1;
        if (a.indskr_name < b.indskr_name) return -1;
        return 0;
      });
    }
    
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('PRESENTATION_TAGS'),
      dividerTitle: this.translate.instant('ALL_PRESENTATION_TAGS'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      hideAllItemsList: false,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handlePresentationTagsComponentEvent(data, eventTarget),
      searchHandler: (text: string) => this._handlePresentationTagsSearch(text, filteredTagData),
      data: filteredTagData.map(tag => {
        let isSelected = false;
        return {
          id: tag.indskr_usertagid,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: true,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: tag.indskr_name,
          showArrow: false,
          arrowType: '',
        };
      }),
    };

    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isGroupedView: true }, PageName.MainToolTemplateComponent);
  }

  private _handlePresentationTagsSearch(text: string, presentaionTags): string[] {
    let ids: Array<string> = [];
    if (text.length >= 1) {
      ids = presentaionTags && presentaionTags.filter(tag => {
        return tag.indskr_name.trim().toLowerCase().includes(text.trim().toLowerCase());
      }).map(tag => tag.indskr_usertagid);
    } else {
      ids = presentaionTags && presentaionTags.map(tag => tag.indskr_usertagid);
    }
    return ids;
  }

  private async _handlePresentationTagsComponentEvent(data: any, eventTarget: string) {
    if (!eventTarget || eventTarget !== 'RightHeaderButtonClick' || !data.selectedItems || data.selectedItems.length === 0) return;
    const selectedTag = this.userTagService.PresentationTags.find((tag) => tag.indskr_usertagid === data.selectedItems[0].id);
    if (!selectedTag) return;
    this.updateTagWithSelectedPres(selectedTag);
  }

  updatePresentationToTag(payload,tagData:UserTag){
    
    this.selectedPresList.forEach(x=>{
      this.presentationService.presentation.find(y=>y.ioPresentationId == x['ioPresentationId']).userTag.push(tagData);
    })

    // payload.forEach(x=>{
    //   if( this.presentationService.presentation.find(v=>v.ioPresentationId==x.indskr_usertagid))
    //   this.presentationService.presentation.find(v=>v.ioPresentationId==x.indskr_usertagid).userTag.push(tagData);
    // });
    this.presentations = this.presentationService.presentation;
  }

  removeResourceFromTag(selectedTagData){
    if (!_.isEmpty(selectedTagData) && selectedTagData.indskr_usertagid) {
      if (selectedTagData.visibility != TagVisibility.PERSONAL) return;
      this.selectedPresList.forEach(resource => {
        let record
        if (resource["contentId"]) {
          record = selectedTagData.entityRecords.find(pres => pres.id === resource["contentId"]);
        } else if (resource["ioPresentationId"]) {
          record = selectedTagData.entityRecords.find(pres => pres.id === resource["ioPresentationId"]);
        }
        if (record) {
          record.deleted = resource.isSelected;
        }
      });

      this.userTagService.createOrUpdateTag(selectedTagData).then((reponse) => {
        if (this.selectedPresList.length) {
          selectedTagData.entityRecords = selectedTagData.entityRecords.filter(record => !record.deleted);
        }
        let tag = this.userTagService.PresentationTags.find(tag => tag.indskr_usertagid === selectedTagData.indskr_usertagid);
        if (tag) {
          tag.entityRecords = selectedTagData.entityRecords;
        }
        this.updateTagwithPresentatinForRemove(selectedTagData);
        this.cancelTagSelection();
        this.removeAllSelectedSuggestions();
        this.handleTagSelection(selectedTagData);
        this.showPreview(null,true);
      }).catch((error) => {
        console.log(" removeFromTag error", error);
      })
    }
  }
  updateTagwithPresentatinForRemove(tag){
    this.selectedPresList.forEach(x=>{
      let selectedPrs = this.presentationService.presentation.find(y=>y.ioPresentationId == x['ioPresentationId'])
      let indx = selectedPrs.userTag.findIndex(t=>t.indskr_usertagid==tag.indskr_usertagid);
      selectedPrs.userTag.splice(indx,1);
    });
    this.presentations = this.presentationService.presentation;
  }
  deleteTag(userTagDetails){
    console.log(userTagDetails);
    this.alertService.showAlert({
      title: this.translate.instant('DELETE_TAG'),
      cssClass: 'saveSearchAlert',
      message: this.translate.instant('TAG_DELETE_CONFIRMATION'),
    }, this.translate.instant('DELETE'), this.translate.instant('CANCEL'))
      .then(async (res) => {
        if (res.role == 'ok') {
          if(userTagDetails.entity && userTagDetails.entity == TagEntityType.PRESENTATION){
            let deleteTagPayload = new UserTag(userTagDetails.indskr_externalid, userTagDetails.indskr_usertagid, true, userTagDetails.indskr_name, [],true,TagStateCode.InActive,TagEntityType.PRESENTATION,'');
            this.userTagService.deleteTag(deleteTagPayload);
          }
          if(userTagDetails.entity && userTagDetails.entity == TagEntityType.RESOURCE){
            let deleteTagPayload = new UserTag(userTagDetails.indskr_externalid, userTagDetails.indskr_usertagid, true, userTagDetails.indskr_name, [],true,TagStateCode.InActive,TagEntityType.RESOURCE,'');
            this.userTagService.deleteTag(deleteTagPayload);
          }  
        }
      });
  }
  removeTagFromPres(tag){
    let level1Keys = ['userTag.indskr_usertagid']
    var options1 = {
      keys: level1Keys,
      threshold: 0.1,
      includeMatches: true,
      includeScore: true,
      shouldSort: true,
      distance:1000
    }
    let searchText = tag.indskr_externalid;
    let newFuse = new fuse(this.presentations,options1);
    let results = newFuse.search(searchText);
    let actualResult = []
    results.forEach(x=>actualResult.push(x.item));
    actualResult.forEach(x=>{
      let selectedPrs = this.presentationService.presentation.find(y=>y.ioPresentationId == x['ioPresentationId'])
      let indx = selectedPrs.userTag.findIndex(t=>t.indskr_usertagid==tag.indskr_usertagid);
      selectedPrs.userTag.splice(indx,1);
    });
    this.presentations = this.presentationService.presentation;
    
  }
  mapForUserTag(selectedData){
    let tagIds=[];
    let copySelectedData : SelectedSuggestionPillDataModel[] = [];
    copySelectedData= selectedData.filter(x=>x.categoryName!="Tags"|| x.categoryName != this.translate.instant("Tags") );
    let tagData=selectedData.filter(x=>x.categoryName=="Tags" || x.categoryName == this.translate.instant("Tags"))
    tagData.forEach(c=> {
      if (!this.isAddToMeeting || this.isAddToEvent) {
        const foundTag =this.userTagService.PresentationTags.find(v=>v.indskr_name==c.selectedFacet)?.entityRecords;
        if(foundTag) tagIds.push(foundTag);
      } else {
        const foundTagForPresentation = this.userTagService.PresentationTags.find(v=>v.indskr_name==c.selectedFacet)?.entityRecords;
        if (foundTagForPresentation) tagIds.push(foundTagForPresentation);
        const foundTagForResource = this.userTagService.resourceTag.find(v=>v.indskr_name==c.selectedFacet)?.entityRecords;
        if (foundTagForResource) tagIds.push(foundTagForResource);
      }
    });
    let contactIds=tagIds.flat().map(c=>c.id);
    contactIds = [...new Set(contactIds)];
    if(contactIds.length<=0){
      return selectedData;
    }
    contactIds.forEach(x=>{
     let  data : SelectedSuggestionPillDataModel = {
       selectedFacet: x,
       categoryPath: "accountid",
       categoryName: "Tags",
       categoryDisplayName: "Tags"
     } ;
     data.isSubCategory = false
     copySelectedData.push(data);
    })
    return copySelectedData;
  }
  onSearchBlur(event){
    if(this.presentationSearchText && this.presentationSearchText.length>0){
      this.hideFilterButton = true;
    }
    else{
      this.hideFilterButton = false;
    }
  }

  getFilterButtonBadgeCount() {
    return this.selectedFilterData?.length;
  }
  onDefaultFilterLoad(){    
    let toolName = this.searchConfigService.toolNames.find(o=>o.toolName=='Presentations');
    let search = this.searchConfigService.savedSearches.filter(x=>x.searchToolName == toolName.searchToolNameID ).find(x=>x.indskr_setfilter == true);         
    if(search){
      this.selectedFilterData = [];
      this.filterNameBubble = [];
      this.filtering = true;
      this.selectedFilterData = search.categoryValuePairs;
      this.selectedFilterData.forEach((data) => this._updateFilterNameBubble(data));
      this.searchPresentationsList();
    }
  }

  public onClickEnter(ev){
    if(ev){
      this.suggestionsActive = false;
    }
  }
    
}
