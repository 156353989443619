import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';


export enum AlertInfoTypes {
  LIST,
  NOTIFICATION_MESSAGE
}

export interface AlertButtons {
  label: string,
  role: string
}

@Component({
  selector: 'app-omni-info-alert',
  templateUrl: './omni-info-alert.component.html',
  styleUrls: ['./omni-info-alert.component.scss'],
})
export class OmniInfoAlertComponent implements OnInit {
  @Input() header: string;
  @Input() topLabel: string;
  @Input() topValue: string;
  @Input() bottomLabel : string;
  @Input() bottomValue:string;
  @Input() alertType : AlertInfoTypes = AlertInfoTypes.LIST;
  @Input() notificationMessage : string;
  @Input() buttons : AlertButtons[] = [];
  @Input() note:string;
  @Input() notificationIcon:string;

  constructor(
    public modalController: ModalController
  ) { }

  ngOnInit() { }

  onButtonClick(button) {
    this.modalController.dismiss(button);
  }

}
