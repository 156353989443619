import { Activity, ActivityType } from './activity.class';
import { addMinutes, getDate, isFuture, format, isValid, isBefore } from 'date-fns';
import { Contact } from '../contact/contact.class';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { IONote } from '../io/io-note.class';
import { Sort } from '../presentation/presentation.class';
import * as _ from 'lodash'

export enum FOLLOW_UP_ACTIVITY_TYPE {
    OPEN = 'Open',
    COMPLETED = "COMPLETED",
    CANCELED = "CANCELED",
    SCHEDULED = "Scheduled"
}

export enum FOLLOW_UP_TYPE {
  ACCOUNT_PLAN = "548910000",
  SCIENTIFIC_PLAN = "548910001",
  OPPORTUNITY_TASK = "548910002",
  NONE = "548910003",
  MEETINGTASK = "548910004",
  MARKETING_BUSINESS_PLAN= "548910005",
  EVENT_TASK= "548910006",
  SUGGESTED = "548910007"
}

export enum FOLLOW_UP_PRIORITY {
  LOW = 548910000,
  MEDIUM = 548910001,
  HIGH = 548910002
}

export enum FOLLOWUP_ACTION_TYPE_STATUS {
  ACTIVE = 1,
  INACTIVE = 2
  }

  export interface FollowUpObjective {
    followUpObjectiveId: string;
    // indskr_name: string
    followUpObjectiveName : string
  }

export class FollowUpActivity extends Activity {

    public offlineId:string;
    public subject:string;
    //public dueDate:Date;
    public assignedTo:Array<TaskUser> = [];
    //public contacts:Array<Contact>;
    public contactId?:string;
    public contactNameString?:string;
    public followUpNotes:Array<IONote> = [];
    public ownerNameString:string;
    public pendingPushToDynamics:boolean;
    //public formattedDueDate: any;
    public planType: string;
    public followupActionTypeID: string;
    public followupActionTypeName: string;
    public reasonForCancellation?:string;

    //Priority
    public priorityID: number;
    public priorityValue: string;

    // ACCOUNT SPECIFIC
    public accountId?:string;
    public accountNameString?:string;
    public accountPlanId?:string;
    public accountPlanNameString?:string;

    //Marketing plan specific
    public marketingPlanId?:string;
    public marketingPlanNameString?:string;

    // SAP SPECIFIC
    public scientificPlanId?:string;
    public scientificPlanNameString?:string;

    //OPPORTUNITY SPECIFIC
    public opportunityId?: string
    public opportunityIdNameString?: string
    public offlineOpportunityId?: string;
    public assignmentHistory: AssignmentHistory[];
    public appointmentId: string;
    public offlineMeetingId: string;
    public appointmentSubject: string;

    // Event
    public indskr_event?: string;
    public indskr_eventname?: string;

    objectives : FollowUpObjective[];
    public indskr_reason: string;

  constructor(raw: any) {
    super(raw);
    if (!this.ID) {
      this.ID = raw['offlineId'];
    }
    this.products = [];
    this.offlineId = (raw['offlineId']) ? raw['offlineId'] : '';
    this.subject = (raw['subject']) ? raw['subject'] : '';
    //this.dueDate = (raw['dueDate']) ? new Date(raw['dueDate']) : null;
    //this.formattedDueDate = this.scheduledEnd? format(this.scheduledEnd, 'MM/DD/YY') : '';
    this.assignedTo = (raw['assignedTo']) ? raw['assignedTo'] : [];
    this.ownerNameString = (raw['ownerName']) ? raw['ownerName'] : '';
    this.planType = (raw['indskr_plantype']) ? raw['indskr_plantype'] : '';

    this.followupActionTypeID = raw['indskr_followupactiontype'];
    this.followupActionTypeName = raw['indskr_followupactiontypename'];

    this.priorityID = raw['indskr_priority'];
    this.priorityValue = raw['priorityValue'];
    this.indskr_reason = raw['indskr_reason'];

    this.reasonForCancellation = raw['reasonForCancellation'] || raw['indskr_reasonforcancellation'] || '';
    this.objectives = _.orderBy(raw['objectives'], 'followUpObjectiveName') || [];

    if (Array.isArray(raw['taskUsersList']) && raw['taskUsersList'].length > 0) {
      raw['taskUsersList'].forEach(user => {
        if (!(user['statecode'] && user['statuscode'] && parseInt(user['statecode']) == 1 && parseInt(user['statuscode']) == 2))
          this.assignedTo.push(new TaskUser(user));
      });
    }

    this.contactId = (raw['indskr_contact'] ? raw['indskr_contact'] : '');
    this.contactNameString = (raw['contactName']) ? raw['contactName'] : '';

    if (Array.isArray(raw['notes']) && raw['notes'].length > 0) {
      raw['notes'].forEach(note => {

        if (note.hasOwnProperty('filesize') && note['filesize'] > 0) { // check if File size is greter than 0 then it has valid document
          note['isdocument'] = true;
        }

        let filteredNotes = this.followUpNotes.filter(noteFromObject => noteFromObject.noteId === note['annotationid'])
        //Some how duplicate notes are coming from service, So filter out the notes which are already present in teh activity.
        if (_.isEmpty(filteredNotes))
          this.followUpNotes.push(new IONote(note));
      });
    }

    if (Array.isArray(raw['products']) && raw['products'].length > 0) {
      raw['products'].forEach(product => {
        this.products.push({productID: product['productId'], productName: product['productName'], taskProductID:product['indskr_taskproductid'] || ''});
      });

      this.products = this.products.sort((a, b) => {
        if (a.productName.toLowerCase() > b.productName.toLowerCase()) {
          return 1;
        }
        if (b.productName.toLowerCase() > a.productName.toLowerCase()) {
          return -1;
        }
      });
    }

    if (raw['pendingPushToDynamics']) {
      this.pendingPushToDynamics = raw['pendingPushToDynamics'];
    }

    // ACCOUNT PLAN SPECIFIC
    this.accountId = (raw['indskr_account']) ? raw['indskr_account'] : '';
    this.accountNameString = (raw['accountName']) ? raw['accountName'] : '';
    this.accountPlanId = (raw['indskr_accountplan']) ? raw['indskr_accountplan'] : '';
    this.accountPlanNameString = (raw['accountplanName']) ? raw['accountplanName'] : '';

    // Marketing Plan SPECIFIC
    this.marketingPlanId = (raw['indskr_marketingbusinessplan']) ? raw['indskr_marketingbusinessplan'] : '';
    this.marketingPlanNameString = raw['marketingPlanName'] || raw['marketingPlanName'] || '';

    // SAP SPECIFIC
    this.scientificPlanId = (raw['indskr_scientificplan']) ? raw['indskr_scientificplan'] : '';
    this.scientificPlanNameString = raw['scientifiPlanName'] || raw['scientifiPlanName'] || '';

    //OPPORTUNITY SPECIFIC
    this.opportunityId = raw['opportunityid'] || ''
    this.opportunityIdNameString = raw['opportunityName'] || ''
    this.offlineOpportunityId = this.opportunityId.indexOf('offline') > -1 ? this.opportunityId : '';
    this.assignmentHistory = [];
    if (raw['assignmentHistory']) {
      raw['assignmentHistory'].forEach(r => {
        this.assignmentHistory.push(new AssignmentHistory(r));
      })
    }

    this.appointmentId = raw['indskr_appoinmentid'] || '';
    this.appointmentSubject = raw['appointmentSubject'] || '';
    this.offlineMeetingId = raw['offlineMeetingId'] || '';

    this.indskr_event = raw['indskr_event'] || undefined;
    this.indskr_eventname = raw['indskr_eventname'] || undefined;
  }

    public get offlineDBId():string {
        if(this.offlineId){
            return DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY + this.offlineId;
        }else{
            return DB_KEY_PREFIXES.FOLLOW_UP_ACTIVITY + this.ID;
        }
    }

    public get serviceDTO():any {
        let payload =  {
            offlineId: this.offlineId
        };
        if(!this.ID.includes('offline')){
            payload['activityid'] = this.ID;
        }
        if(this.subject){
            payload['subject'] = this.subject;
        }
        if(this.status){
            payload['statuscode'] = this.status;
        }
        if(this.state){
            payload['statecode'] = this.state;
        }
        if(isValid(this.scheduledStart)){
            payload['scheduledstart'] = '' + this.scheduledStart.getTime();
        }
        if(isValid(this.scheduledEnd)){
            payload['scheduledend'] = '' + this.scheduledEnd.getTime();
        }
        if(this.ownerId){
            payload['ownerid'] =this.ownerId;
        }
        if(this.contactId){
            payload['indskr_contact'] =this.contactId;
        }
        // if(this.accountId){
        //     payload['indskr_account'] =this.accountId;
        // }

        if(this.planType){
            payload['indskr_plantype'] = this.planType;
        }

        if (this.followupActionTypeID) {
          payload['indskr_followupactiontype'] = this.followupActionTypeID
        }

        // if (this.followupActionTypeName) {
        //   payload['indskr_followupactiontypename'] = this.followupActionTypeName
        // }

        if (this.priorityID) {
          payload['indskr_priority'] = this.priorityID
        }

        if(this.reasonForCancellation){
          payload['indskr_reasonforcancellation'] = this.reasonForCancellation;
        }

        if(this.appointmentId && !this.appointmentId.includes('offline')){
          payload['appointmentId'] = this.appointmentId;
        }

        if(this.offlineMeetingId){
          payload['offlineMeetingId'] = this.offlineMeetingId;
        }
        payload['indskr_account'] = (this.accountId) ? this.accountId : '';
        if (this.planType == FOLLOW_UP_TYPE.ACCOUNT_PLAN) {
          payload['indskr_accountplan'] = (this.accountPlanId) ? this.accountPlanId : '';
        } else if (this.planType == FOLLOW_UP_TYPE.SCIENTIFIC_PLAN) {
          payload['indskr_scientificplan'] = (this.scientificPlanId) ? this.scientificPlanId : '';
        }
        else if (this.planType == FOLLOW_UP_TYPE.OPPORTUNITY_TASK) {
          // payload['indskr_account'] = (this.accountId) ? this.accountId : '';
          payload['indskr_accountplan'] = (this.accountPlanId) ? this.accountPlanId : '';
          payload['opportunityid'] = (this.opportunityId) ? (this.opportunityId.indexOf('offline') > -1 ? "indskr_externalid='" + this.opportunityId + "',indskr_externalsource='" + this.opportunityId + "'" : this.opportunityId) : '';
        } else if (this.planType == FOLLOW_UP_TYPE.MARKETING_BUSINESS_PLAN) {
          // payload['indskr_account'] = this.accountId ? this.accountId : '';
          payload['indskr_marketingbusinessplan'] = this.marketingPlanId ? this.marketingPlanId : '';
        }else if(this.planType == FOLLOW_UP_TYPE.MEETINGTASK){
          // payload['indskr_account'] = this.accountId ? this.accountId : '';
        }


        if (this.products && !_.isEmpty(this.products)) {

          payload['products'] = this.products.map(product => {
                return {
                    productId: product.productID
                };
            })
        } else {
          payload['products'] = [];
        }

        if (this.indskr_event) {
          payload['indskr_event'] = this.indskr_event;
        }

        if(this.assignedTo && this.assignedTo.length > 0) {
            payload['taskUsers'] = this.assignedTo.map(user => {
                return {
                    indskr_user: user.userId,
                    overriddencreatedon: user.overriddencreatedon
                };
            })
        }else{
            payload['taskUsers'] = [];
        }
      if (this.followUpNotes && !_.isEmpty(this.followUpNotes)) {
        let payloadNotes = [];
        this.followUpNotes.map(note => {
          // if (note.noteId && note.noteId.includes('offline') || note.pendingPushForDynamics) {
            payloadNotes = this.getNotesPayload(note, payloadNotes);
          // }
        });
        payload['notes'] = payloadNotes;
      }

      if (this.objectives.length) {
        payload['objectives'] = this.objectives.map((item) => item['followUpObjectiveId']);
      }else{
        payload['objectives'] = [];
      }


        return payload;
    }

  private getNotesPayload(note: IONote, payloadNotes: any[], forOffline: boolean = false) {

    let documentNote
    if (note.hasDocument) {
      documentNote = this._getNotesWithDocumentJSON(note);
      if (forOffline) {
        documentNote['isdocument'] = true;
      }
    } else {
      documentNote = this._getNotesWithoutDocument(note)
    }

    if (forOffline) {
      documentNote['ownerName'] = note.ownerName;
      documentNote['annotationid'] = note.noteId;
      documentNote['pendingPushForDynamics'] = note.pendingPushForDynamics;
    }

    documentNote['createdon'] = `${note.createdTime.getTime()}`;

    if (note.isDeleted) {
      if (note.noteId && !note.noteId.includes('offline')){
        payloadNotes.push(documentNote);
      }
    } else {
      payloadNotes.push(documentNote);
    }

    return payloadNotes;
  }

  private _getNotesWithoutDocument(note: IONote): any {

    let noteWithoutFilePayload = {
      notetext: note.noteText,
      annotationid: note.noteId && !note.noteId.includes('offline') ? note.noteId : null,
      ownerid: note.ownerId,
      ownerName: note.ownerName,
      deleted: note.isDeleted,
      filesize: 0,
      mimetype: '',
      documentbody: '',
      filename: '',
    }
    return noteWithoutFilePayload;
  }

  private _getNotesWithDocumentJSON(note: IONote): any {

    let noteWithFilePayload = {
      notetext: note.noteText,
      ownerid: note.ownerId,
      ownerName: note.ownerName,
      deleted: note.isDeleted,
      filename: note.documentName,
      filesize: note.documentSize,
      mimetype: note.documentMimeType,
      annotationid: note.noteId && !note.noteId.includes('offline') ? note.noteId : null
    }

    if (note.pendingPushForDynamics) {
      if (!_.isEmpty(note.documentDataURL)) {
        noteWithFilePayload['documentbody'] = note.documentDataURL
      }
    }
    return noteWithFilePayload;
  }

  public get offlineDataDTO(): any {

    let payloadNotes = [];

    if (this.followUpNotes && !_.isEmpty(this.followUpNotes)) {
      this.followUpNotes.map(note => {
        payloadNotes = this.getNotesPayload(note, payloadNotes, true);
      });
    }

    return {
      activityid: this.ID,
      offlineId: this.offlineId,
      pendingPushToDynamics: this.pendingPushToDynamics,
      statecode: this.state,
      statuscode: this.status,
      subject: this.subject,
      ownerid: this.ownerId,
      ownerName: this.ownerNameString,
      indskr_account: this.accountId,
      accountName: this.accountNameString,
      //duedate: '' + (this.dueDate ? this.dueDate.getTime() : ''),
      indskr_accountplan: this.accountPlanId,
      indskr_plantype: this.planType,
      opportunityid: this.opportunityId,
      opportunityName: this.opportunityIdNameString,
      offlineOpportunityId: this.offlineOpportunityId,
      indskr_scientificplan: this.scientificPlanId || "",
      scientifiPlanName: this.scientificPlanNameString,
      accountplanName: this.accountPlanNameString,
      activitytypecode: this.type,
      scheduledstart: '' + (this.scheduledStart ? this.scheduledStart.getTime() : ''),
      scheduledend: '' + (this.scheduledEnd ? this.scheduledEnd.getTime() : ''),
      actualstart: '',
      actualend: '',
      indskr_contact: this.contactId,
      contactName: this.contactNameString,
      indskr_priority: this.priorityID ? this.priorityID : null,
      indskr_followupactiontype: this.followupActionTypeID ? this.followupActionTypeID : null,
      indskr_followupactiontypename: this.followupActionTypeName,
      priorityValue:this.priorityValue,
      appointmentId:this.appointmentId,
      appointmentSubject: this.appointmentSubject,
      offlineMeetingId: this.offlineMeetingId,
      notes: payloadNotes,
     reasonForCancellation: this.reasonForCancellation ? this.reasonForCancellation : null,
     indskr_marketingbusinessplan: this.marketingPlanId,
     indskr_reason: this.indskr_reason,
     marketingPlanName: this.marketingPlanNameString,
     objectives: this.objectives,
     modifiedon :this.modified,
      taskUsersList: this.assignedTo.map(user => {
        return {
          indskr_user: user.userId,
          userName: user.userFullName,
          activityid: user.activityId,
          indskr_taskassignmentid: user.taskAssignmentId,
          ownerId: user.ownerId
        };
      }),
      products: this.products.map((product) => {
        return { productId: product.productID, productName: product.productName };
      }),
      assignmentHistory: this.assignmentHistory.map(r => {
        return {
          createdon: r.createdon,
          users: r.users
        }
      }),
      indskr_event: this.indskr_event,
      indskr_eventname: this.indskr_eventname,
    };
  }
}

export class  TaskUser {

    public taskAssignmentId:string;
    public activityId:string;
    public userId:string;
    public userFullName:string;
    public ownerId: string;
    public overriddencreatedon: string;

    constructor(raw:any){
        this.taskAssignmentId = (raw['indskr_taskassignmentid']) ? raw['indskr_taskassignmentid'] : '';
        this.activityId = (raw['activityid']) ? raw['activityid'] : '';
        this.userId = (!_.isEmpty(raw['indskr_user']) ? raw['indskr_user'] : (raw['userId'] ? raw['userId'] : ''));
        this.userFullName = (!_.isEmpty(raw['userName']) ? raw['userName'] : (raw['userFullName'] ? raw['userFullName'] : ''));
        this.ownerId = raw['ownerId'];
        this.overriddencreatedon = raw['overriddencreatedon'];
    }
}

export class AssignmentHistory {
  public createdon: string;
  public users: TaskUser[];
  constructor(raw) {
    this.createdon = raw['createdon'];
    this.users = [];
    if (raw['users']) {
      raw['users'].forEach(r => {
        this.users.push(new TaskUser(r))
      })
    }
  }
}

export class FollowupActionType {
  followupActionTypeID: string;
  name: string;
  statusCode: number;
  status: string; //1:active  2 :Inactive
  planTypeID: number;
  plantypeName: string;
  indskr_reason: string;
  constructor(raw: any) {
    this.followupActionTypeID = raw['indskr_followupactiontypeid'];
    this.name = raw['indskr_name'];
    this.status = raw['statuscode_Formatted'];
    this.statusCode = raw['statuscode'];
    this.planTypeID = raw['indskr_plantype'];
    this.plantypeName = raw['indskr_plantype_Formatted'];
    this.indskr_reason = raw['indskr_indskr_reason']
  }
}
  export interface FollowupActionStatusReason {
    name: string;
    statusCode: number;
    stateCode: number;
  }
