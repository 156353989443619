import { TranslateService } from '@ngx-translate/core';
import { Injectable, NgZone } from '@angular/core';
import Stomp from '@stomp/stompjs';
import { Activity } from '../../classes/activity/activity.class';
import { AppointmentActivity } from '../../classes/activity/appointment.activity.class';
import { Contact, ContactMeetingState } from '../../classes/contact/contact.class';
import { ActivityPresentationSlide } from '../../classes/presentation/activity-presentation-slide.class';
import { ActivityPresentation } from '../../classes/presentation/activity-presentation.class';
import { Page, Presentation } from '../../classes/presentation/presentation.class';
import { Endpoints } from '../../../config/endpoints.config';
import { InboundMeetingRequestWebsocketPayload } from '../../models/inbound-meeing-request-data-model';
import { ActivityService } from '../../services/activity/activity.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ContactOfflineService } from '../../services/contact/contact.service';
import { DeviceService } from '../../services/device/device.service';
import { InboundMeetingControllerService } from '../../services/meeting/inbound-meeting.controller.service';
import { NotificationService } from '../../services/notification/notification.service';
import { PresentationService } from '../../services/presentation/presentation.service';
import { InboundMeetingDataService } from '../meeting/inbound-meeting.data.service';
import { MeetingDataService } from '../meeting/meeting.data.service';
import { RepServices } from '../rep/rep.services';
import { ActivityPresentationSlideContact } from './../../classes/presentation/activity-slide-contacts.class';
import { Resource } from './../../classes/resource/resource.class';
import { EventsService } from './../../services/events/events.service';
import { SENTIMENT } from './payloads/sentiment.payload';
import { PushResourcePayload } from './push-resource-payload-websocket';
import { SharePresentationPayload } from './share-presentation-payload-websocket';
import { ActivityResource } from '../../classes/resource/activity-resource.class';
import { ActivityResourceContact } from '../../classes/resource/activity-resource-contact.class'
import * as _ from 'lodash';
import { ActivityResourceSlide } from '@omni/classes/resource/activity-resource-slide.class';
import { debounceTime } from 'rxjs/operators';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import * as moment from 'moment';
import { AuthenticationDataService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketDataService {
    private connected: boolean;
    private client: Stomp.Client;
    private subscriptions: Array<Activity>;
    private stompSubscriptions: Map<string, Stomp.StompSubscription> = new Map<string, Stomp.StompSubscription>(); //changing the key to string which will hold activity ID
    private reconnectCount: number = 0;
    public meetingInProgress: boolean;
    
    //placeholder for short call remote meeting
    public shortCallRemoteMeeting: boolean;

    public lastSharedSlidePayload: any;
    public lastSharedPresentationPayload: any;
    public lastSharedResourcePayload: any;
    public lastSharedResourcePagePayload: any;

    public lastSharedTime: number;
    public lastSharedResourceTime;

    public guestsAreConnected: boolean;
    public remoteUsersAreConnected: boolean;

    public lastPresSelected: Presentation;
    public lastResourceSelected: Resource;
    public lastPageSelected: Page;
    public lastResourcePageSelected: number;

    constructor(
        private authenticationService: AuthenticationService,
        private authService: AuthenticationDataService,
        private activityService: ActivityService,
        private contacts: ContactOfflineService,
        private meetingService: MeetingDataService,
        private presentationService: PresentationService,
        private deviceService: DeviceService,
        private inboundMeetingCtrl: InboundMeetingControllerService,
        private inboundMeetingDataService: InboundMeetingDataService,
        private repService: RepServices,
        private events: EventsService,
        private notificationService: NotificationService,
        private ngZone: NgZone,
        private translate: TranslateService

    ) {
        this.connected = false;
        this.meetingInProgress = false;
        this.subscriptions = [];

        this.guestsAreConnected = false;
        this.remoteUsersAreConnected = false;
        this.lastSharedTime = new Date().getTime();

        this.deviceService.appStateAndNetworkConnectionChange.subscribe((change: { isBg: boolean, isOffline: boolean }) => {
            if (this.deviceService.isNativeApp) {
                if (!change.isBg && !change.isOffline) {
                    if (!this.client) {
                        this.connect();
                    }
                } else if (change.isBg || change.isOffline) {
                    this.events.publish("pauseResourceVideo");
                    //Let video get pause before disconnecting websocket
                    setTimeout(() => {
                      this.disconnect();
                    }, 100);
                }
            }
        });

        this.events.subscribe('ws:subscribeToMeetingTopic', (activity) => {
            if (activity && activity.type === 'Appointment') {
                this.subscribeToMeetingTopic(activity);
            }
        });

        this.presentationService.currentReferenceChangedSignal.pipe(
          debounceTime(200)
        ).subscribe(({ presentationId, pageIdx, pageId, references }) => {
          this.broadcastCurrentPageReferences(
            (this.activityService.selectedActivity as AppointmentActivity).ID,
            presentationId,
            pageIdx,
            pageId,
            references,
          );
        });
    }


    /**
     * Connects to the kafka websocket server exposing options for sending data and subscribing to topics
     *
     * @memberof WebsocketDataService
     */
    public connect(): Promise<boolean> {
        //do not attempt to connect the WS if we are online
        if (!this.deviceService.isOffline && !this.deviceService.isDeviceRealOffline && !this.deviceService.isDeviceRealOffline && !this.client) {
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl.replace('https', 'wss') + Endpoints.websockets.BASE;
            this.client = Stomp.client(url);
            this.client.heartbeat = { incoming: 2000, outgoing: 2000 };
            /*The client supports automatic reconnecting in
            * case of a connection failure. It is controlled by a field reconnect_delay.
            * Default value is 0, which indicates auto reconnect is disabled.
             */
            this.client.reconnect_delay = 1000; //testing
            this.reconnectCount = 0;
            console.log("The websocket client info in connect => ", this.client);
            return new Promise(async (resolve, reject) => {
                this.client.connect(await this._getStompAuthorizationHeaders(),
                    frame => {
                        this.connectCallback(frame, resolve)
                    },
                    this.connectionErrorFallback.bind(this));
            });
        }
    }

    disconnect() {
        if (this.client) {
            this.client.reconnect_delay = 0;
            this.client.disconnect();
            this.client = null;
            this.reconnectCount = 0;
            this.connected = false;
        }
    }

    public async sendRemoteMeetingEnd(activity: Activity, leaveFlag?: boolean) {
        if (!this.connected || !this.client || this.deviceService.isOffline) {
            console.log('Not connected!');
            this.connect();
            return;
        }

        if (!activity) return;

        let msgMapping: string = Endpoints.websockets.END_REMOTE_MEETING.replace('{activityId}', activity.ID);
        let payload: object = {}
        if (leaveFlag) {
            payload = { "action": "ENDED" };
        } else {
            payload = { "action": "NOT_ENDED" };
        }
        this.client.send(this._buildMappingString(msgMapping), await this._getStompAuthorizationHeaders(), JSON.stringify(payload));
        return this.updateLastSlideEndtime(activity, true); // return a promise<void>

    }
  
    /**
     * Subscribes to a meeting topic and saves it so we don't override the existing
     *
     * @param {Activity} activity
     * @returns {Promise<boolean>}
     * @memberof WebsocketDataService
     */
    public subscribeToMeetingTopic(activity: Activity): Promise<boolean> {
        if (!this.connected || !this.client || this.deviceService.isOffline) {
            console.log('Not connected!');
            this.connect();
            return;
        }

        let foundSubscription = this.subscriptions.find(subscriptionActivity => subscriptionActivity.ID === activity.ID);
        if (foundSubscription) {
            console.log('Already subscribed for ' + activity.ID);
            let stompSubscription = this.stompSubscriptions.get(activity.ID);
            this.stompSubscriptions.delete(activity.ID);
            if (stompSubscription) {
                stompSubscription.unsubscribe();
            }
        } else {
            //Clear data, as for new meeting old resource is shared before clicking on play
            this.lastSharedResourcePagePayload = null;
            this.lastSharedResourcePayload = null;
            this.subscriptions.push(activity);
        }

        let stompSubscription = this.client.subscribe('/topic/meetings.' + activity.ID, this.handleTopicResponse.bind(this));
        this.stompSubscriptions.set(activity.ID, stompSubscription);
        // this.meetingInProgress = true;
    }

    public subscribeToInbound() {
        if (!this.connected || !this.client || this.deviceService.isOffline) {
            console.log('Not connected!');
            this.connect();
            return;
        }

        this.client.subscribe('/topic/meetings.inbound', this.handleInboundMeetingTopicMessage.bind(this))
    }

    public removeSubscription(activity: Activity) {
        if (!activity || !this.client) return;
        let foundSubscription = this.subscriptions.findIndex(subscriptionActivity => subscriptionActivity.ID === activity.ID);
        if (foundSubscription !== -1) {
            //this.sendRemoteMeetingEnd(activity);
            this.subscriptions.splice(foundSubscription, 1);
            let stompSubscription = this.stompSubscriptions.get(activity.ID);
            this.stompSubscriptions.delete(activity.ID);
            if (stompSubscription) {
                stompSubscription.unsubscribe();
                this.client.unsubscribe('/topic/meetings.' + activity.ID);
            }
            this.meetingInProgress = false;
            // this.contacts.resetContactConnectionStatus();
            return;
        }
        // this.contacts.resetContactConnectionStatus();

        this.lastSharedSlidePayload = undefined;
        this.lastSharedPresentationPayload = undefined;
        this.lastPresSelected = undefined;
    }

    public hasSubscription(activity: Activity) {
        let foundSubscription = this.subscriptions.find(subscriptionActivity => subscriptionActivity.ID === activity.ID);
        if (foundSubscription) {
            console.log('Already subscribed for ' + activity.ID);
            return true;
        }
        return false;
    }

    public setSentiment(presentation: Presentation, page: Page, activity: Activity, sentiment: SENTIMENT) {

        if (!(activity instanceof AppointmentActivity)) { return }
        //CWD-1499 , design issue ? how do we know if its face to face if we are not tracking for all invited participants
        let contacts = activity.contacts.filter(c => c.connectionState != ContactMeetingState.LEFT); // && c.connectionState !== 100000002);
    let payload: any
    let newPayload: any
      if (contacts.length > 0) {
        payload = {
          indskr_iopresentationid: presentation.ioPresentationId,
          activityPresentationSlides: [{
            id: this.lastSharedSlidePayload.indskr_ckmpageid,
            indskr_ckmpageid: this.lastSharedSlidePayload.indskr_ckmpageid,
            activityPresentationSlideContacts: contacts.map(contact => {
              let ct = {
                indskr_contactid: contact.ID,
                indskr_pagesentiment: sentiment.toString()
              };
              if (this.lastSharedSlidePayload.indskr_starttime) ct['indskr_pagestartdate'] = this.lastSharedSlidePayload.indskr_starttime;
              if (this.lastSharedSlidePayload.indskr_endtime) ct['indskr_pageenddate'] = this.lastSharedSlidePayload.indskr_endtime;
              return ct;
            }),
          }]
        }
        if (!this.deviceService.isOffline && !this.deviceService.isDeviceRealOffline && !this.deviceService.isDeviceRealOffline) {
          this.sendMessageToTopic(Endpoints.websockets.SHARE_PAGE_SENTIMENT.replace('{activityId}', activity.ID), payload);
        }
        this.updateSelectedMeeting(activity, this.lastSharedPresentationPayload, sentiment);
    } else {
        newPayload = {
          indskr_iopresentationid: presentation.ioPresentationId,
          activityPresentationSlides: [{
            id: this.lastSharedSlidePayload.indskr_ckmpageid,
            indskr_ckmpageid: this.lastSharedSlidePayload.indskr_ckmpageid,
            indskr_pagesentiment: sentiment.toString(),
          }]
        }
        if (!this.deviceService.isOffline && !this.deviceService.isDeviceRealOffline && !this.deviceService.isDeviceRealOffline) {
          this.sendMessageToTopic(Endpoints.websockets.SHARE_PAGE_SENTIMENT.replace('{activityId}', activity.ID), newPayload);
        }
        this.updateSelectedMeeting(activity, this.lastSharedPresentationPayload, sentiment, newPayload);
      }
  }


    public setSentimentForResourceSlide(content: Resource, currentPdfPage: number, activity: Activity, sentiment: SENTIMENT) {
      if (!(activity instanceof AppointmentActivity)) { return }
      let contacts = activity.contacts.filter(c => c.connectionState != ContactMeetingState.LEFT); // && c.connectionState !== 100000002);
      this.lastSharedResourcePagePayload.activityResourceSlideContacts = contacts.map(c => {
        return new ActivityResourceContact({
          indskr_contactid: c.ID,
          indskr_pagesentiment: sentiment.toString(),
          indskr_pagestartdate: this.lastSharedResourcePagePayload.indskr_starttime,
          indskr_pageenddate: this.lastSharedResourcePagePayload.indskr_endtime,
          indskr_pagenumber: currentPdfPage
        })
      });
      let payload = {
        indskr_ioresourceid: content.ioResourceId,
        indskr_iodocumentid: content.ioDocumentId,
        indskr_starttime: this.lastSharedResourcePayload.indskr_starttime,
        activityResourceSlides: [{
          indskr_iodocumentid: this.lastSharedResourcePagePayload.indskr_iodocumentid,
          indskr_ioresourceid: this.lastSharedResourcePagePayload.indskr_ioresourceid,
          indskr_pagenumber: this.lastSharedResourcePagePayload.indskr_pagenumber,
          indskr_starttime: this.lastSharedResourcePagePayload.indskr_starttime,
          activityResourceSlideContacts: this.lastSharedResourcePagePayload.activityResourceSlideContacts
        }]
      };
      if (!this.deviceService.isOffline && !this.deviceService.isDeviceRealOffline) {
        this.sendMessageToTopic(Endpoints.websockets.SHARE_RESOURCE_PAGE_SENTIMENT.replace('{activityId}', activity.ID), payload);
      }
      // const lastActivityResource = activity.activityResources[activity.activityResources.length - 1];
      // if(!_.isEmpty(lastActivityResource.activityResourceSlides))
      //   lastActivityResource.activityResourceSlides[lastActivityResource.activityResourceSlides.length - 1].activityResourceSlideContacts = payload.activityResourceSlides[0].activityResourceSlideContacts;
    }


    public async pushResource(activity: Activity, resource: Resource, contacts: Array<Contact>) {

        if (activity instanceof AppointmentActivity) {
            let payload = PushResourcePayload.getDTO(resource, contacts, new Date().getTime());
            console.log(payload);
            if (!this.deviceService.isOffline && !this.deviceService.isDeviceRealOffline && !this.deviceService.isDeviceRealOffline) {
                this.sendMessageToTopic(Endpoints.websockets.PUSH_RESOURCES.replace('{activityId}', activity.ID), payload, true);
            }
        }
    }

    public async shareVideoResourceEvents(activity: Activity, payload) {
      if (activity instanceof AppointmentActivity && payload) {
          if (!this.deviceService.isOffline && !this.deviceService.isDeviceRealOffline && !this.deviceService.isDeviceRealOffline) {
              this.sendMessageToTopic(Endpoints.websockets.SHARE_VIDEO_RESOURCE_EVENTS.replace('{activityId}', activity.ID), payload, true);
          }
      }
  }

    public async updateStreamingStatus(started) {
        if (!this.deviceService.isOffline && !this.deviceService.isDeviceRealOffline && !this.deviceService.isDeviceRealOffline) {
            if (!this.activityService.selectedActivity) return;
            let activity = this.activityService.selectedActivity;
            let payload = {
                mediaState: started ? "STARTED" : "STOPPED"
            }
            this.sendMessageToTopic(Endpoints.websockets.VIDEO_STATUS.replace('{activityId}', activity.ID), payload, true);
        }
    }

    public broadcastVirtualEvent(msg) {
        if (!this.deviceService.isOffline && msg && this.activityService.selectedActivity && !this.activityService.selectedActivity.ID.includes('offline')) {
            this.sendMessageToTopic(Endpoints.websockets.VIRTUAL_EVENT.replace('{activityId}', this.activityService.selectedActivity.ID), msg);
        }
    }

    private updateSelectedMeeting(activity: Activity, slidePayload: any, sentiment?: SENTIMENT ,newPayload ?: any) {

        if (!this.activityService.selectedActivity) return;

        if (!(activity instanceof AppointmentActivity)) return;

        // this.lastSharedTime = new Date().getTime();

        activity.activityPresentations = activity.activityPresentations || [];

        let pres = activity.activityPresentations.find(item => item['presentationId'] === slidePayload.indskr_iopresentationid);

        if (!pres) {
            pres = new ActivityPresentation({
                indskr_iopresentationid: slidePayload.indskr_iopresentationid,
                indskr_name: slidePayload.indskr_name,
                indskr_productid: slidePayload.indskr_productid,
                activityPresentationSlides: []
            });
            pres.activityPresentationSlides = [];
            activity.activityPresentations.push(pres);
        }
        pres.activityPresentationSlides = pres.activityPresentationSlides || [];

        let presSlide = pres.activityPresentationSlides.length > 0 ? pres.activityPresentationSlides[pres.activityPresentationSlides.length - 1] : undefined;
        let lastSharedSlide = presSlide;

        let presSharedSlidePayload = slidePayload['activityPresentationSlides'][0];
        let lastSharedSlidePayload = slidePayload['activityPresentationSlides'][1];
        let sameSlidePrevInst = _.findLast(pres.activityPresentationSlides, (o)=>o.ckmpageid == presSharedSlidePayload.indskr_ckmpageid);
        let contacts = activity.contacts.filter(c => c.connectionState != ContactMeetingState.LEFT);
        if (!sentiment || !presSlide || presSlide.ckmpageid !== presSharedSlidePayload.indskr_ckmpageid) {
            sentiment = sentiment || undefined;
            presSlide = new ActivityPresentationSlide({
                ...presSharedSlidePayload,
                indskr_ckmpageurl: presSharedSlidePayload.pageUrl,  
                indskr_pagesentiment:sentiment?sentiment.toString():'', 
                activityPresentationSlideContacts: contacts.map(contact => {
                    let ct = {
                        indskr_contactid: contact.ID,
                        indskr_name: `${contact.firstName} ${contact.lastName}`,
                        indskr_pagesentiment: sentiment?sentiment.toString():''
                    };
                    if (presSharedSlidePayload.indskr_starttime) ct['indskr_pagestartdate'] = presSharedSlidePayload.indskr_starttime;
                    if (presSharedSlidePayload.indskr_endtime) ct['indskr_pageenddate'] = presSharedSlidePayload.indskr_endtime;
                    return ct;
                }),
            });
            pres.activityPresentationSlides.push(presSlide);
        } else {
            lastSharedSlide = pres.activityPresentationSlides.length > 1 ? pres.activityPresentationSlides[pres.activityPresentationSlides.length - 2] : undefined;
        }
        if(newPayload){
          let currentSharedSlideFromPayload = newPayload.activityPresentationSlides.length > 0 ? newPayload.activityPresentationSlides.find(a => a.indskr_ckmpageid  == presSlide.ckmpageid) : null;
          if (sentiment && presSlide && currentSharedSlideFromPayload) {
            let foundSharedSlide = newPayload.activityPresentationSlides.length > 0 ? pres.activityPresentationSlides.find(a => a.ckmpageid == presSlide.ckmpageid): null ;
            if(foundSharedSlide){
              foundSharedSlide.pagesentiment =sentiment?sentiment.toString():'' ;
            }
          }
        }
        presSlide.activityPresentationSlideContacts = presSlide.activityPresentationSlideContacts || [];

        for (let slideContact of contacts) {
            let contact = presSlide.activityPresentationSlideContacts.find(c => c.contactid === slideContact.ID);
            if (!contact) {
                contact = new ActivityPresentationSlideContact({
                    indskr_contactid: slideContact.ID,
                    indskr_name: `${slideContact.firstName} ${slideContact.lastName}`,
                    indskr_pagestartdate: presSlide.starttime ? presSlide.starttime : null,
                    indskr_pageenddate: presSlide.endtime ? presSlide.endtime : null
                });
                presSlide.activityPresentationSlideContacts.push(contact);
            }
            let sameSlidePrevInstCont;
            if(sameSlidePrevInst){
              sameSlidePrevInstCont = sameSlidePrevInst.activityPresentationSlideContacts?.find(con=>con.contactid==contact.contactid)
            }
            contact.pagesentiment = sentiment?sentiment:(sameSlidePrevInstCont && sameSlidePrevInstCont.pagesentiment? sameSlidePrevInstCont.pagesentiment:SENTIMENT.NEUTRAL);
        }

        if (lastSharedSlidePayload) {
            lastSharedSlide = lastSharedSlide && lastSharedSlide.ckmpageid === lastSharedSlidePayload.indskr_ckmpageid ? lastSharedSlide : pres.activityPresentationSlides.find(slide => slide.ckmpageid === lastSharedSlidePayload.indskr_ckmpageid);
            if (lastSharedSlide) {
                lastSharedSlide['indskr_starttime'] = lastSharedSlide.starttime = lastSharedSlidePayload.indskr_starttime;
                lastSharedSlide['indskr_endtime'] = lastSharedSlide.endtime = lastSharedSlidePayload.indskr_endtime;
                this.updateSlideContactsStartEndTime(lastSharedSlide,
                                                        lastSharedSlidePayload.indskr_starttime,
                                                        lastSharedSlidePayload.indskr_endtime);
            }
        }
    }

    private updateSelectedMeetingLastSlide(slidePayload) {
        if (!this.activityService.selectedActivity) return;

        let activity = this.activityService.selectedActivity;

        if (activity instanceof AppointmentActivity) {
            let pres: any = activity.activityPresentations.find(item => item['presentationId'] === this.lastPresSelected.ioPresentationId);

            if (pres) {
                if (pres.activityPresentationSlides) {
                    let index = pres.activityPresentationSlides.length - 1;
                    for (; index >= 0; index--) {
                        if (pres.activityPresentationSlides[index].ckmpageid === slidePayload['activityPresentationSlides'][0].indskr_ckmpageid) {
                            pres.activityPresentationSlides[index].indskr_starttime =
                                pres.activityPresentationSlides[index].starttime = slidePayload['activityPresentationSlides'][0].indskr_starttime;
                            pres.activityPresentationSlides[index].indskr_endtime =
                                pres.activityPresentationSlides[index].endtime = slidePayload['activityPresentationSlides'][0].indskr_endtime;

                            // For updating slide-contact entity
                            this.updateSlideContactsStartEndTime(pres.activityPresentationSlides[index],
                                                            slidePayload['activityPresentationSlides'][0].indskr_starttime,
                                                            slidePayload['activityPresentationSlides'][0].indskr_endtime);
                            break;
                        }
                    }
                }
            }
        }
    }
    // isFlipButton is added to reset lastPresSelected
    public updateLastSlideEndtime(activity: Activity, isFlipButton?: boolean, overrideEndTimestamp?: number): Promise<void> {
        return new Promise((resolve) => {
            if (!this.lastSharedSlidePayload) return resolve();
            let payload = {
                indskr_iopresentationid: this.lastPresSelected.ioPresentationId,
                indskr_name: this.lastPresSelected.name, // just to make MSE happy
                indskr_productid: this.lastPresSelected.productID, // is missing investigate
                indskr_ckmthumbnailurl: this.lastPresSelected.thumbnailUrl,
                indskr_ckmzipurl: this.lastPresSelected.zipUrl,
                activityPresentationSlides: [],
            }
            if (this.lastSharedSlidePayload) {
                this.lastSharedSlidePayload.indskr_endtime = !isNaN(overrideEndTimestamp) && overrideEndTimestamp > 0 ? overrideEndTimestamp : new Date().getTime();
                // For updating slide-contact entity
                this.updateSlideContactsStartEndTime(this.lastSharedSlidePayload, this.lastSharedSlidePayload.indskr_starttime, this.lastSharedSlidePayload.indskr_endtime);

                payload.activityPresentationSlides.push(this.lastSharedSlidePayload);

                this.updateSelectedMeetingLastSlide(payload);
                // this.lastSharedPayload = payload.activityPresentationSlides[0];
            }
            this.lastSharedSlidePayload = undefined;
            this.lastSharedPresentationPayload = undefined;
            payload['scheduledend'] = activity.scheduledEnd;
            payload['scheduledstart'] = activity.scheduledStart;
            this.sendMessageToTopic(Endpoints.websockets.SHARE_PRESENTATION_SLIDE_MAPPING.replace('{activityId}', activity.ID), payload);
            if (isFlipButton) {
                this.lastPresSelected = undefined;
            }
            console.log("Last Shared Slide Updated....");
            resolve();
        });
    }

    public updateLastResourcePageEndtime(activity: Activity, isFlipButton?: boolean): Promise<void> {
      return new Promise((resolve) => {
          if (!this.lastSharedResourcePagePayload) resolve();
          let payload = this.lastSharedResourcePayload;
          if(!payload) return;
          const endTime: any = new Date().getTime();
          let activityResourceSlide = payload.activityResourceSlides.find(ar => ar.indskr_endtime == null);
          if (activityResourceSlide) {
            activityResourceSlide.indskr_endtime = endTime;
            activityResourceSlide.activityResourceSlideContacts.map(arc => arc.indskr_pageenddate = endTime);
            payload.activityResourceSlides = [activityResourceSlide];
          }
          if (this.lastResourceSelected) {
            const activity = this.activityService.selectedActivity as AppointmentActivity;
            const index = activity.activityResources.findIndex(resource => resource.indskr_endtime == null && (resource.indskr_iodocumentid ? resource.indskr_iodocumentid == this.lastResourceSelected.ioDocumentId : resource.indskr_ioresourceid == this.lastResourceSelected.ioResourceId));
            if (index >= 0) {
              activity.activityResources[index].indskr_endtime = endTime;
            }
          }
          payload.indskr_endtime = endTime;
          this.lastSharedResourcePayload = undefined;
          this.lastSharedResourcePagePayload = undefined;
          this.sendMessageToTopic(Endpoints.websockets.SHARE_RESOURCE_ASSET_MAPPING.replace('{activityId}', activity.ID), payload);
          if (isFlipButton || this.lastResourceSelected.fileType != 'pdf') {
              this.lastResourceSelected = undefined;
          }
          console.log("Last Shared resource page Updated....");
          resolve();
      });
  }

    private updateSlideContactsStartEndTime(slide: any, startTime?: string, endTime?: string) {
        if (Array.isArray(slide.activityPresentationSlideContacts)) {
            for (let i = 0; i < slide.activityPresentationSlideContacts.length; i++) {
                const contact = slide.activityPresentationSlideContacts[i];
                if (startTime) contact.indskr_pagestartdate = startTime;
                if (endTime) contact.indskr_pageenddate = endTime;
            }
        }
    }

    public async shareSlide(activity: Activity, page: Page, presentation: Presentation, playClicked: boolean) {
        if (!presentation) return;
        this.updateLastResourcePageEndtime(activity as AppointmentActivity);
        let isNewPresentation: boolean = playClicked ? true : false;

        if (this.lastPresSelected != presentation) {
            await this.updateLastSlideEndtime(activity);
            isNewPresentation = true
        }
        this.lastPresSelected = presentation;

        let payload = {
            indskr_iopresentationid: presentation.ioPresentationId,
            indskr_width: presentation.width, // when the presentation is not cached(IOS/WFE) we have to know this field
            indskr_height: presentation.height,
            indskr_name: presentation.name, // just to make MSE happy
            indskr_productid: presentation.productID, // is missing investigate
            indskr_ckmthumbnailurl: presentation.thumbnailUrl,
            indskr_ckmzipurl: presentation.zipUrl,
            isPresentationChange: isNewPresentation,
            isResponsive: !!presentation.isResponsive,
            isEcard: !!presentation.indskr_ecard,
            activityPresentationSlides: [
                {
                    indskr_ckmpageid: page.id,
                    indskr_name: page.name,
                    indskr_starttime: new Date().getTime(), //(this.lastSharedTime ? this.lastSharedTime : new Date().getTime() ),
                    indskr_endtime: undefined,
                    indskr_title: page.name,
                    indskr_virtualpage: page.indskr_isvirtualpage,
                    pageUrl: page.indskr_isvirtualpage && this.lastSharedSlidePayload && this.lastSharedSlidePayload.pageUrl || page.pageUrl,

                }
            ],

        }

        if (this.lastSharedSlidePayload) {
            this.lastSharedSlidePayload.indskr_endtime = new Date().getTime();
            payload.activityPresentationSlides.push(this.lastSharedSlidePayload);
        } else {
            payload.activityPresentationSlides[0].indskr_endtime = undefined;
        }
        this.lastSharedSlidePayload = payload.activityPresentationSlides[0];
        this.lastSharedPresentationPayload = payload;
        this.lastSharedTime = new Date().getTime();

        this.updateSelectedMeeting(activity, payload);

        if (this.connected && this.client) {
            payload['scheduledend'] = activity.scheduledEnd;
            payload['scheduledstart'] = activity.scheduledStart;
            this.sendMessageToTopic(Endpoints.websockets.SHARE_PRESENTATION_SLIDE_MAPPING.replace('{activityId}', activity.ID), payload);
        }
    }


    // Reference broadcast functions
    public broadcastCurrentPageReferences(
      activityId: string,
      indskr_iopresentationid: string,
      pageSequence: number,
      indskr_ckmpageid: string,
      referencesToBroadcast: any[],
      targetContactIds?: string[],
    ) {
      if (this.meetingInProgress && this.connected && this.client && Array.isArray(referencesToBroadcast)) {
        const references = referencesToBroadcast.map(reference => ({
          createdon: reference.createdon,
          indskr_displayname: reference.indskr_displayname ?? null,
          indskr_documentpagenumber: reference.indskr_documentpagenumber ?? null,
          indskr_marker: reference.indskr_marker,
          indskr_name: reference.indskr_name,
          indskr_referencesid: reference.indskr_referencesid,
          _indskr_document_value_Formatted: reference._indskr_document_value_Formatted ?? null,
        }));

        this.sendMessageToTopic(
          Endpoints.websockets.SHARE_PRESENTATION_SLIDE_MAPPING
            .replace('{activityId}', activityId),
          {
            msgCategory: 'REFERENCES',
            msgType: 'REFERENCE_LIST_BROADCAST',
            indskr_iopresentationid,
            pageSequence,
            indskr_ckmpageid,
            references,
            targetContactIds,
          },
        );
      }
    }
    public broadcastCurrentReferencePresentStatus(
      activityId: string,
      presentationId: string,
      indskr_ckmpageid: string,
      referencePresentStatus: any[],
      targetContactIds?: string[],
    ) {
      const payload = {
        msgCategory: 'REFERENCES',
        msgType: 'REFERENCE_PRESENT_STATUS_BROADCAST',
        indskr_iopresentationid: presentationId,
        indskr_ckmpageid,
        referencePresentStatus,
        targetContactIds,
      };

      if (this.connected && this.client) {
        this.sendMessageToTopic(
          Endpoints.websockets.SHARE_PRESENTATION_SLIDE_MAPPING
            .replace('{activityId}', activityId),
          payload,
        );
      }
    }
    public broadcastReferenceEvent(
      activityId: string,
      presentationId: string,
      pageIdx: number,
      pageId: string,
      referenceId: string,
      referenceEvent: 'open' | 'show' | 'hide' | 'close',
      referenceType: 'pdf' | 'url',
      referenceUrl: string,
      targetContactIds?: string[],
    ) {
      const payload = {
        msgCategory: 'REFERENCES',
        msgType: 'REFERENCE_EVENT_BROADCAST',
        indskr_iopresentationid: presentationId,
        pageSequence: pageIdx,
        indskr_ckmpageid: pageId,
        referenceId,
        referenceEvent,
        referenceUrl,
        referenceType,
        targetContactIds,
      };

      if (this.connected && this.client) {
        this.sendMessageToTopic(
          Endpoints.websockets.SHARE_PRESENTATION_SLIDE_MAPPING
            .replace('{activityId}', activityId),
          payload,
        );
      }
    }


    public async shareResourcePage(activity: Activity, page: number, resource: Resource, playClicked: boolean) {
        if (!resource || !activity || !(activity instanceof AppointmentActivity)) return;
        if (this.lastResourcePageSelected === page && this.lastResourceSelected && this.lastResourceSelected.ioDocumentId === resource.ioDocumentId && resource.fileType !="pdf") return;
        this.updateLastSlideEndtime(activity);
        if (this.lastResourceSelected && this.lastResourceSelected != resource) {
            await this.updateLastResourcePageEndtime(activity);
        }
        this.lastResourceSelected = resource;
        const resId: string = resource.ioResourceId ? resource.ioResourceId : resource.ioDocumentId;
        let previouslyShared: ActivityResource = null;
        const sharedTime: any = new Date().getTime();
        let previouslySharedTime: any;
        let sameSlidePrevInst: ActivityResourceSlide = null;
        if (resource.fileType === 'pdf') {
          this.lastResourcePageSelected = page;
          previouslyShared = activity.activityResources.find(item => item['indskr_ioresourceid'] === resId || item['indskr_iodocumentid'] === resId);
          if (previouslyShared) {
            if (!previouslyShared.activityResourceSlides) previouslyShared.activityResourceSlides = [];
            previouslySharedTime = previouslyShared.indskr_starttime;
            sameSlidePrevInst = _.findLast(previouslyShared.activityResourceSlides, (o)=>o.indskr_pagenumber === page);
          }
        }
        const contacts = activity.contacts.filter(c => c.connectionState != ContactMeetingState.LEFT);
        const slide: ActivityResourceSlide = new ActivityResourceSlide({
          indskr_pagenumber: page,
          indskr_starttime: sharedTime,
          indskr_iodocumentid: resource.ioDocumentId,
          indskr_ioresourceid: resource.ioResourceId,
          indskr_endtime: null,
          activityResourceSlideContacts: contacts.map(c => {
            return new ActivityResourceContact({
              indskr_contactid: c.ID,
              indskr_pagesentiment: this.previouslyCapturedSentiment(sameSlidePrevInst, c.ID),
              indskr_pagestartdate: sharedTime,
              indskr_pageenddate: null,
              indskr_pagenumber: page
            }) })
      });
        let payload = {
            ...resource.raw,
            indskr_starttime: previouslySharedTime ? previouslySharedTime : sharedTime,
            activityResourceSlides: [slide]
        }

        if (this.lastSharedResourcePagePayload) {
            this.lastSharedResourcePagePayload.indskr_endtime = sharedTime;
            this.lastSharedResourcePagePayload.activityResourceSlideContacts.map(c => c.indskr_pageenddate = sharedTime);
            payload.activityResourceSlides.push(this.lastSharedResourcePagePayload);
        } else {
            payload.activityResourceSlides[0].indskr_endtime = undefined;
        }
        this.lastSharedResourcePagePayload = payload.activityResourceSlides[0];
        this.lastSharedResourcePayload = payload;
        this.lastSharedResourceTime = sharedTime;
        if (!previouslyShared) {
          previouslyShared = new ActivityResource({
            indskr_iodocumentid: payload.indskr_iodocumentid,
            indskr_ioresourceid: payload.indskr_ioresourceid,
            indskr_ckmtitle: payload.indskr_ckmtitle,
            indskr_starttime: payload.indskr_starttime,
            indskr_endtime: null,
            activityResourceSlides: []
          });
          activity.activityResources = [...activity.activityResources, previouslyShared];
        }
        previouslyShared.activityResourceSlides = [...previouslyShared.activityResourceSlides, this.lastSharedResourcePagePayload];
        if (this.connected && this.client) {
            this.sendMessageToTopic(Endpoints.websockets.SHARE_RESOURCE_ASSET_MAPPING.replace('{activityId}', activity.ID), payload);
        }
    }

    private previouslyCapturedSentiment(resourceSlide: ActivityResourceSlide, contactId) {
      if (!resourceSlide) return SENTIMENT.NEUTRAL.toString();
      const contact: ActivityResourceContact = resourceSlide.activityResourceSlideContacts.find(con=>con.indskr_contactid==contactId);
      return contact && contact.indskr_pagesentiment ? contact.indskr_pagesentiment : SENTIMENT.NEUTRAL.toString();
    }

    /**
     * Unless backend provides a better way of doing this we will not implement.
     *
     * @memberof WebsocketDataService
     */
    // public async shareCurrentSlide() {
    //     this.shareSlide(this.activityService.selectedActivity, this.presentationService.activePresPage, this.presentationService.activePresentation);
    // }

    public async shareCachedPresentation(share: boolean = false) {
        // this.lastSharedPayload = undefined;
        this.presentationService.currentSelectedPres.subscribe(pres => {
          //We have a presentation, let's share it baby
          if (share) {
            if (pres instanceof Presentation) {
              this.sharePresentation(this.activityService.selectedActivity, pres, true);
            } else if (pres instanceof Resource && this.lastSharedResourcePayload) {
              this.shareResourcePage(this.activityService.selectedActivity, this.lastResourcePageSelected, pres, true);
            }
            share = false;
          }
        });
    }

    public async sharePresentation(activity: Activity, presentation: Presentation, cached: boolean = false) {
        if (!this.connected || !this.client || this.deviceService.isOffline) {
            console.error('No websocket connection!');
            this.connect();
            return;
        }

        if (!activity) return; // only makes sense to share presentation with activity selected
        let payload = new SharePresentationPayload(presentation);
        // this.lastSharedPayload = undefined;
        let payloadDTO = payload.getDTO();
        payloadDTO['scheduledend'] = activity.scheduledEnd;
        payloadDTO['scheduledstart'] = activity.scheduledStart;
        if (this.lastSharedSlidePayload)
            payloadDTO.activityPresentationSlides = [this.lastSharedSlidePayload];
        let endpoint = cached ? Endpoints.websockets.SHARE_CACHED_PRESENTATION_MAPPING : Endpoints.websockets.SHARE_PRESENTATION_MAPPING;
        if (cached) {
            this.sendMessageToTopic(endpoint.replace('{activityId}', activity.ID), payloadDTO);
        }
        
    }
    /**
     * Internal fn for mapping activity id's for topics
     *
     * @private
     * @param {Activity} activity
     * @returns {string}
     * @memberof WebsocketDataService
     */
    // tslint:disable-next-line:prefer-function-over-method
    private _buildTopicString(activity: Activity): string {
        return `/topic/meetings.${activity.ID}`;
    }

    /**
     * Internal fn for building mapping messages
     *
     * @private
     * @param {Activity} activity
     * @param {string} mapping
     * @returns {string}
     * @memberof WebsocketDataService
     */
    // tslint:disable-next-line:prefer-function-over-method
    private _buildMappingString(mapping: string): string {
        return `${mapping}`;
    }

    /**
     * Internal fn for generating our security header
     *
     * @private
     * @returns {Stomp.StompHeaders}
     * @memberof WebsocketDataService
     */
    private async _getStompAuthorizationHeaders() {
      let accessToken = await this.authService.getTokenForSelectedInstance();
      let headers: Stomp.StompHeaders = {
        Authorization: 'Bearer ' + accessToken.token
      }

      return headers;
    }

    public sendRemoteAction<T extends {type: string}>(data: T, activityId?: string) {
      this.sendMessageToTopic(
        Endpoints.websockets.REMOTE_ACTION.replace('{activityId}', activityId || this.activityService.selectedActivity.ID),
        data, true);
    }

    /**
     *
     *
     * @param {string} mapping
     * @param {object} payload
     * @memberof WebsocketDataService
     */
    public async sendMessageToTopic(mapping: string, payload: object, ignoreMeetingStatus: boolean = false) {
        if (this.deviceService.isOffline || this.deviceService.isDeviceRealOffline || this.deviceService.isDeviceRealOffline) return;
        // Track only when meeting is in progress
        if ((ignoreMeetingStatus || this.meetingInProgress) && this.client && this.connected) {
          this.client.send(this._buildMappingString(mapping), await this._getStompAuthorizationHeaders(), JSON.stringify(payload));
        }
    }

    async handleNewParticipantJoin(
      activity: AppointmentActivity,
      payload: {
        indskr_name: string,
        indskr_joinstatus: ContactMeetingState,
        indskr_joineddate: string,
        isCoVisitor?: boolean,
        indskr_isguest?: boolean,
        indskr_contactid?: string,
        userId?: string,
      }
    ) {
      let joinedCoVisitor;
      if (activity && payload.indskr_contactid) {
          let joinedContact = activity.contacts.find(contact => contact.ID === payload.indskr_contactid);
          // this.ngZone.run(()=>{
          //     if(this.activityService.selectedActivity && !activity.indskr_shortcall){
          //         if(this.activityService.selectedActivity.location && this.activityService.selectedActivity.location !='LiveMeet'){
          //             this.activityService.selectedActivity.location = '';
          //         }
          //     }
          // });
        if (joinedContact && joinedContact.connectionState !== ContactMeetingState.JOINED) {
          this.notificationService.notify(`${payload.indskr_name} ${this.translate.instant('JOINED')} ${activity.subject}`, 'Websocket Service', 'top');
        }
          if(!activity.isRemoteDetailing) {
              this.activityService.updateAsRemoteMeeting(activity);
          }
      } else if (activity && payload.isCoVisitor && payload.userId) {
          // Co-visitor joined
          joinedCoVisitor = activity.accompaniedUserList.find(user => user.id === payload.userId);
          (joinedCoVisitor && joinedCoVisitor.remoteMeetingJoinStatus === ContactMeetingState.JOINED) ? undefined : this.notificationService.notify(`${payload.indskr_name} ${this.translate.instant('JOINED')} ${activity.subject}`, 'Websocket Service', 'top');
      }
      //await this.shareCurrentSlide(); // this causes CWD-1549 bug avoid until a better design is discussed
      /*CWD-1492,this ensures that the websocket connection is stable and we are sending all the slides to HCP and caching for our usage , IOS does this differently*/
      this.lastResourceSelected = undefined;
      setTimeout(() => {
        this.sendRemoteAction({
          type: 'features',
          features: {
            isTencent: this.authenticationService.hasFeatureAction(FeatureActionsMap.ENABLE_TENCENT_REMOTE_DETAILING),
            audio: this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_VOIP),
            video: this.authenticationService.hasFeatureAction(FeatureActionsMap.VIDEO_BUTTON),
            screenshare: this.deviceService.deviceFlags.desktop && this.deviceService.deviceFlags.web && this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_SCREENSHARE)
          }
        })
      }, 100);
      await this.shareCachedPresentation(true) // every participant that joins the meeting gets a cached presentation
      if (payload.indskr_isguest && payload.indskr_joinstatus === ContactMeetingState.JOINED) {
          //Contact is a guest and joined
          this.guestsAreConnected = true;
          this.meetingService.addGuestToMeetingContacts(payload, activity.subtype === 100000005);
      }

      if (payload.isCoVisitor && joinedCoVisitor) {
          joinedCoVisitor.remoteMeetingJoinStatus = payload.indskr_joinstatus;
          joinedCoVisitor.remoteMeetingJoinedDate = payload.indskr_joineddate || '';
          this.events.publish("remote_meeting_participant_Joined_left",{joinedStatus: 'joined'});
      } else {
          let contact: Contact = _.cloneDeep(this.contacts.getContactByID(payload.indskr_contactid));
          console.log('### contact: ', contact);
          if (!contact && activity) {
              // Since inbound guest doesn't get added to the main contact list, we search from there
              contact = activity.contacts.find((contact: Contact) => {
                  return contact.ID === payload.indskr_contactid;
              });
          }
          if (contact) {
              contact.contactJoinedState = payload.indskr_joinstatus;
              contact.isremote = true;
              contact.join_date = payload.indskr_joineddate || "";
              const index = activity.contacts.findIndex(c => c.ID === contact.ID);
              if (index >= 0) {
                  activity.contacts[index] = contact;
              }
              console.log('### contact updated!: ', contact);
              this.events.publish("remote_meeting_participant_Joined_left",{joinedStatus: 'joined', contactID: contact.ID});
          }
      }

      // Broadcast the reference list & reference present status to the joined participant
      if (this.presentationService.activePresentation instanceof Presentation) {
        const referencesData = this.presentationService.getReferencesFromCache(
          this.presentationService.activePresentation.ioPresentationId,
          Number(this.presentationService.activePresPage.pageSequence),
        );
        if (referencesData) {
          setTimeout(() => {
              this.broadcastCurrentPageReferences(
                (this.activityService.selectedActivity as AppointmentActivity).ID,
                (this.presentationService.activePresentation as Presentation).ioPresentationId,
                Number(this.presentationService.activePresPage.pageSequence),
                referencesData.pageId,
                referencesData.references,
                [payload.indskr_contactid],
              );

              setTimeout(() => {
                if (this.presentationService.isReferenceBeingShared()) {
                  const referencePresentStatus = this.presentationService.getReferenceBeingShared();
                  this.broadcastCurrentReferencePresentStatus(
                    (this.activityService.selectedActivity as AppointmentActivity).ID,
                    (this.presentationService.activePresentation as Presentation).ioPresentationId,
                    referencesData.pageId,
                    referencePresentStatus,
                    [payload.indskr_contactid],
                  );
                }
              }, 500);
          }, 500);
        }
      }
    }

    handleParticipantLeave(
      activity: AppointmentActivity,
      payload: {
        indskr_name: string,
        indskr_joinstatus: ContactMeetingState,
        indskr_leftdate: string,
        isCoVisitor?: boolean,
        indskr_contactid?: string,
        userId?: string,
      }
    ) {
      if (activity && payload.isCoVisitor && payload.userId) {
        // Co-visitor left
        const joinedCoVisitor = activity.accompaniedUserList.find(user => user.id === payload.userId);

        if (joinedCoVisitor.remoteMeetingJoinStatus !== ContactMeetingState.LEFT) {
            (activity ? this.notificationService.notify(`${payload.indskr_name} ${this.translate.instant('LEFT')}  ${activity.subject}`, 'Websocket Service') : undefined);
            joinedCoVisitor.remoteMeetingJoinStatus = payload.indskr_joinstatus;
            joinedCoVisitor.remoteMeetingLeftDate = payload.indskr_leftdate || '';
        }
        this.events.publish("remote_meeting_participant_Joined_left",{joinedStatus: 'left'});
      } else {
        let contact = activity.contacts.find((contact: Contact) => {
            return contact.ID === payload.indskr_contactid;
        });
        if (contact && contact.connectionState !== ContactMeetingState.LEFT) {
          contact = _.cloneDeep(contact);
            (activity ? this.notificationService.notify(`${payload.indskr_name} ${this.translate.instant('LEFT')}  ${activity.subject}`, 'Websocket Service') : undefined);
            contact.contactJoinedState = contact.connectionState  = payload.indskr_joinstatus;
            contact.left_date = payload.indskr_leftdate || "";
            const index = activity.contacts.findIndex(c => c.ID === contact.ID);
          if (index >= 0) {
            activity.contacts[index] = contact;
          }
          this.events.publish("remote_meeting_participant_Joined_left", { joinedStatus: 'left', contactID: contact.ID });
        }
        this.guestsAreConnected = this.isGuestUsersConnected(activity);
      }
    }

    checkRemoteUsersAreConnected(activity: AppointmentActivity) {
      this.remoteUsersAreConnected = this.activityService.isThereConnectedContactsForCurrentMeeting(activity, true);
    }

    /**
     * Handles responses from subscribed topics
     *
     * @private
     * @param {Stomp.Message} response
     * @memberof WebsocketDataService
     */
    // tslint:disable-next-line:prefer-function-over-method
    private async handleTopicResponse(response: Stomp.Message) {
        console.log(`Received response from ${response}`);
        if (!response.body) return;

        let responseObject = JSON.parse(response.body);
        console.log("REP SIDE RESPONSE OBJECT", responseObject.headers);
        let payload = JSON.parse(responseObject.payload);
        console.log("REP SIDE PAYLOAD => ", payload);
        let activity = this.activityService.getActivityByID(responseObject.headers.activityId) as AppointmentActivity;

        switch (responseObject.headers.type) {
            case "MEETING_PUSHRESOURCE_STATUS":
                this.events.publish("meeting:resourcestatus", payload);
                return;
            case "MEETING_PRACTITIONER_STATUS":
                break;
            case "MEETING_REMOTE_ACTION":
              if(payload['type'] == 'consent_request_rejected'){
                this.events.publish('remote_consent:rejected', payload)
              }
              if(payload['type'] == 'consent_request_accepted'){
                this.events.publish('remote_consent:accepted')
              }
              if(payload['type'] == 'consent_capture_completed'){
                this.events.publish('remote_consent:completed', payload)
              }
              if(payload['type'] == 'consent_request_timeout'){
                this.events.publish('remote_consent:timeout')
              }
              if(payload['type'] == 'allocation_request_rejected'){
                this.events.publish('remote_allocation:rejected', payload)
              }
              if(payload['type'] == 'allocation_request_accepted'){
                this.events.publish('remote_allocation:accepted')
              }
              if(payload['type'] == 'allocation_capture_completed'){
                this.events.publish('remote_allocation:completed', payload)
              }
              if(payload['type'] == 'allocation_request_timeout'){
                this.events.publish('remote_allocation:timeout')
              }
              break;
        }

        //Probably a presentation
        if (Array.isArray(payload) && payload[0]) {
            let payloadObj = payload[0];
            if (payloadObj.hasOwnProperty('indskr_presentationname')) {
                this.notificationService.notify(`${payloadObj.indskr_presentationname} added to meeting`, 'Websocket Service', 'top');
            }
            //CODE CLEANUP COMING SOON
        } else if (payload.hasOwnProperty('indskr_name') && payload.hasOwnProperty('indskr_joinstatus')) {
            //guest join status is different from contact join status
            if (payload['indskr_joinstatus'] === 100000001 || payload['indskr_joinstatus'] === ContactMeetingState.JOINED) {
                //(activity ? this.toast.create({ duration: 3000, message: `${payload.indskr_name} joined ${activity.subject}`, showCloseButton: true }).present() : undefined);
                //CWD-2592 Prevent toaster from showing multiple notifications when contact has already joined (in case when multiple web socket messages are received for same event)
                await this.handleNewParticipantJoin(activity, payload);
            } else if (payload['indskr_joinstatus'] === ContactMeetingState.LEFT) {
                this.handleParticipantLeave(activity, payload);
            }
            this.events.publish("refresh_resource_status");
        }
        this.checkRemoteUsersAreConnected(activity);
    }

    public isGuestUsersConnected(activity: AppointmentActivity | Activity): boolean {
        if (activity instanceof AppointmentActivity) {
            return activity.contacts.some(contact => contact.isguest && contact.connectionState != 100000003);
        } else {
            return false;
        }
    }
    private handleInboundMeetingTopicMessage(message: Stomp.Message) {
        if (!message.body) return;

        const messageObject = JSON.parse(message.body);
        const headers: { id: string, timestamp: number, type: string, Authorization: string, activityId: string, 'X-Correlation-Id': string } = messageObject.headers;
        const payload: InboundMeetingRequestWebsocketPayload = JSON.parse(messageObject.payload);

        if (headers && headers.type === 'inbound_request_timeout') {
            // Inbound meeting request cancel
            // Only take an action if the message is for me
            if (payload && payload.filteredRepIds && payload.filteredRepIds.includes(this.authenticationService.user.systemUserID)) {
                if (!payload.inboundRequestId) {
                    console.warn('handleInboundMeetingTopicMessage: inboundRequestId is required to cancel a call.. ', payload);
                    return;
                }
                this.inboundMeetingCtrl.requestCanceled(payload.inboundRequestId);
            }
        } else {
            // Only take an action if the message is for me
            if (payload && payload.filteredRepIds && payload.filteredRepIds.includes(this.authenticationService.user.systemUserID)) {
                // Acknowledge the server that I've received a request
                this.inboundMeetingDataService.acknowledgeRequestReceipt(payload.inboundRequestId)
                    .subscribe({
                        next: () => {
                            this.inboundMeetingCtrl.requestReceived(payload);
                        },
                        error: (err: any) => {
                            console.error('handleInboundMeetingTopicMessage: ', err);
                        }
                    });
            }
        }
    }

    /**
     * Internal fn callback for connecting
     *
     * @private
     * @memberof WebsocketDataService
     */
    private connectCallback(frame: Stomp.Frame, resolve) {
        console.log('connected websocket');
        this.connected = true;
        this.subscribeToInbound();
        //subscribe to meetings again in case of reconnect
        let subscriptions = this.subscriptions.splice(0);
        while (subscriptions.length > 0) {
            let activity = subscriptions.pop();
            this.subscribeToMeetingTopic(activity);
        }
        if (subscriptions.length == 0 && this.activityService.selectedActivity) {
            this.subscribeToMeetingTopic(this.activityService.selectedActivity);
        }
        this.events.publish('ws:reconnected');
        resolve(true);
    }

    /**
     * Internal fn callback for error connecting
     *
     * @private
     * @param {any} error
     * @memberof WebsocketDataService
     */
    // tslint:disable-next-line:prefer-function-over-method
    private connectionErrorFallback(error) {
        this.reconnectCount++;
        this.connected = false;
        if (this.client) {
            this.client.reconnect_delay = 10000; // just extend the reconnection delay
        }

        if (this.reconnectCount > 5 && this.client && (this.deviceService.isOffline || this.deviceService.appInBackground)) {
            this.disconnect();
        }
    }

    get currentTime(): string {
        return new Date().getTime().toString();
    }
}
