<ion-header>
  <ind-page-title [viewData]="emailDetailsPageTitle" (onControlClick)='onPageTitleControlClick($event)'>
  </ind-page-title>
  <business-process *ngIf="messagesProcessView && messagesProcessView.length >= 4" [viewData]="messagesProcessView"
    [scroller]="scroller"></business-process>
</ion-header>
<ion-content #scroller>
  <ng-container>
    <ion-list id="details">
      <ind-section-header [viewData]='detailsHeader' (onControlClick)='onDetailsSectionHeaderControlClick($event)'>
      </ind-section-header>
      <ind-form-field [viewData]="getStatusFormField()"></ind-form-field>
      <ion-row>
        <ion-col size="6">
          <ind-datetime-form *ngIf="startDateField" [viewData]="startDateField"></ind-datetime-form>
        </ion-col>
        <ion-col size="6">
          <ind-datetime-form *ngIf="startTimeField" [viewData]="startTimeField"></ind-datetime-form>
        </ion-col>
      </ion-row>
      <!-- ===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*=== -->
      <ind-form-field *ngIf="isAccountSelectionForEmailEnabled" [viewData]="getAccountFormField()"></ind-form-field>
      <!-- ===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*=== -->
      <div class="contact-selection-main">
        <div class="contact-wrapper">
          <p class="toLabel">
            <ion-text>{{'WECOM_TO' | translate}}</ion-text>
            <ion-icon class='mandatory-icon' *ngIf="!isCompletedActivity"></ion-icon>
          </p>
          <div item-content>
            <ion-row>
              <ion-col class="fat-col">
                <span class="form-field-placeholder-text" *ngIf="email?.emailActivityParties?.length === 0"
                  (click)="gotoContactSelection()">
                  {{((activityService.teamViewActive)?'NO_GLOBALECUSTOMER':'SELECT_WITH_GLOBALCUSTOMER') | translate : {
                  globalCustomerText : utilityService.globalCustomerText } }}
                </span>
                <div class="contact-selected-list" #contactSelectedArea (click)="parentOpenEmailAddress($event)">
                  <div class="contacts-text ion-text-end" slot="end" *ngIf="email?.emailActivityParties?.length > 0">
                    <span class="contact-details"
                      *ngFor="let emailActivityParty of ((email?.emailActivityParties.length >  maxContactsToBeShown && showMore) ?  email?.emailActivityParties.slice(0,maxContactsToBeShown): email?.emailActivityParties )">

                      <span [id]="emailActivityParty.indskr_contactid" class="contact-name">
                        <span
                          *ngIf="!email.indskr_recommendedmessage && !emailService.selectedActivity?.isMessageExternal && emailActivityParty.isNotConsented && !isMessageProductBundleFeature"
                          class="consent-alert">
                          <ion-icon style="color: rgb(178, 153, 87)" name="alert-circle-outline"
                            (click)="captureConsent(emailActivityParty)"></ion-icon>
                        </span>
                        <span class="contact-status" *ngIf="emailActivityParty && !emailActivityParty.isNotConsented"
                          [ngClass]="{
                                 'draft': emailActivityParty.email_statuscode === 1,
                                'pendingSync': emailActivityParty.email_statuscode === 6,
                                'scheduled': emailActivityParty.email_statuscode === 9,
                                'sent': emailActivityParty.email_statuscode === 3,
                                'failed': emailActivityParty.email_statuscode === 8,
                                'read': emailActivityParty.email_statuscode === 0 || emailActivityParty.email_statuscode === 4,
                                'shared': emailActivityParty.email_statuscode === 548910000 }">
                        </span>
                        <span [ngClass]="{'contact-spacing':emailActivityParty.isNotConsented}" class="custom-contact">
                          <span (click)="openEmailAddress($event, emailActivityParty)"
                            class="text-margin">{{emailActivityParty.contact_firstname}}
                            {{emailActivityParty.contact_lastname}}</span>
                        </span>
                        <ion-icon class="remove-eparty" [ngClass]="{'disabled':disabled || backgroundUploadInProgress}"
                          name="close-circle"
                          *ngIf="((email?.channelType === ChannelType.EMAIL && emailActivityParty.emailAddresses?.length === 1) || email?.channelType !== ChannelType.EMAIL) && !isCompletedActivity && !email?.isFromXperiences && !email.indskr_recommendedmessage"
                          (click)="removeContactEmailAddress(emailActivityParty)"></ion-icon>
                      </span>
                    </span>
                    <div *ngIf="email?.emailActivityParties.length > maxContactsToBeShown">
                      <a (click)="onToggleShowMore()" class="show-more">{{showMore ? ('SHOW_MORE' |
                        translate):('SHOW_LESS' | translate)}}</a>
                    </div>
                  </div>
                </div>
              </ion-col>
              <ion-col class="thin-col">
                <ion-icon name="chevron-forward-outline" class="arrow-icon" size="small"
                  *ngIf="!email?.isFromXperiences && !(activityService.teamViewActive && email?.emailActivityParties?.length == 0)"
                  (click)="gotoContactSelection()"></ion-icon>
              </ion-col>
            </ion-row>

          </div>
        </div>
        <div
          [ngClass]="isClickedContact && !isCompletedActivity && email?.emailActivityParties?.length == 0 ? 'form-field-invalid-input-container' : 'contact-bottom-border'">
        </div>
        <ion-item class="error-message-container"
          *ngIf="isClickedContact && !isCompletedActivity && email?.emailActivityParties?.length == 0">
          <ion-label class="error-message-text">{{errorMessageMandatoryField}}</ion-label>
        </ion-item>
      </div>
      <div class="contact-selection-main" *ngIf="isCovisitorFieldEnabled">
        <div class="contact-wrapper">
          <p class="toLabel">{{'ACTIVITY_DETAILS_COVISITORS' | translate}}</p>
          <div item-content>
            <ion-row>
              <ion-col class="fat-col">
                <span class="form-field-placeholder-text" *ngIf="email?.accompaniedUserList?.length === 0"
                  (click)="openAccompainedUsers()">
                  {{'ACTIVITY_DETAILS_SELECT_COVISITORS' | translate}}
                </span>
                <div class="contact-selected-list" id="contactSelectedArea">
                  <div class="contacts-text ion-text-end" slot="end" *ngIf="email?.accompaniedUserList?.length > 0">
                    <span class="contact-details"
                      *ngFor="let emailActivityParty of ((email?.accompaniedUserList.length >  maxCovisitorsToBeShown && covisitorShowMore) ?  email?.accompaniedUserList.slice(0,maxCovisitorsToBeShown): email?.accompaniedUserList )">

                      <span [id]="emailActivityParty.id" class="contact-name">

                        <span class="custom-covisitor">
                          <span class="text-margin">{{emailActivityParty.name}}</span>
                        </span>
                        <ion-icon class="remove-eparty" [ngClass]="{'disabled':disabled || backgroundUploadInProgress}"
                          name="close-circle"
                          *ngIf="!isCompletedActivity && !email?.isFromXperiences && !email.indskr_recommendedmessage"
                          (click)="removeCovisitor(emailActivityParty)"></ion-icon>
                      </span>
                    </span>
                    <div *ngIf="email?.accompaniedUserList.length > maxCovisitorsToBeShown">
                      <a (click)="onToggleCovisitorShowMore()" class="show-more">{{covisitorShowMore ? ('SHOW_MORE' |
                        translate):('SHOW_LESS' | translate)}}</a>
                    </div>
                  </div>
                </div>
              </ion-col>
              <ion-col class="thin-col">
                <ion-icon name="chevron-forward-outline" class="arrow-icon" size="small"
                  *ngIf="!email?.isFromXperiences && !(activityService.teamViewActive && email?.accompaniedUserList?.length == 0)"
                  (click)="openAccompainedUsers()"></ion-icon>
              </ion-col>
            </ion-row>

          </div>
        </div>
        <div class="contact-bottom-border"></div>
      </div>
      <ind-form-field [viewData]="getSubjectFormField()"></ind-form-field>
      <ind-form-field [viewData]="suggestReasonFormField"></ind-form-field>
      <ind-form-field [viewData]="getProductFormField()"
        *ngIf="!this.isFromRemoteMeeting && email?.emailType !== EmailTemplateType.RemoteURL"></ind-form-field>
    </ion-list>
    <ind-section-header id="attachmentsheader" [viewData]='attachmentHeader'
      *ngIf=" email?.channelType === ChannelType.WECOM || (email?.channelType === ChannelType.EMAIL && email?.emailType !== EmailTemplateType.RemoteURL && !emailService.selectedActivity?.isMessageExternal) "
      (onControlClick)='onAttachmentSectionHeaderControlClick($event)'></ind-section-header>
    <ion-item
      *ngIf="email?.channelType === ChannelType.WECOM || (email?.channelType === ChannelType.EMAIL && email?.emailType !== EmailTemplateType.RemoteURL && !emailService.selectedActivity?.isMessageExternal)">
      <ion-content scrollX="true" scrollY="true" style="height:100px">
        <ion-item lines="none" class="resource-item" *ngFor="let attachment of email?.emailAttachments">
          <ion-thumbnail (click)="openResourceInterationDetails(attachment)">
            <img class="interaction-icon" src="assets/imgs/contact_conn.png" *ngIf="attachment.messageInteractions?.length > 0">
            <img io-thumbnail src="{{attachment.indskr_ckmthumbnailurl}}">
          </ion-thumbnail>
        </ion-item>
      </ion-content>
    </ion-item>
    <ng-container *ngIf="email?.channelType !== ChannelType.WECOM">
      <ind-section-header id="previewheader" [viewData]='previewHeader'
        (onControlClick)='onPreviewSectionHeaderControlClick($event)'>
      </ind-section-header>
      <ion-item class="email-preview">
        <ion-content scrollY="true" class="email-preview-body">
          <div id="previewHTML" class="scroll-item preview-body-container" [innerHTML]="templatePreview | safe:'html'"></div>
        </ion-content>
      </ion-item>
      <ion-list
        *ngIf="(checkIfExternalChannelSelected && isNotesFeatureActionEnabled && !emailService.selectedActivity?.isPreviewEnabled)">
        <ind-section-header id="notesheader" [viewData]='notesHeader'
          (onControlClick)='onNotesHeaderControlClick($event)'>
        </ind-section-header>
        <ion-item *ngIf="this.isCompletedActivity" class="notesemptysection" lines="none">
          <ion-label text-wrap>{{this.getNotesData()}}</ion-label>
        </ion-item>
        <ind-text-area id="activity-detail-11" *ngIf="!this.isCompletedActivity"
          [placeHolder]="notesPlaceholder" [disabled]="isReadOnlyJointMeeting || backgroundUploadInProgress"
          [maxLength]="1000"
          [(value)]="emailService.selectedActivity && emailService.selectedActivity.indskr_emailnotes"
          (indFocus)="onNotesFocus()" (indChange)="notesChanged()" [rows]="4" [cols]="10">
        </ind-text-area>
      </ion-list>
    </ng-container>
    <ng-container *ngIf="email?.channelType == ChannelType.WECOM">
      <ind-section-header id="attachmentdetailsheader" [viewData]='attachmentDetailsHeader'></ind-section-header>
      <div class="emailAttachmentDetails">
        <ion-item *ngFor="let attachment of email?.emailAttachments">
          <ion-label>{{attachment.indskr_ckmtitle}}</ion-label>
        </ion-item>
      </div>
    </ng-container>
  </ng-container>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter() && footerService.fabNotRequestedFromPresentation('Email')"
  [footerView]="'detailView'" [selectedView]="'emailDetails'" (buttonClicked)="footerButtonClicked($event)">
</footer-toolbar>
<!-- </ion-footer> -->
