import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Currency} from '@omni/classes/marketing-management/marketing-plan.class';
import {QuotePriceList, QuoteStatus, Quotes} from '@omni/classes/quotes/quotes.class';
import {BehaviorSubject} from 'rxjs';
import {MarketingPlansManagementOfflineService} from '../marketing-management/marketing-management.service';
import {DateTimeFormatsService} from '../date-time-formats/date-time-formats.service';

@Injectable({
  providedIn: 'root'
})
export class QuotesService {
  selectedQuotes$: BehaviorSubject<Quotes> = new BehaviorSubject(null);
  quotesList$: BehaviorSubject<Quotes[]> = new BehaviorSubject([]);
  priceLists: QuotePriceList[] = [];
  preloadedData = null;
  createdFrom = null;
  constructor(
    public translate: TranslateService,
    private marketingPlansManagementOfflineService: MarketingPlansManagementOfflineService,
    public dateTimeFormatsService: DateTimeFormatsService,
  ) {
  }

  async updateQuoteLocal(updatedProperties, quoteId) {
    const quotes = this.quotesList$.getValue();
    const index = quotes.findIndex((quote) => quote.ID === quoteId);
    if (index >= 0) {
      quotes[index] = {...quotes[index], ...updatedProperties};
      this.quotesList$.next(quotes);
      this.selectedQuotes$.next(quotes[index]);
      return
    }
    return;
  }

  updateProduct(updatedProduct, quoteId) {
    let products = this.selectedQuotes$.getValue().products;
    let product: any;
    if (products.length === 0 || !products.some(({quoteDetailId}) => quoteDetailId === updatedProduct.product.quoteDetailId)) {
      product = {
        id: updatedProduct.product.productId,
        quantity: updatedProduct.product.quantity,
        quoteDetailId: updatedProduct.product.quoteDetailId,
        name: updatedProduct.product.name
      }
      products.push(product);
    } else {
      const index = products.findIndex(({quoteDetailId}) => quoteDetailId === updatedProduct.product.quoteDetailId);
      if (index >= 0) {
        if (updatedProduct.product.deleted) {
          products.splice(index, 1);
        } else {
          products[index] = {...products[index], ...updatedProduct.product}
        }
      }
    }
    this.updateQuoteLocal({products, totalAmount: updatedProduct.totalAmount}, quoteId);
  }

  async clearProductsAndUpdateLocal(updatedProperties, quoteId) {
    try {
      updatedProperties = {...updatedProperties, products: []};
      await this.updateQuoteLocal(updatedProperties, quoteId);
      return;
    } catch (error) {
      return
    }
  }

  getFormattedStatus(status: any) {
    switch (status) {
      case QuoteStatus.APPROVED:
        return this.translate.instant("APPROVED");
      case QuoteStatus.DRAFT:
        return this.translate.instant("OM_DRAFT");
      case QuoteStatus.REJECTED:
        return this.translate.instant("REJECTED");
      case QuoteStatus.DYNAMICS_CANCELED:
      case QuoteStatus.CANCELED:
        return this.translate.instant("CANCELLED");
      case QuoteStatus.PENDING_APPROVAL:
        return this.translate.instant("POP_IN_REVIEV");
      case QuoteStatus.IN_PROGRESS:
        return this.translate.instant("IN_PROGRESS");
      case QuoteStatus.DYNAMICS_IN_PROGRESS:
      case QuoteStatus.IN_PROGRESS:
        return this.translate.instant("IN_PROGRESS");
      case QuoteStatus.WON:
        return this.translate.instant("WON");
      case QuoteStatus.OPEN:
        return this.translate.instant("AL_SHIPMENT_FILTERLIST_OPEN");
      case QuoteStatus.LOST:
        return this.translate.instant("OPPORTUNITY_STATE_LOST");
    }
  }

  checkOpportunityOrOrderExistsForQuotes() {
    if (this.preloadedData && this.preloadedData.opportunityId) return true;
    const selectedQuote = this.selectedQuotes$.getValue();
    if (!selectedQuote) return false;
    return (!_.isEmpty(selectedQuote.opportunityId) || !_.isEmpty(selectedQuote.orderId));
  }


  getFormattedValue(value: number, quote): string {
    const currency: Currency = this.marketingPlansManagementOfflineService.currencies.find(currency => currency.id === quote.transactionCurrencyId);
    if (!currency) return null;
    return this.dateTimeFormatsService.selectedSymbolPos.position == 'left'
      ? currency.symbol + value.toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : value.toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + currency.symbol;
  }

  public getFilterText(filters: { productFilter: string, statusFilter:string }, filteredItems: number, products: { text: string, value: string }[]): string {
    let str: string;
    if (filters.statusFilter) {
      str = this.getFormattedStatus(filters.statusFilter);
      if (filters.productFilter) {
        const product = products.find(p => p.value == filters.productFilter);
        str += ', ' + product.text;
      }
    } else if (filters.productFilter) {
      const product = products.find(p => p.value == filters.productFilter);
      str = product.text;
    } else {
      filters = {statusFilter: "", productFilter: ""};
      str = 'All Quotes';
    }
    str = this._translateStatus(str);
    return `${str} (${filteredItems})`;
  }

  private _translateStatus(str: string) {
    const statusTranslations = {
      'Approved': this.translate.instant('APPROVED'),
      'Draft': this.translate.instant('OM_DRAFT'),
      'Rejected': this.translate.instant('REJECTED'),
      'Canceled': this.translate.instant('CANCELLED'),
      'In Review': this.translate.instant('POP_IN_REVIEV'),
      'In Progress': this.translate.instant('IN_PROGRESS'),
      'Won': this.translate.instant('WON'),
      'Shipment Open': this.translate.instant('AL_SHIPMENT_FILTERLIST_OPEN'),
      'Opportunity State Lost': this.translate.instant('OPPORTUNITY_STATE_LOST')
    };

    return statusTranslations[str] || str;
  }
}
