import {Component, Input} from "@angular/core";
import {ComponentViewMode, UIService} from "../../../services/ui/ui.service";
import {DeviceService} from "../../../services/device/device.service";
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { IndPageTitleViewDataModel } from "@omni/models/indPageTitleDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { FormFieldType, IndFormFieldViewDataModel } from "@omni/models/indFormFieldDataModel";
import _ from 'lodash';
import { TranslateService } from "@ngx-translate/core";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { AlertService } from "@omni/services/alert/alert.service";
import { DatePipe } from "@angular/common";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { ActivityService } from "@omni/services/activity/activity.service";
import { IndDropdownListDetailModel } from "@omni/models/indDropdownListModel";
import { PopoverController } from "@ionic/angular";
import { IndDropdownListComponent } from "@omni/components/shared/ind-dropdown-list/ind-dropdown-list";
import { BrandOfflineService } from "@omni/services/brand/brand.service";
import { KOLStatus, KOLStatusCode } from "@omni/classes/contact/kol-status.class";
import { KOLStatusService } from "@omni/services/kol-status/kol-status-service";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { ContactPageComponent } from "../contact-page/contact-page";
import { AgendaFooterService, AgendaFooterView } from "@omni/services/footer/agenda-footer.service";
import { Specialty } from "@omni/classes/contact/contact.class";
import { AlertWithInput } from "@omni/components/shared/alert-with-input/alert-with-input";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { EventName, EventsService } from "@omni/services/events/events.service";
import { TherapeuticAreaDataService } from "@omni/data-services/therapeutic-area/therapeutic-area.data.service";
import { NothingSelectedView } from "@omni/components/shared/nothing-selected-view/nothing-selected-view";

@Component({
  selector: 'kol-status-details',
  templateUrl: 'kol-status-details.html',
  styleUrls:['kol-status-details.scss']
})
export class KOLStatusDetailsComponent {

  @Input() from: any;
  @Input() kolStatus: KOLStatus;
  public currentKOLStatus: KOLStatus;
  private isLayoverPushView: boolean;
  private layoverViewPushedFrom: string;
  public isReadOnlyMode = false;

  public kolStatusDetailsPanePageTitle: IndPageTitleViewDataModel;
  public detailsHeader: IndSectionHeaderViewDataModel;
  public notesSectionHeader: IndSectionHeaderViewDataModel;
  public otherDetailsSectionHeader: IndSectionHeaderViewDataModel;

  public nameFormField: IndFormFieldViewDataModel;
  public kolTypeFormField: IndFormFieldViewDataModel;
  public therapeuticAreaFormField: IndFormFieldViewDataModel;
  public specialityFormField: IndFormFieldViewDataModel;
  public diseaseAreaFormField: IndFormFieldViewDataModel;
  //public commentsFormField: IndFormFieldViewDataModel;
  public dateFormField: IndFormFieldViewDataModel;
  public statusFormField: IndFormFieldViewDataModel;
  public createdByFormField: IndFormFieldViewDataModel;

  private _dropdownPopover:any;
  private currentSpecialties:Array<Specialty> = []
  private readonly ngDestroy$ = new Subject<boolean>();

  constructor(
    private readonly uiService: UIService,
    private readonly device: DeviceService,
    public readonly footerService: FooterService,
    private readonly translate: TranslateService,
    private readonly navService: NavigationService,
    private readonly alertService: AlertService,
    private datePipe: DatePipe,
    private readonly dateTimeFormatsService: DateTimeFormatsService,
    private readonly activityService: ActivityService,
    private readonly popoverCtrl: PopoverController,
    private readonly therapeuticService: TherapeuticAreaDataService,
    private readonly kolStatusService: KOLStatusService,
    public contactService: ContactOfflineService,
    private readonly agendaFooterService: AgendaFooterService,
    private readonly events: EventsService,
  ) {
  }

  ngOnInit() {
    if (this.from) {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
    }
    if(this.kolStatus){
      this.currentKOLStatus = _.cloneDeep(this.kolStatus);
    }
    this.currentSpecialties = [];
    this.contactService.specialties.forEach(a=>{
      if(a.name && !this.currentSpecialties.some(b=> a.id == b.id)){
        this.currentSpecialties.push(a);
      }
    });
    this.currentSpecialties.sort(function (a, b) {
      return (a?.name.toLowerCase() > b?.name.toLowerCase()) ? 1 : -1;
    });

    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this._initPageTitle();
      this._initFormFields();
    });

    this._initPageTitle();
    this._initFormFields();
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  private _initPageTitle() {
    let buttons = [];
    if(this.from != 'KOLStatusTool'){
      buttons.push(
        {
          id: 'close',
          icon: 'chevron-back-outline',
          isDisabled: false,
          align: 'left'
        },
      );
    }
    if(this.currentKOLStatus.isApprovalRecord){
      buttons.push(
        {
          id: "reject",
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('REJECT'),
          isDisabled: this.device.isOffline || this.currentKOLStatus.approvalStatus !== this.translate.instant("PENDING"),
          align: "right"
        }
      );
      buttons.push(
        {
          id: "approve",
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('APPROVE'),
          isDisabled: this.device.isOffline || this.currentKOLStatus.approvalStatus !== this.translate.instant("PENDING"),
          align: "right"
        }
      );
    }else{
      buttons.push(
        {
          id: "save",
          imgSrc: 'assets/imgs/header_send.svg',
          name: this.translate.instant('SUBMIT'),
          isDisabled: this.isSaveButtonDisabled,
          align: "right"
        }
      );
    }
    this.kolStatusDetailsPanePageTitle = {
      id: 'contact-kol-status-details-page-title',
      title: this.currentKOLStatus.name,
      controls: buttons
    }
    if(this.currentKOLStatus.isApprovalRecord){
      this.isReadOnlyMode = true;
      this.footerService.initButtons(FooterViews.CONTACT_KOL_STATUS_APPROVER);
      if(this.currentKOLStatus.approvalStatus == this.translate.instant("PENDING") && !this.device.isOffline){
        this.footerService.enableButtons(['approvecontactkolstatus','rejectcontactkolstatus']);
      }else{
        this.footerService.disableButton(['approvecontactkolstatus','rejectcontactkolstatus']);
      }
      
    }else{
      if(this.currentKOLStatus.statuscode != KOLStatusCode.Draft){
        this.isReadOnlyMode = true;
      }
      this.footerService.initButtons(FooterViews.CONTACT_KOL_STATUS_OWNER);
      if(this.isSaveButtonDisabled){
        this.footerService.disableButton(['savecontactkolstatus']);
      }else{
        this.footerService.enableButtons(['savecontactkolstatus']);
      }
    }
    
  }

  private _initFormFields(){
    this.detailsHeader = {
      id: 'details-header',
      title: this.translate.instant('DETAILS'),
      controls: [],
    };

    this.notesSectionHeader = {
      id: 'comments-header',
      title: this.translate.instant('COMMENTS'),
      controls: [],
    };

    this.otherDetailsSectionHeader = {
      id: 'other-details-header',
      title: this.translate.instant('OTHER_DETAILS'),
      controls: [],
    };

    this.nameFormField = {
      label: this.translate.instant('KOL_NAME'),
      inputText: this.currentKOLStatus.contactId ? this.currentKOLStatus.contactName : '',
      id: 'kol-contact-field',
      isReadOnly: true,
      isDisabled: (!this.isReadOnlyMode && !this.device.isOffline)?false:true,
      customPlaceholderLabel:(this.isReadOnlyMode)?this.translate.instant('NO_KOL_NAME'):this.translate.instant('SELECT_KOL_NAME'),
      showArrow:(!this.isReadOnlyMode && !this.device.isOffline),
      isRequired:(!this.isReadOnlyMode && !this.device.isOffline),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    
    this.kolTypeFormField = {
      label: this.translate.instant('KOL_TYPE'),
      inputText: this.currentKOLStatus.kolTypeString ? this.currentKOLStatus.kolTypeString : '',
      id:'kol-type-field',
      isReadOnly:true,
      isDisabled:(!this.isReadOnlyMode && !this.device.isOffline)?false:true,
      customPlaceholderLabel:(this.isReadOnlyMode)?this.translate.instant('NO_KOL_TYPE'):this.translate.instant('SELECT_KOL_TYPE'),
      showArrow:(!this.isReadOnlyMode && !this.device.isOffline),
      isRequired:(!this.isReadOnlyMode && !this.device.isOffline),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    this.therapeuticAreaFormField = {
      label: this.translate.instant('THERAPEUTIC_AREA'),
      inputText: this.currentKOLStatus.therapeuticAreaId ? this.currentKOLStatus.therapeuticAreaName : '',
      id:'therapeutic-area-field',
      isReadOnly:true,
      isDisabled:(!this.isReadOnlyMode && !this.device.isOffline && this.therapeuticService.therapeuticAreas.length > 0)?false:true,
      customPlaceholderLabel:(this.isReadOnlyMode || this.therapeuticService.therapeuticAreas.length == 0)?this.translate.instant('CASE_NO_THERAPEUTIC_AREA'):this.translate.instant('CASE_SELECT_THERAPEUTIC_AREA'),
      showArrow:(!this.isReadOnlyMode && !this.device.isOffline && this.therapeuticService.therapeuticAreas.length > 0),
      isRequired: false,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    this.specialityFormField = {
      label: this.translate.instant('SPECIALITY'),
      inputText: this.currentKOLStatus.specialtyId ? this.currentKOLStatus.specialtyName : '',
      id:'speciality-field',
      isReadOnly:true,
      isDisabled:(!this.isReadOnlyMode && !this.device.isOffline && this.currentSpecialties.length > 0)?false:true,
      customPlaceholderLabel:(this.isReadOnlyMode || this.currentSpecialties.length == 0)?this.translate.instant('NO_SPECIALTY'):this.translate.instant('SELECT_SPECIALTY'),
      showArrow:(!this.isReadOnlyMode && !this.device.isOffline && this.currentSpecialties.length > 0),
      isRequired:false,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    this.diseaseAreaFormField = {
      label: this.translate.instant('DISEASE_AREA'),
      inputText: this.currentKOLStatus.diseaseAreaId ? this.currentKOLStatus.diseaseAreaName : '',
      id:'disease-area-field',
      isReadOnly:true,
      isDisabled:(!this.isReadOnlyMode && !this.device.isOffline && this.kolStatusService.diseaseAreasList.length > 0)?false:true,
      customPlaceholderLabel:(this.isReadOnlyMode || this.kolStatusService.diseaseAreasList.length == 0)?this.translate.instant('NO_DISEASE_AREA'):this.translate.instant('SELECT_DISEASE_AREA'),
      showArrow:(!this.isReadOnlyMode && !this.device.isOffline && this.kolStatusService.diseaseAreasList.length > 0),
      isRequired:(!this.isReadOnlyMode && !this.device.isOffline),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    // this.commentsFormField = {
    //   label: this.translate.instant('COMMENTS'),
    //   inputText: this.currentKOLStatus.comments,
    //   inputValue :this.currentKOLStatus.comments,
    //   id:'comments-field',
    //   isReadOnly:(!this.isReadOnlyMode && !this.device.isOffline)?false:true,
    //   isDisabled:(!this.isReadOnlyMode && !this.device.isOffline)?false:true,
    //   customPlaceholderLabel:(this.isReadOnlyMode)?this.translate.instant('NO_COMMENTS'):this.translate.instant('ENTER_COMMENTS'),
    //   showArrow:(!this.isReadOnlyMode && !this.device.isOffline),
    //   formFieldType: FormFieldType.INLINE_INPUT,
    //   isRequired: !this.isReadOnlyMode,
    //   errorMessage: this.activityService.getErrorMessageRequiredField(),
    //   eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    // };
    this.dateFormField = {
      label: this.translate.instant('DATE'),
      inputText: this.datePipe.transform(this.currentKOLStatus.createdOn, this.dateTimeFormatsService.date, undefined, this.translate.currentLang),
      isHidden: this.currentKOLStatus.statuscode == KOLStatusCode.Draft,
      id:'date-field',
      isReadOnly:true,
      isDisabled:true,
    };

    this.statusFormField = {
      label: this.translate.instant('STATUS'),
      inputText: this.currentKOLStatus.isApprovalRecord?this.currentKOLStatus.approvalStatus:this.currentKOLStatus.statusString,
      isHidden: this.currentKOLStatus.statuscode == KOLStatusCode.Draft,
      id:'status-field',
      isReadOnly:true,
      isDisabled:true,
    };

    this.createdByFormField = {
      label: this.translate.instant('CREATED_BY'),
      inputText: this.currentKOLStatus.ownerName,
      isHidden: !this.currentKOLStatus.isApprovalRecord || this.currentKOLStatus.statuscode == KOLStatusCode.Draft,
      id:'created-by-field',
      isReadOnly:true,
      isDisabled:true,
    };
  }

  public get isSaveButtonDisabled():boolean {
    let flag = false;
    if(this.currentKOLStatus.statuscode !== KOLStatusCode.Draft){
      flag = true;
    }else if(!this.currentKOLStatus.contactId){
      flag = true;
    }else if(!this.currentKOLStatus.kolTypeValue){
      flag = true;
    }else if(!this.currentKOLStatus.diseaseAreaId){
      flag = true;
    }
    return flag;
  }

  public handleButtonClick(buttonId: string): void {
    let popupTitle = '';
    let popupMessage = '';
    let popupAction = '';
    switch (buttonId) {
      case 'close':
        this.onClosePage();
        return;
      case 'save': {
        if (this.isSaveButtonDisabled) return;
        popupTitle = this.translate.instant('SUBMIT_KOL_STATUS');
        popupMessage = this.translate.instant('R_U_SURE_WANT_SUBMIT_KOL_STATUS');
        popupAction = this.translate.instant('SUBMIT');
        break;
      }
      case 'approve': {
        if (!this.currentKOLStatus.isApprovalRecord) return;
        this.handleStatusUpdate(buttonId);
        return;
      }
      case 'reject': {
        if (!this.currentKOLStatus.isApprovalRecord) return;
        this.handleStatusUpdate(buttonId);
        return;
      }
      default:
        return;
    }
    if (this.currentKOLStatus) {
      this.alertService.showAlert({
        title: popupTitle,
        message: popupMessage}, popupAction
      ).then (res => {
        if(res.role === 'ok' && !this.device.isOffline) {
          if(!this.device.isOffline){
            this.uiService.displayLoader();
            this.currentKOLStatus.statuscode = KOLStatusCode.Submitted;
            this.currentKOLStatus.statusString = 'Submitted';
            this.kolStatusService.saveNewKOLStatus(this.currentKOLStatus).then(res=>{
              this.uiService.dismissLoader();
              this.kolStatusService.isKOLCreateInProgress = false;
              this._initPageTitle();
              this._initFormFields();
              this.events.publish(EventName.NEW_KOL_STATUS_SUBMITTED);
            }).catch(err=>{
              this.currentKOLStatus.statuscode = KOLStatusCode.Draft;
              this.currentKOLStatus.statusString = 'Draft';
              this.uiService.dismissLoader();
            })
          }
        }
      });
    }
  }

  private async handleStatusUpdate(id) {
    const popover = await this.popoverCtrl.create({
      component: AlertWithInput,
      componentProps: {
        header: (id == 'approve' ? this.translate.instant("CONFIRM_APPROVE") : this.translate.instant("CONFIRM_REJECT") ),
        message: this.translate.instant("ENTER_COMMENTS"),
        inputs: [],
        cancelText: this.translate.instant('CANCEL'),
        confirmText: (id == 'approve' ? this.translate.instant("APPROVE") : this.translate.instant("REJECT") ),
        Placeholder: this.translate.instant('Comments'),
        isMandatory: (id == 'approve' ? false : true ),
      },
      cssClass: this.device.isNativeApp ? 'native-alert-with-input-list-view' : 'alert-with-input-list-view',
      backdropDismiss: true,
      event: this.device.isNativeApp ? event : null
    });
    popover.present();
    popover.onDidDismiss().then(async (res) => {
      if (res.data && res.data.role && res.data.role == 'ok' && ((id != 'approve' && res.data.selectedOtherItem) || id == 'approve') ) {
        let payload = {
          //indskr_reason: res.data.selectedOtherItem,
          statuscode: (id == 'approve' ? 548910001 : 548910002),
        }
        if(res.data.selectedOtherItem){
          payload['indskr_reason'] = res.data.selectedOtherItem;
        }
        this.uiService.displayLoader();
        this.kolStatusService.updateKOLRecordStatusOnline(payload,this.currentKOLStatus).then(a=> {
          this.currentKOLStatus.approvalStatus = (id == 'approve' ? 'Approved' : 'Rejected');
          this._initPageTitle();
          this._initFormFields();
          this.events.publish(EventName.NEW_KOL_STATUS_SUBMITTED);
          this.uiService.dismissLoader();
        }).catch(err=>{
          this.uiService.dismissLoader();
          console.log(err);
        });
      }
    });
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (eventName == 'input_value_confirm' && id) {
      switch (id) {
        // case 'comments-field':
        //   this.handleEditComments(event);
        //   break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    } else if (id) {
      switch (id) {
        case 'kol-type-field':
          this.handleKOLTypeSelect(event);
          break;
        case 'therapeutic-area-field':
          this.handleTherapeuticAreaSelect(event);
          break;
        case 'kol-contact-field':
          this.handleContactField();
          break;
        case 'speciality-field':
          this.handleSpecialitySelect(event);
          break;
        case 'disease-area-field':
          this.handleDiseaseAreaSelect(event);
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
  }

  public async handleEditComments(data) {
    if (!this.isReadOnlyMode) {
      //if (data && data.target.value != this.currentKOLStatus.comments) {
      //  this.currentKOLStatus.comments = data.target.value;
        this._updateCurrentKOLStatus();
      //}
    }
  }

  private async handleKOLTypeSelect(data) {
    if (this.isReadOnlyMode) return;
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'Medical-Insight-Category-Select',
      data: [
        {
          title: "Global",
          id: '600000000',
          isSelected: this.currentKOLStatus.kolTypeValue && '600000000' == this.currentKOLStatus.kolTypeValue.toString(),
        },
        {
          title: "Local",
          id: '600000003',
          isSelected: this.currentKOLStatus.kolTypeValue && '600000003' == this.currentKOLStatus.kolTypeValue.toString(),
        },
        {
          title: "National",
          id: '600000002',
          isSelected: this.currentKOLStatus.kolTypeValue && '600000002' == this.currentKOLStatus.kolTypeValue.toString(),
        },
        {
          title: "Regional",
          id: '600000001',
          isSelected: this.currentKOLStatus.kolTypeValue && '600000001' == this.currentKOLStatus.kolTypeValue.toString(),
        },
      ],
    };
    this._dropdownPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data });
    this._dropdownPopover.onDidDismiss().then(async (data) => {
      data = data.data;
      if (data && data.selectedItems && data.selectedItems.length == 1 && (!this.currentKOLStatus.kolTypeValue || (this.currentKOLStatus.kolTypeValue && data.selectedItems[0].id != this.currentKOLStatus.kolTypeValue.toString()) ) ) {
        this.currentKOLStatus.kolTypeValue = parseInt(data.selectedItems[0].id);
        this.currentKOLStatus.kolTypeString = data.selectedItems[0].title
        this._updateCurrentKOLStatus();
      }
      this._dropdownPopover = undefined;
    });
    this._dropdownPopover.present();
  }

  private async handleTherapeuticAreaSelect(data) {
    if (this.isReadOnlyMode) return;
    if(Array.isArray(this.therapeuticService.therapeuticAreas) && this.therapeuticService.therapeuticAreas.length){
      let listData = [];
      for(let i=0;i< this.therapeuticService.therapeuticAreas.length;i++){
        const ta = this.therapeuticService.therapeuticAreas[i];
        let obj = {
          id: ta.therapeuticareaId,
          title: ta.name,
        }
        if(ta.therapeuticareaId == this.currentKOLStatus.therapeuticAreaId){
          obj['isSelected'] = true;
        }
        listData.push(obj);
      }
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'Kol-Status-Therapeutic_Area-Select',
        data: listData,
      };
      this._dropdownPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data });
      this._dropdownPopover.onDidDismiss().then(async (data) => {
        data = data.data;
        if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.currentKOLStatus.therapeuticAreaId) {
          this.currentKOLStatus.therapeuticAreaId = data.selectedItems[0].id;
          this.currentKOLStatus.therapeuticAreaName = data.selectedItems[0].title;
          this._updateCurrentKOLStatus();
        }
        this._dropdownPopover = undefined;
      });
      this._dropdownPopover.present();
    }
  }

  private async handleContactField(){
    if (this.isReadOnlyMode) return;
    this.uiService.prevView = this.uiService.activeView;
    this.uiService.activeView = '';
    this.contactService.contactPageMode = ComponentViewMode.SELECTION;
    this.contactService.accessedContactListFrom = PageName.KOLDetailsComponent;
    let passedData = {
      type: 'PushedContactSelectionView', 
      callbackEvent: (data: any) => this._handleContactComponentCallback(data),
      contactListData:
      {
          for:'KOLStatusContactSelection',
          kol: this.currentKOLStatus
      }
  };
    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, passedData, PageName.KOLDetailsComponent);
    this.agendaFooterService.updateButtons([''],true);
  }

  private async _handleContactComponentCallback(data) {
    if (data?.isDone) {
      if (this.device.isOffline) return;
      if (data && data.selectedItem) {
        this.currentKOLStatus.contactId = data.selectedItem.ID;
        this.currentKOLStatus.contactName = data.selectedItem.fullName;       
      }else if(data && data.clearValue){
        this.currentKOLStatus.contactId = '';
        this.currentKOLStatus.contactName = '';
      }
      this.currentKOLStatus.isPendingPushToDynamics = true;
      this._updateCurrentKOLStatus();
    }
    // this.agendaFooterService.initButtons(AgendaFooterView.ContactKOL);
  }

  private async handleSpecialitySelect(data) {
    if (this.isReadOnlyMode) return;
    if(Array.isArray(this.currentSpecialties) && this.currentSpecialties.length > 0){
      let listData = [];
      let currentStageIndex:number;
      for(let i=0;i< this.currentSpecialties.length;i++){
        const specialty = this.currentSpecialties[i];
        let obj = {
          id: specialty.id,
          title: specialty.name,
        }
        if(specialty.id == this.currentKOLStatus.specialtyId){
          currentStageIndex = i;
          obj['isSelected'] = true;
        }
        listData.push(obj);
      }
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'KOL-Status-Sepcialty-Select',
        data: listData,
      };
      this._dropdownPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data });
      this._dropdownPopover.onDidDismiss().then(async (data) => {
        data = data.data;
        if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.currentKOLStatus.specialtyId) {
          this.currentKOLStatus.specialtyId = data.selectedItems[0].id;
          this.currentKOLStatus.specialtyName = data.selectedItems[0].title;
          this._updateCurrentKOLStatus();
        }
        this._dropdownPopover = undefined;
      });
      this._dropdownPopover.present();
    }
  }

  private async handleDiseaseAreaSelect(data) {
    if (this.isReadOnlyMode) return;
    if(Array.isArray(this.kolStatusService.diseaseAreasList) && this.kolStatusService.diseaseAreasList.length > 0){
      let listData = [];
      let currentStageIndex:number;
      this.kolStatusService.diseaseAreasList = _.uniqBy(this.kolStatusService.diseaseAreasList, 'id');
      for(let i=0;i< this.kolStatusService.diseaseAreasList.length;i++){
        const da = this.kolStatusService.diseaseAreasList[i];
        let obj = {
          id: da.id,
          title: da.title,
        }
        if(da.id == this.currentKOLStatus.diseaseAreaId){
          currentStageIndex = i;
          obj['isSelected'] = true;
        }
        listData.push(obj);
      }
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'KOL-Status-Disease-Area-Select',
        data: listData,
      };
      this._dropdownPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data });
      this._dropdownPopover.onDidDismiss().then(async (data) => {
        data = data.data;
        if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.currentKOLStatus.diseaseAreaId) {
          this.currentKOLStatus.diseaseAreaId = data.selectedItems[0].id;
          this.currentKOLStatus.diseaseAreaName = data.selectedItems[0].title;
          this._updateCurrentKOLStatus();
        }
        this._dropdownPopover = undefined;
      });
      this._dropdownPopover.present();
    }
  }

  private onClosePage() {
    if (this.isLayoverPushView) {
      if (this.layoverViewPushedFrom === 'KOLStatusTool') {
        if(this.kolStatusService.isKOLCreateInProgress){
          this._showDiscardConfirmationPopover().then(res=> {
            this.navService.setChildNavRoot(NothingSelectedView,PageName.NothingSelectedView,PageName.MedicalInsightPageComponent);
            this.navService.setChildNavRightPaneView(false);
            this.footerService.initButtons(FooterViews.Contacts);
          }).catch(err=> {
            return;
          })
        }else{
          this.navService.setChildNavRoot(NothingSelectedView,PageName.NothingSelectedView,PageName.MedicalInsightPageComponent);
          this.navService.setChildNavRightPaneView(false);
          this.footerService.initButtons(FooterViews.Contacts);
        }
      }
    }
  }

  private _showDiscardConfirmationPopover():Promise<any>{
    let cancelText = this.translate.instant("CANCEL");
    if(this.translate.currentLang == 'it') {
      cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
    }
    return new Promise(async (resolve,reject)=> {   
      this.alertService.showAlert({
        title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
        message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')}, this.translate.instant('DISCARD'), cancelText
      ).then (res => {
        if(res.role == "ok") {
          this.kolStatusService.isKOLCreateInProgress = false;
          resolve(true);
        }else{
          reject(null);
        }
      });
    });
  }

  private _updateCurrentKOLStatus(){
    console.log("Updated Current KOL Status");
    this.kolStatusService.isKOLCreateInProgress = true;
    this._initPageTitle();
    this._initFormFields();
  }

  onFooterButtonClicked(buttonId: string) {
    switch (buttonId) {
      case 'savecontactkolstatus':
        this.handleButtonClick('save');
        break;
      case 'approvecontactkolstatus':
        this.handleButtonClick('approve');
        break;
      case 'rejectcontactkolstatus':
        this.handleButtonClick('reject');
        break;
      default:
        break;
    }
  }

}