interface IElectronApi {
  readonly platform: NodeJS.Platform;
  readonly versions: NodeJS.ProcessVersions;
  readonly osVersion: string;
  createWriteStream(
    path: string
  ): {
    write: (chunk: any, callback?: (error: Error) => void) => boolean;
    close: (callback?: (err?: NodeJS.ErrnoException) => void) => void;
  };
  createDir(dir: string): Promise<void>;
  removeFile(path: string): Promise<void>;
  removeRecursively(path: string): Promise<void>;
  getPath(name: string): string;
  convertFileSrc(src: string): string;
  send(channel: string, ...data): void;
  addListener(channel: string, callback: (event, ...args) => void);
  removeListener(channel: string, callback: (event, ...args) => void);
  openExternal(url: string): Promise<void>;
  fileExists(path : string): Promise<boolean>;
  sendOutlookEmail(to: string, cc: string, bcc: string, subject: string, body: string, attachment: string[]): Promise<boolean>
}

const electronApi: IElectronApi = (window as any).electron;

export function IpcEventListner(event: string): MethodDecorator {
  return function (target, propertyKey, descriptor) {
    if (!electronApi) return;
    let method: Function = descriptor.value as any;
    let ngOnInit = target.constructor.prototype.ngOnInit;
    let ngOnDestroy = target.constructor.prototype.ngOnDestroy;
    let eventId = `__ipc_listeners__`;
    target.constructor.prototype.ngOnInit = function (...args) {
      let handler = method.bind(this);
      electronApi.addListener(event, handler);
      if (!this[eventId]) {
        this[eventId] = [];
        target.constructor.prototype.ngOnDestroy = function (...args) {
          if (this[eventId]) {
            this[eventId].forEach(fn => fn());
            delete this[eventId];
          }
          ngOnDestroy && ngOnDestroy.apply(this, args);
        }
      }
      this[eventId].push(() => electronApi.removeListener(event, handler));
      ngOnInit && ngOnInit.apply(this, args);
    }
  }
}


export { electronApi };
