<ion-header>
    <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="hideFilterButton" [searchDebounce]="debounceTime" [(searchText)]="presentationSearchText" (onControlClick)="onPageTitleControlClick($event)" (onSearchChange)="onInput($event.detail)" (onSearchFocus)="ionSearchFocus($event)"  (ionBlur)="onSearchBlur($event)"
        (onSearchBarClick)="clickSearchArea($event)" (onFilterClick)="openFilterMenu($event)" [filterButtonBadgeCount]="getFilterButtonBadgeCount()" (onEnterClick)="onClickEnter($event)"></ind-header-left>

    <ion-item-divider class="keyControlsArea" color="light">
        <div class="key-container">
            <ion-button slot="start" icon-only fill="clear" *ngIf="!isAddToMeeting && !presentationService.isSelectMode" ion-button color="primary" (click)="selectMode()">
                <div>{{'SELECT' | translate}}</div>
            </ion-button>
            <ion-button slot="start" icon-only fill="clear" *ngIf="!isAddToMeeting && presentationService.isSelectMode" ion-button color="primary" (click)="selectMode()">
                <div>{{'CANCEL' | translate}}</div>
            </ion-button>
            <ion-button slot="start" icon-only fill="clear" *ngIf="presentationService.isSelectMode">
                <ion-checkbox (ionChange)="selectAll($event)" [(ngModel)]="isSelectAll"></ion-checkbox>
                {{'ALL' | translate}}
            </ion-button>
            <ion-button slot="start" icon-only fill="clear" *ngIf="presentationService.isSelectMode" [disabled]="!hasSelectedPresList()" (click)='bulkFavouritePres($any(selectedPresList))'>
                {{!hasSelectedPresList() ? this.translate.instant('FAVORITE') : !favouriteButtonState() ? this.translate.instant('UNFAVORITE') : this.translate.instant('FAVORITE')}}
            </ion-button>
            <ion-button slot="start" icon-only fill="clear" *ngIf="presentationService.isSelectMode && !showRemoveTag" [disabled]="!hasSelectedPresList()" (click)="addToTag()" >
              {{!hasSelectedPresList() ? this.translate.instant('ADD_TO_TAG') :  this.translate.instant('ADD_TO_TAG')}}
            </ion-button>
            <ion-button slot="start" icon-only fill="clear" *ngIf="presentationService.isSelectMode && !showRemoveTag" [disabled]="!hasSelectedPresList()" (click)="createNewTag()" >
              {{!hasSelectedPresList() ? this.translate.instant('CREATE_NEW_TAG') :  this.translate.instant('CREATE_NEW_TAG')}}
            </ion-button>
            <ion-button icon-only fill="clear" *ngIf="isMobileDevice() && presentationService.isSelectMode" [disabled]="!hasSelectedPresList() || deviceService.isOffline" (click)='bulkDownloadPres($any(selectedPresList))'>
                {{!hasSelectedPresList() ? this.translate.instant('DOWNLOAD') : (!downloadButtonState() ? this.translate.instant('UNDOWNLOAD') : this.translate.instant('DOWNLOAD'))}}
            </ion-button>
            <ion-button item-left icon-only fill="clear" color="primary" (click)="saveAdvancedSearch()" [disabled]="!searching || disableSaveSearch" *ngIf="!presentationService.isSelectMode">
                {{ 'SAVE_SEARCH' | translate}}
            </ion-button>
            <ion-button slot="end" icon-only fill="clear" color="primary" *ngIf=" presentationService.isSelectMode && showRemoveTag"  [disabled]="!hasSelectedPresList() " (click)="removeResourceFromTag(selectedTagData)">
              {{'REMOVE_FROM_TAGS' | translate }}
          </ion-button>
            <div class="toggleOption" *ngIf="!isMobileDevice() || (isMobileDevice && !presentationService.isSelectMode)">
                <ion-icon (click)='toggleListType(LISTTYPE.GRID)' *ngIf="(listType === LISTTYPE.LIST)" class="list-icon" src="assets/imgs/list-view.svg"></ion-icon>
                <ion-icon (click)='toggleListType(LISTTYPE.LIST)' *ngIf="(listType === LISTTYPE.GRID)" class="grid-icon" src="assets/imgs/grid-view.svg"></ion-icon>
            </div>
        </div>
    </ion-item-divider>
    <!-- selected serach suggestions area -->
    <div class="selectedSearchSuggestions" color="light" *ngIf="selectedSuggestionsData && selectedSuggestionsData.length">
        <ion-icon name="ios-close-circle" (click)="removeAllSelectedSuggestions()" class="clearSuggestions"></ion-icon>
        <span *ngFor="let suggestion of selectedSuggestionsData" class="suggestionNameBubble">
          {{suggestion.selectedFacet}}
          <img src="assets/imgs/close_pill.svg" class="removeButton" (click)="removeSelectedSuggestion(suggestion)"/>
        </span>
    </div>

    <div class="selectedSearchSuggestions" color="light" *ngIf="selectedFilterData && selectedFilterData.length ">
      <span *ngFor="let filter of filterNameBubble; let i=index">
        <ion-text *ngIf="filter.isSavedFilter && i==0" class="category-name"> {{filter.savedFilterName}} : </ion-text>
        <ion-text *ngIf="!filter.isSavedFilter" class="category-name"> {{filter.categoryName}} : </ion-text>
        <span *ngFor="let facet of filter.suggestion" class="suggestionNameBubble">
          {{facet.selectedFacet}}
          <img src="assets/imgs/close_pill.svg" class="removeButton" (click)="removefilterSelectedSuggestion(facet, filter)" />
        </span>
      </span>
    </div>
</ion-header>

<ion-content #listTop no-padding id="pres-list-content" class="has-header" [ngClass]="{'vectorimageleft': !searching && viewPres?.length === 0}">
    <ion-item-group class="presentationContainer" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
        <!-- Selected Presentation Section  -->
        <ind-section-header *ngIf="isAddToMeeting && meetingContent.length>0" [viewData]="{id:'selected-presentation', title: translate.instant('SELECTED_CAP') + ' (' + meetingContent.length + ')'}"></ind-section-header>
        <ion-list *ngIf="isAddToMeeting && meetingContent?.length> 0">
            <ng-container *ngFor="let content of meetingContent">
                <presentation-item (showPreview)="showPreview(content)" [showcancelIcon]="(viewMode === presMode.ADDTOEVENT) ? (content.hideCancelButton ? false : true) : true" [isMeetingPres]="true" [listType]="LISTTYPE.LIST" [presItemTransform]="presItemTransform"
                    [pres]="content" (addToMeeting)="addPresToArray(content)" (downloadPres)="downloadPres(content)" (favouritePres)="singleFavPres(content)" [viewMode]="viewMode">
                </presentation-item>
            </ng-container>
        </ion-list>

        <!--Adding this block to display the added/updated presentations-->
        <ind-section-header *ngIf="newPres.length && !isAddToMeeting" [viewData]="{id:'new-presentation', title: translate.instant('NEW_PRESENTATIONS_CAP') + ' (' + newPres?.length + ')'}"></ind-section-header>
        <ion-list *ngIf="newPres.length && !isAddToMeeting">
            <ng-container *ngFor="let content of newPres">
                <presentation-item (showPreview)="showPreview(content)" [listType]="LISTTYPE.LIST" [pres]="content" (downloadPres)="downloadPres(content)" [presItemTransform]="presItemTransform" (favouritePres)="singleFavPres(content)" [viewMode]="viewMode" (togglePresSelection)="updateSelPresList(content)">
                </presentation-item>
            </ng-container>
        </ion-list>

        <!-- All Presentations -->
        <ng-container *ngIf="!searching && !filtering">
            <ind-section-header [viewData]="allPresentationHeader" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
            <ion-list *ngIf="presToDisplay.length == 0">
                <div *ngIf="!isAddToMeeting || isAddToEvent" class="no-data-message">
                    {{'NO_PRESENTATIONS' | translate}}
                </div>
                <div *ngIf="isAddToMeeting && !isAddToEvent && (!this.resourceService.allResources || this.resourceService.allResources.length == 0)" class="no-data-message">
                    {{'NO_CONTENTS' | translate}}
                </div>
            </ion-list>
            <ind-list-grid *ngIf="viewPres" [listRef]="presListRef" [gridRef]="presGridRef" [viewMetaData]="viewMetaData" [data]="viewPres" [listType]="listType"></ind-list-grid>
            <ion-infinite-scroll (ionInfinite)="doInfinite($event.detail,$event)" threshold="30%">
                <ion-infinite-scroll-content></ion-infinite-scroll-content>
            </ion-infinite-scroll>
        </ng-container>

        <!-- Search Presentation Results -->
        <ng-container *ngIf="searching || filtering">
            <ind-section-header [viewData]="resultPresentationHeader" (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
            <ion-list *ngIf="visibleFilteredList.length == 0">
                <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
            </ion-list>
            <ind-list-grid *ngIf="viewPres" [listRef]="presListRef" [gridRef]="presGridRef" [viewMetaData]="viewMetaData" [data]="viewPres" [listType]="listType"></ind-list-grid>
            <ion-infinite-scroll (ionInfinite)="doInfinite($event.detail,$event)" threshold="30%">
                <ion-infinite-scroll-content></ion-infinite-scroll-content>
            </ion-infinite-scroll>
        </ng-container>
    </ion-item-group>
</ion-content>

<!-- <ion-footer> -->
    <footer-toolbar [footerView]="'masterView'" [selectedView]="'presentations'"></footer-toolbar>
<!-- </ion-footer> -->

<ng-template #presListRef let-data="data">
    <presentation-item class='list-item' [presItemTransform]="presItemTransform" (showPreview)="showPreview(data)" [isSelected]="isPresAddedToMeeting(data)" [sortedBy]="sortBy.text" [pres]="data" (addToMeeting)="addPresToArray(data)" (downloadPres)="downloadPres(data)"
        [listType]="'list'" (favouritePres)="singleFavPres(data)" [viewMode]="viewMode" (togglePresSelection)="updateSelPresList(data)">
    </presentation-item>
</ng-template>

<ng-template #presGridRef let-item="data">
    <presentation-item class='grid-item' [presItemTransform]="presItemTransform" (showPreview)="showPreview(item)" [isSelected]="isPresAddedToMeeting(item)" [sortedBy]="sortBy.text" [pres]="item" (addToMeeting)="addPresToArray(item)" (downloadPres)="downloadPres(item)"
        [listType]="'grid'" (favouritePres)="singleFavPres(item)" [viewMode]="viewMode" (togglePresSelection)="updateSelPresList(item)">
    </presentation-item>
</ng-template>

<search-suggestion-popover (click)="clickedInSuggestionsArea($event)" (selectionMade)="handleFacetSelection($event)" [suggestionsData]="suggestionsData" [searchKey]="searchKey" *ngIf='suggestionsActive' (selectSavedSearch)="handleSavedSearchSelection($event)"
 [from]="uiService.page.PresentationPageComponent" (selectTag)="handleTagSelection($event)" >
</search-suggestion-popover>
