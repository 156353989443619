import { DeviceService } from '@omni/services/device/device.service';
import { Utility } from './../../utility/util';
import { ContactOfflineService, ContactTimelineActivityByContact, ContactTimelineContactByActivity } from '@omni/services/contact/contact.service';
import { Injectable } from '@angular/core';
import { DiskService } from '../disk/disk.service';
import { GlobalErrorHandler } from '../error-handler/error-handler-service';
import { BrandOfflineService } from '../brand/brand.service';
import { AuthenticationService } from '../authentication.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { SelectedSuggestionPillDataModel } from '../../models/search-config-data-model';
import { SearchConfigService } from './../search/search-config.service';
import { ComponentViewMode } from '../ui/ui.service';
import { Events } from '@omni/events';
import { Account } from '@omni/classes/account/account.class';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { CallPlanCustomerType, CallPlanFilter } from '@omni/classes/call-plan/rep-call-plan.class';
import { Contact } from '@omni/classes/contact/contact.class';
import { Activity, ActivityType, ActivityTypeCodeRaw } from '@omni/classes/activity/activity.class';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { PhoneActivity } from '@omni/classes/activity/phone.activity.class';
import { isValid } from 'date-fns';
import { ChannelActivityType } from '@omni/classes/consent/channel.class';
import { ActivityService } from '../activity/activity.service';
import { UserPositionEdgeAnalyticsMetrics } from '@omni/interfaces/call-plan/user-position-edge-analytics-metrics.interface';
import { EmailActivity } from '@omni/classes/activity/email.activity.class';

@Injectable({
  providedIn: 'root'
})
export class CallPlanOfflineService {
    public callPlanFilter: string = "All Products";
    public multiProductSelectionFilter: string[] = [];
    public callPlanPageView:string;
    public selectedCallPlan:any;
    public selectedRepCallPlan:any;
    public selectedCustomerCallPlanId:string = "";
    public selectedCallPlanAtHome:any;
    public meetingOrEmailFilter: string = 'Engagements';
    public positionFilter: string;
    public callPlanMode:string;

    public repCallPlanDetailFilterValue: string = 'all';
    public repCallPlanDetailCompletionFilter: boolean = false;
    public repCallPlanDetailNonCompletionFilter: boolean = false;
    public repCallPlanDetailStatusFilterOpen: boolean = false;
    public repCallPlanDetailStatusFilterApproved: boolean = false;
    public repCallPlanDetailStatusFilterForReview: boolean = false;
    public repCallPlanDetailPositionFilter: string = '';
    public showAccountLIst:Boolean = false;
    public callPlanAccessedFromScheduler: boolean = false;
    public cp_selectedAccount:Array<Account>=[];
    public callplanDetailsLoaded:boolean = true;
    public callPlanSearchText: string = ''

    // advanced search
    myRecentSearches: SelectedSuggestionPillDataModel[] = [];
    teamRecentSearches: SelectedSuggestionPillDataModel[] = [];
    public contactPageMode: ComponentViewMode;

    public segmentCallPlans:any[] = [];
    public teamSegmentCallPlans:any[] = [];
    public teamPositionsOtherRepPlans:any[] = [];
    public otherRepCallPlansList$: Subject<any> = new Subject();
  public segmentCallPlansList$: Subject<any> = new Subject();
  public currentFooterFilter: string = 'call-plan-current';

  public updateActivityIdsForOfflineTimelineByContactId: Array<ContactTimelineActivityByContact> = [];
  
  public repNamesWithPositions = [];

    constructor(
        private disk: DiskService,
        private errorhandler:GlobalErrorHandler,
        private productService: BrandOfflineService,
        private authenticationService: AuthenticationService,
        private searchConfigService: SearchConfigService,
        private events: Events,
        private readonly contactService: ContactOfflineService,
        private deviceService: DeviceService,
        private activityService: ActivityService,
    ) {
    }

    get loadedCallPlans(): boolean {
        return this.segmentCallPlans.length > 0;
    }
    get loadedTeamCallPlans(): boolean {
      return this.teamSegmentCallPlans.length > 0;
    }

    get getCallPlanMode(){
        return this.callPlanMode;
      }

    setCallPlanMode(mode:string){
        this.callPlanMode = mode;
    }

    get getStringForFilterValue(): string {
        //Need to return a friendly string for the current filter set
        //Filter can be Status, completed or position

        //If the filter is set to all simply return All
        if (this.repCallPlanDetailFilterValue)
        return 'All';

        //Else we have to specify all the current filters set
        let returnStringCount: number = 0;

        if (this.repCallPlanDetailCompletionFilter) returnStringCount = returnStringCount + 1;
        if (this.repCallPlanDetailNonCompletionFilter) returnStringCount = returnStringCount + 1;
        if (this.repCallPlanDetailStatusFilterOpen) returnStringCount = returnStringCount + 1;
        if (this.repCallPlanDetailStatusFilterApproved) returnStringCount = returnStringCount + 1;
        if (this.repCallPlanDetailStatusFilterForReview) returnStringCount = returnStringCount + 1;

        this.authenticationService.user.positions.map(position => {
            if (position.selectedRepCallPlanFilter) returnStringCount++;
        });

        // Position Filter for Managers
        this.authenticationService.childPositions.map(position => {
            if (position.selectedRepCallPlanFilter) returnStringCount++;
        });


        return (returnStringCount == 0 ? 'All' : `Filter(${returnStringCount})`);
    }

    get formattedCallPlans(): { past:any[], present:any[], future:any[] } {
      const currentDay: Date = new Date();

      let formattedCallPlanObj = {
          past: [],
          present: [],
          future: []
      };

      this.segmentCallPlans.map(plan => {
          const endDateTimeStampAtMidnight = Utility.changeUTCDateToLocalDateWith0Time(new Date(plan.indskr_enddate).getTime(),true).getTime();
          const startDateTimeStampAtMidnight = Utility.changeUTCDateToLocalDateWith0Time(new Date(plan.indskr_startdate).getTime()).getTime();
          //If end date is in the past
          if (endDateTimeStampAtMidnight < currentDay.getTime()) {
              formattedCallPlanObj.past.push(plan);
              return;
          }

          //If start date is past current date
          //And end date is greater than current date
          if ((startDateTimeStampAtMidnight < currentDay.getTime())
          && (endDateTimeStampAtMidnight > currentDay.getTime())) {
              formattedCallPlanObj.present.push(plan);
              return;
          }

          formattedCallPlanObj.future.push(plan);
          return;
      });

      return formattedCallPlanObj;
  }

    get formattedTeamCallPlans(): { past: any[], present: any[], future: any[] } {
        const currentDay: Date = new Date();

        let formattedCallPlanObj = {
            past: [],
            present: [],
            future: []
        };

        this.teamSegmentCallPlans.map(plan => {
          plan.isTeamCallPlan = true;
          // this.mapTeamCallPlansToSearchIndex(plan);
            const endDateTimeStampAtMidnight = Utility.changeUTCDateToLocalDateWith0Time(new Date(plan.indskr_enddate).getTime(),true).getTime();
            const startDateTimeStampAtMidnight = Utility.changeUTCDateToLocalDateWith0Time(new Date(plan.indskr_startdate).getTime()).getTime();
            //If end date is in the past
            if (endDateTimeStampAtMidnight < currentDay.getTime()) {
                formattedCallPlanObj.past.push(plan);
                return;
            }

            //If start date is past current date
            //And end date is greater than current date
            if ((startDateTimeStampAtMidnight < currentDay.getTime())
            && (endDateTimeStampAtMidnight > currentDay.getTime())) {
                formattedCallPlanObj.present.push(plan);
                return;
            }

            formattedCallPlanObj.future.push(plan);
            return;
        });

        return formattedCallPlanObj;
    }

    getPresentCallPlans(callPlans: any[]) {
      const presentPlans = [];
      const currentDate = new Date();

      if (Array.isArray(callPlans)) {
        for (const callPlan of callPlans) {
          const endDateTimeStampAtMidnight = Utility.changeUTCDateToLocalDateWith0Time(new Date(callPlan.indskr_enddate).getTime(),true).getTime();
          const startDateTimeStampAtMidnight = Utility.changeUTCDateToLocalDateWith0Time(new Date(callPlan.indskr_startdate).getTime()).getTime();

          if ((startDateTimeStampAtMidnight < currentDate.getTime())
            && (endDateTimeStampAtMidnight > currentDate.getTime())) {
                presentPlans.push(callPlan);
          }
        }
      }

      return presentPlans;
    }

    public setRepCallPlanDetailPositionFilter(){
        let count = 0;
        this.authenticationService.childPositions.map( position => {
            if(position.selectedRepCallPlanFilter) count++;
        });
        this.repCallPlanDetailPositionFilter = 'positions_' + count.toString();
    }

    public resetAllRepCallPlanFilters() {
        this.repCallPlanDetailFilterValue = 'All';
        this.repCallPlanDetailCompletionFilter = false;
        this.repCallPlanDetailNonCompletionFilter = false;
        this.repCallPlanDetailStatusFilterOpen = false;
        this.repCallPlanDetailStatusFilterForReview = false;
        this.repCallPlanDetailStatusFilterApproved = false;
        this.repCallPlanDetailPositionFilter = '';

        this.authenticationService.user.positions.map(position => {
            position.selectedRepCallPlanFilter = false;
        });
    }

    public selectEmailOrMeetingFilter(newFilterOpt: string) {
        this.meetingOrEmailFilter = newFilterOpt;
        this.events.publish('selectedCallPlanFilter');
    }

    public async loadUserPositionEdgeAnalyticsMetrics(): Promise<UserPositionEdgeAnalyticsMetrics[] | null> {
      try {
        return (await this.disk.retrieve(DB_KEY_PREFIXES.USER_POSITION_EDGE_ANALYTICS_METRICS, true))?.raw ?? null;
      } catch (error) {
        console.error('loadUserPositionEdgeAnalyticsMetrics: ', error);
      }
    }

    async purgeData(maxEndDateUnixTimestamp: number) {
        if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.CALL_PLANS)) {
            return;
        }
        await this.purgeCallPlans(maxEndDateUnixTimestamp.toString());
        await this.purgeUserPositionEdgeAnalyticsMetrics();

        // if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.TEAM_CALL_PLANS)) {
        //     return;
        // }
        // await this.purgeCallPlans(maxEndDateUnixTimestamp.toString(), true);
    }

    private async purgeCallPlans(maxEndDateUnixTimestampInString: string) {
        //const document = await this.disk.retrieve((isTeam ? DB_KEY_PREFIXES.TEAM_CALLPLAN : DB_KEY_PREFIXES.MY_CALLPLAN));
        const document = await this.disk.retrieve((DB_KEY_PREFIXES.MY_POSITON_CALL_PLANS));
        let filteredCallPlans;
        if (document && Array.isArray(document.raw)) {
            filteredCallPlans = document.raw.filter(raw => {
                if (new Date(raw['indskr_enddate']).getTime() >= parseInt(maxEndDateUnixTimestampInString)) {
                    return true;
                } else {
                    return false;
                }
            });

            // Only write to local db when something got purged
            if (filteredCallPlans.length < document.raw.length) {
                document.raw = filteredCallPlans;
                try {
                    await this.disk.updateDocWithIdAndRev(document);
                } catch (error) {
                    console.error('purgeCallPlans: ', error);
                }
            }
        }
        const document2 = await this.disk.retrieve((DB_KEY_PREFIXES.OTHER_POSITON_CALL_PLANS));
        let filteredCallPlans2;
        if (document2 && Array.isArray(document2.raw)) {
            filteredCallPlans2 = document2.raw.filter(raw => {
                if (new Date(raw['indskr_enddate']).getTime() >= parseInt(maxEndDateUnixTimestampInString)) {
                    return true;
                } else {
                    return false;
                }
            });

            // Only write to local db when something got purged
            if (filteredCallPlans2.length < document2.raw.length) {
                document2.raw = filteredCallPlans2;
                try {
                    await this.disk.updateDocWithIdAndRev(document2);
                } catch (error) {
                    console.error('purgeCallPlans: ', error);
                }
            }
        }
    }

    private async purgeUserPositionEdgeAnalyticsMetrics() {
      const document = await this.disk.retrieve(DB_KEY_PREFIXES.USER_POSITION_EDGE_ANALYTICS_METRICS, true);

      if (document?.raw) {
        document.raw = undefined;
        await this.disk.updateDocWithIdAndRev(document);
      }
    }

    // advanced search - My Call Plans - category data
    // public mapMyCallPlansFieldsToSearchIndex(newCallPlans:CallPlan){
    //     if(!this.searchConfigService.isConfigInitiated){
    //       this.searchConfigService.initSearchConfigs();
    //       this.searchConfigService.isConfigInitiated = true;
    //     }
    //     if(newCallPlans.productName && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Products').values.some(o=> o==newCallPlans.productName))
    //         this.searchConfigService.myCallPlansSearchIndexesConfig.find(config=>config.categoryName=='Products').values.push(newCallPlans.productName);

    //     if(newCallPlans.segmentName && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Segments').values.some(o=> o == newCallPlans.segmentName)){
    //       this.searchConfigService.myCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Segments').values.push(newCallPlans.segmentName);
    //     }
    //     newCallPlans.repCallPlans.forEach(repCallPlan =>{
    //         if(repCallPlan.customerName && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Customers').values.some(o=> o == repCallPlan.customerName))
    //             this.searchConfigService.myCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Customers').values.push(repCallPlan.customerName);
    //     })
    //     newCallPlans.accountsName.forEach(accountName =>{
    //         if(newCallPlans.accountsName && newCallPlans.accountsName.length > 0 && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Accounts').values.some(o=> o == accountName))
    //             this.searchConfigService.myCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Accounts').values.push(accountName);
    //     })
    // }

    // advanced search - My Call Plans - category data
  public mapMyCallPlansToSearchIndex(newCallPlans) {
      this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Products').values =[];
      this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Customers').values =[];
      this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Affiliated Accounts').values =[];
      this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Specialty').values =[];
    newCallPlans.forEach(async newCP => {
      if (!this.searchConfigService.isConfigInitiated) {
        this.searchConfigService.initSearchConfigs();
        this.searchConfigService.isConfigInitiated = true;
      }
      newCP.repPlans.forEach(p => {
        if (p.productid_Formatted && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Products').values.some(o => o == p.productid_Formatted))
          this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Products').values.push(p.productid_Formatted);
      })

      if (newCP.customerName && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Customers').values.some(o => o == newCP.customerName)) {
        this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Customers').values.push(newCP.customerName);
      }
      let accounts = this.contactService.getContactByID(newCP.contactId)?.accountRelationships || []
      accounts.forEach(account => {
        if (account.accountName.length > 0 && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Affiliated Accounts').values.some(o => o == account.accountName))
          this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Affiliated Accounts').values.push(account.accountName);
      })
      newCP.repPlans.forEach(sp => {
        if (sp.specialtyid_Formatted && !this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Specialty').values.some(o => o == sp.specialtyid_Formatted))
          this.searchConfigService.myCallPlansSearchIndexesConfig.find(config => config.categoryName == 'Specialty').values.push(sp.specialtyid_Formatted);
      })
    })
  }

  public mapMyGoalsPlansToSearchIndex(newCallPlans) {
    newCallPlans.forEach(newCP => {
      if (!this.searchConfigService.isConfigInitiated) {
        this.searchConfigService.initSearchConfigs();
        this.searchConfigService.isConfigInitiated = true;
      }
      if (!_.isEmpty(newCP.repPlans) && newCP.repPlans[0].productid_Formatted && !this.searchConfigService.myGoalsPlansSearchIndexesConfig.find(config => config.categoryName == 'Products').values.some(o => o == newCP.repPlans[0].productid_Formatted))
        this.searchConfigService.myGoalsPlansSearchIndexesConfig.find(config => config.categoryName == 'Products').values.push(newCP.repPlans[0].productid_Formatted);
      if(!_.isEmpty(newCP.repPlans)) {
        newCP.repPlans.forEach(repCallPlan => {
          repCallPlan.accounts = this.contactService.getContactByID(repCallPlan.contactId)?.accountRelationships || []
          repCallPlan.accounts.forEach(account => {
            if (account.accountName.length > 0 && !this.searchConfigService.myGoalsPlansSearchIndexesConfig.find(config => config.categoryName == 'Affiliated Accounts').values.some(o => o == account.accountName))
              this.searchConfigService.myGoalsPlansSearchIndexesConfig.find(config => config.categoryName == 'Affiliated Accounts').values.push(account.accountName);
          })
        });
      }
    });
  }

    // advanced search - Team Call Plans - category data
    // public mapTeamCallPlansFieldsToSearchIndex(newCallPlans:CallPlan){
    //     if(!this.searchConfigService.isConfigInitiated){
    //       this.searchConfigService.initSearchConfigs();
    //       this.searchConfigService.isConfigInitiated = true;
    //     }
    //     if(newCallPlans.productName && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Products').values.some(o=> o==newCallPlans.productName))
    //         this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=>config.categoryName=='Products').values.push(newCallPlans.productName);

    //     if(newCallPlans.segmentName && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Segments').values.some(o=> o == newCallPlans.segmentName)){
    //       this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Segments').values.push(newCallPlans.segmentName);
    //     }
    //     newCallPlans.repCallPlans.forEach(repCallPlan =>{
    //         if(repCallPlan.customerName && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Customers').values.some(o=> o == repCallPlan.customerName))
    //             this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Customers').values.push(repCallPlan.customerName);
    //     })
    //     newCallPlans.accountsName.forEach(accountName =>{
    //         if(newCallPlans.accountsName && newCallPlans.accountsName.length > 0 && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Accounts').values.some(o=> o == accountName))
    //             this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Accounts').values.push(accountName);
    //     })
    //     newCallPlans.repCallPlans.forEach(repCallPlan =>{
    //         if(repCallPlan.usersName && Array.isArray(repCallPlan.usersName)) {
    //             repCallPlan.usersName.forEach(userName =>{
    //                 if(userName && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Users').values.some(o=> o ==userName))
    //                     this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Users').values.push(userName);
    //             })
    //         }
    //     })
    //     newCallPlans.repCallPlans.forEach(repCallPlan =>{
    //         if(repCallPlan.positionName && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Positions').values.some(o=> o == repCallPlan.positionName))
    //             this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Positions').values.push(repCallPlan.positionName);
    //         if(repCallPlan.otherRepCallPlans && repCallPlan.otherRepCallPlans.length > 0)
    //         repCallPlan.otherRepCallPlans.forEach(otherRepCallPlan=>{
    //             if(otherRepCallPlan.positionName && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=>config.categoryName == 'Positions').values.some(o=> o == otherRepCallPlan.positionName))
    //             this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Positions').values.push(otherRepCallPlan.positionName);
    //         })
    //     })
    // }

    // advanced search - My Call Plans - category data
    public mapTeamCallPlansToSearchIndex(newCP){
        if(!this.searchConfigService.isConfigInitiated){
          this.searchConfigService.initSearchConfigs();
          this.searchConfigService.isConfigInitiated = true;
        }
        if(newCP.repPlans[0].productid_Formatted && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Products').values.some(o=> o==newCP.repPlans[0].productid_Formatted ))
            this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=>config.categoryName=='Products').values.push(newCP.repPlans[0].productid_Formatted );

        if(newCP.repPlans[0].segmentid_Formatted && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Segments').values.some(o=> o == newCP.repPlans[0].segmentid_Formatted)){
          this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Segments').values.push(newCP.repPlans[0].segmentid_Formatted);
        }
        newCP.repPlans.forEach(repCallPlan =>{
            if(repCallPlan.customerName && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Customers').values.some(o=> o == repCallPlan.customerName))
                this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Customers').values.push(repCallPlan.customerName);

            if(repCallPlan.positionName && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Positions').values.some(o=> o == repCallPlan.positionName))
            this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Positions').values.push(repCallPlan.positionName);

            repCallPlan.accounts = this.contactService.getContactByID(repCallPlan.contactId)?.accountRelationships || []
            repCallPlan.accounts.forEach(account =>{
                if(account.accountName.length > 0 && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Affiliated Accounts').values.some(o=> o == account.accountName))
                    this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Affiliated Accounts').values.push(account.accountName);
            })
            repCallPlan.users = this.authenticationService.user.childUsers.filter(u=> u.positionIDs.indexOf(repCallPlan.positionId)>-1)
            repCallPlan.users.forEach(user =>{
                if(user.fullName.length > 0 && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Users').values.some(o=> o == user.fullName))
                    this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Users').values.push(user.fullName);
            })
        })
        // newCallPlans.accountsName.forEach(accountName =>{
        //     if(newCallPlans.accountsName && newCallPlans.accountsName.length > 0 && !this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Accounts').values.some(o=> o == accountName))
        //         this.searchConfigService.teamCallPlansSearchIndexesConfig.find(config=> config.categoryName == 'Accounts').values.push(accountName);
        // })
    }

    // public clear() {
    //     this.callPlans = [];
    //     this.teamCallPlans = [];
    // }
  
  aggregateRepCallPlans(callPlans: any[]) {
    let a = [];
    if (Array.isArray(callPlans)) {
      // Filter out possible duplicates due to HCP having more than one specialty
      const uniqRepPlansArray = _.uniqBy(callPlans, 'indskr_customercallplanid');
      uniqRepPlansArray.map((c) => {
        let plan = c.contactId ? a.find(o => o.contactId == c.contactId) : a.find(o => o.accountId == c.accountId);
        if(c.contactId) {
          c.customerId = c.contactId;
          c.customerName = c.contactFirstName;
          c.initials = c.contactFirstName?.charAt(0).toUpperCase();
          if(!_.isEmpty(c.contactLastName)) {
            c.initials += c.contactLastName?.charAt(0).toUpperCase();
            c.customerName += " " + c.contactLastName;
          }
        }else {
          c.customerId = c.accountId || c.customerId;
          c.customerName = c.accountName;
          c.initials = c.accountName?.charAt(0).toUpperCase();
        }
        c.productName = c.productid_Formatted || '';

        if (plan) {
          plan.repPlans.push(c);
          plan.actualCalls += this.goalOrCompletedValue(c.indskr_hocalls, c.indskr_actualcalls);
          plan.totalGoalCalls += c.indskr_hocalls ? c.indskr_hocalls : 0;
          plan.actualEmails += this.goalOrCompletedValue(c.indskr_hoemails, c.indskr_actualemails);
          plan.totalGoalEmails += c.indskr_hoemails ? c.indskr_hoemails : 0;
          plan.actualF2FMeetings += this.goalOrCompletedValue(c.indskr_f2fgoals, c.totalf2fmeetingscompleted);
          plan.totalGoalF2FMeetings += c.indskr_f2fgoals ? c.indskr_f2fgoals : 0;
          plan.actualRemoteMeetings += this.goalOrCompletedValue(c.indskr_remotemeetinggoals, c.totalremotemeetingscompleted);
          plan.totalGoalRemoteMeetings += c.indskr_remotemeetinggoals ? c.indskr_remotemeetinggoals : 0;
          plan.actualPhoneCalls += this.goalOrCompletedValue(c.indskr_phonecallgoals, c.totalphonecallscompleted);
          plan.totalGoalPhoneCalls += c.indskr_phonecallgoals ? c.indskr_phonecallgoals : 0;
          plan.actualEvents += this.goalOrCompletedValue(c.indskr_eventgoals, c.indskr_completedevents);
          plan.totalGoalEvents += c.indskr_eventgoals ? c.indskr_eventgoals : 0;
          plan.allRepCallPlansApproved = plan.allRepCallPlansApproved == true && c.statuscode == 2 ? true : false;
          plan.completedGoals = plan.actualCalls + plan.actualEmails + plan.actualF2FMeetings + plan.actualRemoteMeetings + plan.actualPhoneCalls + plan.actualEvents;
          plan.definedGoals = plan.totalGoalCalls + plan.totalGoalEmails + plan.totalGoalF2FMeetings + plan.totalGoalRemoteMeetings + plan.totalGoalPhoneCalls + plan.totalGoalEvents;
          plan.percentage = plan.completedGoals && plan.completedGoals > 0 ? _.toNumber(((plan.completedGoals / plan.definedGoals) * 100).toFixed(0)) : 0;
        } else {
          plan = {
            'cycleplanid': c.cycleplanid,
            'contactId': c.contactId || '',
            'accountId': c.accountId || '',
            'customerId': c.contactId || c.accountId || '',
            'customerType' : c.contactId ? CallPlanCustomerType.CONTACT : CallPlanCustomerType.ACCOUNT,
            'customerName': c.customerName,
            'initials': c.initials,
            'cycleplanname': c.cycleplanname,
            'repPlans': [c],
            'indskr_enddate': c.indskr_enddate,
            'indskr_startdate': c.indskr_startdate,
            'actualCalls': this.goalOrCompletedValue(c.indskr_hocalls, c.indskr_actualcalls),
            'totalGoalCalls': c.indskr_hocalls || 0,
            'actualEmails': this.goalOrCompletedValue(c.indskr_hoemails, c.indskr_actualemails),
            'totalGoalEmails': c.indskr_hoemails || 0,
            'actualF2FMeetings': this.goalOrCompletedValue(c.indskr_f2fgoals, c.totalf2fmeetingscompleted),
            'totalGoalF2FMeetings': c.indskr_f2fgoals || 0,
            'actualRemoteMeetings': this.goalOrCompletedValue(c.indskr_remotemeetinggoals, c.totalremotemeetingscompleted),
            'totalGoalRemoteMeetings': c.indskr_remotemeetinggoals || 0,
            'actualPhoneCalls': this.goalOrCompletedValue(c.indskr_phonecallgoals, c.totalphonecallscompleted),
            'totalGoalPhoneCalls': c.indskr_phonecallgoals || 0,
            'actualEvents': this.goalOrCompletedValue(c.indskr_eventgoals, c.indskr_completedevents),
            'totalGoalEvents': c.indskr_eventgoals || 0,
            'allRepCallPlansApproved': c.statuscode == 2 ? true : false,
            'productName': c.productid_Formatted || '',
            'suggestionApprovalneeded': c.cycleplansuggestionapprovalneeded
          };
          plan['completedGoals'] = plan['actualCalls'] + plan['actualEmails'] + plan['actualF2FMeetings'] + plan['actualRemoteMeetings'] + plan['actualPhoneCalls'] + plan['actualEvents'];
          plan['definedGoals'] = plan['totalGoalCalls'] + plan['totalGoalEmails'] + plan['totalGoalF2FMeetings'] + plan['totalGoalRemoteMeetings'] + plan['totalGoalPhoneCalls'] + plan['totalGoalEvents'];
          plan.percentage = plan.completedGoals && plan.completedGoals > 0 ? _.toNumber(((plan.completedGoals / plan.definedGoals) * 100).toFixed(0)) : 0;
          a.push(plan);
        }
      });
    }
    return a;
  }

  private goalOrCompletedValue(goal, completed) {
    let value = 0;
    if (completed) {
      value = completed > goal ? goal : completed
    }
    return value;
  }

  get isSendDisabled(): boolean {
    const plan = this.selectedRepCallPlan;
    return (this.hasValue(plan.totalGoalCalls) && !this.hasValue(plan.indskr_suggestedcalls)) 
      || (this.hasValue(plan.totalGoalEmails) && !this.hasValue(plan.indskr_suggestedemails))
      || (this.hasValue(plan.totalGoalF2FMeetings) && !this.hasValue(plan.indskr_suggestedf2fmeetings)) 
      || (this.hasValue(plan.totalGoalRemoteMeetings) && !this.hasValue(plan.indskr_suggestedremotemeetings))
      || (this.hasValue(plan.totalGoalPhoneCalls) && !this.hasValue(plan.indskr_suggestedphonecallmeetings))
      || (this.hasValue(plan.totalGoalEvents) && !this.hasValue(plan.indskr_suggestedevents))
  }

  private hasValue(number: number): boolean {
    if (number == undefined || number <= 0) return false;
    return _.gt(number, 0);
  }
  
  /*<<<<<<<<<<<<<<<<<<<<<<<<<<<Call Plan Activities>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
  async mapCallPlanTimelineActivities(response, contact: Contact) {
    if (this.deviceService.isOffline) return;
    let rawActivities: any[] = response['activities'];
    const callplanActivities: Activity[] = [];
    if (Array.isArray(rawActivities)) {
      rawActivities.map(rawJSON => {
        rawJSON._id = DB_KEY_PREFIXES.MEETING_ACTIVITY + rawJSON.activityid;
        // All of the meetings we fetch from this service are all completed.
        // Since statecode value not included in the response, manually setting it here.
        // OMNI-24583 Added Open/Scheduled meeting activities on the timeline
        rawJSON.statecode = 1;

        let newActivity: Activity;

        newActivity = new AppointmentActivity(rawJSON);

        if (rawJSON.activitytypecode === ActivityTypeCodeRaw.Appointment) {
          //if the statuscode is not 3(completed), the statecode is 0(open) to determine meetingowner label.
          if (rawJSON.statuscode != 3) rawJSON.statecode = 0;
          newActivity = new AppointmentActivity(rawJSON);
          let liveMeet = this.activityService.activities.find(item => {
            return (item.ID === rawJSON['activityid'] && item.location === 'LiveMeet');
          });
          if (liveMeet) {
            newActivity = liveMeet;
          }
        } else if (rawJSON.activitytypecode === ActivityTypeCodeRaw.Email) {
          newActivity = new EmailActivity(rawJSON);
          newActivity['parentemailid'] = rawJSON['parentemailid'];
          const startDate = rawJSON["statuscode"] == 548910000 ? rawJSON["actualend"] : rawJSON['senton'] ? rawJSON['senton'] : rawJSON['scheduledend'];
          if (isValid(new Date(startDate))) {
            newActivity.scheduledStart = new Date(startDate);
          } else {
            newActivity.scheduledStart = new Date(parseFloat(startDate));
          }
        }
        else if (rawJSON.activitytypecode === ActivityTypeCodeRaw.PhoneCall) {
          newActivity = new PhoneActivity(rawJSON);
          newActivity['phonecallphonenumber'] = rawJSON['phonecallphonenumber']
          if (isValid(new Date(rawJSON['scheduledstart']))) {
            newActivity.scheduledStart = new Date(rawJSON['scheduledstart']);
          } else {
            newActivity.scheduledStart = new Date(parseFloat(rawJSON['scheduledstart']));
          }
        }

        if (newActivity) {
          //push activity except the cancelled meeting & message (email) with subject Your Time Off
          if (!(newActivity['type'] == "Appointment" && newActivity['status'] == 4)) {
            callplanActivities.push(newActivity);
          }
        }
      });
    }
    //add checked-in events
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL) || this.authenticationService.user.eventTimeLine) {
      if (!_.isEmpty(contact.callplanCheckedInEventsTimeline)) {
        contact.callplanCheckedInEventsTimeline.forEach(ev => {
          ev['type'] = ActivityType.Event;
          ev['scheduledStart'] = ev['_checkinTime'] ? ev['scheduledStart'] = ev['_checkinTime'] : ev['scheduledStart'] = ev['_createdOn'];
          callplanActivities.push(ev);
        });
      }
    }
    //Save activities for call plan timeline in DB
    const contactId: string = contact.ID;
    this.saveActivitiesContactTimelineDB(callplanActivities, contactId);

    contact.mapCallplanActivities(callplanActivities);
  }

  private async saveActivitiesContactTimelineDB(callplanActivities: Activity[], contactId: string) {
    const id = DB_KEY_PREFIXES.CALLPLAN_ACTIVITIES_TIMELINE + contactId;
    const dateWithOfflineDataDuration = this.authenticationService.getPastOfflineDataLimitDateInUTCMillisecond();
    if (!_.isEmpty(callplanActivities) && callplanActivities.length > 0) {
      let filteredOfflineData: Activity[] = [];
      filteredOfflineData = callplanActivities.filter((activity) => {
        return new Date(activity.startDate) > new Date(dateWithOfflineDataDuration);
      });
      try {
        await this.disk.updateOrInsert(id, (doc) => {
          if (!doc || !doc.raw) {
            doc = {
              raw: []
            }
          }
          doc.raw = filteredOfflineData;
          console.log(`Saved activities for call plan timeline in DB: ${filteredOfflineData.length}`);
          return doc;
        });
      } catch (error) {
        console.log("Error saving activities for call plan timeline in DB", error);
      }
    }
  }

  public mapOfflineCallPlanTimelineActivities(callplanActivities: Activity[], contact: Contact) {
    if (!this.deviceService.isOffline) return;
    if (!_.isEmpty(callplanActivities)) {
      callplanActivities.map(conActivity => {
        if (conActivity.type === ActivityType.Email) {
          if (_.isEmpty(conActivity['meetingOwnerName'])) {
            if (this.authenticationService.user && conActivity.meetingOwnerId == this.authenticationService.user.systemUserID) {
              conActivity.meetingOwnerName = this.authenticationService.user.displayName;
            }
          }
          if (!conActivity['channelTypeName']) {
            if (conActivity['channelActivityType'] == ChannelActivityType.SMS) {
              conActivity['channelTypeName'] = 'SMS';
            } else if (conActivity['channelActivityType'] == ChannelActivityType.WHATSAPP) {
              conActivity['channelTypeName'] = 'Whatsapp';
            }
          }
          if (conActivity.status == 548910000) {
            if (!conActivity['actualEnd']) {
              let sharedDate = new Date(conActivity['scheduledStart']);
              if (isValid(sharedDate)) {
                conActivity['actualEnd'] = sharedDate;
              }
            }
          } else {
            if (!conActivity['senton']) {
              let sentOnDate = new Date(conActivity['scheduledStart']);
              if (isValid(sentOnDate)) {
                conActivity['senton'] = sentOnDate;
              }
            }
          }
        } else if (conActivity.type === ActivityType.PhoneCall) {
          if (_.isEmpty(conActivity['phonecallphonenumber'])) {
            conActivity['phonecallphonenumber'] = conActivity['selectedMobileNumber'];
          }
        }
      });
    }
    contact.mapCallplanActivities(callplanActivities);
  }
  /*<<<<<<<<<<<<<<<<<<<<<<<<<<<Call Plan Activities>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
}
