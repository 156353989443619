import { Injectable } from "@angular/core";
import { DeviceService } from "../device/device.service";
import { AuthenticationService } from "../authentication.service";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { FeatureAction } from "@omni/classes/feature-action/feature-action.class";

@Injectable({
  providedIn: 'root',
})
export class FeatureActionsService {
  private featureActions: Map<FeatureActionsMap, FeatureAction> = new Map();

  // Single point of source for any feature flags that requires some extra logic on top of FA
  // and referenced from multiple places.
  private _isShortCallLauncherEnabledInMobileDevice = false;
  get isShortCallLauncherEnabledInMobileDevice() {
    return this._isShortCallLauncherEnabledInMobileDevice;
  }
  private _isCallPlanDashEnabled = false;
  get isCallPlanDashEnabled() {
    return this._isCallPlanDashEnabled;
  }
  private _isShortCallLauncherAndCallPlanDashboardFAsEnabled = false;
  get isShortCallLauncherAndCallPlanDashboardFAsEnabled() {
    return  this._isShortCallLauncherAndCallPlanDashboardFAsEnabled;
  }
  private _isCallPlanDashboardEnabledInMobileDevice = false;
  get isCallPlanDashboardEnabledInMobileDevice() {
    return this.isShortCallLauncherEnabledInMobileDevice && this.isCallPlanDashEnabled;
  }


  constructor(
    private device: DeviceService,
    private authService: AuthenticationService,
  ) {
  }


  getFeatureAction(faName: FeatureActionsMap): FeatureAction {
    return this.featureActions.get(faName) ?? null;
  }
  updateFaValues() {
    this.featureActions.clear();
    const faNames = Object.values(FeatureActionsMap);
    console.log("updateFaValues: featureActions: ", this.authService.user.featureActions)
    for (let i = 0; i < faNames.length; i++) {
      const faName = faNames[i];
      const isOn = this.authService.hasFeatureAction(faName);
      let fa = this.featureActions.get(faName);
      if (!fa) {
        fa = new FeatureAction(faName, isOn);
        this.featureActions.set(faName, fa);
      } else {
        fa.setIsOn(isOn);
      }
    }
    this.setFeatureFlagsWithExtraLogic();
  }

  // Set Feature flags with extra logic
  private setFeatureFlagsWithExtraLogic() {
    this.checkShortCallRelatedFAsWithExtraLogic();
  }


  // // Extra logics for feature flags
  private checkShortCallRelatedFAsWithExtraLogic() {
    // Short call launcher
    this._isShortCallLauncherEnabledInMobileDevice = false;
    const fa = this.featureActions.get(FeatureActionsMap.SHORT_CALL_LAUNCHER);
    this._isShortCallLauncherEnabledInMobileDevice = !!(this.device.isNativeCordova && this.device.isMobileDevicePortrait && fa?.getIsOn());

    // Call plan dashboard
    this._isCallPlanDashboardEnabledInMobileDevice = false;
    const callPlanDashA = this.featureActions.get(FeatureActionsMap.CALL_PLAN_HOME_SCREEN_STYLE_A);
    const callPlanDashB = this.featureActions.get(FeatureActionsMap.CALL_PLAN_HOME_SCREEN_STYLE_B);
    this._isCallPlanDashEnabled = !!(callPlanDashA?.getIsOn() || callPlanDashB?.getIsOn());
    this._isCallPlanDashboardEnabledInMobileDevice = !!(this._isShortCallLauncherEnabledInMobileDevice && this._isCallPlanDashEnabled);

    // To hide call plan menu
    this._isShortCallLauncherAndCallPlanDashboardFAsEnabled = !!(fa?.getIsOn() && this.isCallPlanDashEnabled);

    // console.log("### checkShortCallRelatedFAsWithExtraLogic: this._isShortCallLauncherEnabledInMobileDevice: ", this._isShortCallLauncherEnabledInMobileDevice, ":: this._isCallPlanDashEnabled: ", this._isCallPlanDashEnabled, ':: this._isCallPlanDashboardEnabledInMobileDevice: ', this._isCallPlanDashboardEnabledInMobileDevice, ':: this._isShortCallLauncherAndCallPlanDashboardFAsEnabled: ', this._isShortCallLauncherAndCallPlanDashboardFAsEnabled);
  }
}
