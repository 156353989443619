<ng-container *ngIf ="!isMultipleUpdate">
  <ng-container *ngIf="isLoading; else checkTemplate">
    <io-spinner></io-spinner>
  </ng-container>
<ng-template #checkTemplate>
  <ng-container *ngIf="templates?.length != 1; else displayData">
    <div class="no-data-message">{{ (templates?.length == 0 ? 'ASSESS_NO_TEMPLATES' : 'ASSESS_MORE_TEMPLATES')|
      translate}}</div>
    <img class="os_background-list" />
  </ng-container>
</ng-template>
<ng-template #displayData>
  <ng-container *ngIf="isContactAssessment ? device.isOffline && !rawData && isAssessedContact : device.isOffline && !rawData; else formView">
  <!-- <ng-container *ngIf="templates?.length != 1; else formView"> -->
    <div class="no-data-message">{{ 'AVAILABLE_IN_ONLINE_ONLY' |translate}}</div>
    <img class="os_background-list" />
  </ng-container>
  <ng-template #formView>
    <ng-container *ngFor="let viewData of currentFormView">
      <ind-section-header *ngIf="viewData.type == 'section-header'" [viewData]='viewData.view'
        (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
      <!--For Subsection-->
      <ion-item-divider [ngClass]="{'withSecondaryText': viewData.view.secondaryText}"
        *ngIf="viewData.type == 'sub-section-header'">
        <ion-label>
          <p class="primaryText">
            <ion-text>{{viewData.view.title}}</ion-text>
          </p>
          <p *ngIf="viewData.view.secondaryText" class="secondaryText">
            <ion-text>{{viewData.view.secondaryText}}</ion-text>
          </p>
        </ion-label>
        <ion-buttons slot="end" *ngIf="viewData.view.controls?.length">
          <ng-container *ngFor="let button of viewData.view.controls">
            <ion-button [disabled]="button.isDisabled" (click)="onSectionHeaderControlClick(button)">
              <ion-icon *ngIf="button.icon" [ngClass]="{'disabled':button.isDisabled}" [src]="button.icon"></ion-icon>
            </ion-button>
          </ng-container>
        </ion-buttons>
      </ion-item-divider>
      <div class="emptyContainer" *ngIf="viewData.type == 'empty-container'"></div>
      <ng-container *ngIf="viewData.type == 'form-field'">
        <ind-form-field *ngIf="viewData.control?.Type != '451680002'" [viewData]='viewData.view'
          [ngClass]="viewData.id">
        </ind-form-field>
        <ng-container *ngIf="viewData.control?.Type == '451680002'">
          <ion-row>
            <ion-col size="6">
              <ind-datetime-form *ngIf="viewData.view" [viewData]='viewData.view'></ind-datetime-form>
            </ion-col>
            <ion-col size="6">
              <ind-datetime-form *ngIf="viewData.timeView" [viewData]='viewData.timeView'></ind-datetime-form>
            </ion-col>
            <ion-item
              *ngIf="(viewData.view.infoText && viewData.view.displayInfoText) || (viewData.timeView.infoText && viewData.timeView.displayInfoText)"
              class="message-container">
              <ion-label class="info-message-text">{{viewData.view.infoText ? viewData.view.infoText :
                viewData.timeView.infoText}}</ion-label>
            </ion-item>
          </ion-row>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-template>
<!-- <ion-item no-lines hidden="true">
  <ion-datetime #customerAssessDate (ionCancel)="updateFormDate(false)" (ionChange)="updateFormDate($event.detail)">
  </ion-datetime>
</ion-item> -->
</ng-container>

<!-- <ion-item no-lines hidden="true">
  <ion-datetime #multiCustomerAssessDate (ionCancel)="updateMultiAssessDate(false)" (ionChange)="updateMultiAssessDate($event)" [displayFormat]="dateTimeFormatsService.dateTimeToUpper" [pickerFormat]="dateTimeFormatsService.dateTimeToUpper"
  [monthShortNames]="shortMonth | monthTranslate" [doneText]="this.translate.instant('DONE')" [cancelText]="this.translate.instant('CANCEL')"></ion-datetime>
</ion-item> -->
<ng-container *ngIf="isMultipleUpdate">
  <ion-header>
    <ind-page-title [viewData]="multipleAssessPageTitle" (onControlClick)='onPageTitleControlClick($event)'>
    </ind-page-title>
  </ion-header>
  <ion-content #scrollTop class="ion-no-padding">
    <ng-container *ngIf="isAssessmentLoading; else checkTemplate">
      <io-spinner></io-spinner>
    </ng-container>
    <ng-template #checkTemplate>
      <ng-container *ngIf="templates?.length != 1; else displayData">
        <div class="no-data-message">{{ (templates?.length == 0 ? 'ASSESS_NO_TEMPLATES' : 'ASSESS_MORE_TEMPLATES')|
          translate}}</div>
        <img class="os_background-list" />
      </ng-container>
    </ng-template>
  <ng-template #displayData>
  <!-- <ng-container *ngIf="device.isOffline; else formView"> -->
        <ng-container *ngIf="templates?.length != 1; else formView">
        <div class="no-data-message">{{ 'AVAILABLE_IN_ONLINE_ONLY' |translate}}</div>
        <img class="os_background-list" />
      </ng-container>
      <ng-template #formView>
        <ion-grid class="grid-wrapper">
            <ion-row class="table-row" id="marketScanEditTableRow">
              <ion-col *ngFor="let itemCol of assessGrid;let i = index"
                [ngClass]="[i == 0 ? 'first-column' : 'assess-column', itemCol.sectionIdx%2 == 0 ? 'even-section' : 'odd-section']" class="{{itemCol.columnType}}">
                <div *ngFor="let item of itemCol['columnItems'];let j = index"
                  [ngClass]=" item.isHeaderFirst ? 'header-first' : (item.isHeaderSecond ? 'header-second' : item.isHeaderThird ? 'header-third' : 'input-item') ">
                  <ion-item class="item-text" *ngIf="!item.isInputField"
                    [ngClass]="item.isMoreHeight ? 'more-height-item-text' : '' ">
                    <ion-label class="header-label">
                      <p class="primary-label-text">
                        {{item.columnItemLabel}}
                        <ion-icon class='mandatory-icon' *ngIf="item.isRequired"></ion-icon>
                      </p>
                      <p class="secondary-label-text" *ngIf="item.columnItemSecondaryLabel">
                        {{item.columnItemSecondaryLabel}}
                      </p>
                    </ion-label>
                    <ion-buttons slot="end" *ngIf="item.controls?.length">
                      <ng-container *ngFor="let button of item.controls">
                        <ion-button *ngIf="button.tooltip" tooltip="{{button.tooltip || undefined}}" [disabled]="button.isDisabled" (click)="onSectionHeaderControlClick(button, $event, item)" [attr.id]="'cellId-' + i + '-' + j">
                          <ion-icon *ngIf="button.iconClass" [ngClass]="{'disabled':button.isDisabled}" class="control-icon {{button.iconClass}}"></ion-icon>
                        </ion-button>
                        <ion-button *ngIf="!button.tooltip" [disabled]="button.isDisabled" (click)="onSectionHeaderControlClick(button, $event, item)" [attr.id]="'cellId-' + i + '-' + j">
                          <ion-icon *ngIf="button.iconClass" [ngClass]="{'disabled':button.isDisabled}" class="control-icon {{button.iconClass}}"></ion-icon>
                        </ion-button>
                      </ng-container>
                    </ion-buttons>
                  </ion-item>
                  <ion-item class="input-field-wrap" *ngIf="item.isInputField || item.control?.Type == '451680002'">
                    <ion-label class="item-text" *ngIf="item.isInputField && item.inputFieldType == 'Numeric Field'" id="cellId-{{i}}-{{j}}"
                      [ngClass]="item.isMoreHeight ? 'more-height-item-text' : '' " (click)="onInputTextClick($event, item)">
                      <ion-input type="number" #inputRef placeholder="{{item.placeHolder}}" [disabled]="item.isDisabled" id="cellId-{{i}}-{{j}}"
                        [value]="item.columnItemLabel" (ionBlur)="handleInputValue($event,item)"> </ion-input>
                    </ion-label>
                    <ion-label class="item-text" *ngIf="item.isInputField && item.inputFieldType == 'Text Field'" id="cellId-{{i}}-{{j}}"
                    [ngClass]="item.isMoreHeight ? 'more-height-item-text' : '' " (click)="onInputTextClick($event, item)">
                    <ion-input type="text" #inputRef placeholder="{{item.placeHolder}}" [disabled]="item.isDisabled" id="cellId-{{i}}-{{j}}"
                      [value]="item.columnItemLabel" (ionBlur)="handleInputValue($event,item)"> </ion-input>
                    </ion-label>
                    <ion-label class="item-text" *ngIf="item.isInputField && item.inputFieldType == 'Drop Down Field'" id="cellId-{{i}}-{{j}}"
                    [ngClass]="item.isMoreHeight ? 'more-height-item-text' : '' " (click)="onInputTextClick($event, item)">
                    <ion-text type="text" #inputRef placeholder="{{item.placeHolder}}">{{item.columnItemLabel}}</ion-text>
                    </ion-label>
                    <ion-label class="item-text" *ngIf="item.isInputField && item.inputFieldType == 'Date Time Field'" id="cellId-{{i}}-{{j}}"
                    [ngClass]="item.isMoreHeight ? 'more-height-item-text' : '' " (click)="onInputTextClick($event, item)">
                      <ion-text type="text" placeholder="{{item.placeHolder}}">{{item.columnItemLabel}}</ion-text>
                    </ion-label>
                    <ion-icon *ngIf="item.endSlotItemType == 'arrow'" slot="end" class="ion-float-right arrow-icon {{item.arrowType}}"
                      [ngClass] = "item.isDisabled ? 'disabled' : ''" [name]="item.arrowType" (click)="onInputTextClick($event, item)" id="cellId-{{i}}-{{j}}" ></ion-icon>
                  </ion-item>
                </div>
              </ion-col>
            </ion-row>
        </ion-grid>
      </ng-template>
    </ng-template>
  </ion-content>
  <footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'multiple-assess'"></footer-toolbar>
</ng-container>
