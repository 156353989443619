
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Injectable, Injector } from "@angular/core";
import { AuthenticationService } from "./authentication.service";
import { FeatureActionsMap } from "../classes/authentication/user.class";
import { SESSIONSTACK_TOKEN } from '../../config/endpoints.config';
import { EventName, EventsService } from "./events/events.service";
import { Utility } from "../utility/util";
import { Contact } from '../classes/contact/contact.class';
import { DB_KEY_PREFIXES } from '../config/pouch-db.config';
import { DiskService } from './disk/disk.service';
import { Account } from '../classes/account/account.class';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { getBucketId } from './contact/contact.service';

(window as any).SessionStackKey = (window as any).SessionStackKey || undefined;
(window as any).SessionStack = (window as any).SessionStack || undefined;

declare var SessionStack: { start?: Function, stop?: Function, identify?: Function, getSessionId?: Function, log?: Function, setOnDataCallback?: Function, t?: string, q?: any };
declare var SessionStackKey: string;

@Injectable({
  providedIn: 'root'
})
export class GlobalUtilityService {

  public sync_error: string[] = [];
  public sync_service: string[] = [];

  private sessionStackInitialized: boolean = false;
  private ngDestroy$ = new Subject<boolean>();
  constructor(
    private authService: AuthenticationService,
    private events: EventsService,
    private disk: DiskService,
    private injector: Injector
  ) {
    this.events.observe("session-stack:start").pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => {
        if (!this.sessionStackInitialized) return;
        SessionStack && SessionStack.start && SessionStack.start()
      })
    this.events.observe("session-stack:stop").pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => {
        if (!this.sessionStackInitialized) return;
        SessionStack && SessionStack.stop && SessionStack.stop()
      })
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  private initSessionStack(key: string) {
    if (this.sessionStackInitialized)
      return;
    this.sessionStackInitialized = true;
    SessionStackKey = "SessionStack";
    SessionStack = SessionStack || {
      t: key,
      q: []
    };
    var wrappedMethods = ["start", "stop", "identify", "getSessionId", "log", "setOnDataCallback"];
    var i = 0;
    for (var i = 0; i < wrappedMethods.length; i++) {
      if (!SessionStack[wrappedMethods[i]]) {
        SessionStack[wrappedMethods[i]] = function () {
          SessionStack.q.push([wrappedMethods[i]].concat([].slice.call(arguments, 0)));
        }
      }
    }
    var node = <any>document.createElement("script");
    node.async = 1;
    node.src = "https://cdn.sessionstack.com/sessionstack.js";
    var wafCss = document.getElementsByTagName("script")[0];
    wafCss.parentNode.insertBefore(node, wafCss);
  }

  public setupSessionStack() {
    if (this.authService.hasFeatureAction(FeatureActionsMap.ERROR_REPORTING_REPLAY)) {
      this.initSessionStack(SESSIONSTACK_TOKEN)
      // SessionStack && SessionStack.identify({
      //     userId: this.authService.UserID, // Replace the USER-ID with the user id from your app
      //     displayName: this.authService.userConfig.fullName, // Not required
      // });
    } else {
      // SessionStack && SessionStack.stop();
    }

  }

  public chunkString(str, len) {
    let input = str.trim().split(' ');
    let [index, output] = [0, []]
    output[index] = '';
    input.forEach(word => {
      let temp = `${output[index]} ${word}`.trim()
      if (temp.length <= len) {
        output[index] = temp;
      } else {
        index++;
        output[index] = word;
      }
    })
    return output
  }

  public get globalCustomerText(): string {
    let translate = this.injector.get(TranslateService);
    if (Utility.globalCustomerText) {
      return translate.instant(Utility.globalCustomerText);
    }
  }

  public get globalCustomersText(): string {
    let translate = this.injector.get(TranslateService);
    if (Utility.globalCustomersText) {
      return translate.instant(Utility.globalCustomersText);
    }
  }

  /**
   * update interaction date and type for completed meetings, orders, allocation order, sent Message and case intake created
   * @param contact list of contact
   * @param account list of account
   * @param interaction interaction type
   */
  public async updateInteraction(contacts: Contact[] = [], 
                                  accounts: Account[] = [], 
                                  interaction: string): Promise<void> {

    try {
      if (accounts && accounts.length > 0) {
        let dbAccounts = [];
        let rawAccountDoc: any = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT, true);
        if (rawAccountDoc && rawAccountDoc.raw && Array.isArray(rawAccountDoc.raw)) {
          dbAccounts = rawAccountDoc.raw;
        }
        accounts.forEach(acc => {
          dbAccounts = this.updateInteractionAccount(acc, interaction, dbAccounts);
        });
        if (!_.isEmpty(dbAccounts)) {
          await this.disk.saveAccountsToDB(dbAccounts);
        }
      }


      if (contacts && contacts.length > 0) {
        let dbContacts = [];

        // let rawContactsDoc: any = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT);

        // if (rawContactsDoc && rawContactsDoc.raw && Array.isArray(rawContactsDoc.raw)) {
        //   dbContacts = rawContactsDoc.raw;
        // }

        

        (async () => {
          let dictContacts = {}
          for (const con of contacts) {

            const bucketID = getBucketId(con.ID, this.authService.get_num_buckets());

            const dbKey = Utility.getDBBucketedKey(DB_KEY_PREFIXES.CONTACT, bucketID);
            let dbContacts = dictContacts[dbKey];
        
            if (!dbContacts || _.isEmpty(dbContacts)) {
              // Retrieve the contacts from the database.
              const doc = await this.disk.retrieve(dbKey);
              
              // Check if the retrieved document contains a non-empty array of contacts.
              if (_.isArray(doc?.raw) && doc.raw.length > 0) {
                dbContacts = doc.raw;
              }
            }
            
            // Update the contacts with interaction information.
            dbContacts = await this.updateInteractionContact(con, interaction, dbContacts);
            dictContacts[dbKey] = dbContacts
          }
          await this.disk.saveDataToDynamics(dictContacts)

        })();
        


      }
    } catch (error) {
      console.error('interaction update error: ', error);
    }
  }
  /**
   * for updating account with interaction type and date. It is updating local db and session account
   * @param account account
   */
  public updateInteractionAccount(account: Account, interaction: string, dbAccounts: any[]): any[] {
    if (_.isEmpty(dbAccounts)) return dbAccounts;
    if (account) {
      account.interactionType = interaction;
      account.interactionDate = new Date().getTime();
      let dbIndx = dbAccounts.findIndex(ac => ac.accountid === account.id);
      if (dbIndx >= 0) {
        let dbAccount = dbAccounts[dbIndx];
        dbAccount['interactionType'] = account.interactionType;
        dbAccount['interactionDate'] = account.interactionDate;
        // this.events.publish(EventName.ACCOUNTUPDATEINTERACTION, new Account(dbAccount));
      }
      return dbAccounts;
    }
  }
  /**
   * for updating contact with interaction type and date. It is updating local db and session contact
   * @param contact contact
   */
  public updateInteractionContact(contact: Contact, interaction: string, dbContacts: any[]): any[] {
    if (_.isEmpty(dbContacts)) return dbContacts;
    if (contact) {
      const dbIndx = dbContacts.findIndex(c => c.contactid === contact.ID);
      if (dbIndx >= 0) {
        let dbContact = dbContacts[dbIndx];
        dbContact['interactionType'] = interaction;
        dbContact['interactionDate'] = new Date().getTime();
        dbContacts[dbIndx] = dbContact
      }
      return dbContacts;
    }
  }

}
