import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from "@angular/core";
import { DeviceService } from "@omni/services/device/device.service";
import { Subscription } from "rxjs";
import { debounceTime, skip } from "rxjs/operators";

@Component({
  selector: 'ind-expandable-text',
  templateUrl: 'ind-expandable-text.html',
  styleUrls: ['ind-expandable-text.scss'],
})
export class ExpandableTextComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('textContainer', { static: false }) textContainer!: ElementRef;
  @Input() text: string = '';

  private _expanded: boolean = false;
  @Input()
  set expanded(val: boolean) {
    this.expandedChange.emit(val);
    this._expanded = val;
  };
  get expanded() {
    return this._expanded;
  }
  @Output()
  expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() clampLines: number = 2;  // Number of lines to show before clamping

  constructor(
    private device: DeviceService,
    private renderer: Renderer2,
  ) {}

  isOverflowing = false;
  private clampedHeight: number;
  private initRan = false;
  private sub: Subscription;

  ngOnInit(): void {
    this.sub = this.device.screenWidth.pipe(
      skip(1),
      debounceTime(200),
    ).subscribe((width: number) => {
      if (width > 0) {
        const element = this.textContainer?.nativeElement;
        if (element) {
          // Set height to 'auto' so that the element to have the actual height
          // prior to the overflow check
          this.renderer.setStyle(element, 'height', 'auto');
        }
        // Run the overflow check after screen width changes
        setTimeout(() => {
          this.checkOverflow();
        }, 0);
      }
    });
  }

  ngAfterViewInit(): void {
    // Run the overflow check after the view is fully initialized
    setTimeout(() => {
      this.checkOverflow();
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clampLines']) {
      setTimeout(() => {
        // Don't run the overflow check until the very initial check has run already
        if (!this.initRan) return;
        // Run the overflow check again after the clampLines value changes
        this.checkOverflow();
      }, 0);
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  private checkOverflow() {
    const element = this.textContainer?.nativeElement;

    if (element) {
      this.initRan = true;
      const scrollHeight = element.scrollHeight;

      // Calculate the max height before clamping based on the number of lines to clamp
      const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
      this.clampedHeight = this.clampLines * lineHeight;

      // Determine whether current text is overflowing
      const isOverflowing = this.clampedHeight < scrollHeight;
      // Set the collapsed height based on overflow state
      const collapsedHeight = isOverflowing ? this.clampedHeight : scrollHeight;

      this.isOverflowing = isOverflowing;

      if (this.isOverflowing) {
        // Initially set the max height so that the initial height setting doesn't get affected by the height transition animation
        // Otherwise, user get to see a blink of height change animation because of css transistion setting
        this.renderer.setStyle(element, 'maxHeight', this.isOverflowing && this.expanded ? `${scrollHeight}px` : `${collapsedHeight}px`);
        // Set line clamp value so that ellipses can be displayed
        this.renderer.setStyle(element, '-webkit-line-clamp', `${this.clampLines}`);
      }
      // Set the actual height
      this.renderer.setStyle(element, 'height', this.isOverflowing && this.expanded ? `${scrollHeight}px` : `${collapsedHeight}px`);

      if (!this.isOverflowing && this.expanded) {
        // In case width changes while expanded and it doesn't overflow anymore, reset the expanded flag
        this.expanded = false;
      }
    }
  }

  toggleView() {
    const element = this.textContainer?.nativeElement;

    if (element) {
      if (this.expanded) {
        // Collapsing: Bring line clamp style back and set height to the clamped height to trigger transition
        this.renderer.setStyle(element, 'display', '-webkit-box');
        this.renderer.setStyle(element, '-webkit-line-clamp', `${this.clampLines}`);
        this.renderer.setStyle(element, 'height', `${this.clampedHeight}px`);
      } else {
        // Expanding: Unset line clamp style and set height to scrollHeight to expand triggering transition
        this.renderer.setStyle(element, 'display', 'block');
        this.renderer.setStyle(element, '-webkit-line-clamp', 'unset');
        this.renderer.setStyle(element, 'maxHeight', `unset`);
        this.renderer.setStyle(element, 'height', `${element.scrollHeight}px`);
      }
    }

    this.expanded = !this.expanded;
  }
}
