export const SupportedEventConfiguredFieldTypes: string[] = [
  'String',
  'Memo',     // same as string?
  'Integer',
  'Decimal',
  'BigInt',   // big int
  'Money',    // decimal number
  'Boolean',  // true / false
  'Picklist', // Single option set select. number eg. 1000000
  'Virtual',  // Multi option set select. ',' separated numbers. eg. "1000000,1000001"
  'State',    // Is it same as Picklist?
  'DateTime',
  'Lookup'
];

export const SupportedCoachingConfiguredFieldTypes: string[] = [
  // 'String',
  // 'Memo',     // same as string?
  'Integer',
  'Decimal',
  // 'BigInt',   // big int
  // 'Money',    // decimal number
  // 'Boolean',  // true / false
  // 'Picklist', // Single option set select. number eg. 1000000
  // 'Virtual',  // Multi option set select. ',' separated numbers. eg. "1000000,1000001"
  // 'State',    // Is it same as Picklist?
  // 'DateTime',
];

export const SupportedProcedureContractConfiguredFieldTypes: string[] = [
  'String',
  // 'Memo',     // same as string?
  'Integer',
  'Decimal',
  'BigInt',   // big int
  'Money',    // decimal number
  'Boolean',  // true / false
  'Picklist', // Single option set select. number eg. 1000000
  // 'Virtual',  // Multi option set select. ',' separated numbers. eg. "1000000,1000001"
  // 'State',    // Is it same as Picklist?
  'DateTime',
  'Lookup'
];

// List of attributes that are:
// 1. of supported data type
// 2. available as a field option in iO App Field Config form
// (Display name is not null => the ones that are commented out below)
// 3. "isValidForUpdate" is false
export const ReadOnlyEventConfiguredFieldNames: string[] = [
  'indskr_eventid',
  'versionnumber',
  'createdon',
  'modifiedon',
  'msevtmgt_registrationcount',
  // 'entityimage_timestamp',
  'msdyncrm_eventurlspecified',
  // 'adx_portalbannerimagename',
  // 'adx_portalimagename',
  'indskr_itemizedexpensestotalamount_date',
  'indskr_speakerengagementtotalcommitted_date',
  'indskr_speakerengagementtotalpaidtodate_date',
  'indskr_vendorengagementpaidtodate_date',
  'indskr_vendorengagementprojectedcost_date',
  'msdyncrm_sessionscount_date',
  'overriddencreatedon',
  'exchangerate',
  'importsequencenumber',
  'indskr_itemizedexpensestotalamount_state',
  'indskr_speakerengagementtotalcommitted_state',
  'indskr_speakerengagementtotalpaidtodate_state',
  'indskr_vendorengagementpaidtodate_state',
  'indskr_vendorengagementprojectedcost_state',
  'msdyncrm_sessionscount',
  'msdyncrm_sessionscount_state',
  'msevtmgt_countdownindays',
  'indskr_estimatedcost_base',
  'indskr_itemizedexpensestotalamount',
  'indskr_itemizedexpensestotalamount_base',
  'indskr_projectedadditionalexpense_base',
  'indskr_speakerengagementtotalcommitted',
  'indskr_speakerengagementtotalcommitted_base',
  'indskr_speakerengagementtotalpaidtodate',
  'indskr_speakerengagementtotalpaidtodate_base',
  'indskr_spendpaidtodate',
  'indskr_spendpaidtodate_base',
  'indskr_tacticalplanbudget_base',
  'indskr_tacticalplanbudgetremaining_base',
  'indskr_total_spend',
  'indskr_total_spend_base',
  'indskr_totalspent_base',
  'indskr_vendorengagementpaidtodate',
  'indskr_vendorengagementpaidtodate_base',
  'indskr_vendorengagementprojectedcost',
  'indskr_vendorengagementprojectedcost_base',
  'msevtmgt_budgetallocated_base',
  'msevtmgt_eventvenuecost_base',
  'msevtmgt_miscellaneouscosts_base',
  'msevtmgt_revenuefromsponsorship_base',
  'msevtmgt_targetrevenue_base',
  'msevtmgt_totalcostofeventsactivities_base',
  'msevtmgt_totalcostofexternalmembers_base',
  'msevtmgt_totalregistrationfee_base',
  'msevtmgt_totalrevenuefromtheevent_base',
  // 'createdbyname',
  // 'createdbyyominame',
  // 'createdonbehalfbyname',
  // 'createdonbehalfbyyominame',
  // 'entityimage_url',
  'indskr_approvalconfigurationname',
  'indskr_completedbyname',
  'indskr_completedbyyominame',
  'indskr_contentsettingsname',
  // 'indskr_event_typename',
  // 'indskr_eventplanidname',
  // 'indskr_eventsubtypename',
  'indskr_externalid',
  // 'indskr_meetingappointmentname',
  // 'indskr_positionidname',
  // 'indskr_productidname',
  // 'indskr_speakercontractidname',
  // 'indskr_tacticalplanidname',
  // 'modifiedbyname',
  // 'modifiedbyyominame',
  // 'modifiedonbehalfbyname',
  // 'modifiedonbehalfbyyominame',
  // 'msdyncrm_marketingformidname',
  // 'msevtmgt_buildingname',
  // 'msevtmgt_eventimagename',
  'msevtmgt_formpagejavascriptcode',
  // 'msevtmgt_layoutname',
  // 'msevtmgt_portalpaymentgatewayname',
  // 'msevtmgt_primaryvenuename',
  // 'msevtmgt_producername',
  // 'msevtmgt_roomname',
  // 'msevtmgt_streamownername',
  // 'msevtmgt_streamowneryominame',
  // 'msevtmgt_transactioncurrencyidname',
  // 'msevtmgt_webinarconfigurationidname',
  // 'msevtmgt_webinartypename',
  // 'owneridname',
  // 'owneridyominame',
  // 'owningbusinessunitname',
  // 'transactioncurrencyidname',
];

export const ReadOnlyCoachingConfiguredFieldNames: string[] = [
  'ownerid',
  'indskr_coachingscore',
  'indskr_coachingqualifyingscore',
  'createdonbehalfby',
  'indskr_accepteddate',
  'indskr_dateacknowledged',
  'indskr_dateforreview',
  'indskr_shareddate',
  'indskr_externalid',
  'indskr_coachingreportid',
  'indskr_goapp',
  'importsequencenumber',
  'modifiedby',
  'modifiedonbehalfby',
  'modifiedon',
  'indskr_name',
  'owningbusinessunit',
  'owningteam',
  'owninguser',
  'indskr_project',
  'overriddencreatedon',
  'timezoneruleversionnumber',
  'utcconversiontimezonecode',
  'versionnumber',
];

export const DefaultEventFieldNamesThatCannotBeConfigured: string[] = [
  'statecode',
  'statuscode',
  'msevtmgt_name',
  'indskr_location',
  'msevtmgt_eventstartdate',
  'msevtmgt_eventenddate',
  'indskr_attendeeformat',
  'indskr_cutoffdate',
  'indskr_selectioncriteria',
  'msevtmgt_building',
  'transactioncurrencyid',
  'msevtmgt_budgetallocated',
];

export const DefaultCoachingFieldNamesThatCannotBeConfigured: string[] = [
  'statecode',
  'statuscode',
  'indskr_coachingfor',
  'createdby',
  'indskr_coachingtemplateid',
  'createdon',
  'indskr_periodstartdate',
  'indskr_periodenddate',
  'indskr_ratingssummary',
  'indskr_recommendations'
];

export const DefaultContractFieldNamesThatCannotBeConfigured: string[] = [
  'statecode',
  'statuscode',
  'createdby',
  'createdon',
 'indskr_enddate',
 'indskr_startdate'
];
