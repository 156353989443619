import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SurgeryOrderActivity } from "@omni/classes/activity/surgery-order.activity.class";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { ControlDataType, DynamicForm, FormType } from "@omni/classes/dynamic-form/dynamic-form.class";
import { MarketingPlan } from "@omni/classes/marketing-management/marketing-plan.class";
import { MDMType } from "@omni/classes/mdm/source-type-optionset.class";
import {
  ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY,
  ACCOUNT_CONTACT_AFFILIATIONS_REF_ENTITY,
  CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY
} from "@omni/config/dynamic-forms/affiliations-contants";
import { DefaultAccountDisplayForm } from "@omni/config/dynamic-forms/default-account-display";
import { DynamicFormType } from "@omni/models/dynamic-form-component.model";
import _ from "lodash";
import { combineLatest } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { AccountPlan } from "../../classes/account-management/account-plan.class";
import { Account } from "../../classes/account/account.class";
import { Activity, ActivityType, ActivityTypeCodeRaw } from "../../classes/activity/activity.class";
import { AppointmentActivity } from "../../classes/activity/appointment.activity.class";
import { OrderActivity } from "../../classes/activity/order.activity.class";
import { PhoneActivity } from "../../classes/activity/phone.activity.class";
import { Contact } from "../../classes/contact/contact.class";
import { Opportunity } from "../../classes/opportunity-management/opportunity.class";
import { SecondaryInfoEntityName } from "../../classes/sec-info-config/sec-info.class";
import {
  AccountGlobalSearchAttributes,
  AccountGlobalSearchDefaultFilter,
  AccountGlobalSearchDefaultOrder,
  AccountGlobalSearchDefaultPositionFilterCondition,
  AccountGlobalSearchEntityLevelCharacterSearchAttributeName,
  AccountGlobalSearchPositionLink
} from "../../config/fetch-xml/account/account.fetch-xml.config";
import { DB_KEY_PREFIXES } from "../../config/pouch-db.config";
import { SecInfoConfigDataService } from "../../data-services/sec-info-config/sec-info-config-data-service";
import { FilterConditionOperators } from "../../enums/fetch-xml/fetch-xml.enum";
import {
  AttributeXMLTag,
  EntityXMLTag,
  FetchXMLTag,
  FilterConditionXMLTag,
  FilterXMLTag,
  LinkedEntityXMLTag,
  OrderXMLTag
} from "../../interfaces/fetch-xml/fetch-xml.interface";
import {
  searchIndexDataModel,
  SelectedSuggestionPillDataModel,
  SuggestionPillType
} from "../../models/search-config-data-model";
import { parseTextAfter } from "../../utility/common.utility";
import { getActualValueFromMapping, getAttributeNameForCharacterSearch } from "../../utility/global-search.utility";
import { ActivityService } from "../activity/activity.service";
import { AuthenticationService } from "../authentication.service";
import { CustomerTagStateCode } from "../contact/contact.service";
import { DeviceService } from "../device/device.service";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME, OFFLINE_DB_LINKED_ENTITY_NAME } from "../disk/disk.service";
import { DynamicFormsService } from "../dynamic-forms/dynamic-forms-service";
import { EventsService } from "../events/events.service";
import { GlobalUtilityService } from "../global-utility.service";
import { PageName } from "../navigation/navigation.service";
import { addLoaderCount } from "../reports/functions/report.functions";
import {
  procedureUpdateTotalAccountContactCount
} from "../reports/functions/surgery-order/surgery-order-report.functions";
import { SearchConfigService } from "../search/search-config.service";
import { ComponentViewMode, UIService } from "../ui/ui.service";
import { BaseMeasureData } from "./../../../interfaces/edge-analytics/report.interface";
import { SurgeryOrderMeasureData } from "./../../../interfaces/edge-analytics/surgery-order-report.interface";
import { AccountDTO, AccountGlobalSearchDTO } from "./../../classes/account/account.class";
import { ProcedureTrackerActivity } from "./../../classes/activity/procedure-tracker.activity.class";
import { MeasureType } from "./../../enums/edge-analytics/edge-analytics.enum";
import { ProcedureKPI } from "./../../enums/edge-analytics/surgery-order/surgery-order-report.enum";
import { ReportDataManagementService } from "./../reports/report-data-management.service";
import { CaseActivity } from "@omni/classes/case-intake/case-activity.class";
import { Presentation } from "@omni/classes/presentation/presentation.class";
import { Resource } from "@omni/classes/resource/resource.class";
import { EmailActivity } from "@omni/classes/activity/email.activity.class";
import { isValid } from "date-fns";
import { DEFAULT_FORM_LANGUAGE_CODE } from "@omni/components/shared/ind-display-form/ind-display-form";
import { StoreCheckActivity } from "@omni/classes/activity/store-check.activity.class";
import { LocalizationService } from "../localization/localization.service";
export const EXCLUDED_ACCOUNT_GLANCE_CARD_ATTRIBUTES = ['name'];
import { LocationOfflineService } from "../location/location.service";
/**
 * Offline data service for storing accounts
 *
 * @export
 * @class AccountOfflineService
 */
@Injectable({
  providedIn: 'root'
})
export class AccountOfflineService {

    //This is the nested data structure for our accounts
    //The items in the array contain children nodes of linked accounts,
    //Those accounts also may have linked children, indefinitely
    public accountsTree: Account[];
    public oneKeyAccountsTree: Account[];
    public accountsTreeForOtherTool: Account[];

    public uniqueAccountsFromOtherTools : Account[];

    private leftOverAccounts: Account[];

    public accounts: Account[] = [];
    public oneKeyAccounts: Account[] = [];
    public selectedAccounts: Account[];
    public selectedOKAccounts: any[] = [];
    public selectedFor: AccountSelectedFor;
    public searchAccounts: Account[];
    public selected:Account;
    public prevSelected: Account;
    public selectedOKAccount:any;
    public tempSelected:Account;
    public accessedAccountListFrom:PageName;
    public accountPageMode: ComponentViewMode;
    public accountPrevPageMode: ComponentViewMode;

    private _db;
    public isAccountTimelineLoaded: boolean = false;
    public isSchedulerInvoked: boolean = false;
    public isCaseManagementInvoked: boolean = false;
    recentSearches: SelectedSuggestionPillDataModel[] = [];

    public refreshUIofCurrentSelectedAccountFlag:boolean = false;
    isGlobalSearching = false;
    isOneKeySearching = false;
    globalSearchResults: Account[] = [];
    globalSearchCategoryCounter: Map<string, boolean> = new Map();
    allAccountsCount = 0;
    oneKeyAccountsCount = 0;

    public isOneKeyChangeRequest: boolean = false;
    public isOneKeyAffiliatedToAccount: boolean = false;
    public oneKeyAccountRawData: any;
  linkEntityAccountTo: any;
  linkEntityAccountFrom: any;
  brandAffiliationByAccountId: any;
  linkedAccountContact: any;
  public isAffiliationEnabled:boolean=false;

    public isEnableGoToAccountsTool: boolean = false;
    public isClickedGoToAccountsTool: boolean = false;
    public isNotInMyAccountList: boolean = false;
    public errorMessageOpenAccountDetails: string = '';
    public selectedAccountCr: any;
    public isOpenedSurveyTimelineFromAccountProfile: boolean = false;

    public shortCallSelectedAccountId: string;

    public contactIdsToAdd: Array<string> = [];

    public selectedActivitiesFromAccountFilter: Array<string> = [];
    public selectedSubOptionsFromAccountFilter: { meetingStatus: Array<number> } = { meetingStatus: [] };

    public isMarketingBusinessPlanInvoked: boolean = false;

    public customerSpecialities :any = [];
    public customerAddresses:any = [];

    constructor(
        private disk: DiskService,
        private activityService: ActivityService,
        private authService:AuthenticationService,
        public searchConfigService: SearchConfigService,
        private secondaryInfoService: SecInfoConfigDataService,
        private reportDataMgmService: ReportDataManagementService,
        private datePipe: DatePipe,
        private translate: TranslateService,
        private dynamicFormsService: DynamicFormsService,
        public events: EventsService,
        private utilityService: GlobalUtilityService,
        private readonly uiService: UIService,
        private readonly deviceService: DeviceService,
        private readonly localizationService: LocalizationService,
        private locationService: LocationOfflineService
    ) {
        //this._db = new PouchDB('accounts');
        this.accounts = [];
        this.accountsTree = [];
        this.leftOverAccounts = [];
        this.searchAccounts = [];
        this.selectedAccounts = [];
        this.oneKeyAccounts = [];
        this.oneKeyAccountsTree = [];

        // For edge analytics
        combineLatest([reportDataMgmService.measures$, reportDataMgmService.reportDataRefreshRequest$])
        .pipe(
          debounceTime(0),
        )
        .subscribe(([measures, refreshRequestMeasureType]) => {
          if (measures.has(MeasureType.procedure) && refreshRequestMeasureType === MeasureType.procedure) {
            const measureData: BaseMeasureData = reportDataMgmService.getMeasure(refreshRequestMeasureType);
            const kpiData = measureData?.kpiMap?.get(ProcedureKPI.accounts);
            kpiData?.isDataLoading$?.next(true);
            addLoaderCount(measureData?.isTileDataLoading$)
            procedureUpdateTotalAccountContactCount(measureData as SurgeryOrderMeasureData, 'account', this.allAccountsCount);
          }
        });
      this.events.observe("sync:completed")
        .subscribe(() => {
          this.affiliationControl();
        });
    }

    public getAccountById(id: string): Account {
        return this.accounts.find(account => account.id === id);
    }

    public getAccountName(account) {
      let accountName = "";
      if(account['officialName']) {
        accountName = account['officialName'];
      } else {
        accountName = account['usualName'];
      }
      return accountName;
    }

    /**
     * Maps a raw json response from network to internal array of accounts
     * Also creates our new nested tree structure for accounts
     *
     * @param {object} raw
     * @param {boolean} [doNotSave]
     * @returns {Array<Account>}
     * @memberof AccountOfflineService
     */
    public _mapAccounts(raw: any): Account[] {
        if (Array.isArray(raw)) {
            this.accounts = [];
            this.accountsTree = [];
            this.leftOverAccounts = [];


            //Sort the raw json by if they have a parent account id, this lets us have the parents first.
            raw.sort(
                (accountA: any, accountB: any): number => {
                    //Both are parents, return alphanumeric
                    if (!accountA.hasOwnProperty('_parentaccountid_value') && !accountB.hasOwnProperty('_parentaccountid_value')) {
                        return (accountA.name > accountB.name ? -1 : 1);
                    } else if (!accountA.hasOwnProperty('_parentaccountid_value') && accountB.hasOwnProperty('_parentaccountid_value')) {
                        //A is a parent, b is a child, return 1
                        return -1;
                    } else {
                        //B is a parent, a is a child, return -1?
                        return 1;
                    }
                }
            );
            //raw data for one-key accounts
            this.oneKeyAccountRawData = raw.filter(rawJSON => rawJSON.indskr_mdm === MDMType.ONEKEY);

            const newLastUpdatedTime = new Date().getTime();
            // this.allAccountsCount = raw.length;
            raw.map(rawJSON => {

                //rawJSON['configuredFields'] = this.authService.updateConfiguredFieldValues(rawJSON,'account');
                //rawJSON['lastUpdatedTime'] = newLastUpdatedTime;

                const newAccount = new Account(rawJSON);
                this.mapAccountFieldsToSearchIndex(rawJSON);
                if(!this.accounts.some(acc => acc.id === newAccount.id)){
                  this.accounts.push(newAccount);
                }

                //If the account doesn't have a value for parent account id then it's a base level
                if (!newAccount.parentAccountID) {
                  if(!this.accountsTree.some(acc => acc.id === newAccount.id)){
                    this.accountsTree.push(newAccount);
                  }
                } else {
                    //Find our existing parent and add it to a sublevel
                    let parentAccount = this.accountsTree.find(account => {
                        return account.id === newAccount.parentAccountID;
                    });

                    //If we found a parent, simply add it
                    if (parentAccount) {
                      if(!parentAccount.subAccounts.some(acc => acc.id === newAccount.id)){
                        parentAccount.subAccounts.push(newAccount);
                      }
                    } else {
                        //Add it as a leftover, it's true parent is still being processed
                        if(!this.leftOverAccounts.some(acc => acc.id === newAccount.id)){
                          this.leftOverAccounts.push(newAccount);
                        }
                    }
                }
            });

            this.allAccountsCount = this.accounts.length;

            //Recursively add accounts to parents
            try {
                this.recursiveAccountParentMatch();
            } catch (stackOverflow) {
                console.error('Caught potential stack overflow error on recursively mapping accounts', stackOverflow);
            }
        } else {
            console.error('Unsupported data format for Accounts!: ', raw);
        }

        // if (!doNotSave) {
        //     //Save to our DB or update
        //     this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT, doc => ({raw: raw}))
        //         .catch(error => {
        //             console.error('_mapAccounts: ', error);
        //         });
        // }
        this.sortAccountsTree();
        return this.accounts;
    }

  public mapActivitesByAccount(raw) {
    if (raw && raw.activities && Array.isArray(raw.activities)) {
      let activities = []
      //Check duplicated data and merged the latest data(it takes the value from the last object) on the same activityid - appointment, phonecall
      const appointmentPhonecallData = raw.activities.filter(act=> act.activityid && act.activitytypecode && (act.activitytypecode == 'appointment' || act.activitytypecode == 'phonecall'));
      const otherData = raw.activities.filter(act=> !act.activityid || !act.activitytypecode || act.activitytypecode && act.activitytypecode != 'appointment' && act.activitytypecode != 'phonecall');
      const tempGroupedData = _.values(_.groupBy(appointmentPhonecallData, 'activityid'));
      const tempMergedData = tempGroupedData.map((group)=> _.merge({}, ...group));
      const combinedData = tempMergedData.concat(otherData);

      combinedData.map(o => {
        if (o['scheduledstart']) {
          let activity = new Activity(o)
          if (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER360UI, true)) {
            if (activity.type === ActivityType.Appointment) {
              activity = new AppointmentActivity(o);
              //Customer 360 timiline fetch response mapping
              let accounts: Array<Account> = [];
              if (o['journeyActivityAccounts']) {
                o['journeyActivityAccounts'].forEach(a => {
                  let obj: any = {
                    id: a.account,
                    accountName: a.accountName,
                  }
                  accounts.push(obj);
                });
              }
              (activity as AppointmentActivity).accounts = accounts;

              let presentations: Array<Presentation | Resource> = [];
              if (o['journeyActivityPresentations']) {
                o['journeyActivityPresentations'].forEach(a => {
                  let obj: any = {
                    contentId: a.presentationId,
                    name: a.presentationName,
                    thumbnailURL: a.thumbnailUrl
                  };
                  presentations.push(obj as Presentation);
                });

              }
              if (o['journeyActivityResources']) {
                o['journeyActivityResources'].forEach(a => {
                  let obj: any = {
                    contentId: a.resourceId || a.iodocumentId,
                    name: a.resourceName,
                    thumbnailURL: a.thumbnailUrl
                  };
                  presentations.push(obj as Resource);
                });
              }
              (activity as AppointmentActivity).presentations = presentations;
              if (o['journeyActivityProducts']) {
                (activity as AppointmentActivity).products = [];
                o['journeyActivityProducts'].forEach(a => {
                  let obj: any = {
                    isSelected: true,
                    name: a.productName,
                    ID: a.product
                  };
                  (activity as AppointmentActivity).products.push(obj);
                });
              }
              if (o['journeyActivityCoVisitors']) {
                (activity as AppointmentActivity).accompaniedUserList = [];
                o['journeyActivityCoVisitors'].forEach(a => {
                  let obj: any = {
                    id: a.userId,
                    name: a.userName,
                  };
                  (activity as AppointmentActivity).accompaniedUserList.push(obj);
                });
              }
              if(!o['location']){
                (activity as AppointmentActivity).location = '';
              }
            } else if (activity.type === ActivityType.PhoneCall) {
              activity = new PhoneActivity(o);
              //Customer 360 timiline fetch response mapping
              if (o['journeyActivityContacts']) {
                (activity as PhoneActivity).contacts = [];
                o['journeyActivityContacts'].forEach(a => {
                  let obj: any = {
                    ID: a.contact,
                    fullName: a.contactName,
                  };
                  (activity as PhoneActivity).contacts.push(obj);
                });
              }
              if (o['journeyActivityProducts']) {
                (activity as PhoneActivity).products = [];
                o['journeyActivityProducts'].forEach(a => {
                  let obj: any = {
                    isSelected: true,
                    name: a.productName,
                    ID: a.product
                  };
                  (activity as PhoneActivity).products.push(obj);
                });
              }
              (activity as PhoneActivity).selectedMobileNumber = activity['phonecallphonenumber'] = o['phonecallphonenumber']
            } else if (o.activitytypecode === ActivityTypeCodeRaw.CaseIntake) {
              o['statuscode@OData.Community.Display.V1.FormattedValue'] = o['statusValue'];
              o['indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'] = o['caseStage'];
              o['indskr_expertcategorytype_value@OData.Community.Display.V1.FormattedValue'] = o['expertCategoryName'];
              o['productid_value@OData.Community.Display.V1.FormattedValue'] = o['productName'];
              if(o['expertCategory'] && o['expertCategoryName']){
                o['indskr_expertcategory_value'] = o['expertCategory'];
                o['indskr_expertcategory'] = {
                  id: o['expertCategory'],
                  name: o['expertCategoryName'],
                }
              }
              activity = new CaseActivity(o);
            } else if (o.activitytypecode === ActivityTypeCodeRaw.Email) {

              //Customer 360 timiline fetch response mapping
              if (o['channelTypeName']) {
                o['channelName'] = o['channelTypeName'];
              }
              if (o['productName']) {
                o['emailProductName'] = o['productName'];
              }
              activity = new EmailActivity(o);
              if(o['channelTypeName']){
                activity['channelTypeName'] = o['channelTypeName'];
              }
              if(o['readOn']){
                activity['readOn'] = o['readOn'];
              }
              if(o['senton']){
                activity['senton'] = o['senton'];
              }
              if (o['journeyActivityContacts']) {
                (activity as EmailActivity).emailActivityParties = [];
                o['journeyActivityContacts'].forEach(a => {
                  let obj: any = {
                    indskr_contactid: a.contact,
                    contact_firstname: a.contactName,
                    contact_lastname: '',
                  };
                  (activity as EmailActivity).emailActivityParties.push(obj);
                });
              }
              if (o['journeyActivityPresentations']) {
                (activity as EmailActivity).emailAttachments = [];
                o['journeyActivityPresentations'].forEach(a => {
                  let obj: any = {
                    indskr_resourceid: a.attachmentId,
                    indskr_ckmtitle: a.ckmTitle,
                    indskr_ckmthumbnailurl: a.thumbnailUrl,
                  };
                  (activity as EmailActivity).emailAttachments.push(obj);
                });
              }
              activity['parentemailid'] = o['parentemailid'];
              const startDate = o["statuscode"] == 548910000 ? o["actualend"] : o['senton'] ? o['senton'] : o['scheduledend'];
              if (isValid(new Date(startDate))) {
                activity.scheduledStart = new Date(startDate);
              } else {
                activity.scheduledStart = new Date(parseFloat(startDate));
              }
            } else if(o.type === ActivityType.StoreCheck) {
              activity = new StoreCheckActivity(o);
            }
          }
          activities.push(activity);
        }
      });
      if (raw['orders'] && Array.isArray(raw['orders']) && raw['orders'].length > 0) {
        raw['orders'].forEach(rawOrder => {
          if (rawOrder['indskr_ordertype'] && rawOrder['indskr_ordertype'] == 548910001) {
            let orderObj = new SurgeryOrderActivity(rawOrder);
            orderObj.type = ActivityType.SurgeryOrder;
            if (orderObj.ownerId != this.authService.user.systemUserID) {
              orderObj.isTeamOrder = true;
            }
            if (orderObj.offlineDBId && orderObj.ownerId && orderObj.surgeryOrderStatusString == 'Completed') {
              activities.push(orderObj)
            }
          } else if (rawOrder['indskr_ordertype'] && rawOrder['indskr_ordertype'] == 548910002) {
            let procedutreTracker = new ProcedureTrackerActivity(rawOrder);
            procedutreTracker.type = ActivityType.ProcedureTracker;
            if (procedutreTracker.ownerId != this.authService.user.systemUserID) {
              procedutreTracker.isTeamOrder = true;
            }
            if (procedutreTracker.status == 548910001) {
              activities.push(procedutreTracker)
            }
          } else {
            let orderObj = new OrderActivity(rawOrder);
            orderObj.type = ActivityType.Order;
            if (orderObj.ownerId != this.authService.user.systemUserID) {
              orderObj.isTeamOrder = true;
            }
            if (orderObj.offlineDBId && orderObj.ownerId && (orderObj.orderStatusString == 'Approved' || orderObj.orderStatusString == 'Fulfilled')) {
              orderObj.statusString = orderObj.orderStatusString;
              activities.push(orderObj)
            }
          }
        });
      }
      if (this.selected)
        this.selected.activitesByAccount = activities;
      this.isAccountTimelineLoaded = true;
    }
  }

    public getgroupedActivitiesByAccount(activities,filterValue:string): Array<Object>{
        const events: any[] = [];
        const groupedElements: any = {};
        let groupByKey = 'scheduledStart';
        // let activities = this.selected.activitesByAccount
        if (activities) {
            let value: Activity[] = activities; //this.contactInformation.activitesByContact;
            if (value.length > 0) {
                if(filterValue == 'My Activities'){
                  value = value.filter(activity => activity.ownerId == this.authService.user.systemUserID || activity['surveyedBy'] == this.authService.user.displayName);
                } else if(filterValue != 'All Activities'){
                  if (filterValue == ActivityType.SurgeryOrder) {
                    value = value.filter(o=>{
                      return o.type == ActivityType.SurgeryOrder || o.type == ActivityType.ProcedureTracker
                    })
                  } else {
                    value = value.filter(o=>{
                      return o.type == filterValue
                    })
                  }
                }
                value.map(v => {
                  if(v.hasOwnProperty(groupByKey) && v[groupByKey] != '' && v[groupByKey] != "Invalid Date" && v[groupByKey] != undefined) {
                    v[groupByKey] = new Date(v[groupByKey]);
                  }
                });
                value.sort((a, b) => (b.scheduledStart.getTime()) - (a.scheduledStart.getTime()));
                value.forEach((obj: any) => {
                  if(obj.hasOwnProperty(groupByKey) && !isNaN(obj[groupByKey].valueOf())){
                    let item = this.datePipe.transform(obj[groupByKey], 'MMMM y', undefined, this.translate.currentLang);
                    if (!(item in groupedElements)) {
                        groupedElements[item] = [];
                    }
                    groupedElements[item].push(obj);
                  }
                });

                for (let prop in groupedElements) {
                    if (groupedElements.hasOwnProperty(prop)) {
                        //let meetingDay = isToday(prop) ? "Today" : isTomorrow(prop) ? "Tomorrow" : format(prop,'dddd MMM D');
                        events.push({
                            key: prop,
                            list: groupedElements[prop]
                        });
                    }
                }
            }
        }
        return events;
    }

    public sortAccountsTree() {
      if (this.translate.currentLang == 'zh_CN') {
        this.accountsTree.sort((a, b) => a['accountName'].localeCompare(b['accountName'], ["zh-CN-u-co-pinyin"]));
      } else {
        this.accountsTree.sort((accountA, accountB): number => {
            return (accountA.accountName.toLowerCase() < accountB.accountName.toLowerCase() ? -1 : 1);
        });
      }
    }

    private recursiveAccountParentMatch() {
        //We have an array of leftover accounts not matched yet with valid parent id's.

        //This keeps track to make sure we actually make progress.
        let changes: number = 0;


        //For each account, try to find it's parent in our finished array, if found, add and remove from this list then recall.
        this.leftOverAccounts.map(leftOverAccount => {
            let parentAccount = this.accounts.find(account => {
                return account.id === leftOverAccount.parentAccountID;
            });

            //If found
            if (parentAccount) {
                //Add
                parentAccount.subAccounts.push(leftOverAccount);

                //Remove
                const indexOfAccount = this.leftOverAccounts.indexOf(leftOverAccount);
                this.leftOverAccounts.splice(indexOfAccount, 1);

                changes++;
            }
        });

        //Recursion
        if (this.leftOverAccounts.length > 0 && changes > 0)
            this.recursiveAccountParentMatch();

        if(this.leftOverAccounts.length > 0 && changes == 0){
            this.accountsTree.push(...this.leftOverAccounts);
        }
    }

    private recursiveOneKeyAccountParentMatch() {
      //We have an array of leftover accounts not matched yet with valid parent id's.
      //This keeps track to make sure we actually make progress.
      let changes: number = 0;

      //For each account, try to find it's parent in our finished array, if found, add and remove from this list then recall.
      this.leftOverAccounts.map(leftOverAccount => {
          let parentAccount = this.oneKeyAccounts.find(account => {
              return account.id === leftOverAccount.parentAccountID;
          });
          //If found
          if (parentAccount) {
              //Add
              parentAccount.subAccounts.push(leftOverAccount);
              //Remove
              const indexOfAccount = this.leftOverAccounts.indexOf(leftOverAccount);
              this.leftOverAccounts.splice(indexOfAccount, 1);
              changes++;
          }
      });

      //Recursion
      if (this.leftOverAccounts.length > 0 && changes > 0)
          this.recursiveOneKeyAccountParentMatch();

      if(this.leftOverAccounts.length > 0 && changes == 0){
          this.oneKeyAccountsTree.push(...this.leftOverAccounts);
      }
    }

    get loadedAccounts(): boolean {
        return (this.accounts) ? this.accounts.length > 0 : false;
    }

    get selectedAccountString(): string {
        if (!this.loadedAccounts) return 'Loading...';

        //Only one contact, return his name
        if (this.selectedAccounts.length === 1) {
            return `${this.selectedAccounts[0].accountName}`;
        } else if (this.selectedAccounts.length >= 2) {
            return `${this.selectedAccounts[0].accountName} +${this.selectedAccounts.length-1}`;
        }

        return `Select Accounts`;
    }

    /**
     * Moves an account when selected to the current selected activitys account array
     *
     * @param {Account} account
     * @returns
     * @memberof AccountOfflineService
     */
    public moveAccountToSelected(account: Account) {

        if (this.activityService.selectedActivity instanceof AppointmentActivity) {
            let selectedIndex = this.activityService.selectedActivity.accounts.findIndex(sAccount => sAccount.id === account.id);

            if (selectedIndex !== -1) return;
            this.activityService.selectedActivity.accounts.push(account);
        } else if(this.activityService.selectedActivity instanceof PhoneActivity){
            let selectedIndex = this.activityService.selectedActivity.accounts.findIndex(sAccount => sAccount.id === account.id);

            if (selectedIndex !== -1) return;
            this.activityService.selectedActivity.accounts.push(account);
        }

        else {
            //Call plan called this, add to internal array
            let selectedIndex = this.selectedAccounts.findIndex(sAccount => sAccount.id === account.id);

            if (selectedIndex !== -1) return;
            this.selectedAccounts.push(account);
        }
    }

    /**
     * Removes an account from the currently selected activitys account array
     *
     * @param {Account} account
     * @returns
     * @memberof AccountOfflineService
     */
    public removeAccountFromSelected(account: Account) {
        account.isSelected = false;

        if (this.activityService.selectedActivity instanceof AppointmentActivity) {
            let selectedIndex = this.activityService.selectedActivity.accounts.findIndex(sAccount => sAccount.id === account.id);

            if (selectedIndex < 0) return;
            this.activityService.selectedActivity.accounts.splice(selectedIndex, 1);
        } else {
            //Call plan called this, add to internal array
            let selectedIndex = this.selectedAccounts.findIndex(sAccount => sAccount.id === account.id);

            if (selectedIndex < 0) return;
            this.selectedAccounts.splice(selectedIndex, 1);
        }
    }

    public filterAccounts(query: string) {
        this.searchAccounts = this.accounts.filter(account => {
            return (account.accountName.toLowerCase().includes(query.toLowerCase()));
        });
    }

    public clear() {
        this.accounts = [];
        this.clearFilters();
    }

    public clearFilters() {
        this.searchAccounts = [];
        this.selectedAccounts = [];
        this.selected = undefined;
        this.selectedFor = undefined;
    }

    /*
    dist: distance in meter
    */
    public async getAccountNearByMeter(dist, selectedAccountId?) {
      const nearByAccounts = [];
      const currentCoordinates: any = this.authService.user.currentLocation;
      if (!this.deviceService.isOffline && currentCoordinates && currentCoordinates.longitude > 0 && currentCoordinates.latitude > 0) {
        let rawData = await this.disk.retrieve('account_linked_entityindskr_indskr_customeraddress_v2');
        if (rawData && !_.isEmpty(rawData['raw'])) {
          for (let item of rawData['raw']) {
            const lat = item['indskr_address.indskr_latitude'];
            const long = item['indskr_address.indskr_longitude'];
            const distance = await this.locationService.calculateDistance(currentCoordinates.latitude, currentCoordinates.longitude, lat, long, 'M');
            const account = this.accounts.find(acc => acc.id === item['accountid']);
            if (distance <= dist && account) {
              account.isSelected = selectedAccountId === account.id;
              account['distance'] = Math.floor(distance);
              nearByAccounts.push(account);
            }
          }
        }
      }
      return _.orderBy(nearByAccounts, ['distance', 'accountName'], 'asc');
    }

    public addRemoveAccount(account: Account){
        // this.selected = account;
        if(account.isSelected){
            this.addToSelected(account);
        }else{
            this.removeFromSelected(account);
        }
    }

    addToSelected = (account: Account) => {
        account.isSelected = true;
        this.selectedAccounts.push(account);
    }

    removeFromSelected = (account:Account) => {
        // this.accountService.removeAccountFromSelected(account);
        account.isSelected = false;
        let selectedIndex = this.selectedAccounts.findIndex(sAppContact => sAppContact.id === account.id);
        if (selectedIndex < 0) return;

        this.selectedAccounts.splice(selectedIndex, 1);

        if (this.activityService.selectedActivity instanceof AppointmentActivity) {
          let item = this.activityService.selectedActivity.accounts.find(sAccount => sAccount.id == account.id);
          if(item){
              item.isSelected = false;
          }
      }
    }

    public createAccountplanAccountsTree(accountPlans:Array<AccountPlan>):void{
        this.accountsTreeForOtherTool = [];
        this.leftOverAccounts = [];
        this.uniqueAccountsFromOtherTools = [];
        accountPlans.forEach(accountplan => {
          if (!this.uniqueAccountsFromOtherTools.find(account => account.id == accountplan.accountId)) {
            let foundAcc = this.getAccountById(accountplan.accountId);
            if (foundAcc) {
              let account: Account = JSON.parse(JSON.stringify(foundAcc));
              account.subAccounts = [];
              if (account) {
                this.uniqueAccountsFromOtherTools.push(account);
              }
            }
          }
        });
        this.uniqueAccountsFromOtherTools.forEach(uniqueAccount =>{
            if(!uniqueAccount.parentAccountID){
              this.accountsTreeForOtherTool.push(uniqueAccount);
            }else{

              //Find our existing parent and add it to a sublevel
              let parentAccount = this.uniqueAccountsFromOtherTools.find(account => {
                return account.id === uniqueAccount.parentAccountID;
              });

              //If we found a parent, simply add it
              if (parentAccount) {
                //   if(!(parentAccount.subAccounts && parentAccount.subAccounts.length > 0 && parentAccount.subAccounts.find(ac => ac.id == uniqueAccount.id))){
                //     parentAccount.subAccounts.push(uniqueAccount);
                //   }
                parentAccount.subAccounts.push(uniqueAccount);
              } else {
                  //Add it as a leftover, it's true parent is still being processed
                  this.leftOverAccounts.push(uniqueAccount);
              }
            }
          });

          //Recursively add accounts to parents
          try {
            if(this.leftOverAccounts.length > 0){
              this.findParentAccountInAccountsData();
            }
          } catch (stackOverflow) {
              console.error('Caught potential stack overflow error on recursively mapping accounts', stackOverflow);
          }
        if(this.accountsTreeForOtherTool && this.accountsTreeForOtherTool.length > 1){
          if (this.translate.currentLang == 'zh_CN') {
            this.accountsTreeForOtherTool.sort((a, b) => a['accountName'].localeCompare(b['accountName'], ["zh-CN-u-co-pinyin"]));
          } else
            this.accountsTreeForOtherTool.sort((accountA, accountB): number => {
                return (accountA.accountName.toLowerCase() < accountB.accountName.toLowerCase() ? -1 : 1);
            });
        }
      }

      public createMarketingplanAccountsTree(marketingPlans:Array<MarketingPlan>):void{
        this.accountsTreeForOtherTool = [];
        this.leftOverAccounts = [];
        this.uniqueAccountsFromOtherTools = [];
        marketingPlans.forEach(accountplan => {
          accountplan.accounts.forEach(acc => {
            if (!this.uniqueAccountsFromOtherTools.find(account => account.id == acc.id)) {
              let foundAcc = this.getAccountById(acc.id);
              if (foundAcc) {
                let account: Account = JSON.parse(JSON.stringify(foundAcc));
                account.subAccounts = [];
                if (account) {
                  this.uniqueAccountsFromOtherTools.push(account);
                }
              }
            }
          })
        });
        this.uniqueAccountsFromOtherTools.forEach(uniqueAccount =>{
            if(!uniqueAccount.parentAccountID){
              this.accountsTreeForOtherTool.push(uniqueAccount);
            }else{

              //Find our existing parent and add it to a sublevel
              let parentAccount = this.uniqueAccountsFromOtherTools.find(account => {
                return account.id === uniqueAccount.parentAccountID;
              });

              //If we found a parent, simply add it
              if (parentAccount) {
                //   if(!(parentAccount.subAccounts && parentAccount.subAccounts.length > 0 && parentAccount.subAccounts.find(ac => ac.id == uniqueAccount.id))){
                //     parentAccount.subAccounts.push(uniqueAccount);
                //   }
                parentAccount.subAccounts.push(uniqueAccount);
              } else {
                  //Add it as a leftover, it's true parent is still being processed
                  this.leftOverAccounts.push(uniqueAccount);
              }
            }
          });

          //Recursively add accounts to parents
          try {
            if(this.leftOverAccounts.length > 0){
              this.findParentAccountInAccountsData();
            }
          } catch (stackOverflow) {
              console.error('Caught potential stack overflow error on recursively mapping accounts', stackOverflow);
          }
        if(this.accountsTreeForOtherTool && this.accountsTreeForOtherTool.length > 1){
          if (this.translate.currentLang == 'zh_CN') {
            this.accountsTreeForOtherTool.sort((a, b) => a['accountName'].localeCompare(b['accountName'], ["zh-CN-u-co-pinyin"]));
          } else
            this.accountsTreeForOtherTool.sort((accountA, accountB): number => {
                return (accountA.accountName.toLowerCase() < accountB.accountName.toLowerCase() ? -1 : 1);
            });
        }
      }   

    public createOpportunitiesAccountsTree(opportunities: Array<Opportunity>): void {
        this.accountsTreeForOtherTool = [];
        this.leftOverAccounts = [];
        this.uniqueAccountsFromOtherTools = [];
        opportunities.forEach(opportunity => {
            if (!this.uniqueAccountsFromOtherTools.find(account => account.id == opportunity.accountID)) {
                let foundAcc = this.getAccountById(opportunity.accountID);
                if (foundAcc) {
                    let account: Account = JSON.parse(JSON.stringify(foundAcc));
                    account.subAccounts = [];
                    if (account) {
                        this.uniqueAccountsFromOtherTools.push(account);
                    }
                }
            }
        });
        this.uniqueAccountsFromOtherTools.forEach(uniqueAccount => {
            if (!uniqueAccount.parentAccountID) {
                this.accountsTreeForOtherTool.push(uniqueAccount);
            } else {
                let parentAccount = this.uniqueAccountsFromOtherTools.find(account => {
                    return account.id === uniqueAccount.parentAccountID;
                });
                if (parentAccount) {
                    parentAccount.subAccounts.push(uniqueAccount);
                } else {
                    this.leftOverAccounts.push(uniqueAccount);
                }
            }
        });

        //Recursively add accounts to parents
        try {
            if (this.leftOverAccounts.length > 0) {
                this.findParentAccountInAccountsData();
            }
        } catch (stackOverflow) {
            console.error('Caught potential stack overflow error on recursively mapping accounts', stackOverflow);
        }
        if (this.accountsTreeForOtherTool && this.accountsTreeForOtherTool.length > 1) {
          if (this.translate.currentLang == 'zh_CN') {
            this.accountsTreeForOtherTool.sort((a, b) => a['accountName'].localeCompare(b['accountName'], ["zh-CN-u-co-pinyin"]));
          } else {
            this.accountsTreeForOtherTool.sort((accountA, accountB): number => {
              return (accountA.accountName.toLowerCase() < accountB.accountName.toLowerCase() ? -1 : 1);
            });
          }
        }
    }

    //One-Key Accounts mapping
    public createOneKeyAccountsTree(raw: any): void {
      if (Array.isArray(raw)) {
        this.oneKeyAccounts = [];
        this.oneKeyAccountsTree = [];
        this.leftOverAccounts = [];

        //Sort the raw json by if they have a parent account id, this lets us have the parents first.
        raw.sort(
            (accountA: any, accountB: any): number => {
                //Both are parents, return alphanumeric
                if (!accountA.hasOwnProperty('_parentaccountid_value') && !accountB.hasOwnProperty('_parentaccountid_value')) {
                    return (accountA.name > accountB.name ? -1 : 1);
                } else if (!accountA.hasOwnProperty('_parentaccountid_value') && accountB.hasOwnProperty('_parentaccountid_value')) {
                    //A is a parent, b is a child, return 1
                    return -1;
                } else {
                    //B is a parent, a is a child, return -1?
                    return 1;
                }
            }
        );
        this.oneKeyAccountsCount = raw.length;
        raw.map(rawJSON => {
            const newAccount = new Account(rawJSON);
            this.mapAccountFieldsToSearchIndex(rawJSON);
            if(!this.oneKeyAccounts.some(acc => acc.id === newAccount.id)){
              this.oneKeyAccounts.push(newAccount);
            }
            //If the account doesn't have a value for parent account id then it's a base level
            if (!newAccount.parentAccountID) {
              if(!this.oneKeyAccountsTree.some(acc => acc.id === newAccount.id)){
                this.oneKeyAccountsTree.push(newAccount);
              }
            } else {
                //Find our existing parent and add it to a sublevel
                let parentAccount = this.oneKeyAccountsTree.find(account => {
                    return account.id === newAccount.parentAccountID;
                });
                //If we found a parent, simply add it
                if (parentAccount) {
                  if(!parentAccount.subAccounts.some(acc => acc.id === newAccount.id)){
                    parentAccount.subAccounts.push(newAccount);
                  }
                } else {
                    //Add it as a leftover, it's true parent is still being processed
                    if(!this.leftOverAccounts.some(acc => acc.id === newAccount.id)){
                      this.leftOverAccounts.push(newAccount);
                    }
                }
            }
        });
        //Recursively add accounts to parents
        try {
            this.recursiveOneKeyAccountParentMatch();
        } catch (stackOverflow) {
            console.error('Caught potential stack overflow error on recursively mapping one-key accounts', stackOverflow);
        }
      } else {
        console.error('Unsupported data format for One-Key Accounts!');
      }

      if (this.oneKeyAccountsTree && this.oneKeyAccountsTree.length > 1) {
        if (this.translate.currentLang == 'zh_CN') {
          this.oneKeyAccountsTree.sort((a, b) => a['accountName'].localeCompare(b['accountName'], ["zh-CN-u-co-pinyin"]));
        } else
        this.oneKeyAccountsTree.sort((accountA, accountB): number => {
          return (accountA.accountName.toLowerCase() < accountB.accountName.toLowerCase() ? -1 : 1);
        });
      }
    }

    public createAccountsTree(accounts:Account[]):void {
        this.accountsTreeForOtherTool = [];
        this.leftOverAccounts = [];
        this.uniqueAccountsFromOtherTools = [];
        accounts.forEach(account => {
            if (!this.uniqueAccountsFromOtherTools.find(uniqueaccount => account.id == uniqueaccount.id)) {
                let foundAcc = this.getAccountById(account.id);
                if (foundAcc) {
                    let newAccount: Account = _.cloneDeep(foundAcc);//JSON.parse(JSON.stringify(foundAcc));
                    newAccount.subAccounts = [];
                    if (newAccount) {
                        this.uniqueAccountsFromOtherTools.push(newAccount);
                    }
                }
            }
        });
        this.uniqueAccountsFromOtherTools.forEach(uniqueAccount => {
            if (!uniqueAccount.parentAccountID) {
                this.accountsTreeForOtherTool.push(uniqueAccount);
            } else {
                let parentAccount = this.uniqueAccountsFromOtherTools.find(account => {
                    return account.id === uniqueAccount.parentAccountID;
                });
                if (parentAccount) {
                    parentAccount.subAccounts.push(uniqueAccount);
                } else {
                    this.leftOverAccounts.push(uniqueAccount);
                }
            }
        });

        //Recursively add accounts to parents
        try {
            if (this.leftOverAccounts.length > 0) {
                this.findParentAccountInAccountsData();
            }
        } catch (stackOverflow) {
            console.error('Caught potential stack overflow error on recursively mapping accounts', stackOverflow);
        }
        if (this.accountsTreeForOtherTool && this.accountsTreeForOtherTool.length > 1) {
          if (this.translate.currentLang == 'zh_CN') {
            this.accountsTreeForOtherTool.sort((a, b) => a['accountName'].localeCompare(b['accountName'], ["zh-CN-u-co-pinyin"]));
          } else
            this.accountsTreeForOtherTool.sort((accountA, accountB): number => {
                return (accountA.accountName.toLowerCase() < accountB.accountName.toLowerCase() ? -1 : 1);
            });
        }
    }

    private findParentAccountInAccountsData() {

        let changes: number = 0;

        //For each account, try to find it's parent in our finished array, if found, add and remove from this list then recall.
        this.leftOverAccounts.forEach(leftOverAccount => {
          let foundParentAccount = this.uniqueAccountsFromOtherTools.find(account => {
            return account.id === leftOverAccount.parentAccountID;
          });


          //If found
          if (foundParentAccount) {
            //Add
            // if(!(foundParentAccount.subAccounts && foundParentAccount.subAccounts.length > 0 && foundParentAccount.subAccounts.find(ac => ac.id == uniqueAccount.id))){
            //     foundParentAccount.subAccounts.push(leftOverAccount);
            // }
            foundParentAccount.subAccounts.push(leftOverAccount);

            //Remove
            const indexOfAccount = this.leftOverAccounts.indexOf(leftOverAccount);
            this.leftOverAccounts.splice(indexOfAccount, 1);
            changes++;
          }
        });

        //Recursion
        if (this.leftOverAccounts.length > 0 && changes > 0){
          try {
            this.findParentAccountInAccountsData();
          } catch (stackOverflow) {
            console.error('Caught potential stack overflow error on recursively mapping accounts', stackOverflow);
          }
        }

        if (this.leftOverAccounts.length > 0 && changes == 0) {
          this.accountsTreeForOtherTool.push(...this.leftOverAccounts);
          this.leftOverAccounts = [];
        }
    }

    public async getAffiliatedAccountsFromSelectedContactsForMeeting(contacts:Contact[]):Promise<Account[]>{
        let affiliatedAccounts:Account[] = [];
        if(contacts.length != 0){
            let rawData = await this.disk.retrieve(OFFLINE_DB_LINKED_ENTITY_NAME.ACCOUNT_CONTACT_AFFILIATION);
            if(rawData && rawData.raw && Array.isArray(rawData.raw) && rawData.raw.length != 0){
                rawData.raw.forEach(item => {
                    if(item.hasOwnProperty('contactid') && item.hasOwnProperty('indskr_accountcontactaffiliation.indskr_accountid') && item.hasOwnProperty('indskr_accountcontactaffiliation.statecode') && item['indskr_accountcontactaffiliation.statecode'] == 0 && contacts.some(contact=> contact.ID == item['contactid'])){
                        let foundAccount = this.getAccountById(item['indskr_accountcontactaffiliation.indskr_accountid']);
                        if(foundAccount && !affiliatedAccounts.some(ac=> ac.id == foundAccount.id) && !((!foundAccount || (foundAccount.status === 2 || foundAccount.status == 548910003 || foundAccount.status == 548910010 || foundAccount.status == 548910011 || foundAccount.status == 548910012 || foundAccount.status == 548910013)))){
                          affiliatedAccounts.push(foundAccount);
                        }
                    }
                });
            }
        }
        // contacts.forEach(contact => {
        //     if(contact.accountRelationships && contact.accountRelationships.length != 0){
        //         contact.accountRelationships.forEach(affiliatedAccount => {
        //             let foundAccount = this.getAccountById(affiliatedAccount.accountId);
        //             if(foundAccount && !affiliatedAccounts.some(ac=> ac.id == foundAccount.id && foundAccount.state == 0)){
        //                 affiliatedAccounts.push(foundAccount);
        //             }
        //         });
        //     }
        // })
        if (this.translate.currentLang == 'zh_CN') {
          affiliatedAccounts = affiliatedAccounts.sort((a, b) => a['accountName'].localeCompare(b['accountName'], ["zh-CN-u-co-pinyin"]));
        } else {
          affiliatedAccounts = affiliatedAccounts.sort((accountA, accountB) => {
            return (accountA.accountName.toLowerCase() < accountB.accountName.toLowerCase() ? -1 : 1);
          });
        }
        return affiliatedAccounts;
    }

    public mapAccountFieldsToSearchIndex(rawAccount){
        if(!this.searchConfigService.isConfigInitiated){
          this.searchConfigService.initSearchConfigs();
          this.searchConfigService.isConfigInitiated = true;
        }
        let accountDetailEntries = _.entries(rawAccount);
        this.searchConfigService.accountConfiguredSearchIndexConfig.forEach(config => {
            let suffix: string = '';
            let prefix: string = '';
            if (config.controlDataType) {
                if (config.controlDataType == ControlDataType.PicklistType || config.controlDataType == ControlDataType.BooleanType
                  || config.controlDataType == ControlDataType.StatusType || config.controlDataType == ControlDataType.StateType) {
                    suffix = '@OData.Community.Display.V1.FormattedValue'
                } else if (config.controlDataType == ControlDataType.LookupType) {
                  if(config.isMultilingualLookup){
                    suffix = '_value';
                    prefix = '';
                  }else{
                    suffix = '_value@OData.Community.Display.V1.FormattedValue'
                    prefix = '_';
                  }
                }
            }
            let idx = accountDetailEntries.findIndex(entry => entry[0] == prefix + config.categoryRelativePath + suffix);
            if (idx >= 0) {
                if (!config.values.find(a => a == String(accountDetailEntries[idx][1]))) {
                    config.values.push(String(accountDetailEntries[idx][1]));
                    let mappingValue;
                    if(config.controlDataType == ControlDataType.LookupType){
                      mappingValue = {
                        actualValue: rawAccount['_'+config.categoryRelativePath+'_value'],
                        formattedValue: String(accountDetailEntries[idx][1]),
                      };
                    }else{
                      mappingValue = {
                        actualValue: rawAccount[config.categoryRelativePath],
                        formattedValue: String(accountDetailEntries[idx][1]),
                      };
                    }
                    config.mappingValues.push(mappingValue);
                }
            }
        });
        // newAccount.accountAddressList.forEach(add=>{
        //     if(add.country && !this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='Country').values.some(o => o== add.country))
        //         this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='Country').values.push(add.country)
        //     if(add.region && !this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='Regions').values.some(o => o==add.region))
        //         this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='Regions').values.push(add.region);
        //     if(add.city && !this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='City').values.some(o => o==add.city))
        //         this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='City').values.push(add.city);
        //     if(add.state && !this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='State').values.some(o => o==add.state))
        //         this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='State').values.push(add.state);
        //     if(add.postalCode && !this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='Postal Code').values.some(o => o==add.postalCode))
        //         this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='Postal Code').values.push(add.postalCode);
        // })
        // newAccount.affiliatedAccounts.forEach(acc=>{
        //     if(acc.accountName &&!this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='Related Accounts').values.some(o=> o==acc.accountName))
        //         this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='Related Accounts').values.push(acc.accountName)
        // })
        // newAccount.EmailAddresses.forEach(email=>{
        //   if(email.emailAddress &&!this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='Email Address').values.some(o=> o==email.emailAddress))
        //       this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='Email Address').values.push(email.emailAddress)
        // })
        // if(newAccount.telephone && !this.searchConfigService.accountsSearchIndexesConfig.find(config=> config.categoryName == 'Phone Number').values.some(o=>o == newAccount.telephone)){
        //     this.searchConfigService.accountsSearchIndexesConfig.find(config=> config.categoryName == 'Phone Number').values.push(newAccount.telephone)
        // }
        // newAccount.relatedContacts.forEach(cont=>{
        //     if(cont.contactName && !this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='Customer Affiliations').values.some(o=> o==cont.contactName))
        //         this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='Customer Affiliations').values.push(cont.contactName)
        // })
        // newAccount.coveragePosistionList.forEach(position=>{
        //     if(position.positionName &&!this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='Coverage Team').values.some(o=> o==position.positionName))
        //         this.searchConfigService.accountsSearchIndexesConfig.find(config=>config.categoryName=='Coverage Team').values.push(position.positionName);
        // })
        // let extendedDetails = _.entries(newAccount.extendedDetails);
        // extendedDetails.forEach(entry=>{
        //   if(entry[0] && entry[1] && MultiLingualSearchIndexingForAccountExtendedDeatils[entry[0]]){
        //     if(!this.searchConfigService.accountsSearchIndexesConfig.find(config=> config.categoryName == MultiLingualSearchIndexingForAccountExtendedDeatils[entry[0]].categoryName)){
        //       this.searchConfigService.accountsSearchIndexesConfig.push({
        //         categoryName:MultiLingualSearchIndexingForAccountExtendedDeatils[entry[0]].categoryName,
        //         translationKey:MultiLingualSearchIndexingForAccountExtendedDeatils[entry[0]].translationKey,
        //         categoryRelativePath:'extendedDetails.'+entry[0],
        //         values:[]
        //       })
        //     }
        //     if(!this.searchConfigService.accountsSearchIndexesConfig.find(config=> config.categoryName == MultiLingualSearchIndexingForAccountExtendedDeatils[entry[0]].categoryName).values.some(o=>o == entry[1])){
        //       this.searchConfigService.accountsSearchIndexesConfig.find(config=> config.categoryName == MultiLingualSearchIndexingForAccountExtendedDeatils[entry[0]].categoryName).values.push(entry[1])
        //     }
        //   }
        // })
        // let financialInfo = _.entries(newAccount.financialInfo);
        // financialInfo.forEach(entry=>{
        //   if(entry[0] && entry[1] && MultiLingualSearchIndexingForAccountFinancialInfo[entry[0]] && !MultiLingualSearchIndexingForAccountFinancialInfo[entry[0]].isBooleanTypeCategory){
        //     if(!this.searchConfigService.accountsSearchIndexesConfig.find(config=> config.categoryName == MultiLingualSearchIndexingForAccountFinancialInfo[entry[0]].categoryName)){
        //       this.searchConfigService.accountsSearchIndexesConfig.push({
        //         categoryName:MultiLingualSearchIndexingForAccountFinancialInfo[entry[0]].categoryName,
        //         translationKey:MultiLingualSearchIndexingForAccountFinancialInfo[entry[0]].translationKey,
        //         categoryRelativePath:'financialInfo.'+entry[0],
        //         values:[]
        //       })
        //     }
        //     if(!this.searchConfigService.accountsSearchIndexesConfig.find(config=> config.categoryName == MultiLingualSearchIndexingForAccountFinancialInfo[entry[0]].categoryName).values.some(o=>o == entry[1])){
        //       this.searchConfigService.accountsSearchIndexesConfig.find(config=> config.categoryName == MultiLingualSearchIndexingForAccountFinancialInfo[entry[0]].categoryName).values.push(entry[1])
        //     }
        //   }
        //   if(entry[0] && entry[1] && MultiLingualSearchIndexingForAccountFinancialInfo[entry[0]] && MultiLingualSearchIndexingForAccountFinancialInfo[entry[0]].isBooleanTypeCategory){
        //     if(!this.searchConfigService.accountsSearchIndexesConfig.find(config=> config.categoryName == MultiLingualSearchIndexingForAccountFinancialInfo[entry[0]].categoryName)){
        //       this.searchConfigService.accountsSearchIndexesConfig.push({
        //         categoryName:MultiLingualSearchIndexingForAccountFinancialInfo[entry[0]].categoryName,
        //         translationKey:MultiLingualSearchIndexingForAccountFinancialInfo[entry[0]].translationKey,
        //         categoryRelativePath:'financialInfo.'+entry[0],
        //         isBooleanTypeCategory: true,
        //         values:['Yes','No']
        //       })
        //     }
        //   }
        // })
        // newAccount.configuredFields.forEach(confField=>{
        //     if(confField['fieldType'] && confField['fieldType']!='Boolean' && confField['fieldName'] && confField['fieldValue']){
        //       if(!this.searchConfigService.accountsSearchIndexesConfig.find(config=> config.categoryName == confField['fieldName'])){
        //         this.searchConfigService.accountsSearchIndexesConfig.push({
        //           categoryName:confField['fieldName'],
        //           categoryDisplayName:confField['fieldName'],
        //           categoryRelativePath:'configuredFields.fieldValue',
        //           values:[]
        //         })
        //       }
        //       if(!this.searchConfigService.accountsSearchIndexesConfig.find(config=> config.categoryName == confField['fieldName']).values.some(o=>o == confField['fieldValue'])){
        //         this.searchConfigService.accountsSearchIndexesConfig.find(config=> config.categoryName == confField['fieldName']).values.push(confField['fieldValue'])
        //       }
        //     }
        //     if(confField['fieldType'] && confField['fieldType']=='Boolean' && confField['fieldName'] && confField['fieldValue']){
        //       if(!this.searchConfigService.accountsSearchIndexesConfig.find(config=> config.categoryName == confField['fieldName'])){
        //         this.searchConfigService.accountsSearchIndexesConfig.push({
        //           categoryName:confField['fieldName'],
        //           categoryDisplayName:confField['fieldName'],
        //           categoryRelativePath:'configuredFields.fieldValue',
        //           isBooleanTypeCategory: true,
        //           values:['Yes','No']
        //         })
        //       }
        //     }
        // })
    }

    async updateNewAccountStatus(accounts: Account[]){
      if(accounts && accounts.length > 0) {
        let offlineSaved = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT, true);
        if(offlineSaved && offlineSaved.raw && Array.isArray(offlineSaved.raw)){
          accounts.forEach(ac =>{
              let foundSavedIndex = offlineSaved.raw.findIndex(savedAccount => savedAccount.accountid === ac.id);
              if (foundSavedIndex >= 0) {
                offlineSaved.raw[foundSavedIndex]['isNew'] = false;
              }
          });
          await this.disk.updateDocWithIdAndRev(offlineSaved);
        }
      }
    }

    public async addUpdateRawAccountDetails(rawAccount): Promise<Account> {
        // let offlineSaved = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT, true);
        // if (rawAccount && rawAccount.accountid && offlineSaved && offlineSaved.raw && Array.isArray(offlineSaved.raw)) {
        //     let foundSavedIndex = offlineSaved.raw.findIndex(savedAccount => savedAccount.accountid === rawAccount.accountid);
        //     rawAccount['configuredFields'] = this.authService.updateConfiguredFieldValues(rawAccount,'account');
        //     rawAccount['lastUpdatedTime'] = new Date().getTime();
        //     if (foundSavedIndex >= 0) {

        //         offlineSaved.raw[foundSavedIndex] = rawAccount;
        //     }else{
        //         offlineSaved.raw.push(rawAccount);
        //     }
        //     await this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT, doc => (offlineSaved))
        //     .catch(error => {
        //         console.error('_saveAccounts: ', error);
        //     });
        // }
        if(this.accounts && this.accounts.length != 0){
            const newAccount = new Account(rawAccount);
            this.mapAccountFieldsToSearchIndex(newAccount);
            let foundIndex = this.accounts.findIndex(account => account.id === newAccount.id);
            if (foundIndex >= 0) {
                if (this.accounts[foundIndex].subAccounts && this.accounts[foundIndex].subAccounts.length != 0) {
                    newAccount.subAccounts = this.accounts[foundIndex].subAccounts;
                }
                if (this.accounts[foundIndex].interactionDate) {
                    newAccount.interactionDate = this.accounts[foundIndex].interactionDate;
                }
                if (this.accounts[foundIndex].interactionType) {
                    newAccount.interactionType = this.accounts[foundIndex].interactionType;
                }
                this.accounts[foundIndex] = newAccount;
            }else{
                this.accounts.push(newAccount);
            }
            return newAccount;
        }
    }

    public async addMultipleRawAccountDetails(rawAccountList: any[]): Promise<Account[]> {
      if(!rawAccountList || (rawAccountList && rawAccountList.length == 0)) return;
      let newAccountList: Account[] = [];
      rawAccountList.forEach(async rawAccount=>{
        let newAccount = await this.addUpdateRawAccountDetails(rawAccount);
        if(newAccount) newAccountList.push(newAccount);
      });
      return newAccountList;
    }

    createGlobalSearchFetchXML(selectedSuggestionsData: SelectedSuggestionPillDataModel[]): string {
      let fetchXML: string;
      let isCustomPositionSearch = false; // Customer position query will differ if search includes it
      const shouldFilterByApprovalStatus = this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_VISIBILITY_APPROVAL_BASED);

      if (Array.isArray(selectedSuggestionsData) && selectedSuggestionsData.length > 0) {
        const attributes: AttributeXMLTag[] = AccountGlobalSearchAttributes;
        const linkedEntities: Partial<LinkedEntityXMLTag>[] = [];
        const filterConditions: FilterConditionXMLTag[] = [];
        const filters: FilterXMLTag[] = [];
        const defaultFilter: FilterXMLTag = JSON.parse(JSON.stringify(AccountGlobalSearchDefaultFilter));

        // Group suggestions data by category
        const groupedSuggestions = _.reduce(selectedSuggestionsData, (result, suggestion, key) => {
          let categoryName = suggestion.categoryName;
          if (!categoryName) {
            categoryName = 'account_char_search';
          } else if (categoryName === 'ADDRESSES') {
            categoryName = 'indskr_indskr_customeraddress_v2';
          }

          if (!result.hasOwnProperty(categoryName)) {
            result[categoryName] = [suggestion];
          } else {
            result[categoryName].push(suggestion);
          }

          return result;
        }, {});

        // Process each suggestion category data. Extract data required to build fetchXML
        const suggestionsDataProcessResults: handleSameCategorySearchReturnValue[] = [];
        for (const key in groupedSuggestions) {
          if (Object.prototype.hasOwnProperty.call(groupedSuggestions, key)) {
            const element = groupedSuggestions[key];

            const result = this._handleSameCategorySearch(element);
            suggestionsDataProcessResults.push(result);
          }
        }

        // Merge extracted data
        for (let i = 0; i < suggestionsDataProcessResults.length; i++) {
          const { linkEntity, filter, filterCondition, hasCustomPositionSearch } = suggestionsDataProcessResults[i];

          if (linkEntity) linkedEntities.push(linkEntity);
          if (filter) defaultFilter.nestedFilters.push(filter);
          if (filterCondition) filterConditions.push(filterCondition);
          if (hasCustomPositionSearch) isCustomPositionSearch = hasCustomPositionSearch;
        }

        // Customer position is one of a default filter for global search however, it is also possible to
        // do a global search on customer position (Coverage Team) and that requires a different fetchXML for it
        // It checks whether there's a position search input and if not, injects the default customer position link-entity
        if (!isCustomPositionSearch) {
          const positionFilterLinkEntity: LinkedEntityXMLTag = JSON.parse(JSON.stringify(AccountGlobalSearchPositionLink));
          if (shouldFilterByApprovalStatus) {
            positionFilterLinkEntity.attributes = [{ name: 'indskr_approvalstatus' }];
          }
          const positionIdList: string[] = this.authService.user.positions.map(p => p.ID);
          positionFilterLinkEntity.linkedEntities[0].filters[0].conditions[0].valueList.push(...positionIdList);
          positionFilterLinkEntity.linkedEntities[0].filters[0].conditions[1].valueList.push(...positionIdList);
          linkedEntities.push(positionFilterLinkEntity);
        }

        // If there's no position search input, injects a default customer position filter
        if (!isCustomPositionSearch) {
          const defaultPositionFilter: FilterConditionXMLTag = AccountGlobalSearchDefaultPositionFilterCondition;
          if (!shouldFilterByApprovalStatus) {
            defaultFilter.conditions.push(defaultPositionFilter);
          }
        }
        defaultFilter.conditions.push(...filterConditions);
        filters.push(defaultFilter);
        if(this.authService.user.globalCategorySearchLimit == 548910000 && (selectedSuggestionsData.length>1 || selectedSuggestionsData[0].type !== SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH)){
          let BUFilter:FilterXMLTag = {
            type:'and',
            conditions:[{
              attribute:'owningbusinessunit',
              operator: FilterConditionOperators.eq,
              value:this.authService.user.xBusinessUnitId,
            }]
          }
          filters.push(BUFilter);
        }
        // Prep order tags
        const orders: OrderXMLTag[] = [AccountGlobalSearchDefaultOrder];

        // Prep entity tag
        const entity: EntityXMLTag = {
          name: 'account',
          attributes,
          linkedEntities,
          filters,
          orders,
          replaceStrings: ['{secondaryInfoPlaceholder}']
        };

        // fetchXML tag
        const fetchXMLTag: FetchXMLTag = {
          distinct: true,
          returnTotalRecordCount: true,
          count: 1000,                    // Following what contact global search is doing for now
          entity
        };

        fetchXML = this.searchConfigService.createCustomFetchXML(fetchXMLTag);

        // Inject secondary info fetchXML
        const secondaryInfoFetchXML = this.secondaryInfoService.SecondaryInfoFetchXML(SecondaryInfoEntityName.Account);
        fetchXML = fetchXML.replace('{secondaryInfoPlaceholder}', secondaryInfoFetchXML);
      }

      return fetchXML;
    }

    private _handleSameCategorySearch(suggestionsDataInSameCategory: SelectedSuggestionPillDataModel[]): handleSameCategorySearchReturnValue {
      let linkEntity: Partial<LinkedEntityXMLTag>;
      let filter: FilterXMLTag;
      let filterCondition: FilterConditionXMLTag;
      let hasCustomPositionSearch = false;

      if (Array.isArray(suggestionsDataInSameCategory) && suggestionsDataInSameCategory.length > 0) {
        let entityName = '';
        let searchConfig: searchIndexDataModel = this.searchConfigService.accountConfiguredSearchIndexConfig
                                                  .find(c => c.categoryRelativePath === suggestionsDataInSameCategory[0].categoryPath);

        // LinkEntity
        if (searchConfig && searchConfig.controlDataType === ControlDataType.LinkedEntity) {
          entityName = searchConfig.entity;

          if (searchConfig.entity === 'indskr_indskr_customeraddress_v2') {
            // Composite Address character / value search
            entityName = 'indskr_address';
            linkEntity = {
              name: searchConfig.entity,
              from: searchConfig.fromAttribute,
              to: searchConfig.toAttribute,
              alias: searchConfig.entity,
              linkType: 'outer',
              linkedEntities: [{
                name: 'indskr_address',
                from: 'indskr_addressid',
                to: 'indskr_address',
                alias:'indskr_address',
                linkType: 'outer',
              }]
            };
          } else if (searchConfig.entity === 'indskr_address') {
            // Address sub category character / value search
            const parentEntitySearchConfig = this.searchConfigService.accountConfiguredSearchIndexConfig.find(c => c.entity === searchConfig.parentEntity);
            if (parentEntitySearchConfig) {
              if (parentEntitySearchConfig.parentEntity === parentEntitySearchConfig.entity
                  && parentEntitySearchConfig.fromAttribute && parentEntitySearchConfig.toAttribute
                ) {
                // This is the top level link entity
                linkEntity = {
                  name: parentEntitySearchConfig.entity,
                  from: parentEntitySearchConfig.fromAttribute,
                  to: parentEntitySearchConfig.toAttribute,
                  alias: parentEntitySearchConfig.entity,
                  linkType: 'outer',
                  linkedEntities: [{
                    // This is the actual entity we're searching
                    name: searchConfig.entity,
                    from: searchConfig.fromAttribute,
                    to: searchConfig.toAttribute,
                    alias: searchConfig.entity,
                    linkType: 'outer',
                  }]
                };
              }
            } else {
              console.error('_handleCategorySearch: Parent search config not found..', searchConfig, suggestionsDataInSameCategory);
            }
          } else {
            const linkEntityFetchXML = this._getLinkEntityFetchXMLRecursive(searchConfig);

            if (linkEntityFetchXML) {
              linkEntity = {
                wholeTagTextToBePasted: linkEntityFetchXML
              }
            } else {
              // No fetchXML found...
              console.error('_handleCategorySearch: No linkEntityFetchXML found..', searchConfig);
            }
          }

          if (searchConfig.entity === 'indskr_customerposition') {
            hasCustomPositionSearch = true;
          }
        }

        // Filter
        if (suggestionsDataInSameCategory.length === 1) {
          filterCondition = this._getFetchXMLFilterConditionFromSuggestion(suggestionsDataInSameCategory[0], searchConfig, entityName);
        } else {
          filter = {
            type: 'or',
            conditions: []
          };

          for (let i = 0; i < suggestionsDataInSameCategory.length; i++) {
            const suggestionData = suggestionsDataInSameCategory[i];
            searchConfig = this.searchConfigService.accountConfiguredSearchIndexConfig
                                .find(c => c.categoryRelativePath === suggestionData.categoryPath);

            const condition = this._getFetchXMLFilterConditionFromSuggestion(suggestionData, searchConfig, entityName);
            if (entityName) {
              condition.entityname = entityName;
            }

            if (condition) {
              filter.conditions.push(condition);
            } else {
              console.warn('_handleCategorySearch: No condition generated...', suggestionData, searchConfig);
            }
          }
        }
      }
      return { linkEntity, filter, filterCondition, hasCustomPositionSearch };
    }
    private _getLinkEntityFetchXMLRecursive(searchConfig: searchIndexDataModel): string {
      if (searchConfig.linkEntityFetchXML) {
        return searchConfig.linkEntityFetchXML;
      } else {
        const parentEntitySearchConfig = this.searchConfigService.accountConfiguredSearchIndexConfig.find(c => c.entity === searchConfig.parentEntity);
        if (parentEntitySearchConfig) {
          return this._getLinkEntityFetchXMLRecursive(parentEntitySearchConfig);
        } else {
          return '';
        }
      }
    }
    private _getFetchXMLFilterConditionFromSuggestion(suggestionData: SelectedSuggestionPillDataModel, searchConfig, entityName: string): FilterConditionXMLTag {
      let filterCondition: FilterConditionXMLTag;

      switch (suggestionData.type) {
        case SuggestionPillType.CATEGORY_VALUESEARCH: {
          filterCondition = {
            attribute: parseTextAfter(suggestionData.categoryPath, '.'),
            operator: FilterConditionOperators.eq,
            value: searchConfig ? getActualValueFromMapping(searchConfig.mappingValues, suggestionData.selectedFacet) : '',
            entityname: entityName
          };
          break;
        }
        case SuggestionPillType.CATEGORY_CHARACTERSEARCH: {
          const shouldAppendNameSuffix = searchConfig && (searchConfig.controlDataType === ControlDataType.PicklistType || searchConfig.controlDataType === ControlDataType.BooleanType || searchConfig.isMultilingualLookup) ? true: false;
          filterCondition = {
            attribute: getAttributeNameForCharacterSearch(parseTextAfter(suggestionData.categoryPath, '.'), shouldAppendNameSuffix),
            operator: FilterConditionOperators.like,
            value: `%${suggestionData.charSearchText}%`,
            entityname: entityName
          };
          break;
        }
        case SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH: {
          filterCondition = {
            attribute: AccountGlobalSearchEntityLevelCharacterSearchAttributeName,
            operator: FilterConditionOperators.like,
            value: `%${suggestionData.charSearchText}%`
          };
          break;
        }
        case SuggestionPillType.BOOLEAN_FIELD: {
          filterCondition = {
            attribute: getAttributeNameForCharacterSearch(parseTextAfter(suggestionData.categoryPath, '.'), true),
            operator: FilterConditionOperators.like,
            value: `%${suggestionData.charSearchText}%`,
            entityname: entityName
          };
        }
        default:
          break;
      }

      return filterCondition;
    }

    mapGlobalSearchedAccounts(rawAccounts: AccountGlobalSearchDTO[], isTargetFilter: boolean = false) {
      if (Array.isArray(rawAccounts)) {
        for (let i = 0; i < rawAccounts.length; i++) {
          const rawAccount = rawAccounts[i];

          if(!isTargetFilter) {
            if (!this.accounts.some(a => a.id === rawAccount.accountid)) {
              const account = new Account(rawAccount as AccountDTO);
              this.globalSearchResults.push(account);
            }
          } else {
            const account = new Account(rawAccount as AccountDTO);
            this.globalSearchResults.push(account);
          }
        }
      }
    }

    resetGlobalSearch(onlyEmptyResults = false) {
      this.globalSearchResults.length = 0;
      this.selectedAccounts.length = 0;
      if (!onlyEmptyResults) this.isGlobalSearching = false;
    }

  public updateAccountNoteInOfflineDB(payload):Promise<void> {
    return new Promise(async (resolve,reject)=> {
      let offlineNotes = await this.disk.retrieve(DB_KEY_PREFIXES.OFFLINE_ACCOUNT_NOTES);
      let data:Array<any> = [];
      if(offlineNotes && offlineNotes.raw){
          data = offlineNotes.raw;
          if (payload) {
              let idx = data.findIndex(a => a && a.hasOwnProperty('annotationid') && a.annotationid == payload.annotationid);
              if (idx >= 0) {
                  data[idx] = payload;
                  if(data[idx].annotationid.includes('offlineaccountnote') && data[idx].deleted == true){
                      data.splice(idx,1);
                  }
              } else {
                  data.push(payload);
              }
          }
          offlineNotes.raw = data;
          offlineNotes.count = data.length;
      }else{
          data.push(payload);
          offlineNotes = {
              raw: data,
              count: data.length,
          }
      }
      this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ACCOUNT_NOTES, offlineNotes.count);
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.OFFLINE_ACCOUNT_NOTES, doc => {
          doc = offlineNotes;
          return doc;
      }).catch(error => console.error('Save Account Notes to DB error: ', error));
      resolve();
    });
  }

  async getAccountRelationshipById(accountId: string, isFormAffiliationExplorer: boolean = false) {
    const accountRelationShipTo = await this.disk.retrieve("account_linked_entityindskr_account_accountaccountaffiliation_affiliatedtoaccountid");
    const accountRelationShipFrom = await this.disk.retrieve("account_linked_entityindskr_account_accountaccountaffiliation_affiliatedfromaccountid");
    const brandAffiliation = await this.disk.retrieve("account_linked_entityindskr_accountbrandaffiliation");
    const accountContact = await this.disk.retrieve("account_linked_entityindskr_accountcontactaffiliation");
    const linkEntityAccountTo = accountRelationShipTo ? accountRelationShipTo.raw.filter(x =>
      x["indskr_accountaccountaffiliation.indskr_affiliatedtoaccountid"] == accountId) : [];
    const linkEntityAccountFrom = accountRelationShipFrom ? accountRelationShipFrom.raw.filter(x =>
      x["indskr_accountaccountaffiliation.indskr_affiliatedfromaccountid"] == accountId) : [];
    const brandAffiliationByAccountId = brandAffiliation ? brandAffiliation.raw.filter(x =>
      x["accountid"] == accountId) : [];
    const linkedAccountContact = accountContact ? accountContact.raw.filter((x => x["accountid"] == accountId && x["indskr_accountcontactaffiliation.statecode"] === 0)) : [];
    if (!isFormAffiliationExplorer) {
      this.linkEntityAccountTo = linkEntityAccountTo;
      this.linkEntityAccountFrom = linkEntityAccountFrom;
      this.brandAffiliationByAccountId = brandAffiliationByAccountId;
      this.linkedAccountContact = linkedAccountContact;
    }
    return { linkEntityAccountTo, linkEntityAccountFrom, brandAffiliationByAccountId, linkedAccountContact }
  }

  async affiliationControl() {
    let accountForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("account", FormType.DISPLAYFORM);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if (!accountForm) {
      accountForm = new DynamicForm(DefaultAccountDisplayForm['value'][0]);
      formType = DynamicFormType.DEFAULTFORM;
    }
    const affiliationsRefEntityNames = [
      ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY,
      CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY,
      ACCOUNT_CONTACT_AFFILIATIONS_REF_ENTITY
    ];
    for (let res of accountForm.metadata) {
      for (let control of res.controls) {
        this.isAffiliationEnabled = affiliationsRefEntityNames.includes(control.subgrid?.referencingEntity);
        if (this.isAffiliationEnabled) {
          break;
        }
      }
      if (this.isAffiliationEnabled) {
        break;
      }
    }
  }

  public mapMyAccountPlansToSearchIndex(newAccountPlans) {
    newAccountPlans.forEach(newAP => {
      if (!_.isEmpty(newAP.products)) {
        newAP.products.forEach(product => {
          if (!this.searchConfigService.myGoalsPlansSearchIndexesConfig.find(config => config.categoryName == 'Products').values.some(o => o == product.productName))
            this.searchConfigService.myGoalsPlansSearchIndexesConfig.find(config => config.categoryName == 'Products').values.push(product.productName);
        })
      }
    })
  }
  public setErrorMessageOpenAccount() {
    let toolNameStr: string = this.translate.instant('ACCOUNT');
    let pocNameStr: string = '';
    switch (this.utilityService.globalCustomerText) {
      case 'Stakeholder':
        pocNameStr = this.translate.instant("SUPPORT");
        break;
      case 'Contact':
      case 'Customer':
        pocNameStr = this.translate.instant("ADMINISTRATOR");
        break;
      default:
        pocNameStr = this.translate.instant("ADMINISTRATOR");
        break;
    }
    this.errorMessageOpenAccountDetails = this.translate.instant('YOU_DO_NOT_HAVE_ACCESS_TO_WITH_TEXT', {text: toolNameStr.toLowerCase(), pocName: pocNameStr.toLowerCase()});
  }
  public validateAddAccountToUserPosition(accountId: string): boolean {
    const foundAccount = this.getAccountById(accountId) || '';
    return _.isEmpty(foundAccount);
  }

  public async getPrimaryAddressOfAccount(accountId) {
    let rawData = await this.disk.retrieve('account_linked_entityindskr_indskr_customeraddress_v2');
    if (rawData && rawData.raw && Array.isArray(rawData.raw) && rawData.raw.length != 0) {
      for (let item of rawData.raw) {
        if (item.hasOwnProperty('accountid') && accountId == item['accountid'] && item['indskr_indskr_customeraddress_v2.indskr_isprimary'] == true) {
          let primaryAddress = {
            addressId: item['indskr_indskr_customeraddress_v2.indskr_address'] || '',
            compositeAdd: item['indskr_address.indskr_composite'] || '',
            customerAddressID: item['indskr_indskr_customeraddress_v2.indskr_indskr_customeraddress_v2id'] || '',
            concatanatedAddress: item['indskr_indskr_customeraddress_v2.indskr_address_Formatted'] || '',
            latitude: item['indskr_address.indskr_latitude'] || '',
            longitude: item['indskr_address.indskr_longitude'] || '',
          };
          return primaryAddress;
        }
      }
    }
  }

  public async addLocationToMeeting(currentMeeting, selectedAddress){
    if(selectedAddress){
      currentMeeting.location = selectedAddress.compositeAdd;
      currentMeeting.indskr_meetinglocationlongitude = null;
      currentMeeting.indskr_meetinglocationlatitude = null;
      if (selectedAddress.longitude && selectedAddress.latitude) {
        currentMeeting.indskr_meetinglocationlongitude = selectedAddress.longitude;
        currentMeeting.indskr_meetinglocationlatitude = selectedAddress.latitude;
      }
    }
  }

  public async saveQuickGlanceOptions(options){
    if(options)
    await this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT_QUICK_GLANCE_OPTIONS, (doc) => {
      doc = {
        raw: options,
        lastModified: new Date().getTime().toString()
      };
      return doc;
    });
  }

  public async getQuickGlanceOptions(){
    let options = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_QUICK_GLANCE_OPTIONS);
    if(options && options.raw && Array.isArray(options.raw)){
      return options.raw;
    }else{
      return [];
    }
  }

  public async _getQuickGlanceValues():Promise<any[]>{
    let values:Array<any> = [];
    let alternateValues:Array<any> = [];
    let options:Array<string> = await this.getQuickGlanceOptions();
    let accountForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("account", FormType.DISPLAYFORM);
    const langCode = (!accountForm) ? DEFAULT_FORM_LANGUAGE_CODE : this.localizationService.selectedLanguage.localeID;
    if (!accountForm) {
      accountForm = new DynamicForm(DefaultAccountDisplayForm['value'][0]);
    }
    accountForm.metadata.forEach((tab) => {
      if (tab && tab.controls && tab.controls.length > 0) {
        tab.controls.forEach(control => {
          if (this.dynamicFormsService.checkIfControlShouldNotBeVisible(control) || control.forceHide || !control.dataType || control.dataType == ControlDataType.StateType || control.dataType == ControlDataType.StatusType || !control.isVisible || EXCLUDED_ACCOUNT_GLANCE_CARD_ATTRIBUTES.some(a=> a == control.attributeName)) {
            //
          }else{
            if(options && options.length){
              if(options.some(a=> a == control.attributeName)){
                let value = this.dynamicFormsService.getInputTextForFormField(control,this.selected.raw);
                let label = this.dynamicFormsService.getDisplayText(control.displayNames,langCode);
                if(value){
                  values.push({value: value, label: label});
                  if(values.length == 4){
                    return values;
                  }
                }

              }else {
                let value = this.dynamicFormsService.getInputTextForFormField(control,this.selected.raw);
                let label = this.dynamicFormsService.getDisplayText(control.displayNames,langCode);
                if(value){
                  alternateValues.push({value: value, label: label});
                }
              }
            }else {
              let value = this.dynamicFormsService.getInputTextForFormField(control,this.selected.raw);
              let label = this.dynamicFormsService.getDisplayText(control.displayNames,langCode);
              if(value){
                values.push({value: value, label: label});
                if(values.length == 4){
                  return values;
                }
              }
            }
          }
        });
      }
    });
    if(values.length < 4){
      values.push(...alternateValues.slice(0, (4-values.length)));
    }
    return values;
  }
  
  public getAllAccountIds(){
    return this.accounts.map((account) => account.id);
  }

  public async findPrimaryAccountOfContact(contactId) {
    let primaryAccount;
    let rawData = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY + OFFLINE_DB_LINKED_ENTITY_NAME.ACCOUNT_CONTACT_AFFILIATION);
    if (rawData && rawData.raw && Array.isArray(rawData.raw) && rawData.raw.length != 0) {
      for (let account of rawData.raw) {
        if (account.hasOwnProperty('indskr_accountcontactaffiliation.indskr_contactid') &&
          account.hasOwnProperty('indskr_accountcontactaffiliation.indskr_accountid') &&
          account.hasOwnProperty('indskr_accountcontactaffiliation.statecode') &&
          account['indskr_accountcontactaffiliation.statecode'] == 0 &&
          contactId == account['indskr_accountcontactaffiliation.indskr_contactid'] &&
          account['indskr_accountcontactaffiliation.indskr_isprimaryaccount'] == true) {
          primaryAccount = account;
          break;
        }
      }
    }
    return primaryAccount;
  }
}

export enum AccountSelectedFor {
    CALLPLAN_FILTER,
    ADD_TO_MEETING,
    SCHEDULER_SELECTOR,
    CASE_INTAKE_SELECTOR,
    FOLLOW_UP_ACTION_SELECTION,
    ORDER_ACTIVITY_SELECTION,
    CONTACT_ACCOUNT_SELECTION,
    OPPORTUNITY_SELECTION,
    GENERAL_SINGLE_SELECTION,
    GENERAL_MULTIPLE_SELECTION,
    PHONE_CALL_SELECTION,
    MARKET_SCAN,
    ASSET_LOCATION_SELECTION,
    ACCOUNT_VISIT,
    ADD_TO_MESSAGE,
}

export interface handleSameCategorySearchReturnValue {
  linkEntity: Partial<LinkedEntityXMLTag>,
  filter: FilterXMLTag,
  filterCondition: FilterConditionXMLTag,
  hasCustomPositionSearch: boolean
}
