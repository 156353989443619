import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SENTIMENT } from "@omni/data-services/websocket/payloads/sentiment.payload";
import { SentimentChipInterface } from "../sentiment-chip/sentiment-chip";
import { TranslateService } from "@ngx-translate/core";

export interface KeyMessageSentimentCardInterface {
  id: string,
  keyMessage: string,
  sentiment: SENTIMENT,
}

@Component({
  selector: 'key-message-sentiment-card',
  templateUrl: 'key-message-sentiment-card.html',
  styleUrls: ['key-message-sentiment-card.scss'],
})
export class KeyMessageSentimentCardComponent {
  private NEGATIVE_ICON_SRC = 'assets/imgs/sentiment-negative.svg';
  private NEUTRAL_ICON_SRC = 'assets/imgs/sentiment-neutral.svg';
  private POSITIVE_ICON_SRC = 'assets/imgs/sentiment-positive.svg';

  // @Input() data: KeyMessageSentimentCardData;
  @Input() id: string;
  @Input() keyMessage: string;
  @Input() sentiment: SENTIMENT = null;
  @Output() onSentimentChange: EventEmitter<{ id: string, sentiment: SENTIMENT }> = new EventEmitter();

  sentiments: SentimentChipInterface[] = [
    {
      sentiment: SENTIMENT.NEGATIVE,
      iconSrc: this.NEGATIVE_ICON_SRC,
      label: this.translate.instant('NEGATIVE'),
      selected: false,
    },
    {
      sentiment: SENTIMENT.NEUTRAL,
      iconSrc: this.NEUTRAL_ICON_SRC,
      label: this.translate.instant('NEUTRAL'),
      selected: false,
    },
    {
      sentiment: SENTIMENT.POSITIVE,
      iconSrc: this.POSITIVE_ICON_SRC,
      label: this.translate.instant('POSITIVE'),
      selected: false,
    },
  ];
  constructor(
    private translate: TranslateService,
  ) {}

  onSentimentClick(sentiment: SENTIMENT) {
    const newSentiment = this.sentiment !== sentiment ? sentiment : null;
    this.sentiment = newSentiment;
    this.updateSentimentSelected(newSentiment);
    this.onSentimentChange.emit({
      id: this.id,
      sentiment: newSentiment,
    });
  }

  private updateSentimentSelected(newSentiment: SENTIMENT) {
    this.sentiments.forEach(sentimentChipData => {
      sentimentChipData.selected = sentimentChipData.sentiment === newSentiment;
    });
  }
}
