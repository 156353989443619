import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit, Renderer2, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { SelectListData } from "@omni/models/select-list-data-model";

@Component({
  selector: 'io-selection-sheet',
  templateUrl: './io-selection-sheet.html',
  styleUrls: ['./io-selection-sheet.scss'],
})
export class IoSelectionSheetComponent implements OnInit, AfterViewInit {
  @Input()
  public fullHeightPaddingTop = 24;
  @Input()
  public headerFooterHeightCombined = 44 + 64;
  @Input()
  public emptpyContentMinHeight = 77;
  @Input()
  public maxHeightPercent: number;
  @Input()
  public primaryHeader: string;
  @Input()
  public secondaryHeader: string;
  @Input()
  public listData: SelectListData[] = [];
  @Input()
  public allowEmptySelection: boolean = false;

  @HostBinding('class.ion-page') pageClass = false;
  @ViewChild('content', { read: ElementRef }) content;
  @ViewChild('contentWrapper', { read: ElementRef }) contentWrapper;

  private initSelectedId: string;
  public selectedData: SelectListData;
  public selectedValue: string;
  public isDirty = false;

  constructor(
    private modalCtrl: ModalController,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.selectedData = this.listData.find(d => d.isSelected);
    this.selectedValue = this.selectedData?.id ?? null;
    this.initSelectedId = this.selectedData?.id ?? null;
  }

  ngAfterViewInit(): void {
      setTimeout(async () => {
        // Check for the modal margin & padding
      const modalEl = await this.modalCtrl.getTop();
      const isDynamicModal = modalEl && modalEl.classList.contains('dynamic-height-modal');
      const modalWrapperEl = isDynamicModal ? modalEl.querySelector('.modal-wrapper') : null;
      const parsedParentMarginTop = modalWrapperEl ? parseFloat(window.getComputedStyle(modalWrapperEl, null)?.getPropertyValue('margin-top')) : 0;
      const parentMarginTop = !isNaN(parsedParentMarginTop) ? parsedParentMarginTop : 0;
      const parsedParentPaddingBottom = modalWrapperEl ? parseFloat(window.getComputedStyle(modalWrapperEl, null)?.getPropertyValue('padding-bottom')) : 0;
      const parentPaddingBottom = !isNaN(parsedParentPaddingBottom) ? parsedParentPaddingBottom : 0;
      const maxModalHeight = this.maxHeightPercent ? window.innerHeight * (this.maxHeightPercent / 100) : window.innerHeight - this.fullHeightPaddingTop;

      const maxContentHeight = maxModalHeight - this.headerFooterHeightCombined - parentMarginTop - parentPaddingBottom;
      const contentHeight = Math.min(maxContentHeight, Math.max(this.emptpyContentMinHeight, this.contentWrapper.nativeElement.offsetHeight));
      this.setContentHeight(contentHeight);
      }, 40);
  }

  onCheckboxClick(ev) {
    const newValue = ev.detail.value ?? null;
    if (this.selectedValue) {
      const prevSelected = this.listData.find(d => d.id === this.selectedValue);
      if (prevSelected) {
        prevSelected.isSelected = false;
        this.selectedData = null;
      }
    }
    this.selectedValue = newValue;
    const newSelected = this.listData.find(d => d.id === newValue);
    if (newSelected) {
      newSelected.isSelected = true;
      this.selectedData = newSelected;
    }

    this.dirtyCheck();
  }

  closeModal(isSelectClicked: boolean) {
    this.modalCtrl.dismiss({ isSelectClicked, data: isSelectClicked ? this.selectedData : null });
  }

  private dirtyCheck() {
    // this.isDirty = xor(this.selectedData.map(d => d.id), this.initSelectedIds).length !== 0;
    this.isDirty = this.initSelectedId !== this.selectedValue;
  }

  private setContentHeight(height: number) {
    if (height && this.content?.nativeElement) {
      this.renderer.setStyle(
        this.content.nativeElement,
        'height',
        `${height}px`,
      );
    }
  }
}
