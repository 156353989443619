import { Component, Input } from '@angular/core';
import { ActivityService } from '../../../services/activity/activity.service';
import { AccountOfflineService } from '../../../services/account/account.offline.service';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { TranslateService } from '@ngx-translate/core';
import { PopoverController } from '@ionic/angular';
import { TimeLineItem } from '@omni/components/timeline-item/timeline-item.component';
import { AuthenticationService } from '@omni/services/authentication.service';
import { AccountDataService } from '@omni/data-services/accounts/account.data.service';
import { DeviceService } from '@omni/services/device/device.service';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { AccesingMode, CaseManagementService } from '@omni/services/case-management/case-management.service';
import { CaseManagementDataService } from '@omni/data-services/case-management/case-management.data.service';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { EventsService } from '@omni/services/events/events.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { DatePipe } from '@angular/common';
import { EventsToolDataService } from '@omni/data-services/event/events-tool.data.service';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { ActivityType } from '@omni/classes/activity/activity.class';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { CaseActivity } from '@omni/classes/case-intake/case-activity.class';
import { CaseManagementDetailComponent } from '@omni/components/case-management/case-management-detail/case-management-detail';
import { OrderDetailsComponent } from '@omni/components/order-management/order-details/order-details';
import { ProcedureTrackerDetailsComponent } from '@omni/components/surgery-order/procedure-tracker-details/procedure-tracker-details';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { ActivitiesDetailsPaneComponent } from '@omni/components/activity/activities-details-pane/activities-details-pane';
import { PhoneActivity } from '@omni/classes/activity/phone.activity.class';
import { PhoneCallDetailsComponent } from '@omni/components/phonecall/phone-call-details/phone-call-details';
import { SurgeryOrderDetailsComponent } from '@omni/components/surgery-order/surgery-order-details/surgery-order-details';
import { EventActivity } from '@omni/classes/events-tool/event.class';
import { EventDetailsComponent } from '@omni/components/events-tool/event-details/event-details';
import _ from 'lodash';
import { OrderActivity } from '@omni/classes/activity/order.activity.class';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { DiskService } from '@omni/services/disk/disk.service';
import { Activity } from 'botframework-directlinejs';
import { ProcedureTrackerActivity } from '@omni/classes/activity/procedure-tracker.activity.class';
import { AccountTimelinePageComponent } from '../account-timeline-page/account-timeline-page.component';
import { Customer360UIService } from '@omni/services/ui/customer360ui.service';
import { distanceInWordsToNow, format, isPast } from 'date-fns';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FollowUpActivity } from '@omni/classes/activity/follow-up-action.activity.class';
import { PresentationService } from '@omni/services/presentation/presentation.service';
import { ResourceService } from '@omni/services/resource/resource.service';
import { Presentation } from '@omni/classes/presentation/presentation.class';
import { Resource } from '@omni/classes/resource/resource.class';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { AssessmentTemplate, SurveyCategory, SurveyFrequency, SurveyStatus, TemplateType } from '@omni/classes/customer-assessment/assessment-template.class';
import { CustomerSurveyService } from '@omni/services/customer-survey/customer-survey.service';
import { CustomerAssessment } from '@omni/classes/customer-assessment/customer-assessment.class';
import { SurveyDetailsComponent } from '@omni/components/survey/survey-details/survey-details';

/**
 * Generated class for the AccountFeedComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'account-feed',
  templateUrl: 'account-feed.html',
  styleUrls: ['account-feed.scss']
})
export class AccountFeedComponent {
  timelineSectionHeader: IndSectionHeaderViewDataModel;
  timelineItems: TimeLineItem[] = [];
  latestActivitiesByAccount: any = [];
  isMeetingHideSubTypeFAEnabled = false;

  private ngDestroy$ = new Subject<boolean>();
  @Input() readOnly: boolean;

  constructor(
    private disk: DiskService,
    public datepipe: DatePipe,
    public uiService: UIService,
    public device: DeviceService,
    private events: EventsService,
    public translate: TranslateService,
    public footerService: FooterService,
    private navService: NavigationService,
    public activityService: ActivityService,
    public caseService: CaseManagementService,
    private c360Service : Customer360UIService,
    public utilityService: GlobalUtilityService,
    public popoverController: PopoverController,
    public eventsToolService: EventsToolService,
    public accountService: AccountOfflineService,
    public accountDataService: AccountDataService,
    public activityDataService: ActivityDataService,
    private notifiationService: NotificationService,
    private dynamicFormService: DynamicFormsService,
    public caseDataService: CaseManagementDataService,
    private readonly authService: AuthenticationService,
    public authenticationService: AuthenticationService,
    public accountOfflineService: AccountOfflineService,
    public eventsToolDataService: EventsToolDataService,
    public readonly dateTimeFormatsService: DateTimeFormatsService,
    private readonly presentationService: PresentationService,
    private readonly resourceService: ResourceService,
    private customerAssessService: CustomerAssessService,
    private customerSurveyService: CustomerSurveyService
  ) {
    this.isMeetingHideSubTypeFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_HIDE_SUBTYPE);
  }

  ngOnInit() {
    this.getTimeLineData();
    this._initSectionHeadersView();
    this.navService.childNavPopObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(page=> {
      if(page && (page == PageName.CaseManagementDetailComponent || page == PageName.OrderDetailsComponent || page == PageName.ProcedureTrackerDetailsComponent || page == PageName.ActivitiesDetailsPaneComponent || page == PageName.PhoneCallDetailsComponent || page == PageName.SurgeryOrderDetailsComponent || page == PageName.EventDetailsPageComponent || page == PageName.AccountTimelinePageComponent || page == PageName.SurveyDetailsComponent)){
        this.getTimeLineData();
      }
    })
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  setTimelineList() {
    this.timelineItems = [];
    if (!this.latestActivitiesByAccount.length) return;
    this.latestActivitiesByAccount.forEach(async item => {
      let view;
      // check if scheduleStart time is not there assign start date : temporary solution jmy
      item.scheduledStart = isNaN(item.scheduledStart)?item._startDate:item.scheduledStart;
      item.scheduledEnd = isNaN(item.scheduledEnd)?item.endDate:item.scheduledEnd;
      if(item instanceof AppointmentActivity){
        view = await this._getTimelineItemViewForAppointmentActivity(item)
      }else{
        view = await this.c360Service.getViewForJourneyItem(true, item);
      }
      if (view) {
        if(this.readOnly) view.disableEndArrow = true;
        this.timelineItems.push(view);
      }
    });
  }

  private _initSectionHeadersView() {
    this.timelineSectionHeader = {
      id: 'account-timeline-section',
      title: this.translate.instant('TIMELINE'),
      controls: [{
        id: 'opentimelinedetails',
        isDisabled: this.readOnly,
        iconName: "chevron-forward-outline",
        //icon: 'assets/imgs/arrow-right-grey.svg',
      }]
    }
  }

  async getTimeLineData() {
    this.customerAssessService.accountSurveysForTimeline = [];
    let surveyData = await this.customerAssessService.loadSurveysByAccountIdForTimeline(this.accountService.selected.id)
    if(surveyData) this.latestActivitiesByAccount = await this.combineEventsAndActivities();
    this.setTimelineList();
  }

  onTimelineTypeChange() {
    this.getTimeLineData()
  }

  openActivityDetails = async (ev) => {
    if(this.readOnly) return;
    let item: any = this.timelineItems.find(a => ev && a.id == ev.value);
    if (item) {
      let selActivity = item.refObject;
      //prevent navigation issue
      if ((this.accountOfflineService.accessedAccountListFrom == PageName.ContactDetailsComponent && this.accountOfflineService.accountPageMode === ComponentViewMode.READONLY)
        || this.accountOfflineService.accountPageMode === ComponentViewMode.PREVIEW
        || this.dynamicFormService.isOpenedAffiliatedContactOnAccount
        || this.dynamicFormService.isOpenedAffiliatedAccountOnAccount
        || this.caseService.accessedFrom == AccesingMode.INMEETING) {
        console.log("The current activity cannot be opened to avoid possible problems with navigation loop");
        return;
      }
      if (this.device.isOffline) {
        this.notifiationService.notify(this.translate.instant('ACCOUNT_TIMELINE_NO_INTERNET'), 'Account Timeline', 'top', ToastStyle.DANGER);
        return;
      }

      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'ActivitySkeleton';
      setTimeout(() => {
        this.displayActivityDetails(selActivity as Activity);
      }, 0);
    }
  }

  async displayActivityDetails(selActivity) {
    if (selActivity instanceof CaseActivity) {
      let iCase: CaseActivity = selActivity as CaseActivity;
      if(!this.device.isOffline && !selActivity.ID.includes('offline')){
        this.uiService.displayLoader();
        let res = await this.caseDataService.findCaseById(selActivity.ID);
        if(res){
          for (let key in res) {
            if (key.charAt(0) === "_") {
              var a = key.substring(1, key.length);
              res[a] = res[key];
              delete res[key];
            }
          }
          iCase = new CaseActivity(res);
        }
      }
      if(!_.isEmpty(iCase)) {
        iCase = await this.caseService.reInitDataDependency(iCase);
        this.activityService.selectedActivity = iCase;
        this.caseService.accessedFrom = AccesingMode.ACCOUNT_TIMELINE;
        this.navService.pushChildNavPageWithPageTracking(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent, PageName.AccountPageComponent, { from: 'AccountTimeline' });
        this.caseService.assignSelectedCase(iCase);
        this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
      } else {
        this.notifiationService.notify(this.translate.instant('OM_WAS_A_PROBLEM_CONTACT_ADMINISTRATOR'), 'Account Feed', 'top', ToastStyle.DANGER);
      }
      this.uiService.dismissLoader();
    } else if (selActivity instanceof OrderActivity) {
      let foundActivity = this.activityService.getActivityByID(selActivity.ID);
      if (foundActivity) {
        this.activityService.selectedActivity = foundActivity;
      } else {
        if(!this.device.isOffline && !selActivity.ID.includes('offline')){
          this.uiService.displayLoader();
          foundActivity = await this.activityDataService.getRealTimeActivityDetails(selActivity);
          this.uiService.dismissLoader();
        }else{
          foundActivity = selActivity  as OrderActivity
        }
      }
      this.activityService.selectedActivity = (foundActivity) ? foundActivity : selActivity;
      this.navService.pushChildNavPageWithPageTracking(OrderDetailsComponent, PageName.OrderDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTimeline', activity: this.activityService.selectedActivity });
    } else if (selActivity instanceof ProcedureTrackerActivity) {
      let foundActivity = this.activityService.getActivityByID(selActivity.ID);
      if (foundActivity) {
        this.activityService.selectedActivity = foundActivity;
      } else {
        if(!this.device.isOffline && !selActivity.ID.includes('offline')){
          this.uiService.displayLoader();
          foundActivity = await this.activityDataService.getRealTimeActivityDetails(selActivity);
          this.uiService.dismissLoader();
        }else{
          foundActivity = selActivity  as ProcedureTrackerActivity
        }
      }
      if (!foundActivity) return;
      this.navService.pushChildNavPageWithPageTracking(ProcedureTrackerDetailsComponent, PageName.ProcedureTrackerDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTimeline', activity: this.activityService.selectedActivity });
    } else if (selActivity instanceof AppointmentActivity) {

      let activity = this.activityService.getActivityByID(selActivity.ID);
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;

      if (activity) {
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selected = activity;
      }
      this.uiService.displayLoader();
      // activity = await this.activityDataService.getRealTimeActivityDetails(selActivity);
      let tempActivity: AppointmentActivity = new AppointmentActivity({
        activityid: selActivity.ID,
        activitytypecode: "appointment",
        indskr_ownerfullname: selActivity.meetingOwner,
        indskr_ownerid: selActivity.ownerId,
        location: selActivity.ownerId,
        scheduledend: selActivity.scheduledEnd.getTime().toString(),
        scheduledstart: selActivity.scheduledStart.getTime().toString(),
        statuscode: selActivity.status,
        statecode: selActivity.state,
        subject: selActivity.subject,
      });
      await this.activityDataService.updateActivityDetails(activity ? activity : tempActivity);
      this.accountOfflineService.tempSelected = _.cloneDeep(this.accountOfflineService.selected);
      this.uiService.dismissLoader();
      this.activityService.selected = (activity) ? activity : tempActivity;
      this.activityService.selectedActivity.accessedFrom = PageName.AccountTimelineComponent;
      this.uiService.showNewActivity = false;
      this.activityDataService.activityDetailsLoaded = true;
      this.uiService.showNewActivity = false;
      this.activityDataService.activityDetailsLoaded = true;
      await this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.AccountPageComponent, { from: 'AccountTimeline' });
      //this.activityDataService.updateActivityDetails(activity);
      this.events.publish("detectChangesOnActivityDetails");
      if (this.activityService.selectedActivity) {
        switch (selActivity.type) {
          case ActivityType.Appointment:
            this.footerService.initButtons(FooterViews.Activities);
            break;
          default:
            this.footerService.initButtons('');
        }
      }
    } else if (selActivity instanceof PhoneActivity) {
      let activity = this.activityService.getActivityByID(selActivity.ID);
      if (activity) {
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selected = activity;
      }else{
        activity = selActivity as PhoneActivity;
      }
      if(!this.device.isOffline && !selActivity.ID.includes('offline')){
        await this.uiService.displayLoader();
        //activity = await this.activityDataService.getRealTimeActivityDetails(selActivity);
        // await this.activityDataService.updateActivityDetails(activity);
        await this.activityDataService.getPhonecallActivityByIdOnline(selActivity.ID, selActivity.scheduledStart.getTime().toString(), selActivity.scheduledEnd.getTime().toString(),selActivity.scheduledStart.getTime().toString()).then(async (response) => {
          await this.activityDataService.updateActivityForConfigField(activity, response, ActivityType.PhoneCall);
          await this.activityDataService._appendPhoneCallDetailsToActivity(activity, response, false);
          this.accountOfflineService.tempSelected = _.cloneDeep(this.accountOfflineService.selected);
          this.activityDataService.activityDetailsLoaded = true;
        })
        await this.uiService.dismissLoader();
      }
      this.activityService.selected = activity;
      this.activityService.selectedActivity.accessedFrom = PageName.AccountTimelineComponent;
      this.uiService.showNewActivity = false;
      this.activityDataService.activityDetailsLoaded = true;
      await this.navService.pushChildNavPageWithPageTracking(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTimeline' });
      this.events.publish("detectChangesOnPhoneCallActivityDetails");
      this.footerService.initButtons(FooterViews.PHONE_CALL);
    } else if (selActivity instanceof SurgeryOrderActivity) {
      let foundActivity = this.activityService.getActivityByID(selActivity.ID);
      if (foundActivity) {
        this.activityService.selectedActivity = foundActivity;
      } else {
        if(!this.device.isOffline && !selActivity.ID.includes('offline')){
          this.uiService.displayLoader();
          foundActivity = await this.activityDataService.getRealTimeActivityDetails(selActivity);
          this.uiService.dismissLoader();
        }else{
          foundActivity = selActivity as SurgeryOrderActivity
        } 
      }
      this.activityService.selectedActivity = (foundActivity) ? foundActivity : selActivity;
      this.navService.pushChildNavPageWithPageTracking(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTimeline', activity: this.activityService.selectedActivity });
      this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
    } else if (selActivity instanceof EventActivity) {
      const eventsToolData = this.eventsToolService.eventsToolData;
      const foundEvent = eventsToolData.find(e => {
        if (e.ID) {
          return e.ID === selActivity.ID;
        } else if (e.offlineId) {
          return e.offlineId === selActivity.ID;
        } else {
          return false;
        }
      });
      if (!_.isEmpty(foundEvent)) {
        selActivity = foundEvent;
        this.eventsToolService.selectedEventOnEventsTool = foundEvent;
      } else {
        return;
      }
      if (!this.device.isOffline && !foundEvent.ID.includes('offline')) {
        this.uiService.displayLoader();
        await this.eventsToolDataService.fetchEventRealTimeDetails(selActivity as EventActivity).then((updatedEvent: EventActivity) => {
          selActivity = updatedEvent;
          this.uiService.dismissLoader();
        }).catch(err => {
          console.log(err);
          this.uiService.dismissLoader();
        });
      }
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
      this.activityService.selectedActivity = selActivity;
      this.navService.pushChildNavPageWithPageTracking(EventDetailsComponent, PageName.EventDetailsPageComponent, PageName.AccountPageComponent, {
        from: 'AccountTimeline', event: this.activityService.selectedActivity,
      });
      this.eventsToolService.setSelectedEventUpdates(this.activityService.selectedActivity as EventActivity);
      this.footerService.initButtons(FooterViews.EVENTS_DETAILS);
    } else if (selActivity.type == ActivityType.CustomerSurvey) {
      if(this.device.isOffline) {
        this.notifiationService.notify(this.translate.instant("SURVEY_ACTIVITIES_NOTIFY_ONLINE"), "survey-activities", "top", ToastStyle.DANGER, 3000, true);
        return;
      }
      if(!_.isEmpty(selActivity)) {
        this.openSubmittedSurveyDetails(selActivity);
      }
    }
  }

  private async combineEventsAndActivities() {
    let temp: any = _.cloneDeep(this.activityService.activities);

    // Events
    if (this.eventsToolService.eventsToolData && this.eventsToolService.eventsToolData.length > 0) {
      temp.push(...this.eventsToolService.eventsToolData);
    }

    // Account Survey
    let surveyItems = [];
    let surveysByAccountId: any = this.customerAssessService.accountSurveysForTimeline.filter(survey => survey.surveyStatus != SurveyStatus.SAVED) || [];    
    if(!_.isEmpty(surveysByAccountId)) {
      let groupedResponsesByDate: any = [];
      groupedResponsesByDate = _.groupBy(surveysByAccountId, 'indskr_assessmentdate');
      groupedResponsesByDate = _.values(groupedResponsesByDate);

      groupedResponsesByDate.forEach((resp) => {
        let activity: any = {};

        const templateId: string = resp[0]['_indskr_template_value'];
        const foundTemplate: AssessmentTemplate = this.customerAssessService.surveytemplates.find(survey=> survey.indskr_assessmenttemplateid == templateId);

        let surveyFrequency: string = foundTemplate['indskr_surveyfrequency'] ?  foundTemplate['indskr_surveyfrequency'] == SurveyFrequency.ONCE ? this.translate.instant("ONE_TIME") : this.translate.instant("RECURRING") : '';
        activity['type'] = ActivityType.CustomerSurvey;
        activity['subject'] = resp[0]['indskr_name'] || '';
        activity['assessmentId'] = resp[0]['indskr_customerassessmentid'];
        activity['scheduledStart'] = new Date(resp[0]['indskr_assessmentdate']);
        activity['surveyFrequency'] = surveyFrequency;
        activity['surveyedBy'] = resp[0]['_modifiedby_value_Formatted'] || '';
        activity['templateId'] = templateId;
        // activity['rawData'] = resp;
        surveyItems.push(activity);
      });
    }
    if(!_.isEmpty(surveyItems)) temp.push(...surveyItems);

    temp.sort((activity1, activity2) => {
      if (activity1 instanceof EventActivity && !(activity2 instanceof EventActivity)) {
        if (activity1.startDate < activity2.scheduledStart) return 1;
        if (activity1.startDate > activity2.scheduledStart) return -1;
        return 0;
      } else if (activity2 instanceof EventActivity && !(activity1 instanceof EventActivity)) {
        if (activity1.scheduledStart < activity2.startDate) return 1;
        if (activity1.scheduledStart > activity2.startDate) return -1;
        return 0;
      } else if (activity1 instanceof EventActivity && activity2 instanceof EventActivity) {
        if (activity1.startDate < activity2.startDate) return 1;
        if (activity1.startDate > activity2.startDate) return -1;
        return 0;
      } else {
        if (activity1.scheduledStart < activity2.scheduledStart) return 1;
        if (activity1.scheduledStart > activity2.scheduledStart) return -1;
        return 0;
      }
    });


    let dataToDisplay = [];
    for (let i = 0; i < temp.length; i++) {
      let flag = false;
      let activity = temp[i];
      if (activity instanceof AppointmentActivity) {
        flag = activity._raw_activityAccounts && activity._raw_activityAccounts.some((x) => x.indskr_accountid === this.accountService.selected.id);
        if(!flag){
          flag = activity.accounts && activity.accounts.some((x) => x.id === this.accountService.selected.id) && activity.status != 4;
        }
        if (flag) {
          const foundActivity = await this.getAppointmentFromDb(activity);
          if(foundActivity){
            activity = foundActivity
          }
        }
        // } else if (activity instanceof EmailActivity) {
        //   let act:any = activity;
        //   flag = act.accounts && act.accounts.some((x) => x.accountId === this.accountService.selected.id);
      } else if (activity instanceof PhoneActivity) {
        flag = activity._raw_activityAccounts && activity._raw_activityAccounts.some((x) => x.indskr_accountid === this.accountService.selected.id);
        if(!flag){
          flag = activity.accounts && activity.accounts.some((x) => x.id === this.accountService.selected.id);
        }
        if (flag) {
          const foundActivity = await this.getPhoneCallFromDb(activity);
          if(foundActivity){
            activity = foundActivity;
          }
        }
      } else if (activity instanceof OrderActivity) {
        flag = activity.accountId && activity.accountId === this.accountService.selected.id
      } else if (activity instanceof SurgeryOrderActivity) {
        flag = activity.accountId && activity.accountId === this.accountService.selected.id && activity.status != 548910005;
        // } else if (activity instanceof FollowUpActivity) {
        //   flag = activity.accountId && activity.accountId === this.accountService.selected.id;
      } else if (activity instanceof ProcedureTrackerActivity) {
        flag = activity.accountId && activity.accountId === this.accountService.selected.id;
      } else if (activity instanceof EventActivity) {
        flag = activity.accounts && activity.accounts.length > 0 && activity.accounts.some((x) => x.id === this.accountService.selected.id);
      } else if (activity instanceof CaseActivity) {
        flag = activity._case_account_id && activity._case_account_id == this.accountService.selected.id;
      } else if (activity['type'] == ActivityType.CustomerSurvey) {
        flag = true;
      }
      if (flag) dataToDisplay.push(activity);
      if (dataToDisplay.length === 3) break;
    }
    return dataToDisplay;
  }

  async getAppointmentFromDb(activity: AppointmentActivity) {
    let dbPrefix = DB_KEY_PREFIXES.MEETING_ACTIVITY;
    let dbKey = '';
    dbKey = this.activityService.hasOfflineMeetingData(activity.ID) || activity.ID.includes('offline_') ? dbKey = 'offline' : dbKey = dbPrefix + activity.ID;
    let activityDoc = await this.disk.retrieve(dbKey, true);
    if (activityDoc) {
      const replacingActivity = new AppointmentActivity(activityDoc);
      this.activityService.copyRuntimeActivityVariables(activity, replacingActivity);
      activity = replacingActivity;
      await this.activityDataService._appendMeetingDetailsToActivity(activity, activityDoc);
      return activity;
    }
  }

  async getPhoneCallFromDb(activity) {
    let dbPrefix = DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY;
    let dbKey = '';
    dbKey = this.activityService.hasOfflinePhoneCallData(activity.ID) || activity.ID.includes('offline_') ? dbKey = 'offline' : dbKey = dbPrefix + activity.ID;

    let activityDoc = await this.disk.retrieve(dbKey, true);
    activity = new PhoneActivity(activityDoc);
    await this.activityDataService._appendPhoneCallDetailsToActivity(activity, activityDoc);
    return activity
    
  }

  private async _getTimelineItemViewForAppointmentActivity(item: AppointmentActivity): Promise<TimeLineItem> {
    let view: TimeLineItem;
    let firstdetailIconType, firstdetailObjectId;
    if (item.presentations && item.presentations.length > 0 && item.presentations[0] instanceof Presentation) {
      let foundPres = this.presentationService.getPresentationById(item.presentations[0].ioPresentationId);
      if (foundPres) {
        firstdetailIconType = 'ActivityPresentation';
        firstdetailObjectId = item.presentations[0].ioPresentationId;
      }
    } else if (item.presentations && item.presentations.length > 0 && item.presentations[0] instanceof Resource) {
      let foundRes = this.resourceService.getResourceById(item.presentations[0].ioResourceId, item.presentations[0].ioDocumentId);
      if (foundRes) {
        firstdetailIconType = 'ActivityResource';
        firstdetailObjectId = item.presentations[0].ioResourceId || item.presentations[0].ioDocumentId;
      }
    }else if(item.presentations && item.presentations.length > 0){
      let foundPres = this.presentationService.getPresentationById(item.presentations[0].contentId);
      if(foundPres){
        firstdetailIconType = 'ActivityPresentation';
        firstdetailObjectId = item.presentations[0].contentId;
      }else{
        let foundRes = this.resourceService.getResourceById(item.presentations[0].contentId, item.presentations[0].contentId);
        if (foundRes) {
          firstdetailIconType = 'ActivityResource';
          firstdetailObjectId = item.presentations[0].contentId;
        }
      }
    }
    view = {
      id: item.ID,
      refObject: item,
      subheaderText: format(item.scheduledStart, this.dateTimeFormatsService.dateTimeToUpper),
      subheaderText2: distanceInWordsToNow(item.scheduledStart)+(isPast(item.scheduledStart)?' '+this.translate.instant('AGO'):' '+this.translate.instant('REMAINING_LOWER_CASE')),
      subheaderText2Color: 'primary',
      headerText: item.subject,
      mainIcon: this.activityService.getActivityIconForTimeLine(item),
      dataToDisplay: [
        {
          label: this.translate.instant('ACCOUNTS'),
          value: (item.accounts && item.accounts.length > 0) ? item.accounts[0].accountName : '',
          placeholderText: this.translate.instant('NO_ACCOUNTS'),
          valuePopupText: (item.accounts && item.accounts.length > 1) ? '+ ' + (item.accounts.length - 1) : '',
          popupListData: (item.accounts && item.accounts.length > 1) ? item.accounts.slice(1).map(a => {
            let obj = {
              id: a.id,
              title: a.accountName,
            };
            return obj;
          }) : [],
        },
        {
          label: this.translate.instant('ACTIVITY_DETAILS_LOCATION'),
          value: item.location || '',
          placeholderText: this.translate.instant('NO_LOCATION'),
        },
        {
          label: this.translate.instant('CONTENT'),
          value: (item.presentations && item.presentations.length > 0) ? item.presentations[0].name : '',
          placeholderText: this.translate.instant('NO_CONTENT'),
          openDetailIcon: (firstdetailIconType && firstdetailObjectId) ? 'assets/imgs/omni_path.svg' : null,
          detailIconType: firstdetailIconType,
          detailObjectId: firstdetailObjectId,
          valuePopupText: (item.presentations && item.presentations.length > 1) ? '+ ' + (item.presentations.length - 1) : '',
          popupListData: (item.presentations && item.presentations.length > 1) ? item.presentations.slice(1).map((a:any) => {
            let detailIconType,detailObjectId;
            let thumbnailURL = a['thumbnailURL'];
            if(a instanceof Presentation){
              let foundPres = this.presentationService.getPresentationById(a.ioPresentationId);
              if(foundPres){
                detailIconType = 'ActivityPresentation';
                detailObjectId = a.ioPresentationId;
                thumbnailURL = a.thumbnailUrl;
              }
            }else if(a instanceof Resource){
              let foundRes = this.resourceService.getResourceById(a.ioResourceId,a.ioDocumentId);
              if(foundRes){
                detailIconType = 'ActivityResource';
                detailObjectId = a.ioResourceId || a.ioDocumentId;
                thumbnailURL = a['thumbnailURL'];
              }
            }else{
              let foundPres = this.presentationService.getPresentationById(a.contentId);
              if(foundPres){
                detailIconType = 'ActivityPresentation';
                detailObjectId = a.contentId;
                thumbnailURL = a.thumbnailURL;
              }else{
                let foundRes = this.resourceService.getResourceById(a.contentId,a.contentId);
                if(foundRes){
                  detailIconType = 'ActivityResource';
                  detailObjectId = a.contentId;
                  thumbnailURL = a['thumbnailURL'];
                }
              }
            }
            let obj = {
              id: a.contentId,
              title: a.name,
              detailObjectType: detailIconType,
              detailObjectId: detailObjectId,
              thumbnailURL: thumbnailURL, //Pres Thumbnail
            };
            return obj;
          }) : [],
        },
      ]
    };
    const activityProds = item.products ? item.products.filter(a=> a.isSelected) : [];
    let prodObj = {
      label: this.translate.instant('PRODUCTS'),
      value: (activityProds && activityProds.length > 0) ? activityProds[0].name : '',
      placeholderText: this.translate.instant('NO_PRODUCTS'),
      valuePopupText: (activityProds && activityProds.length > 1) ? '+ ' + (activityProds.length - 1) : '',
      popupListData: (activityProds && activityProds.length > 1) ? activityProds.slice(1).map(a => {
        let obj = {
          id: a.ID,
          title: a.name,
        };
        return obj;
      }) : [],
    };
    view.dataToDisplay.push(prodObj);
    // if (!this.authService.hasFeatureAction(FeatureActionsMap.MEETING_HIDE_SUBTYPE)) {
    //   view.dataToDisplay.push({
    //     label: this.translate.instant('FORMAT_DETAILS'),
    //     value: item.activitySubTypeName || this.translate.instant('NO_FORMAT_DETAILS'),
    //     valuePopupText: '',
    //   })
    // }
    // view.dataToDisplay.push({
    //   label: this.translate.instant('FORMAT'),
    //   value: item.activityTypeName || this.translate.instant('NO_FORMAT'),
    //   valuePopupText: '',
    // })
    view.dataToDisplay.push({
      label: this.translate.instant('ACCOUNT_TIMELINE_APPOINTMENT_MET_BY_OWNER', { meetingOwner: '' }),
      value: item.meetingOwnerName,
      valuePopupText: '',
    });
    // if (this.authService.hasFeatureAction(FeatureActionsMap.MEETING_HIDE_SUBTYPE)) {
      view.dataToDisplay.push({
        label: this.translate.instant('ACTIVITY_DETAILS_COVISITORS'),
        value: (item.accompaniedUserList && item.accompaniedUserList.length > 0) ? item.accompaniedUserList[0].name : '',
        placeholderText: this.translate.instant('ACTIVITY_DETAILS_NO_COVISITORS'),
        valuePopupText: (item.accompaniedUserList && item.accompaniedUserList.length > 1) ? '+ ' + (item.accompaniedUserList.length - 1) : '',
        popupListData: (item.accompaniedUserList && item.accompaniedUserList.length > 1) ? item.accompaniedUserList.slice(1).map(a => {
          let obj = {
            id: a.id,
            title: a.name,
          };
          return obj;
        }) : [],
      })
    // }
    
    return view;
  }

  // private _getTimelineItemViewForEventActivity(item: EventActivity): TimeLineItem {
  //   let view: TimeLineItem;
  //   view = {
  //     id: item.ID,
  //     refObject: item,
  //     subheaderText: format(item.startDate, this.dateTimeFormatService.dateTimeToUpper),
  //     subheaderText2: distanceInWordsToNow(item.startDate) + (isPast(item.startDate) ? ' ' + this.translate.instant('AGO') : ' ' + this.translate.instant('REMAINING_LOWER_CASE')),
  //     subheaderText2Color: 'primary',
  //     headerText: item.name,
  //     mainIcon: this.activityService.getActivityIconForTimeLine(item),
  //     dataToDisplay: [
  //       {
  //         label: this.translate.instant('START_DATE'),
  //         value: format(item.startDate, this.dateTimeFormatService.dateToUpper),
  //       },
  //       {
  //         label: this.translate.instant('START_TIME'),
  //         value: format(item.startDate, this.dateTimeFormatService.timeToUpper),
  //       },
  //       {
  //         label: this.translate.instant('END_DATE'),
  //         value: format(item.endDate, this.dateTimeFormatService.dateToUpper),
  //       },
  //       {
  //         label: this.translate.instant('END_TIME'),
  //         value: format(item.endDate, this.dateTimeFormatService.timeToUpper),
  //       },
  //       {
  //         label: this.translate.instant('ACCOUNTS'),
  //         value: (item.accounts && item.accounts.length > 0) ? item.accounts[0].name : this.translate.instant('NO_ACCOUNTS'),
  //         valuePopupText: (item.accounts && item.accounts.length > 1) ? '+ ' + (item.accounts.length - 1) : '',
  //         popupListData: (item.accounts && item.accounts.length > 1) ? item.accounts.slice(1).map(a => {
  //           let obj = {
  //             id: a.id,
  //             title: a.name,
  //           };
  //           return obj;
  //         }) : [],
  //       },
  //       {
  //         label: this.translate.instant('COACHING_TEAM_MEMBERS'),
  //         value: (item.covisitors && item.covisitors.length > 0) ? item.covisitors[0].name : this.translate.instant('NO_TEAM_MEMBERS'),
  //         valuePopupText: (item.covisitors && item.covisitors.length > 1) ? '+ ' + (item.covisitors.length - 1) : '',
  //         popupListData: (item.covisitors && item.covisitors.length > 1) ? item.covisitors.slice(1).map(a => {
  //           let obj = {
  //             id: a.id,
  //             title: a.name,
  //           };
  //           return obj;
  //         }) : [],
  //       },
  //       {
  //         label: this.translate.instant('PRODUCTS'),
  //         value: (item.products && item.products.length > 0) ? item.products[0].name : this.translate.instant('NO_PRODUCTS'),
  //         valuePopupText: (item.products && item.products.length > 1) ? '+ ' + (item.products.length - 1) : '',
  //         popupListData: (item.products && item.products.length > 1) ? item.products.slice(1).map(a => {
  //           let obj = {
  //             id: a.id,
  //             title: a.name,
  //           };
  //           return obj;
  //         }) : [],
  //       },
  //     ]
  //   };
  //   return view;
  // }
  

  onPageTitleControlClick(id) {
    if (id === 'opentimelinedetails') {
      if (this.device.isOffline) {
        this.notifiationService.notify(this.translate.instant('ACCOUNT_TIMELINE_NO_INTERNET'),'Account Timeline','top',ToastStyle.DANGER);
        return;
      }
      this.uiService.timelineRefreshRequired = true;
      this.navService.pushChildNavPageWithPageTracking(AccountTimelinePageComponent, PageName.AccountTimelinePageComponent, PageName.AccountPageComponent);
    }
  }

  public async openSubmittedSurveyDetails(survey: any) {
    if(this.device.isOffline) {
      this.notifiationService.notify(this.translate.instant("SURVEY_ACTIVITIES_NOTIFY_ONLINE"), "contact-timeline", "top", ToastStyle.DANGER, 3000, true);
      return;
    }
    //check selected survey tempalte id
    const filteredSurveyList = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY);
    const templateId: string = survey['templateId'];
    const foundTemplate: AssessmentTemplate = filteredSurveyList.find(survey=> survey.indskr_assessmenttemplateid == templateId);
    this.customerSurveyService.setCurrentSurvey(foundTemplate);
    try {
      await this.navService.pushChildNavPageWithPageTracking(SurveyDetailsComponent, PageName.SurveyDetailsComponent, PageName.AccountDetailsComponent, { appointmentId: '', from: PageName.AccountDetailsComponent }).then(async()=>{
        this.navService.setChildNavRightPaneView(true); 
        this.uiService.dismissLoader();
        this.customerSurveyService.selectedAccountForSurvey = this.accountOfflineService.selected;
        try {
          //map survey based on survey responses
          this.uiService.displayLoader;
          const responseData = await this.customerAssessService.fetchContactAccountSurveyForDetails(survey, SurveyCategory.ACCOUNT);
          if(!_.isEmpty(responseData)) {
            const formattedSurvey: CustomerAssessment = await this.customerAssessService.mapCustomerSurveyRespForTimeline(responseData);
            if (!_.isEmpty(formattedSurvey)) {
              this.customerSurveyService.setSurveyPreviewMode(true, formattedSurvey, 'timeline');
            }
          }
          this.uiService.dismissLoader();
        } catch (error) {
          console.log("Error open survey details from surve-timeline ", error);
          this.uiService.dismissLoader();
        }
      });
    } catch(error) {
      console.log("failed to open survey ", error);
      this.uiService.dismissLoader();
    }
    
  }

}
