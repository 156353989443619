import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IonNav, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ProcedureContract } from '@omni/classes/procedure-contract/procedure-contract.class';
import { AlertInfoTypes, OmniInfoAlertComponent } from '@omni/components/shared/omni-info-alert/omni-info-alert.component';
import { ProcedureContractService } from '@omni/data-services/procedure-contract/procedure-contract.service';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { ActivityService } from '@omni/services/activity/activity.service';
import { AlertService } from '@omni/services/alert/alert.service';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import _ from 'lodash';

@Component({
  selector: 'surgery-order-contract-list',
  templateUrl: './surgery-order-contract-list.component.html',
  styleUrls: ['./surgery-order-contract-list.component.scss'],
})
export class SurgeryOrderContractListComponent implements OnInit {
  @Output() selectedProcedureContract = new EventEmitter<any>();
  indHeaderLeftModel: IndHeaderLeftDataModel;
  private procedureContracts: ProcedureContract[] = [];
  procedureContractsToDisplay: MainCardViewDataModel[] = [];
  public hideFilterButton: boolean = true;
  public isSearched: boolean = false;
  public searchInput = '';
  public selectedItems: Array<MainCardViewDataModel> = [];
  public selectedHeader: IndSectionHeaderViewDataModel;
  private _isDirty: boolean = false;
  constructor(
    public translate: TranslateService,
    public uiService: UIService,
    private readonly navService: NavigationService,
    public activityService: ActivityService,
    public footerService: FooterService,
    private procedureContractService: ProcedureContractService,
    private navParams: NavParams,
    public navCtrl: IonNav,
    private readonly alertService: AlertService,
    public modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    this.procedureContracts = this.procedureContractService.procedureContractsToList;

    if (this.navParams.data && this.navParams.data.viewData && this.navParams.data.viewData.selectedProcedureContract) {
      this.selectContract(this.navParams.data.viewData.selectedProcedureContract)
    }
    this.initContractList();
  }

  initContractList() {
    this._resetIsDirty();
    this.initPageTitleHeader();
    this.initProcedureContractList();
    this._initSelectedHeader();
  }

  initPageTitleHeader() {
    let buttons = [];
    // if (this.viewData && (this.viewData.viewMode === ComponentViewMode.READONLY || this.viewData.viewMode === ComponentViewMode.ADDNEW || this.viewData.viewMode === ComponentViewMode.SELECTION)) {
    buttons.push({
      id: "back",
      imgSrc: 'assets/imgs/header_cancel.svg',
      cssClass: 'seventyPercentWidth',
      isDisabled: false,
      align: "left",
    },
      {
        id: "done",
        imgSrc: 'assets/imgs/header_complete.svg',
        cssClass: 'seventyPercentWidth',
        isDisabled: !this._isDirty,
        align: "right",
      });
    // } else {
    //   buttons.push({
    //     id: 'close',
    //     imgSrc: 'assets/imgs/back_to_home_btn.svg',
    //     isDisabled: false,
    //     align: 'left',
    //   });
    // }
    this.indHeaderLeftModel = {
      id: 'contract-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('PROCEDURE_CONTRACTS'),
      mode: true,
      controls: buttons,
    };
  }

  initProcedureContractList() {
    this.procedureContractsToDisplay = [];
    this.procedureContracts.forEach((procedureContract) => {
      this.procedureContractsToDisplay.push(this.setContractsToDisplay(procedureContract));
    });
  }

  private _resetIsDirty() {
    this._isDirty = (this.navParams.data.viewData.selectedProcedureContract && this.selectedItems.length === 0) ||
      (this.selectedItems.length > 0 && this.navParams.data.viewData.selectedProcedureContract !== this.selectedItems[0].id);
  }

  async onPageTitleControlClick(event) {
    if (event === 'done') {
      if (this.navParams.data.viewData) {
        let selectedProcedureContract;
        if (!this.selectedItems.length) {
          selectedProcedureContract = null;
        } else {
          // let contract = this.procedureContractService.findProcedureContractById(this.selectedItems[0].id);
          // let removePostSignature = false;
          // if (contract.indskr_postsurgerysignaturecaptured) {
          //   const response = await this.showConfirmAlert();
          //   if (!response) return;
          //   removePostSignature = true;
          // }

          selectedProcedureContract = { id: this.selectedItems[0].id, name: this.selectedItems[0].primaryTextRight };
        }
        this.navParams.data.viewData.callbackFromSelectionComponent("DONEBUTTONCLICK", selectedProcedureContract, null);
        this.goBack();
      }
    } else {
      this.goBack();
    }
  }

  // async showConfirmAlert() {
  //   const modal = await this.modalCtrl.create({
  //     component: OmniInfoAlertComponent,
  //     cssClass: 'omni-notification-modal',
  //     componentProps: {
  //       header: this.translate.instant('SIGNATURE_EXISTS'),
  //       alertType: AlertInfoTypes.NOTIFICATION_MESSAGE,
  //       notificationMessage: this.translate.instant('SIGNATURE_CAPTURE_AGAIN')
  //     }
  //   });

  //   modal.componentProps.buttons = [{ label: this.translate.instant('CONTINUE'), role: 'ok' }, { label: this.translate.instant('CANCEL'), role: 'cancel' }]
  //   await modal.present();

  //   return await modal.onDidDismiss().then(({ data }) => {
  //     if (!data) return false;
  //     if (data.role === 'ok') return true;
  //     else return false;
  //   });
  // }

  onSearch() {
    let filterdProcedureContracts = [];
    if (this.searchInput) {
      filterdProcedureContracts = this.procedureContractService.procedureContractsToList.filter(({ indskr_name }) => indskr_name.toLowerCase().includes(this.searchInput.toLowerCase()));
    } else {
      filterdProcedureContracts = this.procedureContractService.procedureContractsToList;
    }
    this.procedureContractsToDisplay = [];
    filterdProcedureContracts.forEach((procedureContract) => {
      this.procedureContractsToDisplay.push(this.setContractsToDisplay(procedureContract));
    });
  }

  onCancel() { }

  setContractsToDisplay(proceduerContract: ProcedureContract) {
    const card = {
      id: proceduerContract.indskr_procedurecontractid,
      fixedHeight: true,
      // primaryTextLeft: proceduerContract.indskr_name,
      primaryTextRight: proceduerContract.indskr_name,
      //   secondaryTextRight: numberOfProducts > 0 ? marketingBuninessPlan.products[0]?.productName + (numberOfProducts > 1 ? ` +${numberOfProducts - 1}` : '') : '',
      // isSelected: this.selectedPlanId == proceduerContract.indskr_procedurecontractid,
      showArrow: false,
      arrowType: 'chevron-forward',
      clickHandler: async (id: string, event, eventName, refData) => this.handleProcedureContractItemClick(id, event, eventName, refData)
    }
    // if (this.viewData && (this.viewData.viewMode === ComponentViewMode.ADDNEW || this.viewData.viewMode === ComponentViewMode.SELECTION)) {
    const isSelected = this.selectedItems.some(a => a.id == proceduerContract.indskr_procedurecontractid);
    card['mainItemCssClass'] = 'selector-item';
    card['isItemSelectedForSelectionView'] = true;
    card['endIconType'] = isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon';
    card['endIconCssClass'] = isSelected ? 'checkmark-icon' : 'add-icon';
    card['showEndIcon'] = true;
    // }
    return card;
  }

  handleProcedureContractItemClick(id, event, eventName, refData) {
    const procedureContract = this.procedureContractService.findProcedureContractById(id);

    switch (eventName) {
      case 'mainItem':
        if (!_.isEmpty(this.selectedItems)) {
          this.selectedItems.forEach(i => i.isSelected = i.id === id);
        }
        this.procedureContractsToDisplay.forEach(i => i.isSelected = i.id === id);
        this.selectedProcedureContract.emit({ procedureContract, viewMode: ComponentViewMode.PREVIEW });
        break;
      case 'endIcon':
        if (refData && refData.showEndIcon) {
          if (refData.endIconType === 'indegene-selectors-add-icon') this.selectContract(id);
          if (refData.endIconType === 'indegene-selectors-remove-icon') {
            this.selectedItems = [];
            this.initContractList();
          }
        }
        break;
      default:
        break;
    }
  }

  selectContract(id: string) {
    const procedureContract = this.procedureContractService.findProcedureContractById(id);
    if (!procedureContract) return;

    let contractCard = this.setContractsToDisplay(procedureContract)
    contractCard['endIconType'] = 'indegene-selectors-remove-icon';
    contractCard['endIconCssClass'] = 'remove-icon';

    this.selectedItems = [contractCard];

    this.initContractList()
  }

  private _initSelectedHeader() {
    this.selectedHeader = {
      id: 'selected-header',
      title: this.translate.instant('SELECTED') + ' (' + this.selectedItems.length + ')',
      controls: [],
    };
  }

  async goBack() {
    if (this.navParams && this.navParams.data && this.navParams.data.type && this.navParams.data.type == 'InMeetingProcedurePushedAccountSelectionView') {
      if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
      }
      this.navCtrl.pop({ progressAnimation: false }).then(() => {
        // this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
        this.uiService.activeView = this.uiService.prevView;
        // this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
        if (this.navService.getCurrentMasterPageName() == PageName.AccountPageComponent) {
          // this.accountService.accessedAccountListFrom = PageName.ToolsDrawer
        }
      });
    } else {
      if (this.navService.getCurrentPageName() === PageName.SurgeryOrderContractComponent) {
        this.uiService.showRightPane = true;
        this.uiService.activeView = this.uiService.getActiveViewBasedOnActivity(this.activityService.selectedActivity);
        await this.navService.popWithPageTracking().then(() => {
          if (this.activityService.selectedActivityAtHome) {
            this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
            if (this.navService.getActiveChildNavViewPageName() === PageName.SurgeryOrderContractComponent) this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
          }
        });
      }
    }
  }

}
