<ion-header>
  <ind-header-left [viewData]="indHeaderLeftModel" [searchDebounce]="500"
                   (onControlClick)="onPageTitleControlClick($event)" (onSearchChange)="onSearch()"
                   [(searchText)]="searchInput"
                   (onSearchClear)="onCancel($event)" (onFilterClick)="openAccountPlansFilter($event)"
                   [filterButtonBadgeCount]="getFilterButtonBadgeCount()"></ind-header-left>
  <ind-key-controls-area *ngIf="hasAccountPlanCreation && viewData.viewMode == compViewMode.LISTVIEW"
                         [viewData]="accountPlanKeyControlAreaModel">
  </ind-key-controls-area>
  <ion-toolbar *ngIf="fromPlanTab" class="container-c">
    <ind-tabs [data]="tabs" [(selectedTab)]="uiService.activitiesPageTab"
              (selectedTabChange)="onSelectedTabChange($event)"></ind-tabs>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-item-group [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
    <div *ngIf="viewData.viewMode != compViewMode.LISTVIEW">
      <ng-container *ngIf="selectedItems.length > 0">
        <ind-section-header [viewData]="selectedAccPlansHeader"></ind-section-header>
        <main-card *ngFor="let item of selectedItems; trackBy: trackByItemId" [viewData]="item"></main-card>
      </ng-container>
      <ng-container *ngIf="selectableItems.length > 0">
        <ind-section-header [viewData]="selectableAccPlansHeader"></ind-section-header>
        <main-card *ngFor="let item of selectableItems; trackBy: trackByItemId" [viewData]="item"></main-card>
      </ng-container>
    </div>
    <div *ngIf="viewData.viewMode == compViewMode.LISTVIEW">
      <ng-container
        *ngIf="!isSearched || (isSearched && (plansData.past.length > 0 || plansData.present.length > 0 || plansData.future.length > 0)) else noResult;">

        <ind-section-header [viewData]="pastHeaderModel"></ind-section-header>
        <ion-list class="plans-list">
          <ion-item *ngIf="plansData.past.length === 0">
            <div class="no_account_plans">{{ 'NO_PAST_ACCOUNT_PLANS_ASSOCIATED' | translate }}</div>
          </ion-item>
          <ion-item-sliding #slidingItem *ngFor="let plan of plansData.past; trackBy: trackByPlanId" class="sliding-item"
                            (click)="$event.preventDefault(); $event.stopPropagation();">
            <ng-container [ngTemplateOutlet]="mobSlideOptions"
                          [ngTemplateOutletContext]="{plan:plan, slidingItem:slidingItem }"></ng-container>
          </ion-item-sliding>
        </ion-list>

        <ind-section-header [viewData]="currentHeaderModel"></ind-section-header>
        <ion-list class="plans-list">
          <ion-item *ngIf="plansData.present.length === 0">
            <div class="no_account_plans">{{ 'NO_CURRENT_ACCOUNT_PLANS_ASSOCIATED' | translate }}</div>
          </ion-item>
          <ion-item-sliding #slidingItem *ngFor="let plan of plansData.present; trackBy: trackByPlanId" class="sliding-item"
                            (click)="$event.preventDefault(); $event.stopPropagation();">
            <ng-container [ngTemplateOutlet]="mobSlideOptions"
                          [ngTemplateOutletContext]="{plan:plan, slidingItem:slidingItem }"></ng-container>
          </ion-item-sliding>
        </ion-list>

        <ind-section-header [viewData]="futureHeaderModel"></ind-section-header>
        <ion-list class="plans-list">
          <ion-item *ngIf="plansData.future.length === 0">
            <div class="no_account_plans">{{ 'NO_FUTURE_ACCOUNT_PLANS_ASSOCIATED' | translate }}</div>
          </ion-item>
          <ion-item-sliding #slidingItem *ngFor="let plan of plansData.future; trackBy: trackByPlanId" class="sliding-item"
                            (click)="$event.preventDefault(); $event.stopPropagation();">
            <ng-container [ngTemplateOutlet]="mobSlideOptions"
                          [ngTemplateOutletContext]="{plan:plan, slidingItem:slidingItem }"></ng-container>
          </ion-item-sliding>
        </ion-list>

      </ng-container>
    </div>

    <ng-template #noResult>
      <ind-no-search-result [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
    </ng-template>

    <ng-template #mobSlideOptions let-plan='plan' let-slidingItem='slidingItem'>
      <main-card [viewData]="getAccountPlanmainCardModel(plan)"></main-card>
      <ion-item-options side="end" *ngIf="!device.isOffline && (device.isAndroid() || device.isIOS())">
        <div class="sliding-button" (click)="cloneAccountPlan(plan.ID);slidingItem.close();">
          <img class="sliding-icon" src="assets/imgs/copyicon.svg" width="40"/>
          <p class="sliding-btn-text">{{ 'CLONE'|translate }}</p>
        </div>
      </ion-item-options>
    </ng-template>

  </ion-item-group>
</ion-content>
<footer-toolbar *ngIf="!fromPlanTab" [footerView]="'masterView'" [selectedView]="'accountPlansList'"></footer-toolbar>
