export class Channel {
  constructor(
    public _id: String,
    public _rev: String,
    public indskr_consenttypeid: String,
    public indskr_consentType: string,
    public activityType: ChannelActivityType,
    public indskr_customeracquisitioncost: number,
    public indskr_displayasconsentchannel: boolean,
    public indskr_externalchannel: boolean,
    public currencyId: string,
    public currency: string,
    public values: ChannelValue[],
    public isChecked?: boolean,
    public indskr_enablepreview?: boolean,
  ) {}
}

export class ChannelValue {
  constructor(public id: string, public value: string, public isChecked: boolean,public indskr_consentId?: string
  ) {}
}

export enum ChannelType {
  EMAIL = "Email",
  PHONE = "Phone",
  VISIT = "Visit",
  FAX = "Fax",
  EMAIL_ADDRESS = "Email",
  ADDRESS = "Address",
  PHONE_NUMBER = "Phone",
  SMS = "SMS",
  FACEBOOK = "Facebook",
  WHATSAPP = "WhatsApp",
  WECOM = "WeCom",
  SURGERY_ORDER="procedure log"
}
export enum ChannelActivityType{
  ALLOCATION_ORDER = 548910005,
  EMAIL = 548910001,
  VISIT = 548910000,
  SMS = 548910003,
  FACEBOOK = 548910002,
  WHATSAPP = 548910004,
  SALES_ORDER = 548910006,
  CUSTOMER_INQUIRY = 548910007,
  PHONE = 548910009,
  WECOM = 600000000,
  SURGERY_ORDER=548910010
}

export interface EmailActivityChannelDetails {
  id: string;
  title: string;
  activityType: ChannelActivityType;
  channelType:ChannelType;
  indskr_externalchannel?:boolean;
  enablepreview?: boolean;
}


export class BusinessUnitChannel {
  
  indskr_businessunitchannelid: String;
  channelValue: String;
  channelID: string;
  indskr_name: number;
  statusCode: boolean;
  stateCode: boolean;
  businessunitID: string;
  businessUnit: string;

  constructor(raw) {
    this.indskr_businessunitchannelid = raw['indskr_businessunitchannelid'];
    this.indskr_name = raw["indskr_name"];
    this.channelID = raw["_indskr_buchannel_value"];
    this.channelValue = raw['_indskr_buchannel_value@OData.Community.Display.V1.FormattedValue'];
    this.statusCode = raw['statuscode'] // 1- Active
    this.businessunitID = raw['_indskr_businessunit_value'];
    this.businessUnit = raw['_indskr_businessunit_value@OData.Community.Display.V1.FormattedValue'];
    this.stateCode = raw['statecode'];
    }
}