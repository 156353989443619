import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { IonList, ModalController, PopoverController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { ConfiguredFields } from "@omni/classes/authentication/configured.field.class";
import { FeatureActionsMap } from "@omni/classes/authentication/user.class";
import { AssessmentRating } from "@omni/classes/coaching/assessment-rating.class";
import { CoachingTemplate } from "@omni/classes/coaching/coaching-template.class";
import { IONote } from "@omni/classes/io/io-note.class";
import {
  CurViewPageType,
  DateTimeFieldType,
  IndDatetimeFormComponent
} from "@omni/components/shared/ind-datetime-form/ind-datetime-form";
import { IndDropdownListComponent } from "@omni/components/shared/ind-dropdown-list/ind-dropdown-list";
import { MainToolTemplateComponent } from "@omni/components/shared/main-tool-template/main-tool-template";
import { NothingSelectedView } from "@omni/components/shared/nothing-selected-view/nothing-selected-view";
import { ReadOnlyCoachingConfiguredFieldNames } from "@omni/config/configuredField.config";
import { IndDateTimeFormViewDataModel } from "@omni/models/indDateTimeFormDataModel";
import { IndDropdownListDetailModel } from "@omni/models/indDropdownListModel";
import { FormFieldType, IndFormFieldViewDataModel } from "@omni/models/indFormFieldDataModel";
import { IndPageTitleViewDataModel } from "@omni/models/indPageTitleDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from "@omni/models/mainToolTemplateDetail.model";
import { ActivityService } from "@omni/services/activity/activity.service";
import { ProcedureTrackerActivityDataService } from './../../../data-services/procedure-tracker-activity/procedure-tracker-activity.data.service';
import { AuthenticationService } from "@omni/services/authentication.service";
import {
  getConfigFormFieldIsReadOnly,
  getConfigFormFieldType,
  getConfigFormFieldViewDataModelId,
  getConfigFormInputType
} from "@omni/utility/common.utility";
import { format, isSameDay, isBefore } from "date-fns";
import { MAXIMUM_NOTE_ATTACHMENT_SIZE, NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX, toBase64 } from "@omni/utility/util";
import _, { cloneDeep } from "lodash";
import * as moment from "moment";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { User } from "../../../classes/account/child.user.class";
import { AssessmentMeasure } from "../../../classes/coaching/assessment.measure.class";
import { CoachingReportStatus, CoachingType, Report } from "../../../classes/coaching/report.class";
import { ConfirmationAlertData } from "../../../classes/shared/confirmation-alert-data.class";
import { RepServices } from "../../../data-services/rep/rep.services";
import { REP_STATUS } from "../../../models/rep-status-model";
import { SelectListDetail } from "../../../models/select-list-detail-model";
import { COACHING_VIEW_TYPE, CoachingReportService } from "../../../services/coaching/coaching.report.service";
import { DateTimeFormatsService } from "../../../services/date-time-formats/date-time-formats.service";
import { DeviceService } from "../../../services/device/device.service";
import { EventsService } from "../../../services/events/events.service";
import { FooterService, FooterViews } from "../../../services/footer/footer.service";
import { TrackingEventNames, TrackService } from "../../../services/logging/tracking.service";
import { NavigationService, PageName } from "../../../services/navigation/navigation.service";
import { NotificationService, ToastStyle } from "../../../services/notification/notification.service";
import { UIService } from "../../../services/ui/ui.service";
import { SelectListData } from "../../popover/popover";
import { SelectListComponent } from "../../shared/select-list/select-list";
import { CoachingActivitiesComponent } from "../coaching-activities/coaching-activities";
import { ActivityType, ActivityTypeCodeRaw } from "@omni/classes/activity/activity.class";
import { PhoneActivity } from "@omni/classes/activity/phone.activity.class";
import { OrderActivity } from "@omni/classes/activity/order.activity.class";
import { AppointmentActivity } from "@omni/classes/activity/appointment.activity.class";
import { CoachingActivity } from "@omni/classes/coaching/activity.class";
import { ActivityDataService } from "@omni/data-services/activity/activity.service";

/**
 * Generated class for the CoachingDetailsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'coaching-details',
  templateUrl: 'coaching-details.html',
  styleUrls: ['coaching-details.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CoachingDetailsComponent {

  @Input() isDeviceOnline: boolean = true;
  public report: Report = null;
  private reportSubscription: Subscription;
  public periodStartDate: string = null;
  public periodEndDate: string = null;
  public disabled: boolean = false;
  public disableRatings: boolean = true;
  public disableEditing: boolean = false;
  public enableSend: boolean = false;
  private ratingSummary: string = "";
  private ratingSummaryChanged: Subject<string> = new Subject<string>();
  private recommendations: string = "";
  private recommendationChanged: Subject<string> = new Subject<string>();
  private ratingComments: Map<String, String> = new Map();
  private comments: string = "";
  private measureCopy: AssessmentMeasure;
  private categoryIdCopy: string;
  private ratingCommentsChanged$: Subject<string> = new Subject<string>();
  private textValue: string = "";
  ngdestroy$$: any = new Subject<boolean>();
  public disableClearRating: boolean = true;
  private _selectUserModal;
  public coachingShareFA: boolean = false;
  public isReadOnlyMode = true;
  public coachingDetailsPageTitle: IndPageTitleViewDataModel;
  coachingMainHeaderData: IndSectionHeaderViewDataModel = {
    id: 'market-scan-header',
    title: this.translate.instant('DETAILS_CAP'),
    controls: [
    ],
  };
  @ViewChild(IonList, { read: ElementRef, static: false }) list: ElementRef;
  public backgroundUploadInProgress: boolean = false;
  private isClickedCoachingFor: boolean = false;
  private isClickedCoachingPlan: boolean = false;
  private isClickedCoachingTemplate: boolean = false;
  public configFields: IndFormFieldViewDataModel[] = [];
  private requiredFields: { [key: string]: { isEvaluated: boolean } } = {};
  public hasAccessCoachingPlan: boolean = false;
  public notesHeaderModel:IndSectionHeaderViewDataModel;
  public isSaveNotesEnabled:boolean = false;
  public tempNoteText: string = '';
  public notesPlaceholder:string = '';
  public attachmentTitle:string = '';
  // public isNotesAttachmentEnabled:boolean = false;
  public isAttachmentAdded:boolean = false;
  private attachmentFile:any;
  private base64str;
  private relativeActivity;
  public isHideadditionalfeedback:boolean;
  public isActivityFieldValid:boolean = false;


  constructor(
    public device: DeviceService,
    public repService: RepServices,
    public footerService: FooterService,
    public coachingReportService: CoachingReportService,
    public uiService: UIService,
    private navService: NavigationService,
    private trackingService: TrackService,
    public events: EventsService,
    private _cd: ChangeDetectorRef,
    public translate: TranslateService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private notificationService: NotificationService,
    private modalCtrl: ModalController,
    private authService: AuthenticationService,
    private datePipe: DatePipe,
    private popoverCtrl: PopoverController,
    private activityService: ActivityService,
    private procedureTrackerActivityDataService: ProcedureTrackerActivityDataService,
    public activityDataService: ActivityDataService,
  ) {

    this.ratingCommentsChanged$.pipe(debounceTime(1500), // wait 1500ms after the last event before emitting last event
      distinctUntilChanged()) // only emit if value is different from previous value
      .subscribe((model) => {
        if (this.comments.trim() != model.trim()) {
          this.comments = model;
          if (this.ratingComments.has(this.measureCopy.measure_id) && this.ratingComments.get(this.measureCopy.measure_id).trim() != this.comments.trim()) {
            this.coachingReportService.saveOrUpdateRatingComments(this.comments, this.categoryIdCopy, this.measureCopy);
            this.ratingComments.set(this.measureCopy.measure_id, this.comments);
          } else {
            this.ratingComments.set(this.measureCopy.measure_id, model);
            this.coachingReportService.saveOrUpdateRatingComments(model, this.categoryIdCopy, this.measureCopy);
          }
        }
      });
  }

  ngOnInit() {
    this.isHideadditionalfeedback = this.authService.user.buSettings["indskr_hideadditionalfeedback"] ;
    this.coachingShareFA = this.authService.hasFeatureAction(FeatureActionsMap.COACHING_SHARE);
    this.hasAccessCoachingPlan = this.authService.hasFeatureAction(FeatureActionsMap.ACCESS_COACHING_PLAN);
    if(!this.authService.user.buSettings['indskr_coachingmakeactivitymandatory']) this.isActivityFieldValid = true;
    this.reportSubscription = this.coachingReportService.selectedReport.subscribe((report: Report) => {
      if (report) {
        if (this.report?.offlineCoachingReportId != report.offlineCoachingReportId && this.list) {
          this.uiService.scrollListToView(this.list);
        }
        // console.log(this.coachingReportService.allActivity);
        // if(report?.indskr_coachingplan){
        //   this.getCoachingPlanActivity(report.indskr_coachingplan);
        // }
        this.footerService.initButtons(FooterViews.CoachingDetails);
        this.periodStartDate = moment(report.indskr_periodstartdate).format();
        this.periodEndDate = moment(report.indskr_periodenddate).format();
        this.report = report;
        if(this.report.indskr_coachingtemplateid){
          let selectedTemplate = this.coachingReportService.coachingTemplates.find(o=> o.indskr_coachingtemplateid == this.report.indskr_coachingtemplateid)
          if(selectedTemplate) this.setMeasureRequiredBehavior(selectedTemplate);
        }
        //initialize ioNote
        if (!_.isEmpty(this.report.annotations)) {
          this.report.annotations = this.report.annotations.map(note => {
            if (!(note instanceof IONote)) {
              let newNote =  new IONote(note);
              newNote.createdTime = note['createdon'] ? new Date(note['createdon']) : note['createdTime'] ? new Date(note['createdTime']) : null;
              return newNote;
            }
            else return note;
          })
        }
        this._initConfigFields();
        this.calculateTemplateWeight();
        this.initButtons();
        this.initCoachingDetailsPageTitle();
        this.initCoachingNotesModel();
        this._cd.detectChanges();
      }
    });
    this.isDeviceOnline = !this.device.isOffline;
    this.events.observe('device:deviceIsOffline').pipe(
      takeUntil(this.ngdestroy$$))
      .subscribe((status) => {
        // the network service indicate that the device is offline so no arguments.
        this._cd.detectChanges();
        this.isDeviceOnline = false;
        this._cd.detectChanges();
        this._cd.markForCheck();
        this.initButtons();
      });

    this.events.observe('device:deviceIsOnline').pipe(
      takeUntil(this.ngdestroy$$))
      .subscribe((status) => {
        if (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState) {
          this._cd.detectChanges();
          this.isDeviceOnline = false;
          this._cd.detectChanges();
          this._cd.markForCheck();
        }
        else {
          this._cd.detectChanges();
          this.isDeviceOnline = true;
          this._cd.detectChanges();
          this._cd.markForCheck();
        }
        this.initButtons();
      });
      this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngdestroy$$)).subscribe(inProgress => {
        if (this.report) {
          this.backgroundUploadInProgress = inProgress && this.coachingReportService.isOffline(this.report);
          this.initCoachingDetailsPageTitle();
          this.footerService.initButtons(FooterViews.CoachingDetails);
        }
      });
    this.events.observe('TeamCoachingReportClicked').pipe(
      takeUntil(this.ngdestroy$$))
      .subscribe(() => {
        this.isClickedCoachingFor = false;
        this.isClickedCoachingTemplate = false;
        this.isClickedCoachingPlan = false;
      })

  }

  ngOnDestroy() {
    this.reportSubscription.unsubscribe();
    this.ngdestroy$$.next(true);
    this.ngdestroy$$.complete();
  }

  private initButtons() {
    if (!this.report) return;
    this.disabled = false;
    this.disableRatings = true;
    this.disableEditing = false;
    if(!this.authService.user.buSettings['indskr_coachingmakeactivitymandatory']) this.isActivityFieldValid = true;
    let statusCode = this.report.statuscode;
    let activeTab = this.coachingReportService.activeTab;
    this.footerService.initButtons(FooterViews.CoachingDetails);
    if (statusCode !== 1) {
      this.disabled = true;
    }
    if (this.backgroundUploadInProgress || (activeTab === 'teamCoaching' && (this.coachingShareFA && statusCode === 548910003) || (!this.coachingShareFA && statusCode === 1))) {
      this.disableRatings = false;
    }
    if (activeTab === 'teamCoaching') {
      if (this.backgroundUploadInProgress || statusCode == 548910004 || statusCode == 548910005 || statusCode == 548910001 || statusCode == 548910000 || statusCode == 4 || statusCode == 5) {
        this.disableEditing = true;
      }
    } else {
      if (this.backgroundUploadInProgress || statusCode != 1) {
        this.disableEditing = true;
      }
    }
    if (activeTab === 'myCoaching') {
      if (statusCode === 548910000 || statusCode === 548910001 || statusCode === 5) {
        if (this.ackDisabled) {
          this.footerService.disableButton(['ackcoaching']);
        } else {
          this.footerService.enableButtons(['ackcoaching']);
        }
      }
      if (this.coachingShareFA) {
        if (this.backgroundUploadInProgress || statusCode === 548910003 || statusCode === 3) {
          this.footerService.disableButton(['acceptcoaching']);
        } else {
          this.footerService.enableButtons(['acceptcoaching']);
        }
      }
    } else {
      this.footerService.disableButton(['sharecoaching', 'deletecoaching', 'recallcoaching']);
      if (!this.backgroundUploadInProgress) {
        if (statusCode === 1) {
          this.isReadOnlyMode = false;
          this.footerService.enableButtons(['deletecoaching']);
          if (this.coachingShareFA && this.report.indskr_coachingfor_value && this.report.indskr_coachingtemplateid && !this.checkMandatoryFieldsConfigFields()) {
            this.footerService.enableButtons(['sharecoaching']);
          }
          if (this.closeDisabled) {
            this.footerService.disableButton(['recallcoaching', 'closecoaching']);
          }
          if (!this.coachingShareFA) {
            this.setSendBtnStatus();
            if (this.enableSend) {
              this.footerService.enableButtons(['sendcoaching']);
            } else {
              this.footerService.disableButton(['sendcoaching']);
            }
          }
        }
      }
      if (this.coachingShareFA && statusCode === 548910002) {
        this.isReadOnlyMode = false;
      }
      if (!this.coachingShareFA && statusCode === 548910002) {
        this.isReadOnlyMode = true;
        this.footerService.disableButton(['deletecoaching', 'sendcoaching']);
      }
      if (this.coachingShareFA && (statusCode === 548910001 || statusCode === 548910003 || statusCode === 4)) {
        this.setSendBtnStatus();
        if (this.enableSend && statusCode === 548910003) {
          this.isReadOnlyMode = false;
          this.footerService.enableButtons(['sendcoaching']);
        } else {
          this.isReadOnlyMode = true;
          this.footerService.disableButton(['sendcoaching']);
        }
      }
      if (!this.coachingShareFA && (statusCode === 548910001 || statusCode === 4)) {
        this.isReadOnlyMode = true;
        this.footerService.disableButton(['deletecoaching', 'sendcoaching']);
      }
      if (statusCode === 548910000) {
        this.isReadOnlyMode = true;
        this.footerService.disableButton(['deletecoaching', 'sendcoaching']);
      }
      if (statusCode === 548910004) {
        this.isReadOnlyMode = true;
        this.footerService.enableButtons(['recallcoaching']);
      }
      if (statusCode === 548910005) {
        this.isReadOnlyMode = true;
        this.footerService.disableButton(['recallcoaching']);
      }
    }
    if (this.coachingReportService.coachingViewtype === COACHING_VIEW_TYPE.FROM_MEETING || this.coachingReportService.coachingViewtype === COACHING_VIEW_TYPE.FROM_PHONECALL) {
      this.disableEditing = true;
      this.disableRatings = true;
      this.isReadOnlyMode = true;
      this.footerService.disableButton(['sharecoaching', 'deletecoaching', 'sendcoaching']);
    }
  }

  private initCoachingNotesModel(){
    this.notesHeaderModel = {
      id: 'task-notes-header',
      title: this.translate.instant('NOTES'),
      controls: [
        {
          id:'attach_note',
          text: this.translate.instant('ATTACH'),
          isDisabled: false,//this.device.isOffline || this.isReadOnlyMode,
          isVisible: true//!this.isReadOnlyMode,
        },
        {
          id:'save_note',
          text: this.translate.instant('SAVE'),
          isDisabled: !this.isSaveNotesEnabled ,//|| this.device.isOffline || this.isReadOnlyMode,
          isVisible: true//!this.isReadOnlyMode,
        }
      ]
    };
    let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
    this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
  }

  public removeAttachment(event){
    try {
      (document.getElementById('file-input') as any).value = null;
    } catch (error) {
      console.log(error);
    }
    this.attachmentTitle = '';
    this.isAttachmentAdded = false;
    this.attachmentFile = null;
    this.base64str = null;
    if(this.tempNoteText.length > 0 || this.attachmentFile){
      this.isSaveNotesEnabled = true;
    }else{
      this.isSaveNotesEnabled = false;
    }
    this.initCoachingNotesModel();
  }

  calculateTemplateWeight() {
    if (this.report.indskr_coachingtemplateid && this.report.categories.length) {
      this.coachingReportService.templateWeight = 0;
      this.report.categories.forEach(cat => {
        cat.measures.filter(o=>o.measure_isrequired).forEach(m => {
          let maxValue;
          if (m.ratingscale_id) {
            maxValue = Math.max(...m.ratings.map(r=> r.rating_value));
          } else {
            maxValue = m.max_rating;
          }
          this.coachingReportService.templateWeight += maxValue;
        })
      })
    }
  }

  private setMeasureRequiredBehavior(template: CoachingTemplate){
    this.report.categories.forEach(reportCategory=>{
      const templateCtegory = template.categories.find(o=> o.category_id == reportCategory.category_id);
      if(templateCtegory){
        reportCategory.measures.forEach(reportCatMeasure => {
          const templateCatMeasure = templateCtegory.measures.find(m=> m.measure_id == reportCatMeasure.measure_id)
          if(templateCatMeasure) reportCatMeasure.measure_isrequired = templateCatMeasure.measure_isrequired;
        });
      }
    })
  }

  public async openCoachingFor() {
    if (this.report.statuscode !== 1) return;
    // this.navService.pushWithPageTracking(CoachingForSelectComponent, PageName.CoachingForSelectComponent, null, PageName.CoachingForSelectComponent);
    this.coachingReportService.userMappedCoachingTemplates = [];
    const coachingusers: User[] = cloneDeep(this.coachingReportService.users);
    let selecttedConfirm = !!this.report.indskr_coachingfor_value;
    let isConfirmationRequired: boolean = (this.coachingReportService.users && selecttedConfirm)
    let confirmationData;
    if (isConfirmationRequired) {
      confirmationData = new ConfirmationAlertData({
        message: this.translate.instant('COACHING_ALL_CHANGES_LOST_AND_RESET'),
        title: this.translate.instant('COACHING_RESET'),
        cancelButtonText: this.translate.instant('CANCEL'),
        confirmButtonText: this.translate.instant('YES'),
      });
    }
    let options: SelectListData[] = coachingusers.map(user => {
      return { id: user.id, title: user.fullName, isSelected: this.report.indskr_coachingfor_value === user.id }
    });

    const selectListDetail: SelectListDetail = {
      isMultipleSelectionEnabled: false,
      isSearchEnabled: true,
      title: this.translate.instant('COACHING_TEAM_MEMBERS'),
      dividerTitle: this.translate.instant('ALL_TEAM_MEMBERS_CAP'),
      data: options,
      confirmationCheckRequired: isConfirmationRequired,
      confirmationAlertData: confirmationData,
      navOptions: { animate: false }
    };
    this._selectUserModal = await this.modalCtrl.create({ component: SelectListComponent, componentProps: { viewData: selectListDetail }, backdropDismiss: false, animated: false });
    this._selectUserModal.present();
    this._selectUserModal.onDidDismiss().then((obj) => {
      const data = obj && obj.data;
      if (data && data && data.isDone && Array.isArray(data.selectedItems)) {
        if ((data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.report.indskr_coachingfor_value) ||
          (!data.selectedItems || data.selectedItems.length == 0) && this.report.indskr_coachingfor_value) {
          const coaching: Report = <Report>{ ...this.report };
          if (data.selectedItems && data.selectedItems.length == 1) {
            coaching.indskr_coachingfor_value = data.selectedItems[0].id;
            coaching.indskr_name = this.translate.instant("COACHING_FOR") + " " + data.selectedItems[0].title + " " + this.translate.instant("ON") + " " + moment(this.report.createdon).format("MMM DD" + ", " + "YYYY");
            coaching.created_for = data.selectedItems[0].title;
            coaching.indskr_coachingtemplateid = "";
            coaching.indskr_coachingtemplateidname = "";
            for (var createuser of this.coachingReportService.users) {
              if (createuser.id == data.selectedItems[0].id) {
                coaching.created_for_firstname = createuser.firstName;
              }
            }
          }
          else {
            coaching.indskr_coachingfor_value = "";
            coaching.indskr_name = "";
            coaching.created_for = "";
            coaching.created_for_firstname = "";
            coaching.indskr_coachingtemplateid = "";
            coaching.indskr_coachingtemplateidname = "";
          };
          let response = this.coachingReportService.updateCoachingFor(coaching);
          if(response) {
            this.isClickedCoachingTemplate = false;
            if(_.isEmpty(coaching.indskr_coachingfor_value)) this.isClickedCoachingFor = true;
            else this.isClickedCoachingFor = false;
          }
        }
      } else {
        this.isClickedCoachingFor = true
      }
      this.initButtons();
      // this.footerService.initButtons(FooterViews.CoachingDetails);
    });

  }

  public async openCoachingTemplates(event) {
    if (this.report.statuscode !== 1) return;
    if (!this.report.indskr_coachingfor_value) return;
    let options = [];
    this.coachingReportService.userMappedCoachingTemplates = [];
    if (this.report.indskr_coachingfor_value) {
      this.coachingReportService.userMappedCoachingTemplates = this.coachingReportService.getCoachingTemplatesByUser(this.report.indskr_coachingfor_value);
      if(this.coachingReportService.userMappedCoachingTemplates && this.coachingReportService.userMappedCoachingTemplates.length<1) this.isClickedCoachingTemplate = true;
    }
    const coachingTemplates: CoachingTemplate[] = _.cloneDeep(this.coachingReportService.userMappedCoachingTemplates);
    coachingTemplates.forEach((c: CoachingTemplate) => {
      let isSelected: boolean;
      isSelected = this.report && this.report.indskr_coachingtemplateid && this.report.indskr_coachingtemplateid === c.indskr_coachingtemplateid;
      options.push({ id: c.indskr_coachingtemplateid, title: c.indskr_name, isSelected: isSelected });
    });
    options = this.coachingReportService.sortListByFieldName(options, 'title');
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'template-select',
      data: options,
      isMultipleSelectionEnabled: false,
      isReadOnly: this.report.statuscode != 1/*,
      showClearAllBtn: this.report.statuscode === 1,
      clearAllBtnText: this.translate.instant('CLEAR_ALL')*/
    };
    let dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: dropdownListDetail
      },
      cssClass: 'template-dropdown-list-view',
      event: event
    });
    dropdownPopover.onDidDismiss().then(async (popoverData) => {
      const data = popoverData.data;
      if(data!=null) {
        if (this.report.indskr_coachingtemplateid !== data.selectedItems[0].id) {
          const coaching: Report = <Report>{ ...this.report };
          coaching.indskr_coachingtemplateid = data.selectedItems[0].id;
          coaching.indskr_coachingtemplateidname = data.selectedItems[0].title;
          let response = this.coachingReportService.updateCoachingFor(coaching);
          if(response && _.isEmpty(coaching.indskr_coachingtemplateid = data.selectedItems[0].id)) this.isClickedCoachingTemplate = true;
          else this.isClickedCoachingTemplate = false;
        }
      } else {
        this.isClickedCoachingTemplate = true;
      }
    });
    dropdownPopover.present();
  }

  public async openCoachingTypes(event) {
    if (this.report.statuscode !== 1) return;
    let options = [];
    this.coachingReportService.coachingTypes.forEach(c => {
      let isSelected: boolean;
      isSelected = this.report && this.report.indskr_coachingtype && this.report.indskr_coachingtype === c.value;
      options.push({ id: c.value, title: c.label, isSelected: isSelected });
    });
    // options = this.coachingReportService.sortListByFieldName(options, 'title');
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'template-select',
      data: options,
      isMultipleSelectionEnabled: false,
      isReadOnly: this.report.statuscode != 1
    };
    let dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: dropdownListDetail
      },
      cssClass: 'template-dropdown-list-view',
      event: event
    });
    dropdownPopover.onDidDismiss().then(async (popoverData) => {
      const data = popoverData.data;
      if(data!=null) {
        if (this.report.indskr_coachingtype !== data.selectedItems[0].id) {
          const coaching: Report = <Report>{ ...this.report };
          coaching.indskr_coachingtype = data.selectedItems[0].id;
          let payload: any = {
            "indskr_coachingtype": coaching.indskr_coachingtype
          };
          await this.coachingReportService.updateReport(payload, false);
        }
      }
    });
    dropdownPopover.present();
  }

  public async openOverallRatings(event) {
    if (this.report.statuscode !== 1) return;
    let options = [];
    this.coachingReportService.overallRatings.forEach(c => {
      let isSelected: boolean;
      isSelected = this.report && this.report.indskr_overallrating && this.report.indskr_overallrating === c.value;
      options.push({ id: c.value, title: c.label, isSelected: isSelected });
    });
    // options = this.coachingReportService.sortListByFieldName(options, 'title');
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'template-select',
      data: options,
      isMultipleSelectionEnabled: false,
      isReadOnly: this.report.statuscode != 1
    };
    let dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: dropdownListDetail
      },
      cssClass: 'template-dropdown-list-view',
      event: event
    });
    dropdownPopover.onDidDismiss().then(async (popoverData) => {
      const data = popoverData.data;
      if(data!=null) {
        if (this.report.indskr_overallrating !== data.selectedItems[0].id) {
          const coaching: Report = <Report>{ ...this.report };
          coaching.indskr_overallrating = data.selectedItems[0].id;
          let payload: any = {
            "indskr_overallrating": coaching.indskr_overallrating
          };
          await this.coachingReportService.updateReport(payload, false);
        }
      }
    });
    dropdownPopover.present();
  }

  public async openObservations(event, measure, categoryId, measureId) {
    if (this.report.statuscode !== 1) return;
    let options = [];
    if(measure.ratingscale_id){
      measure.ratings.forEach(x=>{
        options.push({ id: x.rating_value, title: x.rating_label, isSelected: measure.rating_label == x.rating_label ,ratingId:x.rating_id })
      });
    }else{
      this.coachingReportService.observationValues.forEach(c => {
        let isSelected: boolean;
        isSelected = measure && measure.observation && measure.observation === c.value;
        options.push({ id: c.value, title: c.label, isSelected: isSelected });
      });
    }
    options = options.sort((a,b)=>a.id-b.id);   
    // options = this.coachingReportService.sortListByFieldName(options, 'title');
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'obs-select',
      data: options,
      isMultipleSelectionEnabled: false,
      isReadOnly: this.report.statuscode != 1
    };
    let dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: dropdownListDetail
      },
      cssClass: 'template-dropdown-list-view',
      event: event
    });
    dropdownPopover.onDidDismiss().then(async (popoverData) => {
      const data = popoverData.data;
      if(data!=null) {
        if(data.selectedItems[0].ratingId){
          this.coachingReportService.saveOrUpdateRating(data.selectedItems[0].id, data.selectedItems[0].title, data.selectedItems[0].ratingId, categoryId, measureId);
          this.disableClearRating = false;
          this.initButtons();
          this._cd.detectChanges();
        }
        else if (measure.observation !== data.selectedItems[0].id) {
          this.coachingReportService.saveOrUpdateObservation(data.selectedItems[0].id, categoryId, measureId);
          this.disableClearRating = false;
          this.initButtons();
          this._cd.detectChanges();
        }
      }
    });
    dropdownPopover.present();
  }

  public async openOverallProcedures(event, measure, categoryId, measureId) {
    if (this.report.statuscode !== 1) return;
    let options = [];
    if(measure.ratingscale_id){
      measure.ratings.forEach(x=>{
        options.push({ id: x.rating_value, title: x.rating_label, isSelected: measure.rating_label == x.rating_label ,ratingId:x.rating_id })
      });
    }else{
      this.coachingReportService.overAllProcedureValues.forEach(c => {
        let isSelected: boolean;
        isSelected = measure && measure.overallprocedure && measure.overallprocedure === c.value;
        options.push({ id: c.value, title: c.label, isSelected: isSelected });
      });
    }
    options = options.sort((a,b)=>a.id-b.id);
    // options = this.coachingReportService.sortListByFieldName(options, 'title');
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'op-select',
      data: options,
      isMultipleSelectionEnabled: false,
      isReadOnly: this.report.statuscode != 1
    };
    let dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: dropdownListDetail
      },
      cssClass: 'template-dropdown-list-view',
      event: event
    });
    dropdownPopover.onDidDismiss().then(async (popoverData) => {
      const data = popoverData.data;
      if(data!=null) {
        // if (data.selectedItems[0].id ) {
          if(data.selectedItems[0].ratingId){
            this.coachingReportService.saveOrUpdateRating(data.selectedItems[0].id, data.selectedItems[0].title, data.selectedItems[0].ratingId, categoryId, measureId);
            this.disableClearRating = false;
            this.initButtons();
            this._cd.detectChanges();
          }else if( !data.selectedItems[0].id ||measure.overallprocedure !== data.selectedItems[0].id){
            this.coachingReportService.saveOrUpdateOverallProcedure(data.selectedItems[0].id, categoryId, measureId);
            this.disableClearRating = false;
            this.initButtons();
            this._cd.detectChanges();
          }
        // }
      }
    });
    dropdownPopover.present();
  }

  public setRating(value: number, rating_label: String, rating_id: String, category_id: String, measure_id: String) {
    if (this.notifyOnDisabledCoachings) return;
    if (this.coachingReportService.activeTab != "teamCoaching" && this.report.statuscode != 548910003) return;
    this.coachingReportService.saveOrUpdateRating(value, rating_label, rating_id, category_id, measure_id);
    this.disableClearRating = false;
    this.initButtons();
    this._cd.detectChanges();
  }

  public clearRatings(category_id: String) {
    this.coachingReportService.clearRatings(category_id);
    this.initButtons();
    this._cd.detectChanges();
  }

  private saveNewReport() {
    if (!this.report.indskr_coachingreportid) {
      this.coachingReportService.saveNewReport();
    }
  }

  public shareReport() {
    // this.trackingService.tracking('CoachingSent', TrackingEventNames.COACHING, null, true);
    this.coachingReportService.shareReport();
    this.initButtons();
  }

  public submitReport() {
    this.trackingService.tracking('CoachingSent', TrackingEventNames.COACHING, null, true);
    this.coachingReportService.submitReport();
    this.initButtons();
  }

  public closeReport() {
    this.trackingService.tracking('CoachingClosed', TrackingEventNames.COACHING, null, true);
    this.coachingReportService.closeReport();
    this.initButtons();
  }

  public acceptReport() {
    // this.trackingService.tracking('CoachingAcknowledged', TrackingEventNames.COACHING, null, true);
    this.coachingReportService.acceptReport();
    this.initButtons();
  }

  public acknowledgeReport() {
    this.trackingService.tracking('CoachingAcknowledged', TrackingEventNames.COACHING, null, true);
    this.coachingReportService.acknowledgeReport();
    this.initButtons();
  }

  public recallReport() {
    // this.trackingService.tracking('CoachingAcknowledged', TrackingEventNames.COACHING, null, true);
    this.coachingReportService.recallReport();
    this.initButtons();
  }

  public scrapReport() {
    this.trackingService.tracking('CoachingScrapped', TrackingEventNames.COACHING, null, true);
    this.coachingReportService.scrapReport();
  }

  public reportStatus(code: number): string {
    let coachingStatus;
    coachingStatus = this.coachingReportService.makeStatus(code);
    return coachingStatus;
  }

  public reportType(code: number): string {
    return CoachingType["Type_" + code];
  }

  public openActivities() {
    if (this.device.isOffline && this.report.statuscode === 1) {
      if (this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) {
        this.notificationService.notify(this.translate.instant('COACHING_MEETING_PHONE_CALL_CAN_BE_ADDED_IN_ONLINE_MODE_ONLY'), 'Coaching Details', 'top', ToastStyle.DANGER);
      } else {
        this.notificationService.notify(this.translate.instant('COACHING_MEETING_CAN_BE_ADDED_IN_ONLINE_MODE_ONLY'), 'Coaching Details', 'top', ToastStyle.DANGER);
      }
      return;
    }
    if (this.report.statuscode !== 1 && !this.coachingHasActivities) return;
    if (this.coachingReportService.activeTab === 'myCoaching' || this.report.indskr_coachingfor_value) {
      this.navService.pushWithPageTracking(CoachingActivitiesComponent, PageName.CoachingActivitiesComponent, null, PageName.CoachingActivitiesComponent);
    }
  }

  setSendBtnStatus() {
    this.enableSend = true;
    if (this.backgroundUploadInProgress || !this.report.indskr_coachingfor_value || !this.checkAllRatings() || this.checkMandatoryFieldsConfigFields()) {
      this.enableSend = false;
      this.disableClearRating = true;
    }
    this.initCoachingDetailsPageTitle();
  }

  private checkAllRatings(): boolean {
    let ratingFound: boolean = false;
    let measures = [].concat(...this.report.categories.map(c=> c.measures));
    for (let i = 0; i <measures.length; i++) {
      if (measures.filter(mesaure => (mesaure.measure_isrequired && !mesaure.rating_id && !mesaure.score)).length == 0) {
        ratingFound = true;
        this.disableClearRating = false;
        break;
      }
    }
    return ratingFound;
  }

  private checkMandatoryFieldsConfigFields(): boolean {
    return this.configFields.filter(e => !e.inputText && e.isRequired).length >= 1;
  }

  public closepage() {
    if (this.uiService.showFieldCoaching) {
      this.uiService.showFieldCoaching = false;
      this.uiService.activeView = this.uiService.prevView
    }
    this.coachingReportService.selectedReport.next(null);
    this.navService.popChildNavPageWithPageTracking();
    if (this.coachingReportService.coachingViewtype === COACHING_VIEW_TYPE.FROM_MEETING) {
      this.footerService.initButtons(FooterViews.Activities);
    } else if (this.coachingReportService.coachingViewtype === COACHING_VIEW_TYPE.FROM_PHONECALL) {
      this.footerService.initButtons(FooterViews.PHONE_CALL);
    }
    this.navService.setChildNavRoot(NothingSelectedView,PageName.NothingSelectedView,PageName.NothingSelectedView).then(()=>{
      this.navService.setChildNavRightPaneView(false);
      this.uiService.updateNothingSelectedScreenMessageFor(1);
      this.uiService.showRightPane = false;
    });
    // this.navService.setChildNavRightPaneView(false);
  }

  ratingsSummaryChanged(value: string) {
    const report: Report = this.report;
    this.ratingSummaryChanged.pipe(
      debounceTime(3000), // wait 2000ms after the last event before emitting last event
      distinctUntilChanged()) // only emit if value is different from previous value
      .subscribe((model) => {
        if (this.ratingSummary.trim() != model.trim()) {
          this.ratingSummary = model;
          let payload: any = {
            "indskr_ratingssummary": this.ratingSummary
          };
          this.coachingReportService.updateRatingSummaryOrRecommendation(report, payload);
        }
      });
    this.ratingSummaryChanged.next(value);
    this.initButtons();
  }

  recommendationsChanged(value: string) {
    const report: Report = this.report;
    this.recommendationChanged.pipe(
      debounceTime(3000), // wait 2000ms after the last event before emitting last event
      distinctUntilChanged()) // only emit if value is different from previous value
      .subscribe((model) => {
        if (this.recommendations.trim() != model.trim()) {
          this.recommendations = model;
          let payload: any = {
            "indskr_recommendations": this.recommendations
          };
          this.coachingReportService.updateRatingSummaryOrRecommendation(report, payload);
        }
      });
    this.recommendationChanged.next(value);
    this.initButtons();
  }

  ratingCommentsChanged(value: any, category_id: string, measure: AssessmentMeasure) {
    this.measureCopy = measure;
    this.categoryIdCopy = category_id;
    this.ratingCommentsChanged$.next(value);
    this.initButtons();
  }

  onTextAreaFocus(value) {
    if (this.notifyOnDisabledCoachings) return;
    this.textValue = value;
  }

  onScoreTextAreaFocus(measure: AssessmentMeasure) {
    if (this.notifyOnDisabledCoachings) return;
    let outOfRange = this.resetZeroOnRanges(measure);
    if (outOfRange) measure.score = "0";
    this.textValue = measure.score ? measure.score.toString() : "";
  }

  resetZeroOnRanges(measure: AssessmentMeasure): boolean {
    let outOfRange = false;
    let score = Number(measure.score);
    if (measure.score && !isNaN(score) && measure.min_rating && measure.max_rating) {
      if (score > measure.max_rating || score < measure.min_rating) {
        measure.score = "0";
        outOfRange = true;
      }
    }
    return outOfRange;
  }

  onFeedbackFocusOut(category_id: string, measure: AssessmentMeasure) {
    if (measure.rating_comments != this.textValue) this.coachingReportService.saveOrUpdateRatingComments(measure.rating_comments, category_id, measure);
    this._cd.detectChanges();
  }

  onScoreFocusOut(measure: AssessmentMeasure, category_id: string, measure_id: String) {
    if (this.notifyOnDisabledCoachings) return;
    let outOfRange = this.resetZeroOnRanges(measure);
    if (outOfRange) measure.score = "0";
    if ((measure.score ? measure.score.toString() : "") != this.textValue)  this.coachingReportService.saveOrUpdateRatingScore(measure.score, category_id, measure_id);
    this.disableClearRating = false;
    this.initButtons();
    this._cd.detectChanges();
  }

  onScoreInput(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onSummaryFocusOut(value) {
    if (this.notifyOnDisabledCoachings) return;
    if (value != this.textValue) this.coachingReportService.updateRatingSummaryOrRecommendation(this.report, { "indskr_ratingssummary": value });
  }

  onRecommendationFocusOut(value) {
    if (this.notifyOnDisabledCoachings) return;
    if (value != this.textValue) this.coachingReportService.updateRatingSummaryOrRecommendation(this.report, { "indskr_recommendations": value });
  }

  onFocusOutPauseForASec() {
    if (this.measureCopy.rating_comments != this.comments) {
      this.uiService.displayLoader();
      setTimeout(async () => {
        await this.coachingReportService.saveOrUpdateRatingComments(this.comments, this.categoryIdCopy, this.measureCopy);
        await this.uiService.dismissLoader();
      }, 1000);
    }
  }

  showHideRatingManagerRep(measure: AssessmentMeasure, rating: AssessmentRating): boolean {
    let show: boolean = false;
    if (this.coachingReportService.activeTab === "teamCoaching") {
      show = measure.rating_id && measure.rating_value === rating.rating_value;
    } else {
      let status = this.report.statuscode;
      if (status == 548910001 || status == 548910000 || status == 5 || status == 548910004 ||status == 548910005 ) {
        show = measure.rating_id && measure.rating_value === rating.rating_value;
      }
    }
    return show;
  }

  showHideValueManagerRep(score: String): String {
    let show: String = "";
    if (this.coachingReportService.activeTab === "myCoaching") {
      let status = this.report.statuscode;
      if (status == 548910001 || status == 548910000 || status == 5) {
        show = score;
      }
    } else {
      show = score;
    }
    return show;
  }

  get notifyOnDisabledCoachings(): boolean {
    let isNotify: boolean = false;
    if (!this.coachingShareFA) return ;
    if (this.coachingReportService.activeTab === 'teamCoaching'
      && (this.report.statuscode === 1 || this.report.statuscode === 2 || this.report.statuscode === 548910002)) {
      let notification = this.translate.instant('COACHING_SHARE_BLOCK_RATINGS_FEEDBACK_ERR', {coachingFor: this.report.created_for});
      this.notificationService.notify(notification, 'Coaching Details', 'top', ToastStyle.DANGER);
      this.textValue = "";
      isNotify = true;
    }
    return isNotify
  }

  private initCoachingDetailsPageTitle(): void {
    let statusCode = this.report.statuscode;
    let coachingFrom: boolean = this.coachingReportService.coachingViewtype === COACHING_VIEW_TYPE.FROM_MEETING || this.coachingReportService.coachingViewtype === COACHING_VIEW_TYPE.FROM_PHONECALL;
    this.coachingDetailsPageTitle = {
      id: 'coaching-details-page-title',
      title: this.coachingReportService.activeTab === 'myCoaching'
        ? this.translate.instant('BY') + " " + this.report.created_by
        : this.translate.instant('COACHING_FOR') + " " + (this.report.indskr_coachingfor_value ? this.report.created_for : this.translate.instant('COACHING_UNKNOWN_USER')),
      controls: [],
    };
    if (this.coachingReportService.activeTab === 'teamCoaching') {
      if (this.hasAccessCoachingPlan) {
        this.coachingDetailsPageTitle.controls.push({
          id: 'scrap',
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('SCRAP'),
          isDisabled: this.backgroundUploadInProgress || this.report.statuscode !== 1,
          align: 'right',
        });
        this.coachingDetailsPageTitle.controls.push({
          id: 'recall',
          imgSrc: 'assets/imgs/header_reopen.svg',
          name: this.hasAccessCoachingPlan ? this.translate.instant('REOPEN') : this.translate.instant('RECALL'),
          isDisabled: this.device.isOffline || statusCode === 548910005 || statusCode === 1 || statusCode === 2,
          align: 'right',
        });
        // this.coachingDetailsPageTitle.controls.push({
        //   id: 'closecoaching',
        //   imgSrc: 'assets/imgs/header_cancel.svg',
        //   name: this.translate.instant('CLOSE'),
        //   isDisabled: this.closeDisabled,
        //   align: 'right',
        // });
        this.coachingDetailsPageTitle.controls.push( {
          id: "closecoaching",
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('COMPLETE'),
          isDisabled: this.closeDisabled,
          align: "right"
        },);
      } else {
        if (statusCode === 1 || statusCode === 548910002 || statusCode === 2) {
          this.coachingDetailsPageTitle.controls.push({
            id: 'scrap',
            imgSrc: 'assets/imgs/header_cancel.svg',
            name: this.translate.instant('SCRAP'),
            isDisabled: this.backgroundUploadInProgress || this.report.statuscode !== 1,
            align: 'right',
          });
          if (this.coachingShareFA) {
            this.coachingDetailsPageTitle.controls.push({
              id: 'share',
              imgSrc: 'assets/imgs/header_send.svg',
              name: this.translate.instant('SHARE'),
              isDisabled: this.shareDisabled,
              align: 'right',
            });
          } else {
            this.coachingDetailsPageTitle.controls.push({
              id: 'send',
              name: this.translate.instant('SEND'),
              imgSrc: 'assets/imgs/header_send.svg',
              isDisabled: this.sendDisabled || coachingFrom,
              align: 'right',
            });
          }
        }
        if (this.coachingShareFA && (statusCode == 548910003 || statusCode == 548910001 || statusCode == 4)) {
          this.coachingDetailsPageTitle.controls.push({
            id: 'send',
            name: this.translate.instant('SEND'),
            imgSrc: 'assets/imgs/header_send.svg',
            isDisabled: this.sendDisabled || coachingFrom,
            align: 'right',
          });
        }
        if (!this.coachingShareFA && (statusCode == 548910001 || statusCode == 4)) {
          this.coachingDetailsPageTitle.controls.push({
            id: 'scrap',
            imgSrc: 'assets/imgs/header_cancel.svg',
            name: this.translate.instant('SCRAP'),
            isDisabled: this.backgroundUploadInProgress || this.report.statuscode !== 1,
            align: 'right',
          },
          {
            id: 'send',
            name: this.translate.instant('SEND'),
            imgSrc: 'assets/imgs/header_send.svg',
            isDisabled: this.sendDisabled || coachingFrom,
            align: 'right',
          });
        }
        if (!this.coachingShareFA && statusCode === 548910000) {
          this.coachingDetailsPageTitle.controls.push({
            id: 'scrap',
            imgSrc: 'assets/imgs/header_cancel.svg',
            name: this.translate.instant('SCRAP'),
            isDisabled: this.backgroundUploadInProgress || this.report.statuscode !== 1,
            align: 'right',
          },
          {
            id: 'send',
            name: this.translate.instant('SEND'),
            imgSrc: 'assets/imgs/header_send.svg',
            isDisabled: this.ackDisabled,
            align: 'right',
          });
        }
        if (this.coachingShareFA && statusCode === 548910000) {
          this.coachingDetailsPageTitle.controls.push({
              id: 'send',
              name: this.translate.instant('SEND'),
              imgSrc: 'assets/imgs/header_send.svg',
              isDisabled: this.ackDisabled,
              align: 'right',
            });
        }
        if (!this.coachingShareFA && statusCode === 548910001 && !this.device.isOffline) {
          this.coachingDetailsPageTitle.controls.push({
            id: 'recall',
            imgSrc: 'assets/imgs/header_reopen.svg',
            name: this.hasAccessCoachingPlan ? this.translate.instant('REOPEN') : this.translate.instant('RECALL'),
            isDisabled: this.device.isOffline,
            align: 'right',
          });
        }
      }
      if (coachingFrom) {
        this.coachingDetailsPageTitle.controls.push({
          id: "close",
          icon: "chevron-back-outline",
          isDisabled: false,
          align: "left"
        });
      }
    }
    if (this.coachingReportService.activeTab === 'myCoaching') {
      if (statusCode === 548910002 || statusCode === 548910003 || statusCode === 3) {
        if (this.coachingShareFA) {
          this.coachingDetailsPageTitle.controls.push({
            id: 'accept',
            imgSrc: 'assets/imgs/header_complete.svg',
            name: this.translate.instant('ACCEPT'),
            isDisabled: this.acceptDisabled,
            align: 'right',
          });
        }
      }
      if (statusCode === 548910000 || statusCode === 548910001 || statusCode === 5) {
        this.coachingDetailsPageTitle.controls.push({
          id: 'acknowledge',
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('ACKNOWLEDGE'),
          isDisabled: this.ackDisabled,
          align: 'right',
        });
      }
    }
  }

  get shareDisabled(): boolean {
    return this.backgroundUploadInProgress || this.disabled || !this.report.indskr_coachingfor_value || !this.report.indskr_coachingtemplateid || this.checkMandatoryFieldsConfigFields();
  }

  get sendDisabled(): boolean {
    return this.backgroundUploadInProgress || !this.enableSend || this.report.statuscode === 548910001 || this.report.statuscode === 4;
  }

  get acceptDisabled(): boolean {
    return this.backgroundUploadInProgress || this.report.statuscode === 548910003 || this.report.statuscode === 3;
  }

  get closeDisabled(): boolean {   
    const buOveralRatingCheck = this.authService.user.buSettings['indskr_coachingoverallrating'] !== null && this.authService.user.buSettings['indskr_coachingoverallrating'] !== undefined ? this.authService.user.buSettings['indskr_coachingoverallrating']:true;
    let overRatingMandt =  buOveralRatingCheck ? !this.report.indskr_overallrating : false;
    return this.backgroundUploadInProgress || this.report.statuscode === 548910004 || this.report.statuscode === 548910005 || !this.report.indskr_coachingfor_value || !this.report.indskr_coachingtemplateid || !this.report.indskr_coachingplan || overRatingMandt || !this.isActivityFieldValid
  }

  get ackDisabled(): boolean {
    const periodEnd = moment(new Date(this.report.indskr_periodenddate), "YYYY-MM-DD", true);
    const present = moment(Date.now()).format("YYYY-MM-DD");
    return this.backgroundUploadInProgress || this.report.statuscode === 548910000 || this.report.statuscode === 5 || periodEnd.startOf('day').isAfter(present);
  }

  public onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'scrap':
        this.scrapReport();
        break;
      case 'closecoaching':
      this.closeReport();
        break;
      case 'share':
        this.shareReport();
        break;
      case 'accept':
        this.acceptReport();
        break;
      case 'send':
        this.submitReport();
        break;
      case 'acknowledge':
        this.acknowledgeReport();
        break;
      case 'recall':
        this.recallReport();
        break;
      case 'close':
        this.closepage();
        break;
      case 'save_note':
        this.handleSaveNote();
        break;
      case 'attach_note':
        this.handleNoteAttachment();
        break;
      default:
        break;
    }
  }

  public getStatusFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('STATUS'),
      inputText: this.reportStatus(this.report.statuscode),
      id: 'status-field',
      isReadOnly: true,
      isDisabled: true,
    };
    return viewData;
  }

  public getCoachingAccountFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNT'),
      inputText: this.report.indskr_account ? this.report.indskr_accountname : "",
      placeholderLabel: this.report.indskr_account ? this.report.indskr_accountname : this.translate.instant('NO_COACHING_ACCOUNT'),
      id: 'account-field',
      isReadOnly: true,
      isDisabled: true,
    };
    return viewData;
  }

  public getCoachingContactFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CONTACT_SINGULAR'),
      inputText: this.report.indskr_contact ? this.report.indskr_contactname : "",
      placeholderLabel: this.report.indskr_contact ? this.report.indskr_contactname : this.translate.instant('NO_COACHING_CONTACT'),
      id: 'contact-field',
      isReadOnly: true,
      isDisabled: true,
    };
    return viewData;
  }

  getCoachingOverAllRatingFormField(): IndFormFieldViewDataModel {
    let overAllRating = "";
    const finalRating = this.coachingReportService.overallRatings.find(e => e.value == this.report.indskr_overallrating);
    if (finalRating) overAllRating = finalRating.label;
    const buOveralRating = this.authService.user.buSettings['indskr_coachingoverallrating'] !== null && this.authService.user.buSettings['indskr_coachingoverallrating'] !== undefined ? this.authService.user.buSettings['indskr_coachingoverallrating']:true;
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('OVERALL_RATING'),
      inputText: this.report.indskr_overallrating ? overAllRating : "",
      id: 'overall-rating-field',
      isHidden:!buOveralRating,
      isReadOnly: true,
      isDisabled: this.disabled || !this.report.created_for,
      placeholderLabel: (this.isReadOnlyMode && !this.report.indskr_overallrating) ? this.translate.instant('NO_OVERALL_RATING') : this.translate.instant('OVERALL_RATING'),
      showArrow: this.report.statuscode === 1,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: !this.disabled,
      errorMessage: !this.disabled ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField: this.isClickedCoachingPlan && _.isEmpty(this.getCoachingPlanText()),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getCoachingRefNoFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('COACHING_REF_NO'),
      inputText: this.report.indskr_coachingrefno,
      placeholderLabel: this.report.indskr_coachingrefno ? this.report.indskr_coachingrefno : this.translate.instant('NO_COACHING_REF_NO'),
      id: 'ref-no-field',
      isReadOnly: true,
      isDisabled: true,
    };
    return viewData;
  }

  public getCoachingPlanFormField(): IndFormFieldViewDataModel {
    let coachingPlanName = "";
    if(this.report.indskr_coachingplanname){
      coachingPlanName = this.report.indskr_coachingplanname;
    }else{
      const coachingPlan = this.coachingReportService.coachingPlans.find(e => e.indskr_coachingplanid == this.report.indskr_coachingplan);
      if (coachingPlan) coachingPlanName = coachingPlan.indskr_name;
    }
    const viewData : IndFormFieldViewDataModel = {
      label: this.translate.instant('COACHING_PLAN'),
      inputText:  this.report.indskr_coachingplan ? coachingPlanName : '',
      placeholderLabel: (this.isReadOnlyMode && !this.report.indskr_coachingplan) ? this.translate.instant('NO_COACHING_PLAN') : this.translate.instant('COACHING_PLAN'),
      id:'coaching-plan-field',
      isReadOnly:true,
      isDisabled:this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: !this.disabled,
      errorMessage: !this.disabled ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField: this.isClickedCoachingPlan && _.isEmpty(this.getCoachingPlanText()),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    }
    return viewData;
  }

  getCoachingTypeFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('COACHING_TYPE'),
      inputText: this.report.indskr_coachingtype ? this.reportType(this.report.indskr_coachingtype) : "",
      id: 'coaching-type-field',
      isReadOnly: true,
      isDisabled: this.disabled || !this.report.created_for,
      placeholderLabel: (this.isReadOnlyMode && !this.report.indskr_coachingtype) ? this.translate.instant('NO_COACHING_TYPE') : this.translate.instant('COACHING_TYPE'),
      showArrow: this.report.statuscode === 1,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  getCoachingObervationFormField(measure: AssessmentMeasure, categoryId, measureId): IndFormFieldViewDataModel {
    let obsName = "";
    if(measure.ratingscale_id && measure.rating_label){
      obsName = measure.rating_label.toString();
    }
    // else if(measure.ratingscale_id && !isNaN(measure.rating_value) ){
    //   obsName = measure.ratings.find(x=>x.rating_value==measure.rating_value) ?measure.ratings.find(x=>x.rating_value==measure.rating_value).rating_label.toString():"";
    // }
    else{
      const obs = this.coachingReportService.observationValues.find(e => e.value == measure.observation);
      if (obs) obsName = measure.observation ? obs.label : "";
    }
    
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: "",
      inputText: obsName,
      id: 'coaching-obs-field',
      isReadOnly: true,
      isDisabled: this.disabled || !this.report.created_for,
      placeholderLabel: measure.ratingscale_id ? " " :(this.isReadOnlyMode && !measure.observation) ? this.translate.instant('NO_OBSERVATION') : this.translate.instant('OBSERVATION'),
      showArrow: this.report.statuscode === 1,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName, measure, categoryId, measureId),
    };
    return viewData;
  }

  getCoachingOverAllProcedureFormField(measure: AssessmentMeasure, categoryId, measureId): IndFormFieldViewDataModel {
    let opName = "";
    if(measure.ratingscale_id && measure.rating_label){
      opName = measure.rating_label.toString();
    }
    // else if(measure.ratingscale_id && !isNaN(measure.rating_value) ){
    //   opName = measure.ratings.find(x=>x.rating_value==measure.rating_value) ? measure.ratings.find(x=>x.rating_value==measure.rating_value).rating_label.toString():"";
    // }
    else{
      const oP = this.coachingReportService.overAllProcedureValues.find(e => e.value == measure.overallprocedure);
      if (oP) opName = oP.label;
    }
  
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: "",
      inputText: opName,
      id: 'coaching-proc-field',
      isReadOnly: true,
      isDisabled: this.disabled || !this.report.created_for,
      placeholderLabel: measure.ratingscale_id ? " " :(this.isReadOnlyMode && !this.report.indskr_coachingtype) ? this.translate.instant('NO_OVERALL_PROCEDURE') : this.translate.instant('OVERALL_PROCEDURE'),
      showArrow: this.report.statuscode === 1,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName, measure, categoryId, measureId),
    };
    return viewData;
  }

  getCoachingForFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.coachingReportService.activeTab === 'teamCoaching' ? this.translate.instant('COACHING_DETAILS_COACHING_FOR') : this.translate.instant('COACHING_DETAILS_COACHING_BY'),
      inputText: this.getCoachingForText(),
      id: 'coaching-for-field',
      isReadOnly: true,
      isDisabled: this.disabled || this.hasAccessCoachingPlan,
      customPlaceholderLabel: this.report.statuscode === 1 && !this.report.indskr_coachingfor_value ? this.translate.instant('COACHING_DETAILS_SELECT_COACHING_FOR') : '',
      showArrow: this.hasAccessCoachingPlan ? false : this.report.statuscode === 1,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isRequired: this.hasAccessCoachingPlan ? false : !this.disabled,
      errorMessage: !this.disabled ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField: this.isClickedCoachingFor && _.isEmpty(this.getCoachingForText()),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  getCoachingTemplateFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('COACHING_TEMPLATE'),
      inputText: this.report.indskr_coachingtemplateidname ? this.report.indskr_coachingtemplateidname : "",
      id: 'coaching-template-field',
      isReadOnly: true,
      isDisabled: this.disabled || !this.report.created_for,
      customPlaceholderLabel: this.report.statuscode === 1 && !this.report.indskr_coachingtemplateid ? this.translate.instant('COACHING_DETAILS_SELECT_COACHING_TEMPLATE') : '',
      showArrow: this.report.statuscode === 1,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: !this.disabled && !_.isEmpty(this.report.indskr_coachingfor_value),
      errorMessage: !this.disabled ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField: this.isClickedCoachingTemplate && _.isEmpty(this.report.indskr_coachingtemplateidname),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  getCoachingForText(): string {
    let value = '';
    if (this.report.statuscode !== 1) {
      value = this.coachingReportService.activeTab === 'teamCoaching' ? this.report.created_for : this.report.created_by;
    }
    else if (this.report.statuscode === 1) {
      value = this.report.indskr_coachingfor_value ? this.report.created_for : '';
    }
    return value;
  }

  getCoachingPlanText(): string {
    let value = '';
    if (this.report.statuscode !== 1) {
      value = this.report.indskr_coachingplan;
    }
    else if (this.report.statuscode === 1) {
      value = this.report.indskr_coachingplan ? this.report.indskr_coachingplan : '';
    }
    return value;
  }

  getCoachingDateFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('COACHING_DETAILS_COACHING_DATE'),
      inputText: this.datePipe.transform(this.report.createdon, this.dateTimeFormatsService.date, undefined, this.translate.currentLang),//format(this.report.createdon, this.dateTimeFormatsService.dateToUpper),
      id: 'status-field',
      isReadOnly: true,
      isDisabled: true,
    };
    return viewData;
  }

  getCoachingActivitiesFormField() {
    if(!this.authService.user.buSettings['indskr_coachingmakeactivitymandatory']) this.isActivityFieldValid = true;
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.hasAccessCoachingPlan ? this.translate.instant('ACTIVITY') : this.translate.instant('MEETINGS'),
      inputText: this.getCoachingActivitiesText(),
      id: 'coaching-activities-field',
      isReadOnly: true,
      isDisabled: this.device.isOffline && this.report.statuscode === 1,
      customPlaceholderLabel: this.hasAccessCoachingPlan && !this.coachingHasActivities ? this.translate.instant('COACHING_ACTIVITY') : !this.coachingHasActivities ? (this.report.statuscode === 1 ? this.translate.instant('COACHING_DETAILS_SELECT_MEETINGS') : this.translate.instant('COACHING_DETAILS_NO_MEETINGS')) : '',
      showArrow: (this.coachingHasActivities && this.report.statuscode != 1) || (this.report.statuscode === 1),
      isRequired: !this.disabled && this.authService.user.buSettings['indskr_coachingmakeactivitymandatory'],
      errorMessage: !this.disabled && this.authService.user.buSettings['indskr_coachingmakeactivitymandatory'] ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField: this.isClickedCoachingPlan && _.isEmpty(this.getCoachingActivitiesText()),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    if(!_.isEmpty(viewData.inputText)) this.isActivityFieldValid = true;
    else if(_.isEmpty(viewData.inputText) && this.authService.user.buSettings['indskr_coachingmakeactivitymandatory'] ) this.isActivityFieldValid = false;
    return viewData;
  }

  _initConfigFields() {
    this.configFields.length = 0;
    if (Array.isArray(this.authService.user.coachingConfiguredFields)) {
      this.setOrderToConfigFields();
      for (let index = 0; index < this.authService.user.coachingConfiguredFields.length; index++) {
        const configuredField: ConfiguredFields = this.authService.user.coachingConfiguredFields[index];
        if(configuredField.fieldLabel == 'Coaching Duration (in hours)') configuredField.fieldLabel = this.translate.instant('COACHING_DURATION_IN_HOURS');
        const id = getConfigFormFieldViewDataModelId(configuredField.fieldType, configuredField.fieldName);
        const { inputText, inputValue } = this.getConfigFieldInputTextAndValue(configuredField.fieldType, configuredField.fieldName);
        const formFieldType = getConfigFormFieldType(configuredField.fieldType);
        const forceReadOnly: boolean = ReadOnlyCoachingConfiguredFieldNames.includes(configuredField.fieldName);
        const isReadOnly: boolean = forceReadOnly
          || getConfigFormFieldIsReadOnly(configuredField.fieldType, configuredField.readOnly);
        const isDisabled: boolean = formFieldType === FormFieldType.POPOVER_SELECT && configuredField.readOnly
          ? true
          : (this.isReadOnlyMode || configuredField.readOnly);
        const isRequired: boolean = !this.isReadOnlyMode && configuredField.mandatory === 'true';
        const isEmptyRequiredField: boolean = this.requiredFields[id]?.isEvaluated === true
          && (inputText === '' || inputText === undefined || inputText === null);
        const field: IndFormFieldViewDataModel = {
          id,
          formFieldType,
          label: configuredField.fieldLabel,
          inputType: this.getConfigFormInputType(configuredField.fieldType),
          inputText,
          inputValue,
          placeholderLabel: configuredField.fieldLabel,
          isReadOnly,
          isDisabled,
          isRequired,
          showArrow: !this.isReadOnlyMode && !isDisabled && !forceReadOnly,
          errorMessage: isRequired ? this.activityService.getErrorMessageRequiredField() : undefined,
          isEmptyRequiredField,
          eventHandler: async (id: string, event, eventName) => this._handleConfigFieldEvent(
            id,
            event,
            eventName,
            configuredField.fieldType,
            configuredField.fieldName,
          ),
        }
        if (configuredField.fieldType == 'Decimal')
          field.inputRegEx = new RegExp(/^\d{0,10}(\.\d{0,2})|[\b]?$/);
        this.configFields.push(field);
      }
    }
  }

  getConfigFormInputType(fieldType: string): any {
    let inputType: 'number' | 'text' = 'text';
    switch (fieldType) {
      case 'Integer':
      case 'BigInt':
      case 'Currency':
      case 'Money':
        inputType = 'number';
        break;
      case 'Decimal':
        inputType = 'text';
        break;
      default:
        break;
    }
    return inputType;
  }

  private setOrderToConfigFields() {
    // sort the coachingConfiguredFields according to the order
    this.authService.user.coachingConfiguredFields.sort((a, b) => {
      if (a.order && b.order) {
        if (a.order > b.order) return 1;
        else if (a.order < b.order) return -1;
        else {
          return a.fieldLabel < b.fieldLabel ? -1 : 1;
        }
      } else if (a.order || b.order) {
        if (a.order === null) {
          return 1;
        } else if (b.order === null) {
          return -1;
        }
        if (a.order > b.order) return 1;
        else if (a.order < b.order) return -1;
        else {
          return a.fieldLabel < b.fieldLabel ? -1 : 1;
        }
      }
    });
  }

  private async _handleConfigFieldEvent(id, event, eventName, fieldType, fieldName) {
    if (id) {
      const currentValue = this.report[fieldName] ?? null;
      try {
        switch (fieldType) {
          case 'Integer':
          case 'BigInt':
          case 'Decimal':
          case 'Money':
          case 'Currency':
            if (eventName === 'input_value_change') {
              let newValue: number = null;
              if (
                event?.target?.value !== null
                && event?.target?.value !== undefined
                && event?.target?.value !== ''
                && Number(event?.target?.value) > 0
              ) {
                try {
                  if (fieldType == 'Decimal') {
                    const regEx = new RegExp(/^\d{0,10}(\.\d{0,2})?$/);
                    if (regEx.test(event.target.value)) {
                      newValue = Number(event.target.value);
                    }
                  }
                  if (fieldType == 'Integer') {
                    const regEx = new RegExp(/^[1-9][0-9]*$/);
                    if (regEx.test(event.target.value)) {
                      newValue = Number(event.target.value);
                    }
                  }
                } catch (error) {
                  console.error('_handleConfigFieldEvent: not a number: ', error, event, fieldType, fieldName);
                }
              } else {
                newValue = null;
              }
            }
            if (eventName === 'input_value_confirm') {
              let newValue: number = Number(event.target.value);
              if (currentValue !== newValue) {
                const payload = {};
                if (fieldType == 'Decimal') {
                  newValue = newValue ? Number(newValue.toFixed(2)) : null;
                }
                if (fieldType == 'Integer') {
                  newValue = newValue ? Number(newValue.toFixed(0)) : null;
                }
                payload[fieldName] = newValue;
                this.report[fieldName] = newValue;
                await this.coachingReportService.updateConfigFields(this.report, payload);
                this._updateConfigFieldInputValues(fieldType, fieldName);
              } else {
                this._configFieldMandatoryCheck(fieldType, fieldName);
              }
            }
            break;

          default:
            break;
        }
      } catch (error) {
        console.error('_handleConfigFieldEvent: ', error, event, fieldType, fieldName);
      }
    }
  }

  private _updateConfigFieldInputValues(fieldType: string, fieldName: string) {
    const fieldId = getConfigFormFieldViewDataModelId(fieldType, fieldName);
    const configFormFieldData = this.configFields.find(f => f.id === fieldId);

    if (configFormFieldData) {
      const { inputText, inputValue } = this.getConfigFieldInputTextAndValue(
        fieldType,
        fieldName
      );
      configFormFieldData.inputText = inputText;
      configFormFieldData.inputValue = inputValue;
      // Mandatory field check
      if (configFormFieldData.isRequired) {
        this.requiredFields[configFormFieldData.id] = { isEvaluated: true };
        configFormFieldData.isEmptyRequiredField = !inputText ? true : false;
      }
    }
  }

  private _configFieldMandatoryCheck(fieldType: string, fieldName: string) {
    const fieldId = getConfigFormFieldViewDataModelId(fieldType, fieldName);
    const configFormFieldData = this.configFields.find(f => f.id === fieldId);

    if (configFormFieldData && configFormFieldData.isRequired) {
      this.requiredFields[configFormFieldData.id] = { isEvaluated: true };
      configFormFieldData.isEmptyRequiredField = !configFormFieldData.inputText ? true : false;
    }
  }

  private getConfigFieldInputTextAndValue(fieldType: string, fieldName: string): { inputText: string, inputValue: any } {
    let inputText: string = '';
    let inputValue: any = null;

    if (
      this.report
      && fieldType && fieldName
    ) {
      let fieldValue = this.report[fieldName];
      inputValue = fieldValue;

      switch (fieldType) {
        case 'String':
        case 'Memo':
        case 'Currency':
        case 'Money':
        case 'Integer':
        case 'BigInt':
        case 'Decimal':
          if (!(fieldValue === undefined || fieldValue === null)) {
            inputText = '' + fieldValue;
          }
          break;

        case 'Boolean':
          inputText = fieldValue === true ? this.translate.instant('YES') : fieldValue === false ? this.translate.instant('No') : '';
          break;

        case 'DateTime':
          try {
            // Convert UTC string to timezone-independent-ish Date object (same number in local timezone)
            // so that we can display the formatted string
            const offsetDate = new Date(fieldValue);
            const tzIndependentDateObj = new Date(
              offsetDate.getUTCFullYear(),
              offsetDate.getUTCMonth(),
              offsetDate.getUTCDate(),
              offsetDate.getUTCHours(),
              offsetDate.getUTCMinutes(),
              0,
              0
            );

            inputText = fieldValue ? format(tzIndependentDateObj, this.dateTimeFormatsService.dateTimeToUpper) : '';
            inputValue = inputText;
          } catch (error) {
            console.error('getConfigFieldInputTextAndValue: DateTime type: ', error);
          }
          break;

        case 'Picklist':
        case 'Virtual':
          // inputText = this.getOptionSetValueText(fieldType, fieldName, fieldValue);
          break;

        // Unsupported
        case 'Uniqueidentifier':
        case 'Lookup':
        case 'Owner':
        case 'EntityName':
          break;
        default:
          console.error('getConfigFieldInputTextAndValue: Unhandled switch case statement: ', fieldType, fieldName);
          break;
      }
    }

    return { inputText, inputValue };
  }

  get coachingHasActivities() {
    if(this.report) {
      return !(_.isEmpty(this.report.meetings) && _.isEmpty(this.report.phonecalls) && _.isEmpty(this.report.procedureLogs))
    }
    return false;
  }

  getCoachingActivitiesText(): string {
    let value = '';
    if (!_.isEmpty(this.report.meetings) || !_.isEmpty(this.report.phonecalls) || !_.isEmpty(this.report.procedureLogs)) {
      if (_.isEmpty(this.report.phonecalls) && _.isEmpty(this.report.procedureLogs) && !_.isEmpty(this.report.meetings)) {
        value = (this.report.meetings[0].indskr_subject.toString() + (this.report.meetings.length > 1 ? (' +' + (this.report.meetings.length - 1)) : ''));
      }
      else if (_.isEmpty(this.report.meetings) && !_.isEmpty(this.report.phonecalls) && _.isEmpty(this.report.procedureLogs)) {
        value = (this.report.phonecalls[0].indskr_subject.toString() + (this.report.phonecalls.length > 1 ? (' +' + (this.report.phonecalls.length - 1)) : ''));
      }
      else if (_.isEmpty(this.report.meetings) && _.isEmpty(this.report.phonecalls) && !_.isEmpty(this.report.procedureLogs)) {
        value = ((this.report.procedureLogs[0]["procedureLogName"] ? this.report.procedureLogs[0]["procedureLogName"].toString() : this.report.procedureLogs[0].indskr_subject.toString()) + (this.report.procedureLogs.length > 1 ? (' +' + (this.report.procedureLogs.length - 1)) : ''));
      }
      else if (_.isEmpty(this.report.meetings) && !_.isEmpty(this.report.phonecalls)) {
        value = (this.report.phonecalls[0].indskr_subject.toString() + (this.report.phonecalls.length > 1 ? (' +' + (this.report.phonecalls.length - 1)) : ''));
      } else {
        const activities = _.orderBy(this.report.meetings.concat(this.report.phonecalls), a => a.indskr_subject);
        value = activities[0].indskr_subject.toString() + (activities.length > 1 ? (' +' + (activities.length - 1)) : '');
      }
    }
    if(!_.isEmpty(value)) this.isActivityFieldValid = true;
    return value;
  }

  public getCoachingStartDateField(): IndDateTimeFormViewDataModel {
    let viewData: IndDateTimeFormViewDataModel;
    viewData = {
      isDateField: true,
      label: this.translate.instant('COACHING_DETAILS_PERIOD_START'),
      customPlaceholderLabel: this.translate.instant('COACHING_DETAILS_PERIOD_START'),
      inputText: this.report.indskr_periodstartdate ?  this.datePipe.transform(this.report.indskr_periodstartdate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : '',
      fromViewPage: CurViewPageType.Coaching,
      id: DateTimeFieldType.StartDateField,
      startDateTimeValue: this.report.indskr_periodstartdate ? moment(this.report.indskr_periodstartdate).format() : '',
      endDateTimeValue: this.report.indskr_periodenddate ? moment(this.report.indskr_periodenddate).format() : '',
      isReadOnly: true,
      isDisabled: this.disabled,
      showArrow: this.report.statuscode === 1,
      isRequired: !this.disabled,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getCoachingEndDateField(): IndDateTimeFormViewDataModel {
    let viewData: IndDateTimeFormViewDataModel;
    viewData = {
      isDateField: true,
      label: this.translate.instant('COACHING_DETAILS_PERIOD_END'),
      customPlaceholderLabel: this.translate.instant('COACHING_DETAILS_PERIOD_END'),
      inputText: this.report.indskr_periodenddate ? this.datePipe.transform(this.report.indskr_periodenddate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang) : '',
      fromViewPage: CurViewPageType.Coaching,
      id: DateTimeFieldType.EndDateField,
      startDateTimeValue: this.report.indskr_periodstartdate ? moment(this.report.indskr_periodstartdate).format() : '',
      endDateTimeValue: this.report.indskr_periodenddate ? moment(this.report.indskr_periodenddate).format() : '',
      isReadOnly: true,
      isDisabled: this.disabled,
      showArrow: this.report.statuscode === 1,
      isRequired: !this.disabled,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  private handleFormFieldEvent(id, event, eventName, data?, categoryId?, measureId?) {
    if (id) {
      if(id != 'coaching-activities-field' && this.backgroundUploadInProgress) return;
      switch (id) {
        case 'coaching-for-field':
          this.openCoachingFor();
          break;
        case 'coaching-template-field':
          this.openCoachingTemplates(event);
          break;
        case 'coaching-activities-field':
          this.openActivities();
          break;
        case 'start-date-field':
          this.openStartDatePicker(event);
          break;
        case 'end-date-field':
          this.openEndDatePicker(event);
          break;
        case 'coaching-plan-field':
          this.openCoachingPlans();
          break;
        case 'coaching-type-field':
          this.openCoachingTypes(event);
          break;
        case 'overall-rating-field':
          this.openOverallRatings(event);
          break;
        case 'coaching-obs-field':
          this.openObservations(event, data, categoryId, measureId);
          break;
        case 'coaching-proc-field':
          this.openOverallProcedures(event, data, categoryId, measureId);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  private openCoachingPlans(): void {
      const listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('COACHING_PLANS'),
        dividerTitle:this.translate.instant('ALL_COACHING_PLANS'),
        isSearchEnabled: !this.isReadOnlyMode,
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: !this.isReadOnlyMode,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        orderByPropertyName: 'primaryTextRight',
        searchTitle: '',
        hideAllItemsList: false, //this.isReadOnlyMode,
        isListSelectionEnabled: !this.isReadOnlyMode,
        listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=> this._handleCoachingPlanComponentEvent(data,eventTarget,refData),
        searchHandler:(text: string) => this._handleCoachingPlanComponentSearch(text),
        data: this.coachingReportService.coachingPlans.map(c => {
          let isSelected = false;
          if(this.report && this.report.indskr_coachingplan && this.report.indskr_coachingplan === c.indskr_coachingplanid){
            isSelected = true;
          }
          return {
            id: c.indskr_coachingplanid,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: !this.isReadOnlyMode,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isSelected,
            endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
            endIconCssClass: isSelected?'checkmark-icon':'add-icon',
            primaryTextRight: c.indskr_name,
            showArrow: false,
            arrowType: ''
          };
        }),
      };
      this.navService.pushWithPageTracking(MainToolTemplateComponent,PageName.NothingSelectedView,{viewData:listDetail},PageName.MainToolTemplateComponent);
      // }
  }

  private async _handleCoachingPlanComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      await this._handledCoachingPlanSelection(eventTarget, data, refData);   
      this.relativeActivity = null;
    }

  }

  async _handledCoachingPlanSelection(event, selected, data) {
    // let coac = await this.getCoachingPlanActivity(this.report.indskr_coachingplan);
    console.log(this.relativeActivity);
    const coaching: Report = <Report>{ ...this.report };
    if (selected.selectedItems && selected.isDone && Array.isArray(selected.selectedItems)) {
      if (selected.selectedItems.length > 0) {
        this.isClickedCoachingPlan = false;
        const coachingPlan = this.coachingReportService.coachingPlans.find(e => e.indskr_coachingplanid == selected.selectedItems[0].id);
        if (coachingPlan && coachingPlan['indskr_trainee_value']) {
          const selectedTrainee = this.procedureTrackerActivityDataService.users.find(e => e.userId == coachingPlan['indskr_trainee_value']);
          coaching.indskr_coachingfor_value = selectedTrainee.userId;
          coaching.indskr_name = this.translate.instant("COACHING_FOR") + " " + selectedTrainee.userFullName + " " + this.translate.instant("ON") + " " + moment(this.report.createdon).format("MMM DD" + ", " + "YYYY");
          coaching.created_for = selectedTrainee.userFullName;
          coaching.indskr_coachingtemplateid = coachingPlan.hasOwnProperty("indskr_coachingtemplate_value")?coachingPlan["indskr_coachingtemplate_value"]:"";
          coaching.indskr_coachingtemplateidname = coachingPlan.hasOwnProperty("indskr_coachingtemplate_value@OData.Community.Display.V1.FormattedValue")?coachingPlan["indskr_coachingtemplate_value@OData.Community.Display.V1.FormattedValue"]:"";;
          coaching.indskr_periodstartdate = new Date(coachingPlan.indskr_approvaldate).getTime();
          coaching.indskr_periodenddate = new Date().getTime();
          coaching.indskr_coachingplan = coachingPlan.indskr_coachingplanid;
          coaching.indskr_coachingplanname = coachingPlan.indskr_name;
          for (var createuser of this.procedureTrackerActivityDataService.users) {
            if (createuser.userId == selectedTrainee.userId) {
              coaching.created_for_firstname = createuser.userFullName;
            }
          }
        }
        // if(this.relativeActivity){
        //   coaching.indskr_contact = this.relativeActivity["indskr_contact"];
        //   coaching.indskr_contactname = this.relativeActivity["indskr_contactname"];
        //   coaching.indskr_account = this.relativeActivity["indskr_account"];
        //   coaching.indskr_accountname = this.relativeActivity["indskr_accountname"];
        // }
      } else {
        this.isClickedCoachingPlan = true;
        coaching.indskr_coachingplan = "";
        coaching.indskr_coachingplanname = "";
        coaching.indskr_coachingfor_value = "";
        coaching.indskr_name = "";
        coaching.created_for = "";
        coaching.created_for_firstname = "";
        coaching.indskr_coachingtemplateid = "";
        coaching.indskr_coachingtemplateidname = "";
        // coaching.meetings = [];
        // coaching.procedureLogs = [];
        coaching.indskr_contact = "";
        coaching.indskr_contactname = "";
        coaching.indskr_account = "";
        coaching.indskr_accountname = "";
      }
      let response = this.coachingReportService.updateCoachingFor(coaching);
      if(response) {
        // this.coachingReportService.getAllActivity();
        this.getCoachingPlanActivity(coaching.indskr_coachingplan);
        this.isClickedCoachingTemplate = false;
        this.isClickedCoachingPlan = false;
        if(_.isEmpty(coaching.indskr_coachingplanname)) this.isClickedCoachingPlan = true;
        else this.isClickedCoachingPlan = false;
      }
    } else {
      this.isClickedCoachingPlan = true;
    }
  }

  private _handleCoachingPlanComponentSearch(text: string): string[] {
    let ids: Array<string> = [];
    if (text.length >= 1) {
      ids = this.coachingReportService.coachingPlans && this.coachingReportService.coachingPlans.filter(pro => {
          return pro.indskr_name.trim().toLowerCase().includes(text.trim().toLowerCase());
      }).map(pro => pro.indskr_coachingplanid);
    } else {
        ids = this.coachingReportService.coachingPlans && this.coachingReportService.coachingPlans.map(pro => pro.indskr_coachingplanid);
    }
    return ids;
  }

  async openStartDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.Coaching,
          startDateTimeValue: moment(this.report.indskr_periodstartdate).format(),
          endDateTimeValue: moment(this.report.indskr_periodenddate).format()
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then( async (data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.startTime !='') {
        this.periodStartDate = data.data.startTime;
        this.periodEndDate = data.data.endTime;
        const startDate: string = moment(this.report.indskr_periodstartdate).format();
          if (!isSameDay(this.periodStartDate, startDate)) {
          let payload: any = {
            "indskr_periodstartdate": moment(this.periodStartDate).startOf('day').toDate().valueOf().toString(),
            "indskr_periodenddate": moment(this.periodEndDate).endOf('day').toDate().valueOf().toString()
          };
          await this.coachingReportService.updateReport(payload, true);
        }
      }
    });
    popover.present();
  }

  async openEndDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.Coaching,
          startDateTimeValue: moment(this.report.indskr_periodstartdate).format(),
          endDateTimeValue: moment(this.report.indskr_periodenddate).format()
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then( async (data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.endTime !='') {
        this.periodEndDate = data.data.endTime;
        const endDate: string = moment(this.report.indskr_periodenddate).format();
        if (!isSameDay(this.periodEndDate, endDate)) {
          let payload: any = {
            "indskr_periodenddate": moment(this.periodEndDate).endOf('day').toDate().valueOf().toString()
          };
          await this.coachingReportService.updateReport(payload, true);
        }
      }
    });
    popover.present();
  }

  private handleNoteAttachment(){
    // if(this.isNotesAttachmentEnabled){
      try {
        (document.getElementById('file-input') as any).click();
      } catch (error) {
        console.log(error);
      }
    // }
  }

  public notesChanged(ev): void {
    if(ev && ev.target){
      this.tempNoteText = ev.target.value;
    }else{
      this.tempNoteText = '';
    }
    if(this.tempNoteText.length > 0 || this.attachmentFile){
      this.isSaveNotesEnabled = true;
    }else{
      this.isSaveNotesEnabled = false;
    }
    this.initCoachingNotesModel();
  }

  private async handleSaveNote(){
    if (this.isSaveNotesEnabled) {
      this.isSaveNotesEnabled = false;
      let offlinePayload = {
        "objecttypecode" : "coaching",
        notetext: this.tempNoteText ? this.tempNoteText : "",
        ownerid: this.authService.user.systemUserID,
        deleted: false,
        coachingReportId: this.report.indskr_coachingreportid
      };
      if(this.base64str){
        offlinePayload['isdocument'] = true;
        offlinePayload['documentbody'] = this.base64str;
        offlinePayload['filename'] = this.attachmentFile.name;
        offlinePayload['filesize'] = this.attachmentFile.size;
        offlinePayload['mimetype'] = this.attachmentFile.type;
      }else{
        offlinePayload['isdocument'] = false;
      }
      // if(!this.device.isOffline){
        await this.uiService.displayLoader();
        await this.coachingReportService.saveCoachingNotes(offlinePayload);
        this._sortCoachingNotes();
        this.tempNoteText = '';
        this.isSaveNotesEnabled = false;
        this.removeAttachment(null);
        this.initCoachingNotesModel();
        await this.uiService.dismissLoader();
      // }
    }
  }

  public async loadImageFromDevice(event){
    if(event && event.target && event.target.files){
      try {
        if((event.target.files[0].size/1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE){
          if(NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(event.target.files[0].name)){
            this.attachmentFile = event.target.files[0];
            this.attachmentTitle = event.target.files[0].name;
            this.isAttachmentAdded = true;
            this.base64str = await toBase64(this.attachmentFile);
            const dataURLPattern = /^data:[^;]+;base64,/;
            this.base64str = this.base64str.replace(dataURLPattern, '');
            this.isSaveNotesEnabled = true;
            this.initCoachingNotesModel();
          }else{
            this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'),'Opportunity Info','top','info');
            this.removeAttachment(false);
          }
        }else{
          this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION',{size:MAXIMUM_NOTE_ATTACHMENT_SIZE}),'Opportunity Info','top','info');
          this.removeAttachment(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  public isNoteControlsEnabled(note: IONote): boolean {
    return !!(note.ownerId == this.authService.user.systemUserID) && !this.device.isOffline && note.noteId != 'description-note' && !this.isReadOnlyMode;
  }

  public async updateNote(ev) {
    if (ev && ev.action) {
      let idx = this.report.annotations.findIndex(note => note.noteId == ev.noteId);
      let payload = {
        "annotationid" : this.report.annotations[idx].noteId,
        "objecttypecode" : "coaching",
        "coachingReportId" : this.report.indskr_coachingreportid
      };
      if (ev.action == 'DELETE' && idx >= 0) {
        payload['deleted'] = true;
      } else if (ev.action == 'SAVE' && idx >= 0) {
        payload['notetext'] = ev.updatedText;
        if(ev.attachmentFileUpdated){
          payload['isdocument'] = true;
          payload['documentbody'] = ev.attachmentFileDataUrl;
          payload['filename'] = ev.documentName;
          payload['filesize'] = ev.documentSize;
          payload['mimetype'] = ev.documentMimeType;
        }else if(ev.isAttachmentRemoved){
          payload['isdocument'] = false;
          payload['documentbody'] = '';
          payload['filename'] = '';
          payload['filesize'] = 0;
          payload['mimetype'] = '';
        }
      }
      // Update Service
      this._sortCoachingNotes();
      if (!this.device.isOffline) {
        await this.uiService.displayLoader();
        await this.coachingReportService.saveCoachingNotes(payload, ev, idx);
        await this.uiService.dismissLoader();
      }
    }
  }

  private _sortCoachingNotes(){
    if(this.report.annotations && this.report.annotations.length > 1){
      this.report.annotations = this.report.annotations.sort((a,b)=>{
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1);
      });
    }
  }

  sortNullsLast(arr, attr, asc) {
    return arr.sort((a, b) => {
      // equal items sort equally
      if (a[attr] === b[attr]) {
        return 0;
      }

      // nulls sort after anything else
      if (a[attr] === null) {
          return 1;
      }
      if (b[attr] === null) {
          return -1;
      }

      // otherwise, if we're ascending, lowest sorts first
      if (asc) {
          return a < b ? -1 : 1;
      }

      // if descending, highest sorts first
      return a < b ? 1 : -1;
    })
  }
  async getCoachingPlanActivity(indskr_coachingplan){
    const coachPlanActivityGroupBy = this.coachingReportService.sortCoachingPlanIdWithActivityId(this.coachingReportService.coachingPlansActivity);
    let activity = coachPlanActivityGroupBy[indskr_coachingplan];
    if(activity){
      let activeActivityId ;
      if(activity.length==1){
        activeActivityId = activity[0]["indskr_meeting_value"] || activity[0]["indskr_procedurelog_value"]
      }
      console.log(this.coachingReportService.coachingPlans);
      if(activeActivityId){
      console.log(this.coachingReportService.allActivity);
      console.log(this.report);
       await this.coachingReportService.getTeamCoachingActivities(true,indskr_coachingplan).subscribe((res) => {
          res = _.uniqBy(res, 'indskr_activityid');
          console.log(res);
          let act = res.find(x=>x.indskr_activityid == activeActivityId )
          if(act){
           return this.getActivity(act);
          } 
          // else{
          //   this.updateReport()
          // }
        });
      // let act = this.coachingReportService.allActivity.filter(x=>x.indskr_activityid == activeActivityId )
      // if(act) this.getActivity(act);
      } 
      // else{
      //   this.updateReport();
      // }
    }
    //  else{
    //   this.updateReport();
    // }  
    
  }
  updateReport(){
    this.report['indskr_contact'] = "";
    this.report['indskr_contactname'] =  "";
    this.report['indskr_account'] =  "";
    this.report['indskr_accountname'] = "";
  }

  async getActivity(selectedActivities) {
    console.log(selectedActivities);
    console.log(this.activityService.selectedActivity);

    if (selectedActivities && !this.device.isOffline ) {
      let payload: any = {
        "meetings": selectedActivities.activityType === ActivityType.Appointment ?[selectedActivities] :[],//.filter(activity => activity.activityType === ActivityType.Appointment),
        "phonecalls": selectedActivities.activityType === ActivityType.PhoneCall ? [selectedActivities] :[],//filter(activity => activity.activityType === ActivityType.PhoneCall),
        "procedureLogs": selectedActivities.activityType === ActivityType.SurgeryOrder ?[selectedActivities] :[],//filter(activity => activity.activityType === ActivityType.SurgeryOrder)
      };
      if (this.authService.hasFeatureAction(FeatureActionsMap.ACCESS_COACHING_PLAN)) {
        // if (!this.activityService.selectedActivity) {
          this.activityService.selectedActivity = null;
          await this.getActivityDetails(selectedActivities);
        // }
        if (this.activityService.selectedActivity) {
          let x = this.activityService.selectedActivity;
          if (this.activityService.selectedActivity.type === ActivityType.Appointment) {
            payload['indskr_contact'] = !_.isEmpty(this.activityService.selectedActivity['contacts']) ? this.activityService.selectedActivity['contacts'][0].ID : "";
            payload['indskr_contactname'] = !_.isEmpty(this.activityService.selectedActivity['contacts']) ? this.activityService.selectedActivity['contacts'][0].fullName : "";
            payload['indskr_account'] = !_.isEmpty(this.activityService.selectedActivity['accounts']) ? this.activityService.selectedActivity['accounts'][0].id : "";
            payload['indskr_accountname'] = !_.isEmpty(this.activityService.selectedActivity['accounts']) ? this.activityService.selectedActivity['accounts'][0].accountName : "";
          } else if (this.activityService.selectedActivity.type === ActivityType.SurgeryOrder) {
            payload['indskr_contact'] = !_.isEmpty(this.activityService.selectedActivity['customers']) ? this.activityService.selectedActivity['customers'][0].ID : "";
            payload['indskr_contactname'] = !_.isEmpty(this.activityService.selectedActivity['customers']) ? this.activityService.selectedActivity['customers'][0].fullname : "";
            payload['indskr_account'] = !_.isEmpty(this.activityService.selectedActivity['accountId']) ? this.activityService.selectedActivity['accountId'] : "";
            payload['indskr_accountname'] = !_.isEmpty(this.activityService.selectedActivity['accountNameString']) ? this.activityService.selectedActivity['accountNameString'] : "";
          }
        }
      }
      this.relativeActivity = payload ;
      await this.coachingReportService.updateReport(payload);      
      return payload;
    }
    // this.uiService.showRightPane = true;
    // this.footerService.initButtons(FooterViews.CoachingDetails);
    // await this.navService.popChildNavCtrlFromStack(ChildNavNames.CoachingActivitiesNavigation);
    // await this.navService.popWithPageTracking();
  }
  async getActivityDetails(activity: CoachingActivity) {
    let raw = {
      activityid: activity.indskr_activityid,
      activitytypecode: activity.activityType == ActivityType.PhoneCall ? ActivityTypeCodeRaw.PhoneCall : activity.activityType == ActivityType.SurgeryOrder ? ActivityType.SurgeryOrder : ActivityTypeCodeRaw.Appointment,
      actualend: activity.indskr_scheduledend,
      scheduledend: activity.indskr_scheduledend,
      actualstart: activity.indskr_scheduledstart,
      scheduledstart: activity.indskr_scheduledstart,
      subject: activity.indskr_subject,
      location: activity.indskr_location,
      statecode: activity.statecode
    };
    const  rawActivity = activity.activityType == ActivityType.PhoneCall ? new PhoneActivity(raw) : activity.activityType == ActivityType.SurgeryOrder ? new OrderActivity(raw) : new AppointmentActivity(raw);
    let foundActivity = this.activityService.getActivityByID(rawActivity.ID);
    this.activityService.selected = (foundActivity) ? foundActivity : rawActivity;
    await this.activityDataService.updateActivityDetails(this.activityService.selectedActivity);
  }

}
