import { CustomerAsset } from "@omni/classes/field-materials/customer-asset.class";
import { FieldMaterialManagementService } from "@omni/services/field-materials/field-material-management.service";
import {UpdateMeetingPayload} from './../../../data-services/meeting/meeting.data.service';
import {AccountDTO, AccountGlobalSearchDTO, AccountsModel} from './../../../classes/account/account.class';
import {ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY} from './../../../config/dynamic-forms/affiliations-contants';
import {skip, takeUntil} from 'rxjs/operators';
import fuse from 'fuse.js';
import {SearchConfigDataService} from './../../../data-services/search-config/search-config-data-service';
import {IonContent, IonList, IonNav, LoadingController, ModalController, NavParams, PopoverController} from '@ionic/angular';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Account} from '../../../classes/account/account.class';
import {AccountOfflineService, AccountSelectedFor, AccountSelectionObservableModel } from '../../../services/account/account.offline.service';
import {ComponentViewMode, UIService} from '../../../services/ui/ui.service';
import {ActivityService} from '../../../services/activity/activity.service';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {ChangeEvent} from '../../angular-virtual-list';
import {DeviceService} from '../../../services/device/device.service';
import {MeetingDataService} from '../../../data-services/meeting/meeting.data.service';
import {AppointmentActivity} from '../../../classes/activity/appointment.activity.class';
import {AccountDetailsComponent} from '../account-details/account-details';
import {FooterService, FooterViews} from '../../../services/footer/footer.service';
import {TrackingEventNames, TrackService} from '../../../services/logging/tracking.service';
import {CallPlanOfflineService} from '../../../services/call-plan/call-plan.offline.service';
import {AccountDataService} from '../../../data-services/accounts/account.data.service';
import {NavigationService, PageName} from '../../../services/navigation/navigation.service';
import {RepServices} from '../../../data-services/rep/rep.services';
import {ActivityDataService} from '../../../data-services/activity/activity.service';
import {ActivityType} from '../../../classes/activity/activity.class';
import {SchedulerService} from '../../../services/scheduler/scheduler.service';
import {DeltaService} from '../../../data-services/delta/delta.service';
import {FollowUpActivityDataService} from '../../../data-services/follow-up-activity/follow-up-activity.data.service';
import {FollowUpActivity, FOLLOW_UP_TYPE} from '../../../classes/activity/follow-up-action.activity.class';
import {
  AccesingMode,
  CaseManagementService,
  DETAILS_OPTION
} from '../../../services/case-management/case-management.service';
import {EventName, EventsService} from '../../../services/events/events.service';
import {OrderActivity} from '../../../classes/activity/order.activity.class';
import {OrderActivityDataService} from '../../../data-services/order-activity/order-activity.data.service';
import {ConfirmationAlertData} from '../../../classes/shared/confirmation-alert-data.class';
import {AccountManagementOfflineService} from '../../../services/account-management/account-management.service';
import {ContactOfflineService, CustomerTagStateCode} from '../../../services/contact/contact.service';
import {TranslateService} from '@ngx-translate/core';
import {OpportunityManagementService} from '../../../services/opportunity-management/opportunity-management.service';
import {Opportunity, OpportunityPrimaryTraget} from '../../../classes/opportunity-management/opportunity.class';
import {Contact, SelectableLinkedEntity} from '../../../classes/contact/contact.class';
import {IndKeyControlsAreaModel} from './../../../models/indKeyControlsAreaModel';
import * as _ from 'lodash';
import {MultiSelectPopover} from '../../../components/multi-select-popover/multi-select-popover';
import {SearchConfigService} from '../../../services/search/search-config.service';
import {IndSectionHeaderViewDataModel} from '../../../models/indSectionHeaderDataModel';
import {MainCardViewDataModel} from '../../../models/MainCardViewDataModel';
import {
  SelectedSuggestionPillDataModel,
  SuggestionPillType,
  UserSavedSearchTypes
} from '../../../models/search-config-data-model';
import {DB_KEY_PREFIXES} from '../../../config/pouch-db.config';
import {DiskService, OFFLINE_DB_LINKED_ENTITY_NAME} from '../../../services/disk/disk.service';
import {AlertService} from '../../../services/alert/alert.service';
import {DateTimeFormatsService} from '../../../services/date-time-formats/date-time-formats.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {FeatureActionsMap} from '../../../classes/authentication/user.class';
import {DynamicFormComponentModel, DynamicFormType} from '../../../models/dynamic-form-component.model';
import {DynamicFormsService} from '../../../services/dynamic-forms/dynamic-forms-service';
import {ControlDataType, DynamicForm, FormType, BusinessProcessType} from '../../../classes/dynamic-form/dynamic-form.class';
import {defaultAccountCreateForm} from '../../../config/dynamic-forms/default-account-create';
import {NotificationService, ToastStyle} from '../../../services/notification/notification.service';
import {DynamicFormComponent} from '../../shared/ind-dynamic-form/ind-dynamic-form';
import {PhoneActivity} from '../../../classes/activity/phone.activity.class';
import {PhoneCallDataService} from '../../../data-services/phone-call/phonecall.data.service';
import {IndTabsDataModel} from "@omni/models/ind-tabs-data-model";
import {IndHeaderLeftDataModel} from "@omni/models/indHeaderLeftDataModel";
import {NewChangeRequestComponent} from "@omni/components/mdm/new-change-request/new-change-request";
import {SecInfoConfigDataService} from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import {SecondaryInfoEntityName} from '@omni/classes/sec-info-config/sec-info.class';
import {
  ChangeRequestStatusCode,
  ChangeRequestType,
  MDMRequestType,
  MDMType
} from "@omni/classes/mdm/source-type-optionset.class";
import {MdmService} from "@omni/services/mdm/mdm.service";
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {EventsToolService} from '../../../services/events-tool/events-tool.service';
import {REP_STATUS} from '@omni/models/rep-status-model';
import {getSearchSuggestionsData} from '../../../utility/global-search.utility';
import {CaseActivity} from '@omni/classes/case-intake/case-activity.class';
import {
  COD_BASE,
  OKEntityType,
  OKWorkplaceSearchKeys,
  OmnipresenceAccountSearchKeys,
  SEARCH_OPERATOR
} from "@omni/classes/onekey/ok-searchkeys.class";
import {OnekeyApiService} from "@omni/services/onekey/onekey-api.service";
import {format} from "date-fns";
import {NothingSelectedView} from "@omni/components/shared/nothing-selected-view/nothing-selected-view";
import { ContentMatchingService } from '@omni/services/content-matching/content-matching.service';
import { ScientificPlanFollowUpTask } from '@omni/components/activity/scientific-activity/scientific-plan-task/scientific-plan-task';
import { FollowUpActionDetailComponent } from '@omni/components/activity/follow-up-action-detail/follow-up-action-detail';
import {fetchQueries} from "@omni/config/dynamics-fetchQueries";
import {DynamicsClientService} from "@omni/data-services/dynamics-client/dynamics-client.service";
import { AgendaFooterService, AgendaFooterView } from '../../../services/footer/agenda-footer.service';
import { Action, MarketScan } from '@omni/classes/market-scan/market-scan.class';
import { VeevaWidgetService } from "@omni/services/veeva-widget/veeva-widget.service";
import { MarketScanService } from '@omni/services/market-scan/market-scan.service';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { AccountPlan, AccountPlanMode } from "@omni/classes/account-management/account-plan.class";
import { SurgeryOrderActivity } from "@omni/classes/activity/surgery-order.activity.class";
import { MarketingPlansManagementOfflineService } from "@omni/services/marketing-management/marketing-management.service";
import { NOTIFICATION, MyAssistantService } from '@omni/services/my-assistant/my-assistant.service';
import { QuotesService } from "@omni/services/quote/quote-tool.service";
import { QuoteToolDataService } from "@omni/data-services/quote-tool/quote-tool-data.service";
import { EntityTag, TagEntityType, TagStateCode, TagVisibility, UserTag, UserTagService } from "@omni/services/user-tag/user-tag.service";
import { PopoverComponent } from "@omni/components/popover/popover";
import { IndFilterMenuComponent } from '@omni/components/shared/ind-filter-menu/ind-filter-menu';
import { TerritoryManagementService } from "@omni/services/territory-management/territory-management.service";
import { ContactDataService } from "@omni/data-services/contact/contact.data.service";
import { EmailService } from '@omni/services/email-templates/email.service';
import { EmailDataService } from '@omni/data-services/email/email.data.service';
import { EmailActivity } from '@omni/classes/activity/email.activity.class';
import { AssessmentTemplate, SurveyFrequency, SurveyStatus } from "@omni/classes/customer-assessment/assessment-template.class";
import { CustomerAssessment } from "@omni/classes/customer-assessment/customer-assessment.class";
import { CustomerSurveyService } from "@omni/services/customer-survey/customer-survey.service";
import { ProcedureContractService } from "@omni/data-services/procedure-contract/procedure-contract.service";

import { LocationOfflineService } from "@omni/services/location/location.service";
import { FeatureActionsService } from "@omni/services/feature-actions/feature-actions.service";
import { AssessmentAttributeType, EntityOptions } from "@omni/classes/customer-assessment/assessment-template.class";
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from "@omni/models/mainToolTemplateDetail.model";
import { MainToolTemplateComponent } from "@omni/components/shared/main-tool-template/main-tool-template";
import { SetBookingDataService } from "@omni/data-services/set-booking/set-booking.data.service";
import { SelectionConfig } from "@omni/interfaces/shared/shared.interface";
import { ContactPageComponent } from "@omni/components/contact/contact-page/contact-page";
/**
 * Generated class for the AccountListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'account-list',
  templateUrl: 'account-list.html',
  styleUrls:['account-list.scss']
})
export class AccountListComponent implements OnInit, AfterViewInit {

  private readonly MIN_SEARCH_LENGTH = 1;
  public searching: boolean = false;
  public compViewMode = ComponentViewMode;
  public allAccounts:Account[];
  public accounts: Account[];
  public newAccounts: Account[] = [];
  public isDirty: boolean = false; //to enable or desable done butto if data changed
  scrollItems: Account[];
  filteredList: Account[] = [];
  visibleFilteredList: Account[] = [];
  accountsData$ = new BehaviorSubject<Account[]>(undefined); //observable account stream
  indices: ChangeEvent = { start: 0, end: 0 };
  private recordCount: number = 30;
  private filteredRecordCount: number = 30;
  @Input() accountListMode: string;
  @Input() accountIdfromIndDynamic: any;
  @Input() accountListAccessedFrom: string;
  private currentSchdeuleAccount: Account[] = [];
  expandAll: boolean = false;
  resetExpandAll: boolean = false;
  public accountsListKeyControlAreaModel:IndKeyControlsAreaModel;
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() selectAccount = new EventEmitter<any>();
  @Output() createNewOpportunity = new EventEmitter<any>();
  @Output() createNewAccount = new EventEmitter<any>();
  @Input() from: any;

  // Only being used by this.accountService.selectedFor == AccountSelectedFor.CONTACT_ACCOUNT_SELECTION
  // Maybe should be used by all single select
  public singleSelect: boolean = false;

  @Input() paramAccount: string; // If account id is passed to this component

  public enableCreateAccount: boolean = false;
  public accountsCreateRequest = false;
  public businessCreateChangeRequest = false;
  public businessAccountCreateRequest = false;
  schedulerSelectionSubscription: Subscription;
  ICase: Subscription;
  private holdSelectedAccountInfo: Account;
  private ngDestroy$ = new Subject<boolean>();
  public affiliatedAccounts:Account[] = [];
  public pagename = PageName;
  sortPopoverData:any;
  sortBy:{text: string, value: string, asc: boolean}
  suggestionsActive: boolean = false;
  shouldFireSearchChangeEvent: boolean = true;
  suggestionsData: {
    header:IndSectionHeaderViewDataModel,
    listData: MainCardViewDataModel[]
  }[] = [];
  //search key to be passed to search-suggestion-popover
  searchKey : string = '';
  //array of user selected facets
  selectedSuggestionsData:  SelectedSuggestionPillDataModel[] = [];
  disableSaveSearch: boolean = false;
  accountSearchText: string;
  private _showDiscardConfirmationForAccountForm:boolean = false;
  private _callbackOnDone;
  private _holdDynamicFormIsDirtyTracker:boolean;
  @Input() callbackEvent: any;
  @Input() selectedAccountId: any;
  @Input() selectedAccountIds: any = [];
  @Input() selectedContactIds: any = [];
  @Input() selectionConfigs: SelectionConfig;
  mobileNumberList: any[];
  public selectedAccountsHeaderModel: IndSectionHeaderViewDataModel;
  public nearByAccountsHeaderModel: IndSectionHeaderViewDataModel;
  public selectedOKAccountsHeaderModel: IndSectionHeaderViewDataModel;
  public allAccountsHeaderModel: IndSectionHeaderViewDataModel;
  public resultsAccountsHeaderModel: IndSectionHeaderViewDataModel;
  public myAccountChangeRequestsHeaderModel: IndSectionHeaderViewDataModel;
  tabsData: IndTabsDataModel[];
  tabsComponentData: IndTabsDataModel[];
  public accountsModel: AccountsModel = AccountsModel.MY_ACCOUNTS_TAB;
  public componentModel: string = 'account';
  public currentTab: String;
  public currentComponent: string;
  indHeaderLeftModel: IndHeaderLeftDataModel
  @ViewChild(IonList, { read: ElementRef, static: false }) list: ElementRef;
  enableGlobalSearch: boolean = false;
  enableVeevaSearch: boolean = false;
  enableVeevaMyRequests: boolean = false;
  enableOneKeySearch: boolean = false;
  enableOneKeyDownload: boolean = false;
  enableOneTimeVeevaMeeting = false;
  globalSearchedAccountsHeaderModel: IndSectionHeaderViewDataModel;
  oneKeySearchedAccountsHeaderModel: IndSectionHeaderViewDataModel;
  public accountCRs = [];
  IaccountCRsSubscription: Subscription;
  public hideFilterButton: boolean = false;
  public filteredAccountCRs = []
  public enableChangeRequestTab: boolean = false;
  filterPopoverData: { text: string; value: string; items: any; handler: (selectedItem: any, item: any, parent: any) => void; }[];
  private changeReqStatusFilter: number = null;
  private changeReqTypeFilter: number = null;
  private changeReqSourceFilter:number = null;
  public isAccessedFromActiveMeeting: boolean = false;
  private isDeviceOnline: boolean = true;
  private isOpportunityUserProvided:boolean = false;
  private searchedByOKID: boolean = false;
  private linkEntitiesReqPayload: any = [];
  public isAssessmentFilter: boolean = false;

  public isAccountTagSelectEnabled:boolean = false;
  private _selectedAccountsForTag:Array<Account> = [];
  private tagListPopover:any;
  private IUserTagDeletedSubscription: Subscription;
  public selectedTagData: UserTag;
  public showRemoveTag:boolean = false;

  private currentAccountPlanOpportunities:any;
  @ViewChild('content', {static:true}) ionContent: IonContent;

  public selectedFilterData: SelectedSuggestionPillDataModel[] = [];
  public filtering: boolean = false;
  public filterNameBubble: { categoryName: string; suggestion: SelectedSuggestionPillDataModel[]}[] = [];
  private isFilteredAccountCRs: boolean = false;
  private allowAffiliatedHCOSelectionOnly:boolean = false;
  public isSingleSelectionHCOHCPEnabled:boolean = false;
  public listPeriodTags : string[] = [];
  private accountVisitRecordCheckResponse;
  public nearByAccounts: Account[] = [];
  public nearByAccountsToDisplay: Account[] = [];

  public selectablLinkedEntityData: Array<SelectableLinkedEntity> = [];
  public hasSecondaryInfo: boolean = false;

  public newOpportunityContactPrimaryTargetFlow:boolean = false;

  public isAddAffiliatedContactsOnAccountAddEnabled:boolean = false;
  public hasComponentChangeTab: boolean = false;
  public accountListData: any;
  public isOpportunityStakeholderValueEnabled: boolean = false;
  public opportunityStakeholderSelectionEnabled:boolean = false;

  constructor(
    public accountService: AccountOfflineService,
    private callPlanService: CallPlanOfflineService,
    private activityService: ActivityService,
    private emailService: EmailService,
    private emailDataService:EmailDataService,
    private activityDataService: ActivityDataService,
    public device: DeviceService,
    private meeting: MeetingDataService,
    public footerService: FooterService,
    public uiService: UIService,
    private trackingService: TrackService,
    public accountDataService: AccountDataService,
    private navService: NavigationService,
    public repService: RepServices,
    private loadingController: LoadingController,
    private schedulerService: SchedulerService,
    private deltaService: DeltaService,
    private followUpActivityDataService: FollowUpActivityDataService,
    private caseManagementService: CaseManagementService,
    private eventService: EventsService,
    private OrderActivityDataService: OrderActivityDataService,
    public navCtrl: IonNav,
    public _cd: ChangeDetectorRef,
    private events: EventsService,
    private accountManagementOfflineService: AccountManagementOfflineService,
    private contactService: ContactOfflineService,
    public translate : TranslateService,
    private opportunityMgmtService: OpportunityManagementService,
    private fieldMaterialManagementService: FieldMaterialManagementService,
    private popover: PopoverController,
    public searchConfigService: SearchConfigService,
    public contactListElement: ElementRef,
    public disk: DiskService,
    public alertService: AlertService,
    public searchConfigDataService: SearchConfigDataService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private authService: AuthenticationService,
    private dynamicFormService: DynamicFormsService,
    private notificationService: NotificationService,
    private phoneCallDataService : PhoneCallDataService,
    private navParams: NavParams,
    private secondaryInfoService: SecInfoConfigDataService,
    public mdmService: MdmService,
    private modalCtrl:ModalController,
    private loadingCtrl: LoadingController,
    private iab: InAppBrowser,
    private authenticationOfflineService: AuthenticationService,
    private eventsToolService: EventsToolService,
    public onekeyApiService: OnekeyApiService,
    private contentMatchService: ContentMatchingService,
    public dynamics: DynamicsClientService,
    private veevaWidgetService: VeevaWidgetService,
    private agendaFooterService: AgendaFooterService,
    private marketScanService: MarketScanService,
    private customerAssessService: CustomerAssessService,
    public opportunityService: OpportunityManagementService,
    public myAssistantService: MyAssistantService,
    private marketingManagementOfflineService:MarketingPlansManagementOfflineService,
    private quotesToolService: QuotesService,
    private quoteToolDataService : QuoteToolDataService,
    private readonly userTagService: UserTagService,
    private readonly territoryService: TerritoryManagementService,
    private contactDataService: ContactDataService,
    private customerSurveyService: CustomerSurveyService,
    private contractService : ProcedureContractService,
    private locationService: LocationOfflineService,
    private faService: FeatureActionsService,
    private setBookingDataService: SetBookingDataService
  ) {
    this.device.isOfflineObservable.pipe(skip(1), takeUntil(this.ngDestroy$)).subscribe(isOffline => this._initKeyControlsAreaModel());

    this.eventService.observe(EventName.NEWOPPORTUNITYCONFIRMED).pipe(takeUntil(this.ngDestroy$)).subscribe((data: Opportunity) => {
      if (this.accountListAccessedFrom == 'OpportunityManagementPage' && data && data.accountID) {
        this.accountService.createOpportunitiesAccountsTree(this.opportunityMgmtService.opportunities);
        this.allAccounts = this.accountService.accountsTreeForOtherTool;
        this.accounts = this.allAccounts.slice(0, this.recordCount);
        let selectedAccount = this.accountService.getAccountById(data.accountID);
        this.accountService.selected = selectedAccount;
        if (selectedAccount.parentAccountID && !this.expandAll && this.allAccounts.some(account => account.id == selectedAccount.parentAccountID)) {
          this.expandAll = true;
        }
      }
    });

    this.eventService.observe('no-opportunity-for-account').pipe(takeUntil(this.ngDestroy$)).subscribe(() =>{
      this.accountService.selected = undefined;
      this.accountService.createOpportunitiesAccountsTree(this.opportunityMgmtService.opportunities);
      this.allAccounts = this.accountService.accountsTreeForOtherTool;
      this.accounts = this.allAccounts.slice(0, this.recordCount);
    })

    this.eventService.observe(EventName.ACCOUNTCREATEDUPDATEDFROMAPP).pipe(takeUntil(this.ngDestroy$)).subscribe((data: Account) => {
      if (this.accountListAccessedFrom == 'AccountPage' && data && data.id) {
        this._showDiscardConfirmationForAccountForm = false;
        this._updateAccountsListAfterUpdateOrCreate(data)
        this.openAccountDetails(data,false, !this.accountService.refreshUIofCurrentSelectedAccountFlag);
      }
    });

    this.eventService.observe(EventName.ACCOUNTFORMISDIRTYEVENT).pipe(takeUntil(this.ngDestroy$)).subscribe((data:boolean) => {
      if (this.accountListAccessedFrom == 'AccountPage') {
        this._showDiscardConfirmationForAccountForm = data;
      }
    });

    this.eventService.observe(EventName.ACCOUNTEDITPAGEACTIVE).pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      this._initKeyControlsAreaModel();
    });

    this.eventService.observe(EventName.ACCOUNTUPDATEINTERACTION).pipe(takeUntil(this.ngDestroy$)).subscribe((data: Account) => {
      this._updateAccountInteractions(data);
    });

    this.eventService.observe(EventName.OPEN_SELECTED_ACCOUNT_DETAILS).pipe(takeUntil(this.ngDestroy$)).subscribe((data: Account) => {
      this.accountListItemClicked(data);
    });

    this.device.onResumeObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      // Retry after coming back from the location setting page
      if (this.locationService.leftToLocationSettingFrom === 'accountList') {
        this.refreshNearByAccounts();
      }
    });

    this.IUserTagDeletedSubscription = this.userTagService.userTagDeletedObserver.subscribe(isTagDeleted => {
      if (isTagDeleted) {
        this.cancelTagSelection();
      }
    });

    if (this.callbackEvent) {
      this._callbackOnDone = this.callbackEvent;
    }

    if (this.navParams) {
      console.log(this.navParams);
      if (this.navParams.get('callbackEvent')) {
        this._callbackOnDone = this.navParams.get('callbackEvent');
      }
      if(this.navParams.data && this.navParams.data.accountListData) {
        this.accountListData = this.navParams.data.accountListData;
      }
    }
    this.IaccountCRsSubscription = this.mdmService.accountCRsObs$.subscribe((data: any[]) => {
      if (this.currentTab == AccountsModel.CHANGE_REQUESTS_TAB && data.length) {
        this.accountCRs = data.filter(cr => cr.statuscode != '548910007');
        if(!this.isFilteredAccountCRs) this.filteredAccountCRs = [...this.accountCRs];
        this._cd.detectChanges();
      }
    });
  }

  updateEmptyMessage(){
    let dataSize = this.allAccounts.length
    if(this.searching || this.filtering){
      dataSize = this.filteredList.length
    }
    if(dataSize <= 0){
      dataSize = this.accountService.selectedAccounts? this.accountService.selectedAccounts.length : 0
    }
    if (this.currentTab == AccountsModel.CHANGE_REQUESTS_TAB) {
      dataSize = this.filteredAccountCRs.length;
    }
    this.uiService.updateNothingSelectedScreenMessageFor(dataSize)
  }

  private _sortAccounts(accounts: Array<Account>): Array<Account> {
    if (this.translate.currentLang == 'zh_CN') {
      return accounts.sort((a, b) => a['accountName'].localeCompare(b['accountName'], ["zh-CN-u-co-pinyin"]));
    } else {
      return accounts.sort((accountA, accountB): number => {
        return (accountA.accountName.toLowerCase() < accountB.accountName.toLowerCase() ? -1 : 1);
      });
    }
  }

  ngOnInit(){
    this.initNearByAccounts();
    this.accountVisitRecordCheckResponse = this.activityService.selectedActivity instanceof AppointmentActivity ? this.activityService.accountVisitRecordCheck(this.activityService.selectedActivity) : undefined;
    this.isAccessedFromActiveMeeting = (this.accountService.accessedAccountListFrom == PageName.ActivitiesDetailsPaneComponent || this.accountService.accessedAccountListFrom == PageName.SurgeryOrderDetailsComponent) && this.accountListMode == this.compViewMode.ADDNEW;
     // This param used to get the page where user came from
     if((this.navParams && this.navParams.data && this.navParams.get('from') && this.navParams.get('from') === 'AccountTool') ||this.isAccessedFromActiveMeeting) {
      this.enableChangeRequestTab = true;
    }
    if (this.navParams && this.navParams.data && this.navParams.get('openedToolFromAccountDetails') && (this.navParams.get('openedToolFromAccountDetails') === true)) {
      this.dynamicFormService.isOpenedAffiliatedAccountOnContact = false;
      this.dynamicFormService.isOpenedAffiliatedAccountOnAccount = false;
      this.accountService.isEnableGoToAccountsTool = true;
      this.contactService.isEnableGoToContactsTool = true;
      this.uiService.selectedOptionInMenu = 'accounts';
      this.uiService.showNewActivity = false;
      this.uiService.prevView = this.uiService.activeView
      this.uiService.activeView = 'accountDetails';
      //this.uiService.accountDataSegment = 'info';
      this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
      this.accountService.accessedAccountListFrom = PageName.ActivitiesPageComponent;
    }
    if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SINGLE_SELECTION_OF_HCO_AND_HCP)){
      this.isSingleSelectionHCOHCPEnabled = true;
    }
    this.businessAccountCreateRequest = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_CREATOR);
    this.accountsCreateRequest = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_CREATE_REQUEST);
    this.businessCreateChangeRequest = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_CHANGE_REQUESTS);
    this.enableCreateAccount = this.businessAccountCreateRequest || this.accountsCreateRequest || this.businessCreateChangeRequest;
    this.enableGlobalSearch = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_GLOBAL_SEARCH);
    this.isOpportunityUserProvided = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_REVENUE_USER_PROVIDED);
    this.enableOneTimeVeevaMeeting = !!this.authService.user.buSettings.indsyn_onetimeveevameeting;
    if (this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_VEEVA_GLOBAL_SEARCH)) {
      this.enableVeevaSearch = true;
      // this.veevaWidgetService.loadVeevaScripts();
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_VEEVA_MY_REQUESTS)) {
      this.enableVeevaMyRequests = true;
      // this.veevaWidgetService.loadVeevaScripts();
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.ONEKEY_ACCOUNT_SEARCH)) {
      this.enableOneKeySearch = true;
      this.onekeyApiService.okSearchResult.subscribe((res) => {
        this.onekeyApiService.oneKeyAccounts = res;
      });
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.ONEKEY_ACCOUNT_DOWNLOAD)) {
      this.enableOneKeyDownload = true;
    }
    this.isOpportunityStakeholderValueEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_STAKEHOLDER_VALUE);

    this.isAddAffiliatedContactsOnAccountAddEnabled = this.authService.user.buConfigs['indskr_addaffiliatedcontactsonaccountadd'];

    // this.uiService.toolsActivityActive = true;
    this.accountService.resetGlobalSearch();
    this.IaccountCRsSubscription = this.mdmService.accountCRsObs$.subscribe((data: any[]) => {
      if (data.length) {
        this.accountCRs = data.filter(cr => cr.statuscode != '548910007');
        if(!this.isFilteredAccountCRs) this.filteredAccountCRs = [...this.accountCRs];
        this._cd.detectChanges();
      }
    });
     this.initData();
    this.initAccountsHeaderLeft();
    if(this.accountListData && this.accountListData.for == 'OpportunityStakeholderSelection' && (this.accountService.accessedAccountListFrom == PageName.OpportunityDetailsComponent || this.accountService.accessedAccountListFrom == PageName.NewOpportunityComponent)) {
      this.hasComponentChangeTab = true;
      this.setComponentTabsData();
    }
    this.setTabsData();
    this._initFilterPopoverData();

    this.eventService.observe('device:deviceIsOffline').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((status) => {
        this.isDeviceOnline = false;
        this.updateDoneButtonStatusOnDeviceStatus();
      });

    this.eventService.observe('device:deviceIsOnline').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((status) => {
        if (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState) {
          this.isDeviceOnline = false;
        }
        else {
          this.isDeviceOnline = true;
        }
        this.updateDoneButtonStatusOnDeviceStatus();
      });

    setTimeout(() => {
      this.uiService.scrollListToView(this.list);
      this._initAllSectionHeaderModel();

    }, 200);
    this.events.publish('refreshDisplayForm');
    this.events.observe("highlightAccount").pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(async (account: Account) => {
        if (this.accountService.accountPageMode != ComponentViewMode.PREVIEW) this._highlightAccount(account);
      });
      this.onDefaultFilterLoad();
    }


  async ngAfterViewInit() {
    if (this.accountService.isClickedGoToAccountsTool && this.navParams && this.navParams.data && this.navParams.get('from') && this.navParams.get('from') === 'AccountTool') {
      if (this.navParams.get('openedToolFromCrDetails') && this.navParams.get('openedToolFromCrDetails') === true) {
        const selectedAccountCrId = this.navParams.get('accountCrId') || '';
        if (selectedAccountCrId && this.filteredAccountCRs) {
          this.accountService.selectedAccountCr = this.filteredAccountCRs.find(cr => cr.indskr_accountcrid == selectedAccountCrId);
        }
        setTimeout(() => {
          this.accountsModel = AccountsModel.CHANGE_REQUESTS_TAB;
          this.checkAccountModel(AccountsModel.CHANGE_REQUESTS_TAB);
        }, 0);
      } else {
        const curDisplayedAccount = this.accountService.selected;
        if (!this.device.isMobileDevice) this._highlightAccount(curDisplayedAccount, true);
        this.accountService.isClickedGoToAccountsTool = false;
        this.dynamicFormService.isOpenedAffiliatedAccountOnContact = false;
        this.dynamicFormService.isOpenedAffiliatedAccountOnAccount = false;
        setTimeout(() => {
          this.navService.setChildNavRoot(AccountDetailsComponent, PageName.AccountDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTool', 'listMode': this.accountListMode });
          this.navService.setChildNavRightPaneView(true);
        }, 0);
      }
    }
  }

  private updateDoneButtonStatusOnDeviceStatus() {
    if (this.accountService.isSchedulerInvoked)
      this.indHeaderLeftModel.controls.find(c => c.id === 'done').isDisabled = !this.isDeviceOnline;
  }

   async initData(){

    //this.disableSearchBar();

    if (this.accountListAccessedFrom == 'AccountManagementPage') {
      this.hideFilterButton = true;
      this.accountService.createAccountplanAccountsTree(this.accountManagementOfflineService.accountPlans);
      this.allAccounts = this.accountService.accountsTreeForOtherTool;
    }else if(this.accountListAccessedFrom == 'AccountPage' && this.accountService.isMarketingBusinessPlanInvoked) {
      this.hideFilterButton = true;
      const selectedMarketingPlan = this.marketingManagementOfflineService.selectedMarketingPlan$.getValue();
      let selectableOpportunityAccounts = selectedMarketingPlan.accounts || [];
      if(!_.isEmpty(selectableOpportunityAccounts)) {
        this.allAccounts = [];
        selectableOpportunityAccounts.forEach(selAccount=>{
          let selectableAccount = this.accountService.getAccountById((selAccount.id));
          if(selectableAccount){
            this.allAccounts.push(selectableAccount);
          }
        });
      }else {
        this.allAccounts = this.accountService.accountsTree.slice();
      }
    } else if (this.accountListAccessedFrom == 'OpportunityManagementPage') {
      this.hideFilterButton = true;
      //if(this.opportunityMgmtService.preSelectedAccount) this.openAccountDetails(this.opportunityMgmtService.preSelectedAccount)
      this.accountService.createOpportunitiesAccountsTree(this.opportunityMgmtService.opportunities);
      this.allAccounts = this.accountService.accountsTreeForOtherTool;
    } else if(this.accountService.accessedAccountListFrom == PageName.DynamicFormComponent && this.accountService.isOneKeyAffiliatedToAccount){
      this.accountService.createOneKeyAccountsTree(this.accountService.oneKeyAccountRawData);
      this.allAccounts = this.accountService.oneKeyAccountsTree.slice();
    }else if(this.accountListAccessedFrom == 'MarketingPlanManagementPageComponent'){
      this.hideFilterButton = true;
      this.accountService.createMarketingplanAccountsTree(this.marketingManagementOfflineService.marketingPlans);
      this.allAccounts = this.accountService.accountsTreeForOtherTool;
    } else{
      this.allAccounts = this.accountService.accountsTree.slice();
      if (this.accountService.accessedAccountListFrom == PageName.ContactAcountAffiliationComponent) {
        let tempContact = this.contactService.tempContactInformation;
        if(tempContact && tempContact.accountRelationships && tempContact.accountRelationships.length > 0) {
          this.allAccounts = this.allAccounts.filter(acc => !tempContact.accountRelationships.some(x => x.accountId == acc.id));
        }
      }
      if (this.accountListData && (this.accountListData.for == 'OpportunityStakeholderSelection' || this.accountListData.for == 'NewOpportunityContactSelection') && this.accountListData.opportunity) {
        if((this.accountListData.opportunity as Opportunity).accountID){
          let accounts:Account[] = [];
          let foundAcc = this.accountService.getAccountById((this.accountListData.opportunity as Opportunity).accountID);
          if (foundAcc){
            accounts.push(foundAcc);
          }
          if (accounts.length != 0) {
            await this.autoSelectAccountAffFilter(accounts);
            // this.affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedAccountsForMeeting(accounts);
          }
          
        }else if(this.accountListData.for == 'NewOpportunityContactSelection'){
          // this.nonGuestContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
        }
        
        if(this.accountListData.for == 'OpportunityStakeholderSelection'){
          this.opportunityStakeholderSelectionEnabled = true;
          this.accountService.accounts.map(e => {
            e.stakeholderRolesList = [];
            e.stakeholderValue = null;
            return e;
          });

          if ((this.accountListData.opportunity as Opportunity).stakeholders && (this.accountListData.opportunity as Opportunity).stakeholders.length > 0) {
            (this.accountListData.opportunity as Opportunity).stakeholders.forEach(stakeholder => {
              let foundAccount = this.accountService.getAccountById(stakeholder.ID);
              if (foundAccount) {
                if(this.isOpportunityStakeholderValueEnabled){
                  foundAccount.stakeholderValue = stakeholder.stakeholderValue;
                }
                if(!foundAccount.stakeholderRolesList.find(sh => sh.roleid === stakeholder.role.roleid)){
                  foundAccount.stakeholderRolesList.push({roleid:stakeholder.role.roleid,name: stakeholder.role.name, isSelected: true});
                }
                let selAccount = this.accountService.selectedAccounts.find(acc => acc.id === foundAccount.id);
                if(!selAccount){
                  this.accountService.selectedAccounts.push(foundAccount);
                }
            }
            })
            // if (this.contactService.contactPageMode == ComponentViewMode.SELECTION) {
              // this.radioSelectedContact = this.contactService.getContactByID((this.contactListData.opportunity as Opportunity).stakeholders[0].ID);
              // this.contactService.contacts.map(item => {
              //   item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
              // });
            // }
          }
        }
      }
      this.accountService.accounts.map(item => {
        item.isSelected = this.accountService.selectedAccounts.some(o => o.id === item.id);
        // item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
      });
      // if (this.contactService.contactPageMode == ComponentViewMode.SELECTION) {
      //   this.selectionEligibleContacts = this.nonGuestContacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
      //   this.selectionEligibleContactsLength = this.selectionEligibleContacts.length;
      // }
    }
    this.accountService.affiliationControl();
    this.listPeriodTags = this.territoryService.getListPeriodTags();
    this.accounts = this.allAccounts.slice(0, this.recordCount);  //this.accountService.accounts.slice(0,this.recordCount);
    // Setup for Call plan account selection
    if (this.accountListMode === this.compViewMode.ADDNEW
      && this.navService.getCurrentPageName() === PageName.CallPlanComponent
      || this.navService.getPreviousPageName() === PageName.CallPlanComponent) {
        this.accountService.selectedAccounts = (this.activityService.selectedActivity && this.activityService.selectedActivity.type === 'Appointment') ? this.activityService.selectedActivity['accounts'].slice() : [];
      this.accountService.accounts.map(item => {
        item.isSelected = this.accountService.selectedAccounts.indexOf(item) >= 0;
      });
    }
    else {
      //Logic for selecting account via scheduler tool
      if (this.accountService.isSchedulerInvoked) {
        this.schedulerSelectionSubscription = this.schedulerService.schedulerObs.subscribe(res => {
          if (res && res.accountList) {
            Object.assign(this.currentSchdeuleAccount, res.accountList);
          }
          this.initiateSchedulerSelection(this.currentSchdeuleAccount);
        });
      }
      else if (this.accountService.isCaseManagementInvoked) {
        // Leaving this part as is to not break other scenarios that use this component
        if (this.caseManagementService.currentCase && this.caseManagementService.currentCase._case_account) {
          this.accountService.selectedAccounts = [];
          this.accountService.selectedAccounts.push(this.caseManagementService.currentCase._case_account);
          this.accountService.accounts.map(item => {
            item.isSelected = this.accountService.selectedAccounts.some(o => o.id === item.id);
          });
        }
        //Set all account as unselected
        else {
          //unset all selections
          // this.accounts.map(item => {
          //   item.isSelected = false;
          // });

          this.accountService.accounts.map(item => {
            item.isSelected = false;
          });
        }
        //Allow selecting empty array as default
        //this.isDirty = true;
      } else if (this.accountService.accessedAccountListFrom == PageName.FollowUpActionDetailComponent) {
        let followup:FollowUpActivity;
        if(this.navParams && this.navParams.data && this.navParams.data.type && (this.navParams.data.type == 'InMeetingFollowupPushedAccountSelectionView' || this.navParams.data.type == 'InPresentationFollowupPushedAccountSelectionView')){
          followup = this.followUpActivityDataService.inMeetingFollowupActionActivity
        }else if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.FollowUp && (this.activityService.selectedActivity as FollowUpActivity).accountId){
          followup = (this.activityService.selectedActivity as FollowUpActivity)
        }
        if(followup){
          this.accountService.selectedAccounts = [];
          let selectedAccount = this.accountService.getAccountById((followup.accountId));
          if(selectedAccount){
            this.accountService.selectedAccounts.push(selectedAccount);
          }
          this.allAccounts.forEach(obj => {
            this._updateAccountIsSelectedRecursively(obj,false);
            if(followup.accountId){
              let res = this._findAccountByIdRecursively(obj,followup.accountId);
              if(res){
                res.isSelected = true;
              }
            }
          });
        }
      } else if (this.accountService.accessedAccountListFrom == PageName.OrderDetailsComponent && this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Order && (this.activityService.selectedActivity as OrderActivity).accountId) {
        this.accountService.selectedAccounts = [];
        let selectedAccount = this.accountService.getAccountById(((this.activityService.selectedActivity as OrderActivity).accountId));
        if(selectedAccount){
          this.accountService.selectedAccounts.push(selectedAccount);
        }
        this.allAccounts.forEach(obj => {
          this._updateAccountIsSelectedRecursively(obj,false);
          if((this.activityService.selectedActivity as OrderActivity).accountId){
            let res = this._findAccountByIdRecursively(obj,(this.activityService.selectedActivity as OrderActivity).accountId);
            if(res){
              res.isSelected = true;
            }
          }
        });
      } else if (this.accountService.accessedAccountListFrom == PageName.ContactAcountAffiliationComponent) {

        this.singleSelect = true;
        this.accountService.selectedAccounts = [];
        let selectedAccount = this.accountService.getAccountById(this.paramAccount);
        if(selectedAccount){
          this.accountService.selectedAccounts.push(selectedAccount);

          this.accountService.accounts.map(item => {
            item.isSelected = selectedAccount.id == item.id;
          });
        }
      } else if(this.accountService.accessedAccountListFrom == PageName.NewOpportunityComponent && !(this.accountListData && this.accountListData.for == 'OpportunityStakeholderSelection')){
        let opportunity: Opportunity = this.opportunityMgmtService.newOpportunity$.getValue();
        if (opportunity && opportunity.primaryTargetValue && opportunity.primaryTargetValue == OpportunityPrimaryTraget.CONTACT && opportunity.contactID) {
          this.newOpportunityContactPrimaryTargetFlow = true;
          let affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting([this.contactService.getContactByID(opportunity.contactID)]);
          if (!_.isEmpty(affiliatedAccounts)) {
            this.allAccounts = [];
            affiliatedAccounts.forEach(selAccount => {
              let selectableAccount = this.accountService.getAccountById((selAccount.id));
              if (selectableAccount) {
                this.allAccounts.push(selectableAccount);
              }
            });
          }
          this.accounts = this.allAccounts.slice(0, this.recordCount);
          this.initAllAccountsHeaderModel();
        }
        this.accountService.selectedAccounts = [];
        this.allAccounts.map(item => {
          item.isSelected = false;
        });
        let selectedAccount;
        if(opportunity && opportunity.accountID){
          selectedAccount = this.accountService.getAccountById(opportunity.accountID);
          if(selectedAccount){
            this.accountService.selectedAccounts.push(selectedAccount);
          }
        }
        this.allAccounts.forEach(obj => {
          this._updateAccountIsSelectedRecursively(obj,false);
          if(selectedAccount && selectedAccount.id){
            let res = this._findAccountByIdRecursively(obj,selectedAccount.id);
            if(res){
              res.isSelected = true;
            }
          }
        });
      }
      else if(this.accountService.accessedAccountListFrom == PageName.AssetDetailsComponent){
        this.accountService.selectedAccounts = [];
        let selectedAsset:CustomerAsset = this.fieldMaterialManagementService.selectedAsset;
        this.allAccounts.map(item => {
          item.isSelected = false;
        });
        let selectedAccount;
        if(selectedAsset && selectedAsset.indskr_newassetlocation){
          selectedAccount = this.accountService.getAccountById(selectedAsset.indskr_newassetlocation);
          if(selectedAccount){
            this.accountService.selectedAccounts.push(selectedAccount);
          }
        }
        this.allAccounts.forEach(obj => {
          this._updateAccountIsSelectedRecursively(obj,false);
          if(selectedAccount && selectedAccount.id){
            let res = this._findAccountByIdRecursively(obj,selectedAccount.id);
            if(res){
              res.isSelected = true;
            }
          }
        });
      } else if(this.accountService.accessedAccountListFrom === PageName.SurveyDetailsComponent) {
        // this.selectionModeForComponent = 'Survey-Details-Selection';
        let selectedTemplate: AssessmentTemplate;
        let selectedAccountIdForSurvey: string = '';
        if(this.navParams && this.navParams.data) {
          selectedTemplate = this.navParams.data.template;
          selectedAccountIdForSurvey = this.navParams.data.accountId;
        }
        if(!_.isEmpty(selectedTemplate)) {
          let eligibleAccountsIDs: string[] = [];
          if (selectedTemplate.indskr_metadata.hasOwnProperty('EligibleCustomers')) {
            const eligibleAccountsFrommetaData = selectedTemplate.indskr_metadata['EligibleCustomers'];
            if (!_.isEmpty(eligibleAccountsFrommetaData)) {
              eligibleAccountsIDs = eligibleAccountsFrommetaData.map(account=>account['ID'])
            }
          }
          //#1 Filtered account list with eligible account IDs
          if(!_.isEmpty(eligibleAccountsIDs)) {
            //#1-1 One-time survey: Show add-icon with non captured eligible contacts in my account
            if(selectedTemplate.indskr_surveyfrequency == SurveyFrequency.ONCE) {
              // const allSurveysByTemplate = this.customerAssessService.getSurveysByTemplateId(selectedTemplate.indskr_assessmenttemplateid)
              // const responsesGroupedByContact = _.groupBy(allSurveysByTemplate,'indskr_entityid');
              if(_.isEmpty(this.customerAssessService.surveyedAccountsForOnetimeSurvey)) this.customerAssessService.loadSurveyedAccountsByTemplates(true);
              const surveyedAccountsByTemplate = this.customerAssessService.surveyedAccountsForOnetimeSurvey[selectedTemplate.indskr_assessmenttemplateid];
              this.accountService.accounts.map(item => {
                const filteredByAccountID = !_.isEmpty(surveyedAccountsByTemplate) ? surveyedAccountsByTemplate.filter(s => s.indskr_entityid == item.id) : [];
                item.isForcedHideAddIcon = !eligibleAccountsIDs.includes(item.id) || !_.isEmpty(filteredByAccountID);
              });
            }else {
              //#1-2 Recurring survey: Show add-icon with eligible account IDs in my contats
              this.accountService.accounts.map(item => {
                item.isForcedHideAddIcon = !eligibleAccountsIDs.includes(item.id);
              });
            }
          }
          //#2 Filtered contacts list without eligible account IDs
          else {
            //#2-1 One-time survey: Show add-icon with non-captured account
            if(selectedTemplate.indskr_surveyfrequency == SurveyFrequency.ONCE) {
              // const allSurveysByTemplate = this.customerAssessService.getSurveysByTemplateId(selectedTemplate.indskr_assessmenttemplateid)
              // const responsesGroupedByContact = _.groupBy(allSurveysByTemplate,'indskr_entityid');
              if(_.isEmpty(this.customerAssessService.surveyedAccountsForOnetimeSurvey)) this.customerAssessService.loadSurveyedAccountsByTemplates(true);
              const surveyedAccountsByTemplate = this.customerAssessService.surveyedAccountsForOnetimeSurvey[selectedTemplate.indskr_assessmenttemplateid];
              this.accountService.accounts.map(item => {
                const filteredByAccountID = !_.isEmpty(surveyedAccountsByTemplate) ? surveyedAccountsByTemplate.filter(s => s.indskr_entityid == item.id) : [];
                item.isForcedHideAddIcon = !_.isEmpty(filteredByAccountID);
              });
            }else {
              //#2-2 Recurring survey: Show add-icon with my contacts
              this.accountService.accounts.map(item => { if(item.isForcedHideAddIcon) item.isForcedHideAddIcon = !_.isEmpty(eligibleAccountsIDs) ? !eligibleAccountsIDs.includes(item.id) : false; });
            }
          }
        }
        if (selectedAccountIdForSurvey) {
            let selectedAccount = this.accountService.getAccountById(selectedAccountIdForSurvey);
            if(selectedAccount){
              this.accountService.selectedAccounts.push(selectedAccount);
            }
        } else {
          this.accountService.accounts.map(obj => obj.isSelected = false);
          this.accountService.selectedAccounts = [];
        }
        // this.selectionEligibleContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
        this.uiService.showNewActivity = false;
      } else if(this.accountService.selectedFor == AccountSelectedFor.GENERAL_SINGLE_SELECTION || this.accountService.selectedFor == AccountSelectedFor.GENERAL_MULTIPLE_SELECTION){
        this._holdDynamicFormIsDirtyTracker = this.dynamicFormService.linkedEntityFormIsDirtyTracker;
        this.dynamicFormService.linkedEntityFormIsDirtyTracker = false;
        this.accountService.selectedAccounts = [];
        if(this.accountService.selectedFor == AccountSelectedFor.GENERAL_SINGLE_SELECTION){
          if (this.selectedAccountId) {
            let selectedAccount = this.accountService.getAccountById(this.selectedAccountId);
            if(selectedAccount){
              this.accountService.selectedAccounts.push(selectedAccount);
            } else if (this.accountService.accessedAccountListFrom === PageName.SurgeryOrderDetailsComponent) {
              let currentSurgeryOrderActivity = this.activityService.selectedActivity as SurgeryOrderActivity;
              if (currentSurgeryOrderActivity.coOwners.some((coOwner) => coOwner.userId === this.authService.user.systemUserID)) {
                let account: any = {
                  accountid: currentSurgeryOrderActivity.accountId,
                  name: currentSurgeryOrderActivity.accountNameString,
                  statuscode: 548910003,
                  id: currentSurgeryOrderActivity.accountId
                };
                let acc = new Account(account);
                this.accountService.selectedAccounts.push(acc);

              }
            } else if (this.accountService.accessedAccountListFrom === PageName.QuoteDetailsComponent) {
              if (this.selectedAccountId) {
                let _account: Account = this.accountService.getAccountById(this.selectedAccountId);
                if (!_account) {
                  const selectedQuote = this.quotesToolService.selectedQuotes$.getValue();
                  if (selectedQuote)
                    _account = new Account({ name: selectedQuote.accountName, id: selectedQuote.accountId } as unknown as AccountDTO);
                  this.accountService.selectedAccounts.push(_account);
                }
              }
            }
          }
        }else{
          if (this.selectedAccountIds) {
            this.selectedAccountIds.forEach(item => {
              let selectedAccount = this.accountService.getAccountById(item.id);
              if(selectedAccount){
                this.accountService.selectedAccounts.push(selectedAccount);
              } else if(this.eventsToolService.isCovisitorViewMode) {
                const rawAccountOnCovisitor: any = {
                  accountid: item.id,
                  name: item.name,
                  statuscode: item.statecode,
                }
                let acc = new Account(rawAccountOnCovisitor);
                this.accountService.selectedAccounts.push(acc);
              } else {
                const rawAccount: any = {
                  accountid: item.id,
                  name: item.name,
                  statuscode: 2
                }
                let acc = new Account(rawAccount);
                this.accountService.selectedAccounts.push(acc);
              }
            });
          }
        }
        this.allAccounts.forEach(obj => {
          this._updateAccountIsSelectedRecursively(obj,false);
          this.accountService.selectedAccounts.forEach(selAcc=>{
            let res = this._findAccountByIdRecursively(obj,selAcc.id);
            if(res){
              res.isSelected = true;
            }
          });
        });
      }  else if (this.accountService.accessedAccountListFrom == PageName.PhoneCallDetailsComponent && this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.PhoneCall) {
        this.accountService.selectedAccounts = (this.activityService.selectedActivity && this.activityService.selectedActivity.type === 'PhoneCall') ? this.activityService.selectedActivity['accounts'].slice() : [];
        this.accountService.accounts.map(item => {
          item.isSelected = this.accountService.selectedAccounts.some(o => o.id === item.id);
        });
      }
      /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
      else if (this.accountService.accessedAccountListFrom == PageName.EmailDetailsPageComponent && this.emailService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Email) {
        let accounts = (this.emailService.selectedActivity && this.activityService.selectedActivity.type === 'Email' && this.emailService.selectedActivity['accounts']) ? this.emailService.selectedActivity['accounts'].slice() : [];
        let emailSelectedAccounts = [];
        accounts.forEach(element => {
          const accountId = element['id'] || element['accountId'];
          if(accountId) {
            const account = this.accountService.getAccountById(accountId);
            if(account) {
              emailSelectedAccounts.push(account);
            }
          }
        });

        this.accountService.selectedAccounts = emailSelectedAccounts;
        this.accountService.accounts.map(item => {
          item.isSelected = this.accountService.selectedAccounts.some(o => o.id === item.id);
        });
      }
      /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
      else if(this.accountService.accessedAccountListFrom === PageName.MarketScanDetailsComponent) {
        const marketScan = this.marketScanService.getSelectedMarketScan();
        if(marketScan.category?.indskr_capturedatafor === 548910002) {
          this.allAccounts = this.allAccounts.filter(acc => acc.raw['indskr_accounttype'] === 100000001);
          this.accountService.createAccountsTree(this.allAccounts);
          this.allAccounts = this.accountService.accountsTreeForOtherTool;
          this.accounts = this.allAccounts.slice(0, this.recordCount);
            // this.affiliatedAccounts = this.accountService.accountsTreeForOtherTool;
          // let selectedContacts:Contact[] = [];
          // this.selectedContactIds.forEach(id => {
          //   let selectedContact = this.contactService.getContactByID(id);
          //   if(selectedContact){
          //     selectedContacts.push(selectedContact);
          //   }
          // });
          // if(selectedContacts.length != 0){
          //   this.affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting(selectedContacts);
          //   this.affiliatedAccounts = this.affiliatedAccounts.filter(function (account){return account.raw['indskr_accounttype'] === 100000001});
            //this.accounts = this.affiliatedAccounts;
            //this.allAccounts = this.affiliatedAccounts;
            // this.accountService.createAccountsTree(this.affiliatedAccounts);
            // this.affiliatedAccounts = this.accountService.accountsTreeForOtherTool;
          //}
          //let rawData = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY+OFFLINE_DB_LINKED_ENTITY_NAME.ACCOUNT_CONTACT_AFFILIATION);
          //if(rawData && rawData.raw){
          //  this.accounts = this.allAccounts.filter(acc => rawData.raw.some(x => x['accountid'] == acc.id));
          //}
          //this.allAccounts = this.accounts;
        }
        if (!_.isEmpty(marketScan.accounts)) {
          this.accountService.selectedAccounts = [];
          marketScan.accounts.forEach(account => {
            if (account.action != Action.DELETE) {
              let _account: Account = this.accountService.getAccountById(account.accountId);
              if (!_account) {
                _account = new Account({ name: account.accountName, id: account.accountId } as unknown as AccountDTO);
              }
              _account['sequence'] = account.sequence;
              _account.isSelected = true;
              this.accountService.selectedAccounts.push(_account);
            }
          });
          this.allAccounts.map(item => {
            item.isSelected = this.accountService.selectedAccounts.some(o => o.id === item.id);
            item.subAccounts.forEach(subAcc=>{
              subAcc.isSelected = this.accountService.selectedAccounts.some(o => o.id === item.id);
            })
          });
        }
      } else if (this.accountService.accessedAccountListFrom == PageName.NewAccountPlanComponent ||  this.accountService.accessedAccountListFrom == PageName.AccountPlanDetailsComponent) {

        this.currentAccountPlanOpportunities = this.opportunityService.opportunities.filter((oppo)=>{
          return oppo.accountPlanID && oppo.accountPlanID == this.accountManagementOfflineService.currentAccountPlan.ID;
        });

        if (!_.isEmpty(this.selectedAccountIds)) {
          this.selectedAccountIds.forEach(selectedAccount => {
            const index = this.accountService.accounts.findIndex(item => item.id == selectedAccount.id);
            if(index > -1) {
              this.accountService.accounts[index].isSelected = true;
              this.accountService.selectedAccounts.push(this.accountService.accounts[index]);
            }
          });

        }
      } else if(this.accountService.accessedAccountListFrom === PageName.MarketingPlanDetails){
        let selectedMarketingPlan = this.marketingManagementOfflineService.selectedMarketingPlan$.getValue();
        let marketingPlanOpportunity = selectedMarketingPlan.opportunities.map(({ ID }) => (ID));
        this.currentAccountPlanOpportunities = this.opportunityService.opportunities.filter((Opportunity) => {
          return marketingPlanOpportunity.some((id) => id === Opportunity.ID);
        })

        if (!_.isEmpty(this.selectedAccountIds)) {
          this.selectedAccountIds.forEach(selectedAccount => {
            const index = this.accountService.accounts.findIndex(item => item.id == selectedAccount.id);
            if (index > -1) {
              this.accountService.accounts[index].isSelected = true;
              this.accountService.selectedAccounts.push(this.accountService.accounts[index]);
            } else {
              this.accountService.selectedAccounts.push(new Account(selectedAccount));
            }
          });
        }
      } else if(this.accountService.accessedAccountListFrom === PageName.ShortCallHomeComponent) {
        const selectedAccount = this.accountService.getAccountById(this.selectedAccountId);
        if (selectedAccount) {
          selectedAccount.isSelected = true;
          this.accountService.selectedAccounts.push(selectedAccount);
        }
      }
      else {
        if (this.accountListData && (this.accountListData.for == 'OpportunityStakeholderSelection' || this.accountListData.for == 'NewOpportunityContactSelection') && this.accountListData.opportunity) return;
        // Leaving this part as is to not break other scenarios that use this component
        this.accountService.selectedAccounts = (this.activityService.selectedActivity && this.activityService.selectedActivity.type === 'Appointment') ? this.activityService.selectedActivity['accounts'].slice() : [];
        this.allAccounts.map(item => {
          item.isSelected = this.accountService.selectedAccounts.some(o => o.id === item.id);
          item.subAccounts.forEach(subAcc=>{
            subAcc.isSelected = this.accountService.selectedAccounts.some(o => o.id === item.id);
          })
        });
      }
    }


    //adding new accounts
    if (this.accountListAccessedFrom == 'AccountManagementPage' || this.accountListAccessedFrom == 'OpportunityManagementPage') {
      this.accountService.uniqueAccountsFromOtherTools.forEach(account => {
        if (this.deltaService.deltaRecordsDTO.accounts.indexOf(account.id) != -1) this.newAccounts.push(account);
      });
    }else{
      this.accountService.accounts.forEach(account => {
        if (this.deltaService.deltaRecordsDTO.accounts.indexOf(account.id) != -1) this.newAccounts.push(account);
      });
    }
    this.accountService.updateNewAccountStatus(this.newAccounts);
    this.newAccounts = this._sortAccounts(this.newAccounts);


    if ((this.navService.getPreviousPageName() == PageName.AccountPageComponent || this.navService.getPreviousPageName() == PageName.OpportunityManagementPage)&& this.accountService.selected) {
      this.holdSelectedAccountInfo = this.accountService.selected;
      this.accountService.selected = undefined;
    }

    if(this.accountService.accessedAccountListFrom == PageName.ActivitiesDetailsPaneComponent && this.accountListMode != this.compViewMode.READONLY && this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Appointment && (this.activityService.selectedActivity as AppointmentActivity).contacts && (this.activityService.selectedActivity as AppointmentActivity).contacts.length !== 0){
      this.affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting((this.activityService.selectedActivity as AppointmentActivity).contacts);
      // if(this.affiliatedAccounts.length>=2){this.affiliatedAccounts=new Array(this.affiliatedAccounts[0]);}
      this.accountService.createAccountsTree(this.affiliatedAccounts);
      this.affiliatedAccounts = this.accountService.accountsTreeForOtherTool;
      this.allowAffiliatedHCOSelectionOnly = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOW_AFFILIATED_HCO_AND_HCP_SELECTION_ONLY);
    } else if(this.accountService.accessedAccountListFrom == PageName.PhoneCallDetailsComponent && this.accountListMode != this.compViewMode.READONLY && this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.PhoneCall && (this.activityService.selectedActivity as PhoneActivity).contacts && (this.activityService.selectedActivity as PhoneActivity).contacts.length !== 0){
      this.affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting((this.activityService.selectedActivity as PhoneActivity).contacts);
      this.accountService.createAccountsTree(this.affiliatedAccounts);
      this.affiliatedAccounts = this.accountService.accountsTreeForOtherTool;
    }
    /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
    else if(this.accountService.accessedAccountListFrom == PageName.EmailDetailsPageComponent && this.accountListMode != this.compViewMode.READONLY && this.emailService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Email && (this.emailService.selectedActivity as EmailActivity).emailActivityParties && (this.activityService.selectedActivity as EmailActivity).emailActivityParties.length !== 0){
      let emailSelectedContacts = [];
      (this.activityService.selectedActivity as EmailActivity).emailActivityParties.forEach(emailContact => {
        const contact = this.contactService.getContactByID(emailContact.indskr_contactid);
        if(contact) {
          emailSelectedContacts.push(contact);
        }
      });
      this.affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting(emailSelectedContacts);
      this.accountService.createAccountsTree(this.affiliatedAccounts);
      this.affiliatedAccounts = this.accountService.accountsTreeForOtherTool;
    }
    /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
    else if ((this.accountService.accessedAccountListFrom == PageName.EventDetailsPageComponent || this.accountService.accessedAccountListFrom == PageName.SurgeryOrderDetailsComponent)
      && this.accountListMode != this.compViewMode.READONLY && this.selectedContactIds) {
      let selectedContacts:Contact[] = [];
      this.selectedContactIds.forEach(id => {
        let selectedContact = this.contactService.getContactByID(id);
        if(selectedContact){
          selectedContacts.push(selectedContact);
        }
      });
      if(selectedContacts.length != 0){
        this.affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting(selectedContacts);
        this.accountService.createAccountsTree(this.affiliatedAccounts);
        this.affiliatedAccounts = this.accountService.accountsTreeForOtherTool;
      }
    } else{
      this.affiliatedAccounts = [];
    }
    if(this.searchConfigService.configUpdateRequired){
      this.searchConfigService.updateSearchConfigsForSelectedLanguage();
      this.searchConfigService.configUpdateRequired = false;
    }
    this._initKeyControlsAreaModel();
    this.sortPopoverData = [
      {
        text: "",
        expanded: true,
        value: "accountName",
        items: [
          { text: this.translate.instant('NAME'), value: "accountName", asc: true },
          { text: this.translate.instant('LAST_MODIFIED_DATE'), value: "modifiedOn", asc: false }
        ],
        handler: (selectedItem, item) => {
          item.value = selectedItem.value;
          this.sortBy = selectedItem;
          this._initAllSectionHeaderModel();
          this.sortAccountsListBasedOnSelection(selectedItem);
        }
      }
    ];
    if (!(this.accountListAccessedFrom == 'AccountManagementPage' || this.accountListAccessedFrom == 'OpportunityManagementPage')){
      this.sortPopoverData[0].items.push({ text: this.translate.instant('LAST_INTERACTION'), value: "interactionDate", asc: false });
      this.sortPopoverData[0].items.push({ text: this.translate.instant('NO_INTERACTION'), value: "createdOn", asc: false });
    }
    if (this.accountService.accessedAccountListFrom == PageName.SurveyDetailsComponent) {
      this.sortPopoverData[0].items.push({ text: this.translate.instant('SURVEY_ELIGIBLE'), value: "surveyEligible", asc: false });
    }
    this.sortBy = this.sortPopoverData[0].items[0];
    if(!this.searchConfigService.isOpportunitesMappedToAccounts){
      this.mapOpportunitiesToSearchIndex();
    }

    this.mapChildUsersWithPositionToAccountsSearchConfig();

    this.events.subscribe('accountListContentResize',()=>{
      //this.content.resize();
    })
    //this.content.resize();
    this.updateEmptyMessage()
  }

  _initFilterPopoverData() {
    this.filterPopoverData = [
      {
        text: '',
        value: '',
        items: [
          { text: this.translate.instant('ALL_CHANGE_REQUESTS'), value: '0' }
        ],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          });
          item.value = selectedItem.value;
          this.clearFilters();
          this.updateEmptyMessage();
        }
      },
      {
        text: this.translate.instant('STATUS'),
        value: '',
        items: [
          // { text: this.translate.instant('OPEN'), value: ChangeRequestStatusCode.OPEN },
          { text: this.translate.instant('IN_PROCESS'), value: ChangeRequestStatusCode.IN_PROGRESS },
          { text: this.translate.instant('SUBMITTED'), value: ChangeRequestStatusCode.SUBMITTED },
          { text: this.translate.instant('PENDING_APPROVAL'), value: ChangeRequestStatusCode.PENDING_APPROVAL },
          { text: this.translate.instant('APPROVED'), value: ChangeRequestStatusCode.APPROVED },
          { text: this.translate.instant('REJECTED'), value: ChangeRequestStatusCode.REJECTED }
        ],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = ''
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          if (item.value != '') {
            this.changeReqStatusFilter = item.value;
            this.filterByStatus(item.value);
          }
          this.updateEmptyMessage();
        }
      },
      {
        text: this.translate.instant('REQUEST_TYPE'),
        value: '',
        items: [
          { text: this.translate.instant('CREATE'), value: MDMRequestType.CREATE },
          { text: this.translate.instant('UPDATE'), value: MDMRequestType.UPDATE },
          { text: this.translate.instant('ADD_AFFILIATION'), value: MDMRequestType.ADD_AFFILIATION }
        ],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          if (item.value != '') {
            this.changeReqTypeFilter = item.value;
            this.filterByRequestType(item.value);
          }
          this.updateEmptyMessage();
        }
      },
      // {
      //   text: this.translate.instant('SOURCE_TYPE'),
      //   value: '',
      //   items: [
      //     { text: this.translate.instant('OMNIPRESENCE'), value: BusinessProcessType.Omnipresence},
      //     { text: this.translate.instant('ONE_KEY'), value: BusinessProcessType.OneKey }
      //   ],
      //   handler: (selectedItem, item, itemRef) => {
      //     itemRef.parent.items[0].value = '';
      //     item.value = item.value === selectedItem.value ? '' : selectedItem.value;
      //     if (item.value != '') {
      //       this.changeReqSourceFilter = item.value;
      //       this.filterBySource(item.value);
      //     }
      //     this.updateEmptyMessage();
      //   }
      // },
    ];
    this.filterPopoverData[0].value = '0';
  }

  private clearFilters() {
    this.filtering = false;
    this.selectedFilterData = [];
    this.filterNameBubble = [];
    if (this.currentTab == AccountsModel.CHANGE_REQUESTS_TAB) {
      this.filteredAccountCRs = this.accountCRs;
      this.isFilteredAccountCRs = false;
      this.changeReqStatusFilter = null;
      this.changeReqTypeFilter = null;
      this.changeReqSourceFilter = null;
    } else {
      if(this.selectedSuggestionsData.length == 0) {
        this.removeAllSelectedSuggestions();
      } else {
        this.searchAccountsList();
      }
      this.sortAccountsListBasedOnSelection(this.sortBy);
    }
    this._initAllSectionHeaderModel();
  }

  private filterByRequestType(type: number) {
    if (!_.isEmpty(this.accountCRs)) {
      if (this.changeReqStatusFilter != null && this.changeReqSourceFilter != null) {
        this.filteredAccountCRs = this.accountCRs.filter(accountCR => accountCR.statuscode == this.changeReqStatusFilter && accountCR.indskr_mdmrequesttype == type && accountCR.indskr_accountsourcetype == this.changeReqSourceFilter);
      }else if (this.changeReqStatusFilter != null) {
        this.filterChangeReqByStatusAndType(type, this.changeReqStatusFilter);
      } else if (this.changeReqSourceFilter != null) {
        this.filteredAccountCRs = this.accountCRs.filter(accountCR => accountCR.indskr_mdmrequesttype == type && accountCR.indskr_accountsourcetype == this.changeReqSourceFilter);
      }else {
        this.filteredAccountCRs = this.accountCRs.filter(accountCR => accountCR.indskr_mdmrequesttype == type);
      }
    } else {
      this.filteredAccountCRs = [];
    }
    this._initAllSectionHeaderModel();
  }

  private filterByStatus(status: number) {
    if (!_.isEmpty(this.accountCRs)) {
      if (this.changeReqTypeFilter != null && this.changeReqSourceFilter != null) {
        this.filteredAccountCRs = this.accountCRs.filter(accountCR => accountCR.statuscode == status && accountCR.indskr_mdmrequesttype == this.changeReqTypeFilter && accountCR.indskr_accountsourcetype == this.changeReqSourceFilter);
      } else if (this.changeReqTypeFilter != null) {
        this.filterChangeReqByStatusAndType(this.changeReqTypeFilter, status);
      } else if (this.changeReqSourceFilter != null) {
        this.filteredAccountCRs = this.accountCRs.filter(accountCR => accountCR.statuscode == status && accountCR.indskr_accountsourcetype == this.changeReqSourceFilter);
      }else {
        this.filteredAccountCRs = this.accountCRs.filter(accountCR => accountCR.statuscode == status);
      }
    } else {
      this.filteredAccountCRs = [];
    }
    this._initAllSectionHeaderModel();
  }

  private filterChangeReqByStatusAndType(type: number, status: number) {
    this.filteredAccountCRs = this.accountCRs.filter(accountCR => accountCR.statuscode == status && accountCR.indskr_mdmrequesttype == type);
  }

  private accountCreateHandler: (event: any) => void = async (event:any) => {
    if(event && event['isMDMType']){
      if(event.value == MDMType.ONEKEY){
        await this.oneKeyDynamicAccountForm();
      }
    }else if(event && !event['isMDMType']){
      if(event.value == ChangeRequestType.BUSINESS){
        await this.defaultBusinessDynamicAccountForm();
      }
    }
  }

  showVectorImage(): boolean {
    // old condition
    // [ngClass]="{'vectorimageleft': accountsModel === 'myAccounts' ? (allAccounts?.length === 0 || (!searching && (accountListMode!=compViewMode.READONLY) && filteredList.length === 0)) : (accountCRs?.length === 0 || (!searching && (accountListMode!=compViewMode.READONLY) && filteredAccountCRs.length === 0))}"

    if (this.searching || this.filtering) {
      if (this.accountsModel === AccountsModel.MY_ACCOUNTS_TAB) {
        if (this.accountListMode === this.compViewMode.LISTVIEW) {
          return this.filteredList.length === 0;
        }
      } else {
        if (this.accountListMode === this.compViewMode.LISTVIEW) {
          return this.filteredAccountCRs.length === 0;
        }
      }
    } else {
      if (this.accountsModel === AccountsModel.MY_ACCOUNTS_TAB) {
        if (this.accountListMode === this.compViewMode.LISTVIEW) {
          return this.accounts && this.accounts.length === 0;
        }
      } else {
        if (this.accountListMode === this.compViewMode.LISTVIEW) {
          return this.accountCRs.length === 0;
        }
      }
    }
    return false;
  }

  async checkAccountModel(value:AccountsModel) {
    if (this.currentTab === value) return;
    if (this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) return;
    this.currentTab = value;
    this.searching = false;
    this.filtering = false;
    if(this.currentTab == AccountsModel.CHANGE_REQUESTS_TAB) {
      this.hideFilterButton = false;
      this.disableSaveSearch = true;
      this.suggestionsActive = false;
      this.enableGlobalSearch = false;
      this.clearFilters();
      this._initFilterPopoverData();
      this.accountService.isClickedGoToAccountsTool = false;
    } else {
      this.hideFilterButton = false;
      this.disableSaveSearch = false;
      this.suggestionsActive = true;
      this.enableGlobalSearch = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_GLOBAL_SEARCH);
    }
    this.removeSelectedSugestionsandUpdateEmptyMessage();
    this.removeSelectedFiltersandUpdateEmptyMessage();
    this._initKeyControlsAreaModel();
    this.accountService.selectedAccountCr = '';
  }

  async checkComponentModel(value: string) {
    if (this.currentComponent === value) return;
    this.currentTab = value;
    if(this.currentTab == 'contact' && (this.contactService.accessedContactListFrom == PageName.OpportunityDetailsComponent || this.contactService.accessedContactListFrom == PageName.NewOpportunityComponent)) {
      let passedData = {
        type:'PushedContactSelectionView',
        contactListData:
        {
            for:'OpportunityStakeholderSelection',
            opportunity: this.accountListData && this.accountListData.opportunity
        },
      };
      this.contactService.accessedContactListFrom = this.accountService.accessedAccountListFrom;
      const opportunityType = this.opportunityService.opportunityTypes.find(opp => opp.indskr_opportunitytypeid == this.accountListData?.opportunity?.opportunityTypeId);
      if (opportunityType.singlestakeholder && opportunityType.singlestakeholder === true) {
        this.contactService.contactPageMode = ComponentViewMode.SELECTION;
      } else {
        this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
      }
      // this.onCloseModal(false);
      this.navService.popWithPageTracking();
      this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, passedData, PageName.OpportunityDetailsComponent, { animate: false }, null);
    }
    
  }

  private removeSelectedSugestionsandUpdateEmptyMessage() {
    this.removeAllSelectedSuggestions();
    this.updateEmptyMessage();
    setTimeout(() => {
      this.uiService.scrollListToView(this.list);
    }, 200);
  }

  private removeSelectedFiltersandUpdateEmptyMessage() {
    this.clearFilters();
    this.updateEmptyMessage();
    setTimeout(() => {
      this.uiService.scrollListToView(this.list);
    }, 200);
  }

  async openFilters(event) {
    const changeReqFilter = await this.popover.create({
      component: MultiSelectPopover, componentProps: { root: this.filterPopoverData },
      event: event
    });
    changeReqFilter.present();
    changeReqFilter.onDidDismiss().then(() => {

    });
  }

  openFilterMenu(event) {
    if(this.currentTab == AccountsModel.CHANGE_REQUESTS_TAB) {
      this._initiateCRFilter();
    } else {
      console.log("clicked filter menu - accounts");
      this._initiateMyAccountsFilter();
    }
  }

  private async _initiateCRFilter() {
    const filterMenuModal = await this.modalCtrl.create({
      component: IndFilterMenuComponent,
      componentProps: {
        viewData: {
          data: this.filterPopoverData,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('FILTERS'),
          isSearchEnabled: true,
        },
        selectedFilter: this.selectedFilterData,
        from: PageName.AccountPageComponent,
        subFrom: AccountsModel.CHANGE_REQUESTS_TAB
      },
      backdropDismiss: false
    });
    filterMenuModal.present();
    filterMenuModal.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          this.clearFilters();
          this.filtering = false;
        } else {
          this.selectedFilterData = [];
          this.filterNameBubble = [];
          this.filtering = true;
          let selectedValue:{ status: any[], requestType: any[], resourceType: any[]} = {status:[], requestType:[], resourceType:[]};

          data.selectedItems.forEach((selected) => {
            this.selectedFilterData.push(selected);
            if(selected.categoryPath == 'accountCR_status') selectedValue['status'].push(selected.value);
            else if(selected.categoryPath == 'accountCR_request_type') selectedValue['requestType'].push(selected.value);
            else if(selected.categoryPath == 'accountCR_source_type') selectedValue['resourceType'].push(selected.value);
          });
          this.selectedFilterData.forEach((data) => this._updateFilterNameBubble(data));
          this.filterAccountCR(selectedValue)
          this.selectedFilterData.sort((a,b)=>{
            if(a.createdOn < b.createdOn) return 1
            else return -1
          })
        }
      }
    });
  }

  private async _initiateMyAccountsFilter() {
    let options;
    this.selectedFilterData.forEach((data)=> {
      if(data.configuredFrom == 'Assess') data.categoryPath = data.categoryName;
    })
    const filterMenuModal = await this.modalCtrl.create({
      component: IndFilterMenuComponent,
      componentProps: {
        viewData: {
          data: options,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('FILTERS'),
          isSearchEnabled: true,
        },
        selectedFilter: this.selectedFilterData,
        from: PageName.AccountPageComponent,
        subFrom: AccountsModel.MY_ACCOUNTS_TAB
      },
      backdropDismiss: false
    });
    filterMenuModal.present();
    filterMenuModal.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          this.clearFilters();
          this.filtering = false;
        } else {
          if (this.device.isOffline && data.selectedItems.some(item => item.configuredFrom == "Assess")) {
            this.notificationService.notify(this.translate.instant('ASSESSMENT_BASED_FILTER_ONLINE_ONLY'),  "Contact list", "top", ToastStyle.INFO, 3000);
            return;
          }
          this.selectedFilterData = [];
          this.filterNameBubble = [];
          this.filtering = true;
          data.selectedItems.forEach((selected) => {
            if(selected.selectedFacet == this.translate.instant('ASSESSED')) this.selectedFilterData.push(this.getAssessmentPill());
            else if(selected.selectedFacet == this.translate.instant('UNASSESSED'))this.selectedFilterData.push(this.getUnAssessmentPill());
            else {
              if(!this.selectedFilterData.some(o=> o.categoryName==selected.categoryName && o.selectedFacet == selected.selectedFacet))
              this.selectedFilterData.unshift(selected);
            }
          });
          this.selectedFilterData.forEach((data) => this._updateFilterNameBubble(data));
          this.suggestionsActive = false;
          this.searchAccountsList(true);
          this.selectedFilterData.sort((a,b)=>{
            if(a.createdOn < b.createdOn) return 1
            else return -1
          })
        }
      }
    });
  }

  private _updateFilterNameBubble(item) {
    if(item.categoryName == 'Assessed' || item.categoryName == 'UnAssessed') {
      let idx = this.filterNameBubble?.findIndex((bubble) => bubble.categoryName == this.translate.instant('ASSESSMENTS'));
      if(idx>-1) this.filterNameBubble[idx].suggestion.push(item);
      else {
        let selected = { categoryName: this.translate.instant('ASSESSMENTS'), suggestion: [item], isSavedFilter: false, savedFilterName: '' };
        this.filterNameBubble.push(selected);
      }
    } else {
      let idx = this.filterNameBubble?.findIndex((bubble) => bubble.categoryName == item.categoryName);
      if(idx>-1) this.filterNameBubble[idx].suggestion.push(item);
      else {
        let selected = { categoryName: item.categoryName, suggestion: [item], isSavedFilter: false, savedFilterName: '' };
        if(item.isSavedFilter) {
          selected.isSavedFilter = true;
          selected.savedFilterName = item.savedFilterName;
        }
        this.filterNameBubble.push(selected);
      }
    }
  }

  private filterAccountCR(selected) {
    this.isFilteredAccountCRs = true;
    this.filteredAccountCRs = this.accountCRs;
    if(!_.isEmpty(this.accountCRs)) {
      if(!_.isEmpty(selected['status'])) {
        this.filteredAccountCRs = this.accountCRs.filter(accountCR => selected['status'].includes(accountCR.statuscode));
      }
      if(!_.isEmpty(selected['requestType'])) {
        this.filteredAccountCRs = this.filteredAccountCRs.filter(accountCR => selected['requestType'].includes(accountCR.indskr_mdmrequesttype));
      }
      if(!_.isEmpty(selected['resourceType'])) {
        this.filteredAccountCRs = this.filteredAccountCRs.filter(accountCR => selected['resourceType'].includes(accountCR.indskr_accountsourcetype));
      }
    }
    this._initAllSectionHeaderModel();
  }

  setTabsData() {
    const disableCR = !(this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_CREATE_REQUEST) || this.businessCreateChangeRequest) && !this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_EDIT_REQUEST);
    this.tabsData = [
      {
        displayText: this.translate.instant('MY_ACCOUNTS_TAB'),
        value: AccountsModel.MY_ACCOUNTS_TAB
      },
      {
        displayText: !disableCR ? this.translate.instant('CHANGE_REQUESTS') : '',
        value: AccountsModel.CHANGE_REQUESTS_TAB,
        disable: disableCR
      },
    ];
  }

  setComponentTabsData() {
    switch(this.accountService.accessedAccountListFrom) {
      case PageName.OpportunityDetailsComponent:
      case PageName.NewOpportunityComponent:
        this.tabsComponentData = [
          {
            displayText: this.translate.instant('CONTACT'),
            value: 'contact',
            disable : false
          },
          {
            displayText: this.translate.instant('ACCOUNT'),
            value: 'account',
            disable: false
          }
        ];
        break;
      default :
        this.tabsComponentData = [
          {
            displayText: this.translate.instant('ACCOUNT'),
            value: 'account',
            disable : false
          }
        ];
        break;

    }
  }

  public get title() {
    if (this.accountListAccessedFrom == 'AccountPage') return this.translate.instant('ACCOUNTS');
    if (this.accountListAccessedFrom == 'AccountManagementPage') return this.translate.instant('ACCOUNT_PLANS');
    if (this.accountListAccessedFrom == 'OpportunityManagementPage') return this.translate.instant('OPPORTUNITIES');
  }

  private initAccountsHeaderLeft(): void {
    let buttons = [];
    if(((this.accountListMode!=this.compViewMode.ADDNEW && this.accountListMode!=this.compViewMode.SELECTION) && (this.accountService.accessedAccountListFrom == this.pagename.ActivitiesPageComponent || this.accountListAccessedFrom == 'AccountManagementPage' || this.accountListAccessedFrom == 'OpportunityManagementPage'))){
      buttons.push({
        id: "home",
        imgSrc: 'assets/imgs/back_to_home_btn.svg',
        //icon: "indegene-back-to-home-btn",
        isDisabled: false,
        align: "left",
        //cssClass: 'icon-back-home'
      });
    }
    else {
      if(this.accountListMode===this.compViewMode.ADDNEW || this.accountListMode===this.compViewMode.SELECTION || this.accountListMode === this.compViewMode.READONLY){
        buttons.push({
          id: "close",
          imgSrc: (this.accountListMode===this.compViewMode.ADDNEW || this.accountListMode===this.compViewMode.SELECTION || this.accountListMode === this.compViewMode.READONLY) ? 'assets/imgs/header_cancel.svg' : 'assets/imgs/back_to_home_btn.svg',
          cssClass: (this.accountListMode===this.compViewMode.ADDNEW || this.accountListMode===this.compViewMode.SELECTION || this.accountListMode === this.compViewMode.READONLY) ?'seventyPercentWidth' : '',
          isDisabled: false,
          align: "left",
        });
      }
    }
    if (this.accountListMode===this.compViewMode.ADDNEW || this.accountListMode===this.compViewMode.SELECTION) {
      buttons.push({
        id: "done",
        imgSrc: 'assets/imgs/header_complete.svg',
        cssClass: 'seventyPercentWidth',
        isDisabled: !this.isDirty,
        align: "right",
      });
    }

    this.indHeaderLeftModel = {
      id: 'accounts-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.title,
      mode: ((this.accountListMode!=this.compViewMode.ADDNEW && this.accountListMode!=this.compViewMode.SELECTION) && (this.accountService.accessedAccountListFrom == this.pagename.ActivitiesPageComponent || this.accountListAccessedFrom == 'AccountManagementPage' || this.accountListAccessedFrom == 'OpportunityManagementPage')),
      customHeaderProps:{ hasCancel: this.accountListMode===this.compViewMode.ADDNEW || this.accountListMode===this.compViewMode.SELECTION || this.accountListMode === this.compViewMode.READONLY },
      controls: buttons,
    };
  }

  onPageTitleControlClick(id){
    if(id==='home'){
      this.trackToolsBack();
    }
    if(id==='close'){
      this.onCloseModal(false);
    }
    if(id==='done'){
      this.onCloseModal(true);
    }
    if (id === 'create-account-btn') {
      this._createNewAccount();
    }
  }

  //Disable the search bar

  // disableSearchBar() {
  //   const inputs: any = document.getElementById("accounts-seachbar").getElementsByTagName("INPUT");
  //   inputs[0].disabled = this.accountListMode === this.compViewMode.READONLY ? true : false
  // }

  private _initAllSectionHeaderModel() {
    this.initResultsAccountsheaderModel();
    this.initAllAccountsHeaderModel();
    this.initGlobalSearchedAccountsHeaderModel();
    this.initChangeRequestAccountsHeaderModel();
  }

  private initResultsAccountsheaderModel() {

    this.initSelectedAccountsHeaderModel();

    let controls: any[] = [];

    if (this.shouldSelectAllOptionVisible()) {
      controls.push({
        id: 'accounts-select-all',
        text: `${this.translate.instant('SELECT_ALL')}`,
        isDisabled: this.isAllAccountSelected,
      });
    }

    controls.push({
      id: "clear-filter",
      text: this.translate.instant('CLEAR_FILTER'),
      isVisible: this.filtering,
      isDisabled: _.isEmpty(this.selectedFilterData)
    },
    {
      id: 'accounts-sort',
      text: this.sortBy?.text,
      isDisabled: false,
      icon:'assets/imgs/sort_with_double_arrows.svg'
    });

    this.resultsAccountsHeaderModel = {
      id: 'accounts-results',
      title: `${this.translate.instant('ACCOUNT_MY_ACCOUNTS')} (${this.filteredList?.length})`,
      controls: controls
    };
  }

  private initChangeRequestAccountsHeaderModel() {

    this.initSelectedAccountsHeaderModel();

    this.initSelectedOKAccountsHeaderModel();

    this.myAccountChangeRequestsHeaderModel = {
      id: 'my-account-change-requests',
      title: ((this.searching || this.filtering) ? this.translate.instant('RESULTS_CAP') : this.translate.instant('MY_CHANGE_REQUESTS')) + ' (' + this.filteredAccountCRs.length + ')',
      controls: [
        {
          id: "clear-filter",
          text: this.translate.instant('CLEAR_FILTER'),
          isVisible: this.filtering,
          isDisabled: _.isEmpty(this.selectedFilterData)
        }
      ]
    };
  }

  private _updateAccountInteractions(data: Account) {
    const account = this.allAccounts.find(acc => acc.id === data.id);
    if (account) {
      account.interactionType = data.interactionType;
      account.interactionDate = data.interactionDate;
    }
  }

  private initSelectedAccountsHeaderModel() {
    let count = this.accountService.selectedAccounts.length;
    let controls: any[] = [];
    let isHidden = (this.accountService.accessedAccountListFrom === PageName.ShortCallHomeComponent || !((this.accountService.selectedAccounts && this.accountService.selectedAccounts.length>0)  && (this.accountListMode===this.compViewMode.ADDNEW || this.accountListMode===this.compViewMode.SELECTION)));
    if (!isHidden) {
      controls.push({
        id: 'accounts-select-all-clear',
        text: `${this.translate.instant('CLEAR')}`,
        isDisabled: this.accountManagementOfflineService.selectedMode === AccountPlanMode.EDIT || 
        ((this.accountService.accessedAccountListFrom === PageName.QuoteDetailsComponent || this.accountService.accessedAccountListFrom === PageName.NewQuoteComponent) && this.quotesToolService.checkOpportunityOrOrderExistsForQuotes()),
      });
    }
    this.selectedAccountsHeaderModel = {
      id: 'selectededitems',
      title: `${this.translate.instant('SELECTED')} (${count})`,
      controls: controls
    }
  }

  private initNearByAccountsHeaderModel() {
    this.nearByAccountsHeaderModel = {
      id: 'nearByAccounts',
      title: `${this.translate.instant('NEAR_BY_ACCOUNTS')} (${this.nearByAccountsToDisplay.length})`,
      controls: [{
        id: 'refresh',
        icon: "assets/imgs/syncIcon.svg",
        isDisabled: this.device.isOffline
      }]
    }
  }

  private async initNearByAccounts() {
    if (this.accountService.accessedAccountListFrom === PageName.ShortCallHomeComponent) {
      await this.uiService.displayLoader();
      //account <= 5km
      this.nearByAccounts = await this.accountService.getAccountNearByMeter(5000, this.selectedAccountId);
      if (this.searching || this.filtering) {
        this.nearByAccountsToDisplay = this.nearByAccounts.filter(acc => this.filteredList.some(fAcc => fAcc.id === acc.id));
      } else {
        this.nearByAccountsToDisplay = this.nearByAccounts;
      }
      this.initNearByAccountsHeaderModel();
      await this.uiService.dismissLoader();
    }
  }

  private initSelectedOKAccountsHeaderModel() {
    let count = this.accountService.selectedOKAccounts.length;
    this.selectedOKAccountsHeaderModel = {
      id: 'selectededokitems',
      title: `${this.translate.instant('SELECTED')} (${count})`,
      controls: []
    }
  }

  private initAllAccountsHeaderModel() {

    this.initNearByAccountsHeaderModel();
    this.initSelectedAccountsHeaderModel();
    this.initSelectedOKAccountsHeaderModel();

    let count  = this.allAccounts.length
    if (this.accountListAccessedFrom === 'AccountPage' && !this.accountService.isOneKeyAffiliatedToAccount && !(this.accountService.accessedAccountListFrom === PageName.MarketScanDetailsComponent) && !this.accountService.isMarketingBusinessPlanInvoked && !(this.accountService.accessedAccountListFrom === PageName.NewOpportunityComponent && this.newOpportunityContactPrimaryTargetFlow)) {
      count = this.accountService.allAccountsCount;
    } else if (this.accountService.isOneKeyAffiliatedToAccount) {
      count = this.accountService.oneKeyAccountsCount;
    }

    if (!this.sortBy &&  this.sortPopoverData && this.sortPopoverData.length > 0) {
      this.sortBy = this.sortPopoverData[0];
    }

    let controls: any[] = [];

    if (this.shouldSelectAllOptionVisible()) {
      controls.push({
        id: 'accounts-select-all',
        text: `${this.translate.instant('SELECT_ALL')}`,
        isDisabled: this.isAllAccountSelected,
      });
    }

    controls.push({
      id: "clear-filter",
      text: this.translate.instant('CLEAR_FILTER'),
      isVisible: this.filtering,
      isDisabled: _.isEmpty(this.selectedFilterData)
    },
    {
      id: 'accounts-sort',
      text: this.sortBy?.text,
      isDisabled: false,
      icon:'assets/imgs/sort_with_double_arrows.svg'
    });

    this.allAccountsHeaderModel = {
      id: 'accounts-all',
      title: `${this.translate.instant('ACCOUNT_MY_ACCOUNTS')} (${count})`,
      controls: controls
    }

    this.checkEnableDisableSelectAllOption();
  }

  private initGlobalSearchedAccountsHeaderModel() {

    this.initSelectedAccountsHeaderModel();

    this.globalSearchedAccountsHeaderModel = {
      id: 'global-search-results',
      title: this.translate.instant('ACCOUNT_OTHER_ACCOUNTS') + ' (' + this.accountService.globalSearchResults.length + ')',
      controls: []
    }
  }

  private initOneKeySearchedAccountsHeaderModel() {
    this.initSelectedOKAccountsHeaderModel();

    this.oneKeySearchedAccountsHeaderModel = {
      id: 'onekey-search-results',
      title: this.translate.instant('ACCOUNT_ONEKEY_ACCOUNTS') + ' (' + this.onekeyApiService.oneKeyAccounts.length + ')',
      controls: []
    }
  }

  isAllAccountSelected: boolean = false;

  onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'accounts-sort':
        this.sortAccountsList();
        break;
      case 'accounts-select-all':
        this.isAllAccountSelected = true;
        this.selectAllAccounts();
        this.initSelectedAccountsHeaderModel();
        break;
      case 'accounts-select-all-clear':
        this.clear();
        this._initKeyControlsAreaModel();
        break;
      case 'clear-filter':
        this.clearFilters();
        break;
      case 'refresh':
        this.refreshNearByAccounts();
        break;
      default:
        console.log("Not implemented", id);
    }
  }

  private async refreshNearByAccounts() {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('SCHEDULER_NO_INTERNET_CONNECTION'), 'Account Page', 'top', ToastStyle.DANGER);
    } else {
      await this.uiService.displayLoader();
      this.authService.user.currentLocation = await this.locationService.getCurrentLocationName();
      // this.events.publish('updateUserLocation');
      this.initNearByAccounts();
    }
  }

  sortAccountsListBasedOnSelection(selectedSortOption){
    if (!this.searching && !this.filtering) {
      if (selectedSortOption.value === 'interactionDate' || selectedSortOption.value === 'createdOn') {
        this.allAccounts = this.sortAccountsOnInteraction(this.allAccounts, selectedSortOption.value);
      } else if(selectedSortOption.value == 'surveyEligible') {
        this.allAccounts = this.sortAccountsOnSurveyEligibility(this.allAccounts);
      } else if (selectedSortOption.value == 'accountName' && this.translate.currentLang == 'zh_CN') {
        this.allAccounts = this.allAccounts.sort((a, b) => a['accountName'].localeCompare(b['accountName'], ["zh-CN-u-co-pinyin"]));
      } else {
        if (selectedSortOption.asc) {
          this.allAccounts.sort((a, b) => {
            if (a[selectedSortOption.value] > b[selectedSortOption.value]) return 1;
            else return -1;
          })
        }
        else {
          this.allAccounts.sort((a, b) => {
            if (a[selectedSortOption.value] > b[selectedSortOption.value]) return -1;
            else return 1;
          })
        }
      }
      this.accounts = this.allAccounts.slice(0, this.recordCount);
    }else{
      if (selectedSortOption.value === 'interactionDate' || selectedSortOption.value === 'createdOn') {
        this.filteredList = this.sortAccountsOnInteraction(this.filteredList, selectedSortOption.value);
      } else if(selectedSortOption.value == 'surveyEligible') {
        this.filteredList = this.sortAccountsOnSurveyEligibility(this.filteredList);
      } else if (selectedSortOption.value == 'accountName' && this.translate.currentLang == 'zh_CN') {
        this.filteredList = this.filteredList.sort((a, b) => a['accountName'].localeCompare(b['accountName'], ["zh-CN-u-co-pinyin"]));
      } else {
        if (selectedSortOption.asc) {
          this.filteredList.sort((a, b) => {
            if (a[selectedSortOption.value] > b[selectedSortOption.value]) return 1;
            else return -1;
          })
        }
        else {
          this.filteredList.sort((a, b) => {
            if (a[selectedSortOption.value] > b[selectedSortOption.value]) return -1;
            else return 1;
          })
        }
      }
    }
  }

  /**
   * sort account list on interaction date or created date follwed by account name
   * @param accounts list of account
   * @param sortOnvalue sorts on selected value
   */
  sortAccountsOnInteraction(accounts: Account[], sortOnvalue: string): Account[] {
    let accountWithoutInteraction: Account[] = [];
    let accountWithInteraction: Account[] = [];
    if (accounts.length > 0 && sortOnvalue) {
      accounts.forEach(account => {
        if (account.interactionDate) {
          accountWithInteraction.push(account);
        } else {
          accountWithoutInteraction.push(account);
        }
      });

      accountWithInteraction.sort((a, b) => {
        let sort;
        if (sortOnvalue === 'createdOn') {
          sort = a.interactionDate - b.interactionDate;
        } else {
          sort = b.interactionDate - a.interactionDate;
        }
        if (sort === 0) {
          return (a.accountName > b.accountName) ? 1 : -1;
        }
        return sort;
      });
      accountWithoutInteraction.sort((a, b) => {
        let sort = b.createdOn - a.createdOn;
        if (sort === 0) {
          return (a.accountName > b.accountName) ? 1 : -1;
        }
        return sort;
      });
      let sortedAccounts;
      if (sortOnvalue === 'createdOn') {
        sortedAccounts =accountWithoutInteraction.concat(accountWithInteraction);
      } else if (sortOnvalue === 'interactionDate') {
        sortedAccounts =accountWithInteraction.concat(accountWithoutInteraction);
      }
      return sortedAccounts;
    }
  }

  /**
   * sort account list on survey eligible follwed by account name
   * @param accounts list of account
   */
  sortAccountsOnSurveyEligibility(accounts: Account[]): Account[] {
    let accountNotEligible: Account[] = [];
    let accountEligible: Account[] = [];
    if (accounts.length > 0) {
      accounts.forEach(account => {
        if (account.isForcedHideAddIcon) {
          accountNotEligible.push(account);
        } else {
          accountEligible.push(account);
        }
      });

      accountEligible.sort((a, b) => {
        return (a.accountName > b.accountName) ? 1 : -1;
      });
      accountNotEligible.sort((a, b) => {
        return (a.accountName > b.accountName) ? 1 : -1;
      });
      return accountEligible.concat(accountNotEligible);
    }
  }

  get showTertiaryFlag(): boolean {
    if (this.sortBy && (this.sortBy.value == 'modifiedOn' || this.sortBy.value == 'interactionDate' || this.sortBy.value == 'createdOn')) {
      return true;
    }
    return false;
  }

  public getSecondaryInfo(account:Account){
    const secondaryInfo: string = this.secondaryInfoService.getSecondaryInfoFormatedText(account, SecondaryInfoEntityName.Account);
    this.hasSecondaryInfo = !_.isEmpty(secondaryInfo);
    return secondaryInfo;
  }

  ngOnDestroy() {
    if (this.schedulerSelectionSubscription) {
      this.schedulerSelectionSubscription.unsubscribe();
    }
    if (this.ICase) {
      this.ICase.unsubscribe();
    }
    this.events.unsubscribe("accounts:customForm");
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.accountService.selectedOKAccount = null;
    this.accountService.isOneKeySearching = false;
    this.IaccountCRsSubscription.unsubscribe();
    this._resetFlagOpenedAffiliatedData();
    if (this.IUserTagDeletedSubscription) this.IUserTagDeletedSubscription.unsubscribe();
  }

  private _updateAccountIsSelectedRecursively(account:Account,isSelected:boolean){
    account.isSelected = isSelected;
    if(account.subAccounts && account.subAccounts.length != 0){
      account.subAccounts.forEach(acc=>{
        this._updateAccountIsSelectedRecursively(acc,false);
      })
    }
  }

  deselectAllAccounts() {
    if (this.searching || this.filtering) {
      this.filteredList.map(obj => obj.isSelected = false);
      this.filteredList.forEach(obj => {
        this._updateAccountIsSelectedRecursively(obj, false);
      });
      if (this.accountService.isGlobalSearching && this.accountService.globalSearchResults) {
        this.accountService.globalSearchResults.filter(acc => acc.isSelected).map(acc => acc.isSelected = false);
      }
    } else {
      this.accountService.accounts.map(obj => obj.isSelected = false);
      this.allAccounts.forEach(obj => {
        this._updateAccountIsSelectedRecursively(obj, false);
      });
    }
    this.checkEnableDisableSelectAllOption();
  }

  selectAllAccounts() {

    // Add the existing Accounts
    let existingSelectedAccounts = [];
    if (this.accountService.selectedAccounts.length) {
      existingSelectedAccounts = [...this.accountService.selectedAccounts];
    }
    this.accountService.selectedAccounts = [];
    let accountsToConcat = [];
    // Add the new one into the existing one.
    if (this.searching || this.filtering) {
      this.selectSearchedAccounts();
      accountsToConcat = existingSelectedAccounts.concat(this.filteredList);
    } else {
      this.selectNonSearchedAccounts()

      accountsToConcat = existingSelectedAccounts.concat(this.allAccounts);

      accountsToConcat.forEach(account => {
        this._findAndAddAccountsRecursively(account, accountsToConcat);
      });

      accountsToConcat.forEach(eachAccount => {
        eachAccount.isSelected = true;
        this._updateAccountIsSelectedRecursively(eachAccount, true);
      });
    }
    if (this.accountService.accessedAccountListFrom === PageName.MarketScanDetailsComponent) {
      let highestSeq = accountsToConcat.length;
      if (!_.isEmpty(existingSelectedAccounts)) {
        highestSeq = existingSelectedAccounts[0]['sequence'] + accountsToConcat.length + 1;
      }
      accountsToConcat.map(ac => ac['sequence'] = highestSeq--);
    }
    this.accountService.selectedAccounts = existingSelectedAccounts.concat(accountsToConcat);
    // Remove the duplicates
    let ids = this.accountService.selectedAccounts.map(o => o.id);
    let filteredUniqueResult = this.accountService.selectedAccounts.filter(({id}, index) => !ids.includes(id, index + 1));
    this.accountService.selectedAccounts = filteredUniqueResult;

    this.isDirty = this.isAccountsDirty();

    this.updateEmptyMessage()
    this._cd.detectChanges();
    this._initKeyControlsAreaModel();
    let doneButton = this.indHeaderLeftModel.controls.find((c) => c.id ===  "done");
    if(doneButton) {
      doneButton.isDisabled = !this.isDirty;
    }
    this.checkEnableDisableSelectAllOption();
  }

  private selectSearchedAccounts() {
    this.filteredList.map(obj => obj.isSelected = true);
    this.filteredList.forEach(obj => {
      this._updateAccountIsSelectedRecursively2(obj, true);
    });
  }

  private selectNonSearchedAccounts() {
    this.accountService.accounts.map(obj => obj.isSelected = true);
    this.allAccounts.forEach(obj => {
      this._updateAccountIsSelectedRecursively2(obj, true);
    });
  }

  private deselectThisAccount(account: Account) {
    if (this.searching || this.filtering) {
      this.filteredList.map(each => {
        this._setChildAccountDeselected(each, account.id);
      });
    } else {
      this.allAccounts.map(each => {
        this._setChildAccountDeselected(each, account.id);
      });
    }
  }

  private _setChildAccountDeselected(account: Account, id: string) {
    if (account.id === id) { account.isSelected = false; }
    if(account.subAccounts && account.subAccounts.length != 0) {
      account.subAccounts.forEach(acc => {
        this._setChildAccountDeselected(acc, id);
      });
    }
  }

  private _updateAccountIsSelectedRecursively2(account: Account, isSelected: boolean){
    account.isSelected = isSelected;
    if(account.subAccounts && account.subAccounts.length != 0){
      account.subAccounts.forEach(acc=>{
        this._updateAccountIsSelectedRecursively2(acc, isSelected);
      })
    }
  }

  private shouldSelectAllOptionVisible(): boolean {
    if (this.accountService.selectedFor == AccountSelectedFor.SCHEDULER_SELECTOR && this.accountService.isSchedulerInvoked) {
      return true;
    } else if (this.accountService.selectedFor == AccountSelectedFor.ADD_TO_MEETING) {
      return !this.isSingleSelectionHCOHCPEnabled && !this.allowAffiliatedHCOSelectionOnly;
    }
    return false;
  }

  private checkEnableDisableSelectAllOption(isSelected: boolean = true, account?: Account) {
    if (this.shouldSelectAllOptionVisible()) {
      let shouldEnable = this.shouldEnableSelectAllOption();

      if (!shouldEnable) {
        if (isSelected) {
          // In case of selected then only allowed to select all the accounts.

          // Means, My Accounts are selected and we need to make sure they will look selected.
          if (this.searching || this.filtering) {
            this.selectSearchedAccounts();
          } else {
            this.selectNonSearchedAccounts();
          }
        }
      }

      if (!isSelected) {
        // If an account is unselected then have to update the list ✔️ should become [+].
        // Need to unselect the selected accounts;
        if (account) {
          this.deselectThisAccount(account);
        }
      }

      if (this.searching || this.filtering) {
        if (this.accountService.selectedAccounts.length !== this.filteredList.length) {
          this.enableDisableSelectAllOption(shouldEnable ? false : true);
        } else {
          this.enableDisableSelectAllOption(true);
        }
      } else {
        if (this.accountService.selectedAccounts.length !== this.allAccounts.length) {
          this.enableDisableSelectAllOption(shouldEnable ? false : true);
        } else {
          this.enableDisableSelectAllOption(true);
        }
      }
    }else{
      this.initSelectedAccountsHeaderModel();
    }
  }

  private getAllIds(accounts: Account[], ids: string[]) {
    accounts.forEach(account => {
      if (!ids.includes(account.id)) {
        ids.push(account.id);
      }
      if (account.subAccounts && account.subAccounts.length) {
        this.getAllIds(account.subAccounts, ids);
      }
    });
  }

  private shouldEnableSelectAllOption(): boolean {
    // When after applying the the advance filter,
    // My Accounts having the accounts which are already selected.
    // We should not show the 'Select All' option.
    if (this.accountService.selectedAccounts.length) {
      let selectedIds = this.accountService.selectedAccounts.map(o => o.id);

      let ids: string[] = [];
      if (this.searching || this.filtering) {
        this.getAllIds(this.filteredList, ids);
      } else {
        this.getAllIds(this.allAccounts, ids);
      }

      let comparableIds = ids; //(this.searching) ? this.filteredList.map(o => o.id) : this.allAccounts.map(o => o.id);
      if (comparableIds.length) {
        let index = 0;
        selectedIds.forEach(id => { if (comparableIds.includes(id)) index += 1; });
        return (index === comparableIds.length) ? false : true;
      } else {
        console.warn('`comparableIds` are empty, this should not be the case.');
        return false;
      }
    }
    return true;
  }

  private enableDisableSelectAllOption(shouldDisable: boolean) {
    let selectAllButton;

    if (this.searching || this.filtering) {
      selectAllButton = this.resultsAccountsHeaderModel.controls.find((c) => c.id ===  "accounts-select-all");
    } else {
      selectAllButton = this.allAccountsHeaderModel.controls.find((c) => c.id ===  "accounts-select-all");
    }

    if (selectAllButton) {
      selectAllButton.isDisabled = shouldDisable;
      this.isAllAccountSelected = shouldDisable;
    }

    this.initSelectedAccountsHeaderModel();
  }

  public async onAccountSelected(account: Account, checked) {
    if (this.enableOneTimeVeevaMeeting && account.isUsedInOneTimeMeeting && account.veevaRecordState == 200000004) {
      this.notificationService.notify(`${this.translate.instant("This account is under review and is already used in another meeting. Please try again with another account")}`, "");
      account.isSelected = false;
      return;
    }
    const activityTypeFieldText = this.activityService.selectedActivity?.activityTypeName
    const isInStoreMeeting = this.accountVisitRecordCheckResponse?.isAccountVisitRecord && activityTypeFieldText === this.translate.instant("INSTORE");
    if(isInStoreMeeting){
      if(checked && this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_applyabaccountlevelfilter']){
        // check selected A/B Level Accounts
        if(account.accountLevel !='548910000' && account.accountLevel !='548910001'){
          this.notificationService.notify(this.translate.instant('NON_AB_LEVEL_ACCOUNT') , 'Meeting Details', 'top', ToastStyle.INFO);
          account.isSelected =false
          return;
        }
      }
    }
    if (this.accountService.accessedAccountListFrom == PageName.PhoneCallDetailsComponent && this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.PhoneCall) {
      this.trackingService.tracking('Click+button', TrackingEventNames.PHONECALL)
    }

    if ((this.accountService.accessedAccountListFrom === PageName.QuoteDetailsComponent || this.quotesToolService.preloadedData) && this.accountService.selectedAccounts.length === 1) {
      if (!checked) {
        this.notificationService.notify(this.translate.instant('AT_LEAST_ONE_ACCOUNT_QUOTE'), 'Account List', 'top', ToastStyle.INFO);
        return;
      } else if (checked && this.quotesToolService.checkOpportunityOrOrderExistsForQuotes()) {
        this.notificationService.notify(this.translate.instant('OPPORTUNITY_ORDER_EXISTS'), 'Account List', 'top', ToastStyle.INFO);
        account.isSelected = false;
        return;
      }
    }

    if (this.accountService.isCaseManagementInvoked ||
        this.accountService.selectedFor == AccountSelectedFor.ORDER_ACTIVITY_SELECTION ||
        this.accountService.selectedFor == AccountSelectedFor.FOLLOW_UP_ACTION_SELECTION ||
        this.accountService.selectedFor == AccountSelectedFor.CONTACT_ACCOUNT_SELECTION ||
        this.accountService.selectedFor == AccountSelectedFor.OPPORTUNITY_SELECTION ||
        this.accountService.selectedFor == AccountSelectedFor.ASSET_LOCATION_SELECTION ||
        this.accountService.selectedFor == AccountSelectedFor.GENERAL_SINGLE_SELECTION ||
        this.accountService.selectedFor == AccountSelectedFor.ACCOUNT_VISIT ||
        this.accountService.accessedAccountListFrom == PageName.AccountPlanDetailsComponent ||
        this.accountService.accessedAccountListFrom == PageName.NewAccountPlanComponent ||
        this.accountService.accessedAccountListFrom === PageName.ShortCallHomeComponent ||
        (this.isSingleSelectionHCOHCPEnabled && this.accountService.accessedAccountListFrom == PageName.ActivitiesDetailsPaneComponent))
        {
      if (this.accountService.accessedAccountListFrom === PageName.ShortCallHomeComponent) {
        if (!checked) return;
        this.nearByAccounts.forEach(acc => acc.isSelected = (acc.id === account.id));
      }
      //Only single select allowed
      this.accountService.accounts.map(obj => obj.isSelected = false);
      if (!_.isEmpty(this.affiliatedAccounts)) this.affiliatedAccounts.map(obj => obj.isSelected = false);
      this.allAccounts.forEach(obj => {
        this._updateAccountIsSelectedRecursively(obj,false);
      });

      let index = this.accountService.accounts.findIndex(obj => obj.id == account.id);
      if (index >= 0) {
        account = this.accountService.accounts[index];
        account.isSelected = checked;
        if (!_.isEmpty(this.affiliatedAccounts)) {
          let affiliationAccount: Account;
          this.affiliatedAccounts.forEach(obj => {
            let res = this._findAccountByIdRecursively(obj, account.id);
            if (res) {
              affiliationAccount = res;
            }
          });
          if (affiliationAccount) {
            affiliationAccount.isSelected = checked;
          }
        }
      }
      // If false clear empty the selection
      if (!checked) {
        this.accountService.selectedAccounts = [];
      }
      else {
        this.accountService.selectedAccounts = [];
        this.accountService.selectedAccounts.push(account);
        const index = this.allAccounts.findIndex(obj => obj.id == account.id);
        if (index >= 0) {
          this.allAccounts[index].isSelected = checked;
        }
      }
      this.isDirty = this.isAccountsDirty();
    }
    /**************** global search accounts ****************/
    else if (this.accountService.isGlobalSearching) {
      // Single select
      const curSelected = this.accountService.selectedAccounts[0];
      this.accountService.selectedAccounts = [];

      if (curSelected) {
        if (checked) {
          if (curSelected.id !== account.id) {
            curSelected.isSelected = false;
            this.accountService.selectedAccounts.pop();
            this.accountService.selectedAccounts.push(account);
              let loader = await this.loadingCtrl.create();
              loader.present();
              await this._updateSelectableLinkedEntity(account.id);
              if(loader) loader.dismiss();
            setTimeout(()=>{
              this.ionContent.scrollToTop();
            }, 100);
          }
        } else {
          // Has to be de-selection of current selected
          curSelected.isSelected = false;
          this.accountService.selectedAccounts.pop();
          this.accountService.contactIdsToAdd = [];
          this.selectablLinkedEntityData = [];
        }
      } else if (checked) {
        this.accountService.selectedAccounts.push(account);
          let loader = await this.loadingCtrl.create();
          loader.present();
          await this._updateSelectableLinkedEntity(account.id);
          if(loader) loader.dismiss();
        setTimeout(()=>{
          this.ionContent.scrollToTop();
        }, 100);
      }
    }
    /************************************************/
    else if (this.accountService.accountPageMode === ComponentViewMode.SELECTION || (this.accountListMode === ComponentViewMode.SELECTION && this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN))) {
      this.accountService.accounts.map(obj => obj.isSelected = false);
      if (checked) {
        this.accountService.selectedAccounts = [account];
        const index = this.accountService.accounts.findIndex(obj => obj.id == account.id);
        if (index >= 0) {
          this.accountService.accounts[index].isSelected = checked;
        }
      }
      else {
        this.accountService.selectedAccounts = [];
      }
      this.isDirty = this.isAccountsDirty();
    } else {
      if(this.accountService.selectedFor == AccountSelectedFor.MARKET_SCAN){
        let idx = this.allAccounts.findIndex(obj => obj.id == account.id);
        if (idx >= 0) {
          account = this.allAccounts[idx];
          account.isSelected = checked;
        }
      }
      let index = this.accountService.accounts.findIndex(obj => obj.id == account.id);
      if (index >= 0) {
        account = this.accountService.accounts[index];
        account.isSelected = checked;
        if (this.affiliatedAccounts.length != 0) {
          let affiliationAccount: Account;
          this.affiliatedAccounts.forEach(obj => {
            let res = this._findAccountByIdRecursively(obj, account.id);
            if (res) {
              affiliationAccount = res;
            }
          });
          if (affiliationAccount) {
            affiliationAccount.isSelected = checked;
          }
        }
        let selectedAccountIndex = this.accountService.selectedAccounts.findIndex(obj => obj.id == account.id);
        if (!checked) {
          if (selectedAccountIndex >= 0)
            this.accountService.selectedAccounts.splice(selectedAccountIndex, 1);
        } else {
          if (selectedAccountIndex < 0) {
            if (this.accountService.accessedAccountListFrom === PageName.MarketScanDetailsComponent) {
              account['sequence'] = this.accountService.selectedAccounts.length > 0 ? this.accountService.selectedAccounts[0]['sequence'] + 1 : 1;
              this.accountService.selectedAccounts.unshift(account);
            } else {
              this.accountService.selectedAccounts.push(account);
            }
          }
        }
        this.isDirty = this.isAccountsDirty();
      }
    }
    this.updateEmptyMessage()
    this._cd.detectChanges();
    this._initKeyControlsAreaModel();
    let doneButton = this.indHeaderLeftModel.controls.find((c) => c.id ===  "done");
    if(doneButton) {
      doneButton.isDisabled = !this.isDirty;
    }
    this.checkEnableDisableSelectAllOption(checked, account);
  }

  onOKAccountSelected(account, checked) {
    if (this.accountService.isOneKeySearching) {
      // Single select
      const curSelected = this.accountService.selectedOKAccounts[0];
      this.accountService.selectedOKAccounts = [];

      if (curSelected) {
        if (checked) {
          if (curSelected['workplaceEid'] !== account['workplaceEid']) {
            curSelected.isSelected = false;
            this.accountService.selectedOKAccounts.pop();
            this.accountService.selectedOKAccounts.push(account);
          }
        } else {
          // Has to be de-selection of current selected
          curSelected.isSelected = false;
          this.accountService.selectedOKAccounts.pop();
        }
      } else if (checked) {
        this.accountService.selectedOKAccounts.push(account);
      }
    }
    this._cd.detectChanges();
    this._initKeyControlsAreaModel();
  }

  private _findAndAddAccountsRecursively(account: Account, copy: Account[]) {
    if (account.subAccounts && account.subAccounts.length > 0) {
      account.subAccounts.forEach(subAccount => {

        if (!copy.includes(subAccount)) {
          copy.push(subAccount);
        }
        let subAccounts = this._findAndAddAccountsRecursively(subAccount, copy);
        copy.push(...subAccounts);
      });
    } else {
      if (!copy.includes(account)) {
        copy.push(account);
      }
    }
    return [];
  }

  private _findAccountByIdRecursively(account:Account,id:string):Account{
    let foundAccount:Account;
    if(account.id == id){
      foundAccount = account;
    }else{
      if(account.subAccounts.length != 0){
        account.subAccounts.forEach(subAccount => {
          try {
            let foundSubAccount = this._findAccountByIdRecursively(subAccount,id);
            if(foundSubAccount){
              foundAccount = foundSubAccount;
            }
          } catch (error) {
            console.log('Recursion Error');
          }
        })
      }
    }
    return foundAccount;
  };

  private _foundAccountInAllAccountsRecursively(id:string){
    let foundAccount:Account;
    if(this.allAccounts && this.allAccounts.length != 0){
      for(let i=0;i<this.allAccounts.length;i++){
        let a = this.allAccounts[i];
        if(a.id == id){
          foundAccount = a;
          break;
        }else if(a.subAccounts && a.subAccounts.length != 0){
          a.subAccounts.forEach(subAccount => {
            try {
              let foundSubAccount = this._findAccountByIdRecursively(subAccount,id);
              if(foundSubAccount){
                foundAccount = foundSubAccount;
              }
            } catch (error) {
              console.log('Recursion Error');
            }
          });
          if(foundAccount){
            break;
          }
        }
      }
    }
    return foundAccount;
  }

  initiateSchedulerSelection(res: any) {
    this.accountService.selectedAccounts = [];
    Object.assign(this.accountService.selectedAccounts, res);
    this.accountService.accounts.map(item => {
      item.isSelected = this.accountService.selectedAccounts.some(o => o.id === item.id);
    });
    this.isDirty = false;
  }

  isAccountsDirty(): boolean {
    if(this.accountService.isGlobalSearching){
      return false;
    }
    if (this.accountService.isCaseManagementInvoked) {
      let flag: boolean = true;
      if (this.caseManagementService.currentCase && this.caseManagementService.currentCase._case_account && this.accountService.selectedAccounts.length == 1 && this.caseManagementService.currentCase._case_account.id == this.accountService.selectedAccounts[0].id) {
        flag = false;
      }else if (this.accountService.selectedAccounts.length == 0 && !this.caseManagementService.currentCase._case_account){
        flag = false;
      }
      return flag;
    }if(this.accountService.selectedFor == AccountSelectedFor.SCHEDULER_SELECTOR && this.accountService.isSchedulerInvoked){
      return (_.xor(this.currentSchdeuleAccount,this.accountService.selectedAccounts).length !== 0);
    } else if (this.accountService.accessedAccountListFrom == PageName.AccountPlanDetailsComponent || this.accountService.accessedAccountListFrom == PageName.NewAccountPlanComponent) {
      return _.xorBy(this.selectedAccountIds, this.accountService.selectedAccounts, 'id').length !== 0;
    } else if (this.accountService.selectedFor == AccountSelectedFor.CALLPLAN_FILTER) {
      return true;
    }else if (this.accountService.selectedFor == AccountSelectedFor.OPPORTUNITY_SELECTION){
      let opportunity:Opportunity = this.opportunityMgmtService.newOpportunity$.getValue();
      if (opportunity && opportunity.accountID) {
        if(this.accountService.selectedAccounts && this.accountService.selectedAccounts.length != 0){
          return opportunity.accountID != this.accountService.selectedAccounts[0].id;
        }else{
          return true;
        }
      }else{
        return !(this.accountService.selectedAccounts && this.accountService.selectedAccounts.length == 0);
      }
    }else if (this.accountService.selectedFor == AccountSelectedFor.ASSET_LOCATION_SELECTION){
      let selectedAsset: CustomerAsset = this.fieldMaterialManagementService.selectedAsset;
      if (selectedAsset && selectedAsset.indskr_newassetlocation) {
        if(this.accountService.selectedAccounts && this.accountService.selectedAccounts.length != 0){
          return (selectedAsset.indskr_newassetlocation != this.accountService.selectedAccounts[0].id && selectedAsset.msdyn_account != this.accountService.selectedAccounts[0].id);
        }else{
          return true;
        }
      }else{
        return !(this.accountService.selectedAccounts && this.accountService.selectedAccounts.length == 0);
      }
    }else if(this.accountService.selectedFor == AccountSelectedFor.GENERAL_SINGLE_SELECTION){
      if (this.selectedAccountId){
        if(this.accountService.selectedAccounts && this.accountService.selectedAccounts.length != 0){
          return this.selectedAccountId != this.accountService.selectedAccounts[0].id;
        }else{
          return true;
        }
      }else{
        return !(this.accountService.selectedAccounts && this.accountService.selectedAccounts.length == 0);
      }
    }else if(this.accountService.selectedFor == AccountSelectedFor.GENERAL_MULTIPLE_SELECTION){
      if (this.selectedAccountIds && this.accountService.selectedAccounts.length === this.selectedAccountIds.length) {
        return this.accountService.selectedAccounts.some(item => {
          let found = this.selectedAccountIds.some(acc => item.id === acc.id);
          if (!found) {
            return true;
          }
        });
      }
      else {
        return true;
      }
    }
    else if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.FollowUp) {
      let flag: boolean = true;
      if ((this.activityService.selectedActivity as FollowUpActivity).accountId && this.accountService.selectedAccounts.length == 1 && (this.activityService.selectedActivity as FollowUpActivity).accountId == this.accountService.selectedAccounts[0].id) {
        flag = false;
      }else if (this.accountService.selectedAccounts.length == 0 && (this.activityService.selectedActivity as FollowUpActivity).accountId == ''){
        flag = false;
      }
      return flag;
    }else if(this.followUpActivityDataService.inMeetingFollowupActionActivity && this.navParams && this.navParams.data && this.navParams.data.type && (this.navParams.data.type == 'InMeetingFollowupPushedAccountSelectionView' || this.navParams.data.type == 'InPresentationFollowupPushedAccountSelectionView')){
      let flag: boolean = true;
      if (this.followUpActivityDataService.inMeetingFollowupActionActivity .accountId && this.accountService.selectedAccounts.length == 1 && this.followUpActivityDataService.inMeetingFollowupActionActivity .accountId == this.accountService.selectedAccounts[0].id) {
        flag = false;
      }else if (this.accountService.selectedAccounts.length == 0 && this.followUpActivityDataService.inMeetingFollowupActionActivity .accountId == ''){
        flag = false;
      }
      return flag;
    } else if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Appointment) {
      if (this.accountService.selectedAccounts.length === this.activityService.selectedActivity['accounts'].length) {
        return this.accountService.selectedAccounts.some(item => {
          let found = this.activityService.selectedActivity['accounts'].some(acc => item.id === acc.id);
          if (!found) {
            return true;
          }
        });
      }
      else {
        return true;
      }
    }
    /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
    else if (this.emailService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Email) {
      if (this.emailService.selectedActivity['accounts'] && this.accountService.selectedAccounts.length === this.emailService.selectedActivity['accounts'].length) {
        return this.accountService.selectedAccounts.some(item => {
          let found = this.emailService.selectedActivity['accounts'].some(acc => item.id === acc.id);
          if (!found) {
            return true;
          }
        });
      }
      else {
        return true;
      }
    }
    /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
    else if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Order) {
      let flag: boolean = true;
      if ((this.activityService.selectedActivity as OrderActivity).accountId && this.accountService.selectedAccounts.length == 1 && (this.activityService.selectedActivity as OrderActivity).accountId == this.accountService.selectedAccounts[0].id) {
        flag = false;
      }else if (this.accountService.selectedAccounts.length == 0 && (this.activityService.selectedActivity as OrderActivity).accountId == ''){
        flag = false;
      }
      return flag;
    } //
    else if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.PhoneCall) {
      let flag: boolean = true;
      if ((this.activityService.selectedActivity as PhoneActivity).accountId && this.accountService.selectedAccounts.length == 1 && (this.activityService.selectedActivity as PhoneActivity).accountId == this.accountService.selectedAccounts[0].id) {
        flag = false;
      }else if (this.accountService.selectedAccounts.length == 0 && (this.activityService.selectedActivity as PhoneActivity).accountId == ''){
        flag = false;
      }
      return flag;
    } else if (this.accountService.selectedFor === AccountSelectedFor.MARKET_SCAN) {
      const marketScan: MarketScan = this.marketScanService.getSelectedMarketScan();
      if (marketScan.accounts && marketScan.accounts.filter(c => c.action != Action.DELETE).length === this.accountService.selectedAccounts.length) {
        return this.accountService.selectedAccounts.some(item => {
          const found = marketScan.accounts.filter(ac => ac.action != Action.DELETE).some(ac => ac.accountId === item.id && item['sequence'] === ac.sequence);
          if (!found) return true;
        });
      } else {
        return true;
      }
    }else if (this.accountService.selectedFor == AccountSelectedFor.CONTACT_ACCOUNT_SELECTION) {
      let flag: boolean = true;
      // if (this.accountService.selectedAccounts.length && this.paramAccount && this.paramAccount == this.accountService.selectedAccounts[0].id) {
      //   flag = false;
      // }
      return flag;
    } else if (this.accountService.accessedAccountListFrom == PageName.MarketingPlanDetails || this.accountService.accessedAccountListFrom === PageName.ShortCallHomeComponent) {
      if (this.selectedAccountId && !_.isEmpty(this.accountService.selectedAccounts)) {
        return this.accountService.selectedAccounts.some(item => item.id !== this.selectedAccountId);
      }
      return true;
    }

  }

  async trackToolsBack() {
    //THIS CODE SHOULD BE ENABLED - when kitbooking is getting created from Account tool and we click on account list 
    let isKitBookingDiscarded = await this.setBookingDataService.checkSetBookingDiscardConfirmationRequired().catch(err=>{});
    if(!isKitBookingDiscarded) return;
    if (this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) return;
    this.uiService.isAccountsFromToolDrawer = false;
    this.uiService.isContactsFromToolDrawer = false;
    this.uiService.toolsActivityActive = false;
    this.accountService.isEnableGoToAccountsTool = false;
    this.contactService.isEnableGoToContactsTool = false;
    this.events.publish("tools-back-clicked", PageName.AccountPageComponent);
    if(this.activityService.prevSelectedActivityOnTimeline && this.activityService.prevSelectedActivityOnTimeline instanceof AppointmentActivity
      && this.navService.getPreviousPageName() == PageName.ContactPageComponent && this.navService.getCurrentMasterPageName() == PageName.OpportunityManagementPage
      && !_.isEmpty(this.contactService.selectedContactOnTimeline)) {
      this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
      this.contactService.contactInformation = this.contactService.selectedContactOnTimeline;
    }
    if(this._showDiscardConfirmationForAccountForm || this.dynamicFormService.linkedEntityFormIsDirtyTracker){
      let cancelText = this.translate.instant("CANCEL");
      if(this.translate.currentLang == 'it') {
        cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
      }
      this.alertService.showAlert({
        header: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
        message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')}, this.translate.instant('DISCARD'), cancelText
      ).then (async res => {
          if(res.role == "ok") {
            if(!this.dynamicFormService.linkedEntityFormIsDirtyTracker){
              this.trackingService.tracking('DiscardSelected', TrackingEventNames.ACCOUNTS,true);
            }
            await this._closeAccountFormPage();
            if(this.uiService.showCancelDoneOnActivityDetails){
              this.activityService.selectedActivity = undefined;
              this.uiService.showNewActivity = false;
            }
            this.onCloseModal(false);
          }else{
            if(!this.dynamicFormService.linkedEntityFormIsDirtyTracker){
              this.trackingService.tracking('CancelSelected', TrackingEventNames.ACCOUNTS,true);
            }
          }
      });
    } else{
      if(this.uiService.showCancelDoneOnActivityDetails){
        if (!this.activityService.selectedActivityAtHome){
                  this.activityService.selectedActivity = undefined;
        this.uiService.showNewActivity = false;
        } else {
          this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
        }

      }
      this.onCloseModal(false);
    }
  }

  onCloseModal = async (isDone) => {
    if (!isDone) {
      this.events.publish('updateMandatoryField', PageName.AccountPageComponent);
    }
    if (this.accountService.selectedFor !== AccountSelectedFor.ADD_TO_MEETING && this.accountListMode !== ComponentViewMode.ADDNEW) {
      this.deltaService.deltaRecordsDTO.accounts.length = 0;
    }
    if (this.accountService.isSchedulerInvoked) {
      this.accountService.selected = undefined;
      this.accountService.isSchedulerInvoked = false;
      if (isDone) {
        this.schedulerService.setSelectionResult(this.accountService.selectedAccounts);
      }
      else {
       // this.schedulerService.setSelectionResult(this.currentSchdeuleAccount);
        this.accountService.clearFilters();
      }
      this.accountService.accounts.map(item => {
        item.isSelected = false;
      });
      this.navService.popWithPageTracking();
      this.footerService.initButtons(FooterViews.SchedulingDetails);
    }
    else if (this.accountService.isCaseManagementInvoked) {
      this.accountService.selected = undefined;
      let selectedAccounts: Account[] = [];
      if (this.caseManagementService.accessedFrom !== AccesingMode.PRESENTATION) {
        //this needs clean up - with child nav
        setTimeout(() => {
          this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
        }, 100);
      }
      if (isDone) {
        Object.assign(selectedAccounts, this.accountService.selectedAccounts);
        this.accountService.selectedAccounts = [];
        console.log(this.accountService.selectedAccounts);
      }
      else {
        this.accountService.selectedAccounts = [];
        selectedAccounts = undefined;
        this.accountService.clearFilters();
      }

      this.accountService.accounts.map(item => item.isSelected = false);
      this.caseManagementService.setDetailsOption({
        data: selectedAccounts,
        action: DETAILS_OPTION.ACCOUNT_SELECTION
      });

      if (this.caseManagementService.accessedFrom === AccesingMode.INMEETING ||
        this.caseManagementService.accessedFrom === AccesingMode.IN_PHONE_CALL ||
        this.caseManagementService.accessedFrom === AccesingMode.IN_SET_BOOKING) {
        if (this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent) {
          this.uiService.activeView = 'ContactPageRightPaneNav';
        } else if (this.caseManagementService.accessedFrom === AccesingMode.IN_SET_BOOKING) {

        } else {
          this.uiService.activeView = 'Appointment';
          if(this.caseManagementService.accessedFrom === AccesingMode.IN_PHONE_CALL){
            this.uiService.activeView = 'PhoneCall';
          }
        }
        if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
        }


        this.navCtrl.pop({ progressAnimation: false }).then(() => {
          this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
        });

        return;
      } else if (this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
        this.uiService.activeView = 'Meeting';
        this.accountService.isCaseManagementInvoked = false;
        this.navService.popWithPageTracking();
        return;
      }
      else if (this.caseManagementService.accessedFrom === AccesingMode.AGENDA ||
        this.caseManagementService.accessedFrom === AccesingMode.CALENDAR) {
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.accountService.isCaseManagementInvoked = false;
        this.navService.popWithPageTracking();
      }
      else if (this.caseManagementService.accessedFrom === AccesingMode.CONTACT) {
        this.uiService.activeView = 'ContactPageRightPaneNav';
        this.accountService.isCaseManagementInvoked = false;
        this.navService.popWithPageTracking();
      }
      else if (this.caseManagementService.accessedFrom === AccesingMode.CASE_TOOL) {
        this.accountService.isCaseManagementInvoked = false;
        this.navService.popWithPageTracking();
      }
      else{
        this.uiService.activeView = 'customer_inquiry';
        this.accountService.isCaseManagementInvoked = false;
        try {
          this.navService.popWithPageTracking().then(()=> {
            if (this.activityService.selectedActivityAtHome && this.navParams && this.navParams.data && this.navParams.get('from') && this.navParams.get('from') === 'AccountTool'
            && this.activityService.selectedActivityAtHome.type == ActivityType.CaseIntake) {
              this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
              this.caseManagementService.accessedFrom = AccesingMode.AGENDA;
              this.caseManagementService.assignSelectedCase(this.activityService.selectedActivity as CaseActivity);
            } else {
              this.activityService.selectedActivity = null;
            }
          });
        } catch (error) {
          console.log("unhandled case for inquiry navigation!!");
        }
      }

      if(this.caseManagementService.currentCase) {
        this.activityService.selectedActivity = this.caseManagementService.currentCase;
      }
      else{
        if(this.device.isMobileDevice) {
          this.uiService.showRightPane = false;
        }
      }
    }
    else if (this.accountService.accessedAccountListFrom == PageName.PhoneCallDetailsComponent && this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.PhoneCall) {
      if(isDone){
       await this.accountSelectionForPhoneCall();
       this.trackingService.tracking('DoneClicked', TrackingEventNames.PHONECALL)
      }
       this.closeAccountPageBackToPhonecall()
      return
    }
    /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
    else if (this.accountService.accessedAccountListFrom == PageName.EmailDetailsPageComponent && this.emailService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Email) {
      if(isDone){
       await this.accountSelectionForEmail();
       this.trackingService.tracking('DoneClicked', TrackingEventNames.EMAIL)
      }
       this.closeAccountPageBackToEmail()
      return
    }
    /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
    else if (this.accountService.accessedAccountListFrom == PageName.FollowUpActionDetailComponent) {
      if (this)
        if (isDone) {
          let followup:FollowUpActivity;
          if(this.navParams && this.navParams.data && this.navParams.data.type && (this.navParams.data.type == 'InMeetingFollowupPushedAccountSelectionView' || this.navParams.data.type == 'InPresentationFollowupPushedAccountSelectionView')){
            followup = this.followUpActivityDataService.inMeetingFollowupActionActivity
          }else if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.FollowUp){
            followup = (this.activityService.selectedActivity as FollowUpActivity)
          }
          this.isDirty = false;
          let account;
          if (this.accountService.selectedAccounts && this.accountService.selectedAccounts.length > 0) {
            account = this.accountService.selectedAccounts[0];
          }
          this.accountService.accessedAccountListFrom = null;
          followup.accountId = (account) ? account.id : null;
          followup.accountNameString = (account) ? account.accountName : '';
          followup.accountPlanId = null;
          followup.accountPlanNameString = '';
          followup.opportunityId = '';
          followup.offlineOpportunityId = '';
          followup.opportunityIdNameString = '';
          followup.pendingPushToDynamics = true;
          if (followup.marketingPlanId && followup.accountId) {
            const marketingPlan = this.marketingManagementOfflineService.marketingPlans.find(mbp => mbp.ID === followup.marketingPlanId);
            if (marketingPlan && !marketingPlan.accounts.some(acc => acc.id === account.id)) {
              followup.marketingPlanId = '';
              followup.marketingPlanNameString = '';
            }
          } else {
            followup.marketingPlanId = '';
            followup.marketingPlanNameString = '';
          }
          this.events.publish("updateFollowupActivityHeader");
          let loader = await this.loadingController.create({});
          loader.present();
          await this.followUpActivityDataService.updateFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalCopy: true, onLocalDatabase: true }, [followup], new Date().getTime()).then(success => {
            // Successfull Action
            if (!this.uiService.toolsActivityActive)
            {
              this.events.publish("refreshAgenda");
            }
            else {
              this.uiService.agendaRefreshRequired = true;
            }
          }).catch(async error => {
            // Handle error occured during follow up activity updation
            if (error && error['errorCode'] && error['errorCode'] == 'ALREADYCOMPLETEDACTIVITY') {
              await this.activityDataService.updateActivityDetails(followup);
            }
          });
          loader.dismiss();
        }
      this.accountService.accounts.map(item => item.isSelected = false);
      this.accountService.clearFilters();
      if(this.navParams && this.navParams.data && this.navParams.data.type && this.navParams.data.type == 'InMeetingFollowupPushedAccountSelectionView'){
        if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
        }
        this.navCtrl.pop({ progressAnimation: false }).then(() => {
          // this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
          this.uiService.activeView = this.uiService.prevView;
          this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
          if(this.navService.getCurrentMasterPageName()==PageName.AccountPageComponent){
            this.accountService.accessedAccountListFrom = PageName.ToolsDrawer
          }
        });
      }else {
        this.navService.popWithPageTracking().then(succ => {
          switch (this.navService.getCurrentMasterPageName()) {
            case PageName.ActivitiesPageComponent:
              this.uiService.activeView = 'ActivitiesPageRightPaneNav';
              break;
            case PageName.ContactPageComponent:
              this.uiService.activeView = 'ContactPageRightPaneNav';
              break;
            case PageName.AccountPageComponent:
              if (this.holdSelectedAccountInfo) {
                this.accountService.selected = this.holdSelectedAccountInfo;
                this.holdSelectedAccountInfo = null;
                this.accountService.accessedAccountListFrom = PageName.ActivitiesPageComponent
              }
              break;
            default:
              break;
          }
        });
      }

      return;
    }else if(this.accountService.accessedAccountListFrom == PageName.NewAccountPlanComponent ||this.accountService.accessedAccountListFrom == PageName.AccountPlanDetailsComponent) {
      if (isDone) {

        if (this.accountManagementOfflineService.selectedMode === AccountPlanMode.EDIT) {
          if (this.isAccountRemoved()) {
            const response = await this.showConfirmAlert();
            if (!response) {
              this.accountService.selectedAccounts = [];
              let account: Account;
              this.selectedAccountIds.forEach(element => {
                let index = this.accountService.accounts.findIndex(obj => obj.id == element.id);
                if (index >= 0) {
                  account = this.accountService.accounts[index];
                  account.isSelected = true;
                  this.accountService.selectedAccounts.push(account)
                }
              });
              this.isDirty = false;
              let doneButton = this.indHeaderLeftModel.controls.find((c) => c.id ===  "done");
              if(doneButton) {
                doneButton.isDisabled = !this.isDirty;
              }
              return;
            }
          }
        }

        let selectedItems = this.accountService.selectedAccounts.map(acc=> {
          let idx = this.selectedAccountIds.findIndex(item => item.id == acc.id);
          if(idx>= 0){
            return {
              id: this.selectedAccountIds[idx].id,
              name: this.selectedAccountIds[idx].name,
              statecode: this.selectedAccountIds[idx].statecode,
            }
          }else{
            return {
              id: acc.id,
              name: acc.accountName,
              statecode: 0,
            }
          }
        });
        this._callbackOnDone(_.isEmpty(selectedItems) ? null : selectedItems);
      }
      this.accountService.accounts.map(item => item.isSelected = false);
      this.accountService.selectedAccounts = [];
      this.accountService.accessedAccountListFrom = PageName.AccountManagementPageComponent;
      this.navService.popWithPageTracking();
    } else if (this.accountService.accessedAccountListFrom == PageName.OrderDetailsComponent && this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Order) {
      if (isDone) {
        this._doAccountSelectionForOrderActivity();
        return;
      }
      this.closePageAfterAccountSelectionForOrders();
      return;
    }else if (this.accountService.accessedAccountListFrom == PageName.NewOpportunityComponent && !(this.accountListData && this.accountListData.for == 'OpportunityStakeholderSelection')){
      if(isDone){
        let opportunity = this.opportunityMgmtService.newOpportunity$.value;
        if (this.accountService.selectedAccounts && this.accountService.selectedAccounts.length > 0) {
          let account = this.accountService.selectedAccounts[0];
          this.accountService.accessedAccountListFrom = null;
          if(opportunity.accountID != account.id){
            opportunity.stakeholders = [];
          }
          opportunity.accountID = account.id;
          opportunity.accountName = account.accountName;
          /* if(opportunity.primaryTargetValue && opportunity.primaryTargetValue == OpportunityPrimaryTraget.CONTACT){
            opportunity.contactID = '';
            opportunity.contactName = '';
            let affiliatedContacts:Contact[] = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([account]);
            if(!opportunity.contactID){
              if(affiliatedContacts && affiliatedContacts.length == 1){
                opportunity.contactID = affiliatedContacts[0].ID;
                opportunity.contactName = affiliatedContacts[0].fullName;
              }
            }else {
              if(!(affiliatedContacts && affiliatedContacts.some(a=> a.ID == opportunity.contactID))){
                opportunity.contactID = '';
                opportunity.contactName = '';
              }
            }
          } else */
            if (opportunity.primaryTargetValue == OpportunityPrimaryTraget.ACCOUNT && opportunity.contactID) {
            opportunity.contactID = '';
            opportunity.contactName = '';
          }
          this.opportunityMgmtService.newOpportunity$.next(opportunity);
        }else {
          this.accountService.accessedAccountListFrom = null;
          opportunity.accountID = '';
          opportunity.accountName = '';
          opportunity.stakeholders = [];
          this.opportunityMgmtService.newOpportunity$.next(opportunity);
        }
      }
      this.closePageAfterAccountSelectionForOpportunity();
      return;
    } else if (this.accountService.accessedAccountListFrom == PageName.OpportunityDetailsComponent || this.accountService.accessedAccountListFrom == PageName.NewOpportunityComponent) {
      if (isDone) {
        if(this.accountListData.for == 'NewOpportunityContactSelection'){
          if (this.accountService.accountPageMode == ComponentViewMode.SELECTION) {
            let data;
            if(this.accountService.selectedAccounts){
              data  = { selectedItem: this.accountService.selectedAccounts, isDone: true };
            }else{
              data = { clearValue: true, isDone:true };
            }
            this._callbackOnDone(data);
            // this.contactService.accessedContactListFrom = null;
            this.accountService.accessedAccountListFrom = PageName.ToolsDrawer
            this.uiService.activeView = this.uiService.prevView;
            this.closePageAfterAccountSelectionForOpportunity();
            return;
          }
        }
        let data: AccountSelectionObservableModel = {
          for: 'OpportunityDetails',
          id: (this.accountListData.opportunity as Opportunity).ID,
          accounts: this.accountService.selectedAccounts
        }
        this.accountService.setSelectedAccounts(data);
        this.accountService.accessedAccountListFrom = null;
        this.uiService.activeView = this.uiService.prevView;
        this.navService.popWithPageTracking().then(succ => {
          if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
            this.uiService.activeView = 'ContactPageRightPaneNav';
            this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
          }
        });
        return;
      }
      this.contactService.accessedContactListFrom = null;
      this.uiService.activeView = this.uiService.prevView;
      this.navService.popWithPageTracking().then(succ => {
        if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
          this.uiService.activeView = 'ContactPageRightPaneNav';
        }
      });
      return;
    }
    else if (this.accountService.accessedAccountListFrom == PageName.AssetDetailsComponent){
      if(isDone){
        const selectedAsset = this.fieldMaterialManagementService.selectedAsset;
        if (this.accountService.selectedAccounts && this.accountService.selectedAccounts.length > 0) {
          let account = this.accountService.selectedAccounts[0];
          this.accountService.accessedAccountListFrom = null;
          selectedAsset.indskr_newassetlocation = account.id;
          selectedAsset.indskr_newassetlocation_value = account.accountName;
        }else {
          this.accountService.accessedAccountListFrom = null;
          selectedAsset.indskr_newassetlocation = '';
          selectedAsset.indskr_newassetlocation_value = '';
        }
        const data = { selectedItems: selectedAsset, isDone: true };
        this._callbackOnDone(data);
      }
      this.closePageAfterAccountSelectionForOpportunity();
      return;
    } else if(this.accountService.accessedAccountListFrom == PageName.SurveyDetailsComponent) {
      if(isDone) {
        //update selected contact info in the survey payload
        if (this.accountService.selectedAccounts && this.accountService.selectedAccounts.length > 0) {
          this.customerSurveyService.selectedAccountForSurvey = this.accountService.selectedAccounts[0];
          this.accountService.accessedAccountListFrom = null;
        }
        const accountId: string = this.customerSurveyService.selectedAccountForSurvey.id;
        let selectedTemplate: AssessmentTemplate;
        let surveyDataFromDB: CustomerAssessment;
        if(this.navParams && this.navParams.data) {
          selectedTemplate = this.navParams.data.template;
        }
        if(!_.isEmpty(selectedTemplate)) {

          // contactsListSavedSurvey = await this.customerAssessService.getContactIdsSavedSurvey(selectedTemplate, SurveyStatus.SAVED);

          const templateId: string = selectedTemplate.indskr_assessmenttemplateid;
          // let surveysFromDB = await this.customerAssessService.getCustomerSurveyFromDB(templateId);
          let sureysByTemplateId = this.customerAssessService.getSurveysByTemplateId(templateId, 'account', false);

          // const isContactSavedData: boolean = contactsListSavedSurvey.includes(contactId);

          if(!_.isEmpty(sureysByTemplateId)) {
            let savedSurvey = sureysByTemplateId.find(survey => survey.surveyStatus === SurveyStatus.SAVED && survey.indskr_entityid === accountId);
            if (savedSurvey) {
                surveyDataFromDB = savedSurvey
            }
            // response update
            if(!_.isEmpty(surveyDataFromDB)) {
              const responseData = await this.customerAssessService.fetchContactAccountSurveyForDetails(surveyDataFromDB, 'account', true);
              if(!_.isEmpty(responseData)) {
                surveyDataFromDB.responses = responseData;
              }
            }
            this.customerSurveyService.setSurveyPreviewMode(false, surveyDataFromDB);
          }else {
            this.customerSurveyService.setSurveyPreviewMode(false);
          }
        }else {
          this.customerSurveyService.setSurveyPreviewMode(false);
        }
      }
      this.accountService.accounts.map(item => {
        item.isSelected = false;
        item.isForcedHideAddIcon = false;
      });
      this.accountService.selectedAccounts = [];

      await this.navService.popWithPageTracking().then(() => {
        if(!_.isEmpty(this.customerSurveyService.selectedAccountForSurvey)) {
          this.footerService.initButtons(FooterViews.SAVE_SURVEY_DETAILS);
        }else {
          this.footerService.initButtons(FooterViews.SURVEY_DETAILS);
        }
      });
    } else if ((this.accountService.selectedFor == AccountSelectedFor.GENERAL_SINGLE_SELECTION || this.accountService.selectedFor == AccountSelectedFor.GENERAL_MULTIPLE_SELECTION) && this._callbackOnDone){
      if(isDone){
        this._holdDynamicFormIsDirtyTracker = (this.accountService.accessedAccountListFrom === PageName.SurgeryOrderDetailsComponent ||
          this.accountService.accessedAccountListFrom === PageName.StoreCheckDetailsComponent || 
          this.accountService.accessedAccountListFrom === PageName.QuoteDetailsComponent || this.accountService.accessedAccountListFrom === PageName.NewQuoteComponent) ? false : true;
        let data;
        if(this.accountService.selectedAccounts.length == 0){
          data = { clearValue: true };
          if (this.selectionConfigs) {
            if (Array.isArray(this.selectionConfigs.afterActions)) {
              const actionsToPerform = this.selectionConfigs.afterActions.filter(a => a.selectionType !== 'CANCEL' && ((!isNaN(a.selectionCount) && a.selectionCount === 0) || isNaN(a.selectionCount)));
              if (actionsToPerform) {
                data.afterActions = actionsToPerform;
              }
            }
          }
        }else{
          if(this.accountService.selectedFor == AccountSelectedFor.GENERAL_SINGLE_SELECTION){
            data = { selectedItem: {id: this.accountService.selectedAccounts[0].id, name: this.accountService.selectedAccounts[0].accountName}, isDone: true };
            if (this.selectionConfigs) {
              if (Array.isArray(this.selectionConfigs.fieldNamesToInclude)) {
                for (const fieldName of this.selectionConfigs.fieldNamesToInclude) {
                  if (this.accountService.selectedAccounts[0]?.raw && this.accountService.selectedAccounts[0].raw[fieldName]) {
                    if (!data.selectedItem.otherFieldsToInclude) {
                      data.selectedItem.otherFieldsToInclude = {};
                    }
                    data.selectedItem.otherFieldsToInclude[fieldName] = this.accountService.selectedAccounts[0].raw[fieldName];
                  }
                }
              }
              if (Array.isArray(this.selectionConfigs.afterActions)) {
                const actionsToPerform = this.selectionConfigs.afterActions.filter(a => a.selectionType !== 'CANCEL' && ((!isNaN(a.selectionCount) && a.selectionCount > 0) || isNaN(a.selectionCount)));
                if (actionsToPerform) {
                  data.afterActions = actionsToPerform;
                }
              }
            }
          }else if(this.accountService.selectedFor == AccountSelectedFor.GENERAL_MULTIPLE_SELECTION){
            let selectedItems = this.accountService.selectedAccounts.map(acc=> {
              let idx = this.selectedAccountIds.findIndex(item => item.id == acc.id);
              if(idx>= 0){
                return {
                  id: this.selectedAccountIds[idx].id,
                  name: this.selectedAccountIds[idx].name,
                  statecode: this.selectedAccountIds[idx].statecode,
                }
              }else{
                return {
                  id: acc.id,
                  name: acc.accountName,
                  statecode: 0,
                }
              }
            });
            data = { selectedItems: selectedItems, isDone: true };
          }
        }
        this._callbackOnDone(data);
      }
      this.dynamicFormService.linkedEntityFormIsDirtyTracker = this._holdDynamicFormIsDirtyTracker;
      this._closePageAfterGeneralSelection();
    }else if (this.accountService.accessedAccountListFrom == PageName.ContactAcountAffiliationComponent) {
      if (isDone) {
        this.events.publish("contact:account", this.accountService.selectedAccounts[0])
      }
      this.accountService.accounts.map(item => item.isSelected = false);
      this.accountService.clearFilters();
      this.navService.popWithPageTracking();
      return;
    }else if(this.callPlanService.showAccountLIst == true && this.accountService.selectedFor == AccountSelectedFor.CALLPLAN_FILTER){
      this.callPlanService.showAccountLIst = false;
      this.trackingService.tracking('CallPlanAccountsFilter', TrackingEventNames.CALLPLAN)
      this.accountService.selected = undefined;
      if (!isDone) {
        this.accountService.clearFilters();
      }
      this.navService.popWithPageTracking();
    } else if(this.accountService.accessedAccountListFrom == PageName.MarketScanDetailsComponent) {
      if (isDone) {
        let selectedItems = this.accountService.selectedAccounts.map(acc => {
          return {
            id: acc.id,
            name: acc.accountName,
            sequence: acc['sequence']
          }
        });
        const data = { selectedItems: selectedItems, isDone: true };
        this._callbackOnDone(data);
      }
      this.accountService.accounts.map(item => item.isSelected = false);
      this.accountService.selectedAccounts = [];
      this.navService.popWithPageTracking().then(() => {
        this.footerService.initButtons(FooterViews.MARKET_SCAN_DETAILS);
      });
    } else if (this.accountService.accessedAccountListFrom == PageName.MarketingPlanDetails) {
      if (isDone) {
        // if (this.accountManagementOfflineService.selectedMode === AccountPlanMode.EDIT) {
          if (this.isAccountRemoved()) {
            const response = await this.showConfirmAlert();
            if (!response) {
              this.accountService.selectedAccounts = [];
              let account: Account;
              this.selectedAccountIds.forEach(element => {
                let index = this.accountService.accounts.findIndex(obj => obj.id == element.id);
                if (index >= 0) {
                  account = this.accountService.accounts[index];
                  account.isSelected = true;
                  this.accountService.selectedAccounts.push(account)
                }
              });
              this.initSelectedAccountsHeaderModel();
              this.isDirty = false;
              let doneButton = this.indHeaderLeftModel.controls.find((c) => c.id === "done");
              if (doneButton) {
                doneButton.isDisabled = !this.isDirty;
              }
              return;
            }
          // }
        }

        let selectedItems = this.accountService.selectedAccounts.map(acc => {
          let idx = this.selectedAccountIds.findIndex(item => item.id == acc.id);
          if (idx >= 0) {
            return {
              id: this.selectedAccountIds[idx].id,
              name: this.selectedAccountIds[idx].name,
              statecode: this.selectedAccountIds[idx].statecode,
            }
          } else {
            return {
              id: acc.id,
              name: acc.accountName,
              statecode: 0,
            }
          }
        });
        this._callbackOnDone(_.isEmpty(selectedItems) ? null : selectedItems);
      }
      this.accountService.accounts.map(item => item.isSelected = false);
      this.accountService.selectedAccounts = [];
      this.accountService.accessedAccountListFrom = PageName.MarketingPlanManagementPageComponent;
      this.navService.popWithPageTracking();
    } else if(this.accountService.accessedAccountListFrom === PageName.ShortCallHomeComponent) {
      if (isDone) {
        const data = { selectedItem: this.accountService.selectedAccounts[0], isDone: true };
        this._callbackOnDone(data);
      }
      this.accountService.accounts.map(item => item.isSelected = false);
      this.accountService.selectedAccounts = [];
      this.navService.popWithPageTracking().then(() => {
        // Short call feature requires to bring the tab back to short call home
        if (this.faService.isShortCallLauncherEnabledInMobileDevice && this.accountListMode === ComponentViewMode.LISTVIEW) {
          this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
          this.uiService.setAgendaTab('shortCallHome');
        }
      });
    } else {
      let loader:HTMLIonLoadingElement

      let eventName = isDone ? 'Done from Accounts' : 'AccountsBack';

      // this.accountService.selected = undefined;

      if (isDone && this.activityService.selectedActivity
        && (
          this.accountService.selectedFor === AccountSelectedFor.ADD_TO_MEETING
          || this.accountService.selectedFor === AccountSelectedFor.ACCOUNT_VISIT
        )
      ) {
        this.trackingService.tracking(eventName, TrackingEventNames.ACCOUNTS);
        // loader = await this.loadingController.create({});
        // loader.present();
        //this.handledRemoveOpportunities();
        this.handledRemoveAccountPlans();
        await this.uiService.displayLoader();
        // To Add/Remove affiliated contacts for meeting
        let updateContactsForMeetingFlag:boolean = false;
        let contactsToUpdate:Contact[] = [];
        //Check for Remove
        if (!this.accountVisitRecordCheckResponse?.isAccountVisitRecord
          && (this.activityService.selectedActivity as AppointmentActivity).contacts.length === 1 && (this.activityService.selectedActivity as AppointmentActivity).accounts.length === 1
        ) {
          let affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting((this.activityService.selectedActivity as AppointmentActivity).accounts);
          if(affiliatedContacts.length === 1 && affiliatedContacts[0].ID == (this.activityService.selectedActivity as AppointmentActivity).contacts[0].ID){
            // Remove the only added affiliated contact
            updateContactsForMeetingFlag = true;
            contactsToUpdate = [];
          }
        }
        //Check for add
        if (!this.accountVisitRecordCheckResponse?.isAccountVisitRecord && this.accountService.selectedAccounts?.length === 1
          && (this.activityService.selectedActivity as AppointmentActivity).contacts.length === 0 || updateContactsForMeetingFlag) {
          let affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(this.accountService.selectedAccounts);
          if (affiliatedContacts.length === 1) {
            // Add the only affiliated contact
            updateContactsForMeetingFlag = true;
            contactsToUpdate = affiliatedContacts;
          }
        }
        if(updateContactsForMeetingFlag){
          await this._updateContactsOfSelectedMeeting(contactsToUpdate);
        }


        // If it's offline & account visit activity, we don't update
        if (
          !(this.accountVisitRecordCheckResponse?.isAccountVisitRecord
            && this.device.isOffline)
        ) {

          const oldAccounts = this.activityService.selectedActivity['accounts'] ?? undefined;

        this.activityService.selectedActivity['accounts'] = [];

        this.accountService.selectedAccounts.map(account => {
          if (this.enableOneTimeVeevaMeeting && account.veevaRecordState === 200000004) {
            account.isUsedInOneTimeMeeting = true;
          }
          this.accountService.moveAccountToSelected(account);
        });
        if (this.activityService.selectedActivity instanceof AppointmentActivity) {
          if ((this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) ||  (this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_populateaccountaddress'])) && !this.activityService.isRemoteChannelEnabled((this.activityService.selectedActivity as AppointmentActivity).indskr_activitytype)) {
            await this.addAccountAddressToMeeting();
          }

          if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
            try {
              if (this.accountVisitRecordCheckResponse?.isAccountVisitRecord) {
                await this.meeting.updateAccountToAccountVisit(this.activityService.selectedActivity, oldAccounts, loader);
                const isInStoreMeeting = this.accountVisitRecordCheckResponse?.isAccountVisitRecord && this.activityService.selectedActivity.activityTypeName === this.translate.instant("INSTORE");
                if (isInStoreMeeting && !_.isEmpty(this.activityService.selectedActivity['inStorePhotos'])) {
                  await this.uiService.displayLoader();
                  await this.meeting.updateInStorePhotos(this.activityService.selectedActivity['inStorePhotos'].map(photo => { return { photoAttachmentId: photo.indskr_photoattachmentid, deleted: true } }));
                  await this.uiService.dismissLoader();
                }
              } else {
                await this.meeting.addAccountsToMeeting(this.activityService.selectedActivity);
                if ((this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) ||  (this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_populateaccountaddress'])) && !this.activityService.isRemoteChannelEnabled((this.activityService.selectedActivity as AppointmentActivity).indskr_activitytype)) {
                  await this.meeting.updateMeetingLocation(this.activityService.selectedActivity);
                }

                // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
                if(this.secondaryInfoService.isAppointmentSecInfoConfigured) {
                  const subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForAppointment(this.activityService.selectedActivity, SecondaryInfoEntityName.Appointment);
                  this.activityService.selectedActivity.subject = subject;
                } else if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
                   this.activityService.selectedActivity.subject = [this.translate.instant('MEETING'), this.activityService.selectedActivity.activityTypeName, this.activityService.selectedActivity.meetingOwnerName].filter(Boolean).join(" - ");
                   const subject = this.meeting.getMeetingSubject(this.activityService.selectedActivity);
                   this.activityService.selectedActivity.subject = subject;
                }
                // % % % Ability Autoname Subject in Meeting and Procedure Log % % % // 
              }
            } catch (error) {
              console.error('AccountListComponent: onCloseModal: ', error);
              await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity, false, true);
            }
          } else {
            // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
            if(this.secondaryInfoService.isAppointmentSecInfoConfigured) {
              const subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForAppointment(this.activityService.selectedActivity, SecondaryInfoEntityName.Appointment);
              this.activityService.selectedActivity.subject = subject;
            } else if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
              this.activityService.selectedActivity.subject = [this.translate.instant('MEETING'), this.activityService.selectedActivity.activityTypeName, this.activityService.selectedActivity.meetingOwnerName].filter(Boolean).join(" - ");
              const subject = this.meeting.getMeetingSubject(this.activityService.selectedActivity);
              this.activityService.selectedActivity.subject = subject;
            }
            // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
            await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
          }

          if (this.secondaryInfoService.isAppointmentSecInfoConfigured) {
            let payload = new UpdateMeetingPayload(
              this.activityService.selectedActivity.subject,
              this.activityService.selectedActivity.location,
              this.activityService.selectedActivity.scheduledStart,
              this.activityService.selectedActivity.scheduledEnd,
              this.activityService.selectedActivity.notes,
              false
            );

            await this.meeting.updateMeeting(this.activityService.selectedActivity, payload);
          }
          
          }
        }
        // await this.uiService.dismissLoader();
        this.accountService.clearFilters();
      }
      else if (!isDone) {
        this.trackingService.tracking(eventName, TrackingEventNames.ACCOUNTS);
        this.accountService.clearFilters();
      }

      if (this.callPlanService.showAccountLIst) {
        this.trackingService.tracking('CallPlanAccountsFilter', TrackingEventNames.CALLPLAN)
        this.callPlanService.showAccountLIst = !this.callPlanService.showAccountLIst;
      }
      else {
        // this.uiService.activeView = this.uiService.prevView;
        /*
          User has selected a different activity at agenda details
          and has selected some other type at timeline
        */


        // loader?.dismiss();
        await this.uiService.dismissLoader();

        this.navService.popWithPageTracking().then(() => {
          if(this.accountListMode == ComponentViewMode.LISTVIEW) {
            this.activityService.selectedActivity = this.activityService.selectedActivityAtHome ? this.activityService.selectedActivityAtHome : this.activityService.selectedActivity;
            this.uiService.activeView = '';
            this.uiService.activeView = this.uiService.getActiveViewBasedOnActivity(this.activityService.selectedActivity);
            if(this.activityService.selectedActivity?.type=="followuptask"){
              let activity = (this.activityService.selectedActivity as FollowUpActivity);
              if(activity.scientificPlanId || (activity.planType && activity.planType == FOLLOW_UP_TYPE.SCIENTIFIC_PLAN)) {
                this.events.publish('updateCurrentFollowUpActivity');
                this.navService.setChildNavRoot(ScientificPlanFollowUpTask, PageName.ScientificPlanFollowUpTask, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: this.activityService.selectedActivity });
              } else {
                this.navService.setChildNavRoot(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: this.activityService.selectedActivity });
              }
            }
          }
          else{
            this.uiService.activeView = this.uiService.prevView;
          }
          if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) {
            if(_.isEmpty(this.activityService.selectedActivityAtHome) && _.isEmpty(this.activityService.selectedActivity)) {
              this.uiService.showRightPane = false;
              this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
            }else {
              this.footerService.initButtons(FooterViews.Activities);
              this.events.publish("detectChangesOnActivityDetails"); // refresh meeting structure
            }
          } else if (this.uiService.activeView === 'customer_inquiry' || this.navService.getActiveChildNavViewPageName() === PageName.CaseManagementDetailComponent) {
            this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
          }

          if (isDone) {
            //this.events.publish('AccountSelected', true);
          }

          if(this.accountService.accessedAccountListFrom === PageName.PhoneCallDetailsComponent){
            this.uiService.showNewActivity = false;
            this.uiService.prevView = 'accountDetails';
            this.uiService.activeView = 'PhoneCall';
            this.footerService.initButtons(FooterViews.PHONE_CALL);
           // this.activityDataService.activityDetailsLoaded = true;
          }

          if (((this.accountListMode == ComponentViewMode.ADDNEW && this.activityService.selectedActivity instanceof AppointmentActivity)
            || this.accountService.accessedAccountListFrom == PageName.ActivitiesDetailsPaneComponent) && this.navService.getCurrentPageName() == PageName.ContactPageComponent) {
            this.uiService.showNewActivity = false;
            this.uiService.prevView = 'contactDetails';
            this.uiService.activeView = 'ContactPageRightPaneNav';
            this.activityDataService.activityDetailsLoaded = true;
          }

          if (this.accountService.accessedAccountListFrom == PageName.ActivitiesDetailsPaneComponent && this.navService.getCurrentPageName() == PageName.AccountPageComponent) {
            this.accountService.accessedAccountListFrom = PageName.ActivitiesPageComponent;
          }
          else if (this.accountService.accessedAccountListFrom == PageName.ActivitiesDetailsPaneComponent && this.navService.getCurrentPageName() == PageName.AccountManagementPageComponent) {
            this.accountListMode = ComponentViewMode.LISTVIEW
            this.accountService.accessedAccountListFrom = PageName.AccountManagementPageComponent
          }
          else {
            this.accountService.accessedAccountListFrom = undefined;
          }

          if (this.uiService.showFieldCoaching && this.navService.getActiveChildNavViewPageName() === PageName.CoachingDetailsComponent) {
            this.navService.popChildNavPageWithPageTracking();
          }

          if(this.navService.getPreviousActiveChildNavViewPageName() === PageName.NotificationDetailsComponent
            && (this.navService.getActiveChildNavViewPageName() === PageName.ContactDetailsComponent || this.navService.getActiveChildNavViewPageName() === PageName.AccountDetailsComponent)) {
            this.navService.popChildNavPageWithPageTracking();
          }

          // Short call feature requires to bring the tab back to short call home
          if (this.faService.isShortCallLauncherEnabledInMobileDevice && this.accountListMode === ComponentViewMode.LISTVIEW) {
            this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
            this.uiService.setAgendaTab('shortCallHome');
          }
        });
      }
    }
    if (this.holdSelectedAccountInfo && this.navService.getCurrentPageName() == PageName.AccountPageComponent) {
      this.accountService.selected = this.holdSelectedAccountInfo;
      this.holdSelectedAccountInfo = null;
      this.accountService.accessedAccountListFrom == PageName.ActivitiesPageComponent
    }
  }

  async addAccountAddressToMeeting() {
    if (this.activityService.selectedActivity instanceof AppointmentActivity) {
      let meeting = this.activityService.selectedActivity;
      if (this.activityService.selectedActivity['accounts'].length != 0) {
        const selectedAddress = await this.accountService.getPrimaryAddressOfAccount(this.activityService.selectedActivity['accounts'][0].id);
        if (selectedAddress) {
          meeting.location = selectedAddress.compositeAdd;
          meeting.indskr_meetinglocationlongitude = selectedAddress.longitude ? selectedAddress.longitude : null;
          meeting.indskr_meetinglocationlatitude = selectedAddress.latitude ? selectedAddress.latitude : null;
        } else {
          meeting.location = '';
          meeting.indskr_meetinglocationlongitude = null;
          meeting.indskr_meetinglocationlatitude = null;
        }
      } else {
        meeting.location = '';
        meeting.indskr_meetinglocationlongitude = null;
        meeting.indskr_meetinglocationlatitude = null;
      }
    }
  }

  async showConfirmAlert(): Promise<boolean> {
    return this.alertService.showAlert({
      title: this.translate.instant('REMOVE_ACCOUNT'),
      message: this.translate.instant('REMOVE_ACCOUNT_CONFRIM')
    }, this.translate.instant('YES'), this.translate.instant('NO')
    ).then(async res => {
      if (res.role == "ok") return true;
      return false
    });
  }

  isAccountRemoved(){
    return !this.selectedAccountIds.every(({ id }) => this.accountService.selectedAccounts.find((account) => account.id === id));
  }

  handledRemoveOpportunities() {
    if (!this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT)) return;
    let removedAccount = (this.activityService.selectedActivity as AppointmentActivity).accounts.filter(a => !this.accountService.selectedAccounts.some(b => b.id == a.id));
    let selectedOpportunities = [];
    (this.activityService.selectedActivity as AppointmentActivity).associatedOpportunities.forEach(opp => {
      let foundOpportunity = this.opportunityMgmtService.getOpportunityDetailsbyId(opp.opportunityId);
      if (!removedAccount.some(a => a.id == foundOpportunity.accountID))
        selectedOpportunities.push(opp)
    })
    let opportuntiesPayload = [];
    selectedOpportunities.forEach(a => {
      opportuntiesPayload.push(a.opportunityId);
    });
    const payload = opportuntiesPayload;
    let applicableOpportunities = [];
    opportuntiesPayload.forEach(item => {
      let foundOpportunity = this.opportunityMgmtService.getOpportunityDetailsbyId(item);
      applicableOpportunities.push(foundOpportunity);
    });
    if (applicableOpportunities.length) {
      (this.activityService.selectedActivity as AppointmentActivity).associatedOpportunities = applicableOpportunities.map(a => {
        return {
          opportunityId: a.ID,
          opportunityName: a.opportunityName,
        }
      });
    } else {
      (this.activityService.selectedActivity as AppointmentActivity).associatedOpportunities = [];
    }
    // this.uiService.displayLoader();
      this.meeting.addOpportunitiesToMeeting(this.activityService.selectedActivity as AppointmentActivity, payload).then(async (opportunities) => {
        // this.uiService.dismissLoader();
      });
  }

  handledRemoveAccountPlans() {
    let removedAccount = (this.activityService.selectedActivity as AppointmentActivity).accounts.filter(a => !this.accountService.selectedAccounts.some(b => b.id == a.id));
    if (!removedAccount.length || !(this.activityService.selectedActivity as AppointmentActivity).associatedAccountPlans.length) return;
    const selectedAccountPlans = [];
    if (this.accountService.selectedAccounts.length) {
      const selectedAcIds = this.accountService.selectedAccounts.map(e => e.id);
      this.accountManagementOfflineService.accountPlans.forEach((e) => {
        if (selectedAcIds.includes(e.accountId) && !selectedAccountPlans.includes(e.ID)) {
          selectedAccountPlans.push(e.ID)
        }
      });
    }
    let accountPlansPayload = [];
    (this.activityService.selectedActivity as AppointmentActivity).associatedAccountPlans.forEach(ap => {
      if (selectedAccountPlans.includes(ap.accountPlanId)) {
        accountPlansPayload.push(ap.accountPlanId);
      }
    })
    const payload = accountPlansPayload;
    let applicableAccountPlans = [];
    accountPlansPayload.forEach(item => {
      let foundAccountPlan = this.accountManagementOfflineService.getAccountPlanByPlanId(item);
      applicableAccountPlans.push(foundAccountPlan);
    });
    if (applicableAccountPlans.length) {
      (this.activityService.selectedActivity as AppointmentActivity).associatedAccountPlans = applicableAccountPlans.map(a => {
        return {
          accountPlanId: a.ID,
          accountPlanName: a.accountPlanName,
        }
      });
    } else {
      (this.activityService.selectedActivity as AppointmentActivity).associatedAccountPlans = [];
    }
    this.uiService.displayLoader();
    this.meeting.addAccountPlansToMeeting(this.activityService.selectedActivity as AppointmentActivity, payload).then(async (opportunities) => {
      this.uiService.dismissLoader();
    });
  }

  async accountSelectionForPhoneCall() {
    await this.uiService.displayLoader();
    let updateContactsForMeetingFlag: boolean = false;
    let contactsToUpdate: Contact[] = [];
    //Check for Remove
    if ((this.activityService.selectedActivity as PhoneActivity).contacts.length === 1 && (this.activityService.selectedActivity as PhoneActivity).accounts.length === 1) {
      let affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting((this.activityService.selectedActivity as PhoneActivity).accounts);
      if (affiliatedContacts.length === 1 && affiliatedContacts[0].ID == (this.activityService.selectedActivity as PhoneActivity).contacts[0].ID) {
        // Remove the only added affiliated contact
        updateContactsForMeetingFlag = true;
        contactsToUpdate = [];
      }
    }
    if ((this.activityService.selectedActivity as PhoneActivity).accounts.length === 0 && this.accountService.selectedAccounts && this.accountService.selectedAccounts.length === 1 && (this.activityService.selectedActivity as PhoneActivity).contacts.length === 0) {
      let affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(this.accountService.selectedAccounts);
      if (affiliatedContacts.length === 1) {
        // Add the only affiliated contact
        updateContactsForMeetingFlag = true;
        contactsToUpdate = affiliatedContacts;
      }
    }
    if (updateContactsForMeetingFlag) {
      await this._updateContactsOfSelectedPhoneCall(contactsToUpdate);
    }
    this.activityService.selectedActivity['accounts'] = [];
    this.accountService.selectedAccounts.map(account => {
      this.accountService.moveAccountToSelected(account);
    });

    let phoneCallPayLoad = this.phoneCallDataService.setPhoneCallPayload(this.activityService.selectedActivity as PhoneActivity)

    if (!this.device.isOffline && !this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID)) {
      await this.phoneCallDataService.updatePhoneCall(this.activityService.selectedActivity as PhoneActivity, phoneCallPayLoad); //CWD-2414 Update the activity subject if auto subject feature action is enabled
      // this.activityService.isActivityUpdate = true;
    }
    else {
      await this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity);
    }
    await this.uiService.dismissLoader();
  }

  /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
  async accountSelectionForEmail() {
    await this.uiService.displayLoader();

    this.emailService.selectedActivity['accounts'] = [];
    this.accountService.selectedAccounts.map(account => {
      let selectedIndex = this.emailService.selectedActivity.accounts.findIndex(sAccount => sAccount.id === account.id);

      if (selectedIndex !== -1) return;
        this.emailService.selectedActivity.accounts.push(account);
    });

    let selectedAccount = [];
    if (this.emailService.selectedActivity.accounts) {
      this.emailService.selectedActivity.accounts.map(account => {
        selectedAccount.push({
          indskr_accountid: account.id,
        });
      });
    }

    const payloadSelectedAccount = {
      accounts : selectedAccount
    }
    if (!this.device.isOffline) {
      await this.emailDataService.sendEmailExternalActivity(payloadSelectedAccount, this.emailService.selectedActivity.activityid).then(() => {
        // this.emailService.selectedActivity.status = 548910000;
        this.emailService.selectedActivity.status = 1;
  
        this.uiService.dismissLoader();
      }).catch(() => {
        this.emailService.selectedActivity.status = 1;
        this.uiService.dismissLoader();
      });
    }

  }
  /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/

  closeAccountPageBackToPhonecall(){
    this.trackingService.tracking('CancelClicked', TrackingEventNames.PHONECALL)

    if (this.uiService.activitiesPagePlanTabSelectedPlan === 'accountPlansTab') {
      this.navService.popWithPageTracking().then(() => {
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.footerService.initButtons(FooterViews.PHONE_CALL);
      });
    } else {
      this.navService.popWithPageTracking().then(() => {
        this.uiService.showNewActivity = false;
        if (this.navService.getCurrentPageName() === PageName.ContactPageComponent) {
          this.uiService.prevView = 'contactDetails';
          this.uiService.activeView = 'phoneCallTimelineDetails';
        } else if (this.navService.getCurrentPageName() === PageName.CallPlanComponent) {
          this.uiService.activeView = 'phoneCallTimelineDetails';
        } else if (this.navService.getCurrentPageName() === PageName.AccountPageComponent) {
          this.uiService.activeView = "Accounts";
        } else {
          this.uiService.activeView = 'PhoneCall';
        }
        this.footerService.initButtons(FooterViews.PHONE_CALL);
      })
    }
  }

  /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
  closeAccountPageBackToEmail(){
    this.trackingService.tracking('CancelClicked', TrackingEventNames.EMAIL)

    if (this.uiService.activitiesPagePlanTabSelectedPlan === 'accountPlansTab') {
      this.navService.popWithPageTracking().then(() => {
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.footerService.initButtons(FooterViews.EmailDetails);
      });
    } else {
      this.navService.popWithPageTracking().then(() => {
        this.uiService.showNewActivity = false;
        // if (this.navService.getCurrentPageName() === PageName.ContactPageComponent) {
        //   this.uiService.prevView = 'contactDetails';
        //   this.uiService.activeView = 'phoneCallTimelineDetails';
        // } else if (this.navService.getCurrentPageName() === PageName.CallPlanComponent) {
        //   this.uiService.activeView = 'phoneCallTimelineDetails';
        // } else if (this.navService.getCurrentPageName() === PageName.AccountPageComponent) {
        //   this.uiService.activeView = "Accounts";
        // }
        // else {
          this.uiService.activeView = 'Email';
        // }
        this.footerService.initButtons(FooterViews.EmailDetails);
      })
    }
  }
  /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/

  async accountListItemClicked(inAccount, event?) {
    if(event && event.target && event.target.className && event.target.className.includes("stakeholder-input")){
      return;
    }
    let account = this.accountService.getAccountById(inAccount.id);
    if(!account) {
      this.openAccountDetails(inAccount);
      return;
    }
    //THIS CODE SHOULD BE ENABLED - when kitbooking is getting created from Account tool and we click on account list 
    let isKitBookingDiscarded = await this.setBookingDataService.checkSetBookingDiscardConfirmationRequired().catch(err=>{});
    if(!isKitBookingDiscarded) return;

    if (this.accountService.isAffiliationEnabled) {
      await this.accountService.getAccountRelationshipById(account.id);
    }
    if (this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) return;
    if(this._showDiscardConfirmationForAccountForm || this.dynamicFormService.linkedEntityFormIsDirtyTracker ){
      let cancelText = this.translate.instant("CANCEL");
      if(this.translate.currentLang == 'it') {
        cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
      }
      this.alertService.showAlert({
        header: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
        message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')}, this.translate.instant('DISCARD'), cancelText
      ).then (res => {
          if(res.role == "ok") {
            if(!this.dynamicFormService.linkedEntityFormIsDirtyTracker){
              this.trackingService.tracking('DiscardSelected', TrackingEventNames.ACCOUNTS,true);
            }
            this._closeAccountFormPage();
            this.openAccountDetails(account);
            this._updateAccountWithRealtimeDetails(account.id).then((acc)=>{
              if(acc){
                if(this.accountService.selected){
                  acc.activitesByAccount = this.accountService.selected.activitesByAccount;
                  acc.events = this.accountService.selected.events;
                }
                this.openAccountDetails(acc,false, !this.accountService.refreshUIofCurrentSelectedAccountFlag);
              }
            }).catch(err=> {
              console.log(err);
            })
          }else{
            if(!this.dynamicFormService.linkedEntityFormIsDirtyTracker){
              this.trackingService.tracking('CancelSelected', TrackingEventNames.ACCOUNTS,true);
            }
          }
      });
    }
    else{
      //

      this.openAccountDetails(account);
      this._updateAccountWithRealtimeDetails(account.id).then((acc:Account)=>{
        if(acc){
          if(this.accountService.selected){
            acc.activitesByAccount = this.accountService.selected.activitesByAccount;
            acc.events = this.accountService.selected.events;
          }
          this.openAccountDetails(acc,false, !this.accountService.refreshUIofCurrentSelectedAccountFlag);
        }
      }).catch(err=> {
        console.log(err);
      })
    }
    this.dynamicFormService.isOpenedAffiliatedContactOnAccount = false;
    this.dynamicFormService.isOpenedAffiliatedAccountOnAccount = false;
    this.dynamicFormService.isOpenedAffiliatedAccountOnContact = false;
    this.dynamicFormService.isOpenedAffiliatedContactOnContact = false;
    this.accountService.isOpenedSurveyTimelineFromAccountProfile = false;
    this.dynamicFormService.openedUnmappedData = false;
  }

  async globalSearchedAccountListItemClicked(account: Account) {
    if (this.accountService.selected && account.id === this.accountService.selected.id) return;

    const loader = await this.loadingController.create();
    loader.present();

    const rawData = await this.accountDataService.getRealTimeAccountDetailsOnline(account.id, true);
    try {
      if (rawData) {
        account = new Account(rawData);
        this.openAccountDetails(account);
      }
    } catch (error) {
      console.error('globalSearchedAccountListItemClicked: ', error);
    } finally {
      loader.dismiss();
    }
  }

  private updateMasterListWithAccount(acc: Account) {

    if (this.allAccounts)
    this.allAccounts = this.updateAccountsWithUpdatedAccount(acc,this.allAccounts);// Update All Accounts

    if (this.accounts)
    this.accounts = this.updateAccountsWithUpdatedAccount(acc, this.accounts); //  Update this.accounts

    if (this.filteredList)
    this.filteredList = this.updateAccountsWithUpdatedAccount(acc, this.filteredList);// In case of Filtered Accounts update that.

    if (this.newAccounts)
    this.newAccounts = this.updateAccountsWithUpdatedAccount(acc, this.newAccounts);
  }

  private updateAccountsWithUpdatedAccount(acc: Account, accounts: Account[]): Account[] {
    if (acc) {
      const idx = accounts.findIndex(account => account.id === acc.id);
      if (idx >= 0) {
        if(accounts[idx].subAccounts && accounts[idx].subAccounts.length != 0){
          acc.subAccounts = accounts[idx].subAccounts;
        }
        accounts[idx] = acc;
      }
      else {
        console.warn('highlightContact: updated contact does not exist in contactToDisplay array');
      }
    }
    return accounts;
  }

  public openAccountDetails(account: Account,fecthActivities:boolean = true, isAlreadyLoaded: boolean = false) {
    if (!account || !account.status  || account.status === 2 || account.status == 548910003 || account.status == 548910010 || account.status == 548910011 || account.status == 548910012 || account.status == 548910013) {
      this.notificationService.notify(this.translate.instant('ACCOUNT_NO_LONGER_ACCESS'), 'Account List Read Only', 'top', ToastStyle.DANGER);
      return
    };
    if (this.accountService.isSchedulerInvoked) {
      this.accountService.selected = account;
      this.accountService.selected.activitesByAccount = [];
      this.accountService.selected.events=[];
      // if(fecthActivities){
      //   this.accountDataService.getAccountTimelineInfo(account);
      // }
      //this.uiService.activeView = 'accountDetails';
      this.navService.setChildNavRoot(AccountDetailsComponent, PageName.AccountDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTool','listMode': this.accountListMode });
      // if (this.device.isMobileDevice && this.device.deviceOrientation == 'PORTRAIT') {
      //   this.navService.pushWithPageTracking(AccountDetailsComponent, PageName.AccountDetailsComponent);
      // }
      this.footerService.initButtons(FooterViews.Accounts);
      this.navService.setChildNavRightPaneView(true);
    }
    else if (this.accountListAccessedFrom == 'AccountManagementPage') {
      //this.uiService.showRightPane = true;
      this.accountService.selected = account;
      this.selectAccount.emit(account);
    }
    else if (this.accountListAccessedFrom == 'OpportunityManagementPage') {
      this.accountService.selected = account;
      this.selectAccount.emit(account);
    }else {
      const insightsEventName = this.accountListMode === ComponentViewMode.ADDNEW ? 'Account INFO via Meeting' : 'AccountInfo'
      this.trackingService.tracking(insightsEventName, TrackingEventNames.ACCOUNTS);

      this.accountService.selected = account;
      this.accountService.selected.activitesByAccount = _.isEmpty(account.activitesByAccount) ? [] : account.activitesByAccount;
      this.accountService.selected.events = _.isEmpty(account.events) ? [] : account.events;
      // if(fecthActivities){
      //   this.accountDataService.getAccountTimelineInfo(account);
      // }
      this.uiService.showNewActivity = false;
      if(!isAlreadyLoaded) {
        //this.uiService.accountDataSegment = 'info';
        this.navService.setChildNavRoot(AccountDetailsComponent, PageName.AccountDetailsComponent, PageName.AccountPageComponent, { from: 'AccountTool','listMode': this.accountListMode });
      }
      // if (this.device.isMobileDevice && this.device.deviceOrientation == 'PORTRAIT') {
      //   this.navService.pushWithPageTracking(AccountDetailsComponent, PageName.AccountDetailsComponent);
      // }
      this.footerService.initButtons(FooterViews.Accounts);
      this.navService.setChildNavRightPaneView(true);
      this._initKeyControlsAreaModel();
      //this.initAccountsHeaderLeft();
    }
  }

  addToSelectedAccountList(selAccount) {
    this.accountService.addRemoveAccount(selAccount);
  }

  addToOKSelected(selected) {
    selected['isSelected'] = true;
    this.onekeyApiService.oneKeyAccounts.forEach(account => {
      if (account['workplaceEid'] != selected['workplaceEid']) {
        account['isSelected'] = false;
      }
    });
    this.accountService.selectedOKAccounts = [];
    this.accountService.selectedOKAccount = selected;
    this.accountService.selectedOKAccounts.push(selected);
    this.initSelectedOKAccountsHeaderModel();
    this._initKeyControlsAreaModel();
  }

  addNewAccount() {
    //TODO: Open new account screen
  }

  doInfinite(eventDetail,event) {
    if (this.searching || this.filtering) {
      if (this.accounts.length < (this.filteredList.length + 30)) {
        this.visibleFilteredList.push(...this.filteredList.slice(this.filteredRecordCount, (this.filteredRecordCount + 30)));
      } else {
        this.visibleFilteredList.push(...this.filteredList.slice(this.filteredRecordCount));
      }
      this.filteredRecordCount = this.filteredRecordCount + 30;
      event.target.complete();
      return;
    }
    if (this.accounts.length < (this.allAccounts.length + 30)) {
      this.accounts.push(...this.allAccounts.slice(this.recordCount, (this.recordCount + 30)));
    } else {
      this.accounts.push(...this.allAccounts.slice(this.recordCount));
    }
    this.recordCount = this.recordCount + 30;
    event?.target.complete();
  }

  enableSelection() {
    this.accountListMode = ComponentViewMode.SELECTION;
  }

  clear() {
    let opportunityLinkedAccountExists = false;
    for (let i = 0; i < this.accounts.length; i++) {
      const account = this.accounts[i];
       // if marketing business plan is linked to opportunity
      if (!this.disableRemoveIcon(account.id)) {
        this.recursiveDeSelect(account);
      }else{
        opportunityLinkedAccountExists = true;
      }
    }
    if(this.affiliatedAccounts.length != 0){
      for (let i = 0; i < this.affiliatedAccounts.length; i++) {
        const account = this.affiliatedAccounts[i];
          this.recursiveDeSelect(account);
      }
    }

    if (opportunityLinkedAccountExists) { // for marketing business plan
      this.accountService.selectedAccounts = this.accountService.selectedAccounts.filter(({id}) => this.disableRemoveIcon(id));
      this.isDirty = this.isAccountsDirty();
      let doneButton = this.indHeaderLeftModel.controls.find((c) => c.id === "done");
      if (doneButton) {
        doneButton.isDisabled = !this.isDirty;
      }
      this.initSelectedAccountsHeaderModel();
    } else {
      //this.accountService.clearFilters();
      this.accountService.searchAccounts = [];
      this.accountService.selectedAccounts = [];
      // this.accountService.selected = undefined;
      this.isDirty = this.isAccountsDirty();
      let doneButton = this.indHeaderLeftModel.controls.find((c) => c.id === "done");
      if (doneButton) {
        doneButton.isDisabled = !this.isDirty;
      }
      this.deselectAllAccounts();
    }
  }



  recursiveDeSelect(account) {
    account.isSelected = false;

    if (account.subAccounts && account.subAccounts.length > 0) {
      for (let j = 0; j < account.subAccounts.length; j++) {
        const subAccount = account.subAccounts[j];
        this.recursiveDeSelect(subAccount);
      }
    } else {
      return;
    }
  }

  private async _doAccountSelectionForOrderActivity() {
    if ((this.activityService.selectedActivity as OrderActivity).products && (this.activityService.selectedActivity as OrderActivity).products.filter(p=>!p.isDeleted).length > 0) {
      let confirmationData = new ConfirmationAlertData({
        message: this.translate.instant('OM_CHANGING_SELECTION_WILL_CLEAR_PRODUTCS'),
        title: this.translate.instant('CHANGE_ACCOUNT'),
        cancelButtonText: this.translate.instant('CANCEL'),
        confirmButtonText: this.translate.instant('YES'),
      });
      await this.uiService.displayAlertConfirmation(confirmationData).then(info => {
        if(info){
          this.isDirty = false;
          this.updateAccountForOrderActivity();
        }
      }).catch(error => {
        this.isDirty = true;
        return;
      });
    } else {
      this.updateAccountForOrderActivity();
    }
  }

  private closePageAfterAccountSelectionForOrders() {
    this.accountService.clearFilters();
    this.navService.popWithPageTracking().then(succ => {
      switch (this.navService.getCurrentMasterPageName()) {
        case PageName.ActivitiesPageComponent:
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          break;
        case PageName.ContactPageComponent:
          this.uiService.activeView = 'ContactPageRightPaneNav';
          break;
        case PageName.AccountPageComponent:
          if (this.holdSelectedAccountInfo) {
            this.accountService.selected = this.holdSelectedAccountInfo;
            this.holdSelectedAccountInfo = null;
            this.accountService.accessedAccountListFrom = PageName.ActivitiesPageComponent
          }
          break;
        default:
          break;
      }
    });
  }

  private async _closePageAfterGeneralSelection() {
    this.accountService.clearFilters();
    if(this.navParams && this.navParams.data && this.navParams.data.type && this.navParams.data.type == 'InMeetingProcedurePushedAccountSelectionView'){
      if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
      }
      this.navCtrl.pop({ progressAnimation: false }).then(() => {
        // this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
        this.uiService.activeView = this.uiService.prevView;
        this.accountService.accountPageMode = ComponentViewMode.LISTVIEW;
        if(this.navService.getCurrentMasterPageName()==PageName.AccountPageComponent){
          this.accountService.accessedAccountListFrom = PageName.ToolsDrawer
        }
      });
    } else {
      this.navService.popWithPageTracking().then(succ => {
        this.accountService.selectedFor = undefined;
        if(this.accountService.accessedAccountListFrom == PageName.SurgeryOrderDetailsComponent){
          this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
          if(this._callbackOnDone){
            this._callbackOnDone(null);
          }
        }
        if(this.accountService.accessedAccountListFrom == PageName.ProcedureTrackerDetailsComponent){
          this.footerService.initButtons(FooterViews.PROCEDURE_TRACKER_ACTIVITY);
        }
        if (this.navService.getCurrentPageName() == PageName.AccountPageComponent) {
          this.accountService.accessedAccountListFrom = PageName.ActivitiesPageComponent;
        }else{
          this.accountService.accessedAccountListFrom = undefined;
        }
      });
    }
  }

  private async closePageAfterAccountSelectionForOpportunity() {
    this.accountService.accounts.map(item => item.isSelected = false);
    this.accountService.clearFilters();
    this.navService.popWithPageTracking().then(succ => {
      switch (this.navService.getCurrentMasterPageName()) {
        case PageName.OpportunityManagementPage:
        case PageName.OpportunityDetailsComponent:
          if (this.holdSelectedAccountInfo) {
            this.accountService.selected = this.holdSelectedAccountInfo;
            this.holdSelectedAccountInfo = null;
          }
          break;
        case PageName.AssetDetailsComponent:
          if (this.holdSelectedAccountInfo) {
            this.accountService.selected = this.holdSelectedAccountInfo;
            this.holdSelectedAccountInfo = null;
            this.footerService.initButtons(FooterViews.ASSET_DETAILS);
          }
          break;
        default:
          break;
      }
    });
  }

  private async updateAccountForOrderActivity() {
    let account;
    if (this.accountService.selectedAccounts && this.accountService.selectedAccounts.length > 0) {
      account = this.accountService.selectedAccounts[0];
    }
    this.accountService.accessedAccountListFrom = null;
    (this.activityService.selectedActivity as OrderActivity).priceListId = '';
    (this.activityService.selectedActivity as OrderActivity).currencySymbol = '';
    (this.activityService.selectedActivity as OrderActivity).accountId = (account) ? account.id : null;
    (this.activityService.selectedActivity as OrderActivity).accountNameString = (account) ? account.accountName : '';
    (this.activityService.selectedActivity as OrderActivity).accountPlanId = '';
    (this.activityService.selectedActivity as OrderActivity).accountPlanNameString = '';
    (this.activityService.selectedActivity as OrderActivity).opportunityId = '';
    (this.activityService.selectedActivity as OrderActivity).offlineOpportunityId = '';
    (this.activityService.selectedActivity as OrderActivity).opportunityNameString = '';
    (this.activityService.selectedActivity as OrderActivity).products.map((o) => {
      o.isDeleted = true;
    });
    (this.activityService.selectedActivity as OrderActivity).total = 0;
    (this.activityService.selectedActivity as OrderActivity).formattedTotalAmount = '0.0';
    (this.activityService.selectedActivity as OrderActivity).pendingPushToDynamics = true;
    this.uiService.displayLoader();
    await this.OrderActivityDataService.updateOrderActivity({ onDynamics: !this.device.isOffline, onLocalCopy: true, onLocalDatabase: true, operationDetail:{code:'ORDER_ACCOUNT_UPDATED', message:'updateWeekview'} }, [(this.activityService.selectedActivity as OrderActivity)], new Date().getTime()).then(success => {
      // Successfull Action
      if(this.navService.getPreviousPageName() === PageName.OrderManagementPageComponent){
        this.events.publish("updateOrdersList");
      }
      if (this.navService.getPreviousPageName() === PageName.ActivitiesPageComponent) {
        if (!this.uiService.toolsActivityActive)
            {
              this.events.publish("refreshAgenda");
            }
            else {
              this.uiService.agendaRefreshRequired = true;
            }
      }
      else{
        this.uiService.agendaRefreshRequired = true;
      }
    }).catch(error => {
      // Handle error occured during follow up activity updation
    });
    this.uiService.dismissLoader();
    this.closePageAfterAccountSelectionForOrders();
  }

  public handleCreateNewOpportunity():void{
    this.createNewOpportunity.emit(true);
  }

  private async _updateContactsOfSelectedMeeting(contacts: Contact[]) {
    if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Appointment && !(this.activityService.selectedActivity as AppointmentActivity).isCompleted) {
      this.activityService.selectedActivity['contacts'] = [];
      contacts.forEach(contact => {
        this.contactService.moveContactToSelected(contact);
      });
      const isAutoSubjectEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT);
      const isAcinoBaseUser = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT);
      let activity = (this.activityService.selectedActivity as AppointmentActivity);
      let isSubjectUpdated = false;
      if (isAutoSubjectEnabled || isAcinoBaseUser || this.secondaryInfoService.isAppointmentSecInfoConfigured) {
        let subject: string = this.translate.instant('MEETING');
        let subPrefix = ` - ${subject} - ${activity.activityTypeName} - ${activity.meetingOwnerName}`;
        const selectedContacts = Array.isArray(activity.contacts)
          ? activity.contacts
          : [];
        if (isAcinoBaseUser) {
          subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}${this.translate.instant('VISIT')}`;
        }
        switch (selectedContacts.length) {
          case 0:
            if (!isAcinoBaseUser)
              subject = `${this.translate.instant('MEETING')} - ${activity.activityTypeName} - ${activity.meetingOwnerName}`;
            break;
          case 1:
            subject = `${this.contactService.getFullNameForContactDisplaySequence(selectedContacts[0])}${subPrefix}`;
            if (isAcinoBaseUser) {
              subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}${this.translate.instant('VISIT')} - ${this.contactService.getFullNameForContactDisplaySequence(selectedContacts[0])}`;
            }
            break;
          default:
            if (selectedContacts.length > 1) {
              selectedContacts.sort((contactA, contactB): number => {
                let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
                let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
                if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
                if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;

                return 0;
              });
              subject = `${this.contactService.getFullNameForContactDisplaySequence(selectedContacts[0])} + ${selectedContacts.length - 1}${subPrefix}`;
              if (isAcinoBaseUser) {
                subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}${this.translate.instant('VISIT')} - ${this.contactService.getFullNameForContactDisplaySequence(selectedContacts[0])}`;
              }
            }
        }
        if (this.activityService.selectedActivity.subject !== subject) {
          this.activityService.selectedActivity.subject = subject;
          isSubjectUpdated = true;
        }

        this.refreshPlanToDO();
      }

      if(this.activityService.selectedActivity.location !== "LiveMeet"){
        if(!this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) ||  (this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_populateaccountaddress'])){
          await this.contactService.setLocationField(contacts);
        }
      }

      if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
        await this.meeting.addContactsToMeeting(this.activityService.selectedActivity);
        // this.contentMatchService.isNewContactAddedToMeeting = true;
        this.events.publish('contentmatching:refresh');


        if (isSubjectUpdated && !(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN) ||  (this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_populateaccountaddress']))) {
          const payload = new UpdateMeetingPayload(
            this.activityService.selectedActivity.subject,
            this.activityService.selectedActivity.location,
            this.activityService.selectedActivity.scheduledStart,
            this.activityService.selectedActivity.scheduledEnd
          );
          try {
            await this.meeting.updateMeeting((this.activityService.selectedActivity as AppointmentActivity), payload);
          } catch (error) {
            console.error('AccountListComponent: _updateContactsOfSelectedMeeting: ', error);
            await this.activityService.upsertMeetingsOfflineData((this.activityService.selectedActivity as AppointmentActivity), false, true);
          }
          // } finally {
          //   this.activityService.isActivityUpdate = true;
          // }
        }
        if (this.contactService.contactSentimentUpdateActivity){
          if (!this.device.isOffline) {
            let payload = {
              "activityPresentations": this.activityService.getPresentationsDTO(this.contactService.contactSentimentUpdateActivity['activityPresentations']),
              "activityId": this.contactService.contactSentimentUpdateActivity.ID
            };
            this.meeting.updateMeetingSentiment(this.contactService.contactSentimentUpdateActivity, payload);
          }
          this.contactService.contactSentimentUpdateActivity = undefined;
        }
      } else {
        await this.activityService.upsertMeetingsOfflineData((this.activityService.selectedActivity as AppointmentActivity));
        // this.activityService.isActivityUpdate = true;
      }
      if (this._callbackOnDone) this._callbackOnDone(this.activityService.selectedActivity['contacts']);
    }
  }

  refreshPlanToDO() {
    if (this.agendaFooterService.actSegment === 'agenda' && this.agendaFooterService.planTabSegment === 'planTabTodo') {
      this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity);
    }
  }

  private async _updateContactsOfSelectedPhoneCall(contacts: Contact[]) {
    if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.PhoneCall && !(this.activityService.selectedActivity as PhoneActivity).isCompleted) {
      this.activityService.selectedActivity['contacts'] = [];
      contacts.forEach(contact => {
        this.contactService.moveContactToSelected(contact);
      });

      const newSubject = ((this.activityService.selectedActivity as PhoneActivity).contacts.length != 0) ? (this.activityService.selectedActivity as PhoneActivity).contactString : '';
          if (!_.isEmpty(newSubject)) {
            this.activityService.selectedActivity.subject = `${newSubject} - Phone Call`//${this.translate.instant//('NEW_ACTIVITY_PHONECALL')}`;
          }
          else {
            this.activityService.selectedActivity.subject = 'Phone Call'//this.translate.instant('NEW_ACTIVITY_PHONECALL');
          }

      if(contacts.length){
        let contactAffiliated;
        contactAffiliated = contacts[0];
        this.mobileNumberList = [];
        this.mobileNumberList.push({'phoneNumber': contactAffiliated.mobilePhone ? contactAffiliated.mobilePhone : '', 'identity': 'mobilePhone'})
        this.mobileNumberList.push({'phoneNumber': contactAffiliated.indskr_alternatephone1 ? contactAffiliated.indskr_alternatephone1 : '', 'identity': 'alternatephone1'})
        this.mobileNumberList.push({'phoneNumber': contactAffiliated.indskr_alternatephone2 ? contactAffiliated.indskr_alternatephone2 : '', 'identity': 'alternatephone2'})
        this.mobileNumberList.push({'phoneNumber': contactAffiliated.indskr_alternatephone3 ? contactAffiliated.indskr_alternatephone3 : '', 'identity': 'alternatephone3'})
        this.mobileNumberList.push({'phoneNumber': contactAffiliated.telephone ? contactAffiliated.telephone : '', 'identity': 'telephone'})

        if(this.mobileNumberList.length){
          const isNotEmpty = (element) => !_.isEmpty(element.phoneNumber);
          let index = this.mobileNumberList.findIndex(isNotEmpty);
          (this.activityService.selectedActivity as PhoneActivity).selectedMobileNumber = this.mobileNumberList[index].phoneNumber
         }
      }
      let phoneCallPayLoad = this.phoneCallDataService.setPhoneCallPayload(this.activityService.selectedActivity as PhoneActivity);
      await this.activityService.upsertPhoneCallOfflineData((this.activityService.selectedActivity as PhoneActivity));
      if (!this.device.isOffline && !this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID)) {
        await this.phoneCallDataService.updatePhoneCall(this.activityService.selectedActivity as PhoneActivity, phoneCallPayLoad);
      }
    }
  }

  private _initKeyControlsAreaModel() {
    this.isAccessedFromActiveMeeting = (this.accountService.accessedAccountListFrom == PageName.ActivitiesDetailsPaneComponent || this.accountService.accessedAccountListFrom == PageName.SurgeryOrderDetailsComponent) && this.accountListMode == this.compViewMode.ADDNEW;
    let isSelectedSavedTag: boolean = false;
    if(!_.isEmpty(this.selectedSuggestionsData)) {
      const idx = this.selectedSuggestionsData.findIndex(d=>d.categoryPath == "tag" || d.categoryPath == "Tag");
      if (idx > -1) {
        isSelectedSavedTag = true;
        if (!_.isEmpty(this.userTagService.accountTags)) {
          const selectedFacet = this.selectedSuggestionsData[idx]?.selectedFacet || '';
          this.selectedTagData = this.userTagService.accountTags.find(t=>t.indskr_name == selectedFacet);
        }
      } 
    }
    this.accountsListKeyControlAreaModel = {
      id:'accounts-list-key-controls-area',
      isHidden: !(!this.singleSelect && (this.accountListMode != this.compViewMode.READONLY)),
      eventHandler: (id: string, event, eventName) => this.handleControlsAreaEvent(id, event, eventName),
      controls:[
        {
          id:'save-search',
          text: this.translate.instant('SAVE_SEARCH'),
          isHidden: this.isAccountTagSelectEnabled || (this.accountListAccessedFrom == 'AccountManagementPage'||this.accountListAccessedFrom == 'OpportunityManagementPage') && !this.isAccessedFromActiveMeeting,
          isDisabled:!this.searching || this.disableSaveSearch,
          leftAligned: true
        },
        {
          id:'veeva-search',
          text: this.translate.instant('SEARCH_ALL_WITH_TEXT', { text: this.translate.instant("VEEVA_ACCOUNT") }),
          isHidden: this.isAccountTagSelectEnabled || (!this.enableVeevaSearch || this.accountService.accessedAccountListFrom === PageName.ShortCallHomeComponent || this.accountListAccessedFrom == 'AccountManagementPage' || this.accountListAccessedFrom == 'OpportunityManagementPage' || (!this.enableVeevaSearch && this.isAccessedFromActiveMeeting)),
          isDisabled: this.device.isOffline,
          leftAligned: false
        },
        {
          id:'veeva-my-requests',
          text: this.translate.instant('MY_VEEVA_CHANGE_REQUESTS'),
          isHidden: this.isAccountTagSelectEnabled || !(this.enableVeevaSearch && this.accountListAccessedFrom !== 'AccountManagementPage' && this.accountListAccessedFrom !== 'OpportunityManagementPage' && (this.enableVeevaSearch || !this.isAccessedFromActiveMeeting)),
          isDisabled: this.device.isOffline,
          leftAligned: false
        },
        {
          id:'onekey-search',
          text: this.translate.instant('SEARCH_ONEKEY'),
          isHidden: this.isAccountTagSelectEnabled || (this.accountService.accessedAccountListFrom === PageName.ShortCallHomeComponent || !this.enableOneKeySearch || this.accountListAccessedFrom == 'AccountManagementPage' || this.accountListAccessedFrom == 'OpportunityManagementPage' || (!this.enableOneKeySearch && this.isAccessedFromActiveMeeting)),
          isDisabled: this.isAssessmentFilter || !this.searching,
          leftAligned: false
        },
        {
          id:'global-search',
          text: this.translate.instant('SEARCH_OTHER_WITH_TEXT', { text: this.translate.instant("ACCOUNT") }),
          isHidden: this.accountService.accessedAccountListFrom === PageName.ShortCallHomeComponent || this.isAccountTagSelectEnabled || !(this.enableGlobalSearch && this.accountListAccessedFrom !== 'AccountManagementPage' && this.accountListAccessedFrom !== 'OpportunityManagementPage' && this.accountListMode !==  ComponentViewMode.ADDNEW && this.accountListMode !== ComponentViewMode.SELECTION && this.accountListMode !== ComponentViewMode.READONLY) && !(this.enableGlobalSearch && this.isAccessedFromActiveMeeting),
          isDisabled: this.isAssessmentFilter || !this.searching || this.device.isOffline,
          leftAligned: false
        },
        {
          id:'add-global-searched-account',
          text: this.translate.instant('ADD'),
          isDisabled: this.accountService.selectedAccounts.length === 0 || this.device.isOffline,
          isHidden: this.accountService.accessedAccountListFrom === PageName.ShortCallHomeComponent || this.isAccountTagSelectEnabled || !this.accountService.isGlobalSearching,
          leftAligned: false
        },
        {
          id:'add-ok-searched-account',
          text: this.translate.instant('ADD'),
          isHidden: this.accountService.accessedAccountListFrom === PageName.ShortCallHomeComponent || this.isAccountTagSelectEnabled || !this.accountService.isOneKeySearching,
          isDisabled: this.accountService.selectedOKAccounts.length === 0,
          leftAligned: false
        },
        { //OMNI-14631
          id:'create-btn',
          text: this.translate.instant('CREATE'),
          isHidden: this.accountService.accessedAccountListFrom === PageName.ShortCallHomeComponent || this.isAccountTagSelectEnabled || this.accountListAccessedFrom != 'OpportunityManagementPage',
          isDisabled: (this.device.isOffline && this.isOpportunityUserProvided),
          leftAligned:false
        },
        // {
        //   id:'clear-btn',
        //   text: this.translate.instant('CLEAR'),
        //   isHidden: !((this.accountService.selectedAccounts && this.accountService.selectedAccounts.length>0) && !this.accountService.isSchedulerInvoked && (this.accountListMode===this.compViewMode.ADDNEW || this.accountListMode===this.compViewMode.SELECTION)),
        //   leftAligned:false
        // },
        {
          id:'create-acc-btn',
          text: this.translate.instant('CREATE'),
          isHidden: this.accountService.accessedAccountListFrom === PageName.ShortCallHomeComponent || !(this.accountListAccessedFrom !== 'AccountManagementPage' && this.accountListAccessedFrom !== 'OpportunityManagementPage' && this.enableCreateAccount && this.accountListMode !==  ComponentViewMode.ADDNEW && this.accountListMode !== ComponentViewMode.SELECTION && this.accountListMode !== ComponentViewMode.READONLY) && !(this.enableCreateAccount && this.isAccessedFromActiveMeeting),
          isDisabled: this.isAccountTagSelectEnabled || this.device.isOffline || this.navService.getActiveChildNavViewPageName() == PageName.DynamicFormComponent,
          leftAligned:false
        },
        {
          id:'expand-btn',
          text: (!this.expandAll) ? this.translate.instant('ACCOUNT_EXPAND_ALL') : this.translate.instant('ACCOUNT_COLLAPSE_ALL'),
          isHidden: this.accountService.isGlobalSearching || this.currentTab === AccountsModel.CHANGE_REQUESTS_TAB,
          leftAligned:false,
        },
        {
          id:'select-tag-btn',
          text: this.translate.instant('SELECT'),
          isHidden: this.isAccountTagSelectEnabled || this.accountService.isGlobalSearching || this.accountListMode == this.compViewMode.ADDNEW || this.accountListMode==this.compViewMode.SELECTION || this.accountService.accessedAccountListFrom != this.pagename.ActivitiesPageComponent,
          leftAligned:false,
        },
        {
          id:'cancel-tag-btn',
          text: this.translate.instant('CANCEL'),
          isHidden: this.isAccountTagSelectEnabled == false,
          isDisabled: false,
          leftAligned:false,
        },
        {
          id:'add-to-tag-btn',
          text: this.translate.instant('ADD_TO_TAG'),
          isHidden: !(this.isAccountTagSelectEnabled == true && this.showRemoveTag == false),
          isDisabled: this._selectedAccountsForTag.length == 0,
          leftAligned:false,
        },
        {
          id:'remove-tag-btn',
          text: this.translate.instant('REMOVE_FROM_TAGS'),
          isHidden: (!this.showRemoveTag || !isSelectedSavedTag || this.isAccountTagSelectEnabled == false || this.accountService.isGlobalSearching || this.accountListMode == this.compViewMode.ADDNEW || this.accountListMode==this.compViewMode.SELECTION || this.accountService.accessedAccountListFrom != this.pagename.ActivitiesPageComponent),
          isDisabled: !(this.selectedTagData && this._selectedAccountsForTag.length != 0),
          leftAligned:false,
        },
        {
          id:'create-new-tag-btn',
          text: this.translate.instant('CREATE_NEW_TAG'),
          isHidden: !(this.isAccountTagSelectEnabled == true && this.showRemoveTag == false),
          isDisabled: this._selectedAccountsForTag.length == 0,
          leftAligned:false,
        },
      ]
    };
  }

  private handleControlsAreaEvent(id,event,eventName){
    if(id && eventName && eventName == 'controls-click'){
      switch(id){
        case 'create-btn':
          this.handleCreateNewOpportunity();
          break;
        case 'clear-btn':
          this.clear();
          this._initKeyControlsAreaModel();
          break;
        case 'expand-btn':
          this.expandAll = !this.expandAll;
          this._initKeyControlsAreaModel();
          break;
        case 'save-search':
          this.saveAdvancedSearch();
          break;
        case 'veeva-search':
          this.veevaWidgetService.openSearchHCOWidget();
          break;
        case 'veeva-my-requests':
          this.veevaWidgetService.openMyRequestsWidget();
          break;
          break;
        case 'global-search':
          this._globalSearch();
          break;
        case 'create-acc-btn':
          this._createNewAccount();
          break;
        case 'add-global-searched-account':
          this._addGlobalSearchedAccountToUserPosition();
          break;
        case 'add-ok-searched-account':
          this.addToPopulation();
          break;
        case 'onekey-search':
          this.oneKeyAccountSearch();
          break;
        case 'select-tag-btn':
          this.isAccountTagSelectEnabled = true;
          this._initKeyControlsAreaModel();
          break;
        case 'cancel-tag-btn':
          this.isAccountTagSelectEnabled = false;
          this.cancelTagSelection();
          break;
        case 'add-to-tag-btn':
          this.addToTag();
          break;
        case 'create-new-tag-btn':
          this.createNewTag();
          break;
        case 'remove-tag-btn':
          this.removeAccountsFromTag(this.selectedTagData);
          break;
        default:
          console.log('Unhandled switch case statement');
      }
    }
  }

  public async oneKeyAccountSearch() {
    if (this.device.isOffline) return;
    this.accountService.isGlobalSearching = false;
    this.accountService.isOneKeySearching = true;
    this.searchedByOKID = false;
    this.accountService.selected = undefined;
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
    await this.uiService.displayLoader();
    let criteria = await this.buildSearchCriteria();
    await this.onekeyApiService.searchOneKey(criteria);
    await this.uiService.dismissLoader();
    this.initOneKeySearchedAccountsHeaderModel();
    this._initKeyControlsAreaModel();
  }

  async buildSearchCriteria(): Promise<object> {
    let criteria = {
      "codBase": [this.authService.user.iqviaCodBase],
      "entityType": OKEntityType.WORKPLACE,
      "fields": []
    }
    if (this.selectedSuggestionsData && Array.isArray(this.selectedSuggestionsData) && this.selectedSuggestionsData.length) {
      this.selectedSuggestionsData.forEach(suggestion => {
        let codBaseSubStr = suggestion.charSearchText && !suggestion['categoryPath'] ? suggestion.charSearchText.substring(0,3) : "";
        if (this.authService.user.iqviaCodBase.toUpperCase() == codBaseSubStr.toUpperCase()) {
          criteria.fields.push({
            "name": OKWorkplaceSearchKeys.WORKPLACE_ID,
            "values": [suggestion.charSearchText.toUpperCase()]
          });
          this.searchedByOKID = true;
        } else {
          if (criteria.fields.length) criteria.fields.push(SEARCH_OPERATOR.AND);
          if (suggestion['charSearchText'] && !suggestion['categoryPath']) {
            criteria.fields.push({
              "name": OKWorkplaceSearchKeys.OFFICIAL_NAME,
              "values": [suggestion.charSearchText]
            }, SEARCH_OPERATOR.OR, {
              "name": OKWorkplaceSearchKeys.OFFICIAL_NAME2,
              "values": [suggestion.charSearchText]
            });
            if (this.authService.user.iqviaCodBase == COD_BASE.GERMANY || this.authService.user.iqviaCodBase == COD_BASE.DENMARK) {
              criteria.fields.push(SEARCH_OPERATOR.OR, {
                "name": OKWorkplaceSearchKeys.USUAL_NAME,
                "values": [suggestion.charSearchText]
              }, SEARCH_OPERATOR.OR, {
                "name": OKWorkplaceSearchKeys.USUAL_NAME2,
                "values": [suggestion.charSearchText]
              })
            }
          }
          if (suggestion['categoryPath']) {
            let categoryPathCI = suggestion['categoryPath'].toLowerCase();
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.ACTIVITY_LOCATION.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.ACTIVITY_LOCATION])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.ACTIVITY_LOCATION,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.ACTIVITY_STRUCTURE.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.ACTIVITY_STRUCTURE])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.ACTIVITY_STRUCTURE,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.WORKPLACE_ID.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.WORKPLACE_ID])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.WORKPLACE_ID,
                "values": [suggestion.charSearchText ? suggestion.charSearchText.toUpperCase() : suggestion.selectedFacet.toUpperCase()]
              });
              this.searchedByOKID = true;
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.WORKPLACE_TYPE.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.WORKPLACE_TYPE])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.WORKPLACE_TYPE,
                "values": [suggestion.charSearchText ? suggestion.charSearchText.toUpperCase() : suggestion.selectedFacet.toUpperCase()]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.OFFER_TRAINING.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.OFFER_TRAINING])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.OFFER_TRAINING,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (this.authService.user.iqviaCodBase == COD_BASE.GERMANY) {
              if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.NO_OF_BEDS.toLowerCase())) {
                if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.NO_OF_BEDS])) criteria.fields.push(SEARCH_OPERATOR.OR);
                criteria.fields.push({
                  "name": OKWorkplaceSearchKeys.NO_OF_BEDS,
                  "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
                });
              }
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.MANAGER_ACCOUNT.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.MANAGER_ACCOUNT])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.MANAGER_ACCOUNT,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.MANAGER_ACCOUNT_OFFL_NAME.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.MANAGER_ACCOUNT_OFFL_NAME])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.MANAGER_ACCOUNT_OFFL_NAME,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.MANAGER_ACCOUNT_OFFL_NAME2.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.MANAGER_ACCOUNT_OFFL_NAME2])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.MANAGER_ACCOUNT_OFFL_NAME2,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.MANAGER_ACCOUNT_USL_NAME.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.MANAGER_ACCOUNT_USL_NAME])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.MANAGER_ACCOUNT_USL_NAME,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.MANAGER_ACCOUNT_USL_NAME2.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.MANAGER_ACCOUNT_USL_NAME2])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.MANAGER_ACCOUNT_USL_NAME2,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (this.authService.user.iqviaCodBase == COD_BASE.GERMANY) {
              if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.LEGAL_CATEGORY.toLowerCase())) {
                if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.LEGAL_CATEGORY])) criteria.fields.push(SEARCH_OPERATOR.OR);
                criteria.fields.push({
                  "name": OKWorkplaceSearchKeys.LEGAL_CATEGORY,
                  "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
                });
              }
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.STRUCTURE_TYPE.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.STRUCTURE_TYPE])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.STRUCTURE_TYPE,
                "values": [suggestion.charSearchText ? suggestion.charSearchText.toUpperCase() : suggestion.selectedFacet.toUpperCase()]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.TYPE_CODE.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.TYPE_CODE])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.TYPE_CODE,
                "values": [suggestion.charSearchText ? suggestion.charSearchText.toUpperCase() : suggestion.selectedFacet.toUpperCase()]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.TELEPHONE_TYPE_CODE.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.TELEPHONE_TYPE_CODE])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.TELEPHONE_TYPE_CODE,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.TELEPHONE_RANK.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.TELEPHONE_RANK])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.TELEPHONE_RANK,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.TELEPHONE_OFC_NUM.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.TELEPHONE_OFC_NUM])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.TELEPHONE_OFC_NUM,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.AFFL_TO_ACC.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.AFFL_TO_ACC])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.AFFL_TO_ACC,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.AFFL_FROM_ACC.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.AFFL_FROM_ACC])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.AFFL_FROM_ACC,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.AFFL_LINK_CODE.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.AFFL_LINK_CODE])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.AFFL_LINK_CODE,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.CHECK_STATUS_CODE.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.CHECK_STATUS_CODE])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.CHECK_STATUS_CODE,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.REASON_CODE.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.REASON_CODE])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.REASON_CODE,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.TELEPHONE_CALL_NO_FOR_DISPLAY.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.TELEPHONE_CALL_NO_FOR_DISPLAY])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.TELEPHONE_CALL_NO_FOR_DISPLAY,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.TELEPHONE_CALL_NO_FOR_SEARCH.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.TELEPHONE_CALL_NO_FOR_SEARCH])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKWorkplaceSearchKeys.TELEPHONE_CALL_NO_FOR_SEARCH,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (this.authService.user.iqviaCodBase == COD_BASE.GERMANY || this.authService.user.iqviaCodBase == COD_BASE.NETHERLAND) {
              if (categoryPathCI.includes(OmnipresenceAccountSearchKeys.TELEPHONE_CONSENT.toLowerCase())) {
                if (_.some(criteria.fields, ['name', OKWorkplaceSearchKeys.TELEPHONE_CONSENT])) criteria.fields.push(SEARCH_OPERATOR.OR);
                criteria.fields.push({
                  "name": OKWorkplaceSearchKeys.TELEPHONE_CONSENT,
                  "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
                });
              }
            }
          }
        }
      });
    }
    return criteria;
  }

  displayOKDetails(account) {
    console.log("opening onekey searched account details");
    if(this.navService.getActiveChildNavViewPageName() == PageName.ContactDetailsComponent || this.navService.getActiveChildNavViewPageName() == PageName.AccountDetailsComponent){
      this.navService.popChildNavPageWithPageTracking();
    } else {
      this.navService.setChildNavRightPaneView(false);
    }
    this.accountService.selectedOKAccount = account;
    this.navService.pushChildNavPageWithPageTracking(AccountDetailsComponent, PageName.ContactDetailsComponent, PageName.ContactPageComponent, { listMode: ComponentViewMode.READONLY });
  }

  async addToPopulation() {
    let account = this.accountService.selectedOKAccount;
    if (!account['isInContract']) {
      let payload = [{
        "onekeyEid": account['workplaceEid'],
        "entityType": OKEntityType.WORKPLACE
      }];
      await this.uiService.displayLoader();
      if (!this.searchedByOKID) {
        await this.downloadAccount(account);
      }
      await this.onekeyApiService.submitPop(payload).then(async () => {
        let idx = this.onekeyApiService.oneKeyAccounts.findIndex(acc => acc['workplaceEid'] == account['workplaceEid']);
        await this.createAccountInDynamics(this.onekeyApiService.oneKeyAccounts[idx]);
        this.onekeyApiService.oneKeyAccounts.splice(idx, 1);
        this.onekeyApiService.okSearchResult.next(this.onekeyApiService.oneKeyAccounts);
        this.accountService.selectedOKAccount = null;
        this.accountService.selectedOKAccounts.length = 0;
        await this.uiService.dismissLoader();
      }).catch(async (err) => {
        console.log("Error occurred while submitting to population", err);
        await this.uiService.dismissLoader();
      });
      this.initOneKeySearchedAccountsHeaderModel();
    }
  }

  async downloadAccount(account) {
    let payload = {
      "codBase": [this.authService.user.iqviaCodBase],
      "entityType": OKEntityType.WORKPLACE,
      "fields": [{
        "name": OKWorkplaceSearchKeys.WORKPLACE_ID,
        "values": [account['workplaceEid'].toUpperCase()]
      }]
    }
    await this.onekeyApiService.downloadOnekey(payload).then(async (res) => {
      if(res && Array.isArray(res)) {
        let idx = this.onekeyApiService.oneKeyAccounts.findIndex(acc => acc['workplaceEid'] == account['workplaceEid']);
        this.onekeyApiService.oneKeyAccounts[idx] = res[0]['workplace'];
        this.onekeyApiService.okSearchResult.next(this.onekeyApiService.oneKeyAccounts);
      }
    });
  }

  async createAccountInDynamics(account) {
    let payload = {
      "omnione_dateoflastworkplacequalitycheck":account['checkDate'] ? format(account['checkDate'], 'YYYY-MM-DD') : null,
      "omnione_accountmanagermdmid":account['managerWorkplaceEid'] ? account['managerWorkplaceEid'] : null,
      "omnione_workplacecreationdate":account['creationDate'] ? format(account['creationDate'], 'YYYY-MM-DD') : null,
      "omnione_providestraining":account['isOfferingTraining'] ? account['isOfferingTraining'] : false,
      "omnione_activityofthestructure":"100000000",
      "name":account['officialName'] ? account['officialName'] : account['usualName'],
      "omnione_officialworkplacenameina2ndalphabetlangua":account['officialName2'] ? account['officialName2'] : null,
      "indskr_noofbeds": (account['profileDatas'] && account['profileDatas']['NB_BEDS1']) ? account['profileDatas']['NB_BEDS1'] : 0,
      // "omnione_reasonforlastworkplaceupdate":account['reasonCode'] ? account['reasonCode'] : null,
      "omnione_officialworkplacename":account['officialName'] ? account['officialName'] : null,
      "omnione_accountmanagername":account['managerWorkplaceUsualName'] ? account['managerWorkplaceUsualName'] : null,
      "omnione_accountmanagename2":account['managerWorkplaceUsualName2'] ? account['managerWorkplaceUsualName2'] : null,
      "omnione_accountmanagerofficialname":account['managerWorkplaceOfficialName'] ? account['managerWorkplaceOfficialName'] : null,
      "omnione_accountmanagerofficialname2":account['managerWorkplaceOfficialName2'] ? account['managerWorkplaceOfficialName2'] : null,
      "indskr_mdm": MDMType.ONEKEY,
      "indskr_mdmid":account["workplaceEid"],
      "indskr_accountsourcetype": ChangeRequestType.MDM,
      // "omnione_workplacestructuretype":account['structureTypeCode'] ? account['structureTypeCode'] : null,
      // "omnione_qualitycheckstatus":account['checkStatusCode'] ? account['checkStatusCode'] : null,
      // "omnione_activitylocation":account['activityLocationCode'] ? account['activityLocationCode'] : null,
      "lookupfields": []
    };
    await this.accountDataService.createUpdateAccountOnline(payload).then(async res=> {
      try {
        if (res && res['accountid']) {
          account['address'] = account['workplaceAddresses'];
          if (account['address']) {
            await this.mapOKAddressesToAccount(res['accountid'], account);
          }
          let rawDetails = await this.accountDataService.getRealTimeAccountDetailsOnline(res['accountid']);
          if (rawDetails) {
            this.initAllAccountsHeaderModel();
            await this.accountService.addUpdateRawAccountDetails(rawDetails);
            let newAccount = this.accountService.getAccountById(res['accountid']);
            this._updateAccountsListAfterUpdateOrCreate(newAccount, true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }).catch(async err=>{
      console.error(err);
      await this.uiService.dismissLoader();
    });
  }

  async mapOKAddressesToAccount(accountId, account) {
    try {
      let fetchQuery = fetchQueries.oneKey.addressMetaData;
      let tasks = [];
      if (account['address']['P,1'] && account['address']['P,1']['address']) {
        let addressObj = account['address']['P,1']['address'];
        fetchQuery = fetchQuery.replace('{postalCode}', addressObj['longPostalCode']);
        if (addressObj['postalTownReference']) {
          let postalCodeObj = addressObj['postalTownReference'];
          fetchQuery = fetchQuery.replace('{countryCode}', postalCodeObj['country']);
          if (postalCodeObj['subdivisions']) {
            let subDivisions = postalCodeObj['subdivisions'];
            fetchQuery = fetchQuery.replace('{stateName}', subDivisions['SUB.3']['longLocalizedLabel']);
            fetchQuery = fetchQuery.replace('{cityName}', subDivisions['SUB.5']['longLocalizedLabel']);
            console.log("address metadata fetchQuery: ", fetchQuery)
            tasks.push(this.dynamics.executeFetchQuery('indskr_lu_countries',fetchQuery).then(data =>{
              if (data && Array.isArray(data) && data.length) {
                let payload = {
                  "indskr_Country_LU@odata.bind": "/indskr_lu_countries("+data[0]['countryId']+")",
                  "indskr_State_lu@odata.bind": "/indskr_lu_states("+data[0]['stateId']+")",
                  "indskr_City_lu@odata.bind": "/indskr_lu_cities("+data[0]['cityId']+")",
                  "indskr_postalcode_lu@odata.bind": "/indskr_lu_postalcodes("+data[0]['postalCode']+")",
                  "indskr_externalid": addressObj['addressEid'],
                  "indskr_line1": addressObj['addressLongLabel'] ? addressObj['addressLongLabel'] : "",
                  "omnione_dispatchlabel": postalCodeObj['dispatchLabel'] ? postalCodeObj['dispatchLabel'] : "",
                  "omnione_village": postalCodeObj['villageLabel'] ? postalCodeObj['villageLabel'] : "",
                  "indskr_postofficebox": account['address']['P,1']['postalBoxLabel'] ? account['address']['P,1']['postalBoxLabel'] : ""
                };
                tasks.push(this.dynamics.create(payload, 'indskr_addresses').then(res =>{
                  console.log(res);
                  console.log("successfully mapped OK address to indskr_addresses", res);

                  let mapAddressToAccount = {
                    "indskr_Customer_account@odata.bind": "/accounts(" +accountId+ ")",
                    "indskr_Address@odata.bind": "/indskr_addresses(" +res['indskr_addressid']+ ")",
                    "indskr_name": addressObj['addressLongLabel'] ? addressObj['addressLongLabel'] : "",
                    "indskr_customeraddresssourcetype": 548910001,
                    "indskr_isprimary": true
                  }
                  tasks.push(this.dynamics.create(mapAddressToAccount, "indskr_indskr_customeraddress_v2s").then(res =>{
                    console.log(res);
                    console.log("successfully mapped indskr_indskr_customeraddress_v2s to account", res);
                  }));
                }));
              }
            }));
            await Promise.all(tasks);
          }
        }

      }
    } catch (e) {
      console.log("failed to map address to OK account");
      console.log(e);
    }
  }

  private loadIOSWidget() {
    console.log(window.location.href);
    //happens for contact and accounts
    let activeInstance = this.authenticationOfflineService.userConfig.activeInstance;

    const veevaConfig = activeInstance.veevaConfig;
    // ${activeInstance.OmnipresenceWfeUrl}
    this.iab.create(`${activeInstance.OmnipresenceWfeUrl}/?veevaWidget=VEEVA_SEARCH&preset=account&widgetID=${veevaConfig.searchWidgetId}&authDomain=${veevaConfig.authDomain}&widgetName=${veevaConfig.searchWidgetName}&manifestURL=${veevaConfig.manifestScriptUrl}&widgetURL=${veevaConfig.searchWidgetScriptUrl}`, '_system', { location: 'yes', hideurlbar: 'no', hidenavigationbuttons: 'no' });
    return;
  }

  @HostListener('window:veeva-network-add-request-submitted', ['$event'])
  public async veevaAddRequestSubmitted(event) {
    console.log(event);
  }

  @HostListener('window:veeva-network-select', ['$event'])
  public async createVeevaEntity(event) {
    console.log(event);
    let entity = event.detail.entity;
    let newEntity = !!event.detail.taskId;
    const loading = await this.loadingCtrl.create();
    if (entity.entityType == 'HCO') {
      const veevaIndex = this.accountService.accounts.findIndex(ac => ac.indskr_mdm == MDMType.VEEVA && ac.extendedDetails.externalId == entity.entity.vid__v);
      loading.present();
      try {
        let data = await this.accountDataService.createVeevaAccount(entity.entity);
        if (data[0]?.accountid && event.detail.taskId) {
          await this.dynamics.dwa.create({
            omniveev_externalid: event.detail.taskId,
            omniveev_type: 200000000,
            'omniveev_customer_account@odata.bind': `/accounts(${data[0]?.accountid})`
          }, "omniveev_datachangerequests");
        }
        if (data[0]?.accountid && event.detail.widget?.associateCustomKey) {
          // define alternative custom key format
          const veevaNetworkFingerprint = this.veevaWidgetService.config.veevaNetworkFingerprint;
          event.detail.widget.associateCustomKey(
            entity.entityId,
            veevaNetworkFingerprint,
            entity.entityType,
            data[0]?.accountid,
            veevaNetworkFingerprint
          );
        }

        console.log(data)
        loading.dismiss();
        if (!data[0].accountid) this.notificationService.notify(this.translate.instant('ERROR_CREATING_VEEVA_ACCOUNT'), 'Account List', 'top', ToastStyle.DANGER, 3000);
        else {
          let rawDetails = await this.accountDataService.getRealTimeAccountDetailsOnline(data[0].accountid);
          if (rawDetails) {
            await this.accountService.addUpdateRawAccountDetails(rawDetails);
            let newAccount: Account = this.accountService.getAccountById(data[0].accountid);
            if (veevaIndex >= 0) {
              this.notificationService.notify( this.translate.instant('CONTACT_UPDATE_CREATED'), "Account List", "top", ToastStyle.INFO, 3000);
            } else {
              this._updateAccountsListAfterUpdateOrCreate(newAccount,true);
              this.accountService.selected = null;
              this.notificationService.notify( this.translate.instant('NEW_ACCOUNT_CREATED'), "Account List", "top", ToastStyle.INFO, 3000);
            }
            newAccount.isSelected = true;
            this.accountService.selectedAccounts ??= [];
            this.accountService.selectedAccounts.push(newAccount);
            this.isDirty = this.isAccountsDirty();
          }
        }
      } catch(err) {
        loading.dismiss();
        this.notificationService.notify(this.translate.instant('ERROR_CREATING_VEEVA_ACCOUNT'), 'Account List', 'top', ToastStyle.DANGER, 3000);
      }
    } else {
      loading.present();
      try {
        let data = await this.contactService.createVeevaContact(entity.entity);
        console.log(data)
        if (data[0]?.contactid && event.detail.taskId) {
          await this.dynamics.dwa.create({
            omniveev_externalid: event.detail.taskId,
            omniveev_type: 200000000,
            'omniveev_customer_contact@odata.bind': `/contacts(${data[0]?.contactid})`
          }, "omniveev_datachangerequests");
        }
        if (data[0]?.contactid && event.detail.widget?.associateCustomKey) {
          // define alternative custom key format
          const veevaNetworkFingerprint = this.veevaWidgetService.config.veevaNetworkFingerprint;
          event.detail.widget.associateCustomKey(
            entity.entityId,
            veevaNetworkFingerprint,
            entity.entityType,
            data[0]?.contactid,
            veevaNetworkFingerprint
          );
        }
        loading.dismiss();
        if (!data[0].contactid)
          this.notificationService.notify(this.translate.instant('ERROR_CREATING_VEEVA_CONTACT'), 'Account List', 'top', ToastStyle.DANGER, 3000);
        else {
          const newContacts = await this.contactService.fetchContactsForConfiguredDisplay(false, false, data[0].contactid);
          const createdContact = new Contact(newContacts[0]);
          this.notificationService.notify( this.translate.instant('CONTACT_NEW_CREATED'), "Account List", "top", ToastStyle.INFO, 3000);
        }
      } catch(err) {
        loading.dismiss();
        this.notificationService.notify(this.translate.instant('ERROR_CREATING_VEEVA_CONTACT'), 'Account List', 'top', ToastStyle.DANGER, 3000);
      }
    }
  }

  private async _globalSearch() {
    this.accountService.isGlobalSearching = true;
    this.accountService.resetGlobalSearch(true);
    this.resetOneKeySearch();
    this.initGlobalSearchedAccountsHeaderModel();
    this._initKeyControlsAreaModel();

    const loader = await this.loadingController.create();
    loader.present();

    try {
      const searchResult: AccountGlobalSearchDTO[] =  await this.accountDataService.globalSearch(this.selectedSuggestionsData);
      this.accountService.mapGlobalSearchedAccounts(searchResult);
      // Update result count
      this.initGlobalSearchedAccountsHeaderModel();
    } catch (error) {
      console.error('_globalSearch: ', error);
    } finally {
      loader.dismiss();
    }
  }

  private async _addGlobalSearchedAccountToUserPosition() {
    if (this.device.isOffline) return;
    if (this.accountService.selectedAccounts[0]) {
      const isReasonFieldEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_MAPPING_APPROVAL);
      const res = await this.alertService.showAlert({
        title: this.translate.instant('ACCOUNT_ADD'),
        inputs: isReasonFieldEnabled
          ? [{
            type: 'text',
            name: 'reason',
            placeholder: this.translate.instant('ADD_REASON_TO_ADD_TO_YOUR_ACCOUNT')
          }]
          : []
      });

      if (res.role == 'ok') {
        const loader = await this.loadingController.create();
        loader.present();

        try {
          let customerPositionPayload;
          if (isReasonFieldEnabled) {
            if (res?.data?.values?.reason) {
              customerPositionPayload = {
                indskr_reason: res.data.values.reason,
                indskr_skipapproval: false,
              };
            } else {
              this._addGlobalSearchedAccountToUserPosition();
              return;
            }
          }

          let mapSuccess: boolean;

          try {
            mapSuccess = await this.accountDataService.mapGlobalSearchedAccountToUserPosition(
              this.accountService.selectedAccounts[0].id,
              customerPositionPayload,
            );
          } catch (error) {
            if (error?.error?.errorMessage?.includes('Duplicate Detected')) {
              const errorMessage: string = this.getErrorMessageMappingPosition("ACCOUNT", error.error.errorMessage);
              if (errorMessage) {
                this.notificationService.notify(
                  errorMessage,
                  'AccountList',
                  'top',
                  ToastStyle.DANGER,
                  3000,
                );
              }
            }
          } finally {
            if (mapSuccess) {
              const isVisibilityApprovalBased = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_VISIBILITY_APPROVAL_BASED);
              //selected contact affiliation to user position
              if(!_.isEmpty(this.accountService.contactIdsToAdd)) {
                // this.notificationService.notify(this.translate.instant('CONTACTS_SUBMISSION_IN_BACKGROUND'), 'ContactList', "top", ToastStyle.INFO, 3000);
                await this._mapContactToUserPosition(customerPositionPayload, isReasonFieldEnabled);
              }
              if (
                !isReasonFieldEnabled
                || customerPositionPayload?.indskr_skipapproval !== false
                || !isVisibilityApprovalBased
              ) {
                const rawAccount = await this.accountDataService.getRealTimeAccountDetailsOnline(
                  this.accountService.selectedAccounts[0].id
                );

                if (rawAccount) {
                  const newAccount = await this.accountService.addUpdateRawAccountDetails(rawAccount);
                  this._updateAccountsListAfterUpdateOrCreate(newAccount, true);
                  // Clear search
                  this.accountService.resetGlobalSearch();
                  this.selectedSuggestionsData = [];
                  this.filteredList = [];
                  this.visibleFilteredList = [];
                  this.accountSearchText = '';
                  this.searching = false;
                  this.filtering = false;
                  this.sortAccountsListBasedOnSelection(this.sortBy);
                  this._initKeyControlsAreaModel();
                  this.initAllAccountsHeaderModel();
                }
              } else {
                this.accountService.selectedAccounts = [];
                this.accountService.resetGlobalSearch();
                this.selectedSuggestionsData = [];
                this.filteredList = [];
                this.visibleFilteredList = [];
                this.accountSearchText = '';
                this.searching = false;
                this.filtering = false;
                this._initKeyControlsAreaModel();
                this.initAllAccountsHeaderModel();
                this.notificationService.notify(this.translate.instant('SUBMITTED_FOR_APPROVAL'), 'ContactList', "top", ToastStyle.INFO, 3000);
              }
              if(!isReasonFieldEnabled || !isVisibilityApprovalBased) {
                await this.myAssistantService.loadAndMapNotificationsFromDB();
              }
            }
          }
        } catch (error) {
          console.error('_addGlobalSearchedAccountToUserPosition: ', error);
        } finally {
          loader.dismiss();
        }
      }
    }
  }

  sortAccountsList(){
    this.popover
      .create({component: MultiSelectPopover,componentProps: { root: this.sortPopoverData }, event:event})
      .then((data)=>{
        data.present();
      })
  }

  // start advance search functions
  @HostListener('window:click', ['$event.target'])
  onClick(targetElement: string) {
    if(this.suggestionsActive) {
      this.suggestionsActive = false
    }
    this._cd.detectChanges();
  }

  onInput(eventDetail, event) {
    this.searchKey = '';
    //disable search contacts if fired because we eptied the serach text programatically
    if(!this.shouldFireSearchChangeEvent){
      this.shouldFireSearchChangeEvent = true;
      return;
    }
    let params = (eventDetail.value) ? eventDetail.value : '';
    if(this.currentTab == AccountsModel.CHANGE_REQUESTS_TAB) {
      this.handleCRSearch(params);
      return;
    }
    if (this.accountService.isGlobalSearching) {
      this.accountService.resetGlobalSearch();
    }
    if (this.accountService.isOneKeySearching) {
      this.resetOneKeySearch();
    }
    //for length > 2
    if (params.length >= 2) {
      if (params.trim().length == 0) return;
      this.searching = true;
      this.searchKey = params;
      this._initKeyControlsAreaModel()
      if (this.accountService.isOneKeyAffiliatedToAccount) {
        this.searchAccountSuggestions(this.accountService.oneKeyAccounts, params, event);
      } else {
        this.searchAccountSuggestions(this.accountService.accounts, params, event);
      }
      this.sortAccountsListBasedOnSelection(this.sortBy);
    }
    // for length 0 to 2
    else {
        this.suggestionsData = [];
        if(!this.selectedSuggestionsData || !this.selectedSuggestionsData.length){
          this.filteredList = []
          this.visibleFilteredList = [];
          this.searching = false;
          this.sortAccountsListBasedOnSelection(this.sortBy);
          this._initKeyControlsAreaModel()
        }
        else{
          this.searchAccountsList();
        }
        if(!this.accountService.recentSearches || !this.accountService.recentSearches.length){
          let searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='Accounts')
          if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
            this.suggestionsActive = false;
          }
        }
    }
    this.filterNearByAccounts();
    this.updateEmptyMessage()
    this._cd.detectChanges();
    this.initResultsAccountsheaderModel();
    this.checkEnableDisableSelectAllOption();
  }

  private filterNearByAccounts() {
    if (this.accountService.accessedAccountListFrom === PageName.ShortCallHomeComponent) {
      if (this.searching || this.filtering) {
        this.nearByAccountsToDisplay = this.nearByAccounts.filter(acc => this.filteredList.some(fAcc => fAcc.id === acc.id));
      } else {
        this.nearByAccountsToDisplay = this.nearByAccounts;
      }
      this.initNearByAccountsHeaderModel();
    }
  }

  public async handleCRSearch(searchText: string) {
    if (searchText && searchText.trim().length > 0) {
      const formattedSearchText = this.searchConfigService.convertFormattedString(searchText).toLowerCase();
      this.isFilteredAccountCRs = true;
      this.filteredAccountCRs = this.accountCRs.filter(c => {
        if (c.indskr_name) {
          let accountCRstring = this.searchConfigService.convertFormattedString(c.indskr_name).toLowerCase();
          return accountCRstring && accountCRstring.includes(formattedSearchText);
        }
      });
    } else {
      this.filteredAccountCRs = this.accountCRs;
      this.isFilteredAccountCRs = false;
    }
    if(this.myAccountChangeRequestsHeaderModel) {
      this.myAccountChangeRequestsHeaderModel.title = (_.isEmpty(searchText) ? this.translate.instant('MY_CHANGE_REQUESTS') : this.translate.instant('RESULTS_CAP')) + ' (' + this.filteredAccountCRs.length + ')';
    }
    this.updateEmptyMessage();
  }

  //search the facets list to display suggestions and also do text search for contacts list
  searchAccountSuggestions(accounts: Account[], searchText: string, event?){
    if (this.accountListAccessedFrom == 'AccountManagementPage' || this.accountListAccessedFrom == 'OpportunityManagementPage') {
      this.filteredList = [];
      const formattedSearchText = searchText ? this.searchConfigService.convertFormattedString(searchText).toLowerCase() : '';
      this.accountService.uniqueAccountsFromOtherTools.map(account => {
        let accountString = this.getOjectValues(account);
        if (accountString.includes(formattedSearchText)) {
          this.filteredList.push(account);
        }
      });
    }
    else{
      if (searchText && accounts) {
        searchText = searchText.toUpperCase();
        // let obj = {
        //   categoryDisplayName : "Tag",
        //   categoryName  : "Tag",
        //   categoryRelativePath  : "userTag.indskr_name",
        //   // featureActionDependant  : "Tag",
        //   translationKey  : "Tag",
        //   values  : []
        // }
        let values=this.userTagService.accountTags.map(x=>x.indskr_name);
        this.searchConfigService.accountConfiguredSearchIndexConfig.find(x=>x.categoryName=="Tag" || x.categoryName == this.translate.instant("Tag")).values = values;
        // let copyAccountSearchConfig = JSON.parse(JSON.stringify(this.searchConfigService.accountConfiguredSearchIndexConfig))  
        // copyAccountSearchConfig.push(obj);
    
        let searchIndexConfig = this.searchConfigService.accountConfiguredSearchIndexConfig
        searchIndexConfig = searchIndexConfig.filter(s=>s.configuredFrom != 'Assess');
        //MDM ID category search enabled at searchText length > 3
        const searchTextLength: number = searchText.length;
        if(searchTextLength < 2) searchIndexConfig = searchIndexConfig.filter(s=>s.categoryRelativePath != 'indskr_mdmid');
        this.suggestionsData = this.searchConfigService.fetchSuggestions(searchIndexConfig,
                                    this.selectedSuggestionsData,this.translate.instant("ACCOUNTS"),
                                    this.accountSearchText, !this.device.isOffline);
         //testing for delete
         this.suggestionsData.filter(y=>y.header).filter(y=>y.header.title=="Tag" || y.header.title == this.translate.instant("Tag")).forEach(t=>t.listData.forEach(y=>{
          let tag=this.userTagService.accountTags.find(x=>x.indskr_name==y.primaryTextLeft)
          if(tag){
            y.showArrow = tag.visibility == TagVisibility.PERSONAL;
            y.arrowType="indegene-ios-close-icon";
            y.tagDetails= tag;
            y.clickHandler = ((id?: string, event?: any, specificTarget?: string, tag?: any) => {
              if (specificTarget == 'endArrow') {
                this.deleteTag(tag.tagDetails);
              } 
              else if (specificTarget == 'mainItem'){
                this.handleTagSelectionTag(tag.tagDetails);
              }
            })
          }  
        }));
        if(this.suggestionsData.length) this.suggestionsActive = true;
        if(!this.selectedSuggestionsData || !this.selectedSuggestionsData.length){
          this.filteredList = accounts
        }
        const formattedSearchText = this.searchConfigService.convertFormattedString(searchText).toLowerCase();
        this.filteredList = this.filteredList.filter((acc)=>{
          let accountString = this.getOjectValues(acc);
          return accountString.includes(formattedSearchText);
        })
        this.filteredRecordCount = 30;
        this._cd.detectChanges()
      }
    }
    this.visibleFilteredList = this.filteredList.slice(0, this.filteredRecordCount);  //this.accountService.accounts.slice(0,this.recordCount);
    this.initAllAccountsHeaderModel();
  }
  public handleTagSelectionTag(tagData: UserTag) {
    // if (!tagData) return;
    // this.suggestionsActive = false;
    // this.showRemoveTag = false;
    // this._selectedAccountsForTag = [];
    // this.searching = true;
    // this.filteredList =[];
    // this.suggestionsActive = false;
    // tagData.entityRecords.forEach(record => {
    //   let account = this.accountService.getAccountById(record.id);
    //   if (account && !(!account.status || account.status === 2 || account.status == 548910003 || account.status == 548910010 || account.status == 548910011 || account.status == 548910012 || account.status == 548910013)) {
    //     account.isSelectedForTag = false;
    //     if (!this.filteredList.some(acc => acc.id == record.id)) {
    //       this.filteredList.push(account);
    //     }
    //   }
    // });

    // this.filterMetadata.count = this.filteredList.length;
    // this.visibleFilteredList = this.slicePresentations(0, this.filteredRecordCount);
    // this.updateAccToDisplay()
    const selectedPill: SelectedSuggestionPillDataModel = this.getTagSuggestionPill(tagData);
    //Only one tag can be filtered at a time
    // this.selectedSuggestionsData = this.selectedSuggestionsData.filter(pill => pill.categoryName !== selectedPill.categoryName);
    // this.selectedSuggestionsData.push(selectedPill);
    // this.selectedTagData = _.cloneDeep(tagData);
    this.handleFacetSelection(selectedPill);
    // this.handleTagSelection(tagData);

  }
  //search the list based off selected facets
  async searchAccountsList(isFilter?: boolean){
    if(isFilter) {
      this.filtering = true
    }else {
      this.searching = true;
    }
    let selectedData: SelectedSuggestionPillDataModel[] = [];
    if(this.filtering && this.searching) {
      selectedData = _.uniq(_.concat(this.selectedFilterData, this.selectedSuggestionsData));
    } else {
      selectedData = isFilter ? this.selectedFilterData : this.selectedSuggestionsData;
    }
    selectedData = this.mapForUserTag(selectedData);
    let filteredAccounts:any = this.accountService.isOneKeyAffiliatedToAccount ? this.accountService.oneKeyAccounts : this.accountService.accounts;
    let selectedSuggestionsDataCopy = selectedData.slice();
    this.isAssessmentFilter = selectedData.filter(data => data.categoryName == "Assessed" || data.categoryName == "UnAssessed").length > 0;
    this._initKeyControlsAreaModel();
    selectedSuggestionsDataCopy = selectedSuggestionsDataCopy.filter(suggestion=> suggestion.categoryName!='Opportunities');
    let entityLevelCharSearch = selectedSuggestionsDataCopy.find(o=> o.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH);
    const formattedSearchText = entityLevelCharSearch && entityLevelCharSearch.charSearchText ? this.searchConfigService.convertFormattedString(entityLevelCharSearch.charSearchText).toLowerCase() : '';
    const formattedSearchTextSplit = formattedSearchText ? formattedSearchText.split(" ") : '';
    if (entityLevelCharSearch && formattedSearchTextSplit) {
      formattedSearchTextSplit.forEach(searchText => {
        filteredAccounts = filteredAccounts.filter(account => {
          let accountEntityLevel = this.getOjectValues(account);
           return accountEntityLevel.includes(searchText);
         });
      });
    }
    // Handle Account Tag AND operation
    if(this.selectedSuggestionsData && this.selectedSuggestionsData.some(a=> a.categoryName == 'listPeriodTag')){
      try {
        filteredAccounts = filteredAccounts.filter(account => account.tagLabels.includes(this.selectedSuggestionsData.find(a=> a.categoryName == 'listPeriodTag').selectedFacet));
      } catch (error) {}
    }
    let filteredAccountsForAssessmentFilter = _.cloneDeep(filteredAccounts);
    let groupedSelectedSuggestions: {
      category:string,
      suggestions:SelectedSuggestionPillDataModel[]
    }[] = [];
    selectedSuggestionsDataCopy.forEach(data=>{
      if(data && data.categoryName){
        let groupIndex = groupedSelectedSuggestions.findIndex(group=>group.category==data.categoryName)
        const suggestions = getSearchSuggestionsData(data);

        if(groupIndex>-1){
          groupedSelectedSuggestions[groupIndex].suggestions.push(...suggestions);
        }
        else{
          if(data.categoryName != 'listPeriodTag'){
            groupedSelectedSuggestions.push({
              category:data.categoryName,
              suggestions:[...suggestions]
            });
          }
        }
      }
    })
    let isAssessed: boolean = false;
    let isUnAssessed: boolean = false;
    if (!_.isEmpty(groupedSelectedSuggestions)) {
      groupedSelectedSuggestions.forEach(config => {
        if (config.category === "Assessed" || config.category === "UnAssessed") {
          isAssessed = isAssessed || config.category === "Assessed";
          isUnAssessed = isUnAssessed || config.category === "UnAssessed";
        }
        if ((config.category === "Assessments" || config.category === this.translate.instant("Assessments")) && config.suggestions && config.suggestions.length > 0) {
          // Update flags based on suggestions
          isAssessed = isAssessed || config.suggestions.some(s => s.selectedFacet === "Assessed" || s.selectedFacet === this.translate.instant("ASSESSED"));
          isUnAssessed = isUnAssessed || config.suggestions.some(s => s.selectedFacet === "UnAssessed" || s.selectedFacet === this.translate.instant("UNASSESSED"));
        }
      });
    }
    for (let i = 0; i < groupedSelectedSuggestions.length; i++) {
      const groupConfig = groupedSelectedSuggestions[i];
      const isAssessmentsCategory: boolean = groupConfig.category == "Assessments" || groupConfig.category == this.translate.instant("Assessments");
      let tempAccountsList: Account[] = filteredAccounts;
      let filteredAccountsWithinSameCategory: any = [];
      let activeAssessments;
      
      //groupConfig.suggestions.forEach(async config=>{
      if ((groupConfig.category == "Assessed" || isAssessmentsCategory) && !isUnAssessed) {
        this.customerAssessService.assessedAccounts.forEach(assessedAccount => {
          let thisAccount = tempAccountsList.find(a => a.id == assessedAccount['accountid']);
          if (!thisAccount) return;
          if (!filteredAccountsWithinSameCategory.some(cont => cont.id == assessedAccount['accountid'])) {
            filteredAccountsWithinSameCategory.push(thisAccount);
          }
        })
      } else if ((groupConfig.category == "UnAssessed" || isAssessmentsCategory) && !isAssessed) {
        this.accountService.accounts.forEach(account => {
          if (!this.customerAssessService.assessedAccounts.some(assessedAccount => assessedAccount['accountid'] == account.id)) {
            let thisAccount = tempAccountsList.find(ac => ac.id == account.id);
            if (!thisAccount) return;
            if (!filteredAccountsWithinSameCategory.some(ac => ac.id == account.id)) filteredAccountsWithinSameCategory.push(account);
          }
        })
      } else if((groupConfig.category == "Assessed" || groupConfig.category == "UnAssessed" || isAssessmentsCategory) && isAssessed && isUnAssessed) {
        if(groupedSelectedSuggestions.length == 2) {
          filteredAccountsWithinSameCategory = filteredAccountsForAssessmentFilter;
        }else {
          filteredAccountsWithinSameCategory = tempAccountsList;
        }
      } else {
        for (let j = 0; j < groupConfig.suggestions.length; j++) {
          const config = groupConfig.suggestions[j];
          if (config.categoryName != 'Opportunities') {
            let searchConfig;
            if (config.configuredFrom == 'Assess') {
              searchConfig = this.searchConfigService.accountConfiguredSearchIndexConfig.find(a => a.categoryName == config.categoryName);
              config.categoryPath = searchConfig ? searchConfig.categoryRelativePath : config.categoryPath;
            } else if (config.categoryPath) {
              searchConfig = this.searchConfigService.accountConfiguredSearchIndexConfig.find(a => a.categoryRelativePath == config.categoryPath);
            } else {
              searchConfig = this.searchConfigService.accountConfiguredSearchIndexConfig.find(a => a.categoryName == config.categoryName);
              config.categoryPath = searchConfig ? searchConfig.categoryRelativePath : config.categoryPath;
            }

            let key = [config.categoryPath];
            var options2 = {
              keys: key,
              threshold: 0,
              distance: 100,
              includeScore: true
            }
            let searchText: string;
            if (config.type && (config.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH || config.type == SuggestionPillType.BOOLEAN_FIELD)) {
              searchText = config.charSearchText;
              options2.threshold = 0.1;
              options2.distance = 1000;
            }
            else {
              searchText = config.selectedFacet;
            }
            if (searchConfig && searchConfig.controlDataType == 'Assess') {
              if(searchConfig.categoryRelativePath == "indskr_choicelabel") {
                if (config.selectedFacetInEng) {
                  searchText = config.selectedFacetInEng
                }
                options2.distance = 1000;
                options2.threshold = 0.2;
              }
              let isTypeChoice: boolean = searchConfig.categoryRelativePath == "indskr_choicelabel";
              let searchType =  isTypeChoice ? AssessmentAttributeType.CHOICE : AssessmentAttributeType.FLAG;
              let categoryName = searchConfig.categoryNameInEng ? searchConfig.categoryNameInEng : searchConfig.categoryName;
              let assessments = await this.customerAssessService.getAssessmentResponsesForOnlineSearch(EntityOptions.ACCOUNT, searchType, categoryName);

              if(assessments) {
                assessments = assessments.filter(assess => {
                  return tempAccountsList.some(account => account.id == assess['ca.indskr_customer']);
                })
              }
              const sortedActiveAssessments = _.orderBy(assessments, ['modifiedon','createdon'], ['desc','desc']);
              const latestRecords = _.uniqBy(sortedActiveAssessments, 'ca.indskr_customer');

              let results;
              let exactMatchResults = [];
              if (searchConfig.categoryRelativePath == "indskr_choicelabel" && config.type && config.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
                results = latestRecords.filter(assess => {
                  if(assess['indskr_choicelabel']) {
                    //check multiple choice
                    let isMatched: boolean = false;
                    let tempData = assess['indskr_choicelabel'].split(',') || [];
                    if(!_.isEmpty(tempData) && tempData.length > 1) {
                      tempData.forEach((d,idx)=> {
                        if(tempData[idx] == searchText) isMatched = true;
                      });
                      return isMatched;
                    }else {
                      return assess['indskr_choicelabel'] == searchText;
                    }
                  }
                });
                //@ts-ignore
                results.forEach((result: any) => {
                  let accountObj = tempAccountsList.find(a => a.id == result['ca.indskr_customer']);
                  if (accountObj) exactMatchResults.push(accountObj);
                })
              } else {
                let newFuse = new fuse(latestRecords, options2);
                results = newFuse.search(searchText);
                //@ts-ignore
                results.forEach((result: any) => {
                  let accountObj = tempAccountsList.find(a => a.id == result.item['ca.indskr_customer']);
                  if (accountObj) {
                    exactMatchResults.push(accountObj)
                  }
                })
              }
              filteredAccountsWithinSameCategory = _.uniq(_.concat(filteredAccountsWithinSameCategory, exactMatchResults))
            } else if (searchConfig && searchConfig.controlDataType == 'LinkedEntity') {
              //options2.keys = [[config.categoryPath]];
              if (searchConfig.parentEntity == ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY) {
                const dbKeyFrom = 'indskr_account_accountaccountaffiliation_affiliatedfromaccountid';
                const dbKeyTo = 'indskr_account_accountaccountaffiliation_affiliatedtoaccountid';
                let rawLEDataFrom = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY + dbKeyFrom);
                let rawLEDataTo = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY + dbKeyTo);
                if (rawLEDataFrom && rawLEDataFrom.raw) {
                  let suffix = '@OData.Community.Display.V1.FormattedValue';
                  let searchKey = key[0];
                  let searchObjectKey = 'accountid';
                  if (key[0] == "indskr_accountaccountaffiliation.indskr_affiliatedfromaccountid" || key[0] == "indskr_accountaccountaffiliation.indskr_affiliatedtoaccountid") {
                    searchKey = "indskr_accountaccountaffiliation.indskr_affiliatedtoaccountid";
                    searchObjectKey = "indskr_accountaccountaffiliation.indskr_affiliatedfromaccountid";
                  }
                  if (searchConfig.isMultilingualLookup && config.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
                    suffix = '';
                    let foundMappingValue;
                    if (searchConfig.mappingValues && Array.isArray(searchConfig.mappingValues)) {
                      foundMappingValue = searchConfig.mappingValues.find(a => a.formattedValue == searchText);
                      if (foundMappingValue && foundMappingValue.actualValue) {
                        searchText = foundMappingValue.actualValue;
                      }
                    }
                  }
                  const linkedEntityData = rawLEDataFrom.raw;
                  let currentDataFrom = [];
                  if (config.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
                    currentDataFrom = linkedEntityData.filter(item => item[searchKey + suffix] == searchText || item[searchKey] == searchText);
                  } else if (config.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH) {
                    currentDataFrom = linkedEntityData.filter(item => {
                      let formattedValueA = item[searchKey + suffix] ? this.searchConfigService.convertFormattedString(item[searchKey + suffix]).toLowerCase() : '';
                      let formattedValueB = item[searchKey] ? this.searchConfigService.convertFormattedString(item[searchKey]).toLowerCase() : '';
                      let formattedSearchText = searchText ? this.searchConfigService.convertFormattedString(searchText).toLowerCase() : '';
                      return formattedValueA && formattedValueA.includes(formattedSearchText) || formattedValueB && formattedValueB.includes(formattedSearchText);
                    });
                  }
                  currentDataFrom.forEach(linkedEntityObj => {
                    let thisAccount = tempAccountsList.find(a => a.id == linkedEntityObj[searchObjectKey]);
                    if (!thisAccount) return;
                    if (!filteredAccountsWithinSameCategory.some(cont => cont.id == thisAccount.id)) {
                      filteredAccountsWithinSameCategory.push(thisAccount);
                    }
                  });
                }
                if (rawLEDataTo && rawLEDataTo.raw) {
                  let suffix = '@OData.Community.Display.V1.FormattedValue';
                  let searchKey = key[0];
                  let searchObjectKey = 'accountid';
                  if (key[0] == "indskr_accountaccountaffiliation.indskr_affiliatedfromaccountid" || key[0] == "indskr_accountaccountaffiliation.indskr_affiliatedtoaccountid") {
                    searchKey = "indskr_accountaccountaffiliation.indskr_affiliatedfromaccountid";
                    searchObjectKey = "indskr_accountaccountaffiliation.indskr_affiliatedtoaccountid";
                  }
                  if (searchConfig.isMultilingualLookup && config.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
                    suffix = '';
                    let foundMappingValue;
                    if (searchConfig.mappingValues && Array.isArray(searchConfig.mappingValues)) {
                      foundMappingValue = searchConfig.mappingValues.find(a => a.formattedValue == searchText);
                      if (foundMappingValue && foundMappingValue.actualValue) {
                        searchText = foundMappingValue.actualValue;
                      }
                    }
                  }
                  const linkedEntityData = rawLEDataTo.raw;
                  let currentDataTo = [];
                  if (config.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
                    currentDataTo = linkedEntityData.filter(item => item[searchKey + suffix] == searchText || item[searchKey] == searchText);
                  } else if (config.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH) {
                    currentDataTo = linkedEntityData.filter(item => {
                      let formattedValueA = item[searchKey + suffix] ? this.searchConfigService.convertFormattedString(item[searchKey + suffix]).toLowerCase() : '';
                      let formattedValueB = item[searchKey] ? this.searchConfigService.convertFormattedString(item[searchKey]).toLowerCase() : '';
                      let formattedSearchText = searchText ? this.searchConfigService.convertFormattedString(searchText).toLowerCase() : '';
                      return formattedValueA && formattedValueA.includes(formattedSearchText) || formattedValueB && formattedValueB.includes(formattedSearchText);
                    });
                  }
                  currentDataTo.forEach(linkedEntityObj => {
                    let thisAccount = tempAccountsList.find(a => a.id == linkedEntityObj[searchObjectKey]);
                    if (!thisAccount) return;
                    if (!filteredAccountsWithinSameCategory.some(cont => cont.id == thisAccount.id)) {
                      filteredAccountsWithinSameCategory.push(thisAccount);
                    }
                  });
                }
              } else {
                const dbKey = searchConfig.parentEntity;
                let rawLEData = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY + dbKey);
                if (rawLEData && rawLEData.raw) {
                  let suffix = '@OData.Community.Display.V1.FormattedValue';
                  if (searchConfig.isMultilingualLookup && config.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
                    suffix = '';
                    let foundMappingValue;
                    if (searchConfig.mappingValues && Array.isArray(searchConfig.mappingValues)) {
                      foundMappingValue = searchConfig.mappingValues.find(a => a.formattedValue == searchText);
                      if (foundMappingValue && foundMappingValue.actualValue) {
                        searchText = foundMappingValue.actualValue;
                      }
                    }
                  }
                  const linkedEntityData = rawLEData.raw;
                  let currentData;
                  if (config.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
                    currentData = linkedEntityData.filter(item => item[key[0] + suffix] == searchText || item[key[0]] == searchText);
                  } else if (config.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH) {
                    currentData = linkedEntityData.filter(item => {
                      let formattedValueA = item[key[0] + suffix] ? this.searchConfigService.convertFormattedString(item[key[0] + suffix]).toLowerCase() : '';
                      let formattedValueB = item[key[0]] ? this.searchConfigService.convertFormattedString(item[key[0]]).toLowerCase() : '';
                      let formattedSearchText = searchText ? this.searchConfigService.convertFormattedString(searchText).toLowerCase() : '';
                      return formattedValueA && formattedValueA.includes(formattedSearchText) || formattedValueB && formattedValueB.includes(formattedSearchText);
                    });
                  }
                  currentData.forEach(linkedEntityObj => {
                    let thisAccount = tempAccountsList.find(a => a.id == linkedEntityObj.accountid);
                    if (!thisAccount) return;
                    if (!filteredAccountsWithinSameCategory.some(cont => cont.id == thisAccount.id)) {
                      filteredAccountsWithinSameCategory.push(thisAccount);
                    }
                  });
                }
              }
            } else if (searchConfig && searchConfig.controlDataType != 'LinkedEntity' && searchConfig.controlDataType != 'DefaultConfig') {
              let suffix: string = '';
              let prefix: string = '';
              if (searchConfig.controlDataType) {
                if (searchConfig.controlDataType == ControlDataType.PicklistType || searchConfig.controlDataType == ControlDataType.BooleanType
                  || searchConfig.controlDataType == ControlDataType.StatusType || searchConfig.controlDataType == ControlDataType.StateType) {
                  suffix = '@OData.Community.Display.V1.FormattedValue'
                } else if (searchConfig.controlDataType == ControlDataType.LookupType) {
                  if (searchConfig.isMultilingualLookup && config.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
                    suffix = '_value';
                    prefix = '_';
                    let foundMappingValue;
                    if (searchConfig.mappingValues && Array.isArray(searchConfig.mappingValues)) {
                      foundMappingValue = searchConfig.mappingValues.find(a => a.formattedValue == searchText);
                      if (foundMappingValue && foundMappingValue.actualValue) {
                        searchText = foundMappingValue.actualValue;
                      }
                    }
                  } else {
                    suffix = '_value@OData.Community.Display.V1.FormattedValue'
                    prefix = '_';
                  }
                }
              }
              let searchKey = prefix + searchConfig.categoryRelativePath + suffix;
              let rawAccountsList = tempAccountsList.map(a => a.raw);
              let results;
              if (config.type == SuggestionPillType.CATEGORY_VALUESEARCH || config.type == SuggestionPillType.BOOLEAN_FIELD) {
                results = rawAccountsList.filter(con => con.hasOwnProperty('accountid') && con.hasOwnProperty(searchKey) && con[searchKey] == searchText);
              } else if (config.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH) {
                results = rawAccountsList.filter(con => {
                  let formattedValue = con[searchKey] ? this.searchConfigService.convertFormattedString(con[searchKey]).toLowerCase() : '';
                  let formattedSearchText = searchText ? this.searchConfigService.convertFormattedString(searchText).toLowerCase() : '';
                  return con.hasOwnProperty('accountid') && formattedValue && formattedValue.includes(formattedSearchText);
                });
              }

              let exactMatchResults = [];
              //@ts-ignore
              results.forEach((result: any) => {
                let accountObj = tempAccountsList.find(a => a.id == result.accountid);
                if (accountObj) {
                  exactMatchResults.push(accountObj)
                }
              })
              filteredAccountsWithinSameCategory = _.uniq(_.concat(filteredAccountsWithinSameCategory, exactMatchResults))
            } else if(searchConfig && searchConfig.categoryRelativePath == "hasTeamusers" && config.selectedFacet) {
              try {
                if(config.value) {
                  if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT)) {
                    await this.uiService.displayLoader();
                    let response: AccountGlobalSearchDTO[] = [];
                    try {
                      response = await this.accountDataService.getTargetAccountsBasedOnPositionId(config.value);
                    } catch (error) {
                      console.error('TargetAccounts - AccountList: ', error);
                    } finally {
                      if(!_.isEmpty(response)) {
                        console.warn('TargetAccounts - Found');

                        this.accountService.mapGlobalSearchedAccounts(response, true);
                        filteredAccountsWithinSameCategory = this.accountService.globalSearchResults;
                        this.accountService.globalSearchResults = [];

                        if(response[0]['customerListPeriodName']) {
                          this.selectedSuggestionsData = [];
                          this.searching = true;
                          // this.isTargetContactsPillRemoved = true;
                          this.selectedSuggestionsData = [{
                            selectedFacet: response[0]['customerListPeriodName'],
                            categoryPath: 'listTag',
                            categoryName: 'listTag',
                            categoryDisplayName: 'listTag',
                          }]
                        }
                      } else {//end of if(!_.isEmpty(response))
                        console.warn('No TargetAccounts');
                        await this.uiService.displayLoader();
                        let response = [];
                        try {
                          response = await this.accountDataService.getAccountsBasedOnPositionId(config.value);
                        } catch (error) {
                          console.error('AccountList: ', error);
                        } finally {
                          if(!_.isEmpty(response)) {
                            this.accountService.mapGlobalSearchedAccounts(response, true);
                            filteredAccountsWithinSameCategory = this.accountService.globalSearchResults;
                            this.accountService.globalSearchResults = [];
                          }
                          await this.uiService.dismissLoader();
                        }
                      }
                      await this.uiService.dismissLoader();
                    }
                  }
                  else {
                    console.warn('Fetch AccountList');

                    await this.uiService.displayLoader();
                    let response = [];
                    try {
                      response = await this.accountDataService.getAccountsBasedOnPositionId(config.value);
                    } catch (error) {
                      console.error('AccountList: ', error);
                    } finally {
                      if(!_.isEmpty(response)) {
                        this.accountService.mapGlobalSearchedAccounts(response, true);
                        filteredAccountsWithinSameCategory = this.accountService.globalSearchResults;
                        this.accountService.globalSearchResults = [];
                      }
                      await this.uiService.dismissLoader();
                    }
                  }
                }
              }
              catch(error) {
                console.log(error);
                await this.uiService.dismissLoader();
              }
            }
          } 
        }
      }
      filteredAccounts = filteredAccountsWithinSameCategory;
    }

    let opportunitySuggestions = isFilter ? this.selectedFilterData.filter(o=>o.categoryName=='Opportunities') : this.selectedSuggestionsData.filter(o=>o.categoryName=='Opportunities');
    if(opportunitySuggestions.length){
      let filteredAccountsWithinOpportunityCategory: any = [];
      opportunitySuggestions.forEach(config=>{
        let key = [config.categoryPath]
        var options2 = {
          keys: key,
          threshold: 0.1,
          distance: 1000
          //includeMatches: true
        }
        let searchText: string;
              if(config.type && (config.type==SuggestionPillType.CATEGORY_CHARACTERSEARCH || config.type==SuggestionPillType.BOOLEAN_FIELD)){
                searchText = config.charSearchText;
              }
              else{
                searchText = config.selectedFacet;
              }
              let newFuse = new fuse(this.opportunityMgmtService.opportunities,options2);
              let results:any = newFuse.search(searchText);
              results.forEach(opportunity=>{
                let thisAccount = this.accountService.getAccountById(opportunity.accountID);
                if(!_.isEmpty(thisAccount)) {
                  if(!_.isEmpty(filteredAccountsWithinOpportunityCategory) && !filteredAccountsWithinOpportunityCategory.some(acct=>acct.id == thisAccount.id)) {
                    filteredAccountsWithinOpportunityCategory.push(thisAccount);
                  }else {
                    filteredAccountsWithinOpportunityCategory.push(thisAccount);
                  }
                }
              });
      })
      filteredAccounts = _.intersection(filteredAccounts, filteredAccountsWithinOpportunityCategory);
    }
    let tagSuggestion = selectedData.filter(o=>(o.categoryName=='Tag'||o.categoryName=='tag')) ;
    if(tagSuggestion.length){
      let filteredAccountsWithinTagCategory: any = [];

      let results = selectedData.map(x=>x.selectedFacet);
      results.forEach(id=>{
        let thisAccount = this.accountService.getAccountById(id);
        if(!_.isEmpty(thisAccount)) {
          if(!_.isEmpty(filteredAccountsWithinTagCategory) && !filteredAccountsWithinTagCategory.some(acct=>acct.id == thisAccount.id)) {
            filteredAccountsWithinTagCategory.push(thisAccount);
          }else {
            filteredAccountsWithinTagCategory.push(thisAccount);
          }
        }
      });
      filteredAccounts =  filteredAccountsWithinTagCategory;
    }
    this.filteredList = filteredAccounts;
    this.visibleFilteredList = this.filteredList.slice(0, this.filteredRecordCount);
    if (this.selectedTagData ) {
      this.handleTagSelection(this.selectedTagData);
    }
    this.updateEmptyMessage()
    this._initAllSectionHeaderModel();
  }

  getOjectValues(Object): string {
    let objString: string[] = [];
    var accInclude = ["accountName", "city", "postal", "accountType", "positionname", "extendedDetails"];
    for (let key in Object) {
      if (accInclude.indexOf(key) > -1 && Object[key]) {
        if (Array.isArray(Object[key])) {
          for (let i = 0; i < Object[key].length; i++) {
            objString.push(this.getOjectValues(Object[key][i]));
          }
        } else {
          if (key == "extendedDetails") {
            for (let subKey in Object[key]) {
              let posName = Object[key][subKey]
              if (subKey == "coveragePositions" && Array.isArray(posName) && posName) {
                posName.forEach(pos => {
                  if (pos["positionname"]) {
                    objString.push(pos["positionname"])
                  }

                })
              } else if (subKey == "indskr_accounttype") {
                objString.push(Object[key]["indskr_accounttype@OData.Community.Display.V1.FormattedValue"])
              }
            }
          } else {
            objString.push(Object[key]);
          }
        }
      }
    }
    return this.searchConfigService.convertFormattedString(objString.join().toLowerCase());

  }

  ionSearchFocus(ev) {
    try {
      let el = this.contactListElement.nativeElement.ownerDocument.getElementById('allAccounts');
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.log("scroll error");
    }
  }

  async clickSearchArea(ev){
    ev.stopPropagation();
    if (this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) return;
    if(this.currentTab == AccountsModel.CHANGE_REQUESTS_TAB) {
      return;
    }
    else if(!this.suggestionsActive) {

      if(this.accountListMode != this.compViewMode.READONLY){
        this.suggestionsActive = true;
      }

      if (_.isEmpty(this.accountService.recentSearches) && _.isEmpty(this.userTagService.accountTags) && _.isEmpty(this.listPeriodTags)) {
        let searchToolName = this.searchConfigService.toolNames.find(tool => tool.toolName == 'Accounts')
        if (searchToolName && (this.searchConfigService.savedSearches.filter(search => search.searchToolName == searchToolName.searchToolNameID)).length == 0) {
          this.suggestionsActive = false;
        }
      }
      if (!this.suggestionsActive) this.suggestionsActive = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_ASSESS);
    }
  }

  clickedInSuggestionsArea(ev){
    ev.stopPropagation();
  }

  handleAssessmentSearchClick(value) {
    this.suggestionsActive = false;
    if (value && value.id) {
      this.selectedSuggestionsData = [];
      if (value.id == "assessed") {
        this.selectedSuggestionsData.push(this.getAssessmentPill());
      } else {
        this.selectedSuggestionsData.push(this.getUnAssessmentPill());
      }
      this.accountSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searchAccountsList();
      this.sortAccountsListBasedOnSelection(this.sortBy);
    }
  }

  private getAssessmentPill(): SelectedSuggestionPillDataModel {
    return {
      selectedFacet: this.translate.instant("ASSESSED"),
      categoryPath: 'Assessed',
      categoryName: 'Assessed',
      categoryDisplayName: 'Assessed'
    };
  }

  private getUnAssessmentPill(): SelectedSuggestionPillDataModel {
    return {
      selectedFacet: this.translate.instant("UNASSESSED"),
      categoryPath: 'UnAssessed',
      categoryName: 'UnAssessed',
      categoryDisplayName: 'UnAssessed'
    };
  }

  handleFacetSelection(data:SelectedSuggestionPillDataModel){
    // this.selectedSuggestionsData=[];//clear prev search
    this.disableSaveSearch = false;
    if (this.accountService.isGlobalSearching) {
      this.accountService.resetGlobalSearch();
    }
    if (this.accountService.isOneKeySearching) {
      this.resetOneKeySearch();
    }
    this._initKeyControlsAreaModel()
    console.log(data);
    this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(data, this.selectedSuggestionsData);
    this.selectedSuggestionsData.sort((a,b)=>{
      if(a.createdOn < b.createdOn) return 1
      else return -1
    })
    //if(this.selectedSuggestionsData.length == 1) this.content.resize();
    this.suggestionsActive = false;
    this.accountSearchText = '';
    this.suggestionsData= [];
    this.shouldFireSearchChangeEvent = false;
    this.searchAccountsList().then(()=>{
      this.sortAccountsListBasedOnSelection(this.sortBy);
    });
    if(!data.isComingFromRecentSearch){
      let recentSearches;
      recentSearches = this.accountService.recentSearches
      if(!recentSearches.some(recSearch=>recSearch.categoryName==data.categoryName && recSearch.selectedFacet==data.selectedFacet)){
        recentSearches.unshift(data);
        _.remove(recentSearches, (o, index)=>index>2);
        this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT_RECENT_SEARCHES,(doc)=>{
          if(!doc || !doc.raw){
            doc={
              raw:[]
            }
          }
          doc.raw = recentSearches;
          return doc;
        })
      }
    }

    // if(data.categoryName=="tag"){
    //   this.handleTagSelection(this.selectedTagData);
    //   // this.suggestionsActive = false;
    //   // this.showRemoveTag = true;
    //   // this._selectedAccountsForTag = [];
    //   // this.searching = true;
    //   // this.filteredList =[];
    // }
  }
  handleSavedSearchSelection(data){
    this.selectedSuggestionsData = [];
    if(data && data.categoryValuePairs){
      data.categoryValuePairs.forEach(catValPair=>{
        this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(catValPair, this.selectedSuggestionsData);
      })
      this.selectedSuggestionsData.sort((a,b)=>{
        if(a.createdOn < b.createdOn) return 1
        else return -1
      })
      //if(this.selectedSuggestionsData.length > 0) this.content.resize();
      this.suggestionsActive = false;
      this.accountSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searchAccountsList().then(()=>{
        this.sortAccountsListBasedOnSelection(this.sortBy);
      });
    }
  }

  removeSelectedSuggestion(suggestion){
    this.disableSaveSearch = false;
    _.remove(this.selectedSuggestionsData,(o)=>_.isEqual(o,suggestion));
    if(suggestion.categoryName === 'tag' || suggestion.categoryName === 'Tag') {
      this.showRemoveTag = false;
      this.cancelTagSelection();
      this.selectedTagData = null;
    }
    if (this.accountService.isGlobalSearching) {
      this.accountService.resetGlobalSearch();
    }
    if (this.accountService.isOneKeySearching) {
      this.resetOneKeySearch();
    }
    if(this.selectedSuggestionsData.length == 0){
      //this.content.resize();
      this.filteredList = [];
      this.visibleFilteredList = [];
      this.accountSearchText = '';
      // this.shouldFireSearchChangeEvent = false;
      this.searching = false;
      this._initKeyControlsAreaModel()
      if(this.selectedFilterData?.length > 0) {
        this.searchAccountsList(true);
      }
    }
    else{
      let flag:boolean = this.selectedFilterData?.length > 0 ? true : false;
      this.searchAccountsList(flag);
      this._initKeyControlsAreaModel();
    }
    this.sortAccountsListBasedOnSelection(this.sortBy);
    this.checkEnableDisableSelectAllOption();
    this.onSearchBlur(null);
  }

  removeAllSelectedSuggestions(){
    this.disableSaveSearch = true;
    if (this.accountService.isGlobalSearching) {
      this.accountService.resetGlobalSearch();
    }
    if (this.accountService.isOneKeySearching) {
      this.resetOneKeySearch();
    }
    this.selectedSuggestionsData = [];
    //this.content.resize();
    this.filteredList = [];
    this.visibleFilteredList = [];
    this.accountSearchText = '';
    // this.shouldFireSearchChangeEvent = false;
    this.searching = false;
    this.selectedTagData = null;
    this.cancelTagSelection();
    this.sortAccountsListBasedOnSelection(this.sortBy);
    this._initKeyControlsAreaModel()
    this.checkEnableDisableSelectAllOption();
    this.onSearchBlur(null);
  }
  async saveAdvancedSearch(failedText?: string){
    let saveSearchName: string
    let toolName;
    toolName = this.searchConfigService.toolNames.find(o=>o.toolName=='Accounts');
    let currentSavedSearches = this.searchConfigService.savedSearches.filter(o=> o.searchToolName==toolName.searchToolNameID);
    this.alertService.showAlert({ header:this.translate.instant('SAVE_SEARCH'),
                                  subHeader:failedText?failedText:'',
                                  cssClass:'saveSearchAlert',
                                  message:this.translate.instant('SAVE_SEARCH_POPOVER_MESSAGE'),
                                  inputs:[{type:'text', name:"name", placeholder:this.translate.instant('ENTER_NAME')}]
                                }, this.translate.instant('SAVE'), this.translate.instant('CANCEL'))
    .then(async (res)=>{
      if(res.role == 'ok'){
        if(res.data && res.data.values.name){
          saveSearchName = res.data.values.name.trim();
          let sameNameSearches = currentSavedSearches.filter(o=>o.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase())==0);
          let incrementNumber: number = 1;
          if(sameNameSearches.length){
            saveSearchName += ' (';
            _.each(sameNameSearches,(searchData=>{
              if(searchData.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase()) == 0){
                let currentIncrement = parseInt(searchData.searchName.toLowerCase().charAt(saveSearchName.length));
                if(!isNaN(currentIncrement) && currentIncrement>=incrementNumber) incrementNumber = currentIncrement+1;
              }
            }))
            saveSearchName+= incrementNumber+')';
          }
          this.disableSaveSearch = true;
          this._initKeyControlsAreaModel()
          await this.searchConfigDataService.saveAdvancedSearch(saveSearchName,
                                                                toolName?toolName.searchToolNameID:'',
                                                                UserSavedSearchTypes.OWNED,
                                                                this.selectedSuggestionsData.slice())
        }
        else if(res.data && res.data.values.name == ""){
          this.saveAdvancedSearch(this.translate.instant('NAME_IS_REQUIRED'));
        }
      }
    })
  }
  mapOpportunitiesToSearchIndex(){
    this.opportunityMgmtService.opportunities.forEach(opportunity=>{
      if(opportunity.accountID){
        let thisAccount = this.accountService.getAccountById(opportunity.accountID);
        if(thisAccount){
          if(!this.searchConfigService.accountConfiguredSearchIndexConfig.find(config=>config.categoryName=='Opportunities').values.some(o=> o==opportunity.opportunityName))
            this.searchConfigService.accountConfiguredSearchIndexConfig.find(config=>config.categoryName=='Opportunities').values.push(opportunity.opportunityName);
        }
      }
    })
  }
  // end advance search functions

  private async _createNewAccount(){
    if (this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) return;
    this.dynamicFormService.okLookUpMapper = [];
    this.events.unsubscribe("accounts:customForm");
    this.events.subscribe("accounts:customForm", this.accountCreateHandler);
    this.accountService.tempSelected = _.cloneDeep(this.accountService.selected);
    if (this.businessCreateChangeRequest && this.businessAccountCreateRequest) {
      this.notificationService.notify(this.translate.instant('INVALID_CONFIGURATION_FORMS_ERR'), 'ContactList', "top", ToastStyle.DANGER, 3000);
    } else if ((this.businessAccountCreateRequest || this.businessCreateChangeRequest) && this.accountsCreateRequest) {
      this.uiService.activeView = 'NewChangeRequestTypeSelect';
      this.uiService.showNewActivity = false;
      this.navService.setChildNavRightPaneView(true);
      await this.navService.pushChildNavPageWithPageTracking(NewChangeRequestComponent, PageName.NewChangeRequestComponent, PageName.AccountPageComponent, {
        from: 'Account'
      });
    } else if(this.businessAccountCreateRequest || this.businessCreateChangeRequest) {
      await this.defaultBusinessDynamicAccountForm();
    } else if(this.accountsCreateRequest) {
      await this.oneKeyDynamicAccountForm();
    } else {
      const event = {
        value : ChangeRequestType.BUSINESS,
        isMDMType: false,
      }
      this.events.publish("accounts:customForm", event);
    }

  }

  async defaultBusinessDynamicAccountForm() {
    if (this.businessCreateChangeRequest) {
      this._handleBusinessAccountChangeRequests();
    } else {
      let accountForm: DynamicForm = await this.getFormDefinition();
      let formType = DynamicFormType.CONFIGUREDFORM;
      if (!accountForm) {
        accountForm = new DynamicForm(defaultAccountCreateForm['value'][0]);
        formType = DynamicFormType.DEFAULTFORM;
      }
      this.accountService.selected = null;
      let formDetail: DynamicFormComponentModel = {
        title: this.translate.instant('ACCOUNT'),
        LookupEntitySetName: 'accounts',
        showLeftHeaderButton: true,
        leftHeaderBtnIcon: 'chevron-back-outline',
        showRightHeaderButton: true,
        rightHeaderImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        formMetadata: accountForm,
        formEntityObject: null,
        mainEntityObjectId: null,
        dynamicFormType: formType,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: DynamicFormComponentModel) => this._handleAccountFormComponentEvent(data, eventTarget, refData),
      };

      this.trackingService.tracking('CreateSelected', TrackingEventNames.ACCOUNT_CREATE, true);
      this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent, PageName.DynamicFormComponent, PageName.AccountPageComponent, {
        MODE: 'CREATE_MODE',
        FORM_DETAIL: formDetail,
      });
      this.footerService.initButtons(FooterViews.Accounts);
      this.navService.setChildNavRightPaneView(true);
      this.uiService.showNewActivity = false;
      this._initKeyControlsAreaModel();
    }
  }

  async _handleBusinessAccountChangeRequests(){
    let accountForm:DynamicForm = await this.dynamicFormService.getFormDefinitionForEntity('indskr_accountcr',FormType.CREATEFORM,BusinessProcessType.Omnipresence);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if(!accountForm){
      return;
    }
    this.mdmService.addLinkEntities([]);
    const accountRaw = {
      indskr_accountsourcetype: BusinessProcessType.Omnipresence,
      indskr_mdmrequesttype: MDMRequestType.CREATE
    }
    this.accountService.selected = null
    for(let i = 0; i < accountForm.metadata.length; i++) {
      const tab = accountForm.metadata[i];
      if(tab && tab.controls && tab.controls.length > 0) {
        for(let j = 0; j < tab.controls.length; j++) {
          let control = tab.controls[j];
          if(control.attributeName && (control.attributeName == "indskr_mdm" || control.attributeName == 'indskr_mdmid')) {
            control.forceHide = true;
          }
        }
      }
    }
    let formDetail:DynamicFormComponentModel = {
      title: this.translate.instant('ACCOUNT'),
      LookupEntitySetName: 'indskr_accountcrs',
      showLeftHeaderButton: true,
      leftHeaderBtnIcon: 'chevron-back-outline',
      showRightHeaderButton: true,
      rightHeaderImgSrc: 'assets/imgs/header_save.svg',
      rightHeaderBtnText: this.translate.instant('SAVE'),
      formMetadata: accountForm,
      formEntityObject: accountRaw,
      mainEntityObjectId: null,
      dynamicFormType: formType,
      navOptions: { animate: false },
      eventsHandler: (data:any, eventTarget:string, refData:DynamicFormComponentModel)=> this._handleDataCRFormComponentEvent(data,eventTarget,refData),
    };

    await this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent,PageName.DynamicFormComponent,PageName.AccountPageComponent,{
      MODE:'CREATE_MODE',
      FORM_DETAIL: formDetail,
      TYPE: "OMNIPRESENCE"
    });
    this.footerService.initButtons(FooterViews.Accounts);
    this.navService.setChildNavRightPaneView(true);
    this.uiService.showNewActivity = false;
    this._initKeyControlsAreaModel();

  }

  async oneKeyDynamicAccountForm() {
    let accountForm:DynamicForm = await this.dynamicFormService.getFormDefinitionForEntity('indskr_accountcr',FormType.CREATEFORM, BusinessProcessType.OneKey);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if(!accountForm){
      this.notificationService.notify(this.translate.instant('ONE_KEY_CREATE_FORM_ERROR'), 'DynamicFormComponent', "top", ToastStyle.DANGER, 3000);
      return;
    }
    this.mdmService.addLinkEntities([]);
    const accountRaw = {
      indskr_mdmid: "",
      indskr_name: "",
      indskr_accountsourcetype: 548910001,
      indskr_mdm: 548910000,
      indskr_mdmrequesttype: MDMRequestType.CREATE
    }
    this.accountService.selected = null
    let formDetail:DynamicFormComponentModel = {
      title: this.translate.instant('ACCOUNT'),
      LookupEntitySetName: 'indskr_accountcrs',
      showLeftHeaderButton: true,
      leftHeaderBtnIcon: 'chevron-back-outline',
      showRightHeaderButton: true,
      rightHeaderImgSrc: 'assets/imgs/header_save.svg',
      rightHeaderBtnText: this.translate.instant('SAVE'),
      formMetadata: accountForm,
      formEntityObject: accountRaw,
      mainEntityObjectId: null,
      dynamicFormType: formType,
      navOptions: { animate: false },
      eventsHandler: (data:any, eventTarget:string, refData:DynamicFormComponentModel)=> this._handleOneKeyAccountFormComponentEvent(data,eventTarget,refData),
    };

    await this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent,PageName.DynamicFormComponent,PageName.AccountPageComponent,{
      MODE:'CREATE_MODE',
      FORM_DETAIL: formDetail,
      TYPE: "ONE_KEY"
    });
    this.footerService.initButtons(FooterViews.Accounts);
    this.navService.setChildNavRightPaneView(true);
    this.uiService.showNewActivity = false;
    this._initKeyControlsAreaModel();

  }

  private async getFormDefinition(){
    let accountForm:DynamicForm = await this.dynamicFormService.getFormDefinitionForEntity('account',FormType.CREATEFORM);
    if(accountForm){
      return accountForm;
    }
  }

  private async _handleAccountFormComponentEvent(data,eventTarget,refData:DynamicFormComponentModel){
    if(eventTarget){
      if(eventTarget == 'DONEBUTTONCLICK'){
        if(data && data.formValue){
          this.trackingService.tracking('SaveSelected', TrackingEventNames.ACCOUNT_CREATE,true);
          this._handleAccountCreateAndUpdateOnline(data);
        }
      }else if(eventTarget == 'CANCELBUTTONCLICK'){
        this.trackingService.tracking('BackSelected', TrackingEventNames.ACCOUNT_CREATE,true);
        if(data && data.displayConfirmation){
          this._displayDiscardConfirmation();
        }else{
          this._closeAccountFormPage();
          if (data && data.formValue && data.formValue.accountid && this.accountService.selected && this.accountService.selected.id == data.formValue['accountid']) {
            let selectedAccount = this.accountService.getAccountById(data.formValue['accountid']);
            if (selectedAccount) {
              if(this.accountService.selected){
                selectedAccount.activitesByAccount = this.accountService.selected.activitesByAccount;
                selectedAccount.events = this.accountService.selected.events;
              }
              this.accountService.selected = selectedAccount;
              this.openAccountDetails(selectedAccount,false);
            }
          }
        }
      }else if(eventTarget == 'ADDEDLINKEDENTITY'){
        this.trackingService.tracking('DoneSelected', TrackingEventNames.ACCOUNT_EDIT,true);
        if(data && data.entity && data.values) {
          this.addLinkEntitiesForAccount(data);
          this._showDiscardConfirmationForAccountForm = data;
        }
        // if(data && data.formValue && data.formValue.accountid){
        //   let rawDetails = await this.accountDataService.getRealTimeAccountDetailsOnline(data.formValue['accountid']);
        //   if (rawDetails) {
        //     await this.accountService.addUpdateRawAccountDetails(rawDetails);
        //     let selectedAccount = this.accountService.getAccountById(data.formValue['accountid']);
        //     if(selectedAccount && this.accountService.selected && this.accountService.selected.id == data.formValue['accountid']){
        //       this.accountService.selected = selectedAccount;
        //       this.openAccountDetails(data,false, true);
        //     }
        //   }
        // }
      }else if(eventTarget == 'ISFORMDIRTY'){
        this._showDiscardConfirmationForAccountForm = data;
    }
    }
  }

  private async _handleDataCRFormComponentEvent(data,eventTarget,refData:DynamicFormComponentModel){
    if (eventTarget) {
      if (eventTarget == 'DONEBUTTONCLICK') {
        if (data && data.formValue) {
          await this.uiService.displayLoader();
          console.log(this.mdmService.getReqPayloadLinkEntities());
          let payload = data.formValue;
          payload["linkEntities"] = this.mdmService.getReqPayloadLinkEntities();
          await this.mdmService.createBusinessAccountDataCR(payload).then(async (res: any) => {
            this._initAllSectionHeaderModel();
            this._showDiscardConfirmationForAccountForm = false;
            if (data && data.eventsHandler) {
              data.eventsHandler(res, 'DISABLEFORM');
              // await this.mdmService.addAccountCrNotification(data, res, NOTIFICATION.BUSINESS_ACCOUNT_CHANGE_REQUEST_SUBMITTED, this.translate.instant('BUSINESS_ACCOUNT_CHANGE_REQUEST_SUBMITTED', { accountName: data.formValue['indskr_name'] }));
              // await this.myAssistantService.loadAndMapNotificationsFromDB();
              this.dynamicFormService.linkedEntityFormIsDirtyTracker = false;
              this.notificationService.notify(this.translate.instant('CR_SUBMIT_MESSAGE', { crType: 'account', lastName: data.formValue['indskr_name'] }), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
            }
            await this.uiService.dismissLoader();
          }).catch((err) => {
            this.uiService.dismissLoader();
            console.log("Error creating Account change request");
          });
        }
      } else if (eventTarget == 'CANCELBUTTONCLICK') {
        if (data && data.displayConfirmation) {
          this._displayDiscardConfirmation();
        } else {
          this._closeAccountFormPage();
        }
      } else if (eventTarget == 'ADDEDLINKEDENTITY') {
        if (data) {
          this.mdmService.addLinkEntities(data);
          this._showDiscardConfirmationForAccountForm = data;
          if (data && data.eventsHandler) {
            data.eventsHandler(data, 'ENABLEFORM');
          }
          this._showDiscardConfirmationForAccountForm = data;
        }
      } else if (eventTarget == 'ISFORMDIRTY') {
        this._showDiscardConfirmationForAccountForm = data;
      }
    }
  }

  private async _handleOneKeyAccountFormComponentEvent(data,eventTarget,refData:DynamicFormComponentModel){
    if (eventTarget) {
      if (eventTarget == 'DONEBUTTONCLICK') {
        if (data && data.formValue) {
          await this.uiService.displayLoader();
          console.log(this.mdmService.getReqPayloadLinkEntities());
          let payload = data.formValue;
          payload['indskr_mdmrequesttype']= MDMRequestType.CREATE;
          payload["linkEntities"] = this.mdmService.getReqPayloadLinkEntities();
          await this.mdmService.createOneKeyAccountCR(payload).then(async (res: any) => {
            this._initAllSectionHeaderModel();
            this._showDiscardConfirmationForAccountForm = false;
            if (data && data.eventsHandler) {
              data.eventsHandler(res, 'DISABLEFORM');
              this.dynamicFormService.linkedEntityFormIsDirtyTracker = false;
              this.notificationService.notify(this.translate.instant('CR_SUBMIT_MESSAGE', { crType: 'account', lastName: data.formValue['indskr_name'] }), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
            }
            await this.uiService.dismissLoader();
          }).catch((err) => {
            this.uiService.dismissLoader();
            console.log("Error creating Account change request");
          });
        }
      } else if (eventTarget == 'CANCELBUTTONCLICK') {
        if (data && data.displayConfirmation) {
          this._displayDiscardConfirmation();
        } else {
          this._closeAccountFormPage();
        }
      } else if (eventTarget == 'ADDEDLINKEDENTITY') {
        if (data) {
          this.mdmService.addLinkEntities(data);
          this._showDiscardConfirmationForAccountForm = data;
          if (data && data.eventsHandler) {
            data.eventsHandler(data, 'ENABLEFORM');
          }
        }
      } else if (eventTarget == 'ISFORMDIRTY') {
        this._showDiscardConfirmationForAccountForm = data;
      }
    }
  }

  private _updateAccountWithRealtimeDetails(id:string):Promise<any>{
    return new Promise(async (resolve,reject)=> {
      if(!this.authService.user.isCustomerProfileLaunchSyncEnabled){
        resolve('')
      }
      else if(!id || this.accountListAccessedFrom != 'AccountPage' || this.device.isOffline){
        reject();
      }else{
        let rawDetails = await this.accountDataService.getRealTimeAccountDetailsOnline(id,false,false);
        if (rawDetails) {
          let oldAccount = this._foundAccountInAllAccountsRecursively(id);
          await this.accountService.addUpdateRawAccountDetails(rawDetails);
          let newAccount = this.accountService.getAccountById(id);
          if(this.navService.getActiveChildNavViewPageName() == PageName.AccountDetailsComponent && this.accountService.selected && this.accountService.selected.id == newAccount.id){
            if(oldAccount && newAccount && (oldAccount.parentAccountID != newAccount.parentAccountID || oldAccount.accountName != newAccount.accountName)){
              this._updateAccountsListAfterUpdateOrCreate(newAccount);
              this.updateMasterListWithAccount(newAccount);
            }
            if(this.uiService.accountDataSegment == 'info'){
              resolve(newAccount);
            }else {
              reject();
            }
          }else{
            reject();
          }
        }else if(this.accountService.refreshUIofCurrentSelectedAccountFlag){
          let newAccount = this.accountService.getAccountById(id);
          resolve(newAccount)
        }else{
          reject();
        }
      }
    });
  }

  private async _handleAccountCreateAndUpdateOnline(data){
    let payload = data.formValue;
    if(!payload) return;
    this.uiService.displayLoader();
    if(this.linkEntitiesReqPayload && this.linkEntitiesReqPayload.length>0){
      const accountContactAffReqPayload = [];
      for (let reqPayload of this.linkEntitiesReqPayload) {
        await this.dynamicFormService.createLinkedEntity(reqPayload.values, reqPayload.entity);
        if (reqPayload.entity === 'indskr_accountcontactaffiliations' && (payload.indskr_accounttype == '100000001' || payload.indskr_accounttype == '100000004')) {
          accountContactAffReqPayload.push({
            accountId: payload.accountid,
            accountName: payload.name,
            contactId: reqPayload?.values['lookupfields']?.find(req => req['entity'] === 'contacts').id,
            indskr_accounttype: 100000001
          });
        }
      }
      this.eventService.publish("marketScan:newAccountContactAff", accountContactAffReqPayload);

      this.linkEntitiesReqPayload = [];
    }
    await this.accountDataService.createUpdateAccountOnline(payload).then(async res=>{
        try {
          if (res && res['accountid']) {
            let rawDetails = await this.accountDataService.getRealTimeAccountDetailsOnline(res['accountid']);
            if (rawDetails) {
              this.initAllAccountsHeaderModel();
              await this.accountService.addUpdateRawAccountDetails(rawDetails);
              let newAccount = this.accountService.getAccountById(res['accountid']);
              this._updateAccountsListAfterUpdateOrCreate(newAccount,true);
              this._showDiscardConfirmationForAccountForm = false;
              if (data && data.eventsHandler) {
                if (data.isFormSaved) {
                  this.openAccountDetails(newAccount,false, !this.accountService.refreshUIofCurrentSelectedAccountFlag);
                  data.eventsHandler(res, 'NEWDATAUPDATED');
                  this.notificationService.notify(this.translate.instant('CONTACT_UPDATE_CREATED'), 'Account-Form-Component');
                } else {
                  // this.openAccountDetails(newAccount,false, false);
                  data.eventsHandler(res, 'NEWDATACREATED');
                  this.accountService.selected = newAccount;
                  this.notificationService.notify(this.translate.instant('NEW_ACCOUNT_CREATED'), 'Account-Form-Component');
                }
              }
            }
          }
        } catch (error) {
            console.error(error);
        }
    }).catch(err=>{
        console.error(err);
    });
    this.uiService.dismissLoader();
  }

  private _updateAccountsListAfterUpdateOrCreate(updatedAccount: Account,updateNewAccountsList:boolean = false) {
    this.accountService.createAccountsTree(this.accountService.accounts);
    this.accountService.accountsTree = this.accountService.accountsTreeForOtherTool;
    this.allAccounts = this.accountService.accountsTreeForOtherTool;
    this.accounts = this.allAccounts.slice(0, this.recordCount);
    this.sortAccountsListBasedOnSelection(this.sortBy);
    if (updatedAccount) {
      this.accountService.selected = updatedAccount;
      if(updateNewAccountsList){
        let newIdx = this.newAccounts.findIndex(a => a.id == updatedAccount.id);
        if (newIdx >= 0) {
          this.newAccounts[newIdx] = updatedAccount;
        } else {
          this.newAccounts.push(updatedAccount);
        }
        this.newAccounts = this._sortAccounts(this.newAccounts);
      }
    }
  }

  private _displayDiscardConfirmation(){
    let cancelText = this.translate.instant("CANCEL");
    if(this.translate.currentLang == 'it') {
      cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
    }
    this.alertService.showAlert({
        header: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
        message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')}, this.translate.instant('DISCARD'), cancelText
    ).then (res => {
        if(res.role == "ok") {
          this.trackingService.tracking('DiscardSelected', TrackingEventNames.ACCOUNT_CREATE,true);
          this.dynamicFormService.okAddressMapper = {};
          this.dynamicFormService.okLookUpMapper = [];
          this._closeAccountFormPage();
        }else{
          this.trackingService.tracking('CancelSelected', TrackingEventNames.ACCOUNT_CREATE,true);
        }
    });
  }

  private async _closeAccountFormPage(){
    this._showDiscardConfirmationForAccountForm = false;
    this.dynamicFormService.linkedEntityFormIsDirtyTracker = false;
    this.dynamicFormService.okLookUpMapper = [];
    if(this.navService.getActiveChildNavViewPageName() == PageName.DynamicFormComponent){
      await this.navService.popChildNavPageWithPageTracking().then(success=>{
        if (this.enableCreateAccount && this.accountsCreateRequest && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_ONEKEY_SELECTION)) {
          this.navService.pushChildNavPageWithPageTracking(NewChangeRequestComponent, PageName.NewChangeRequestComponent, PageName.AccountPageComponent, {
            from: 'Account'
          });
        } else if(this.navService.getActiveChildNavViewPageName() == PageName.AccountDetailsComponent) {
          if (this.accountService.tempSelected)
          this.accountService.selected = this.accountService.tempSelected;
        }
        else if(this.navService.getActiveChildNavViewPageName() == PageName.NothingSelectedView){
            this.navService.setChildNavRightPaneView(false);
        }
        this._initKeyControlsAreaModel();
      });
    }
  }

  resetOneKeySearch() {
    this.onekeyApiService.oneKeyAccounts.length = 0;
    this.accountService.isOneKeySearching = false;
    this.accountService.selectedOKAccount = null;
    this.accountService.selectedOKAccounts.length = 0;
    this.searchedByOKID = false;
    this.onekeyApiService.okSearchResult.next([]);
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
  }

  private addLinkEntitiesForAccount(linkEntity) {
    if (_.isEmpty(linkEntity))
      this.linkEntitiesReqPayload = [];
    else {
      const linkEntityPayload = {
        "values": linkEntity["values"],
        "entity": linkEntity["entity"]
      }
      this.linkEntitiesReqPayload.push(linkEntityPayload);
    }
  }

  private async _highlightAccount(account: Account, isOpenedOnPreview: boolean = false) {
    if (account && account.parentAccountID) {
      const parentAccount: Account = this.accountService.getAccountById(account.parentAccountID);
      // When opened sub-account, code that expands all is applied because of the code complexity.
      // Scenario: Open sub-account and collapse it then open sub-account again from the affiliation account
      this.expandAll = true;
      this._cd.detectChanges();
      this._initKeyControlsAreaModel();
      if (parentAccount) account = parentAccount;
    }
    const accountItem = document.getElementById(account.id);
    const isNewAccount = this.newAccounts.some(n => n.id == account.id);
    if (this.accountService.isAffiliationEnabled) {
      await this.accountService.getAccountRelationshipById(account.id);
    }
    if (isNewAccount) {
      this.ionContent.scrollToTop();
    } else if (accountItem && !isOpenedOnPreview) {
      const index = this.accounts.findIndex(ac => ac.id === account.id);
      const maxIndex = this.accounts.length - 1;
      document.getElementById(this.allAccounts[index > this.accounts.length - 2 ? index : maxIndex < index + 2 ? index : index + 2].id).scrollIntoView(false);
    } else {
      const index = this.allAccounts.findIndex(ac => ac.id === account.id);
      if (index >= 0) {
        const lastIndex = index > this.allAccounts.length - 3 ? index : index + 3;
        this.accounts.push(...this.allAccounts.slice(this.recordCount, lastIndex));
        this.recordCount = this.accounts.length;
        setTimeout(() => {
          const accountItem = document.getElementById(this.allAccounts[lastIndex - 1].id);
          if (accountItem) {
            accountItem.scrollIntoView(false);
          }
        }, 500);
      }
    }
  }

  private _resetFlagOpenedAffiliatedData() {
    this.dynamicFormService.isOpenedAffiliatedContactOnAccount = false;
    this.dynamicFormService.isOpenedAffiliatedAccountOnAccount = false;
    this.dynamicFormService.isOpenedAffiliatedAccountOnContact = false;
    this.dynamicFormService.isOpenedAffiliatedContactOnContact = false;
    this.dynamicFormService.openedUnmappedData = false;
    this.accountService.isEnableGoToAccountsTool = false;
    this.contactService.isEnableGoToContactsTool = false;
    this.uiService.isForcedHideGoToToolBtn = false;
  }

  disableRemoveIcon(accountID): boolean {
    if (this.accountService.accessedAccountListFrom == PageName.MarketingPlanDetails) {
      return this.currentAccountPlanOpportunities.some(opportunity => opportunity.accountID === accountID);
    }
    if (this.accountService.accessedAccountListFrom == PageName.AccountPlanDetailsComponent || this.accountService.accessedAccountListFrom == PageName.NewAccountPlanComponent) return true;
    return false;
  }

  // checkOpportunityOrOrderExistsForQuotes(){
  //   const selectedQuote = this.quotesToolService.selectedQuotes$.getValue();
  //   return (!_.isEmpty(selectedQuote.opportunityId) || !_.isEmpty(selectedQuote.orderId))
  // }

  onTagCheckboxValueChange(event) {
    if (event && event.id) {
      let idx = this._selectedAccountsForTag.findIndex(a => a.id == event.id);
      if (event.value) {
        if (idx < 0) {
          this._selectedAccountsForTag.push(this.accountService.getAccountById(event.id));
        }
      } else {
        if (idx >= 0) {
          this._selectedAccountsForTag.splice(idx, 1);
        }
      }
    }
    this._initKeyControlsAreaModel();
  }

  // async addToTag() {
  //   //this.selectedExistingTag = true;
  //   this.tagListPopover = await this.popover
  //     .create({
  //       component: PopoverComponent,
  //       componentProps: {
  //         userTags: [..._.cloneDeep(this.userTagService.accountTags)],
  //         field: "user_tags"
  //       },
  //       cssClass: 'add_to_tag',
  //       event: event
  //     });
  //   this.tagListPopover.present();
  //   this.tagListPopover.onDidDismiss().then(async (tag: { data: UserTag }) => {
  //     if (tag.data) {
  //       if (tag.data.indskr_usertagid === "CREATE_NEW_TAG") {
  //         this.createNewTag();
  //       } else { // clicked on exiting tag name
  //         this.updateTagWithSelectedAccounts(tag.data);
  //       }
  //     }

  //     console.log("tag", tag);

  //   });
  // }

  async addToTag() {
    let filteredTagData: UserTag[] = !_.isEmpty(this.userTagService.accountTags) ? _.cloneDeep(this.userTagService.accountTags).filter(tag => tag.visibility == TagVisibility.PERSONAL) : [];
     let tagDataForModifiedTagFlag: UserTag[] = !_.isEmpty(this.userTagService.accountTags) ? _.cloneDeep(this.userTagService.accountTags).filter(tag => tag.allowUserToModifytag == true) : [];
    filteredTagData = _.concat(filteredTagData,tagDataForModifiedTagFlag);
    if (!_.isEmpty(filteredTagData)) {
      filteredTagData.sort((a, b): number => {
        if (a.indskr_name > b.indskr_name) return 1;
        if (a.indskr_name < b.indskr_name) return -1;
        return 0;
      });
    }
    
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('ACCOUNT_TAGS'),
      dividerTitle: this.translate.instant('ALL_ACCOUNT_TAGS'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      hideAllItemsList: false,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleAccountTagsComponentEvent(data, eventTarget),
      searchHandler: (text: string) => this._handleAccountTagsSearch(text, filteredTagData),
      data: filteredTagData.map(tag => {
        let isSelected = false;
        return {
          id: tag.indskr_usertagid,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: true,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: tag.indskr_name,
          showArrow: false,
          arrowType: '',
        };
      }),
    };

    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail }, PageName.MainToolTemplateComponent);
  }

  private _handleAccountTagsSearch(text: string, accountTags): string[] {
    let ids: Array<string> = [];
    if (text.length >= 1) {
      ids = accountTags && accountTags.filter(tag => {
        return tag.indskr_name.trim().toLowerCase().includes(text.trim().toLowerCase());
      }).map(tag => tag.indskr_usertagid);
    } else {
      ids = accountTags && accountTags.map(tag => tag.indskr_usertagid);
    }
    return ids;
  }

  private async _handleAccountTagsComponentEvent(data: any, eventTarget: string) {
    if (!eventTarget || eventTarget !== 'RightHeaderButtonClick' || !data.selectedItems || data.selectedItems.length === 0) return;
    const selectedTag = this.userTagService.accountTags.find((tag) => tag.indskr_usertagid === data.selectedItems[0].id);
    if (!selectedTag) return;
    this.updateTagWithSelectedAccounts(selectedTag);
  }

  createNewTag(failedText?: string) {
    this.alertService.showAlert({
      title: this.translate.instant("CREATE_TAG"),
      subTitle: failedText ? failedText : "",
      cssClass: 'saveSearchAlert',
      inputs: [{ type: "text", name: "name", placeholder: this.translate.instant("ENTER_TAG_NAME") }]
    }, this.translate.instant("CREATE"), this.translate.instant("CANCEL"))
      .then(res => {
        if (res.role == "ok") {
          if (res.data && res.data.values.name) {
            console.log("createNewTagAlert res", res, this._selectedAccountsForTag);
            const selectedAccounts = this._selectedAccountsForTag.map(account => {
              return new EntityTag(account.id);
            });
            let payload = new
              UserTag(null, null, false,
                res.data.values.name, selectedAccounts,
                false, TagStateCode.Active, TagEntityType.ACCOUNT, TagVisibility.PERSONAL);
            this.userTagService.createOrUpdateTag(payload, true).then((reponse) => {
              if (reponse.length) {
                payload.indskr_usertagid = reponse[0].indskr_usertagid;
                payload.indskr_externalid = reponse[0].indskr_externalid;
              }
              this.userTagService.accountTags.push(payload);
              this.userTagService.accountTags = this.userTagService.sortUserTags(this.userTagService.accountTags);
              this.cancelTagSelection();
              console.log("createNewTagAlert response", reponse);
            }).catch(error => {
              console.log("createNewTagAlert error ", error);
            });
          } else {
            this.createNewTag(this.translate.instant("TAG_NAME_IS_REQUIRED"));
          }
        }
      });
  }

  cancelTagSelection() {
    if (this.allAccounts?.length) {
      this.allAccounts.forEach((account) => {
        account.isSelectedForTag = false;
      })
    }
    this.isAccountTagSelectEnabled = false;
    this.showRemoveTag = false;
    this._selectedAccountsForTag.forEach((acc) => {
      acc.isSelectedForTag = false;
    })
    this._selectedAccountsForTag = [];
    this._initKeyControlsAreaModel();
  }

  updateTagWithSelectedAccounts(tagData: UserTag) {
    const selectedAccounts = this._selectedAccountsForTag.map(acc => {
      return new EntityTag(acc.id);
    });
    let differenceBySelectedAccounts = _.differenceBy(selectedAccounts, tagData.entityRecords, "id");
    tagData.entityRecords.push(...differenceBySelectedAccounts);
    this.userTagService.createOrUpdateTag(tagData).then((response) => {
      let foundTag = this.userTagService.accountTags.find(tag => tag.indskr_usertagid === tagData.indskr_usertagid || tag.indskr_externalid === tagData.indskr_externalid);
      if(foundTag){
        foundTag.entityRecords = tagData.entityRecords;
      }
      this.cancelTagSelection();
    }).catch((error) => {
      console.log("updateTagWithSelectedContacts error", error);
    })

  }

  public handleTagSelection(tagData: UserTag) {
    if (!tagData) return;
    // if ((this.accountService.isGlobalSearching || this.accountListMode == this.compViewMode.ADDNEW || this.accountListMode == this.compViewMode.SELECTION || this.accountService.accessedAccountListFrom != this.pagename.ActivitiesPageComponent)) {
    //   this.showRemoveTag = true;
    // }
    this.showRemoveTag = tagData.visibility == TagVisibility.PERSONAL;
    this.filteredList =[];
    this.suggestionsActive = false;
    this._selectedAccountsForTag = [];
    this.searching = true;
    if(this.selectedTagData){
      this.selectedTagData.entityRecords.forEach(record => {
        let accountIdx = this.filteredList.findIndex(a=> a.id == record.id);
        if (accountIdx >= 0) {
          this.filteredList[accountIdx].isSelectedForTag = false;
          this.filteredList.splice(accountIdx,1)
        }
      });
    }
    tagData.entityRecords.forEach(record => {
      let account = this.accountService.getAccountById(record.id);
      if (account && !(!account.status || account.status === 2 || account.status == 548910003 || account.status == 548910010 || account.status == 548910011 || account.status == 548910012 || account.status == 548910013)) {
        account.isSelectedForTag = false;
        if (!this.filteredList.some(acc => acc.id == record.id)) {
          this.filteredList.push(account);
        }
      }
    });
    const selectedPill: SelectedSuggestionPillDataModel = this.getTagSuggestionPill(tagData);
    //Only one tag can be filtered at a time
    this.selectedSuggestionsData = this.selectedSuggestionsData.filter(pill => pill.categoryName !== selectedPill.categoryName);
    this.selectedSuggestionsData.push(selectedPill);
    this.selectedTagData = _.cloneDeep(tagData);
    this._initKeyControlsAreaModel();
    this.initResultsAccountsheaderModel();
  }

  public handleListPeriodTagSelection(selectedListPeriodTag: string) {
    this.suggestionsActive = false;
    this.searching = true;
    this.selectedSuggestionsData = [];
    this.selectedSuggestionsData = [{
      selectedFacet: selectedListPeriodTag,
      categoryPath: 'listPeriodTag',
      categoryName: 'listPeriodTag',
      categoryDisplayName: 'listPeriodTag',
    }];
    if(this.selectedFilterData?.length > 0) {
      this.searchAccountsList(true);
    }else{
      this.filteredList = this.accountService.accounts.filter(account => account.tagLabels.includes(selectedListPeriodTag));
      this.visibleFilteredList = this.filteredList.slice(0, this.filteredRecordCount);
    }
    this.sortAccountsListBasedOnSelection(this.sortBy);
    this.updateEmptyMessage()
    this._initAllSectionHeaderModel();
  }

  private getTagSuggestionPill(tagData: UserTag): SelectedSuggestionPillDataModel {
    return {
      selectedFacet: tagData.indskr_name,
      categoryPath: 'Tag',
      categoryName: 'Tag',
      categoryDisplayName: 'Tag',
    };
  }

  removeAccountsFromTag(selectedTagData: UserTag) {
    if (selectedTagData) {
      if (selectedTagData.visibility != TagVisibility.PERSONAL) return;
      this._selectedAccountsForTag.forEach(account => {
        let record = selectedTagData.entityRecords.find(selectedTagDataContact => selectedTagDataContact.id === account.id);
        if (record) {
          record.deleted = account.isSelectedForTag;
        }
      });

      this.userTagService.createOrUpdateTag(selectedTagData).then((response) => {
        if (this._selectedAccountsForTag.length) {
          selectedTagData.entityRecords = selectedTagData.entityRecords.filter(record => !record.deleted);
        }
  
        let tag = this.userTagService.accountTags.find(tag => tag.indskr_usertagid === selectedTagData.indskr_usertagid);
        if (tag) {
          tag.entityRecords = selectedTagData.entityRecords;
        }
        this.cancelTagSelection();
        this.removeAllSelectedSuggestions();
        this.handleTagSelection(selectedTagData);
      }).catch((error) => {
        console.log(" removeFromTag error", error);
      })
    }
  }

  removeSelectedFilter(suggestion, filter){
    let selectedSuggestions = this.filterNameBubble.find((bubble) => bubble == filter).suggestion;
    _.remove(this.selectedFilterData,(o)=>_.isEqual(o,suggestion));
    _.remove(this.filterNameBubble.find((bubble) => bubble == filter).suggestion,(o)=>_.isEqual(o,suggestion));
    if(_.isEmpty(selectedSuggestions)) {
      let index = this.filterNameBubble.findIndex((b) => b.categoryName == filter.categoryName);
      if(index>-1) this.filterNameBubble.splice(index,1);
    }
    if(this.currentTab == AccountsModel.CHANGE_REQUESTS_TAB) {
      if(this.selectedFilterData.length == 0){
        this.filtering = false;
        this.filteredAccountCRs = this.accountCRs;
        this.isFilteredAccountCRs = false;
        this._initAllSectionHeaderModel();
      } else {
        let selectedValue:{ status: any[], requestType: any[], resourceType: any[]} = {status:[], requestType:[], resourceType:[]};
        this.selectedFilterData.forEach((selected) => {
          if(selected.categoryPath == 'accountCR_status') selectedValue['status'].push(selected.value);
          else if(selected.categoryPath == 'accountCR_request_type') selectedValue['requestType'].push(selected.value);
          else if(selected.categoryPath == 'accountCR_source_type') selectedValue['resourceType'].push(selected.value);
        });
        this.filterAccountCR(selectedValue);
      }
    } else {
      if(this.selectedFilterData.length == 0 && this.selectedSuggestionsData?.length == 0) {
         this.accounts = [];
         this.filteredList = [];
         this.visibleFilteredList = []
         this.accountSearchText = '';
        //  this.shouldFireSearchChangeEvent = false;
         this.searching = false;
         this.filtering = false;
         if(this.accountService.isGlobalSearching){
           this.accountService.resetGlobalSearch();
           this.accountService.globalSearchResults = [];
           this.initAccountsHeaderLeft();
         }
         if (this.accountService.isOneKeySearching) {
           this.resetOneKeySearch();
         }
         this.cancelTagSelection();
         this.sortAccountsListBasedOnSelection(this.sortBy);
         this.initAccountsHeaderLeft();
         this.checkEnableDisableSelectAllOption();
      }
      else if(this.selectedFilterData.length==0) {
        this.filtering = false;
        this.searchAccountsList();
      }else {
        //Update sub categories - Assessment
        if (filter && (filter.categoryName == 'Assessments' || filter.categoryName == this.translate.instant('ASSESSMENTS')) && suggestion && suggestion.categoryPath == 'Assessed') {
          const isRemovedAssessCategory: boolean = !this.selectedFilterData.some(d=>d.categoryPath == 'Assessed');
          if (isRemovedAssessCategory && !_.isEmpty(this.selectedFilterData)) {
            this.selectedFilterData.forEach(d => {
              if (d.configuredFrom == 'Assess') {
                const targetCategoryPath = d.categoryPath;
                this._clearSubCategory(targetCategoryPath);
              }
            });
          }
        }
        this.searchAccountsList(true);
      }
    }
  }

  private _clearSubCategory(targetCategoryPath: string) {
    const idx = this.filterNameBubble.findIndex(bubble=>bubble.suggestion.some(category=>category.categoryPath == targetCategoryPath));
    if(idx > -1) this.filterNameBubble.splice(idx, 1);
    if(!_.isEmpty(this.selectedFilterData)) {
      this.selectedFilterData = this.selectedFilterData.filter(d=>d.categoryPath != targetCategoryPath);
    }
  }

  getFilterButtonBadgeCount() {
    return this.selectedFilterData?.length;
  }

  getViewModeForAccountItem(account:Account):ComponentViewMode{
    if(
      !(
        this.accountVisitRecordCheckResponse?.isAccountVisitRecord
        || this.accountVisitRecordCheckResponse?.isAccountVisitNestedMeeting
      )
      && this.allowAffiliatedHCOSelectionOnly
    ){
      if(this.affiliatedAccounts.length > 0 && this.affiliatedAccounts.some(a=> a.id == account.id)){
        return this.accountListMode as ComponentViewMode;
      }else{
        return ComponentViewMode.READONLY;
      }
    }
    return this.accountListMode as ComponentViewMode;
  }

  public selectLinkedEntity(selectedLinkedEntity) {
    if(selectedLinkedEntity.isDisableChkBox || this.device.isOffline) return;
    selectedLinkedEntity.isChecked = !selectedLinkedEntity.isChecked;
    if(selectedLinkedEntity.isChecked) {
      this.accountService.contactIdsToAdd.push(selectedLinkedEntity.selId);
    } else {
      if(!_.isEmpty(this.accountService.contactIdsToAdd)) {
        const idx = this.accountService.contactIdsToAdd.findIndex(aId => aId == selectedLinkedEntity.selId);
        if(idx > -1) this.accountService.contactIdsToAdd.splice(idx,1);
      }
    }
  }

  private async _updateSelectableLinkedEntity(accountId: string) {
    this.accountService.contactIdsToAdd = [];
    this.selectablLinkedEntityData = [];
    const dbKey: string = 'indskr_accountcontactaffiliation';
    const referenceEntity: string = 'accountid';
    this.selectablLinkedEntityData = await this.accountDataService.getSelectableLinkedEntity(dbKey, referenceEntity, accountId);
    if(!_.isEmpty(this.selectablLinkedEntityData)) {
      const isReasonFieldEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_MAPPING_APPROVAL);
      this.selectablLinkedEntityData.forEach(le=> {
        if(isReasonFieldEnabled) {
          le.isDisableChkBox = !_.isEmpty(le.approvalStatus) ? true : !this.contactService.validateAddContactToUserPosition(le.selId);
        }else {
          le.isDisableChkBox = !this.contactService.validateAddContactToUserPosition(le.selId);
        }
        if(this.isAddAffiliatedContactsOnAccountAddEnabled && le.isPrimary && !le.isDisableChkBox) {
          le.isChecked = true;
          //default selected primary account
          this.accountService.contactIdsToAdd.push(le.selId);
        }
      });
      //sort the affiliated contact: 1st line - primary contact
      let tempLinkedEntityData = this.selectablLinkedEntityData;
      let filteredByPrimaryId = tempLinkedEntityData.filter(le=>(le.isPrimary)).sort((a,b)=>(a.selName.toLowerCase() > b.selName.toLowerCase()) ? 1 : -1);
      let sortAndfilteredByNonPrimaryId = tempLinkedEntityData.filter(le=>(!le.isPrimary)).sort((a,b)=>(a.selName.toLowerCase() > b.selName.toLowerCase()) ? 1 : -1);
      this.selectablLinkedEntityData = filteredByPrimaryId.concat(sortAndfilteredByNonPrimaryId);
    }
  }
  //Map the selected affiliated contacts to user position
  private async _mapContactToUserPosition(customerPositionPayload, isReasonFieldEnabled: boolean) {
    const contactIdList = this.accountService.contactIdsToAdd;
    let payload = {};
    if(isReasonFieldEnabled && customerPositionPayload){
      payload = {
        'indskr_reason': customerPositionPayload?.indskr_reason,
        'indskr_skipapproval': customerPositionPayload?.indskr_skipapproval,
      }
    }
    for(const contactId of contactIdList) {
      payload['contactid'] = contactId;
      let mapSuccess: boolean;
      try {
        mapSuccess = await this.contactDataService.postContactToPositionByContactId(contactId, payload);
      } catch (error) {
        if (error?.error?.errorMessage?.includes('Duplicate Detected')) {
          const errorMessage: string = this.getErrorMessageMappingPosition("CONTACT", error.error.errorMessage);
          if (errorMessage) {
            this.notificationService.notify(
              errorMessage,
              'ContactList',
              'top',
              ToastStyle.DANGER,
              5000,
            );
          }
        }
      } finally {
        if(mapSuccess) {
          console.log("updated new contact - selected affiliated contacts from account via global search");
        }
      }
    }
  }

  async mapChildUsersWithPositionToAccountsSearchConfig(){
    if (this.device.isOffline) return;

    if(_.isEmpty(this.searchConfigDataService.childUsersWithPositions)) {
      this.searchConfigDataService.childUsersWithPositions = await this.contactDataService.getAllChildUsersBasedOnPositionId(this.authenticationOfflineService.user.positionID);
    }

    if(!_.isEmpty(this.searchConfigDataService.childUsersWithPositions)) {
      this.searchConfigDataService.childUsersWithPositions.forEach(childUser=>{
        if(childUser.userfullname && !this.searchConfigService.accountConfiguredSearchIndexConfig.find(config=> config.categoryName == 'Teamusers').mappingValues.some(o=>o.formattedValue == childUser.userfullname)){
          this.searchConfigService.accountConfiguredSearchIndexConfig.find(config=> config.categoryName == 'Teamusers').mappingValues.push({
           actualValue: childUser.childpositionid.toString(),
           formattedValue: childUser.userfullname.toString()
          });
        }
      });

      this.searchConfigDataService.childUsersWithPositions = this.searchConfigDataService.childUsersWithPositions.map(childUser=>{
        childUser = _.pick(childUser,['childpositionid','userfullname']);

        childUser.id = `filter_menu_Teamusers_${childUser['userfullname']}`,
        childUser.label = `${childUser['userfullname']}`,
        childUser.value = `${childUser['childpositionid']}`,
        childUser.isChecked = false;
        childUser.category = 'Teamusers';
        childUser.isExpanded = false;
        childUser.wasCalled = false;
        childUser.childUsers = [];
        childUser.depth = 0;
        return childUser;
      });
    }
  }
  deleteTag(userTagDetails){
    console.log(userTagDetails);
    this.alertService.showAlert({
      title: this.translate.instant('DELETE_TAG'),
      cssClass: 'saveSearchAlert',
      message: this.translate.instant('TAG_DELETE_CONFIRMATION'),
    }, this.translate.instant('DELETE'), this.translate.instant('CANCEL'))
      .then(async (res) => {
        if (res.role == 'ok') {
           if(userTagDetails.entity && userTagDetails.entity == TagEntityType.ACCOUNT){
            let deleteTagPayload = new UserTag(userTagDetails.indskr_externalid, userTagDetails.indskr_usertagid, true, userTagDetails.indskr_name, [],true,TagStateCode.InActive,TagEntityType.ACCOUNT,'');
            this.userTagService.deleteTag(deleteTagPayload);
          } 
        }
      });
  }

  mapForUserTag(selectedData){
    let tagIds=[];
    let copySelectedData : SelectedSuggestionPillDataModel[] = [];
    copySelectedData= selectedData.filter(x=>x.categoryName!="Tag");
    let tagData=selectedData.filter(x=>x.categoryName=="Tag" || x.categoryName == this.translate.instant("Tag"))
    tagData.forEach(c=> {
      const foundTag = this.userTagService.accountTags.find(v=>v.indskr_name==c.selectedFacet)?.entityRecords;
      if(foundTag) tagIds.push(foundTag)
    })
    let accountIds=tagIds.flat().map(c=>c.id)
    accountIds = [...new Set(accountIds)];
    if(accountIds.length<=0){
      return selectedData;
    }
    accountIds.forEach(x=>{
     let  data : SelectedSuggestionPillDataModel = {
       selectedFacet: x,
       categoryPath: "accountid",
       categoryName: "Tag",
       categoryDisplayName: "Tag"
     } ;
     data.isSubCategory = false
     copySelectedData.push(data);
    })
    return copySelectedData;
  }
  onSearchBlur(event){
    if(this.accountSearchText && this.accountSearchText.length>0){
      this.hideFilterButton = true;
    }
    else{
      this.hideFilterButton = false;
    }
  }
  onDefaultFilterLoad(){
    let toolName = this.searchConfigService.toolNames.find(o=>o.toolName=='Accounts');
    let search = this.searchConfigService.savedSearches.filter(x=>x.searchToolName == toolName.searchToolNameID ).find(x=>x.indskr_setfilter == true);         
    if(search){
      this.selectedFilterData = [];
      this.filterNameBubble = [];
      this.filtering = true;
      this.selectedFilterData = search.categoryValuePairs;
      this.selectedFilterData.forEach((data) => this._updateFilterNameBubble(data));
      this.filterNameBubble.map(c=>{
        c['isSavedFilter'] = true;
        c['savedFilterName'] = search.searchName;
      });
      this.searchAccountsList();
    }
  }

  public onClickEnter(ev){
    if(ev){
      this.suggestionsActive = false;
    }
  }
  
  private getErrorMessageMappingPosition(targetEntity: string, errorMessage: any): string {
    let errorMessageStr: string = this.translate.instant(`${targetEntity}_ALREADY_SUBMITTED_FOR_APPROVAL`);
    if (errorMessage.includes('inactive')) {
      errorMessageStr = this.translate.instant(`${targetEntity}_REQUEST_INACTIVE_FOR_APPROVAL`);
    } else if (errorMessage.includes('For Review')) {
      errorMessageStr = this.translate.instant(`${targetEntity}_ALREADY_SUBMITTED_FOR_APPROVAL`);
    } else if (errorMessage.includes('Draft')) {
      errorMessageStr = this.translate.instant(`${targetEntity}_REJECTED_FOR_APPROVAL`);
    }
    return errorMessageStr;
  }

  private async autoSelectAccountAffFilter(accounts: Account[]) {
    if (!_.isEmpty(accounts)) {
      const selectedItems = [];
      const config = this.searchConfigService.accountConfiguredSearchIndexConfig.find(config => config.categoryRelativePath === 'indskr_accountaccountaffiliation.indskr_affiliatedfromaccountid');
      if (config) {
        accounts.forEach(account => {
          selectedItems.push({
            "selectedFacet": account.accountName,
            "charSearchText": "",
            "categoryPath": config.categoryRelativePath,
            "categoryName": config.categoryName,
            "categoryDisplayName": config.categoryDisplayName,
            "type": 548910000,
            "value": "",
            "createdOn": new Date().getTime(),
            "isAggregatedSection": false,
            "subCategories": [],
            "isSubCategory": false
          })
        })
        let obj = {
          "data": {
            "selectedItems": selectedItems,
            "isDone": true
          }
        };
        await this.uiService.displayLoader();
        await this.onMyAccountsFilterDismiss(obj);
        await this.uiService.dismissLoader();
      }
    }
  }

  private async onMyAccountsFilterDismiss(obj: any) {
    if (obj && obj.data && obj.data.isDone) {
      let data = obj.data;
      if (_.isEmpty(data.selectedItems)) {
        this.clearFilters();
        this.filtering = false;
      } else {
        if (this.device.isOffline && data.selectedItems.some(item => item.configuredFrom == "Assess")) {
          this.notificationService.notify(this.translate.instant('ASSESSMENT_BASED_FILTER_ONLINE_ONLY'),  "Contact list", "top", ToastStyle.INFO, 3000);
          return;
        }
        this.selectedFilterData = [];
        this.filterNameBubble = [];
        this.filtering = true;
      
        data.selectedItems.forEach((selected) => {
          if (selected.selectedFacet == this.translate.instant('ASSESSED'))
            this.selectedFilterData.push(this.getAssessmentPill());
          else if (selected.selectedFacet == this.translate.instant('UNASSESSED'))
            this.selectedFilterData.push(this.getUnAssessmentPill());

          else {
            if (!this.selectedFilterData.some(o => o.categoryName == selected.categoryName && o.selectedFacet == selected.selectedFacet))
              this.selectedFilterData.unshift(selected);
          }
        });
        this.selectedFilterData.forEach((data) => {
          this._updateFilterNameBubble(data);
        });
        this.suggestionsActive = false;
        await this.searchAccountsList(true);
        this.selectedFilterData.sort((a, b) => {
          if (a.createdOn < b.createdOn)
            return 1;
          else
            return -1;
        });
      }
    }
  }
  public handleStakeholderValueInput(event){
    this.isDirty =true;
    this.initAccountsHeaderLeft();
  }

  updateConnectionRoleToSelectedAccount(event, selectedAccount: Account, connectionRole: any){
    if (event && event.stopPropagation) event.stopPropagation();
    const contactRole = selectedAccount.stakeholderRolesList.find(role => role.roleid === connectionRole.connectionroleid);
    if(!contactRole){
      selectedAccount.stakeholderRolesList.push({roleid:connectionRole.connectionroleid,name: connectionRole.name, isSelected: true});
    }else{
      selectedAccount.stakeholderRolesList = selectedAccount.stakeholderRolesList.filter(role => role.roleid !== connectionRole.connectionroleid);
    }
    this.isDirty = true;
    this.initAccountsHeaderLeft();
  }

  public slectedAccountConnectionRole (selectedAccount: Account, connectionRole: any): any{
    return selectedAccount.stakeholderRolesList?.find(role => role.roleid === connectionRole.connectionroleid);
  }


}