<ion-header>
  <ion-toolbar>
    <label>{{primaryHeader}}<span *ngIf="secondaryHeader" class="secondary-header">{{secondaryHeader}}</span></label>
  </ion-toolbar>
</ion-header>
<ion-content #content>
  <div #contentWrapper class="wrapper">
    <ion-radio-group [allowEmptySelection]="allowEmptySelection" [value]="selectedValue" (ionChange)="onCheckboxClick($event)">
      <ion-item lines="none" *ngFor="let data of listData">
        <ion-label [ngClass]="{'disabled-text': data.isDisabled}">{{data.title}}</ion-label>
        <ion-radio [disabled]="data.isDisabled" [value]="data.id" justify="space-between"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </div>
</ion-content>
<div class="action-bar">
  <ion-button slot="end" (click)="closeModal(true)" [disabled]="!this.selectedData || !isDirty">
    <ion-icon class="submit-icon" src="assets/imgs/white-select-icon.svg" alt="Confirm"></ion-icon>
    {{'SELECT' | translate}}
  </ion-button>
  <ion-button class="cncl-btn" (click)="closeModal(false)" fill="clear" slot="end">
    <ion-icon class="submit-icon" src="assets/imgs/blue-close-icon.svg" alt="Cancel"></ion-icon>
    {{'CLOSE' | translate}}
  </ion-button>
</div>
