import { Injectable } from "@angular/core";
import { Device } from "@awesome-cordova-plugins/device/ngx";
import { Diagnostic } from "@awesome-cordova-plugins/diagnostic/ngx";
import { Geolocation } from "@awesome-cordova-plugins/geolocation/ngx";
import { NativeGeocoder, NativeGeocoderOptions, NativeGeocoderResult } from "@awesome-cordova-plugins/native-geocoder/ngx";
import { AlertController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { DeviceService } from "../device/device.service";
import { DiskService } from '../disk/disk.service';
import { GlobalErrorHandler } from '../error-handler/error-handler-service';
import { UIService } from "../ui/ui.service";

export enum GPS_STATUS {
  VALID = 600000000,
  INVALID = 600000001
}
declare var cordova:any;
export type LocationSettingTriggerPoint = 'home' | 'authService' | 'accountList' | 'account-meeting-distance' | null;
@Injectable({
  providedIn: 'root'
})
export class LocationOfflineService {
  public locations: Array<string>;
  public currentLocation: { latitude: Number, longitude: Number };
  private country;
  public isLocationEnabledOnDevice = false;
  private diagnosticPlugin;
  private locationAccuracyPlugin;
  public leftToLocationSettingFrom: LocationSettingTriggerPoint = null;
  public isFetchingLocationAlready = false;

  constructor(
    private disk: DiskService,
    private geolocation: Geolocation,
    public globalErrorHandler: GlobalErrorHandler,
    private nativeGeocoder: NativeGeocoder,
    private device: Device,
    private diagnostic: Diagnostic,
    private deviceService: DeviceService,
    public alertController: AlertController,
    public translate: TranslateService,
    public uiService: UIService,
    public platform: Platform,
  ) {
    this.locations = [];

    this.init();
  }

  async init() {
    await this.platform.ready();
    this.diagnosticPlugin = cordova.plugins.diagnostic ?? undefined;
    this.locationAccuracyPlugin = cordova.plugins.locationAccuracy ?? undefined;
  }

  /**
   * Maps and saves our location to in memory and disk
   * 
   * @param {object} response 
   * @memberof LocationOfflineService
   */
  public mapLocations(response: object) {
    if (response && Array.isArray(response)) {
      this.locations = [];
      response.map(location => {
        this.locations.push(location);
      });

      this.disk.updateOrInsert('locations', doc => ({ raw: response }))
        .catch(error => this.globalErrorHandler.handleError(new Error(error)));
    }
  }

  public get getLocationData(): Array<string> {
    return this.locations;
  }

  private async getLocationAuthorizationStatusesForAndroid() {
    return new Promise((resolve, reject) => {
      this.diagnosticPlugin?.getLocationAuthorizationStatuses(resolve, reject);
    });
  }
  private async requestPreciseLocationAuthorizationForAndroid() {
    return new Promise((resolve, reject) => this.diagnosticPlugin?.requestLocationAuthorization(
      resolve,
      reject,
      undefined,
      this.diagnosticPlugin.locationAccuracyAuthorization.FULL,
    ));
  }
  async isLocationEnabled(): Promise<boolean> {
    return new Promise((resolve, reject) => this.diagnosticPlugin?.isLocationEnabled(
      resolve,
      reject,
    ));
  }
  private async iOSLocationEnableRequest() {
    return new Promise((resolve, reject) => this.locationAccuracyPlugin?.request(
      resolve,
      reject,
    ));
  }

  private async hasPreciseLocationPermissionAndroid() {
    const statuses = await this.getLocationAuthorizationStatusesForAndroid();
    const fineLocationPermission = statuses[this.diagnostic.permission.ACCESS_FINE_LOCATION];
    return fineLocationPermission === this.diagnostic.permissionStatus.GRANTED || fineLocationPermission === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE
      ? true : false;
  }

  private async checkPermissions(calledFrom?: LocationSettingTriggerPoint) {
    try {
      const isAndroid = this.diagnostic && this.diagnosticPlugin !== undefined && this.deviceService.isAndroid();
      const isIOS = this.diagnostic && this.diagnosticPlugin !== undefined && this.deviceService.isIOS();

      const isEnabled = await this.isLocationEnabled();

      if (isAndroid) {
        try {
          if (!isEnabled) {
            // Device location is off
            await this.uiService.dismissLoader();
            if (this.leftToLocationSettingFrom === null && calledFrom) {
              // Redirect user to the location settings
              // Location settings redirection does not have a callback
              // Set a flag to trigger a retry when user comes back from the location settings
              this.leftToLocationSettingFrom = calledFrom;
              await this.showEnableGpsAlert();
              this.diagnostic.switchToLocationSettings();
            } else if (this.leftToLocationSettingFrom) {
              // It was retry. Halt
              this.leftToLocationSettingFrom = null;
              this.isFetchingLocationAlready = false;
            }
            return false;
          }

          // Check and request app location permission
          // Open a permission request modal
          // It won't open the modal if already granted
          let priorStatuses = await this.getLocationAuthorizationStatusesForAndroid();
          let priorFineLocationPermission = priorStatuses[this.diagnostic.permission.ACCESS_FINE_LOCATION];

          await this.requestPreciseLocationAuthorizationForAndroid();

          // Confirm if the necessary permission is given.
          const hasPermission = await this.hasPreciseLocationPermissionAndroid();
          let statuses = await this.getLocationAuthorizationStatusesForAndroid();
          let coarseLocationPermission = statuses[this.diagnostic.permission.ACCESS_COARSE_LOCATION];
          let fineLocationPermission = statuses[this.diagnostic.permission.ACCESS_FINE_LOCATION];
          if (
            // Has permission, halt
            hasPermission
            // Or it's a retry after coming back from settings. Silently halt this time.
            || (!hasPermission && this.leftToLocationSettingFrom !== null)
            // Or has coarse permission && just got denied again
            || (
              (
                coarseLocationPermission === this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE
                || coarseLocationPermission === this.diagnostic.permissionStatus.GRANTED
              )
              && (
                priorFineLocationPermission !== this.diagnostic.permissionStatus.DENIED_ALWAYS
              )
            )
          ) {
            // Granted the necessary permission.
            this.leftToLocationSettingFrom = null;
            this.isFetchingLocationAlready = false;
            return hasPermission;
          }

          // Permission state at this point: device location is on but precise permission not given.
          // Either request the precise location permission one more time or redirect user to the settings if denied always flag is set.
          statuses = await this.getLocationAuthorizationStatusesForAndroid();
          fineLocationPermission = statuses[this.diagnostic.permission.ACCESS_FINE_LOCATION];

          if (fineLocationPermission === this.diagnostic.permissionStatus.DENIED_ALWAYS) {
            // DENIED_ALWAYS means user needs to be directed to the settings page to manually change the setting.
            // TODO: Add a dialog alerting that the function won't work without precision turned on and explain the user how to change the setting
            // Dismiss a loader if there was one turned on in previous steps.
            this.uiService.dismissLoader();
            const data = await this.askPermissionForLocation();
            if (data.role === 'confirm') {
              if (this.leftToLocationSettingFrom === null && calledFrom) {
                this.leftToLocationSettingFrom = calledFrom;
              }
              this.diagnostic.switchToLocationSettings();
            } else {
              this.leftToLocationSettingFrom = null;
            }
          } else {
            // Open permission request modal
            await this.requestPreciseLocationAuthorizationForAndroid();

            // Confirm if the necessary permission is given
            const hasPrecisePermission = await this.hasPreciseLocationPermissionAndroid();

            this.leftToLocationSettingFrom = null;
            this.isFetchingLocationAlready = false;
            return hasPrecisePermission;
          }
        } catch (error) {
          console.error('checkPermissions: android: ', error);
        }
      } else if (isIOS) {
        try {
          if (!isEnabled) {
            await this.uiService.dismissLoader();
            if (this.leftToLocationSettingFrom === null && calledFrom) {
              this.leftToLocationSettingFrom = calledFrom;
              await this.showEnableGpsAlert();
              this.iOSLocationEnableRequest();
            } else if (this.leftToLocationSettingFrom) {
              this.leftToLocationSettingFrom = null;
              this.isFetchingLocationAlready = false;
            }
            return false;
          }

          const accuracy = await this.diagnostic.getLocationAccuracyAuthorization();
          if (!accuracy || accuracy === this.diagnosticPlugin.locationAccuracyAuthorization.REDUCED) {
            // Dismiss a loader if there was one turned on in previous steps.
            this.uiService.dismissLoader();
            const data = await this.askPermissionForLocation()
            if (data.role === 'confirm') {
              if (this.leftToLocationSettingFrom === null && calledFrom) {
                this.leftToLocationSettingFrom = calledFrom;
              }
              this.diagnostic.switchToSettings();
            } else {
              this.isFetchingLocationAlready = false;
            }
            return false;
          }

          this.leftToLocationSettingFrom = null;
          this.isFetchingLocationAlready = false;
          return true;
        } catch (error) {
          console.error('checkPermissions: iOS: ', error);
        }
      }
    } catch (error) {
      console.error('location.service: checkPermissions: ', error);
    }
    this.isFetchingLocationAlready = false;
    return false;
  }

  // in china baidu map uses bdu09 standard while gaiode and tencent uses gcj standard
  // most of them uses baidu and sainofi asked for baidu so we convert wgs84(world standarad) to bdu09
  public async getCurrentLocation(calledFrom?: LocationSettingTriggerPoint) {
    if (this.deviceService.isNativeApp) {
      const checkPermission = await this.checkPermissions(calledFrom);
      if (!checkPermission) {
        this.isLocationEnabledOnDevice = false;
        return { latitude: 0, longitude: 0 };
      } else {
        this.isLocationEnabledOnDevice = true;
      }
    }

   
    return this.geolocation.getCurrentPosition({ enableHighAccuracy: true, timeout: 5000 }).then(async (resp) => {
      if (resp && resp.coords) {
        this.country = await this.getCountryFromCoords(resp.coords.latitude, resp.coords.longitude)
        if (this.country !== 'CN') {
          this.currentLocation = { latitude: resp.coords.latitude, longitude: resp.coords.longitude };
        } else {
          this.currentLocation = this.wgs84bd09ll(resp.coords.longitude, resp.coords.latitude);
        }
        return this.currentLocation;
      }
    }).catch((error) => {
      console.log('Error getting location', error);
      if (error.code === 1) {
        const platform = this.device.platform;
        this.uiService.dismissLoader();
        this.diagnostic.isLocationAvailable().then((locationPermissionGranted) => {
          if (!locationPermissionGranted) {
            this.askPermissionForLocation().then((data) => {
              if (data.role !== 'confirm') return;
              if (platform === 'Android') {
                this.diagnostic.switchToLocationSettings();
              } else {
                this.diagnostic.switchToSettings();
              }
            }).catch(err => console.log(err));
          }
        });
      }
      return { latitude: 0, longitude: 0 }
    });
  }

  public async getCurrentLocationName(calledFrom?: LocationSettingTriggerPoint) {
    try {
      const currentCoordinates: any = await this.getCurrentLocation(calledFrom);
      if (currentCoordinates.latitude <= 0) {
        return { latitude: 0, longitude: 0, name: this.translate.instant('LOCATION_SERVICE_UNAVAILABLE') };
      } else {
        //street address, City
        const location = await this.getLocationFromCoords(currentCoordinates.latitude, currentCoordinates.longitude);
        return { latitude: currentCoordinates.latitude, longitude: currentCoordinates.longitude, name: location ? location : this.translate.instant('LOCATION_SERVICE_UNAVAILABLE') };
      }
    } catch (err) {
      console.error("failed to fetch current location", err);
      return { latitude: 0, longitude: 0, name: this.translate.instant('LOCATION_SERVICE_UNAVAILABLE') };
    }
  }

  // reffered from https://github.com/googollee/eviltransform/blob/master/javascript/transform.js

  transform(x, y) {
    const xy = x * y;
    const absX = Math.sqrt(Math.abs(x));
    const xPi = x * Math.PI;
    const yPi = y * Math.PI;
    const d = 20.0 * Math.sin(6.0 * xPi) + 20.0 * Math.sin(2.0 * xPi);
    let lat = d;
    let lng = d;
    lat += 20.0 * Math.sin(yPi) + 40.0 * Math.sin(yPi / 3.0);
    lng += 20.0 * Math.sin(xPi) + 40.0 * Math.sin(xPi / 3.0);
    lat += 160.0 * Math.sin(yPi / 12.0) + 320 * Math.sin(yPi / 30.0);
    lng += 150.0 * Math.sin(xPi / 12.0) + 300.0 * Math.sin(xPi / 30.0);
    lat *= 2.0 / 3.0;
    lng *= 2.0 / 3.0;
    lat += -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * xy + 0.2 * absX;
    lng += 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * xy + 0.1 * absX;
    return { lat, lng };
  }

  delta(lat, lng) {
    const earthRadiusInMeters = 6378137.0;
    const eccentricitSsquare = 0.00669342162296594323;
    const d = this.transform(lng - 105.0, lat - 35.0);
    const radLat = lat / 180.0 * Math.PI;
    let magic = Math.sin(radLat);
    magic = 1 - eccentricitSsquare * magic * magic;
    const sqrtMagic = Math.sqrt(magic);
    d.lat = (d.lat * 180.0) / ((earthRadiusInMeters * (1 - eccentricitSsquare)) / (magic * sqrtMagic) * Math.PI);
    d.lng = (d.lng * 180.0) / (earthRadiusInMeters / sqrtMagic * Math.cos(radLat) * Math.PI);
    return d;
  }

  // wgs84 coordinates to gcj coordinates
  fwgs2gcj(wgsLat, wgsLng) {
    const d = this.delta(wgsLat, wgsLng);
    return { lat: wgsLat + d.lat, lng: wgsLng + d.lng };
  }

  // to convert wgs84 coordinates to bdu coordinates
  // wgs84 worldwide standard
  wgs84bd09ll(longitude, latitude): { latitude: number, longitude: number } {
    if (this.country !== 'CN') {
      return { latitude, longitude };
    }
    const c = this.fwgs2gcj(latitude, longitude);
    return this.gcj02bd09ll(c.lng, c.lat);
  }

  // gcj coordinates to baidu coordinates
  // gcj is another coordinates standard in china used by different map
  gcj02bd09ll(lng, lat) {
    const xpi = Math.PI * 3000.0 / 180.0;
    const x = lng;
    const y = lat;
    const z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * xpi);
    const theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * xpi);
    const bdLon = z * Math.cos(theta) + 0.0065;
    const bdLat = z * Math.sin(theta) + 0.006;
    return { longitude: bdLon, latitude: bdLat };
  }

  /**
   * calulate the distance between two lat and long and return the distance in the unit passed
   * @param currentLatitude : number
   * @param currentLongitude : number
   * @param secondLatitude : number
   * @param secondLongitude : number
   * @param unit : string
   * @returns distance in incoming unit (Supported Values : Miles(unit), K, M, N);
   */
  async calculateDistance(currentLatitude, currentLongitude, secondLatitude, secondLongitude, unit) {
    if ((currentLatitude == secondLatitude) && (currentLongitude == secondLongitude)) {
      return 0;
    }
    else {
      const radlat1 = Math.PI * currentLatitude / 180;
      const radlat2 = Math.PI * secondLatitude / 180;
      const theta = currentLongitude - secondLongitude;
      const radtheta = Math.PI * theta / 180;
      let distance = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (distance > 1) distance = 1;
      distance = Math.acos(distance);
      distance = distance * 180 / Math.PI;
      distance = distance * 60 * 1.1515;
      if (unit == "K") { distance = distance * 1.609344 }
      if (unit == "N") { distance = distance * 0.8684 }
      if (unit == "M") { distance = (distance * 1.609344) * 1000 }
      return distance;
    }
  }

  async convertAddressToCoords(address): Promise<boolean | any> {
    const options: NativeGeocoderOptions = {
      useLocale: true,
      maxResults: 5
    };

    return await this.nativeGeocoder.forwardGeocode(address, options)
      .then((result: NativeGeocoderResult[]) => {
        const convertedCoordsFromAddressConverted = this.wgs84bd09ll(+result[0].longitude, +result[0].latitude);
        return convertedCoordsFromAddressConverted;
      }).catch((error: any) => {
        console.log(error);
        return false;
      });
  }

  async getCountryFromCoords(latitude, longitude) {
    const options: NativeGeocoderOptions = {
      useLocale: true,
      maxResults: 5
    };

    return await this.nativeGeocoder.reverseGeocode(latitude, longitude, options)
      .then((result: NativeGeocoderResult[]) => {
        if (result.length > 0) {
          return result[0].countryCode;
        }
        return ('IN');
      }).catch((error: any) => {
        console.log(error);
        return false;
      });
  }

  async getLocationFromCoords(latitude, longitude) {
    const options: NativeGeocoderOptions = {
      useLocale: true,
      maxResults: 5
    };

    return await this.nativeGeocoder.reverseGeocode(latitude, longitude, options)
      .then((result: NativeGeocoderResult[]) => {
        if (result.length > 0) {
          return [result[0].subThoroughfare, result[0].thoroughfare, result[0].subLocality, result[0].locality].filter(Boolean).join(', ');
        }
        return ('IN');
      }).catch((error: any) => {
        console.error('getLocationFromCoords: ', error);
        return '';
      });
  }

  async showEnableGpsAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('ENABLE_LOCATION'),
      message: this.translate.instant('ENABLE_LOCATION_MESSAGE'),
      buttons: [{
        text: this.translate.instant('OK'),
        role: 'confirm',
        handler: () => {
          // this.handlerMessage = 'Alert confirmed';
        },
      }],
    });

    await alert.present();

    await alert.onDidDismiss();
    // this.roleMessage = `Dismissed with role: ${role}`;
  }

  async askPermissionForLocation() {
    const alert = await this.alertController.create({
      header: this.translate.instant('USER_DENIED_PERMISSION'),
      message: this.translate.instant('USER_LOCATION_DENIED'),
      buttons: [{
        text: this.translate.instant('OK'),
        role: 'confirm',
        handler: () => {
          // this.handlerMessage = 'Alert confirmed';
        },
      }, {
        text: this.translate.instant('CANCEL'),
        role: 'cancel',
        handler: () => {
          // this.handlerMessage = 'Alert confirmed';
        },
      }],
    });

    await alert.present();

    return await alert.onDidDismiss();
  }

}