import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY } from './../../../config/dynamic-forms/affiliations-contants';
import {AgendaFooterService, AgendaFooterView} from './../../../services/footer/agenda-footer.service';
import {IndHeaderLeftDataModel} from '@omni/models/indHeaderLeftDataModel';
import {DateTimeFormatsService} from './../../../services/date-time-formats/date-time-formats.service';
import {format, isValid} from 'date-fns';
import {ContactConsent} from './../../../classes/consent/contact-consent.class';
import {AccountRelation, ContactAddress, ContactAllocation, ContactMedicalInsight, ContactsModel, DCRApprovalReq, SelectableLinkedEntity} from './../../../classes/contact/contact.class';
import {DB_KEY_PREFIXES} from './../../../config/pouch-db.config';
import {DiskService, OFFLINE_DB_LINKED_ENTITY_NAME} from './../../../services/disk/disk.service';
import {MainCardViewDataModel} from './../../../models/MainCardViewDataModel';
import {IndSectionHeaderViewDataModel} from './../../../models/indSectionHeaderDataModel';
import {SearchConfigService} from './../../../services/search/search-config.service';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {Contact, ContactDTO, ContactMeetingState, Email, Specialty} from '../../../classes/contact/contact.class';
import {IonContent, IonList, IonNav, LoadingController, ModalController, NavParams, PopoverController} from '@ionic/angular';
import {
  ContactOfflineService,
  ContactSelectionObservableModel, ContactTag, CustomerTagStateCode,
  UserTagForContact
} from "../../../services/contact/contact.service";
import {ComponentViewMode, UIService} from '../../../services/ui/ui.service';
import {FooterService, FooterViews} from '../../../services/footer/footer.service';
import {ActivityService} from '../../../services/activity/activity.service';
import {DeviceService} from '../../../services/device/device.service';
import {ContactDataService} from '../../../data-services/contact/contact.data.service';
import {MeetingDataService, UpdateMeetingPayload} from '../../../data-services/meeting/meeting.data.service';
import {AppointmentActivity} from '../../../classes/activity/appointment.activity.class';
import {AuthenticationService} from '../../../services/authentication.service';
import {TrackingEventNames, TrackService} from '../../../services/logging/tracking.service';
import {NavigationService, PageName} from '../../../services/navigation/navigation.service';
import {EmailTemplateServices} from '../../../data-services/email-templates/email-template.service';
import {RepServices} from '../../../data-services/rep/rep.services';
import {ActivityType} from '../../../classes/activity/activity.class';
import {CreateSampleDropRequestBody, SampleActivity} from '../../../classes/activity/sample.activity.class';
import {SampleService, SamplingDetailsViewMode} from '../../../services/sample/sample.service';
import {ActivityDataService} from '../../../data-services/activity/activity.service';
import {SampleDataService} from '../../../data-services/sample/sample.data.service';
import {FeatureActionsMap} from '../../../classes/authentication/user.class';
import {ChangeEvent} from '../../angular-virtual-list';
import {Subject, Subscription} from 'rxjs';
import {EmailService} from '../../../services/email-templates/email.service';
import {
  EmailActivity,
  EmailActivityParty,
  EmailAddress,
  EmailViewType
} from '../../../classes/activity/email.activity.class';
import {EmailDataService} from '../../../data-services/email/email.data.service';
import {DeltaService} from '../../../data-services/delta/delta.service';
import {FollowUpActivity} from '../../../classes/activity/follow-up-action.activity.class';
import {FollowUpActivityDataService} from '../../../data-services/follow-up-activity/follow-up-activity.data.service';
import {DynamicsClientService} from '../../../data-services/dynamics-client/dynamics-client.service';
import {ConsentService} from '../../../services/consent/consent.service';
import {Channel, ChannelActivityType, ChannelType} from '../../../classes/consent/channel.class';
import {
  AccesingMode,
  CaseManagementService,
  DETAILS_OPTION
} from '../../../services/case-management/case-management.service';
import {NothingSelectedView} from '../../shared/nothing-selected-view/nothing-selected-view';
import {XperiencesService} from '../../../services/xperiences/xperiences.service';
import {CustomerRelationship, TrendingCustomer} from '../../../classes/xperiences/trending.customer.class';
import {CustomerSelectionMode} from '../../../utility/util';
import {ScientificActivityService} from '../../../services/scientific-activity/scientific-activity.service';
import {GlobalUtilityService} from "../../../services/global-utility.service";
import {TranslateService} from '@ngx-translate/core';
import {Opportunity} from '../../../classes/opportunity-management/opportunity.class';
import {Account} from '../../../classes/account/account.class';
import {AccountOfflineService} from '../../../services/account/account.offline.service';
import _, {isEmpty, uniqBy} from 'lodash';
import {NotificationService, ToastStyle} from '../../../services/notification/notification.service';
import {AlertService} from '../../../services/alert/alert.service';
import fuse from 'fuse.js'
import {
  SelectedSuggestionPillDataModel,
  SuggestionPillType,
  UserSavedSearchTypes
} from '../../../models/search-config-data-model';
import {SearchConfigDataService} from '../../../data-services/search-config/search-config-data-service';
import {MultiSelectPopover} from '../../../components/multi-select-popover/multi-select-popover';
import {DynamicFormsService} from '../../../services/dynamic-forms/dynamic-forms-service';
import {DynamicFormComponent} from '../../shared/ind-dynamic-form/ind-dynamic-form';
import {PhoneActivity} from '../../../classes/activity/phone.activity.class';
import {ControlDataType, DynamicForm, FormType, BusinessProcessType, OmniGender, OneCRMGender, OneCRMReqType, BusinessLine, DCR_CATEGORY, DCR_SOURCE, VaccineReqType, BusinessLineVal} from '../../../classes/dynamic-form/dynamic-form.class';
import {DynamicFormComponentModel, DynamicFormType} from '../../../models/dynamic-form-component.model';
import {DEFAULT_CONTACT_CREATE_FORM,} from '../../../config/dynamic-forms/default-contact/default-contact-create';
import {PhoneCallDataService} from '../../../data-services/phone-call/phonecall.data.service';
import {MarketScanService} from '../../../services/market-scan/market-scan.service';
import {Action, CaptureDataFor, MarketScanAccount, MarketScanContact} from '../../../classes/market-scan/market-scan.class';
import {EventName, EventsService} from '../../../services/events/events.service';
import {takeUntil} from 'rxjs/operators';
import {SecInfoConfigDataService} from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import {SecondaryInfoEntityName} from '@omni/classes/sec-info-config/sec-info.class';
import {IndTabsDataModel} from "@omni/models/ind-tabs-data-model";
import {NewChangeRequestComponent} from "@omni/components/mdm/new-change-request/new-change-request";
import {
  ChangeRequestStatusCode,
  ChangeRequestType,
  MDMRequestType,
  MDMType
} from "@omni/classes/mdm/source-type-optionset.class";
import {MdmService} from "@omni/services/mdm/mdm.service";
import {AccountDataService} from '@omni/data-services/accounts/account.data.service';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {REP_STATUS} from '@omni/models/rep-status-model';
import {CaseActivity} from '@omni/classes/case-intake/case-activity.class';
import {getSearchSuggestionsData} from '../../../utility/global-search.utility';
import {OpportunityManagementService} from '@omni/services/opportunity-management/opportunity-management.service';
import {OnekeyApiService} from "@omni/services/onekey/onekey-api.service";
import {
  OKActivitySearchKeys,
  OKEntityType,
  OmnipresenceContactSearchKeys,
  SEARCH_OPERATOR
} from "@omni/classes/onekey/ok-searchkeys.class";
import {ContactDetailsComponent} from "@omni/components/contact/contact-details/contact-details";
import { DatePipe } from '@angular/common';
import { PopoverComponent } from '@omni/components/popover/popover';
import {fetchQueries} from "@omni/config/dynamics-fetchQueries";
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { MyAssistantService, NOTIFICATION } from '@omni/services/my-assistant/my-assistant.service';
import { MasterDataService } from '@omni/data-services/master/master.data.service';
import { GeneeNotificationPopoverComponent } from '@omni/components/genee-notification-popover/genee-notification-popover';
import { VeevaWidgetService } from '@omni/services/veeva-widget/veeva-widget.service';
import { IntegrationType } from '@omni/classes/onekey/ok-integration.class';
import { AssessmentTemplate, EntityOptions, SurveyStatus, SurveyFrequency, TemplateType, SurveyCategory, AssessmentAttributeType } from '@omni/classes/customer-assessment/assessment-template.class';
import { startRecursiveActivityScheduleConflictFlagging } from '@omni/utility/activity.utility';
import { EventActivity } from '@omni/classes/events-tool/event.class';
import { AttendeeFormat, EventParticipantStatusCode, EventRegistrationStatus } from '@omni/enums/event/event.enum';
import { EventsToolDataService } from '@omni/data-services/event/events-tool.data.service';
import { CustomerAssessComponent } from '@omni/components/assessment/customer-assess/customer-assess';
import { CustomerAssessment } from '@omni/classes/customer-assessment/customer-assessment.class';
import { MarketingPlansManagementOfflineService } from '@omni/services/marketing-management/marketing-management.service';
import { IndFilterMenuComponent } from '@omni/components/shared/ind-filter-menu/ind-filter-menu';
import { PresentationService } from '@omni/services/presentation/presentation.service';
import { KOLStatusPageComponent } from '../kol-status-page/kol-status-page';
import { KOLStatusService } from '@omni/services/kol-status/kol-status-service';
import { KOLStatus } from '@omni/classes/contact/kol-status.class';
import { TerritoryManagementPageComponent } from '@omni/pages/territory-management-page/territory-management-page.component';
import { TerritoryManagementService } from '@omni/services/territory-management/territory-management.service';
import { CustomerSurveyService } from "@omni/services/customer-survey/customer-survey.service";
import { CrDetailsComponent } from '@omni/components/mdm/contact/cr-details/cr-details';
import { resolve } from 'dns';
import { MedicalInsightService } from '@omni/services/medical-insight/medical-insight-service';
import { MedicalInsightPageComponent } from '../medical-insight-page/medical-insight-page';
import { _isNumberValue } from '@angular/cdk/coercion';
import { LocalizationService } from '@omni/services/localization/localization.service';
import { TagStateCode, TagVisibility } from '@omni/services/user-tag/user-tag.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '@omni/models/mainToolTemplateDetail.model';
import { MainToolTemplateComponent } from '@omni/components/shared/main-tool-template/main-tool-template';
import { EventParticipantComponent } from '@omni/components/event-participant/event-participant';
import { SetBookingDataService } from '@omni/data-services/set-booking/set-booking.data.service';
import { AccountPageComponent } from '@omni/components/account/account-page/account-page';

/**
 * Generated class for the ContactListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'contact-list',
  templateUrl: 'contact-list.html',
  styleUrls:['contact-list.scss']
})
export class ContactListComponent {
  radioSelectedContact: Contact;
  public selectedContacts: Array<Contact>;
  public segmentedContacts: Array<Contact>;
  public contactSegment: string = 'name';
  public enableRemote: boolean = false;
  public searching: boolean;
  public compViewMode = ComponentViewMode;
  private currentPage: number = 1;
  public newContacts: Contact[] = [];
  public nonGuestContacts: Contact[] = [];
  public contactState = ContactMeetingState;
  private holdContactInformation: Contact;
  private _loading: HTMLIonLoadingElement;
  private isAutoSubjectEnabled: boolean = false;
  public isDirty: boolean = false; //to enable or desable done butto if data changed
  public isSentimentsDirty: boolean = false; //to enable or desable done butto if data changed
  public isKeyMessageSentimentsDirty:boolean = false; // To track key message sentiments dirty event
  public contactSearchText: string = '';
  private recordCount: number = 30;
  private searchRecordCount: number = 30;
  private globalSearchRecordCount: number = 30;
  private _contactsToDisplay: Contact[] = []; //Contacts to display
  shouldFireSearchChangeEvent: boolean = true;
  slicedSearchedContactsToDisplay: Contact[];
  mobileNumberList: any[];
  mobileNumberListforShareMessage: any[];
  selectedMobileNumberListforShareMessage: any[];
  @Input() selectedContactIds: any;
  @Input() maximumeventcapacity:any;
  @Input() isCovisitorView: any;
  @Input() selectedAccountIds: any;
  indHeaderLeftModel: IndHeaderLeftDataModel
  public isAccessedFromContactToolsDrawer: boolean;
  public isMarketScanContactAndAccount:boolean = false;
  public isDisabledSubmitAll: boolean=false;
  public get contactsToDisplay(): Contact[] {
    return this._contactsToDisplay;
  }
  public set contactsToDisplay(value: Contact[]) {
    this._contactsToDisplay = value;
  }
  public searchedContactsToDisplay: Contact[] = [] //Searched contacts to display
  public affiliatedContacts: Contact[] = []; // Affiliated contacts from selected accounts from meeting
  public filterMetadata = { count: 0 };
  public targetContactList: Contact[] = []

  @Input() contactListMode: string;
  @Input() participantsList: any[] = [];

  @Output() closeModal = new EventEmitter<string>();
  @Output() addNewContactHandler = new EventEmitter();

  indices: ChangeEvent = { start: 0, end: 0 };
  @ViewChild(IonList, { read: ElementRef, static: false }) list: ElementRef;

  private readonly MIN_SEARCH_LENGTH = 1;
  public selectionEligibleContactsLength: number;
  public selectionModeForComponent: string;
  public selectionEligibleContacts: Array<Contact> = [];
  public filteredScientificTaskContacts: Array<Contact> = [];
  public contactsWithEmailAddress: Array<Contact> = [];
  public emailActivity: EmailActivity;

  private loading: HTMLIonLoadingElement;
  private unsubscribe$ = new Subject<void>();

  public enableCreateContact = false;
  public enableEventCreateContact = false;
  public contactsCreateRequest = false;
  public businessCreateRequest = false;
  public businessCreateChangeRequest = false;
  public enableGlobalSearch = false;
  public enableVeevaSearch = false;
  public enableVeevaMyRequests = false;
  public enableOneKeySearch = false;
  public enableOneKeyDownload = false;
  public enableOneTimeVeevaMeeting = false;
  public selectedGlobalSearchContact: Contact = null;
  private IcaseSUbscription: Subscription;
  private isCCRFormDirty = false;
  public pagename = PageName;

  private listViewContact: Contact;
  private selectionMode: CustomerSelectionMode;
  public ChannelType = ChannelType;
  public ChannelActivityType = ChannelActivityType;
  public globalCustomerDisplayText: string;
  public globalCustomerText: string;
  public globalCustomersText: string;
  public slicedGlobalSearchResults: Contact[] = [];
  public isAssessmentFilter: boolean = false;
  public enableAssessment: boolean = false;
  // private assessRawData: CustomerAssessment[] = [];
  private isAssessmentLoading: boolean = false;
  private templates: AssessmentTemplate[];

  public selectedMobileNumber:any;

  // contact user tag story
  public selectedExistingTag: boolean;
  public showUserContactTag: boolean;
  public showAndRemoveTagContacts: boolean;
  public showRemoveFromTag: boolean = false;
  public disableAddToTag: boolean = true;
  public selectedContactsFromTag : Contact[] = [];
  public tagListPopover: HTMLIonPopoverElement;
  public selectedTagData: UserTagForContact;
  public isFromConsentTool:boolean;
  public isKOLStatusEnabled:boolean = false;
  public isMedicalInsightEnabled:boolean = false;
  public isCustomeListManagementEnabled:boolean = false;
  public isSingleSelectionHCOHCPEnabled:boolean = false;
  public listPeriodTags : string[] = [];
  public isClickedTagSelectBtn: boolean = false;
  public get tagMyContactsHeaderModel() {
    return {
      id: "selected-tags",
      title: `${this.translate.instant("MY_CONTACTS", { globalCustomerText: this.globalCustomersText })} (${this.selectedContactsFromTag.length})`,
      controls: []
    };
  }
  public get selectedTagDataHeaderModel() {
    return {
      id: "selected-tags",
      title: `${this.selectedTagData.indskr_name}  (${this.selectedContactsFromTag.length})`,
      controls: []
    };
  }

  public get noContactForSelectedTag(){
    const globalCustomersText = this.globalCustomersText.toLocaleLowerCase();
    return `${this.translate.instant("NO_CONTACTS_FOR_SELECTED_TAG", { text: globalCustomersText })}`
  }
  public isInvokedForOpportunityStakeholderSelection: boolean = false;

  //To keep track of previously selected contacts for any entity
  private _initiallySelectedContacts:Array<Contact> = [];
  private fetchRealtimeDetailsFlag:boolean = true;

  public get isOpenedForLiveMeet() {
    return (this.contactService.contactPageMode === this.compViewMode.ADDNEW
      || this.contactService.contactPageMode === this.compViewMode.SELECTION)
      && this.activityService.selectedActivity
      && this.activityService.selectedActivity instanceof AppointmentActivity
      && this.activityService.selectedActivity.isLiveMeet;
  }
  public get title() {
    if(this.navParams && this.navParams.data && this.navParams.get('from') && this.navParams.get('from') === 'ConsentTool') return this.translate.instant('CONSENT');
    if (this.isOpenedForLiveMeet) return this.translate.instant('ACTIVITY_DETAILS_PARTICIPANTS');
    if (this.isInvokedForOpportunityStakeholderSelection || this.contactService.accessedContactListFrom == PageName.AccountPlanDetailsComponent || this.contactService.accessedContactListFrom === PageName.MarketingPlanDetails) return this.translate.instant('STAKEHOLDERS');
    if( this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent) return this.translate.instant('ACTIVITY_DETAILS_PARTICIPANTS');
    return this.translate.instant(this.utilityService.globalCustomersText.toUpperCase());
  }
  //boolean to decide visibility of suggestions popover
  suggestionsActive: boolean = false;
  //array of shortlisted facets
  suggestionsData: {
    header:IndSectionHeaderViewDataModel,
    listData: MainCardViewDataModel[]
  }[] = [];
  //search key to be passed to search-suggestion-popover
  searchKey : string = '';
  //array of user selected facets
  selectedSuggestionsData:  SelectedSuggestionPillDataModel[] = [];
  disableSaveSearch: boolean = false;

  sortPopoverData:any;
  sortBy:{text: string, value: string, asc: boolean};

  private _callbackOnDone;
  private _holdDynamicFormIsDirtyTracker:boolean;
  private selectedCustomerId: string;
  @Input() filteredSPContacts:any;
  @Input() callbackEvent:any;
  @Input() contactListData:any;
  public isAccessedFromToolsDrawer: boolean = false;
  public isAccessedActiveMeeting: boolean = false;

  public restrictedSelectedContactsViewActive:boolean = false;
  public restrictedViewSelectedContacts:Array<any> = [];
  public allContactsHeader: IndSectionHeaderViewDataModel;
  public selectedContactsHeader: IndSectionHeaderViewDataModel;
  public segmentedContactsHeader: IndSectionHeaderViewDataModel;
  public restrictedSelectedContactsHeader: IndSectionHeaderViewDataModel;
  public newContactsHeader: IndSectionHeaderViewDataModel;
  public newParticipantsHeader: IndSectionHeaderViewDataModel;
  public affiliatedContactsHeader: IndSectionHeaderViewDataModel;
  public selectedGlobalSearchContactHeader: IndSectionHeaderViewDataModel;
  public selectedOKSearchContactHeader: IndSectionHeaderViewDataModel;
  public globalContactsHeader: IndSectionHeaderViewDataModel;
  public oneKeyContactsHeader: IndSectionHeaderViewDataModel;
  public localResultsContactsHeader: IndSectionHeaderViewDataModel;
  public resultsAllocationHeaderModel: IndSectionHeaderViewDataModel;
  public phoneResultsAllocationHeaderModel: IndSectionHeaderViewDataModel;
  public localResultsAllocationHeaderModel: IndSectionHeaderViewDataModel;
  public myContactChangeRequestsHeaderModel: IndSectionHeaderViewDataModel;
  public myDCRApprovableRequestsHeaderModel: IndSectionHeaderViewDataModel;
  IcontactCRsSubscription: Subscription;
  tabsData: IndTabsDataModel[];
  tabsComponentData: IndTabsDataModel[];
  public contactsModel: ContactsModel = ContactsModel.MY_CONTACTS_TAB;
  public componentModel: string = 'contact';
  public currentTab: String;
  public currentComponent: string;
  public contactCRs = [];
  public filteredContactCRs = [];
  public hideFilterButton: boolean = false;
  filterPopoverData: { text: string; value: string; items: any; handler: (selectedItem: any, item: any, parent: any) => void; }[];
  private changeReqStatusFilter: number = null;
  private changeReqTypeFilter: number = null;
  private changeReqSourceFilter: number = null;
  public selectedEmailChannel:Channel;
  private isDeviceOnline: boolean = true;
  private ngDestroy$ = new Subject<boolean>();
  public selectedContactsForNonNativeChannelSMS:boolean = false;
  public selectedContactsForNonNativeChannelWhatsApp:boolean = false;
  private linkEntitiesReqPayload: any = [];
  private searchedByOKID: boolean = false;
  private IUserTagDeletedSubscription: Subscription;
  private isOpenedOnPreviewMode: boolean = false;

  public hasSecondaryInfo: boolean = false;
  public selectablLinkedEntityData: Array<SelectableLinkedEntity> = [];
  @ViewChild('content', {static:true}) ionContent: IonContent;
  public selectedFilterData: SelectedSuggestionPillDataModel[] = [];
  public filtering: boolean = false;
  public filterNameBubble: { categoryName: string; suggestion: SelectedSuggestionPillDataModel[]}[] = [];
  private isFilteredContactCRs: boolean = false;
  private allowAffiliatedHCPSelectionOnly:boolean = false;
  public isAccountVisitEnabled = false;
  public isAccountVisitRecord = false;
  public isAccountVisitNestedMeeting = false;
  public filteredDCRApprovables: Array<MainCardViewDataModel> = [];
  public filteredItemsList:Array<DCRApprovalReq> = [];
  private selectedDateFromFilter: {startDate: string, endDate: string} = {startDate: '', endDate: ''};
  private isContactConfigUpdateRequired: boolean = false;
  public opportunityStakeholderSelectionEnabled:boolean = false;
  public isOpportunityStakeholderValueEnabled:boolean = false;

  //private contactDetailsPage:any = ContactDetailsComponent;
  //private contactDetailsPageName = PageName.ContactDetailsComponent;
  public hasTerritoryFAEnabled: boolean = false;
  public hasComponentChangeTab: boolean = false;

  constructor(
    public navCtrl: IonNav,
    public contactService: ContactOfflineService,
    private contactDataService: ContactDataService,
    public uiService: UIService,
    public events: EventsService,
    public device: DeviceService,
    private meetingService: MeetingDataService,
    private masterData: MasterDataService,
    private loadingCtrl: LoadingController,
    public footerService: FooterService,
    public activityService: ActivityService,
    private activityDataService: ActivityDataService,
    private authenticationOfflineService: AuthenticationService,
    private trackingService: TrackService,
    private navService: NavigationService,
    public emailtemplateService: EmailTemplateServices,
    public repService: RepServices,
    public sampleService: SampleService,
    public sampledataService: SampleDataService,
    private contactListElement: ElementRef,
    public emailService: EmailService,
    public emailDataService: EmailDataService,
    public deltaService: DeltaService,
    private followUpActivityDataService: FollowUpActivityDataService,
    public dynamics: DynamicsClientService,
    private consentService: ConsentService,
    private caseManagementService: CaseManagementService,
    private alertService: AlertService,
    public _cd: ChangeDetectorRef,
    public navParams: NavParams,
    private xperiencesService: XperiencesService,
    private sapService: ScientificActivityService,
    public utilityService: GlobalUtilityService,
    public translate: TranslateService,
    private accountService: AccountOfflineService,
    private notificationService: NotificationService,
    private searchConfigService: SearchConfigService,
    public disk: DiskService,
    private scientificActivityService: ScientificActivityService,
    public searchConfigDataService:SearchConfigDataService,
    public popover: PopoverController,
    public dateTimeFormatsService: DateTimeFormatsService,
    private dynamicFormsService: DynamicFormsService,
    private phoneCallDataService : PhoneCallDataService,
    private marketScanService: MarketScanService,
    private secondaryInfoService: SecInfoConfigDataService,
    public mdmService: MdmService,
    private modalCtrl:ModalController,
    public accountDataService: AccountDataService,
    private iab: InAppBrowser,
    private veevaWidgetService: VeevaWidgetService,
    private agendaFooterService: AgendaFooterService,
    public opportunityService: OpportunityManagementService,
    public onekeyApiService: OnekeyApiService,
    private datePipe: DatePipe,
    private customerAssessService: CustomerAssessService,
    public toast: NotificationService,
    public myAssistantService: MyAssistantService,
    private eventsToolService: EventsToolService,
    private eventsToolDataService: EventsToolDataService,
    private marketingManagementOfflineService:MarketingPlansManagementOfflineService,
    private presentationService: PresentationService,
    public kolStatusService: KOLStatusService,
    public territoryService: TerritoryManagementService,
    private customerSurveyService: CustomerSurveyService,
    private meetingDataService: MeetingDataService,
    public medicalInsightService: MedicalInsightService,
    public languageService:LocalizationService,
    private faService: FeatureActionsService,
    private setBookingDataService: SetBookingDataService
  ) {
    if(this.navParams.data) {
      if(this.navParams.data.customerDeatilsPreview){
        this.selectedCustomerId = this.navParams.data.customerId;
        console.log('customerId in contacts', this.selectedCustomerId);
      }else if(this.navParams.data.contactListData){
        this.contactListData=this.navParams.data.contactListData;
      }
      if(this.navParams.data.filteredSPContacts){
        this.filteredSPContacts = this.navParams.data.filteredSPContacts;
      }
    }
    if(!this.contactService.loadedContacts){
      this.checkContactModel(ContactsModel.APPROVALS_TAB);
      this.contactsModel = ContactsModel.APPROVALS_TAB;
    }


    this.isFromConsentTool = this.navParams.get('from') === 'ConsentTool';
    this.selectedContacts = [];
    this.segmentedContacts = [];
    this.events.subscribe('refreshSeletecContacts', () => {
      this.selectedContacts = (this.activityService.selectedActivity) ? _.cloneDeep(this.activityService.selectedActivity['contacts'].slice()) : [];
    });
    this.events.subscribe("contact-content-update", (value) => {
      this.isSentimentsDirty = value;
      this.initContactsHeaderLeft();
    });
    this.events.subscribe("contact-key-message-sentiment-update", (value) => {
      this.isKeyMessageSentimentsDirty = value;
      this.initContactsHeaderLeft();
    });
    this.events.subscribe("closeMultiAssessPage", () => {
      this.cancelTagSelection();
    });

    this.events.observe("highlightContact").pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((contact: Contact) => {
        const contactItem = document.getElementById(contact.ID);
        const isNewContact = this.newContacts.some(n => n.ID == contact.ID);
        if (isNewContact) {
          this.ionContent.scrollToTop();
        } else if (contactItem) {
          const index = this.contactsToDisplay.findIndex(c => c.ID === contact.ID);
          const maxIndex = this.contactsToDisplay.length - 1;
          document.getElementById(this.contactsToDisplay[index > this.contactsToDisplay.length - 2 ? index : maxIndex < index + 2 ? index : index + 2].ID).scrollIntoView(false);
        } else {
          const index = this.nonGuestContacts.findIndex(c => c.ID === contact.ID);
          if (index >= 0) {
            const lastIndex = index > this.nonGuestContacts.length - 3 ? index : index + 3;
            this.contactsToDisplay.push(...this.sliceContacts(this.recordCount, lastIndex));
            this.recordCount = this.contactsToDisplay.length;
            setTimeout(() => {
              const contactItem = document.getElementById(this.contactsToDisplay[lastIndex - 1].ID);
              if (contactItem) {
                contactItem.scrollIntoView(false);
              }
            }, 500);
          }
        }
        if (!this.isOpenedOnPreviewMode) {
          this.highlightContact(null, contact);
        } else this.highlightContact(null, contact, true);
      });

    this.events.observe("highlightAffiliatedContact").pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((contact: Contact) => {
        this.highlightContact(null, contact, true, undefined, true);
      });

      this.events.observe("updateContactListIsDirty").pipe(
        takeUntil(this.ngDestroy$))
        .subscribe(() => {
          if(this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent) {
            this.isDirty = !_.isEmpty(this.eventsToolService.selectedEventOnEventsTool?.['eventPassesToSave']);
          }
          this.initContactsHeaderLeft();
        });


    this.IUserTagDeletedSubscription = this.contactService.userTagDeletedObserver.subscribe(isTagDeleted =>{
      if(isTagDeleted){
         this.cancelTagSelection();
      }
    });

    if (this.filteredSPContacts) {
      this.filteredScientificTaskContacts = this.filteredSPContacts;
    }

    if (this.callbackEvent) {
      this._callbackOnDone = this.callbackEvent;
    }

    if (this.navParams && this.navParams.data) {
      if (this.navParams.data['callbackEvent']) {
        this._callbackOnDone = this.navParams.data['callbackEvent'];
      }
    }


    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        this.globalCustomerDisplayText = this.translate.instant('CUSTOMERS').toUpperCase();
        this.globalCustomersText = this.translate.instant('CUSTOMERS');
        this.globalCustomerText = this.translate.instant('CUSTOMER');
        break;
      case 'Stakeholder':
        this.globalCustomerDisplayText = this.translate.instant('STAKEHOLDERS').toUpperCase();
        this.globalCustomersText = this.translate.instant('STAKEHOLDERS');
        this.globalCustomerText = this.translate.instant('STAKEHOLDER');
        break;
      default:
        if (this.utilityService.globalCustomersText) {
          this.globalCustomerDisplayText = this.utilityService.globalCustomersText.toUpperCase();
          this.globalCustomersText = this.utilityService.globalCustomersText;
          this.globalCustomerText = this.utilityService.globalCustomerText;  
        }
        break;
    }
  }

  private contactCreateHandler: (event:any) => void = async (event:any) => {
    if(event && event['isMDMType']){
      if(event.value == MDMType.ONEKEY){
        await this.oneKeyContactCreateForm();
      }
    }else if(event && !event['isMDMType']){
      if(event.value == ChangeRequestType.BUSINESS){
        await this.defaultBusinessContactDynamicForm(event);
      }
    }
  }

  private async initiateContactsSyncWhenAsk() {
    // Show Sync UI
    let loadingText: string = this.translate.instant('GENEE_INITIALIZING_SYNCING_DATA_PLEASE_WAIT');
    let data = {
      heading: this.translate.instant('GENEE_SYNC_IN_PROGRESS_CAP'),
      notificationText: loadingText,
      showLoader: true
    }
    if (!this.uiService.geneeSyncPopover) {
      this.uiService.geneeSyncPopover = await this.popover.create(
        {
          component: GeneeNotificationPopoverComponent, componentProps: data,
          backdropDismiss: false,
          showBackdrop: true,
          cssClass: 'geneeSyncInProgress',
        });
      this.uiService.geneeSyncPopover.present();
    }

    // Animated Progress Bar in Header
    this.device.syncInProgress = true;

    this.deltaService.masterSyncState.setState('Sync');
    await this.masterData.onlyContactSync(false, false).then(() => {
      this.uiService.geneeSyncPopover.dismiss();
      this.uiService.geneeSyncPopover = undefined;
      this.device.syncInProgress = false;
      this.onSuccessfulContactSync();
      this.deltaService.masterSyncState.setState('Success');
    }).catch(error => {
      console.error('Contact Sync Error', error);
      this.uiService.geneeSyncPopover.dismiss();
      this.uiService.geneeSyncPopover = undefined;
      this.device.syncInProgress = false;
      this.deltaService.masterSyncState.setState('Fail');
    });
  }

  private onSuccessfulContactSync() {
    this.setTabsData();
    this.setComponentTabsData();
    this.initContactsHeaderLeft();
    this.contactsToDisplay = [];
    this.init();
    this.contactService.contacts.forEach(contact => {
      if (this.deltaService.deltaRecordsDTO.contacts.indexOf(contact.ID) != -1 && !contact.isguest) {
        this.newContacts.push(contact);
      }
    });
    this.contactService.updateNewContactStatus(this.newContacts);
    this.updateEmptyMessage();
    this._initAllSectionHeaderModel();
    this._initFilterPopoverData();
  }

  ngOnInit() {
    this.onDefaultFilterLoad();
    // This param used to get the page where user came from. contactService.accessedContactListFrom can't have track when user visits tools -> meeting -> consent -> contact.
    if(this.navParams && this.navParams.data && this.navParams.get('from') && (this.navParams.get('from') === 'ContactTool' || this.navParams.get('from') === 'ConsentTool')) {
      this.isAccessedFromToolsDrawer = true;
    }
    if (this.navParams && this.navParams.data && this.navParams.get('from') && (this.navParams.get('from') === 'ContactTool')
      && ((this.navParams.get('openedToolFromContactDetails') && this.navParams.get('openedToolFromContactDetails') === true)
          || this.navParams.get('openedToolFromCrDetails') && this.navParams.get('openedToolFromCrDetails') === true)) {
      this.dynamicFormsService.isOpenedAffiliatedContactOnAccount = true;
      this.accountService.isEnableGoToAccountsTool = true;
      this.contactService.isEnableGoToContactsTool = true;
      this.uiService.selectedOptionInMenu = 'contacts';
      this.uiService.showNewActivity = false;
      this.uiService.prevView = this.uiService.activeView
      this.uiService.activeView = "";
      this.uiService.isConsentFromToolDrawer = false;
      this.uiService.consentFlipButton = false;
      //this.uiService.contactDetailsSegment = 'info';
      this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
      this.contactService.accessedContactListFrom = PageName.ContactDetailsComponent;
    }

    this.mdmService.chnagerequestApproved$.subscribe(async (isCRApproved) => {
      if (isCRApproved) {
        if (this.contactsModel !== ContactsModel.MY_CONTACTS_TAB) {
          this.currentTab = ContactsModel.MY_CONTACTS_TAB;
          this.contactsModel = ContactsModel.MY_CONTACTS_TAB;
          await this.initiateContactsSyncWhenAsk();
        }
      }
    });

    if(this.navParams && this.navParams.data && this.navParams.get('from') && (this.navParams.get('from') === 'ContactTool')) {
      this.isAccessedFromContactToolsDrawer = true;
    }
    this.dynamicFormsService.isOpenedAffiliatedContactOnAccount = false;
    this.dynamicFormsService.isOpenedAffiliatedAccountOnAccount = false;
    this.dynamicFormsService.isOpenedAffiliatedAccountOnContact = false;
    this.dynamicFormsService.isOpenedAffiliatedContactOnContact = false;
    this.dynamicFormsService.openedUnmappedData = false;
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT)) {
      const appointmentActivityHandle: AppointmentActivity = this.activityService.selectedActivity && this.activityService.selectedActivity.type === ActivityType.Appointment
        ? this.activityService.selectedActivity as AppointmentActivity : undefined;
      this.isAccountVisitEnabled = true;
      this.isAccountVisitRecord = !!appointmentActivityHandle?.indskr_isparentcall;
      this.isAccountVisitNestedMeeting = !!appointmentActivityHandle?.indskr_parentcallid;
    }
    if(
      this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SINGLE_SELECTION_OF_HCO_AND_HCP)
      && !this.isAccountVisitRecord
    ){
      this.isSingleSelectionHCOHCPEnabled = true;
    }
    this.isAccessedActiveMeeting = (this.contactService.accessedContactListFrom === PageName.ActivitiesDetailsPaneComponent || this.contactService.accessedContactListFrom === PageName.ProcedureTrackerComponent
      || this.contactService.accessedContactListFrom === PageName.PresentationMeetingComponent || this.contactService.accessedContactListFrom === PageName.SurgeryOrderDetailsComponent)
      && this.contactService.contactPageMode === ComponentViewMode.ADDNEW;
    this.contactsCreateRequest = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CONTACTS_CREATE_REQUEST);
    this.businessCreateRequest = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CONTACT_CREATOR);
    this.enableOneTimeVeevaMeeting = !!this.authenticationOfflineService.user.buSettings.indsyn_onetimeveevameeting;
    this.businessCreateChangeRequest = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_CHANGE_REQUESTS);
    this.enableCreateContact = (this.businessCreateRequest || this.contactsCreateRequest || this.businessCreateChangeRequest) && (this.isAccessedActiveMeeting ||
      (this.contactService.contactPageMode===this.compViewMode.LISTVIEW && !this.uiService.isConsentFromToolDrawer));
    this.enableEventCreateContact = (this.authenticationOfflineService.user.featureActions.indexOf(FeatureActionsMap.CONTACT_CREATOR_EVENTS) > - 1);
    this.enableAssessment = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_ASSESS);
    this.isCustomeListManagementEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT) && this.contactService.contactPageMode === this.compViewMode.LISTVIEW && !this.uiService.isConsentFromToolDrawer;
    this.isKOLStatusEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_KOL_STATUS) && this.contactService.contactPageMode===this.compViewMode.LISTVIEW && !this.uiService.isConsentFromToolDrawer;
    this.isMedicalInsightEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_MEDICAL_INSIGHT) && this.contactService.contactPageMode===this.compViewMode.LISTVIEW && !this.uiService.isConsentFromToolDrawer;
    // # # # View Contacts in sequence the way they were selected during meeting creation # # # //
    this.contactService.isMeetingContactSequenceEnabled = this.authenticationOfflineService.user.buSettings['indskr_meetingcontactsequence'] ? true : false;
    // # # # View Contacts in sequence the way they were selected during meeting creation # # # //
    // if(this.isKOLStatusEnabled && this.contactService.contactPageMode===this.compViewMode.LISTVIEW && !this.uiService.isConsentFromToolDrawer){
    //   this.agendaFooterService.footerViewAtHome=this.agendaFooterService.lastFooterView;
    //   this.agendaFooterService.initButtons(AgendaFooterView.ContactKOL);
    // }
    console.log("contactsCreateRequest: "+this.contactsCreateRequest+" businessCreateRequest"+this.businessCreateRequest+" businessCreateChangeRequest"+this.businessCreateChangeRequest+" enableCreateContact"+this.enableCreateContact);
    if (((this.contactService.contactPageMode === ComponentViewMode.LISTVIEW
      || this.contactService.contactPageMode === ComponentViewMode.READONLY) && !this.uiService.isConsentFromToolDrawer && this.isAccessedFromToolsDrawer)
      || this.isAccessedActiveMeeting) {
        if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CONTACTS_GLOBAL_SEARCH)) {
          this.enableGlobalSearch = true;
        }
        if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CONTACTS_VEEVA_GLOBAL_SEARCH) && !this.device.isOffline) {
          // this.veevaWidgetService.loadVeevaScripts();
          this.enableVeevaSearch = true;
        }
        if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CONTACTS_VEEVA_MY_REQUESTS) && !this.device.isOffline) {
          // this.veevaWidgetService.loadVeevaScripts();
          this.enableVeevaMyRequests = true;
        }
        if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ONEKEY_CONTACT_SEARCH)) {
          this.enableOneKeySearch = true;
          this.onekeyApiService.okSearchResult.subscribe((res) => {
            this.onekeyApiService.oneKeyContacts = res;
          });
        }
        if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ONEKEY_CONTACT_DOWNLOAD)) {
          this.enableOneKeyDownload = true;
        }
    }

    this.events.subscribe(EventName.DCR_APPROVAL_SUBMITTED, (params) => {
      this.updateItemsToDisplay();
    });

    if(this.contactService.accessedContactListFrom == PageName.OpportunityDetailsComponent 
      || (this.contactService.accessedContactListFrom == PageName.NewOpportunityComponent && this.contactListData && this.contactListData.for == 'OpportunityStakeholderSelection')) this.hasComponentChangeTab = true;
    this.setTabsData();
    this.setComponentTabsData();

    this.initContactsHeaderLeft();
    //this.disableSearchBar();

    this.contactsToDisplay = [];
    // this.contacts$.next(this.contactsToDisplay);
    this.init();

    this.events.unsubscribe('contactsPageDidEnter');
    this.events.subscribe('contactsPageDidEnter', () => {
      // this.getContacts();
      setTimeout(() => {
        if(this.loading) this.loading.dismiss();
      }, 500);
    });
    this.events.unsubscribe('notifyCCRIsDirty');
    this.events.subscribe('notifyCCRIsDirty', (isDirty) => {
      this.isCCRFormDirty = true;

    });
    this.events.unsubscribe('sortContacts');
    this.events.subscribe('sortContacts', () => this.sortContactsListBasedOnSelection(this.sortBy));

    this.contactService.contactInformationObservable.pipe(takeUntil(this.unsubscribe$)).subscribe(data=> {
      if(data) {
        this.updateContactLists(data);
      }
    });

    //adding new contacts
    this.contactService.contacts.forEach(contact => {
      // Remove duplicate emailAddressList data
      const uniqueEmailsMap = new Map();
      contact.emailAddressList.forEach(email => {
        uniqueEmailsMap.set(email.emailAddressId, email);
      });
      contact.emailAddressList = [...uniqueEmailsMap.values()];

      if(this.contactService.activeConsents.find(c=>c.contactid == contact.ID)){
        contact.hasActiveConsent=this.translate.instant('YES');
      }else{
        contact.hasActiveConsent= this.translate.instant('NO');
      }
      if (this.deltaService.deltaRecordsDTO.contacts.indexOf(contact.ID) != -1 && !contact.isguest) {
        this.newContacts.push(contact);
      }
    });
    this.contactService.updateNewContactStatus(this.newContacts);
    // console.log("selectedContacts", this.selectedContacts)
    if(!this.searchConfigService.isContactProfileDataMappedToContacts){
      this.contactService.mapContactProfileOfflineDataToContacts();
      this.searchConfigService.isContactProfileDataMappedToContacts = true;
    }
    if(!this.searchConfigService.isAllocationsMappedToContacts){
      this.mapAllocationsToContactsSearchConfig()
    }
    const isMappedConsentsToContactSearch: boolean = this.nonGuestContacts?.some(c=>c.hasActiveConsent == this.translate.instant("YES"));
    if(!this.searchConfigService.isConsentsMappedToContacts || !isMappedConsentsToContactSearch){
       this.mapConsentsToContactsSearchConfig()
    }
    if(!this.searchConfigService.isSAPActivitiesMappedToContacts){
      this.mapSAPActivitiesToContactsSearchConfig()
    }

    this.mapActivitiesToContactsSearchConfig()

    this.mapChildUsersWithPositionToContactsSearchConfig();

    if(this.searchConfigService.configUpdateRequired){
      this.searchConfigService.updateSearchConfigsForSelectedLanguage();
      this.searchConfigService.configUpdateRequired = false;
    }else {
      this.isContactConfigUpdateRequired = true;
    }
    this.disableSaveSearch = true;

    this.sortPopoverData = [
      {
        text: "",
        expanded: true,
        value: "fullName",
        items: [
          { text: this.translate.instant('NAME'), value: "fullName", asc: true },
          { text: this.translate.instant('LAST_MODIFIED_DATE'), value: "modifiedOn", asc: false }
        ],
        handler: (selectedItem, item) => {
          item.value = selectedItem.value;
          this.sortBy = selectedItem;
          //this.contactService.sortContactsBySelectedSortOption(this.sortBy.value, this.sortBy.asc);
          this.sortContactsListBasedOnSelection(selectedItem)
        }
      }
    ];
    if (this.uiService.isConsentFromToolDrawer) {
      this.sortPopoverData[0].items.push({ text: this.translate.instant('DATE_CONSENTED'), value: "lastConsentedDate", asc: false })
      this.sortPopoverData[0].items.push({ text: this.translate.instant('CONSENT_EXPIRY_DATE'), value: "consentExpiryDate", asc: true })
    } else {
      this.sortPopoverData[0].items.push({ text: this.translate.instant('LAST_INTERACTION'), value: "interactionDate", asc: false });
      this.sortPopoverData[0].items.push({ text: this.translate.instant('NO_INTERACTION'), value: "createdOn", asc: false });
    }
    if (this.contactService.contactPageMode == ComponentViewMode.SELECTION && this.contactService.accessedContactListFrom == PageName.SurveyDetailsComponent) {
      this.sortPopoverData[0].items.push({ text: this.translate.instant('SURVEY_ELIGIBLE'), value: "surveyEligible", asc: false });
    }
    this.sortBy = this.sortPopoverData[0].items[0];
    this.sortContactsListBasedOnSelection(this.sortBy);
    if(this.selectedCustomerId) {
      let customer = _.find(this.nonGuestContacts, {ID: this.selectedCustomerId});
      if(customer) {
        setTimeout(() => {
          this.highlightContact(null, customer);
        }, 1000);
      }
    }
    this.IcontactCRsSubscription = this.mdmService.contactCRsObs$.subscribe((data: any[]) => {
      if (data.length) {
        this.contactCRs = data.filter(cr => cr.statuscode != '548910007');
        if(!this.isFilteredContactCRs) this.filteredContactCRs = [...this.contactCRs];
        this._cd.detectChanges();
      }
    });
    if ( this.contactService.contactPageMode != ComponentViewMode.READONLY &&
      this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT) && this.territoryService.currentListPeriod && this.territoryService.currentListPeriod.tagLabel) {
      this.hasTerritoryFAEnabled = true;
      this.handleListPeriodTagSelection(this.territoryService.currentListPeriod.tagLabel);
    }
    this.updateEmptyMessage();
    this._initAllSectionHeaderModel();
    this._initFilterPopoverData();
    this.events.observe('device:deviceIsOffline').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((status) => {
        this.isDeviceOnline = false;
        this.updateDoneButtonStatusOnDeviceStatus();
      });

    this.events.observe('device:deviceIsOnline').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((status) => {
        if (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState) {
          this.isDeviceOnline = false;
        }
        else {
          this.isDeviceOnline = true;
        }
        this.updateDoneButtonStatusOnDeviceStatus();
      });
      this.getEventRegistrationStatus()
}

  getEventRegistrationStatus() {
    let eventActivity = this.eventsToolService.selectedEventOnEventsTool;
    if(!_.isEmpty(eventActivity)) {
      for (let contact of this.selectedContacts) {
        let eventParticipant = eventActivity.participants?.find(participant => participant.id === contact.ID);
        if (eventParticipant) {
          if (eventParticipant.indskr_reasons == EventRegistrationStatus.Draft && eventActivity.attendeeFormat == AttendeeFormat.propose && _.isEmpty(eventActivity.attendeeFormat)) {
            this.isDisabledSubmitAll = false;
          } else {
            this.isDisabledSubmitAll = true;
          }
        }
      }
    }
  }

  private updateDoneButtonStatusOnDeviceStatus() {
    if (this.contactService.isSchedulerInvoked)
      this.indHeaderLeftModel.controls.find(c => c.id === 'done').isDisabled = !this.isDeviceOnline;
  }

  _initFilterPopoverData() {
    let contactSourceTypeOptions = [];
    if(this.mdmService.sourceTypeOptionSet && this.mdmService.sourceTypeOptionSet.length){
      if(this.mdmService.sourceTypeOptionSet[0]){
        contactSourceTypeOptions.push({ text: this.mdmService.translateSourceTypeOptionSet(this.mdmService.sourceTypeOptionSet[0].label), value: this.mdmService.sourceTypeOptionSet[0].value })
      }
      if(this.mdmService.sourceTypeOptionSet[1]){
        contactSourceTypeOptions.push({ text: this.mdmService.translateSourceTypeOptionSet(this.mdmService.sourceTypeOptionSet[1].label), value: this.mdmService.sourceTypeOptionSet[1].value })
      }
    }else {
      contactSourceTypeOptions =[
        { text: this.translate.instant('OMNIPRESENCE'), value: BusinessProcessType.Omnipresence},
        { text: this.translate.instant('ONE_KEY'), value: BusinessProcessType.OneKey }
      ];
    }
    this.filterPopoverData = [
      {
        text: '',
        value: '',
        items: [
          { text: this.translate.instant('ALL_CHANGE_REQUESTS'), value: '0' }
        ],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          });
          item.value = selectedItem.value;
          this.clearFilters();
          this.updateEmptyMessage();
        }
      },
      {
        text: this.translate.instant('STATUS'),
        value: '',
        items: [
          // { text: this.translate.instant('OPEN'), value: ChangeRequestStatusCode.OPEN },
          { text: this.translate.instant('IN_PROCESS'), value: ChangeRequestStatusCode.IN_PROGRESS },
          { text: this.translate.instant('SUBMITTED'), value: ChangeRequestStatusCode.SUBMITTED },
          { text: this.translate.instant('PENDING_APPROVAL'), value: ChangeRequestStatusCode.PENDING_APPROVAL },
          { text: this.translate.instant('APPROVED'), value: ChangeRequestStatusCode.APPROVED },
          { text: this.translate.instant('REJECTED'), value: ChangeRequestStatusCode.REJECTED }
        ],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = ''
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          if (item.value != '') {
            this.changeReqStatusFilter = item.value;
            this.filterByStatus(item.value);
          }
          this.updateEmptyMessage();
        }
      },
      {
        text: this.translate.instant('REQUEST_TYPE'),
        value: '',
        items: [
          { text: this.translate.instant('CONTACT_CR_NEW'), value: MDMRequestType.CREATE },
          { text: this.translate.instant('CONTACT_CR_UPDATE'), value: MDMRequestType.UPDATE },
          { text: this.translate.instant('ADD_AFFILIATION'), value: MDMRequestType.ADD_AFFILIATION }
        ],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          if (item.value != '') {
            this.changeReqTypeFilter = item.value;
            this.filterByRequestType(item.value);
          }
          this.updateEmptyMessage();
        }
      },
      {
        text: this.translate.instant('SOURCE_TYPE'),
        value: '',
        items: contactSourceTypeOptions,
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? '' : selectedItem.value;
          if (item.value != '') {
            this.changeReqSourceFilter = item.value;
            this.filterBySource(item.value);
          }
          this.updateEmptyMessage();
        }
      },
    ];
    this.filterPopoverData[0].value = '0';
  }

  private clearFilters() {
    this.filtering = false;
    this.selectedFilterData = [];
    this.filterNameBubble = [];
    this.selectedDateFromFilter = {startDate: '', endDate: ''};
    this.contactService.selectedActivitiesFromContactFilter = [];
    this.contactService.selectedSubOptionsFromContactFilter = { meetingStatus: [] };
    if (this.currentTab == ContactsModel.CHANGE_REQUESTS_TAB) {
      this.filteredContactCRs = this.contactCRs;
      this.isFilteredContactCRs = false;
      this.changeReqStatusFilter = null;
      this.changeReqTypeFilter = null;
      this.changeReqSourceFilter = null;
    } else {
      if(this.selectedSuggestionsData.length == 0) {
        this.removeAllSelectedSuggestions();
      } else {
        this.searchContactsList();
      }
      this.sortContactsListBasedOnSelection(this.sortBy);
    }
    this._initAllSectionHeaderModel();
  }

  private filterContactCR(selected) {
    this.isFilteredContactCRs = true;
    this.filteredContactCRs = this.contactCRs;
    if(!_.isEmpty(this.contactCRs)) {
      if(!_.isEmpty(selected['status'])) {
        this.filteredContactCRs = this.contactCRs.filter(contactCR => selected['status'].includes(contactCR.statuscode));
      }
      if(!_.isEmpty(selected['requestType'])) {
        this.filteredContactCRs = this.filteredContactCRs.filter(contactCR => selected['requestType'].includes(contactCR.indskr_mdmrequesttype));
      }
      if(!_.isEmpty(selected['resourceType'])) {
        this.filteredContactCRs = this.filteredContactCRs.filter(contactCR => selected['resourceType'].includes(contactCR.indskr_contactsourcetype));
      }
    }
    this._initAllSectionHeaderModel();
  }

  private filterByRequestType(type: number) {
    if (!_.isEmpty(this.contactCRs)) {
      if (this.changeReqStatusFilter != null && this.changeReqSourceFilter != null) {
        this.filteredContactCRs = this.contactCRs.filter(contactCR => contactCR.statuscode == this.changeReqStatusFilter && contactCR.indskr_mdmrequesttype == type && contactCR.indskr_contactsourcetype == this.changeReqSourceFilter);
      }else if (this.changeReqStatusFilter != null) {
        this.filterChangeReqByStatusAndType(type, this.changeReqStatusFilter);
      } else if (this.changeReqSourceFilter != null) {
        this.filteredContactCRs = this.contactCRs.filter(contactCR => contactCR.indskr_mdmrequesttype == type && contactCR.indskr_contactsourcetype == this.changeReqSourceFilter);
      }else {
        this.filteredContactCRs = this.contactCRs.filter(contactCR => contactCR.indskr_mdmrequesttype == type);
      }
    } else {
      this.filteredContactCRs = [];
    }
    this._initAllSectionHeaderModel();
  }

  private filterByStatus(status: number) {
    if (!_.isEmpty(this.contactCRs)) {
      if (this.changeReqTypeFilter != null && this.changeReqSourceFilter != null) {
        this.filteredContactCRs = this.contactCRs.filter(contactCR => contactCR.statuscode == status && contactCR.indskr_mdmrequesttype == this.changeReqTypeFilter && contactCR.indskr_contactsourcetype == this.changeReqSourceFilter);
      } else if (this.changeReqTypeFilter != null) {
        this.filterChangeReqByStatusAndType(this.changeReqTypeFilter, status);
      } else if (this.changeReqSourceFilter != null) {
        this.filteredContactCRs = this.contactCRs.filter(contactCR => contactCR.statuscode == status && contactCR.indskr_contactsourcetype == this.changeReqSourceFilter);
      }else {
        this.filteredContactCRs = this.contactCRs.filter(contactCR => contactCR.statuscode == status);
      }
    } else {
      this.filteredContactCRs = [];
    }
    this._initAllSectionHeaderModel();
  }

  private filterBySource(source:number){
    if (!_.isEmpty(this.contactCRs)) {
      if (this.changeReqTypeFilter != null && this.changeReqStatusFilter != null) {
        this.filteredContactCRs = this.contactCRs.filter(contactCR => contactCR.statuscode == this.changeReqStatusFilter && contactCR.indskr_mdmrequesttype == this.changeReqTypeFilter && contactCR.indskr_contactsourcetype == source);
      } else if(this.changeReqTypeFilter != null){
        this.filteredContactCRs = this.contactCRs.filter(contactCR => contactCR.indskr_mdmrequesttype == this.changeReqTypeFilter && contactCR.indskr_contactsourcetype == source);
      }else if(this.changeReqStatusFilter != null){
        this.filteredContactCRs = this.contactCRs.filter(contactCR => contactCR.statuscode == this.changeReqStatusFilter && contactCR.indskr_contactsourcetype == source);
      }else {
        this.filteredContactCRs = this.contactCRs.filter(contactCR => contactCR.indskr_contactsourcetype == source);
      }
    } else {
      this.filteredContactCRs = [];
    }
    this._initAllSectionHeaderModel();
  }

  private filterChangeReqByStatusAndType(type: number, status: number) {
    this.filteredContactCRs = this.contactCRs.filter(contactCR => contactCR.statuscode == status && contactCR.indskr_mdmrequesttype == type);
  }

  showVectorImage(): boolean {
    if(this.contactsModel === ContactsModel.MY_CONTACTS_TAB) {
      return this.contactsToDisplay.length === 0 && !this.selectedContactsFromTag?.length
    } else {
      return this.contactCRs.length === 0 && !this.selectedContactsFromTag?.length
    }
  }

  //Disable the search bar

  // disableSearchBar() {
  //   const inputs: any = document.getElementById("contact-seachbar").getElementsByTagName("INPUT");
  //   inputs[0].disabled =
  // }

  /**
   * this function updates the message on nothing selected page
   */
  updateEmptyMessage(){
    let dataSize = 0
    if (this.currentTab == ContactsModel.CHANGE_REQUESTS_TAB) {
      dataSize = this.filteredContactCRs.length;
    }
    else if(!this.searching && (this.selectedContacts.length > 0 || this.newContacts.length >0 ||
      this.affiliatedContacts.length || this.selectedGlobalSearchContact ||
      this.nonGuestContacts.length >0)){
        dataSize = 1
      }
      else{
        if(this.isDirty){

          if(this.radioSelectedContact){
            if((this.contactService.accessedContactListFrom == PageName.CustomerInsightComponent ||
              this.contactService.accessedContactListFrom == PageName.FollowUpActionDetailComponent ||
              this.contactService.accessedContactListFrom == PageName.KOLDetailsComponent ||
              this.contactService.accessedContactListFrom == PageName.ContactMedicalInsightComponent ||
              this.contactService.accessedContactListFrom == PageName.PhoneCallDetailsComponent) &&
              this.radioSelectedContact.isRadioSelected ){
                dataSize = 1
            }
            else if(this.radioSelectedContact.isSelected){
              dataSize = 1
            }
          }

        }
        else if(this.slicedSearchedContactsToDisplay && this.slicedSearchedContactsToDisplay.length > 0 ||
          this.selectedContacts && this.selectedContacts.length > 0
          || this.radioSelectedContact){
          dataSize = 1
        }

      }
      this.events.publish('showContactRHSEmptyPage', dataSize > 0? true:false)
  }

  async checkContactModel(value: ContactsModel) {
    if (this.currentTab === value) return;
    if (this.uiService.contactDetailsSegment == "customer-assess" && this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) return;
    this.currentTab = value;
    if (this.currentTab == ContactsModel.CHANGE_REQUESTS_TAB) {
      this.enableChnageRequestFlags();
    } else if (this.currentTab == ContactsModel.APPROVALS_TAB) {
      await this.fetchDCRApprovalActivities();
    } else {
      this.enableMyContactsFlag();
    }
    this.removeSelectedSugestionsandUpdateEmptyMessage();
    this.removeSelectedFiltersandUpdateEmptyMessage();
    this.contactService.selectedContactCr = '';
    this.contactService.selectedDCRApprovalReq = null;
  }

  async checkComponentModel(value: string) {
    if (this.currentComponent === value) return;
    this.currentTab = value;
    if(this.contactService.accessedContactListFrom == PageName.OpportunityDetailsComponent || this.contactService.accessedContactListFrom == PageName.NewOpportunityComponent) {
      let listmode;
      const opportunityType = this.opportunityService.opportunityTypes.find(opp => opp.indskr_opportunitytypeid == this.contactListData?.opportunity?.opportunityTypeId);
      if (opportunityType.singlestakeholder && opportunityType.singlestakeholder === true) {
        this.accountService.accountPageMode = ComponentViewMode.SELECTION
        listmode = ComponentViewMode.SELECTION;
      } else {
        this.accountService.accountPageMode = ComponentViewMode.ADDNEW
        listmode = ComponentViewMode.ADDNEW;
      }
      this.accountService.accessedAccountListFrom = this.contactService.accessedContactListFrom;
      let passedData = {
        type:'PushedContactSelectionView',
        accountListData:
        {
            for:'OpportunityStakeholderSelection',
            opportunity: this.contactListData && this.contactListData.opportunity
        },
        'listMode': listmode
      };
      // this.onCloseModal(false);
      this.navService.popWithPageTracking();
      this.contactService.accessedContactListFrom = this.accountService.accessedAccountListFrom;
      this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, passedData, PageName.OpportunityDetailsComponent, { animate: false }, null);
    }
    
  }

  async fetchDCRApprovalActivities() {
    this.uiService.displayLoader();
      this.contactDataService.getDCRApprovalRequestsOnline().then(succ => {
        this.updateItemsToDisplay();
        this.uiService.dismissLoader();
      }).catch(err => {
        this.uiService.dismissLoader();
      });
  }

  public updateItemsToDisplay() {
    this.filteredItemsList = this.contactService.approvableDCRRequests.filter((a) => {
      return (a.name.toLocaleLowerCase().toLowerCase().indexOf(this.contactSearchText.toLocaleLowerCase()) > -1 && a.approvalStatus == this.translate.instant('PENDING'));
    });
    this.filteredDCRApprovables = this.filteredItemsList.sort((a, b) => (b.createdOn.getTime()) - (a.createdOn.getTime())).map(item=>{
      return this.getMainCardDataModel(item);
    });
    this.updateEmptyMessage();
    this._initDCRApprovalsListHeader();
  }

  public getMainCardDataModel(item: DCRApprovalReq) {
    let viewData: MainCardViewDataModel;
    viewData = {
      id: item.ID,
      fixedHeight: true,
      primaryTextRight: item.name,
      secondaryTextRight: this.getFormattedDate(item.createdOn),
      secondaryTextRightTwo: item.isApprovalRecord?item.approvalStatus:item.statusString,
      secondaryTextRightThree: item.isApprovalRecord?item.ownerName:'',
      isSelected: item.ID === this.contactService.selectedDCRApprovalReq?.ID,
      showArrow: false,
      clickHandler: async (id: string, event, specificTarget,refData) => {
        await this.handleCardItemClick(id, event, specificTarget, refData)
      }
    };
    return viewData;
  }

  private async handleCardItemClick(id: string, event, specificTarget,refData){
    if(specificTarget === 'mainItem'){
      await this.openDCRReqDetails(id);
    }
  }

  private async openDCRReqDetails(id) {
    let foundDCRApprovalReq: DCRApprovalReq = this.contactService.approvableDCRRequests.find(a => a.ID == id);
    if (foundDCRApprovalReq) {
      foundDCRApprovalReq['indskr_contactcrid'] = foundDCRApprovalReq.ID;
      this.contactService.selectedDCRApprovalReq = foundDCRApprovalReq;
      this.filteredDCRApprovables.forEach(order => {
        order.isSelected = false;
        if (order.id === foundDCRApprovalReq.ID) order.isSelected = true;
      });
      await this.viewDetails(foundDCRApprovalReq);
    }
  }

  async viewDetails(selected) {
    let currentData;
    let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_contactcr", FormType.DISPLAYFORM, selected['indskr_mdm'] == BusinessProcessType.SanofiChina ? BusinessProcessType.SanofiChina : undefined);;
    if (!contactForm) {
      this.notificationService.notify(this.translate.instant('ONE_KEY_DISPLAY_FORM_NO_FORMS_ERR'), 'DynamicFormComponent', "top", ToastStyle.DANGER, 3000);
      this.uiService.dismissLoader();
      return;
    }
    if (this.device.isOffline) {
      return;
    } else {
      await this.uiService.displayLoader();
      try {
        let previousStatus = selected.statuscode;

        await this.mdmService.fetchContactCRConfiguredDisplay(false, false, selected).then((res) => {
          if (res && Array.isArray(res)) {
            currentData = res[0];
          } else {
            currentData = res;
          }
          currentData['attachment1'] = selected.indskr_attachment1 ? selected.indskr_attachment1 : undefined;
          currentData['attachment2'] = selected.indskr_attachment2 ? selected.indskr_attachment2 : undefined;
          console.log(currentData);
          this.uiService.dismissLoader();
        });
      } catch (e) {
        console.log("error occurred fetching contactCR online: ", e);
        await this.uiService.dismissLoader();
      }

    }
    this.mdmService.currentSelected.next(currentData);
    this.uiService.activeView = 'ContactPageRightPaneNav';
    this.uiService.showNewActivity = false;
    this.navService.setChildNavRightPaneView(true);
    await this.navService.setChildNavRoot(CrDetailsComponent, PageName.CrDetailsComponent, PageName.ContactPageComponent, {
      from: 'Contact',
      displayFormDetails: contactForm,
      approvalRequest: selected,
      dynamicFormType: DynamicFormType.CONFIGUREDFORM
    });
  }

  public getFormattedDate(value): string {
    return this.datePipe.transform(value, 'MMM dd yyyy', undefined, this.translate.currentLang);
  }

  private _initDCRApprovalsListHeader() {
    let title:string = 'ALL_APPROVALS';
    if(this.contactSearchText.length){
      title = 'RESULTS_CAP'
    }
    this.myDCRApprovableRequestsHeaderModel = {
      id: 'selected-header',
      title: this.translate.instant(title) + ' (' + this.filteredItemsList.length + ')',
      controls: [],
    };
  }

  private enableChnageRequestFlags() {
    this.hideFilterButton = false;
    this.disableSaveSearch = true;
    this.suggestionsActive = false;
    this.enableGlobalSearch = false;
    this.clearFilters();
    this._initFilterPopoverData();
  }

  private removeSelectedSugestionsandUpdateEmptyMessage() {
    this.removeAllSelectedSuggestions();
    this.updateEmptyMessage();
    setTimeout(() => {
      this.uiService.scrollListToView(this.list);
    }, 200);
  }

  private removeSelectedFiltersandUpdateEmptyMessage() {
    this.clearFilters();
    this.updateEmptyMessage();
    setTimeout(() => {
      this.uiService.scrollListToView(this.list);
    }, 200);
  }

  private enableMyContactsFlag() {
    this.hideFilterButton = false;
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CONTACTS_GLOBAL_SEARCH)) {
      this.enableGlobalSearch = true;
    }
    this.disableSaveSearch = false;
    this.suggestionsActive = true;
  }

  async openFilters(event) {
    const changeReqFilter = await this.popover.create({
      component: MultiSelectPopover, componentProps: { root: this.filterPopoverData },
      event: event
    });
    changeReqFilter.present();
    changeReqFilter.onDidDismiss().then(() => {
    });
  }

  async openFilterMenu(event) {
    if(this.isContactConfigUpdateRequired) {
      this.searchConfigService.updateSearchContactConfigsForSelectedLanguage();
      this.isContactConfigUpdateRequired = false;
    }
    if(this.currentTab == ContactsModel.CHANGE_REQUESTS_TAB) {
      this._initiateCRFilter();
    } else {
      this._initiateMyContactsFilter();
    }
  }

  private async _initiateCRFilter() {
    const filterMenuModal = await this.modalCtrl.create({
      component: IndFilterMenuComponent,
      componentProps: {
        viewData: {
          data: this.filterPopoverData,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('FILTERS'),
          isSearchEnabled: true,
          // isReadOnly: this.isReadOnlyView
        },
        selectedFilter: this.selectedFilterData,
        from: PageName.ContactPageComponent,
        subFrom: ContactsModel.CHANGE_REQUESTS_TAB
      },
      backdropDismiss: false
    });
    filterMenuModal.present();
    filterMenuModal.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          this.clearFilters();
          this.filtering = false;
        } else {
          this.selectedFilterData = [];
          this.filterNameBubble = [];
          this.filtering = true;
          let selectedValue:{ status: any[], requestType: any[], resourceType: any[]} = {status:[], requestType:[], resourceType:[]};

          data.selectedItems.forEach((selected) => {
            this.selectedFilterData.push(selected);
            if(selected.categoryPath == 'contactCR_status') selectedValue['status'].push(selected.value);
            else if(selected.categoryPath == 'contactCR_request_type') selectedValue['requestType'].push(selected.value);
            else if(selected.categoryPath == 'contactCR_source_type') selectedValue['resourceType'].push(selected.value);
          });
          this.selectedFilterData.forEach((data) => this._updateFilterNameBubble(data));
          this.filterContactCR(selectedValue)
          this.selectedFilterData.sort((a,b)=>{
            if(a.createdOn < b.createdOn) return 1
            else return -1
          })
        }
      }
    });
  }

  private _updateFilterNameBubble(item) {
    if(item.categoryName == 'Assessed' || item.categoryName == 'UnAssessed') {
      let idx = this.filterNameBubble?.findIndex((bubble) => bubble.categoryName == this.translate.instant('ASSESSMENTS'));
      if(idx>-1) this.filterNameBubble[idx].suggestion.push(item);
      else {
        let selected = { categoryName: this.translate.instant('ASSESSMENTS'), suggestion: [item], isSavedFilter: false, savedFilterName: '' };
        this.filterNameBubble.push(selected);
      }
    } else {
      let idx = this.filterNameBubble?.findIndex((bubble) => bubble.categoryName == item.categoryName);
      if(idx>-1) this.filterNameBubble[idx].suggestion.push(item);
      else {
        let selected = { categoryName: item.categoryName, suggestion: [item], isSavedFilter: false, savedFilterName: '' };
        if(item.isSavedFilter) {
          selected.isSavedFilter = true;
          selected.savedFilterName = item.savedFilterName;
        }
        this.filterNameBubble.push(selected);
      }
    }
  }

  private async _initiateMyContactsFilter() {
    // Add: if there is no search categories available return with the message
    let options;
    this.selectedFilterData.forEach((data)=> {
      if(data.configuredFrom == 'Assess') data.categoryPath = data.categoryName;
    })
    const filterMenuModal = await this.modalCtrl.create({
      component: IndFilterMenuComponent,
      componentProps: {
        viewData: {
          data: options,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('FILTERS'),
          isSearchEnabled: true,
          // isReadOnly: this.isReadOnlyView
          selectedDate: this.selectedDateFromFilter
        },
        selectedFilter: this.selectedFilterData,
        from: PageName.ContactPageComponent,
        subFrom: ContactsModel.MY_CONTACTS_TAB
      },
      backdropDismiss: false
    });
    filterMenuModal.present();
    filterMenuModal.onDidDismiss().then(async (obj: any) => {
      this.onMyContactsFilterDismiss(obj);
    });
  }

  private async onMyContactsFilterDismiss(obj: any) {
    if (obj && obj.data && obj.data.isDone) {
      let data = obj.data;
      if (_.isEmpty(data.selectedItems)) {
        this.clearFilters();
        this.filtering = false;
      } else {
        if (this.device.isOffline && data.selectedItems.some(item => item.configuredFrom == "Assess")) {
          this.notificationService.notify(this.translate.instant('ASSESSMENT_BASED_FILTER_ONLINE_ONLY'),  "Contact list", "top", ToastStyle.INFO, 3000);
          return;
        }
        if (this.contactService.accessedContactListFrom == PageName.ToolsDrawer && data.selectedItems.find(s => s.categoryName == 'Positions')) {
          await this.uiService.displayLoader();
          await this.contactService.fetchContactsForConfiguredDisplay(false, false, null, false, false, data.selectedItems[0].selectedFacet);
          this.nonGuestContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
          await this.uiService.dismissLoader();
        }
        this.selectedFilterData = [];
        this.filterNameBubble = [];
        this.filtering = true;
        /******* filtered survey list by duration *******/
        const isFilteredByDate: boolean = !_.isEmpty(data.selectedDate) && !_.isEmpty(data.selectedDate.startDate) && !_.isEmpty(data.selectedDate.endDate) || false;
        if (isFilteredByDate) {
          this.selectedDateFromFilter = {
            startDate: data.selectedDate.startDate,
            endDate: data.selectedDate.endDate,
          };
        } else {
          this.selectedDateFromFilter = { startDate: '', endDate: '' };
        }
        // this.filterSurveyList(selectedValue, isFilteredByDate);
        data.selectedItems.forEach((selected) => {
          if (selected.selectedFacet == this.translate.instant('ASSESSED'))
            this.selectedFilterData.push(this.getAssessmentPill());
          else if (selected.selectedFacet == this.translate.instant('UNASSESSED'))
            this.selectedFilterData.push(this.getUnAssessmentPill());

          // else this.selectedFilterData = this.searchConfigService.manageSelectedSuggestions(selected, this.selectedFilterData)
          else {
            if (!this.selectedFilterData.some(o => o.categoryName == selected.categoryName && o.selectedFacet == selected.selectedFacet))
              this.selectedFilterData.unshift(selected);
          }
        });
        this.contactService.selectedActivitiesFromContactFilter = [];
        this.contactService.selectedSubOptionsFromContactFilter = { meetingStatus: [] };
        this.selectedFilterData.forEach((data) => {
          this._updateFilterNameBubble(data);
          if (data.categoryPath == "myActivitiesByContact") {
            let convertedValue = this.contactService.convertOptionValueToActivityType(data.selectedFacet);
            this.contactService.selectedActivitiesFromContactFilter.push(convertedValue);
          } else if (data.categoryPath == "filterMeetingsStatus") {
            let selectedOption: number = -1;
            if (data.selectedFacet == this.translate.instant("COMPLETED")) {
              selectedOption = 3;
            } else if (data.selectedFacet == this.translate.instant("OPEN")) {
              selectedOption = 1;
            }
            if (selectedOption != -1)
              this.contactService.selectedSubOptionsFromContactFilter.meetingStatus.push(selectedOption);
          }
        });
        this.suggestionsActive = false;
        await this.searchContactsList(true);
        this.selectedFilterData.sort((a, b) => {
          if (a.createdOn < b.createdOn)
            return 1;
          else
            return -1;
        });
      }
    }
  }

  setTabsData() {
    const disableCR = !this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CONTACTS_CREATE_REQUEST) && !this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CONTACTS_EDIT_REQUEST) && !this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_CHANGE_REQUESTS);
    this.tabsData = [
      {
        displayText: (this.languageService.selectedLanguage.code == "ja" ? this.translate.instant('MY_STACKHOLDERS') : this.translate.instant('MY_CONTACTS', { globalCustomerText: this.globalCustomersText } )),
        value: ContactsModel.MY_CONTACTS_TAB,
        disable : !this.contactService.loadedContacts
      },
      {
        displayText: !disableCR ? this.translate.instant('CHANGE_REQUESTS') : '',
        value: ContactsModel.CHANGE_REQUESTS_TAB,
        disable: disableCR
      },
      {
        displayText: !disableCR ? this.translate.instant('APPROVALS') : '',
        value: ContactsModel.APPROVALS_TAB,
        disable: this.device.isOffline || !this.authenticationOfflineService.user?.buConfigs?.indskr_businessline || this.authenticationOfflineService.user?.buConfigs?.indskr_businessline !== BusinessLineVal.VACCINE
      },
    ];
  }

  setComponentTabsData() {
    switch(this.contactService.accessedContactListFrom) {
      case PageName.OpportunityDetailsComponent:
      case PageName.NewOpportunityComponent:
        this.tabsComponentData = [
          {
            displayText: this.translate.instant('CONTACT'),
            value: 'contact',
            disable : false
          },
          {
            displayText: this.translate.instant('ACCOUNT'),
            value: 'account',
            disable: false
          }
        ];
        break;
      default :
        this.tabsComponentData = [
          {
            displayText: this.translate.instant('CONTACT'),
            value: 'contact',
            disable : false
          }
        ];
        break;

    }
  }



  sortContactsListBasedOnSelection(selectedSortOption) {
    if(!selectedSortOption) return;
    if (!this.searching && !this.filtering) {
      if (this.contactService.contactPageMode === ComponentViewMode.SELECTION && (this.contactService.accessedContactListFrom != PageName.PhoneCallDetailsComponent)) {
        if (selectedSortOption.value === 'interactionDate' || selectedSortOption.value === 'createdOn') {
          this.selectionEligibleContacts = this.sortContactsOnInteraction(this.selectionEligibleContacts, selectedSortOption.value);
        } else if(selectedSortOption.value == 'surveyEligible') {
          this.selectionEligibleContacts = this.sortContactsOnSurveyEligibility(this.selectionEligibleContacts);
        } else if (selectedSortOption.value == 'fullName' && this.translate.currentLang == 'zh_CN') {
          this.selectionEligibleContacts = this.selectionEligibleContacts.sort((a, b) => a['fullname'].localeCompare(b['fullname'], ["zh-CN-u-co-pinyin"]));
        } else {
          this.selectionEligibleContacts = this.selectionEligibleContacts.sort((a, b) => {
            if ((selectedSortOption.asc && a[selectedSortOption.value] >= b[selectedSortOption.value]) || (!selectedSortOption.asc && b[selectedSortOption.value] >= a[selectedSortOption.value])) return 1;
            else return -1;
          })
        }
      } else {
        if (selectedSortOption.value === 'interactionDate' || selectedSortOption.value === 'createdOn') {
          this.nonGuestContacts = this.sortContactsOnInteraction(this.nonGuestContacts, selectedSortOption.value);
        } else if (selectedSortOption.value == 'fullName' && this.translate.currentLang == 'zh_CN') {
          this.nonGuestContacts = this.nonGuestContacts.sort((a, b) => a['fullname'].localeCompare(b['fullname'], ["zh-CN-u-co-pinyin"]));
        } else {
          this.nonGuestContacts = this.nonGuestContacts.sort((a, b) => {
            if ((selectedSortOption.asc && a[selectedSortOption.value] >= b[selectedSortOption.value]) || (!selectedSortOption.asc && b[selectedSortOption.value] >= a[selectedSortOption.value])) return 1;
            else return -1;
          })
        }
      }
      this.contactsToDisplay = this.sliceContacts(0, this.recordCount);
    }
    else {
      if (selectedSortOption.value === 'interactionDate' || selectedSortOption.value === 'createdOn') {
        this.searchedContactsToDisplay = this.sortContactsOnInteraction(this.searchedContactsToDisplay, selectedSortOption.value);
      } else if(selectedSortOption.value == 'surveyEligible') {
        this.searchedContactsToDisplay = this.sortContactsOnSurveyEligibility(this.searchedContactsToDisplay);
      } else if (selectedSortOption.value == 'fullName' && this.translate.currentLang == 'zh_CN') {
        this.searchedContactsToDisplay = this.searchedContactsToDisplay.sort((a, b) => a['fullname'].localeCompare(b['fullname'], ["zh-CN-u-co-pinyin"]));
      } else {
        this.searchedContactsToDisplay = this.searchedContactsToDisplay.sort((a, b) => {
          if ((selectedSortOption.asc && a[selectedSortOption.value] >= b[selectedSortOption.value]) || (!selectedSortOption.asc && b[selectedSortOption.value] >= a[selectedSortOption.value])) return 1;
          else return -1;
        })
      }
      if (this.searchRecordCount < 30) { this.searchRecordCount = 30; }
      this.slicedSearchedContactsToDisplay = this.sliceSearchedContacts(0, this.searchRecordCount);
    }
    this._initAllSectionHeaderModel();
    this._cd.detectChanges();
  }

  /**
   * sort contact list on interaction date or created date follwed by contact name
   * @param contacts list of contacts
   * @param sortOnvalue sorts on selected value
   */
  sortContactsOnInteraction(contacts: Contact[], sortOnvalue: string): Contact[] {
    let contactWithoutInteraction: Contact[] = [];
    let contactWithInteraction: Contact[] = [];
    if (contacts.length > 0 && sortOnvalue) {
      contacts.forEach(contact => {
        if (contact.interactionDate) {
          contactWithInteraction.push(contact);
        } else {
          contactWithoutInteraction.push(contact);
        }
      });

      contactWithInteraction.sort((a, b) => {
        let sort;
        if (sortOnvalue === 'createdOn') {
          sort = a.interactionDate - b.interactionDate;
        } else {
          sort = b.interactionDate - a.interactionDate;
        }
        if (sort === 0) {
          return (a.fullName > b.fullName) ? 1 : -1;
        }
        return sort;
      });
      contactWithoutInteraction.sort((a, b) => {
        let sort = b.createdOn - a.createdOn;
        if (sort === 0) {
          return (a.fullName > b.fullName) ? 1 : -1;
        }
        return sort;
      });
      if (sortOnvalue === 'createdOn') {
        return contactWithoutInteraction.concat(contactWithInteraction);
      } else if (sortOnvalue === 'interactionDate') {
        return contactWithInteraction.concat(contactWithoutInteraction);
      }
    }
  }

  /**
   * sort contact list on survey eligible follwed by contact name
   * @param contacts list of contacts
   */
  sortContactsOnSurveyEligibility(contacts: Contact[]): Contact[] {
    let contactNotEligible: Contact[] = [];
    let contactEligible: Contact[] = [];
    if (contacts.length > 0) {
      contacts.forEach(contact => {
        if (contact.isForcedHideAddIcon) {
          contactNotEligible.push(contact);
        } else {
          contactEligible.push(contact);
        }
      });

      contactEligible.sort((a, b) => {
        return (a.fullName > b.fullName) ? 1 : -1;
      });
      contactNotEligible.sort((a, b) => {
        return (a.fullName > b.fullName) ? 1 : -1;
      });
      return contactEligible.concat(contactNotEligible);
    }
  }

  async ngAfterViewInit() {
    this.loading = await this.loadingCtrl.create();
    this.loading.present();
    if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Appointment && !this.device.shouldBeMobileView) {
      if(this.activityService.selectedActivity['contacts'] && this.activityService.selectedActivity['contacts'].length > 0
        && (this.contactService.contactPageMode == ComponentViewMode.READONLY || this.contactService.contactPageMode == ComponentViewMode.ADDNEW)){
            this.highlightContact(null, this.selectedContacts[0]);
      }
    }
    if (this.contactService.isClickedGoToContactsTool && this.navParams && this.navParams.data && this.navParams.get('from') && this.navParams.get('from') === 'ContactTool') {
      this.contactService.isClickedGoToContactsTool = false;
      this.dynamicFormsService.isOpenedAffiliatedContactOnAccount = false;
      this.dynamicFormsService.isOpenedAffiliatedContactOnContact = false;
      this.fetchRealtimeDetailsFlag = false;
      if (this.navParams.get('openedToolFromCrDetails') && this.navParams.get('openedToolFromCrDetails') === true) {
        const selectedContactCrId = this.navParams.get('contactCrId') || '';
        if (selectedContactCrId && this.filteredContactCRs) {
          this.contactService.selectedContactCr = this.filteredContactCRs.find(cr=>cr.indskr_contactcrid == selectedContactCrId);
        }
        setTimeout(async () => {
          const approvalRequest = this.navParams.get('approvalRequest');
          if (approvalRequest != null) {
            this.contactsModel = ContactsModel.APPROVALS_TAB;
          } else {
            this.contactsModel = ContactsModel.CHANGE_REQUESTS_TAB;
          }
          await this.checkContactModel(this.contactsModel);
          if (this.contactsModel === ContactsModel.APPROVALS_TAB && approvalRequest) {
            this.contactService.selectedDCRApprovalReq = approvalRequest;
            this.openDCRReqDetails(approvalRequest.ID);
          }
        }, 0);
      } else {
        const curDisplayedContact = this.navParams.get('contactInformation') || null;
        this.isOpenedOnPreviewMode = true;
        if (!_.isEmpty(curDisplayedContact)) this.events.publish('highlightContact', curDisplayedContact);
      }
    }
  }

  ngOnDestroy() {
    this.events.unsubscribe("refreshSeletecContacts");
    this.events.unsubscribe("contact-content-update");
    this.events.unsubscribe("contact-key-message-sentiment-update");
    this.events.unsubscribe('contactsPageDidEnter');
    this.events.unsubscribe('notifyCCRIsDirty');
    this.events.unsubscribe('receivedSignal');
    this.events.unsubscribe("contacts:customForm");
    this.events.unsubscribe('closeMultiAssessPage');
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.sampleService.contactTimelineFilter = "All_Activities";  //bug cwd-3450
    this.contactService.isSchedulerInvoked = false;
    this.contactService.isCaseManagementInvoked = false;
    if (this.IUserTagDeletedSubscription) this.IcontactCRsSubscription.unsubscribe();
    this.contactService.selectedOKContact.next(null);
    this.contactService.isOneKeySearching = false;
    if (this.IUserTagDeletedSubscription) this.IUserTagDeletedSubscription.unsubscribe();
    this._resetFlagOpenedAffiliatedData();
    this.contactService.selectedDCRApprovalReq = null;
  }

  async init() {
    this.isOpportunityStakeholderValueEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_STAKEHOLDER_VALUE);
    if (this.contactService.isSchedulerInvoked) {//Invoke Custom logic for Scheduler component without impacting the existing logic
      this.searching = false;
      this.filtering = false;
      this.isDirty = true;
      this.contactService.searchContacts = [];
      this.radioSelectedContact = undefined;

      this.nonGuestContacts = this.contactService.schedulerDisplayContacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);

      this.selectedContacts = this.activityService.selectedActivity['contacts'];

      this.isDirty = this.selectedContacts.length > 0 ? true : false; // This is to check in case of schedulers if no matched contacts make isDirty as false and through isDoneDisabled() to HTMl for disabling Done Button in case of no matched contact

      console.log("this.selectedContacts", this.selectedContacts)
      this.contactService.schedulerDisplayContacts.map(item => {
        item.isSelected = this.selectedContacts.some(o => o.ID === item.ID);
        item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
      });
      this.initContactsHeaderLeft();

      //this.content.resize();
    }
    else if (this.contactService.isCaseManagementInvoked) {
      this.searching = false;
      this.filtering = false;
      this.contactService.searchContacts = [];
      if (this.contactService.contactInformation) this.listViewContact = this.contactService.contactInformation;

      this.contactService.contactInformation = undefined;

      this.nonGuestContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
      if(!_.isEmpty(this.caseManagementService.currentCase)) {
        if(!_.isEmpty(this.caseManagementService.currentCase._case_contact)) {
          this.selectedContacts = new Array(1).fill(this.caseManagementService.currentCase._case_contact);
        }else {
          this.selectedContacts = [];
        }
      }
      this.radioSelectedContact = this.caseManagementService.currentCase._case_contact;

      this.contactService.contacts.map(item => {
        item.isSelected = this.selectedContacts.some(o => o.ID === item.ID);
        item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
      });

      //this.content.resize();
    } else if (this.contactService.accessedContactListFrom == PageName.OpportunityDetailsComponent || this.contactService.accessedContactListFrom == PageName.NewOpportunityComponent) {
      this.searching = false;
      this.filtering = false;
      this.contactService.searchContacts = [];
      this.contactService.contactInformation = undefined;
      if (this.contactListData && (this.contactListData.for == 'OpportunityStakeholderSelection' || this.contactListData.for == 'NewOpportunityContactSelection') && this.contactListData.opportunity) {
        if((this.contactListData.opportunity as Opportunity).accountID){
          this.nonGuestContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
          let accounts:Account[] = [];
          let foundAcc = this.accountService.getAccountById((this.contactListData.opportunity as Opportunity).accountID);
          if (foundAcc){
            accounts.push(foundAcc);
          }
          if (accounts.length != 0) {
            this.affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(accounts);
          }
          await this.autoSelectAccountAffFilter(accounts);
          // let affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([this.accountService.getAccountById((this.contactListData.opportunity as Opportunity).accountID)]);
          // if (affiliatedContacts && affiliatedContacts.length > 0) {
          //   affiliatedContacts.forEach(affiliatedContact => {
          //     let foundContact = this.contactService.getContactByID(affiliatedContact.ID);
          //     if (foundContact) this.nonGuestContacts.push(foundContact);
          //   })
          //   this.uiService.showNewActivity = false;
          // }
        }else if(this.contactListData.for == 'NewOpportunityContactSelection'){
          this.nonGuestContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
        }
        
        if(this.contactListData.for == 'OpportunityStakeholderSelection'){
          this.isInvokedForOpportunityStakeholderSelection = true;
          this.opportunityStakeholderSelectionEnabled = true;
          this.contactService.contacts.map(e => {
            e.stakeholderRolesList = [];
            e.stakeholderValue = null;
            return e;
          });
          if ((this.contactListData.opportunity as Opportunity).stakeholders && (this.contactListData.opportunity as Opportunity).stakeholders.length > 0) {
            (this.contactListData.opportunity as Opportunity).stakeholders.forEach(stakeholder => {
              let foundContact = this.contactService.getContactByID(stakeholder.ID);
              if (foundContact) {
                if(this.isOpportunityStakeholderValueEnabled){
                  foundContact.stakeholderValue = stakeholder.stakeholderValue;
                }
                if(!foundContact.stakeholderRolesList.find(sh => sh.roleid === stakeholder.role.roleid)){
                  foundContact.stakeholderRolesList.push({roleid:stakeholder.role.roleid,name: stakeholder.role.name, isSelected: true});
                }
                let selContact = this.selectedContacts.find(con => con.ID === foundContact.ID);
                if(!selContact){
                  this.selectedContacts.push(foundContact);
                  this._initiallySelectedContacts.push(foundContact);
                }
            }
            })
            if (this.contactService.contactPageMode == ComponentViewMode.SELECTION) {
              this.radioSelectedContact = this.contactService.getContactByID((this.contactListData.opportunity as Opportunity).stakeholders[0].ID);
              this.contactService.contacts.map(item => {
                item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
              });
            }
          }
        } else if(this.contactListData.for == 'NewOpportunityContactSelection'){
          if ((this.contactListData.opportunity as Opportunity).contactID) {
            let foundContact = this.contactService.getContactByID((this.contactListData.opportunity as Opportunity).contactID);
            if (foundContact) {
              let selContact = this.selectedContacts.find(con => con.ID === foundContact.ID);
              if (!selContact) {
                this.selectedContacts.push(foundContact);
                this._initiallySelectedContacts.push(foundContact);
              }
              if (this.contactService.contactPageMode == ComponentViewMode.SELECTION) {
                this.radioSelectedContact = this.contactService.getContactByID((this.contactListData.opportunity as Opportunity).contactID);
                this.contactService.contacts.map(item => {
                  item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
                });
              }
            }
          }
        }
        
      }
      this.contactService.contacts.map(item => {
        item.isSelected = this.selectedContacts.some(o => o.ID === item.ID);
        item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
      });
      if (this.contactService.contactPageMode == ComponentViewMode.SELECTION) {
        this.selectionEligibleContacts = this.nonGuestContacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
        this.selectionEligibleContactsLength = this.selectionEligibleContacts.length;
      }
    } else if (this.contactService.accessedContactListFrom == PageName.AccountPlanDetailsComponent || this.contactService.accessedContactListFrom == PageName.MarketingPlanDetails) {
      this.searching = false;
      this.filtering = false;
      this.contactService.searchContacts = [];
      this.contactService.contactInformation = undefined;
      let selectedAccountPayload = this.selectedAccountIds.map(id => {
        return { id }
      })
      let affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(selectedAccountPayload);
      if (affiliatedContacts && affiliatedContacts.length > 0) {
        affiliatedContacts.forEach(affiliatedContact => {
          // let foundContact = this.contactService.getContactByID(affiliatedContact.ID);
          if (affiliatedContact && affiliatedContact.isActive && !affiliatedContact.isguest) {
            this.nonGuestContacts.push(affiliatedContact);
            if (this.selectedContactIds.includes(affiliatedContact.ID)){
              this.selectedContacts.push(affiliatedContact);
            }
            if (!this.device.isMobileDevice) {
              this.highlightContact(null, this.selectedContacts[0]);
            }
          }
        })
        this.uiService.showNewActivity = false;
      }

      if (this.contactService.accessedContactListFrom == PageName.MarketingPlanDetails) {
        let currentMarketingBusinessPlan = this.marketingManagementOfflineService.selectedMarketingPlan$.getValue();
        this.selectedContactIds.forEach(contactId => {
          let foundContact = this.contactService.getContactByID(contactId);
          if (!foundContact) {
            let contact = currentMarketingBusinessPlan.contacts.find((contact) => contact.contactId === contactId);
            let temp = { ID: contact.contactId, firstName: contact.contactFullName } as Contact;
            this.selectedContacts.push(temp);
          } else if (foundContact && !this.selectedContacts.some((selectedContact) => selectedContact.ID === foundContact.ID)) {
            this.selectedContacts.push(foundContact)
          }
        });
      }

      this.contactService.contacts.map(item => item.isSelected = this.selectedContactIds.includes(item.ID));
    }
    else {
      this.searching = false;
      this.filtering = false;
      this.contactService.searchContacts = [];
      this.radioSelectedContact = undefined;
      const email: EmailActivity = this.emailService.selectedActivity;

    if (email && this.contactService.accessedContactListFrom === PageName.EmailDetailsPageComponent) {
      if (email.isMessageExternal && email.channelActivityType == ChannelActivityType.SMS && email?.isPreviewEnabled) {
        this.selectedContactsForNonNativeChannelSMS = true;
      } else this.selectedContactsForNonNativeChannelSMS = false;
      if (email.isMessageExternal && email.channelActivityType == ChannelActivityType.WHATSAPP && email?.isPreviewEnabled) {
        this.selectedContactsForNonNativeChannelWhatsApp = true;
      } else this.selectedContactsForNonNativeChannelWhatsApp = false;
    }
      if (email && this.contactService.accessedContactListFrom === PageName.EmailDetailsPageComponent
        && this.contactService.contactPageMode === ComponentViewMode.ADDNEW) {
          this.fetchRealtimeDetailsFlag = false;
          if(email.indskr_channelid){
            this.selectedEmailChannel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === email.indskr_channelid);
          }else if(email.channelActivityType){
            this.selectedEmailChannel = this.consentService.savedChannels.find(sch => sch.activityType === email.channelActivityType);
          }
          this.events.subscribe(EventName.FINISHEDSERVICEWORKERMAPPING,()=>{
            if (this.contactService.accessedContactListFrom === PageName.EmailDetailsPageComponent) {
              if (this.emailService.selectedActivity && this.selectedContacts.length) {
                //this.selectedContacts = (this.contactService.getContactsByEmailAddressId(email));
                this.selectedContacts.forEach(contact => {
                  this.validateConsentForContact(contact);
                });
              }
            }
          });
        // Check if template is selected
        // let productId: string | null | undefined = undefined;
        // if (email.template_id) {
        //   productId = this.emailService.getProductIdFromTemplate(email.template_id);
        // }

        this.nonGuestContacts = this.contactService.contacts.filter(contact => {
          if (contact.isguest === false && contact.isActive && contact.indskr_iseventparticipant != true) {
            // contact.isSelectable = this.consentService.doesContactHaveConsentForChannel(contact, email.channelType, email.scheduledStart, productId).hasConsented;
            return true;
          }
          return false;
        });
        //this.nonGuestContacts = await this.contactService.fetchEmailsFromDBAndMapToContacts(this.nonGuestContacts);


      } else {

        if (this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent) {
          let eventsId = this.contactService.eventsId;
          this.nonGuestContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && (item.msevtmgt_eventid == eventsId || item.msevtmgt_eventid == ''));
        }
        else {
          this.nonGuestContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
        }
      }

      if (this.navService.getPreviousPageName() == PageName.ContactPageComponent && this.contactService.contactInformation) {
        this.holdContactInformation = this.contactService.contactInformation;
        this.contactService.contactInformation = null;
        this.selectedContacts = [];
      } else if (this.navService.getPreviousPageName() == PageName.PresentationMeetingComponent
                && (this.contactService.accessedContactListFrom == PageName.PresentationMeetingComponent
                    || this.contactService.accessedContactListFrom == PageName.NewActivityComponent)
                && this.contactService.contactInformation) {
        this.holdContactInformation = this.contactService.contactInformation;
        this.contactService.contactInformation = null;
        this.selectedContacts = [];
      } else if (this.contactService.contactPageMode == ComponentViewMode.SELECTION
        && (this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_MEETING ||
          this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_EVENTS
          || this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION)
          && this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent && this.contactService.contactInformation) {
        this.holdContactInformation = this.contactService.contactInformation;
        this.contactService.contactInformation = null;
        this.selectedContacts = [];
      } else if (email && this.emailService.viewType == EmailViewType.CREATE_FROM_MEETING && this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent && this.contactService.contactInformation) {
        this.holdContactInformation = this.contactService.contactInformation;
      }
      if (this.navService.getPreviousPageName() != PageName.ConsentDetailsComponent && this.contactService.contactInformation && this.uiService.activitiesPageTab !== 'plans') {
        this.holdContactInformation = this.contactService.contactInformation;
        this.contactService.contactInformation = null;
      }
      if (email && this.contactService.accessedContactListFrom === PageName.EmailDetailsPageComponent) {
        if (email.emailActivityParties && email.emailActivityParties.length) {
          this.selectedContacts = (this.contactService.getContactsByEmailAddressId(email));
          this.selectedContacts.forEach(contact => {
            this.validateConsentForContact(contact);
          });
          if (this.selectedContactsForNonNativeChannelSMS && this.selectedContacts && this.selectedContacts.length > 0) {
            this.mobileNumberListforShareMessage = [];
            if  (this.selectedContacts[0].mobilePhone || this.selectedContacts[0].telephone || this.selectedContacts[0].indskr_alternatephone1 || this.selectedContacts[0].indskr_alternatephone2 || this.selectedContacts[0].indskr_alternatephone3){
              this.mobileNumberListforShareMessage.push({'phoneNumber': this.selectedContacts[0].mobilePhone ? this.selectedContacts[0].mobilePhone : '', 'identity': 'mobilePhone'})
              this.mobileNumberListforShareMessage.push({'phoneNumber': this.selectedContacts[0].indskr_alternatephone1 ? this.selectedContacts[0].indskr_alternatephone1 : '', 'identity': 'alternatephone1'})
              this.mobileNumberListforShareMessage.push({'phoneNumber': this.selectedContacts[0].indskr_alternatephone2 ? this.selectedContacts[0].indskr_alternatephone2 : '', 'identity': 'alternatephone2'})
              this.mobileNumberListforShareMessage.push({'phoneNumber': this.selectedContacts[0].indskr_alternatephone3 ? this.selectedContacts[0].indskr_alternatephone3 : '', 'identity': 'alternatephone3'})
              this.mobileNumberListforShareMessage.push({'phoneNumber': this.selectedContacts[0].telephone ? this.selectedContacts[0].telephone : '', 'identity': 'telephone'})
            }
            if (email.emailActivityParties[0].contact_mobilephone){
                this.mobileNumberListforShareMessage.forEach(item =>{
                  if (email.emailActivityParties[0].contact_mobilephone.includes(item.phoneNumber) && !_.isEmpty(item.phoneNumber)) {
                    item.isSelected = true;
                  }
                });
              }
            this.selectedMobileNumberListforShareMessage = [];
            this.selectedMobileNumberListforShareMessage = this.mobileNumberListforShareMessage.filter(phoneNumber => phoneNumber.isSelected);
          }
        } else {
          this.selectedContacts = [];
        };
      } else if (this.contactService.accessedContactListFrom == PageName.PhoneCallDetailsComponent){
        this.selectedContacts = (this.activityService.selectedActivity && this.activityService.selectedActivity.type === ActivityType.PhoneCall) ? this.activityService.selectedActivity['contacts'].slice() : [];
      } else if (this.contactService.accessedContactListFrom === PageName.MarketScanDetailsComponent) {
        setTimeout(() => {
          const marketScan = this.marketScanService.getSelectedMarketScan();
          this.isMarketScanContactAndAccount = marketScan.category.indskr_capturedatafor === 548910002;
          if (marketScan) {
            const selectedContactIds = [];
            if (marketScan.contacts && marketScan.contacts.length) {
              marketScan.contacts.forEach(contact => {
                if (contact.action != Action.DELETE) {
                  let _contact: Contact = this.contactService.getContactByID(contact.contactId);
                  if (!_contact) {
                    _contact = { firstName: contact.contact_fullName, ID: contact.contactId } as Contact;
                  }
                  _contact['sequence'] = contact.sequence;
                  selectedContactIds.push(_contact.ID);
                  this.selectedContacts.push(_contact);
                }
              });
            }
            if (this.contactService.contactPageMode === ComponentViewMode.ADDNEW && marketScan.segmentedContacts && marketScan.segmentedContacts.length) {
              marketScan.segmentedContacts.forEach(contact => {
                if (contact.action != Action.DELETE) {
                  let _contact: Contact = this.contactService.getContactByID(contact.contactId);
                  if (!_contact) {
                    _contact = { firstName: contact.contact_fullName, ID: contact.contactId } as Contact;
                  }
                  _contact.isSelected = selectedContactIds.includes(_contact.ID);
                  if (this.isAllSegmentedContactSelected)
                    this.isAllSegmentedContactSelected = selectedContactIds.includes(_contact.ID);
                  this.segmentedContacts.push(_contact);
                }
              });
            }
            this.contactService.contacts.map(item => {
              item.isSelected = this.selectedContacts.some(o => o.ID === item.ID);
              item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
            });
          }
          this._initAllSectionHeaderModel();
          this.checkEnableDisableSelectAllOption();
        }, 1);
      } else {
        this.selectedContacts = (this.activityService.selectedActivity && this.activityService.selectedActivity.type === ActivityType.Appointment && this.activityService.selectedActivity['contacts']) ? _.cloneDeep(this.activityService.selectedActivity['contacts'].slice()) : [];
        if(!_.isEmpty(this.selectedContacts)) {
          this.selectedContacts = _.uniqBy(this.selectedContacts, 'ID');
        }
      }
      if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Sample) {
        if (this.activityService.selectedActivity instanceof SampleActivity) {
          this.radioSelectedContact = this.contactService.getContactByID(this.activityService.selectedActivity.contactID);
        }
      }
      else if (this.contactService.contactPageMode == ComponentViewMode.SELECTION
        && (this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_MEETING ||
          this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_EVENTS
          || this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION)) {
        this.radioSelectedContact =
          this.contactService.getContactByID(
            this.sampleService.inMeetingAllocationActivity.contactID
          );
      } else if (this.contactService.contactPageMode == ComponentViewMode.SELECTION && (this.contactService.accessedContactListFrom == PageName.FollowUpActionDetailComponent || this.contactService.accessedContactListFrom == PageName.ScientificPlanFollowUpTask)) {
        if(this.followUpActivityDataService.inMeetingFollowupActionActivity && this.navParams && this.navParams.data && this.navParams.data.type && (this.navParams.data.type == 'InMeetingFollowupPushedContactSelectionView' || this.navParams.data.type == 'InPresentationFollowupPushedContactSelectionView')){
          this.radioSelectedContact = this.contactService.getContactByID(this.followUpActivityDataService.inMeetingFollowupActionActivity.contactId);
        }else if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.FollowUp) {
          this.radioSelectedContact = this.contactService.getContactByID((this.activityService.selectedActivity as FollowUpActivity).contactId);
        }
      }else if (this.contactService.contactPageMode == ComponentViewMode.SELECTION && this.contactService.accessedContactListFrom == PageName.KOLDetailsComponent) {
        if (this.contactListData && this.contactListData.for == 'KOLStatusContactSelection' && this.contactListData.kol) {
          this.radioSelectedContact = this.contactService.getContactByID((this.contactListData.kol as KOLStatus).contactId);
          this.contactService.contacts.map(item => {
            item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
          });
        }

        if(this.contactService.contactInformation){
          this.holdContactInformation = this.contactService.contactInformation;
        }

        this.contactService.contacts.map(item => {
          item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
        });
        if (this.contactService.contactPageMode == ComponentViewMode.SELECTION) {
          this.selectionEligibleContacts = this.nonGuestContacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
          this.selectionEligibleContactsLength = this.selectionEligibleContacts.length;
        }
      }else if (this.contactService.contactPageMode == ComponentViewMode.SELECTION && this.contactService.accessedContactListFrom == PageName.ContactMedicalInsightComponent) {
        if (this.contactListData && this.contactListData.for == 'MedicalInsightContactSelection' && this.contactListData.medicalInsight) {
          this.radioSelectedContact = this.contactService.getContactByID((this.contactListData.medicalInsight as ContactMedicalInsight).contactId);
          this.contactService.contacts.map(item => {
            item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
          });
        }

        if(this.contactService.contactInformation){
          this.holdContactInformation = this.contactService.contactInformation;
        }

        this.contactService.contacts.map(item => {
          item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
        });
        if (this.contactService.contactPageMode == ComponentViewMode.SELECTION) {
          this.selectionEligibleContacts = this.nonGuestContacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
          this.selectionEligibleContactsLength = this.selectionEligibleContacts.length;
        }
      }
      else if (this.contactService.contactPageMode == ComponentViewMode.SELECTION && this.contactService.accessedContactListFrom == PageName.DynamicFormComponent){
        this._holdDynamicFormIsDirtyTracker = this.dynamicFormsService.linkedEntityFormIsDirtyTracker;
        this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
        if (this.navParams && this.navParams.data && this.navParams.data.selectedContactId) {
          this.radioSelectedContact = this.contactService.getContactByID(this.navParams.data.selectedContactId);
        }
      } else if ((this.contactService.contactPageMode == ComponentViewMode.ADDNEW || this.contactService.contactPageMode == ComponentViewMode.READONLY) && (this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent
        || this.contactService.accessedContactListFrom == PageName.SurgeryOrderDetailsComponent || this.contactService.accessedContactListFrom === PageName.ProcedureTrackerComponent)) {
        this.restrictedSelectedContactsViewActive = true;
        this._holdDynamicFormIsDirtyTracker = this.dynamicFormsService.linkedEntityFormIsDirtyTracker;
        this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
        if (this.selectedContactIds) {
          let covisitorView = this.isCovisitorView;
          this.selectedContacts = [];
          this.selectedContactIds.forEach(stakeholder => {
            let foundContact = this.contactService.getContactByID(stakeholder.id);
            if (foundContact) {
              this.selectedContacts.push(foundContact);
              this._initiallySelectedContacts.push(foundContact);
              this.restrictedViewSelectedContacts.push({
                ID: foundContact.ID,
                customerId: foundContact.appId,
                title: foundContact.title,
                firstName: foundContact.firstName,
                middleName: foundContact.middleName,
                lastName: foundContact.lastName,
                fullname: foundContact.fullname,
                isActive: foundContact.isActive,
                statusCode: foundContact.statusCode,
                primaryAccount: foundContact.getPrimaryAccountRelationship,
                primarySpecialty: foundContact.primarySpecialtyName,
                ownerId: stakeholder.ownerId,
                secondaryInfo: foundContact.secondaryInfo,
                raw: foundContact.raw,
                primaryEmail:foundContact.raw.emailaddress1,
                isSelectable: (this.contactService.contactPageMode == ComponentViewMode.ADDNEW && !stakeholder.signatureCaptured &&  stakeholder.reason != 548910002)?((covisitorView)?stakeholder.ownerId == this.authenticationOfflineService.user.systemUserID:true):false,
              })
            }else{
              let con = new Contact({
                contactid: stakeholder.id,
                title: stakeholder.title,
                firstname: stakeholder.firstName ? stakeholder.firstName : stakeholder.name,
                middlename: stakeholder.middleName,
                lastname: stakeholder.lastName,
                fullname: stakeholder.name,
                statuscode: stakeholder.statecode,
                indskr_externalid: stakeholder.customerId,
              });
              this.selectedContacts.push(con);
              this._initiallySelectedContacts.push(con);
              this.restrictedViewSelectedContacts.push({
                ID: stakeholder.id,
                customerId: stakeholder.customerId,
                title: '',
                firstName: stakeholder.firstName ? stakeholder.firstName : stakeholder.name,
                middleName: stakeholder.middleName,
                lastName: stakeholder.lastName,
                fullname: stakeholder.name,
                primaryAccount: stakeholder.primaryAccount,
                primarySpecialty: stakeholder.primarySpecialty,
                isActive: stakeholder.statecode == 0,
                statusCode: stakeholder.statusCode,
                ownerId: stakeholder.ownerId,
                secondaryInfo: '',
                isSelectable: (this.contactService.contactPageMode == ComponentViewMode.ADDNEW && !stakeholder.signatureCaptured &&  stakeholder.reason != 548910002)?((covisitorView)?stakeholder.ownerId == this.authenticationOfflineService.user.systemUserID:true):false,
              })
            }
          })
        }
      }
      this.contactService.contacts.map(item => {
        item.isSelected = this.selectedContacts.some(o => o.ID === item.ID);
        item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
      });
      //this.content.resize();

      if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
        this.isAutoSubjectEnabled = true;
      }

      if (this.contactService.contactPageMode == ComponentViewMode.SELECTION) {
        switch (this.contactService.accessedContactListFrom) {
          case PageName.DynamicFormComponent:
            this.selectionModeForComponent = 'Dynamic-Form-Selection';
            if(this.contactService.isOneKeyAffiliatedToContact){
              this.selectionEligibleContacts = this.contactService.contacts.filter(item => item.mdmType == MDMType.ONEKEY && item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
            } else {
              this.selectionEligibleContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
            }
            break;
          case PageName.FollowUpActionDetailComponent:
            this.selectionModeForComponent = 'Follow-Up-Details-Selection';
            this.selectionEligibleContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
            this.uiService.showNewActivity = false;
            break;
          case PageName.KOLDetailsComponent:
            this.selectionModeForComponent = 'Kol-Status-Details-Selection';
            this.selectionEligibleContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
            this.uiService.showNewActivity = false;
            break;
          case PageName.ContactMedicalInsightComponent:
            this.selectionModeForComponent = 'Medical-Insight-Details-Selection';
            this.selectionEligibleContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
            this.uiService.showNewActivity = false;
            break;
          case PageName.ScientificPlanFollowUpTask:
            this.selectionModeForComponent = 'Sap-Follow-Up-Details-Selection';
            if ((this.activityService.selectedActivity as FollowUpActivity).scientificPlanId) {
              console.log((this.activityService.selectedActivity as FollowUpActivity).scientificPlanId);
              let sapID = (this.activityService.selectedActivity as FollowUpActivity).scientificPlanId;
              let selectedSap = this.sapService.currentAndFutureSap.find(x => x.ID == sapID);
              this.selectionEligibleContacts = this.filteredScientificTaskContacts;
            } else {
              this.selectionEligibleContacts = this.filteredScientificTaskContacts;
            }
            break;
          case PageName.CustomerInsightComponent:
            this.selectionModeForComponent = 'Customer-Insight-Selection';
            this.selectionMode = this.navParams.data && this.navParams.data.type
            const selectedContactId: string = this.navParams.data && this.navParams.data.contactId;
            if (selectedContactId) {
              this.radioSelectedContact = this.contactService.getContactByID(selectedContactId);
              this.contactService.contacts.map(item => {
                item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
              });
            }
            this.selectionEligibleContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
            break;
          case PageName.SurgeryOrderContractDetailsComponent:
            this.selectionModeForComponent = 'contract-selection';
            this.selectionMode = this.navParams.data && this.navParams.data.type
            const selectedId: string = this.navParams.data && this.navParams.data.selectedContactIds;
            if (selectedId && selectedId.length >= 0) {
              this.radioSelectedContact = this.contactService.getContactByID(selectedId[0]);
              this.contactService.contacts.map(item => {
                item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
              });
            }
            this.selectionEligibleContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
            let accounts: Account[] = [];
            this.selectedAccountIds.forEach(acc => {
              let foundAccount = this.accountService.getAccountById(acc.id);
              if (foundAccount) {
                accounts.push(foundAccount);
              }
            });
            await this.autoSelectAccountAffFilter(accounts);
            break;
          case PageName.SurveyDetailsComponent:
            this.selectionModeForComponent = 'Survey-Details-Selection';
            let selectedTemplate: AssessmentTemplate;
            let selectedContactIdForSurvey: string = '';
            let selectedAccountForSurvey: Account = null;
            // let contactIdsSubmittedSurvey = [];
            if(this.navParams && this.navParams.data) {
              selectedTemplate = this.navParams.data.template;
              selectedContactIdForSurvey = this.navParams.data.contactId;
              selectedAccountForSurvey = this.navParams.data.account;
            }
            if(!_.isEmpty(selectedTemplate)) {
              // Display only affiliated contacts for Account Survey
              if(selectedTemplate.indskr_entity == SurveyCategory.ACCOUNT) {
                this.selectionEligibleContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting([selectedAccountForSurvey]);
                if (selectedContactIdForSurvey) {
                  this.radioSelectedContact = this.contactService.getContactByID(selectedContactIdForSurvey);
                  this.selectionEligibleContacts.map(item => {
                    item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
                  });
                }
              } else {
                let eligibleCustomersIDs: string[] = [];
                if (selectedTemplate.indskr_metadata.hasOwnProperty('EligibleCustomers')) {
                  const eligibleCustomersFrommetaData = selectedTemplate.indskr_metadata['EligibleCustomers'];
                  if (!_.isEmpty(eligibleCustomersFrommetaData)) {
                    eligibleCustomersIDs = eligibleCustomersFrommetaData.map(customer=>customer['ID'])
                  }
                }
                //#1 Filtered contacts list with eligible customers IDs
                if(!_.isEmpty(eligibleCustomersIDs)) {
                  //#1-1 One-time survey: Show add-icon with non captured eligible contacts in my contacts
                  if(selectedTemplate.indskr_surveyfrequency == SurveyFrequency.ONCE) {
                    // const allSurveysByTemplate = this.customerAssessService.getSurveysByTemplateId(selectedTemplate.indskr_assessmenttemplateid)
                    // const responsesGroupedByContact = _.groupBy(allSurveysByTemplate,'indskr_entityid');
                    const surveyedContactsByTemplate = this.customerAssessService.surveyedContactsForOnetimeSurvey[selectedTemplate.indskr_assessmenttemplateid];
                    this.contactService.contacts.map(item => {
                      const filteredByContactID = !_.isEmpty(surveyedContactsByTemplate) ? surveyedContactsByTemplate.filter(s => s.indskr_entityid == item.ID) : [];
                      item.isForcedHideAddIcon = !eligibleCustomersIDs.includes(item.ID) || !_.isEmpty(filteredByContactID);
                    });
                  }else {
                    //#1-2 Recurring survey: Show add-icon with eligible customers IDs in my contats
                    this.contactService.contacts.map(item => {
                      item.isForcedHideAddIcon = !eligibleCustomersIDs.includes(item.ID);
                    });
                  }
                }
                //#2 Filtered contacts list without eligible customers IDs
                else {
                  //#2-1 One-time survey: Show add-icon with non-captured contats
                  if(selectedTemplate.indskr_surveyfrequency == SurveyFrequency.ONCE) {
                    // const allSurveysByTemplate = this.customerAssessService.getSurveysByTemplateId(selectedTemplate.indskr_assessmenttemplateid)
                    // const responsesGroupedByContact = _.groupBy(allSurveysByTemplate,'indskr_entityid');
                    const surveyedContactsByTemplate = this.customerAssessService.surveyedContactsForOnetimeSurvey[selectedTemplate.indskr_assessmenttemplateid];
                    this.contactService.contacts.map(item => {
                      const filteredByContactID = !_.isEmpty(surveyedContactsByTemplate) ? surveyedContactsByTemplate.filter(s => s.indskr_entityid == item.ID) : [];
                      item.isForcedHideAddIcon = !_.isEmpty(filteredByContactID);
                    });
                  }else {
                    //#2-2 Recurring survey: Show add-icon with my contacts
                    this.contactService.contacts.map(item => { if(item.isForcedHideAddIcon) item.isForcedHideAddIcon = !_.isEmpty(eligibleCustomersIDs) ? !eligibleCustomersIDs.includes(item.ID) : false; });
                  }
                }
                if (selectedContactIdForSurvey) {
                  this.radioSelectedContact = this.contactService.getContactByID(selectedContactIdForSurvey);
                  this.contactService.contacts.map(item => {
                    item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
                  });
                }
                this.selectionEligibleContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
              }              
            }  
            this.uiService.showNewActivity = false;
            break;
          default:
            this.selectionModeForComponent = 'Sample-Activity-Selection';
            this.sampleService.contactCustomerSampleAllocations.map((o) => {
              if (o.contactId && o.currentCustomerSampleAllocations && o.currentCustomerSampleAllocations.length > 0) {
                let foundContact: Contact = this.contactService.getContactByID(o.contactId);
                if (foundContact && !foundContact.isguest && foundContact.isActive) {
                  this.selectionEligibleContacts.push(foundContact);
                }
              }
            });
            break;
        }

        this.selectionEligibleContacts.sort(function (a, b) {
          return (a.fullname.toLowerCase() > b.fullname.toLowerCase()) ? 1 : -1;
        })
        this.selectionEligibleContactsLength = this.selectionEligibleContacts.length;
      }

      if (this.contactService.contactPageMode == ComponentViewMode.READONLY && this.contactService.isConsentInvoked) {
        this.selectedContacts = this.activityService.selectedActivity['contacts'];
      }
    }

    this.contactService.affiliationControl();
    this.listPeriodTags = this.territoryService.getListPeriodTags();
    this.contactsToDisplay = this.sliceContacts(0, this.recordCount);
    
    /** Remove the related customer if the insight customer is same*/
    if (this.uiService.activeView === "add-customer-relationship") {
      let insight: TrendingCustomer = this.xperiencesService.newInsight;
      if (insight.contactid && this.contactsToDisplay && this.contactService.contacts) {
        this.contactsToDisplay = this.contactService.contacts.filter(contact => contact.ID != insight.contactid && contact.isguest === false && contact.isActive && contact.indskr_iseventparticipant != true);
        this.selectionEligibleContacts = this.contactsToDisplay;
        this.selectionEligibleContactsLength = this.selectionEligibleContacts.length;
      }
    }

    // Initializing affiliated contacts from selected accounts for meeting
    if (this.contactService.contactPageMode == ComponentViewMode.ADDNEW && (this.contactService.accessedContactListFrom == PageName.ActivitiesDetailsPaneComponent || this.contactService.accessedContactListFrom == PageName.PresentationMeetingComponent) && this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Appointment && (this.activityService.selectedActivity as AppointmentActivity).accounts.length !== 0) {
      this.allowAffiliatedHCPSelectionOnly = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOW_AFFILIATED_HCO_AND_HCP_SELECTION_ONLY);
      let accounts:Account[] = [];
      (this.activityService.selectedActivity as AppointmentActivity).accounts.forEach(obj =>{
        let foundAcc = this.accountService.getAccountById(obj.id);
        if (foundAcc){
          accounts.push(foundAcc);
        }
      });
      if (accounts.length != 0) {
        this.affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(accounts);
      }
      await this.autoSelectAccountAffFilter(accounts);
    } else if (this.contactService.contactPageMode == ComponentViewMode.ADDNEW && this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent && this.selectedAccountIds){
      let accounts:Account[] = [];
      this.selectedAccountIds.forEach(acc => {
        let foundAccount = this.accountService.getAccountById(acc.id);
        if (foundAccount) {
          accounts.push(foundAccount);
        }
      });
      if (accounts.length != 0) {
        this.affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(accounts);
      }
      await this.autoSelectAccountAffFilter(accounts);
    }else if (this.contactService.accessedContactListFrom == PageName.PhoneCallDetailsComponent && this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.PhoneCall && (this.activityService.selectedActivity as PhoneActivity).contacts.length !== 0) {
      this.radioSelectedContact = this.contactService.getContactByID((this.activityService.selectedActivity as PhoneActivity).contacts[0].ID);
      this.mobileNumberList = [];
      this.mobileNumberList.push({'phoneNumber': this.radioSelectedContact.mobilePhone ? this.radioSelectedContact.mobilePhone : '', 'identity': 'mobilePhone'})
      this.mobileNumberList.push({'phoneNumber': this.radioSelectedContact.indskr_alternatephone1 ? this.radioSelectedContact.indskr_alternatephone1 : '', 'identity': 'alternatephone1'})
      this.mobileNumberList.push({'phoneNumber': this.radioSelectedContact.indskr_alternatephone2 ? this.radioSelectedContact.indskr_alternatephone2 : '', 'identity': 'alternatephone2'})
      this.mobileNumberList.push({'phoneNumber': this.radioSelectedContact.indskr_alternatephone3 ? this.radioSelectedContact.indskr_alternatephone3 : '', 'identity': 'alternatephone3'})
      this.mobileNumberList.push({'phoneNumber': this.radioSelectedContact.telephone ? this.radioSelectedContact.telephone : '', 'identity': 'telephone'})

      if((this.activityService.selectedActivity as PhoneActivity).selectedMobileNumber){
        this.mobileNumberList.forEach(x =>{
         if(x.phoneNumber == (this.activityService.selectedActivity as PhoneActivity).selectedMobileNumber){
            x.isChecked = true;
         }
        });
      } else{
        if(this.mobileNumberList.length){
          const isNotEmpty = (element) => !_.isEmpty(element.phoneNumber);
          let index = this.mobileNumberList.findIndex(isNotEmpty);
          this.mobileNumberList[index].isChecked = true;
          (this.activityService.selectedActivity as PhoneActivity).selectedMobileNumber = this.mobileNumberList[index].phoneNumber
         }
      }
      this.contactService.contacts.map(item => {
        item.isRadioSelected = this.radioSelectedContact && item.ID === this.radioSelectedContact.ID;
      });
      if((this.activityService.selectedActivity as PhoneActivity).accounts.length !== 0){
        let accounts:Account[] = [];
        (this.activityService.selectedActivity as PhoneActivity).accounts.forEach(obj =>{
          let foundAcc = this.accountService.getAccountById(obj.id) || this.accountService.getAccountById(obj.accountId)
          if (foundAcc){
            accounts.push(foundAcc);
          }
        });
        await this.autoSelectAccountAffFilter(accounts);
        if (accounts.length != 0) {
          this.affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(accounts);
        } else {
          this.affiliatedContacts = [];
        }
      }
    }
    else if (this.contactService.accessedContactListFrom == PageName.PhoneCallDetailsComponent && this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.PhoneCall && (this.activityService.selectedActivity as PhoneActivity).accounts.length !== 0){
      let accounts:Account[] = [];
      (this.activityService.selectedActivity as PhoneActivity).accounts.forEach(obj =>{
        let foundAcc = this.accountService.getAccountById(obj.id) || this.accountService.getAccountById(obj.accountId)
        if (foundAcc){
          accounts.push(foundAcc);
        }
      });
      if (accounts.length != 0) {
        this.affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(accounts);
      }
      await this.autoSelectAccountAffFilter(accounts);
    } else if ((this.contactService.accessedContactListFrom === PageName.ProcedureTrackerComponent || this.contactService.accessedContactListFrom == PageName.SurgeryOrderDetailsComponent)
      && this.contactService.contactPageMode == ComponentViewMode.ADDNEW && !_.isEmpty(this.selectedAccountIds)) {
      this.affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(this.selectedAccountIds);
      let accounts:Account[] = [];
      this.selectedAccountIds.forEach(acc => {
        let foundAccount = this.accountService.getAccountById(acc.id);
        if (foundAccount) {
          accounts.push(foundAccount);
        }
      });
      await this.autoSelectAccountAffFilter(accounts);
    } else if (this.contactService.contactPageMode == ComponentViewMode.ADDNEW && this.contactService.accessedContactListFrom === PageName.EmailDetailsPageComponent && this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Email) {
      if (!_.isEmpty(this.activityService.selectedActivity.accounts)) {
        let accounts:Account[] = [];
        this.activityService.selectedActivity.accounts.forEach(obj => {
          const accountId = obj.id || obj.accountId || obj.accountid || '';
          let foundAcc = this.accountService.getAccountById(accountId);
          if (foundAcc){
            accounts.push(foundAcc);
          }
        });
        if (accounts.length != 0) {
          this.affiliatedContacts = await this.contactService.getAffiliatedContactsFromAccountsForMeeting(accounts);
        }
        await this.autoSelectAccountAffFilter(accounts);
      }      
    } else {
      this.affiliatedContacts = [];
    }
    if (!this.authenticationOfflineService.user.isCustomerProfileLaunchSyncEnabled) this.fetchRealtimeDetailsFlag = false;
   
    this._initAllSectionHeaderModel();
  }
  isAllContactSelected: boolean = false;
  private isAllSegmentedContactSelected: boolean = true;

  private async autoSelectAccountAffFilter(accounts: Account[]) {
    if (!_.isEmpty(accounts)) {
      const selectedItems = [];
      const config = this.searchConfigService.contactConfiguredSearchIndexConfig.find(config => config.categoryRelativePath === 'indskr_accountcontactaffiliation.indskr_accountid' || config.categoryRelativePath === 'indskr_primaryaccount');
      if (config) {
        accounts.forEach(account => {
          selectedItems.push({
            "selectedFacet": account.accountName,
            "charSearchText": "",
            "categoryPath": config.categoryRelativePath,
            "categoryName": config.categoryName,
            "categoryDisplayName": config.categoryDisplayName,
            "type": 548910000,
            "value": "",
            "createdOn": new Date().getTime(),
            "isAggregatedSection": false,
            "subCategories": [],
            "isSubCategory": false
          })
        })
        let obj = {
          "data": {
            "selectedItems": selectedItems,
            "isDone": true
          }
        };
        await this.uiService.displayLoader();
        await this.onMyContactsFilterDismiss(obj);
        await this.uiService.dismissLoader();
      }
    }
  }

  private initSelectedContactsHeaderModel() {
    let controls: any[] = [];

    if (this.shouldSelectAllOptionVisible()) {
      if (this.selectedContacts.length) {
        controls.push({
          id: 'contacts-select-all-clear',
          text: `${this.translate.instant('CLEAR')}`,
          isDisabled: false,
        });
      }
    }

    this.selectedContactsHeader = {
      id: 'selected-contacts-header',
      title: this.contactService.isSchedulerInvoked ? this.translate.instant('MATCHED_GLOBALCUSTOMER', {globalCustomerText: this.globalCustomerDisplayText}) + ' (' + this.selectedContacts.length + ')' : this.translate.instant('SELECTED_CAP') + ' (' + this.selectedContacts.length + ')',
      controls: controls
    }
  }

  private initSegmentedContactsHeaderModel() {
    this.segmentedContactsHeader = {
      id: 'segmented-contacts-header',
      title: this.translate.instant('SEGMENTED_WITH_GLOBALCUSTOMER', {globalCustomerText: this.globalCustomerDisplayText}) + ' (' + this.segmentedContacts.length + ')',
      controls: [{
        id: 'seg-contacts-select-all',
        text: `${this.translate.instant('SELECT_ALL')}`,
        isDisabled: this.isAllSegmentedContactSelected,
      }]
    }
  }

  private _initAllSectionHeaderModel() {

    let allContactsHeaderControls: any[] = [];
    if (this.shouldSelectAllOptionVisible()) {
      if (this.nonGuestContacts.length) {

        allContactsHeaderControls.push({
          id: 'contacts-select-all',
          text: `${this.translate.instant('SELECT_ALL')}`,
          isDisabled: this.isAllContactSelected,
        });
      }
    }
    allContactsHeaderControls.push({
        id: "clear-filter",
        text: this.translate.instant('CLEAR_FILTER'),
        isVisible: this.filtering,
        isDisabled: _.isEmpty(this.selectedFilterData)
      },{
      id: 'contact-sort',
      text: this.sortBy?.text,
      isDisabled: false,
      img: "assets/imgs/sort_with_double_arrows.svg",
    });
    this.allContactsHeader = {
      id: 'all-contacts-header',
      title: ((this.isOpenedForLiveMeet || this.contactService.accessedContactListFrom == this.pagename.EventDetailsPageComponent)
        ? this.translate.instant('MY_PARTICIPANTS') : this.contactService.accessedContactListFrom == PageName.AccountPlanDetailsComponent || this.contactService.accessedContactListFrom == PageName.MarketingPlanDetails
          ? this.translate.instant('AFFILIATED_STAKEHOLDERS') : 
          this.languageService.selectedLanguage.code == "ja"? this.translate.instant('MY_STACKHOLDERS') : 
          this.translate.instant('MY_GLOBALCUSTOMER', { globalCustomerText: this.globalCustomerDisplayText })) + ' (' + this.nonGuestContacts.length + ')',
      controls: allContactsHeaderControls
    };
    this.initSelectedContactsHeaderModel();
    this.initSegmentedContactsHeaderModel();
    this.restrictedSelectedContactsHeader = {
      id:'restricted-selected-contacts-header',
      title: this.translate.instant('SELECTED_CAP') + ' (' + this.restrictedViewSelectedContacts.length + ')',
      controls: []
    };
    this.newContactsHeader = {
      id:'new-contacts-header',
      title: (this.contactSearchText || this.contactSearchText.length <= 2) ? this.translate.instant('NEW_CAP') + ' ' + this.globalCustomerDisplayText + ' (' + this.newContacts.length + ')' : '',
      controls: []
    };
    this.newParticipantsHeader = {
      id:'new-participant-header',
      title: (this.contactSearchText || this.contactSearchText.length <= 2) ? this.translate.instant('NEW_PARTICIPANTS_CAP') + ' (' + this.newContacts.length + ')' : '',
      controls: []
    };

    this.affiliatedContactsHeader = {
      id:'affiliated-contacts-header',
      title: this.translate.instant('AFFILIATED') + ' (' + this.affiliatedContacts.length + ')',
      controls: []
    };
    this.selectedGlobalSearchContactHeader = {
      id:'selected-globalSearch-contacts-header',
      title: this.translate.instant('SELECTED_CAP') + ' ' + this.globalCustomerText.toUpperCase() + '(1)',
      controls: []
    };
    this.selectedOKSearchContactHeader = {
      id:'selected-ok-contacts-header',
      title: this.translate.instant('SELECTED_CAP') + ' ' + this.globalCustomerText.toUpperCase() + '(1)',
      controls: []
    };
    this.globalContactsHeader = {
      id:'global-contacts-header',
      title: this.translate.instant('CONTACT_OTHER_GLOBALCUSTOMERS', {globalCustomerText : this.globalCustomerDisplayText}) + ' (' + this.contactService.globalSearchResults.length + ')',
      controls: [
        {
          id: 'contact-sort',
          text: this.sortBy?.text,
          isDisabled: false,
          img: "assets/imgs/sort_with_double_arrows.svg",
        }
      ]
    };
    this.oneKeyContactsHeader = {
      id:'onekey-contacts-header',
      title: this.translate.instant('CONTACT_ONEKEY_GLOBALCUSTOMERS', {globalCustomerText : this.globalCustomerDisplayText}) + ' (' + this.onekeyApiService.oneKeyContacts.length + ')',
      controls: []
    };

    this.resultsAllocationHeaderModel = {
      id: 'allocation-results',
      title: ((this.selectionModeForComponent === 'Sample-Activity-Selection') ? (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CONTACT_UPDATE) ? this.translate.instant('ALLOCATION_WITH_GLOBALSTAKEHOLDERS'): this.translate.instant('ALLOCATION_WITH_GLOBALCUSTOMER'))
        :  this.languageService.selectedLanguage.code == "ja"? this.translate.instant('MY_STACKHOLDERS') : 
        this.translate.instant('MY_GLOBALCUSTOMER', { globalCustomerText: this.globalCustomerDisplayText })
        )
        + ' (' + this.selectionEligibleContactsLength + ')',
      controls: [
        {
          id: "clear-filter",
          text: this.translate.instant('CLEAR_FILTER'),
          isVisible: this.filtering,
          isDisabled: _.isEmpty(this.selectedFilterData)
        },
        {
          id: 'contact-sort',
          text: this.sortBy?.text,
          isDisabled: false,
          img: "assets/imgs/sort_with_double_arrows.svg",
        }
      ]
    };
    this.phoneResultsAllocationHeaderModel = {
      id: 'phone-allocation-results',
      title: ((this.selectionModeForComponent === 'Sample-Activity-Selection') ?
        this.translate.instant('MY_CUSTOMERS', { myglobalcustomer: this.globalCustomerDisplayText })
        : this.languageService.selectedLanguage.code == "ja"? this.translate.instant('MY_STACKHOLDERS') : 
        this.translate.instant('MY_GLOBALCUSTOMER', { globalCustomerText: this.globalCustomerDisplayText }) 
        )
        + ' (' + this.nonGuestContacts.length + ')',
      controls: [
        {
          id: "clear-filter",
          text: this.translate.instant('CLEAR_FILTER'),
          isVisible: this.filtering,
          isDisabled: _.isEmpty(this.selectedFilterData)
        },
        {
          id: 'contact-sort',
          text: this.sortBy?.text,
          isDisabled: false,
          img: "assets/imgs/sort_with_double_arrows.svg",
        }
      ]
    };
    this.localResultsContactsHeader = {
      id: 'local-search-contacts',
      title:  this.languageService.selectedLanguage.code == "ja"? this.translate.instant('MY_STACKHOLDERS') : 
      this.translate.instant('MY_GLOBALCUSTOMER', { globalCustomerText: this.globalCustomerDisplayText }) + ' (' + this.filterMetadata.count + ')',
      controls: allContactsHeaderControls
    }
    this.localResultsAllocationHeaderModel = {
      id: 'local-search-allocation-results',
      title: this.translate.instant('RESULTS_CAP') + ' (' + this.searchedContactsToDisplay.length + ')',
      controls: [
        {
          id: "clear-filter",
          text: this.translate.instant('CLEAR_FILTER'),
          isVisible: this.filtering,
          isDisabled: _.isEmpty(this.selectedFilterData)
        },
        {
          id: 'contact-sort',
          text: this.sortBy?.text,
          isDisabled: false,
          img: "assets/imgs/sort_with_double_arrows.svg",
        }
      ]
    };
    this.myContactChangeRequestsHeaderModel = {
      id: 'my-contact-change-requests',
      title: (this.searching || this.filtering ? this.translate.instant('RESULTS_CAP') : this.translate.instant('MY_CHANGE_REQUESTS')) + ' (' + this.filteredContactCRs.length + ')',
      controls: [
        {
          id: "clear-filter",
          text: this.translate.instant('CLEAR_FILTER'),
          isVisible: this.filtering,
          isDisabled: _.isEmpty(this.selectedFilterData)
        }
        /*{
          id: 'contact-sort',
          text: this.sortBy?.text,
          isDisabled: false,
          img: "assets/imgs/sort_with_double_arrows.svg",
        }*/
      ]
    }
    this._initDCRApprovalsListHeader();
  }

  async deselectAllContactsForAssessment() {
    await this.uiService.displayLoader();
    setTimeout(async () => {
      let guests = [...this.selectedContacts.filter(each => { return each.isguest; })];

      if (guests && guests.length) {
        this.selectedContacts = guests;
      } else {
        this.selectedContacts = [];
      }

      if (this.searching || this.filtering) {
        this.searchedContactsToDisplay.map(obj => obj.isSelectedForTag = false);
      } else {
        this.nonGuestContacts.map(obj => obj.isSelectedForTag = false);
      }

      await this.uiService.dismissLoader();
    }, 0)
  }

  async deselectAllContacts() {
    await this.uiService.displayLoader();
    setTimeout(async () => {
      let guests = [...this.selectedContacts.filter(each => { return each.isguest; })];

      if (guests && guests.length) {
        this.selectedContacts = guests;
      } else {
        this.selectedContacts = [];
      }

      if (this.searching || this.filtering) {
        this.searchedContactsToDisplay.map(obj => obj.isSelected = false);
      } else {
        this.nonGuestContacts.map(obj => obj.isSelected = false);
      }

      if (this.segmentedContacts.length) {
        this.segmentedContactsHeader.controls[0].isDisabled = this.isAllSegmentedContactSelected = false;
      }

      this.enableDisableDoneButton();

      this.checkEnableDisableSelectAllOption();
      await this.uiService.dismissLoader();
    }, 0)
  }

  async selectAllContactsForAssessment() {
    if (this.searching || this.filtering) {
      if (this.searchedContactsToDisplay.length >= this.contactService.contactsToBeSelected) {
        this.notificationService.notify(this.translate.instant('SELECT_ALL_BUTTON_VISIBLE_MESSAGE',{N:this.contactService.contactsToBeSelected}),  "Contact list", "top", ToastStyle.INFO, 3000);
      } 
    } else {
      if (this.nonGuestContacts.length >= this.contactService.contactsToBeSelected) {
        this.notificationService.notify(this.translate.instant('SELECT_ALL_BUTTON_VISIBLE_MESSAGE',{N:this.contactService.contactsToBeSelected}),  "Contact list", "top", ToastStyle.INFO, 3000);
      } 
    }

    await this.uiService.displayLoader();
    setTimeout(async () => {
      // ---------------***------------- Create "Select All" for contact tool ---------------***------------- //
      const contactsToBeSelected = this.contactService.contactsToBeSelected;

      // Add the existing contacts
      let existingSelectedContacts = [];
      if (this.selectedContacts.length) {
        existingSelectedContacts = [...this.selectedContacts];
      }
      this.selectedContacts = [];
      let contactsToConcat = [];
      // Add the new one into the existing one.
      if (this.searching || this.filtering) {
        this.selectSearchedContactsForAssessment();
        contactsToConcat = this.searchedContactsToDisplay;
      } else {
        this.selectNonSearchedContactsForAssessment();
        contactsToConcat = this.nonGuestContacts;
      }
      this.selectedContacts = existingSelectedContacts.concat(contactsToConcat);

      // Remove the duplicates
      let ids = this.selectedContacts.map(o => o.ID);
      let filteredUniqueResult = this.selectedContacts.filter(({ ID }, index) => !ids.includes(ID, index + 1));

      // Consider only first n contacts where n=25
      let firstNContacts = filteredUniqueResult.slice(0, contactsToBeSelected);
      this.selectedContacts = firstNContacts;

      await this.uiService.dismissLoader();
      // ---------------***------------- Create "Select All" for contact tool ---------------***------------- //
    }, 0);
  }


  async selectAllContacts() {
    if (this.searching || this.filtering) {
      if (this.searchedContactsToDisplay.length >= this.contactService.contactsToBeSelected) {
        this.notificationService.notify(this.translate.instant('SELECT_ALL_BUTTON_VISIBLE_MESSAGE',{N:this.contactService.contactsToBeSelected}),  "Contact list", "top", ToastStyle.INFO, 3000);
      } 
    } else {
      if (this.nonGuestContacts.length >= this.contactService.contactsToBeSelected) {
        this.notificationService.notify(this.translate.instant('SELECT_ALL_BUTTON_VISIBLE_MESSAGE',{N:this.contactService.contactsToBeSelected}),  "Contact list", "top", ToastStyle.INFO, 3000);
      } 
    }

    await this.uiService.displayLoader();
    setTimeout(async () => {
      // ---------------***------------- Ability to 'Select All' customers ---------------***------------- //
      const contactsToBeSelected = this.contactService.contactsToBeSelected;
      // ---------------***------------- Ability to 'Select All' customers ---------------***------------- //

      // Add the existing contacts
      let existingSelectedContacts = [];
      if (this.selectedContacts.length) {
        existingSelectedContacts = [...this.selectedContacts];
      }
      this.selectedContacts = [];
      let contactsToConcat = [];
      // Add the new one into the existing one.
      if (this.searching || this.filtering) {
        //To handle contact selection from Message form UI
        if (this.contactService.accessedContactListFrom !== PageName.EmailDetailsPageComponent) {
          this.selectSearchedContacts();
        }
        contactsToConcat = this.searchedContactsToDisplay;
        // this.selectedContacts = existingSelectedContacts.concat(this.searchedContactsToDisplay);
      } else {
        //To handle contact selection from Message form UI
        if (this.contactService.accessedContactListFrom !== PageName.EmailDetailsPageComponent) {
          this.selectNonSearchedContacts();
        }
        contactsToConcat = this.nonGuestContacts;
        // this.selectedContacts = existingSelectedContacts.concat(this.nonGuestContacts);
      }
      if (this.contactService.accessedContactListFrom === PageName.MarketScanDetailsComponent) {
        let highestSeq = contactsToConcat.length;
        if (!_.isEmpty(existingSelectedContacts)) {
          highestSeq = existingSelectedContacts[0]['sequence'] + contactsToConcat.length + 1;
        }
        contactsToConcat.map(c => c['sequence'] = highestSeq--);
      }
      this.selectedContacts = existingSelectedContacts.concat(contactsToConcat);

      //To handle contact selection from Message form UI
      if (this.contactService.accessedContactListFrom === PageName.EmailDetailsPageComponent) {
        if (!_.isEmpty(this.selectedContacts)) {
          
          this.selectedContacts = this.selectedContacts.filter((contact: Contact) => {
            if (this.selectedEmailChannel) {
              switch (this.selectedEmailChannel.activityType) {
                case ChannelActivityType.WECOM: return !_.isEmpty(contact.indskr_wechatuserid)
                case ChannelActivityType.WHATSAPP:
                case ChannelActivityType.SMS:
                  return (contact.mobilePhone || '').length > 0
                case ChannelActivityType.FACEBOOK:
                  return (contact.indskr_facebookpsid || '').length > 0
                default:
                  return contact.emailAddressList.length > 0;
              }
            }
            return contact.isSelectable;
          });
        }
      }

      // Remove the duplicates
      let ids = this.selectedContacts.map(o => o.ID);
      let filteredUniqueResult = this.selectedContacts.filter(({ ID }, index) => !ids.includes(ID, index + 1));

      // ---------------***------------- Ability to 'Select All' customers ---------------***------------- //
      // Consider only first n contacts where n=25
      let firstNContacts = filteredUniqueResult.slice(0, contactsToBeSelected);
      this.selectedContacts = firstNContacts;

      //To handle contact selection from Message form UI
      if (this.contactService.accessedContactListFrom === PageName.EmailDetailsPageComponent) {
        if (!_.isEmpty(this.selectedContacts)) {
          this.selectedContacts.forEach((contact: Contact) => {
            contact.isSelected = true;
            this.selectDefaultEmailAddress(contact);
          });
          this.isDirty = this.isContactsDirty();
        }
      }
      // ---------------***------------- Ability to 'Select All' customers ---------------***------------- //

      if (!this.isAllSegmentedContactSelected) {
        this.isAllSegmentedContactSelected = true;
        this.initSegmentedContactsHeaderModel();
      }

      this.updateEmptyMessage()
      this._cd.detectChanges();

      this.enableDisableDoneButton();

      this.checkEnableDisableSelectAllOption();
      await this.uiService.dismissLoader();
    }, 0);
  }

  private selectSearchedContactsForAssessment() {
    const contactsToBeSelected = this.contactService.contactsToBeSelected;

    this.searchedContactsToDisplay.slice(0, contactsToBeSelected).map(obj => obj.isSelectedForTag = true);
  }

  private selectNonSearchedContactsForAssessment() {
    const contactsToBeSelected = this.contactService.contactsToBeSelected;

    this.nonGuestContacts.slice(0, contactsToBeSelected).map(obj => obj.isSelectedForTag = true);
  }

  private selectSearchedContacts() {
    const contactsToBeSelected = this.contactService.contactsToBeSelected;

    this.searchedContactsToDisplay.slice(0, contactsToBeSelected).map(obj => obj.isSelected = true);
  }

  private selectNonSearchedContacts() {
    const contactsToBeSelected = this.contactService.contactsToBeSelected;

    this.nonGuestContacts.slice(0, contactsToBeSelected).map(obj => obj.isSelected = true);
  }

  private enableDisableDoneButton() {
    this.isDirty = this.isContactsDirty();
    let doneButton = this.indHeaderLeftModel.controls.find((c) => c.id ===  "done");
    if(doneButton) {
      doneButton.isDisabled = !this.isDirty;
    }
  }

  private checkEnableDisableSelectAllOption() {
    if (this.shouldSelectAllOptionVisible()) {
      let shouldEnable = this.shouldEnableSelectAllOption();

      if (!shouldEnable) {
        // Means, My Contacts are selected and we need to make sure they will look selected.
        if (this.searching || this.filtering) {
          this.selectSearchedContacts();
        } else {
          this.selectNonSearchedContacts();
        }
      }

      if (this.searching || this.filtering) {
        if (this.selectedContacts.length !== this.searchedContactsToDisplay.length) {
          this.enableDisableSelectAllOption(shouldEnable ? false : true);
        } else {
          this.enableDisableSelectAllOption(true);
        }
      } else {
        if (this.selectedContacts.length !== this.nonGuestContacts.length) {
          this.enableDisableSelectAllOption(shouldEnable ? false : true);
        } else {
          this.enableDisableSelectAllOption(true);
        }
      }
    }
  }

  private shouldEnableSelectAllOption(): boolean {
    // When after applying the the advance filter,
    // My Customers having the cusotmers which are already selected.
    // We should not show the 'Select All' option.
    if (this.selectedContacts.length) {
      let selectedIds = this.selectedContacts.map(o => o.ID);
      
      const contactsToBeSelected = this.contactService.contactsToBeSelected;
      let comparableIds = (this.searching || this.filtering) ? this.searchedContactsToDisplay.slice(0, contactsToBeSelected).map(o => o.ID) : this.nonGuestContacts.slice(0, contactsToBeSelected).map(o => o.ID);

      if (comparableIds.length) {
        let index = 0;
        selectedIds.forEach(id => { if (comparableIds.includes(id)) index += 1; });
        return (index === comparableIds.length) ? false : true;
      } else {
        console.warn('`comparableIds` are empty, this should not be the case.');
        return false;
      }
    }
    return true;
  }

  private enableDisableSelectAllOption(shouldDisable: boolean) {
    let selectAllButton;

    if (this.searching || this.filtering) {
      selectAllButton = this.localResultsContactsHeader.controls.find((c) => c.id ===  "contacts-select-all");
    } else {
      selectAllButton = this.allContactsHeader.controls.find((c) => c.id ===  "contacts-select-all");
    }

    if (selectAllButton) {
      if (this.selectedContacts.length >= this.contactService.contactsToBeSelected) {
        selectAllButton.isDisabled = true;
        this.isAllContactSelected = true;
      } else {
        selectAllButton.isDisabled = shouldDisable;
        this.isAllContactSelected = shouldDisable;
      }
    }

    this.initSelectedContactsHeaderModel();
  }

  private shouldSelectAllOptionVisible(): boolean {
    let isVisible = false;
    if (this.contactService.contactPageMode !== ComponentViewMode.READONLY) {
      if (this.contactService.accessedContactListFrom === PageName.SchedulerDetailComponent && this.contactService.isSchedulerInvoked) {
        isVisible = true;
      } else if (this.contactService.accessedContactListFrom === PageName.MarketScanDetailsComponent) {
        isVisible = true;
      } else if (
        this.contactService.accessedContactListFrom === PageName.ActivitiesDetailsPaneComponent
        && !this.isAccountVisitRecord && !this.isSingleSelectionHCOHCPEnabled && !this.allowAffiliatedHCPSelectionOnly
      ) {
        isVisible = true;
      } else if (this.contactService.accessedContactListFrom === PageName.EmailDetailsPageComponent) {
        isVisible = true;
      }
    }
    return isVisible;
  }

  onSectionHeaderControlClick(id: string) {
    if (id === 'contact-sort') {
        this.sortContactsList();
    } else if (id === 'contacts-select-all') {
      this.isAllContactSelected = true;
      this.selectAllContacts();
      this.initSelectedContactsHeaderModel();
    } else if (id === 'contacts-select-all-clear') {
      this.deselectAllContacts();
    } else if(id === 'seg-contacts-select-all') {
      this.selectAllSegContacts();
    } else if(id === 'clear-filter') {
      this.clearFilters();
    }
  }

  private async selectAllSegContacts() {
    await this.uiService.displayLoader();
    setTimeout(async () => {
      if (this.selectedContacts.length > 0) {
        let seq = this.selectedContacts[0]['sequence'] + 1;
        this.segmentedContacts.filter(c => !this.selectedContacts.includes(c)).reverse().forEach(c => {
          c.isSelected = true;
          c['sequence'] = seq++;
          this.selectedContacts.unshift(c);
        });
      } else {
        let seq = this.segmentedContacts.length;
        this.segmentedContacts.forEach(c => {
          c.isSelected = true;
          c['sequence'] = seq--;
          this.selectedContacts.push(c);
        });
      }
      this.segmentedContactsHeader.controls[0].isDisabled = true;
      this.initSelectedContactsHeaderModel();
      this.checkEnableDisableSelectAllOption();
      this.enableDisableDoneButton();
      await this.uiService.dismissLoader();
    }, 0);
  }

  getVoiceSatus(contactID) {
    let index = _.findIndex(this.participantsList, { contactID: contactID });
    if (index != -1) {
      return this.participantsList[index].voiceStatus;
    }
    return null;
  }

  isSelectable = (contact: Contact) => {
    if (this.contactService.contactPageMode !== ComponentViewMode.ADDNEW) return false;
    if (this.allowAffiliatedHCPSelectionOnly){
      return this.affiliatedContacts.length && this.affiliatedContacts.some(a=> a.ID == contact.ID);
    }
    if (this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent) {
      if(contact.isForcedHideAddIconEvent) return false;
    }
    if (this.contactService.accessedContactListFrom != PageName.EmailDetailsPageComponent) return true;
    //const email: EmailActivity = this.emailService.selectedActivity;
    if (this.selectedEmailChannel) {
      switch (this.selectedEmailChannel.activityType) {
        case ChannelActivityType.WECOM: return !_.isEmpty(contact.indskr_wechatuserid)
        case ChannelActivityType.WHATSAPP:
        case ChannelActivityType.SMS:
          return (contact.mobilePhone || '').length > 0
        case ChannelActivityType.FACEBOOK:
          return (contact.indskr_facebookpsid || '').length > 0
        default:
          return contact.emailAddressList.length > 0;
      }
    }
    return contact.isSelectable;
  }

  addToSelected = async (event, contact: Contact) => {
    if (event && event.stopPropagation) event.stopPropagation();
    if(this.isAccountVisitRecord || this.isAccountVisitNestedMeeting){
      if(this.authenticationOfflineService.user.buConfigs && this.authenticationOfflineService.user.buConfigs['indskr_allowconsentedhcptoaccountvisit']){
        const hasActiveConsent = await this.meetingDataService.getActiveGeneralConsent(contact.ID);
        if(!hasActiveConsent){
          this.notificationService.notify(this.translate.instant('ACTIVE_GENERAL_CONSENT_NOT_CONSENTED'), 'Meeting Details', 'top', ToastStyle.DANGER);
          return;
        }
      }
    }
    if (this.enableOneTimeVeevaMeeting && contact.isUsedInOneTimeMeeting && contact.veevaRecordState == 200000004) {
      this.toast.notify(`${this.translate.instant("This contact is under review and is already used in another meeting. Please try again with another contact")}`, "");
      return;
    }

    //reset connection roles
    contact.stakeholderRolesList= [];
    if(this.contactService.accessedContactListFrom === PageName.MarketScanDetailsComponent)
      await this.uiService.displayLoader();
    setTimeout(async () => {
      //If invoked via case management tool then the user can select only one customer at a time
      if (this.contactService.isCaseManagementInvoked) {
        //reset all the data in contacts array
        this.contactService.contacts.map(e => e.isSelected = false);
        //reset the selected contact array
        this.selectedContacts = []
        //select the contact
        contact.isSelected = true;
        //push it to the selected array lis
        this.selectedContacts.push(contact);
        this.isDirty = this.isContactsDirty();
        //select contact for Non-Native channel
      } else if (this.selectedContactsForNonNativeChannelSMS || this.selectedContactsForNonNativeChannelWhatsApp) {
        this.contactService.contacts.map(e => e.isSelected = false);
        this.selectedContacts = []
        contact.isSelected = true;
        this.selectedContacts.push(contact);
        this.isDirty = this.isContactsDirty();
        // To select multiple numbers for SMS non-native.
        if (this.selectedContactsForNonNativeChannelSMS) {
          this.mobileNumberListforShareMessage = [];
          if (this.selectedContacts[0].mobilePhone || this.selectedContacts[0].telephone || this.selectedContacts[0].indskr_alternatephone1 || this.selectedContacts[0].indskr_alternatephone2 || this.selectedContacts[0].indskr_alternatephone3) {
            this.mobileNumberListforShareMessage.push({ 'phoneNumber': this.selectedContacts[0].mobilePhone ? this.selectedContacts[0].mobilePhone : '', 'identity': 'mobilePhone' })
            this.mobileNumberListforShareMessage.push({ 'phoneNumber': this.selectedContacts[0].indskr_alternatephone1 ? this.selectedContacts[0].indskr_alternatephone1 : '', 'identity': 'alternatephone1' })
            this.mobileNumberListforShareMessage.push({ 'phoneNumber': this.selectedContacts[0].indskr_alternatephone2 ? this.selectedContacts[0].indskr_alternatephone2 : '', 'identity': 'alternatephone2' })
            this.mobileNumberListforShareMessage.push({ 'phoneNumber': this.selectedContacts[0].indskr_alternatephone3 ? this.selectedContacts[0].indskr_alternatephone3 : '', 'identity': 'alternatephone3' })
            this.mobileNumberListforShareMessage.push({ 'phoneNumber': this.selectedContacts[0].telephone ? this.selectedContacts[0].telephone : '', 'identity': 'telephone' })
          }
          if (this.mobileNumberListforShareMessage.length) {
            const isNotEmpty = (element) => !_.isEmpty(element.phoneNumber);
            let index = this.mobileNumberListforShareMessage.findIndex(isNotEmpty);
            this.mobileNumberListforShareMessage[index].isSelected = true;
          }
        }
      }
      //Normal function as usual
      else {
        //event
        if (this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent) {
          const isInvitationLimitAlertMessageNotification: boolean = this.authenticationOfflineService.user.isInvitationLimitAlertMessageNotification;
          const isDisableInvitationsWhenTheLimitHasReached: boolean = this.authenticationOfflineService.user.isDisableInvitationsWhenTheLimitHasReached;
          
          if(!this.device.isOffline && (isInvitationLimitAlertMessageNotification || isDisableInvitationsWhenTheLimitHasReached)) {
            const isExceedLimit = await this.contactService.fetchEventCheckInLimit(contact.ID);
            if(isExceedLimit) {
              if(isInvitationLimitAlertMessageNotification && !isDisableInvitationsWhenTheLimitHasReached) {
                this.notificationService.notify(this.translate.instant('REACHED_INVITATION_LIMIT', { attendeeName: contact.fullName }), 'EventComponent', "top", ToastStyle.INFO, 3000);
              }else if(isDisableInvitationsWhenTheLimitHasReached) {
                this.notificationService.notify(this.translate.instant('REACHED_INVITATION_LIMIT', { attendeeName: contact.fullName }), 'EventComponent', "top", ToastStyle.DANGER, 3000);
                contact.isForcedHideAddIconEvent = true;
                console.log("Disabled Invitation because check-in limit has reached.");
                return;
              }
            }
          }
        }

        contact.isSelected = true;
        this.eventsToolService.isEventParticipationDirty = true;
        let selIndex = this.selectedContacts.indexOf(contact); //Check for duplicates
        if (selIndex < 0) {
          if (this.contactService.accessedContactListFrom === PageName.MarketScanDetailsComponent) {
            //For market scan, new contacts should be added at top
            contact['sequence'] = this.selectedContacts.length > 0 ? this.selectedContacts[0]['sequence'] + 1 : 1;
            if (this.selectedContacts.length > 0) {
              const tempSelectedContacts = [];
              tempSelectedContacts.push(contact);
              tempSelectedContacts.push(...this.selectedContacts);
              this.selectedContacts = tempSelectedContacts;
              // this.selectedContacts.unshift(contact);
            }
            else {
              this.selectedContacts.push(contact);
            }
            if (!this.isAllSegmentedContactSelected) {
              const selectedIds = this.selectedContacts.map(sc => sc.ID);
              this.isAllSegmentedContactSelected = !this.segmentedContacts.some(segC => !selectedIds.includes(segC.ID));
            }
          } else{
            if(this.isSingleSelectionHCOHCPEnabled && (this.contactService.accessedContactListFrom == PageName.ActivitiesDetailsPaneComponent || this.contactService.accessedContactListFrom == PageName.PresentationMeetingComponent || this.contactService.accessedContactListFrom == PageName.EmailDetailsPageComponent)){
              this.selectedContacts.forEach(c=> {
                let foundCon = this.contactService.getContactByID(c.ID);
                if(foundCon)
                this.removeFromSelected(null,foundCon,null);
              })
              this.selectedContacts.push(contact);
            }else{
              // # # # View Contacts in sequence the way they were selected during meeting creation # # # //
              contact.indskr_sequence = this.selectedContacts.length + 1;
              // # # # View Contacts in sequence the way they were selected during meeting creation # # # //
              this.selectedContacts.push(contact);
            }
          }
        }
        if (this.emailService.selectedActivity) this.selectDefaultEmailAddress(contact);
        this.isDirty = this.isContactsDirty();
      }
      if (this.restrictedSelectedContactsViewActive) {
        let idx = this.restrictedViewSelectedContacts.findIndex(a => a.ID == contact.ID);
        if (idx < 0) {
          this.restrictedViewSelectedContacts.push({
            ID: contact.ID,
            customerId: contact.appId,
            title: contact.title,
            firstName: contact.firstName,
            middleName: contact.middleName,
            lastName: contact.lastName,
            fullname: contact.fullname,
            isActive: contact.isActive,
            statusCode: contact.statusCode,
            primaryAccount: contact.getPrimaryAccountRelationship,
            primarySpecialty: contact.primarySpecialtyName,
            secondaryInfo: contact.secondaryInfo,
            raw: contact.raw,
            ownerId: this.authenticationOfflineService.user.systemUserID,
            isSelectable: true,
          });
        }
      }
      this.initContactsHeaderLeft()
      this._initAllSectionHeaderModel();
      this.checkEnableDisableSelectAllOption();
      this._cd.detectChanges();
      if((this.contactService.accessedContactListFrom == PageName.OpportunityDetailsComponent || this.contactService.accessedContactListFrom == PageName.NewOpportunityComponent) && this.opportunityService.stakeholderRoles){
        this.updateConnectionRoleToSelectedContact(null, contact, this.opportunityService.stakeholderRoles[0]);
      }
      //Event
      else if (this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent) {
        const index = this.eventsToolService.selectedEventOnEventsTool.participants?.findIndex(participant => participant.id == contact.ID);
        if (index >= 0 && this.eventsToolService.selectedEventOnEventsTool.participants[index].eventPasses) {
          //For saved contacts, update noOfPassesLeft on adding back
          this.eventsToolService.selectedEventOnEventsTool.participants[index].eventPasses.forEach(ePass => {
            const passIndex = this.eventsToolService.selectedEventOnEventsTool.passes.findIndex(pass => pass.msevtmgt_passId == ePass.msevtmgt_passId);
            if (passIndex >= 0 && this.eventsToolService.selectedEventOnEventsTool.passes[passIndex]['temp_msevtmgt_noOfPassesLeft'] > 0) {
              this.eventsToolService.selectedEventOnEventsTool.passes[passIndex]['temp_msevtmgt_noOfPassesLeft'] -= 1;
            } else {
              const removeEventPass = [{
                msevtmgt_passId: this.eventsToolService.selectedEventOnEventsTool.passes[passIndex].msevtmgt_passId,
                msevtmgt_attendeepassid: ePass.msevtmgt_attendeepassId,
                msevtmgt_passName: this.eventsToolService.selectedEventOnEventsTool.passes[passIndex].msevtmgt_passName,
                eventRegId: this.eventsToolService.selectedEventOnEventsTool.participants[index].registrationId,
                passId: this.eventsToolService.selectedEventOnEventsTool.passes[passIndex].msevtmgt_passId,
                deleted: true
              }]
              if (_.isEmpty(this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave'])) this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave'] = [];
              const eventPassToSaveInx = this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave'].findIndex(passesToSave => passesToSave.id == contact.ID);
              if (eventPassToSaveInx == -1) {
                this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave'].push({
                  id: contact.ID,
                  eventPasses: removeEventPass
                });
              } else {
                this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave'][eventPassToSaveInx].eventPasses = removeEventPass;
              }
          }
          })
        }
        // this.highlightContact(null, contact, false, 'profile');
      } else if (this.contactService.accessedContactListFrom === PageName.EmailDetailsPageComponent) {
        this.ionContent.scrollToTop();
      }
      await this.uiService.dismissLoader();
    }, 0);
    if (this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent) {
      this.highlightContact(event, contact);
      // this.events.publish('selectedEventParticipantChanged', {selectedContacts: this.selectedContacts, contact: contact});
    }
  };

  selectDefaultEmailAddress(contact: Contact) {
    contact.emailAddressList.map(ea => ea.isSelected = false);
    this.validateConsentForContact(contact);
  }

  validateConsentForContact(contact: Contact) {
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CONSENT_TOOL) && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MESSAGE_CONSENT_REQUIRED)) {
      let email: EmailActivity = this.emailService.selectedActivity;
      if (email.statuscode == 1) {
        if (email.template_id && email.templateRefProductId) {
          let consentedContact = this.consentService.doesContactHaveConsentForChannel(contact, email.channelType, email.scheduledStart, email.templateRefProductId);
          if (consentedContact.hasConsented) {
            if (consentedContact.contact.emailAddressList.length && contact.emailAddressList.length) {
              contact.emailAddressList.forEach(e => {
                const emailAddr = consentedContact.contact.emailAddressList.find(ea => ea.emailAddressId == e.emailAddressId && ea.hasConsented);
                if (emailAddr) {
                  emailAddr.isSelectable = true;
                  if (email.emailActivityParties.length) {
                    emailAddr.isSelected = email.emailActivityParties.find(e => e.indskr_contactid == contact.ID) ?
                      !!email.emailActivityParties
                      .find(e => e.indskr_contactid == contact.ID).emailAddresses
                      .find(e => e.email_addressid == emailAddr.emailAddressId) : true;
                  } else {
                    emailAddr.isSelected = true;
                  }
                }
              });
            }
          } else {
            this.setPrimaryEmailAddress(contact);
          }
        } else {
          this.setPrimaryEmailAddress(contact);
        }
      }
    } else {
      this.setPrimaryEmailAddress(contact);
    }
  }

  setPrimaryEmailAddress(contact: Contact) {
    contact.emailAddressList.map(ea => ea.isSelectable = true);
    let anyEmailsSelected: boolean = contact.emailAddressList.some(e => e.isSelected);
    if (!anyEmailsSelected) {
      const defaultEmailAddr = contact.emailAddressList.find(ea => ea.isPrimary);
      if (defaultEmailAddr) {
        defaultEmailAddr.isSelected = true
      }
    }
  }

  restrictedViewRemoveFromSelected = (event,contact) => {
    let idx = this.restrictedViewSelectedContacts.findIndex(a=> a.ID == contact.ID);
    if(idx >= 0){
      let foundContact = this.contactService.getContactByID(contact.ID);
      if(foundContact){
        this.removeFromSelected(event,foundContact,null);
      }else{
        this.isDirty = true;
      }
      this.restrictedViewSelectedContacts.splice(idx,1);
      this._initAllSectionHeaderModel();
    }
    this.initContactsHeaderLeft();
  };

  removeFromSelected = (event, contact, contactIndex: number) => {
    if (event && event.stopPropagation) event.stopPropagation();

    contact.isSelected = false;
    let selectedIndex = this.selectedContacts.findIndex(sAppContact => sAppContact.ID === contact.ID);
    if (selectedIndex < 0) return;

    let [removedContact] = this.selectedContacts.splice(selectedIndex, 1);
    if (removedContact) {
      this.eventsToolService.removePendingParticipantDetails(removedContact.ID);
      this.navService.popToRootChildNavPageWithPageTracking();
    }

    if (this.emailService.selectedActivity) {
      if (this.emailService.selectedActivity.emailActivityParties) {
        let item = this.emailService.selectedActivity.emailActivityParties.find(emailParty => {
          return (emailParty.indskr_contactid === contact.ID);
        });
        if (item) {
          item.isSelected = false;
        }
      }
    } else if (this.contactService.accessedContactListFrom == PageName.OpportunityDetailsComponent || this.contactService.accessedContactListFrom == PageName.NewOpportunityComponent ) {

    } else if (this.contactService.accessedContactListFrom === PageName.MarketScanDetailsComponent) {
      if(this.isAllSegmentedContactSelected) {
        this.isAllSegmentedContactSelected = false;
      }
    } else if (this.contactService.accessedContactListFrom === PageName.EventDetailsPageComponent) {
      const index = this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave']?.findIndex(participant => participant.id == contact.ID);
      if (index >= 0) {
        //For unsaved contacts, update noOfPassesLeft on removing
        this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave'][index]['eventPasses'].forEach(ePass => {
          const passIndex = this.eventsToolService.selectedEventOnEventsTool.passes.findIndex(pass => pass.msevtmgt_passId == ePass.msevtmgt_passId);
          if (passIndex >= 0) {
            this.eventsToolService.selectedEventOnEventsTool.passes[passIndex]['temp_msevtmgt_noOfPassesLeft'] += 1;
          }
        })
        this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave'].splice(index, 1);
      } else {
        const index = this.eventsToolService.selectedEventOnEventsTool.participants?.findIndex(participant => participant.id == contact.ID);
        if (index >= 0 && this.eventsToolService.selectedEventOnEventsTool.participants[index].eventPasses) {
          //For saved contacts, update noOfPassesLeft on removing
          this.eventsToolService.selectedEventOnEventsTool.participants[index].eventPasses.forEach(ePass => {
            const passIndex = this.eventsToolService.selectedEventOnEventsTool.passes.findIndex(pass => pass.msevtmgt_passId == ePass.msevtmgt_passId);
            if (passIndex >= 0) {
              this.eventsToolService.selectedEventOnEventsTool.passes[passIndex]['temp_msevtmgt_noOfPassesLeft'] += 1;
            }
          })
        }
      }
      this.events.publish('selectedEventParticipantChanged', {selectedContacts: this.selectedContacts, contact: removedContact});
    } else if (this.contactService.accessedContactListFrom === PageName.SurgeryOrderDetailsComponent || this.contactService.accessedContactListFrom === PageName.ProcedureTrackerComponent) {
    } else if (this.contactService.accessedContactListFrom === PageName.AccountPlanDetailsComponent || this.contactService.accessedContactListFrom === PageName.MarketingPlanDetails) {
    } else if (!this.contactService.isSchedulerInvoked && !this.contactService.isCaseManagementInvoked) {
      let item = this.activityService.selectedActivity['contacts'].find((o) => {
        return (o.ID === contact.ID);
      });
      if (item) {
        item.isSelected = false;
      }
    }
    else {

    }
    this.isDirty = this.isContactsDirty();
    this.updateEmptyMessage();
    this.initContactsHeaderLeft();
    this._initAllSectionHeaderModel();
    this.checkEnableDisableSelectAllOption();
    this._cd.detectChanges();
  };

  isContactsDirty(): boolean {
    if (this.contactService.isSchedulerInvoked) {
      if(this.selectedContacts.length > 0) {
        return true;
      } else if(this.selectedContacts.length === 0) {
        return false;
      }
    }
    else if (this.contactService.isCaseManagementInvoked) {
      if(!this.caseManagementService.currentCase._case_contact_id){
        return this.selectedContacts.length != 0;
      }else{
        if(this.selectedContacts.length == 0){
          return true;
        }else{
          return this.selectedContacts[0].ID != this.caseManagementService.currentCase._case_contact_id;
        }
      }
    } else if (this.contactService.accessedContactListFrom == PageName.OpportunityDetailsComponent || this.contactService.accessedContactListFrom == PageName.NewOpportunityComponent) {
      return _.xor(this._initiallySelectedContacts,this.selectedContacts).length !== 0;
    }else if (this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent) {
      return _.xor(this._initiallySelectedContacts,this.selectedContacts).length !== 0 || !_.isEmpty(this.eventsToolService.selectedEventOnEventsTool?.['eventPassesToSave']);
    }else if (this.contactService.accessedContactListFrom == PageName.SurgeryOrderDetailsComponent || this.contactService.accessedContactListFrom === PageName.ProcedureTrackerComponent) {
      return _.xor(this._initiallySelectedContacts,this.selectedContacts).length !== 0;
    } else if( this.contactService.accessedContactListFrom === PageName.MarketScanDetailsComponent) {
      const marketScan = this.marketScanService.getSelectedMarketScan();
      if (marketScan.contacts && marketScan.contacts.filter(c => c.action != Action.DELETE).length === this.selectedContacts.length) {
        return this.selectedContacts.some(item => {
          const found = marketScan.contacts.filter(c => c.action != Action.DELETE).some(contact => contact.contactId === item.ID && item['sequence'] === contact.sequence);
          if (!found) return true;
        });
      } else {
        return true;
      }
    } else if( this.contactService.accessedContactListFrom === PageName.AccountPlanDetailsComponent || this.contactService.accessedContactListFrom === PageName.MarketingPlanDetails) {
      return this.selectedContacts?.length != this.selectedContactIds?.length ? true : this.selectedContacts.some(con => !this.selectedContactIds.includes(con.ID));
    } else {
      if (this.emailService.selectedActivity) {
        if (this.emailService.selectedActivity.emailActivityParties &&
          this.selectedContacts.length === this.emailService.selectedActivity.emailActivityParties.length) {
          this.selectedContacts.some(item => {
            let found = this.emailService.selectedActivity.emailActivityParties.some(emailActivityParty =>
              emailActivityParty.indskr_contactid === item.ID
            );
            if (!found) {
              return true;
            }
          });
        }
        return true;
      }
      else if (this.activityService.selectedActivity['contacts'] && this.selectedContacts.length === this.activityService.selectedActivity['contacts'].length) {
        return this.selectedContacts.some(item => {
          let found = this.activityService.selectedActivity['contacts'].some(contact => item.ID === contact.ID);
          if (!found) {
            return true;
          }
        });
      }
      else {
        return true;
      }
    }
  };

  async makeSelection(event, contact: Contact) {
    if (event && event.stopPropagation) event.stopPropagation();
    const phoneCallSelection = this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.PhoneCall && this.contactService.accessedContactListFrom == PageName.PhoneCallDetailsComponent && this.contactService.contactPageMode == ComponentViewMode.SELECTION;
    if (phoneCallSelection && !this.device.isOffline && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.VOICE_CALL)) {
      const constentTypes = [100000000, 100000001];
      const consents = await this.consentService.getConsentsByContactIdAndConsentTypes(contact.ID, constentTypes);
      if (!consents.find(c => c.consenttype == 100000000)) {
        this.notificationService.notify(this.translate.instant('VOICE_CALL_GENERAL_CONSENT_NOT_FOUND'), 'Meeting Details', 'top', ToastStyle.DANGER);
        return;
      }
      if (!consents.find(c => c.consenttype == 100000001)) {
        this.notificationService.notify(this.translate.instant('VOICE_CALL_RECORDING_CONSENT_NOT_FOUND'), 'Meeting Details', 'top', ToastStyle.INFO);
      }
    }
    if (this.radioSelectedContact && contact.ID == this.radioSelectedContact.ID) return;
    if (this.ensureDontContactAMAContact(contact)) return;

    this.nonGuestContacts.map((o) => {
      o.isRadioSelected = false;
      if (o.ID == contact.ID) {
        o.isRadioSelected = true;
        this.radioSelectedContact = o;
      }
    })
    if(this.contactService.accessedContactListFrom == PageName.DynamicFormComponent && this.contactService.contactPageMode == ComponentViewMode.SELECTION){
      this.isDirty = this.radioSelectedContact.ID != this.navParams.data.selectedContactId;
    }else if(this.contactService.accessedContactListFrom == PageName.FollowUpActionDetailComponent && this.contactService.contactPageMode == ComponentViewMode.SELECTION){
      if(this.followUpActivityDataService.inMeetingFollowupActionActivity && this.navParams && this.navParams.data && this.navParams.data.type && (this.navParams.data.type == 'InMeetingFollowupPushedContactSelectionView' || this.navParams.data.type == 'InPresentationFollowupPushedContactSelectionView')){
        this.isDirty  = this.radioSelectedContact.ID != this.followUpActivityDataService.inMeetingFollowupActionActivity.contactId;
      }else if(this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.FollowUp){
        this.isDirty = this.radioSelectedContact.ID != (this.activityService.selectedActivity as FollowUpActivity).contactId;
      }
    }else if(this.contactService.accessedContactListFrom == PageName.KOLDetailsComponent && this.contactService.contactPageMode == ComponentViewMode.SELECTION){
      if(this.contactListData && (this.contactListData.kol as KOLStatus).contactId){
        this.isDirty  = this.radioSelectedContact.ID != (this.contactListData.kol as KOLStatus).contactId;
      }else{
        this.isDirty = true;
      }
    }
    else if(this.contactService.accessedContactListFrom == PageName.ContactMedicalInsightComponent && this.contactService.contactPageMode == ComponentViewMode.SELECTION){
      if(this.contactListData && (this.contactListData.medicalInsight as ContactMedicalInsight).contactId){
        this.isDirty  = this.radioSelectedContact.ID != (this.contactListData.medicalInsight as ContactMedicalInsight).contactId;
      }else{
        this.isDirty = true;
      }
    }else if(this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Sample && this.contactService.accessedContactListFrom == PageName.NewSampleActivityComponent && this.contactService.contactPageMode == ComponentViewMode.SELECTION){
      this.isDirty = this.radioSelectedContact.ID != (this.activityService.selectedActivity as SampleActivity).contactID;
    }else if (this.contactService.accessedContactListFrom == PageName.CustomerInsightComponent) {
      const selectedContactId: string =  this.navParams.data && this.navParams.data.contactId;
      this.isDirty = this.radioSelectedContact.ID != selectedContactId;
    } else if (phoneCallSelection) {
      this.isDirty = true;
      this.mobileNumberList = [];
      if(this.radioSelectedContact.mobilePhone || this.radioSelectedContact.telephone || this.radioSelectedContact.indskr_alternatephone1 || this.radioSelectedContact.indskr_alternatephone2 || this.radioSelectedContact.indskr_alternatephone3){
      this.mobileNumberList.push({'phoneNumber': this.radioSelectedContact.mobilePhone ? this.radioSelectedContact.mobilePhone : '', 'identity': 'mobilePhone'})
      this.mobileNumberList.push({'phoneNumber': this.radioSelectedContact.indskr_alternatephone1 ? this.radioSelectedContact.indskr_alternatephone1 : '', 'identity': 'alternatephone1'})
      this.mobileNumberList.push({'phoneNumber': this.radioSelectedContact.indskr_alternatephone2 ? this.radioSelectedContact.indskr_alternatephone2 : '', 'identity': 'alternatephone2'})
      this.mobileNumberList.push({'phoneNumber': this.radioSelectedContact.indskr_alternatephone3 ? this.radioSelectedContact.indskr_alternatephone3 : '', 'identity': 'alternatephone3'})
      this.mobileNumberList.push({'phoneNumber': this.radioSelectedContact.telephone ? this.radioSelectedContact.telephone : '', 'identity': 'telephone'})
      }
      if(this.mobileNumberList.length){
       const isNotEmpty = (element) => !_.isEmpty(element.phoneNumber);
       let index = this.mobileNumberList.findIndex(isNotEmpty);
       this.mobileNumberList[index].isChecked = true;
       this.selectedMobileNumber = this.mobileNumberList[index].phoneNumber
      }
      this.trackingService.tracking('Click+button', TrackingEventNames.PHONECALL);
    }else if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Appointment && this.contactService.accessedContactListFrom == PageName.ActivitiesDetailsPaneComponent && this.isSingleSelectionHCOHCPEnabled){
      let selectedID = '';
      let alreadySelID = '';
      if(this.selectedContacts.length > 0){
        selectedID = this.selectedContacts[0].ID;
      }
      if((this.activityService.selectedActivity as AppointmentActivity).contacts.length > 0){
        alreadySelID = (this.activityService.selectedActivity as AppointmentActivity).contacts[0].ID;
      }
      this.isDirty = selectedID != alreadySelID;
    }
    else {
      this.isDirty = true;
    }
    this.initContactsHeaderLeft()
    this._cd.detectChanges();
    if ((this.contactService.accessedContactListFrom == PageName.OpportunityDetailsComponent || this.contactService.accessedContactListFrom == PageName.NewOpportunityComponent) && this.opportunityService.stakeholderRoles) {
      this.updateConnectionRoleToSelectedContact(null, contact, this.opportunityService.stakeholderRoles[0]);
    }
    //this.onCloseModal(true)
  };

  removeSelection(event, contact) {
    if (event && event.stopPropagation) event.stopPropagation();
    if (this.radioSelectedContact && contact.ID != this.radioSelectedContact.ID) return;
    this.nonGuestContacts.map((o) => {
      o.isRadioSelected = false;
    })
    this.radioSelectedContact = undefined;
    if(this.contactService.accessedContactListFrom == PageName.DynamicFormComponent && this.contactService.contactPageMode == ComponentViewMode.SELECTION){
      if(this.navParams.data.selectedContactId){
        this.isDirty = true;
      }else{
        this.isDirty = false;
      }
    }
    else if(this.contactService.accessedContactListFrom == PageName.FollowUpActionDetailComponent && this.contactService.contactPageMode == ComponentViewMode.SELECTION){
      if(this.followUpActivityDataService.inMeetingFollowupActionActivity && this.navParams && this.navParams.data && this.navParams.data.type && (this.navParams.data.type == 'InMeetingFollowupPushedContactSelectionView' || this.navParams.data.type == 'InPresentationFollowupPushedContactSelectionView')){
        this.isDirty = true;
      }else if(this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.FollowUp && (this.activityService.selectedActivity as FollowUpActivity).contactId){
        this.isDirty = true;
      }else{
        this.isDirty = false;
      }
    }else if(this.contactService.accessedContactListFrom == PageName.KOLDetailsComponent && this.contactService.contactPageMode == ComponentViewMode.SELECTION){
      if(this.contactListData && (this.contactListData.kol as KOLStatus).contactId){
        this.isDirty = true;
      }else{
        this.isDirty = false;
      }
    }else if(this.contactService.accessedContactListFrom == PageName.ContactMedicalInsightComponent && this.contactService.contactPageMode == ComponentViewMode.SELECTION){
      if(this.contactListData && (this.contactListData.medicalInsight as ContactMedicalInsight).contactId){
        this.isDirty = true;
      }else{
        this.isDirty = false;
      }
    }else if(this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Sample && this.contactService.accessedContactListFrom == PageName.NewSampleActivityComponent && this.contactService.contactPageMode == ComponentViewMode.SELECTION){
      if((this.activityService.selectedActivity as SampleActivity).contactID){
        this.isDirty = true;
      }else{
        this.isDirty = false;
      }
    }else if (this.contactService.accessedContactListFrom == PageName.CustomerInsightComponent && this.contactService.contactPageMode == ComponentViewMode.SELECTION) {
      this.isDirty = !! (this.navParams.data && this.navParams.data.contactId)
    }else{
      this.isDirty = true;
    }
    this.initContactsHeaderLeft()
    this.updateEmptyMessage()
    this._cd.detectChanges();
  }

  private ensureDontContactAMAContact(contact: Contact) {
    if (this.authenticationOfflineService.hasFeatureAction({ featureAction: FeatureActionsMap.ENABLE_PHONE_CALL_CONSENT_CHECK, skipLDCheck: true })
      && this.contactService.checkIfContactIsAMADoNotContact(contact)) {
      this.notificationService.notify("Unable to select doctor as this doctor has opted out for phone call consent.", 'Contact list', 'top', ToastStyle.DANGER);
      return true;
    }
  }

  updateEmailAddressToSelectedContact(event, selectedContact: Contact, emailAddressDetailsToUpdate: Email) {
    if (event && event.stopPropagation) event.stopPropagation();
    if ((!emailAddressDetailsToUpdate.isSelectable) || this.emailService.selectedActivity.status != 1) return;
    if (emailAddressDetailsToUpdate.isSelected) {
      let alloToDeselect = selectedContact.emailAddressList.filter(emailAddressDetails => emailAddressDetails.isSelected).length === 1;
      if (!alloToDeselect) {
        emailAddressDetailsToUpdate.isSelected = !emailAddressDetailsToUpdate.isSelected;
        this.isDirty = true;
      }
    } else {
      emailAddressDetailsToUpdate.isSelected = !emailAddressDetailsToUpdate.isSelected;
      this.isDirty = true;
    }
    this.initContactsHeaderLeft();
  }

  public slectedContactConnectionRole (selectedContact: Contact, connectionRole: any): any{
    return selectedContact.stakeholderRolesList?.find(role => role.roleid === connectionRole.connectionroleid);
  }
  updateConnectionRoleToSelectedContact(event, selectedContact: Contact, connectionRole: any){
    if (event && event.stopPropagation) event.stopPropagation();
    const contactRole = selectedContact.stakeholderRolesList.find(role => role.roleid === connectionRole.connectionroleid);
    if(!contactRole){
      selectedContact.stakeholderRolesList.push({roleid:connectionRole.connectionroleid,name: connectionRole.name, isSelected: true});
    }else{
      selectedContact.stakeholderRolesList = selectedContact.stakeholderRolesList.filter(role => role.roleid !== connectionRole.connectionroleid);
    }
    this.isDirty = true;
    this.initContactsHeaderLeft();
  }

  async trackToolsBack() {
    //THIS CODE SHOULD BE ENABLED - when kitbooking is getting created from Account tool and we click on account list 
    let isKitBookingDiscarded = await this.setBookingDataService.checkSetBookingDiscardConfirmationRequired().catch(err=>{});
    if(!isKitBookingDiscarded) return;
    if(this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) return;
    this.uiService.isAccountsFromToolDrawer = false;
    this.uiService.isContactsFromToolDrawer = false;
    this.accountService.isEnableGoToAccountsTool = false;
    this.contactService.isEnableGoToContactsTool = false;
    if (this.navService.getActiveChildNavViewPageName() === PageName.DynamicFormComponent && (this.isCCRFormDirty || this.dynamicFormsService.linkedEntityFormIsDirtyTracker)) {
      let cancelText = this.translate.instant("CANCEL");
      if(this.translate.currentLang == 'it') {
        cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
      }
      this.alertService.showAlert({
        title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
        message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')}, this.translate.instant('DISCARD_CONTACT_CR'), cancelText
      ).then (res => {
        if(res.role == "ok") {
          this.isCCRFormDirty = false;
          this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ContactPageComponent);
          this.uiService.isConsentFromToolDrawer = false;
          this.onCloseModal(false);
        }
      });
      return;
    }else{
      this.onCloseModal(false);
      this.events.publish("tools-back-clicked", PageName.ContactPageComponent);
    }
  }

  isDoneButtonDisabled() {
    return (!this.contactService.isSchedulerInvoked && !this.contactService.isCaseManagementInvoked)
      ? !(this.isDirty || this.isSentimentsDirty || this.isKeyMessageSentimentsDirty) : !this.isDirty;
  }

  async onCloseModal(isDone) {
    if (!isDone) {
      if (this.contactService.accessedContactListFrom == PageName.ActivitiesDetailsPaneComponent && this.activityService.prevSelectedActivity instanceof AppointmentActivity
        && this.activityService.selectedActivity instanceof EventActivity) {
        this.activityService.selectedActivity = this.activityService.prevSelectedActivity;
      }
      this.events.publish('updateMandatoryField', PageName.ContactPageComponent);
    }else{
      const activityTypeFieldText = this.activityService.selectedActivity ? this.activityService.selectedActivity?.activityTypeName : undefined;
      let isInStoreMeeting = this.isAccountVisitRecord && !this.isAccountVisitNestedMeeting && activityTypeFieldText === this.translate.instant("INSTORE");
      if (isInStoreMeeting && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT_FORMAT_INSTORE_MR)) {
        let isTargetHCP : boolean = false;
        this.selectedContacts.forEach(contacta => {
          this.targetContactList.forEach(contactb =>{
            if(contacta.ID == contactb.ID){isTargetHCP =true; return ;}
          })
        });
        if(this.selectedContacts.length < 3 || !isTargetHCP){
          this.notificationService.notify(this.translate.instant('FA_INSTORE_CHECK_HCP_MR'), "Meeting Details", "top", ToastStyle.INFO, 3000);
        }
      }
      if (isInStoreMeeting && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT_FORMAT_INSTORE_OSS)) {
        let isTargetHCP : boolean = false, targetHcpCounter : number = 0;
        this.selectedContacts.forEach(contacta => {
          this.targetContactList.forEach(contactb =>{
            if(contacta.ID == contactb.ID){
              if(targetHcpCounter++ >=2){
              isTargetHCP =true; return ;}}
          })
        });
        if(this.selectedContacts.length < 6 || !isTargetHCP){
          this.notificationService.notify(this.translate.instant('FA_INSTORE_CHECK_HCP_OSS'), "Meeting Details", "top", ToastStyle.INFO, 3000);
        }
      }  
    }
    if (!this.contactService.isCaseManagementInvoked && !this.contactService.isConsentInvoked) {
      this.uiService.activeView = this.uiService.prevView;
    }
    this.uiService.isConsentFromToolDrawer = false;
    if(this.contactService.scientInfoModalOpen){
      this.events.publish('closeScientInfoModal');
    }
    if (this.contactService.accessedContactListFrom == PageName.CustomerInsightComponent) {
      if (isDone) {
        //if (this.radioSelectedContact) {
          let insight: TrendingCustomer = this.xperiencesService.newInsight;
          switch (this.selectionMode) {
            case CustomerSelectionMode.FOR_INSIGHT:
              insight.contactid = (this.radioSelectedContact)?this.radioSelectedContact.ID:undefined;
              insight.fullname = (this.radioSelectedContact)?this.radioSelectedContact.fullName:undefined;
              this.events.publish('insights_contact_assignment');
              break;
            case CustomerSelectionMode.FOR_INSIGHT_RELATIONSHIP:
              const relationship: CustomerRelationship = this.navParams.data && this.navParams.data.relationship;
              relationship.contactid = (this.radioSelectedContact)?this.radioSelectedContact.ID:undefined;
              relationship.fullname = (this.radioSelectedContact)?this.radioSelectedContact.fullName:undefined;
            this.events.publish('insights_relationship_contact_assignment');

            default:
              break;
          }
        //} else return;
      }
      this.navService.popWithPageTracking().then(() => {
        this.footerService.initButtons(FooterViews.None);
        this.contactService.accessedContactListFrom = null;
        if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
          if (this.holdContactInformation) {
            this.contactService.contactInformation = this.holdContactInformation;
          }
          this.uiService.activeView = 'ContactPageRightPaneNav';
        } else {
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        }
        this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
      });
      return;
    }
    else if (this.contactService.accessedContactListFrom == PageName.FollowUpActionDetailComponent) {
      if (isDone) {
        if (this.contactService.contactPageMode == ComponentViewMode.SELECTION) {
          let followup:FollowUpActivity;
          if(this.followUpActivityDataService.inMeetingFollowupActionActivity && this.navParams && this.navParams.data && this.navParams.data.type && (this.navParams.data.type == 'InMeetingFollowupPushedContactSelectionView' || this.navParams.data.type == 'InPresentationFollowupPushedContactSelectionView')){
            followup = this.followUpActivityDataService.inMeetingFollowupActionActivity;
          }else{
            followup = this.activityService.selectedActivity as FollowUpActivity
          }
          if (followup) {
            followup.contactId = (this.radioSelectedContact) ? this.radioSelectedContact.ID : '';
            followup.contactNameString = (this.radioSelectedContact) ? this.radioSelectedContact.fullName : '';
            followup.pendingPushToDynamics = true;
            this.events.publish("updateFollowupActivityHeader");

            this._loading = await this.loadingCtrl.create();
            this._loading.present();
            await this.followUpActivityDataService.updateFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalCopy: true, onLocalDatabase: true }, [followup], new Date().getTime()).then(success => {
              // Successfull Action
            }).catch(async error => {
              // Handle error occured during follow up activity updation
              if (error && error['errorCode'] && error['errorCode'] == 'ALREADYCOMPLETEDACTIVITY') {
                await this.activityDataService.updateActivityDetails(followup);
              }
            });
            this._loading.dismiss();
          }
          this.contactService.accessedContactListFrom = null;
          this.uiService.activeView = this.uiService.prevView;
          if(this.navParams && this.navParams.data && this.navParams.data.type && this.navParams.data.type == 'InMeetingFollowupPushedContactSelectionView'){
            if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
              document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
            }
            this.navCtrl.pop({ progressAnimation: false }).then(() => {
              if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
                this.uiService.activeView = 'ContactPageRightPaneNav';
                this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
              }
            });
          }else{
            this.navService.popWithPageTracking().then(succ => {
              if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
                this.uiService.activeView = 'ContactPageRightPaneNav';
                this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
              }
            });
          }

          return;
        }
      }
      this.contactService.accessedContactListFrom = null;
      this.uiService.activeView = this.uiService.prevView;
      if(this.navParams && this.navParams.data && this.navParams.data.type && this.navParams.data.type == 'InMeetingFollowupPushedContactSelectionView'){
        if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
        }
        this.navCtrl.pop({ progressAnimation: false }).then(() => {
        });
      }else{
        this.navService.popWithPageTracking().then(succ => {
          if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
            this.uiService.activeView = 'ContactPageRightPaneNav';
          }
        });
      }
      return;
    }else if(this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent){
      if(isDone){
        const participantIdsTofilter = this.eventsToolService.selectedEventOnEventsTool.participants.filter(contact =>
          contact.indskr_reasons == EventRegistrationStatus.Declined || contact.indskr_reasons == EventRegistrationStatus['Cancelled late']
          || contact.indskr_reasons == EventRegistrationStatus.Cancelled).map(contact => contact.id);

        if (this.selectedContacts.filter(contact => !participantIdsTofilter.includes(contact.ID)).length > this.maximumeventcapacity && this.maximumeventcapacity != "" && this.maximumeventcapacity != null) {
          this.toast.notify(`${this.translate.instant("YOU_CAN_NOT_ADD_CUSTOMERS_EXCEEDING_EVENT_CAPACITY")}`, "");
          return;
        }
        let data;
        this._holdDynamicFormIsDirtyTracker = true;
        if (this.selectedContacts.length > 0 && this.restrictedViewSelectedContacts.length > 0) {
          let selectedContacts = this.restrictedViewSelectedContacts.map(con => {
            const participant = this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave']?.find(participant => participant.id == con.ID);
            return {
              id: con.ID,
              name: con.fullname,
              statecode: con.isActive ? 0 : 1,
              ownerId: con.ownerId,
              customerId: con.customerId,
              title: con.title,
              firstName: con.firstName,
              middleName: con.middleName,
              lastName: con.lastName,
              primaryAccount: con.primaryAccount,
              primarySpecialty: con.primarySpecialty,
              raw: con.raw,
              eventPasses: participant ? participant['eventPasses'] : [],
              primaryEmail:con.raw?.emailaddress1
            }
          });
          data = { selectedItems: selectedContacts, isDone: true };
        }else{
          data = { clearValue: true };
        }
        this._callbackOnDone(data);
        this.navService.popWithPageTracking().then(()=>{
          this.contactService.accessedContactListFrom = null;
          this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
          this.dynamicFormsService.linkedEntityFormIsDirtyTracker = this._holdDynamicFormIsDirtyTracker;
          if(this.holdContactInformation){
            this.contactService.contactInformation = this.holdContactInformation;
          }
          if(this.uiService.activeView != this.uiService.prevView){
            this.uiService.activeView = this.uiService.prevView;
          }
        });
        return;
      } else {
        if(this.eventsToolService.isEventParticipationDirty) {
          this.alertService.showAlert({
            title: this.translate.instant('DISCARD_CHANGES'),
            message: this.translate.instant('CI_R_U_WANT_DISCARD_CHANGES')
          }, this.translate.instant('DISCARD')
          ).then(res => {
            if (res.role == "ok") {
              if(!_.isEmpty(this.eventsToolService.selectedEventOnEventsTool)){
                this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave'] = [];
              }
              this.eventsToolService.isEventParticipationDirty = false;
              this.eventsToolService.clearPendingParticipantDetails();
              if (this.contactService.contactPageMode == this.compViewMode.ADDNEW) {
                this.deltaService.deltaRecordsDTO.contacts.length = 0;
              }
              this.navService.popWithPageTracking().then(()=>{
                this.contactService.accessedContactListFrom = null;
                this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
                this.dynamicFormsService.linkedEntityFormIsDirtyTracker = this._holdDynamicFormIsDirtyTracker;
                if(this.holdContactInformation){
                  this.contactService.contactInformation = this.holdContactInformation;
                }
                if(this.uiService.activeView != this.uiService.prevView){
                  this.uiService.activeView = this.uiService.prevView;
                }
              });
            } else {
              return;
            }
            return;
          });
        } else {
          if(!_.isEmpty(this.eventsToolService.selectedEventOnEventsTool)){
            this.eventsToolService.selectedEventOnEventsTool['eventPassesToSave'] = [];
          }
          this.eventsToolService.clearPendingParticipantDetails();
          this.navService.popWithPageTracking().then(()=>{
            this.contactService.accessedContactListFrom = null;
            this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
            this.dynamicFormsService.linkedEntityFormIsDirtyTracker = this._holdDynamicFormIsDirtyTracker;
            if(this.holdContactInformation){
              this.contactService.contactInformation = this.holdContactInformation;
            }
            if(this.uiService.activeView != this.uiService.prevView){
              this.uiService.activeView = this.uiService.prevView;
            }
          });
          return;
        }
      }
    } else if (this.contactService.accessedContactListFrom == PageName.SurgeryOrderDetailsComponent || this.contactService.accessedContactListFrom === PageName.ProcedureTrackerComponent) {
      if (isDone) {
        let data;
        if(this.selectedContacts.length > 0 && this.restrictedViewSelectedContacts.length > 0){
          let selectedContacts = this.restrictedViewSelectedContacts.map(con => {
            return {
              id: con.ID,
              title: con.title,
              firstName: con.firstName,
              middleName: con.middleName,
              lastName: con.lastName,
              fullname: con.fullname,
              isActive: con.isActive,
              statusCode: con.statusCode
            }
          });
          data = { selectedItems: selectedContacts, isDone: true };
        }else{
          data = { clearValue: true };
        }

        //if LiveMeet activty then don't update location
        if(this.activityService.selectedActivity.location !== "LiveMeet"){
          // customer primary location will be selected if available.
          this.activityService.selectedActivity.location = '';

          if (this.selectedContacts.length) {

            let compositeAddList = (contact) => {
              if (contact.addressesList && contact.addressesList.length) {
                return (<any[]>contact.addressesList).find((address) => address.isPrimary);
              } else {
                return;
              }
            }

            let unique =  new Set (this.selectedContacts.map(compositeAddList));
            if (unique) {
            let array = Array.from(unique);
              if (Array.from(unique).length == 1) {
                let first = array[0];
                if (first && first.compositeAdd && first.compositeAdd.length) {
                  this.activityService.selectedActivity.location = Array.from(unique)[0].compositeAdd;
                }
              } else{
                let uniqueAddress = new Set (array.map(addres =>{
                  if(addres){
                    return addres.compositeAdd;
                  }
                }));
                let uniqueAddressList = Array.from(uniqueAddress);
                if(Array.from(uniqueAddress).length == 1){
                  this.activityService.selectedActivity.location = uniqueAddressList[0];
                }
              }
            }
          }
        }

        this._callbackOnDone(data);
      }
      if(this.navParams && this.navParams.data && this.navParams.data.type && this.navParams.data.type == 'InMeetingProcedurePushedContactSelectionView'){
        if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
        }
        this.navCtrl.pop({ progressAnimation: false }).then(() => {
          this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
          this.uiService.activeView = this.uiService.prevView;
          this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
          if(this.navService.getCurrentMasterPageName()==PageName.ContactPageComponent){
            this.contactService.accessedContactListFrom = PageName.ToolsDrawer
          }
          if (this.holdContactInformation) {
            this.contactService.contactInformation = this.holdContactInformation;
            this.addToSelected(null, this.holdContactInformation);
          }
        });
      }else{
        this.navService.popWithPageTracking().then(()=>{
          this.contactService.accessedContactListFrom = null;
          this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
          this.dynamicFormsService.linkedEntityFormIsDirtyTracker = this._holdDynamicFormIsDirtyTracker;
          if(this.holdContactInformation){
            this.contactService.contactInformation = this.holdContactInformation;
          }
          if(this.uiService.activeView != this.uiService.prevView){
            this.uiService.activeView = this.uiService.prevView;
          }
          if(this.navParams && this.navParams.data && this.navParams.data.type && this.navParams.data.type == 'PushedContactSelectionView'){
            this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
          }

        });
      }
      return;
    } else if(this.contactService.accessedContactListFrom == PageName.DynamicFormComponent){
      if(isDone){
        let data;
        this._holdDynamicFormIsDirtyTracker = true;
        if(this.radioSelectedContact){
          data = { selectedItem: {id: this.radioSelectedContact.ID, name: this.radioSelectedContact.fullName}, isDone: true };
        }else{
          data = { clearValue: true };
        }
        this._callbackOnDone(data);
      }
      this.contactService.contactPageMode = this.compViewMode.LISTVIEW;
      const currentPageName = this.navService.getCurrentPageName();
      if (currentPageName == PageName.ContactPageComponent) {
        this.uiService.activeView = 'ContactPageRightPaneNav';
        this.uiService.prevView = 'ContactPageRightPaneNav';
      }
      this.navService.popWithPageTracking().then(()=>{
        this.contactService.accessedContactListFrom = PageName.ToolsDrawer;
        this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        this.dynamicFormsService.linkedEntityFormIsDirtyTracker = this._holdDynamicFormIsDirtyTracker;
        if(this.holdContactInformation){
          this.contactService.contactInformation = this.holdContactInformation;
        }
        if(this.uiService.activeView != this.uiService.prevView){
          this.uiService.activeView = this.uiService.prevView;
        }
      });

      return;
    }
    else if (this.contactService.accessedContactListFrom == PageName.ScientificPlanFollowUpTask) {
      if (isDone) {
        if (this.contactService.contactPageMode == ComponentViewMode.SELECTION) {
          if (this.activityService.selectedActivity.type == ActivityType.FollowUp) {
            (this.activityService.selectedActivity as FollowUpActivity).contactId = (this.radioSelectedContact) ? this.radioSelectedContact.ID : '';
            (this.activityService.selectedActivity as FollowUpActivity).contactNameString = (this.radioSelectedContact) ? this.radioSelectedContact.fullName : '';
            (this.activityService.selectedActivity as FollowUpActivity).pendingPushToDynamics = true;
            this.events.publish("updateFollowupActivityHeader");

            this._loading = await this.loadingCtrl.create();
            this._loading.present();
            await this.followUpActivityDataService.updateFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalCopy: true, onLocalDatabase: true }, [(this.activityService.selectedActivity as FollowUpActivity)], new Date().getTime()).then(success => {
              // Successfull Action
            }).catch(async error => {
              // Handle error occured during follow up activity updation
              if (error && error['errorCode'] && error['errorCode'] == 'ALREADYCOMPLETEDACTIVITY') {
                await this.activityDataService.updateActivityDetails((this.activityService.selectedActivity as FollowUpActivity));
              }
            });
            this._loading.dismiss();
          }
          this.contactService.accessedContactListFrom = null;
          this.uiService.activeView = this.uiService.prevView;
          this.navService.popWithPageTracking().then(succ => {
            if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
              this.uiService.activeView = 'ContactPageRightPaneNav';
              this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
            }
          });
          return;
        }
      }
      this.contactService.accessedContactListFrom = null;
      this.uiService.activeView = this.uiService.prevView;
      this.navService.popWithPageTracking().then(succ => {
        if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
          this.uiService.activeView = 'ContactPageRightPaneNav';
          this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        }
      });

      return;
    }
    else if (this.contactService.accessedContactListFrom == PageName.KOLDetailsComponent) {

      if (isDone) {
        if (this.contactService.contactPageMode == ComponentViewMode.SELECTION) {
          let data;
          if(this.radioSelectedContact){
            data  = { selectedItem: this.radioSelectedContact, isDone: true };
          }else{
            data = { clearValue: true, isDone:true };
          }
          this._callbackOnDone(data);
          // this.contactService.accessedContactListFrom = null;
          this.contactService.accessedContactListFrom = PageName.ToolsDrawer
          this.uiService.activeView = this.uiService.prevView;
          this.navService.popWithPageTracking().then(succ => {
            if (this.holdContactInformation) {
              this.contactService.contactInformation = this.holdContactInformation;
            }
            // if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
              this.uiService.activeView = 'ContactPageRightPaneNav';
              this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
            // }
          });
          return;
        }
      }
      this._callbackOnDone(null);
      // this.contactService.accessedContactListFrom = null;
      this.contactService.accessedContactListFrom = PageName.ToolsDrawer
      this.uiService.activeView = this.uiService.prevView;
      this.navService.popWithPageTracking().then(succ => {
        // if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
          if (this.holdContactInformation) {
            this.contactService.contactInformation = this.holdContactInformation;
          }
          this.uiService.activeView = 'ContactPageRightPaneNav';
        // }
      });
      return;
    }else if (this.contactService.accessedContactListFrom == PageName.ContactMedicalInsightComponent) {

      if (isDone) {
        if (this.contactService.contactPageMode == ComponentViewMode.SELECTION) {
          let data;
          if(this.radioSelectedContact){
            data  = { selectedItem: this.radioSelectedContact, isDone: true };
          }else{
            data = { clearValue: true, isDone:true };
          }
          this._callbackOnDone(data);
          // this.contactService.accessedContactListFrom = null;
          this.contactService.accessedContactListFrom = PageName.ToolsDrawer
          this.uiService.activeView = this.uiService.prevView;
          this.navService.popWithPageTracking().then(succ => {
            if (this.holdContactInformation) {
              this.contactService.contactInformation = this.holdContactInformation;
            }
            // if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
              this.uiService.activeView = 'ContactPageRightPaneNav';
              this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
            // }
          });
          return;
        }
      }
      this._callbackOnDone(null);
      // this.contactService.accessedContactListFrom = null;
      this.contactService.accessedContactListFrom = PageName.ToolsDrawer
      this.uiService.activeView = this.uiService.prevView;
      this.navService.popWithPageTracking().then(succ => {
        // if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
          if (this.holdContactInformation) {
            this.contactService.contactInformation = this.holdContactInformation;
          }
          this.uiService.activeView = 'ContactPageRightPaneNav';
        // }
      });
      return;
    }
    else if(((this.contactService.accessedContactListFrom == PageName.PhoneCallDetailsComponent)
          || (this.contactService.accessedContactListFrom == PageName.ActivitiesDetailsPaneComponent
            && this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.PhoneCall))){
      if(isDone){
        if (!this._loading) {
          this._loading = await this.loadingCtrl.create();
          this._loading.present();
        }
          this.trackingService.tracking('Selectphonenumber', TrackingEventNames.PHONECALL)
          this.trackingService.tracking('DoneClicked', TrackingEventNames.PHONECALL);
          let existingContact;
          if ((this.activityService.selectedActivity as PhoneActivity).contacts.length === 1 && this.radioSelectedContact) {
            existingContact = this.activityService.selectedActivity['contacts'].find((c) => {
              return c.ID == this.radioSelectedContact.ID;
            })
          }
        let updateAccountsForMeetingFlag: boolean = false;
        let accountsToUpdate: Account[] = [];
        if ((this.activityService.selectedActivity as PhoneActivity).accounts.length === 1 && (this.activityService.selectedActivity as PhoneActivity).contacts.length === 1) {
          let affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting((this.activityService.selectedActivity as PhoneActivity).contacts);
          if (affiliatedAccounts.length === 1 && affiliatedAccounts[0].id == (this.activityService.selectedActivity as PhoneActivity).accounts[0].id) {
            updateAccountsForMeetingFlag = true;
            accountsToUpdate = [];
          }
        }
        if ((this.activityService.selectedActivity as PhoneActivity).contacts.length === 0 && this.radioSelectedContact && (this.activityService.selectedActivity as PhoneActivity).accounts.length === 0) {
          let affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting([this.radioSelectedContact]);
            updateAccountsForMeetingFlag = true;
            accountsToUpdate = affiliatedAccounts;
            this.contactService.moveContactToSelected(this.radioSelectedContact);
        }
        if ((this.activityService.selectedActivity as PhoneActivity).contacts.length === 0 && this.radioSelectedContact && (this.activityService.selectedActivity as PhoneActivity).accounts.length > 0) {
            this.contactService.moveContactToSelected(this.radioSelectedContact);
        }
        if ((this.activityService.selectedActivity as PhoneActivity).contacts.length === 1 && this.radioSelectedContact  && (this.activityService.selectedActivity as PhoneActivity).accounts.length > 0) {
          let affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting([this.radioSelectedContact]);
          if (!_.isEmpty(affiliatedAccounts)) {
            updateAccountsForMeetingFlag = true;
            accountsToUpdate = affiliatedAccounts;
            this.contactService.moveContactToSelected(this.radioSelectedContact);
          }
        }
        if ((this.activityService.selectedActivity as PhoneActivity).contacts.length === 1 && this.radioSelectedContact  && (this.activityService.selectedActivity as PhoneActivity).accounts.length === 0) {
          let affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting([this.radioSelectedContact]);
            updateAccountsForMeetingFlag = true;
            accountsToUpdate = affiliatedAccounts;
            this.contactService.moveContactToSelected(this.radioSelectedContact);
        }
        if(!this.radioSelectedContact){
          (this.activityService.selectedActivity as PhoneActivity).contacts = [];
        }
        if (updateAccountsForMeetingFlag) {
          await this._updateAccountsOfSelectedPhoneCall(accountsToUpdate);
        }
        if(!existingContact){
          const newSubject = ((this.activityService.selectedActivity as PhoneActivity).contacts.length != 0) ? (this.activityService.selectedActivity as PhoneActivity).contactString : '';
          if (!isEmpty(newSubject)) {
            this.activityService.selectedActivity.subject = `${newSubject} - Phone Call`;// ${this.translate.instant('NEW_ACTIVITY_PHONECALL')}// This is changes as it wass changing teh special Characters.
          }
          else {
            this.activityService.selectedActivity.subject = 'Phone Call'//this.translate.instant('NEW_ACTIVITY_PHONECALL');
          }
        }

        let phoneCallPayLoad = this.phoneCallDataService.setPhoneCallPayload(this.activityService.selectedActivity as PhoneActivity);

        if(!(this.activityService.selectedActivity as PhoneActivity).contacts.length){
          (this.activityService.selectedActivity as PhoneActivity).selectedMobileNumber = '';
        }

        if(this.selectedMobileNumber){
          (this.activityService.selectedActivity as PhoneActivity).selectedMobileNumber = this.selectedMobileNumber;
        }

        if (!this.device.isOffline && !this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID)) {
            await this.phoneCallDataService.updatePhoneCall(this.activityService.selectedActivity as PhoneActivity, phoneCallPayLoad); //CWD-2414 Update the activity subject if auto subject feature action is enabled
            // this.activityService.isActivityUpdate = true;
        }
        else {
            await this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity);
        }
        // this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity as PhoneActivity)
        // this.events.publish('refreshAgenda');
        (this.activityService.getActivityByID(this.activityService.selectedActivity.ID)as PhoneActivity).selectedMobileNumber = this.selectedMobileNumber;
        this._loading.dismiss();

      }

      else {
        this.trackingService.tracking('ContactCancelClicked', TrackingEventNames.PHONECALL)
      }
      this.navService.popWithPageTracking().then(() => {
        if (!this.uiService.showCancelDoneOnActivityDetails) {
          // if the selected activity at home and selected activity in activtyService are same we can assign teh selected activity to selecetd activity at home
          //https://exeevo.atlassian.net/browse/OMNI-21788
          //this issue is happening because we have this.activityService.selectedActivityAtHome not updated and while popping we are updating the activityServic.selectedActivity with selectedActivityAtHome.
          //if both the activities are same, we can assign selected activityAthome with updated valueor if we have both teh activities same Don't need to
          if (!_.isEmpty(this.activityService.selectedActivityAtHome) && !_.isEmpty(this.activityService.selectedActivity) && this.activityService.selectedActivityAtHome.ID === this.activityService.selectedActivity.ID) {
              this.activityService.selectedActivityAtHome = this.activityService.selectedActivity;
          }

         this.activityService.selectedActivity = this.activityService.selectedActivityAtHome ? this.activityService.selectedActivityAtHome : this.activityService.selectedActivity;

          this.uiService.activeView = this.uiService.getActiveViewBasedOnActivity(this.activityService.selectedActivity);
          this.events.publish("detectChangesOnPhoneCallActivityDetails"); // refresh meeting structure
        }

        this.uiService.showNewActivity = false;
        this.events.publish('updateMandatoryField', PageName.ContactPageComponent);
        this.uiService.prevView = 'contactDetails';
        this.footerService.initButtons(FooterViews.PHONE_CALL);

        if (this.navService.getCurrentPageName() == PageName.ContactPageComponent){
            if (this.holdContactInformation) {
              this.contactService.contactInformation = this.holdContactInformation;
              this.addToSelected(null, this.holdContactInformation);
            }
            this.contactService.contactPageMode = this.compViewMode.LISTVIEW;
            this.uiService.activeView = 'phoneCallTimelineDetails';
            this.activityDataService.activityDetailsLoaded = true;
            this.contactService.accessedContactListFrom = PageName.ToolsDrawer;
        // } else if (this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan === 'accountPlansTab') {
        } else if (this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans') {
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        }

        // if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
      });
    }
    else if (this.contactService.accessedContactListFrom == PageName.OpportunityDetailsComponent || this.contactService.accessedContactListFrom == PageName.NewOpportunityComponent) {
      if (isDone) {
        if(this.contactListData.for == 'NewOpportunityContactSelection'){
          if (this.contactService.contactPageMode == ComponentViewMode.SELECTION) {
            let data;
            if(this.radioSelectedContact){
              data  = { selectedItem: this.radioSelectedContact, isDone: true };
            }else{
              data = { clearValue: true, isDone:true };
            }
            this._callbackOnDone(data);
            // this.contactService.accessedContactListFrom = null;
            this.contactService.accessedContactListFrom = PageName.ToolsDrawer
            this.uiService.activeView = this.uiService.prevView;
            this.navService.popWithPageTracking().then(succ => {
              if (this.holdContactInformation) {
                this.contactService.contactInformation = this.holdContactInformation;
              }
              // if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
                this.uiService.activeView = 'ContactPageRightPaneNav';
                this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
              // }
            });
            return;
          }
        }
        let data: ContactSelectionObservableModel = {
          for: 'OpportunityDetails',
          id: (this.contactListData.opportunity as Opportunity).ID,
          contacts: this.contactService.contactPageMode == ComponentViewMode.ADDNEW ? this.selectedContacts : this.contactService.contactPageMode == ComponentViewMode.SELECTION && this.radioSelectedContact ? [this.radioSelectedContact] : []
        }
        this.contactService.setSelectedContacts(data);
        this.contactService.accessedContactListFrom = null;
        this.uiService.activeView = this.uiService.prevView;
        this.navService.popWithPageTracking().then(succ => {
          if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
            this.uiService.activeView = 'ContactPageRightPaneNav';
            this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
          }
        });
        return;
      }
      this.contactService.accessedContactListFrom = null;
      this.uiService.activeView = this.uiService.prevView;
      this.navService.popWithPageTracking().then(succ => {
        if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
          this.uiService.activeView = 'ContactPageRightPaneNav';
        }
      });
      return;
    } else if (this.contactService.accessedContactListFrom == PageName.AccountPlanDetailsComponent || this.contactService.accessedContactListFrom === PageName.MarketingPlanDetails) {
      if (isDone) {
        this.uiService.displayLoader();
        const contactAccAffs = await this.contactService.getAccountContactAffiliationsFromDB();
        const selectedContacts = this.selectedContacts.map(selectedContact => { return { id: selectedContact.ID, name: selectedContact.fullName } });
        const affiliatedIds = contactAccAffs.filter(contactAccAff =>
          this.selectedAccountIds.includes(contactAccAff.accountid) && selectedContacts.some(con => con.id == contactAccAff['indskr_accountcontactaffiliation.indskr_contactid']))
          .map(contactAccAff => {
            return {
              indskr_accountcontactaffiliationid: contactAccAff['indskr_accountcontactaffiliation.indskr_accountcontactaffiliationid'],
              contactId: contactAccAff['indskr_accountcontactaffiliation.indskr_contactid'],
              contactFullName: selectedContacts.find(con => con.id == contactAccAff['indskr_accountcontactaffiliation.indskr_contactid']).name
            }
          })
        // this.uiService.dismissLoader();
        this._callbackOnDone(_.uniqBy(affiliatedIds, "contactId"));
      }
      this.contactService.accessedContactListFrom = null;
      this.uiService.activeView = this.uiService.prevView;
      this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
      this.navService.popWithPageTracking();
    } else if(this.contactService.accessedContactListFrom == PageName.SurgeryOrderContractDetailsComponent){
      if (isDone) {
        this._callbackOnDone({ selectedItems : this.radioSelectedContact ? [this.radioSelectedContact] : [] });
      } else {
        this._callbackOnDone(null)
      }

      this.contactService.accessedContactListFrom = null;
        this.uiService.activeView = this.uiService.prevView;
        this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        this.navService.popWithPageTracking();
    }

    //Request triggered for scheduler feature
    else if (this.contactService.isSchedulerInvoked) {
      if (isDone) {
        this.trackingService.tracking('SchedulerContactsSelected', TrackingEventNames.SCHEDULER)
      }
      else {
        this.trackingService.tracking('SchedulerContactsCanceled', TrackingEventNames.SCHEDULER)
      }
      if (this.device.isOffline && isDone) {
        this.notificationService.notify("Unable to complete the action. Please retry", 'Contact list', 'top', ToastStyle.DANGER);
        return;
      }
      this.contactService.contactInformation = undefined;
      this.storeSelectedContactsForScheduler(isDone);
    }
    else if (this.contactService.isCaseManagementInvoked) {
      let selected: any[] = [];
      if (this.caseManagementService.accessedFrom !== AccesingMode.PRESENTATION
        && this.contactService.accessedContactListFrom !== PageName.PresentationMeetingComponent) {
        //this needs clean up - with child nav
        setTimeout(() => {
          this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
        }, 100);
      }
      if (isDone) {
        Object.assign(selected, this.selectedContacts);
        this.selectedContacts = [];
      }
      else {
        this.selectedContacts = [];
        selected = undefined;
      }
      this.contactService.contactInformation = undefined;
      this.caseManagementService.setDetailsOption({
        data: selected,
        action: DETAILS_OPTION.CONTACT_SELECTION
      });



      if (this.caseManagementService.accessedFrom === AccesingMode.INMEETING ||
        this.caseManagementService.accessedFrom === AccesingMode.IN_PHONE_CALL ||
        this.caseManagementService.accessedFrom === AccesingMode.IN_SET_BOOKING) {
        if (this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent ||
          this.navService.getActiveChildNavViewMasterPageName() === PageName.ContactPageComponent) {
            if(this.caseManagementService.accessedFrom !== AccesingMode.IN_PHONE_CALL){
              this.uiService.activeView = 'ContactPageRightPaneNav';
            }
        } else if (this.caseManagementService.accessedFrom === AccesingMode.IN_SET_BOOKING) {
          
        } else {
          this.uiService.activeView = 'Appointment';
          if(this.caseManagementService.accessedFrom === AccesingMode.IN_PHONE_CALL){
            this.uiService.activeView = 'PhoneCall';
          } 
        }
        this.contactService.contactInformation = this.listViewContact;
        // //@ts-ignore
        // if (this.navCtrl.el.classList.contains('fullStretchView')) {
        //   //@ts-ignore
        //   this.navCtrl.el.classList.remove('fullStretchView')
        // }

        if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
        }


        this.navCtrl.pop({ progressAnimation: false }).then(() => {
          if (this.caseManagementService.accessedFrom !== AccesingMode.PRESENTATION) {
            this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
          }
          this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        });
        return;
      } else if (this.caseManagementService.accessedFrom === AccesingMode.PRESENTATION) {
        this.uiService.activeView = 'Meeting';

        this.contactService.accessedContactListFrom = PageName.ToolsDrawer;
        this.contactService.contactPageMode = this.compViewMode.LISTVIEW;
        this.navService.popWithPageTracking();
        return;
      }
      else if (this.caseManagementService.accessedFrom === AccesingMode.CONTACT) {
        this.contactService.isCaseManagementInvoked = false;
        this.uiService.activeView = 'ContactPageRightPaneNav';
        this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        this.contactService.accessedContactListFrom = PageName.ToolsDrawer;
        this.contactService.contactInformation = this.listViewContact;
        this.navService.popWithPageTracking();
      }
      else if (this.caseManagementService.accessedFrom === AccesingMode.AGENDA) {
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.contactService.isCaseManagementInvoked = false;
        this.navService.popWithPageTracking();
      }
      else if (this.caseManagementService.accessedFrom === AccesingMode.CASE_TOOL) {
        this.contactService.isCaseManagementInvoked = false;
        this.navService.popWithPageTracking();
      }
      else {
        this.uiService.activeView = 'customer_inquiry';
        this.contactService.isCaseManagementInvoked = false;
        try {
          this.navService.popWithPageTracking();
        } catch (error) {
          console.log("unhandled case for inquiry navigation!!");
        }
      }
      //Find the meeting activity being shown in presentation view instead of the opened activity from contact timeline
      if (this.presentationService.selectedActivityId && this.contactService.accessedContactListFrom == PageName.PresentationMeetingComponent) {
        const foundActivity = this.activityService.getActivityByID(this.presentationService.selectedActivityId);
        if(foundActivity && foundActivity instanceof AppointmentActivity) this.activityService.selectedActivity = foundActivity;
        this.footerService.initButtons(FooterViews.PreviewMeeting);
      }
      if (this.caseManagementService.currentCase) {
        this.activityService.selectedActivity = this.caseManagementService.currentCase;
      }
      else {
        if (this.device.isMobileDevice) {
          this.uiService.showRightPane = false;
        }
      }

    }
    else if (this.contactService.isConsentInvoked && !isDone) {
      await this.navService.popWithPageTracking().then(()=> {
        this.contactService.isConsentInvoked = false;
        if(this.uiService.consentFlipButton) {
          //this.uiService.contactDetailsSegment = this.uiService.contactDetailsSegment == 'capture' ? 'info' : this.uiService.contactDetailsSegment;
          this.footerService.initButtons(FooterViews.ConsentDetails);
          this.uiService.isEmailAccessedFromConsnentDetails = true;
        }
      });

    } else if(this.contactService.accessedContactListFrom === PageName.MarketScanDetailsComponent) {
      const marketScan = this.marketScanService.getSelectedMarketScan();
      if(isDone) {
        await this.uiService.displayLoader();
        //let alreadySelectedContactIds = marketScan.contacts.map(c=> c.contactId);
        let marketScanSelectedContacts: MarketScanContact[] = [];
        //this.selectedContacts.map(async contact => {
        for (let l=0;l<this.selectedContacts.length;++l){
          let contact =this.selectedContacts[l];
          if(contact){
            const marketScanSelectedAccounts: MarketScanAccount[] = [];
            if(marketScan.category && marketScan.category.indskr_capturedatafor == CaptureDataFor.CUSTOMERS_ACCOUNTS && marketScan.selectView == 'CUSTOMER_VIEW'){
              let affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting([contact]);
              if(affiliatedAccounts && affiliatedAccounts.length > 0){
                //affiliatedAccounts.forEach(item => {
                for (let m=0;m<affiliatedAccounts.length;++m){
                  let item = affiliatedAccounts[m];
                  if(item && item.raw['indskr_accounttype'] === 100000001 && !marketScanSelectedAccounts.some(a=> a.accountId == item.id)){
                    marketScanSelectedAccounts.push(new MarketScanAccount(item.accountName, item.id, Action.PATCH))
                  }
                }
              }
            }
            marketScanSelectedContacts.push(new MarketScanContact(contact.fullName,contact.ID, Action.PATCH, contact['sequence'],true,marketScanSelectedAccounts));
          }
        }
        if (marketScan.indskr_marketscanid || marketScan.pendingPushToDynamics) {
          marketScan.isModified = true;
        }
        this.marketScanService.unMapReMapContacts(marketScanSelectedContacts, marketScan);
        this.marketScanService.mapContactsToMarketScan(marketScan, marketScanSelectedContacts);
        this.uiService.dismissLoader();
      }
      await this.navService.popWithPageTracking().then(() => {
        this.footerService.initButtons(FooterViews.MARKET_SCAN_DETAILS);
      });
    } else if(this.contactService.accessedContactListFrom === PageName.SurveyDetailsComponent) {
      if(isDone) {
        let selectedTemplate: AssessmentTemplate;
        if(this.navParams && this.navParams.data) {
          selectedTemplate = this.navParams.data.template;
        }
        if(selectedTemplate.indskr_entity == 'account') {
          // update affiliated contact for account survey
          let data: ContactSelectionObservableModel = {
            for: 'accountSurveyDetails',
            id: selectedTemplate.indskr_assessmenttemplateid,
            contacts: this.radioSelectedContact ? [this.radioSelectedContact] : []
          }
          this.contactService.setSelectedContacts(data);
          this.customerSurveyService.setCurrentSurvey(selectedTemplate);
        } else {
          //update selected contact info in the survey payload for contact survey
          this.customerSurveyService.selectedContactForSurvey = this.radioSelectedContact;
          const contactId: string = this.customerSurveyService.selectedContactForSurvey.ID;
          let contactsListSavedSurvey = [];
          let surveyDataFromDB: CustomerAssessment;
          if(!_.isEmpty(selectedTemplate)) {
            const templateId: string = selectedTemplate.indskr_assessmenttemplateid;
            // let surveysFromDB = await this.customerAssessService.getCustomerSurveyFromDB(templateId);
            let sureysByTemplateId = this.customerAssessService.getSurveysByTemplateId(templateId, 'contact', false);
            if(!_.isEmpty(sureysByTemplateId)) {
              let savedSurvey = sureysByTemplateId.find(survey => survey.surveyStatus === SurveyStatus.SAVED && survey.indskr_entityid === contactId);
              if (savedSurvey) {
                  surveyDataFromDB = savedSurvey
              }
              // response update
              if(!_.isEmpty(surveyDataFromDB)) {
                const responseData = await this.customerAssessService.fetchContactAccountSurveyForDetails(surveyDataFromDB, 'contact', true);
                if(!_.isEmpty(responseData)) {
                  surveyDataFromDB.responses = responseData;  
                }
              }
              this.customerSurveyService.setSurveyPreviewMode(false, surveyDataFromDB);
            }else {
              this.customerSurveyService.setSurveyPreviewMode(false);
            }
          }else {
            this.customerSurveyService.setSurveyPreviewMode(false);
          }
        }
      }
      this.contactService.contacts.map(item => {
        item.isForcedHideAddIcon = false;
      });
      await this.navService.popWithPageTracking().then(() => {
        if(this.navService.getCurrentMasterPageName() == PageName.PresentationMeetingComponent) {
          if(this.navParams.data.template.indskr_entity != 'account') this.footerService.initButtons(FooterViews.PreviewMeeting);
        } else if(!_.isEmpty(this.customerSurveyService.selectedContactForSurvey) || !_.isEmpty(this.radioSelectedContact) || this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent || this.navParams.data.template.indskr_entity == 'account') {
          this.footerService.initButtons(FooterViews.SAVE_SURVEY_DETAILS);
        }else {
          this.footerService.initButtons(FooterViews.SURVEY_DETAILS);
        }
      });
    } else {

      const canUpdateSubject = !(this.isAccountVisitRecord);
      if (this.contactService.contactPageMode !== this.compViewMode.SELECTION) {
        if (isDone) {
          if (!this._loading) {
            this._loading = await this.loadingCtrl.create();
            this._loading.present();
          }
          if(this.isDirty){
            if (this.contactService.accessedContactListFrom === PageName.EmailDetailsPageComponent && this.selectedContacts) {
              if (this.emailService.isActionTriggeredFromFooter) { this.trackingService.tracking('ContactAddedtoEmailActionBar', TrackingEventNames.EMAIL); }
              else { this.trackingService.tracking('ContactAddedtoEmail', TrackingEventNames.EMAIL); }
              //reset the value for next iteration
              this.emailService.isActionTriggeredFromFooter = false;
              await this.updateEmailAddressToEmailActivity();
            } else {
              let subject: string = this.translate.instant('MEETING');
              //let subPrefix = (this.activityService.selectedActivity.ID.includes('offline')) ? "Offline meeting with " : "Meeting with ";
              // Per TC-799, the ofline and online should have the same subject , if auto subject is enabled
              let subPrefix = ` - ${subject}`;
              const isAcinoBaseUser = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT);
              let activity = (this.activityService.selectedActivity as AppointmentActivity);
              if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
                subPrefix = [subject, activity.activityTypeName, activity.meetingOwnerName].filter(Boolean).join(' - ');
              }
              if (isAcinoBaseUser) {
                subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}`+this.translate.instant('VISIT');
              }
              switch (this.selectedContacts.length) {
                case 0:
                  if (!isAcinoBaseUser)
                    subject = [this.translate.instant('MEETING'), activity.activityTypeName, activity.meetingOwnerName].filter(Boolean).join(" - ");
                  break;
                case 1:
                  subject = this.selectedContacts[0].fullName + ' - ' + subPrefix;
                  if (isAcinoBaseUser) {
                    subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}`+this.translate.instant('VISIT') + ` - ${this.contactService.getFullNameForContactDisplaySequence(this.selectedContacts[0])}`;
                  }
                  break;
                default:
                  if (this.selectedContacts.length > 1) {
                    // # # # View Contacts in sequence the way they were selected during meeting creation # # # //
                      if(!this.contactService.isMeetingContactSequenceEnabled) {
                        this.selectedContacts.sort((contactA, contactB): number => {
                          let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
                          let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
                          if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
                          if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;

                          return 0;
                        });
                      } else {
                        this.selectedContacts.sort((contactA, contactB) => {
                          return contactA.indskr_sequence - contactB.indskr_sequence;
                        });
                      } 
                  // # # # View Contacts in sequence the way they were selected during meeting creation # # # //
                    subject = `${this.contactService.getFullNameForContactDisplaySequence(this.selectedContacts[0])} + ${this.selectedContacts.length - 1}` + ' - ' + subPrefix;
                    if (isAcinoBaseUser) {
                      subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}`+this.translate.instant('VISIT') + ` - ${this.contactService.getFullNameForContactDisplaySequence(this.selectedContacts[0])}`;
                    }
                  }
              }


              // To Add/Remove affiliated accounts for meeting
              const notAccountVisit = !this.isAccountVisitNestedMeeting && !this.isAccountVisitRecord;
              let updateAccountsForMeetingFlag: boolean = false;
              let accountsToUpdate: Account[] = [];
              //Check for Remove
              if (notAccountVisit && (this.activityService.selectedActivity as AppointmentActivity).accounts.length === 1 && (this.activityService.selectedActivity as AppointmentActivity).contacts.length === 1) {
                let affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting((this.activityService.selectedActivity as AppointmentActivity).contacts);
                if (affiliatedAccounts.length === 1 && affiliatedAccounts[0].id == (this.activityService.selectedActivity as AppointmentActivity).accounts[0].id) {
                  // Remove the only added affiliated account
                  updateAccountsForMeetingFlag = true;
                  accountsToUpdate = [];
                } else if (affiliatedAccounts.length > 1) {
                  const selectedActivityAccountId = (this.activityService.selectedActivity as AppointmentActivity).accounts[0].id || '';
                  const foundAffiliatedAccount = affiliatedAccounts.some(a=>a.id == selectedActivityAccountId);
                  if (foundAffiliatedAccount) {
                    updateAccountsForMeetingFlag = true;
                    accountsToUpdate = [];
                  }
                }
              }
              //Check for add
              if (notAccountVisit && this.selectedContacts?.length === 1 && (this.activityService.selectedActivity as AppointmentActivity).accounts.length === 0 || updateAccountsForMeetingFlag) {
                let affiliatedAccounts = await this.accountService.getAffiliatedAccountsFromSelectedContactsForMeeting(this.selectedContacts);
                if (affiliatedAccounts.length === 1) {
                  // Add the only affiliated contact
                  updateAccountsForMeetingFlag = true;
                  accountsToUpdate.push(...affiliatedAccounts);
                } else {
                  let contact = this.selectedContacts[0]
                  if(contact?.primaryAccount){
                    let aff = affiliatedAccounts.filter(af => af.id == contact.primaryAccount.id)
                    updateAccountsForMeetingFlag = true;
                    accountsToUpdate.push(...aff);
                  }
                }
              }
              if (updateAccountsForMeetingFlag) {
                await this._updateAccountsOfSelectedMeeting(accountsToUpdate);
                this.events.publish("detectChangesOnActivityDetails"); // refresh meeting structure
              }

              if(notAccountVisit && this.activityService.selectedActivity.location !== "LiveMeet"){
                if(!this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ENABLE_GPS_CHECK_IN)){
                  await this.contactService.setLocationField(this.selectedContacts);
                }
              }
              // Remove customer requested field if Meeting compliance FA is mapped
              if((this.activityService.selectedActivity as AppointmentActivity).contacts && _.xor((this.activityService.selectedActivity as AppointmentActivity).contacts.map(a=> a.ID),this.selectedContacts.map(a=> a.ID)).length !== 0){
                await this._resetCustomerRequestedField();
              }

              let payload = new UpdateMeetingPayload(
                subject,
                this.activityService.selectedActivity.location,
                this.activityService.selectedActivity.scheduledStart,
                this.activityService.selectedActivity.scheduledEnd,
                this.activityService.selectedActivity.notes,
                false
              );

              //CWD-2414 Update the activity subject if auto subject feature action is enabled
              if (canUpdateSubject && (this.isAutoSubjectEnabled || isAcinoBaseUser)) {
                this.activityService.selectedActivity.subject = subject;
                this.activityService.getActivityByID(this.activityService.selectedActivity.ID).subject = subject;
                this.refreshPlanToDO();
              }

              this.trackingService.tracking('Contacts', 'Done from contacts');

              // If it's offline & account visit actvity, we don't update
              if (
                !((this.isAccountVisitRecord || this.isAccountVisitNestedMeeting)
                  && this.device.isOffline)
              ) {
                const oldContacts = Array.isArray(this.activityService.selectedActivity['contacts'])
                  ? [...this.activityService.selectedActivity['contacts']]
                  : [];
              this.selectedContacts.map(contact => {
                if (this.enableOneTimeVeevaMeeting && contact.veevaRecordState === 200000004) {
                  contact.isUsedInOneTimeMeeting = true;
                }
                this.contactService.moveContactToSelected(contact);
              });
              let contactsForRemovePageSentimet;
              if(this.activityService.selectedActivity['contacts']){
                contactsForRemovePageSentimet = this.activityService.selectedActivity['contacts'].filter(ac => !this.selectedContacts.some(b=> b.ID == ac.ID));
                this.activityService.selectedActivity['contacts'] = this.activityService.selectedActivity['contacts'].filter(ac => this.selectedContacts.some(b=> b.ID == ac.ID));
              }else{
                this.activityService.selectedActivity['contacts'] = [];
              }

              const index = this.activityService.activities?.findIndex(act => act.ID === this.activityService.selectedActivity.ID) || -1;
              if (index >= 0) {
                this.activityService.activities[index]['contacts'] = this.activityService.selectedActivity['contacts'];
              }

              if (this.activityService.selectedActivity instanceof AppointmentActivity) {
                if (this.isAccountVisitRecord) {
                    await this.meetingService.updateContactToAccountVisit(this.activityService.selectedActivity as AppointmentActivity, oldContacts);
                } else if (this.isAccountVisitNestedMeeting) {
                  const selectedAccountVisit = this.activityService.selectedActivity && this.activityService.selectedActivity.type === ActivityType.Appointment
                    ? this.activityService.selectedActivity as AppointmentActivity : undefined;
                  let parentMeeting = await this.activityDataService.getNewDetailAppendedAppointmentActivityInstanceFromLocalDB(selectedAccountVisit?.indskr_parentcallid) as AppointmentActivity;
                  if(selectedAccountVisit.contacts.length > 0){ // checking contact added is in parent or not
                    let contactExists = _.find(parentMeeting.contacts, {ID : selectedAccountVisit.contacts[0].ID})
                    if(!contactExists){
                      parentMeeting.contacts.push(selectedAccountVisit.contacts[0]);
                    }
                  } else {
                    let contactExists = _.find(parentMeeting.contacts, {ID : oldContacts[0].ID});
                    if(contactExists){
                      let nestedMeetingExistWithContact = this.activityService.nestedMeetingExistWithContact(contactExists.ID, parentMeeting.ID)
                      if(!nestedMeetingExistWithContact){
                        parentMeeting.contacts = parentMeeting.contacts.filter((contact) => contact.ID !== contactExists.ID);
                      }
                    }
                  }

                  let payload = [parentMeeting, selectedAccountVisit];
                  await this.meetingService.crudMeetingsOnlineWithOfflineUploadEndpoint(payload)
                } else {

                   // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
                   if(this.secondaryInfoService.isAppointmentSecInfoConfigured) {
                    subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForAppointment(this.activityService.selectedActivity, SecondaryInfoEntityName.Appointment);

                    payload = new UpdateMeetingPayload(
                      subject,
                      this.activityService.selectedActivity.location,
                      this.activityService.selectedActivity.scheduledStart,
                      this.activityService.selectedActivity.scheduledEnd,
                      this.activityService.selectedActivity.notes,
                      false
                    );

                    this.activityService.selectedActivity.subject = subject;
                    this.activityService.getActivityByID(this.activityService.selectedActivity.ID).subject = subject;
                    this.refreshPlanToDO()
                  }
                  // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //

                if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
                  if (canUpdateSubject && (this.isAutoSubjectEnabled || isAcinoBaseUser || this.secondaryInfoService.isAppointmentSecInfoConfigured)) {
                    try {
                      await this.meetingService.updateMeeting(this.activityService.selectedActivity, payload); //CWD-2414 Update the activity subject if auto subject feature action is enabled
                    } catch (error) {
                      console.error('ContactListComponent: onCloseModal: noSelectionMode: isDone: ', error);
                      await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity, false, true);
                    }
                    // } finally {
                    //   this.activityService.isActivityUpdate = true;
                    // }
                  }
                  //await this.meetingService.addContactsToMeeting(this.activityService.selectedActivity);
                  await this.updateMeetingSentiments();
                  if (contactsForRemovePageSentimet.length > 0) {
                    await this.meetingService.removePageSentiments(this.activityService.selectedActivity, contactsForRemovePageSentimet);
                  }
                } else {
                  this.meetingDataService.mapPageSentimentsInLocalDB(this.activityService.selectedActivity);
                  if (contactsForRemovePageSentimet.length > 0) {
                    await this.meetingService.removePageSentiments(this.activityService.selectedActivity, contactsForRemovePageSentimet);
                  } 
                  await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
                }

                }
                if (this._callbackOnDone) this._callbackOnDone(this.selectedContacts);
              }

              }
            }
          } else if (this.isSentimentsDirty || this.isKeyMessageSentimentsDirty) {
            await this.updateMeetingSentiments();
          }
        } else {
          if (this.contactService.contactPageMode == this.compViewMode.LISTVIEW) {
            this.deltaService.deltaRecordsDTO.contacts.length = 0;
          }
          this.trackingService.tracking('ContactsBack', TrackingEventNames.CONTACTS)
        }
      }
      else if (isDone) {
        let currentAllocationActivity: SampleActivity
        if (this.radioSelectedContact) {
          if (this.sampleService.samplingDetailsViewMode &&
            this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_MEETING ||
            this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION) {
            currentAllocationActivity = this.sampleService.inMeetingAllocationActivity;
            let contactAddedToAllocation;
            if (this.activityService.selectedActivity['contacts']) {
              contactAddedToAllocation = this.activityService.selectedActivity['contacts'].find((c) => {
                return c.ID == this.radioSelectedContact.ID;
              })
            }
            if (!contactAddedToAllocation) {
              this.contactService.moveContactToSelected(this.radioSelectedContact);
              if (
                this.sampleService.samplingDetailsViewMode !== SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION
                && (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID))
              ) {

                await this.meetingService.addContactsToMeeting(this.activityService.selectedActivity)
                .catch((error) => {
                  console.error('ContactListComponent: onCloseModal: selectionMode: isDone: ', error);
                  this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity, false, true);
                });
              }
              // Remove customer requested field if Meeting compliance FA is mapped
              await this._resetCustomerRequestedField();
              await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity);
              const isAcinoBaseUser = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT);
              if (canUpdateSubject && (this.isAutoSubjectEnabled || isAcinoBaseUser || this.secondaryInfoService.isAppointmentSecInfoConfigured)) {
                let subject: string = 'Meeting';
                let subPrefix = `- ${this.translate.instant('MEETING')}`;;
                let activity = this.activityService.selectedActivity as AppointmentActivity;
                if (isAcinoBaseUser) {
                  subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}`+this.translate.instant('VISIT');
                }
                switch (activity.contacts.length) {
                  case 0:
                    break;
                  case 1:
                    subject = `${this.contactService.getFullNameForContactDisplaySequence(activity.contacts[0])}${subPrefix}`;
                    if (isAcinoBaseUser) {
                      subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}`+this.translate.instant('VISIT') + ` - ${this.contactService.getFullNameForContactDisplaySequence(activity.contacts[0])}`;
                    }
                    break;
                  default:
                    if (activity.contacts.length > 1) {
                      // # # # View Contacts in sequence the way they were selected during meeting creation # # # //
                      if(!this.contactService.isMeetingContactSequenceEnabled) {
                        activity.contacts.sort((contactA, contactB): number => {
                          let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
                          let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
                          if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
                          if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;
  
                          return 0;
                        });
                      } else {
                        activity.contacts.sort((contactA, contactB) => {
                          return contactA.indskr_sequence - contactB.indskr_sequence;
                        });
                      } 
                      // # # # View Contacts in sequence the way they were selected during meeting creation # # # //
                      subject = `${this.contactService.getFullNameForContactDisplaySequence(activity.contacts[0])} + ${activity.contacts.length - 1}${subPrefix}`;
                      if (isAcinoBaseUser) {
                        subject = `${activity.activityTypeName ? activity.activityTypeName + ' ' : ''}`+this.translate.instant('VISIT') + ` - ${this.contactService.getFullNameForContactDisplaySequence(activity.contacts[0])}`;
                      }
                    }
                }

                // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
                if(this.secondaryInfoService.isAppointmentSecInfoConfigured) {
                  subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForAppointment(this.activityService.selectedActivity, SecondaryInfoEntityName.Appointment);
                }
                 // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //

                let payload = new UpdateMeetingPayload(
                  subject,
                  this.activityService.selectedActivity.location,
                  this.activityService.selectedActivity.scheduledStart,
                  this.activityService.selectedActivity.scheduledEnd
                );
                this.activityService.selectedActivity.subject = subject;
                this.activityService.getActivityByID(this.activityService.selectedActivity.ID).subject = subject;
                // this.activityService.isActivityUpdate = true;
                if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
                  if (this.isAutoSubjectEnabled || isAcinoBaseUser) {
                    try {
                      await this.meetingService.updateMeeting(this.activityService.selectedActivity as AppointmentActivity, payload); //CWD-2414 Update the activity subject if auto subject feature action is enabled
                    } catch (error) {
                      console.error('ContactListComponent: onCloseModal: selectionMode: isDone: autoSubjectEnabled: ', error);
                      await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity, false, true);
                    }
                  }
                  await this.updateMeetingSentiments();
                } else {
                  await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity);
                }
              }
            }
          } else if(this.sampleService.samplingDetailsViewMode &&
            this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_EVENTS){
              currentAllocationActivity = this.sampleService.inMeetingAllocationActivity;

          }
          else {
            currentAllocationActivity = this.activityService.selectedActivity as SampleActivity;
            this.activityService.selectedActivity['contacts'] = [];
          }
          currentAllocationActivity.contactID = this.radioSelectedContact.ID;
          let addressList = this.radioSelectedContact.addressesList;
          addressList =  uniqBy(addressList, 'addressId');
          if (addressList.length == 1) {
            currentAllocationActivity.addressID = addressList[0].customerAddressID;
            currentAllocationActivity.location = addressList[0].compositeAdd
          }
          else {
            currentAllocationActivity.addressID = '';
            currentAllocationActivity.location = ''
          }
          currentAllocationActivity.contactName = this.radioSelectedContact.fullName;
          currentAllocationActivity.contactID = this.radioSelectedContact.ID;
        }
        else {
          if (this.sampleService.samplingDetailsViewMode &&
            (this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_MEETING ||
               this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_EVENTS
              || this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_MEETING_PRESENTATION)) {
            currentAllocationActivity = this.sampleService.inMeetingAllocationActivity;
            let contactAddedToAllocation;
          }
          else {
            currentAllocationActivity = this.activityService.selectedActivity as SampleActivity;
            this.activityService.selectedActivity['contacts'] = [];
          }
          currentAllocationActivity.contactID = '';
          currentAllocationActivity.addressID = '';
          currentAllocationActivity.location = ''
          currentAllocationActivity.contactName = '';
        }
        //currentAllocationActivity.addressID = '';
        //currentAllocationActivity.location = '';
        //currentAllocationActivity.samples = [];
        currentAllocationActivity.samples.map((o) => {
          o.deleted = true;
          o.totalQuantity = 0;
          if(o.lots && o.lots.length > 0){
            o.lots.map(l=> {
              l.indskr_quantity = 0;
              l.deleted = true
            });
          }
        })
        if (currentAllocationActivity.subject && currentAllocationActivity.subject.indexOf('Allocation Order') > -1) {
          currentAllocationActivity.subject = this.translate.instant('CONTACT_A_ORDER', { fullname: this.radioSelectedContact.fullName });
        }
        let currentActivityPayload = new CreateSampleDropRequestBody(currentAllocationActivity)
        //if (!this.device.isOffline) {
        let loader = await this.loadingCtrl.create();
        loader.present();
        await this.sampledataService.updateSampleActivity(currentActivityPayload, currentAllocationActivity)
          .then(() => {
            if (currentAllocationActivity.appointmentID && currentAllocationActivity.appointmentID.length > 0)
              this.events.publish('updateEmbeddedActivity', currentAllocationActivity);
              this.events.publish('selectedActivityChangedInBackround');
            // this.activityService.isActivityUpdate = true;
            loader.dismiss();
          })
          .catch(() => {
            loader.dismiss();
          })
      }

      if (this.contactService.contactPageMode == ComponentViewMode.SELECTION
        && this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_MEETING
        || this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_EVENTS) {
        // //@ts-ignore
        // if (this.navCtrl.el.classList.contains('fullStretchView')) {
        //   //@ts-ignore
        //   this.navCtrl.el.classList.remove('fullStretchView')
        // }
        if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
        }
        this.navCtrl.pop({ progressAnimation: false }).then(() => {
          this.footerService.initButtons(FooterViews.SampleDropDetails);
          this.uiService.activeView = this.uiService.prevView;
          this.events.publish('selectedActivityChangedInBackround');
          this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
          if(this.navService.getCurrentMasterPageName()==PageName.ContactPageComponent){
            this.contactService.accessedContactListFrom = PageName.ToolsDrawer
          }
          if (this.holdContactInformation) {
            this.contactService.contactInformation = this.holdContactInformation;
            this.addToSelected(null, this.holdContactInformation);
          }
        });
        return;
      }

      if (this._loading) {
        this._loading.dismiss();
        this._loading = null;
        this.selectedContacts = [];
        this.radioSelectedContact = undefined;
      }

      if (this.contactService.accessedContactListFrom == PageName.EmailDetailsPageComponent && this.emailService.selectedActivity && (this.emailService.viewType == EmailViewType.CREATE_FROM_MEETING ||
        this.emailService.viewType == EmailViewType.EMAIL_FROM_MEETING_PRESENTATION ||
        this.emailService.viewType == EmailViewType.CREATE_FROM_PHONE_CALL)) {
          if (this.emailService.viewType == EmailViewType.EMAIL_FROM_MEETING_PRESENTATION ) {
            await this.navService.popWithPageTracking();
            return;
          }
        // //@ts-ignore
        // if (this.navCtrl.el.classList.contains('fullStretchView')) {
        //   //@ts-ignore
        //   this.navCtrl.el.classList.remove('fullStretchView')
        // }
        if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
        }
        this.navCtrl.pop({ progressAnimation: false }).then(() => {
          this.contactService.accessedContactListFrom = PageName.ToolsDrawer;
          this.contactService.contactPageMode = this.compViewMode.LISTVIEW;
          this.uiService.activeView = this.uiService.prevView;
          if (this.holdContactInformation) {
            this.contactService.contactInformation = this.holdContactInformation;
            this.addToSelected(null, this.holdContactInformation);
          }
          // this.footerService.initButtons(FooterViews.EmailDetails);
          this.events.publish('update-email-footer');
        });
        return;
      }

      this.contactService.contactSentimentUpdateActivity = undefined;

      await this.navService.popWithPageTracking()
        .then(() => {
          /*
            User has selected a different activity at agenda details
            and has selected some other type at timeline
          */
          if (this.contactService.accessedContactListFrom === PageName.ToolsDrawer) {
            // Short call feature requires to bring the tab back to short call home
            if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
              this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
              this.uiService.setAgendaTab('shortCallHome');
            }
          }
          if (this.contactService.contactPageMode == this.compViewMode.LISTVIEW && !this.uiService.showCancelDoneOnActivityDetails && (this.activityService.selectedActivityAtHome || this.activityService.selectedActivity)) {
            if (this.activityService.selectedActivity && !(this.activityService.selectedActivity instanceof AppointmentActivity)
                && this.activityService.selectedActivity.accessedFrom != PageName.ContactTimelineComponent) {
                this.activityService.selectedActivity = this.activityService.selectedActivityAtHome ? this.activityService.getActivityByID(this.activityService.selectedActivityAtHome.ID) : this.activityService.selectedActivity;
            }
            //According to available open meeting in the timeline, activity can be updated.
            if(!_.isEmpty(this.activityService.selectedActivityAtHome) && this.activityService.selectedActivityAtHome instanceof AppointmentActivity
              && !_.isEmpty(this.activityService.selectedActivity) && this.activityService.selectedActivity instanceof AppointmentActivity) {
              if(this.activityService.selectedActivityAtHome.ID == this.activityService.selectedActivity.ID) {
                this.activityService.selectedActivityAtHome = this.activityService.selectedActivity = this.activityService.getActivityByID(this.activityService.selectedActivity.ID);
              }else {
                this.activityService.selectedActivity = this.activityService.getActivityByID(this.activityService.selectedActivityAtHome.ID);
              }
            }
            const activity = this.sampleService.inMeetingAllocationActivity ? this.sampleService.inMeetingAllocationActivity : this.activityService.selectedActivity;
            if(this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans') {
              this.uiService.activeView = 'ActivitiesPageRightPaneNav';
              this.uiService.showRightPane = this.device.isMobileDevicePortrait? false: true;
              this.activityService.selectedActivity = this.activityService.selectedActivityAtHome;
              // if(this.activityService.selectedActivity.type === ActivityType.Email && this.contactService.accessedContactListFrom !== PageName.ToolsDrawer) this.footerService.initButtons(FooterViews.EmailDetails);
              if(this.activityService.selectedActivity.type === ActivityType.Email && this.contactService.accessedContactListFrom !== PageName.ToolsDrawer) this.events.publish('update-email-footer');
            } else {
              this.uiService.activeView = this.uiService.getActiveViewBasedOnActivity(activity);
            }
            if(this.uiService.activeView == 'Email' && this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Email){
              this.emailService.selectedActivity = this.activityService.selectedActivity as EmailActivity;
            }
            this.events.publish("detectChangesOnActivityDetails"); // refresh meeting structure
          }
          if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) {
            if(_.isEmpty(this.activityService.selectedActivityAtHome) && _.isEmpty(this.activityService.selectedActivity)) {
              this.uiService.showRightPane = false;
              this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
            }else {
              this.footerService.initButtons(FooterViews.Activities);
            }
          }
          // if (this.uiService.activeView === 'Meeting') this.footerService.initButtons(FooterViews.PreviewMeeting);
          if (this.uiService.activeView === 'Sample') {
            this.footerService.initButtons(FooterViews.SampleDropDetails);
          }
          if (this.uiService.activeView === 'customer_inquiry' || this.navService.getActiveChildNavViewPageName() === PageName.CaseManagementDetailComponent) {
            this.caseManagementService.accessedFrom = AccesingMode.AGENDA;
            this.caseManagementService.assignSelectedCase(<CaseActivity>this.activityService.selectedActivity );
            this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
            if (this.activityService.selectedActivity) {
              this.events.publish('activity-pane:selectActivity', this.activityService.selectedActivity);
            }
          }

          if (this.uiService.activeView === 'Email' || this.navService.getActiveChildNavViewPageName() === PageName.EmailDetailsPageComponent) {
            setTimeout(() => {
              if(this.emailService.selectedActivity && this.emailService.selectedActivity.isMessageExternal) {
                this.footerService.initButtons(FooterViews.MESSAGE_NON_NATIVE);
              } else {
                // this.footerService.initButtons(FooterViews.EmailDetails);
                this.events.publish('update-email-footer');
              }
            }, 20); // contact footer is getting initialized, so added timeout
            this.emailService.viewType = EmailViewType.FROM_AGENDA;
          }

          if (
            isDone
            && this.uiService.prevView != 'EmailTemplate'
            && (!this.isAccountVisitRecord && !this.isAccountVisitNestedMeeting) && this.isDirty
          ) {
            // timeout added to get the event once the activity details page reinitialized
            setTimeout(() => {
              this.events.publish('ContactsSelected', true);
            }, 10);
          } else if (isDone && this.isAccountVisitRecord) {
            this.events.publish("detectChangesOnActivityDetails"); // refresh meeting structure
          }
          // if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
          if (this.contactService.accessedContactListFrom == PageName.ActivitiesDetailsPaneComponent
            && this.navService.getCurrentPageName() == PageName.ContactPageComponent) {
            //if (this.contactService.accessedContactListFrom == PageName.ActivitiesDetailsPaneComponent && this.navService.getCurrentPageName() == PageName.ContactPageComponent) {
            if (this.holdContactInformation) {
              this.contactService.contactInformation = this.holdContactInformation;
              this.addToSelected(null, this.holdContactInformation);
            }
            this.contactService.contactPageMode = this.compViewMode.LISTVIEW;
            this.uiService.showNewActivity = false;
            this.uiService.prevView = 'contactDetails';
            this.uiService.activeView = 'ContactPageRightPaneNav';
            this.activityDataService.activityDetailsLoaded = true;
            if (this.activityService.selectedActivity?.accessedFrom != PageName.ContactTimelineComponent) this.activityService.selectedActivity = this.activityService.prevSelectedActivity || this.activityService.selectedActivity;
            this.contactService.accessedContactListFrom = PageName.ToolsDrawer;
            //}
          // } else if (!isDone && this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan != 'accountPlansTab') {
          } else if (this.navService.getCurrentPageName() == PageName.PresentationMeetingComponent) {
            this.uiService.activeView = 'Meeting';
            // this.footerService.initButtons(FooterViews.PreviewMeeting);
            this.contactService.contactPageMode = this.compViewMode.LISTVIEW;
          }
          //Find the meeting activity being shown in presentation view instead of the opened activity from contact timeline
          if (this.presentationService.selectedActivityId && (this.contactService.accessedContactListFrom == PageName.PresentationMeetingComponent || this.navService.getCurrentPageName() == PageName.PresentationMeetingComponent)) {
            const foundActivity = this.activityService.getActivityByID(this.presentationService.selectedActivityId);
            if(foundActivity && foundActivity instanceof AppointmentActivity) this.activityService.selectedActivity = foundActivity;
            this.footerService.initButtons(FooterViews.PreviewMeeting);
          }

          if ((this.contactService.accessedContactListFrom == PageName.PresentationMeetingComponent
                || this.contactService.accessedContactListFrom == PageName.NewSampleActivityComponent)
                  && this.navService.getCurrentPageName() == PageName.ContactPageComponent) {
            if (this.holdContactInformation) {
              this.contactService.contactInformation = this.holdContactInformation;
            }
            this.contactService.contactPageMode = this.compViewMode.LISTVIEW;
            this.contactService.accessedContactListFrom = PageName.ToolsDrawer;
          }
          //scenario- contact list accessed from sample, whic is created from meeting, when meeting is accssed from
          //contact tool (pretty messed up, eh!) OMNI-10884
          if(this.contactService.accessedContactListFrom == PageName.NewSampleActivityComponent
              && this.navService.getCurrentPageName() == PageName.PresentationMeetingComponent
              && this.navService.getPreviousPageName()==PageName.ContactPageComponent){
                if (this.holdContactInformation) {
                  this.contactService.contactInformation = this.holdContactInformation;
                }
                this.contactService.contactPageMode = this.compViewMode.LISTVIEW;
                this.contactService.accessedContactListFrom = PageName.ToolsDrawer;
          }
          if (this.contactService.accessedContactListFrom == PageName.EmailDetailsPageComponent) {
            if (this.holdContactInformation) {
              this.contactService.contactInformation = this.holdContactInformation;
            }
            if(this.uiService.activitiesPageTab !== 'plans') this.contactService.accessedContactListFrom = PageName.ToolsDrawer;
            this.contactService.contactPageMode = this.compViewMode.LISTVIEW;
            if (isDone) this.emailService.showNotification();
          }
          if (this.uiService.showFieldCoaching && this.navService.getActiveChildNavViewPageName() === PageName.CoachingDetailsComponent) {
            this.navService.popChildNavPageWithPageTracking();
          }
          if(this.navService.getPreviousActiveChildNavViewPageName() === PageName.NotificationDetailsComponent
            && (this.navService.getActiveChildNavViewPageName() === PageName.ContactDetailsComponent || this.navService.getActiveChildNavViewPageName() === PageName.AccountDetailsComponent)) {
            this.navService.popChildNavPageWithPageTracking();
          }

          if (this.contactService.accessedContactListFrom === PageName.ToolsDrawer) {
            // Short call feature requires to bring the tab back to short call home
            if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
              this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
              this.uiService.setAgendaTab('shortCallHome');
            }
          }
        });

    }
  };

  private async  _resetCustomerRequestedField(){
    if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_SOLICITATION_COMPLIANCE) && (this.activityService.selectedActivity as AppointmentActivity).appConfigFields && (this.activityService.selectedActivity as AppointmentActivity).appConfigFields.length > 0){
      let idx = (this.activityService.selectedActivity as AppointmentActivity).appConfigFields.findIndex(a=> a.fieldName == "indskr_hcpinteraction");
      if(idx >= 0){
        (this.activityService.selectedActivity as AppointmentActivity).appConfigFields.splice(idx,1);
        if(!this.device.isOffline){
          try {
            await this.dynamics.deleteSinglePropertyValue(this.activityService.selectedActivity.ID,'appointments','indskr_hcpinteraction');
          } catch (error) {
            console.log(error);
          }
        }
        this.events.publish('activities:appConfigFields');
      }
    }
    this.events.publish('activities:appConfigFields', 'indskr_hcpinteraction');
  }


  private async updateEmailAddressToEmailActivity() {
    let emailActivityParties: EmailActivityParty[] = [];
    this.selectedContacts.forEach(contact => {
      // this.addEmaillAddressToContact(contact, emailActivity);
      let emailActivityParty: EmailActivityParty = new EmailActivityParty();
      emailActivityParty.contact_firstname = contact.firstName;
      emailActivityParty.contact_lastname = contact.lastName;
      emailActivityParty.indskr_contactid = contact.ID;
      emailActivityParty.contact_mobilephone = contact.mobilePhone;
      emailActivityParty.contact_indskr_facebookpsid = contact.indskr_facebookpsid;
      emailActivityParty.emailAddresses = [];
      contact.emailAddressList.forEach(emailAddress => {
        if (emailAddress.isSelected) {
          let emailAddressDetails: EmailAddress = new EmailAddress();
          emailAddressDetails.email_addressid = emailAddress.emailAddressId;
          emailAddressDetails.emailAddress = emailAddress.emailAddress
          emailActivityParty.emailAddresses.push(emailAddressDetails);
        }
      });
      // update selected phone number for sharing message on non-native SMS channel
      if (this.selectedContactsForNonNativeChannelSMS && this.mobileNumberListforShareMessage && this.mobileNumberListforShareMessage.length > 0) {
        let selectedPhoneNumberList = this.mobileNumberListforShareMessage.filter(phoneNumber => phoneNumber.isSelected);
        if (selectedPhoneNumberList && selectedPhoneNumberList.length > 0) {
          let phoneNumberListArray: string[] = [];
          selectedPhoneNumberList.forEach(phoneNumber => {
              phoneNumberListArray.push(phoneNumber.phoneNumber);
          });
          if (phoneNumberListArray && phoneNumberListArray.length > 0) {
            emailActivityParty.contact_mobilephone = phoneNumberListArray.join(", ");
          }
        }
      }
      emailActivityParties.push(emailActivityParty);
    });
    await this.emailService.updateEmailActivityParties(emailActivityParties);
    this.selectedContacts = [];
  }

  isContactsChanged() {
    return this.selectedContacts === this.activityService.selectedActivity['contacts'];
  };

  /**
   * set val to the value of the ev target
   *
   * @param {any} ev
   * @returns
   * @memberof ContactListComponent
   */
  getItems(ev) {
    let val = (ev.target.value) ? ev.target.value : '';

    if (val.length <= this.MIN_SEARCH_LENGTH) {
      this.searching = false;
      return;
    }

    // this.searching = true;
    // this.contactService.filterContacts(val);
  };

  public openRestricedSelectionContactDetail(event,contact){
    if(contact && contact.ID){
      let foundContact = this.contactService.getContactByID(contact.ID);
      if(foundContact){
        this.highlightContact(event,foundContact);
      }else{
        this.notificationService.notify(this.translate.instant('CONTACT_NO_LONGER_ACCESS'), 'Contact list', 'top', ToastStyle.DANGER);
        return;
      }
    }
  }

  public async highlightContact(event, contact, isOpenedOnPreview: boolean = false, contactDetailsSegment:string = '', isOpenedUnappedData: boolean = false) {
    if(event && event.target && event.target.className && event.target.className.includes("stakeholder-input")){
      return;
    }
    if(!contact) return;
    //THIS CODE SHOULD BE ENABLED - when kitbooking is getting created from Contact tool and we click on Contact list 
    let isKitBookingDiscarded = await this.setBookingDataService.checkSetBookingDiscardConfirmationRequired().catch(err=>{});
    if(!isKitBookingDiscarded) return;

    if(this.contactService.accessedContactListFrom === PageName.ActivitiesDetailsPaneComponent || this.contactService.accessedContactListFrom === PageName.PresentationMeetingComponent){
      contactDetailsSegment = 'profile';
    }
    this.isOpenedOnPreviewMode = isOpenedOnPreview;
    if (!isOpenedOnPreview && this.uiService.isContactsFromToolDrawer && this.contactService.contactPageMode == ComponentViewMode.PREVIEW) {
      if (this.contactService.contactPrevPageMode == ComponentViewMode.ADDNEW || this.contactService.contactPrevPageMode == ComponentViewMode.SELECTION) {
        this.contactService.contactPageMode = this.contactService.contactPrevPageMode;
      } else {
        this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
      }
      this.dynamicFormsService.isOpenedAffiliatedContactOnContact = false;
    }
    try {
      console.log("OMNI-23311:Emails for this contact: "+contact.emailAddressList)
    } catch (error) {

    }
    if(this.contactService.isInGlobalSearch && this.contactService.contactInformation && !isOpenedOnPreview) {
      if(this.contactService.contactInformation.ID == contact.ID) {
        console.log("same as old do nothing");
        return;
      } else {
        this.contactDataService.purgeContactFromDatabase(this.contactService.contactInformation.ID);
      }
    }
    if(this.contactService.scientInfoModalOpen){
      this.events.publish('closeScientInfoModal');
    }
    if (!contact.isActive) {
      this.notificationService.notify(this.translate.instant('CONTACT_NO_LONGER_ACCESS'), 'Contact list', 'top', ToastStyle.DANGER);
      return;
    }
    if(this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) return;
    if (this.navService.getActiveChildNavViewPageName() === PageName.DynamicFormComponent && (this.isCCRFormDirty || this.dynamicFormsService.linkedEntityFormIsDirtyTracker)) {
      let cancelText = this.translate.instant("CANCEL");
      if(this.translate.currentLang == 'it') {
        cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
      }
      this.alertService.showAlert({
        title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
        message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')}, this.translate.instant('DISCARD_CONTACT_CR'), cancelText
      ).then (res => {
        if(res.role == "ok") {
          this.isCCRFormDirty = false;
          this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
          // this.uiService.activeView = 'contactDetails';
          // this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ContactPageComponent);
          this.navService.setChildNavRoot(ContactDetailsComponent, PageName.ContactDetailsComponent, PageName.ContactPageComponent);
          this.navService.setChildNavRightPaneView(true);
          this.uiService.showNewActivity = false;
          this.highlightContact(event, contact);
        }
      });
      return;
    }

    let updatedContact: Contact = null;
    if (!this.contactService.isSchedulerInvoked || !this.contactService.isCaseManagementInvoked) {
      const insightsEventName = this.contactService.contactPageMode === ComponentViewMode.ADDNEW ? 'Contact INFO via Meeting' : 'ContactInfo';
      // this.uiService.activeView = 'contactDetails';
      this.trackingService.tracking(insightsEventName, TrackingEventNames.CONTACTS)
      this.uiService.showNewActivity = false;
      if (this.uiService.isConsentFromToolDrawer && this.contactService.accessedContactListFrom === PageName.ToolsDrawer) {
        this.uiService.contactDetailsSegment = 'consent';
      } else  {
        this.uiService.contactDetailsSegment = contactDetailsSegment;
      }
      this.contactService.isContactDetailsLoaded = false;
      // const connectedState = contact.connectionState;
      this.contactService.contactInformation = contact;
      if (!this.isOpenedOnPreviewMode) {
        this.navService.setChildNavRoot(ContactDetailsComponent, PageName.ContactDetailsComponent, PageName.ContactPageComponent, {contact});
      } else this.navService.setChildNavRoot(ContactDetailsComponent, PageName.ContactDetailsComponent, PageName.ContactPageComponent, {from: 'ContactTool', openedToolFromContactDetails: true, contact});
      this.navService.setChildNavRightPaneView(true);

      if(!isOpenedUnappedData) this.dynamicFormsService.openedUnmappedData = false;
      let loader = await this.loadingCtrl.create();
      if (this.contactService.isInGlobalSearch && !isOpenedOnPreview) {
        /**
         * Get info of contacts not currently in database
         * online only request
         */
        loader.present();

        let temp =  await this.contactService.fetchContactsForConfiguredDisplay(true, false, contact.ID, true)
        if(temp && temp[0]) {
          updatedContact = new Contact(temp[0]);
          this.contactService.rawContactInformation = new Contact(temp[0]);
        }
        if(this.selectedGlobalSearchContact && this.selectedGlobalSearchContact.ID == contact.ID) {
          await this._updateSelectableLinkedEntity(contact.ID, true);
        }
        loader.dismiss();
      }
      else if(this.fetchRealtimeDetailsFlag && !isOpenedOnPreview){
        //loader.present();
        this.contactService.contactInformation = contact;
        this.contactService.isContactDetailsLoaded = true;
        this.contactService.refreshUIofCurrentSelectedContactFlag = false;
        // updatedContact = await this.contactDataService.getContactDetail(contact);
        let temp =  await this.contactService.fetchContactsForConfiguredDisplay(false, false, contact.ID)
        if(temp && temp[0]) updatedContact = new Contact(temp[0]);
        if(this.contactService.refreshUIofCurrentSelectedContactFlag){
          if (updatedContact) {
            this.updateContactLists(updatedContact);
            if(this.contactService.contactInformation && this.contactService.contactInformation.ID == updatedContact.ID && !this.uiService.isConsentFromToolDrawer){
              this.contactService.contactInformation = updatedContact;
            }
            this.contactService.isContactDetailsLoaded = true;
          }else{
            // if(this.contactService.contactInformation.ID == contact.ID){
            //   this.contactService.contactInformation = contact;
            // }
          }
          this.contactService.contactUpdated.next(true);
        }
        //loader.dismiss();
      }
      // if (this.contactService.contactInformation) {
      //   this.contactService.contactInformation.connectionState = connectedState;
      // }
    }
    if (this.contactService.isCaseManagementInvoked) {
      // this.addToSelected(event, contact);
      this.contactService.isContactDetailsLoaded = false;
      this.contactService.contactInformation = contact;
      this.contactService.isContactDetailsLoaded = true;
      // this.uiService.activeView = 'contactDetails';
      this.navService.setChildNavRoot(ContactDetailsComponent, PageName.ContactDetailsComponent, PageName.ContactPageComponent);
      this.navService.setChildNavRightPaneView(true);
      if (this.uiService.isConsentFromToolDrawer && this.contactService.accessedContactListFrom === PageName.ToolsDrawer) {
        this.uiService.contactDetailsSegment = 'capture';
      } else  {
        this.uiService.contactDetailsSegment = contactDetailsSegment;
      }
    }
    else {
      //this.uiService.showNewActivity = false;
      if (this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent) {
        this.uiService.contactDetailsSegment = 'profile';
        this.events.publish('selectedEventParticipantChanged', {selectedContacts: this.selectedContacts, contact: contact});
      }
      this.contactService.isContactDetailsLoaded = false;
      if(updatedContact && this.contactService.contactInformation && updatedContact.ID == this.contactService.contactInformation.ID && !this.uiService.isConsentFromToolDrawer) {
        this.contactService.contactInformation = updatedContact || contact;
      }
      this.contactService.isContactDetailsLoaded = true;
    }
  }

  getConnectionState(contact: Contact) {
    const contacts: Contact[] = this.activityService.selectedActivity && this.activityService.selectedActivity['contacts'];
    if (contacts && contacts.length > 0) {
      const index = contacts.findIndex(c => c.ID === contact.ID);
      if (index >= 0) {
        return contacts[index].connectionState;
      } else {
        return this.contactState.NOTJOINED;
      }
    }
    return this.contactState.NOTJOINED;
  }

  private updateContactLists(updatedContact: Contact, isAddGlobalSearcedContact: boolean = false) {
    if (this.navParams && this.navParams.data && this.navParams.get('openedToolFromContactDetails') && this.navParams.get('openedToolFromContactDetails') === true) return;
    let idx = this.contactsToDisplay.findIndex(c => c.ID === updatedContact.ID);
    if (idx >= 0) {
      this.contactsToDisplay[idx] = updatedContact;
    } else {
      if (isAddGlobalSearcedContact) {
        this.contactsToDisplay.push(updatedContact);
      }
      console.warn('highlightContact: updated contact does not exist in contactToDisplay array');
    }
    // Update the non Guest Contacts, A this will be further use for resetting the COntacts after Search
    idx = this.nonGuestContacts.findIndex(c => c.ID === updatedContact.ID);
    if (idx >= 0) {
      this.nonGuestContacts[idx] = updatedContact;
    } else {
      if (isAddGlobalSearcedContact) {
        this.nonGuestContacts.push(updatedContact);
      }
      console.warn('highlightContact: updated contact does not exist in contactToDisplay array');
    }

    // Update Affiliated Contact List

    if (this.affiliatedContacts && this.affiliatedContacts.length > 0) {
      idx = this.affiliatedContacts.findIndex(c => c.ID === updatedContact.ID);
      if (idx >= 0) {
        this.affiliatedContacts[idx] = updatedContact;
      } else {
        console.warn('highlightContact: updated contact does not exist in contactToDisplay array');
      }
    }

    // update new Contact List
    if (this.newContacts && this.newContacts.length > 0) {
      idx = this.newContacts.findIndex(c => c.ID === updatedContact.ID);
      if (idx >= 0) {
        this.newContacts[idx] = updatedContact;
      } else {
        if (isAddGlobalSearcedContact) {
          this.newContacts.push(updatedContact);
        }
        console.warn('highlightContact: updated contact does not exist in contactToDisplay array');
      }
    } else if(isAddGlobalSearcedContact) {
      let idx = this.newContacts.findIndex(c => c.id == updatedContact.id);
      if (idx >= 0) {
        this.newContacts[idx] = updatedContact;
      } else {
        this.newContacts.push(updatedContact);
      }
    }

    //Update Selecetd Contact List

    if (this.selectedContacts && this.selectedContacts.length > 0) {
      idx = this.selectedContacts.findIndex(c => c.ID === updatedContact.ID);
      if (idx >= 0) {
        this.selectedContacts[idx] = updatedContact;
      } else {
        console.warn('highlightContact: updated contact does not exist in contactToDisplay array');
      }
    }

    // Update Searched Contact
    if (this.searching || this.filtering) {
      idx = this.slicedSearchedContactsToDisplay.findIndex(c => c.ID === updatedContact.ID);
      if (idx >= 0) {
        this.slicedSearchedContactsToDisplay[idx] = updatedContact;
      } else {
        if (isAddGlobalSearcedContact) {
          this.slicedSearchedContactsToDisplay.push(updatedContact);
        }
        console.warn('highlightContact: updated contact does not exist in contactToDisplay array');
      }
    }

    this._initAllSectionHeaderModel();
  }

  // private async getContactDetails(contact) {
  //   const updatedContact = await this.contactDataService.getContactDetail(contact);
  //   if (updatedContact) {
  //     const idx = this.contactsToDisplay.findIndex(c => c.ID === updatedContact.ID);
  //     if (idx >= 0) {
  //       this.contactsToDisplay[idx] = updatedContact;
  //     } else {
  //       console.warn('highlightContact: updated contact does not exist in contactToDisplay array');
  //     }
  //   }
  // }

  private sliceContacts(startIndex: number, count: number) {
    if (this.contactService.contactPageMode == ComponentViewMode.SELECTION && this.contactService.accessedContactListFrom !== PageName.PhoneCallDetailsComponent) {
      return this.selectionEligibleContacts.length < startIndex + count ? this.selectionEligibleContacts.slice(startIndex) : this.selectionEligibleContacts.slice(startIndex, startIndex + count);
    } else {
      return this.nonGuestContacts.length < startIndex + count ? this.nonGuestContacts.slice(startIndex) : this.nonGuestContacts.slice(startIndex, startIndex + count);;
    }
  }

  private sliceSearchedContacts(startIndex: number, count: number) {
    return this.searchedContactsToDisplay.length < startIndex + count ? this.searchedContactsToDisplay.slice(startIndex) : this.searchedContactsToDisplay.slice(startIndex, startIndex + count);
  };

  private sliceGlobalSearchedContacts(startIndex: number, count: number) {
    return this.contactService.globalSearchResults.length < startIndex + count ? this.contactService.globalSearchResults.slice(startIndex) : this.contactService.globalSearchResults.slice(startIndex, startIndex + count);
  };


  public getSecondaryInfo(contact:Contact){
    let secondaryInfo: string = this.secondaryInfoService.getSecondaryInfoFormatedText(contact, SecondaryInfoEntityName.Contact);
    this.hasSecondaryInfo = secondaryInfo ? true : false;
    return secondaryInfo;
  }
  doInfinite(eventDetails,event) {
    this.contactsToDisplay.push(...this.sliceContacts(this.recordCount, 30));
    this.recordCount = this.contactsToDisplay.length;
    event.target.complete();
  }
  doSearchedContactsInfinite(eventDetails,event) {
    this.slicedSearchedContactsToDisplay.push(...this.sliceSearchedContacts(this.searchRecordCount, 30));
    this.searchRecordCount = this.slicedSearchedContactsToDisplay.length;
    event.target.complete();
  }

  doGlobalSearchedContactsInfinite(eventDetails,event) {
    this.slicedGlobalSearchResults.push(...this.sliceGlobalSearchedContacts(this.globalSearchRecordCount, 30));
    this.globalSearchRecordCount = this.slicedGlobalSearchResults.length;
    event.target.complete();
  }

  getContacts() {
    if (this.contactService.contactPageMode == ComponentViewMode.SELECTION && (this.activityService.selectedActivity && this.activityService.selectedActivity.type != ActivityType.PhoneCall
      || this.contactService.accessedContactListFrom ===  PageName.CustomerInsightComponent
      || (this.contactService.accessedContactListFrom ===  PageName.DynamicFormComponent && this.contactService.isOneKeyAffiliatedToContact))) {
      // let allocationEligibleContactIDs = new Map();
      // this.sampleService.contactCustomerSampleAllocations.map((o)=>{
      //   allocationEligibleContactIDs.set(o.contactId, true)
      // })
      // let selectionEligibleContacts = this.nonGuestContacts.filter((c)=>{
      //   return allocationEligibleContactIDs.has(c.ID)
      // })
      // this.selectionEligibleContactsLength = selectionEligibleContacts.length;
      return this.selectionEligibleContacts;
    } else {
      return this.nonGuestContacts;
    }
  }


  updateMeetingSentiments = async () => {
    if (!this.contactService.contactSentimentUpdateActivity) return;
    if (!this.device.isOffline && this.contactService.contactSentimentUpdateActivity instanceof AppointmentActivity) {
      let payload = {
        // "activityPresentations": this.activityService.getPresentationsDTO(this.contactService.contactSentimentUpdateActivity.activityPresentations),
        "activityId": this.contactService.contactSentimentUpdateActivity.ID
        // "activityResources": this.contactService.contactSentimentUpdateActivity.activityResources
      };
      if(!this.isSentimentsDirty){
        // delete payload.activityPresentations;
        // delete payload.activityResources;
      }
      if (this.isKeyMessageSentimentsDirty && !this.isDirty &&this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_KEY_MESSAGE_SENTIMENT) && this.contactService.contactSentimentUpdateActivity.activityContactSentiments && this.contactService.contactSentimentUpdateActivity.activityContactSentiments.length > 0) {
        await this.uiService.displayLoader();
        payload["contactAttendees"] = [];
        this.contactService.contactSentimentUpdateActivity.activityContactSentiments.forEach(acs => {
          let contact = (this.contactService.contactSentimentUpdateActivity as AppointmentActivity).contacts.find(c => c.ID == acs.indskr_contactid);
          if (contact) {
            const contactAttendeePayload = this.meetingDataService.getContactAttendeePayload(contact);
            if (contactAttendeePayload !== null) {
              contactAttendeePayload['activityKeyMessageSentiments'] = acs.activityKeyMessageSentiments;
              payload["contactAttendees"].push(contactAttendeePayload);
            }
          }
        });
      }
      console.log(payload);
      await this.meetingService.updateMeetingSentiment(this.contactService.contactSentimentUpdateActivity, payload);
      await this.uiService.dismissLoader();
    }
    this.contactService.contactSentimentUpdateActivity = undefined;
  };


  storeSelectedContactsForScheduler(isDone) {
    //unset and close selction of contacts
    if (isDone) {
      this.trackingService.tracking('Matched Contacts Selected', TrackingEventNames.SCHEDULER);
      this.contactService.setMatchedContacts(this.selectedContacts);
      this.navService.popWithPageTracking();
      this.contactService.isSchedulerInvoked = false;
      this.uiService.activeView = 'Scheduler';
    }
    else {
      this.trackingService.tracking('Matched Contact Selection Canceled',TrackingEventNames.SCHEDULER);
      this.navService.popWithPageTracking();
      this.contactService.contacts.forEach(e => e.schedulerFrequency = 0);
      this.contactService.contactSentimentUpdateActivity = undefined;
      this.contactService.isSchedulerInvoked = false;
      this.uiService.activeView = 'Scheduler';
    }
    this.footerService.initButtons(FooterViews.SchedulingDetails);
  }

  sortContactsList(){
    this.popover
      .create({component: MultiSelectPopover,componentProps: { root: this.sortPopoverData },event:event})
      .then((data)=>{
        data.present();
      })
  }

  // start advance search functions
  @HostListener('window:click', ['$event.target'])
  onClick(targetElement: string) {
    if(this.suggestionsActive) {
      this.suggestionsActive = false
    }
    this._cd.detectChanges();
  }

  onInput(event) {
    this.searchKey = '';
    //disable search contacts if fired because we eptied the serach text programatically
    if(!this.shouldFireSearchChangeEvent){
      this.shouldFireSearchChangeEvent = true;
      return;
    }
    let params = (event.value) ? event.value : '';
    if(this.currentTab == ContactsModel.CHANGE_REQUESTS_TAB) {
      this.handleCRSearch(params);
      return;
    }
    if (this.contactService.isInGlobalSearch) {
      this.purgeGlobalSearchUserData();
      this.contactService.contactInformation = null;
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ContactPageComponent);
      this.selectedGlobalSearchContact = null;
      this.contactService.isInGlobalSearch = false;
      this.initContactsHeaderLeft();
    }
    if (this.contactService.isOneKeySearching) {
      this.resetOneKeySearch();
    }
    //for length > 2
    if (params.length >= 2) {
      if (params.trim().length == 0) return;
      this.searching = true;
      this.searchKey = params;
      this.searchContactSuggestions(this.getContacts(), params, event);
    }
    // for length 0 to 2
    else {
        this.suggestionsData = [];
        if(!this.selectedSuggestionsData || !this.selectedSuggestionsData.length){
          this.searchedContactsToDisplay = []
          this.slicedSearchedContactsToDisplay = []
          this.searching = false;
          this.sortContactsListBasedOnSelection(this.sortBy)
        }
        else{
          this.searchContactsList();
        }
        if(!this.uiService.isConsentFromToolDrawer){
          if(!this.contactService.recentSearches || !this.contactService.recentSearches.length){
            let searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='Contacts')
            if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
              this.suggestionsActive = false;
            }
          }
        }
        else{
          if(!this.contactService.consentRecentSearches || !this.contactService.consentRecentSearches.length){
            let searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='Consents')
            if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
              this.suggestionsActive = false;
            }
          }
        }
    }
    this.updateEmptyMessage()
    this._cd.detectChanges();
  }

  public async handleCRSearch(searchText: string) {
    if (searchText && searchText.trim().length > 0) {
      const formattedSearchText = this.searchConfigService.convertFormattedString(searchText).toLowerCase();
      this.isFilteredContactCRs = true;
      this.filteredContactCRs = this.contactCRs.filter(c => {
        let firstNameString: string;
        let lastNameString: string;
        if (c.indskr_firstname) firstNameString = this.searchConfigService.convertFormattedString(c.indskr_firstname).toLowerCase();
        if (c.indskr_lastname) lastNameString = this.searchConfigService.convertFormattedString(c.indskr_lastname).toLowerCase();
        return (firstNameString && firstNameString.includes(formattedSearchText) || lastNameString && lastNameString.includes(formattedSearchText));
      });
    } else {
      this.filteredContactCRs = this.contactCRs;
      this.isFilteredContactCRs = false;
    }
    if(this.myContactChangeRequestsHeaderModel) {
      this.myContactChangeRequestsHeaderModel.title = (_.isEmpty(searchText) ? this.translate.instant('MY_CHANGE_REQUESTS') : this.translate.instant('RESULTS_CAP')) + ' (' + this.filteredContactCRs.length + ')';
    }
    this.updateEmptyMessage();
  }

  //search the facets list to display suggestions and also do text search for contacts list
  searchContactSuggestions(contacts: Contact[], searchText: string, event?): Contact[] {

    if (searchText && contacts) {
      searchText = searchText.toUpperCase();
      //map tag with search
        let obj = {
          categoryDisplayName : this.translate.instant("Tag"), //"Tag",
          categoryName  : "Tag",
          categoryRelativePath  : "userTag.indskr_name",
          // featureActionDependant  : "Tag",
          translationKey  : "Tag",
          values  : []
        }
        obj.values= this.contactService.contactTags.map(x=>x.indskr_name);
        let copyContactSearchConfig = JSON.parse(JSON.stringify(this.searchConfigService.contactConfiguredSearchIndexConfig))  
        copyContactSearchConfig.push(obj);
      //first search categories and category values
      let searchIndexConfig = copyContactSearchConfig//this.searchConfigService.contactConfiguredSearchIndexConfig;
      searchIndexConfig = searchIndexConfig.filter(s=>s.configuredFrom != 'Assess');
      let contactsTitle: string;
      //MDM ID category search enabled at searchText length > 2
      const searchTextLength: number = searchText.length;
      if(searchTextLength < 2) searchIndexConfig = searchIndexConfig.filter(s=>s.categoryRelativePath != 'indskr_mdmid');
      if(this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent)
          contactsTitle = this.translate.instant('ACTIVITY_DETAILS_PARTICIPANTS');
      else
          contactsTitle = this.translate.instant(this.utilityService.globalCustomersText);
      if(this.uiService.isConsentFromToolDrawer)
          searchIndexConfig = searchIndexConfig.filter(config=>!config.customizedBypassGateEnabled);
      this.suggestionsData = this.searchConfigService.fetchSuggestions(searchIndexConfig,
                              this.selectedSuggestionsData, contactsTitle,
                              this.contactSearchText, !this.uiService.isConsentFromToolDrawer && !this.device.isOffline);
       //testing for delete
       this.suggestionsData.filter(y=>y.header).filter(y=>y.header.title=="Tag" || y.header.title == this.translate.instant("Tag")).forEach(t=>t.listData.forEach(y=>{
        let tag = this.contactService.contactTags.find(x=>x.indskr_name==y.primaryTextLeft)
        if(tag){
          y.showArrow = tag.visibility == TagVisibility.PERSONAL;
          y.arrowType="indegene-ios-close-icon";
          y.tagDetails= tag;
          y.clickHandler = ((id?: string, event?: any, specificTarget?: string, tag?: any) => {
            if (specificTarget == 'endArrow') {
              this.deleteTag(tag.tagDetails);
            } 
            else if (specificTarget == 'mainItem'){
              this.handleTagSelection(tag.tagDetails);
            }
          })
        }  
      }));                        
      if(this.suggestionsData.length) this.suggestionsActive = true;
      if(!this.selectedSuggestionsData || !this.selectedSuggestionsData.length){
        this.searchedContactsToDisplay = contacts
      }
      const formattedSearchText = this.searchConfigService.convertFormattedString(searchText).trim();
      this.searchedContactsToDisplay = this.searchedContactsToDisplay.filter(contact => {
        let contactString = this.getOjectValues(contact);
        return contactString.includes(formattedSearchText);
      });
    } else {
      return [];
    }
    this.filterMetadata.count = this.searchedContactsToDisplay.length;
    if (this.searchRecordCount < 30) { this.searchRecordCount = 30; }
    this.slicedSearchedContactsToDisplay = this.sliceSearchedContacts(0, this.searchRecordCount);
    this._initAllSectionHeaderModel();
  }
  // public handleTagSelectionTag(tagData: con) {
  //   if (!tagData) return;
  //   this.suggestionsActive = false;
  //   this.showRemoveTag = true;
  //   this._selectedAccountsForTag = [];
  //   this.searching = true;
  //   this.filteredList =[];
  //   tagData.entityRecords.forEach(record => {
  //     let account = this.contactService.getContactByID(record.);
  //     if (account && !(!account.status || account.status === 2 || account.status == 548910003 || account.status == 548910010 || account.status == 548910011 || account.status == 548910012 || account.status == 548910013)) {
  //       account.isSelectedForTag = false;
  //       if (!this.filteredList.some(acc => acc.id == record.id)) {
  //         this.filteredList.push(account);
  //       }
  //     }
  //   });
  // }
  private deleteTag(userTagDetails: any) {
    this.alertService.showAlert({
      title: this.translate.instant('DELETE_TAG'),
      cssClass: 'saveSearchAlert',
      message: this.translate.instant('TAG_DELETE_CONFIRMATION'),
    }, this.translate.instant('DELETE'), this.translate.instant('CANCEL'))
      .then(async (res) => {
        if (res.role == 'ok') {
            let deleteTagPayload = new UserTagForContact(userTagDetails.indskr_externalid, userTagDetails.indskr_usertagid, true, userTagDetails.indskr_name, [],true,TagStateCode.InActive,'');
            this.contactService.deleteContactTag(deleteTagPayload);
          
        }
      });
  }

  //search the list based off selected facets
  async searchContactsList(isFilter?: boolean) {
    if(isFilter) this.filtering = true;
    else this.searching = true;
    let selectedData: SelectedSuggestionPillDataModel[] = [];
    if(this.filtering && this.searching) {
      selectedData = _.uniq(_.concat(this.selectedFilterData, this.selectedSuggestionsData));
    } else {
      selectedData = isFilter ? this.selectedFilterData : this.selectedSuggestionsData;
    }
    //second level search for contacts
    selectedData = this.mapForUserTag(selectedData);
    let filteredContacts:any = this.getContacts();
    let selectedSuggestionsDataCopy = selectedData.slice().filter(data => data.categoryName != 'tag'); // user tag method has differnt filter method
    this.isAssessmentFilter = selectedData.filter(data => data.categoryName == "Assessed" || data.categoryName == "UnAssessed").length > 0;
    let entityLevelCharSearch = selectedSuggestionsDataCopy.find(o=> o.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH);
    const formattedSearchText = entityLevelCharSearch && entityLevelCharSearch.charSearchText ? this.searchConfigService.convertFormattedString(entityLevelCharSearch.charSearchText).toUpperCase() : '';
    const formattedSearchTextSplit = formattedSearchText ? formattedSearchText.split(" ") : '';
    if (entityLevelCharSearch && formattedSearchTextSplit) {
      formattedSearchTextSplit.forEach(searchText => {
        filteredContacts = filteredContacts.filter(contact => {
          let contactEntityLevel = this.getOjectValuesEntityLevel(contact);
           return contactEntityLevel.includes(searchText);
         });
      });
    }
    // Handle Account Tag AND operation
    if(this.selectedSuggestionsData && this.selectedSuggestionsData.some(a=> a.categoryName == 'listTag')){
      try {
        filteredContacts = filteredContacts.filter(contact => contact.tagLabels.includes(this.selectedSuggestionsData.find(a=> a.categoryName == 'listTag').selectedFacet));
      } catch (error) {}
    }
    let filteredContactsForAssessmentFilter = _.cloneDeep(filteredContacts);
    let groupedSelectedSuggestions: {
      category:string,
      suggestions:SelectedSuggestionPillDataModel[]
    }[] = [];
    selectedSuggestionsDataCopy.forEach(data=>{
      if(data && data.categoryName && (!data.isSubCategory || data.configuredFrom == 'Assess')){
        let groupIndex = groupedSelectedSuggestions.findIndex(group=>group.category==data.categoryName);
        const suggestions = getSearchSuggestionsData(data);
        if(groupIndex>-1){
          groupedSelectedSuggestions[groupIndex].suggestions.push(...suggestions);
        }
        else{
          if(data.categoryName != 'listTag'){
            groupedSelectedSuggestions.push({
              category:data.categoryName,
              suggestions:[...suggestions]
            });
          }
        }
      }
    })
    let isAssessed: boolean = false;
    let isUnAssessed: boolean = false;
    if (!_.isEmpty(groupedSelectedSuggestions)) {
      groupedSelectedSuggestions.forEach(config => {
        if (config.category === "Assessed" || config.category === "UnAssessed") {
          isAssessed = isAssessed || config.category === "Assessed";
          isUnAssessed = isUnAssessed || config.category === "UnAssessed";
        }
        if ((config.category === "Assessments" || config.category === this.translate.instant("Assessments")) && config.suggestions && config.suggestions.length > 0) {
          // Update flags based on suggestions
          isAssessed = isAssessed || config.suggestions.some(s => s.selectedFacet === "Assessed" || s.selectedFacet === this.translate.instant("ASSESSED"));
          isUnAssessed = isUnAssessed || config.suggestions.some(s => s.selectedFacet === "UnAssessed" || s.selectedFacet === this.translate.instant("UNASSESSED"));
        }
      });
    }
    for (let i = 0; i < groupedSelectedSuggestions.length; i++) {
      const groupConfig = groupedSelectedSuggestions[i];
      const isAssessmentsCategory: boolean = groupConfig.category == "Assessments" || groupConfig.category == this.translate.instant("Assessments");
      let tempContactsList: Contact[] = filteredContacts;
      let filteredContactsWithinSameCategory: any = [];
      let activeConsents, sciPlans, activeAssessments;

      if ((groupConfig.category == "Assessed" || isAssessmentsCategory) && !isUnAssessed) {
        this.customerAssessService.assessedContacts.forEach(assessContact => {
          let thisContact = tempContactsList.find(a => a.ID == assessContact['contactid']);
          if (!thisContact) return;
          if (thisContact.isguest === false && thisContact.isActive && !filteredContactsWithinSameCategory.some(cont => cont.ID == assessContact['contactid'])) {
            filteredContactsWithinSameCategory.push(thisContact);
          }
        })
      } else if ((groupConfig.category == "UnAssessed" || isAssessmentsCategory) && !isAssessed) {
        this.getContacts().forEach(nonGuestContact => {
          if (!this.customerAssessService.assessedContacts.some(assessContact => assessContact['contactid'] == nonGuestContact.ID)) {
            let thisContact = tempContactsList.find(a => a.ID == nonGuestContact.ID);
            if (!thisContact) return;
            if (thisContact.isguest === false && thisContact.isActive && !filteredContactsWithinSameCategory.some(cont => cont.ID == nonGuestContact.ID)) filteredContactsWithinSameCategory.push(nonGuestContact);
          }
        })
      } else if((groupConfig.category == "Assessed" || groupConfig.category == "UnAssessed" || isAssessmentsCategory) && isAssessed && isUnAssessed) {
          if(groupedSelectedSuggestions.length == 2) filteredContactsWithinSameCategory = filteredContactsForAssessmentFilter;
          else filteredContactsWithinSameCategory = tempContactsList;
      } else {
        for (let j = 0; j < groupConfig.suggestions.length; j++) {
          const config = groupConfig.suggestions[j];
          let searchConfig;
          if (config.configuredFrom == 'Assess') {
            searchConfig = this.searchConfigService.contactConfiguredSearchIndexConfig.find(a => a.categoryName == config.categoryName);
            config.categoryPath = searchConfig ? searchConfig.categoryRelativePath : config.categoryPath;
          } else if (config.categoryPath) {
            searchConfig = this.searchConfigService.contactConfiguredSearchIndexConfig.find(a => a.categoryRelativePath == config.categoryPath);
          } else {
            searchConfig = this.searchConfigService.contactConfiguredSearchIndexConfig.find(a => a.categoryName == config.categoryName);
            config.categoryPath = searchConfig ? searchConfig.categoryRelativePath : config.categoryPath;
          }
          //Incase of primary account filter also, filter based on account-contact affiliation link entity data
          if (config.categoryPath === "indskr_primaryaccount") {
            config.categoryPath = "indskr_accountcontactaffiliation.indskr_accountid";
            searchConfig.categoryRelativePath = "indskr_accountcontactaffiliation.indskr_accountid";
            searchConfig.controlDataType = "LinkedEntity";
            searchConfig.relationshipName = "indskr_contact_accountcontactaffiliation_contact";
            searchConfig.parentEntity = "indskr_accountcontactaffiliation";
          }
          let key = [config.categoryPath];
          var options2 = {
            keys: key,
            threshold: 0,
            distance: 100,
            includeScore: true
          }
          let searchText: string;
          if (config.type && (config.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH || config.type == SuggestionPillType.BOOLEAN_FIELD)) {
            searchText = config.charSearchText;
            options2.threshold = 0.1;
            options2.distance = 1000;
          }
          else {
            searchText = config.selectedFacet;
          }
          if (searchConfig && searchConfig.controlDataType == 'DefaultConfig') {
            if (config.categoryName.includes('Consent') && config.type != SuggestionPillType.BOOLEAN_FIELD) {
              if (activeConsents == undefined) {
                activeConsents = this.consentService.allActiveConsentSubject.value
              }
              activeConsents = activeConsents.filter(actCons => {
                return filteredContacts.some(contact => contact.ID == actCons.indskr_contactid && actCons.indskr_iseventparticipant != true)
              })
              let newFuse = new fuse(activeConsents, options2);
              let results = newFuse.search(searchText);
              //@ts-ignore
              results.forEach((result: any) => {
                if ((config.type && config.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH) || result.score < 0.1) {
                  let thisContact = this.contactService.getContactByID(result.item.indskr_contactid);
                  if (!filteredContactsWithinSameCategory.some(cont => cont.ID == thisContact.ID)) filteredContactsWithinSameCategory.push(thisContact);
                }
              })
            }
            else if (config.categoryName == 'Scientific Plans') {
              if (sciPlans == undefined) {
                sciPlans = this.scientificActivityService.sapActivities
              }
              sciPlans = sciPlans.filter(sapAct => {
                let contactsMatchedAlready = _.intersectionBy(filteredContacts, sapAct.contacts, 'ID');
                return contactsMatchedAlready.length > 0;
              })
              let newFuse = new fuse(sciPlans, options2);
              let results = newFuse.search(searchText);
              //@ts-ignore
              results.forEach((result: any) => {
                if ((config.type && config.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH) || result.score < 0.1) {
                  result.item.contacts.forEach(sapContact => {
                    let thisContact = this.contactService.getContactByID(sapContact.ID);
                    if (!filteredContactsWithinSameCategory.some(cont => cont.ID == thisContact.ID)) filteredContactsWithinSameCategory.push(thisContact);
                  })
                }
              })
            }
            //******Fixed filter items - My_Activities: Meetings, Live meetings, Phone Calls, Messages, Allocation order, Customer Inquiries
            else if (config.categoryPath == "myActivitiesByContact") {
              let activitiesContactIds = [];
              let tempActivities = _.cloneDeep(this.activityService.activities);
              //Combined Events Activities
              if(!_.isEmpty(this.eventsToolService.eventsToolData)) {
                let tempEventActivities = _.cloneDeep(this.eventsToolService.eventsToolData);
                tempEventActivities.map(activity=> {
                  activity['scheduledStart'] = new Date(activity._startDate);
                });
                tempActivities = tempActivities.concat(tempEventActivities);
              }
              //***step1. Check filtered by Date
              const isFilteredByDate: boolean = !_.isEmpty(this.selectedDateFromFilter) && !_.isEmpty(this.selectedDateFromFilter.startDate) && !_.isEmpty(this.selectedDateFromFilter.endDate) || false;
              if(!_.isEmpty(tempActivities) && isFilteredByDate) {
                tempActivities = tempActivities.filter(activity => new Date(activity['scheduledStart']) >= new Date(this.selectedDateFromFilter.startDate) && new Date(activity['scheduledStart']) <= new Date(this.selectedDateFromFilter.endDate));
              }
              //***step2. Check selected sub creteria
              const selectedSubCategories = config.subCategories ? config.subCategories : [];
              //meetings-status
              let isFilterClosedMeeting: boolean = false;
              let isFilterOpenMeeting: boolean = false;
              //messges-status
              let isFilterReadMessage: boolean = false;
              let isFilterDeliveredMessage: boolean = false;
              //messages-attachment
              let isFilterNoAttachment: boolean = false;
              let isFilterYesAttachment: boolean = false;
              //messages-link-accessed including attachment accessed
              let isFilterNoLinkAccessed: boolean = false;
              let isFilterYesLinkAccessed: boolean = false;

              if(!_.isEmpty(selectedSubCategories)) {
                selectedSubCategories.forEach(category=>{
                  if(category.id == "filterMeetingsStatus" && !_.isEmpty(category.childItems)) {
                    category.childItems.forEach(cItem=>{
                      if(cItem.isChecked) {
                        if(cItem.label == this.translate.instant("COMPLETED")) {
                          isFilterClosedMeeting = true;
                        }else if(cItem.label == this.translate.instant("OPEN")) {
                          isFilterOpenMeeting = true;
                        }
                      }
                    });
                  }else if(category.id == "filterMessagesStatus" && !_.isEmpty(category.childItems)) {
                    category.childItems.forEach(cItem=>{
                      if(cItem.isChecked) {
                        if(cItem.label == this.translate.instant("DELIVERED")) {
                          isFilterDeliveredMessage = true;
                        }else if(cItem.label == this.translate.instant("READ")) {
                          isFilterReadMessage = true;
                        }
                      }
                    });
                  }else if(category.id == "filterMessagesAttachment" && !_.isEmpty(category.childItems)) {
                    category.childItems.forEach(cItem=>{
                      if(cItem.isChecked) {
                        if(cItem.label == this.translate.instant("NO")) {
                          isFilterNoAttachment = true;
                        }else if(cItem.label == this.translate.instant("YES")) {
                          isFilterYesAttachment = true;
                        }
                      }
                    });
                  }else if(category.id == "filterMessagesLinkAccessed" && !_.isEmpty(category.childItems)) {
                    category.childItems.forEach(cItem=>{
                      if(cItem.isChecked) {
                        if(cItem.label == this.translate.instant("NO")) {
                          isFilterNoLinkAccessed = true;
                        }else if(cItem.label == this.translate.instant("YES")) {
                          isFilterYesLinkAccessed = true;
                        }
                      }
                    });
                  }
                });
              }
              //***step3. Check eligible contact IDs based on the selected category
              //Meetings
              if(config.selectedFacet == this.translate.instant("MEETINGS")) {
                if(!_.isEmpty(tempActivities)) {
                  const targetActivities = tempActivities.filter(activity=>activity instanceof AppointmentActivity && activity.type == ActivityType.Appointment && activity.location != 'LiveMeet') as AppointmentActivity[];
                  if(!_.isEmpty(targetActivities)) {
                    targetActivities.forEach(activity=>{
                      if(!_.isEmpty(activity.contacts)) {
                        activity.contacts.map(cont=>{
                          if(cont.ID && !activitiesContactIds.some(contId=>contId == cont.ID)) {
                            if(!isFilterClosedMeeting && !isFilterOpenMeeting) {
                              activitiesContactIds.push(cont.ID);
                            }else {
                              if(isFilterClosedMeeting && activity.state == 1) {
                                let idx = activitiesContactIds.findIndex(id=>id==cont.ID);
                                if(idx == -1) activitiesContactIds.push(cont.ID);
                              }
                              if(isFilterOpenMeeting && activity.state == 0) {
                                let idx = activitiesContactIds.findIndex(id=>id==cont.ID);
                                if(idx == -1) activitiesContactIds.push(cont.ID);
                              }
                            }
                          }
                        });
                      }
                    });
                  }
                }
              }
              //Live Meetings
              if(config.selectedFacet == this.translate.instant("LIVE_MEETING")) {
                if(!_.isEmpty(tempActivities)) {
                  const targetActivities = tempActivities.filter(activity=>activity instanceof AppointmentActivity && activity.type == ActivityType.Appointment && activity.location == 'LiveMeet') as AppointmentActivity[];
                  if(!_.isEmpty(targetActivities)) {
                    targetActivities.forEach(activity=>{
                      if(!_.isEmpty(activity.contacts)) {
                        activity.contacts.map(cont=>{
                          if(cont.ID && !activitiesContactIds.some(contId=>contId == cont.ID)) {
                            let idx = activitiesContactIds.findIndex(id=>id==cont.ID);
                            if(idx == -1) activitiesContactIds.push(cont.ID);
                          }
                        });
                      }
                    });
                  }
                }
              }
              //Phone Calls
              else if(config.selectedFacet == this.translate.instant("PHONE_CALLS")) {
                if(!_.isEmpty(tempActivities)) {
                  const targetActivities = tempActivities.filter(activity=>activity instanceof PhoneActivity && activity.type == ActivityType.PhoneCall && (activity.state == 1 || activity.statusString == 'Completed')) as PhoneActivity[];
                  if(!_.isEmpty(targetActivities)) {
                    targetActivities.forEach(activity=>{
                      if(!_.isEmpty(activity.raw_contactAttendees)) {
                        activity.raw_contactAttendees.map(cont=>{
                          if(cont.indskr_contactid && !activitiesContactIds.some(contId=>contId == cont.indskr_contactid)) {
                            activitiesContactIds.push(cont.indskr_contactid);
                          }
                        });
                      }
                    });
                  }
                }
              }
              //Messages
              else if(config.selectedFacet == this.translate.instant("MESSAGES")) {
                if(!_.isEmpty(tempActivities)) {
                  let targetActivities = tempActivities.filter(activity=>activity instanceof EmailActivity && activity.type == ActivityType.Email && (activity.emailStatus == 3 || activity.emailStatus == 548910000)) as EmailActivity[];
                  //Filter by attachments
                  if(isFilterYesAttachment && !isFilterNoAttachment) {
                    targetActivities = targetActivities.filter(activity=> !_.isEmpty(activity.emailAttachments));
                  }else if(!isFilterYesAttachment && isFilterNoAttachment) {
                    targetActivities = targetActivities.filter(activity=> _.isEmpty(activity.emailAttachments));
                  }
                  if(!_.isEmpty(targetActivities)) {
                    targetActivities.forEach(activity=>{
                      if(!_.isEmpty(activity.emailActivityParties)) {
                        activity.emailActivityParties.map(p=>{
                          if(p.indskr_contactid && !activitiesContactIds.some(contId=>contId == p.indskr_contactid)) {
                            if(!isFilterDeliveredMessage && !isFilterReadMessage && !isFilterNoLinkAccessed && !isFilterYesLinkAccessed) {
                              activitiesContactIds.push(p.indskr_contactid);
                            }
                            else {
                              let isMatchedCondition: boolean = true;
                              //Filter by Transfer Status
                              if(isFilterReadMessage && !isFilterDeliveredMessage) {
                                isMatchedCondition = (p.email_statuscode == 0 || p.email_statuscode == 4) && isMatchedCondition;
                              }else if(!isFilterReadMessage && isFilterDeliveredMessage) {
                                isMatchedCondition = (p.email_statuscode == 3 || p.email_statuscode == 548910000) && isMatchedCondition;
                              }
                              if(isFilterNoLinkAccessed && isFilterYesLinkAccessed) {
                                isMatchedCondition = true;
                              }else if(isFilterNoLinkAccessed || isFilterYesLinkAccessed) {
                                if(!_.isEmpty(p.emailAddresses)) {
                                  let isClickedAttachment: boolean = false;
                                    p.emailAddresses.forEach(e=> {
                                    //Filter by Link Accessed
                                    let numOfClicked: number = 0; 
                                    if (e.email_linksclickedcount) {
                                      numOfClicked += e.email_linksclickedcount;
                                    } 
                                    if (e.email_attachmentopencount) {
                                      numOfClicked += e.email_attachmentopencount;
                                    }
                                    if(isFilterNoLinkAccessed && !isFilterYesLinkAccessed) {
                                      isClickedAttachment = isClickedAttachment || numOfClicked == 0;
                                    }else if(!isFilterNoLinkAccessed && isFilterYesLinkAccessed) {
                                      isClickedAttachment = isClickedAttachment || numOfClicked > 0;
                                    }
                                  });
                                  isMatchedCondition = isClickedAttachment && isMatchedCondition;
                                }else {
                                  isMatchedCondition = false;
                                }
                              }

                              if(isMatchedCondition) {
                                let idx = activitiesContactIds.findIndex(id=>id==p.indskr_contactid);
                                if(idx == -1) activitiesContactIds.push(p.indskr_contactid);
                              }
                            }
                          }
                        });
                      }
                    });
                  }
                }
              }
              //Allocation Order
              else if(config.selectedFacet == this.translate.instant("ALLOCATION_ORDER")) {
                if(!_.isEmpty(tempActivities)) {
                  const targetActivities = tempActivities.filter(activity=>activity instanceof SampleActivity && activity.type == ActivityType.Sample && (activity.state == 1 || activity.statusString == 'Completed')) as SampleActivity[];
                  if(!_.isEmpty(targetActivities)) {
                    targetActivities.forEach(activity=>{
                      if(!_.isEmpty(activity.contactID) && !activitiesContactIds.some(contId=>contId == activity.contactID)) {
                        activitiesContactIds.push(activity.contactID);
                      }
                    });
                  }
                }
              }
              //Customer Inquiries
              else if(config.selectedFacet == this.translate.instant('CUSTOMER_INQUIRIES', { globalCustomerText: this.utilityService.globalCustomerText })) {
                if(!_.isEmpty(tempActivities)) {
                  const targetActivities = tempActivities.filter(activity=>activity instanceof CaseActivity && activity.type == ActivityType.CaseIntake) as CaseActivity[];
                  if(!_.isEmpty(targetActivities)) {
                    targetActivities.forEach(activity=>{
                      if(!_.isEmpty(activity.raw) && !_.isEmpty(activity.raw['customerid_value']) && !activitiesContactIds.some(contId=>contId == activity.raw['customerid_value'])) {
                        activitiesContactIds.push(activity.raw['customerid_value']);
                      }
                    });
                  }
                }
              }
              //Events
              else if(config.selectedFacet == this.translate.instant('EVENTS')) {
                if(!_.isEmpty(tempActivities)) {
                  const targetActivities = tempActivities.filter(activity=>activity instanceof EventActivity) as EventActivity[];
                  if(!_.isEmpty(targetActivities)) {
                    targetActivities.forEach(activity=>{
                      if(!_.isEmpty(activity.participants)) {
                        activity.participants.map(p=>{
                          if(p.id && !activitiesContactIds.some(contId=>contId == p.id)) {
                            activitiesContactIds.push(p.id);
                          }
                        });
                      }
                    });
                  }
                }
              }
              //***step4. Update filtered contact list
              let exactMatchResults = [];
              activitiesContactIds.map(contId=> {
                let thisContact = this.contactService.getContactByID(contId);
                if(!_.isEmpty(thisContact) && thisContact.isActive && !thisContact.isguest && !thisContact.indskr_iseventparticipant) {
                  if(tempContactsList.some(cont => cont.ID == thisContact.ID)) {
                    exactMatchResults.push(thisContact);
                  }
                }
              });
              filteredContactsWithinSameCategory = _.uniq(_.concat(filteredContactsWithinSameCategory, exactMatchResults))
            }
            else if (config.categoryPath == "hasTeamusers" && config.selectedFacet) {
              try {
                if(config.value) {
                  if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT)) {
                    this.selectedSuggestionsData = [];
                    if(!this.searchConfigService.isTargetContactsPillRemoved) {
                      await this.uiService.displayLoader();
                      let response = [];
                      try {
                        response = await this.contactDataService.getTargetContactsBasedOnPositionId(config.value);
                      } catch (error) {
                        console.error('TargetContacts - ContactList: ', error);
                      } finally {
                        if(!_.isEmpty(response)) {
                          console.warn('TargetContacts - Found');

                          this.mapGlobalSearchResults(response, true);
                          filteredContactsWithinSameCategory = this.contactService.globalSearchResults;
                          this.contactService.globalSearchResults = [];
  
                          if(response[0]['customerListPeriodName']) {
                            this.searching = true;
                            this.searchConfigService.isTargetContactsPillRemoved = true;
                            this.selectedSuggestionsData = [{
                              selectedFacet: response[0]['customerListPeriodName'],
                              categoryPath: 'listTag',
                              categoryName: 'listTag',
                              categoryDisplayName: 'listTag',
                            }]
                          }
                        } else {//end of if(!_.isEmpty(response))
                          console.warn('No TargetContacts');

                          await this.uiService.displayLoader();
                          let response = [];
                          try {
                            response = await this.contactDataService.getContactsBasedOnPositionId(config.value);
                          } catch (error) {
                            console.error('ContactList: ', error);
                          } finally {
                            if(!_.isEmpty(response)) {
                              this.mapGlobalSearchResults(response, true);
                              filteredContactsWithinSameCategory = this.contactService.globalSearchResults;
                              this.contactService.globalSearchResults = [];
                            }
                            await this.uiService.dismissLoader();
                          }
                        } 
                        await this.uiService.dismissLoader();
                      }
                    } else {
                      console.warn(`this.searchConfigService.isTargetContactsPillRemoved: ${this.searchConfigService.isTargetContactsPillRemoved}`)
                    } //end of if(!this.searchConfigService.isTargetContactsPillRemoved)
                    
                  } else {
                      console.warn('Fetch ContactList');

                      await this.uiService.displayLoader();
                      let response = [];
                      try {
                        response = await this.contactDataService.getContactsBasedOnPositionId(config.value);
                      } catch (error) {
                        console.error('ContactList: ', error);
                      } finally {
                        if(!_.isEmpty(response)) {
                          this.mapGlobalSearchResults(response, true);
                          filteredContactsWithinSameCategory = this.contactService.globalSearchResults;
                          this.contactService.globalSearchResults = [];
                        }
                        await this.uiService.dismissLoader();
                      }
                  }//end of if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT))
                }
              }
              catch(error) {
                console.log(error);
                await this.uiService.dismissLoader();
              }
            }
            else {
              if (config.categoryPath == 'allPhoneNumbers') {
                options2.distance = 1000;
                options2.threshold = 0.05
              }
              let newFuse = new fuse(filteredContacts, options2);
              let results = newFuse.search(searchText);
              let exactMatchResults = [];
              //@ts-ignore
              results.forEach((result: any) => {
                if ((config.categoryPath == 'allPhoneNumbers') || (config.type && config.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH) || result.score < 0.1) {
                  exactMatchResults.push(result.item)
                }
              })
              filteredContactsWithinSameCategory = _.uniq(_.concat(filteredContactsWithinSameCategory, exactMatchResults))
            }
          } else if (searchConfig && searchConfig.controlDataType == 'LinkedEntity') {
            //options2.keys = [[config.categoryPath]];
            const dbKey = searchConfig.parentEntity !== CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY ? searchConfig.parentEntity : searchConfig.relationshipName;
            let rawLEData = await this.disk.retrieve(dbKey);
            if (rawLEData && rawLEData.raw) {
              let suffix = '@OData.Community.Display.V1.FormattedValue';
              if (searchConfig.isMultilingualLookup && config.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
                suffix = '';
                let foundMappingValue;
                if (searchConfig.mappingValues && Array.isArray(searchConfig.mappingValues)) {
                  foundMappingValue = searchConfig.mappingValues.find(a => a.formattedValue == searchText);
                  if (foundMappingValue && foundMappingValue.actualValue) {
                    searchText = foundMappingValue.actualValue;
                  }
                }
              }
              const linkedEntityData = rawLEData.raw;
              let currentData;
              if (config.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
                currentData = linkedEntityData.filter(item => item[key[0] + suffix] == searchText || item[key[0]] == searchText);
              } else if (config.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH) {
                currentData = linkedEntityData.filter(item => {
                  let formattedValueA = item[key[0] + suffix] ? this.searchConfigService.convertFormattedString(item[key[0] + suffix]).toLowerCase() : '';
                  let formattedValueB = item[key[0]] ? this.searchConfigService.convertFormattedString(item[key[0]]).toLowerCase() : '';
                  let formattedSearchText = searchText ? this.searchConfigService.convertFormattedString(searchText).toLowerCase() : '';
                  return formattedValueA && formattedValueA.includes(formattedSearchText) || formattedValueB && formattedValueB.includes(formattedSearchText);
                });
              }
              currentData.forEach(linkedEntityObj => {
                let thisContact = tempContactsList.find(a => a.ID == linkedEntityObj.contactid);
                //let thisContact = this.contactService.getContactByID(linkedEntityObj.contactid);
                if (!thisContact) return;
                if (thisContact.isguest === false && thisContact.isActive && !filteredContactsWithinSameCategory.some(cont => cont.ID == thisContact.ID)) {
                  filteredContactsWithinSameCategory.push(thisContact);
                }
              });
            }
          } else if (searchConfig && searchConfig.controlDataType == 'Assess') {
            if (searchConfig.categoryRelativePath == "indskr_choicelabel") {
              if (config.selectedFacetInEng) {
                  searchText = config.selectedFacetInEng
                }
              options2.distance = 1000;
              options2.threshold = 0.2;
            }
            let isTypeChoice: boolean = searchConfig.categoryRelativePath == "indskr_choicelabel";
            let searchType =  isTypeChoice ? AssessmentAttributeType.CHOICE : AssessmentAttributeType.FLAG;
            let categoryName = searchConfig.categoryNameInEng ? searchConfig.categoryNameInEng : searchConfig.categoryName;
            let assessments = await this.customerAssessService.getAssessmentResponsesForOnlineSearch(EntityOptions.CONTACT, searchType, categoryName);

            if(assessments) {
              assessments = assessments.filter(assess => {
                return filteredContacts.some(contact => contact.ID == assess['ca.indskr_customer']);
              })
              const sortedActiveAssessments = _.orderBy(assessments, ['modifiedon','createdon'], ['desc','desc']);
              const latestRecords = _.uniqBy(sortedActiveAssessments, 'ca.indskr_customer');
              let results;
              if (searchConfig.categoryRelativePath == "indskr_choicelabel" && config.type && config.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
                results = latestRecords.filter(assess => {
                  if(assess['indskr_choicelabel']) {
                    //check multiple choice
                    let isMatched: boolean = false;
                    let tempData = assess['indskr_choicelabel'].split(',') || [];
                    if(!_.isEmpty(tempData) && tempData.length > 1) {
                      tempData.forEach((d,idx)=> {
                        if(tempData[idx] == searchText) isMatched = true;
                      });
                      return isMatched;
                    }else {
                      return assess['indskr_choicelabel'] == searchText;
                    }
                  }
                });
                //@ts-ignore
                results.forEach((result: any) => {
                  let thisContact = this.contactService.getContactByID(result['ca.indskr_customer']);
                  if (!filteredContactsWithinSameCategory.some(cont => cont.ID == thisContact.ID)) filteredContactsWithinSameCategory.push(thisContact);
                })
              } else {
                let newFuse = new fuse(latestRecords, options2);
                results = newFuse.search(searchText);
                //@ts-ignore
                results.forEach((result: any) => {
                  let thisContact = this.contactService.getContactByID(result.item['ca.indskr_customer']);
                  if (!filteredContactsWithinSameCategory.some(cont => cont.ID == thisContact.ID)) filteredContactsWithinSameCategory.push(thisContact);
                })
              }
            }
          } else if (searchConfig && searchConfig.controlDataType != 'LinkedEntity' && searchConfig.controlDataType != 'DefaultConfig') {
            let suffix: string = '';
            let prefix: string = '';
            if (searchConfig.controlDataType) {
              if (searchConfig.controlDataType == ControlDataType.PicklistType || searchConfig.controlDataType == ControlDataType.BooleanType
                || searchConfig.controlDataType == ControlDataType.MultiSelectPicklistType
                || searchConfig.controlDataType == ControlDataType.StatusType || searchConfig.controlDataType == ControlDataType.StateType) {
                suffix = '@OData.Community.Display.V1.FormattedValue'
              } else if (searchConfig.controlDataType == ControlDataType.LookupType) {
                if (searchConfig.isMultilingualLookup && config.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
                  suffix = '_value';
                  prefix = '_';
                  let foundMappingValue;
                  if (searchConfig.mappingValues && Array.isArray(searchConfig.mappingValues)) {
                    foundMappingValue = searchConfig.mappingValues.find(a => a.formattedValue == searchText);
                    if (foundMappingValue && foundMappingValue.actualValue) {
                      searchText = foundMappingValue.actualValue;
                    }
                  }
                } else {
                  suffix = '_value@OData.Community.Display.V1.FormattedValue'
                  prefix = '_';
                }
              }
            }
            let searchKey = prefix + searchConfig.categoryRelativePath + suffix;
            let rawContactList = tempContactsList.map(a => a.raw);
            let results;
            if (config.type == SuggestionPillType.CATEGORY_VALUESEARCH || config.type == SuggestionPillType.BOOLEAN_FIELD) {
              results = rawContactList.filter(con => con.hasOwnProperty('contactid') && con.hasOwnProperty(searchKey) && con[searchKey] == searchText);
            } else if (config.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH) {
              results = rawContactList.filter(con => {
                let formattedValue = con[searchKey] ? this.searchConfigService.convertFormattedString(con[searchKey]).toLowerCase() : '';
                let formattedSearchText = searchText ? this.searchConfigService.convertFormattedString(searchText).toLowerCase() : '';
                return con.hasOwnProperty('contactid') && formattedValue && formattedValue.includes(formattedSearchText);
              });
            }

            let exactMatchResults = [];
            //@ts-ignore
            results?.forEach((result: any) => {
              let contactObj = tempContactsList.find(a => a.ID == result.contactid);
              if (contactObj) {
                exactMatchResults.push(contactObj)
              }
            })
            filteredContactsWithinSameCategory = _.uniq(_.concat(filteredContactsWithinSameCategory, exactMatchResults))
          }
        }
      }
      filteredContacts = filteredContactsWithinSameCategory;
    }

    let tagSuggestion = selectedData.filter(o=>(o.categoryName=='Tag'||o.categoryName=='tag' || o.categoryName == this.translate.instant("Tag"))) ;
    if(tagSuggestion.length){
      let filteredAccountsWithinTagCategory: any = [];
     
      let results = selectedData.map(x=>x.selectedFacet);
      results.forEach(id=>{
        let thisContact = this.contactService.getContactByID(id);
        if(!_.isEmpty(thisContact)) {
          if(!_.isEmpty(filteredAccountsWithinTagCategory) && !filteredAccountsWithinTagCategory.some(acct=>acct.id == thisContact.ID)) {
            filteredAccountsWithinTagCategory.push(thisContact);
          }else {
            filteredAccountsWithinTagCategory.push(thisContact);
          }
        }
      });
      filteredContacts =  filteredAccountsWithinTagCategory;
    }

    this.searchedContactsToDisplay = filteredContacts;
    this.formatToDisplaySearchedContacts();
    const tagSuggestionIndex = selectedData.findIndex(data => data.categoryName === 'tag' || data.categoryName == this.translate.instant("Tag"));
    if (tagSuggestionIndex >= 0) {
      if (!this.showUserContactTag && !this.selectedTagData) {
        this.selectedTagData = this.contactService.contactTags.find(contactTag => contactTag.indskr_name === selectedData[tagSuggestionIndex].selectedFacet)
      }
      this.handleTagSelection(this.selectedTagData);
    }
    this.onSearchBlur(null);
  }

  private formatToDisplaySearchedContacts() {
    this.filterMetadata.count = this.searchedContactsToDisplay.length;
    if (this.searchRecordCount < 30) { this.searchRecordCount = 30; }
    this.slicedSearchedContactsToDisplay = this.sliceSearchedContacts(0, this.searchRecordCount);
    this.sortContactsListBasedOnSelection(this.sortBy);
    this._initAllSectionHeaderModel();
    this.checkEnableDisableSelectAllOption();
  }

  //Character search only on Name of the contact
  getOjectValuesEntityLevel(conObject): string {
    let objString: string[] = [];
    let contactInclude = ['fullname'];
    for (let contact in conObject) {
      if (contactInclude.indexOf(contact) > -1 && conObject[contact]) {
        if (Array.isArray(conObject[contact])) {
          for (let i = 0; i < conObject[contact].length; i++) {
            objString.push(this.getOjectValuesEntityLevel(conObject[contact][i]));
          }
        } else {
          objString.push(conObject[contact]);
        }
      }
    }
    return this.searchConfigService.convertFormattedString(objString.toString().toUpperCase());
  }

  getOjectValues(conObject): string {
    let objString: string[] = [];
    let propNotInclude = ['ID', 'activitesByContact', 'configuredFields', 'connectionState', 'repCallPlansByContact', 'title', 'titleFormattedValue', 'trackAction', 'trackActionFormattedValue'];
    for (let contact in conObject) {
      if (propNotInclude.indexOf(contact) == -1 && conObject[contact]) {
        if (Array.isArray(conObject[contact])) {
          for (let i = 0; i < conObject[contact].length; i++) {
            objString.push(this.getOjectValues(conObject[contact][i]));
          }
        } else {
          objString.push(conObject[contact]);
        }
      }
    }

    return this.searchConfigService.convertFormattedString(objString.toString().toUpperCase());

  }

  ionSearchFocus(ev) {
    try {
      let el = this.contactListElement.nativeElement.ownerDocument.getElementById('allContacts');
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.log("scroll error");
    }
  }

  async clickSearchArea(ev){
    ev.stopPropagation();
    if (this.uiService.contactDetailsSegment == "customer-assess" && this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) return;
    if(this.currentTab == ContactsModel.CHANGE_REQUESTS_TAB) {
      return;
    }
    else if(!this.suggestionsActive) {

      if(this.contactService.contactPageMode != this.compViewMode.READONLY){
        this.suggestionsActive = true;
      }
      //this.suggestionsActive = true;
      //this.suggestionsData = [];
      if(this.uiService.isConsentFromToolDrawer){
        if(!this.contactService.consentRecentSearches || !this.contactService.consentRecentSearches.length){
          let searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='Consents')
          if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
            this.suggestionsActive = false;
          }
        }
      }
      else{
        if (_.isEmpty(this.contactService.recentSearches) && _.isEmpty(this.contactService.contactTags) && _.isEmpty(this.listPeriodTags)) {
          let searchToolName = this.searchConfigService.toolNames.find(tool => tool.toolName == 'Contacts')
          if (searchToolName && (this.searchConfigService.savedSearches.filter(search => search.searchToolName == searchToolName.searchToolNameID)).length == 0) {
            this.suggestionsActive = false;
          }
        }
      }
      if (!this.suggestionsActive) this.suggestionsActive = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_ASSESS);
    }
    if (!_.isEmpty(this.contactService.contactTags)) {
      this.suggestionsActive = true;
    }
  }

  handleAssessmentSearchClick(value) {
    this.suggestionsActive = false;
    if(value && value.id) {
      this.selectedSuggestionsData = [];
      if(value.id == "assessed") {
        this.selectedSuggestionsData.push(this.getAssessmentPill());
      } else {
        this.selectedSuggestionsData.push(this.getUnAssessmentPill());
      }
      this.contactSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searchContactsList();
      this.sortContactsListBasedOnSelection(this.sortBy);
    }
  }

  clickedInSuggestionsArea(ev){
    ev.stopPropagation();
  }

  handleFacetSelection(data:SelectedSuggestionPillDataModel){
    this.disableSaveSearch = false;
    this.purgeGlobalSearchUserData();
    if(this.contactService.isInGlobalSearch){
      this.contactService.isInGlobalSearch = false;
      this.selectedGlobalSearchContact = null;
      this.initContactsHeaderLeft();
    }
    if (this.contactService.isOneKeySearching) {
      this.resetOneKeySearch();
    }
    console.log(data);
    this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(data, this.selectedSuggestionsData)
    // search should be applied with both
    if (this.showUserContactTag && this.selectedTagData) {
      this.selectedSuggestionsData.push(this.getTagSuggestionPill(this.selectedTagData))
    }
    this.suggestionsActive = false;
    this.contactSearchText = '';
    this.suggestionsData= [];
    this.shouldFireSearchChangeEvent = false;
    this.searchContactsList();
    this.selectedSuggestionsData.sort((a,b)=>{
      if(a.createdOn < b.createdOn) return 1
      else return -1
    })
    if(!data.isComingFromRecentSearch){
      let recentSearches;
      if(this.uiService.isConsentFromToolDrawer) recentSearches = this.contactService.consentRecentSearches
      else recentSearches = this.contactService.recentSearches
      let existingRecentSearchIndex = recentSearches.findIndex(recSearch=>recSearch.categoryName==data.categoryName && recSearch.selectedFacet==data.selectedFacet)
      if(existingRecentSearchIndex<0){
        recentSearches.unshift(data);
        _.remove(recentSearches, (o, index)=>index>2);
      }
      else{
        recentSearches.splice(existingRecentSearchIndex,1);
        recentSearches.unshift(data);
      }
      this.disk.updateOrInsert(this.uiService.isConsentFromToolDrawer?DB_KEY_PREFIXES.CONSENT_RECENT_SEARCHES:DB_KEY_PREFIXES.CONTACT_RECENT_SEARCHES,(doc)=>{
        if(!doc || !doc.raw){
          doc={
            raw:[]
          }
        }
        doc.raw = recentSearches;
        return doc;
      })
    }
    this._cd.detectChanges();
  }

  handleSavedSearchSelection(data){
    this.selectedSuggestionsData = [];
    if(data && data.categoryValuePairs){
      data.categoryValuePairs.forEach(catValPair=>{
        this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(catValPair, this.selectedSuggestionsData);
      })

      this.selectedSuggestionsData.sort((a,b)=>{
        if(a.createdOn < b.createdOn) return 1
        else return -1
      })
      //if(this.selectedSuggestionsData.length > 0) this.content.resize();
      // search should be applied with both
      if (this.showUserContactTag && this.selectedTagData) {
        this.selectedSuggestionsData.push(this.getTagSuggestionPill(this.selectedTagData))
      }
      this.suggestionsActive = false;
      this.contactSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searchContactsList();
    }
  }

  removeSelectedSuggestion(suggestion){
    this.disableSaveSearch = false;
    _.remove(this.selectedSuggestionsData,(o)=>_.isEqual(o,suggestion));
    if(suggestion.categoryName === 'tag') {
      this.showUserContactTag = false;
      this.cancelTagSelection();
    }
    if(this.selectedSuggestionsData.length == 0){
     // this.content.resize();
      this.searchedContactsToDisplay = [];
      this.slicedSearchedContactsToDisplay = [];
      this.selectedContactsFromTag = [];
      this.showAndRemoveTagContacts = false;
      this.contactSearchText = '';
      // this.shouldFireSearchChangeEvent = false;
      this.searching = false;
      this.purgeGlobalSearchUserData();
      if(this.contactService.isInGlobalSearch){
        this.contactService.isInGlobalSearch = false;
        this.selectedGlobalSearchContact = null;
        this.initContactsHeaderLeft();
      }
      if (this.contactService.isOneKeySearching) {
        this.resetOneKeySearch();
      }
      this.sortContactsListBasedOnSelection(this.sortBy);
      this.initContactsHeaderLeft();
      this.checkEnableDisableSelectAllOption();
      if(this.selectedFilterData?.length > 0) {
        this.searchContactsList(true);
        this.searchConfigService.isTargetContactsPillRemoved = false;
      }
    } else {
      let flag:boolean = this.selectedFilterData?.length > 0 ? true : false;
      this.searchContactsList(flag);
      this.searchConfigService.isTargetContactsPillRemoved = false;
    }
    
  }

  removeSelectedFilter(suggestion, filter){
    let selectedSuggestions = this.filterNameBubble.find((bubble) => bubble == filter).suggestion;
    _.remove(this.selectedFilterData,(o)=>_.isEqual(o,suggestion));
    _.remove(this.filterNameBubble.find((bubble) => bubble == filter).suggestion,(o)=>_.isEqual(o,suggestion));
    if(_.isEmpty(selectedSuggestions)) {
      let index = this.filterNameBubble.findIndex((b) => b.categoryName == filter.categoryName);
      if(index>-1) this.filterNameBubble.splice(index,1);
    }
    if(this.currentTab == ContactsModel.CHANGE_REQUESTS_TAB) {
      if(this.selectedFilterData.length == 0){
        this.filtering = false;
        this.filteredContactCRs = this.contactCRs;
        this.isFilteredContactCRs = false;
        this._initAllSectionHeaderModel();
      } else {
        let selectedValue:{ status: any[], requestType: any[], resourceType: any[]} = {status:[], requestType:[], resourceType:[]};
        this.selectedFilterData.forEach((selected) => {
          if(selected.categoryPath == 'contactCR_status') selectedValue['status'].push(selected.value);
          else if(selected.categoryPath == 'contactCR_request_type') selectedValue['requestType'].push(selected.value);
          else if(selected.categoryPath == 'contactCR_source_type') selectedValue['resourceType'].push(selected.value);
        });
        this.filterContactCR(selectedValue);
      }
    } else {
      if(this.selectedFilterData.length == 0 && this.selectedSuggestionsData?.length == 0){
        // this.content.resize();
         this.searchedContactsToDisplay = [];
         this.slicedSearchedContactsToDisplay = [];
         this.selectedContactsFromTag = [];
         this.showAndRemoveTagContacts = false;
         this.contactSearchText = '';
        //  this.shouldFireSearchChangeEvent = false;
         this.searching = false;
         this.filtering = false;
         this.selectedDateFromFilter = {startDate: '', endDate: ''};
         this.purgeGlobalSearchUserData();
         if(this.contactService.isInGlobalSearch){
           this.contactService.isInGlobalSearch = false;
           this.selectedGlobalSearchContact = null;
           this.initContactsHeaderLeft();
         }
         if (this.contactService.isOneKeySearching) {
           this.resetOneKeySearch();
         }
         this.sortContactsListBasedOnSelection(this.sortBy);
         this.initContactsHeaderLeft();
         this.checkEnableDisableSelectAllOption();
       }
       else if(this.selectedFilterData.length==0) {
        this.filtering = false;
        this.searchContactsList();
       } else {
        //Update sub categories - My Activites, Update filter pre-selection in timeline
        const myActivitiesAndSubCategoriesList = ['myActivitiesByContact', 'filterMeetingsStatus', 'filterMessagesStatus', 'filterMessagesAttachment', 'filterMessagesLinkAccessed'];
        if(myActivitiesAndSubCategoriesList.includes(suggestion.categoryPath)) {
          if(suggestion.categoryPath == "myActivitiesByContact" && !_.isEmpty(this.contactService.selectedActivitiesFromContactFilter)) {
            const convertedValue = this.contactService.convertOptionValueToActivityType(suggestion.selectedFacet);
            const targetIdx = this.contactService.selectedActivitiesFromContactFilter.findIndex(d=> d == convertedValue);
            if(targetIdx > -1) this.contactService.selectedActivitiesFromContactFilter.splice(targetIdx, 1);
          }else if(suggestion.categoryPath == "filterMeetingsStatus" && !_.isEmpty(this.contactService.selectedSubOptionsFromContactFilter.meetingStatus)) {
            let selectedOption: number = -1;
            if(suggestion.selectedFacet == this.translate.instant("COMPLETED")) {
              selectedOption = 3;
            }else if(suggestion.selectedFacet == this.translate.instant("OPEN")) {
              selectedOption = 1;
            }
            const targetIdx = this.contactService.selectedSubOptionsFromContactFilter.meetingStatus.findIndex(d=> d == selectedOption);
            if(targetIdx > -1) this.contactService.selectedSubOptionsFromContactFilter.meetingStatus.splice(targetIdx, 1);
          }
          this._updateSubCategories(suggestion);
        }
        //Update sub categories - Assessment
        if (filter && (filter.categoryName == 'Assessments' || filter.categoryName == this.translate.instant('ASSESSMENTS')) && suggestion && suggestion.categoryPath == 'Assessed') {
          const isRemovedAssessCategory: boolean = !this.selectedFilterData.some(d=>d.categoryPath == 'Assessed');
          if (isRemovedAssessCategory && !_.isEmpty(this.selectedFilterData)) {
            this.selectedFilterData.forEach(d => {
              if (d.configuredFrom == 'Assess') {
                const targetCategoryPath = d.categoryPath;
                this._clearSubCategory(targetCategoryPath);
              }
            });
          }
        }
        this.searchContactsList(true);
       }
    }
  }

  removeAllSelectedSuggestions(){
    this.disableSaveSearch = true;
    this.selectedSuggestionsData = [];
    //this.content.resize();
    this.searchedContactsToDisplay = [];
    this.slicedSearchedContactsToDisplay = []
    this.contactSearchText = '';
    // this.shouldFireSearchChangeEvent = false;
    this.searching = false;
    this.selectedTagData = null;
    this.selectedContactsFromTag = [];
    this.showAndRemoveTagContacts = false;
    this.purgeGlobalSearchUserData();
    if(this.contactService.isInGlobalSearch){
      this.contactService.isInGlobalSearch = false;
      this.selectedGlobalSearchContact = null;
      this.initContactsHeaderLeft();
    }
    if (this.contactService.isOneKeySearching) {
      this.resetOneKeySearch();
    }
    this.showUserContactTag = false;
    this.cancelTagSelection();
    this.sortContactsListBasedOnSelection(this.sortBy);
    this.onSearchBlur(null);
  }

  mapAllocationsToContactsSearchConfig(){
    this.sampleService.contactCustomerSampleAllocations.forEach(csa =>{
      let thisContact = this.nonGuestContacts.find(cont=>cont.ID==csa.contactId);
      if(thisContact){
        let thisContactAllocations:ContactAllocation[] = csa.currentCustomerSampleAllocations.map(alloc=>{
          let allocModel:ContactAllocation = {
            contactId: alloc.contactId,
            contactName: alloc.contactName,
            sampleSKUId: alloc.sampleSKUId,
            sampleSKUName: alloc.sampleSKUName
          }
          if(alloc.sampleSKUName && !this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryName == 'Allocations').values.some(o=>o == alloc.sampleSKUName)){
            this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryName == 'Allocations').values.push(alloc.sampleSKUName)
          }
          return allocModel
        });
        this.contactService.getContactByID(csa.contactId)?this.contactService.getContactByID(csa.contactId).contactAllocations.push(...thisContactAllocations):'';
      }
    })
    this.searchConfigService.isAllocationsMappedToContacts = true;
  }
  mapConsentsToContactsSearchConfig(){
    this.consentService.allActiveConsentSubject.subscribe((consents: ContactConsent[]) => {
      if (consents && !_.isEmpty(consents)) {
      consents.forEach(consent=>{
        let thisContact = this.nonGuestContacts.find(cont=>cont.ID==consent.indskr_contactid);
        if(thisContact && consent.activeConsents.length>0) thisContact.hasActiveConsent = this.translate.instant('YES');
        thisContact && consent.activeConsents.forEach((consentTerm)=>{
          if(consentTerm.createdon && thisContact){
            if(!thisContact.lastConsentedDate || consentTerm.createdon > thisContact.lastConsentedDate){
              thisContact.lastConsentedDate = consentTerm.createdon
            }
            if(!thisContact.consentExpiryDate || consentTerm.indskr_untildate < thisContact.consentExpiryDate){
              thisContact.consentExpiryDate = consentTerm.indskr_untildate
            }
          }
          if (consentTerm.products) {
            consentTerm.products.forEach(consentProduct=>{
              if(consentProduct.indskr_productname && !this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryName == 'Consent Products').values.some(o=>o == consentProduct.indskr_productname)){
                this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryName == 'Consent Products').values.push(consentProduct.indskr_productname.toString())
              }
            })
          }
          if(consentTerm.indskr_consentTermName && !this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryName == 'Consent Terms').values.some(o=>o == consentTerm.indskr_consentTermName)){
            this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryName == 'Consent Terms').values.push(consentTerm.indskr_consentTermName.toString())
          }
        })
      })
     }
    })
    // this.consentService.allConsentChannelSubject.value.forEach(consentChannel=>{
    //     if(consentChannel.indskr_consentType && !this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryName == 'Consent Channels').values.some(o=>o == consentChannel.indskr_consentType)){
    //       this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryName == 'Consent Channels').values.push(consentChannel.indskr_consentType.toString())
    //     }
    // })
    let activeConsentsConfig = this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'hasActiveConsent');
    if(activeConsentsConfig) {
      activeConsentsConfig.values = [this.translate.instant('YES'), this.translate.instant('NO')];
    }
    this.searchConfigService.isConsentsMappedToContacts = true;
  }

  async mapChildUsersWithPositionToContactsSearchConfig(){
    if (this.device.isOffline) return;

    if(_.isEmpty(this.searchConfigDataService.childUsersWithPositions)) {
      this.searchConfigDataService.childUsersWithPositions = await this.contactDataService.getAllChildUsersBasedOnPositionId(this.authenticationOfflineService.user.positionID);
    }

    if(!_.isEmpty(this.searchConfigDataService.childUsersWithPositions)) {
      this.searchConfigDataService.childUsersWithPositions.forEach(childUser=>{
        if(childUser.userfullname && !this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryName == 'Teamusers').mappingValues.some(o=>o.formattedValue == childUser.userfullname)){
          this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryName == 'Teamusers').mappingValues.push({
           actualValue: childUser.childpositionid.toString(),
           formattedValue: childUser.userfullname.toString()
          });
        }
      });

      this.searchConfigDataService.childUsersWithPositions = this.searchConfigDataService.childUsersWithPositions.map(childUser=>{
        childUser = _.pick(childUser,['childpositionid','userfullname']);

        childUser.id = `filter_menu_Teamusers_${childUser['userfullname']}`,
        childUser.label = `${childUser['userfullname']}`,
        childUser.value = `${childUser['childpositionid']}`,
        childUser.isChecked = false;
        childUser.category = 'Teamusers';
        childUser.isExpanded = false;
        childUser.wasCalled = false;
        childUser.childUsers = [];
        childUser.depth = 0;
        return childUser;
      });
    }
  }

  mapSAPActivitiesToContactsSearchConfig(){
    this.sapService.sapActivities.map(sap=>{
      sap.contacts.forEach(sapContact=>{
        let thisContact = this.nonGuestContacts.find(cont=>cont.ID == sapContact.ID);
        if(thisContact && this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryName == 'Scientific Plans')){
          if(sap.scientificActivityPlanName && !this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryName == 'Scientific Plans').values.some(o=>o == sap.scientificActivityPlanName)){
            this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryName == 'Scientific Plans').values.push(sap.scientificActivityPlanName)
          }
        }
      })
    })
    this.searchConfigService.isSAPActivitiesMappedToContacts = true;
  }

  mapActivitiesToContactsSearchConfig() {
    //reset the selected Activities which is to track the selected Activities for use in the Timeline filter
    this.contactService.selectedActivitiesFromContactFilter = [];
    this.contactService.selectedSubOptionsFromContactFilter = { meetingStatus: [] };
    if(!_.isEmpty(this.activityService.activities)) {
      //Meeting
      const meetingActivities = this.activityService.activities.filter(activity=>activity instanceof AppointmentActivity && activity.type == ActivityType.Appointment && activity.location != 'LiveMeet') as AppointmentActivity[];
      if(!_.isEmpty(meetingActivities)) {
        let hasContactId: boolean = false;
        hasContactId = meetingActivities.some(activity=>{
          return !_.isEmpty(activity.contacts);
        });
        if(hasContactId) {
          const targetValue: string = this.translate.instant("MEETINGS");
          if(!this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'myActivitiesByContact').values.some(o=>o == targetValue)) {
            this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'myActivitiesByContact').values.push(targetValue);
          }
          //added default sub criteria
          //meetings-status
          const statusClosed: string = this.translate.instant("COMPLETED");
          const statusOpen: string = this.translate.instant("OPEN");
          let idx = this.searchConfigService.contactConfiguredSearchIndexConfig.findIndex(config=> config.categoryRelativePath == 'filterMeetingsStatus');
          if(idx>-1) {
            this.searchConfigService.contactConfiguredSearchIndexConfig[idx].values = [statusClosed, statusOpen];
          }else {
            const newObj = {
              categoryName: 'Meetings_Status',
              translationKey: 'FILTER_MEETINGS_STATUS',
              categoryRelativePath:'filterMeetingsStatus',
              controlDataType: 'DefaultConfig',
              values:[statusClosed, statusOpen],
              mappingValues: [],
              isFixedFilter: true,
              filterOrder: '2',
              isForcedHide: true,
              isSubCategory: true,
            }
            this.searchConfigService.contactConfiguredSearchIndexConfig.push(newObj);
          }
        }
      }
      //Live meeting
      const liveMeetingActivities = this.activityService.activities.filter(activity=>activity instanceof AppointmentActivity && activity.type == ActivityType.Appointment && activity.location == 'LiveMeet') as AppointmentActivity[];
      if(!_.isEmpty(liveMeetingActivities)) {
        let hasContactId: boolean = false;
        hasContactId = liveMeetingActivities.some(activity=>{
          return !_.isEmpty(activity.contacts);
        });
        if(hasContactId) {
          const targetValue: string = this.translate.instant("LIVE_MEETING");
          if(!this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'myActivitiesByContact').values.some(o=>o == targetValue)) {
            this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'myActivitiesByContact').values.push(targetValue);
          }
        }
      }
      //Phone Calls
      const phoneCallActivities = this.activityService.activities.filter(activity=>activity instanceof PhoneActivity && activity.type == ActivityType.PhoneCall && (activity.state == 1 || activity.statusString == 'Completed')) as PhoneActivity[];
      if(!_.isEmpty(phoneCallActivities)) {
        let hasContactId: boolean = false;
        hasContactId = phoneCallActivities.some(activity=>{
          return !_.isEmpty(activity.raw_contactAttendees);
        });
        if(hasContactId) {
          const targetValue: string = this.translate.instant("PHONE_CALLS");
          if(!this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'myActivitiesByContact').values.some(o=>o == targetValue)) {
            this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'myActivitiesByContact').values.push(targetValue);
          }
        }
      }
      //Email
      const emailActivities = this.activityService.activities.filter(activity=>activity instanceof EmailActivity && activity.type == ActivityType.Email && (activity.emailStatus == 3 || activity.emailStatus == 548910000)) as EmailActivity[];
      if(!_.isEmpty(emailActivities)) {
        let hasContactId: boolean = false;
        hasContactId = emailActivities.some(ea=>{
          return !_.isEmpty(ea.emailActivityParties) && ea.emailActivityParties.some(p=>p.indskr_contactid != '');
        });
        if(hasContactId) {
          const targetValue: string = this.translate.instant("MESSAGES");
          if(!this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'myActivitiesByContact').values.some(o=>o == targetValue)) {
            this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'myActivitiesByContact').values.push(targetValue);
          }
          //added default sub criteria
          //messages-status
          const statusDelivered: string = this.translate.instant("DELIVERED");
          const statusRead: string = this.translate.instant("READ");
          const statusIdx = this.searchConfigService.contactConfiguredSearchIndexConfig.findIndex(config=> config.categoryRelativePath == 'filterMessagesStatus');
          if(statusIdx>-1) {
            this.searchConfigService.contactConfiguredSearchIndexConfig[statusIdx].values = [statusDelivered, statusRead];
          }else {
            const newObj = {
              categoryName: 'Messages_Status',
              translationKey: 'FILTER_MESSAGES_STATUS',
              categoryRelativePath:'filterMessagesStatus',
              controlDataType: 'DefaultConfig',
              values:[statusDelivered, statusRead],
              mappingValues: [],
              isFixedFilter: true,
              filterOrder: '3',
              isForcedHide: true,
              isSubCategory: true,
            }
            this.searchConfigService.contactConfiguredSearchIndexConfig.push(newObj);
          }
          //messages-attachment
          const attachmentNo: string = this.translate.instant("NO");
          const attachmentYes: string = this.translate.instant("YES");
          const attachmentIdx = this.searchConfigService.contactConfiguredSearchIndexConfig.findIndex(config=> config.categoryRelativePath == 'filterMessagesAttachment');
          if(attachmentIdx>-1) {
            this.searchConfigService.contactConfiguredSearchIndexConfig[attachmentIdx].values = [attachmentNo, attachmentYes];
          }else {
            const newObj = {
              categoryName: 'Messages_Attachment',
              translationKey: 'FILTER_MESSAGES_CONTENTS',
              categoryRelativePath:'filterMessagesAttachment',
              controlDataType: 'DefaultConfig',
              values:[attachmentNo, attachmentYes],
              mappingValues: [],
              isFixedFilter: true,
              filterOrder: '4',
              isForcedHide: true,
              isSubCategory: true,
            }
            this.searchConfigService.contactConfiguredSearchIndexConfig.push(newObj);
          }
          //messages-link accessed
          const linkAccessedNo: string = this.translate.instant("NO");
          const linkAccessedYes: string = this.translate.instant("YES");
          const linkAccessedIdx = this.searchConfigService.contactConfiguredSearchIndexConfig.findIndex(config=> config.categoryRelativePath == 'filterMessagesLinkAccessed');
          if(linkAccessedIdx>-1) {
            this.searchConfigService.contactConfiguredSearchIndexConfig[linkAccessedIdx].values = [linkAccessedNo, linkAccessedYes];
          }else {
            const newObj = {
              categoryName: 'Messages_Link_Accessed',
              translationKey: 'FILTER_MESSAGES_CONTENTS_ACCESSED',
              categoryRelativePath:'filterMessagesLinkAccessed',
              controlDataType: 'DefaultConfig',
              values:[linkAccessedNo, linkAccessedYes],
              mappingValues: [],
              isFixedFilter: true,
              filterOrder: '5',
              isForcedHide: true,
              isSubCategory: true,
            }
            this.searchConfigService.contactConfiguredSearchIndexConfig.push(newObj);
          }
        }
      }
      //Allocation order
      const samepleActivities = this.activityService.activities.filter(activity=>activity instanceof SampleActivity && activity.type == ActivityType.Sample && (activity.state == 1 || activity.statusString == 'Completed')) as SampleActivity[];
      if(!_.isEmpty(samepleActivities)) {
        let hasContactId: boolean = false;
        hasContactId = samepleActivities.some(activity=>{
          return !_.isEmpty(activity.contactID);
        });
        if(hasContactId) {
          const targetValue: string = this.translate.instant("ALLOCATION_ORDER");
          if(!this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'myActivitiesByContact').values.some(o=>o == targetValue)) {
            this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'myActivitiesByContact').values.push(targetValue);
          }
        }
      }
      //Customer Inquiries
      const caseIntakeActivities = this.activityService.activities.filter(activity=>activity instanceof CaseActivity && activity.type == ActivityType.CaseIntake) as CaseActivity[];
      if(!_.isEmpty(caseIntakeActivities)) {
        let hasContactId: boolean = false;
        hasContactId = caseIntakeActivities.some(activity=>{
          return !_.isEmpty(activity.raw) && !_.isEmpty(activity.raw['customerid_value']);
        });
        if(hasContactId) {
          const targetValue: string = this.translate.instant('CUSTOMER_INQUIRIES', { globalCustomerText: this.utilityService.globalCustomerText });
          if(!this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'myActivitiesByContact').values.some(o=>o == targetValue)) {
            this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'myActivitiesByContact').values.push(targetValue);
          }
        }
      }
      //Events
      const eventActivities: Array<EventActivity> = this.eventsToolService.eventsToolData;
      if(!_.isEmpty(eventActivities)) {
        let hasContactId: boolean = false;
        hasContactId = eventActivities.some(activity=>{
          return !_.isEmpty(activity.participants);
        });
        if(hasContactId) {
          const targetValue: string = this.translate.instant('EVENTS');
          if(!this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'myActivitiesByContact').values.some(o=>o == targetValue)) {
            this.searchConfigService.contactConfiguredSearchIndexConfig.find(config=> config.categoryRelativePath == 'myActivitiesByContact').values.push(targetValue);
          }
        }
      }
    }
  }

  async saveAdvancedSearch(failedText?: string){
    let saveSearchName: string
    let toolName;
    if(this.uiService.isConsentFromToolDrawer) toolName = this.searchConfigService.toolNames.find(o=>o.toolName=='Consents')
    else toolName = this.searchConfigService.toolNames.find(o=>o.toolName=='Contacts')
    let currentSavedSearches = this.searchConfigService.savedSearches.filter(o=> o.searchToolName==toolName.searchToolNameID);
    this.alertService.showAlert({ title:this.translate.instant('SAVE_SEARCH'),
                                  subTitle:failedText?failedText:'',
                                  cssClass:'saveSearchAlert',
                                  message:this.translate.instant('SAVE_SEARCH_POPOVER_MESSAGE'),
                                  inputs:[{type:'text', name:"name", placeholder:this.translate.instant('ENTER_NAME')}]
                                }, this.translate.instant('SAVE'), this.translate.instant('CANCEL'))
    .then(async (res)=>{
      if(res.role == 'ok'){
        if(res.data && res.data.values.name){
          saveSearchName = res.data.values.name.trim();
          let sameNameSearches = currentSavedSearches.filter(o=>o.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase())==0);
          let incrementNumber: number = 1;
          if(sameNameSearches.length){
            saveSearchName += ' (';
            _.each(sameNameSearches,(searchData=>{
              if(searchData.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase()) == 0){
                let currentIncrement = parseInt(searchData.searchName.toLowerCase().charAt(saveSearchName.length));
                if(!isNaN(currentIncrement) && currentIncrement>=incrementNumber) incrementNumber = currentIncrement+1;
              }
            }))
            saveSearchName+= incrementNumber+')';
          }
          this.disableSaveSearch = true;
          await this.searchConfigDataService.saveAdvancedSearch(saveSearchName,
                                                                toolName?toolName.searchToolNameID:'',
                                                                UserSavedSearchTypes.OWNED,
                                                                this.selectedSuggestionsData.slice())
        }
        else if(res.data && res.data.values.name == ""){
          this.saveAdvancedSearch(this.translate.instant('NAME_IS_REQUIRED'));
        }
      }
    })
  }

  getTertiaryInfo(contact: Contact) {
    let str: string = '';
    if (this.sortBy) {
      switch (this.sortBy.value) {
        case 'modifiedOn':
          str = contact.modifiedOn ? this.getFormattedAndLocalisedDate(new Date(contact.modifiedOn)) : ''
          break;
        case 'lastConsentedDate':
          str = contact.lastConsentedDate ? this.getFormattedAndLocalisedDate(new Date(contact.lastConsentedDate)) : this.translate.instant('NO_CONSENT');
          break;
        case 'consentExpiryDate':
          if(typeof(contact.consentExpiryDate)=='string'){
            contact.consentExpiryDate = parseInt(contact.consentExpiryDate);
          }
          str = contact.consentExpiryDate != 4000000000000 ? this.getFormattedAndLocalisedDate(new Date(contact.consentExpiryDate)) : this.translate.instant('NO_CONSENT');
          break;
        case 'interactionDate':
          str = contact.interactionDate ? this.getFormattedAndLocalisedDate(contact.interactionDate) + ' ' + contact.interactionType : this.translate.instant('NO_INTERACTION');
          break;
        case 'createdOn':
          str = (contact.interactionDate && contact.interactionDate > 0) ? this.getDateDifference(contact.interactionDate) : this.translate.instant('NO_INTERACTION');
      }
    }
    return str;
  }

  /**
   * to calculate no of days between today and @param interactionDate
   * @param interactionDate
   */
  getDateDifference(interactionDate: number): string {
    if (interactionDate) {
      let dateDiff = new Date().getTime() - interactionDate;
      const day = 1000 * 60 * 60 * 24;
      let numberOfDays = Math.ceil(dateDiff / day);
      return (numberOfDays > 1) ? 'No. of days - ' + numberOfDays.toString() : "Today";
    }
    return "";
  }

  get showTertiary(): boolean {
    if (this.sortBy && (this.sortBy.value == 'modifiedOn' || this.sortBy.value == 'lastConsentedDate' || this.sortBy.value == 'consentExpiryDate'
      || this.sortBy.value == 'interactionDate' || this.sortBy.value == 'createdOn')) {
      return true;
    }
    return false;
  }

  // end advance search functions

  async addNewContact() {
    // this.addNewContactHandler.emit();
    if (this.uiService.contactDetailsSegment == "customer-assess" && this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) return;
    if(this.contactService.scientInfoModalOpen){
      this.events.publish('closeScientInfoModal');
    }
    this.dynamicFormsService.okLookUpMapper = [];
    this.events.unsubscribe("contacts:customForm");
    this.events.subscribe("contacts:customForm", this.contactCreateHandler);
    this.contactService.tempContactInformation = this.contactService.contactInformation;
    if (this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent) {
      const event = {
        value : ChangeRequestType.BUSINESS,
        isMDMType: false,
      }
      this.events.publish("contacts:customForm", event);
    }
    else {
      if(this.businessCreateChangeRequest && this.businessCreateRequest){
        this.notificationService.notify(this.translate.instant('INVALID_CONFIGURATION_FORMS_ERR'), 'ContactList', "top", ToastStyle.DANGER, 3000);
      }else if ((this.businessCreateRequest || this.businessCreateChangeRequest) && this.contactsCreateRequest) {
        this.uiService.activeView = 'ContactPageRightPaneNav';
        this.uiService.showNewActivity = false;
        this.navService.setChildNavRightPaneView(true);
        await this.navService.pushChildNavPageWithPageTracking(NewChangeRequestComponent, PageName.NewChangeRequestComponent, PageName.ContactPageComponent, {
          from: 'Contact'
        });
      } else if(this.businessCreateRequest || this.businessCreateChangeRequest) {
        let event;
        if(this.businessCreateChangeRequest){
          event = {
            value : this.mdmService.sourceTypeOptionSet[0] ? this.mdmService.sourceTypeOptionSet[0].value : ChangeRequestType.BUSINESS,
            label: this.mdmService.sourceTypeOptionSet[0] ? this.mdmService.sourceTypeOptionSet[0].label : 'BUSINESS',
            isMDMType: false,
          }
        }
        await this.defaultBusinessContactDynamicForm(event);
      } else if(this.contactsCreateRequest) {
        await this.oneKeyContactCreateForm();
      } else {
        const event = {
          value : ChangeRequestType.BUSINESS,
          isMDMType: false,
        }
        this.events.publish("contacts:customForm", event);
      }
    }
  }

  private getFormattedAndLocalisedDate(value: any) {
    return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  public async defaultBusinessContactDynamicForm(event = null) {
    if(this.businessCreateChangeRequest){
      this._handleBusinessContactChangeRequests(event);
    }else{
      let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("contact",FormType.CREATEFORM);
      let formType = DynamicFormType.CONFIGUREDFORM;
      if(!contactForm){
        contactForm = new DynamicForm(DEFAULT_CONTACT_CREATE_FORM['value'][0]);
        formType = DynamicFormType.DEFAULTFORM;
      }
      this.contactService.contactInformation = null;
      let formDetails: DynamicFormComponentModel = {
        title: (this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent)? "Participant":this.globalCustomerText,
        LookupEntitySetName: 'contacts',
        showLeftHeaderButton: true,
        leftHeaderBtnIcon: 'chevron-back-outline',
        showRightHeaderButton: true,
        rightHeaderImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        formMetadata: contactForm,
        formEntityObject: null,
        mainEntityObjectId: null,
        dynamicFormType: formType,
        navOptions: { animate: false },
        eventsHandler: (data:any, eventTarget:string, refData:DynamicFormComponentModel)=> this._handleContactFormComponentEvent(data,eventTarget,refData),
      };

      this.uiService.activeView = 'ContactPageRightPaneNav';
      this.uiService.showNewActivity = false;
      this.navService.setChildNavRightPaneView(true);
      this.trackingService.tracking('CreateSelected', TrackingEventNames.CONTACT_CREATE, true);
      this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent, PageName.DynamicFormComponent, PageName.ContactPageComponent, {
        MODE:'CREATE_MODE',
        FORM_DETAIL: formDetails,
      });
      this.footerService.initButtons(FooterViews.Contacts);
    }
  }

  private async _handleBusinessContactChangeRequests(event){
    let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_contactcr", FormType.CREATEFORM, BusinessProcessType.Omnipresence);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if(!contactForm){
     return;
    }
    const contactRaw = {
      indskr_contactsourcetype: event ? event.value : BusinessProcessType.Omnipresence,
      'indskr_contactsourcetype@OData.Community.Display.V1.FormattedValue': event ? event.label : 'OMNIPRESENCE',
      indskr_mdmrequesttype: MDMRequestType.CREATE,
    }
    this.contactService.contactInformation = null;
    this.mdmService.addLinkEntities([]);
    for(let i = 0; i < contactForm.metadata.length; i++) {
      const tab = contactForm.metadata[i];
      if(tab && tab.controls && tab.controls.length > 0) {
        for(let j = 0; j < tab.controls.length; j++) {
          let control = tab.controls[j];
          if(control.attributeName && (control.attributeName == "indskr_mdm" || control.attributeName == 'indskr_mdmid')) {
            control.forceHide = true;
          }
        }
      }
    }
    let formDetails: DynamicFormComponentModel = {
      title: this.globalCustomerText,
      LookupEntitySetName: 'indskr_contactcrs',
      showLeftHeaderButton: true,
      leftHeaderBtnIcon: 'chevron-back-outline',
      showRightHeaderButton: true,
      rightHeaderImgSrc: 'assets/imgs/header_save.svg',
      rightHeaderBtnText: this.translate.instant('SAVE'),
      formMetadata: contactForm,
      formEntityObject: contactRaw,
      mainEntityObjectId: null,
      dynamicFormType: formType,
      navOptions: { animate: false },
      eventsHandler: (data:any, eventTarget:string, refData:DynamicFormComponentModel)=> this._handleDataCRFormComponentEvent(data,eventTarget,refData),
    };

    this.uiService.activeView = 'ContactPageRightPaneNav';
    this.uiService.showNewActivity = false;
    this.navService.setChildNavRightPaneView(true);
    await this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent, PageName.DynamicFormComponent, PageName.ContactPageComponent, {
      MODE: 'CREATE_MODE',
      FORM_DETAIL: formDetails,
      TYPE: "OMNIPRESENCE"
    });
    this.footerService.initButtons(FooterViews.Contacts);
  }

  public async oneKeyContactCreateForm() {
    let contactForm: DynamicForm = null;
    let sanofiChinaForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_contactcr", FormType.CREATEFORM, BusinessProcessType.SanofiChina);
    let oneKeyForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_contactcr", FormType.CREATEFORM, BusinessProcessType.OneKey);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if(!oneKeyForm && !sanofiChinaForm){
     this.notificationService.notify(this.translate.instant('ONE_KEY_CREATE_FORM_ERROR'), 'DynamicFormComponent', "top", ToastStyle.DANGER, 3000);
     return;
    }
    if(oneKeyForm && sanofiChinaForm){
      this.notificationService.notify(this.translate.instant('MORE_THAN_ONE_FORM_CONFIGURED_ERR'), 'DynamicFormComponent', "top", ToastStyle.DANGER, 3000);
      return;
    }
    contactForm = oneKeyForm ? oneKeyForm : sanofiChinaForm;
    const contactRaw = {
      indskr_mdmid: "",
      indskr_lastname: "",
      indskr_contactsourcetype: 548910001,
      indskr_mdm: contactForm.raw && contactForm.raw['indskr_businessprocess'] == BusinessProcessType.SanofiChina ? 600000000 : 548910000,
      indskr_mdmrequesttype: MDMRequestType.CREATE
    }
    this.contactService.contactInformation = null;
    this.mdmService.addLinkEntities([]);
    let titleText = this.globalCustomerText;
    if(this.translate.currentLang == 'it') {
      titleText = this.translate.instant("ONEKEY_CONTACT_CREATE_TITLE");
    }
    let formDetails: DynamicFormComponentModel = {
      title: titleText,
      LookupEntitySetName: 'indskr_contactcrs',
      showLeftHeaderButton: true,
      leftHeaderBtnIcon: 'chevron-back-outline',
      showRightHeaderButton: true,
      rightHeaderImgSrc: 'assets/imgs/header_save.svg',
      rightHeaderBtnText: this.translate.instant('SAVE'),
      formMetadata: contactForm,
      formEntityObject: contactRaw,
      mainEntityObjectId: null,
      dynamicFormType: formType,
      navOptions: { animate: false },
      eventsHandler: (data:any, eventTarget:string, refData:DynamicFormComponentModel)=> this._handleContactCRFormComponentEvent(data,eventTarget,refData),
    };

    this.uiService.activeView = 'ContactPageRightPaneNav';
    this.uiService.showNewActivity = false;
    this.navService.setChildNavRightPaneView(true);
    await this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent, PageName.DynamicFormComponent, PageName.ContactPageComponent, {
      MODE: 'CREATE_MODE',
      FORM_DETAIL: formDetails,
      TYPE: "ONE_KEY"
    });
    this.footerService.initButtons(FooterViews.Contacts);
  }

  private async _handleContactFormComponentEvent(data, eventTarget, refData:DynamicFormComponentModel){
    if(eventTarget) {
      if(eventTarget == 'DONEBUTTONCLICK') {
        if(data && data.formValue) {

          this.trackingService.tracking('SaveSelected', TrackingEventNames.CONTACT_CREATE, true);
          let loader = await this.loadingCtrl.create();
          loader.present();

          if (this.linkEntitiesReqPayload && this.linkEntitiesReqPayload.length > 0) {
            const accountContactAffReqPayload = [];
            for (let reqPayload of this.linkEntitiesReqPayload) {
              await this.dynamicFormsService.createLinkedEntity(reqPayload.values, reqPayload.entity);
              if (reqPayload.entity === 'indskr_accountcontactaffiliations') {
                accountContactAffReqPayload.push({
                  accountId: reqPayload.values['lookupfields']?.find(req => req['entity'] === 'accounts').id,
                  accountName: null,
                  contactId: reqPayload.values['lookupfields']?.find(req => req['entity'] === 'contacts').id,
                  indskr_accounttype: null
                });
              }
            }
            this.events.publish("marketScan:newAccountContactAff", accountContactAffReqPayload);

            this.linkEntitiesReqPayload = [];
          }

          ///indskr_iseventparticipant
          if (!this.contactService.isInGlobalSearch && this.enableEventCreateContact && this.restrictedSelectedContactsViewActive && !this.uiService.isConsentFromToolDrawer) {
            let eventId = this.contactService.eventsId
            let cfvObj = {
              name : "msevtmgt_originatingeventid",
              entity : "msevtmgt_events",
              id : eventId
            }
            if(data.formValue.hasOwnProperty('lookupfields')){
              data.formValue['lookupfields'].push(cfvObj);
            }else{
              data.formValue['lookupfields'] = [cfvObj];
            }
            data.formValue['indskr_iseventparticipant'] = true;
          }
          await this.contactDataService.createDynamicContact(data.formValue).then(async (res) => {
              if(res) {
                console.log(res);

                if(res && res['contactid']) {
                  //if(!this.contactService.tempContactInformation)
                  this.contactService.tempContactInformation = new Contact({contactid: res['contactid'], firstname: data.formValue['firstname']});
                  this.contactService.tempContactInformation.ID = res['contactid'];
                  this.contactService.tempContactInformation.firstName = data.formValue['firstname'];
                  this.contactService.tempContactInformation.lastName = data.formValue['lastname']
                  this.contactService.tempContactInformation.fullname = this.contactService.tempContactInformation.firstName + " " + this.contactService.tempContactInformation.lastName;
                  this.contactService.tempContactInformation.isActive = true;
                  let eventId = this.contactService.eventsId
                  this.contactService.tempContactInformation.msevtmgt_eventid = eventId;

                  if(data && data.eventsHandler) {
                    let rawDetails = await this.contactDataService.getContactDetails(this.contactService.tempContactInformation, false);
                    if(rawDetails) {
                      this.contactService.tempContactInformation = rawDetails;
                    }
                    let index = this.newContacts.findIndex(x => x.ID == this.contactService.tempContactInformation.ID)
                    let tempC: Contact =  new Contact(this.contactService.tempContactInformation.raw)
                    if(index < 0) {
                      this.newContacts.push(tempC);
                    } else {
                      this.newContacts[index] = tempC;
                    }
                    if (this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent) {

                      let eventsId = this.contactService.eventsId;
                      this.nonGuestContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && (item.msevtmgt_eventid == eventsId || item.msevtmgt_eventid == ''));
                    }
                    else {
                      this.nonGuestContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
                    }
                    this.sortContactsListBasedOnSelection(this.sortBy)
                    this.contactsToDisplay = this.sliceContacts(0, this.recordCount);

                    // this.contactsToDisplay.push(this.contactService.tempContactInformation);
                    this.contactService.contactInformation = this.contactService.tempContactInformation;
                    this.isCCRFormDirty = false;
                    this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
                    if (data.isFormSaved) {
                      this.highlightContact(null,tempC);
                      data.eventsHandler(res, 'NEWDATAUPDATED');
                      this.notificationService.notify(this.translate.instant('CONTACT_UPDATE_CREATED'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
                    } else {
                      // this.highlightContact(null,tempC);
                      data.eventsHandler(res, 'NEWDATACREATED');
                      this.notificationService.notify( this.translate.instant('CONTACT_NEW_CREATED'), "DynamicFormComponent", "top", ToastStyle.INFO, 3000);
                    }

                  }
                }

                this.isCCRFormDirty = false;
                loader.dismiss();
              }
          })
          .catch((err) => {
              loader.dismiss();
              console.log("Error creating Contact");
          });
        }
      } else if(eventTarget == 'CANCELBUTTONCLICK') {
        this.trackingService.tracking('BackSelected', TrackingEventNames.CONTACT_CREATE,true);
        this.closePage();
      } else if(eventTarget == 'ADDEDLINKEDENTITY') {
        this.trackingService.tracking('DoneSelected', TrackingEventNames.CONTACT_CREATE, true);
        if(data && data.entity && data.values) {
          this.addLinkEntitiesForContact(data);
          this.isCCRFormDirty = data;
        }
        // let conDetails = await this.contactDataService.getContactDetails(this.contactService.tempContactInformation, false);
        // if(conDetails) {
        //   let index = this.newContacts.findIndex(x => x.ID == conDetails.ID)
        //   let tempC: Contact =  new Contact(conDetails.raw);
        //   if(index >= 0) {
        //     this.newContacts[index] = tempC;
        //   }
        // }
      } else if (eventTarget == 'ISFORMDIRTY') {
        this.isCCRFormDirty = data;
      }
    }
    this._cd.detectChanges();
    this._initAllSectionHeaderModel();
  }

  private async _handleDataCRFormComponentEvent(data, eventTarget, refData:DynamicFormComponentModel){
    if(eventTarget) {
      if (eventTarget == 'DONEBUTTONCLICK') {
        if (data && data.formValue) {
          await this.uiService.displayLoader();
          let payload = _.cloneDeep(data.formValue);
          //payload['indskr_mdmrequesttype']= MDMRequestType.CREATE;
          payload["linkEntities"] = this.mdmService.getReqPayloadLinkEntities();
          await this.mdmService.createBusinessContactDataCR(payload).then(async (res: any) => {
            this._initAllSectionHeaderModel();
            data.eventsHandler(res, 'DISABLEFORM');

            // Create the Notifiction here on Business contact submission.

            await this.mdmService.addNotification(data, res, NOTIFICATION.BUSINESS_CHANGE_REQUEST_OPEN, this.translate.instant('BUSINESS_CHANGE_REQUEST_SUBMITTED', { fullname: data.formValue['indskr_firstname'] + " " + data.formValue['indskr_lastname']}), 'Open');

            await this.myAssistantService.loadAndMapNotificationsFromDB();

            this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
            this.notificationService.notify(this.translate.instant('BUSINESS_CHANGE_REQUEST_CREATE_CONTACT_SUCCESSFULL'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
            this.isCCRFormDirty = false;
            // if customerAvailability flag is true then add it to customer master list and db
            // if (this.authenticationOfflineService.user.customerAvailability && res['contactId']) {
            //   let newContactId = res['contactId'];
            //   this.contactService.tempContactInformation = new Contact({ contactid: res['contactid'], firstname: data.formValue['firstname'] });
            //   this.contactService.tempContactInformation.ID = newContactId;
            //   this.contactService.tempContactInformation.firstName = data.formValue['firstname'];
            //   this.contactService.tempContactInformation.lastName = data.formValue['lastname']
            //   this.contactService.tempContactInformation.fullname = this.contactService.tempContactInformation.firstName + " " + this.contactService.tempContactInformation.lastName;
            //   this.contactService.tempContactInformation.isActive = true;
            //   let eventId = this.contactService.eventsId
            //   this.contactService.tempContactInformation.msevtmgt_eventid = eventId;

            //   if (data && data.eventsHandler) {
            //     let rawDetails = await this.contactDataService.getContactDetails(this.contactService.tempContactInformation, false);
            //     if (rawDetails) {
            //       this.contactService.tempContactInformation = rawDetails;
            //     }
            //     let index = this.newContacts.findIndex(x => x.ID == this.contactService.tempContactInformation.ID)
            //     let tempC: Contact = new Contact(this.contactService.tempContactInformation.raw)
            //     if (index < 0) {
            //       this.newContacts.push(tempC);
            //     } else {
            //       this.newContacts[index] = tempC;
            //     }
            //     if (this.contactService.accessedContactListFrom == PageName.EventDetailsPageComponent) {
            //       let eventsId = this.contactService.eventsId;
            //       this.nonGuestContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && (item.msevtmgt_eventid == eventsId || item.msevtmgt_eventid == ''));
            //     }
            //     else {
            //       this.nonGuestContacts = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
            //     }
            //     this.sortContactsListBasedOnSelection(this.sortBy)
            //     this.contactsToDisplay = this.sliceContacts(0, this.recordCount);

            //     this.contactsToDisplay.push(this.contactService.tempContactInformation);
            //     this.contactService.contactInformation = this.contactService.tempContactInformation;
            //     this.isCCRFormDirty = false;
            //     this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
            //     //this.highlightContact(null, tempC);
            //   }
            // }
            await this.uiService.dismissLoader();
          }).catch((err) => {
            this.uiService.dismissLoader();
            console.log("Error creating Contact change request");
          });
        }
      } else if(eventTarget == 'CANCELBUTTONCLICK') {
        this.trackingService.tracking('BackSelected', TrackingEventNames.CONTACT_CREATE,true);
        this.closePage();
      } else if(eventTarget == 'ADDEDLINKEDENTITY') {
        this.trackingService.tracking('DoneSelected', TrackingEventNames.CONTACT_CREATE, true);
        if (data) {
          this.mdmService.addLinkEntities(data);
          this.isCCRFormDirty = data;
          if (data && data.eventsHandler) {
            data.eventsHandler(data, 'ENABLEFORM');
          }
        }
      } else if (eventTarget == 'ISFORMDIRTY') {
        this.isCCRFormDirty = data;
      }
    }
    this._cd.detectChanges();
  }

  private async _handleContactCRFormComponentEvent(data, eventTarget, refData:DynamicFormComponentModel){
    if(eventTarget) {
      if(eventTarget == 'DONEBUTTONCLICK') {
        if(data && data.formValue) {
          let payload = _.cloneDeep(data.formValue);
          payload['indskr_mdmrequesttype']= MDMRequestType.CREATE;
          payload["linkEntities"] = this.mdmService.getReqPayloadLinkEntities();

          //add mandatory fields
          payload['indskr_mdm'] = payload['indskr_mdm'] ? payload['indskr_mdm'] : 548910000;
          payload['indskr_contactsourcetype'] = 548910001;
          payload['statuscode'] = 548910004;
          payload['statecode'] = 1;
          if (payload['indskr_mdm'] == BusinessProcessType.SanofiChina) {
            let account = null;
            if (data.formValue.lookupfields && data.formValue.lookupfields.length) {
              let accountLookup = data.formValue.lookupfields.filter(e => e.name == "indskr_Account");
              if (!_.isEmpty(accountLookup)) {
                account = this.accountService.getAccountById(accountLookup[0].id);
              }
              if (!account || !account.extendedDetails || !account.extendedDetails['externalId']) {
                this.notificationService.notify(this.translate.instant('ACCOUNT_ETMS_ERR_NOTIFY', { accountName: (account && account.accountName) ? account.accountName : "" }), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
                await this.uiService.dismissLoader();
                return;
              }
            }

            if (!await this.showConfirmationPopup())  return;

            await this.uiService.displayLoader();

            const businessLineVal: BusinessLineVal = this.authenticationOfflineService.user?.buConfigs?.indskr_businessline;
            payload['indskr_businessline'] = businessLineVal;
            payload['statuscode'] = 1;
            payload['statecode'] = 0;
            let userEmail = this.authenticationOfflineService.user.mail;
            if (userEmail.includes('sanofi.net.cn'))
              userEmail = userEmail.replace('sanofi.net.cn', 'sanofi.com');
            if (businessLineVal === BusinessLineVal.VACCINE) {
              const hcp_vaccine = {
                "userName": `${payload['indskr_lastname']}`,
                "gender": payload['indskr_gendercode'] == OmniGender.MALE ? 1 : 2,
                "businessLine": BusinessLineVal.VACCINE,
                "department": data.formStringValue['indskr_departmentvaccine_Desc'],
                "position": data.formStringValue['indskr_rolevaccine_Desc'],
                "mrEmail": userEmail,
                "actionType": VaccineReqType.ADD,
                "hcoCode": account.extendedDetails['externalId']
              }
              payload['hcp_vaccine'] = hcp_vaccine;
            } else if (businessLineVal === BusinessLineVal.PHARMA || businessLineVal === BusinessLineVal.RETAIL || businessLineVal === BusinessLineVal.VACCINE_MDM) {
              let mdm_payload = {
                "dcr_source": DCR_SOURCE.ONECRM,
                "dcr_requestor_nm": this.authenticationOfflineService.user.displayName,
                "dcr_requestor_email": userEmail,
                "dcr_category": DCR_CATEGORY.HCP,
                "dcr_type": OneCRMReqType.ADD,
                "new_hcp_first_nm": `${payload['indskr_lastname']}`,
                "new_hcp_status": "ACTV",
                "new_gender": payload['indskr_gendercode'] == OmniGender.MALE ? OneCRMGender.MALE : OneCRMGender.FEMALE,
                "new_position": data.formStringValue['indskr_rolepharma_Desc'],
                "new_hco_etms": account.extendedDetails['externalId'],
                "new_specialty": data.formStringValue['indskr_specialty'],
                "new_hco_nm": account.accountName,
                "new_title": data.formStringValue['indskr_titlepharma_Desc'],
                "new_hcp_auth": ""
              }
              mdm_payload['new_sa_dept_nm'] = "";
              if (businessLineVal === BusinessLineVal.PHARMA || businessLineVal == BusinessLineVal.VACCINE_MDM) {
                mdm_payload['dcr_business_line'] = businessLineVal == BusinessLineVal.PHARMA ? BusinessLine.PHARMA : BusinessLine.VACCINE;
                if (data.formValue.lookupfields && data.formValue.lookupfields.length) {
                  let specialty = data.formValue.lookupfields.find(e => e['entity'] == "indskr_lu_specialties");
                  if (specialty) {
                    let sp = this.contactService.specialties.find(e => e.id == specialty.id);
                    if (sp) {
                      mdm_payload['new_sa_dept_nm'] = sp.indskr_sadeptnm ? sp.indskr_sadeptnm : "";
                    }
                  }
                }
              } else {
                mdm_payload['dcr_business_line'] = BusinessLine.REATIL;
                mdm_payload['new_position'] = data.formStringValue['indskr_positionretail_Desc'] ? data.formStringValue['indskr_positionretail_Desc'] : data.formStringValue['indskr_positionretail'];;
                mdm_payload['new_specialty'] = "";
                mdm_payload['new_title'] = data.formStringValue['indskr_titleretail_Desc'] ? data.formStringValue['indskr_titleretail_Desc'] : data.formStringValue['indskr_titleretail'];
              }
              
              mdm_payload['new_hco_vid'] = account.indskr_hcovid ? account.indskr_hcovid : '';
              mdm_payload['new_dept_nm'] = "";
              this.mdmService.setEmptyNows(mdm_payload);
              payload['mdm_payload'] = mdm_payload
            }
          } 
          console.log(payload);
          //Create unique Identifier for handling sync of OneKey CR created in Offline 
          const addedNewOneKeyCRId = 'onekeycr' + '_' + Math.random().toString(36).substring(7);

          /*************Create OneKey Change Request in offline*************/
          if (this.contactDataService.isOfflineOneKeyCR(addedNewOneKeyCRId)) {
            payload['addedNewOneKeyCRId'] = addedNewOneKeyCRId;
            payload['indskr_contactcrid'] = addedNewOneKeyCRId;

            this.contactDataService.addToOfflineOneKeyCRIds(payload.addedNewOneKeyCRId);
            await this.disk.createOneKeyCR(payload);

            this._initAllSectionHeaderModel();

            let res = {
              "addedNewOneKeyCRId": payload['addedNewOneKeyCRId']
            };

            payload['modifiedon'] = new Date().getTime();
            payload['createdon'] = new Date().getTime();

            // To create new onekey information for the one cr saved
            await this.mdmService.fetchOneKeyCRConfiguredDisplayOffline(payload, payload['linkEntities']);

            // await this.mdmService.upsertContactCRInPouchDB(payload);

            // await this.diskService.upsertContactCRs(data);
            let contactCRs = this.mdmService.contactCRs.getValue();
            contactCRs.push(payload);
            this.mdmService.contactCRs.next(contactCRs);
            this.mdmService.contactCRList = contactCRs;

            // this._initAllSectionHeaderModel();
            data.eventsHandler(res, 'DISABLEFORM');
            this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
            if (payload['indskr_mdm'] == BusinessProcessType.SanofiChina) {
              this.notificationService.notify(this.translate.instant('ONECRM_DCR_CHANGE_REQUEST_IN_PROCESS'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
              await this.mdmService.addNotification(data, res, NOTIFICATION.ONECRM_DCR_REQUEST_STAGED, this.translate.instant('ONECRM_DCR_REQUEST_STAGED',
              { fullname: data.formValue['indskr_lastname'] }), 'Submitted');
            } else {
              this.notificationService.notify(this.translate.instant('ONE_KEY_CHANGE_REQUEST_IN_PROCESS'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
              await this.mdmService.addNotification(data, res, NOTIFICATION.ONE_KEY_CHANGE_REQUEST_STAGED, this.translate.instant('ONE_KEY_CHANGE_REQUEST_STAGED',
              { fullname: data.formValue['indskr_firstname'] + " " + data.formValue['indskr_lastname'] }), 'Submitted');
            }

            this.isCCRFormDirty = false;
            await this.uiService.dismissLoader();
          }
          /*************Create OneKey Change Request in offline*************/
          else {
            await this.mdmService.createOneKeyContactCR(payload).then(async (res: any) => {
              this._initAllSectionHeaderModel();
              data.eventsHandler(res, 'DISABLEFORM');
              this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;


              if (this.authenticationOfflineService.okIntegrationSettings && this.authenticationOfflineService.okIntegrationSettings.typeOfIntegration == IntegrationType.ONE_KEY)  {
                this.notificationService.notify(this.translate.instant('ONE_KEY_CHANGE_REQUEST_SUCCESSFULLY_SUBMITTED'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);

                await this.mdmService.addNotification(data, res, NOTIFICATION.ONE_KEY_CHANGE_REQUEST_SUBMITTED, this.translate.instant('ONE_KEY_CHANGE_REQUEST_SUBMITTED',
                  { fullname: data.formValue['indskr_firstname'] + " " + data.formValue['indskr_lastname'] }), 'Submitted');
              } else {
                if (payload['indskr_mdm'] == BusinessProcessType.SanofiChina) {
                  this.notificationService.notify(this.translate.instant('ONECRM_DCR_CHANGE_REQUEST_IN_PROCESS'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
                  await this.mdmService.addNotification(data, res, NOTIFICATION.ONECRM_DCR_REQUEST_STAGED, this.translate.instant('ONECRM_DCR_REQUEST_STAGED',
                  { fullname: data.formValue['indskr_lastname'] }), 'Submitted');
                } else {
                  this.notificationService.notify(this.translate.instant('ONE_KEY_CHANGE_REQUEST_IN_PROCESS'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
                  await this.mdmService.addNotification(data, res, NOTIFICATION.ONE_KEY_CHANGE_REQUEST_STAGED, this.translate.instant('ONE_KEY_CHANGE_REQUEST_STAGED',
                  { fullname: data.formValue['indskr_firstname'] + " " + data.formValue['indskr_lastname'] }), 'Submitted');
                }
              }

              if (res.hasOwnProperty('indskr_isduplicate') && res['indskr_isduplicate']) {
                this.notificationService.notify(this.translate.instant('CRM_DUP_CR_ERR'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
              }

              this.isCCRFormDirty = false;
              await this.uiService.dismissLoader();
            }).catch((err) => {
              if(err.error) {
                if (err.error.error && err.error.error == 'MDM_DCR_CREATION_FAILED') {
                  this.notificationService.notify(this.translate.instant('ONECRM_DCR_CHANGE_REQUEST_FAILED'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
                } else if (err.error.errorMessage && err.error.errorMessage.includes('Contact CR already exists')) {
                  this.notificationService.notify(this.translate.instant('CRM_DUP_CR_ERR'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
                } else if (err.error.message && err.error.message.includes('Contact CR already exists')) {
                  this.notificationService.notify(this.translate.instant('CRM_DUP_CR_ERR'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
                } else if (err.error.errorMessage && err.error.errorMessage.includes('Contact already exists')) {
                  this.notificationService.notify(this.translate.instant('CRM_DUP_CONTACT_ERR'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
                }
              }
              this.uiService.dismissLoader();
              console.log("Error creating Contact change request");
            });
          }
        }
      } else if(eventTarget == 'CANCELBUTTONCLICK') {
        this.trackingService.tracking('BackSelected', TrackingEventNames.CONTACT_CREATE,true);
        this.closePage();
      } else if(eventTarget == 'ADDEDLINKEDENTITY') {
        this.trackingService.tracking('DoneSelected', TrackingEventNames.CONTACT_CREATE, true);
        if (data) {
          this.mdmService.addLinkEntities(data);
          this.isCCRFormDirty = data;
          if (data && data.eventsHandler) {
            data.eventsHandler(data, 'ENABLEFORM');
          }
        }
      } else if (eventTarget == 'ISFORMDIRTY') {
        this.isCCRFormDirty = data;
      }
    }
    this._cd.detectChanges();
  }

  private showConfirmationPopup(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      return this.alertService.showAlert({
        title: this.translate.instant('POP_CONFIRM_SUBMIT_POLL'),
        message: this.translate.instant('R_U_SURE_SUBMIT_CR')
      }, this.translate.instant('CONFIRM'),  this.translate.instant('CANCEL')
      ).then(async res => {
        if (res.role === "ok") resolve(true);
        else reject();
      })
    })
  }


  closePage(isCancel?: boolean) {
    if(this.isCCRFormDirty) {
        let cancelText = this.translate.instant("CANCEL");
        if(this.translate.currentLang == 'it') {
          cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
        }
        this.alertService.showAlert({
            title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
            message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')}, this.translate.instant('DISCARD_CONTACT_CR'), cancelText
        ).then (res => {
            if(res.role == "ok") {
              this.trackingService.tracking('DiscardSelected', TrackingEventNames.CONTACT_CREATE,true);
              this.uiService.showNewActivity = false;
              this.uiService.activeView = 'contactDetails';
              this.isCCRFormDirty = false;
              this.dynamicFormsService.okAddressMapper = {};
              this.dynamicFormsService.okLookUpMapper = [];
              this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ContactPageComponent).then(() => {
                this.navService.setChildNavRightPaneView(false);
                if(this.device.isMobileDevicePortrait) {
                  this.contactService.contactInformation = undefined;
                  this.uiService.activeView = '';
                }
              });
            } else if(res.role == "cancel") {
              this.trackingService.tracking('CancelSelected', TrackingEventNames.CONTACT_CREATE, true);
          }
        });
    } else {
        this.trackingService.tracking('CancelSelected', TrackingEventNames.CONTACT_CREATE, true);
        if (this.navService.getActiveChildNavViewPageName() == PageName.DynamicFormComponent) {
          if (this.enableCreateContact && this.contactsCreateRequest && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CONTACTS_ONEKEY_SELECTION)) {
            this.navService.popChildNavPageWithPageTracking().then(success => {
              this.uiService.activeView = 'ContactPageRightPaneNav';
              this.uiService.showNewActivity = false;
              this.navService.pushChildNavPageWithPageTracking(NewChangeRequestComponent, PageName.NewChangeRequestComponent, PageName.ContactPageComponent, {
                from: 'Contact'
              });
            });
          } else {
            this.navService.setChildNavRightPaneView(false);
            this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ContactPageComponent).then(() => {
              if (this.device.isMobileDevicePortrait) {
                this.contactService.contactInformation = undefined;
                this.uiService.activeView = '';
              } else {
                if (this.contactService.tempContactInformation) this.highlightContact({}, this.contactService.tempContactInformation);
              }
            });
          }
      } else {
        this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ContactPageComponent).then(() => {
          if (this.device.isMobileDevicePortrait) {
            this.contactService.contactInformation = undefined;
            this.uiService.activeView = '';
          } else {
            if (this.contactService.tempContactInformation) this.highlightContact({}, this.contactService.tempContactInformation);
          }
        });
      }

    }
  }

  async addContactToMyPosition() {
    if (this.device.isOffline) return;
    // Add Reason field when Acino feature action is there
    const isReasonFieldEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_MAPPING_APPROVAL);
    const isVisibilityApprovalBased = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_VISIBILITY_APPROVAL_BASED);
    if (this.selectedGlobalSearchContact) {
      this.alertService.showAlert({
        title: this.translate.instant('CONTACTS_ADD'),
        inputs:isReasonFieldEnabled?[{type:'text', name:"reason", placeholder:this.translate.instant('ADD_REASON_TO_ADD_TO_YOUR_CONTACT')}]:[],
      }
      ).then (async res => {
        if(res.role == "ok") {
          let payload = {'contactid': this.selectedGlobalSearchContact.ID};
          if(isReasonFieldEnabled){
            if(res.data && res.data.values.reason){
              payload['indskr_reason'] = res.data.values.reason;
              payload['indskr_skipapproval'] = false;
            }else{
              this.addContactToMyPosition();
              return;
            }
          }
          this.contactService.contactInformation = null;
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ContactPageComponent);
          let loader = await this.loadingCtrl.create();
          loader.present();
          let mapSuccess: boolean = false;
          try {
            mapSuccess = await this._mapContactToUserPosition(payload);
          } catch(error) {
            console.log(error);
          } finally {
            if(mapSuccess) {
              if(!_.isEmpty(this.contactService.accountIdsToAdd)) {
                await this._mapAccountToUserPosition(payload, isReasonFieldEnabled);
              }
              if(!isReasonFieldEnabled || !isVisibilityApprovalBased) {
                await this.myAssistantService.loadAndMapNotificationsFromDB();
              }
            }
          }
          loader.dismiss();
        }
      });
    }
  }
  //Map the selected contact to user position
  private async _mapContactToUserPosition(payload): Promise<boolean> {
    let mapSuccess: boolean = false;
    try {
      mapSuccess = await this.contactDataService.postContactToPosition(this.selectedGlobalSearchContact, payload);
    } catch(error) {
      if (error?.error?.errorMessage?.includes('Duplicate Detected')) {
        const errorMessage: string = this.getErrorMessageMappingPosition("CONTACT", error.error.errorMessage);
        if (errorMessage) {
          this.notificationService.notify(
            errorMessage,
            'ContactList',
            'top',
            ToastStyle.DANGER,
            3000,
          );
        }
      }
    } finally {
      if(mapSuccess) {
        const isVisibilityApprovalBased = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_VISIBILITY_APPROVAL_BASED);
        if(payload['indskr_skipapproval'] !== false || !isVisibilityApprovalBased){
          //Push to new Contacts
          //Required delay time to save the fetch data into the disk during getContactDetails
          await new Promise(resolve => setTimeout(resolve, 3000));
          let updatedContact = this.contactService.getContactByID(this.selectedGlobalSearchContact.ID);
          if(updatedContact){
            this.contactService.contactInformation = updatedContact;
            this.updateContactLists(updatedContact,true);
          }
          this.selectedGlobalSearchContact = null;
          //Clear Search
          this.searching = false;
          this.filtering = false;
          this.contactService.isInGlobalSearch = false;
          this.contactSearchText = "";
          this.contactService.searchContacts = [];
          this.selectedSuggestionsData = [];
          this.initContactsHeaderLeft();
        }else{
          this.selectedGlobalSearchContact = null;
          //Clear Search
          this.searching = false;
          this.filtering = false;
          this.contactService.isInGlobalSearch = false;
          this.contactSearchText = "";
          this.contactService.searchContacts = [];
          this.selectedSuggestionsData = [];
          this.initContactsHeaderLeft();
          this.notificationService.notify(this.translate.instant('SUBMITTED_FOR_APPROVAL'), 'ContactList', "top", ToastStyle.INFO, 3000);
        }
      }
    }
    return mapSuccess;
  }
  //Map the selected affiliated accounts to user position
  private async _mapAccountToUserPosition(payload, isReasonFieldEnabled: boolean) {
    if(!this.contactService.accountIdsToAdd || (this.contactService.accountIdsToAdd && this.contactService.accountIdsToAdd.length == 0)) return;
    let rawNewAccountList = [];
    const accountIdList = this.contactService.accountIdsToAdd;

    for(const accountId of accountIdList) {
      let mapSuccess: boolean;
      try {
        mapSuccess = await this.accountDataService.mapGlobalSearchedAccountToUserPosition(accountId, payload);
      } catch(error) {
        if (error?.error?.errorMessage?.includes('Duplicate Detected')) {
          const errorMessage: string = this.getErrorMessageMappingPosition("ACCOUNT", error.error.errorMessage);
          if (errorMessage) {
            this.notificationService.notify(
              errorMessage,
              'AccountList',
              'top',
              ToastStyle.DANGER,
              5000,
            );
          }
        }
      } finally {
        if(mapSuccess) {
          if(!isReasonFieldEnabled || payload?.indskr_skipapproval !== false) {
            const rawNewAccount = await this.accountDataService.getRealTimeAccountDetailsOnline(accountId);
            if(!_.isEmpty(rawNewAccount)) {
              rawNewAccountList.push(rawNewAccount);
            }
          }
        }
      }
    }
    if(!_.isEmpty(rawNewAccountList)) {
      await this.accountService.addMultipleRawAccountDetails(rawNewAccountList);
    }
  }

  async searchContactGlobally() {
    if (!this.searching) return;
    if (!this.selectedSuggestionsData) return;
    this.trackingService.tracking('SelectedSearchAllCustomers', TrackingEventNames.CONTACT_CREATE, true);
    this.resetOneKeySearch();
    this.contactService.isInGlobalSearch = true;
    this.contactService.globalSearchResults = [];
    this.slicedGlobalSearchResults = [];
    this.globalContactsHeader.title = this.translate.instant('CONTACT_OTHER_GLOBALCUSTOMERS', {globalCustomerText : this.globalCustomerDisplayText}) + ' (' + this.contactService.globalSearchResults.length + ')';
    this.contactService.contactInformation = null;

    let loader =await this.loadingCtrl.create();
    loader.present();
    let response = [];

    try {
      response = await this.contactDataService.globalSearch(this.selectedSuggestionsData);
    } catch (error) {
      console.error('globalSearch: ', error);
    } finally {
      this.mapGlobalSearchResults(response);
      loader.dismiss();
    }
  }

  async searchOneKeyContacts() {
    if (this.device.isOffline) return;
    this.contactService.isInGlobalSearch = false;
    this.contactService.isOneKeySearching = true;
    this.searchedByOKID = false;
    this.contactService.contactInformation = undefined;
    this.uiService.activeView = '';
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ContactDetailsComponent);
    await this.uiService.displayLoader();
    let criteria = await this.buildSearchCriteria();
    await this.onekeyApiService.searchOneKey(criteria);
    this._initAllSectionHeaderModel();
    await this.uiService.dismissLoader();
  }

  async buildSearchCriteria(): Promise<object> {
    let criteria = {
      "codBase": [this.authenticationOfflineService.user.iqviaCodBase],
      "entityType": OKEntityType.ACTIVITY,
      "fields": []
    }
    if (this.selectedSuggestionsData && Array.isArray(this.selectedSuggestionsData) && this.selectedSuggestionsData.length) {
      this.selectedSuggestionsData.forEach(suggestion => {
        let codBaseSubStr = suggestion.charSearchText && !suggestion['categoryPath'] ? suggestion.charSearchText.substring(0,3) : "";
        if (this.authenticationOfflineService.user.iqviaCodBase == codBaseSubStr) {
          criteria.fields.push({
            "name": OKActivitySearchKeys.INDIVIDUAL_ID,
            "values": [suggestion.charSearchText]
          });
          this.searchedByOKID = true;
        } else {
          if (criteria.fields.length) criteria.fields.push(SEARCH_OPERATOR.AND);
          if (suggestion['charSearchText'] && !suggestion['categoryPath']) {
            criteria.fields.push({
              "name": OKActivitySearchKeys.FIRST_NAME,
              "values": [suggestion.charSearchText]
            },
              SEARCH_OPERATOR.OR,
              {
              "name": OKActivitySearchKeys.FIRST_NAME2,
              "values": [suggestion.charSearchText]
            },
              SEARCH_OPERATOR.OR,
              {
              "name": OKActivitySearchKeys.LAST_NAME,
              "values": [suggestion.charSearchText]
            },
              SEARCH_OPERATOR.OR,
              {
              "name": OKActivitySearchKeys.LAST_NAME2,
              "values": [suggestion.charSearchText]
            },
              SEARCH_OPERATOR.OR,
              {
              "name": OKActivitySearchKeys.MIDDLE_NAME,
              "values": [suggestion.charSearchText]
            },
              SEARCH_OPERATOR.OR,
              {
              "name": OKActivitySearchKeys.USL_FIRST_NAME,
              "values": [suggestion.charSearchText]
            },
              SEARCH_OPERATOR.OR,
              {
              "name": OKActivitySearchKeys.USL_LAST_NAME,
              "values": [suggestion.charSearchText]
            });
          }
          if (suggestion['categoryPath']) {
            let categoryPathCI = suggestion['categoryPath'].toLowerCase();
            if (categoryPathCI.includes(OmnipresenceContactSearchKeys.INDIVIDUAL_ID.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKActivitySearchKeys.INDIVIDUAL_ID])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKActivitySearchKeys.INDIVIDUAL_ID,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
              this.searchedByOKID = true;
            }
            if (categoryPathCI.includes(OmnipresenceContactSearchKeys.GENDER_CODE.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKActivitySearchKeys.GENDER_CODE])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKActivitySearchKeys.GENDER_CODE,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceContactSearchKeys.PREFERRED_LANGUAGE.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKActivitySearchKeys.PREFERRED_LANGUAGE])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKActivitySearchKeys.PREFERRED_LANGUAGE,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceContactSearchKeys.NAME_PREFIX.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKActivitySearchKeys.NAME_PREFIX])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKActivitySearchKeys.NAME_PREFIX,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceContactSearchKeys.TITLE_CODE.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKActivitySearchKeys.TITLE_CODE])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKActivitySearchKeys.TITLE_CODE,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceContactSearchKeys.TYPE_CODE.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKActivitySearchKeys.TYPE_CODE])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKActivitySearchKeys.TYPE_CODE,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
            if (categoryPathCI.includes(OmnipresenceContactSearchKeys.REASON_CODE.toLowerCase())) {
              if (_.some(criteria.fields, ['name', OKActivitySearchKeys.REASON_CODE])) criteria.fields.push(SEARCH_OPERATOR.OR);
              criteria.fields.push({
                "name": OKActivitySearchKeys.REASON_CODE,
                "values": [suggestion.charSearchText ? suggestion.charSearchText : suggestion.selectedFacet]
              });
            }
          }
        }
      });
    }
    return criteria;
  }

  addToOKSelected(selected) {
    selected['isSelected'] = true;
    this.onekeyApiService.oneKeyContacts.forEach(contact => {
      if (contact['individualEid'] != selected['individualEid']) {
        contact['isSelected'] = false;
      }
    });
    this.contactService.selectedOKContacts = [];
    this.contactService.selectedOKContact.next(selected);
    this.contactService.selectedOKContacts.push(selected);
    this._initAllSectionHeaderModel();
  }

  displayOKDetails(contact) {
    console.log("opening onekey searched contact details");
    this.uiService.activeView = 'contactDetails';
    //this.uiService.contactDetailsSegment = 'info';
    this.uiService.showNewActivity = false;
    this.contactService.selectedOKContact.next(contact);
    if(this.navService.getActiveChildNavViewPageName() == PageName.AccountDetailsComponent || this.navService.getActiveChildNavViewPageName() == PageName.ContactDetailsComponent){
      this.navService.popChildNavPageWithPageTracking();
    } else {
      this.navService.setChildNavRightPaneView(false);
    }
    this.navService.pushChildNavPageWithPageTracking(ContactDetailsComponent, PageName.ContactDetailsComponent, PageName.AccountPageComponent, { contactListMode: ComponentViewMode.READONLY });
  }

  async addToPopulation() {
    let contact = this.contactService.selectedOKContact.value;
    if (!contact['isInContract']) {
      let payload = [{
        "onekeyEid": contact['activityEid'],
        "entityType": OKEntityType.ACTIVITY
      }];
      await this.uiService.displayLoader();
      if (!this.searchedByOKID) {
        await this.downloadContact(contact);
      }
      await this.onekeyApiService.submitPop(payload).then(async () => {
        await this.createContactInDynamics(contact);
        let idx = this.onekeyApiService.oneKeyContacts.findIndex(con => con['individualEid'] == contact['individualEid']);
        this.onekeyApiService.oneKeyContacts.splice(idx, 1);
        this.onekeyApiService.okSearchResult.next(this.onekeyApiService.oneKeyContacts);
        this.contactService.selectedOKContact.next(null);
        this.contactService.selectedOKContacts.length = 0;
        await this.uiService.dismissLoader();
      }).catch(async (e) => {
        console.log("error occurred while adding to population", e);
        await this.uiService.dismissLoader();
      });
      this._initAllSectionHeaderModel();
    }
  }

  async downloadContact(contact) {
    let payload = {
      "codBase": [this.authenticationOfflineService.user.iqviaCodBase],
      "entityType": OKEntityType.ACTIVITY,
      "fields": [{
        "name": OKActivitySearchKeys.INDIVIDUAL_ID,
        "values": [contact['individualEid'].toUpperCase()]
      }]
    }
    await this.onekeyApiService.downloadOnekey(payload).then(async (res) => {
      if(res && Array.isArray(res)) {
        res[0]['individual']['isInContract'] = contact['isInContract'];
        res[0]['individual']['activityEid'] = contact['onekeyEid'];
        res[0]['individual']['address'] = res[0]['workplace']['workplaceAddresses'];
        contact['address'] = res[0]['workplace']['workplaceAddresses'];
        contact['qualifications'] = res[0]['individual']['qualifications'];
        res[0]['individual']['isSelected'] = contact['isSelected'];
        let idx = this.onekeyApiService.oneKeyContacts.findIndex(con => con['individualEid'] == contact['individualEid']);
        this.onekeyApiService.oneKeyContacts[idx] = res[0]['individual'];
        this.onekeyApiService.okSearchResult.next(this.onekeyApiService.oneKeyContacts);
      }
    });
  }

  async createContactInDynamics(contact) {
    let payload = {
      // "indskr_title":"548910001",
      "firstname":contact['firstName'] ? contact['firstName'] : contact['usualFirstName'],
      "lastname":contact['lastName'] ? contact['lastName'] : contact['usualLastName'],
      "middlename":contact['middleName'] ? contact['middleName'] : '',
      // "indskr_deanumberexpirationdate":"2021-07-20",
      // "indskr_deanumber":"10121",
      // "telephone1":"10121",
      // "mobilephone":"10121111111",
      // "indskr_alternatephone1":"9098909890",
      "indskr_contactsourcetype": ChangeRequestType.MDM,
      "indskr_mdm":MDMType.ONEKEY,
      "indskr_mdmid":contact['individualEid'],
      // "indskr_alternatephone2":"8989898989",
      // "indskr_practicingstatus":"548910000",
      // "omnione_dayandmonthofbirth":"2021-04-20",
      // "omnione_yearofbirth":"1987-04-20",
      // "omnione_graduationyear":"2019-04-20",
      // "indskr_speaker":true,
      "lookupfields":[],
      "omnione_dateoflastindividualqualitycheck":contact['checkDate'] ? format(contact['checkDate'], 'YYYY-MM-DD') : null,
      "omnione_individualcreationdate":contact['creationDate'] ? format(contact['creationDate'], 'YYYY-MM-DD') : null,
      "omnione_individualsfirstnameina2ndalphabetlanguag":contact['firstName2'] ? contact['firstName2'] : null,
      "omnione_individualslastnameina2ndalphabetlanguage":contact['lastName2'] ? contact['lastName2'] : null,
      "omnione_initials":contact['firstNameInitials'] ? contact['firstNameInitials'] : null,
      "omnione_dateoflastindividualstatusupdate":contact['statusDate'] ? format(contact['statusDate'], 'YYYY-MM-DD') : null,
      // "omnione_titlecode":"100000000",
      // "omnione_stopflagforvisitsdonotvisit":true,
      // "omnione_contactqualitycheckstatus":"100000000",
      "omnione_dataexport":contact['noDataExport'] ? contact['noDataExport'] : false,
      // "indskr_custom_contactid":"WDEM06437508",
      // "indskr_npi_number":1012121,
      // "indskr_facebookpsid":"12345"
    };
    await this.contactDataService.createDynamicContact(payload).then(async (res) => {
      if(res && res['contactid']) {
        if (contact['qualifications']) {
          await this.mapOKSpecialityToContact(res['contactid'], contact);
        }
        if (contact['address']) {
          await this.mapOKAddressesToContact(res['contactid'], contact);
        }
        if(!this.contactService.tempContactInformation) this.contactService.tempContactInformation = new Contact({contactid: res['contactid'], firstname: payload['firstname']});
        this.contactService.tempContactInformation.ID = res['contactid'];
        this.contactService.tempContactInformation.firstName = payload['firstname'];
        this.contactService.tempContactInformation.lastName = payload['lastname'] ? payload['lastname'] : "";
        this.contactService.tempContactInformation.fullname = this.contactService.tempContactInformation.firstName + " " + this.contactService.tempContactInformation.lastName;
        this.contactService.tempContactInformation.isActive = true;

        let rawDetails = await this.contactDataService.getContactDetails(this.contactService.tempContactInformation, false);
        if(rawDetails) {
          this.uiService.activeView = 'contactDetails';
          this.contactService.tempContactInformation = rawDetails;
        }
        let index = this.newContacts.findIndex(x => x.ID == this.contactService.tempContactInformation.ID)
        let tempC: Contact =  new Contact(this.contactService.tempContactInformation.raw)
        if(index < 0) {
          this.newContacts.push(tempC);
        } else {
          this.newContacts[index] = tempC;
        }

        this.sortContactsListBasedOnSelection(this.sortBy)
        this.contactsToDisplay = this.sliceContacts(0, this.recordCount);

        this.contactService.contactInformation = this.contactService.tempContactInformation;
      }
    }).catch(async (err) => {
      console.log("Error creating Contact via OK API", err);
      await this.uiService.dismissLoader();
    });
  }

  async mapOKSpecialityToContact(contactId, contact) {
    try {
      let fetchQuery = fetchQueries.oneKey.specialityById;
      let tasks = [];
      if (contact['qualifications']['SP,1'] && contact['qualifications']['SP,1']['corporateLabel']) {
        let specialityRes = null;
        fetchQuery = fetchQuery.replace('{specialityName}', contact['qualifications']['SP,1']['corporateLabel']);
        tasks.push(this.dynamics.executeFetchQuery('omnione_onekeycodeslabelses',fetchQuery).then(speciality =>{
          if (Array.isArray(speciality) && speciality.length) {
            specialityRes = speciality.filter(sp => sp.omnione_isocodebase === this.authenticationOfflineService.user.iqviaCodBase);
            console.log("specialityRes: " +specialityRes);
            if (specialityRes && Array.isArray(specialityRes) && specialityRes.length) {
              let payload = {
                "indskr_name": contact['qualifications']['SP,1']['corporateLabel'],
                "omnione_lu_specialties@odata.bind": "/omnione_onekeycodeslabelses("+specialityRes[0]['omnione_onekeycodeslabelsid']+")",
                "indskr_customerspecialtysourcetype": 548910001,
                "indskr_isprimary": true,
                "statuscode": 1,
                "statecode": 0,
                "indskr_customerid_contact@odata.bind": "/contacts(" + contactId + ")"
              };
              tasks.push(this.dynamics.create(payload, 'indskr_customerspecialties').then(res =>{
                console.log("successfully mapped omnione_lu_specialties to indskr_customerspecialties" +res);
              }));
              let updateSpecialtyPayload = {
                "omnione_lu_Specialties@odata.bind": "/omnione_onekeycodeslabelses("+specialityRes[0]['omnione_onekeycodeslabelsid']+")",
              };
              tasks.push(this.dynamics.update(contactId, 'contacts', updateSpecialtyPayload).then(res =>{
                console.log("successfully mapped omnione_lu_specialties to contacts" +res);
              }));
            }
          }
        }));
        await Promise.all(tasks);
      }
    } catch (e) {
      console.log("failed to map specialties to OK contact");
      console.log(e);
    }
  }

  async mapOKAddressesToContact(contactId, contact) {
    try {
      let fetchQuery = fetchQueries.oneKey.addressMetaData;
      let tasks = [];
      if (contact['address']['P,1'] && contact['address']['P,1']['address']) {
        let addressObj = contact['address']['P,1']['address'];
        fetchQuery = fetchQuery.replace('{postalCode}', addressObj['longPostalCode']);
        if (addressObj['postalTownReference']) {
          let postalCodeObj = addressObj['postalTownReference'];
          fetchQuery = fetchQuery.replace('{countryCode}', postalCodeObj['country']);
          if (postalCodeObj['subdivisions']) {
            let subDivisions = postalCodeObj['subdivisions'];
            fetchQuery = fetchQuery.replace('{stateName}', subDivisions['SUB.3']['longLocalizedLabel']);
            fetchQuery = fetchQuery.replace('{cityName}', subDivisions['SUB.5']['longLocalizedLabel']);
            console.log("address metadata fetchQuery: ", fetchQuery)
            tasks.push(this.dynamics.executeFetchQuery('indskr_lu_countries',fetchQuery).then(data =>{
              if (data && Array.isArray(data) && data.length) {
                let payload = {
                  "indskr_Country_LU@odata.bind": "/indskr_lu_countries("+data[0]['countryId']+")",
                  "indskr_State_lu@odata.bind": "/indskr_lu_states("+data[0]['stateId']+")",
                  "indskr_City_lu@odata.bind": "/indskr_lu_cities("+data[0]['cityId']+")",
                  "indskr_postalcode_lu@odata.bind": "/indskr_lu_postalcodes("+data[0]['postalCode']+")",
                  "indskr_externalid": addressObj['addressEid'],
                  "indskr_line1": addressObj['addressLongLabel'] ? addressObj['addressLongLabel'] : "",
                  "omnione_dispatchlabel": postalCodeObj['dispatchLabel'] ? postalCodeObj['dispatchLabel'] : "",
                  "omnione_village": postalCodeObj['villageLabel'] ? postalCodeObj['villageLabel'] : "",
                  "indskr_postofficebox": contact['address']['P,1']['postalBoxLabel'] ? contact['address']['P,1']['postalBoxLabel'] : ""
                };
                tasks.push(this.dynamics.create(payload, 'indskr_addresses').then(res =>{
                  console.log(res);
                  console.log("successfully mapped OK address to indskr_addresses", res);

                  let mapAddressToContact = {
                    "indskr_Customer_contact@odata.bind": "/contacts(" +contactId+ ")",
                    "indskr_Address@odata.bind": "/indskr_addresses(" +res['indskr_addressid']+ ")",
                    "indskr_name": addressObj['addressLongLabel'] ? addressObj['addressLongLabel'] : "",
                    "indskr_customeraddresssourcetype": 548910001,
                    "indskr_isprimary": true
                  }
                  tasks.push(this.dynamics.create(mapAddressToContact, "indskr_indskr_customeraddress_v2s").then(res =>{
                    console.log(res);
                    console.log("successfully mapped indskr_indskr_customeraddress_v2s to contact", res);
                  }));
                }));
              }
            }));
            await Promise.all(tasks);
          }
        }

      }
    } catch (e) {
      console.log("failed to map address to OK contact");
      console.log(e);
    }
  }

  mapGlobalSearchResults(raw: any, isTargetFilter: boolean = false ) {
    if (Array.isArray(raw)) {
      raw.map(rawJSON => {
        const existingContact = this.contactService.contacts.find(con => con.ID === rawJSON.contactid);
        if(existingContact) {
          this.contactService.globalSearchResults.push(existingContact);
        } else {
          let duplicateContact: Contact = this.contactService.globalSearchResults.find(x => x.ID == rawJSON.contactid);
          let specialty: Specialty = {
            id: rawJSON['indskr_customerspecialty.indskr_specialtyid'] ? rawJSON['indskr_customerspecialty.indskr_specialtyid'] : "",
            name: rawJSON['indskr_customerspecialty.indskr_specialtyid_Formatted'] ? rawJSON['indskr_customerspecialty.indskr_specialtyid_Formatted'] : "",
            isprimary: rawJSON['indskr_customerspecialty.indskr_isprimary'] ? rawJSON['indskr_customerspecialty.indskr_isprimary'] : false
          };
          let address: ContactAddress = {
            city: rawJSON['indskr_address.indskr_city_lu_Formatted'] || '',
            state: rawJSON['stateName'] || '',
            postal: '',
            country: rawJSON['countryName'] || '',
            countryCode: rawJSON['countryCode'] || '',
            street: '',
            street2: '',
            street3: '',
            region: '',
            postOfficeBox: '',
            isPrimary: !!rawJSON['indskr_primary'],
            addressId: '',
            compositeAdd: '',
            primaryContact: '',
            fax: '',
            fax2: '',
            telephone1: '',
            telephone2: '',
            isSampleEligible: false,
            customerAddressID: '',
            concatanatedAddress: ''
          };
          let addObj: AccountRelation = {
            accountId: null,
            accountContactAffiliationId: null,
            accountName: rawJSON['aca_name'] || '',
            name: '',
            startDate: '',
            endDate: '',
            createdDate: '',
            comments: '',
            contactRoleId: '',
            contactRole: '',
            directRelation: 'False',
            status: 'Not Active',
            statecode: null,
            expandIcon: 'arrow-dropup',
            showDetails: false,
            isPrimary: rawJSON['aca_isprimary'],
          };
          if (duplicateContact) {
            if(specialty.isprimary && specialty.name) duplicateContact.primarySpecialty = specialty;
            if(address.isPrimary) duplicateContact.addressesList.push(address);
            if(addObj.accountName) duplicateContact.accountRelationships.push(addObj);
          } else {
            rawJSON["modifiedon"] = new Date(rawJSON["modifiedon"]).getTime().toString();
            let tempContact = new Contact(rawJSON);

            if(specialty.isprimary && specialty.name) tempContact.primarySpecialty = specialty;
            if(address.isPrimary) tempContact.addressesList.push(address);
            if(addObj.accountName) tempContact.accountRelationships.push(addObj);
            tempContact.isActive = true;
            tempContact.specialties.push(specialty);
            this.contactService.globalSearchResults.push(tempContact);
          }
        }
      });
      // map for display
      this.contactService.globalSearchResults.map(con => {
        if (con.specialties) {
          let spArray = [];
          for (let sp of con.specialties) {
            spArray.push(sp.name);
          }
          con.combinedSpecialty = spArray.join(", ");
        }
      })

      if(!isTargetFilter) {
        // filter to order by name first
        let specialtyGroup: Contact[] = [];
        let nameOrder = this.contactService.globalSearchResults.filter(x => {
          if (x.fullName.toLowerCase().includes(this.contactSearchText)) {
            return true;
          } else {
            specialtyGroup.push(x);
          }
        });

        this.contactService.globalSearchResults = nameOrder.concat(specialtyGroup);

          //remove global search contacts thats already in your contact
        this.contactService.globalSearchResults = this.contactService.globalSearchResults.filter(x => {
          return !this.contactService.contacts.some(con => {
            return con.ID == x.ID;
          });
        });
      }
    } else {
      console.error('Unsupported data format for Contact!');
    }
    if (this.globalSearchRecordCount < 30) this.globalSearchRecordCount = 30;
    this.slicedGlobalSearchResults = this.sliceGlobalSearchedContacts(0,this.globalSearchRecordCount);
    this.globalContactsHeader.title = this.translate.instant('CONTACT_OTHER_GLOBALCUSTOMERS', {globalCustomerText : this.globalCustomerDisplayText}) + ' (' + this.contactService.globalSearchResults.length + ')';
  }

  getContactSpecialty(contact) {
    if (contact.specialties) {
      return contact.specialties.join(",");
    }
  }

  // isCanAddUser() {
  //   if (!this.contactService.contactInformation) return false;

  //   let x = this.contactService.contacts.some(o => o.ID == this.contactService.contactInformation.ID);
  //   return !x;
  // }

  removeSelectedGSC() {
    this.selectedGlobalSearchContact = null;
    this.selectablLinkedEntityData = [];
    this.contactService.accountIdsToAdd = [];
    this.initContactsHeaderLeft();
  }

  onOKContactSelected(contact, checked) {
    if (this.contactService.isOneKeySearching) {
      // Single select
      const curSelected = this.contactService.selectedOKContacts[0];
      this.contactService.selectedOKContacts = [];

      if (curSelected) {
        if (checked) {
          if (curSelected['individualEid'] !== contact['individualEid']) {
            curSelected.isSelected = false;
            this.contactService.selectedOKContacts.pop();
            this.contactService.selectedOKContacts.push(contact);
          }
        } else {
          // Has to be de-selection of current selected
          curSelected.isSelected = false;
          this.contactService.selectedOKContacts.pop();
        }
      } else if (checked) {
        this.contactService.selectedOKContacts.push(contact);
      }
    }
    this._cd.detectChanges();
    this.initContactsHeaderLeft();
  }

  async selectGSC(event, contact) {
    if (this.device.isOffline) return;
    event.stopPropagation();
    if (contact) {
      this.selectedGlobalSearchContact = contact;
      let loadFromDB: boolean = false;
      if(this.contactService.contactInformation && this.contactService.contactInformation.ID == contact.ID) {
        loadFromDB = true;
      } else loadFromDB = false;
      let loader = await this.loadingCtrl.create();
      if(!loadFromDB) loader.present();
      await this._updateSelectableLinkedEntity(contact.ID, loadFromDB);
      if(loader) loader.dismiss();
      //the user can see and select the affiliated account along with scroll up
      setTimeout(()=>{
        this.ionContent.scrollToTop();
      }, 100);
    }
    this.initContactsHeaderLeft();
  }

  private async _updateSelectableLinkedEntity(contactId: string, loadFromDB: boolean) {
    this.contactService.accountIdsToAdd = [];
    this.selectablLinkedEntityData = [];
    const dbKey: string = 'indskr_accountcontactaffiliation';
    const referenceEntity: string = 'contactid';
    this.selectablLinkedEntityData = await this.contactService.getSelectableLinkedEntity(dbKey, referenceEntity, contactId, loadFromDB);
    if(!_.isEmpty(this.selectablLinkedEntityData)) {
      const isReasonFieldEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_MAPPING_APPROVAL);
      this.selectablLinkedEntityData.forEach(le=> {
        if(isReasonFieldEnabled) {
          le.isDisableChkBox = !_.isEmpty(le.approvalStatus) ? true : !this.accountService.validateAddAccountToUserPosition(le.selId);
        }else {
          le.isDisableChkBox = !this.accountService.validateAddAccountToUserPosition(le.selId);
        }        
        if(le.isPrimary && !le.isDisableChkBox) {
          le.isChecked = true;
          //default selected primary account
          this.contactService.accountIdsToAdd.push(le.selId);
        }
      });
      //sort the affiliated account: 1st line - primary account
      let tempLinkedEntityData = this.selectablLinkedEntityData;
      let filteredByPrimaryId = tempLinkedEntityData.filter(le=>le.isPrimary);
      let sortAndfilteredByNonPrimaryId = tempLinkedEntityData.filter(le=>(!le.isPrimary)).sort((a,b)=>(a.selName.toLowerCase() > b.selName.toLowerCase()) ? 1 : -1);
      this.selectablLinkedEntityData = filteredByPrimaryId.concat(sortAndfilteredByNonPrimaryId);
    }
  }

  private async _updateAccountsOfSelectedMeeting(accounts: Account[]) {
    if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Appointment && !(this.activityService.selectedActivity as AppointmentActivity).isCompleted) {
      this.activityService.selectedActivity['accounts'] = [];
      accounts.forEach(account => {
        this.accountService.moveAccountToSelected(account);
      });

      if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
        try {
          await this.meetingService.addAccountsToMeeting((this.activityService.selectedActivity as AppointmentActivity));
        } catch (error) {
          console.error('ContactListComponent: _updateAccountsOfSelectedMeeting: ', error);
          await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity, false, true);
        }
      } else {
        await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity);
      }
    }
  }

  private async _updateAccountsOfSelectedPhoneCall(accounts: Account[]) {
    if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.PhoneCall) {
      this.activityService.selectedActivity['accounts'] = [];
      accounts.forEach(account => {
        this.accountService.moveAccountToSelected(account);
      });
      await this.activityService.upsertPhoneCallOfflineData((this.activityService.selectedActivity as PhoneActivity));
      // if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
      //   await this.meetingService.addAccountsToMeeting((this.activityService.selectedActivity as PhoneActivity));
      // }
    }
  }

  public get isContactCreateDisabled():boolean {
    return this.navService.getActiveChildNavViewPageName() == PageName.NewChangeRequestComponent || this.navService.getActiveChildNavViewPageName() == PageName.DynamicFormComponent;
  }

  public get isEventContactCreateDisabled():boolean {
    return this.contactService.contactPageMode == ComponentViewMode.READONLY || this.device.isOffline;
  }


  public mobileNumber: boolean = true;
  public alternatePhone1: boolean = false;
  public alternatePhone2 : boolean = false;
  public alternatePhone3 : boolean = false

  /**
   * Phone call activity phone number selction method
   * @param event
   * @param value
   */
  phoneNumberSelection(index){
    if (!this.mobileNumberList[index]?.isChecked && this.ensureDontContactAMAContact(this.radioSelectedContact)) return;
      this.mobileNumberList.forEach((x, ind) => {
        if(ind !== index){
          x.isChecked = false;
        } else{
          x.isChecked = true;
          this.selectedMobileNumber = x.phoneNumber;
          // (this.activityService.selectedActivity as PhoneActivity).selectedMobileNumber = x.phoneNumber
        }
      });
      this.isDirty = true;
      let doneButton = this.indHeaderLeftModel.controls.find((c) => c.id ===  "done");
      if(doneButton) doneButton.isDisabled = !this.isDirty;
  }

  nonNativePhoneNumberSelection(event, selectedContact: Contact, mobileNumber){
    if (event && event.stopPropagation) event.stopPropagation();
    let selectedPhoneNumberList = this.mobileNumberListforShareMessage.filter(phoneNumber => phoneNumber.isSelected);
    if (mobileNumber.isSelected) {
      let alloToDeselect = selectedPhoneNumberList.length === 1;
      if (!alloToDeselect) {
        mobileNumber.isSelected = !mobileNumber.isSelected;
        this.isDirty = true;
      }
    } else if (selectedPhoneNumberList.length < 2) {
        mobileNumber.isSelected = !mobileNumber.isSelected;
        this.isDirty = true;
    } else {
      selectedPhoneNumberList[0].isSelected = false;
      mobileNumber.isSelected = !mobileNumber.isSelected;
      this.isDirty = true;
    }
    this.initContactsHeaderLeft();
  }

  private purgeGlobalSearchUserData() {

    if (this.contactService.isInGlobalSearch && this.contactService.contactInformation) {
      this.contactDataService.purgeContactFromDatabase(this.contactService.contactInformation.ID);
    }
  }

  /*
  getPlaceholder(): string {
    let placeholder: string = "";
    if (this.isOpenedForLiveMeet) {
      placeholder = this.translate.instant('SEARCH_PARTICIPANTS');
      return placeholder;
    } else {
      switch (this.utilityService.globalCustomerText) {
        case 'Customer':
          placeholder = this.translate.instant('SEARCH_CUSTOMERS');
          break;
        case 'Stakeholder':
          placeholder = this.translate.instant('SEARCH_STAKEHOLDERS');
          break;
        default:
          placeholder = this.translate.instant('SEARCH_CUSTOMERS');
          break;
      } return placeholder;
    }
  }*/

  private initContactsHeaderLeft(): void {
    let buttons = [];
    if(this.isAccessedFromToolsDrawer){
      buttons.push({
        id: "home",
        imgSrc: 'assets/imgs/back_to_home_btn.svg',
        //icon: "indegene-back-to-home-btn",
        isDisabled: false,
        align: "left",
        //cssClass: 'icon-back-home'
      });
      /*if (!this.contactService.isInGlobalSearch && this.enableCreateContact && this.contactService.contactPageMode===this.compViewMode.LISTVIEW && !this.uiService.isConsentFromToolDrawer) {
        buttons.push({
          id: "create",
          imgSrc: 'assets/imgs/ios_add_3x.svg',
          //name: this.translate.instant('CREATE'),
          isDisabled: this.isContactCreateDisabled,
          align: "right",
        });
      }*/
      // if (this.contactService.isInGlobalSearch && this.enableGlobalSearch && this.contactService.contactPageMode===this.compViewMode.LISTVIEW && !this.uiService.isConsentFromToolDrawer) {
      //   buttons.push({
      //     id: "add",
      //     name: this.translate.instant('ADD'),
      //     isDisabled: !this.selectedGlobalSearchContact,
      //     align: "right",
      //   });
      // }
    }
    else{
      if((this.contactService.contactPageMode===ComponentViewMode.ADDNEW
          || this.contactService.contactPageMode===ComponentViewMode.SELECTION )){
            buttons.push({
              id: "close",
              imgSrc: 'assets/imgs/header_cancel.svg',
              cssClass: 'seventyPercentWidth',
              //name:this.translate.instant('CANCEL'),
              isDisabled: false,
              align: "left",
            },{
              id: "done",
              imgSrc: 'assets/imgs/header_complete.svg',
              cssClass: 'seventyPercentWidth',
              //name:this.translate.instant('DONE'),
              isDisabled: this.isDoneButtonDisabled(),
              align: "right",
            });
      }
      else if(this.contactService.contactPageMode===ComponentViewMode.READONLY){
        buttons.push({
          id: "close",
          imgSrc: 'assets/imgs/header_cancel.svg',
          cssClass: 'seventyPercentWidth',
          //name:this.translate.instant('CANCEL'),
          isDisabled: false,
          align: "left",
        })
      }
      else{
        buttons.push({
          id: "close",
          imgSrc: 'assets/imgs/back_to_home_btn.svg',
          //name:this.translate.instant('BACK'),
          isDisabled: false,
          align: "left",
        });
      }
    }
    this.indHeaderLeftModel = {
      id: 'orders-list-header-left',
      cssClass: 'main-tool-header-title',
      title: (this.isOpenedForLiveMeet || this.contactService.accessedContactListFrom == this.pagename.EventDetailsPageComponent)?(this.translate.instant('ACTIVITY_DETAILS_PARTICIPANTS')):(this.uiService.isConsentFromToolDrawer ? this.translate.instant('CONSENTS'): this.translate.instant('CONTACTS')),
      mode: this.isAccessedFromToolsDrawer,
      customHeaderProps:{hasCancel:!this.isAccessedFromToolsDrawer},
      controls: buttons,
    };
  }

  onPageTitleControlClick(id){
    if(id==='home'){
      this.trackToolsBack();
    }
    if(id==='close'){
      this.onCloseModal(false);
    }
    if(id==='done'){
      this.onCloseModal(true);
    }
    // if(id==='create'){
    //   this.addNewContact();
    // }
    // if(id==='add'){
    //   this.addContactToMyPosition();
    // }
  }

  public async loadVeevaMyRequestsWidget() {
    await this.veevaWidgetService.openMyRequestsWidget();
  }

  public async loadVeevaWidget() {
    await this.veevaWidgetService.openSearchHCPWidget();
  }

  private newVeevaRequests: any = [];
  @HostListener('window:veeva-network-add-request-submitted', ['$event'])
  public async veevaAddRequestSubmitted(event) {
    console.log(event);
  }

  @HostListener('window:veeva-network-select', ['$event'])
  public async createVeevaEntity(event) {
    console.log(event);
    let entity = event.detail.entity;
    let newEntity = !!event.detail.taskId;
    const loading = await this.loadingCtrl.create();
    if (entity.entityType == 'HCP') {
      loading.present();
      const veevaIndex = this.contactService.contacts.findIndex(c => c.mdmType == MDMType.VEEVA && c.appId == entity.entity.vid__v);
      try {
        let data = await this.contactService.createVeevaContact(entity.entity);
        if (data[0]?.contactid && event.detail.taskId) {
          await this.dynamics.dwa.create({
            omniveev_type: 200000000,
            omniveev_externalid: event.detail.taskId,
            'omniveev_customer_contact@odata.bind': `/contacts(${data[0]?.contactid})`
          }, "omniveev_datachangerequests");
        }
        // define alternative custom key format
        if (data[0]?.contactid && event.detail.widget?.associateCustomKey) {
          // define alternative custom key format
          const veevaNetworkFingerprint = this.veevaWidgetService.config.veevaNetworkFingerprint;
          event.detail.widget.associateCustomKey(
            entity.entityId,
            veevaNetworkFingerprint,
            entity.entityType,
            data[0]?.contactid,
            veevaNetworkFingerprint
          );
        }
        loading.dismiss();
        if (!data[0].contactid) this.notificationService.notify(this.translate.instant('ERROR_CREATING_VEEVA_CONTACT'), 'Contact List', 'top', ToastStyle.DANGER, 3000);
        else {
          const newContacts = await this.contactService.fetchContactsForConfiguredDisplay(false, false, data[0].contactid);
          const createdContact = new Contact(newContacts[0]);
          if (veevaIndex >= 0) {
            this.notificationService.notify( this.translate.instant('CONTACT_UPDATE_CREATED'), "Contact List", "top", ToastStyle.INFO, 3000);
          } else {
            this.notificationService.notify( this.translate.instant('CONTACT_NEW_CREATED'), "Contact List", "top", ToastStyle.INFO, 3000);
            this.newContacts.push(createdContact);
            this.contactsToDisplay.push(createdContact);
            this.selectionEligibleContacts.push(createdContact);
            this.nonGuestContacts.push(createdContact);
            this.searchContactsList();
            createdContact.isSelected = true;
            this.selectedContacts.push(createdContact);
            if (this.activityService.selectedActivity) {
              this.enableDisableDoneButton();
            }
          }
        }
      } catch(err) {
        loading.dismiss();
        this.notificationService.notify(this.translate.instant('ERROR_CREATING_VEEVA_CONTACT'), 'Contact List', 'top', ToastStyle.DANGER, 3000);
      }
    } else {
      loading.present();
      try {
        let data = await this.accountDataService.createVeevaAccount(entity.entity);
        if (data[0]?.accountid && event.detail.taskId) {
          await this.dynamics.dwa.create({
            omniveev_externalid: event.detail.taskId,
            omniveev_type: 200000000,
            'omniveev_customer_account@odata.bind': `/accounts(${data[0]?.accountid})`
          }, "omniveev_datachangerequests");
        }
        // define alternative custom key format
        if (data[0]?.accountid && event.detail.widget?.associateCustomKey) {
          // define alternative custom key format
          const veevaNetworkFingerprint = this.veevaWidgetService.config.veevaNetworkFingerprint;
          event.detail.widget.associateCustomKey(
            entity.entityId,
            veevaNetworkFingerprint,
            entity.entityType,
            data[0]?.accountid,
            veevaNetworkFingerprint
          );
        }
        console.log(data)
        loading.dismiss();
        if (!data[0].accountid) this.notificationService.notify(this.translate.instant('ERROR_CREATING_VEEVA_ACCOUNT'), 'Contact List', 'top', ToastStyle.DANGER, 3000);
        else {
          let rawDetails = await this.accountDataService.getRealTimeAccountDetailsOnline(data[0].accountid);
          if (rawDetails) {
            await this.accountService.addUpdateRawAccountDetails(rawDetails);
            let newAccount: Account = this.accountService.getAccountById(data[0].accountid);
            this.notificationService.notify( this.translate.instant('NEW_ACCOUNT_CREATED'), "Contact List", "top", ToastStyle.INFO, 3000);
          }
        }
      } catch(err) {
        loading.dismiss();
        this.notificationService.notify(this.translate.instant('ERROR_CREATING_VEEVA_ACCOUNT'), 'Contact List', 'top', ToastStyle.DANGER, 3000);
      }
    }
  }

  resetOneKeySearch() {
    this.initContactsHeaderLeft();
    this.onekeyApiService.oneKeyContacts.length = 0;
    this.contactService.selectedOKContact.next(null);
    this.contactService.selectedOKContacts.length = 0;
    this.searchedByOKID = false;
    this.onekeyApiService.okSearchResult.next([]);
    this.contactService.isOneKeySearching = false;
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ContactPageComponent);
  }

  private addLinkEntitiesForContact(linkEntity) {
    if (_.isEmpty(linkEntity))
      this.linkEntitiesReqPayload = [];
    else {
      const linkEntityPayload = {
        "values": linkEntity["values"],
        "entity": linkEntity["entity"]
      }
      this.linkEntitiesReqPayload.push(linkEntityPayload);
    }
  }


  showSelectOptionForTag(){
    this.cancelTagSelection();
    this.showUserContactTag = true;
  }

  cancelTagSelection() {
    if (this.slicedSearchedContactsToDisplay?.length) {
      this.slicedSearchedContactsToDisplay.forEach((contact) => {
        contact.isSelectedForTag = false;
      })
    }
    if (this.showRemoveFromTag || this.isClickedTagSelectBtn) {
      if (this.showRemoveFromTag) this.showRemoveFromTag = false;
      if (this.isClickedTagSelectBtn) this.isClickedTagSelectBtn = false;
      this.selectedContactsFromTag.forEach((contact) => {
        contact.isSelectedForTag = false;
      })
      this.disableAddToTag = !this.selectedContactsFromTag.some((contact) => contact.isSelectedForTag);
    } else {
      if (!_.isEmpty(this.selectedSuggestionsData) && this.selectedTagData) {
        this.selectedSuggestionsData = this.selectedSuggestionsData.filter(data => data.selectedFacet != this.selectedTagData.indskr_name);
      }
      this.selectedTagData = null;
      this.showUserContactTag = false;
      this.selectedExistingTag = false;
      this.disableAddToTag = true;
      this.showRemoveFromTag = false;
      this.showAndRemoveTagContacts = false;
      this.selectedContactsFromTag = [];
      this.contactsToDisplay.forEach((contact) => {
        contact.isSelectedForTag = false;
      })
    }
  }

  // async addToTag() {
  //   this.selectedExistingTag = true;
  //   this.tagListPopover = await this.popover
  //     .create({
  //       component: PopoverComponent,
  //       componentProps: {
  //         contactTags: [..._.cloneDeep(this.contactService.contactTags)],
  //         field: "contact_tags"
  //       },
  //       cssClass : 'add_to_tag',
  //       event: event
  //     });
  //   this.tagListPopover.present();
  //   this.tagListPopover.onDidDismiss().then(async (tag: { data: UserTagForContact }) => {
  //     if (tag.data) {
  //       if (tag.data.indskr_usertagid === "CREATE_NEW_TAG") {
  //         this.createNewTagAlert();
  //       } else { // clicked on exiting tag name
  //         this.updateTagWithSelectedContacts(tag.data);
  //       }
  //     }

  //     console.log("tag", tag);

  //   });
  // }


  async addToTag() {
    let filteredTagData: UserTagForContact[] = !_.isEmpty(this.contactService.contactTags) ? _.cloneDeep(this.contactService.contactTags).filter(tag => tag.visibility == TagVisibility.PERSONAL) : [];
    let tagDataForModifiedTagFlag: UserTagForContact[] = !_.isEmpty(this.contactService.contactTags) ? _.cloneDeep(this.contactService.contactTags).filter(tag => tag.allowUserToModifytag == true) : [];
    filteredTagData = _.concat(filteredTagData,tagDataForModifiedTagFlag);
    if (!_.isEmpty(filteredTagData)) {
      filteredTagData.sort((a, b): number => {
        if (a.indskr_name > b.indskr_name) return 1;
        if (a.indskr_name < b.indskr_name) return -1;
        return 0;
      });
    }
    
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('CUSTOMER_TAGS'),
      dividerTitle: this.translate.instant('ALL_CUSTOMER_TAGS'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      hideAllItemsList: false,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleContactTagsComponentEvent(data, eventTarget),
      searchHandler: (text: string) => this._handleContactTagsSearch(text, filteredTagData),
      data: filteredTagData.map(tag => {
        let isSelected = false;
        return {
          id: tag.indskr_usertagid,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: true,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: tag.indskr_name,
          showArrow: false,
          arrowType: '',
        };
      }),
    };

    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isGroupedView: true }, PageName.MainToolTemplateComponent);
  }

  private _handleContactTagsSearch(text: string, contactTags): string[] {
    let ids: Array<string> = [];
    if (text.length >= 1) {
      ids = contactTags && contactTags.filter(tag => {
        return tag.indskr_name.trim().toLowerCase().includes(text.trim().toLowerCase());
      }).map(tag => tag.indskr_usertagid);
    } else {
      ids = contactTags && contactTags.map(tag => tag.indskr_usertagid);
    }
    return ids;
  }

  private async _handleContactTagsComponentEvent(data: any, eventTarget: string) {
    if (!eventTarget || eventTarget !== 'RightHeaderButtonClick' || !data.selectedItems || data.selectedItems.length === 0) return;
    const selectedTag = this.contactService.contactTags.find((tag) => tag.indskr_usertagid === data.selectedItems[0].id);
    if (!selectedTag) return;
    this.updateTagWithSelectedContacts(selectedTag);
  }

  createNewTagAlert(failedText?: string) {
    this.alertService.showAlert({
      title: this.translate.instant("CREATE_TAG"),
      subTitle: failedText ? failedText : "",
      cssClass:'saveSearchAlert',
      inputs: [{ type: "text", name: "name", placeholder: this.translate.instant("ENTER_TAG_NAME") }]
    }, this.translate.instant("CREATE"), this.translate.instant("CANCEL"))
      .then(res => {
        if (res.role == "ok") {
          if (res.data && res.data.values.name) {
            let selectedContactsForTag = this.contactsToDisplay.filter((contact) => contact.isSelectedForTag);
            if(this.slicedSearchedContactsToDisplay?.length) {
              selectedContactsForTag = this.slicedSearchedContactsToDisplay.filter((contact) => contact.isSelectedForTag);
            }
            console.log("createNewTagAlert res", res, selectedContactsForTag);
            const selectedContacts = selectedContactsForTag.map(contact => {
              return new ContactTag(contact.ID);
            });
            let payload = new
            UserTagForContact(null, null, false,
              res.data.values.name, selectedContacts,
              false , CustomerTagStateCode.Active,TagVisibility.PERSONAL);
            this.contactService.createOrUpdateContactTag(payload, true).then((reponse) => {
              if(reponse.length) {
                payload.indskr_usertagid = reponse[0].indskr_usertagid;
                payload.indskr_externalid = reponse[0].indskr_externalid;
              }
              this.contactService.contactTags.push(payload);
              this.contactService.contactTags =  _.sortBy(this.contactService.contactTags , "indskr_name")
              this.cancelTagSelection();
              console.log("createNewTagAlert response", reponse);
            }).catch(error => {
              console.log("createNewTagAlert error ", error);
            });
          } else {
            this.createNewTagAlert(this.translate.instant("TAG_NAME_IS_REQUIRED"));
          }
        }
      });
  }

  updateTagWithSelectedContacts(tagData: UserTagForContact) {
    let selectedContactsForTag = this.contactsToDisplay.filter((contact) => contact.isSelectedForTag);
    if(this.slicedSearchedContactsToDisplay?.length) {
      selectedContactsForTag = this.slicedSearchedContactsToDisplay.filter((contact) => contact.isSelectedForTag);
    }
    const selectedContacts = selectedContactsForTag.map(contact => {
      return new ContactTag(contact.ID);
    });
    let differenceBySelectedContacts = _.differenceBy(selectedContacts, tagData.contacts, "contactId");
    tagData.contacts.push(...differenceBySelectedContacts);
    this.contactService.createOrUpdateContactTag(tagData).then((response) => {
     // this.handleTagSelection(tagData);
      this.contactService.contactTags.find(tag => tag.indskr_usertagid === tagData.indskr_usertagid).contacts = tagData.contacts;
      this.cancelTagSelection();
    }).catch((error) => {
      console.log("updateTagWithSelectedContacts error", error);
    })

  }

  async selectContactsFromTag(selectedTagData: UserTagForContact) {
    this.isClickedTagSelectBtn = true;
    if (this.uiService.contactDetailsSegment == "customer-assess" && this.customerAssessService.isAssessmentDirty && !(await this.customerAssessService.discardChagesForAssess())) return;
    this.showRemoveFromTag = true;
    if (selectedTagData) {
      this.showRemoveFromTag = selectedTagData.visibility == TagVisibility.PERSONAL;
    }
  }

  public handleTagSelection(tagData: UserTagForContact) {
    if (!tagData) return;
    this.isClickedTagSelectBtn = false;
    this.showUserContactTag = true;
    this.showAndRemoveTagContacts = true;
    this.suggestionsActive = false;
    this.showRemoveFromTag = tagData.visibility == TagVisibility.PERSONAL;
    this.selectedContactsFromTag = [];
    this.searchedContactsToDisplay = [];
    this.slicedSearchedContactsToDisplay = [];
    if (this.contactService.accessedContactListFrom === PageName.NewSampleActivityComponent &&  !_.isEmpty(this.selectionEligibleContacts)) {
      tagData.contacts.forEach(contractIdData => {
        this.selectionEligibleContacts.forEach(selectionEligibleContact => {
          if (contractIdData.contactId == selectionEligibleContact.ID) {
            let contact = this.contactService.getContactByID(contractIdData.contactId);
            if (contact && contact.isActive) {
              contact.isSelectedForTag = false;
              if ((((this.searching || this.filtering) && this.searchedContactsToDisplay.includes(contact))) || !this.searching || !this.filtering) {
                this.selectedContactsFromTag.push(contact);
              }
            }
          }
        });
      });
    } else {
      tagData.contacts.forEach(contractIdData => {
        let contact = this.contactService.getContactByID(contractIdData.contactId);
        if (contact && contact.isActive) {
          contact.isSelectedForTag = false;
          if ((((this.searching || this.filtering) && this.searchedContactsToDisplay.includes(contact))) || !this.searching || !this.filtering) {
            if (!this.selectedContactsFromTag.some(con => con.ID == contractIdData.contactId)) {
            this.selectedContactsFromTag.push(contact);
            }
          }
        }
      });
    }
    const selectedPill: SelectedSuggestionPillDataModel = this.getTagSuggestionPill(tagData);
    //Only one tag can be filtered at a time
    this.selectedSuggestionsData = this.selectedSuggestionsData.filter(pill => pill.categoryName !== selectedPill.categoryName);
    this.selectedSuggestionsData.push(selectedPill);
    this.selectedTagData = _.cloneDeep(tagData);
    this.searchedContactsToDisplay = this.selectedContactsFromTag;
  }

  public handleListPeriodTagSelection(selectedListPeriodTag: string) {
    this.suggestionsActive = false;
    this.selectedSuggestionsData = [];
    this.searching = true;
    // this.searchContactsList();
    this.selectedSuggestionsData = [{
      selectedFacet: selectedListPeriodTag,
      categoryPath: 'listTag',
      categoryName: 'listTag',
      categoryDisplayName: 'listTag',
    }]
    if(this.selectedFilterData?.length > 0) {
      this.searchContactsList(true);
    }else{
      this.searchedContactsToDisplay = this.getContacts().filter(contact => contact.tagLabels.includes(selectedListPeriodTag));
      if(this.territoryService.currentListPeriod.tagLabel == selectedListPeriodTag)this.targetContactList = this.searchedContactsToDisplay.slice();
    }
    this.sortContactsListBasedOnSelection(this.sortBy);
    this.formatToDisplaySearchedContacts();
  }

  private getTagSuggestionPill(tagData: UserTagForContact): SelectedSuggestionPillDataModel {
    return {
      selectedFacet: tagData.indskr_name,
      categoryPath: 'tag',
      categoryName: 'tag',
      categoryDisplayName: 'tag',
    };
  }

  private getAssessmentPill(): SelectedSuggestionPillDataModel {
    return {
      selectedFacet: this.translate.instant("ASSESSED"),
      categoryPath: 'Assessed',
      categoryName: 'Assessed',
      categoryDisplayName: 'Assessed'
    };
  }

  private getUnAssessmentPill(): SelectedSuggestionPillDataModel {
    return {
      selectedFacet: this.translate.instant("UNASSESSED"),
      categoryPath: 'UnAssessed',
      categoryName: 'UnAssessed',
      categoryDisplayName: 'UnAssessed'
    };
  }

  removeContactsFromTag(selectedTagData: UserTagForContact) {
    if (selectedTagData) {
      if (selectedTagData.visibility != TagVisibility.PERSONAL) return;
      this.selectedContactsFromTag.forEach(contact => {
        selectedTagData.contacts.find(selectedTagDataContact => selectedTagDataContact.contactId === contact.ID).deleted = contact.isSelectedForTag
      })
    }
    this.contactService.createOrUpdateContactTag(selectedTagData).then((response) => {
      if (this.selectedContactsFromTag.length) {
        this.selectedContactsFromTag = this.selectedContactsFromTag.filter(contact => !contact.isSelectedForTag);
        selectedTagData.contacts = this.selectedContactsFromTag.map(tag => new ContactTag(tag.ID, false));
      }

      this.contactService.contactTags.find(tag => tag.indskr_usertagid === selectedTagData.indskr_usertagid).contacts = selectedTagData.contacts;
      this.cancelTagSelection();
    }).catch((error) => {
      console.log(" removeFromTag error", error);
    })
  }

  checkContactForTag(event, checkedContact) {
      let tempContackArray: Array<Contact>;
      if (this.selectedContactsFromTag?.length) {
        tempContackArray = this.selectedContactsFromTag
      } else if (this.slicedSearchedContactsToDisplay?.length) {
        tempContackArray = this.slicedSearchedContactsToDisplay
      } else if (this.contactsToDisplay?.length) {
        tempContackArray = this.contactsToDisplay;
      }
      if (tempContackArray?.length) {
        this.disableAddToTag = !tempContackArray.some((contact) => contact.isSelectedForTag)
      }
  }

  refreshPlanToDO() {
    if (this.agendaFooterService.actSegment === 'agenda' && this.agendaFooterService.planTabSegment === 'planTabTodo') {
      this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity);
    }
  }
  private _resetFlagOpenedAffiliatedData() {
    this.dynamicFormsService.isOpenedAffiliatedContactOnAccount = false;
    this.dynamicFormsService.isOpenedAffiliatedAccountOnAccount = false;
    this.dynamicFormsService.isOpenedAffiliatedAccountOnContact = false;
    this.dynamicFormsService.isOpenedAffiliatedContactOnContact = false;
    this.dynamicFormsService.openedUnmappedData = false;
    this.accountService.isEnableGoToAccountsTool = false;
    this.contactService.isEnableGoToContactsTool = false;
    this.uiService.isForcedHideGoToToolBtn = false;
  }



  async submitAllForApproval() {
    try {
      let eventActivity = this.eventsToolService.selectedEventOnEventsTool;
      if (!eventActivity) return;
      await this.uiService.displayLoader();
      let pendingRequests = [];
      for (let contact of this.selectedContacts) {
        let indskr_reasons = EventRegistrationStatus.Proposed;
        let eventParticipant = eventActivity.participants?.find(participant => participant.id === contact.ID);
        let indskr_justification = eventParticipant?.indskr_justification;
        let statecode = EventParticipantStatusCode.FOR_REVIEW
        if (eventParticipant) {
          if (eventParticipant.indskr_reasons != EventRegistrationStatus.Draft) continue;
          pendingRequests.push(this.dynamics.dwa.update(eventParticipant.registrationId, 'msevtmgt_eventregistrations', { indskr_reasons, indskr_justification: eventParticipant.indskr_justification })
            .then(((eventParticipant) => () => {
              eventParticipant.indskr_reasons = indskr_reasons;
              eventParticipant.indskr_justification = indskr_justification;
            })(eventParticipant)));
        } else {
          const details = this.eventsToolService.getPendingParticipantDetails(contact.ID);
          if (!details) {
            let eventregistration_statuscode = EventParticipantStatusCode.FOR_REVIEW;
            this.eventsToolService.createPendingParticipantDetails(contact.ID, { notes: [], indskr_reasons, indskr_justification, eventregistration_statuscode});
          } else if (details.indskr_reasons == EventRegistrationStatus.Draft) {
            details.indskr_reasons = EventRegistrationStatus.Proposed;
            details.indskr_justification = indskr_justification;
            details.eventregistration_statuscode = EventParticipantStatusCode.FOR_REVIEW;
          }
        }
      }
      await Promise.all(pendingRequests);
      if (pendingRequests.length) {
        this.eventsToolDataService.updateEventsData(
          {
            onDynamics: false,
            onLocalDatabase: true,
            onLocalCopy: true,
          },
          [eventActivity],
        );
      }
      this.events.publish("eventsTool:submit-for-approval:refresh");
    }
    finally {
      this.toast.notify(`${this.translate.instant("NOTIFICATION_PARTICIPANT_FOR_APPROVAL_BY_USING_SUBMIT_ALL")}`, "");
      this.getEventRegistrationStatus();
      this.uiService.dismissLoader();
    }
  }

  public async openAssessment() {
    let selectedContactsForTag = this.contactsToDisplay.filter((contact) => contact.isSelectedForTag);
    this.customerAssessService.assessRawData = [];
    this.customerAssessService.isAssessmentLoading = false;
    this.templates = this.customerAssessService.getTemplatesForEntity(TemplateType.ASSESSMENT, EntityOptions.CONTACT);
    if (!_.isEmpty(this.selectedContactsFromTag)) {
      selectedContactsForTag = this.selectedContactsFromTag.filter((contact) => contact.isSelectedForTag);
    }
    if(this.slicedSearchedContactsToDisplay?.length) {
      selectedContactsForTag = this.slicedSearchedContactsToDisplay.filter((contact) => contact.isSelectedForTag);
    }
    if(selectedContactsForTag && selectedContactsForTag.length > 25) {
      this.notificationService.notify(this.translate.instant('ASSESS_MAX_CUSTOMER', {maxNumber: '25'}), 'ContactListComponent', 'top', ToastStyle.DANGER, 3000);
      return;
    } else if(!this.device.isOffline && selectedContactsForTag && selectedContactsForTag.length) {
      await Promise.all(selectedContactsForTag.map(async (con) =>{
        if (this.templates.length == 1) {
          const isProcedureSectionEnabled: boolean = this.templates[0].indskr_metadata.Sections.some(section => section.Procedure) && con.primarySpecialty?.id != null;
          const procedures = isProcedureSectionEnabled ? this.customerAssessService.specialtyProceduresMap.get(con.primarySpecialty.id) : [];
          await this.customerAssessService.fetchContactAssessment(con.ID, this.templates[0].indskr_assessmenttemplateid).then((data: CustomerAssessment) => {
            if(!_.isEmpty(data)){
              if (isProcedureSectionEnabled && data?.responses) {
                if (_.isEmpty(procedures)) {
                  data.responses = data.responses.filter(resp => resp['indskr_procedures'] == null);
                } else {
                  const procedureIds = procedures.map(procedure => procedure.ID);
                  data.responses = data.responses.filter(resp => resp['indskr_procedures'] == null || procedureIds.includes(resp['indskr_procedures']))
                }
              }
              if(this.customerAssessService.assessRawData && this.customerAssessService.assessRawData.length>0) {
                let idx = this.customerAssessService.assessRawData.findIndex(raw => raw.indskr_customerassessmentid == data.indskr_customerassessmentid);
                if(idx>-1) this.customerAssessService.assessRawData[idx] = data;
                else this.customerAssessService.assessRawData.push(data);
              } else {
                this.customerAssessService.assessRawData.push(data);
              }
            }
          });
        } else {
          this.customerAssessService.isAssessmentLoading = true;
        }
      }))
      .then(()=>{
        this.navService.pushWithPageTracking(CustomerAssessComponent, PageName.CustomerAssessComponent, { 'from': 'ContactPageComponent', 'selectedCustomers': selectedContactsForTag, 'isMultipleUpdate': true, 'template' : this.templates, 'assessRawData': this.customerAssessService.assessRawData, 'isAssessmentLoading': this.isAssessmentLoading }, PageName.ContactPageComponent);
      });
    }
    //Offline mode - assessments with multiple contacts
    // else if(this.device.isOffline && selectedContactsForTag && selectedContactsForTag.length) {
    //   await Promise.all(selectedContactsForTag.map(async (con) =>{
    //     if (this.templates.length == 1) {
    //       const isProcedureSectionEnabled: boolean = this.templates[0].indskr_metadata.Sections.some(section => section.Procedure) && con.primarySpecialty?.id != null;
    //       const procedures = isProcedureSectionEnabled ? this.customerAssessService.specialtyProceduresMap.get(con.primarySpecialty.id) : [];
    //       await this.customerAssessService.fetchOfflineContactAssessment(con.ID).then((data: CustomerAssessment) => {
    //         if(!_.isEmpty(data)){
    //           if (isProcedureSectionEnabled && data?.responses) {
    //             if (_.isEmpty(procedures)) {
    //               data.responses = data.responses.filter(resp => resp['indskr_procedures'] == null);
    //             } else {
    //               const procedureIds = procedures.map(procedure => procedure.ID);
    //               data.responses = data.responses.filter(resp => resp['indskr_procedures'] == null || procedureIds.includes(resp['indskr_procedures']))
    //             }
    //           }
    //           if(this.customerAssessService.assessRawData && this.customerAssessService.assessRawData.length>0) {
    //             let idx = this.customerAssessService.assessRawData.findIndex(raw => raw.indskr_customerassessmentid == data.indskr_customerassessmentid);
    //             if(idx>-1) this.customerAssessService.assessRawData[idx] = data;
    //             else this.customerAssessService.assessRawData.push(data);
    //           } else {
    //             this.customerAssessService.assessRawData.push(data);
    //           }
    //         }
    //       });
    //     } else {
    //       this.customerAssessService.isAssessmentLoading = true;
    //     }
    //   }))
    //   .then(()=>{
    //     this.navService.pushWithPageTracking(CustomerAssessComponent, PageName.CustomerAssessComponent, { 'from': 'ContactPageComponent', 'selectedCustomers': selectedContactsForTag, 'isMultipleUpdate': true, 'template' : this.templates, 'assessRawData': this.customerAssessService.assessRawData, 'isAssessmentLoading': this.isAssessmentLoading }, PageName.ContactPageComponent);
    //   });
    // }
  }

  public selectLinkedEntity(selectedLinkedEntity) {
    if(selectedLinkedEntity.isDisableChkBox || this.device.isOffline) return;
    selectedLinkedEntity.isChecked = !selectedLinkedEntity.isChecked;
    if(selectedLinkedEntity.isChecked) {
      this.contactService.accountIdsToAdd.push(selectedLinkedEntity.selId);
    } else {
      if(this.contactService.accountIdsToAdd && this.contactService.accountIdsToAdd.length) {
        let idx = this.contactService.accountIdsToAdd.findIndex(aId => aId == selectedLinkedEntity.selId);
        if(idx > -1) this.contactService.accountIdsToAdd.splice(idx,1);
      }
    }
  }

  getFilterButtonBadgeCount() {
    if(_.isEmpty(this.selectedFilterData)) {
      this.contactService.selectedActivitiesFromContactFilter = [];
      this.contactService.selectedSubOptionsFromContactFilter = { meetingStatus: [] };
    }
    return this.selectedFilterData?.length;
  }

  public openKOLManagement() {
    if(this.isKOLStatusEnabled){
      this.kolStatusService.getDiseaseAreaDataOffline();
      this.kolStatusService.getMyKOLDataOffline().then(a=>{
        this.footerService.previousFooterView = this.footerService.footerForView;
        this.navService.pushWithPageTracking(KOLStatusPageComponent, PageName.KOLStatusPageComponent, {}, PageName.KOLStatusPageComponent);
      }).catch(err=> {
        console.log('Error mapping KOL tool data'+err)
      })

    }
  }

  public openMedicalInsight() {
    if(this.isMedicalInsightEnabled){
      this.medicalInsightService.getMyMedicalInsightDataOffline().then(a=>{
        this.footerService.previousFooterView = this.footerService.footerForView;
        this.navService.pushWithPageTracking(MedicalInsightPageComponent, PageName.MedicalInsightPageComponent, {}, PageName.MedicalInsightPageComponent);
      }).catch(err=> {
        console.log('Error mapping Medical Insight tool data'+err)
      })
    }
  }

  public openManageList() {
    this.navService.pushWithPageTracking(TerritoryManagementPageComponent, PageName.TerritoryManagementPageComponent, {}, PageName.TerritoryManagementPageComponent);
  }

  private _clearSubCategory(targetCategoryPath: string) {
    const idx = this.filterNameBubble.findIndex(bubble=>bubble.suggestion.some(category=>category.categoryPath == targetCategoryPath));
    if(idx > -1) this.filterNameBubble.splice(idx, 1);
    if(!_.isEmpty(this.selectedFilterData)) {
      this.selectedFilterData = this.selectedFilterData.filter(d=>d.categoryPath != targetCategoryPath);
    }
  }

  private _updateSubCategories(suggestion: any) {
    const targetCategoryPath = suggestion.categoryPath || '';
    const targetFacet = suggestion.selectedFacet || '';

    if(targetCategoryPath == "myActivitiesByContact") {
      if(targetFacet == this.translate.instant("MEETINGS")) {
        this._clearSubCategory("filterMeetingsStatus");
        this.contactService.selectedSubOptionsFromContactFilter = { meetingStatus: [] };
      }else if(targetFacet == this.translate.instant("MESSAGES")) {
        this._clearSubCategory("filterMessagesStatus");
        this._clearSubCategory("filterMessagesAttachment");
        this._clearSubCategory("filterMessagesLinkAccessed");
      }
      //Reset the date if there is no selected activity
      if(!_.isEmpty(this.selectedFilterData)) {
        const isSelectedMyActivity: boolean = this.selectedFilterData.some(d=>d.categoryPath == 'myActivitiesByContact');
        if(!isSelectedMyActivity) this.selectedDateFromFilter = {startDate: '', endDate: ''};
      }
    }else {
      if(!_.isEmpty(this.selectedFilterData)) {
        this.selectedFilterData.forEach(data=>{
          if(data.categoryPath == "myActivitiesByContact" && !_.isEmpty(data.subCategories)) {
            data.subCategories.forEach(category=>{
              if(category.id == targetCategoryPath && !_.isEmpty(category.childItems)) {
                category.childItems.map(cItem=>{
                  if(cItem.label == targetFacet) {
                    cItem.isChecked = false;
                  }
                });
              }
            });
          }
        });
      }
    }
  }
  mapForUserTag(selectedData){
    let tagIds=[];
    let copySelectedData : SelectedSuggestionPillDataModel[] = [];
    copySelectedData= selectedData.filter(x=>x.categoryName!="Tag" || x.categoryName != this.translate.instant("Tag"));
    let tagData=selectedData.filter(x=>x.categoryName=="Tag" || x.categoryName == this.translate.instant("Tag"))
    tagData.forEach(c=> {
      const foundTag = this.contactService.contactTags.find(v=>v.indskr_name==c.selectedFacet)?.contacts;
      if(foundTag) tagIds.push(foundTag);
    })
    let contactIds=tagIds.flat().map(c=>c.contactId);
    contactIds = [...new Set(contactIds)];
    if(contactIds.length<=0){
      return selectedData;
    }
    contactIds.forEach(x=>{
     let  data : SelectedSuggestionPillDataModel = {
       selectedFacet: x,
       categoryPath: "accountid",
       categoryName: "Tag",
       categoryDisplayName: "Tag"
     } ;
     data.isSubCategory = false
     copySelectedData.push(data);
    })
    return copySelectedData;
  }
  onSearchBlur(event){
    if(this.contactSearchText && this.contactSearchText.length>0){
      this.hideFilterButton = true;
    }
    else{
      this.hideFilterButton = false;
    }
  }

  public handleStakeholderValueInput(event){
    this.isDirty =true;
    this.initContactsHeaderLeft()
  }
  onDefaultFilterLoad(){    
    let toolName = this.searchConfigService.toolNames.find(o=>o.toolName=='Contacts');
    let search = this.searchConfigService.savedSearches.filter(x=>x.searchToolName == toolName.searchToolNameID ).find(x=>x.indskr_setfilter == true);         
    if(search){
      this.selectedFilterData = [];
      this.filterNameBubble = [];
      this.filtering = true;
      this.selectedFilterData = search.categoryValuePairs;
      this.selectedFilterData.forEach((data) => this._updateFilterNameBubble(data));
      this.searchContactsList();
    }
  }

  public onClickEnter(ev){
    if(ev){
      this.suggestionsActive = false;
    }
  }
  
  private getErrorMessageMappingPosition(targetEntity: string, errorMessage: any): string {
    let errorMessageStr: string = this.translate.instant(`${targetEntity}_ALREADY_SUBMITTED_FOR_APPROVAL`);
    if (errorMessage.includes('inactive')) {
      errorMessageStr = this.translate.instant(`${targetEntity}_REQUEST_INACTIVE_FOR_APPROVAL`);
    } else if (errorMessage.includes('For Review')) {
      errorMessageStr = this.translate.instant(`${targetEntity}_ALREADY_SUBMITTED_FOR_APPROVAL`);
    } else if (errorMessage.includes('Draft')) {
      errorMessageStr = this.translate.instant(`${targetEntity}_REJECTED_FOR_APPROVAL`);
    }
    return errorMessageStr;
  }
}

export interface Condition {
  categoryName: string,
  categoryRelativePath: string,
  charSearchtext: string,
  value: string,
  attributeName: string,
  parentEntity: string,
  entity: string,
  searchType: number,
  controlType?: string,
}

