import { SearchConfigService } from './../../services/search/search-config.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Endpoints } from '../../../config/endpoints.config';
import { DiskService } from '../../services/disk/disk.service';
import { CustomerSampleAllocationDTO, SampleAllocationDTO, LotDTO } from '../../models/sample-model';
import { AuthenticationService } from '../../services/authentication.service';
import { DB_SYNC_STATE_KEYS, DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { DeviceService } from '../../services/device/device.service';
import { SampleService } from '../../services/sample/sample.service';
import { CreateSampleDropRequestBody, SampleActivity } from '../../classes/activity/sample.activity.class';
import { ActivityService } from '../../services/activity/activity.service';

import { Events } from '@omni/events';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { NotificationService, ToastStyle } from '../../services/notification/notification.service';
import { DeltaService, EntityNames, EntitySyncInfo } from '../delta/delta.service';
import { UIService } from '../../services/ui/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomerLicenseDTO } from '../../classes/sampling/customer-license.class';
import { GlobalUtilityService } from '../../services/global-utility.service';
import { Contact } from '../../classes/contact/contact.class';
import { SyncFeatureCategory } from '../../enums/delta-service/delta-service.enum';
import { AllocationAdjustService } from '../../services/sample/allocation-adjust.service';
import moment from 'moment';
import { Utility } from '@omni/utility/util';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import _ from 'lodash';
import { Position } from '@omni/classes/account/child.position.class';
import { count } from 'console';
import { getBucketId } from '@omni/services/contact/contact.service';
import { AllocationProductEligibility, CustomerSampleProductEligibility } from '@omni/classes/sample/customer-sample-allocation.class';

@Injectable({
  providedIn: 'root'
})
export class SampleDataService {
    private _newDeltaSampleAllocIds: Array<string> = [];
    private _newDeltaCustSampleAllocIds: { addedOrUpdated: string[], deleted: Array<{ id: string, contactId: string }> } = { addedOrUpdated: [], deleted: [] };
    private _newDeltaLotIds: Array<string> = [];
    private _isInitialMappingDone: boolean = false;
    private _isInitialCustomerAllocationMappingDone: boolean = false;


    constructor(
        private http: HttpClient,
        private disk: DiskService,
        private deviceService: DeviceService,
        private authenticationService: AuthenticationService,
        private sampleService: SampleService,
        public activityService: ActivityService,
        public events: Events,
        public notificationService: NotificationService,
        private deltaService: DeltaService,
        private uiService: UIService,
        public searchConfigService: SearchConfigService,
        public translate:TranslateService,
        private globalUtility: GlobalUtilityService,
        private dynamics: DynamicsClientService,
        private adjustService: AllocationAdjustService
    ) {
    }

    async syncSamples(forceFullSync = false, loadFromDbOnly = false) {
        // Fetch from backend and save to DB
        //await this._fetchAndSaveSampleAllocations(forceFullSync);
        if(!this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)){
            return
        }
        this.deltaService.pushSyncEntityName(SyncFeatureCategory.orders);
        this.fetchSKUSampleUnitPrices(loadFromDbOnly);
        this.fetchAllocationProductEligibilities(loadFromDbOnly);
        this.fetchCustomerSampleProductEligibilities(forceFullSync, loadFromDbOnly);
        await this._fetchAndSaveCustomerSampleAllocations(forceFullSync, loadFromDbOnly);


        // Load all from DB. Runs only once when app is loading.
        //await this.sampleService.loadAllSampleAndCustomerAllocationsFromDBAndMap(forceFullSync);
        //await this.sampleService.loadAllContactCustomerAllocationsFromDBAndMap(forceFullSync);

        // Load newly saved delta from DB.
        //await this.sampleService.loadAndMapNewDeltaToSampleAndCustomerAllocations(this._newDeltaSampleAllocIds, this._newDeltaCustSampleAllocIds);
        // await this.sampleService.loadAndMapNewDeltaToContactCustomerAllocations(this._newDeltaCustSampleAllocIds);

        //this._newDeltaSampleAllocIds = [];
        this._newDeltaCustSampleAllocIds = { addedOrUpdated: [], deleted: [] };
        this.searchConfigService.isAllocationsMappedToContacts = false;
    }

    private fetchSKUSampleUnitPrices(loadFromDBOnly) {
      if (loadFromDBOnly || this.deviceService.isOffline) {
        this.sampleService.loadSKUSampleUnitPrice();
        return;
      };
      let fetchXML = fetchQueries.sampleUnitPricing;
      let positionString = '';
      this.authenticationService.user.positions.forEach(({ ID }) => positionString += `<value>${ID}</value>`);
      fetchXML = fetchXML.split('{positionIds}').join(positionString);
      this.dynamics.executeFetchQuery('products', fetchXML).then(resp => {
        this.sampleService.saveSKUSampleUnitPrice(resp);
      }).catch((error) => {
        console.error("Failed to fetch fetchSKUSampleUnitPrices: ", error);
      });
    }

    private async fetchAllocationProductEligibilities(loadFromDbOnly = false) {
      if (!(this.deviceService.isOffline || loadFromDbOnly)) {
        let positionString = '';
        this.authenticationService.user.positions.forEach(({ ID }) => positionString += `<value>${ID}</value>`);
        const fetchXML = fetchQueries.allocationProductEligibilities.split('{positionIds}').join(positionString);
        await this.dynamics.executeFetchQuery('products', fetchXML).then((eligibilities: AllocationProductEligibility[]) => {
          this.sampleService.saveAllocationEligibilities(eligibilities);
        }).catch((error) => {
          console.error("Failed to fetch fetchSKUSampleUnitPrices: ", error);
        });
      } else {
        await this.sampleService.loadAllocationProductEligibilities();
      }
    }

    private async fetchCustomerSampleProductEligibilities(forceFullSync = false, loadFromDbOnly = false) {
      if (!(this.deviceService.isOffline || loadFromDbOnly)) {
        const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CUST_SAMPLE_PROD_ELIGIBILITIES);
        const startDate = moment().format('YYYY-MM-DD');
        let fetchXML = fetchQueries.customerSampleProductEligibilities.split('{date}').join(startDate);
        const isInitialSync = forceFullSync || !syncState || !syncState.lastUpdatedTime ? true : new Date(syncState.lastUpdatedTime).getMonth() != new Date(startDate).getMonth();
        let positionString = '';
        this.authenticationService.user.positions.forEach(({ ID }) => positionString += `<value>${ID}</value>`);
        fetchXML = fetchXML.split('{positionIds}').join(positionString);
        if (isInitialSync) {
          fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
        } else {
          const deltaSyncFilter = '<filter type="and"><condition attribute="modifiedon" operator="eq" value="{modifedon}" /></filter>'
          .replace('{modifedon}', moment(syncState.lastUpdatedTime).format('YYYY-MM-DD'));
          fetchXML = fetchXML.replace('{deltaSyncFilter}', deltaSyncFilter);
        }
        await this.dynamics.executeFetchQuery('indskr_customersampleproducteligibilities', fetchXML).then(async (customerSampleProductEligibilities: CustomerSampleProductEligibility[]) => {
          this.sampleService.saveCustomerSampleProductEligibilities(customerSampleProductEligibilities, isInitialSync);
          syncState.lastUpdatedTime = new Date().getTime();
          await this.disk.updateSyncState(syncState);
        }).catch((error) => {
          console.error("Failed to fetch CustomerSampleProductEligibilities: ", error);
        });
      } else {
        await this.sampleService.loadCustomerSampleProductEligibilities();
      }
    }

    async syncIndividualCustomerAllocationsFromContactProfile(rawAllocations:CustomerSampleAllocationDTO[]){
        this._newDeltaCustSampleAllocIds = { addedOrUpdated: [], deleted: [] };
        await this.sampleService.saveDeltaSyncedCustomerSampleAllocations(rawAllocations, this._newDeltaCustSampleAllocIds);
        await this.sampleService.loadAndMapNewDeltaToContactCustomerAllocations(this._newDeltaCustSampleAllocIds, true, rawAllocations);
        this._newDeltaCustSampleAllocIds = { addedOrUpdated: [], deleted: [] };
        this.events.publish('Contact-Profile:refreshContactSampleEligibilities')
    }

  private async _fetchAndSaveCustomerSampleAllocations(forceFullSync = false, loadFromDbOnly = false) {

    if (!(this.deviceService.isOffline || loadFromDbOnly)) {

      let syncStatesFromDB = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CUST_SAMPLE_ALLOC);

      let syncStates = syncStatesFromDB.syncStates

      const startDate = new Date().getTime();
      const endDate = new Date(moment(new Date()).add(1, 'month').endOf('month').format('YYYY-MM-DD')).getTime();

      let mypositions = this.authenticationService.user.positions.map(position => position.ID)
      const childPositions = this.authenticationService.user.childPositions.map(position => position.childPositionId)

      if (childPositions && !_.isEmpty(childPositions)) {
        mypositions = mypositions.concat(childPositions)
      }

      const allocationSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.sampleProduct,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };

      let errors = []

      let positionWiseResponses = {}

      if (mypositions.length > 0) {

        for (let positionIndex = 0; positionIndex < mypositions.length; positionIndex++) {
          const position = mypositions[positionIndex];

          let syncState

          const newLastUpdatedTime = new Date().getTime();

          if (syncStates && syncStates.length > 0) {
            syncState = syncStates.find(syncState => syncState.positionID == position)
          }

          const isInitialSync = (!syncStatesFromDB.syncStates || _.isEmpty(syncStatesFromDB.syncStates)) || (!syncState || (syncState && syncState.lastUpdatedTime ? new Date(syncState.lastUpdatedTime).getMonth() != new Date(newLastUpdatedTime).getMonth() : !syncState.lastUpdatedTime))

          const doFullSync = forceFullSync || isInitialSync;

          let url = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.sample.CUSTOMER_SAMPLE_ALLOCATIONS.replace('{{positionIDs}}', position)
            .replace('{{startDate}}', startDate.toString())
            .replace('{{endDate}}', endDate.toString());

          url = !doFullSync ? url + '&lastUpdatedTime=' + syncState.lastUpdatedTime : url;

          let sampleUnitPriceForSKUs;

          try {
            let response = await this.http.get<CustomerSampleAllocationDTO[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
            syncStates = this.updateSyncState(allocationSyncInfo, syncState, newLastUpdatedTime, syncStates ? syncStates : [], position);
            if (response && _.isArray(response)) {
              allocationSyncInfo.totalSynced += response.length
              // completeResponse =  _.concat(completeResponse, response)
              positionWiseResponses[position] = response
            }
          } catch (error) {
            console.error('_fetchAndSaveCustomerSampleAllocations: ', error);
            //allocationSyncInfo.errorMessage = '[sampleAllocation][error]' + error ? error.errorMessage : '';
            this.deltaService.addSyncErrorToEntitySyncInfo(allocationSyncInfo, url, error);
            syncStates = this.updateSyncState(allocationSyncInfo, syncState, newLastUpdatedTime, syncStates ? syncStates : [], position);
          }
        }
      }

      //positionID-> response 
      //if there is no sync state in that case it's complete initial Sync 
      //1. Do compacting
      //2. save that as an initial sync if all the Sync States are empty 

      let completeResponseArray: CustomerSampleAllocationDTO[] = [];

      mypositions.forEach(position => {
        completeResponseArray = completeResponseArray.concat(positionWiseResponses[position])
      })
      let completeUniqueResponseArray = _.uniqBy(completeResponseArray, 'indskr_customersampleproductid')

      if (!syncStatesFromDB.syncStates || _.isEmpty(syncStatesFromDB.syncStates)) {// Condition for Initial Sync
        await this.sampleService.saveFullSyncedCustomerSampleAllocations(completeUniqueResponseArray, forceFullSync);
        this._isInitialCustomerAllocationMappingDone = true;

      } else {
        if (!this._isInitialCustomerAllocationMappingDone) {
          await this.sampleService.loadAllContactCustomerAllocationsFromDBAndMap([], true);
          this._isInitialCustomerAllocationMappingDone = true;
        }
        if (!_.isEmpty(completeUniqueResponseArray)) {
          this._newDeltaCustSampleAllocIds = { addedOrUpdated: [], deleted: [] };
          await this.sampleService.saveDeltaSyncedCustomerSampleAllocations(completeUniqueResponseArray, this._newDeltaCustSampleAllocIds);
          await this.sampleService.loadAndMapNewDeltaToContactCustomerAllocations(this._newDeltaCustSampleAllocIds, false, completeResponseArray);
        }
      }
      syncStatesFromDB.syncStates = syncStates
      await this.disk.updateSyncState(syncStatesFromDB);

      this.deltaService.addEntitySyncInfo(allocationSyncInfo);
    } else {
      await this.sampleService.loadAllContactCustomerAllocationsFromDBAndMap([], true);
    }
  }

  private updateSyncState(allocationSyncInfo: EntitySyncInfo, syncState: any, newLastUpdatedTime: number, syncStatesFromDB: any, positionID: string) {
    if (allocationSyncInfo.syncStatus) {

      if (!syncState) {syncState = {lastUpdatedTime: newLastUpdatedTime, positionID: positionID}}
      else syncState.lastUpdatedTime = newLastUpdatedTime;

      if (syncStatesFromDB && _.isArray(syncStatesFromDB) && !_.isEmpty(syncStatesFromDB)){
        const index =  syncStatesFromDB.findIndex(syncState => syncState.positionID == positionID)
        if (index > -1) { syncStatesFromDB[index] = syncState; } else syncStatesFromDB.push(syncState);
      } else {
        syncStatesFromDB.push(syncState)
      }
    }
    return syncStatesFromDB;
  }

    async syncLots(dataRange: { from: string, to: string }, forceFullSync = false, loadFromDbOnly = false) {
        if(!this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)){
            return
        }
        // Fetch from backend and save to DB
        await this._fetchAndSaveLots(dataRange, forceFullSync, loadFromDbOnly);

        // Load all from DB. Runs only once when app is loading.
        await this.sampleService.loadAllLotsFromDBAndMap(forceFullSync);
        await this.adjustService.loadAllLotsFromDBAndMap(forceFullSync)

        // Load newly saved delta from DB.
        // await this.sampleService.loadAndMapNewDeltaToLots(this._newDeltaLotIds);
        // await this.adjustService.loadAndMapNewDeltaToLots(this._newDeltaLotIds);

        this._newDeltaLotIds = [];
    }

    private async _fetchAndSaveLots(dataRange: { from: string, to: string }, forceFullSync = false, loadFromDbOnly = false) {
        if (!this.deviceService.isOffline && !loadFromDbOnly) {
            const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_LOT);
            const isInitialSync = !syncState || !syncState.lastUpdatedTime;
            const doFullSync = forceFullSync || isInitialSync;
            const newLastUpdatedTime = new Date().getTime();

            const lotSyncInfo: EntitySyncInfo = {
                entityName: EntityNames.lot,
                totalFailed: 0,
                totalSynced: 0,
                errors: [],
                syncStatus: true
            };

            let url = this.authenticationService.userConfig.activeInstance.entryPointUrl +
                Endpoints.sample.LOTS;

            url = url.replace('{{startDate}}', dataRange.from);
            url = url.replace('{{positionIDs}}', this.authenticationService.getLoggedInUserPositions().toString());
            url = this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TRANSFER) ? url + '&features=allocation_transfer' : url;
            url = !doFullSync ? url + '&lastUpdatedTime=' + syncState.lastUpdatedTime : url;

            try {
                const response: LotDTO[] = await this.http.get<LotDTO[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();

                if (response && Array.isArray(response)) {
                    if (doFullSync) {
                        // Full sync flow
                        if (response.length > 0) {
                            await this.sampleService.saveFullSyncedLots(response, forceFullSync);
                        }
                    } else {
                        // Delta sync flow
                        if (response.length > 0) {
                            this._newDeltaLotIds = [];
                            await this.sampleService.saveDeltaSyncedLots(response, this._newDeltaLotIds);
                        }
                    }
                    lotSyncInfo.totalSynced = response.length;
                }
                this.sampleService.lotsLastSynTime = newLastUpdatedTime;
                // Done sync. Update sync state.
                syncState.lastUpdatedTime = newLastUpdatedTime;
                await this.disk.updateSyncState(syncState);
            } catch (error) {
                console.error('syncLots: ', error);
                this.deltaService.addSyncErrorToEntitySyncInfo(lotSyncInfo, url, error);
            }

            this.deltaService.addEntitySyncInfo(lotSyncInfo);
        }
    }

    public async initiateSampleOrder(payload:CreateSampleDropRequestBody){
        //We need to save the payload in the DB/SS until we can resync with backend
        let responseActivity;

        // Manually set activity status to open
        payload['statecode'] = 0;
        payload['activitytypecode'] = 'indskr_sampledrop';
        let current = new Date();
        payload['offlineActivityId'] = 'offline_sampleActivity_'+current.getTime();
        if (this.deviceService.isOffline) {
            //Create a new appointment activity with our payload.
            let offlineReqBody = payload;

            let sampleOrderActivity = new SampleActivity(
              offlineReqBody
            );
            sampleOrderActivity.ID = offlineReqBody['offlineActivityId'];
            sampleOrderActivity.ownerId = this.authenticationService.user.systemUserID;

            let rawOfflineSampleOrder = await this.disk.createOfflineSampleOrder(sampleOrderActivity);
            if(!this.activityService.hasOfflineSampleOrderData(rawOfflineSampleOrder.offlineActivityId)){
                this.activityService.addToOfflineSampleOrderIds(rawOfflineSampleOrder.offlineActivityId);
            }
            this.activityService.addActivity(sampleOrderActivity);
            if (!this.uiService.toolsActivityActive){
              this.events.publish('refreshAgenda');
            } else this.uiService.agendaRefreshRequired = true;
            responseActivity = sampleOrderActivity;
            return responseActivity
        }
        //   call the create sample order service here to create activity in dynamics
           let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.sample.CREATE_ALLOCATIONS_DROP;
           let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
           headers.headers = headers.headers.set(
             'X-BusinessUnitId',
             this.authenticationService.user.xBusinessUnitId
           ).set(
             "X-PositionId",
             this.authenticationService.user.xPositionID
           );

           await this.http
             .post(url, payload, headers)
             .toPromise()
             .then(async (response)=>{
                response['statecode'] = 0;
                response['activitytypecode'] = 'indskr_sampledrop'
                let responseAllocationActivity = new SampleActivity(response)
                await this.disk.updateOrInsert(DB_KEY_PREFIXES.SAMPLE_ACTIVITY + responseAllocationActivity.ID, (doc) => {
                    return responseAllocationActivity;
                });
                responseActivity = responseAllocationActivity;
                return
             })
             .catch(async (error)=>{
                let offlineReqBody = payload;
                // Manually set activity status to open

                let sampleOrderActivity = new SampleActivity(
                  offlineReqBody
                );
                sampleOrderActivity.ID = offlineReqBody['offlineActivityId'];
                sampleOrderActivity.ownerId = this.authenticationService.user.systemUserID;
                let rawOfflineSampleOrder = await this.disk.createOfflineSampleOrder(
                  sampleOrderActivity
                );
                if(!this.activityService.hasOfflineSampleOrderData(rawOfflineSampleOrder.offlineActivityId)){
                    this.activityService.addToOfflineSampleOrderIds(rawOfflineSampleOrder.offlineActivityId);
                }
                this.activityService.addActivity(sampleOrderActivity)
                if (!this.uiService.toolsActivityActive){
                  this.events.publish('refreshAgenda');
                } else this.uiService.agendaRefreshRequired = true;
                responseActivity = sampleOrderActivity;
                return
             })
            return responseActivity
    }

    public async updateSampleActivity(payload:CreateSampleDropRequestBody, sampleActivity?: SampleActivity, contact?: Contact):Promise<any>{
        let updatedActivity;

        if (this.deviceService.isOffline || sampleActivity.ID.includes('offline')) {
            //update local db for this activity
            this.disk.updateOrInsertActivityToActivityDetailRawDocument(sampleActivity, true)
            // let localSavedActivity =
            // let rawOfflineSampleOrder = await this.disk.createOfflineSampleOrder(
            //   sampleOrderActivity
            // );

            //add to offline update document hashmap
            let id_to_be_updated:string =  sampleActivity.offlineActivityId;
            if(!this.activityService.hasOfflineSampleOrderData(id_to_be_updated)){
                this.activityService.addToOfflineSampleOrderIds(id_to_be_updated);
                await this.disk.createOfflineSampleOrder(sampleActivity)
            }
            //update offline upload document for this activity
            else(
                this.disk.updateofflineSampleOrderDocument(sampleActivity)
            )
            //add/update to session display activities
            if(sampleActivity.state == 2){
                await this.activityService.removeActivity(sampleActivity);
                if(sampleActivity.appointmentID && sampleActivity.appointmentID.length > 0)
                    this.events.publish('deletedEmbeddedActivity', sampleActivity);
            }
            else{
                await this.activityService.addActivity(sampleActivity, true);
            }
            this.activityService.publishActivityEvent({action: "Update", activity: sampleActivity});

            if (sampleActivity.appointmentID && sampleActivity.appointmentID.length > 0 && sampleActivity.state === 1) {
                this.events.publish('updateEmbeddedActivity', sampleActivity);
            }
            // interaction for contact for completed sample order
            if(sampleActivity.status === 2){
              await this._updateInteractionForContacts(contact);
            }
            //Publish event to update the agenda list
            if (!this.uiService.toolsActivityActive){
              this.events.publish('refreshAgenda');
            } else this.uiService.agendaRefreshRequired = true;
            //responseActivity = sampleOrderActivity;
            //Publish event to update the completed allocation order in contact timeline offline mode
            if(this.deviceService.isOffline && sampleActivity.status === 2) {
              await this.activityService.publishOfflineActivityForTimeline(sampleActivity);
            }
            return // responseActivity
        }
        else{
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.sample.UPDATE_ALLOCATIONS_DROP;
            if(payload.activityid){
                url = url.replace('{{SampleActivityId}}', payload.activityid)
            }
            else{
                //add to offline update document hashmap
                if(!this.activityService.hasOfflineSampleOrderData(sampleActivity.offlineActivityId)){
                    this.activityService.addToOfflineSampleOrderIds(sampleActivity.offlineActivityId);
                    this.disk.createOfflineSampleOrder(sampleActivity)
                }
                //update offline upload document for this activity
                else(
                    this.disk.updateofflineSampleOrderDocument(sampleActivity)
                )

                //add/update to session display activities
                if(sampleActivity.state == 2){
                    await this.activityService.removeActivity(sampleActivity);
                    if(sampleActivity.appointmentID && sampleActivity.appointmentID.length > 0)
                        this.events.publish('deletedEmbeddedActivity', sampleActivity);
                }
                else{
                    await this.activityService.addActivity(sampleActivity, true);
                }

                if (sampleActivity.appointmentID && sampleActivity.appointmentID.length > 0 && sampleActivity.state === 1) {
                    this.events.publish('updateEmbeddedActivity', sampleActivity);
                }
                // interaction for contact for completed sample order
                if (sampleActivity.status === 2) {

                  await this._updateInteractionForContacts(contact);
                }
                //Publish event to update the agenda list
                if (!this.uiService.toolsActivityActive){
                  this.events.publish('refreshAgenda');
                } else this.uiService.agendaRefreshRequired = true;
                //responseActivity = sampleOrderActivity;
                return // responseActivity
            }
            let headers = Endpoints.meeting.INITIATE_MEETING_HEADERS;
            headers.headers = headers.headers.set(
             'X-BusinessUnitId',
             this.authenticationService.user.xBusinessUnitId
            ).set(
             "X-PositionId",
             this.authenticationService.user.xPositionID
            ).set(
                "X-SystemUserId",
                this.authenticationService.user.systemUserID
            );
            await this.http
             .patch(url, payload, headers)
             .toPromise()
             .then(async ()=>{
                // add to session display activities
                if(sampleActivity.state == 2){
                    this.activityService.removeActivity(sampleActivity)
                    if(sampleActivity.appointmentID && sampleActivity.appointmentID.length > 0)
                        this.events.publish('deletedEmbeddedActivity', sampleActivity);
                }
                else{
                    await this.activityService.addActivity(sampleActivity, true);
                }
                 // interaction for contact for completed sample order
                 if (sampleActivity.status === 2) {
                  await this._updateInteractionForContacts(contact);
                 }
             
                if (sampleActivity.appointmentID && sampleActivity.appointmentID.length > 0 && sampleActivity.state === 1) {
                    this.events.publish('updateEmbeddedActivity', sampleActivity);
                }
                //UPSERT LOCAL DISK RAW ACTIVITY
                this.disk.updateOrInsertActivityToActivityDetailRawDocument(sampleActivity, true)
                //publish event to update agenda list
                this.activityService.publishActivityEvent({action: "Update", activity: sampleActivity});
                if (!this.uiService.toolsActivityActive)
                {
                  this.events.publish("refreshAgenda");
                }
                else {
                  this.uiService.agendaRefreshRequired = true;
                }
              return;
             })
             .catch(async (error)=>{
                 let errorInfo = (error.hasOwnProperty('error')) ? error.error : error;
                if (errorInfo.errorCode == 'ERR_IO_SR01' || errorInfo.errorCode == 'ERR_IO_SR07') {
                    //for some reason, limits have exceeded for one of the SKUs in this sample activity
                    this.notificationService.notify(this.translate.instant('EXCEEDED_ALLOWCATION_LIMIT'),'Sample Data Service','top');
                    this.sampleService.updateSampleEligibilities(sampleActivity,errorInfo['errorDetails']);
                    throw new Error('exceededLimits');
                }
                if (errorInfo.errorCode == 'ERR_IO_SR08') {
                  this.notificationService.notify(this.translate.instant('CUSTOMER_DROP_LIMIT_EXEED_ERROR'), 'Sample Data Service', 'top', ToastStyle.DANGER);
                  throw new Error('exceededLimits');
                }
                if(errorInfo.errorCode =='ERR_IO_SR04'){
                    this.notificationService.notify(this.translate.instant('ALLOWCATION_COULD_NOT_BE_COMPLETED'),'Sample Data Service','top');
                    //this.sampleService.updateSampleEligibilities(sampleActivity,errorInfo['errorDetails']);
                    throw new Error('allocation recalled');
                }
                if(errorInfo.errorCode == "ERR_IO_500"){
                    this.notificationService.notify(this.translate.instant('NETWORK_ISSUES_WHILE_COMPLETE_REQUEST'),'Sample Data Service','top',ToastStyle.DANGER);
                    throw new Error('Server Error');
                }
                this.disk.updateOrInsertActivityToActivityDetailRawDocument(sampleActivity, true)
          
                //add to offline update document hashmap
                if(!this.activityService.hasOfflineSampleOrderData(sampleActivity.offlineActivityId)){
                    this.activityService.addToOfflineSampleOrderIds(sampleActivity.offlineActivityId);
                    this.disk.createOfflineSampleOrder(sampleActivity)
                }
                //update offline upload document for this activity
                else(
                    this.disk.updateofflineSampleOrderDocument(sampleActivity)
                )

                //add/update to session display activities
                if(sampleActivity.state == 2){
                    await this.activityService.removeActivity(sampleActivity);
                    if(sampleActivity.appointmentID && sampleActivity.appointmentID.length > 0)
                        this.events.publish('deletedEmbeddedActivity', sampleActivity);
                }
                else{
                    await this.activityService.addActivity(sampleActivity, true);
                }

                if (sampleActivity.appointmentID && sampleActivity.appointmentID.length > 0 && sampleActivity.state === 1) {
                    this.events.publish('updateEmbeddedActivity', sampleActivity);
                }
                //Publish event to update the agenda list
                this.activityService.publishActivityEvent({action: "Update", activity: sampleActivity});
                if (!this.uiService.toolsActivityActive){
                  this.events.publish('refreshAgenda');
                } else this.uiService.agendaRefreshRequired = true;
                return // responseActivity
             })
             return updatedActivity
        }
    }

    /**
 * Updates the interaction information for a specific contact in the local database.
 * 
 * This method performs the following steps:
 * 1. Determines the appropriate bucket ID for the contact using the contact's ID and the number of buckets.
 * 2. Constructs the database key for storing the contact data by prefixing with the appropriate key.
 * 3. Retrieves the existing contacts data from the local storage using the generated database key.
 * 4. If data exists and is an array, it assigns it to `dbContacts`; otherwise, initializes `dbContacts` as an empty array.
 * 5. Updates the interaction information of the provided contact within the `dbContacts` array, specifically updating the 'Allocations' field.
 * 6. If `dbContacts` is not empty after the update, it stores the updated contact information back in the local storage by either updating or inserting the document with the modified contact list.
 * 
 * @param contact - The contact object that needs to be updated with new interaction information.
 * @returns Promise<void>
 */
  private async _updateInteractionForContacts(contact: Contact) {
    const bucketID = getBucketId(contact.ID, this.authenticationService.get_num_buckets());

    const dbKey = Utility.getDBBucketedKey(DB_KEY_PREFIXES.CONTACT, bucketID);

    let dbContacts = [];

    let rawContactsDoc: any = await this.disk.retrieve(dbKey);

    if (rawContactsDoc && rawContactsDoc.raw && Array.isArray(rawContactsDoc.raw)) {

      dbContacts = rawContactsDoc.raw;
    }

    dbContacts = this.globalUtility.updateInteractionContact(contact, 'Allocations', dbContacts);

    if (!_.isEmpty(dbContacts)) {
      this.disk.updateOrInsert(dbKey, doc => ({ raw: dbContacts }));
    }
  }

  /**
 * Synchronizes customer licenses with the server or loads them from the local database, depending on the device's online status and the provided flags.
 * 
 * The method operates in two main modes:
 * 1. **Online Mode (Default):** If the device is online and `loadFromDbOnly` is `false`, it performs a synchronization of customer licenses:
 *    - **Full Sync:** If `forceFullSync` is `true` or it's the first sync (no previous sync state found), it fetches all customer licenses from the server.
 *    - **Delta Sync:** If a previous sync state exists and `forceFullSync` is `false`, it fetches only the updated customer licenses since the last sync.
 *    - The method constructs the appropriate API URL based on the sync mode (full or delta).
 *    - The retrieved data is then processed:
 *      - **Full Sync:** Maps all customer licenses from the server to the local storage.
 *      - **Delta Sync:** Compares and updates the local storage with any changes from the server.
 *    - Finally, it updates the sync state with the current timestamp and records the sync operation's details (total synced, errors, etc.).
 * 
 * 2. **Offline Mode or Load from DB:** If the device is offline or `loadFromDbOnly` is `true`, it skips the server sync and directly loads the customer licenses from the local database, mapping them for use.
 * 
 * The method handles errors during the sync process, logs them, and updates the sync information accordingly.
 * 
 * @param forceFullSync - If `true`, forces a full sync, ignoring the last sync time.
 * @param loadFromDbOnly - If `true`, skips the server sync and loads data only from the local database.
 * @returns Promise<void>
 */
    async syncCustomerLicenses(forceFullSync = false, loadFromDbOnly = false) {
        if (!(this.deviceService.isOffline || loadFromDbOnly)) {
            const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CUSTOMER_LICENSES);
            const isInitialSync = !syncState || !syncState.lastUpdatedTime;
            const doFullSync = forceFullSync || isInitialSync;
            const newLastUpdatedTime = new Date().getTime();

            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.sample.GET_CUSTOMER_LICENSES;
            url = url.replace('{{positionIDs}}',this.authenticationService.getLoggedInUserPositions().toString())
            url = !doFullSync ? url + '&lastUpdatedTime=' + syncState.lastUpdatedTime : url;

            const licenseSyncInfo: EntitySyncInfo = {
                entityName: EntityNames.customerLicense,
                totalFailed: 0,
                totalSynced: 0,
                errors: [],
                syncStatus: true
            };

            let response: CustomerLicenseDTO[];
            try {
                response = await this.http.get<CustomerLicenseDTO[]>(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
            } catch (error) {
                console.error('syncLicenses: ', error);
                this.deltaService.addSyncErrorToEntitySyncInfo(licenseSyncInfo, url, error);
            }

            if (response && Array.isArray(response)) {
                if (doFullSync) {
                    // Initial full sync flow
                    await this.sampleService.mapFullSyncedCustomerLicenses(response, newLastUpdatedTime, forceFullSync);
                    this._isInitialMappingDone = true;
                } else {
                    // Delta sync flow
                    const dbCustomerLicenses = await this.sampleService.loadCustomerLicensesFromDB();

                    if (!this._isInitialMappingDone) {
                        await this.sampleService.mapCustomerLicensesWithDbData(dbCustomerLicenses);
                        this._isInitialMappingDone = true;
                    }

                    // Map delta
                    await this.sampleService.mapDeltaSyncedCustomerLicenses(response, dbCustomerLicenses, newLastUpdatedTime);
                }

                // Done sync. Update sync state.
                if (licenseSyncInfo.syncStatus) {
                    syncState.lastUpdatedTime = newLastUpdatedTime;
                    licenseSyncInfo.totalSynced = response.length;
                    await this.disk.updateSyncState(syncState);
                }
            }

            this.deltaService.addEntitySyncInfo(licenseSyncInfo);
        } else {
            // Just load data from DB and map.
            const dbCustomerLicenses = await this.sampleService.loadCustomerLicensesFromDB();
            await this.sampleService.mapCustomerLicensesWithDbData(dbCustomerLicenses);
        }
    }
}
