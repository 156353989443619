import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Expense } from "@omni/classes/account-management/account-plan.class";
import { IONote } from "@omni/classes/io/io-note.class";
import { AccountManagementDataService } from "@omni/data-services/account-management/account-management.data.service";
import { FormFieldType, IndFormFieldViewDataModel } from "@omni/models/indFormFieldDataModel";
import { IndPageTitleViewDataModel } from "@omni/models/indPageTitleDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { AuthenticationService } from "@omni/services/authentication.service";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { DeviceService } from "@omni/services/device/device.service";
import { NavigationService } from "@omni/services/navigation/navigation.service";
import { NotificationService } from "@omni/services/notification/notification.service";
import { UIService } from "@omni/services/ui/ui.service";
import { MAXIMUM_NOTE_ATTACHMENT_SIZE, NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX, toBase64 } from "@omni/utility/util";
import { isBefore } from "date-fns";
import _ from "lodash";
import { Guid } from "typescript-guid";

@Component({
  selector: 'expenses-details',
  templateUrl: 'expenses-details.html',
  styleUrls: ['expenses-details.scss']
})
export class ExpensesDetails {
  @Input() isEditMode: boolean;
  @Input() selectedExpense: Expense;
  @Input() callbackEvent: any;
  @ViewChild('attachInput') attachInput: ElementRef;
  public pageTitle: IndPageTitleViewDataModel;
  headerData: IndSectionHeaderViewDataModel = {
    id: 'new-expenses-header',
    title: this.translate.instant('DETAILS_CAP'),
    controls: [
    ],
  };
  public descriptionFormField: IndFormFieldViewDataModel;
  public amountFormField: IndFormFieldViewDataModel;
  public isAttachmentAdded: boolean = false;
  public attachmentTitle: string = '';
  public notes: string;
  private _isClicked: { [x: string]: boolean } = {};
  public notesHeaderModel: IndSectionHeaderViewDataModel;
  private attachmentFile: any;
  private base64str: any;
  private isSaveNotesEnabled: boolean = false;

  constructor(private translate: TranslateService,
    private navService: NavigationService,
    private readonly device: DeviceService,
    private readonly dateTimeFormatsService: DateTimeFormatsService,
    private readonly notificationService: NotificationService,
    public readonly uiService: UIService,
    private readonly authService: AuthenticationService) {

  }

  ngOnInit() {
    this.initPageTitle();
    this.initNotesheader();
    this.initDescriptionFormField();
    this.initAmountFormField();
  }

  private initAmountFormField() {
    this.amountFormField = {
      label: this.translate.instant('AMOUNT'),
      inputText: this.selectedExpense?.amount ? this.dateTimeFormatsService.formattedCurrency(this.selectedExpense.amountFormatted) : '',
      inputValue: this.selectedExpense?.amount,
      id: 'amount-field',
      isDisabled: this.device.isOffline,
      placeholderLabel: this.translate.instant('AMOUNT'),
      showArrow: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      inputType: 'number',
      isRequired: true,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked['amount-field'] && this.selectedExpense?.amount == null,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

  private initDescriptionFormField() {
    this.descriptionFormField = {
      label: this.translate.instant('DESCRIPTION'),
      inputText: this.selectedExpense?.description,
      inputValue: this.selectedExpense?.description,
      id: 'description-field',
      isDisabled: this.device.isOffline,
      placeholderLabel: this.translate.instant('DESCRIPTION'),
      showArrow: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: true,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked['description-field'] && _.isEmpty(this.selectedExpense?.description),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

  private initPageTitle() {
    this.pageTitle = {
      id: 'expenses-page-title',
      title: this.translate.instant("EXPENSE"),
      displayDefaultBackButton: true,
      controls: [
        {
          id: 'scrap',
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('SCRAP'),
          isDisabled: false,
          align: 'right'
        },
        {
          id: 'save',
          imgSrc: 'assets/imgs/header_save.svg',
          name: this.translate.instant('SAVE'),
          isDisabled: true,
          align: 'right'
        }],
    };
  }

  private initNotesheader() {
    let controls = [
      {
        id: "attach",
        text: this.translate.instant('ATTACH'),
        isDisabled: this.device.isOffline,
        isVisible: true,
      },
      {
        id: 'save-notes',
        text: this.translate.instant('SAVE'),
        isDisabled: !this.isSaveNotesEnabled,
        isVisible: true,
      }
    ]

    this.notesHeaderModel = {
      id: 'notes-header',
      title: this.translate.instant('NOTES'),
      controls: controls
    };
  }

  public async onPageTitleControlClick(id: string) {
    if (this.device.isOffline) return;
    switch (id) {
      case "save":
        this.saveExpenseDetails();
        break;
      case "close":
        this.closePage();
        break;
      case "scrap":
        this.scrapExpense();
        break;
      case "attach":
        this.handleNoteAttachment();
        break;
      case "save-notes":
        this.handleNotesSave();
        break;
      default:
        console.log("Unhandled case");
    }
  }

  private handleNotesSave() {
    if (this.isSaveNotesEnabled && ((this.notes && !_.isEmpty(this.notes)) || !_.isEmpty(this.base64str))) {
      this.isSaveNotesEnabled = false;
      this.initNotesheader();
      let tempNote = new IONote({
        annotationid: Guid.create().toString(),
        createdon: new Date().getTime(),
        notetext: this.notes,
        ownerName: this.authService.user.displayName,
        ownerid: this.authService.user.systemUserID,
      });
      if (this.base64str) {
        tempNote['hasDocument'] = true;
        tempNote['documentDataURL'] = this.base64str;
        tempNote['documentName'] = this.attachmentFile.name;
        tempNote['documentSize'] = this.attachmentFile.size;
        tempNote['documentMimeType'] = this.attachmentFile.type;
      } else {
        tempNote['hasDocument'] = false;
      }
      if (!this.selectedExpense.notes) {
        this.selectedExpense.notes = [];
      }
      this.selectedExpense.notes.push(tempNote);
      this.removeAttachment(null);
      this.notes = '';
      this.updateSaveBtn();
      this._sortNotes();
    }
  }

  private _sortNotes(): void {
    if (this.selectedExpense.notes && this.selectedExpense.notes.length > 1) {
      this.selectedExpense.notes.sort((a, b) => {
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1)
      })
    }
  }

  get nonDeletedNotes() {
    return this.selectedExpense?.notes?.filter(note => !note.isDeleted)
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (this.device.isOffline) return;
    switch (id) {
      case 'description-field':
        if (eventName && eventName == 'input_value_confirm') {
          this.handleDescriptionField(event);
        }
        break;
      case 'amount-field':
        if (eventName && eventName == 'input_value_confirm') {
          this.handleAmountField(event);
        }
        break;
    }
    this._isClicked[id] = true;
  }

  private handleNoteAttachment() {
    try {
      this.attachInput.nativeElement.click();
    } catch (error) {
      console.log(error);
    }
  }

  private handleDescriptionField(event) {
    if (event && event.target.value !== undefined && this.selectedExpense?.description != event.target.value) {
      this.selectedExpense.description = event.target.value;
      this.initDescriptionFormField();
      this.updateSaveBtn();
    }
  }

  private handleAmountField(event) {
    if (event && event.target.value !== undefined && this.selectedExpense?.amount != event.target.value) {
      this.selectedExpense.amount = isNaN(parseInt(event.target.value)) ? null : parseInt(event.target.value);
      if (this.selectedExpense.amount != null) {
        this.selectedExpense.amountFormatted = this.dateTimeFormatsService.selectedSymbolPos.value == 'left'
          ? this.selectedExpense.currencySymbol + this.selectedExpense.amount.toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : this.selectedExpense.amount.toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + this.selectedExpense.currencySymbol;
      } else {
        this.selectedExpense.amountFormatted = null;
      }
      this.initAmountFormField();
      this.updateSaveBtn();
    }
  }

  private updateSaveBtn() {
    this.pageTitle.controls.find(control => control.id == 'save').isDisabled = !(this.selectedExpense.amount != null && !_.isEmpty(this.selectedExpense.description));
  }

  private scrapExpense() {
    this.navService.popChildNavPageWithPageTracking().then(() => {
      const data = {
        isDone: true,
        deleted: true,
        selectedExpense: this.selectedExpense
      }
      this.callbackEvent(data);
    });
  }

  private closePage() {
    this.navService.popChildNavPageWithPageTracking();
  }

  private saveExpenseDetails() {
    const data = {
      isDone: true,
      selectedExpense: this.selectedExpense
    }
    this.callbackEvent(data);
    this.pageTitle.controls.find(control => control.id == 'save').isDisabled = true;
  }

  public removeAttachment(event) {
    try {
      this.attachInput.nativeElement.value = null;
    } catch (error) {
      console.log(error);
    }
    this.attachmentTitle = '';
    this.isAttachmentAdded = false;
    this.attachmentFile = null;
    this.base64str = null;
  }

  public notesChanged(ev): void {
    if (this.device.isOffline) return;
    if (ev && ev.target) {
      this.notes = ev.target.value;
      this.isSaveNotesEnabled = true;

    } else {
      this.notes = '';
      this.isSaveNotesEnabled = true;

    }
    this.initNotesheader();
  }

  public async loadImageFromDevice(event) {
    if (event && event.target && event.target.files) {
      try {
        if ((event.target.files[0].size / 1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE) {
          if (NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(event.target.files[0].name)) {
            this.attachmentFile = event.target.files[0];
            this.attachmentTitle = event.target.files[0].name;
            this.isAttachmentAdded = true;
            this.base64str = await toBase64(this.attachmentFile);
            const dataURLPattern = /^data:[^;]+;base64,/;
            this.base64str = this.base64str.replace(dataURLPattern, '');
            this.isSaveNotesEnabled = true;
            this.initNotesheader();
          } else {
            this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'), 'Note Attachment', 'top', 'info');
            this.removeAttachment(false);
          }
        } else {
          this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION', { size: MAXIMUM_NOTE_ATTACHMENT_SIZE }), 'Note Attachment', 'top', 'info');
          this.removeAttachment(false);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  public async updateNote(ev) {
    if (ev && ev.action) {
      if (this.device.isOffline) return;
      const idx = this.selectedExpense.notes.findIndex(note => note.noteId === ev.noteId);
      let note = this.selectedExpense.notes[idx];

      if (ev.action === 'DELETE' && idx >= 0) {
        note.isDeleted = true;
        if (note.hasDocument)
          note = this._resetDocumentDetailsOfNote(note);
      } else if (ev.action === 'SAVE' && idx >= 0) {
        note.noteText = ev.updatedText;
        if (ev.attachmentFileUpdated) {
          note = this._updateAttachmentinNotes(note, ev);
        } else if (ev.attachmentFileUpdated) {
          note = this._updateAttachmentinNotes(note, ev)
        } else if (ev.isAttachmentRemoved) {
          note.hasDocument = false;
          note = this._resetDocumentDetailsOfNote(note);
        }
      }
      this.updateSaveBtn();
      this.selectedExpense.notes[idx] = note;
    }
  }

  private _resetDocumentDetailsOfNote(note: IONote) {
    note.documentSize = 0;
    note.documentName = '';
    note.documentMimeType = '';
    note.documentDataURL = '';
    note.hasDocument = false;
    return note;
  }

  private _updateAttachmentinNotes(note: IONote, ev: any) {
    note.hasDocument = true;
    note.documentSize = ev.documentSize;
    note.documentName = ev.documentName;
    note.documentMimeType = ev.documentMimeType;
    note.documentDataURL = ev.attachmentFileDataUrl;
    return note;
  }
}