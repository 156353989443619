import { Country } from './../shared/country-list.class';
import { Activity } from '../activity/activity.class';
import { Contact, TrackAction } from '../contact/contact.class';
import { format, isValid } from 'date-fns';
import { AppointmentActivity } from '../activity/appointment.activity.class';
import { EventActivity } from '../events-tool/event.class';
import _ from 'lodash';
/**
 * Class representing the data structure from backend
 * @export
 * @class Account
 */
export class Account {
    public id: string;
    public parentAccountID: string;
    public displayName: string;
    public accountName: string;
    public accountLevel: string;
    public accountLevelFormatted: string;
    public telephone: string;
    public city: string;
    public state: number;
    public country: string;
    public postal: string;
    public status: number;
    public rating: number;
    public address: string;
    public secondaryAddress: Address;
    public isSelected: boolean;
    public order: number;
    public expandIcon: string;
    public showDetails: boolean;
    public activitesByAccount: Array<Activity>=[];
    public events: Array<EventActivity> = [];
    //public relatedContacts: Array<RelatedContact>;
    //public affiliatedAccounts: Array<AffiliatedAccounts>;
    //public _relatedContactIDs: Array<string>;
    //public EmailAddresses: Array<EmailAddress>;
    public primaryEmailString: string;
    public otherEmailString: string;
    public deaExpiryDate: Date;
    public configuredFields: Array<object>;
    public value: string; // scheduler for schedule

    public extendedDetails: ExtendedDetails;
    //public accountAddressList: Array<AccountAddress>=[];
    //public coveragePosistionList: Array<CoveragePosition>=[];
    public financialInfo: FinancialInfo;

    public lastUpdatedTime: number;
    public modifiedOn: number;
    public interactionDate: number;
    public createdOn: number;
    public interactionType: string
    public isNew: boolean;
    public raw;
    public indskr_mdm: number;
    public indskr_accountsourcetype: String;
    public indskr_mdmid: string
    public isUsedInOneTimeMeeting = false;
    public veevaRecordState: number;
    public tagLabels: string[] = [];
    public isSelectedForTag:boolean = false;
    public indskr_legacyid: string;
    public indskr_hcovid: string;
    public entityImage:string;
    public isForcedHideAddIcon?: boolean = false;

    get secondaryInfo(): string{
        return ''; //((this.extendedDetails.accountType ? this.extendedDetails.accountType+', ':'') +(this.accountAddressList.length && this.getPrimaryCountry ? this.getPrimaryCountry +', ':'')).trim().slice(0,-1);
    }

    // get getPrimaryCountry(): string {
    //     if (this.accountAddressList.length == 0) return undefined;

    //     let primaryCtryFound: AccountAddress;

    //     this.accountAddressList.forEach(add => {
    //       if (add.isPrimary) primaryCtryFound = add;
    //     });

    //     if(primaryCtryFound) {
    //       return primaryCtryFound.country;
    //     } else {
    //       return '';
    //     }
    //   }

    /**
     * These are children accounts of this account, if there are no elements then the poor fella has no children and must be living hella rich
     *
     * @type {Array<Account>}
     * @memberof Account
     */
    public subAccounts: Array<Account>;

    constructor(raw: AccountDTO) {
        this.order = 0; //default ordering number assignement
        this.id = raw.accountid;
        this.raw = raw;
        this.lastUpdatedTime = raw['lastUpdatedTime'] || 0;
        this.parentAccountID = raw['_parentaccountid_value'];
        this.accountName = raw.name || '';
        this.accountLevel = raw['indskr_accountlevel'];
        this.accountLevelFormatted = raw['indskr_accountlevel@OData.Community.Display.V1.FormattedValue'];
        this.value = raw.name; // for segment

        this.address = raw.address1_name;
        this.city = raw['address1_city'];
        this.state = raw['address2_stateorprovince'];
        this.country = raw['address2_country'];
        this.postal = raw['address1_postalcode'];
        this.telephone = raw['telephone1'];
        this.indskr_legacyid = raw['indskr_legacyid'];
        this.indskr_hcovid = raw['indskr_hcovid'] || '';
        this.status = raw['statuscode'];
        this.isSelected = false;
        this.subAccounts = [];
        //this.relatedContacts = [];
        //this.affiliatedAccounts = [];
        //this.EmailAddresses=[];
        this.primaryEmailString="";
        this.otherEmailString='';
        this.configuredFields = raw['configuredFields'] || [];
        this.modifiedOn = raw['modifiedon']?new Date(raw['modifiedon']).getTime():0;
        this.isNew = false;
        this.createdOn= raw['createdon'] ? new Date(raw['createdon']).getTime(): 0;
        this.interactionDate = raw['interactionDate'] ? parseInt(raw['interactionDate']): 0;
        this.interactionType = raw['interactionType'] ;
        this.indskr_mdm = raw['indskr_mdm'];
        this.indskr_accountsourcetype = raw['indskr_accountsourcetype'];
        this.indskr_mdmid = raw['indskr_mdmid'];
        this.isUsedInOneTimeMeeting = !!raw['omniveev_isusedinonetimemeeting'];
        this.veevaRecordState = raw['omniveev_recordstate'];
        this.tagLabels = _.isEmpty(raw['tagLabels']) ? [] : [...raw['tagLabels']];
        this.extendedDetails = {
            accountSegment: raw['indskr_accountsegment@OData.Community.Display.V1.FormattedValue'] || '',
            accountAccreditation: raw['_indskr_accreditationagency_value@OData.Community.Display.V1.FormattedValue'] || '',
            customerAccountId1: raw.indskr_customaccountid1 || '',
            deaNumber: raw.indskr_deanumber || '',
            fax: raw.fax || '',
            glnNumber: raw.indskr_glnnumber || '',
            hinNumber: raw.indskr_hinnumber || '',
            accountType: raw['indskr_accounttype@OData.Community.Display.V1.FormattedValue'] || '',
            accountTypeOption: raw['indskr_accounttype'],
            hospitalType: raw['indskr_hospitaltype@OData.Community.Display.V1.FormattedValue'] || '',
            idn: raw['_indskr_idn_value@OData.Community.Display.V1.FormattedValue'] || '',
            idnParents: raw['_indskr_idnparent_value@OData.Community.Display.V1.FormattedValue'] || '',
            ownershipType: raw['indskr_ownershiptype@OData.Community.Display.V1.FormattedValue'] || '',
            externalId: raw.indskr_externalid || '',
            npiNumber: raw['indskr_npinumber@OData.Community.Display.V1.FormattedValue'] || '',
            numOfDischarges: raw['indskr_numberofdischarges@OData.Community.Display.V1.FormattedValue'] || '',
            numOfStaffedBeds: raw['indskr_numberofstaffedbeds@OData.Community.Display.V1.FormattedValue'] || '',
            parentAccId: raw['_parentaccountid_value@OData.Community.Display.V1.FormattedValue'] || '',
            ownership: raw['indskr_ownership@OData.Community.Display.V1.FormattedValue'] || '',
            providerNumber: raw.indskr_providernumber || '',
            soleCommunityHospital: raw['indskr_solecommunityhospital@OData.Community.Display.V1.FormattedValue'] || '',
            bidNumber: raw.indskr_340bidnumber || '',
            bCommunication: raw['indskr_340bclassification@OData.Community.Display.V1.FormattedValue'] || ''
        }


        this.financialInfo = {
            deniedInPatientClaims: raw['indskr_deniedinpatientclaims_v2@OData.Community.Display.V1.FormattedValue'] || '',
            deniedOutPatientClaims: raw['indskr_deniedoutpatientclaims_v2@OData.Community.Display.V1.FormattedValue'] || '',
            inPatiendRevenue: raw['indskr_inpatientrevenue_v2@OData.Community.Display.V1.FormattedValue'] || '',
            netMedicaidRevenue: raw['indskr_netmedicaidrevenue_v2@OData.Community.Display.V1.FormattedValue'] || '',
            netMedicareRevenue: raw['indskr_netmedicarerevenue_v2@OData.Community.Display.V1.FormattedValue'] || '',
            netOperatingProfitMargin: raw['indskr_netoperatingprofitmargin_v2@OData.Community.Display.V1.FormattedValue'] || '',
            netPatientRevenue: raw['indskr_netpatientrevenue_v2@OData.Community.Display.V1.FormattedValue'] || '',
            outPatientRevenue: raw['indskr_outpatientrevenue_v2@OData.Community.Display.V1.FormattedValue'] || '',
            patientDiscounts: raw['indskr_patientdiscounts_v2@OData.Community.Display.V1.FormattedValue'] || '',
            patientDiscountPercentage: raw['indskr_patientdiscountpercentage@OData.Community.Display.V1.FormattedValue'] || '',
            publicCorporation: raw['indskr_publiccorporation@OData.Community.Display.V1.FormattedValue'] || '',
            stockSymbol: raw.indskr_stocksymbol || '',
            totalMedicaidPatientCharge: raw['indskr_totalmedicaidpatientcharge_v2@OData.Community.Display.V1.FormattedValue'] || '',
            totalMedicarePatientCharge: raw['indskr_totalmedicarepatientcharge_v2@OData.Community.Display.V1.FormattedValue'] || '',
            totalPatientRevenue: raw['indskr_totalpatientrevenue_v2@OData.Community.Display.V1.FormattedValue'] || '',
            totalRevenues: raw['indskr_totalrevenues_v2@OData.Community.Display.V1.FormattedValue'] || ''
        }

        // if (raw.accountAffiliatedTo && Array.isArray(raw.accountAffiliatedTo)) {
        //     raw.accountAffiliatedTo.map(accountAffiliation => {
        //         this.affiliatedAccounts.push({
        //             accountAccountAffiliationID: accountAffiliation['indskr_accountaccountaffiliationid'],
        //             createdOn: accountAffiliation['createdon'] || '',
        //             accountName: accountAffiliation['_indskr_affiliatedtoaccountid_value@OData.Community.Display.V1.FormattedValue'] || '',
        //             accountType: accountAffiliation['indskr_type@OData.Community.Display.V1.FormattedValue'] || '',
        //             accountDescription: accountAffiliation['indskr_description'] || '',
        //             startDate: accountAffiliation['indskr_startdate']? format(parseInt(accountAffiliation['indskr_startdate']), 'MMM DD, YYYY') : '',
        //             endDate: accountAffiliation['indskr_enddate']?format(parseInt(accountAffiliation['indskr_enddate']), 'MMM DD, YYYY') : '',
        //             open: false,
        //             status: accountAffiliation['statecode@OData.Community.Display.V1.FormattedValue'],
        //             affTypeId: accountAffiliation['_indskr_typelookup_value'],
        //             affTypeValue: accountAffiliation['_indskr_typelookup_value@OData.Community.Display.V1.FormattedValue']
        //         });
        //     });
        // }
        // if(raw.accountEmailAddress && Array.isArray(raw.accountEmailAddress))
        // {
        //     let otherEmailAddresses = [];
        //     raw.accountEmailAddress.map(account =>{
        //         if(account.isPrimary){
        //             this.primaryEmailString = account.emailAddress
        //             this.EmailAddresses.push({
        //               isPrimary:true,
        //               emailAddress:account.emailAddress
        //             })
        //         }
        //         else{
        //             otherEmailAddresses.push(account.emailAddress);
        //             this.EmailAddresses.push({
        //               isPrimary:false,
        //               emailAddress:account.emailAddress
        //             })
        //         }
        //     });
        //     this.otherEmailString  = otherEmailAddresses.toString();
        // }
        if (raw.indskr_deanumberexpirationdate){
            if(parseInt(raw.indskr_deanumberexpirationdate)){
                this.deaExpiryDate = new Date(parseInt(raw.indskr_deanumberexpirationdate));
            }else{
                this.deaExpiryDate = new Date(raw.indskr_deanumberexpirationdate);
            }
            if(!isValid(this.deaExpiryDate)){
                this.deaExpiryDate = undefined;
            }
        }
        // if (raw.contactAccountAffiliations && Array.isArray(raw.contactAccountAffiliations)) {
        //     raw.contactAccountAffiliations.map((contact: ContactAccountAffiliationDTO) => {
        //         this.relatedContacts.push({
        //             contactId: contact.contactId,
        //             createdOn: contact.createdon ? (new Date(parseFloat(contact.createdon))).toDateString() : '',
        //             accountContactAffiliationId: contact.indskr_accountcontactaffiliationid,
        //             directRelationshipFlag: contact.indskr_directrelationshipflag,
        //             stateCode: contact.statecode,
        //             stateDisplayTxt: contact['statecode@OData.Community.Display.V1.FormattedValue'],
        //             contactName: contact['contactId@OData.Community.Display.V1.FormattedValue'],
        //             contactRole: contact.indskr_contactrole,
        //             contactRoleDisplayTxt: contact['indskr_contactrole@OData.Community.Display.V1.FormattedValue'],
        //             startDate: contact['indskr_startdate'] ? (new Date(parseFloat(contact['indskr_startdate']))).toDateString() : undefined,
        //             endDate: contact['indskr_enddate'] ? (new Date(parseFloat(contact['indskr_enddate']))).toDateString() : undefined,
        //             comments: contact['indskr_comments'],
        //             affRoleId: contact['_indskr_affiliationroleid_value'],
        //             affRoleValue: contact['_indskr_affiliationroleid_value@OData.Community.Display.V1.FormattedValue'],
        //             open: false,
        //         });
        //     });
        // }
        // if (raw.accountAffiliatedTo && Array.isArray(raw.accountAffiliatedTo)) {
        //     raw.accountAffiliatedTo.map(accountAffiliation => {
        //         this.affiliatedAccounts.push({
        //             accountAccountAffiliationID: accountAffiliation['indskr_accountaccountaffiliationid'],
        //             createdOn: accountAffiliation['createdon'] || '',
        //             accountName: accountAffiliation['_indskr_affiliatedtoaccountid_value@OData.Community.Display.V1.FormattedValue'] || 'N/A',
        //             accountType: accountAffiliation['indskr_type@OData.Community.Display.V1.FormattedValue'] || 'N/A',
        //             accountDescription: accountAffiliation['indskr_description'] || 'N/A',
        //             startDate: accountAffiliation['indskr_startdate']? format(parseInt(accountAffiliation['indskr_startdate']), 'MMM DD, YYYY') : 'N/A',
        //             endDate: accountAffiliation['indskr_enddate']?format(parseInt(accountAffiliation['indskr_enddate']), 'MMM DD, YYYY') : 'N/A',
        //             open: false,
        //             status: accountAffiliation['statecode@OData.Community.Display.V1.FormattedValue']
        //         });
        //     });
        // }
        // if (raw.accountAffiliatedFrom && Array.isArray(raw.accountAffiliatedFrom)) {
        //     raw.accountAffiliatedFrom.map(accountAffiliation => {
        //         this.affiliatedAccounts.push({
        //             accountAccountAffiliationID: accountAffiliation['indskr_accountaccountaffiliationid'],
        //             createdOn: accountAffiliation['createdon'] || '',
        //             accountName: accountAffiliation['_indskr_affiliatedfromaccountid_value@OData.Community.Display.V1.FormattedValue'] || '',
        //             accountType: accountAffiliation['indskr_type@OData.Community.Display.V1.FormattedValue'] || '',
        //             accountDescription: accountAffiliation['indskr_description'] || '',
        //             startDate: accountAffiliation['indskr_startdate']? format(parseInt(accountAffiliation['indskr_startdate']), 'MMM DD, YYYY') : '',
        //             endDate: accountAffiliation['indskr_enddate']?format(parseInt(accountAffiliation['indskr_enddate']), 'MMM DD, YYYY') : '',
        //             open: false,
        //             status: accountAffiliation['statecode@OData.Community.Display.V1.FormattedValue'],
        //             affTypeId: accountAffiliation['_indskr_typelookup_value'],
        //             affTypeValue: accountAffiliation['_indskr_typelookup_value@OData.Community.Display.V1.FormattedValue']
        //         });
        //     });
        // }

        // if(raw.accountAddress && Array.isArray(raw.accountAddress)){
        //     for (let address of raw.accountAddress) {
        //         let addObj: AccountAddress = {
        //             customerAddressId: address.customeraddressid || '',
        //             isPrimary: !!address.indskr_primary,
        //             compositeAdd:'',
        //             addressTypeCode: address["addresstypecode@OData.Community.Display.V1.FormattedValue"] || '',
        //             telephone1: address.telephone1 || '',
        //             telephone2: address.telephone2 || '',
        //             fax: address.fax || '',
        //             secondaryFax: address.secondaryfax || '',
        //             postOfficeBox: address.postofficebox || '',
        //             primaryContact: address.primarycontact || '',
        //             district: address["district@OData.Community.Display.V1.FormattedValue"] || '',
        //             address_line1: address.address_line1 || '',
        //             address_line2: address.address_line2 || '',
        //             address_line3: address.address_line3 || '',
        //             state: address.state || '',
        //             city: address.city || '',
        //             country: address.country || '',
        //             countryCode: address.countrycode3 || '',
        //             postalCode: address.postalcode || '',
        //             region: address.regionname || ''
        //         };
        //         addObj.compositeAdd = addObj.address_line1 + ' ' + addObj.address_line2 + ' ' + addObj.address_line3 + ' ' + addObj.city + ' ' + addObj.state + ' ' + addObj.country + ' ' + addObj.postalCode;
        //         this.accountAddressList.push(addObj);
        //     }
        // }

        // if(raw.coveragePositions && Array.isArray(raw.coveragePositions)){
        //     for (let position of raw.coveragePositions){
        //         let addObj: CoveragePosition = {
        //             accountId: position.accountId || '',
        //             positionName: position.positionname || '',
        //             parentPositionName: position.parentpositionname || ''
        //         };
        //         this.coveragePosistionList.push(addObj);
        //     }
        // }
    }
  mapEvents(events: EventActivity[]) {
    this.events = [];
    if (events && Array.isArray(events)) {
      events.forEach((event: EventActivity) => {
        this.events.push(new EventActivity(event));
      })
      console.log('Fetched Account events for timeline:: ' + this.events.length);
    }
  }
}

export interface Address {
    city: string;
    street: string;
    country: string;
    composite: string;
    state: string;
    postal: string;
}

export interface RelatedContact {
    contactId: string;
    createdOn: string;
    accountContactAffiliationId: string;
    directRelationshipFlag: boolean;
    stateCode: number;
    stateDisplayTxt: string;
    contactName: string;
    contactRole: number;
    contactRoleDisplayTxt: string;
    startDate: string;
    endDate: string;
    comments: string;
    affRoleId: string;
    affRoleValue: string;
    open?: boolean;
}
export interface EmailAddress{
    isPrimary: boolean;
    emailAddress: string;
}

export interface AffiliatedAccounts {
    accountAccountAffiliationID: string;
    createdOn: string;
    accountName: string;
    accountType: string;
    accountDescription: string;
    startDate: string;
    endDate: string;
    open?: boolean;
    status: string;
    affTypeId: string;
    affTypeValue: string;
}

export interface ExtendedDetails {
    accountSegment: string;
    accountAccreditation: string;
    customerAccountId1: string;
    deaNumber: string;
    fax: string;
    glnNumber: string;
    hinNumber: string;
    accountType: string;
    accountTypeOption?: number;
    hospitalType: string;
    idn: string;
    idnParents: string;
    ownershipType: string;
    externalId: string;
    npiNumber: string;
    numOfDischarges: string;
    numOfStaffedBeds: string;
    parentAccId: string;
    ownership: string;
    providerNumber: string;
    soleCommunityHospital: string;
    bidNumber: string;
    bCommunication: string;
}

export const MultiLingualSearchIndexingForAccountExtendedDeatils = {
  accountSegment: {
    categoryName:'Account Segment',
    translationKey: 'ACCOUNT_SEGMENT'
  },
  accountAccreditation: {
    categoryName:'Accreditation Agency',
    translationKey: 'ACCOUNT_ACCREDITATION_AGENCY'
  },
  customerAccountId1: {
    categoryName:'Custom ID',
    translationKey: 'ACCOUNT_CUSTOM_ID'
  },
  deaNumber: {
    categoryName:'DEA Number',
    translationKey: 'ACCOUNT_DEA_NUMBER'
  },
  fax: {
    categoryName:'Fax Number',
    translationKey: 'ACCOUNT_FAX_NUMBER'
  },
  glnNumber: {
    categoryName:'GLN Number',
    translationKey: 'ACCOUNT_GLN_NUMBER'
  },
  hinNumber: {
    categoryName:'HIN Number',
    translationKey: 'ACCOUNT_HIN_NUMBER'
  },
  accountType : {
    categoryName:'Type',
    translationKey: 'TYPE'
  },
  hospitalType: {
    categoryName:'Hospital Type',
    translationKey: 'ACCOUNT_HOSPITAL_TYPE'
  },
  idn: {
    categoryName:'IDN',
    translationKey: 'ACCOUNT_IDN'
  },
  idnParents: {
    categoryName:'IDN Parent',
    translationKey: 'ACCOUNT_IDN_PARENT'
  },
  ownershipType: {
    categoryName:'Managed/Leased/Owned',
    translationKey: 'ACCOUNT_MANAGED_LEASED_OWNED'
  },
  externalId: {
    categoryName:'MDM ID',
    translationKey: 'ACCOUNT_MDM_ID'
  },
  npiNumber: {
    categoryName:'NPI Number',
    translationKey: 'ACCOUNT_NPI_NUMBER'
  },
  numOfDischarges: {
    categoryName:'Number of Discharges',
    translationKey: 'ACCOUNT_NUMBER_OF_DISCHARGES'
  },
  numOfStaffedBeds: {
    categoryName:'Number of Staffed Beds',
    translationKey: 'ACCOUNT_NUMBER_OF_STAFFED_BEDS'
  },
  parentAccId:{
    categoryName:'Parent Account',
    translationKey: 'ACCOUNT_PARENT_ACCOUNT'
  },
  ownership: {
    categoryName:'Proprietor',
    translationKey: 'ACCOUNT_PROPRIETOR'
  },
  providerNumber: {
    categoryName:'Provider Number',
    translationKey: 'ACCOUNT_PROVIDER_NUMBER'
  },
  bidNumber: {
    categoryName:'340B ID Number',
    translationKey: 'ACCOUNT_340B_ID_NUMBER'
  },
  bCommunication: {
    categoryName:'340B Classification',
    translationKey: 'ACCOUNT_340B_CLASSIFICATION'
  }
}

export interface AccountAddress {
    customerAddressId: string;
    isPrimary: boolean;
    compositeAdd: string;
    addressTypeCode: string;
    telephone1: string;
    telephone2: string;
    fax: string;
    secondaryFax: string;
    postOfficeBox: string;
    primaryContact: string;
    district: string;
    address_line1: string;
    address_line2: string;
    address_line3: string;
    state: string;
    city: string;
    country: string;
    postalCode: string;
    countryCode: string;
    region: string;
}

export interface CoveragePosition {
    accountId: string;
    positionName: string;
    parentPositionName: string;
}

export interface FinancialInfo {
    deniedInPatientClaims: string;
    deniedOutPatientClaims: string;
    inPatiendRevenue: string;
    netMedicaidRevenue: string;
    netMedicareRevenue: string;
    netOperatingProfitMargin: string;
    netPatientRevenue: string;
    outPatientRevenue: string;
    patientDiscounts: string;
    patientDiscountPercentage: string;
    publicCorporation: string;
    stockSymbol: string;
    totalMedicaidPatientCharge: string;
    totalMedicarePatientCharge: string;
    totalPatientRevenue: string;
    totalRevenues: string;
}

export const MultiLingualSearchIndexingForAccountFinancialInfo = {
  deniedInPatientClaims: {
    categoryName:'Denied Inpatient Claims',
    translationKey: 'ACCOUNT_DENIED_INPATIENT_CLAIMS'
  },
  deniedOutPatientClaims: {
    categoryName:'Denied Outpatient Claims',
    translationKey: 'ACCOUNT_DENIED_OUTPATIENT_CLAIMS'
  },
  inPatiendRevenue: {
    categoryName:'Inpatient Revenue',
    translationKey: 'ACCOUNT_INPATIENT_REVENUE'
  },
  netMedicaidRevenue: {
    categoryName:'Net Medicaid Revenue',
    translationKey: 'ACCOUNT_NET_MEDICAID_REVENUE'
  },
  netMedicareRevenue: {
    categoryName:'Net Medicare Revenue',
    translationKey: 'ACCOUNT_NET_MEDICARE_REVENUE'
  },
  netOperatingProfitMargin: {
    categoryName:'Net Operating Profit Margin',
    translationKey: 'ACCOUNT_NET_OPERATING_PROFIT_MARGIN'
  },
  netPatientRevenue: {
    categoryName:'Net Patient Revenue',
    translationKey: 'ACCOUNT_NET_PATIENT_REVENUE'
  },
  outPatientRevenue: {
    categoryName:'Outpatient Revenue',
    translationKey: 'ACCOUNT_OUTPATIENT_REVENUE'
  },
  patientDiscounts: {
    categoryName:'Patient Discounts',
    translationKey: 'ACCOUNT_PATIENT_DISCOUNT'
  },
  publicCorporation:{
    categoryName:'Public Corporation',
    translationKey: 'ACCOUNT_PUBLIC_CORPORATOR',
    isBooleanTypeCategory: true
  },
  stockSymbol: {
    categoryName:'Stock Symbol',
    translationKey: 'ACCOUNT_STOCK_SYMBOL'
  },
  totalMedicaidPatientCharge: {
    categoryName:'Total Medicaid Patient Charge',
    translationKey: 'ACCOUNT_TOTAL_MEDICAID_PATIENT_CHARGE'
  },
  totalMedicarePatientCharge: {
    categoryName:'Total Medicare Patient Charge',
    translationKey: 'ACCOUNT_TOTAL_MEDICARE_PATIENT_CHARGE'
  },
  totalPatientRevenue: {
    categoryName:'Total Patient Revenue',
    translationKey: 'ACCOUNT_TOTAL_PATIENT_REVENUE'
  },
  totalRevenues: {
    categoryName:'Total Revenues',
    translationKey: 'ACCOUNT_TOTAL_REVENUE'
  }
}

export interface AccountAddressDTO {
    '@odata.etag': string;
    indskr_indskr_customeraddress_v2id: string;
    '_indskr_address_value@OData.Community.Display.V1.FormattedValue': string;
    '_indskr_address_value@Microsoft.Dynamics.CRM.associatednavigationproperty': string;
    '_indskr_address_value@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    _indskr_address_value: string;
    'modifiedon@OData.Community.Display.V1.FormattedValue': string;
    modifiedon: string;
    'createdon@OData.Community.Display.V1.FormattedValue': string;
    createdon: string;
    state: string;
    city: string;
    country: string;
    countrycode3: string,
    'indskr_primary@OData.Community.Display.V1.FormattedValue': string;
    indskr_primary: boolean;
    customeraddressid: string;
    'lu_city@OData.Community.Display.V1.FormattedValue': string;
    'lu_city@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    lu_city: string;
    'accountId@OData.Community.Display.V1.FormattedValue': string;
    'accountId@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    accountId: string;
    postalcode: string;
    'stateorprovince@OData.Community.Display.V1.FormattedValue': string;
    'stateorprovince@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    stateorprovince: string;
    postofficebox: string;
    address_line1: string;
    address_line2: string;
    address_line3: string;
    'lu_postalcode@OData.Community.Display.V1.FormattedValue': string;
    'lu_postalcode@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    lu_postalcode: string;
    name: string;
    regionname: string;
    geographicclassificationname: string;
    fipscountycode: string;
    'addresstypecode@OData.Community.Display.V1.FormattedValue': string;
    addresstypecode: string;
    telephone1: string;
    telephone2: string;
    fax: string;
    secondaryfax: string;
    primarycontact: string;
    'district@OData.Community.Display.V1.FormattedValue': string;
    district: string;
}

export interface AccountEmailAddressDTO {
    '@odata.etag': string;
    indskr_email_addressid: string;
    'accountid@OData.Community.Display.V1.FormattedValue': string;
    'accountid@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    accountid: string;
    userid: string;
    emailId: string;
    emailAddress: string;
    'isPrimary@OData.Community.Display.V1.FormattedValue': string;
    isPrimary: boolean;
    username: string;
}

export interface AccountAffiliatedFromDTO {
    '@odata.etag': string;
    '_modifiedby_value@OData.Community.Display.V1.FormattedValue': string;
    '_modifiedby_value@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    _modifiedby_value: string;
    'statecode@OData.Community.Display.V1.FormattedValue': string;
    statecode: number;
    'modifiedon@OData.Community.Display.V1.FormattedValue': string;
    modifiedon: string;
    'createdon@OData.Community.Display.V1.FormattedValue': string;
    createdon: string;
    '_createdby_value@OData.Community.Display.V1.FormattedValue': string;
    '_createdby_value@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    _createdby_value: string;
    '_indskr_affiliatedfromaccountid_value@OData.Community.Display.V1.FormattedValue': string;
    '_indskr_affiliatedfromaccountid_value@Microsoft.Dynamics.CRM.associatednavigationproperty': string;
    '_indskr_affiliatedfromaccountid_value@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    _indskr_affiliatedfromaccountid_value: string;
    indskr_accountaccountaffiliationid: string;
    '_indskr_affiliatedtoaccountid_value@OData.Community.Display.V1.FormattedValue': string;
    '_indskr_affiliatedtoaccountid_value@Microsoft.Dynamics.CRM.associatednavigationproperty': string;
    '_indskr_affiliatedtoaccountid_value@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    _indskr_affiliatedtoaccountid_value: string;
    affTypeId: string;
    affTypeValue: string;
}

export interface AccountAffiliatedTo {
    createdon: string;
    indskr_accountaccountaffiliationid: string;
    '_indskr_affiliatedtoaccountid_value@OData.Community.Display.V1.FormattedValue': string;
    'indskr_type@OData.Community.Display.V1.FormattedValue': string;
    indskr_description: string;
    'statecode@OData.Community.Display.V1.FormattedValue': string;
    affTypeId: string;
    affTypeValue: string;
}

export interface ContactAccountAffiliationDTO {
    '@odata.etag': string;
    indskr_accountcontactaffiliationid: string;
    'statecode@OData.Community.Display.V1.FormattedValue': string;
    statecode: number;
    'indskr_contactrole@OData.Community.Display.V1.FormattedValue': string;
    indskr_contactrole: number;
    '_indskr_accountid_value@OData.Community.Display.V1.FormattedValue': string;
    '_indskr_accountid_value@Microsoft.Dynamics.CRM.associatednavigationproperty': string;
    '_indskr_accountid_value@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    _indskr_accountid_value: string;
    'createdon@OData.Community.Display.V1.FormattedValue': string;
    createdon: string;
    'indskr_directrelationshipflag@OData.Community.Display.V1.FormattedValue': string;
    'indskr_directrelationshipflag': boolean;
    'contactId@OData.Community.Display.V1.FormattedValue': string;
    'contactId@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    contactId: string;
    affRoleId: string;
    affRoleValue: string;
}

export interface FinancialInfoDTO {
    '@odata.etag': string;
    'indskr_deniedinpatientclaims_v2@OData.Community.Display.V1.FormattedValue': string;
    indskr_deniedinpatientclaims_v2: string;
    'indskr_deniedoutpatientclaims_v2@OData.Community.Display.V1.FormattedValue': string;
    indskr_deniedoutpatientclaims_v2: string
    'indskr_inpatientrevenue_v2@OData.Community.Display.V1.FormattedValue': string;
    indskr_inpatientrevenue_v2: string;
    'indskr_netmedicaidrevenue_v2@OData.Community.Display.V1.FormattedValue': string;
    indskr_netmedicaidrevenue_v2: string;
    'indskr_netmedicarerevenue_v2@OData.Community.Display.V1.FormattedValue': string;
    indskr_netmedicarerevenue_v2: string;
    'indskr_netoperatingprofitmargin_v2@OData.Community.Display.V1.FormattedValue': string;
    indskr_netoperatingprofitmargin_v2: string;
    'indskr_netpatientrevenue_v2@OData.Community.Display.V1.FormattedValue': string;
    indskr_netpatientrevenue_v2: string;
    'indskr_outpatientrevenue_v2@OData.Community.Display.V1.FormattedValue': string;
    indskr_outpatientrevenue_v2: string;
    'indskr_patientdiscounts_v2@OData.Community.Display.V1.FormattedValue': string;
    indskr_patientdiscounts_v2: string;
    'indskr_patientdiscountpercentage@OData.Community.Display.V1.FormattedValue': string;
    indskr_patientdiscountpercentage: string;
    'indskr_publiccorporation@OData.Community.Display.V1.FormattedValue': string;
    indskr_publiccorporation: string;
    indskr_stocksymbol: string;
    'indskr_totalmedicaidpatientcharge_v2@OData.Community.Display.V1.FormattedValue': string;
    indskr_totalmedicaidpatientcharge_v2: string;
    'indskr_totalmedicarepatientcharge_v2@OData.Community.Display.V1.FormattedValue': string;
    indskr_totalmedicarepatientcharge_v2: string
    'indskr_totalpatientrevenue_v2@OData.Community.Display.V1.FormattedValue': string;
    indskr_totalpatientrevenue_v2: string;
    'indskr_totalrevenues_v2@OData.Community.Display.V1.FormattedValue': string;
    indskr_totalrevenues_v2: string;
}

export interface CoveragePosistionDTO {
    'accountId@OData.Community.Display.V1.FormattedValue': string;
    'accountId@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    accountId: string;
    positionname: string;
    parentpositionname: string;
}

export interface AccountDTO {
    '@odata.etag': string;
    'address1_latitude@OData.Community.Display.V1.FormattedValue': string;
    address1_latitude: number;
    'merged@OData.Community.Display.V1.FormattedValue': string;
    merged: boolean;
    'statecode@OData.Community.Display.V1.FormattedValue': string;
    statecode: number;
    'exchangerate@OData.Community.Display.V1.FormattedValue': string;
    exchangerate: number;
    indskr_customaccountid1: string;
    name: string;
    'indskr_accounttype@OData.Community.Display.V1.FormattedValue': string;
    indskr_accounttype: number;
    '_owningbusinessunit_value@Microsoft.Dynamics.CRM.associatednavigationproperty': string;
    '_owningbusinessunit_value@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    _owningbusinessunit_value: string;
    '_owninguser_value@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    _owninguser_value: string;
    'openrevenue_state@OData.Community.Display.V1.FormattedValue': string;
    openrevenue_state: number;
    address1_composite: string;
    'address1_longitude@OData.Community.Display.V1.FormattedValue': string;
    address1_longitude: number;
    'accountratingcode@OData.Community.Display.V1.FormattedValue': string;
    accountratingcode: number;
    'marketingonly@OData.Community.Display.V1.FormattedValue': string;
    marketingonly: boolean;
    'donotphone@OData.Community.Display.V1.FormattedValue': string;
    donotphone: boolean;
    'preferredcontactmethodcode@OData.Community.Display.V1.FormattedValue': string;
    preferredcontactmethodcode: number;
    '_ownerid_value@OData.Community.Display.V1.FormattedValue': string;
    '_ownerid_value@Microsoft.Dynamics.CRM.associatednavigationproperty': string;
    '_ownerid_value@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    _ownerid_value: string;
    '_indskr_idnparent_value@OData.Community.Display.V1.FormattedValue': string;
    '_indskr_idnparent_value@Microsoft.Dynamics.CRM.associatednavigationproperty': string;
    '_indskr_idnparent_value@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    _indskr_idnparent_value: string;
    'customersizecode@OData.Community.Display.V1.FormattedValue': string;
    customersizecode: number;
    '_indskr_idn_value@OData.Community.Display.V1.FormattedValue': string;
    '_indskr_idn_value@Microsoft.Dynamics.CRM.associatednavigationproperty': string;
    '_indskr_idn_value@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    _indskr_idn_value: string;
    'donotpostalmail@OData.Community.Display.V1.FormattedValue': string;
    donotpostalmail: boolean;
    'openrevenue_date@OData.Community.Display.V1.FormattedValue': string;
    openrevenue_date: string;
    'openrevenue_base@OData.Community.Display.V1.FormattedValue': string;
    openrevenue_base: number;
    address1_line1: string;
    'indskr_isverified@OData.Community.Display.V1.FormattedValue': string;
    indskr_isverified: boolean;
    'donotemail@OData.Community.Display.V1.FormattedValue': string;
    donotemail: boolean;
    indskr_externalid: string;
    'address2_shippingmethodcode@OData.Community.Display.V1.FormattedValue': string;
    address2_shippingmethodcode: number;
    'indskr_deanumberexpirationdate@OData.Community.Display.V1.FormattedValue': string;
    indskr_deanumberexpirationdate: string;
    'timezoneruleversionnumber@OData.Community.Display.V1.FormattedValue': string;
    timezoneruleversionnumber: number;
    address1_addressid: string;
    'indskr_solecommunityhospital@OData.Community.Display.V1.FormattedValue': string;
    indskr_solecommunityhospital: boolean;
    'statuscode@OData.Community.Display.V1.FormattedValue': string;
    statuscode: number;
    'createdon@OData.Community.Display.V1.FormattedValue': string;
    createdon: string;
    'address2_freighttermscode@OData.Community.Display.V1.FormattedValue': string;
    address2_freighttermscode: number;
    'opendeals_state@OData.Community.Display.V1.FormattedValue': string;
    opendeals_state: number;
    'openrevenue@OData.Community.Display.V1.FormattedValue': string;
    openrevenue: number;
    'donotsendmm@OData.Community.Display.V1.FormattedValue': string;
    donotsendmm: boolean;
    'donotfax@OData.Community.Display.V1.FormattedValue': string;
    donotfax: boolean;
    'donotbulkpostalmail@OData.Community.Display.V1.FormattedValue': string;
    donotbulkpostalmail: boolean;
    'modifiedon@OData.Community.Display.V1.FormattedValue': string;
    modifiedon: string;
    'creditonhold@OData.Community.Display.V1.FormattedValue': string;
    creditonhold: boolean;
    telephone1: string;
    indskr_legacyid: string;
    indskr_hcovid: string;
    'indskr_publiccorporation@OData.Community.Display.V1.FormattedValue': string;
    indskr_publiccorporation: boolean;
    'indskr_accountsegment@OData.Community.Display.V1.FormattedValue': string;
    indskr_accountsegment: number;
    '_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue': string;
    '_transactioncurrencyid_value@Microsoft.Dynamics.CRM.associatednavigationproperty': string;
    '_transactioncurrencyid_value@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    _transactioncurrencyid_value: string;
    'donotbulkemail@OData.Community.Display.V1.FormattedValue': string;
    donotbulkemail: boolean;
    '_modifiedby_value@OData.Community.Display.V1.FormattedValue': string;
    '_modifiedby_value@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    '_modifiedby_value': string;
    'followemail@OData.Community.Display.V1.FormattedValue': string;
    followemail: boolean;
    'shippingmethodcode@OData.Community.Display.V1.FormattedValue': string;
    shippingmethodcode: number;
    'businesstypecode@OData.Community.Display.V1.FormattedValue': string;
    businesstypecode: number;
    '_createdby_value@OData.Community.Display.V1.FormattedValue': string;
    '_createdby_value@Microsoft.Dynamics.CRM.lookuplogicalname': string;
    _createdby_value: string;
    address1_name: string;
    'territorycode@OData.Community.Display.V1.FormattedValue': string;
    territorycode: number;
    'address2_addresstypecode@OData.Community.Display.V1.FormattedValue': string;
    address2_addresstypecode: number;
    accountid: string;
    'participatesinworkflow@OData.Community.Display.V1.FormattedValue': string;
    participatesinworkflow: boolean;
    'accountclassificationcode@OData.Community.Display.V1.FormattedValue': string;
    accountclassificationcode: number;
    address2_addressid: string;
    indskr_stocksymbol: string;
    'opendeals_date@OData.Community.Display.V1.FormattedValue': string;
    opendeals_date: string;
    username: string;
    userid: string;
    coveragePositions: CoveragePosistionDTO[];
    contactAccountAffiliations: ContactAccountAffiliationDTO[];
    accountAffiliatedFrom: AccountAffiliatedFromDTO[];
    accountAffiliatedTo: any[];
    accountEmailAddress: AccountEmailAddressDTO[];
    accountAddress: AccountAddressDTO[];
    track_action: number;
    fax: string;
    indskr_deanumber: string;
    indskr_glnnumber: string;
    indskr_hinnumber: string;
    indskr_providernumber: string;
    indskr_340bidnumber: string;
    financialInformation: FinancialInfoDTO[];
    indskr_mdm: number;
    indskr_mdmid: string;
    indskr_accountsourcetype: String;
    interactionDate?: string;
    interactionType?: string;
    tagLabels?: string;
    indskr_accountlevel?: string;
    'indskr_accountlevel@OData.Community.Display.V1.FormattedValue'?: string;
}

export interface AccountGlobalSearchDTO {
  // Account Detail
  accountid: string;
  name: string;
  createdon?: string;
  modifiedon?: string;

  // Primary Address
  country?: string;
}

export enum AccountsModel {
  MY_ACCOUNTS_TAB = "myAccounts",
  CHANGE_REQUESTS_TAB = "changeRequests"
}

export class CustomerSpecaility {
  customerSpecialityId: string;
  isPrimary: boolean;
  name: string;
  customerId: string;
  customerName: string;
  specialityId: string;
  specialityName: string;
  statecode : number;
  constructor(raw) {
    this.customerSpecialityId = raw['indskr_customerspecialtyid'];
    this.isPrimary = raw['indskr_isprimary'];
    this.name = raw['indskr_name'];
    this.customerId = raw['_indskr_customerid_value'];
    this.customerName = raw['_indskr_customerid_value_Formatted'];
    this.specialityId = raw['_indskr_specialtyid_value'];
    this.specialityName = raw['_indskr_specialtyid_value_Formatted'];
  }
}

export class customerAddress {
  customerAddressId: string;
  customerAddressString: string;
  addressId: string;
  addressString: string;
  accountId : string;
  statecode : number;
  constructor(raw) {
    this.customerAddressId = raw['indskr_indskr_customeraddress_v2id'];
    this.customerAddressString = raw['indskr_name'];
    this.addressId = raw['_indskr_address_value_Formatted'];
    this.addressString = raw['_indskr_address_value'];
    this.accountId = raw['_indskr_account_guid_value'];
  }
}
