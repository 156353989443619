<div #expandWrapper class='expand-wrapper' [class.collapsed]="!expanded" *ngIf="viewType=='activitiesList'">
    <!-- <div class="contentText">
        <ion-icon name="time"></ion-icon>
        <span>{{activity.expandedTimeText}}</span>
    </div> -->
    <div padding-left style="overflow-x: auto; ">
        <!-- <button ion-button fill="clear" *ngIf="activity.type == activityType.Appointment">Schedule Follow up</button>
        <button ion-button fill="clear" *ngIf="activity.type == activityType.Appointment">Follow up email</button>
        <button ion-button fill="clear" (click)="onDeleteClick()">Scrap</button> -->
        <span (click)="onDeleteClick($event)" padding-right color="primary" [ngClass]="{'disabledText': (activity.isCompleted || (activity.type == 'TimeOff') ||readOnlyMeeting || activityService.teamViewActive || checkCustomScrapDisabledCondition)}">{{'SCRAP' | translate}}</span>
        <span (click)="addNewActivity($event,activity)" *ngIf="activity.type == activityType.Appointment || activity.type == activityType.PhoneCall" [ngClass]="{'disabledText':readOnlyMeeting || activityService.teamViewActive}" padding-right color="primary">{{'SCHEDULE_FOLLOW_UP' | translate}}</span>
        <!-- <span *ngIf="activity.type == activityType.Appointment" padding-right color="primary" [ngClass]="{'disabledText':true}">Follow up email</span> -->
    </div>
</div>
<!-- <div #expandWrapper class='expand-wrapper-accounts' [class.collapsed]="!expanded" *ngIf="viewType=='contactAccountInfo'">
    <ion-list no-padding> -->
        <!-- <ion-item>
            <ion-label col-4 padding-left>Start Date</ion-label>
            <ion-label col-8 class="indGrey-color">{{contactAccountRelation.startDate | indDateFormat}}</ion-label>
        </ion-item>
        <ion-item>
            <ion-label col-4 padding-left>End Date</ion-label>
            <ion-label col-8 class="indGrey-color">{{contactAccountRelation.endDate | indDateFormat}}</ion-label>
        </ion-item> -->
        <!-- <ion-item>
            <ion-label col-4 padding-left>Primary</ion-label>
            <ion-label col-8 class="indGrey-color">{{contactAccountRelation.primaryRelation}}</ion-label>
        </ion-item> -->
        <!-- <ion-item>
            <ion-label col-4 padding-left>Direct Relationship</ion-label>
            <ion-label col-8 class="indGrey-color">{{(contactAccountRelation.directRelation) ? 'Yes' : 'No'}}</ion-label>
        </ion-item> -->
        <!-- <ion-item>
            <ion-label col-4 padding-left>{{'ROLE' | translate}}</ion-label>
            <ion-label col-8 class="indGrey-color">{{contactAccountRelation.contactRole}}</ion-label>
        </ion-item>
        <ion-item>
            <ion-label col-4 padding-left>{{'STATUS' | translate}}</ion-label>
            <ion-label col-8 class="indGrey-color">{{contactAccountRelation.status}}</ion-label>
        </ion-item>
        <ion-item>
            <ion-label class="createddate" col-4 padding-left>{{'CREATED_DATE' | translate}}</ion-label>
            <ion-label col-8 class="indGrey-color">{{contactAccountRelation.createdDate | customDate: 'DATE'}}</ion-label>
        </ion-item> -->
        <!-- <ion-item>
            <ion-label col-4 padding-left>Comments</ion-label>
            <ion-label col-8 class="indGrey-color">{{contactAccountRelation.comments}}</ion-label>
        </ion-item> -->
    <!-- </ion-list>
</div>
<div #expandWrapper class='expand-wrapper-accounts' [class.collapsed]="!expanded" *ngIf="viewType=='contactRelationInfo'">
    <ion-list no-padding>
        <ion-item>
            <ion-label col-4 padding-left>{{'RELATIONSHIP' | translate}}</ion-label>
            <ion-label col-8>{{contactContactRelation.relationship}}</ion-label>
        </ion-item>
        <ion-item>
            <ion-label class="createddate" col-4 padding-left>{{'CREATED_DATE' | translate}}</ion-label>
            <ion-label col-8>{{formattedDate(contactContactRelation.createdDate)}}</ion-label>
        </ion-item>
    </ion-list>
</div> -->

<!-- Call plan section -->
<div #expandWrapper [ngClass]="{'enableCopyPaste': expandableAccessedFrom=='contactProfile'}" [class.collapsed]="!expanded" *ngIf="viewType=='contactCallplans'"
  class='expand-wrapper-accounts item-border-bottom'>
    <ion-list no-padding>
        <ion-item-group no-padding *ngFor="let plan of contactCallPlans">

          <ion-item *ngIf="contactCallPlanViewMode == 'Engagements' && plan.totalGoalCalls !=0">
            <ion-label class="text-wrapper">
              <ion-row class="primary-text">
                <ion-text>{{plan.formattedTitle}}</ion-text>
              </ion-row>
              <ion-row class="sencond-line-text">
                <ion-col size='1.5' size-xs='2' size-lg='1.5'>
                  <ion-text class="displayPill">{{plan.meetingCompletePercentage}}%</ion-text>
                </ion-col>
                <ion-col size='3' size-xs='3' size-lg='2' class="secondary-text">
                  <ion-text>{{plan.callPlanStatus | repCallPlanState}}</ion-text>
                </ion-col>
                <ion-col size='6' size-xs='6' size-lg='7' class="secondary-text">
                  <ion-text>{{plan.positionName}}</ion-text>
                </ion-col>
              </ion-row>
            </ion-label>
          </ion-item>

          <ion-item *ngIf="contactCallPlanViewMode == 'Messages' && plan.totalGoalEmails !=0">
            <ion-label class="text-wrapper">
              <ion-row class="primary-text">
                <ion-text>{{plan.formattedTitle}}</ion-text>
              </ion-row>
              <ion-row class="sencond-line-text">
                <ion-col size='1.5' size-xs='2' size-lg='1.5'>
                  <ion-text class="displayPill">{{plan.emailCompletePercentage}}%</ion-text>
                </ion-col>
                <ion-col size='4' class="secondary-text">
                  <ion-text>{{plan.callPlanStatus | repCallPlanState}}</ion-text>
                </ion-col>
                <ion-col size='6' size-xs='6' size-lg='7' class="secondary-text">
                  <ion-text>{{plan.positionName}}</ion-text>
                </ion-col>
              </ion-row>
            </ion-label>
          </ion-item>

          <ion-item *ngIf="contactCallPlanViewMode == 'F2fMeetings' && plan.totalGoalF2FMeetings !=0">
            <ion-label class="text-wrapper">
              <ion-row class="primary-text">
                <ion-text>{{plan.formattedTitle}}</ion-text>
              </ion-row>
              <ion-row class="sencond-line-text">
                <ion-col size='1.5' size-xs='2' size-lg='1.5'>
                  <ion-text class="displayPill">{{plan.f2fMeetingCompletePercentage}}%</ion-text>
                </ion-col>
                <ion-col size='4' class="secondary-text">
                  <ion-text>{{plan.callPlanStatus | repCallPlanState}}</ion-text>
                </ion-col>
                <ion-col size='6' size-xs='6' size-lg='7' class="secondary-text">
                  <ion-text>{{plan.positionName}}</ion-text>
                </ion-col>
              </ion-row>
            </ion-label>
          </ion-item>

          <ion-item *ngIf="contactCallPlanViewMode == 'PhoneCalls' && plan.totalGoalPhoneCalls !=0">
            <ion-label class="text-wrapper">
              <ion-row class="primary-text">
                <ion-text>{{plan.formattedTitle}}</ion-text>
              </ion-row>
              <ion-row class="sencond-line-text">
                <ion-col size='1.5' size-xs='2' size-lg='1.5'>
                  <ion-text class="displayPill">{{plan.phoneCallCompletePercentage}}%</ion-text>
                </ion-col>
                <ion-col size='4' class="secondary-text">
                  <ion-text>{{plan.callPlanStatus | repCallPlanState}}</ion-text>
                </ion-col>
                <ion-col size='6' size-xs='6' size-lg='7' class="secondary-text">
                  <ion-text>{{plan.positionName}}</ion-text>
                </ion-col>
              </ion-row>
            </ion-label>
          </ion-item>

          <ion-item *ngIf="contactCallPlanViewMode == 'RemoteMeetings' && plan.totalGoalRemoteMeetings !=0">
            <ion-label class="text-wrapper">
              <ion-row class="primary-text">
                <ion-text>{{plan.formattedTitle}}</ion-text>
              </ion-row>
              <ion-row class="sencond-line-text">
                <ion-col size='1.5' size-xs='2' size-lg='1.5'>
                  <ion-text class="displayPill">{{plan.remoteMeetingCompletePercentage}}%</ion-text>
                </ion-col>
                <ion-col size='4' class="secondary-text">
                  <ion-text>{{plan.callPlanStatus | repCallPlanState}}</ion-text>
                </ion-col>
                <ion-col size='6' size-xs='6' size-lg='7' class="secondary-text">
                  <ion-text>{{plan.positionName}}</ion-text>
                </ion-col>
              </ion-row>
            </ion-label>
          </ion-item>

          <ion-item *ngIf="contactCallPlanViewMode == 'Events' && plan.totalGoalEvents !=0">
            <ion-label class="text-wrapper">
              <ion-row class="primary-text">
                <ion-text>{{plan.formattedTitle}}</ion-text>
              </ion-row>
              <ion-row class="sencond-line-text">
                <ion-col size='1.5' size-xs='2' size-lg='1.5'>
                  <ion-text class="displayPill">{{plan.eventsCompletePercentage}}%</ion-text>
                </ion-col>
                <ion-col size='4' class="secondary-text">
                  <ion-text>{{plan.callPlanStatus | repCallPlanState}}</ion-text>
                </ion-col>
                <ion-col size='6' size-xs='6' size-lg='7' class="secondary-text">
                  <ion-text>{{plan.positionName}}</ion-text>
                </ion-col>
              </ion-row>
            </ion-label>
          </ion-item>
        </ion-item-group>
    </ion-list>
</div>


<!-- Scientific plan section -->
<div #expandWrapper class='expand-wrapper-accounts scientific-plans item-border-bottom' [class.collapsed]="!expanded" *ngIf="viewType=='scientificplans'"
  [ngClass]="{'enableCopyPaste': expandableAccessedFrom=='contactProfile'}">
    <ion-list no-padding no-margin>
      <ion-item-group no-padding *ngFor="let plan of scientificPlans" (click)="openSP(plan)">
        <ion-item>
            <ion-label class="text-wrapper">
                <ion-text class="primary-text">{{plan.scientificActivityPlanName}}</ion-text>
                <!-- Pipe to get the segmant call plan name from rep call plan name -->
            </ion-label>
            <ion-icon slot="end" class="arrow-icon" name="chevron-forward-outline"></ion-icon>
            <!-- <div ion-row>
              {{plan.scientificActivityPlanName}}
          </div> -->
        </ion-item>
       </ion-item-group>
    </ion-list>
</div>

<!--CONTENTS HISTORY-->
<div #expandWrapper class='expand-wrapper-history item-border-bottom' [ngClass]="(expandableAccessedFrom=='contactProfile')?(activityContents.length==0 && keyMessageSentiments.length == 0)?'enableCopyPaste expand-wrapper-history-height':'enableCopyPaste': {'expand-wrapper-history-height':(activityContents.length==0 && keyMessageSentiments.length == 0)}" 
  [class.collapsed]="!expanded" *ngIf="viewType=='contactActivePres' || viewType=='contactPresHistory'">
    <ion-scroll no-padding no-margin style="height:100%!important;" class="contactPresHistory">
        <ion-grid>
          <ion-label>
            <ion-text class="primary-text-head" > {{ "PRODUCT_KEY_MESSGES_CAMEL_CASE" | translate }}</ion-text>
          </ion-label>
            <ion-row class="ion-margin-right" style="display: flow-root;background-color: #fff; height: 20px;" *ngFor="let prod of contentProducts">
                <ion-col size="3">
                    <ion-text>{{prod.name || prod.productName}}</ion-text>
                </ion-col>
                <ion-col class="keyMessage" size="8">
                    <ion-text>{{getKeyMessages(prod)}}</ion-text>
                </ion-col>
                <div class="separationLine-no-margin"></div>
            </ion-row>
        </ion-grid>
        <!-- <div no-padding style="display: flow-root;background-color: #fff; height: 20px;" *ngFor="let prod of contentProducts">
          <span>{{prod.name || prod.productName}}</span>
          <span class="overflowEllipsis keyMessage">{{getKeyMessages(prod)}}</span>
        </div> -->
      <!-- <div no-padding class="presContainer"  *ngFor="let content of activityContents"> -->
      <div *ngIf="keyMessageSentiments.length > 0" class="presContainer">
        <ion-list class="key-message-sentiment-list">
          <ion-label>
            <ion-text class="primary-text-head" >{{ "KEY_MESSAGE_SENTIMENT" | translate }}</ion-text>
          </ion-label>
          <ion-item *ngFor="let item of keyMessageSentiments" margin-left class="key-msg-sentiment-item">
            <ion-label>
              <div col-10 float-left>
                  <span class="secondary-text-head" text-nowrap>{{item.indskr_name}}</span>
              </div>
            </ion-label>
            <div style="z-index: 2;">
              <img [src]="(item.sentimentImgURL) ? item.sentimentImgURL : isMeetingSentimentDisabled ? null :'assets/imgs/sentiment-nutral.png'" width="24" float-right/>
              <ion-icon name="chevron-down-outline" [ngClass]="{'completed-activity': (viewType=='contactPresHistory' || device.isOffline || this.activity.isCompleted || readOnlyMeeting || (this.activity['meetingOwner'] != this.authenticationOfflineService.user.displayName && !device.isOffline))}" class="end-icon" (click)="openKeyMessageSentimentPopover($event,item)"></ion-icon>
            </div>
          </ion-item>
        </ion-list>
      </div>
      <div *ngIf="activityContents.length > 0" class="presContainer">
        <ion-label>
          <ion-text class="primary-text-head" >{{ "CONTENT_SENTIMENT" | translate}}</ion-text>
        </ion-label>
        <ion-list *ngFor="let content of activityContents">
          <ion-label>
            <ion-text class="primary-text" >{{content.indskr_name || content.name || content.indskr_ckmtitle}}</ion-text>
          </ion-label>
          <div *ngIf="content.type == 'presentation'">
            <ion-item *ngFor="let slide of sortedPresSlidesByStartTime(content)" margin-left [ngClass]="{'duration-enabled': meetingContentDurationEnabled}">
              <ion-checkbox color="primary" class="checkboxStart" mode="ios" [checked]="isSlideShared(slide)" [disabled]="viewType=='contactPresHistory' || this.activity.isCompleted || readOnlyMeeting || (this.activity['meetingOwner'] != this.authenticationOfflineService.user.displayName && !device.isOffline)"
              (ionChange)="updateSentiments(slide,$event)"></ion-checkbox>
              <ion-label>
                <div col-10 float-left>
                    <span class="secondary-text-slide" text-nowrap>{{slide.indskr_name || slide.name}}</span>
                    <br>
                    <span *ngIf="meetingContentDurationEnabled" class="secondary-text-duration">{{getDuration(slide,content)}}</span>
                </div>
              </ion-label>
              <div style="z-index: 2;">
                <img [src]="(slide.sentimentImgURL) ? slide.sentimentImgURL : 'assets/imgs/sentiment-nutral.png'" width="24" float-right/>
                <ion-icon name="chevron-down-outline" [ngClass]="{'completed-activity': (viewType=='contactPresHistory' || this.activity.isCompleted || readOnlyMeeting || (this.activity['meetingOwner'] != this.authenticationOfflineService.user.displayName && !device.isOffline))}" class="end-icon" (click)="openSentimentPopover($event,slide)"></ion-icon>
              </div>
            </ion-item>
          </div>
          <div *ngIf="content.type == 'resource'">
            <ion-item *ngFor="let slide of sortedPresSlidesByStartTime(content)" margin-left [ngClass]="{'duration-enabled': meetingContentDurationEnabled}">
              <ion-checkbox color="primary" class="checkboxStart" mode="ios" [checked]="isSlideShared(slide)" (ionChange)="updateSentiments(slide,$event)" [disabled]="viewType=='contactPresHistory' || this.activity.isCompleted || readOnlyMeeting || (this.activity['meetingOwner'] != this.authenticationOfflineService.user.displayName && !device.isOffline)"
              ></ion-checkbox>
              <ion-label>
                <div col-10 float-left>
                    <span class="secondary-text-head" text-nowrap>{{content.indskr_ioresourceid ? content.indskr_ckmtitle: 'Page ' + slide.indskr_pagenumber}}</span>
                    <br>
                    <span *ngIf="meetingContentDurationEnabled" class="secondary-text">{{getDuration(slide)}}</span>
                </div>
              </ion-label>
              <div style="z-index: 2;">
                <img [src]="(slide.sentimentImgURL) ? slide.sentimentImgURL : 'assets/imgs/sentiment-nutral.png'" width="24" float-right/>
                <ion-icon name="chevron-down-outline" [ngClass]="{'completed-activity': viewType=='contactPresHistory' || this.activity.isCompleted || readOnlyMeeting || (this.activity['meetingOwner'] != this.authenticationOfflineService.user.displayName && !device.isOffline)}" class="end-icon" (click)="openSentimentPopover($event,slide)"></ion-icon>
              </div>
            </ion-item>
          </div>
        </ion-list>
      </div>
    </ion-scroll>
</div>

<div #expandWrapper class='expand-wrapper' [class.collapsed]="!expanded" *ngIf="viewType=='schedulerList'">
    <div padding-left style="overflow-x: auto; ">
        <span (click)="schedulerActionInvoked('scrap')" padding-right color="primary" [ngClass]="{'disabledText': !isDeviceOnline}">{{'SCRAP' | translate}}</span>
        <span (click)="schedulerActionInvoked('copy')" padding-right color="primary" [ngClass]="{'disabledText': !isDeviceOnline}">{{'COPY' | translate}}</span>
    </div>
</div>

<div #expandWrapper class='expand-wrapper' [class.collapsed]="!expanded" *ngIf="viewType=='customer_inquiry'">
    <div padding-left style="overflow-x: auto; ">
        <span (click)="customerInquiryDelete($event)" padding-right color="primary" [ngClass]="{'disabledText': (checkCustomScrapDisabledCondition)}">{{'SCRAP' | translate}}</span>
    </div>
</div>

<!--Publication Expanded View-->
<div #expandWrapper [class.collapsed]="!expanded" *ngIf="viewType=='medPublication'">
    <ion-list class="scientific-info-detail item-border-bottom" no-padding style="margin-bottom: -10px">
        <ion-item *ngIf="publication.indskr_subject">
            <ion-label col-4 text-wrap>{{'JOURNAL_TITLE' | translate}}</ion-label>
            <ion-label col-8 class="indGrey-color" text-wrap>{{publication.indskr_subject}}</ion-label>
        </ion-item>
        <ion-item *ngIf="publication.indskr_description">
            <ion-label col-4 text-wrap>{{'ABSTRACT' | translate}}</ion-label>
            <ion-label col-8 class="indGrey-color" text-wrap>{{publication.indskr_description}}</ion-label>
        </ion-item>
        <ion-item *ngIf="publication.indskr_articleidentified">
            <ion-label col-4 text-wrap>{{'ARTICLE_IDENTIFIER' | translate}}</ion-label>
            <ion-label col-8 class="indGrey-color" text-wrap>{{publication.indskr_articleidentified}}</ion-label>
        </ion-item>
        <ion-item *ngIf="publication.indskr_author">
            <ion-label col-4 text-wrap>{{'AUTHOR' | translate}}</ion-label>
            <ion-label col-8 class="indGrey-color" text-wrap>{{publication.indskr_author}}</ion-label>
        </ion-item>
        <ion-item *ngIf="publication.indskr_dateofpublication">
            <ion-label col-4 text-wrap>{{'DATE_OF_PUBLICATION' | translate}}</ion-label>
            <ion-label col-8 class="indGrey-color" text-wrap>{{publication.indskr_dateofpublication | customDate:'DATE'}}</ion-label>
        </ion-item>
    </ion-list>
</div>

<!--Research Expanded View-->
<div #expandWrapper [class.collapsed]="!expanded" *ngIf="viewType=='medResearch'">
    <ion-list class="scientific-info-detail item-border-bottom" no-padding>
        <ion-item *ngIf="research.indskr_researchtype">
            <ion-label col-4 padding-left text-wrap>{{'EXPANDABLE_RESEARCH_TYPE' | translate}}</ion-label>
            <ion-label col-8 padding-left class="indGrey-color" text-wrap>{{research.indskr_researchtype}}</ion-label>
        </ion-item>
        <ion-item *ngIf="research.indskr_fundedby">
            <ion-label col-4 padding-left text-wrap>{{'EXPANDABLE_FUNDED_BY' | translate}}</ion-label>
            <ion-label col-8 padding-left class="indGrey-color" text-wrap>{{research.indskr_fundedby}}</ion-label>
        </ion-item>
        <ion-item *ngIf="research.indskr_sponsor">
            <ion-label col-4 padding-left text-wrap>{{'EXPANDABLE_SPONSOR' | translate}}</ion-label>
            <ion-label col-8 padding-left class="indGrey-color" text-wrap>{{research.indskr_sponsor}}</ion-label>
        </ion-item>
        <ion-item *ngIf="research.indskr_studytype">
            <ion-label col-4 padding-left text-wrap>{{'EXPANDABLE_STUDY_TYPE' | translate}}</ion-label>
            <ion-label col-8 padding-left class="indGrey-color" text-wrap>{{research.indskr_studytype}}</ion-label>
        </ion-item>
        <ion-item *ngIf="research.indskr_roles">
            <ion-label col-4 padding-left text-wrap>{{'ROLE' | translate}}</ion-label>
            <ion-label col-8 padding-left class="indGrey-color" text-wrap>{{research.indskr_roles}}</ion-label>
        </ion-item>
    </ion-list>
</div>

<!--Speaker Engagement Expanded View-->
<div #expandWrapper [class.collapsed]="!expanded" *ngIf="viewType=='mktEngagement'">
    <ion-list class="scientific-info-detail item-border-bottom" no-padding>
        <!--<ion-item *ngIf="spkEngagement.indskr_speakerengagementname">
      <ion-label col-4 padding-left text-wrap>Speaker Engagement</ion-label>
      <ion-label col-8 padding-left class="indGrey-color" text-wrap>{{spkEngagement.indskr_speakerengagementname}}</ion-label>
    </ion-item>-->
        <ion-item *ngIf="spkEngagement.indskr_sessionname">
            <ion-label col-4 padding-left text-wrap>{{'EXPANDABLE_SESSION' | translate}}</ion-label>
            <ion-label col-8 padding-left class="indGrey-color" text-wrap>{{spkEngagement.indskr_sessionname}}</ion-label>
        </ion-item>
        <ion-item *ngIf="spkEngagement.indskr_sessionstarttime">
            <ion-label col-4 padding-left text-wrap>{{'START_TIME' | translate}}</ion-label>
            <ion-label col-8 padding-left class="indGrey-color" text-wrap>{{spkEngagement.indskr_sessionstarttime | customDate:'DATE_TIME'}}</ion-label>
        </ion-item>
        <ion-item *ngIf="spkEngagement.indskr_sessionendtime">
            <ion-label col-4 padding-left text-wrap>{{'END_TIME' | translate}}</ion-label>
            <ion-label col-8 padding-left class="indGrey-color" text-wrap>{{spkEngagement.indskr_sessionendtime | customDate:'DATE_TIME'}}</ion-label>
        </ion-item>
    </ion-list>
</div>

<!--Event Attendance Expanded View-->
<!--<div #expandWrapper [class.collapsed]="!expanded" *ngIf="viewType=='mktEvents'">
  <ion-list no-padding style="margin: 0">
    <ion-item *ngIf="evtHistory.indskr_sessionname">
      <ion-label col-4 padding-left text-wrap>Session</ion-label>
      <ion-label col-8 padding-left class="indGrey-color" text-wrap>{{evtHistory.indskr_sessionname}}</ion-label>
    </ion-item>
    <ion-item *ngIf="evtHistory.indskr_speakername">
      <ion-label col-4 padding-left text-wrap>Speaker</ion-label>
      <ion-label col-8 padding-left class="indGrey-color" text-wrap>{{evtHistory.indskr_speakername}}</ion-label>
    </ion-item>
  </ion-list>
</div>-->

<!--Session History Expanded View-->
<!--<div #expandWrapper [class.collapsed]="!expanded" *ngIf="viewType=='mktSessions'">
  <ion-list no-padding style="margin: 0">
    <ion-item *ngIf="sesHistory.indskr_sessiontitle">
      <ion-label col-5 padding-left text-wrap>Session Title</ion-label>
      <ion-label col-7 padding-left class="indGrey-color" text-wrap>{{sesHistory.indskr_sessiontitle}}</ion-label>
    </ion-item>
    <ion-item *ngIf="sesHistory.indskr_sessionformat">
      <ion-label col-5 padding-left text-wrap>Session Format</ion-label>
      <ion-label col-7 padding-left class="indGrey-color" text-wrap>{{sesHistory.indskr_sessionformat}}</ion-label>
    </ion-item>
    <ion-item *ngIf="sesHistory.indskr_eventname">
      <ion-label col-5 padding-left text-wrap>Event</ion-label>
      <ion-label col-7 padding-left class="indGrey-color" text-wrap>{{sesHistory.indskr_eventname}}</ion-label>
    </ion-item>
    <ion-item *ngIf="sesHistory.indskr_audiencetypename">
      <ion-label col-5 padding-left text-wrap>Audience Type</ion-label>
      <ion-label col-7 padding-left class="indGrey-color" text-wrap>{{sesHistory.indskr_audiencetypename}}</ion-label>
    </ion-item>
  </ion-list>
</div>-->

<!--Customer Events Details-->
<div class='event-description' #expandWrapper [class.collapsed]="!expanded" *ngIf="viewType=='eventDetails'"
[ngClass]="{'enableCopyPaste': expandableAccessedFrom=='contactProfile'}">
    <span>{{customerEvent.event.description}}</span>
    <ion-thumbnail *ngIf="customerEvent.event.eventImage">
        <img src="{{customerEvent.event.eventImage}}">
    </ion-thumbnail>
    <div *ngIf="customerEvent.event.status == 'accepted'" class="events-buttons">
        <span class="message">{{ 'EXPANDABLE_HAS_SIGNED_UP' | translate : {text : customerEvent.contact} }}</span>
    </div>
    <div *ngIf="customerEvent.event.status == 'declined'" class="events-buttons">
        <span class="message">{{ 'EXPANDABLE_HAS_NOT_SIGNED_UP' | translate : {text : customerEvent.contact} }}</span>
    </div>
    <div *ngIf="customerEvent.register && customerEvent.event.status == null" class="events-buttons">
        <ion-button outline slot="end" fill="clear" class="sign-up" (click)="captureEventRegResponse(true, customerEvent.event.eventRegistrationId, customerEvent.contact, customerEvent.contactId, customerEvent.event.eventId)">{{'SIGN_UP' | translate}}</ion-button>
        <ion-button outline slot="end" fill="clear" class="not-interested" (click)="captureEventRegResponse(false, customerEvent.event.eventRegistrationId, customerEvent.contact, customerEvent.contactId, customerEvent.event.eventId)">{{'NOT_INTERESTED' | translate}}</ion-button>
    </div>
</div>


<!----Opportunity section-->
<div #expandWrapper class='expand-wrapper-accounts opportunities item-border-bottom' [class.collapsed]="!expanded" *ngIf="viewType=='opportunities'">
    <ion-list no-padding no-margin>
        <ion-item no-padding *ngFor="let opportunity of opportunities" (click)="openOp(opportunity)">
            <ion-label class="text-wrapper">
                <ion-text class="primary-text">{{opportunity.opportunityName}}</ion-text>
                <!-- Pipe to get the segmant call plan name from rep call plan name -->
            </ion-label>
            <ion-icon slot="end" class="arrow-icon" name="chevron-forward-outline"></ion-icon>
            <!-- <div ion-row>
              {{plan.scientificActivityPlanName}}
          </div> -->
        </ion-item>
    </ion-list>
</div>
