import { ActivityType } from './../../classes/activity/activity.class';
import { OrderCreationType } from './../../classes/opportunity-management/opportunity.class';
import { EventName, EventsService } from './../../services/events/events.service';
import { NotificationService } from './../../services/notification/notification.service';
import { SurgeryOrderActivityDataService } from './../surgery-order-activity/surgery-order-activity.data.service';
import { SharedDataService } from './../shared-data/shared.data.service';
import { SearchConfigService } from './../../services/search/search-config.service';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from './../../services/disk/disk.service';
import { OpportunityManagementService } from './../../services/opportunity-management/opportunity-management.service';
import { Opportunity, OpportunityProduct, Quote, Agreement, CollaboratorType, LookupSearchResult, EstimatedRevenueCalculationBasedOn } from '../../classes/opportunity-management/opportunity.class';
import { fetchQueries } from './../../config/dynamics-fetchQueries';
import { AuthenticationService } from './../../services/authentication.service';
import { DynamicsClientService } from './../dynamics-client/dynamics-client.service';
import { DeviceService } from './../../services/device/device.service';
import { Injectable } from "@angular/core";
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { OperationDetail } from '../follow-up-activity/follow-up-activity.data.service';
import { Endpoints } from '../../../config/endpoints.config';
import { HttpClient } from '@angular/common/http';
import { differenceInHours } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { Utility } from '../../utility/util';
import { DeltaService } from '../delta/delta.service';
import { SyncFeatureCategory } from '../../enums/delta-service/delta-service.enum';
import _ from 'lodash';
import { IONote } from '@omni/classes/io/io-note.class';
import { SurveyForm } from '@omni/classes/survey/survey-form.class';
import { TrackAction } from '@omni/utility/common-enums';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { MarketingPlan } from '@omni/classes/marketing-management/marketing-plan.class';
import { format } from "date-fns";
import { UIService } from '@omni/services/ui/ui.service';

@Injectable({
  providedIn: 'root'
})
export class OpportunityManagementDataService {

  public isSelectedOpportunityUpdated:boolean = false;
  public lookupSearchData:Array<LookupSearchResult>= [];
  public lookupSearchInProgress: boolean = false;

  constructor(
    public dynamicsClient : DynamicsClientService,
    public device: DeviceService,
    public authService: AuthenticationService,
    public opportunitiesService: OpportunityManagementService,
    private disk: DiskService,
    private http: HttpClient,
    private translate:TranslateService,
    public searchConfigService: SearchConfigService,
    private deltaService: DeltaService,
    private  sharedDataService: SharedDataService,
    private readonly surgeryOrderActivityDataService: SurgeryOrderActivityDataService,
    private readonly notificationService: NotificationService,
    private readonly events: EventsService,
    private readonly dateTimeFormatsService: DateTimeFormatsService,
    public uiService: UIService,
  ){

  }

  async loadOfflineDataOpportunity(){
    await this.disk.retrieve(DB_KEY_PREFIXES.OPPORTUNITIES, true).then((doc)=>{
      if(doc && doc.raw){
        this.opportunitiesService.opportunities = doc.raw
      }
      else {
        this.opportunitiesService.opportunities = [];
      }
    })

    // Track offline data count
    this.opportunitiesService.trackOfflineOpportunityDataCount();

    await this.disk.retrieve(DB_KEY_PREFIXES.OPPORTUNITY_QUOTES, true).then((doc)=>{
      //offlineDataStored = doc
      if(doc && doc.raw){
        this.opportunitiesService.qoutes = doc.raw
      }
      else {
        this.opportunitiesService.qoutes = [];
      }
    })

    await this.disk.retrieve(DB_KEY_PREFIXES.OPPORTUNITY_AGREEMENTS, true).then((doc)=>{
      if(doc && doc.raw){
        this.opportunitiesService.agreements = doc.raw
      }
      else {
        this.opportunitiesService.agreements = [];
      }
    })
  }

  public async getOpportunitiesData(fullSync?: boolean, accountID?: string, loadFromDbOnly = false, justCreatedOpportunity = false, opportunityId?: string) {
    try {
      // if (this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT)) {
        if(this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) || (!this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT) && (this.authService.user.buSettings["indskr_displayopportunity"] || this.authService.hasFeatureAction(FeatureActionsMap.ASSOCIATE_OPPORTUNITIES_TO_MEETINGS)) )) {
        this.deltaService.pushSyncEntityName(SyncFeatureCategory.profiles);
        if (loadFromDbOnly) {
          await this.loadOfflineDataOpportunity();
        } else {
          let offlineDataStored;
          let lastModifiedForDeltaSync, hourDifference;
          if (!opportunityId) {
            await this.disk.retrieve(DB_KEY_PREFIXES.OPPORTUNITIES, true).then((doc) => {
              offlineDataStored = doc
              if (doc && doc.raw) {
                this.opportunitiesService.opportunities = doc.raw
              }
              else {
                this.opportunitiesService.opportunities = [];
                fullSync = true;
              }
            })
          } else {
            offlineDataStored = {};
            offlineDataStored['raw'] = this.opportunitiesService.opportunities;
          }
          //fill position filter
          let positionIds = this.authService.user.positions.map(o => {
            return o.ID
          });
          let positionString = '';
          positionIds.forEach(p => {
            positionString += '<value>' + p + '</value>'
          })
          let fetchXml = fetchQueries.opportunityManagement.fetchOpportunities.split('{positionIDs}').join(positionString);
          //fill deltasync filter
          let now = new Date();
          if (fullSync) {
            fetchXml = fetchXml.replace('{deltaSyncFilter}', '');
          }
          else {
            let opportunityFilter = '';
            if (opportunityId) {
              opportunityFilter = `<filter type="and">
                                    <condition attribute="opportunityid" operator="eq" value="{0}" />
                                  </filter>`.replace('{0}', opportunityId);
            }
            fetchXml = fetchXml.replace("{opportunityIdFilter}", opportunityFilter);
            if (accountID) {
              let accountOpportunities = offlineDataStored.raw.filter((a) => a.accountID == accountID);
              let iterator = 0;
              if (accountOpportunities.length) {
                do {
                  if (accountOpportunities[iterator].lastUpdated) {
                    lastModifiedForDeltaSync = accountOpportunities[iterator].lastUpdated;
                  }
                  iterator++;
                } while (accountOpportunities[iterator] && !lastModifiedForDeltaSync);
              }
            }
            else offlineDataStored ? lastModifiedForDeltaSync = offlineDataStored.lastModified : '';
            let deltaSyncFilter
            if (lastModifiedForDeltaSync) {
              hourDifference = differenceInHours(
                now,
                new Date(lastModifiedForDeltaSync)
              )
              //add one to make sure we take care of fractional difference in hours
              hourDifference += 1
              const entityname = 'opportunity'
              deltaSyncFilter = fetchQueries.deltaSyncFilter.split('{entityName}').join(entityname)
              deltaSyncFilter = deltaSyncFilter.replace('{hourDifference}', hourDifference)
              deltaSyncFilter = deltaSyncFilter.replace('{entityID}', entityname + 'id')
            }
            else deltaSyncFilter = ''
            fetchXml = fetchXml.replace('{deltaSyncFilter}', deltaSyncFilter)
          }
          //fill account filter
          if (accountID) {
            let accountIDFilter = `<filter><condition attribute="customerid" operator="eq" value="` + accountID + `"/></filter>`
            fetchXml = fetchXml.replace('{accountFilter}', accountIDFilter)
          }
          else {
            fetchXml = fetchXml.replace('{accountFilter}', '')
          }
          await this.dynamicsClient.executeFetchQuery('opportunities', fetchXml).then(async (res) => {
            if (!justCreatedOpportunity && res && res.length > 0 && res[0] && res[0].hasOwnProperty('opportunityid')) {
              const oppoProducts = await this.getOpportunityProducts(fullSync, accountID, lastModifiedForDeltaSync, positionString, opportunityId);
              res.push(...oppoProducts);
              const oppoCompetitors = await this.getOpportunityCompetitors(fullSync, accountID, lastModifiedForDeltaSync, positionString, opportunityId);
              res.push(...oppoCompetitors);
              
            }
            console.log('opportunities', res)
            this.aggregateOpportunities(res, fullSync);
            if (res) {
              await this.mapMarketingPlansToOpportunities(fullSync, accountID, lastModifiedForDeltaSync, positionString, opportunityId);
            }
            this.searchConfigService.isOpportunitesMappedToAccounts = false;
            if (accountID) {
              this.opportunitiesService.opportunities.map((o) => {
                if (!justCreatedOpportunity && o.accountID && o.accountID == accountID) {
                  o.lastUpdated = now.getTime();
                }
              })
              this.disk.updateOrInsert(DB_KEY_PREFIXES.OPPORTUNITIES, (doc) => {
                // doc = {
                //     raw: []
                // };
                doc.raw = this.opportunitiesService.opportunities;
                //doc.lastModified = now.getTime();
                return doc;
              })
            }
            else {
              this.disk.updateOrInsert(DB_KEY_PREFIXES.OPPORTUNITIES, (doc) => {
                doc = {
                  raw: []
                };
                doc.raw = this.opportunitiesService.opportunities;
                doc.raw.map(r => {
                  r.lastUpdated = now.getTime();
                })
                doc.lastModified = now.getTime();
                return doc;
              })
            }
            this.opportunitiesService.opportunities$.next(this.opportunitiesService.opportunities);
            if (!justCreatedOpportunity) await this.getQuotesData(fullSync, accountID, lastModifiedForDeltaSync, positionString);
          },
            (error) => {
                console.log('error in opportunities fetch');
                
            })
        }
        // Track offline data count
        this.opportunitiesService.trackOfflineOpportunityDataCount();
      }
    } catch (error) {
      console.log('error in opportunities fetch');
        
    }
  }

  private async mapMarketingPlansToOpportunities(fullSync: boolean, accountID: string, lastModifiedForDeltaSync: any, positionString: string, opportunityId: string) {
    const oppoMarketingBusinessPlans = await this.getOpportunityMarketingPlans(fullSync, accountID, lastModifiedForDeltaSync, positionString, opportunityId);
    oppoMarketingBusinessPlans.forEach(a => {
      if (a.hasOwnProperty('opportunityid')) {
        const opportunity: Opportunity = this.opportunitiesService.opportunities.find(o => o.ID == a.opportunityid);
        if (a.hasOwnProperty('brandPlans.indskr_brandplanid') && opportunity) {
          const id = a['brandPlans.indskr_brandplanid'];
          let mbp = opportunity.marketingBusinessPlans.find(o => o.ID == id);
          if (!mbp && a['brandPlans.statecode'] != 1 && a['brandPlans.statuscode'] != 2) {
            opportunity.marketingBusinessPlans.push({
              ID: id,
              brandPlanName: a['brandPlans.indskr_name']
            } as MarketingPlan);
          }
        }
      }
    });
  }

  async aggregateOpportunities(opportunitiesRawData, fullSync?:boolean, opportunityID?:string){
    let opportunities: Opportunity[] = [];
    opportunitiesRawData.map(a=>{
      if(a && a.opportunityid){
        let opportunity: Opportunity;
        opportunity = opportunities.find(o => o.ID == a.opportunityid)
        if (!opportunity) {
          opportunity = new Opportunity(a);
          opportunity.products = [];
          if (!(opportunity.stateCode == 2 && opportunity.statusCode == 548910000 && fullSync)) {
            opportunities.push(opportunity);
          }
        }
        if(a.hasOwnProperty('opportunityProductCategories.productid')){
          if(a.hasOwnProperty('opportunityProductCategories.isCompetitorProduct') && a['opportunityProductCategories.isCompetitorProduct'] == true){
            const id = a['opportunityProductCategories.productid']
            let productCat = opportunity.competitorProducts.find(o => o.ID == id)
            if(!productCat) {
              opportunity.competitorProducts.push({
                ID: a['opportunityProductCategories.productid'],
                name: a['opportunityProductCategories.productName']
              });
            }
          }else{
            const id = a['opportunityProductCategories.productid']
            let productCat = opportunity.productCategory.find(o => o.productCategoryId == id)
            if(!productCat) {
              opportunity.productCategory.push({
                productCategoryId: a['opportunityProductCategories.productid'],
                productCategoryName: a['opportunityProductCategories.productName']
              });
            }
          }
        }
        if(a.hasOwnProperty('opportunityProducts.opportunityproductid')){
          let product = opportunity.products.find(o => o.productID == a['opportunityProducts.opportunityproductid']
                                                                      && (!a['opportunityProducts.indskr_productcategory']
                                                                      || a['opportunityProducts.indskr_productcategory']==o.productCategoryID))
          if(!product) {
            let obj = {
              productID: a['opportunityProducts.opportunityproductid'],
              productName: a['opportunityProducts.opportunityproductname'] || '',
              unitPrice: a['opportunityProducts.priceperunit'] || '',
              unitPriceFormatted: a['opportunityProducts.priceperunit_Formatted'] || '',
              quantity: a['opportunityProducts.quantity'] || '',
              total: a['opportunityProducts.extendedamount'] || '',
              totalFormatted: a['opportunityProducts.extendedamount_Formatted'] || '',
              uomid: a['opportunityProducts.uomid'] || '',
              productCategoryID: a['opportunityProducts.indskr_productcategory'] || '',
              productCategoryName: a['opportunityProducts.indskr_productcategory_Formatted'] || ''
            };
            if(opportunity.isestimatedrevenuebasedonprocedureandproducts){
              obj['total'] = obj['unitPrice'] * obj['quantity'];
              obj['totalFormatted'] = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
              ? opportunity.transactionCurrencySymbol+this.dateTimeFormatsService.getFormattedNumber(obj['total'])
              : this.dateTimeFormatsService.getFormattedNumber(obj['total']) + opportunity.transactionCurrencySymbol;
            }
            opportunity.products.push(obj);
          }
        }
        if(a.hasOwnProperty('opportunityContacts.contactid')){
           let stakeholder = opportunity.stakeholders.find(o => o.ID == a['opportunityContacts.contactid']
                                                                && (!a['roleid']
                                                                    || o.role?.roleid == a['roleid']))
            let stakeholderValue;
            if(a.hasOwnProperty('opportunityStakeholderValues.indskr_stakeholder') && a['opportunityContacts.contactid'] == a['opportunityStakeholderValues.indskr_stakeholder']){
              stakeholderValue = a['opportunityStakeholderValues.indskr_value'];
            }
           if(!stakeholder) {
            opportunity.stakeholders.push({
              ID: a['opportunityContacts.contactid'],
              firstName: a['opportunityContacts.firstname'] || '',
              lastName: a['opportunityContacts.lastname'] || '',
              isActive: (a['opportunityContacts.statuscode']
                        && (a['opportunityContacts.statuscode']===1 || a['opportunityContacts.statuscode']===548910000))? true : false,
              fullName: a['opportunityContacts.firstname']?a['opportunityContacts.firstname'] + ' ' +(a['opportunityContacts.lastname']||''):(a['opportunityContacts.lastname']||''),
              role: {
                name: a['roleid_Formatted'] || '',
                roleid: a['roleid'] || ''
              },
              stakeholderValue: stakeholderValue,
            });
          }else {
            if(stakeholderValue){
              stakeholder.stakeholderValue = stakeholderValue;
            }
          }
        }
        if(a.hasOwnProperty('opportunityCollaborators.systemuserid') && a.hasOwnProperty('roleid')){
          let collaborator = opportunity.collaborators.find(o => o.ID == a['opportunityCollaborators.systemuserid']
                                                                          && o.role.roleid == a['roleid']);
          if(!collaborator) {
            const role = this.opportunitiesService.collaboratorRoles.find(o=>o.connectionroleid == a['roleid']);
            if(role){
              opportunity.collaborators.push({
                ID: a['opportunityCollaborators.systemuserid'],
                firstName: a['opportunityCollaborators.firstname'] || '',
                lastName: a['opportunityCollaborators.lastname'] || '',
                fullName: a['opportunityCollaborators.firstname']?a['opportunityCollaborators.firstname'] + ' ' +(a['opportunityCollaborators.lastname']||''):(a['opportunityContacts.lastname']||''),
                isActive: a['rolestatus']==0?true:false,
                role: {
                  name: a['roleid_Formatted'] || '',
                  roleid: a['roleid'] || '',
                  category: role.category
                }
              });
              if(a['opportunityCollaborators.systemuserid'] == this.authService.user.xSystemUserID && a['rolestatus']==0){
                opportunity.collaboratorType = role.category;
              }
            }
          }
        }


        if(a.hasOwnProperty('productManager.systemuserid')){
          opportunity.productManager = {
            fullName : a['productManager.firstname'] + a['productManager.lastname'] || '',
            id : a['productManager.systemuserid']
          }
        }

        if(a.hasOwnProperty('opportunityNotes.annotationid')){
          const id = a['opportunityNotes.annotationid']
          let note = opportunity.opportunityNotes.find(o => o.noteId == id)
          if(!note) {
            opportunity.opportunityNotes.push(new IONote({
              annotationid: id,
              activityid:'',
              contactid:'',
              accountid:'',
              createdon:new Date(a['opportunityNotes.createdon']).getTime().toString(),
              notetext:a['opportunityNotes.notetext'],
              ownerName:a['opportunityNotes.ownerid_Formatted'],
              ownerid:a['opportunityNotes.ownerid'],
              isdocument:a['opportunityNotes.isdocument'],
              documentbody:'',
              filename:a['opportunityNotes.filename'],
              filesize:a['opportunityNotes.filesize'],
              mimetype:a['opportunityNotes.mimetype'],
              isDeleted: false,
              pendingPushForDynamics:false,
              updated:false
            }));
          }
        }
        if(a.hasOwnProperty('opportunityActivities.activityid')){
          const id = a['opportunityActivities.activityid']
          let activity = opportunity.activitiesByOpportunity.find(o => o.ID == id)
          if(!activity) {
            opportunity.activitiesByOpportunity.push({
              ID: id,
              subject:a['opportunityActivities.subject'],
              scheduledEnd:a['opportunityActivities.scheduledend']?(a['opportunityActivities.scheduledend']): null,
              state: a['opportunityActivities.statecode'],
              type: ActivityType.Other,
              ownerid: a['opportunityActivities.ownerid']
            });
          }
        }
        if(a.hasOwnProperty('opportunityTasks.activityid')){
          const id = a['opportunityTasks.activityid']
          let activity = opportunity.activitiesByOpportunity.find(o => o.ID == id)
          if(!activity) {
            opportunity.activitiesByOpportunity.push({
              ID: id,
              subject:a['opportunityTasks.subject'],
              scheduledEnd:a['opportunityTasks.scheduledend']?(a['opportunityTasks.scheduledend']): null,
              state: a['opportunityTasks.statecode'],
              type: ActivityType.FollowUp,
              ownerid: a['opportunityTasks.ownerid']
            });
          }
        }
        if(a.hasOwnProperty('opportunityCompetitors.competitorid')){
          const id = a['opportunityCompetitors.competitorid'];
          let activity = opportunity.competitors.find(o => o.ID == id)
          if(!activity) {
            opportunity.competitors.push({
              ID: id,
              name:a['opportunityCompetitors.name'],
            });
          }
        }
        if(a.hasOwnProperty('opportunityReasons.indskr_opportunityreasonid')){
          const id = a['opportunityReasons.indskr_opportunityreasonid'];
          let item = opportunity.opportunityCloseReasons.find(o => o.reasonId == id)
          if(!item) {
            opportunity.opportunityCloseReasons.push({
              reasonId: id,
              reasonName:a['opportunityReasons.indskr_reason'],
            });
          }
        }
        
        if(opportunity.ownerID == this.authService.user.xSystemUserID){
          opportunity.isTeamOpportunity = false;
          opportunity.collaboratorType = CollaboratorType.OWNER;
        }
        else{
          opportunity.isTeamOpportunity = true;
        }
      }
    })
    if(fullSync){
      this.opportunitiesService.opportunities = opportunities;
    }
    else{
      opportunities.map(oppo=>{
        if(!oppo.trackAction || (oppo.trackAction &&  oppo.trackAction == 'Download')){
          // var index = this.opportunitiesService.opportunities.findIndex(o=> {
          //   return (o.ID == oppo.ID && !o.pendingPushToDynamics) || (o.offlineId && o.offlineId == oppo.offlineId && !o.pendingPushToDynamics)
          // })
          //if(index >-1){
            if(oppo.stateCode == 2 && oppo.statusCode == 548910000){
              this.opportunitiesService.removeOpportuntiy(oppo).catch(err=>{
                console.log('Error occured while removing opportunity\nError: '+err);
              });;
            }
            else if(!oppo.isTeamOpportunity || oppo.collaborators.some(col=>col.ID == this.authService.user.xSystemUserID && col.isActive)){
              this.opportunitiesService.addUpdateOpportuntiy(oppo);
            }
            else{
              this.opportunitiesService.removeOpportuntiy(oppo).catch(err=>{
                console.log('Error occured while removing opportunity\nError: '+err);
              });;
            }
          //}
        }
        if(oppo.trackAction &&  oppo.trackAction == 'Remove'){
          let index = this.opportunitiesService.opportunities.findIndex(o=> o.ID == oppo.ID)
          if(index>-1){
            this.opportunitiesService.opportunities[index].stateCode = 2;
            this.opportunitiesService.opportunities[index].statusCode = 548910000;
            this.opportunitiesService.removeOpportuntiy(oppo).catch(err=>{
              console.log('Error occured while removing opportunity\nError: '+err);
            });
          }
        }
      })
      // this.opportunitiesService.opportunities = this.opportunitiesService.opportunities.filter(p=>{
      //   return !(p.statusCode == 548910000 && p.stateCode == 2)
      // })
    }
    if(!this.authService.hasFeatureAction(FeatureActionsMap.DISCONTINUE_OPPORTUNTIY)){
      _.remove(this.opportunitiesService.opportunities, o=>o.statusCode==548910002);
    }
  }

  public async getOpportunityProducts(fullSync? : boolean, accountID?:string, lastModifiedForDeltaSync?: any, positionString?: string, opportunityId?: string) {
    try {
        // fill position filter
        let fetchXml = fetchQueries.opportunityManagement.fetchOpportunitiesProductsAndNotes.split('{positionIDs}').join(positionString);
        let hourDifference;
        //fill delta sync filter
        let now = new Date();
        if(fullSync){
          fetchXml = fetchXml.replace('{deltaSyncFilter}', '');
        }
        else{
          let deltaSyncFilter;
          if(lastModifiedForDeltaSync){
            hourDifference = differenceInHours(
              now,
              new Date(lastModifiedForDeltaSync)
            )
            //add one to make sure we take care of fractional difference in hours
            hourDifference += 1
            const entityname = 'opportunity'
            deltaSyncFilter = fetchQueries.deltaSyncFilter.split('{entityName}').join(entityname)
            deltaSyncFilter = deltaSyncFilter.replace('{hourDifference}',hourDifference)
            deltaSyncFilter = deltaSyncFilter.replace('{entityID}',entityname+'id')
          }
          else deltaSyncFilter = ''
          fetchXml = fetchXml.replace('{deltaSyncFilter}', deltaSyncFilter)
        }

        let opportunityFilter = '';
        if (opportunityId) {
          opportunityFilter = `<filter type="and">
                                  <condition attribute="opportunityid" operator="eq" value="{0}" />
                                </filter>`.replace('{0}', opportunityId);
        }
        fetchXml = fetchXml.replace("{opportunityIdFilter}", opportunityFilter);

        //fill account filter
        if(accountID){
          let accountIDFilter = `<filter><condition attribute="customerid" operator="eq" value="`+accountID+`"/></filter>`
          fetchXml = fetchXml.replace('{accountFilter}',accountIDFilter )
        }
        else{
          fetchXml = fetchXml.replace('{accountFilter}','')
        }
        let response = await this.dynamicsClient.executeFetchQuery('opportunities',fetchXml)
        return response;
    } catch (error) {
      return [];
    }
  }

  public async getOpportunityCompetitors(fullSync? : boolean, accountID?:string, lastModifiedForDeltaSync?: any, positionString?: string, opportunityId?: string) {
    try {
        // fill position filter
        let fetchXml = fetchQueries.opportunityManagement.fetchOpportunitiesCompetitors.split('{positionIDs}').join(positionString);
        let hourDifference;
        //fill delta sync filter
        let now = new Date();
        if(fullSync){
          fetchXml = fetchXml.replace('{deltaSyncFilter}', '');
        }
        else{
          let deltaSyncFilter;
          if(lastModifiedForDeltaSync){
            hourDifference = differenceInHours(
              now,
              new Date(lastModifiedForDeltaSync)
            )
            //add one to make sure we take care of fractional difference in hours
            hourDifference += 1
            const entityname = 'opportunity'
            deltaSyncFilter = fetchQueries.deltaSyncFilter.split('{entityName}').join(entityname)
            deltaSyncFilter = deltaSyncFilter.replace('{hourDifference}',hourDifference)
            deltaSyncFilter = deltaSyncFilter.replace('{entityID}',entityname+'id')
          }
          else deltaSyncFilter = ''
          fetchXml = fetchXml.replace('{deltaSyncFilter}', deltaSyncFilter)
        }

        let opportunityFilter = '';
        if (opportunityId) {
          opportunityFilter = `<filter type="and">
                                  <condition attribute="opportunityid" operator="eq" value="{0}" />
                                </filter>`.replace('{0}', opportunityId);
        }
        fetchXml = fetchXml.replace("{opportunityIdFilter}", opportunityFilter);

        //fill account filter
        if(accountID){
          let accountIDFilter = `<filter><condition attribute="customerid" operator="eq" value="`+accountID+`"/></filter>`
          fetchXml = fetchXml.replace('{accountFilter}',accountIDFilter )
        }
        else{
          fetchXml = fetchXml.replace('{accountFilter}','')
        }
        let response = await this.dynamicsClient.executeFetchQuery('opportunities',fetchXml)
        return response;
    } catch (error) {
      return [];
    }
  }

  public async getOpportunityMarketingPlans(fullSync?: boolean, accountID?: string, lastModifiedForDeltaSync?: any, positionString?: string, opportunityId?: string) {
    try {
      if (!this.authService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN)) return [];
      // fill position filter
      let fetchXml = fetchQueries.opportunityManagement.fetchOpportunitiesMarketingPlans.split('{positionIDs}').join(positionString);
      let hourDifference;
      //fill delta sync filter
      let now = new Date();
      if (fullSync) {
        fetchXml = fetchXml.replace('{deltaSyncFilter}', '');
      }
      else {
        let deltaSyncFilter;
        if (lastModifiedForDeltaSync) {
          hourDifference = differenceInHours(
            now,
            new Date(lastModifiedForDeltaSync)
          )
          //add one to make sure we take care of fractional difference in hours
          hourDifference += 1
          const entityname = 'opportunity'
          deltaSyncFilter = fetchQueries.deltaSyncFilter.split('{entityName}').join(entityname)
          deltaSyncFilter = deltaSyncFilter.replace('{hourDifference}', hourDifference)
          deltaSyncFilter = deltaSyncFilter.replace('{entityID}', entityname + 'id')
        }
        else deltaSyncFilter = ''
        fetchXml = fetchXml.replace('{deltaSyncFilter}', deltaSyncFilter)
      }

      let opportunityFilter = '';
      if (opportunityId) {
        opportunityFilter = `<filter type="and">
                                  <condition attribute="opportunityid" operator="eq" value="{0}" />
                                </filter>`.replace('{0}', opportunityId);
      }
      fetchXml = fetchXml.replace("{opportunityIdFilter}", opportunityFilter);

      //fill account filter
      if (accountID) {
        let accountIDFilter = `<filter><condition attribute="customerid" operator="eq" value="` + accountID + `"/></filter>`
        fetchXml = fetchXml.replace('{accountFilter}', accountIDFilter)
      }
      else {
        fetchXml = fetchXml.replace('{accountFilter}', '')
      }
      let response = await this.dynamicsClient.executeFetchQuery('opportunities', fetchXml)
      return response;
    } catch (error) {
      return [];
    }
  }

  public async getQuotesData(fullSync? : boolean, accountID?:string, lastModifiedForDeltaSync?: any, positionString?: string) {
    try {
        await this.disk.retrieve(DB_KEY_PREFIXES.OPPORTUNITY_QUOTES, true).then((doc)=>{
          //offlineDataStored = doc
          if(doc && doc.raw){
            this.opportunitiesService.qoutes = doc.raw
          }
          else {
            this.opportunitiesService.qoutes = [];
            fullSync = true;
          }
        })

        // fill position filter
        let fetchXml = fetchQueries.opportunityManagement.fetchQuotes.split('{positionIDs}').join(positionString);
        let hourDifference;
        //fill delta sync filter
        let now = new Date();
        if(fullSync){
          fetchXml = fetchXml.replace('{deltaSyncFilter}', '');
        }
        else{
          let deltaSyncFilter;
          if(lastModifiedForDeltaSync){
            hourDifference = differenceInHours(
              now,
              new Date(lastModifiedForDeltaSync)
            )
            //add one to make sure we take care of fractional difference in hours
            hourDifference += 1
            const entityname = 'quote'
            deltaSyncFilter = fetchQueries.deltaSyncFilter.split('{entityName}').join(entityname)
            deltaSyncFilter = deltaSyncFilter.replace('{hourDifference}',hourDifference)
            deltaSyncFilter = deltaSyncFilter.replace('{entityID}',entityname+'id')
          }
          else deltaSyncFilter = '';
          if (!accountID) {
            fetchXml = fetchXml.replace('{deltaSyncFilter}', deltaSyncFilter)
          }
        }

        //fill account filter
        if(accountID){
          let accountIDFilter = `<filter><condition attribute="accountid" operator="eq" value="`+accountID+`"/></filter>`
          fetchXml = fetchXml.replace('{accountFilter}',accountIDFilter);
          fetchXml = fetchXml.replace('{deltaSyncFilter}', '');
        }
        else{
          fetchXml = fetchXml.replace('{accountFilter}','')
        }
        await this.dynamicsClient.executeFetchQuery('opportunities',fetchXml).then(async (res)=>{
          console.log('quotes', res);
          if(!fullSync){
            const deletedQuotes = await this.fetchDeletedQuotes(hourDifference);
            console.log('deleted quotes', deletedQuotes);
            res.push(...deletedQuotes);
          }

          if (accountID) {
            const quotesIds = res.map((quote) => quote['quotes.quoteid']);
            this.opportunitiesService.qoutes = this.opportunitiesService.qoutes.filter((quote) => {
              quote.accountID === accountID && quotesIds.includes(quote.quoteID)
            });
            console.log(quotesIds)
          }

          this.aggregateQuotes(res, fullSync);
          this.disk.updateOrInsert(DB_KEY_PREFIXES.OPPORTUNITY_QUOTES, (doc)=>{
            doc = {
                raw: []
            };
            doc.raw = this.opportunitiesService.qoutes;
            return doc;
          })
          await this.getAgreements(fullSync, accountID, lastModifiedForDeltaSync, positionString);
        },
        (error)=>{

        })
      //}
    } catch (error) {

    }
  }

  async fetchDeletedQuotes(hourDifference?: any){
    try{
      let responseForDeletedQuotes = [];
      let fetchXML;
        if(hourDifference){
          fetchXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
          <entity name="indskr_trackchange">
            <order attribute='createdon' descending='false'/>
            <attribute name="createdon" alias="track_action_CreatedOn"/>
            <attribute name="indskr_action" alias="track_action"/>
            <attribute name="indskr_entityid" alias="quotes.quoteid"/>
            <filter type="and">
              <condition attribute="createdon" operator="last-x-hours" value="`+ hourDifference+`" />
              <condition value="quote" attribute="indskr_entityname" operator="eq" />
              <condition value="548910001" attribute="indskr_action" operator="eq" />
            </filter>
            </entity>
            </fetch>`
        }
      responseForDeletedQuotes = await this.dynamicsClient.executeFetchQuery('indskr_trackchanges',fetchXML)
      return responseForDeletedQuotes;
    }catch (error){
      console.log(error);
    }
  }

  async aggregateQuotes(quotesRawData, fullSync?:boolean){
    let quotes: Quote[] = [];
    quotesRawData.map(a=>{
      try {
        if(a['quotes.quoteid']){
          let quote: Quote;
          quote = quotes.find(o=>o.quoteID == a['quotes.quoteid'])
          if(!quote) {
            quote = new Quote(a);
            quote.quoteProducts = []
            quotes.push(quote);
          }
          if(a.hasOwnProperty('quoteProducts.productid')){
            let product = quote.quoteProducts.find(o => o.productID == a['quoteProducts.productid'])
            if(!product) {
              quote.quoteProducts.push({
                productID: a['quoteProducts.productid'],
                productName: a['quoteProducts.productname'] || '',
                unitPrice: a['quoteProducts.priceperunit'] || '',
                unitPriceFormatted: a['quoteProducts.priceperunit_Formatted'] || '',
                quantity: a['quoteProducts.quantity'] || '',
                total: a['quoteProducts.extendedamount'] || '',
                totalFormatted: a['quoteProducts.extendedamount_Formatted'] || '',
              });
            }
          }
        }
      } catch (error) {
        console.error('Error occored while aggregating quotes data: '+error);
      }
    })
    if(fullSync){
      this.opportunitiesService.qoutes = quotes
    }
    else{
      quotes.map(quote=>{
        if(!quote.trackAction || (quote.trackAction &&  quote.trackAction == 'Download')){
          const idx = this.opportunitiesService.qoutes.findIndex(o=>o.quoteID==quote.quoteID);
          if(idx>-1){
            this.opportunitiesService.qoutes[idx] = quote;
          }
          else{
            this.opportunitiesService.qoutes.push(quote);
          }
        }
        if(quote.trackAction &&  quote.trackAction == 'Remove'){
          let index = this.opportunitiesService.qoutes.findIndex(o=> o.quoteID == quote.quoteID)
          if(index>-1){
            this.opportunitiesService.qoutes.splice(index, 1);
          }
        }
      })
    }
  }

  public async getAgreements(fullSync? : boolean, accountID?:string, lastModifiedForDeltaSync?:any, positionString? : string) {
    try {
        await this.disk.retrieve(DB_KEY_PREFIXES.OPPORTUNITY_AGREEMENTS, true).then((doc)=>{
          if(doc && doc.raw){
            this.opportunitiesService.agreements = doc.raw
          }
          else {
            this.opportunitiesService.agreements = [];
          }
        })
        //fill position filter
        let fetchXml = fetchQueries.opportunityManagement.fetchAgreements.split('{positionIDs}').join(positionString);

        //fill delta sync filter
        let now = new Date();
        let deltaSyncFilter, hourDifference
        if(fullSync){
          fetchXml = fetchXml.replace('{deltaSyncFilter}', '');
        }
        else{
          if(lastModifiedForDeltaSync){
            hourDifference = differenceInHours(
              now,
              new Date(lastModifiedForDeltaSync)
            )
            //add one to make sure we take care of fractional difference in hours
            hourDifference += 1
            const entityname = 'indskr_agreement'
            deltaSyncFilter = fetchQueries.deltaSyncFilter.split('{entityName}').join(entityname)
            deltaSyncFilter = deltaSyncFilter.replace('{hourDifference}',hourDifference)
            deltaSyncFilter = deltaSyncFilter.replace('{entityID}',entityname+'id')
          }
          else deltaSyncFilter = ''
          fetchXml = fetchXml.replace('{deltaSyncFilter}', deltaSyncFilter)
        }

        //fill account filter
        if(accountID){
          let accountIDFilter = `<filter><condition attribute="accountid" operator="eq" value="`+accountID+`"/></filter>`
          fetchXml = fetchXml.replace('{accountFilter}',accountIDFilter )
        }
        else{
          fetchXml = fetchXml.replace('{accountFilter}','')
        }
        await this.dynamicsClient.executeFetchQuery('opportunities',fetchXml).then(async (res)=>{
          console.log('agreements', res)
          if(!fullSync){
            const deletedAgreements = await this.fetchDeletedAgreements(hourDifference);
            console.log('deleted agreements', deletedAgreements);
            res.push(...deletedAgreements);
          }
          this.aggregateAgreements(res, fullSync);
          this.disk.updateOrInsert(DB_KEY_PREFIXES.OPPORTUNITY_AGREEMENTS, (doc)=>{
            doc = {
                raw: []
            };
            doc.raw = this.opportunitiesService.agreements;
            return doc;
          })
        },
        (error)=>{

        })
      //}
    } catch (error) {

    }
  }

  async fetchDeletedAgreements(hourDifference?: any){
    try{
      let responseForDeletedAgreements = [];
      let fetchXML;
        if(hourDifference){
          fetchXML = `<fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
          <entity name="indskr_trackchange">
            <order attribute='createdon' descending='false'/>
            <attribute name="createdon" alias="track_action_CreatedOn"/>
            <attribute name="indskr_action" alias="track_action"/>
            <attribute name="indskr_entityid" alias="agreements.indskr_agreementid"/>
            <filter type="and">
              <condition attribute="createdon" operator="last-x-hours" value="`+ hourDifference+`" />
              <condition value="indskr_agreement" attribute="indskr_entityname" operator="eq" />
              <condition value="548910001" attribute="indskr_action" operator="eq" />
            </filter>
            </entity>
            </fetch>`
        }
      responseForDeletedAgreements = await this.dynamicsClient.executeFetchQuery('indskr_trackchanges',fetchXML)
      return responseForDeletedAgreements;
    }catch (error){
      console.log(error);
    }
  }

  async aggregateAgreements(agreementsRawData, fullSync?:boolean){
    let agreements: Agreement[] = [];
    agreementsRawData.map(a=>{
      try {
        if(a['agreements.indskr_agreementid']){
          let agreement: Agreement;
          agreement = agreements.find(o=>o.agreementID == a['agreements.indskr_agreementid'] &&
                                      o.opportunityID == a['opportunityid'])
          if(!agreement) {
            agreement = new Agreement(a);
            agreements.push(agreement);
          }
        }
      } catch (error) {
        console.error('Error occored while aggregating aggrements data: '+error);
      }
    })
    if(fullSync){
      this.opportunitiesService.agreements = agreements
    }
    else{
      agreements.map(agrmnt=>{
        if(!agrmnt.trackAction || (agrmnt.trackAction &&  agrmnt.trackAction == 'Download')){
          var idx = this.opportunitiesService.agreements.findIndex(o=>o.agreementID==agrmnt.agreementID && o.opportunityID == agrmnt.opportunityID);
          if(idx>-1){
            this.opportunitiesService.agreements[idx] = agrmnt;
          }
          else{
            this.opportunitiesService.agreements.push(agrmnt);
          }
        }
        if(agrmnt.trackAction &&  agrmnt.trackAction == 'Remove'){
          _.remove(this.opportunitiesService.agreements,(o=>o.agreementID==agrmnt.agreementID))
        }
      })
    }
  }

  public async uploadOfflineOpportunities(isPartialUpload = false, maxRecordCountForPartialUpload = 10):Promise<any> {
    let opportunityData: Opportunity[];
    await this.disk.retrieve(DB_KEY_PREFIXES.OPPORTUNITIES, true).then((doc) => {
      if (doc && doc.raw) {
        opportunityData = doc.raw
      }
      else {
        opportunityData = [];
      }
    });
    if(opportunityData.length !== 0){
      let offlineData:Opportunity[] = opportunityData.filter(opportunity => opportunity.pendingPushToDynamics);
      if(offlineData.length !== 0){
        if (isPartialUpload) {
          offlineData = offlineData.splice(0, maxRecordCountForPartialUpload);
        }
        return this.updateOpportunity({onDynamics:true,onLocalDatabase:true,onLocalCopy:true,operationDetail:{code:'OFFLINEUPLOAD',message:'Offline opportunities upload'}},offlineData).catch(err=>{
          console.log("Error occored while uploading opportunity\nData:"+offlineData+"\nError:"+err);
        });
      }
    }
  }

  private async createOpportunityOnline(payload) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.opportunityManagement.CREATE_OPPORTUNITY;
    // Create new opportuntiy on dynamics and return promise with success or failure
    return this.http.post(url, payload).toPromise();
  }

  private async updateOpportunityOnline(id,payload) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.opportunityManagement.UPDATE_OPPORTUNITY;
    url = url.replace('{{opportunityid}}', id)
    // Update opportuntiy on dynamics and return promise with success or failure
    return this.http.patch(url, payload).toPromise();
  }

  private async closeOpportunityOnline(opportunity:Opportunity,closeType) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.opportunityManagement.CLOSE_OPPORTUNITY;
    url = url.replace('{{opportunityid}}', opportunity.ID);
    url = url.replace('{{closeType}}', closeType == 'closeaslost'? 'Lose':'Win');
    let payload = {
      "description" : opportunity.closeDescriptionText,
    }
    return this.http.patch(url, payload).toPromise();
  }

  private async scrapOpportunityOnline(opportunity: Opportunity) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.opportunityManagement.DELETE_OPPORTUNITY;
    url = url.replace('{{opportunityid}}', opportunity.ID)
    return this.http.delete(url).toPromise();
  }

  private async updateOpportunityStatusOnline(opportunity:Opportunity) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.opportunityManagement.UPDATE_OPPORTUNITY_STATUS;
    url = url.replace('{{opportunityid}}', opportunity.ID);
    let payload = {
      "statuscode": opportunity.statusCode,
      "description" : opportunity.closeDescriptionText || '',
      "indskr_opportunityreason" :opportunity.opportunityReasonID
    }
    if((payload.statuscode == 4 || payload.statuscode == 3) && opportunity.closedValue != null && opportunity.closedValue != undefined ){
      payload['actualvalue'] = opportunity.closedValue;
    }
    if(opportunity.opportunityCloseReasons && opportunity.opportunityCloseReasons.length > 0){
      payload['opportunityCloseReasons'] = opportunity.opportunityCloseReasons.map(a=> a.reasonId);
    }
    return this.http.patch(url, payload).toPromise();
  }

  private async uploadOpportunityOnline(payload) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.opportunityManagement.OFFLINE_CREATE_OPPORTUNITY;
    // Update opportuntiy on dynamics and return promise with success or failure
    return this.http.post(url, payload).toPromise();
  }

  public async updateOpportunityNoteOnline(payload,opportunityId) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.opportunityManagement.UPSERT_OPPORTUNITY_NOTE;
    url = url.replace('{{opportunityid}}',opportunityId)
    // Update opportunity note on dynamics and return promise with success or failure
    return this.http.put(url, payload).toPromise();
  }

  public async createOpportunity(action: OperationDetail, data: Array<Opportunity>): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (data.length == 0) reject(this.translate.instant('NO_DATA_PASSED_FOR_OPERATION'));
      let checkNextAction: boolean = true;
      if (action.onDynamics) {
        if (data && data.length == 1) {
          let serviceDTO = this.getServiceDTO(data[0]);
          delete serviceDTO.appCreatedDate;
          delete serviceDTO.productCategory;
          delete serviceDTO.products;
          // delete serviceDTO.stakeHolders;
          delete serviceDTO.collaborators;
          delete serviceDTO.accountplanid;
          delete serviceDTO.accountplanobjectiveid;
          delete serviceDTO.competitorProducts;
          delete serviceDTO.competitors;
          delete serviceDTO.countryid;
          delete serviceDTO.indskr_opportunityreason;
          delete serviceDTO.indskr_parentopportunity;
          // delete serviceDTO.indskr_primaryspecialty;
          // delete serviceDTO.indskr_subspecialty;
          // delete serviceDTO.marketingBusinessPlans;
          delete serviceDTO.actualRevenue;
          delete serviceDTO.discount;
          delete serviceDTO.estimatedRevenue;
          await this.createOpportunityOnline(serviceDTO).then(info => {
            // Succesfully created on dynamics
            if (info && info['opportunityid']) {
              data[0].ID = info['opportunityid'];
              data[0].pendingPushToDynamics = false;
            }
            if (!action.onLocalDatabase && !action.onLocalCopy) {
              resolve(this.translate.instant('NO_SUCCESSFULLY_CREATED_ON_SERVER'));
              checkNextAction = false;
            }
          }).catch(error => {
            // Handle any error scenario
            checkNextAction = true; // As per current expected behaviour still create opportunity in offline db
          });
        } else {
          console.log('Got offline data as:' + data);
          resolve('');
          // can be used forbulk data upload on dynamics
        }
      }
      if (action.onLocalDatabase && checkNextAction) {
        //Initialise document id and get offline DTO for each activity
        if (data) {
          let now = new Date();
          let offlineData:Opportunity[];
          let pendingPushCount: number = 0;
          await this.disk.retrieve(DB_KEY_PREFIXES.OPPORTUNITIES, true).then((doc)=>{
            if(doc && doc.raw){
              offlineData = doc.raw
            }
            else {
              offlineData = [];
            }
          });
          for (let i = 0; i < data.length; i++) {
            let opportunity = data[i];
            //opportunity.lastUpdated = now.getTime();
            let idx = offlineData.findIndex(value => value.ID == opportunity.ID);
            if (idx >= 0) {
              if (!offlineData[idx].pendingPushToDynamics && opportunity.pendingPushToDynamics) {
                pendingPushCount++;
              }
              offlineData[idx] = opportunity;
            }else{
              offlineData.push(opportunity);
              if (opportunity.pendingPushToDynamics) {
                pendingPushCount++;
              }
            }
          };

          if (offlineData && Array.isArray(offlineData)) {
            try {
              await this.disk.updateOrInsert(DB_KEY_PREFIXES.OPPORTUNITIES, (doc) => {
                // doc = {
                //   raw: [],
                //   lastModified:
                // };
                doc.raw = offlineData;
                //doc.lastModified
                return doc;
              })

              // Track offline data count
              this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.OPPORTUNITY, pendingPushCount);
            } catch (error) {
              reject(this.translate.instant('ERROR_WHILE_SAVING_OFFLINE_DATA') + error);
              checkNextAction = false;
            }
            if (!action.onLocalCopy) {
              resolve(this.translate.instant('SUCCESSFULLY_CREATED_ON_OFFLINE_DB'));
              checkNextAction = false;
            }
          }
        }
      }
      if (action.onLocalCopy && checkNextAction) {
        // push into local array of order-activities
        if (data) {
          data.forEach(opportunity => {
            this.opportunitiesService.addUpdateOpportuntiy(opportunity).then(success => {
              resolve('Successfully added opportunity');
            }).catch(err => {
              reject('Error Occured while saving opportunity into local array' + err);
            })
          })
        }
      }
    });
  }

  public async updateOpportunity(action: OperationDetail, data: Array<Opportunity>): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (data.length == 0) reject(this.translate.instant('NO_DATA_PASSED_FOR_OPERATION'));
      let checkNextAction: boolean = true;
      if (action.onDynamics) {
        if (data && data.length == 1 && (!action.operationDetail || action.operationDetail.code !== 'OFFLINEUPLOAD')) {
          if (!data[0].ID.includes('offline')) {
            let currentStatusRes = await this.dynamicsClient.retrieveAll('opportunities',
                                                                ['statecode','statuscode'],
                                                                `opportunityid eq '${data[0].ID}'`
                                                              ).then(res => res.value[0]).catch(()=> []);
            if(currentStatusRes && currentStatusRes['statecode']!=0){
              if(currentStatusRes['statuscode']==548910002) this.notificationService.notify(this.translate.instant('OM_ERROR_DISCONTINUED_TO_OPEN'),'opportunityManagement');
              else this.notificationService.notify(this.translate.instant('OM_ERROR_CLOSE_TO_OPEN'),'opportunityManagement');
              data[0].pendingPushToDynamics = false;
              checkNextAction = false;
              this.events.publish(EventName.OPPORTUNITYERROR_CLOSE_TO_OPEN);
              reject('Opportunity is in readonly mode');
            }
            else{
              let serviceDTO = this.getServiceDTO(data[0]);
              // Manually delete createdOn property for update operation
              if (serviceDTO.hasOwnProperty('appCreatedDate')) {
                delete serviceDTO.appCreatedDate;
              }
              if (serviceDTO.hasOwnProperty('businessUnitId')) {
                delete serviceDTO.businessUnitId;
              }
              if (serviceDTO.hasOwnProperty('erCalculatedOnApp')) {
                delete serviceDTO.erCalculatedOnApp;
              }
              if (serviceDTO.hasOwnProperty('indskr_contact')) {
                delete serviceDTO.indskr_contact;
              }
              if (serviceDTO.hasOwnProperty('indskr_primarytarget')) {
                delete serviceDTO.indskr_primarytarget;
              }
              if(action.operationDetail?.message && action.operationDetail.message == 'Update_opportunity_status'){
                await this.updateOpportunityStatusOnline(data[0]).then(info => {
                  // Succesfully updated on dynamics
                  data[0].pendingPushToDynamics = false;
                  if (!action.onLocalDatabase && !action.onLocalCopy) {
                    resolve('Successfully updated on server');
                    checkNextAction = false;
                  }
                }).catch(err => {
                  // Handle any error scenario
                  checkNextAction = true; // As per current expected behaviour still push the updates to offline db
                });
              }
              else {
                try {
                  switch (action.operationDetail?.code){
                    case 'OPPORTUNITYPRODUCTADD':
                    case 'updateproducts':
                      delete serviceDTO.productCategory;
                      delete serviceDTO.stakeHolders;
                      delete serviceDTO.stakeholderValues;
                      delete serviceDTO.collaborators;
                      delete serviceDTO.appointmentId;
                      delete serviceDTO.surveyResponses;
                      delete serviceDTO.competitors;
                      delete serviceDTO.competitorProducts;
                      break;
                    case'updatecustomers':
                      delete serviceDTO.productCategory;
                      delete serviceDTO.products;
                      delete serviceDTO.collaborators;
                      delete serviceDTO.appointmentId;
                      delete serviceDTO.surveyResponses;
                      delete serviceDTO.competitors;
                      delete serviceDTO.competitorProducts;
                      break;
                    case 'updatesurgeryproducts':
                      delete serviceDTO.collaborators;
                      delete serviceDTO.appointmentId;
                      delete serviceDTO.surveyResponses;
                      delete serviceDTO.competitors;
                      delete serviceDTO.competitorProducts;
                      break;
                    case 'updatesurgery':
                      delete serviceDTO.productCategory;
                      delete serviceDTO.products;
                      delete serviceDTO.collaborators;
                      delete serviceDTO.appointmentId;
                      delete serviceDTO.surveyResponses;
                      delete serviceDTO.competitors;
                      delete serviceDTO.competitorProducts;
                    case'updateproductcategory':
                      delete serviceDTO.stakeHolders;
                      delete serviceDTO.stakeholderValues;
                      delete serviceDTO.collaborators;
                      delete serviceDTO.appointmentId;
                      delete serviceDTO.surveyResponses;
                      delete serviceDTO.competitors;
                      delete serviceDTO.competitorProducts;
                      break;
                    case 'updatecollaborators':
                      delete serviceDTO.productCategory;
                      delete serviceDTO.products;
                      delete serviceDTO.stakeHolders;
                      delete serviceDTO.stakeholderValues;
                      delete serviceDTO.appointmentId;
                      delete serviceDTO.surveyResponses;
                      delete serviceDTO.competitors;
                      delete serviceDTO.competitorProducts;
                      break;
                    case 'updateappointmentid':
                      delete serviceDTO.productCategory;
                      delete serviceDTO.products;
                      delete serviceDTO.stakeHolders;
                      delete serviceDTO.stakeholderValues;
                      delete serviceDTO.collaborators;
                      delete serviceDTO.surveyResponses;
                      delete serviceDTO.competitors;
                      delete serviceDTO.competitorProducts;
                      break;
                    case 'updateopportunitybu':
                    case 'updateopportunitytype':
                    case 'updateopportunitydesc':
                    case 'updateopportunityaccountplan':
                    case 'updateopportunityobjective':
                    case 'updatecloseddate':
                    case 'updateestimatedvalue':
                    case 'updateactualvalue':
                    case 'updateopportunitydiscount':
                    case 'updatepurchaseprocess':
                    case 'updatecompetitorbusinessvalue':
                      delete serviceDTO.productCategory;
                      delete serviceDTO.products;
                      delete serviceDTO.stakeHolders;
                      delete serviceDTO.stakeholderValues;
                      delete serviceDTO.collaborators;
                      delete serviceDTO.appointmentId;
                      delete serviceDTO.surveyResponses;
                      delete serviceDTO.competitors;
                      delete serviceDTO.competitorProducts;
                      break;
                    case 'updateopportunitycountry':
                      if(!data[0].isrevenuesystemcalculated){
                        delete serviceDTO.productCategory;
                        delete serviceDTO.products;
                        delete serviceDTO.stakeHolders;
                        delete serviceDTO.stakeholderValues;
                        delete serviceDTO.collaborators;
                        delete serviceDTO.appointmentId;
                        delete serviceDTO.surveyResponses;
                        delete serviceDTO.competitors;
                        delete serviceDTO.competitorProducts;
                      }else{
                        delete serviceDTO.productCategory;
                        delete serviceDTO.stakeHolders;
                        delete serviceDTO.stakeholderValues;
                        delete serviceDTO.collaborators;
                        delete serviceDTO.appointmentId;
                        delete serviceDTO.surveyResponses;
                        delete serviceDTO.competitors;
                        delete serviceDTO.competitorProducts;
                      }
                      break;
                    case 'updateopportunitystage':
                      delete serviceDTO.productCategory;
                      delete serviceDTO.products;
                      delete serviceDTO.stakeHolders;
                      delete serviceDTO.stakeholderValues;
                      delete serviceDTO.collaborators;
                      delete serviceDTO.appointmentId;
                      delete serviceDTO.surveyResponses;
                      delete serviceDTO.competitors;
                      delete serviceDTO.competitorProducts;
                      delete serviceDTO.indskr_primaryspecialty;
                      delete serviceDTO.indskr_subspecialty;
                      serviceDTO['stageMoved'] = data[0].stageMoved;
                      const selectedType = this.opportunitiesService.opportunityTypes.find(ot => ot.indskr_opportunitytypeid == data[0].opportunityTypeId);
                      if (selectedType && selectedType['indskr_nostageprogressafterdays'] != null) {
                        serviceDTO['indskr_progressstatus'] = data[0].indskr_progressstatus;
                      }
                      data[0].stageMoved = null;
                      break;
                    case 'updateSurveyResponse':
                      delete serviceDTO.productCategory;
                      delete serviceDTO.products;
                      delete serviceDTO.stakeHolders;
                      delete serviceDTO.stakeholderValues;
                      delete serviceDTO.collaborators;
                      delete serviceDTO.appointmentId;
                      delete serviceDTO.competitors;
                      delete serviceDTO.competitorProducts;
                      delete serviceDTO.indskr_primaryspecialty;
                      delete serviceDTO.indskr_subspecialty;
                      break;
                    case 'updatecompetitors':
                      delete serviceDTO.productCategory;
                      delete serviceDTO.products;
                      delete serviceDTO.stakeHolders;
                      delete serviceDTO.stakeholderValues;
                      delete serviceDTO.collaborators;
                      delete serviceDTO.appointmentId;
                      delete serviceDTO.competitorProducts;
                      delete serviceDTO.accountid;
                      delete serviceDTO.accountplanid;
                      delete serviceDTO.accountplanobjectiveid;
                      delete serviceDTO.countryid;
                      delete serviceDTO.opportunitySubTypeId;
                      delete serviceDTO.opportunityTypeId;
                      delete serviceDTO.procedureId;
                      delete serviceDTO.actualRevenue;
                      delete serviceDTO.discount;
                      delete serviceDTO.estimatedClosedDate;
                      delete serviceDTO.estimatedRevenue;
                      delete serviceDTO.isrevenuesystemcalculated;
                      delete serviceDTO.name;
                      delete serviceDTO.statecode;
                      delete serviceDTO.statuscode;
                      delete serviceDTO.surveyResponses;
                      delete serviceDTO.offlineOpportunityId;
                      delete serviceDTO.opportunityid;
                      delete serviceDTO.indskr_primaryspecialty;
                      delete serviceDTO.indskr_subspecialty;
                      delete serviceDTO.indskr_opportunityreason;
                      delete serviceDTO.indskr_parentopportunity;
                      delete serviceDTO.marketingBusinessPlans;
                      break;
                    case 'updatecompetitorproducts':
                      delete serviceDTO.productCategory;
                      delete serviceDTO.products;
                      delete serviceDTO.stakeHolders;
                      delete serviceDTO.stakeholderValues;
                      delete serviceDTO.collaborators;
                      delete serviceDTO.appointmentId;
                      delete serviceDTO.competitors;
                      delete serviceDTO.accountid;
                      delete serviceDTO.accountplanid;
                      delete serviceDTO.accountplanobjectiveid;
                      delete serviceDTO.countryid;
                      delete serviceDTO.opportunitySubTypeId;
                      delete serviceDTO.opportunityTypeId;
                      delete serviceDTO.procedureId;
                      delete serviceDTO.actualRevenue;
                      delete serviceDTO.discount;
                      delete serviceDTO.estimatedClosedDate;
                      delete serviceDTO.estimatedRevenue;
                      delete serviceDTO.isrevenuesystemcalculated;
                      delete serviceDTO.name;
                      delete serviceDTO.statecode;
                      delete serviceDTO.statuscode;
                      delete serviceDTO.surveyResponses;
                      delete serviceDTO.offlineOpportunityId;
                      delete serviceDTO.opportunityid;
                      delete serviceDTO.indskr_primaryspecialty;
                      delete serviceDTO.indskr_subspecialty;
                      delete serviceDTO.indskr_opportunityreason;
                      delete serviceDTO.indskr_parentopportunity;
                      delete serviceDTO.marketingBusinessPlans;
                      break;
                    case 'updateopportunitymarketingplan':
                      delete serviceDTO.productCategory;
                      delete serviceDTO.products;
                      delete serviceDTO.stakeHolders;
                      delete serviceDTO.stakeholderValues;
                      delete serviceDTO.collaborators;
                      delete serviceDTO.appointmentId;
                      delete serviceDTO.competitors;
                      delete serviceDTO.accountid;
                      delete serviceDTO.accountplanid;
                      delete serviceDTO.accountplanobjectiveid;
                      delete serviceDTO.countryid;
                      delete serviceDTO.opportunitySubTypeId;
                      delete serviceDTO.opportunityTypeId;
                      delete serviceDTO.procedureId;
                      delete serviceDTO.actualRevenue;
                      delete serviceDTO.discount;
                      delete serviceDTO.estimatedClosedDate;
                      delete serviceDTO.estimatedRevenue;
                      delete serviceDTO.isrevenuesystemcalculated;
                      delete serviceDTO.name;
                      delete serviceDTO.statecode;
                      delete serviceDTO.statuscode;
                      delete serviceDTO.surveyResponses;
                      delete serviceDTO.offlineOpportunityId;
                      delete serviceDTO.opportunityid;
                      delete serviceDTO.competitorProducts;
                      delete serviceDTO.indskr_opportunityreason;
                      delete serviceDTO.indskr_primaryspecialty;
                      delete serviceDTO.indskr_subspecialty;
                      break;
                    case 'updateProductManager':
                        delete serviceDTO.productCategory;
                        delete serviceDTO.products;
                        delete serviceDTO.stakeHolders;
                        delete serviceDTO.stakeholderValues;
                        delete serviceDTO.collaborators;
                        delete serviceDTO.appointmentId;
                        delete serviceDTO.competitors;
                        delete serviceDTO.accountid;
                        delete serviceDTO.accountplanid;
                        delete serviceDTO.accountplanobjectiveid;
                        delete serviceDTO.countryid;
                        delete serviceDTO.opportunitySubTypeId;
                        delete serviceDTO.opportunityTypeId;
                        delete serviceDTO.procedureId;
                        delete serviceDTO.actualRevenue;
                        delete serviceDTO.discount;
                        delete serviceDTO.estimatedClosedDate;
                        delete serviceDTO.estimatedRevenue;
                        delete serviceDTO.isrevenuesystemcalculated;
                        delete serviceDTO.name;
                        delete serviceDTO.statecode;
                        delete serviceDTO.statuscode;
                        delete serviceDTO.surveyResponses;
                        delete serviceDTO.offlineOpportunityId;
                        delete serviceDTO.opportunityid;
                        delete serviceDTO.competitorProducts;
                        delete serviceDTO.indskr_opportunityreason;
                        delete serviceDTO.marketingBusinessPlans;
                        delete serviceDTO.indskr_primaryspecialty;
                        delete serviceDTO.indskr_subspecialty;
                        break;
                    case 'updateparentopportunity':
                    case 'updatespecialty':
                        delete serviceDTO.productCategory;
                        delete serviceDTO.products;
                        delete serviceDTO.stakeHolders;
                        delete serviceDTO.stakeholderValues;
                        delete serviceDTO.collaborators;
                        delete serviceDTO.appointmentId;
                        delete serviceDTO.competitors;
                        delete serviceDTO.accountid;
                        delete serviceDTO.accountplanid;
                        delete serviceDTO.accountplanobjectiveid;
                        delete serviceDTO.countryid;
                        delete serviceDTO.opportunitySubTypeId;
                        delete serviceDTO.opportunityTypeId;
                        delete serviceDTO.procedureId;
                        delete serviceDTO.actualRevenue;
                        delete serviceDTO.discount;
                        delete serviceDTO.estimatedClosedDate;
                        delete serviceDTO.estimatedRevenue;
                        delete serviceDTO.isrevenuesystemcalculated;
                        delete serviceDTO.name;
                        delete serviceDTO.statecode;
                        delete serviceDTO.statuscode;
                        delete serviceDTO.surveyResponses;
                        delete serviceDTO.offlineOpportunityId;
                        delete serviceDTO.opportunityid;
                        delete serviceDTO.competitorProducts;
                        delete serviceDTO.indskr_opportunityreason;
                        delete serviceDTO.marketingBusinessPlans;
                        break;
                        case 'updatefocusarea':
                        delete serviceDTO.productCategory;
                        delete serviceDTO.products;
                        delete serviceDTO.stakeHolders;
                        delete serviceDTO.stakeholderValues;
                        delete serviceDTO.collaborators;
                        delete serviceDTO.appointmentId;
                        delete serviceDTO.competitors;
                        delete serviceDTO.accountid;
                        delete serviceDTO.accountplanid;
                        delete serviceDTO.accountplanobjectiveid;
                        delete serviceDTO.countryid;
                        delete serviceDTO.opportunitySubTypeId;
                        delete serviceDTO.opportunityTypeId;
                        delete serviceDTO.procedureId;
                        delete serviceDTO.actualRevenue;
                        delete serviceDTO.discount;
                        delete serviceDTO.estimatedClosedDate;
                        delete serviceDTO.estimatedRevenue;
                        delete serviceDTO.isrevenuesystemcalculated;
                        //delete serviceDTO.name;
                        delete serviceDTO.statecode;
                        delete serviceDTO.statuscode;
                        delete serviceDTO.surveyResponses;
                        delete serviceDTO.offlineOpportunityId;
                        delete serviceDTO.opportunityid;
                        delete serviceDTO.competitorProducts;
                        delete serviceDTO.indskr_opportunityreason;
                        delete serviceDTO.marketingBusinessPlans;
                        break;
                        
                        
                        case 'updateOwner':
                          delete serviceDTO.productCategory;
                          delete serviceDTO.products;
                          delete serviceDTO.stakeHolders;
                          delete serviceDTO.stakeholderValues;
                          delete serviceDTO.collaborators;
                          delete serviceDTO.appointmentId;
                          delete serviceDTO.competitors;
                          delete serviceDTO.accountid;
                          delete serviceDTO.accountplanid;
                          delete serviceDTO.accountplanobjectiveid;
                          delete serviceDTO.countryid;
                          delete serviceDTO.opportunitySubTypeId;
                          delete serviceDTO.opportunityTypeId;
                          delete serviceDTO.procedureId;
                          delete serviceDTO.actualRevenue;
                          delete serviceDTO.discount;
                          delete serviceDTO.estimatedClosedDate;
                          delete serviceDTO.estimatedRevenue;
                          delete serviceDTO.isrevenuesystemcalculated;
                          delete serviceDTO.name;
                          delete serviceDTO.statecode;
                          delete serviceDTO.statuscode;
                          delete serviceDTO.surveyResponses;
                          delete serviceDTO.offlineOpportunityId;
                          delete serviceDTO.opportunityid;
                          delete serviceDTO.competitorProducts;
                          delete serviceDTO.indskr_opportunityreason;
                          delete serviceDTO.marketingBusinessPlans;
                          delete serviceDTO.productManagerId;
                          delete serviceDTO.indskr_primaryspecialty;
                          delete serviceDTO.indskr_subspecialty;
                          break;
                    default:
                  }
                  if(action.operationDetail?.code && action.operationDetail.code =='updatequantities'){
                    delete serviceDTO.product_category;
                    delete serviceDTO.collaborators;
                    delete serviceDTO.stakeHolders;
                    delete serviceDTO.stakeholderValues;
                    delete serviceDTO.appointmentId;
                    delete serviceDTO.surveyResponses;
                    delete serviceDTO.competitors;
                    delete serviceDTO.competitorProducts;
                    delete serviceDTO.indskr_primaryspecialty;
                    delete serviceDTO.indskr_subspecialty;
                  }
                } catch (error) {

                }
                await this.updateOpportunityOnline(data[0].ID,serviceDTO).then(info => {
                  // Succesfully updated on dynamics
                  //if (info && info['opportunityid']) {
                    data[0].pendingPushToDynamics = false;
                    if(data[0].competitors){
                      data[0].competitors = data[0].competitors.filter(a=> !a.isDeleted);
                    }
                    if(data[0].competitorProducts){
                      data[0].competitorProducts = data[0].competitorProducts.filter(a=> !a.isDeleted);
                    }
                    if(0){// Check for deleted status
                      data[0].isHardDeleted = true;
                    }
                  //}
                  if (!action.onLocalDatabase && !action.onLocalCopy) {
                    resolve('Successfully updated on server');
                    checkNextAction = false;
                  }
                }).catch(err => {
                  // Handle any error scenario
                  if (action.operationDetail?.code == 'updateopportunitystage') {
                    reject('');
                    checkNextAction = false;
                  } else {
                    checkNextAction = true; // As per current expected behaviour still push the updates to offline db
                  }
                });
                if (action.operationDetail?.code == 'updateopportunitystage') {
                  const stageMovementResponse = await this.dynamicsClient.retrieveAll('opportunities', ['indskr_allowstagemovementbyuser', 'indskr_allowbackwardstagemovement'], `opportunityid eq '${data[0].ID}'`).then(res => res.value[0]).catch(() => []);
                  if (stageMovementResponse['indskr_allowstagemovementbyuser'] == false) {
                    data[0].indskr_allowstagemovementbyuser = false;
                  }
                  if (stageMovementResponse['indskr_allowbackwardstagemovement'] == false) {
                    data[0].indskr_allowbackwardstagemovement = false;
                  }
                }
              }
            }
          } else {
            // Check whether to push activity online or not
            if (action.operationDetail && action.operationDetail.code && action.operationDetail.code == 'scrapopportunity') {
              // Deleting an opportunity that doesn't have dynamics id should be deleted directly from app without sending it to dynamics
              data[0].pendingPushToDynamics = false;
            }
          }
        } else {// Bulk opportunities upload on dynamics
          if(action.onDynamics){
            let payload = data.map(opportunity => {
              let serviceDTO = this.getServiceDTO(opportunity);
              // Manually delete createdOn property for update operation
              if (serviceDTO.hasOwnProperty('opportunityid') && serviceDTO.opportunityid != '' && serviceDTO.hasOwnProperty('appCreatedDate')) {
                delete serviceDTO.appCreatedDate;
                delete serviceDTO.businessUnitId;
                delete serviceDTO.erCalculatedOnApp;
              }
              return serviceDTO;
            });
            await this.uploadOpportunityOnline(payload).then(response => {
              if(response && Array.isArray(response)){
                response.forEach(opportunityResponse => {
                  if(!opportunityResponse['error'] && opportunityResponse['offlineOpportunityId'] && opportunityResponse['opportunityid']){
                    let idx = data.findIndex(a=>a.offlineId == opportunityResponse['offlineOpportunityId']);
                    if(idx>=0){
                      data[idx].ID = opportunityResponse['opportunityid'];
                      data[idx].pendingPushToDynamics = false;
                      if(data[idx].stateCode == 2 && data[idx].statusCode == 548910000){// Check for deleted status
                        data[idx].isHardDeleted = true;
                      }
                    }
                  }
                });
              }
              if (!action.onLocalDatabase && !action.onLocalCopy) {
                resolve('Successfully updated on server');
                checkNextAction = false;
              }
            }).catch(err => {
              if(action.operationDetail && action.operationDetail.code == 'OFFLINEUPLOAD'){
                checkNextAction = false;
                reject('Error Occoured while uploading opportunities'+'\ndata: '+data+'\nError: '+err);
              }
            })
          }
        }
      }
      if (action.onLocalDatabase && checkNextAction) {
        if (data) {
          let now = new Date();
          let offlineData:Opportunity[];
          await this.disk.retrieve(DB_KEY_PREFIXES.OPPORTUNITIES, true).then((doc)=>{
            if(doc && doc.raw){
              offlineData = doc.raw
            }
            else {
              offlineData = [];
            }
          });
          for (let i = 0; i < data.length; i++) {
            let opportunity = data[i];
            //opportunity.lastUpdated = now.getTime();
            let idx = offlineData.findIndex(value => {
              let flag:boolean = false;
              if(value.ID && !value.ID.includes('offline')){
                flag = value.ID == opportunity.ID ;
              }else{
                flag = value.offlineId == opportunity.offlineId;
              }
              return flag
            });
            if (idx >= 0) {
              if(opportunity.isHardDeleted || action.operationDetail.code === EventName.ASSIGN_OPPORTUNITY_OWNER){
                if (offlineData[idx].pendingPushToDynamics) {
                  this.disk.subtractOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.OPPORTUNITY, 1);
                }
                offlineData.splice(idx,1);
              }else{
                if (!offlineData[idx].pendingPushToDynamics && opportunity.pendingPushToDynamics) {
                  this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.OPPORTUNITY, 1);
                }
                offlineData[idx] = opportunity;
              }
            }else if(!opportunity.isHardDeleted){
              offlineData.push(opportunity);
              if (opportunity.pendingPushToDynamics) {
                this.disk.addOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.OPPORTUNITY, 1);
              }
            }
          };

          if (offlineData && Array.isArray(offlineData)) {
            try {
              await this.disk.updateOrInsert(DB_KEY_PREFIXES.OPPORTUNITIES, (doc) => {
                // doc = {
                //   raw: []
                // };
                doc.raw = offlineData;
                //doc.lastModified = now.getTime();
                return doc;
              })

            } catch (error) {
              reject(this.translate.instant('ERROR_WHILE_SAVING_OFFLINE_DATA') + error);
              checkNextAction = false;
            }
            if (!action.onLocalCopy) {
              resolve(this.translate.instant('SUCCESSFULLY_CREATED_ON_OFFLINE_DB'));
              checkNextAction = false;
            }
          }
        }
      }
      if (action.onLocalCopy && checkNextAction) {
        if (data) {
          data.forEach(opportunity => {
            if (opportunity.stateCode == 2 && opportunity.statusCode == 548910000) {// If opportunity is deleted
              this.opportunitiesService.removeOpportuntiy(opportunity).then(success => {
                resolve('Successfully updated order activity');
              }).catch(err => {
                reject('Error Occured while deleting opportunity from local array' + err);
              })
            } else {
              this.opportunitiesService.addUpdateOpportuntiy(opportunity).then(success => {
                this.isSelectedOpportunityUpdated = true;
                resolve('Successfully updated order activity');
              }).catch(err => {
                reject('Error Occured while updating opportunity in local array' + err);
              })
            }
          })
        }
      }
    });
  }

  public getServiceDTO(opportunity:Opportunity):any{
    let payload = {
      "offlineOpportunityId": (opportunity.offlineId) ? opportunity.offlineId : '',
      "accountid": (opportunity.accountID) ? opportunity.accountID : '',
      "accountplanid": (opportunity.accountPlanID) ? opportunity.accountPlanID : '',
      "accountplanobjectiveid": (opportunity.opportunityObjectiveID) ? opportunity.opportunityObjectiveID : '',
      "countryid": (opportunity.countryId) ? opportunity.countryId : '',
      "name": (opportunity.opportunityName) ? opportunity.opportunityName : '',
      "estimatedRevenue": (opportunity.estimatedValue) ? opportunity.estimatedValue : 0,
      "statecode": opportunity.stateCode,
      "statuscode": opportunity.statusCode,
      "discount": (opportunity.elligibleDiscount) ? opportunity.elligibleDiscount : 0,
      "isrevenuesystemcalculated": opportunity.isrevenuesystemcalculated,
      "erCalculatedOnApp": opportunity.isestimatedrevenuebasedonprocedureandproducts,
      "indskr_opportunityreason": opportunity.opportunityReasonID
    };
    if (opportunity.indskr_progressstatus) {
      payload['indskr_progressstatus'] = opportunity.indskr_progressstatus;
    }
    if(opportunity.appointmentId && !opportunity.appointmentId.includes('offline')){
      payload['appointmentId'] = opportunity.appointmentId
    }
    if(opportunity.ID && !opportunity.ID.includes('offline')){
      payload['opportunityid'] = opportunity.ID
    }
    if(opportunity.businessUnitId) {
       payload['businessUnitId'] = opportunity.businessUnitId;
    }
    if(opportunity.opportunityTypeId) {
      payload['opportunityTypeId'] = opportunity.opportunityTypeId;
    }
    if(opportunity.opportunityTypeId) {
      payload['opportunitySubTypeId'] = opportunity.opportunitySubTypeId;
    }
    if(opportunity.purchaseProcess != undefined){
      payload['purchaseProcess'] = opportunity.purchaseProcess;
    }
    if(opportunity.createdOn != undefined){
      payload['appCreatedDate'] = opportunity.createdOn;
    }
    if(opportunity.priceList){
      payload['pricelevelid'] = opportunity.priceList;
    }
    if(opportunity.estimatedDate){
      let date = new Date(parseInt(opportunity.estimatedDate));
      payload['estimatedClosedDate'] = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),
      0, 0, 0);
    }
    if(!opportunity.isrevenuesystemcalculated){
      payload['actualRevenue'] = opportunity.closedValue;
    }
    if(opportunity.surgeryId){
      payload['procedureId'] = opportunity.surgeryId
    }
    if(opportunity.productCategory){
      payload['productCategory'] = opportunity.productCategory.map(pc => {
        return { "productid": pc.productCategoryId };
      })
    }
    if(opportunity.competitorBusinessValue){
      payload['indskr_competitorsbusinessvalue'] = opportunity.competitorBusinessValue;
    }
    if(opportunity.products){
      payload['products'] = opportunity.products.filter(product => !product.isDeleted).map(product => {
        let obj = {
          "productid": product.productID,
          "quantity": product.quantity,
          "uomid": (product.uomid) ? product.uomid : '',
          "indskr_productCategoryId" : product.productCategoryID,
        };
        if(opportunity.isestimatedrevenuebasedonprocedureandproducts){
          obj['unitprice'] = product.unitPrice;
        }
        return obj;
      })
    }
    if(opportunity.stakeholders){
      payload['stakeHolders'] = opportunity.stakeholders.map(stakeholder => {
        return {
          "indskr_contactid": stakeholder.ID,
          "indskr_name": stakeholder.fullName,
          "roles": stakeholder.role.roleid,
        };
      });
      let stakeholderValues = [];
      opportunity.stakeholders.forEach(stakeholder => {
        if(!stakeholderValues.some(a=> a.indskr_stakeholder == stakeholder.ID)){
          stakeholderValues.push({
            "indskr_stakeholder": stakeholder.ID,
            "indskr_value": stakeholder.stakeholderValue,
          });
        }
      });
      payload['stakeholderValues'] = stakeholderValues
    }
    if(opportunity.collaborators){
      payload['collaborators'] = opportunity.collaborators.filter(c=>c.isActive).map(collaborator => {
        return {
          "userId": collaborator.ID,
          "roles": collaborator.role.roleid,
        };
      })
    }
    if(opportunity.competitors){
      payload['competitors'] = opportunity.competitors.map(c => {
        return {
          competitorId: c.ID,
          deleted: c.isDeleted,
        }
      })
    }
    if (opportunity.marketingBusinessPlans) {
      payload['marketingBusinessPlans'] = opportunity.marketingBusinessPlans.map(mbp => {
        return {
          id: mbp.ID,
          deleted: mbp['isDeleted'],
        }
      })
    }
    if(opportunity.competitorProducts){
      payload['competitorProducts'] = opportunity.competitorProducts.map(cp => {
        return {
          productId: cp.ID,
          deleted: cp.isDeleted,
        }
      })
    }
    if(opportunity.surveyResponseDTO){
      payload['surveyResponses'] = opportunity.surveyResponseDTO;
    }
    if(opportunity.productManager){
      payload['productManagerId'] = opportunity.productManager.id
    }
    if (opportunity.ownerID) {
      payload['ownerid'] = opportunity.ownerID
    }
    payload['indskr_parentopportunity'] = opportunity.parentOpportunityId ? opportunity.parentOpportunityId : '';
    payload['indskr_primaryspecialty'] = opportunity.primarySpecialtyId ? opportunity.primarySpecialtyId : '';
    payload['indskr_subspecialty'] = opportunity.subSpecialtyId ? opportunity.subSpecialtyId : '';
    if(opportunity.primaryTargetValue){
      payload['indskr_primarytarget'] = opportunity.primaryTargetValue;
    }
    if(opportunity.focusArea){
      payload['indskr_focusarea'] = opportunity.focusArea;
    }
    if(opportunity.contactID){
      payload['indskr_contact'] = opportunity.contactID;
    }
    return payload;
  }

  public async getConnectionRoles(fullSync : boolean, loadFromDbOnly = false){
    if(loadFromDbOnly){
      await this.disk.retrieve(DB_KEY_PREFIXES.STAKEHOLDERS_CONNECTION_ROLES, true).then((doc)=>{
        if(doc && doc.raw){
          this.opportunitiesService.stakeholderRoles = doc.raw
        }
        else {
          this.opportunitiesService.stakeholderRoles = [];
        }
      })
      await this.disk.retrieve(DB_KEY_PREFIXES.COLLABORATOR_CONNECTION_ROLES, true).then((doc)=>{
        if(doc && doc.raw){
          this.opportunitiesService.collaboratorRoles = doc.raw
        }
        else {
          this.opportunitiesService.collaboratorRoles = [];
        }
      })
      return;
    }
    try {
      let res = await this.dynamicsClient.retrieveAll('connectionroles',
                                                      ['connectionroleid','name', 'category'],
                                                      `statecode eq 0 and
                                                        (category eq 1000 or category eq 548910000
                                                          or category eq 548910001 or category eq 548910002)`
                                                    ).then(res => res.value).catch(()=> []);
      this.opportunitiesService.stakeholderRoles = res.filter(o=>o['category']==1000);
      this.opportunitiesService.collaboratorRoles = res.filter(o=>o['category']!=1000);
      this.disk.updateOrInsert(DB_KEY_PREFIXES.STAKEHOLDERS_CONNECTION_ROLES, (doc)=>{
        doc = {
            raw: []
        };
        doc.raw = this.opportunitiesService.stakeholderRoles;
        return doc;
      })
      this.disk.updateOrInsert(DB_KEY_PREFIXES.COLLABORATOR_CONNECTION_ROLES, (doc)=>{
        doc = {
            raw: []
        };
        doc.raw = this.opportunitiesService.collaboratorRoles;
        return doc;
      })
    } catch (error) {
      console.log('connectionroles service failed', error)
    }
  }

  // public async getBusinesUnitsMasterList(fullSync : boolean, loadFromDbOnly = false){
  //   if(loadFromDbOnly){
  //     await this.disk.retrieve(DB_KEY_PREFIXES.BUSINESS_UNTIS, true).then((doc)=>{
  //       if(doc && doc.raw){
  //         this.sharedDataService.businessUnits = doc.raw
  //       }
  //       else {
  //         this.sharedDataService.businessUnits = [];
  //       }
  //     })
  //     return;
  //   }
  //   let fetchXml = fetchQueries.opportunityManagement.fetchBusinessUnits;
  //   await this.dynamicsClient.executeFetchQuery('businessunits',fetchXml).then((res)=>{
  //     console.log('BUs', res);
  //     this.sharedDataService.businessUnits = res;
  //     this.disk.updateOrInsert(DB_KEY_PREFIXES.BUSINESS_UNTIS, (doc)=>{
  //       doc = {
  //           raw: []
  //       };
  //       doc.raw = this.sharedDataService.businessUnits;
  //       return doc;
  //     })
  //   })
  // }

  public async getOpportunityTypes(fullSync : boolean, loadFromDbOnly = false){
    if(loadFromDbOnly){
      await this.disk.retrieve(DB_KEY_PREFIXES.OPPORTUNITY_TYPES, true).then((doc)=>{
        if(doc && doc.raw){
          this.opportunitiesService.opportunityTypes = doc.raw
        }
        else {
          this.opportunitiesService.opportunityTypes = [];
        }
      })
      return;
    }
    try {
      let typesFetchXML: string = fetchQueries.opportunityManagement.fetchOpportunityTypes;
      typesFetchXML = typesFetchXML.replace(
        '{userBU}',
        this.authService.user.xBusinessUnitId
      );
      typesFetchXML = typesFetchXML.replace(
        '{userBU}',
        this.authService.user.xBusinessUnitId
      );
      let res = await this.dynamicsClient.executeFetchQuery(
        'indskr_opportunitytypes',
        typesFetchXML
      );
      let opportunityTypes = [];
      if (res && Array.isArray(res)) {
        res.forEach(item => {
          if (item['indskr_opportunitytypeid']) {
            let idx = opportunityTypes.findIndex(a => a.indskr_opportunitytypeid == item['indskr_opportunitytypeid']);
            const formattedOppType = {
              indskr_name: item['indskr_name'],
              indskr_opportunitytypeid: item['indskr_opportunitytypeid'],
              indskr_description: item['indskr_description'],
              indskr_productdisplay: item['opportunityBpfConfig.indskr_productdisplay'] ? item['opportunityBpfConfig.indskr_productdisplay'] : 548910000,
              indskr_ordercreation: item['opportunityBpfConfig.indskr_ordercreation'] ? item['opportunityBpfConfig.indskr_ordercreation'] : false,
              indskr_ordercreationprocess: item['opportunityBpfConfig.indskr_ordercreationprocess'] ? item['opportunityBpfConfig.indskr_ordercreationprocess'] : 548910000,
              surveyTemplateId: (item.hasOwnProperty('opportunitySurveyTemplateBU.indskr_businessunit') && item['opportunitySurveyTemplateBU.indskr_businessunit'] == this.authService.user.xBusinessUnitId) ? item['opportunitySurveyTemplate.indskr_expertinquirytemplateid'] : null,
              estimatedRevenueAutoCalculated: (item.hasOwnProperty('opportunityBpfConfig.indskr_estimatedrevenueautocalculated') ? item['opportunityBpfConfig.indskr_estimatedrevenueautocalculated'] : false),
              estimatedRevenueCalculationBasedOn: (item.hasOwnProperty('opportunityBpfConfig.indskr_estimatedrevenuecalculationbasedon') ? item['opportunityBpfConfig.indskr_estimatedrevenuecalculationbasedon'] : null),
              actualRevenueAutoCalculated: (item.hasOwnProperty('opportunityBpfConfig.indskr_actualrevenueautocalculated') ? item['opportunityBpfConfig.indskr_actualrevenueautocalculated'] : false),
              actualrevenuecalculationbasedon: (item.hasOwnProperty('opportunityBpfConfig.indskr_actualrevenuecalculationbasedon') ? item['opportunityBpfConfig.indskr_actualrevenuecalculationbasedon'] : null),
              indskr_nostageprogressafterdays: item.hasOwnProperty('opportunityBpfConfig.indskr_nostageprogressafterdays') ? item['opportunityBpfConfig.indskr_nostageprogressafterdays'] : null,
              singlestakeholder:item.hasOwnProperty('opportunityBpfConfig.indskr_singlestakeholder') ? item['opportunityBpfConfig.indskr_singlestakeholder'] : null,
              opportunitySubTypes: [],
              indskr_productmanager: item.hasOwnProperty('opportunityBpfConfig.indskr_productmanager') ? item['opportunityBpfConfig.indskr_productmanager'] : false,
              indskr_focusarea: item.hasOwnProperty('opportunityBpfConfig.indskr_focusarea') ? item['opportunityBpfConfig.indskr_focusarea'] : false,
              indskr_estimatedcloseddatemandatory: item.hasOwnProperty('opportunityBpfConfig.indskr_estimatedcloseddatemandatory') ? item['opportunityBpfConfig.indskr_estimatedcloseddatemandatory'] : null,
              indskr_productsmandatory: item.hasOwnProperty('opportunityBpfConfig.indskr_productsmandatory') ? item['opportunityBpfConfig.indskr_productsmandatory'] : null
            }
            if (idx < 0) {
              opportunityTypes.push(formattedOppType);
            } else if (item.hasOwnProperty('opportunitySurveyTemplateBU.indskr_businessunit')) {
              opportunityTypes[idx] = formattedOppType;
            }

          }
        })
      }
      
      let subTypesFetchXML: string = fetchQueries.opportunityManagement.fetchOpportunitySubTypes;
      subTypesFetchXML = subTypesFetchXML.replace(
        '{userBU}',
        this.authService.user.xBusinessUnitId
      );
      let subtypesres = await this.dynamicsClient.executeFetchQuery(
        'indskr_opportunitytypes',
        subTypesFetchXML
      );

      if(res && Array.isArray(subtypesres)){
        subtypesres.forEach(item=> {
          if(item['indskr_opportunitytypeid']){
            let idx = opportunityTypes.findIndex(a=> a.indskr_opportunitytypeid == item['indskr_opportunitytypeid']);
            if(idx >= 0){
              opportunityTypes[idx].opportunitySubTypes.push({
                indskr_opportunitysubtypeid: item['indskr_opportunitysubtype2.indskr_opportunitysubtypeid'],
                indskr_name: item['indskr_opportunitysubtype2.indskr_name'],
              })
            }
          }
        })
      }

      this.opportunitiesService.opportunityTypes = opportunityTypes;
      this.disk.updateOrInsert(DB_KEY_PREFIXES.OPPORTUNITY_TYPES, (doc)=>{
        doc = {
            raw: []
        };
        doc.raw = this.opportunitiesService.opportunityTypes;
        return doc;
      })
    } catch (error) {
      console.log('opportunity types service failed', error)
    }
  }

  // public async getOpportunityOptionSets(fullSync: boolean, loadFromDbOnly = false) {
  //   if (!this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_OPTIONSET_FIELDS)) return;
  //   this.opportunitiesService.opportunityOptionSets = [];
  //   if (loadFromDbOnly) {
  //     await this.disk.retrieve(DB_KEY_PREFIXES.OPPORTUNITY_OPTION_SETS, true).then((doc)=>{
  //       if(doc && doc.raw){
  //         this.opportunitiesService.opportunityOptionSets = doc.raw
  //       }
  //       else {
  //         this.opportunitiesService.opportunityOptionSets = [];
  //       }
  //     })
  //     return;
  //   }
  //   try {
  //     const filter = "LogicalName eq 'opportunityratingcode' or LogicalName eq 'indskr_source' or LogicalName eq 'indskr_affordability' or LogicalName eq 'indskr_gemnongem'";
  //     const res = await this.dynamicsClient.retrieveAttributes("LogicalName='opportunity'", 'Microsoft.Dynamics.CRM.PicklistAttributeMetadata', ['LogicalName', 'OptionSet'], filter, 'OptionSet($select=Options)');
  //     if (res && !_.isEmpty(res.value)) {
  //       this.opportunitiesService.opportunityOptionSets = res.value.map(v => {
  //         const key = v.LogicalName;
  //         const options = v.OptionSet.Options.map((os) => ({ value: os.Value, label: os.Label.UserLocalizedLabel.Label }));
  //         return ({ key: key, options: options });
  //       });
  //     }
  //     this.disk.updateOrInsert(DB_KEY_PREFIXES.OPPORTUNITY_OPTION_SETS, (doc)=>{
  //       doc = { raw: this.opportunitiesService.opportunityOptionSets };
  //       return doc;
  //     })
  //   } catch (error) {
  //     console.log('opportunity option sets failed', error)
  //   }
  // }

  public async getOpportunityProductsByPositions (fullSync:boolean, loadFromDBOnly:boolean = false){
    if(!this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_BY_PRODUCTS)) return;
    let lastModifiedForDeltaSync, hourDifference;
    await this.disk.retrieve(DB_KEY_PREFIXES.OPPORTUNTIY_PRODUCTS_BY_POSITION).then((doc)=>{
      if(doc && doc.raw && doc.raw.length){
        this.opportunitiesService.opportunityTypes;
        this.opportunitiesService.productsHierarchyByPosition = doc.raw;
        lastModifiedForDeltaSync = doc.lastUpdatedTime;
      }
      else{
        this.opportunitiesService.productsHierarchyByPosition  = [];
        fullSync = true;
      }
    });

    if (loadFromDBOnly) return;

    if (loadFromDBOnly) return;
    let fetchXML = fetchQueries.opportunityManagement.fetchOpportunityProductByPositions;
    let entityName = 'products';
    //add delta sync filter
    if (fullSync) {
      fetchXML = fetchXML.replace('{deltaSyncFilter}', '');
    }
    else {

      let hourDifference;
      let now = new Date();
      fetchXML = fetchQueries.opportunityManagement.fetchProductsMappingDelta;
      hourDifference = differenceInHours(
        now,
        new Date(lastModifiedForDeltaSync)
      )
      //add one to make sure we take care of fractional difference in hours
      hourDifference += 1
      fetchXML = fetchXML.replace('{hourDifference}',hourDifference);
      entityName = 'indskr_trackchanges';
    }
    let positionIds = this.authService.user.positions.map(o => {
      return o.ID
    });
    let positionString = '';
    positionIds.forEach(p => {
      positionString += '<value>' + p + '</value>'
    })
    fetchXML = fetchXML.replace('{positionIDs}',positionString);

    await this.dynamicsClient.executeFetchQuery(entityName, fetchXML).then(async (data)=>{

      if(data && Array.isArray(data)){
        if(fullSync){
          this.opportunitiesService.productsHierarchyByPosition = []; 
        }
        let checkerArray = [];
        data.forEach(item => {
          if(item.hasOwnProperty('indskr_action') && item['indskr_action'] == TrackAction.Deleted){
            if(item['categoryproductid']){
              let idx = this.opportunitiesService.productsHierarchyByPosition.findIndex(a=> a.productCategoryId == item['categoryproductid']);
              if(idx >= 0){
                this.opportunitiesService.productsHierarchyByPosition.splice(idx,1);
              }
            }
          }else if(item['categoryproductid']){
            let idx = this.opportunitiesService.productsHierarchyByPosition.findIndex(a=> a.productCategoryId == item['categoryproductid']);
            if(idx >= 0){
              this.opportunitiesService.productsHierarchyByPosition[idx].productCategoryName = item['categoryname'];
              this.opportunitiesService.productsHierarchyByPosition[idx].productCategoryStatecode = item['categorystatecode'];
              if(!checkerArray.some(a=> a == item['categoryproductid'])){
                checkerArray.push(item['categoryproductid']);
                this.opportunitiesService.productsHierarchyByPosition[idx].products = [];
              }
              if(item['skuProduct.productid']){
                let idx2 = this.opportunitiesService.productsHierarchyByPosition[idx].products.findIndex(prod => prod.productId == item['skuProduct.productid']);
                if(idx2 >= 0){
                  this.opportunitiesService.productsHierarchyByPosition[idx].products[idx2].productCategoryId = item['categoryproductid'];
                  this.opportunitiesService.productsHierarchyByPosition[idx].products[idx2].productCategoryName = item['categoryname'];
                  this.opportunitiesService.productsHierarchyByPosition[idx].products[idx2].productName = item['skuProduct.name'];
                  this.opportunitiesService.productsHierarchyByPosition[idx].products[idx2].productStatecode = item['skuProduct.statecode'];
                  this.opportunitiesService.productsHierarchyByPosition[idx].products[idx2].uomid = item['skuProduct.defaultuomid'];
                  this.opportunitiesService.productsHierarchyByPosition[idx].products[idx2].uomid = item['skuProduct.indskr_newproductintroduction'] ? item['skuProduct.indskr_newproductintroduction'] : false;
                }else {
                  this.opportunitiesService.productsHierarchyByPosition[idx].products.push({
                    productCategoryId : item['categoryproductid'],
                    productId: item['skuProduct.productid'],
                    productCategoryName : item['categoryname'],
                    productName : item['skuProduct.name'],
                    productStatecode : item['skuProduct.statecode'],
                    uomid: item['skuProduct.defaultuomid'],
                    productNumber : item['skuProduct.productnumber'],
                    indskr_newproductintroduction: item['skuProduct.indskr_newproductintroduction'] ? item['skuProduct.indskr_newproductintroduction'] : false
                  });
                }
              }
            }else{
              let newObj = {
                productCategoryId: item['categoryproductid'],
                productCategoryStatecode: item['categorystatecode'],
                productCategoryName: item['categoryname'],
                products: [],
              };
              if(item['skuProduct.productid']){
                newObj.products.push({
                  productCategoryId : item['categoryproductid'],
                  productId: item['skuProduct.productid'],
                  productCategoryName : item['categoryname'],
                  productName : item['skuProduct.name'],
                  productStatecode : item['skuProduct.statecode'],
                  uomid: item['skuProduct.defaultuomid'],
                  productNumber : item['skuProduct.productnumber'],
                  indskr_newproductintroduction: item['skuProduct.indskr_newproductintroduction'] ? item['skuProduct.indskr_newproductintroduction'] : false
                })
              }
              this.opportunitiesService.productsHierarchyByPosition.push(newObj);
              checkerArray.push(item['categoryproductid']);
            }
          }
        });
      }


      this.disk.updateOrInsert(DB_KEY_PREFIXES.OPPORTUNTIY_PRODUCTS_BY_POSITION, (doc) => {
        if (!doc || !doc.raw) {
          doc = {
            raw: []
          };
        }
        doc.raw = this.opportunitiesService.productsHierarchyByPosition;
        doc.lastUpdatedTime = new Date().getTime();
        return doc;
      });
    }, (errror)=>console.log(errror));
  }


  public async getOpportunityReasons(loadFromDBOnly: boolean = false) {
    if (loadFromDBOnly) {
      await this.disk.retrieve(DB_KEY_PREFIXES.OPPORTUNITY_REASONS, true).then((doc) => {
        if (doc && doc.raw) {
          this.opportunitiesService.opportunityReasons = doc.raw
        }
        else {
          this.opportunitiesService.opportunityReasons = [];
        }
      })
      return;
    }
    try {
      let reasonsFetchXML: string = fetchQueries.opportunityManagement.fetchOpportunityReasons;
      let res = await this.dynamicsClient.executeFetchQuery(
        'indskr_opportunityreasons',
        reasonsFetchXML
      );
      this.opportunitiesService.opportunityReasons = [];
      if (res && Array.isArray(res)) {
        res.forEach(item => {
          this.opportunitiesService.opportunityReasons.push({
            indskr_opportunityreasonid: item['indskr_opportunityreasonid'],
            indskr_reason: item['indskr_reason'],
            indskr_statusreason: item['indskr_statusreason'],
            indskr_opportunitytypeid: item['as.indskr_opportunitytype']
          })
        })
      }
      this.disk.updateOrInsert(DB_KEY_PREFIXES.OPPORTUNITY_REASONS, (doc) => {
        doc = {
          raw: []
        };
        doc.raw = this.opportunitiesService.opportunityReasons;
        return doc;
      })
    } catch (error) {
      console.log('opportunity reasons fetch failed', error)
    }
  }

  public async getCompetitorsData(fullSync:boolean, loadFromDBOnly:boolean = false, isFromMarketScan = false){
    if(!this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_COMPETITOR_DATA) && !isFromMarketScan) return;
    if(loadFromDBOnly){
      await this.disk.retrieve(DB_KEY_PREFIXES.OPPORTUNITY_COMPETITORS, true).then((doc)=>{
        if(doc && doc.raw){
          this.opportunitiesService.opportunityCompetitors = doc.raw
        }
        else {
          this.opportunitiesService.opportunityCompetitors = [];
        }
      })
      return;
    }
    try {
      let fetchXML: string = fetchQueries.opportunityManagement.fetchOpportunityCompetitors;
      fetchXML = fetchXML.replace(
        '{userBU}',
        this.authService.user.xBusinessUnitId
      );
      let res = await this.dynamicsClient.executeFetchQuery(
        'competitors',
        fetchXML
      );
      this.opportunitiesService.opportunityCompetitors = [];
      if(res && Array.isArray(res)){
        res.forEach(item=> {
          if(item['competitorid']){
            this.opportunitiesService.opportunityCompetitors.push({
              name: item['name'],
              id: item['competitorid'],
            });
          }
        })
      }
    this.disk.updateOrInsert(DB_KEY_PREFIXES.OPPORTUNITY_COMPETITORS, (doc)=>{
        doc = {
            raw: []
        };
        doc.raw = this.opportunitiesService.opportunityCompetitors;
        return doc;
      })
    } catch (error) {
      console.log('opportunity competitors fetch failed', error)
    }
  }

  public async getLookupResults(searchParams): Promise<any> {
    let fetchXml = fetchQueries.opportunityManagement.fetchCollaborators.split("{{query}}").join(searchParams);
    this.lookupSearchInProgress = true;
    let searchResult: LookupSearchResult[] = [];
    this.lookupSearchData = [];
    await this.dynamicsClient.executeFetchQueryWithPageNumber('systemusers', fetchXml, 0)
          .then((res) => {
            let systemUserId = this.authService.user.systemUserID;
            for(let r of res) {
              let data = new LookupSearchResult(r);
              if(data.id != systemUserId && data.id != this.opportunitiesService.selectedOpportunityOwnerID) {
                searchResult.push(data);
              }
            }
            this.lookupSearchData = searchResult;
            this.lookupSearchInProgress = false;
            return searchResult;
          },
          (err) => {
            console.log('Lookup search dynamics error', err);
            this.lookupSearchInProgress = false;
          })
    return searchResult;
  }

  public async getOpportunityBusinessProcessStagesByProcessInstanceId(processInstanceId: string) {
    try {
      if (processInstanceId) {
        let url: string = this.authService.userConfig.activeInstance.url + "/api/data/v9.0/RetrieveActivePath(ProcessInstanceId=" + processInstanceId + ")";
        return this.http.get<any[]>(url).toPromise();
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async getOpportunityActiveStageIdByProcessInstanceId(processInstanceId: string, schemaName: string) {
    try {
      if (processInstanceId && schemaName) {
        let fetchXML = fetchQueries.opportunityManagement.fetchActiveStageForOpportunity;
        fetchXML = fetchXML.replace('{schemaName}', schemaName);
        fetchXML = fetchXML.replace('{bpfinstanceid}', processInstanceId);
        if (schemaName == "opportunitysalesprocess") {
          // If deafult business process is mapped then apped es at the end for correct entity set name
          schemaName = schemaName+'e';
        }
        let res = await this.dynamicsClient.executeFetchQuery(
          schemaName+'s',
          fetchXML
        );
        return res;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  private _getAttributeFetchXMLFromElements(elements){
    let attributes = '';
    elements.forEach((element) => {
      if (element.metadata && element.metadata.logicalName) {
        attributes += `<attribute name="${element.metadata.logicalName}"/>`;
      }
      if(element.type == 'panel' && element.elements && element.elements.length > 0){
        attributes += this._getAttributeFetchXMLFromElements(element.elements);
      }
    });
    return attributes;
  }

  public async fetchSurveyJSAtrributesForOpportunity(opportunity:Opportunity,template:any){
    if(template.surveyConfiguration){
      let fetchXML = fetchQueries.opportunityManagement.fetchConfiguredAttributesForSurveyJSInfo;
      fetchXML = fetchXML.replace('{opportunityId}',opportunity.ID);
      let attributes:string = '';
      template.surveyConfiguration.pages[0].elements.forEach((element) => {
        if(element.metadata && element.metadata.logicalName) {
          attributes += `<attribute name="${element.metadata.logicalName}"/>`;
        }
        if(element.type == 'panel' && element.elements && element.elements.length > 0){
          const attr = this._getAttributeFetchXMLFromElements(element.elements);
          if(attr){
            attributes+= attr;
          }
        }
      });
      fetchXML = fetchXML.replace('{ATTRIBUTES}',attributes);
      const response = await this.dynamicsClient.executeFetchQuery(
        'opportunities',
        fetchXML
      );
      if(response && Array.isArray(response)){
        opportunity.surveyResponse = [];
        opportunity.surveyResponseData = {};
        opportunity.surveyResponseAdditionalData = {};
        opportunity.surveyResponseLookupData = [];
        // opportunity.surveyResponseDTO = {
        //   "lookupfields": [],
        // };
        template.surveyConfiguration.pages[0].elements.forEach((element) => {
          if(element.metadata){
            this._updateSurveyResponseForElement(element,opportunity,response);
          }else if(element.type == 'panel' && element.elements && element.elements.length > 0){
            this._updateSurveyResponseForElements(element.elements,opportunity,response);
          }
          
        });
      }
    }
  }

  private _updateSurveyResponseForElements(elements,opportunity,response){
    elements.forEach((element) => {
      if(element.metadata){
        this._updateSurveyResponseForElement(element,opportunity,response);
      }else if(element.type == 'panel' && element.elements && element.elements.length > 0){
        this._updateSurveyResponseForElements(element.elements,opportunity,response);
      }
    });
  }

  private _updateSurveyResponseForElement(element,opportunity,response){
    if(element.metadata){
      let answer:string = '';
      if(element.type == "lookup" && response[0].hasOwnProperty(`_${element.metadata.logicalName}_value@OData.Community.Display.V1.FormattedValue`)) {
        answer = response[0][`_${element.metadata.logicalName}_value@OData.Community.Display.V1.FormattedValue`];
        opportunity.surveyResponseAdditionalData[`${element.metadata.schemaName}@odata.bind`] = `/${element.metadata.target.setName}(${response[0][`_${element.metadata.logicalName}_value`]})`;
        opportunity.surveyResponseLookupData.push({
          id: response[0][`_${element.metadata.logicalName}_value`],
          name: response[0][`_${element.metadata.logicalName}_value@OData.Community.Display.V1.FormattedValue`],
          targetEntity: element.metadata.target.name,
          questionName: element.name,
        });
        // opportunity.surveyResponseDTO['lookupfields'].push({
        //   name: element.metadata.schemaName,
        //   entity: element.metadata.target.setName,
        //   id: response[0][`_${element.metadata.logicalName}_value`],
        // });
        opportunity.surveyResponseData[element.name] = response[0][`_${element.metadata.logicalName}_value`];
      }else if(response[0].hasOwnProperty(element.metadata.logicalName)){
        // if(response[0].hasOwnProperty(element.metadata.logicalName+`@OData.Community.Display.V1.FormattedValue`)){
        //   answer = response[0][element.metadata.logicalName+`@OData.Community.Display.V1.FormattedValue`];
        // }else{
        //   answer = response[0][element.metadata.logicalName];
        // }
        answer = response[0][element.metadata.logicalName];
        if(element.inputType == "date"){
          answer = format(new Date(answer), 'YYYY-MM-DD');
        }
        //opportunity.surveyResponseDTO[element.metadata.logicalName] = answer;
        opportunity.surveyResponseData[element.name] = answer;
      }
      opportunity.surveyResponse.push({
        indskr_question: element.title,
        indskr_answer: answer,
      })
    }
  }

  public async isCustomerAssessmentResponseAvailable(contacts: any) {
    let contactIdValues = '';
    contacts.forEach((p) => {
      contactIdValues += '<value>' + p.ID + '</value>'
    })
    const fetchXml = fetchQueries.opportunityManagement.fetchCustomerAssessmentRespForContact.replace("{contactIds}", contactIdValues);
    let responses = null;
    try {
      responses = (await this.dynamicsClient.executeFetchQuery('indskr_customerassessmentresponses', fetchXml));
      if (!_.isEmpty(responses)) {
        let contactIds = _.uniq(responses.map(c => c.contactId))
        return contactIds.length == contacts.length;
      }
    } catch (error) {
      console.error("Failed to fetch customer assessments: ", error);
    }
    return !_.isEmpty(responses);
  }

  public async refreshOpportunityFromQuote(quote){
    await this.uiService.displayLoader();
    await this.getOpportunitiesData(false, quote.accountId, false, false, quote.opportunityId);
    this.uiService.dismissLoader();
    this.isSelectedOpportunityUpdated = true;
  }
}
