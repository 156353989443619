import { Component, ViewChild, HostListener, Input, ElementRef } from '@angular/core';
import { CameraPreview, CameraPreviewOptions, CameraPreviewPictureOptions } from '@awesome-cordova-plugins/camera-preview/ngx';
import { IonContent } from '@ionic/angular';
import { NavigationService } from '@omni/services/navigation/navigation.service';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { DeviceService } from '@omni/services/device/device.service';
import watermark from 'watermarkjs';
import { IndPhotoCaptureViewerDataModel } from '@omni/models/indPhotoCaptureViewerDataModel';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { Photo } from '@omni/classes/store-check/photo';
import { UIService } from '@omni/services/ui/ui.service';

@Component({
  selector: 'ind-photo-capture-viewer',
  templateUrl: 'ind-photo-capture-viewer.html',
  styleUrls: ['ind-photo-capture-viewer.scss']
})
export class IndPhotoCaptureViewerComponent {

  public pageTitle: IndPageTitleViewDataModel;

  @Input() photoModel: IndPhotoCaptureViewerDataModel;

  imageStyle: { [key: string]: string } = {};
  @ViewChild('previewImg') previewImg: HTMLImageElement;
  @ViewChild('ionContent') ionContent: IonContent;
  private cameraPreviewOpts: CameraPreviewOptions = { x: 0, y: 72, width: window.innerWidth, height: window.innerHeight - 152, camera: 'rear', storeToFile: false };
  public capturedPhoto:Photo = null;
  public photos: Photo[] = [];

  public previewAvailable: boolean = false;
  public showCarousel: boolean = false;
  private screenWidth:number;
  private screenHeight:number;

  constructor(
    private cameraPreview: CameraPreview,
    private navService: NavigationService,
    public translate: TranslateService,
    private notificationService: NotificationService,
    public device: DeviceService,
    private readonly el: ElementRef,
    private readonly uiService: UIService
  ) { }

  public ngOnInit(): void {
    this.device.screenWidth.subscribe((screenWidth:number) =>{this.screenWidth=screenWidth})
    this.device.screenHeight.subscribe((screenHeight:number) =>{this.screenHeight=screenHeight})
    this.initializePageTitle();
  }

  public ionViewDidEnter() {
    this.device.lockDeviceOrientationPotrait();
    this.startCameraPreview();
  }

  async startCameraPreview() {
    const scrollElement = await this.ionContent?.getScrollElement();
    let headerHeight = 72;
    const headerEl = this.el.nativeElement.querySelector('#ph-capture-header');
    if (headerEl) {
      headerHeight = headerEl.offsetHeight;
    }
    this.cameraPreviewOpts.y = headerHeight;
    this.cameraPreviewOpts.width = scrollElement?.clientWidth;
    this.cameraPreviewOpts.height = scrollElement?.clientHeight
    this.cameraPreview.startCamera(this.cameraPreviewOpts).then(() => {
        console.log('Camera preview started');
      }, (err) => {
        console.log('Error starting camera preview', err);
        this.closepage();
      }
    );
  }

  async capturePhoto() {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'Store Check Activity', 'top', ToastStyle.DANGER);
      return;
    }
    if (this.photos.length < this.photoModel.photosLimit) {
      await this.uiService.displayLoader();
      let pictureOptions: CameraPreviewPictureOptions = null;
      console.log(this.device.deviceFlags);
      if (this.device.deviceFlags.ipad) {
        pictureOptions = { width: this.screenWidth, height: this.screenHeight, quality: 98 };
      } else {
        pictureOptions = { width: this.screenWidth * 2, height: this.screenHeight * 2, quality: 98 };
      }
      this.cameraPreview.takePicture(pictureOptions).then((base64PictureData) => {
        const watermarkText = this.getFormattedDate(new Date());
        const capturedPhoto = 'data:image/jpeg;base64,' + base64PictureData;
        watermark([capturedPhoto])
          .image(watermark.text.lowerRight(watermarkText, '52px Arial', '#ffffff', 1))
          .then(async (result: any) => {
            const now = new Date();
            const formattedDate = this.getFormattedDate(now);
            const photoModel: Photo = {
              name: this.photoModel.photoNameFormat.replace("{currentTimestamp}", formattedDate),
              base64String: result.src,
              createdAt: new Date().getTime(),
              photoOrigin: this.photoModel.photoOrigin,
              accountId: this.photoModel.accountId
            }
            this.capturedPhoto = photoModel;
            this.previewAvailable = true;
            this.cameraPreview.hide();
            await this.uiService.dismissLoader();
          })
          .catch(async (error: any) => {
            console.log('Error adding watermark:', error);
            await this.uiService.dismissLoader();
          });
      }, async (err) => {
        console.log('Error capturing photo', err);
        await this.uiService.dismissLoader();
      });
    } else {
      this.notificationService.notify(this.photoModel.errorMessage, 'Photo Capture', 'top', 'info');
    }
  }

  discardPhoto() {
    this.previewAvailable = false;
    this.capturedPhoto = null;
    this.cameraPreview.show();
  }

  savePhoto() {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('YOUR_DEVICE_IS_OFFLINE'), 'Store Check Activity', 'top', ToastStyle.DANGER);
      return;
    }
    this.photos.push(this.capturedPhoto);
    this.previewAvailable = false;
    this.capturedPhoto = null;
    this.initializePageTitle();
    this.cameraPreview.show();
    
  }

  stopCameraPreview() {
    this.cameraPreview.stopCamera().then(() => {
        console.log('Camera preview stopped');
      }, (err) => {
        console.log('Error stopping camera preview', err);
      }
    );
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.setInitialImageStyle();
  }

  onImageLoad() {
    this.setInitialImageStyle();
  }

  setInitialImageStyle() {
    if (this.previewImg) {
      const imageNaturalWidth = this.previewImg.naturalWidth;
      const imageNaturalHeight = this.previewImg.naturalHeight;
      if (imageNaturalWidth >= imageNaturalHeight) {
        this.imageStyle = { 'width': '100%', 'height': 'auto' };
      } else {
        this.imageStyle = { 'width': 'auto', 'height': '100%' };
      }
    }
  }

  private initializePageTitle() {
    let buttons = [];
    buttons.push({
      id: "scrap",
      imgSrc: 'assets/imgs/header_cancel.svg',
      name: this.translate.instant('CANCEL'),
      align: "right"
    },
      {
        id: "markComplete",
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('DONE'),
        isDisabled: _.isEmpty(this.photos),
        align: "right"
      }
    );
    this.pageTitle = {
      id: 'photo-capture-viewer-page-title',
      title: this.photoModel.pageTitle,
      controls: buttons,
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
      case 'scrap':
        this.photoModel.callbackEvent([]);
        this.closepage();
        break;
      case 'markComplete':
        this.photoModel.callbackEvent(this.photos);
        this.closepage();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  private closepage() {
    this.stopCameraPreview();
    this.device.unlockDeviceOrientation();
    this.navService.popWithPageTracking();
  }

  getFormattedDate(now: Date): string {
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  
}








