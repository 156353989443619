import { Subject, BehaviorSubject } from 'rxjs';
import { Opportunity, Quote, Agreement, LookupSearchResult, OpportunityCollaborators, OpportunityPrimaryTraget } from '../../classes/opportunity-management/opportunity.class';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { Account } from '../../classes/account/account.class';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from '../disk/disk.service';
import { TranslateService } from '@ngx-translate/core';
import { SKUProduct } from '@omni/classes/activity/surgery-order.activity.class';
import { format } from 'date-fns';
import { DateTimeFormatsService } from '../date-time-formats/date-time-formats.service';
import { DeviceService } from '../device/device.service';
import { electronApi } from "@omni/services/electron-api";
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class OpportunityManagementService {
  public opportunities: Opportunity[] = [];
  public opportunities$: Subject<Opportunity[]> = new Subject();
  public qoutes: Quote[];
  public agreements: Agreement[];
  public stakeholderRoles: any = [];
  public collaboratorRoles: any = [];
  public selectedCollaboratorList: any = [];
  public selectedOpportunityOwnerID: string = '';

  public newOpportunity$ = new BehaviorSubject<Opportunity>(null);
  public newOpportunityObserver = this.newOpportunity$.asObservable();

  private selectedCollaborators = new BehaviorSubject<OpportunityCollaborators>(null);
  public selectedCollaboratorsObserver = this.selectedCollaborators.asObservable();
  @Output() handleUpdates: EventEmitter<string> = new EventEmitter();

  public purchaseProcessOptions: Array<any> = [
    {
      text: 'Individual',
      value: 0,
    },
    {
      text: 'Committee',
      value: 1,
    },
    {
      text: 'Unknown',
      value: 2,
    }
  ];
  opportunityTypes: any = [];
  opportunityCompetitors: any = [];
  //opportunityOptionSets: any = [];
  opportunityReasons:any=[];

  public productsHierarchyByPosition : Array<{
      productCategoryId: string,
      productCategoryName: string,
      productCategoryStatecode: number,
      products:SKUProduct[]
    }> = [];
    public opportunityDynamicUrl: string;

  // public preSelectedAccount: Account;
  // public preSelectedopportunity: Opportunity

  constructor(
    private authService: AuthenticationService,
    private disk: DiskService,
    private translate: TranslateService,
    public readonly dateTimeFormatsService: DateTimeFormatsService,
    public device: DeviceService,
    private inAppBrowser: InAppBrowser,
  ) { }

  public getOpportunityDetailsbyId(id: string): Opportunity {
    if (this.opportunities && this.opportunities.length > 0) {
      return this.opportunities.find(opportunity => opportunity.ID == id
                                                    || (opportunity.offlineId
                                                    && opportunity.offlineId == id));
    } else {
      return null;
    }
  }

  public getOpportunitiesByAccountId(id: string): Array<Opportunity> {
    if (this.opportunities && this.opportunities.length > 0) {
      return this.opportunities.filter(opportunity => opportunity.accountID == id);
    } else {
      return [];
    }
  }

  public addUpdateOpportuntiy(opportunity: Opportunity) {
    return new Promise(async (resolve, reject) => {
      if (opportunity.ID) {
        let idx = this.opportunities.findIndex(value => value.ID === opportunity.ID
                                                        || (opportunity.offlineId
                                                        && opportunity.offlineId == value.offlineId));
        if (idx >= 0) {
          this.opportunities[idx] = opportunity;
          this.opportunities$.next(this.opportunities);
          resolve("Successfuly updated new opportunity");
        } else {
          this.opportunities.push(opportunity);
          this.opportunities$.next(this.opportunities);
          resolve("Successfuly added new opportunity");
        }
      } else {
        reject("Cannot add data with no id");
      }
    });
  }

  public removeOpportuntiy(opportunity: Opportunity) {
    return new Promise(async (resolve, reject) => {
      if (opportunity.ID) {
        let idx = this.opportunities.findIndex(value => value.ID === opportunity.ID
                                                        || (opportunity.offlineId
                                                        && opportunity.offlineId == value.offlineId));
        if (idx >= 0) {
          this.opportunities.splice(idx, 1);
          this.opportunities$.next(this.opportunities);
          resolve("Successfuly removed opportunity");
        } else {
          reject("No opportunity found with given id");
        }
      } else {
        reject("Cannot remove data with no id passed");
      }
    });
  }

  public getNewOpportunityObject(): Opportunity {
    let curTime:Date = new Date();
    let payload = {
      opportunityid: 'offline_opportunity_'+curTime.getTime().toString(),
      indskr_externalid: 'offline_opportunity_'+curTime.getTime().toString(),
      createdby: this.authService.user.xSystemUserID,
      createdbyusername: this.authService.user.displayName,
      ownerid: this.authService.user.xSystemUserID,
      ownername: this.authService.user.displayName,
      createdon: curTime.getTime(),
      name: this.translate.instant('OPPORTUNITY'),
    };
    return new Opportunity(payload);
  }

  public getStakeholderStringForOpportunity(opportunity):string{
    let stakeholderString:string = '';
    //find first alphabetical account
    opportunity.stakeholders.sort((contactA, contactB): number => {
        let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
        let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
        if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
        if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;
        return 0;
    });
    //Only one contact, return his name
    if (opportunity.stakeholders.length === 1) {
        stakeholderString = `${opportunity.stakeholders[0].fullName}`;
        if(!opportunity.stakeholders[0].isActive && String(opportunity.stakeholders[0].fullName).trim() != ''){
            stakeholderString += ' (Inactive)';
        }
    } else if (opportunity.stakeholders.length >= 2) {
        stakeholderString =  `${opportunity.stakeholders[0].fullName}`;
        if(!opportunity.stakeholders[0].isActive){
            stakeholderString += ` (Inactive) +${opportunity.stakeholders.length-1}`;
        }else if(opportunity.stakeholders.some((con,index) =>{
            if(index == 0) return false;
            return (!con.isActive);
        })){
            stakeholderString =  `${opportunity.stakeholders[0].fullName} +${opportunity.stakeholders.length-1} (Inactive)`;
        }else{
            stakeholderString =  `${opportunity.stakeholders[0].fullName} +${opportunity.stakeholders.length-1}`;
        }
    }
    if(!stakeholderString || stakeholderString == undefined || stakeholderString == 'undefined ') {
      return "";
    }
    else
    return stakeholderString;
  }

  trackOfflineOpportunityDataCount() {
    const offlineDataCount = this.opportunities.filter(o => o.pendingPushToDynamics === true).length;
    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.OPPORTUNITY, offlineDataCount);
  }

  public setSelectedCollaborators(selectedData: OpportunityCollaborators) {
    this.selectedCollaborators.next(selectedData);
    this.selectedCollaborators.next(null);
  }

  setOpportunityDescription(opportunity) {
    let opportunityName = '';
    if (this.authService.user.buSettings && this.authService.user.buSettings['indskr_autopopulateopportunitysubjects']) {
      // if (opportunity.createdOn) {
      //   opportunityName = new Date(parseInt(opportunity.createdOn)).getFullYear().toString();
      // }
      if (opportunity.estimatedDate) {
        opportunityName = new Date(parseInt(opportunity.estimatedDate)).getFullYear().toString();
      }

      if (opportunity.focusArea) {
        opportunityName = opportunityName === '' ? opportunity.focusArea : `${opportunityName} - ${opportunity.focusArea}`;
      }
      if (opportunity.primaryTargetValue == OpportunityPrimaryTraget.ACCOUNT) {
        if (opportunity.accountName) {
          opportunityName = opportunityName === '' ? opportunity.accountName : `${opportunityName} - ${opportunity.accountName}`;
        }
        if (opportunity.stakeholders.length > 0) {
          switch (opportunity.stakeholders.length) {
            case 1:
              if(opportunity.stakeholders[0]['role']['name']==="Champion") {
                opportunityName = opportunityName === '' ? opportunity.stakeholders[0].fullName : `${opportunityName} - ${opportunity.stakeholders[0].fullName}`;
              }
              break;
            default:
              if (opportunity.stakeholders.length > 1) {
                opportunity.stakeholders.sort((contactA, contactB): number => {
                  let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
                  let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
                  if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
                  if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;
                  return 0;
                });
                let customerName = '';
                opportunity.stakeholders.forEach(contact => {
                  if(contact['role']['name']==="Champion")
                    customerName = customerName === '' ? contact.fullName : `${customerName}, ${contact.fullName}`;
                });
                if(!_.isEmpty(customerName)) opportunityName = opportunityName === '' ? customerName : `${opportunityName} - ${customerName}`;
              }
          }
        }
      } else if (opportunity.primaryTargetValue == OpportunityPrimaryTraget.CONTACT) {
        if (opportunity.contactName) {
          opportunityName = opportunityName === '' ? opportunity.contactName : `${opportunityName} - ${opportunity.contactName}`;
        }
        if (opportunity.accountName) {
          opportunityName = opportunityName === '' ? opportunity.accountName : `${opportunityName} - ${opportunity.accountName}`;
        }
      }
      opportunityName = opportunityName == '' ? opportunity.ownerName : `${opportunityName} - ${opportunity.ownerName}`;
    } else {
      if (opportunity.estimatedDate) {
        opportunityName = new Date(parseInt(opportunity.estimatedDate)).getFullYear().toString();
      }

      if (opportunity.products.length > 0) {
        opportunityName = opportunityName === '' ? opportunity.products[0].productName : `${opportunityName} - ${opportunity.products[0].productName}`;
      }

      if (opportunity.accountName) {
        opportunityName = opportunityName === '' ? opportunity.accountName : `${opportunityName} - ${opportunity.accountName}`;
      }

      if (opportunity.stakeholders.length > 0) {
        switch (opportunity.stakeholders.length) {
          case 0:
            break;
          case 1:
            opportunityName = opportunityName === '' ? opportunity.stakeholders[0].fullName : `${opportunityName} - ${opportunity.stakeholders[0].fullName}`;
            break;
          default:
            if (opportunity.stakeholders.length > 1) {
              opportunity.stakeholders.sort((contactA, contactB): number => {
                let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
                let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
                if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
                if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;
                return 0;
              });
              let customerName = `${opportunity.stakeholders[0].fullName} + ${opportunity.stakeholders.length - 1} `
              opportunityName = opportunityName === '' ? customerName : `${opportunityName} - ${customerName}`;
              this.handleUpdates.emit(opportunityName);
            }
        }
      }

      opportunityName = opportunityName == '' ? opportunity.ownerName : `${opportunityName} - ${opportunity.ownerName}`;
    }
    return opportunityName;
  }

  openOpportunityDynamicLink(opportunity){
    this.opportunityDynamicUrl = this.authService.user.buSettings['indskr_opportunityurl'] ;
    console.log(opportunity);
    if(this.opportunityDynamicUrl){
      this.opportunityDynamicUrl = this.opportunityDynamicUrl + opportunity.ID;
      // if(this.device.deviceFlags.ios){
      //   window.open(this.opportunityDynamicUrl, '_system');
      // } 
      if (this.device.deviceFlags.electron) {
        electronApi.openExternal(this.opportunityDynamicUrl);
      }else if(this.device.deviceFlags.ios){
        this.inAppBrowser.create(this.opportunityDynamicUrl, '_system', { location: 'yes', toolbar: 'yes', zoom: 'no' });
      }else{
        window.open(this.opportunityDynamicUrl, '_system');
      }      
    }
  }

}
