import { AgendaFooterService } from './../../../services/footer/agenda-footer.service';
import { NotificationService, ToastStyle } from './../../../services/notification/notification.service';
import { EventsService } from './../../../services/events/events.service';
import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { IndPageTitleViewDataModel } from './../../../models/indPageTitleDataModel';
import { TranslateService } from '@ngx-translate/core';
import { ActivityService } from "@omni/services/activity/activity.service";
import { SurgeryOrderActivity } from "@omni/classes/activity/surgery-order.activity.class";
import { AuthenticationService } from "@omni/services/authentication.service";
import { FooterService, FooterViews } from "@omni/services/footer/footer.service";
import { MainCardViewDataModel } from "@omni/models/MainCardViewDataModel";
import { ChildNavNames, NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { UIService } from "@omni/services/ui/ui.service";
import { LoadingController, ModalController, IonNav } from "@ionic/angular";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { differenceInCalendarDays, isPast } from "date-fns";
import { DeviceService } from '@omni/services/device/device.service';
import { SurgeryOrderActivityDataService } from "@omni/data-services/surgery-order-activity/surgery-order-activity.data.service";
import { OperationDetail } from '@omni/data-services/follow-up-activity/follow-up-activity.data.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SurveyService } from '@omni/services/survey/survey.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { ProcedureTrackerActivityDataService } from '@omni/data-services/procedure-tracker-activity/procedure-tracker-activity.data.service';
import { SelectListComponent } from '@omni/components/shared/select-list/select-list';
import { FieldMaterialManagementService } from '@omni/services/field-materials/field-material-management.service';
import { ActivityOutcome, ActivityOutcomeType } from '@omni/classes/activity/activity.class';
import { MarketingPlansManagementOfflineService } from '@omni/services/marketing-management/marketing-management.service';
import { UserTypes } from '@omni/classes/activity/procedure-tracker.activity.class';
import { Opportunity } from '@omni/classes/opportunity-management/opportunity.class';
import { AppointmentActivity, CovisitorAccess } from '@omni/classes/activity/appointment.activity.class';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { AlertInfoTypes, OmniInfoAlertComponent } from '@omni/components/shared/omni-info-alert/omni-info-alert.component';
import { ProcedureContractService } from '@omni/data-services/procedure-contract/procedure-contract.service';
import { PROCEDURE_CONTRACT_STATUS, PROCEDURE_CONTRACT_TYPES } from '@omni/classes/procedure-contract/procedure-contract.class';
import { SecInfoConfigDataService } from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import { SecondaryInfoEntityName } from '@omni/classes/sec-info-config/sec-info.class';

@Component({
  selector: 'surgery-order-details',
  templateUrl: 'surgery-order-details.html',
  styleUrls: ['surgery-order-details.scss']
})
export class SurgeryOrderDetailsComponent {

  @Input() from: any;
  @Input() activity: any;
  public currentSurgeryOrderActivity: SurgeryOrderActivity;
  private isLayoverPushView: boolean;
  private layoverViewPushedFrom: string;
  public isReadOnlyMode = true;
  public isCompleteEnabled = false;
  public surgeryOrderDetailsPanePageTitle: IndPageTitleViewDataModel;
  public isInMeetingProcedureLogFlow: boolean = false;
  public noProcedureDay: boolean = false;
  public notesPlaceholder: string = '';
  public isSaveNotesEnabled: boolean = false;
  public tempNoteText: string = '';
  // date time picker
  public startTime: string;
  public endTime: string;
  public prevStartTime: string;
  public prevEndTime: string;
  public minStartDate: string;
  private ngDestroy$ = new Subject<boolean>();
  public backgroundUploadInProgress: boolean = false;
  public globalCustomerText: string;
  // public isWeeklyFlag: boolean = false;
  private surgeryOrderDetailsPageTitleText: string;
  // public hasTagAssets: boolean = false;
  public taggedAssets: [] = [];
  public isSurveyConfigured: boolean = false;
  public surveyLookupResults: Array<any> = [];
  public surveyTemplate: any;
  private isSurveyValid: boolean = false;
  public _hasTagAssetsSurveyJS: boolean = false;
  private activityOutcomeOptions: Array<ActivityOutcome> = [];
  private activityOutcomeisMandatory: boolean = false;
  public applicableOpportunities: Opportunity[] = [];
  public allModelActivities: MainCardViewDataModel[];
  public applicableMeetings: AppointmentActivity[] = [];
  tabsData: IndTabsDataModel[] = [
    {
      displayText: this.translate.instant('INFO'),
      value: 'info'
    },
    {
      displayText: this.translate.instant('OCE_TRACKING'),
      value: 'oceTracking',
      disable: false,
    }
  ];
  public surgeryOrderDetailSegment: string = this.tabsData[0].value;

  isOCETrackingEnabled = false;
  public isCovisitor = false;
  public covisitorAccess;
  public isCoowner = false;
  public isOwner = false;
  public isCancelEnabled = false;
  public isAssetBooking: boolean = false;

  constructor(
    private readonly authService: AuthenticationService,
    public readonly footerService: FooterService,
    private readonly agendaFooterService: AgendaFooterService,
    public activityService: ActivityService,
    public translate: TranslateService,
    private _CD: ChangeDetectorRef,
    public navService: NavigationService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public uiService: UIService,
    public device: DeviceService,
    private surgeryOrderDataService: SurgeryOrderActivityDataService,
    private events: EventsService,
    private notifications: NotificationService,
    private loadingController: LoadingController,
    public modalCtrl: ModalController,
    public navCtrl: IonNav,
    public readonly surveyService: SurveyService,
    private procedureTrackerDataService: ProcedureTrackerActivityDataService,
    public fieldMaterialManagementService: FieldMaterialManagementService,
    public marketingPlansManagementOfflineService: MarketingPlansManagementOfflineService,
    public procedureContractService: ProcedureContractService,
    private secondaryInfoService: SecInfoConfigDataService
  ) {
    // this.events.observe('updateMandatoryField').pipe(takeUntil(this.ngDestroy$)).subscribe((field: PageName) => {
    //   // this._updateMandatoryField(field);
    // });
  }

  async ngOnInit() {
    let hideProcedure = this.authService.user.buSettings && this.authService.user.buSettings['indskr_hideprocedureproductcategory'];
    this.isCancelEnabled = this.authService.user.buSettings && this.authService.user.buSettings['indskr_cancelprocedurelogwithreason'];
    this.isAssetBooking = this.authService.user.buSettings && this.authService.user.buSettings['indskr_assetavailabilitylogic'] == 548910001;

    if (this.surgeryOrderDataService.isProcedureProductHierarchyDataMappingInProgress && !hideProcedure) {
      this.notifications.notify('Preparing Procedure Log Products Data, Please wait...', 'SurgeryOrderDetailsComponent', "top");
    }

    this.isOCETrackingEnabled = this.authService.user.buSettings && this.authService.user.buSettings['indskr_ocetracking'];
    // this.isWeeklyFlag = (this.authService.user.procedureLogDuration === 'Weekly');
    // this.hasTagAssets = this.authService.user.hasTagAssetsToProcedureLog;

    this.currentSurgeryOrderActivity = this.activityService.selectedActivity as SurgeryOrderActivity
    if (this.activity) {
      this.currentSurgeryOrderActivity = this.activity;
    }

    this.noProcedureDay = this.currentSurgeryOrderActivity.indskr_noprocedureday;

    if (this.from) {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
      if (this.layoverViewPushedFrom === 'InMeetingProcedure') {
        this.surgeryOrderDataService.isInMeetingProcedureLogFlow = true;
      }
      if (this.layoverViewPushedFrom === 'PresentationMeetingComponent') {
        this.isInMeetingProcedureLogFlow = true;
      } else {
        this.surgeryOrderDataService.inMeetingSurgeryOrderActivity = null;
      }
    }

    this.initAllDataModels();
    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      if (this.activityService.selectedActivity instanceof SurgeryOrderActivity) {
        if (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent && this.navService.getActiveChildNavViewPageName() === PageName.SurgeryOrderDetailsComponent) {
          this.getPageTitle();
          this.initFooterViewButtons();
        }
      }
    });
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      if (this.currentSurgeryOrderActivity) {
        this.backgroundUploadInProgress = inProgress && this.currentSurgeryOrderActivity.pendingPushToDynamics;
        this.initFooterViewButtons();
        this.getPageTitle();
      }
    });
    setTimeout(() => {
      try {
        document.getElementById('maindetailslist').scrollIntoView();
      } catch (error) { }
    }, 400);
  }

  ngDoCheck() {
    if (this.surgeryOrderDataService.isSelectedSurgeryOrderActivityUpdated) {
      this.currentSurgeryOrderActivity = this.activityService.selectedActivity as SurgeryOrderActivity;
      this.initAllDataModels();
      this.surgeryOrderDataService.isSelectedSurgeryOrderActivityUpdated = false;
    }
    if (this.activityService.selectedActivity instanceof SurgeryOrderActivity) {
      if (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent && this.navService.getActiveChildNavViewPageName() === PageName.SurgeryOrderDetailsComponent) {
        this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
        this.initFooterViewButtons();
      }
    }
    if (this.from && this.from == 'InMeetingProcedure' && this.surgeryOrderDataService.isInMeetingProcedureLogFlow
      && this.navService.getActiveChildNavViewPageName() == PageName.ActivitiesDetailsPaneComponent) {
      this.initFooterViewButtons();
    }
  }

  ngOnDestroy() {
    this.events.unsubscribe('surgeryOrder:footerEvents');
    this.events.unsubscribe('reloadSurveyTemplate');
    this.noProcedureDay = false;
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  public get isReopenButtonEnabled(): boolean {
    return this.surgeryOrderDataService.isReopenButtonEnabled(this.currentSurgeryOrderActivity, this.from, {
      isOwner: this.isOwner,
      isCovisitor: this.isCovisitor,
      isCoowner: this.isCoowner,
      covisitorAccess: this.covisitorAccess,
    });
  }

  public get isScrapButtonEnabled(): boolean {
    return this.surgeryOrderDataService.isScrapButtonEnabled(this.currentSurgeryOrderActivity, this.from, {
      isOwner: this.isOwner,
      isCovisitor: this.isCovisitor,
      isCoowner: this.isCoowner,
      covisitorAccess: this.covisitorAccess
    });
  }

  public get isCompleteButtonEnabled(): boolean {

    return this.surgeryOrderDataService.isCompleteButtonEnabled(this.currentSurgeryOrderActivity, this.from, {
      isOwner: this.isOwner,
      isCovisitor: this.isCovisitor,
      isCoowner: this.isCoowner,
      covisitorAccess: this.covisitorAccess,
      noProcedureDay: this.noProcedureDay,
      // procedureContractType: this.procedureContractType,
      activityOutcomeisMandatory: this.activityOutcomeisMandatory
    });
  }

  private get _enableBackButton(): boolean {
    return window.innerWidth < 500 || (this.from && (this.from === 'AccountTimeline' ||
      this.from === 'ContactTimeline' ||
      this.from === 'InMeetingProcedure' ||
      this.from === 'ProcedureLogFromContactTool' ||
      this.from === 'ProcedureLogFromAccountTool' ||
      this.from === 'OpportunityManagementTool' ||
      this.from === 'MarketingBusinessPlanTimeline' || 
      this.from === 'MarketingPlanOpportunities' ||
      this.from === 'ProcedureLogFromProcedureContract'
    ));
  }

  initAllDataModels() {
    this.activityOutcomeOptions = this.surgeryOrderDataService.activityOutcomes.filter(a => a.indskr_omnipresenceactivity == ActivityOutcomeType.ProcedureLog);
    this.activityOutcomeisMandatory = this.activityOutcomeOptions.some(a => a.indskr_mandatory);

    this.isCoowner = this.currentSurgeryOrderActivity.coOwners?.some((coOwner) => coOwner.userId === this.authService.user.systemUserID);
    this.isOwner = this.currentSurgeryOrderActivity.ownerId === this.authService.user.xSystemUserID;
    this.isCovisitor = this.currentSurgeryOrderActivity.coVisitors?.some((coVisitors) => coVisitors.userId === this.authService.user.systemUserID);
    this.covisitorAccess = this.covisitorAccess = this.authService.user.buSettings && this.authService.user.buSettings['indskr_covisitoraccessonprocedurelog'];
    this.isReadOnlyMode =  !this.isOwner && !this.isCoowner &&
    (this.isCovisitor && (this.covisitorAccess == CovisitorAccess.READ_ACCESS || this.covisitorAccess == CovisitorAccess.MINIMUM_ACCESS)) 
    || this.currentSurgeryOrderActivity.status === 548910001 || this.currentSurgeryOrderActivity.status === 548910005;

    // this.initFooterViewButtons();
    this.getPageTitle();
    this._CD.detectChanges();
  }

  public getPageTitle() {
    let buttons = [];
    if (this._enableBackButton) {
      buttons = [{
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      }];
    }

    const shouldDisable = this.currentSurgeryOrderActivity.state !== 0 || this.device.isOffline || (!this.isOwner && !this.isCoowner && this.covisitorAccess !== CovisitorAccess.FULL_ACCESS);

    if (this.authService.hasFeatureAction(FeatureActionsMap.ASSIGN_PROCEDURE_LOG) && !this.noProcedureDay) {
      buttons.push({
        id: "assignOwnersurgeryactivity",
        imgSrc: 'assets/imgs/assign_owner_white.svg',
        name: this.translate.instant('ASSIGN'),
        align: "right",
        isDisabled: shouldDisable
      })
    }

    buttons.push(
      {
        id: "reopensurgeryactivity",
        imgSrc: 'assets/imgs/header_activity_reopen.svg',
        name: this.translate.instant('REOPEN'),
        isDisabled: this.backgroundUploadInProgress ? true : !this.isReopenButtonEnabled,
        align: "right"
      },
      {
        id: "scrapsurgeryorderactivity",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.isCancelEnabled ? this.translate.instant('DELETE_OR_CANCEL_PROCEDURE_LOG_HEADER') : this.translate.instant('SCRAP'),
        isDisabled: this.backgroundUploadInProgress ? true : !this.isScrapButtonEnabled,
        align: "right"
      },
      {
        id: "markcomplete",
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('COMPLETE'),
        isDisabled: this.backgroundUploadInProgress ? true : !this.isCompleteButtonEnabled,
        align: "right"
      },
    );
    this.surgeryOrderDetailsPanePageTitle = {
      id: 'activities-details-page-title',
      title: this.initializeheaderTitle(),
      controls: buttons,
      enableTooltipForTitle: this.secondaryInfoService.isProcedureLogSecInfoConfigured
    };
  }

  private initializeheaderTitle() {
    switch (this.currentSurgeryOrderActivity.subject) {
      case 'Procedure Log':
        this.surgeryOrderDetailsPageTitleText = this.translate.instant('NEW_ACTIVITY_PROCEDURE_LOG');
        break;
      default:
        this.surgeryOrderDetailsPageTitleText = this.currentSurgeryOrderActivity.subject;
        break;
    }
    return this.surgeryOrderDetailsPageTitleText;
  }

  public async handleButtonClick(buttonId: string) {
    if (!buttonId) return;

    if (buttonId == 'close') {
      this.onClosePage();
      return;
    }

    if (buttonId === 'assignOwnersurgeryactivity') {
      this.openUsersList();
      return;
    }

    if (buttonId === 'markcompleteagain') {
      this.handleButtonEventForSurgeryOrderActivityAfterConfirmation(buttonId);
      return;
    }

    if (buttonId == 'scrapsurgeryorderactivity' && !this.isScrapButtonEnabled) return;
    if (buttonId == 'markcomplete' && !this.isCompleteButtonEnabled) return;
    if (buttonId == 'reopensurgeryactivity' && !this.isReopenButtonEnabled) return;

    if (this.currentSurgeryOrderActivity.indskr_procedurecontract && buttonId == 'scrapsurgeryorderactivity') {
      this.notifications.notify(this.translate.instant('PROCEDURE_LOG_SCRAP_NOTIFICATION'), 'procedure log', 'top', 'info');
      return;
    }

    const isCancelEnabled = this.authService.user.buSettings && this.authService.user.buSettings['indskr_cancelprocedurelogwithreason'];
    const { role } = await this.surgeryOrderDataService.handleHeaderFooterBtnClick(buttonId, this.currentSurgeryOrderActivity, this.backgroundUploadInProgress);

    if (isCancelEnabled && buttonId === 'scrapsurgeryorderactivity' && role === 'cancel') {
      this.showCancelReasonPopover('');
      return;
    }

    if (role === 'ok') {
      this.handleButtonEventForSurgeryOrderActivityAfterConfirmation(buttonId);
    }
  }

  public onSectionHeaderControlClick(id: string) {
    this.handleButtonClick(id);
  }

  private async handleButtonEventForSurgeryOrderActivityAfterConfirmation(buttonId: string) {
    if (this.backgroundUploadInProgress) return;
    if (this.currentSurgeryOrderActivity.indskr_procedurecontract && buttonId === 'scrapsurgeryorderactivity') return;

    const action = {
      onDynamics: !this.device.isOffline,
      onLocalDatabase: true,
      onLocalCopy: true,
      operationDetail: {
        code: buttonId,
        message: this.translate.instant('OM_UPDATE_O_ACTIVITY'),
      }
    };
    const loader = await this.loadingController.create({});
    loader.present();
    this.currentSurgeryOrderActivity.pendingPushToDynamics = true;
    this.surgeryOrderDataService.updateOrderActivityStatus(action, this.currentSurgeryOrderActivity).then((succ) => {
      switch (buttonId) {
        case 'scrapsurgeryorderactivity': {
          this.activityService.publishActivityEvent({ action: "Delete", activity: this.currentSurgeryOrderActivity });
          const isLayovered = (this.layoverViewPushedFrom === 'InMeetingProcedure');
          this.events.publish('surgeryOrderActivityDeleted', this.currentSurgeryOrderActivity, isLayovered);
          if (this.layoverViewPushedFrom === 'InMeetingProcedure') {
            this.events.publish('deletedEmbeddedActivity', this.currentSurgeryOrderActivity);
            this.isInMeetingProcedureLogFlow = true;
          }
          if (this.isAssetBooking &&  !_.isEmpty(this.currentSurgeryOrderActivity.indskr_assetbookingid)) {
            let payload = [{
              "indskr_assetbookingid" : this.currentSurgeryOrderActivity.indskr_assetbookingid,
              "statuscode" : "548910005"
            }]
            this.currentSurgeryOrderActivity.assets = [];
            this.currentSurgeryOrderActivity.indskr_assetbookingid = '';
            this.surgeryOrderDataService.createUpdateAssetBooking(payload);
          }
          if (this.isInMeetingProcedureLogFlow) {
            this.onClosePage();
          } else {
            if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
              this.onClosePage();
              this.activityService.selectedActivity = null;
              this.activityService.selectedActivityAtHome = null;
              this.events.publish('refreshAgenda');
            } else if (this.navService.getActiveChildNavName() === ChildNavNames.ContactPageNavigation) {
              this.onClosePage();
            } else if (this.navService.getActiveChildNavName() === ChildNavNames.AccountPageNavigation) {
              this.onClosePage();
            }
            else if (this.navService.getActiveChildNavName() === ChildNavNames.OpportunityManagementNavigation) {
              this.onClosePage();
            } else if (this.navService.getActiveChildNavName() === ChildNavNames.MarketingPlanNavigation) {
              this.onClosePage();
            }
            else {
              this.uiService.agendaRefreshRequired = true;
            }
            this.uiService.showRightPane = false;
          }
          break;
        }
        case 'markcomplete':
        case 'markcompleteagain': {
          this.currentSurgeryOrderActivity.setYearMonthWeek();
          if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
            this.events.publish('refreshAgenda');
          }
          else {
            this.uiService.agendaRefreshRequired = true;
          }
          this.initAllDataModels();
          this.events.publish('updateEmbeddedActivity', this.currentSurgeryOrderActivity);
          this.events.publish('surgeryOrderActivityCompleted', this.currentSurgeryOrderActivity);
          break;
        }
        case 'reopensurgeryactivity': {
          if (this.layoverViewPushedFrom === 'InMeetingProcedure' || this.layoverViewPushedFrom === 'PresentationMeetingComponent') {
            this.events.publish('updateEmbeddedActivity', this.currentSurgeryOrderActivity);
            this.initAllDataModels();
          } else if (this.layoverViewPushedFrom && (this.layoverViewPushedFrom === 'AccountTimeline' || this.layoverViewPushedFrom === 'ContactTimeline' || this.from === 'OpportunityManagementTool' || this.from == 'MarketingPlanOpportunities')) {
            this.initAllDataModels();
          }
          break;
        }
        default:
          break;
      }
      loader.dismiss();

    }).catch(async err => {
      //Handle error scenario
      if (err && err.errorCode && err.errorCode === 'ONLINEONLYUPDATEFAILED') {
        this.notifications.notify(this.translate.instant('OM_WAS_A_PROBLEM_CONTACT_ADMINISTRATOR'), 'Order Details', 'top', ToastStyle.DANGER);
        // if (err.operationCode && (err.operationCode === 'approveorderactivity' || err.operationCode === 'notapproveorderactivity' || err.operationCode === 'sendorderactivityforreview' || err.operationCode === 'cancelorderactivityreview' || err.operationCode === 'autoapprovalflow' || err.operationCode === 'fulfillorderactivity' || err.operationCode === 'reopenorderactivity')) {
        //   // Fetch realtime order details
        //   await this.activityDataService.updateActivityDetails(this.currentOrderActivity);
        // }
      }

      if (err && err.errorCode && err.errorCode === 'PROCEDURECONTRACTNOTSIGNED') {
        this.openNotificationModal('PROCERDURE_CONTRACT_NO_SIGNATURE');
      }

      switch (buttonId) {
        case 'scrapsurgeryorderactivity': {
          //Check from where activity is deleted
          break;
        }
        case 'markcomplete': {
          this.currentSurgeryOrderActivity.state = 0;
          this.currentSurgeryOrderActivity.status = 1;
          break;
        }
        case 'reopensurgeryactivity': {
          this.currentSurgeryOrderActivity.state = 1;
          this.currentSurgeryOrderActivity.status = 548910001;
          break;
        }
      }
      loader.dismiss();
    });
  }

  private onClosePage() {
    const currentPage: PageName = this.navService.getCurrentPageName();
    this.surgeryOrderDataService.isInMeetingProcedureLogFlow = false;
    if (this.isLayoverPushView) {
      if (this.layoverViewPushedFrom === 'AccountTimeline') {
        this.navService.popChildNavPageWithPageTracking();
        this.footerService.initButtons(FooterViews.Contacts);
        this.activityService.selectedActivity = null;
        return;
      } else if (this.layoverViewPushedFrom === 'ContactTimeline') {
        this.activityService.selectedActivity = null;
        this.uiService.activeView = 'contactDetails';
        this.footerService.initButtons(FooterViews.Contacts);
        this.navService.popChildNavPageWithPageTracking();
        return;
      } else if (this.layoverViewPushedFrom === 'InMeetingProcedure') {
        this.modalCtrl.dismiss();
        if (currentPage === PageName.ActivitiesPageComponent) {
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.events.publish("initTimePickerLimitedMinTime");
          this.footerService.initButtons(FooterViews.Activities);
        }
        this.surgeryOrderDataService.inMeetingSurgeryOrderActivity = null;
        return;
      } else if (this.layoverViewPushedFrom === 'PresentationMeetingComponent') {
        this.uiService.activeView = 'Meeting';
        this.events.publish('deletedEmbeddedActivity', this.currentSurgeryOrderActivity);
        this.events.publish("initTimePickerLimitedMinTime");
        this.footerService.initButtons(FooterViews.PreviewMeeting);
        this.surgeryOrderDataService.inMeetingSurgeryOrderActivity = null;
      } else if (this.layoverViewPushedFrom === 'ProcedureLogFromContactTool') {
        this.uiService.activeView = 'contactDetails';
        this.footerService.initButtons(FooterViews.Contacts);
        this.navService.popChildNavPageWithPageTracking();
        return;
      } else if (this.layoverViewPushedFrom === 'ProcedureLogFromAccountTool') {
        this.uiService.activeView = 'accountDetails';
        this.footerService.initButtons(FooterViews.Accounts);
        this.navService.popChildNavPageWithPageTracking();
        return;
      }
      else if (this.layoverViewPushedFrom == 'OpportunityManagementTool'
        || this.layoverViewPushedFrom == 'AccountProfileOpportunities'
        || this.layoverViewPushedFrom == 'AccountPlanOpportunities'
        || this.layoverViewPushedFrom == 'MarketingPlanOpportunities'
      ) {
        this.footerService.initButtons('opportunityDetails');
        this.navService.popChildNavPageWithPageTracking();
        return;
      }else if(this.layoverViewPushedFrom === 'ProcedureLogFromProcedureContract'){
        this.footerService.initButtons(FooterViews.PROCEDURE_CONTRACT);
        this.navService.popChildNavPageWithPageTracking();
        return;
      }
    }
    if (this.device.shouldBeMobileView) {
      if (this.agendaFooterService.actSegment === "week") {
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selectedActivity = undefined;
        this.uiService.showRightPane = false;
        this.uiService.activeView = '';
        this.activityService.shouldScrollToPrevSelectedActivity = !this.activityService.didOpenDetailPageFromAgendaPage;
        this.activityService.didOpenDetailPageFromAgendaPage = false;
        // this.events.publish('weekview:RefreshUI');
        return;
      } else if (this.agendaFooterService.actSegment === "agenda") {
        if (this.uiService.activitiesPagePlanTabSelectedPlan === 'planTabTodo') {
          this.activityService.selectedActivity = undefined;
        }
      }
    }

    if (this.navService.getCurrentMasterPageName() === PageName.MarketingPlanManagementPageComponent) {
      this.navService.popChildNavPageWithPageTracking();
      this.footerService.initButtons(FooterViews.AccountPlanObjectives);
      return;
    }

    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.NothingSelectedView).then(() => {
      this.navService.setChildNavRightPaneView(false);
      this.uiService.updateNothingSelectedScreenMessageFor(1);
      this.uiService.showRightPane = false;
    });
  }

  private async _updateSurgeryOrderActivity(operationDetailCode: string) {
    let action: OperationDetail = {
      onDynamics: !this.device.isOffline,
      onLocalDatabase: true,
      onLocalCopy: true,
      operationDetail: {
        code: operationDetailCode,
        message: this.translate.instant('DELTA_SYNC_ORDERS_UPDATE')
      }
    };
    const newLastUpdatedTime = new Date().getTime();
    let hasOfflineChanges = this.currentSurgeryOrderActivity.pendingPushToDynamics || false;
    this.currentSurgeryOrderActivity.pendingPushToDynamics = true;
    this.currentSurgeryOrderActivity.modifiedOn = new Date();
    if (!this.device.isOffline) {
      this.uiService.displayLoader();
    }
    await this.surgeryOrderDataService.updateOrderActivity(action, [this.currentSurgeryOrderActivity], newLastUpdatedTime, hasOfflineChanges).then((succ) => {

    }).catch((err) => {

    })
    if (!this.device.isOffline) {
      this.uiService.dismissLoader();
    }
  }

  private async openUsersList() {
    let data = [];
    if (this.activityService.repUserList.length) {
      data = this.procedureTrackerDataService.users.filter((user) => user.userId != this.authService.user.xSystemUserID && user.userType && user.userType.includes(UserTypes.SALES_REP)).map(user => {
        return {
          id: user.userId,
          title: user.userFullName
        }
      });
    }

    data = _.orderBy(data, 'title');

    const userModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: data,
          isMultipleSelectionEnabled: false,
          title: this.translate.instant('USERS'),
          dividerTitle: this.translate.instant('ALL_USERS'),
          isSearchEnabled: true,
          confirmationCheckRequired: true,
          confirmationAlertData: {
            title: this.translate.instant('ARE_YOU_SURE'),
            message: this.translate.instant('ASSIGN_OWNER_WARNING'),
            confirmButtonText: this.translate.instant('ASSIGN'),
            cancelButtonText: this.translate.instant('CANCEL')
          },
          needcustomMessage: true
        }
      },
      backdropDismiss: false
    });
    userModal.present();
    userModal.onDidDismiss().then(async (obj) => {
      if (obj && obj.data && obj.data.isDone) {
        const data = obj.data;
        if (_.isEmpty(data.selectedItems)) {

        } else {
          let currentOwnerId = this.currentSurgeryOrderActivity.ownerId;
          try {
            let operationDetailCode = 'updateOwner';
            this.currentSurgeryOrderActivity.ownerId = data.selectedItems[0].id;
            this.currentSurgeryOrderActivity.ownerNameString = data.selectedItems[0].title
            let subject = this.currentSurgeryOrderActivity.subject;
            switch (this.currentSurgeryOrderActivity.customers.length) {
              case 0:
                subject = 'Procedure Log';
                break;
              case 1:
                subject = `Procedure Log - ${this.currentSurgeryOrderActivity.customers[0].fullname} - ${data.selectedItems[0].title}`;
                break;
              default:
                if (this.currentSurgeryOrderActivity.customers.length > 1) {
                  this.currentSurgeryOrderActivity.customers.sort((contactA, contactB): number => {
                    let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
                    let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
                    if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
                    if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;
                    return 0;
                  });
                  subject = `Procedure Log - ${this.currentSurgeryOrderActivity.customers[0].fullname} + ${this.currentSurgeryOrderActivity.customers.length - 1} - ${data.selectedItems[0].title}`;
                }
            }

            // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
            if (this.secondaryInfoService.isProcedureLogSecInfoConfigured) {
              subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForSalesOrder(this.currentSurgeryOrderActivity, SecondaryInfoEntityName.SalesOrder);
              this.currentSurgeryOrderActivity.subject = subject;
            }
            // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //

            this.currentSurgeryOrderActivity.subject = subject;
            await this._updateSurgeryOrderActivity(operationDetailCode);

            let currentUserIsCoowner = this.currentSurgeryOrderActivity.coOwners && this.currentSurgeryOrderActivity.coOwners.some((coOwner) => coOwner.userId === this.authService.user.xSystemUserID);
            if (currentUserIsCoowner || this.isCovisitor) return;

            if (this.navService.getCurrentPageName() === PageName.ActivitiesDetailsPaneComponent && this.device.isMobileDevice) {
              this.navService.popWithPageTracking();
            } else if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
              this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
              if (this.footerService.isActivitiesButton && this.uiService.showRightPane) this.uiService.showRightPane = false;
            } else {
              this.navService.popChildNavPageWithPageTracking();
            }

            this.activityService.removeActivity(this.currentSurgeryOrderActivity, true);
            if (!this.uiService.toolsActivityActive) { this.events.publish('refreshAgenda'); }
            else this.uiService.agendaRefreshRequired = true;
            if (this.uiService.activitiesPageTab === 'day') {
              this.events.publish('activityDeleted');
            }
            if (this.activityService.multiOptionActivityFilter && this.activityService.multiOptionActivityFilter.some(a => a.categoryPath == "Users" && (a.value == this.currentSurgeryOrderActivity.ownerId || this.currentSurgeryOrderActivity.coOwners.some((coOwner) => coOwner.userId == a.value)))) {
              // Dont remove the activity from calendar
            } else {
              this.activityService.publishActivityEvent({ action: "Delete", activity: this.activityService.selectedActivity });
            }
          } catch (error) {
            // if owner updation fails
            this.currentSurgeryOrderActivity.ownerId = currentOwnerId;

            if (this.navService.getCurrentPageName() === PageName.ActivitiesDetailsPaneComponent && this.device.isMobileDevice) {
              this.navService.popWithPageTracking();
            } else if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
              this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
              if (this.footerService.isActivitiesButton && this.uiService.showRightPane) this.uiService.showRightPane = false;
            } else {
              this.navService.popChildNavPageWithPageTracking();
            }
          }
        }
      }
    })
  }

  async segmentChanged(selectedTab: string) {
    this.surgeryOrderDetailSegment = selectedTab;
  }

  async openNotificationModal(message) {
    // const procedureContract = this.procedureContractService.findProcedureContractById(this.currentSurgeryOrderActivity.indskr_procedurecontract);

    const modal = await this.modalCtrl.create({
      component: OmniInfoAlertComponent,
      cssClass: 'omni-notification-modal',
      componentProps: {
        header: this.translate.instant('SIGNATURE_PENDING'),
        alertType: AlertInfoTypes.NOTIFICATION_MESSAGE,
        notificationMessage: this.translate.instant(message)
      }
    });

    if (this.currentSurgeryOrderActivity.indskr_procedurecontract) {
      modal.componentProps.buttons = [{ label: this.translate.instant('CONFIRM_PROCEDURE_LOG_COMPLETE'), role: 'ok' }]
    }

    await modal.present();

    await modal.onDidDismiss().then(({ data }) => {
      if (data && data.role === 'ok') {
        this.handleButtonClick('markcompleteagain');
      }
    });
  }

  // get procedureContractType() {
  //   const contractType = this.surgeryOrderDataService.findContractTypeByProcedureTypeId(this.currentSurgeryOrderActivity.indskr_proceduretype);
  //   if (!contractType) return false;
  //   return contractType.indskr_usagetype;
  // }

  public async showCancelReasonPopover(myEvent) {
    try {
      const response = await this.surgeryOrderDataService.showCancelSurgeryOrderPopover(myEvent);
      if (response && response.role && response.role == 'ok') {
        this.uiService.displayLoader();
        this.currentSurgeryOrderActivity.indskr_reasonforcancellation = response.inputs.value;
        this.currentSurgeryOrderActivity.cancellationreason = response.inputs.name;
        if (response.selectedOtherItem) {
          this.currentSurgeryOrderActivity.indskr_cancellationreasonother = response.selectedOtherItem;
        }
        if (this.isAssetBooking &&  !_.isEmpty(this.currentSurgeryOrderActivity.indskr_assetbookingid)) {
          let payload = [{
            "indskr_assetbookingid" : this.currentSurgeryOrderActivity.indskr_assetbookingid,
            "statuscode" : "548910005"
          }]
          this.currentSurgeryOrderActivity.assets = [];
          this.currentSurgeryOrderActivity.indskr_assetbookingid = '';
          this.surgeryOrderDataService.createUpdateAssetBooking(payload);
        }
        await this.surgeryOrderDataService.updateOrderActivityStatus({
          onDynamics: !this.device.isOffline,
          onLocalDatabase: true,
          onLocalCopy: true,
          operationDetail: {
            code: 'cancelsurgeryorder',
            message: this.translate.instant('OM_UPDATE_O_ACTIVITY'),
          }
        }, this.currentSurgeryOrderActivity);
        if (!this.uiService.toolsActivityActive) { this.events.publish('refreshAgenda'); }
        else this.uiService.agendaRefreshRequired = true;

        if (this.authService.user.buSettings &&
          !this.authService.user.buSettings['indskr_displaycancelledprocedurelogoncalendar']) {
          this.handleCancellation();
        }

        const isLayovered = (this.layoverViewPushedFrom === 'InMeetingProcedure');
        if (isLayovered) {
          this.events.publish('surgeryCancelled', this.currentSurgeryOrderActivity);
          this.events.publish('updateEmbeddedActivity', this.currentSurgeryOrderActivity);
          this.initAllDataModels();
        }

        this.uiService.dismissLoader();
      }
    } catch (error) {
      console.log('Error white showing cancel popover', error);
    }
  }

  handleCancellation() {
    const isLayovered = (this.layoverViewPushedFrom === 'InMeetingProcedure');
    this.events.publish('surgeryOrderActivityDeleted', this.currentSurgeryOrderActivity, isLayovered);
    if (this.layoverViewPushedFrom === 'InMeetingProcedure') {
      this.events.publish('deletedEmbeddedActivity', this.currentSurgeryOrderActivity);
      this.isInMeetingProcedureLogFlow = true;
    }
    if (this.isInMeetingProcedureLogFlow) {
      this.onClosePage();
    } else {
      if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
        this.onClosePage();
        this.activityService.selectedActivity = null;
        this.activityService.selectedActivityAtHome = null;
        this.events.publish('refreshAgenda');
      } else if (this.navService.getActiveChildNavName() === ChildNavNames.ContactPageNavigation) {
        this.onClosePage();
      } else if (this.navService.getActiveChildNavName() === ChildNavNames.AccountPageNavigation) {
        this.onClosePage();
      }
      else if (this.navService.getActiveChildNavName() === ChildNavNames.OpportunityManagementNavigation) {
        this.onClosePage();
      }
      else {
        this.uiService.agendaRefreshRequired = true;
      }
      this.uiService.showRightPane = false;
    }
  }

  private initFooterViewButtons(): boolean {
    if (!this.currentSurgeryOrderActivity) return;
    if (this.isInMeetingProcedureLogFlow) {
      this.footerService.initButtons(FooterViews.PREVIEW_MEETING_ACTIVITY);
    }
    this.events.unsubscribe('surgeryOrder:footerEvents');
    this.events.subscribe("surgeryOrder:footerEvents", (data) => {
      if (data) {
        this.handleButtonClick(data);
        data = null;
      }
    });
    switch (this.currentSurgeryOrderActivity.surgeryOrderStatusString) {
      case 'Draft':
        if (!this.isInMeetingProcedureLogFlow) {
          this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
          let btnsArr = [];
          const selectedSurgery = this.surgeryOrderDataService.productHierarchies.find(a => a.surgeryId == this.currentSurgeryOrderActivity.surgeryId);
          if (!this.isCompleteButtonEnabled || this.backgroundUploadInProgress || (selectedSurgery && selectedSurgery.surveyTemplateId && !this.isSurveyValid)) btnsArr.push('markCompleteSurgeryOrder');
          if (!this.isScrapButtonEnabled || this.backgroundUploadInProgress) btnsArr.push('scrapSurgeryOrderActivity');
          if (!this.isReopenButtonEnabled || this.backgroundUploadInProgress) btnsArr.push('reopensurgeryactivity');
          if (this.device.isOffline || this.activityService.selectedActivity.ownerId !== this.authService.user.xSystemUserID && this.authService.hasFeatureAction(FeatureActionsMap.ASSIGN_PROCEDURE_LOG)) btnsArr.push('assignOwnersurgeryactivity');
          this.footerService.disableButton(btnsArr);
        }
        this.isReadOnlyMode = false;
        break;
      case 'Completed':
        if (!this.isInMeetingProcedureLogFlow) {
          this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
          let btnsArr = ['scrapSurgeryOrderActivity', 'markCompleteSurgeryOrder'];
          if (!this.isReopenButtonEnabled || this.backgroundUploadInProgress) btnsArr.push('reopensurgeryactivity');
          if (this.authService.hasFeatureAction(FeatureActionsMap.ASSIGN_PROCEDURE_LOG)) btnsArr.push('assignOwnersurgeryactivity');
          this.footerService.disableButton(btnsArr);
        }
        this.isReadOnlyMode = true;
        break;
      case 'Canceled':
        if (!this.isInMeetingProcedureLogFlow) {
          this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
          let btnsArr = ['markCompleteSurgeryOrder', 'reopensurgeryactivity', 'assignOwnersurgeryactivity'];
          if (this.isCancelEnabled) {
            btnsArr.push('scraporcancelSurgeryOrderActivity')
          } else {
            btnsArr.push('scrapSurgeryOrderActivity');
          }
          this.footerService.disableButton(btnsArr);
        }
        this.isReadOnlyMode = true;
        break;
      default:
        if (!this.isInMeetingProcedureLogFlow) {
          this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
        }
        break;
    }
    if (this.currentSurgeryOrderActivity.ownerId != this.authService.user.systemUserID && (this.currentSurgeryOrderActivity.coOwners && !this.currentSurgeryOrderActivity.coOwners.some((coOwner) => coOwner.userId === this.authService.user.systemUserID))) {
      this.isReadOnlyMode = true;
    }
    if (this.from === PageName.AccountPlanActivities) {
      let btnsArr = ['scrapSurgeryOrderActivity', 'markCompleteSurgeryOrder', 'reopensurgeryactivity', 'assignOwnersurgeryactivity'];
      this.footerService.disableButton(btnsArr);
      this.isReadOnlyMode = true;
    }
    if (this.from === PageName.AccountPlanActivities) {
      let btnsArr = ['scrapSurgeryOrderActivity', 'markCompleteSurgeryOrder', 'reopensurgeryactivity', 'assignOwnersurgeryactivity'];
      this.footerService.disableButton(btnsArr);
      this.isReadOnlyMode = true;
    }
  }

}
