<ion-header>
    <!-- <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="true" [(searchText)]="resourceSearchText" (onControlClick)="onPageTitleControlClick($event)" (onSearchChange)="onInput($event)" (onSearchFocus)="ionSearchFocus($event)" (onSearchBarClick)="clickSearchArea($event)"></ind-header-left> -->
    <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="hideFilterButton" [(searchText)]="resourceSearchText" [searchDebounce]="debounceTime"  (onControlClick)="onPageTitleControlClick($event)" (onSearchChange)="onInput($event)" (onSearchFocus)="ionSearchFocus($event)" (ionBlur)="onSearchBlur($event)"
        (onSearchBarClick)="clickSearchArea($event)"  (onFilterClick)="openFilterMenu($event)" [filterButtonBadgeCount]="getFilterButtonBadgeCount()" (onEnterClick)="onClickEnter($event)"></ind-header-left>
    <ion-item-divider *ngIf="resourceService.viewMode!=resourceMode.DOCUMENTS" class="keyControlsArea" [ngClass]="{'platform-ios-btn':deviceService.deviceFlags.ios}" color="light">
        <ion-button slot="start" icon-only fill="clear" *ngIf="resourceService.viewMode == resourceMode.MENU" ion-button color="primary" (click)="selectMode()">
            <div *ngIf="!resourceService.isSelectMode">{{'SELECT' | translate}}</div>
            <div *ngIf="resourceService.isSelectMode">{{'CANCEL' | translate}}</div>
        </ion-button>
        <ion-button slot="start" icon-only fill="clear" *ngIf="deviceService.isNativeApp && resourceService.isSelectMode">
            <ion-checkbox (ionChange)="selectAll()" [(ngModel)]="isSelectAll"></ion-checkbox>
            {{'ALL' | translate}}
        </ion-button>
        <ion-button slot="start" icon-only fill="clear" *ngIf="deviceService.isNativeApp && resourceService.isSelectMode" [disabled]="!hasSelectedResources || deviceService.isOffline" (click)='bulkDownloadResources()'>
            {{!hasSelectedResources ? this.translate.instant('DOWNLOAD') : (!downloadButtonState ? this.translate.instant('UNDOWNLOAD') : this.translate.instant('DOWNLOAD'))}}
        </ion-button>
        <ion-button slot="start" icon-only fill="clear" *ngIf="resourceService.isSelectMode && !showRemoveTag" [disabled]="!hasSelectedResources " (click)='createNewTag()'>
            {{this.translate.instant('CREATE_NEW_TAG')}}
        </ion-button>
        <ion-button slot="start" icon-only fill="clear" *ngIf="resourceService.isSelectMode  && !showRemoveTag" [disabled]="!hasSelectedResources " (click)="addToTag()" >
            {{ this.translate.instant('ADD_TO_TAG')}}
        </ion-button>
        <ion-button slot="end" icon-only fill="clear" color="primary" *ngIf=" resourceService.isSelectMode && showRemoveTag"  [disabled]="!hasSelectedResources " (click)="removeContactsFromTag(selectedTagData)">
          {{'REMOVE_FROM_TAGS' | translate }}
      </ion-button>
        <ion-button *ngIf="viewMode != resourceMode.DOCUMENTS && !resourceService.isSelectMode" item-left icon-only fill="clear" color="primary" (click)="saveAdvancedSearch()" [disabled]="!searching || disableSaveSearch">
            {{ 'SAVE_SEARCH' | translate}}
        </ion-button>
    </ion-item-divider>
    <div class="selectedSearchSuggestions" color="light" *ngIf="selectedSuggestionsData && selectedSuggestionsData.length">
        <ion-icon name="ios-close-circle" (click)="removeAllSelectedSuggestions()" class="clearSuggestions"></ion-icon>
        <span *ngFor="let suggestion of selectedSuggestionsData" class="suggestionNameBubble">
      {{suggestion.selectedFacet}}
      <img src="assets/imgs/close_pill.svg" class="removeButton" (click)="removeSelectedSuggestion(suggestion)" />
    </span>
    </div>
    <div class="selectedSearchSuggestions" color="light" *ngIf="selectedFilterData && selectedFilterData.length ">
      <span *ngFor="let filter of filterNameBubble; let i=index">
        <ion-text *ngIf="filter.isSavedFilter && i==0" class="category-name"> {{filter.savedFilterName}} : </ion-text>
        <ion-text *ngIf="!filter.isSavedFilter" class="category-name"> {{filter.categoryName}} : </ion-text>
        <span *ngFor="let facet of filter.suggestion" class="suggestionNameBubble">
          {{facet.selectedFacet}}
          <img src="assets/imgs/close_pill.svg" class="removeButton" (click)="removefilterSelectedSuggestion(facet, filter)" />
        </span>
      </span>
    </div>
</ion-header>

<ion-content no-padding [ngClass]="{'vectorimageleft' : !searching && resources.length === 0, 'show-progress-bar-pad': deviceService.isBackgroundUploadInProgress && resourceService.viewMode == resourceMode.DOCUMENTS}">

    <!-- Adding this block to display the added/updated resources on clicking from toolsdrawer -->
    <ng-container *ngIf="viewMode != resourceMode.DOCUMENTS">
        <ind-section-header *ngIf="newResources.length && !isAddToEmail && !allowPushResource" [viewData]="{id:'selectededitems-new-resources', title: translate.instant('NEW_RESOURCES_CAP') + ' (' + (newResources | callback: filterByDate | filterBy: resourceFilter).length + ')'}">
        </ind-section-header>
        <ion-list *ngIf="newResources.length && !allowPushResource && !isAddToEmail">
            <resource-list-item *ngFor="let resource of newResources | callback: filterByDate | filterBy: resourceFilter | orderBy: sortBy?.value:sortBy?.asc" tappable (click)="highlightResource(resource)" [emailActivity]="'emailActivity'" [resource]="resource" [sortedBy]="sortBy?.value">
            </resource-list-item>
        </ion-list>
    </ng-container>

    <!-- selected resources for Email section -->
    <ind-section-header *ngIf="isAddToEmail && emailResource.length>0" [viewData]="{id:'selectededitems', title: translate.instant('SELECTED') + ' (' + emailResource.length + ')'}">
    </ind-section-header>
    <ion-list *ngIf="isAddToEmail && emailResource.length>0">
        <resource-list-item *ngFor="let resource of emailResource | callback: filterByDate | filterBy: resourceFilter | orderBy: sortBy?.value:sortBy?.asc" tappable (click)="highlightResource(resource)" (onPushResource)="pushResource($event, resource)" [allowPushResource]="allowPushResource"
            [emailActivity]="'emailActivity'" [resource]="resource" [sortedBy]="sortBy?.value" [viewMode]="viewMode" [isEmailResource]="true" (addToEmail)="addResourceToArray(resource)">
        </resource-list-item>
    </ion-list>

    <!-- All Resources -->
    <ng-container *ngIf="!searching">
        <ind-section-header [viewData]="allResourcesHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
            <div header>
                <ng-container *ngIf="!isSingleFileType">
                    {{ (contentMatchService.isContentMatchingApplied ? 'MATCHED_RESOURCES' : 'ALL_RESOURCES') | translate | capitalizeFirstLetter}} ({{(resources| callback: filterByDate | filterBy: resourceFilter).length}})
                </ng-container>
                <ng-container *ngIf="isSingleFileType">
                    {{'All Strategy Documents'}} ({{(resources | callback: filterByDate | filterBy: resourceFilter).length}})
                </ng-container>
            </div>
        </ind-section-header>
        <ion-list>
            <div *ngIf="!isAddToEmail">
                <resource-list-item *ngFor="let resource of slicedResources" tappable (onPushResource)="pushResource($event, resource)" (toggleResourceSelection)="updateSelectedResource(resource)" [allowPushResource]="allowPushResource" [emailActivity]="'emailActivity'"
                    [resource]="resource" [sortedBy]="sortBy.value">
                </resource-list-item>
            </div>
            <div *ngIf="isAddToEmail">
                <resource-list-item *ngFor="let resource of slicedResources" tappable (onPushResource)="pushResource($event, resource)" [allowPushResource]="allowPushResource" [emailActivity]="'emailActivity'" [resource]="resource" [sortedBy]="sortBy?.value" [viewMode]="viewMode"
                    [isSelect]="checkResourceSelected(resource)" (addToEmail)="addResourceToArray(resource)" (toggleResourceSelection)="updateSelResourceList(resource)">
                </resource-list-item>
            </div>
            <ion-infinite-scroll *ngIf="resources.length > 0" (ionInfinite)="doInfinite($event.detail,$event)" threshold="20%">
                <ion-infinite-scroll-content></ion-infinite-scroll-content>
            </ion-infinite-scroll>
        </ion-list>
        <p *ngIf='resources.length === 0' class="no-data-message">{{'NO_RESOURCES' | translate}}</p>
    </ng-container>
    <ng-container *ngIf="searching">
        <ind-section-header [viewData]="{
                        id:'results',
                        title:translate.instant('AL_RESULTS') + ' (' + (filteredList| callback: filterByDate | filterBy: resourceFilter).length + ')',
                        controls:[{id:'sort',text: sortBy?.text, isDisabled: false, icon:'assets/imgs/sort_with_double_arrows.svg'
                      }]}" (onControlClick)='sort()'></ind-section-header>
        <ion-list>
            <div *ngIf="!isAddToEmail">
                <resource-list-item *ngFor="let resource of filteredList | callback: filterByDate | filterBy: resourceFilter | orderBy: sortBy?.value:sortBy?.asc" tappable (onPushResource)="pushResource($event, resource)" (toggleResourceSelection)="updateSelectedResource(resource)"
                    [allowPushResource]="allowPushResource" [emailActivity]="'emailActivity'" [resource]="resource" [sortedBy]="sortBy.value">
                </resource-list-item>
            </div>
            <div *ngIf="isAddToEmail">
                <resource-list-item *ngFor="let resource of filteredList | callback: filterByDate | filterBy: resourceFilter | orderBy: sortBy?.value:sortBy?.asc" tappable (onPushResource)="pushResource($event, resource)" [allowPushResource]="allowPushResource" [emailActivity]="'emailActivity'"
                    [resource]="resource" [sortedBy]="sortBy?.value" [viewMode]="viewMode" [isSelect]="checkResourceSelected(resource)" (addToEmail)="addResourceToArray(resource)" (toggleResourceSelection)="updateSelResourceList(resource)">
                </resource-list-item>
            </div>
        </ion-list>
        <ind-no-search-result *ngIf="filteredList.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
    </ng-container>
</ion-content>

<!-- <ion-footer> -->
    <footer-toolbar [footerView]="'masterView'" [selectedView]="'presentations'"></footer-toolbar>
<!-- </ion-footer> -->

<search-suggestion-popover (click)="clickedInSuggestionsArea($event)" (selectionMade)="handleFacetSelection($event)"
  [suggestionsData]="suggestionsData" [searchKey]="searchKey" *ngIf='suggestionsActive'
  (selectSavedSearch)="handleSavedSearchSelection($event)" (selectTag)="handleTagSelection($event)"
  [from]="uiService.page.ResourcePageComponent">
</search-suggestion-popover>
