<ion-header class="{{uiService.showFieldCoaching ? 'coaching-agenda': 'coaching-tool'}}">
    <ind-page-title [viewData]="coachingDetailsPageTitle" (onControlClick)='onSectionHeaderControlClick($event)'>
    </ind-page-title>
    <ind-section-header [viewData]='coachingMainHeaderData'>
    </ind-section-header>
</ion-header>

<ion-content [ngClass]="{'mobileContent': device.isMobileDevice}">
    <ion-row>
      <ion-col size="12" size-lg="4" size-md="6">
        <ind-form-field [viewData]="getStatusFormField()"></ind-form-field>
      </ion-col>
      <ion-col *ngIf="hasAccessCoachingPlan" size="12" size-lg="4" size-md="6">
        <ind-form-field [viewData]="getCoachingRefNoFormField()"></ind-form-field>
      </ion-col>
      <ion-col *ngIf="hasAccessCoachingPlan" size="12" size-lg="4" size-md="6">
        <ind-form-field [viewData]="getCoachingPlanFormField()"></ind-form-field>
      </ion-col>
      <ion-col *ngIf="hasAccessCoachingPlan" size="12" size-lg="4" size-md="6">
        <ind-form-field [viewData]="getCoachingTypeFormField()"></ind-form-field>
      </ion-col>
      <ion-col size="12" size-lg="4" size-md="6">
        <ind-form-field [viewData]="getCoachingForFormField()"></ind-form-field>
      </ion-col>
      <ion-col size="12" size-lg="4" size-md="6">
        <ind-form-field [viewData]="getCoachingTemplateFormField()"></ind-form-field>
      </ion-col>
      <!-- Coaching Date -->
      <ion-col *ngIf="report.statuscode !== 1 || !hasAccessCoachingPlan" size="12" size-lg="4" size-md="6">
        <ind-form-field [viewData]="getCoachingDateFormField()"></ind-form-field>
      </ion-col>
      <!-- Period Start and End -->
      <ion-col *ngIf="!hasAccessCoachingPlan" size="12" size-lg="4" size-md="6">
        <ind-datetime-form [viewData]="getCoachingStartDateField()"></ind-datetime-form>
      </ion-col>
      <ion-col *ngIf="!hasAccessCoachingPlan" size="12" size-lg="4" size-md="6">
        <ind-datetime-form [viewData]="getCoachingEndDateField()"></ind-datetime-form>
      </ion-col>
      <ion-col size="12" size-lg="4" size-md="6">
        <!-- Activities -->
        <ind-form-field [viewData]="getCoachingActivitiesFormField()"></ind-form-field>
      </ion-col>
      <ion-col *ngIf="hasAccessCoachingPlan" size="12" size-lg="4" size-md="6">
        <!-- Account -->
        <ind-form-field [viewData]="getCoachingAccountFormField()"></ind-form-field>
      </ion-col>
      <ion-col *ngIf="hasAccessCoachingPlan" size="12" size-lg="4" size-md="6">
        <!-- Contact -->
        <ind-form-field [viewData]="getCoachingContactFormField()"></ind-form-field>
      </ion-col>
      <ion-col *ngIf="hasAccessCoachingPlan" size="12" size-lg="4" size-md="6">
        <!-- OverAll Rating -->
        <ind-form-field [viewData]="getCoachingOverAllRatingFormField()"></ind-form-field>
      </ion-col>
      
      <ion-col *ngFor="let formField of configFields" size="12" size-lg="4" size-md="6">
        <!-- App Config fields -->
        <ind-form-field *ngIf="!hasAccessCoachingPlan" [viewData]="formField"></ind-form-field>
      </ion-col>

    </ion-row>
    <div *ngIf="report.categories && report.categories.length && !hasAccessCoachingPlan">
      <div *ngFor="let category of report.categories | orderBy: 'category_name': true; let i = index">
          <ind-section-header [viewData]="{
            id:'caterogy',
            title: category.category_name,
            isRequired: (coachingShareFA && report.indskr_coachingfor_value && category.measures && category.measures.length>0 ? report.statuscode === 548910003 : report.statuscode === 1),
            controls:[{
              id:'clearRating',text: translate.instant('COACHING_DETAILS_CLEAR_RATINGS'),
              isDisabled: disableRatings || disableClearRating
            }]
          }" (onControlClick)='clearRatings(category.category_id)'>
          </ind-section-header>
          <div *ngFor="let measure of category.measures | orderBy: 'measure_name' :true; let j = index" class="measure-item">
              <ion-grid>
                  <ion-row [ngClass]="{'pad-bottom': !showHideValueManagerRep(measure.rating_id)}">
                      <ion-col class="metric-wrap" [ngClass]="{'disabledLabel': disableRatings}">
                          <span>{{measure.measure_name}}</span>
                          <ion-icon class='mandatory-icon' *ngIf="measure.measure_isrequired"></ion-icon>
                      </ion-col>
                  </ion-row>
                  <div class="margin-10" *ngIf="showHideValueManagerRep(measure.rating_id)">
                      <span class="min-max-text" [ngClass]="{'disabledLabel': disableRatings}">{{measure.rating_label}}</span>
                  </div>
                  <ion-grid *ngIf="measure.ratingscale_id && measure.measure_type === 548910000">
                    <ion-row class="grid-display">
                        <ion-col *ngFor="let rating of measure.ratings | orderBy: 'rating_value' :true;">
                            <label class='square-radio'>
                              <input type='radio' [readOnly]="disableRatings" [disabled]="disableEditing"
                                (change)='setRating(rating.rating_value,rating.rating_label, rating.rating_id, category.category_id, measure.measure_id)'
                                name="report.categories[{{i}}].measures[{{j}}].rating_value"
                                [checked]="showHideRatingManagerRep(measure, rating)" />
                              <span
                                [ngClass]="{'checked-rating': showHideRatingManagerRep(measure, rating) && !measure.clearRating}">{{rating.rating_value}}</span>
                            </label>
                        </ion-col>
                    </ion-row>
                  </ion-grid>
                  <div class="margin-5" *ngIf="!measure.ratingscale_id && measure.min_rating && measure.max_rating">
                    <span class="min-max-text">{{translate.instant('RATING_MIN_MAX', { min: measure.min_rating, max: measure.max_rating } )}}</span>
                  </div>
                  <ion-item *ngIf="measure.measure_type === 548910002" class="text-left" lines="none">
                    <ind-text-area [inputMode]="'numeric'" [readonly]="disableRatings" [disabled]="disableEditing" [placeHolder]="translate.instant('ENTER_RATING')" [(value)]="coachingReportService.activeTab === 'myCoaching' && report.statuscode === 548910003 ? '' : measure.score" (keypress)="onScoreInput($event)" (indBlur)="onScoreFocusOut(measure, category.category_id, measure.measure_id)" (indFocus)="onScoreTextAreaFocus(measure)" [ws]="4" [cols]="10">
                    </ind-text-area>
                  </ion-item>
                  <ion-item *ngIf="measure.measure_type === 548910003" class="text-left" lines="none">
                    <ind-form-field [viewData]="getCoachingObervationFormField(measure, category.category_id, measure.measure_id)"></ind-form-field>
                  </ion-item>
                  <ion-item *ngIf="measure.measure_type === 548910004" class="text-left" lines="none">
                    <ind-form-field [viewData]="getCoachingOverAllProcedureFormField(measure, category.category_id, measure.measure_id)"></ind-form-field>
                  </ion-item>
                  <div class="row-separator" *ngIf="!isHideadditionalfeedback">
                      <ion-item class="text-left" [ngClass]="{'item-disable': (disableRatings || measure.clearRating || (measure.ratingscale_id ? !measure.rating_id : !measure.score))}" lines="none">  
                        <ind-text-area [placeHolder]="translate.instant('ADDITION_FEEDBACK')" [disabled]="disableEditing" [maxLength]="1000" [(value)]="coachingReportService.activeTab === 'myCoaching' && report.statuscode === 548910003 ? '' : measure.rating_comments" (indBlur)="onFeedbackFocusOut(category.category_id, measure)" (indFocus)="onTextAreaFocus(measure.rating_comments)" [ws]="4" [cols]="10" [autoGrow]="true">
                          </ind-text-area>
                      </ion-item>
                  </div>
              </ion-grid>
          </div>
      </div>
  </div>
  <div *ngIf="report.categories && report.categories.length && hasAccessCoachingPlan">
    <div *ngFor="let category of sortNullsLast(report.categories, 'category_sequence_no', true); let i = index">
        <ind-section-header [viewData]="{
          id:'caterogy',
          title: category.category_name,
          isRequired: false,
          controls:[{
            id:'clearRating',text: translate.instant('COACHING_DETAILS_CLEAR_RATINGS'),
            isDisabled: disableRatings || disableClearRating,
            isVisible: false
          }]
        }" (onControlClick)='clearRatings(category.category_id)'>
        </ind-section-header>
        <div *ngFor="let measure of sortNullsLast(category.measures, 'measure_sequence_no', true); let j = index" class="measure-item">
            <ion-grid>
                <ion-row [ngClass]="{'pad-bottom': !showHideValueManagerRep(measure.rating_id)}">
                    <ion-col class="metric-wrap" [ngClass]="{'disabledLabel': disableRatings}">
                        <span>{{measure.measure_name}}</span>
                        <ion-icon class='mandatory-icon' *ngIf="measure.measure_isrequired"></ion-icon>
                    </ion-col>
                </ion-row>
                <div class="margin-10" *ngIf="showHideValueManagerRep(measure.rating_id) && measure.measure_type === 548910000">
                    <span class="min-max-text" [ngClass]="{'disabledLabel': disableRatings}">{{measure.rating_label}}</span>
                </div>
                <ion-grid *ngIf="measure.ratingscale_id && measure.measure_type === 548910000">
                  <ion-row class="grid-display">
                      <ion-col *ngFor="let rating of measure.ratings | orderBy: 'rating_value' :true;">
                          <label class='square-radio'>
                            <input type='radio' [readOnly]="disableRatings" [disabled]="disableEditing"
                              (change)='setRating(rating.rating_value,rating.rating_label, rating.rating_id, category.category_id, measure.measure_id)'
                              name="report.categories[{{i}}].measures[{{j}}].rating_value"
                              [checked]="showHideRatingManagerRep(measure, rating)" />
                            <span
                              [ngClass]="{'checked-rating': showHideRatingManagerRep(measure, rating) && !measure.clearRating}">{{rating.rating_value}}</span>
                          </label>
                      </ion-col>
                  </ion-row>
                </ion-grid>
                <div class="margin-5" *ngIf="!measure.ratingscale_id && measure.min_rating && measure.max_rating">
                  <span class="min-max-text">{{translate.instant('RATING_MIN_MAX', { min: measure.min_rating, max: measure.max_rating } )}}</span>
                </div>
                <ion-item *ngIf="measure.measure_type === 548910002" class="text-left" lines="none">
                  <ind-text-area [inputMode]="'numeric'" [readonly]="disableRatings" [disabled]="disableEditing" [placeHolder]="translate.instant('ENTER_RATING')" [(value)]="coachingReportService.activeTab === 'myCoaching' && report.statuscode === 548910003 ? '' : measure.score" (keypress)="onScoreInput($event)" (indBlur)="onScoreFocusOut(measure, category.category_id, measure.measure_id)" (indFocus)="onScoreTextAreaFocus(measure)" [ws]="4" [cols]="10">
                  </ind-text-area>
                </ion-item>
                <ion-item *ngIf="measure.measure_type === 548910003  " class="text-left" lines="none">
                  <ind-form-field [viewData]="getCoachingObervationFormField(measure, category.category_id, measure.measure_id)"></ind-form-field>
                </ion-item>
                <ion-item *ngIf="measure.measure_type === 548910004 " class="text-left" lines="none">
                  <ind-form-field [viewData]="getCoachingOverAllProcedureFormField(measure, category.category_id, measure.measure_id)"></ind-form-field>
                </ion-item>
                <div class="row-separator" *ngIf="!isHideadditionalfeedback">
                    <ion-item class="text-left" [ngClass]="{'item-disable': ((!measure.observation || !measure.overallprocedure) && report.statuscode != 1)}" lines="none">  
                      <ind-text-area [placeHolder]="translate.instant('ADDITION_FEEDBACK')" [disabled]="disableEditing" [maxLength]="1000" [(value)]="coachingReportService.activeTab === 'myCoaching' && report.statuscode === 548910003 ? '' : measure.rating_comments" (indBlur)="onFeedbackFocusOut(category.category_id, measure)" (indFocus)="onTextAreaFocus(measure.rating_comments)" [ws]="4" [cols]="10" [autoGrow]="true">
                        </ind-text-area>
                    </ion-item>
                </div>
            </ion-grid>
        </div>
    </div>
  </div>
  <ind-section-header *ngIf="!hasAccessCoachingPlan" [viewData]="{id: 'details-summary', title:translate.instant('COACHING_DETAILS_RATING_SUMMARY_CAPS') }">
  </ind-section-header>
  <ion-item *ngIf="!hasAccessCoachingPlan" class="summaryrecommendation">
      <ind-text-area [readonly]="disableRatings" [disabled]="disableEditing" [placeHolder]="translate.instant('ENTER_RATING_JUSTIFICATION')" [maxLength]="1000" [(value)]="coachingReportService.activeTab === 'myCoaching' && report.statuscode === 548910003 ? '' : report.indskr_ratingssummary" (indBlur)="onSummaryFocusOut(report.indskr_ratingssummary)" (indFocus)="onTextAreaFocus(report.indskr_ratingssummary)"
          [ws]="4" [cols]="10" [autoGrow]="true">
      </ind-text-area>
  </ion-item>

  <ind-section-header *ngIf="!hasAccessCoachingPlan" [viewData]="{id: 'recommendations', title:translate.instant('RECOMMENDATIONS_CAPS') }">
  </ind-section-header>
  <ion-item *ngIf="!hasAccessCoachingPlan" class="summaryrecommendation">
      <ind-text-area [readonly]="disableRatings" [disabled]="disableEditing" [placeHolder]="translate.instant('ENTER_RECOMMENDATIONS')" [maxLength]="1000" [(value)]="coachingReportService.activeTab === 'myCoaching' && report.statuscode === 548910003 ? '' : report.indskr_recommendations" (indBlur)="onRecommendationFocusOut(report.indskr_recommendations)" (ionFocus)="onTextAreaFocus(report.indskr_recommendations)"
          [ws]="4" [cols]="10" [autoGrow]="true">
      </ind-text-area>
  </ion-item>
  <!-- Coaching Notes -->
  <ind-section-header [viewData]="notesHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
  </ind-section-header>
  <ion-item class="placeholderEmptyDiv"
    *ngIf="(!report.annotations || report.annotations.length==0) && isReadOnlyMode"></ion-item>
  <ind-text-area id="notesInput" [placeHolder]="notesPlaceholder"
    [showAttachment]="isAttachmentAdded" [attachmentTitle]="attachmentTitle" [maxLength]="1000"
    (indChange)="notesChanged($event)" [value]="tempNoteText" (removeAttachment)="removeAttachment($event)" [rows]="4"
    [cols]="10"> </ind-text-area>
  <input class="hide-input" #attachInput type="file" (change)="loadImageFromDevice($event)" id="file-input">
  <io-note *ngFor="let note of report.annotations" [isNoteAttachmentEnabled]="true"
    [inputNote]="note" [editable]="isNoteControlsEnabled(note)" [deletable]="isNoteControlsEnabled(note)"
    (saveNoteOut)="updateNote($event)">
  </io-note>
</ion-content>
<!-- <ion-footer *ngIf="report"> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'CoachingDetails'"></footer-toolbar>
<!-- </ion-footer> -->
