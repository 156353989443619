import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { DatePipe } from '@angular/common';
import { EventActivity } from "@omni/classes/events-tool/event.class";
import { EventParticipantComponent } from "@omni/components/event-participant/event-participant";
import { EventsToolService } from "@omni/services/events-tool/events-tool.service";
import { DateTimeFormatsService } from './../../../services/date-time-formats/date-time-formats.service';
import { ScientificInfoDetailsComponent } from './../scientific-info-details/scientific-info-details';
import { NothingSelectedView } from './../../shared/nothing-selected-view/nothing-selected-view';
import { Component, Input, ApplicationRef, ChangeDetectorRef, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { Activity, ActivityType } from '../../../classes/activity/activity.class';
import { DeviceService } from '../../../services/device/device.service';
import { ActivityService, ActivitySource } from '../../../services/activity/activity.service';
import { PopoverController, LoadingController, ModalController, IonContent, IonItemGroup, IonList } from '@ionic/angular';
import { Events } from '@omni/events';
import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { PopoverComponent } from '../../popover/popover';
import { CallPlanOfflineService } from '../../../services/call-plan/call-plan.offline.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { SampleService, SamplingDetailsViewMode } from '../../../services/sample/sample.service';
import { CustomerSampleAllocation, CustomerSampleProductEligibility } from '../../../classes/sample/customer-sample-allocation.class';
import { isWithinRange, addMinutes, format, isValid, isFuture } from 'date-fns';
import { SampleDataService } from '../../../data-services/sample/sample.data.service';
import { CreateSampleDropRequestBody, SampleActivity } from '../../../classes/activity/sample.activity.class';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { ContactDataService } from '../../../data-services/contact/contact.data.service';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import {Contact, ProductSegmentation} from "../../../classes/contact/contact.class";
import _ from 'lodash';
import { CustomerEventsService } from '../../../services/customer-event/customer-events.service';
import { EventRegistration, ContactEvent } from '../../../classes/customer-event/customer-event.class';
import { ScientificActivityPlan } from '../../../classes/scientific-activity/scientific-activity.class';
import { ScientificActivityDataService } from '../../../data-services/scientific-activity/scientific-activity.data.service';
import { ScientificActivityDetail } from '../../activity/scientific-activity/scientific-activity-detail/scientific-activity-detail';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { ContactConsentComponent } from '@omni/components/contact/contact-consent/contact-consent';
import { AttendeeFormat, EventRegistrationStatus, EventStatus } from '@omni/enums/event/event.enum';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { CustomerSurveyService } from '@omni/services/customer-survey/customer-survey.service';
import { AssessmentTemplate, InternalSurveyStatus, SurveyCategory, SurveyFrequency, SurveyStatus, SurveyType, TemplateType } from '@omni/classes/customer-assessment/assessment-template.class';
import { SubCardViewDataModel } from '@omni/models/SubCardViewDataModel';
import { SurveyDetailsComponent } from '@omni/components/survey/survey-details/survey-details';
import { CustomerAssessment } from '@omni/classes/customer-assessment/customer-assessment.class';
import { SurveyListCardviewComponent } from '@omni/components/survey/survey-list-cardview/survey-list-cardview';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TimeLineItem } from '@omni/components/timeline-item/timeline-item.component';
import { Customer360UIService } from '@omni/services/ui/customer360ui.service';
import { EventsToolDataService } from '@omni/data-services/event/events-tool.data.service';
import { EventDetailsComponent } from '@omni/components/events-tool/event-details/event-details';
import { ContactOmnichannelEventsComponent } from '../contact-omnichannel-events/contact-omnichannel-events';
import { ContactTimelinePageComponent } from '../contact-timeline-page/contact-timeline-page';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { ContactSentimentHistoryListComponent } from '../contact-sentiment-history-list/contact-sentiment-history-list';
import { MeetingSentimentHistoryDetailComponent } from '@omni/components/activity/meeting-sentiment-history-detail/meeting-sentiment-history-detail';
import { Opportunity } from '@omni/classes/opportunity-management/opportunity.class';
import { OpportunityManagementService } from '@omni/services/opportunity-management/opportunity-management.service';
import { OpportunityManagementDataService } from '@omni/data-services/opportunity-management/opportunity-management.data.service';
import { OpportunityDetailsComponent } from '@omni/components/opportunity-management/opportunity-details/opportunity-details';
import { ContactOmnichannelOpportunitiesComponent } from '../contact-omnichannel-opportunities/contact-omnichannel-opportunities';


/**
 * Generated class for the ContactHistoryComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'contact-profile',
  templateUrl: 'contact-profile.html',
  styleUrls:['contact-profile.scss']
})
export class ContactProfileComponent {

  @Output() openScientificPlanDetails:EventEmitter<ScientificActivityPlan> = new EventEmitter();

  public compViewMode = ComponentViewMode;
  public meetingHistory: Array<Activity> = [];
  public meetingHistoryToDisplay: Array<Activity> = [];
  private recordCount = 20;
  public activeMeeting: Activity;
  public currentMeetingExpanded:boolean = true;
  public formattedCallPlans: any;
  public expandedCallPlanSection: string = 'present';
  public expandedSPSection: string = 'none';
  public contactCallPlanViewMode: string = 'Meeting';
  public userName:string;
  public isSampleRequestEnabled:boolean = false;
  @Input() contactListMode:string;
  noCallPlans: boolean = false;
  public sampleEligibilities: Array<CustomerSampleAllocation> = [];
  public isSalesProfileEnabled: boolean = false;
  public isMedMarketingEnabled: boolean = false;
  public isMedProfileEnabled: boolean = false;
  public selectedContact: Contact = null;
  public isEventsEnabled: boolean = false;
  public eventExpandIcon: string = "chevron-down-outline";
  public customerEvents: ContactEvent;
  public currentScientificPlans:ScientificActivityPlan[] =[];
  public futureScientificPlans:ScientificActivityPlan[] =[];
  public pastScientificPlans:ScientificActivityPlan[] =[];
  public isScientificActivityEnabled:boolean = false;
  public isMeetingContentDurationEnabled: boolean = false;
  @ViewChild('eventInfoHeader', {static:true}) eventInfoHeader: any;
  @ViewChild('sciInfoHeader', {static:true}) sciInfoHeader: any;
  @ViewChild('scrollTop', { read: ElementRef, static: false }) scrollTop: ElementRef;
  public contactCallPlanHeader: IndSectionHeaderViewDataModel;
  expandedSection: string = 'open';
  public isConsentToolEnabled: boolean = false;
  public isCallPlanToolEnbaled: boolean = false;
  public showConsents: boolean = false;
  public consents: any = [];
  //survey feature
  public isSurveyEnabled:boolean = false;
  public surveyListSectionHeader: IndSectionHeaderViewDataModel;
  private filteredSurveyList: AssessmentTemplate[] = [];
  public filteredSurveyListToDisplay: SubCardViewDataModel[] = [];
  public AvailableFilteredSurveyList = [];
  public from: string;
  private IpreviewSurveyModeSubscription: Subscription;
  private ngDestroy$ = new Subject<boolean>();

  private holdActivityDetailsPage:PageName;
  public isEventActivitiesEnabled:boolean = false;
  public eventsSectionHeader: IndSectionHeaderViewDataModel;
  public eventItems:Array<TimeLineItem> = [];
  private isCustomer360UIEnabled:boolean = false;
  public sentimentHistorySectionHeader: IndSectionHeaderViewDataModel;
  public meetingHistoryData: Array<Activity> = [];
  public meetingHistoryItems:Array<TimeLineItem> = [];
  @Input() readOnly: boolean;
  public opportunitiesHeader: IndSectionHeaderViewDataModel;
  applicableOpportunities: Opportunity[] = [];
  ngUnSubscribe$ = new Subject<boolean>();
  timelineItems: TimeLineItem[] = [];
  loadProgress: any;
  public opportunitiesItems:Array<TimeLineItem> = [];
  public isOpportunityToolEnabled: boolean = false;


  @ViewChild(ContactConsentComponent) consent: ContactConsentComponent;

  constructor(public authenticationService:AuthenticationService,
              public contactService: ContactOfflineService,
              public contactDataService: ContactDataService,
              public activityService: ActivityService,
              private popoverCtrl: PopoverController,
              public callPlanOfflineService: CallPlanOfflineService,
              private _appRef: ApplicationRef,
              private _cd: ChangeDetectorRef,
              private events:Events,
              private eventsToolService: EventsToolService,
              public device:DeviceService,
              public sampleService:SampleService,
              public sampleDataService: SampleDataService,
              public loadingController: LoadingController,
              public uiService: UIService,
              public footerService: FooterService,
              public navService :NavigationService,
              public customerEventsService: CustomerEventsService,
              private scientificPlanService:ScientificActivityDataService,
              private trackingService:TrackService,
              public translate:TranslateService,
              public modalCtrl: ModalController,
              public dateTimeFormatsService: DateTimeFormatsService,
              private datePipe: DatePipe,
              public customerSurveyService: CustomerSurveyService,
              private dynamics: DynamicsClientService,
              public customerAssessService: CustomerAssessService,
              private readonly customer360UIService: Customer360UIService,
              private readonly eventsToolDataService: EventsToolDataService,
              private opportunityService: OpportunityManagementService,
              private readonly opportunityDataService: OpportunityManagementDataService,
              ) {
        this.meetingHistory = [];
        this.meetingHistoryToDisplay = [];
        this.meetingHistoryItems = [];
        this.meetingHistoryData = [];
        this.formattedCallPlans = {
          past: [],
          present: [],
          future: []
        };
        this.selectedContact = this.contactService.contactInformation;

        this.isOpportunityToolEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT);
        this.isConsentToolEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.CONSENT_TOOL);
        this.isCallPlanToolEnbaled = this.authenticationService.hasFeatureAction(FeatureActionsMap.CALL_PLANS);
        this.isSalesProfileEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.SALES_PROFILE);
        this.isEventsEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_REGISTRATION);
        this.isMedProfileEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.MEDICAL_PROFILE);
        this.isMedMarketingEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_INFORMATION);
        this.isMeetingContentDurationEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_CONTENT_DURATION);
        this.isSurveyEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SURVEY);
        this.isEventActivitiesEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL);
       this.isCustomer360UIEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER360UI, true);
        this.showConsents = this.authenticationService.user?.buConfigs?.indskr_showcontactconsents && !this.device.isOffline;

        if (this.device.isOffline) {
          this.contactService.isContactProfileLoaded = true;
          this.init();
        } else {
          this.updateUI();
        }
        this.events.subscribe('updateProfileData', ()=>{
          this.setProfileData()
        });
        this.events.subscribe('selectedCallPlanFilter', ()=>this.initSectionHeader());
  }

  async updateUI() {
    this.contactService.isContactProfileLoaded = true;
    this.contactCallPlanViewMode = this.callPlanOfflineService.meetingOrEmailFilter;
    await this.init();
    this._cd.detectChanges();
  }

      async init(){
        const currentDay: Date = new Date();
        const currentMeeting = this.activityService.selectedActivity;
        this.events.subscribe('Contact-Profile:refreshContactSampleEligibilities',()=>this.initSampleEligibilities())
        if(currentMeeting && currentMeeting.type == ActivityType.Appointment && this.hasContentShared(currentMeeting)
        && currentMeeting['contacts'].findIndex(contact => {return contact.ID === this.contactService.contactInformation.ID}) > -1){

          this.contactService.contactSentimentUpdateActivity =this.activityService.selectedActivity;
          this.contactService.contactSentimentUpdateActivity.expanded = true;
        }
        else{
          this.contactService.contactSentimentUpdateActivity = undefined;
        }
        this.activityService.indexWholeSampleActivityMetasBySKU();
        if(this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)){
          this.isSampleRequestEnabled = true;
        }
``
        this.isScientificActivityEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_TOOL_PLAN);
        if(this.isScientificActivityEnabled){
          await this.scientificPlanService.getScientificPlansByContactId(this.contactService.contactInformation.ID).then(spData =>{
            this.currentScientificPlans = spData.filter(sap => (Number(sap.startDate) < currentDay.getTime() && Number(sap.endDate) > currentDay.getTime()))
                                          .sort((a,b)=>{
                                            return Number(a.startDate) > Number(b.startDate) ? 1 : -1;
                                          });
            this.futureScientificPlans = spData.filter(sap => (Number(sap.startDate) > currentDay.getTime()))
                                          .sort((a,b)=>{
                                            return Number(a.startDate) > Number(b.startDate) ? 1 : -1;
                                          });
            this.pastScientificPlans = spData.filter(sap => (Number(sap.endDate) < currentDay.getTime()))
                                          .sort((a,b)=>{
                                            return Number(a.startDate) > Number(b.startDate) ? 1 : -1;
                                          });
          if(this.currentScientificPlans.length>0 ){
            this.expandedSPSection = 'current';
          }
          });
        }
        this.setProfileData();
        this.initSampleEligibilities();
        this.initCustomerEvents();
        this.initSectionHeader();
        if (this.showConsents) {
          this.initConsents();
        }
      }

  ngOnInit() {
     //Avialable Survey
     if(this.isSurveyEnabled) {
      this._initSurveyList();
      this._initSurveySectionHeader();
    }
    if(this.isEventActivitiesEnabled){
      this._initEventsSectionHeader();
      this._initEventActivitiesList();
    }
    if(this.isCustomer360UIEnabled){
      this._initSentimentHistorySectionHeader();
    }

    this.navService.childNavPopObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(page=> {
      if(page && page == this.holdActivityDetailsPage && this.navService.getActiveChildNavViewPageName() == PageName.ContactDetailsComponent){
        this._initEventActivitiesList();
      }
    })
    this.IpreviewSurveyModeSubscription = this.customerSurveyService.surveyPreviewObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(value => {
      if(!_.isEmpty(value[1])) {
        //Submitted Survey
        if(value[1].surveyStatus == SurveyStatus.SUBMITTED) {
          this._initSurveyList();
          this._initSurveySectionHeader();
        }
      }
    });
    this.contactService.isOpenedSurveyTimelineFromContactProfile = false;

    this.navService.childNavPopObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(page=> {
      if(page && this.navService.getActiveChildNavViewPageName() == PageName.ContactDetailsComponent && page == PageName.EventDetailsPageComponent){
        this._initEventActivitiesList();
      }
    });
    this.opportunityService.opportunities$.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((data) => {
      if (data?.length) {
        this._initOpportunitiesList();
      }
    });
    this._initOpportunitiesList();
  }

  ngAfterViewInit(){
    setTimeout(() => {
      if(this.scrollTop) this.scrollTop.nativeElement.scrollIntoView(false);
    }, 500);
  }

  ngOnDestroy(){
    this.events.unsubscribe('Contact-Profile:refreshContactSampleEligibilities');
    this.events.unsubscribe('updateProfileData');
    if (this.IpreviewSurveyModeSubscription) this.IpreviewSurveyModeSubscription.unsubscribe();
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.ngUnSubscribe$.next(true);
    this.ngUnSubscribe$.complete();
  }

  initSectionHeader(){
    let titleText = this.translate.instant('CALL_PLANS');
    if(this.translate.currentLang == 'it') {
      titleText = this.translate.instant('CALL_PLAN');
    }
    this.contactCallPlanHeader = {
      id: 'contact-profile-callPlan-header',
      title : titleText,
      controls: [
        {
          id: 'contact-profile-callPlan-sort',
          text: this.getSortTitle(),
          isDisabled: false,
          img: "assets/imgs/sort_with_double_arrows.svg",
          bubbleUpEvent: true
        }
      ]
    }
  }

  private getSortTitle() {
    let sortTitle: string = '';
    switch (this.callPlanOfflineService?.meetingOrEmailFilter) {
      case 'Messages':
        sortTitle = this.translate.instant('MESSAGES');
        break;
      case 'Engagements':
        sortTitle = this.translate.instant('ENGAGEMENTS');
        break;
      case 'F2fMeetings':
        sortTitle = this.translate.instant('F2F_MEETINGS');
        break;
      case 'PhoneCalls':
        sortTitle = this.translate.instant('PHONE_CALLS');
        break;
      case 'RemoteMeetings':
        sortTitle = this.translate.instant('REMOTE_MEETINGS');
        break;
      case 'Events':
        sortTitle = this.translate.instant('EVENTS');
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
    return sortTitle;
  }

  public getActiveMeetingMainCarModel(contactSentimentUpdateActivity: Activity): MainCardViewDataModel {
    let viewData: MainCardViewDataModel;
    if(contactSentimentUpdateActivity){
      viewData = {
        id: contactSentimentUpdateActivity.ID,
        fixedHeight: true,
        primaryTextRight: contactSentimentUpdateActivity.subject,
        secondaryTextRight: contactSentimentUpdateActivity.meetingOwner !== this.authenticationService.user.displayName ? this.translate.instant('MET_BY_WITH_TEXT') : this.translate.instant('MET_BY_ME'),
        secondaryTextRightTwo: contactSentimentUpdateActivity.scheduledStart ? contactSentimentUpdateActivity.scheduledStart.toDateString() : '',
        isExpandable: true,
        isExpanded: contactSentimentUpdateActivity.expanded,
        expandableViewType: 'activitiesList',
        showArrow: true,
        arrowType: 'chevron-forward-outline',
        noSeparationLine: true,
        clickHandler: (id: string, event) => {
          this.expandList(event, contactSentimentUpdateActivity);
        }
      };
    }
    return viewData;
  }

  public getContentHistoryMainCardDataModel(meeting: Activity): MainCardViewDataModel {
    let viewData: MainCardViewDataModel;
    if(meeting){
      let labelPostfix = meeting.meetingOwnerName === this.userName ? this.translate.instant('BY_ME_SMALL') : this.translate.instant('BY').toLowerCase() + " " + meeting.meetingOwnerName;
      let labelText = this.translate.instant('MET') + " " + labelPostfix;
      viewData = {
        id: meeting.ID,
        fixedHeight: true,
        primaryTextRight: labelText,
        secondaryTextRight: meeting.subject ==='Meeting'? this.translate.instant('MEETING') : (this.authenticationService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT) && meeting.subject ==='Visit') ? this.translate.instant('VISIT') : meeting.subject,
        secondaryTextRightTwo: this.getFormattedAndLocalisedDate(meeting.scheduledStart),
        // isExpanded: meeting.expanded,
        // expandableViewType: 'activitiesList',
        isAccordionForm: true,
        showAccordionIcon: ((meeting['activityPresentations']  && meeting['activityPresentations'].length > 0)
        || (meeting['activityResources']  && meeting['activityResources'].length > 0)
        || (meeting['activityProducts'] && meeting['activityProducts'].length > 0)) && !this.readOnly,
        accordionIconType: meeting.expandIcon,
        isAccordionText: meeting.expanded,
        noSeparationLine: true,
        enableCopyPaste: true,
        clickHandler: (id: string, ev) => {
          this.expandList(ev, meeting);
        }
      };
    }
    return viewData;
  }


  private getFormattedAndLocalisedDate(value: any) {
    return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  public getCallPlanDataModel(section: string): MainCardViewDataModel {
    let viewData: MainCardViewDataModel;
      viewData = {
        id: 'call-plan-data',
        fixedHeight: true,
        primaryTextRight: section === 'present'?this.translate.instant('CONTACT_CURRENT_CALL_PLANS'):section ==='future'?this.translate.instant('CONTACT_FUTURE_CALL_PLANS'):section ==='past'?this.translate.instant('CONTACT_PAST_CALL_PLANS'):'',
        isAccordionForm: true,
        showAccordionIcon: true,
        accordionIconType: this.expandedCallPlanSection === section?'accordion-minus':'accordion-add',
        isAccordionText: this.expandedCallPlanSection === section,
        noSeparationLine: true,
        enableCopyPaste: true,
        clickHandler: (id: string, ev) => {
          this.expandCallPlanList(ev, section);
        }
      };
    return viewData;
  }

  public getScientificActivityDataModel(section: string): MainCardViewDataModel {
    let viewData: MainCardViewDataModel;
      viewData = {
        id: 'scientific-activity-data',
        fixedHeight: true,
        primaryTextRight: section === 'current'?this.translate.instant('CONTACT_CURRENT_SCIENTIFIC_PLANS'):section ==='future'?this.translate.instant('CONTACT_FUTURE_SCIENTIFIC_PLANS'):section ==='past'?this.translate.instant('CONTACT_PAST_SCIENTIFIC_PLANS'):'',
        isAccordionForm: true,
        showAccordionIcon: true,
        accordionIconType: this.expandedSPSection === section?'accordion-minus':'accordion-add',
        isAccordionText: this.expandedSPSection === section,
        noSeparationLine: true,
        enableCopyPaste: true,
        clickHandler: (id: string, ev) => {
          this.expandSPList(ev, section);
        }
      };
    return viewData;
  }

  public eventSpeakerMainCardDataModel(): MainCardViewDataModel{
    let viewData: MainCardViewDataModel;
    viewData={
      id:'Speaker Engagements',
      fixedHeight: true,
      primaryTextRight: this.translate.instant('CONTACT_SPEAKER_ENGAGEMENTS'),
      showArrow: true,
      arrowType: 'chevron-forward-outline',
      noPaddingIcon: true,
      enableCopyPaste: true,
      clickHandler:(id: string, ev) => {
        this.openSciInfoDetails('Speaker Engagements');
      }
    };
    return viewData;
  }

  public eventAttendanceMainCardDataModel(): MainCardViewDataModel{
    let viewData: MainCardViewDataModel;
    viewData={
      id:'Event Attendance',
      fixedHeight: true,
      primaryTextRight: this.translate.instant('EVENT_ATTENDANCE'),
      showArrow: true,
      arrowType: 'chevron-forward-outline',
      noPaddingIcon: true,
      enableCopyPaste: true,
      clickHandler:(id: string, ev) => {
        this.openSciInfoDetails('Event Attendance');
      }
    };
    return viewData;
  }

  public availableEventMainCardDataModel(event: EventRegistration): MainCardViewDataModel{
    let viewData: MainCardViewDataModel;
    if(event){
      viewData= {
        id:event.eventRegistrationId,
        fixedHeight: true,
        primaryTextRight: event.eventName,
        secondaryTextRight: `On ${this.getFormattedAndLocalisedDate(new Date(parseInt(event.eventStartDate)))}`,
        secondaryTextRightTwo: this.translate.instant('CONTACT_REGISTERED_BY_OWNER', {text : event.registeredBy}),
        // isExpandable: true,
        // isExpanded: event['isExpanded'],
        // expandableViewType: 'activitiesList',
        showArrow: true,
        arrowType: 'chevron-forward',
        // isAccordionForm: true,
        // showAccordionIcon: (event.description || event.eventImage)? true : false,
        // accordionIconType:  event['eventExpandIcon'],
        // isAccordionText: event['isExpanded'],
        // isExpanded: event['isExpanded'],
        enableCopyPaste: true
      };
      viewData.clickHandler = async (id: string, ev) => {
        /*if (viewData.showAccordionIcon) {
          this.expandEventDetails(ev, event);
        }*/
          const foundItem = this.eventsToolService.eventsToolData.find(a => {
            if (a.ID) {
              return a.ID === event.eventId;
            } else if (a.offlineId) {
              return a.offlineId === event.eventId;
            } else {
              return false;
            }
          });
          await this._viewEventRegistraionDetails(foundItem);
      }
    }
    return viewData;
  }

  private async _viewEventRegistraionDetails(event: EventActivity) {
    const participant = this.eventsToolService.getEventParticipantByContactId(
      event.participants,
      this.contactService.contactInformation.ID,
    );
    const viewData: Record<string, any> = {
      eventParticipant: participant,
      currentEvent: event,
    };
    if (!participant) {
      viewData.contact = this.contactService.contactInformation;
    }
    await this.navService.pushChildNavPageWithPageTracking(EventParticipantComponent, PageName.ContactDetailsComponent, PageName.ContactDetailsComponent, viewData)
  }

  public activeMeetingMainCardDataModel(activeMeeting: Activity): MainCardViewDataModel{
    let viewData: MainCardViewDataModel;
    viewData={
      id:'active Meeting',
      fixedHeight: true,
      primaryTextRight: this.contactService.contactSentimentUpdateActivity?.subject,
      primaryTextLeft: (this.contactService.contactSentimentUpdateActivity && this.contactService.contactSentimentUpdateActivity.meetingOwner) !== this.authenticationService.user.displayName
       ? this.translate.instant('MET_BY_WITH_TEXT', {text : this.contactService.contactSentimentUpdateActivity?.meetingOwnerName}) : this.translate.instant('MET_BY_ME'),
      secondaryTextRight: this.getFormattedAndLocalisedDate(this.contactService.contactSentimentUpdateActivity?.scheduledStart),
      // showArrow: true,
      // arrowType: 'chevron-forward-outline',
      isAccordionForm: true,
      showAccordionIcon: true,
      accordionIconType:  this.contactService.contactSentimentUpdateActivity?.expanded ? 'accordion-minus' : 'accordion-add',
      isAccordionText: this.contactService.contactSentimentUpdateActivity?.expanded,
      noSeparationLine: true,
      enableCopyPaste: true,
      clickHandler: (id: string, ev) => {
        this.expandList(ev, activeMeeting);
      }
    };
    return viewData;
  }

  private initCustomerEvents(){
    //initial Customer Events
    this.customerEvents = this.customerEventsService.getEventsForContact(this.contactService.contactInformation.ID);
  }

  initSampleEligibilities(){
    let eligibleAllocation = this.sampleService.contactCustomerSampleAllocations.find(sampleAllocation=> sampleAllocation.contactId == this.contactService.contactInformation.ID);
    if(eligibleAllocation){
      this.sampleEligibilities = eligibleAllocation.currentCustomerSampleAllocations.filter((sampleAllocation) =>{
        let dateToday = new Date();
        if( isWithinRange(dateToday,sampleAllocation.startDate,sampleAllocation.endDate)) {
          let isValid = (!sampleAllocation.customerLicenseIds || Array.isArray(sampleAllocation.customerLicenseIds) && sampleAllocation.customerLicenseIds.length === 0);
          // Check if has license requirement
          if (Array.isArray(sampleAllocation.customerLicenseIds) && sampleAllocation.customerLicenseIds.length > 0) {
            isValid = this.sampleService.validateCustomerAllocationWithLicense(sampleAllocation.customerLicenseIds, this.contactService.contactInformation.addressesList);
          }
          if (isValid) {
            if (sampleAllocation.totalsamplevaluelimit > 0) {
              sampleAllocation.indskr_totalsamplevalueremaining = (sampleAllocation.totalsamplevaluelimit - sampleAllocation.indskr_totalsamplevaluedelivered) + '';
              sampleAllocation.indskr_totalsamplevalueremaining = sampleAllocation.currencySymbol ? this.dateTimeFormatsService.formattedCurrency(sampleAllocation.currencySymbol + '' + sampleAllocation.indskr_totalsamplevalueremaining) : sampleAllocation.indskr_totalsamplevalueremaining;
              sampleAllocation['totalsamplevaluelimitFormatted'] = this.dateTimeFormatsService.formattedCurrency(sampleAllocation.totalsamplevaluelimit + '');
            }
            sampleAllocation['startDateFormatted'] = this.datePipe.transform(sampleAllocation.startDate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
            sampleAllocation['endDateFormatted'] = this.datePipe.transform(sampleAllocation.endDate, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
            return sampleAllocation;
          }
        }
      });
      const additionalEligibleAllocation = [];
      for (let sampleAllocation of this.sampleEligibilities) {
        if (!sampleAllocation.buId && !sampleAllocation.buProductSampling) continue;
        const skusForBU = this.sampleService.skusSampleUnitPrice.filter(sku => sku.buId === sampleAllocation.buId);
        if (sampleAllocation.buId && _.isEmpty(skusForBU)) continue;
        const eligibleSkus: CustomerSampleProductEligibility[] = this.sampleService.getEligibileSkusForAllocProfile(sampleAllocation.allocationRulesProfileId, sampleAllocation.id, skusForBU.map(prod => prod.productid));
        if (!_.isEmpty(eligibleSkus)) {
          sampleAllocation.sampleSKUId = skusForBU[0].indskr_skuid;
          sampleAllocation.sampleSKUName = skusForBU[0].skuName;
          const productEligibility = eligibleSkus.find(sku => sku.skuId === skusForBU[0].indskr_skuid);
          sampleAllocation['customerDropLimit'] = productEligibility ? ((productEligibility.indskr_totalunitsdelivered > 0 ? productEligibility.indskr_totalunitsdelivered : 0) + '/' + productEligibility.indskr_eligibilitylimit) : null;
          if (skusForBU.length > 1) {
            for (let sku of skusForBU.slice(1)) {
              const customerAllocation = _.cloneDeep(sampleAllocation);
              customerAllocation.sampleSKUId = sku.indskr_skuid;
              customerAllocation.sampleSKUName = sku.skuName;
              const productEligibility = eligibleSkus.find(eSku => eSku.skuId === sku.indskr_skuid);
              customerAllocation['customerDropLimit'] = productEligibility ? ((productEligibility.indskr_totalunitsdelivered > 0 ? productEligibility.indskr_totalunitsdelivered : 0) + '/' + productEligibility.indskr_eligibilitylimit) : null;
              additionalEligibleAllocation.push(customerAllocation);
            }
          }
        }
      }
      this.sampleEligibilities.push(...additionalEligibleAllocation);
    }
  }




  public get isActiveMeetingViewEnabled():boolean {
    let flag:boolean = false;
    if(this.contactService.contactPageMode != ComponentViewMode.LISTVIEW && this.contactService.contactSentimentUpdateActivity && this.contactService.contactSentimentUpdateActivity.type == ActivityType.Appointment){
      flag = true;
    }
    return flag;
  }

  setProfileData(){
    //get presentation history data only if device is online (CWD-805) and the service is returned
    if(!this.device.isOffline && this.contactService.isContactProfileLoaded){
      if(this.contactService.contactInformation && this.contactService.contactInformation.activitesByContact && this.contactService.contactInformation.activitesByContact.length>0){
        if(this.isCustomer360UIEnabled){
          this.meetingHistoryData = this.contactService.contactInformation.activitesByContact.filter(item => (item.type === 'Appointment' && item.subject));
          this._initMeetingHistoryViewItems();
        }else{
          this.meetingHistory = this.contactService.contactInformation.activitesByContact.filter(item => (item.type === 'Appointment' && item.subject));
          this.meetingHistoryToDisplay = this.meetingHistory.slice(0, this.recordCount);
        }
      }else{
        this.meetingHistory = [];
        this.meetingHistoryToDisplay = [];
        this.meetingHistoryItems = [];
      }

      this.authenticationService.getOfflineUser().then((user)=>{
        if(user){
          this.userName = user.displayName;
        }
        return;
      });

    }else{
      this.meetingHistory = [];
      this.meetingHistoryToDisplay = [];
      this.meetingHistoryData = [];
      this.meetingHistoryItems = [];
    }
    //get call plans data if device is online (CWD-805) and the service is returned or device is offline
    this.formattedCallPlans = {
        past: [],
        present: [],
        future: []
      };
      this.noCallPlans = true;
    if(this.contactService.isContactProfileLoaded){
      if(this.contactService.contactInformation.repCallPlansByContact){
        this.formattedCallPlans = this.contactService.getFormattedRepCallPlans();
        this.noCallPlans = false
      }
    }
  }

  async openCllPlanActivityFilterPopup(myEvent){
    let popover = await this.popoverCtrl.create(
        {component: PopoverComponent,
        componentProps:{ field: 'callPlansActivityFilter' },
        cssClass: "callplan-activity-filter-popover",
        event:myEvent.event}
      );
      popover.present();

      popover.onDidDismiss().then(() => {
        this._appRef.tick();
        this._cd.markForCheck();
      });
  }
  expandList = (event,activityItem: Activity): void => {
    event.stopPropagation();
    // console.log(activityItem);
    if (this.hasContentShared(activityItem)  || !_.isEmpty(activityItem['activityProducts'])) {
        activityItem.expanded = !activityItem.expanded;
        activityItem.expandIcon = (activityItem.expanded) ? 'accordion-minus' : 'accordion-add';
    }
  }


  private hasContentShared(activity: Activity): boolean {
    return !_.isEmpty(activity['activityPresentations']) || !_.isEmpty(activity['activityResources']);
  }

  expandCallPlanList(event, section:string){
      this.expandedCallPlanSection = this.expandedCallPlanSection == section ? 'none': section
  }

  expandSPList(event, section:string){
    this.expandedSPSection = this.expandedSPSection === section ? 'none': section
  }


  expandEventDetails(event, customerEvent) {
    customerEvent.isExpanded = !customerEvent.isExpanded;
    customerEvent.eventExpandIcon = (customerEvent.isExpanded) ? "accordion-minus" : "accordion-add";
  }

  getHeight(meeting):number{
    if (!meeting) return 0;
    const presentationsShared: boolean = !_.isEmpty(meeting.activityPresentations);
    const resourcesShared: boolean = !_.isEmpty(meeting.activityResources);
    const productShared: boolean = !_.isEmpty(meeting['activityProducts']) || !_.isEmpty(meeting.products);
    const keyMessageSentiments: boolean = meeting.activityContactSentiments.length > 0 && meeting.activityContactSentiments[0].activityKeyMessageSentiments && meeting.activityContactSentiments[0].activityKeyMessageSentiments.length > 0;
    if(presentationsShared || resourcesShared || productShared) {
      let totalSlide:number =0;
      let totProd:number = 0;
      let keyMsgSentiment:number = 0;
      if (presentationsShared) {
        meeting.activityPresentations.forEach(pres => {
          if(pres.activityPresentationSlides){
            totalSlide = totalSlide + pres.activityPresentationSlides.length;
          }
        });
      }
      if (resourcesShared) {
        if(_.some(meeting.activityResources, 'activityResourceSlides')) {
          meeting.activityResources.forEach(rsrc => {
            if(rsrc.activityResourceSlides) {
              totalSlide += rsrc.activityResourceSlides.length;
            } else {
              totalSlide ++;
            }
          })
        } else {
          //Old meetings will not have activityResourceSlides
          totalSlide += meeting.activityResources.length;
        }
      }
      if(meeting.products){
        meeting.products.forEach(prod => {
          if(prod.isSelected){
            totProd = totProd + 1;
          }
        });
      }else{
        totProd = (meeting['activityProducts']) ? meeting['activityProducts'].length : 0;
      }
      if(keyMessageSentiments){
        keyMsgSentiment = meeting.activityContactSentiments[0].activityKeyMessageSentiments.length;
        keyMsgSentiment = keyMsgSentiment*48 + 35;
      }
      if(totalSlide > 0 && productShared){
        const slideHeight = this.isMeetingContentDurationEnabled ? 54 : 50;
        // product name, content name - 20px each and meeting item label height -10 to remove line & extra space for the last item
        return (keyMsgSentiment) + (slideHeight * totalSlide )  + ((totProd + meeting.activityPresentations.length + meeting.activityResources.length) * 20) - 10;
      } else if (totalSlide == 0 && productShared){
        return (keyMsgSentiment) +( totProd * 22);
      } else if (!keyMessageSentiments && presentationsShared) {
        const slideHeight = this.isMeetingContentDurationEnabled ? 54 : 50;
        return (slideHeight * totalSlide ) ;
      }
      else{
        return (keyMsgSentiment) + 0;
      }
    }else{
      return 0
    }
  }

  onCallPlanViewChange(viewtype: string){
    this.contactCallPlanViewMode = viewtype;
  }
  isDataAvailable(){
    return this.contactService.contactSentimentUpdateActivity || (this.meetingHistory.length > 0 || this.meetingHistoryData.length > 0) ||
    (this.formattedCallPlans && this.formattedCallPlans.present && this.formattedCallPlans.present.length>0) ||
     (this.formattedCallPlans && this.formattedCallPlans.future && this.formattedCallPlans.future.length>0) ||
     (this.formattedCallPlans && this.formattedCallPlans.past && this.formattedCallPlans.past.length>0) ||
     (this.isSampleRequestEnabled && this.sampleEligibilities) || this.contactService.contactInformation.productSegmentations.length ||
     (this.currentScientificPlans && this.currentScientificPlans.length>0) || (this.futureScientificPlans && this.futureScientificPlans.length>0) ||
      (this.customerEvents && this.customerEvents.availableEvents.length > 0) || (this.customerEvents && this.customerEvents.upcomingEvents.length > 0) ||
      (this.consent && this.consent.activeConsents && this.consent.activeConsents.length > 0) ||
      (this.filteredSurveyList && !_.isEmpty(this.filteredSurveyList));
  }

  openSciInfoDetails(view: string) {
    if (this.contactService.accessedContactListFrom === PageName.AccountDetailsComponent && this.contactService.contactPageMode === ComponentViewMode.READONLY) return;
    this.uiService.scientificInfoView = view;
    this.uiService.showNewActivity = false;
    let modalOpts = {
      cssClass:'contactProfileScientificInfoModal',
      animated:true,
    }
    this.contactService.scientInfoModalOpen = true;
    this.modalCtrl.create({component: ScientificInfoDetailsComponent,componentProps: {}, ...modalOpts}).then((modal)=>{
      modal.present();
    })
  }

  onOpenSPContactProfile(sp){
    if (this.contactService.accessedContactListFrom === PageName.AccountDetailsComponent && this.contactService.contactPageMode === ComponentViewMode.READONLY) return;
    this.trackingService.tracking('ContactsProfileScientificPlans', TrackingEventNames.SCIENTIFICACTIVITYPLAN);
    // this.openScientificPlanDetails.emit(sp);
    this.navService.pushChildNavPageWithPageTracking(ScientificActivityDetail, PageName.ScientificActivityDetail, PageName.ContactPageComponent, { selectedSAP: sp, selectedContactId: this.contactService.contactInformation.ID, from: 'ContactTools' });
  }

  doContentInfinite(event){
    this.recordCount = this.meetingHistoryToDisplay.length;
    this.meetingHistoryToDisplay.push(...this.meetingHistory.slice(this.recordCount, this.recordCount + 20));
    event.target.complete();
  }

  private async initConsents() {
    this.uiService.displayLoader();
    let fetchXML = fetchQueries.fetchConsentsByContactId;
    fetchXML = fetchXML.replace('{contactId}', this.contactService.contactInformation.ID);
    const consents = await this.dynamics.executeFetchQuery('indskr_consents', fetchXML);
    console.log("Consents : ", consents);
    this.consents = consents;
    this.uiService.dismissLoader();
  }

  public getConsentMainCardDataModel(consent: any): MainCardViewDataModel {
    let viewData: MainCardViewDataModel;
    if (consent) {
      viewData = {
        id: consent.indskr_consentid,
        fixedHeight: true,
        primaryTextRight: consent.indskr_name,
        secondaryTextRight: this.getFormattedDateTime(consent.createdon)
      };
      if (consent.indskr_consentversion) {
        viewData.primaryTextLeft = `${this.translate.instant("VERSION")} ${consent.indskr_consentversion}`;
      }
    }
    return viewData;
  }

  public getFormattedDateTime(value: Date): string {
    if (value) {
      let capturedDay = this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
      let capturedTime = (new Date(value)).toLocaleTimeString('en-US',{ hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
      return capturedDay + " " + capturedTime;
    } else {
      return '';
    }
  }

  private _initSurveyList() {
    this.filteredSurveyList = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY && template?.indskr_surveytype != SurveyType.INTERNAL && new Date(template.indskr_validfrom) <= new Date() && new Date(template.indskr_validto) >= new Date() && template.indskr_entity == SurveyCategory.CONTACT);
    this.customerSurveyService.surveyTemplates.filter(list => list.indskr_surveytype != SurveyType.INTERNAL);
    if(!_.isEmpty(this.filteredSurveyList)) {
      this._initViewData();
    }
  }

  private _initViewData() {
    this.filteredSurveyListToDisplay = [];
    this.AvailableFilteredSurveyList = [];
    this.filteredSurveyList = _.orderBy(this.filteredSurveyList, ['indskr_validto','indskr_name'], ['desc', 'asc']);
    this.filteredSurveyList.forEach( (survey) => {
      const templateId: string = survey.indskr_assessmenttemplateid;
      const selectedContactId = this.selectedContact.ID;
      let isEligibleCustomer: boolean = false;
      //check eligible customers
      if(survey.indskr_metadata.hasOwnProperty('EligibleCustomers') && !_.isEmpty(survey.indskr_metadata.EligibleCustomers)) {
        let eligibleCustomersIDs: string[] = [];
        const eligibleCustomersFrommetaData = survey.indskr_metadata['EligibleCustomers'];
        eligibleCustomersIDs = eligibleCustomersFrommetaData.map(customer=>customer['ID']);
        isEligibleCustomer = eligibleCustomersIDs.some(contactId => contactId == selectedContactId);
      }else {
        isEligibleCustomer = true;
      }
      const isCapturedOneTimeSurvey: boolean = this.customerAssessService.isCapturedOneTimeSurvey(templateId, selectedContactId, 'contact');
      //To display top 2 available surveys based on eligible dates with eligible customers, without capturing one-time surveys
      if(this.filteredSurveyListToDisplay.length < 2) {
        if(isEligibleCustomer) {
          //check captured one-time survey
          if(survey.indskr_surveyfrequency == SurveyFrequency.ONCE) {
            if(!isCapturedOneTimeSurvey) {
              this.filteredSurveyListToDisplay.push(this._getSubCardDataModel(survey));
            }
          }else {
            this.filteredSurveyListToDisplay.push(this._getSubCardDataModel(survey));
          }
        }
      }
      //update number of available survey
      if(isEligibleCustomer) {
        //check captured one-time survey
        if(survey.indskr_surveyfrequency == SurveyFrequency.ONCE) {
          if(!isCapturedOneTimeSurvey) {
            this.AvailableFilteredSurveyList.push(survey);
          }
        }else {
          this.AvailableFilteredSurveyList.push(survey);
        }
      }
    });
}

  private async _initOpportunitiesList() {
    this.opportunitiesItems = [];
    this.applicableOpportunities = await this._getLatestOpportunitiesForOmnichannel(2);
    if (this.applicableOpportunities && this.applicableOpportunities.length > 0) {
      for (let item of this.applicableOpportunities) {
        let view = await this.customer360UIService._getProfileItemViewForOpportunities(item)
        if (view) {
          this.opportunitiesItems.push(view);
        }
      }
    }
    this._initOpportunitiesSectionHeader();
  }

  private async _getLatestOpportunitiesForOmnichannel(sliceEnd?: number) {
    let allItems = [];
    try {
      if (this.opportunityService.opportunities && this.opportunityService.opportunities.length) {
        let oppItems = this.opportunityService.opportunities.filter((oppo) => {
          const isContact = oppo.contactID && oppo.contactID == this.contactService.contactInformation.ID;
          const isStakeholder = oppo.stakeholders?.length > 0 && oppo.stakeholders.some(opp => opp.ID == this.contactService.contactInformation.ID);
          if (isContact || isStakeholder) return true
        })
        let allOpportunities = oppItems.sort((a, b) => {
          if (a.createdOn && b.createdOn) {
            return parseInt(b.createdOn) - parseInt(a.createdOn)
          } else {
            return 0;
          }
        });
        let opportunities = allOpportunities.slice(0, sliceEnd);
        if (opportunities && opportunities.length > 0) {
          allItems.push(...opportunities);
        }
      }
    } catch (error) {
      console.log('Error occured while getting events for journey section' + error);
    }
    return allItems;
  }


 

  openOpportunityDetail(item) {
    let opportunityId = item.refObject.ID
    this.navService.pushChildNavPageWithPageTracking(OpportunityDetailsComponent,
      PageName.OpportunityDetailsComponent, PageName.ContactPageComponent, { from: 'ContactTimeline', opportunity: this.opportunityService.getOpportunityDetailsbyId(opportunityId) })
  }


  private _initSurveySectionHeader() {
    const numOfList: number = this.AvailableFilteredSurveyList.length;
    this.surveyListSectionHeader = {
      id: 'survey_list_section_header',
      // title: `${this.translate.instant("AVAILABLE")} ${this.translate.instant("SURVEY")} (${numOfList})`,
      title: `${this.translate.instant("AVAILABLE_SURVEY")} (${numOfList})`,
      isShowSperationVerticalLine: true,
      controls: [
        {
          id: "goto-contact-timeline",
          text: '',
          icon: "assets/imgs/survey-icon-blue-time.svg",
          isDisabled: this.readOnly //check available survey - submitted data
        },
        {
          id: 'goto-AllSurveyTemplate-list',
          text: '',
          icon: "assets/imgs/chevron-forward-outline-blue.svg",
          isDisabled: _.isEmpty(this.filteredSurveyList) || this.readOnly,
      }],
    };
  }

  private _getSubCardDataModel(survey: AssessmentTemplate): SubCardViewDataModel {
    const viewData: SubCardViewDataModel = {
      id: survey.indskr_assessmenttemplateid,
      primaryTextRight: survey.indskr_name,
      isSubPrimaryTextRight: true,
      subPrimaryTextRight: this.translate.instant('EXPIRES_ON') + ' ' + this._getFormattedDate(survey['indskr_validto']),
      isSecondaryGrid: true,
      gridItems: [],
      startIconInline: "assets/imgs/survey_blue_bluedot.svg",
      buttonItems: [],
      clickHandler: (id: string, event, specificTarget) => {}
    };
    //grid items
    let gridItems: any[];

    if (survey.indskr_description) {
      gridItems = [
        {
          id: 'description',
          label: '',
          value: survey.indskr_description,
          descriptionField: true,
        },
        {
          id: 'type',
          label: this.translate.instant('SURVEY_TYPE'),
          value: survey.indskr_surveytype === SurveyType.IN_MEETING ? this.translate.instant('IN_MEETING') : this.translate.instant('CUSTOMER'),
          rawValue: survey['indskr_surveytype'],
        },
        {
          id: 'use-type',
          label: this.translate.instant('USE_TYPE'),
          value: this.getSurveyFrequency(survey),
          rawValue: survey['indskr_surveyfrequency'],
        },
      ];
    } else {
      gridItems = [
        {
          id: 'type',
          label: this.translate.instant('SURVEY_TYPE'),
          value: survey.indskr_surveytype === SurveyType.IN_MEETING ? this.translate.instant('IN_MEETING') : this.translate.instant('CUSTOMER'),
          rawValue: survey['indskr_surveytype'],
        },
        {
          id: 'use-type',
          label: this.translate.instant('USE_TYPE'),
          value: this.getSurveyFrequency(survey),
          rawValue: survey['indskr_surveyfrequency'],
        },
        {
          id: 'status',
          // label: this.translate.instant("SURVEY_CUSTOMER_SUVEYED"),
          label: this.translate.instant("CONTACT_ELIGIBLE"),
          value: this.getCustomerSurveyed(survey),
          rawValue: undefined,
        },
      ];
    }
    viewData.gridItems = gridItems;
    //buttons - If external type and one-time survey is captured, start-survey button is disabled, start-survey on preview is disabled
    viewData.buttonItems.push(
      {
        id: 'preview',
        name: this.translate.instant("PREVIEW"),
        isDisabled: this.contactService.contactPageMode != ComponentViewMode.LISTVIEW
      },
    );

    if (survey.indskr_surveytype !== SurveyType.IN_MEETING) {
      viewData.buttonItems.push(
        {
          id: 'start-survey',
          name: this.translate.instant("START_SURVEY"),
          isDisabled: this.contactService.contactPageMode != ComponentViewMode.LISTVIEW
        }
      );
    }
    return viewData;
  }

  private getCustomerSurveyed(survey: AssessmentTemplate): string {
    return this.customerAssessService.getNumOfCustomerSurveyed(survey);
  }

  private getSurveyFrequency(survey: AssessmentTemplate) {
    return survey.indskr_surveyfrequency == SurveyFrequency.ONCE ? this.translate.instant("ONE_TIME") : this.translate.instant("RECURRING");
  }

  private _getFormattedDate(date: any): string {
    const formattedDate = this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    return formattedDate;
  }

  public onSectionHeaderControlClick(id:string) {
    switch(id){
      case 'goto-contact-timeline':
        this.contactService.isOpenedSurveyTimelineFromContactProfile = true;
        if (this.isCustomer360UIEnabled) {
          this.uiService.timelineRefreshRequired = true;
          this.navService.pushChildNavPageWithPageTracking(ContactTimelinePageComponent,PageName.ContactTimelinePageComponent,PageName.ContactPageComponent, {
            filterEnabled: true,
          });
        }else {
          this.uiService.contactDetailsSegment = 'timeline';
        }
        
        break;
      case 'goto-AllSurveyTemplate-list':
        this._openSurveyListPage();
        break;
      case 'openeventactivitieslist':
        this._openEventActivitiesList();
        break;
      case 'opensentimenthistorylist':
        this._openSentimentHistoryList();
        break;
        case 'openOpportunitieslist':
          this._openOpportunitiesList();
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  public onButtonControlClick(event) {
    if(!_.isEmpty(event) && !_.isEmpty(event.button)) {
      const buutonId = event.button.id;
      const surveyTemplate = event.viewData;
      console.log("clicked button ", buutonId);
      if(buutonId == 'preview') {
        this.openSurvey(surveyTemplate, true);
      }else if(buutonId == 'start-survey') {
        this.openSurvey(surveyTemplate, false);
      }
    }
  }

  async openSurvey(selectedTemplateFromCardView: any, isReadOnly: boolean) {
    if(!_.isEmpty(selectedTemplateFromCardView)) {
      await this.uiService.displayLoader();
      const rawSurveyTemplateList = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY && new Date(template.indskr_validfrom) <= new Date() && new Date(template.indskr_validto) >= new Date);
      const foundTemplate: AssessmentTemplate = rawSurveyTemplateList?.find(template=> template.indskr_assessmenttemplateid == selectedTemplateFromCardView.id);
      if(!_.isEmpty(foundTemplate)) {
        this.customerSurveyService.setCurrentSurvey(foundTemplate);
        try {
          await this.navService.pushChildNavPageWithPageTracking(SurveyDetailsComponent, PageName.SurveyDetailsComponent, PageName.ContactDetailsComponent, { appointmentId: '', from: PageName.ContactDetailsComponent }).then(async()=>{
            this.navService.setChildNavRightPaneView(true);
            this.uiService.dismissLoader();
            this.customerSurveyService.selectedContactForSurvey = this.contactService.contactInformation;;

            if(!isReadOnly) {
              //check saved survey data from db before starting the survey
              const contactId: string = this.customerSurveyService.selectedContactForSurvey.ID || '';
              const templateId: string = foundTemplate.indskr_assessmenttemplateid || '';
              if(!_.isEmpty(contactId) && !_.isEmpty(templateId)) {
                // let surveysFromDB = await this.customerAssessService.getCustomerSurveyFromDB(templateId);
                let surveysByTemplateId = this.customerAssessService.getSurveysByTemplateId(templateId, 'contact', false);
                if(!_.isEmpty(surveysByTemplateId)) {
                  let surveyDataFromDB: CustomerAssessment;
                  let savedSurvey = surveysByTemplateId.find(survey => survey.surveyStatus === SurveyStatus.SAVED && survey.indskr_entityid === contactId);
                  if (savedSurvey) {
                      surveyDataFromDB = savedSurvey;
                  }
                  // response update
                  const responseData = await this.customerAssessService.fetchContactSurveyForDetails(surveyDataFromDB, 'contact', true);
                  if(!_.isEmpty(responseData)) {
                    surveyDataFromDB.responses = responseData;
                  }
                  this.customerSurveyService.setSurveyPreviewMode(false, surveyDataFromDB);
                }else {
                  //There is no saved data with the selected contact and survey template
                  this.customerSurveyService.setSurveyPreviewMode(false);
                }
              }
            }else {
              //preview template
              this.customerSurveyService.setSurveyPreviewMode(true);
            }
          });
        } catch(error) {
          console.log("failed to open survey ", error);
          this.uiService.dismissLoader();
        }
      }else {
        console.log("There is no found template before opening the survey");
        this.uiService.dismissLoader();
      }
    }
  }

  private async _openSurveyListPage() {
    await this.uiService.displayLoader();
    try {
      await this.navService.pushChildNavPageWithPageTracking(SurveyListCardviewComponent, PageName.SurveyDetailsComponent, PageName.ContactDetailsComponent, { appointmentId: '', from: PageName.ContactDetailsComponent }).then(async()=>{
        this.navService.setChildNavRightPaneView(true);
        this.uiService.dismissLoader();
        this.customerSurveyService.selectedContactForSurvey = this.contactService.contactInformation;;
      });
    } catch(error) {
      console.log("failed to open survey ", error);
      this.uiService.dismissLoader();
    }
  }

  private _initEventsSectionHeader() {
    this.eventsSectionHeader = {
      id: 'events_activities_section_header',
      title: this.translate.instant("EVENTS"),
      controls: [{
        id: 'openeventactivitieslist',
        isDisabled: this.readOnly,
        iconName: "chevron-forward-outline",
      }]
    };
  }
  private _initOpportunitiesSectionHeader() {
    this.opportunitiesHeader = {
      id: 'Opportunities',
      title: this.translate.instant('OPPORTUNITIES_CAPS'),
      controls: [{
        id: 'openOpportunitieslist',
        isDisabled:this.opportunitiesItems.length === 0,
        iconName: "chevron-forward-outline",
      }]
    }
  }

  private _initSentimentHistorySectionHeader() {
    this.sentimentHistorySectionHeader = {
      id:"contact-content-history-header", 
      title: this.translate.instant("SENTIMENT_HISTORY"),
      controls: [{
        id: 'opensentimenthistorylist',
        isDisabled: this.readOnly,
        iconName: "chevron-forward-outline",
      }]
    }
  }

  private _initEventActivitiesList() {
    if(this.contactService.contactInformation.ID){
      this.eventItems = [];
      let events: Array<EventActivity> = this._getLatestEventsForOmnichannel(2);
      if (events && events.length > 0) {
        events.forEach(item => {
          let view = this.customer360UIService.getOmnichannelViewForEventActivity(item);
          if (view) {
            this.eventItems.push(view);
          }
        })
      }
    }
  }

  private _getLatestEventsForOmnichannel(sliceEnd?: number): Array<EventActivity> {
    let allItems = [];
    let result = [];
    const contactID = this.contactService.contactInformation.ID;
    // Event
    try {
      if (this.eventsToolService.eventsToolData && this.eventsToolService.eventsToolData.length > 0) {
        let eventItems = this.eventsToolService.eventsToolData.filter(a=> {
          return (( a.participants && a.participants.some(b=> b.id == contactID && !(
            b.isDeleted
            || b.indskr_reasons === EventRegistrationStatus.Cancelled
            || b.indskr_reasons === EventRegistrationStatus.Declined
            || b.indskr_reasons === EventRegistrationStatus['Cancelled late']
            || b.indskr_reasons === EventRegistrationStatus['No show'])) ) || (a.statuscode == EventStatus.DRAFT  && isFuture(a.endDate)))
        }).sort((a, b) => {
          if (a.scheduledStart > b.scheduledStart) return -1;
          if (a.scheduledStart < b.scheduledStart) return 1;
          return 0;
        }).slice(0, sliceEnd);
        if (eventItems && eventItems.length > 0) {
          allItems.push(...eventItems);
        }
      }
    } catch (error) {
      console.log('Error occured while getting events for journey section' + error);
    }
    return allItems;
  }

  private _openEventActivitiesList() {
    this.navService.pushChildNavPageWithPageTracking(ContactOmnichannelEventsComponent,PageName.ContactOmnichannelEventsComponent,PageName.ContactPageComponent);
  }

  private _openSentimentHistoryList(){
    this.navService.pushChildNavPageWithPageTracking(ContactSentimentHistoryListComponent,PageName.ContactOmnichannelEventsComponent,PageName.ContactPageComponent);
  }

  
  private _openOpportunitiesList(){
    this.navService.pushChildNavPageWithPageTracking(ContactOmnichannelOpportunitiesComponent,PageName.ContactOmnichannelOpportunitiesComponent,PageName.ContactPageComponent, {
      filterEnabled: false,
      from:'opportunities-list-section'
    });
  }

  public async openEventDetails(ev) {
    let item: any = this.eventItems.find(a => ev && a.id == ev.value);
    if (item) {
      let activity = item.refObject;
      if (activity.type == ActivityType.Event || activity instanceof EventActivity) {
        const eventsToolData = this.eventsToolService.eventsToolData;
        const foundEvent = eventsToolData.find(e => {
          if (e.ID) {
            return e.ID === activity.ID;
          } else if (e.offlineId) {
            return e.offlineId === activity.offlineId;
          } else {
            return false;
          }
        });
        if (!_.isEmpty(foundEvent)) {
          activity = foundEvent;
          this.eventsToolService.selectedEventOnEventsTool = foundEvent;
        } else {
          return;
        }
        if (!this.device.isOffline) {
          this.uiService.displayLoader();
          await this.eventsToolDataService.fetchEventRealTimeDetails(activity as EventActivity).then((updatedEvent: EventActivity) => {
            activity = updatedEvent;
            this.uiService.dismissLoader();
          }).catch(err => {
            console.log(err);
            this.uiService.dismissLoader();
          });
        }
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selectedActivity = activity;
        this.holdActivityDetailsPage = PageName.EventDetailsPageComponent;
        this.navService.pushChildNavPageWithPageTracking(EventDetailsComponent, PageName.EventDetailsPageComponent, PageName.ContactPageComponent, {
          from: 'ContactTimeline', event: this.activityService.selectedActivity,
        });
        this.eventsToolService.setSelectedEventUpdates(this.activityService.selectedActivity as EventActivity);
        this.footerService.initButtons(FooterViews.EVENTS_DETAILS);
      }
    }
  }

  private _initMeetingHistoryViewItems(){
    if(this.meetingHistoryData && this.meetingHistoryData.length > 0){
      this.meetingHistoryItems = [];
      const activities = this.meetingHistoryData.sort((a, b) => {
        if (a.scheduledStart > b.scheduledStart) return -1;
        if (a.scheduledStart < b.scheduledStart) return 1;
        return 0;
      }).slice(0, 2);
      activities.forEach(item => {
        const view = this.customer360UIService.getSentimentHistoryViewForMeetingActivity(item as AppointmentActivity);
        if(view){
          if(this.readOnly) view.disableEndArrow = true;
          this.meetingHistoryItems.push(view);
        }
      })
    }
  }

  public openItemDetails(ev){
    let item: any = this.meetingHistoryData.find(a => ev && a.ID == ev.value);
    if (item) {
      if (item) {
        const params = {
          from: 'ContactSentimentHistoryList',
          meeting: item,
        }
        this.navService.pushChildNavPageWithPageTracking(MeetingSentimentHistoryDetailComponent, PageName.MeetingSentimentHistoryDetailComponent, PageName.ContactPageComponent, params);
      }
    }
  }
}
