import { Injectable } from '@angular/core';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { AuthenticationService } from '../authentication.service';
import { GlobalUtilityService } from '../global-utility.service';

@Injectable({
  providedIn: 'root'
})

export class FooterButtons {

  constructor(
    private authService: AuthenticationService,
    private utilityService: GlobalUtilityService) {

  }

  public getFooterOptions() {
    return [
      // Meeting Details Icons
      {
        "id": "meetingEvent",
        "iconurl": "assets/imgs/events.svg",
        "isactive": true,
        "isvisible": false,
        "name": "EVENT",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 50
      },
      {
        "id": "meetingReopen",
        "iconurl": "assets/imgs/activity_reopen.svg",
        "isactive": true,
        "isvisible": false,
        "name": "REOPEN",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 50
      },
      {
        "id": "meetingassignOwner",
        "iconurl": "assets/imgs/assign_owner.svg",
        "isactive": true,
        "isvisible": false,
        "name": "ASSIGN",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 50
      },
      {
        "id": "meetingOpportunity",
        "iconurl": "assets/imgs/opportunity_mgmt_tool.svg",
        "isactive": true,
        "isvisible": false,
        "name": "OPPORTUNITY",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 50
      },
      {
        "id": "meetingAssets",
        "iconurl": "assets/imgs/meeting_assets.svg",
        "isactive": true,
        "isvisible": false,
        "name": "ASSETS",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 51
      },
      {
        "id": "meetingCustomerInquiry",
        "iconurl": "assets/imgs/meeting_custInquiry.svg",
        "isactive": true,
        "isvisible": false,
        "name": "INQUIRY",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 0
      },
      {
        "id": "presentationCustomerInquiry",
        "iconurl": "assets/imgs/meeting_custInquiry.svg",
        "isactive": true,
        "isvisible": false,
        "name": "INQUIRY",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 14
      },
      {
        "id": "createEmailInMeeting",
        "iconurl": "assets/imgs/resource-email-icon.svg",
        "isactive": true,
        "isvisible": false,
        "name": "attachEmail",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": ''
      },
      {
        "id": "createEmailInPresentationPreview",
        "iconurl": "assets/imgs/resource-email-icon.svg",
        "isactive": true,
        "isvisible": false,
        "name": "attachEmail",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 13
      },
      {
        "id": "resources",
        "iconurl": "assets/imgs/action_button_resources.svg",
        "isactive": true,
        "isvisible": false,
        "name": "RESOURCES",
        "isBadge": true,
        "badgeValue": '',
        "disableWhenOffline": true,
        "eventhandler": 'openResourcesView()',
        "featureActions": [FeatureActionsMap.RESOURCE_TOOL],
        "displayOrder": 11
      },
      {
        "id": "references",
        "iconurl": "assets/imgs/action_button_open_in_new_window.svg",
        "isactive": true,
        "isvisible": false,
        "name": "REFERENCES",
        "isBadge": false,
        "badgeValue": '',
        "disableWhenOffline": true,
        "eventhandler": '',
        "featureActions": [FeatureActionsMap.CONTENT_REFERENCES],
        "displayOrder": 12
      },
      {
        "id": "referencesactive",
        "iconurl": "assets/imgs/action_button_open_in_new_window_active.svg",
        "isactive": true,
        "isvisible": false,
        "name": "REFERENCES",
        "isBadge": false,
        "badgeValue": '',
        "disableWhenOffline": true,
        "eventhandler": '',
        "featureActions": [],
        "displayOrder": 12
      },
      {
        "id": "cancelmeeting",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 50,
        detail: true
      },
      {
        "id": "cancelorscrapmeeting",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "DELETE_OR_CANCEL_PROCEDURE_LOG_HEADER",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 50,
        detail: true
      },
      {
        "id": "markcomplete",
        "iconurl": "assets/imgs/action_button_complete.svg",
        "isactive": true,
        "isvisible": false,
        "name": "COMPLETE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 50,
        detail: true
      },
      {
        "id": "createOrEditSampleOrder",
        "iconurl": "assets/imgs/action_button_sampling.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SIGNATURE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": 'openContactView()'
      },
      {
        "id": "contactspresentationpreview",
        "iconurl": "assets/imgs/action_button_contacts.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CUSTOMERS",
        "isBadge": true,
        "badgeValue": '',
        "eventhandler": 'openContactView()',
        "displayOrder": 2,
        detail: true
      },
      {
        "id": "message",
        "iconurl": "assets/imgs/share_dark.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SHARE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 6
      },
      {
        "id": "contacts",
        "iconurl": "assets/imgs/action_button_contacts.svg",
        "isactive": true,
        "isvisible": false,
        "name": "Contacts",
        "isBadge": true,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right",
        "variableVisibility": false,
        "displayOrder": 6,
        detail: true,
        "external": this.utilityService.globalCustomerText
      },
      {
        "id": "contactsMessage",
        "iconurl": "assets/imgs/action_button_contacts.svg",
        "isactive": true,
        "isvisible": false,
        "name": "Contacts",
        "isBadge": true,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 0,
        detail: true,
        "external": this.utilityService.globalCustomerText
      },
      {
        "id": "presentations",
        "iconurl": "assets/imgs/action_button_presentations.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CONTENT",
        "isBadge": true,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 4,
        detail: true
      },
      {
        "id": "video",
        "iconurl": "assets/imgs/action_button_video.svg",
        "isactive": true,
        "isvisible": false,
        "name": "VIDEO",
        "isBadge": false,
        "disableWhenOffline": true,
        "badgeValue": '',
        "eventhandler": '',
        "featureActions": [FeatureActionsMap.VIDEO_BUTTON],
        "availableOnFullScreen": true,
        "displayOrder": 8

      },
      {
        "id": "stop_video_active",
        "iconurl": "assets/imgs/action_button_stop_video.svg",
        "isactive": true,
        "isvisible": false,
        "name": "VIDEO",
        "isBadge": false,
        "badgeValue": '',
        "disableWhenOffline": true,
        "eventhandler": '',
        "featureActions": [FeatureActionsMap.VIDEO_BUTTON],
        "availableOnFullScreen": true,
        "displayOrder": 8
      },
      {
        "id": "audioactive",
        "iconurl": "assets/imgs/audio_active.png",
        "isactive": true,
        "isvisible": false,
        "name": "AUDIO",
        "isBadge": false,
        "disableWhenOffline": true,
        "featureActions": [FeatureActionsMap.MEETING_VOIP],
        "badgeValue": '',
        "eventhandler": '',
        "availableOnFullScreen": true,
        "displayOrder": 7
      },
      {
        "id": "stop_audio",
        "iconurl": "assets/imgs/action_button_speaker_default_2x.png",
        "isactive": true,
        "isvisible": false,
        "name": "AUDIO",
        "isBadge": false,
        "disableWhenOffline": true,
        "featureActions": [FeatureActionsMap.MEETING_VOIP],
        "badgeValue": '',
        "eventhandler": '',
        "availableOnFullScreen": true,
        "displayOrder": 7
      },
      {
        "id": "presentationsrightalligned",
        "iconurl": "assets/imgs/action_button_presentations.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CONTENT",
        "isBadge": true,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 4
      },
      {
        "id": "presentationsleftalligned",
        "iconurl": "assets/imgs/action_button_presentations.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CONTENT",
        "isBadge": true,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 4
      },
      {
        "id": "play",
        "iconurl": "assets/imgs/action_button_play.svg",
        "isactive": true,
        "isvisible": false,
        "name": "PLAY",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "availableOnFullScreen": true,
        "displayOrder": 2
      },
      {
        "id": "stop",
        "iconurl": "assets/imgs/action_button_stop.png",
        "isactive": true,
        "isvisible": false,
        "name": "Stop",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "pause",
        "iconurl": "assets/imgs/action_button_pause_active.svg",
        "isactive": true,
        "isvisible": false,
        "name": "PAUSE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "availableOnFullScreen": true,
        "displayOrder": 2
      },
      {
        "id": "carousel",
        "iconurl": "assets/imgs/action_button_carousel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CAROUSEL",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "availableOnFullScreen": true,
        "displayOrder": 7
      },
      {
        "id": "carouselactive",
        "iconurl": "assets/imgs/action_button_carousel_active.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CAROUSEL",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "availableOnFullScreen": true,
        "displayOrder": 7
      },
      {
        "id": "meetingswipeenabledactive",
        "iconurl": "assets/imgs/pres_swipe_active.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SWIPE",
        "disableWhenOffline": false,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "availableOnFullScreen": true,
        "displayOrder": 6
      },
      {
        "id": "meetingswipedisabled",
        "iconurl": "assets/imgs/pres_swipe_inactive.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SWIPE",
        "disableWhenOffline": false,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "availableOnFullScreen": true,
        "displayOrder": 6
      },
      {
        "id": "createOrEditSampleOrderPresentationPreview",
        "iconurl": "assets/imgs/action_button_sampling.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SIGNATURE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 3
      },
      {
        "id": "preview",
        "iconurl": "assets/imgs/action_button_preview.svg",
        "isactive": true,
        "isvisible": false,
        "name": "PRESENT",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 7
      },
      {
        "id": "checkin",
        "iconurl": "assets/imgs/check_in.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CHECK_IN_FOOTBAR",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "featureActions": [FeatureActionsMap.ENABLE_GPS_CHECK_IN],
        "displayOrder": 5,
       
      },
      {
        "id": "checkout",
        "iconurl": "assets/imgs/check_in.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CHECK_OUT_FOOTBAR",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "featureActions": [FeatureActionsMap.ENABLE_GPS_CHECK_IN],
        "displayOrder": 5,
        
      },
      {
        "id": "teams_meeting",
        "iconurl": "assets/imgs/action_button_livetime.svg",
        "isactive": true,
        "isvisible": false,
        "name": "ACTIVITY_DETAILS_LIVE_TIME",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 50
      },
      {
        "id": "homepage",
        "iconurl": "assets/imgs/action_button_preview.svg",
        "isactive": true,
        "isvisible": false,
        "name": "FINISH",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 15
      },
      {
        "id": "homepage_disabled",
        "iconurl": "assets/imgs/action_button_preview_disabled.svg",
        "isactive": true,
        "isvisible": false,
        "name": "FINISH",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 15
      },
      {
        "id": "openmoreoptions",
        "iconurl": "assets/imgs/action_button_more.svg",
        "isactive": true,
        "isvisible": false,
        "name": "MORE",
        "disableWhenOffline": false,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "availableOnFullScreen": true,
        "displayOrder": 200
      },
      {
        "id": "openmoreoptionsactive",
        "iconurl": "assets/imgs/action_button_more_active.svg",
        "isactive": true,
        "isvisible": false,
        "name": "MORE",
        "disableWhenOffline": false,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "availableOnFullScreen": true,
        "displayOrder": 200
      },
      //Other Icons
      {
        "id": "presentationcarousel",
        "iconurl": "assets/imgs/action_button_carousel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CAROUSEL",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "availableOnFullScreen": true,
        "displayOrder": 1
      },
      {
        "id": "presentationcarouselactive",
        "iconurl": "assets/imgs/action_button_carousel_active.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CAROUSEL",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "availableOnFullScreen": true,
        "displayOrder": 1
      },
      {
        "id": "presentationswipeenabled",
        "iconurl": "assets/imgs/pres_swipe_active.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SWIPE",
        "disableWhenOffline": false,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 5,
        "availableOnFullScreen": true,
      },
      {
        "id": "presentationswipedisabled",
        "iconurl": "assets/imgs/pres_swipe_inactive.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SWIPE",
        "disableWhenOffline": false,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "availableOnFullScreen": true,
      },
      {
        "id": "appsettingupload",
        "iconurl": "assets/imgs/action_button_send.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SEND",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
      },
      {
        "id": "screencapture",
        "iconurl": "assets/imgs/action_button_capture.svg",
        "isactive": true,
        "isvisible": false,
        "name": "Capture Screen",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"
      },
      {
        "id": "scrapemail",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1,
        detail: true
      },
      {
        "id": "personalize_template",
        "iconurl": "assets/imgs/action_button_template_personalize.svg",
        "isactive": true,
        "isvisible": false,
        "name": "PERSONALIZE",
        "isBadge": false,
        "eventhandler": '',
        "displayOrder": 2,
        detail: true
      },
      {
        "id": "template",
        "iconurl": "assets/imgs/select_template.svg",
        "isactive": true,
        "isvisible": false,
        "name": "TEMPLATE",
        "isBadge": false,
        "eventhandler": '',
        "displayOrder": 3,
        detail: true
      },
      {
        "id": "cancelSamplingInMeeting",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "attachment",
        "iconurl": "assets/imgs/action_button_attachment.svg",
        "isactive": true,
        "isvisible": false,
        "name": "ATTACHMENTS_CAMEL_CASE",
        "isBadge": true,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 4,
        detail: true
      },
      {
        "id": "delete",
        "iconurl": "assets/imgs/action_button_delete.png",
        "isactive": true,
        "isvisible": false,
        "name": "DELETE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"
      },
      {
        "id": "mic",
        "iconurl": "assets/imgs/action_button_mic.svg",
        "isactive": true,
        "isvisible": false,
        "name": "MIC",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"
      },
      {
        "id": "geenie",
        "iconurl": "assets/imgs/ogeenee.svg",
        "isactive": true,
        "isvisible": false,
        "name": "MyAssistant",
        "isBadge": true,
        "badgeValue": '',
        "eventhandler": '',
        "align": "center"
      },
      {
        "id": "email",
        "iconurl": "assets/imgs/remote-email-icon.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SEND",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"
      },
      {
        "id": "contactsAllocation",
        "iconurl": "assets/imgs/action_button_contacts.svg",
        "isactive": true,
        "isvisible": false,
        "name": 'Contact',
        "isBadge": true,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2,
        "external": this.utilityService.globalCustomerText
      },
      {
        "id": "share-resource",
        "iconurl": "assets/imgs/resources_share2.svg",
        "isactive": false,
        "isvisible": false,
        "name": "SHARE",
        "isBadge": true,
        "badgeValue": '',
        "eventhandler": 'openResourcesView()',
        "align": "right"
      },
      {
        "id": "clear",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CLEAR",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 3
      },
      {
        "id": "phoneCallReopen",
        "iconurl": "assets/imgs/activity_reopen.svg",
        "isactive": true,
        "isvisible": false,
        "name": "REOPEN",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": -1
      },
      {
        "id": "contactsphonecall",
        "iconurl": "assets/imgs/action_button_contacts.svg",
        "isactive": true,
        "isvisible": false,
        "name": "Contacts",
        "isBadge": true,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1,
        "external": this.utilityService.globalCustomerText
      },
      {
        "id": "scrapphonecall",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "markcompletephonecall",
        "iconurl": "assets/imgs/action_button_complete.svg",
        "isactive": true,
        "isvisible": false,
        "name": "COMPLETE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 3
      },
      {
        "id": "send",
        "iconurl": "assets/imgs/action_button_send.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SEND",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 5
      },
      {
        "id": "sendEmail",
        "iconurl": "assets/imgs/action_button_send.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SEND",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 5
      },
      {
        "id": "viewMeeting",
        "iconurl": "assets/imgs/omni_appointment.svg",
        "isactive": true,
        "isvisible": false,
        "name": "VIEW_MEETING",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 0
      },
      {
        "id": "reject",
        "iconurl": "assets/imgs/action_button_reject.svg",
        "isactive": true,
        "isvisible": false,
        "name": "NOT_APPROVED",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "approve",
        "iconurl": "assets/imgs/action_button_approve.svg",
        "isactive": true,
        "isvisible": false,
        "name": "APPROVE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "fullscreen",
        "iconurl": "assets/imgs/action_button_fullscreen.svg",
        "isactive": true,
        "isvisible": false,
        "name": "Fullscreen",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 4
      },
      {
        "id": "presentaion_detail",
        "iconurl": "assets/imgs/list-view.svg",
        "isactive": true,
        "isvisible": false,
        "name": "PRESENTATION_DETAIL",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "exitfullscreen",
        "iconurl": "assets/imgs/action_button_contract.svg",
        "isactive": true,
        "isvisible": false,
        "name": "BACK",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 4
      },
      {
        "id": "deletetimeoff",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "savetimeoff",
        "iconurl": "assets/imgs/scheduling_save_update_icon.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CR_SAVE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "sendtimeoff",
        "iconurl": "assets/imgs/action_button_send.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SEND",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 3
      },
      {
        "id": "notapprovedtimeoff",
        "iconurl": "assets/imgs/action_button_reject.svg",
        "isactive": true,
        "isvisible": false,
        "name": "NOT_APPROVE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 4
      },
      {
        "id": "approvetimeoff",
        "iconurl": "assets/imgs/action_button_approve.svg",
        "isactive": true,
        "isvisible": false,
        "name": "APPROVE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
      },
      {
        "id": "deletecoaching",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "closecoaching",
        "iconurl": "assets/imgs/action_button_complete.svg",
        "isactive": true,
        "isvisible": false,
        "name": "COMPLETE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 3
      },
      {
        "id": "sharecoaching",
        "iconurl": "assets/imgs/action_button_send.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SHARE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "sendcoaching",
        "iconurl": "assets/imgs/action_button_send.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SEND",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "ackcoaching",
        "iconurl": "assets/imgs/coaching-acknowledge-icon.svg",
        "isactive": true,
        "isvisible": false,
        "name": "ACKNOWLEDGE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "recallcoaching",
        "iconurl": "assets/imgs/header_reopen.svg",
        "isactive": true,
        "isvisible": false,
        "name": this.authService.hasFeatureAction(FeatureActionsMap.ACCESS_COACHING_PLAN)? "REOPEN" : "RECALL",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right",
        "displayOrder": 3
      },
      {
        "id": "acceptcoaching",
        "iconurl": "assets/imgs/header_complete.svg",
        "isactive": true,
        "isvisible": false,
        "name": "ACCEPT",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "samplingeligibilities",
        "iconurl": "assets/imgs/action_button_eligibilities.svg",
        "isactive": true,
        "isvisible": false,
        "name": "ELIGIBILITIES",
        "isBadge": true,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 3
      },
      {
        "id": "signature",
        "iconurl": "assets/imgs/action_button_signature.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SIGNATURE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"
      },
      {
        "id": "flipToSampleDetails",
        "iconurl": "assets/imgs/action_button_preview.svg",
        "isactive": true,
        "isvisible": false,
        "name": "FLIP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"
      },
      {
        "id": "acknowledgeShipment",
        "iconurl": "assets/imgs/action_button_complete.svg",
        "isactive": true,
        "isvisible": false,
        "name": "RECEIPT",
        "isBadge": false,
        "disableWhenOffline": true,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"
      },
      {
        "id": "suggestscheduler",
        "iconurl": "assets/imgs/suggest_schedule_icon.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SUGGEST",
        "isBadge": false,
        "disableWhenOffline": true,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "copyscheduler",
        "iconurl": "assets/imgs/copy_white.svg",
        "isactive": true,
        "isvisible": true,
        "name": "COPY",
        "isBadge": false,
        "disableWhenOffline": true,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "scrapScheduler",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "disableWhenOffline": true,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 3
      },
      {
        "id": "savescheduler",
        "iconurl": "assets/imgs/scheduling_save_update_icon.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SAVE",
        "isBadge": false,
        "disableWhenOffline": true,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 4
      },
      {
        "id": "editText",
        "iconurl": "assets/imgs/action_button_edittext.svg",
        "isactive": true,
        "isvisible": false,
        "name": "editText",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"
      },
      {
        "id": "confirmChanges",
        "iconurl": "assets/imgs/action_button_confirmchanges.svg",
        "isactive": true,
        "isvisible": false,
        "name": "confirmChanges",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"
      },
      {
        "id": "generateconsents",
        "iconurl": "assets/imgs/capture_sign.svg",
        "isactive": true,
        "isvisible": false,
        "name": "GENERATE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "consentFlip",
        "iconurl": "assets/imgs/action_button_preview.svg",
        "isactive": true,
        "isvisible": false,
        "name": "FLIP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "consentFlipDetails",
        "iconurl": "assets/imgs/action_button_preview.svg",
        "isactive": true,
        "isvisible": false,
        "name": "FLIP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"
      },
      {
        "id": "cancelprogressreport",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "sendprogressreport",
        "iconurl": "assets/imgs/action_button_send.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SEND",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "notapproveprogressreport",
        "iconurl": "assets/imgs/action_button_reject.svg",
        "isactive": true,
        "isvisible": false,
        "name": "NOT_APPROVE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "approveprogressreport",
        "iconurl": "assets/imgs/action_button_approve.svg",
        "isactive": true,
        "isvisible": false,
        "name": "APPROVE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "scrapfollowup",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "markfollowupcancel",
        "iconurl": "assets/imgs/Lost.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CANCEL",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "markfollowupcomplete",
        "iconurl": "assets/imgs/action_button_complete.svg",
        "isactive": true,
        "isvisible": false,
        "name": "COMPLETE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 3
      },
      {
        "id": "cancelProgressReportReview",
        "iconurl": "assets/imgs/cancel_review.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CANCEL",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "scrapcase",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "submitcase",
        "iconurl": "assets/imgs/action_button_send.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SUBMIT",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "notapproveorderactivity",
        "iconurl": "assets/imgs/action_button_reject.svg",
        "isactive": true,
        "isvisible": false,
        "name": "NOT_APPROVE",
        "disableWhenOffline": true,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "approveorderactivity",
        "iconurl": "assets/imgs/action_button_approve.svg",
        "isactive": true,
        "isvisible": false,
        "name": "APPROVE",
        "disableWhenOffline": true,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "scraporderactivity",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "autoapproveorderactivity",
        "iconurl": "assets/imgs/scheduling_save_update_icon.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SAVE",
        "disableWhenOffline": true,
        "isBadge": false,
        "badgeValue": "",
        "eventhandler": "",
        "displayOrder": 3
      },
      {
        "id": "sendorderactivityforreview",
        "iconurl": "assets/imgs/action_button_send.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SEND",
        "disableWhenOffline": true,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "cancelorderactivityreview",
        "iconurl": "assets/imgs/cancel_review.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CANCEL",
        "disableWhenOffline": true,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"
      },
      {
        "id": "reopenorderactivity",
        "iconurl": "assets/imgs/action_button_reopen.svg",
        "isactive": true,
        "isvisible": false,
        "name": "OM_RE_OPEN",
        "disableWhenOffline": true,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "fulfillorderactivity",
        "iconurl": "assets/imgs/action_button_complete.svg",
        "isactive": true,
        "isvisible": false,
        "name": "OM_FULFILL",
        "disableWhenOffline": true,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "confirmopportunity",
        "iconurl": "assets/imgs/action_button_confirmchanges.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CONFIRM",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 5
      },
      {
        "id": "assignOwneropportunity",
        "iconurl": "assets/imgs/assign_owner.svg",
        "isactive": true,
        "isvisible": false,
        "name": "ASSIGN",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": -1
      },
      {
        "id": "closeaswon",
        "iconurl": "assets/imgs/Won-dark.svg",
        "isactive": true,
        "isvisible": false,
        "name": "WON",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 4
      },
      {
        "id": "closeaslost",
        "iconurl": "assets/imgs/Lost-dark.svg",
        "isactive": true,
        "isvisible": false,
        "name": "OPPORTUNITY_STATE_LOST",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 3
      },
      {
        "id": "closeasusercanceled",
        "iconurl": "assets/imgs/action_button_cancel_opportunity.svg",
        "isactive": true,
        "isvisible": false,
        "name": "DISCONTINUE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "opportunityscrap",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "marketingEmailLike",
        "iconurl": "assets/imgs/xp_thumbs_up.svg",
        "isactive": true,
        "isvisible": false,
        "name": "XPERIENCES_MARKETING_EMAIL_LIKE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"

      },
      {
        "id": "marketingEmailLikeActive",
        "iconurl": "assets/imgs/xp_thumbs_up_active.svg",
        "isactive": true,
        "isvisible": false,
        "name": "XPERIENCES_MARKETING_EMAIL_LIKE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"

      },
      {
        "id": "marketingEmailDislike",
        "iconurl": "assets/imgs/xp_thumbs_down.svg",
        "isactive": true,
        "isvisible": false,
        "name": "marketing email like",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"
      },
      {
        "id": "marketingEmailDisllikeActive",
        "iconurl": "assets/imgs/xp_thumbs_down_active.svg",
        "isactive": true,
        "isvisible": false,
        "name": "marketing email like",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"
      },
      {
        "id": "flipToInquiryDetails",
        "iconurl": "assets/imgs/action_button_preview.svg",
        "isactive": true,
        "isvisible": false,
        "name": "FLIP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": ''
      },
      {
        "id": "flipToInquiryAcknowledgement",
        "iconurl": "assets/imgs/capture_sign.svg",
        "isactive": true,
        "isvisible": false,
        "name": this.authService.hasFeatureAction(FeatureActionsMap.CASE_SIGNATURE) ? "SIGNATURE" : "SHOW",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "scrapMarketScan",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "disableWhenOffline": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "saveMarketScan",
        "iconurl": "assets/imgs/scheduling_save_update_icon.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SAVE",
        "isBadge": false,
        "disableWhenOffline": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "updateMarketScan",
        "iconurl": "assets/imgs/scheduling_save_update_icon.svg",
        "isactive": true,
        "isvisible": false,
        "name": "UPDATE",
        "isBadge": false,
        "disableWhenOffline": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "editMarketScan",
        "iconurl": "assets/imgs/scheduling_edit_icon.svg",
        "isactive": true,
        "isvisible": false,
        "name": "EDIT",
        "isBadge": false,
        "disableWhenOffline": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "pdfPreview",
        "iconurl": "assets/imgs/action_button_pdf_viewer.svg",
        "isactive": true,
        "isvisible": false,
        "name": "PDF",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "QRcodeScan",
        "iconurl": "assets/imgs/qr_code_white.svg",
        "isactive": true,
        "isvisible": false,
        "name": "Scan QR",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "signatureEvents",
        "iconurl": "assets/imgs/action_button_signature.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SIGNATURE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "attendeeEvents",
        "iconurl": "assets/imgs/Attendance.svg",
        "isactive": true,
        "isvisible": false,
        "name": "Attendance",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "checkInEvents",
        "iconurl": "assets/imgs/check_in.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CHECK_IN",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "event_tool_activity_scrap",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 3
      },
      {
        "id": "event_tool_activity_mark_complete",
        "iconurl": "assets/imgs/action_button_complete.svg",
        "isactive": true,
        "isvisible": false,
        "name": "COMPLETE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 4
      },
      {
        "id": "event_tool_send_for_approval",
        "iconurl": "assets/imgs/action_button_send.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SEND",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 5
      },
      {
        "id": "flipToEventDetails",
        "iconurl": "assets/imgs/action_button_preview.svg",
        "isactive": true,
        "isvisible": false,
        "name": "FLIP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"
      },
      {
        "id": "exportPDF",
        "iconurl": "assets/imgs/action_button_file_export.svg",
        "isactive": true,
        "isvisible": false,
        "name": "Export",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right",
        "displayOrder": 2
      },
      // {
      //   "id": "uploadPDF",
      //   "iconurl": "assets/imgs/omni_upload_grey.svg",
      //   "isactive": true,
      //   "isvisible": false,
      //   "name": "Upload",
      //   "isBadge": false,
      //   "badgeValue": '',
      //   "eventhandler": '',
      //   "align": "right",
      //   "displayOrder": 3
      // },
      {
        "id": "downloadPDF",
        "iconurl": "assets/imgs/omni_download_grey.svg",
        "isactive": true,
        "isvisible": false,
        "name": "Download",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right",
        "displayOrder": 2
      },
      {
        "id": "sendEmailContract",
        "iconurl": "assets/imgs/action_button_send.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SEND EMAIL",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "scrapSurgeryOrderActivity",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "scraporcancelSurgeryOrderActivity",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "DELETE_OR_CANCEL_PROCEDURE_LOG_HEADER",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "scrapProcedureTrackerActivity",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right",
        "displayOrder": 1
      },
      {
        "id": "markCompleteSurgeryOrder",
        "iconurl": "assets/imgs/action_button_complete.svg",
        "isactive": true,
        "isvisible": false,
        "name": "COMPLETE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "markCompleteProcedureTrackerActivity",
        "iconurl": "assets/imgs/action_button_complete.svg",
        "isactive": true,
        "isvisible": false,
        "name": "COMPLETE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right",
        "displayOrder": 2
      },
      {
        "id": "reopensurgeryactivity",
        "iconurl": "assets/imgs/activity_reopen.svg",
        "isactive": true,
        "isvisible": false,
        "name": "REOPEN",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": -1
      },
      {
        "id": "assignOwnersurgeryactivity",
        "iconurl": "assets/imgs/assign_owner.svg",
        "isactive": true,
        "isvisible": false,
        "name": "ASSIGN",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": -2
      },
      {
        "id": "reopenproceduretrackeractivity",
        "iconurl": "assets/imgs/activity_reopen.svg",
        "isactive": true,
        "isvisible": false,
        "name": "REOPEN",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "left",
        "displayOrder": -1 //to order the icon to the left
      },
      {
        "id": "shareMessage",
        "iconurl": "assets/imgs/share_btn.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SHARE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 5
      },
      {
        "badgeValue": "",
        "eventhandler": "",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "id": "scrapAdjustment",
        "isactive": true,
        "isBadge": false,
        "isvisible": false,
        "featureActions": [FeatureActionsMap.ALLOCATION_ADJUSTMENT],
        "name": "SCRAP",
        "displayOrder": 1
      },
      {
        "badgeValue": "",
        "eventhandler": "",
        "iconurl": "assets/imgs/action_button_send.svg",
        "id": "adjustShipment",
        "isactive": true,
        "isBadge": false,
        "isvisible": false,
        "featureActions": [FeatureActionsMap.ALLOCATION_ADJUSTMENT],
        "name": "SEND_ADJUST_SHIPMENT",
        "displayOrder": 2
      },
      {
        "badgeValue": "",
        "disableWhenOffline": true,
        "eventhandler": "",
        "iconurl": "assets/imgs/action_button_send.svg",
        "id": "transferallocation",
        "isactive": true,
        "isBadge": false,
        "isvisible": false,
        "name": "TRANSFER"
      },
      {
        "badgeValue": "",
        "eventhandler": "",
        "iconurl": "assets/imgs/scheduling_save_update_icon.svg",
        "id": "saveAllocationAdjustment",
        "isactive": true,
        "isBadge": false,
        "isvisible": false,
        "featureActions": [FeatureActionsMap.ALLOCATION_ADJUSTMENT_AUTO_APPROVAL],
        "name": "SAVE",
        "displayOrder": 2
      },
      {
        "badgeValue": "",
        "eventhandler": "",
        "iconurl": "assets/imgs/action_button_reject.svg",
        "id": "notApproveAdjustment",
        "featureActions": [FeatureActionsMap.ALLOCATION_ADJUSTMENT_TEAM_REQUEST],
        "isactive": true,
        "isBadge": false,
        "isvisible": false,
        "name": "NOT_APPROVE",
        "displayOrder": 1
      },
      {
        "badgeValue": "",
        "eventhandler": "",
        "iconurl": "assets/imgs/action_button_approve.svg",
        "id": "approveAdjustment",
        "featureActions": [FeatureActionsMap.ALLOCATION_ADJUSTMENT_TEAM_REQUEST],
        "isactive": true,
        "isBadge": false,
        "isvisible": false,
        "name": "APPROVE",
        "displayOrder": 2
      },
      {
        "eventhandler": "",
        "iconurl": "assets/imgs/select_template.svg",
        "id": "generalTemplate",
        "isactive": true,
        "isBadge": false,
        "isvisible": false,
        "name": "TEMPLATE",
        "displayOrder": 1
      },
      {
        "badgeValue": "",
        "eventhandler": "",
        "iconurl": "assets/imgs/scheduling_save_update_icon.svg",
        "id": "generalSave",
        "isactive": true,
        "isBadge": false,
        "isvisible": false,
        "name": "SAVE",
        "displayOrder": 2
      },
      {
        "badgeValue": "",
        "eventhandler": "",
        "iconurl": "assets/imgs/scheduling_save_update_icon.svg",
        "id": "generalUpdate",
        "isactive": true,
        "isBadge": false,
        "isvisible": false,
        "name": "UPDATE",
        "displayOrder": 2
      },
      {
        "badgeValue": "",
        "eventhandler": "",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "id": "generalCancel",
        "isactive": true,
        "isBadge": false,
        "isvisible": false,
        "name": "CANCEL",
        "displayOrder": 2,
      },
      {
        "badgeValue": "",
        "eventhandler": "",
        "iconurl": "assets/imgs/action_button_send.svg",
        "id": "generalSend",
        "isactive": true,
        "isBadge": false,
        "isvisible": false,
        "name": "SEND",
        "displayOrder": 3
      },
      {
        "id": "emailTemplateFlipPreview",
        "iconurl": "assets/imgs/action_button_preview.svg",
        "isactive": true,
        "isvisible": false,
        "name": "FLIP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"
      },
      {
        "id": "emailTemplateModalPreview",
        "iconurl": "assets/imgs/email_preview.svg",
        "isactive": true,
        "isvisible": false,
        "name": "PREVIEW",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right"
      },
      {
        "align": "right",
        "badgeValue": "",
        "disableWhenOffline": true,
        "eventhandler": "",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "id": "cancelassetdeployment",
        "isactive": true,
        "isBadge": false,
        "isvisible": false,
        "name": "ASSET_CANCEL_DEPLOYMENT",
        "displayOrder": 0
      },
      {
        "align": "right",
        "badgeValue": "",
        "disableWhenOffline": true,
        "eventhandler": "",
        "iconurl": "assets/imgs/action_button_preview.svg",
        "id": "transferasset",
        "isactive": true,
        "isBadge": false,
        "isvisible": false,
        "name": "TRANSFER",
        "displayOrder": 1
      },
      {
        "align": "right",
        "badgeValue": "",
        "disableWhenOffline": true,
        "eventhandler": "",
        "iconurl": "assets/imgs/action_button_confirmchanges.svg",
        "id": "confirmtransfer",
        "isactive": true,
        "isBadge": false,
        "isvisible": false,
        "name": "CONFIRM",
        "displayOrder": 2
      },
      {
        "id": "transfer-cancel",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "Scrap",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right",
        "displayOrder": 0
      },
      {
        "id": "transfer-reject",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "Reject",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right",
        "displayOrder": 1
      },
      {
        "id": "transfer-acknowledge",
        "iconurl": "assets/imgs/action_button_complete.svg",
        "isactive": true,
        "isvisible": false,
        "name": "ACKNOWLEDGE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right",
        "displayOrder": 2
      },
      {
        "id": "transfer-complete",
        "iconurl": "assets/imgs/action_button_complete.svg",
        "isactive": true,
        "isvisible": false,
        "name": "COMPLETE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "left",
        "variableVisibility": false,
        "displayOrder": 2
      },
      {
        "id": "saveMultipleAssess",
        "iconurl": "assets/imgs/scheduling_save_update_icon.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SAVE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "savecontactmedicalinsight",
        "iconurl": "assets/imgs/scheduling_save_update_icon.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SAVE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "align": "right",
      },
      {
        "id": "pharmacovigilance-adverse-events",
        "iconurl": "assets/imgs/action_button_adverse_events.svg",
        "isactive": true,
        "isvisible": false,
        "name": "ADVERSE_EVENTS",
        "isBadge": false,
        "displayOrder": 0
      },
      {
        "id": "pharmacovigilance-special-situations",
        "iconurl": "assets/imgs/action_button_special_situations.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SPECIAL_SITUATIONS",
        "isBadge": false,
        "displayOrder": 1
      },
      {
        "id": "pharmacovigilance-contacts-us",
        "iconurl": "assets/imgs/action_button_contact_us.svg",
        "isactive": true,
        "isvisible": false,
        "name": "CONTACT_US",
        "isBadge": false,
        "displayOrder": 2
      },
      {
        "id": "savecontactkolstatus",
        "iconurl": "assets/imgs/action_button_send.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SUBMIT",
        "disableWhenOffline": true,
        "isBadge": false,
        "badgeValue": "",
        "eventhandler": "",
        "displayOrder": 1
      },
      {
        "id": "rejectcontactkolstatus",
        "iconurl": "assets/imgs/action_button_reject.svg",
        "isactive": true,
        "isvisible": false,
        "name": "REJECT",
        "disableWhenOffline": true,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "approvecontactkolstatus",
        "iconurl": "assets/imgs/action_button_approve.svg",
        "isactive": true,
        "isvisible": false,
        "name": "APPROVE",
        "disableWhenOffline": true,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "badgeValue": "",
        "eventhandler": "",
        "iconurl": "assets/imgs/scheduling_save_update_icon.svg",
        "id": "saveterritory",
        "isactive": true,
        "isBadge": false,
        "isvisible": false,
        "name": "SAVE",
        "displayOrder": 1
      },
      {
        "id": "scrapterritory",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2,
      },
      {
        "id": "sendTerritoryForApproval",
        "iconurl": "assets/imgs/action_button_send.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SEND_FOR_APPROVAL",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 3
      },
      {
        "id": "rejectManageList",
        "iconurl": "assets/imgs/action_button_reject.svg",
        "isactive": true,
        "isvisible": false,
        "name": "REJECT",
        "disableWhenOffline": true,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "approveManageList",
        "iconurl": "assets/imgs/action_button_approve.svg",
        "isactive": true,
        "isvisible": false,
        "name": "APPROVE",
        "disableWhenOffline": true,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "hopQrCode",
        "iconurl": "assets/imgs/footer_qr_code.svg",
        "isactive": true,
        "isvisible": false,
        "name": "HOP_QR_CODE",
        "disableWhenOffline": true,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1,
        "detail": true
      },
      {
        "id": "startSurvey",
        "iconurl": "assets/imgs/survey_black.svg",
        "isactive": true,
        "isvisible": false,
        "name": "START_SURVEY",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "scrapSurvey",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "saveSurvey",
        "iconurl": "assets/imgs/scheduling_save_update_icon.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SAVE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "submitSurvey",
        "iconurl": "assets/imgs/submit_black.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SUBMIT",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 3
      },
      {
        "id": "rejectDCRRequest",
        "iconurl": "assets/imgs/action_button_reject.svg",
        "isactive": true,
        "isvisible": false,
        "name": "REJECT",
        "disableWhenOffline": true,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "approveDCRRequest",
        "iconurl": "assets/imgs/action_button_approve.svg",
        "isactive": true,
        "isvisible": false,
        "name": "APPROVE",
        "disableWhenOffline": true,
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "completeStoreCheck",
        "iconurl": "assets/imgs/action_button_complete.svg",
        "isactive": true,
        "isvisible": false,
        "name": "COMPLETE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 50,
        detail: true
      },
      {
        "id": "scrapStoreCheck",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 50,
        detail: true
      },
      {
        "id": "scrapsetbooking",
        "iconurl": "assets/imgs/action_button_cancel.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SCRAP",
        "isBadge": false,
        "disableWhenOffline": true,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 51,
        detail: true
      },
      {
        "id": "editsetbooking",
        "iconurl": "assets/imgs/scheduling_edit_icon.svg",
        "isactive": true,
        "isvisible": false,
        "name": "EDIT",
        "isBadge": false,
        "disableWhenOffline": true,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 52
      },
      {
        "id": "shareContent",
        "iconurl": "assets/imgs/share_dark.svg",
        "isactive": true,
        "isvisible": false,
        "name": "SHARE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 1
      },
      {
        "id": "remoteMeeting",
        "iconurl": "assets/imgs/remote.svg",
        "isactive": true,
        "isvisible": false,
        "name": "REMOTE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 2
      },
      {
        "id": "completeShortCall",
        "iconurl": "assets/imgs/action_button_complete.svg",
        "isactive": true,
        "isvisible": false,
        "name": "COMPLETE",
        "isBadge": false,
        "badgeValue": '',
        "eventhandler": '',
        "displayOrder": 9
      }
      
    ];
}
}

export interface FooterButtonOptions {
  isactive?: boolean,
  isvisible?: boolean,
  name?: string,
  disableWhenOffline?: boolean,
  isBadge?: boolean,
  badgeValue?: string,
  eventhandler?: any,
  align?: 'left' | 'center' | 'right',
  availableOnFullScreen?: boolean,
  variableVisibility?: boolean,
  displayOrder?: number,
};
