import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'ind-gauge-chart',
  templateUrl: './ind-gauge-chart.html',
  styleUrls: ['./ind-gauge-chart.scss']
})
export class IndGaugeChartComponent implements OnInit, OnChanges {
  @Input() percentage: number = 0;
  @Input() color: string = '#007AFF';
  @Input() backgroundColor: string = '#F2F2F2';
  @Input() strokeWidth: number = 9;
  @Input() diameter: number = 120;

  center: number;
  radius: number;
  circumference: number;
  dashoffset: number;

  ngOnInit(): void {
    this.updateChart();
  }

  ngOnChanges(): void {
    this.updateChart();
  }

  updateChart(): void {
    if (isNaN(this.percentage)) {
      console.error('ind-gauge-chart: percentage NaN: ', this.percentage);
      return;
    }
    this.center = this.diameter / 2;
    this.radius = this.center - this.strokeWidth / 2;
    this.circumference = 2 * Math.PI * this.radius;
    this.dashoffset = this.circumference;

    const boundPercentage = Math.min(Math.max(this.percentage, 0), 100);
    const percentageDecimal = boundPercentage / 100;
    this.dashoffset = this.circumference * (1 - percentageDecimal);
  }
}
