import { FilterConditionOperators } from '../../../enums/fetch-xml/fetch-xml.enum';
import { AttributeXMLTag, FilterConditionXMLTag, FilterXMLTag, LinkedEntityXMLTag, OrderXMLTag } from '../../../interfaces/fetch-xml/fetch-xml.interface';

export const AccountGlobalSearchEntityLevelCharacterSearchAttributeName: string = 'name';
export const AccountGlobalSearchAttributes: AttributeXMLTag[] = [
  { name: 'accountid' }, { name: 'name' }, { name: 'createdon' },
  { name: 'modifiedon' }
];

export const AccountGlobalSearchPositionLink: LinkedEntityXMLTag = {
  name: 'indskr_customerposition',
  from: 'indskr_customerid',
  to: 'accountid',
  linkType: 'outer',
  alias: 'cp',
  linkedEntities: [
    {
      name: 'position',
      from: 'positionid',
      to: 'indskr_positionid',
      linkType: 'outer',
      alias: 'position',
      filters: [
        {
          type: 'or',
          conditions: [
            {
              attribute: 'positionid',
              operator: FilterConditionOperators.in,
              valueList: []
            },
            {
              attribute: 'parentpositionid',
              operator: FilterConditionOperators.in,
              valueList: []
            },
          ]
        }
      ]
    }
  ]
};

export const AccountGlobalSearchDefaultFilterConditions: FilterConditionXMLTag[] = [
  { attribute: 'statecode', operator: FilterConditionOperators.eq, value: '0' },
];

export const AccountGlobalSearchDefaultPositionFilterCondition: FilterConditionXMLTag = { 
  entityname: 'position',
  attribute: 'positionid',
  operator: FilterConditionOperators.null
};

export const AccountGlobalSearchDefaultFilter: FilterXMLTag = {
  conditions: AccountGlobalSearchDefaultFilterConditions,
  nestedFilters: [],
}

export const AccountGlobalSearchDefaultOrder: OrderXMLTag = {
  attribute: 'name',
  descending: false
}

export const FETCH_ACCOUNTS_LINK_ENTITES_BY_ACCOUNTID = [
  {
      entityName: "indskr_accountcontactaffiliation",
      fetchXML: `
          <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
            <entity name="account">
              <attribute name="accountid"/>
              <link-entity name="indskr_accountcontactaffiliation" from="indskr_accountid" to="accountid" link-type="inner" alias="indskr_accountcontactaffiliation">
                <attribute name="createdon"/>
                <attribute name="statecode"/>
                <attribute name="statuscode" />
                <attribute name="indskr_startdate"/>
                <attribute name="indskr_enddate"/>
                <attribute name="indskr_comments"/>
                <attribute name="indskr_contactid"/>
                <attribute name="indskr_accountid"/>
                <attribute name="indskr_contactrole"/>
                <attribute name="omnione_role"/>
                <attribute name="createdby"/>
                <attribute name="modifiedon"/>
                <attribute name="modifiedby"/>
                <attribute name="indskr_isprimaryaccount"/>
                <attribute name="indskr_accountcontactaffiliationid"/>
                {AccountFilter}
                <link-entity name='contact' from='contactid' to='indskr_contactid' link-type='inner' alias='contactaffiliation'>
                  <link-entity name='indskr_customerposition' from='indskr_customerid' to='contactid' link-type='inner' alias='cp'>
                    <attribute name="indskr_approvalstatus"/>
                    <attribute name="indskr_positionid"/>
                    <attribute name="statecode"/>
                    <link-entity name='contact' from='contactid' to='indskr_customerid' link-type='inner' alias='cc'></link-entity>
                  </link-entity>
                </link-entity>
              </link-entity>
            </entity>
          </fetch>`,
  },
]