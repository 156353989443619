


export class CaseType {

    public id;
    public name;
    public categoryTypes: CategoryType[];
    public surveyTemplate: string;
    public users: CaseAssignee[];
    public teams: CaseAssignee[];
  

    constructor(raw: RawCaseType) {
        this.id = raw.indskr_expertcategoriesid;
        this.name = raw.indskr_name;
        this.categoryTypes = raw.categoryTypes.map(e => new CategoryType(e));
        this.surveyTemplate = raw.inquiryTemplate;
        this.users = raw.users ?? [];
        this.teams = raw.teams ?? [];
    }
}

export enum CaseCategory {
  MEDICAL = 548910000,
  COMMERCIAL = 548910001

}

export class CategoryType {
    name;
    id;
    indskr_productselection;
    indskr_customermandatory;
    indskr_makeaccountmandatory;
    constructor(raw: RawCategoryType) {
        this.name = raw.indskr_name;
        this.id = raw.indskr_expertcategorytypeid;
        this.indskr_productselection = raw["indskr_productselection"];
        this.indskr_customermandatory = raw["indskr_customermandatory"];
        this.indskr_makeaccountmandatory = raw["indskr_makeaccountmandatory"];
    }
}

export class CaseResponsePreference {

    public id;
    public name;

    constructor(raw: RawCaseResponse) {
        this.id = raw.value;
        this.name = raw.response;
    }
}

export class TherapeuticArea {

    public therapeuticareaid;
    public therapeuticarea_name;

    constructor(raw: RawTherapeuticArea) {
        this.therapeuticareaid = raw.therapeuticareaid;
        this.therapeuticarea_name = raw.therapeuticarea_name;
    }
}

export class Timeline {
    public status;
    public scheduledStart: Date;
    public description: any;
    public currentStatus: string;
    public partyId: string;
    public phoneNumber: string;
    public ownerId: string;
    public contactName: string;
    public activityId: string;
    incidentid: string;
    indskr_ownerfullname: string;
    activitytypecode: string;
    subject: string;
    location: string;
    readDate: Date;
    meetingLocation: any;
    emailSentOn: Date;
    readOn: Date;
    contactNames?: string[];

    constructor(raw: RawTimeline) {
      this.status = raw['activityStatus'] ? raw['activityStatus'] : '';

      try {
        this.scheduledStart = new Date(Number(raw['scheduledstart']));
        this.emailSentOn = raw['emailSentOn'] ? new Date(Number(raw['emailSentOn'])) : null;
        this.readOn = raw['readOn'] ? new Date(Number(raw['readOn'])) : null;
        if (this.emailSentOn) {
          this.scheduledStart = this.emailSentOn;
        }
      } catch (error) {
        console.log('error converting starttime for inquiry timeline')
      }

      this.description = raw['activityDescription'] ? raw['activityDescription'] : '';

      this.incidentid = raw['incidentid'];

      this.indskr_ownerfullname = raw['indskr_ownerfullname'];
      this.activitytypecode = raw['activitytypecode'];
      this.subject = raw['subject'] ? raw['subject'] : '';
      this.location = raw['meetingLocation'] ? String(raw['meetingLocation']) : 'No Location';
      this.currentStatus = raw['currentStatus'] ? raw['currentStatus'] : '';
      this.partyId = raw['partyId'] ? raw['partyId'] : '';
      this.phoneNumber = raw['phoneNumber'] ? raw['phoneNumber'] : '';
      this.ownerId = raw['ownerId'] ? raw['ownerId'] : '';
      this.contactName = raw['contactName'] ? raw['contactName'] : '';
      this.activityId = raw['activityId'] ? raw['activityId'] : '';
      this.contactNames = [];
    }
}

export interface RawCaseResponse {
    value: number;
    response: string;
}

export interface RawCaseType {
    '@odata.etag': string;
    indskr_expertcategoriesid: string;
    indskr_name: string;
    categoryTypes: RawCategoryType[];
    inquiryTemplate: string;
    users: CaseAssignee[];
    teams: CaseAssignee[];
}

export interface RawCategoryType {
    '@odata.etag': string;
    indskr_expertcategorytypeid: string;
    indskr_name: string;
    indskr_productselection: any;
    indskr_customermandatory: any;
    indskr_makeaccountmandatory: any;
}

export interface RawTherapeuticArea {
    therapeuticareaid: string;
    therapeuticarea_name: string;
}

export interface CaseAssignee {
  indskr_expertcategoriesid: string;
  id: string;
  indskr_name: string;
}

export interface RawTimeline {
    activityDescription?: string;
    scheduledstart: string;
    activityStatus?: string;
    incidentid?: string;
    indskr_ownerfullname?: string;
    activitytypecode?: string;
    subject?: string;
    location?:any;
    emailSentOn?: string;
    readOn?:string;
    currentStatus?: string;
    partyId?: string;
    phoneNumber?: string;
    ownerId?: string;
    contactName?: string;
    activityId?: string;
    contactNames?: string[];
}

export enum CaseAssigneeType {
  USER = 'user',
  TEAM = 'team',
}

