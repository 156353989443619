<ion-header>
    <ind-page-title [viewData]="templatePreviewPageTitle" (onControlClick)='onPageTitleControlClick($event)'>
    </ind-page-title>
</ion-header>
<ion-content class="ion-no-padding">
    <ion-grid>
        <ion-row justify-content-center>
            <ion-col *ngIf="!deviceService.isMobileDevice" size="2"></ion-col>
            <ion-col [ngClass]="{'center-screen': !deviceService.isMobileDevice }">
                <ng-container *ngIf="!preview || $any(preview).length == 0; else showPreview">
                    <div class="no-data-message">{{ 'NO_CONTENT' | translate}}</div>
                </ng-container>
                <ng-template #showPreview>
                    <ind-section-header [viewData]='{id:"template-preview", title: translate.instant("PREVIEW_CAP")}'>
                    </ind-section-header>
                    <ng-container>
                        <div [innerHTML]="preview" class="emailTempContainer" (click)="openUrl($event)"></div>
                    </ng-container>
                </ng-template>
            </ion-col>
            <ion-col *ngIf="!deviceService.isMobileDevice" size="2"></ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<!-- <ion-footer> -->
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'" [selectedView]="'email-personalization'"></footer-toolbar>
<!-- </ion-footer> -->