import { Injectable } from '@angular/core';
import { AuthenticationService } from '@omni/services/authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { DB_ALLDOCS_QUERY_OPTIONS, DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS, PREFIX_SEARCH_ENDKEY_UNICODE } from '@omni/config/pouch-db.config';
import { DeltaService, EntityNames, EntitySyncInfo } from '@omni/data-services/delta/delta.service';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from '@omni/services/disk/disk.service';
import { CustomerAssessDataService } from '@omni/data-services/customer-assess/customer-assess.data.service';
import {
  ALL_CUSTOMER_ASSESS_FETCHXML, ASSESSED_ACCOUNTS_FETCHXML, ASSESSED_CONTACTS_FETCHXML, ASSESSMENT_TEMPLATE_STATUS_CONDITION,
  ASSESS_TEAMVIEW_ROLES, ASSESS_TEMPLATE_FETCHXML, ASSESS_TEAMVIEW_TEMPLATES_FETCHXML, CUSTOMER_ASSESS_FETCHXML, CUSTOMER_ASSESS_FOR_SEARCH_FETCHXML,
  POSITIONGROUP_PRODUCT_FETCHXML, POSITION_PRODUCT_FETCHXML, PROCEDURES_FOR_SPECIALTY, CUSTOMER_ASSESS_TEAMVIEW_FETCHXML,
  CUSTOMER_SURVEY_FETCHXML, ALL_CUSTOMER_SURVEY_FETCHXML, USER_SURVEY_FETCHXML, ALL_CUSTOMER_SURVEY_TEMPLATE_FETCHXML, CUSTOMER_SURVEY_TEMPLATE_FETCHXML_ROLE,CUSTOMER_SURVEY_TEMPLATE_FETCHXML_POSITION, CUSTOMER_SURVEY_DETAILS_FETCHXML, ACCOUNT_ASSESS_FOR_SEARCH_FETCHXML, SURVEY_FOR_TIMELINE_FETCHXML, SURVEYED_ENTITIES_FOR_ONETIME_SURVEY_FETCHXML, NUMBER_OF_SURVEYED_FETCHXML, CUSTOMER_ASSESS_ONLINE_SEARCH_FETCHXML, ACCOUNT_ASSESS_ONLINE_SEARCH_FETCHXML,
  POSITIONGROUP_PRODUCT_FETCHXML_DELTA,
  POSITIONGROUP_PRODUCT_FETCHXML_REMOVE_TRACK_CHANGES
} from '@omni/config/dynamic-forms/assess-template-fetchxml';
import { differenceInHours, format } from 'date-fns';
import { AssessmentAttributeType, AssessmentTemplate, AttributeSection, Control, EntityOptions, InternalSurveyStatus, SurveyCategory, SurveyFrequency, SurveyStatus, SurveyType, TemplateType } from '@omni/classes/customer-assessment/assessment-template.class';
import { FormFieldType } from '@omni/models/indFormFieldDataModel';
import _ from 'lodash';
import { AssessTeamviewRefData, CustomerAssessment, InternalSurvey } from '@omni/classes/customer-assessment/customer-assessment.class';
import { Guid } from 'typescript-guid';
import { DeviceService } from '../device/device.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../alert/alert.service';
import { EventsService } from '../events/events.service';
import { CaseManagementService } from '../case-management/case-management.service';
import { TherapeuticArea } from '@omni/classes/therapeutic-area/therapeutic-area.class';
import { BrandOfflineService } from '../brand/brand.service';
import { TherapeuticAreaDataService } from '@omni/data-services/therapeutic-area/therapeutic-area.data.service';
import { Brand } from '@omni/classes/brand/brand.class';
import { SearchConfigService } from '@omni/services/search/search-config.service';
import { LocalizationService } from '../localization/localization.service';
import { Contact, Specialty } from '@omni/classes/contact/contact.class';
import { Endpoints } from 'src/config/endpoints.config';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '../date-time-formats/date-time-formats.service';
import { ContactOfflineService } from '../contact/contact.service';
import { BehaviorSubject } from 'rxjs';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { AccountOfflineService } from '../account/account.offline.service';
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { TrackAction } from '@omni/utility/common-enums';


@Injectable({
  providedIn: 'root'
})
export class CustomerAssessService {

  public assessmentTemplates: AssessmentTemplate[] = [];
  public surveytemplates: AssessmentTemplate[] = [];
  private isInitialMappingDone: boolean = false;
  private isSurveyInitialMappingDone: boolean = false;
  public isAssessmentDirty: boolean = false;
  public tAProductsMap: TherapeuticArea[] = [];
  public positionProductIdMap: [] = [];
  public positionGroupProductIdMap: [] = [];
  public assessedContacts: any[] = [];
  public assessedAccounts: any[] = [];
  public products: Brand[] = [];
  private triggerFullSyncSearchData: boolean = false;
  public isInitialMappingDoneForSearch: boolean = false;
  public isInitialMappingDoneForSearchAccount: boolean = false;
  public specialtyProceduresMap: Map<String, Brand[]> = new Map<String, Brand[]>();
  public assessRawData: CustomerAssessment[] = [];
  public isAssessmentLoading: boolean = false;
  public allContactAssessments: CustomerAssessment[] = [];
  public allAccountAssessments: CustomerAssessment[] = [];
  //Teamview
  public positionsTeamview: AssessTeamviewRefData[] = [];
  public positionGroupsTeamview: AssessTeamviewRefData[] = [];
  public productsTeamview: AssessTeamviewRefData[] = [];
  public assessmentTemplatesTeamView: AssessmentTemplate[] = [];
  public assessmentTeamViewFielterMenuData = [];
  //Survey
  public allContactSurveys = [];
  public allInternalSurveys = [];
  public allAccountSurveys = [];

  public allContactSurveysByAssessment: any[] = [];
  public allContactSurveysForAppt = new BehaviorSubject<CustomerAssessment>(null);
  public allContactSurveysForApptObs$ = this.allContactSurveysForAppt.asObservable();
  public contactSurveysForTimeline: any[] = [];
  public accountSurveysForTimeline: any[] = [];
  public surveyedContactsForOnetimeSurvey: any[] = [];
  public surveyedAccountsForOnetimeSurvey: any[] = [];

  // public surveyedContactsInfo = new BehaviorSubject<any[]>([]);
  // public surveyedContactsInfoObs$ = this.surveyedContactsInfo.asObservable();
  public numOfSurveyedInfo = new BehaviorSubject<any[]>([]);
  public numOfSurveyedInfo$ = this.numOfSurveyedInfo.asObservable();

  // public surveyedAccountsInfo: any[] = [];
  public weekDay: Array<string> = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
  public isSurveyFormDirty: boolean = false;
  public captureHCPforAccountSurvey: boolean = false;
  public numOfSurveyed: any[] = [];

  constructor(
    private authService: AuthenticationService,
    private disk: DiskService,
    private customerAssessDataService: CustomerAssessDataService,
    private deltaService: DeltaService,
    private device: DeviceService,
    private translate: TranslateService,
    private alertService: AlertService,
    private events: EventsService,
    private caseManagementService: CaseManagementService,
    private readonly therapeuticService: TherapeuticAreaDataService,
    private searchConfigService: SearchConfigService,
    private localizationService: LocalizationService,
    public datePipe: DatePipe,
    public dateTimeFormatsService: DateTimeFormatsService,
    public contactService: ContactOfflineService,
    private dynamics: DynamicsClientService,
    private accountService: AccountOfflineService,
    private http: HttpClient,
    private loadingCtrl: LoadingController,
  ) {
    this.events.observe("sync:completed").subscribe(() => {
      this.prepareTherapeuticAreaProductsMap();
      this.filterOnlyLoggedInUsersBrandProducts();
      this.checkBUConfig();
      if (!this.isInitialMappingDoneForSearch) this.mapCustomerAssessmentForSearch(EntityOptions.CONTACT);
      if (!this.isInitialMappingDoneForSearchAccount) this.mapCustomerAssessmentForSearch(EntityOptions.ACCOUNT);
    })
  }

  private prepareTherapeuticAreaProductsMap() {
    this.tAProductsMap = [];
    this.caseManagementService.caseProductSKU.filter(brand => brand.isCompetitorProduct != "true").forEach(brand => {
      if (brand.therapeuticAreas) {
        brand.therapeuticAreas.filter(ta => this.therapeuticService.therapeuticAreas.some(therapeuticArea => ta.therapeuticareaid === therapeuticArea.therapeuticareaId)).forEach(ta => {
          if (!ta['brands'])
            ta['brands'] = [];
          const index = this.tAProductsMap.findIndex(therapeuticArea => therapeuticArea.therapeuticareaId == ta.therapeuticareaid);
          let product = {
            ID: brand.ID,
            name: brand.name,
          };
          if (index >= 0) {
            this.tAProductsMap[index]['brands'].push(product);

          }
          else {
            ta['brands'].push(product);
            const therapeuticArea: TherapeuticArea = new TherapeuticArea({
              indskr_therapeuticareaid: ta.therapeuticareaid,
              indskr_name: ta.therapeuticarea_name
            })
            therapeuticArea['brands'] = ta['brands'];
            this.tAProductsMap.push(therapeuticArea);
          }
        });
      }
    })
  }

  private filterOnlyLoggedInUsersBrandProducts() {
    const userPositionIds = this.authService.user.positions.filter(p => (p.status == 100000000 || p.status == 0)).map(posi => posi.ID);
    this.products = this.caseManagementService.caseProductSKU.filter(prod => prod.isCompetitorProduct != "true" && this.positionProductIdMap.some(posiProd => posiProd['productId'] == prod.ID && userPositionIds.includes(posiProd['positionId'])));
  }

  public async loadAssessmentTemplateFromDB() {
    //Fetch Assessment templates from db
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUST_ASSESS_TEMPLATES).then((data: AssessmentTemplate[]) => {
      if (!_.isEmpty(data)) {
        this.assessmentTemplates = data.filter(d => new Date(d.indskr_validfrom) <= new Date() && new Date(d.indskr_validto) >= new Date && d.statuscode == 2 && d.statecode == 1);
        console.log(`Assessment Templates from disk : ${this.assessmentTemplates ? this.assessmentTemplates.length : 0}`);
      }
    });
    const isTeamViewEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ASSESSMENTS_TEAM_VIEW);
    if(isTeamViewEnabled) {
      this.loadAssessmentTemplateTeamviewFromDB();
    }
  }

  public async loadSurveyTemplateFromDB() {
    //Fetch Survey templates from db
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUST_SURVEY_TEMPLATES).then((data: AssessmentTemplate[]) => {
      if (!_.isEmpty(data)) {
        this.surveytemplates = data.filter(d => d.statuscode == 2 && d.statecode == 1);
        console.log(`Survey Templates from disk : ${this.surveytemplates ? this.surveytemplates.length : 0}`);
      }
    });
  }

  public async loadAssessmentTemplateTeamviewFromDB() {
    //Fetch Assessment templates teamview from db
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUST_ASSESS_TEAMVIEW_TEMPLATES).then((data: AssessmentTemplate[]) => {
      if (!_.isEmpty(data)) {
        this.assessmentTemplatesTeamView = data.filter(d => new Date(d.indskr_validfrom) <= new Date() && new Date(d.indskr_validto) >= new Date && d.statuscode == 2 && d.statecode == 1);
        console.log(`Assessment Templates Teamview from disk : ${this.assessmentTemplatesTeamView ? this.assessmentTemplatesTeamView.length : 0}`);
      }
    }).catch(err => {
      console.error("Error occurred while loading assessment templates - Teamview...", err)
    });
  }

  public async loadPositionProductIdMap() {
    await this.disk.retrieve(DB_KEY_PREFIXES.POSITION_PRODUCT_MAP).then((data) => {
      this.positionProductIdMap = data ? data.raw : [];
    });
  }

  public async loadPositionGroupProductIdMap() {
    await this.disk.retrieve(DB_KEY_PREFIXES.POSITIONGROUP_PRODUCT_MAP).then((data) => {
      this.positionGroupProductIdMap = data ? data.raw : [];
    });
  }

  private async loadAssessedContactsFromDB() {
    await this.disk.retrieve(DB_KEY_PREFIXES.ASSESSED_CONTACTS).then((data) => {
      this.assessedContacts = data ? data.raw : [];
    });
  }

  private async loadAssessedAccountsFromDB() {
    await this.disk.retrieve(DB_KEY_PREFIXES.ASSESSED_ACCOUNTS).then((data) => {
      this.assessedAccounts = data ? data.raw : [];
    });
  }

  private async loadSpecialtyProceduresFromDB() {
    await this.disk.retrieve(DB_KEY_PREFIXES.SPECIALTY_PROCEDURES_FOR_ASSESSMENT).then((data) => {
      this.specialtyProceduresMap = data ? data.raw : new Map<String, Brand[]>();
    });
  }

  // private async loadAllContactAssesseFromDB() {
  //   try {
  //     await this.disk.retrieve(DB_KEY_PREFIXES.ALL_CONTACT_ASSESSMENT).then((data) => {
  //       this.allContactAssessments = data ? data.raw : [];
  //     });
  //   } catch(error) {
  //     console.log("Error loading all contact assess from DB", error);
  //   }
  // }

  private async loadAllAccountAssesseFromDB() {
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.ALL_ACCOUNT_ASSESSMENT).then((data) => {
        this.allAccountAssessments = data ? data.raw : [];
      });
    } catch(error) {
      console.log("Error loading all account assess from DB", error);
    }
  }

  // private async loadSurveyedContactsFromDB(hasCustomerSurveyEnabled: boolean) {
  //   if(!hasCustomerSurveyEnabled) return;
  //   try {
  //     await this.disk.retrieve(DB_KEY_PREFIXES.SURVEYED_CONTACTS).then((data) => {
  //       this.surveyedContacts = data ? data.raw : [];
  //     });
  //   } catch(error) {
  //     console.log("Error loading all contact survey from DB", error);
  //   }
  // }

  // private async loadSurveyedAccountsFromDB(hasCustomerSurveyEnabled: boolean) {
  //   if(!hasCustomerSurveyEnabled) return;
  //   await this.disk.retrieve(DB_KEY_PREFIXES.SURVEYED_ACCOUNTS).then((data) => {
  //     this.surveyedAccounts = data ? data.raw : [];
  //   });
  // }

  // private async loadAllContactSurveyFromDB(hasCustomerSurveyEnabled: boolean) {
  //   if(!hasCustomerSurveyEnabled) return;
  //   try {
  //     await this.disk.retrieve(DB_KEY_PREFIXES.ALL_CONTACT_SURVEY).then((data) => {
  //       this.allContactSurveys = data ? data.raw : [];
  //     });
  //   } catch(error) {
  //     console.log("Error loading all contact survey from DB", error);
  //   }
  // }

  private async loadAllContactSurveyFromDB(hasCustomerSurveyEnabled: boolean) {
    if(!hasCustomerSurveyEnabled) return;
    try {
      await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACT_SURVEYS).then((data:any[]) => {
        this.allContactSurveys = data && _.isArray(data) && data[0] && data[0].raw ? data[0].raw : [];
      });
    } catch(error) {
      console.log("Error loading all contact survey from DB", error);
    }
  }

  private async loadAllAccountSurveyFromDB(hasCustomerSurveyEnabled: boolean) {
    if(!hasCustomerSurveyEnabled) return;
    try {
      await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACCOUNT_SURVEYS).then((data) => {
        this.allAccountSurveys = data && _.isArray(data) && data[0] && data[0].raw ? data[0].raw : [];
      });
    } catch(error) {
      console.log("Error loading all account survey from DB", error);
    }
  }

  public getTemplatesForEntity(templateType: TemplateType, entity: EntityOptions): AssessmentTemplate[] {
    const templates = this.assessmentTemplates.filter(template => template.indskr_type == templateType && template.indskr_entityoptions == entity && new Date(template.indskr_validfrom) <= new Date() && new Date(template.indskr_validto) >= new Date);
    return templates ? templates : [];
  }

  public getSurveyTemplatesForEntity(templateType: TemplateType, entity: SurveyCategory): AssessmentTemplate[] {
    const templates = this.surveytemplates.filter(template => template.indskr_type == templateType && template.indskr_entity == entity);
    return templates ? templates : [];
  }

  public getTemplatesTeamviewForEntity(templateType: TemplateType, entity: EntityOptions): AssessmentTemplate[] {
    const templates = this.assessmentTemplatesTeamView.filter(template => template.indskr_type == templateType && template.indskr_entityoptions == entity && new Date(template.indskr_validfrom) <= new Date() && new Date(template.indskr_validto) >= new Date);
    return templates ? templates : [];
  }

  public getFormFieldType(control: Control, isFromSectionSpecific: boolean = false) {
    switch (control.Type) {
      case AssessmentAttributeType.TEXT:
      case AssessmentAttributeType.MEMO:
      case AssessmentAttributeType.NUMERIC:
      case AssessmentAttributeType.RANGE:
        return FormFieldType.INLINE_INPUT;
      case AssessmentAttributeType.FLAG:
        return isFromSectionSpecific ? FormFieldType.TOGGLE : FormFieldType.POPOVER_SELECT;
      case AssessmentAttributeType.TIMESTAMP:
      case AssessmentAttributeType.CHOICE:
        return FormFieldType.POPOVER_SELECT;
      default:
        return FormFieldType.INLINE_INPUT;
    }
  }

  public async syncAssessMasterData(forceFullSync = false, loadFromDBOnly = false) {
    const dataRangeWithFutureBoundBySixMonths = this.authService.getFromToDateRangeInUTCMiliSec(undefined);
    const startDate = format(new Date(parseInt(dataRangeWithFutureBoundBySixMonths.from)), 'YYYY-MM-DD'), endDate = format(new Date(parseInt(dataRangeWithFutureBoundBySixMonths.to)), 'YYYY-MM-DD');
    const hasCustomerSurveyEnabled: boolean = this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SURVEY);
    const hasAccountSurveyEnabled: boolean = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_SURVEY);
    const isSurveyEanbled: boolean = hasCustomerSurveyEnabled || hasAccountSurveyEnabled;
    if (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_ASSESS) || isSurveyEanbled) {
      if (loadFromDBOnly || this.device.isOffline) {
        Promise.all([
          this.loadAssessmentTemplateFromDB().then(() => {
            this.loadSpecialtyProceduresFromDB(),
            // this.loadAllContactAssesseFromDB(),
            // this.loadAllAccountAssesseFromDB(),
            this.loadAssessedContactsFromDB(),
            this.loadAssessedAccountsFromDB(),
            this.mapConfiguredSearchIndexConfig(this.assessmentTemplates);

          }),
          this.loadPositionProductIdMap(),
          this.loadPositionGroupProductIdMap(),

          this.loadSurveyTemplateFromDB().then(() => {

            Promise.all([
              this.loadAllContactSurveyFromDB(hasCustomerSurveyEnabled),
              this.loadAllAccountSurveyFromDB(hasAccountSurveyEnabled)
            ]).then(()=>{
              this.mergeSavedandSubmittedSurveysForAppt(isSurveyEanbled);
            }),
            this.loadAllInternalSurveys(hasCustomerSurveyEnabled);
            this.loadSurveyedContactsByTemplates(hasCustomerSurveyEnabled);
            this.loadSurveyedAccountsByTemplates(hasAccountSurveyEnabled);
            this.loadNumOfSurveyedFromDB();
          })
        ])
      } else {
        Promise.all([
          this.getAssessmentTemplates(forceFullSync).then(() => {
            this.syncSpecialtyProcedures();
            // this.getAllContactAssessments(forceFullSync),
            // this.getAllAccountAssessments(),
            this.syncAssessedContacts(),
            this.syncAssessedAccounts(),
            //search
            // this.getAssessmentForSearch(this.triggerFullSyncSearchData, EntityOptions.CONTACT);
            // this.getAssessmentForSearch(this.triggerFullSyncSearchData, EntityOptions.ACCOUNT);
            this.mapConfiguredSearchIndexConfig(this.assessmentTemplates);
          }),
          this.getPositionProductIdMap(),
          this.getPositionGroupProductIdMap(forceFullSync),

          this.getSurveyTemplates(forceFullSync).then(()=>{
            this.loadSurveyTemplateFromDB();
            Promise.all([
              this.getAllContactSurveys(forceFullSync, hasCustomerSurveyEnabled, dataRangeWithFutureBoundBySixMonths),
              this.getAllAccountSurveys(forceFullSync, hasAccountSurveyEnabled, dataRangeWithFutureBoundBySixMonths)
            ]).then(() => {
              this.mergeSavedandSubmittedSurveysForAppt(isSurveyEanbled);
            })
            this.getAllInternalSurveys(hasCustomerSurveyEnabled)
            this.getSurveyedContactsForOnetimeSurvey(hasCustomerSurveyEnabled);
            this.getSurveyedAccountsForOnetimeSurvey(hasAccountSurveyEnabled);
            this.getNumOfSurveyed(isSurveyEanbled);
          })
        ])
      }
      this.checkBUConfig();
    }
  }

  private async syncAssessedContacts() {
    this.assessedContacts = [];
    if (!_.isEmpty(this.assessmentTemplates)) {
      const templates = this.getTemplatesForEntity(TemplateType.ASSESSMENT, EntityOptions.CONTACT);
      if (templates.length == 1) {
        try {
          let fetchXml = ASSESSED_CONTACTS_FETCHXML;
          const positions = this.getPositionValuesForFetchXmlFilter();
          fetchXml = fetchXml.replace('{templateId}', templates[0].indskr_assessmenttemplateid).replace('{positionIds}', positions).replace('{positionIds}', positions).replace('{BUId}', this.authService.user.xBusinessUnitId);
          await this.customerAssessDataService.getAssessedContacts(fetchXml).then(async (data: []) => {
            this.assessedContacts = data;
            await this.disk.updateOrInsert(DB_KEY_PREFIXES.ASSESSED_CONTACTS, doc => ({ raw: data }))
              .catch((err) =>
                console.error("syncAssessedContacts: Error saving assessed contacts to offline db! ", err)
              );
          });
        } catch (error) {
          console.error('failed to syncAssessedContacts ', error);
        }
      }
    }
  }

  private async syncAssessedAccounts() {
    this.assessedAccounts = [];
    if (!_.isEmpty(this.assessmentTemplates)) {
      const templates = this.getTemplatesForEntity(TemplateType.ASSESSMENT, EntityOptions.ACCOUNT);
      if (templates.length == 1) {
        try {
          let fetchXml = ASSESSED_ACCOUNTS_FETCHXML;
          const positions = this.getPositionValuesForFetchXmlFilter();
          fetchXml = fetchXml.replace('{templateId}', templates[0].indskr_assessmenttemplateid).replace('{positionIds}', positions).replace('{positionIds}', positions).replace('{BUId}', this.authService.user.xBusinessUnitId);
          await this.customerAssessDataService.getAssessedAccounts(fetchXml).then(async (data: []) => {
            this.assessedAccounts = data;
            await this.disk.updateOrInsert(DB_KEY_PREFIXES.ASSESSED_ACCOUNTS, doc => ({ raw: data }))
              .catch((err) =>
                console.error("syncAssessedAccounts: Error saving assessed accounts to offline db! ", err)
              );
          });
        } catch (error) {
          console.error('failed to syncAssessedAccountss ', error);
        }
      }
    }
  }

  // private async getAllContactAssessments(forceFullSync: boolean) {
  //   this.allContactAssessments = [];
  //   if (!_.isEmpty(this.assessmentTemplates)) {
  //     const templates = this.getTemplatesForEntity(TemplateType.ASSESSMENT, EntityOptions.CONTACT);
  //     if (templates.length == 1) {
  //       try {
  //         const assessmentTemplateId = templates[0].indskr_assessmenttemplateid;
  //         await this.fetchAllContactAssessment(forceFullSync, assessmentTemplateId).then(async(data: CustomerAssessment[])=>{
  //           this.allContactAssessments = data;
  //           console.log("getAllContactAssessments: ", this.allContactAssessments.length);
  //         })
  //       } catch (error) {
  //         console.error('failed to getAllContactAssessments ', error);
  //       }
  //     }
  //   }
  // }

  // private async getAllAccountAssessments() {
  //   this.allAccountAssessments = [];
  //   if (!_.isEmpty(this.assessmentTemplates)) {
  //     const templates = this.getTemplatesForEntity(TemplateType.ASSESSMENT, EntityOptions.ACCOUNT);
  //     if (templates.length == 1) {
  //       try {
  //         const assessmentTemplateId = templates[0].indskr_assessmenttemplateid;
  //         await this.fetchAllAccountAssessment(true, assessmentTemplateId).then(async(data: CustomerAssessment[])=>{
  //           this.allAccountAssessments = data;
  //         })
  //       } catch (error) {
  //         console.error('failed to getAllAccountAssessments ', error);
  //       }
  //     }
  //   }
  // }

  private async getAllContactSurveys(forceFullSync: boolean = false, hasCustomerSurveyEnabled: boolean, dataRange: { from: string, to: string }) {
    if(!hasCustomerSurveyEnabled) return;
    this.allContactSurveysByAssessment = [];
    if (!_.isEmpty(this.surveytemplates)) {
      const templates = this.getSurveyTemplatesForEntity(TemplateType.SURVEY, SurveyCategory.CONTACT);
      if (!_.isEmpty(templates)) {
        try {
          let surveyTemplateIds =[];
          templates.forEach(template=>{
            surveyTemplateIds.push(template.indskr_assessmenttemplateid);
          });
          await this.fetchAllContactSurveysByTemplates(forceFullSync, surveyTemplateIds, dataRange).then(async(data: CustomerAssessment[])=>{
              if(!_.isEmpty(data)) {
                console.log("fetched survey data: ", data.length);
              }
              // this.updateNumOfSubmittedSurveys('contact', this.allContactSurveys.filter(survey => survey.surveyStatus != SurveyStatus.SAVED));
          });
        } catch (error) {
          console.error('failed to getAllContactAssessments ', error);
        }
      }
    }
  }

  private async getAllAccountSurveys(forceFullSync: boolean = false, hasCustomerSurveyEnabled: boolean, dataRange: { from: string, to: string }) {
    if(!hasCustomerSurveyEnabled) return;
    this.allAccountSurveys = [];
    if (!_.isEmpty(this.surveytemplates)) {
      const templates = this.getSurveyTemplatesForEntity(TemplateType.SURVEY, SurveyCategory.ACCOUNT);
      if (!_.isEmpty(templates)) {
        try {
          let surveyTemplateIds =[];
          templates.forEach(template=>{
            surveyTemplateIds.push(template.indskr_assessmenttemplateid);
          });
          await this.fetchAllAccountSurveysByTemplates(forceFullSync, surveyTemplateIds, dataRange).then(async(data: CustomerAssessment[])=>{
            if(!_.isEmpty(data)) {
              console.log("fetched account survey data: ", data.length);
            }
            // this.updateNumOfSubmittedSurveys('account', this.allAccountSurveys.filter(survey => survey.surveyStatus != SurveyStatus.SAVED))
          })
        } catch (error) {
          console.error('failed to getAllAccountAssessments ', error);
        }
      }
    }
  }

  public async getSurveysForTimeline(entityId:string, surveyFor: SurveyCategory) {
    if (!_.isEmpty(this.surveytemplates)) {
      const templates = this.getSurveyTemplatesForEntity(TemplateType.SURVEY, surveyFor);
      if (!_.isEmpty(templates)) {
        try {
          let surveyTemplateIds =[];
          templates.forEach(template=>{
            surveyTemplateIds.push(template.indskr_assessmenttemplateid);
          });
          await this.fetchSurveyForTimelinesByTemplates(surveyFor, entityId, surveyTemplateIds).then(async(data: CustomerAssessment[])=>{
              if(!_.isEmpty(data)) {
                this.saveSurveyTimelineInDB(data, entityId, surveyFor);
                console.log("fetched survey data for Timeline: ", data.length);
              }
          });
        } catch (error) {
          console.error('failed to getSurveysForTimeline: ', error);
        }
      }
    }
  }

  private async getPositionProductIdMap() {
    try {
      let fetchXml = POSITION_PRODUCT_FETCHXML;
      let positionString = this.getPositionValuesForFetchXmlFilter();
      fetchXml = fetchXml.replace('{0}', positionString).replace('{0}', positionString);
      await this.customerAssessDataService.getProducts(fetchXml).then(async (data: []) => {
        this.positionProductIdMap = data;
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.POSITION_PRODUCT_MAP, doc => ({ raw: data }))
          .catch((err) =>
            console.error("getPositionProductIdMap: Error retreiving position product ids map to offline db!", err)
          );
      });
    } catch (error) {
      console.log("Error retreiving position product ids map ", error);
    }
  }

  public async getPositionGroupProductIdMap(forceFullSync) {
    try {
      let fetchXml = POSITIONGROUP_PRODUCT_FETCHXML;
      let offlineData, lastModified;
      await this.disk.retrieve(DB_KEY_PREFIXES.POSITIONGROUP_PRODUCT_MAP).then((data) => {
        offlineData = data ? data.raw : [];
        lastModified = data && data.lastModified ? data.lastModified : null;
      });

      let trackChangesData = [];
      if(lastModified && !forceFullSync){
        let hourDifference = differenceInHours(new Date(), new Date(lastModified));
        hourDifference += 1;
        fetchXml = POSITIONGROUP_PRODUCT_FETCHXML_DELTA.replace('{DeltaSyncFilterValue}',`${hourDifference}`);
        trackChangesData  = await this.dynamics.executeFetchQuery('indskr_trackchanges', POSITIONGROUP_PRODUCT_FETCHXML_REMOVE_TRACK_CHANGES.replace('{DeltaSyncFilterValue}',`${hourDifference}`));
      }
      lastModified = new Date().getTime();
      let positionString = '';
      this.authService.user.positions.forEach(p => {
        positionString += '<value>' + p.ID + '</value>'
      })
      fetchXml = fetchXml.replace('{PositionIdsFilter}',positionString);
      
      await this.customerAssessDataService.getPositionGroupProducts(fetchXml).then(async (data:any []) => {
        if (forceFullSync && !data.length) offlineData = [];
        if(trackChangesData.length){
          data.push(...trackChangesData);
          data = data.sort((a,b)=> new Date(a['trackActionCreatedOn']).getTime() - new Date(b['trackActionCreatedOn']).getTime());
        }
        if(data?.length){
          if (forceFullSync || !offlineData.length) offlineData = data;

          if (!forceFullSync && offlineData.length > 0) {
            data.forEach(value => {
              if(value['trackAction'] === TrackAction.Deleted && !value['positionGroupId']){
                const dataToRemove = offlineData.filter(a=> a['positionId'] == value['positionId'] && a['productId'] == value['productId']);
                if(dataToRemove.length > 0){
                  dataToRemove.forEach(i=>{
                    let j= offlineData.findIndex(k=> k['positionId'] == i['positionId'] && k['productId'] == i['productId']);
                    if(j>= 0){
                      offlineData.splice(j, 1);
                    }
                  })
                }
               }else{
                const index = offlineData.findIndex(a=> a['positionId'] == value['positionId'] && a['productId'] == value['productId'] && a['positionGroupId'] == value['positionGroupId']);
                if (index < 0 && value['trackAction'] == TrackAction.Download && value['productStatecode'] !== 1) {
                  offlineData.push(value);
                } else if (index >= 0 && (value['trackAction'] === TrackAction.Deleted || value['productStatecode'] === 1) ) {
                offlineData.splice(index, 1);
                }
               }
            });
          }
        }
        this.positionGroupProductIdMap = offlineData;
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.POSITIONGROUP_PRODUCT_MAP, doc => ({ raw: offlineData,lastModified: lastModified }))
          .catch((err) =>
            console.error("getPositionGroupProductIdMap: Error retreiving position product ids map to offline db!", err)
          );
      });
    } catch (error) {
      console.log("Error retreiving positionGroup product ids map ", error);
    }
  }

  private getPositionValuesForFetchXmlFilter() {
    let positionIds = this.authService.user.positions.filter(p => (p.status == 100000000 || p.status == 0)).map(o => {
      return o.ID;
    });
    let positionString = '';
    positionIds.forEach(p => {
      positionString += '<value>' + p + '</value>';
    });
    return positionString;
  }

  private async getAssessmentTemplates(forceFullSync: boolean) {
    let fetchXml = ASSESS_TEMPLATE_FETCHXML;

    const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CUSTOMER_ASSESS_TEMPLATES);
    const isInitialSync = forceFullSync || !syncState || !syncState.lastUpdatedTime;
    const newLastUpdatedTime = new Date().getTime();
    const isTeamViewFeatureAction = this.authService.hasFeatureAction(FeatureActionsMap.ASSESSMENTS_TEAM_VIEW);

    const assessTemplatesSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.assessmentTemplates,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    const validity = format(newLastUpdatedTime, 'YYYY-MM-DDTHH:mm:ss');
    fetchXml = fetchXml.replace('{0}', validity).replace('{0}', validity).replace('{BUID}', this.authService.user.xBusinessUnitId);
    if (isInitialSync) {
      this.assessmentTemplates = [];
      fetchXml = fetchXml.replace('{statusCondition}', ASSESSMENT_TEMPLATE_STATUS_CONDITION).replace('{DeltaCondition}', '').replace('{RoleStatusCondition}', `<condition attribute="statecode" operator="eq" value="0" />`).replace(
        '{statecodeAttribute}', '');
      try{
        Promise.all([
          this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUST_ASSESS_TEMPLATES),
          this.deleteCustomerAssessments([], true),
        ]);
      }catch(error) {
        console.error("Failed to delete assessment templates, assessment and survey ", error);
      }
    }
    else {
      const statecodeAttribute = `<attribute name="statecode" alias="role_statecode"/>`;
      // const lastModifiedFormatted = format(new Date(syncState.lastUpdatedTime).toUTCString(), 'YYYY-MM-DD' + 'T' + 'HH:mm:ss');
      const deltaSyncFilter = `<condition attribute="modifiedon" operator="ge" value="` + new Date(syncState.lastUpdatedTime).toISOString() + `" />`
      fetchXml = fetchXml.replace('{statusCondition}', '').replace('{DeltaCondition}', deltaSyncFilter).replace('{statecodeAttribute}', statecodeAttribute).replace('{RoleStatusCondition}', '');
      if (!this.isInitialMappingDone) {
        await this.loadAssessmentTemplateFromDB();
        this.isInitialMappingDone = true;
      }
    }

    await this.customerAssessDataService.getAssessmentTemplates(fetchXml).then(
      async (data: AssessmentTemplate[]) => {
        if (!_.isEmpty(data)) {
          console.log("Saving customer assess templates to disk...");
          if (isInitialSync) {
            await this.saveAssessTemplatesToDisk(data);
          } else {
            await this.saveDeltaAssessTemplatesToDisk(data);
          }
          this.triggerFullSyncSearchData = true;
        }
        this.isInitialMappingDone = true;
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.disk.updateSyncState(syncState);
        if (Array.isArray(data)) {
          assessTemplatesSyncInfo.totalSynced = data.length;
        }
        this.deltaService.addEntitySyncInfo(assessTemplatesSyncInfo);
      }).catch(err => {
        console.error("Error occurred while fetching customer assessment templates...", err)
        this.deltaService.addSyncErrorToEntitySyncInfo(assessTemplatesSyncInfo, 'indskr_assessmenttemplates', err);
        this.deltaService.addEntitySyncInfo(assessTemplatesSyncInfo);
      });
    
    if(isTeamViewFeatureAction) {
      let fetchXmlTeamViewTeamplates = ASSESS_TEAMVIEW_TEMPLATES_FETCHXML;
      let fetchXmlTeamViewRoles = ASSESS_TEAMVIEW_ROLES;

      const assessTemplatesTeamViewSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.assessmentTemplates,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };
      fetchXmlTeamViewTeamplates = fetchXmlTeamViewTeamplates.replace('{0}', validity).replace('{0}', validity);
      fetchXmlTeamViewTeamplates = fetchXmlTeamViewTeamplates.replace('{statusCondition}', ASSESSMENT_TEMPLATE_STATUS_CONDITION)
      .replace('{DeltaCondition}', '')
      .replace('{RoleStatusCondition}', `<condition attribute="statecode" operator="eq" value="0" />`)
      .replace('{statecodeAttribute}', '');
      
      await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUST_ASSESS_TEAMVIEW_TEMPLATES);
      //set Positions filter conditions
      let teamviewPosition = await this.getAssessTeamViewPositionIds();
      let positionsFilterCondition = await this.getPositionsFilterCondition(teamviewPosition);
      //set Roles filter conditions
      fetchXmlTeamViewRoles = fetchXmlTeamViewRoles.replace('{positionsCondition}', positionsFilterCondition);
      let rolesFilterCondition = await this.getRolesFilterCondition(fetchXmlTeamViewRoles);
      if(rolesFilterCondition) {
        fetchXmlTeamViewTeamplates = fetchXmlTeamViewTeamplates.replace('{rolesCondition}', rolesFilterCondition);
        //Fetch templates based on security roles
        await this.customerAssessDataService.getAssessmentTemplates(fetchXmlTeamViewTeamplates).then(async(data: AssessmentTemplate[]) => {
          if (!_.isEmpty(data)) {
            console.log("Saving customer assess templates - Teamview to disk...");
            await this.saveAssessTemplatesTeamViewToDisk(data);
          }
          syncState.lastUpdatedTime = newLastUpdatedTime;
          await this.disk.updateSyncState(syncState);
          if(Array.isArray(data)) {
            assessTemplatesTeamViewSyncInfo.totalSynced = data.length;
          }
          this.deltaService.addEntitySyncInfo(assessTemplatesTeamViewSyncInfo);
        }).catch(err => {
            console.error("Error occurred while fetching customer assessment templates - Teamview...", err)
            this.deltaService.addSyncErrorToEntitySyncInfo(assessTemplatesTeamViewSyncInfo, 'indskr_assessmenttemplates', err);
            this.deltaService.addEntitySyncInfo(assessTemplatesTeamViewSyncInfo);
        });
      }
    }
  }



  private async getSurveyTemplates(forceFullSync: boolean) {
    let fetchXml_role = CUSTOMER_SURVEY_TEMPLATE_FETCHXML_ROLE;
    let fetchXml_position = CUSTOMER_SURVEY_TEMPLATE_FETCHXML_POSITION;
    

    const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CUSTOMER_SURVEY_TEMPLATES);
    const isInitialSync = forceFullSync || !syncState || !syncState.lastUpdatedTime;
    const newLastUpdatedTime = new Date().getTime();

    const assessTemplatesSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.assessmentTemplates,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    fetchXml_role = fetchXml_role.replace('{BUID}', this.authService.user.xBusinessUnitId);
    fetchXml_position = fetchXml_position.replace('{BUID}', this.authService.user.xBusinessUnitId);
    if (isInitialSync) {
      this.surveytemplates = [];
      fetchXml_role = fetchXml_role.replace('{statusCondition}', ASSESSMENT_TEMPLATE_STATUS_CONDITION).replace('{DeltaCondition}', '').replace('{RoleStatusCondition}', `<condition attribute="statecode" operator="eq" value="0" />`).replace(
        '{statecodeAttribute}', '');
        fetchXml_position = fetchXml_position.replace('{statusCondition}', ASSESSMENT_TEMPLATE_STATUS_CONDITION).replace('{DeltaCondition}', '').replace('{RoleStatusCondition}', `<condition attribute="statecode" operator="eq" value="0" />`).replace(
        '{statecodeAttribute}', '');
      try{
        Promise.all([
          this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUST_SURVEY_TEMPLATES),
          this.deleteCustomerSurveys([], true)
        ]);
      }catch(error) {
        console.error("Failed to delete assessment templates, assessment and survey ", error);
      }
    }
    else {
      const statecodeAttribute = `<attribute name="statecode" alias="role_statecode"/>`;
      const deltaSyncFilter = `<condition attribute="modifiedon" operator="ge" value="` + new Date(syncState.lastUpdatedTime).toISOString() + `" />`
      fetchXml_role = fetchXml_role.replace('{statusCondition}', '').replace('{DeltaCondition}', deltaSyncFilter).replace('{statecodeAttribute}', statecodeAttribute).replace('{RoleStatusCondition}', '');
      fetchXml_position = fetchXml_position.replace('{statusCondition}', '').replace('{DeltaCondition}', deltaSyncFilter).replace('{statecodeAttribute}', statecodeAttribute).replace('{RoleStatusCondition}', '');
      if (!this.isSurveyInitialMappingDone) {
        await this.loadSurveyTemplateFromDB();
        this.isSurveyInitialMappingDone = true;
      }
    }
    await this.customerAssessDataService.getAssessmentTemplates(fetchXml_position).then(
      async (data_position: AssessmentTemplate[]) => {
        
        await this.customerAssessDataService.getAssessmentTemplates(fetchXml_role).then(async (data_role) => {

        let data: AssessmentTemplate[] = []

          if (!_.isEmpty(data_position)) {
            data = data_position;
          }

          if (!_.isEmpty(data_role)) {
            data = data.concat(data_role)
          }

          data = _.unionBy(data, 'indskr_assessmenttemplateid')
        
        if (!_.isEmpty(data)) {
          console.log("Saving customer assess templates to disk...");
          if (isInitialSync) {
            await this.saveSurveytemplates(data);
          } else {
            await this.saveDeltaSurveyTemplatesToDisk(data);
          }
          this.triggerFullSyncSearchData = true;
        }

        this.isSurveyInitialMappingDone = true;
        syncState.lastUpdatedTime = newLastUpdatedTime;

        await this.disk.updateSyncState(syncState);
        if (Array.isArray(data)) {
          assessTemplatesSyncInfo.totalSynced = data.length;
        }
        this.deltaService.addEntitySyncInfo(assessTemplatesSyncInfo);
      }).catch(err => {
        console.error("Error occurred while fetching customer survey templates...", err)
        this.deltaService.addSyncErrorToEntitySyncInfo(assessTemplatesSyncInfo, 'indskr_assessmenttemplates', err);
        this.deltaService.addEntitySyncInfo(assessTemplatesSyncInfo);
      });
      }).catch(err => {
        console.error("Error occurred while fetching customer survey templates...", err)
        this.deltaService.addSyncErrorToEntitySyncInfo(assessTemplatesSyncInfo, 'indskr_assessmenttemplates', err);
        this.deltaService.addEntitySyncInfo(assessTemplatesSyncInfo);
      });
  }

  public async getTeamViewTemplatesFromChildPositions(): Promise<any[]> {
    let childPositionTemplateIds = [];
    let fetchXmlTeamViewTeamplates = ASSESS_TEAMVIEW_TEMPLATES_FETCHXML;
    let fetchXmlTeamViewRoles = ASSESS_TEAMVIEW_ROLES;
    const newLastUpdatedTime = new Date().getTime();
    const validity = format(newLastUpdatedTime, 'YYYY-MM-DDTHH:mm:ss');

    fetchXmlTeamViewTeamplates = fetchXmlTeamViewTeamplates.replace('{0}', validity).replace('{0}', validity);
    fetchXmlTeamViewTeamplates = fetchXmlTeamViewTeamplates.replace('{statusCondition}', ASSESSMENT_TEMPLATE_STATUS_CONDITION)
    .replace('{DeltaCondition}', '')
    .replace('{RoleStatusCondition}', `<condition attribute="statecode" operator="eq" value="0" />`)
    .replace('{statecodeAttribute}', '');
    
    //set Positions filter conditions
    const childPositionIds = await this.getAssessTeamViewChildPositionIds();
    let positionsFilterCondition = await this.getPositionsFilterCondition(childPositionIds);
    //set Roles filter conditions
    fetchXmlTeamViewRoles = fetchXmlTeamViewRoles.replace('{positionsCondition}', positionsFilterCondition);
    let rolesFilterCondition = await this.getRolesFilterCondition(fetchXmlTeamViewRoles);
    
    if(rolesFilterCondition) {
      fetchXmlTeamViewTeamplates = fetchXmlTeamViewTeamplates.replace('{rolesCondition}', rolesFilterCondition);
      //Fetch templates based on security roles
      await this.customerAssessDataService.getAssessmentTemplates(fetchXmlTeamViewTeamplates).then(async(data: AssessmentTemplate[]) => {
        if (!_.isEmpty(data)) {
          childPositionTemplateIds = data.map(d=>{ return d['indskr_assessmenttemplateid']; });
        }
      }).catch(err => {
          console.error("Error occurred while fetching customer assessment templates - Teamview...", err)
      });
    }
    return childPositionTemplateIds;
  }

  private async saveDeltaAssessTemplatesToDisk(data: AssessmentTemplate[]) {
    const deletedTemplates = [];
    const deletedTemplateIds = [];
    data = _.unionBy(_.orderBy(data, 'role_statecode', 'asc'), 'indskr_assessmenttemplateid');
    for (let template of data) {
      template._id = DB_KEY_PREFIXES.CUSTOMER_ASSESS_TEMPLATES + template['indskr_assessmenttemplateid'];
      const index = this.assessmentTemplates.findIndex(assessTemplate => assessTemplate.indskr_assessmenttemplateid === template.indskr_assessmenttemplateid);
      if (template.statuscode === 2 && template.statecode === 1 && template.role_statecode == 0) {
        const templateToSave: AssessmentTemplate = this.sortTemplate(new AssessmentTemplate(template));
        if (index > -1) {
          this.assessmentTemplates[index] = templateToSave;
        } else {
          this.assessmentTemplates.push(templateToSave);
        }
        await this.disk.updateOrInsert(template._id, (doc) => { return templateToSave; });
      } else {
        //Delete DB Record is presenet if only present in DB
        if (index > -1) {
          deletedTemplates.push({ _id: this.assessmentTemplates[index]._id, _rev: this.assessmentTemplates[index]._rev, _deleted: true });
          deletedTemplateIds.push(this.assessmentTemplates[index].indskr_assessmenttemplateid);
          this.assessmentTemplates.splice(index, 1);
        }
      }
    }
    if (!_.isEmpty(deletedTemplates)) {
      try {
        // Bulk save/update docs to DB
        await this.disk.bulk(deletedTemplates);
        this.deleteCustomerAssessments(deletedTemplateIds)
      } catch (error) {
        console.error('error occurred: saving deleted assess template: ', error);
      }
    }

  }

  private async saveDeltaSurveyTemplatesToDisk(data: AssessmentTemplate[]) {
    const deletedTemplates = [];
    const deletedTemplateIds = [];
    data = _.unionBy(_.orderBy(data, 'role_statecode', 'asc'), 'indskr_assessmenttemplateid');
    for (let template of data) {
      template._id = DB_KEY_PREFIXES.CUSTOMER_SURVEY_TEMPLATES + template['indskr_assessmenttemplateid'];
      const index = this.surveytemplates.findIndex(assessTemplate => assessTemplate.indskr_assessmenttemplateid === template.indskr_assessmenttemplateid);
      if (template.statuscode === 2 && template.statecode === 1) {
        const templateToSave: AssessmentTemplate = this.sortTemplate(new AssessmentTemplate(template));
        /* Role and position groups are or condition
           When fetching template data, if there is the same tempalte from both role-based and position-based. The data will be position-based through concat and unionBy functions.
           If the templated is fetched based on role, it has role statecode. Otherwise, it does not have it.
           In case of the template has the role condition only, need to check state code. If role is changed from activity to inactivity, the template will be deleted. 
        */
        if(template.hasOwnProperty('role_statecode')) {
          if(template.role_statecode == 0) {
            if (index > -1) {
              this.surveytemplates[index] = templateToSave;
            } else {
              this.surveytemplates.push(templateToSave);
            }
            await this.disk.updateOrInsert(template._id, (doc) => { return templateToSave; });
          }else {
            //Delete DB record
            if (index > -1) {
              deletedTemplates.push({ _id: this.surveytemplates[index]._id, _rev: this.surveytemplates[index]._rev, _deleted: true });
              deletedTemplateIds.push(this.surveytemplates[index].indskr_assessmenttemplateid);
              this.surveytemplates.splice(index, 1);
            }
          }
        }else {
          if (index > -1) {
            this.surveytemplates[index] = templateToSave;
          } else {
            this.surveytemplates.push(templateToSave);
          }
          await this.disk.updateOrInsert(template._id, (doc) => { return templateToSave; });
        }
      } else {
        //Delete DB Record is presenet if only present in DB
        if (index > -1) {
          deletedTemplates.push({ _id: this.surveytemplates[index]._id, _rev: this.surveytemplates[index]._rev, _deleted: true });
          deletedTemplateIds.push(this.surveytemplates[index].indskr_assessmenttemplateid);
          this.surveytemplates.splice(index, 1);
        }
      }
    }
    if (!_.isEmpty(deletedTemplates)) {
      try {
        // Bulk save/update docs to DB
        await this.disk.bulk(deletedTemplates);
        this.deleteCustomerSurveys(deletedTemplateIds);
      } catch (error) {
        console.error('error occurred: saving deleted assess template: ', error);
      }
    }

  }

  private async saveAssessTemplatesToDisk(data: AssessmentTemplate[]) {
    for (let i = 0; i < data.length; i++) {
      const rawData = data[i];
      /* Pouch db doesnt allow to store keyword starting with _ */
      for (let key in data[i]) {
        if (key.charAt(0) === "_") {
          var a = key.substring(1, key.length);
          data[i][a] = rawData[a] = data[i][key];
          delete data[i][key];
          delete rawData[key];
        }
      }
      rawData['_id'] = DB_KEY_PREFIXES.CUSTOMER_ASSESS_TEMPLATES + rawData['indskr_assessmenttemplateid'];
      this.assessmentTemplates.push(this.sortTemplate(new AssessmentTemplate(rawData)));
      try {
        await this.disk.bulk(this.assessmentTemplates);
      } catch (error) {
        console.log("error saving bulk templates", error);
      }
    }
  }

  private async saveSurveytemplates(data: AssessmentTemplate[]) {
    for (let i = 0; i < data.length; i++) {
      const rawData = data[i];
      /* Pouch db doesnt allow to store keyword starting with _ */
      for (let key in data[i]) {
        if (key.charAt(0) === "_") {
          var a = key.substring(1, key.length);
          data[i][a] = rawData[a] = data[i][key];
          delete data[i][key];
          delete rawData[key];
        }
      }
      rawData['_id'] = DB_KEY_PREFIXES.CUSTOMER_SURVEY_TEMPLATES + rawData['indskr_assessmenttemplateid'];
      this.surveytemplates.push(this.sortTemplate(new AssessmentTemplate(rawData)));
      try {
        await this.disk.bulk(this.surveytemplates);
      } catch (error) {
        console.log("error saving bulk templates", error);
      }
    }
  }

  private sortTemplate(template: AssessmentTemplate): AssessmentTemplate {
    const sharedSection: AttributeSection[] = [];
    const positionSection: AttributeSection[] = [];
    const positionGroupSection: AttributeSection[] = [];
    let posiProdTASection: AttributeSection[] = [];
    template.indskr_metadata.Sections.forEach(section => {
      section.Controls = this.sortControls(section.Controls);
      if (!section.Position && !section.PositionGroup && !section.Product && !section.TherapetuicArea) {
        sharedSection.push(section);
      } else if (section.Position && !section.TherapetuicArea && !section.Product && !section.PositionGroup) {
        positionSection.push(section);
      } else if (!section.Position && !section.TherapetuicArea && !section.Product && section.PositionGroup) {
        positionGroupSection.push(section);
      } else {
        posiProdTASection.push(section);
      }
    });
    template.indskr_metadata.Sections = this.sortAttributeSections(sharedSection).concat(this.sortAttributeSections(positionSection)).concat(this.sortAttributeSections(positionGroupSection)).concat(this.sortAttributeSections(posiProdTASection));
    return template;
  }

  private sortControls(controls: Control[]): Control[] {
    const controlsWithOrders = _.orderBy(controls.filter(control => control.Order != 0), ['Order', 'Attribute.DisplayName'], "asc");
    const controlsWithoutOrders = _.orderBy(controls.filter(control => control.Order == 0), ['Attribute.DisplayName'], "asc");
    return controlsWithOrders.concat(controlsWithoutOrders);
  }

  private sortAttributeSections(sections: AttributeSection[]): AttributeSection[] {
    const sectionsWithOrders = _.orderBy(sections.filter(control => control.Order != 0), ['Order', 'SectionProperties.DisplayName'], "asc");
    const sectionsWithoutOrders = _.orderBy(sections.filter(control => control.Order == 0), ['SectionProperties.DisplayName'], "asc");
    return sectionsWithOrders.concat(sectionsWithoutOrders);
  }

  private async syncSpecialtyProcedures() {
    const templates = this.getTemplatesForEntity(TemplateType.ASSESSMENT, EntityOptions.CONTACT);
    this.specialtyProceduresMap = new Map<String, Brand[]>();
    if (templates.length == 1 && templates[0].indskr_metadata.Sections.some(section => section.Procedure)) {
      const positionIds = this.getPositionValuesForFetchXmlFilter();
      const fetchXml = PROCEDURES_FOR_SPECIALTY.replace("{positionIds}", positionIds).replace("{positionIds}", positionIds);
      const responses = await this.customerAssessDataService.getAssessedContacts(fetchXml);
      if (responses) {
        for (let response of responses) {
          const brand = new Brand(response);
          if (this.specialtyProceduresMap.has(response["specialtyId"])) {
            const existingBrands = this.specialtyProceduresMap.get(response["specialtyId"]);
            existingBrands.push(brand);
            this.specialtyProceduresMap.set(response["specialtyId"], _.uniqBy(existingBrands, "ID"));
          } else {
            this.specialtyProceduresMap.set(response["specialtyId"], [brand]);
          }
        }
      }
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.SPECIALTY_PROCEDURES_FOR_ASSESSMENT, (doc) => {
        if (!doc || !doc.raw) {
          doc = {
            raw: []
          }
        }
        doc.raw = this.specialtyProceduresMap;
        return doc;
      }).catch(err => console.log("Failed to specialty_procedures_for_assessment ", err))
    }
  }

  public async saveMultiCustomerAssessment(multipleFormValue:any, selectedTemplate: AssessmentTemplate, deletedFormValues: any ): Promise<any> {
    const isOffline: boolean = this.device.isOffline;
    return new Promise(async (resolve,reject) => {
      if(!isOffline) {
        if(!_.isEmpty(multipleFormValue) && _.isArray(multipleFormValue)) {
          let payload: CustomerAssessment[] = [];
          await Promise.all(multipleFormValue.map(async (value) => {
            let updateCustAssessFromDB: CustomerAssessment = await this.getCustomerAssessmentFromDB(value.contactId);
            let currentFormValue = value.currentValue;
            if (_.isEmpty(updateCustAssessFromDB) || updateCustAssessFromDB.indskr_template != selectedTemplate.indskr_assessmenttemplateid) {
              updateCustAssessFromDB = new CustomerAssessment(
                Guid.create().
                toString(), 
                selectedTemplate.indskr_assessmenttemplateid, 
                selectedTemplate.indskr_entity, 
                selectedTemplate.indskr_name, 
                value.contactId, 
                new Date().toISOString().toString(), 
                new Date().toISOString().toString(), 
                [],
                );
              if(isOffline) updateCustAssessFromDB.isInitialAssessInOffline = true;
            }
            let responses = [];
            for (let [key, value] of Object.entries(currentFormValue)) {
              if (key.includes('_')) {
                this._fromSectionSpecific(value, responses);
              } else {
                responses = responses.concat(value);
              }
            }
            if (!_.isEmpty(deletedFormValues)) {
              for (let [key, value] of Object.entries(deletedFormValues)) {
                if (key.includes('_')) {
                  this._fromSectionSpecific(value, responses);
                } else {
                  responses = responses.concat(value);
                }
              }
            }
            updateCustAssessFromDB.responses = responses;
            updateCustAssessFromDB.pendingPushToDynamics = isOffline;
            payload.push(updateCustAssessFromDB);
          }))
          .then(() => {
            let updatedCA: CustomerAssessment[] = []
            if(!_.isEmpty(payload)) {
              payload.forEach(p => {
                if (p.indskr_entity == "contact") {
                  const idx = this.assessedContacts.findIndex(c=>c.contactid == p.indskr_entityid);
                  if (idx < 0) {
                    this.assessedContacts.push({ "contactid": p.indskr_entityid });
                  }
                } else {
                  //multi-assessment for account is not implemented yet
                }
              });
              this.customerAssessDataService.uploadOfflineAssessment(payload).then(async (res) => {
                if(!_.isEmpty(res)) {
                  Promise.all(payload.map(async (ca) => {
                    if(res.some(r => r['indskr_customerassessmentid'] == ca.indskr_customerassessmentid)) {
                      ca.pendingPushToDynamics = this.device.isOffline;
                      await this.saveCustomerAssessmentInDB(ca);
                      updatedCA.push(this.filterResponses(ca));
                    }
                  }))
                  .then(() => {
                    resolve(updatedCA);
                  })
                }
              })
            }          
          })
        }
      } else {
        if(!_.isEmpty(multipleFormValue) && _.isArray(multipleFormValue)) {
          let offlineAssessment: CustomerAssessment[] = [];
          multipleFormValue.forEach(async value => {
            let updateCustAssessFromDB: CustomerAssessment = await this.getCustomerAssessmentFromDB(value.contactId);
            let filtedOfflineCustomerAssessment: CustomerAssessment = await this.fetchUpdatedOfflineCustomerAssessment(value.contactId, updateCustAssessFromDB);
            if(!_.isEmpty(filtedOfflineCustomerAssessment)) {
              this.assessedContacts.push({ "contactid": value.contactId });
              updateCustAssessFromDB.contactId = value.contactId;
              await this.saveOfflineContactAssessmentInDB(updateCustAssessFromDB);
              // Track offline data count
              try {
                await this.loadOfflineContactAssessmentInDB().then((data)=>{
                  if(!_.isEmpty(data) && data.length) {
                    let offlineCount = data.length;
                    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_ASSESSMENT, offlineCount);
                  }
                });
              } catch(error) {
                console.log("Error loading offline contact assessment in DB", error);
              }
              offlineAssessment.push(filtedOfflineCustomerAssessment)
            }
          });
          resolve(offlineAssessment);
        }
      }  
    })
         
  }

  public async saveCustomerAssessment(captureFor: string, entityId: string, currentFormValue: any, selectedTemplate: AssessmentTemplate, deletedFormValues: any): Promise<CustomerAssessment> {
    let updateCustAssessFromDB: CustomerAssessment = await this.getCustomerAssessmentFromDB(entityId);
    const isOffline: boolean = this.device.isOffline;
    if (_.isEmpty(updateCustAssessFromDB) || updateCustAssessFromDB.indskr_template != selectedTemplate.indskr_assessmenttemplateid) {
      updateCustAssessFromDB = new CustomerAssessment(
        Guid.create().
        toString(), 
        selectedTemplate.indskr_assessmenttemplateid, 
        selectedTemplate.indskr_entity, 
        selectedTemplate.indskr_name, 
        entityId, 
        new Date().toISOString().toString(), 
        new Date().toISOString().toString(), 
        [],
        );
      if(isOffline) updateCustAssessFromDB.isInitialAssessInOffline = true;
    }
    let responses = [];
    for (let [key, value] of Object.entries(currentFormValue)) {
      if (key.includes('_')) {
        this._fromSectionSpecific(value, responses);
      } else {
        responses = responses.concat(value);
      }
    }
    if (!_.isEmpty(deletedFormValues)) {
      for (let [key, value] of Object.entries(deletedFormValues)) {
        if (key.includes('_')) {
          this._fromSectionSpecific(value, responses);
        } else {
          responses = responses.concat(value);
        }
      }
    }
    updateCustAssessFromDB.responses = responses;
    updateCustAssessFromDB.pendingPushToDynamics = isOffline;

    if(!isOffline) {
      if(captureFor == 'contact') {
        this.assessedContacts.push({ "contactid": entityId });
        const response = await this.customerAssessDataService.saveContactAssessment(updateCustAssessFromDB).catch((error) => {
          console.error("Failed to save contact assessment ", error);
        });
        if(!_.isEmpty(response) && response['indskr_customerassessmentid'] === updateCustAssessFromDB.indskr_customerassessmentid) {
          return await this.fetchContactAssessment(entityId, updateCustAssessFromDB.indskr_template);
        }
      }else if(captureFor == 'account') {
        this.assessedAccounts.push({ "accountid": entityId });
        const response = await this.customerAssessDataService.saveAccountAssessment(updateCustAssessFromDB).catch((error) => {
          console.error("Failed to save account assessment ", error);
        });
        if(!_.isEmpty(response) && response['indskr_customerassessmentid'] === updateCustAssessFromDB.indskr_customerassessmentid) {
          return await this.fetchAccountAssessment(entityId, updateCustAssessFromDB.indskr_template);
        }
      }
    }
    //Offline mode
    else{
      const filtedOfflineCustomerAssessment: CustomerAssessment = await this.fetchUpdatedOfflineCustomerAssessment(entityId, updateCustAssessFromDB);
      if(captureFor == 'contact' && !_.isEmpty(filtedOfflineCustomerAssessment)) {
        this.assessedContacts.push({ "contactid": entityId });
        updateCustAssessFromDB.contactId = entityId;
        await this.saveOfflineContactAssessmentInDB(updateCustAssessFromDB);
        // Track offline data count
        try {
          await this.loadOfflineContactAssessmentInDB().then((data)=>{
            if(!_.isEmpty(data) && data.length) {
              let offlineCount = data.length;
              this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_ASSESSMENT, offlineCount);
            }
          });
        } catch(error) {
          console.log("Error loading offline contact assessment in DB", error);
        }
        return filtedOfflineCustomerAssessment;
      } else if(captureFor == 'account' && !_.isEmpty(filtedOfflineCustomerAssessment)) {
        this.assessedAccounts.push({ "accountid": entityId });
        updateCustAssessFromDB.accountId = entityId;
        await this.saveOfflineAccountAssessmentInDB(updateCustAssessFromDB);
        // Track offline data count
        try {
          await this.loadOfflineAccountAssessmentInDB().then((data)=>{
            if(!_.isEmpty(data) && data.length) {
              let offlineCount = data.length;
              this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ACCOUNT_ASSESSMENT, offlineCount);
            }
          });
        } catch(error) {
          console.log("Error loading offline account assessment in DB", error);
        }
        return filtedOfflineCustomerAssessment;
      } 
    }
  }

  // public async saveMultipleAssessOffline(captureFor: string, entityId: string, formValueListById: any, selectedTemplate: AssessmentTemplate, deletedFormValues: any): Promise<CustomerAssessment> {
  //   let updateCustAssessFromDB: CustomerAssessment = await this.getCustomerAssessmentFromDB(entityId);
  //   const isOffline: boolean = this.device.isOffline;
  //   if(_.isEmpty(updateCustAssessFromDB) || updateCustAssessFromDB.indskr_template != selectedTemplate.indskr_assessmenttemplateid) {
  //     updateCustAssessFromDB = new CustomerAssessment(
  //       Guid.create().
  //       toString(), 
  //       selectedTemplate.indskr_assessmenttemplateid, 
  //       selectedTemplate.indskr_entity, 
  //       selectedTemplate.indskr_name, 
  //       entityId, 
  //       new Date().toISOString().toString(), 
  //       new Date().toISOString().toString(), 
  //       [],
  //       );
  //     if(isOffline) updateCustAssessFromDB.isInitialAssessInOffline = true;
  //   }
  //   let responses = [];
  //   formValueListById.forEach((formValue)=>{
  //     for (let [key, value] of Object.entries(formValue.currentValue)) {
  //       if (key.includes('_')) {
  //         this._fromSectionSpecific(value, responses);
  //       } else {
  //         responses = responses.concat(value);
  //       }
  //     }
  //   })
  //   if (!_.isEmpty(deletedFormValues)) {
  //     for (let [key, value] of Object.entries(deletedFormValues)) {
  //       if (key.includes('_')) {
  //         this._fromSectionSpecific(value, responses);
  //       } else {
  //         responses = responses.concat(value);
  //       }
  //     }
  //   }
  //   updateCustAssessFromDB.responses = responses;
  //   updateCustAssessFromDB.pendingPushToDynamics = isOffline;

  //   const filtedOfflineCustomerAssessment: CustomerAssessment = await this.fetchUpdatedOfflineCustomerAssessment(entityId, updateCustAssessFromDB);
  //   if(captureFor == 'contact' && !_.isEmpty(filtedOfflineCustomerAssessment)) {
  //     this.assessedContacts.push({ "contactid": entityId });
  //     updateCustAssessFromDB.contactId = entityId;
  //     await this.saveOfflineContactAssessmentInDB(updateCustAssessFromDB);
  //     // Track offline data count
  //     try {
  //       await this.loadOfflineContactAssessmentInDB().then((data)=>{
  //         if(!_.isEmpty(data) && data.length) {
  //           let offlineCount = data.length;
  //           this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_ASSESSMENT, offlineCount);
  //         }
  //       });
  //     } catch(error) {
  //       console.log("Error loading offline contact assessment in DB", error);
  //     }
  //     return filtedOfflineCustomerAssessment;
  //   }else if(captureFor == 'account' && !_.isEmpty(filtedOfflineCustomerAssessment)) {
  //     this.assessedAccounts.push({ "accountid": entityId });
  //     updateCustAssessFromDB.accountId = entityId;
  //     await this.saveOfflineAccountAssessmentInDB(updateCustAssessFromDB);
  //     // Track offline data count
  //     try {
  //       await this.loadOfflineAccountAssessmentInDB().then((data)=>{
  //         if(!_.isEmpty(data) && data.length) {
  //           let offlineCount = data.length;
  //           this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ACCOUNT_ASSESSMENT, offlineCount);
  //         }
  //       });
  //     } catch(error) {
  //       console.log("Error loading offline account assessment in DB", error);
  //     }
  //     return filtedOfflineCustomerAssessment;
  //   } 
  // }

  public parseSectionId(sectionId: string) {
    const selectedSectionIds = sectionId.split("_");
    return {
      positionId: _.isEmpty(selectedSectionIds[0]) ? null : selectedSectionIds[0],
      taId: _.isEmpty(selectedSectionIds[1]) ? null : selectedSectionIds[1],
      productId: _.isEmpty(selectedSectionIds[2]) ? null : selectedSectionIds[2],
      procedureId: _.isEmpty(selectedSectionIds[3]) ? null : selectedSectionIds[3],
      positionGroupId: _.isEmpty(selectedSectionIds[4]) ? null : selectedSectionIds[4]
    };
  }

  private _fromSectionSpecific(value, responses) {
    for (let [, value1] of Object.entries(value)) {
      responses.push(value1);
    }
  }

  public async getAssessmentResponsesForOnlineSearch(targetEntity: EntityOptions, typeToSearch: AssessmentAttributeType, categoryName: string) {
    let loader = await this.loadingCtrl.create();
    loader.present();
    let fetchXML = targetEntity == EntityOptions.CONTACT ? CUSTOMER_ASSESS_ONLINE_SEARCH_FETCHXML : ACCOUNT_ASSESS_ONLINE_SEARCH_FETCHXML;
    let isTypeChoice: boolean = typeToSearch == AssessmentAttributeType.CHOICE;
    let typeCondition =  isTypeChoice? `<condition attribute="indskr_type" operator="eq" value="451680004" />` : `<condition attribute="indskr_type" operator="eq" value="451680005"/>`
    const userPositionId = this.authService.user.xPositionID;
    const templates = this.getTemplatesForEntity(TemplateType.ASSESSMENT, targetEntity);
    let templateFilterCondition = templates[0].indskr_assessmenttemplateid;

    fetchXML = fetchXML.replace('{category}', categoryName)
                        .replace('{typeCondition}', typeCondition)
                        .replace('{userPosition}', userPositionId)
                        .replace('{parentUserPosition}', userPositionId)
                        .replace('{templateId}', templateFilterCondition);
    let validAssesments: Array<any> = [];
    let assessments: Array<any> = [];
    try {
      let response = await this.dynamics.executeFetchXml(
        'indskr_customerassessmentresponses',
        fetchXML
      );
      if (response) {
        if (Array.isArray(response.value)) {
          assessments = response.value;
          assessments = this.filterResponseForSearch(assessments);
          this.assessmentTemplates.forEach(template => {
            let templateId = template.indskr_assessmenttemplateid;
            if (template.indskr_metadata && template.indskr_metadata.Sections && template.indskr_metadata.Sections.length > 0) {
              template.indskr_metadata.Sections.forEach(section => {
                this.validateResponse(templateId, section, assessments, validAssesments);
              })
            }
          });
        }
      }
      if(loader) loader.dismiss();
    } catch (error) {
      console.error('fetching Search Results error - indskr_customerassessmentresponses: ', error);
      if(loader) loader.dismiss();
    }
    if(loader) loader.dismiss();
    return validAssesments;
  }

  public async getAssessmentForSearch(forceFullSync: boolean, targetEntity: EntityOptions) {
    const isContactEntity: boolean = targetEntity == EntityOptions.CONTACT;
    const templates = this.getTemplatesForEntity(TemplateType.ASSESSMENT, targetEntity);
    if (!this.authService.hasFeatureAction(FeatureActionsMap.ASSESSMENT_ADVANCED_SEARCH, true) || _.isEmpty(templates) || !_.isEmpty(templates) && templates.length != 1) return;
    
    let offlineDocs = await this.getCustomerAssessmentForSearchFromDB(isContactEntity);
    if (this.device.isOffline) {
      return offlineDocs;
    }
    const syncKey: string = isContactEntity ? DB_SYNC_STATE_KEYS.SYNC_CUSTOMER_ASSESS_FOR_SEARCH : DB_SYNC_STATE_KEYS.SYNC_ACCOUNT_ASSESS_FOR_SEARCH;
    const syncState = await this.disk.getSyncState(syncKey);
    const isInitialSync = forceFullSync || !syncState || !syncState.lastUpdatedTime;
    const newLastUpdatedTime = new Date().getTime();

    let fetchXml = isContactEntity ? CUSTOMER_ASSESS_FOR_SEARCH_FETCHXML : ACCOUNT_ASSESS_FOR_SEARCH_FETCHXML;
    let typeToSearch = [AssessmentAttributeType.CHOICE, AssessmentAttributeType.FLAG];
    let typeCondition = '';
    typeToSearch.forEach(t => {
      typeCondition += '<value>' + t + '</value>'
    })
    fetchXml = fetchXml.replace('{TypeCondition}', typeCondition).replace('{BUId}', this.authService.user.xBusinessUnitId);

    let templateFilterCondition = templates[0].indskr_assessmenttemplateid;

    const userPositionId = this.authService.user.xPositionID;

    fetchXml = fetchXml.replace('{TypeCondition}', typeCondition)
    .replace('{userPosition}', userPositionId)
    .replace('{parentUserPosition}', userPositionId);

    let updatedTemplateCondition = '';
    if(templateFilterCondition) {
      updatedTemplateCondition = `<link-entity name="indskr_assessmenttemplate" from="indskr_assessmenttemplateid" to="indskr_template" link-type="inner" alias="ap">
        <filter type="and">
          <condition attribute="statecode" operator="eq" value="1"/>
          <condition attribute="indskr_assessmenttemplateid" operator="eq" value="${templateFilterCondition}" />
        </filter>
      </link-entity>`;
    }
    fetchXml = fetchXml.replace('{templateCondition}', updatedTemplateCondition);
   

    const assessForSearchSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.customerassessmentresponse,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    if (isInitialSync) {
      fetchXml = fetchXml.replace('{DeltaCondition}', '');
      const optionKey = isContactEntity ? DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUST_ASSESSMENTS_FOR_SEARCH : DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACCOUNT_ASSESSMENTS_FOR_SEARCH;
      await this.disk.deleteAllFromDbUsingAlldocsQuery(optionKey);  
    }
    else {
      const deltaSyncFilter = `<condition attribute="modifiedon" operator="ge" value="` + new Date(syncState.lastUpdatedTime).toISOString() + `" />`
      fetchXml = fetchXml.replace('{DeltaCondition}', deltaSyncFilter);
    }

    if (isInitialSync) {
      let pagingInfo: any = null;
      let rawAllData = [];
      
      do {
        try {
          let response = await this.dynamics.executeFetchXml(
            'indskr_customerassessmentresponses',
            fetchXml,
            undefined,
            pagingInfo?.nextPage ?? 0,
            pagingInfo?.cookie ?? null,
          );
  
          if (response) {
            pagingInfo = response.PagingInfo ?? null;
            
            if (Array.isArray(response.value)) {
              rawAllData.push(...response.value);
            }
          }
  
        } catch (error) {
          console.log("Error retreiving assess data for search: ", error);
          this.deltaService.addSyncErrorToEntitySyncInfo(assessForSearchSyncInfo, 'indskr_customerassessmentresponse', error);
          this.deltaService.addEntitySyncInfo(assessForSearchSyncInfo);
          pagingInfo = null;
          rawAllData = null;
        }
      } while (pagingInfo !== null);
  
      if (!_.isEmpty(rawAllData)) {
        console.log("Fetched assessment responses for search: ", rawAllData.length);
        this.saveCustomerAssessmentForSearch(rawAllData, isInitialSync, offlineDocs, isContactEntity);
      }
      syncState.lastUpdatedTime = newLastUpdatedTime;
      await this.disk.updateSyncState(syncState);
      if (Array.isArray(rawAllData)) assessForSearchSyncInfo.totalSynced = rawAllData.length;
      this.deltaService.addEntitySyncInfo(assessForSearchSyncInfo);
      this.triggerFullSyncSearchData = false;
    }else {
      await this.customerAssessDataService.getCustomerAssessment(fetchXml).then(
        async (data) => {
          if (!_.isEmpty(data)) {
            this.saveCustomerAssessmentForSearch(data, isInitialSync, offlineDocs, isContactEntity);
          }
          syncState.lastUpdatedTime = newLastUpdatedTime;
          await this.disk.updateSyncState(syncState);
          
          if (Array.isArray(data)) assessForSearchSyncInfo.totalSynced = data.length;
          this.deltaService.addEntitySyncInfo(assessForSearchSyncInfo);
          this.triggerFullSyncSearchData = false;
        }).catch(error => {
          console.log("Error retreiving assess data for search: ", error);
          this.deltaService.addSyncErrorToEntitySyncInfo(assessForSearchSyncInfo, 'indskr_customerassessmentresponse', error);
          this.deltaService.addEntitySyncInfo(assessForSearchSyncInfo);
      });
    }
  }

  public async fetchContactAssessment(contactId: string, templateId: string): Promise<CustomerAssessment> {
    if (this.device.isOffline) {
      return await this.getCustomerAssessmentFromDB(contactId);
    }
    const contactAssessment: CustomerAssessment = await this.getCustomerAssessmentFromDB(contactId);;
    const modifiedonCondition = contactAssessment && contactAssessment.indskr_template == templateId ? '<condition attribute="modifiedon" operator="ge" value="{0}"/>'.replace('{0}', contactAssessment.modifiedon) : '';
    const fetchXml = CUSTOMER_ASSESS_FETCHXML.replace('{indskr_entityid}', contactId)
      .replace('{indskr_entity}', 'contact')
      .replace('{indskr_template}', templateId)
      // .replace('{DeltaCondition}', modifiedonCondition)
      .replace('{DeltaCondition}', '')
      .replace('{BUId}', this.authService.user.xBusinessUnitId);
    try {
      const data = await this.customerAssessDataService.getCustomerAssessment(fetchXml);
      if (_.isEmpty(data) && contactAssessment?.indskr_template == templateId) {
        //No data available in delta service respone
        return contactAssessment;
      }
      return await this.mapCustomerAssessmentResp(data);
    } catch (error) {
      console.error("Failed to fetch contact assessment ", error);
    }
  }

  public async fetchAccountAssessment(accountId: string, templateId: string): Promise<CustomerAssessment> {
    if (this.device.isOffline) {
      return await this.getCustomerAssessmentFromDB(accountId);
    }
    const accountAssessment: CustomerAssessment = await this.getCustomerAssessmentFromDB(accountId);;
    const modifiedonCondition = accountAssessment && accountAssessment.indskr_template == templateId ? '<condition attribute="modifiedon" operator="ge" value="{0}"/>'.replace('{0}', accountAssessment.modifiedon) : '';
    const fetchXml = CUSTOMER_ASSESS_FETCHXML.replace('{indskr_entityid}', accountId)
      .replace('{indskr_entity}', 'account')
      .replace('{indskr_template}', templateId)
      .replace('{DeltaCondition}', modifiedonCondition)
      .replace('{BUId}', this.authService.user.xBusinessUnitId);;
    try {
      const data = await this.customerAssessDataService.getCustomerAssessment(fetchXml);
      if (_.isEmpty(data) && accountAssessment?.indskr_template == templateId) {
        //No data available in delta service respone
        return accountAssessment;
      }
      return await this.mapCustomerAssessmentResp(data);
    } catch (error) {
      console.error("Failed to fetch account assessment ", error);
    }
  }

  // public async fetchOfflineContactAssessment(contactId: string): Promise<CustomerAssessment> {
  //   const contactAssessment: CustomerAssessment = await this.getCustomerAssessmentFromDB(contactId);
  //   if(!_.isEmpty(contactAssessment)) return contactAssessment;
  //   try {
  //     if(_.isEmpty(this.allContactAssessments)) {
  //       this.loadAllContactAssesseFromDB();
  //     }
  //     if(!_.isEmpty(this.allContactAssessments)) {
  //       let filteredData = this.allContactAssessments.filter(ca=>ca['ca.indskr_entityid'] == contactId);
  //       return await this.mapCustomerAssessmentResp(filteredData);
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch offline contact assessment ", error);
  //   }
  // }

  // public async fetchOfflineAccountAssessment(accountId: string): Promise<CustomerAssessment> {
  //   const accountAssessment: CustomerAssessment = await this.getCustomerAssessmentFromDB(accountId);
  //   if(!_.isEmpty(accountAssessment)) return accountAssessment;
  //   try {
  //     if(_.isEmpty(this.allAccountAssessments)) {
  //       this.loadAllAccountAssesseFromDB();
  //     }
  //     if(!_.isEmpty(this.allAccountAssessments)) {
  //       let filteredData = this.allAccountAssessments.filter(ca=>ca['ca.indskr_entityid'] == accountId);
  //       return await this.mapCustomerAssessmentResp(filteredData);
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch offline account assessment ", error);
  //   }
  // }

  // public async fetchAllContactAssessment(forceFullSync: boolean = false, templateId: string): Promise<CustomerAssessment[]> {
  //   const templates = this.getTemplatesForEntity(TemplateType.ASSESSMENT, EntityOptions.CONTACT);
  //   const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ALL_CONTACT_ASSESS);
  //   const isInitialSync = forceFullSync || !syncState || !syncState.lastUpdatedTime;
  //   const newLastUpdatedTime = new Date().getTime();

  //   if(!isInitialSync && _.isEmpty(this.allContactAssessments)) {
  //     await this.loadAllContactAssesseFromDB();
  //   }

  //   const allContactAssessSyncInfo: EntitySyncInfo = {
  //     entityName: EntityNames.customerassessmentresponse,
  //     totalFailed: 0,
  //     totalSynced: 0,
  //     errors: [],
  //     syncStatus: true
  //   };

  //   let templateFilterCondition = templates[0].indskr_assessmenttemplateid;

  //   const userPositionId = this.authService.user.xPositionID;

  //   let fetchXml = ALL_CUSTOMER_ASSESS_FETCHXML.replace('{indskr_entity}', 'contact')
  //     .replace('{userPosition}', userPositionId)
  //     .replace('{parentUserPosition}', userPositionId);

  //   let updatedTemplateCondition = '';
  //   if(templateFilterCondition) {
  //     updatedTemplateCondition = `<link-entity name="indskr_assessmenttemplate" from="indskr_assessmenttemplateid" to="indskr_template" link-type="inner" alias="ap">
  //       <filter type="and">
  //         <condition attribute="statecode" operator="eq" value="1"/>
  //         <condition attribute="indskr_assessmenttemplateid" operator="eq" value="${templateFilterCondition}" />
  //       </filter>
  //     </link-entity>`;
  //   }
  //   fetchXml = fetchXml.replace('{templateCondition}', updatedTemplateCondition);

  //   if(isInitialSync) {
  //     fetchXml = fetchXml.replace('{DeltaCondition}', '');
  //     await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACT_ASSESSMENTS);
  //   } else {
  //     const deltaSyncFilter = `<condition attribute="modifiedon" operator="ge" value="` + new Date(syncState.lastUpdatedTime).toISOString() + `" />`;
  //     fetchXml = fetchXml.replace('{DeltaCondition}', deltaSyncFilter);
  //   }
  //   let allContactAssessmentData: CustomerAssessment[] = [];
  //   await this.customerAssessDataService.getCustomerAssessment(fetchXml).then( async(rawAllData) => {
  //       if(!_.isEmpty(rawAllData)) {
  //         this.saveAllContactAssessmentInDB(rawAllData, isInitialSync);
  //         allContactAssessmentData = rawAllData;
  //       }
  //       syncState.lastUpdatedTime = newLastUpdatedTime;
  //       await this.disk.updateSyncState(syncState);
  //       if(Array.isArray(rawAllData)) {
  //         allContactAssessSyncInfo.totalSynced = rawAllData.length;
  //       }
  //       this.deltaService.addEntitySyncInfo(allContactAssessSyncInfo);
  //     }).catch(error=> {
  //       console.error("Error occurred while fetching all contact assessment data...", error)
  //       this.deltaService.addSyncErrorToEntitySyncInfo(allContactAssessSyncInfo, 'indskr_customerassessmentresponse', error);
  //       this.deltaService.addEntitySyncInfo(allContactAssessSyncInfo);
  //     })
  //   return allContactAssessmentData;
  // }

  public async fetchUpdatedOfflineCustomerAssessment(entityId: string, newCustomerAssessment: CustomerAssessment): Promise<CustomerAssessment> {
    const customerAssessmentFromDB: CustomerAssessment = await this.getCustomerAssessmentFromDB(entityId);
    if (!_.isEmpty(newCustomerAssessment)) {
      const customerAssessment = new CustomerAssessment(
        newCustomerAssessment['indskr_customerassessmentid'],
        newCustomerAssessment['indskr_template'],
        newCustomerAssessment['indskr_entity'],
        newCustomerAssessment['indskr_name'],
        newCustomerAssessment['indskr_entityid'],
        newCustomerAssessment['indskr_assessmentdate'],
        new Date().toISOString().toString(),
        [],
      );
      if(newCustomerAssessment.responses && newCustomerAssessment.responses.length>0) {
        customerAssessment.responses.push(...newCustomerAssessment.responses);
      }
      if(!_.isEmpty(customerAssessmentFromDB) && customerAssessmentFromDB.responses && customerAssessmentFromDB.responses.length>0) {
        customerAssessment.responses.push(...customerAssessmentFromDB.responses);
      }
      customerAssessment.pendingPushToDynamics = this.device.isOffline;
      if(customerAssessment.responses && customerAssessment.responses.length > 0) {
        await this.saveCustomerAssessmentInDB(customerAssessment);
        return this.filterResponses(customerAssessment);
      } else return null;
    }
  }

  // public async fetchAllAccountAssessment(forceFullSync: boolean = false, templateId: string): Promise<CustomerAssessment[]> {
  //   const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ALL_ACCOUNT_ASSESS);
  //   const isInitialSync = forceFullSync || !syncState || !syncState.lastUpdatedTime;
  //   const newLastUpdatedTime = new Date().getTime();

  //   const allAccountAssessSyncInfo: EntitySyncInfo = {
  //     entityName: EntityNames.customerassessmentresponse,
  //     totalFailed: 0,
  //     totalSynced: 0,
  //     errors: [],
  //     syncStatus: true
  //   };

  //   let fetchXml = ALL_CUSTOMER_ASSESS_FETCHXML.replace('{indskr_entity}', 'account')
  //     .replace('{indskr_template}', templateId)
  //     .replace('{BUId}', this.authService.user.xBusinessUnitId);

  //   if(isInitialSync) {
  //     fetchXml = fetchXml.replace('{DeltaCondition}', '');
  //     await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACCOUNT_ASSESSMENTS);
  //   } else {
  //     const deltaSyncFilter = `<condition attribute="modifiedon" operator="ge" value="` + new Date(syncState.lastUpdatedTime).toISOString() + `" />`;
  //     fetchXml = fetchXml.replace('{DeltaCondition}', deltaSyncFilter);
  //   }
  //   let allAccountAssessmentData: CustomerAssessment[] = [];
  //   await this.customerAssessDataService.getCustomerAssessment(fetchXml).then( async(rawAllData) => {
  //       if(!_.isEmpty(rawAllData)) {
  //         this.saveAllAccountAssessmentInDB(rawAllData);
  //         allAccountAssessmentData = rawAllData;
  //       }
  //       syncState.lastUpdatedTime = newLastUpdatedTime;
  //       await this.disk.updateSyncState(syncState);
  //       if(Array.isArray(rawAllData)) {
  //         allAccountAssessSyncInfo.totalSynced = rawAllData.length;
  //       }
  //       this.deltaService.addEntitySyncInfo(allAccountAssessSyncInfo);
  //     }).catch(error=> {
  //       console.error("Error occurred while fetching all account assessment data...", error)
  //       this.deltaService.addSyncErrorToEntitySyncInfo(allAccountAssessSyncInfo, 'indskr_customerassessmentresponse', error);
  //       this.deltaService.addEntitySyncInfo(allAccountAssessSyncInfo);
  //     })
  //   return allAccountAssessmentData;
  // }

  private async mapCustomerAssessmentResp(data): Promise<CustomerAssessment> {
    if (!_.isEmpty(data)) {
      const customerAssessment = new CustomerAssessment(
        data[0]['ca.indskr_customerassessmentid'],
        data[0]['ca.indskr_template'],
        data[0]['ca.indskr_entity'],
        data[0]['ca.indskr_name'],
        data[0]['ca.indskr_entityid'],
        data[0]['ca.indskr_assessmentdate'],
        new Date().toISOString().toString(),
        [],
      );
      for (let i = 0; i < data.length; i++) {
        for (let key in data[i]) {
          if (key.charAt(0) === "_" && key.endsWith("_value")) {
            const a = key.substring(1, key.indexOf("_value"));
            data[i][a] = data[i][key];
            delete data[i][key];
            delete data[key];
          }
        }
      }
      customerAssessment.responses = data;
      customerAssessment.pendingPushToDynamics = this.device.isOffline;
      await this.saveCustomerAssessmentInDB(customerAssessment);
      return this.filterResponses(customerAssessment);
    }
  }

  private filterResponses(customerAssessment: CustomerAssessment): CustomerAssessment {
    const filteredResponses = [];
    const myPositionIds: string[] = this.authService.user.positions.filter(p => (p.status == 100000000 || p.status == 0)).map(posi => posi.ID);
    const myPositionGroupIds: string[] = this.authService.user.positionGroups.map(posi => posi.positionGroupId);
    const myTAs: string[] = this.therapeuticService.therapeuticAreas.map(ta => ta.therapeuticareaId);
    const myProductIds: string[] = this.products.map(brand => brand.ID);
    const isOfflineMode: boolean = this.device.isOffline;
    let sectionRespOwnerList: any[] = [];
    if(isOfflineMode) {
      sectionRespOwnerList = customerAssessment.responses.map(ca => { return { responseId: ca['indskr_customerassessmentresponseid'], ownerId: ca['ownerid'] || ca['_ownerid_value'] }});
    }

    customerAssessment.responses.forEach(resp => {
      if (resp.hasOwnProperty("indskr_product") || resp.hasOwnProperty("indskr_therapeuticarea") || resp.hasOwnProperty("indskr_position") || resp.hasOwnProperty("indskr_positiongroup")) {
        if (resp.hasOwnProperty("indskr_therapeuticarea") && myTAs.includes(resp.indskr_therapeuticarea) && !resp.indskr_product && !resp.indskr_position && !resp.indskr_positiongroup) {
          //TA only
          filteredResponses.push(resp);
          // removed user id check based on OMNI-34760
          // if(!isOfflineMode && resp["owninguser"] == this.authService.user.systemUserID) {
          //   filteredResponses.push(resp);
          // }else if(isOfflineMode && !_.isEmpty(sectionRespOwnerList)) {
          //   const foundSectionResp = sectionRespOwnerList.find(p => p.responseId == resp['indskr_customerassessmentresponseid'] && !_.isEmpty(p.ownerId));
          //   if(foundSectionResp) {
          //     if(foundSectionResp.ownerId == this.authService.user.systemUserID) {
          //       filteredResponses.push(resp);
          //     }else {
          //       console.log("no matching ownerId in therapeutic area");
          //     }
          //   }else {
          //     //This is the first time adding value in offline mode.
          //     filteredResponses.push(resp);
          //   }
          // }
        } else if (resp.hasOwnProperty("indskr_position") && myPositionIds.includes(resp.indskr_position) && !resp.indskr_product && !resp.indskr_positiongroup && !resp.indskr_therapeuticarea && (!resp.hasOwnProperty("indskr_position") || myPositionIds.includes(resp.indskr_position))) {
          //Position only
          filteredResponses.push(resp);
        } else if (resp.hasOwnProperty("indskr_positiongroup") && myPositionGroupIds.includes(resp.indskr_positiongroup) && !resp.indskr_product && !resp.indskr_position && !resp.indskr_therapeuticarea && (!resp.hasOwnProperty("indskr_positiongroup") || myPositionGroupIds.includes(resp.indskr_positiongroup))) {
          //PositionGroup only
          filteredResponses.push(resp);
        } else if (resp.hasOwnProperty("indskr_product") && myProductIds.includes(resp.indskr_product) && !resp.indskr_therapeuticarea && ((!resp.hasOwnProperty("indskr_position") || !resp["indskr_position"]) && (!resp.hasOwnProperty("indskr_positiongroup") || !resp["indskr_positiongroup"]))) {
          // Product Only
          // product might or might not be mapped to the position/positiongroup.
          //set value in offline which does not have statecode.
          filteredResponses.push(resp);
        } else if (resp.hasOwnProperty("indskr_product") && myProductIds.includes(resp.indskr_product) && !resp.indskr_therapeuticarea && !resp.indskr_positiongroup && resp.hasOwnProperty("indskr_position") && myPositionIds.includes(resp.indskr_position)) {
          //Product and Position
          filteredResponses.push(resp);
        } else if (resp.hasOwnProperty("indskr_product") && myProductIds.includes(resp.indskr_product) && !resp.indskr_therapeuticarea && !resp.indskr_position && resp.hasOwnProperty("indskr_positiongroup") && myPositionGroupIds.includes(resp.indskr_positiongroup)) {
          //Product and PositionGroup
          filteredResponses.push(resp);
        } else if (resp.hasOwnProperty("indskr_product") && myProductIds.includes(resp.indskr_product) && !resp.indskr_position && !resp.indskr_positiongroup && resp.hasOwnProperty("indskr_therapeuticarea") && myTAs.includes(resp.indskr_therapeuticarea)) {
          //Product and TA
          filteredResponses.push(resp);
        } else if (resp.hasOwnProperty("indskr_position") && myPositionIds.includes(resp.indskr_position) && !resp.indskr_product && !resp.indskr_positiongroup && resp.hasOwnProperty("indskr_therapeuticarea") && myTAs.includes(resp.indskr_therapeuticarea)) {
          //Position and TA
          filteredResponses.push(resp);
        }  else if (resp.hasOwnProperty("indskr_positiongroup") && myPositionGroupIds.includes(resp.indskr_positiongroup) && !resp.indskr_product && !resp.indskr_position && resp.hasOwnProperty("indskr_therapeuticarea") && myTAs.includes(resp.indskr_therapeuticarea)) {
          //PositionGroup and TA
          filteredResponses.push(resp);
        } else if (!resp.indskr_positiongroup && resp.hasOwnProperty("indskr_position") && myPositionIds.includes(resp.indskr_position) && resp.hasOwnProperty("indskr_product") && myProductIds.includes(resp.indskr_product) && resp.hasOwnProperty("indskr_therapeuticarea") && myTAs.includes(resp.indskr_therapeuticarea)) {
          //Position and Product and TA
          filteredResponses.push(resp);
        } else if (!resp.indskr_position && resp.hasOwnProperty("indskr_positiongroup") && myPositionGroupIds.includes(resp.indskr_positiongroup) && resp.hasOwnProperty("indskr_product") && myProductIds.includes(resp.indskr_product) && resp.hasOwnProperty("indskr_therapeuticarea") && myTAs.includes(resp.indskr_therapeuticarea)) {
          //PositionGroup and Product and TA
          filteredResponses.push(resp);
        } else if (isOfflineMode && !resp.hasOwnProperty("statecode")) {
          //set value in offline which does not have statecode.
          filteredResponses.push(resp);
        } 
      } else {
        //Shares attributes  OR Procedure section customer assessment response
        filteredResponses.push(resp);
      }
    });
    customerAssessment.responses = filteredResponses;
    return customerAssessment;
  }

  private async saveCustomerAssessmentInDB(contactAssessment: CustomerAssessment) {
    contactAssessment._id = DB_KEY_PREFIXES.CUSTOMER_ASSESSMENT + contactAssessment.indskr_entityid;
    await this.disk.updateOrInsert(contactAssessment._id, () => { return contactAssessment; }).catch(err => console.log("Failed to saveCustomerAssessmentInDB ", err))
  }
  //the offline data is managed separtely to upload the offline data when switching to the online mode
  private async saveOfflineContactAssessmentInDB(contactAssessment: CustomerAssessment) {
    //random id is used to add multiple offline data created within the same assessment id
    if(!_.isEmpty(contactAssessment) && contactAssessment.responses && contactAssessment.responses.length > 0) {
      contactAssessment._id = DB_KEY_PREFIXES.OFFLINE_CONTACT_ASSESSMENT + Math.random().toString(36).substring(7);
      await this.disk.updateOrInsert(contactAssessment._id, () => {
        console.log("saved offline contact assessment in DB");
        return contactAssessment; 
      }).catch(err => console.log("Failed to save offline contact assessment in DB ", err))
    }
  }
  private async saveOfflineAccountAssessmentInDB(accountAssessment: CustomerAssessment) {
    //random id is used to add multiple offline data created within the same assessment id
    if(!_.isEmpty(accountAssessment) && accountAssessment.responses && accountAssessment.responses.length > 0) {
      accountAssessment._id = DB_KEY_PREFIXES.OFFLINE_ACCOUNT_ASSESSMENT + Math.random().toString(36).substring(7);
      await this.disk.updateOrInsert(accountAssessment._id, () => {
        console.log("saved offline account assessment in DB");
        return accountAssessment; 
      }).catch(err => console.log("Failed to save offline account assessment in DB ", err))
    }
  }

  private async loadOfflineContactAssessmentInDB() {
    let option = {
      selector: {
          '_id': {
              $gte: DB_KEY_PREFIXES.OFFLINE_CONTACT_ASSESSMENT,
              $lte: DB_KEY_PREFIXES.OFFLINE_CONTACT_ASSESSMENT + PREFIX_SEARCH_ENDKEY_UNICODE
          },
          'pendingPushToDynamics': { $eq: true }
      }
    };
    return await this.disk.find(option);
  }

  private async loadOfflineAccountAssessmentInDB() {
    let option = {
      selector: {
          '_id': {
              $gte: DB_KEY_PREFIXES.OFFLINE_ACCOUNT_ASSESSMENT,
              $lte: DB_KEY_PREFIXES.OFFLINE_ACCOUNT_ASSESSMENT + PREFIX_SEARCH_ENDKEY_UNICODE
          },
          'pendingPushToDynamics': { $eq: true }
      }
    };
    return await this.disk.find(option);
  }

  // private async saveAllContactAssessmentInDB(allContactAssessments: CustomerAssessment[], isInitialSync: boolean) {
  //   const id = DB_KEY_PREFIXES.ALL_CONTACT_ASSESSMENT;
  //   if(!_.isEmpty(allContactAssessments) && allContactAssessments.length > 0) {
  //     try {
  //       let assessments = [];
  //       if(isInitialSync) {
  //         assessments = _.cloneDeep(allContactAssessments);
  //       }else {
  //         assessments = _.cloneDeep(this.allContactAssessments);
  //       }
  //       if(!isInitialSync && !_.isEmpty(assessments)) {
  //         allContactAssessments.forEach(assess => {
  //           let idx = assessments.findIndex(asmt => asmt.indskr_customerassessmentresponseid === assess['indskr_customerassessmentresponseid']);
  //           if (idx > -1) {
  //             assessments[idx] = assess;
  //           } else {
  //             assessments.push(assess)
  //           }
  //         });

  //       }
  //       await this.disk.updateOrInsert(id, (doc) => { 
  //         if(!doc || !doc.raw) {
  //           doc= {
  //             raw:[]
  //           }
  //         }
  //         doc.raw = assessments;
  //         return doc;
  //       });
  //     } catch (error) {
  //       console.log("Error saving all contact assessment in DB", error);
  //     }
  //   }
  // }

  private async saveAllAccountAssessmentInDB(allAccountAssessments: CustomerAssessment[]) {
    const id = DB_KEY_PREFIXES.ALL_ACCOUNT_ASSESSMENT;
    if(!_.isEmpty(allAccountAssessments) && allAccountAssessments.length > 0) {
      try {
        await this.disk.updateOrInsert(id, (doc) => { 
          if(!doc || !doc.raw) {
            doc= {
              raw:[]
            }
          }
          doc.raw = allAccountAssessments;
          return doc;
        });
      } catch (error) {
        console.log("Error saving all account assessment in DB", error);
      }
    }
  }

  public async getCustomerAssessmentFromDB(entityid: string) {
    const id = DB_KEY_PREFIXES.CUSTOMER_ASSESSMENT + entityid;
    const customerAssessment = await this.disk.retrieve(id, true);
    return customerAssessment ? this.filterResponses(customerAssessment) : null;
  }

  public async getCustomerAssessmentForSearchFromDB(isContactEntity: boolean) {
    const id = isContactEntity ? DB_KEY_PREFIXES.CUSTOMER_ASSESSMENT_FOR_SEARCH : DB_KEY_PREFIXES.ACCOUNT_ASSESSMENT_FOR_SEARCH;
    const customerAssessment = await this.disk.retrieve(id, true);
    return customerAssessment;
  }

  public async deleteCustomerAssessments(deletedTemplateIds: string[], clearAll: boolean = false) {
    if (clearAll) {
      await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUST_ASSESSMENTS);
    } else {
      const deletedCustomerAssessments = [];
      const customerAssessments: CustomerAssessment[] = await this.getAllCustomerAssessments();
      customerAssessments.forEach(customerAssessment => {
        if (deletedTemplateIds.includes(customerAssessment.indskr_template)) {
          deletedCustomerAssessments.push({ _id: customerAssessment._id, _rev: customerAssessment._rev, _deleted: true });
        }
      });
      if (_.isEmpty(deletedCustomerAssessments)) {
        await this.disk.bulk(deletedCustomerAssessments);
      }
    }
  }

  private async getAllCustomerAssessments(): Promise<CustomerAssessment[]> {
    return await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUST_ASSESSMENTS).then((data: CustomerAssessment[]) => {
      return data;
    });
  }

  public discardChagesForAssess(): Promise<Boolean> {
    let cancelText = this.translate.instant("CANCEL");
    if(this.translate.currentLang == 'it') {
      cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
    }
    return new Promise<Boolean>(resolve => {
      this.alertService.showAlert({
        title: this.translate.instant('DISCARD_CHANGES'),
        message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')
      }, this.translate.instant('DISCARD'), cancelText
      ).then(res => {
        if (res.role == "ok") {
          this.isAssessmentDirty = false;
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  public _validateMandatoryField(id, value, event, currentFormView, sectionId?): boolean {
    let fieldValue;
    if (event.value != null) {
      fieldValue = event.value;
    } else if (event.target) {
      fieldValue = event.target.value;
    }
    const formViewList = currentFormView;
    if (fieldValue === null || fieldValue === undefined) {
      formViewList.forEach(formView => {
        if (sectionId) {
          if(formView.id == id && formView.sectionId == sectionId) {
            formView.view.isInvalid = true;
            formView.view.isEmptyRequiredField = true;
          }
        } else if (formView.id == id) {
          formView.view.isInvalid = true;
          formView.view.isEmptyRequiredField = true;
        }
      })
      return false;
    } else {
      formViewList.forEach(formView => {
        if (sectionId) {
          if(formView.id == id && formView.sectionId == sectionId)formView.view.isInvalid = false;
        } else if (formView.id == id) formView.view.isInvalid = false;
      })
      return true;
    }
  }

  /**
   * if section is of type product: always product is displayed in Section name as it is multiselect
   * if section is of type Position only: always Position is displayed in Section name as it is multiselect
   * if section is of type Therapeutic Area only: always Therapeutic Area is displayed in Section name as it is multiselect
   */
  public getSelectionTemplate(selectedSection, masterData, controls, primarySpecialty: Specialty = null) {
    let sectionTemplate;
    let secondaryText: string = null;

    if (selectedSection.procedureId) {
      //Procedure section
      sectionTemplate = {
        id: selectedSection.procedureId,
        name: masterData.procedures?.find(procedure => procedure.ID == selectedSection.procedureId)?.name,
        controls: controls,
        secondaryText: primarySpecialty?.name
      }
    } else if (selectedSection.productId) {
      //Product section
      if (selectedSection.positionId) {
        //Product and Position
        secondaryText = masterData.positions?.find(pos => pos.ID == selectedSection.positionId)?.name;
      }
      if (selectedSection.taId) {
        //Product and TA section, Product and TA and Position
        const taName = masterData.ta?.find(prod => prod.therapeuticareaId == selectedSection.taId)?.name;
        secondaryText = [secondaryText, taName].filter(Boolean).join(", ");
      }
      if (selectedSection.positionGroupId) {
        //Product and TA section, Product and TA and PositionGroup
        const pGName = masterData.positionGroups?.find(pos => pos.positionGroupId == selectedSection.positionGroupId)?.positionGroupName;
        secondaryText = [secondaryText, pGName].filter(Boolean).join(", ");
      }
      sectionTemplate = {
        id: selectedSection.productId,
        name: masterData.products?.find(prod => prod.ID == selectedSection.productId)?.name,
        controls: controls,
        secondaryText: secondaryText
      }

    } else if (selectedSection.positionId) {
      //Positon section
      if (selectedSection.taId) {
        //Position and TA section
        const taName = masterData.ta?.find(prod => prod.therapeuticareaId == selectedSection.taId)?.name;
        secondaryText = [secondaryText, taName].filter(Boolean).join(", ");
      }
      sectionTemplate = {
        id: selectedSection.positionId,
        name: masterData.positions?.find(pos => pos.ID == selectedSection.positionId)?.name,
        controls: controls,
        secondaryText: secondaryText
      }

    } else if (selectedSection.positionGroupId) {
      //PositonGroup section
      if (selectedSection.taId) {
        //PositionGroup and TA section
        const taName = masterData.ta?.find(prod => prod.therapeuticareaId == selectedSection.taId)?.name;
        secondaryText = [secondaryText, taName].filter(Boolean).join(", ");
      }
      sectionTemplate = {
        id: selectedSection.positionGroupId,
        name: masterData.positionGroups?.find(pos => pos.positionGroupId == selectedSection.positionGroupId)?.positionGroupName,
        controls: controls,
        secondaryText: secondaryText
      }

    } else if (selectedSection.taId) {
      //TA section
      sectionTemplate = {
        id: selectedSection.taId,
        name: masterData.ta?.find(prod => prod.therapeuticareaId == selectedSection.taId)?.name,
        controls: controls
      }
    }
    return sectionTemplate;
  }

  public getLabelsForSection(selectedSection, masterData, controls, primarySpecialty: Specialty = null, columnSubID) {
    switch (columnSubID) {
      case 'Procedure':
        if (selectedSection.procedureId) {
          return { 
            id: selectedSection.procedureId,
            label: masterData.procedures?.find(procedure => procedure.ID == selectedSection.procedureId)?.name,
          }
        }
        break;
      case 'TherapetuicArea':
        if (selectedSection.taId) {
          return {
            id: selectedSection.taId,
            label: masterData.ta?.find(prod => prod.therapeuticareaId == selectedSection.taId)?.name
          }
        }
        break;
      case 'Position':
        if (selectedSection.positionId) {
          return {
            id: selectedSection.positionId,
            label: masterData.positions?.find(pos => pos.ID == selectedSection.positionId)?.name
          }
        }
        break;
      case 'PositionGroup':
        if (selectedSection.positionGroupId) {
          return {
            id: selectedSection.positionGroupId,
            label: masterData.positionGroups?.find(pos => pos.positionGroupId == selectedSection.positionGroupId)?.name
          }
        }
        break;
      case 'Product':
        if (selectedSection.productId) {
          return {
            id: selectedSection.productId,
            label: masterData.products?.find(prod => prod.ID == selectedSection.productId)?.name
          }
        }
        break;
      default:
        return null;
    }
    return null;
  }

  public getLabelsForSectionTeamview(selectedSection, masterData, controls, primarySpecialty: Specialty = null, columnSubID) {
    switch (columnSubID) {
      case 'Procedure':
        if (selectedSection.procedureId) {
          return { 
            id: selectedSection.procedureId,
            label: masterData.procedures?.find(procedure => procedure.ID == selectedSection.procedureId)?.name,
          }
        }
        break;
      case 'TherapetuicArea':
        if (selectedSection.taId) {
          return {
            id: selectedSection.taId,
            label: masterData.ta?.find(prod => prod.ID == selectedSection.taId)?.name
          }
        }
        break;
      case 'Position':
        if (selectedSection.positionId) {
          return {
            id: selectedSection.positionId,
            label: masterData.positions?.find(pos => pos.ID == selectedSection.positionId)?.name
          }
        }
        break;
      case 'PositionGroup':
        if (selectedSection.positionGroupId) {
          return {
            id: selectedSection.positionGroupId,
            label: masterData.positionGroups?.find(pos => pos.ID == selectedSection.positionGroupId)?.name
          }
        }
        break;
      case 'Product':
        if (selectedSection.productId) {
          return {
            id: selectedSection.productId,
            label: masterData.products?.find(prod => prod.ID == selectedSection.productId)?.name
          }
        }
        break;
      default:
        return null;
    }
    return null;
  }

  private mapConfiguredSearchIndexConfig(templates) {
    if (!this.authService.hasFeatureAction(FeatureActionsMap.ASSESSMENT_ADVANCED_SEARCH, true)) return;
    if (!this.searchConfigService.isConfigInitiated) {
      this.searchConfigService.initSearchConfigs();
      this.searchConfigService.isConfigInitiated = true;
      this.searchConfigService.configUpdateRequired = true;
    }
    this.searchConfigService.contactConfiguredSearchIndexConfig = this.searchConfigService.contactConfiguredSearchIndexConfig.filter(config => config['controlDataType'] != 'Assess');
    this.searchConfigService.accountConfiguredSearchIndexConfig = this.searchConfigService.accountConfiguredSearchIndexConfig.filter(config => config['controlDataType'] != 'Assess');
    if (templates && templates.length > 0) {
      templates.forEach(template => {
        if (template['indskr_entity']) {
          let indexConfig = template['indskr_entity'] == 'contact' ? this.searchConfigService.contactConfiguredSearchIndexConfig : this.searchConfigService.accountConfiguredSearchIndexConfig;
          template.indskr_metadata.Sections.forEach(section => {
            if (section.Controls && section.Controls.length > 0) {
              section.Controls.forEach(control => {
                let searchCategory;
                // Advance Search is only availabe if the Attribute is set as Searchable AND the type is Choice/Flag
                if (control.Searchable && (control.Type == AssessmentAttributeType.CHOICE || control.Type == AssessmentAttributeType.FLAG)) {
                  let choiceOptions;
                  let choiceOptionsInEnglish = [];
                  let searchAttributes = [];
                  if (control.Type == AssessmentAttributeType.CHOICE && control.Choice && control.Choice.Options && control.Choice.Options.length > 0) {

                    choiceOptions = control.Choice.Options.map(option => {

                      if (option.displayNames && !_.isEmpty(option.displayNames)) {
                        const displayName = option.displayNames.find(value => value.LanguageCode == this.localizationService.selectedLanguage.localeID);
                        if (displayName) {
                          return displayName.Description;
                        } else {
                          return option['Label'];
                        }
                      } else {
                        return option['Label'];
                      }
                    });

                    choiceOptionsInEnglish = control.Choice.Options.map(option => {
                      if (option['Label']) {
                        return option['Label'];
                      }
                    });
                  }
                  if (control.Type == AssessmentAttributeType.FLAG) searchAttributes.push('indskr_flag_Formatted');
                  else searchAttributes.push('indskr_choicelabel');

                  let displayName = control.DisplayName;

                  if (control.DisplayNames && !_.isEmpty(control.DisplayNames)) {
                    const langSpecificDisplayname = control.DisplayNames.find(value => value.LanguageCode == this.localizationService.selectedLanguage.localeID);
                    if (langSpecificDisplayname) {
                      displayName = langSpecificDisplayname.Description;
                    }
                  }
                  searchCategory = {
                    categoryName: displayName,
                    categoryNameInEng: control.DisplayName,
                    categoryRelativePath: control.Type == AssessmentAttributeType.FLAG ? 'indskr_flag_Formatted' : 'indskr_choicelabel',
                    controlDataType: 'Assess',
                    values: control.Type == AssessmentAttributeType.FLAG ? [this.translate.instant('YES'), this.translate.instant('NO')] : choiceOptions,
                    optionValuesInEng: control.Type == AssessmentAttributeType.FLAG ? ['Yes', 'No'] : choiceOptionsInEnglish,
                    isBooleanTypeCategory: control.Type == AssessmentAttributeType.FLAG ? true : false,
                    configuredFrom: 'Assess',
                    categoryDisplayName: displayName,
                    filterOrder: "c1",
                    isFixedFilter: true,
                    isForcedHide: true,
                    isSubCategory: true,
                  };
                  if (indexConfig && indexConfig.length > 0) {
                    let idx = indexConfig.findIndex(config => config.categoryName.includes(control.DisplayName));
                    if (idx > -1) indexConfig[idx] = searchCategory;
                    else indexConfig.push(searchCategory);
                  } else {
                    indexConfig.push(searchCategory);
                  }
                }
              })
            }
          })
        }
      })
    }
  }

  private async saveCustomerAssessmentForSearch(data, isInitialSync, offlineDocs, isContactEntity: boolean): Promise<CustomerAssessment[]> {
    if (this.searchConfigService.configUpdateRequired) {
      this.searchConfigService.updateSearchConfigsForSelectedLanguage();
      this.searchConfigService.configUpdateRequired = false;
    }
    let assessments = [];
    if (!isInitialSync && offlineDocs && !_.isEmpty(offlineDocs)) {
      if(Array.isArray(offlineDocs)) {
        offlineDocs.forEach(o=> {
          if(!_.isEmpty(o) && !_.isEmpty(o.raw)) {
            assessments.push(...o.raw);
          }
        });
      } else {
        assessments = _.cloneDeep(offlineDocs.raw);
      }
    }
    if (!_.isEmpty(data)) {
      if (!isInitialSync) {
        data.forEach(assess => {
          let idx = assessments.findIndex(asmt => asmt.indskr_customerassessmentresponseid === assess['indskr_customerassessmentresponseid']);
          if (idx > -1) {
            assessments[idx] = assess;
          } else {
            assessments.push(assess)
          }
        });
      } else {
        assessments = data;
      }
    }
    const targetKey = isContactEntity ? DB_KEY_PREFIXES.CUSTOMER_ASSESSMENT_FOR_SEARCH : DB_KEY_PREFIXES.ACCOUNT_ASSESSMENT_FOR_SEARCH
    await this.disk.updateOrInsert(targetKey, (doc) => {
      if (!doc || !doc.raw) {
        doc = {
          raw: []
        }
      }
      doc.raw = assessments;
      if(isContactEntity) {
        this.isInitialMappingDoneForSearch = false;
      }else {
        this.isInitialMappingDoneForSearchAccount = false;
      }
      return doc;
    }).catch(err => console.log("Failed to saveCustomerAssessmentInDB ", err))
    return assessments;
  }

  private async mapCustomerAssessmentForSearch(targetEntity: EntityOptions) {
    const isContactEntity: boolean = targetEntity == EntityOptions.CONTACT;
    const targetKey: string = isContactEntity ? DB_KEY_PREFIXES.CUSTOMER_ASSESSMENT_FOR_SEARCH : DB_KEY_PREFIXES.ACCOUNT_ASSESSMENT_FOR_SEARCH;
    let offlineAssessForSearch = await this.disk.retrieve(targetKey);
    let validAssesments: Array<any> = [];
    let assessments: Array<any> = [];
    if (offlineAssessForSearch && offlineAssessForSearch.raw) {
      assessments = offlineAssessForSearch.raw;
      assessments = this.filterResponseForSearch(assessments);
    }
    this.assessmentTemplates.forEach(template => {
      let templateId = template.indskr_assessmenttemplateid;
      if (template.indskr_metadata && template.indskr_metadata.Sections && template.indskr_metadata.Sections.length > 0) {
        template.indskr_metadata.Sections.forEach(section => {
          this.validateResponse(templateId, section, assessments, validAssesments);
        })
      }
    });
    const assessForSearchSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.customerassessmentresponse,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    await this.disk.updateOrInsert(targetKey, (doc) => {
      if (!doc || !doc.raw) {
        doc = {
          raw: []
        }
      }
      doc.raw = validAssesments;
      if (Array.isArray(validAssesments)) assessForSearchSyncInfo.totalSynced = validAssesments.length;
      this.deltaService.addEntitySyncInfo(assessForSearchSyncInfo);
      if(isContactEntity) {
        this.isInitialMappingDoneForSearch = true;
      }else {
        this.isInitialMappingDoneForSearchAccount = true;
      }
      return doc;
    }).catch(err => {
      console.log("Failed to saveCustomerAssessmentInDB ", err);
      this.deltaService.addSyncErrorToEntitySyncInfo(assessForSearchSyncInfo, 'indskr_customerassessmentresponse', err);
      this.deltaService.addEntitySyncInfo(assessForSearchSyncInfo);
    })
  }

  private filterResponseForSearch(assessments) {
    const filteredResponses = [];
    const myPositionIds: string[] = this.authService.user.positions.filter(p => (p.status == 100000000 || p.status == 0)).map(posi => posi.ID);
    const myTAs: string[] = this.therapeuticService.therapeuticAreas.map(ta => ta.therapeuticareaId);
    const myProductIds: string[] = this.products.map(brand => brand.ID);

    assessments.forEach(asmt => {
      if (asmt.hasOwnProperty("_indskr_product_value") || asmt.hasOwnProperty("_indskr_therapeuticarea_value") || asmt.hasOwnProperty("_indskr_position_value")) {
        if (asmt.hasOwnProperty("_indskr_therapeuticarea_value") && myTAs.includes(asmt['_indskr_therapeuticarea_value']) && !asmt['_indskr_product_value'] && !asmt['_indskr_position_value'] && asmt["_owninguser_value"] == this.authService.user.systemUserID) {
          //TA only
          filteredResponses.push(asmt);
        } else if (asmt.hasOwnProperty("_indskr_position_value") && myPositionIds.includes(asmt['_indskr_position_value']) && !asmt['_indskr_product_value'] && !asmt['_indskr_therapeuticarea_value']) {
          //Position only
          filteredResponses.push(asmt);
        } else if (asmt.hasOwnProperty("_indskr_product_value") && myProductIds.includes(asmt['_indskr_product_value']) && !asmt['_indskr_therapeuticarea_value'] && asmt.hasOwnProperty("_indskr_position_value") && myPositionIds.includes(asmt['_indskr_position_value'])) {
          //Product and Position
          filteredResponses.push(asmt);
        } else if (asmt.hasOwnProperty("_indskr_position_value") && myPositionIds.includes(asmt['_indskr_position_value']) && !asmt['_indskr_product_value'] && asmt.hasOwnProperty("_indskr_therapeuticarea_value") && myTAs.includes(asmt['_indskr_therapeuticarea_value'])) {
          //Position and TA
          filteredResponses.push(asmt);
        } else if (asmt.hasOwnProperty("_indskr_position_value") && myPositionIds.includes(asmt['_indskr_position_value']) && asmt.hasOwnProperty("_indskr_product_value") && myProductIds.includes(asmt['_indskr_product_value']) && asmt.hasOwnProperty("_indskr_therapeuticarea_value") && myTAs.includes(asmt['_indskr_therapeuticarea_value'])) {
          //Position and Product and TA
          filteredResponses.push(asmt);
        }
      } else {
        //Shares attributes customer assessment response
        filteredResponses.push(asmt);
      }
    });
    assessments = filteredResponses;
    return assessments;
  }

  private validateResponse(templateId, section: AttributeSection, assessments, validAssessments) {
    assessments.forEach(asmt => {
      if (templateId == asmt['ca.indskr_template']) {
        if (section.Position || section.PositionGroup || section.TherapetuicArea || section.Product) {
          if (section.Position && !section.TherapetuicArea && !section.Product && !section.PositionGroup) {
            //Position only
            if (asmt['_indskr_position_value'] && !asmt['_indskr_positiongroup_value'] && !asmt['_indskr_therapeuticarea_value'] && !asmt['_indskr_product_value']) {
              validAssessments.push(asmt);
            }
          } else if (!section.Position && !section.TherapetuicArea && !section.Product && section.PositionGroup) {
            //PositionGroup only
            if (!asmt['_indskr_position_value'] && asmt['_indskr_positiongroup_value'] && !asmt['_indskr_therapeuticarea_value'] && !asmt['_indskr_product_value']) {
              validAssessments.push(asmt);
            }
          } else if (!section.Position && !section.PositionGroup && section.TherapetuicArea && !section.Product) {
            //TA only
            if (!asmt['_indskr_position_value'] && !asmt['_indskr_positiongroup_value'] && asmt['_indskr_therapeuticarea_value'] && !asmt['_indskr_product_value']) {
              validAssessments.push(asmt);
            }
          } else if (!section.PositionGroup && !section.TherapetuicArea && section.Product) {
            //Product and Position, Positon only
            if (asmt['_indskr_position_value'] && !asmt['_indskr_positiongroup_value'] && !asmt['_indskr_therapeuticarea_value'] && asmt['_indskr_product_value']) {
              validAssessments.push(asmt);
            }
          } else if (!section.PositionGroup && section.Position && section.TherapetuicArea && !section.Product) {
            //Position and TA
            if (asmt['_indskr_position_value'] && !asmt['_indskr_positiongroup_value'] && asmt['_indskr_therapeuticarea_value'] && !asmt['_indskr_product_value']) {
              validAssessments.push(asmt);
            }
          } else if (!section.PositionGroup && section.TherapetuicArea && section.Product) {
            //Position and TA  and Position, Product and TA
            if (!asmt['_indskr_positiongroup_value'] && asmt['_indskr_position_value'] && asmt['_indskr_therapeuticarea_value'] && asmt['_indskr_product_value']) {
              validAssessments.push(asmt);
            }
          }
        } else {
          if (!asmt['_indskr_positiongroup_value'] && !asmt['_indskr_position_value'] && !asmt['_indskr_therapeuticarea_value'] && !asmt['_indskr_product_value']) {
            validAssessments.push(asmt);
          }
        }
      }
    })
    return validAssessments;
  }

  public async uploadOfflineContactAssessments(isPartialUpload = false, maxRecordCountForPartialUpload = 10) {
    if (this.device.isOffline) return;
    let option = {
      selector: {
          '_id': {
              $gte: DB_KEY_PREFIXES.OFFLINE_CONTACT_ASSESSMENT,
              $lte: DB_KEY_PREFIXES.OFFLINE_CONTACT_ASSESSMENT + PREFIX_SEARCH_ENDKEY_UNICODE
          },
          'pendingPushToDynamics': { $eq: true }
      }
    };
    const contactAssessmentSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.contactAssessment,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    let rawContactAssessments: CustomerAssessment[] = [];
    try {
      rawContactAssessments = await this.disk.find(option);
      if(!_.isEmpty(rawContactAssessments)){
        rawContactAssessments.sort((a, b) => {
          if(a.modifiedon < b.modifiedon) return -1
          else return 1
        });
        if(isPartialUpload && rawContactAssessments.length > maxRecordCountForPartialUpload) {
          rawContactAssessments = rawContactAssessments.splice(0, maxRecordCountForPartialUpload);
        }
        // const payload = rawContactAssessments;
        const payloadUnAssessedData = rawContactAssessments.filter(r => r.isInitialAssessInOffline);
        const payloadAssessedData = rawContactAssessments.filter(r => !r.isInitialAssessInOffline);
        await this.customerAssessDataService.uploadOfflineAssessment(payloadUnAssessedData).then((res)=> {
          if(!_.isEmpty(res)) {
            for(let i=0; i<res.length; i++) {
              const data = res[i];
              const customerAssessmentId = data['indskr_customerassessmentid'];
              const index = rawContactAssessments.findIndex(ca => ca.pendingPushToDynamics && ca.indskr_customerassessmentid === customerAssessmentId);
              if(index > -1) {
                if(data['errorId']) {
                  contactAssessmentSyncInfo.totalFailed++;
                }
                else if(data['indskr_customerassessmentid'] && !data['errorId']) {
                  rawContactAssessments[index].pendingPushToDynamics = false;
                  this.disk.remove(rawContactAssessments[index]._id);
                  contactAssessmentSyncInfo.totalSynced++;
                }
              }
            }
            // Track offline data count
            const uploadedCnt = contactAssessmentSyncInfo.totalSynced;
            console.log("Uploaded offline unassessed contact assessments: ", uploadedCnt);
            const pendingData = rawContactAssessments.filter(customerAssessment => customerAssessment.pendingPushToDynamics);
            console.log("Pending offline unassessed contact assessments: ", pendingData.length);
            this.disk.subtractOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_ASSESSMENT, uploadedCnt ? uploadedCnt : 0);
            this.deltaService.addEntitySyncInfo(contactAssessmentSyncInfo, true);
          }
        });
        await this.customerAssessDataService.uploadOfflineAssessment(payloadAssessedData).then((res)=> {
          if(!_.isEmpty(res)) {
            for(let i=0; i<res.length; i++) {
              const data = res[i];
              const customerAssessmentId = data['indskr_customerassessmentid'];
              const index = rawContactAssessments.findIndex(ca => ca.pendingPushToDynamics && ca.indskr_customerassessmentid === customerAssessmentId);
              if(index > -1) {
                if(data['errorId']) {
                  contactAssessmentSyncInfo.totalFailed++;
                }
                else if(data['indskr_customerassessmentid'] && !data['errorId']) {
                  rawContactAssessments[index].pendingPushToDynamics = false;
                  this.disk.remove(rawContactAssessments[index]._id);
                  contactAssessmentSyncInfo.totalSynced++;
                }
              }
            }
            // Track offline data count
            const uploadedCnt = contactAssessmentSyncInfo.totalSynced;
            console.log("Uploaded offline assessed contact assessments: ", uploadedCnt);
            const pendingData = rawContactAssessments.filter(customerAssessment => customerAssessment.pendingPushToDynamics);
            console.log("Pending offline assessed contact assessments: ", pendingData.length);
            this.disk.subtractOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_ASSESSMENT, uploadedCnt ? uploadedCnt : 0);
            this.deltaService.addEntitySyncInfo(contactAssessmentSyncInfo, true);
          }
        });
      }
    } catch (err) {
      console.error("Failed to upload offline contact assessments: ", err);
      contactAssessmentSyncInfo.totalFailed = rawContactAssessments?.length;
      this.deltaService.addSyncErrorToEntitySyncInfo(contactAssessmentSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.customerAssessment.UPLOAD_OFFLINE_ASSESSMENTS, err);
      this.deltaService.addEntitySyncInfo(contactAssessmentSyncInfo, true);
    }
  }

  public async uploadOfflineAccountAssessments(isPartialUpload = false, maxRecordCountForPartialUpload = 10) {
    if (this.device.isOffline) return;
    let option = {
      selector: {
          '_id': {
              $gte: DB_KEY_PREFIXES.OFFLINE_ACCOUNT_ASSESSMENT,
              $lte: DB_KEY_PREFIXES.OFFLINE_ACCOUNT_ASSESSMENT + PREFIX_SEARCH_ENDKEY_UNICODE
          },
          'pendingPushToDynamics': { $eq: true }
      }
    };
    const accountAssessmentSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.accountAssessment,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    let rawAccountAssessments: CustomerAssessment[] = [];
    try {
      rawAccountAssessments = await this.disk.find(option);
      if(!_.isEmpty(rawAccountAssessments)){
        rawAccountAssessments.sort((a, b) => {
          if(a.modifiedon < b.modifiedon) return -1
          else return 1
        });
        if(isPartialUpload && rawAccountAssessments.length > maxRecordCountForPartialUpload) {
          rawAccountAssessments = rawAccountAssessments.splice(0, maxRecordCountForPartialUpload);
        }
        // const payload = rawAccountAssessments;
        const payloadUnAssessedData = rawAccountAssessments.filter(r => r.isInitialAssessInOffline);
        const payloadAssessedData = rawAccountAssessments.filter(r => !r.isInitialAssessInOffline);
        await this.customerAssessDataService.uploadOfflineAssessment(payloadUnAssessedData).then((res)=> {
          if(!_.isEmpty(res)) {
            for(let i=0; i<res.length; i++) {
              const data = res[i];
              const customerAssessmentId = data['indskr_customerassessmentid'];
              const index = rawAccountAssessments.findIndex(ca => ca.pendingPushToDynamics && ca.indskr_customerassessmentid === customerAssessmentId);
              if(index > -1) {
                if(data['errorId']) {
                  accountAssessmentSyncInfo.totalFailed++;
                }
                else if(data['indskr_customerassessmentid'] && !data['errorId']) {
                  rawAccountAssessments[index].pendingPushToDynamics = false;
                  this.disk.remove(rawAccountAssessments[index]._id);
                  accountAssessmentSyncInfo.totalSynced++;
                }
              }
            }
            // Track offline data count
            const uploadedCnt = accountAssessmentSyncInfo.totalSynced;
            console.log("Uploaded offline unassessed account assessments: ", uploadedCnt);
            const pendingData = rawAccountAssessments.filter(customerAssessment => customerAssessment.pendingPushToDynamics);
            console.log("Pending offline unassessed account assessments: ", pendingData.length);
            this.disk.subtractOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ACCOUNT_ASSESSMENT, uploadedCnt ? uploadedCnt : 0);
            this.deltaService.addEntitySyncInfo(accountAssessmentSyncInfo, true);
          }
        });
        await this.customerAssessDataService.uploadOfflineAssessment(payloadAssessedData).then((res)=> {
          if(!_.isEmpty(res)) {
            for(let i=0; i<res.length; i++) {
              const data = res[i];
              const customerAssessmentId = data['indskr_customerassessmentid'];
              const index = rawAccountAssessments.findIndex(ca => ca.pendingPushToDynamics && ca.indskr_customerassessmentid === customerAssessmentId);
              if(index > -1) {
                if(data['errorId']) {
                  accountAssessmentSyncInfo.totalFailed++;
                }
                else if(data['indskr_customerassessmentid'] && !data['errorId']) {
                  rawAccountAssessments[index].pendingPushToDynamics = false;
                  this.disk.remove(rawAccountAssessments[index]._id);
                  accountAssessmentSyncInfo.totalSynced++;
                }
              }
            }
            // Track offline data count
            const uploadedCnt = accountAssessmentSyncInfo.totalSynced;
            console.log("Uploaded offline assessed account assessments: ", uploadedCnt);
            const pendingData = rawAccountAssessments.filter(customerAssessment => customerAssessment.pendingPushToDynamics);
            console.log("Pending offline assessed account assessments: ", pendingData.length);
            this.disk.subtractOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ACCOUNT_ASSESSMENT, uploadedCnt ? uploadedCnt : 0);
            this.deltaService.addEntitySyncInfo(accountAssessmentSyncInfo, true);
          }
        });
      }
    } catch (err) {
      console.error("Failed to upload offline account assessments: ", err);
      accountAssessmentSyncInfo.totalFailed = rawAccountAssessments?.length;
      this.deltaService.addSyncErrorToEntitySyncInfo(accountAssessmentSyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.customerAssessment.UPLOAD_OFFLINE_ASSESSMENTS, err);
      this.deltaService.addEntitySyncInfo(accountAssessmentSyncInfo, true);
    }
  }

  public getSectionHeader(sectionProperties) {
    let sectionheader = sectionProperties?.DisplayName || '';
    if (sectionProperties.hasOwnProperty('DisplayNames')) {
      const displayNames = sectionProperties.DisplayNames;
      if (!_.isEmpty(displayNames)) {
        const displayName = displayNames.find(value => value.LanguageCode == this.localizationService.selectedLanguage.localeID);
        if (displayName) {
          sectionheader = displayName.Description;
        } else {
          let foundTranslatedStr = this.localizationService.getTranslatedString(sectionheader);
          if(!_.isEmpty(foundTranslatedStr)) sectionheader = foundTranslatedStr;
        }
      } else {
        let foundTranslatedStr = this.localizationService.getTranslatedString(sectionheader);
        if(!_.isEmpty(foundTranslatedStr)) sectionheader = foundTranslatedStr;
      }
    }
    return sectionheader;
  }

  public getSelectionTemplateTeamview(selectedSection, masterData, controls, primarySpecialty: Specialty = null) {
    let sectionTemplate;
    let secondaryText: string = null;

    if (selectedSection.procedureId) {
      //Procedure section
      sectionTemplate = {
        id: selectedSection.procedureId,
        name: masterData.procedures.find(procedure => procedure.ID == selectedSection.procedureId)?.name,
        controls: controls,
        secondaryText: primarySpecialty?.name
      }
    } else if (selectedSection.productId) {
      //Product section
      if (selectedSection.positionId) {
        //Product and Position
        secondaryText = masterData.positions.find(pos => pos.ID == selectedSection.positionId)?.name;
      }
      if (selectedSection.taId) {
        //Product and TA section, Product and TA and Position
        const taName = masterData.ta.find(prod => prod.ID == selectedSection.taId)?.name;
        secondaryText = [secondaryText, taName].filter(Boolean).join(", ");
      }
      if (selectedSection.positionGroupId) {
        //Product and TA section, Product and TA and PositionGroup
        const pGName = masterData.positionGroups.find(pos => pos.ID == selectedSection.positionGroupId)?.name;
        secondaryText = [secondaryText, pGName].filter(Boolean).join(", ");
      }
      sectionTemplate = {
        id: selectedSection.productId,
        name: masterData.products.find(prod => prod.ID == selectedSection.productId)?.name,
        controls: controls,
        secondaryText: secondaryText
      }

    } else if (selectedSection.positionId) {
      //Positon section
      if (selectedSection.taId) {
        //Position and TA section
        const taName = masterData.ta.find(prod => prod.ID == selectedSection.taId)?.name;
        secondaryText = [secondaryText, taName].filter(Boolean).join(", ");
      }
      sectionTemplate = {
        id: selectedSection.positionId,
        name: masterData.positions.find(pos => pos.ID == selectedSection.positionId)?.name,
        controls: controls,
        secondaryText: secondaryText
      }

    } else if (selectedSection.positionGroupId) {
      //PositonGroup section
      if (selectedSection.taId) {
        //PositionGroup and TA section
        const taName = masterData.ta.find(prod => prod.ID == selectedSection.taId)?.name;
        secondaryText = [secondaryText, taName].filter(Boolean).join(", ");
      }
      sectionTemplate = {
        id: selectedSection.positionGroupId,
        name: masterData.positionGroups.find(pos => pos.ID == selectedSection.positionGroupId)?.name,
        controls: controls,
        secondaryText: secondaryText
      }

    } else if (selectedSection.taId) {
      //TA section
      sectionTemplate = {
        id: selectedSection.taId,
        name: masterData.ta.find(prod => prod.ID == selectedSection.taId)?.name,
        controls: controls
      }
    }
    return sectionTemplate;
  }

  /* get teamview master data includes Positions, Position Groups, Products, Therapeutic Areas*/
  public async getAssessTeamViewPositionIds(): Promise<AssessTeamviewRefData[]> {
    let teamViewPositionIds: AssessTeamviewRefData[] = [];
    if(this.authService.user && !_.isEmpty(this.authService.user.positions)) {
      let positionIds = this.authService.user.positions.map(o=>{ 
          let data: AssessTeamviewRefData = {ID: o.ID, name: o.name} 
          return data;
        });
      teamViewPositionIds = teamViewPositionIds.concat(positionIds);
    }
    if(this.authService.user && !_.isEmpty(this.authService.user.childPositions)) {
      let childPositionIds = this.authService.user.childPositions.map(c=>{ 
          let data: AssessTeamviewRefData = {ID: c.childPositionId, name: c.childPosition}
          return data; 
        });
      teamViewPositionIds = teamViewPositionIds.concat(childPositionIds);
    }
    teamViewPositionIds = teamViewPositionIds.reduce((uniqId, pId) => (uniqId.includes(pId) ? uniqId : [...uniqId, pId]),[]);
    this.positionsTeamview = teamViewPositionIds;
    
    return teamViewPositionIds;
  }

  public async getAssessTeamViewChildPositionIds(): Promise<AssessTeamviewRefData[]> {
    let teamViewChildPositionIds: AssessTeamviewRefData[] = [];
    if(this.authService.user && !_.isEmpty(this.authService.user.childPositions)) {
      let childPositionIds = this.authService.user.childPositions.map(c=>{ 
          let data: AssessTeamviewRefData = {ID: c.childPositionId, name: c.childPosition}
          return data; 
        });
      teamViewChildPositionIds = teamViewChildPositionIds.concat(childPositionIds);
    }
    teamViewChildPositionIds = teamViewChildPositionIds.reduce((uniqId, pId) => (uniqId.includes(pId) ? uniqId : [...uniqId, pId]),[]);
    return teamViewChildPositionIds;
  }

  public async getAssessTeamViewPositionGroupIds(positionsFilterCondition: string): Promise<AssessTeamviewRefData[]> {
    let teamViewPositionGroupIds: AssessTeamviewRefData[] = [];
    let fetchXML = fetchQueries.positionGroupsByPositionIdsTeamview;
    fetchXML = fetchXML.replace('{positionsCondition}', positionsFilterCondition);
    try{
      await this.customerAssessDataService.getAssessmentTeamViewPositionGroups(fetchXML).then((res)=> {
        if(!_.isEmpty(res)) {
          if(!_.isEmpty(res)) {
            let rawData = _.values(res);
            rawData.forEach(r=>{
              let data: AssessTeamviewRefData = {ID: r['indskr_positiongroupsid'], name: r['indskr_name']}
              teamViewPositionGroupIds.push(data);
            });
            teamViewPositionGroupIds = teamViewPositionGroupIds.reduce((uniqId, pId) => (uniqId.includes(pId) ? uniqId : [...uniqId, pId]),[]); 
            this.positionGroupsTeamview = teamViewPositionGroupIds;
          }
        }
      });
    }catch(error) {
      console.log("Error - get assess team view position groups ",error);
    }
    return teamViewPositionGroupIds;
  }

  public async getAssessTeamViewProductIds(positionsFilterCondition: string): Promise<AssessTeamviewRefData[]> {
    let teamViewProductIds: AssessTeamviewRefData[] = [];
    let fetchXML = fetchQueries.productsByPositionIdsTeamview;
    fetchXML = fetchXML.replace('{positionsCondition}', positionsFilterCondition);
    try{
      await this.customerAssessDataService.getAssessmentTeamViewProducts(fetchXML).then((res)=> {
        if(!_.isEmpty(res)) {
          let rawData = _.values(res);
          rawData.forEach(r=>{
            let data: AssessTeamviewRefData = {ID: r['productid'], name: r['name']}
            teamViewProductIds.push(data);
          });
          teamViewProductIds = teamViewProductIds.reduce((uniqId, pId) => (uniqId.includes(pId) ? uniqId : [...uniqId, pId]),[]);
          this.productsTeamview = teamViewProductIds;
        }
      });
    }catch(error) {
      console.log("Error - get assess team view products ", error);
    }
    return teamViewProductIds;
  }

  private async saveAssessTemplatesTeamViewToDisk(data: AssessmentTemplate[]) {
    this.assessmentTemplatesTeamView = [];
    for (let i = 0; i < data.length; i++) {
      const rawData = data[i];
      /* Pouch db doesnt allow to store keyword starting with _ */
      for (let key in data[i]) {
        if (key.charAt(0) === "_") {
          var a = key.substring(1, key.length);
          data[i][a] = rawData[a] = data[i][key];
          delete data[i][key];
          delete rawData[key];
        }
      }
      rawData['_id'] = DB_KEY_PREFIXES.CUSTOMER_ASSESS_TEAMVIEW_TEMPLATES + rawData['indskr_assessmenttemplateid'];
      this.assessmentTemplatesTeamView.push(this.sortTemplate(new AssessmentTemplate(rawData)));
      try {
        await this.disk.bulk(this.assessmentTemplatesTeamView);
      } catch (error) {
        console.log("Error - saving bulk templates for team view", error);
      }
    }
  }

  public async fetchCustomerAssessmentMasterDataTeamview(){
    let teamviewPosition = await this.getAssessTeamViewPositionIds();
    const teamViewPositionIds = teamviewPosition.map(p=> {return p.ID});
    let positionsFilterCondition = '';
    if(!_.isEmpty(teamViewPositionIds)) {
      teamViewPositionIds.forEach(pId => { 
        if(pId) {
          positionsFilterCondition +=`<value>${pId}</value>\n`
        }
      });
    }
    try{
      Promise.all([
        this.getAssessTeamViewPositionGroupIds(positionsFilterCondition),
        this.getAssessTeamViewProductIds(positionsFilterCondition),
        this.therapeuticService.getTherapeuticAreaTeamview(positionsFilterCondition)
      ]);
    }catch(error) {
      console.error("Failed to fetch assessment master data for teamview ", error);
    }
    
  }

  public async fetchCustomerAssessmentTeamview(entity: string, entityId: string, templateId: string): Promise<CustomerAssessment[]> {
    if (this.device.isOffline) return;
    const fetchXml = CUSTOMER_ASSESS_TEAMVIEW_FETCHXML.replace('{indskr_entityid}', entityId)
      .replace('{indskr_entity}', entity)
      .replace('{indskr_template}', templateId)
      .replace('{DeltaCondition}', '');
    
    try {
      const data = await this.customerAssessDataService.getCustomerAssessment(fetchXml);
      if(_.isEmpty(data)) {
        console.log("customer assessment response data is not available");
      }else {
        let groupDataByPosition: any = [];
        let regroupedList = [];
        groupDataByPosition = _.groupBy(data, '_indskr_userprimaryposition_value');
        groupDataByPosition = _.values(groupDataByPosition);
        await groupDataByPosition.forEach((data)=>{
          this.mapCustomerAssessmentRespTeamview(data).then((res: CustomerAssessment)=>{
            if(res) regroupedList.push(res);
          });
        });
        return regroupedList
      }
      // return await this.mapCustomerAssessmentRespTeamview(data);
    } catch (error) {
      console.error("Failed to fetch assessment for teamview ", error);
    }
  }

  private async mapCustomerAssessmentRespTeamview(data): Promise<CustomerAssessment> {
    if (!_.isEmpty(data)) {
      const customerAssessment = new CustomerAssessment(
        data[0]['ca.indskr_customerassessmentid'],
        data[0]['ca.indskr_template'],
        data[0]['ca.indskr_entity'],
        data[0]['ca.indskr_name'],
        data[0]['ca.indskr_entityid'],
        data[0]['ca.indskr_assessmentdate'],
        new Date().toISOString().toString(),
        [],
      );
      customerAssessment.positionId = data[0]['_indskr_userprimaryposition_value'] || '';
      customerAssessment.position = data[0]['_indskr_userprimaryposition_value_Formatted'] || '';
      
      for (let i = 0; i < data.length; i++) {
        for (let key in data[i]) {
          if (key.charAt(0) === "_" && key.endsWith("_value")) {
            const a = key.substring(1, key.indexOf("_value"));
            data[i][a] = data[i][key];
            delete data[i][key];
            delete data[key];
          }
        }
      }
      customerAssessment.responses = data;
      customerAssessment.pendingPushToDynamics = false;
      return this.filterResponsesTeamview(customerAssessment);
    }
  }

  private filterResponsesTeamview(customerAssessment: CustomerAssessment): CustomerAssessment {
    const filteredResponses = [];
    const teamPositionIds: string[] = this.positionsTeamview.map(p=>{return p.ID});
    const teamPositionGroupIds: string[] = this.positionGroupsTeamview.map(p=>{return p.ID});
    const teamTAs: string[] = this.therapeuticService.therapeuticAreasTeamview.map(ta => ta.ID);
    const teamProductIds: string[] = this.productsTeamview.map(p=>{return p.ID});;

    customerAssessment.responses.forEach(resp => {
      if (resp.hasOwnProperty("indskr_product") || resp.hasOwnProperty("indskr_therapeuticarea") || resp.hasOwnProperty("indskr_position") || resp.hasOwnProperty("indskr_positiongroup")) {
        if (resp.hasOwnProperty("indskr_therapeuticarea") && teamTAs.includes(resp.indskr_therapeuticarea) && !resp.indskr_product && !resp.indskr_position && !resp.indskr_positiongroup) {
          //TA only
          filteredResponses.push(resp);
        } else if (resp.hasOwnProperty("indskr_position") && teamPositionIds.includes(resp.indskr_position) && !resp.indskr_product && !resp.indskr_positiongroup && !resp.indskr_therapeuticarea && (!resp.hasOwnProperty("indskr_position") || teamPositionIds.includes(resp.indskr_position))) {
          //Position only
          filteredResponses.push(resp);
        } else if (resp.hasOwnProperty("indskr_positiongroup") && teamPositionGroupIds.includes(resp.indskr_positiongroup) && !resp.indskr_product && !resp.indskr_position && !resp.indskr_therapeuticarea && (!resp.hasOwnProperty("indskr_positiongroup") || teamPositionGroupIds.includes(resp.indskr_positiongroup))) {
          //PositionGroup only
          filteredResponses.push(resp);
        } else if (resp.hasOwnProperty("indskr_product") && teamProductIds.includes(resp.indskr_product) && !resp.indskr_therapeuticarea && ((!resp.hasOwnProperty("indskr_position") || !resp["indskr_position"]) && (!resp.hasOwnProperty("indskr_positiongroup") || !resp["indskr_positiongroup"]))) {
          // Product Only
          // product might or might not be mapped to the position/positiongroup.
          // set value in offline which does not have statecode.
          filteredResponses.push(resp);
        } else if (resp.hasOwnProperty("indskr_product") && teamProductIds.includes(resp.indskr_product) && !resp.indskr_therapeuticarea && !resp.indskr_positiongroup && resp.hasOwnProperty("indskr_position")) {
          //Product and Position
          filteredResponses.push(resp);
        } else if (resp.hasOwnProperty("indskr_product") && teamProductIds.includes(resp.indskr_product) && !resp.indskr_therapeuticarea && !resp.indskr_position && resp.hasOwnProperty("indskr_positiongroup") && teamPositionGroupIds.includes(resp.indskr_positiongroup)) {
          //Product and PositionGroup
          filteredResponses.push(resp);
        } else if (resp.hasOwnProperty("indskr_product") && teamProductIds.includes(resp.indskr_product) && !resp.indskr_position && !resp.indskr_positiongroup && resp.hasOwnProperty("indskr_therapeuticarea") && teamTAs.includes(resp.indskr_therapeuticarea)) {
          //Product and TA
          filteredResponses.push(resp);
        } else if (resp.hasOwnProperty("indskr_position") && teamPositionIds.includes(resp.indskr_position) && !resp.indskr_product && !resp.indskr_positiongroup && resp.hasOwnProperty("indskr_therapeuticarea") && teamTAs.includes(resp.indskr_therapeuticarea)) {
          //Position and TA
          filteredResponses.push(resp);
        }  else if (resp.hasOwnProperty("indskr_positiongroup") && resp.indskr_positiongroup && !resp.indskr_product && !resp.indskr_position && resp.hasOwnProperty("indskr_therapeuticarea") && resp.indskr_therapeuticarea) {
          //PositionGroup and TA
          filteredResponses.push(resp);
        } else if (!resp.indskr_positiongroup && resp.hasOwnProperty("indskr_position") && teamPositionIds.includes(resp.indskr_position) && resp.hasOwnProperty("indskr_product") && teamProductIds.includes(resp.indskr_product) && resp.hasOwnProperty("indskr_therapeuticarea") && teamTAs.includes(resp.indskr_therapeuticarea)) {
          //Position and Product and TA
          filteredResponses.push(resp);
        } else if (!resp.indskr_position && resp.hasOwnProperty("indskr_positiongroup") && teamPositionGroupIds.includes(resp.indskr_positiongroup) && resp.hasOwnProperty("indskr_product") && teamProductIds.includes(resp.indskr_product) && resp.hasOwnProperty("indskr_therapeuticarea") && teamTAs.includes(resp.indskr_therapeuticarea)) {
          //PositionGroup and Product and TA
          filteredResponses.push(resp);
        }
      } else {
        //Shares attributes  OR Procedure section customer assessment response
        filteredResponses.push(resp);
      }
    });
    customerAssessment.responses = filteredResponses;
    return customerAssessment;
  }

  async getPositionsFilterCondition(teamviewPosition): Promise<string> {
    let positionsFilterCondition = '';
    const teamViewPositionIds = teamviewPosition.map(p=> {return p.ID});
    if(!_.isEmpty(teamViewPositionIds)) {
      teamViewPositionIds.forEach((pId,idx) => { 
        if(pId) {
          positionsFilterCondition +=`<value>${teamViewPositionIds[idx]}</value>\n`
        }
      });
    }
    return positionsFilterCondition;
  }

  async getRolesFilterCondition(fetchXmlTeamViewRoles): Promise<string> {
    let rolesFilterCondition = '';
    await this.customerAssessDataService.getAssessmentTeamViewRoles(fetchXmlTeamViewRoles).then(async(res) => {
      if(res && Array.isArray(res)) {
        let teamViewRoleIds = [];
        res.forEach(r => { 
          if(r['roleid']) {
            teamViewRoleIds.push(r['roleid']) 
          }
        });
        teamViewRoleIds.forEach((roleId,idx) => { 
          if(roleId) {
            rolesFilterCondition +=`<value>${teamViewRoleIds[idx]}</value>\n`
          }
        });
      }
    }).catch(err => {
      console.error("Error occurred while fetching roles - Teamview...", err)
    });
    return rolesFilterCondition;
  }

  /* customer survey related code */
  // public getAllSurveyedContactInfo(): any[] {
  //   return this.surveyedContactsInfo.getValue();
  // }

  // public setAllSurveyedContactInfo(surveyedList: any[]) {
  //   this.surveyedContactsInfo.next(surveyedList);
  // }


  public getAllSurveyedInfo(): any[] {
    return this.numOfSurveyedInfo.getValue();
  }

  public setAllSurveyedInfo(surveyedList: any[]) {
    this.numOfSurveyedInfo.next(surveyedList);
  }

  public async fetchCustomerSurvey(captureFor: string, entityId: string, templateId: string, isSubmitted: boolean = false, response: any): Promise<any> {
    const dataRangeWithFutureBoundBySixMonths = this.authService.getFromToDateRangeInUTCMiliSec(undefined);
    const startDate = format(parseInt(dataRangeWithFutureBoundBySixMonths.from),'YYYY-MM-DD');

    let savedSurveyFromDB = {
      "indskr_assessmentdate" : response['indskr_assessmentdate'],
      "indskr_customerassessmentid" : response['indskr_customerassessmentid'],
      "indskr_entity": response['indskr_entity'],
      "indskr_entityid": response['indskr_entityid'],
      "indskr_name": response['indskr_name'],
      "surveyStatus": response['surveyStatus'],
      "_indskr_template_value": response['indskr_template'],
    }
    // let savedSurveyFromDB: CustomerAssessment
    // let customerSurveysFromDB = await this.getCustomerSurveyFromDB(templateId);
    let updateDataDB = captureFor == 'contact' ? this.allContactSurveys : this.allAccountSurveys;
    let customerSurveysFromDB = updateDataDB?.filter(survey=>survey.surveyStatus === SurveyStatus.SAVED && entityId === survey.indskr_entityid && survey['_indskr_template_value'] == templateId);
    if (!_.isEmpty(customerSurveysFromDB)) savedSurveyFromDB = customerSurveysFromDB[0];
    
    if (this.device.isOffline) {
      return savedSurveyFromDB;
    }

    const customerSurvey: any = savedSurveyFromDB;

    const userPositionId = this.authService.user.xPositionID;
    const offlineDurationFilter = `<condition attribute="createdon" operator='on-or-after' value="` + new Date(startDate).toISOString() + `" />`;

    const fetchXml = CUSTOMER_SURVEY_FETCHXML.replace('{indskr_entityid}', entityId)
      .replace('{indskr_entity}', captureFor)
      .replace('{indskr_template}', templateId)
      .replace('{BUId}', this.authService.user.xBusinessUnitId)
      .replace('{userPosition}', userPositionId)
      .replace('{parentUserPosition}', userPositionId)
      .replace('{OfflineDurationCondition}', offlineDurationFilter);

    try {
      const data: any[] = await this.customerAssessDataService.getCustomerSurveyCustomerAssessments(fetchXml);

      if (_.isEmpty(data) && customerSurvey?.indskr_template == templateId) {
        //No data available in delta service respone
        return customerSurvey;
      }
      //update data in db - timeline, allsurvey
      // this.saveUpdatedCustomerSurveyForTimelineInDB(data);
      //save submitted data into all customer survey in db
      const submittedAssessmentId = customerSurvey?.indskr_customerassessmentid;
      const submittedData = data.filter(d=>d['indskr_customerassessmentid'] == submittedAssessmentId);
      if(!_.isEmpty(submittedData)) {
        let filteredRawData = _.map(submittedData, _.partialRight(_.pick, 
          [ 'indskr_customerassessmentid',
            'indskr_assessmentdate',
            'indskr_entity',
            'indskr_entityid',
            'indskr_name',
            '_indskr_template_value',
            '_modifiedby_value_Formatted',
            '_indskr_customer_value_Formatted',
            '_indskr_appointment_value',
            'surveyStatus',
            '_indskr_affiliatedcontact_value'
          ]
        ));
        if(!_.isEmpty(updateDataDB)) {
          let idx = updateDataDB.findIndex(d => d['indskr_customerassessmentid'] == submittedAssessmentId);
          if(idx>-1) updateDataDB.splice(idx,1);
          updateDataDB = updateDataDB.concat(filteredRawData);
        }else {
          updateDataDB = filteredRawData;
        }
      }
      if(!_.isEmpty(updateDataDB)) {
        await this.saveUpdatedAllCustomerSurveyInDB(captureFor, updateDataDB).then(async ()=>{
          if(captureFor == 'contact') {
            await this.loadAllContactSurveyFromDB(true).then(()=>{
              this.mergeSavedandSubmittedSurveysForAppt(true);
              // this.updateNumOfSubmittedSurveys('contact', this.allContactSurveys.filter(survey => survey.surveyStatus != SurveyStatus.SAVED));
            })
          }else {
            await this.loadAllAccountSurveyFromDB(true).then(()=>{
              this.mergeSavedandSubmittedSurveysForAppt(true);
              // this.updateNumOfSubmittedSurveys('account', this.allAccountSurveys.filter(survey => survey.surveyStatus != SurveyStatus.SAVED));
            });
          }
        });
      }
      return await this.mapCustomerSurveyResp(customerSurveysFromDB,customerSurvey.indskr_customerassessmentid ,data, isSubmitted);
    } catch (error) {
      console.error("Failed to fetch contact survey ", error);
    }
  }


  fetchInternalSurvey() {

  }

  // public async fetchOfflineContactSurvey(contactId: string, templateId: string): Promise<CustomerAssessment> {
  //   const hasCustomerSurveyEnabled: boolean = this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SURVEY);
  //   const contactSurveysFromDB: CustomerAssessment[] = await this.getCustomerSurveyFromDB(contactId, templateId);
  //   if(!_.isEmpty(contactSurveysFromDB)) return contactSurvey;
  //   try {
  //     if(_.isEmpty(this.allContactSurveys)) {
  //       this.loadAllContactSurveyFromDB(hasCustomerSurveyEnabled);
  //     }
  //     if(!_.isEmpty(this.allContactSurveys)) {
  //       let filteredData = this.allContactSurveys.filter(ca=>ca['ca.indskr_entityid'] == contactId);
  //       return await this.mapCustomerSurveyResp(filteredData);
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch offline contact survey ", error);
  //   }
  // }

  // public async fetchOfflineAccountSurvey(accountId: string, templateId: string): Promise<CustomerAssessment> {
  //   // const hasCustomerSurveyEnabled: boolean = this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SURVEY);
  //   // const accountSurveys: CustomerAssessment[] = await this.getCustomerSurveyFromDB(accountId, templateId);
  //   // if(!_.isEmpty(accountSurveys)) return accountSurveys;
  //   // try {
  //   //   if(_.isEmpty(this.allAccountSurveys)) {
  //   //     this.loadAllAccountSurveyFromDB(hasCustomerSurveyEnabled);
  //   //   }
  //   //   if(!_.isEmpty(this.allAccountSurveys)) {
  //   //     let filteredData = this.allAccountSurveys.filter(ca=>ca['ca.indskr_entityid'] == accountId);
  //   //     return await this.mapCustomerSurveyResp(filteredData);
  //   //   }s
  //   // } catch (error) {
  //   //   console.error("Failed to fetch offline account survey ", error);
  //   // }

  //   return null
  // }

  public async fetchAllContactSurveysByTemplates(forceFullSync: boolean = false, templateIds: string[], dataRange: { from: string, to: string }): Promise<CustomerAssessment[]> {
    const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ALL_CONTACT_SURVEY);
    const isInitialSync = forceFullSync || !syncState || !syncState.lastUpdatedTime;
    const newLastUpdatedTime = new Date().getTime();
    const startDate = format(parseInt(dataRange.from),'YYYY-MM-DD');

    const allContactSurveySyncInfo: EntitySyncInfo = {
      entityName: EntityNames.customerassessmentresponse,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    let templatesFilterCondition = '';
    if(!_.isEmpty(templateIds)) {
      templateIds.forEach(tId => { 
        if(tId) {
          templatesFilterCondition +=`<value>${tId}</value>\n`
        }
      });
    }

    const userPositionId = this.authService.user.xPositionID;
    const offlineDurationFilter = `<condition attribute="createdon" operator='on-or-after' value="` + new Date(startDate).toISOString() + `" />`;

    let fetchXml = ALL_CUSTOMER_SURVEY_FETCHXML.replace('{indskr_entity}', 'contact')
      .replace('{BUId}', this.authService.user.xBusinessUnitId)
      .replace('{userPosition}', userPositionId)
      .replace('{parentUserPosition}', userPositionId)
      .replace('{templatesCondition}', templatesFilterCondition)
      .replace('{OfflineDurationCondition}', offlineDurationFilter);

    if(isInitialSync) {
      fetchXml = fetchXml.replace('{DeltaCondition}', '');
      await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACT_SURVEYS);
    } else {
      const deltaSyncFilter = `<condition attribute="modifiedon" operator="ge" value="` + new Date(syncState.lastUpdatedTime).toISOString() + `" />`;
      fetchXml = fetchXml.replace('{DeltaCondition}', deltaSyncFilter);
    }

    let pagingInfo: any = null;
    let rawAllData = [];
    
    do {
      try {
        let response = await this.dynamics.executeFetchXml(
          'indskr_customerassessments',
          fetchXml,
          undefined,
          pagingInfo?.nextPage ?? 0,
          pagingInfo?.cookie ?? null,
        );

        if (response) {
          pagingInfo = response.PagingInfo ?? null;

          if (Array.isArray(response.value)) {
            let filteredRawData = _.map(response.value, _.partialRight(_.pick, 
                [ 'indskr_customerassessmentid',
                  'indskr_assessmentdate',
                  'indskr_entity',
                  'indskr_entityid',
                  'indskr_name',
                  '_indskr_template_value',
                  '_modifiedby_value_Formatted',
                  '_indskr_customer_value_Formatted',
                  '_indskr_appointment_value',
                  'surveyStatus'
                ]
              ));
            rawAllData.push(...filteredRawData);
          }
        }
      } catch (error) {
        console.error('fetching error - indskr_customerassessments: ', error);
        pagingInfo = null;
        rawAllData = null;
      }
    } while (pagingInfo !== null);
    
    let allContactSurveyDataByTemplate: CustomerAssessment[] = [];
    
    await this.loadAllContactSurveyFromDB(true);

    if(!_.isEmpty(rawAllData)) {
      if(isInitialSync) {
        this.allContactSurveys = rawAllData;
      } else {
        rawAllData.map(survey => {
          const index = this.allContactSurveys.findIndex(a => a.indskr_customerassessmentid == survey.indskr_customerassessmentid);
          if(index>-1) {
            this.allContactSurveys[index] = survey;
          } else {
            this.allContactSurveys.push(survey);
          }
        })
      }
      this.saveAllContactSurveyInDB(this.allContactSurveys);
      allContactSurveyDataByTemplate = rawAllData;

      if(allContactSurveySyncInfo.syncStatus) {
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.disk.updateSyncState(syncState);
      }
      allContactSurveySyncInfo.totalSynced = rawAllData.length;
      this.deltaService.addEntitySyncInfo(allContactSurveySyncInfo);
    }
    
    return allContactSurveyDataByTemplate;
  }

  public async fetchAllAccountSurveysByTemplates(forceFullSync: boolean = false, templateIds: string[], dataRange: { from: string, to: string }): Promise<CustomerAssessment[]> {
    const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ALL_ACCOUNT_SURVEY);
    const isInitialSync = forceFullSync || !syncState || !syncState.lastUpdatedTime;
    const newLastUpdatedTime = new Date().getTime();
    const startDate = format(parseInt(dataRange.from),'YYYY-MM-DD');

    const allContactSurveySyncInfo: EntitySyncInfo = {
      entityName: EntityNames.customerassessmentresponse,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    let templatesFilterCondition = '';
    if(!_.isEmpty(templateIds)) {
      templateIds.forEach(tId => { 
        if(tId) {
          templatesFilterCondition +=`<value>${tId}</value>\n`
        }
      });
    }

    const userPositionId = this.authService.user.xPositionID;
    const offlineDurationFilter = `<condition attribute="createdon" operator='on-or-after' value="` + new Date(startDate).toISOString() + `" />`;

    let fetchXml = ALL_CUSTOMER_SURVEY_FETCHXML.replace('{indskr_entity}', 'account')
      .replace('{BUId}', this.authService.user.xBusinessUnitId)
      .replace('{userPosition}', userPositionId)
      .replace('{parentUserPosition}', userPositionId)
      .replace('{templatesCondition}', templatesFilterCondition)
      .replace('{OfflineDurationCondition}', offlineDurationFilter);

    if(isInitialSync) {
      fetchXml = fetchXml.replace('{DeltaCondition}', '');
      await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACCOUNT_SURVEYS);
    } else {
      const deltaSyncFilter = `<condition attribute="modifiedon" operator="ge" value="` + new Date(syncState.lastUpdatedTime).toISOString() + `" />`;
      fetchXml = fetchXml.replace('{DeltaCondition}', deltaSyncFilter);
    }

    let pagingInfo: any = null;
    let rawAllData = [];
    
    do {
      try {
        let response = await this.dynamics.executeFetchXml(
          'indskr_customerassessments',
          fetchXml,
          undefined,
          pagingInfo?.nextPage ?? 0,
          pagingInfo?.cookie ?? null,
        );

        if (response) {
          pagingInfo = response.PagingInfo ?? null;

          if (Array.isArray(response.value)) {
            let filteredRawData = _.map(response.value, _.partialRight(_.pick, 
                [ 'indskr_customerassessmentid',
                  'indskr_assessmentdate',
                  'indskr_entity',
                  'indskr_entityid',
                  'indskr_name',
                  '_indskr_template_value',
                  '_modifiedby_value_Formatted',
                  '_indskr_customer_value_Formatted',
                  '_indskr_appointment_value',
                  'surveyStatus',
                  '_indskr_affiliatedcontact_value'
                ]
              ));
            rawAllData.push(...filteredRawData);
          }
        }
      } catch (error) {
        console.error('fetching error - indskr_customerassessments: ', error);
        pagingInfo = null;
        rawAllData = null;
      }
    } while (pagingInfo !== null);
    
    let allAccountSurveyDataByTemplate: CustomerAssessment[] = [];
    
    await this.loadAllAccountSurveyFromDB(true);

    if(!_.isEmpty(rawAllData)) {
      if(isInitialSync) {
        this.allAccountSurveys = rawAllData;
      } else {
        rawAllData.map(survey => {
          const index = this.allAccountSurveys.findIndex(a => a.indskr_customerassessmentid == survey.indskr_customerassessmentid);
          if(index>-1) {
            this.allAccountSurveys[index] = survey;
          } else {
            this.allAccountSurveys.push(survey);
          }
        })
      }
      this.saveAllAccountSurveyInDB(this.allAccountSurveys);
      allAccountSurveyDataByTemplate = rawAllData;

      if(allContactSurveySyncInfo.syncStatus) {
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.disk.updateSyncState(syncState);
      }
      allContactSurveySyncInfo.totalSynced = rawAllData.length;
      this.deltaService.addEntitySyncInfo(allContactSurveySyncInfo);
    }
    
    return allAccountSurveyDataByTemplate;
  }

  public updateCustomerSurveyAppt(survey: any) {
    let index = this.allContactSurveysByAssessment.findIndex(svy => svy.indskr_customerassessmentid == survey.indskr_customerassessmentid);
    if(index>-1) {
      this.allContactSurveysByAssessment[index].indskr_appointmentid = survey.indskr_appointmentid;
      this.allContactSurveysByAssessment[index].surveyStatus = survey.surveyStatus;
      survey.responses.forEach(el => {
        let idx = this.allContactSurveysByAssessment[index].responses.findIndex(resp => resp.indskr_customerassessmentresponseid == el.indskr_customerassessmentresponseid);
        if(idx>-1) this.allContactSurveysByAssessment[index].responses[idx] = el; 
        else this.allContactSurveysByAssessment[index].responses.push(el);
      }) 
    } else {
      this.allContactSurveysByAssessment.push(survey);
    }
    // this.saveAllContactSurveyApptInDB(this.allContactSurveysByAssessment);
  }

  public deleteCustomerSurveyAppt(survey: CustomerAssessment) {
    let index = this.allContactSurveysByAssessment.findIndex(svy => svy.indskr_customerassessmentid == survey.indskr_customerassessmentid);
    if(index>-1) {
      this.allContactSurveysByAssessment.splice(index, 1);
      // this.saveAllContactSurveyApptInDB(this.allContactSurveysByAssessment);
    } 
  }

  public deleteCustomerSurveyApptById(assessmentId: string) {
    let index = this.allContactSurveysByAssessment.findIndex(svy => svy.indskr_customerassessmentid == assessmentId);
    if(index>-1) {
      this.allContactSurveysByAssessment.splice(index, 1);
      // this.saveAllContactSurveyApptInDB(this.allContactSurveysByAssessment);
    } 
  }

  public getCustomerSurveyById(assessmentId: string) {
    return this.allContactSurveysByAssessment.find(svy => svy.indskr_customerassessmentid == assessmentId);
  }

  public async fetchUpdatedOfflineCustomerSurvey(entityId: string, newCustomerSurvey: CustomerAssessment): Promise<CustomerAssessment> {
    const templateId: string = newCustomerSurvey.indskr_template;
    let surveysFromDB: CustomerAssessment[] = await this.getCustomerSurveyFromDB(templateId);
    if (!_.isEmpty(surveysFromDB)) {
      surveysFromDB = this.mapSurveyResponses(surveysFromDB);
    }

    if (!_.isEmpty(newCustomerSurvey)) {

      newCustomerSurvey.pendingPushToDynamics = this.device.isOffline;

      const index = surveysFromDB.findIndex(survey => survey.indskr_customerassessmentid === newCustomerSurvey.indskr_customerassessmentid);

      if (!_.isEmpty(surveysFromDB)) {
        if (index >= 0) { surveysFromDB[index] = newCustomerSurvey; }
        else { surveysFromDB.push(newCustomerSurvey) }
      }

      await this.saveCustomerSurveyInDB(surveysFromDB, newCustomerSurvey.indskr_template);
      return newCustomerSurvey;
    } else return null;
  }

  public async fetchAllAccountSurvey(forceFullSync: boolean = false, templateIds: string[]): Promise<CustomerAssessment[]> {
    const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ALL_ACCOUNT_SURVEY);
    const isInitialSync = forceFullSync || !syncState || !syncState.lastUpdatedTime;
    const newLastUpdatedTime = new Date().getTime();

    const allAccountSurveySyncInfo: EntitySyncInfo = {
      entityName: EntityNames.customerassessmentresponse,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };

    let templatesFilterCondition = '';
    if(!_.isEmpty(templateIds)) {
      templateIds.forEach(tId => { 
        if(tId) {
          templatesFilterCondition +=`<value>${tId}</value>\n`
        }
      });
    }

    let fetchXml = ALL_CUSTOMER_SURVEY_FETCHXML.replace('{indskr_entity}', 'account')
    .replace('{templatesCondition}', templatesFilterCondition)
      .replace('{BUId}', this.authService.user.xBusinessUnitId);

    if(isInitialSync) {
      fetchXml = fetchXml.replace('{DeltaCondition}', '');
      await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACCOUNT_SURVEYS);
    } else {
      const deltaSyncFilter = `<condition attribute="modifiedon" operator="ge" value="` + new Date(syncState.lastUpdatedTime).toISOString() + `" />`;
      fetchXml = fetchXml.replace('{DeltaCondition}', deltaSyncFilter);
    }
    let allAccountSurveyData: CustomerAssessment[] = [];
    await this.customerAssessDataService.getCustomerSurvey(fetchXml).then( async(rawAllData) => {
        if(!_.isEmpty(rawAllData)) {
          this.saveAllAccountSurveyInDB(rawAllData);
          allAccountSurveyData = rawAllData;
        }
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.disk.updateSyncState(syncState);
        if(Array.isArray(rawAllData)) {
          allAccountSurveySyncInfo.totalSynced = rawAllData.length;
        }
        this.deltaService.addEntitySyncInfo(allAccountSurveySyncInfo);
      }).catch(error=> {
        console.error("Error occurred while fetching all account survey data...", error)
        this.deltaService.addSyncErrorToEntitySyncInfo(allAccountSurveySyncInfo, 'indskr_customerassessmentresponse', error);
        this.deltaService.addEntitySyncInfo(allAccountSurveySyncInfo);
      })
    return allAccountSurveyData;
  }

  public async mapCustomerSurveyResp(customerSurveys: CustomerAssessment[],customerSurveyID,data, isSubmitted: boolean = false): Promise<CustomerAssessment> {

    const filteredData = data.filter(csr => csr['indskr_customerassessmentid'] == customerSurveyID)

    if (!_.isEmpty(data)) {
      const customerSurvey = new CustomerAssessment(
        filteredData[0]['indskr_customerassessmentid'],
        filteredData[0]['_indskr_template_value'],
        filteredData[0]['indskr_entity'],
        filteredData[0]['indskr_name'],
        filteredData[0]['indskr_entityid'],
        filteredData[0]['indskr_assessmentdate'],
        new Date().toISOString().toString(),
        [],
        filteredData[0]['te.indskr_type'],
        filteredData[0]['te.indskr_surveytype'],
        filteredData[0]['te.indskr_surveyfrequency'],
        filteredData[0]['indskr_appointmentid'],
        filteredData[0]['surveyStatus']
      );

      for (let i = 0; i < filteredData.length; i++) {
        for (let key in filteredData[i]) {
          if (key.charAt(0) === "_" && key.endsWith("_value")) {
            const a = key.substring(1, key.indexOf("_value"));
            filteredData[i][a] = filteredData[i][key];
            delete filteredData[i][key];
            delete filteredData[key];
          }
        }
      }


      // customerSurvey.responses = filteredData;
      customerSurvey.pendingPushToDynamics = this.device.isOffline;
      if(isSubmitted) customerSurvey.surveyStatus = SurveyStatus.SUBMITTED;

      let index = customerSurveys.findIndex(survey=> survey.indskr_customerassessmentid === customerSurvey.indskr_customerassessmentid)

      index >= 0 ? customerSurveys[index] = customerSurvey : customerSurveys.push(customerSurvey)

      await this.saveCustomerSurveyInDB(customerSurveys,customerSurvey.indskr_template);
      return customerSurvey;
    }
  }

  public async fetchContactSurveyForDetails(survey, surveyFor:string, isInMeetingFlow: boolean = false) {
    const assessmentId = !_.isEmpty(survey) ? !isInMeetingFlow ? survey.assessmentId : survey.indskr_customerassessmentid : '';
    let contactSurveyData: CustomerAssessment[] = [];

    if(!_.isEmpty(assessmentId)) {
      let fetchXml = CUSTOMER_SURVEY_DETAILS_FETCHXML.replace('{indskr_entity}', surveyFor)
      .replace('{BUId}', this.authService.user.xBusinessUnitId)
      .replace('{customerAssessmentId}', assessmentId);

      await this.customerAssessDataService.getCustomerSurvey(fetchXml).then( async(rawAllData) => {
        if(!_.isEmpty(rawAllData)) {
          contactSurveyData = rawAllData;
        }
      }).catch(error=> {
        console.error("Error occurred while fetching contact survey data by templateId and assessmentId...", error)
      })
    }
    
    return contactSurveyData;
  }

  public async fetchContactAccountSurveyForDetails(survey, surveyFor: string, isInMeetingFlow: boolean = false) {
    const assessmentId = !isInMeetingFlow ? survey.assessmentId : survey.indskr_customerassessmentid;
    let contactSurveyData: CustomerAssessment[] = [];

    if(!_.isEmpty(assessmentId)) {
      let fetchXml = CUSTOMER_SURVEY_DETAILS_FETCHXML.replace('{indskr_entity}', surveyFor)
      .replace('{BUId}', this.authService.user.xBusinessUnitId)
      .replace('{customerAssessmentId}', assessmentId);

      await this.customerAssessDataService.getCustomerSurvey(fetchXml).then( async(rawAllData) => {
        if(!_.isEmpty(rawAllData)) {
          contactSurveyData = rawAllData;
        }
      }).catch(error=> {
        console.error("Error occurred while fetching contact survey data by templateId and assessmentId...", error)
      })
    }
    
    return contactSurveyData;
  }


  public async mapCustomerSurveyRespForTimeline(data): Promise<CustomerAssessment> {
    if (!_.isEmpty(data)) {
      const customerSurvey = new CustomerAssessment(
        data[0]['ca.indskr_customerassessmentid'],
        data[0]['ca.indskr_template'],
        data[0]['ca.indskr_entity'],
        data[0]['ca.indskr_name'],
        data[0]['ca.indskr_entityid'],
        data[0]['ca.indskr_assessmentdate'],
        new Date().toISOString().toString(),
        [],
      );

      const targetTemplateId = data[0]['ca.indskr_template'] || '';
      if(!_.isEmpty(this.surveytemplates) && !_.isEmpty(targetTemplateId)) {
        const foundTemplate: AssessmentTemplate = this.surveytemplates.find(template=> template.indskr_assessmenttemplateid == targetTemplateId);
        if(!_.isEmpty(foundTemplate)) {
          customerSurvey.indskr_type = foundTemplate['indskr_type'];
          customerSurvey.indskr_surveytype = foundTemplate['indskr_surveytype'];
          customerSurvey.indskr_surveyfrequency = foundTemplate['indskr_surveyfrequency'];
        }
      }

      for (let i = 0; i < data.length; i++) {
        for (let key in data[i]) {
          if (key.charAt(0) === "_" && key.endsWith("_value")) {
            const a = key.substring(1, key.indexOf("_value"));
            data[i][a] = data[i][key];
            delete data[i][key];
            delete data[key];
          }
        }
      }
      customerSurvey.surveyStatus = data[0].surveyStatus == SurveyStatus.SAVED ? SurveyStatus.SAVED : SurveyStatus.SUBMITTED;
      customerSurvey.responses = data;
      return customerSurvey;
    }
  }

  public async mapInternalSurveyResp(data): Promise<InternalSurvey> {
    if (!_.isEmpty(data)) {
      const internalSurvey = new InternalSurvey(
        data[0]['_indskr_usersurvey_value'],
        data[0]['ca.indskr_template'],
        data[0]['ca.indskr_name'],
        data[0]['ca.indskr_surveydate'],
        new Date().toISOString().toString(),
        [],
        this.authService.user.systemUserID,
        TemplateType.SURVEY,
        SurveyType.INTERNAL,
        data[0]['te.indskr_surveyfrequency'],
        '',
        data[0]['surveyStatus']
      );
      for (let i = 0; i < data.length; i++) {
        for (let key in data[i]) {
          if (key.charAt(0) === "_" && key.endsWith("_value")) {
            const a = key.substring(1, key.indexOf("_value"));
            data[i][a] = data[i][key];
            delete data[i][key];
            delete data[key];
          }
        }
      }
      internalSurvey.responses = data;
      // internalSurvey.surveyStatus = SurveyStatus.SUBMITTED;
      return internalSurvey;
    }
  }

  private async saveUpdatedAllCustomerSurveyInDB(captureFor: string, updateDataDB: any[]) {

    const id = captureFor == 'contact' ? DB_KEY_PREFIXES.ALL_CONTACT_SURVEY : DB_KEY_PREFIXES.ALL_ACCOUNT_SURVEY;

    try {
      await this.disk.updateOrInsert(id, (doc) => { 
        if(!doc || !doc.raw) {
          doc= {
            raw:[]
          }
        }
        doc.raw = updateDataDB;
        return doc;
      });
    } catch (error) {
      console.log("Error saving updated all customer survey for timeline in DB", error);
    }
  }

  private async saveCustomerSurveyInDB(customerSurveys: CustomerAssessment[], templateId) {
    let key = DB_KEY_PREFIXES.CUSTOMER_SURVEY + templateId;
    // customerSurvey._id = DB_KEY_PREFIXES.CUSTOMER_SURVEY + customerSurvey.indskr_entityid + '_' + customerSurvey.indskr_template;
    // await this.disk.updateOrInsert(customerSurvey._id, () => { return customerSurvey; }).catch(err => console.log("Failed to saveCustomerSurveyInDB ", err))

    await this.disk.updateOrInsert(key, doc => ({ raw: customerSurveys })).catch((err) => console.error("Saving Survey: Error saving customer Surveys data to offline db!", err));

  }

  //the offline data is managed separtely to upload the offline data when switching to the online mode
  private async saveOfflineContactSurveyInDB(contactSurvey: CustomerAssessment) {
    //random id is used to add multiple offline data created within the same survey id
    if(!_.isEmpty(contactSurvey) && contactSurvey.responses && contactSurvey.responses.length > 0) {
      contactSurvey._id = DB_KEY_PREFIXES.OFFLINE_CONTACT_SURVEY + Math.random().toString(36).substring(7);
      await this.disk.updateOrInsert(contactSurvey._id, () => {
        console.log("saved offline contact survey in DB");
        return contactSurvey; 
      }).catch(err => console.log("Failed to save offline contact survey in DB ", err))
    }
  }

  private async saveOfflineAccountSurveyInDB(accountSurvey: CustomerAssessment) {
    //random id is used to add multiple offline data created within the same survey id
    if(!_.isEmpty(accountSurvey) && accountSurvey.responses && accountSurvey.responses.length > 0) {
      accountSurvey._id = DB_KEY_PREFIXES.OFFLINE_ACCOUNT_SURVEY + Math.random().toString(36).substring(7);
      await this.disk.updateOrInsert(accountSurvey._id, () => {
        console.log("saved offline account survey in DB");
        return accountSurvey; 
      }).catch(err => console.log("Failed to save offline account survey in DB ", err))
    }
  }

  private async loadOfflineContactSurveyInDB() {
    let option = {
      selector: {
          '_id': {
              $gte: DB_KEY_PREFIXES.OFFLINE_CONTACT_SURVEY,
              $lte: DB_KEY_PREFIXES.OFFLINE_CONTACT_SURVEY + PREFIX_SEARCH_ENDKEY_UNICODE
          },
          'pendingPushToDynamics': { $eq: true }
      }
    };
    return await this.disk.find(option);
  }

  private async loadOfflineAccountSurveyInDB() {
    let option = {
      selector: {
          '_id': {
              $gte: DB_KEY_PREFIXES.OFFLINE_ACCOUNT_SURVEY,
              $lte: DB_KEY_PREFIXES.OFFLINE_ACCOUNT_SURVEY + PREFIX_SEARCH_ENDKEY_UNICODE
          },
          'pendingPushToDynamics': { $eq: true }
      }
    };
    return await this.disk.find(option);
  }

  public async uploadOfflineContactSurveys(isPartialUpload = false, maxRecordCountForPartialUpload = 10) {
    if (this.device.isOffline) return;
    let option = {
      selector: {
          '_id': {
              $gte: DB_KEY_PREFIXES.OFFLINE_CONTACT_SURVEY,
              $lte: DB_KEY_PREFIXES.OFFLINE_CONTACT_SURVEY + PREFIX_SEARCH_ENDKEY_UNICODE
          },
          'pendingPushToDynamics': { $eq: true }
      }
    };
    const contactSurveySyncInfo: EntitySyncInfo = {
      entityName: EntityNames.contactAssessment,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    let rawContactSurveys: CustomerAssessment[] = [];
    try {
      rawContactSurveys = await this.disk.find(option);
      if(!_.isEmpty(rawContactSurveys)){
        rawContactSurveys.sort((a, b) => {
          if(a.modifiedon < b.modifiedon) return -1
          else return 1
        });
        if(isPartialUpload && rawContactSurveys.length > maxRecordCountForPartialUpload) {
          rawContactSurveys = rawContactSurveys.splice(0, maxRecordCountForPartialUpload);
        }
        const payloadUnSurveyedData = rawContactSurveys.filter(r => r.isInitialAssessInOffline);
        const payloadSurveyedData = rawContactSurveys.filter(r => !r.isInitialAssessInOffline);
        await this.customerAssessDataService.uploadOfflineSurvey(payloadUnSurveyedData).then((res)=> {
          if(!_.isEmpty(res)) {
            for(let i=0; i<res.length; i++) {
              const data = res[i];
              const customerAssessmentId = data['indskr_customerassessmentid'];
              const index = rawContactSurveys.findIndex(ca => ca.pendingPushToDynamics && ca.indskr_customerassessmentid === customerAssessmentId);
              if(index > -1) {
                if(data['errorId']) {
                  contactSurveySyncInfo.totalFailed++;
                }
                else if(data['indskr_customerassessmentid'] && !data['errorId']) {
                  rawContactSurveys[index].pendingPushToDynamics = false;
                  this.disk.remove(rawContactSurveys[index]._id);
                  contactSurveySyncInfo.totalSynced++;
                }
              }
            }
            // Track offline data count
            const uploadedCnt = contactSurveySyncInfo.totalSynced;
            console.log("Uploaded offline contact surveys: ", uploadedCnt);
            const pendingData = rawContactSurveys.filter(customerAssessment => customerAssessment.pendingPushToDynamics);
            console.log("Pending offline contact surveys: ", pendingData.length);
            this.disk.subtractOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_SURVEY, uploadedCnt ? uploadedCnt : 0);
            this.deltaService.addEntitySyncInfo(contactSurveySyncInfo, true);
          }
        });
        await this.customerAssessDataService.uploadOfflineSurvey(payloadSurveyedData).then((res)=> {
          if(!_.isEmpty(res)) {
            for(let i=0; i<res.length; i++) {
              const data = res[i];
              const customerAssessmentId = data['indskr_customerassessmentid'];
              const index = rawContactSurveys.findIndex(ca => ca.pendingPushToDynamics && ca.indskr_customerassessmentid === customerAssessmentId);
              if(index > -1) {
                if(data['errorId']) {
                  contactSurveySyncInfo.totalFailed++;
                }
                else if(data['indskr_customerassessmentid'] && !data['errorId']) {
                  rawContactSurveys[index].pendingPushToDynamics = false;
                  this.disk.remove(rawContactSurveys[index]._id);
                  contactSurveySyncInfo.totalSynced++;
                }
              }
            }
            // Track offline data count
            const uploadedCnt = contactSurveySyncInfo.totalSynced;
            console.log("Uploaded offline assessed contact assessments: ", uploadedCnt);
            const pendingData = rawContactSurveys.filter(customerAssessment => customerAssessment.pendingPushToDynamics);
            console.log("Pending offline assessed contact assessments: ", pendingData.length);
            this.disk.subtractOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_ASSESSMENT, uploadedCnt ? uploadedCnt : 0);
            this.deltaService.addEntitySyncInfo(contactSurveySyncInfo, true);
          }
        });
      }
    } catch (err) {
      console.error("Failed to upload offline contact assessments: ", err);
      contactSurveySyncInfo.totalFailed = rawContactSurveys?.length;
      this.deltaService.addSyncErrorToEntitySyncInfo(contactSurveySyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.customerAssessment.UPLOAD_OFFLINE_ASSESSMENTS, err);
      this.deltaService.addEntitySyncInfo(contactSurveySyncInfo, true);
    }
  }

  public async uploadOfflineAccountSurveys(isPartialUpload = false, maxRecordCountForPartialUpload = 10) {
    if (this.device.isOffline) return;
    let option = {
      selector: {
          '_id': {
              $gte: DB_KEY_PREFIXES.OFFLINE_ACCOUNT_SURVEY,
              $lte: DB_KEY_PREFIXES.OFFLINE_ACCOUNT_SURVEY + PREFIX_SEARCH_ENDKEY_UNICODE
          },
          'pendingPushToDynamics': { $eq: true }
      }
    };
    const accountSurveySyncInfo: EntitySyncInfo = {
      entityName: EntityNames.accountAssessment,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    let rawAccountSurveys: CustomerAssessment[] = [];
    try {
      rawAccountSurveys = await this.disk.find(option);
      if(!_.isEmpty(rawAccountSurveys)){
        rawAccountSurveys.sort((a, b) => {
          if(a.modifiedon < b.modifiedon) return -1
          else return 1
        });
        if(isPartialUpload && rawAccountSurveys.length > maxRecordCountForPartialUpload) {
          rawAccountSurveys = rawAccountSurveys.splice(0, maxRecordCountForPartialUpload);
        }
        // const payload = rawAccountAssessments;
        const payloadUnSurveyedData = rawAccountSurveys.filter(r => r.isInitialAssessInOffline);
        const payloadSurveyedData = rawAccountSurveys.filter(r => !r.isInitialAssessInOffline);
        await this.customerAssessDataService.uploadOfflineSurvey(payloadUnSurveyedData).then((res)=> {
          if(!_.isEmpty(res)) {
            for(let i=0; i<res.length; i++) {
              const data = res[i];
              const customerAssessmentId = data['indskr_customerassessmentid'];
              const index = rawAccountSurveys.findIndex(ca => ca.pendingPushToDynamics && ca.indskr_customerassessmentid === customerAssessmentId);
              if(index > -1) {
                if(data['errorId']) {
                  accountSurveySyncInfo.totalFailed++;
                }
                else if(data['indskr_customerassessmentid'] && !data['errorId']) {
                  rawAccountSurveys[index].pendingPushToDynamics = false;
                  this.disk.remove(rawAccountSurveys[index]._id);
                  accountSurveySyncInfo.totalSynced++;
                }
              }
            }
            // Track offline data count
            const uploadedCnt = accountSurveySyncInfo.totalSynced;
            console.log("Uploaded offline account surveys: ", uploadedCnt);
            const pendingData = rawAccountSurveys.filter(customerAssessment => customerAssessment.pendingPushToDynamics);
            console.log("Pending offline account surveys: ", pendingData.length);
            this.disk.subtractOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ACCOUNT_SURVEY, uploadedCnt ? uploadedCnt : 0);
            this.deltaService.addEntitySyncInfo(accountSurveySyncInfo, true);
          }
        });
        await this.customerAssessDataService.uploadOfflineSurvey(payloadSurveyedData).then((res)=> {
          if(!_.isEmpty(res)) {
            for(let i=0; i<res.length; i++) {
              const data = res[i];
              const customerAssessmentId = data['indskr_customerassessmentid'];
              const index = rawAccountSurveys.findIndex(ca => ca.pendingPushToDynamics && ca.indskr_customerassessmentid === customerAssessmentId);
              if(index > -1) {
                if(data['errorId']) {
                  accountSurveySyncInfo.totalFailed++;
                }
                else if(data['indskr_customerassessmentid'] && !data['errorId']) {
                  rawAccountSurveys[index].pendingPushToDynamics = false;
                  this.disk.remove(rawAccountSurveys[index]._id);
                  accountSurveySyncInfo.totalSynced++;
                }
              }
            }
            // Track offline data count
            const uploadedCnt = accountSurveySyncInfo.totalSynced;
            console.log("Uploaded offline assessed account surveys: ", uploadedCnt);
            const pendingData = rawAccountSurveys.filter(customerAssessment => customerAssessment.pendingPushToDynamics);
            console.log("Pending offline assessed account surveys: ", pendingData.length);
            this.disk.subtractOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ACCOUNT_SURVEY, uploadedCnt ? uploadedCnt : 0);
            this.deltaService.addEntitySyncInfo(accountSurveySyncInfo, true);
          }
        });
      }
    } catch (err) {
      console.error("Failed to upload offline account surveys: ", err);
      accountSurveySyncInfo.totalFailed = rawAccountSurveys?.length;
      this.deltaService.addSyncErrorToEntitySyncInfo(accountSurveySyncInfo, this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.customerAssessment.UPLOAD_OFFLINE_ASSESSMENTS, err);
      this.deltaService.addEntitySyncInfo(accountSurveySyncInfo, true);
    }
  }

  private async saveAllContactSurveyInDB(allContactSurveys: CustomerAssessment[]) {
    const id = DB_KEY_PREFIXES.ALL_CONTACT_SURVEY;
    if(!_.isEmpty(allContactSurveys) && allContactSurveys.length > 0) {
      try {
        await this.disk.updateOrInsert(id, (doc) => { 
          if(!doc || !doc.raw) {
            doc= {
              raw:[]
            }
          }
          doc.raw = allContactSurveys;
          return doc;
        });
      } catch (error) {
        console.log("Error saving all contact survey in DB", error);
      }
    }
  }

  private async saveAllAccountSurveyInDB(allAccountSurveys: CustomerAssessment[]) {
    const id = DB_KEY_PREFIXES.ALL_ACCOUNT_SURVEY;
    if(!_.isEmpty(allAccountSurveys) && allAccountSurveys.length > 0) {
      try {
        await this.disk.updateOrInsert(id, (doc) => { 
          if(!doc || !doc.raw) {
            doc= {
              raw:[]
            }
          }
          doc.raw = allAccountSurveys;
          return doc;
        });
      } catch (error) {
        console.log("Error saving all account survey in DB", error);
      }
    }
  }

  public async getCustomerSurveyFromDB(templateId: string): Promise<CustomerAssessment[]> {
    const id = DB_KEY_PREFIXES.CUSTOMER_SURVEY + templateId;
    const doc = await this.disk.retrieve(id, true);
    return doc ? doc.raw : [];
  }

  public async deleteCustomerSurveys(deletedTemplateIds: string[], clearAll: boolean = false) {
    if (clearAll) {
      await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUST_SURVEYS);
    } else {
      const deletedCustomerSurveys = [];
      const customerSurveys: CustomerAssessment[] = await this.getAllCustomerSurveys();
      customerSurveys.forEach(customerSurvey => {
        if (deletedTemplateIds.includes(customerSurvey.indskr_template)) {
          deletedCustomerSurveys.push({ _id: customerSurvey._id, _rev: customerSurvey._rev, _deleted: true });
        }
      });
      if (_.isEmpty(deletedCustomerSurveys)) {
        await this.disk.bulk(deletedCustomerSurveys);
      }
    }
  }

  private async getAllCustomerSurveys(): Promise<CustomerAssessment[]> {
    return await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUST_SURVEYS).then((data: CustomerAssessment[]) => {
      return data;
    });
  }

  private async fetchAllContactSurveys(): Promise<CustomerAssessment[]> {
    return await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACT_SURVEYS).then((data: CustomerAssessment[]) => {
      return data;
    });
  }

  public async deleteCustomerSurveyInDB(assessmentId: string) {
    const targetId = DB_KEY_PREFIXES.ALL_CONTACT_SURVEY;
    await this.disk.retrieve(targetId, true).then((data)=>{
      let surveyDataFromDB = data ? data.raw : [];
      if(!_.isEmpty(surveyDataFromDB)) {
        const idx = surveyDataFromDB.findIndex(d=>d['indskr_customerassessmentid'] == assessmentId);
        if(idx > -1) {
          surveyDataFromDB.splice(idx,1);
          this.saveAllContactSurveyInDB(surveyDataFromDB);
        }
      }
      let index = this.allContactSurveys.findIndex(svy => svy.indskr_customerassessmentid == assessmentId);
      if(index>-1) {
        this.allContactSurveys.splice(index, 1);
      } 
    }).catch(err => console.log("Failed to deleteCustomerSurveyInDB ", err))
  }

  public async deleteAccountSurveyInDB(assessmentId: string) {
    const targetId = DB_KEY_PREFIXES.ALL_ACCOUNT_SURVEY;
    await this.disk.retrieve(targetId, true).then((data)=>{
      let surveyDataFromDB = data ? data.raw : [];
      if(!_.isEmpty(surveyDataFromDB)) {
        const idx = surveyDataFromDB.findIndex(d=>d['indskr_customerassessmentid'] == assessmentId);
        if(idx > -1) {
          surveyDataFromDB.splice(idx,1);
          this.saveAllAccountSurveyInDB(surveyDataFromDB);
        }
      }
      let index = this.allAccountSurveys.findIndex(svy => svy.indskr_customerassessmentid == assessmentId);
      if(index>-1) {
        this.allAccountSurveys.splice(index, 1);
      } 
    }).catch(err => console.log("Failed to deleteAccountSurveyInDB ", err))
  }

  public async deleteInternalSurveyInDB(survreyId: string) {
    const targetId = DB_KEY_PREFIXES.INTERNAL_SURVEY;
    await this.disk.retrieve(targetId, true).then((data)=>{
      let internalSurveyDataFromDB = data ? data.raw : [];
      if(!_.isEmpty(internalSurveyDataFromDB)) {
        let filteredSurveys = internalSurveyDataFromDB.filter((survey)=> survey['_indskr_usersurvey_value'] != survreyId);
        this.saveInternalSurveyInDB(filteredSurveys);
        // const idx = internalSurveyDataFromDB.findIndex(d=>d['_indskr_usersurvey_value'] == survreyId);
        // if(idx > -1) {
        //   internalSurveyDataFromDB.splice(idx,1);
        //   this.saveInternalSurveyInDB(internalSurveyDataFromDB);
        // }
      }
      this.allInternalSurveys = internalSurveyDataFromDB.filter((survey)=> survey['_indskr_usersurvey_value'] != survreyId);
      // let index = this.allInternalSurveys.findIndex(svy => svy._indskr_usersurvey_value == survreyId);
      // if(index>-1) {
      //   this.allInternalSurveys.splice(index, 1);
      // } 
    }).catch(err => console.log("Failed to deleteInternalSurveyInDB ", err))

    // try {
    //   await this.disk.remove(targetId);
    // } catch(error) {
    //   console.log("Failed to deleteInternalSurveyInDB ", error);
    // }
  }

  // public async saveSurvey(captureFor: string, 
  //     entityId: string,
  //     currentFormValue: any, 
  //     selectedTemplate: AssessmentTemplate,
  //     deletedFormValues: any, 
  //     appointmentId?: string,
  //     isValidToSubmit?: boolean): Promise<CustomerAssessment> {

  //   const templateId: string = selectedTemplate.indskr_assessmenttemplateid;

  //   let surveysFromDB: CustomerAssessment[] = await this.getCustomerSurveyFromDB(templateId);

  //   const isOffline: boolean = this.device.isOffline;

  //   let savedSurveys = surveysFromDB.filter(survey => survey.surveyStatus === SurveyStatus.SAVED && survey.indskr_entityid === entityId)

  //   let surveyToBeUpdated: CustomerAssessment;

  //   if (_.isEmpty(savedSurveys)) { //That means if there is no Saved Survey then create a new one otherwise update the saved one 
  //     let newSurvey = new CustomerAssessment(
  //       Guid.create().
  //       toString(), 
  //       templateId, 
  //       selectedTemplate.indskr_entity, 
  //       selectedTemplate.indskr_name, 
  //       entityId, 
  //       new Date().toISOString().toString(), 
  //       new Date().toISOString().toString(), 
  //       [],
  //       selectedTemplate.indskr_type,
  //       selectedTemplate.indskr_surveytype,
  //       selectedTemplate.indskr_surveyfrequency,
  //       appointmentId,
  //       isValidToSubmit
  //       );
  //     if(isOffline) newSurvey.isInitialAssessInOffline = true;
  //     surveyToBeUpdated = newSurvey;
  //   } else {
  //     surveyToBeUpdated = savedSurveys[0];
  //     surveyToBeUpdated.indskr_appointmentid = appointmentId;
  //   }

    

  //   let responses = [];
  //   for (let [key, value] of Object.entries(currentFormValue)) {
  //     if (key.includes('_')) {
  //       this._fromSectionSpecific(value, responses);
  //     } else {
  //       responses = responses.concat(value);
  //     }
  //   }


  //   if (!_.isEmpty(deletedFormValues)) {
  //     for (let [key, value] of Object.entries(deletedFormValues)) {
  //       if (key.includes('_')) {
  //         this._fromSectionSpecific(value, responses);
  //       } else {
  //         responses = responses.concat(value);
  //       }
  //     }
  //   }


  //   surveyToBeUpdated.responses = responses;
  //   surveyToBeUpdated.pendingPushToDynamics = isOffline;
  //   surveyToBeUpdated.surveyStatus = SurveyStatus.SAVED;
  //   surveyToBeUpdated.isValidToSubmit = isValidToSubmit;
  
  //   let index = surveysFromDB.findIndex(survey => survey.indskr_customerassessmentid === surveyToBeUpdated.indskr_customerassessmentid)

  //   if (index >= 0) {
        
  //   } 

  //   index >=0 ? surveysFromDB[index] = surveyToBeUpdated: surveysFromDB.push(surveyToBeUpdated);

  //   await this.saveCustomerSurveyInDB(surveysFromDB, templateId);

  //   // return surveyToBeUpdated;
  //   let savedSurvey = new CustomerAssessment(
  //     surveyToBeUpdated.indskr_customerassessmentid,
  //     surveyToBeUpdated.indskr_template,
  //     surveyToBeUpdated.indskr_entity,
  //     surveyToBeUpdated.indskr_name,
  //     surveyToBeUpdated.indskr_entityid,
  //     surveyToBeUpdated.indskr_assessmentdate,
  //     surveyToBeUpdated.modifiedon,
  //     surveyToBeUpdated.responses,
  //     surveyToBeUpdated.indskr_type,
  //     surveyToBeUpdated.indskr_surveytype,
  //     surveyToBeUpdated.indskr_surveyfrequency,
  //     surveyToBeUpdated.indskr_appointmentid,
  //     surveyToBeUpdated.isValidToSubmit
  //   )
  //   savedSurvey.surveyStatus = surveyToBeUpdated.surveyStatus;
  //   this.updateCustomerSurveyAppt(savedSurvey);
  //   return surveyToBeUpdated;
  // }

public async prepareSurveyPayload(captureFor: string, 
    entityId: string,
    currentFormValue: any, 
    selectedTemplate: AssessmentTemplate,
    deletedFormValues: any, 
    status: SurveyStatus,
    appointmentId?: string,
    isValidToSubmit?: boolean,
    affiliatedContactId?: string): Promise<CustomerAssessment> {

  const templateId: string = selectedTemplate.indskr_assessmenttemplateid;
  
  let surveysByTemplateId = this.getSurveysByTemplateId(templateId, selectedTemplate.indskr_entity, false);
  let savedSurveys = surveysByTemplateId?.find(survey => survey.surveyStatus === SurveyStatus.SAVED && survey.indskr_entityid === entityId);

  const isOffline: boolean = this.device.isOffline;

  let surveyToBeUpdated: CustomerAssessment;

  if (_.isEmpty(savedSurveys)) { //That means if there is no Saved Survey then create a new one otherwise update the saved one 
    let newSurvey = new CustomerAssessment(
      Guid.create().toString(), 
      templateId, 
      selectedTemplate.indskr_entity, 
      selectedTemplate.indskr_name, 
      entityId, 
      new Date().toISOString().toString(), 
      new Date().toISOString().toString(), 
      [],
      selectedTemplate.indskr_type,
      selectedTemplate.indskr_surveytype,
      selectedTemplate.indskr_surveyfrequency,
      appointmentId,
      status,
      isValidToSubmit,
      affiliatedContactId
      );
    if(isOffline) newSurvey.isInitialAssessInOffline = true;
    surveyToBeUpdated = newSurvey;
  } else {
    surveyToBeUpdated = savedSurveys;
    surveyToBeUpdated['indskr_appointmentid'] = appointmentId;
    surveyToBeUpdated['indskr_affiliatedcontactid'] = affiliatedContactId;
  }

  let responses = [];
  for (let [key, value] of Object.entries(currentFormValue)) {
    if(Object.keys(value).every(val => val.includes('-'))) {
      let attribute = _.cloneDeep(value);
      for(let [key, val] of Object.entries(attribute)) {
        if (key.includes('_')) {
          this._fromSectionSpecific(val, responses);
        } else if(val.hasOwnProperty('@odata.etag')) {
          responses = responses.concat(this.mapSavedResponses(val));
        } else {
          responses = responses.concat(val);
        }
      }
    } else {
      if (key.includes('_')) {
        this._fromSectionSpecific(value, responses);
      } else if(value.hasOwnProperty('@odata.etag')) {
        responses = responses.concat(this.mapSavedResponses(value));
      } else {
        responses = responses.concat(value);
      }
    }
  }

  if (!_.isEmpty(deletedFormValues)) {
    for (let [key, value] of Object.entries(deletedFormValues)) {
      if(Object.keys(value).every(val => val.includes('-'))) {
        let attribute = _.cloneDeep(value);
        for(let [key, val] of Object.entries(attribute)) {
          if (key.includes('_')) {
            this._fromSectionSpecific(val, responses);
          } else {
            responses = responses.concat(val);
          }
        }
      } else {
        if (key.includes('_')) {
          this._fromSectionSpecific(value, responses);
        } else {
          responses = responses.concat(value);
        }
      }
    }
  }

  surveyToBeUpdated.responses = responses;
  surveyToBeUpdated.pendingPushToDynamics = isOffline;
  surveyToBeUpdated.surveyStatus = status;
  surveyToBeUpdated.isValidToSubmit = isValidToSubmit;

  let index = surveysByTemplateId.findIndex(survey => survey.indskr_customerassessmentid === surveyToBeUpdated.indskr_customerassessmentid)
  index >=0 ? surveysByTemplateId[index] = surveyToBeUpdated: surveysByTemplateId.push(surveyToBeUpdated);
  await this.saveCustomerSurveyInDB(surveysByTemplateId, templateId);

  return surveyToBeUpdated;
}

private mapSavedResponses(payload) {
  let type = payload['indskr_type'];
  let response = {
    'indskr_customerassessmentresponseid': payload['indskr_customerassessmentresponseid'],
    'indskr_type': type,
    'indskr_attribute': payload['_indskr_attribute_value'] || payload['indskr_attribute'],
    'indskr_name': payload['ca.indskr_name'],
    'indskr_assessmentcategory': payload['indskr_assessmentcategory']
  }
  this._setAttributeResponseValue(type, response, payload);
  return response;
}

private _setAttributeResponseValue(type, responseCaptured: any, value: any) {
  switch (type) {
    case AssessmentAttributeType.TEXT:
      responseCaptured['indskr_text'] = value['indskr_text'];
      break;
    case AssessmentAttributeType.FLAG:
      responseCaptured['indskr_flag'] = value['indskr_flag'];
      break;
    case AssessmentAttributeType.MEMO:
      responseCaptured['indskr_memo'] = value['indskr_memo'];
      break;
    case AssessmentAttributeType.NUMERIC:
    case AssessmentAttributeType.RANGE:
      responseCaptured['indskr_numeric'] = value['indskr_numeric'];
      break;
    case AssessmentAttributeType.TIMESTAMP:
      responseCaptured['indskr_timestamp'] = value['indskr_timestamp'];
      break;
    case AssessmentAttributeType.CHOICE:
      responseCaptured['indskr_choice'] = value['indskr_choice'];
      responseCaptured['indskr_choicevalues'] = value['indskr_choicevalues'];
      responseCaptured['indskr_choicelabel'] = value['indskr_choicelabel'];
      break;
  }
}

public mapSurveyResponses(rawAllData:any[]): CustomerAssessment[] {
  let surveys: CustomerAssessment[] = [];
  if(rawAllData && Array.isArray(rawAllData)) {
    rawAllData.forEach((raw) => {
      if(raw['ca.indskr_customerassessmentid']) {
        let survey: CustomerAssessment;
        survey = surveys.find(o => o.indskr_customerassessmentid == raw['ca.indskr_customerassessmentid']);  
        if(!survey) {
          survey = new CustomerAssessment(
            raw['ca.indskr_customerassessmentid'],
            raw['ca.indskr_template'],
            raw['ca.indskr_entity'],
            raw['ca.indskr_name'],
            raw['ca.indskr_entityid'],
            raw['ca.indskr_assessmentdate'],
            raw['modifiedon'],
            [],
            raw['te.indskr_type'],
            raw['te.indskr_surveytype'],
            raw['te.indskr_surveyfrequency'],
            raw['ca.indskr_appointmentid'],
            raw['surveyStatus'],
            raw.isValidToSubmit
          )
        }
        if(raw.hasOwnProperty('indskr_customerassessmentresponseid')) {
          let response;
          if(!_.isEmpty(survey.responses)) response = survey.responses.find(r => r.indskr_customerassessmentresponseid == raw['indskr_customerassessmentresponseid']);
          if(!response) {
            for (let key in raw) {
              if (key.charAt(0) === "_" && key.endsWith("_value")) {
                const a = key.substring(1, key.indexOf("_value"));
                raw[a] = raw[key];
                delete raw[key];
              }
            }
            survey.responses.push(raw);
          }
        }
        survey.surveyStatus = raw['surveyStatus'] == 548910000 ? SurveyStatus.SAVED : SurveyStatus.SUBMITTED;

        let index = surveys.findIndex(sur=> sur.indskr_customerassessmentid === survey.indskr_customerassessmentid)
        index >= 0 ? surveys[index] = survey : surveys.push(survey)
      }
    })
  }
  return surveys; 
}

public async scrapSurvey(assessmentId: string) {
  const isOffline: boolean = this.device.isOffline;
  const response = await this.customerAssessDataService.scrapExternalSurvey(assessmentId).catch((error) => {
    console.error("Failed to scrap contact survey ", error);
  });
}

public async scrapInternalSurvey(surveyId: string) {
  const isOffline: boolean = this.device.isOffline;
  const response = await this.customerAssessDataService.scrapInternalSurvey(surveyId).catch((error) => {
    console.error("Failed to scrap internal survey ", error);
  });
}

public async saveAndSubmitSurvey(captureFor: string, entityId: string, currentFormValue: any, selectedTemplate: AssessmentTemplate, deletedFormValues: any, status:SurveyStatus, appointmentId?: string, isValidToSubmitSurvey?: boolean, affiliatedContactId?: string): Promise<CustomerAssessment> {

  const isOffline: boolean = this.device.isOffline;
  const isSubmitted: boolean = status == SurveyStatus.SUBMITTED;

  let surveyToBeUpdated: CustomerAssessment;
  await this.prepareSurveyPayload(captureFor, entityId, currentFormValue, selectedTemplate, deletedFormValues, status, appointmentId, isValidToSubmitSurvey, affiliatedContactId).then((data) => {
    if(!_.isEmpty(data)) surveyToBeUpdated = data;
  })
 
  if(!isOffline && !_.isEmpty(surveyToBeUpdated)) {
    if(captureFor == 'contact') {// Survey For Contact
      // this.surveyedContacts.push({ "contactid": entityId });
      // save or submit the survey to Dynamics via service
      const response = await this.customerAssessDataService.saveCustomerSurvey(captureFor, surveyToBeUpdated).catch((error) => {
        console.error("Failed to save contact survey ", error);
      });

      if(!_.isEmpty(response) && response['indskr_customerassessmentid'] === surveyToBeUpdated.indskr_customerassessmentid) {
        if(selectedTemplate.indskr_surveyfrequency == SurveyFrequency.ONCE) {
          let surveyedContacts = this.surveyedContactsForOnetimeSurvey[selectedTemplate.indskr_assessmenttemplateid];
          if(!_.isEmpty(surveyedContacts) && !surveyedContacts.some(con => con.indskr_entityid ==entityId)) surveyedContacts.push({
            'indskr_entityid': entityId,
            'templateid': selectedTemplate.indskr_assessmenttemplateid
          }); 
          else {
            this.surveyedContactsForOnetimeSurvey[selectedTemplate.indskr_assessmenttemplateid] = [{
              'indskr_entityid': entityId,
              'templateid': selectedTemplate.indskr_assessmenttemplateid
            }]
          }
          this.saveSurveyedEntitiesByTemplates(this.surveyedContactsForOnetimeSurvey, captureFor);
        }
        this.updateNumOfSurveyedByTemplate(selectedTemplate);
        return await this.fetchCustomerSurvey(captureFor, entityId, selectedTemplate.indskr_assessmenttemplateid, isSubmitted, response);
      }

    } else if(captureFor == 'account') { // Survey For Account 
      const response = await this.customerAssessDataService.saveCustomerSurvey(captureFor, surveyToBeUpdated).catch((error) => {
        console.error("Failed to save account survey ", error);
      });
      if(!_.isEmpty(response) && response['indskr_customerassessmentid'] === surveyToBeUpdated.indskr_customerassessmentid) {
        if(isSubmitted && selectedTemplate.indskr_surveyfrequency == SurveyFrequency.ONCE) {
          let surveyedAccounts = this.surveyedAccountsForOnetimeSurvey[selectedTemplate.indskr_assessmenttemplateid];
          if(!_.isEmpty(surveyedAccounts) && !surveyedAccounts.some(con => con.indskr_entityid ==entityId)) {
            surveyedAccounts.push({
            'indskr_entityid': entityId,
            'templateid': selectedTemplate.indskr_assessmenttemplateid
            });
          } else {
            this.surveyedAccountsForOnetimeSurvey[selectedTemplate.indskr_assessmenttemplateid] = [{
              'indskr_entityid': entityId,
              'templateid': selectedTemplate.indskr_assessmenttemplateid
            }]
          }
          this.saveSurveyedEntitiesByTemplates(this.surveyedAccountsForOnetimeSurvey, captureFor);
          this.updateNumOfSurveyedByTemplate(selectedTemplate);
        }
        return await this.fetchCustomerSurvey(captureFor, entityId, selectedTemplate.indskr_assessmenttemplateid, isSubmitted, response);
      }
    }
  }
    //Offline mode 
    // Need to update meeting flow later
    else {
      const filtedOfflineCustomerSurvey: CustomerAssessment = await this.fetchUpdatedOfflineCustomerSurvey(entityId, surveyToBeUpdated);
      if(captureFor == 'contact' && !_.isEmpty(filtedOfflineCustomerSurvey)) {
        surveyToBeUpdated.contactId = entityId;
        await this.saveOfflineContactSurveyInDB(surveyToBeUpdated);
        // Track offline data count
        try {
          await this.loadOfflineContactSurveyInDB().then((data)=>{
            if(!_.isEmpty(data) && data.length) {
              const offlineCount = data.length;
              this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_SURVEY, offlineCount);
            }
          });
        } catch(error) {
          console.log("Error loading offline contact survey in DB", error);
        }
        return filtedOfflineCustomerSurvey;
      } else if(captureFor == 'account' && !_.isEmpty(filtedOfflineCustomerSurvey)) {
        surveyToBeUpdated.accountId = entityId;
        await this.saveOfflineAccountSurveyInDB(surveyToBeUpdated);
        // Track offline data count
        try {
          await this.loadOfflineAccountSurveyInDB().then((data)=>{
            if(!_.isEmpty(data) && data.length) {
              const offlineCount = data.length;
              this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ACCOUNT_SURVEY, offlineCount);
            }
          });
        } catch(error) {
          console.log("Error loading offline account survey in DB", error);
        }
        return filtedOfflineCustomerSurvey;
      } 
    }
  }

  public getGroupedSurveysByMonth(surveys, isInternalSurvey: boolean = false, isSavedData: boolean = false): Array<Object> {

    const events: any[] = [];
    const groupedElements: any = {};
    let groupByKey: string = '';
    if(!isInternalSurvey) {
      groupByKey = 'indskr_assessmentdate';
    }else {
      groupByKey = 'ca.indskr_surveydate';
    }
    
    surveys.map(sv => sv[groupByKey] = new Date(sv[groupByKey]));

    const sortedSurveys = surveys.sort((a, b) => ((new Date(b[groupByKey])).getTime()) - ((new Date(a[groupByKey])).getTime()));

    let groupedSurveysByDate: any = [];

    groupedSurveysByDate = _.groupBy(sortedSurveys, groupByKey);
    groupedSurveysByDate = _.values(groupedSurveysByDate);
    
    if (groupedSurveysByDate) {
        let formattedGroupedSurveysByDate: any = groupedSurveysByDate;
        if (!_.isEmpty(formattedGroupedSurveysByDate)) {
          formattedGroupedSurveysByDate.map(obj => {
            if(obj[0].hasOwnProperty(groupByKey) && obj[0][groupByKey] != '' && obj[0][groupByKey] != "Invalid Date" && obj[0][groupByKey] != undefined) {
              obj[0][groupByKey] = new Date(obj[0][groupByKey]);
            }
          });

          formattedGroupedSurveysByDate.forEach((obj: any) => {
                if(obj[0].hasOwnProperty(groupByKey) && !isNaN(obj[0][groupByKey].valueOf())){

                let item =  this.datePipe.transform(obj[0][groupByKey], 'MMMM y', undefined, this.translate.currentLang)

                if (!(item in groupedElements)) {
                    groupedElements[item] = [];
                }
                let formattedDate = this._formattedDate(obj[0][groupByKey]);

                let formattedName: string = ''
                if(!isInternalSurvey) {
                  if(!isSavedData) {
                    formattedName = obj[0]['_indskr_customer_value_Formatted'];
                  }else {
                    const entityId = obj[0]['indskr_entityid'];
                    if(obj[0]['indskr_entity'] == 'contact') formattedName = this.contactService.getFullNameByContactId(entityId);
                    else formattedName = this.accountService.getAccountById(entityId)?.accountName;
                  }
                } else {
                  const userName: string = this.authService.user.displayName;
                  formattedName = userName;
                }

                let dataWithResponse = undefined;
                if(isSavedData || isInternalSurvey) {
                  dataWithResponse = obj;
                }

                let formattedObj = {
                  modifiedon: formattedDate,
                  name: formattedName,
                  surveyedBy: !isInternalSurvey ? obj[0]['_modifiedby_value_Formatted'] : "",
                  entity: !isInternalSurvey ? obj[0]['indskr_entity'] : "",
                  entityId: !isInternalSurvey ? obj[0]['indskr_entityid'] : "",
                  templateId: !isInternalSurvey ? obj[0]['_indskr_template_value'] : obj[0]['ca.indskr_template'],
                  assessmentId: !isInternalSurvey ? obj[0]['indskr_customerassessmentid'] : obj[0]['_indskr_usersurvey_value'],
                  responses: dataWithResponse,
                  surveyStatus: obj[0]['surveyStatus'] == SurveyStatus.SAVED ? SurveyStatus.SAVED : SurveyStatus.SUBMITTED
                }
                groupedElements[item].push(formattedObj);
              }
            });

            for (let prop in groupedElements) {
                if (groupedElements.hasOwnProperty(prop)) {
                    events.push({
                        key: prop,
                        list: groupedElements[prop]
                    });
                }
            }
        }
    }
    return events;
  }

  private _formattedDate(dateTimeValue): string {
    const formattedDate = this.datePipe.transform(dateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    const formattedTime = dateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    const day = dateTimeValue.getUTCDay()
    const dayOfWeek = this.weekDay[day > 0 ? day-1 : this.weekDay.length - 1];
    const formattedDayOfWeek = this.translate.instant(dayOfWeek);
    return formattedDate + ' ' + formattedTime + ' ' + formattedDayOfWeek;
  }

  // private updateNumOfSubmittedSurveys(entity: string, allSurveyes: CustomerAssessment[]) {
  //   let surveyedList: any[] = [];
  //   if(!_.isEmpty(allSurveyes)) {
  //     let groupedSurveyByTemplate: any = [];
  //     groupedSurveyByTemplate = _.groupBy(allSurveyes,'_indskr_template_value');
  //     groupedSurveyByTemplate = _.values(groupedSurveyByTemplate);
  //     groupedSurveyByTemplate.forEach(group=> {
  //       let entityIdList = [];
  //       let surveyedNumber:number = 0;
  //       group.forEach(resp=> {
  //         entityIdList.push(resp['indskr_entityid']);
  //       });

  //       let id = group[0]['_indskr_template_value'];
  //       surveyedNumber = _.uniq(entityIdList).length;
  //       let data = {
  //         templateId: id,
  //         numOfSurveyed: surveyedNumber
  //       }
  //       surveyedList.push(data);
  //     });
  //   }
  //   if(entity == 'contact') {
  //     this.setAllSurveyedContactInfo(surveyedList)
  //   }else if(entity == 'account') {
  //     this.setAllSurveyedAccountInfo(surveyedList);
  //   }
  // }

  public getNumOfCustomerSurveyed(template: AssessmentTemplate): string {
    const targetEntity = template.indskr_entity;
    const templateId = template.indskr_assessmenttemplateid;
    let numOfSurveyed: number = 0;
    let numOfTotal: number = 0;
    // let surveyedList = this.getAllSurveyedContactInfo();
    let surveyedList = this.getAllSurveyedInfo();
    if(_.isEmpty(surveyedList)) this.loadNumOfSurveyedFromDB(); //In case of the app kill, try to reload the surveyed data.

    if(!_.isEmpty(surveyedList)){
      const idx = surveyedList.findIndex(d=>d.templateId == templateId);
      if(idx>-1) numOfSurveyed = surveyedList[idx].numOfSurveyed ?? 0;
    }
    //In case of the app kill, try to reload the surveyed data.
    // else if(targetEntity == 'contact') {
    //   if(!_.isEmpty(this.allContactSurveys)) {
    //     this.updateNumOfSubmittedSurveys('contact', this.allContactSurveys.filter(survey => survey.surveyStatus != SurveyStatus.SAVED));
    //     surveyedList = this.getAllSurveyedContactInfo();
    //     if(!_.isEmpty(surveyedList)) {
    //       const idx = surveyedList.findIndex(d=>d.templateId == templateId);
    //       if(idx>-1) numOfSurveyed = surveyedList[idx].numOfSurveyed;
    //     }
    //   }else {
    //     this.loadAllContactSurveyFromDB(true).then(()=>{
    //       this.updateNumOfSubmittedSurveys('contact', this.allContactSurveys.filter(survey => survey.surveyStatus == SurveyStatus.SUBMITTED));
    //       surveyedList = this.getAllSurveyedContactInfo();
    //       if(!_.isEmpty(surveyedList)) {
    //         const idx = surveyedList.findIndex(d=>d.templateId == templateId);
    //         if(idx>-1) numOfSurveyed = surveyedList[idx].numOfSurveyed;
    //       }
    //     });
    //   }
    // }

    if(targetEntity == 'contact') {
      const mycontactList = this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true);
      if (template.indskr_metadata.hasOwnProperty('EligibleCustomers')) {
        const eligibleCustomersFrommetaData: [] = template.indskr_metadata['EligibleCustomers'];
        if (!_.isEmpty(eligibleCustomersFrommetaData) && !_.isEmpty(mycontactList)) {  
          let eligibleCustomersIDs: string[] = eligibleCustomersFrommetaData.map(customer=>customer['ID']);
          let mycontactIDs: string[] = mycontactList.map(contact=>contact.ID);
          let matchedMyContactIds: string[] = [];
          matchedMyContactIds = mycontactIDs.filter(contactId => eligibleCustomersIDs.indexOf(contactId) !== -1);
          numOfTotal = matchedMyContactIds.length;
        } else {
          numOfTotal = mycontactList.length;
        }
      } else {
        numOfTotal = mycontactList.length;
      }
    }else {
      numOfTotal = 0; //ToDo: accounts
    }
    // const customerSurveyed: string = `${numOfSurveyed}/${numOfTotal}`;
    const customerSurveyed: string = `${numOfTotal}`;
    return customerSurveyed;
  }

  public getNumOfAccountSurveyed(template: AssessmentTemplate): string {
    const targetEntity = template.indskr_entity;
    const templateId = template.indskr_assessmenttemplateid;
    let numOfSurveyed: number = 0;
    let numOfTotal: number = 0;
    let surveyedList = this.getAllSurveyedInfo();
    if(_.isEmpty(surveyedList)) this.loadNumOfSurveyedFromDB();
    if(!_.isEmpty(surveyedList)){
      const idx = surveyedList.findIndex(d=>d.templateId == templateId);
      if(idx>-1) numOfSurveyed = surveyedList[idx].numOfSurveyed ?? 0;
    }

    if(targetEntity == 'account') {
      const myAccountList = this.accountService.accounts;
      if (template.indskr_metadata.hasOwnProperty('EligibleCustomers')) {
        const eligibleAccountsFrommetaData: [] = template.indskr_metadata['EligibleCustomers'];
        if (!_.isEmpty(eligibleAccountsFrommetaData) && !_.isEmpty(myAccountList)) {  
          let eligibleAccountssIDs: string[] = eligibleAccountsFrommetaData.map(account=>account['ID']);
          let myAccountIDs: string[] = myAccountList.map(account=>account.id);
          let matchedMyAccountIds: string[] = [];
          matchedMyAccountIds = myAccountIDs.filter(accountId => eligibleAccountssIDs.indexOf(accountId) !== -1);
          numOfTotal = matchedMyAccountIds.length;
        } else {
          numOfTotal = myAccountList.length;
        }
      } else {
        numOfTotal = myAccountList.length;
      }
    }else {
      numOfTotal = 0; //ToDo: accounts
    }
    // const accountSurveyed: string = `${numOfSurveyed}/${numOfTotal}`;
    const accountSurveyed: string = `${numOfTotal}`;
    return accountSurveyed;
  }

  public async updateNumOfSurveyedByTemplate(template: AssessmentTemplate) {
    let numOfSurveyed: number = 0;
    let url: string = this.authService.userConfig.activeInstance.url + `/api/data/v9.1/indskr_assessmenttemplates(${template.indskr_assessmenttemplateid})?$select=indskr_customerssurveyed`;
    const response = await this.http.get<any[]>(url).toPromise();
    if(response && response['indskr_customerssurveyed']) numOfSurveyed = response['indskr_customerssurveyed'];
    let idx = this.numOfSurveyed.findIndex(survey => survey['templateId'] == template.indskr_assessmenttemplateid);
    if(idx>-1) this.numOfSurveyed[idx]['numOfSurveyed'] = numOfSurveyed;
    else this.numOfSurveyed.push({
      templateId: template.indskr_assessmenttemplateid,
      numOfSurveyed: numOfSurveyed
    });
    this.saveNumOfSurveyedInDB(this.numOfSurveyed);
    this.setAllSurveyedInfo(this.numOfSurveyed);
  }

  getSurveysByTemplateId(templateID: string, surveyFor: string, internalSurvey = false) {
    let groupedByTemplate;
    let surveysFilteredBytemplate = [];
    let allSurveys = surveyFor == 'contact' ? this.allContactSurveys : this.allAccountSurveys;

    if (!internalSurvey) {
      if (!_.isEmpty(allSurveys)) {
        surveysFilteredBytemplate = allSurveys.filter(a=>a['_indskr_template_value'] == templateID);
      }
    } else {
      if (!_.isEmpty(this.allInternalSurveys)) {
        groupedByTemplate = _.groupBy(this.allInternalSurveys,'ca.indskr_template');
        if (groupedByTemplate){
          surveysFilteredBytemplate = groupedByTemplate[templateID];
        }
      }
    }
    
    return surveysFilteredBytemplate
  }

  getSurveysByContactId(contactID: string) {
    let groupedByContactId: any = [];
    let surveysFilteredByContactId = [];

    if(!_.isEmpty(this.allContactSurveys)) {
      groupedByContactId = _.groupBy(this.allContactSurveys, 'indskr_entityid');
    }
    if(!_.isEmpty(groupedByContactId)){
      surveysFilteredByContactId = groupedByContactId[contactID];
    }

    return surveysFilteredByContactId;
  }


  private async saveAllInternalSurveys(internalSurveys) {
    const id = DB_KEY_PREFIXES.ALL_INTERNAL_SURVEYS;
    if(!_.isEmpty(internalSurveys)) {
      try {
        await this.disk.updateOrInsert(id, (doc) => { 
          if(!doc || !doc.raw) {
            doc= {
              raw:[]
            }
          }
          internalSurveys.map(surveyResp => {
            if(surveyResp['surveyStatus'] != SurveyStatus.SAVED) {
              surveyResp['surveyStatus'] = SurveyStatus.SUBMITTED;
             }
          });
          doc.raw = internalSurveys;
          return doc;
        });
      } catch (error) {
        console.log("Error saving all contact internal survey in DB", error);
      }
    }
  }


  private async loadAllInternalSurveys(hasCustomerSurveyEnabled: boolean) {
    if(!hasCustomerSurveyEnabled) return;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.INTERNAL_SURVEY).then((data) => {
        this.allInternalSurveys = data ? data.raw : [];
      });
    } catch(error) {
      console.log("Error loading all contact survey from DB", error);
    }
  }


  public async fetchAllInternalSurvey(forceFullSync: boolean = false): Promise<InternalSurvey[]> {
    const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ALL_INTERNAL_SURVEY);
    const isInitialSync = forceFullSync || !syncState || !syncState.lastUpdatedTime;
    const newLastUpdatedTime = new Date().getTime();

    const all_InternalSurvey_Info: EntitySyncInfo = {
      entityName: EntityNames.internal_user_survey_response,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };


    let fetchXml = USER_SURVEY_FETCHXML.replace('{userID}', this.authService.user.systemUserID)
    if(isInitialSync) {
      fetchXml = fetchXml.replace('{deltaCondition}', '');
      await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACT_SURVEYS);
    } else {
      const deltaSyncFilter = `<condition attribute="modifiedon" operator="ge" value="` + new Date(syncState.lastUpdatedTime).toISOString() + `" />`;
      fetchXml = fetchXml.replace('{deltaCondition}', deltaSyncFilter);
    }
    let internalSurveys = [];
    
    await this.customerAssessDataService.getInternalSurvey(fetchXml).then( async(rawAllData) => {
        if(!_.isEmpty(rawAllData)) {
          this.saveAllInternalSurveys(rawAllData);
          internalSurveys = rawAllData;
        }
        syncState.lastUpdatedTime = newLastUpdatedTime;
        await this.disk.updateSyncState(syncState);
        if(_.isArray(rawAllData)) {
          all_InternalSurvey_Info.totalSynced = rawAllData.length;
        }
        this.deltaService.addEntitySyncInfo(all_InternalSurvey_Info);
      }).catch(error=> {
        console.error("Error occurred while fetching all contact survey data...", error)
        this.deltaService.addSyncErrorToEntitySyncInfo(all_InternalSurvey_Info, 'indskr_customerassessmentresponse', error);
        this.deltaService.addEntitySyncInfo(all_InternalSurvey_Info);
      })
    return internalSurveys;
  }


  private async getAllInternalSurveys(hasCustomerSurveyEnabled: boolean) {
    if(!hasCustomerSurveyEnabled) return;
    this.allInternalSurveys = [];
    if (!_.isEmpty(this.surveytemplates)) {
      const internalSurveyTemplates  = this.surveytemplates.filter(template => template.indskr_surveytype === SurveyType.INTERNAL)
      if (!_.isEmpty(internalSurveyTemplates)) {
        try {
          let surveyTemplateIds =[];
          internalSurveyTemplates.forEach(template=>{
            surveyTemplateIds.push(template.indskr_assessmenttemplateid);
          });
          await this.fetchAllInternalSurvey(false).then(async(data)=>{
            if(!_.isEmpty(data)) {
              data.map(d => {
                if(d['surveyStatus'] != SurveyStatus.SAVED) {
                  d['surveyStatus'] = SurveyStatus.SUBMITTED;
                 }
              });
            }
            this.saveInternalSurveyInDB(data);
            this.allInternalSurveys = data;
          })
        } catch (error) {
          console.error('failed to getAllContactAssessments ', error);
        }
      }
    }
  }

  public async getSurveyedContactsForOnetimeSurvey(hasCustomerSurveyEnabled: boolean) {
    if(!hasCustomerSurveyEnabled) return;
    this.surveyedContactsForOnetimeSurvey = [];
    if (!_.isEmpty(this.surveytemplates)) {
      const onetimeSurveyTemplates  = this.surveytemplates.filter(template => template.indskr_surveytype === SurveyType.EXTERNAL && template.indskr_surveyfrequency === SurveyFrequency.ONCE && template.indskr_entity == 'contact');
      if (!_.isEmpty(onetimeSurveyTemplates)) {
        try {
          let surveyTemplateIds =[];
          onetimeSurveyTemplates.forEach(template=>{
            surveyTemplateIds.push(template.indskr_assessmenttemplateid);
          });
          await this.fetchSurveyedEntitiesByTemplates('contact', surveyTemplateIds).then(async(data)=>{
            this.saveSurveyedEntitiesByTemplates(data, 'contact');
            this.surveyedContactsForOnetimeSurvey = data;
          })
        } catch (error) {
          console.error('failed to getSurveyedContactsForOnetimeSurvey ', error);
        }
      }
    }
  }

  public async getSurveyedAccountsForOnetimeSurvey(hasCustomerSurveyEnabled: boolean) {
    if(!hasCustomerSurveyEnabled) return;
    if (!_.isEmpty(this.surveytemplates)) {
      const onetimeAccountSurveyTemplates  = this.surveytemplates.filter(template => template.indskr_surveytype === SurveyType.EXTERNAL && template.indskr_surveyfrequency === SurveyFrequency.ONCE && template.indskr_entity == 'account');
      if (!_.isEmpty(onetimeAccountSurveyTemplates)) {
        try {
          let surveyTemplateIds =[];
          onetimeAccountSurveyTemplates.forEach(template=>{
            surveyTemplateIds.push(template.indskr_assessmenttemplateid);
          });
          await this.fetchSurveyedEntitiesByTemplates('account', surveyTemplateIds).then(async(data)=>{
            this.saveSurveyedEntitiesByTemplates(data, 'account');
            this.surveyedAccountsForOnetimeSurvey = data;
          })
        } catch (error) {
          console.error('failed to getSurveyedAccountsForOnetimeSurvey ', error);
        }
      }
    }
  }


  public async getAllInternalSurveysFromDB(): Promise<InternalSurvey[]> {

    const id = DB_KEY_PREFIXES.INTERNAL_SURVEY;
    const doc = await this.disk.retrieve(id, true);
    return doc ? doc.raw : [];
  }


  private async saveInternalSurveyInDB(internalSurveys: InternalSurvey[]) {
    let key = DB_KEY_PREFIXES.INTERNAL_SURVEY;
    try {
      await this.disk.updateOrInsert(key, doc => ({ raw: internalSurveys })).catch((err) => {
        console.error("Saving Survey: Error saving internalSurvey data to offline db!", err)
      });
    } catch(err) {
      console.log(err)
    }
    

  }

  // public async saveInternalSurvey(currentFormValue: any,
  //                            selectedTemplate: AssessmentTemplate, 
  //                            deletedFormValues: any, 
  //                            appointmentId?: string,
  //                            isValidToSubmit?: boolean): Promise<InternalSurvey>  {

  //     let internalSurvey

  //     let surveysFromDB = await this.getAllInternalSurveysFromDB()

  //     let savedSurveys = surveysFromDB.filter(internalSurvey => internalSurvey.indskr_template === selectedTemplate.indskr_assessmenttemplateid && internalSurvey.surveyStatus  === SurveyStatus.SAVED)

  //     const isOffline: boolean = this.device.isOffline;

  //     if (!_.isEmpty(savedSurveys)) { internalSurvey = savedSurveys[0];}
  //     else {

  //        internalSurvey = new InternalSurvey(
  //         Guid.create().toString(), 
  //         selectedTemplate.indskr_assessmenttemplateid, 
  //         selectedTemplate.indskr_name, 
  //         new Date().toISOString().toString(), 
  //         new Date().toISOString().toString(), 
  //         [],
  //         this.authService.user.systemUserID,
  //         selectedTemplate.indskr_type,
  //         selectedTemplate.indskr_surveytype,
  //         selectedTemplate.indskr_surveyfrequency,
  //         appointmentId,
  //         isValidToSubmit);

  //       if(isOffline) internalSurvey.isInitialAssessInOffline = true;
  //     }


  //     let responses = [];
  //     for (let [key, value] of Object.entries(currentFormValue)) {
  //       if (key.includes('_')) {
  //         this._fromSectionSpecific(value, responses);
  //       } else {
  //         responses = responses.concat(value);
  //       }
  //     }
  
  
  //     if (!_.isEmpty(deletedFormValues)) {
  //       for (let [key, value] of Object.entries(deletedFormValues)) {
  //         if (key.includes('_')) {
  //           this._fromSectionSpecific(value, responses);
  //         } else {
  //           responses = responses.concat(value);
  //         }
  //       }
  //     }

  //     internalSurvey.responses = responses

  //     internalSurvey.pendingPushToDynamics = isOffline;
  //     internalSurvey.surveyStatus = SurveyStatus.SAVED;
  //     internalSurvey.isValidToSubmit = isValidToSubmit;
        
  //     let index = surveysFromDB.findIndex(survey => survey.indskr_usersurveyid === internalSurvey.indskr_usersurveyid)
  
  //     index >=0 ? surveysFromDB[index] = internalSurvey: surveysFromDB.push(internalSurvey);
  
  //     await this.saveInternalSurveyInDB(surveysFromDB);
  
  //     return internalSurvey;
  // }

  public async prepareInternalSurveyPayload(currentFormValue: any, 
                                            selectedTemplate: AssessmentTemplate,
                                            deletedFormValues: any, 
                                            status: SurveyStatus,
                                            appointmentId?: string,
                                            isValidToSubmit?: boolean): Promise<InternalSurvey> {
                                              
      const isOffline: boolean = this.device.isOffline;
      let internalSurvey
      let surveysFromDB = await this.getAllInternalSurveysFromDB()
      let savedSurveys = surveysFromDB.filter(internalSurvey => internalSurvey['ca.indskr_template'] === selectedTemplate.indskr_assessmenttemplateid && internalSurvey.surveyStatus  === SurveyStatus.SAVED)

      internalSurvey = new InternalSurvey(
        !_.isEmpty(savedSurveys) ? savedSurveys[0]['indskr_usersurveyid'] || savedSurveys[0]['_indskr_usersurvey_value'] : Guid.create().toString(), 
        selectedTemplate.indskr_assessmenttemplateid, 
        selectedTemplate.indskr_name, 
        new Date().toISOString().toString(), 
        new Date().toISOString().toString(), 
        [],
        this.authService.user.systemUserID,
        selectedTemplate.indskr_type,
        selectedTemplate.indskr_surveytype,
        selectedTemplate.indskr_surveyfrequency,
        appointmentId,
        status,
        isValidToSubmit);
      if(isOffline) internalSurvey.isInitialAssessInOffline = true;

      // if (!_.isEmpty(savedSurveys)) internalSurvey.indskr_usersurveyid = savedSurveys[0]['indskr_usersurveyid'] || savedSurveys[0]['_indskr_usersurvey_value'] ;
      let responses = [];
      for (let [key, value] of Object.entries(currentFormValue)) {
        if(Object.keys(value).every(val => val.includes('-'))) {
          let attribute = _.cloneDeep(value);
          for(let [key, val] of Object.entries(attribute)) {
            if (key.includes('_')) {
              this._fromSectionSpecific(val, responses);
            } else if(val.hasOwnProperty('@odata.etag')) {
              responses = responses.concat(this.mapSavedResponses(val));
            } else {
              responses = responses.concat(val);
            }
          }
        } else {
          if (key.includes('_')) {
            this._fromSectionSpecific(value, responses);
          } else if(value.hasOwnProperty('@odata.etag')) {
            responses = responses.concat(this.mapSavedResponses(value));
          } else {
            responses = responses.concat(value);
          }
        }
      }
    
      if (!_.isEmpty(deletedFormValues)) {
        for (let [key, value] of Object.entries(deletedFormValues)) {
          if(Object.keys(value).every(val => val.includes('-'))) {
            let attribute = _.cloneDeep(value);
            for(let [key, val] of Object.entries(attribute)) {
              if (key.includes('_')) {
                this._fromSectionSpecific(val, responses);
              } else {
                responses = responses.concat(val);
              }
            }
          } else {
            if (key.includes('_')) {
              this._fromSectionSpecific(value, responses);
            } else {
              responses = responses.concat(value);
            }
          }
        }
      }

      internalSurvey.responses = responses
      internalSurvey.pendingPushToDynamics = isOffline;
      internalSurvey.surveyStatus = status;
      internalSurvey.isValidToSubmit = isValidToSubmit;
        
      // let index = surveysFromDB.findIndex(survey => survey.indskr_usersurveyid === internalSurvey.indskr_usersurveyid)  
      // index >=0 ? surveysFromDB[index] = internalSurvey: surveysFromDB.push(internalSurvey);  
      // await this.saveInternalSurveyInDB(surveysFromDB);  
      return internalSurvey;
}

    // public async submitInternalSurvey(currentFormValue: any, selectedTemplate: AssessmentTemplate, deletedFormValues: any, appointmentId?: string): Promise<InternalSurvey> {

    //   const templateId: string = selectedTemplate.indskr_assessmenttemplateid;
  
    //   const internalsurveysFromDB: InternalSurvey[] = await this.getAllInternalSurveysFromDB();

    //   const savedInternalSurveys = internalsurveysFromDB.filter(internalSurvey => internalSurvey.indskr_template === selectedTemplate.indskr_assessmenttemplateid && internalSurvey.surveyStatus === SurveyStatus.SAVED);

    //   let surveyToBeSubmitted;

    //   if (!_.isEmpty(savedInternalSurveys)) {
    //     surveyToBeSubmitted = savedInternalSurveys[0];
    //   }
  
    //   const isOffline: boolean = this.device.isOffline;

    //   if(!isOffline && !_.isEmpty(surveyToBeSubmitted)) {
    //       const response = await this.customerAssessDataService.saveInternalSurvey(surveyToBeSubmitted).catch((error) => {
    //         console.error("Failed to save Internal survey ", error);
    //       });
  
    //       if(!_.isEmpty(response)) {
    //         surveyToBeSubmitted['surveyStatus'] = SurveyStatus.SUBMITTED;
    //         let index = internalsurveysFromDB.findIndex(survey => survey.indskr_template === surveyToBeSubmitted.indskr_template);
    //         index >=0 ? internalsurveysFromDB[index] = surveyToBeSubmitted: internalsurveysFromDB.push(surveyToBeSubmitted);
    //         await this.saveInternalSurveyInDB(internalsurveysFromDB);
    //         await this.customerAssessDataService.getInternalSurvey(USER_SURVEY_FETCHXML.replace('{userID}',this.authService.user.systemUserID)).then((rawDaata) => {
    //           this._processInternalSurveySubmitResponse(rawDaata)
    //         });
    //       }
    //   }

    //   return surveyToBeSubmitted;
    // }

    public async saveAndSubmitInternalSurvey(currentFormValue: any, selectedTemplate: AssessmentTemplate, deletedFormValues: any, status:SurveyStatus, isValidToSubmit?: boolean, appointmentId?: string): Promise<InternalSurvey> {
      const isOffline: boolean = this.device.isOffline;
      const isSubmitted: boolean = status == SurveyStatus.SUBMITTED;
      const internalsurveysFromDB: InternalSurvey[] = await this.getAllInternalSurveysFromDB();

      let surveyToBeUpdated: InternalSurvey;
      await this.prepareInternalSurveyPayload(currentFormValue, selectedTemplate, deletedFormValues, status, appointmentId, isValidToSubmit).then((data) => {
        if(!_.isEmpty(data)) surveyToBeUpdated = data;
      })
     
      if(!isOffline && !_.isEmpty(surveyToBeUpdated)) {
        const response = await this.customerAssessDataService.saveInternalSurvey(surveyToBeUpdated).catch((error) => {
          console.error("Failed to save Internal survey ", error);
        });

        if(!_.isEmpty(response)) {
          // surveyToBeUpdated['surveyStatus'] = status;
          // let index = internalsurveysFromDB.findIndex(survey => survey['ca.indskr_template'] === surveyToBeUpdated.indskr_template);
          // index >=0 ? internalsurveysFromDB[index] = surveyToBeUpdated: internalsurveysFromDB.push(surveyToBeUpdated);
          // await this.saveInternalSurveyInDB(internalsurveysFromDB);
          await this.customerAssessDataService.getInternalSurvey(USER_SURVEY_FETCHXML.replace('{userID}',this.authService.user.systemUserID)).then((rawData) => {
            this._processInternalSurveySubmitResponse(rawData);
          });
        }
      }
      return surveyToBeUpdated;
    }

    private async _processInternalSurveySubmitResponse(data) {
        if(!_.isEmpty(data)) {
          data.map(d => {
            if(d['surveyStatus'] != SurveyStatus.SAVED) {
              d['surveyStatus'] = SurveyStatus.SUBMITTED;
            }
          });
        }
        await this.saveInternalSurveyInDB(data);
        this.allInternalSurveys = data;
    }
    
  public discardChagesForSurvey(): Promise<Boolean> {
    let cancelText = this.translate.instant("CANCEL");
    if(this.translate.currentLang == 'it') {
      cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
    }
    return new Promise<Boolean>(resolve => {
      this.alertService.showAlert({
        title: this.translate.instant('DISCARD_CHANGES'),
        message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')
      }, this.translate.instant('DISCARD'), cancelText
      ).then(res => {
        if (res.role == "ok") {
          this.isSurveyFormDirty = false;
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  public getInternalSurveyStatus(survey: AssessmentTemplate): InternalSurveyStatus {
    let currentStatus: InternalSurveyStatus = InternalSurveyStatus.PENDING;
    const templateId: string = survey.indskr_assessmenttemplateid;
    const allInternalSurveys = this.allInternalSurveys;
    if(!_.isEmpty(allInternalSurveys)) {
      const filteredAllInternalSurvyes = allInternalSurveys.filter(survey=>survey['ca.indskr_template'] == templateId);
      if(!_.isEmpty(filteredAllInternalSurvyes) && filteredAllInternalSurvyes.some(survey=>survey.surveyStatus == SurveyStatus.SUBMITTED)) {
        currentStatus = InternalSurveyStatus.COMPLETED;
      }
    }
    return currentStatus;
  }

  public isCapturedOneTimeSurvey(templateId, entityId, surveyFor:string): boolean {
    let isCaptured: boolean = false;
    const allSurveysByTemplate = this.getSurveysByTemplateId(templateId, surveyFor);
    if(!_.isEmpty(allSurveysByTemplate)) {
      const responsesGroupedByContact = _.groupBy(allSurveysByTemplate,'indskr_entityid');
      if(!_.isEmpty(responsesGroupedByContact) && !_.isEmpty(responsesGroupedByContact[entityId])) {
        isCaptured = true;
      }
    }
    return isCaptured;
  }

  public getAllContactSurveysForAppt(): CustomerAssessment {
    return this.allContactSurveysForAppt.getValue();
  }

  public setAllContactSurveysForAppt(survey: CustomerAssessment) {
    this.allContactSurveysForAppt.next(survey);
  }

  public async mergeSavedandSubmittedSurveysForAppt(hasCustomerSurveyEnabled: boolean) {
    if(!hasCustomerSurveyEnabled) return;
    this.allContactSurveysByAssessment = [];
    // let submittedSurveys = _.map(this.allContactSurveys, _.partialRight(_.pick, ['indskr_customerassessmentid', '_indskr_appointment_value', '_indskr_template_value', 'indskr_entity','indskr_entityid','indskr_name']));
    let submittedSurveys = _.cloneDeep([...this.allContactSurveys, ...this.allAccountSurveys]);
    submittedSurveys = submittedSurveys.filter(sub => sub['_indskr_appointment_value'] && sub['_indskr_appointment_value'].length > 0);
    try {
      await this.getAllCustomerSurveys().then((surveys: CustomerAssessment[]) => {
        let savedSurveys: any[] = [];
        if(!_.isEmpty(surveys)) {
          surveys.forEach((survey) => {
            if(survey['raw'] && _.isArray(survey['raw'])) {
              let uniq: any[] = survey['raw'].filter((s) => !submittedSurveys.some((submitted) => submitted['indskr_customerassessmentid'] == s['indskr_customerassessmentid']));
              savedSurveys = [...savedSurveys, ...uniq];
            }
          })
          this.allContactSurveysByAssessment = submittedSurveys.concat(savedSurveys);
        } else {
          this.allContactSurveysByAssessment = submittedSurveys;
        }
      });
    } catch {
      console.log("Error - fail to merge Saved and Submitted Surveys for Appointment");
      this.allContactSurveysByAssessment = submittedSurveys;
    } 
  }

  public isAssessedContact(contactId: string): boolean {
    let isAssessedContact: boolean = false;
    if(_.isEmpty(this.assessedContacts)) {
      this.loadAssessedContactsFromDB();
    }
    if(!_.isEmpty(this.assessedContacts)) {
      isAssessedContact = this.assessedContacts.some(x=>x.contactid == contactId);
    }
    return isAssessedContact;
  }

  public async fetchSurveyForTimelinesByTemplates(surveyFor:string, contactId: string, templateIds: string[]): Promise<CustomerAssessment[]> {
    let templatesFilterCondition = '';
    if(!_.isEmpty(templateIds)) {
      templateIds.forEach(tId => { 
        if(tId) {
          templatesFilterCondition +=`<value>${tId}</value>\n`
        }
      });
    }

    const userPositionId = this.authService.user.xPositionID;

    let fetchXml = SURVEY_FOR_TIMELINE_FETCHXML.replace('{indskr_entity}', surveyFor)
      .replace('{BUId}', this.authService.user.xBusinessUnitId)
      .replace('{userPosition}', userPositionId)
      .replace('{parentUserPosition}', userPositionId)
      .replace('{templatesCondition}', templatesFilterCondition)
      .replace('{indskr_entityid}', contactId);

    let rawAllData = [];

    try {
      let response = await this.dynamics.executeFetchXml(
        'indskr_customerassessments',
        fetchXml
      );
      if (response) {
        if (Array.isArray(response.value)) {
          let filteredRawData = _.map(response.value, _.partialRight(_.pick, 
              [ 'indskr_customerassessmentid',
                'indskr_assessmentdate',
                'indskr_entityid',
                'indskr_name',
                '_indskr_template_value',
                '_modifiedby_value_Formatted',
                '_indskr_customer_value_Formatted',
                '_indskr_appointment_value',
                'surveyStatus'
              ]
            ));
          rawAllData.push(...filteredRawData);
        }
        if(surveyFor == SurveyCategory.CONTACT) this.contactSurveysForTimeline = rawAllData;
        else this.accountSurveysForTimeline = rawAllData;
      }
    } catch (error) {
      console.error('fetching error - indskr_customerassessments: ', error);
    }

    return rawAllData;
  }

  public saveSurveyTimelineInDB(surveyTimeline: any[], entityId, surveyFor: SurveyCategory) {
    let dbKey = surveyFor == SurveyCategory.CONTACT ? DB_KEY_PREFIXES.CUSTOMER_SURVEY_TIMELINE : DB_KEY_PREFIXES.ACCOUNT_SURVEY_TIMELINE;
    const id = dbKey + entityId;
    if(!_.isEmpty(surveyTimeline) && surveyTimeline.length > 0) {
      try {
        this.disk.updateOrInsert(id, (doc) => { 
          if(!doc || !doc.raw) {
            doc= {
              raw:[]
            }
          }
          doc.raw = surveyTimeline;
          console.log(`Saved surveys for contact/account timeline in DB: ${surveyTimeline.length}`);  
          return doc;
        });
      } catch (error) {
        console.log("Error saving surveys for contact/account timeline in DB: ", error);
      }
    }
  }
  
  public loadSurveysByContactIdForTimeline(contactID: string) {
    let timelineDataByContactId: any = [];
    
    const id = DB_KEY_PREFIXES.CUSTOMER_SURVEY_TIMELINE + contactID;

    try {
      this.disk.retrieve(id).then((data) => {
        timelineDataByContactId = data ? data.raw : [];
      });
    } catch(error) {
      console.log("Error loading all contact survey from DB", error);
    }
    this.contactSurveysForTimeline = timelineDataByContactId;
  }

  public async loadSurveysByAccountIdForTimeline(accountID: string) {
    let timelineDataByAccountId: any = [];
    
    const id = DB_KEY_PREFIXES.ACCOUNT_SURVEY_TIMELINE + accountID;
    let timelineData = await this.disk.retrieve(id);
    if(timelineData) {
      timelineDataByAccountId = timelineData ? timelineData['raw'] : [];
      this.accountSurveysForTimeline = timelineDataByAccountId;
    }
    return timelineDataByAccountId;
    // try {
    //   this.disk.retrieve(id).then((data) => {
    //     timelineDataByAccountId = data ? data.raw : [];
    //   });
    // } catch(error) {
    //   console.log("Error loading all account survey from DB", error);
    //   this.accountSurveysForTimeline = [];
    // }
    // this.accountSurveysForTimeline = timelineDataByAccountId;

  }

  public groupSurveyTimelineData(surveys:any[], contactID) {
    let groupedByContactId: any = [];
    let surveysFilteredByContactId = [];
    if(!_.isEmpty(surveys)) {
      groupedByContactId = _.groupBy(surveys, 'indskr_entityid');
    }
    if(!_.isEmpty(groupedByContactId)){
      surveysFilteredByContactId = groupedByContactId[contactID];
    }
    return surveysFilteredByContactId;
  }

  public async fetchSurveyedEntitiesByTemplates(surveyFor:string, templateIds: string[]): Promise<any[]> {
    let templatesFilterCondition = '';
    if(!_.isEmpty(templateIds)) {
      templateIds.forEach(tId => { 
        if(tId) {
          templatesFilterCondition +=`<value>${tId}</value>\n`
        }
      });
    }

    const userPositionId = this.authService.user.xPositionID;

    let fetchXml = SURVEYED_ENTITIES_FOR_ONETIME_SURVEY_FETCHXML.replace('{indskr_entity}', surveyFor)
      .replace('{BUId}', this.authService.user.xBusinessUnitId)
      .replace('{userPosition}', userPositionId)
      .replace('{parentUserPosition}', userPositionId)
      .replace('{templatesCondition}', templatesFilterCondition)

    let rawAllData: any = [];

    try {
      let response = await this.dynamics.executeFetchXml(
        'indskr_customerassessments',
        fetchXml
      );
      if (response) {
        if (!_.isEmpty(response.value) && Array.isArray(response.value)) {
          rawAllData = _.groupBy(response.value, 'templateid');
        }
        if(surveyFor == SurveyCategory.CONTACT) this.surveyedContactsForOnetimeSurvey = rawAllData;
        else this.surveyedAccountsForOnetimeSurvey = rawAllData;
      }
    } catch (error) {
      console.error('fetching error - surveyed entities: ', error);
      this.surveyedContactsForOnetimeSurvey;
    }
    return rawAllData;
  }

  private async saveSurveyedEntitiesByTemplates(data: any[], captureFor: string) {
    let key = captureFor == 'contact' ? DB_KEY_PREFIXES.SURVEYED_CONTACTS_FOR_ONETIME_SURVEY : DB_KEY_PREFIXES.SURVEYED_ACCOUNTS_FOR_ONETIME_SURVEY;
    try {
      await this.disk.updateOrInsert(key, doc => ({ raw: data })).catch((err) => {
        console.error("Saving Survey: Error saving surveyed entities data to offline db!", err)
      });
    } catch(err) {
      console.log(err)
    }
  }

  public async loadSurveyedContactsByTemplates(hasCustomerSurveyEnabled: boolean) {
    if(!hasCustomerSurveyEnabled) return;
    let key = DB_KEY_PREFIXES.SURVEYED_CONTACTS_FOR_ONETIME_SURVEY;
    try {
      await this.disk.retrieve(key).then((data) => {
        this.surveyedContactsForOnetimeSurvey = data ? data.raw : [];
      });
    } catch(error) {
      console.log("Error loading all surveyed entities from DB", error);
    }
  }

  public async loadSurveyedAccountsByTemplates(hasAccountSurveyEnabled: boolean) {
    if(!hasAccountSurveyEnabled) return;
    let key = DB_KEY_PREFIXES.SURVEYED_ACCOUNTS_FOR_ONETIME_SURVEY;
    try {
      await this.disk.retrieve(key).then((data) => {
        this.surveyedAccountsForOnetimeSurvey = data ? data.raw : [];
      });
    } catch(error) {
      console.log("Error loading all surveyed accounts from DB", error);
    }
  }
  

  public getSurveyCategoryValue(survey: AssessmentTemplate) {
    return survey.indskr_surveytype == SurveyType.INTERNAL ? SurveyCategory.INTERNAL : survey.indskr_entity == 'account' ? SurveyCategory.ACCOUNT : SurveyCategory.CONTACT;
  }

  public checkBUConfig() {
    if (this.authService.user.buConfigs && this.authService.user.buConfigs['indskr_capturehcpinfoforaccountsurvey']) {
      this.captureHCPforAccountSurvey = true;
    }

  }

  public async getNumOfSurveyed(hasCustomerSurveyEnabled) {
    if(!hasCustomerSurveyEnabled) return;
    if (!_.isEmpty(this.surveytemplates)) {
      let templatesFilterCondition ='';
      this.surveytemplates.forEach(template=>{
        templatesFilterCondition +=`<value>${template.indskr_assessmenttemplateid}</value>\n`
      });
      let fetchXml = NUMBER_OF_SURVEYED_FETCHXML.replace('{templatesCondition}', templatesFilterCondition);
      let rawAllData: any = [];
      try {
        let response = await this.dynamics.executeFetchXml(
          'indskr_assessmenttemplates',
          fetchXml
        );
        if (response) {
          if (!_.isEmpty(response.value) && Array.isArray(response.value)) {
            response.value.forEach((val) => {
              rawAllData.push({
                templateId: val['indskr_assessmenttemplateid'],
                numOfSurveyed: val['indskr_customerssurveyed']
              })
            })
          }
          this.numOfSurveyed = rawAllData;
          this.saveNumOfSurveyedInDB(rawAllData);
          this.setAllSurveyedInfo(rawAllData);
        }
      } catch (error) {
        console.error('fetching error - number of surveyed: ', error);
      }
      return rawAllData;
    }
  }

  private async saveNumOfSurveyedInDB(numOfSurveyed: any[]) {
    const id = DB_KEY_PREFIXES.NUM_OF_SURVEYED;
    if(!_.isEmpty(numOfSurveyed) && numOfSurveyed.length > 0) {
      try {
        await this.disk.updateOrInsert(id, (doc) => { 
          if(!doc || !doc.raw) {
            doc= {
              raw:[]
            }
          }
          doc.raw = numOfSurveyed;
          return doc;
        });
      } catch (error) {
        console.log("Error saving all num of surveyed in DB", error);
      }
    }
  }

  public async loadNumOfSurveyedFromDB() {
    await this.disk.retrieve(DB_KEY_PREFIXES.NUM_OF_SURVEYED).then((data) => {
        this.numOfSurveyed = data ? data.raw : [];
        this.setAllSurveyedInfo(this.numOfSurveyed);
      });
  }

}
