import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BrandOfflineService } from '../../services/brand/brand.service';
import { Endpoints } from '../../../config/endpoints.config';
import { DiskService } from '../../services/disk/disk.service';
import { AuthenticationService } from '../../services/authentication.service';
import { DeviceService } from '../../services/device/device.service';
import { EntitySyncInfo, EntityNames, DeltaService } from '../delta/delta.service';
import { DB_KEY_PREFIXES, DB_SYNC_STATE_KEYS } from '../../config/pouch-db.config';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { differenceInHours } from 'date-fns';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import _ from 'lodash';
import { buildConfiguration } from '@azure/msal-browser/dist/config/Configuration';

@Injectable({
  providedIn: 'root'
})
export class BrandDataService {

    constructor(
        private http: HttpClient,
        private brandService: BrandOfflineService,
        private disk: DiskService,
        private authenticationService: AuthenticationService,
        private device: DeviceService,
        private deltaService: DeltaService,
        private dynamics: DynamicsClientService,
    ) {}

    /**
     * Returns a boolean wether the network call to pull brands was successful or not.
     * The resulting data, like always, will be available in the offline data service
     * 
     * @memberof BrandDataService
     */
    async getBrands(loadFromDbOnly = false) {
        if (!(loadFromDbOnly || this.device.isOffline)) {
            let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.brands.GET_BRANDS;

            const positions = this.authenticationService.user.positions.map((o)=>{
                return o.ID
            })
            url = url.replace('{{positionIDs}}',positions.toString())
            const brandSyncInfo: EntitySyncInfo = {
                entityName: EntityNames.product,
                totalFailed: 0,
                totalSynced: 0,
                errors: [],
                syncStatus: true
            };

            try {
                let response = await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER)
                .toPromise();
                if (Array.isArray(response)) {
                    brandSyncInfo.totalSynced = response.length;
                }
                this.brandService.mapBrands(response);    
            } catch (error) {
                this.deltaService.addSyncErrorToEntitySyncInfo(brandSyncInfo, url, error);
            }
            this.deltaService.addEntitySyncInfo(brandSyncInfo);
        } else {
            const brands = await this.disk.retrieve(DB_KEY_PREFIXES.PRODUCT, true);
            if (brands) {
                this.brandService.mapBrands(brands.raw, true);
            } else {
                console.warn('getBrands: Could not fetch products');
            }
        }
    }

    public async getOfflineBrands() {
        //if(this.disk.check('brands')){
            await this.disk.retrieve(DB_KEY_PREFIXES.PRODUCT).then(brands =>{
                if(brands && brands.raw) this.brandService.mapBrands(brands.raw, true);
            }).catch (diskError => {
            console.error('Caught a disk error trying to load brands from disk', diskError);
            });
        //}
    }

    public async syncProductIndications(fullSync?: boolean, loadFromDBOnly = false) {
      if (!this.device.isOffline && (this.authenticationService.hasFeatureAction(FeatureActionsMap.ENABLE_INDICATIONS))) {
        let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.PRODUCT_INDICATIONS);
        let lastUpdatedTime;
        if (offlineData && offlineData.raw && !fullSync) {
          if (offlineData.lastUpdatedTime) {
            lastUpdatedTime = offlineData.lastUpdatedTime;
          }
        }
        if (!loadFromDBOnly) {
          let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_PRODUCT_INDICATIONS);
          const bulkProductIndicaitonsSyncInfo: EntitySyncInfo = {
            entityName: EntityNames.product_indications,
            totalFailed: 0,
            totalSynced: 0,
            errors: [],
            syncStatus: true
          };
          const now = new Date().getTime();
          let fetchXML = fetchQueries.productIndicationsAndKeyMessagesByPositionIds;
          let positionIds = this.authenticationService.user.positions.map(o => {
            return o.ID
          });
          let positionString = '';
          positionIds.forEach(p => {
            positionString += '<value>' + p + '</value>'
          })
          fetchXML = fetchXML.split('{PositionIds1}').join(positionString);
          fetchXML = fetchXML.split('{PositionIds2}').join(positionString);
          if (lastUpdatedTime && !fullSync) {
            let hourDifference = differenceInHours(now, new Date(lastUpdatedTime));
            hourDifference += 1
            fetchXML = fetchXML.replace('{hourDifference}', `${hourDifference}`);
          } else {
            fetchXML = fetchXML.replace('<filter type="and"><condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" /></filter>', '');
          }
  
          let response = await this.dynamics.executeFetchQuery('products', fetchXML);
  
          lastUpdatedTime = new Date().getTime();
          if (response && Array.isArray(response) && response.length != 0 && response[0]) {
            bulkProductIndicaitonsSyncInfo.totalSynced += response.length;            
            if (offlineData && offlineData.raw && !fullSync) {
              response.forEach(item => {
                try {
                  if(item['productid']){
                    let idx = offlineData.raw.findIndex(a => a.productid == item.productid);
                    if(idx >= 0){
                      let indIdx = offlineData.raw[idx].productIndications.findIndex(b => b.indskr_productindicationid == item['productIndications.indskr_productindicationid']);
                      if(indIdx >= 0){
                        if(item['productIndications.indskr_productindicationid'] && item['productIndications.statecode'] == 0){
                          if(item['productIndicationsKeyMessages.indskr_keymessageid']){
                            let indKeyMsgIdx = offlineData.raw[idx].productIndications[indIdx].productIndicationsKeyMessages.findIndex(a=> a.indskr_keymessageid == item['productIndicationsKeyMessages.indskr_keymessageid']);
                            if(indKeyMsgIdx >= 0){
                              if(item['productIndicationsKeyMessages.statecode'] != 0){
                                offlineData.raw[idx].productIndications[indIdx].productIndicationsKeyMessages.splice(indKeyMsgIdx,1);
                              }
                            }else{
                              if(item['productIndicationsKeyMessages.statecode'] == 0){
                                offlineData.raw[idx].productIndications[indIdx].productIndicationsKeyMessages.push({
                                  'indskr_keymessageid': item['productIndicationsKeyMessages.indskr_keymessageid'],
                                  'indskr_name': item['productIndicationsKeyMessages.indskr_name'],
                                })
                              }
                            }
                          }
                        }else if(item['productIndications.indskr_productindicationid']){
                          offlineData.raw[idx].productIndications.splice(indIdx,1);
                        }
                      }else{
                        if(item['productIndications.indskr_productindicationid'] && item['productIndications.statecode'] == 0){
                          let obj = {
                            'indskr_productindicationid': item['productIndications.indskr_productindicationid'],
                            'indskr_name': item['productIndications.indskr_name'],
                            'productIndicationsKeyMessages': [],
                          }
                          if(item['productIndicationsKeyMessages.indskr_keymessageid'] && item['productIndicationsKeyMessages.statecode'] == 0){
                            obj['productIndicationsKeyMessages'].push({
                                'indskr_keymessageid': item['productIndicationsKeyMessages.indskr_keymessageid'],
                                'indskr_name': item['productIndicationsKeyMessages.indskr_name'],
                            })
                          }
                          offlineData.raw[idx].productIndications.push(obj);
                        }
                      }
                    }else{
                      let obj = {
                        'productid': item['productid'],
                        'name': item['name'],
                        'productIndications': [],
                      }
                      if(item['productIndications.indskr_productindicationid'] && item['productIndications.statecode'] == 0){
                        obj.productIndications.push({
                          'indskr_productindicationid': item['productIndications.indskr_productindicationid'],
                          'indskr_name': item['productIndications.indskr_name'],
                          'productIndicationsKeyMessages': [],
                        });
                        if(item['productIndicationsKeyMessages.indskr_keymessageid'] && item['productIndicationsKeyMessages.statecode'] == 0){
                          obj.productIndications[0]['productIndicationsKeyMessages'].push({
                              'indskr_keymessageid': item['productIndicationsKeyMessages.indskr_keymessageid'],
                              'indskr_name': item['productIndicationsKeyMessages.indskr_name'],
                          })
                        }
                        offlineData.raw.push(obj);
                      }
                    }
                  }
                } catch (error) {
                  console.log(error);
                }
                // let idx = offlineData.raw.findIndex(a => a.productid == item.productid);
                // if (idx >= 0) {
                //   if(item.statecode == 0){
                //     offlineData.raw[idx] = item;
                //   }else{
                //     offlineData.raw.splice(idx,1);
                //   }
                // } else {
                //   if(item.statecode == 0){
                //     offlineData.raw.push(item);
                //   }
                // }
              });
              offlineData.lastUpdatedTime = lastUpdatedTime;
            } else {
              response = this.aggregateProductIndications(response);
              offlineData = {
                raw: response,
                lastUpdatedTime: lastUpdatedTime,
              }
            }
            syncState.lastUpdatedTime = lastUpdatedTime;
            await this.disk.updateSyncState(syncState);
            await this.deltaService.addEntitySyncInfo(bulkProductIndicaitonsSyncInfo);
            this.brandService.productIndications = offlineData.raw;
            await this.disk.updateOrInsert(DB_KEY_PREFIXES.PRODUCT_INDICATIONS, doc => {
              doc = offlineData;
              return doc;
            }).catch(error => console.error('Save product indications data in offline db error: ', error));
          }else{
            this.brandService.productIndications = offlineData ? offlineData.raw : [];
          }
        }else{
          this.brandService.productIndications = offlineData ? offlineData.raw : [];
        }
      }
    }
    aggregateProductIndications(data):Array<any>{
      let result = [];
      if(data && data.length){
        data.forEach(record=>{
          let idx = result.findIndex(a=> a['productid'] == record['productid']);
          if(idx >= 0 && record['productIndications.indskr_productindicationid']){
            if(record['productIndications.statecode'] == 0){
              let idx2 = result[idx]['productIndications'].findIndex(b=> b.indskr_productindicationid == record['productIndications.indskr_productindicationid']);
              if(idx2 >= 0 && record['productIndicationsKeyMessages.indskr_keymessageid']){
                if(record['productIndicationsKeyMessages.statecode'] == 0){
                  result[idx]['productIndications'][idx2]['productIndicationsKeyMessages'].push({
                    'indskr_keymessageid': record['productIndicationsKeyMessages.indskr_keymessageid'],
                    'indskr_name': record['productIndicationsKeyMessages.indskr_name'],
                  });
                }
              }else{
                let obj = {
                  'indskr_productindicationid': record['productIndications.indskr_productindicationid'],
                  'indskr_name': record['productIndications.indskr_name'],
                  'productIndicationsKeyMessages': [],
                }
                if(record['productIndicationsKeyMessages.indskr_keymessageid'] && record['productIndicationsKeyMessages.statecode'] == 0){
                  obj.productIndicationsKeyMessages.push({
                    'indskr_keymessageid': record['productIndicationsKeyMessages.indskr_keymessageid'],
                    'indskr_name': record['productIndicationsKeyMessages.indskr_name'],
                  })
                }
                result[idx]['productIndications'].push(obj);
              }
            }
          }else{
            let obj = {
              'productid': record['productid'],
              'name': record['name'],
              'productIndications': [],
            }
            if(record['productIndications.indskr_productindicationid'] && record['productIndications.statecode'] == 0){
              obj.productIndications.push({
                'indskr_productindicationid': record['productIndications.indskr_productindicationid'],
                'indskr_name': record['productIndications.indskr_name'],
                'productIndicationsKeyMessages': [],
              });
              if(record['productIndicationsKeyMessages.indskr_keymessageid'] && record['productIndicationsKeyMessages.statecode'] == 0){
                obj.productIndications[0]['productIndicationsKeyMessages'].push({
                    'indskr_keymessageid': record['productIndicationsKeyMessages.indskr_keymessageid'],
                    'indskr_name': record['productIndicationsKeyMessages.indskr_name'],
                })
              }
            }
            result.push(obj);
          }
        })
      }
      return result;
    }

  public async getKeyMessagesByPositionGroup(loadFromDBOnly = false) {
    if (!this.device.isOffline && this.authenticationService.user.indskr_mapkeymessagestopositiongroup) {
      let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.KEY_MESSAGES_BY_POSITION_GROUP);
      if(loadFromDBOnly){
        this.brandService.keyMessagesByPositionGroups = offlineData ? offlineData.raw : [];
      }else {
        if(_.isEmpty(this.authenticationService.user.positionGroups)) return;
        let fetchXML = fetchQueries.keyMessagesByPositionGroups;
        let positionGroupIds = this.authenticationService.user.positionGroups.map(o => {
          return o.positionGroupId
        });
        let positionGroupString = '';
        positionGroupIds.forEach(p => {
          positionGroupString += '<value>' + p + '</value>'
        })
        fetchXML = fetchXML.split('{PositionGroupIds}').join(positionGroupString);
        let response = await this.dynamics.executeFetchQuery('indskr_keymessages', fetchXML);
        if (response && Array.isArray(response)) {
          this.brandService.keyMessagesByPositionGroups = response;
          offlineData = {
            raw: response,
          }
        }
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.KEY_MESSAGES_BY_POSITION_GROUP, doc => {
          doc = offlineData;
          return doc;
        }).catch(error => console.error('Save Key Messages By Position Group data in offline db error: ', error));
      }
    }
  }

  public async syncSupportingMaterials(fullSync?: boolean, loadFromDBOnly = false){
    if (!this.device.isOffline && (this.authenticationService.hasFeatureAction(FeatureActionsMap.MEETING_SUPPORTING_MATERIALS))) {
      let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.SUPPORTING_MATERIALS);
      let lastUpdatedTime;
      if (offlineData && offlineData.raw && !fullSync) {
        if (offlineData.lastUpdatedTime) {
          lastUpdatedTime = offlineData.lastUpdatedTime;
        }
      }
      if (!loadFromDBOnly) {
        let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_SUPPORTING_MATERIALS);
        const bulkSupportingMaterialsSyncInfo: EntitySyncInfo = {
          entityName: EntityNames.supporting_materials,
          totalFailed: 0,
          totalSynced: 0,
          errors: [],
          syncStatus: true
        };
        const now = new Date().getTime();
        let fetchXML = fetchQueries.supportingMaterialsByBU;
        
        if (lastUpdatedTime && !fullSync) {
          let hourDifference = differenceInHours(now, new Date(lastUpdatedTime));
          hourDifference += 1
          fetchXML = fetchXML.replace('{hourDifference}', `${hourDifference}`);
        } else {
          fetchXML = fetchXML.replace('<filter type="and"><condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" /></filter>', '');
        }

        let response = await this.dynamics.executeFetchQuery('indskr_supportingmaterialses', fetchXML);

        lastUpdatedTime = new Date().getTime();
        if (response && Array.isArray(response) && response.length != 0 && response[0]) {
          bulkSupportingMaterialsSyncInfo.totalSynced += response.length;            
          if (offlineData && offlineData.raw && !fullSync) {
            response.forEach(item => {
              try {
                if (item['id']) {
                  let idx = offlineData.raw.findIndex(a => a.id == item.id);
                  if (idx >= 0) {
                    if(item['statecode']  == 0){
                      offlineData.raw[idx] = item;
                    }else {
                      offlineData.raw.splice(idx,1);
                    }
                  } else {
                    if(item['statecode']  == 0){
                      offlineData.raw.push(item);
                    }
                  }
                }
              } catch (error) {
                console.log(error);
              }
            });
            offlineData.lastUpdatedTime = lastUpdatedTime;
          }else{
            offlineData = {
              raw: response,
              lastUpdatedTime: lastUpdatedTime,
            }
          }
          syncState.lastUpdatedTime = lastUpdatedTime;
          await this.disk.updateSyncState(syncState);
          await this.deltaService.addEntitySyncInfo(bulkSupportingMaterialsSyncInfo);
          this.brandService.supportingMaterials = offlineData ? offlineData.raw : [];
          await this.disk.updateOrInsert(DB_KEY_PREFIXES.SUPPORTING_MATERIALS, doc => {
            doc = offlineData;
            return doc;
          }).catch(error => console.error('Save supporting materials data in offline db error: ', error));
        }else{
          this.brandService.supportingMaterials = offlineData ? offlineData.raw : [];
        }
      }else{
        this.brandService.supportingMaterials = offlineData ? offlineData.raw : [];
      }
    }
  }
}