<ion-list lines="none" class="list-preview-list">
  <ion-item *ngIf="showOfflineMessage && offlineMessage" [ngClass]="{'item-border' : showOfflineMessage && offlineMessage}">
    <ion-label class="list-preview-offline-message item-border"> {{offlineMessage}}</ion-label>
  </ion-item>
  <ion-item *ngFor="let popoverListItem of popupListData;let i=index"
    [ngClass]="{'item-border' : popupListData.length > 1 ? i < (popupListData.length-1) : false}">
    <ion-thumbnail slot="start" class="thumbnail" *ngIf="popoverListItem.thumbnailURL">
      <img class="thumbnaim-img" io-thumbnail [src]="popoverListItem.thumbnailURL" />
    </ion-thumbnail>
    <ion-list>
      <ion-label class="list-preview-title"> {{ popoverListItem.title }}</ion-label>
      <ion-label class="list-preview-label" *ngIf="popoverListItem.secondLineText"> {{ popoverListItem.secondLineText }}</ion-label>
      <ion-label class="list-preview-label" *ngIf="popoverListItem.thirdLineText"> {{ popoverListItem.thirdLineText }}</ion-label>
    </ion-list>
    <ion-icon *ngIf="popoverListItem.detailObjectId" class="end-icon" slot="end" color="primary" name="chevron-forward-outline" (click)="openContent(popoverListItem)"></ion-icon>
  </ion-item>
  <div *ngIf="popupListData && popupListData.length == 1 && popupListData[0].previewHtml" class="preview-html-popup">
    <iframe class='preview-html-iframe' [srcdoc]="popupListData[0].previewHtml | safe:'html'"></iframe>
  </div>
</ion-list>