
import { HttpHeaders } from '@angular/common/http';

export const BUILD_ENV: string = '<NAMESPACE>';
export const HOST: string = '<ENTRY>';
export const GLOBAL: string = '<GLOBAL>';

export const SESSIONSTACK_TOKEN: string = '<SESSIONSTACK>';

export const API_URL: string = `https://${HOST}/api/`;
export const GLOBAL_URL: string = `https://${GLOBAL}/api/`;

export const GLOBALTNT: string = `${GLOBAL_URL}`;
export const GLOBAL_AUTH: string = `${GLOBAL_URL}`;

export const LAUNCH_DARKLY_CLIENT_ID: string = '<LDKEY>';

export const APP_CENTER_TOKEN_IOS: string = "<APP_CENTER_TOKEN_IOS>";
export const APP_CENTER_TOKEN_ANDROID: string = "<APP_CENTER_TOKEN_ANDROID>";

export const APP_VERSION: string = '<APP_VERSION>';
export const TENCENT_SDK_APP_ID: any = '<TENCENT_SDK_APP_ID>';
export const TENCENT_SECRET_KEY: string = '<TENCENT_SECRET_KEY>';

export const DirectMessagingConfig = {
    TWILIO: {
        accountSid: 'AC86e17f1b3a46c9ba76769c612de64ed1',
        authToken: 'ff0b99ff0aa9fe0ce725fff81c4d6786',
    },
    Facebook: {
        access_token: 'EAAI5gFhdhDIBAA86O3esMXnh1ZBC42qlZAMouRSM3UWWDW3gaaiqz8AwZB8uhZBWcoKYW16w3kEaZCuy1iqucZB7ecXb4RsKBaQmOvieeS4n2DUZAiJL0erCfy1b8nFoRqVvFFPZBqlJLr5D0FgHF9jrAqXZALmh5pgzHVRhPITJdZAAZDZD'
    }
};

export const BugseeConfig = {
     iOS: {
        DEV: 'f3ae6639-1ca8-43f1-a5b8-54d37bb57a6d',
        QA: 'de5de783-5610-42ee-9d02-0bc1c19f1972',
        DEMO: '64f76f39-64db-4f3a-9cdd-33b7035c2a0d'
     },
     ANDROID: {
        DEV: '53e03dc8-8c13-4651-96f6-3c318b83251c',
        QA: '704848b0-aa60-4522-89ad-7e6e8f62df41',
        DEMO: 'a4bf8cb2-32ec-43c1-b57c-d69e66dfbb68'
     }
};

export const Endpoints = {
    //link to omnipresence DNA to be placed here
    DNA:{
        LinkToOmniPresenceDNA: '?email={{userEnamilAddress}}'
    },
    GLOBAL_SYNC_HEADER: {
        headers: new HttpHeaders({ 'Sync-Service': 'true' })
    },
    NOTE_DOCUMENT_BODY: '/api/data/v9.1/annotations({annotationid})?$select=documentbody',
     accounts: {
        GET_ACCOUNTS: '/api/cag/accounts',
        GET_ACCOUNT_ID: '/api/cag/accounts/{{AccountId}}',
        GET_ACCOUNT_TIMELINEINFO: '/api/cag/v122/accounts/{{AccountId}}/activities?businessUnitId={{businessUnitId}}&timelineActivities={{timelineActivities}}',
        GET_BULK_ACCOUNTS: '/api/bms/accounts?positionIds={{positionIDs}}',
        GET_USERS_POSITIONS: '/api/cag/positions/users',
        GET_BULK_DELTA: '/api/bms/accounts?lastUpdatedTime={lastUpdatedTime}&positionIds={{positionIDs}}',
        GET_ACCOUNT_DELTA: '/api/bms/deltachanges/accounts/{accountId}?lastUpdatedTime={lastUpdatedTime}&positionIds={{positionIDs}}',
        CREATE_ACCOUNT: '/api/cag/dynamicforms/accounts',
        CREATE_VEEVA_ACCOUNT: '/api/cag/veeva/accounts',
        GET_ACCOUNT_INTERACTION: '/api/bms/accounts/interaction?positionIds={{positionIDs}}&startDate={startDate}',
        MAP_ACCOUNT_TO_USER_POSITION: '/api/cag/v170/accounts/{{accountId}}/positions/{{positionId}}',
        UPLOAD_ACCOUNT_NOTES: '/api/cag/accounts/notes',
        ACCOUNT_ASSESSMENT: '/api/cag/v131/accounts/{accountId}/assessments'
    },
    dynamic_forms: {
        CREATE_LINKED_ENTITY: '/api/cag/dynamicforms/linkedentities?entityName={entityName}',
        OFFLINE_CREATE_LINKED_ENTITY: '/api/cag/offline/linkedentities',
        CREATE_MULTIPLE_LINKED_ENTITIES: '/api/cag/dynamicforms/multiplelinkedentities',
    },
    domain: {
        DYNAMICS_DEV: 'dynamics.dev.indegene.com',
        GET_DOMAIN_DETAILS: GLOBALTNT + 'tnt/azuretenantdetails/domain?domain=',
        GET_TEAMS_CONFIG: GLOBALTNT + 'tnt/teams/configuration?clientid={clientid}&tenantid={tenantid}'
    },
    authentication: {
        /**
         *  {tentantID} : tentant id
         */
        MICROSOFT_BASE_URL_GLOBAL: 'https://login.microsoftonline.com',
        MICROSOFT_BASE_URL_CHINA: 'https://login.partner.microsoftonline.cn',
        MICROSOFT_OAUTH: 'https://login.microsoftonline.com/{tenantID}/oauth2/token',
        GET_INSTANCES: GLOBAL_AUTH + 'sso/instances',
        MICROSOFT_OAUTH_CHINA: 'https://login.partner.microsoftonline.cn/{tenantID}/oauth2/token',
        GET_CHINA_INSTANCES: GLOBAL_AUTH + 'sso/v92/instances',

        /**
         * {realm_id} : Provided from domain endpoint
         */
        AUTHENTICATE_USER: GLOBAL_AUTH + 'sso/auth/realms/{realm_id}/token',
        AUTHENTICATE_USER_HEADERS: {
            headers: new HttpHeaders(
                { 'Content-Type': 'application/x-www-form-urlencoded'}
            )
        },
        AUTHENTICATE_USER_STATUS: {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                    'X-SystemUserId': '{system_id}'
                },
            )
        },
        GET_USER_HEADERS: {
            headers: new HttpHeaders(
                {
                    'Content-Type': 'application/json'
                }
            )
        },
        LOGIN: GLOBAL_AUTH + 'sso/login',
        REFRESH_RESOURCE: GLOBAL_AUTH + 'sso/auth/realms/{realm_id}/token',
        GET_USER: GLOBAL_AUTH + 'sso/v152/currentuser',
        REFRESH: GLOBAL_AUTH + 'sso/auth/realms/{realm_id}/token',
        REP_STATUS: '/api/rou/rep/state/current',
        UPDATE_STATUS: '/api/rou/rep/state',
        ENCRYPT_CLIENT_SECRET: GLOBALTNT + 'tnt/encryptsecret',
        GET_CHILD_USERS : GLOBAL_AUTH + 'cag/v51/positions/users',
        GET_OK_API_SETTINGS: '/api/sso/okapisettings'
    },
    activites: {
        EMAIL_ACTIVITY: '/api/rhs/crm/activity/{activity_id}/activityType/email',
        PHONE_ACTIVITY: '/api/rhs/crm/activity/{activity_id}/activityType/phonecall',
        GET_ACTIVITY_DETAILS: '/api/rhs/v172/activities/{activity_id}?type={activity_type}&configFields={{configFields}}',
        GET_DELTA_ACTIVITY_DETAILS: '/api/bms/v171/deltachanges/activities/{activity_id}?lastUpdatedTime={last_updated_time}&positionIds={{positionIDs}}&appointmentConfigFields={{appointmentConfigFields}}',
        GET_ACCOMPANIED_USER: '/api/bms/v112/accompaniedusers?positionIds={{positionIds}}',
        POST_ACCOMPANIED_USER: '/api/rhs/meetings/{activity_id}/accompaniedusers',
        SCREEN_SHARING_TIME: '/api/rhs/meetings/{activityId}/contentshares',
        GET_ACTIVITY_TYPE: '/api/rhs/v172/activitytypes',
        GET_SUBACTIVITY_TYPE: '/api/rhs/activitysubtypes',
        UPDATE_ACTIVITY_TYPE_SUB_TYPE: '/api/rhs/activitytype/{entityName}/{activityId}',
        GET_CONFIG_TYPE_OPTIONSET_VALUES: '/api/data/v9.0/EntityDefinitions(LogicalName={{logicalEntityName}})/Attributes(LogicalName={{attributeLogicalName}})/Microsoft.Dynamics.CRM.PicklistAttributeMetadata?$select=LogicalName&$expand=OptionSet',
        GET_CONFIG_TYPE_MULTISELECT_OPTIONSET_VALUES: '/api/data/v9.0/EntityDefinitions(LogicalName={{logicalEntityName}})/Attributes(LogicalName={{attributeLogicalName}})/Microsoft.Dynamics.CRM.MultiSelectPicklistAttributeMetadata?$select=LogicalName&$expand=OptionSet',
        GET_SELF_ACTIVITIES_BY_ACTIVITY_TYPES: '/api/bms/v172/self/activities?startDate={startDate}&endDate={endDate}&positionIds={positionIDs}&activityTypes={activityTypes}',
        GET_TEAM_USER_ACTIVITIES_BY_ACTIVITY_TYPES: '/api/bms/v170/team/activities?startDate={startDate}&endDate={endDate}&positionIds={positionIDs}&activityTypes={activityTypes}&childUserIds={childUserIds}',
        GET_TEAM_ACTIVITIES_FOR_TOOLS_BY_ACTIVITY_TYPES: '/api/bms/v121/team/activities?startDate={startDate}&endDate={endDate}&positionIds={positionIDs}&teamActivity={teamActivity}',
        TEAM_MEETING_ACTIVITY: '/api/rhs/teamsmeeting',
        UPDATE_TEAMS_MEETING: `https://graph.microsoft.com/v1.0/me/onlineMeetings/{teamsMeetingId}`,
        UPDATE_USER_TIME_RECOMMENDATION: '/api/sso/user/{user}',
        MAP_CUSTOMER_ASSETS_TO_MEETING: '/api/rhs/meetings/{activityId}/assets',
        UPDATE_CUSTOMER_JOIN_DETAILS_LEFT_DATE: '/api/rhs/meetings/{activityId}/practitioners'

    },
    brands: {
        GET_BRANDS: '/api/cag/v150/products?positionIds={{positionIDs}}',
        /**
         * {brand_ids} the ids of the brands you want returned, comma seperated or so i assume
         */
        GET_BRANDS_BY_ID: '/api/con/products?ids={brand_ids}'
    },
    callplans: {
        BULK_CALLPLANS: '/api/bms/callPlans?isRepCallPlanGrouped=true',
        BULK_TEAM_CALLPLANS: '/api/bms/teamCallPlans?isRepCallPlanGrouped=true',
        GET_CALL_PLANS: '/api/cag/crm/callplans',
        GET_TEAM_CALL_PLANS: '/api/cag/myTeamCallPlans',
        GET_TEAM_CALL_PLANS_DETAILS: '/api/cag/callplans/myteam/repcallplans?callPlanId={callPlanID}&isRepCallPlanGrouped=true',
        GET_CUSTOMERS_FOR_CALLPLAN: '/api/cag/callplans/repcallplans?callPlanId={callPlanID}&isRepCallPlanGrouped=true',
        UPDATE_CALL_PLAN: '/api/cag/v161/repCallPlans',
        UPDATE_CUSTOMER_CALL_PLAN:'/api/cag/v161/callplans/{callPlanId}/status',
        GET_CALL_PLANS_FOR_CUSTOMER: '/api/cag/repcallplans?contactId={contactID}',
        GET_BULK_DELTA_CALLPLANS: '/api/bms/callPlans?lastUpdatedTime={lastUpdatedTime}&isRepCallPlanGrouped=true',
        GET_CALL_PLAN_BY_ID: '/api/bms/deltachanges/callplans/{callPlanID}?lastUpdatedTime={last_updated_time}&isRepCallPlanGrouped=true',
        GET_TEAM_CALL_PLAN_BY_ID: '/api/bms/deltachanges/teamcallplans/{callPlanID}?lastUpdatedTime={last_updated_time}&isRepCallPlanGrouped=true',
        GET_CALLPLAN_ACTIVITIES: '/api/cag/callplans/{contactid}/activities?startDate={startDate}&endDate={endDate}'
    },

    coachingReports: {
        GET_ASSESSMENT_MATRIX: '/api/rhs/assessmentmatrix?buId={buId}',
        GET_MY_COACHING_REPORTS: '/api/bms/v121/mycoachingreports',
        GET_TEAM_COACHING_REPORTS: '/api/bms/v121/teamcoachingreports',
        GET_COACHING_TEMPLATES: '/api/bms/v152/coachingtemplates?positionIds={PositionIds}',
        GET_COACHING_TEMPLATES_USERS: '/api/rhs/v121/coachingtemplatesfor?positionIds={PositionIds}' ,
        GET_COACHING_FOR_USERS: '/api/bms/childusers',
        CREATE_COACHING_REPORT: '/api/rhs/v121/coachingreport',
        UPDATE_COACHING_REPORT: '/api/rhs/coachingreport/{reportID}',
        GET_COACHING_REPORT: '/api/rhs/v80/coachingreport/{reportID}',
        GET_MY_COACHING_REPORT: '/api/bms/v121/coachingreports/{reportID}',
        GET_TEAM_COACHING_REPORT: '/api/bms/v121/teamcoachingreports/{reportID}',
        ACKNOWLEDGE_MY_COACHING_REPORT: '/api/rhs/coachingreport/{reportID}/acknowledge',
        RECALL_TEAM_COACHING_REPORT: '/api/rhs/coachingreport/{reportID}/recall',
        SCRAP_COACHING_REPORT: '/api/rhs/coachingreport/{reportID}',
        GET_APPOINTMENTS: '/api/rhs/v132/appointments?userId={userId}&periodStartDate={periodStartDate}&periodEndDate={periodEndDate}',
        GET_PHONECALLS: '/api/rhs/phonecalls?userId={userId}&periodStartDate={periodStartDate}&periodEndDate={periodEndDate}',
        GET_SURGERYORDERS: '/api/rhs/v140/surgeryorders?userId={userId}&periodStartDate={periodStartDate}&periodEndDate={periodEndDate}',
        SAVE_OR_UPDATE_RATING: '/api/rhs/coachingreports/{reportID}/category/{categoryID}',
        CLEAR_RATINGS: '/api/rhs/coachingreports/{reportID}/assessmentmetrics',
        GET_DELTA_MY_COACHING: '/api/bms/v121/mycoachingreports?lastUpdatedTime={lastUpdatedTime}',
        GET_DELTA_TEAM_COACHING: '/api/bms/v121/teamcoachingreports?lastUpdatedTime={lastUpdatedTime}',
        GET_COACHING_SCALES: '/api/bms/coachingscales',
        UPDATE_COAHING_FOR: '/api/rhs/v152/coachingreport/{reportID}/coachingfor?positionIds={positionIds}',
        UPLOAD_OFFLINE_COACHINGS: '/api/rhs/v121/offline/coachingreports',
        UPDATE_COACHING_SCORE: '/api/rhs/coachingreports/{reportID}/score',
        SAVE_COACHING_NOTES: '/api/rhs/coaching/notes'
    },

    consent: {
        GET_ACTIVE_CONSENTS: '/api/bms/v163/consents?positionIds={{positionIDs}}',
        GET_DELTA_ACTIVE_CONSENTS: '/api/bms/v163/consents?positionIds={{positionIDs}}&lastUpdatedTime={lastUpdatedTime}',
        GET_ACTIVE_CONSENTS_BY_CONTACTID: '/api/bms/consentsByContactId?contactId={{contactId}}',
        GET_CONSENT_CHANNELS: '/api/bms/v44/channels',
        GET_DELTA_CONSENT_CHANNELS: '/api/bms/v44/channels?lastUpdatedTime={lastUpdatedTime}',
        GET_CONSENT_TERMS: '/api/bms/v123/consentterms',
        GET_DELTA_CONSENT_TERMS: '/api/bms/v123/consentterms?lastUpdatedTime={lastUpdatedTime}',
        SAVE_CONSENT: '/api/rhs/113/consents',
        SAVE_CONSENT_ACTIVITIES: '/api/rhs/113/consentActivities',
        GET_ACTIVE_GENERAL_CONSENT: '/api/rhs/activeGeneralConsent?contactId={contactId}',
        GET_ACTIVE_CONSENT: '/api/rhs/activeconsents?contactId={contactId}&consentType={consentType}',
        SAVE_PAPER_CONSENT: '/api/rhs/consents/notes'
    },

    emailTemplates:{
        GET_ALL_EMAIL_TEMPLATES: '/api/cms/templates', //'https://entrypoint-devps-na.io.indegene.com/api/cms/templates', //
        EMAIL_ATTACHMENT: '/api/rhs/email/meeting/attachment'
    },
    contacts: {

        GET_CONTACTS: '/api/cag/contacts?page={page}&size={size}',
        GET_BULKCONTACTS: '/api/bms/bulkcontacts?positionIds={{positionIDs}}',
        GET_BULKCONTACTS_APPCONFIGFIELD_HEADERS:{
            headers : new HttpHeaders({
                'appConfigFields': '{configField}'
            })
        },
        GET_CONTACTS_AGGREGATION_HEADERS: {
            headers: new HttpHeaders({
                'X-Prefer-Aggregation': 'true',
                'X-SystemUserId': '{system_id}'
            })
        },
        GET_CONTACT_DETAILS : '/api/cag/contacts/{contactid}',
        GET_FULL_CONTACT_DETAILS: '/api/bms/deltachanges/contacts/{contact_id}',
        GET_DELTA_CONTACT_DETAILS: '/api/bms/deltachanges/contacts/{contact_id}?positionIds={{positionIDs}}&lastUpdatedTime={last_updated_time}',
        GET_CONTACT_PROFILE : '/api/cag/v140/contacts/{contactid}/profile?positionIds={{positionIDs}}',
        GET_CONTACT_ACTIVITIES: '/api/cag/v160/contacts/{contactid}/activities?businessUnitId={{businessUnitId}}&timelineActivities={{timelineActivities}}',
        GET_CUSTOMER_JOURNEY: '/api/cag/v122/contacts/{contactid}/customerjourney',
        GET_CUSTOMER_JOURNEY_REALTIME_EMAILS: '/api/cag/contacts/{contactid}/journeyRealtimeEmails',
        GET_CONTACTS_FROM_ACCESS_TOKEN_RAW: "rhs/accesstoken/{access_token}/meetings",
        GET_CONTACTS_FROM_ACCESS_TOKEN_HEADERS: {
            headers: new HttpHeaders({
                "Content-Type": "application/json"
            })
        },
        GET_BULK_CONTACTS_DELTA: '/api/bms/bulkcontacts?lastUpdatedTime={lastUpdatedTime}',
        ASSOCIATE_CONTACT_TO_POSITION : '/api/cag/v170/contacts/{contactid}/positions/{positionid}',
        GET_PREVIEW_PUBLICATION: '/api/cag/publications/{publicationId}/attachments/{attachmentId}/preview',
        GET_SPECIALTIES: '/api/bms/v11/specialties',
        GET_LANGUAGES: '/api/bms/languages',
        CREATE_CONTACT_META: '/api/cag/v172/contacts',
        CREATE_DYNAMIC_CONTACT: '/api/cag/dynamicforms/contacts',
        CREATE_VEEVA_CONTACT: '/api/cag/veeva/contacts',
        GET_CONTACT_INTERACTION: '/api/bms/contacts/interaction?positionIds={{positionIDs}}&startDate={startDate}',
        UPLOAD_CONTACT_NOTES: '/api/cag/contacts/notes',
        GET_BULK_PROFILE_DATA: '/api/bms/v511/contactprofiles?positionIds={{positionIDs}}&lastUpdatedTime={{lastupdatedTime}}',
        CONTACT_TAGGING : '/api/cag/usertags',
        CONTACT_ASSESSMENT: '/api/cag/v131/contacts/{contactId}/assessments',
        UPLOAD_PREFERRED_ADDRESS: '/api/cag/userpositions/preferredaddress',
        UPLOAD_OFFLINE_CONTACTS: '/api/cag/offline/contacts',
        SAVE_KOL_STATUS: '/api/cag/contacts/kolstatus',
        UPDATE_KOL_STATUS: '/api/cag/kolstatus/{approvalActivityId}/status',
        SAVE_CONTACT_MEDICAL_INSIGHT: '/api/cag/customerinteractioninsights',
        "INTERNAL_SURVEY":'api/cag/internalsurvey',
        GET_VOICE_CALL_AGENT_TOKEN: '/api/cag/voicecalltoken'
    },
    crm: {
        GET_DETAILS: '/api/rhs/crm/meetings'
    },
    contentmatching:{
        GET_CONTENT_MATCHING: '/api/bms/contentmatching?positionIds={positionid}',
        GET_CONTENT_MATCHING_DELTA: '/api/bms/contentmatching?positionIds={positionid}&lastUpdatedTime={lastupdatedTime}',
        GET_MESSAGE_CONTENT_MATCHING: '/api/bms/messagescontentmatching?positionIds={positionid}',
        GET_MESSAGE_CONTENT_MATCHING_DELTA: '/api/bms/messagescontentmatching?positionIds={positionid}&lastUpdatedTime={lastupdatedTime}'
    },
    delta: {
        GET_DELTA_SYNC_NOTIFICATIONS: '/api/bms/deltachanges?lastUpdatedTime={lastUpdatedTime}',
        GET_CALL_PLANS: '/api/bms/callPlans?lastUpdatedTime={lastUpdatedTime}&isRepCallPlanGrouped=true',
        GET_TEAM_CALL_PLANS: '/api/bms/teamCallPlans?lastUpdatedTime={lastUpdatedTime}&isRepCallPlanGrouped=true',
        GET_DELTA_SYNC_COACHINGS: '/api/bms/deltachanges?lastUpdatedTime={lastUpdatedTime}&entity=indskr_coachingreport',
        UPLOAD_SYNC_INFO: '/api/bms/syncinfo',
    },
   email: {
        SEND_EMAIL: '/api/rhs/email',
        FETCH_PRODUCT_SKU: '/api/cag/brandskuproducts?positionIds={{positionIDs}}',
        CREATE_EMAIL_ACTIVITY: '/api/rhs/emails',
        GET_ALL_EMAIL_ACTIVITIES: '/api/bms/v44/emails?startDate={startDate}&endDate={endDate}&positionIds={positionid}',
        GET_EMAIL_TEMPLATES: '/api/bms/emailtemplates?positionIds={positionid}',
        GET_DELTA_SYNC_EMAIL_TEMPLATES: '/api/bms/emailtemplates?lastUpdatedTime={lastUpdatedTime}&positionIds={positionid}',
        GET_CONTENT_TOKENS: '/api/bms/v120/contenttokens',
        UPDATE_EMAILADDRESS: '/api/rhs/emails/{EmailActivityId}/emailaddresses',
        SCRAP_EMAIL_ACTIVITY: '/api/rhs/emails/{EmailActivityId}',
        UPDATE_EMAIL_ACTIVITY: '/api/rhs/emails/{EmailActivityId}',
        UPDATE_EMAIL_TEMPLATE: '/api/rhs/emails/{EmailActivityId}/template',
        SEND_EMAIL_ACTIVITY: '/api/rhs/v43/emails/{EmailActivityId}/send',
        ADD_ATTACHMENTS: '/api/rhs/emails/{EmailActivityId}/attachments',
        REMOVE_ATTACHMENTS: '/api/rhs/emails/attachments',
        UPDATE_SMS_PARTIES: '/api/rhs/sms/{EmailActivityId}/phonenumbers',
        SEND_EMAIL_HEADERS: {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'X-Correlation-Id': '{correlation_id}',
                    'X-Feature-Id': 'Send_Email',

                })
        },
        ADD_ACCOMPANIED_USER: '/api/rhs/emails/{EmailActivityId}/appointments/{appointmentId}/covisitors',
        GET_UPDATED_TEMPLATES: '/api/rhs/emailtemplates?groupIds={groupIds}&positionIds={positionIds}'
    },
    appsetting: {
        GET_SAS_TOKEN: '/api/tnt/azurestorage',
        GET_ABOUT: '/api/tnt/azure/documents',
    },
    ihtml: {
        UPLOAD_TRACKING: '/api/rhs/meetings/{activityId}/presentations/{presentationId}/slides/{slideId}/ihtmlevents'
    },
    ogenie: {
        BOTFRAMEWORK_URL: 'https://directline.botframework.com/v3/directline/conversations',
        BOTFRAMEWORK_URL_ACTIVITY: 'https://directline.botframework.com/v3/directline/conversations/{conversationID}/activities',
        BEARER_TOKEN: 'Bearer iVImPpLT9yI.cwA.l8Y.yAb9b1PKZhlGxIKu5WHwiFMOjB2rj7VqWumdUNXrSvI'
    },
    offline: {
        UPLOAD_MEETING: '/api/cmn/v161/upload',
        UPLOAD_BULK_MEETING: '/api/rhs/v102/meeting/offline/summaries',
        UPLOAD_OFFLINE_DATA: '/api/cmn/offlinesave',
        UPLOAD_OFFLINE_PHONECALL: '/api/rhs/offline/phonecalls',
        UPLOAD_HEADERS: {
            headers: new HttpHeaders({
                'X-SystemUserId': '{system_id}'
            })
        },
    },
    network: {
        cache: {cache: true},
        debug: true
    },
    locations: {
        GET_LOCATIONS: '/api/cag/contacts/location'
    },
    presentations: {
        GET_PRESENTATIONS: "/api/ccc/v141/presentations",
        GET_DELTA_PRESENTATIONS: "/api/bms/presentations",
        GET_PRESENTATIONS_AGGREGATION_HEADERS: {
            headers: new HttpHeaders({
                'X-Prefer-Aggregation': 'true',
                'X-SystemUserId': '{system_id}'
            })
        },
        FAVOURITE_PRESENTATION : "/api/ccc/presentations/favorites",
        FAVOURITE_PRESENTATION_HEADER: {
            headers: new HttpHeaders({
                'X-SystemUserId': '{system_id}',
            })
        },

        download: '/api/ccc/presentations/1089'
    },
    resources: {
      GET_RESOURCES: '/api/bms/resources',
    },
    scientificActivities: {
        GET_THERAPEUTIC_AREA : '/api/cag/therapeuticareas?positionIds={{positionIDs}}',
        UPDATE_MEETING_THERAPEUTICAREA : '/api/rhs/meetings/{{ActivityId}}/therapeuticareas',
        GET_SP_TIMELINE: '/api/cag/scientificplans/{{scientificplanID}}/activities{{prodIDs}}&startDate={{planStart}}&endDate={{planEnd}}&positionIds={{PositionId}}{{therapeuticIds}}{{contactId}}'
    },
    meeting: {
        /**
         * Get all meetings that start/created between two unix timestamps
         * {startDate}
         * {endDate}
         */
        GET_MEETINGS_WITH_DATE_FILTER: '/api/rhs/meetings?startDate={startDate}&endDate={endDate}',
        INITIATE_MEETING: '/api/rhs/v142/meetings',
        UPDATE_MEETING: '/api/rhs/meetings/{activity_id}',
        UPDATE_MEETING_STATUS: '/api/rhs/v140/meetings/{activity_id}/status',
        VALIDATE_RETROACTIVE_COMPLETION_LIMIT: 'api/rhs/meetings/validateMeetingCompletionDatetime?startTimestamp={startTimestamp}&endTimestamp={endTimestamp}',
        PUT_MEETING: '/api/rhs/meetings/{activity_id}',
        PATCH_MEETING: '/api/rhs/v142/meetings/{activity_id}',
        ADD_ACCOUNTS_TO_MEETING: '/api/rhs/meetings/{activity_id}/accounts',
        ADD_CONTACTS_TO_MEETING: '/api/rhs/meetings/{activity_id}/contacts',
        COPY_SENTIMENT_TO_ATTENDEESLIDE:'/api/rhs/meetings/{activity_id}/activityattendeeslides',
        ADD_PRESENTATION_TO_MEETING: '/api/rhs/meetings/{activity_id}/presentations',
        UPDATE_MEETING_PRODUCTS: "/api/rhs/meetings/{activity_id}/products",
        UPDATE_MEETING_SUPPORTING_MATERIALS: "/api/rhs/meeting/{activity_id}/supportingmaterials",
        VIDEO_TOKEN: "/api/rhs/rep/meeting/{activity_id}/videotoken",
        VIDEO_SHARE: "/api/rhs/meetings/{activity_id}/videoshare",
        MEETING_TYPE: "/api/rhs/meetingtypes",
        UPDATE_APP_CONFIGFIELDS_TO_MEETING:'/api/rhs/meetings/{activity_id}/configfields',
        REP_VIDEO_DURATION: API_URL + "rhs/meetings/{activity_id}/hcpvideoduration",
        SEND_MEETING_INVITE: "/api/rhs/meetings/{activity_id}/sendInvite",
        UPDATE_MEETING_DESCRIPTION: "/api/rhs/meetings/{activity_id}/description",
        ADD_OPPORTUNITIES_TO_MEETING:"/api/rhs/meeting/{activity_id}/opportunities",
        ADD_ACCOUNTPLANS_TO_MEETING:"/api/rhs/meeting/{activity_id}/accountplans",
        ADD_EVENTS_TO_MEETING:"/api/rhs/meeting/{activity_id}/events",
        UPLOAD_MEETING_NOTES: '/api/rhs/meetings/notes',
        GPS_CHECK_IN:"/api/rhs/meetings/{activity_id}/gpscheckindetails",
        GPS_ACTIVITY_PHOTOS:"/api/rhs/meetings/{activity_id}/gpsactivityphotos",
        SAVE_MEETING_OBJECTIVES: "/api/rhs/meeting/{activity_id}/objectives",
        UPDATE_MEETING_DISEASEAREA : '/api/rhs/meetings/{{ActivityId}}/diseaseareas',
        UPDATE_MEETING_PRODUCT_INDICATIONS : '/api/rhs/meetings/{{ActivityId}}/productindications',
        GENERATE_MEETING_URL : '/api/rhs/meetings/{meetingId}/link',
        ASSIGN_MEETING_OWNER:'/api/rhs/meetings/{activityId}/ownership',
        UPDATE_MEETING_MARKETING_BUSINESS_PLAN : '/api/rhs/meetings/{activityId}/marketingbusinessplans',
        UPDATE_MEETING_COACHING_PLAN : '/api/rhs/meetings/{activityId}/coachingplan',
        UPDATE_INSTORE_PHOTOS: '/api/rhs/meetings/{activityId}/photoattachments',
        UPDATE_MEETING_OUTCOME: '/api/rhs/meetings/{activity_id}/outcome',
        UPDATE_MEETING_SETBOOKING: '/api/rhs/meetings/{activity_id}/kitbookings',
        DELETE_MEETING:'/api/rhs/meetings/{activityId}',
        CONTENT_SHARE: '/api/rhs/meetings/{activity_id}/contentshare',
        /**
         * {rep_x_position}
         *
         */
        INITIATE_MEETING_HEADERS: {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-PositionId': '{rep_x_position}',
                'X-Feature-Id': 'Initiate_Meeting',
                'X-Correlation-Id': 'test',

            })
        },

        /**
         * Inbound meeting
         */
        INBOUND_MEETING_REQUEST: '/api/rhs/inboundrequests/{inbound_request_id}/status',
        INBOUND_MEETING_REQUEST_HEADERS: {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-SystemUserId': '{system_id}',
                'No-Retry': 'true',
            })
        },
    },
    headers: {
        content_type: {
            json: {
                headers: new HttpHeaders(
                    { 'Content-Type': 'application/json'}
                )
            },
            zip: {
                headers: new HttpHeaders(
                    {
                        'Content-Type': 'application/zip',
                        'Accept': 'application/zip',
                    }
                )
            }
        }
    },
    /**
         * Time Offs
    */
    tot: {
      INITIATE_TOT: '/api/cag/crm/tot',
      TOT_REASON: '/api/cag/crm/tot/reasons',
      DELETE_TOT: '/api/cag/crm/tot/{timeOffRequestID}',
    },
    scheduler: {
        ALL_SCHEDULES: "/api/rhs/schedulingpatterns",
        FILTER_SCHEDULES: "/api/rhs/meetings/filters/{entityName}",
        SCHEDULE_BATCH: "/api/rhs/meetings/batch",
        UPDATE_SCHEDULE: "/api/rhs/schedulingpatterns/{schedulerID}",
        CREATE_SCHEDULE: "/api/rhs/schedulingpatterns",
        CUSTOMER_SEGMENT: "/api/bms/customersegmentations",
        DELETE_SCHEDULE: "/api/rhs/schedulingpatterns/{schedulerID}",
        PIORITIES:"/api/rhs/schedulingpriorities",
        CITY:"/api/rhs/cities",
        ZIP:"/api/rhs/postalcodes",
        FETCH_CONTACT_SUGGESTION: "/api/sch/fetchCustomers",
        FETCH_MEETING_SUGGESTION: "/api/sch/fetchTimeslots",
        SAVE_MEETINGS_BULK: "/api/rhs/meetings/batch",
        DNA_FEEDBACK: "/api/scf"
    },

    sample: {
        SAMPLE_ALLOCATIONS: '/api/bms/sampleproductconfigurations',
        CUSTOMER_SAMPLE_ALLOCATIONS: '/api/bms/v163/customersampleallocations?positionIds={{positionIDs}}&startDate={{startDate}}&endDate={{endDate}}',
        LOTS: '/api/bms/v150/samplingallocations?startDate={{startDate}}&positionIds={{positionIDs}}',
        CREATE_ALLOCATIONS_DROP: '/api/rhs/v93/sampledrops',
        UPDATE_ALLOCATIONS_DROP: '/api/rhs/v45/sampledrops/{{SampleActivityId}}',
        GET_CONSENT_TERMS: '/api/cag/v451/consentterms?channel=Allocation Orders',
        GET_CONSENT_TYPES: '/api/cag/consenttypes',
        GET_ALLOCATION_TRANSFER_USERS: '/api/rhs/allocationtransfer/users',
        GET_CUSTOMER_LICENSES: '/api/bms/customerlicenses?positionIds={{positionIDs}}',
    },
    accountManagement:{
      CREATEORUPDATE_PROGRESS_REPORT: '/api/cag/objectives/progressreports',
      DELETE_PROGRESS_REPORT: '/api/cag/objectives/progressreports/{REPORTID}',
      SAVE_ACCOUNT_PLAN: "/api/cag/accountplans/{accountPlanId}",
      DEFAULT_CURRENCY_SYMBOL: "/api/data/v9.1/usersettingscollection({userId})?$select=currencysymbol,_transactioncurrencyid_value",
      PERSONAL_SETTING_CURRENCY_SYMBOL: "/api/data/v9.1/transactioncurrencies({transactioncurrencyid})?$select=currencysymbol",
      SAVE_ACTIVITY_MEETING: "/api/cag/accountplans/{accountPlanId}/meetings",
      SAVE_ACTIVITY_PHONECALL: "/api/cag/accountplans/{accountPlanId}/phonecalls",
      UPDATE_OBJECTIVE: "/api/cag/accountplanobjectives/{{AccountPlanObjectiveId}}",
    },
    followup: {
       CREATE_UPDATE_FOLLOW_UP: '/api/cag/v122/accountcallplan/followuptasks',
       DELETE_FOLLOW_UP: '/api/cag/accountcallplan/followuptasks/{{followupid}}',
       GET_SAME_LEVEL_AND_CHILD_USERS_LIST: '/api/cag/followuptasks/users?positionIds={{positionIds}}',
       GET_STATUS_REASONS: `/api/data/v9.0/EntityDefinitions(LogicalName='task')/Attributes/Microsoft.Dynamics.CRM.StatusAttributeMetadata?$expand=OptionSet`,
       SAVE_FOLLOW_UP_OBJECTIVES : '/api/cag/followuptasks/{followupid}/objectives'
    },
    orderManagement: {
        GET_ALL_ORDERS: '/api/bms/v50/orders?startDate={{startDate}}&endDate={{endDate}}&positionIds={{positionIds}}&lastUpdatedTime={{lastUpdatedTime}}',
        CREATE_UPDATE_ORDER: '/api/rhs/v172/orders',
        UPDATE_ORDER :  '/api/rhs/v172/orders',
        DELETE_ORDER_ACTIVITY: '/api/rhs/v134/orders/{{salesorderid}}',
        CREATE_UPDATE_PROCEDURE_PERIOD_TRACKER: '/api/rhs/proceduretracker/{{procedureid}}',
        UPDATE_PROCEDURE_TRACKER: '/api/rhs/proceduretracker',
        DELETE_PROCEDURE_TRACKER: '/api/rhs/proceduretracker/{{procedureTrackerId}}',
        APPROVE_NOT_APPROVE_ORDER: '/api/rhs/approvalactivities/{{approvalactivityid}}',
        CREATE_UPDATE_ASSET_BOOKING: '/api/cag/assetbookings'
    },
     procedureContract: {
      UPDATE_PROCEDURE_CONTRACT:'/api/rhs/v143/procedurecontracts/{{procedureContractId}}',
      UPLOAD_OFFLINE_PROCEDURE_CONTRACT:'/api/rhs/v143/offline/procedurecontracts',
      DOWNLOAD_CONTRACT_DOC:'/api/rhs/v142/procedurecontract/download?contractId={{contractId}}&fileName={{fileName}}',
      DOWNLOAD_GENERATED_CONTRACT_DOC:'/api/rhs/v161/procedurecontracts/digitaldocuments?contractId={{contractId}}&usageType={{usageType}}&buId={{buId}}',
      UPLOAD_CONTRACT_DOC:'/api/rhs/v142/procedurecontract/upload?contractId={{contractId}}',
      CREATE_NEW_PROCEDURE_CONTRACT:'/api/rhs/v143/procedurecontracts'
    },
    opportunityManagement: {
        CREATE_OPPORTUNITY: '/api/cag/v131/opportunities',
        UPDATE_OPPORTUNITY: '/api/cag/v131/opportunities/{{opportunityid}}',
        OFFLINE_CREATE_OPPORTUNITY: '/api/cag/v131/offline/opportunities',
        DELETE_OPPORTUNITY: '/api/cag/opportunities/{{opportunityid}}',
        CLOSE_OPPORTUNITY: '/api/cag/opportunities/{{opportunityid}}/closeopportunities?closeType={{closeType}}',
        UPDATE_OPPORTUNITY_STATUS:'/api/cag/v131/opportunities/{{opportunityid}}/status',
        UPSERT_OPPORTUNITY_NOTE: '/api/cag/opportunities/{{opportunityid}}/notes',
    },
    acknowledgment_receipt:{
        ALLOCATION_SHIPMENTS: '/api/bms/v45/usershipmentallocations',
        SHIPMENT_LOSS_REASONS: '/api/rhs/usershipmentallocations/lossreasons',
        ACKNOWLEDGE_SHIPMENT: '/api/rhs/usershipmentallocations/{shipment_id}/acknowledge',
        ACKNOWLEDGE_HEADERS: {
            headers: new HttpHeaders({
                'X-SystemUserId': '{system_id}'
            })
        },
    },
    alloc_transfer: {
        ALLOC_TRANSFER_REASONS: '/api/rhs/usershipmentallocations/transferreasons',
        ALLOC_TRANSFER: '/api/rhs/usershipmentallocations',
        ALLOC_TRANSFER_HEADERS: {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-SystemUserId': '{system_id}',
            })
        },
    },
    alloc_adjustment:{
      ALLOC_ADJUSTMENT_REASONS: '/api/rhs/usershipmentallocations/adjustmentreasons',
      ALLOC_ADJUSTMENT_CREATION: '/api/rhs/usershipmentallocations/adjustment',
      ALLOC_ADJUSTMENT_DETAIL: '/api/bms/allocationadjustments/',
      ALLOC_ADJUSTMENT_UPDATE: '/api/rhs/usershipmentallocations/{{adjustmentID}}/adjustment',
      ALLOC_ADJUSTMENTS: '/api/bms/allocationadjustments',
      ALOCC_DELETE_ADJUSTEMNT:'/api/rhs/usershipmentallocations/{{adjustmentID}}/adjustment',
      ALLOC_ADJUSTMENT_HEADERS: {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-SystemUserId': '{system_id}',
            })
        },
    },
    websockets: {
        BASE: `/api/mwb/echo`,
        BASE_URL: 'wss://websocketmicroservice.io/meetings/{activity_id}',
        SHARE_PRESENTATION_SLIDE: 'wss://websocketmicroservice.io/meetings/{activity_id}/presentations/asset',
        SHARE_PRESENTATION_SLIDE_MAPPING: '/app/meetings/{activityId}/presentations/asset',
        SHARE_RESOURCE_MAPPING: '/app/meetings/{activityId}/share/resource',
        SHARE_RESOURCE_ASSET_MAPPING: '/app/meetings/{activityId}/resources/asset',
        SHARE_PRESENTATION_MAPPING: '/app/meetings/{activityId}/presentations/asset',
        SHARE_CACHED_PRESENTATION_MAPPING: '/app/meetings/{activityId}/presentations/asset/contact',
        SHARE_PAGE_SENTIMENT: '/app/meetings/{activityId}/presentations/assets/sentiment',
        SHARE_WE_PARTCIPANTS: '/app/wemeetings/{activityId}/participants/list',
        SHARE_RESOURCE_PAGE_SENTIMENT:  '/app/meetings/{activityId}/resources/assets/sentiment',
        PUSH_RESOURCES: '/app/meetings/{activityId}/pushresources',
        PUSH_RESOURCE_STATUS: '/app/meetings/{activityid}/pushresources/status',
        VIDEO_STATUS: '/app/meetings/{activityId}/video',
        END_REMOTE_MEETING: '/app/meetings/{activityId}/remote/end',
        REMOTE_ACTION:'/app/meetings/{activityId}/remote/action',
        VIRTUAL_EVENT:'/app/meetings/{activityId}/interactive/events',
        SHARE_VIDEO_RESOURCE_EVENTS:'/app/meetings/{activityId}/resources/events',
    },

   notes_assisstant:{
     configuration: GLOBAL_AUTH + 'tnt/analytics/notesassistant/configuration',
       LUISDetection:"https://{{LUISregion}}.api.cognitive.microsoft.com/luis/v2.0/apps/{{appID}}?verbose=true&timezoneOffset=-360&subscription-key={{subKey}}&q=",
       sendToModerator: GLOBAL_AUTH + "nam/notes"
   },
   poll: {
     GET: '/api/bms/pollings?positionIds={{positionIDs}}',
     GET_DELTA: '/api/bms/pollings?lastUpdatedTime={lastUpdatedTime}&positionIds={{positionIDs}}',
     CREATE: '/api/rhs/v51/pollings',
   },
   casemanagement: {
        FETCH_EXPERT_CATEGORIES: '/api/cag/v172/expertcategories',
        FETCH_RESPONSE_PREFERENCE: '/api/cag/responsepreferences',
        CREATE_CASE: '/api/cag/cases',
        UPLOAD_OFFLINE_CASE: '/api/cag/offline/cases',
        FETCH_ALL_CASES: '/api/bms/cases?startDate={startDate}&endDate={endDate}&category={casecategory}',
        DELTA_FETCH_CASES: '/api/bms/cases?lastUpdatedTime={lastUpdatedTime}&category={casecategory}',
        DELTA_FETCH_CASES_BY_ID: '/api/bms/v150/deltachanges/cases/{CASE_ID}',
        FETCH_PRODUCT_SKU: '/api/cag/brandskuproducts?positionIds={{positionIDs}}',
        FETCH_CASE_BY_ID: '/api/cag/v150/cases/{CASE_ID}'
    },
    xperiences: {
        FETCH_TRENDING_CUTOMERS: '/api/xpr/fetchXperiences',
        FETCH_INSIGHT_OPTIONSETS: '/api/cag/cxinsights/optionsets',
        FETCH_INSIGHT_INTEREST_CATEGORIES: '/api/cag/interestcategories',
        CREATE_CUSTOMER_INSIGHT: '/api/cag/contacts/insights',
        SAVE_CUSTOMER: '/api/xpr/saveContact',
        SEND_FEEDBACK: '/api/xpr/feedback',
        RELATIONSHIP_INSIGHT_FEEDBACK: '/api/xpr/feedback_relationship_insight',
        MARKETING_EMAIL_DETAILS: '/api/cag/marketingemails/{email_id}',
        CREATE_GENERAL_INSIGHT: '/api/cag/general/insights',
        FETCH_JOURNEY_INSIGHTS: '/api/cag/journeyinsights/{insight_id}',
        FETCH_INTEREST_INSIGHTS: '/api/cag/interestinsights/{insight_id}',
        FETCH_RELATIONSHIP_INSIGHTS: '/api/cag/relationshipinsights/{insight_id}',
        FETCH_TRENDING_ACCOUNTS: '/api/xpr/accountXperiences',
        FETCH_TRENDING_FEEDS: '/api/xpr/feedsXperiences'
    },
    customerEvents: {
        FETCH_CONTACT_EVENTS: '/api/bms/v73/contactevents?positionIds={{positionIDs}}&startDate={{startDate}}',
        CAPTURE_EVENT_REGISTRATION_RESPONSE: '/api/rhs/v82/eventregistrationresponses',
        FETCH_EVENTS_AND_REGISTRATIONS: '/api/cag/contacts/{{contactId}}/eventsregistrationresponse'
    },
    advanceSearch:{
        CREATE_SEARCH: '/api/cag/v162/usersearches',
        DELETE_SEARCH: '/api/cag/usersearches/{SEARCHID}',
        GET_TOOL_NAMES: '/api/cag/usersearches/searchtools',
        GET_USER_SAVED_SEARCHES: '/api/bms/v162/usersearches?lastUpdatedTime={lastUpdatedTime}',
        OFFLINE_UPLOAD_USER_SEARCHES: '/api/cag/v162/offline/usersearches'
    },
    edgeAnalytics:{
      facts:'/facts?positionIds={positionIds}&startYearMonth={startYearMonth}',
      dimensions:'/dimensions?positionIds={positionIds}&startYearMonth={startYearMonth}'
    },
    phonecall:{
      CREATE_PHONE_CALL_ACTIVITY:'/api/rhs/phonecalls',
      UPDATE_PHONE_CALL_ACTIVITY:'/api/rhs/v50/phonecalls/{activity_id}',
      UPDATE_PHONE_CALL_DURATION:'/api/rhs/v104/phonecalls/{activity_id}',
      GET_DELTA_PHONE_CALL_ACTIVITY_DETAILS:'/api/bms/v104/deltachanges/phonecalls/{activity_id}?startDate={startDate}&endDate={endDate}&activityId={activity_id1}&lastUpdatedTime={lastUpdatedTime}&positionIds={positionIds}&phonecallConfigFields={phonecallConfigFields}',
      UPDATE_APP_CONFIGFIELDS_TO_PHONE_CALL:'/api/rhs/phonecalls/{activity_id}/configfields',
      CREATE_CALL_ACTIVITY: '/api/rhs/phonecalls/{phoneCallId}/callactivities',
      UPDATE_CALL_ACTIVITY: '/api/rhs/callactivities/{callActivityId}',
      GET_VOICE_CALL_RECORD: '/api/cag/voicecallrecords',
      GET_VOICE_CALL_RECORD_LINK: '/api/cag/voicecallrecordlink',
      GET_VOICE_CALL_RECORD_LINKS: '/api/cag/voicecallrecordlinks'
    },
    nextCallObjective: {
        CREATE_NEXT_CALL_OBJECTIVE:'/api/rhs/callobjectives',
        UPDATE_DELETE_NEXT_CALL_OBJECTIVE:'/api/rhs/callobjectives/{objective_id}',
        OFFLINE_NEXT_CALL_OBJECTIVE: '/api/rhs/offline/callobjectives'
    },
    marketScan: {
        GET_MARKET_SCANS: '/api/csn/v90/marketscans?startDate={startDate}&endDate={endDate}&positionId={positionId}',
        GET_CUSTOMER_SEGMENTATIONS: '/api/bms/contacts/customersegmentations?positionIds={PositionIds}',
        GET_DELTA_MARKET_SCAN_BY_ID: '/api/csn/v90/deltachanges/marketscans/{marketScanId}?lastUpdatedTime={lastUpdatedTime}',
        CREATE_MARKET_SCAN: '/api/cag/v80/marketscans',
        UPDATE_MARKET_SCAN: '/api/cag/v80/marketscans',
        DELETE_MARKET_SCAN: '/api/cag/marketscans/{marketScanId}',
        OFFLINE_UPLOAD_MARKET_SCAN: '/api/cag/offline/v80/marketscans',
        GET_CATEGORIES: '/api/csn/v90/marketscancategories',
        EVENTS: '/api/csn/events?positionIds={PositionIds}&startDate={startDate}&endDate={endDate}',
        CUSTOMER_JOURNEYS: '/api/csn/customerjourneys?positionIds={PositionIds}&startDate={startDate}&endDate={endDate}',
        AFFILIATED_ACCOUNTS: '/api/csn/marketscanaccounts?positionIds={PositionIds}'
    },
    eventsTool: {
        SYNC_EVENTS_ACTIVITY_DATA: '/api/bms/v151/events?positionIds={{positionIDs}}&startDate={{startDate}}&endDate={{endDate}}{{lastupdatedTime}}',
        PATCH_REALTIME_DETAILS:'/api/cag/events/{{eventid}}',
        FETCH_REALTIME_DETAILS:'/api/cag/v151/events/{{eventid}}',
        CREATE_UPDATE_NEW_EVENT: '/api/cag/events',
        SAVE_EVENT_ATTENDEE_PASSES: '/api/cag/events/{eventId}/attendeepasses',
        UPLOAD_EVENTS_DATA: '/api/cag/v92/offline/events',
        SEND_FOR_APPROVAL: '/api/cag/events/sendforapproval',
        SEND_FOR_APPROVAL_PARTICIPANT: '/api/cag/events/sendforapprovalparticipant'
    },
    mdm: {
        GET_CONTACT_CRS: '/api/bms/contactchangerequests?startDate={startDate}',
        GET_ACCOUNT_CRS: '/api/bms/accountchangerequests?startDate={startDate}',
        CREATE_CONTACT_CR: '/api/cag/dynamicforms/contactchangerequests',
        EDIT_CONTACT_CR: '/api/cag/dynamicforms/contactchangerequests/{contactId}',
        CREATE_ACCOUNT_CR: '/api/cag/dynamicforms/accountchangerequests',
        UPDATE_CONTACT_CR: '/api/cag/dynamicforms/contactchangerequests/{contactId}',
        UPDATE_ACCOUNT_CR: '/api/cag/dynamicforms/accountchangerequests/{accountId}',
        SCRAP_CONTACT_CR: '/api/cag/dynamicforms/contactchangerequests/{contactId}',
        SCRAP_ACCOUNT_CR: '/api/cag/dynamicforms/accountchangerequests/{accountId}',
        UPLOAD_OFFLINE_CR: '/api/cag/offline/contactchangerequests',
        GET_HCP_QRCODE: '/api/cag/hcpQrCode',
        FETCH_MDM_MATERIAL_CR: '/api/cag/mdmMaterial',
        GET_HCP_EXCLUSICE_QR_CODE: '/api/cag/hcpQrExclusiveCode?userid={useremail}&businessCode={useretmscode}&type={userbusinessline}&hcoCode={accountetmscode}',
        UPDATE_APPROVAL_ACTIVITY: '/api/rhs/approvalactivities/{approvalActivityId}'
    },
    surgeryOrder: {
      SYNC_SURGERY_ORDER_ACTIVITIES: '/api/bms/orders?positionIds={{positionIDs}}&startDate={{startDate}}&endDate={{endDate}}{{lastupdatedTime}}',
    },
    customerAsset: {
      UPDATE_CUSTOMER_ASSET: '/api/cag/assets/{assetId}',
      CANCEL_CUSTOMER_ASSET_DEPLOYMENT: '/api/cag/assets/{assetId}/canceldeployment',
      CREATE_UPDATE_CUSTOMER_ASSET_TRANSFER_NOTE: '/api/cag/assettransfer/notes',
      UPDATE_TRANSFER_REQUEST_STATUS: '/api/cag/assets/{assetTransferId}/acknowledge',
      CREATE_ASSET_NOTES: '/api/cag/assets/notes',
      UPDATE_NOTES: '/api/cag/notes/{noteId}',
      DELETE_NOTES: '/api/cag/notes/{noteId}',
      CREATE_TRANSFER_REQUEST: '/api/cag/v81/asset/{assetId}/transfer'
    },
    OneKey: {
      ServiceProvider: 'imshealth.com',
      SEARCH_OK: '/ok/search/{connectorId}',
      SUBMIT_VR: '/vr/submit/{connectorId}',
      TRACE_VR: '/vr/trace/{connectorId}',
      SUBMIT_POP: '/pop/submitKey/{connectorId}'
    },
    CognitiveSearch:{
      keywordSearch: '/api/cog/cogsearch'
    },
    customerAssessment: {
      UPLOAD_OFFLINE_ASSESSMENTS: '/api/cag/offline/assessments',
      SCRAP_ASSESSMENT: '/api/cag/assessments/{assessmentId}'
    },
    marketingBusineesPlan:{
      SAVE_MARKET_BUSINESS_PLAN:'/api/cag/v131/marketingbusinessplans/{marketPlanId}',
      SAVE_MARKET_BUSINESS_PLAN_ATTACHMETS:'/api/cag/marketingbusinessplans/{marketPlanId}/notes',
      SAVE_MEETINGS_FOR_MARKETING_PLAN: '/api/cag/v121/marketingbusinessplans/{marketingPlanId}/meetings',
      SAVE_PHONE_CALLS_FOR_MARKETING_PLAN: '/api/cag/marketingbusinessplans/{marketingPlanId}/phonecalls',
      SAVE_SURGERY_ORDER_FOR_MARKETING_PLAN : '/api/cag/marketingbusinessplans/{marketingPlanId}/orders',
      DELETE_MARKETING_PLAN : '/api/rhs/marketingbusinessplan/{marketingPlanId}'
    },
    quotes:{
      SAVE_QUOTES:'/api/cag/quotes/{quoteId}',
      SAVE_QUOTES_ATTACHMENTS : '/api/cag/quotes/{quoteId}/notes',
      SAVE_QUOTES_PRODUCTS : '/api/cag/quotes/{quoteId}/quotedetails',
      UPDATE_APPROVAL_ACTIVITY: '/api/rhs/approvalactivities/{approvalActivityId}'
    },
    pharmacovigilance: {
      GET_PHARMACOVIGILANCE_REPORTS: '/api/bms/pharmacovigilance',
      POST_PHARMACOVIGILANCE_REPORT: '/api/cag/pharmacovigilance',
    },
    territoryManagement:{
      CREATE_CUSTOMER_POSITION_LIST:'/api/cag/customerpositionlists',
      UPDATE_CUSTOMER_POSITION_LIST:'/api/cag/customerpositionlists/{customerPostionListId}',
      DELETE_CUSTOMER_POSITION_LIST:'/api/cag/customerpositionlists/{customerPostionListId}'
    },
    survey: {
      INTERNAL:'/api/cag/v131/internalsurvey',
      SCRAP_SURVEY: '/api/cag/internalsurvey/{userSurveyid}'
    },
    storecheck: {
      CREATE_STORE_CHECK: '/api/rhs/storechecks',
      UPDATE_STORE_CHECK: '/api/rhs/storechecks/{activityId}',
      UPDATE_PROMO_CHECK: '/api/rhs/storechecks/{activityId}/promochecks'
    },
    photos: {
      UPLOAD_PHOTOS: '/api/rhs/azureblobstorage/uploadmultiimage'
    },
    offtakeCheck: {
      SAVE_OFFTAKE: '/api/cag/offtakechecks'
    },
    setbooking: {
      CREATE_UPDATE_SET_BOOKING: '/api/cag/dynamicforms/kitbookings',
      SET_BOOKING_NOTES: '/api/cag/kitbookings/notes',
    }
}
