import { PRODUCT_DISPLAY } from './../../../classes/activity/surgery-order.activity.class';
import { Brand } from './../../../classes/brand/brand.class';
import { MainToolTemplateComponent } from './../../shared/main-tool-template/main-tool-template';
import { BrandOfflineService } from './../../../services/brand/brand.service';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from './../../../models/mainToolTemplateDetail.model';
import { OrderCreationType } from './../../../classes/opportunity-management/opportunity.class';
import { Opportunity } from '@omni/classes/opportunity-management/opportunity.class';
import { OpportunityManagementDataService } from '@omni/data-services/opportunity-management/opportunity-management.data.service';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavParams, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { isFuture, isPast } from 'date-fns';
import { ActivityType } from '../../../classes/activity/activity.class';
import { OrderActivity, OrderProductClass } from '../../../classes/activity/order.activity.class';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { PriceList } from '../../../classes/order-management/price-list.class';
import { ConfirmationAlertData } from '../../../classes/shared/confirmation-alert-data.class';
import { ActivityDataService } from '../../../data-services/activity/activity.service';
import { OperationDetail, OrderActivityDataService } from '../../../data-services/order-activity/order-activity.data.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { SharedDataService } from '../../../data-services/shared-data/shared.data.service';
import { FormFieldType, IndFormFieldViewDataModel } from '../../../models/indFormFieldDataModel';
import { SelectListData } from '../../../models/select-list-data-model';
import { SelectListDetail } from '../../../models/select-list-detail-model';
import { AccountOfflineService, AccountSelectedFor } from '../../../services/account/account.offline.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { AlertService } from '../../../services/alert/alert.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { DeviceService } from '../../../services/device/device.service';
import { EventsService } from '../../../services/events/events.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { TrackingEventNames, TrackService } from '../../../services/logging/tracking.service';
import { ChildNavNames, NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { OpportunityManagementService } from '../../../services/opportunity-management/opportunity-management.service';
import { OrderManagementService } from '../../../services/order-management/order-management.service';
import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { Utility } from '../../../utility/util';
import { AccountPageComponent } from '../../account/account-page/account-page';
import { PopoverComponent } from '../../popover/popover';
import { NothingSelectedView } from '../../shared/nothing-selected-view/nothing-selected-view';
import { SelectListComponent } from '../../shared/select-list/select-list';
import { OrderAddQuantityComponent } from '../order-add-quantity/order-add-quantity';
import { IndPageTitleViewDataModel } from './../../../models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from './../../../models/indSectionHeaderDataModel';
import { AccountManagementOfflineService } from './../../../services/account-management/account-management.service';
import { AgendaFooterService } from './../../../services/footer/agenda-footer.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import _ from 'lodash';
import { SurveyService } from '@omni/services/survey/survey.service';
import { MobileAppTemplateType } from '@omni/classes/survey/survey-form.class';

/**
 * Generated class for the OrderDetailsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'order-details',
  templateUrl: 'order-details.html',
  styleUrls:['order-details.scss']
})
export class OrderDetailsComponent {

  public currentOrderActivity: OrderActivity;
  private isLayoverPushView: boolean;
  private layoverViewPushedFrom: string;
  public isAddProductEnabled = false;
  public isSendForApprovalEnabled = false;
  public isCancelReviewEnabled = false;
  public isScrapEnabled = false;
  public isMarkOrderNotApprovedEnabled = false;
  public isMarkOrderApprovedEnabled = false;
  public isReopenEnabled = false;
  public isFulfillEnabled = false;
  public isAutoApprovalEnabled = false;
  public isReadOnlyMode = true;
  public isViewFromTeamOrder = false;
  public orderDetailsViewMode: string;
  private _selectCountryModal: HTMLIonModalElement;
  public currentMatchedPriceList: PriceList;
  // public isEditingDiscount: boolean = false;
  private hardDisableFooterButtonsCheck = false;
  private _initialDiscountValue: number;
  unsavedDiscount: number;
  accountPlanPopover: HTMLIonPopoverElement;
  isOpportunityEnabled = false;
  isAccountPlanEnabled = false;
  isOrderWithinOpportunityContext = false;
  orderDetailsPanePageTitle: IndPageTitleViewDataModel;
  @Input() from: any;
  @Input() activity: any;
  detailsHeader: IndSectionHeaderViewDataModel;
  productsHeader: IndSectionHeaderViewDataModel;
  statusFormField: IndFormFieldViewDataModel;
  createdByFormField: IndFormFieldViewDataModel;
  orderNumberFormField: IndFormFieldViewDataModel;
  countryFormField: IndFormFieldViewDataModel;
  accountFormField: IndFormFieldViewDataModel;
  accountPlanFormField: IndFormFieldViewDataModel;
  opportunityFormField: IndFormFieldViewDataModel;
  totalFormField: IndFormFieldViewDataModel;
  discountFormField: IndFormFieldViewDataModel;
  productsFormField: IndFormFieldViewDataModel;
  allProductsMainCardModel: MainCardViewDataModel[];
  symbolPosSubscription: Subscription;
  decimalPosSubscription: Subscription;
  public backgroundUploadInProgress: boolean = false;
  //public orderStatusString: string;
  private isClickedCountry: boolean = false;
  private isClickedAccount: boolean = false;
  private isClickedTotal: boolean = false;

  private readonly ngDestroy$ = new Subject<boolean>();
  private products: Brand[] = [];
  private isClickedProductsSelect: boolean = false;
  public isSurveyConfigured: boolean = false;
  public salesOrderSurveyHeaderModel: IndSectionHeaderViewDataModel;
  public surveyLookupResults: Array<any>=[];
  private _surveyTemplateLoaded:boolean = false;
  public surveyTemplate: any;
  private isSurveyValid:boolean = true;
  private _surveyJsAttributesFetched:boolean = false;
  public allDataInitialized:boolean = false;

  constructor(
    public repService: RepServices,
    public navService: NavigationService,
    public uiService: UIService,
    public device: DeviceService,
    public trackingService: TrackService,
    public orderManagementService: OrderManagementService,
    private readonly accountService: AccountOfflineService,
    public readonly footerService: FooterService,
    private readonly alertService: AlertService,
    private readonly orderActivityDataService: OrderActivityDataService,
    private readonly events: EventsService,
    private readonly authService: AuthenticationService,
    private readonly sharedDataService: SharedDataService,
    private readonly modalCtrl: ModalController,
    private readonly activityService: ActivityService,
    public notifications: NotificationService,
    private readonly activityDataService: ActivityDataService,
    public translate: TranslateService,
    private readonly accountManagementService: AccountManagementOfflineService,
    private readonly popoverCtrl: PopoverController,
    private readonly opportunityService: OpportunityManagementService,
    private readonly agendaFooterService: AgendaFooterService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private loadingController: LoadingController,
    private _CD: ChangeDetectorRef,
    private readonly opportunityManagementDataService: OpportunityManagementDataService,
    private readonly brandService: BrandOfflineService,
    private navParams: NavParams,
    public readonly surveyService: SurveyService,
  ) {
    this.symbolPosSubscription = this.dateTimeFormatsService.symbolPos.subscribe( sp => {
      if (sp) {
        this.getTotalFormField();
      }
    });
    this.decimalPosSubscription = this.dateTimeFormatsService.decimalPos.subscribe( dp => {
      if (dp) {
        this.getTotalFormField();
      }
    });
    this.events.subscribe('reloadSurveyTemplate', () => {
      this.loadSurveyTemplate();
    })
  }

  async ngOnInit() {
      if (this.from) {
        this.isLayoverPushView = true;
        this.layoverViewPushedFrom = this.from;
        if (this.layoverViewPushedFrom === 'OpportunityManagementTool'
            || this.layoverViewPushedFrom === 'AccountProfileOpportunities'
            || this.layoverViewPushedFrom === 'AccountPlanOpportunities'
            || this.layoverViewPushedFrom === 'MarketingPlanOpportunities'
            ) {
          this.isOrderWithinOpportunityContext = true;
          this.uiService.isFromOpportunites=false;
        }
      }
      if (this.activity) {
        this.currentOrderActivity = this.activity;
        if (this.currentOrderActivity.opportunityId) {
          const opportunity = this.opportunityService.opportunities.find(opp => opp.ID == this.currentOrderActivity.opportunityId);
          if (opportunity) {
            const selectedOpportunityType = this.opportunityService.opportunityTypes.find(a => a.indskr_opportunitytypeid == opportunity.opportunityTypeId);
            if (selectedOpportunityType && selectedOpportunityType.indskr_ordercreationprocess === OrderCreationType.MANUAL) {
              this.currentOrderActivity.manualOrder = true;
              this.products = !_.isEmpty(this.brandService?.brands) ? this.brandService?.brands : [];
              if (selectedOpportunityType.indskr_productdisplay == PRODUCT_DISPLAY.NPI_PRODUCTS_ONLY) {
                this.products = this.products.filter(p => p.indskr_newproductintroduction);
              } else if (selectedOpportunityType.indskr_productdisplay == PRODUCT_DISPLAY.ALL_EXCLUDING_NPI_PRODUCTS) {
                this.products = this.products.filter(p => !p.indskr_newproductintroduction);
              }
            }
          }
        }
        this._initialDiscountValue = this.currentOrderActivity.elligibleDiscount;
        if (this.currentOrderActivity.isTeamOrder) {
          if (this.layoverViewPushedFrom && (this.layoverViewPushedFrom === 'OrderManagementTool' || this.layoverViewPushedFrom === 'AgendaView')) {
            this.isViewFromTeamOrder = true;
          }
          this.isReadOnlyMode = true;
        }
        this.updateFooterViewButtons();
      }
      if (this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT)) this.isAccountPlanEnabled = true;
      if (this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT)) this.isOpportunityEnabled = true;
      this.isAutoApprovalEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT_AUTO_APPROVAL);
      await this._initSalesOrderSurveyJsAttributes();
      if(this.isSurveyConfigured){
        this.uiService.displayLoader();
      }
      this.initAllDataModelsWitchDetectChange();
      this.trackingService.tracking('OrderInfo', TrackingEventNames.ORDERMANAGEMENT);

      this.translate.onLangChange.subscribe((data) => {
        this.initAllDatamodels();
      })

      this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
        this.updateFooterViewButtons();
        this._initSalesOrderSurveyJsAttributes();
        this.initAllDatamodels();
      });

      this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
        if (this.currentOrderActivity) {
          this.backgroundUploadInProgress = inProgress && this.currentOrderActivity.pendingPushToDynamics;
          this.updateFooterViewButtons();
          this.getPageTitle();
          this.getProductHeader();
        }
      });

      this.events.observe('updateMandatoryField').pipe(takeUntil(this.ngDestroy$)).subscribe((field) => {
          if(field == PageName.AccountPageComponent) {
            this.isClickedAccount = true;
            this.getAccountFormField();
          }
      });
      if(this.isSurveyConfigured){
        await setTimeout(() => {
          try {
            document.getElementById('ordermaindetailslist').scrollIntoView();
            // this.uiService.dismissLoader();
          } catch (error) {
            
          }
        },400);
      }
      this.allDataInitialized = true;
  }

  ngDoCheck() {
    if(!this.activityService.selectedActivity || this.activityService.selectedActivity.ID !== this.currentOrderActivity.ID) return;

    if (this.orderActivityDataService.isSelectedOrderActivityUpdated) {
      if(this.currentOrderActivity.isTeamOrder){
        this.initAllDatamodels();
        this.orderActivityDataService.isSelectedOrderActivityUpdated = false;
        return;
      }
      const latestOrderDetails = this.activityService.getActivityByID(this.currentOrderActivity.ID) as OrderActivity;
      if(this.currentOrderActivity.orderStatusString !== latestOrderDetails.orderStatusString
          || this.currentOrderActivity.accountNameString !== latestOrderDetails.accountNameString){
            this.events.publish('updateOrdersList');
      }
      this.currentOrderActivity = latestOrderDetails;
      this.hardDisableFooterButtonsCheck = false;
      this.initSurveyData();
      this.initAllDataModelsWitchDetectChange();
      if (this.activityService.selectedActivity && this.activityService.selectedActivity.ID === this.currentOrderActivity.ID) {
        this.activityService.selectedActivity = this.currentOrderActivity;
      }

    }
    if (this.hardDisableFooterButtonsCheck) return;
    if (this.currentOrderActivity?.isHardDeleted) {
      this.hardDisableFooterButtonsCheck = true;
      this.onClosePage();
    }
    if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
      if (this.uiService.activeView === 'ActivitiesPageRightPaneNav' && this.navService.getActiveChildNavViewPageName() === PageName.OrderDetailsComponent) {
        if (this.navService.getCurrentMasterPageName() === PageName.OrderAddQuantityComponent) {
          return;
        }
      } else {
        return;
      }
    }
    else if (this.navService.getActiveChildNavName() === ChildNavNames.OpportunityManagementNavigation) {
      if (this.navService.getCurrentMasterPageName() === PageName.OrderAddQuantityComponent) {
        return;
      }
    } else if (this.navService.getActiveChildNavName() === ChildNavNames.OrderManagementNavigation) {
      if (this.navService.getCurrentMasterPageName() === PageName.OrderAddQuantityComponent) {
        return;
      }
    } else if (this.navService.getActiveChildNavName() === ChildNavNames.ContactPageNavigation) {
      if (this.uiService.activeView !== 'ContactPageRightPaneNav') {
        return;
      }
    }
    if (this.navService.getActiveChildNavViewPageName() !== PageName.OrderDetailsComponent ||
    (this.activityService.selectedActivity && this.activityService.selectedActivity.type !== ActivityType.Order)) return;
    if (this._currentViewButtonsNotInitializedCheck()) {
      this.initFooterViewButtons();
      this.updateFooterViewButtons();
    }
    // Enable or Disable footer buttons according to view
    if (this.orderActivityDataService.isSelectedOrderActivityUpdated) {
      this.orderActivityDataService.isSelectedOrderActivityUpdated = false;
      this.updateFooterViewButtons();
      this.getProductHeader();
      this.getPageTitle();
    }
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.symbolPosSubscription.unsubscribe();
    this.decimalPosSubscription.unsubscribe();
    this.events.unsubscribe('reloadSurveyTemplate');
  }

  get orderStatusString(){
    let str;
    switch (this.currentOrderActivity.orderStatusString) {
      case 'Canceled':
        str = this.translate.instant('CANCELLED');
        break;
      case 'Approved':
        str = this.translate.instant('APPROVED');
        break;
      case 'For Review':
        str = this.translate.instant('FOR_REVIEW');
        break;
      case 'Fulfilled':
        str = this.translate.instant('OM_FULFILLED');
        break;
      case 'Draft':
        str = this.translate.instant('OM_DRAFT');
        break;
      case 'Open':
        str=this.translate.instant('OPEN');
        break;
      case 'Rejected':
        str=this.translate.instant('REJECTED');
        break;
      default:
        str = this.currentOrderActivity.orderStatusString;
        break;
    }
    return str;
  }

  public get productsStringForTitle(): string {
    let str = '';
    if(this.currentOrderActivity){
      if(this.currentOrderActivity.products.length === 1){
        str = '- '+this.currentOrderActivity.products[0].productName;
      }else if(this.currentOrderActivity.products.length > 1){
        str = '- '+this.currentOrderActivity.products[0].productName+' +'+(this.currentOrderActivity.products.length-1);
      }
    }
    return str;
  }

  private _currentViewButtonsNotInitializedCheck(): boolean {
    if (!this.currentOrderActivity) return false;
    let flag: boolean;
    switch (this.currentOrderActivity.orderStatusString) {
      case 'Draft':
        if (!this.isViewFromTeamOrder) {
          flag = !this.footerService.getActiveButtonsList().some(id => id === 'scraporderactivity' || id === 'sendorderactivityforreview');
        } else {
          this.hardDisableFooterButtonsCheck = true;
          this.onClosePage();
        }
        break;
      case 'For Review':
        if (!this.isViewFromTeamOrder) {
          flag = !this.footerService.getActiveButtonsList().some(id => id === 'cancelorderactivityreview');
        } else {
          flag = !this.footerService.getActiveButtonsList().some(id => id === 'notapproveorderactivity' || id === 'approveorderactivity');
        }
        break;
      case 'Approved':
        if (!this.isViewFromTeamOrder) {
          flag = !this.footerService.getActiveButtonsList().some(id => id === 'fulfillorderactivity' || id === 'reopenorderactivity');
        } else {
          flag = false;
          this.footerService.initButtons(FooterViews.Contacts);
        }
        break;
      case 'Fulfilled':
      default:
        flag = false;
        this.footerService.initButtons(FooterViews.Contacts);
    }
    return flag;
  }

  private initFooterViewButtons(): boolean {
    if (!this.currentOrderActivity) return;
    switch (this.currentOrderActivity.orderStatusString) {
      case 'Draft':
        if(this.isAutoApprovalEnabled){
          this.footerService.initButtons(FooterViews.ORDER_ACTIVITY_AUTO_APPROVE);
          this.footerService.disableButton(['scraporderactivity', 'autoapproveorderactivity']);
        }else{
          this.footerService.initButtons(FooterViews.ORDER_ACTIVITY_OPEN);
          this.footerService.disableButton(['scraporderactivity', 'sendorderactivityforreview']);
        }
        break;
      case 'For Review':
        if (!this.isViewFromTeamOrder) {
          this.footerService.initButtons(FooterViews.ORDER_ACTIVITY_FOR_REVIEW);
          this.footerService.disableButton(['cancelorderactivityreview']);
          //this.isCancelReviewEnabled = true;
        } else {
          this.footerService.initButtons(FooterViews.ORDER_ACTIVITY_FOR_REVIEW_TEAM_ORDERS);
          this.footerService.disableButton(['notapproveorderactivity', 'approveorderactivity']);
        }
        break;
      case 'Approved':
        if (!this.isViewFromTeamOrder) {
          this.footerService.initButtons(FooterViews.ORDER_ACTIVITY_APPROVED);
          this.footerService.disableButton(['fulfillorderactivity', 'reopenorderactivity']);
        } else {
          this.footerService.initButtons(FooterViews.Contacts);
        }
        break;
      case 'Fulfilled':
      case 'Rejected':
        this.footerService.initButtons(FooterViews.Contacts);
        this.isReadOnlyMode = true;
        break;
      default:
        break;
    }
  }

  private updateFooterViewButtons(): boolean {
    if (!this.currentOrderActivity) return;
    switch (this.currentOrderActivity.orderStatusString) {
      case 'Draft':
        if (this.isViewFromTeamOrder) {
          this.hardDisableFooterButtonsCheck = true;
          this.onClosePage();
          return;
        }
        this.footerService.disableButton(['scraporderactivity', 'sendorderactivityforreview','autoapproveorderactivity']);
        this.isSendForApprovalEnabled = false;
        if (this.currentOrderActivity.ownerId === this.authService.user.systemUserID && !this.activityService.teamViewActive && !this.backgroundUploadInProgress) {
          this.footerService.enableButtons(['scraporderactivity']);
          this.isScrapEnabled = true;
          let defaultChecks: boolean = this.currentOrderActivity.accountId && !this.device.isOffline && !_.isEmpty(this.currentOrderActivity.products) && this.currentOrderActivity.total > 0  && this.isSurveyValid;
          if (!this.currentOrderActivity.manualOrder) defaultChecks = defaultChecks && !_.isEmpty(this.currentOrderActivity.countryId);
          if (defaultChecks) {
            if(this.isAutoApprovalEnabled){
              this.footerService.enableButtons(['autoapproveorderactivity']);
            }else{
              this.footerService.enableButtons(['sendorderactivityforreview']);
            }
            this.isSendForApprovalEnabled = true;
          }
          this.isReadOnlyMode = this.orderManagementService.isSelectionPane;
          this.isAddProductEnabled = (this.currentOrderActivity.countryId && this.currentOrderActivity.accountId) ? true : false;
        }
        break;
      case 'For Review':
        if (!this.isViewFromTeamOrder) {
          this.isReadOnlyMode = true;
          this.isAddProductEnabled = false;
          this.isCancelReviewEnabled = false;
          if (!this.activityService.teamViewActive && !this.device.isOffline && !this.backgroundUploadInProgress) {
            this.isCancelReviewEnabled = true;
            this.footerService.enableButtons(['cancelorderactivityreview']);
          }
        } else {
          this.footerService.disableButton(['notapproveorderactivity', 'approveorderactivity']);
          this.isMarkOrderNotApprovedEnabled = false;
          this.isMarkOrderApprovedEnabled = false;
           if (!this.device.isOffline && !this.activityService.teamViewActive && !this.backgroundUploadInProgress) {
            this.footerService.enableButtons(['notapproveorderactivity', 'approveorderactivity']);
            this.isMarkOrderNotApprovedEnabled = true;
            this.isMarkOrderApprovedEnabled = true;
          }
        }
        break;
      case 'Approved':
        if (!this.isViewFromTeamOrder) {
          this.isAddProductEnabled = false;
          this.footerService.disableButton(['fulfillorderactivity', 'reopenorderactivity']);
          this.isFulfillEnabled = false;
          this.isReopenEnabled = false;
          if (this.currentOrderActivity.ownerId === this.authService.user.systemUserID && !this.activityService.teamViewActive && !this.device.isOffline && !this.backgroundUploadInProgress) {
            this.footerService.enableButtons(['fulfillorderactivity', 'reopenorderactivity']);
            this.isFulfillEnabled = true;
            this.isReopenEnabled = true;
            this.isReadOnlyMode = true;
          }
        }
        break;
      case 'Fulfilled':
      case 'Canceled':
      case 'Rejected':
        this.isReadOnlyMode = true;
        this.isAddProductEnabled = false;
        break;
      default:
        break;
    }
    this.getProductHeader();
  }

  public get isBackButtonEnabled(): boolean {
    if (this.isLayoverPushView) {
      if (this.layoverViewPushedFrom === 'OrderManagementTool' && !this.isSplitView()) {
        return true;
      } else if (this.layoverViewPushedFrom === 'AccountTool') {
        return true;
      } else if (this.layoverViewPushedFrom === 'AgendaView' && window.innerWidth < 620) {
        return true;
      } else if (this.layoverViewPushedFrom === 'AccountTimeline') {
        return true;
      }
      else if (this.layoverViewPushedFrom === 'OpportunityManagementTool'
              || this.layoverViewPushedFrom === 'AccountProfileOpportunities'
              || this.layoverViewPushedFrom === 'AccountPlanOpportunities'
              || this.layoverViewPushedFrom === 'MarketingPlanOpportunities') {
        return true;
      } else {
        return false;
      }
    } else {
      //Handle if used
    }
  }

  public handleButtonClick(id: string): void {
    this.handleButtonEventForOrderActivity(id);
  }

  public async openCountrySelect() {
    if (this.isReadOnlyMode) return;
    const countriesList = this.sharedDataService.allCountriesList;
    const isConfirmationRequired: boolean = (this.currentOrderActivity.products && this.currentOrderActivity.products.filter(p=>!p.isDeleted).length > 0);
    let confirmationData;
    if (isConfirmationRequired) {
      confirmationData = new ConfirmationAlertData({
        message: this.translate.instant('OM_CHANGING_SELECTION_WILL_CLEAR_PRODUTCS'),
        title: this.translate.instant('OM_CHANGE_COUNTRY'),
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Yes',
      });
    }
    if (countriesList && countriesList.length > 0) {
      const selectListDetail: SelectListDetail = {
        isMultipleSelectionEnabled: false,
        isSearchEnabled: false,
        title: this.translate.instant('COUNTRIES'),
        dividerTitle: this.translate.instant('OM_ALL_COUNTRIES'),
        confirmationCheckRequired: isConfirmationRequired,
        confirmationAlertData: confirmationData,
        data: countriesList.map(country => {
          return {
            id: country.countryId,
            title: country.countryName,
            isSelected: country.countryId === this.currentOrderActivity.countryId,
          };
        })
      };
      selectListDetail.data = countriesList.map(country => {
        return {
          id: country.countryId,
          title: country.countryName,
          isSelected: country.countryId === this.currentOrderActivity.countryId,
        };
      });
      selectListDetail.isMultipleSelectionEnabled = false;
      selectListDetail.isSearchEnabled = true;
      selectListDetail.title = this.translate.instant('COUNTRIES');
      selectListDetail.dividerTitle = this.translate.instant('OM_ALL_COUNTRIES');
      this.footerService.forceHideMasterFooter = true;
      this._selectCountryModal = await this.modalCtrl.create({component: SelectListComponent, componentProps: { viewData: selectListDetail }, cssClass:'select_country', backdropDismiss: false });
      this._selectCountryModal.present();
      this.navService.isModalViewOpen = true;
      this._selectCountryModal.onDidDismiss().then(async (modaldata: any) => {
        this.footerService.forceHideMasterFooter = false;
        this.isClickedCountry = true;
        const data = modaldata.data;
        if (data && data.isDone) {
          if ((data.selectedItems && data.selectedItems.length === 1 && data.selectedItems[0].id !== this.currentOrderActivity.countryId)
            || ((!data.selectedItems || data.selectedItems.length === 0) && this.currentOrderActivity.countryId)) {
            if (data.selectedItems && data.selectedItems.length === 1) {
              this.currentOrderActivity.priceListId = '';
              this.currentOrderActivity.countryId = data.selectedItems[0].id;
              this.currentOrderActivity.countryNameString = data.selectedItems[0].title;
            } else {
              this.currentOrderActivity.priceListId = '';
              this.currentOrderActivity.currencySymbol = '';
              this.currentOrderActivity.countryId = '';
              this.currentOrderActivity.countryNameString = '';
              this.isAddProductEnabled = false;
            }
            this.currentOrderActivity.total = 0;
            this.currentOrderActivity.formattedTotalAmount = '0.0';
            this.currentOrderActivity.products.forEach((o) => {
              o.isDeleted = true;
            });
            this.currentOrderActivity.pendingPushToDynamics = true;
            //this.uiService.displayLoader();
            let loader = await this.loadingController.create({});
            loader.present();
            await this.orderActivityDataService.updateOrderActivity({ onDynamics: !this.device.isOffline, onLocalCopy: true, onLocalDatabase: true }, [this.currentOrderActivity], new Date().getTime()).then(success => {
              //Successfull Action
              this.initAllDataModelsWitchDetectChange();
              this.updateFooterViewButtons();
            }).catch(error => {
              //Handle error occured during follow up activity updation
            });
           // this.uiService.dismissLoader();
           loader.dismiss();
          }
        } else {
          this.getCountryFormField();
        }

      });
    }
  }
  public openAccountSelect(): void {
    if (!this.isReadOnlyMode && !this.isOrderWithinOpportunityContext) {
      this.accountService.selectedFor = AccountSelectedFor.ORDER_ACTIVITY_SELECTION;
      this.accountService.accessedAccountListFrom = PageName.OrderDetailsComponent;
      this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { listMode: ComponentViewMode.ADDNEW, type: 'PushedAccountSelectionView' }, PageName.OrderDetailsComponent, { animate: false }, null);
    }
  }
  public async openAccountPlanSelect(myEvent) {
    if (this.isReadOnlyMode) return;
    const accountPlans = this.accountManagementService.accountPlans.filter(plan => {
        plan.accountPlanTimeFrame = isPast(parseInt(plan.endDate)) ? 'Past'
          : isFuture(parseInt(plan.startDate)) ? 'Future' : 'Present';
      return (plan.accountId == this.currentOrderActivity.accountId && plan.accountPlanTimeFrame !== 'Past' && plan.status === 'Open');
      });
    if (Array.isArray(accountPlans) && accountPlans.length > 0) {
        const mappedAccountPlans = accountPlans.map(plan => {
          return {
            title: plan.accountPlanName,
            id: plan.ID,
            isSelected: plan.ID === this.currentOrderActivity.accountPlanId
          };
        });
        this.accountPlanPopover = await this.popoverCtrl.create(
          {
            component: PopoverComponent,
            componentProps: { selectListPopupData: mappedAccountPlans, field: 'select-list-popup' },
            cssClass: 'account-plan-select',
            event:myEvent
          });
        this.accountPlanPopover.onDidDismiss().then(async (acntPlanData: any) => {
          const data = acntPlanData.data;
          if (data && data.selectedItem && data.selectedItem.id !== this.currentOrderActivity.accountPlanId) {
            this.currentOrderActivity.accountPlanId = data.selectedItem.id;
            this.currentOrderActivity.accountPlanNameString = data.selectedItem.title;
            this.currentOrderActivity.pendingPushToDynamics = true;
            let loader = await this.loadingController.create({});
            loader.present();
            await this.orderActivityDataService.updateOrderActivity({
              onDynamics: !this.device.isOffline,
              onLocalCopy: true,
              onLocalDatabase: true
            }, [this.currentOrderActivity], new Date().getTime()).then(succ => {
              // Successfull action
            }).catch(async error => {
              // Handle error occured during follow up activity updation
              if (error && error.errorCode) {
                await this.activityDataService.updateActivityDetails(this.currentOrderActivity);
              }
            });
            loader.dismiss();
          }
          this.accountPlanPopover = undefined;
        });
        this.accountPlanPopover.present();
      }
    // }
  }
  public async openOpportunitySelect(myEvent) {
    if (this.isReadOnlyMode || this.isOrderWithinOpportunityContext) return;
    const opportunities = this.opportunityService.opportunities.filter(oppo => {
        return (oppo.accountID === this.currentOrderActivity.accountId && oppo.stateCode === 0);
      });
    if (Array.isArray(opportunities) && opportunities.length > 0) {
        const mappedOpportunities = opportunities.map(oppo => {
          return {
            title: oppo.opportunityName,
            id: oppo.ID,
            isSelected: oppo.ID === this.currentOrderActivity.opportunityId
          };
        });
        this.accountPlanPopover = await this.popoverCtrl.create({component: PopoverComponent,componentProps: {
          selectListPopupData: mappedOpportunities,
          field: 'select-list-popup'
        }, cssClass: 'account-plan-select',
        event });
        this.accountPlanPopover.onDidDismiss().then(async (oppoData: any) => {
          const data = oppoData.data;
          if (data && data.selectedItem && data.selectedItem.id !== this.currentOrderActivity.opportunityId) {
            this.currentOrderActivity.opportunityId = data.selectedItem.id;
            this.currentOrderActivity.opportunityNameString = data.selectedItem.title;
            this.currentOrderActivity.offlineOpportunityId = this.currentOrderActivity.opportunityId.indexOf('offline') > -1 ?
              this.currentOrderActivity.opportunityId : '';
            this.currentOrderActivity.pendingPushToDynamics = true;
            let loader = await this.loadingController.create({});
            loader.present();
            await this.orderActivityDataService.updateOrderActivity({
              onDynamics: !this.device.isOffline,
              onLocalCopy: true,
              onLocalDatabase: true
            }, [this.currentOrderActivity], new Date().getTime()).then(succ => {
              // Successfull action
            }).catch(async error => {
              // Handle error occured during follow up activity updation
              if (error && error.errorCode) {
                await this.activityDataService.updateActivityDetails(this.currentOrderActivity);
              }
            });
            loader.dismiss();
          }
          this.accountPlanPopover = undefined;
        });
        this.accountPlanPopover.present();
      }
    // }
  }

  async editDiscount(event) {
    if (this.isReadOnlyMode) return;
    // if(!this.isEditingDiscount){
    //   this.unsavedDiscount = this.currentOrderActivity.elligibleDiscount
    // }
    // else{
    //   if(action && action=='done'){

    //   }
    // }
    try {
      if (event.value == null || event.value === '' || event.value < 0 || event.value > 100 || (event.value && event.value.includes('.'))) {
        event.value = this.currentOrderActivity.elligibleDiscount;
      }
    } catch (error) { }

    if (parseInt(event.value) !== this.currentOrderActivity.elligibleDiscount) {
      this.currentOrderActivity.elligibleDiscount = parseInt(event.value);
      this.currentOrderActivity.formattedDiscount = parseInt(event.value) + '%';
      this.currentOrderActivity.pendingPushToDynamics = true;
      if (this.currentOrderActivity.products && this.currentOrderActivity.products.length) {
        this.currentOrderActivity.total = this.currentOrderActivity.products.filter(o => !o.isDeleted).map(product => product.total).reduce((a, b) => (a - 0) + (b - 0), 0);
        this.currentOrderActivity.total = parseFloat((this.currentOrderActivity.total * (1 - (this.currentOrderActivity.elligibleDiscount / 100))).toFixed(2));
        this.currentOrderActivity.formattedTotalAmount = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
          ? (this.authService.user.currencysymbol) + this.dateTimeFormatsService.getFormattedNumber(this.currentOrderActivity.total)
          : this.dateTimeFormatsService.getFormattedNumber(this.currentOrderActivity.total) + (this.authService.user.currencysymbol);
      }
      const action: OperationDetail = {
        onDynamics: !this.device.isOffline,
        onLocalDatabase: true,
        onLocalCopy: true,
      };
      this.uiService.displayLoader();
      await this.orderActivityDataService.updateOrderActivity(action, [this.currentOrderActivity], new Date().getTime()).then(succ => {
        //Handle Successful response
      }).catch(error => {
        //Handle error scenarios
      });
      this.uiService.dismissLoader();
    }
    // this.isEditingDiscount = !this.isEditingDiscount
  }

  public async openProductSelect(product?: OrderProductClass) {
    if (this.isAddProductEnabled && !this.backgroundUploadInProgress) {
      await this.checkForMatchingPriceList().then(() => {
        if (this.isViewFromTeamOrder) {
          this.trackingService.tracking('TeamOrderViewProduct', TrackingEventNames.ORDERMANAGEMENT);
        }
        this.navService.pushWithPageTracking(OrderAddQuantityComponent, PageName.OrderAddQuantityComponent,
          {
            from: 'OrderDetailsPage', activity:
              this.currentOrderActivity, selectedProduct: product, matchedPriceList: this.currentMatchedPriceList
          },
          PageName.OrderAddQuantityComponent);
      }).catch((error) => {
        // To do error handler
      });
    }
  }

  public async removeSelectedProduct(product?: OrderProductClass) {
    if (this.isAddProductEnabled && !this.backgroundUploadInProgress) {
      const idx = this.currentOrderActivity.products.findIndex(p => p.productId === product.productId);
      if (idx >= 0) {
        this.currentOrderActivity.products.splice(idx, 1);
        this.currentOrderActivity.total = this.currentOrderActivity.products.map(product => product.total).reduce((a, b) => (a - 0) + (b - 0), 0);
        if (this.currentOrderActivity.elligibleDiscount) {
          this.currentOrderActivity.total = parseFloat((this.currentOrderActivity.total * (1 - (this.currentOrderActivity.elligibleDiscount / 100))).toFixed(2));
        }
        this.currentOrderActivity.formattedTotalAmount = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
          ? this.authService.user.currencysymbol + this.dateTimeFormatsService.getFormattedNumber(this.currentOrderActivity.total)
          : this.dateTimeFormatsService.getFormattedNumber(this.currentOrderActivity.total) + this.authService.user.currencysymbol;
        this.currentOrderActivity.pendingPushToDynamics = true;
        const action: OperationDetail = {
          onDynamics: !this.device.isOffline,
          onLocalDatabase: true,
          onLocalCopy: true,
          operationDetail: {
            code: 'ORDERPRODUCTADD',
            message: this.translate.instant('OM_UPDATE_PRODUCTS_QUANTITIES_FOR_AN_ORDER')
          }
        };
        this.uiService.displayLoader();
        await this.orderActivityDataService.updateOrderActivity(action, [this.currentOrderActivity], new Date().getTime()).then(succ => {
          //Handle Successful response
        }).catch(error => {
          //Handle error scenarios
        });
        this.uiService.dismissLoader();
      }
    }
  }
  private async checkForMatchingPriceList() {
    return new Promise(async (resolve, reject) => {
      let flag = false;
      await this.orderManagementService.findMatchingPriceListForOrder(this.currentOrderActivity).then((priceList: PriceList) => {
        if (priceList && priceList.products && priceList.products.length > 0) {
          this.currentMatchedPriceList = priceList;
          flag = true;
        }
        else flag = false;
      }).catch((error) => {
        if (error) {
          this.notifications.notify(this.translate.instant('OM_NO_AVAILABLE_PRICE'), '', 'top', '');
        }
        if (error && error === 'Select a country to find matching price list') {
          this.isAddProductEnabled = false;
        }
        flag = false;
      });
      if (flag) resolve(true);
      else reject(false);
    });
  }

  public isSplitView(): boolean {
    return (window.innerWidth > 500);
  }

  public onClosePage(): void {
    if (this.isLayoverPushView) {
      if (this.layoverViewPushedFrom === 'OrderManagementTool') {
        if (this.isViewFromTeamOrder) {
          this.trackingService.tracking('TeamOrderInfoBack', TrackingEventNames.ORDERMANAGEMENT);
        }
        this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        this.orderManagementService.isRightPaneNavActive = false;
        this.orderManagementService.selectedOrder = null;
        this.activityService.selectedActivity = null;
      } else if (this.layoverViewPushedFrom === 'AccountTool') {
        this.uiService.activeView = 'accountDetails';
        this.footerService.initButtons(FooterViews.Accounts);
        this.navService.popChildNavPageWithPageTracking();
      } else if (this.layoverViewPushedFrom === 'AgendaView') {
        this.trackingService.tracking('OrderInfoBack', TrackingEventNames.ORDERMANAGEMENT);
        this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        this.uiService.activeView = '';
        this.uiService.showRightPane = false;
      } else if (this.layoverViewPushedFrom === 'AccountTimeline') {
        this.navService.popChildNavPageWithPageTracking();
        this.activityService.selectedActivity = null;
      }
      else if (this.layoverViewPushedFrom === 'OpportunityManagementTool'
                || this.layoverViewPushedFrom === 'AccountProfileOpportunities'
                || this.layoverViewPushedFrom === 'AccountPlanOpportunities'
                || this.layoverViewPushedFrom === 'MarketingPlanOpportunities') {
        this.footerService.initButtons('OpportunityOrders');
        this.navService.popChildNavPageWithPageTracking();
        this.activityService.selectedActivity = null;
      }
    }
    if (this.device.shouldBeMobileView) {
      if (this.agendaFooterService.actSegment === "week") {
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selectedActivity = undefined;
        this.uiService.showRightPane = false;
        this.uiService.activeView = '';
        this.activityService.shouldScrollToPrevSelectedActivity = !this.activityService.didOpenDetailPageFromAgendaPage;
        this.activityService.didOpenDetailPageFromAgendaPage = false;
        // this.events.publish('weekview:RefreshUI');
      }
    }
  }

  private handleButtonEventForOrderActivity(buttonId: string) {
    let popupTitle = '';
    let popupMessage = '';
    let popupAction = '';
    let popupInputs = [];
    switch (buttonId) {
      case 'close':
        this.onClosePage();
        return;
      case 'scraporderactivity': {
        if (!this.isScrapEnabled) return;
        if (this.layoverViewPushedFrom === 'OrderManagementTool') {
          this.trackingService.tracking('OrderManagementScrapOrder', TrackingEventNames.ORDERMANAGEMENT);
        } else if (this.layoverViewPushedFrom === 'AgendaView') {
          this.trackingService.tracking('ScrapOrder', TrackingEventNames.ORDERMANAGEMENT);
        }
        popupTitle = this.translate.instant('OM_SCRAP_ORDER');
        popupMessage = this.translate.instant('R_U_SURE_SCRAP_O');
        popupAction = this.translate.instant('SCRAP');
        break;
      }
      case 'autoapproveorderactivity':
      case 'sendorderactivityforreview': {
        if (!this.isSendForApprovalEnabled) return;
        if (this.authService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT_AUTO_FULFILL)) {
          popupTitle = this.translate.instant('OM_FULFILL_O');
          popupMessage = this.translate.instant('OM_ARE_SURE_FULFILL_O');
          popupAction = this.translate.instant('OM_FULFILL');
          buttonId = 'autofulfillflow';
        } else if (this.isAutoApprovalEnabled) {
          popupTitle = this.translate.instant('OM_SET_O_APPROVED');
          popupMessage = this.translate.instant('OM_ARE_YOU_SURE_O_APPROVED');
          popupAction = this.translate.instant('APPROVE');
          buttonId = 'autoapprovalflow';
        } else {
          if (this.layoverViewPushedFrom === 'OrderManagementTool') {
            this.trackingService.tracking('OrderManagementSubmitOrder', TrackingEventNames.ORDERMANAGEMENT);
          }
          popupTitle = this.translate.instant('SEND_FOR_APPROVAL');
          popupMessage = this.translate.instant('OM_ARE_YOU_SURE_SEND_FOR_APPRO');
          popupAction = this.translate.instant('SEND');
        }
        break;
      }
      case 'cancelorderactivityreview': {
        if (!this.isCancelReviewEnabled) return;
        popupTitle = this.translate.instant('CANCEL_REVIEW');
        popupMessage = this.translate.instant('OM_ARE_YOU_SURE_CANCEL_REVIEW');
        break;
      }
      case 'notapproveorderactivity': {
        if (!this.isMarkOrderNotApprovedEnabled) return;
        popupTitle = this.translate.instant('OM_NOT_APPRO_O');
        popupMessage = this.translate.instant('OM_ARE_YOU_SURE_NOT_APPRO_O');
        popupAction = this.translate.instant('NOT_APPROVE');
        popupInputs = [{type:'text', name:"reason", placeholder:this.translate.instant('ENTER_REASON_FOR_REJECTION')}];
        break;
      }
      case 'approveorderactivity': {
        if (!this.isMarkOrderApprovedEnabled) return;
        popupTitle = this.translate.instant('OM_APPROVE_ORDER');
        popupMessage = this.translate.instant('OM_ARE_YOU_SURE_APPRO_O');
        popupAction = this.translate.instant('APPROVE');
        break;
      }
      case 'fulfillorderactivity': {
        if (!this.isFulfillEnabled) return;
        popupTitle = this.translate.instant('OM_FULFILL_O');
        popupMessage = this.translate.instant('OM_ARE_SURE_FULFILL_O');
        popupAction = this.translate.instant('OM_FULFILL');
        break;
      }
      case 'reopenorderactivity': {
        if (!this.isReopenEnabled) return;
        popupTitle = this.translate.instant('OM_RE_OPEN_O');
        popupMessage = this.translate.instant('OM_ARE_YOU_SURE_RE_OPEN_O');
        popupAction = this.translate.instant('TIMEOFF_REOPEN');
        break;
      }
      default:
        return;
    }
    if (this.currentOrderActivity) {
      this.alertService.showAlert({
        title: popupTitle,
        message: popupMessage,
        inputs: popupInputs,
      }, popupAction
      ).then (res => {
        if(res.role === 'ok') {
          if(buttonId == 'notapproveorderactivity'){
            if(res.data && res.data.values.reason){
              this.currentOrderActivity.rejectionReason = res.data.values.reason;
              this.handleButtonEventForOrderActivityAfterConfirmation(buttonId);
            }else{
              this.handleButtonEventForOrderActivity(buttonId);
            }
          }else{
            this.handleButtonEventForOrderActivityAfterConfirmation(buttonId);
          }
        }
      });
    }
  }

  private async handleButtonEventForOrderActivityAfterConfirmation(buttonId: string) {
    const action = {
      onDynamics: !this.device.isOffline,
      onLocalDatabase: !this.isViewFromTeamOrder,
      onLocalCopy: !this.isViewFromTeamOrder,
      operationDetail: {
        code: buttonId,
        message: this.translate.instant('OM_UPDATE_O_ACTIVITY'),
      }
    };
    this.currentOrderActivity.pendingPushToDynamics = true;
    this.uiService.displayLoader();
    await this.orderActivityDataService.updateOrderActivityStatus(action, this.currentOrderActivity).then(async succ => {
      if(this.isViewFromTeamOrder){
        let idx = this.orderManagementService.teamOrder.findIndex(o=> o.ID == this.currentOrderActivity.ID);
        if(idx >= 0){
          this.orderManagementService.teamOrder[idx] = this.currentOrderActivity;
        }
      }
      switch (buttonId) {
        case 'scraporderactivity': {
          //Check from where activity is deleted
          //this.onClosePage();
          //this.hardDisableFooterButtonsCheck = true;
          this.events.publish('updateOrdersList',{ activity : this.currentOrderActivity, mode: 'delete' });
          if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
            // if(this.agendaFooterService.actSegment=== "week"){
            //   this.events.publish('weekview:RefreshUI');
            // }
            // else this.events.publish('refreshAgenda');
            this.events.publish('refreshAgenda');
          }
          else{
            this.uiService.agendaRefreshRequired = true;
          }
          break;
        }
        case 'autoapproveorderactivity':
        case 'sendorderactivityforreview': {
          this.events.publish('updateOrdersList',{ activity : this.activityService.selectedActivity, mode : 'update' });
          break;
        }
        case 'autoapprovalflow': {
          this.events.publish('updateOrdersList',{ activity : this.activityService.selectedActivity, mode : 'update' });
          break;
        }
        case 'cancelorderactivityreview': {
          this.events.publish('updateOrdersList',{ activity : this.activityService.selectedActivity, mode : 'update' });
          break;
        }
        case 'notapproveorderactivity': {
          this.events.publish('updateOrdersList',{ activity : this.activityService.selectedActivity, mode : 'update' });
          this.trackingService.tracking('TeamOrderNotApprove', TrackingEventNames.ORDERMANAGEMENT);
          break;
        }
        case 'approveorderactivity': {
          this.events.publish('updateOrdersList',{ activity : this.activityService.selectedActivity, mode : 'update' });
          this.trackingService.tracking('TeamOrderApprove', TrackingEventNames.ORDERMANAGEMENT);
          break;
        }
        case 'autofulfillflow':
        case 'fulfillorderactivity': {
          this.events.publish('updateOrdersList',{ activity : this.activityService.selectedActivity, mode : 'update' });
          //update opportunity actual revenue if order is connected to an opportunity
          if (this.currentOrderActivity.opportunityId) {
            const opportunity = this.opportunityService.getOpportunityDetailsbyId(this.currentOrderActivity.opportunityId);
            if (opportunity && opportunity.transactionCurrencySymbol === this.authService.user.currencysymbol) {
              opportunity.closedValue += this.currentOrderActivity.total;
              opportunity.closedValueFormatted = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
                ? opportunity.transactionCurrencySymbol + this.dateTimeFormatsService.getFormattedNumber(opportunity.closedValue)
                : this.dateTimeFormatsService.getFormattedNumber(opportunity.closedValue) + opportunity.transactionCurrencySymbol;
              this.opportunityService.addUpdateOpportuntiy(opportunity).then(success => {
                this.opportunityManagementDataService.isSelectedOpportunityUpdated = true;
              }).catch(err => {
              });
            }
          }
          break;
        }
        case 'reopenorderactivity': {
          this.events.publish('updateOrdersList',{ activity : this.activityService.selectedActivity, mode : 'update' });
          break;
        }
        default:
          break;
      }

    }).catch(async err => {
      //Handle error scenario
      if (err && err.errorCode && err.errorCode === 'ONLINEONLYUPDATEFAILED') {
        this.notifications.notify(this.translate.instant('OM_WAS_A_PROBLEM_CONTACT_ADMINISTRATOR'), 'Order Details', 'top', ToastStyle.DANGER);
        if (err.operationCode && (err.operationCode === 'approveorderactivity' || err.operationCode === 'notapproveorderactivity' || err.operationCode === 'sendorderactivityforreview' || err.operationCode === 'autoapproveorderactivity' || err.operationCode === 'cancelorderactivityreview'
          || err.operationCode === 'autoapprovalflow' || err.operationCode === 'fulfillorderactivity' || err.operationCode === 'reopenorderactivity' || err.operationCode === 'autofulfillflow')) {
          // Fetch realtime order details
          if (!this.device.isOffline) {
            if (this.currentOrderActivity.isTeamOrder) {
              let realTimeOrder  = await this.activityDataService.getRealTimeActivityDetails(this.currentOrderActivity);
              let idx = this.orderManagementService.teamOrder.findIndex(o => o.ID == realTimeOrder.ID);
              if (idx >= 0) {
                this.orderManagementService.teamOrder[idx] = (<OrderActivity>realTimeOrder);
              }
            } else {
              await this.activityDataService.updateActivityDetails(this.currentOrderActivity);
            }
          }
        }
      }
    });
    this.uiService.dismissLoader();
  }

  initAllDataModelsWitchDetectChange() {
    this.initAllDatamodels();
    this._CD.detectChanges();
  }

  private initAllDatamodels() {
    this.getPageTitle();
    this.getAccountFormField();
    this.getAccountPlanFormField();
    this.getCountryFormField();
    this.getCreatedByFormField();
    this.getDiscountFormField();
    this.getDetailsHeader();
    this.getOpportunityFormField();
    this.getOrderNumberFormField();
    this.getProductHeader();
    this.getStatusFormField();
    this.getTotalFormField();
    this.getProductsFormField();
    this.getProductsDataModels();
  }

  public getPageTitle(){
    const buttons = [];
      buttons.push({
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        isVisible: this.isBackButtonEnabled,
        align: 'left'
      },
      {
        id: 'scraporderactivity',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('SCRAP'),
        isDisabled: this.backgroundUploadInProgress ? this.backgroundUploadInProgress : !this.isScrapEnabled,
        isVisible: this.currentOrderActivity.orderStatusString === 'Draft',
        align: 'right'
      },
      {
        id: 'sendorderactivityforreview',
        imgSrc: this.isAutoApprovalEnabled ? 'assets/imgs/header_save.svg' : 'assets/imgs/header_send.svg',
        name: this.translate.instant(((this.isAutoApprovalEnabled) ? 'SAVE' : 'SEND')),
        isDisabled: this.backgroundUploadInProgress ? this.backgroundUploadInProgress : !this.isSendForApprovalEnabled,
        isVisible: this.currentOrderActivity.orderStatusString === 'Draft',
        align: 'right'
      },
      {
        id: 'notapproveorderactivity',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('NOT_APPROVE'),
        isDisabled: this.backgroundUploadInProgress ? this.backgroundUploadInProgress : !this.isMarkOrderNotApprovedEnabled,
        isVisible: this.currentOrderActivity.orderStatusString === 'For Review' && this.isViewFromTeamOrder,
        align: 'right'
      },
      {
        id: 'approveorderactivity',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('APPROVE'),
        isDisabled: this.backgroundUploadInProgress ? this.backgroundUploadInProgress : !this.isMarkOrderApprovedEnabled,
        isVisible: this.currentOrderActivity.orderStatusString === 'For Review' && this.isViewFromTeamOrder,
        align: 'right'
      },
      {
        id: 'cancelorderactivityreview',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('CANCEL_REVIEW'),
        isDisabled: this.backgroundUploadInProgress ? this.backgroundUploadInProgress : !this.isCancelReviewEnabled,
        isVisible: this.currentOrderActivity.orderStatusString === 'For Review' && !this.isViewFromTeamOrder,
        align: 'right'
      },
      {
        id: 'reopenorderactivity',
        imgSrc: 'assets/imgs/header_reopen.svg',
        name: this.translate.instant('OM_RE_OPEN'),
        isDisabled: this.backgroundUploadInProgress ? this.backgroundUploadInProgress : !this.isReopenEnabled,
        isVisible: this.currentOrderActivity.orderStatusString === 'Approved' && !this.isViewFromTeamOrder,
        align: 'right'
      },
      {
        id: 'fulfillorderactivity',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('OM_FULFILL'),
        isDisabled: this.backgroundUploadInProgress ? this.backgroundUploadInProgress : !this.isFulfillEnabled,
        isVisible: this.currentOrderActivity.orderStatusString === 'Approved' && !this.isViewFromTeamOrder,
        align: 'right'
      });
      this.orderDetailsPanePageTitle = {
        id: 'activities-details-page-title',
        title: this.translate.instant('NEW_ACTIVITY_SALES_ORDER') + ((this.currentOrderActivity)?(' - ' + this.currentOrderActivity.orderNumber):''),
        controls: this.orderManagementService.isSelectionPane ? [] : buttons.filter(o => o.isVisible)
      };
  }

  public getDiscountFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('OM_ELLIGIBLE_DISCOUNT'),
      inputText: ((this.currentOrderActivity.formattedDiscount) ? this.currentOrderActivity.formattedDiscount + '' : '0%'),
      inputType: 'number',
      inputValue: (this.currentOrderActivity.elligibleDiscount) ? this.currentOrderActivity.elligibleDiscount : 0,
      formattingSymbol: '%',
      id: 'discount-field',
      isHidden: this.currentOrderActivity.manualOrder,
      isReadOnly: this.isReadOnlyMode,
      isDisabled: this.isReadOnlyMode,
      placeholderLabel: this.translate.instant('DISCOUNT'),
      showArrow: !this.isReadOnlyMode,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.discountFormField = viewData;
  }

  public getProductsFormField() {
    const currentProducts = this.currentOrderActivity.products.filter(a=> !a.isDeleted);
    this.productsFormField = {
      isHidden: !this.currentOrderActivity.manualOrder,
      label: this.translate.instant('PRODUCTS'),
      inputText: this._currentProductsString,
      customPlaceholderLabel: (this.isReadOnlyMode && _.isEmpty(currentProducts)) ? this.translate.instant('NO_PRODUCTS') : this.translate.instant('SELECT_PRODUCTS'),
      id:'product-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode ,
      showArrow: !this.isReadOnlyMode,
      isRequired: !this.isReadOnlyMode,
      isEmptyRequiredField: this.isClickedProductsSelect && _.isEmpty(this.currentOrderActivity.products),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
  }

  private get _currentProductsString(): string {
    let str = '';
    const currentProducts = this.currentOrderActivity.products.filter(a=> !a.isDeleted);
    if (currentProducts) {
        if (currentProducts.length === 1) {
            str = `${currentProducts[0].productName}`;
        } else if (currentProducts.length >= 2) {
            currentProducts.sort((a, b): number => {
                if (a.productName > b.productName) return 1;
                if (a.productName < b.productName) return -1;
                return 0;
            });
            str = `${currentProducts[0].productName} +${currentProducts.length - 1}`;
        }
    }
    return str;
  }

  public getDetailsHeader() {

    this.detailsHeader = {
      id:'details-header',
      title: this.translate.instant('DETAILS'),
      controls: []
    };
  }
  public getProductHeader() {
    this.productsHeader = {
      id:'products-header',
      title: this.translate.instant('PRODUCTS'),
      isRequired: this.backgroundUploadInProgress ? !this.backgroundUploadInProgress : this.isAddProductEnabled,
      controls: [
        {
          id:'selectProduct',
          text:this.translate.instant('PRODUCTS'),
          isDisabled: this.orderManagementService.isSelectionPane || (this.backgroundUploadInProgress ? this.backgroundUploadInProgress : !this.isAddProductEnabled),
        },
      ]
    };
  }


  private statusString() {

    switch (this.currentOrderActivity.orderStatusString) {
      case 'Canceled':
        return this.translate.instant('CANCELLED');
      case 'Approved':
        return this.translate.instant('APPROVED');
      case 'For Review':
        return this.translate.instant('FOR_REVIEW');
      case 'Fulfilled':
        return this.translate.instant('OM_FULFILLED');
      case 'Draft':
        return this.translate.instant('OM_DRAFT');
      case 'Rejected':
        return this.translate.instant('REJECTED');
    }
  }

  public getStatusFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('STATUS'),
      inputText: this.statusString()+((this.currentOrderActivity.pendingPushToDynamics)?' - Pending Sync':''),
      id:'status-field',
      isReadOnly:true,
      isDisabled:true,
    };
    this.statusFormField =  viewData;
  }

  public getCreatedByFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CREATED_BY'),
      inputText: this.currentOrderActivity.ownerNameString,
      id:'created-by-field',
      isReadOnly:true,
      isDisabled:true,
    };
    this.createdByFormField = viewData;
  }

  public getOrderNumberFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ORDER_NUMBER'),
      inputText: this.currentOrderActivity.orderNumber,
      id:'order-number-field',
      isReadOnly:true,
      isDisabled:true,
    };
    this.orderNumberFormField = viewData;
  }

  public getCountryFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('COUNTRY'),
      inputText: (this.currentOrderActivity.countryNameString)?this.currentOrderActivity.countryNameString:'',
      customPlaceholderLabel: (this.isReadOnlyMode)?this.translate.instant('NO_COUNTRY') : this.translate.instant('SELECT_COUNTRY'),
      id:'country-field',
      isReadOnly:true,
      isDisabled:this.isReadOnlyMode,
      isHidden: this.currentOrderActivity.manualOrder,
      showArrow: !this.isReadOnlyMode,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isRequired: !this.isReadOnlyMode,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedCountry && _.isEmpty(this.currentOrderActivity.countryNameString),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    this.countryFormField = viewData;
  }

  public getAccountFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNT'),
      inputText: (this.currentOrderActivity.accountNameString)?this.currentOrderActivity.accountNameString:'',
      customPlaceholderLabel: (this.isReadOnlyMode)?this.translate.instant('NO_ACCOUNT') : this.translate.instant('SELECT_ACCOUNT'),
      id:'account-field',
      isReadOnly:true,
      isDisabled:this.isReadOnlyMode || this.isOrderWithinOpportunityContext,
      showArrow: !this.isReadOnlyMode && !this.isOrderWithinOpportunityContext,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isRequired: !this.isReadOnlyMode && !this.isOrderWithinOpportunityContext,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedAccount && _.isEmpty(this.currentOrderActivity.accountNameString),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id,event,eventName),
    };
    this.accountFormField = viewData;
  }

  public getAccountPlanFormField() {
    let viewData: IndFormFieldViewDataModel;
    const accountPlans = this.accountManagementService.accountPlans.filter(plan => {
      plan.accountPlanTimeFrame = isPast(parseInt(plan.endDate)) ? 'Past'
        : isFuture(parseInt(plan.startDate)) ? 'Future' : 'Present';
      return (plan.accountId == this.currentOrderActivity.accountId && plan.accountPlanTimeFrame !== 'Past' && plan.status === 'Open');
    });
    const isAccountPlansData: boolean = accountPlans.length > 0;
    viewData = {
      isHidden: !this.isAccountPlanEnabled,
      label: this.translate.instant('ACCOUNT_PLAN'),
      inputText: (this.currentOrderActivity.accountPlanNameString)?this.currentOrderActivity.accountPlanNameString:'',
      customPlaceholderLabel: (this.isReadOnlyMode || !isAccountPlansData)?this.translate.instant('NO_ACCOUNT_PLAN_ASSOCIATED'):this.translate.instant('SELECT_ACCOUNT_PLAN'),
      id:'account-plan-field',
      isReadOnly:true,
      isDisabled:this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode && isAccountPlansData,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => {
        if(isAccountPlansData){
          this.handleFormFieldEvent(id, event, eventName);
        }
      }
    };
    this.accountPlanFormField = viewData;
  }

  public getOpportunityFormField() {
    let viewData: IndFormFieldViewDataModel;
    const opportunities = this.opportunityService.opportunities.filter(oppo => this.currentOrderActivity.accountId && oppo.accountID === this.currentOrderActivity.accountId && oppo.stateCode === 0);
    const isOpportunitiesData: boolean = opportunities.length > 0;
    viewData = {
      isHidden: !this.isOpportunityEnabled,
      label: this.translate.instant('OPPORTUNITY'),
      inputText: (this.currentOrderActivity.opportunityNameString)?this.currentOrderActivity.opportunityNameString:'',
      placeholderLabel: (this.isReadOnlyMode || !isOpportunitiesData)?this.translate.instant('FOLLOW_UP_NO_OPPORTUNITY'):this.translate.instant('OM_SELECT_OPPORTUNITY'),
      skipPlaceholderTranslation: true,
      id:'opportunity-field',
      isReadOnly:true,
      isDisabled:(this.isReadOnlyMode || this.isOrderWithinOpportunityContext),
      showArrow: (!this.isReadOnlyMode && isOpportunitiesData && !this.isOrderWithinOpportunityContext),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => {
        if(isOpportunitiesData){
          this.handleFormFieldEvent(id, event, eventName);
        }
      }
    };
    this.opportunityFormField = viewData;
  }

  public getTotalFormField() {
    let viewData: IndFormFieldViewDataModel;
    if (this.currentOrderActivity.total == null) this.currentOrderActivity.formattedTotalAmount = null;
    else  this.currentOrderActivity.formattedTotalAmount = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
          ? this.authService.user.currencysymbol + this.dateTimeFormatsService.getFormattedNumber(this.currentOrderActivity.total)
          : this.dateTimeFormatsService.getFormattedNumber(this.currentOrderActivity.total) + this.authService.user.currencysymbol;
    viewData = {
      label: this.translate.instant('TOTAL'),
      inputText: this.currentOrderActivity.formattedTotalAmount ? this.dateTimeFormatsService.formattedCurrency(this.currentOrderActivity.formattedTotalAmount) : '0',
      inputValue: this.currentOrderActivity.total,
      id:'total-field',
      isReadOnly:true,
      isDisabled:true,
    };
    if (!this.isReadOnlyMode && this.currentOrderActivity.manualOrder) {
      viewData.isReadOnly = false;
      viewData.isDisabled = false;
      viewData.placeholderLabel = this.translate.instant('TOTAL');
      viewData.isRequired = true;
      viewData.errorMessage = this.activityService.getErrorMessageRequiredField();
      viewData.isEmptyRequiredField = this.isClickedTotal && this.currentOrderActivity.total <= 0;
      viewData.showArrow = true;
      viewData.formFieldType = FormFieldType.INLINE_INPUT;
      viewData.eventHandler = (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName);
    }
    this.totalFormField = viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      if(this.backgroundUploadInProgress) return;
      switch (id) {
        case 'discount-field':
          if (eventName === 'input_value_confirm') {
            this.editDiscount(event.target);
          }
          break;
        case 'total-field':
          if (eventName === 'input_value_confirm') {
            this.handleTotalField(event.target);
          }
          break;
        case 'country-field':
          this.openCountrySelect();
          break;
        case 'account-field':
          this.openAccountSelect();
          this.isClickedAccount = true;
          break;
        case 'account-plan-field':
          this.openAccountPlanSelect(event);
          break;
        case 'opportunity-field':
          this.openOpportunitySelect(event);
          break;
        case 'product-field':
          this.openProductSelectForManualOrder();
          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
  }

  private openProductSelectForManualOrder(): void {
    if (!this.isReadOnlyMode) {
      this.isClickedProductsSelect = true;
      let data = [];
      const brands = _.cloneDeep(_.sortBy(this.products, [function (o) { return o.name; }]));
      brands.forEach(prod => {
        let isSelected = false;
        if (this.currentOrderActivity.products
          && this.currentOrderActivity.products.some(sopro => (sopro.productId === prod.ID))) {
          isSelected = true;
        }
        data.push({
          id: prod.ID,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: !this.isReadOnlyMode,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: prod.name,
          showArrow: false,
          arrowType: '',
          eventOwnerId: this.currentOrderActivity.ownerId
        })
      })
      const listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('PRODUCTS'),
        dividerTitle: this.translate.instant('ALL_PRODUCTS_CAP'),
        isSearchEnabled: !this.isReadOnlyMode,
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: !this.isReadOnlyMode,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        orderByPropertyName: 'primaryTextRight',
        searchTitle: this.translate.instant('SEARCH_PRODUCTS'),
        hideAllItemsList: this.isReadOnlyMode,
        isListSelectionEnabled: !this.isReadOnlyMode,
        listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProductComponentEvent(data, eventTarget, refData, 'product'),
        searchHandler: (text: string) => this._handleProductsComponentSearch(text, 'product'),
        data: data
      };
      if (this.isReadOnlyMode) {
        this.currentOrderActivity.products.forEach(pro => {
          const idx = listDetail.data.findIndex(it => it.id === pro.productId);
          if (idx < 0) {
            const viewData: MainCardViewDataModel = {
              id: pro.productId,
              primaryTextLeft: '',
              secondaryTextLeft: '',
              showEndIcon: !this.isReadOnlyMode,
              mainItemCssClass: 'selector-item',
              isItemSelectedForSelectionView: true,
              endIconType: 'indegene-selectors-checkmark-icon',
              endIconCssClass: 'checkmark-icon',
              primaryTextRight: pro.productName,
              showArrow: false,
              arrowType: '',
            };
            listDetail.data.push(viewData);
          }
        });
      }
      this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isGroupedView: false }, PageName.MainToolTemplateComponent);
    }
  }

  private _handleProductsComponentSearch(text: string, formField: string): string[] {
    return this.products.filter(product => {
      return product.name.trim().toLowerCase().includes(text.trim().toLowerCase());
    }).map(prod => prod.ID);
  }

  private async _handleProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail, fieldName: string) {
    if (!this.isReadOnlyMode && eventTarget && eventTarget === 'RightHeaderButtonClick' && data.isDone) {
      let alreadySelectedProducts = this.currentOrderActivity.products;
      this.currentOrderActivity.products = [];
      if (!_.isEmpty(data.selectedItems)) {
        data.selectedItems.forEach(item => {
          let alreadySelectedProd = alreadySelectedProducts.find(p => p.productId == item.id);
          if (alreadySelectedProd) {
            this.currentOrderActivity.products.push(alreadySelectedProd);
          }
          else {
            const foundProduct = this.products.find(p => p.ID == item['id']);
            if (foundProduct) {
              this.currentOrderActivity.products.push({
                productId: foundProduct.ID,
                productName: foundProduct.name,
                quantity: 1,
                uomid: foundProduct.defaultuomid,
                isDeleted: false,
                unitPrice: 0,
                formattedUnitPrice: "0$",
                total: 1,
                formattedTotal: "1",
              });
            }
          }
        });
      }
      this.currentOrderActivity.pendingPushToDynamics = true;
      const action: OperationDetail = {
        onDynamics:!this.device.isOffline,
        onLocalDatabase:true,
        onLocalCopy:true,
        operationDetail:{
          code:'ORDERPRODUCTADD',
          message:this.translate.instant('OM_UPDATE_PRODUCTS_QUANTITIES_FOR_AN_ORDER')
        }
      };
      this.uiService.displayLoader();
      await this.orderActivityDataService.updateOrderActivity(action,[this.currentOrderActivity],new Date().getTime()).then(succ=>{
        //Handle Successful response
      }).catch(error=>{
        //Handle error scenarios
      });
      this.uiService.dismissLoader();
    } else {
      this.getProductsFormField();
    }
  }

  private async handleTotalField(data) {
    this.isClickedTotal = true;
    if (data.value !== undefined && this.currentOrderActivity?.total != data.value) {
      this.currentOrderActivity.total = isNaN(parseInt(data.value)) ? null : parseInt(data.value);
      const action: OperationDetail = {
        onDynamics: !this.device.isOffline,
        onLocalDatabase: true,
        onLocalCopy: true,
      };
      this.uiService.displayLoader();
      await this.orderActivityDataService.updateOrderActivity(action, [this.currentOrderActivity], new Date().getTime()).then(succ => {
        //Handle Successful response
      }).catch(error => {
        //Handle error scenarios
      });
      this.uiService.dismissLoader();
    } else {
      this.getTotalFormField();
    }
  }

  public getProductsDataModels() {
    this.allProductsMainCardModel = this.currentOrderActivity.products.filter(prod=>!prod.isDeleted).map((f) => {
      let viewModel: MainCardViewDataModel = {
          id: f.productId,
          fixedHeight: true,
          primaryTextRight: f.productName,
          primaryTextLeft: this.dateTimeFormatsService.formattedCurrency(f.formattedTotal) + " "+ this.translate.instant('FOR')+ " "+ f.quantity + " " + this.translate.instant('OM_UNITS'),
          showEndIcon: !this.orderManagementService.isSelectionPane && (this.backgroundUploadInProgress ? !this.backgroundUploadInProgress : this.isAddProductEnabled),
          endIconType: 'indegene-selectors-remove-icon',
          endIconCssClass:'remove-icon',
          mainItemCssClass: 'selector-item',
          
          clickHandler: (id: string, event, target) => this.handleProductClick(id, event, target)
      }
      return viewModel;
    });
  }

  public handleProductClick(id, event, target){
    if(this.orderManagementService.isSelectionPane) return;
    let product = this.currentOrderActivity.products.find(o=>o.productId===id);
    if(target && target == 'mainItem'){
      this.openProductSelect(product);
    }
    if(target && target == 'endIcon'){
      this.removeSelectedProduct(product);
    }
  }

  private async _updateSalesOrderActivity(operationDetailCode:string){

    let action: OperationDetail = {
      onDynamics: !this.device.isOffline,
      onLocalDatabase: true,
      onLocalCopy: true,
      operationDetail: {
        code: operationDetailCode,
        message: this.translate.instant('DELTA_SYNC_ORDERS_UPDATE')
      }
    };
    const newLastUpdatedTime = new Date().getTime();
    this.currentOrderActivity.pendingPushToDynamics = true;
    if(!this.device.isOffline){
      this.uiService.displayLoader();
    }
    await this.orderActivityDataService.updateOrderActivity(action,[this.currentOrderActivity],newLastUpdatedTime).then((succ)=>{
      this.getStatusFormField();
    }).catch((err)=>{
      this.getStatusFormField();
    })
    if(operationDetailCode == 'updateSurveyResponse'){
      this._surveyTemplateLoaded = false;
      this.initSurveyData();
    }
    if(!this.device.isOffline){
      this.uiService.dismissLoader();
    }
  }

  private async _initSalesOrderSurveyJsAttributes(hardFetch:boolean = false) {
    if (this.currentOrderActivity && !this.device.isOffline && (!this._surveyJsAttributesFetched || hardFetch)) {
        const foundTemplate = this.surveyService.surveyForms.find(a => a.templateType == MobileAppTemplateType.SalesOrderType);
        if (foundTemplate) {
          this.uiService.displayLoader();
          await this.orderActivityDataService.fetchSurveyJSAtrributesForSalesOrder(this.currentOrderActivity, foundTemplate).then(() => {
            this.uiService.dismissLoader();
          });
          this._surveyJsAttributesFetched = true;
        }
    }
    this.initSurveyData();
  }

  private initSurveyData() {
    if (this.currentOrderActivity && this.surveyService.surveyForms.some(a=> a.templateType == MobileAppTemplateType.SalesOrderType)) {
      this.loadSurveyResults();
      this.loadSurveyTemplate();
      this.isSurveyConfigured = true;
      this.surveyService.surveyActiveFor = 'SalesOrder';
      this.salesOrderSurveyHeaderModel = {
        id: 'survey-detail-header',
        title: this.translate.instant('ADDITIONAL_INFORMATION'),
        controls: []
      }
    }
  }

  private loadSurveyResults() {
    if (this.currentOrderActivity.surveyResponseData) {
      if (!_.isEqual(this.currentOrderActivity.surveyResponseData, this.surveyService.surveyStaticResults)) {
        this.surveyService.surveyStaticResults = this.currentOrderActivity.surveyResponseData;
      }
    } else {
      this.surveyService.surveyStaticResults = '';
    }
    if (this.currentOrderActivity.surveyResponseLookupData && this.currentOrderActivity.surveyResponseLookupData.length > 0) {
      if (!_.isEqual(this.currentOrderActivity.surveyResponseLookupData, this.surveyLookupResults)) {
        this.surveyService.surveyLookupResults = this.surveyLookupResults = this.currentOrderActivity.surveyResponseLookupData;
      }
    } else {
      if (this.surveyLookupResults.length != 0) {
        this.surveyService.surveyLookupResults = this.surveyLookupResults = [];
      }
    }
  }

  private loadSurveyTemplate() {
    if(!this._surveyTemplateLoaded){
    let form = this.surveyService.surveyForms.find(a => a.templateType == MobileAppTemplateType.SalesOrderType);
    if (form) {
      this.surveyTemplate = _.cloneDeep(form.surveyConfiguration);
      try {
        if (this.isReadOnlyMode) {
          const element = this.surveyService.markSurveyElementsReadonly(this.surveyTemplate.pages[0].elements);
          // this.surveyTemplate.pages[0].elements.forEach(ele => {
          //   ele.readOnly = true;
          // });
        }
      } catch (error) {
        console.log('Error while marking fields readonly' + error);
      }
    }else{

    }
    this.events.publish('loadSurveyTemplate', this.surveyTemplate);
    this._surveyTemplateLoaded = true;
    }
    
    if (this.surveyTemplate) this.validateSurveyForReview(this.surveyService.surveyStaticResults);
  }

  private validateSurveyForReview(surveyData) {
    this.isSurveyValid = true;
    this.surveyTemplate.pages[0].elements.forEach((question) => {
      if (question.isRequired) {
        this.isSurveyValid = this.isSurveyValid && surveyData.hasOwnProperty(question.name) ? true : false;
        if(question.type == 'panel' && question.elements && question.elements.length > 0 ){
          this.validateSurveyElementsForReview(question.elements,surveyData);
        }
      }
    })
  }

  private validateSurveyElementsForReview(elements,surveyData){
    if(elements && elements.length > 0){
      for(let i=0;i<elements.length;i++){
        this.isSurveyValid = this.isSurveyValid && surveyData.hasOwnProperty(elements[i].name) ? true : false;
        if(elements[i].type == 'panel' && elements[i].elements && elements[i].elements.length > 0){
          this.validateSurveyElementsForReview(elements[i].elements,surveyData)
        }
      }
    }
  }

  public async updateSurveyResult(surveyResponse) {
    this.currentOrderActivity.surveyResponse = surveyResponse;
  }

  public async updateSurveyData(surveyData) {
    for (var propt in surveyData) {
      if (this.currentOrderActivity.surveyResponseData && this.currentOrderActivity.surveyResponseData.hasOwnProperty(propt)) {
        if (this.currentOrderActivity.surveyResponseData[propt] != surveyData[propt]) {
          this.updateSurveyQuestion({ name: propt, value: surveyData[propt] });
        }
      } else {
        this.updateSurveyQuestion({ name: propt, value: surveyData[propt] });
      }
    }
    this.surveyService.surveyStaticResults = this.currentOrderActivity.surveyResponseData = surveyData;
    this.validateSurveyForReview(surveyData);
    this.updateFooterViewButtons();
    this.getPageTitle();
    this.initSurveyData();
  }

  public async updateSurveyQuestion(question) {
    try {
      if (question && question.name && this.surveyTemplate && this.surveyTemplate.pages && this.surveyTemplate.pages[0]) {
        const element = this.surveyService.getElementFromSurveyTemplateElements(this.surveyTemplate.pages[0].elements,question);
        if (element && element.type && element.metadata) {
          if (element.type == "lookup" && question.value) {

            let idx;
            if (!this.currentOrderActivity.surveyResponseDTO.hasOwnProperty('lookupfields')) {
              this.currentOrderActivity.surveyResponseDTO['lookupfields'] = [];
            }
            idx = this.currentOrderActivity.surveyResponseDTO['lookupfields'].findIndex(a => a.name == element.metadata.logicalName);
            if (idx >= 0) {
              if (question.value != this.currentOrderActivity.surveyResponseDTO['lookupfields'][idx].id) {
                this.currentOrderActivity.surveyResponseDTO['lookupfields'][idx] = {
                  name: element.metadata.logicalName,
                  entity: element.metadata.target.setName,
                  id: question.value,
                };
                this._updateSalesOrderActivity('updateSurveyResponse');
              }
            } else {
              this.currentOrderActivity.surveyResponseDTO['lookupfields'].push({
                name: element.metadata.logicalName,
                entity: element.metadata.target.setName,
                id: question.value,
              });
              this._updateSalesOrderActivity('updateSurveyResponse');
            }
          } else {
            if (!this.currentOrderActivity.surveyResponseDTO || (question.value != this.currentOrderActivity.surveyResponseDTO[element.metadata.logicalName])) {
              this.currentOrderActivity.surveyResponseDTO[element.metadata.logicalName] = question.value;
              this._updateSalesOrderActivity('updateSurveyResponse')
            }
          }
        }
      }
    } catch (error) {

    }

  }

}
