import { Component, OnInit, ElementRef, Renderer2, AfterViewInit, ViewChildren, QueryList, ChangeDetectionStrategy, NgZone, ChangeDetectorRef, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { DeviceService } from '../../services/device/device.service';
import {  PopoverController, ModalController} from '@ionic/angular';
import { Events } from '@omni/events';
import { BrandOfflineService } from '../../services/brand/brand.service';
import { Brand } from '../../classes/brand/brand.class';
import { ActivityService } from '../../services/activity/activity.service';
import { AppointmentActivity } from '../../classes/activity/appointment.activity.class';
import { MeetingDataService, UpdateMeetingPayload } from '../../data-services/meeting/meeting.data.service';
import { DiskService } from '../../services/disk/disk.service';
import { GlobalErrorHandler } from '../../services/error-handler/error-handler-service';
import { SpeechSDKService } from '../../services/speechskd.service';
import { NavigationService, PageName } from '../../services/navigation/navigation.service';
import { UIService } from '../../services/ui/ui.service';
import { CallNotesAssistantComponent } from '../call-notes-assistant/call-notes-assistant';
import { AuthenticationService } from '../../services/authentication.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { FooterService } from '../../services/footer/footer.service';
import { NotificationService, ToastStyle } from '../../services/notification/notification.service';
import { GeneeNotificationPopoverComponent } from '../genee-notification-popover/genee-notification-popover';
import { TrackService, TrackingEventNames } from '../../services/logging/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import { EmailActivity } from '@omni/classes/activity/email.activity.class';
import { EmailService } from '@omni/services/email-templates/email.service';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { MainToolTemplateComponent } from '../shared/main-tool-template/main-tool-template';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '@omni/models/mainToolTemplateDetail.model';
import _ from 'lodash';
import { EventsService } from '@omni/services/events/events.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BarcodeStatus, BarcodescannerService } from '@omni/services/barcodescanner/barcodescanner.service';
import { OmniAlertComponent } from '../shared/omni-alert/omni-alert.component';
import { AgendaFooterService } from '@omni/services/footer/agenda-footer.service';
import { SecondaryInfoEntityName } from '@omni/classes/sec-info-config/sec-info.class';
import { SecInfoConfigDataService } from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import { MeetingActivityState } from '@omni/classes/activity/activity.class';

/**
 * Generated class for the ProductKeyMessagesComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */

@Component({
  selector: 'product-key-messages',
  templateUrl: 'product-key-messages.html',
  styleUrls: ['product-key-messages.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})


export class ProductKeyMessagesComponent implements OnInit , AfterViewInit, OnDestroy {
  showKeyMessageArrowButtons: boolean;
  public spaceBetweenSlides: number;
  public slidePerView: number | string;
  public spaceBetween: number;
  public currentIndex: number;

  productCheckedFromActivity:string[];
  showReorderIcon:boolean;
  activityProducts: Array<Brand> = [];
  products:Array<Brand> = [];
  tempProducts: Array<Brand> = [];
  private productKeyMessagesSaved:Array<Brand> = [];
  updateProductsFlag:boolean = false;
  brandsReordered:boolean = false;
  public brandIDs:Array<any>= new Array<any>();
  private currentSlider:HTMLIonSlidesElement;
  @ViewChildren("roundInput") roundLabels:QueryList<ElementRef>;
  @ViewChildren('slider') keyMessageSlider:QueryList<any>;
  @Input() isReadOnlyJointMeeting:boolean = false;
  @Input() checkIfExternalChannelSelected:boolean = false;
  @Input() backgroundUploadInProgress: boolean;
  recognizer: any;
  showCallNotesButton: boolean = false;
  hasCallNotesEnabled: boolean = false;
  popover: any
  productsKeyMessagesHeaderModel: IndSectionHeaderViewDataModel;
  contentBrand: Array<Brand> = [];
  brandProductsAfterSort: Brand[];
  defaultProducts: Brand[];
  productsAddedForcovisitor: any[] = [];
  private ngDestroy$ = new Subject<boolean>();
  @Input() isProductRequired: boolean;
  @Output() openKeyMessageSentiment = new EventEmitter();
  private scannedProduct;

  constructor(
    public events:EventsService,
    public device:DeviceService,
    private elRef:ElementRef,
    public activityService: ActivityService,
    private renderer:Renderer2,
    private meetingDataService: MeetingDataService,
    private emailService: EmailService,
    private diskService:DiskService,
    public globalErrorHandler:GlobalErrorHandler,
    private _cd: ChangeDetectorRef,
    private _ngZone: NgZone,
    public speechService: SpeechSDKService,
    public uiService: UIService,
    public navService: NavigationService,
    public authService: AuthenticationService,
    public footerService: FooterService,
    public notificationService: NotificationService,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    public trackingService: TrackService,
    private translate : TranslateService,
    private brandService: BrandOfflineService,
    private barcodescannerService : BarcodescannerService,
    private readonly agendaFooterService: AgendaFooterService,
    private secondaryInfoService: SecInfoConfigDataService,
  ) {
    if(this.device.isMobileDevice){
      this.slidePerView = 2;
      this.spaceBetween = -5;
    }else{
      //web
      this.slidePerView =  4;
      this.spaceBetween = -10;
   }
  }

  private _activitySelectedEventHandler = () => {
    this.tempProducts.map(product => {
      //Remove isSelected & isAutoSelected from each product to reset the ui
      product.isSelected = undefined;
      product.isAutoSelected = undefined;
      product.isGenieSelected = undefined;

      product.keyMessages.map(keyMessage => {
        keyMessage.isAutoSelected = undefined;
        keyMessage.isSelected = undefined;
        keyMessage.isGenieSelected = undefined;
      })
    })

    //CWD-3765, disble the save button when user tap on a new meeting activity
    this.updateProductsFlag = false;
    this.getProductMessageHeader();
  };

  private _activityProductsCreatedEventHandler = (brands:Array<Brand>) => {
    /**
    * this is a reference to master brands with isSelected and isAutoSelected set accordingly
    * based on the meeting activityProduct from dynamics
    */
    this.products = _.cloneDeep(_.sortBy(brands, [function (o) { return o.isSelected || o.isAutoSelected || o.isGenieSelected; }]));
    const savedProducts = _.cloneDeep(brands.filter(o => { return o.isSelected || o.isAutoSelected || o.isGenieSelected }));
    this.defaultProducts = _.cloneDeep(_.sortBy(brands, [function (o) { return o.name; }])).splice(0, 5)
    if (savedProducts.length) {
      this.tempProducts = _.uniqBy(savedProducts.concat(this.defaultProducts), 'ID');
    } else {
      this.tempProducts = this.defaultProducts;
    }
    if ((this.activityService.selectedActivity as AppointmentActivity)?.presentations?.length) {
      let addedContent = (this.activityService.selectedActivity as AppointmentActivity).presentations;
      if (!_.isEmpty(addedContent)) {
        addedContent.forEach(content => {
          if (!_.isEmpty(content.brands)) {
            this.activityService.selectedActivity.products?.forEach(brand => {
              if (content.brands[0]?.name === brand.name) {
                this.contentBrand.push(brand);
              } else if ((content.brands[0] === brand.name)) {
                this.contentBrand.push(brand);
              }
            });
          }
        });
      }
      this.tempProducts = this.tempProducts.concat(this.contentBrand);
      let selectedTemp = this.tempProducts.filter(o => {
        return o.isSelected || o.isAutoSelected || o.isGenieSelected
      });
      if (selectedTemp.length) {
        this.tempProducts = _.uniqBy(selectedTemp.concat(this.tempProducts), 'ID')
      } else {
        this.tempProducts = _.uniqBy(this.tempProducts, 'ID')
      }
    }

    if(this.productsAddedForcovisitor.length && this.isReadOnlyJointMeeting){
      this.productsAddedForcovisitor.forEach(pro =>{
        this.tempProducts.push(new Brand(pro, pro.indskr_sequence, true));
        console.log("this.tempProducts",this.tempProducts)
      })
      this.tempProducts = _.uniqBy( _.sortBy(this.tempProducts, [function(o) { return o.isSelected || o.isAutoSelected || o.isGenieSelected; }]),'ID');
     }


    /** Filter activity products for the completed meeting before loading */
    this.filterCompletedActivityProducts();
    /* */

    this.showCallNotesButton = !this.activityService.selectedActivity.isCompleted && this.activityService.selectedActivity.state != MeetingActivityState.Canceled && this.speechService.CallNotesAssistantDataMappedToMeeting.some(o=>o.activityID==this.activityService.selectedActivity.ID)
                                  && this.hasCallNotesEnabled && !this.enableReadonlyViewForMeetings && !this.backgroundUploadInProgress && !this.activityService.selectedActivity?.isDiffPosition;
    this.getProductMessageHeader();
  };

  ngOnInit(): void {
    this.tempProducts = [];
    this.products = [];
    this.defaultProducts = [];
    this.contentBrand = [];
    this.unsubscribeToEvents();
    this.subscribeToEvents();
    this.initDetails();
    this.getProductMessageHeader();

    this.translate.onLangChange.subscribe(data => {
      this.initDetails();
      this.getProductMessageHeader();
    });

    this.barcodescannerService.scanningInProgress = false;
  }

  private  initDetails = async () => {
    //when an activity is selected let's reset our data set but keep the general structure
   // this._activitySelectedEventHandler();
    this.updateProductsFlag = false;
    this.showCallNotesButton = !this.activityService.selectedActivity.isCompleted && this.activityService.selectedActivity.state != MeetingActivityState.Canceled && this.speechService.CallNotesAssistantDataMappedToMeeting.some(o=>o.activityID==this.activityService.selectedActivity.ID)
                                  && this.authService.hasFeatureAction(FeatureActionsMap.GENIE_CALL_NOTES) && !this.enableReadonlyViewForMeetings && !this.backgroundUploadInProgress && !this.activityService.selectedActivity?.isDiffPosition;;

    this.getProductMessageHeader();

    this.showKeyMessageArrowButtons = !(this.device.isNativeApp);
    this.showReorderIcon = true;
    this.hasCallNotesEnabled = this.authService.hasFeatureAction(FeatureActionsMap.GENIE_CALL_NOTES)
    if (this.activityService.selectedActivity.products /*&& !this.device.isOffline*/) {
      this.products = _.cloneDeep(this.activityService.selectedActivity.products);
      let products = _.sortBy(this.products, [function(o) { return o.isSelected || o.isAutoSelected || o.isGenieSelected}]);
     // const savedProducts = _.takeWhile(this.products, function(o) { return o.isSelected || o.isAutoSelected || o.isGenieSelected; });
      const savedProducts = this.products.filter(o =>{return o.isSelected || o.isAutoSelected || o.isGenieSelected});
      if (savedProducts.length) {
        products = _.sortBy(products, [function (o) { return o.name; }]);
        this.tempProducts = _.uniqBy(savedProducts.concat(products.splice(0, 5), this.contentBrand), 'ID');
      }
      else if(!savedProducts.length && this.contentBrand.length && this.tempProducts.length > 5){
        products = _.sortBy(products, [function (o) { return o.name; }]);
        this.tempProducts = _.uniqBy(this.contentBrand.concat(products.splice(0, 5)), 'ID');
      } else {
        products = _.sortBy(products, [function (o) { return o.name; }]);
        this.defaultProducts = _.cloneDeep(products.splice(0, 5));
        this.tempProducts =  this.defaultProducts;
      }
    } else {
      //we are offline or the selected activity products is not in sync with MSE , and we need to retrieve from disk
      if (this.productKeyMessagesSaved.length !== 0) {
        console.log("Referenced . . .");
        this.products = this.productKeyMessagesSaved;
        this.tempProducts = _.sortBy(this.products, [function(o) { return o.isSelected || o.isAutoSelected || o.isGenieSelected; }]);
        this.tempProducts = _.cloneDeep(this.products.splice(0,5));
      } else {
        this.brandService.deSelectAll();
        let brands: Array<Brand> = _.cloneDeep(this.brandService.brands.filter(b=> !b.productApplicability.length || b.productApplicability.includes('100000000')));
        //no details, make basic array
        let brand = brands.filter(value => {
          return value.isSelected === undefined || !value.isSelected;
        });
        brand.forEach((el, index) => el.priority = brands.length + index + 1);
        //sort the products and return it to UI
        //now sort the products based on the sequence(product priority) , if the sequence is not available default
        brands.sort((item1, item2) => {
          //sort criteria
          if (item1.priority && item2.priority) {
            return item1.priority - item2.priority;
          }
        });
        this.products = this.activityService.selectedActivity.products = brands;
        this.products = _.sortBy(this.products, [function(o) { return o.name; }]);
        this.tempProducts = _.cloneDeep(this.products.slice(0,5));
        this.defaultProducts = this.tempProducts;
      }
    }
    //if content added to the meeting
    if((this.activityService.selectedActivity as AppointmentActivity)?.presentations?.length){
      let addedContent = (this.activityService.selectedActivity as AppointmentActivity).presentations;
      this.contentAdding(addedContent,  this.brandService.brands.filter(b=> !b.productApplicability.length || b.productApplicability.includes('100000000')))
      this.tempProducts = this.tempProducts.concat(this.contentBrand);
      this.tempProducts = _.uniqBy(this.tempProducts, 'ID');
    }

    /** Filter activity products for the completed meeting before loading */
    this.filterCompletedActivityProducts();
    /** */
    this._cd.detectChanges();
  }
  ngOnDestroy() : void {
    this.dismissPopover();
    // this.unsubscribeToEvents();
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    console.log('called ng destroy in product key msgs comp');
  }

  ngOnChanges() {
    this.initDetails();
    this.getProductMessageHeader();
  }

  // tslint:disable-next-line:prefer-function-over-method
  ngAfterViewInit():void{
  }

  subscribeToEvents() {

    this.events.observe('meetingCompleted').pipe(takeUntil(this.ngDestroy$)).subscribe((activity) => {
      this.initDetails();
      this.getProductMessageHeader();
    });
    this.events.observe('meetingReopened').pipe(takeUntil(this.ngDestroy$)).subscribe((activity) => {
      this.initDetails();
      this.getProductMessageHeader();
    });
    this.events.observe('userStateChanged').pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      this.getProductMessageHeader();
    });
    this.events.observe('activities-pane:activitySelected').pipe(takeUntil(this.ngDestroy$)).subscribe(this._activitySelectedEventHandler);
    this.events.observe('user:activity_products_created').pipe(takeUntil(this.ngDestroy$)).subscribe(this._activityProductsCreatedEventHandler);
    this.events.observe('initActivityKeyMessages').pipe(takeUntil(this.ngDestroy$)).subscribe(async () => {
      await this.initDetails();
    });
    this.events.observe('contentIsAdded').pipe(takeUntil(this.ngDestroy$)).subscribe((addedContent) => {
      let brands: Array<Brand> = _.cloneDeep(this.brandService.brands.filter(b=> !b.productApplicability.length || b.productApplicability.includes('100000000')));
      this.contentBrand = [];
      if ((this.activityService.selectedActivity as AppointmentActivity).activityProducts.length) {
        this.contentAdding(addedContent, (this.activityService?.selectedActivity as AppointmentActivity).activityProducts)
      } else {
        this.contentAdding(addedContent, brands)
      }
      this.tempProducts = this.tempProducts.concat(this.contentBrand);
      this.tempProducts = _.uniqBy(this.tempProducts, 'ID');
      if (!this.updateProductsFlag) {
        this.activityService.selectedActivity.products = this.tempProducts;
        this._activityProductsCreatedEventHandler(this.activityService.selectedActivity.products);
      }
      this._cd.detectChanges();
      console.log(this.tempProducts);
    })
    this.events.observe('contentIsRemoved').pipe(takeUntil(this.ngDestroy$)).subscribe((removedContent: any) => {
      let indexOfRemoveContent;
      removedContent?.brands.forEach(brand => {
        indexOfRemoveContent = this.tempProducts.findIndex((pro) => ((pro.name === brand || pro.name === brand.name) && (!pro.isSelected) && (!pro.isAutoSelected) && !(this.isDefaultProduct(pro))));
        if (indexOfRemoveContent > -1) {
          this.tempProducts.splice(indexOfRemoveContent, 1);
        }
      });
    })
    this.events.observe('LUISdetectionFinished').pipe(takeUntil(this.ngDestroy$)).subscribe((data: string) => {
      if (data && data == "fromMeeting") {
        this.initDetails();
        this.showGeneePopover(data);
        this.getProductMessageHeader();
      }
    });

    this.events.observe('savedProductsForCovisitor').pipe(takeUntil(this.ngDestroy$)).subscribe((pro: any[]) => {
      if (this.isReadOnlyJointMeeting) {
        this.productsAddedForcovisitor = pro ? pro.filter(pr => pr['productStructure'] != 3) : [];
      }
    });
  }

 isDefaultProduct(pro){
   if(!this.defaultProducts){
    this.defaultProducts = (_.sortBy(this.brandService.brands.filter(b=> !b.productApplicability.length || b.productApplicability.includes('100000000')), [function(o) { return o.name; }])).splice(0,5)
   }
 return this.defaultProducts.some((defaultPro) => defaultPro.name === pro.name)
 }

 contentAdding(addedContent,brands){
  let addedContentBrandsList = [];
  let addedContentBrandWithName = [];
  // pushing added content brands to the addedContentBrandsList array
   if (!_.isEmpty(addedContent)) {
     addedContent.forEach((content) => {
       if(!_.isEmpty(content.brands))
        addedContentBrandsList.push(...content.brands);
     });
   }
  addedContentBrandsList = _.uniq(addedContentBrandsList);

  // sometime brands will have object with name property
   addedContentBrandsList.filter( addedContentBrand => addedContentBrand.name).forEach(ab => {
     if(ab.name){
      addedContentBrandWithName.push(ab.name);
     }
  });
  addedContentBrandsList = _.uniq([...addedContentBrandWithName, ...addedContentBrandsList]);

  addedContentBrandsList.forEach(contentBrand => {
    this.contentBrand.push(...brands.filter(brand =>
      brand.name === contentBrand
    ));
  });
 }

unsubscribeToEvents() {
  console.log('unsubscribing product key msgs events');
  this.events.unsubscribe('activities-pane:activitySelected');
  //this.events.unsubscribe('activities-pane:activitySelected', this.dismissPopover);
  this.events.unsubscribe('user:activity_products_created');
  this.events.unsubscribe('initActivityKeyMessages');
  this.events.unsubscribe('LUISdetectionFinished');
  // this.events.unsubscribe('userStateChanged');
  // this.events.unsubscribe('meetingCompleted');
  this.events.unsubscribe('contentIsAdded');
  this.events.unsubscribe('contentIsRemoved');
  this.events.unsubscribe('savedProductsForCovisitor');
}

dismissPopover(){
    if(this.popover){
      this.popover.dismiss();
      this.popover = undefined;
    }
}

showGeneePopover(source?: string){
  let data;
    data = {
      heading: this.translate.instant('GENEE_NOTES_ASSISTANT'),
      notificationText: this.translate.instant('GENEE_NOTIFICATION_TEXT'),
      mainButtontext: this.translate.instant('GENEE_SHOW_ME'),
      secondaryButtontext: this.translate.instant('GENEE_MAYBE_LATER')
    }
  if(!this.popover){
    this.showMeetingNotesPopover(data);
  }
  else{
    this.popover = undefined;
    this.showMeetingNotesPopover(data);
  }
}

async showMeetingNotesPopover (data:any) {
  if (!this.popover) {
    this.popover = await this.popoverCtrl.create({component:GeneeNotificationPopoverComponent,componentProps: data,cssClass: 'geneeCallNotes' ,showBackdrop: false})
    this.popover.onDidDismiss().then((data) => {
      if(data && data.data.viewNotes){
        if(this.showCallNotesButton){
          this.goToNotesAssistant();
        }
      }
    });
    this.popover.present();
  }
}

filterCompletedActivityProducts(){
  /** Filter only the completed activity products */
  if((this.tempProducts.length > 0 && this.activityService.selectedActivity.isCompleted && this.activityService.selectedActivity.state == MeetingActivityState.Canceled )){
    //this.enableReadonlyViewForMeetings: Removed Condition to fix OMNI-15417.
    this.tempProducts = this.tempProducts.filter(pdt =>  pdt.isSelected);
  }
}
/**
 *
 *
 * @readonly
 * @type {string}
 * @memberof ProductKeyMessagesComponent
 */
// tslint:disable-next-line:prefer-function-over-method
public assignUniqueIdToKeyMessages(productID:string , keyMessageID:string):string{
    return productID +"-"+ keyMessageID;
  }
  /**
   *
   *
   * @param {*} indexes
   * @memberof ProductKeyMessagesComponent
   */
  reorderData(indexes:any){
    //if(this.activityService.selectedActivity.isCompleted) return;
    let element = this.tempProducts[indexes.from];
    this.tempProducts.splice(indexes.from, 1);
    this.tempProducts.splice(indexes.to, 0, element);
    this.tempProducts.map((obj:any , index:any)=>{
      let tempObj:any = obj;
      if(tempObj.isSelected) {
        tempObj['priority'] = index + 1;
      }
      return tempObj
    });
    this.updateProductsFlag = true;
    indexes.complete();
    if (element.isSelected){
      this.updateProductsFlag = true;
      this.getProductMessageHeader();
    }
    console.log("Reordered" , this.products);
  }

/**
 *
 *
 * @memberof ProductKeyMessagesComponent
 */
slideDown=(event:any , productName:any)=>{
  let sliderQueryList = this.keyMessageSlider.find(slider => slider._elementRef.nativeElement.id === event.target.id);
  sliderQueryList.slidePrev(300 , false);
  }
  /**
   *
   *
   * @memberof ProductKeyMessagesComponent
   */
  slideUp=(event:any , productName:any)=>{
    let sliderQueryList = this.keyMessageSlider.find(slider => (slider._elementRef.nativeElement.id === event.target.id));
    sliderQueryList.slideNext(300 , false);
    this.currentSlider =  sliderQueryList;
  }
  /**
   *
   *
   * @memberof ProductKeyMessagesComponent
   */
  toggleChecked=(ev:MouseEvent , product:any , keyMessage:string)=>{
    //tag the div round by id
    if(!product.isAutoSelected) {
      if(product.isSelected){
        /**
         *  Should we deselect product if use unselects all key message??
         *  Leave it checked for now...
         */
      }else{
        product.isSelected = true;
      }
    }
    //this.events.publish('detectChangesOnActivityDetails');
    this._cd.detectChanges();
  }
  /**
   *
   *
   * @memberof ProductKeyMessagesComponent
   */
  updateProduct=(event:UIEvent , product:any )=>{
    //update the model here too for unchecking and triggering a change detetctor ref
    //If the user is accompanied and viewing a readonly meeting then ignore clicks
    if(product.isAutoSelected || this.activityService.selectedActivity.isCompleted || this.activityService.selectedActivity.state == MeetingActivityState.Canceled || this.isReadOnlyJointMeeting || this.enableReadonlyViewForMeetings || this.backgroundUploadInProgress || this.activityService.selectedActivity?.isDiffPosition) return;
    if(product.isSelected){
      product.isSelected = false;
      product.isAutoSelected = false;

       // Unselect all keymessages if the user unselects the brand
      product.keyMessages.forEach(key => key.isSelected = false);
    } else {
      this.trackingService.tracking('MeetingProductUpdate', TrackingEventNames.ACTIVITY)
      product.isSelected = true;
      product.isAutoSelected = false;
    }
    this.updateProductsFlag = true;
    this.getProductMessageHeader();
    this._cd.detectChanges();
    // this.events.publish('detectChangesOnActivityDetails');
    // this.getProductMessageHeader();
  }
  /**
   * coming soon
   *
   * @memberof ProductKeyMessagesComponent
   */
  async saveProductKeyMessages() {
    //Just an extra check to avoid non authorized data update if HTML faces any manual override
    if (!this.isReadOnlyJointMeeting) {
      if (this.updateProductsFlag) { // this ensures we are not doing the expensive network call to update the meeting DTO object all the time , do it only when needed
        if (this.activityService.selectedActivity instanceof AppointmentActivity || this.activityService.selectedActivity instanceof EmailActivity) {
          const prevProdState = _.cloneDeep(this.activityService.selectedActivity.products);
          this.activityService.selectedActivity.products = this.tempProducts;
          this.tempProducts = _.cloneDeep(this.activityService.selectedActivity.products);
          console.log("Captured state of the product model to MSE ", this.activityService.selectedActivity.products);
          if (this.checkIfExternalChannelSelected) {
            let selectedProducts: Array<Brand> = this.tempProducts.filter(prod => prod.isSelected === true)
            if (selectedProducts.length > 1) {
              this.notificationService.notify(this.translate.instant("MULTIPLE_PRODUCTS"), 'Email Activity Detail', 'top', ToastStyle.DANGER);
              return;
            }
          }

          if (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
            try { // I/O operations needs to be wrap in a try and catch block
              await this.uiService.displayLoader();
              await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity); // offline saving
              this.uiService.dismissLoader();
              if(this.authService.hasFeatureAction(FeatureActionsMap.SCAN_PRODUCTS_MEETING) && this.scannedProduct){
                this.openSuccessModal(this.scannedProduct);
              }
              this.activityService.selectedActivity.products = this.activityService.selectedActivity.products.sort((item1: any, item2: any) => {
                if (item1.priority && item2.priority) {
                  return item1.priority - item2.priority;
                }
              });

              this.initDetails();
            } catch (e) {
              this.globalErrorHandler.handleError(new Error(e)); // will implement a Global Error handler
              this.uiService.dismissLoader();
            }

          } else {
            try {
              //updated to reflect the slide share problems CWD-1414
              if (this.activityService.selectedActivity instanceof AppointmentActivity) {
                this.updateProductsFlag = false;
                await this.uiService.displayLoader();
                this.getProductMessageHeader();
                await this.meetingDataService.updateMeetingProductKeyMessages(this.activityService.selectedActivity);

                 // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
                if(this.secondaryInfoService.isAppointmentSecInfoConfigured) {
                  const subject = this.secondaryInfoService.getSecondaryInfoFormatedTextForAppointment(this.activityService.selectedActivity, SecondaryInfoEntityName.Appointment);
                  this.activityService.selectedActivity.subject = subject;
                  this.syncSubject();
                } else if(this.authService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
                  this.activityService.selectedActivity.subject = [this.translate.instant('MEETING'), this.activityService.selectedActivity.activityTypeName, this.activityService.selectedActivity.meetingOwnerName].filter(Boolean).join(" - ");
                  const subject = this.meetingDataService.getMeetingSubject(this.activityService.selectedActivity);
                  this.activityService.selectedActivity.subject = subject;
                  this.syncSubject();
                }
                this.uiService.dismissLoader();
                // % % % Ability Autoname Subject in Meeting and Procedure Log % % % //
              } else if (this.activityService.selectedActivity instanceof EmailActivity) {
                await this.emailService.updateEmailActivityProductKeyMessages(this.activityService.selectedActivity)
              }
              if(this.authService.hasFeatureAction(FeatureActionsMap.SCAN_PRODUCTS_MEETING) && this.scannedProduct){
                this.openSuccessModal(this.scannedProduct);
              }
              this.activityService.selectedActivity.products = this.activityService.selectedActivity.products.sort((item1: any, item2: any) => {
                if (item1.priority && item2.priority) {
                  return item1.priority - item2.priority;
                }
              })
              this.events.publish('user:activity_products_created', this.activityService.selectedActivity.products);
              this.initDetails();
              //this._activityProductsCreatedEventHandler(this.activityService.selectedActivity.products);
            } catch (e) {
              this.activityService.selectedActivity.products = prevProdState;
              this.events.publish('user:activity_products_created', this.activityService.selectedActivity.products);
              this.uiService.dismissLoader();
              //handled in the patch services!!!
              this.globalErrorHandler.handleError(new Error(e));
            }
          }
        }
        //Removed the toast message
        //this.toast.create({duration: 3000, message:'Product and key messages updated',showCloseButton:true}).present();
      }
      this.updateProductsFlag = false;
      this._cd.detectChanges();
    }
  }

  goToNotesAssistant(){
    this.modalCtrl.create({component: CallNotesAssistantComponent, cssClass: "modal-fullscreen"}).then((modal)=> modal.present());
  }
  /**
   *
   *
   * @memberof ProductKeyMessagesComponent
   */
  public updatedHighlightedKeyMessage=(ev:MouseEvent , product:any , keyMessage:any)=>{
      //dont use the ev.target creates additonal buttons very wierd
      if(keyMessage.isAutoSelected || this.activityService.selectedActivity.isCompleted || this.activityService.selectedActivity.state == MeetingActivityState.Canceled|| this.isReadOnlyJointMeeting || this.enableReadonlyViewForMeetings || this.backgroundUploadInProgress || this.activityService.selectedActivity?.isDiffPosition) return;
      this.trackingService.tracking('MeetingKeyMessageUpdate', TrackingEventNames.ACTIVITY)
      if(!keyMessage.isSelected) {
        keyMessage.isSelected = true;
        keyMessage.isAutoSelected = false;
      } else {
        keyMessage.isSelected = false;
      }
      this.updateProductsFlag = true;
      this.toggleChecked(ev , product , keyMessage.name);

      this.getProductMessageHeader();
  }

/**
 *
 *
 * @param {*} ev
 * @memberof ProductKeyMessagesComponent
 */
public slideEnd(ev){
    //console.log("End " ,  ev , "Current Slider " ,this.currentSlider ? this.currentSlider._elementRef.nativeElement.id : "AHAHAHAHA"); // clean it up and push
    if(this.currentSlider){
      this.currentSlider.slidePrev();
    }
  }
/**
 *
 * This a device specific implementation. On swiping to the end all the key messages disappears on device
 * this is the routine to fix that
 * @param {*} event
 * @memberof ProductKeyMessagesComponent
 */
public setCurrentSlider(event:any){
  if(this.device.isNativeApp || this.device.isMobileDevice){

    this.currentSlider =  this.keyMessageSlider.find((slider)=>{
      return slider._elementRef.nativeElement.id === event._elementRef.nativeElement.id
     });

     //}
    }
  }

  /**
 *
 *
 * @memberof ProductKeyMessagesComponent
 */
  async slideChanged() {
    this.currentSlider.getActiveIndex().then((res)=>{
      this.currentIndex = res
      if(this.currentIndex === 2){ // ultimate hack of all time CWD-1559
        this.currentSlider.slideTo(this.currentIndex - 0.6, 300);
      }
    })
  }

  public getProductMessageHeader() {

    let detailsHeaderControls = [];

    detailsHeaderControls.push({
      id: 'add-product',
      // text: this.translate.instant('ADD_PRODUCTS'),
      text: this.translate.instant('ADD'),
      isDisabled:
      this.activityService.selectedActivity.isCompleted ||
      this.activityService.selectedActivity.state === MeetingActivityState.Canceled ||
      this.isReadOnlyJointMeeting ||
      this.enableReadonlyViewForMeetings || this.brandService?.brands.filter(b=> !b.productApplicability.length || b.productApplicability.includes('100000000')).length <= 5
      || this.backgroundUploadInProgress || this.activityService.selectedActivity?.isDiffPosition,
    })
    detailsHeaderControls.push({
        id: 'productMessage-key-save',
        text: this.translate.instant('SAVE'),
        isDisabled:
          !this.updateProductsFlag ||
          this.activityService.selectedActivity.isCompleted ||
          this.activityService.selectedActivity.state === MeetingActivityState.Canceled||
          this.isReadOnlyJointMeeting ||
          this.enableReadonlyViewForMeetings ||
          this.backgroundUploadInProgress || this.activityService.selectedActivity?.isDiffPosition,
    });

    if (
      this.showCallNotesButton &&
      !this.activityService.selectedActivity.isCompleted &&
      this.activityService.selectedActivity.state != MeetingActivityState.Canceled &&
      !this.enableReadonlyViewForMeetings &&
      !this.backgroundUploadInProgress && !this.activityService.selectedActivity?.isDiffPosition
    ) {
        detailsHeaderControls.push({
        id: 'products-key-message-view',
        text: this.translate.instant('VIEW'),
        isDisabled: this.isReadOnlyJointMeeting || !this.activityService.isMeetingOwner || this.device.isOffline || this.backgroundUploadInProgress || this.activityService.selectedActivity?.isDiffPosition,
        img: 'assets/imgs/ogeenee.svg',
      });
      }

      if(this.authService.hasFeatureAction(FeatureActionsMap.MEETING_KEY_MESSAGE_SENTIMENT)){
        detailsHeaderControls.push({
          id: 'key-message-sentiment',
          text: this.translate.instant('SENTIMENT'),
          isDisabled: this.backgroundUploadInProgress,
          // iconClass: 'sentiment-icon',
          tooltip: this.translate.instant('SENTIMENT'),
        });
      }

    if(this.authService.hasFeatureAction(FeatureActionsMap.SCAN_PRODUCTS_MEETING)){
      detailsHeaderControls.push({
        id: 'scan-barcode',
        isDisabled: this.activityService.selectedActivity.isCompleted ||this.activityService.selectedActivity.state === MeetingActivityState.Canceled || this.isReadOnlyJointMeeting || this.activityService.teamViewActive || this.backgroundUploadInProgress || this.activityService.selectedActivity?.isDiffPosition,
        img: 'assets/imgs/barcode.svg',
      });
    }


    this.productsKeyMessagesHeaderModel = {
      id: 'products-key-messages',
      title: this.translate.instant('PRODUCT_KEY_MESSGES'),
      isRequired: this.isProductRequired,
      controls: detailsHeaderControls,
      adjustWidth : this.authService.hasFeatureAction(FeatureActionsMap.SCAN_PRODUCTS_MEETING)
    };
  }

  addProduct(){
    this.brandProductsAfterSort = _.sortBy(this.brandService?.brands.filter(b=> !b.productApplicability.length || b.productApplicability.includes('100000000')),[function(o) { return o.name; }]);
    if(this.brandProductsAfterSort){
      this.brandProductsAfterSort = this.brandProductsAfterSort.filter((product, index) => { return index > 4}); _.sortBy(this.products, [function(o) { return o.name; }]);
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('PRODUCTS'),
      dividerTitle:this.translate.instant('ALL_PRODUCTS_CAP'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      hideAllItemsList: false,
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail)=> this._handleProductComponentEvent(data,eventTarget,refData),
      searchHandler:(text: string) => this._handleProductsComponentSearch(text),
      data: this.brandProductsAfterSort && this.brandProductsAfterSort.map(pro => {
        let isSelected = false;
        let showEndIcon = true;
        if(this.tempProducts  && this.tempProducts.some(evpro => (evpro.ID === pro.ID))){
          isSelected = true;
        }
        if(this.tempProducts  && this.tempProducts.some(evpro => ((evpro.ID === pro.ID) && evpro.isAutoSelected))){
          showEndIcon = false;
        }
        if(this.contentBrand && this.contentBrand.some(evpro => ((evpro.ID === pro.ID)))){
          showEndIcon = false;
        }
        return {
          id: pro.ID,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: showEndIcon,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSelected,
          endIconType: isSelected?'indegene-selectors-checkmark-icon':'indegene-selectors-add-icon',
          endIconCssClass: isSelected?'checkmark-icon':'add-icon',
          primaryTextRight: pro.name,
          showArrow: false,
          arrowType: '',
          eventOwnerId: this.activityService.selectedActivity.ownerId
        };
      }),
    };
      this.navService.pushWithPageTracking(MainToolTemplateComponent,PageName.NothingSelectedView,{viewData:listDetail, isGroupedView:false},PageName.MainToolTemplateComponent);
    }
  }


  private _handleProductsComponentSearch(text: string): string[] {
    let ids: Array<string> = [];
    if (text.length >= 1) {
        ids = this.brandService.brands && this.brandService.brands.filter(pro => {
            return pro.name.trim().toLowerCase().includes(text.trim().toLowerCase()) && (!pro.productApplicability.length || pro.productApplicability.includes('100000000'));
        }).map(pro => pro.ID);
    } else {
        ids = this.brandService.brands && this.brandService.brands.filter(b=> !b.productApplicability.length || b.productApplicability.includes('100000000')).map(pro => pro.ID);
    }
    return ids;
  }
  private _handleProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
        if (!this.activityService.selectedActivity.isCompleted && this.activityService.selectedActivity.state != MeetingActivityState.Canceled&& data && data.isDone) {
            if (data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems.length >= 0) {
                  // this.tempProducts = [];
                  const foundProduct:Array<Brand> = [];
                  let activityProduct:Array<Brand>  = [];
                  if((this.activityService.selectedActivity as AppointmentActivity).activityProducts.length){
                     activityProduct = (this.activityService.selectedActivity as AppointmentActivity).activityProducts;
                  } else{
                     activityProduct = this.brandService.brands;
                  }
                  data.selectedItems.forEach(item => {
                    activityProduct.forEach(product =>{
                       if(product.ID === item.id){
                          product.isSelected = true;
                          foundProduct.push(product)
                       }
                    });
                    if (foundProduct) {
                      this.tempProducts = _.uniqBy( _.sortBy(this.tempProducts.concat(foundProduct), [function(o) { return o.isSelected || o.isAutoSelected || o.isGenieSelected; }]),'ID');

                    }
                });

              // To remove products
              let index;
              data.removedItems.forEach(removedItem => {
                index = this.tempProducts.findIndex(activePro => {
                  return (activePro.ID === removedItem.id);
                });
                if (index > -1) {
                  this.tempProducts.splice(index, 1);
                }
              });
            }
            this._updateProduct();
        }
    }
}
  _updateProduct(){
    this.updateProductsFlag = true;
    this.saveProductKeyMessages();
  }
  onSectionHeaderControlClick(id) {
    if (id === 'productMessage-key-save') {
      this.saveProductKeyMessages();
    } else if (id === 'products-key-message-view') {
      this.goToNotesAssistant();
    } else if (id === 'add-product'){
      this.addProduct();
    } else if (id === 'key-message-sentiment'){
      this.openKeyMessageSentiment.emit(true);
    }else if(id === 'scan-barcode'){
      this.scanProduct();
    }
  }

  async scanProduct() {
    const barCodeResponse = await this.barcodescannerService.openBarCodeScanner();

    if (!barCodeResponse || barCodeResponse.status !== BarcodeStatus.SUCCESS) {
      if (barCodeResponse.status === BarcodeStatus.ERROR_OCCURED) {
        this.notificationService.notify(this.translate.instant('NOT_ABLE_TO_READ_BARCODE'), 'Procuedre', 'top', ToastStyle.DANGER);
      }
      return;
    }

    this.addProductByBarcode(barCodeResponse.data)
  }

  async addProductByBarcode(productNumber) {
    let options;
    if (this.activityService.selectedActivity instanceof AppointmentActivity) {
      const allProducts = this.brandService.brands;
      let foundProduct = allProducts.find((selectedProduct) => selectedProduct.productnumber === productNumber);

      if (!foundProduct) {
        options = {
          mainImgSrc : 'assets/imgs/round-red-close.svg',
          message : this.translate.instant('PRODUCT_NOT_FOUND'),
          subText: this.translate.instant('PLEASE_CONTACT_SYSTEM_ADMIN'),
        }

        const response = await this.openProductAlert(options);
        if (response && response.data) {
          this.scanProduct();
        }
        return;
      }

      if (allProducts.length) {
        const productSelected = this.tempProducts.find((selectedProduct) => selectedProduct.productnumber === productNumber);
        if (productSelected && productSelected.isSelected) {
          options = {
            mainImgSrc : 'assets/imgs/round-grey-tick.svg',
            message : this.translate.instant('PRODUCT_ADDED_ALREADY'),
            subText: this.translate.instant('PRODUCT_ID') + ` : ${foundProduct.productnumber}`,
            list : [{
              itemAvatarSrc : 'assets/imgs/tools_customer_allocations.svg',
              itemText : `${foundProduct.name}`
            }],
          }
          const response = await this.openProductAlert(options);
          if (response && response.data) {
            this.scanProduct();
          }
          return;
        }

        foundProduct.isSelected = true;
        this.scannedProduct = foundProduct;
        this.tempProducts.push(foundProduct);
        this.tempProducts = _.uniqBy(_.sortBy(this.tempProducts, [
          function (o) { return o.isSelected || o.isAutoSelected || o.isGenieSelected; }
        ]), 'ID');
        this._updateProduct();
      }
    }
  }

  async openSuccessModal(product){
    let options = {
      mainImgSrc : 'assets/imgs/round-blue-tick.svg',
      message : this.translate.instant('PRODUCT_ADDED'),
      subText: this.translate.instant('PRODUCT_ID') + ` : ${product?.productnumber}`,
      list : [{
        itemAvatarSrc : 'assets/imgs/tools_customer_allocations.svg',
        itemText : `${product?.name}`
      }],
    }
    const response = await this.openProductAlert(options);
    if (response && response.data) {
      this.scanProduct();
      return;
    }
  }

  async openProductAlert(options:any) {
    const modal = await this.modalCtrl.create({
      component: OmniAlertComponent,
      cssClass: 'omni-alert-modal',
      componentProps: {
        inputOptions: {
          ...options,
          cancelBtnText : this.translate.instant('CLOSE'),
          confirmBtnText : this.translate.instant('SCAN_BARCODE')
        }
      }
    });
    await modal.present();

    return await modal.onDidDismiss().then((data) => {
      this.scannedProduct = null;
      return data;
    });

  }
  syncSubject(){
    let payload = new UpdateMeetingPayload(
      this.activityService.selectedActivity.subject,
      this.activityService.selectedActivity.location,
      this.activityService.selectedActivity.scheduledStart,
      this.activityService.selectedActivity.scheduledEnd
    );
     this.meetingDataService.updateMeeting(this.activityService.selectedActivity as AppointmentActivity, payload, true);

  }
  // Only introduced to handle specific sccenario of making appointment editable in calendar view
  public get enableReadonlyViewForMeetings():boolean {
    let flag = this.activityService.teamViewActive;
    if(this.activityService.isMyUserSelectedInTeamView && this.agendaFooterService.actSegment == 'week' && this.activityService.selectedActivity && this.activityService.selectedActivity instanceof AppointmentActivity && this.activityService.activities.some(a=> a.ID == this.activityService.selectedActivity.ID)){
      flag = false;
    }
    return flag;
  }
}
