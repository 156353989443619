import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { Brand, KeyMessage } from '@omni/classes/brand/brand.class';
import { ActivityFeedback, ShortCallField } from '@omni/classes/short-call/short-call.class';
import { ActivityService } from '@omni/services/activity/activity.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import Swiper from 'swiper';
import { KeyMessageSentimentCardInterface } from './key-message-sentiment-card/key-message-sentiment-card';
import { SENTIMENT } from '@omni/data-services/websocket/payloads/sentiment.payload';
import _ from 'lodash';
import { AuthenticationService } from '@omni/services/authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { MeetingStructureService } from '@omni/services/meeting-structure/meeting-structure.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

const CARD_FULL_HEIGHT_PADDING_AT_TOP = 24;
const CARD_HEADER_FOOTER_HEIGHT_COMBINED = 44 + 64;

@Component({
  selector: 'io-short-call-details',
  templateUrl: './io-short-call-details.component.html',
  styleUrls: ['./io-short-call-details.component.scss'],
})
export class IoShortCallDetailsComponent implements OnInit, AfterViewInit {

  @HostBinding('class.ion-page') pageClass = false;
  @ViewChild('content', { read: ElementRef }) content;
  @ViewChild('contentWrapper', { read: ElementRef }) contentWrapper;
  @ViewChild('swiper') swiper: Swiper;
  @Input() private view: string;
  public fields: ShortCallField[] = [];
  public activityFeedbacks: ActivityFeedback[] = [];
  public selectedActivityFeedback: ActivityFeedback = null;
  public slideOptions = {
    slidesPerView: 1,
    slidesPerColumn: 2,
    slidesPerColumnFill: 'row',
    spaceBetween: 8,
    speed: 400,
  };
  keyMessagesData: KeyMessageSentimentCardInterface[] = [];
  private lastSlideEndTimestamp: number;

  constructor(
    private navParams: NavParams,
    public activityService: ActivityService,
    private dateFormat: DateTimeFormatsService,
    public translate: TranslateService,
    private datePipe: DatePipe,
    private readonly modalCtrl: ModalController,
    private renderer: Renderer2,
    private authService: AuthenticationService,
    private meetingStructureService: MeetingStructureService,
    private faService: FeatureActionsService,
    public cdRef: ChangeDetectorRef,
  ) {

   }

  ngOnInit() {
    console.log(this.view);
    console.log(this.navParams.data);
    this.initShortCallFields();
    this.initActivityFeedbacks();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.swiper) {
        this.swiper.update();
      }
      setTimeout(async () => {
        // Check for the modal margin & padding
        const modalEl = await this.modalCtrl.getTop();
        const isDynamicModal = modalEl && modalEl.classList.contains('dynamic-height-modal');
        const modalWrapperEl = isDynamicModal ? modalEl.querySelector('.modal-wrapper') : null;
        const parsedParentMarginTop = modalWrapperEl ? parseFloat(window.getComputedStyle(modalWrapperEl, null)?.getPropertyValue('margin-top')) : 0;
        const parentMarginTop = !isNaN(parsedParentMarginTop) ? parsedParentMarginTop : 0;
        const parsedParentPaddingBottom = modalWrapperEl ? parseFloat(window.getComputedStyle(modalWrapperEl, null)?.getPropertyValue('padding-bottom')) : 0;
        const parentPaddingBottom = !isNaN(parsedParentPaddingBottom) ? parsedParentPaddingBottom : 0;

        const maxContentHeight = window.innerHeight - CARD_FULL_HEIGHT_PADDING_AT_TOP - CARD_HEADER_FOOTER_HEIGHT_COMBINED - parentMarginTop - parentPaddingBottom;
        const contentHeight = Math.min(maxContentHeight, this.contentWrapper.nativeElement.offsetHeight);
        this.setContentHeight(contentHeight);
        this.cdRef.detectChanges();
      }, 100);
    }, 40);
  }

  private addKeyMsgsToDisplayArray(origArray: KeyMessage[]): KeyMessageSentimentCardInterface[] {
    if (!origArray || !Array.isArray(origArray) || origArray.length < 1) {
      return [];
    }
    const n = origArray.length;
    const resultArray: KeyMessageSentimentCardInterface[] = [];

    resultArray.push(
      {
        id: origArray[0].ID,
        keyMessage: origArray[0].name,
        sentiment: null,
      }
    );
    // Push even first
    for (let i = 1; i < n; i++) {
      if (i % 2 === 0) {
        resultArray.push(
          {
            id: origArray[i].ID,
            keyMessage: origArray[i].name,
            sentiment: null,
          }
        );
      }
    }
    // push odds later
    for (let i = 1; i < n; i++) {
      if (i % 2 !== 0) {
        resultArray.push(
          {
            id: origArray[i].ID,
            keyMessage: origArray[i].name,
            sentiment: null,
          }
        );
      }
    }

    return _.uniqBy(resultArray, 'keyMessage');
  }

  public initShortCallFields() {
    const meeting = this.activityService.selectedActivity;
    if (meeting && meeting instanceof AppointmentActivity) {
      let dateTimeValue = this.activityService.getFormattedDateTimeText();
      let dateField: ShortCallField = null;
      if (dateTimeValue) {
        const visitDate = this.datePipe.transform(dateTimeValue.startDateTime, this.dateFormat.date, undefined, this.translate.currentLang);
        dateField = { label: 'VISIT_DATE', value: visitDate };
      }
      let conatctStr: string = this.translate.instant('NO_CONTACT');
      if (meeting.contacts.length > 0) {
        conatctStr = `${meeting.contacts[0].firstName} ${meeting.contacts[0].lastName}`;
        if (meeting.contacts.length > 1) {
          conatctStr += ` (+${meeting.contacts.length - 1})`;
        }
      }
      let productStr: string = this.translate.instant('NO_PRODUCTS');
      if (meeting.products?.length > 0) {
        const selectedProducts: Brand[] = meeting.products.filter(p => p.isSelected || p.isAutoSelected);
        if (selectedProducts.length > 0) {
          if (meeting.indskr_parentcallid != null) {
            productStr = selectedProducts.map(pr => pr.name).join(", ");
          } else {
            productStr = `${selectedProducts[0].name}`;
            if (selectedProducts.length > 1) {
              productStr += ` (+${selectedProducts.length - 1})`;
            }
          }
        }

        const allKeyMessages = selectedProducts.reduce((result, product) => {
          if (product.keyMessages && Array.isArray(product.keyMessages)) {
            result.push(...product.keyMessages);
          }
          return result;
        }, []).filter(p => p.isSelected || p.isAutoSelected);

        if (allKeyMessages?.length > 0) {
          this.keyMessagesData = this.addKeyMsgsToDisplayArray(allKeyMessages);
        }
      }
      const contactField: ShortCallField = { label: "SHORT_CALL_CONTACT", value: conatctStr };
      const productField: ShortCallField = { label: "PRODUCTS", value: productStr };
      this.fields = [dateField, contactField, productField];
      if ((this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_SURVEY) || this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SURVEY)) && this.meetingStructureService.surveys?.length > 0) {
        const surveys = this.meetingStructureService.surveys;
        const surveyStr = surveys.length > 1 ? _.sortBy(surveys, 'indskr_name')[0].indskr_name + ` (+${surveys.length - 1})` : surveys[0].indskr_name;
        const surveyField: ShortCallField = { label: "SURVEY", value: surveyStr };
        this.fields.push(surveyField);
      }
      if (
        this.faService.getFeatureAction(FeatureActionsMap.ALL_PRESENTATION_PLAY_DURATION)?.getIsOn()
        && !meeting.isRemoteDetailing
      ) {
        this.lastSlideEndTimestamp = new Date().getTime();
        const presentationPlayDurationField: ShortCallField = {
          label: "ALL_PRESENTATION_PLAY_DURATION",
          value: this.activityService.getAllPresentationPlayDuration(this.activityService.selectedActivity as AppointmentActivity, this.lastSlideEndTimestamp),
        };
        this.fields = [...this.fields, presentationPlayDurationField];
      }
    }
  }

  public onSentimentChange(data: {id: string, sentiment: SENTIMENT}) {
    const keyMsgData = this.keyMessagesData.find(km => km.id === data?.id);
    if (keyMsgData) {
      keyMsgData.sentiment = data.sentiment;
    }
  }

  private initActivityFeedbacks() {
    this.activityFeedbacks = [
      {
        label: 'FEEDBACK_NOT_NITERESTED',
        icon: 'negative',
        value: 548910002
      },
      {
        label: 'FEEDBACK_ACKNOWLEDGE',
        icon: 'neutral',
        value: 548910001
      },
      {
        label: 'FEEDBACK_INTERESTED',
        icon: 'positive',
        value: 548910000
      }
    ];
  }

  public selectActivityFeedback(feedback: ActivityFeedback) {
    this.selectedActivityFeedback = this.selectedActivityFeedback?.value != feedback.value ? feedback : null;
  }

  public closeModal(type: string) {
    let capturedData = null;
    let keyMessageSentiment = null;

    if (type == 'submit') {
      if (this.selectedActivityFeedback) {
        capturedData = {
          indskr_meetingrating: this.selectedActivityFeedback.value
        }
      }
      if (this.keyMessagesData.length) {
        keyMessageSentiment = this.keyMessagesData;
      }
    }

    this.modalCtrl.dismiss({
      type,
      capturedData,
      keyMessageSentiment,
      lastSlideEndTimestamp: this.lastSlideEndTimestamp,
    });
    this.cdRef.detectChanges();
  }

  private setContentHeight(height: number) {
    if (height && this.content?.nativeElement) {
      this.renderer.setStyle(
        this.content.nativeElement,
        'height',
        `${height}px`,
      );
    }
  }
}
