import { Activity, ActivityType } from './activity.class';
import { DB_KEY_PREFIXES } from '../../config/pouch-db.config';
import { addMinutes } from 'date-fns';
import { isInteger } from 'lodash';

export class SetBookingActivity extends Activity {

  public offlineId: string;
  public pendingPushToDynamics: boolean;
  public rawData:any;
  public indskr_status:SetBookingStatus;
  public serviceDTO:any;
  public isTeamKitBooking: boolean;

  constructor(raw: any) {
    super(raw);
    if (!this.ID) {
      this.ID = raw['indskr_setbookingid'];
      if (!this.ID) {
        this.ID = raw['indskr_externalid'];
      }
    }
    this.offlineId = (raw['indskr_externalid']) ? raw['indskr_externalid'] : ''; '';
    if (raw['statuscode']) {
      this.status = parseInt(raw['statuscode']);
    }
    this.subject = (raw['indskr_name']) ? raw['indskr_name'] : 'Kit Booking';
    if(raw['indskr_status_Formatted']){
      this.statusString = raw['indskr_status_Formatted']
    }
    if(raw['indskr_status']){
      this.indskr_status = raw['indskr_status']
    }
    if (raw['indskr_operationstartdate'] || raw['indskr_startdate']) {
      let date = raw['indskr_operationstartdate'] || raw['indskr_startdate']
      if(isInteger(date)){
        this.scheduledStart = new Date(parseInt(date));
      }else{
        this.scheduledStart = new Date(date);
      }
      this.scheduledEnd = addMinutes(this.scheduledStart, 30);
      this.scheduledStartLocale = this.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    }
    if (raw['indskr_operationenddate'] || raw['indskr_enddate']) {
      let date = raw['indskr_operationenddate'] || raw['indskr_enddate']
      if(isInteger(date)){
        this.scheduledEnd = new Date(parseInt(date));
      }else{
        this.scheduledEnd = new Date(date);
      }
    }
    this.type = ActivityType.SetBooking;
    this.pendingPushToDynamics = (raw['pendingPushToDynamics']) ? raw['pendingPushToDynamics'] : null;
    this.rawData = {
      indskr_startdate: raw['indskr_operationstartdate'],
      indskr_enddate: raw['indskr_operationenddate'],
      ...raw
    };
  }

  public get offlineDBId(): string {
    if (this.offlineId) {
      return DB_KEY_PREFIXES.SET_BOOKING + this.offlineId;
    } else {
      return DB_KEY_PREFIXES.SET_BOOKING + this.ID;
    }
  }

  public get statusUpdateDTO(): any {
    if (!this.ID) return {};
    return {
      indskr_setbookingid: this.ID,
      statecode: this.state + '',
      statuscode: this.status + '',
    };
  }

}

export enum SetBookingStatus {
  DRAFT = 808210000,
  REQUESTED = 808210001,
  SUBMITTED = 808210002,
  COMPLETED = 808210003,
  CANCELLED = 808210005,
  RETURNREQUESTED = 808210006,
  PENDINGSYNC = 808210010,
  SYNCERROR = 808210011,
  PENDINGCANCEL = 808210012,
  CANCELERROR = 808210013
}

export const RequiredSetBookingAttributes = [
  "indskr_status",
  "indskr_operationstartdate",
  "indskr_operationenddate",
  "indskr_name",
  "statecode",
  "statuscode",
  "ownerid",
  "indskr_patientid",
]

