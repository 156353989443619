import {takeUntil} from 'rxjs/operators';
import {MultiSelectPopover} from './../../multi-select-popover/multi-select-popover';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {AuthenticationService} from "../../../services/authentication.service";
import {FooterService} from "../../../services/footer/footer.service";
import {IonList, NavController, PopoverController} from "@ionic/angular";
import {DeviceService} from "../../../services/device/device.service";
import {UIService} from "../../../services/ui/ui.service";
import {TrackingEventNames, TrackService} from "../../../services/logging/tracking.service";
import {COACHING_VIEW_TYPE, CoachingReportService} from '../../../services/coaching/coaching.report.service';
import {Report} from '../../../classes/coaching/report.class';
import {Subject, Subscription} from "rxjs";
import {FeatureActionsMap} from '../../../classes/authentication/user.class';
import {RepServices} from "../../../data-services/rep/rep.services";
import {EventsService} from "../../../services/events/events.service";
import {REP_STATUS} from "../../../models/rep-status-model";
import {TranslateService} from '@ngx-translate/core';
import {OrderByPipe} from '../../../pipes/orderby.pipe';
import {IndSectionHeaderViewDataModel} from '../../../models/indSectionHeaderDataModel';
import {MainCardViewDataModel} from '../../../models/MainCardViewDataModel';
import {
  SelectedSuggestionPillDataModel,
  SuggestionPillType,
  UserSavedSearchTypes
} from '../../../models/search-config-data-model';
import {SearchConfigService} from '../../../services/search/search-config.service';
import {SearchConfigDataService} from '../../../data-services/search-config/search-config-data-service';
import {DiskService} from '../../../services/disk/disk.service';
import {DB_KEY_PREFIXES} from '../../../config/pouch-db.config';
import * as _ from 'lodash';
import {AlertService} from '../../../services/alert/alert.service';
import {IndTabsDataModel} from '@omni/models/ind-tabs-data-model';
import {IndHeaderLeftDataModel} from "../../../models/indHeaderLeftDataModel";
import {IndKeyControlsAreaModel} from "@omni/models/indKeyControlsAreaModel";
import {NavigationService, PageName} from '@omni/services/navigation/navigation.service';

/**
 * Generated class for the CoachingListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'coaching-list',
  templateUrl: 'coaching-list.html',
  styleUrls:['coaching-list.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoachingListComponent implements OnInit, OnChanges {

  @Input() isDeviceOnline: boolean = true;
  @Output() closeModal = new EventEmitter<string>();
  @Output() onNewCoaching = new EventEmitter<any>();
  @Output() onCoachingReportSelection = new EventEmitter<any>();
  @ViewChildren('searchResult') filteredItems;
  @ViewChild(IonList, { read: ElementRef, static: false }) list: ElementRef;
  public coachingModel: string = 'teamCoaching';
  public hasMyCoaching: boolean = false;
  public hasTeamCoaching: boolean = false;
  // private statusFilter:number = 0;
  public currentTab: String;
  public IreportByTabSubjectSubscription: Subscription;
  public reportByTabSubject: Report[] = [];
  // private myReportByTabSubject: Report[] = [];
  // private teamReportByTabSubject: Report[] = []
  public filteredList: Report[] = [];
  ngdestroy$: any = new Subject<boolean>();
  // listFiltered: boolean = false;
  filterPopoverData: any;
  teamFilterPopoverData: any;
  searchText: string;
  //@ViewChild(Content, {static:true}) content:Content;

  // searchTextModel: string;
  private orderBy = new OrderByPipe();
  sortPopoverData: any;
  teamSortPopoverData: any;
  sortBy:{text: string, value: any, asc: boolean} = { text: this.translate.instant('COACHING_DETAILS_COACHING_DATE'), value: "createdon", asc: false }
  teamSortBy:{text: string, value: any, asc: boolean} = { text: this.translate.instant('COACHING_DETAILS_COACHING_DATE'), value: "createdon", asc: true }

  public searching: boolean = false;
  coachingSearchText: string;
  shouldFireSearchChangeEvent: boolean = true;
  suggestionsData: {
      header:IndSectionHeaderViewDataModel,
      listData: MainCardViewDataModel[]
  }[] = [];
  //search key to be passed to search-suggestion-popover
  searchKey : string = '';
  selectedSuggestionsData:  SelectedSuggestionPillDataModel[] = [];
  suggestionsActive: boolean = false;
  disableSaveSearch: boolean = false;
  tabsData: IndTabsDataModel[];
  indHeaderLeftModel: IndHeaderLeftDataModel;
  public coachingListKeyControlAreaModel: IndKeyControlsAreaModel;
  public allMyCoachingHeaderModel: IndSectionHeaderViewDataModel;
  sectionHeaderData: IndSectionHeaderViewDataModel;
  searchSectionHeaderData: IndSectionHeaderViewDataModel;
  private visibleCount: number = 0;
  public filteredCoachingList: any[] = [];
  public coachingFromGenee: Report = null;
  constructor(
    private navCtrl: NavController,
    private footerService: FooterService,
    public uiServices: UIService,
    private device: DeviceService,
    private authenticationService: AuthenticationService,
    private trackingService: TrackService,
    public coachingReportService: CoachingReportService,
    public repService: RepServices,
    public events: EventsService,
    private _cd: ChangeDetectorRef,
    public popover: PopoverController,
    private translate : TranslateService,
    public coachingListElement: ElementRef,
    public disk: DiskService,
    public searchConfigService: SearchConfigService,
    public searchConfigDataService: SearchConfigDataService,
    private alertService: AlertService,
    private navService: NavigationService

  ) { }

  ngOnInit() {
    this.uiServices.showNewActivity = true;
    this.coachingSearchText = '';
    this.coachingReportService.selectedReport.next(null);
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.COACHING_TOOL)) {
      this.coachingModel = "myCoaching";
      this.searchText = this.translate.instant("SEARCH_MY_COACHING")
      this.hasMyCoaching = true;
    }
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.COACHING_TOOL) && this.authenticationService.hasFeatureAction(FeatureActionsMap.COACHING_TEAM_VIEW)) {
      this.hasTeamCoaching = true;
    }
    this.setTabsData();
    this.initCoachingHeaderLeft();
    this._initKeyControlsAreaModel();
    this.currentTab = this.coachingModel;
    this.isDeviceOnline = !this.device.isOffline;

    this.IreportByTabSubjectSubscription = this.coachingReportService.reportByTabSubjectObs.subscribe(
      (res:Report[]) => {
          this._cd.detectChanges();
          // this.reportByTabSubject = this.orderBy.transform(res, "createdon", false);
          this.reportByTabSubject = res;
          if(this.coachingModel === 'myCoaching') this.sortCoachingBySortSelection(this.sortBy);
          else this.sortCoachingBySortSelection(this.teamSortBy);
          this.updateVisibleRecords();
          this._cd.detectChanges();
          this._cd.markForCheck();
          this.initSectionHeaders();
      }
    );

    this.events.subscribe('open-coaching-from-genee', (params) => {
      this.openCoachingReportFromGenee(params);
    });

    this.events.observe('device:deviceIsOffline').pipe(
      takeUntil(this.ngdestroy$))
      .subscribe((status) => {
        // the network service indicate that the device is offline so no arguments.
        this._cd.detectChanges();
        this.isDeviceOnline = false;
        this._cd.detectChanges();
        this._cd.markForCheck();
      });

    this.events.observe('device:deviceIsOnline').pipe(
      takeUntil(this.ngdestroy$))
      .subscribe((status) => {
        if (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState) {
          this._cd.detectChanges();
          this.isDeviceOnline = false;
          this._cd.detectChanges();
          this._cd.markForCheck();
        }
        else {
          this._cd.detectChanges();
          this.isDeviceOnline = true;
          this._cd.detectChanges();
          this._cd.markForCheck();
        }
      });
    this.events.observe("open-coaching").pipe(
      takeUntil(this.ngdestroy$))
      .subscribe(async (report: Report) => {
        this.openCoachingReportDetails(report);
      });

    this.sortPopoverData = [
      {
        text: "",
        expanded: true,
        value: "createdon",
        items: [
          { text: this.translate.instant('COACHING_DETAILS_COACHING_DATE'), value: "createdon", asc: false },
          { text: this.translate.instant('ACKNOWLEDGED_DATE'), value: "indskr_dateacknowledged", asc: false },
          { text: this.translate.instant('FOR_REVIEW_DATE'), value: "indskr_dateforreview", asc: false },
        ],
        handler: (selectedItem, item) => {
          item.value = selectedItem.value;
          this.sortBy = selectedItem;
          this.updateSortTextInHeader();
          this.sortCoachingBySortSelection(selectedItem);
          // this.coachingReportService.fiterReports();
          this.updateVisibleRecords();
        }
      }
    ];
    this.sortBy = this.sortPopoverData[0].items[0];
    this.teamSortPopoverData=[
      {
        text: "",
        expanded: true,
        value: "createdon",
        items: [
          { text: this.translate.instant('COACHING_DETAILS_COACHING_DATE'), value: "createdon", asc: false },
          { text: this.translate.instant('NAME'), value: "created_for", asc: true },
          { text: this.translate.instant('ACKNOWLEDGED_DATE'), value: "indskr_dateacknowledged", asc: false },
          { text: this.translate.instant('FOR_REVIEW_DATE'), value: "indskr_dateforreview", asc: false },
        ],
        handler: (selectedItem, item) => {
          item.value = selectedItem.value;
          this.teamSortBy = selectedItem;
          this.updateSortTextInHeader();
          this.sortCoachingBySortSelection(selectedItem);
          // this.coachingReportService.fiterReports();
          this.updateVisibleRecords();
        }
      }
    ]
    this.teamSortBy = this.teamSortPopoverData[0].items[0];
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.COACHING_SHARE)) {
      this.sortPopoverData[0].items.push({ text: this.translate.instant('SHARED_DATE'), value: "indskr_shareddate", asc: false },
        { text: this.translate.instant('ACCEPTED_DATE'), value: "indskr_accepteddate", asc: false });
      this.teamSortPopoverData[0].items.push({ text: this.translate.instant('SHARED_DATE'), value: "indskr_shareddate", asc: false },
        { text: this.translate.instant('ACCEPTED_DATE'), value: "indskr_accepteddate", asc: false });
    }

    if(this.searchConfigService.configUpdateRequired){
      this.searchConfigService.updateSearchConfigsForSelectedLanguage();
      this.searchConfigService.configUpdateRequired = false;
    }
    this.refreshCoachingList();
    this.updateEmptyMessage()
  }

  private updateVisibleRecords() {
    this.visibleCount = 20;
    if (this.reportByTabSubject.length > 20) {
      this.filteredCoachingList = this.reportByTabSubject.slice(0, this.visibleCount);
    } else {
      this.filteredCoachingList = this.reportByTabSubject;
    }
    // this.handleSearch();
    this.updateEmptyMessage();
  }

  private async openCoachingReportFromGenee(params: any) {
    if (params) {
      // Get the coaching Id.
      const coachingId = params.coachingId;
      if (this.coachingFromGenee && this.coachingFromGenee.indskr_coachingreportid === coachingId) {
        // Requested for the same coaching. Nothing to do here. Ignoring request.
        return;
      }
      // Check if it is a team coaching.
      const isTeamCoaching = params.isTeamCoaching;
      // Change the tab based on the coaching type.
      if (isTeamCoaching) {
        this.coachingModel = "teamCoaching";
      } else {
        this.coachingModel = "myCoaching";
      }
      // Refresh the reports based on the tab.
      await this.refreshCoachingList();
      // Get the reports by tab.
      const reports = this.reportByTabSubject.filter(r => r.indskr_coachingreportid === coachingId);
      if (reports.length > 0) {
        let report = reports[0];
        if (report) {
          // Assign to temporary variable for display.
          this.coachingFromGenee = report;
          // If exist in first twenty records clean it.
          if (this.isExistInFirstTwenties()) {
            this.coachingFromGenee = null;
            await this.scrollToRequestedCard(coachingId);
          }
          // Open the requested report from Genee.
          await this.openCoachingReportDetails(report);
        }
      } else {
        console.warn("Coaching from Genee: Report doesn't exist.");
      }
    }
  }

  private scrollToRequestedCard(coachingId: string) {
    const mainCardElement = document.getElementById(coachingId);
    if (mainCardElement) {
      setTimeout(() => {
        mainCardElement.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
      }, 500);
    }
  }

  public doInfinite(event) {
    this.visibleCount = this.filteredCoachingList.length;
    this.filteredCoachingList.push(...this.reportByTabSubject.slice(this.visibleCount, this.visibleCount + 20));
    // this.handleSearch();
    event.target.complete();

    // If requested report will be available then we can hide the temporary added (highlighted) coaching report.
    if (this.isVisibleCardFoundInCurrentList()) {
      this.coachingFromGenee = null;
    }
  }

  private isExistInFirstTwenties(): boolean {
    if (this.coachingFromGenee) {
      if (this.filteredCoachingList.length <= 20) {
        const reports = (this.filteredCoachingList.filter(r => r.indskr_coachingreportid === this.coachingFromGenee.indskr_coachingreportid))
        return reports.length > 0 ? true : false;
      }
    }
    return false;
  }

  private isVisibleCardFoundInCurrentList(): boolean {
    if (this.coachingFromGenee) {
      if (this.filteredCoachingList.length) {
        const reports = (this.filteredCoachingList.filter(r => r.indskr_coachingreportid === this.coachingFromGenee.indskr_coachingreportid));
        return reports.length > 0 ? true : false;
      }
    }
    return false;
  }


  updateEmptyMessage() {
    let dataSize = 0
    if (!this.searching) {
      if (this.reportByTabSubject.length > 0) {
        dataSize = 1
      }
    }
    else if (this.filteredList.length > 0) {

    }
    this.uiServices.updateNothingSelectedScreenMessageFor(dataSize)
  }

  get showTertiaryFlag(): boolean {
    return this.sortBy && (this.sortBy.value == 'indskr_dateacknowledged' || this.sortBy.value == 'createdon' || this.sortBy.value == 'indskr_shareddate' || this.sortBy.value == 'indskr_accepteddate' || this.sortBy.value == 'indskr_dateforreview');
  }

  ngOnChanges() {

  }

  ngOnDestroy() {
    this.events.unsubscribe('open-coaching-from-genee');
    this.IreportByTabSubjectSubscription.unsubscribe();
    this.ngdestroy$.next(true);
    this.ngdestroy$.complete();
  }

  ngAfterViewInit() {
    this._cd.detectChanges();
    this._cd.markForCheck();
    setTimeout(() => {
      this.uiServices.scrollListToView(this.list);
    }, 200);
  }

  // onfilterChange(status_code:number) {
  //   this.statusFilter = status_code;
  //   this.applyFilter();
  // }

  checkActivityModel(value:String) {
    this.coachingSearchText = '';
    if(value === 'myCoaching'){
      this.searchText = this.translate.instant("SEARCH_MY_COACHING")
    } else{
      this.searchText = this.translate.instant("SEARCH_TEAM_COACHING")
    }
    if (this.currentTab === value) return;
    if (this.coachingModel === 'teamCoaching')
      this.trackingService.tracking('TeamCoachingClicked', TrackingEventNames.COACHING);
    this.currentTab = value;
    // this.statusFilter = 0;
    this.coachingReportService.selectedReport.next(null);
    if (this.navService.getActiveChildNavViewPageName() == PageName.CoachingDetailsComponent) {
      this.navService.popToRootChildNavPageWithPageTracking();
    }
    this.initCoachingHeaderLeft();
    this.removeAllSelectedSuggestions();
    this.refreshCoachingList();
    this.updateEmptyMessage();
    this.initSectionHeaders();
    this._initKeyControlsAreaModel();
  }

  openCoachingReportDetails(report:Report) {
    if (this.coachingModel === 'myCoaching')
      this.trackingService.tracking('MyCoachingClicked', TrackingEventNames.COACHING);
    else {
      this.trackingService.tracking('CoachingReportClicked', TrackingEventNames.COACHING);
      this.events.publish('TeamCoachingReportClicked');
    }
    this.coachingReportService.selectedReport.next(report);
    this.coachingReportService.coachingViewtype = COACHING_VIEW_TYPE.FROM_TOOL;

    this.onCoachingReportSelection.emit(report);
  }

  makeNewCoaching() {
    this.trackingService.tracking('CoachingCreated', TrackingEventNames.COACHING)
    this.onNewCoaching.emit(null);
    console.log("initialized new coaching report");
    this.refreshCoachingList();
    this.updateEmptyMessage()
  }

  private refreshCoachingList() {
    this.coachingReportService.activeTab = this.coachingModel;
    this.coachingReportService.fiterReports();
  }

  sortCoachingBySortSelection(selectedSortOption){
    let list: any;
    if(!this.searching) list = this.reportByTabSubject
    else list = this.filteredList;

    list.sort((a, b) =>{
      if (a[selectedSortOption.value] == null) a[selectedSortOption.value] = '';
      if (b[selectedSortOption.value] == null) b[selectedSortOption.value] = '';

      if (selectedSortOption.value === 'created_for') {
        if (a[selectedSortOption.value] == '') a[selectedSortOption.value] = 'Unknown';
        if (b[selectedSortOption.value] == '') b[selectedSortOption.value] = 'Unknown';
        if(a[selectedSortOption.value] == b[selectedSortOption.value]) return (a.createdon > b.createdon) ? -1 : 1;
        else return a[selectedSortOption.value] > b[selectedSortOption.value] ? 1 : -1;
      }
      else if(selectedSortOption.asc) {
        if (a[selectedSortOption.value] > b[selectedSortOption.value]) return 1;
        else return -1;
      } else {
        if (a[selectedSortOption.value] > b[selectedSortOption.value]) return -1;
        else return 1;
      }
    })
  }

  async sortCoachingList(){
    if(this.coachingModel === 'myCoaching'){
      this.popover
      .create({component: MultiSelectPopover,componentProps: { root: this.sortPopoverData }, event:event})
      .then((data)=>{
        data.present();
      })
    } else {
      this.popover
      .create({component: MultiSelectPopover,componentProps: { root: this.teamSortPopoverData }, event:event})
      .then((data)=>{
        data.present();
      })
    }
  }

  trackByFn(index, item) {
    return index;
  }

  /**
   *
   *
   * @memberof CoachingListComponent
   */
  onCloseModal(event) {
    this.closeModal.emit(event);
    // emit this event to the coaching component
  }

  // start advance search functions
  @HostListener('window:click', ['$event.target'])
  onClick(targetElement: string) {
      if(this.suggestionsActive) {
          this.suggestionsActive = false
      }
      this._cd.detectChanges();
  }

  onInput(event) {
      this.searchKey = '';
      if(!this.shouldFireSearchChangeEvent){
        this.shouldFireSearchChangeEvent = true;
        return;
      }
      let params = (event.value) ? event.value : '';
      //for length > 2
      if (params.length > 2) {
        if (params.trim().length == 0) return;
        this.searching = true;
        this.searchKey = params;
        this._initKeyControlsAreaModel();
        this.searchCoachingSuggestions(this.reportByTabSubject, params, event);
      }
      // for length 0 to 2
      else {
          this.suggestionsData = [];
          if(!this.selectedSuggestionsData || !this.selectedSuggestionsData.length){
            this.filteredList = [];
            this.searching = false;
            this._initKeyControlsAreaModel();
          }
          else{
            this.searchCoachingList();
          }
          if (this.coachingModel === 'myCoaching') {
            if(!this.coachingReportService.recentSearches || !this.coachingReportService.recentSearches.length){
              let searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='My Coaching')
              if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
                this.suggestionsActive = false;
              }
            }
          }
          else {
            if(!this.coachingReportService.teamRecentSearches || !this.coachingReportService.teamRecentSearches.length){
              let searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='Team Coaching');
              if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
                this.suggestionsActive = false;
              }
            }
          }
      }
      this._cd.detectChanges();
      this.updateEmptyMessage()
      this.searchSectionHeaderData.title = `${this.translate.instant('RESULTS_CAP')} (${this.filteredList.length})`;
  }

  //search the facets list to display suggestions and also do text search for contacts list
  searchCoachingSuggestions(Coachings: Report[], searchText: string, event?){
    if (searchText && Coachings) {
      searchText = searchText.toUpperCase();
      let searchIndexConfig:any;
      if(this.coachingModel === 'myCoaching') {
        searchIndexConfig = this.searchConfigService.myCoachingSearchIndexesConfig;
      } else {
        searchIndexConfig = this.searchConfigService.teamCoachingSearchIndexesConfig;
      }
      this.suggestionsData = this.searchConfigService.fetchSuggestions(searchIndexConfig,this.selectedSuggestionsData,this.translate.instant("Coaching"),this.coachingSearchText)
      if(this.suggestionsData.length) this.suggestionsActive = true;
      if(!this.selectedSuggestionsData || !this.selectedSuggestionsData.length){
        this.filteredList = Coachings
      }
      const formattedSearchText = this.searchConfigService.convertFormattedString(searchText).toLowerCase();
      this.filteredList = this.filteredList.filter((coaching)=>{
        let coachingString: any;
        if(this.coachingModel === 'myCoaching') coachingString = this.translate.instant("BY") + ' ' + this.getOjectValues(coaching);
        else coachingString = this.translate.instant("COACHING_FOR") + ' ' + this.getOjectValues(coaching);
        return coachingString.toLowerCase().includes(formattedSearchText);
      })
    } else {
      return [];
    }
  }


  //search the list based off selected facets
  searchCoachingList(){
    //second level search for coaching
    this.searching = true;
    this._initKeyControlsAreaModel();
    this.coachingReportService.fiterReports();
    let filteredCoaching:any = this.reportByTabSubject;
    let selectedSuggestionsDataCopy = this.selectedSuggestionsData.slice();
    let entityLevelCharSearch = selectedSuggestionsDataCopy.find(o=> o.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH);
    const formattedSearchText = entityLevelCharSearch && entityLevelCharSearch.charSearchText ? this.searchConfigService.convertFormattedString(entityLevelCharSearch.charSearchText).toLowerCase() : '';
    const formattedSearchTextSplit = formattedSearchText ? formattedSearchText.split(" ") : '';
    if (entityLevelCharSearch && formattedSearchTextSplit) {
      formattedSearchTextSplit.forEach(searchText => {
        filteredCoaching = filteredCoaching.filter(coaching => {
          let coachingEntityLevel: any;
          if (this.coachingModel === 'myCoaching') coachingEntityLevel = this.translate.instant("BY") + ' ' + this.getOjectValues(coaching);
          else coachingEntityLevel = this.translate.instant("COACHING_FOR") + ' ' + this.getOjectValues(coaching);
          return coachingEntityLevel.includes(searchText);
         });
      });
    }
    filteredCoaching = this.searchConfigService.fetchFilteredListBasedOnSuggestions(selectedSuggestionsDataCopy, filteredCoaching);
    this.filteredList = filteredCoaching;
    this.searchSectionHeaderData.title = `${this.translate.instant('RESULTS_CAP')} (${this.filteredList.length})`;
  }

  getOjectValues(coachingObject): string {
    let objString: string[] = [];
    let coachingInclude: any;
    if(this.coachingModel === 'myCoaching') coachingInclude = ['created_by'];
    else coachingInclude = ['created_for'];
    for (let coaching in coachingObject) {
      if (coachingInclude.indexOf(coaching) > -1 && coachingObject[coaching]) {
        if (Array.isArray(coachingObject[coaching])) {
          for (let i = 0; i < coachingObject[coaching].length; i++) {
            objString.push(this.getOjectValues(coachingObject[coaching][i]));
          }
        } else {
          objString.push(coachingObject[coaching]);
        }
      }
    }
    return this.searchConfigService.convertFormattedString(objString.toString().toLowerCase());
  }

  ionSearchFocus(ev) {
    try {
      let el = this.coachingListElement.nativeElement.ownerDocument.getElementById('allCoaching');
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.log("scroll error");
    }
  }

  clickSearchArea(ev){
    ev.stopPropagation();
    if(!this.suggestionsActive) {
      this.suggestionsActive = true;
      if(this.coachingModel === 'myCoaching' && (!this.coachingReportService.recentSearches || !this.coachingReportService.recentSearches.length)){
        let searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='My Coaching')
        if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
          this.suggestionsActive = false;
        }
      } else if(this.coachingModel === 'teamCoaching' && (!this.coachingReportService.teamRecentSearches || !this.coachingReportService.teamRecentSearches.length)) {
        let searchToolName = this.searchConfigService.toolNames.find(tool=>tool.toolName=='Team Coaching')
        if(searchToolName && (this.searchConfigService.savedSearches.filter(search=>search.searchToolName == searchToolName.searchToolNameID)).length == 0){
          this.suggestionsActive = false;
        }
      }
    }
  }

  clickedInSuggestionsArea(ev){
    ev.stopPropagation();
  }

  handleFacetSelection(data:SelectedSuggestionPillDataModel){
    this.disableSaveSearch = false;
    console.log(data);
    this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(data, this.selectedSuggestionsData);
    this.selectedSuggestionsData.sort((a,b)=>{
      if(a.createdOn < b.createdOn) return 1
      else return -1
    })
    //if(this.selectedSuggestionsData.length == 1) this.content.resize();
    this.suggestionsActive = false;
    this.coachingSearchText = '';
    this.suggestionsData= [];
    this.shouldFireSearchChangeEvent = false;
    this.searchCoachingList();
    if(!data.isComingFromRecentSearch){
      let recentSearches = this.coachingModel == 'myCoaching' ? this.coachingReportService.recentSearches : this.coachingReportService.teamRecentSearches;
      if(!recentSearches.some(recSearch=>recSearch.categoryName==data.categoryName && recSearch.selectedFacet==data.selectedFacet)){
          recentSearches.unshift(data);
          _.remove(recentSearches, (o, index)=>index>2);
          if(this.coachingModel == 'myCoaching'){
          this.disk.updateOrInsert(DB_KEY_PREFIXES.MY_COACHING_RECENT_SEARCHES,(doc)=>{
            if(!doc || !doc.raw){
              doc={
                raw:[]
              }
            }
            doc.raw = recentSearches;
            return doc;
          })
        } else {
          this.disk.updateOrInsert(DB_KEY_PREFIXES.TEAM_COACHING_RECENT_SEARCHES,(doc)=>{
            if(!doc || !doc.raw){
              doc={
                raw:[]
              }
            }
            doc.raw = recentSearches;
            return doc;
          })
        }
      }
    }
    this._initKeyControlsAreaModel();
  }

  handleSavedSearchSelection(data){
    this.selectedSuggestionsData = [];
    if(data && data.categoryValuePairs){
      data.categoryValuePairs.forEach(catValPair=>{
        this.selectedSuggestionsData = this.searchConfigService.manageSelectedSuggestions(catValPair, this.selectedSuggestionsData);
      })
      this.selectedSuggestionsData.sort((a,b)=>{
        if(a.createdOn < b.createdOn) return 1
        else return -1
      })
      //if(this.selectedSuggestionsData.length > 0) this.content.resize();
      this.suggestionsActive = false;
      this.coachingSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searchCoachingList();
    }
  }

  removeSelectedSuggestion(suggestion){
    this.disableSaveSearch = false;
    this._initKeyControlsAreaModel();
    _.remove(this.selectedSuggestionsData,(o)=>_.isEqual(o,suggestion));
    if(this.selectedSuggestionsData.length == 0){
      //this.content.resize();
      this.filteredList = [];
      this.coachingSearchText = '';
      this.shouldFireSearchChangeEvent = false;
      this.searching = false;
      this._initKeyControlsAreaModel();
    }
    else{
      this.searchCoachingList();
    }
  }

  removeAllSelectedSuggestions(){
    this.disableSaveSearch = true;
    this.selectedSuggestionsData = [];
    //this.content.resize();
    this.filteredList = [];
    this.coachingSearchText = '';
    this.shouldFireSearchChangeEvent = false;
    this.searching = false;
    this._initKeyControlsAreaModel();
  }

  async saveAdvancedSearch(failedText?: string){
    let saveSearchName: string
    let toolName;
    if(this.coachingModel === 'myCoaching') {
      toolName = this.searchConfigService.toolNames.find(o=>o.toolName=='My Coaching');
    } else {
      toolName = this.searchConfigService.toolNames.find(o=>o.toolName=='Team Coaching');
    }
    let currentSavedSearches = this.searchConfigService.savedSearches.filter(o=> o.searchToolName==toolName.searchToolNameID);
    this.alertService.showAlert({ title:this.translate.instant('SAVE_SEARCH'),
                                  subTitle:failedText?failedText:'',
                                  cssClass:'saveSearchAlert',
                                  message:this.translate.instant('SAVE_SEARCH_POPOVER_MESSAGE'),
                                  inputs:[{type:'text', name:"name", placeholder:this.translate.instant('ENTER_NAME')}]
                                }, this.translate.instant('SAVE'), this.translate.instant('CANCEL'))
    .then(async (res)=>{
      if(res.role == 'ok'){
        if(res.data && res.data.values.name){
          saveSearchName = res.data.values.name.trim();
          let sameNameSearches = currentSavedSearches.filter(o=>o.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase())==0);
          let incrementNumber: number = 1;
          if(sameNameSearches.length){
            saveSearchName += ' (';
            _.each(sameNameSearches,(searchData=>{
              if(searchData.searchName.toLowerCase().indexOf(saveSearchName.toLowerCase()) == 0){
                let currentIncrement = parseInt(searchData.searchName.toLowerCase().charAt(saveSearchName.length));
                if(!isNaN(currentIncrement) && currentIncrement>=incrementNumber) incrementNumber = currentIncrement+1;
              }
            }))
            saveSearchName+= incrementNumber+')';
          }
          await this.searchConfigDataService.saveAdvancedSearch(saveSearchName,
                                                                toolName?toolName.searchToolNameID:'',
                                                                UserSavedSearchTypes.OWNED,
                                                                this.selectedSuggestionsData.slice())
          this.disableSaveSearch = true;
          this._initKeyControlsAreaModel();
          this._cd.detectChanges();
        }
        else if(res.data && res.data.values.name == ""){
          this.saveAdvancedSearch(this.translate.instant('NAME_IS_REQUIRED'));
        }
      }
    })
  }

  setTabsData() {
    this.tabsData = [
      {
         displayText: this.translate.instant('COACHING_MY_COACHING'),
         value: "myCoaching"
      },
      {
       displayText: this.hasTeamCoaching ? this.translate.instant('COACHING_TEAM_COACHING') : '',
       value: "teamCoaching",
       disable: !this.hasTeamCoaching
      },
    ];
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.onCloseModal(id);
        break;
      case 'plusNew':
        this.makeNewCoaching();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  private initCoachingHeaderLeft(): void {
    let buttons = [];
    buttons.push({
      id: "close",
      isDisabled: false,
      align: "left",
      imgSrc: "assets/imgs/back_to_home_btn.svg"
    })
    if (this.coachingModel === 'teamCoaching') {
      buttons.push({
        id: "plusNew",
        imgSrc: 'assets/imgs/ios_add_3x.svg',
        isDisabled: false,
        align: "right",
      });
    }
    this.indHeaderLeftModel = {
      id: 'coaching-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('COACHING'),
      mode: true,
      controls: buttons,
    };
  }

  private _initKeyControlsAreaModel() {
    this.coachingListKeyControlAreaModel = {
      id: 'coaching-list-key-controls-area',
      isHidden: false,
      eventHandler: (id: string, event, eventName) => this.handleControlsAreaEvent(id, event, eventName),
      controls: [
        {
          id: 'save-search',
          text: this.translate.instant('SAVE_SEARCH'),
          isHidden: false,
          isDisabled: !this.searching || this.disableSaveSearch,
          leftAligned: true
        }
      ]
    }
  }

  private handleControlsAreaEvent(id,event,eventName) {
    if (id && eventName && eventName == 'controls-click') {
      switch(id){
        case 'save-search':
          this.saveAdvancedSearch();
          break;
        default:
          console.log('Unhandled switch case statement');
      }
    }
  }

  private initSectionHeaders() {
    this.sectionHeaderData = {
      id: 'section-header',
      title: `${this.translate.instant('COACHING_ALL_COACHING_CAP')} (${this.reportByTabSubject.length})`,
      controls: [
        {
          id: 'coaching-sort',
          text: this.coachingModel === 'teamCoaching' ? this.teamSortBy.text : this.sortBy.text,
          isDisabled: false,
          img: "assets/imgs/sort_with_double_arrows.svg",
        }
      ],
    };
    this.searchSectionHeaderData = {
      id: 'section-header',
      title: `${this.translate.instant('RESULTS_CAP')} (${this.filteredList.length})`,
      controls: [
        {
          id: 'coaching-sort',
          text: this.coachingModel === 'teamCoaching' ? this.teamSortBy.text : this.sortBy.text,
          isDisabled: false,
          img: "assets/imgs/sort_with_double_arrows.svg",
        }
      ],
    };
  }

  onSectionHeaderControlClick(id: string) {
    if (id === 'coaching-sort') {
        this.sortCoachingList();
    }
  }

  updateSortTextInHeader() {
    if(this.searching) {
      if(this.coachingModel === 'teamCoaching') {
        this.sectionHeaderData.controls[0].text = this.teamSortBy.text;
        this.searchSectionHeaderData.controls[0].text = this.teamSortBy.text;
      }
      else {
        this.sectionHeaderData.controls[0].text = this.sortBy.text;
        this.searchSectionHeaderData.controls[0].text = this.sortBy.text;
      }
    } else {
      if(this.coachingModel === 'teamCoaching') {
          this.sectionHeaderData.controls[0].text = this.teamSortBy.text;
          this.searchSectionHeaderData.controls[0].text = this.teamSortBy.text;
      }
      else {
        this.sectionHeaderData.controls[0].text = this.sortBy.text;
        this.searchSectionHeaderData.controls[0].text = this.sortBy.text;
      }
    }
  }

  public onClickEnter(ev){
    if(ev){
      this.suggestionsActive = false;
    }
  }
}
