import { Component, ViewChild, ViewChildren } from '@angular/core';
import { NavigationService, PageName, ChildNavNames } from '../../../services/navigation/navigation.service';
import { UIService } from '../../../services/ui/ui.service';
import { DeviceService } from '../../../services/device/device.service';
import { TrackService } from '../../../services/logging/tracking.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { NavController } from '@ionic/angular';
import { AppSettingsDetailsComponent } from '../appsettings-details/appsettings-details';
import { AppSettingsRecommendationsComponent } from '../app-settings-recommendations/app-settings-recommendations.component';
import { GeneralSettingsComponent } from '../general-settings/general-settings';
import { AboutPageComponent } from '../about-page/about-page';
import { SettingsService } from '../../../services/app-settings/settings.service';
import { IoFileService } from '../../../services/io-file-service/io-file-service';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { IndHeaderLeftDataModel } from '../../../models/indHeaderLeftDataModel';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { ActivityService } from '../../../services/activity/activity.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { EventsService } from '@omni/services/events/events.service';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

/**
 *
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'appsettings-list',
  templateUrl: 'appsettings-list.html',
  styleUrls:['appsettings-list.scss']
})
export class AppSettingsListComponent {

  public isSelected: boolean = true;
  //@ViewChild(Content, {static:true}) content: Content;
  @ViewChildren('searchResult') filteredItems;
  settingList:any[];
  searchedSettingList: any[] = [];
  selectedOption: string;
  allSettingHeader: IndSectionHeaderViewDataModel;
  searchResultHeader: IndSectionHeaderViewDataModel
  public settingCount:number;
  searchTextModel: string;
  indHeaderLeftModel: IndHeaderLeftDataModel;

  constructor(
    public navService: NavigationService,
    public uiService: UIService,
    public device: DeviceService,
    public trackingService: TrackService,
    public repService: RepServices,
    public navCtrl: NavController,
    public settingService: SettingsService,
    public ioFile: IoFileService,
    public translate:TranslateService,
    private authService: AuthenticationService,
    private activityService: ActivityService,
    public footerService: FooterService,
    private events: EventsService,
    private agendaFooterService: AgendaFooterService,
    private faService: FeatureActionsService,
  ) {
    if (this.device.shouldBeMobileView) this.isSelected = false;
  }

  ngOnInit() {
    this.searchTextModel = "";
    this.initSettingList();
    this.initHeaderLeft();
    this.settingCount= this.settingList.length;
    this.initSectionHeader();
    this.translate.onLangChange.subscribe(data =>{
      this.translate.use(data.lang);
      this.initSettingList();
      this.initHeaderLeft();
      this.initSectionHeader();
    });
  }

  initSectionHeader(){
    this.allSettingHeader = {
      id:'all-setting-header',
      title: this.translate.instant('SETTINGS_ALL_SETTINGS') +' '+'('+this.settingCount+')',
      controls: [],
    };
    this.searchResultHeader = {
      id:'all-setting-header',
      title: this.getSearchSectionHeaderTitle(),
      controls: [],
    };
  }
  private getSearchSectionHeaderTitle(): string {
    return `${this.translate.instant('RESULT')} (${this.searchedSettingList.length})`;
  }

  initSettingList(){
    this.settingList = [{
      id: 'SETTINGS_GENERAL_SETTINGS',
      fixedHeight: true,
      primaryTextLeft: '',
      secondaryTextLeft: '',
      showIcon: false,
      iconName: '',
      secondaryTextRightTwo:'',
      isSecondaryTextRightTwoPillType: false,
      showArrow: false,
      arrowType: 'assets/imgs/general_settings2x.png',
      primaryTextRight: this.translate.instant('SETTINGS_GENERAL_SETTINGS'),
      secondaryTextRight: this.translate.instant('SETTINGS_GENERAL_SETTINGS_SUB_TITLE'),
      isSelected: this.selectedOption === 'SETTINGS_GENERAL_SETTINGS',
    },{
      fixedHeight: true,
      primaryTextLeft: '',
      secondaryTextLeft: '',
      showIcon: false,
      iconName: '',
      secondaryTextRightTwo:'',
      isSecondaryTextRightTwoPillType: false,
      showArrow: false,
      arrowType: 'assets/imgs/about2x.png',
      id: 'ABOUT',
      primaryTextRight: this.translate.instant('ABOUT'),
      secondaryTextRight: this.translate.instant('SETTINGS_ABOUT_SUBTITLE'),
      isSelected: this.selectedOption === 'ABOUT',
    }];

    if(this.authService.hasFeatureAction(FeatureActionsMap.TIME_RECOMMENDATION) && this.authService.user.bestTimeRecommendationConfig > 0){
      this.settingList.splice(1,0,{
        fixedHeight: true,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showIcon: false,
        iconName: '',
        secondaryTextRightTwo:'',
        isSecondaryTextRightTwoPillType: false,
        showArrow: false,
        arrowType: 'assets/imgs/app-settings-diagnostic-icon.PNG',
        id: 'SETTINGS_RECOMMENDATIONS',
        primaryTextRight: this.translate.instant('SETTINGS_RECOMMENDATIONS'),
        secondaryTextRight: this.translate.instant('SETTINGS_RECOMMENDATIONS_SUBTITLE'),
        isSelected: this.selectedOption === 'SETTINGS_RECOMMENDATIONS',
      });
    }

    if(this.authService.hasFeatureAction(FeatureActionsMap.ASSISTED_DIAGNOSTIC)){
      this.settingList.unshift({
        fixedHeight: true,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showIcon: false,
        iconName: '',
        secondaryTextRightTwo:'',
        isSecondaryTextRightTwoPillType: false,
        showArrow: false,
        arrowType: 'assets/imgs/app-settings-diagnostic-icon.PNG',
        id: 'SETTINGS_ASSISTED_DIAGNOSTICS',
        primaryTextRight: this.translate.instant('SETTINGS_ASSISTED_DIAGNOSTICS'),
        secondaryTextRight: this.translate.instant('SETTINGS_RECVD_ADV_ASS_FROM_OMNI'),
        isSelected: this.selectedOption === 'SETTINGS_ASSISTED_DIAGNOSTICS',
      });
    }
  }

  private initHeaderLeft() {
    this.indHeaderLeftModel = {
      id: 'appsettings-list-header-left',
      title: this.translate.instant('SETTINGS'),
      mode: true,
      controls: [
        {
          id: 'close',
          imgSrc: 'assets/imgs/back_to_home_btn.svg',
          isDisabled: false,
          align: 'left'
        }
      ]
    }
  }
  onHeaderControlClick(id) {
    if (id === 'close') {
      this.onClosePage();
    }
  }

  ngAfterViewInit() {
    //this.content.resize();
  // this.navService.setChildNavRoot(AppSettingsDetailsComponent, PageName.AppSettingsDetailsComponent, PageName.AppSettingsPageComponent)
    this.uiService.showRightPane = true;

  }

  public isSplitView(): boolean {
    return (window.innerWidth > 500);
  }

  public openDetails(selectedTab) {
    this.selectedOption = selectedTab.id
    if (this.device.shouldBeMobileView) {
      this.uiService.showRightPane = true;
    }
    this.initSettingList();
    this.navService.setChildNavRightPaneView(true);
    if(selectedTab.id === 'SETTINGS_ASSISTED_DIAGNOSTICS') {
      this.navService.setChildNavRoot(AppSettingsDetailsComponent, PageName.AppSettingsDetailsComponent, PageName.AppSettingsPageComponent, { from: PageName.AppSettingsPageComponent });
    } else if(selectedTab.id === 'SETTINGS_RECOMMENDATIONS') {
      this.navService.setChildNavRoot(AppSettingsRecommendationsComponent, PageName.AppSettingsRecommendationsComponent, PageName.AppSettingsPageComponent, { from: PageName.AppSettingsPageComponent });
    } else if(selectedTab.id === 'SETTINGS_GENERAL_SETTINGS') {
      this.navService.setChildNavRoot(GeneralSettingsComponent, PageName.GeneralSettingsComponent, PageName.AppSettingsPageComponent, { from: PageName.AppSettingsPageComponent });
    } else if(selectedTab.id === 'ABOUT') {
      this.navService.setChildNavRoot(AboutPageComponent, PageName.AboutSettingsComponent, PageName.AppSettingsPageComponent, { from: PageName.AppSettingsPageComponent })
    }
  }

  public onClosePage(): void {
    //
    if (this.device.isMobileDevicePortrait) {
      this.uiService.showRightPane = false;
      this.uiService.showNewActivity = false;
    }

    // this.navService.popChildNavCtrlFromStack(ChildNavNames.AppsettingNavigation);
    this.navService.popWithPageTracking()
    .then(() => {
      // Short call feature requires to bring the tab back to short call home
      if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
        this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
        this.uiService.setAgendaTab('shortCallHome');
      }
    }).catch(err => {
      console.log("error is " + err);
    });
    if (this.agendaFooterService.actSegment === 'week') this.events.publish('calendarView:RefreshUI');
  }

  onInput(ev: any) {
    if (ev && ev.target) {
      if (ev.target.value) {
        this.searchedSettingList = this.settingList.filter(setting => {
          if (setting.primaryTextRight) {
            return (setting.primaryTextRight.toLowerCase().includes(this.searchTextModel));
          }
        });
      } else {
        this.searchedSettingList = [];
      }
      this.searchResultHeader.title = this.getSearchSectionHeaderTitle();
    }
  }
}
