import { LocalizationService } from './../../services/localization/localization.service';
import { SelectedSuggestionPillDataModel } from './../../models/search-config-data-model';
import { ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY, ACCOUNT_CONTACT_AFFILIATIONS_REF_ENTITY } from './../../config/dynamic-forms/affiliations-contants';
import { Injectable, NgZone } from '@angular/core';
import { AccountOfflineService } from '../../services/account/account.offline.service';
import { Endpoints } from '../../../config/endpoints.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME, OFFLINE_DB_LINKED_ENTITY_NAME } from '../../services/disk/disk.service';
import { Account, AccountGlobalSearchDTO,  CustomerSpecaility, customerAddress } from '../../classes/account/account.class';
import { AuthenticationService } from '../../services/authentication.service';
import { DB_SYNC_STATE_KEYS, DB_KEY_PREFIXES, DB_ALLDOCS_QUERY_OPTIONS, PREFIX_SEARCH_ENDKEY_UNICODE } from '../../config/pouch-db.config';
import { DeltaService, EntityNames, EntitySyncInfo } from '../delta/delta.service';
import { timeout } from 'rxjs/operators';
import { DynamicForm, FormType, ControlDataType, Control } from '@omni/classes/dynamic-form/dynamic-form.class';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { DynamicFormType } from '@omni/models/dynamic-form-component.model';
import { DefaultAccountDisplayForm, RequiredAccountAttributes, AccountSearchConfigExcludedAttributes, AccountsPositionFilter, FETCH_ACCOUNT_DEFAULT_LINK_ENTITES, ACCOUNT_EVENT_FETCH_QUERIES, ACCOUNT_LIST_PERIOD_TAG } from '@omni/config/dynamic-forms/default-account-display';
import { isBefore, differenceInHours } from 'date-fns';
import { SearchConfigService } from '@omni/services/search/search-config.service';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { searchIndexDataModel } from '@omni/models/search-config-data-model';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import * as XML2JS from 'xml2js';
import { TranslateService } from '@ngx-translate/core';
import { uniqBy, isEmpty, intersectionBy, xorBy } from 'lodash';
import { SecondaryInfoEntityName } from '@omni/classes/sec-info-config/sec-info.class';
import { SecInfoConfigDataService } from '../sec-info-config/sec-info-config-data-service';
import { getSearchSuggestionsData } from '../../utility/global-search.utility';
import {MyAssistantService, NOTIFICATION} from "@omni/services/my-assistant/my-assistant.service";
import {IndNotificationDataModel} from "@omni/models/indNotificationDataModel";
import { EventActivity } from '@omni/classes/events-tool/event.class';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { DeviceService } from '@omni/services/device/device.service';
import { EntityTag, TagEntityType, TagStateCode, UserTag, UserTagService } from '@omni/services/user-tag/user-tag.service';
import * as moment from 'moment';
import { CONTACT_FETCH_QUERIES } from '@omni/config/fetch-xml/contact-fetchXMLs';
import _ from 'lodash';
import { APPROVAL_STATUS, SelectableLinkedEntity } from '@omni/classes/contact/contact.class';
import { FETCH_ACCOUNTS_LINK_ENTITES_BY_ACCOUNTID } from '../../config//fetch-xml/account/account.fetch-xml.config';
import { SurveyCategory } from '@omni/classes/customer-assessment/assessment-template.class';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { ContactOfflineService, ContactTag, CustomerTagStateCode, UserTagForContact } from '@omni/services/contact/contact.service';
import { ContactDataService } from '../contact/contact.data.service';
import { PresentationDataService } from '../presentation/presentation.data.service';
import { ResourceDataService } from '../resource/resource.data.service';

@Injectable({
  providedIn: 'root'
})
export class AccountDataService {

    // list of all linked entites from form
    public accountLinkEntities: Array<string> = [];
    private _advancedSearchConfigServiceWorker;
    private accountsNotificationModel: IndNotificationDataModel;


    constructor(
        private disk: DiskService,
        private http: HttpClient,
        private accountOfflineService: AccountOfflineService,
        private authService: AuthenticationService,
        private deltaService: DeltaService,
        private dynamicFormsService: DynamicFormsService,
        private searchConfigService: SearchConfigService,
        private dynamics: DynamicsClientService,
        private ngZone: NgZone,
        private translate: TranslateService,
        private secondaryInfoService: SecInfoConfigDataService,
        private localizationService: LocalizationService,
        private myAssistantService: MyAssistantService,
        private readonly device: DeviceService,
        private readonly userTagService: UserTagService,
        private contactOfflineService: ContactOfflineService,
        private customerAssessService: CustomerAssessService,
        private dateService : DateTimeFormatsService,
        private readonly contactService: ContactDataService,
        private readonly presentationService: PresentationDataService,
        private readonly resourceService: ResourceDataService,
    ) {
        this._initAdvancedSearchServiceWorker();
    }


    /**
     *    We need to either do a full sync where we download everything or a partial delta sync.
     *
     * @returns {boolean}
     * @memberof AccountDataService
     */
    public async syncAccounts(forceFullSync: boolean = false, loadFromDbOnly = false): Promise<void> {
        let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ACCOUNT);
        const isInitialSync = !syncState || !syncState.lastUpdatedTime;
        let doFullSync = forceFullSync || isInitialSync;
        const newLastUpdatedTime = new Date().getTime();

        const accountSyncInfo: EntitySyncInfo = {
            entityName: EntityNames.account,
            totalFailed: 0,
            totalSynced: 0,
            errors: [],
            syncStatus: true
        };

        if(loadFromDbOnly){
          await this._doAccountsConfiguredDisplaySync(accountSyncInfo,[],doFullSync,loadFromDbOnly,syncState.lastUpdatedTime);
        }else{
          // first, getting all account interaction date and type, so it will mapped to account data
        let interactionResponse = await this.syncInteractionDataForAccount(doFullSync, syncState.lastUpdatedTime);

        //If isInitialSync, we clearly got nothing, load it up sonny, standard old school method of replace everything
        // if (isInitialSync || doFullSync) {
        //     await this._doInitialSync(accountSyncInfo, interactionResponse);
        // } else {
        //     //Delta baby
        //     await this._doDeltaSync(syncState.lastUpdatedTime, accountSyncInfo, interactionResponse);
        // }
        await this._doAccountsConfiguredDisplaySync(accountSyncInfo,interactionResponse,doFullSync,loadFromDbOnly,syncState.lastUpdatedTime);

        // Add sync info to the service for tracking
        this.deltaService.addEntitySyncInfo(accountSyncInfo);

        // // Done sync. Update sync state.
        if (accountSyncInfo.syncStatus) {
            syncState.lastUpdatedTime = newLastUpdatedTime;
            await this.disk.updateSyncState(syncState);
        }
        this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_RECENT_SEARCHES, true).then((doc)=>{
          if(doc && doc.raw){
            this.accountOfflineService.recentSearches = doc.raw
          }
          else {
            this.accountOfflineService.recentSearches = [];
          }
        })
        return;
        }

    }

    /**
     *  This will perform an initial sync and map it standard.
     *     Easy bb
     *
     * @private
     * @memberof AccountDataService
     */
    // private async _doInitialSync(accountSyncInfo: EntitySyncInfo, accountInteraction = []) {
    //     let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accounts.GET_BULK_ACCOUNTS;
    //     const positions = this.authService.user.positions.map((o)=>{
    //         return o.ID
    //     })
    //     url = url.replace('{{positionIDs}}',positions.toString())
    //     let response: any;

    //     try {
    //         response = await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    //     } catch (error) {
    //         console.error('syncAccounts: _doInitialSync: ', error);
    //         //accountSyncInfo.errors = '[account][error]' + error ? error.errorMessage : '';
    //         this.deltaService.addSyncErrorToEntitySyncInfo(accountSyncInfo, url, error);
    //         return;
    //     }

    //     if (response && Array.isArray(response)) {
    //         await this.mapAccountWithInteraction(response, accountInteraction);
    //         this.accountOfflineService._mapAccounts(response);
    //         accountSyncInfo.totalSynced = response.length;
    //     }
    // }

    // /**
    //  *    This is only going to give me entities that have changed, we need to combine this result set with our offline data set.
    //  *
    //  * @private
    //  * @param {number} lastUpdated
    //  * @memberof AccountDataService
    //  */
    // private async _doDeltaSync(lastUpdated: number, accountSyncInfo: EntitySyncInfo, accountInteraction = []) {
    //     let url: string = this.authService.userConfig.activeInstance.entryPointUrl +
    //         Endpoints.accounts.GET_BULK_DELTA.replace('{lastUpdatedTime}', lastUpdated.toString());
    //     const positions = this.authService.user.positions.map((o)=>{
    //         return o.ID
    //     })
    //     url = url.replace('{{positionIDs}}',positions.toString())
    //     let response: any;

    //     try {
    //         response = await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER).toPromise();
    //     } catch (error) {
    //         console.error('syncAccounts: _doDeltaSync: ', error);
    //         // accountSyncInfo.errorMessage = '[account][error]' + error ? error.errorMessage : '';
    //         this.deltaService.addSyncErrorToEntitySyncInfo(accountSyncInfo, url, error);
    //     }
    //     // updating delta account with inteaction date and type
    //     await this.mapAccountWithInteraction(response, accountInteraction);

    //     let offlineSaved = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT, true);

    //     // updating offline saved account with inteaction date and type
    //     await this.mapAccountWithInteraction(offlineSaved.raw, accountInteraction);

    //     if (Array.isArray(response) && response.length > 0) {
    //         // replaceIDSet = response.map(rawResponse => rawResponse.accountid);
    //         response.forEach(row =>{
    //             let foundSavedIndex = offlineSaved && offlineSaved.raw && Array.isArray(offlineSaved.raw)
    //                                     ? offlineSaved.raw.findIndex(savedAccount => savedAccount.accountid === row.accountid) : -1;
    //             if (foundSavedIndex >= 0) {
    //                 if(row['statecode'] == 0 && row['coveragePositions'] != null && (row.hasOwnProperty('track_action') && row.track_action !== TrackAction.Deleted)){
    //                     if(row['isNew']) {
    //                       this.deltaService.deltaRecordsDTO.accounts.push(row.accountid);
    //                     }
    //                     //updating row with interaction date
    //                     let foundDbAccount = offlineSaved.raw[foundSavedIndex];
    //                     row['interactionDate'] = foundDbAccount['interactionDate'];
    //                     row['interactionType'] = foundDbAccount['interactionType'];
    //                     offlineSaved.raw[foundSavedIndex] = row;
    //                 }else{
    //                     offlineSaved.raw.splice(foundSavedIndex,1);
    //                 }
    //             }else{
    //                 if(row['statecode'] == 0 && row['coveragePositions'] != null && (row.hasOwnProperty('track_action') && row.track_action !== TrackAction.Deleted)){
    //                     row['isNew'] = true;
    //                     // Push only the accounts are created and do not include deleted/inactive records
    //                     this.deltaService.deltaRecordsDTO.accounts.push(row.accountid);
    //                     offlineSaved.raw.push(row);
    //                 }
    //             }

    //         });

    //         this.disk.updateDocWithIdAndRev(offlineSaved);
    //         accountSyncInfo.totalSynced = response.length;
    //     } else {
    //       //display offline saved new accouns which were not visited bfr
    //       if(offlineSaved && offlineSaved.raw && Array.isArray(offlineSaved.raw)) {
    //         let newAccounts =offlineSaved.raw.filter((offlineAccount) =>{
    //             return offlineAccount.isNew;
    //         });
    //         if(newAccounts && newAccounts.length > 0 ){
    //           console.log("Not visited new accounts", newAccounts);
    //           newAccounts.forEach(account => {
    //             this.deltaService.deltaRecordsDTO.accounts.push(account['accountid']);
    //           });
    //         }
    //       }
    //     }

    //     this.accountOfflineService._mapAccounts(offlineSaved.raw);
    // }

    private async _getAccountsConfiguredDisplayFetchXML(formDef, formType, forceFullSync, lastUpdatedTime, accountId,fetchMultilingualFields:boolean = false) {
        let parentEntityFetchXml = fetchQueries.configuredFormFetchXMLs.fetchConfiguredFormEntity;
        //create attributes
        let parentEntityAttributesStr = '<attribute name="accountid"/>';
        // Default Parent level Attributes

        RequiredAccountAttributes.forEach(attr => {
            parentEntityAttributesStr += '<attribute name="' + attr + '"/>'
        });

        if (this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_VEEVA_SELECTION) || this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNTS_VEEVA_GLOBAL_SEARCH)) {
          parentEntityAttributesStr += '<attribute name="omniveev_isusedinonetimemeeting"/>';
          parentEntityAttributesStr += '<attribute name="omniveev_recordstate"/>';
        }

        parentEntityAttributesStr += '<attribute name="indskr_hcovid"/>';

        let linkEntityAttributesArray = [];
        const entityNameCurrentForm: string = formDef.entityName;
        formDef.metadata.forEach(tab => {
            tab.controls.forEach(control => {
                if (control.dataType) {
                  let linkedAttrFetchXML = '';
                    if(control.attributeName){
                      if(fetchMultilingualFields && control.lookupEntityPrimaryId && control.lookupEntityPrimaryId == "omnione_onekeycodeslabelsid"){
                        // linkedAttrFetchXML = `
                        //   <link-entity name="omnione_onekeycodeslabels" from="omnione_onekeycodeslabelsid" to="${control.attributeName}" visible="false" link-type="outer">
                        //     <attribute name="${multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]}" alias="${control.attributeName}_value"/>
                        //     ${this.authService.user.localeId != '1033' ? `<attribute name="omnione_en_long_label" alias="${control.attributeName}_fallbackvalue"/>`:''}
                        //     <attribute name="omnione_onekeycodeslabelsid" alias="_${control.attributeName}_value"/>
                        //   </link-entity>
                        // `;
                        // parentEntityAttributesStr += linkedAttrFetchXML;
                        this.localizationService.multiLingualAttributes.push(control.attributeName);
                        parentEntityAttributesStr += '<attribute name="' + control.attributeName + '"/>';
                      }else{
                        parentEntityAttributesStr += '<attribute name="' + control.attributeName + '"/>';
                      }
                    }
                    if (control.dataType != ControlDataType.DateTimeType && control.dataType != ControlDataType.CustomerType
                        && control.dataType != ControlDataType.MemoType
                        && control.dataType != ControlDataType.WebResource && control.dataType != ControlDataType.MultiSelectPicklistType
                        && !this.searchConfigService.accountConfiguredSearchIndexConfig.some(conf => conf.categoryRelativePath == control.attributeName)
                        && !AccountSearchConfigExcludedAttributes.some(a=> a == control.attributeName)) {
                        let searchCategory: searchIndexDataModel = {
                            categoryName: this.dynamicFormsService.getSearchConfigDisplayText(control.displayNames, formType, control.isCustom, control.attributeName, entityNameCurrentForm),
                            categoryRelativePath: control.attributeName,
                            controlDataType: control.dataType,
                            values: [],
                            mappingValues: [],
                            parentEntity: 'account',
                            entity: "",
                            linkEntityFetchXML: linkedAttrFetchXML,
                            isBooleanTypeCategory: control.dataType == ControlDataType.BooleanType,
                            numOfLinkEntity: 0,
                            isMultilingualLookup: control.lookupEntityPrimaryId && control.lookupEntityPrimaryId == "omnione_onekeycodeslabelsid",
                        }
                        this.searchConfigService.accountConfiguredSearchIndexConfig.push(searchCategory);
                    }
                }else if (!control.dataType && control.subgrid) {
                    linkEntityAttributesArray.push(control);
                }
            });
        });

        parentEntityFetchXml = parentEntityFetchXml.replace('{parentLevelAttributes}', parentEntityAttributesStr);
        parentEntityFetchXml = parentEntityFetchXml.replace('{parentEntityName}', formDef.entityName);
        parentEntityFetchXml = parentEntityFetchXml.replace('{linkEntityPlaceholder}', '');

        const secondaryFetchXml = this.secondaryInfoService.SecondaryInfoFetchXML(SecondaryInfoEntityName.Account);
        parentEntityFetchXml = parentEntityFetchXml.replace('{secondaryInfoPlaceholder}', secondaryFetchXml || '' );

        let hourDifference;
        if(forceFullSync) {
          let initSyncFilter = '';
          if(!accountId){
            initSyncFilter = `<filter type="and">
                <condition attribute="statecode" operator="eq" value="0" />
              </filter>`;
          }
          parentEntityFetchXml = parentEntityFetchXml.replace('{deltasyncFilterLevel1}',initSyncFilter);
        }else {
            let now = new Date();
            hourDifference = differenceInHours(
                now,
                new Date(lastUpdatedTime)
            )
            //add one to make sure we take care of fractional difference in hours
            hourDifference += 1;
            let lastModifiedFilter = `<filter type="or">
              <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" />
              <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" entityname="indskr_customerpositionfilter"/>
            </filter>`

            if(accountId) {
                lastModifiedFilter = `<filter type="or">
                    <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" />
                </filter>`
            }
            parentEntityFetchXml = parentEntityFetchXml.replace('{deltasyncFilterLevel1}', lastModifiedFilter);
        }

        let positionFilterString = '';
        if(accountId) {
            let accountIdFilter = `<filter type="and"><condition attribute="accountid" operator="eq" value="` + accountId + `" /></filter>`
            parentEntityFetchXml = parentEntityFetchXml.replace('{positionFilterlevel1}', '');
            parentEntityFetchXml = parentEntityFetchXml.replace('{customFilterLevel1}', accountIdFilter);
        } else {

            //create position filter
            let positionIds = this.authService.user.positions.map(o => {
                return o.ID
            });
            let positionIDValues = ''
            positionIds.forEach(p => {
                positionIDValues += '<value>' + p + '</value>'
            })
            positionFilterString = AccountsPositionFilter.split('{positionIDs}').join(positionIDValues);
            const approvalStatusFilter = this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_VISIBILITY_APPROVAL_BASED)
            ? `<filter type="or">
                <condition attribute="indskr_approvalstatus" operator="eq" value="548910002" />
                <condition attribute="indskr_approvalstatus" operator="null"/>
              </filter>`
            : '';
            const filterOutFromAppSyncFilter = this.authService.hasFeatureAction(FeatureActionsMap.FILTER_OUT_FROM_APP_SYNC)
            ? `<filter type="or">
                  <condition attribute="indskr_filteroutfromappsync" value="1" operator="ne"/>
                  <condition attribute="indskr_overriddefilteroutfromappsync" value="1" operator="eq"/>
              </filter>` 
            : "";
            positionFilterString = positionFilterString.replace('{approvalStatusFilter}', approvalStatusFilter);
            positionFilterString = positionFilterString.replace('{filterOutFromAppSyncFilter}', filterOutFromAppSyncFilter);
            parentEntityFetchXml = parentEntityFetchXml.replace('{positionFilterlevel1}', positionFilterString);
            parentEntityFetchXml = parentEntityFetchXml.replace('{customFilterLevel1}', '');

        }

        return { parentEntityFetchXml, linkEntityAttributesArray, hourDifference, positionFilterString };
    }
    private async _getDynamicFormAndType(): Promise<{ formDef: DynamicForm, formType: DynamicFormType }> {
      let accountForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("account", FormType.DISPLAYFORM);
      let formType = DynamicFormType.CONFIGUREDFORM;

      if (!accountForm) {
          accountForm = new DynamicForm(DefaultAccountDisplayForm['value'][0]);
          formType = DynamicFormType.DEFAULTFORM;
      }
      let formDef = accountForm;

      return { formDef, formType };
    }

    async _fetchGlobalSearchedAccountDetailsById(accountId: string) {
      // Fetch Account entity level details
      const fetchGlobalSearchAccountDetails = true;
      const fetchMultilingualFields:boolean = this.authService.user.securityRoles.some(a=> a.name == 'iO OneKey User' || a.name == 'iO OneKey Admin');
      let { formDef, formType } = await this._getDynamicFormAndType();
      let { parentEntityFetchXml, linkEntityAttributesArray } = await this._getAccountsConfiguredDisplayFetchXML(formDef, formType, true, null, accountId,fetchMultilingualFields);
      let response;

      try {
        response = await this.dynamics.executeFetchQuery('accounts',parentEntityFetchXml);
        if (Array.isArray(response) && response.length > 0) {
          response = response[0];
          let linkEntities = {};

          // Fetch extra link entity data
          if (Array.isArray(linkEntityAttributesArray)) {
            for (let i = 0; i < linkEntityAttributesArray.length; i++) {
              const linkEntity = linkEntityAttributesArray[i];
              const fetchMultilingualFields:boolean = this.authService.user.securityRoles.some(a=> a.name == 'iO OneKey User' || a.name == 'iO OneKey Admin');
              let { fetchXML } = this._getAccountsLinkedEntityFetchXML(linkEntity, accountId, null, null, formType,fetchMultilingualFields,fetchGlobalSearchAccountDetails);
              let linkEntityResponse: any[];

              let pagingInfo: any = null;
              let rawAllData = [];
      
              do {
                try {
                  let response = await this.dynamics.executeFetchXml(
                    'accounts',
                    fetchXML,
                    undefined,
                    pagingInfo?.nextPage ?? 0,
                    pagingInfo?.cookie ?? null,
                  );
            
                  if (response) {
                    pagingInfo = response.PagingInfo ?? null;
                    
                    if (Array.isArray(response.value)) {
                      rawAllData.push(...response.value);
                    }
                  }
            
                } catch (error) {
                  console.log("Error retreiving linked entity data: ", error);
                  pagingInfo = null;
                  rawAllData = null;
                }
              } while (pagingInfo !== null);
            
              // mapping responses
              linkEntityResponse = rawAllData;          

              let dbKey: string = '';
              if (linkEntity.subgrid.referencingEntity === ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY) {
                dbKey = linkEntity.subgrid.relationshipName;
              } else {
                dbKey = linkEntity.subgrid.referencingEntity;
              }

              if (dbKey) {
                linkEntities[DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY + dbKey] = [...linkEntityResponse];
              }
            }
          }
          //commenting default entity fetch part for global search contacts. only configure entities are required to generate display
          // Fetch default link entity data
          // for (let i = 0; i < FETCH_ACCOUNT_DEFAULT_LINK_ENTITES.length; i++) {
          //   const linkEntity = FETCH_ACCOUNT_DEFAULT_LINK_ENTITES[i];
          //   if(!linkEntity.isFetch || linkEntityAttributesArray.find(l=> l.subgrid.referencingEntity == linkEntity.entityName)) continue;
          //   let fetchXML = this._getAccountsDefaultLinkEntityFetchXML(linkEntity, null, null);
          //   let linkEntityResponse: any[];

          //   try {
          //     linkEntityResponse = await this.dynamics.executeFetchQuery('accounts', fetchXML);
          //     linkEntities[DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY + linkEntity.entityName] = [...linkEntityResponse];
          //   } catch (error) {
          //     console.error('_fetchAccountDetailsById: default link entity fetch', error);
          //   }
          // }

          response['globalSearchedDataLinkEntities'] = linkEntities;
        }
      } catch (error) {
        console.error('_fetchAccountDetailsById: entity info fetch', error);
      }

      return response;
    }

    public async _doAccountsConfiguredDisplaySync(accountSyncInfo,interactionResponse,doFullSync,loadFromDB,lastUpdatedTime, accountId = null){
        this.accountLinkEntities = [];
        let { formDef, formType } = await this._getDynamicFormAndType();
        let forceFullSync = doFullSync;
        let offlineAccountRawData = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT);
        const fetchMultilingualFields:boolean = this.authService.user.securityRoles.some(a=> a.name == 'iO OneKey User' || a.name == 'iO OneKey Admin');
        if(!accountId){
          if(fetchMultilingualFields && offlineAccountRawData && offlineAccountRawData.lastLocaleId && offlineAccountRawData.lastLocaleId != this.authService.user.localeId){
            this.localizationService.fullSyncRequiredForOnekeyCodes = true;
          }
          if(offlineAccountRawData && offlineAccountRawData.formType && offlineAccountRawData.formType == DynamicFormType.DEFAULTFORM && formType == DynamicFormType.CONFIGUREDFORM){
            forceFullSync = true;
            this.localizationService.fullSyncRequiredForOnekeyCodes = true;
            this.searchConfigService.resetConfiguredConfig('Accounts');
          }else if(offlineAccountRawData && offlineAccountRawData.formType && offlineAccountRawData.formType == DynamicFormType.CONFIGUREDFORM && formType == DynamicFormType.DEFAULTFORM){
            forceFullSync = true;
            this.localizationService.fullSyncRequiredForOnekeyCodes = true;
            this.searchConfigService.resetConfiguredConfig('Accounts');
          }else if(offlineAccountRawData && offlineAccountRawData.formType && offlineAccountRawData.formType == DynamicFormType.CONFIGUREDFORM && offlineAccountRawData.lastInitialSyncTime && isBefore(new Date(offlineAccountRawData.lastInitialSyncTime),new Date(formDef.modiefiedOn))){
            forceFullSync = true;
            this.localizationService.fullSyncRequiredForOnekeyCodes = true;
            this.searchConfigService.resetConfiguredConfig('Accounts');
          }
        }

        forceFullSync = forceFullSync || this.secondaryInfoService.isAccountSecInfoUpdated;

        if(!this.searchConfigService.isConfigInitiated){
            this.searchConfigService.initSearchConfigs();
            this.searchConfigService.isConfigInitiated = true;
        }
        if((forceFullSync || !accountId)){
          this.searchConfigService.configUpdateRequired = true;
        }

        let { parentEntityFetchXml, linkEntityAttributesArray, hourDifference, positionFilterString } = await this._getAccountsConfiguredDisplayFetchXML(formDef, formType, forceFullSync, lastUpdatedTime, accountId,fetchMultilingualFields);

        let deletedAccountsRes;
        if(loadFromDB){
          await Promise.all([
            this.fetchAccountsLinkedEntityData(linkEntityAttributesArray, positionFilterString, hourDifference, formType, accountId, loadFromDB, fetchMultilingualFields, forceFullSync),
            this.fetchAccountsDefaultLinkEntityData(positionFilterString, hourDifference, linkEntityAttributesArray, accountId, loadFromDB)
          ])
          this.accountOfflineService._mapAccounts(offlineAccountRawData.raw);
          if (this.searchConfigService.configUpdateRequired) {
            this.searchConfigService.updateSearchConfigsForSelectedLanguage();
            this.searchConfigService.configUpdateRequired = false;
          }
        } else {
          return await this.dynamics.executeFetchQuery('accounts', parentEntityFetchXml).then(async (res) => {
            // let dataToSave = res;
            //Incase of linkEntity in secondary info cartisias product is available in response, hence iterate res and take which has all values in res
            let dataToSave = [];
            for(let val of res) {
              const index = dataToSave.findIndex(acc => acc.accountid === val.accountid);
              if(index >= 0) {
                if(_.entries(val).length > _.entries(dataToSave[index]).length) {
                  dataToSave[index] = val;
                }
              } else {
                dataToSave.push(val);
              }
            }
            res = dataToSave;
            let lastInitialSyncTime;
            if (!accountId) {
              accountSyncInfo.totalSynced = res.length;
            }
            const accountTags = _.isEmpty(res) ? null : await this.fetchAccountListPeriodTags(forceFullSync, lastUpdatedTime, accountId);
            if ((!forceFullSync || accountId) && Array.isArray(res)) {

              let localRaw = [...offlineAccountRawData.raw];
              if (!accountId) {
                let posID = this.authService.user.xPositionID
                deletedAccountsRes = await this.fetchDeletedFromTrackChange("account", hourDifference, posID);
                if (deletedAccountsRes && Array.isArray(deletedAccountsRes)) {
                  let MDConfig = this.searchConfigService.accountConfiguredSearchIndexConfig.find(o => o.categoryName == 'MDM ID');
                  deletedAccountsRes.forEach(dRes => {
                    if (dRes) {
                      let MDMID;
                      let idx = localRaw.findIndex(a => a && a.hasOwnProperty('accountid') && a['accountid'] == dRes["indskr_entityid"]);
                      if (idx >= 0) {
                        MDMID = localRaw[idx].indskr_mdmid
                        localRaw.splice(idx, 1);
                      }
                      if (MDConfig && MDMID) {
                        MDConfig.values = MDConfig.values.filter(v => v != MDMID)
                      }
                    }
                  });
                }
              }

              lastInitialSyncTime = offlineAccountRawData.lastInitialSyncTime;
              res.forEach(rawRes => {
                if (rawRes) {
                  let idx = localRaw.findIndex(a => a.accountid == rawRes.accountid);
                  if (idx >= 0) {
                    if (rawRes['statecode'] == 1 && (rawRes['statuscode'] == 2 || rawRes['statuscode'] == 548910003 || rawRes['statuscode'] == 548910010 || rawRes['statuscode'] == 548910011 || rawRes['statuscode'] == 548910012 || rawRes['statuscode'] == 548910013)) {
                      localRaw.splice(idx, 1);
                      if (this.accountOfflineService.selected && this.accountOfflineService.selected.id == rawRes.accountid && !this.accountOfflineService.refreshUIofCurrentSelectedAccountFlag) {
                        this.accountOfflineService.refreshUIofCurrentSelectedAccountFlag = true;
                      }
                    } else {
                      let foundDbAccount = localRaw[idx];
                      rawRes['interactionDate'] = foundDbAccount['interactionDate'];
                      rawRes['interactionType'] = foundDbAccount['interactionType'];
                      if (this.accountOfflineService.selected && this.accountOfflineService.selected.id == rawRes.accountid && !this.accountOfflineService.refreshUIofCurrentSelectedAccountFlag) {
                        this.accountOfflineService.refreshUIofCurrentSelectedAccountFlag = JSON.stringify(localRaw[idx]) != JSON.stringify(rawRes);
                      }
                      localRaw[idx] = rawRes;
                    }
                  } else {
                    if (rawRes['statuscode'] != 2 && rawRes['statuscode'] != 548910003 && rawRes['statuscode'] != 548910010 && rawRes['statuscode'] != 548910011 && rawRes['statuscode'] != 548910012 && rawRes['statuscode'] != 548910013) {
                      localRaw.push(rawRes);
                      rawRes['isNew'] = true;
                      if (this.accountOfflineService.selected && this.accountOfflineService.selected.id == rawRes.accountid && !this.accountOfflineService.refreshUIofCurrentSelectedAccountFlag) {
                        this.accountOfflineService.refreshUIofCurrentSelectedAccountFlag = true;
                      }
                      if (!offlineAccountRawData.raw.some(acc => acc.accountid === rawRes['accountid'])) {
                        this.deltaService.deltaRecordsDTO.accounts.push(rawRes['accountid']);
                      }
                    }
                  }
                }
              });
              if (this.deltaService.deltaRecordsDTO.accounts.length) {
                let displayName = this.deltaService.deltaRecordsDTO.accounts.length == 1 ? this.translate.instant("ACCOUNT") : this.translate.instant("ACCOUNTS");
                let showCount = this.deltaService.deltaRecordsDTO.accounts.length == 1 ? '' : this.deltaService.deltaRecordsDTO.accounts.length;
                this.accountsNotificationModel = {
                  type: NOTIFICATION.NEW_ACCOUNTS_NOTIFICATION,
                  name: this.translate.instant("NEW_ACCOUNTS_NOTIFICATION", {count: showCount, account: displayName}),
                  DateTime: Date.now(),
                  id: NOTIFICATION.NEW_ACCOUNTS_NOTIFICATION + this.deltaService.deltaRecordsDTO.accounts[0],
                  data: {
                    data: this.deltaService.deltaRecordsDTO.accounts
                  },
                  icon: 'assets/imgs/accounts_notification.svg',
                  isRed: false,
                  params: {count: showCount, account: displayName}
                }
                this.myAssistantService.saveNotificationToDisk(this.accountsNotificationModel); 
              }
              dataToSave = localRaw;
            } else {
              lastInitialSyncTime = new Date().getTime();
            }
            if (interactionResponse && dataToSave && dataToSave.length > 0) {
              await this.mapAccountWithInteraction(dataToSave, interactionResponse);
            }
            if (dataToSave && dataToSave.length > 0) {
              dataToSave = dataToSave.filter(con => !(con['statecode'] == 1 && (con['statuscode'] == 2 || con['statuscode'] == 548910003 || con['statuscode'] == 548910010 || con['statuscode'] == 548910011 || con['statuscode'] == 548910012 || con['statuscode'] == 548910013)));
              //If contact source is empty, mark it as business contacts
              dataToSave.forEach(data => {
                if (data.indskr_accountsourcetype == null) {
                  data['indskr_accountsourcetype'] = 548910000;
                  data['indskr_accountsourcetype@OData.Community.Display.V1.FormattedValue'] = "Business";
                  data['indskr_accountsourcetype_Formatted'] = "Business";
                }
                if (accountTags) {
                  if (accountTags[data['accountid']]) {
                    const tagLabels = [];
                    accountTags[data['accountid']].forEach(tag => {
                      if (tag['tagLabel']) {
                        tagLabels.push(tag['tagLabel']);
                      }
                    });
                    data['tagLabels'] = tagLabels;
                  }
                }
              });
            }
            await this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT, doc => {
              doc = {
                raw: dataToSave,
                lastInitialSyncTime: lastInitialSyncTime,
                lastLocaleId: this.authService.user.localeId,
                formType: formType,
              };
              return doc;
            }).catch(error => console.error('Save Forms to DB error: ', error));
            await Promise.all([this.fetchAccountsLinkedEntityData(linkEntityAttributesArray, positionFilterString, hourDifference, formType, accountId, loadFromDB, fetchMultilingualFields, forceFullSync),
            this.fetchAccountsDefaultLinkEntityData(positionFilterString, hourDifference, linkEntityAttributesArray, accountId, loadFromDB)]);

            this.accountOfflineService._mapAccounts(dataToSave);

            if (this.searchConfigService.configUpdateRequired) {
              this.searchConfigService.updateSearchConfigsForSelectedLanguage();
              this.searchConfigService.configUpdateRequired = false;
            }
            // this.fetchEmailsFromDBAndMapToContacts(this.contacts);
            //await this.fetchAddressesFromDBAndMapToContacts(this.contacts);

            return res;
          }).catch(err => {
            if (!accountId) {
              this.deltaService.addSyncErrorToEntitySyncInfo(accountSyncInfo, 'accounts', err);
            }
          })
        }

    }

    private async fetchAccountListPeriodTags(forceFullSync, lastUpdatedTime, accountId) {
      if (!this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT)) return [];
      try {
        let fetchXml = ACCOUNT_LIST_PERIOD_TAG;
        const todayDate = moment().format("YYYY-MM-DD");
        let hourDifference;
        if (forceFullSync) {
          let initSyncFilter = '';
          if (!accountId) {
            initSyncFilter = `<filter type="and">
                <condition attribute="statecode" operator="eq" value="0" />
              </filter>`;
          }
          fetchXml = fetchXml.replace('{deltasyncFilterLevel1}', initSyncFilter);
        } else {
          let now = new Date();
          hourDifference = differenceInHours(
            now,
            new Date(lastUpdatedTime)
          )
          //add one to make sure we take care of fractional difference in hours
          hourDifference += 1;
          let lastModifiedFilter = `<filter type="or">
              <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" />
              <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" entityname="indskr_customerpositionfilter"/>
            </filter>`

          if (accountId) {
            lastModifiedFilter = `<filter type="or">
                    <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" />
                </filter>`
          }
          fetchXml = fetchXml.replace('{deltasyncFilterLevel1}', lastModifiedFilter);
        }

        let positionFilterString;
        if (accountId) {
          let accountIdFilter = `<filter type="and"><condition attribute="accountid" operator="eq" value="` + accountId + `" /></filter>`
          fetchXml = fetchXml.replace('{positionFilterlevel1}', '');
          fetchXml = fetchXml.replace('{customFilterLevel1}', accountIdFilter);
        } else {

          //create position filter
          let positionIds = this.authService.user.positions.map(o => {
            return o.ID
          });
          let positionIDValues = ''
          positionIds.forEach(p => {
            positionIDValues += '<value>' + p + '</value>'
          })
          positionFilterString = AccountsPositionFilter.split('{positionIDs}').join(positionIDValues);
          const approvalStatusFilter = this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_VISIBILITY_APPROVAL_BASED)
            ? `<filter type="or">
                <condition attribute="indskr_approvalstatus" operator="eq" value="548910002" />
                <condition attribute="indskr_approvalstatus" operator="null"/>
              </filter>`
            : '';
          positionFilterString = positionFilterString.replace('{approvalStatusFilter}', approvalStatusFilter);
          fetchXml = fetchXml.replace('{positionFilterlevel1}', positionFilterString);
          fetchXml = fetchXml.replace('{customFilterLevel1}', '');
        }
        fetchXml = fetchXml.replace('{secondaryInfoPlaceholder}', '').split('{date}').join(todayDate).replace('{positionId}', this.authService.user.xPositionID);;
        const accountTags = await this.dynamics.executeFetchQuery('accounts', fetchXml);
        return _.groupBy(accountTags, (item) => item['accountid']);
      } catch (error) {
        console.error("Failed to fetch account list period tags: ", error);
      }
      return [];
    }

    async fetchDeletedFromTrackChange(entity, hourDifference: any,primaryPositionID?:string ){
      try{
          let res = [];
          let fetchXML = `
              <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="true">
                  <entity name="indskr_trackchange">
                      <attribute name="createdon"/>
                      <attribute name="indskr_action"/>
                      <attribute name="indskr_entityid"/>
                      <filter type="and">
                          <condition attribute="createdon" operator="last-x-hours" value="`+ hourDifference+`" />
                          <condition value="` + entity  + `" attribute="indskr_entityname" operator="eq" />
                          <condition value="548910001" attribute="indskr_action" operator="eq" />
                          {positionIDFilter}
                      </filter>
                  </entity>
              </fetch>`
              if(!primaryPositionID){
                fetchXML = fetchXML.replace('{positionIDFilter}','')
              }
              else{
                let positionCondition = '<condition value="'+primaryPositionID+'" attribute="indskr_positionid" operator="eq" />'
                fetchXML = fetchXML.replace('{positionIDFilter}',positionCondition);
              }
          res = await this.dynamics.executeFetchQuery('indskr_trackchanges',fetchXML)
          return res
      } catch (error){
          console.log(error)
      }
    }

    private _getAccountsLinkedEntityFetchXML(linkEntity, accountid, positionFilterString, hourDifference, dynamicFormType,fetchMultilingualFields:boolean = false,isFetchGlobalSearchAccountDetails = false) {
        let searchCategory: searchIndexDataModel;

        this.accountLinkEntities.push(linkEntity.subgrid.referencingEntity);
        //#region fetchxml replace
        let fetchXML = fetchQueries.configuredFormFetchXMLs.fetchConfiguredFormEntityPaging;
        fetchXML = fetchXML.replace('{parentEntityName}', 'account');
        fetchXML = fetchXML.replace('{parentLevelAttributes}', '<attribute name="accountid"/>');
        fetchXML = fetchXML.replace('{linkEntityPlaceholder}', fetchQueries.configuredFormFetchXMLs.linkEntityPlaceholder);
        fetchXML = fetchXML.replace('{linkEntityName}', linkEntity.subgrid.referencingEntity);
        fetchXML = fetchXML.replace('{linkEntityAttr}', linkEntity.subgrid.referencingAttribute);
        fetchXML = fetchXML.replace('{prentEntityAttr}', linkEntity.subgrid.parentAttribute);
        fetchXML = fetchXML.replace('{linkEntityAlias}', linkEntity.subgrid.referencingEntity);
        fetchXML = fetchXML.replace('{customFilterLevel2}', '');
        //fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', '');
        //fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', '');
        fetchXML = fetchXML.replace('{secondaryInfoPlaceholder}','');

        // Filter---

        // Address---> isPrimary

        if(accountid) {
            let accountidfilter = `<filter type="and"><condition attribute="accountid" operator="eq" value="` + accountid + `" /></filter>`
            fetchXML = fetchXML.replace('{customFilterLevel1}', accountidfilter);
            fetchXML = fetchXML.replace('{positionFilterlevel1}', '');
        } else {
            fetchXML = fetchXML.replace('{positionFilterlevel1}', positionFilterString);
            fetchXML = fetchXML.replace('{customFilterLevel1}', '');
        }

        // //modifiedon filter
        // if (hourDifference && !accountid) {
        //     let lastModifiedFilter =
        //         `<filter type="and">
        //             <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" />
        //         </filter>`
        //     fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', lastModifiedFilter);
        // }
        // else {
        //     fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', '');
        // }
        if(isFetchGlobalSearchAccountDetails){
          fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', '');
          fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', '');
        }
        else if (hourDifference && !accountid) {
          let lastModifiedFilter =
              `<filter type="or">
              <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" entityname="`+linkEntity.subgrid.referencingEntity+`"/>
              <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `" entityname="indskr_customerpositionfilter"/>
            </filter>`
          fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', lastModifiedFilter);
          fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', '');
      }
      // No modified on filter for intial sync
      else if(!hourDifference && !accountid){
          fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', '');
          fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', '');
      }
      // Modified on filter for usual real time details fetch - filter at only linked entity level
      else {
        let lastModifiedFilter =
              `<filter type="or">
              <condition attribute="modifiedon" operator="last-x-hours" value="`+ hourDifference + `"/>
            </filter>`
          fetchXML = fetchXML.replace('{deltasyncFilterLevel1}', '');
          fetchXML = fetchXML.replace('{deltasyncFilterLevel2}', lastModifiedFilter);
      }

        //#endregion


        //check if it linkentity is also a mandatory data
        let requiredLinkEntity = FETCH_ACCOUNT_DEFAULT_LINK_ENTITES.find(x => x.entityName == linkEntity.subgrid.referencingEntity)
        let shouldMerge = false;
        let requiredJSONQuery;
        if (requiredLinkEntity) {
            if (requiredLinkEntity.entityFetchXML) {
                shouldMerge = true;
                XML2JS.parseString(requiredLinkEntity.entityFetchXML, (err, data) => {
                    requiredJSONQuery = data;
                })
            }
        }

        //generate attributes list and next level link-entities
        let queryString = linkEntity.subgrid.subgridQuery;
        let JSONQuery;
        let linkEntityAttributesStr = '';
        XML2JS.parseString(queryString, (err, data) => {
            JSONQuery = data;
        })
        let multilingualAttributes = [];
        if(fetchMultilingualFields && linkEntity.subgrid && linkEntity.subgrid.subgridLayout && linkEntity.subgrid.subgridLayout.length > 0){
          multilingualAttributes = linkEntity.subgrid.subgridLayout.filter(a=> a.targetEntity && a.targetEntity == 'omnione_onekeycodeslabels').map(a=> a.attribute);
        }
        JSONQuery.fetch.entity[0].attribute.forEach(attr => {
          let idx;
          if (multilingualAttributes.length > 0) {
            idx = multilingualAttributes.findIndex(a => a == attr.$.name);
          }
          if (idx >= 0) {
            // linkEntityAttributesStr += `
            //       <link-entity name="omnione_onekeycodeslabels" from="omnione_onekeycodeslabelsid" to="${attr.$.name}" visible="false" link-type="outer" >
            //         <attribute name="${multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]}" alias="${JSONQuery.fetch.entity[0].$.name}.${attr.$.name}_value"/>
            //         ${this.authService.user.localeId != '1033' ? `<attribute name="omnione_en_long_label" alias="${JSONQuery.fetch.entity[0].$.name}.${attr.$.name}_fallbackvalue"/>`:''}
            //         <attribute name="omnione_onekeycodeslabelsid" alias="${JSONQuery.fetch.entity[0].$.name}.${attr.$.name}"/>
            //       </link-entity>
            //       `;
            this.localizationService.multiLingualAttributes.push(attr.$.name);
            linkEntityAttributesStr += '<attribute name="' + attr.$.name + '"/>';
          } else {
            linkEntityAttributesStr += '<attribute name="' + attr.$.name + '"/>';
          }
        });

        let numOfLinkEntity = 0; //keep track the number of link entity. Needed for building global search fetchxml
        //Do it for the required data
        if (shouldMerge) {
            requiredJSONQuery.fetch.entity[0].attribute.forEach(attr => {
                linkEntityAttributesStr += '<attribute name="' + attr.$.name + '"/>'
            });
            if (JSONQuery.fetch.entity[0]['link-entity'] && JSONQuery.fetch.entity[0]['link-entity'].length) {
                JSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
                    numOfLinkEntity ++;
                    try {
                        linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
                        + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";

                        linEnt.attribute.forEach(linEntAttr => {
                            linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
                        });

                        if (Array.isArray(requiredJSONQuery.fetch.entity[0]['link-entity'])) {
                            let reqLE = requiredJSONQuery.fetch.entity[0]['link-entity'].find(x => x.$.name == linEnt.$.name);
                            if (reqLE && reqLE.attribute) {
                                reqLE.attribute.forEach(reqLinEntAttr => {
                                    if (!linEnt.attribute.some(x => x.$.name == reqLinEntAttr.$.name)) {
                                        linkEntityAttributesStr += '<attribute name="' + reqLinEntAttr.$.name + '"/>'
                                    }
                                });
                            }
                        }
                        linkEntityAttributesStr += "</link-entity>"
                    } catch (error) {
                        console.log(error);
                    }
                });
            }

            //now iterate through the filter and append the filter criteria defined in the
          linkEntityAttributesStr = this.dynamicFormsService.appendSortCriteria(JSONQuery, linkEntityAttributesStr);
          linkEntityAttributesStr = this.dynamicFormsService.appendFilterCriteria(JSONQuery, linkEntityAttributesStr);
          //now iterate through the filter and append the sort criteria defined in the

            if (requiredJSONQuery.fetch.entity[0]['link-entity'] && requiredJSONQuery.fetch.entity[0]['link-entity'].length) {
                requiredJSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
                    numOfLinkEntity ++;
                    try {
                        if (Array.isArray(JSONQuery.fetch.entity[0]['link-entity']) && JSONQuery.fetch.entity[0]['link-entity'].some(x => x.$.name == linEnt.$.name)) return;

                        linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
                            + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";

                        if(linEnt.attribute) {
                            linEnt.attribute.forEach(linEntAttr => {
                                linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
                            });
                        }
                        linkEntityAttributesStr += "</link-entity>"
                    } catch (error) {
                        console.log(error);
                    }
                });
            }
        } else {
            // if no reqired data
            if (JSONQuery.fetch.entity[0]['link-entity'] && JSONQuery.fetch.entity[0]['link-entity'].length) {
                JSONQuery.fetch.entity[0]['link-entity'].forEach(linEnt => {
                    numOfLinkEntity ++;
                    try {
                        linkEntityAttributesStr += "<link-entity name='" + linEnt.$.name + "' from='" + linEnt.$.from + "' to='"
                        + linEnt.$.to + "' link-type='outer' alias='" + linEnt.$.name + "'>";
                        if(linEnt.attribute) {
                            linEnt.attribute.forEach(linEntAttr => {
                                linkEntityAttributesStr += '<attribute name="' + linEntAttr.$.name + '"/>'
                            });
                        }

                        linkEntityAttributesStr += "</link-entity>"
                    } catch (error) {
                        console.log(error);
                    }

                });
        }
          linkEntityAttributesStr = this.dynamicFormsService.appendSortCriteria(JSONQuery, linkEntityAttributesStr);
          linkEntityAttributesStr = this.dynamicFormsService.appendFilterCriteria(JSONQuery, linkEntityAttributesStr);
        }
        let globalSearchFetch = fetchQueries.configuredFormFetchXMLs.linkEntityPlaceholder;
        globalSearchFetch = globalSearchFetch.replace('{linkEntityName}', linkEntity.subgrid.referencingEntity);
        globalSearchFetch = globalSearchFetch.replace('{linkEntityAttr}', linkEntity.subgrid.referencingAttribute);
        globalSearchFetch = globalSearchFetch.replace('{prentEntityAttr}', linkEntity.subgrid.parentAttribute);
        globalSearchFetch = globalSearchFetch.replace('{linkEntityAlias}', linkEntity.subgrid.referencingEntity);
        globalSearchFetch = globalSearchFetch.replace('{customFilterLevel2}', '');
        globalSearchFetch = globalSearchFetch.replace('{deltasyncFilterLevel2}', '');
        globalSearchFetch = globalSearchFetch.replace('{linkEntityAttributes}', linkEntityAttributesStr);

        if (linkEntity.isSearchable && linkEntity.searchAttributeName) {
            const refEntityNamesToBeAggregated = [
              ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY,
            ];
            let idx = this.searchConfigService.accountConfiguredSearchIndexConfig.findIndex(conf=> conf.categoryRelativePath == linkEntity.searchAttributeName);
            if(idx >= 0) {
                let tempConfig = this.searchConfigService.accountConfiguredSearchIndexConfig[idx];
                tempConfig.linkEntityFetchXML = globalSearchFetch;
                searchCategory = this.searchConfigService.accountConfiguredSearchIndexConfig[idx];
            } else {
                const isThisToBeAggregated = refEntityNamesToBeAggregated.includes(linkEntity.subgrid.referencingEntity);
                let categoryName: string = isThisToBeAggregated
                      ? this.getAggregatedCategoryName(linkEntity)
                      : this.dynamicFormsService.getSearchConfigDisplayText(linkEntity.displayNames, dynamicFormType, linkEntity.isCustom);
                let multiLingualSearchAttribute = linkEntity.searchAttributeName.split('.');
                searchCategory = {
                    categoryName,
                    categoryDisplayName: categoryName,
                    controlDataType: 'LinkedEntity',
                    categoryRelativePath: linkEntity.searchAttributeName,
                    values: [],
                    mappingValues: [],
                    parentEntity: linkEntity.subgrid.referencingEntity,
                    entity: linkEntity.subgrid.referencingEntity,
                    linkEntityFetchXML: globalSearchFetch,
                    numOfLinkEntity:  numOfLinkEntity,
                    fromAttribute: linkEntity.subgrid.referencingAttribute,
                    toAttribute: linkEntity.subgrid.parentAttribute,
                    isThisToBeAggregated,
                    relationshipName: linkEntity.subgrid.relationshipName ?? '',
                    isMultilingualLookup: multilingualAttributes.some(a=> a == multiLingualSearchAttribute[1]),
                };
                this.searchConfigService.accountConfiguredSearchIndexConfig.push(searchCategory);
            }
        }

        linkEntityAttributesStr += `<attribute name="statecode"/><attribute name="statuscode"/>`;
        // if linkEntity.subgrid.subgridQuery has statecode condition, deactivated data does not update
        linkEntityAttributesStr = linkEntityAttributesStr.replace('<condition attribute=\"statecode\" operator=\"eq\" value=\"0\"/>', '');
        fetchXML = fetchXML.replace('{linkEntityAttributes}', linkEntityAttributesStr);

        return { fetchXML, searchCategory };
    }
    private getAggregatedCategoryName(linkEntity: Control): string {
      let categoryName: string = '';
      if (linkEntity) {
        if (linkEntity.subgrid?.referencingEntity === ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY) {
          categoryName = 'ACCOUNT_AFFILIATIONS';
        }

        categoryName = this.translate.instant(categoryName);
      }

      return categoryName;
    }


    private async fetchAccountsLinkedEntityData(linkedEntityDefArray: Control[], positionFilterString, hourDifference, dynamicFormType:DynamicFormType, accountid, loadFromDB, fetchMultilingualFields:boolean = false,isFetchGlobalSearchAccountDetails = false ) {
      return Promise.all(linkedEntityDefArray.map(async (linkEntity)=>{
        let { fetchXML, searchCategory } = this._getAccountsLinkedEntityFetchXML(linkEntity, accountid, positionFilterString, hourDifference, dynamicFormType,fetchMultilingualFields,isFetchGlobalSearchAccountDetails);
            if(loadFromDB) {
                let temp = await this.disk.retrieve(linkEntity.subgrid.referencingEntity);
                if(temp) {
                  if(searchCategory){
                    searchCategory.values = [];
                    searchCategory.mappingValues = [];
                    this.mapLinkEntityToSearch(linkEntity, searchCategory, temp.raw);
                  }
                }
            }else{
              let pagingInfo: any = null;
              let rawAllData = [];
      
              do {
                try {
                  let response = await this.dynamics.executeFetchXml(
                    'accounts',
                    fetchXML,
                    undefined,
                    pagingInfo?.nextPage ?? 0,
                    pagingInfo?.cookie ?? null,
                  );
            
                  if (response) {
                    pagingInfo = response.PagingInfo ?? null;
                    
                    if (Array.isArray(response.value)) {
                      rawAllData.push(...response.value);
                    }
                  }
            
                } catch (error) {
                  console.log("Error retreiving linked entity data: ", error);
                  pagingInfo = null;
                  rawAllData = null;
                }
              } while (pagingInfo !== null);
            
              // mapping responses 
              let dataToSave = rawAllData;
              let dbKey: string = '';
              if (linkEntity.subgrid.referencingEntity === ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY) {
                dbKey = linkEntity.subgrid.relationshipName;
              } else {
                dbKey = linkEntity.subgrid.referencingEntity;
              }
              if ((hourDifference || accountid) && Array.isArray(rawAllData)) {
                  let temp = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY + dbKey);
                  let leIdAttrName = linkEntity.subgrid.referencingEntity + "." + linkEntity.subgrid.referencingEntity + "id";
                  let localRaw;
                  if (temp) {
                      localRaw = temp.raw
                      rawAllData.forEach(rawRes => {
                          if (rawRes && rawRes.hasOwnProperty('accountid') && rawRes.hasOwnProperty('accountid') && rawRes[leIdAttrName]) {
                              let idx = localRaw.findIndex(a => a && a.hasOwnProperty('accountid') && a[leIdAttrName] == rawRes[leIdAttrName]);
                              if(this.accountOfflineService.selected && this.accountOfflineService.selected.id == rawRes.accountid && !this.accountOfflineService.refreshUIofCurrentSelectedAccountFlag){
                                if(idx >= 0){
                                  this.accountOfflineService.refreshUIofCurrentSelectedAccountFlag = this.accountOfflineService.refreshUIofCurrentSelectedAccountFlag = JSON.stringify(localRaw[idx]) != JSON.stringify(rawRes);
                                }else{
                                  this.accountOfflineService.refreshUIofCurrentSelectedAccountFlag = true;
                                }
                              }
                              if (idx >= 0) {
                                if ((rawRes['statecode'] == 1 && rawRes['statuscode'] == 2) || (rawRes[linkEntity.subgrid.referencingEntity+'.statecode'] == 1 && rawRes[linkEntity.subgrid.referencingEntity+'.statuscode'] == 2)) {
                                      localRaw.splice(idx, 1);
                                  } else {
                                      localRaw[idx] = rawRes;
                                  }
                              } else {
                                if (rawRes['statuscode'] != 2 && rawRes[linkEntity.subgrid.referencingEntity+'.statuscode'] != 2) {
                                      localRaw.push(rawRes);
                                  }
                              }
                          }
                      });
                      dataToSave = localRaw;
                  }
              }
              await this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY + dbKey, doc => {
                  doc = {
                      raw: dataToSave,
                  };
                  return doc;
              }).catch(error => console.error('Save Forms LE to DB error: ', error));
              if(searchCategory){
                searchCategory.values = [];
                searchCategory.mappingValues = [];
                this.mapLinkEntityToSearch(linkEntity, searchCategory, dataToSave);
              }
            }
      }));
        // for (var i = 0; i < linkedEntityDefArray.length; i++) {
        //     let linkEntity = linkedEntityDefArray[i];

        // }
        // To Do purge of unused account linked entities

    }

    public async fetchUnmappedAccountsLinkedEntityData(accountId: string){
      const fetchGlobalSearchAccountDetails = true;
      const fetchMultilingualFields:boolean = this.authService.user.securityRoles.some(a=> a.name == 'iO OneKey User' || a.name == 'iO OneKey Admin');
      let { formDef, formType } = await this._getDynamicFormAndType();
      let { linkEntityAttributesArray } = await this._getAccountsConfiguredDisplayFetchXML(formDef, formType, true, null, accountId,fetchMultilingualFields);
      let linkEntities = {};
      if (Array.isArray(linkEntityAttributesArray)) {
        for (let i = 0; i < linkEntityAttributesArray.length; i++) {
          const linkEntity = linkEntityAttributesArray[i];
          if(linkEntity.subgrid.referencingEntity== ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY || 
              linkEntity.subgrid.referencingEntity== ACCOUNT_CONTACT_AFFILIATIONS_REF_ENTITY ||
              linkEntity.subgrid.referencingEntity== "indskr_accountbrandaffiliation")
          {
            const fetchMultilingualFields:boolean = this.authService.user.securityRoles.some(a=> a.name == 'iO OneKey User' || a.name == 'iO OneKey Admin');
            let { fetchXML } = this._getAccountsLinkedEntityFetchXML(linkEntity, accountId, null, null, formType,fetchMultilingualFields,fetchGlobalSearchAccountDetails);
            let linkEntityResponse: any[]
            let pagingInfo: any = null;
            let rawAllData = [];
            do {
              try {
                let response = await this.dynamics.executeFetchXml(
                  'accounts',
                  fetchXML,
                  undefined,
                  pagingInfo?.nextPage ?? 0,
                  pagingInfo?.cookie ?? null,
                );
          
                if (response) {
                  pagingInfo = response.PagingInfo ?? null;
                  
                  if (Array.isArray(response.value)) {
                    rawAllData.push(...response.value);
                  }
                }
          
              } catch (error) {
                console.log("Error retreiving linked entity data: ", error);
                pagingInfo = null;
                rawAllData = null;
              }
            } while (pagingInfo !== null);
          
            // mapping responses
            linkEntityResponse = rawAllData;         
            let dbKey: string = '';
            if (linkEntity.subgrid.referencingEntity === ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY) {
              dbKey = linkEntity.subgrid.relationshipName;
            } else {
              dbKey = linkEntity.subgrid.referencingEntity;
            }
            if (dbKey) {
              linkEntities[DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY + dbKey] = [...linkEntityResponse];
            }
          }
        }
      }     
      const linkEntityAccountTo = linkEntities["account_linked_entityindskr_account_accountaccountaffiliation_affiliatedtoaccountid"]? linkEntities["account_linked_entityindskr_account_accountaccountaffiliation_affiliatedtoaccountid"]:[];
      const linkEntityAccountFrom = linkEntities["account_linked_entityindskr_account_accountaccountaffiliation_affiliatedfromaccountid"]? linkEntities["account_linked_entityindskr_account_accountaccountaffiliation_affiliatedfromaccountid"]:[];
      const brandAffiliationByAccountId = linkEntities["account_linked_entityindskr_accountbrandaffiliation"]? linkEntities["account_linked_entityindskr_accountbrandaffiliation"]:[];
      const linkedAccountContact = linkEntities["account_linked_entityindskr_accountcontactaffiliation"]? linkEntities["account_linked_entityindskr_accountcontactaffiliation"]:[];  
      return { linkEntityAccountTo, linkEntityAccountFrom, brandAffiliationByAccountId, linkedAccountContact };
    }

    private _getAccountsDefaultLinkEntityFetchXML(linkEntity, positionFilter, hourDifference,accountid = null) {
      let fetchXML = linkEntity.fetchXML;
          fetchXML = fetchXML.replace('{PositionFilterPH}', positionFilter);

          //modifiedon filter
          if(hourDifference) {
            let lastModifiedFilter = `
                <filter type="and">
                    <condition attribute="modifiedon" operator="last-x-hours" value="`+hourDifference+`" />
                </filter>`;
            if(accountid) {
              lastModifiedFilter = `
                <filter type="and">
                    <condition attribute="modifiedon" operator="last-x-hours" value="`+hourDifference+`" />
                    <filter type="and"><condition attribute="accountid" operator="eq" value="` + accountid + `" /></filter>
                </filter>`;
            }
            fetchXML = fetchXML.replace('{DeltaSyncFilter}', lastModifiedFilter);
          }
          else {
            let accountidfilter = '';
            if(accountid) {
              accountidfilter = `<filter type="and"><condition attribute="accountid" operator="eq" value="` + accountid + `" /></filter>`
            }
            fetchXML = fetchXML.replace('{DeltaSyncFilter}', accountidfilter);
          }
          fetchXML = fetchXML.replace(/\s+/g, ' ');
          return fetchXML;
    }
    async fetchAccountsDefaultLinkEntityData(positionFilter, hourDifference, linkedEntityFromForm:any[], accountid, loadFromDB){
      return Promise.all(FETCH_ACCOUNT_DEFAULT_LINK_ENTITES.map(async (linkEntity)=>{
        if(!linkEntity.isFetch || linkedEntityFromForm.find(l=> l.subgrid.referencingEntity == linkEntity.entityName)) return;
        let fetchXML = this._getAccountsDefaultLinkEntityFetchXML(linkEntity, positionFilter, hourDifference,accountid);

        if(loadFromDB){
          //let dataToSave = await this.disk.retrieve(linkEntity.entityName);
            // Add to default Linked entity data for mapping to contact object
          // if(linkEntity.entityName == 'indskr_email_address' || linkEntity.entityName == 'indskr_indskr_customeraddress_v2' || linkEntity.entityName == 'indskr_accountcontactaffiliation'){
          //     this._defaultLinkedEntityMappingData.push(...dataToSave);
          // }
        }else{
          await this.dynamics.executeFetchQuery("accounts", fetchXML).then( async (res) => {
              let dataToSave = res;
              if ((hourDifference || accountid) && Array.isArray(res)) {

                  let temp = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY+linkEntity.entityName);
                  let leIdAttrName = linkEntity.entityName + "." + linkEntity.entityName + "id";
                  let localRaw;
                  if (temp) {
                    localRaw = temp.raw
                    res.forEach(rawRes => {
                      if (rawRes && rawRes.hasOwnProperty('accountid') && rawRes.hasOwnProperty(leIdAttrName)) {
                        let idx = localRaw.findIndex(a => a && a.hasOwnProperty('accountid') && a[leIdAttrName] == rawRes[leIdAttrName]);
                        if(this.accountOfflineService.selected && this.accountOfflineService.selected.id == rawRes.accountid && !this.accountOfflineService.refreshUIofCurrentSelectedAccountFlag){
                          if(idx >= 0){
                            this.accountOfflineService.refreshUIofCurrentSelectedAccountFlag = JSON.stringify(localRaw[idx]) != JSON.stringify(rawRes);
                          }else{
                            this.accountOfflineService.refreshUIofCurrentSelectedAccountFlag = true;
                          }
                        }
                        if (idx >= 0) {
                            if (rawRes['statecode'] == 1 && rawRes['statuscode'] == 2) {
                                localRaw.splice(idx, 1);
                            } else {
                                localRaw[idx] = rawRes;
                            }
                        } else {
                            if (rawRes['statuscode'] != 2) {
                                localRaw.push(rawRes);
                            }
                        }
                      }
                    });
                  dataToSave = localRaw;
                }
                if(hourDifference && linkEntity.entityName == "annotation") {
                  let deletedRes = await this.fetchDeletedFromTrackChange(linkEntity.entityName, hourDifference);
                  if(deletedRes && Array.isArray(deletedRes)) {
                    deletedRes.forEach(dRes => {
                      if (dRes) {
                        let idx = dataToSave.findIndex(a => a && a.hasOwnProperty('accountid') && a[leIdAttrName] == dRes["indskr_entityid"]);
                        if (idx >= 0) {
                          if(this.accountOfflineService.selected && this.accountOfflineService.selected.id == dataToSave[idx].accountid){
                            this.accountOfflineService.refreshUIofCurrentSelectedAccountFlag = true;
                          }
                          dataToSave.splice(idx, 1);
                        }
                      }
                    });
                  }
                }
              }
              await this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY+linkEntity.entityName, doc => {
                  doc = {
                    raw: dataToSave,
                  };
                  return doc;
                })
                .catch(error => console.error('Save Default LE to DB error: ', error));
            },
            (err) => {
              console.log(err);
            })
        }
      }))
      // for(var i=0; i < FETCH_ACCOUNT_DEFAULT_LINK_ENTITES.length; i++) {
      //   //(linkEntity => {
      //     let linkEntity = FETCH_ACCOUNT_DEFAULT_LINK_ENTITES[i];

      //   }
    }

    private mapLinkEntityToSearch(linkEntity, searchCategory, linkEntityData) {

        if (searchCategory) {
            this._postMessageOnAdvancedSearchWorker({ type: 'mapLinkEntitySearchIndex', rawList: linkEntityData, configIndex: searchCategory });
        }
        if(linkEntity.subgrid.referencingEntity == 'indskr_indskr_customeraddress_v2'){
            this.searchConfigService.contactConfiguredFormDefaultConfigs.forEach(defaultConfig=>{
                let currentConfig:searchIndexDataModel;
                let idx = this.searchConfigService.accountConfiguredSearchIndexConfig.findIndex(conf=> conf.categoryRelativePath == defaultConfig.categoryRelativePath);
                if(idx >= 0){
                    currentConfig = this.searchConfigService.accountConfiguredSearchIndexConfig[idx];
                }else{
                    const clonedDefaultConfig: searchIndexDataModel = JSON.parse(JSON.stringify(defaultConfig));
                    if(clonedDefaultConfig.translationKey) {
                      clonedDefaultConfig.categoryDisplayName = this.translate.instant(clonedDefaultConfig.translationKey);
                    }
                    else {
                      clonedDefaultConfig.categoryDisplayName = clonedDefaultConfig.categoryName;
                    }

                    // Custom assignment for Address
                    clonedDefaultConfig.fromAttribute = 'indskr_addressid';
                    clonedDefaultConfig.toAttribute = 'indskr_address';

                    currentConfig = clonedDefaultConfig;
                    this.searchConfigService.accountConfiguredSearchIndexConfig.push(currentConfig);
                }
                this._postMessageOnAdvancedSearchWorker({ type: 'mapLinkEntitySearchIndex', rawList: linkEntityData, configIndex: currentConfig });
            });
        }
    }

    private _initAdvancedSearchServiceWorker() {
        this._advancedSearchConfigServiceWorker = new Worker('./assets/workers/advanced-search-worker.js');
        this._advancedSearchConfigServiceWorker.onmessage = (event) => {
            this.ngZone.run(() => {
                let index = this.searchConfigService.accountConfiguredSearchIndexConfig.findIndex(o => o.categoryRelativePath == event.data.categoryRelativePath);
                if (index > -1) {
                    this.searchConfigService.accountConfiguredSearchIndexConfig[index] = event.data
                }
                else this.searchConfigService.accountConfiguredSearchIndexConfig.push(event.data);
            })
        };
    }

    private _postMessageOnAdvancedSearchWorker(data){
        this._advancedSearchConfigServiceWorker.postMessage(data);
    }

    public async getOfflineAccounts() {
        //if(this.disk.check('accounts')){
            await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT).then(document => {
                if(document && document.raw) this.accountOfflineService._mapAccounts(document.raw);
            }).catch(diskError =>{
                console.error('Caught disk error trying to load accounts from disk', diskError);
            });
        //}
    }

  public async getAccountTimelineInfo(account: Account) {
    this.accountOfflineService.isAccountTimelineLoaded = false;
    await Promise.all([
      this.getAccountTimelineActivities(account),
      this.getAccountTimelineEvent(account),
      this.getAccountTimelineSurvey(account)
    ])
    this.accountOfflineService.isAccountTimelineLoaded = true;
  }

  async getAccountTimelineActivities(account: Account) {
    let url: string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accounts.GET_ACCOUNT_TIMELINEINFO.replace('{{AccountId}}', account.id);
    url = url.replace('{{businessUnitId}}', this.authService.user.xBusinessUnitId);
    let timelineActivities: number = 451680002; // Organisation
    if(this.authService.user.timelineActivites) {
      timelineActivities = this.authService.user.timelineActivites;
    }
    url = url.replace('{{timelineActivities}}', timelineActivities.toString());
    let response: any;
    try {
      response = await this.http.get(url).toPromise();
    } catch (error) {
      console.error('getAccountTimelineInfo: ', error);
    }
    if (response) {
      this.accountOfflineService.mapActivitesByAccount(response)
    }
  }

  public async getAccountTimelineEvent(account: Account) {
    try {
      if (!this.authService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL)) {
        account.mapEvents([]); return;
      } else {
        const fetchXml = ACCOUNT_EVENT_FETCH_QUERIES.fetchEventsForAccount.replace("{accountid}", account.id);
        const accountEventDetailsResponse: EventActivity[] = await this.dynamics.executeFetchQuery('msevtmgt_events', fetchXml);
        account.mapEvents(accountEventDetailsResponse);
      }
    }
    catch {
      console.log("Invaild Timeline Events data");
    }
  }

  public async getAccountTimelineSurvey(account: Account) {
    if (this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_SURVEY)) {
      await this.customerAssessService.getSurveysForTimeline(account.id, SurveyCategory.ACCOUNT);
    }
  }

    public async getRawAccountDetailsById(id:string){
        let offlineSaved = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT, true);
        if (offlineSaved && offlineSaved.raw && Array.isArray(offlineSaved.raw)) {
            let foundSavedIndex = offlineSaved.raw.findIndex(savedAccount => savedAccount.accountid === id);
            if (foundSavedIndex >= 0) {
                return offlineSaved.raw[foundSavedIndex];
            }
        }
    }

    public async getRealTimeAccountDetailsOnline(id:string, fetchDataOnly = false,forceFullSync = true){
        let url:string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accounts.GET_ACCOUNT_ID.replace('{{AccountId}}',id);
        let response: any;
        try {
            this.accountOfflineService.refreshUIofCurrentSelectedAccountFlag = false;
            if (!fetchDataOnly) {
              let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ACCOUNT);
              response = await this._doAccountsConfiguredDisplaySync(null,null,forceFullSync,false,syncState.lastUpdatedTime,id);
              if(Array.isArray(response)){
                response = response [0];
              }
            } else {
              response = await this._fetchGlobalSearchedAccountDetailsById(id);
            }

            return response;
        } catch (error) {
            console.error('getAccountInfo: ', error);
        }
    }

    public async createUpdateAccountOnline(payload):Promise<any> {
        let url:string = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accounts.CREATE_ACCOUNT;
        // Create new account on dynamics and return promise with success or failure
        return this.http.post(url, payload).toPromise();
    }
        /**
    * get interaction date for account http call to get intaraction dates for all account
    * @param forceFullSync
    */
    private async syncInteractionDataForAccount(doFullSyn: boolean, lastUpdatedTime): Promise<any> {
        let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accounts.GET_ACCOUNT_INTERACTION;
        const startDateFrom = this.authService.getFromToDateRangeInUTCMiliSec(undefined, undefined).from;
        url = url.replace('{{positionIDs}}', this.authService.getLoggedInUserPositions().toString());
        url = url.replace('{startDate}', startDateFrom);
        url = (doFullSyn) ? url : url + '&lastUpdatedTime=' + lastUpdatedTime;
        const accountInteractionSyncInfo: EntitySyncInfo = {
            entityName: EntityNames.accountInteraction,
            totalFailed: 0,
            totalSynced: 0,
            errors: [],
            syncStatus: true
        };
        let response: any;
        try {
            response = await this.http.get(url, Endpoints.GLOBAL_SYNC_HEADER).pipe(timeout(25000)).toPromise();
        } catch (error) {
            console.error('syncAccountInteraction: ', error);
            this.deltaService.addSyncErrorToEntitySyncInfo(accountInteractionSyncInfo, url, error);
            response = [];
        }
        return response;
    }

    private filterByApprovalStatus(searchResults: any[]): any[] {
      let filtered = [];

      try {
        // Filter already approved ones
        const approvedIds: Map<string, any> = new Map();
        for (const result of searchResults) {
          if (
            result['position.positionid']
            && result['cp.indskr_approvalstatus'] === 548910002
            && result.accountid
          ) {
            approvedIds.set(result.accountId, true);
          }
        }
        filtered = approvedIds.size > 0
          ? searchResults.filter(r => approvedIds.has(r.accountid))
          : searchResults;
        filtered = uniqBy(filtered, 'accountid');
      } catch (error) {
        console.error('filterByApprovalStatus: ', error);
      }

      return filtered;
    }

    /**
     * add interaction date and type to contacts raw
     * @param conatctsResponse All contacts raw data
     * @param interactionResponse  All interaction contact raw data
     */
    private async mapAccountWithInteraction(accountResponse: any, interactionResponse: any): Promise<void> {
        try {
            if (Array.isArray(accountResponse) && Array.isArray(interactionResponse) && interactionResponse.length > 0 && accountResponse.length > 0) {
                for (let i = 0; i < accountResponse.length; i++) {
                    const rawAccount = accountResponse[i];
                    const foundIndex = interactionResponse.findIndex(a => a.accountId === rawAccount.accountid);
                    if (foundIndex >= 0) {
                        let interaction = interactionResponse[foundIndex];
                        rawAccount['interactionDate'] = interaction['interactionDate'];
                        rawAccount['interactionType'] = interaction['interactionType'];
                    }
                }
            }
        } catch (error) {
            console.error('In mapAccountWithInteraction : ', error)
        }
    }

    async globalSearch(selectedSuggestions: SelectedSuggestionPillDataModel[]): Promise<AccountGlobalSearchDTO[]> {
      let finalSearchResult: AccountGlobalSearchDTO[] = [];

      if (Array.isArray(selectedSuggestions)) {
        let nonAggregatedCategorySuggestions: SelectedSuggestionPillDataModel[] = [];
        let aggregatedCategorySuggestions: SelectedSuggestionPillDataModel[] = [];

        // Split aggregated & non-aggregated categories
        for (let i = 0; i < selectedSuggestions.length; i++) {
          const suggestion = selectedSuggestions[i];
          if (suggestion.isAggregatedSection) {
            aggregatedCategorySuggestions.push(suggestion);
          } else {
            nonAggregatedCategorySuggestions.push(suggestion);
          }
        }

        const shouldFilterByApprovalStatus = this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_VISIBILITY_APPROVAL_BASED);

        // Non-aggregated
        let nonAggregatedCategorySearchResult = await this.runGlobalSearchTask(nonAggregatedCategorySuggestions);
        if (shouldFilterByApprovalStatus) {
          nonAggregatedCategorySearchResult = this.filterByApprovalStatus(nonAggregatedCategorySearchResult);
        }

        if (nonAggregatedCategorySuggestions.length > 0 && aggregatedCategorySuggestions.length === 0) {
          // Only need non aggregated search result
          finalSearchResult = [
            ...nonAggregatedCategorySearchResult ?? []
          ]
        } else {
          // Aggregated category search
          let aggregatedCategorySearchResult;
          for (let i = 0; i < aggregatedCategorySuggestions.length; i++) {
            const selectedSuggestion = aggregatedCategorySuggestions[i];
            const splitSuggestions = getSearchSuggestionsData(selectedSuggestion);

            let categorySearchResult: AccountGlobalSearchDTO[] = [];
            for (let j = 0; j < splitSuggestions.length; j++) {
              const suggestion = splitSuggestions[j];
              let partialCategorySearchResult = await this.runGlobalSearchTask([suggestion]);
              if (shouldFilterByApprovalStatus) {
                partialCategorySearchResult = this.filterByApprovalStatus(partialCategorySearchResult);
              }
              // xor each partial search result
              categorySearchResult = xorBy(categorySearchResult, partialCategorySearchResult, 'accountid');
            }

            // AND each non-aggregated category search result
            if (categorySearchResult.length > 0) {
              if (!aggregatedCategorySearchResult) {
                aggregatedCategorySearchResult = [
                  ...categorySearchResult
                ];
              } else {
                aggregatedCategorySearchResult = intersectionBy(finalSearchResult, categorySearchResult, 'accountid');
              }
            } else {
              aggregatedCategorySearchResult = [];
              break;
            }
          }

          if (nonAggregatedCategorySuggestions.length === 0) {
            finalSearchResult = [
              ...aggregatedCategorySearchResult ?? []
            ];
          } else if (nonAggregatedCategorySuggestions.length > 0) {
            // AND non-aggregated & aggregated search result
            finalSearchResult = intersectionBy(nonAggregatedCategorySearchResult, aggregatedCategorySearchResult, 'accountid');
          }
        }
      }

      return finalSearchResult;
    }
    private async runGlobalSearchTask(selectedSuggestions: SelectedSuggestionPillDataModel[]): Promise<AccountGlobalSearchDTO[]> {
      let response: AccountGlobalSearchDTO[] = [];
      const fetchXML = this.accountOfflineService.createGlobalSearchFetchXML(selectedSuggestions);
      try {
        if (fetchXML) {
          response = await this.dynamics.executeFetchQueryWithPageNumber('accounts', fetchXML, 0);
        }
      } catch (error) {
        console.error('account data service: runGlobalSearchTask: ', error);
      }
      return response;
    }

    async mapGlobalSearchedAccountToUserPosition(
      accountId: string,
      customerPositionPayload: { indskr_reason?: string, indskr_skipapproval?: boolean } = {}
    ): Promise<boolean> {
      let mapSuccess: boolean = false;

      if (accountId) {
        const targetPositionId = this.authService.user.xPositionID;
        let url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accounts.MAP_ACCOUNT_TO_USER_POSITION;
        url = url.replace('{{accountId}}', accountId);
        url = url.replace('{{positionId}}', targetPositionId);

        try {
          // Service does not return any response if succeeds
          await this.http.post(url, customerPositionPayload).toPromise();
          mapSuccess = true;
        } catch (error) {
          console.error('mapGlobalSearchedAccountToUserPosition: ', error);
          if (
            error?.error?.errorMessage?.includes('Duplicate Detected')
          ) {
            throw error;
          }
        }
      }

      return mapSuccess;
    }

    public async createVeevaAccount(entity: any): Promise<any> {
      const url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accounts.CREATE_VEEVA_ACCOUNT;
      let headers = new HttpHeaders();
      if (!entity.isNew) {
        headers = headers.set("X-PositionId", this.authService.user.xPositionID);
      }
      return await this.http.post(url, [entity], { headers }).toPromise();
    }

    public async uploadAccountNotesOnline(): Promise<any> {
      return new Promise(async (resolve,reject)=> {
        let offlineNotes = await this.disk.retrieve(DB_KEY_PREFIXES.OFFLINE_ACCOUNT_NOTES);
        let dbNotes = await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY + OFFLINE_DB_LINKED_ENTITY_NAME.ACCOUNT_NOTES);
        if(offlineNotes && offlineNotes.raw && Array.isArray(offlineNotes.raw) && offlineNotes.raw.length != 0){
          const payload = offlineNotes.raw.filter(item => item.pendingPushOnDynamics).map(item=>{
          if(item.annotationid.includes('offlineaccountnote')) {
            return {
              accountid: item.accountid,
              createdon: item.createdon,
              notetext: item.notetext,
              ownerid: item.ownerid,
              filename: item.filename,
              filesize: item.filesize,
              documentbody: item.documentbody,
              mimetype: item.mimetype
            };
          } else {
            if(item.deleted){
              return {
                deleted: true,
                noteid: item.annotationid
              };
            } else if(item.fileupdated){
              return {
                notetext: item.notetext,
                noteid: item.annotationid,
                filename: item.filename,
                filesize: item.filesize,
                documentbody: item.documentbody,
                mimetype: item.mimetype
              };
            } else if(item.fileremoved){
              return {
                notetext: item.notetext,
                noteid: item.annotationid,
                filename: item.filename,
                filesize: item.filesize,
                documentbody: item.documentbody,
                mimetype: item.mimetype
              };
            } else {
              return {
                notetext: item.notetext,
                noteid: item.annotationid
              };
            }
          }
        });
        const url = this.authService.userConfig.activeInstance.entryPointUrl + Endpoints.accounts.UPLOAD_ACCOUNT_NOTES;
        const response = await this.http.post(url, payload, Endpoints.headers.content_type.json).toPromise();
        if(response && Array.isArray(response) && response.length != 0){
          response.forEach((note,idx) => {
            if(note.hasOwnProperty('noteid')){
              // Add to real offline db for customer notes
              try {
                if (dbNotes && dbNotes.raw && Array.isArray(dbNotes.raw) && dbNotes.raw.length != 0) {
                  const dbIdx = dbNotes.raw.findIndex(item => item['annotation.annotationid'] == note['noteid']);
                  if(payload[idx].deleted == true){
                    dbNotes.raw.splice(dbIdx,1);
                  } else {
                    if (dbIdx >= 0) {
                      dbNotes.raw[dbIdx]['annotation.annotationid'] = note['noteid'];
                      dbNotes.raw[dbIdx]['annotation.notetext'] = offlineNotes.raw[idx]['notetext'];
                      dbNotes.raw[dbIdx]['annotation.filename'] = offlineNotes.raw[idx]['filename'];
                      dbNotes.raw[dbIdx]['annotation.mimetype'] = offlineNotes.raw[idx]['mimetype'];
                    } else {
                      dbNotes.raw.push({
                        'annotation.annotationid': note['noteid'],
                        'annotation.objectid': offlineNotes.raw[idx]['accountid'],
                        'accountid': offlineNotes.raw[idx]['accountid'],
                        'annotation.createdon': new Date(parseInt(offlineNotes.raw[idx]['createdon'])),
                        'annotation.notetext': offlineNotes.raw[idx]['notetext'],
                        'annotation.ownerid@OData.Community.Display.V1.FormattedValue':offlineNotes.raw[idx]['ownerName'],
                        'annotation.ownerid':offlineNotes.raw[idx]['ownerid'],
                        'annotation.filename': offlineNotes.raw[idx]['filename'],
                        "annotation.mimetype": offlineNotes.raw[idx]['mimetype']
                      });
                    }
                  }
                } else if (payload[idx].deleted != true) {
                  dbNotes = {
                    raw : [{
                      'annotation.annotationid': note['noteid'],
                      'annotation.objectid': offlineNotes.raw[idx]['accountid'],
                      'accountid': offlineNotes.raw[idx]['accountid'],
                      'annotation.createdon': new Date(parseInt(offlineNotes.raw[idx]['createdon'])),
                      'annotation.notetext': offlineNotes.raw[idx]['notetext'],
                      'annotation.ownerid@OData.Community.Display.V1.FormattedValue':offlineNotes.raw[idx]['ownerName'],
                      'annotation.ownerid':offlineNotes.raw[idx]['ownerid'],
                      'annotation.filename': offlineNotes.raw[idx]['filename'],
                      "annotation.mimetype": offlineNotes.raw[idx]['mimetype']
                    }]
                  }
                }
                offlineNotes.raw[idx].pendingPushOnDynamics = false;
              } catch (error) {
                  console.log(error);
              }
            }
          });
          offlineNotes.raw = offlineNotes.raw.filter(item => item.pendingPushOnDynamics);
          offlineNotes.count = offlineNotes.raw.length;
          this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ACCOUNT_NOTES, offlineNotes.count);
          await this.disk.updateOrInsert(DB_KEY_PREFIXES.OFFLINE_ACCOUNT_NOTES, doc => {
              doc = offlineNotes;
              return doc;
          }).catch(error => console.error('Save Account Notes to DB error: ', error));

          if(dbNotes){
              await this.disk.updateOrInsert(DB_KEY_PREFIXES.ACCOUNT_LINKED_ENTITY + OFFLINE_DB_LINKED_ENTITY_NAME.ACCOUNT_NOTES, doc => {
                  doc = dbNotes;
                  return doc;
              }).catch(error => console.error('Save Account Notes Linked Entity to DB error: ', error));
          }
        }
        resolve(response);
      } else {
        resolve(null);
      }
    });
  }

  async syncAllTags(loadFromDbOnly = false) {
    let offlineFallback: boolean = this.device.isOffline || loadFromDbOnly;
    if (offlineFallback) {
      await this.loadAccountTagFromDB();
    } else {
      const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_ACCOUNT_TAG);
      const isInitialSync = !syncState || !syncState.lastUpdatedTime;
      let lastUpdatedTime = syncState?.lastUpdatedTime;
      const accountTagsSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.account_tag,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };
      if (isInitialSync) {
        await this.AllTagInitialSync();
      } else {
        await this.AllTagDeltaSync(lastUpdatedTime)
      }
      const newLastUpdatedTime = new Date().getTime().toString();
      syncState.lastUpdatedTime = newLastUpdatedTime;
      await this.disk.updateSyncState(syncState);
      if (Array.isArray(this.userTagService.accountTags)) {
        accountTagsSyncInfo.totalSynced = this.userTagService.accountTags.length;
      }
      this.deltaService.addEntitySyncInfo(accountTagsSyncInfo);
    }
    this.contactService.syncCustomerTag(loadFromDbOnly);
    this.presentationService.syncPresentationTag(loadFromDbOnly);
    this.resourceService.syncResourceTag(loadFromDbOnly);
  }

  private async loadAccountTagFromDB() {
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_USER_TAG).then((data: UserTag[]) => {
      //check for delete true
      this.userTagService.accountTags = data.filter(a => a.entity == TagEntityType.ACCOUNT);
    });
  }

  // private async accountTagInitialSync() {
  //   try {
  //     await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_USER_TAG);
  //     const positionIds = this.authService.user.positions.map(o => {
  //       return o.ID
  //     });
  //     const buId = this.authService.user.buConfigs.indskr_bulevelconfigurationid;
  //     let positionString = '';
  //     positionIds.forEach(p => {
  //       positionString += '<value>' + p + '</value>';
  //     })
  //     let PrivateTagfetchXml = fetchQueries.accounts.fetchAccountPrivateTags;
  //     let publicTagfetchXml = fetchQueries.accounts.fetchAccountPublicTags;
  //     let assignedTagfetchXml=fetchQueries.accounts.fetchAssignedAccountTags.replace(/{positionIDs}/g, `${positionString}`);
  //     let assignButagfetchXml = fetchQueries.accounts.fetchAssignedAccountTagsForBU.replace(/{buId}/g, `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`).replace(/{positionIDs}/g, `${positionString}`);
  //     publicTagfetchXml = publicTagfetchXml.replace('{busUnitID}', `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`);
  //     await Promise.all([
  //       this.dynamics.executeFetchQuery('indskr_usertags', PrivateTagfetchXml),
  //       this.dynamics.executeFetchQuery('indskr_usertags', publicTagfetchXml),
  //       this.dynamics.executeFetchQuery('indskr_usertags', assignedTagfetchXml),
  //       this.dynamics.executeFetchQuery('indskr_usertags', assignButagfetchXml)
  //     ]).then(async response => {
  //       let privateTag = response[0];
  //       let publicTag = response[1];
  //       let assignedTag =response[2];
  //       let assignedTagBu =response[3];
  //       const accountTagDetailsResponse = privateTag.concat(publicTag,assignedTag,assignedTagBu)
  //       accountTagDetailsResponse.forEach(record=> {
  //         if(!record['indskr_externalid']){
  //           record['indskr_externalid'] = record['indskr_usertagid'];
  //         }
  //       });
  //       let accountIdList = this.getAccountIdsForTagIds(accountTagDetailsResponse)
  //       let accountTagDetails = accountTagDetailsResponse.map((tag) => {
  //         return new UserTag(tag.indskr_externalid, tag.indskr_usertagid, tag.deleted, tag.indskr_name,
  //           accountIdList[tag.indskr_usertagid], false, TagStateCode.Active, TagEntityType.ACCOUNT,tag.indskr_visibility , tag.indskr_filter)
  //       });
  //       accountTagDetails = uniqBy(accountTagDetails, 'indskr_usertagid');
  //       this.userTagService.accountTags = accountTagDetails;
  //       this.userTagService.accountTags = _.uniqBy(this.userTagService.accountTags,"indskr_usertagid");
  //       this.userTagService.accountTags = this.userTagService.sortUserTags(this.userTagService.accountTags);
        
  //       await this.disk.upsertUserTagListToDisk(accountTagDetails)
  //     })
  //   }
  //   catch (e) {
  //     console.log(e);
  //   }
  // }

  private async AllTagInitialSync() {
    try {
      await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_USER_TAG);
      const positionIds = this.authService.user.positions.map(o => {
        return o.ID
      });
      const buId = this.authService.user.buConfigs.indskr_bulevelconfigurationid;
      let positionString = '';
      positionIds.forEach(p => {
        positionString += '<value>' + p + '</value>';
      })
      let PrivateTagfetchXml = fetchQueries.allUserTag.fetchPrivateTags;
      let publicTagfetchXml = fetchQueries.allUserTag.fetchPublicTags;
      let assignedTagfetchXml=fetchQueries.allUserTag.fetchAssignedTagsPosition.replace(/{positionIDs}/g, `${positionString}`);
      let assignButagfetchXml = fetchQueries.allUserTag.fetchAssignedTagsForBU.replace(/{busUnitID}/g, `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`).replace(/{positionIDs}/g, `${positionString}`);
      // publicTagfetchXml = publicTagfetchXml.replace('{busUnitID}', `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`);
      await Promise.all([
        this.dynamics.executeFetchQuery('indskr_usertags', PrivateTagfetchXml),
        this.dynamics.executeFetchQuery('indskr_usertags', publicTagfetchXml),
        this.dynamics.executeFetchQuery('indskr_usertags', assignedTagfetchXml),
        this.dynamics.executeFetchQuery('indskr_usertags', assignButagfetchXml)
      ]).then(async response => {
        let privateTag = response[0];
        let publicTag = response[1];
        let assignedTag =response[2];
        let assignedTagBu =response[3];

        let allTags = privateTag.concat(publicTag,assignedTag,assignedTagBu);
        const categorisedTags = this.getCategoriesedTags(allTags);
        //account 
        // const accountTagDetailsResponse = allTags.filter(x => x && x['indskr_taggedfor'] && x['indskr_taggedfor'].toLowerCase() === "account");
        // accountTagDetailsResponse.forEach(record=> {
        //   if(!record['indskr_externalid']){
        //     record['indskr_externalid'] = record['indskr_usertagid'];
        //   }
        // });
        const accountTagDetailsResponse = categorisedTags.accountTagDetailsResponse;
        let accountIdList = this.getAccountIdsForTagIds(accountTagDetailsResponse);
        let accountTagDetails = accountTagDetailsResponse.map((tag) => {
          return new UserTag(tag.indskr_externalid, tag.indskr_usertagid, tag.deleted, tag.indskr_name,
            accountIdList[tag.indskr_usertagid], false, TagStateCode.Active, TagEntityType.ACCOUNT,tag.indskr_visibility , tag.indskr_filter ,tag.indskr_allowusertomodifytag)
        });
        accountTagDetails = uniqBy(accountTagDetails, 'indskr_usertagid');
        this.userTagService.accountTags = accountTagDetails;
        this.userTagService.accountTags = _.uniqBy(this.userTagService.accountTags,"indskr_usertagid");
        this.userTagService.accountTags = this.userTagService.sortUserTags(this.userTagService.accountTags);
        
        await this.disk.upsertUserTagListToDisk(accountTagDetails);

        // contact
        // const contactTagDetailsResponse = allTags.filter(x => x && x['indskr_taggedfor'] && x['indskr_taggedfor'].toUpperCase() === "CONTACT")
        // contactTagDetailsResponse.forEach(record=> {
        //   if(!record['indskr_externalid']){
        //     record['indskr_externalid'] = record['indskr_usertagid'];
        //   }
        // });

        const contactTagDetailsResponse = categorisedTags.contactTagDetailsResponse;
        let contactIdList = this.getContractIdsForTagIds(contactTagDetailsResponse)
        console.log(contactIdList);
        let contactTagDetails = contactTagDetailsResponse.map((tag) => {
          return new UserTagForContact(tag.indskr_externalid, tag.indskr_usertagid, tag.deleted, tag.indskr_name,
            contactIdList[tag.indskr_usertagid], false, tag.statecode,tag.indskr_visibility,tag.indskr_filter ,tag.indskr_allowusertomodifytag)
        });
        contactTagDetails = _.uniqBy(contactTagDetails, 'indskr_usertagid');
        this.contactOfflineService.contactTags = contactTagDetails;
        console.log("contactTagDetails", contactTagDetails);
        await this.disk.upsertUserTagListToDisk(contactTagDetails, TagEntityType.CONTACT);
        
        // presentation
        // const presentationTagDetailsResponse = allTags.filter(x => x && x['indskr_taggedfor'] && x['indskr_taggedfor'].toLowerCase() === "presentation")
        // presentationTagDetailsResponse.forEach(record => {
        //   if (!record['indskr_externalid']) {
        //     record['indskr_externalid'] = record['indskr_usertagid'];
        //   }
        // });

        const presentationTagDetailsResponse = categorisedTags.presentationTagDetailsResponse;
        let presentationIdList = this.getpresentationIdsForTagIds(presentationTagDetailsResponse)
        let presentationTagDetails = presentationTagDetailsResponse.map((tag) => {
          return new UserTag(tag.indskr_externalid, tag.indskr_usertagid, tag.deleted, tag.indskr_name,
            presentationIdList[tag.indskr_usertagid], false, TagStateCode.Active, TagEntityType.PRESENTATION, tag.indskr_visibility , tag.indskr_filter ,tag.indskr_allowusertomodifytag)
        });
        presentationTagDetails = uniqBy(presentationTagDetails, 'indskr_usertagid');
        this.userTagService.PresentationTags = presentationTagDetails;
        this.userTagService.PresentationTags = this.userTagService.sortUserTags(this.userTagService.PresentationTags);
        this.userTagService.PresentationTags = uniqBy(this.userTagService.PresentationTags,"indskr_externalid")
        await this.disk.upsertUserTagListToDisk(presentationTagDetails)

        //Resource
        // const resourceTagDetailsResponse = allTags.filter(x => x && x['indskr_taggedfor'] && x['indskr_taggedfor'].toLowerCase() === "resource");
        // resourceTagDetailsResponse.forEach(record => {
        //   if (!record['indskr_externalid']) {
        //     record['indskr_externalid'] = record['indskr_usertagid'];
        //   }
        // });

        const resourceTagDetailsResponse = categorisedTags.resourceTagDetailsResponse;
        let resourceIdList = this.getresourceIdsForTagIds(resourceTagDetailsResponse)
        let resourceTagDetails = resourceTagDetailsResponse.map((tag) => {
          return new UserTag(tag.indskr_externalid, tag.indskr_usertagid, tag.deleted, tag.indskr_name,
            resourceIdList[tag.indskr_usertagid], false, TagStateCode.Active, TagEntityType.RESOURCE, tag.indskr_visibility,tag.indskr_filter ,tag.indskr_allowusertomodifytag)
        });
        resourceTagDetails = uniqBy(resourceTagDetails, 'indskr_usertagid');
        this.userTagService.resourceTag = resourceTagDetails;
        this.userTagService.resourceTag = this.userTagService.sortUserTags(this.userTagService.resourceTag);
        await this.disk.upsertUserTagListToDisk(resourceTagDetails)
      })
    }
    catch (e) {
      console.log(e);
    }
  }

  private getCategoriesedTags(allTags): any {
    let accountTagDetailsResponse = [];
    let contactTagDetailsResponse = [];
    let presentationTagDetailsResponse = [];
    let resourceTagDetailsResponse = [];

    for (let tag of allTags) {
      if (tag['indskr_taggedfor']) {
        if (!tag['indskr_externalid']) {
          tag['indskr_externalid'] = tag['indskr_usertagid'];
        }
        if (tag['indskr_taggedfor'].toLowerCase() === "account") {
          accountTagDetailsResponse.push(tag);
        } else if (tag['indskr_taggedfor'].toUpperCase() === "CONTACT") {
          contactTagDetailsResponse.push(tag);
        } else if (tag['indskr_taggedfor'].toLowerCase() === "presentation") {
          presentationTagDetailsResponse.push(tag);
        } else if (tag['indskr_taggedfor'].toLowerCase() === "resource") {
          resourceTagDetailsResponse.push(tag);
        }
      }
    }

    return { accountTagDetailsResponse, contactTagDetailsResponse, presentationTagDetailsResponse, resourceTagDetailsResponse };
  }

  // private async accountTagDeltaSync(lastUpdatedTime) {
  //   await this.loadAccountTagFromDB();
  //   const positionIds = this.authService.user.positions.map(o => {
  //     return o.ID
  //   });
  //   let positionString = '';
  //   positionIds.forEach(p => {
  //     positionString += '<value>' + p + '</value>';
  //   })
  //   let PrivateTagfetchXml = fetchQueries.accounts.updatedAccountPrivateTags;
  //   let publicTagfetchXml = fetchQueries.accounts.updatedAccoutPublicTags;
  //   let assignedTagfetchXml=fetchQueries.accounts.updatedAssignedAccountTags.replace(/{positionIDs}/g, `${positionString}`);
  //   let assignButagfetchXml = fetchQueries.accounts.updatedAssignedAccountTagsForBU.replace(/{buId}/g, `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`).replace(/{positionIDs}/g, `${positionString}`);
  //   publicTagfetchXml = publicTagfetchXml.replace('{busUnitID}', `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`);
  //   // lastUpdatedTime = moment(parseInt(lastUpdatedTime)).format('YYYY-MM-DD');
  //   lastUpdatedTime = new Date(parseInt(lastUpdatedTime)).toISOString();
  //   PrivateTagfetchXml = PrivateTagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
  //   publicTagfetchXml = publicTagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
  //   assignedTagfetchXml = assignedTagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
  //   assignButagfetchXml = assignButagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
  //   let deletedUserTagsFetchXml = CONTACT_FETCH_QUERIES.deletedUserTagsFetchXml;
  //   deletedUserTagsFetchXml = deletedUserTagsFetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
  //   await Promise.all([
  //     this.dynamics.executeFetchQuery('indskr_usertags', PrivateTagfetchXml),
  //     this.dynamics.executeFetchQuery('indskr_usertags', publicTagfetchXml),
  //     this.dynamics.executeFetchQuery('indskr_usertags', assignedTagfetchXml),
  //     this.dynamics.executeFetchQuery('indskr_usertags', assignButagfetchXml),
  //     this.dynamics.executeFetchQuery('indskr_trackchanges', deletedUserTagsFetchXml)
  //   ]).then(response => {
  //     let privateTag = response[0];
  //     let publicTag = response[1];
  //     let assignedTag =response[2];
  //     let assignedTagBu =response[3];
  //     let updateAccountTags = privateTag.concat(publicTag,assignedTag,assignedTagBu);
  //     updateAccountTags.forEach(record=> {
  //       if(!record['indskr_externalid']){
  //         record['indskr_externalid'] = record['indskr_usertagid'];
  //       }
  //     });
  //     let deletedAccountTags = response[3];
  //     if (!isEmpty(deletedAccountTags)) {
  //       deletedAccountTags.forEach(async trackChangedTag => {
  //         let index = this.userTagService.accountTags
  //           .findIndex(tag => (tag.indskr_usertagid === trackChangedTag['indskr_usertagid']));
  //         if (index >= 0) {
  //           await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + this.userTagService.accountTags[index].indskr_externalid);
  //           this.userTagService.accountTags.splice(index, 1);
  //         }
  //       });
  //     }
  //     if (!isEmpty(updateAccountTags)) {
  //       let accountIdList = this.getAccountIdsForTagIds(updateAccountTags)
  //       let accountTagDetails: UserTag[] = updateAccountTags.map((tag) => {
  //         return new UserTag(tag.indskr_externalid, tag.indskr_usertagid, tag.deleted, tag.indskr_name, accountIdList[tag.indskr_usertagid], false, tag.statecode, TagEntityType.ACCOUNT,tag.indskr_visibility ,tag.indskr_filter)
  //       });
  //       accountTagDetails = uniqBy(accountTagDetails, 'indskr_usertagid');
  //       accountTagDetails.forEach(async (tagDetails) => {

  //         const checkTagIndex = this.userTagService.accountTags.findIndex(tag => tag.indskr_externalid === tagDetails.indskr_externalid)
  //         if (checkTagIndex > -1) {
  //           this.userTagService.accountTags.splice(checkTagIndex, 1);
  //         }
  //         // if Tag data is updated with contacts
  //         if (tagDetails.stateCode === TagStateCode.Active) {
  //           this.userTagService.accountTags.push(tagDetails);
  //           await this.disk.upsertUserTag(tagDetails, TagEntityType.ACCOUNT);
  //         } else {
  //           await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + tagDetails.indskr_externalid);
  //         }
  //       });
  //       this.userTagService.accountTags = _.uniqBy(this.userTagService.accountTags,"indskr_usertagid");
  //       this.userTagService.accountTags = this.userTagService.sortUserTags(this.userTagService.accountTags);
  //     }
  //   })
  //     .catch((e) => { console.error('uploadOfflineData: ', e); return false; });
  // }

  private async AllTagDeltaSync(lastUpdatedTime) {
    await this.loadAccountTagFromDB();
    const positionIds = this.authService.user.positions.map(o => {
      return o.ID
    });
    let positionString = '';
    positionIds.forEach(p => {
      positionString += '<value>' + p + '</value>';
    })
    let PrivateTagfetchXml = fetchQueries.allUserTag.updatedPrivateTags;
    let publicTagfetchXml = fetchQueries.allUserTag.updatedPublicTags;
    let assignedTagfetchXml= fetchQueries.allUserTag.updatedAssignedTagPosition.replace(/{positionIDs}/g, `${positionString}`);
    let assignButagfetchXml = fetchQueries.allUserTag.updatedAssignedTagsForBU.replace(/{busUnitID}/g, `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`).replace(/{positionIDs}/g, `${positionString}`);
    let emptyBuAndPosition = fetchQueries.allUserTag.updatedEmptyBuAndPosition;
    // publicTagfetchXml = publicTagfetchXml.replace('{busUnitID}', `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`);
    // lastUpdatedTime = moment(parseInt(lastUpdatedTime)).format('YYYY-MM-DD');
    lastUpdatedTime = new Date(parseInt(lastUpdatedTime)).toISOString();
    PrivateTagfetchXml = PrivateTagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
    publicTagfetchXml = publicTagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
    assignedTagfetchXml = assignedTagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
    assignButagfetchXml = assignButagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
    emptyBuAndPosition = emptyBuAndPosition.replace('{lastUpdatedTime}', lastUpdatedTime)
    let deletedUserTagsFetchXml = CONTACT_FETCH_QUERIES.deletedUserTagsFetchXml;
    deletedUserTagsFetchXml = deletedUserTagsFetchXml.replace('{lastUpdatedTime}', lastUpdatedTime).replace('{userId}', `${this.authService.user.xSystemUserID}`)
    await Promise.all([
      this.dynamics.executeFetchQuery('indskr_usertags', PrivateTagfetchXml),
      this.dynamics.executeFetchQuery('indskr_usertags', publicTagfetchXml),
      this.dynamics.executeFetchQuery('indskr_usertags', assignedTagfetchXml),
      this.dynamics.executeFetchQuery('indskr_usertags', assignButagfetchXml),
      this.dynamics.executeFetchQuery('indskr_trackchanges', deletedUserTagsFetchXml),
      this.dynamics.executeFetchQuery('indskr_usertags', emptyBuAndPosition)
    ]).then(response => {
      let privateTag = response[0];
      let publicTag = response[1];
      let assignedTag =response[2];
      let assignedTagBu =response[3];
      let emptyBuAndPos =response[5];
      let allTag = privateTag.concat(publicTag,assignedTag,assignedTagBu,emptyBuAndPos);
      let categorisedTags = this.getCategoriesedTags(allTag);
      // let updateAccountTags = allTag.filter(x => x && x['indskr_taggedfor'] && x['indskr_taggedfor'].toLowerCase() === "account");
      // let updateContactTags = allTag.filter(x => x && x['indskr_taggedfor'] && x['indskr_taggedfor'].toLowerCase() === "contact");
      // let updatePresentationTags = allTag.filter(x => x && x['indskr_taggedfor'] && x['indskr_taggedfor'].toLowerCase() === "presentation");
      // let updateResourceTags = allTag.filter(x => x && x['indskr_taggedfor'] && x['indskr_taggedfor'].toLowerCase() === "resource");
      const updateAccountTags = categorisedTags.accountTagDetailsResponse;
      const updateContactTags =  categorisedTags.contactTagDetailsResponse;
      const updatePresentationTags = categorisedTags.presentationTagDetailsResponse;
      const updateResourceTags = categorisedTags.resourceTagDetailsResponse;
      if(updateAccountTags.length>0) this.accountTagMap(updateAccountTags,response[4],positionIds);
      if(updateContactTags.length>0) this.contactTagMap(updateContactTags,response[4],positionIds);
      if(updatePresentationTags.length>0) this.PresentationTagMap(updatePresentationTags,response[4],positionIds);
      if(updateResourceTags.length>0) this.ResourceTagMap(updateResourceTags,response[4],positionIds);
      
    })
      .catch((e) => { 
        console.error('uploadOfflineData: ', e); 
        return false; 
      });
  }

  private getAccountIdsForTagIds(updateAccountTags: any[]) {
    return updateAccountTags.reduce((data, val) => {
      data[val.indskr_usertagid] = data[val.indskr_usertagid] || [];
      let accountId = new EntityTag(val["accountId"])
      if (accountId) {
        if (accountId.id) {
          data[val.indskr_usertagid].push(accountId)
        }
      } else if (!data[val.indskr_usertagid].length) {
        data[val.indskr_usertagid] = []
      }
      return data;
    }, {});
  }

  private getContractIdsForTagIds(updateContactTags: any[]) {
    return updateContactTags.reduce((data, val) => {
      data[val.indskr_usertagid] = data[val.indskr_usertagid] || [];
      let contactId = new ContactTag(val["contactId"])
      if (contactId) {
        data[val.indskr_usertagid].push(contactId)
      } else if (!data[val.indskr_usertagid].length) {
        data[val.indskr_usertagid] = []
      }
      return data;
    }, {});
  }

  private getpresentationIdsForTagIds(updatePresentationTags: any[]) {
    return updatePresentationTags.reduce((data, val) => {
      data[val.indskr_usertagid] = data[val.indskr_usertagid] || [];
      let presentationId = new EntityTag(val["presentationId"])
      if (presentationId) {
        if (presentationId.id) {
          data[val.indskr_usertagid].push(presentationId)
        }
      } else if (!data[val.indskr_usertagid].length) {
        data[val.indskr_usertagid] = []
      }
      return data;
    }, {});
  }

  private getresourceIdsForTagIds(updateResourceTags: any[]) {
    return updateResourceTags.reduce((data, val) => {
      data[val.indskr_usertagid] = data[val.indskr_usertagid] || [];
      let resourceId = new EntityTag(val["resourceId"]);
      let documentId = new EntityTag(val["documentId"]);
      if (resourceId || documentId) {
        if (resourceId.id) {
          data[val.indskr_usertagid].push(resourceId)
        }
        if (documentId.id) {
          data[val.indskr_usertagid].push(documentId)
        }
      } else if (!data[val.indskr_usertagid].length) {
        data[val.indskr_usertagid] = []
      }
      return data;
    }, {});
  }

  private getBuForUserTags(tagList: any[]) {
    return tagList.reduce((data, val) => {
      data[val.indskr_usertagid] = data[val.indskr_usertagid] || [];
      let id = val["bu"]
      if (id) {
          data[val.indskr_usertagid].push(id)
        
      } else if (!data[val.indskr_usertagid].length) {
        data[val.indskr_usertagid] = []
      }
      return data;
    }, {});
  }
  private getPosForUserTags(tagList: any[]) {
    return tagList.reduce((data, val) => {
      data[val.indskr_usertagid] = data[val.indskr_usertagid] || [];
      let id = val["position"]
      if (id) {
          data[val.indskr_usertagid].push(id)
        
      } else if (!data[val.indskr_usertagid].length) {
        data[val.indskr_usertagid] = []
      }
      return data;
    }, {});
  }
  async uploadOfflineAccountTag(isPartialUpload = false, maxRecordCountForPartialUpload = 10) {
    if (!isPartialUpload && this.device.isOffline) return;
    let option = {
      selector: {
        '_id': {
          $gte: DB_KEY_PREFIXES.USER_TAG,
          $lte: DB_KEY_PREFIXES.USER_TAG + PREFIX_SEARCH_ENDKEY_UNICODE
        },
        'pendingPushToDynamics': { $eq: true }
      }
    };

    try {
      let userTags: UserTag[] = await this.disk.find(option);
      if (userTags && userTags.length) {
        if (isPartialUpload) {
          if (userTags.length > maxRecordCountForPartialUpload) {
            userTags = userTags.splice(0, maxRecordCountForPartialUpload);
          }
        }
        //userTags = userTags.filter(tag => tag.stateCode === TagStateCode.Active);
        await this.userTagService.uploadOfflineData(userTags).subscribe(response => {
          if (response) {
            response.forEach(async res => {
              let data = res;
              const indskr_externalid = data['indskr_externalid'];
              const index = userTags.findIndex(tag => tag.indskr_externalid === indskr_externalid);
              if (index > -1) {
                userTags[index].pendingPushToDynamics = false;
                userTags[index].indskr_usertagid = data['indskr_usertagid'];
                if (userTags[index].deleted) {
                  await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + userTags[index].indskr_externalid);
                }
              }
            
              let tagPres = this.userTagService.PresentationTags.find(x=>x.indskr_externalid == indskr_externalid )
              if(tagPres) tagPres.indskr_usertagid = data['indskr_usertagid'];
              let tagAcc = this.userTagService.accountTags.find(x=>x.indskr_externalid == indskr_externalid )
              if(tagAcc)tagAcc.indskr_usertagid = data['indskr_usertagid'];
            });
          }
        }, error => {
          console.error(error);
        });
        const newCount = userTags.filter(userTag => userTag.pendingPushToDynamics);
        this.disk.subtractOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.USER_TAG, newCount ? newCount.length : 0);
      }

    } catch (err) {
      console.error("Failed to upload offline Account tag: ", err);
    }

  }

   /** Filter lineked Entity data to use affiliated account-contact selection via global search */
   public async getSelectableLinkedEntity(dbKey:string, referenceEntity:string, selectedId:string): Promise<SelectableLinkedEntity[]> {
    let selectableLinkedEntity: Array<SelectableLinkedEntity> = [];
    try {
      const response = await this.fetchAccountsSpecificLinkEntityData(dbKey, selectedId);
      const attrStatecodeStr: string = dbKey + '.statecode';
      const attrSelIdStr: string = dbKey + '.indskr_contactid';
      const attrIsPrimaryStr: string = dbKey + '.indskr_isprimaryaccount';
      const attrSelNameStr: string = dbKey + '.indskr_contactid_Formatted';
      const attrApprovalStatusStr: string = 'cp' + '.indskr_approvalstatus';
      const attrPositionIdStr: string = 'cp' + '.indskr_positionid';
      const userPositionId: string = this.authService.user.xPositionID;
      const customerPositionStatecode: string = 'cp' + '.statecode';
      
      const rawLEData = await this.disk.retrieve(dbKey);
      if(!_.isEmpty(rawLEData) && rawLEData.raw) {
        const linkedEntityData = rawLEData.raw.filter(x=>x[referenceEntity] == selectedId && (!x.hasOwnProperty(attrStatecodeStr) || (x.hasOwnProperty(attrStatecodeStr) && x[attrStatecodeStr] == 0)));
        
        if(!_.isEmpty(linkedEntityData)) {
          const isReasonFieldEnabled = this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_MAPPING_APPROVAL);
          
          linkedEntityData.forEach(le=>{
            let approvalStatusValue: string = '';
            if(isReasonFieldEnabled) {
              if(le[attrPositionIdStr] && le[attrPositionIdStr] == userPositionId) {
                if (le[attrApprovalStatusStr]) {
                  if (le[attrApprovalStatusStr] == APPROVAL_STATUS.FOR_REVIEW) {
                    approvalStatusValue = this.translate.instant("SUBMITTED_FOR_APPROVAL");
                  } else if (le[attrApprovalStatusStr] == APPROVAL_STATUS.DRAFT) {
                    approvalStatusValue = this.translate.instant("REJECTED_BY_APPROVER");
                  }
                }
              }
            }
            if (le[customerPositionStatecode] && le[customerPositionStatecode] == 1) {
              approvalStatusValue = this.translate.instant("APPROVAL_DEACTIVATED");
            }
            selectableLinkedEntity.push({
              selId: le[attrSelIdStr] || '',
              isPrimary: le[attrIsPrimaryStr] || false,
              selName: le[attrSelNameStr] || '',
              isDisableChkBox: false,
              isChecked: false,
              approvalStatus: approvalStatusValue
            });
          });
          
          if(isReasonFieldEnabled) {
            selectableLinkedEntity = _.orderBy(selectableLinkedEntity, 'approvalStatus', 'desc');
          }
          selectableLinkedEntity = _.uniqBy(selectableLinkedEntity, 'selId');
        }
      }

      return selectableLinkedEntity;
    
    } catch(error) {
      console.error("Failed to fetch linked entity data: ", error);
      return selectableLinkedEntity;
    }
  }

  async fetchAccountsSpecificLinkEntityData(targetLinkedEntity: string, accountId) {
    return Promise.all(
      FETCH_ACCOUNTS_LINK_ENTITES_BY_ACCOUNTID.map(async (linkEntity) => {
        if (targetLinkedEntity != linkEntity.entityName) return;
        let accountFilter = `<filter><condition attribute="indskr_accountid" operator="eq" value="{0}" /></filter>`
        let fetchXML = linkEntity.fetchXML;
        fetchXML = fetchXML.replace('{AccountFilter}', accountFilter);
        fetchXML = fetchXML.replace('{0}', accountId);

        try {
          const res = await this.dynamics.executeFetchQuery("accounts", fetchXML);
          if(!_.isEmpty(res)) {
            let dataToSave = '';
            if ((accountId) && Array.isArray(res)) {
              let temp = await this.disk.retrieve(linkEntity.entityName);
              const leIdAttrName = linkEntity.entityName + "." + linkEntity.entityName + "id";
              const lePositionIdStr = 'cp.indskr_positionid';
              let localRaw;
              if (!_.isEmpty(temp)) {
                localRaw = temp.raw;
                let isUpdated: boolean = false;
                res.forEach(rawRes => {
                  if (rawRes && rawRes.hasOwnProperty('accountid') && rawRes.hasOwnProperty(leIdAttrName)) {
                    let idx = localRaw.findIndex(a => a && a.hasOwnProperty('accountid') && a[leIdAttrName] == rawRes[leIdAttrName] && a[lePositionIdStr] == rawRes[lePositionIdStr]);
                    if (idx >= 0) {
                      isUpdated = true;
                      if (rawRes['statecode'] == 1 && rawRes['statuscode'] == 2) {
                        localRaw.splice(idx, 1);
                      } else {
                        localRaw[idx] = rawRes;
                      }
                    } else {
                      if (rawRes['statuscode'] != 2) {
                        isUpdated = true;
                        localRaw.push(rawRes);
                      }
                    }
                  }
                });
                dataToSave = localRaw;
                if (isUpdated && !_.isEmpty(dataToSave)) {
                  await this.disk.updateOrInsert(linkEntity.entityName, doc => {
                    doc = { raw: dataToSave, };
                    return doc;
                  }).catch(error => console.error('Save Updated LE to DB error: ', error))
                }
              }
            }
          }
        } catch (error) {
          console.error("Failed to fetch linked entity data: ", error);
        }
      })
    );
  }

  public async getEntityImageStringForAccount (account:Account, hardRefresh:boolean = false){
    if(account && account.id && (!account.entityImage || hardRefresh)){
      const resp = await this.dynamics.retrieveAll('accounts', ['entityimage'],
      `accountid eq ${account.id}`);
      return resp && resp.value && resp.value[0].entityimage ? "data:image/jpeg;base64,"+resp.value[0].entityimage : null;
    }else if(account && account.entityImage){
      return account.entityImage;
    }
  }

  // --------------------------------Team Users - Filter levels-------------------------------- //
  public async getTargetAccountsBasedOnPositionId(positionId: string) : Promise<AccountGlobalSearchDTO[]> {
    console.log(`getTargetAccountsBasedOnPositionId`);
    let response:AccountGlobalSearchDTO[] = [];

    try {
      const todayDate = moment().format("YYYY-MM-DD");
      let fetchXML = fetchQueries.fetchTargetAccountsBasedOnPositionId
       .replace('{date}', todayDate)
      .replace('{date}', todayDate)
      .split('{positionID}').join(positionId);
      fetchXML = fetchXML.replace('{secondaryinfoFetchXml}', this.secondaryInfoService.SecondaryInfoFetchXML(SecondaryInfoEntityName.Account));

      response = await this.dynamics.executeFetchQuery('accounts', fetchXML);
      response = _.orderBy(_.uniqBy(response, 'accountid'), ['name'],['asc']);
      console.warn(`getTargetAccountsBasedOnPositionId: executeFetchQuery`);
      console.log(response);
    } catch (error) {
      console.error('getTargetAccountsBasedOnPositionId: error:', error);
    }
    return response;
  }

  public async getAccountsBasedOnPositionId(positionId: string): Promise<string[]> {
    console.log(`getAccountsBasedOnPositionId`);
    let response = [];

    let fetchXML = fetchQueries.fetchAccountsBasedOnPositionId;
    fetchXML = fetchXML.replace('{secondaryinfoFetchXml}', this.secondaryInfoService.SecondaryInfoFetchXML(SecondaryInfoEntityName.Account));

    try {
      fetchXML = fetchXML.replace('{positionID}', `${positionId}`);
      response = await this.dynamics.executeFetchQuery('accounts', fetchXML);
      response = _.orderBy(_.uniqBy(response, 'accountid'), ['name'],['asc']);
      console.warn(`getAccountsBasedOnPositionId: executeFetchQuery`);
      console.log(response);
    } catch (error) {
      console.error('getAccountsBasedOnPositionId: error:', error);
    }
    return response;
  }

  // fetching only for accounts mapped to user position
  public async fetchCustomerAddresses(fullSync, loadFromDbOnly = false) {
    const offlineData = await this.loadCustomerAddressFromLocalDB();
    let deltaFilter = '';

    if (loadFromDbOnly) {
      this.accountOfflineService.customerAddresses = offlineData.raw ? offlineData.raw : [];
      return;
    }

    let fetchXML = fetchQueries.accounts.customerAddress;

    const positions = this.authService.user.positions.map((o) => {
      return o.ID
    });

    let positionString = '';
    positions.forEach(p => {
      positionString += '<value>' + p + '</value>';
    });

    if (offlineData && offlineData.lastModified) {
      const modifiedon = this.dateService.formatDateForFetchXML(offlineData.lastModified);
      deltaFilter = `<condition attribute="modifiedon" operator="ge" value="` + modifiedon + `"/>`;
    }

    fetchXML = fetchXML.replace('{positionIDs}', positionString);
    fetchXML = fetchXML.replace('{deltaSyncFilter}', !fullSync ? deltaFilter : '');

    if (fullSync) {
      fetchXML = fetchXML.replace('{fullSyncFilter}', '<condition attribute="statecode" operator="eq" value="0" />');
    }

    await this.dynamics.executeFetchQuery('indskr_indskr_customeraddress_v2s', fetchXML).then((response) => {
      if (fullSync && !response.length) this.accountOfflineService.customerAddresses = [];
      
      if (response && response.length > 0) {
        let customerAddresses = response.map((caddress) => new customerAddress(caddress));
        this.accountOfflineService.customerAddresses = customerAddresses; 
        if (fullSync || !this.accountOfflineService.customerAddresses.length) this.accountOfflineService.customerAddresses = customerAddresses;

        if (!fullSync && this.accountOfflineService.customerAddresses.length > 0) {
          customerAddresses.forEach((customerAddress : customerAddress) => {
            const index = this.accountOfflineService.customerAddresses.findIndex(({ customerAddressId }) => customerAddressId === customerAddress.customerAddressId);
            if (index < 0 && customerAddress.statecode === 1) {
              this.accountOfflineService.customerAddresses.push(customerAddress);
            } else if (customerAddress.statecode === 1) { // if customer specialities deactivated remove from local
              this.accountOfflineService.customerAddresses.splice(index, 1);
            } else {
              this.accountOfflineService.customerAddresses[index] = customerAddress;
            }
          });
        }
        this.saveCustomerAddressInLocalDB(customerAddresses);
      }
    }).catch((err) => {
      console.log(err); 
    })
  }

  public async saveCustomerAddressInLocalDB(customerAddresses: any) {
    try {
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.CUSTOMER_ADDRESSES, (doc) => {
        doc = {
          raw: [],
          lastModified: new Date().getTime()
        };
        doc.raw = customerAddresses;
        return doc;
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async loadCustomerAddressFromLocalDB() {
    let offlineDataStored;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.CUSTOMER_ADDRESSES, true).then((doc) => {
        offlineDataStored = doc?.raw ? doc : [];
      });
    }
    catch (er) {
      console.error("Failed to load procedure sub types from local db!: ", er)
      offlineDataStored = [];
    }
    return offlineDataStored;
  }

  accountTagMap(updateAccountTags,trackchangeDlt,positionIds){
    updateAccountTags.forEach(record=> {
      if(!record['indskr_externalid']){
        record['indskr_externalid'] = record['indskr_usertagid'];
      }
    });
    let deletedAccountTags = trackchangeDlt;
    if (!isEmpty(deletedAccountTags)) {
      deletedAccountTags.forEach(async trackChangedTag => {
        let index = this.userTagService.accountTags
          .findIndex(tag => (tag.indskr_usertagid === trackChangedTag['indskr_usertagid']));
        if (index >= 0) {
          await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + this.userTagService.accountTags[index].indskr_externalid);
          this.userTagService.accountTags.splice(index, 1);
        }
      });
    }
    if (!isEmpty(updateAccountTags)) {
      let accountIdList = this.getAccountIdsForTagIds(updateAccountTags);
      let accountIdListForBu = this.getBuForUserTags(updateAccountTags);
      let accountIdListForPos = this.getPosForUserTags(updateAccountTags);
      let accountTagDetails: UserTag[] = updateAccountTags.map((tag) => {
        return new UserTag(tag.indskr_externalid, tag.indskr_usertagid, tag.deleted, tag.indskr_name, accountIdList[tag.indskr_usertagid], false, tag.statecode, TagEntityType.ACCOUNT,tag.indskr_visibility ,tag.indskr_filter,tag.indskr_allowusertomodifytag,accountIdListForBu[tag.indskr_usertagid],accountIdListForPos[tag.indskr_usertagid])
      });
      accountTagDetails = uniqBy(accountTagDetails, 'indskr_usertagid');
      let entriesWithBuOrPosition = accountTagDetails.filter(tg=>tg.visibility=="600000000");
       entriesWithBuOrPosition = entriesWithBuOrPosition.filter((resource) =>
          resource.BUs.includes(this.authService.user.buConfigs.indskr_bulevelconfigurationid) || positionIds.some(position => resource.Posiions.includes(position))
        );
      let entriesWithoutBuAndPosition = accountTagDetails.filter(tg=>tg.visibility=="600000000")
      entriesWithoutBuAndPosition = entriesWithoutBuAndPosition.filter(
          (resource) => !resource.BUs.includes(this.authService.user.buConfigs.indskr_bulevelconfigurationid) && !positionIds.every(position => resource.Posiions.includes(position))
        );

      entriesWithBuOrPosition = accountTagDetails.filter(tg=>tg.visibility!="600000000").concat(entriesWithBuOrPosition);
      entriesWithBuOrPosition.forEach(async (tagDetails) => {

        const checkTagIndex = this.userTagService.accountTags.findIndex(tag => tag.indskr_externalid === tagDetails.indskr_externalid)
        if (checkTagIndex > -1) {
          this.userTagService.accountTags.splice(checkTagIndex, 1);
        }
        // if Tag data is updated with contacts
        if (tagDetails.stateCode === TagStateCode.Active) {
          this.userTagService.accountTags.push(tagDetails);
          await this.disk.upsertUserTag(tagDetails, TagEntityType.ACCOUNT);
        } else {
          await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + tagDetails.indskr_externalid);
        }
      });
      entriesWithoutBuAndPosition.forEach(async (tagDetails) => {
        const checkTagIndex = this.userTagService.accountTags.findIndex(tag => tag.indskr_externalid === tagDetails.indskr_externalid)
        if (checkTagIndex > -1) {
          this.userTagService.accountTags.splice(checkTagIndex, 1);
          await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + tagDetails.indskr_externalid);
          
        }
      })
      this.userTagService.accountTags = _.uniqBy(this.userTagService.accountTags,"indskr_usertagid");
      this.userTagService.accountTags = this.userTagService.sortUserTags(this.userTagService.accountTags);
    }
  }
  contactTagMap(updateContactTags,trackchangeDlt,positionIds){
    updateContactTags.forEach(record=> {
      if(!record['indskr_externalid']){
        record['indskr_externalid'] = record['indskr_usertagid'];
      }
    });
    let deletedContactTags = trackchangeDlt;
    if (!_.isEmpty(deletedContactTags)) {
      deletedContactTags.forEach(async trackChangedTag => {

        let index = this.contactOfflineService.contactTags
          .findIndex(tag => (tag.indskr_usertagid === trackChangedTag['indskr_usertagid']));

        if (index >= 0) {
          await this.disk.remove(DB_KEY_PREFIXES.CONTACT_TAG + this.contactOfflineService.contactTags[index].indskr_externalid);
          this.contactOfflineService.contactTags.splice(index, 1);
        }
      });
    }
    if (!_.isEmpty(updateContactTags)) {
      let contactIdList = this.getContractIdsForTagIds(updateContactTags)
      let contactIdListForBu = this.getBuForUserTags(updateContactTags);
      let contactIdListForPos = this.getPosForUserTags(updateContactTags);
        
      let contactTagDetails: UserTagForContact[] = updateContactTags.map((tag) => {
        return new UserTagForContact(tag.indskr_externalid, tag.indskr_usertagid, tag.deleted, tag.indskr_name, contactIdList[tag.indskr_usertagid], false, tag.statecode,tag.indskr_visibility,tag.indskr_filter,tag.indskr_allowusertomodifytag,contactIdListForBu[tag.indskr_usertagid],contactIdListForPos[tag.indskr_usertagid])
      });
      contactTagDetails = uniqBy(contactTagDetails, 'indskr_usertagid');
      let entriesWithBuOrPosition = contactTagDetails.filter((resource) =>
          (resource.BUs.includes(this.authService.user.buConfigs.indskr_bulevelconfigurationid) || positionIds.some(position => resource.Posiions.includes(position))) && resource.visibility=="600000000"
        );
        
      let entriesWithoutBuAndPosition = contactTagDetails.filter(
          (resource) => (!resource.BUs.includes(this.authService.user.buConfigs.indskr_bulevelconfigurationid) && !positionIds.every(position => resource.Posiions.includes(position)))&& resource.visibility=="600000000"
        );
      entriesWithBuOrPosition = contactTagDetails.filter(tg=>tg.visibility!="600000000").concat(entriesWithBuOrPosition);
      entriesWithBuOrPosition.forEach(async (tagDetails) => {

        const checkTagIndex = this.contactOfflineService.contactTags.findIndex(tag => tag.indskr_externalid === tagDetails.indskr_externalid)
        if (checkTagIndex > -1) {
          this.contactOfflineService.contactTags.splice(checkTagIndex, 1);
        }
        // if Tag data is updated with contacts
        if (tagDetails.stateCode === CustomerTagStateCode.Active) {
          this.contactOfflineService.contactTags.push(tagDetails);
          await this.disk.upsertUserTag(tagDetails, TagEntityType.CONTACT);
        } else {
          await this.disk.remove(DB_KEY_PREFIXES.CONTACT_TAG + tagDetails.indskr_externalid);
        }
      });
      entriesWithoutBuAndPosition.forEach(async (tagDetails)=>{
        const checkTagIndex = this.contactOfflineService.contactTags.findIndex(tag => tag.indskr_externalid === tagDetails.indskr_externalid)
        if (checkTagIndex > -1) {
          this.contactOfflineService.contactTags.splice(checkTagIndex, 1);
          await this.disk.remove(DB_KEY_PREFIXES.CONTACT_TAG + tagDetails.indskr_externalid);
        }
      })
      this.contactOfflineService.contactTags = _.sortBy(this.contactOfflineService.contactTags, "indskr_name");
      this.contactOfflineService.contactTags = _.uniqBy(this.contactOfflineService.contactTags, "indskr_usertagid");
    }
  }
  
  
  PresentationTagMap(updatePresentationTags,trackchangeDlt,positionIds){
    updatePresentationTags.forEach(record => {
      if (!record['indskr_externalid']) {
        record['indskr_externalid'] = record['indskr_usertagid'];
      }
    });
    let deletedPresentationTags = trackchangeDlt;
    if (!isEmpty(deletedPresentationTags)) {
      deletedPresentationTags.forEach(async trackChangedTag => {
        let index = this.userTagService.PresentationTags
          .findIndex(tag => (tag.indskr_usertagid === trackChangedTag['indskr_usertagid']));
        if (index >= 0) {
          await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + this.userTagService.PresentationTags[index].indskr_externalid);
          this.userTagService.PresentationTags.splice(index, 1);
        }
      });
    }
    if (!isEmpty(updatePresentationTags)) {
      let presentationIdList = this.getpresentationIdsForTagIds(updatePresentationTags);
      let presentationIdListForBu = this.getBuForUserTags(updatePresentationTags);
      let presentationIdListForPos = this.getPosForUserTags(updatePresentationTags);//resourceIdListForBu[tag.indskr_usertagid],resourceIdListForPos[tag.indskr_usertagid]
        
      let presentationTagDetails: UserTag[] = updatePresentationTags.map((tag) => {
        return new UserTag(tag.indskr_externalid, tag.indskr_usertagid, tag.deleted, tag.indskr_name, presentationIdList[tag.indskr_usertagid], false, tag.statecode, TagEntityType.PRESENTATION, tag.indskr_visibility,tag.indskr_filter,tag.indskr_allowusertomodifytag,presentationIdListForBu[tag.indskr_usertagid],presentationIdListForPos[tag.indskr_usertagid]
          )
      });
      presentationTagDetails = uniqBy(presentationTagDetails, 'indskr_usertagid');
      let entriesWithBuOrPosition = presentationTagDetails.filter((resource) =>
          (resource.BUs.includes(this.authService.user.buConfigs.indskr_bulevelconfigurationid) || positionIds.some(position => resource.Posiions.includes(position)) ) && resource.visibility=="600000000"
        );
        
      let entriesWithoutBuAndPosition = presentationTagDetails.filter(
          (resource) => (!resource.BUs.includes(this.authService.user.buConfigs.indskr_bulevelconfigurationid) && !positionIds.every(position => resource.Posiions.includes(position))) && resource.visibility=="600000000"
        );
      
      entriesWithBuOrPosition = presentationTagDetails.filter(tg=>tg.visibility!="600000000").concat(entriesWithBuOrPosition);
      entriesWithBuOrPosition.forEach(async (tagDetails) => {

        const checkTagIndex = this.userTagService.PresentationTags.findIndex(tag => tag.indskr_externalid === tagDetails.indskr_externalid)
        if (checkTagIndex > -1) {
          this.userTagService.PresentationTags.splice(checkTagIndex, 1);
        }
        // if Tag data is updated with presentation
        // this.userTagService.PresentationTags[checkTagIndex]= null
        if (tagDetails.stateCode === TagStateCode.Active) {
          this.userTagService.PresentationTags.push(tagDetails);
          // this.userTagService.PresentationTags[checkTagIndex] = tagDetails;
          await this.disk.upsertUserTag(tagDetails, TagEntityType.PRESENTATION);
        } else {
          await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + tagDetails.indskr_externalid);
        }
      });
      entriesWithoutBuAndPosition.forEach(async (tagDetails)=>{
        const checkTagIndex = this.userTagService.PresentationTags.findIndex(tag => tag.indskr_externalid === tagDetails.indskr_externalid)

         if (checkTagIndex > -1) {
          this.userTagService.PresentationTags.splice(checkTagIndex, 1);
        }
        await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + tagDetails.indskr_externalid);
      })
      this.userTagService.PresentationTags = this.userTagService.sortUserTags(this.userTagService.PresentationTags);
      this.userTagService.PresentationTags = uniqBy(this.userTagService.PresentationTags,"indskr_externalid")
    }
  }
  ResourceTagMap(updateResourceTags,trackchangeDlt,positionIds){
    updateResourceTags.forEach(record => {
      if (!record['indskr_externalid']) {
        record['indskr_externalid'] = record['indskr_usertagid'];
      }
    });
    let deletedResourceTags = trackchangeDlt;
    if (!isEmpty(deletedResourceTags)) {
      deletedResourceTags.forEach(async trackChangedTag => {
        // let index = this.userTagService.resourceTag
        //   .findIndex(tag => (tag.indskr_usertagid === trackChangedTag['indskr_usertagid']));
        // if (index >= 0) {
        //   await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + this.userTagService.resourceTag[index].indskr_externalid);
        //   this.userTagService.resourceTag.splice(index, 1);
        // }
        let index = this.userTagService.resourceTag
        .findIndex(tag => (tag.indskr_usertagid === trackChangedTag['indskr_usertagid']));
        if (index >= 0) {
          await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + this.userTagService.resourceTag[index].indskr_externalid);
          this.userTagService.resourceTag.splice(index, 1);
        }
        });
    }
    if (!isEmpty(updateResourceTags)) {
      let entriesWithBuOrPosition ;
      let entriesWithoutBuAndPosition
      let resourceIdList = this.getresourceIdsForTagIds(updateResourceTags);
      let resourceIdListForBu = this.getBuForUserTags(updateResourceTags);
      let resourceIdListForPos = this.getPosForUserTags(updateResourceTags);

      let resourceTagDetails: UserTag[] = updateResourceTags.map((tag) => {
        return new UserTag(tag.indskr_externalid, tag.indskr_usertagid, tag.deleted, tag.indskr_name, resourceIdList[tag.indskr_usertagid], false, tag.statecode, TagEntityType.RESOURCE, tag.indskr_visibility, tag.indskr_filter,tag.indskr_allowusertomodifytag,resourceIdListForBu[tag.indskr_usertagid],resourceIdListForPos[tag.indskr_usertagid])
      });
      resourceTagDetails = uniqBy(resourceTagDetails, 'indskr_usertagid');
     
      entriesWithBuOrPosition = resourceTagDetails.filter((resource) =>
         ( resource.BUs.includes(this.authService.user.buConfigs.indskr_bulevelconfigurationid) || positionIds.some(position => resource.Posiions.includes(position)) ) && resource.visibility =="600000000"
        );
        
      entriesWithoutBuAndPosition = resourceTagDetails.filter(
          (resource) => !resource.BUs.includes(this.authService.user.buConfigs.indskr_bulevelconfigurationid) && !positionIds.every(position => resource.Posiions.includes(position)) && resource.visibility =="600000000"
        );
      
      entriesWithBuOrPosition = resourceTagDetails.filter(tg=>tg.visibility!="600000000").concat(entriesWithBuOrPosition);
      entriesWithBuOrPosition.forEach(async (tagDetails) => {

        const checkTagIndex = this.userTagService.resourceTag.findIndex(tag => tag.indskr_externalid === tagDetails.indskr_externalid)
        if (checkTagIndex > -1) {
          this.userTagService.resourceTag.splice(checkTagIndex, 1);
        }
        // if Tag data is updated with resource
        if (tagDetails.stateCode === TagStateCode.Active) {
          this.userTagService.resourceTag.push(tagDetails);
          await this.disk.upsertUserTag(tagDetails, TagEntityType.RESOURCE);
        } else {
          await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + tagDetails.indskr_externalid);
        }
      });
      entriesWithoutBuAndPosition.forEach(async (tagDetails)=>{
        const checkTagIndex = this.userTagService.resourceTag.findIndex(tag => tag.indskr_externalid === tagDetails.indskr_externalid)
        if (checkTagIndex > -1) {
          this.userTagService.resourceTag.splice(checkTagIndex, 1);
          await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + tagDetails.indskr_externalid);
        }
      })
      this.userTagService.resourceTag = _.uniqBy(this.userTagService.resourceTag,"indskr_usertagid");
      this.userTagService.resourceTag = this.userTagService.sortUserTags(this.userTagService.resourceTag);
    }
  }

}
