import { Appointment } from './../../../models/appointment-data-model';
import { ProcedureTrackerActivityDataService } from './../../../data-services/procedure-tracker-activity/procedure-tracker-activity.data.service';
import { ProcedureTrackerActivity } from './../../../classes/activity/procedure-tracker.activity.class';
import { ProcedureTrackerDetailsComponent } from './../../surgery-order/procedure-tracker-details/procedure-tracker-details';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { takeUntil, ignoreElements, filter, debounceTime } from 'rxjs/operators';
import { ReportService } from './../../../services/reports/report.service';
import { EventsService } from './../../../services/events/events.service';
import { Component, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit, NgZone, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { ActivityDataService } from '../../../data-services/activity/activity.service';
import { ActivityService, ActivityScrapStatus } from '../../../services/activity/activity.service';
import {
  Activity,
  ActivityType,
  ActivityTypeToFilterNameMap,
  MeetingActivityState
} from '../../../classes/activity/activity.class';
import { NavController, AlertController, PopoverController, ModalController, IonSegment, IonSegmentButton, LoadingController } from '@ionic/angular';
import {
  format,
  isToday,
  isTomorrow,
  isValid,
  differenceInCalendarDays,
  addMinutes,
  isSameDay
} from "date-fns";
import  ja  from 'date-fns/locale/ja';
import  es  from 'date-fns/locale/es';
import  fr  from 'date-fns/locale/fr';
import  en  from 'date-fns/locale/en';
import  tr from '@angular/common/locales/tr';
import  pt from '@angular/common/locales/pt';
import  it from '@angular/common/locales/it';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { LogService } from '../../../services/logging/log-service';
import { UIService } from '../../../services/ui/ui.service';
import { DeviceService, VIEW_MODE_CHANGE } from '../../../services/device/device.service';
import { MeetingDataService, InitiateMeetingPayload } from '../../../data-services/meeting/meeting.data.service';
import { MeetingStatus } from '../../../classes/meeting/meeting.class';
import { AppointmentActivity, CovisitorAccess, OFFLINE_ID_PREFIX } from '../../../classes/activity/appointment.activity.class';
import { WebsocketDataService } from '../../../data-services/websocket/websocket.data.service';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { AccountOfflineService } from '../../../services/account/account.offline.service';
import { NavigationService, PageName, ChildNavNames } from '../../../services/navigation/navigation.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { VirtualListComponent, ChangeEvent } from '../../angular-virtual-list';
import { RepServices } from '../../../data-services/rep/rep.services';
import { DurationType, TimeOffActivity } from "../../../classes/activity/timeoff.class";
import { TimeOffService } from "../../../services/time-off/time-off.service";
import { REP_STATUS } from '../../../models/rep-status-model';
import { SampleDataService } from '../../../data-services/sample/sample.data.service';
import { CreateSampleDropRequestBody, SampleActivity } from '../../../classes/activity/sample.activity.class';
import { SampleService, SamplingDetailsViewMode } from '../../../services/sample/sample.service';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { CoachingReportService } from '../../../services/coaching/coaching.report.service';
import { SchedulerService } from '../../../services/scheduler/scheduler.service';
import { ActivitiesFilterPopoverComponent } from '../../activities-filter-popover/activities-filter-popover';
import { TimeOffUtilityService } from '../../../services/time-off/time-off-utility.service';
import { EmailActivity, EmailActivityParty, EmailViewType, EmailActionType, EmailAddress } from '../../../classes/activity/email.activity.class';
import { EmailService } from '../../../services/email-templates/email.service';
import { DeltaService } from '../../../data-services/delta/delta.service';
import { FollowUpActionDetailComponent } from '../follow-up-action-detail/follow-up-action-detail';
import { FollowUpActivityDataService } from '../../../data-services/follow-up-activity/follow-up-activity.data.service';
import { FollowUpActivity, FOLLOW_UP_TYPE } from '../../../classes/activity/follow-up-action.activity.class';
import { NothingSelectedView } from '../../shared/nothing-selected-view/nothing-selected-view';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import _ from 'lodash'
import { OrderDetailsComponent } from '../../order-management/order-details/order-details';
import { OrderActivity } from '../../../classes/activity/order.activity.class';
import { CaseManagementService, AccesingMode } from '../../../services/case-management/case-management.service';
import { CaseActivity } from '../../../classes/case-intake/case-activity.class';
import { OrderActivityDataService, OperationDetail } from '../../../data-services/order-activity/order-activity.data.service';
import { CaseManagementDataService } from '../../../data-services/case-management/case-management.data.service';
import { ScientificPlanFollowUpTask } from '../scientific-activity/scientific-plan-task/scientific-plan-task';
import {GlobalUtilityService} from "../../../services/global-utility.service";
import { CallPlanOfflineService } from '../../../services/call-plan/call-plan.offline.service';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { AlertService } from '../../../services/alert/alert.service';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { SelectListDetail } from '../../../models/select-list-detail-model';
import { SelectListComponent } from '../../../components/shared/select-list/select-list';
import { ReportsFiltersComponent } from '../../../components/reports/reports-filters/reports-filters';
import { IpcEventListner } from '../../../services/electron-api';
import {Utility} from "../../../utility/util";
import {NewActivityComponent} from "../new-activity/new-activity";
import { PhoneActivity, OFFLINE_PHONE_CALL_ID_PREFIX } from '../../../classes/activity/phone.activity.class';
import { PhoneCallDataService, PhonecallStatus, InitiatePhoneCallPayload } from '../../../data-services/phone-call/phonecall.data.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { AgendaFooterView, AgendaFooterService } from '@omni/services/footer/agenda-footer.service';
import { Channel, ChannelType, ChannelActivityType } from '@omni/classes/consent/channel.class';
import { XperiencesService } from '@omni/services/xperiences/xperiences.service';
import { ConsentService } from '@omni/services/consent/consent.service';
import { ResourceEmailTemplate } from '@omni/classes/email-templates/email-template.class';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { SurgeryOrderDetailsComponent } from '@omni/components/surgery-order/surgery-order-details/surgery-order-details';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { DatePipe } from '@angular/common';
import { Util } from 'applicationinsights-js';
import { ActivitiesDetailsPaneComponent } from '../activities-details-pane/activities-details-pane';
import { CaseManagementDetailComponent } from '@omni/components/case-management/case-management-detail/case-management-detail';
import { EmailActivityDetailComponent } from '../email-activity-detail/email-activity-detail';
import { NewSampleActivityComponent } from '../new-sample-activity/new-sample-activity';
import { TimeOffDetailsComponent } from '@omni/components/time-off/time-off-details/time-off-details';
import { PhoneCallDetailsComponent } from '@omni/components/phonecall/phone-call-details/phone-call-details';
import { TimeOffDataService } from '@omni/data-services/time-off/time-off.data.service';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { ResourceService } from '@omni/services/resource/resource.service';
import { PresentationService } from '@omni/services/presentation/presentation.service';
import { DiskService } from '@omni/services/disk/disk.service';
import moment from 'moment';
import { BrandOfflineService } from '@omni/services/brand/brand.service';
import { Presentation } from '@omni/classes/presentation/presentation.class';
import { Resource } from '@omni/classes/resource/resource.class';
import { OpportunityManagementService } from '@omni/services/opportunity-management/opportunity-management.service';
import { AccountManagementOfflineService } from '@omni/services/account-management/account-management.service';
import { OrderManagementService } from '@omni/services/order-management/order-management.service';
import { MyAssistantService } from '@omni/services/my-assistant/my-assistant.service';
import { LocalizationService } from '@omni/services/localization/localization.service';
import { startRecursiveActivityScheduleConflictFlagging, startRecursiveEventScheduleConflictFlagging, startRecursiveActivityCloseByActivityFlagging } from '../../../utility/activity.utility';
import { IndFilterMenuComponent } from '@omni/components/shared/ind-filter-menu/ind-filter-menu';
import { ContactsModel } from '@omni/classes/contact/contact.class';
import { StoreCheckActivity } from '@omni/classes/activity/store-check.activity.class';
import { StoreCheckDetailsComponent } from '../store-check-activity/store-check-details';
import { SetBookingActivityDetailsComponent } from '../set-booking-activity-detail/set-booking-activity-detail';
import { SetBookingDataService } from '@omni/data-services/set-booking/set-booking.data.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';
import { SetBookingActivity, SetBookingStatus } from '@omni/classes/activity/set-booking.activity.class';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { MobileAppTemplateType } from '@omni/classes/survey/survey-form.class';
import { SurveyService } from '@omni/services/survey/survey.service';
import { Guid } from 'typescript-guid';
import { SecInfoConfigDataService } from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import { SecondaryInfoEntityName } from '@omni/classes/sec-info-config/sec-info.class';

const NumberOfSkeletonCardsToDisplay = 5;

/**
 * Generated class for the ActivitiesPaneComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'activities-pane',
  templateUrl: 'activities-pane.html',
  styleUrls:['activities-pane.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ActivitiesPaneComponent implements AfterViewInit {
  // public activitymode: string = "agenda";
  newTimeOff: TimeOffActivity = new TimeOffActivity({}, ActivityType.TimeOff);
  public isExpanded: boolean = false;
  public itemExpandHeight: number = 25;
  public myIcon = "chevron-down-outline";
  public activityType: string = 'All Activities';
  public activityTypes: string[];   //= ['All Activities', 'Appointment', 'Phone', 'Email'];
  public todaysActivity: Activity[];
  public lastActiveSegment: string = 'agenda';  // CWD-2422 Track the changes between segments view
  public istoday = isToday;
  sameDayFlag: boolean = false;
  scrollOnce: boolean;
  scrollDate: Date;
  selectedActivity: any;
  indices: ChangeEvent = { start: 0, end: 0 };
  public groupedActivities$ = new BehaviorSubject<any[]>(null);
  public groupActvitiesDate: Date[] = [];
  public months: Array<String> = ["January", "Febuary", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
  public weekDay: Array<String> = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  public loadMoreText: string = 'Load more...';
  public trackPrevActivityLength: number = 0;
  public currentActivityLength: number = 0;

  public hasBaselineEnabled: boolean = false;
  public globalCustomerText: string;
  public dateDividers: Array<number>;
  private hasTimeoffEnabled: boolean = false;
  public isDeviceOnline: boolean = true;
  private _isSegmentChanged: boolean = false;
  private _isAgendaRefreshed: boolean = false;
  private _orientationHandler: (modeChange: VIEW_MODE_CHANGE, curPageName: PageName, curMasterPageName: PageName) => void;
  private ngDestroy$ = new Subject<boolean>();
  public selectedTabValue: string = 'agenda';
  public numberOfSkeletonCardsToDisplay = NumberOfSkeletonCardsToDisplay;


  @ViewChild('expWrapper', {static:true}) expandWrapper: ElementRef;
  @ViewChild('content', {static:true}) content: HTMLIonContentElement;
  @ViewChild(VirtualListComponent, {static:false}) private virtualList: VirtualListComponent | any;

  @Output() onAddNewActivityClick = new EventEmitter();
  @Output() scheduleSelected = new EventEmitter<any>(null);
  @Input() refreshSchedulerList: boolean = false;
  // @ViewChild('tabsGroup', {static:false}) private tabsGroup: HTMLIonSegmentButtonElement;

  private ItabSubscription: Subscription;

  hasSamplingEnabled: boolean;
  hasEmailEnabled: boolean;
  hasCustomerInquiries: boolean
  hasPhoneCallEnabled: boolean  = false;
  private hasSuggestedMeetingsEnabled: boolean;
  private hasSuggestedWeComMessagesEnabled: boolean;
  private hasSuggestedTaskEnabled: boolean;
  private isFollowUpTasksVisible: boolean = false;
  private isOrderActivitiesEnabled: boolean = false;
  private isProcedureLogActivitiesEnabled: boolean = false;
  private isProcedureTrackerActivitiesEnabled: boolean = false;
  private isSetBookingActivitiesEnabled:boolean = false;
  public template: ResourceEmailTemplate;

  private _scrollToInvokeCounter = 0;
  public confirmNewPattern:boolean = false;
  public viewPortItems: any;
  reportTilesSlideOpts= {
    initialSlide: 1,
    speed: 400
  }
  hasEdgeAnalyticsEnabled: boolean = false;
  tabsData: IndTabsDataModel[] = [];
  public hasSchedulerEnabled: boolean = false;
  date_fns_locale: any;
  // added setter to update the tabs when hasSchedulerEnabled is modified
  set _hasSchedulerEnabled(value: boolean) {
    // this.tabsData[2].hide = !value;
    // this.tabsData = [...this.tabsData];
    // this.hasSchedulerEnabled = value;
  }

  shouldShowSearchBarFilterButton: boolean = true;
  isAndroid: boolean = false;
  filterAtHome: string;
  filterValueAtHome: string;
  private copySurgeryOrderActivityInProgress:boolean = false;
  private copySetBookingActivityInProgress:boolean = false;
  private copyProcedureTrackerActivityInProgress:boolean = false;
  public backgroundUploadInProgress: boolean = false;
  public syncInProgress: boolean = false;
  private _agendaFooterBtnDblClickHandler = (buttonId: string) => {
    if (buttonId === 'act-active') {
      // First check if today's activity avail
      let dateToCompare: Date = new Date();
      dateToCompare.setHours(0, 0, 0, 0);
      let idx: number = _.sortedIndexBy(this.groupedActivities$.getValue(), { scheduledStart: dateToCompare }, 'scheduledStart');
      const numTotalActivities: number = this.groupedActivities$.getValue().length;

      if (idx < 0 || idx >= numTotalActivities) {
        // If not, scroll to end of the list
        idx = numTotalActivities - 1;
      }
      if (idx >= 0) {
        setTimeout(() => {
          if (this.virtualList) {
            this.virtualList.scrollInto(this.groupedActivities$.getValue()[idx]);
          }
        }, 100);
      }
    }
  };
  private isInitDone: boolean = false;
  private hasAccountVisitEnabled = false;
  private nestedAccountVisitSecondaryLabel = 'Nested Customer Meeting';
  //private linkEntitiesReqPayload: any = [];

  constructor(
    private phonecallDataService: PhoneCallDataService,
    public activityDataService: ActivityDataService,
    public activityService: ActivityService,
    public navCtrl: NavController,
    public footerService: FooterService,
    public logServices: LogService,
    public device: DeviceService,
    public events: EventsService,
    private alertService: AlertService,
    private meetingService: MeetingDataService,
    public contactService: ContactOfflineService,
    private websocket: WebsocketDataService,
    public uiService: UIService,
    private _ngZone: NgZone,
    private _cd: ChangeDetectorRef,
    public authenticationOfflineService: AuthenticationService,
    private trackingService: TrackService,
    public accountOfflineService: AccountOfflineService,
    private navService: NavigationService,
    public repService: RepServices,
    public timeOffService: TimeOffService,
    public sampleDataService: SampleDataService,
    public sampleService: SampleService,
    private coachingReportService: CoachingReportService,
    private elementRef: ElementRef,
    private schedulerService: SchedulerService,
    public popoverCtrl: PopoverController,
    private timeOffUtilityService: TimeOffUtilityService,
    public emailService: EmailService,
    public deltaService: DeltaService,
    private followUpDataService: FollowUpActivityDataService,
    private caseManagementService: CaseManagementService,
    private orderActivityDataService: OrderActivityDataService,
    private caseManagementDataService: CaseManagementDataService,
    public translate : TranslateService,
    public utilityService:GlobalUtilityService,
    private callplanService: CallPlanOfflineService,
    private notificationService: NotificationService,
    public reportService: ReportService,
    public modalCtrl: ModalController,
    private dateTimeFormatService: DateTimeFormatsService,
    public agendaFooterService: AgendaFooterService,
    private xpService: XperiencesService,
    private consentService: ConsentService,
    private surgeryOrderActivityDataService: SurgeryOrderActivityDataService,
    public datepipe: DatePipe,
    private timeOffDataService: TimeOffDataService,
    private loadingController: LoadingController,
    private followUpActivityDataService: FollowUpActivityDataService,
    private resourceService: ResourceService,
    private presentationService: PresentationService,
    private readonly diskService: DiskService,
    private brandService: BrandOfflineService,
    public opportunitiesService: OpportunityManagementService,
    private readonly accountManagementService: AccountManagementOfflineService,
    private orderManagementService: OrderManagementService,
    private eventsToolService: EventsToolService,
    private procedureTrackerActivityDataService: ProcedureTrackerActivityDataService,
    public assistantService: MyAssistantService,
    private localizationService: LocalizationService,
    private readonly setBookingDataService: SetBookingDataService,
    public faService: FeatureActionsService,
    private readonly dynamicFormService: DynamicFormsService,
    public readonly surveyService: SurveyService,
    public secInfoConfigDataService: SecInfoConfigDataService
  ) {
    this.dateDividers = [];
    //this.activityTypes = this.activityService.activityTypes();
    this.activityService.activityFilter = ActivityType.AllActivity;
    this.filterAtHome = this.activityService.activityFilter;
    this.filterValueAtHome = this.translate.instant('HOME');
    //this.activityTypes.unshift('All Activities');
    this.translate.onLangChange.subscribe(data =>{
      // this.translate.use(data.lang);
      this.filterValueAtHome = this.translate.instant('HOME');
      this.tabsData = this.uiService.getAgendaTabs();
      // this.refreshAgenda();
      this.refreshHeader();
      this.events.publish('AgendaRefreshDone');
    });



    this.events.unsubscribe('filterActivitiesDataForSelectedUser');
    this.events.subscribe('filterActivitiesDataForSelectedUser', (data) => {
      if (data === 'UserSelected') {
        this.uiService.displayLoader(null,true);
        this.activityDataService.fetchAgendaTeamViewActivities(this.authenticationOfflineService.impersonatedUser.userId).then(succ=> {
          this.filterAgendaData(data);
          this.navService.setChildNavRoot(NothingSelectedView,PageName.NothingSelectedView,PageName.ActivitiesPageComponent);
          this.uiService.dismissLoader();
        }).catch(error=> {
          this.onStopImpersonation();
          this.uiService.dismissLoader();
        });
        // Team view mode enabled
        this.uiService.showNewActivity = false;
        data = null;
      }else if(data === 'MultipleOptionFilter' && this.activityService.multiOptionActivityFilter){
        if(this.activityService.multiOptionActivityFilter.some(a=> a.categoryPath == "Users" && a.value != this.authenticationOfflineService.user.systemUserID)){
            this.uiService.displayLoader(null,true);
            this.activityService.selectedActivity = undefined;
            this.authenticationOfflineService.impersonatedUser = null;
            this.uiService.activeView = '';
            let userIds = '';
            this.activityService.multiOptionActivityFilter.filter(a=> a.categoryPath == "Users").forEach(b=> {
              if(b.value && b.value != this.authenticationOfflineService.user.systemUserID){
                if(!this.authenticationOfflineService.impersonatedUser){
                  if(this.authenticationOfflineService.user?.buSettings["indskr_homepageteamview"]===548910001){
                    let allUsers =[...this.authenticationOfflineService.user.childUsers,...this.authenticationOfflineService.user.siblingUsersCommonParent, ...this.authenticationOfflineService.user.siblingUsersCommonPositions];
                    this.authenticationOfflineService.impersonatedUser = allUsers?.find(a=> a.userId == b.value);
                  }
                  else
                    this.authenticationOfflineService.impersonatedUser = this.authenticationOfflineService.user.childUsers.find(a=> a.userId == b.value);
                }
                userIds+= b.value+',';
              }
            });
            userIds = userIds.slice(0, -1);
            this.activityDataService.fetchAgendaTeamViewActivities(userIds,true).then(succ=> {
              this.filterAgendaData('MultipleOptionFilter');
              this.navService.setChildNavRoot(NothingSelectedView,PageName.NothingSelectedView,PageName.ActivitiesPageComponent);
              this.uiService.dismissLoader();
            }).catch(error=> {
              this.onStopImpersonation();
              this.uiService.dismissLoader();
            });
            // Team view mode enabled
            this.uiService.showNewActivity = false;
            data = null;
          }else{
            this.filterAgendaData('MultipleOptionFilter');
          }
      }else{
        this.filterAgendaData(data);
      }
    })

    //TC-243 Whenever user perform sync then update the feature action
    this.events.observe('refreshAgenda')
    .pipe(
      takeUntil(this.ngDestroy$),
      debounceTime(250),
    ).subscribe(() => {
      this.refreshAgenda();
    });

    this.events.observe('refreshTab').pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      this.refreshTab();
    });

    this.events.observe('insertNewActivity').pipe(takeUntil(this.ngDestroy$)).subscribe((activity) => {
      if (this.hasAccountVisitEnabled) {
        // List is not guaranteed to be sorted by dates due to nested meetings
        // Need to come up with better solution
        // but due to given time, resorting to refreshAgenda
        //To resolve performance issue - skip refresh on the following list. As CPU usage reaches 100%, the app is slowing down.
        const skipRefreshList = [PageName.SurveyPageComponent,PageName.CustomerCallPlanPage,PageName.ContactPageComponent,PageName.AccountPageComponent];
        const currentMasterPageName = this.navService.getCurrentMasterPageName();
        if(!skipRefreshList.includes(currentMasterPageName)) {
          this.refreshAgenda();
        }
        return;
      }
      const g = this.groupedActivities$.getValue();
      let i = _.sortedIndexBy(g, activity, 'scheduledStart');
      if (i >= 0) {
        // Check if date header for activity start date exists. If not, add the header as well.
        if (i === 0 || !this._doesDateHeaderExistForGivenActivityDate(g, i - 1, activity)) {
          const header = this._getDateHeader(activity);
          g.splice(i++, 0, header);
        }
        g.splice(i, 0, activity);
        this.groupedActivities$.next(g);
        this._isAgendaRefreshed = true;
      }
    });

    this.events.observe('scrollToDate').pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      setTimeout(()=>{
        this.scrollToDate();
      },100);
    });

    this.events.observe('activity-pane:selectActivity').pipe(takeUntil(this.ngDestroy$)).subscribe((activity) => {
      this.openActivityDetails(activity);
    });

    this.events.observe('refreshAgendHeader').pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      this.refreshHeader();
      // this will happen if tab is switched from Week to Agenda.
      if (this.agendaFooterService.actSegment === 'agenda'){
        this.events.publish("updateCurrentDate");
        if (this.uiService.toolsActivityActive) {
          this.uiService.toolsActivityActive = false;
          this.refreshAgenda();
        }
      }
    })

    this.events.observe("start-websocket-subscription").pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => {
        if (!this.device.isOffline && this.navService.getPreviousPageName() !== PageName.CoachingActivitiesComponent
          && this.navService.getCurrentPageName() !== PageName.CoachingActivitiesComponent) {
          if (this.activityService.selectedActivity instanceof AppointmentActivity) {
            (async () => this.websocket.removeSubscription(this.activityService.selectedActivity))();
            (async () => this.websocket.subscribeToMeetingTopic(this.activityService.selectedActivity))();
          }
        }
      });

    if(this.device.isNativeApp){
      window.addEventListener('keyboardDidHide', (event)=>{
        setTimeout(()=>{
          if(this.virtualList && !this.navService.isModalViewOpen && this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent){
            this.virtualList.refreshList();
          }
        },0);
      });
    }

     this.uiService.activeView = 'Appointment';

    //if(this.hasEdgeAnalyticsEnabled) this.reportService.initEmbeddedAnalyticsView();
    //this.reportService.initiateOlapCube()
    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.tabsData = this.uiService.getAgendaTabs();
    });

    this.agendaFooterService.agendaFooterBtnDblClick$
    .pipe(
      takeUntil(this.ngDestroy$)
    )
    .subscribe((buttonId: string) => this._agendaFooterBtnDblClickHandler(buttonId));
  }

  @IpcEventListner('open-url')
  private externalUrl(event, data) {
    console.log(data);
    let obj = Utility.getUrlParams(data['url']);
    console.log(this.uiService.activeView);
    if (this.authenticationOfflineService.user.xSystemUserID != obj['userId']) return;
    if (this.device.isOffline) return;
    if (this.uiService.activeView != "Appointment") {
      this.navService.popToRootWithPageTracking();
      this.uiService.activeView = "Appointment";
    }
    if (this.uiService.activeView == "Appointment") {
      this.navService.pushWithPageTracking(NewActivityComponent, PageName.NewActivityComponent);
      this.activityService.invokeCallCenterActivity$.next(true);
      this.activityService.contactCenterActivityInputs$.next(obj);
    }
  }

  private refreshAgenda() {
    const refreshAgendaNonce: number = new Date().getTime();
    this.activityService.refreshAgendaNonce = refreshAgendaNonce;
    this.activityService.refreshAgendaAction$.next({ action: 'Run', nonce: refreshAgendaNonce });
    this.resetFeatureAction();
    this.caseManagementService.reMapDataWithValues(this.accountOfflineService.accounts, this.contactService.contacts);
    //for a manager level user, filter activities based on ownerID;else display all activities that are fetched from API based on position
    if (!this.activityService.teamViewActive) {
      this.activityTimeConflictCheck();
    }
    this.activityService.filterDisplayActivity();

    //To resolve performance issue - skip refresh on the following list. As CPU usage reaches 100%, the app is slowing down.
    const skipRefreshList = [PageName.SurveyPageComponent,PageName.CustomerCallPlanPage,PageName.ContactPageComponent,PageName.AccountPageComponent];
    const currentMasterPageName = this.navService.getCurrentMasterPageName();
    if(!skipRefreshList.includes(currentMasterPageName)) {
      this.groupedActivities$.next(this.createAgendaArrayFromActivities(this.activityService.displayActivities));
    }
    this._isAgendaRefreshed = true;
    this.events.publish('filterChanged', this.activityService.activityFilter);
    // if(this.agendaFooterService.actSegment === 'week') {
    //   this.events.publish('weekview:RefreshUI');
    // }
    this.tabsData = this.uiService.getAgendaTabs();
    this.activityService.refreshAgendaAction$.next({ action: 'Stop', nonce: refreshAgendaNonce });
    this.activityService.refreshAgendaAction$.next({ action: 'Idle', nonce: null });

    // this.nestedAccountVisitSecondaryLabel = this.translate.instant('NESTED_CUSTOMER_MEETING');
    // this.nestedAccountVisitSecondaryLabel = format(activity.scheduledStart,this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale)+' - '+format(activity.scheduledEnd,this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale)
    this.isInitDone = true;
  }

  private refreshTab() {
    this.resetFeatureAction();
    this.tabsData = this.uiService.getAgendaTabs();
  }
  private activityTimeConflictCheck() {
    this.activityService.setConflictCheckState('Running');

    // Sort & reset
    this.activityService.sortAllActivitiesByStartDateTime(true);
    this.eventsToolService.clearConflictActivityIds();

    // Find starting Index
    const startIdx = this.activityService.findAndSetConflictCheckStartIdx();

    try {
      const userId: string = this.authenticationOfflineService.user.xSystemUserID;
      for (let i = startIdx; i < this.activityService.activities.length; i++) {
        const activity = this.activityService.activities[i];
        if (activity.type === ActivityType.Appointment) {
          startRecursiveActivityScheduleConflictFlagging(this.activityService.activities, i, userId, this.hasAccountVisitEnabled);
          startRecursiveEventScheduleConflictFlagging(this.eventsToolService.eventsToolData, activity, userId);
        } else if (activity.type === ActivityType.PhoneCall) {
          if (!this.hasPhoneCallEnabled) {
            continue;
          }
          startRecursiveActivityScheduleConflictFlagging(this.activityService.activities, i, userId, this.hasAccountVisitEnabled);
          startRecursiveEventScheduleConflictFlagging(this.eventsToolService.eventsToolData, activity, userId);
        } else if (activity.type === 'TimeOff' || activity.type === 'TimeOffRequest') {
          if (!this.hasTimeoffEnabled) {
            continue;
          }
          startRecursiveActivityScheduleConflictFlagging(this.activityService.activities, i, userId, this.hasAccountVisitEnabled);
          startRecursiveEventScheduleConflictFlagging(this.eventsToolService.eventsToolData, activity, userId);
        }
      }
      if (this.authenticationOfflineService.user.meetingProximityWarningPeriod > 0) {
        const myMeetingsWithContacts: Activity[] = this.activityService.activities.filter(activity => activity instanceof AppointmentActivity && (activity.meetingOwnerId === userId ||
          (Array.isArray(activity.accompaniedUserList) && activity.accompaniedUserList.findIndex(a => a.id === userId) >= 0)) && !_.isEmpty(activity.contacts));
        if (!_.isEmpty(myMeetingsWithContacts)) {
          for (let i = 0; i < myMeetingsWithContacts.length; i++) {
            startRecursiveActivityCloseByActivityFlagging(myMeetingsWithContacts, i, userId, this.authenticationOfflineService.user.meetingProximityWarningPeriod, this.hasAccountVisitEnabled);
          }
        }      }
    } catch (error) {
      console.error('activityTimeConflictCheck: ', error);
    }
    // Publish conflict check done.
    this.activityService.setConflictCheckState('Done');
  }

  public createAgendaArrayFromActivities(src: Activity[]): Array<object> {
    let returnedArray = [];
    //Go through the source array, keep track of dates, when the day changes, insert header obj
    if (src && src.length > 0) {

      //Filter out activities with no start data and search text
      src = src.filter(ac => {
        if(isValid(ac.scheduledStart)){
          if(this.activityService.activitiesSearchText && this.activityService.activitiesSearchText.length >=1){
            let flag:boolean = this.activityService._checkIfActivityIncludesSearchText(ac, this.activityService.activitiesSearchText);
            if(!flag){
              return false;
            }else{
              return true;
            }
          }else{
            return true;
          }
        }else{
          return false;
        }
      });

      if (this.activityService.teamViewActive) {
        //Presort to be safe
        src.sort((activity1, activity2) => {
          if (activity1.scheduledStart.getTime() === 0) return 0;
          if (activity2.scheduledStart.getTime() === 0) return 0;

          if (activity1.scheduledStart > activity2.scheduledStart) return 1;
          if (activity1.scheduledStart < activity2.scheduledStart) return -1;
          return 1;
        });
      }

      let monitorDateArray = [];
      const accountVisitParentIdAndIdx: { id: string, idxToInsert: number }[] = [];
      const accountVisitNestedMeetingsMap: Map<string, Activity[]> = new Map();

      for (let index = 0; index < src.length; index++) {
        const activity = src[index];
        if(!activity.scheduledStart){
          continue;
        }
        // Filter out not enabled activity types
        if (activity.type === 'TimeOff' || activity.type === 'TimeOffRequest') {
          if (!this.hasTimeoffEnabled) {
            continue;
          }
        }
        if (activity.type === ActivityType.Appointment && activity['indskr_suggest'] && !this.hasSuggestedMeetingsEnabled) {
          continue;
        }
        if (!this.hasSamplingEnabled && activity.type === 'Sample') {
          continue;
        }
        if (!this.hasCustomerInquiries && activity.type === ActivityType.CaseIntake) {
          continue;
        }
        if (activity.type === ActivityType.PhoneCall) {
          if (!this.hasPhoneCallEnabled) {
            continue;
          }
        }
        if (!this.hasEmailEnabled && activity.type === 'Email') {
          // this.emailService.selectedActivity = null;
          // this.activityService.selectedActivity = null;
          continue;
        }
        if (activity.type === ActivityType.FollowUp) {
          if (!this.isFollowUpTasksVisible) continue;
          if (!this.hasSuggestedTaskEnabled && activity['planType'] == FOLLOW_UP_TYPE.SUGGESTED) continue;
          if (activity['planType'] == FOLLOW_UP_TYPE.SUGGESTED && activity['state'] === 2 && activity['status'] === 6) continue;
        }
        if (!this.isOrderActivitiesEnabled && activity.type === ActivityType.Order) {
          continue;
        }
        if (!this.isProcedureLogActivitiesEnabled && activity.type === ActivityType.SurgeryOrder) {
          continue;
        }
        if (!this.isProcedureTrackerActivitiesEnabled && activity.type === ActivityType.ProcedureTracker) {
          continue;
        }
        if (!this.isSetBookingActivitiesEnabled && activity.type === ActivityType.SetBooking) {
          continue;
        }
        if(activity.type === ActivityType.SurgeryOrder &&
          activity.status === 548910005 &&
          this.authenticationOfflineService.user.buSettings &&
          !this.authenticationOfflineService.user.buSettings['indskr_displaycancelledprocedurelogoncalendar']){
            continue;
        }
        if (activity.type === ActivityType.Appointment &&
          activity.status === 4 &&
          this.authenticationOfflineService.user.buSettings &&
          !this.authenticationOfflineService.user.buSettings['indskr_displaycancelledmeetingoncalendar']) {
          continue;
        }
        // Account visit nested meetings
        // Group nested meetings under parent's ID
        // and append them to the parents later
        if (
          this.hasAccountVisitEnabled
          && (activity as AppointmentActivity).indskr_parentcallid
        ) {
          // Nested meetings
          const parentId: string = (activity as AppointmentActivity).indskr_parentcallid;
          let nestedMeetingList: Activity[];
          if (!accountVisitNestedMeetingsMap.has(parentId)) {
            nestedMeetingList = [];
            accountVisitNestedMeetingsMap.set(parentId, nestedMeetingList);
          } else {
            nestedMeetingList = accountVisitNestedMeetingsMap.get(parentId);
          }
          if (Array.isArray(nestedMeetingList)) {
            nestedMeetingList.push(activity);
          }
          continue;
        }
        if (activity.type === ActivityType.Email
          && ((activity['indskr_recommendedmessage'] && !this.hasSuggestedWeComMessagesEnabled)
            || (activity['offlineActivityId'] && activity['offlineActivityId'].indexOf('remoteurl') > -1))) {
          continue;
        }
        if (!this)
          if (this.activityService.selectedActivity && activity.ID === this.activityService.selectedActivity.ID) {
            if (activity.type === ActivityType.Email) {
              this.emailService.selectedActivity = <EmailActivity>activity;
              this.emailService.setCurrentEmail(activity);
            }
          }

        const currentDayOfMonth = activity.scheduledStart.toDateString().substr(0, 10).toUpperCase();

        //Do we have a header for this?
        if (monitorDateArray.indexOf(currentDayOfMonth) >= 0) {
          //we do, ignore and push next element
          returnedArray.push(activity);
        } else {
          //We dont, insert header object at index of this then push next element
          // scheduled start is added to do binary search the whole array later
          const headerObj = this._getDateHeader(activity);
          returnedArray.push(headerObj);

          //Continue adding activities
          returnedArray.push(activity);

          monitorDateArray.push(currentDayOfMonth);
        }

        // Account visit parent
        // Tag parent account visit and it's index in array so that
        // nested children can be appended later
        // Do this at the end since we need to know the exact index to insert children.
        if (
          this.hasAccountVisitEnabled
          && (activity as AppointmentActivity).indskr_isparentcall
        ) {
          accountVisitParentIdAndIdx.unshift({ id: activity.ID, idxToInsert: returnedArray.length });
        }
      }

      // Insert nested meetings to below parents
      if (this.hasAccountVisitEnabled && accountVisitParentIdAndIdx.length > 0) {
        for (const parentIdAndIdx of accountVisitParentIdAndIdx) {
          const nestedMeetings = accountVisitNestedMeetingsMap.get(parentIdAndIdx.id);
          if (Array.isArray(nestedMeetings)) {
            returnedArray.splice(parentIdAndIdx.idxToInsert, 0, ...nestedMeetings);
          }
        }
      }
    }
    this.events.publish('updateActivitiesRHSEmptyPage', returnedArray.length > 0 ? false: true)

    return returnedArray;
  }



  ngOnChanges() {
    //console.log(this.refreshSchedulerList);
  }

  async ngOnInit() {
    this.isAndroid = this.device.isAndroid();

    // Avoid default footer button init from the Short Call Launcher
    if (
      !this.faService.isShortCallLauncherEnabledInMobileDevice
      || this.faService.isShortCallLauncherEnabledInMobileDevice
        && this.agendaFooterService.actSegment === 'agenda'
    ) {
      this.agendaFooterService.initButtons(AgendaFooterView.Act);
    }
    this.uiService.setnewActivityRequest(false);
    this.authenticationOfflineService.getOfflineUser();
    if (this.device.isOffline) {
      if (this.device._isDeviceOfflineFromLogin) {
        this.hasBaselineEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.BASELINE) ? true : false;
        this.hasTimeoffEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL) ? true : false;
        this._hasSchedulerEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SCHEDULER) ? true : false;
        this.hasSamplingEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL) ? true : false;
        this.hasEmailEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY) ? true : false;
        this.hasCustomerInquiries = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE) ? true : false;
        this.hasPhoneCallEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY) ? true :  false;
        //this.isFollowUpTasksVisible = true; // Follow up action : the user will have the category 'None' as a default, regardless of FA.
        // this.isFollowUpTasksVisible = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT) ||
        //                               this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_FOLLOW_UP_TASK) ? true : false;
        this.isFollowUpTasksVisible = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY) ? true : false;
        this.isOrderActivitiesEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT) ? true : false;
        this.isProcedureLogActivitiesEnabled = this.authenticationOfflineService.user.hasProcedureLog;
        this.isProcedureTrackerActivitiesEnabled = this.authenticationOfflineService.user.hasBulkProcedureLog;
        this.hasEdgeAnalyticsEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EDGE_ANALYTICS_MEETING)? true: false;
        this.hasSuggestedMeetingsEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_MEETINGS);
        this.hasSuggestedWeComMessagesEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_WECOM_MESSAGES);
        this.hasSuggestedTaskEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_TASKS);
        this.hasAccountVisitEnabled = !!(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT));
        this.isSetBookingActivitiesEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.KIT_BOOKING);
      } else {
        await this.authenticationOfflineService.getOfflineUser().then((user) => {
          this.hasBaselineEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.BASELINE) ? true : false;
          this.hasTimeoffEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL) ? true : false;
          this._hasSchedulerEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SCHEDULER) ? true : false;
          this.hasSamplingEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL) ? true : false;
          this.hasEmailEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY) ? true : false;
          this.hasCustomerInquiries = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE) ? true : false;
          this.hasPhoneCallEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY) ? true : false;
          //this.isFollowUpTasksVisible = true; // Follow up action : the user will have the category 'None' as a default, regardless of FA.
          // this.isFollowUpTasksVisible = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT) ||
          //                               this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_FOLLOW_UP_TASK)? true : false;
          this.isFollowUpTasksVisible = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY) ? true : false;
          this.isOrderActivitiesEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT) ? true : false;
          this.isProcedureLogActivitiesEnabled = this.authenticationOfflineService.user.hasProcedureLog;
          this.isProcedureTrackerActivitiesEnabled = this.authenticationOfflineService.user.hasBulkProcedureLog;
          this.hasEdgeAnalyticsEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EDGE_ANALYTICS_MEETING)? true: false;
          this.hasSuggestedMeetingsEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_MEETINGS);
          this.hasSuggestedWeComMessagesEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_WECOM_MESSAGES);
          this.hasSuggestedTaskEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_TASKS);
          this.hasAccountVisitEnabled = !!(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT));
          this.isSetBookingActivitiesEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.KIT_BOOKING);
        })
      }
    }
    else {
      this.resetFeatureAction();
    }
    this.tabsData = this.uiService.getAgendaTabs();
    //this.content.resize();
    if (!this._orientationHandler) {
      this._orientationHandler = (modeChange: VIEW_MODE_CHANGE, curPageName: PageName, curMasterPageName: PageName) => {
        if (curMasterPageName === PageName.ActivitiesPageComponent) {
          if (modeChange === VIEW_MODE_CHANGE['normal-to-mobile']) {
            if (this.device.shouldBeMobileView && !this.uiService.showNewActivity
              && this.activityService.selectedActivity) {
              if (this.uiService.activeView === 'Appointment') {
                // this.navService.pushWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent);
                this.uiService.showRightPane = true;
              }
              if ((this.uiService.activeView === 'Sample') && this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent) {
                this.uiService.showRightPane = true;
              }
              if (this.uiService.activeView === 'Email') {
                this.uiService.showRightPane = true;
              }
              if (this.uiService.activeView === 'PhoneCall') {
                this.uiService.showRightPane = true;
              }
              if (this.uiService.activeView === 'customer_inquiry') {
                this.uiService.showRightPane = true;
              }
            }
          }
        }
      };
      this.events.unsubscribe('device:view-mode-change');
      this.events.subscribe('device:view-mode-change', this._orientationHandler);
    }


    this.events.observe('device:deviceIsOffline').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((status) => {
        // the network service indicate that the device is offline so no arguments.
        this.isDeviceOnline = false;
      });

    this.events.observe('device:deviceIsOnline').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((status) => {
        //network service says that internet connection are active
        //checking for device level settings
        //user set the status to offline in toolsdrawer
        if (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState) {
          this.isDeviceOnline = false;
        }
        //user set the status to online in toolsdrawer
        else {
          this.isDeviceOnline = true;
        }
      });


    this.ItabSubscription = this.uiService.tabObserver.subscribe(view => {
      if (view === 'agenda') {
        this.uiService.activitiesPageTab = view;
        if (!this.device.isMobileDevicePortrait && !this.authenticationOfflineService.impersonatedUser) {
        this.uiService.showNewActivity = true;

        }
        // this.tabsGroup.value = 'agenda';
        // this.tabAgenda.checked = true;
        this.uiService.activeView = 'Appointment';
      }
      if (view === 'scheduler') {
        this.uiService.activitiesPageTab = view;
        //this.uiService.showNewActivity = false;
        //this.tabsGroup.value = 'scheduler';
        //this.tabScheduler.checked = true;
        //this.tabAgenda.checked = false;
      }
      if (view === 'edgeAnalytics') {
        this.uiService.activitiesPageTab = view;
      }
      if (view === 'plans') {
        this.uiService.activitiesPageTab = view;
      }
    });

    if (this.device.isNativeApp) {
      this.device.onPauseObservable
        .pipe(
          debounceTime(0),
          takeUntil(this.ngDestroy$)
        )
        .subscribe(() => {
          const snapshotItems = [];
          if (Array.isArray(this.viewPortItems)) {
            for (let i = 0; i < this.viewPortItems.length; i++) {
              const viewPortItem = this.viewPortItems[i];
              if (viewPortItem.isDivider) {
                snapshotItems.push(viewPortItem);
              } else {
                const data = this.getMainCardDataModel(viewPortItem);
                delete data['clickHandler'];
                delete data['expandableData'];
                snapshotItems.push(data);
              }
            }
          }
          this.activityService.storeSnapshot(snapshotItems);
        });
    }

    if(this.activityService.selectedActivityOnGenee) {
      this.activityService.selectedActivity = this.activityService.selectedActivityOnGenee;
      this.uiService.showNewActivity = false;
    }


    switch (this.translate.currentLang) {
        case 'es' :
          this.date_fns_locale =  {locale : es}
          break;
        case 'fr' :
          this.date_fns_locale =  {locale : fr}
          break;
        case 'ja' :
          this.date_fns_locale =  {locale : ja}
          break;
        case 'tr':
          this.date_fns_locale = { locale: tr }
          break;
        case 'pt':
          this.date_fns_locale = { locale: pt }
          break;
        case 'it':
          this.date_fns_locale = { locale: it }
          break;
        case 'en' :
          this.date_fns_locale =  {locale : en}
          break;
    }

    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      this.backgroundUploadInProgress = inProgress;
      this._cd.detectChanges();
    });
    this.device.syncInProgress$.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      this.syncInProgress = inProgress;
      this._cd.detectChanges();
    });
  }



  public segmentClick(){
    if (this.uiService.activitiesPageTab === 'agenda') {
      this.agendaFooterService.actSegment = 'agenda';
      this.agendaFooterService.initButtons(AgendaFooterView.Act);
    }
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    if (this._orientationHandler) {
      this.events.unsubscribe('device:view-mode-change', this._orientationHandler);
      this._orientationHandler = null;
    }
    //please do not unsubscribe to these events. These are crucial events that need to be detected by app.
    //unsubsribing it like this will unsubscribe it from all the places.
    //this.events.unsubscribe('device:deviceIsOffline');
    //this.events.unsubscribe('device:deviceIsOnline');
    this.events.unsubscribe('scrollToDate');
    this.ItabSubscription.unsubscribe();
  }

  ngAfterViewChecked() {
    if ((this.device.shouldBeMobileView && !this.uiService.showRightPane) || !this.device.shouldBeMobileView || this.activityService.inboundMeetingFlagForActivitiesPane) {

      if (this._isSegmentChanged && !this.activityService.isNewActivityCreated && this.uiService.activitiesPageTab === 'agenda' && this.virtualList) {
        //this.virtualList.refreshList();
        if (this.activityService.selectedActivity) {
          setTimeout(() => {
            let activityToScrollInto = this.activityService.selectedActivity;
            // Check if it's account visit's nested meeting.
            // If so, make it to scroll into the parent.
            const accountVisitRecordCheckResponse = this.activityService.selectedActivity instanceof AppointmentActivity
              ? this.activityService.accountVisitRecordCheck(this.activityService.selectedActivity as AppointmentActivity)
              : undefined;
            if (accountVisitRecordCheckResponse?.isAccountVisitNestedMeeting) {
              const parent = this.activityService.getActivityByID((activityToScrollInto as AppointmentActivity).indskr_parentcallid);
              activityToScrollInto = parent ? parent : activityToScrollInto;
            }

            this.virtualList.scrollInto(activityToScrollInto);
            if (!(this.device.isMobileDevice && this.device.deviceOrientation === "PORTRAIT")) {
              this.openActivityDetails(this.activityService.selectedActivity);
            }
          }, 400);
        } else {
          this.scrollToDate();
        }
        this._isSegmentChanged = false;
      }
      if (this.activityService.shouldScrollToPrevSelectedActivity && this.virtualList) {
        if (this.activityService.prevSelectedActivity) {
          let activityToScrollInto = this.activityService.prevSelectedActivity;
          this.activityService.shouldScrollToPrevSelectedActivity = false;
          const items = this.virtualList.getItems();
          if (this.activityService.prevSelectedActivity.type === 'Sample' && Array.isArray(items) && items.length > 0) {
            const idx = items.findIndex((o) => o.ID === this.activityService.prevSelectedActivity.ID);
            if (idx >= 0) {
              this.activityService.prevSelectedActivity = items[idx];
            }
          }

          // Check if it's account visit's nested meeting.
          // If so, make it to scroll into the parent.
          const accountVisitRecordCheckResponse = this.activityService.selectedActivity instanceof AppointmentActivity
            ? this.activityService.accountVisitRecordCheck(this.activityService.selectedActivity as AppointmentActivity)
            : undefined;
          if (accountVisitRecordCheckResponse?.isAccountVisitNestedMeeting) {
            const parent = this.activityService.getActivityByID((activityToScrollInto as AppointmentActivity).indskr_parentcallid);
            activityToScrollInto = parent ? parent : activityToScrollInto;
          }
          this.virtualList.scrollInto(activityToScrollInto);
        }
      }
      if (this.activityService.isComingFromOtherPage && this.virtualList) {
        if (this.activityService.selectedActivity) {
          this.openActivityDetails(this.activityService.selectedActivity);
        }
        this.activityService.isComingFromOtherPage = false;
      }
      if (this.activityService.isNewActivityCreated && this.virtualList) {
        // Make it to invoke on the next tick
        if (this.activityService.selectedActivity) {
          this.openActivityDetails(this.activityService.selectedActivity, false, true);
        }
        this.activityService.isNewActivityCreated = false;
      }
      if (this.activityService.inboundMeetingFlagForActivitiesPane && this.virtualList) {
        // Make it to invoke on the next tick
        if (this.activityService.selectedActivity) {
          this.openActivityDetails(this.activityService.selectedActivity, true, true);
        }
        this.activityService.inboundMeetingFlagForActivitiesPane = false;
      }
      if ((!this.activityService.scrolledOnce || this._isAgendaRefreshed) && !this.activityService.isNewActivityCreated && this.virtualList && this.groupedActivities$.getValue().length > 0) {

        if ((this.virtualList['visibleChildren'] < this.groupedActivities$.getValue().length && this.virtualList['previousEnd'] >= this.virtualList['visibleChildren'] && this.virtualList['previousStart'] > 0) ||
          (this.virtualList['visibleChildren'] >= this.groupedActivities$.getValue().length && this.virtualList['previousStart'] >= 0)
        ) {
          this.activityService.scrolledOnce = true;
          this._isAgendaRefreshed = false;
          this._scrollToInvokeCounter = 0;
          if(!this._checkIfSelectedActivityIsInView){
            this.scrollToDate();
          }
        } else {
          this._scrollToInvokeCounter++;
          // To prevent infinite loop. It's not an elegant solution for sure..
          if (this._scrollToInvokeCounter > 10) {
            this.activityService.scrolledOnce = true;
            this._isAgendaRefreshed = false;
            this._scrollToInvokeCounter = 0;
          }
          if(!this._checkIfSelectedActivityIsInView){
            this.scrollToDate();
          }
        }
      }
    }
  }

  ionViewDidEnter (){
    this.scrollToDate();
  }

  private get _checkIfSelectedActivityIsInView():boolean{
    let flag:boolean = false;
    if(this.activityService.selectedActivity && this.virtualList['storedItems'] && this.virtualList.storedItems.length > 0 && this.virtualList['previousStart'] >= 0 && this.virtualList['previousEnd'] >= 0){
      for(let idx=this.virtualList['previousStart'];idx<this.virtualList['previousEnd'];idx++){
        if(this.virtualList['storedItems'][idx] && !this.virtualList['storedItems'][idx].isDivider && this.virtualList['storedItems'][idx].ID == this.activityService.selectedActivity.ID){
          flag = true;
          break;
        }
      }
    }
    return flag;
  }

  scrollToDate() {
    let dateToCompare = new Date();
    if (this.activityService.selectedActivity) {
      if (this.lastActiveSegment == 'agenda' && this.activityService.selectedActivityOnAgenda) {
        if (this.activityService.selectedActivity && this.activityService.selectedActivityOnAgenda && this.activityService.selectedActivity.ID !== this.activityService.selectedActivityOnAgenda.ID)
          this.openActivityDetails(this.activityService.selectedActivityOnAgenda);
      }
      dateToCompare = new Date(this.activityService.selectedActivity.scheduledStart);
    } else if (this.activityService.isComingBackFromDetailPage && this.activityService.prevSelectedActivity) {
      dateToCompare = new Date(this.activityService.prevSelectedActivity.scheduledStart);
    } else {
      if (this.activityService.getScrollDate){
        dateToCompare = new Date(this.activityService.getScrollDate);
      }
      dateToCompare.setHours(0);
      dateToCompare.setMinutes(0);
      dateToCompare.setSeconds(0);
      dateToCompare.setMilliseconds(0);
    }


    let idx = _.sortedIndexBy(this.groupedActivities$.getValue(), { scheduledStart: dateToCompare }, 'scheduledStart');
    if(idx >= this.groupedActivities$.value?.length && idx != 0){
      dateToCompare = _.cloneDeep(this.groupedActivities$.value[idx-1].scheduledStart);
      dateToCompare.setHours(0);
      dateToCompare.setMinutes(0);
      dateToCompare.setSeconds(0);
      dateToCompare.setMilliseconds(0);
      idx = _.sortedIndexBy(this.groupedActivities$.getValue(), { scheduledStart: dateToCompare }, 'scheduledStart');
    }
    if (idx >= 0) {
      setTimeout(() => {
        if (this.virtualList) this.virtualList.scrollInto(this.groupedActivities$.value[idx]);
        //this.content.resize();
      }, 100);
    }
    this.activityService.selectedActivityOnAgenda = undefined;
  }

  resetFeatureAction() {
    //if (!this.device.isOffline) {
      this.hasBaselineEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.BASELINE) ? true : false;
      this.hasTimeoffEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.TIME_OFF_TOOL) ? true : false;
      this.hasSamplingEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL) ? true : false;
      this.hasEmailEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY) ? true : false;
      this.hasPhoneCallEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY) ? true : false;
      this.hasCustomerInquiries = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE) ? true : false;
      this._hasSchedulerEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SCHEDULER) ? true : false;
      this.isOrderActivitiesEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT) ? true : false;
      this.isProcedureLogActivitiesEnabled = this.authenticationOfflineService.user.hasProcedureLog;
      this.isProcedureTrackerActivitiesEnabled = this.authenticationOfflineService.user.hasBulkProcedureLog;
      this.hasEdgeAnalyticsEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EDGE_ANALYTICS_MEETING)? true: false;
      this.hasSuggestedMeetingsEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_MEETINGS);
      this.hasSuggestedWeComMessagesEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_WECOM_MESSAGES);
      this.hasSuggestedTaskEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SHOW_SUGGESTED_TASKS);
      this.hasAccountVisitEnabled = !!(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ACCOUNT_VISIT));
      this.isSetBookingActivitiesEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.KIT_BOOKING);
      if (this.hasSchedulerEnabled) {
        this.schedulerService.mapSchedulerPatterns();
      }
    //}
// Not sure Why All these feature actions are reset only in online case, Moving out Follow up action from teh offline constrinat for OMNI-22997: Offline>Follow-up is not visible in Agenda after visiting plan tab or measure Tab
//This afftects on Line number 474 it comes as false which skips the loop and follow up action is not visible.
    this.isFollowUpTasksVisible = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_ACTIVITY) ? true : false;

  }


  /**
   * Custom track by fn for performance
   * @param index
   * @param item
   */
  tbFn(index, item) {
    //if(item.list){
    return item.list[0].id;
    //}
  }

  /**
   *
   *
   * @private
   * @param {any} currentDate
   * @memberof ActivitiesPaneComponent
   */
  // tslint:disable-next-line:prefer-function-over-method
  private scrollTo(currentDate: any) {
    //now the divider is tagged based on their given dates , get the id of each divider and call the MSDN-DOM routine on it or use the content scrollInto routine
    let ele: any = document.getElementById(currentDate);
    if (ele) {
      ele.scrollIntoView(true);
      let yOffset = ele.offsetTop;
      this.content.scrollTo(0, 0);
    }
  }


  ngAfterViewInit() {
    this.activityService.scrolledOnce = false;
    this.scrollDate = new Date(this.activityService.getScrollDate);
    //this.content.resize()
  }

  /**
   * Returns true if we have nothing to show for yesterday today and tomorrow, used to show the no activities message
   *
   * @readonly
   * @type {boolean}
   * @memberof ActivitiesPaneComponent
   */
  get displayNoActivities(): boolean {
    if (this.activityService.activityFilter === ActivityType.AllActivity) {
      if (this.activityService.activities && this.activityService.getActivitiesByStartDate) {
        return (this.activityService.getActivitiesByStartDate.length == 0);
      }
    } else if (this.activityService.activityFilter === ActivityType.Appointment) {
      if (this.activityService.filteredAppointments) {
        return (this.activityService.filteredAppointments.length == 0);
      }
    } else if (this.activityService.activityFilter === ActivityType.TimeOff) {
      if (this.activityService.filteredTimeOffs) {
        return (this.activityService.filteredTimeOffs.length == 0);
      }
    }
    return false;
  }

  /**
   * Gets activity icons based on activity type
   *
   * @memberof ActivitiesPaneComponexnt
   */
  getTypeImage = (activityType): string => {
    return this.activityService.getActivityIcon(activityType);
  }
  /**
   * Expand activity time and delete options
   *
   * @memberof ActivitiesPaneComponent
   */
  expandList = (event, activityItem: Activity): void => {
    activityItem.expanded = !activityItem.expanded;
    activityItem.expandIcon = (activityItem.expanded) ? 'accordion-minus' : 'accordion-add';
    if(event){
      event.stopPropagation();
    }
  }

  private _showRightPane(isNewlyCreatedActivity: boolean) {
    this.uiService.showRightPane = true;
    setTimeout(() => {
      this._isAgendaRefreshed = isNewlyCreatedActivity;
    }, 0);
  }
  /**
   * Opens Activity details screen by setting activity service selected property
   *
   * @memberof ActivitiesPaneComponent
   */
  async openActivityDetails(activity, isInboundFlow = false, isNewlyCreatedActivity = false) {
    let flag = await this.setBookingDataService.checkSetBookingDiscardConfirmationRequired().catch(err=>{});
    if(flag){
      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'ActivitySkeleton';
      this._cd.detectChanges();
      setTimeout(()=>{
        this.handleOpenActivity(activity, isInboundFlow = false, isNewlyCreatedActivity);
      },1)
    }
  }

  public handleOpenActivity(activity, isInboundFlow = false, isNewlyCreatedActivity = false) {
    //this._cd.detach();
    if(this.activityService.prevSelectedActivity as SetBookingActivity && this.activityService.selectedActivity as AppointmentActivity
        && isNewlyCreatedActivity && this.activityService.isNewMeetingFlow && this.activityService.isLayOverPushedMeetingFromOtherActivities)  return;
    this.presentationService.clearReferencesCache();
    this.uiService.showCancelDoneOnActivityDetails = false;
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent); // Remove once agenda RHS is replaced with ion-nav
    this.activityDataService.activityDetailsLoaded = isInboundFlow ? true : false;
    if (!activity.ID || isNewlyCreatedActivity || (activity.ID && activity.ID.includes('offline'))) {
      //offline created activity
      this.activityDataService.activityDetailsLoaded = true;
    }
    this.timeOffService.totMode = 'myRequests';
    this.uiService.showNewActivity = false;
    this.uiService.showFieldCoaching = false;
    let prevActivity = this.activityService.prevSelectedActivity;
    let foundActivity = this.activityService.getActivityByID(activity.ID);

    if(foundActivity){
      this.activityService.selected = foundActivity;
    }else{
      this.activityService.selected = activity;
    }

    // this.events.publish("updateTherapeuticarea");

    //Clear old selected accounts
    this.accountOfflineService.selectedAccounts = [];
    this.accountOfflineService.selected = null;
    if (activity.type !== ActivityType.Email && this.emailService.viewType != EmailViewType.CREATE_FROM_MEETING && this.emailService.viewType != EmailViewType.EMAIL_FROM_MEETING_PRESENTATION) {
      this.emailService.selectedActivity = null;
      this.emailService.setCurrentEmail(undefined);
    }

    if (activity.type === ActivityType.CaseIntake) {
      if(!activity.ID) {
        activity.ID = activity.offline_ID;
        activity._case_status_value = "Open";

        // let temp = this.caseManagementService.myCases.find(e => e.offline_ID === activity.offline_ID);
        // activity = temp ? temp : activity;
      };
      if(!this.authenticationOfflineService.impersonatedUser){
        this.activityDataService.fetchCaseDetails(activity);
      }
    }

    switch (activity.type) {
      case ActivityType.Appointment:
        // this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        this.navService.setChildNavRoot(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent, { isNewlyCreatedActivity: this.activityService.isNewActivityFlow });
        break;
      case ActivityType.PhoneCall:
        if (this.device.shouldBeMobileView) {
          this._showRightPane(isNewlyCreatedActivity);
        }
        break;
      case ActivityType.Email:
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.emailService.viewType = EmailViewType.FROM_AGENDA;
        if (this.device.shouldBeMobileView) {
          this._showRightPane(isNewlyCreatedActivity);
        }
        this.emailService.selectedActivity = <EmailActivity>this.activityService.selectedActivity;
        this.navService.setChildNavRoot(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ActivitiesPageComponent, { from: PageName.ActivitiesPageComponent });
        break;

      case ActivityType.CaseIntake:
        this.caseManagementService.accessedFrom = AccesingMode.AGENDA;
        if (this.device.shouldBeMobileView) {
          this._showRightPane(isNewlyCreatedActivity);
        }
        break;

      case ActivityType.Sample:
        this.sampleService.samplingDetailsViewMode = SamplingDetailsViewMode.VIEW_DETAILS;
        if (this.device.shouldBeMobileView) {
          this._showRightPane(isNewlyCreatedActivity);
        }
        this.navService.setChildNavRoot(NewSampleActivityComponent, PageName.NewSampleActivityComponent, PageName.ActivitiesPageComponent);
        this.events.publish('selectedActivityChangedInBackround');
        break;
      case ActivityType.StoreCheck:
        if (this.device.shouldBeMobileView) {
          this._showRightPane(isNewlyCreatedActivity);
        }
        this.navService.setChildNavRoot(StoreCheckDetailsComponent, PageName.StoreCheckDetailsComponent, PageName.ActivitiesPageComponent);
        break;

      case ActivityType.FollowUp:
        console.log(activity);
        // TODO SAP: Do Plan type check
        if(activity.scientificPlanId || (activity.planType && activity.planType == FOLLOW_UP_TYPE.SCIENTIFIC_PLAN)) {
          this.events.publish('updateCurrentFollowUpActivity');
          this.navService.setChildNavRoot(ScientificPlanFollowUpTask, PageName.ScientificPlanFollowUpTask, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: this.activityService.selectedActivity });
        } else {
          this.navService.setChildNavRoot(FollowUpActionDetailComponent, PageName.FollowUpActionDetailComponent, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: this.activityService.selectedActivity });
        }
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        break;

      case ActivityType.Order:
          this.trackingService.tracking('AgendaOrderInfo', TrackingEventNames.ORDERMANAGEMENT);
        this.navService.setChildNavRoot(OrderDetailsComponent, PageName.OrderDetailsComponent, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: this.activityService.selectedActivity });
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        break;

      case ActivityType.TimeOff:
      case ActivityType.TimeOffRequest:
        if (this.device.shouldBeMobileView) {
          this.timeOffService.setMobileTracker('agenda');
          this._showRightPane(isNewlyCreatedActivity);
        }
        break;
      case ActivityType.SurgeryOrder:
        this.navService.setChildNavRoot(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: this.activityService.selectedActivity });
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        if (this.device.shouldBeMobileView) {
          this._showRightPane(isNewlyCreatedActivity);
        }
        //return;
        break;
      case ActivityType.ProcedureTracker:
        this.navService.setChildNavRoot(ProcedureTrackerDetailsComponent, PageName.ProcedureTrackerDetailsComponent, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: this.activityService.selectedActivity });
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        if (this.device.shouldBeMobileView) {
          this._showRightPane(isNewlyCreatedActivity);
        }
        break;
      case ActivityType.SetBooking:
        this.navService.setChildNavRoot(SetBookingActivityDetailsComponent, PageName.SetBookingActivityDetailsComponent, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: this.activityService.selectedActivity });
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        return;
        // Set Booking has a different flow for fetching data through configured forms
        break;
      default:
        break;
    }

    if (isInboundFlow) {
      this.activityService.inboundMeetingFlagForActivitiesDetailsPane = true;
    }
    else {
      if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.COACHING_TOOL) && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.COACHING_TEAM_VIEW)) {
        this.coachingReportService.setActivityCoaching(this.activityService.selectedActivity);
      }
      this._ngZone.runOutsideAngular(() => {
        if (!isNewlyCreatedActivity && prevActivity) {
          this.websocket.sendRemoteMeetingEnd(prevActivity, true);
          this.websocket.removeSubscription(prevActivity);
        }
        if(!this.authenticationOfflineService.impersonatedUser){
          if (!isNewlyCreatedActivity) {
            this.activityDataService.updateActivityDetails(activity);
          } else {
            this.activityDataService._appendMeetingDetailsToActivity(activity);
          }
        }
      });

      if (!this.device.isOffline && !activity.ID.includes('offline') && !this.authenticationOfflineService.impersonatedUser) {
        if (this.activityService.selectedActivity instanceof AppointmentActivity) {
          (async () => this.websocket.subscribeToMeetingTopic(this.activityService.selectedActivity))();
        }
      }
    }

    if (this.activityService.selectedActivity && !isInboundFlow) {
      let activity = this.activityService.selectedActivity;
      switch (activity.type) {
        case ActivityType.Appointment:
          this.footerService.initButtons(FooterViews.Activities);
          break;
        case ActivityType.PhoneCall:
          this.navService.setChildNavRoot(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.ActivitiesPageComponent);
          this.footerService.initButtons(FooterViews.PHONE_CALL);
          break;
        case ActivityType.Email:
          this.footerService.initButtons(FooterViews.EmailDetails);
          break;
        case ActivityType.TimeOff:
          let tot: TimeOffActivity = <TimeOffActivity>this.activityService.selectedActivity;
          tot.name = tot.subject;
          tot.totStartTime = tot.scheduledStart;
          tot.totEndTime = tot.scheduledEnd;
          tot.totIsAlldayEvent = tot.totIsAlldayEvent != undefined ? tot.totIsAlldayEvent : tot.allDayEvent;
          this.timeOffService.totMode = 'myRequests';
          this.loadTimeOffDetailsView(tot);
          break;
        case ActivityType.TimeOffRequest:
          let tor: TimeOffActivity = <TimeOffActivity>this.activityService.selectedActivity;
          this.timeOffService.totMode = 'myRequests';
          tor.totStartTime = tor.scheduledStart;
          tor.totEndTime = tor.scheduledEnd;
          this.loadTimeOffDetailsView(tor);
          break;
        case ActivityType.Sample:
          this.footerService.initButtons(FooterViews.SampleDropDetails);
          break;
        case ActivityType.CaseIntake: {
          try {
            this.caseManagementService.accessedFrom = AccesingMode.AGENDA;
            this.caseManagementService.assignSelectedCase(<CaseActivity>activity);
          } catch (error) { }
          this.uiService.activeView = "ActivitiesPageRightPaneNav";
          this.navService.setChildNavRoot(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent, PageName.ActivitiesPageComponent);
          this.footerService.initButtons(FooterViews.CUSTOMER_INQUIRY);
          break;
        }
        case ActivityType.SurgeryOrder:
          this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
          break;
        default:
          this.footerService.initButtons('');
      }
    }

    this.activityService.selectedActivityAtHome = this.activityService.selectedActivity;

    // If split view, scroll to selected activity
    // if (!this.device.shouldBeMobileView && this.virtualList) {
    //   if (!this.activityService.didOpenDetailPageFromAgendaPage) {
    //     this.virtualList.scrollInto(this.activityService.selectedActivity);
    //   }
    //   else {
    //     this.activityService.didOpenDetailPageFromAgendaPage = false;
    //   }
    // }

    //Emit event to let products and key message component clear its data
    this.events.publish('activities-pane:activitySelected');
    this.events.publish('scrollToTop');
    if (!isInboundFlow) {
      this.contactService.resetContactConnectionStatus();
    }

    if (this.activityService.selectedActivity instanceof AppointmentActivity) {
      this.events.publish('activities:appConfigFields');
    }

    if (this.activityService.selectedActivity instanceof PhoneActivity) {
      this.events.publish('phoneactivity:appConfigFields');
    }

    if (this.activityService.selectedActivity instanceof EmailActivity) {
      this.events.publish('emailActivity:loadChannels');
    }

    //this._cd.reattach();
    //this._cd.markForCheck();
  }

  private loadTimeOffDetailsView(tot: TimeOffActivity) {
    this.timeOffService.setSelectedTot(tot);
    this.uiService.activeView = "TimeOffDetail";
    this.navService.setChildNavRoot(TimeOffDetailsComponent, PageName.TimeOffDetailsComponent, PageName.ActivitiesPageComponent);
    this.footerService.initButtons(FooterViews.TimeOffDetails);
  }

  /**
   * Open New Activity Screen
   *
   * @memberof ActivitiesPaneComponent
   */
  async openNewActivity() {
    let flag = await this.setBookingDataService.checkSetBookingDiscardConfirmationRequired().catch(err=>{});
    if(!flag) return;
    if(this.websocket.meetingInProgress || this.activityService.isThereConnectedContactsForCurrentMeeting(<AppointmentActivity>this.activityService.selectedActivity, true)){
      let response = await this.activityService.cancelRemoteMeeting();
      if (response) {
        // if (this.uiService.activitiesPageTab === "scheduler") {
        //   this.uiService.showNewActivity = !this.uiService.showNewActivity;
        //   this.uiService.activeView = 'Appointment';
        //   this.uiService.setnewActivityRequest(true);
        //   this.uiService.activitiesPageTab = 'agenda';
        //   this.tabAgenda.isActive = true;
        //   if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SCHEDULER) && this.tabScheduler) {
        //     this.tabScheduler.isActive = false;
        //   }
        // }
        if (this.activityService.selectedActivity) {
          this.meetingService.updateContactJoinDetailsLeftDate(this.activityService.selectedActivity, true);
          this.websocket.sendRemoteMeetingEnd(this.activityService.selectedActivity, true);
        }
        this.websocket.meetingInProgress = false;
        // this.onAddNewActivityClick.emit();
        this.loadNewActivity();
      }
    } else this.loadNewActivity();
  }

  loadNewActivity(){
    if (this.agendaFooterService.actSegment === "scheduler" || this.agendaFooterService.actSegment === "approvals") {
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
      this.uiService.showNewActivity = !this.uiService.showNewActivity;
      this.uiService.activeView = 'Appointment';
      this.uiService.setnewActivityRequest(true);
      this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.ActivitiesPageComponent);
      this.agendaFooterService.initButtons(AgendaFooterView.Act);
      //this.tabAgenda.isActive = true;
      //if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.SCHEDULER) && this.tabScheduler) {
        //this.tabScheduler.isActive = false;
      //}
    }
    this.onAddNewActivityClick.emit();
  }

  async openActivitiesFilter(ev: Event) {
    let flag = await this.setBookingDataService.checkSetBookingDiscardConfirmationRequired().catch(err=>{});
    if(!flag) return;
    console.log('open activities filter popover');
    this.trackingService.tracking('AgendaActivitiesFilterClicked', TrackingEventNames.ACTIVITY)

    if(this.authenticationOfflineService.user.buConfigs['indskr_multicalendar']){ // Check for enabling multi calendar feature
      this._handleActTabActivityFilter();
    } else {
      let popover = await this.popoverCtrl.create({
        component: ActivitiesFilterPopoverComponent,
        cssClass: 'activities-filter',
        event: ev
      })
      popover.present();
      popover.onDidDismiss().then((data) => {
        console.log('data from activity filter', data);
      })
    }
  }

  /**
   * Filter Activity based on select option value
   *
   * @param {any} activityType
   * @memberof ActivitiesPaneComponent
   */
  filterAgendaData(activityType?: string) {
    if (activityType && activityType != 'All Activities' && this.activityService.selectedActivity
      && this.activityService.selectedActivity.type != activityType)  {
        this.activityService.selectedActivity = undefined;
        if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        }
      }
    let isTeamViewUserSelection = false;
    if (!activityType && !this.activityService.multiOptionActivityFilter) {
      activityType = this.activityService.activityFilter;
      isTeamViewUserSelection = true;
    }
    if(this.activityService.multiOptionActivityFilter){
      try {
        let selActivityTypeOptions = this.activityService.multiOptionActivityFilter.filter(a => a.categoryPath == "Activity_Type");
        if(selActivityTypeOptions.length == 0){
          this.activityService.activityFilter = 'All Activities';
        }else{
          this.activityService.activityFilter = selActivityTypeOptions[0].value;
        }
        this.activityService.activityFilterValue = this.translate.instant(this.translateSelectedOptions(ActivityTypeToFilterNameMap[this.activityService.activityFilter]));
        if(this.activityService.multiOptionActivityFilter.length > 1){
          let numOfSelectedPositions = this.activityService.multiOptionActivityFilter.filter(cat => cat.categoryPath === 'Positions').length;
          if((this.activityService.multiOptionActivityFilter.length-numOfSelectedPositions -1) > 0)
            this.activityService.activityFilterValue = this.activityService.activityFilterValue +' +'+(this.activityService.multiOptionActivityFilter.length-numOfSelectedPositions -1);
        }
      } catch (error) {}
    }else{
      console.log(activityType);
      // this.authenticationOfflineService.impersonatedUser = undefined;
      this.activityService.activityFilter = activityType;
      this.activityService.activityFilterValue = ActivityTypeToFilterNameMap[activityType]
      this.activityService.activityFilterValue = this.translateSelectedOptions(ActivityTypeToFilterNameMap[activityType]);
    }

    if(this.activityService.activityFilterValue.indexOf('Customer')>-1){
      this.activityService.activityFilterValue = this.activityService.activityFilterValue.replace('Customer',this.globalCustomerText);
    }
    if(this.activityService.activityFilterValue.indexOf('Stakeholder')>-1){
      this.activityService.activityFilterValue = this.activityService.activityFilterValue.replace('Stakeholder',this.globalCustomerText);
    }
    this.activityService.filterActivities(activityType);

    if (!this.uiService.toolsActivityActive) {
      this.events.publish('refreshAgenda');
    } else {
      this.uiService.agendaRefreshRequired = true;
    }

    if (isTeamViewUserSelection) {
      this.activityService.publishActivityDataFilterEvent({ filterType: 'teamView' });
    } else {
      this.activityService.publishActivityDataFilterEvent({ filterType: 'activityType' });
    }

    this.filterAtHome = this.activityService.activityFilter;
    this.filterValueAtHome = this.activityService.activityFilterValue;
  }

  translateSelectedOptions(selectedOption:string){

    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        this.globalCustomerText = this.translate.instant('CUSTOMER');
        break;
      case 'Stakeholder':
        this.globalCustomerText = this.translate.instant('STAKEHOLDER');
        break;
      default:
        this.globalCustomerText = this.utilityService.globalCustomerText;
        break;
    }
    switch(selectedOption){
     case 'All Activities':
     return 'HOME';
     case 'Meetings':
     return 'MEETINGS';
     case 'Phone Calls':
      return 'PHONE_CALLS';
     case 'Messages':
     return 'MESSAGES';
     case 'Time Off':
     return 'TIME_OFF';
     case 'Joint Meetings':
     return 'JOIN_MEETING';
     case 'Joint Phone Calls':
     return 'JOINT_PHONE_CALLS';
     case 'Allocation Orders':
     return 'ALLOWCATION_ORDERS';
     case 'Follow-up Actions':
     return 'FOLLOW_UP_ACTIONS';
     case 'Follow-up':
     return 'FOLLOW_UP';
     case 'Order':
     return 'NEW_ACTIVITY_ORDER';
     case 'Sales Orders':
     return 'ACCOUNT_TIMELINE_OPTION_ORDERS';
     case 'Surgery Orders':
     return 'PROCEDURE_LOGS';
     case 'Customer Inquiries':
     return  this.translate.instant('CUSTOMER_INQUIRIES', { globalCustomerText: this.globalCustomerText });
     case 'LiveMeet':
     return 'LIVE_MEETING';
     case 'StoreCheck': return 'STORE_CHECKS'
     case 'SetBooking':
      return 'KIT_BOOKINGS'
     default:
     return selectedOption;
    }
}


  onStopImpersonation() {
    this.trackingService.tracking('UserSelectionRemoved', TrackingEventNames.ACTIVITY);
    this.authenticationOfflineService.impersonatedUser = undefined;
    this.activityService.selectedActivity = undefined;
    this.activityService.agendaTeamViewActivities = [];
    if(this.activityService.multiOptionActivityFilter){
      this.activityService.multiOptionActivityFilter = this.activityService.multiOptionActivityFilter.filter(a => a.categoryPath == "Activity_Type");
    }
    //if(this.uiService.activeView == 'ActivitiesPageRightPaneNav'){
      this.navService.setChildNavRoot(NothingSelectedView,PageName.NothingSelectedView,PageName.ActivitiesPageComponent);
    //}
    this.filterAgendaData();
  }

  /**
   * Set List header text based on date
   *
   * @param {any} value
   * @returns {string}
   * @memberof ActivitiesPaneComponent
   */
  public formatHeader(value): string {
    let edge = window.navigator.userAgent.indexOf('Edge/');
    let formatedDate: any;
    if (edge > 0) {
      let tempDate = value.split("-");
      let currentMonthIndex: number;
      let filteredMonth = this.months.filter((month: string, index: number) => {
        let compare = month.substring(0, 3);
        currentMonthIndex = index;
        return compare === tempDate[1];
      });
      let realDate = new Date(Date.parse(tempDate[0] + " " + tempDate[1] + " " + tempDate[2]));
      let dayOfTheWeek = this.weekDay[realDate.getDay() - 1];
      tempDate = dayOfTheWeek + " " + filteredMonth[filteredMonth.length - 1] + " " + tempDate[0];
      formatedDate = parseInt(window.navigator.userAgent.substring(edge + 5, window.navigator.userAgent.indexOf('.', edge)), 10) >= 16 ? tempDate : value;
    } else {
      formatedDate = isToday(value) ? 'Today' : isTomorrow(value) ? 'Tomorrow' : this.getFormattedAndLocalisedDate(value);
    }
    return formatedDate;
  }

  private getFormattedAndLocalisedDate(value: any) {
    return this.datepipe.transform(value, this.dateTimeFormatService.date, undefined, this.translate.currentLang);
  }
  /**
   * Sets Todays header color to Blue
   *
   * @param {any} value
   * @returns
   * @memberof ActivitiesPaneComponent
   */
  public setHeaderColor(value) {
    let headerColor = isToday(value) ? "primary" : 'light';
    return headerColor;
  }

  public isLabelToday(activity):boolean {
    let flag:boolean = false;
    if(activity && activity.scheduledStart){
      const today = new Date();
      const currentDate = today.toDateString().substr(0, 10).toUpperCase();
      if(currentDate == activity.scheduledStart.toDateString().substr(0, 10).toUpperCase()){
        flag = true;
      }
    }
    return flag;
  }

  /**
   *
   *
   * @param {*} date
   * @returns {string}
   * @memberof ActivitiesPaneComponent
   */
  formatActivityGroupDate(date: any): string {
    return format(new Date(date), 'DD-MM-YYYY'); // this is use to tag the item group for agenda
  }

  async segmentChanged(selectedTabValue: string) {
    let flag = await this.setBookingDataService.checkSetBookingDiscardConfirmationRequired().catch(err=>{});
    if(!flag){
      return false;
    }
    const currentActivePageName = this.navService.getActiveChildNavViewPageName();
    const skipFunctionOnThisActivePageList = [PageName.GeneralSettingsComponent];
    if(skipFunctionOnThisActivePageList.includes(currentActivePageName)) return;
    //this.activityService.activityFilter = ActivityType.AllActivity;
    this.trackInsightEvent(selectedTabValue);
    this.uiService.setAgendaTab(selectedTabValue);
    if (selectedTabValue === this.lastActiveSegment) return;
    //this.content.resize();
    this.activityService.activitiesSearchText = '';
    if(this.websocket.meetingInProgress || this.activityService.isThereConnectedContactsForCurrentMeeting(<AppointmentActivity>this.activityService.selectedActivity, true)){
      let response = await this.activityService.cancelRemoteMeeting();
      if (!response) {
        selectedTabValue = this.lastActiveSegment;
        return false;
      } else {
        if (this.activityService.selectedActivity) {
          this.meetingService.updateContactJoinDetailsLeftDate(this.activityService.selectedActivity, true);
          this.websocket.sendRemoteMeetingEnd(this.activityService.selectedActivity, true);
          this.websocket.removeSubscription(this.activityService.selectedActivity);
          this.websocket.meetingInProgress = false;
        }
        this.uiService.activitiesPageTab = selectedTabValue;
      }
    } else {
      this.uiService.activitiesPageTab = selectedTabValue;
    }

    /* TC-730 fixes */
    this.uiService.setnewActivityRequest(false);

    this._isSegmentChanged = true;

    if (this.lastActiveSegment == 'agenda') {
      // this.refreshAgenda();
      // To Reset Activity Filters on ACT Tab OMNI-39002
      if(this.authenticationOfflineService.user.buConfigs['indskr_multicalendar'] && this.activityService.multiOptionActivityFilter?.length > 0){
        this.activityService.activityFilter = ActivityType.AllActivity;
        this.activityService.multiOptionActivityFilter = [];
        this.onStopImpersonation();
      }
      this.uiService.agendaRefreshRequired = true;
      if (this.activityService.selectedActivity) {
        this.activityService.selectedActivityOnAgenda = this.activityService.selectedActivity;
      }
    } else if (this.lastActiveSegment === "scheduler") {
      await this.schedulerService.discardCurrentChanges();
    }

    if (selectedTabValue === "scheduler") {
      this.activityService.selectedActivity = undefined;
      this.uiService.showNewActivity = false;
    }
    //The page related to the tab should be updated through setAgendaTab, but when switcing from Agenda to Plans tab after sync,
    //subscription may not be possible because the initial value and set value are the same. - check with distinctUntilChanged
    //In this case, republish the event
    if (selectedTabValue === 'plans' && !this.uiService.isSetAgendaTab) {
      this.events.publish('setAgendaTabForPlans');
    }

    this.lastActiveSegment = selectedTabValue;

    // this.events.publish('updateActivitiesRHSEmptyPage', this.groupedActivities$.value.length > 0 ? false : true)
    if(selectedTabValue !== 'Impact' && !this.xpService.unSavedChanges)
      this.events.publish('SegmentChnaged');
  }

  trackInsightEvent(type: string) {
    this.uiService.activitiesPageTab = type;
    switch (type) {
      case 'agenda': {
        this.scrollToDate();
        this.trackingService.tracking('AgendaAgendaView', TrackingEventNames.ACTIVITY);
        //this.tabsGroup.value = 'agenda'
        break;
      }

      case 'day': {
        this.trackingService.tracking('AgendaWeekView', TrackingEventNames.ACTIVITY);
        break;
      }

      case 'scheduler': {
        this.trackingService.tracking('AgendaSchedulerClicked', TrackingEventNames.ACTIVITY);
        // this.uiService.showNewActivity = false;
        //this.tabsGroup.value = 'scheduler';
        //this.tabAgenda.checked = false;
        //this.tabScheduler.checked = true;
        break;
      }

      default:
        break;
    }
  }

  onScheduleSelection(event) {
    if (event['isNewScheduler']) {
      this.trackingService.tracking('New Scheduling Route Creation', TrackingEventNames.SCHEDULER);
    } else {
      this.trackingService.tracking('Use of Existing Scheduling Route', TrackingEventNames.SCHEDULER);
    }
    this.scheduleSelected.emit(event);
  }

  removeDeletedSchedulerPattern(event) {
    if (event) {
      this.activityService.selectedActivity = undefined;
      this.uiService.showNewActivity = false;
      this.uiService.activeView = '';
      this.refreshSchedulerList = !this.refreshSchedulerList;
      if (this.device.isMobileDevice) {
        this.uiService.showRightPane = false;
      }
    }
  }

  schedulerClicked() {
    // this.uiService.activitiesPageTab = 'scheduler';
  }

  async confirmRemoteMeetingdisconnect(activity) {
    let response = await this.activityService.cancelRemoteMeeting();
    if (response) {
      this.websocket.meetingInProgress = false;
      this.activityService.didOpenDetailPageFromAgendaPage = true;
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
      this.meetingService.updateContactJoinDetailsLeftDate(this.activityService.selectedActivity, true);
      this.openActivityDetails(activity);
    }
  }

  public getActivitySecondaryTextStringForFollowup(activity: FollowUpActivity): string {
    if (activity) {
      return this.followUpDataService.getActivitySecondaryTextStringForFollowup(activity);
    }
  }

  private getActivitySecondaryTextStringForStoreCheck(activity: StoreCheckActivity) : string{
    return activity && !_.isEmpty(activity.categoryId) ? activity.categoryName : this.translate.instant('XPERIENCES_RELATIONSHIP_NO_CATEGORY');
  }

  public deleteCI(e) {
    console.log(e);
    let state: string = String(e._case_status_value).toLocaleLowerCase();
    if (state != 'open' && state != 'pending sync') {
      return;
    }
    this.caseManagementService.assignSelectedCase(e);

    this.alertService.showAlert({
      header: this.translate.instant('POP_SCRAP_INQUIRY'),
      message: this.translate.instant('POP_R_U_SURE_SCRAP_SELECTED_INQUIRY')}, this.translate.instant('SCRAP')
    ).then (res => {
      if(res.role == "ok") {
        this.trackingService.tracking('InquiryActivityScrapAgenda', TrackingEventNames.CUSTOMER_INQUIRY)
        this.caseManagementService.scrapCustomerInquiry(<CaseActivity>e);
      }
    });
  }


  private _doesDateHeaderExistForGivenActivityDate(activities, previousItemIndex, activity) {
    const prevListItem = activities[previousItemIndex];

    let dateDiff = 0;
    if (!prevListItem.isDivider) {
      dateDiff = differenceInCalendarDays(prevListItem.scheduledStart, activity.scheduledStart);
    }
    return !prevListItem ? false : !(!prevListItem.isDivider && dateDiff !== 0);
  }
  private _getDateHeader(activity) {
    const today = new Date();
    const currentDate = today.toDateString().toUpperCase();

    // The very first activity. Add a header.
    const headerObj = {
      isDivider: true,
      label: activity.scheduledStart.toDateString().toUpperCase(),
      view:{
        id:'',
        isHighlighted: activity.scheduledStart.toDateString().toUpperCase() === currentDate ? true : false,
        title: activity.scheduledStart.toDateString().toUpperCase() === currentDate ? this.translate.instant('TODAY') : this._getFormattedAndLocalisedDate(activity.scheduledStart, 'EEE MMM dd'),//format(activity.scheduledStart,'ddd MMM D')
        controls: [],
      },
      scheduledStart: new Date(
        activity.scheduledStart.getFullYear(),
        activity.scheduledStart.getMonth(),
        activity.scheduledStart.getDate()
      )
    }
    if (headerObj.label === currentDate) {
      headerObj.label = 'Today';
    }
    return headerObj;
  }

    private _getFormattedAndLocalisedDate(value: any, format: string): string {
    return this.datepipe.transform(value, format, undefined, this.translate.currentLang);
  }

  getSearchBarPlaceholderText(){
    let text:string;
    switch(this.uiService.activitiesPageTab){
      case 'agenda':
        text= this.translate.instant('SEARCH_AGENDA');
        break;
      case 'day':
        text= this.translate.instant('SEARCH_WEEK');
        break;
      case 'scheduler':
        text= this.translate.instant('SEARCH_SCHEDULER');
        break;
    }
    return text;
  }

  onInput(ev){
    if(ev && ev.target){
      this.activityService.activitiesSearchText = ev.target.value;
      if (this.uiService.activitiesPageTab == 'agenda' && this.agendaFooterService.actSegment != 'approvals') {
        this.refreshAgenda();
      }
    }
  }

  newSchedule(){
    this.confirmNewPattern = !this.confirmNewPattern;
  }


  public getMainCardDataModel(activity: Activity) {
    let targetActivity = this.activityService.getActivityByID(activity.ID);
    if(targetActivity){
      activity = targetActivity;
    }
    //Time off, Time off request - Formatted time
    let setTimeOffTime = new Date();
    let setTimeOffStartTime = new Date();
    let setTimeOffEndTime = new Date();
    let formattedTimeOffTimeText: string = '';
    let tot: TimeOffActivity = <TimeOffActivity>activity;
    if(activity.type === ActivityType.TimeOff || activity.type === ActivityType.TimeOffRequest) {
      setTimeOffTime.setTime(activity.scheduledStart.getTime());
      switch(tot.durationType) {
        case 'All Day':
          setTimeOffTime.setHours(24);
          setTimeOffTime.setMinutes(0);
          formattedTimeOffTimeText = format(setTimeOffTime,this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale); // all day format
          break;
        case 'Half Day':
          setTimeOffTime.setMinutes(0);
          (activity.scheduledStart.getHours() < 12)?setTimeOffTime.setHours(24):setTimeOffTime.setHours(12);
          formattedTimeOffTimeText = format(setTimeOffTime,this.dateTimeFormatService.timeFormatSelected.segment); // half day format
          break;
        case 'Hourly':
          setTimeOffStartTime.setTime(activity.scheduledStart.getTime());
          setTimeOffEndTime.setTime(activity.scheduledEnd.getTime());
          // Check half day event again because there is no payload related to half day during initialization of agenda list.
          if(activity.scheduledStart.getHours() == 0 && activity.scheduledStart.getMinutes() == 0 && activity.scheduledEnd.getHours() == 11 && activity.scheduledEnd.getMinutes() == 59 ||
            activity.scheduledStart.getHours() == 12 && activity.scheduledStart.getMinutes() == 0 && activity.scheduledEnd.getHours() == 23 && activity.scheduledEnd.getMinutes() == 59) {
            setTimeOffTime.setMinutes(0);
            (activity.scheduledStart.getHours() < 12)?setTimeOffTime.setHours(24):setTimeOffTime.setHours(12);
            formattedTimeOffTimeText = format(setTimeOffTime,this.dateTimeFormatService.timeFormatSelected.segment); // half day format
          } else {
            formattedTimeOffTimeText = format(setTimeOffStartTime,this.dateTimeFormatService.timeFormatSelected.segment)+' - '+format(setTimeOffEndTime,this.dateTimeFormatService.timeFormatSelected.segment); // hourly format
          }
          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
    // Followup time - set default 12Am
    let setFollowUpTime = new Date();
    setFollowUpTime.setHours(24);
    setFollowUpTime.setMinutes(0);

    let viewData: MainCardViewDataModel;
    switch (activity.type) {
      case ActivityType.Appointment: {
        const appointmentHandle: AppointmentActivity = (activity as AppointmentActivity);
        const isNestedAccountVisit: boolean = this.hasAccountVisitEnabled && !!(appointmentHandle.indskr_parentcallid);
        const parentAccountVisitId: string = isNestedAccountVisit ? appointmentHandle.indskr_parentcallid : undefined;
        const isConflicting = (
          (
            !this.hasAccountVisitEnabled
            || (
              this.hasAccountVisitEnabled
              && !isNestedAccountVisit
            )
          )
          && (
            (
              activity.conflictingActivityIds instanceof Map
              && activity.conflictingActivityIds.size > 0
            )
            || (
              activity.closeByActivityIds instanceof Map
              && activity.closeByActivityIds.size > 0
            )
          )
        ) ? true : false

        viewData = {
          id: activity.ID,
          fixedHeight: true,
          isExpandable: true,
          isExpanded: activity.expanded,
          expandableViewType: 'activitiesList',
          expandableData: activity,
          expandHeight: this.itemExpandHeight,
          isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
          primaryTextLeft: isNestedAccountVisit
            ? format(appointmentHandle.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale) + ' - ' + format(appointmentHandle.scheduledEnd, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale)
            : format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale) + ' - ' + format(activity.scheduledEnd, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale),
          //secondaryTextLeft: activity.lengthOfActivity,
          primaryTextRight: isNestedAccountVisit
            ? appointmentHandle.contacts && appointmentHandle.contacts[0]?.fullName || this.translate.instant('MEETING')
            : activity.subject == 'Meeting' ? this.translate.instant('MEETING') : (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.VISIT_AUTO_SUBJECT) && activity.subject == 'Visit') ? this.translate.instant('VISIT') : activity.subject,
          secondaryTextRight: isNestedAccountVisit
            ? undefined
            : (!activity.location || activity.location == 'nan') ? this.translate.instant('NO_LOCATION') : (activity.location == 'No Location' ? this.translate.instant('NO_LOCATION') : activity.location),
          showArrow: false,
          showIcon: true,
          iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
          isConflicting,
          mainItemCssClass: isNestedAccountVisit ? 'first-nested-item' : '',
          clickHandler: (id: string, event, specificTarget) => {
            const foundActivity = this.activityService.getActivityByID(id);
            if (foundActivity) {
              if (specificTarget && specificTarget == 'delete_control') {
                this.events.publish('deleteMeeting', foundActivity);
              }
              else if (specificTarget && specificTarget == 'endArrow') {
                this.expandList(event, activity)
              } else if (specificTarget && specificTarget == 'endIcon') {
                this._handleMoreOptionsPopover(activity, event);
              } else if (!_.isEmpty(foundActivity['indskr_teamslink'])) {
                this.openActivityDetails(foundActivity);
              } else if (this.websocket.meetingInProgress || this.activityService.isThereConnectedContactsForCurrentMeeting(<AppointmentActivity>this.activityService.selectedActivity, true)) {
                if (this.activityService.selectedActivity?.ID !== activity?.ID) {
                  this.confirmRemoteMeetingdisconnect(foundActivity);
                }
              } else {
                this.openActivityDetails(foundActivity);
              }
            }
          }
        };
        break;
      }
      case ActivityType.PhoneCall:
        viewData = {
          id: activity.ID,
          fixedHeight: true,
          isExpandable: true,
          isExpanded: activity.expanded,
          expandableViewType: 'activitiesList',
          expandableData: activity,
          expandHeight: this.itemExpandHeight,
          isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
          primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale),
          //secondaryTextLeft: activity.lengthOfActivity,
          primaryTextRight: this.activityService.getActivitysubject(activity),
          secondaryTextRight: ((activity as PhoneActivity).selectedMobileNumber == "" || (activity as PhoneActivity).selectedMobileNumber == null || (activity as PhoneActivity).selectedMobileNumber == undefined) ? this.translate.instant('NO_CONTACT_NUMBER') : (activity as PhoneActivity).selectedMobileNumber,
          showArrow: false,
          showIcon: true,
          iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
          isConflicting: activity.conflictingActivityIds instanceof Map && activity.conflictingActivityIds.size > 0 ? true : false,
          clickHandler: (id: string, event, specificTarget) => {
            let foundActivity = this.activityService.getActivityByID(id);
            if (foundActivity) {
              if (specificTarget && specificTarget == 'delete_control') {
                this.events.publish('deleteMeeting', foundActivity);
              }
              else if (specificTarget && specificTarget == 'endArrow') {
                this.expandList(event, activity)
              } else if (specificTarget && specificTarget == 'endIcon') {
                this._handleMoreOptionsPopover(activity, event);
              }
              else {
                this.openActivityDetails(foundActivity);
              }
            }
          }
        };
        break;
      case ActivityType.Sample:
        viewData = {
          id: activity.ID,
          fixedHeight: true,
          isExpandable: true,
          isExpanded: activity.expanded,
          expandableViewType: 'activitiesList',
          expandableData: activity,
          expandHeight: this.itemExpandHeight,
          isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
          primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale),
          primaryTextRight: (activity as SampleActivity).orderId ? this.translate.instant('ALLOWCATION_ORDER') + ' - ' + (activity as SampleActivity).orderId : this.translate.instant('ALLOWCATION_ORDER'),
          secondaryTextRight: (!(activity as SampleActivity).contactName) ? this.translate.instant('NO_CONTACT') : (activity as SampleActivity).contactName,
          showArrow: false,
          showIcon: true,
          isSrcAttribute: true,
          iconName: this.activityService.getActivityIcon(activity),
          clickHandler: (id: string, event, specificTarget) => {
            let foundActivity = this.activityService.getActivityByID(id);
            if (foundActivity) {
              if (specificTarget && specificTarget == 'delete_control') {
                this.events.publish('deleteMeeting', foundActivity);
              }
              else if (specificTarget && specificTarget == 'endArrow') {
                this.expandList(event, activity)
              } else if (specificTarget && specificTarget == 'endIcon') {
                this._handleMoreOptionsPopover(activity, event);
              }
              else {
                this.openActivityDetails(foundActivity);
              }
            }
          }
        };
        break;
      case ActivityType.StoreCheck:
          viewData = {
            id: activity.ID,
            fixedHeight: true,
            isExpandable: true,
            isExpanded: activity.expanded,
            expandableViewType: 'activitiesList',
            expandableData: activity,
            expandHeight: this.itemExpandHeight,
            isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
            primaryTextLeft: this.activityService.getActivityDateRange(activity),
            primaryTextRight: activity.subject == 'Store Check' ? this.translate.instant('STORE_CHECK') : activity.subject,
            secondaryTextRight: this.getActivitySecondaryTextStringForStoreCheck(activity as StoreCheckActivity),
            showArrow: false,
            showIcon: true,
            iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
            clickHandler: (id: string, event, specificTarget) => {
              let foundActivity = this.activityService.getActivityByID(id);
              if (foundActivity) {
                if (specificTarget && specificTarget == 'delete_control') {
                  this.events.publish('deleteMeeting', foundActivity);
                } else if (specificTarget && specificTarget == 'endArrow') {
                  this.expandList(event, activity)
                } else if (specificTarget && specificTarget == 'endIcon') {
                  this._handleMoreOptionsPopover(activity, event);
                } else {
                  this.openActivityDetails(foundActivity);
                }
              }
            }
          };
          break;
      case ActivityType.FollowUp:
        viewData = {
          id: activity.ID,
          fixedHeight: true,
          isExpandable: true,
          isExpanded: activity.expanded,
          expandableViewType: 'activitiesList',
          expandableData: activity,
          expandHeight: this.itemExpandHeight,
          isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
          primaryTextLeft: format(setFollowUpTime, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale),
          primaryTextRight: activity.subject == 'Follow-up Action' ? this.translate.instant('FOLLOW_UP_ACTION') : activity.subject,
          secondaryTextRight: this.getActivitySecondaryTextStringForFollowup(activity as FollowUpActivity),
          showArrow: false,
          showIcon: true,
          iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
          clickHandler: (id: string, event, specificTarget) => {
            let foundActivity = this.activityService.getActivityByID(id);
            if (foundActivity) {
              if (specificTarget && specificTarget == 'delete_control') {
                this.events.publish('deleteMeeting', foundActivity);
              }
              else if (specificTarget && specificTarget == 'endArrow') {
                this.expandList(event, activity)
              } else if (specificTarget && specificTarget == 'endIcon') {
                this._handleMoreOptionsPopover(activity, event);
              }
              else {
                this.openActivityDetails(foundActivity);
              }
            }
          }
        };
        break;
      case ActivityType.TimeOff:
        viewData = {
          id: activity.ID,
          fixedHeight: true,
          isExpandable: true,
          isExpanded: activity.expanded,
          expandableViewType: 'activitiesList',
          expandableData: activity,
          expandHeight: this.itemExpandHeight,
          isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
          primaryTextLeft: formattedTimeOffTimeText,
          //secondaryTextLeft: this.timeOffService.getFormattedTimeInterval(activity as TimeOffActivity),
          // primaryTextRight: activity.reason?activity.subject:activity.subject=='Time Off'?this.translate.instant('TIME_OFF'):activity.subject,
          primaryTextRight: activity.subject == 'Time Off' ? this.translate.instant('TIME_OFF') : activity.subject,
          secondaryTextRight: activity.reason ? this.localizationService.getTranslatedString(activity.reason) : this.translate.instant('NO_REASON'),
          showArrow: false,
          showIcon: true,
          iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
          isConflicting: activity.conflictingActivityIds instanceof Map && activity.conflictingActivityIds.size > 0 ? true : false,
          clickHandler: (id: string, event, specificTarget) => {
            let foundActivity = this.activityService.getActivityByID(id);
            if (foundActivity) {
              if (specificTarget && specificTarget == 'delete_control') {
                this.events.publish('deleteMeeting', foundActivity);
              }
              else if (specificTarget && specificTarget == 'endArrow') {
                this.expandList(event, activity)
              } else if (specificTarget && specificTarget == 'endIcon') {
                this._handleMoreOptionsPopover(activity, event);
              }
              else {
                this.openActivityDetails(foundActivity);
              }
            }
          }
        };
        break;
      case ActivityType.TimeOffRequest:
        viewData = {
          id: activity.ID,
          fixedHeight: true,
          isExpandable: true,
          isExpanded: activity.expanded,
          expandableViewType: 'activitiesList',
          expandableData: activity,
          expandHeight: this.itemExpandHeight,
          isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
          primaryTextLeft: formattedTimeOffTimeText,
          //secondaryTextLeft: this.timeOffService.getFormattedTimeInterval(activity as TimeOffActivity),
          primaryTextRight: activity.subject == 'Time Off' ? this.translate.instant('TIME_OFF') : activity.subject,
          secondaryTextRight: activity.reason ? this.localizationService.getTranslatedString(activity.reason) : this.translate.instant('NO_REASON'),
          showArrow: false,
          showIcon: true,
          iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
          isConflicting: activity.conflictingActivityIds instanceof Map && activity.conflictingActivityIds.size > 0 ? true : false,
          clickHandler: (id: string, event, specificTarget) => {
            let foundActivity = this.activityService.getActivityByID(id);
            if (foundActivity) {
              if (specificTarget && specificTarget == 'delete_control') {
                this.events.publish('deleteMeeting', foundActivity);
              }
              else if (specificTarget && specificTarget == 'endArrow') {
                this.expandList(event, activity)
              } else if (specificTarget && specificTarget == 'endIcon') {
                this._handleMoreOptionsPopover(activity, event);
              }
              else {
                this.openActivityDetails(foundActivity);
              }
            }
          }
        };
        break;
      case ActivityType.Email:
        viewData = {
          id: activity.ID,
          fixedHeight: true,
          isExpandable: true,
          isExpanded: activity.expanded,
          expandableViewType: 'activitiesList',
          expandableData: activity,
          expandHeight: this.itemExpandHeight,
          isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
          primaryTextLeft: this.emailService.setTimeForMessages(activity as EmailActivity), //Time sent
          primaryTextRight: this.emailService.setPrimaryTextRightForMessages(activity as EmailActivity), //Customer Name
          secondaryTextRight: this.emailService.setSecondaryTextForMessages(activity as EmailActivity), //Channel name
          secondaryTextRightTwo: this.emailService.setSecondaryTextTwoForMessages(activity as EmailActivity), //Template subject
          showArrow: false,
          showIcon: true,
          iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
          clickHandler: (id: string, event, specificTarget) => {
            let foundActivity = this.activityService.getActivityByID(id);
            if (foundActivity) {
              if (specificTarget && specificTarget == 'delete_control') {
                this.events.publish('deleteMeeting', foundActivity);
              }
              else if (specificTarget && specificTarget == 'endArrow') {
                this.expandList(event, activity)
              } else if (specificTarget && specificTarget == 'endIcon') {
                this._handleMoreOptionsPopover(activity, event);
              }
              else {
                this.openActivityDetails(foundActivity);
              }
            }
          }
        };
        break;
      case ActivityType.Order:
        viewData = {
          id: activity.ID,
          fixedHeight: true,
          isExpandable: true,
          isExpanded: activity.expanded,
          expandableViewType: 'activitiesList',
          expandableData: activity,
          expandHeight: this.itemExpandHeight,
          isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
          primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale),
          primaryTextRight: this.translate.instant('NEW_ACTIVITY_SALES_ORDER') + ' - ' + activity.subject,
          secondaryTextRight: (!(activity as OrderActivity).accountId) ? this.translate.instant('NO_ACCOUNT') : (activity as OrderActivity).accountNameString,
          showArrow: false,
          showIcon: true,
          iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
          clickHandler: (id: string, event, specificTarget) => {
            let foundActivity = this.activityService.getActivityByID(id);
            if (foundActivity) {
              if (specificTarget && specificTarget == 'delete_control') {
                this.events.publish('deleteMeeting', foundActivity);
              }
              else if (specificTarget && specificTarget == 'endArrow') {
                this.expandList(event, activity)
              } else if (specificTarget && specificTarget == 'endIcon') {
                this._handleMoreOptionsPopover(activity, event);
              }
              else {
                this.openActivityDetails(foundActivity);
              }
            }
          }
        };
        break;
      case ActivityType.CaseIntake:
        viewData = {
          id: activity.ID,
          isExpandable: true,
          isExpanded: activity.expanded,
          expandableViewType: 'customer_inquiry',
          fixedHeight: true,
          expandableData: activity,
          expandHeight: this.itemExpandHeight,
          isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
          primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale),
          primaryTextRight: (activity as CaseActivity).caseName,
          //secondaryTextRight: '',
          //secondaryTextRightTwo: '',
          showArrow: false,
          showIcon: true,
          iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
          clickHandler: (id: string, event, specificTarget) => {
            let foundActivity = this.activityService.getActivityByID(id);
            if (foundActivity) {
              if (specificTarget && specificTarget == 'delete_control') {
                this.deleteCI(foundActivity);
              }
              else if (specificTarget && specificTarget == 'endArrow') {
                this.expandList(event, activity)
              } else if (specificTarget && specificTarget == 'endIcon') {
                this._handleMoreOptionsPopover(activity, event);
              }
              else {
                this.openActivityDetails(foundActivity);
              }
            }
          }
        };
        if ((activity as CaseActivity)._case_stage_value) {
          viewData.secondaryTextRight = this.getCaseState((activity as CaseActivity)._case_stage_value);
          viewData.secondaryTextRightTwo = ((activity as CaseActivity)._case_contact) ? (activity as CaseActivity)._case_contact.fullName : ((activity as CaseActivity)._case_trans_customer_name && (activity as CaseActivity)._case_trans_customer_name != Utility.globalCustomerText) ? (activity as CaseActivity)._case_trans_customer_name : `${this.translate.instant('NO')} ${this.utilityService.globalCustomerText}`;
        } else {
          viewData.secondaryTextRight = ((activity as CaseActivity)._case_contact) ? (activity as CaseActivity)._case_contact.fullName : ((activity as CaseActivity)._case_trans_customer_name && (activity as CaseActivity)._case_trans_customer_name != Utility.globalCustomerText) ? (activity as CaseActivity)._case_trans_customer_name : `${this.translate.instant('NO')} ${this.utilityService.globalCustomerText}`;
        }
        break;
      case ActivityType.SurgeryOrder:
        viewData = {
          id: activity.ID,
          fixedHeight: true,
          isExpandable: true,
          isExpanded: activity.expanded,
          expandableViewType: 'activitiesList',
          expandableData: activity,
          expandHeight: this.itemExpandHeight,
          isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
          primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale) + ' - ' + format(activity.scheduledEnd, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale),
          primaryTextRight: activity.subject,
          secondaryTextRight: this.getSecondaryInfoProcedureLog(activity),
          showArrow: false,
          showIcon: true,
          iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
          clickHandler: (id: string, event, specificTarget) => {
            let foundActivity = this.activityService.getActivityByID(id);
            if (foundActivity) {
              if (specificTarget && specificTarget == 'delete_control') {
                this.events.publish('deleteMeeting', foundActivity);
              }
              else if (specificTarget && specificTarget == 'endArrow') {
                this.expandList(event, activity)
              } else if (specificTarget && specificTarget == 'endIcon') {
                this._handleMoreOptionsPopover(activity, event);
              } else {
                this.openActivityDetails(foundActivity);
              }
            }
          }
        };
        break;
      case ActivityType.ProcedureTracker:
        viewData = {
          id: activity.ID,
          fixedHeight: true,
          isExpandable: true,
          isExpanded: activity.expanded,
          expandableViewType: 'activitiesList',
          expandableData: activity,
          expandHeight: this.itemExpandHeight,
          isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
          primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale) + ' - ' + format(activity.scheduledEnd, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale),
          primaryTextRight: activity.subject,
          secondaryTextRight: this.getSecondaryInfoProcedureTracker(activity),
          showArrow: false,
          showIcon: true,
          iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
          clickHandler: (id: string, event, specificTarget) => {
            let foundActivity = this.activityService.getActivityByID(id);
            if (foundActivity) {
              if (specificTarget && specificTarget == 'delete_control') {
                this.events.publish('deleteMeeting', foundActivity);
              }
              else if (specificTarget && specificTarget == 'endArrow') {
                this.expandList(event, activity)
              } else if (specificTarget && specificTarget == 'endIcon') {
                this._handleMoreOptionsPopover(activity, event);
              } else {
                this.openActivityDetails(foundActivity);
              }
            }
          }
        };
        break;
      case ActivityType.SetBooking:
        viewData = {
          id: activity.ID,
          fixedHeight: true,
          isExpandable: true,
          isExpanded: activity.expanded,
          expandableViewType: 'activitiesList',
          expandableData: activity,
          expandHeight: this.itemExpandHeight,
          isSelected: (this.activityService.selectedActivity && this.activityService.selectedActivity.ID == activity.ID),
          primaryTextLeft: format(activity.scheduledStart, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale)+' - '+format(activity.scheduledEnd, this.dateTimeFormatService.timeFormatSelected.segment, this.date_fns_locale),
          primaryTextRight: activity.subject,
          secondaryTextRight: this.secInfoConfigDataService.isKitBookingSecInfoConfigured ? this.secInfoConfigDataService.getSecondaryInfoFormatedTextForKitBooking(activity as SetBookingActivity, SecondaryInfoEntityName.Kitbooking) : activity.statusString,
          showArrow: false,
          showIcon: true,
          iconName: 'indegene-' + this.activityService.getActivityIcon(activity),
          clickHandler: (id: string, event, specificTarget) => {
            let foundActivity = this.activityService.getActivityByID(id);
            if (foundActivity) {
              if (specificTarget && specificTarget == 'delete_control') {
                this.events.publish('deleteMeeting', foundActivity);
              }
              // else if (specificTarget && specificTarget == 'endArrow') {
              //   this.expandList(event, activity)
              // }
              else if (specificTarget && specificTarget == 'endIcon') {
                this._handleMoreOptionsPopover(activity, event);
              }
              else {
                this.openActivityDetails(foundActivity);
              }
            }
          }
        };
        break;
    }
    if(!this.activityService.teamViewActive && !(this.device.isAndroid() || this.device.isIOS()) && ((!activity.isCompleted && this.checkCustomScrapCondition(activity)) || this.checkCustomCloneCondition(activity))){
      viewData.showEndIcon = true;
      viewData.isSrcAttribute = true;
      viewData.endIconType = 'assets/imgs/horizontal-menu-filled.svg';
      viewData.endIconCssClass = 'showOnHover';
    }
    return viewData;
  }

  private getSecondaryInfoProcedureLog(activity: Activity): string {
    if ((activity as SurgeryOrderActivity).indskr_noprocedureday) {
      return this.translate.instant('NO_PROCEDURE_DAY');
    } else {
      return (!(activity as SurgeryOrderActivity).accountId) ? this.translate.instant('NO_ACCOUNT') : (activity as SurgeryOrderActivity).accountNameString;
    }
  }

  private getSecondaryInfoProcedureTracker(activity: Activity): string {
    return (!(activity as ProcedureTrackerActivity).accountId) ? this.translate.instant('NO_ACCOUNT') : (activity as ProcedureTrackerActivity).accountNameString;
  }

  public getCaseState(caseState):string{

    switch (caseState) {
        case 'Open':
          caseState = this.translate.instant('OPEN');
            break;
        case 'Approved':
          caseState = this.translate.instant('APPROVE');
            break;
        case 'For Review':
            caseState = this.translate.instant('FOR_REVIEW');
            break;
        case 'In Progress':
            caseState = this.translate.instant('IN_PROGRESS');
            break;
        case 'Intake':
              caseState = this.translate.instant('INTAKE');
              break;
        case 'Pending Sync':
              caseState = this.translate.instant('PENDING_SYNC');
              break;
        default:
            caseState = caseState;
            break;
        }
        return caseState;
}

  private checkIfChannelIsExternal(channelId: string): boolean {
    if (channelId != undefined || channelId != "") {
      const cha: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === channelId);
      if (cha != undefined && cha.indskr_externalchannel === true) {
        return true;
      }
    }
    return false;
  }

  onSearchFocus() {
    this.shouldShowSearchBarFilterButton = false;
  }
  onSearchBlur() {
    this.shouldShowSearchBarFilterButton = this.agendaFooterService.actSegment == 'scheduler'? false : true;
  }
  refreshHeader() {
    if(this.agendaFooterService.actSegment == 'scheduler' || this.agendaFooterService.actSegment == 'approvals') {
      this.shouldShowSearchBarFilterButton = false;
      this.activityService.activityFilter = ActivityType.AllActivity;
      this.activityService.activityFilterValue = this.translate.instant('HOME');
    } else {
      this.shouldShowSearchBarFilterButton = true;
      this.activityService.activityFilter = this.filterAtHome;
      this.activityService.activityFilterValue = this.filterValueAtHome;
    }
    if (!this.isInitDone) {
      this.refreshAgenda();
    } else if (!this.uiService.toolsActivityActive){
      this.events.publish("refreshAgenda");
    }
    else {
      this.uiService.agendaRefreshRequired = true;
    }
  }

  public async createSurgeryOrderCopy (masterSurgeryOrder:SurgeryOrderActivity){
    if(masterSurgeryOrder && this.copySurgeryOrderActivityInProgress == false){
      this.uiService.displayLoader();
      masterSurgeryOrder = this.activityService.getActivityByID(masterSurgeryOrder.ID) as SurgeryOrderActivity;
      this.copySurgeryOrderActivityInProgress = true;
      let copyDTO = masterSurgeryOrder.offlineDataDTO;
      let autoSetOwnerAsCoowner = this.authenticationOfflineService.user.buSettings && this.authenticationOfflineService.user.buSettings['indskr_setownerascoowner'];

      delete copyDTO.indskr_procedurecontract;
      let errorOccured: boolean = false;
      let currentTime = new Date().getTime();
      let start, end;
      start = format(Utility.getRoundedDownTime(new Date()));
      end = format(addMinutes(start, 30));


      let foundTemplate;
      let hideProcedureAndProductCategory = this.authenticationOfflineService.user.buSettings && this.authenticationOfflineService.user.buSettings['indskr_hideprocedureproductcategory'];
      if (hideProcedureAndProductCategory) {
        let procedureLogTypeTemplates = this.surveyService.surveyForms.filter(a => a.templateType == MobileAppTemplateType.ProcedureLogType);
        if (!_.isEmpty(procedureLogTypeTemplates)) {
          procedureLogTypeTemplates = _.orderBy(procedureLogTypeTemplates, ['modiefiedOn'], ['desc']);
          foundTemplate = procedureLogTypeTemplates[0];
        }
      } else {
        if (masterSurgeryOrder.surgeryId) {
          const selectedSurgery = this.surgeryOrderActivityDataService.productHierarchies.find(a => a.surgeryId == masterSurgeryOrder.surgeryId);
          if (selectedSurgery && selectedSurgery.surveyTemplateId) {
            foundTemplate = this.surveyService.surveyForms.find(form => form.surveyFormId == selectedSurgery.surveyTemplateId);
          }
        }
      }

      if(foundTemplate){
        await this.surgeryOrderActivityDataService.fetchSurveyJSAtrributesForProcedure(masterSurgeryOrder, foundTemplate);
        foundTemplate = _.cloneDeep(foundTemplate.surveyConfiguration);
        Object.keys(masterSurgeryOrder.surveyResponseData).forEach((key) => {
          const element = this.surveyService.getElementFromSurveyTemplateElements(foundTemplate.pages[0].elements, { name : key});
          if (element && element.type && element.metadata) {
            if (element.type == "lookup" && masterSurgeryOrder.surveyResponseData[key]) {

              let idx;
              if (!masterSurgeryOrder.surveyResponseDTO.hasOwnProperty('lookupfields')) {
                masterSurgeryOrder.surveyResponseDTO['lookupfields'] = [];
              }
              idx = masterSurgeryOrder.surveyResponseDTO['lookupfields'].findIndex(a => a.name == element.metadata.logicalName);
              if (idx >= 0) {
                if (masterSurgeryOrder.surveyResponseData[key] != masterSurgeryOrder.surveyResponseDTO['lookupfields'][idx].id) {
                  masterSurgeryOrder.surveyResponseDTO['lookupfields'][idx] = {
                    name: element.metadata.logicalName,
                    entity: element.metadata.target.setName,
                    id: masterSurgeryOrder.surveyResponseData[key],
                  };
                }
              } else {
                masterSurgeryOrder.surveyResponseDTO['lookupfields'].push({
                  name: element.metadata.logicalName,
                  entity: element.metadata.target.setName,
                  id: masterSurgeryOrder.surveyResponseData[key],
                });
              }
            } else {
              if (!masterSurgeryOrder.surveyResponseDTO || (masterSurgeryOrder.surveyResponseData[key] != masterSurgeryOrder.surveyResponseDTO[element.metadata.logicalName])) {
                masterSurgeryOrder.surveyResponseDTO[element.metadata.logicalName] = masterSurgeryOrder.surveyResponseData[key];
              }
            }
          }
        });

      }


      copyDTO['salesorderid'] = 'offline_surgeryorder_' + currentTime;
      copyDTO['indskr_externalid'] = 'offline_surgeryorder_' + currentTime;
      copyDTO['pendingPushToDynamics'] = true;
      copyDTO['statuscode'] = 1;
      copyDTO['statecode'] = 0;
      copyDTO['createdon'] = new Date(currentTime).getTime();
      copyDTO['scheduledstart'] = new Date(start).getTime();
      copyDTO['scheduledend'] = new Date(end).getTime();
      copyDTO['indskr_scheduleddate'] = new Date(start).getTime();
      copyDTO['indskr_scheduledenddate'] = new Date(end).getTime();
      copyDTO['contractStatus'] = null;

      let coOwners = [{
        userId: this.authenticationOfflineService.user.xSystemUserID,
        userFullName: this.authenticationOfflineService.user.displayName,
        indskr_procedurelogcoownerid: Guid.create().toString()
      }]

      if(autoSetOwnerAsCoowner){
        copyDTO = { ...copyDTO, coOwners }
      }

      let newSurgeryOrder = new SurgeryOrderActivity(copyDTO);
      newSurgeryOrder.statusString = 'Draft';
      newSurgeryOrder.ownerId = this.authenticationOfflineService.user.systemUserID;
      newSurgeryOrder.ownerNameString = this.authenticationOfflineService.user.displayName;
      if(newSurgeryOrder.products && newSurgeryOrder.products.length != 0){
        newSurgeryOrder.products = newSurgeryOrder.products.map(p => {
          p.quantity = 1;
          return p;
        });
      }

      if(newSurgeryOrder.procedureNotes && newSurgeryOrder.procedureNotes.length != 0){
        newSurgeryOrder.procedureNotes = newSurgeryOrder.procedureNotes.map(p => {
          p.noteId = '';
          return p;
        });
      }

      if (masterSurgeryOrder.indskr_proceduresubtype) {
        newSurgeryOrder.indskr_proceduresubtypename = masterSurgeryOrder.indskr_proceduresubtypename
      }

      if (masterSurgeryOrder.indskr_proceduretype) {
        newSurgeryOrder.indskr_proceduretypename = masterSurgeryOrder.indskr_proceduretypename
      }

      if (masterSurgeryOrder.surveyResponseDTO) {
        newSurgeryOrder.surveyResponseDTO = masterSurgeryOrder.surveyResponseDTO;
      }

      await this.surgeryOrderActivityDataService.createOrderActivity({ onDynamics: !this.device.isOffline, onLocalCopy: true, onLocalDatabase: true }, [newSurgeryOrder], currentTime, false).then(succ => {
        //Successfully created new surgery order activity
        this.activityService.selectedActivity = newSurgeryOrder;
      }).catch(err => {
        // Handle any error occured during order activity creation
        errorOccured = true;
      });
      if (!errorOccured) {
        let from: string = 'AgendaView';
        this.navService.setChildNavRoot(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent, PageName.ActivitiesPageComponent, { from: from, activity: newSurgeryOrder });
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        if (this.uiService.activitiesPageTab == 'day') {
          let data = {
            id: newSurgeryOrder.ID,
            subject: newSurgeryOrder.subject,
            location: (newSurgeryOrder.accountId) ? newSurgeryOrder.accountNameString : 'No Account',
            scheduledStart: newSurgeryOrder.scheduledStart,
            scheduledEnd: newSurgeryOrder.scheduledEnd,
            type: newSurgeryOrder.type,
          };
          this.uiService.setUIServiceData({
            view: 'new-activity', data,
          });
        }
        if (!this.uiService.toolsActivityActive){
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
      }
      this.copySurgeryOrderActivityInProgress = false;
      this.uiService.dismissLoader();
    }
  }

  public async cloneSetBooking (masterSetBooking: SetBookingActivity){
    if(masterSetBooking && this.copySetBookingActivityInProgress == false){
      this.uiService.displayLoader();
      this.copySetBookingActivityInProgress = true;
      let start = format(Utility.getRoundedDownTime(new Date()));
      let end = format(addMinutes(start, 30));
      let copyDTO ={
        "indskr_status": SetBookingStatus.DRAFT,
        "lookupfields": [],
        "indskr_type": masterSetBooking.rawData["indskr_type"],
        "indskr_operationtype": masterSetBooking.rawData["indskr_operationtype"],
        "indskr_operationsubtype": masterSetBooking.rawData["indskr_operationsubtype"],
        "indskr_surgerytype": masterSetBooking.rawData["indskr_surgerytype"],
        "indskr_source": 548910001,
        "indskr_operationstartdate": new Date(start).toISOString(),
        "indskr_operationenddate": new Date(end).toISOString(),
      }
      if(masterSetBooking.rawData["_indskr_accountnamesoldto_value"])
        copyDTO.lookupfields.push({
          "name": "indskr_accountnamesoldto",
          "entity": "accounts",
          "id": masterSetBooking.rawData["_indskr_accountnamesoldto_value"]
        });
      if(masterSetBooking.rawData["_indskr_surgeonaccountzsur_value"])
        copyDTO.lookupfields.push({
          "name": "indskr_surgeonaccountzsur",
          "entity": "accounts",
          "id": masterSetBooking.rawData["_indskr_surgeonaccountzsur_value"]
        });
      if(masterSetBooking.rawData["_indskr_surgeoncontact_value"])
        copyDTO.lookupfields.push({
          "name": "indskr_surgeoncontact",
          "entity": "contacts",
          "id": masterSetBooking.rawData["_indskr_surgeoncontact_value"]
      });
      if(masterSetBooking.rawData["_indskr_shipto_value"])
        copyDTO.lookupfields.push({
          "name": "indskr_shipto",
          "entity": "indskr_indskr_customeraddress_v2s",
          "id": masterSetBooking.rawData["_indskr_shipto_value"]
        });
      if(masterSetBooking.rawData["_indskr_partnerlocation_value"])
        copyDTO.lookupfields.push({
          "name": "indskr_partnerlocation",
          "entity": "indskr_indskr_customeraddress_v2s",
          "id": masterSetBooking.rawData["_indskr_partnerlocation_value"]
        });

      let newSetBooking = new SetBookingActivity(copyDTO);
      newSetBooking.allDayEvent = !isSameDay(newSetBooking.scheduledStart,newSetBooking.scheduledEnd);
      newSetBooking.serviceDTO = copyDTO;
      newSetBooking.statusString = 'Draft';

      const action:OperationDetail = {
        onDynamics: true,
        onLocalDatabase: true,
        onLocalCopy: true,
        operationDetail: {
          code: 'CREATENEWSETBOOKINGACTIVITY',
          message: 'Create New Set Booking Activity'
        }
      }
      await this.setBookingDataService.createSetBookingActivity(action,[newSetBooking],new Date().getTime()).then(async (res)=> {
        //let teamMembersArray = await this.diskService.retrieve(DB_KEY_PREFIXES.SET_BOOKING_LINKED_ENTITY + "indskr_setbookingteammember");
        let setBookingProductsArray = await this.diskService.retrieve(DB_KEY_PREFIXES.SET_BOOKING_LINKED_ENTITY + "indskr_setbookingproduct");
        let linkEntityProductsData = setBookingProductsArray && (setBookingProductsArray.raw).filter(x => x["indskr_setbookingid"] == masterSetBooking.ID && (!x.hasOwnProperty('indskr_setbookingproduct.statecode') || (x.hasOwnProperty('indskr_setbookingproduct.statecode') && x['indskr_setbookingproduct.statecode'] == 0)));

        let linkEntitiesReqPayload = [];
        linkEntityProductsData.forEach((product_obj)=>
          linkEntitiesReqPayload.push({
            "values": {
                "lookupfields": [
                    {
                        "name": "indskr_setBooking",
                        "entity": "indskr_setbookings",
                        "id": newSetBooking.ID
                    },
                    {
                        "name": "indskr_product",
                        "entity": "products",
                        "id": product_obj["indskr_setbookingproduct.indskr_product"]
                    }
                ],
                "indskr_quantity": product_obj["indskr_setbookingproduct.indskr_quantity"],
                "indskr_name": product_obj["indskr_setbookingproduct.indskr_name"]
            },
            "entity": "indskr_setbookingproducts",
          })
        );

        if (linkEntitiesReqPayload && linkEntitiesReqPayload.length > 0) {
          for (let reqPayload of linkEntitiesReqPayload) {
            await this.dynamicFormService.createLinkedEntity(reqPayload.values, reqPayload.entity);
          }
          linkEntitiesReqPayload = [];
        }
        await this.setBookingDataService.fetchRealTimeSetBookingDetails(newSetBooking);
        if (this.uiService.activitiesPageTab == 'day') {
          let data = {
            id: newSetBooking.ID,
            subject: newSetBooking.subject,
            location: newSetBooking.statusString,
            scheduledStart: newSetBooking.scheduledStart,
            scheduledEnd: newSetBooking.scheduledEnd,
            type: newSetBooking.type,
          };
          this.uiService.setUIServiceData({
            view: 'new-activity', data,
          });
        }
        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
        if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
        //this.events.publish('activity-pane:selectActivity', newSetBooking);
        if(masterSetBooking) {       //if(data && data.eventsHandler)
          let rawDetails = {
            indskr_setbookingid: newSetBooking.ID,
          };
          // Add code to fetch real time details
          if(rawDetails) {
            this.activityService.selected = newSetBooking;
          }
        }
        //load set booking display form with flag isCloneFlow=true to auto open edit form
        this.navService.setChildNavRoot(SetBookingActivityDetailsComponent, PageName.SetBookingActivityDetailsComponent, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: this.activityService.selectedActivity, isCloneFlow: true});
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        this.uiService.dismissLoader();
      }).catch(err =>{
        console.log("Error in cloning kitbooking "  + err);
      });


      this.copySetBookingActivityInProgress = false;
      this.uiService.dismissLoader();
    }
  }

  async cloneActivity(activity: Activity) {
    switch (activity.type) {
      case ActivityType.Appointment:
        this.cloneAppointmentActivity(activity as AppointmentActivity);
        break;
      case ActivityType.PhoneCall:
        this.clonePhoneActivity(activity as PhoneActivity);
        break;
      case ActivityType.FollowUp:
        console.log('Inside FollowUp');
        await this.cloneFollowupTask(activity as FollowUpActivity).then(success => {
        }).catch(error => {
          //Handle error in new follow up creation for UI
        });
        return;
      case ActivityType.TimeOff:
        this.cloneTimeOff(activity as TimeOffActivity);
        break;
      case ActivityType.Email:
        this.cloneEmailActivity(activity as EmailActivity);
        break;
      case ActivityType.Order:
        await this.cloneOrderActivity(activity as OrderActivity).then(success => {
        }).catch(error => {
        });
        this.activityService.selectedActivityAtHome = this.activityService.selectedActivity;
        return;
      case ActivityType.CaseIntake:
        this.cloneCustomerInquiry(activity as CaseActivity);
        break;
      case ActivityType.SurgeryOrder:
        this.createSurgeryOrderCopy(activity as SurgeryOrderActivity);
        break;
      case ActivityType.ProcedureTracker:
        if (!this.device.isOffline) {
          this.cloneProcedureTracker(activity as ProcedureTrackerActivity);
        }
        break;
      case ActivityType.SetBooking:
        if (!this.device.isOffline) {
          this.cloneSetBooking(activity as SetBookingActivity)
        }
        break;
    }

  }

  private async cloneFollowupTask(followupActivity : FollowUpActivity): Promise<any> {
    this.uiService.displayLoader();
    return new Promise(async (resolve, reject) => {
      let currentTime = new Date().getTime();
      let payload = {
        activityId: 'offline_followup_' + currentTime,
        activitytypecode: 'followuptask',
        subject: followupActivity.subject,
        offlineId: 'offline_followup_' + currentTime,
        indskr_plantype: followupActivity.planType,
      };
      let diffDays = differenceInCalendarDays(new Date(), followupActivity.scheduledStart);
      if(diffDays <= 0) {
        payload['scheduledstart'] = followupActivity.scheduledStart;
      } else {
        payload['scheduledstart'] = currentTime;
      }
      payload['indskr_followupactiontype'] = followupActivity.followupActionTypeID ? followupActivity.followupActionTypeID : '';
      payload['indskr_followupactiontypename'] = followupActivity.followupActionTypeName ? followupActivity.followupActionTypeName : '';

      payload['indskr_priority'] = followupActivity.priorityID ? followupActivity.priorityID : '';
      payload['priorityValue'] = followupActivity.priorityValue ? followupActivity.priorityValue : '';

      // ACCOUNT PLAN SPECIFIC
      if(followupActivity.accountId) {
        let account = this.accountOfflineService.getAccountById(followupActivity.accountId);
        if(!(!account || account.status === 2 || account.status == 548910003 || account.status == 548910010 || account.status == 548910011 || account.status == 548910012 || account.status == 548910013)){
          payload['indskr_account'] = followupActivity.accountId ? followupActivity.accountId : '';
          payload['accountName'] = followupActivity.accountNameString ? followupActivity.accountNameString : '';
        }
      }
      if(followupActivity.accountPlanId) {
        let accountPlan = this.accountManagementService.getAccountPlanByPlanId(followupActivity.accountPlanId);
        if(accountPlan && accountPlan.status.toLowerCase().includes('open')) {
          payload['indskr_accountplan'] = followupActivity.accountPlanId ? followupActivity.accountPlanId : '';
          payload['accountplanName'] = followupActivity.accountPlanNameString ? followupActivity.accountPlanNameString : '';
        }
      }

      // SAP SPECIFIC
      payload['indskr_scientificplan'] = followupActivity.scientificPlanId ? followupActivity.scientificPlanId : '';
      payload['scientifiPlanName'] = followupActivity.scientificPlanNameString ? followupActivity.scientificPlanNameString : '';

      //OPPORTUNITY SPECIFIC
      payload['opportunityid'] = followupActivity.opportunityId ? followupActivity.opportunityId : '';
      payload['opportunityName'] = followupActivity.opportunityIdNameString ? followupActivity.opportunityIdNameString : '';

      if(followupActivity.contactId) {
        let contact = this.contactService.getContactByID(followupActivity.contactId);
        if(contact && contact.isActive){
          payload['indskr_contact'] = followupActivity.contactId ? followupActivity.contactId : '';
          payload['contactName'] = followupActivity.contactNameString ? followupActivity.contactNameString : '';
        }
      }

      if(!this.device.isOffline && followupActivity.assignedTo && followupActivity.assignedTo.length > 0) {
        let users = [];
        let userData = []
        await this.followUpActivityDataService.getSameLevelAndChildUsersListOnline(false, true).then(async res => {
          userData = res;
        });
        followupActivity.assignedTo.forEach(user => {
          if(userData && userData.length > 0 && userData.some(systemUser => systemUser.systemuserid == user.userId)) {
            users.push({
              indskr_taskassignmentid: user.taskAssignmentId ? user.taskAssignmentId : '',
              activityid: user.activityId ? user.activityId : '',
              indskr_user: user.userId ? user.userId : '',
              userName: user.userFullName ? user.userFullName : '',
              ownerId: user.ownerId ? user.ownerId : ''
            });
          }
        });
        payload['taskUsersList'] = users;
      }

      if(followupActivity.products && followupActivity.products.length > 0) {
        let products = [];
        followupActivity.products.forEach(product => {
          if(this.brandService.brands.some(brand => brand.ID == product.productID)) {
            products.push({
              productId: product.productID ? product.productID : '',
              productName: product.productName ? product.productName : '',
              indskr_taskproductid: product.taskProductID ? product.taskProductID : ''
            });
          }
        });
        payload['products'] = products;
      }

      let newFollowUp = new FollowUpActivity(payload);
      newFollowUp.state = 0;
      newFollowUp.statusString = 'Open';
      newFollowUp.ownerId = this.authenticationOfflineService.user.systemUserID;
      newFollowUp.ownerNameString = this.authenticationOfflineService.user.displayName;
      newFollowUp.pendingPushToDynamics = true;
      await this.followUpActivityDataService.createFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalCopy: true, onLocalDatabase: true }, [newFollowUp], currentTime, false).then(succ => {
        //Successfully created new follow up activity
        this.activityService.selectedActivity = newFollowUp;
      }).catch(err => {
        // Handle any error occured during follow up activity creation
        reject('Error occured while creating Scientific Activity Plan');

      });
      this.openActivityDetails(newFollowUp);
      this.uiService.dismissLoader();
    });
  }

  public async cloneCustomerInquiry (caseActivity: CaseActivity){
    this.uiService.displayLoader();
    let currentDate = new Date();
    let temp_id = "offline_case_" + Math.floor(currentDate.getTime() + Math.random() * 900000);

    let raw: any = {
      "productid_value": caseActivity._case_product_id,
      "statecode@OData.Community.Display.V1.FormattedValue": "Active",
      "statecode": 0,
      "statuscode@OData.Community.Display.V1.FormattedValue": "Open",
      'indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue': 'Open',
      "statuscode": 0,
      "indskr_expertcategory_value": caseActivity._case_type_id,
      "indskr_expertcategory": caseActivity._case_type,
      "createdon": currentDate.getTime(),
      "indskr_intakecreated": currentDate.getTime(),
      "incidentid": temp_id,
      'indskr_externalid': temp_id,
      "ticketnumber": undefined,
      "indskr_responsepreference": caseActivity._case_response_preference_id,
      'ownerid_value': this.authenticationOfflineService.user.systemUserID,
      'ownerid_value@OData.Community.Display.V1.FormattedValue': this.authenticationOfflineService.user.displayName ? this.authenticationOfflineService.user.displayName : "",
      "isNew": true,
      "indskr_meeting_value": caseActivity._online_meetingID,
      "createdby_value": this.authenticationOfflineService.user.systemUserID
    }
    if(caseActivity._case_account_id) {
      let account = this.accountOfflineService.getAccountById(caseActivity._case_account_id);
        if(!(!account || account.status === 2 || account.status == 548910003 || account.status == 548910010 || account.status == 548910011 || account.status == 548910012 || account.status == 548910013)){
          raw['indskr_account_value'] = caseActivity._case_account_id ? caseActivity._case_account_id : undefined;
          raw['indskr_account_value@OData.Community.Display.V1.FormattedValue'] = caseActivity._case_trans_account_name ? caseActivity._case_trans_account_name : undefined;
        }
    }

    if(caseActivity._case_contact_id) {
      let contact = this.contactService.getContactByID(caseActivity._case_contact_id);
      if(contact && contact.isActive){
        raw['customerid_value'] = caseActivity._case_contact_id ? caseActivity._case_contact_id : undefined;
        raw['customerid_value@OData.Community.Display.V1.FormattedValue'] = caseActivity._case_trans_customer_name ? caseActivity._case_trans_customer_name : undefined;
      }
    }
    if(caseActivity._selected_address) {
      raw['indskr_customeraddress'] = caseActivity._selected_address;
    }
    if(caseActivity._selected_email_id) {
      raw['indskr_customeremail'] = caseActivity._selected_email_id;
    }
    if(caseActivity._selected_phone) {
      raw['indskr_customerphone'] = caseActivity._selected_phone;
    }
    let iCase = new CaseActivity(raw);
    iCase._case_response_preference = caseActivity._case_response_preference;
    iCase._id = DB_KEY_PREFIXES.MY_CASES + iCase.ID;

    this.caseManagementService.assignSelectedCase(iCase);
    await this.diskService.addCaseToCollection(iCase);
    this.caseManagementService.upsertMyCases(iCase);
    this.events.publish("refreshAgenda");
    this.openActivityDetails(iCase);
    this.uiService.dismissLoader();
  }

  private async cloneOrderActivity(orderActivity : OrderActivity): Promise<any> {
    this.uiService.displayLoader();
    return new Promise(async (resolve, reject) => {
      let currentTime = new Date().getTime();
      let start, end;
      start = format(Utility.getRoundedDownTime(new Date()));
      end = format(addMinutes(start, 30));
      let payload = {
        salesorderid: 'offline_order_' + currentTime,
        indskr_externalid: 'offline_order_' + currentTime,
        indskr_ordertype:548910000,
        ordernumber: 'New Order',
        subject: 'New Order',
        name: 'New Order',
        pendingPushToDynamics: true,
        statuscode: 1,
        statecode: 0,
        activitytypecode: 'indskr_order',
        createdon: new Date(currentTime).getTime(),
        scheduledstart: new Date(start).getTime(),
        scheduledend: new Date(end).getTime(),
      }
      if(orderActivity.accountId) {
        let account = this.accountOfflineService.getAccountById(orderActivity.accountId);
        if(!(!account || account.status === 2 || account.status == 548910003 || account.status == 548910010 || account.status == 548910011 || account.status == 548910012 || account.status == 548910013)){
          payload['customerid'] = orderActivity.accountId || '';
          payload['customerName'] = orderActivity.accountNameString || '';
          if(orderActivity.opportunityId) {
            let opportunity = this.opportunitiesService.getOpportunityDetailsbyId(orderActivity.opportunityId);
            if(opportunity && opportunity.stateCode === 0) {
              payload['opportunityid'] = orderActivity.opportunityId || '';
              payload['opportunityName'] = orderActivity.opportunityNameString || '';
            }
          }
          if(orderActivity.accountPlanId) {
            let accountPlan = this.accountManagementService.getAccountPlanByPlanId(orderActivity.accountPlanId);
            if(accountPlan && accountPlan.status.toLowerCase().includes('open')) {
              payload['accountplanid'] = orderActivity.accountPlanId || '';
              payload['accountplanName'] = orderActivity.accountPlanNameString || '';
            }
          }
        }
      }
      payload['indskr_country'] = orderActivity.countryId || '';
      payload['countryName'] = orderActivity.countryNameString || '';
      payload['discountpercentage'] = orderActivity.elligibleDiscount || '';
      if(orderActivity.priceListId && this.orderManagementService.priceLists) {
        let priceList = this.orderManagementService.priceLists.find(p => p.ID === orderActivity.priceListId);
        if(priceList) {
          payload['pricelevelid'] = orderActivity.priceListId || '';
          payload['totalamount'] = orderActivity.total || '';
          payload['formattedTotalAmount'] = orderActivity.formattedTotalAmount || '';
          if(orderActivity.products && orderActivity.products.length > 0) {
            let products = [];
            orderActivity.products.forEach(product => {
              products.push({
                productid: product.productId,
                productName: product.productName ? product.productName : '',
                extendedamount: product.total ? product.total : 0,
                formattedExtendedamount: product.formattedTotal ? product.formattedTotal : '',
                priceperunit: product.unitPrice ? product.unitPrice : 0,
                quantity: product.quantity ? product.quantity : 0,
                uomid: product.uomid ? product.uomid : '',
                isDeleted: product.isDeleted ? product.isDeleted : '',
              });
            });
            payload['products'] = products;
          }
        }
      }

      let newOrder = new OrderActivity(payload);
      newOrder.statusString = 'Open';
      newOrder.ownerId = this.authenticationOfflineService.user.systemUserID;
      newOrder.ownerNameString = this.authenticationOfflineService.user.displayName;

      await this.orderActivityDataService.createOrderActivity({ onDynamics: !this.device.isOffline, onLocalCopy: true, onLocalDatabase: true }, [newOrder], currentTime, false).then(succ => {
        //Successfully created new order activity
        this.activityService.selectedActivity = newOrder;
      }).catch(err => {
        // Handle any error occured during order activity creation
        reject('error occured while creating new order');
      });
      this.openActivityDetails(newOrder);
      this.uiService.dismissLoader();
    });
  }

  public async cloneTimeOff (masterTimeOffActivity: TimeOffActivity){
    this.uiService.displayLoader();
    let currentDate = new Date();
    if(masterTimeOffActivity.durationType == DurationType.AllDay) {
      this.newTimeOff.totStartTime = new Date(currentDate.setHours(0, 0, 0));
      this.newTimeOff.totEndTime = new Date(currentDate.setHours(23, 59, 59));
    } else if(masterTimeOffActivity.durationType == DurationType.HalfDay) {
      let meridian = moment(new Date).format('A');
        if (meridian === 'PM') {
          this.timeOffService.popupSelectedTimePeriodEvent = "PM";
          this.newTimeOff.totStartTime = new Date(currentDate.setHours(12, 0, 0));
          this.newTimeOff.totEndTime = new Date(currentDate.setHours(23, 59, 59));
        } else {
          this.timeOffService.popupSelectedTimePeriodEvent = "AM";
          this.newTimeOff.totStartTime = new Date(currentDate.setHours(0, 0, 0));
          this.newTimeOff.totEndTime = new Date(currentDate.setHours(11, 59, 59));
        }
    } else if(masterTimeOffActivity.durationType == DurationType.Hourly) {
        this.newTimeOff.totStartTime = new Date(currentDate.setHours(8,0,0)); // default start time value
        let setCombinedDateTimeForEndTime = format(addMinutes(this.newTimeOff.totStartTime, 60));
        this.newTimeOff.totEndTime = new Date(setCombinedDateTimeForEndTime);
        this.events.publish("initTimePickerLimitedMinTime", {startDateTime: this.newTimeOff.totStartTime, endDateTime: this.newTimeOff.totEndTime});
    }
    this.newTimeOff.scheduledStartLocale = this.newTimeOff.totStartTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    this.newTimeOff.offlineTimeOffRequestId = "offline_tot_" + currentDate.getTime();
    this.newTimeOff.totIsAlldayEvent = masterTimeOffActivity.totIsAlldayEvent;
    this.newTimeOff.timeOffReason = masterTimeOffActivity.timeOffReason;
    this.newTimeOff.reason = masterTimeOffActivity.reason;
    if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.TIME_OFF_AUTOSUBJECT)) {
      this.newTimeOff.name = masterTimeOffActivity.reason + ` - ${this.translate.instant('TIME_OFF')}`
      this.newTimeOff.subject = masterTimeOffActivity.reason + ` - ${this.translate.instant('TIME_OFF')}`
    } else {
      this.newTimeOff.name = masterTimeOffActivity.name;
      this.newTimeOff.subject = masterTimeOffActivity.subject;
    }
    this.newTimeOff.durationType = masterTimeOffActivity.durationType;
    this.newTimeOff.state = 0;
    this.newTimeOff.statecode = 0;
    this.newTimeOff.status = 100000000;
    this.newTimeOff.statusString = 'Open';
    this.newTimeOff.statusValue = 'Open';
    this.newTimeOff.type = ActivityType.TimeOffRequest;
    this.newTimeOff.scheduledStart = this.newTimeOff.totStartTime;
    this.newTimeOff.scheduledEnd = this.newTimeOff.totEndTime;
    this.newTimeOff.positionId = this.authenticationOfflineService.user.xPositionID;
    this.newTimeOff.totOwnerId = this.authenticationOfflineService.user.systemUserID;

    // const loader = await this.loadingController.create();
    this.timeOffService.display = true;

    // loader.present();

    //If device is offline
    if (this.device.isOffline) {
      await this.timeOffService.saveMyTimeOff(this.newTimeOff, false).then(() => {
        // loader.dismiss();
        // this.postTimeOffSave(newTimeOff, true);
        if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
          this.navService.setChildNavRoot(TimeOffDetailsComponent, PageName.TimeOffDetailsComponent, PageName.ActivitiesPageComponent, { isNewlyCreatedActivity: true });
          this.footerService.initButtons(FooterViews.TimeOffDetails);
        }
      });
    }
    //If device is online
    else if (!this.device.isOffline) {
      this.timeOffDataService.createNewTimeOff(this.newTimeOff).subscribe(
        async (success: any) => {
          await this.timeOffService.saveMyTimeOff(this.newTimeOff, true, success).then(() => {
            // loader.dismiss();
            // this.postTimeOffSave(newTimeOff, false);
            if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
              this.navService.setChildNavRoot(TimeOffDetailsComponent, PageName.TimeOffDetailsComponent, PageName.ActivitiesPageComponent, { isNewlyCreatedActivity: true });
              this.footerService.initButtons(FooterViews.TimeOffDetails);
            }
          });
        },
        async error => {
          //error save offline
          await this.timeOffService.saveMyTimeOff(this.newTimeOff, false).then(() => {
            // loader.dismiss();
            // this.postTimeOffSave(newTimeOff, true);
            if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
              this.navService.setChildNavRoot(TimeOffDetailsComponent, PageName.TimeOffDetailsComponent, PageName.ActivitiesPageComponent, { isNewlyCreatedActivity: true });
              this.footerService.initButtons(FooterViews.TimeOffDetails);
            }
          })
        });
    }
    this.openActivityDetails(this.newTimeOff);
    this.uiService.dismissLoader();

  }

  private async cloneAppointmentActivity(activity:AppointmentActivity){
    try {
      this.uiService.displayLoader();

      // Append Meeting Linked Entities
      if(!(this.activityService.hasOfflineMeetingData(activity.ID) || activity.ID.includes('offline_'))){
        const activityDoc = await this.activityDataService.getOfflineActivityDocByKey(DB_KEY_PREFIXES.MEETING_ACTIVITY+activity.ID);
        if(activityDoc){
          await this.activityDataService._appendMeetingDetailsToActivity(activity, activityDoc);
        }
      }
      let currentTime = new Date().getTime();
      let start, end;
      start = format(Utility.getRoundedDownTime(new Date()));
      start = format(addMinutes(start, 30));
      end = format(addMinutes(start, 30));
      let payload = new InitiateMeetingPayload(
        activity.subject,
        activity.location,
        new Date(start).getTime(),
        new Date(end).getTime(),
        '',
        OFFLINE_ID_PREFIX + new Date().getTime()
      );
      const newMeeting = await this.meetingService.createNewMeeting(payload, true);
      if(newMeeting){
        let data;
        if (!newMeeting.createdOffline) {
          // Online created meeting
          data = { id: newMeeting.ID, ...payload }
        } else {
          // Offline created meeting
          data = {
            id: newMeeting.ID,
            subject: newMeeting.subject,
            location: newMeeting.location,
            scheduledStart: newMeeting.scheduledStart,
            scheduledEnd: newMeeting.scheduledEnd,
          };
        }

        this.uiService.setUIServiceData({
          view: 'new-activity', data,
        });
        this.activityService.selectedActivity = newMeeting;
        if(activity.accounts && activity.accounts.length != 0){
          //this.activityService.selectedActivity = newMeeting;
            this.activityService.selectedActivity['accounts'] = [];
            activity.accounts.forEach(activityAccount=> {
              let account = this.accountOfflineService.getAccountById(activityAccount.id);
              if(!(!account || account.status === 2 || account.status == 548910003 || account.status == 548910010 || account.status == 548910011 || account.status == 548910012 || account.status == 548910013)){
                this.accountOfflineService.moveAccountToSelected(account);
              }
            })
            if (this.activityService.selectedActivity instanceof AppointmentActivity) {
              //await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
              if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
                await this.meetingService.addAccountsToMeeting(this.activityService.selectedActivity);
              }
            }
        }
        if(activity.contacts && activity.contacts.length != 0){
            this.activityService.selectedActivity['contacts'] = [];
            activity.contacts.forEach(activityContact=> {
              let contact = this.contactService.getContactByID(activityContact.ID);
              if(contact && contact.isActive){
                this.activityService.selectedActivity['contacts'].push(contact)
              }
            })
            if (this.activityService.selectedActivity instanceof AppointmentActivity) {
              // if (this.device.isOffline) {
              //   await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
              // }
              if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
                await this.meetingService.addContactsToMeeting(this.activityService.selectedActivity, true);
              }
            }
        }
        if(activity.accompaniedUserList && activity.accompaniedUserList.length != 0){
          this.activityService.selectedActivity['accompaniedUserList'] = [];
          activity.accompaniedUserList.forEach(activityUser=> {
            this.activityService.selectedActivity['accompaniedUserList'].push(activityUser)
          })
          if (this.activityService.selectedActivity instanceof AppointmentActivity) {
            // if (this.device.isOffline) {
            //   await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
            // }
            if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
              await this.meetingService.addRemoveAccompaniedUsers(this.activityService.selectedActivity['accompaniedUserList'])
            }
          }
      }
        if(activity.presentations && activity.presentations.length != 0){
          this.activityService.selectedActivity['presentations'] = [];
          activity.presentations.forEach(activityPresentation => {
            let foundPres;
            if(activityPresentation instanceof Presentation){
              foundPres = this.presentationService.presentation.find(p=> p.ioPresentationId == activityPresentation.contentId || p.contentId == activityPresentation.contentId);
            }else if (activityPresentation instanceof Resource){
              foundPres = this.resourceService.allResources.find(p=> p.contentId == activityPresentation.contentId);
            }
            if(foundPres && Utility.filterByDate(foundPres)){
              this.activityService.selectedActivity['presentations'].push(activityPresentation);
            }
          })
          if (this.activityService.selectedActivity instanceof AppointmentActivity) {
            //await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
            if (!this.device.isOffline && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
              try {
                await this.meetingService.addContentToMeeting(this.activityService.selectedActivity.presentations, this.activityService.selectedActivity)
              } catch (error) {
                this.notificationService.notify(this.translate.instant('ERROR_UPDATING_MEETING_PRESENTATIONS'), 'New Activity');
              }
            }
          }
        }
        if (this.activityService.selectedActivity instanceof AppointmentActivity) {
          await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
          this.openActivityDetails(this.activityService.selectedActivity);
        }
        this.uiService.dismissLoader();
      }
    } catch (error) {
      console.log('Error cloning appointment activity:'+error);
    }

  }

  private async clonePhoneActivity(activity:PhoneActivity){
    try {
      this.uiService.displayLoader();
      // Append Phone Call Linked Entities
      if(!(this.activityService.hasOfflinePhoneCallData(activity.ID) || activity.ID.includes('offline_'))){
        const activityDoc = await this.activityDataService.getOfflineActivityDocByKey(DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY+activity.ID);
        if(activityDoc){
          await this.activityDataService._appendPhoneCallDetailsToActivity(activity, activityDoc);
        }
      }

      let currentTime = new Date().getTime();
      let start, end;
      start = format(Utility.getRoundedDownTime(new Date()));
      start = format(addMinutes(start, 30));
      end = format(addMinutes(start, 30));
      let payload = new InitiatePhoneCallPayload(
        new Date(start).getTime(),
        new Date(end).getTime(),
        activity.subject,
        OFFLINE_PHONE_CALL_ID_PREFIX + new Date().getTime(),
        0
      );

      let newPhoneCall = await this.phonecallDataService.createPhoneCallActivity(payload);
      if(newPhoneCall){
        let data;
        if (!newPhoneCall.createdOffline) {
          // Online created meeting
          data = { id: newPhoneCall.ID };
        } else {
          // Offline created meeting
          data = {
            id: newPhoneCall.ID,
            subject: newPhoneCall.subject,
            location: newPhoneCall.location,
            scheduledStart: newPhoneCall.scheduledStart,
            scheduledEnd: newPhoneCall.scheduledEnd,
          };
        }
        this.uiService.setUIServiceData({
          view: 'new-activity', data,
        }); // this is an observable stream

        this.activityService.selectedActivity = newPhoneCall;

        if(activity.contacts && activity.contacts.length != 0){
          this.activityService.selectedActivity['contacts'] = [];
          newPhoneCall.selectedMobileNumber = activity.selectedMobileNumber
          const activityContact = activity.contacts[0];
          let contact = this.contactService.getContactByID(activityContact.ID);
          if(contact && contact.isActive){
            this.contactService.moveContactToSelected(contact);
            await this.phonecallDataService.addContactsToPhoneCallActivity(newPhoneCall);
          }
        }
        if(activity.accounts && activity.accounts.length != 0){
          this.activityService.selectedActivity['accounts'] = [];
          activity.accounts.forEach(activityAccount=> {
            let account = this.accountOfflineService.getAccountById(activityAccount.id);
            if(!(!account || account.status === 2 || account.status == 548910003 || account.status == 548910010 || account.status == 548910011 || account.status == 548910012 || account.status == 548910013)){
              this.accountOfflineService.moveAccountToSelected(account);
            }
          })
        }

        if (this.activityService.selectedActivity instanceof PhoneActivity) {
          this.phonecallDataService.saveMeeting(newPhoneCall);
          let phoneCallPayLoad = this.phonecallDataService.setPhoneCallPayload(this.activityService.selectedActivity as PhoneActivity)
          if (!this.device.isOffline && !this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID)) {
            await this.phonecallDataService.updatePhoneCall(this.activityService.selectedActivity as PhoneActivity, phoneCallPayLoad); //CWD-2414 Update the activity subject if auto subject feature action is enabled
          }
          else {
            await this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity);
          }
          this.openActivityDetails(this.activityService.selectedActivity);
        }
        this.uiService.dismissLoader();
      }
    } catch (error) {

    }
  }

  private async cloneEmailActivity(activity:EmailActivity){
    try {
      this.uiService.displayLoader();
      let scheduleTime = new Date().getTime().toString();
      let payload = {
        scheduledstart: scheduleTime,
        scheduledend: scheduleTime,
        subject: activity.subject,
        statuscode: 1,
        channelType: activity.channelType,
        channelId: activity.indskr_channelid,
        channelActivityType: activity.channelActivityType,
      };
      let newActivity:EmailActivity = await this.emailService.createNewEmailActivity(payload);
      if(this.navService.getCurrentMasterPageName() !== PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan !== 'callPlans') this.uiService.activeView = 'Email';
      let data = {
        id: newActivity.ID, subject: newActivity.subject, info: newActivity.info, type: newActivity.type,
        scheduledStart: newActivity.scheduledStart, scheduledEnd: newActivity.scheduledEnd,
      };
      this.activityService.addActivity(newActivity);
      if (this.uiService.activitiesPageTab === 'day') {
        this.uiService.setUIServiceData({
          view: 'new-activity', data
        });
      }
      this.emailService.selectedActivity = newActivity;
      this.activityService.selectedActivity = newActivity;
      this.emailService.setCurrentEmail(this.emailService.selectedActivity);
      /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
      if(this.emailService.selectedActivity && this.emailService.selectedActivity.accounts && this.emailService.selectedActivity.accounts.length != 0){
        this.emailService.selectedActivity['accounts'] = [];
        this.emailService.selectedActivity.accounts.forEach(activityAccount=> {
          let account = this.accountOfflineService.getAccountById(activityAccount.id);
          if(!(!account || account.status === 2 || account.status == 548910003 || account.status == 548910010 || account.status == 548910011 || account.status == 548910012 || account.status == 548910013)){
            this.accountOfflineService.moveAccountToSelected(account);
          }
        })
      }
      /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
      if (!_.isEmpty(activity.emailActivityParties)) {
        let emailActivityParties: EmailActivityParty[] = [];
        let selectedContactsForNonNativeChannelSMS = false;
        if (newActivity.isMessageExternal && newActivity.channelActivityType == ChannelActivityType.SMS && newActivity?.isPreviewEnabled) {
          selectedContactsForNonNativeChannelSMS = true;
        }
        activity.emailActivityParties.forEach(emailContact => {
          const contact = this.contactService.getContactByID(emailContact.indskr_contactid);
          if(contact && contact.isActive){
            // this.addEmaillAddressToContact(contact, emailActivity);
            let emailActivityParty: EmailActivityParty = new EmailActivityParty();
            emailActivityParty.contact_firstname = contact.firstName;
            emailActivityParty.contact_lastname = contact.lastName;
            emailActivityParty.indskr_contactid = contact.ID;
            emailActivityParty.contact_mobilephone = contact.mobilePhone;
            emailActivityParty.contact_indskr_facebookpsid = contact.indskr_facebookpsid;
            emailActivityParty.emailAddresses = [];
            contact.emailAddressList.forEach(emailAddress => {
              if (emailContact.emailAddresses && emailContact.emailAddresses.some(e=> e.emailAddress == emailAddress.emailAddress)) {
                let emailAddressDetails: EmailAddress = new EmailAddress();
                emailAddressDetails.email_addressid = emailAddress.emailAddressId;
                emailAddressDetails.emailAddress = emailAddress.emailAddress
                emailActivityParty.emailAddresses.push(emailAddressDetails);
              }
            });
            // update selected phone number for sharing message on non-native SMS channel
            if (selectedContactsForNonNativeChannelSMS) {
              emailActivityParty.contact_mobilephone = emailContact.contact_mobilephone;
            }
            emailActivityParties.push(emailActivityParty);
          }
        });
        await this.emailService.updateEmailActivityParties(emailActivityParties);
      }

      //Update Product
      let isProductInactive:boolean = false;
      if(activity.product_id){
        let addedProduct = this.emailService.emailProductSKU.find(p=> p.ID == activity.product_id);
        if(addedProduct && addedProduct.status == "A"){
          await this.emailService.updateEmailActivity({ product_id: activity.product_id }, EmailActionType.PRODUCT);
        }else{
          isProductInactive = true;
        }
      }


      //Update Template
      if(activity.template_id && !isProductInactive){
        let selectedTemplate = this.emailService.emailTemplates.find(t => t.indskr_emailtemplateid == activity.template_id);
        if(selectedTemplate && (selectedTemplate.statuscode == 1 || selectedTemplate.statuscode == 548910001)){
          let selectedChannel = selectedTemplate.channelTypes.find(ct => ct.channelType === this.emailService.selectedActivity.channelType);
          let emailBody = (selectedChannel ? selectedChannel.body : selectedTemplate.indskr_body);
          let request = {
            template_id: selectedTemplate.indskr_emailtemplateid,
            subject: selectedTemplate.indskr_email_subject,
            description: emailBody,
            product_id: selectedTemplate.product_id,
            indskr_therapeuticareaid: selectedTemplate.therapeuticareaId
          }
          await this.emailService.updateEmailActivity(request, EmailActionType.TEMPLATE_ADD);
        }
      }

      if(!isProductInactive && newActivity.channelType == ChannelType.EMAIL){
        //newActivity.emailAttachments = activity.emailAttachments;
        //Update Attachment
        let emailResources = [];
        if(activity.emailAttachments && activity.emailAttachments.length != 0){
          activity.emailAttachments.forEach(emailRes => {
            let foundRes = this.resourceService.allResources.find(a=> a.ioResourceId == emailRes.indskr_resourceid || a.ioDocumentId == emailRes.indskr_resourceid);
            if(foundRes && foundRes.stateCode != 1){
              emailResources.push(foundRes);
            }
          });
        }
        await this.emailService.detectResourceAddedOrRemoved(emailResources);
      }
      this.openActivityDetails(this.emailService.selectedActivity);
      this.uiService.dismissLoader();
    } catch (error) {
      console.log("Error while cloning email activity"+error);
    }
  }

  public checkCustomScrapCondition(activity): boolean {
    const currentUser = this.authenticationOfflineService.user;
    let flag = true;
    if (activity && activity.type == ActivityType.FollowUp && activity.ownerId != this.authenticationOfflineService.user.systemUserID) {
      flag = false;
    } else if (activity && activity.type == ActivityType.Order) {
      flag = false;
      if(activity.ownerId == this.authenticationOfflineService.user.systemUserID && (activity as OrderActivity).orderStatusString == 'Draft'){
        flag = true;
      }
    } else if (activity && activity.type == ActivityType.SurgeryOrder) {
        const hasVisitorFullAccess   = currentUser?.buSettings['indskr_covisitoraccessonprocedurelog'] === CovisitorAccess.FULL_ACCESS;
        const isCovisitor = activity.coVisitors?.some((coVisitors) => coVisitors.userId === currentUser.systemUserID);
        const isOwner = activity.ownerId === currentUser.xSystemUserID;
        const isCoowner = activity.coOwners?.some((coOwner) => coOwner.userId === currentUser.systemUserID);
        const isCancelledOrCompleted = activity.status === 548910001 || activity.status === 548910005

        flag = !isCancelledOrCompleted && (isOwner || isCoowner || (isCovisitor && hasVisitorFullAccess));
    }else if(activity && activity.type == ActivityType.ProcedureTracker
      && (activity as ProcedureTrackerActivity).status === 548910001
      && (this.activityService.selectedActivity && this.activityService.selectedActivity.ownerId == this.authenticationOfflineService.user.systemUserID)
      || this.device.isOffline) {
        return false;
    } else if (activity && activity.type == ActivityType.Email && (this.authenticationOfflineService.user.systemUserID != activity.ownerId || activity.status != 1)){
      flag = false;
    } else if (activity && activity.type == ActivityType.Appointment) {
      flag = false;
      if(activity.state == MeetingActivityState.Open) {
        const hasVisitorFullAccess = currentUser?.buSettings['indskr_covisitoraccessonmeeting'] === CovisitorAccess.FULL_ACCESS;
        const isCoVisitor = (activity: AppointmentActivity): boolean => !_.isEmpty(activity.accompaniedUserList) &&
          activity.accompaniedUserList.some(user => user.id === currentUser.systemUserID);
        if (this.activityService.isActivityOwner(activity) || (hasVisitorFullAccess && isCoVisitor(activity))) {
          flag = true;
        }
      }
    }
     else if (activity && activity.type == ActivityType.StoreCheck &&
      (this.device.isOffline || activity.state == 1 || (activity as StoreCheckActivity).ownerId != this.authenticationOfflineService.user.systemUserID)) {
      flag = false
    } else if (activity && activity.type == ActivityType.PhoneCall && (activity["meetingOwnerId"] != this.authenticationOfflineService.user.systemUserID)){
      flag = false
    }else if (activity && activity.type == ActivityType.CaseIntake && !((activity as CaseActivity)._case_status_value.toLowerCase().includes('open'))){
       //Customer Inquiry
      flag = false
    }else if(activity && (activity.type == ActivityType.TimeOffRequest || activity.type == ActivityType.TimeOff)
           && (((activity as TimeOffActivity).status == 100000003) || ((activity as TimeOffActivity).status == 100000004))) {
      flag = false;
    }else if (activity && activity.type == ActivityType.SetBooking &&
      (this.device.isOffline ||
      !((activity as SetBookingActivity).indskr_status == SetBookingStatus.DRAFT ||
        (activity as SetBookingActivity).indskr_status == SetBookingStatus.SUBMITTED
      ))){
       //Kit Booking
      flag = false;
    }
    return flag;
  }

  public checkCustomCloneCondition(activity:Activity):boolean {
    if(!activity) return false;
    let flag:boolean = false;
    switch (activity.type) {
      case ActivityType.Appointment:
        if(activity.isCompleted || (activity as AppointmentActivity).status !== 4){
          flag = true;
        }
        break;
      case ActivityType.PhoneCall:
        if(activity.isCompleted){
          flag = true;
        }
        break;
      case ActivityType.FollowUp:
        if(activity.isCompleted){
          flag = true;
        }
        break;
      case ActivityType.TimeOff:
        if(activity.isCompleted || (activity as TimeOffActivity).status == 100000004){
          flag = true;
        }
        break;
      case ActivityType.Email:
        if(activity.isCompleted || (activity as EmailActivity).emailStatus == 2 || (activity as EmailActivity).emailStatus == 3 || (activity as EmailActivity).emailStatus == 4 || (activity as EmailActivity).emailStatus == 8 || (activity as EmailActivity).emailStatus == 548910000){
          flag = true;
        }
        break;
      case ActivityType.Order:
        if((activity as OrderActivity).status == 548910001 || (activity as OrderActivity).status == 100001){
          flag = true;
        }
        break;
      case ActivityType.CaseIntake:
        const statusValue = (activity as CaseActivity)._case_status_value.toLocaleLowerCase();
        const statusCheck = (!statusValue.includes('open') && !statusValue.includes('pending sync'));
        if(activity.isCompleted || statusCheck) {
          flag = true;
        }
        break;
      case ActivityType.SurgeryOrder:
        flag = (activity as SurgeryOrderActivity).status !== 548910005
        break;
      case ActivityType.ProcedureTracker:
        if(!this.device.isOffline){
          if((activity as ProcedureTrackerActivity).status == 548910001 || (activity as ProcedureTrackerActivity).status == 100001){
            flag = true;
          }
        }
        break;
      case ActivityType.SetBooking:
        if(!this.device.isOffline && (activity as SetBookingActivity).indskr_status !== SetBookingStatus.CANCELLED && (activity as SetBookingActivity).indskr_status !== SetBookingStatus.PENDINGCANCEL && (activity as SetBookingActivity).indskr_status !== SetBookingStatus.CANCELERROR){
          flag=true;
        }
        break;
    }
    return flag;
  }

  public scrapActivityFromSlideButton(activity:Activity){
    if(!activity) return;

    let foundActivity = activity instanceof AppointmentActivity && activity.indskr_parentcallid
      ? activity
      : this.activityService.getActivityByID(activity.ID);
    if(foundActivity){
      this.events.publish('deleteMeeting',foundActivity);
    }
  }

  private async _handleMoreOptionsPopover(activity,event) {
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'activities-pane-more-options-select',
      showClearAllBtn: false,
      data: [],
    };
    if(this.checkCustomCloneCondition(activity)){
      dropdownListDetail.data.push({
        title: this.translate.instant('CLONE'),
        id: 'Clone',
        isSelected: false,
      })
    }

    if(!activity.isCompleted && this.checkCustomScrapCondition(activity)){
      let title =  this.translate.instant('SCRAP');

      if (this.authenticationOfflineService.user.buSettings &&
        ((this.authenticationOfflineService.user.buSettings['indskr_cancelprocedurelogwithreason'] &&
          activity instanceof SurgeryOrderActivity) ||
          (this.authenticationOfflineService.user.buSettings['indskr_cancelmeetingwithreason'] &&
            activity instanceof AppointmentActivity))
      ) {
        title = this.translate.instant('DELETE_OR_CANCEL_PROCEDURE_LOG_HEADER')
      }

      dropdownListDetail.data.push({
        title,
        id: 'Scrap',
        isSelected: false,
      })
    }

    let target = {
      top: event.target.getBoundingClientRect().top,
      bottom: event.target.getBoundingClientRect().bottom,
      height: event.target.getBoundingClientRect().height,
      left: event.target.getBoundingClientRect().left,
      right: event.target.getBoundingClientRect().right,
      width: event.target.getBoundingClientRect().width,
      x: event.target.getBoundingClientRect().x,
      y: event.target.getBoundingClientRect().y,
    };
    let ev:any = {
      target: {
         getBoundingClientRect: () => {
            return target;
         }
      }
   };
    let moreOptionsPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: ev,
    });
    moreOptionsPopover.onDidDismiss().then(async (data: any) => {
      if (data.data) data = data.data;
      if (data && data.selectedItems && data.selectedItems.length == 1) {
        if (data.selectedItems[0].id == 'Clone') {
          this.cloneActivity(activity);
        } else if (data.selectedItems[0].id == 'Scrap') {
          this.scrapActivityFromSlideButton(activity);
        }
      }
    })
    moreOptionsPopover.present();
  }

  private async cloneProcedureTracker(activity: ProcedureTrackerActivity) {
    try {
      const currentTime = new Date().getTime();
      const isWeeklyFlag = ('Weekly' === this.authenticationOfflineService.user.procedureLogDuration);
      const start = format(Utility.getRoundedDownTime(new Date()));
      const end = format(addMinutes(start, 30));
      let startOfWeek, endOfWeek;
      let procedureTrackersPayLoad = [];

      if (isWeeklyFlag) {
        startOfWeek = moment(start).startOf('week').toDate();
        endOfWeek = moment(start).endOf('week').toDate();
        const firstDay = moment(start).startOf('week').format('MMM DD, YYYY');
        const lastDay = moment(start).endOf('week').format('MMM DD, YYYY');
        this.notificationService.notify(this.translate.instant('PROCEDURE_LOG_FOR_WEEK', { startDate: firstDay, endDate: lastDay }), 'New Activity', 'top', ToastStyle.INFO);
      }

      if (activity.procedureTrackers.length > 0) {
        await this.activityDataService.updateActivityDetails(activity);
        procedureTrackersPayLoad = activity.procedureTrackers.map((procedureTracker) => {
          return ({
            ...procedureTracker,
             trackerContacts: procedureTracker.trackerContacts.map((trackerContact) => ({ ...trackerContact, id: trackerContact.ID }))
          })
        })
      }

      let payload: any = {
        salesorderid: 'offline_proceduretracker_' + currentTime,
        indskr_externalid: 'offline_proceduretracker_' + currentTime,
        indskr_ordertype: 548910002,
        ordernumber: 'Surgery Order',
        name: 'Bulk Procedure Log',
        pendingPushToDynamics: true,
        statuscode: 1,
        statecode: 0,
        activitytypecode: 'ProcedureTracker',
        createdon: currentTime,
        modifiedon: currentTime + '',
        scheduledstart: (isWeeklyFlag) ? startOfWeek : new Date(start).getTime(),
        scheduledend: (isWeeklyFlag) ? endOfWeek : new Date(end).getTime(),
        divisionName: this.authenticationOfflineService.user.businessUnitName,
        divisionId: this.authenticationOfflineService.user.xBusinessUnitId,
        procedureTrackers: procedureTrackersPayLoad
      }

      let newProcedureTracker = new ProcedureTrackerActivity(payload);
      if (isWeeklyFlag) {
        newProcedureTracker.scheduledEnd = endOfWeek;
      }

      newProcedureTracker.statusString = 'Draft';
      newProcedureTracker.modifiedby = newProcedureTracker.ownerId = this.authenticationOfflineService.user.systemUserID;
      newProcedureTracker.modifiedByName = newProcedureTracker.ownerNameString = this.authenticationOfflineService.user.displayName;
      newProcedureTracker.ownerNameString = this.authenticationOfflineService.user.displayName;

      const procedureLogAction = {
        onDynamics: !this.device.isOffline,
        onLocalCopy: true,
        onLocalDatabase: true
      }

      this.uiService.displayLoader();
      await this.procedureTrackerActivityDataService.createOrderActivity(procedureLogAction, [newProcedureTracker], currentTime, false).then(async (succ) => {
        await this.activityDataService.updateActivityDetails(newProcedureTracker);
        this.activityService.selectedActivity = newProcedureTracker;
        this.trackingService.tracking('AgendanewProcedureTracker', TrackingEventNames.PROCEDURE_TRACKER);
        this.navService.setChildNavRoot(ProcedureTrackerDetailsComponent, PageName.ProcedureTrackerDetailsComponent, PageName.ActivitiesPageComponent, { from: 'AgendaView', activity: newProcedureTracker });
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = true;
        this.activityService.selectedActivityAtHome = this.activityService.selectedActivity;
        this.uiService.dismissLoader();
      if (!this.uiService.toolsActivityActive) {
        this.events.publish("refreshAgenda");
      }
      }).catch(err => {
        // Handle any error occured during order activity creation
      });
    } catch {

    }
  }

  private async _handleActTabActivityFilter(){
    let options;
    let selectedDateFromFilter = {
      endDate: "",
      startDate: "",
    };
    let selectedFilterData = [];
    if(this.activityService.multiOptionActivityFilter ){
      selectedFilterData = this.activityService.multiOptionActivityFilter;
    }
    const filterMenuModal = await this.modalCtrl.create({
      component: IndFilterMenuComponent,
      componentProps: {
        viewData: {
          data: options,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('FILTERS'),
          isSearchEnabled: true,
          isReadOnly: false,
          selectedDate: selectedDateFromFilter
        },
        selectedFilter: selectedFilterData,
        from: PageName.ActivitiesPageComponent,
        subFrom: ContactsModel.ACTIVITIES_TAB,
      },
      backdropDismiss: false
    });
    filterMenuModal.present();
    filterMenuModal.onDidDismiss().then(async (obj: any) => {
      if(obj && obj.data && obj.data.isDone){

        if(obj.data.selectedItems.length == 0){
          this.authenticationOfflineService.impersonatedUser = undefined;
          this.activityService.agendaTeamViewActivities = [];
          this.activityService.multiOptionActivityFilter = [];
          this.events.publish('filterActivitiesDataForSelectedUser',ActivityType.AllActivity);
        }else if( obj.data.selectedItems.some(a=> (a.categoryPath == "Users" && a.value != this.authenticationOfflineService.user.systemUserID)) ){
          this.authenticationOfflineService.impersonatedUser = undefined;
          this.activityService.agendaTeamViewActivities = [];
          this.activityService.multiOptionActivityFilter = obj.data.selectedItems;
          this.events.publish('filterActivitiesDataForSelectedUser','MultipleOptionFilter');
        }else if( obj.data.selectedItems.some(a=> a.categoryPath == "Activity_Type" && a.value == ActivityType.AllActivity) ){
          this.authenticationOfflineService.impersonatedUser = undefined;
          this.activityService.agendaTeamViewActivities = [];
          this.activityService.multiOptionActivityFilter = [];
          this.events.publish('filterActivitiesDataForSelectedUser',ActivityType.AllActivity);
        }else {
          this.authenticationOfflineService.impersonatedUser = undefined;
          this.activityService.agendaTeamViewActivities = [];
          this.activityService.multiOptionActivityFilter = obj.data.selectedItems;
          this.events.publish('filterActivitiesDataForSelectedUser','MultipleOptionFilter');
        }
      }
      this.agendaFooterService.initButtons(AgendaFooterView.Act);
    });
  }
}
// tslint:disable-next-line:max-file-line-count
