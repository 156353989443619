import { ModalController, NavController, NavParams } from '@ionic/angular';
import { Component, Input, OnDestroy } from '@angular/core';

import { NavigationService } from '../../../../services/navigation/navigation.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DeviceService } from '../../../../services/device/device.service';
import { IoFileService } from '../../../../services/io-file-service/io-file-service';
import { SettingsService } from '../../../../services/app-settings/settings.service';
import { SettingsAbout } from '../../../../classes/app-settings/settings-about';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

/**
 * Generated class for the AboutDetailsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
    selector: 'about-details',
    templateUrl: 'about-details.html',
    styleUrls: ['about-details.scss'],
})
export class AboutDetailsComponent implements OnDestroy {
    public aboutTitle: string;
    public contentToDisplay: any;
    public urlLink: SafeResourceUrl;
    public urlString: string;
    public isReadyToDisplay = false;
    private readonly docDownloadSubscription: Subscription;

    public backButton = [
        {
            id: 'close',
            icon: 'chevron-back-outline',
            isDisabled: false,
            align: 'left',
        },
    ];
    public pageTitle = '';

    constructor(
        private readonly navParams: NavParams,
        private readonly navService: NavigationService,
        private readonly navCtrl: NavController,
        private readonly sanitizer: DomSanitizer,
        public deviceService: DeviceService,
        private readonly ioFile: IoFileService,
        private readonly settings: SettingsService,
        private readonly modalCtrl: ModalController,
        private readonly translate: TranslateService
    ) {
        this.aboutTitle =
            (this.navParams.data && this.navParams.data.aboutTitle) || '';

        this.docDownloadSubscription = this.settings.isDownloaded.subscribe(
            (downloaded: boolean) => {
                if (downloaded) {
                    this.initDoc();
                }
                else{
                  this.settings.downloadSettings();
                }
            }
        );
        this.pageTitle = `Omnipresence ${this.translate.instant(
            this.aboutTitle
        )}`;
    }

    ngOnDestroy() {
        if (this.docDownloadSubscription) {
            this.docDownloadSubscription.unsubscribe();
        }
    }

    initDoc() {
        let doc: SettingsAbout;

        if (this.aboutTitle == 'COPYRIGHT_INFO') {
            this.contentToDisplay = this.copyrightInfo;
            doc = this.settings.legalDocs.find(
                (a) => a.name == 'Copyright_Information'
            );
        } else if (this.aboutTitle == 'TERMS_AND_CONDITIONS') {
            this.contentToDisplay = this.termsAndConditions;
            doc = this.settings.legalDocs.find(
                (a) => a.name == 'Terms_And_Conditions'
            );
        } else if (this.aboutTitle == 'PRIVACY_POLICY') {
            this.contentToDisplay = this.privacy;
            doc = this.settings.legalDocs.find(
                (a) => a.name == 'Privacy_Policy'
            );
        }
        if (doc) this.getUrl(doc);
    }

    async onClosePage() {
        if (!this.navService.getCurrentPageName()) {
            this.modalCtrl.dismiss();
        } else {
            await this.navService.popWithPageTracking();
        }
    }

    public getUrl(doc) {
        if (this.deviceService.isNativeApp) {
            this.urlString = this.ioFile.getLocalURLDocument(`${doc.name}.pdf`);
            this.isReadyToDisplay = !!this.urlString;
        } else {
            this.urlLink = this.sanitizer.bypassSecurityTrustResourceUrl(
                `${doc.url}#view=FitH&toolbar=0&navpanes=0`
            );
            this.isReadyToDisplay = !!this.urlLink;
        }
    }

    public copyrightInfo = `
        <h4>Last modified: October 19, 2018</h4>
        <p>The following copyrights are owned by <b>Microsoft Corporation</b></p>
        <ul>
            <li>App Insights, App Center</li>
            <li>Azure (All related Azure services, resources, and capacities)</li>
            <li>Dynamics (Dynamics, Dynamics 365)</li>
            <li>Office (Office, Office 365, Word, PowerPoint, Outlook)	</li>
            <li>Power BI (Power BI Service, Power BI Desktop, Power BI Pro)</li>
        </ul>
        <p>The following copyrights are owned by their <b>respective owners</b></p>
        <ul>
            <li>Apache Airflow</li>
            <li>Apple iOS SDK, iOS frameworks Swift</li>
            <li>Apple Siri</li>
            <li>Aspose PDF .NET APIs, PPTX .NET APIs</li>
            <li>Ephox TinyMCE LGPL</li>
            <li>Google Voice</li>
            <li>Python Software Foundation</li>
            <li>R</li>
            <li>Talend</li>
            <li>TokBo</li>
        </ul>
        <p>Indegene, Inc. makes no claims to these copyrights and uses them according to the respective licenses.</p>
  `;

    public termsAndConditions = `
  <h4>Introduction</h4>

  <p>Your employer whether a company, firm or organization where you are working on a full time basis, on contract, or through a third party agency (“Employer”) has subscribed to the Omnipresence platform either directly with Indegene, Inc. (“Indegene”) or indirectly via an authorized reseller. These Terms Of Service (“User Terms”) do not change any terms of a business agreement with your Employer or between your Employer and a reseller, as applicable, but is a binding agreement between you (&lt;b&gt;End User&lt;/b&gt; or &lt;b&gt;you&lt;/b&gt;) and Indegene. These User Terms govern your use of the Omnipresence Mobile or Web Applications including all related documentation (the 'App') according to the terms of your Employer’s Omnipresence license (“Omnipresence License”). The App is licensed, not sold, to you.</p>
  <p>YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THESE USER TERMS; (B) REPRESENT THAT YOU ARE 18 YEARS OF AGE OR OLDER/OF LEGAL AGE TO ENTER INTO A BINDING AGREEMENT; AND (C) ACCEPT THESE USER TERMS. IF YOU DO NOT AGREE TO THESE USER TERMS, DO NOT USE THE APP AND CONTACT YOUR EMPLOYER.</p>
  <ol>
      <li>
          License Grant. Subject to the terms of these User Terms and to the Omnipresence License, Indegene grants you a limited, non-exclusive, and nontransferable license to:
          <ol type="a">
              <li>
                  download, install, and use the App for your use on a single mobile device or desktop owned or otherwise controlled by you or your Employer (&lt;b&gt;Device&lt;/b&gt;) strictly in accordance with the App's documentation; and
              </li>
              <li>
                  access, stream, download, and use on such Device the Content and Services (as defined in Section 5) made available in or otherwise accessible through the App, strictly in accordance with these User Terms and the Terms of Use applicable to such Content and Services as set forth in Section 5.
              </li>
          </ol>
      </li>
      <li>
          <u>Reservation of Rights.</u> You acknowledge and agree that the App is provided under license, and not sold, to you. You do not acquire any ownership interest in the App under these User Terms, or any other rights thereto other than to use the App in accordance with the license granted, and subject to all terms, conditions, and restrictions, under these User Terms. Indegene and its licensors and service providers reserve and shall retain their entire right, title, and interest in and to the App, including all copyrights, trademarks, and other intellectual property rights therein or relating thereto, except as expressly granted to you in these User Terms.
      </li>
      <li>
          <u>Content and Services.</u> The App may provide you with access to the Hosted Services (as defined in the Omnipresence License ) (&lt;b&gt;Omnipresence&lt;/b&gt;) and products and services accessible thereon, and certain features, functionality, and content accessible on or through the App may be hosted on Omnipresence (collectively, &lt;b&gt;Content and Services&lt;/b&gt;). Your access to and use of such Content and Services are governed by the &lt;b&gt;Omnipresence License&lt;/b&gt;. Your access to and use of such Content and Services requires your Employer to execute such a &lt;b&gt;Omnipresence License&lt;/b&gt; and to register you with Omnipresence, and their failure to do so may restrict you from accessing or using certain of the App's features and functionality. Any violation of such &lt;b&gt;Omnipresence License&lt;/b&gt; will also be deemed a violation of these User Terms.
      </li>
      <li>
          <u>Updates.</u> Indegene may from time to time in its sole discretion develop and provide App updates, which may include upgrades, bug fixes, patches, other error corrections, and/or new features (collectively, including related documentation, 'Updates'). Updates may also modify or delete in their entirety certain features and functionality. You agree that Indegene has no obligation to provide any Updates or to continue to provide or enable any particular features or functionality other than what is provided by your Employer’s Omnipresence License. You shall promptly download and install all Updates as required by your Employer and acknowledge and agree that the App or portions thereof may not properly operate should you fail to do so. You further agree that all Updates will be deemed part of the App and be subject to all terms and conditions of these User Terms.
      </li>
      <li>
          <u>Collection and Use of Your Information.</u> You acknowledge that when you download, install, or use the App, Indegene may use automatic means (including, for example, cookies and web beacons) to collect information about your Device and about your use of the App. You also may be required to provide certain information about yourself as a condition to downloading, installing, or using the App or certain of its features or functionality. All information we collect through or in connection with this App is subject to the Privacy Policy
      </li>
      <li>
          <u>Prohibited Uses. You must not use the Content and Services in any way that violates the Acceptable Use Policy in your employer’s.</u>Omnipresence License. This policy includes may cover:
          <ol type="a">
              <li>Genral usage rules</li>
              <li>Unlawful Content</li>
              <li>Graphic material</li>
              <li>Factual accuracy</li>
              <li>Marketing and spam</li>
              <li>Monitoring</li>
              <li>Hyperlinks</li>
              <li>Harmful software</li>
          </ol>
          <p>You should contact your Employer’s appropriate representatives to request more information about the prohibited uses.</p>
      </li>
      <li>
          <u>Content.</u> The App may display, include, or make available content from your Employer or third-party content (including data, information, applications, and other products, services, and/or materials) or provide links to such content for customer engagement purposes (“Customer Engagement Materials”). You acknowledge and agree that Indegene is not responsible for any Customer Engagement Materials, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Indegene does not assume and will not have any liability or responsibility to you or any other person or entity for any Customer Engagement Materials. Customer Engagement Materials and links thereto are provided solely as a functionality to you, and you access and use them entirely at your own risk and subject to your Employer’s and any applicable third parties' terms and conditions.
      </li>
      <li>
          <u>Term and Termination.</u> The term of User Terms commences when you download and install the App or have this done for you by your Employer and will continue in effect until terminated by your Employer or Indegene as set forth in this section. In addition, these User Terms will terminate immediately and automatically without any notice if you violate any of the terms and conditions of these User Terms. Upon termination all rights granted to you under this User Terms will also terminate; you must cease all use of the App and delete all copies of the App from your Device or have this done for you by your Employer. Termination will not limit any of Indegene's rights or remedies at law or in equity.
      </li>
      <li>
          <u>Disclaimer of Warranties, Limitations of Liability, Indemnification, Governing Law  and other rights are according to the provisions of your Employer’s.</u> Omnipresence License. You should contact your Employer’s appropriate representatives to request more information about these terms.
      </li>
      <li>
          <u>Severability.</u> If any provision of these User Terms is illegal or unenforceable under applicable law, the remainder of the provision will be amended to achieve as closely as possible the effect of the original term and all other provisions of these User Terms will continue in full force and effect.
      </li>
      <li>
          <u>Entire User Terms.</u> These User Terms and your Employer’s Omnipresence License constitute the entire agreement between you and Indegene with respect to the App and supersede all prior or contemporaneous understandings and agreements, whether written or oral, with respect to the App.
      </li>
  </ol>
  `;

    public privacy = `
        <h3>Last modified: October 10, 2018</h3>
        <h3>Introduction</h3>
        <p>Omnipresence respects your privacy and is committed to protecting it. Your employer whether a company, firm or organization where you are working on a full time basis, on contract, or through a third party agency (“Employer”) has subscribed to the Omnipresence platform either directly with Indegene, Inc. (“Indegene”) or indirectly via an authorized reseller. This privacy statement does not change any terms of a business agreement with your Employer or between your Employer and a reseller, as applicable, but is simply intended to provide additional information to you regarding data in the Omnipresence platform. Your Employer is regarded as a data controller within the meaning of applicable data protection laws, and Indegene's processing of such data for the Omnipresence platform is under the direction of your Employer, directly or indirectly as applicable. You should contact your Employer about any questions regarding usage of data as part of the Omnipresence platform.</p>
        <p>This statement describes :</p>
        <ul>
            <li>
                The types of personal information we may collect or that you may provide when you download, install, register with, access, or use the Omnipresence Mobile or Web Applications (the 'App').
            </li>
            <li>
                Our practices for collecting, using, maintaining, protecting, and disclosing that information.
            </li>
        </ul>
        <p>This policy applies only to information we collect in this App, in email, text, and other electronic communications sent through or in connection with this App and the Omnipresence platform.</p>
        <p>This policy DOES NOT apply to information that:</p>
        <ul>
            <li>
                We collect offline or on any other Indegene apps or websites, including websites you may access through this App.
            </li>
            <li>
                You provide to or is collected by any third party or your Employer Other Indegene websites and apps, third parties, and your Employer, may have their own privacy policies, which we encourage you to read before providing information on or through them.
            </li>
        </ul>
        <p>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, register with, or use this App and contact your Employer directly. This policy may change from time to time. Please check the policy periodically for updates.</p>
        <ol>
            <li>
                <b class="list-header">Information We Collect and How We Collect It</b>
                <p>We collect information from and about users of our App:</p>
                <ul>
                    <li>PRIVACY_DIR_FROM_YOU</li>
                    <li>Automatically when you use the App.</li>
                </ul>
            </li>
            <li>
                <b class="list-header">Information You Provide to Us</b>
                <p>For you to download, register with, or use this App, your Employer or you may provide information:</p>
                <ul>
                    <li>
                        By which you may be personally identified, such as: name, email address, telephone number, Employer, or similar information that is defined as personal or “personally identifiable” under applicable law ('personal information').
                    </li>
                    <li>
                        That is about you but individually does not identify you, such as: business contact information, Employer information not directly related to you, or similar information needed for your use of the App.
                    </li>
                </ul>
                <p>This information includes:</p>
                <ul>
                    <li>
                        Information that you enter or are required to enter by your Employer by filling in forms in the App. This includes information provided at the time of registering to use the App and also when reporting issues or opening tickets.
                    </li>
                    <li>
                        Records and copies of your correspondence (including email addresses and phone numbers), if you contact us.
                    </li>
                    <li>
                        Details of transactions or communications you carry out through the App and of the fulfillment of those transactions or communications.
                    </li>
                </ul>
            </li>
            <li>
                <b class="list-header">Automatic Information Collection and Tracking</b>
                <p>When you download, access, and use the App, it may use technology to automatically collect:</p>
                <ul>
                    <li>
                        <b>Usage Details.</b> When you access and use the App, we may automatically collect certain details of your access to and use of the App, including traffic data, location data, logs, and other communication data and the resources that you access and use on or through the App.
                    </li>
                    <li>
                        <b>Device Information.</b> We may collect information about your mobile device and internet connection, including the device's unique device identifier, IP address, operating system, browser type, mobile network information, and the device's telephone number.
                    </li>
                    <li>
                        <b>Stored Information, Files, Resources.</b> This App may collect real-time information about the location of your device. When you have location services turned on for you device, the App will use such services to determine your physical location to collect such information.
                    </li>
                    <li>
                        <b>Location Information.</b> This App may collect real-time information about the location of your device. When you have location services turned on for you device, the App will use such services to determine your physical location to collect such information. </li>
                </ul>
                <p>
                    If you do not want us to collect this information do not download the App or delete it from your device and contact your Employer directly. Additionally, you may be able to turn off access to specific stored information, files, resources or location services via your device’s preferences controls as permitted by your Employer’s hardware and software policies. Note, however, that if you turn off such access you may not be able to access those features of the App or they may not function as expected.
                    </p>
                    <p>We also may use these or other technologies to collect information about your Employer’s customer engagement activities over time as permitted by functionalities included in your Employer’s Omnipresence licenses. If you have any questions about such action then you should contact your Employer directly.</p>
            </li>
            <li>
                <b class="list-header">Information Collection and Tracking Technologies</b>
                <p>The technologies we use for automatic information collection may include:</p>
                <ul>
                    <li>
                        <b>Cookies (or mobile cookies).</b> A cookie is a small file placed on your smartphone. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your device. However, if you select this setting you may be unable to access certain features of the App or they may not function as expected. </li>
                    <li>
                        <b>Localized Database.</b> When using the App, it may download on your device, store and periodically sync this localized data. The localized database could contain the information and content you use the App for, such as your information, your customer data, your calendar, your customer signed forms, your presentations as well as usage or security data related to the App and its functionalities.
                    </li>
                    <li><b>Web Beacons.</b> Pages of the App or content contained in the App or emails generated from or about the App may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the App, for example, to count users who have visited those pages or opened an email and for other related App statistics (for example, recording the popularity of certain content and verifying system and server integrity).</li>
                    <li><b>Locally Shared Objects (LSOs or Flash Cookies).</b> Pages of the App, and parts of the Omnipresence platform use LSOs in a similar way to regular Cookies. These small files which are automatically downloaded onto your computer and are used to track activity and collect data the same way normal Cookies do.</li>
                </ul>
            </li>
            <li>
                <b class="list-header">Third-Party Information Collection</b>
                <p>When you use the App or its content, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties include:</p>
                <ul>
                    <li>Third party service providers to Indegene which enable certain functionality or analytics for the Omnipresence platform</li>
                    <li>Analytics service providers or content developers related to the content provided in the Apps by your Employer or you, if applicable</li>
                    <li>Your mobile device manufacturer</li>
                    <li>Your mobile service provider</li>
                </ul>
                <p>
                    Some of these third parties may use tracking technologies to collect information about you when you use the App. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites, apps, and other online services websites. They may use this information in ways your Employer may have agreed to or otherwise. We do not control these third parties' tracking technologies or how they may be used. If you have any questions about third party information collection you should contact your Employer directly.
                </p>
            </li>
            <li>
                <b class="list-header">How We Use Your Information</b>
                <p>We use information that we collect about you or that you provide to us in the App, including any personal information, to:</p>
                <ul>
                    <li>Provide you with the App, its content, and functionality; as well as any other related information, products, or services.</li>
                    <li>Coordinate communications between you and your Employer’s customers or between you and other users, as permitted by functionalities included in your Employer’s Omnipresence license.</li>
                    <li>Establish and maintain your Employer’s customer contacts and related customer engagement capabilities.</li>
                    <li>Give you notices about your user account, including notifications from the App.</li>
                </ul>
                <p>The usage and customer engagement information we collect helps us to improve our App and to deliver a better and more personalized experience to you and your Employer’s customers by enabling us to:</p>
                <ul>
                    <li>Recognize you when you use the App.</li>
                    <li>Estimate our audience size and usage patterns.</li>
                    <li>Store information about your preferences, allowing us to customize our App according to your individual interests.</li>
                    <li>Speed up your searches.</li>
                    <li>Connect you to recommended actions and content as determined by intelligent algorithms as permitted by functionalities included in your Employer’s Omnipresence license.</li>
                </ul>
                <p>We may use the information we collect to display content (including commercial messages) to specific target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with such content, the content provider (either us or a third party) will assume that you meet the target criteria.</p>
            </li>
            <li>
                <b class="list-header">Disclosure of Your Information</b>
                <p>We may disclose without restriction aggregated information about our users and App functionalities, which is information that does not identify you or any individual or device. Such information may be used in various analytics including reports, charts, or algorithms to generate insights or recommendations for you as well as other users without identifying you individually:</p>
                <p>In addition, we may disclose personal information that we collect or you provide according to any provisions in your Employer’s Omnipresence licenses</p>
                <ul>
                    <li>To our subsidiaries and affiliates.</li>
                    <li>To your Employer for various reporting and analytics including reports, charts, or algorithms to generate insights or recommendations for your Employer and other users to who your Employer provides the App.</li>
                    <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
                    <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Indegene's assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Indegene about our App users is among the assets transferred.</li>
                    <li>To fulfill the purpose for which you provide it. For example, if you give us an email address to use the 'contact' feature of our Website or App, we will transmit the contents of that email and your email address to the recipients.</li>
                    <li>For any other purpose disclosed by us when you provide the information.</li>
                    <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
                    <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Indegene, our customers or others. This includes exchanging information with other companies and organizations for the purposes of identity theft prevention, fraud protection, and risk reduction.</li>
                </ul>
            </li>
            <li>
                <b class="list-header">Your Choices About Our Collection, Use, and Disclosure of Your Information</b>
                <p>We strive to provide you with choices regarding the personal information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of over your information. You should contact your Employer directly before turning off any settings in your device.</p>
                <ul>
                    <li><b>Tracking Technologies.</b>You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies or block the use of other tracking technologies, some parts of the App may then be inaccessible or not function properly.</li>
                    <li><b>Location Information.</b> You can choose whether or not to allow the App to collect and use real-time information about your device's location through the device's privacy or location services settings. If you block the use of location information, some parts of the App may then be inaccessible or not function properly.</li>
                </ul>
                <p>We do not control third parties' collection or use of your information for their various purposes. However these third parties may provide you with ways to choose not to have your information collected or used in this way.</p>
            </li>
            <li>
                <b class="list-header">Accessing and Correcting Your Personal Information</b>
                <p>You can review and change your personal information, only as permitted by your Employer, by logging into the App and visiting your user profile page. You may also contact your Employer directly to request access to, correct, or delete any personal information about yourself or your customer contacts that you have entered into the App. We cannot delete such personal information or accommodate a request to change information without your Employer’s approval or if we believe the change would violate any law or legal requirement.</p>
            </li>
            <li>
                <b class="list-header">Data Security, Storage and Retention</b>
                <p>We have implemented commercially reasonable administrative, procedural, and technical measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure, according to such provisions in your Employer’s Omnipresence licenses.</p>
                <p>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our App, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information to other users and to follow your Employer’s hardware and software policies at all times.</p>
                <p>Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our App. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.</p>
                <p>Your data is stored in one or more of our global hubs, which are made up of data centers provided by leading cloud products providers including but not restricted to such as Microsoft, Google and Amazon, according to the best location for performance of the App in your geography, your Employer’s preferences or requirements, and any other data residency requirements. You should contact your Employer directly if you have questions about where your data is stored.</p>
                <p>Your data is retained in our global hubs according to the provisions in your Employer’s Omnipresence licenses.</p>
            </li>
            <li>
                <b class="list-header">Changes to Our Privacy Policy</b>
                <p>We may update our privacy policy from time to time. If we make material changes to how we treat our users' personal information, we will post the new privacy policy on this page with a notice that the privacy policy has been updated.</p>
                <p>The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address and phone number for you and for periodically visiting this privacy policy to check for any changes.</p>
            </li>
            <li>
                <b class="list-header">Contact Information</b>
                <p>To ask questions or comment about this privacy policy and our privacy practices, you should contact your Employer.</p>
            </li>
        </ol>
    </div>
  `;
}
