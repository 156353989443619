import { AgendaFooterService } from '@omni/services/footer/agenda-footer.service';
import { Component, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, Input } from '@angular/core';
import { DeviceService, VIEW_MODE_CHANGE } from "../../../services/device/device.service";
import { format, addMinutes, differenceInMinutes, distanceInWords, distanceInWordsToNow, differenceInDays, differenceInHours, differenceInSeconds, isThisQuarter, isSameDay, isToday } from "date-fns";
import { PopoverController, NavParams, } from '@ionic/angular';
import { Events } from '@omni/events';
import { PopoverComponent } from '../../popover/popover';
import { UIService } from '../../../services/ui/ui.service';
import { TimeOffService } from '../../../services/time-off/time-off.service';
import { TimeOffActivity } from '../../../classes/activity/timeoff.class';
import { TimeOffDataService } from '../../../data-services/time-off/time-off.data.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { TimeOffUtilityService } from '../../../services/time-off/time-off-utility.service';
import { NavigationService, PageName } from "../../../services/navigation/navigation.service";
import { RepServices } from '../../../data-services/rep/rep.services';
import { ActivityService } from '../../../services/activity/activity.service';
import { ActivityType, Activity } from '../../../classes/activity/activity.class';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { TimeOffDetailsComponent } from '../../time-off/time-off-details/time-off-details';
import { AlertController, LoadingController } from "@ionic/angular";
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { TranslateService } from '@ngx-translate/core';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { DurationType } from './../../../classes/activity/timeoff.class';
import moment from 'moment';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import _ from 'lodash';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';

/**
 * Generated class for the NewTimeOffComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'new-time-off',
  templateUrl: 'new-time-off.html',
  styleUrls:['new-time-off.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewTimeOffComponent implements OnInit, OnDestroy {
  private _displayConflictAlert: boolean = false;
  private _uuid: number = null;
  get displayConflictAlert(): boolean {
    return this._displayConflictAlert;
  }
  @Input() set displayConflictAlert(display: boolean) {
    this._displayConflictAlert = display;
  }
  get uuid(): number {
    return this._uuid;
  }
  @Input() set uuid(uuid: number) {
    this._uuid = uuid;
  }
  /* declaration for new activity object */
  newTimeOff: TimeOffActivity = null;

  //public activityType: any;
  //public reasonList;

  //public userPosition: string;

  //reason: string = "Sick";


  private previousSelectedActivity:Activity;

  /* stores the current page details for navigation purpose */
  private currentMasterPage: PageName;

  // date time picker
  public startDateField: IndDateTimeFormViewDataModel;
  public startTimeField: IndDateTimeFormViewDataModel;
  public endDateField: IndDateTimeFormViewDataModel;
  public endTimeField: IndDateTimeFormViewDataModel;
  public durationField: IndDateTimeFormViewDataModel;
  public timePeriodField: IndDateTimeFormViewDataModel;
  private startDateTimeValue: string;
  private startDateValue: string;
  private startTimeValue: string;
  private endDateTimeValue: string;
  private endDateValue: string;
  private endTimeValue: string;
  private duration: string;
  public selectedDurationType: string;
  private ngDestroy$ = new Subject<boolean>();
  public popupSelectedTimePeriodText: string;
  private _updatedCellDateTime: () => void;
  private _updatedDayDateTime: () => void;
  private _updateCurrentDate: () => void;
  private currentDate: Date = new Date();

  constructor(
    public navParams: NavParams,
    private uiService: UIService,
    public navService: NavigationService,
    private popoverCtrl: PopoverController,
    private timeOffDataService: TimeOffDataService,
    private authenticationService: AuthenticationService,
    public timeOffService: TimeOffService,
    //private repService: RepServices,
    private timeOffUtilityService: TimeOffUtilityService,
    private activityService: ActivityService,
    private changeDetectorRef: ChangeDetectorRef,
    private deviceService: DeviceService,
    private footerService: FooterService,
    private loadingCtrl: LoadingController,
    //private alertCtrl: AlertController,
    private events: Events,
    public translate: TranslateService,
    private notificationService: NotificationService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private datePipe: DatePipe,
    private readonly agendaFooterService: AgendaFooterService
  ) {
    /* reset the time-off to initial stage in case the user made any change to the template without saving */
    this.reset();

    /* Week view event handling for setting the date and time */
    this._updatedCellDateTime = () => {
      const data: { view: string, data: { startDate: Date, endDate: Date } } = this.uiService.getCellData;
      if(this.selectedDurationType == DurationType.Hourly) {
        this.newTimeOff.totStartTime = data.data.startDate;
        let setCombinedDateTimeForEndTime = format(addMinutes(this.newTimeOff.totStartTime, 60));
        this.newTimeOff.totEndTime = new Date(setCombinedDateTimeForEndTime);
        let dateTime = {startDateTime: this.newTimeOff.totStartTime, endDateTime: this.newTimeOff.totEndTime};
        this.dateTimeFormatsService.updateLimitedMinTime.next(dateTime);
      }else {
        if (this.selectedDurationType == DurationType.AllDay) {
          this.newTimeOff.totStartTime = new Date(data.data.startDate.setHours(0, 0, 0));
          this.newTimeOff.totEndTime = new Date(data.data.startDate.setHours(23, 59, 59));
        } else if (this.selectedDurationType == DurationType.HalfDay) {
          let meridian = moment(new Date).format('A');
          if (meridian === 'PM') {
            this.timeOffService.popupSelectedTimePeriodEvent = "PM";
            this.newTimeOff.totStartTime = new Date(data.data.startDate.setHours(12, 0, 0));
            this.newTimeOff.totEndTime = new Date(data.data.startDate.setHours(23, 59, 59));
          } else {
            this.timeOffService.popupSelectedTimePeriodEvent = "AM";
            this.newTimeOff.totStartTime = new Date(data.data.startDate.setHours(0, 0, 0));
            this.newTimeOff.totEndTime = new Date(data.data.startDate.setHours(11, 59, 59));
          }
        }
      }
      
      this.initDateTimeFormFields();
      this.changeDetectorRef.markForCheck();
    }
    this.events.subscribe('updatedCellClick', this._updatedCellDateTime);

    /* Week view event handling for setting the date and time */
    this._updatedDayDateTime = () => {
      if (this.agendaFooterService.actSegment == "week" && this.uiService.selectedCalendarDateInWeekView) {
        this.newTimeOff.totStartTime = this.uiService.selectedCalendarDateInWeekView;
        if (this.selectedDurationType == DurationType.Hourly) {
          let setCombinedDateTimeForEndTime = format(addMinutes(this.newTimeOff.totStartTime, 60));
          this.newTimeOff.totEndTime = new Date(setCombinedDateTimeForEndTime);
          this.events.publish("initTimePickerLimitedMinTime", {startDateTime: this.newTimeOff.totStartTime, endDateTime: this.newTimeOff.totEndTime});
        } else if (this.selectedDurationType == DurationType.AllDay) {
          this.newTimeOff.totStartTime = new Date(this.uiService.selectedCalendarDateInWeekView.setHours(0, 0, 0));
          this.newTimeOff.totEndTime = new Date(this.uiService.selectedCalendarDateInWeekView.setHours(23, 59, 59));
        } else if (this.selectedDurationType == DurationType.HalfDay) {
          let meridian = moment(new Date).format('A');
          if (meridian === 'PM') {
            this.timeOffService.popupSelectedTimePeriodEvent = "PM";
            this.newTimeOff.totStartTime = new Date(this.uiService.selectedCalendarDateInWeekView.setHours(12, 0, 0));
            this.newTimeOff.totEndTime = new Date(this.uiService.selectedCalendarDateInWeekView.setHours(23, 59, 59));
          } else {
            this.timeOffService.popupSelectedTimePeriodEvent = "AM";
            this.newTimeOff.totStartTime = new Date(this.uiService.selectedCalendarDateInWeekView.setHours(0, 0, 0));
            this.newTimeOff.totEndTime = new Date(this.uiService.selectedCalendarDateInWeekView.setHours(11, 59, 59));
          }
        }
      }
      this.initDateTimeFormFields();
      this.changeDetectorRef.markForCheck();
    }
    this._updateCurrentDate = () => {
      console.log('New Time Off: update current date');
      // this.uiService.selectedCalendarDateInWeekView = this.currentDate;
      this.newTimeOff.totStartTime = this.currentDate;
      if (this.selectedDurationType == DurationType.Hourly) {
        this.newTimeOff.totStartTime = new Date(this.currentDate.setHours(8,0,0)); // default start time value
        let setCombinedDateTimeForEndTime = format(addMinutes(this.newTimeOff.totStartTime, 60));
        this.newTimeOff.totEndTime = new Date(setCombinedDateTimeForEndTime);
        this.events.publish("initTimePickerLimitedMinTime", {startDateTime: this.newTimeOff.totStartTime, endDateTime: this.newTimeOff.totEndTime});
      } else if (this.selectedDurationType == DurationType.AllDay) {
        this.newTimeOff.totStartTime = new Date(this.currentDate.setHours(0, 0, 0));
        this.newTimeOff.totEndTime = new Date(this.currentDate.setHours(23, 59, 59));
      } else if (this.selectedDurationType == DurationType.HalfDay) {
        let meridian = moment(new Date).format('A');
        if (meridian === 'PM') {
          this.timeOffService.popupSelectedTimePeriodEvent = "PM";
          this.newTimeOff.totStartTime = new Date(this.currentDate.setHours(12, 0, 0));
          this.newTimeOff.totEndTime = new Date(this.currentDate.setHours(23, 59, 59));
        } else {
          this.timeOffService.popupSelectedTimePeriodEvent = "AM";
          this.newTimeOff.totStartTime = new Date(this.currentDate.setHours(0, 0, 0));
          this.newTimeOff.totEndTime = new Date(this.currentDate.setHours(11, 59, 59));
        }
      }
      this.initDateTimeFormFields();
      this.changeDetectorRef.markForCheck();
    }
    this.events.subscribe('updatedDayClick', this._updatedDayDateTime);
    this.events.subscribe('updateCurrentDate', this._updateCurrentDate);
  }


  ngOnInit() {
    this.selectedDurationType = this.timeOffUtilityService.checkCurrentDurationType(this.timeOffUtilityService.currentDurationType);
    /* Set date as per the week view */
    if (this.agendaFooterService.actSegment == "week" && this.uiService.selectedCalendarDateInWeekView) {
      let startOfDate = new Date(this.uiService.selectedCalendarDateInWeekView.setHours(0,0,0)); // If a time off has to be cread then It should aquire 12: AM slot from Calendar and day view, It's happening correctly in Agenda, so as to be part of OMNI-9180
      this.newTimeOff.totStartTime = startOfDate
      this.newTimeOff.totStartTime = this.uiService.selectedCalendarDateInWeekView;
      this.newTimeOff.totEndTime = this.uiService.selectedCalendarDateInWeekView;
      this.initDateTimeFormFields();
      this.changeDetectorRef.markForCheck();
    }
    /* Time off activity from the agenda view */
    else if (this.uiService.activitiesPageTab === 'day') {
      /* Default date time setting */
        const data: { view: string, data: { startDate: Date, endDate: Date } } = this.uiService.getCellData;
        if (data) {
          this.newTimeOff.totStartTime = data.data.startDate;
          this.newTimeOff.totEndTime = data.data.endDate;
          this.initDateTimeFormFields();
          this.changeDetectorRef.markForCheck();
        }
    } else {
      this.activityService.signalConflictCheck$.next({ componentUuid: this.uuid, signal: true, start: new Date(this.startDateTimeValue), end: new Date(this.newTimeOff.scheduledEnd) });
    }
     // Update start/end time field with selected time from the time picker
     this.dateTimeFormatsService.updateSelectedNewTimeTimeOffRequest$.pipe(takeUntil(this.ngDestroy$)).subscribe((timeData) => {
       if (timeData) {
         if (timeData.id == DateTimeFieldType.StartTimeField) {
            this.newTimeOff.totStartTime = new Date(timeData.time);
            if (this.selectedDurationType == DurationType.Hourly) {
              let setCombinedDateTimeForEndTime = format(addMinutes(timeData.time, 60));
              this.newTimeOff.totEndTime = new Date(setCombinedDateTimeForEndTime);
            }
            this.initDateTimeFormFields();
            this.changeDetectorRef.markForCheck();
         } else if (timeData.id == DateTimeFieldType.EndTimeField) {
           this.newTimeOff.totEndTime = new Date(timeData.time);
           this.initDateTimeFormFields();
           this.changeDetectorRef.markForCheck();
         }
       }
     });
  }

  ngOnDestroy() {
    // To preserve the value of selected activity on agenda in case time off is not confirmed
    if(this.previousSelectedActivity){
      this.activityService.selectedActivity = this.previousSelectedActivity;
    }else{
      this.activityService.selectedActivity = null;
    }
    this.events.unsubscribe('updatedCellClick', this._updatedCellDateTime);
    this.events.unsubscribe('updatedDayClick', this._updatedDayDateTime);
    this.events.unsubscribe('updateCurrentDate', this._updateCurrentDate);
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  /* Closing the new timeoff activity page via emitter */
  closepage() {
    this.navService.popWithPageTracking();
  }

  /* Sets the value for the new timeoff to default values and keys for UI*/
  public reset() {
    this.newTimeOff = new TimeOffActivity({
      indskr_timeoffrequestid: "",
      statecode: 0,
      statuscode: 100000000,
      "statuscode@OData.Community.Display.V1.FormattedValue": "Open",
      "indskr_starttime@OData.Community.Display.V1.FormattedValue": this.timeOffDataService.getTimeStamp("start"),
      indskr_starttime: this.timeOffDataService.getTimeStamp("start"),
      _indskr_positionid_value: this.authenticationService.user.xPositionID,
      // "_indskr_positionid_value@OData.Community.Display.V1.FormattedValue": this.authenticationService.user.positionName,
      indskr_isalldayevent: true,
      "indskr_endtime@OData.Community.Display.V1.FormattedValue": this.timeOffDataService.getTimeStamp("end"),
      indskr_endtime: this.timeOffDataService.getTimeStamp("end"),
      indskr_reason: undefined,  //(this.timeOffService.defaultTimeOffReason) ? this.timeOffService.defaultTimeOffReason.reasonCode : 100000001,
      "indskr_reason@OData.Community.Display.V1.FormattedValue": '', //(this.timeOffService.defaultTimeOffReason) ? this.timeOffService.defaultTimeOffReason.reason : "Sick",
      //indskr_name: this.authenticationService.hasFeatureAction(FeatureActionsMap.TIME_OFF_AUTOSUBJECT) ? ("Time Off"+((this.timeOffService.defaultTimeOffReason) ? " - "+this.timeOffService.defaultTimeOffReason.reason : '')) : "Time Off",
      indskr_name: "Time Off",
      _ownerid_value: this.authenticationService.user.systemUserID,
      "_ownerid_value@OData.Community.Display.V1.FormattedValue": "",
    }, ActivityType.TimeOffRequest);
    //For Datepicker and other event stuffs
    // To preserve the value of selected activity on agenda in case time off is not confirmed
    if(this.activityService.selectedActivity){
      this.previousSelectedActivity = this.activityService.selectedActivity;
    }
    this.activityService.selectedActivity = this.newTimeOff;
    this.timeOffService.popupSelectedEvent = this.timeOffUtilityService.decodeEventType(this.newTimeOff);
    this.currentMasterPage = this.navService.getCurrentMasterPageName();
    this.initDateTimeFormFields();
  }

  /* Updating the event type for the time off the date and time needs to be updated accordingly */
  async openEventPopover (event) {
    let popover = await this.popoverCtrl.create({
      component:PopoverComponent,
      componentProps:{ field: 'timeOffEvent' },
      cssClass: "timeOffEvent-popover",
      event:event});
    popover.present();
    popover.onDidDismiss().then((obj: any) => {
      const data:string = obj && obj.data;
      if (data != null) {
        this.timeOffService.popupSelectedEvent = this.translate.instant(data.replace(" ", "_").toUpperCase());
        let dateUpdate: Object = this.timeOffUtilityService.getDateTimeForTimeOffEvent(data, this.newTimeOff);
        this.newTimeOff.totStartTime = this.activityService.selectedActivity['totStartTime'] = dateUpdate["totStartTime"];
        this.newTimeOff.totEndTime = this.activityService.selectedActivity['totEndTime'] = dateUpdate["totEndTime"];
        this.newTimeOff.totIsAlldayEvent = this.timeOffUtilityService.recodeEventType(data);
        this.newTimeOff.durationType = this.timeOffUtilityService.decodeEventType(this.activityService.selectedActivity as TimeOffActivity);
        this.selectedDurationType = this.timeOffUtilityService.checkCurrentDurationType(this.timeOffUtilityService.currentDurationType);
        this.timeOffService.popupSelectedTimePeriodEvent = this.timeOffUtilityService.getTimePeriodForTimeOffEvent(this.newTimeOff);
        this.initDateTimeFormFields();
        this.changeDetectorRef.markForCheck();
      }
    });
  }

  /* method to save the time off data to dynamics or offline mode as per the network availability */
  public async saveTimeOff() {

    this.previousSelectedActivity = this.activityService.selectedActivity;

    this.newTimeOff.scheduledStart = new Date(this.newTimeOff.totStartTime);
    this.newTimeOff.scheduledEnd = new Date(this.newTimeOff.totEndTime);
    this.newTimeOff.scheduledStartLocale = this.newTimeOff.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    this.newTimeOff.offlineTimeOffRequestId = "offline_tot_" + new Date().getTime();
    const loader = await this.loadingCtrl.create();
    this.timeOffService.display = true;

    loader.present();

    //If device is offline
    if (this.deviceService.isOffline) {
      await this.timeOffService.saveMyTimeOff(this.newTimeOff, false).then(() => {
        loader.dismiss();
        this.postTimeOffSave(this.newTimeOff, true);
        if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
          this.navService.setChildNavRoot(TimeOffDetailsComponent, PageName.TimeOffDetailsComponent, PageName.ActivitiesPageComponent, { isNewlyCreatedActivity: true });
          this.footerService.initButtons(FooterViews.TimeOffDetails);
        }
      });
    }
    //If device is online
    else if (!this.deviceService.isOffline) {
      this.timeOffDataService.createNewTimeOff(this.newTimeOff).subscribe(
        async (success: any) => {
          await this.timeOffService.saveMyTimeOff(this.newTimeOff, true, success).then(() => {
            loader.dismiss();
            this.postTimeOffSave(this.newTimeOff, false);
            if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
              this.navService.setChildNavRoot(TimeOffDetailsComponent, PageName.TimeOffDetailsComponent, PageName.ActivitiesPageComponent, { isNewlyCreatedActivity: true });
              this.footerService.initButtons(FooterViews.TimeOffDetails);
            }
          });
        },
        async error => {
          //error save offline
          await this.timeOffService.saveMyTimeOff(this.newTimeOff, false).then(() => {
            loader.dismiss();
            this.postTimeOffSave(this.newTimeOff, true);
            if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
              this.navService.setChildNavRoot(TimeOffDetailsComponent, PageName.TimeOffDetailsComponent, PageName.ActivitiesPageComponent, { isNewlyCreatedActivity: true });
              this.footerService.initButtons(FooterViews.TimeOffDetails);
            }
          })
        });
    }

  }


  /* Oncce the time off is saved by the above logic then we have to update the calendar view and update the data accordingly */
  private postTimeOffSave(tot: TimeOffActivity, isOffline: boolean) {

    let data;

    data = {
      id: tot.timeOffRequestId,
      subject: tot.subject,
      location: tot.location,
      scheduledStart: new Date(tot.totStartTime).valueOf(),
      scheduledEnd: new Date(tot.totEndTime).valueOf(),
      type: ActivityType.TimeOffRequest,
      reason: tot.reason,
      allDay: tot.totIsAlldayEvent
    };

    if (data) {
      try {
        this.calendarAddEvent(data, isOffline);
      }
      catch (error) {
        try {
          setTimeout(() => {
            this.calendarAddEvent(data, isOffline);
          }, 3000);
        } catch (error) {
          console.log("calendar instance fails after 3 seconds as well.");
        }
      }
    }
  }

  calendarAddEvent(data: any, isOffline?: boolean) {
    this.uiService.setUIServiceData({
      view: "new-activity", data,
    });

    this.timeOffService.setSelectedTot(this.newTimeOff);
    this.activityService.selectedActivity = this.newTimeOff;
    this.footerService.initButtons(FooterViews.TimeOffDetails);
    this.activityService.publishActivityEvent({action: "Create", activity: this.newTimeOff});

    //Mobile devices
    if (this.deviceService.isMobileDevice) {
      if (this.currentMasterPage === PageName.ActivitiesPageComponent) {
        // this.navService.pushWithPageTracking(TimeOffDetailsComponent, PageName.TimeOffDetailsComponent, { activityType: event });
        this.uiService.showRightPane = true;
        this.afterTimeOffCreationDone(true);
      }
    }
    //WFE
    else if (!this.deviceService.isMobileDevice) {
      this.afterTimeOffCreationDone(false);
    }
  }

  private afterTimeOffCreationDone(isMobile: boolean) {
    this.uiService.activeView = 'TimeOffDetail';
    if (!isMobile) {
      this.uiService.showNewActivity = false;
    }
    //so the user sees the list top post action completion
    this.uiService.activeViewNewMeeting = 'meeting';
    this.uiService.showNewActivity = false;
  }

  public initDateTimeFormFields() {
    let popupSelectedTimePeriod: string = this.timeOffService.getTimePeriodText(this.timeOffService.popupSelectedTimePeriodEvent);
    let dateTimeValue = (this.newTimeOff) ? this.timeOffService.generateTimeDurationText(this.newTimeOff) : '';
    if (dateTimeValue) {
      this.startDateTimeValue = dateTimeValue.startDateTime;
      this.startDateValue = dateTimeValue.startDate;
      this.startTimeValue = dateTimeValue.startTime;
      this.endDateTimeValue = dateTimeValue.endDateTime;
      this.endDateValue = dateTimeValue.endDate;
      this.endTimeValue = dateTimeValue.endTime;
      this.duration = dateTimeValue.duration;
    }
    this.startDateField = {
      isDateField: true,
      isNewActivityView: true,
      label: this.translate.instant('START_DATE'),
      inputText: this.startDateValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.NewTimeOff,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: false,
      showArrow: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.startTimeField = {
      isTimeField: true,
      isNewActivityView: true,
      label: this.translate.instant('START_TIME'),
      inputText: this.startTimeValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.NewTimeOff,
      id: DateTimeFieldType.StartTimeField,
      isReadOnly: true,
      isDisabled: false,
      showArrow: true,
    };
    this.endDateField = {
      isDateField: true,
      isNewActivityView: true,
      label: this.translate.instant('END_DATE'),
      inputText: this.endDateValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.NewTimeOff,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: false,
      showArrow: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.endTimeField = {
      isTimeField: true,
      isNewActivityView: true,
      label: this.translate.instant('END_TIME'),
      inputText: this.endTimeValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.NewTimeOff,
      id: DateTimeFieldType.EndTimeField,
      isReadOnly: true,
      isDisabled: false,
      showArrow: true,
    };
    this.durationField = {
      isDurationField: true,
      isNewActivityView: true,
      label: this.translate.instant('DURATION_TIMEOFF'),
      inputText: this.duration,
      id: DateTimeFieldType.TimeDurationField,
      isReadOnly: true,
      isDisabled: false,
      showArrow: false,
    };
    this.timePeriodField = {
      isTimePeriodField: true,
      isNewActivityView: true,
      label: this.translate.instant('TIME') + ' ' + this.translate.instant('PERIOD'),
      inputText: popupSelectedTimePeriod,
      id: DateTimeFieldType.TimePeriodField,
      isReadOnly: true,
      isDisabled: false,
      showArrow: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }

    const startTimestamp: number = new Date(this.startDateTimeValue)?.getTime();
    const endTimeStamp: number = new Date(this.endDateTimeValue)?.getTime();
    this.activityService.signalConflictCheck$.next({ componentUuid: this.uuid, signal: true, start: new Date(this.startDateTimeValue), end: new Date((!isNaN(startTimestamp) && !isNaN(endTimeStamp) && startTimestamp === endTimeStamp) ? this.newTimeOff.scheduledEnd : this.endDateTimeValue) });
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      switch (id) {
        case 'start-date-field':
          this.openStartDatePicker(event);
          break;
        case 'end-date-field':
          this.openEndDatePicker(event);
          break;
        case 'time-period-field':
          this.openTimePeriodPopover(event);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  async openStartDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    if (this.newTimeOff != null && !this.activityService.teamViewActive) {
      if (this.newTimeOff.statusValue === 'Open' && this.authenticationService.user.systemUserID === this.newTimeOff.totOwnerId) {
        let popover = await this.popoverCtrl.create(
          {
            component: IndDatetimeFormComponent,
            componentProps: {
              currentViewPage: CurViewPageType.NewTimeOff,
              startDateTimeValue: this.newTimeOff.totStartTime,
              endDateTimeValue: this.newTimeOff.totEndTime
            },
            cssClass: "datetime-popover"
          }
        );
        popover.onDidDismiss().then((data: any) => {
          if (data !== null && !_.isEmpty(data.data) && data.data.startTime !='') {
            let diff: number = Math.ceil(moment(new Date(data.data.endTime)).diff(moment(new Date(data.data.startTime)), 'seconds'));
            if(diff == 0){
              this.notificationService.notify(this.translate.instant('EQUAL_DATE'), 'TimeOffDetails', 'top', ToastStyle.DANGER);
              return;
            }
            if (this.newTimeOff.totStartTime != data.data.startTime) {
              this.newTimeOff.totStartTime = data.data.startTime;
              this.newTimeOff.totEndTime = data.data.endTime;
            }
            this.timeOffService.generateTimeDurationText(this.newTimeOff);
            this.activityService.selectedActivity['totStartTime'] = new Date(data.data.startTime);
            this.activityService.selectedActivity['totEndTime'] = new Date(data.data.endTime);
            // update startTime/endTime in new activity
            this.events.publish("updateNewTimeOffDateTime", this.newTimeOff);
            this.initDateTimeFormFields();
            this.changeDetectorRef.markForCheck();
          }
        });
        popover.present();
      }
    }
  }

  async openEndDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    if (this.newTimeOff != null && !this.activityService.teamViewActive) {
      if (this.newTimeOff.statusValue === 'Open' && this.authenticationService.user.systemUserID === this.newTimeOff.totOwnerId) {
        let popover = await this.popoverCtrl.create(
          {
            component: IndDatetimeFormComponent,
            componentProps: {
              currentViewPage: CurViewPageType.NewTimeOff,
              startDateTimeValue: this.newTimeOff.totStartTime,
              endDateTimeValue: this.newTimeOff.totEndTime
            },
            cssClass: "datetime-popover"
          }
        );
        popover.onDidDismiss().then((data: any) => {
          if (data !== null && !_.isEmpty(data.data) && data.data.startTime !='') {
            let diff: number = Math.ceil(moment(new Date(data.data.endTime)).diff(moment(new Date(data.data.startTime)), 'seconds'));
            if(diff == 0){
              this.notificationService.notify(this.translate.instant('EQUAL_DATE'), 'TimeOffDetails', 'top', ToastStyle.DANGER);
              return;
            }
            if (this.newTimeOff.totEndTime != data.data.endTime) {
              this.newTimeOff.totStartTime = data.data.startTime;
              this.newTimeOff.totEndTime = data.data.endTime;
            }
            this.timeOffService.generateTimeDurationText(this.newTimeOff);
            this.activityService.selectedActivity['totStartTime'] = new Date(data.data.startTime);
            this.activityService.selectedActivity['totEndTime'] = new Date(data.data.endTime);
            // update startTime/endTime in new activity
            this.events.publish("updateNewTimeOffDateTime", this.newTimeOff);
            this.initDateTimeFormFields();
            this.changeDetectorRef.markForCheck();
          }
        });
        popover.present();
      }
    }
  }
  // Half Day - AM/PM popover
  async openTimePeriodPopover (event) {
    if (this.newTimeOff != null && !this.activityService.teamViewActive) {
      if (this.newTimeOff.statusValue === 'Open' && this.authenticationService.user.systemUserID === this.newTimeOff.totOwnerId) {
        let popover = await this.popoverCtrl.create({
          component: PopoverComponent,
          componentProps:{ field: 'timePeriod' },
          cssClass: "timeOffEvent-popover",
          event:event});
        popover.present();
        popover.onDidDismiss().then((obj: any) => {
          const data = obj && obj.data;
          if (data != null) {
            this.changeDetectorRef.detectChanges();
            this.timeOffService.popupSelectedTimePeriodEvent = data;
            this.popupSelectedTimePeriodText = data;
            let dateUpdate: Object = this.timeOffUtilityService.getDateTimeForTimeOffEvent("Half Day", this.newTimeOff);
            if (this.popupSelectedTimePeriodText == "AM") {
              let halfStartTIme: Date = moment(dateUpdate['totStartTime']).set('hour', 0).set('minute', 0).set('second', 0).toDate();
              let halfEndTIme: Date = moment(dateUpdate['totEndTime']).set('hour', 11).set('minute', 59).set('second', 59).toDate();
              dateUpdate["totStartTime"] = halfStartTIme.valueOf();
              dateUpdate["totEndTime"] = halfEndTIme.valueOf();
            } else {
              let halfStartTIme: Date = moment(dateUpdate['totStartTime']).set('hour', 12).set('minute', 0).set('second', 0).toDate();
              let halfEndTIme: Date = moment(dateUpdate['totEndTime']).set('hour', 23).set('minute', 59).set('second', 59).toDate();
              dateUpdate["totStartTime"] = halfStartTIme.valueOf();
              dateUpdate["totEndTime"] = halfEndTIme.valueOf();
            }
            this.newTimeOff.totStartTime = dateUpdate["totStartTime"];
            this.newTimeOff.totEndTime = dateUpdate["totEndTime"];
            this.activityService.selectedActivity['totStartTime'] = new Date(this.newTimeOff.totStartTime);
            this.activityService.selectedActivity['totEndTime'] = new Date(this.newTimeOff.totEndTime);
            this.initDateTimeFormFields();
            this.changeDetectorRef.detectChanges();
            this.changeDetectorRef.markForCheck();
          }
        });
      }
    }
  }
}
