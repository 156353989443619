<ion-header>
  <ion-toolbar class="left-header-toolbar" [ngClass]="{'toolbar-android': isAndroid}">
    <ion-buttons class="start-slot" slot="start" >
      <ion-menu-toggle *ngIf="accountVisit == null">
        <ion-icon class="darkMenuToggle bar-button-menutoggle-ios" src="assets/imgs/menu-toggle.svg"></ion-icon>
        <span *ngIf="deltaService.deltaRecordsDTO.contacts.length ||  deltaService.deltaRecordsDTO.accounts.length ||
              deltaService.deltaRecordsDTO.presentations.length || deltaService.deltaRecordsDTO.resources.length" class="notify-bubble">
        </span>
      </ion-menu-toggle>
      <div class="ion-menu-toggle" *ngIf="accountVisit != null" (click)="menuClicked($event)">
        <ion-icon class="darkMenuToggle bar-button-menutoggle-ios" src="assets/imgs/menu-toggle.svg"></ion-icon>
        <span *ngIf="deltaService.deltaRecordsDTO.contacts.length ||  deltaService.deltaRecordsDTO.accounts.length ||
              deltaService.deltaRecordsDTO.presentations.length || deltaService.deltaRecordsDTO.resources.length" class="notify-bubble">
        </span>
      </div>
    </ion-buttons>

    <div class="searchbar-wrapper">
        <ion-searchbar [disabled]="accountVisit != null" [(ngModel)]="searchText" (ionChange)="onSearchInput($event)" [debounce]="300" placeholder="{{'SELECT_CONTACT' | translate}}" (ionBlur)="onSearchBlur($event)" ></ion-searchbar>
    </div>
  </ion-toolbar>

  <ng-container *ngIf="backgroundUploadInProgress || syncInProgress">
    <ion-progress-bar mode="ios" class="left-progress-bar" type="indeterminate"></ion-progress-bar>
    <div class="in-progress">
      <ion-spinner></ion-spinner>
      <span class="secondary-text">{{'UPLOAD_IN_PROGRESS' | translate}}</span>
    </div>
  </ng-container>
</ion-header>

<ion-content>
  <ng-container *ngIf="isAccountInitDone; else accountWidgetskeleton">
    <div id="account-container">
      <ion-item class="no-border">
        <ng-container *ngIf="selectedAccount && !accountVisit">
          <ion-icon slot="start" name="accountwidgeticon" class="accountwidgeticon"></ion-icon>
          <ion-label>
            <ion-text class="primary-text account"
              (click)="openAccountSelection()">{{selectedAccount.accountName}}</ion-text>
            <ion-text class="secondary-text"><span *ngIf="selectedAccount.accountLevel && selectedAccount.raw && selectedAccount.raw['indskr_accountlevel@OData.Community.Display.V1.FormattedValue']"
                class="accountLevel"> {{selectedAccount.raw['indskr_accountlevel@OData.Community.Display.V1.FormattedValue']}}</span> <span class="targetCount">{{targetContactsCount}} {{'TARGET_CONTACTS' |
              translate}}</span></ion-text>
          </ion-label>
          <ion-icon name="chevron-down-outline" class="arrow-icon" (click)="openAccountSelection()"></ion-icon>
        </ng-container>
        <ng-container *ngIf="accountVisit">
          <ion-icon slot="start" class="back-btn" name="chevron-back-outline" (click)="scrapAccountVisit()"></ion-icon>
          <ion-label>
            <ion-text class="primary-text account">
              <span class="activityType">{{'ACCOUNT_VISIT' | translate}} | </span>
              <span>{{accountVisit.accounts[0].accountName}}</span>
            </ion-text>
          </ion-label>
        </ng-container>
        <!-- Case for no accounts data -->
        <ng-container *ngIf="!selectedAccount && !accountVisit">
          <ion-icon slot="start" name="accountwidgeticon" class="accountwidgeticon"></ion-icon>
          <ion-label>
            <ion-text class="primary-text account">{{'NO_ACCOUNTS' | translate}}</ion-text>
            <ion-text class="secondary-text"><span class="targetCount">{{0}} {{'TARGET_CONTACTS' | translate}}</span></ion-text>
          </ion-label>
        </ng-container>
        <ion-button slot="end" fill="clear" *ngFor="let option of shortCallActivityOptions"
            (click)="selectOption(option)"
            [disabled]="!selectedAccount" [ngClass]="{selected: selectedActivityOption == option}"><span>{{option |
              translate}}</span></ion-button>
      </ion-item>
    </div>
  </ng-container>

  <div *ngIf="((selectedActivityOption === 'MEETING' || selectedActivityOption === 'REMOTE_HOME') && selectedAccount) || accountVisit" id="contact-container">
    <ng-container *ngIf="accountVisit">
      <div class="fields-contatiner">
        <label *ngIf="accountVisit.contacts?.length > 0" class="secondary-text">{{accountVisit.contacts.length}}
          {{'CONTACTS_SELECTED' | translate}}</label>
        <ion-button class="add-contact-btn"
          slot="end" icon-only
          fill="clear" color="primary" (click)="openContactSelection()">
          <ion-icon class="secondary-text" color="primary" name="add-outline"></ion-icon>
          <span class="tertiary-text">{{ 'ADD_CONTACTS' | translate}}</span>
        </ion-button>
      </div>
    </ng-container>
    <div class="contact-chips" *ngIf="((selectedActivityOption === 'MEETING' || selectedActivityOption === 'REMOTE_HOME') && selectedAccount) || accountVisit?.contacts?.length > 0">
      <div [id]="contact.ID"
        *ngFor="let contact of contactListToBeDisplayedObservable | async"
        class="contact-chip"
        [ngClass]="{'chip-selected': contact.ID === selectedContact?.ID}"
        (click)="contactChipClick(contact)"
      >
        <ion-icon *ngIf="contact.isTargetContact"
          [name]="contact.ID === selectedContact?.ID ? 'target-contact-icon-selected' : 'target-contact-icon'"
          [class]="contact.ID === selectedContact?.ID ? 'target-contact-icon-selected' : 'target-contact-icon'"
        ></ion-icon>
        <span>{{ contact.fullname | uppercase }}</span>
      </div>
    </div>
    <div *ngIf="selectedContact" class="contact-detail-wrapper">
      <div class="contact-detail-card">
        <div class="contact-detail-card-row">
          <div class="contact-detail-card-upper-line-text contact-attriute-row">
            <div class="contact-attribute contact-name">
              {{selectedContact.fullname}}
            </div>
            <div *ngIf="selectedContact.indskr_externalid" class="contact-attribute contact-etms">
              {{selectedContact.indskr_externalid}}
            </div>
            <div *ngIf="selectedContact.raw.department" class="contact-attribute contact-department">
              {{selectedContact.raw.department}}
            </div>
          </div>
          <div class="contact-detail-card-upper-line-btn">
            <ion-button
              fill="outline"
              class="contact-detail-btn"
              (click)="openContactDetail(selectedContact)"
            >{{'DETAILS' | translate}}</ion-button>
          </div>
        </div>
        <div class="contact-detail-card-row">
          <div class="contact-attriute-row">
            <div *ngIf="shouldDisplayCustomerSegment && selectedContact.productSegmentations && selectedContact.productSegmentations[0] && selectedContact.productSegmentations[0].segmentation" class="contact-attribute contact-segment">
              {{selectedContact.productSegmentations[0].segmentation}}
            </div>

            <div *ngIf="selectedContact.raw.indskr_speaker" class="contact-attribute contact-speaker">
              <div>{{'SPEAKER' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="contact-detail-card-row">
          <div class="contact-attriute-row">
            <div *ngIf="(mostRecentActivityLoadingCounter.counterObservable | async) <= 0 && mostRecentActivityTitle"
              class="contact-attribute contact-activity">
              <div class="text">
                {{mostRecentActivityTitle}}
              </div>
            </div>
            <div *ngIf="(mostRecentActivityLoadingCounter.counterObservable | async) > 0" class="contact-attribute contact-activity">
              <div class="text">
                <ion-skeleton-text class="skeleton-text-animated" style="width: 200px;"></ion-skeleton-text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

  <div class="section-container">
    <div class="section-header">
      <div class="section-title">{{'CONTENT' | translate}}</div>
      <div class="card-filter">
        <ind-tabs [data]="productTabData" [type]="'md'" [(selectedTab)]="selectedProductTab"
          (selectedTabChange)="selectedTabChange($event)"></ind-tabs>
        <div class="searchbar-overlay" *ngIf="!syncInProgress">
          <ion-button tooltip="Filter" class="searchbar-overlay-button" fill="clear" (click)="openContentFilter()">
            <ion-icon class="searchbar-overlay-button-filter-icon" slot="icon-only"
              src="assets/imgs/filter-blue-horz.svg"></ion-icon>
            <span class="active-filter" *ngIf="selectedTags.length > 0"></span>
          </ion-button>
        </div>
      </div>
    </div>
    <div class="section-body">
      <io-content-cards [contentData]="eCardData" (selectedContent)="onContentSelect($event, true)"></io-content-cards>
      <io-content-cards [contentData]="edaData" (selectedContent)="onContentSelect($event, false)"></io-content-cards>
    </div>
  </div>
  <div class="section-container">
    <div class="section-header">
      <div class="section-title">
        <span>{{'SCH_TODO' | translate}} {{ toDoRefresh ||  syncInProgress || todos.length == 0 ? '' : ' (' + todos.length +')'}}</span>
        <img *ngIf="!toDoRefresh && !syncInProgress" class="sync-img" (click)="syncTodo()" src="assets/imgs/greySyncIcon.svg"/>
      </div>
    </div>
    <div class="section-body todo">
      <div [ngClass]="{'todo-item-skl': toDoRefresh || syncInProgress}" class="todo-items"  *ngIf="toDoRefresh || syncInProgress; else toDoList">
        <ion-label *ngFor="let _ of [1, 2, 3]" class="todo-item">
          <div class="todo-skl-container">
            <ion-skeleton-text class="skeleton-text-animated todo-skl"></ion-skeleton-text>
          </div>
        </ion-label>
      </div>
    </div>
  </div>

</ion-content>


<ng-template #toDoList>
  <ion-label class="todo-item no-content" *ngIf="todos.length == 0">{{'NO_CONTENT' | translate}}</ion-label>
  <div class="todo-item" *ngFor="let todo of todos" (click)="openTodoDetails(todo)">
    <div class="todo-row" *ngFor="let row of todo.rows">
      <ion-icon *ngIf="row.icon" class="activity-icon" slot="start" [src]="row.icon">
      </ion-icon>
      <p>
        <span [ngClass]="row.cssClass">{{row.text}}</span>
        <span *ngIf="row.subText" class="secondary-text sub-text">{{row.subText}}</span>
      </p>
      <!-- <p *ngIf="row.subText" class="secondary-text sub-text">{{row.subText}}</p> -->
      <span class="new-todo" *ngIf="row.new_todo"></span>
      <ion-icon *ngIf="row.scrapIcon" class="cncl-icon" (click)="scrapTodo(todo, $event)" slot="end"
        src="assets/imgs/short_call_cancel.svg"></ion-icon>
    </div>
    <div *ngIf="todo.type != 'followuptask'" class="todo-row last-row">
      <ion-button class="right-item reschedule-btn button-small"
        (click)="updateTodo(todo, $event)" fill="clear" slot="end">
        <span>{{'MODIFY_DATE' | translate}}</span>
      </ion-button>
    </div>
  </div>
</ng-template>

<ng-template #accountWidgetskeleton>
  <ion-item class="wrapper-item no-border">
    <ion-thumbnail slot="start" class="main-icon">
      <ion-skeleton-text class="skeleton-text-animated"></ion-skeleton-text>
    </ion-thumbnail>

    <ion-label class="text-wrapper">
      <ion-text class="primary-text">
        <ion-skeleton-text class="skeleton-text-animated">
        </ion-skeleton-text>
      </ion-text>
      <ion-text class="secondary-text">
        <ion-skeleton-text class="skeleton-text-animated target-contact-skl" >
        </ion-skeleton-text>
      </ion-text>
    </ion-label>
  </ion-item>

  <div class="separationLine"></div>
</ng-template>

  <div class="searchSuggestionWrapper" *ngIf="suggestionsData && suggestionsData.length">
    <div class="filteredSearchSuggestion">
      <ion-item *ngFor="let value of suggestionsData" (click)="handleFacetSelection(value)">
        <ion-label class="contactName" [innerHTML]="value.text"></ion-label>
      </ion-item>
    </div>
  </div>