import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { AuthenticationService } from './../../../services/authentication.service';
import { DeltaService } from '@omni/data-services/delta/delta.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { AboutDetailsComponent } from './about-details/about-details';
import { APP_VERSION } from '../../../../config/endpoints.config';
import { UIService } from '../../../services/ui/ui.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { IndSectionHeaderComponent } from '@omni/components/shared/ind-section-header/ind-section-header';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { Subject } from 'rxjs';
import { DeviceService } from '@omni/services/device/device.service';
import { AlertService } from '@omni/services/alert/alert.service';
import { EventsService } from '@omni/services/events/events.service';
import { takeUntil } from 'rxjs/operators';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { ActivityDataService } from '../../../data-services/activity/activity.service';


@Component({
  selector: 'about-page',
  templateUrl: 'about-page.html',
  styleUrls: ['about-page.scss']
})
export class AboutPageComponent implements OnInit {

  public aboutPageTitle: IndPageTitleViewDataModel;
  readonly appVersion = APP_VERSION;
  versionHeader: IndSectionHeaderViewDataModel;
  importantDeatilHeader: IndSectionHeaderViewDataModel;
  refreshDataHeader: IndSectionHeaderViewDataModel;
  forceUploadEnabled: boolean = false;
  private ngDestroy$: any = new Subject<boolean>();
  constructor(
    private translate: TranslateService,
    public navService: NavigationService,
    public uiService: UIService,
    public footerService: FooterService,
    public device: DeviceService,
    private events: EventsService,
    private alertService: AlertService,
    private _cd: ChangeDetectorRef,
    private activityDataService: ActivityDataService,
    private deltaService: DeltaService,
    private authService: AuthenticationService,
  ) {

    this.initAboutPageTitle();
  }

  ngOnInit(): void {
    this.footerService.initButtons(FooterViews.APP_SETTINGS_ABOUT);
    this.versionHeader = {
      id: 'version-header',
      title: this.translate.instant('VERSION_CAPS'), //IMPORTANT DETAILS_CAPS
      controls: [],
    };

    this.importantDeatilHeader = {
      id: 'imp-details-header',
      title: this.translate.instant('IMPORTANT DETAILS_CAPS'), //IMPORTANT DETAILS_CAPS
      controls: [],
    };
    this.refreshDataHeader = {
      id: 'refresh-data-header',
      title: this.translate.instant('DATA'),
      controls: [],
    };
    this.events.observe('device:deviceIsOffline').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((status) => {
        this._cd.detectChanges();
      });

    this.events.observe('device:deviceIsOnline').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((status) => {
        this._cd.detectChanges();
      });
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      this._cd.detectChanges();
    });

    this.forceUploadEnabled = this.authService.hasFeatureAction(FeatureActionsMap.FORCE_OFFLINE_ACTIVITIES_PUSH, true);
  }

  private initAboutPageTitle(): void {
    this.aboutPageTitle = {
      id: 'about-page-title',
      title: this.translate.instant('ABOUT'),
      controls: [],
    };
  }

  openAboutDetails(title) {
    this.navService.pushWithPageTracking(AboutDetailsComponent, PageName.AboutDetailsComponent, { aboutTitle: title }, PageName.AboutDetailsComponent);
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.onClosePage();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  public onClosePage() {
    this.uiService.showRightPane = false;
    this.navService.setChildNavRightPaneView(false);
  }

  public refreshData() {
    if (this.device.isOffline) return;
    this.alertService.showAlert({
      header: this.translate.instant('REFRESH_DATA'),
      message: this.translate.instant('REFRESH_DATA_ALERT')
    }, this.translate.instant('REFRESH')
    ).then(res => {
      if (res.role == "ok") {
        this.uiService.setAgendaTab("plans");
        this.navService.popToRootWithPageTracking().then(() => {
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
          this.events.publish("refreshData");
        })
      }
    })
  }

  async forceUpload() {
    const res = await this.alertService.showAlert({
      header: this.translate.instant("FORCE_UPLOAD"),
      message: this.translate.instant("FORCE_UPLOAD_MESSAGE")
    },this.translate.instant("UPLOAD") ,this.translate.instant("CANCEL"));

    if (res?.role === 'ok') {
      await this.uiService.displayLoader();
      this.deltaService.startSyncInfoRecording(true, false);
  
      await this.activityDataService.forceOfflineDataUpload();
  
      this.deltaService.endSyncInfoRecording(true);
      await this.deltaService.uploadSyncStatus(true);
      this.deltaService.cleanUpSyncInfoRecording();
      await this.uiService.dismissLoader();
    }
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

}
