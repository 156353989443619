import { SurveyService } from '@omni/services/survey/survey.service';
import { EventsService } from './../../../services/events/events.service';
import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { PopoverController } from '@ionic/angular';
import { AccesingMode, CaseManagementService } from '../../../services/case-management/case-management.service';
import { CaseActivity } from '../../../classes/case-intake/case-activity.class';
import { DeviceService } from '../../../services/device/device.service';
import { CaseManagementDataService } from '../../../data-services/case-management/case-management.data.service';
import { LoadingController } from '@ionic/angular';
import { AuthenticationService } from '../../../services/authentication.service';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { MultiSelectPopover } from '../../multi-select-popover/multi-select-popover';
import { TranslateService } from '@ngx-translate/core';
import { GlobalUtilityService } from "../../../services/global-utility.service";
import { IndHeaderLeftDataModel } from "../../../models/indHeaderLeftDataModel";
import { TrackingEventNames, TrackService } from "../../../services/logging/tracking.service";
import { UIService } from "../../../services/ui/ui.service";
import _ from 'lodash';
import { Subscription, Subject } from 'rxjs';
import { IndTabsDataModel } from '@omni/models/ind-tabs-data-model';
import { ActivityService } from '@omni/services/activity/activity.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { takeUntil } from 'rxjs/operators';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

/**
 * Generated class for the CaseManagementListComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'case-management-list',
  templateUrl: 'case-management-list.html',
  styleUrls:['case-management-list.scss']
})
export class CaseManagementListComponent {

  hasTeamEnabled: boolean = false;
  filterPopoverData: { text: string; value: string; items: any; handler: (selectedItem: any, item: any, itemRef: any) => void; }[];
  teamFilterPopoverData: ({ text: string; value: string; items: { text: any; value: any; }[]; handler: (selectedItem: any, item: any, itemRef: any) => void; customized?: boolean; })[];

  indHeaderLeftModel: IndHeaderLeftDataModel;
  isAddPlusShown: boolean = false;
  filterValueSelected: boolean = false;
  @ViewChild('Content', {static:true}) private content: HTMLIonContentElement;
  NoInquiryMessage: string = 'NO_INQUIRY';
  private cases: CaseActivity[] = [];
  public visibleCases: CaseActivity[] = [];
  public visibleCount: number = 20;
  private scrollTop: boolean = false;
  private casesSubscription: Subscription;
  tabsData: IndTabsDataModel[];
  private teamCaseFilterBadgeCount: number = 0;
  private ngDestroy$ = new Subject<boolean>();

  constructor(
    private activityService: ActivityService,
    private navService: NavigationService,
    private repService: RepServices,
    private popoverCtrl: PopoverController,
    public caseManagementService: CaseManagementService,
    private deviceService: DeviceService,
    private caseManagementDataService: CaseManagementDataService,
    private loadingController: LoadingController,
    private authenticationService: AuthenticationService,
    public translate: TranslateService,
    private trackingService: TrackService,
    public utilityService: GlobalUtilityService,
    private uiService: UIService,
    private events: EventsService,
    public footerService: FooterService,
    private _cd: ChangeDetectorRef,
    private surveyService: SurveyService,
    private agendaFooterService: AgendaFooterService,
    private faService: FeatureActionsService,
  ) { }

  ngOnInit() {
    this.hasTeamEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.TEAM_CASES);
    this.setTabsData();
    this.casesSubscription = this.caseManagementService.casesSource$.subscribe((data: CaseActivity[]) => {
      this.visibleCases = [];
      this.visibleCount = 20;
      if (_.isEmpty(data)) this.cases = [];
      else {
        this.cases = data;
        if (this.cases.length > 20) {
          this.visibleCases = this.cases.slice(0, this.visibleCount);
        } else {
          this.visibleCases = this.cases;
        }
        try {
          if (this.content && !this.scrollTop) {
            this.scrollTop = true;
            this.content.scrollToTop()
            .then(() => this.scrollTop = false)
            .catch(() => {console.log("Scroll Error");this.scrollTop = false;});
          }
        } catch(err) {
          console.log("Scroll Error")
          this.scrollTop = false;
        }
      }
    });

    this.applyFilters();
    this.filterPopoverData = [
      {
        text: "",
        value: "All",
        items: [{ text: this.translate.instant('ALL_INQUIRIES_S'), value: 'All' }],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          })
          item.value = selectedItem.value;
          this.caseManagementService.caseFilterText = item.value;
          this.caseManagementService.filterObject = undefined;
          this.caseManagementService.filterUserObject = undefined;
          this.applyFilters();
        }
      },
      {
        text: this.translate.instant('INQUIRY_CATEGORY_FILTER'),
        value: "",
        items: this.caseManagementService.caseTypeList.map(b => ({ text: b.name, value: b.name })),
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = '';
          item.value = item.value === selectedItem.value ? "" : selectedItem.value;
          this.caseManagementService.caseFilterText = item.value;
          if (this.caseManagementService.caseFilterText == '') {
            itemRef.parent.items[0].value = 'All';
            this.caseManagementService.caseFilterText = 'All';
            this.caseManagementService.filterObject = undefined;
            this.caseManagementService.filterUserObject = undefined;
          }
          this.applyFilters();
        }
      }
    ]

    let teamViewFilterProducts = this.caseManagementService.caseProductSKU.map(o => ({ text: o.name, value: o }));
    teamViewFilterProducts.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1);

    this.teamFilterPopoverData = [
      {
        text: "",
        value: "All",
        items: [{ text: this.translate.instant('ALL_INQUIRIES_S'), value: 'All' }],
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items.map((o) => {
            o.value = '';
          })
          item.value = selectedItem.value;
          this.caseManagementService.teamInquiriesFilter.type = item.value;
          this.caseManagementService.teamInquiriesFilter.product = '';
          this.caseManagementService.filterObject = undefined;
          this.caseManagementService.filterUserObject = undefined;
          this.caseManagementService.teamInquiriesFilter.user = '';
          this.applyFilters();
        }
      },
      {
        text: this.translate.instant('INQUIRY_CATEGORY_FILTER'),
        value: "",
        items: this.caseManagementService.caseTypeList.map(b => ({ text: b.name, value: b.name })),
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = ''
          item.value = item.value === selectedItem.value ? "" : selectedItem.value;
          this.caseManagementService.teamInquiriesFilter.type = item.value;
          if (!this.caseManagementService.teamInquiriesFilter.type
            && !this.caseManagementService.teamInquiriesFilter.product
            && !this.caseManagementService.teamInquiriesFilter.user) {
            itemRef.parent.items[0].value = 'All';
            this.caseManagementService.teamInquiriesFilter.type = 'All';
            this.caseManagementService.teamInquiriesFilter.product = '';
            this.caseManagementService.filterObject = undefined;
            this.caseManagementService.filterUserObject = undefined;
            this.caseManagementService.teamInquiriesFilter.user = '';
          }
          this.applyFilters();
        }
      },
      {
        text: this.translate.instant('PRODUCT'),
        value: "",
        items: teamViewFilterProducts,
        handler: (selectedItem, item, itemRef) => {
          itemRef.parent.items[0].value = ''
          item.value = item.value === selectedItem.value ? "" : selectedItem.value;
          this.caseManagementService.filterObject = item.value;
          this.caseManagementService.teamInquiriesFilter.product = item.value.name
          //type should not be All if a product gets selected
          if (item.value != '' && this.caseManagementService.teamInquiriesFilter.type == 'All') {
            this.caseManagementService.teamInquiriesFilter.type = ''
          }
          if (!this.caseManagementService.teamInquiriesFilter.type
            && !this.caseManagementService.teamInquiriesFilter.product
            && !this.caseManagementService.teamInquiriesFilter.user) {
            itemRef.parent.items[0].value = 'All';
            this.caseManagementService.teamInquiriesFilter.type = 'All';
            this.caseManagementService.teamInquiriesFilter.product = '';
            this.caseManagementService.filterObject = undefined;
            this.caseManagementService.filterUserObject = undefined;
            this.caseManagementService.teamInquiriesFilter.user = '';
          }
          this.applyFilters();
        }
      },
      {
        text: this.translate.instant('USER'),
        value: "",
        items: [],
        customized: true,
        handler: (selectedItem, item, itemRef) => {

        }
      }
    ];
    this.initCaseHeaderLeft();
    this.uiService.toolsActivityActive = true;

    this.deviceService.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      if(this.caseManagementService.listMode != 'myCases'){
        if(this.deviceService.isOffline){
          this.NoInquiryMessage = 'TEAM_INQUIRY_ONLINE_ONLY';
        }
        this.uiService.dismissLoader();
      }
      this.setTabsData();
      this._cd.detectChanges();
    });
  }

  updateEmptyMessage(){
    this.events.publish('updateInquiriesRHSEmptyMessage', this.caseManagementService.casesLength > 0 ? true : false)
  }

  async listSegment(selecetedTabValue: string) {
    this.caseManagementService.listMode = selecetedTabValue;
    if (selecetedTabValue == 'myCases') {
      this.trackingService.tracking('MyInquiriesTab', TrackingEventNames.CUSTOMER_INQUIRY);
      this.NoInquiryMessage = 'NO_INQUIRY'
    } else {
      this.trackingService.tracking('TeamInquiriesTab', TrackingEventNames.CUSTOMER_INQUIRY);
      this.NoInquiryMessage = 'NO_TEAM_INQUIRY';
      if(this.caseManagementService.teamCases.length == 0){
        this.uiService.displayLoader(null,true);
        await this.getTeamCasesData();
      }else{
        this.caseManagementService.casesSource = this.caseManagementService.teamCases;
      }
    }
    this.caseManagementService.assignSelectedCase(undefined);
    this.initCaseHeaderLeft();
    this.applyFilters();
  }

  onClose(o) {
    this.caseManagementService.resetFilters();

    this.navService.popWithPageTracking().then( () => {
      this.uiService.activeView = this.uiService.prevView;

      this.activityService.activityDetailsLoaded = true;
      if (this.activityService.selectedActivity) {
        this.events.publish('activity-pane:selectActivity', this.activityService.selectedActivity);
      }
      this.events.publish("tools-back-clicked", PageName.CaseManagementDetailComponent);
      this.uiService.toolsActivityActive = false;

      // Short call feature requires to bring the tab back to short call home
      if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
        this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
        this.uiService.setAgendaTab('shortCallHome');
      }

      // To trigger scroll to today once we go back to agenda
      setTimeout(() => {
        this.activityService.scrolledOnce = false;
      }, 100);

    });

    this.uiService.toolsActivityActive = false;
  }

  openCaseFilterPopup(e, event) {
    this.filterValueSelected = true;
    if (e == 'my') this.popoverCtrl.create({component: MultiSelectPopover,componentProps: { root: this.filterPopoverData },  cssClass: 'dropdown-list-view', event:event}).then((data)=>data.present())
    else this.popoverCtrl.create({component:MultiSelectPopover,componentProps: { root: this.teamFilterPopoverData },  cssClass: 'dropdown-list-view', event:event}).then((data)=>data.present())
    this.caseManagementService.searchText = '';
  }

  handleSearch() {
    this.filterValueSelected = false;
    this.caseManagementService.filterObject = undefined;
    this.caseManagementService.filterUserObject = undefined
    this.caseManagementService.caseFilterText = "All";
    this.caseManagementService.teamInquiriesFilter.product = ''
    this.caseManagementService.teamInquiriesFilter.user = '';
    this.caseManagementService.teamInquiriesFilter.type = 'All';
    this.filterPopoverData.map((o) => {
      o.value = '';
    });
    this.teamFilterPopoverData.map((o) => {
      o.value = '';
    });
    this.applyFilters();
  }

  public doInfinite(eventDetail,event) {
    this.visibleCount = this.visibleCases.length;
    this.visibleCases.push(...this.cases.slice(this.visibleCount, this.visibleCount + 20));
    event.target.complete();
}

  applyFilters() {
    if (this.caseManagementService.listMode === 'teamCases') {
      this.caseManagementService.getTeamCaseList();
    } else {
      this.caseManagementService.getMyCaseList();
    }
    this.updateEmptyMessage()
  }
  async caseSelection(item: CaseActivity) {

    this.caseManagementService.accessedFrom = AccesingMode.CASE_TOOL;
    this.caseManagementService.isCaseSelected = true;
    this.caseManagementService.currentCase = item;

    /* Online device with GUID */
    if (!this.deviceService.isOffline && !item.ID.includes('offline') && this.caseManagementService.listMode !== 'teamCases') {

        let loader = await this.loadingController.create();
        loader.present();
        this.caseManagementDataService.fetchCaseById(item.ID).then(
          async res => {
            item._case_stage_value = res['_indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'] ? res['_indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'] : item._case_stage_value;

            item._case_status_value = res['statuscode@OData.Community.Display.V1.FormattedValue'] ? res['statuscode@OData.Community.Display.V1.FormattedValue'] : item._case_status_value;

            item._case_assignee = res['_ownerid_value@OData.Community.Display.V1.FormattedValue'] ? res['_ownerid_value@OData.Community.Display.V1.FormattedValue'] : item._case_assignee;

            item._case_timeline = res['inquiryTimeline'] ? item.getTimeline(res['inquiryTimeline']) : item._case_timeline ? item._case_timeline : [];

            item.state = res['statecode'] ? res['statecode'] : item.state;

            item._case_status_id = res['statuscode'] ? res['statuscode'] : item['statuscode'];

            item._case_overriddencreatedon = res['overriddencreatedon'] ? new Date(Number(res['overriddencreatedon'])) : undefined;
            item._indskr_intakecreated = res['indskr_intakecreated'] ? new Date(Number(res['indskr_intakecreated'])) : undefined;

            item._case_inquiry_response = res['expertInquiryResponse'] || item._case_inquiry_response;

            this.caseManagementService.assignSelectedCase(item);

            if (this.caseManagementService.listMode === 'myCases') {
              await this.caseManagementService.upsertMyCases(item);

              await this.caseManagementService.updateCaseInPouch(item);
            } else {
              await this.caseManagementService.upsertTeamCases(item);

              await this.caseManagementService.updateTeamCaseInPouch(item);
            }
            loader.dismiss();
          }).catch(err => {
          this.caseManagementService.assignSelectedCase(item);
          loader.dismiss();
        });
    }
    /* Online device with offline ID, rare chance but need to handle, create case and assign */
    else if (!this.deviceService.isOffline && item.ID.includes('offline') && String(item._case_status_value).toLowerCase().includes('pending sync') && this.caseManagementService.listMode !== 'teamCases') {
      this.caseManagementDataService.createCaseOnline(item).then(
        async response => {
          if (!response.hasOwnProperty('error')) {
            await this.caseManagementService.removeOfflinePayload(item);
            await this.caseManagementService.addNewCase(response, item);
            this.caseManagementService.assignSelectedCase(item);
          }
          else {
            this.caseManagementService.assignSelectedCase(item);
          }
        }
      ).catch(err => {
        console.log(err);
        this.caseManagementService.assignSelectedCase(item);
      })
    }
    /* probably the device is offline just set whatever data we have and be done with this */
    else {
      this.caseManagementService.assignSelectedCase(item);
    }

    /* Next step setting the view for the user */

    if (this.deviceService.shouldBeMobileView) {
      this.caseManagementService.showRightPane = true;
    }
    else {
      this.caseManagementService.showRightPane = false;
    }

  }

  newCaseTrigger() {
    this.caseManagementService.showNewCase = true;
    this.caseManagementService.assignSelectedCase(undefined);
    this.caseManagementService.accessedFrom = AccesingMode.CASE_TOOL;
    this.caseManagementService.listMode = "myCases";
    this.caseManagementService.showRightPane = this.deviceService.shouldBeMobileView;
    this.surveyService.surveyLookupResults = [];
    this.surveyService.assignLookupResult(undefined);
  }

  getCaseFilterText() {
    let str = '';
    this.teamCaseFilterBadgeCount = 0;

    if (this.caseManagementService.searchText != "") {
      str = this.translate.instant('AL_RESULTS');
    }
    else {
      if (this.caseManagementService.teamInquiriesFilter.type == 'All') {
        str = this.translate.instant('ALL_INQUIRIES_S')
      }
      else {
        if (this.caseManagementService.teamInquiriesFilter.type) {
          str = this.caseManagementService.teamInquiriesFilter.type
          this.teamCaseFilterBadgeCount++;
          if (this.caseManagementService.teamInquiriesFilter.product) {
            str += ', ' + this.caseManagementService.teamInquiriesFilter.product
            this.teamCaseFilterBadgeCount++;
            if (this.caseManagementService.teamInquiriesFilter.user) {
              str += ', ' + this.caseManagementService.teamInquiriesFilter.user
              this.teamCaseFilterBadgeCount++;
            }
          }
          else if (this.caseManagementService.teamInquiriesFilter.user) {
            str += ', ' + this.caseManagementService.teamInquiriesFilter.user
            this.teamCaseFilterBadgeCount++;
          }
        }
        else {
          if (this.caseManagementService.teamInquiriesFilter.product) {
            str = this.caseManagementService.teamInquiriesFilter.product
            this.teamCaseFilterBadgeCount++;
            if (this.caseManagementService.teamInquiriesFilter.user) {
              str += ', ' + this.caseManagementService.teamInquiriesFilter.user
              this.teamCaseFilterBadgeCount++;
            }
          }
          else if (this.caseManagementService.teamInquiriesFilter.user) {
            str = this.caseManagementService.teamInquiriesFilter.user
            this.teamCaseFilterBadgeCount++;
          }
        }
      }
    }

    return str
  }
  getFilterButtonBadgeCount() {
    if (this.caseManagementService.listMode === 'myCases') {
      return this.caseManagementService.searchText === ''
              && this.caseManagementService.caseFilterText
              && !this.caseManagementService.caseFilterText.includes('All') ? 1 : 0;
    } else {
      return this.teamCaseFilterBadgeCount;
    }
  }

  private initCaseHeaderLeft(): void {
    let buttons = [];
    buttons.push({
        id: "close",
        imgSrc: 'assets/imgs/back_to_home_btn.svg',
        isDisabled: false,
        align: "left",
      },
      {
        id: "plusNew",
        imgSrc: 'assets/imgs/ios_add_3x.svg',
        isDisabled: false,
        align: "right",
        tooltip: "Create New Inquiry"
      });
    this.indHeaderLeftModel = {
      id: 'case-list-header-left',
      cssClass: 'main-tool-header-title',
      title: this.translate.instant('CUSTOMER_INQUIRIES_WITH_TEXT', { globalCustomerText: this.utilityService.globalCustomerText }),
      mode: true,
      controls: buttons,
    };

  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.onClose(false);
        break;
      case 'plusNew':
        this.newCaseTrigger();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }


  setTabsData() {
    this.tabsData = [
      {
         displayText: this.translate.instant('MY_INQUIRIES'),
         value: "myCases"
      },
      {
       displayText: this.hasTeamEnabled ? this.translate.instant('TEAM_INQUIRIES') : '',
       value: "teamCases",
       disable: !this.hasTeamEnabled || (this.deviceService.isOffline && this.caseManagementService.teamCases.length == 0),
      },
    ];
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.caseManagementService.casesSource = null;
    this.caseManagementService.teamCases = [];
    this.casesSubscription.unsubscribe();
  }

  private async getTeamCasesData(){
    this.caseManagementDataService.getTeamCasesOnline().then(succ=> {
      if (this.caseManagementService.listMode === 'teamCases') {
        this.caseManagementService.casesSource = this.caseManagementService.teamCases;
        this.applyFilters();
        this.uiService.dismissLoader();
      }
    }).catch(error => console.log('Error occured while fetch team cases online: '+error));
  }
}
