import { PresentationService } from './../../../services/presentation/presentation.service';
import {ConsentDataService} from '../../../data-services/consent/consent.data.service';
import {ActivityService} from '@omni/services/activity/activity.service';
import {WebsocketDataService} from '../../../data-services/websocket/websocket.data.service';
import {Component} from "@angular/core";
import {AuthenticationService} from "@omni/services/authentication.service";
import {AlertController, IonNav, ModalController, NavController, NavParams, PopoverController} from "@ionic/angular";
import {FooterService, FooterViews} from "@omni/services/footer/footer.service";
import {DeviceService} from "@omni/services/device/device.service";
import {RepServices} from "../../../data-services/rep/rep.services";
import {NavigationService, PageName} from "@omni/services/navigation/navigation.service";
import {NotificationService, ToastStyle} from "@omni/services/notification/notification.service";
import {ConsentSource, ConsentTerm, Signaturerequired, TermTokens} from "../../../classes/consent/consent-term.class";
import {Subject, Subscription} from "rxjs";
import {ConsentService} from "@omni/services/consent/consent.service";
import {Channel, ChannelActivityType, ChannelType, ChannelValue} from "../../../classes/consent/channel.class";
import {ConsentSignatureModalComponent} from "../consent-signature-modal/consent-signature-modal";
import {EventName, EventsService} from "@omni/services/events/events.service";
import {TrackingEventNames, TrackService} from "@omni/services/logging/tracking.service";
import {ComponentViewMode, UIService} from "@omni/services/ui/ui.service";
import {ContactOfflineService} from "@omni/services/contact/contact.service";
import {Contact, ContactAddress, ContactMeetingState, Email} from "../../../classes/contact/contact.class";
import {ContactNewAddressComponent} from "../contact-change/contact-address/contact-address-add";
import {AddressDataService} from "../../../data-services/address/address.data.service";
import {TranslateService} from "@ngx-translate/core";
import {AlertService} from "@omni/services/alert/alert.service";
import {ContactPageComponent} from "../contact-page/contact-page";
import {ConsentTermGenerateService} from "@omni/services/consent/consent-term-generate.service";
import {EmailService} from "@omni/services/email-templates/email.service";
import {EmailViewType} from "../../../classes/activity/email.activity.class";
import {CallPlanOfflineService} from "@omni/services/call-plan/call-plan.offline.service";
import {Product} from "../../../classes/consent/product.class";
import {Endpoints} from '../../../../config/endpoints.config';
import {DB_KEY_PREFIXES} from '../../../config/pouch-db.config';
import {FeatureActionsMap} from '../../../classes/authentication/user.class';
import {IndSectionHeaderViewDataModel} from '@omni/models/indSectionHeaderDataModel';
import { MeetingStructureService } from '@omni/services/meeting-structure/meeting-structure.service';
import { EmbeddedInteraction, EmbeddedInteractionType } from '@omni/classes/activity/activity.class';
import { MDMType } from '@omni/classes/mdm/source-type-optionset.class';
import _ from 'lodash';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IONote } from '@omni/classes/io/io-note.class';
import { MAXIMUM_NOTE_ATTACHMENT_SIZE, NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX, NOTE_ATTACHMENT_MIME_TYPES_VIDEO_RESTRICTED_REGEX, toBase64 } from '@omni/utility/util';
import { isBefore } from 'date-fns';
import { take, takeUntil } from 'rxjs/operators';
import {AbstractControl, Validators} from "@angular/forms";

/**
 * Generated class for the ConsentDetailsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: "consent-details[base-page]",
  templateUrl: "consent-details.html",
  styleUrls:['consent-details.scss']
})
export class ConsentDetailsComponent {
  public consentTerm: ConsentTerm = null;
  private consentTermSubscription: Subscription;
  public generatedTerms: ConsentTerm[] = [];
  private contact: Contact = null;
  private consentChannels: Channel[] = [];
  private channelSubscription: Subscription;

  isExpanded: boolean = false;
  expandOrCollapse: string = "Show All";
  public limitProds: number = 3;
  termBody: string = '';
  termBodyView: SafeHtml;

  addSubscription: Subscription;
  remoteSignatureFlowComplete: boolean = false;
  productsHeaderModel: IndSectionHeaderViewDataModel;
  emailHeaderModel: IndSectionHeaderViewDataModel;
  termsHeaderModel: IndSectionHeaderViewDataModel;
  signatureHeaderModel: IndSectionHeaderViewDataModel;
  public pageTitle: string = "";
  public backButton = [{
    id: "close",
    icon: "chevron-back-outline",
    isDisabled: false,
    align: "left"
  }];
  IcurrentMeetingActivitySubscription: Subscription;
  selectedMeetingActivity: EmbeddedInteraction;
  remoteConsentWaitTimer: any;
  waitingForRemoteConsentResponse: boolean = false;
  remoteConnectionLost: boolean;
  isOneKeyContact: boolean = false;
  public isConsentAddAddressDisabled: boolean = false;
  public signaturerequired = Signaturerequired;
  public isViewLess: boolean = true;
  private isLimitedView: boolean = false;
  public consentActivitiesByContactId_ConsentTermId = [];
  public notesHeaderModel:IndSectionHeaderViewDataModel;
  public isSaveNotesEnabled:boolean = false;
  public attachmentTitle:string = '';
  public isPaperConsentEnabled:boolean = false;
  public isAttachmentAdded:boolean = false;
  private attachmentFile:any;
  private base64str;
  public tempNoteText: string = '';
  private readonly ngDestroy$ = new Subject<boolean>();
  private capturedConsentTerms = [];

  constructor(
    public authenticationService: AuthenticationService,
    public navCtrl: IonNav,
    public footerService: FooterService,
    public device: DeviceService,
    public repService: RepServices,
    private navService: NavigationService,
    private events: EventsService,
    public popoverCtrl: PopoverController,
    public notificationService: NotificationService,
    public consentService: ConsentService,
    public consentTermGenerateService: ConsentTermGenerateService,
    private alertCtrl: AlertController,
    private alertService: AlertService,
    private trackingService: TrackService,
    private uiService: UIService,
    private contactService: ContactOfflineService,
    private addressService: AddressDataService,
    private translate:TranslateService,
    private emailService: EmailService,
    public navParams: NavParams,
    private callPlanService:CallPlanOfflineService,
    public webSocketService:WebsocketDataService,
    public activityService: ActivityService,
    public consentDataService: ConsentDataService,
    public modalController: ModalController,
    public presentationService: PresentationService,
    private readonly meetingStructureService: MeetingStructureService,
    private readonly sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.capturedConsentTerms = [];
    if(this.consentService.inMeetingConsentFlow){
      let contactID = this.contactService.contactInformation.ID;
      this.contact = this.activityService.selectedActivity['contacts'].find(o => o.ID == contactID);
      this.consentService.consentRemoteFlow = this.contact.connectionState == ContactMeetingState.JOINED
      this.remoteConnectionLost = this.contact.connectionState != ContactMeetingState.JOINED;
    }
    else  {
      this.consentService.consentRemoteFlow = false;
      this.contact = this.contactService.contactInformation;
    }
    this.consentTermSubscription = this.consentService.selectedConsentTerm.subscribe(
      (cTerm: ConsentTerm) => {
        if (cTerm) {
          this._initFooter();
          this.isExpanded = false;
          this.expandOrCollapse = "Show All";
          this.consentTerm = cTerm;
          this.consentTermGenerateService.contact = this.contactService.contactInformation;
          this.isPaperConsentEnabled = _.cloneDeep(cTerm.indskr_allowpaperconsentcapture);
          this.resetPaperConsent();
          if(!_.isEmpty(cTerm.consentActivity)) {
            let paperConsents: IONote[] = [];
            cTerm.consentActivity.forEach(act => {
              if(!_.isEmpty(act['annotations'])) {
                act['annotations'].forEach(note => {
                  note['isdocument'] = true;
                  let ioNote = new IONote(note);
                  if(_.isEmpty(paperConsents)) {
                    paperConsents.push(ioNote);
                  } else if(paperConsents.findIndex(con => ioNote['documentName'] == con['documentName'] && ioNote['documentSize'] == con['documentSize'] && ioNote['noteText'] == con['noteText'])<0)
                    paperConsents.push(new IONote(note));
                });
              }
            })
            if(!_.isEmpty(paperConsents)) {
              this.isPaperConsentEnabled = true;
              this.consentTerm.annotations = paperConsents;
              this.consentTerm.isPaperConsent = true;
            }
          }
          this.updateTokens(this.consentTerm);
        }
      }
    );
    this.channelSubscription = this.consentService.allConsentChannelSubject.subscribe(
      (response: Channel[]) => {
        this.consentChannels = response;
      }
    );
    this.consentService.allGeneratedTerms.subscribe(terms => {
      this.generatedTerms = terms;
      this.updateTermSelectionStatus();
      this.updateTokens(this.consentTerm);
    });

    if (this.emailService.viewType == EmailViewType.EMAIL_FROM_MEETING_PRESENTATION) {
      this.footerService.initButtons(FooterViews.PREVIEW_MEETING_ACTIVITY);
    }
    this.events.subscribe("consentDetails:footerEvents", this.footerHandler);
    this.events.subscribe('remote_consent:completed',(data)=>{
      if(this.waitingForRemoteConsentResponse){
        this.notificationService.notify(data.contactName +' '+ this.translate.instant('SIGNATURE_REQUEST_COMPLETE'),'');
        this.waitingForRemoteConsentResponse = false;
        if(this.remoteConsentWaitTimer) clearTimeout(this.remoteConsentWaitTimer)
        let activeConsent = data.consentTerm
        activeConsent.offlineId = DB_KEY_PREFIXES.CONSENTS + new Date().getTime();
        activeConsent.userid = this.authenticationService.user.xSystemUserID;
        activeConsent.indskr_positionid = this.authenticationService.user.xPositionID;
        this.consentDataService.saveConsent(activeConsent).then(
          async (res: ConsentTerm) => {
            await this.consentService.saveOrUpdateConsentsInDB(activeConsent);
            await this.consentService.getAllConsentActivitiesForProductsAndEmails(false);
            this.consentService.selectedConsentTerm.next(activeConsent);
            await this.uiService.dismissLoader();
            this.remoteSignatureFlowComplete = true;
          },
          msg => {}
        );
      }
      else{
        this.webSocketService.sendMessageToTopic(Endpoints.websockets.REMOTE_ACTION.replace('{activityId}',
                                                this.activityService.selectedActivity.ID),
                                                {consentTerm:this.consentTerm, channels:this.consentService.savedChannels,
                                                  type:'remote_consent:timeout', contactID: this.contactService.contactInformation.ID
                                                },true
        );
      }
    })
    this.events.subscribe('remote_consent:rejected',(data)=>{
      if(this.waitingForRemoteConsentResponse){
        this.notificationService.notify(this.translate.instant('SIGNATURE_REQUEST_DECLINED'),'');
        if(this.remoteConsentWaitTimer) clearTimeout(this.remoteConsentWaitTimer)
        this.uiService.dismissLoader();
        this.remoteSignatureFlowComplete = false;
        this.waitingForRemoteConsentResponse = false;
      }
      else{
        this.webSocketService.sendMessageToTopic(Endpoints.websockets.REMOTE_ACTION.replace('{activityId}',
                                                this.activityService.selectedActivity.ID),
                                                {consentTerm:this.consentTerm, channels:this.consentService.savedChannels,
                                                  type:'remote_consent:timeout', contactID: this.contactService.contactInformation.ID
                                                },true
        );
      }
    })
    this.events.subscribe('remote_consent:accepted',()=>{
      if(this.waitingForRemoteConsentResponse){
        this.notificationService.notify(this.translate.instant('SIGNATURE_REQUEST_ACCEPTED'),'');
        if(this.remoteConsentWaitTimer){
          clearTimeout(this.remoteConsentWaitTimer)
        }
        this.remoteConsentWaitTimer = setTimeout(()=>{
          this.notificationService.notify(this.translate.instant('SIGNATURE_REQUEST_TIMEOUT'),'');
          this.waitingForRemoteConsentResponse = false;
          this.uiService.dismissLoader();
          this.webSocketService.sendMessageToTopic(Endpoints.websockets.REMOTE_ACTION.replace('{activityId}',
                                                this.activityService.selectedActivity.ID),
                                                {consentTerm:this.consentTerm, channels:this.consentService.savedChannels,
                                                  type:'remote_consent:timeout', contactID: this.contactService.contactInformation.ID
                                                },true
          );
        },60000)
      }
      else{
        this.webSocketService.sendMessageToTopic(Endpoints.websockets.REMOTE_ACTION.replace('{activityId}',
                                                this.activityService.selectedActivity.ID),
                                                {consentTerm:this.consentTerm, channels:this.consentService.savedChannels,
                                                  type:'remote_consent:timeout', contactID: this.contactService.contactInformation.ID
                                                },true
        );
      }
    })
    // this.events.subscribe('remote_consent:timeout',()=>{
    //   if(this.waitingForRemoteConsentResponse){
    //     if(this.remoteConsentWaitTimer){
    //       clearTimeout(this.remoteConsentWaitTimer);
    //     }
    //     this.notificationService.notify(this.translate.instant('SIGNATURE_REQUEST_TIMEOUT'),'');
    //     this.waitingForRemoteConsentResponse = false;
    //   }
    // })
    this.events.subscribe('remote_meeting_participant_Joined_left',this.handleContactConnectionLost.bind(this))
    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.initPaperConsentHeader();
    });
    let titleName = this.consentTerm.isSavedConsent ? this.consentTerm.indskr_consentTermName : this.translate.instant('NEW_CONSENT_CAPTURE');
    this.pageTitle = titleName;

    this.isOneKeyContact = this.contact.mdmType === MDMType.ONEKEY ? true : false;
    this.isConsentAddAddressDisabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.CONSENT_ADD_ADDRESS_DISABLE);

    this.showMessageConsentCannotAddDetailsForOneKeyContact();

    this.getConsentActivitiesByContactId_ConsentTermId();
    this.initSectionHeader();
  }
  public handleContactConnectionLost(data){
    if(this.consentService.inMeetingConsentFlow && data.contactID && data.contactID == this.contactService.contactInformation.ID){
      if (data.joinedStatus === 'joined') {
        this.consentService.consentRemoteFlow = true;
        this.remoteConnectionLost = false;
      } else if (data.joinedStatus === 'left') {
        if (data.contactID && data.contactID == this.contactService.contactInformation.ID) {
          this.remoteConnectionLost = true;
          //this.closeInMeetingConsentFlow();
        }
      }
    }
  }

  initSectionHeader() {
    this.getProducsHeader();
    this.getEmailSectionHeader();
    this.getTermsHeader();
    this.signatureTermsHeader();
    this.initPaperConsentHeader();
  }

  public getProducsHeader() {

    this.productsHeaderModel = {
      id: 'products-header',
      title: this.translate.instant('PRODUCTS_CAP'),
      controls: [],
    };
  }
  public onSectionHeaderControlClick(id:string) {
    switch (id) {
      case 'view-expandable':
        this._checkContainerHeight();
        break;
      // case 'save_paper_consent':
      //   this.savePaperConsent();
      //   break;
      case 'attach_note':
        this.handleNoteAttachment();
        break;
      default:
        break;
    }
  }
  private _checkContainerHeight(){
    const termBodyContainer = document.getElementById('termBodyContainer');
    const containerHeight = termBodyContainer ? termBodyContainer.getBoundingClientRect().height : null;
    this.isLimitedView = false;
    const pTagInTermBody = termBodyContainer.getElementsByTagName('p');
    let totalPtagHeight: number = 0;
    for(let p of pTagInTermBody) {
      totalPtagHeight += p.getBoundingClientRect().height;
    }
    if(containerHeight && containerHeight > 51) {
      this.isViewLess = true;
    } else {
      this.isViewLess = false;
      if(pTagInTermBody && totalPtagHeight < 30) this.isLimitedView = true;
    }
    this.getTermsHeader();
  }


  public getTermsHeader() {

    this.termsHeaderModel = {
      id: 'terms-header',
      title: this.translate.instant('TERMS_CAP'),
      controls: [
        {
          id: 'view-expandable',
          text: this.isViewLess ? this.translate.instant('VIEW_MORE') : this.translate.instant('VIEW_LESS'),
          isVisible: this.termBodyView ? true : false,
          isDisabled: this.isLimitedView ? true : false,
        },
      ]
    };
  }

  public signatureTermsHeader() {

    this.signatureHeaderModel = {
      id: 'signature-header',
      title: this.translate.instant('SIGNATURE_CAP'),
      controls: [],
    };
  }

  public getEmailSectionHeader() {

    let detailsHeaderControls = [];
    // let name = this.getChannelName(this.channel.indskr_consenttypeid)}}

    // detailsHeaderControls.push({
    //     id: 'products-select-deselect-btn',
    //     text: this.productLableText==this.translate.instant('Deselect All')? this.translate.instant('DESELECT_ALL'):this.translate.instant('SELECT_ALL'),
    //     isDisabled:false,
    // });

    this.emailHeaderModel = {
      id: 'products-header',
      title: this.translate.instant('PRODUCTS_CAP'),
      controls: [],
    };
  }

  public initPaperConsentHeader() {
    this.notesHeaderModel = {
      id: 'task-notes-header',
      title: this.translate.instant('PAPER_CONSENTS'),
      doNotModifyTitleCase: true,
      controls: [
        {
          id:'attach_note',
          text: this.translate.instant('ATTACH'),
          isDisabled: this.device.isOffline || this.consentTerm.isSavedConsent,
          isVisible: this.isPaperConsentEnabled && !this.consentTerm.isSavedConsent,
        },
        // {
        //   id:'save_paper_consent',
        //   text: this.translate.instant('SAVE'),
        //   isDisabled: !this.isSaveNotesEnabled || this.device.isOffline || this.consentTerm.isSavedConsent,
        //   isVisible: !this.consentTerm.isSavedConsent,
        // }
      ]
    };
  }

  public removeAttachment(event){
    try {
      (document.getElementById('file-input') as any).value = null;
    } catch (error) {
      console.log(error);
    }
    this.attachmentTitle = '';
    this.isAttachmentAdded = false;
    this.attachmentFile = null;
    this.base64str = null;
    // if(this.tempNoteText.length > 0 || this.attachmentFile){
    if(this.attachmentFile){
      this.isSaveNotesEnabled = true;
    }else{
      this.isSaveNotesEnabled = false;
    }
    this.initPaperConsentHeader();
  }

  private footerHandler: (eventName: string) => void = (eventName: string) => {
    switch (eventName) {
      case "consentFlipDetails":
        if (!this.uiService.consentFlipButton) {
          this.closepage();
        } else {
          if (this.emailService.viewType == EmailViewType.CREATE_FROM_MEETING ||
            this.emailService.viewType == EmailViewType.CREATE_FROM_PHONE_CALL ) {
            // this.navCtrl.pop();
            if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
              document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
            }
            this.events.publish('inmeeting-activity-back', { 'email': this.emailService.selectedActivity});
          } else if (this.navParams && this.navParams.data.from == PageName.EmailDetailsPageComponent) {
            this.navService.popWithPageTracking().then(() => {
              setTimeout(()=>{
                // this.footerService.initButtons(FooterViews.EmailDetails);
                this.events.publish('update-email-footer');
              },10);
            });
            this.emailService.checkConsentsForMessageActivity();
          } else if (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent) {
            this.navService.popChildNavPageWithPageTracking().then(() => {
              this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
              this.events.publish('update-active-consents-procedureLog');
            });
          }
          else {
            this.returnBackToConsent();
          }
        }
        break;
      default:
        break;
    }
  };

  expandCollapse() {
    if (this.expandOrCollapse === "Show All") {
      this.isExpanded = true;
      this.limitProds = this.consentTerm.products.length;
    } else if (this.expandOrCollapse === "Show Less") {
      this.isExpanded = false;
      this.limitProds = 3;
    }
    this.expandOrCollapse = this.isExpanded ? "Show Less" : "Show All";
  }

  checkAllDisabled() {
    this.consentTerm.channels.forEach(channel => {
      if (channel.values && channel.values.length) {
        channel['block'] = channel.values.every(v => (v['block'] && v['block'] === true))
      }
    })
    return this.consentTerm.channels.every(ch => (ch['block'] && ch['block'] === true));
  }

  private updateTermSelectionStatus() {
    this.generatedTerms.forEach((generatedTerm: ConsentTerm) => {
      generatedTerm.isSelectedTerm =
        this.consentTerm.indskr_consenttermsid ===
        generatedTerm.indskr_consenttermsid;
    });
    this.updateTokens(this.consentTerm);
  }

  updateTokens(term: ConsentTerm) {
    const cTerm: ConsentTerm = JSON.parse(JSON.stringify(term));
    let termBody: string = cTerm.indskr_body ? cTerm.indskr_body.toString() : "";
    if (termBody) {
      for (let token in TermTokens) {
        if (TermTokens.hasOwnProperty(token)) {
          if (termBody.indexOf(TermTokens[token]) >= 0) {
            termBody = this.replaceTokens(termBody, TermTokens[token]);
          }
        }
      }
      this.termBody = termBody;
      let formattedBody = this._replacePropertyInTag(termBody);
      this.termBodyView = this.sanitizer.bypassSecurityTrustHtml(formattedBody);
    }
  }

  replaceTokens(termBody: string, token: string): string {
    switch (token) {
      case TermTokens.PRODUCT_NAME_TOKEN:
        let pNames = this.stringifyProductNames();
        termBody = termBody.split(token).join(pNames ? pNames : "");
        break;
      case TermTokens.CONTACT_TITLE_TOKEN:
        termBody = termBody.split(token).join(this.contact.title ? this.contact.title : "");
        break;
      case TermTokens.CONTACT_FIRST_NAME_TOKEN:
        termBody = termBody.split(token).join(this.contact.firstName ? this.contact.firstName : "");
        break;
      case TermTokens.CONTACT_LAST_NAME_TOKEN:
        termBody = termBody.split(token).join(this.contact.lastName ? this.contact.lastName : "");
        break;
      case TermTokens.CONTACT_FULL_NAME_TOKEN:
        termBody = termBody.split(token).join(this.contact.fullName ? this.contact.fullName : "");
        break;
      case TermTokens.CONTACT_EMAIL_TOKEN:
        let eNames = this.stringifyEmails();
        termBody = termBody.split(token).join(eNames ? eNames : "");
        break;
      case TermTokens.CONTACT_ADDRESS_TOKEN:
        let aNames = this.stringifyAddresses();
        termBody = termBody.split(token).join(aNames ? aNames : "");
        break;
      case TermTokens.CONTACT_PHONE_TOKEN:
        let phoneNums = this.stringifyPhoneNumbers();
        termBody = termBody.split(token).join(phoneNums ? phoneNums : "");
        break;
      case TermTokens.CONTACT_FAX_TOKEN:
        termBody = termBody.split(token).join(this.contact.fax ? this.contact.fax : "");
        break;
      default:
    }
    return termBody;
  }

  stringifyProductNames(): string {
    let products: Product[] = this.consentTerm.products.filter(
      product => product.isChecked
    );
    return Array.prototype.map.call(products, s => s.indskr_productname).toString();
  }

  stringifyEmails(): string {
    let values: ChannelValue[] = [];
    this.consentTerm.channels.forEach(channel => {
      if (channel.indskr_consentType == (ChannelType.EMAIL || ChannelType.FACEBOOK)) {
        if (channel.values) {
          channel.values.forEach(value => {
            if (value.isChecked) values.push(value);
          });
        }
      }
    });
    return Array.prototype.map.call(values, s => s.value).toString();
  }

  stringifyPhoneNumbers(): string {
    let values: ChannelValue[] = [];
    this.consentTerm.channels.forEach(channel => {
      if (channel.indskr_consentType == (ChannelType.PHONE || ChannelType.SMS || ChannelType.WHATSAPP)) {
        if (channel.values) {
          channel.values.forEach(value => {
            if (value.isChecked) values.push(value);
          });
        }
      }
    });
    return Array.prototype.map.call(values, s => s.value).toString();
  }

  stringifyAddresses(): string {
    let values: ChannelValue[] = [];
    this.consentTerm.channels.forEach(channel => {
      if (channel.indskr_consentType == ChannelType.VISIT || channel.indskr_consentType == ChannelType.SURGERY_ORDER) {
        if (channel.values) {
          channel.values.forEach(value => {
            if (value.isChecked) values.push(value);
          });
        }
      }
    });
    return Array.prototype.map.call(values, s => s.value).toString();
  }

  openConsentDetails(term: ConsentTerm) {
    console.log("Opening Consent terms: " + term.indskr_consenttermsid);
    term.isSelectedTerm = true;
    this.consentService.selectedConsentTerm.next(term);
    // const generatedTerms = this.consentTermGenerateService.generateConsentTerms();
    // this.consentService.allGeneratedTerms.next(generatedTerms);
    const generatedTerms = this.consentService.allGeneratedTerms.value;
    this.generatedTerms = generatedTerms;
    this.updateTermSelectionStatus();
    this.isLimitedView = false;
    this.isViewLess = true;
    this.getTermsHeader();
  }

  ngOnDestroy() {
    this.events.unsubscribe("consentDetails:footerEvents", this.footerHandler);
    this.events.unsubscribe("remote_consent:completed");
    this.events.unsubscribe("remote_consent:rejected");
    this.events.unsubscribe("remote_consent:accepted");
    this.events.unsubscribe("remote_consent:timeout");
    this.events.unsubscribe('remote_meeting_participant_Joined_left',this.handleContactConnectionLost)
    this.consentTermSubscription.unsubscribe();
    if (this.addSubscription) this.addSubscription.unsubscribe();
    if (this.channelSubscription) this.channelSubscription.unsubscribe();
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  closepage() {
    this.contactService.isConsentInvoked = false;
    if (this.emailService.viewType == EmailViewType.CREATE_FROM_MEETING ||
      this.emailService.viewType == EmailViewType.CREATE_FROM_PHONE_CALL) {

      // this.navService.popWithPageTracking();
    if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
      document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
    }
    this.events.publish('inmeeting-activity-back', { 'email': this.emailService.selectedActivity });

    }
    else if (this.emailService.viewType == EmailViewType.EMAIL_FROM_MEETING_PRESENTATION) {
      this.navService.popWithPageTracking().then(() => {
        this.emailService.checkConsentsForMessageActivity();
      });
    }
    else if(this.consentService.inMeetingConsentFlow){
      if((this.consentService.consentRemoteFlow && this.remoteSignatureFlowComplete)
          || (!this.consentService.consentRemoteFlow && this.consentTerm.isSavedConsent)){
        this.closeInMeetingConsentFlow();
      }
      else this.navService.popChildNavPageWithPageTracking();
    }
    else {
      if (!this.uiService.consentFlipButton) {
        this.navService.popWithPageTracking().then(() => {
          if (this.consentService.consentTab === FooterViews.ConsentCapture) {
            this.footerService.initButtons(FooterViews.ConsentCapture);
          }else{
            this.footerService.initButtons(FooterViews.Contacts);
          }
        });
      }
      else if (this.navService.getCurrentMasterPageName() === PageName.CallPlanComponent){
        this.uiService.activeView ='Email';
        this.callPlanService.callPlanPageView = 'CallPlanNewEmailDetails';
        this.navService.popWithPageTracking().then(() => {
          // this.footerService.initButtons(FooterViews.EmailDetails);
          this.events.publish('update-email-footer');
        });
        this.emailService.checkConsentsForMessageActivity();
      }
      else if (this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent || this.navService.getCurrentMasterPageName() === PageName.ConsentDetailsComponent){
        this.uiService.activeView ='Email';
        this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
        this.navService.popWithPageTracking().then(() => {
          this.emailService.checkConsentsForMessageActivity();
          if(this.emailService.viewType == EmailViewType.FROM_AGENDA) {
            this.emailService.setCurrentEmail(this.emailService.selectedActivity);
          }
        });
      }
      else if (this.navService.getCurrentMasterPageName() === PageName.ScientificActivityPage){
        this.uiService.activeView ='Email';
        this.navService.popWithPageTracking().then(() => {
          // this.footerService.initButtons(FooterViews.EmailDetails);
          this.events.publish('update-email-footer');
        });
        this.emailService.checkConsentsForMessageActivity();
      }
      else if(this.emailService.viewType == EmailViewType.FROM_AGENDA && this.navParams && this.navParams.data.from == PageName.EmailDetailsPageComponent) {
        this.navService.popWithPageTracking().then(() => {
          setTimeout(()=>{
            // this.footerService.initButtons(FooterViews.EmailDetails);
            this.events.publish('update-email-footer');
          },10);
        });
        this.emailService.checkConsentsForMessageActivity();
      }
      else if (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent) {
        this.navService.popChildNavPageWithPageTracking().then(() => {
          this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
          this.events.publish('update-active-consents-procedureLog');
        });
      }
      else {
        this.navService.popToRootWithPageTracking().then(() => {
          this.emailService.checkConsentsForMessageActivity();
        });
      }
    }
  }
  closeInMeetingConsentFlow(){
    this.consentService.inMeetingConsentFlow = false;
    this.presentationService.showNewActivity = false;
    if ((!this.selectedMeetingActivity) || (this.selectedMeetingActivity && this.selectedMeetingActivity.type === EmbeddedInteractionType.Content)) {
      this.footerService.initButtons(FooterViews.PreviewMeeting);
    }
    this.navService.popChildNavPageWithPageTrackingMultipleSteps(2);
  }

  returnBackToConsent() {
    this.uiService.showNewActivity = false;
    this.uiService.activeView = "contactDetails";
    this.uiService.contactDetailsSegment = 'capture';
    this.contactService.isContactDetailsLoaded = false;
    this.contactService.contactInformation = this.contactService.getContactByID(this.consentTermGenerateService.contact.ID);
    this.uiService.isConsentFromToolDrawer = true;
    this.contactService.isContactDetailsLoaded = true;
    this.contactService.isConsentInvoked = true;
    this.contactService.contactPageMode = ComponentViewMode.READONLY;
    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, {from: "MESSAGE_ACTIVITY"}, PageName.ConsentDetailsComponent);
  }

  checkIfAtleastOneProductSelected(event) {
    let atleastOneSelected: boolean = this.consentTerm.products.some(
      product => product.isChecked
    );
    if (!atleastOneSelected) {
      event.target.checked = true;
    }

    const checkedProducts = this.consentTerm.products.filter(
      product => product.isChecked
    );

    // To reset channel Checkboxes
    this.consentTerm.channels.forEach((channel,index1) => {
      if (channel.activityType == ChannelActivityType.EMAIL) {
          if (channel.values) {
            channel.values.forEach((value,index2) => {
              this.consentTerm.channels[index1].values[index2]['disable'] = false;
              this.consentTerm.channels[index1].values[index2]['isChecked'] = false;
          });
        }
      }
    });

    if(!_.isEmpty(checkedProducts)) {
      checkedProducts.forEach((product) => {

        const consentActivitiesByContactId_ConsentTermId_Product =
        this.consentActivitiesByContactId_ConsentTermId.filter((item) => {
          return (
            item.indskr_productname == product.indskr_productname
          );
        });

        if(!_.isEmpty(consentActivitiesByContactId_ConsentTermId_Product)) {
          console.log(`consentActivitiesByContactId_ConsentTermId_Product :${consentActivitiesByContactId_ConsentTermId_Product.length} for product:${product}`);

          this.consentTerm.channels.forEach((channel,index1) => {
            if (channel.activityType == ChannelActivityType.EMAIL) {
              if (channel.values) {
                channel.values.forEach((value,index2) => {

                  const isExist = consentActivitiesByContactId_ConsentTermId_Product.some(
                    (item) => {
                      return (
                        item.emailAddress == value.value &&
                        item.consentTypeId == channel.indskr_consenttypeid
                      );
                    }
                  );

                  if (isExist) {
                    this.consentTerm.channels[index1].values[index2]['disable'] = true;
                    this.consentTerm.channels[index1].values[index2]['isChecked'] = false;
                  }
                  // if (value.isChecked) values.push(value);
                });
              } //end of if (channel.values)
            }//end of if (channel.indskr_consentType == (ChannelType.EMAIL))
          });
        }//end of if(!_.isEmpty(consentActivitiesByContactId_ConsentTermId_Product))

      });
    }



    this.updateTokens(this.consentTerm);
  }

  displayNoValues(channel: Channel): string {
    let value = "";
    if (channel.indskr_consentType === ChannelType.EMAIL)
      value = this.translate.instant('CONSENT_NO_EMAIL_ADDRS');
    else if (channel.indskr_consentType === ChannelType.PHONE)
      value = this.translate.instant('CONSENT_NO_PHONE_NUMBS');
    else if (channel.indskr_consentType === ChannelType.VISIT)
      value = this.translate.instant('CONSENT_NO_ADDRS');
    else if (channel.indskr_consentType === ChannelType.FACEBOOK)
      value = this.translate.instant('CONSENT_NO_EMAIL_ADDRS');
    else if (channel.indskr_consentType === ChannelType.SMS)
      value = this.translate.instant('CONSENT_NO_PHONE_NUMBS');
    else if (channel.indskr_consentType === ChannelType.WHATSAPP)
      value =this.translate.instant('CONSENT_NO_PHONE_NUMBS');
    else if (channel.indskr_consentType.toLocaleLowerCase() === 'Allocation Orders'.toLowerCase()) {
      value =this.translate.instant('NO_ORDERS');
    }
    else value = this.translate.instant('NO')+" " + channel.indskr_consentType.toLowerCase();
    return value;
  }

  checkIfAtleastOneChannelValueSelected(event) {
    const refTerm: ConsentTerm = this.consentTerm;
    let values: ChannelValue[] = [];
    refTerm.channels
      .filter(chan => chan.isChecked)
      .forEach(channel => {
        if (channel.values && channel.values.length) {
          values = channel.values.filter(value => value.isChecked);
          if (values.length < 1) {
            event.target.checked = true;
          }
        }
      });
    this.updateTokens(this.consentTerm);
  }

  async launchSignaturePad() {
    if(!this.consentService.consentRemoteFlow){
      //signature capture
      this.consentService.selectedConsentTerm.next(this.consentTerm);
      let submittedTermId = this.consentTerm.indskr_consenttermsid;
      let popover = await this.popoverCtrl.create({
                                                  component: ConsentSignatureModalComponent,
                                                  componentProps:{},
                                                  cssClass: "signature-pad",
                                                  backdropDismiss: false });
      popover.present();
      popover.onDidDismiss().then((data:any)=>{
        if(data && data.data.completedFlow){
          this.consentService.allGeneratedTerms.value.forEach(term => {
            if (term.indskr_consenttermsid == submittedTermId) {
              const index = this.capturedConsentTerms.findIndex(termId => termId == submittedTermId);
              if (index < 0) this.capturedConsentTerms.push(submittedTermId);
            }
          });
          this._reloadCurrentConsentTerm(true);
          this.getConsentActivitiesByContactId_ConsentTermId();
          this.navigate();
          if(data.data.submittedTerm) this.disableSubmittedChValue(submittedTermId, data.data.submittedTerm);
        }
      });
    }
    else{
      this.remoteSignatureFlowComplete = false;
      this.uiService.displayLoader({},true);
      let isReadOnly = false;
      let contactInfoObj = {title:this.contact.title, firstName:this.contact.firstName, lastName:this.contact.lastName, fullName:this.contact.fullname, fax:this.contact.fax};
      this.webSocketService.sendMessageToTopic(
      Endpoints.websockets.REMOTE_ACTION.replace('{activityId}', this.activityService.selectedActivity.ID),
      {consentTerm:this.consentTerm, channels:this.consentService.savedChannels,
        type:'consent_signature_capture', readonly:isReadOnly,
        contactID: this.contactService.contactInformation.ID,
        isProdConsent: this.authenticationService.user.isProductConsent,
        contactInfo:contactInfoObj,
      },
      true);
      this.waitingForRemoteConsentResponse = true;
      this.notificationService.notify(this.translate.instant('SIGNATURE_REQUEST_SENT'),'');
      this.remoteConsentWaitTimer = setTimeout(()=>{
        this.notificationService.notify(this.translate.instant('SIGNATURE_REQUEST_TIMEOUT'),'');
        this.waitingForRemoteConsentResponse = false;
        this.uiService.dismissLoader();
        this.webSocketService.sendMessageToTopic(Endpoints.websockets.REMOTE_ACTION.replace('{activityId}',
                                                this.activityService.selectedActivity.ID),
                                                {consentTerm:this.consentTerm, channels:this.consentService.savedChannels,
                                                  type:'remote_consent:timeout', contactID: this.contactService.contactInformation.ID
                                                },true
        );
      },60000)
    }
  }

  navigate() {
    const availableTerms: ConsentTerm[] = this.consentService.allGeneratedTerms.value.filter(
      term => !term.isSavedConsent
    );
    let isAllCapturedConsentTerms: boolean = false;
    if (!_.isEmpty(this.consentService.allGeneratedTerms.value) && !_.isEmpty(this.capturedConsentTerms)) {
      isAllCapturedConsentTerms = this.consentService.allGeneratedTerms.value.length > 1 && this.consentService.allGeneratedTerms.value.length == this.capturedConsentTerms.length;
    }

    if (
      this.consentService.allGeneratedTerms.value.length === 1 ||
      !availableTerms.length || isAllCapturedConsentTerms
    ) {
      if (isAllCapturedConsentTerms) this.capturedConsentTerms = [];

      if (this.emailService.viewType == EmailViewType.CREATE_FROM_MEETING ||
        this.emailService.viewType == EmailViewType.CREATE_FROM_PHONE_CALL) {
          if (document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
            document.getElementsByTagName('ion-modal')[0].classList.remove('fullStretchView');
          }
        this.events.publish('inmeeting-activity-back', { 'email': this.emailService.selectedActivity });
        this.emailService.checkConsentsForMessageActivity()
      }
      else if (this.emailService.viewType == EmailViewType.EMAIL_FROM_MEETING_PRESENTATION) {
        this.navService.popWithPageTracking().then(() => {
          this.emailService.checkConsentsForMessageActivity();
          this.emailService.setCurrentEmail(this.emailService.selectedActivity);
        });
      }
      else if(this.consentService.inMeetingConsentFlow){
        this.closeInMeetingConsentFlow()
      }
      else {
        if (!this.uiService.consentFlipButton) {
          this.uiService.contactDetailsSegment = "consent";
          this.events.publish(EventName.CONTACTDETAILSSEGMENTUPDATE,this.uiService.contactDetailsSegment);
          this.navService.popWithPageTracking();
        }
        else if (this.navService.getCurrentMasterPageName() === PageName.CallPlanComponent){
          this.uiService.activeView ='Email';
          this.callPlanService.callPlanPageView = 'CallPlanNewEmailDetails';
          this.navService.popWithPageTracking().then(() => {
            this.emailService.checkConsentsForMessageActivity();
            // this.footerService.initButtons(FooterViews.EmailDetails);
            this.events.publish('update-email-footer');
          });
        }
        else if (this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent || PageName.ConsentDetailsComponent){
          this.uiService.activeView ='Email';
          this.contactService.contactPageMode = ComponentViewMode.LISTVIEW;
          this.navService.popWithPageTracking().then(() => {
            this.emailService.checkConsentsForMessageActivity();
            if(this.emailService.viewType == EmailViewType.FROM_AGENDA) {
              // this.footerService.initButtons(FooterViews.EmailDetails);
              this.events.publish('update-email-footer');
            }
          });
        }
        else if (this.navService.getCurrentMasterPageName() === PageName.ScientificActivityPage){
          this.uiService.activeView ='Email';
          this.navService.popWithPageTracking();
          this.emailService.checkConsentsForMessageActivity();
        }
        else {
          this.navService.popToRootWithPageTracking().then(() => {
            this.consentService.currentConsentTerm.subscribe(val => {
              console.log(val);
              this.emailService.checkConsentsForMessageActivity();
            });
          });
        }
      }
    }
    this._initFooter();
  }

  openConfirmationPopup() {
    if(!this.consentService.consentRemoteFlow){
      this.alertService.showAlert({
        title: this.translate.instant('CONSENT_CONFIRM'),
        message: this.consentTerm.indskr_confirmationmessage?this.consentTerm.indskr_confirmationmessage:this.translate.instant('CONSENT_CONFIRMATION_MESSAGE_DEFAULT')
      }, this.translate.instant('CONFIRM')
      ).then (res => {
          if(res.role == "ok") {
            this.submitWithoutSignature();
          }
      });
    }
    else{
      this.remoteSignatureFlowComplete = false;
      this.uiService.displayLoader({},true);
      let isReadOnly = false;
      let contactInfoObj = {title:this.contact.title, firstName:this.contact.firstName, lastName:this.contact.lastName, fullName:this.contact.fullname, fax:this.contact.fax};
      this.webSocketService.sendMessageToTopic(
      Endpoints.websockets.REMOTE_ACTION.replace('{activityId}', this.activityService.selectedActivity.ID),
      {consentTerm:this.consentTerm, channels:this.consentService.savedChannels,
        type:'consent_signature_capture', readonly:isReadOnly,
        contactID: this.contactService.contactInformation.ID,
        isProdConsent: this.authenticationService.user.isProductConsent,
        contactInfo:contactInfoObj,
      },
      true);
      this.waitingForRemoteConsentResponse = true;
      this.notificationService.notify(this.translate.instant('SIGNATURE_REQUEST_SENT'),'');
      this.remoteConsentWaitTimer = setTimeout(()=>{
        this.notificationService.notify(this.translate.instant('SIGNATURE_REQUEST_TIMEOUT'),'');
        this.waitingForRemoteConsentResponse = false;
        this.uiService.dismissLoader();
        this.webSocketService.sendMessageToTopic(Endpoints.websockets.REMOTE_ACTION.replace('{activityId}',
                                                this.activityService.selectedActivity.ID),
                                                {consentTerm:this.consentTerm, channels:this.consentService.savedChannels,
                                                  type:'remote_consent:timeout', contactID: this.contactService.contactInformation.ID
                                                },true
        );
      },60000)
    }
  }

  async submitWithoutSignature (){
    //possible to submit without signature if no feature action for Consent signature
    await this.uiService.displayLoader();
    let submittedTermId = this.consentTerm.indskr_consenttermsid;
    const currentConsentTerm = JSON.parse(JSON.stringify(this.consentTerm));
    // currentConsentTerm.indskr_signature = "";
    currentConsentTerm.products = currentConsentTerm.products.filter(
      product => product.isChecked
    );
    let channels: Channel[] = [];
    currentConsentTerm.channels.forEach(channel => {
      delete channel._id;
      delete channel._rev;
      let values: ChannelValue[] = [];
      if (channel.values) {
        channel.values.forEach(value => {
          if (value.isChecked) values.push(value);
        });
        if (values.length) {
          channel.values = values;
          channels.push(channel);
        }
      }
    });
    currentConsentTerm.createdon = new Date().getTime();
    currentConsentTerm.indskr_positionid = this.authenticationService.user.xPositionID;
    currentConsentTerm.userid = this.authenticationService.user.xSystemUserID;
    let paperConsent;
    if(this.isPaperConsentEnabled && this.consentTerm.isPaperConsent) {
      currentConsentTerm.indskr_optinsource = ConsentSource.PAPER_CONSENT;
      paperConsent = this.prepareNotePayload(currentConsentTerm);
    }

    if ((this.authenticationService.user.isProductConsent && currentConsentTerm.products.length && channels.length) || (!this.authenticationService.user.isProductConsent && channels.length)) {
      this.consentService.currentConsentTerm.next(currentConsentTerm);
      currentConsentTerm.channels = channels;

      // To check whether Product Consent flag is false for this Business Unit
      // then passed empty Product array so that in Dynamics Product value should be blank in Optin Records
      currentConsentTerm.products = this.authenticationService.user.isProductConsent ? currentConsentTerm.products : [];

      this.trackingService.tracking(
        "Submit Consent",
        TrackingEventNames.CONSENT
      );
      this.consentService.createConsentTerm(currentConsentTerm, paperConsent).then(
        async () => {
          if(this.isPaperConsentEnabled && this.consentTerm.isPaperConsent) {
            this.removeAttachment(null);
            this._sortPaperConsents();
            this.initPaperConsentHeader();
            this.consentTerm.isPaperConsent = false;
            this.tempNoteText = '';
          }
          this.consentService.allGeneratedTerms.value.forEach(term => {
            if (term.indskr_consenttermsid == submittedTermId) {
              const index = this.capturedConsentTerms.findIndex(termId => termId == submittedTermId);
              if (index < 0) this.capturedConsentTerms.push(submittedTermId);
            }
          });
          this._reloadCurrentConsentTerm(true);
          this.getConsentActivitiesByContactId_ConsentTermId();
          this.navigate();
          this.disableSubmittedChValue(submittedTermId, currentConsentTerm);

          await this.uiService.dismissLoader();

        },
        async error => {
          await this.uiService.dismissLoader();
        }
      );
    } else {
      if (this.authenticationService.user.isProductConsent) {
        this.notificationService.notify(
          this.translate.instant('CONSENT_CANT_SUBMITTED'),
          "Consent Signature",
          "top",
          ToastStyle.DANGER,
          3000,
          true
        );
      } else {
        this.notificationService.notify(
          this.translate.instant('CONSENT_CANT_SUBMITTED_PRODUCT_CONSENT_FLAG_OFF'),
          "Consent Signature",
          "top",
          ToastStyle.DANGER,
          3000,
          true
        );
      }

      await this.uiService.dismissLoader();
    }
  };

  disableSubmittedChValue(id, term) {
    let prevTerm = this.generatedTerms.find(term => term.indskr_consenttermsid == id);
    term.channels.forEach(channel => {
      if (channel.values) {
        channel.values.forEach(value => {
          if (value.isChecked) {
            prevTerm?.channels.forEach(ch => {
              if(ch.values) {
                let foundValue = ch.values.find((val) => val.value == value.value);
                if(foundValue) {
                  foundValue['block'] = true;
                  foundValue.isChecked = false;
                }
              }
            })
          }
        });
      }
    });
  }

  addContactMetaData(ch: Channel) {
    let chType: string = '';
    switch (ch.activityType) {
      case ChannelActivityType.EMAIL:
        chType = ChannelType.EMAIL;
        break;
      case ChannelActivityType.FACEBOOK:
        chType = ChannelType.FACEBOOK;
        break;
      case ChannelActivityType.SURGERY_ORDER:
        chType = ChannelType.SURGERY_ORDER;
        break;
      case ChannelActivityType.PHONE:
        chType = ChannelType.PHONE;
        break;
      case ChannelActivityType.SMS:
        chType = ChannelType.SMS;
        break;
      case ChannelActivityType.WHATSAPP:
        chType = ChannelType.WHATSAPP;
        break;
      case ChannelActivityType.VISIT:
        chType = ChannelType.VISIT;
        break;
      default:
        chType = ch.indskr_consentType;
        break;
    }

    switch (chType) {
      case ChannelType.EMAIL : case ChannelType.FACEBOOK: case ChannelType.SURGERY_ORDER:
        this.alertService.showAlert({
          title: this.translate.instant('CONSENT_ADD_EMAIL'),
          message: this.translate.instant('CONSENT_EMAIL_ADDED'),
          inputs: [
            {
              name: 'email',
              placeholder: this.translate.instant('CONSENT_ENTER_EMAIL'),
              type: 'email'
            }
          ]}, this.translate.instant('ADD')
        ).then (res => {
          if(res.role == "ok") {
            this.addEmailAddress(res.data)
          }
        });
        break;
      case ChannelType.PHONE : case ChannelType.SMS : case ChannelType.WHATSAPP:
        this.alertService.showAlert({
          title: this.translate.instant('CONSENT_ADD_NUM'),
          message: this.translate.instant('CONSENT_PHONE_NUMBER_ADDED'),
          inputs: [
            {
              name: 'phone',
              placeholder: this.translate.instant('CONSENT_ENTER_NUM')
            }
          ]}, this.translate.instant('ADD')
        ).then (res => {
          if(res.role == "ok") {
            this.addPhoneNumber(res.data)
          }
        });
        break;
      case ChannelType.VISIT: {
        this.contactService.tempContactInformation = this.contact;
        //
        if (this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING ||
          this.emailService.viewType === EmailViewType.CREATE_FROM_PHONE_CALL) {
          this.navCtrl.push(ContactNewAddressComponent, {from: PageName.ConsentDetailsComponent}, { progressAnimation: false });
        } else {
          this.navService.pushWithPageTracking(ContactNewAddressComponent, PageName.ContactNewAddressComponent,
            {from: PageName.ConsentDetailsComponent}, PageName.ConsentDetailsComponent);
        }
        //
        this.addSubscription = this.addressService.$addAddress.pipe(take(1)).subscribe(address => {
          if (address) {
            this.addCustomerAddress(address);
          }
        });
        break;
      }
      default:
    }
  }

  addCustomerAddress(data: any) {
    if (this.device.isOffline) return;

    let validateObj = this.validateFieldData(data, ChannelType.VISIT);
    if (!validateObj.isValid) {
      this.notificationService.notify(
        validateObj.message,
        "Consent Signature",
        "top",
        ToastStyle.DANGER,
        3000,
        true
      );
      return false;
    } else {
      let payload = {
        "indskr_addressid": data.addressId,
        "indskr_isprimary": !this.contact.addressesList.length
      };
      this.consentService.updateContact(this.contact, payload, ChannelActivityType.VISIT, data).then((resp:boolean)=>{
        this._reloadCurrentConsentTerm();
      });
    }
  }

  addEmailAddress(data: any) {
    let validateObj = this.validateFieldData(data.values, ChannelType.EMAIL);
    if (!validateObj.isValid) {
      this.notificationService.notify(
        validateObj.message,
        "Consent Signature",
        "top",
        ToastStyle.DANGER,
        3000,
        true
      );
      return false;
    } else {
      let payload = {
        "indskr_emailaddress": data.values.email,
        "indskr_isprimary": !this.contact.emailAddressList.length
      };
      this.consentService.updateContact(this.contact, payload, ChannelActivityType.EMAIL).then((resp:boolean)=>{
        this._reloadCurrentConsentTerm();
      });
    }
  }

  validateFieldData(data, type) {
    if (type == ChannelType.EMAIL) {
      if (data.email) {
        let email: Email = this.contact.emailAddressList.find(email => email.emailAddress == data.email);
        if (email) {
          return {
            isValid: false,
            message: this.translate.instant('CONTACT_EMAIL_EXISTS')
          };
        }
        const customEmailPattern = /^(([^-_#$%<>()\[\]\\.,;:\s@"]+([._-][^-_#$%<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*)*\.[a-zA-Z]{2,})|localhost|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})))$/;
        const emailValue = data.email;
        const isValid = customEmailPattern.test(emailValue);

        if (isValid) {
          return {
            isValid: true,
            message: ''
          };
        } else {
          return {
            isValid: false,
            message: this.translate.instant('CONSENT_ENTERED_INVALID_EMAIL')
          };
        }
      } else {
        return {
          isValid: false,
          message: this.translate.instant('CONSENT_EMAIL_CANT_EMPTY')
        }
      }
    } else if (type == ChannelType.PHONE) {
      if (data.phone) {
        let phone: boolean = (this.contact.mobilePhone == data.phone
          || this.contact.telephone == data.phone
          || this.contact.indskr_alternatephone1 == data.phone
          || this.contact.indskr_alternatephone2 == data.phone
          || this.contact.indskr_alternatephone3 == data.phone);
        if (phone) {
          return {
            isValid: false,
            message: this.translate.instant('CONSENT_NUM_EXISTS')
          };
        }
        if (this.contact.indskr_alternatephone1
          && this.contact.indskr_alternatephone2
          && this.contact.indskr_alternatephone3) {
          return {
            isValid: false,
            message: this.translate.instant('CONSENT_3_NUM_CAN_ADD')
          };
        }
        if( /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(data.phone) ){
          return {
            isValid: true,
            message: ''
          };
        } else {
          return {
            isValid: false,
            message: this.translate.instant('CONSENT_ENTERED_AN_INVALID_NUM')
          };
        }
      } else {
        return {
          isValid: false,
          message: this.translate.instant('CONSENT_PHONE_CANT_EMPTY')
        }
      }
    } else if (type == ChannelType.VISIT) {
      let conAddress: ContactAddress = this.contact.addressesList.find(ad => ad.addressId == data.addressId);
      if (!conAddress) {
        return {
          isValid: true,
          message: ''
        };
      } else {
        return {
          isValid: false,
          message: this.translate.instant('CONSENT_ADDRS_EXISTS')
        };
      }
    }
  }

  addPhoneNumber(data: any) {
    let validateObj = this.validateFieldData(data.values, ChannelType.PHONE);
    if (!validateObj.isValid) {
      this.notificationService.notify(
        validateObj.message,
        "Consent Signature",
        "top",
        ToastStyle.DANGER,
        3000,
        true
      );
      return false;
    } else {
      let payload = {};
      if (!this.contact.indskr_alternatephone1) {
        payload['field'] = 'indskr_alternatephone1';
        payload['value'] = data.values.phone;
      } else if (!this.contact.indskr_alternatephone2) {
        payload['field'] = 'indskr_alternatephone2';
        payload['value'] = data.values.phone;
      } else if (!this.contact.indskr_alternatephone3) {
        payload['field'] = 'indskr_alternatephone3';
        payload['value'] = data.values.phone;
      }
      this.consentService.updateContact(this.contact, payload, ChannelActivityType.PHONE).then((resp:boolean)=>{
        this._reloadCurrentConsentTerm();
      });
    }
  }

  getActivityType(aTYpe){
    return aTYpe ? ChannelActivityType[aTYpe].toUpperCase() : "";
  }

  hideButton(activityType): boolean {
    let hide: boolean = true;
    if (activityType == ChannelActivityType.EMAIL
      || activityType == ChannelActivityType.PHONE
      || activityType == ChannelActivityType.VISIT
      || activityType == ChannelActivityType.SMS
      || activityType == ChannelActivityType.WHATSAPP
      || activityType == ChannelActivityType.FACEBOOK
      || activityType == ChannelActivityType.SURGERY_ORDER) {
      hide = false;
    }
    if (this.consentTerm.isSavedConsent) hide = true;
    return hide;
  }

  disableAddButtonOffline(activityType): boolean {
    let isDisabled: boolean = false;
    if (this.device.isOffline && activityType == ChannelActivityType.VISIT) {
        isDisabled = true;
    }
    return isDisabled;
  }

  getChannelName(channel) {
    let ch: Channel = this.consentChannels.find(sch => sch.indskr_consenttypeid === channel.indskr_consenttypeid);
    let consentType = _.isEmpty(ch) ? channel.indskr_consentType : ch.indskr_consentType;
    // return (ch) ? ch.indskr_consentType : '';
    if (!_.isEmpty(consentType)) {

      let translateddisplayName: string = "";

      switch (consentType) {
        case 'Email':
          translateddisplayName = this.translate.instant('EMAIL');
          break;
        case 'Address':
          translateddisplayName = this.translate.instant('ADDRESS');
          break;
        case 'Phone':
          translateddisplayName = this.translate.instant('PHONE');
          break;
        case 'SMS':
          translateddisplayName = this.translate.instant('SMS');
          break;
        case 'Visit':
          translateddisplayName = this.translate.instant('VISIT');
          break;
        case 'WhatsApp':
          translateddisplayName = this.translate.instant('WHATSAPP');
          break;
        case 'Facebook':
          translateddisplayName = this.translate.instant('FACEBOOK');
          break;
        case 'Fax':
          translateddisplayName = this.translate.instant('FAX');
          break;
        case 'Campaign':
          translateddisplayName = this.translate.instant('CAMPAIGN');
          break;
        case 'Allocation orders':
        case 'Allocation Orders':

          translateddisplayName = this.translate.instant('ALLOWCATION_ORDERS');

        break;
        default:
          return consentType;
          break;
      }
      return translateddisplayName;
    }
  }

  displayAddButtonText(chType): string {
    switch (chType) {
      case ChannelActivityType.EMAIL:
      case ChannelActivityType.FACEBOOK:
      case ChannelActivityType.SURGERY_ORDER:
        return this.translate.instant('ADD_EMAIL');
      // case ChannelActivityType.PHONE.toLowerCase():
      case ChannelActivityType.PHONE:
      case ChannelActivityType.WHATSAPP:
      case ChannelActivityType.SMS:
        return this.translate.instant('ADD_PHONE_NUMBER');
      case ChannelActivityType.VISIT:
        return this.translate.instant('ADD_ADDRESS');
      default:
    }
  }

  disableAddButtonForOneKeyContact(chType): boolean {

    // To check first whether the contact selected is OneKey
    // then proceed with individual checking of BU flags to Add Email, PhoneNumber & Address
    if(this.isOneKeyContact){
      switch (chType) {
        case ChannelActivityType.EMAIL:
        case ChannelActivityType.FACEBOOK:
        case ChannelActivityType.SURGERY_ORDER:
          return this.authenticationService.user.isConsentAddNewEmailDisabled;
        case ChannelActivityType.PHONE:
        case ChannelActivityType.WHATSAPP:
        case ChannelActivityType.SMS:
          return this.authenticationService.user.isConsentAddNewPhoneNumberDisabled;
        case ChannelActivityType.VISIT:
          return this.authenticationService.user.isConsentAddNewAddressDisabled;
        default:
          return false;
      }
    }
    // To check Add button for non-OneKey Contacts
    else {
       // To check first Feature action and channel type is Address
      return this.isConsentAddAddressDisabled && chType === ChannelActivityType.VISIT ? true : false ;
    }
  }

  showMessageConsentCannotAddDetailsForOneKeyContact() {

    // To check first whether the contact selected is OneKey
    // then proceed with individual checking of BU flags to Add Email, PhoneNumber & Address
    if(this.isOneKeyContact){
      let channelActivityTypesArr = [];

      this.consentTerm.channels.forEach(channel => {
        if ((channel.activityType == ChannelActivityType.EMAIL
            || channel.activityType == ChannelActivityType.FACEBOOK
            || channel.activityType == ChannelActivityType.SURGERY_ORDER)
            && this.authenticationService.user.isConsentAddNewEmailDisabled) {
            channelActivityTypesArr.push(this.translate.instant('EMAIL'));
         }

        if ((channel.activityType == ChannelActivityType.PHONE
            || channel.activityType == ChannelActivityType.WHATSAPP
            || channel.activityType == ChannelActivityType.SMS)
            && this.authenticationService.user.isConsentAddNewPhoneNumberDisabled) {
            channelActivityTypesArr.push(this.translate.instant('PHONE'));
          }

        if ((channel.activityType == ChannelActivityType.VISIT)
            && this.authenticationService.user.isConsentAddNewAddressDisabled) {
            channelActivityTypesArr.push(this.translate.instant('ADDRESS'));
          }

      });

      if(!_.isEmpty(channelActivityTypesArr)) {
        let channelActivityTypesStr = `${channelActivityTypesArr.join(", ")} ${channelActivityTypesArr.length === 1 ? `${this.translate.instant('NEW_ACTIVITY_CHANNEL').toLowerCase()}`: `${this.translate.instant('CHANNELS').toLowerCase()}`}`;

        this.notificationService.notify(
          this.translate.instant('ALERT_CONSENT_CANNOT_ADD_DETAILS',{ channelActivityTypes : channelActivityTypesStr }),
          "Cannot Add Details",
          "top",
          ToastStyle.INFO,
          3000,
          true
        );
      }
    }
  }
  private _replacePropertyInTag(contentBody: any): any {
    return this.activityService.replacePropertyInTag(contentBody);
  }

  openUrl(event:any) {
    if (this.device.isOffline && !event || !event.target) return;
    const target = event.target;
    this.activityService.openExternalLink(target);
  }

  private _initFooter() {
    if(this.consentService.inMeetingConsentFlow){
      this.footerService.initButtons(FooterViews.PREVIEW_MEETING_ACTIVITY);
      this.IcurrentMeetingActivitySubscription = this.meetingStructureService.selectedMeetingActivityObserver.subscribe(activity => {
        this.selectedMeetingActivity = activity;
      });
    }else {
      if(this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent
        || this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent
        || this.navService.getCurrentMasterPageName() == PageName.ScientificActivityPage
        || this.navService.getCurrentMasterPageName() == PageName.PresentationMeetingComponent
        || this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent) {
        this.footerService.initButtons(FooterViews.ConsentDetails);
      } else if (this.uiService.activeView === 'Email') {
        this.footerService.initButtons(FooterViews.None);
      } else {
        this.footerService.initButtons(FooterViews.ConsentDetails);
      }
    }
  }

  public _reloadCurrentConsentTerm(highlightNextTerm?: boolean) {
    // this.generatedTerms = _.orderBy(this.consentTermGenerateService.generateConsentTerms(), ['indskr_consentTermName','asc']);
    this.generatedTerms = _.orderBy(this.consentService.allGeneratedTerms.value, ['indskr_consentTermName','asc']);
    const currentConsentTermId = this.consentTerm.indskr_consenttermsid;
    if(!_.isEmpty(this.generatedTerms)) {
      let idx = this.generatedTerms.findIndex(ut=>ut.indskr_consenttermsid == currentConsentTermId);
      if(idx > -1) {
        this.consentTerm = this.generatedTerms[idx];

        if(highlightNextTerm) {
          //Set value index of next Consent term
          let nextConsentTermIndex = idx + 1;
          let nextConsentTerm = this.generatedTerms[idx];

        //To set Next consent term in Sequence and set first if selected one is last
          if (nextConsentTermIndex > -1 && nextConsentTermIndex < this.generatedTerms.length) {
            nextConsentTerm = this.generatedTerms[nextConsentTermIndex];
          } else {
            nextConsentTerm = this.generatedTerms[0];
          }

          this.consentTerm.isSelectedTerm = true;
          this.consentService.selectedConsentTerm.next(nextConsentTerm);
          // const generatedTerms = this.consentTermGenerateService.generateConsentTerms();
          const generatedTerms = this.consentService.allGeneratedTerms.value;
          this.consentService.allGeneratedTerms.next(generatedTerms);
          this.generatedTerms = generatedTerms;
          this.updateTermSelectionStatus();
          this.isLimitedView = false;
          this.isViewLess = true;
          this.getTermsHeader();
        }else {
          this.consentTerm.isSelectedTerm = true;
        }
      }
    }
  }

  public getConsentActivitiesByContactId_ConsentTermId() {
    if (!_.isEmpty(this.consentService.allConsentActivitiesWithProductsAndEmails)) {
      console.warn(`Filter Consent Activities By Contact Id & ConsentTerm Id`);
      this.consentActivitiesByContactId_ConsentTermId = this.consentService.allConsentActivitiesWithProductsAndEmails.filter(
        item => item.contactId === this.consentTermGenerateService.contact.ID &&
        this.consentTerm.indskr_consenttermsid);
    }
  }

  private handleNoteAttachment(){
    if(this.isPaperConsentEnabled){
      try {
        (document.getElementById('file-input') as any).click();
      } catch (error) {
        console.log(error);
      }
    }
  }

  public notesChanged(ev): void {
    if(ev && ev.target){
      this.tempNoteText = ev.target.value;
    }else{
      this.tempNoteText = '';
    }
    // if(this.tempNoteText.length > 0 || this.attachmentFile){
    if(this.attachmentFile){
      this.isSaveNotesEnabled = true;
    }else{
      this.isSaveNotesEnabled = false;
    }
    this.initPaperConsentHeader();
  }

  public async savePaperConsent() {
    if(!this.consentService.consentRemoteFlow){
      this.alertService.showAlert({
        title: this.translate.instant('CONSENT_CONFIRM'),
        message: this.consentTerm.indskr_confirmationmessage?this.consentTerm.indskr_confirmationmessage:this.translate.instant('CONSENT_CONFIRMATION_MESSAGE_DEFAULT')
      }, this.translate.instant('CONFIRM')
      ).then (res => {
          if(res.role == "ok") {
            this.submitWithoutSignature();
          }
      });
    }
  }

  private prepareNotePayload(term){
    if (this.isSaveNotesEnabled && (!_.isEmpty(this.base64str))) {
      this.isSaveNotesEnabled = false;
      let offlinePayload = {
        "objecttypecode" : "consent",
        createdon: term.createdon.toString(),
        notetext: this.tempNoteText ? this.tempNoteText : "",
        ownerid: this.authenticationService.user.systemUserID,
        deleted: false,
      }
      if(this.base64str){
        offlinePayload['isdocument'] = true;
        offlinePayload['documentbody'] = this.base64str;
        offlinePayload['filename'] = this.attachmentFile.name;
        offlinePayload['filesize'] = this.attachmentFile.size;
        offlinePayload['mimetype'] = this.attachmentFile.type;
      }else{
        offlinePayload['isdocument'] = false;
      }
      return offlinePayload;
    } else {
      return '';
    }
  }

  public async loadImageFromDevice(event){
    if(event && event.target && event.target.files){
      try {
        if(NOTE_ATTACHMENT_MIME_TYPES_VIDEO_RESTRICTED_REGEX.test(event.target.files[0].name)) {
          this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'),'Consent Details','top','info');
          this.removeAttachment(false);
        }else if((event.target.files[0].size/1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE){
          if(NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(event.target.files[0].name)){
            this.attachmentFile = event.target.files[0];
            this.attachmentTitle = event.target.files[0].name;
            this.isAttachmentAdded = true;
            this.base64str = await toBase64(this.attachmentFile);
            const dataURLPattern = /^data:[^;]+;base64,/;
            this.base64str = this.base64str.replace(dataURLPattern, '');
            this.isSaveNotesEnabled = true;
            this.initPaperConsentHeader();
          }else{
            this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'),'Consent Details','top','info');
            this.removeAttachment(false);
          }
        }else{
          this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION',{size:MAXIMUM_NOTE_ATTACHMENT_SIZE}),'Consent Details','top','info');
          this.removeAttachment(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  public isNoteControlsEnabled(note: IONote): boolean {
    return !!(note.ownerId == this.authenticationService.user.systemUserID) && !this.device.isOffline && note.noteId != 'description-note' && !this.consentTerm.isSavedConsent;
  }

  public async updateNote(ev) {
    if (ev && ev.action) {
      let idx = this.consentTerm.annotations.findIndex(note => note.noteId == ev.noteId);
      let payload = {
        "annotationid" : this.consentTerm.annotations[idx].noteId,
        "objecttypecode" : "consent",
        "consentTermId" : this.consentTerm.indskr_consenttermsid
      };
      if (ev.action == 'DELETE' && idx >= 0) {
        payload['deleted'] = true;
      } else if (ev.action == 'SAVE' && idx >= 0) {
        payload['notetext'] = ev.updatedText;
        if(ev.attachmentFileUpdated){
          payload['isdocument'] = true;
          payload['documentbody'] = ev.attachmentFileDataUrl;
          payload['filename'] = ev.documentName;
          payload['filesize'] = ev.documentSize;
          payload['mimetype'] = ev.documentMimeType;
        }else if(ev.isAttachmentRemoved){
          payload['isdocument'] = false;
          payload['documentbody'] = '';
          payload['filename'] = '';
          payload['filesize'] = 0;
          payload['mimetype'] = '';
        }
      }
      // Update Service
      this._sortPaperConsents();
      if (!this.device.isOffline) {
        this.uiService.displayLoader();
        // let res = await this.opportunityDataService.updateOpportunityNoteOnline(payload,this.opportunity.ID);
        // if(res && res['annotationid']){
        //   if (ev.action == 'DELETE' && idx >= 0) {
        //     this.report.annotations.splice(idx, 1);
        //   } else if (ev.action == 'SAVE' && idx >= 0) {
        //     this.report.annotations[idx].noteText = ev.updatedText;
        //     if(ev.attachmentFileUpdated){
        //       this.report.annotations[idx].hasDocument = true;
        //       this.report.annotations[idx].documentDataURL = '';
        //       this.report.annotations[idx].documentName = ev.documentName;
        //       this.report.annotations[idx].documentSize = ev.documentSize;
        //       this.report.annotations[idx].documentMimeType = ev.documentMimeType;
        //     }else if(ev.isAttachmentRemoved){
        //       this.report.annotations[idx].hasDocument = false;
        //       this.report.annotations[idx].documentDataURL = '';
        //       this.report.annotations[idx].documentName = '';
        //       this.report.annotations[idx].documentSize = 0;
        //       this.report.annotations[idx].documentMimeType = '';
        //     }
        //   }
        //   await this._updateOpportunityNoteInDB('UPDATEOPPORTUNITYNOTE');
        //   this.uiService.dismissLoader();
        // }
      }
    }
  }

  private _sortPaperConsents(){
    if(this.consentTerm.annotations && this.consentTerm.annotations.length > 1){
      this.consentTerm.annotations = this.consentTerm.annotations.sort((a,b)=>{
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1);
      });
    }
  }

  public togglePaperConsent() {
    if(this.consentTerm.isPaperConsent && this.device.isOffline) {
      this.notificationService.notify(this.translate.instant('CANT_UPLOAD_PAPER_CONSENT_OFFLINE'),'Consent Info','top','info');
      return;
    }
    this.initPaperConsentHeader();
  }

  private resetPaperConsent() {
    this.consentTerm.annotations = [];
    this.consentTerm.isPaperConsent = false;

  }
}
