<ion-grid no-padding [ngClass]="(!!navService.isActiveChildNavRightPaneViewDisplayed)?'preview-active':'preview-inactive'">
    <ion-row no-padding>
        <ion-col class="main-tool-template-left-pane" [ngClass]="(viewData.isSearchEnabled)?'searchActive':'searchInactive'" no-padding>
            <ion-header>
                <ind-header-left [viewData]="indHeaderLeftModel" [hideFilterButton]="!viewData.isFilterPopoverActive && !viewData.isFilterMenuActive" [searchDisabled]="!viewData.isSearchEnabled" [(searchText)]="searchInput"
                    (onControlClick)="onPageTitleControlClick($event)" 
                    (onSearchInput)="searchText($event.detail)" 
                    (onSearchClear)="clearSearch($event.detail)"
                    (onFilterClick)="openFilters($event)"></ind-header-left>
              <!-- Tab View -->
              <ion-toolbar no-padding *ngIf="viewData.isTabEnabled">
                <ind-tabs [data]="viewData.tabData" [(selectedTab)]="viewData.isFor"
                  (selectedTabChange)="changeTab($event)"></ind-tabs>
              </ion-toolbar>
            </ion-header>
            <!-- Key Controls Area -->
            <ind-key-controls-area id="mainToolKeyControl" *ngIf="mainToolTemplateKeyControlAreaModel" [viewData]="mainToolTemplateKeyControlAreaModel"> </ind-key-controls-area>
            <ion-content [ngClass]="{'vectorimageleft' : !searchInput && listData.length <= 0}">
                <ion-list *ngIf="viewData" [ngClass]="{'margin-bottom-above-fab': footerService.shouldApplyBottomMargin(true)}">
                    <!-- Selected Items View -->
                    <!-- <ion-item-divider sticky='true' *ngIf="selectedItems.length>0">{{ 'SELECTED_CAP' | translate}} ({{selectedItems.length}})</ion-item-divider> -->
                    <!-- <ind-section-header *ngIf="selectedItems.length>0 && (navService.getPreviousPageName() !== 1)" [viewData]='{id:"tool_selectedlist_header",title: translate.instant("SELECTED_CAP") + " ("+selectedItems.length+")"}'></ind-section-header> -->
                    <ind-section-header *ngIf="selectedItems.length>0" [viewData]='sectionHeader' (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
                    <ng-container *ngIf="isSelectedSectionExpanded">
                      <main-card *ngFor="let item of selectedItems" [viewData]="item"></main-card>
                    </ng-container>                    
                    <ion-item class="seperator" *ngIf="!isSelectedSectionExpanded"></ion-item>
                    <!-- All Items View -->
                    <ng-container *ngIf="!viewData.hideAllItemsList">
                        <!-- <ion-item-divider *ngIf = "!searchActive" sticky='true'>{{viewData.dividerTitle | translate}} ({{filteredData.length}})</ion-item-divider>
                        <ion-item-divider *ngIf = "searchActive" sticky='true'>
                            <span class="filterStyleHeading"> {{'RESULTS'}} ({{filteredData.length}}) </span>
                        </ion-item-divider> -->
                        <ind-section-header [viewData]='{id:"tool_list_header",title: (!searchActive? (translate.instant(viewData.dividerTitle))
                        :translate.instant("AL_RESULTS"))+" ("+(!searchActive && isGroupedView?childrenLength:isHierarchyView?totalLength: filteredData.length)+")"}'></ind-section-header>
                        <ng-container *ngFor="let item of itemsToDisplay">
                          <main-card [ngClass]="{'indent':this.isGroupedView && item.showEndIcon}" [viewData]="item"></main-card>
                          <ind-hierarchy-list-item [hidden]="!item.isExpanded" *ngIf="item.childItems" [viewData]="item.childItems"></ind-hierarchy-list-item>
                        </ng-container>

                    </ng-container>
                </ion-list>
                <ion-infinite-scroll (ionInfinite)="doInfinite($event.detail,$event)" threshold="30%">
                  <ion-infinite-scroll-content></ion-infinite-scroll-content>
                </ion-infinite-scroll>
                <ind-no-search-result *ngIf="searchInput && filteredData.length === 0" [text]="('NO_RESULTS_FOR_THIS_SEARCH' | translate)"></ind-no-search-result>
            </ion-content>
            <!-- <ion-footer> -->
                <footer-toolbar [footerView]="'masterView'" [selectedView]="'select-list'"></footer-toolbar>
            <!-- </ion-footer> -->
        </ion-col>
        <ion-col class="main-tool-template-right-pane" no-padding>
          <ion-nav #maintooltemplaterightpane></ion-nav>
        </ion-col>
    </ion-row>
</ion-grid>