
import {of as observableOf, throwError as observableThrowError, forkJoin as observableForkJoin,  Subject, ReplaySubject ,  Observable, firstValueFrom, forkJoin } from 'rxjs';

import {mergeMap,  catchError, flatMap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx'
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { DeviceService } from "../device/device.service";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { LogService } from "../logging/log-service";
import { PresentationService } from '../presentation/presentation.service';
import { Store } from "@ngrx/store";
import * as FileAction from '../../store/io-file-service/actions/file.actions';
import { fileManagerState } from '../../store/io-file-service/states/file.state';
import * as path from 'path';
import { Platform } from "@ionic/angular";
import { electronApi } from "../electron-api";
import { Resource } from "../../classes/resource/resource.class";
import { ResourceService } from "../resource/resource.service";
import { NotificationService } from "../notification/notification.service";
import { TranslateService } from "@ngx-translate/core";
import { UIService } from '../ui/ui.service';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { AuthenticationService } from '../authentication.service';
import { Endpoints } from '../../../config/endpoints.config';

declare var cordova:any;

@Injectable({
  providedIn: 'root'
})
export class IoFileService {

    //Progress update data
    private _dlProgressSubject: Subject<number | null> = new ReplaySubject<number | null>(1);
    private _unzipProgressSubject: Subject<number | null> = new ReplaySubject<number | null>(1);
    public dlProgress$: Observable<number | 0>;
    public unzipProgress$: Observable<number | 0>;

    private dataStorageDirectory: string;
    private presDirectory = '/presentations';
    private resourceDirectory: string = '/resources';

    constructor(
        private fs: File,
        private ft: FileTransfer,
        private logService: LogService,
        private device: DeviceService,
        private presentationService: PresentationService,
        private platform: Platform,
        private store: Store<fileManagerState>,
        private resourceService: ResourceService,
        public translate: TranslateService,
        private notificationService: NotificationService,
        private uiService: UIService,
        private fileOpener: FileOpener,
        private authService: AuthenticationService,
        private http: HttpClient
    ) {
        this.dlProgress$ = this._dlProgressSubject.asObservable();
        this.unzipProgress$ = this._unzipProgressSubject.asObservable();
        this.initializeDirectory();
    }

    private resolveLocalUrl(url: string) {
        return [this.dataStorageDirectory.replace(/\/$/, ""), url.replace(/^\//, '')].join('/');
    }

    private async createDir(dir: string) {
        dir = this.resolveLocalUrl(dir);
        if (this.device.deviceFlags.electron) {
            return await electronApi.createDir(dir)
        }
        return await this.fs.createDir(path.dirname(dir), path.basename(dir), false);
    }

    private async deleteFile(file: string) {
        file = this.resolveLocalUrl(file);
        if (this.device.deviceFlags.electron) {
            return await electronApi.removeFile(file);
        }
        return await this.fs.removeFile(path.dirname(file), path.basename(file));
    }

    private async deleteDir(dir: string) {
        dir = this.resolveLocalUrl(dir);
        if (this.device.deviceFlags.electron) {
            await electronApi.removeRecursively(dir);
            return;
        }
        let entry: any = await this.fs.resolveLocalFilesystemUrl(dir);
        await entry.removeRecursively();
    }

    //#region Download to saving process

    public async download(src: string, dest: string, progress: (event?) => void, mime, resourcePath?: string) {
        dest = this.resolveLocalUrl(dest);
        if (!this.device.deviceFlags.electron) {
            let headers = new HttpHeaders();
            if (mime) {
                headers = headers.set('Content-Type', mime);
                headers = headers.set('Accept', mime);
            }
            const requestOptions: any = {};
            requestOptions.headers = headers;
            requestOptions.responseType = 'arraybuffer';
            requestOptions.reportProgress = true;
            let ft = this.ft.create();
            ft.onProgress(event => progress(event));
            await ft.download(src, dest, true, requestOptions)
        } else {
            if (resourcePath) await this.createDir(resourcePath);
            let response = await fetch(src, {
                headers: mime ? new Headers({
                    'Content-Type': mime,
                    'Accept': mime,
                }
                ) : undefined,
            });
            if (!response.ok) {
                throw Error(`Unable to download, server returned ${response.status} ${response.statusText}`);
            }
            const body = response.body;
            if (body == null) {
                throw Error('No response body');
            }

            const finalLength = length || parseInt(response.headers.get('Content-Length' || '0'), 10);
            const reader = body.getReader();
            const writer = electronApi.createWriteStream(dest);
            try {
                let bytesDone = 0;

                while (true) {
                    const result = await reader.read();
                    if (result.done) {
                        if (progress != null) {
                            progress({ total: finalLength, loaded: finalLength });
                        }
                        break;
                    }

                    const chunk = result.value;
                    if (chunk == null) {
                        throw Error('Empty chunk received during download');
                    } else {
                        writer.write(Buffer.from(chunk));
                        if (progress != null) {
                            bytesDone += chunk.byteLength;
                            progress({ total: finalLength, loaded: bytesDone });
                        }
                    }
                }
            }
            finally {
                writer.close();
            }
        }
        this.logService.logEvent(`${src} downloaded as ${dest}`);
        return true;
    }

    async unzip(src, dest, progress?) {
      src = this.resolveLocalUrl(src);
      dest = this.resolveLocalUrl(dest);
      return new Promise((res, rej) => {
        let zip = (window as any).zip;
        if (!zip) {
          rej("zip is not defined");
          return;
        }
        zip.unzip(src, dest, result => {
          if (result === 0) {
            res(true);
          } else {
            rej("presentation UNZIP failed");
          }
        }, progress);
      });
    }

    private async initializeDirectory() {
        if (this.dataStorageDirectory) return;
        await this.platform.ready();
        if (this.device.deviceFlags.electron) {
            this.dataStorageDirectory = electronApi.getPath('userData');
        } else {
            this.dataStorageDirectory = this.fs.externalDataDirectory || this.fs.dataDirectory || this.fs.tempDirectory || this.fs.cacheDirectory;
        }
        console.log('Data storage', this.dataStorageDirectory);
        if (!this.dataStorageDirectory || this.dataStorageDirectory == null || this.dataStorageDirectory == undefined) {
            this.logService.logError('dataStorageDirectory is undefined');
            return;
        }

        await this.createDir(this.presDirectory);
        await this.createDir(this.resourceDirectory);
    }

    //#region Update progress bar

    updateDowloadProgress = (progress?: ProgressEvent) => {
        this._dlProgressSubject.next(progress ? Math.round((progress.loaded / progress.total) * 100) : 0);
    }

    updateUnzipProgress = (progress: any) => {
        this._unzipProgressSubject.next(Math.round((progress.loaded / progress.total) * 100));
    }

    resetProgress() {
        this._dlProgressSubject.next(null);
        this._unzipProgressSubject.next(null);
    }

    //#endregion End of update region


    downloadPresentation = (presId: string): Observable<any> => {

        // this.activeDownload = presentationId;
        // activeDownloadSubject.next(presentationId);
        let pres = this.presentationService.getPresObject(presId);

        return observableForkJoin(
            this.download(pres.zipUrl, path.join(this.presDirectory, presId + ".zip"), progress => this.updateDowloadProgress(progress), 'application/zip')
                .catch(err => {
                    console.log("presentation zip failed", err)
                    return Promise.reject(err);
                }),
            this.download(pres.thumbnailZipUrl, path.join(this.presDirectory, presId + "_thumbnail.zip"), progress => this.updateDowloadProgress(progress), 'application/zip')
                .catch(err => {
                    console.log("thumbnail zip failed", err)
                    return Promise.reject(err);
                }),
            this.download(pres.thumbnailUrl, path.join(this.presDirectory, presId + '/pres_thumbnail', pres.thumbnailUrl.substr(pres.thumbnailUrl.lastIndexOf('/') + 1)), progress => this.updateDowloadProgress(progress), 'application/zip', path.join(this.presDirectory, presId + '/pres_thumbnail'))
            .catch(err => {
              console.log("presentation thumbnail failed", err);
                return Promise.resolve(true);
            })
        ).pipe(
            flatMap(() => {
                this.store.dispatch(new FileAction.downloadFileSuccess({ presentationId: presId }));
                return this.unzipPresentationNative(presId);
            }),
            catchError(err => {
                this.logService.logError("Download failed", err);
                this.store.dispatch(new FileAction.downloadFileDequeue());
                return observableThrowError(err);
            }),
        );
    }

    unzipPresentationNative = (presId: string): Observable<any> => {
        let presPath = path.join(this.presDirectory, presId);
        this.store.dispatch(new FileAction.unZipFile());

        const obs_cb = observableForkJoin(
            this.unzip(presPath + ".zip", presPath, (progress) => this.updateUnzipProgress(progress)).catch(
                function (rs) {
                    throw observableThrowError(rs.http_status);
                }
            ),
            this.unzip(presPath + "_thumbnail.zip", presPath + "/thumbnail").catch(
                function (rs) {
                    throw observableThrowError(rs.http_status);
                }
            )
        );

        return obs_cb.pipe(mergeMap(() => this.deleteZipFile(presId)));
    };

    //#endregion Download to saving process


    downloadFailedDelete = (presId: string) => {

        console.log("DELETE FAILED DOWNLOAD");
        this.resetProgress();
        // this.notificationService.notify( "Failed to download presentation " + pres.name, "io-file-service", "top", ToastStyle.DANGER, 3000, true);
        this.store.dispatch(new FileAction.downloadFileDequeue());
    }

    private convertFileSrc(str: string) {
        if (this.device.deviceFlags.electron) {
            return electronApi.convertFileSrc(str);
        }
        str = this.resolveLocalUrl(str);
        let ionicConvertFileSrc = (((<any>window || {}).Ionic || {}).WebView || {}).convertFileSrc;
        if (ionicConvertFileSrc) {
            return ionicConvertFileSrc(str);
        }
        return str;
    }

    getLocalURL(fn: string, presenataionId?: string) {
        if (!this.presentationService.activePresentation && this.presentationService.activePresentation instanceof Resource) return "";
        return this.convertFileSrc(path.join(this.presDirectory, presenataionId? presenataionId : this.presentationService.activePresentation['ioPresentationId'], fn));
    }

    getLocalURLForResource(resourceId: string, fn: string) {
        return this.convertFileSrc(path.join(this.resourceDirectory, resourceId, fn));
    }

    getLocalURLDocument = (fn: any): any => {
        return this.convertFileSrc(fn);
    }

    deleteZipFile = (presId: string): Observable<any> => {
        // activeDownloadSubject.next(null);
        this.resetProgress();
        return observableForkJoin(
            this.deleteFile(path.join(this.presDirectory, presId + ".zip")).catch(
                function () {
                    console.log("Presentation zip DELETE failed");
                    return observableOf(null);;
                }
            ),
            this.deleteFile(path.join(this.presDirectory, presId + "_thumbnail.zip")).catch(
                function () {
                    console.log("thumbnail zip DELETE failed");
                    return observableOf(null);
                }
            )
        );
    }
    async deleteDownloadedPres(presId: string) {
        try {
            this.deleteDir(path.join(this.presDirectory, presId));
        } catch (ex) {
            console.error('deleteDownloadedPres: Downloaded Presentation DEL Fail!!', ex)
        }
    }


    public downloadDocuments(url, fileName: string): Observable<any> {
        const rs: Observable<any> = observableForkJoin(
            this.download(url, fileName, (progress) => this.updateDowloadProgress(progress), 'application/pdf').catch(
                function (err) {
                    console.log("Download Failed");
                    console.log(err);
                    throw observableThrowError(err);
                    // return err;
                }
            ),

        )

        return rs;
    }

    downloadResource = (resourceId: string): Observable<any> => {
        let resource: Resource = this.resourceService.getResourceById(resourceId, resourceId);
        console.log("Downloading resource : " + resource.title);
        let downloadList = [];
        const file: string = resource.assetURL.substr(resource.assetURL.lastIndexOf('/') + 1);
        const resourcePath = path.join(this.resourceDirectory, resourceId);
        downloadList.push(this.download(resource.assetURL, path.join(resourcePath, file), progress => this.updateDowloadProgress(progress), 'application/zip', resourcePath)
            .catch(err => {
                console.log("resource download failed for " + resource.title, err);
                return Promise.resolve(false);
            }));
        if (resource.thumbnailURL) {
            const thumbnailFile: string = resource.thumbnailURL.substr(resource.thumbnailURL.lastIndexOf('/') + 1);
            downloadList.push(this.download(resource.thumbnailURL, path.join(resourcePath + '/thumbnail', thumbnailFile), progress => this.updateDowloadProgress(progress), 'application/zip', resourcePath + '/thumbnail')
                .catch(err => {
                    console.log("resource thumbnail download failed for " + resource.title, err);
                    resource.thumbnailFailed = true;
                    return Promise.resolve(true);
                }));
        }
        return observableForkJoin(...downloadList)
            .pipe(
                catchError(err => {
                    this.logService.logError("Download Resource failed", err);
                    return observableThrowError(err);
                }),
            );
    }

    async deleteDownloadedResource(resourceId: string) {
        try {
            this.deleteDir(path.join(this.resourceDirectory, resourceId));
        } catch (ex) {
            console.error('Delete downloaded resource failed --> res id : ' + resourceId, ex);
        }
    }

    async deleteAllDownloadedResources() {
        console.log('Deleting all downloaded resources');
        try {
            this.deleteDir(this.resourceDirectory);
        } catch (ex) {
            console.error('Error occurred while deleting all downloaded resources', ex);
        }
    }

    async writeFileBufferToSharedDirectory(fileName: string, buffer: ArrayBuffer, option: { replace: boolean } = { replace: false }, directory?: string): Promise<any> {
        let _directory;
        if (directory) {
            _directory = directory;
        } else if (this.device.deviceFlags.ios) {
            _directory = this.fs.documentsDirectory;
        } else if (this.device.deviceFlags.android) {
            _directory = `${this.fs.externalRootDirectory}/Download/`;
        }

        if(!option.replace){
          fileName = await this.createUniqueName(_directory, fileName);
        }

        if (_directory && fileName && buffer) {
            try {
                // Replace if same file name exists.
                const res = await this.fs.writeFile(_directory, fileName, buffer, option);
                return res;
            } catch (error) {
                throw error;
            }
        } else {
            console.error(`writeFileBufferToSharedDirectory: fileName: ${fileName}, directory: ${_directory}, buffer: `, buffer);
            return false;
        }
    }

    async downloadeBase64DataFileAndOpenInNativeApp (fileName:string,file:any,mimeType:string){
      try {
        if(this.device.isNativeApp && file && fileName && mimeType){
          this.uiService.displayLoader();

          let _directory = this.fs.cacheDirectory;
          if (this.device.deviceFlags.ios) {
            _directory = this.fs.tempDirectory;
          }
          fetch(file,
            {
              method: "GET"
            }).then(res => res.blob()).then(blob => {
              this.fs.writeFile(_directory, fileName, blob, { replace: true }).then(res => {
                const path = res.nativeURL;
                this.fileOpener.open(
                  path,
                  mimeType
                ).then((res) => {
                  this.uiService.dismissLoader();
                }).catch(err => {
                  this.fileOpener.showOpenWithDialog(
                    path,
                    mimeType
                  ).then((res) => {
                    this.uiService.dismissLoader();
                  }).catch(err => {
                    console.log(err)
                    this.uiService.dismissLoader();
                  });
                });
              }).catch(err => {
                console.log(err)
                this.uiService.dismissLoader();
              });
            }).catch(err => {
              console.log(err)
              this.uiService.dismissLoader();
            });
        }
      } catch (error) {
        console.log(error);
      }
    }

  async getDocumentBody(annotationID: string) {
    //{{resource}}/api/data/v8.2/annotations(a0716ff2-cab7-eb11-8236-000d3a127385)?$select=documentbody

    // NOTE_DOCUMENT_BODY

    let url = this.authService.userConfig.activeInstance.url + Endpoints.NOTE_DOCUMENT_BODY
    url = url.replace('{annotationid}', annotationID)

    return  await this.http.get<any[]>(url).toPromise();
  }

  convertBlobToArrayBuffer(blob: Blob): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();

      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        resolve(arrayBuffer);
      };

      reader.onerror = () => {
        reject(new Error('Error converting Blob to ArrayBuffer.'));
      };

      reader.readAsArrayBuffer(blob);
      } catch (error) {
        console.log(error);
        reject(null)
      }
    });
  }

  downloadBlobFileInBrowser(blob: Blob, fileName: string) {
    try {
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (err) {
      console.log(err);
    }
  }

  async downloadInElectron(src: string, replace = false) {
    try {
      const { timeZone } = Intl?.DateTimeFormat()?.resolvedOptions();

      const httpOptions = {
        headers: new HttpHeaders({ 'X-Zone-Id': timeZone }),
        responseType: 'text' as 'text',
        observe: 'response' as 'response'
      }

      const response = await this.http.get(src, httpOptions).toPromise();
      if (!response) return;
      const base64String = response.body;
      let fileName = this.extractFileNameFromResponse(response.headers.get('Content-Disposition'));

      let dest = electronApi.getPath('downloads') + `\\${fileName}`;

      const fileExist = await electronApi.fileExists(dest);
      
      if (fileExist && !replace) {
        fileName = await this.createUniqueName(electronApi.getPath('downloads'), fileName);
      }

      dest = electronApi.getPath('downloads') + `\\${fileName}`;
      const buffer = Buffer.from(base64String, 'base64');

      const writeStream = electronApi.createWriteStream(dest);
      writeStream.write(buffer);
      writeStream.close();
      return dest;
    } catch (error) {
      console.log('write error', error);
      return false;
    }
  }

  private async createUniqueName(path, fileName): Promise<string> {
    try {
      const extensionIndex = fileName.lastIndexOf('.');
      const extension = extensionIndex < 0 ? '' : fileName.substring(extensionIndex);
      let uniqueName = '';
      let baseName = extensionIndex < 0 ? fileName : fileName.substring(0, extensionIndex);
      let fileExists = false;
      if (this.device.deviceFlags.electron) {
        fileExists = await electronApi.fileExists(path + `\\${fileName}`);
      } else if (this.device.deviceFlags.ios) {
        await this.fs.checkFile(path, fileName).then(succ=> {
          fileExists = true;
        }).catch(err=> {
          fileExists = false;
        });
      }
      if (fileExists) {
        let counter = 1;
        do {
          let suffix = counter ? `(${counter})` : '';
          uniqueName = `${baseName}${suffix}${extension}`;
          if (this.device.deviceFlags.electron) {
            fileExists = await electronApi.fileExists(path + `\\${uniqueName}`);
          } else if (this.device.deviceFlags.ios) {
            await this.fs.checkFile(path, uniqueName).then(succ => {
              fileExists = true;
            }).catch(err => {
              fileExists = false;
            });
          }
          if (fileExists) {
            counter++;
          } else {
            return uniqueName;
          }
        } while (fileExists);
      }
      return fileName; // fallback
    } catch (error) {
      return fileName
    }
  }

  async convertBase64ToBlob(base64, contentType) {
    // Decode the base64 string
    const byteCharacters = atob(base64);

    // Create a Uint8Array of the same length as the decoded string
    const byteArray = new Uint8Array(byteCharacters.length);

    // Iterate over each character and convert it to a byte
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }

    return new Blob([byteArray], { type: contentType });
  }

  async writeToExternalStoageInAndroid(base64String : string, fileName : string) {
    const params = {
      data: base64String,
      filename: fileName,
    };
   
    try {
      const uri = await cordova.plugins.safMediastore.writeFile(params);
      console.log('File written to: ', uri, fileName);
      return ({ uri, fileName });
    } catch (error) {
      console.error('Error writing file', error);
    }
  }

  async removeFiles(filePaths: string[]) {
    try {
      for (let path of filePaths) {
        const decodedFilename = decodeURIComponent(path);
        const filePath = decodedFilename.substring(0, decodedFilename.lastIndexOf('/') + 1);
        const filename = decodedFilename.substring(decodedFilename.lastIndexOf('/') + 1);
        this.fs.removeFile(filePath ,filename);
      }
    } catch (error) {
      console.log('Error while removing file',error);
    }
  }

  async getFiles(responses : any, path : string) {
    const writePromises = Object.keys(responses).map(async (key: string) => {
      let fileName = this.extractFileNameFromResponse(responses[key].headers.get('Content-Disposition'));

      let filePath;

      if (this.device.deviceFlags.electron) {
        const buffer = Buffer.from(responses[key].body, 'base64');
        let dest = path+`\\${fileName}`;
        const writeStream = electronApi.createWriteStream(dest);
        writeStream.write(buffer);
        writeStream.close();
        filePath = dest;
      } else {
        const blob = await this.convertBase64ToBlob(responses[key].body, null);
        const arrayBuffer = await this.convertBlobToArrayBuffer(blob);

        let res;
        if (this.device.deviceFlags.ios) {
          res = await this.writeFileBufferToSharedDirectory(fileName, arrayBuffer, {
            replace: true
          });
        } else {
          res = await this.writeFileBufferToSharedDirectory(fileName, arrayBuffer, {
            replace: true
          }, path);
        }
      
        filePath = this.device.deviceFlags.ios ? this.cleanFilename(res.nativeURL) : res.nativeURL;
        
      }

      return filePath;
    });

    const results = await Promise.all(writePromises)
    return results;
  }

  cleanFilename(url) {
     // Decode URI to handle encoded characters
     let decodedUrl = decodeURIComponent(url);
     const lastIndex = decodedUrl.lastIndexOf('/');
     const extension = decodedUrl.split('.').pop();
     const regex = new RegExp("([^\/\\&]+)\."+ extension +"$", "i");
     const baseUrl = decodedUrl.substring(0, lastIndex + 1);
     
     // Use a regular expression to extract the filename
     let match = decodedUrl.match(regex);
     if (match) {
       return baseUrl + match[1] + `.${extension}`;
     } else {
       return 'Filename not found!';
     }
  }

  async downloadFile(urls) {
    const { timeZone } = Intl?.DateTimeFormat()?.resolvedOptions();

    let httpOptions :any = {
      headers: new HttpHeaders({ 'X-Zone-Id': timeZone }),
      responseType  : 'text' as 'text',
      observe : 'response' as 'response'
    }

    const downLoadableObservables = urls.map((url) => {
      return this.http.get(url, httpOptions)
    });

    const responses: any = await firstValueFrom(forkJoin(downLoadableObservables));
    let path;

    if (this.device.deviceFlags.electron) {
      path = electronApi.getPath('temp');
    } else {
      path = this.fs.dataDirectory;
    }
    const fileNames: string[] = await this.getFiles(responses, path);
    return fileNames;
  }

  public extractFileNameFromResponse(contentDisposition: string): string {
    const encodedFileName = contentDisposition.split("''")[1];
    return decodeURIComponent(encodedFileName);
  }

}
