import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { Component, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, AfterViewInit, OnInit } from '@angular/core';
import { IndFormFieldViewDataModel, FormFieldType } from '../../../models/indFormFieldDataModel';
import { DomController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from '../../../services/device/device.service';
import { PopoverComponent } from '@omni/components/popover/popover';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { CheckboxCustomEvent } from '../../../interfaces/shared/shared.interface';
import { electronApi } from "@omni/services/electron-api";
import { DomSanitizer } from '@angular/platform-browser';

const DefaultLabelWidth = '30%';
const DefaultValueWidth = '60%';

/**
 * The FormFieldComponent is a versatile component designed to render various types of form fields dynamically. It provides a flexible and customizable interface for displaying and interacting with form fields within Angular applications.
 * It is intended to be used as a reusable building block for the application. It can render a wide range of form field types, including text inputs, dropdowns, numeric, and more, based on the configuration provided through the IndFormFieldViewDataModel interface.
 * See https://angular.io/api/core/Component for more info on Angular
 * @Component
 */
@Component({
  selector: 'ind-form-field',
  templateUrl: 'ind-form-field.html',
  styleUrls:['ind-form-field.scss']
})
export class IndFormFieldComponent implements OnInit, AfterViewInit {

  //public isEditingNumberInput: boolean = false;
  public isInvalidValue: boolean = false;
  public editingInput: boolean = false;
  public isInputInFocus:boolean = false;
  public formFieldType = FormFieldType;
  public defaultLabelWidth = DefaultLabelWidth;
  public defaultValueWidth = DefaultValueWidth;
  private inputEventHandlerSubject: Subject<{event, action}>;
  private inputEventHandlerSubjectSubscription: Subscription;
  private isInputDebounceEnabled = false;

  @Input() viewData: IndFormFieldViewDataModel;
  @Input() biggerFontLabel: boolean = false;
  @Input() isSkeleton: boolean = false;
  @Input() skeletonConfig: { labelWidth: string, valueWidth: string } = { labelWidth: DefaultLabelWidth, valueWidth: DefaultValueWidth };
  @Output() onInputClick = new EventEmitter<any>();

  @ViewChild('input') myInput:any;

  @Output() handleIconClick = new EventEmitter<any>();
  @Output() handleAdditionalControls = new EventEmitter<any>();

  constructor(
    private translate: TranslateService,
    public _cd: ChangeDetectorRef,
    public dom: DomController,
    public device: DeviceService,
    private popoverCtrl: PopoverController,
    private iab: InAppBrowser,
    private readonly sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    // Debounce setup for input field if configured
    this.isInputDebounceEnabled = this.viewData?.inputDebounceTime && this.viewData?.inputDebounceTime > 0;
    if (this.isInputDebounceEnabled) {
      this.inputEventHandlerSubject = new Subject<{event, action}>();
      this.inputEventHandlerSubjectSubscription = this.inputEventHandlerSubject.pipe(
        debounceTime(this.viewData.inputDebounceTime),
      ).subscribe(({event, action}) => {
        this.handleMainEvent(event, action);
      });
    }
  }

  ngAfterViewInit() {
    if (this.viewData && this.viewData.invalidInputValueTrigger) {
      setTimeout(() => {
        this.handleMainEvent({ value: this.viewData.inputValue }, 'input_value_change')
      }, 350);
    }
  }
  ngOnDestroy() {
    if (this.inputEventHandlerSubjectSubscription) {
      this.inputEventHandlerSubjectSubscription.unsubscribe();
    }
  }

  public get arrowType(): string {
    let type: string = '';
    if (this.viewData && this.viewData.showArrow && this.viewData.formFieldType) {
      switch (this.viewData.formFieldType) {
        case FormFieldType.INLINE_INPUT:
          type = 'chevron-back-outline';
          break;
        case FormFieldType.NEW_PAGE_SELECT:
          type = 'chevron-forward-outline';
          break;
        case FormFieldType.POPOVER_SELECT:
          type = 'chevron-down-outline';
          break
        default:
          type = '';
      }
    }
    return type;
  }

  public get formFieldPlaceholder(): string {

    // If skipPlaceholderTranslation is true,
    // We don't want this function to do translation for us.
    if (this.viewData.skipPlaceholderTranslation) {
      return this.viewData.placeholderLabel;
    }

    let type: string = '';
    if (this.viewData && this.viewData.showArrow && this.viewData.formFieldType) {
      switch (this.viewData.formFieldType) {
        case FormFieldType.INLINE_INPUT:
          type = this.translate.instant('ENTER') + ' ';
          break;
        case FormFieldType.NEW_PAGE_SELECT:
          type = this.translate.instant('SELECT') + ' ';
          break;
        case FormFieldType.POPOVER_SELECT:
          type = this.translate.instant('SELECT') + ' ';
          break;
        case FormFieldType.DATA_SUBMITTED:
          type = this.translate.instant('NO') + ' ';
          break;
        case FormFieldType.OPTIONS_NOT_AVAILABLE:
          return this.translate.instant('NO_DROPDOWN_OPTIONS_AVAILABLE', { fieldName: this.viewData.label });
        default:
          type = '';
      }
    } else if (this.viewData && !this.viewData.inputText && this.viewData.formFieldType === FormFieldType.DATA_SUBMITTED) {
      type = this.translate.instant('NO') + ' ';
    }
    if (this.viewData.hasOwnProperty('customPlaceholderLabel')) {
      return this.viewData.customPlaceholderLabel;
    } else if (this.viewData.placeholderLabel) {
      type = type + this.viewData.placeholderLabel;
    }
    return type;
  }

  public onInputTextClick(event) {
    if (this.viewData && this.viewData.id) {
      if (!this.viewData.isDisabled) {
        if (this.viewData.isReadOnly) {
          let data = {
            event: event,
            id: this.viewData.id,
          }
          this.onInputClick.emit(data);
          if (this.viewData.eventHandler) {
            let bindingTarget = event.target;
            if (this.viewData.formFieldType == FormFieldType.POPOVER_SELECT) {
              if (event && event.target) {
                if (event.target.className.includes('form-field-text') || event.target.className.includes('form-field-value')) {
                  bindingTarget = event.target.parentElement.nextElementSibling;
                } else if (event.target.className.includes('form-field-placeholder-text') || event.target.className.includes('form-field-user-input-text')) {
                  bindingTarget = event.target.parentElement.nextElementSibling;
                } else if (event.target.className.includes('inline') && event.target.parentElement && (event.target.parentElement.className.includes('form-field-placeholder-text') || event.target.parentElement.className.includes('form-field-user-input-text') || event.target.parentElement.className.includes('form-field-text') || event.target.parentElement.className.includes('form-field-value'))) {
                  bindingTarget = event.target.parentElement.parentElement.nextElementSibling;
                }
              }
              let bindingEvent = {
                target: bindingTarget,
              }
              event = bindingEvent;
            }
            this.handleMainEvent(event, 'main_click_event')
          }
        } else {
          this.editingInput = true;
          this._cd.detectChanges();
          if (this.viewData && this.viewData.id && !this.viewData.isDisabled && !this.viewData.isDateTimeField) {
            this.isInputInFocus = true;
          }
          if (this.viewData.eventHandler) {
            this.viewData.eventHandler(this.viewData.id, event, 'DetectChange', this.viewData);
          }
          this.dom.read(() => {
            if (this.myInput && this.myInput.nativeElement) {
              this.myInput.nativeElement.focus();
            }
          })
          // setTimeout(() => {
          //   if(this.myInput){
          //     this.myInput.setFocus();
          //   }
          // },350);
        }
        if (this.device.deviceFlags.ios && event?.target?.className.includes('form-field-value')) {
          this.linkableTextValidation(this.viewData.inputText);
        }
      } else {
        if (this.viewData.infoTextEventHandler) {
          this.viewData.displayInfoText = true;
          this.viewData.infoTextEventHandler(this.viewData.id, event.value, event);
        }
      }
    }
  }

  public handleEnterEvent(event) {
    if (this.myInput && this.myInput.nativeElement) {
      try {
        this.myInput.nativeElement.blur();
        this.dom.read(() => {
          this.isInputInFocus = false;
          this.editingInput = false;
          this._cd.detectChanges();
          if (this.viewData.eventHandler) {
            this.viewData.eventHandler(this.viewData.id, event, 'DetectChange', this.viewData);
          }
        })
      } catch (error) {
        console.log(error);
      }
    }
  }

  public onInputFocus(event){
    if (this.viewData && this.viewData.id && !this.viewData.isDisabled) {
      this.isInputInFocus = true;
    }
  }

  // public onFocus(focusInput) {
  ////   this.focusedInputElement = focusInput as HTMLElement;
  // }

  public handleInputEvent(event, action) {
    if (this.isInputDebounceEnabled) {
      this.inputEventHandlerSubject.next({ event, action });
      return;
    }
    this.handleMainEvent(event, action);
  }

  public handleMainEvent(event, action) {
    if (action && action == 'input_focus_out') {
      this.isInputInFocus = false;
      action = 'input_value_confirm';
      try {
        if (event) {
          event.stopPropagation();
        }
      } catch (error) {
        // Handle Error To Do
      }
      this.editingInput = false;
      this._cd.detectChanges();
    }
    if(action == 'input_value_confirm' || action == 'input_value_change'){
      if(event && event.target){
        this.viewData.inputValue = event.target.value;
      }
      if(this.viewData.isDisabled || this.viewData.isReadOnly){
        return;
      }
    }
    if (this.viewData.eventHandler) {
      if (this.viewData.inputValidator) {
        if (action == 'main_click_event') {
          this.isInvalidValue = false;
          this.viewData.eventHandler(this.viewData.id, event, action, this.viewData);
        } else if (event.value && this.viewData.inputValidator(this.viewData.id, event.value, action)) {
          this.isInvalidValue = false;
          this.viewData.inputText = event.value;
          this.viewData.eventHandler(this.viewData.id, event, action, this.viewData);
        } else if (event.target && this.viewData.inputValidator(this.viewData.id, event.value, event.target, action)) {
          this.isInvalidValue = false;
          if(this.viewData.formFieldType && this.viewData.formFieldType == FormFieldType.INLINE_INPUT){
            if (event.target && event.target.value) this.viewData.inputText = event.target.value;
          }
          this.viewData.eventHandler(this.viewData.id, event, action, this.viewData);
        } else {
          this.isInvalidValue = true;
          if(this.viewData.formFieldType && this.viewData.formFieldType == FormFieldType.INLINE_INPUT){
            if (event.target && event.target.value) this.viewData.inputText = event.target.value;
          }
          this.viewData.eventHandler(this.viewData.id, event, 'InvalidValueEntered', this.viewData);
        }
      } else {
        this.viewData.eventHandler(this.viewData.id, event, action, this.viewData);
      }
    }
  }

  public validateInput(event: any) {
    if (!this.viewData.inputRegEx) return;
    if (!this.viewData.inputRegEx.test(event.key)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  /**
   * Handle icon click event
   * @param event
   */
  onInputIconClick(event) {
    this.handleIconClick.emit(event);
    this.viewData.eventHandler(this.viewData.id, event, 'iconClick', this.viewData);
  }

  onClickOfToolTipIcon(event) {
    this.viewData.eventHandler(this.viewData.id, event, 'toolTipiconClick', this.viewData);
  }

  onAdditionalControlClick(control) {
    this.handleAdditionalControls.emit(control.id);
  }
  //linkable text conversion
  // linkableText(text) {
  //   const urlRegexp = new RegExp(/([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/,'gi');
  //   let checkedText = '';
  //   checkedText = (text.toString() || "").replace(urlRegexp, function(match, space, url) {
  //     return space + '<a id="' + url + '"' + ' style="color:#007AFF; text-decoration:underline">' + url + '</a>';
  //   });   
  //   let formattedText = this.sanitizer.bypassSecurityTrustHtml(checkedText);
  //   return formattedText;
  // }
  openUrl(event:any, text) {
    if(event && event.target && event.target.id == '') return;
    let checkedText: string[] = [];
    const urlRegexp = new RegExp(/([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/,'gi');
    (text.toString() || "").replace(urlRegexp, function(match, space, url) {
      let hyperlink = url;
      if (!hyperlink.match('^https?:\/\/') && !hyperlink.match('^http?:\/\/')) {
        hyperlink = 'https://' + hyperlink;
      }
      hyperlink = hyperlink.replaceAll('"','');
      hyperlink = hyperlink.replaceAll('<','');
      hyperlink = hyperlink.replaceAll('>','');
      checkedText.push(hyperlink);
    });
    if(checkedText && checkedText.length > 0) {
      let targetId = event && event.target ? event.target.id : '';
      targetId = targetId.replace('https://', '');
      targetId = targetId.replace('http://', '');
      targetId = targetId.replace('wwww.', '');
      const idx = checkedText.findIndex(t=> targetId && t.includes(targetId));
      if(idx>-1) {
        if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
          if(this.device.deviceFlags.ios) {
            this.iab.create(checkedText[idx], '_blank', { location: 'no', hideurlbar: 'yes', zoom: 'no' });
          }else {
            this.iab.create(checkedText[idx], '_system', { location: 'yes', toolbar: 'yes', zoom: 'no' }); 
          }
        } else {
          if(this.device.deviceFlags.electron) {
            electronApi.openExternal(checkedText[idx]);
          }else {
            window.open(checkedText[idx], "_system");
          }
        }
      }
    }
  }
  //linkable text validation for css
  linkableTextValidation(text) {
    let urlRegexp = /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi;
    let checkedText: string = '';
    (text.toString() || "").replace(urlRegexp, function(match, space, url) {
      let hyperlink = url.toLowerCase();
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'https://' + hyperlink;
      }
      checkedText = hyperlink;
    });
    if(checkedText.includes('http')) {
      return true;
    } else {
      return false;
    }
  }

  onCheckboxChange(event: CheckboxCustomEvent) {
    this.viewData.eventHandler(this.viewData.id, event);
  }

  onToggleChange(event) {
    this.viewData.eventHandler(this.viewData.id, event, null, this.viewData);
  }

  async showDropdown(event) {
    let dropdownValues = this.viewData.dropdown.values as any;
    if (this.viewData?.dropdown?.values instanceof Function) {
      dropdownValues = (dropdownValues as Function)();
      if ((dropdownValues as any).then) {
        dropdownValues = await dropdownValues;
      }
    }
    const popoverOption = {
      selectListPopupData: dropdownValues,
      field: 'select-list-popup'
    };
    let ev = event;

    let popover = await this.popoverCtrl.create({ component: PopoverComponent, componentProps: popoverOption, event });
    popover.present();
    let dismissFn = await popover.onDidDismiss()
    if (dismissFn?.data?.selectedItem) {
      this.viewData.inputValue = dismissFn.data.selectedItem.id;
      this.viewData.inputText = dismissFn.data.selectedItem.title;
      this.handleMainEvent({ target: { value: dismissFn.data.selectedItem.id, text: dismissFn.data.selectedItem.title } }, 'input_value_confirm');
    }
  }
}
