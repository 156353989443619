/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { TranslateService } from '@ngx-translate/core';
import { WebsocketDataService } from '@omni/data-services/websocket/websocket.data.service';
import { Events } from '@omni/events';
import { DeviceService } from '../device/device.service';
import { LogService } from '../logging/log-service';
import {
  NotificationService,
  ToastStyle,
} from '../notification/notification.service';

import { EventsService } from '../events/events.service';

let OT;

interface IOTInitOptions {
  name: string;
  features: {
    video: boolean;
    audio: boolean;
    screenshare: boolean;
  };
  userId: string;
  apiKey: string;
  tokens: {
    publisher: string;
    subscriber: string;
    session: string;
  };
}
const enum OpenTokSignalType {
  featureActionResponse = 'FeatureActionResponse',
  identityResponse = 'IdentityResponse',
  audioRequest = 'AudioRequest',
  audioResponse = 'AudioResponse',
  audioStatusResponse = 'AudioStatusResponse',
}

export interface OTState {
  audio: 'playing' | 'paused' | 'waiting';
  video: 'playing' | 'paused' | 'waiting';
  screenshare: 'playing' | 'paused' | 'waiting';
  screenaudio: 'playing' | 'paused' | 'waiting';
}

@Injectable({
  providedIn: 'root',
})
export class OpentokService implements OnDestroy {
  private _options: IOTInitOptions;
  private _session: OT.Session;
  private _streams: OT.Stream[];
  public activityId = null;

  private _audioActive = false;
  private _videoActive = false;
  private _screenshareActive = false;

  get waiting() {
    return (
      !this._session ||
      this._state.audio === 'waiting' ||
      this._state.video === 'waiting' ||
      this._state.screenaudio === 'waiting' ||
      this._state.screenshare === 'waiting'
    );
  }

  get audioActive() {
    return this._audioActive;
  }

  set audioActive(value) {
    this._audioActive = value;
  }

  get videoActive() {
    return this._videoActive;
  }

  set videoActive(value) {
    this._videoActive = value;
  }

  get screenshareActive() {
    return this._screenshareActive;
  }

  set screenshareActive(value) {
    this._screenshareActive = value;
  }

  private _timeoutId;
  get enabledFeatures() {
    return this._options.features;
  }

  get tokens() {
    return this._options?.tokens;
  }

  get session() {
    return this._session;
  }

  get streams() {
    return this._streams || [];
  }

  private _state: Partial<OTState> = {};

  constructor(
    private readonly events: Events,
    private readonly ngZone: NgZone,
    private readonly log: LogService,
    private readonly device: DeviceService,
    private readonly notif: NotificationService,
    private readonly translate: TranslateService,
    private readonly androidPermissions: AndroidPermissions,
    private readonly ws: WebsocketDataService,
    private readonly eventsService: EventsService,
  ) {
    this.loadOT();
  }

  private async loadOT() {
    if (this.device.deviceFlags.nativeIOS) {
      const cordovaOT = (window as any)?.cordova?.plugins?.OT;
      if (cordovaOT) {
        OT = cordovaOT;
      }
    } else if (!OT) {
      OT = await import('@opentok/client');
    }
    // OT.setLogLevel(OT.DEBUG);
  }

  initPublisher(...args) {
    return OT.initPublisher(...args);
  }

  disconnect() {
    this.cleanup();
  }

  ngOnDestroy() {
    this.cleanup();
  }

  private cleanup(newOptions?: IOTInitOptions) {
    if (
      !this._session ||
      (this._options?.tokens?.session === newOptions?.tokens?.session &&
        this._options?.tokens?.publisher === newOptions?.tokens?.publisher)
    ) {
      return;
    }
    this._session?.disconnect();
    this._session?.off();
    this.audioActive = false;
    this.videoActive = false;
    this.screenshareActive = false;
    this._streams = [];
    delete this._state.audio;
    delete this._state.video;
    delete this._state.screenaudio;
    delete this._state.screenshare;
    delete this._session;
  }

  async checkSupportedFeatures() {
    const screenshare = await this.checkScreenSharingCapability().catch(
      () => false
    );
    const androidPermissions = this.device.deviceFlags.nativeAndroid
      ? await this.checkAndRequestPermission().catch(() => false)
      : true;
    return {
      screenshare,
      webrtc: androidPermissions && !!OT?.checkSystemRequirements(),
    };
  }

  private async checkAndRequestPermission(): Promise<any> {
    const PERMISSION = this.androidPermissions.PERMISSION;
    try {
      await this.androidPermissions.requestPermissions([
        PERMISSION.CAMERA,
        PERMISSION.INTERNET,
        PERMISSION.RECORD_AUDIO,
        PERMISSION.WAKE_LOCK,
        PERMISSION.MODIFY_AUDIO_SETTINGS,
      ]);
      return true;
    } catch (reason) {
      if (reason === 'cordova_not_available') return;
      throw reason;
    }
  }
  private async checkScreenSharingCapability() {
    return new Promise<any>((resolve, reject) => {
      OT.checkScreenSharingCapability((response) => {
        if (!response.supported) {
          reject(new Error('This browser does not support screen sharing.'));
        } else if (response.extensionRegistered === false) {
          reject(
            new Error(
              'Screen sharing requires a extension, but there is no extension registered'
            )
          );
        } else if (response.extensionInstalled === false) {
          reject(
            new Error(
              'Screen sharing requires a extension, but there is no supported extension installed'
            )
          );
        } else {
          resolve(response);
        }
      });
    });
  }

  reconnectingToastMsg() {
    this.notif.notify(
      this.translate.instant('VOIP_RECONNECTING'),
      'activity-details',
      'top',
      ToastStyle.INFO
    );
  }

  private notifyUserJoinLeft(userId: string, join: boolean) {
    if (userId) {
      this.eventsService.publish(`remote:${join ? 'join' : 'left'}`, userId);
    }
  }

  public async init(options: IOTInitOptions) {
    this.disconnect();
    this._options = options;
    const session = OT.initSession(
      this._options.apiKey,
      this._options.tokens.session
    ) as OT.Session;
    // const guidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    session.on('streamCreated', (event) => {
      this.ngZone.run(() => {
        this.log.logDebug(`Stream created: ${event?.stream?.streamId}`);
        if (!this._streams) {
          this._streams = [];
        }

        if (
          this._streams.some(
            (stream) => stream.streamId === event?.stream?.streamId
          )
        ) {
          this.log.logDebug(
            `Duplicate stream found, reason unknown: ${event?.stream?.streamId}`
          );
          return;
        }
        this._streams.push(event?.stream);
        this.notifyUserJoinLeft(event?.stream?.name, true);
      });
    });

    session.on('streamDestroyed', (event) => {
      this.ngZone.run(() => {
        this.log.logDebug(`Stream destroyed: ${event?.stream?.streamId}`);
        const streamIndex = this._streams.findIndex(
          (stream) => stream.streamId === event?.stream?.streamId
        );
        if (streamIndex >= 0) {
          this._streams.splice(streamIndex, 1);
          this.notifyUserJoinLeft(event.stream.name, false);
        }
      });
    });

    session.on('signal', (event) => {
      this.ngZone.run(() => {
        if (!event.from) {
          return;
        }
        if (session.connection?.connectionId === event?.from?.connectionId) {
          return;
        }
        const signalType = event.type.substring(
          event.type.indexOf(':') + 1,
          event.type.length
        ) as OpenTokSignalType;
        if (signalType === OpenTokSignalType.audioStatusResponse) {
          let data; // JSON.parse(event.data);
          if (this.device.deviceFlags.nativeIOS) {
            data = event.data;
          } else {
            data = JSON.parse(event.data);
          }
          const contactId = data.contactID;
          const isMute = data.voiceStatus !== 'UNMUTE';
          this.events.publish('voip-audio-status', {
            contactID: contactId,
            voiceStatus: data.voiceStatus,
          });
        }
      });
    });

    session.on('streamPropertyChanged', (event) => {
      this.ngZone.run(() => {
        this.events.publish('opentok-session-streamPropertyChanged', event);
      });
    });

    session.on('sessionReconnecting', (event) => {
      this.ngZone.run(() => {
        this.notif.notify(
          this.translate.instant('POOR_INTERNET'),
          'activity-details',
          'top',
          ToastStyle.INFO,
          5000,
          false
        );
        setTimeout(() => {
          this.reconnectingToastMsg();
        }, 10000);
      });
    });

    session.on('connectionCreated', async (event) => {
      this.ngZone.run(() => {
        if (
          event.connection?.connectionId === session.connection?.connectionId
        ) {
          return;
        }
        session.signal(
          {
            type: OpenTokSignalType.identityResponse,
            data: JSON.stringify({
              identity: this._options.userId,
              isPresenter: true,
            }),
            to: event.connection,
          },
          () => {}
        );

        session.signal(
          {
            type: OpenTokSignalType.featureActionResponse,
            data: JSON.stringify({
              isAudioFeatureActionEnabled: this._options.features.audio,
              isVideoFeatureActionEnabled: this._options.features.video,
              isScreenshareFeatureActionEnabled: this._options.features
                .screenshare,
            }),
            to: event.connection,
          },
          () => {}
        );
      });
    });

    if (
      await new Promise((resolve) => {
        session.once('sessionConnected', () => resolve(session));
        session.once('sessionDisconnected', (ev) => {
          this.ws.updateStreamingStatus(false);
        });
        session.once('exception', () => resolve(null));
        session.connect(this._options.tokens.publisher, (error) => {
          if (!error) return;
          switch (error.name) {
            case 'OT_NOT_CONNECTED':
              this.notif.notify(
                this.translate.instant('NOT_CONNECTED_TO_NETWORK'),
                'activity-details',
                'top',
                ToastStyle.INFO
              );
              break;
            case 'OT_CREATE_PEER_CONNECTION_FAILED':
              this.notif.notify(
                this.translate.instant('NOT_CONNECTED_TO_NETWORK'),
                'activity-details',
                'top',
                ToastStyle.INFO
              );
              break;
            default:
              this.notif.notify(
                this.translate.instant('PLEASE_TRY_AGAIN_LATER'),
                'activity-details',
                'top',
                ToastStyle.INFO
              );
          }
          resolve(null);
        });
      })
    ) {
      this.ngZone.run(() => {
        this._session = session;
        this.ws.updateStreamingStatus(true);
      });
    }
  }

  public async publish(element: HTMLElement, props: OT.PublisherProperties) {
    return new Promise<OT.Publisher>((resolve, reject) => {
      let handled = false;
      let publisher;
      const failed = (error) => {
        if (handled) return;
        handled = true;
        reject(error);
      };
      const succeeded = () => {
        if (handled) return;
        handled = true;
        if (publisher) {
          resolve(publisher);
        } else {
          reject(new Error('failed'));
        }
      };
      let handler;
      handler = (event) => {
        OT.off('exception', handler);
        failed(event);
      };
      OT.on('exception', handler);
      publisher = this.initPublisher(element, props, (error) => {
          if (error) {
            failed(error);
          } else {
            console.log('Publisher initialized.');
          }
      });
      publisher.on('streamCreated', (event) => succeeded());
      publisher.on('accessDenied', (event) => failed(event));
      this.session.publish(publisher);
    });
  }


  public async unpublish(publisher: OT.Publisher) {
    if (this.session && publisher) {
      await new Promise<void>((resolve, reject) => {
        let handled = false;
        const succeeded = () => {
          if (handled) return;
          handled = true;
          resolve();
        };

        const failed = (err) => {
          if (handled) return;
          handled = true;
          reject(err);
        };

        let handler;
        handler = (event) => {
          OT.off('exception', handler);
          failed(event);
        };
        OT.on('exception', handler);
        publisher.once('streamDestroyed', (ev) => succeeded());
        this.session.unpublish(publisher);
      });
    }
  }

  public sendSignal(signal: {
    type?: string;
    data?: string;
    to?: OT.Connection;
  }) {
    this.session.signal(signal, () => {});
  }

  public setOTState(state: Partial<OTState>, ...clearKeys: (keyof OTState)[]) {
    this.ngZone.run(() => {
      const oldState = this._state;
      let newState = { ...(oldState || {}) };
      clearKeys.forEach((key) => {
        delete newState[key];
      });
      newState = { ...(newState || {}), ...(state || {}) };
      if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
        this._state = newState;
        this.raiseStateChanged(oldState);
      }
    });
  }

  public clearOTState(...keys: (keyof OTState)[]) {
    this.ngZone.run(() => {
      const oldState = this._state;
      const newState = { ...(oldState || {}) };
      keys.forEach((key) => {
        delete newState[key];
      });
      if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
        this._state = newState;
        this.raiseStateChanged(oldState);
      }
    });
  }

  private raiseStateChanged(oldState: Partial<OTState>) {
    this.updateViews();
    this.audioActive =
      this._state?.audio === 'playing' ||
      (!this._state?.audio ? false : this.audioActive);
    this.videoActive =
      this._state?.video === 'playing' ||
      (!this._state?.video ? false : this.videoActive);
    this.screenshareActive =
      this._state?.screenshare === 'playing' ||
      (!this._state?.screenshare ? false : this.screenshareActive);

    this.events.publish('opentok-state-changed', this._state, oldState);

    if (oldState?.video !== this._state?.video) {
      if (this._state?.video === 'playing') {
        this.notif.notify(
          this.translate.instant('YOUR_ARE_NOW_SENDING_VIDEO'),
          'activity-details',
          'top',
          ToastStyle.INFO
        );
      } else if (!this._state?.video) {
        this.notif.notify(
          this.translate.instant('YOUR_DEVICE_VIDEO_CAMERA_DISABLED'),
          'activity-details',
          'top',
          ToastStyle.INFO
        );
      }
    }
    if (oldState?.audio !== this._state?.audio) {
      if (this._state?.audio === 'playing') {
        this.notif.notify(
          this.translate.instant('YOU_ARE_NOW_SENDIING_AUDIO'),
          'activity-details',
          'top',
          ToastStyle.INFO
        );
      } else if (!this._state?.audio) {
        this.notif.notify(
          this.translate.instant('YOUR_DEVICE_MICROPHONE_MUTED'),
          'activity-details',
          'top',
          ToastStyle.INFO
        );
      }
    }
  }

  updateViews() {
    if (!OT?.updateViews) return;
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
      this._timeoutId = undefined;
    }
    this._timeoutId = setTimeout(() => {
      this._timeoutId = undefined;
      OT?.updateViews();
    }, 100);
  }
}
