import {AgendaFooterService} from '@omni/services/footer/agenda-footer.service';
import {AlertController, IonNav, LoadingController, ModalController, PopoverController} from '@ionic/angular';

import {ChangeDetectorRef, Component, Input, OnInit, ViewChild, ElementRef} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {Events} from '@omni/events';
import {endOfDay, isWithinRange, startOfDay, format} from 'date-fns';
import * as moment from "moment";
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IndSectionHeaderViewDataModel} from '@omni/models/indSectionHeaderDataModel';
import {
  EmailActionType,
  EmailActivity,
  EmailActivityParty,
  EmailStatusCodes,
  EmailViewType
} from '../../../classes/activity/email.activity.class';
import {FeatureActionsMap} from "../../../classes/authentication/user.class";
import {Channel, ChannelActivityType, ChannelType} from '../../../classes/consent/channel.class';
import {ConsentTerm} from "../../../classes/consent/consent-term.class";
import {ContactConsent} from '../../../classes/consent/contact-consent.class';
import {Product} from "../../../classes/consent/product.class";
import {Contact} from "../../../classes/contact/contact.class";
import {EmailTemplateType, ResourceEmailTemplate} from '../../../classes/email-templates/email-template.class';
import {Resource} from '../../../classes/resource/resource.class';
import {ResourceInteractionDetailComponent} from '../../../components/resource-interaction-detail/resource-interaction-detail';
import {RepServices} from '../../../data-services/rep/rep.services';
import {FormFieldType, IndFormFieldViewDataModel} from '../../../models/indFormFieldDataModel';
import {IndPageTitleViewDataModel} from '../../../models/indPageTitleDataModel';
import {ActivityService} from '../../../services/activity/activity.service';
import {AlertService} from '../../../services/alert/alert.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {BrandOfflineService} from "../../../services/brand/brand.service";
import {CallPlanOfflineService} from '../../../services/call-plan/call-plan.offline.service';
import {ConsentTermGenerateService} from "../../../services/consent/consent-term-generate.service";
import {ConsentService} from '../../../services/consent/consent.service';
import {ContactOfflineService} from '../../../services/contact/contact.service';
import {DateTimeFormatsService} from "../../../services/date-time-formats/date-time-formats.service";
import {DeviceService} from '../../../services/device/device.service';
import {DirectMessagingService} from '../../../services/direct-messaging/direct-messaging.service';
import {EmailService} from '../../../services/email-templates/email.service';
import {EventName, EventsService} from '../../../services/events/events.service';
import {FooterService, FooterViews} from '../../../services/footer/footer.service';
import {GlobalUtilityService} from "../../../services/global-utility.service";
import {TrackingEventNames, TrackService} from '../../../services/logging/tracking.service';
import {ChildNavNames, NavigationService, PageName} from '../../../services/navigation/navigation.service';
import {NotificationService, ToastStyle} from '../../../services/notification/notification.service';
import {ResourceService, ResourceView} from '../../../services/resource/resource.service';
import {ComponentViewMode, UIService} from '../../../services/ui/ui.service';
import {ConsentDetailsComponent} from '../../contact/consent-details/consent-details';
import {ContactPageComponent} from '../../contact/contact-page/contact-page';
import {EmailTemplatePageComponent} from '../../email/email-template-page/email-template-page';
import {PopoverComponent} from '../../popover/popover';
import {ResourcePageComponent} from '../../resource/resource-page/resource-page';
import {EmailPopoverComponent} from '@omni/components/email/email-popover/email-popover';
import {PhoneActivity} from '../../../classes/activity/phone.activity.class';
import {PhoneCallMeetingStructureService} from './../../../services/phone-call-meeting-structure/phone-call-meeting-structure.service';
import {IoUserSelection} from '@omni/services/io-userselection/io-userselection.service';
import {IOMultiSelect} from '@omni/components/io-component/io-multiselect/io-multiselect';
import {AccompainedUser} from '@omni/classes/activity/accompained-user.class';
import {BusinessProcessViewDataModel} from '@omni/models/business-process-view-data.model';
import {ContentMatchingService} from "@omni/services/content-matching/content-matching.service";
import {cloneDeep} from "lodash";
import { MainToolTemplateComponent } from '@omni/components/shared/main-tool-template/main-tool-template';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '@omni/models/mainToolTemplateDetail.model';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { TemplatePreviewComponent } from './../../email/template-preview/template-preview';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { DatePipe } from '@angular/common';
import _ from 'lodash';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { LocalizationService } from '@omni/services/localization/localization.service';
import { Brand } from '@omni/classes/brand/brand.class';
import { AccountOfflineService, AccountSelectedFor } from '@omni/services/account/account.offline.service';
import { AccountPageComponent } from '@omni/components/account/account-page/account-page';

/**
 * Generated class for the EmailActivityDetailComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'email-activity-detail',
  templateUrl: 'email-activity-detail.html',
  styleUrls: ['email-activity-detail.scss']
})

export class EmailActivityDetailComponent implements OnInit {

  private hasResourceToolEnabled: boolean = false;
  private popover: any;
  public selectedEmailAddress: String[] = [];
  public emailResources: Resource[] = [];
  public emailAddress: String[] = [];
  public allowToRemoveContact = false;
  public scheduledTime: string;
  public formattedScheduledTime: [string, string];
  public minDate: string;
  public disabled: boolean = false;
  public isProductSelectedFromTemplate: boolean = false;
  public email: EmailActivity;
  ISelectionSubscription: Subscription;
  IcurrentEmailSubscription: Subscription;
  @Input() data: any;
  private inMeetingFlow: boolean = false;
  private inPhoneCallFlow: boolean
  ngDestroy$: any = new Subject<boolean>();
  private enableBackButtonOnDetails: boolean = false;
  public _isPersonalizeDisabled: boolean = true;
  public isCompletedActivity: boolean = false;
  public ChannelType = ChannelType;
  public EmailTemplateType = EmailTemplateType;
  public readonly consentsEnabled: boolean;
  emailDetailsPageTitle: IndPageTitleViewDataModel;
  public emailStatusString: string;
  messageChannel: string = null;
  showMore = true;
  covisitorShowMore = true;
  maxContactsToBeShown = 5;
  maxCovisitorsToBeShown = 5;
  public inMeetingPresentationFlow = false;
  channelIsDeactivated: boolean;
  public isReadOnlyMode = false;
  public contentBody: string;
  private isReadOnlyRecommendedMessage: boolean;
  public notesPlaceholder:string = '';

  public get templatePreview() {
    this.email = this.email ? this.email : this.emailService.selectedActivity;
    let preview = this.email?.channelType === ChannelType.EMAIL ? this.email?.templatePreview || '' : `<pre>${this.email?.templatePreview || ''}</pre>`;
    this.contentBody = preview;
    let formattedBody = this._replacePropertyInTag(preview);
    return formattedBody;
  }
  @Input() modalView: any
  //@Input() data: any
  public detailsHeader: IndSectionHeaderViewDataModel;
  public attachmentHeader: IndSectionHeaderViewDataModel;
  public attachmentDetailsHeader: IndSectionHeaderViewDataModel;
  public previewHeader: IndSectionHeaderViewDataModel;
  public notesHeader: IndSectionHeaderViewDataModel;
  public checkIfExternalChannelSelected: boolean = false;
  public isNotesDirty: boolean = false;
  public preSavedNotes: string;
  public isReadOnlyJointMeeting: boolean = false;
  public isNotesFeatureActionEnabled: boolean = false;
  public selectedChannel: Channel;
  public isMessageProductBundleFeature: boolean = false;
  public isFromRemoteMeeting: boolean = false;
  public channelName: string;
  private hasMeetingAutoSubject: boolean = false;
  private multiSelectSubscription: Subscription;
  public messagesProcessView:Array<BusinessProcessViewDataModel> = [];
  public isCovisitorFieldEnabled:boolean = false;
  hasContentMatching: boolean = false;

  public startDateField: IndDateTimeFormViewDataModel
  public startTimeField: IndDateTimeFormViewDataModel;
  public prevStartTime: string;
  private isTimeFormatChanged: boolean = false;
  public backgroundUploadInProgress: boolean = false;

  @ViewChild('contactSelectedArea') contactSelectedArea: ElementRef;

  dateFormatSubscription : Subscription;
  timeFormatSubscription : Subscription;
  // mandatory field check
  public isClickedContact: boolean = false;
  private _isClickedProduct: boolean = false;
  private _emptyProduct: boolean = false;
  public errorMessageMandatoryField: string = '';
  public suggestReasonFormField: IndFormFieldViewDataModel;
  private _isRTETokenEmbedded: boolean = false;
  private _isUserPictureTokenEmbedded: boolean = false;

  /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
  public isAccountVisitEnabled = false;
  public isAccountVisitRecord = false;
  private shouldCheckConflict: boolean = false;
  public isAccountSelectionForEmailEnabled: boolean = false;
  /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/

  constructor(public activityService: ActivityService,
    public footerService: FooterService,
    public uiService: UIService,
    public contactService: ContactOfflineService,
    public navService: NavigationService,
    private authService: AuthenticationService,
    private resourceService: ResourceService,
    public popoverCtrl: PopoverController,
    public events: Events,
    private eventService: EventsService,
    public _cd: ChangeDetectorRef,
    public device: DeviceService,
    private ioUserSelection: IoUserSelection,
    public emailService: EmailService,
    public repService: RepServices,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private alertService: AlertService,
    public modalCtrl: ModalController,
    private consentService: ConsentService,
    private trackingService: TrackService,
    private sanitizer: DomSanitizer,
    private dms: DirectMessagingService,
    public navCtrl: IonNav,
    public translate: TranslateService,
    public utilityService: GlobalUtilityService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private notificationService: NotificationService,
    private callPlanService: CallPlanOfflineService,
    private brandOfflineService: BrandOfflineService,
    private consentTermGenerateService: ConsentTermGenerateService,
    private phoneCallMeetingStructureService: PhoneCallMeetingStructureService,
    private agendaFooterService: AgendaFooterService,
    private contentMatchingService: ContentMatchingService,
    private datePipe: DatePipe,
    private surgeryOrderDataService: SurgeryOrderActivityDataService,
    private localizationService: LocalizationService,
    public accountService: AccountOfflineService,
  ) {
    this.events.subscribe('emailActivity:loadChannels', () => {
      this.getExternalChannel(this.email.indskr_channelid);
      this.email.emailProduct = this.emailService.getProduct();
      this.email.emailProductName = this.emailService.getProductDetails();
    });
    this.dateFormatSubscription = this.dateTimeFormatsService.dateFormat.subscribe( df => {
      if (df) {
        this.setEmailDetails();
      }
    });
    this.timeFormatSubscription = this.dateTimeFormatsService.timeFormat.subscribe( tf => {
      if (tf) {
        this.isTimeFormatChanged = true;
        this.setEmailDetails();
      }
    });
    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      if(this.navService.getCurrentPageName() != PageName.TemplatePreviewComponent) this.initFooter();
    });
  }

  ngOnInit() {
    console.log("Initializing email details");
    this.setEmailDetails();
    if (this.authService.hasFeatureAction(FeatureActionsMap.RESOURCE_TOOL)) {
      this.hasResourceToolEnabled = true;
    }

    if (this.authService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT)) {
      this.hasMeetingAutoSubject = true;
    }

    if (this.authService.hasFeatureAction(FeatureActionsMap.MEETING_NOTES)) {
        this.isNotesFeatureActionEnabled = true;
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_PRODUCT_BUNDLE)) {
        this.isMessageProductBundleFeature = true;
    }
    /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
    if (this.authService.hasFeatureAction(FeatureActionsMap.EMAIL_ACCOUNT_INFORMATION_CAPTURE)) {
        this.isAccountSelectionForEmailEnabled = true;
    }
    /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
    this.hasContentMatching = this.authService.hasFeatureAction(FeatureActionsMap.CONTENT_MATCHING);
    if (this.hasContentMatching && this.emailService?.selectedActivity?.emailActivityParties?.length && this.emailService?.selectedActivity?.emailStatus == 1) {
      this.fetchVersionUpdateTemplates();
      if (!this.emailService?.matchedMessageTemplates.length) {
        this.emailService.matchedMessageTemplates = [...this.contentMatchingService.fetchMatchedTemplates(this.emailService.selectedActivity.emailActivityParties)];
      }
    }
    if (this.emailService.selectedActivity) {
      this.initMessagesProcessView();
    }

    this.getExternalChannel(this.email?.indskr_channelid);

    this.ISelectionSubscription = this.resourceService.resourceSelectionObserver.subscribe((res: Resource[]) => {
      if (res && Array.isArray(res)) {
        this.updateEmailResource(res);
        this.setup();
      }
    });

    /* Adding this since the existing methods didn't seem stable */
    this.IcurrentEmailSubscription = this.emailService?.currentEmailObserver.subscribe(value => {

      if (value && value instanceof EmailActivity) {
        /* TC-495
          User should have a template selected,
          template should have description and the description should have %content.url% token
            &&
          Device should be online for desktop and
        */
        this.emailService.isResourceDisabled = true;

        if (this.hasContentMatching && this.emailService?.selectedActivity?.emailActivityParties?.length && this.emailService?.selectedActivity?.emailStatus == 1) {
          this.emailService.matchedMessageTemplates = [...this.contentMatchingService.fetchMatchedTemplates(this.emailService?.selectedActivity?.emailActivityParties)];
          if(!(this.email?.isMessageExternal && !this.email?.isPreviewEnabled) && !this.isMessageProductBundleFeature){
            this.selectMatchedTemplate();
          }
        }

        if (value.template_id && value.description) {
          if ((value.emailStatus == 1 || value.emailStatus == 9) && value.description.includes('%content.url%') && this.authService.hasFeatureAction(FeatureActionsMap.RESOURCE_TOOL)) {
            //On browser, this functionality only has to work while online
            if (!this.device.isNativeApp && !this.device.isOffline) {
              //Desktop and online
              this.emailService.isResourceDisabled = false;
              const emailTemplateIndex = this.emailService?.emailTemplates.findIndex(e => {
                return e.indskr_emailtemplateid === value.template_id;
              });
              if (emailTemplateIndex >= 0)
                value.templateRefProductId = this.emailService?.emailTemplates[emailTemplateIndex].product_id;
            }
            //On mobile app, user should be able to make these selections either online or offline and see that they are saved.
            else if (this.device.isNativeApp) {
              this.emailService.isResourceDisabled = false;
              const emailTemplateIndex = this.emailService?.emailTemplates.findIndex(e => {
                return e.indskr_emailtemplateid === value.template_id;
              });
              if (emailTemplateIndex >= 0)
                value.templateRefProductId = this.emailService?.emailTemplates[emailTemplateIndex].product_id;
            }
            this.emailService.selectedActivity = value;
          }
          this._isPersonalizeDisabled = this.email?.channelType !== ChannelType.EMAIL
            || !value.description.toLowerCase().includes('%custom.')
            || (value.emailStatus !== 1 && value.emailStatus !== 9)
            || this.emailService.isHideContentTokenOnly(value.description);
        }
        //Reset the values and set the preview again
        this.setEmailDetails();
        this.emailService.checkConsentsForMessageActivity();
      }
      this.setup();
    });

    this.eventService.observe('device:deviceIsOffline').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((status) => {
        this.disableOnNetworkCheck();
        //this.isDeviceOnline = false;
      });

    this.eventService.observe('device:deviceIsOnline').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((status) => {
        // if (this.repService.getCurrentUserState() === REP_STATUS.OFFLINE.userState) {
        //   this.disableOnNetworkCheck();
        // }
        // else {
        //   this.disableOnNetworkCheck();
        // }
        this.disableOnNetworkCheck();
      });

    this.eventService.observe('update-email-footer').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => {
        let options = {
          sendEmail: {
            show: true,
            isActive: this.canSendMessage(),
          }
        };
        this.footerService.initButtons(FooterViews.EmailDetails, options);
      });

    this.eventService.subscribe('update-token-replacement', (body) => {
      if (this.email?.description) {
        this.email.description = body;
      }
      this.setup();
    });
    if (this.emailService.emailFromGeneeMessage.messageFlag) {
      this.notificationService.notify(this.emailService.emailFromGeneeMessage.message, 'activity-details', 'top', ToastStyle.INFO)
      this.emailService.emailFromGeneeMessage.messageFlag = false;
      this.emailService.emailFromGeneeMessage.message = '';
    }

    if(this.isCovisitorFieldEnabled){
      this.multiSelectSubscription = this.ioUserSelection.accompaniedUserSelectionSource.subscribe(data => {
        if (data != undefined) {
          this.updateAccompaniedUsers(data);
        }
      });
    }
    this.prevStartTime = format(this.scheduledTime);
    // Update start/end time field with selected time from the time picker
    this.dateTimeFormatsService.updateSelectedTimeMessage$.pipe(takeUntil(this.ngDestroy$)).subscribe((timeData) => {
      if (timeData) {
        if (timeData.id == DateTimeFieldType.StartTimeField) {
          // moment() is not supported date format as "DD/MM/YY" and "D/M/YY" when date and time are combined.
          // set 'MMM dd, yyyy' and 'en-US' to combine date and time, and changed date format will be adjusted through getDisplayTime().
          let getStartDate = this.datePipe.transform(this.scheduledTime, "MMM dd, yyyy", undefined, 'en-US');
          let setCombinedDateTime = moment(getStartDate + ' ' + timeData.time).format();
          this.scheduledTime = setCombinedDateTime;
          setTimeout(() => {
            this.onChangeSchedule(timeData.timeOpened);
          }, 0);
        }
      }
    });

    this.translate.onLangChange.subscribe(data => {
      this.setup()
      this.initDetailViewField();
    });
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      if (this.email && this.navService.getActiveChildNavViewPageName() === PageName.EmailDetailsPageComponent) {
        this.backgroundUploadInProgress = inProgress && this.activityService.hasOfflineEmailData(this.email.offlineActivityId);
        this.initEmailDetailsPageTitle();
        this.footerSetDate = null; // force reload footer
        this.initFooter();
        this.initHeaders();
        this.initDateTimeFormFields();
        this._cd.detectChanges();
      }
    });

    this.attachmentDetailsHeader = {
      id: 'attachment-details-header',
      title: this.translate.instant('ATTACHMENT_DETAILS'),
      controls: [],
      isRequired: false,
    };

    this.errorMessageMandatoryField = this.activityService.getErrorMessageRequiredField();
  }

  private async updateAccompaniedUsers(raw: AccompainedUser[]) {
    await this.emailService.addRemoveAccompaniedUsers(raw).then(e => {
      this.ioUserSelection.setAccompaniedUserSelectedData(undefined);
    });
  }

  private filterChannelActivityType(acitvityType): ChannelActivityType {
      let channelActivityType: ChannelActivityType;

      switch(acitvityType) {
        case ChannelActivityType.SMS:
          channelActivityType = ChannelActivityType.SMS;
          break;
        case ChannelActivityType.EMAIL:
          channelActivityType = ChannelActivityType.EMAIL;
          break;
        case ChannelActivityType.FACEBOOK:
          channelActivityType = ChannelActivityType.FACEBOOK;
          break;
        case ChannelActivityType.WHATSAPP:
          channelActivityType = ChannelActivityType.WHATSAPP;
          break;
        default:
          break;
      }
      return channelActivityType;
  }

  public getProductFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PRODUCT'),
      // deactivated flow need to be taken care and bit of code cleanup
      inputText: (this.email && (this.email.emailProduct || this.email.emailProductName)) ? this.email.emailProductName : '',
      inputValue: (this.email && (this.email.emailProduct || this.email.emailProductName)) ? this.email.emailProductName : '',
      customPlaceholderLabel: this.isCompletedActivity && !this.email?.emailProduct ? this.translate.instant('NO_PRODUCT') : (this.email && !this.email.emailProduct) ? this.translate.instant('SELECT_PRODUCT') : this.translate.instant('SELECT_PRODUCT'),
      id: 'product-field',
      isReadOnly: true,
      secondaryInfoText: this.getSecondaryInfoProduct(),
      isDisabled:  this.handleProductStatus(),
      showArrow: !this.handleProductStatus(),
      isRequired: this.email?.channelActivityType === ChannelActivityType.WECOM ? false : !this.isCompletedActivity && !this.handleProductStatus() && this.email && this.email.isMessageExternal && !this.email.isPreviewEnabled,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: (this._isClickedProduct && this.email && !this.email.emailProduct) || this._emptyProduct,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  private handleProductStatus() : boolean {
     if (this.disabled || this.email?.isFromXperiences || this.isProductSelectedFromTemplate || this.isCompletedActivity) {
           return true
     }
     return false
  }

  public get isScrapEnabled(): boolean {
    if (!this.email) return;
    if (this.authService.user.systemUserID !== this.email?.ownerId || this.email?.status !== 1 || this.activityService.teamViewActive || this.backgroundUploadInProgress) {
      return false;
    } else {
      return true;
    }
  }

  disableOnNetworkCheck() {
    const value = this.emailService.selectedActivity;
    if (value && value.template_id && value.description) {
      if ((value.emailStatus === 1 || value.emailStatus === 9) && value.description.includes('%content.url%') && this.authService.hasFeatureAction(FeatureActionsMap.RESOURCE_TOOL)) {
        //On browser, this functionality only has to work while online
        if (!this.device.isNativeApp && !this.device.isOffline) {
          //Desktop and online
          this.emailService.isResourceDisabled = false;
          value.templateRefProductId = this.emailService.emailTemplates.find(e => {
            return e.indskr_emailtemplateid === value.template_id
          }).product_id;
        }
        //On mobile app, user should be able to make these selections either online or offline and see that they are saved.
        else if (this.device.isNativeApp) {
          this.emailService.isResourceDisabled = false;
          value.templateRefProductId = this.emailService.emailTemplates.find(e => {
            return e.indskr_emailtemplateid === value.template_id
          }).product_id;
        }
      }
    }
    this.setup();
  }

  async onChangeSchedule(timeOpenedTimePicker?: number) {
    if (this.scheduledTime && this.scheduledTime !== moment(this.email?.scheduledEnd).format()) {
      //New selected time is greater than the min date time set
      let diffTime: number = Math.ceil(moment(new Date(this.scheduledTime)).diff(moment(new Date()), 'minutes'));
      if (timeOpenedTimePicker) {
        diffTime = Math.ceil(moment(new Date(this.scheduledTime)).diff(moment(new Date(timeOpenedTimePicker)), 'minutes'));
      }
      if (diffTime >= -1) {
      // if (new Date(this.scheduledTime).getTime() > new Date().getTime()) {
        if (!this.validateContactsHasConsents(new Date(this.scheduledTime))) return;
        this.trackingService.tracking('EmailActivityUpdatetime', TrackingEventNames.EMAIL);
        const selectedDate: Date = new Date(this.scheduledTime);
        await this.emailService.updateEmailActivity({ scheduledend: selectedDate.getTime(), scheduledstart: selectedDate.getTime() }, EmailActionType.SCHEDULE);
      }
      else {
        /**
         * ngx-material-timePicker: it does not support overwriting the selected time value from the timePicker
         * Only the value selected by the timePicker is displayed.
         * To display the previous time value, re-render is required
         */
        if (timeOpenedTimePicker) {
          const email: EmailActivity = this.emailService.selectedActivity;
          let channelType: string = email.channelType;
          this.notificationService.notify(this.translate.instant("MESSAGE_ACTIVITY_UNABLE_SCHEDULE_EMAIL", {type: channelType}), 'Date Time Picker', 'top', ToastStyle.DANGER);
          if (this.activityService.selectedActivity && !this.inMeetingFlow && !this.inPhoneCallFlow && this.navService.getCurrentMasterPageName() != PageName.PresentationMeetingComponent) {
            this.events.publish('activity-pane:selectActivity', this.activityService.selectedActivity);
          }
        }
        this.scheduledTime = this.prevStartTime;
        this.initDateTimeFormFields();
        this._cd.detectChanges();
      }
      this.events.publish('messageActivityTimeIsUpdated', this.activityService.selectedActivity);
      if (!this.uiService.toolsActivityActive) {
        this.events.publish("refreshAgenda");
      } else{
        this.uiService.agendaRefreshRequired = true;
      }
    }
  }

  private scrapEmail() {
    if (this.emailService.selectedActivity) {
      this.alertService.showAlert({
        header: this.translate.instant('EMAIL_ACTIVITY_ALERT_TITLE_SCRAP_MESSGE'),
        message: this.translate.instant('R_U_SURE_SCRAP_MESG')
      }, this.translate.instant('SCRAP')
      ).then(res => {
        if (res.role == "ok") {
          const isLayovered = (this.inMeetingFlow || this.inPhoneCallFlow);
          this.emailService.scrapEmailActivity(this.emailService.selectedActivity, isLayovered).then(() => {
            this.activityService.publishActivityEvent({action: "Delete", activity: this.emailService.selectedActivity});
            this.emailService.selectedActivity = null;
            const currentPage = this.navService.getCurrentPageName();
            if (currentPage === PageName.EmailDetailsPageComponent && this.device.isMobileDevice) {
              this.navService.popWithPageTracking();
            }
            if (currentPage === PageName.ActivitiesPageComponent && !this.inMeetingFlow) {
              if(this.uiService.activitiesPageTab !=='plans') {
                this.uiService.activeView = 'ActivitiesPageRightPaneNav';
                this.uiService.showNewActivity = false;
                this.uiService.showRightPane = false;
                this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
              } else {
                this.uiService.showRightPane = false;
                if (this.navService.getPreviousActiveChildNavViewPageName() == PageName.CustomerCallPlanDetails) {
                  this.navService.popChildNavPageWithPageTracking();
                } else {
                  this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
                }
                this.events.publish('scrollToDate');
              }
            }
            this.events.publish("initTodo");
            this.uiService.agendaRefreshRequired = true;
            if (this.uiService.activitiesPageTab === 'day' || this.inMeetingFlow) {
              this.events.publish("initTimePickerLimitedMinTime");
              this.events.publish('activityDeleted', this.emailService.selectedActivity);
            }
            // if (this.agendaFooterService.actSegment === 'week') this.events.publish('weekview:RefreshUI');
            if (this.inMeetingFlow || this.inPhoneCallFlow) {
              this.events.publish("initTimePickerLimitedMinTime");
              if (this.emailService.viewType == EmailViewType.CREATE_FROM_MEETING) {
                this.modalCtrl.dismiss();
                if (currentPage == PageName.CallPlanComponent ||currentPage == PageName.CustomerCallPlanPage) {
                  this.callPlanService.callPlanPageView = "CallPlanNewMeetingDetails";
                } else if (currentPage == PageName.ActivitiesPageComponent) {
                  this.uiService.activeView = "ActivitiesPageRightPaneNav";
                }
                this.footerService.initButtons(FooterViews.Activities);
              } else {
                this.goBackToPhoneCall();
                this.modalCtrl.dismiss();
              }
              // this.events.publish('activityDeleted', this.emailService.selectedActivity);
              this.emailService.viewType = EmailViewType.EMAIL_SCRAPPED;
              return;
            }
            this.emailService.viewType = EmailViewType.EMAIL_SCRAPPED;
            this.activityService.selectedActivityAtHome = undefined;
            this.activityService.selectedActivity = undefined;
            this.activityService.selectedActivityOnAgenda = undefined;
            // if(this.uiService.activitiesPagePlanTabSelectedPlan != 'scientificPlans') {
            if(this.uiService.activitiesPageTab != 'plans') {
              this.uiService.activeView = undefined;
            }
            if (this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent && !this.inPhoneCallFlow) {
              this.uiService.activeView = 'CallPlanDetails'
              this.callPlanService.callPlanPageView = 'CallPlanDetail';
              this.events.publish('activity-scrapped-created-from-call-plan');
              this.navService.popChildNavPageWithPageTracking();
              this.uiService.showNewActivity = false;
              this.footerService.initButtons(FooterViews.CallPlanDetails);
            }
            if (this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage && !this.inPhoneCallFlow) {
              this.uiService.activeView = 'CallPlanDetails'
              this.callPlanService.callPlanPageView = 'CallPlanDetail';
              this.events.publish('activity-scrapped-created-from-call-plan');
              this.navService.popChildNavPageWithPageTracking();
              this.uiService.showNewActivity = false;
              this.footerService.initButtons(FooterViews.CallPlanDetails);
            }
            else if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
              if(this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER360UI,true)){
                this.navService.popChildNavPageWithPageTracking();
              }else {
                this.navService.popToRootChildNavPageWithPageTracking();
              }

              this.uiService.showNewActivity = false;
              this.footerService.initButtons(FooterViews.Contacts);
            }
            else if (this.navService.getCurrentMasterPageName() == PageName.ScientificActivityPage) {
              // this.activityService.isActivityUpdate = true;
              this.uiService.showRightPane = false;
              this.uiService.showNewActivity = false;
              this.navService.popToRootChildNavPageWithPageTracking();
              this.footerService.initButtons(FooterViews.None);
            }
            return;
          }).catch(() => {
            return;
          });

        }
      });


    }
  }

  private goBackToAppointment() {
    this.uiService.activeView = 'ActivitiesPageRightPaneNav';
    this.uiService.showNewActivity = false;
    this.footerService.initButtons(FooterViews.Activities);
    this.activityService.activitySource = null;
  }

  private goBackToPhoneCall() {
    if (this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent) {
      this.uiService.activeView = 'phoneCallTimelineDetails';
    } else if(this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent || this.navService.getCurrentMasterPageName() == PageName.CustomerCallPlanPage) {
      this.callPlanService.callPlanPageView = 'CallPlanPhoneCallDetails';
      this.phoneCallMeetingStructureService.createEmbeddedInteractions(this.activityService.selectedActivity as PhoneActivity)
    }
    else {
      this.uiService.activeView = 'PhoneCall';
      this.uiService.showRightPane = true;
    }
    this.uiService.showNewActivity = false;
    if (this.navService.getCurrentMasterPageName() != PageName.PresentationMeetingComponent) {
      this.footerService.initButtons(FooterViews.PHONE_CALL);
    }
    this.activityService.activitySource = null;
    this.events.publish('detectChangesOnPhoneCallActivityDetails');
  }

  private async setEmailDetails() {
    if (this.emailService.selectedActivity) {
      //reset value for tracking event
      this.emailService.isActionTriggeredFromFooter = false;
      this.inMeetingFlow = false;
      this.inMeetingPresentationFlow = false;
      this.isFromRemoteMeeting = this.emailService.isRemoteEmail;
      if (this.emailService.viewType == EmailViewType.CREATE_FROM_MEETING
        || this.emailService.viewType == EmailViewType.EMAIL_FROM_MEETING_PRESENTATION) {
        this.inMeetingFlow = true;
        this.enableBackButtonOnDetails = true;
        if (this.emailService.viewType == EmailViewType.EMAIL_FROM_MEETING_PRESENTATION) {
          this.inMeetingPresentationFlow = true;
        }
      }
      if (this.emailService.viewType == EmailViewType.CREATE_FROM_PHONE_CALL) {
        this.inPhoneCallFlow = true
        this.enableBackButtonOnDetails = true;
      }
      if (this.emailService.viewType === EmailViewType.FROM_CONTACT_TIMELINE || this.emailService.viewType === EmailViewType.FROM_XPERIENCES
        || this.emailService.viewType === EmailViewType.FROM_SCIENTIFIC_PLAN) {
        this.enableBackButtonOnDetails = true;
      }
      if (this.navService.getCurrentPageName() === PageName.ContactPageComponent && this.uiService.isContactsFromToolDrawer) {
        this.enableBackButtonOnDetails = true;
      }

      this.email = this.emailService.selectedActivity;
      this.email.emailProduct = this.isMessageProductBundleFeature ? this.emailService.getProductFromProductHierachy() : this.emailService.getProduct();
      this.email.emailProductName = this.isMessageProductBundleFeature ? this.email.emailProduct? this.email.emailProduct.name: '': this.emailService.getProductDetails();
      this.email.product_id = this.emailService.selectedActivity.product_id;
      this.email.subject = this.emailService.getTitleOfEmailActivity();
      this.disabled = (this.email?.emailStatus !== 1) || (this.email?.ownerId !== this.authService.user.systemUserID) || (!this.email.emailActivityParties.length && !this.email.accompaniedUserList.length);
      this.isCompletedActivity = (this.email?.emailStatus !== 1);
      this.isReadOnlyRecommendedMessage = this.email.indskr_recommendedmessage;
      this._isRTETokenEmbedded = false;
      let tokenIds = this.emailService.emailTemplates.find(templ => { return templ.indskr_emailtemplateid == this.email?.template_id })?.content_tokens;
      let contentTokens = this.emailService.contentTokens;
      if(!_.isEmpty(contentTokens) && !_.isEmpty(tokenIds)) this._isRTETokenEmbedded = contentTokens.some(token => tokenIds.some(id => token.indskr_contenttokenid == id && token.indskr_isrtetemplate));

      ///Imbedded Photo in Rep Email Template///
      this._isUserPictureTokenEmbedded = false;
      let templateBody = this.emailService.emailTemplates.find(templ => { return templ.indskr_emailtemplateid == this.email.template_id })?.indskr_body;
      const strPhotoUrl = `%user.signature%`;
      if(!_.isEmpty(templateBody)) this._isUserPictureTokenEmbedded = templateBody.includes(strPhotoUrl);

      if(this.device.isOffline && this._isUserPictureTokenEmbedded) {
        this.notificationService.notify(this.translate.instant("DYNAMIC_TOKEN_NOT_AVAILABLE_IN_OFFLINE"), 'Email_Template_Preview', 'top', ToastStyle.DANGER);
        this.emailService.isNotifiedTokenMessageInOffline = true;
      }else {
        this.emailService.isNotifiedTokenMessageInOffline = false;
      }
      ///Imbedded Photo in Rep Email Template///

      if (this.checkIfExternalChannelSelected) {
        this.assignChannelActivityIfUndefined()
        this.disabled = false;
        if(!this.isCompletedActivity) {
         this.email.subjectDisabled = false; // No Templates mapping for Non Native Messages.
        }
      }
      this.scheduledTime = moment(this.email?.scheduledEnd).format();
      this.minDate = moment(new Date().setHours(0, 0, 0)).format();
      this.formattedScheduledTime = this.emailService.getDisplayTime();
      if (this.navService.getCurrentMasterPageName() === (PageName.CallPlanComponent || PageName.ContactDetailsComponent || PageName.ConsentDetailsComponent)) {
        this.enableBackButtonOnDetails = true;
      }
      if(this.navService.getCurrentPageName() === PageName.CustomerCallPlanPage || this.navService.getCurrentPageName() === PageName.CustomerCallPlanDetails){
        this.enableBackButtonOnDetails = true;
      }
      this.initEmailDetailsPageTitle();
      this.initFooter();
      this.initSuggestReason();
      //Generating thumbnails
      //resource array to be created before the personalization
      if (this.email?.emailAttachments && this.email?.emailAttachments.length) {
        //empty old content
        this.emailResources = [];
        this.email?.emailAttachments.forEach(i => {
          const r = this.resourceService.allResources.find(v => {
            return (i.indskr_resourceid === v.ioResourceId) || (i.indskr_resourceid === v.ioDocumentId)
          });
          if (r && (this.emailResources.findIndex(d => { return (r.ioResourceId === d.ioResourceId) && (r.ioDocumentId === d.ioDocumentId) }) === -1)) {
            this.emailResources.push(r);
          }
        });
      }

      else {
        this.emailResources = [];
      }
      if(!(this.email?.isMessageExternal && !this.email?.isPreviewEnabled) && !this.isMessageProductBundleFeature){
        await this.selectMatchedTemplate();
      }

      if (this.email?.description && this.email?.description.length > 0) {
        if (this.email?.emailStatus == 3 && !this.email?.isMessageExternal) {
          this.email.templatePreview = this.checkActiveEmailAddressInPreview(this.email.description);
        }
        else if (this.email?.template_id) {
          this.email.templatePreview = this.email?.description ? this.email?.description : "";
          this.email.editablePreview = this.email?.description;
          const template: ResourceEmailTemplate = this.emailService.emailTemplates.find(templ => { return templ.indskr_emailtemplateid == this.email.template_id });
          await this.localizationService.initMultilingualFieldsData()
          if (template) {
            this.email.subjectDisabled = !template.indskr_editablebyuser;
              this.email.emailProductName = this.isMessageProductBundleFeature ? this.email.emailProductName : template.productName;
              this.email.product_id = this.isMessageProductBundleFeature? this.email.product_id : template.product_id;
              this.isProductSelectedFromTemplate = true;
            try {
              //Then Personalize the same
              this.emailService.personalizeTemplate(this.email, template);
              //generate content preview if email has not sent
              if (this.email?.emailAttachments && this.email?.emailStatus !== 3) {
                const customAttachment = this.emailService.convertEmailAttachmentToHtmlString(this.emailResources);
                const template = this.emailService.updatePreviewTemplate(customAttachment, true);
                this.email.templatePreview = template;
              }
            } catch (error) {
              console.log(error)
            }
          }else{
            try {
              this.emailService.personalizeTemplate(this.email);
            } catch (error) {

            }
          }
        }
      }
      switch (this.email?.emailStatusString) {
        case 'Canceled':
          this.emailStatusString = this.translate.instant('CANCELLED')
          break;
        case 'Sent':
          this.emailStatusString = this.translate.instant('SENT');
          break;
        case 'Completed':
          this.emailStatusString = this.translate.instant('COMPLETED');
          break;
        case 'Open':
          this.emailStatusString = this.translate.instant('OPEN');
          break;
        case 'Received':
          this.emailStatusString = this.translate.instant('RECEIVED');
          break;
        case 'Pending Sync':
          this.emailStatusString = this.translate.instant('PENDING_SYNC_SMALL');
          break;
        case 'Sending':
          this.emailStatusString = this.translate.instant('SENDING');
          break;
        case 'Failed':
          this.emailStatusString = this.translate.instant('FAILED');
          break;
        case 'Scheduled':
          this.emailStatusString = this.translate.instant('SCHEDULED');
          break;
        case 'Shared':
          this.emailStatusString = this.translate.instant('SHARED');
          break;
        default:
          this.emailStatusString = this.email?.emailStatusString;
          break;
      }

      if(this.email && this.email.offlineActivityId && this.email.offlineActivityId.includes('remoteurl_')){
        this.isCovisitorFieldEnabled = true;
      }

      this.initMessagesProcessView();

    }
  }

  private initMessagesProcessView() {
    this.getExternalChannel(this.email?.indskr_channelid);
    this.messagesProcessView = [];
    this.messagesProcessView.push({
      id: 'detailsprocess',
      title: this.translate.instant('DETAILS'),
      bindingElementId: 'details',
      isActive:true,
    });
    if(this.email?.channelType === ChannelType.EMAIL && this.email?.emailType !== EmailTemplateType.RemoteURL && !this.emailService.selectedActivity?.isMessageExternal){
      this.messagesProcessView.push({
        id: 'attachmentsheaderprocess',
        title: this.translate.instant('ATTACHMENTS_CAMEL_CASE'),
        bindingElementId: 'attachmentsheader',
        isActive:false,
      });
    }
    this.messagesProcessView.push({
      id: 'previewheaderprocess',
      title: this.translate.instant('PREVIEW'),
      bindingElementId: 'previewheader',
      isActive:false,
    });
    if(this.checkIfExternalChannelSelected && this.isNotesFeatureActionEnabled && !this.emailService.selectedActivity?.isPreviewEnabled){
      this.messagesProcessView.push({
        id: 'notesheaderprocess',
        title: this.translate.instant('NOTES'),
        bindingElementId: 'notesheader',
        isActive:false,
      });
      let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
      this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
    }
  }

  async fetchVersionUpdateTemplates() {
    if (!this.device.isOffline) {
      await this.emailService.fetchVersionUpdateTemplates().then(async () => await this.emailService.loadEmailTemplatesDataFromLocalDb());
    }
  }

  async selectMatchedTemplate() {
    let emailActvity = this.emailService.selectedActivity;
    if (emailActvity.emailActivityParties.length && !emailActvity.template_id && (this.emailService.filteredTemplates.length === 1 || this.emailService.matchedMessageTemplates.length === 1)) {
      if (this.emailService.matchedMessageTemplates.length === 1) {
        emailActvity.template_id = this.emailService.matchedMessageTemplates[0].indskr_emailtemplateid;
      } else {
        emailActvity.template_id = this.emailService.filteredTemplates[0].indskr_emailtemplateid;
      }
      let template = this.emailService.filteredTemplates.find(temp => temp.indskr_emailtemplateid === emailActvity.template_id);
      if (template) {
        emailActvity.templateRefProductId = template.product_id;
        await this.autoSelectTemplate(template);
      }
    }
  }

  async autoSelectTemplate(template) {
    let selectedChannel = template.channelTypes.find(ct => ct.channelType === this.emailService.selectedActivity.channelType);

    let emailBody = (selectedChannel ? selectedChannel.body : template.indskr_body);
    let request = {
      template_id: template.indskr_emailtemplateid,
      subject: template.indskr_email_subject,
      description: emailBody,
      product_id: template.product_id,
      indskr_therapeuticareaid: template.therapeuticareaId
    }
    await this.emailService.updateEmailActivity(request, EmailActionType.TEMPLATE_ADD);
  }

  private footerSetDate = null;
  private allowedToCallFooterMethod(): boolean {
    if (this.footerSetDate) {
      const millisecondBetweenTwoDate = Math.abs((new Date().getTime() - this.footerSetDate.getTime()));
      // To avoid repeated calls for the footer init.
      if (millisecondBetweenTwoDate > 200) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  private initFooter() {
    const email: EmailActivity = this.emailService.selectedActivity;
    // check exteranl/preview channel
    if (email && email.indskr_channelid) {
      let selectedChannelId = email.indskr_channelid;
      this.emailService.getChannelInfo(selectedChannelId);
    }
    if (this.emailService.viewType === EmailViewType.EMAIL_FROM_MEETING_PRESENTATION) {
      // @ts-ignore
      this.footerService.initButtons(FooterViews.PREVIEW_MEETING_ACTIVITY);
    } else if (this.emailService.selectedActivity && this.emailService.selectedActivity.isMessageExternal) {
        this.footerService.initButtons(FooterViews.MESSAGE_NON_NATIVE);
    } else {
      if (this.allowedToCallFooterMethod()) {
        const options = {
          sendEmail: {
            show: true,
            isActive: this.canSendMessage(),
          }
        };
        this.footerService.initButtons(FooterViews.EmailDetails, options);
        this.footerSetDate = new Date();
      }
    }
  }

  captureConsent(emailActivityParty: EmailActivityParty) {
    this.setUpProductsChannelsContactInfoForConsent(emailActivityParty);

    // To show Toast Notification  “You have selected a template that is not associated with
    // your product, please change the template” when user clicks on the exclamation mark
    if (this.authService.user.isProductConsent) {
      if (_.isEmpty(this.consentTermGenerateService.selectedProducts)
        && !_.isEmpty(this.consentTermGenerateService.selectedChannels)
        && this.consentTermGenerateService.contact) {
        this.notificationService.notify(
          this.translate.instant('ALERT_CONSENT_CHANGE_THE_TEMPLATE'),
          "Change The Template",
          "top",
          ToastStyle.DANGER,
          3000,
          true
        );
        return;
      }
    }

    if (this.email?.channelType == ChannelType.FACEBOOK && !this.consentTermGenerateService.checkIfFacebookChannelSelected()) {
      this.consentTermGenerateService.showMessageFacebook();
      return;
    }
    this.alertService.showAlert({
      header: this.translate.instant('OBTAIN_CONSENT'),
      message: this.translate.instant('ALERT_CONSENT_CAPTURE_FOR_CONTACT', { contactName: emailActivityParty.contact_firstname + " " + emailActivityParty.contact_lastname })
    }, this.translate.instant('OBTAIN')
    ).then(res => {
      if (res.role == "ok") {
        this.callGenerateTerms();
      }
    });
  }

  callGenerateTerms() {
    const selectedContacts: Contact[] = [];

    // To check whether Product Consent flag is true for this Business Unit
    // then continue with the existing flow of check Consent submitted for Products & Channels
    // else go for new flow to check Consent submitted for Products only
    if (this.authService.user.isProductConsent) {
      if (this.consentTermGenerateService.selectedProducts.length
        && this.consentTermGenerateService.selectedChannels.length
        && this.consentTermGenerateService.contact) {
        let generatedTerms: ConsentTerm[] = this.consentTermGenerateService.generateConsentTerms();
        if (generatedTerms.length) {
          // @ts-ignore
          generatedTerms = generatedTerms.sort((a, b) => a.indskr_consentTermName.localeCompare(b.indskr_consentTermName));
          generatedTerms[0].isSelectedTerm = true;
          this.consentService.selectedConsentTerm.next(generatedTerms[0]);
          this.consentService.allGeneratedTerms.next(generatedTerms);
          this.consentService.consentTab = FooterViews.ConsentCapture;
          this.uiService.consentFlipButton = true;
          this.emailService.selectedActivity.emailActivityParties.forEach(e => {
            selectedContacts.push(this.contactService.contacts.find(c => c.ID === e.indskr_contactid));
          });
          // if (this.emailService.viewType !== EmailViewType.CREATE_FROM_PHONE_CALL) {
          //   this.activityService.selectedActivity['contacts'] = selectedContacts;
          // }
          if (this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING ||
            this.emailService.viewType === EmailViewType.CREATE_FROM_PHONE_CALL) {
            if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
              setTimeout(()=>{
                document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
              });
            }
            this.navCtrl.push(ConsentDetailsComponent, {}, { progressAnimation: false });
            // this.navService.pushWithPageTracking(ConsentDetailsComponent,PageName.ConsentDetailsComponent);
          } else {
            this.navService.pushWithPageTracking(
              ConsentDetailsComponent,
              PageName.ConsentDetailsComponent, { from : PageName.EmailDetailsPageComponent }
            );
          }
        }
      }
    }
    else {
      if (this.consentTermGenerateService.selectedChannels.length
        && this.consentTermGenerateService.contact) {
        let generatedTerms: ConsentTerm[] = this.consentTermGenerateService.generateConsentTerms();
        if (generatedTerms.length) {
          // @ts-ignore
          generatedTerms = generatedTerms.sort((a, b) => a.indskr_consentTermName.localeCompare(b.indskr_consentTermName));
          generatedTerms[0].isSelectedTerm = true;
          this.consentService.selectedConsentTerm.next(generatedTerms[0]);
          this.consentService.allGeneratedTerms.next(generatedTerms);
          this.consentService.consentTab = FooterViews.ConsentCapture;
          this.uiService.consentFlipButton = true;
          this.emailService.selectedActivity.emailActivityParties.forEach(e => {
            selectedContacts.push(this.contactService.contacts.find(c => c.ID === e.indskr_contactid));
          });
          // if (this.emailService.viewType !== EmailViewType.CREATE_FROM_PHONE_CALL) {
          //   this.activityService.selectedActivity['contacts'] = selectedContacts;
          // }
          if (this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING ||
            this.emailService.viewType === EmailViewType.CREATE_FROM_PHONE_CALL) {
            if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
              setTimeout(()=>{
                document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
              });
            }
            this.navCtrl.push(ConsentDetailsComponent, {}, { progressAnimation: false });
            // this.navService.pushWithPageTracking(ConsentDetailsComponent,PageName.ConsentDetailsComponent);
          } else {
            this.navService.pushWithPageTracking(
              ConsentDetailsComponent,
              PageName.ConsentDetailsComponent, { from : PageName.EmailDetailsPageComponent }
            );
          }
        }
      }
    }
  }

  setUpProductsChannelsContactInfoForConsent(emailActivityParty: EmailActivityParty) {
    this.consentTermGenerateService.selectedProducts = [];
    this.consentTermGenerateService.selectedChannels = [];
    if (this.brandOfflineService.brands && this.brandOfflineService.brands.length) {
      const brand = this.brandOfflineService.brands.find(e => e.ID === this.email?.templateRefProductId);
      if (brand) {
        this.consentTermGenerateService.selectedProducts.push(new Product(brand.ID, brand.name, true));
      }
    }
    const channels: Channel[] = this.consentService.allConsentChannelSubject.getValue();
    if (channels.length) {
      const channel = channels.find(ch => ch.indskr_consentType === this.email?.channelType);
      if (channel) {
        channel.isChecked = true;
        this.consentTermGenerateService.selectedChannels.push(channel);
      }
    }
    this.consentTermGenerateService.contact = this.contactService.contacts.find(c => c.ID === emailActivityParty.indskr_contactid);
    this.contactService.contactInformation = this.consentTermGenerateService.contact;
  }

  public openEmailTemplatePage() {
    if (this.disabled) return;
    this.uiService.prevView = this.uiService.activeView;
    this.uiService.activeView = 'template';
    // this.navService.pushWithPageTracking(EmailTemplatePageComponent, PageName.EmailTemplatePageComponent);
    this.navigate(EmailTemplatePageComponent, PageName.EmailTemplatePageComponent);
  }

  public openTemplatePersonalizePage() {
    if (this.disabled || this._isPersonalizeDisabled) return;
    // this.navService.pushWithPageTracking(TemplatePersonalizePageComponent, PageName.TemplatePersonalizePageComponent);
    this.navigate(TemplatePreviewComponent, PageName.TemplatePreviewComponent);
  }

  public openAttachmentPage() {
    if (this.emailService.isResourceDisabled || this.disabled) return;
    this.resourceService.viewMode = ResourceView.ADDTOEMAIL;
    //required for TC-495
    this.uiService.prevView = this.uiService.activeView;
    this.uiService.activeView = 'resource';
    // this.navService.pushWithPageTracking(ResourcePageComponent, PageName.ResourcePageComponent);
    this.navigate(ResourcePageComponent, PageName.ResourcePageComponent);
  }

  gotoContactSelection() {
    this.isClickedContact = true;
    // if (this.authService.hasFeatureAction(FeatureActionsMap.CONSENT_TOOL) && !this.email.template_id) {
    //   if (this.email.status == 1) {
    //     Deliver toast noti
    //   }
    // }
    this.assignChannelActivityIfUndefined()
    if (this.email && this.email.emailActivityParties && this.email.emailActivityParties.length === 0 && (this.activityService.teamViewActive || this.backgroundUploadInProgress || this.isReadOnlyRecommendedMessage)) return;
    this.contactService.accessedContactListFrom = PageName.EmailDetailsPageComponent
    if (this.email?.status === 1 && this.emailService.viewType !== EmailViewType.FROM_XPERIENCES && !this.activityService.teamViewActive && !this.backgroundUploadInProgress && !this.isReadOnlyRecommendedMessage) {
      this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
    } else {
      this.contactService.contactPageMode = ComponentViewMode.READONLY;
    }
    //go to contact selection screen
    if (this.inMeetingFlow || this.inPhoneCallFlow) {
      this.uiService.prevView = this.uiService.activeView;
      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'contactDetails';
    } else {
      this.uiService.prevView = 'ActivitiesPageRightPaneNav';
      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'contactDetails';
    }
    this.navigate(ContactPageComponent, PageName.ContactPageComponent);
  }

  private navigate(component: any, page: PageName) {
    if (this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING ||
      this.emailService.viewType === EmailViewType.CREATE_FROM_PHONE_CALL) {
      if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        setTimeout(()=>{
          document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
        });
      }
      this.navCtrl.push(component, this.data, { progressAnimation: false });
      return;
    }
    this.navService.pushWithPageTracking(component, page, null, page);
  }

  openAccompainedUsers() {
    //if (!this.isReadOnlyJointMeeting && !this.activityService.selectedActivity.isCompleted) {
    if (this.email?.emailStatus != EmailStatusCodes.Draft) return;
    // this.trackingService.tracking('Add a user (rep / manager)  to the accompanied user field');
    // this.trackingService.tracking('Access Joint Meeting Tool via Meeting');
    this.ioUserSelection.setCaseSelectorSource("Team Members");
    this.ioUserSelection.setSelectedInputSource(this.email["accompaniedUserList"]);
    this.ioUserSelection.setSelectionDataSource(this.activityService.repUserList);

    this.uiService.showNewActivity = false;
    this.uiService.prevView = this.uiService.activeView;
    if (this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING ||
      this.emailService.viewType === EmailViewType.CREATE_FROM_PHONE_CALL) {
      if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        setTimeout(()=>{
          document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
        });
      }
      setTimeout(() => {
        this.navCtrl.push(IOMultiSelect, {selectionFor:'EmailActivity'}, { progressAnimation: false });
      }, 10);
      return;
    } else {
      setTimeout(() => {
        this.navService.pushWithPageTracking(IOMultiSelect, PageName.IOMultiSelect, {selectionFor:'EmailActivity'}, PageName.IOMultiSelect);
      }, 10);
    }

  }



  public async openContactEmailAddress(emailActivityParty: EmailActivityParty, myEvent) {
    if (!this.popover) {
      this.popover = await this.popoverCtrl.create({
        component: PopoverComponent,
        componentProps: { emailAddressList: emailActivityParty.emailAddresses, field: 'emailaddress' },
        event: myEvent
      });
      this.popover.onDidDismiss().then((data) => {
        if (data && data.emailaddress) {
          this.selectedEmailAddress.push(data.emailaddress);
        }
        this.popover = undefined;
      });
      this.popover.present();
    }
  }

  async removeContactEmailAddress(emailActivityParty: EmailActivityParty) {
    const loader = await this.loadingCtrl.create();
    loader.present();
    const emailActivityParties: EmailActivityParty[] = this.email?.emailActivityParties;
    emailActivityParties.splice(emailActivityParties.indexOf(emailActivityParty), 1);
    this.emailService.updateEmailActivityParties(emailActivityParties).then(() => {
      loader.dismiss();
      this.isClickedContact = true;
    }).catch(() => loader.dismiss());
  }

  async removeCovisitor(covisitor:AccompainedUser) {
    let data = this.email?.accompaniedUserList;
    let idx = this.email?.accompaniedUserList.findIndex(a=> a.id == covisitor.id);
    if(idx >= 0){
      data.splice(idx,1);
      this.updateAccompaniedUsers(data);
    }
  }

  parentEventObject: any = null;

  parentOpenEmailAddress(event) {
    this.parentEventObject = event;
  }

  async openEmailAddress(event: Event, selected) {
    //added correct event for handling popover event (OMNI-188800)
    if(event) event.stopPropagation();
    let emailParty: EmailActivityParty;
    this.email?.emailActivityParties.filter(emailActivityParty => emailActivityParty.indskr_contactid === selected.indskr_contactid)
      .forEach(emailActivityParty => { 
        if (emailActivityParty.indskr_contactid) {
          const contact: Contact = this.contactService.getContactByID(emailActivityParty.indskr_contactid);
          if (contact) {
            if (contact.emailAddressList && contact.emailAddressList.length > 0) {
              const contactEmailAddressSet = new Set(contact.emailAddressList.map(e => e.emailAddress));
              const filteredData = emailActivityParty['emailAddresses']?.filter(ep => contactEmailAddressSet.has(ep.emailAddress));
              emailActivityParty['emailAddresses'] = filteredData;
            } else {
              emailActivityParty['emailAddresses'] = [];
            }
          }
        }
        emailParty = emailActivityParty; 
      });

      if (this.contactSelectedArea) {
        this.contactSelectedArea.nativeElement.click();
        if (this.parentEventObject) {
          this.executeOpenEmailAddress(this.parentEventObject, emailParty);
        }
      }
  }

  assignChannelActivityIfUndefined() {
    if(this.emailService.selectedActivity.channelActivityType === undefined) {
      const channel: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === this.email.indskr_channelid);
      if (channel != undefined) {
      this.emailService.selectedActivity.channelActivityType = this.filterChannelActivityType(channel.activityType);
      }
    }
  }

  private async executeOpenEmailAddress(event, emailParty) {
    if (!this.popover) {
      this.popover = await this.popoverCtrl.create({
        component: PopoverComponent,
        componentProps: {
          emailActivityParty: emailParty,
          field: 'emailActivityParty'
        },
        cssClass: 'emailAddress-popover',
        event: event
      });
      this.popover.onDidDismiss().then((data) => {
        this.popover = undefined;
      });
      this.popover.present()
    }
  }

  private updateHTMLOutline() {
    // Here we will loop through all the elements of previewHTML
    // and then try to hide the outline.
    if (this.templatePreview.length) {
      let parentElement = document.getElementById('previewHTML');
      if (parentElement) {
        let descendents = parentElement.getElementsByTagName('*');
        if (descendents.length) {
          for(var index = 0; index < descendents.length; index++) {
            const current = descendents[index];
            // current.setAttribute('outline', 'none');
            // current.setAttribute('outline:focus', 'none');
            (current as HTMLElement).style.outline = 'none';
          }
        }
      }
    }
  }

  async editSubject(event) {
    if(this.disabled || this.email?.subjectDisabled || this.isCompletedActivity || this.email['indskr_recommendedmessage'] || this.email?.channelType === ChannelType.WECOM) return;
    if (this.authService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT) || (this.checkIfExternalChannelSelected && !this.isCompletedActivity) || !this.email.subjectDisabled) {
      const emailSubject: string = (event.target.value && event.target.value.length > 0) ? event.target.value : 'Message';
      if (event !== null && event.value !== null) {
        if (this.email?.subject !== emailSubject) {
          this.trackingService.tracking('EmailSubjectUpdated', TrackingEventNames.EMAIL);
          this.emailService.updateEmailActivity({ subject: emailSubject }, EmailActionType.SUBJECT);
        }
      }
    }
  }

  async showAlert(message, title) {
    return new Promise<boolean>(async resolve => {
      const alert = await this.alertCtrl.create({
        header: title,
        subHeader: message,
        backdropDismiss: false,
        buttons: [
          {
            text: this.translate.instant('CANCEL'),
            role: 'cancel',
            handler: () => { resolve(false); }
          },
          {
            text: this.translate.instant('YES'),
            handler: () => {
              resolve(true);
            }
          }
        ],
      });
      alert.present();
    });
  }

  canSendMessage() {
    if(this.email?.channelType === ChannelType.WECOM) return false;
    if (this.checkIfExternalChannelSelected) {
       return true;
    }
    if (this.email) {
      // if (this.device.isOffline && this.email.channelType !== ChannelType.EMAIL) return false;
      if (this.disabled) return false;
      if (!this.email.template_id) return false;
      if (this.email.emailActivityParties.length == 0 && this.email.accompaniedUserList.length == 0) return false;
      if (this.emailService.disableSendButton) return false;
      if (this.device.isOffline && this._isRTETokenEmbedded) return false;
      if (this.device.isOffline && this._isUserPictureTokenEmbedded) return false;
      return true;
    }

    return false;
  }

  canShareMessage() {
    if(this.email?.channelType === ChannelType.WECOM) return false;
      if (this.isCompletedActivity) return false;
      if (this.checkIfExternalChannelSelected && (this.email?.channelActivityType == ChannelActivityType.SMS || this.email.channelActivityType == ChannelActivityType.WHATSAPP)) {
        if (this.email?.emailActivityParties && this.email?.emailActivityParties.length > 0) {
          if (!this.email?.emailActivityParties[0]?.indskr_contactid) {
            return false;
          }
          if (!this.email?.template_id) {
            return false;
          }
        } else return false;
        if (this.disabled) return false;
      }
      return true;
  }

  canMarkComplete() {
    if(this.email?.channelType === ChannelType.WECOM) return false;
    if (this.isCompletedActivity) return false;
    if (this.checkIfExternalChannelSelected && this.email && (this.email.channelActivityType == ChannelActivityType.SMS || this.email.channelActivityType == ChannelActivityType.WHATSAPP)) {
      if (this.email.emailActivityParties && this.email.emailActivityParties.length > 0) {
        if (!this.email.emailActivityParties[0].indskr_contactid) {
          return false;
        }
      } else return false;
      if (this.disabled) return false;
    }
    return true;
  }

  private validateConsent(): boolean {
    const templateProductId = this.emailService.getProductIdFromTemplate(this.email?.template_id);
    for (let i = 0; i < this.email?.emailActivityParties.length; i++) {
      const customer = this.email?.emailActivityParties[i];
      const contact = { ID: customer.indskr_contactid };
      switch (this.email?.channelType) {
        case ChannelType.WHATSAPP:
        case ChannelType.SMS:
          contact['mobilePhone'] = customer.contact_mobilephone;
          break;
        case ChannelType.FACEBOOK:
          contact['indskr_facebookpsid'] = customer.contact_indskr_facebookpsid;
          break;
        case ChannelType.EMAIL:
          contact['emailAddressList'] = customer.emailAddresses;
        default:
          break;
      }
      const canSend = this.consentService.consentValidationBeforeSend(contact, this.email?.channelType, this.email?.scheduledStart, templateProductId) ? true : false;
      if (!canSend) {
        return false;
      }
    }
    return true;
  }

  /*private notifyInvalidConsent(message: string = null) {
    if (message === null) {
      message = this.translate.instant('EMAIL_INVALID_CONSENT');
    }
    this.notificationService.notify(message, '', 'top', ToastStyle.DANGER, 3000, true);
  }*/

  async send() {
    if (!this.canSendMessage()) return;
    let failed = false;
    switch (this.email?.channelType) {
      case ChannelType.SMS:
        if (!await this.showAlert(this.translate.instant('EMAIL_ACTIVITY_SURE_SEND_SMS'), 'Message')) return;
        this.checkIfExternalChannelSelected ? this.sendExternalActivity() : this.emailService.sendEmailActivity(this.emailService.selectedActivity.activityid, false);
        break;
      case ChannelType.FACEBOOK:
      //case ChannelType.WHATSAPP:
        try {
          const template: ResourceEmailTemplate = this.emailService.emailTemplates.find(templ => { return templ.indskr_emailtemplateid == this.email?.template_id });
          await this.localizationService.initMultilingualFieldsData()
          const { templatePreview } = this.emailService.personalizeTemplateBody(this.email?.description, {
            user: this.authService.user,
            tokens: template.content_tokens,
            productId: template.product_id,
            activity: this.activityService.selectedActivity
          })
          switch (this.email?.channelType) {
            case ChannelType.FACEBOOK:
              /*if (!this.validateConsent()) {
                this.notifyInvalidConsent();
                return;
              }*/
              if (!await this.showAlert(this.translate.instant('EMAIL_ACTIVITY_SEND_FB_MESSGE'), this.translate.instant('MESSAGE'))) return;
              await this.localizationService.initMultilingualFieldsData()
              for (const activityParty of this.email?.emailActivityParties) {
                const { templatePreview: preview } = this.emailService.personalizeTemplateBody(templatePreview, { contactId: activityParty.indskr_contactid });
                await this.dms.sendFacebookMessage({ body: preview, recipient: activityParty.contact_indskr_facebookpsid }).catch(() => { failed = true });
              }
              break;
            // case ChannelType.SMS:
            //   /*if (!this.validateConsent()) {
            //     this.notifyInvalidConsent();
            //     return;
            //   }*/
            //   if (!await this.showAlert(this.translate.instant('EMAIL_ACTIVITY_SURE_SEND_SMS'), 'Message')) return;
            //   this.emailService.sendEmailActivity(this.emailService.selectedActivity.activityid);
            //   // for (const activityParty of this.email.emailActivityParties) {
            //   //   const { templatePreview: preview } = this.emailService.personalizeTemplateBody(templatePreview, { contactId: activityParty.indskr_contactid });
            //   //   await this.dms.sendTextMessage({ body: preview, recipient: activityParty.contact_mobilephone }).catch(() => { failed = true });
            //   // }
            //   break;
            //case ChannelType.WHATSAPP:
              /*if (!this.validateConsent()) {
                this.notifyInvalidConsent();
                return;
              }
              if (!await this.showAlert(this.translate.instant('EMAIL_ACTIVITY_SURE_SEND_WHATS_APP'), 'Message')) return;
              await this.localizationService.initMultilingualFieldsData()
              for (const activityParty of this.email?.emailActivityParties) {
                const { templatePreview: preview } = this.emailService.personalizeTemplateBody(templatePreview, { contactId: activityParty.indskr_contactid });
                await this.dms.sendWhatsAppMessage({ body: preview, recipient: activityParty.contact_mobilephone }).catch(() => { failed = true });
              }*/
          }
          if (failed) {
            throw "Failed";
          } else {
            this.events.publish('messageActivitySent', this.emailService.selectedActivity);
          }
          const date = new Date();
          await this.emailService.updateEmailActivity({ statuscode: 3, statecode: 1, actualstart: date.toISOString(), actualend: date.toISOString() }, EmailActionType.STATUS);
        } catch {
          await this.emailService.updateEmailActivity({ statuscode: 8 }, EmailActionType.STATUS);
        }
        // this.activityService.isActivityUpdate = true;
        if (!this.uiService.toolsActivityActive) {
          this.events.publish("refreshAgenda");
        } else{
          this.uiService.agendaRefreshRequired = true;
        }

        this.emailService.setCurrentEmail(this.email);
        break;
      default:
        this.checkIfExternalChannelSelected ? this.sendExternalActivity() : this.sendEmail();
        break;
    }
    this.events.publish("initTodo");
    this.emailService.isRemoteEmail = false;
  }

  async sendEmail() {
    if (this.email?.emailType !== EmailTemplateType.RemoteURL && this.email?.description && this.email?.description.includes('%content.url%') && (!this.email?.emailAttachments || this.email?.emailAttachments.length == 0)) {
      this.notificationService.notify(this.translate.instant("EMAIL_ACTIVITY_MUST_HAVE_ONE_ATTACHMENT"), 'Email Activity Detail', 'top', ToastStyle.DANGER);
      return;
    }

    if (!this.isFromRemoteMeeting&& (this.email?.emailProductName === "" || this.email?.emailProductName === undefined)) {
      this.notificationService.notify(this.translate.instant("EMAIL_ACTIVITY_MUST_HAVE_AT_LEAST_ONE_PRODUCT"), 'Email Activity Detail', 'top', ToastStyle.DANGER);
      return;
     }

    /*if (!this.validateConsent()) {
      this.notifyInvalidConsent();
      return;
    }*/
    if (!await this.showAlert(this.translate.instant('EMAIL_ACTIVITY_SURE_SEND_EMAIL'), this.translate.instant('MESSAGE'))) return;
    if (this.emailService.isActionTriggeredFromFooter) { this.trackingService.tracking('EmailSentActionBar', TrackingEventNames.EMAIL, null, true); }
    else { this.trackingService.tracking('EmailSent', TrackingEventNames.EMAIL, null, true); }
    //reset the value for next iteration
    this.emailService.isActionTriggeredFromFooter = false;
    if(this.isCovisitorFieldEnabled && (this.email?.emailActivityParties == undefined || this.email?.emailActivityParties.length == 0)){
      await this.emailService.updateDummyEmailAddressInToField();
    }
    this.emailService.sendEmailActivity(this.emailService.selectedActivity.activityid, false);
  }

  sendExternalActivity() {
     if (!this.isFromRemoteMeeting && (this.email?.emailProductName === "" || this.email?.emailProductName === undefined)) {
       if (!this.emailService.selectedActivity?.isPreviewEnabled) {
        this._emptyProduct = true;
        this.notificationService.notify(this.translate.instant("EMAIL_ACTIVITY_MUST_HAVE_AT_LEAST_ONE_PRODUCT"), 'Email Activity Detail', 'top', ToastStyle.DANGER);
        return;
       }
     } else {
      this._emptyProduct = false;
     }

     if (this.hasMeetingAutoSubject === true) {
      if (this.email && this.email.subject) {
             this.email.subject =  this.emailService.getTitleOfEmailActivity() != 'Message' ? this.translate.instant('MESSAGE') + ' - ' + this.emailService.getTitleOfEmailActivity() : this.translate.instant('MESSAGE');
      }
     } else {
        this.assignChannelActivityIfUndefined()
        if(this.emailService.selectedActivity.channelActivityType === ChannelActivityType.SMS || this.emailService.selectedActivity.channelActivityType === ChannelActivityType.WHATSAPP) {
          this.email.subject = this.email?.emailSubject;
        }
     }
     if (!this.email?.isPreviewEnabled) {
      this.email.statuscode = EmailStatusCodes.Sent;
      this.email.statecode = 1;
     }
    this.emailService.isActionTriggeredFromFooter = false;
    this.emailService.sendEmailActivity(this.emailService.selectedActivity.activityid, true);
  }

  sendEmailActivity() {
    this.emailService.sendEmailActivity(this.email?.ID, false);
  }

  validateContactsHasConsents(date: Date): boolean {
    if (!this.authService.hasFeatureAction(FeatureActionsMap.MESSAGE_CONSENT_REQUIRED)) return true;
    const emailContacts: EmailActivityParty[] = this.email?.emailActivityParties;
    const invalidContacts: string[] = [];
    if (emailContacts && emailContacts) {
      const consents: ContactConsent[] = this.consentService.allActiveConsentSubject.getValue();
      if (consents.length && this.email?.template_id && this.email?.templateRefProductId) {
        emailContacts.forEach(ec => {
          const consent: ContactConsent = consents.find(cn => cn.indskr_contactid == ec.indskr_contactid);
          if (consent) {
            consent.activeConsents.forEach(ac => {
              if (ac.channels.some(ch => ch.indskr_consentType == ChannelType.EMAIL) &&
                !isWithinRange(date, startOfDay(parseFloat(ac.indskr_fromdate.toString())), endOfDay(parseFloat(ac.indskr_untildate.toString()))) && invalidContacts.indexOf(ec.indskr_contactid) == -1) {
                invalidContacts.push(ec.contact_firstname + " " + ec.contact_lastname);
              }
            });
          } else {
            if (invalidContacts.indexOf(ec.indskr_contactid) == -1) invalidContacts.push(ec.contact_firstname);
          }
        });
      }
    }
    if (invalidContacts.length) {
      this.notificationService.notify(this.translate.instant("EMAIL_ACTIVITY_NOT_HAVE_ACTIVE_EMAIL"), 'Email Activity Detail', 'top', ToastStyle.DANGER);
      return false;
    }
    return true;
  }

  closepage() {
    if (this.emailService.viewType == EmailViewType.FROM_XPERIENCES) {
      this.events.publish("go-back-to-xperiences", PageName.EmailDetailsPageComponent);
      return;
    }
    const currentPage: PageName = this.navService.getCurrentPageName();
    const currentPagecurrentMasterPage: PageName = this.navService.getCurrentMasterPageName();
    if (!this.inMeetingFlow && !this.inPhoneCallFlow && currentPage == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab != 'plans') {
      // this.activityService.isActivityUpdate = true;
      this.uiService.showRightPane = false;
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
      this.activityService.selectedActivity = undefined;
      if (this.device.shouldBeMobileView) {
        this.uiService.showRightPane = false;
        this.activityService.shouldScrollToPrevSelectedActivity = !this.activityService.didOpenDetailPageFromAgendaPage;
      }
      this.activityService.didOpenDetailPageFromAgendaPage = false;
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
    }
    if (!this.inMeetingFlow && !this.inPhoneCallFlow && currentPage == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab === 'plans') {
      // this.activityService.isActivityUpdate = true;
      this.uiService.showNewActivity = false;
      this.activityService.selectedActivity = undefined;
      this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      this.footerService.initButtons('');
      if (this.uiService.activitiesPagePlanTabSelectedPlan === 'scientificPlans' && this.uiService.contactDetailsSegment === 'timeline') {
        this.navService.popChildNavPageWithPageTracking();
      } else if (this.uiService.activitiesPagePlanTabSelectedPlan === 'planTabTodo') {
        if (this.device.shouldBeMobileView) {
          this.uiService.showRightPane = false;
        }
        this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
      } else {
        this.navService.popToRootChildNavPageWithPageTracking();
      }
    }
    if (currentPage == PageName.EmailDetailsPageComponent && currentPagecurrentMasterPage == PageName.ActivitiesDetailsPaneComponent) {
      // this.activityService.isActivityUpdate = true;
      this.uiService.showRightPane = false;
      this.activityService.selectedActivity = undefined;
      this.navService.popToRootWithPageTracking().then(() => {
        this.events.publish('scrollToDate');
      })
    }

    if (currentPagecurrentMasterPage == PageName.ScientificActivityPage && !this.inMeetingFlow) {
      // this.activityService.isActivityUpdate = true;
      this.uiService.showRightPane = false;
      this.uiService.showNewActivity = false;
      this.activityService.selectedActivity = undefined;
      this.footerService.initButtons('');
      if ((this.data && this.data.from === PageName.ContactDetailsComponent) || this.uiService.activitiesPagePlanTabSelectedPlan === 'scientificPlans') {
        this.navService.popChildNavPageWithPageTracking();
      } else {
        this.navService.popToRootChildNavPageWithPageTracking();
      }
    }
    else if (currentPage == PageName.ContactPageComponent && !(this.inMeetingFlow || this.inPhoneCallFlow)) {
      this.activityService.selectedActivity = undefined;
      this.uiService.showNewActivity = false;
      this.uiService.showRightPane = false;
      this.navService.popChildNavPageWithPageTracking();
      this.footerService.initButtons(FooterViews.Contacts);
      this.emailService.selectedActivity = null;
    }
    else if (currentPage == PageName.AccountPageComponent && !(this.inMeetingFlow || this.inPhoneCallFlow)) {
      this.activityService.selectedActivity = undefined;
      this.uiService.showNewActivity = false;
      this.uiService.showRightPane = false;
      this.navService.popChildNavPageWithPageTracking();
      this.footerService.initButtons(FooterViews.Accounts);
      this.emailService.selectedActivity = null;
    }
    else if (currentPagecurrentMasterPage == PageName.CallPlanComponent && !(this.inMeetingFlow || this.inPhoneCallFlow)) {
      // this.uiService.activeView = 'CallPlanDetails';
      this.callPlanService.callPlanPageView = 'CallPlanDetail';
      this.navService.popChildNavPageWithPageTracking();
      this.uiService.showNewActivity = false;
      this.footerService.initButtons('');
    }
    else if (currentPagecurrentMasterPage == PageName.CustomerCallPlanPage && !(this.inMeetingFlow || this.inPhoneCallFlow)) {
      // this.uiService.activeView = 'CallPlanDetails';
      this.callPlanService.callPlanPageView = 'CallPlanDetail';
      this.navService.popChildNavPageWithPageTracking();
      this.uiService.showNewActivity = false;
      this.footerService.initButtons('');
    }
    else if (this.inMeetingFlow) {
      this.emailService.viewType = undefined;
      this.inMeetingFlow = false;
      this.modalCtrl.dismiss();
      this.emailService.selectedActivity = null;
      this.events.publish("initTimePickerLimitedMinTime");
      this.events.publish('scrollToDate');
      if (this.emailService.viewType == EmailViewType.EMAIL_FROM_MEETING_PRESENTATION) {
        this.inMeetingPresentationFlow = false;
      }
      if (currentPage == PageName.PresentationMeetingComponent) {
        this.uiService.activeView = 'Meeting';
        this.footerService.initButtons(FooterViews.PreviewMeeting);
      }
      else if (
        currentPage == PageName.ActivitiesPageComponent
        || currentPage === PageName.ContactPageComponent
      ) {
        this.goBackToAppointment();
      } else if (currentPage == PageName.CallPlanComponent || currentPage == PageName.CustomerCallPlanPage) {
        this.callPlanService.callPlanPageView = "CallPlanNewMeetingDetails";
        this.footerService.initButtons(FooterViews.Activities);
      }
    } else if (this.inPhoneCallFlow) {
      this.emailService.viewType = undefined;
      this.inPhoneCallFlow = false;
      this.modalCtrl.dismiss();
      this.emailService.selectedActivity = null;
      this.events.publish('scrollToDate');
      // if(currentPage == PageName.ActivitiesPageComponent){
      //   this.goBackToPhoneCall()
      // }
      this.goBackToPhoneCall()
    }
    this.emailService.isRemoteEmail = false;
  }

  ngOnDestroy() {
    this.checkIfExternalChannelSelected  = false
    this.isFromRemoteMeeting = false;
    // adding this condition to avoid the footer events unsubscription when coming from consent details page
    // ngDestroy id getting invoked even though we are in this page
    if(!this.uiService.isEmailAccessedFromConsnentDetails) {
      this.uiService.isEmailAccessedFromConsnentDetails = false;
    }
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    // this.contactService.accessedContactListFrom = null;
    // this.events.unsubscribe('activities-pane:emailSelected');
    console.log('destroying email details page');
    if (this.IcurrentEmailSubscription) {
      this.IcurrentEmailSubscription.unsubscribe();
      this.ISelectionSubscription.unsubscribe();
    }
    if(this.multiSelectSubscription){
      this.multiSelectSubscription.unsubscribe();
    }
    this.dateFormatSubscription.unsubscribe();
    this.timeFormatSubscription.unsubscribe();
    // this.emailService.selectedActivity = null;
  }

  async updateEmailResource(val: Resource[]) {
    let selectedResource: Resource[] = [];
    selectedResource = Object.assign([], val);
    this.resourceService.setEmailResource(undefined);
    // this.emailService.detectResourceAddedOrRemoved(selectedResource);
  }

  openResourceInterationDetails(attachment) {
    if (this.emailService.selectedActivity.statuscode === EmailStatusCodes.Sent) {
      this.emailService.getResourceInteractionDetails(attachment);
      this.uiService.prevView = this.uiService.activeView;
      this.uiService.activeView = 'ResourceContactList';
      if (this.email?.isFromXperiences) return;
      if (this.inMeetingFlow || this.inPhoneCallFlow) {
        if (this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING || this.emailService.viewType === EmailViewType.CREATE_FROM_PHONE_CALL) {
          this.navCtrl.push(ResourceInteractionDetailComponent, this.data, { progressAnimation: false });
        }
        else
          this.navService.pushChildNavPageWithPageTracking(ResourceInteractionDetailComponent, PageName.ResourceInteractionDetailComponent, PageName.PresentationMeetingComponent);
      }
      else
        this.navService.pushChildNavPageWithPageTracking(ResourceInteractionDetailComponent, PageName.ResourceInteractionDetailComponent, PageName.PresentationMeetingComponent);
    }
  }

  scrapMessage() {
    this.emailService.isRemoteEmail = false;
    if (this.isScrapEnabled) {
      if (this.inPhoneCallFlow && this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent) {
        if (this.modalView) {
          this.scrapEmail();
        }
        return
      }
      this.scrapEmail();
    }
  }

  private initEmailDetailsPageTitle(): void {
    const cha: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === this.email?.indskr_channelid);
    this.emailService.selectedActivity.isPreviewEnabled = cha?.indskr_enablepreview === true ? true : false;
    const buttons = [];
    if (this.enableBackButtonOnDetails) {
      buttons.push({
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      })
    }
    buttons.push(
      {
        id: "scrap",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('SCRAP'),
        isDisabled: !this.isScrapEnabled,
        align: "right"
      },
      {
        id: "send",
        imgSrc: this.sendButtonImgSrc,
        name: this.sendButtonName,
        isDisabled: this.backgroundUploadInProgress ? true : (this.checkIfExternalChannelSelected?  this.emailService.selectedActivity?.isPreviewEnabled ? !this.canShareMessage() : !this.canMarkComplete() : !this.canSendMessage()),
        align: "right"
      }
    );
     this.getExternalChannel(this.email?.indskr_channelid);
    this.setChannel(this.email.channelType);
    this.emailDetailsPageTitle = {
      id: 'email-details-page-title',
      title: _.isEmpty(this.getSubjectValue()) ?  this.messageChannel : this.getSubjectValue(),
      // title: this.messageChannel + (this.email?.subject == 'Message' ? '' : ' - ' + this.email?.subject),
      controls: buttons,
    };
    // if(this.emailService.viewType == EmailViewType.FROM_XPERIENCES) {
    //   this.emailDetailsPageTitle.title = this.messageChannel + (this.email.subject == 'Message' ? '' : ' - ' + this.email.subject);
    // }
  }

  get sendButtonImgSrc() {
    return this.email.channelType === ChannelType.WECOM
      ? 'assets/imgs/header_send.svg' :
      (this.checkIfExternalChannelSelected || this.emailService.selectedActivity?.isMessageExternal)
        ? this.emailService.selectedActivity?.isPreviewEnabled
          ? 'assets/imgs/share.svg'
          : 'assets/imgs/header_complete.svg'
        : 'assets/imgs/header_send.svg'
  }

  get sendButtonName() {
    return this.email.channelType === ChannelType.WECOM
      ? this.translate.instant('SEND') :
    (this.checkIfExternalChannelSelected  || this.emailService.selectedActivity?.isMessageExternal)
    ? this.emailService.selectedActivity?.isPreviewEnabled
    ? this.translate.instant('SHARE')
    : this.translate.instant('MARK_COMPLETE')
    : this.translate.instant('SEND')
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closepage();
        break;
      case 'scrap':
        this.scrapMessage();
        break;
      case 'send':
        this.checkIfExternalChannelSelected? this.sendExternalActivity(): this.send();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  private getTemplateSubject(template_id): string {
    const template: ResourceEmailTemplate = this.emailService.emailTemplates.find(temp => temp.indskr_emailtemplateid === template_id);
    if(template){
    return template.indskr_email_subject;
    }else{
      return '';
    }
  }

  getSubjectValue() {
    // External channel message with turned off preview
   /* if (this.checkIfExternalChannelSelected && !this.email?.isPreviewEnabled) {
      // autoSubject FA
      if (this.hasMeetingAutoSubject && this.email && this.email.subject) {
        this.email.subject = this.emailService.getTitleOfEmailActivity() != 'Message' ? this.emailService.getTitleOfEmailActivity() : this.translate.instant('MESSAGE');
        return this.emailService.getTitleOfEmailActivity() != 'Message' ? this.translate.instant('MESSAGE') + ' - ' + this.emailService.getTitleOfEmailActivity() : this.translate.instant('MESSAGE');
      } else return this.email.emailSubject || '';
      // message with template
    } else {*/
      return this.email ? this.email.emailSubject : '';
    // }
  }

  /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
  public getAccountFormField(): IndFormFieldViewDataModel {
    const isSelectedAccounts: boolean = this.emailService.selectedActivity && this.emailService.selectedActivity?.accounts && this.emailService.selectedActivity.accounts.length > 0;

    const isAccountRequired = this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_CONTACT_MANDATORY)
      || this.isAccountVisitRecord;

    const isAccountFormDisabled: boolean = !(this.emailService.selectedActivity) || this.emailService.selectedActivity && this.emailService.selectedActivity?.emailStatus != EmailStatusCodes.Draft && !isSelectedAccounts;

    const isAccountFormShowArrow: boolean = this.emailService.selectedActivity ? this.emailService.selectedActivity?.emailStatus != EmailStatusCodes.Draft && !isSelectedAccounts ? false : true : false;

    const placeholderText: string = this.emailService.selectedActivity && this.emailService.selectedActivity?.emailStatus == EmailStatusCodes.Draft ? this.translate.instant('SELECT_ACCOUNTS') : !isSelectedAccounts ? this.translate.instant('NO_ACCOUNT') : '';

    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNTS'),
      inputText: (this.emailService.selectedActivity && this.emailService.selectedActivity.accounts && (this.emailService.selectedActivity).accounts.length != 0) ? (this.emailService.selectedActivity).accountString : '',
      customPlaceholderLabel: placeholderText,
      id: 'account-field',
      isReadOnly: true,
      isRequired: isAccountRequired,
      isEmptyRequiredField: true,
      isDisabled: isAccountFormDisabled,
      showArrow: isAccountFormShowArrow,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }
  /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/

  public getSubjectFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('SUBJECT'),
      inputText: this.getSubjectValue(),
      inputValue: (this.email && this.email.emailSubject) ? this.email.emailSubject : '',
      id: 'subject-field',
      isReadOnly: this.updateSubjectState(),
      isDisabled:  this.updateSubjectState() || !this.authService.user.buConfigs['indskr_messageactivitysubjecteditable'],
      placeholderLabel: this.translate.instant('SUBJECT'),
      showArrow: !this.updateSubjectState(),
      isRequired: !this.isCompletedActivity && !this.updateSubjectState(),
      formFieldType: FormFieldType.INLINE_INPUT,
      displayInfoText: this.email['indskr_recommendedmessage'],
      infoText: this.translate.instant('SUGGESTED_MESSAGE'),
      infoTextCSSClass: 'orange-info-message-text',
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    if(this.emailDetailsPageTitle){
      const index = this.emailDetailsPageTitle.controls.findIndex((c) => c.id === 'send');
      if (index) {
        this.emailDetailsPageTitle.controls[index].isDisabled = this.backgroundUploadInProgress ? true : (this.checkIfExternalChannelSelected ? this.emailService.selectedActivity?.isPreviewEnabled ? !this.canShareMessage() : !this.canMarkComplete() : !this.canSendMessage());
      }
    }
    return viewData;
  }

  // public getCovisitorFormField(): IndFormFieldViewDataModel {
  //   let viewData: IndFormFieldViewDataModel;
  //   viewData = {
  //     label: this.translate.instant('ACTIVITY_DETAILS_COVISITORS'),
  //     inputText: this.email.accompaniedUserList?.length ? this.email.accompaniedUserString : '',
  //     id: 'covisitor-field',
  //     isReadOnly:true,
  //     placeholderLabel: 'Covisitor',
  //     isDisabled: (this.email.emailStatus!=EmailStatusCodes.Draft),
  //     showArrow: (this.email.emailStatus==EmailStatusCodes.Draft),
  //     formFieldType: FormFieldType.NEW_PAGE_SELECT,
  //     eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
  //   };
  //   return viewData;
  // }

  private updateSubjectState(): boolean {
    if (this.email['indskr_recommendedmessage'] || this.email?.channelType === ChannelType.WECOM) return true;
    else if (this.authService.hasFeatureAction(FeatureActionsMap.MEETING_AUTO_SUBJECT) && !this.email?.isPreviewEnabled) {
        return true;
    } else if (this.email?.isPreviewEnabled && this.email?.subjectDisabled) {
        return true;
    } else if(this.checkIfExternalChannelSelected && !this.isCompletedActivity) {
        return false;
    } else {
        return this.disabled || this.emailService?.selectedActivity?.subjectDisabled
    }
  }

  private initSuggestReason() {
    let suggestReasonField: IndFormFieldViewDataModel = {
      label: this.translate.instant('SUGGEST_REASON'),
      inputText: this.email.indskr_reason,
      inputValue: this.email.indskr_reason,
      id: 'suggest-reason-field',
      isReadOnly: true,
      isDisabled: true,
      placeholderLabel: this.translate.instant('SUGGEST_REASON'),
      showArrow: false,
      isHidden: !this.email.indskr_recommendedmessage,
      isFieldNextIcon: true,
      imgSrc: 'assets/imgs/field_star.svg',
      formFieldType: FormFieldType.DATA_SUBMITTED
    };
    this.suggestReasonFormField = suggestReasonField;
  }

  private initDateTimeFormFields() {
    const startDateValue = this.formattedScheduledTime[0]
    let startDateTimeValue: Date = new Date(this.email?.scheduledEnd);
    const startDateTimeStr = startDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    let sentDate, sentDateValue, sentTimeValue, sentDateTimeStr;
    // check exteranl/preview channel
    if (this.email && this.email.indskr_channelid) {
      let selectedChannelId = this.email.indskr_channelid;
      this.emailService.getChannelInfo(selectedChannelId);
    }
    let dateEditable: boolean = false;
    if (this.email && this.email.channelActivityType == ChannelActivityType.EMAIL) {
      if (this.email && this.email?.emailStatus == 1 && this.email?.ownerId === this.authService.user.systemUserID && !this.email?.isFromXperiences) {
        dateEditable = true;
      } else dateEditable = false;
    } else {
      // external channel only (except share message)
      if (this.email && (this.email.isMessageExternal && !this.email.isPreviewEnabled)) {
        if (this.email && this.email?.emailStatus == 1 && this.email?.ownerId === this.authService.user.systemUserID && !this.email?.isFromXperiences) {
          dateEditable = true;
        }
      } else dateEditable = false;
    }
    if (this.email && (this.email?.statuscode === 3 || this.email?.statuscode === 8) ){
      sentDate = this.emailService.getSentTime();
      sentDateValue = sentDate[0];
      sentTimeValue = sentDate[1];
      sentDateTimeStr = moment(sentDateValue + ' ' + sentTimeValue).format();
      this.events.publish("initTimePickerLimitedMinTime", {status:"message_sent"});
    } else if (this.isTimeFormatChanged) {
      this.events.publish("initTimePickerLimitedMinTime", {status:"message_open", startTime: startDateTimeStr});
      this.isTimeFormatChanged = false;
    }

    this.startDateField = {
      isDateField: true,
      label: ((this.email?.statuscode === 3 || this.email?.statuscode === 8) ? this.translate.instant('SENT_AT') : (this.email?.statuscode === 548910000 || this.email?.emailStatusString ==='Shared') ? this.translate.instant('SHARED_AT') : this.translate.instant('SCHEDULE_AT')),
      startDateTimeValue: (this.email?.statuscode === 3 || this.email?.statuscode === 8) ? sentDateTimeStr : startDateTimeStr,
      fromViewPage: CurViewPageType.Email,
      inputText: (this.email?.statuscode === 3 || this.email?.statuscode === 8) ? sentDateValue : startDateValue,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || !dateEditable,
      showArrow: dateEditable,
      isRequired: !this.isCompletedActivity && dateEditable,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.startTimeField = {
      isTimeField: true,
      label: this.translate.instant('TIME'),
      startDateTimeValue: (this.email?.statuscode === 3 || this.email?.statuscode === 8) ? sentDateTimeStr : startDateTimeValue,
      fromViewPage: CurViewPageType.Email,
      inputText: (this.email?.statuscode === 3 || this.email?.statuscode === 8) ? sentTimeValue : this.formattedScheduledTime[1],
      id: DateTimeFieldType.StartTimeField,
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || !dateEditable,
      showArrow: dateEditable,
      isRequired: !this.isCompletedActivity && dateEditable,
    };
  }

  public getStatusFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('STATUS'),
      inputText: (this.email && (this.email.channelType === ChannelType.EMAIL || this.email.emailActivityParties.length <= 1)) ? this.emailStatusString : this.checkStatusForMultipleCustomer(),
      id: 'status-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public onDetailsSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'message-activity-scrap':
        this.scrapMessage();
        break;
      case 'message-activity-send':
        this.checkIfExternalChannelSelected? this.sendExternalActivity(): this.send();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  public onAttachmentSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'add-attachment':
        this.openAttachmentPage();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  public onPreviewSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'preview-template':
        this.openEmailTemplatePage();
        break;
      case 'preview-personalise':
        this.openTemplatePersonalizePage();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  public onNotesHeaderControlClick(id: string) {
    switch (id) {
      case 'notes-save':
        this.saveNotes();
        break;
      case 'notes-cancel':
        this.cancelNotes();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  public getDetailsHeader() {
    const detailsHeaderControls = [];
    this.getExternalChannel(this.email?.indskr_channelid);
    if (this.emailService.viewType == EmailViewType.EMAIL_FROM_MEETING_PRESENTATION) {
      detailsHeaderControls.push({
        id: 'message-activity-scrap',
        text: this.translate.instant('SCRAP'),
        isDisabled: !this.isScrapEnabled,
        isVisible: this.emailService.viewType === 5,
      },
        {
          id: 'message-activity-send',
          text: this.checkIfExternalChannelSelected ? this.emailService.selectedActivity?.isPreviewEnabled ? this.translate.instant('SHARE') : this.translate.instant('MARK_COMPLETE') : this.translate.instant('SEND'),
          isDisabled: this.backgroundUploadInProgress ? true : this.checkIfExternalChannelSelected ? this.emailService.selectedActivity?.isPreviewEnabled ? !this.canShareMessage() : !this.canMarkComplete() : !this.canSendMessage(),
          isVisible: this.emailService.viewType === 5 // && !this.isCompletedActivity,
        });
    }
    this.detailsHeader = {
      id: 'details-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: detailsHeaderControls
    };
  }

  public getAttachmentHeader() {
    const attachmentHeaderControls = [];
    attachmentHeaderControls.push({
      id: 'add-attachment',
      text: this.translate.instant('ADD'),
      isDisabled: this.backgroundUploadInProgress ? true : this.checkIfExternalChannelSelected ? true : (this.isReadOnlyRecommendedMessage || this.emailService.isResourceDisabled || this.disabled),
      isVisible: !this.isCompletedActivity && this.email?.channelType != ChannelType.WECOM,
    });
    this.attachmentHeader = {
      id: 'attachment-header',
      title: this.translate.instant('ATTACHMENTS'),
      controls: attachmentHeaderControls,
      isRequired: !this.isCompletedActivity && !this.emailService.isResourceDisabled && !this.checkIfExternalChannelSelected && !this.disabled,
    };
  }

  public getPreviewHeader() {
    const previewHeaderControls = [];
    this.getExternalChannel(this.email?.indskr_channelid);
    const contactsAdded: boolean = this.email.emailActivityParties && this.email.emailActivityParties.length != 0 || false;
    previewHeaderControls.push({
      id: 'preview-template',
      text: this.translate.instant('TEMPLATE'),
      isDisabled: this.backgroundUploadInProgress ? true : (this.checkIfExternalChannelSelected || this.emailService.selectedActivity?.isMessageExternal) && !this.emailService.selectedActivity?.isPreviewEnabled ? true : (this.disabled || this.isCompletedActivity || !contactsAdded),
      isVisible: true,
    },
      {
        id: 'preview-personalise',
        text: this.translate.instant('PERSONALIZE'),
        isDisabled: this.backgroundUploadInProgress ? true : (this.checkIfExternalChannelSelected || this.emailService.selectedActivity?.isMessageExternal) ? true : (this.disabled || this._isPersonalizeDisabled),
        isVisible: !this.isCompletedActivity,
      });
    this.previewHeader = {
      id: 'preview-header',
      title: this.translate.instant('PREVIEW_CAP'),
      controls: previewHeaderControls,
      isRequired: (this.checkIfExternalChannelSelected || this.emailService.selectedActivity?.isMessageExternal) && !this.emailService.selectedActivity?.isPreviewEnabled ? false : !(this.disabled || this.isCompletedActivity || !contactsAdded),
    };
  }

  public getNotesHeader() {
    const notesHeaderControls = [];
    notesHeaderControls.push({
      id: 'notes-save',
      text: this.translate.instant('SAVE'),
      isDisabled:  this.backgroundUploadInProgress  ? true : (!this.isNotesDirty || this.activityService.selectedActivity?.isCompleted),
      isVisible: true,
    },
      {
        id: 'notes-cancel',
        text: this.translate.instant('CANCEL'),
        isDisabled:  this.backgroundUploadInProgress ? true : (!this.isNotesDirty || this.activityService.selectedActivity?.isCompleted),
        isVisible: !this.isCompletedActivity,
      });
    this.notesHeader = {
      id: 'notes-email',
      title: this.translate.instant('ACTIVITY_DETAILS_NOTES'),
      controls: notesHeaderControls,
    };
  }

  public getNotesData(): string {
     let notesData = (this.emailService && (this.emailService.selectedActivity || (this.emailService.selectedActivity && this.emailService.selectedActivity.indskr_emailnotes))) ? this.emailService.selectedActivity.indskr_emailnotes : ''
     return notesData
  }

  private initHeaders() {
    this.getDetailsHeader();
    this.getAttachmentHeader();
    this.getPreviewHeader();
    this.getNotesHeader();
  }

  private initDetailViewField() {
    // this.getTimeFormField();
    this.getStatusFormField();
    this.getSubjectFormField();
    this.initDateTimeFormFields();
  }

  ngAfterViewInit() {
    // if(this.emailService.viewType !== EmailViewType.FROM_XPERIENCES)
    // this.setup();
  }

  private setup() {
    this.initEmailDetailsPageTitle();
    this.initSuggestReason();
    if(this.navService.getCurrentPageName() != PageName.AppSettingsPageComponent) {
      this.initFooter();
    }
    this.initHeaders();
    this.updateHTMLOutline();
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      if (this.backgroundUploadInProgress) return;
      switch (id) {
        /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
        case "account-field":
          this.openAccounts();
          break;
        /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
        case 'subject-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.editSubject(event);
          }
          break;
        case 'product-field':
          this.isMessageProductBundleFeature ? this.productBundleSelection(event): this.productSelection(event);
          break;
        case 'start-date-field':
          this.openStartDatePicker(event);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  /**
   *
   *
   * @returns
   * @memberof ActivitiesDetailsPaneComponent
   * set mode of Account view 'addnew', readOnly, listviw
   */
  /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/
  openAccounts() {
    this.uiService.prevView = this.uiService.activeView;
    this.uiService.showNewActivity = false;
    this.uiService.activeView = 'accountDetails';
    this.accountService.selectedFor = AccountSelectedFor.ADD_TO_MESSAGE;
    this.accountService.accessedAccountListFrom = PageName.EmailDetailsPageComponent;
    this.footerService.initButtons(FooterViews.PHONE_CALL);
    let listMode: ComponentViewMode = ComponentViewMode.ADDNEW;
    if (this.email?.status === 1 && this.emailService.viewType !== EmailViewType.FROM_XPERIENCES && !this.activityService.teamViewActive && !this.backgroundUploadInProgress && !this.isReadOnlyRecommendedMessage) {
      listMode = ComponentViewMode.ADDNEW;
    } else {
      listMode = ComponentViewMode.READONLY;
    }
    this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { 'listMode': listMode }, PageName.AccountPageComponent)
  }

  onContactsUpdated(data) {
    if (this.authService.user.meetingProximityWarningPeriod > 0) {
      this.shouldCheckConflict = true;
      if (this.uiService.toolsActivityActive) {
        this.activityService.signalActivityConflictCheck$.next(this.emailService.selectedActivity);
      } else {
        this.events.publish("refreshAgenda");
      }
    }
  }
  /*===*===*===*===*===*===*=== Email Account Information Capture ===*===*===*===*===*===*===*/

  public async productBundleSelection(event) {
    let selectedProdCategories = this.surgeryOrderDataService.productHierarchies;
    const listDetail: MainToolTemplateDetail = {
      title: this.translate.instant('PRODUCTS'),
      dividerTitle: this.translate.instant('ALL_PRODUCTS_CAP'),
      isSearchEnabled: !this.isReadOnlyMode,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: !this.isReadOnlyMode,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      searchTitle: this.translate.instant('SEARCH_PRODUCTS'),
      hideAllItemsList: this.isReadOnlyMode,
      isListSelectionEnabled: !this.isReadOnlyMode,
      listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
      navOptions: { animate: false },
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProductComponentEvent(data, eventTarget, refData, 'product'),
      //searchHandler:(text: string) => this._handleProductsComponentSearch(text,'product'),
      data: this.getProductsSelectionData(selectedProdCategories)
    };
    if (this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING || this.emailService.viewType === EmailViewType.CREATE_FROM_PHONE_CALL) {
      if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        setTimeout(()=>{
          document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
        });
      }
      this.navCtrl.push(MainToolTemplateComponent, { viewData: listDetail, isNavWithinModalView: true, isHierarchyView: true });
    } else {
      this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.MainToolTemplateComponent, { viewData: listDetail, isHierarchyView: true }, PageName.MainToolTemplateComponent);
    }
  }

  private getProductsSelectionData(selectedProdCategories) {
    let data = [];
    let selcetedEmail = this.emailService.selectedActivity;
    selectedProdCategories.forEach(parentProd => {
      let isParSelected = false;
      if (selcetedEmail.product_id && (parentProd.surgeryId == selcetedEmail.product_id)) {
        isParSelected = true;
      }
      let mainCardItem = {
        id: parentProd.surgeryId,
        primaryTextLeft: '',
        secondaryTextLeft: '',
        showEndIcon: true,
        mainItemCssClass: 'selector-item',
        isItemSelectedForSelectionView: isParSelected,
        endIconType: isParSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
        endIconCssClass: isParSelected ? 'checkmark-icon' : 'add-icon',
        primaryTextRight: parentProd.surgeryName,
        showArrow: false,
        eventOwnerId: this.authService.user.systemUserID,
        childItems: []
      };

      parentProd.productCategories.forEach(secLevelProd => {
        let isSecSelected = false;
        if (selcetedEmail.product_id && (secLevelProd.productCategoryId === selcetedEmail.product_id) && parentProd.surgeryId === selcetedEmail.level1Product) {
          isSecSelected = true;
        }
        let secCardchildItem = {
          id: secLevelProd.productCategoryId,
          parentID: parentProd.surgeryId,
          primaryTextLeft: '',
          secondaryTextLeft: '',
          showEndIcon: !this.isReadOnlyMode,
          mainItemCssClass: 'selector-item',
          isItemSelectedForSelectionView: isSecSelected,
          endIconType: isSecSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
          endIconCssClass: isSecSelected ? 'checkmark-icon' : 'add-icon',
          primaryTextRight: secLevelProd.productCategoryName,
          showArrow: false,
          arrowType: '',
          eventOwnerId: this.authService.user.systemUserID,
          uniqueHierarchyIdentifier: parentProd.surgeryId,
          childItems: []
        }

        secLevelProd.products.forEach(prod => {
          let isProdSelected = false;
          if (selcetedEmail.product_id && prod.productId == selcetedEmail.product_id && prod.productCategoryId === selcetedEmail.level2Product
            && parentProd.surgeryId === selcetedEmail.level1Product) {
            isProdSelected = true;
          }
          let thirdChildItem = {
            id: prod.productId,
            parentID: secLevelProd.productCategoryId,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: !this.isReadOnlyMode,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isProdSelected,
            endIconType: isProdSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
            endIconCssClass: isProdSelected ? 'checkmark-icon' : 'add-icon',
            primaryTextRight: prod.productName,
            showArrow: false,
            arrowType: '',
            eventOwnerId: this.authService.user.systemUserID,
            uniqueHierarchyIdentifier: parentProd.surgeryId
          }
          secCardchildItem.childItems.push(thirdChildItem);
        });
        mainCardItem.childItems.push(secCardchildItem);
      })
      data.push(mainCardItem);
    })
    return data;
  }

  private _handleProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail, fieldName: string) {
    if (this.disabled) return;
    if (eventTarget && eventTarget === 'RightHeaderButtonClick' && data && data.isDone) {
      if (data.selectedItems && data.selectedItems.length > 0) {
        const product = data.selectedItems[0];
        var brand = {
          productid: product.id,
          name: product.primaryTextRight,
          description: product.primaryTextRight
        }
        this.email.emailProduct = new Brand(brand);
        this.email.emailProductName = product.primaryTextRight;
        this.email.product_id = product.id;
        this.emailService.selectedActivity.emailProduct = this.email.emailProduct;
        this.emailService.selectedActivity.emailProductName = this.email.emailProductName;
        let level1Prod: string = null;
        let level2Prod: string = null;
        if (product.uniqueHierarchyIdentifier && product.parentID) {
          if (product.parentID == product.uniqueHierarchyIdentifier) {
            level1Prod = product.parentID;
            this.email.level1Product = product.parentID;
          } else {
            level1Prod = product.uniqueHierarchyIdentifier;
            level2Prod = product.parentID;
            this.email.level1Product = product.uniqueHierarchyIdentifier;
            this.email.level2Product = product.parentID;
          }
        }
      //   if (this.emailService.getEmailTemplateById(this.emailService.selectedActivity.template_id)?.product_id && this.emailService.selectedActivity.product_id && product.id != this.emailService.selectedActivity.product_id) {
      //     this.emailService.updateEmailActivity({ product_id: product.id, level1Product: level1Prod, level2Product: level2Prod, template_id: null }, EmailActionType.PRODUCT);
      //     this.emailService.selectedActivity.template_id = null;
      //     this.emailService.selectedActivity.templatePreview = null;
      //   } else {
      //    this.emailService.updateEmailActivity({ product_id: product.id, level1Product: level1Prod, level2Product: level2Prod }, EmailActionType.PRODUCT);
      //  }

       this.emailService.updateEmailActivity({ product_id: product.id, level1Product: level1Prod, level2Product: level2Prod }, EmailActionType.PRODUCT);

      } else if (data.removedItems && data.removedItems.length > 0) {
        this.email.emailProduct = undefined;
        this.email.product_id = undefined;
        this.email.emailProductName = '';
        this.emailService.selectedActivity.emailProduct = this.email.emailProduct;
        this.emailService.selectedActivity.product_id = this.email.product_id;
        this.emailService.selectedActivity.emailProductName = this.email.emailProductName;
        this.emailService.updateEmailActivity({ product_id: null, level1Product: null, level2Product: null, template_id: null }, EmailActionType.PRODUCT);
        this.emailService.selectedActivity.template_id = null;
        this.emailService.selectedActivity.templatePreview = null;
      }
    }
  }

  public getSecondaryInfoProduct(): string {
    let emailActivity = this.emailService.selectedActivity;
    let secInfo: string;
    if (this.isMessageProductBundleFeature && emailActivity && emailActivity.product_id) {
      if (emailActivity.level1Product && emailActivity.level2Product) {
        secInfo = this.emailService.getProductFromProductHierachy(emailActivity.level1Product).name + ' > ' + this.emailService.getProductFromProductHierachy(emailActivity.level2Product).name;
      } else if (emailActivity.level1Product && !emailActivity.level2Product) {
        secInfo = this.emailService.getProductFromProductHierachy(emailActivity.level1Product).name;
      }
    }
    return secInfo;
  }

  public async productSelection(e) {
    if (this.disabled) return;

    let modal = await this.modalCtrl.create({
      component: EmailPopoverComponent,
      componentProps: {
        value: {
          email: this.activityService.selectedActivity
        }
      },
      cssClass: this.device.isOffline ? 'case-product-popover offline-padding' : 'case-product-popover'
    }
    );
    modal.present();
    modal.onDidDismiss().then(
      async (data: any) => {
        data = data.data;
        this._isClickedProduct = true;
        this._emptyProduct = false;
        if (data) {
                    if (data.isDone) {
                      // User click on ✔ button.
                      if (data.selection) {
                        // User has added/updated the Product selection
                        const product = data.selection;
                        this.email.emailProduct = (product) ? product : this.email.emailProduct ? this.email.emailProduct : undefined;
                        this.email.product_id = (product) ? product.ID : this.email.product_id ? this.email.product_id : undefined;
                        this.email.emailProductName = this.email.emailProduct ? String(this.email.emailProduct.name) : '';
                        this.emailService.selectedActivity.emailProduct  = this.email.emailProduct
                        this.emailService.selectedActivity.product_id = this.email.product_id
                        this.emailService.selectedActivity.emailProductName = this.email.emailProductName
                        this.emailService.updateEmailActivity({ product_id: this.email.product_id}, EmailActionType.PRODUCT);
                      } else {
                        // User has removed the Product selection
                        this.email.emailProduct = undefined;
                        this.email.product_id = undefined;
                        this.email.emailProductName = '';
                        this.emailService.selectedActivity.emailProduct  = this.email.emailProduct;
                        this.emailService.selectedActivity.product_id = this.email.product_id;
                        this.emailService.selectedActivity.emailProductName = this.email.emailProductName;
                        this.emailService.updateEmailActivity({ product_id: null }, EmailActionType.PRODUCT);
                      }
                     } else {
                      // User click on X button.
                      // Nothing to do.
                    }
                   }
      });
  }

  onToggleShowMore() {
    this.showMore = !this.showMore;
  }

  onToggleCovisitorShowMore() {
    this.covisitorShowMore = !this.covisitorShowMore;
  }

  getExternalChannel(channelId: string) {
    if (channelId != undefined || channelId != "") {
      const cha: Channel = this.consentService.savedChannels.find(sch => sch.indskr_consenttypeid === channelId);
      if (cha != undefined) {
        //this.emailService.selectedActivity.channelActivityType = this.filterChannelActivityType(cha.activityType);
        if (cha.indskr_externalchannel === true) {
           this.checkIfExternalChannelSelected =  true
           this.channelName = String(cha.indskr_consentType);
           if (this.email) this.email.isMessageExternal = true;
           this.emailService.selectedActivity.isMessageExternal = true;
           this.emailService.selectedActivity.isPreviewEnabled = cha?.indskr_enablepreview === true ? true : false;
           this.initFooter();
        } else {
          this.channelName = String(cha.indskr_consentType);
          this.checkIfExternalChannelSelected = false
          this.emailService.selectedActivity.isMessageExternal = false;
          if (this.email) this.email.isMessageExternal = false;
          this.emailService.selectedActivity.isPreviewEnabled = false;
        }
      } else { // reset everything to false.
        this.channelIsDeactivated = true;
        this.checkIfExternalChannelSelected = false;
        this.emailService.selectedActivity.isMessageExternal = false;
        if (this.email) this.email.isMessageExternal = false;
        this.emailService.selectedActivity.isPreviewEnabled = false;
      }
    }
    this.initDetailViewField();
  }

  saveNotes() {
    if (this.emailService.selectedActivity instanceof EmailActivity) {
      this.trackingService.tracking('EmailNotesUpdate', TrackingEventNames.EMAIL);
      this.emailService.updateEmailActivity({ indskr_emailnotes: this.emailService.selectedActivity.indskr_emailnotes}, EmailActionType.NOTES);
    }
    this.isNotesDirty = false;
  }

  cancelNotes() {
    if (this.preSavedNotes) {
      this.emailService.selectedActivity.indskr_emailnotes =  this.preSavedNotes;
    } else {
      this.emailService.selectedActivity.indskr_emailnotes = "";
    }
    this.isNotesDirty = false
    this.getNotesHeader();
  }

  onNotesFocus() {
    this.preSavedNotes =  this.emailService.selectedActivity.indskr_emailnotes;
    this.getNotesHeader();
  }

  public notesChanged() {
    this.isNotesDirty = true;
    this.getNotesHeader();
  }

  public footerButtonClicked(buttonId: string) {
    switch (buttonId) {
      case "sendEmail":
      case "markcomplete":
        this.checkIfExternalChannelSelected? this.sendExternalActivity(): this.send();
        break;
      case "scrapemail":
        if (this.inPhoneCallFlow && this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent) {
          if (this.modalView) {
            this.scrapEmail();
          }
          return
        }
        this.scrapEmail();
        break;
      case "template":
        this.openEmailTemplatePage();
        break;
      case "personalize_template":
        this.openTemplatePersonalizePage();
        break;
      case "attachment":
        this.openAttachmentPage();
        break;
      case "contacts":
      case "contactsMessage":
        this.gotoContactSelection();
        break;
      case "shareMessage":
        this.sendExternalActivity();
        break;
      default:
        break;
    }
  }

  private setChannel(channel) {
    if(this.channelIsDeactivated){
      this.messageChannel = this.email.channelName === 'Email' ? this.translate.instant('EMAIL') : this.email.channelName;;
    } else if (this.channelName){
      this.messageChannel = this.channelName === 'Email' ? this.translate.instant('EMAIL') : this.channelName;
    } else {
      this.messageChannel = channel ? (channel === 'Email' ? this.translate.instant('EMAIL') :this.translate.instant('MESSAGE')) :this.translate.instant('MESSAGE') ;
    }
  }

  private checkStatusForMultipleCustomer(): string {
    let currentStatusString: string = this.emailStatusString;
    if (this.email && this.email.emailActivityParties && this.email.emailActivityParties.length > 1) {
      let cntSentStatus = this.email.emailActivityParties.filter(emailActivityParty => emailActivityParty.email_statuscode == 3).length;
      if (cntSentStatus >= 1) {
        currentStatusString = this.translate.instant('SENT');
      }
    }
    return currentStatusString;
  }

  async openStartDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.Email,
          startDateTimeValue: this.scheduledTime
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then((data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.startTime !='') {
        this.scheduledTime = data.data.startTime;
        this.activityService.selectedActivity.modified = new Date();
        this.onChangeSchedule();
      } else {
        this.scheduledTime = this.prevStartTime;
        this.onChangeSchedule();
      }
    });
    popover.present();
  }

  private _replacePropertyInTag(contentBody: any): any {
    return this.activityService.replacePropertyInTag(contentBody);
  }

  openUrl(event:any) {
    if (this.device.isOffline && !event || !event.target) return;
    const target = event.target;
    this.activityService.openExternalLink(target);
  }

  checkActiveEmailAddressInPreview(targetPreview: string): string {
    let updatedPreview: string = targetPreview; 
    let inactiveEmailList: string[] = [];

    if (this.email?.emailActivityParties) {
      this.email.emailActivityParties.forEach(emailActivityParty => {
        const contactId = emailActivityParty.indskr_contactid || '';
        if (contactId) {
          const contact: Contact = this.contactService.getContactByID(emailActivityParty.indskr_contactid);
          if (contact) {
            if (contact.emailAddressList && contact.emailAddressList.length > 0) {
              const contactEmails = new Set(contact.emailAddressList.map(e => e.emailAddress)); 
              emailActivityParty['emailAddresses'].forEach(ea => {
                if (!contactEmails.has(ea.emailAddress)) {
                  inactiveEmailList.push(ea.emailAddress);
                }
              });
            } else {
              emailActivityParty['emailAddresses'].forEach(ea => {
                  inactiveEmailList.push(ea.emailAddress);
              });
            }
          }
        }  
      });
    }
    if (!_.isEmpty(inactiveEmailList)) {
      if (updatedPreview) {
        inactiveEmailList.forEach(ie => {
          const regex = new RegExp(ie, 'g');
          updatedPreview = updatedPreview.replace(regex, '');
        });
      }
    } else {
      //if email address has changed or removed, we cannot track it in the description.
    }
    return updatedPreview;
  }
}
