import { HopQRCodeModal } from '@omni/components/contact/hop-qr-code-modal/hop-qr-code-modal';
import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { differenceInMinutes, format, isValid, addMinutes } from 'date-fns';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OmniAccordionViewDataModel } from '@omni/models/omniAccordionViewDataModel';
import { Brand, ProductRecordType } from '../../../classes/brand/brand.class';
import { BusinessLineVal, BusinessProcessType, Control, ControlDataType, DateFormat, DisplayText, DynamicForm, EditConfig, FieldConfigFilterOperatorType, FormTabs, FormType, LookupSearchResult, MDM_USER_TYPE, SetBookingProductFilter, SubgridLayout, ViewType } from '../../../classes/dynamic-form/dynamic-form.class';
import { AccountPageComponent } from '../../../components/account/account-page/account-page';
import { ContactPageComponent } from '../../../components/contact/contact-page/contact-page';
import { CUSTOM_ADDRESS_FIELDS, SHARED_CREATE_FORM } from '../../../config/dynamic-forms/shared-form/shared-create-form';
import { AddressDataService } from '../../../data-services/address/address.data.service';
import { DynamicFormComponentModel, DynamicFormType } from '../../../models/dynamic-form-component.model';
import { IndDynamicFormLookupListDetailModel } from '../../../models/dynamicFormLookupListDetailModel';
import { IndDropdownListDetailModel } from '../../../models/indDropdownListModel';
import { FormFieldType, IndFormFieldViewDataModel } from '../../../models/indFormFieldDataModel';
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { MainToolTemplateDetail } from '../../../models/mainToolTemplateDetail.model';
import { MainToolTemplateListSelectionType } from '../../../models/mainToolTemplateDetail.model';
import { SelectListData } from '../../../models/select-list-data-model';
import { AccountOfflineService, AccountSelectedFor } from '../../../services/account/account.offline.service';
import { AlertService } from '../../../services/alert/alert.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { BrandOfflineService } from '../../../services/brand/brand.service';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { DeviceService } from '../../../services/device/device.service';
import { DynamicFormsService } from '../../../services/dynamic-forms/dynamic-forms-service';
import { EventsService } from '../../../services/events/events.service';
import { LocalizationService } from '../../../services/localization/localization.service';
import { TrackingEventNames, TrackService } from '../../../services/logging/tracking.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { DynamicFormLookupPopoverComponent } from '../dynamic-form-lookup-popover/dynamic-form-lookup-popover';
import { IndDropdownListComponent } from '../ind-dropdown-list/ind-dropdown-list';
import { MainToolTemplateComponent } from '../main-tool-template/main-tool-template';
import { MdmService } from '@omni/services/mdm/mdm.service';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { CONTACT_FETCH_QUERIES } from "../../../config/fetch-xml/contact-fetchXMLs";
import { FooterService } from '@omni/services/footer/footer.service';
import { DiskService } from '@omni/services/disk/disk.service';
import { ChangeRequestType } from '@omni/classes/mdm/source-type-optionset.class';
import { multilingualLanguageToAttributeMapping } from '@omni/config/dynamic-forms/default-contact/default-contact-create';
import * as XML2JS from 'xml2js';
import { ACCOUNT_ACCOUNT_AFFILIATIONS_CR_REF_ENTITY, ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY, ACCOUNT_ACCOUNT_FROM_CR_ATTRIBUTE_NAME,
         ACCOUNT_ACCOUNT_FROM_FILTER_ATTRIBUTE_NAME, ACCOUNT_ACCOUNT_TO_CR_ATTRIBUTE_NAME, ACCOUNT_ACCOUNT_TO_FILTER_ATTRIBUTE_NAME,
         CONTACT_CONTACT_AFFILIATIONS_CR_REF_ENTITY, CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY, CONTACT_CONTACT_FROM_CR_ATTRIBUTE_NAME,
         CONTACT_CONTACT_FROM_FILTER_ATTRIBUTE_NAME, CONTACT_CONTACT_TO_CR_ATTRIBUTE_NAME, CONTACT_CONTACT_TO_FILTER_ATTRIBUTE_NAME } from '../../../config/dynamic-forms/affiliations-contants';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '../ind-datetime-form/ind-datetime-form';
import { ActivityService } from '@omni/services/activity/activity.service';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { DCR_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX, MAXIMUM_NOTE_ATTACHMENT_SIZE, toBase64 } from '@omni/utility/util';
import { SetBookingStatus } from '@omni/classes/activity/set-booking.activity.class';
import { SetBookingDataService } from '@omni/data-services/set-booking/set-booking.data.service';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { MOBILE_FORMS_DISPLAY_ENTITY_FILTER } from '@omni/config/dynamic-forms/dynamic-forms-fetchxml';
import { DisplayFormComponent } from '../ind-display-form/ind-display-form';
import { MainToolTemplateDetailsComponent } from '../main-tool-template-details/main-tool-template-details';
import { MainToolTemplateDeatilData } from '@omni/models/mainToolTemplateDetailData.model';
import { SelectionConfig } from '@omni/interfaces/shared/shared.interface';

const DEFAULT_FORM_LANGUAGE_CODE = '0000';

const compareBy = (...props) => (a, b) => {
  for (let i = 0; i < props.length; i++) {
    const ascValue = props[i].startsWith('-') ? -1 : 1;
    const prop = props[i].startsWith('-') ? props[i].substr(1) : props[i];
    if (a[prop]) {
      if (b[prop]) {
        if (a[prop] !== b[prop]) {
          return a[prop] > b[prop] ? ascValue : -ascValue;
        }
      } else {
        return ascValue;
      }
    } else {
      return ascValue === 1 ? -1 : 0;
    }
  }
  return 0;
};
/**
 * Generated class for the DynamicFormComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'ind-dynamic-form',
  templateUrl: 'ind-dynamic-form.html',
  styleUrls: ['ind-dynamic-form.scss']
})
export class DynamicFormComponent {

  private _isFormSaved = false;
  public formDetail: DynamicFormComponentModel;
  public isSearchBarEnabled = false;

  public searchText = '';
  public searchPlaceholder = '';
  public isSearchPopoverActive = false;
  public searchPopoverOptions: Array<{
    id: string,
    primaryText: string,
    data: any,
  }> = [];
  public selectedSearchOption: any;
  public isSelectedFromSearch = false;

  private _mode: string;
  private _previousMode: string;
  private _isFormDirty = false;
  public dynamicFormPageTitle: IndPageTitleViewDataModel;
  public firstSectionHeader: IndSectionHeaderViewDataModel;
  private  _activeLanguageCode = '1033';
  private _currentFormValue: { [x: string]: any };
  private _currentFormStringValues: { [x: string]: any };
  private _customFormValue: { [x: string]: any }; //object to store custom fields

  private readonly $ngDestroy: any = new Subject<boolean>();

  public linkedEntityValues: {
    [x: string]: Array<OmniAccordionViewDataModel>;
  };

  private _dropdownPopover: HTMLIonPopoverElement;
  private _lookupPopover: HTMLIonPopoverElement;
  private _activeLookupSearchControl: Control;
  private _activeProductSelectControl: Control;
  private _activeMainTemplateLookupSelectControl: Control;

  public currentFormView: Array<{
    id: string,
    type: string,
    view: any,
    timeView?: any,
    data?: string,
    control?: Control,
    isCollapsed?: boolean,
    section?: string
  }> = [];
  private _dynamicForm: DynamicForm;

  @ViewChild('dynamicFormDate', { static: true }) dynamicFormDateTimePicker: HTMLIonDatetimeElement;
  shortMonth: string[] = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  public showSearchListDropdown = false;
  @Input() MODE: string;
  @Input() PREVIOUS_MODE: string;
  @Input() FORM_DETAIL: any;
  @Input() TYPE;
  @Input() EDIT_CONFIG: EditConfig;
  @Input() LINKED_ENTITY_DB_PREFIX;
  @Input() RAW_DATA;
  @Input() REF_ENTITY_NAME;
  @Input() from: any;
  public editConfig: EditConfig = EditConfig.ADD_NEW;
  public linkedEntityDBPrefix: string = '';
  public rawData;
  public referencingEntityName: string = '';
  public changeRequest: boolean = false;
  private disableForm: boolean = false;
  public isAccountAccountAffiliationPopover = false;
  public isAccountContactAffiliationPopover = false;
  public isAddressOneKeyPopover = false;
  public isAddressPopover = false;

  private startDate: Date;
  private endDate: Date;
  public _dateTimePickerActiveControl: Control;
  public startTime: { [x: string]: any } = {};
  public endTime: { [x: string]: any } = {};
  public prevStartTime: { [x: string]: any } = {};
  public prevEndTime: { [x: string]: any } = {};

  // private hasAccountAccountAffiliationForm: boolean = false;
  private hasAccountContactAffiliationForm: boolean = false;
  private hasAddressForm: boolean = false;
  // private hasCountryFieldInAddressForm: boolean = false;

  // for address edit form with auto-populated
  private addedNewACAffiliation: boolean = false;
  public addedNewAddressForAccountCR: boolean = false;
  public isEditModeForAddress: boolean = false;
  public currentEditFormView: Array<{
    id: string,
    type: string,
    view: any,
    data?: string,
    control?: Control,
  }> = [];
  private _dynamicFormForAddressEdit: DynamicForm;
  private _foundFormForACAffiliationCR: DynamicForm;
  private _foundFormForAddressCR: DynamicForm;
  private formEditAddressDetail: DynamicFormComponentModel;
  public isExpandedCurrentEditFormView: boolean = false;
  public hasExistingLinkedDataForAddressAccountCR: boolean = false;
  public labelForExistingAddress: string;
  private isHandleEditAddressFormField: boolean = false;
  private _existingFormValue: { [x: string]: any };
  public _existingCustomFormValue: { [x: string]: any };
  private _existingFormStringValues: { [x: string]: any };
  private isAutoPopulatedLinkedDataFlow: boolean = false;
  public isViewFromEditFormToAccordionForm: boolean = false;
  public errorMessage: string;
  public addressAttrName: string;
  private _stateIdBasedOnCity: string;
  private _isClicked: { [x: string]: boolean } = {};
  public worksCardHeader: IndSectionHeaderViewDataModel;
  public isWorkCardAdded = false;
  public workCardTitle = '';
  workCardBase64str: any;
  workCardFile: any;
  @ViewChild('workcardAttachInput') workcardAttachInput: ElementRef;
  public practiceCertificateHeader: IndSectionHeaderViewDataModel;
  public isPracticeCertificateAdded = false;
  public practiceCertificateTitle = '';
  practiceCertificateBase64str: any;
  practiceCertificateFile: any;
  @ViewChild('practiceCertificateAttachInput') practiceCertificateAttachInput: ElementRef;
  public businessLine: number = -1;
  private _DCR_isVxMDMFlow = false;

  private _runInitViewDataAfterBusinessRuleLogic:boolean = false;
  public searchKeyWordFromMainToolTemplate = '';
  public selectedFilterFromMainToolTemplate: any;
  private isLayoverPushView: boolean;
  private layoverViewPushedFrom: string;

  constructor(
    public translate: TranslateService,
    public navService: NavigationService,
    public uiService: UIService,
    public authService: AuthenticationService,
    private readonly dynamicFormsService: DynamicFormsService,
    private readonly popoverCtrl: PopoverController,
    public dateTimeFormatsService: DateTimeFormatsService,
    private readonly device: DeviceService,
    private readonly events: EventsService,
    private readonly notificationService: NotificationService,
    private readonly addressService: AddressDataService,
    private readonly localizationService: LocalizationService,
    private readonly trackingService: TrackService,
    private readonly alertService: AlertService,
    private readonly accountService: AccountOfflineService,
    private readonly UIService: UIService,
    private readonly contactService: ContactOfflineService,
    private readonly brandService: BrandOfflineService,
    private readonly mdmService: MdmService,
    public readonly footerService: FooterService,
    private dynamics: DynamicsClientService,
    private disk: DiskService,
    public activityService: ActivityService,
    private datePipe: DatePipe,
    private setBookingDataService: SetBookingDataService
  ) {
    this._activeLanguageCode = this.localizationService.selectedLanguage.localeID;
  }

  private async _parseRawObjectDataToValues(rawObject: any) {
    if (this._dynamicForm && this._dynamicForm.metadata && this._dynamicForm.metadata.length > 0) {
      this._dynamicForm.metadata.forEach((tab, tabIndex) => {
        if (tab && tab.controls && tab.controls.length > 0) {
          tab.controls.forEach(async control => {
            switch (control.dataType) {
              case ControlDataType.MoneyType:
              case ControlDataType.IntegerType:
              case ControlDataType.DecimalType:
              case ControlDataType.DoubleType:
              case ControlDataType.MemoType:
              case ControlDataType.StringType:
                if (rawObject[control.attributeName]) {
                  if (this.MODE =='EDIT_MODE' && this.TYPE != "OMNIPRESENCE" && (this._dynamicForm.entityName == 'indskr_contactcr' || this._dynamicForm.entityName =='indskr_accountcr')) {
                    if (control.isVisible && control.isRequired && !control.forceHide) {
                      this._setAttributeValue(control, rawObject[control.attributeName], false);
                    }
                  } else {
                    this._setAttributeValue(control, rawObject[control.attributeName], false);
                  }
                  if (this._mode == 'EDIT_MODE' && this.formDetail.dynamicFormType == DynamicFormType.DEFAULTFORM) {
                    control.isReadOnly = true;
                  }
                }
                break;
              case ControlDataType.LookupType:
                if (rawObject['_' + control.attributeName + '_value']) {
                  if (this.MODE =='EDIT_MODE' && this.TYPE != "OMNIPRESENCE" && (this._dynamicForm.entityName == 'indskr_contactcr' || this._dynamicForm.entityName =='indskr_accountcr')) {
                    if (control.isVisible && control.isRequired && !control.forceHide) {

                      let otherFieldsToInclude;
                      // Hardcoded logic for DCR Vx MDM
                      // TODO: add configuration for this instead of hardcode
                      if (this._DCR_isVxMDMFlow) {
                        let fieldNamesToIncludeInSelectionData = this._getFieldNamesToIncludeInSelectionData(control);
                        if (fieldNamesToIncludeInSelectionData) {
                          otherFieldsToInclude = {};
                          for (const fieldName of fieldNamesToIncludeInSelectionData) {
                            if (rawObject['_' + control.attributeName + '_' + fieldName]) {
                              otherFieldsToInclude[fieldName] = rawObject['_' + control.attributeName + '_' + fieldName];
                            }
                          }
                        }
                      }

                      if(control.lookupEntityPrimaryId && control.lookupEntityPrimaryId == "omnione_onekeycodeslabelsid"){
                        if(rawObject['_' + control.attributeName  + '_value'] && this.localizationService.multiLingualFieldsData && this.localizationService.multiLingualFieldsData.length > 0){
                          let foundRecord = this.localizationService.multiLingualFieldsData.find(a=> a['omnione_onekeycodeslabelsid'] == rawObject['_' + control.attributeName  + '_value']);
                          let translatedLang: string = '';
                          // if(foundRecord) translatedLang = foundRecord[multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]] ? foundRecord[multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]] || '' : foundRecord['omnione_en_long_label'] || '';
                          let key = this.localizationService.getOneKeyTableAttributes();
                          if(foundRecord) translatedLang = foundRecord[key] ? foundRecord[key] || '' : foundRecord['omnione_en_long_label'] || '';
                          if(foundRecord && foundRecord['omnione_onekeycodeslabelsid'] && translatedLang){
                            const onekeyCodesLabelsId = foundRecord['omnione_onekeycodeslabelsid'] || '';
                            let codIdOneKey = '';
                            if(foundRecord && foundRecord['omnione_cod_id_onekey']) {
                              codIdOneKey = foundRecord['omnione_cod_id_onekey'];
                              this._setAttributeValue(control, onekeyCodesLabelsId, false, codIdOneKey);
                              this._setAttributeStringValue(control.attributeName, translatedLang);
                            }else {
                              const schemaFieldName = foundRecord['omnione_schemafieldname'] || '';
                              try {
                                await this.dynamicFormsService.getLookupCodIdOnekey(schemaFieldName, onekeyCodesLabelsId).then((res)=>{
                                  if(!_.isEmpty(res)) codIdOneKey = res;
                                  this._setAttributeValue(control, onekeyCodesLabelsId, false, codIdOneKey);
                                  this._setAttributeStringValue(control.attributeName, translatedLang);
                                });
                              } catch(error) {
                                console.log("Error getting lookup codIdOnekey");
                                this._setAttributeValue(control, onekeyCodesLabelsId, false, codIdOneKey);
                                this._setAttributeStringValue(control.attributeName, translatedLang);
                              }
                            }
                          }
                        }
                      }else{
                        this._setAttributeValue(control, rawObject['_' + control.attributeName + '_value'], false, '', true, otherFieldsToInclude);
                        this._setAttributeStringValue(control.attributeName, rawObject['_' + control.attributeName + '_value@OData.Community.Display.V1.FormattedValue']);
                      }
                    }
                  }else if(control.lookupEntityPrimaryId && control.lookupEntityPrimaryId == "omnione_onekeycodeslabelsid"){
                    if(rawObject['_' + control.attributeName  + '_value'] && this.localizationService.multiLingualFieldsData && this.localizationService.multiLingualFieldsData.length > 0){
                      let foundRecord = this.localizationService.multiLingualFieldsData.find(a=> a['omnione_onekeycodeslabelsid'] == rawObject['_' + control.attributeName  + '_value']);
                      let translatedLang: string = '';
                      // if(foundRecord) translatedLang = foundRecord[multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]] ? foundRecord[multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]] || '' : foundRecord['omnione_en_long_label'] || '';
                      let key = this.localizationService.getOneKeyTableAttributes();
                      if(foundRecord) translatedLang = foundRecord[key] ? foundRecord[key] || '' : foundRecord['omnione_en_long_label'] || '';
                      if(foundRecord && foundRecord['omnione_onekeycodeslabelsid'] && translatedLang){
                        const onekeyCodesLabelsId = foundRecord['omnione_onekeycodeslabelsid'] || '';
                        let codIdOneKey = '';
                        if(foundRecord && foundRecord['omnione_cod_id_onekey']) {
                          codIdOneKey = foundRecord['omnione_cod_id_onekey'];
                          this._setAttributeValue(control, onekeyCodesLabelsId, false, codIdOneKey);
                          this._setAttributeStringValue(control.attributeName, translatedLang);
                        }else {
                          const schemaFieldName = foundRecord['omnione_schemafieldname'] || '';
                          try {
                            await this.dynamicFormsService.getLookupCodIdOnekey(schemaFieldName, onekeyCodesLabelsId).then((res)=>{
                              if(!_.isEmpty(res)) codIdOneKey = res;
                              this._setAttributeValue(control, onekeyCodesLabelsId, false, codIdOneKey);
                              this._setAttributeStringValue(control.attributeName, translatedLang);
                            });
                          } catch(error) {
                            console.log("Error getting lookup codIdOnekey");
                            this._setAttributeValue(control, onekeyCodesLabelsId, false, codIdOneKey);
                            this._setAttributeStringValue(control.attributeName, translatedLang);
                          }
                        }
                      }
                    }
                  }else {
                    this._setAttributeValue(control, rawObject['_' + control.attributeName + '_value'], false);
                    this._setAttributeStringValue(control.attributeName, rawObject['_' + control.attributeName + '_value@OData.Community.Display.V1.FormattedValue']);
                  }
                  if (this._mode == 'EDIT_MODE' && this.formDetail.dynamicFormType == DynamicFormType.DEFAULTFORM) {
                    control.isReadOnly = true;
                  }
                }
                break;
              case ControlDataType.BooleanType:
                if (rawObject.hasOwnProperty(control.attributeName)) {
                  if (this.MODE =='EDIT_MODE' && this.TYPE != "OMNIPRESENCE" && (this._dynamicForm.entityName == 'indskr_contactcr' || this._dynamicForm.entityName =='indskr_accountcr')) {
                    if (control.isVisible && control.isRequired && !control.forceHide) {
                      (rawObject[control.attributeName]) ? this._setAttributeValue(control, true, false) : this._setAttributeValue(control, false, false);
                    }
                  } else {
                    (rawObject[control.attributeName]) ? this._setAttributeValue(control, true, false) : this._setAttributeValue(control, false, false);
                  }
                  if (this._mode == 'EDIT_MODE' && this.formDetail.dynamicFormType == DynamicFormType.DEFAULTFORM) {
                    control.isReadOnly = true;
                  }
                }
                break;
              case ControlDataType.MultiSelectPicklistType:
                if (rawObject[control.attributeName]) {
                  this._setAttributeValue(control, rawObject[control.attributeName], false);
                  let str = rawObject[control.attributeName+'@OData.Community.Display.V1.FormattedValue'].replace(/;/g, ',');
                  if (this.MODE =='EDIT_MODE' && this.TYPE != "OMNIPRESENCE" && (this._dynamicForm.entityName == 'indskr_contactcr' || this._dynamicForm.entityName =='indskr_accountcr')) {
                    if (control.isVisible && control.isRequired && !control.forceHide) {
                      this._setAttributeStringValue(control.attributeName,str);
                    }
                  } else {
                    this._setAttributeStringValue(control.attributeName,str);
                  }
                  if (this._mode == 'EDIT_MODE' && this.formDetail.dynamicFormType == DynamicFormType.DEFAULTFORM) {
                    control.isReadOnly = true;
                  }
                }
                break;
              case ControlDataType.PicklistType:
                if (rawObject[control.attributeName]) {
                  if (this.MODE =='EDIT_MODE' && this.TYPE != "OMNIPRESENCE" && (this._dynamicForm.entityName == 'indskr_contactcr' || this._dynamicForm.entityName =='indskr_accountcr')) {
                    if (control.isVisible && control.isRequired && !control.forceHide) {
                      this._setAttributeValue(control, rawObject[control.attributeName], false);
                      this._setAttributeStringValue(control.attributeName, rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue']);
                    }
                  } else {
                    this._setAttributeValue(control, rawObject[control.attributeName], false);
                    this._setAttributeStringValue(control.attributeName, rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue']);
                  }
                  if (this._mode == 'EDIT_MODE' && this.formDetail.dynamicFormType == DynamicFormType.DEFAULTFORM) {
                    control.isReadOnly = true;
                  }
                }
                break;
              case ControlDataType.DateTimeType:
                if (rawObject[control.attributeName] || rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue']) {
                  let date: Date;
                  if (rawObject[control.attributeName]) {
                    date = new Date(rawObject[control.attributeName]);
                    if (!isValid(date)) {
                      date = new Date(parseInt(rawObject[control.attributeName]));
                      if (!isValid(date) && rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue']) {
                        date = new Date(rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue']);
                      }
                    }
                  } else if (rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue']) {
                      date = new Date(rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue']);
                  }
                  if (isValid(date)) {
                    if (control.dateFormat === DateFormat.DateAndTime) {
                      this._setAttributeValueDate(control, date);
                    } else if (control.dateFormat === DateFormat.DateOnly) {
                      date.setHours(0, 0, 0, 0);
                      this._setAttributeValueDate(control, date);
                    }
                  } else {
                    this._setAttributeValue(control, "");
                    this._setAttributeStringValue(control.attributeName, "");
                  }
                  if (control.attributeName == 'indskr_startdate' && this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) {
                    if (this.startTime && this.startTime.hasOwnProperty(control.attributeName)) {
                      this.startTime[control.attributeName] = this._currentFormStringValues[control.attributeName];
                    } else {
                      this.startTime = {
                        indskr_startdate: this._currentFormStringValues[control.attributeName]
                      }
                    }
                  } else if (control.attributeName == 'indskr_enddate' && this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) {
                    if (this.endTime && this.endTime.hasOwnProperty(control.attributeName)) {
                      this.endTime[control.attributeName] = this._currentFormStringValues[control.attributeName];
                    } else {
                      this.endTime = {
                        indskr_enddate: this._currentFormStringValues[control.attributeName]
                      }
                    }
                  }
                  if (this._mode === 'EDIT_MODE' && this.formDetail.dynamicFormType == DynamicFormType.DEFAULTFORM) {
                     control.isReadOnly = true;
                  }
                }
                break;
              case ControlDataType.StateType:
              case ControlDataType.StatusType:
                if (this.MODE =='EDIT_MODE') {
                  control.isReadOnly = true;
                  if (rawObject[control.attributeName] || rawObject[control.attributeName] == 0) {
                    this._setAttributeValue(control, rawObject[control.attributeName]);
                    let str = rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue'];
                    if (rawObject[control.attributeName + '@OData.Community.Display.V1.FormattedValue'] == "In Progress" && this.changeRequest) {
                      str = "In Process";
                    }
                    if (!_.isEmpty(str)) this._setAttributeStringValue(control.attributeName, str);
                  } else {
                    this._setAttributeValue(control, "");
                    this._setAttributeStringValue(control.attributeName, "")
                  }
                }
                break;
              case null:
                // Handle Subgrid case
                if(this.formDetail.enableSubgridDataEditing){
                  await this._initializeSubgridData(control);
                }
                default:
                break;
            }
          });
        }
      });
    }
  }
// Business flow - Main form - Existing Linked Entity Data
  private async _parseRawObjectDataToValuesLinkedEntity() {
    if (this._dynamicForm && this._dynamicForm.metadata && this._dynamicForm.metadata.length > 0) {
      this._dynamicForm.metadata.forEach((tab) => {
        if (tab && tab.controls && tab.controls.length > 0) {
          tab.controls.forEach(async control => {
            if (control.dataType == null) {
              let response = await this._initializeSubgridData(control);
              if (control.linkedEntityConfig && response) this._initViewData();
            }
          });
        }
      });
    }
  }
  // Business flow - Linked Entity Edit form
  private async _parseRawObjectDataToValuesExistingData(rawObject: any, isUpdatedLinkedEntityFormData: boolean) {
    if (this._dynamicForm && this._dynamicForm.metadata && this._dynamicForm.metadata.length > 0) {
      this._dynamicForm.metadata.forEach((tab) => {
        if (tab && tab.controls && tab.controls.length > 0) {
          tab.controls.forEach(async control => {
            let entityName = this.formDetail.formMetadata.entityName;
            const isAddressEntity: boolean = entityName == "indskr_indskr_customeraddress_v2" || entityName == "indskr_customeraddresscr";
            let foundFormValue: string;
            let foundFormStringValue: string;
            switch (control.dataType) {
              case ControlDataType.MoneyType:
              case ControlDataType.IntegerType:
              case ControlDataType.DecimalType:
              case ControlDataType.DoubleType:
              case ControlDataType.MemoType:
              case ControlDataType.StringType:
                if (isUpdatedLinkedEntityFormData) {
                  if (control.isCustom) {
                    if (rawObject.customFormValue && rawObject.customFormValue.hasOwnProperty(control.attributeName)) {
                      foundFormValue = rawObject.customFormValue[control.attributeName] || '';
                    }
                  } else {
                    if (rawObject.formValue && rawObject.formValue.hasOwnProperty(control.attributeName)) {
                      foundFormValue = rawObject.formValue[control.attributeName] || '';
                    }
                  }
                } else {
                  foundFormValue = rawObject[entityName + '.' + control.attributeName] || '';
                  if (isAddressEntity && !foundFormValue) {
                    entityName = "indskr_address";
                    foundFormValue = rawObject[entityName + '.' + control.attributeName] || '';
                  }
                }
                if (foundFormValue) {
                  this._setAttributeValue(control, foundFormValue, false);
                }
                break;
              case ControlDataType.LookupType:
                let targetAttributeName = control.isCustom ? control.schemaName : (control.isCustomAttribute ? control.schemaName : control.attributeName);
                if (isUpdatedLinkedEntityFormData) {
                  let foundObj;
                  if (control.isCustom && rawObject.customFormValue.lookupfields) {
                    foundObj = rawObject.customFormValue.lookupfields.filter(x=>x.name == targetAttributeName)
                  } else if (!control.isCustom && rawObject.formValue.lookupfields) {
                    foundObj = rawObject.formValue.lookupfields.filter(x=>x.name == targetAttributeName);
                  }
                  if (foundObj.length > 0) {
                    foundFormValue = foundObj[0].id || '';
                    if (rawObject.formStringValue && rawObject.formStringValue.hasOwnProperty(control.attributeName)) {
                      foundFormStringValue = rawObject.formStringValue[control.attributeName] || '';
                    }
                  }
                } else {
                  foundFormValue = rawObject[entityName + '.' + control.attributeName] || '';
                  foundFormStringValue = rawObject[entityName + '.' + control.attributeName + '@OData.Community.Display.V1.FormattedValue'] || '';
                  if (isAddressEntity && !foundFormValue) {
                    entityName = "indskr_address";
                    foundFormValue = rawObject[entityName + '.' + control.attributeName] || '';
                    foundFormStringValue = rawObject[entityName + '.' + control.attributeName + '@OData.Community.Display.V1.FormattedValue'] || '';
                  }
                  if(!_.isEmpty(foundFormValue) && _.isEmpty(foundFormStringValue)) {
                    foundFormStringValue = rawObject[entityName + '.' + control.attributeName + '_value'] || '';
                  }
                }
                if (foundFormValue) {
                  this._setAttributeValue(control, foundFormValue, false);
                  this._setAttributeStringValue(control.attributeName, foundFormStringValue);
                  if (this._mode == 'EDIT_MODE' && this.formDetail.dynamicFormType == DynamicFormType.DEFAULTFORM) {
                    control.isReadOnly = true;
                  }
                }
                break;
              case ControlDataType.BooleanType:
                if (isUpdatedLinkedEntityFormData) {
                  if (control.isCustom) {
                    if (rawObject.customFormValue && rawObject.customFormValue.hasOwnProperty(control.attributeName)) {
                      foundFormValue = rawObject.customFormValue[control.attributeName] || '';
                    }
                  } else {
                    if (rawObject.formValue && rawObject.formValue.hasOwnProperty(control.attributeName)) {
                      foundFormValue = rawObject.formValue[control.attributeName] || '';
                    }
                    if (control.attributeName == "indskr_isprimary" && rawObject.formValue && !rawObject.formValue.hasOwnProperty(control.attributeName) && rawObject.formStringValue.indskr_isprimary == "No") {
                      this._setAttributeValue(control, false, false);
                    }
                  }
                } else {
                  foundFormValue = rawObject[entityName + '.' + control.attributeName];
                  if (isAddressEntity && foundFormValue == undefined) {
                    entityName = "indskr_address";
                    foundFormValue = rawObject[entityName + '.' + control.attributeName];
                  }
                }
                if (rawObject.hasOwnProperty(entityName + '.' + control.attributeName) || (rawObject.formValue && rawObject.formValue.hasOwnProperty(control.attributeName))) {
                  foundFormValue ? this._setAttributeValue(control, true, false) : this._setAttributeValue(control, false, false);
                  if (this._mode == 'EDIT_MODE' && this.formDetail.dynamicFormType == DynamicFormType.DEFAULTFORM) {
                    control.isReadOnly = true;
                  }
                }
                break;
              case ControlDataType.MultiSelectPicklistType:
                if (isUpdatedLinkedEntityFormData) {
                  if (control.isCustom) {
                    if (rawObject.customFormValue && rawObject.customFormValue.hasOwnProperty(control.attributeName)) {
                      foundFormValue = rawObject.customFormValue[control.attributeName] || '';
                    }
                  } else {
                    if (rawObject.formValue && rawObject.formValue.hasOwnProperty(control.attributeName)) {
                      foundFormValue = rawObject.formValue[control.attributeName] || '';
                    }
                  }
                  if (rawObject.formStringValue && rawObject.formStringValue.hasOwnProperty(control.attributeName)) {
                    foundFormStringValue = rawObject.formStringValue[control.attributeName].replace(/;/g, ',') || '';
                  }
                } else {
                  foundFormValue = rawObject[entityName + '.' + control.attributeName] || '';
                  foundFormStringValue = rawObject[entityName + '.' + control.attributeName + '@OData.Community.Display.V1.FormattedValue'].replace(/;/g, ',') || '';
                  if (isAddressEntity && !foundFormValue) {
                    entityName = "indskr_address";
                    foundFormValue = rawObject[entityName + '.' + control.attributeName] || '';
                    foundFormStringValue = rawObject[entityName + '.' + control.attributeName + '@OData.Community.Display.V1.FormattedValue'].replace(/;/g, ',') || '';
                  }
                }
                if(!_.isEmpty(foundFormValue) && _.isEmpty(foundFormStringValue)) {
                  foundFormStringValue = rawObject[entityName + '.' + control.attributeName + '_value'].replace(/;/g, ',') || '';
                }
                if (foundFormValue) {
                  this._setAttributeValue(control, foundFormValue, false);
                  this._setAttributeStringValue(control.attributeName, foundFormStringValue);
                  if (this._mode == 'EDIT_MODE' && this.formDetail.dynamicFormType == DynamicFormType.DEFAULTFORM) {
                    control.isReadOnly = true;
                  }
                }
                break;
              case ControlDataType.PicklistType:
                if (isUpdatedLinkedEntityFormData) {
                  if (control.isCustom) {
                    if (rawObject.customFormValue && rawObject.customFormValue.hasOwnProperty(control.attributeName)) {
                      foundFormValue = rawObject.customFormValue[control.attributeName] || '';
                    }
                  } else {
                    if (rawObject.formValue && rawObject.formValue.hasOwnProperty(control.attributeName)) {
                      foundFormValue = rawObject.formValue[control.attributeName] || '';
                    }
                  }
                  if (rawObject.formStringValue && rawObject.formStringValue.hasOwnProperty(control.attributeName)) {
                    foundFormStringValue = rawObject.formStringValue[control.attributeName] || '';
                  }
                } else {
                  foundFormValue = rawObject[entityName + '.' + control.attributeName] || '';
                  foundFormStringValue = rawObject[entityName + '.' + control.attributeName + '@OData.Community.Display.V1.FormattedValue'] || '';
                  if (isAddressEntity && !foundFormValue) {
                    entityName = "indskr_address";
                    foundFormValue = rawObject[entityName + '.' + control.attributeName] || '';
                    foundFormStringValue = rawObject[entityName + '.' + control.attributeName + '@OData.Community.Display.V1.FormattedValue'] || '';
                  }
                  if(!_.isEmpty(foundFormValue) && _.isEmpty(foundFormStringValue)) {
                    foundFormStringValue = rawObject[entityName + '.' + control.attributeName + '_value'] || '';
                  }
                }
                if (foundFormValue) {
                  this._setAttributeValue(control, foundFormValue, false);
                  this._setAttributeStringValue(control.attributeName, foundFormStringValue);
                  if (this._mode == 'EDIT_MODE' && this.formDetail.dynamicFormType == DynamicFormType.DEFAULTFORM) {
                    control.isReadOnly = true;
                  }
                }
                break;
              case ControlDataType.DateTimeType:
                if (isUpdatedLinkedEntityFormData) {
                  if (control.isCustom) {
                    if (rawObject.customFormValue && rawObject.customFormValue.hasOwnProperty(control.attributeName)) {
                      foundFormValue = rawObject.customFormValue[control.attributeName] || '';
                    }
                  } else {
                    if (rawObject.formValue && rawObject.formValue.hasOwnProperty(control.attributeName)) {
                      foundFormValue = rawObject.formValue[control.attributeName] || '';
                    }
                  }
                  if (rawObject.formStringValue && rawObject.formStringValue.hasOwnProperty(control.attributeName)) {
                    foundFormStringValue = rawObject.formStringValue[control.attributeName] || '';
                  }
                } else {
                  foundFormValue = rawObject[entityName + '.' + control.attributeName] || '';
                  foundFormStringValue = rawObject[entityName + '.' + control.attributeName + '@OData.Community.Display.V1.FormattedValue'] || '';
                  if (isAddressEntity && !foundFormValue) {
                    entityName = "indskr_address";
                    foundFormValue = rawObject[entityName + '.' + control.attributeName] || '';
                    foundFormStringValue = rawObject[entityName + '.' + control.attributeName + '@OData.Community.Display.V1.FormattedValue'] || '';
                  }
                  if(!_.isEmpty(foundFormValue) && _.isEmpty(foundFormStringValue)) {
                    foundFormStringValue = rawObject[entityName + '.' + control.attributeName + '_value'] || '';
                  }
                }
                if (foundFormValue || foundFormStringValue) {
                  let date: Date;
                  if (foundFormValue) {
                      date = new Date(foundFormValue);
                      if (!isValid(date)) {
                        date = new Date(parseInt(foundFormValue));
                        if (!isValid(date)) {
                          date = new Date(foundFormStringValue);
                        }
                      }
                  } else if (foundFormStringValue) {
                      date = new Date(foundFormStringValue);
                  }
                  if (isValid(date)) {
                    if (control.dateFormat === DateFormat.DateAndTime) {
                      this._setAttributeValueDate(control, date);
                    } else if (control.dateFormat === DateFormat.DateOnly) {
                      date.setHours(0, 0, 0, 0);
                      this._setAttributeValueDate(control, date);
                    }
                  } else {
                    this._setAttributeValue(control, "");
                    this._setAttributeStringValue(control.attributeName, "");
                  }
                  if (control.attributeName == 'indskr_startdate' && this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) {
                    if (this.startTime && this.startTime.hasOwnProperty(control.attributeName)) {
                      this.startTime[control.attributeName] = this._currentFormStringValues[control.attributeName];
                    } else {
                      this.startTime = {
                        indskr_startdate: this._currentFormStringValues[control.attributeName]
                      }
                    }
                  } else if (control.attributeName == 'indskr_enddate' && this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) {
                    if (this.endTime && this.endTime.hasOwnProperty(control.attributeName)) {
                      this.endTime[control.attributeName] = this._currentFormStringValues[control.attributeName];
                    } else {
                      this.endTime = {
                        indskr_enddate: this._currentFormStringValues[control.attributeName]
                      }
                    }
                  }
                  if (this._mode === 'EDIT_MODE' && this.formDetail.dynamicFormType == DynamicFormType.DEFAULTFORM) {
                    control.isReadOnly = true;
                  }
                }
                break;
              case ControlDataType.StateType:
              case ControlDataType.StatusType:
                if (this.MODE =='EDIT_MODE') {
                  control.isReadOnly = true;
                  foundFormValue = rawObject[entityName + '.' + control.attributeName] || '';
                  foundFormStringValue = rawObject[entityName + '.' + control.attributeName + '@OData.Community.Display.V1.FormattedValue'] || '';
                  if(!_.isEmpty(foundFormValue) && _.isEmpty(foundFormStringValue)) {
                    foundFormStringValue = rawObject[entityName + '.' + control.attributeName + '_value'] || '';
                  }
                  if (foundFormStringValue || foundFormStringValue == '0') {
                    this._setAttributeValue(control, foundFormValue, false);
                    if (foundFormStringValue == "In Progress" && this.changeRequest) {
                      foundFormStringValue = "In Process";
                    }
                    if (!_.isEmpty(foundFormStringValue)) this._setAttributeStringValue(control.attributeName, foundFormStringValue);
                  } else {
                    this._setAttributeValue(control, "");
                    this._setAttributeStringValue(control.attributeName, "")
                  }
                }
                break;
              default:
                break;
            }
          });
        }
      });
    }
  }

  async ngOnInit() {
    if (this.MODE) {
      this._mode = this.MODE;
      this._previousMode = this.MODE;
    }
    if(this.from && this.from!="") {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
    }
    if (this.TYPE === "ONE_KEY" || this.TYPE === "OMNIPRESENCE" || this.TYPE == "SANOFI_CHINA") {
      this.changeRequest = true;
      this.businessLine = this.authService.user.buConfigs && this.authService.user.buConfigs.indskr_businessline ? this.authService.user.buConfigs.indskr_businessline : -1;
      if (this.TYPE =='SANOFI_CHINA' && (this.businessLine == 2 || this.businessLine == 3)) {
        this.worksCardHeader = {
          id: 'work-card-section-header',
          title: this.translate.instant('ATTACHMENT_1'),
          controls: [{
            id: 'attach_work_cards',
            text: this.translate.instant('ATTACH'),
            isDisabled: this.device.isOffline || this.disableForm
          }]
        };
        this.practiceCertificateHeader = {
          id: 'practice-certificate-section-header',
          title: this.translate.instant('ATTACHMENT_2'),
          controls: [{
            id: 'attach_practice_certificate',
            text: this.translate.instant('ATTACH'),
            isDisabled: this.device.isOffline || this.disableForm
          }]
        };
      }
    }

    if (this.FORM_DETAIL) {
      this.formDetail = this.FORM_DETAIL;
      if (this.formDetail.formMetadata) {
        this._dynamicForm = this.formDetail.formMetadata;
        if(this._dynamicForm.entityName == 'indskr_setbooking'){
          // For Kit Booking Create and Edit form Hardcode the attribute names for start date and end date fields as all the validation and logics are hardcoded to specific attribute names
          // These hardcoded attribute names are reverted when form is closed on Save operation
          this._dynamicForm.metadata.forEach((tab) => {
            if (tab && tab.controls && tab.controls.length > 0) {
              let startDateControlIndex = tab.controls.findIndex(a => a.attributeName == 'indskr_operationstartdate');
              let endDateControlIndex = tab.controls.findIndex(a => a.attributeName == 'indskr_operationenddate');
              if(startDateControlIndex >= 0){
                tab.controls[startDateControlIndex].attributeName = 'indskr_startdate';
              }
              if(endDateControlIndex >= 0){
                tab.controls[endDateControlIndex].attributeName = 'indskr_enddate';
              }
            }
          });
        }

        if (!this.changeRequest || (this.changeRequest && this.TYPE == "OMNIPRESENCE")) {
          if (!this.formDetail.isEditFormWithLinkedData && this._mode == "EDIT_MODE" && this.TYPE == 'OMNIPRESENCE') {
            this.editConfig = EditConfig.BOTH;
          } else if (!this.formDetail.isEditFormWithLinkedData && this._mode == "EDIT_MODE") {
            this.editConfig = this._dynamicForm.editConfig;
          } else if (this.formDetail.isEditFormWithLinkedData) {
            this.editConfig = this.EDIT_CONFIG;
          }
        }
      }

      // Check if it's DCR Vx-MDM Business Line flow
      if (
        this.changeRequest
        && this.businessLine === BusinessLineVal.VACCINE_MDM
        && this.formDetail.LookupEntitySetName === 'indskr_contactcrs'
        && this.formDetail.formMetadata?.raw?.indskr_mdmusertype === MDM_USER_TYPE.VACCINE_MDM
        && (this._mode === 'CREATE_MODE' || this._mode === 'EDIT_MODE')
      ) {
        this._DCR_isVxMDMFlow = true;
      }

      this._runHardcodedLogic();
      if (this.formDetail.formEntityObject && !this.formDetail.isEditFormWithLinkedData) {
        this._parseRawObjectDataToValues(this.formDetail.formEntityObject);
        if(this._mode == "EDIT_MODE"){
          this._isFormDirty = false;
          this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
          if (this.formDetail && this.formDetail.eventsHandler) {
            this.formDetail.eventsHandler(false, 'ISFORMDIRTY');
          }
        }
        if (this.formDetail.formEntityObject.indskr_mdmid) {
          this.dynamicFormsService.mdmId = this.formDetail.formEntityObject.indskr_mdmid;
        }
      }
      if (this.formDetail.mainEntityObjectId) {
        this._currentFormValue = {
          ...this.formDetail.mainEntityObjectId,
          ...this._currentFormValue
        };
        this._isFormSaved = true;
      }
      if (this.formDetail.showSearchBar) {
        this.isSearchBarEnabled = true;
        this.searchPlaceholder = this.translate.instant('SEARCH') + ' ' + (this.formDetail.title ? this.formDetail.title : '');
      }
      if (this.formDetail.linkedEntityParentData) {
        if (!this.formDetail.isEditFormWithLinkedData) {
          this._currentFormValue = { lookupfields: [this.formDetail.linkedEntityParentData] };
        } else {
          this._currentFormValue = { lookupfields: [this.formDetail.linkedEntityParentData], ...this._currentFormValue };
        }
      }
      if (this.formDetail.formMetadata.entityName === 'indskr_accountcontactaffiliationcr' || this.formDetail.formMetadata.entityName === 'indskr_accountaccountaffiliationcr'
          || this.formDetail.formMetadata.entityName === 'indskr_customeraddresscrs') {
        this._initViewData();
      } else this._initViewData(true);

      // Business flow, Fill the form with existing linked entity data
      if ((!this.changeRequest || (this.changeRequest && this.TYPE == "OMNIPRESENCE")) && (this.editConfig == EditConfig.UPDATE_EXISTING || this.editConfig == EditConfig.BOTH || (this.editConfig == EditConfig.ADD_NEW && this.formDetail.LookupEntitySetName == 'indskr_setbookingproducts'))) {
        // main form view
        if (!this.formDetail.isEditFormWithLinkedData && (this._mode == "EDIT_MODE" || (this._mode == "CREATE_MODE" && this.formDetail.LookupEntitySetName == 'indskr_setbookingproducts'))) {
          await this._parseRawObjectDataToValuesLinkedEntity();
        }
        // linked entity form view
        else if (this.formDetail.isEditFormWithLinkedData && this._mode == "CREATE_MODE") {
          let formEntityObj = this.formDetail.formEntityObject;
          let isUpdatedLinkedEntityFormData: boolean = this.formDetail.isUpdatedLinkedEnityFormData;
          await this._parseRawObjectDataToValuesExistingData(formEntityObj, isUpdatedLinkedEntityFormData).then(()=> {
            this._initViewData();
          });
        }
      }
      this._runBusinessRulesLogic();
    }

    if (this.formDetail.dynamicFormType == DynamicFormType.DEFAULTFORM) {

      let loaderison = false;
      for (let tab of this._dynamicForm.metadata) {
        for (let con of tab.controls) {
          if (con.dataType == ControlDataType.PicklistType || con.dataType == ControlDataType.MultiSelectPicklistType) {
            if (!loaderison) {
              this.uiService.displayLoader();
              loaderison = true;
            }
            let optionSetName = con.optionSetName ? con.optionSetName : con.attributeName;
            if(!(optionSetName === 'indskr_accountsourcetype' || optionSetName === 'indskr_contactsourcetype'))
              con.optionSet = await this.dynamicFormsService.getOptionSet(optionSetName);
          }
        }
      }
      this.uiService.dismissLoader();
    }
    // display/add existing linked data for auto-populated
    if (this.changeRequest) {
      this._foundFormForAddressCR = await this.dynamicFormsService.getFormDefinitionForEntity('indskr_addresscr', FormType.CREATEFORM);
    }
    if (this.changeRequest && this._mode == "EDIT_MODE") {
      // foundForm is for address edit in account CR
      this._foundFormForACAffiliationCR = await this.dynamicFormsService.getFormDefinitionForEntity('indskr_accountcontactaffiliationcr', FormType.CREATEFORM);
      this._dynamicFormForAddressEdit = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_customeraddresscr", FormType.CREATEFORM);
      if (this.formDetail.LookupEntitySetName == "indskr_contactcrs" || this.formDetail.LookupEntitySetName == "indskr_contactcr") {
        this.autoPopulatedLinkedData('accountContactAffiliation', false);
        this.autoPopulatedLinkedData('contactAddress', false);
      } else if (this.formDetail.LookupEntitySetName == "indskr_accountcrs" || this.formDetail.LookupEntitySetName == "indskr_accountcr") {
        this.autoPopulatedLinkedData('accountAddress', false).then(() =>{
          this.addressAttrName = this.dynamicFormsService.addressAttributeName;
        });
      }
    }
    if (this._dynamicForm.entityName == 'indskr_setbooking') {
      const today = new Date();
      let startTime;
      if(_.isEmpty(this.startTime) || (this.startTime && !this.startTime.hasOwnProperty('indskr_startdate'))) {
        let targetControl: Control = this._getTargetControl('indskr_startdate');
        startTime = new Date(new Date().setDate(new Date().getDate() + 1)).setHours(8,0,0,0);
        this.startTime['indskr_startdate'] = moment(startTime).format();
        this._updateFormDateModernDatePicker(this.startTime['indskr_startdate'], true, targetControl);
      }
      if(_.isEmpty(this.endTime) || (this.endTime && !this.endTime.hasOwnProperty('indskr_enddate'))) {
        let targetControl: Control = this._getTargetControl('indskr_enddate');
        let kigBookingDuration = _.cloneDeep(this.authService.user.buSettings['indskr_kitbookingduration']);
        let endTime = kigBookingDuration ? new Date(this.startTime['indskr_startdate']).setMinutes(kigBookingDuration,0,0) : new Date(new Date().setDate(new Date().getDate() + 1)).setHours(23,59,59,59);
        this.endTime['indskr_enddate'] = moment(endTime).format();
        this._updateFormDateModernDatePicker(this.endTime['indskr_enddate'], true, targetControl);
      }
      if (!(this._currentFormValue && this._currentFormValue.hasOwnProperty('lookupfields') && this._currentFormValue['lookupfields'].findIndex(a => a.name == 'indskr_salesconsultant')==-1)
          || (this._currentFormValue && this._mode=="CREATE_MODE" && this.isLayoverPushView && (this.layoverViewPushedFrom == "KitBookingfromAccountTool" || this.layoverViewPushedFrom == "KitBookingfromContactTool"))
      ) {
        let salesCon = this._currentFormValue['lookupfields']?.find(a => a.name == 'indskr_salesconsultant');
        if(_.isEmpty(salesCon) || (salesCon && salesCon.id && salesCon.id == this.authService.user.systemUserID)) {
          let targetControl = this._getTargetControl('indskr_salesconsultant');
          this._activeLookupSearchControl = targetControl;
          let data = {
            isDone: true,
            selectedItem: {
              id: this.authService.user.systemUserID,
              name: this.authService.user.displayName
            }
          }
          this._handleLookupComponentCallback(data);
          this._activeLookupSearchControl = undefined;
        }
      }
      if(!(this._currentFormValue && this._currentFormValue.hasOwnProperty('indskr_operationtype'))) {
        let targetControl: Control = this._getTargetControl('indskr_operationtype');
        let typeConfig =  this.authService.user.ioConfigurations?.find(o=>o.configName=="KitBooking.OperationTypes.SubTypes.Relation");
        let data = { id:'', title:''};
        let dropdownListDetail: IndDropdownListDetailModel = this.dynamicFormsService.getDropdownListViewFromOptionSet(targetControl.optionSet, '', this._activeLanguageCode);
        if(typeConfig && typeConfig.configValue) {
          let selectedTypeConfig = JSON.parse(typeConfig.configValue).find(o=> o["BU"].toLowerCase().trim() == this.authService.user.businessUnitName.toLowerCase().trim());
          if(selectedTypeConfig && dropdownListDetail && dropdownListDetail.data) {
            let foundOption = dropdownListDetail.data.find(option => option.title.toLowerCase().trim() == selectedTypeConfig['operationType'].toLowerCase().trim());
            if(foundOption) {
              data.title = foundOption.title;
              data.id = foundOption.id;
            }
          }
        }
        this._setAttributeStringValue(targetControl.attributeName, data.title);
        this._setAttributeValue(targetControl, data.id, false);
      }
    }
    this.events.observe('device:deviceIsOffline').pipe(takeUntil(this.$ngDestroy)).subscribe(() => {
      this._initViewData();
    });
    this.events.observe('device:deviceIsOnline').pipe(takeUntil(this.$ngDestroy)).subscribe(() => {
      this._initViewData();
    });
    this.events.observe('updateMandatoryField').pipe(takeUntil(this.$ngDestroy)).subscribe((field: PageName) => {
      this._initViewData();
    });
    // Update start/end time field with selected time from the time picker
    this.dateTimeFormatsService.updateSelectedTimeConfiguredForm$.pipe(takeUntil(this.$ngDestroy)).subscribe((timeData) => {
      if (timeData) {
        let targetControl: Control;
        if (timeData.controlId) {
          targetControl = this._getTargetControl(timeData.controlId);
        }
        if (timeData.id == DateTimeFieldType.StartTimeField && targetControl !=undefined) {
          if (this.startTime && this.startTime.hasOwnProperty(targetControl.attributeName)) {
            this.startTime[targetControl.attributeName] = timeData.time;
          } else {
            this.startTime = {
              [targetControl.attributeName]: timeData.time
            }
          }
          // Auto calculated enddate field
          if (timeData.controlId == 'indskr_startdate') {
            let secondaryTargetControl: Control = this._getTargetControl('indskr_enddate');
            let startTime = this.startTime[targetControl.attributeName] ? this.startTime[targetControl.attributeName] : '';
            let endTime = this.endTime['indskr_enddate'] ? this.endTime['indskr_enddate'] : '';
            let diffInMin: number;
            if (startTime && endTime) {
              diffInMin = differenceInMinutes(endTime, startTime);
            }
            if (this._dynamicForm.entityName == 'indskr_setbooking') {
              let kigBookingDuration = _.cloneDeep(this.authService.user.buSettings['indskr_kitbookingduration']);
              if(kigBookingDuration) {
                let endTime = addMinutes(new Date(startTime), kigBookingDuration);
                this.endTime['indskr_enddate'] = moment(endTime).format();
                this._updateFormDateModernDatePicker(this.endTime['indskr_enddate'], true, secondaryTargetControl);
              }
              this._updateFormDateModernDatePicker(startTime, false, targetControl);
            } else if (secondaryTargetControl && diffInMin <= 0) {
              if (this.endTime && this.endTime.hasOwnProperty('indskr_enddate')) {
                this.endTime['indskr_enddate'] = timeData.addedTime;
              } else {
                this.endTime = {
                  indskr_enddate: timeData.addedTime
                }
              }
              this._updateFormDateModernDatePicker(timeData.addedTime, false, secondaryTargetControl);
            }
          }
          setTimeout(() => {
            this.handleStartDateTimeValue(targetControl);
          }, 0);
        } else if (timeData.id == DateTimeFieldType.EndTimeField && targetControl !=undefined) {
          if (this.endTime && this.endTime.hasOwnProperty('indskr_enddate')) {
            this.endTime['indskr_enddate'] = timeData.time;
          } else {
            this.endTime = {
              indskr_enddate: timeData.time,
            }
          }
          setTimeout(() => {
            this.handleEndDateTimeValue(targetControl);
          }, 0);
        }
      }
    });
  }

  ngOnDestroy() {
    this.searchKeyWordFromMainToolTemplate = '';
    this.$ngDestroy.next(true);
    this.$ngDestroy.complete();
    if(!(this.navService.getActiveChildNavViewPageName() === PageName.DynamicFormComponent)) {
      this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
      this.setBookingDataService._isCreateEditKitBookingFormDirty = false;
    }
  }

  private initFormPageTitleAndButtons(isFormValid: boolean) {
    const buttons = [];
    if (this.formDetail.leftHeaderBtnText && !this.formDetail.isEditFormWithLinkedData) {
      buttons.push({
        id: 'cancel',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.formDetail.leftHeaderBtnText,
        isDisabled: false,
        align: 'right',
      });
    }
    if (this.formDetail.leftHeaderBtnIcon) {
      buttons.push({
        id: 'close',
        icon: this.formDetail.leftHeaderBtnIcon,
        isDisabled: false,
        align: 'left',
      });
    }
    if (this.formDetail.leftHeaderBtnText && this.formDetail.isEditFormWithLinkedData) {
      buttons.push({
        id: 'scrap',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.formDetail.leftHeaderBtnText,
        isDisabled: false,
        align: 'right',
      });
    }
    if (this.formDetail.rightHeaderBtnText) {
      /*************Edit business information in offline - Non-OneKey contacts*************/
      if(this.formDetail.formMetadata.entityName == 'contact' || this.formDetail.formMetadata.entityName == 'indskr_contactcr') {
          buttons.push({
            id: 'done',
            imgSrc: this.formDetail.rightHeaderImgSrc,
            name: this.formDetail.rightHeaderBtnText || this.translate.instant('CR_SAVE'),
            isDisabled: this.disableForm || !isFormValid || !this._isFormDirty,
            align: 'right'
          });
        }
      /*************Edit business information in offline - Non-OneKey contacts*************/
        else {
          buttons.push({
            id: 'done',
            imgSrc: this.formDetail.rightHeaderImgSrc,
            name: this.formDetail.rightHeaderBtnText,
            isDisabled: this.disableForm || !isFormValid || !this._isFormDirty,
            align: 'right'
          });
        }
    }
    if(this.formDetail.rightHeaderBtnTextArray){
      for(let i=0; i<this.formDetail.rightHeaderBtnTextArray.length; i++){
        const isScrapButtonDisabled = this.formDetail.rightHeaderBtnIdsArray[i] == "SCRAPKITBOOKING" &&
        !(this.formDetail.formEntityObject?.indskr_status == SetBookingStatus.DRAFT ||
          this.formDetail.formEntityObject?.indskr_status == SetBookingStatus.SUBMITTED);
        const isSaveButtonDisabled = this.formDetail.rightHeaderBtnIdsArray[i] == "SAVEKITBOOKING" &&
          (!this._isFormDirty ||
            this.formDetail.formEntityObject?.indskr_status == SetBookingStatus.SUBMITTED ||
            this.formDetail.formEntityObject?.indskr_status == SetBookingStatus.SYNCERROR ||
            this.formDetail.formEntityObject?.indskr_status == SetBookingStatus.REQUESTED ||
            this.device.isOffline
          )
        buttons.push({
          id: this.formDetail.rightHeaderBtnIdsArray[i],
          imgSrc: this.formDetail.rightHeaderImgSrcArray[i],
          name: this.formDetail.rightHeaderBtnTextArray[i],
          isDisabled: this.disableForm || (!isFormValid && this.formDetail.rightHeaderBtnIdsArray[i]!="SCRAPKITBOOKING") || isScrapButtonDisabled || isSaveButtonDisabled,
          align: 'right'
        });
      }
    }

    this.dynamicFormPageTitle = {
      id: 'dynamic-form-page-title',
      title: this.formDetail.title,
      controls: buttons,
    };
  }

  private _initViewData(isFirstInit?: boolean) {
    let isFormValid = true;
    this.isAccountAccountAffiliationPopover = false;
    this.isAccountContactAffiliationPopover = false;
    this.isAddressOneKeyPopover = false;
    this.isAddressPopover = false;

    if (this._dynamicForm && this._dynamicForm.metadata && this._dynamicForm.metadata.length > 0) {
      this.currentFormView = [];
      const nonVisibleFormSection = this.setBookingDataService.formSectionConfigure?.filter(config => config['_indskr_mobileappform_value'] == this.formDetail.formMetadata.mobileFormId && !config['indskr_visibility']);
      this._dynamicForm.metadata.forEach((tab, tabIndex) => {
        let isClearEnabled = false;
        let isSectionHide = false;
        if (this.formDetail.customFields && tabIndex == 0) {
          isClearEnabled = true;
          if (isFirstInit) tab.controls = [...this.formDetail.customFields, ...tab.controls];
        }
        if (!this.changeRequest && this.formDetail.showClearButton && tabIndex == 0) isClearEnabled = true;
        if (tab && tab.controls && tab.controls.length > 0) {
          // if(this.changeRequest && this.formDetail.LookupEntitySetName === "indskr_customeraddresscrs") {
          //   //Address and customer Address both Name field can be present for customer address change request
          //   tab.controls = _.unionBy(tab.controls, 'attributeName');
          // }
          tab.displayNames?.forEach(section => {
            if(!_.isEmpty(nonVisibleFormSection) && nonVisibleFormSection.some(sec => sec['indskr_name'].toLowerCase().trim() == section.description.toLowerCase().trim())) {
              isSectionHide = true;
            }
          })
          if(isSectionHide) return;
          let secHeader = this._getSectionHeaderViewForTab(tab, isClearEnabled);
          if (secHeader) {
            this.currentFormView.push(secHeader);
          }
          tab.controls.forEach(control => {
            let view = this._getViewForControl(control, tab, isFirstInit);
            if (view) {
              if(this.changeRequest) {
                this.disableDefaultValues(view);
              } else {
                this.disableDefaultValuesForBusiness(view);
              }
              this.currentFormView.push(view);
            }
            if(control.dataType && this.formDetail.formMetadata.entityName == 'indskr_indskr_customeraddress_v2' && this.selectedSearchOption) {
              let formView = this.currentFormView.find(data => data.id == control.attributeName);
              if(formView){
                if(formView.view?.inputText !== this.selectedSearchOption[control.attributeName]) this.dynamicFormsService.isSearchedAddress = false;
              }
            }
            // Check for validation
            if(!control.forceHide) {
              if (control.isRequired && control.dataType) {
                isFormValid = this._checkControlValidation(control) && isFormValid;
              } else if ((this.changeRequest || (!this.changeRequest && this._mode == "EDIT_MODE") || (!this.changeRequest && this._mode == "CREATE_MODE" && this._isFormSaved)) && control.isVisible && !control.dataType){
                isFormValid = this._checkLinkedEntityControlValidation(control) && isFormValid;
              }
            }
          });
        }
      });
      if (this.dynamicFormsService.isSelectedDataFromSearch  && (this.formDetail.formMetadata.entityName == 'indskr_accountcr' || this.formDetail.formMetadata.entityName == 'indskr_contactcr')) {
        this.currentFormView.forEach(data => {
          if (data.control?.isRequired) {
            if (data.view?.inputText.length > 0) {
              isFormValid = true && isFormValid;
            } else isFormValid = false;
          }
        });
      }
    }
    //Additional mandatory related entities form fields check
    if (this.formDetail.formMetadata.entityName == 'indskr_accountcr' || this.formDetail.formMetadata.entityName == 'indskr_contactcr'
      || this.formDetail.formMetadata.entityName == 'indskr_customeraddresscr') {
      if (this._dynamicForm && this._dynamicForm.metadata) {
        for (let tabIdx = 0; tabIdx < this._dynamicForm.metadata.length; tabIdx++) {
          const tab = this._dynamicForm.metadata[tabIdx];
          if (tab && tab.controls && tab.controls.length > 0) {
            tab.controls.forEach(control => {
              // To Check entities for Auto-populated Data Flow
              if (this.formDetail.formMetadata.entityName == 'indskr_accountcr') {
                if (control.isVisible && (control.lookupEntityPrimaryId == "indskr_customeraddresscrid")) {
                  this.hasAddressForm = true;
                }
              } else if (this.formDetail.formMetadata.entityName == 'indskr_contactcr') {
                if (control.isVisible && (control.lookupEntityPrimaryId == "indskr_accountcontactaffiliationcrid")) {
                  this.hasAccountContactAffiliationForm = true;
                }
              }
            });
          }
        };
        if (this.TYPE == 'SANOFI_CHINA' && (this.businessLine == 2) && (this.isWorkCardAdded || this.isPracticeCertificateAdded)) {
          this._isFormDirty = true;
        } else if (this.TYPE == 'SANOFI_CHINA' && (this.businessLine == 2) && !this.isWorkCardAdded && !this.isPracticeCertificateAdded) {
          this._isFormDirty = false;
        }
      }
    }
    if (this.isAutoPopulatedLinkedDataFlow && !_.isEmpty(this.formEditAddressDetail) && !this.addedNewAddressForAccountCR && isFormValid !=undefined) {
      isFormValid = this._initViewEditFormData() && isFormValid;
    }

    this.initFormPageTitleAndButtons(isFormValid);

  }

  private _targetControlViewData(control: Control) {
    let isFormValid = true;
    this.isAccountAccountAffiliationPopover = false;
    this.isAccountContactAffiliationPopover = false;
    this.isAddressOneKeyPopover = false;
    this.isAddressPopover = false;

    if (this._dynamicForm && this._dynamicForm.metadata && this._dynamicForm.metadata.length > 0) {
      this._dynamicForm.metadata.forEach((tab, tabIndex) => {
        if (tab && tab.controls && tab.controls.length > 0) {
          tab.controls.forEach(control => {
            // Check for validation
            if(!control.forceHide) {
              if (control.isRequired && control.dataType) {
                isFormValid = this._checkControlValidation(control) && isFormValid;
              } else if ((this.changeRequest || (!this.changeRequest && this._mode == "EDIT_MODE") || (!this.changeRequest && this._mode == "CREATE_MODE" && this._isFormSaved)) && control.isVisible && !control.dataType){
                isFormValid = this._checkLinkedEntityControlValidation(control) && isFormValid;
              }
            }
          });
        }
      });
      if (this.dynamicFormsService.isSelectedDataFromSearch  && (this.formDetail.formMetadata.entityName == 'indskr_accountcr' || this.formDetail.formMetadata.entityName == 'indskr_contactcr')) {
        this.currentFormView.forEach(data => {
          if (data.control?.isRequired) {
            if (data.view?.inputText.length > 0) {
              isFormValid = true && isFormValid;
            } else isFormValid = false;
          }
        });
      }
    }
    //Additional mandatory related entities form fields check
    if (this.formDetail.formMetadata.entityName == 'indskr_accountcr' || this.formDetail.formMetadata.entityName == 'indskr_contactcr'
      || this.formDetail.formMetadata.entityName == 'indskr_customeraddresscr') {
      if (this._dynamicForm && this._dynamicForm.metadata) {
        for (let tabIdx = 0; tabIdx < this._dynamicForm.metadata.length; tabIdx++) {
          const tab = this._dynamicForm.metadata[tabIdx];
          if (tab && tab.controls && tab.controls.length > 0) {
            tab.controls.forEach(control => {
              // To Check entities for Auto-populated Data Flow
              if (this.formDetail.formMetadata.entityName == 'indskr_accountcr') {
                if (control.isVisible && (control.lookupEntityPrimaryId == "indskr_customeraddresscrid")) {
                  this.hasAddressForm = true;
                }
              } else if (this.formDetail.formMetadata.entityName == 'indskr_contactcr') {
                if (control.isVisible && (control.lookupEntityPrimaryId == "indskr_accountcontactaffiliationcrid")) {
                  this.hasAccountContactAffiliationForm = true;
                }
              }
            });
          }
        };
        if (this.TYPE == 'SANOFI_CHINA' && (this.businessLine == 2) && (this.isWorkCardAdded || this.isPracticeCertificateAdded)) {
          this._isFormDirty = true;
        } else if (this.TYPE == 'SANOFI_CHINA' && (this.businessLine == 2) && !this.isWorkCardAdded && !this.isPracticeCertificateAdded) {
          this._isFormDirty = false;
        }
      }
    }
    if (this.isAutoPopulatedLinkedDataFlow && !_.isEmpty(this.formEditAddressDetail) && !this.addedNewAddressForAccountCR && isFormValid !=undefined) {
      isFormValid = this._initViewEditFormData() && isFormValid;
    }

    // update target control field
    if(control.attributeName && !_.isEmpty(this.currentFormView)) {
      const targetControlView = this._getViewForControl(control);
      let index = -1;
      if(!control.dataType) {
        let sectionHeaderName:string = this.localizationService.getTranslatedString(this._getDisplayText(control.displayNames));
        index = this.currentFormView.findIndex(v=>v.view.id == sectionHeaderName);
      } else if(control.dataType == ControlDataType.DateTimeType) {
        const isBirthDateField: boolean = (control.attributeName == 'omnione_yearofbirth' || control.attributeName == 'omnione_dayandmonthofbirth');
        if(isBirthDateField) {
          index = this.currentFormView.findIndex(v=>v.view.id == control.attributeName);
        }else {
          index = this.currentFormView.findIndex(v=>v.view.controlId == control.attributeName);
        }
      }else {
        index = this.currentFormView.findIndex(v=>v.view.id == control.attributeName);
      }

      if(index > -1) {
        this.currentFormView[index] = targetControlView;
      }
    }

    this.initFormPageTitleAndButtons(isFormValid);

  }
  // initView for address edit form - Account CR
  private _initViewEditFormData(isFirstInit?: boolean): boolean {
    let isFormValid = true;
    this.isAccountAccountAffiliationPopover = false;
    this.isAccountContactAffiliationPopover = false;
    this. isAddressOneKeyPopover = false;
    this.isAddressPopover = false;

    // set view data for address edit form with existing data - Account CR-Address
    if (this._dynamicFormForAddressEdit && this._dynamicFormForAddressEdit.metadata && this._dynamicFormForAddressEdit.metadata.length > 0) {
      this.currentEditFormView = [];
      this._dynamicFormForAddressEdit.metadata.forEach((tab, tabIndex) => {
        if (this.formEditAddressDetail.customFields && tabIndex == 0) {
          if (isFirstInit) tab.controls = [...this.formEditAddressDetail.customFields, ...tab.controls];
        }
        if (tab && tab.controls && tab.controls.length > 0) {
          tab.controls.forEach(control => {
            let view = this._getViewAddressEditFormForControl(control);
            if (view) {
              this.currentEditFormView.push(view);
            }
            // Check for validation
            if(!this.dynamicFormsService.isSelectedDataFromSearch) {
              if (control.isRequired && control.dataType && !control.forceHide) {
                isFormValid = this._checkControlValidation(control, true) && isFormValid;
              }
            }
          });
        }
      });
    }
    return isFormValid;
  }

  private disableDefaultValues(view) {
    switch (view.id) {
      case "indskr_name":
        if (this.MODE === "EDIT_MODE" && !this._isFormSaved) {
          view.view.isDisabled = false;
          view.view.showArrow = true;
        }
        break;
      case "indskr_firstname":
        if (this.MODE === "EDIT_MODE" && !this._isFormSaved) {
          view.view.isDisabled = false;
          view.view.showArrow = true;
        }
        break;
      case "indskr_lastname":
        if (this.MODE === "EDIT_MODE" && !this._isFormSaved) {
          view.view.isDisabled = false;
          view.view.showArrow = true;
        }
        break;
      case "indskr_mdm":
        view.view.isDisabled = true;
        view.view.inputValue = this.formDetail.formEntityObject.indskr_mdm == BusinessProcessType.SanofiChina ? "SanofiChina" : "OneKey";
        view.view.inputText = this.formDetail.formEntityObject.indskr_mdm == BusinessProcessType.SanofiChina ? "SanofiChina" : "OneKey";
        view.view.showArrow = false;
        break;
      case "indskr_mdmrequesttype":
        view.view.isDisabled = true;
        view.view.inputValue = this.formDetail.formEntityObject.indskr_mdmrequesttype == 548910000 ? this.translate.instant('CREATE') : this.translate.instant('UPDATE');
        view.view.inputText = this.formDetail.formEntityObject.indskr_mdmrequesttype == 548910000 ? this.translate.instant('CREATE') : this.translate.instant('UPDATE');
        view.view.showArrow = false;
        break;
      case "indskr_mdmid":
        view.view.isDisabled = true;
        view.view.inputValue = this.formDetail.formEntityObject.indskr_mdmid;
        view.view.inputText = this.formDetail.formEntityObject.indskr_mdmid;
        view.view.showArrow = false;
        break;
      case "indskr_accountsourcetype":
        if (this.TYPE == "OMNIPRESENCE") {
          view.view.inputValue = "Business";
          view.view.inputText = "Business";
        } else {
          view.view.inputValue = "MDM";
          view.view.inputText = "MDM";
        }
        view.view.isDisabled = true;
        view.view.showArrow = false;
        break;
      case "indskr_contactsourcetype":
        if(this.TYPE == "OMNIPRESENCE"){
          //view.view.inputValue = "OMNIPRESENCE";
          //view.view.inputText = "OMNIPRESENCE";
        }else{
          view.view.inputValue = "MDM";
          view.view.inputText = "MDM";
        }
        view.view.isDisabled = true;
        view.view.showArrow = false;
        break;
    }
  }

  private getOptionSetMap(view, value) {
    if (view.control && view.control.optionSet && !_.isEmpty(view.control.optionSet)) {
      const optionMap = {key:"", value:""};
      const options = view.control.optionSet;
      options.forEach(o => {
        if (o['displayNames'] && !_.isEmpty(o['displayNames'])) {
          o['displayNames'].forEach(d => {
            if (d['description'] && d['description'] == value) {
              optionMap['key'] = d['description'];
              optionMap['value'] = o['value'];
            }
          })
        }
      })
      return optionMap;
    }
  }

  private disableDefaultValuesForBusiness(view) {
    switch (view.id) {
      case "indskr_accountsourcetype":
        const accountSource: string = this.formDetail.formEntityObject?.indskr_accountsourcetype == ChangeRequestType.MDM ? 'OneKey' : this.translate.instant('BUSINESS');
        view.view.isDisabled = true;
        view.view.inputValue = accountSource;
        view.view.inputText = accountSource;
        view.view.showArrow = false;
        break;
      case "indskr_contactsourcetype":
        const contactSource: string = this.formDetail.formEntityObject?.indskr_contactsourcetype == ChangeRequestType.MDM ? 'OneKey' : this.translate.instant('BUSINESS');
        view.view.isDisabled = true;
        view.view.inputValue = contactSource;
        view.view.inputText = contactSource;
        view.view.showArrow = false;
        break;
    }
  }

  private _checkControlValidation(control, isEditAddressForm?: boolean): boolean {
    let flag: boolean = true;
    const currentValue = !isEditAddressForm ? (control.isCustom ? this._customFormValue : this._currentFormValue) : (control.isCustom ? this._existingCustomFormValue : this._existingFormValue);
    switch (control.dataType) {
      case ControlDataType.StringType:
      case ControlDataType.MoneyType:
      case ControlDataType.IntegerType:
      case ControlDataType.DecimalType:
      case ControlDataType.DoubleType:
      case ControlDataType.MemoType:
        if (currentValue && currentValue[control.attributeName]) {
          if (isNaN(currentValue[control.attributeName])) {
            flag = currentValue[control.attributeName];
          } else {
            flag = true;
          }
        } else {
          flag = false;
        }
        break;
      case ControlDataType.LookupType:
        let objName = control.isCustomAttribute ? control.schemaName : control.attributeName;
        if (currentValue && currentValue.hasOwnProperty('lookupfields')) {
          let idx = currentValue['lookupfields'].findIndex(a => a.name == objName);
          flag = idx >= 0;
        } else flag = false;
        break
      case ControlDataType.BooleanType:
        flag = currentValue && currentValue.hasOwnProperty(control.attributeName);
        break;
      case ControlDataType.MultiSelectPicklistType:
      case ControlDataType.PicklistType:
        if (currentValue && currentValue[control.attributeName]) {
          if (isNaN(currentValue[control.attributeName])) {
            flag = currentValue[control.attributeName];
          } else {
            flag = true;
          }
        } else {
          flag = false;
        }
        break;
      case ControlDataType.DateTimeType:
        if (currentValue && currentValue[control.attributeName]) {
          if (isNaN(currentValue[control.attributeName])) {
            flag = currentValue[control.attributeName];
          } else {
            flag = true;
          }
        } else {
          flag = false;
        }
        break;
      default:
        break;
    }
    return flag;
  }

  private _checkLinkedEntityControlValidation(control: Control): boolean {
    let flag = true;
    if (!this.changeRequest && this._mode == 'EDIT_MODE' && this.editConfig == EditConfig.ADD_NEW
      && control.linkedEntityConfig && control.linkedEntityConfig.isRequired && control.linkedEntityConfig.minNumber) {
      // * This is for checking the total number of linked Entity Entered *
      let numOfLinkedEntity = 0;
      if(this.dynamicFormsService.numOfLinkedEntity && this.dynamicFormsService.numOfLinkedEntity[control.lookupEntitySetName]){
        numOfLinkedEntity = this.dynamicFormsService.numOfLinkedEntity[control.lookupEntitySetName];
      }
      if (this._mode == 'EDIT_MODE' && control.linkedEntityConfig && numOfLinkedEntity > 0) {
        if(this.linkedEntityValues && this.linkedEntityValues[control.attributeName]){
          // If you change the Max number to less than the stored data in Dynamics, Save button will not be activated.
          flag = (numOfLinkedEntity + this.linkedEntityValues[control.attributeName].length < control.linkedEntityConfig.minNumber) ? false : true;
        } else {
          flag = (numOfLinkedEntity < control.linkedEntityConfig.minNumber) ? false : true;
        }
      } else if (control.linkedEntityConfig && control.linkedEntityConfig.minNumber) {
        if (this.linkedEntityValues && this.linkedEntityValues[control.attributeName]) {
          flag = this.linkedEntityValues[control.attributeName].length >= control.linkedEntityConfig.minNumber ? true : false;
        } else {
          flag = false;
        }
      }
      return flag;
    } else {
      // * checking the number of linked entity per request
      if (control.linkedEntityConfig && control.linkedEntityConfig.isRequired && control.linkedEntityConfig.minNumber) {
        if (this.linkedEntityValues && this.linkedEntityValues[control.attributeName]) {
          flag = this.linkedEntityValues[control.attributeName].length >= control.linkedEntityConfig.minNumber ? true : false;
        } else {
          flag = false;
        }
      }
      return flag;
    }
  }

  private _getSectionHeaderViewForTab(tab: FormTabs, clearEnabled: boolean = false) {
    if (tab.displayNames && tab.displayNames.length > 0) {
      try {
        let headerLabel: string = this.localizationService.getTranslatedString(this._getDisplayText(tab.displayNames));
        return {
          id: headerLabel+'_section_header',
          type: 'section-header',
          view: {
            id: headerLabel,
            title: headerLabel,
            controls: clearEnabled && !this.dynamicFormsService.isSelectedDataFromSearch ? [
              {
                id: headerLabel + '_' + 'CLEAR',
                text: this.translate.instant('CLEAR'),

              },{
                id: `${headerLabel}-collapsible`,
                icon: 'assets/imgs/up-arrow.svg',
                isDisabled: false
              }
            ] : [
              {
                id: `${headerLabel}-collapsible`,
                icon: 'assets/imgs/up-arrow.svg',
                isDisabled: false
              }
            ],
          },
        };
      } catch (error) {
        console.log('Error parsing section header view from tab' + error);
      }
    }
  }

  private _getViewForControl(control: Control, tab?: FormTabs, isFirstInit?: boolean) {
    if (control.dataType) {
      if (this.dynamicFormsService.checkIfControlShouldNotBeVisible(control) || control.forceHide) {
        if (this.MODE == 'CREATE_MODE') {
          return null;
        } else if (this.MODE == 'EDIT_MODE') {
          if (control.dataType == ControlDataType.StateType || control.dataType == ControlDataType.StatusType) {
            if (!control.isVisible) return null
          } else return null;
        }
      }
      if (this.changeRequest) {
        if (control.lookupEntityPrimaryId == "indskr_lu_postalcodeid" || control.lookupEntityPrimaryId == "indskr_lu_districtid") {
          control.dependsOn = 'indskr_City_lu';
        }
      }
      const isBirthDateField: boolean = (control.attributeName == 'omnione_yearofbirth' || control.attributeName == 'omnione_dayandmonthofbirth');
      let displayText:DisplayText[] = tab && tab.displayNames ? tab.displayNames : this._getTargetTabDisplayNamesByControl(control.attributeName);
      let headerLabel: string = this.localizationService.getTranslatedString(this._getDisplayText(displayText));
      let sectionId: string = headerLabel+'_section_header';
      try {
        if (control.dataType != ControlDataType.DateTimeType || (control.dataType == ControlDataType.DateTimeType && isBirthDateField)) {
          if(this.MODE == 'CREATE_MODE' && isFirstInit && control.defaultValue != null && control.defaultValue != undefined && !this.formDetail.isEditFormWithLinkedData) this._setDefaultValue(control);
          let labelText: string = this._getDisplayText(control.displayNames, control.isCustom, control.attributeName);
          let placeholderText: string = this._getDisplayText(control.descriptions, control.isCustom);
          let fieldView: IndFormFieldViewDataModel = {
            label: labelText,
            inputText: this._getInputTextForFormField(control),
            inputValue: this._getInputTextForFormField(control),
            maxLength: this.dynamicFormsService.getFormFieldType(control) == FormFieldType.INLINE_INPUT ? control.maxLength : 100,
            id: control.attributeName,
            isReadOnly: (control.isReadOnly || this.dynamicFormsService.getFormFieldType(control) != FormFieldType.INLINE_INPUT),
            isDisabled: this._isControlDisabled(control),
            showArrow: this._isShowArrow(control),
            inputType: (control.dataType == ControlDataType.DecimalType || control.dataType == ControlDataType.DoubleType || control.dataType == ControlDataType.IntegerType || control.dataType == ControlDataType.MoneyType) ? 'number' : 'text',
            formFieldType: this.dynamicFormsService.getFormFieldType(control),
            isRequired: control.isRequired,
            isInvalid: false,
            isEmptyRequiredField: control.isRequired ? this._isClicked && this._isClicked[control.attributeName] && !this._getInputTextForFormField(control) : false,
            errorMessage: control.isRequired ? this.translate.instant('THIS_IS_A_REQUIRED_FIELD') : undefined,
            inputValidator:(id: string,value: any, event) => control.isRequired ? this._validateMandatoryField(id, value, event, false) : true,
            eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id, event, eventName),
          };
          if(placeholderText && placeholderText.trim() != ''){
            fieldView.customPlaceholderLabel = placeholderText;
          }else{
            fieldView.placeholderLabel = labelText;
          }
          return {
            id: fieldView.id,
            type: 'form-field',
            view: fieldView,
            control: control,
            section: sectionId
          };
        } else if (control.dataType == ControlDataType.DateTimeType) {
            let dateFieldView: IndDateTimeFormViewDataModel;
            let timeFieldView: IndDateTimeFormViewDataModel;
            let isStartDateTimeFieldType: boolean = true;
            isStartDateTimeFieldType = control.attributeName != 'indskr_enddate' ? true : false;
            let labelText: string = this._getDisplayText(control.displayNames, control.isCustom);
            // DateOnly Field
            if (control.dateFormat == DateFormat.DateOnly) {
              this._dateTimePickerActiveControl = control;
              let dateFieldValue: string ='';
              const getDateTimeValue = this._getInputTextForFormField(control);
              if (!_.isEmpty(getDateTimeValue)) {
                const formattedDateTimeValue = getDateTimeValue ? moment(getDateTimeValue).format() : '';
                if (!_.isEmpty(formattedDateTimeValue) && formattedDateTimeValue != 'Invalid date') {
                  let currentDateTimeValue = new Date(formattedDateTimeValue);
                  dateFieldValue = this.datePipe.transform(currentDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
                }
              }
              dateFieldView = {
                isDateField: true,
                label: labelText,
                customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
                inputText: dateFieldValue,
                inputValue: dateFieldValue,
                fromViewPage: CurViewPageType.ConfiguredForm,
                id: isStartDateTimeFieldType ? DateTimeFieldType.StartDateField : DateTimeFieldType.EndDateField,
                controlId: control.attributeName,
                isReadOnly: true,
                isDisabled: this._isControlDisabled(control),
                showArrow: this._isShowArrow(control),
                isRequired: control.isRequired,
                isInvalid: false,
                isEmptyRequiredField: control.isRequired ? this._isClicked && this._isClicked[control.attributeName] && _.isEmpty(this._getInputTextForFormField(control)) : false,
                errorMessage: control.isRequired ? this.translate.instant('THIS_IS_A_REQUIRED_FIELD') : undefined,
                inputValidator:(id: string,value: any, event) => control.isRequired ? this._validateMandatoryField(control.attributeName, value, event, false) : true,
                eventHandler: (id: string, event, eventName, dataRef) => this._handleFormFieldEvent(control.attributeName, event, eventName, dataRef),
              };
              return {
                id: dateFieldView.id,
                type: 'form-field',
                view: dateFieldView,
                control: control,
                section: sectionId
              };
            // Date and Time Field
            } else if (control.dateFormat == DateFormat.DateAndTime) {
              this._dateTimePickerActiveControl = control;
              let dateFieldValue: string = '';
              let timeFieldValue: string = '';
              const getDateTimeValue = this._getInputTextForFormField(control);
              if (!_.isEmpty(getDateTimeValue)) {
                const formattedDateTimeValue = getDateTimeValue ? moment(getDateTimeValue).format() : '';
                if (!_.isEmpty(formattedDateTimeValue) && formattedDateTimeValue != 'Invalid date') {
                  let currentDateTimeValue = new Date(formattedDateTimeValue);
                  dateFieldValue = this.datePipe.transform(currentDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
                  timeFieldValue = currentDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
                  if (!this.dateTimeFormatsService.is12HourFormat && timeFieldValue.includes("24:")) {
                    timeFieldValue = timeFieldValue.replace("24:", "00:");
                  }
                }
              }
              let getSecondaryDateTimeValue: string = '';
              const secondaryTargetControl: Control = isStartDateTimeFieldType ? this._getTargetControl('indskr_enddate') : this._getTargetControl('indskr_startdate');
              if (secondaryTargetControl) {
                getSecondaryDateTimeValue = this._getInputTextForFormField(secondaryTargetControl);
              }
              const dateTextTrans = this.translate.instant('DATE');
              let labelDateText: string = labelText;
              if (labelText.includes('Date') || labelText.includes('date') || labelText.includes(dateTextTrans) || labelText.includes(dateTextTrans.toLowerCase())) {
                labelDateText = labelText;
              } else {
                labelDateText = labelDateText + ' ' + dateTextTrans;
              }
              dateFieldView = {
                isDateField: true,
                label: labelDateText,
                customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
                inputText: dateFieldValue,
                inputValue: dateFieldValue,
                startDateTimeValue: isStartDateTimeFieldType ? getDateTimeValue : getSecondaryDateTimeValue ? getSecondaryDateTimeValue : getDateTimeValue,
                endDateTimeValue: isStartDateTimeFieldType ? getSecondaryDateTimeValue ? getSecondaryDateTimeValue : getDateTimeValue : getDateTimeValue,
                fromViewPage: CurViewPageType.ConfiguredForm,
                id: isStartDateTimeFieldType ? DateTimeFieldType.StartDateField : DateTimeFieldType.EndDateField,
                controlId: control.attributeName,
                isReadOnly: true,
                isDisabled: this._isControlDisabled(control),
                showArrow: this._isShowArrow(control),
                isRequired: control.isRequired,
                isInvalid: false,
                isEmptyRequiredField: control.isRequired ? this._isClicked && this._isClicked[control.attributeName] && _.isEmpty(this._getInputTextForFormField(control)) : false,
                errorMessage: control.isRequired ? this.translate.instant('THIS_IS_A_REQUIRED_FIELD') : undefined,
                inputValidator:(id: string,value: any, event) => control.isRequired ? this._validateMandatoryField(control.attributeName, value, event, false) : true,
                eventHandler: (id: string, event, eventName, dataRef) => this._handleFormFieldEvent(control.attributeName, event, eventName, dataRef),
              };
              if(this._mode == 'EDIT_MODE' && this.formDetail?.formMetadata?.entityName == 'indskr_setbooking'
                && this.formDetail.formEntityObject?.indskr_status == SetBookingStatus.COMPLETED
                && (control.attributeName =="indskr_startdate" || control.attributeName =="indskr_enddate"))
                dateFieldView.isDisabled = true;
              const timeTextTrans = this.translate.instant('TIME');
              let labelTimeText: string = labelText;
              if (labelText.includes('Date')) {
                labelTimeText = labelText.replace('Date', 'Time');
              } else if (labelText.includes('date')) {
                labelTimeText = labelText.replace('date', 'time');
              } else if (labelText.includes(dateTextTrans)) {
                labelTimeText = labelText.replace(dateTextTrans, timeTextTrans);
              } else if (labelText.includes(dateTextTrans.toLowerCase())) {
                labelTimeText = labelText.replace(dateTextTrans.toLowerCase(), timeTextTrans.toLowerCase());
              } else {
                labelTimeText = labelTimeText + ' ' + timeTextTrans;
              }
              timeFieldView = {
                isTimeField: true,
                label: labelTimeText,
                customPlaceholderLabel: this.translate.instant('SELECT_TIME'),
                inputText: timeFieldValue,
                inputValue: timeFieldValue,
                startDateTimeValue: isStartDateTimeFieldType ? getDateTimeValue : getSecondaryDateTimeValue ? getSecondaryDateTimeValue : getDateTimeValue,
                endDateTimeValue: isStartDateTimeFieldType ? getSecondaryDateTimeValue ? getSecondaryDateTimeValue : getDateTimeValue : getDateTimeValue,
                fromViewPage: CurViewPageType.ConfiguredForm,
                id: isStartDateTimeFieldType ? DateTimeFieldType.StartTimeField : DateTimeFieldType.EndTimeField,
                controlId: control.attributeName,
                isReadOnly: true,
                isDisabled: dateFieldView.inputText == '' || this._isControlDisabled(control),
                showArrow: dateFieldView.inputText != '' && this._isShowArrow(control),
                isRequired: control.isRequired,
                isInvalid: false,
                isEmptyRequiredField: control.isRequired ? this._isClicked && this._isClicked[control.attributeName] && _.isEmpty(this._getInputTextForFormField(control)) : false,
                errorMessage: control.isRequired ? this.translate.instant('THIS_IS_A_REQUIRED_FIELD') : undefined,
                inputValidator:(id: string,value: any, event) => control.isRequired ? this._validateMandatoryField(control.attributeName, value, event, false) : true,
              };
              return {
                id: dateFieldView.id,
                type: 'form-field',
                view: dateFieldView,
                timeView: timeFieldView,
                control: control,
                section: sectionId
              };
            }
        }
      } catch (error) {
        console.log('Error occured while parsing controls view' + error);
      }
    } else {// Related entity field
      try {
        if (control.forceHide === true) {
          return null;
        } else {
          this.dynamicFormsService.getCustomDisplayNameFromField(control, this.formDetail.dynamicFormType);
          let headerLabel: string = this.localizationService.getTranslatedString(this._getDisplayText(control.displayNames));
          let isMaxNumReached = this._isMaxNumOfLinkedEntityReached(control);
          let isSectionButtonsDisabled = this._mode == 'EDIT_MODE' && 
            this.formDetail?.formMetadata?.entityName == 'indskr_setbooking' &&
            this.formDetail.formEntityObject?.indskr_status == SetBookingStatus.COMPLETED &&
            control.lookupEntitySetName =="indskr_setbookingproducts";
          let buttons = [];
          if(control.linkedEntityConfig && control.linkedEntityConfig.abilityToRemoveRecords == '548910000') {
            buttons.push({
              id: `${control.attributeName}_SCRAP`,
              text: this.translate.instant('SCRAP'),
              isRequired: false,
              isDisabled: this.disableForm || (this.linkedEntityValues && this.linkedEntityValues[control.attributeName] && this.linkedEntityValues[control.attributeName].filter(x=> x.isCheckedForScrap)?.length == 0)
                          || _.isEmpty(this.linkedEntityValues) || (this.linkedEntityValues && _.isEmpty(this.linkedEntityValues[control.attributeName]))
                          || isSectionButtonsDisabled
            })
          }
          buttons.push({
              id: control.attributeName + '_' + 'ADD',
              text: (this.editConfig == EditConfig.ADD_NEW || this.editConfig == EditConfig.BOTH) ? this.translate.instant('ADD') : '',
              isRequired: control.isRequired,
              isDisabled: this.disableForm || ((!this._isFormSaved) && !this.changeRequest) || isMaxNumReached || isSectionButtonsDisabled
                          || (this.linkedEntityValues && this.linkedEntityValues[control.attributeName] && this.linkedEntityValues[control.attributeName].filter(x=> x.isCheckedForScrap)?.length > 0)
            },
            {
              id: `${control.attributeName}-collapsible`,
              icon: 'assets/imgs/up-arrow.svg',
              isDisabled: false
            }
          )
          let view = {
            id: headerLabel,
            type: 'section-header',
            view: {
              id: headerLabel,
              title: headerLabel,
              isRequired: control.isRequired || control.linkedEntityConfig ? control.linkedEntityConfig['isRequired'] ? control.linkedEntityConfig['isRequired'] : control.linkedEntityConfig['minNumber'] > 0 ? true : false : false,
              controls: buttons
            },
            data: control.attributeName,
          };
          return view;
        }
      } catch (error) {
        console.log('Error occured while related entity field view' + error);
      }
    }
  }

  private _isMaxNumOfLinkedEntityReached(control: Control) {
    if (!this.changeRequest && this._mode == 'EDIT_MODE' && this.editConfig == EditConfig.ADD_NEW) {
      // *checking if the TOTAL number of linked entity entered reaches to the max limitations
      let numOfLinkedEntity = 0;
      if(this.dynamicFormsService.numOfLinkedEntity && this.dynamicFormsService.numOfLinkedEntity[control.lookupEntitySetName]){
        numOfLinkedEntity = this.dynamicFormsService.numOfLinkedEntity[control.lookupEntitySetName];
      }
      if (this._mode == 'EDIT_MODE' && control.linkedEntityConfig && numOfLinkedEntity > 0) {
        if(this.linkedEntityValues && this.linkedEntityValues[control.attributeName]){
          return numOfLinkedEntity + this.linkedEntityValues[control.attributeName].length >= control.linkedEntityConfig.maxNumber ? true : false;
        } else {
          return numOfLinkedEntity >= control.linkedEntityConfig.maxNumber ? true : false;
        }
      } else if (control.linkedEntityConfig && this.linkedEntityValues && this.linkedEntityValues[control.attributeName]) {
        return this.linkedEntityValues[control.attributeName].length >= control.linkedEntityConfig.maxNumber ? true : false;
      } else {
        return false;
      }
    } else {
      // *checking the number of linked entity entered Per REQUEST reaches to the max limitation
      if (control.linkedEntityConfig && this.linkedEntityValues && this.linkedEntityValues[control.attributeName]) {
        if (this.changeRequest && this.isAutoPopulatedLinkedDataFlow){
          return this.linkedEntityValues[control.attributeName].length > control.linkedEntityConfig.maxNumber ? true : false;
        } else {
          return this.linkedEntityValues[control.attributeName].length >= control.linkedEntityConfig.maxNumber ? true : false;
        }
      } else {
        return false;
      }
    }
  }
  // getView for address edit form - Account CR
  private _getViewAddressEditFormForControl(control: Control) {
    if (control.dataType) {
      if (this.dynamicFormsService.checkIfControlShouldNotBeVisible(control) || control.forceHide) {
        return null;
      }
      if (this.changeRequest && (control.lookupEntityPrimaryId == "indskr_lu_postalcodeid" || control.lookupEntityPrimaryId == "indskr_lu_districtid")) {
        control.dependsOn = 'indskr_City_lu';
      }
      try {
        // this.dynamicFormsService.customizeLabelRequestResponseComments(control);
        let labelText: string = this._getDisplayText(control.displayNames, control.isCustom);
        let placeholderText: string = this._getDisplayText(control.descriptions, control.isCustom);
        let fieldView: IndFormFieldViewDataModel = {
          label: labelText,
          inputText: this._getInputTextForAddressEditFormField(control),
          inputValue: this._getInputTextForAddressEditFormField(control),
          maxLength: this.dynamicFormsService.getFormFieldType(control) == FormFieldType.INLINE_INPUT ? control.maxLength : 100,
          id: control.attributeName,
          isReadOnly: (control.isReadOnly || this.dynamicFormsService.getFormFieldType(control) != FormFieldType.INLINE_INPUT),
          isDisabled: this._isControlDisabled(control),
          showArrow: this._isShowArrow(control),
          inputType: (control.dataType == ControlDataType.DecimalType || control.dataType == ControlDataType.DoubleType || control.dataType == ControlDataType.IntegerType || control.dataType == ControlDataType.MoneyType) ? 'number' : 'text',
          formFieldType: this.dynamicFormsService.getFormFieldType(control),
          isRequired: control.isRequired,
          isInvalid: false,
          inputValidator:(id: string,value: any, event) => control.isRequired ? this._validateMandatoryField(id, value, event, true) : true,
          eventHandler: (id: string, event, eventName) => this._handleEditAddressFormFieldEvent(id, event, eventName),
        };
        if (placeholderText && placeholderText.trim() != '') {
          fieldView.customPlaceholderLabel = placeholderText;
        } else {
          fieldView.placeholderLabel = labelText;
        }
        return {
          id: fieldView.id,
          type: 'form-field',
          view: fieldView,
          control: control
        };
      } catch (error) {
        console.log('Error occured while parsing controls view' + error);
      }
    } else {// Related entity field
      try {
        if (control.forceHide === true) {
          return null;
        } else {
          let headerLabel: string = this.localizationService.getTranslatedString(this._getDisplayText(control.displayNames));
          let isMaxNumReached = this._isMaxNumOfLinkedEntityReached(control);
          let view = {
            id: headerLabel,
            type: 'section-header',
            view: {
              id: headerLabel,
              title: headerLabel,
              isRequired: control.isRequired || control.linkedEntityConfig ? control.linkedEntityConfig['isRequired'] ? control.linkedEntityConfig['isRequired'] : control.linkedEntityConfig['minNumber'] > 0 ? true : false : false,
              controls: [
                {
                  id: control.attributeName + '_' + 'ADD',
                  text: this.translate.instant('ADD'),
                  isDisabled: this.disableForm || ((!this._isFormSaved) && !this.changeRequest) || isMaxNumReached
                },{
                  id: `${control.attributeName}-collapsible`,
                  icon: 'assets/imgs/up-arrow.svg',
                  isDisabled: false
                }
              ],
            },
            data: control.attributeName,
          };
          return view;
        }
      } catch (error) {
        console.log('Error occured while related entity field view' + error);
      }
    }
  }

  private _getDisplayText(dtArray: DisplayText[], isCustomControl: boolean = false, attributeName: string = '') {
    if (!dtArray) return '';
    let displayText: string;
    //-------Custom label--hard coded start
    //statuscode
    if (this.businessLine == -1) {
      if (attributeName && attributeName == 'statuscode' && this.formDetail && this.formDetail.formMetadata
      && (this.formDetail.formMetadata.entityName == 'account' || this.formDetail.formMetadata.entityName == 'contact'
        || this.formDetail.formMetadata.entityName == 'indskr_accountcr' || this.formDetail.formMetadata.entityName == 'indskr_contactcr')) {
        return displayText = this.translate.instant('VERIFICATION');
      }
    }
    const refEntityName = this.formDetail.formMetadata.entityName;
    const affiliationsRefNames = [
      ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY, CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY,
      ACCOUNT_ACCOUNT_AFFILIATIONS_CR_REF_ENTITY, CONTACT_CONTACT_AFFILIATIONS_CR_REF_ENTITY,
    ];
    const isAffiliationsData = affiliationsRefNames.includes(refEntityName);
    if (isAffiliationsData && attributeName) {
      //A-A-Affiliation in account form
      if (refEntityName == ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY) {
        if (attributeName == ACCOUNT_ACCOUNT_FROM_FILTER_ATTRIBUTE_NAME && !_.isEmpty(this.dynamicFormsService.accountAffiliatedToLabelText)) {
          return displayText = this.dynamicFormsService.accountAffiliatedToLabelText;
        } else if (attributeName == ACCOUNT_ACCOUNT_TO_FILTER_ATTRIBUTE_NAME && !_.isEmpty(this.dynamicFormsService.accountAffiliatedFromLabelText)) {
          return displayText = this.dynamicFormsService.accountAffiliatedFromLabelText;
        }
      }
      //C-C-Affiliation in contact form
      else if (refEntityName == CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY) {
        if (attributeName == CONTACT_CONTACT_FROM_FILTER_ATTRIBUTE_NAME && !_.isEmpty(this.dynamicFormsService.contactAffiliatedToLabelText)) {
          return displayText = this.dynamicFormsService.contactAffiliatedToLabelText;
        } else if (attributeName == CONTACT_CONTACT_TO_FILTER_ATTRIBUTE_NAME && !_.isEmpty(this.dynamicFormsService.contactAffiliatedFromLabelText)) {
          return displayText = this.dynamicFormsService.contactAffiliatedFromLabelText;
        }
      }
      //A-A-Affiliation in account CR form
      else if (refEntityName == ACCOUNT_ACCOUNT_AFFILIATIONS_CR_REF_ENTITY) {
        if (attributeName == ACCOUNT_ACCOUNT_FROM_CR_ATTRIBUTE_NAME && !_.isEmpty(this.dynamicFormsService.accountCrAffiliatedToLabelText)) {
          return displayText = this.dynamicFormsService.accountCrAffiliatedToLabelText;
        } else if (attributeName == ACCOUNT_ACCOUNT_TO_CR_ATTRIBUTE_NAME && !_.isEmpty(this.dynamicFormsService.accountCrAffiliatedFromLabelText)) {
          return displayText = this.dynamicFormsService.accountCrAffiliatedFromLabelText;
        }
      }
      //C-C-Affiliation in contact CR form
      else if (refEntityName == CONTACT_CONTACT_AFFILIATIONS_CR_REF_ENTITY) {
        if (attributeName == CONTACT_CONTACT_FROM_CR_ATTRIBUTE_NAME && !_.isEmpty(this.dynamicFormsService.contactCrAffiliatedToLabelText)) {
          return displayText = this.dynamicFormsService.contactCrAffiliatedToLabelText;
        } else if (attributeName == CONTACT_CONTACT_TO_CR_ATTRIBUTE_NAME && !_.isEmpty(this.dynamicFormsService.contactCrAffiliatedFromLabelText)) {
          return displayText = this.dynamicFormsService.contactCrAffiliatedFromLabelText;
        }
      }
    }
    //-------hard coded end

    const langCode = (this.formDetail.dynamicFormType == DynamicFormType.DEFAULTFORM) ? DEFAULT_FORM_LANGUAGE_CODE : this._activeLanguageCode;
    const dt = dtArray.find(value => value.languagecode == langCode);

    if (dt) {
      displayText = dt.languagecode == DEFAULT_FORM_LANGUAGE_CODE ? this.translate.instant(dt.description) : dt.description;
    } else {
      let en = dtArray.find(value => value.languagecode == '1033'); // default to english if no translation for the specific language;
      if (en) {
        displayText = en.description
      } else {
        //recheck the default form language code in the case of custom fields
        const defalutLang = dtArray.find(value => value.languagecode == '0000');
        if(defalutLang && defalutLang.description) {
          displayText = this.translate.instant(defalutLang.description);
        } else {
          displayText = '';
        }
      }
    }
    return displayText;
  }

  private _getInputTextForFormField(control: Control): string {
    if (control && control.dataType) {
      switch (control.dataType) {
        case ControlDataType.StringType:
        case ControlDataType.MoneyType:
        case ControlDataType.IntegerType:
        case ControlDataType.DecimalType:
        case ControlDataType.DoubleType:
        case ControlDataType.MemoType:
          if (control.isCustom) {
            return (this._customFormValue && this._customFormValue[control.attributeName]) ? this._customFormValue[control.attributeName] : '';
          } else {
            return (this._currentFormValue && this._currentFormValue[control.attributeName]) ? this._currentFormValue[control.attributeName] : '';
          }
        case ControlDataType.LookupType:
          return (this._currentFormValue && this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) ? this._currentFormStringValues[control.attributeName] : '';
        case ControlDataType.BooleanType:
          return (this._currentFormValue && this._currentFormValue.hasOwnProperty(control.attributeName) && this._currentFormValue[control.attributeName] != null) ? (this._currentFormValue[control.attributeName] ? this.translate.instant('YES') : this.translate.instant('NO')) : '';
        case ControlDataType.StateType:
          return (this._currentFormValue && (this._currentFormValue[control.attributeName] || this._currentFormValue[control.attributeName]==0) && this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) ? this._currentFormStringValues[control.attributeName] : '';
        case ControlDataType.StatusType:
        case ControlDataType.MultiSelectPicklistType:
        case ControlDataType.PicklistType:
          if (control.isCustom) {
            return (this._customFormValue && this._customFormValue[control.attributeName] && this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) ? this._currentFormStringValues[control.attributeName] : '';
          }
          else{
            return (this._currentFormValue && this._currentFormValue[control.attributeName] && this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) ? this._currentFormStringValues[control.attributeName] : '';
          }
        case ControlDataType.DateTimeType:
          if (control.attributeName =='omnione_yearofbirth') {
            if ((this._currentFormValue && this._currentFormValue[control.attributeName] && this._currentFormStringValues && this._currentFormStringValues[control.attributeName])) {
              const currentValueUTC = moment(this._currentFormStringValues[control.attributeName]).utc().format();
              const formattedDate = (new Date(currentValueUTC).toISOString());
              const currentDateAndTimeValue = new Date(formattedDate);
              const yearOfBirth = format(currentDateAndTimeValue, 'YYYY');
              return yearOfBirth ? yearOfBirth : '';
            }
          } else if(control.attributeName =='omnione_dayandmonthofbirth') {
            if ((this._currentFormValue && this._currentFormValue[control.attributeName] && this._currentFormStringValues && this._currentFormStringValues[control.attributeName])) {
              const currentValueUTC = moment(this._currentFormStringValues[control.attributeName]).utc().format();
              const formattedDate = (new Date(currentValueUTC).toISOString());
              const currentDateAndTimeValue = new Date(formattedDate);
              const getYearValue = currentDateAndTimeValue.getFullYear().toString();
              const getTranslateDate = this.datePipe.transform(currentDateAndTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
              const regExpFindYear = new RegExp('(\/?\-?\,?\s?' + getYearValue + '?\,?\s?\-?\/?)', 'gi');
              let dayAndMonthOnly = getTranslateDate.replace(regExpFindYear, '');
              dayAndMonthOnly = dayAndMonthOnly.replace(',', '');
              return dayAndMonthOnly ? dayAndMonthOnly : '';
            }
          } else {
            if (this._currentFormValue && this._currentFormValue[control.attributeName] && this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) {
              let currentValue = this._currentFormStringValues[control.attributeName];
              const currentValueUTC = moment(currentValue).utc().format();
              const formattedDate = (new Date(currentValueUTC).toISOString());
              return formattedDate;
            } else return '';
          }
        default:
          return '';
      }
    } else {
      return '';
    }
  }
  // getInputText for address edit form - Account CR
  private _getInputTextForAddressEditFormField(control: Control): string {
    if (control && control.dataType) {
      switch (control.dataType) {
        case ControlDataType.StringType:
        case ControlDataType.MoneyType:
        case ControlDataType.IntegerType:
        case ControlDataType.DecimalType:
        case ControlDataType.DoubleType:
        case ControlDataType.MemoType:
          if (control.isCustom) {
            return (this._existingCustomFormValue && this._existingCustomFormValue[control.attributeName]) ? this._existingCustomFormValue[control.attributeName] : '';
          } else {
            return (this._existingFormValue && this._existingFormValue[control.attributeName]) ? this._existingFormValue[control.attributeName] : '';
          }
        case ControlDataType.LookupType:
          return (this._existingFormValue && this._existingFormStringValues && this._existingFormStringValues[control.attributeName]) ? this._existingFormStringValues[control.attributeName] : '';
        case ControlDataType.BooleanType:
          return (this._existingFormValue && this._existingFormValue.hasOwnProperty(control.attributeName) && this._existingFormValue[control.attributeName] != null) ? (this._existingFormValue[control.attributeName] ? this.translate.instant('YES') : this.translate.instant('No')) : '';
        case ControlDataType.MultiSelectPicklistType:
        case ControlDataType.PicklistType:
          return (this._existingFormValue && this._existingFormValue[control.attributeName] && this._existingFormStringValues && this._existingFormStringValues[control.attributeName]) ? this._existingFormStringValues[control.attributeName] : '';
        case ControlDataType.DateTimeType:
          return (this._existingFormValue && this._existingFormValue[control.attributeName] && this._existingFormStringValues && this._existingFormStringValues[control.attributeName]) ? this._existingFormStringValues[control.attributeName] : '';
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  private _isControlDisabled(control: Control) {
    if(this.disableForm)return true;
    if(this.dynamicFormsService.isSelectedDataFromAddressOneKey && this.selectedSearchOption && this.selectedSearchOption[control.attributeName]) return true;
    if(control.dataType == ControlDataType.StateType || control.dataType == ControlDataType.StatusType) return true;
    if (control.isCustom) {
      if (this.isSelectedFromSearch) {
        if(this.selectedSearchOption && this.selectedSearchOption[control.attributeName]) return true;
        else if(!this.changeRequest) return true;
      }
      if (control.dependsOn) {
        if (!this.isHandleEditAddressFormField) {
          if (!(this._customFormValue && this._customFormValue['lookupfields'])) {
            return true;
          } else {
            let isDepndsOnSet = this._customFormValue['lookupfields'].find(x => x.name == control.dependsOn);
            return (control.isReadOnly || !isDepndsOnSet) || (control.dataType == ControlDataType.LookupType && this.contactService.disableEditForVeevaContactOnly());
          }
        } else {
          if (!(this._existingCustomFormValue && this._existingCustomFormValue['lookupfields'])) {
            return true;
          } else {
            let isDepndsOnSet = this._existingCustomFormValue['lookupfields'].find(x => x.name == control.dependsOn);
            return (control.isReadOnly || !isDepndsOnSet) || (control.dataType == ControlDataType.LookupType && this.contactService.disableEditForVeevaContactOnly());
          }
        }
      }
    }
    return control.isReadOnly || (control.dataType == ControlDataType.LookupType && this.contactService.disableEditForVeevaContactOnly());
  }

  private _isShowArrow(control: Control) {
    if(this.disableForm) return false;
    if(control.dataType == ControlDataType.StateType || control.dataType == ControlDataType.StatusType) return false;
    if(this.dynamicFormsService.isSelectedDataFromAddressOneKey && this.selectedSearchOption && this.selectedSearchOption[control.attributeName]) return false;
    if (control.isCustom) {
      if (this.isSelectedFromSearch) {
        if(this.selectedSearchOption && this.selectedSearchOption[control.attributeName]) return false;
        else if(!this.changeRequest) return false;
      }
      if (control.dependsOn) {
        if (!this.isHandleEditAddressFormField) {
          if (!(this._customFormValue && this._customFormValue['lookupfields'])) {
            return false;
          } else {
            let isDepndsOnSet = this._customFormValue['lookupfields'].find(x => x.name == control.dependsOn);
            return (!control.isReadOnly && isDepndsOnSet);
          }
        } else {
          if (!(this._existingCustomFormValue && this._existingCustomFormValue['lookupfields'])) {
            return false;
          } else {
            let isDepndsOnSet = this._existingCustomFormValue['lookupfields'].find(x => x.name == control.dependsOn);
            return (!control.isReadOnly && isDepndsOnSet);
          }
        }
      }
    }
    return !control.isReadOnly || (control.dataType == ControlDataType.LookupType);
  }

  public async onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
      case 'cancel':
        if (this.formDetail && this.formDetail.eventsHandler) {
          let data = {
            formValue: this._currentFormValue,
            formStringValue: this._currentFormStringValues,
            customFormValue: this._customFormValue,
            eventsHandler: (data: any, eventName: string) => this.callbackFromParentComponent(data, eventName),
            displayConfirmation: this._isFormDirty || this.dynamicFormsService.linkedEntityFormIsDirtyTracker,
          }
          if(this.layoverViewPushedFrom)
            data["layoverViewPushedFrom"] = this.layoverViewPushedFrom;
          this.formDetail.eventsHandler(data, 'CANCELBUTTONCLICK', this.formDetail);
        }
        break;
      case 'scrap':
        if (this.formDetail && this.formDetail.eventsHandler) {
          let data = {
            formValue: this._currentFormValue,
            formStringValue: this._currentFormStringValues,
            customFormValue: this._customFormValue,
            eventsHandler: (data: any, eventName: string) => this.callbackFromParentComponent(data, eventName),
            displayConfirmation: this._isFormDirty || this.dynamicFormsService.linkedEntityFormIsDirtyTracker,
          }
          this.formDetail.eventsHandler(data, 'SCARPBUTTONCLICK', this.formDetail);
        }
        break;
      case 'done':
        if (this._mode == 'EDIT_MODE') {
          // In case of auto-populated data flow
          if (this.changeRequest && this.isAutoPopulatedLinkedDataFlow) {
            let addressAttrName = this.dynamicFormsService.addressAttributeName || '';
            let accountAffiliationAttrName = this.dynamicFormsService.accountAffiliationAttributeName || '';
            // Send auto-populated existing data to payload if the account-contact affiliation data did not replace the existing data in Contact CR.
            if (this.hasAccountContactAffiliationForm && !this.addedNewACAffiliation && this.formDetail.LookupEntitySetName == 'indskr_contactcrs') {
              if (accountAffiliationAttrName) {
                await this.autoPopulatedLinkedData('accountContactAffiliation', true).then(async () => {
                  if (addressAttrName) {
                    await this.autoPopulatedLinkedData('contactAddress', true).then(() => {
                      if (this.formDetail && this.formDetail.eventsHandler && this._validateCurrentFormValue()) {
                        if (this._currentFormValue && !_.isEmpty(this.formDetail.formEntityObject) && this.formDetail.formEntityObject['indskr_mdm']) {
                          this._currentFormValue['indskr_mdm'] = this.formDetail.formEntityObject['indskr_mdm'];
                        }
                        this.dynamicFormsService.okCRMapper = this._currentFormStringValues;
                        let data = {
                            formValue: this._currentFormValue,
                            formStringValue: this._currentFormStringValues,
                            isFormSaved: this._isFormSaved,
                            eventsHandler: (data: any, eventName: string) => this.callbackFromParentComponent(data, eventName),
                        }
                        this.formDetail.eventsHandler(data, 'DONEBUTTONCLICK', this.formDetail);
                      }
                    });
                  } else {
                    if (this.formDetail && this.formDetail.eventsHandler && this._validateCurrentFormValue()) {
                      this.dynamicFormsService.okCRMapper = this._currentFormStringValues;
                      let data = {
                          formValue: this._currentFormValue,
                          formStringValue: this._currentFormStringValues,
                          isFormSaved: this._isFormSaved,
                          eventsHandler: (data: any, eventName: string) => this.callbackFromParentComponent(data, eventName),
                      }
                      this.formDetail.eventsHandler(data, 'DONEBUTTONCLICK', this.formDetail);
                    }
                  }
                });
              }
            // Send auto-populated existing data to payload if the address data did not replace the existing data in Account CR.
            } else if (this.hasAddressForm && !this.addedNewAddressForAccountCR && (this.formDetail.LookupEntitySetName == 'indskr_accountcrs' || this.formDetail.LookupEntitySetName == 'indskr_accountcr')) {
              if (addressAttrName) {
                await this.autoPopulatedLinkedData('accountAddress', true).then(() => {
                  if (this.formDetail && this.formDetail.eventsHandler && this._validateCurrentFormValue()) {
                    this.dynamicFormsService.okCRMapper = this._currentFormStringValues;
                    let data = {
                        formValue: this._currentFormValue,
                        formStringValue: this._currentFormStringValues,
                        isFormSaved: this._isFormSaved,
                        eventsHandler: (data: any, eventName: string) => this.callbackFromParentComponent(data, eventName),
                    }
                    this.formDetail.eventsHandler(data, 'DONEBUTTONCLICK', this.formDetail);
                  }
                });
              } else {
                if (this.formDetail && this.formDetail.eventsHandler && this._validateCurrentFormValue()) {
                  this.dynamicFormsService.okCRMapper = this._currentFormStringValues;
                  let data = {
                      formValue: this._currentFormValue,
                      formStringValue: this._currentFormStringValues,
                      isFormSaved: this._isFormSaved,
                      eventsHandler: (data: any, eventName: string) => this.callbackFromParentComponent(data, eventName),
                  }
                  this.formDetail.eventsHandler(data, 'DONEBUTTONCLICK', this.formDetail);
                }
              }
            } else {
              if (this.formDetail && this.formDetail.eventsHandler && this._validateCurrentFormValue()) {
                this.dynamicFormsService.okCRMapper = this._currentFormStringValues;
                let data = {
                    formValue: this._currentFormValue,
                    formStringValue: this._currentFormStringValues,
                    isFormSaved: this._isFormSaved,
                    eventsHandler: (data: any, eventName: string) => this.callbackFromParentComponent(data, eventName),
                }
                this.formDetail.eventsHandler(data, 'DONEBUTTONCLICK', this.formDetail);
              }
            }
          // non auto-populated data flow
          } else {
            if (this.formDetail && this.formDetail.eventsHandler && this._validateCurrentFormValue()) {
              if (this._currentFormValue && !_.isEmpty(this.formDetail.formEntityObject) && this.formDetail.formEntityObject['indskr_mdm']) {
                this._currentFormValue['indskr_mdm'] = this.formDetail.formEntityObject['indskr_mdm'];
              }
              this.dynamicFormsService.okCRMapper = this._currentFormStringValues;
              let formValue = _.cloneDeep(this._currentFormValue);
              if (this.TYPE == 'SANOFI_CHINA') {
                if (this.isWorkCardAdded) {
                  formValue.attachment1 = {
                    base64str: this.workCardBase64str,
                    filename: this.workCardTitle
                  }
                }
                if (this.isPracticeCertificateAdded) {
                  formValue.attachment2 = {
                    base64str: this.practiceCertificateBase64str,
                    filename: this.practiceCertificateTitle
                  }
                }
              }
              let data = {
                formValue: formValue,
                formStringValue: this._currentFormStringValues,
                isFormSaved: this._isFormSaved,
                eventsHandler: (data: any, eventName: string) => this.callbackFromParentComponent(data, eventName),
              }
              this.formDetail.eventsHandler(data, 'DONEBUTTONCLICK', this.formDetail);
            }
          }
          if (this._isFormSaved) {
            this._isFormDirty = false;
            this.initFormPageTitleAndButtons(true);
          }
        // CREATE_MODE
        } else {
          if (this.formDetail && this.formDetail.eventsHandler && this._validateCurrentFormValue()) {
            if (this._currentFormValue && !_.isEmpty(this.formDetail.formEntityObject) && this.formDetail.formEntityObject['indskr_mdm']) {
              this._currentFormValue['indskr_mdm'] = this.formDetail.formEntityObject['indskr_mdm'];
            }
            this.dynamicFormsService.okCRMapper = this._currentFormStringValues;
            let data = {
              formValue: this._currentFormValue,
              formStringValue: this._currentFormStringValues,
              customFormValue: this._customFormValue,
              isSelectedFromSearch: this.isSelectedFromSearch,
              isFormSaved: this._isFormSaved,
              eventsHandler: (data: any, eventName: string) => this.callbackFromParentComponent(data, eventName),
            }
            this.formDetail.eventsHandler(data, 'DONEBUTTONCLICK', this.formDetail);
          }
        }
        break;
      default:
        console.log('Unhandled switch case statement');
        console.log("Handling kitbooking save, submit and scrap here");
        if (this._mode == 'EDIT_MODE') {
          this._isFormDirty = false;
          this.initFormPageTitleAndButtons(true);
          if (this.formDetail && this.formDetail.eventsHandler && this._validateCurrentFormValue()) {
            if (this._currentFormValue && !_.isEmpty(this.formDetail.formEntityObject) && this.formDetail.formEntityObject['indskr_mdm']) {
              this._currentFormValue['indskr_mdm'] = this.formDetail.formEntityObject['indskr_mdm'];
            }
            this.dynamicFormsService.okCRMapper = this._currentFormStringValues;
            let formValue = _.cloneDeep(this._currentFormValue);
            if (this.TYPE == 'SANOFI_CHINA') {
              if (this.isWorkCardAdded) {
                formValue.attachment1 = {
                  base64str: this.workCardBase64str,
                  filename: this.workCardTitle
                }
              }
              if (this.isPracticeCertificateAdded) {
                formValue.attachment2 = {
                  base64str: this.practiceCertificateBase64str,
                  filename: this.practiceCertificateTitle
                }
              }
            }
            let data = {
              formValue: formValue,
              formStringValue: this._currentFormStringValues,
              isFormSaved: this._isFormSaved,
              eventsHandler: (data: any, eventName: string) => this.callbackFromParentComponent(data, eventName),
            }
            this.formDetail.eventsHandler(data, id, this.formDetail);
          }
        } else {
          if (this.formDetail && this.formDetail.eventsHandler && this._validateCurrentFormValue()) {
            if (this._currentFormValue && !_.isEmpty(this.formDetail.formEntityObject) && this.formDetail.formEntityObject['indskr_mdm']) {
              this._currentFormValue['indskr_mdm'] = this.formDetail.formEntityObject['indskr_mdm'];
            }
            this.dynamicFormsService.okCRMapper = this._currentFormStringValues;
            let data = {
              formValue: this._currentFormValue,
              formStringValue: this._currentFormStringValues,
              customFormValue: this._customFormValue,
              isSelectedFromSearch: this.isSelectedFromSearch,
              isFormSaved: this._isFormSaved,
              eventsHandler: (data: any, eventName: string) => this.callbackFromParentComponent(data, eventName),
            }
            if(this.layoverViewPushedFrom)
              data["layoverViewPushedFrom"] = this.layoverViewPushedFrom;
            this.formDetail.eventsHandler(data, id, this.formDetail);
          }
        }
        break;
    }
  }

  private _validateCurrentFormValue(): boolean {
    let flag = true;
    if (this._dynamicForm && this._dynamicForm.metadata && this._dynamicForm.metadata.length > 0) {
      for (let i = 0; i < this._dynamicForm.metadata.length; i++) {
        if (flag == false) {
          break;
        }
        const tab = this._dynamicForm.metadata[i];
        if (tab && tab.controls && tab.controls.length > 0) {
          for (let j = 0; j < tab.controls.length; j++) {
            const control = tab.controls[j];
            if (control.dataType == ControlDataType.StringType && control.format && control.format == 'Email') {
              // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
              let emailRegEx: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
              if (this._currentFormValue && this._currentFormValue[control.attributeName]) {
                if (this._currentFormValue[control.attributeName].length <= 0) {
                  flag = false;
                  this.notificationService.notify(this.translate.instant('CONSENT_EMAIL_CANT_EMPTY'), 'dynamic-form-component', 'top', ToastStyle.DANGER);
                  break;
                } else if (!emailRegEx.test(this._currentFormValue[control.attributeName])) {
                  flag = false;
                  this.notificationService.notify(this.translate.instant('CONTACT_INVALID_EMAIL_ADDRS'), 'dynamic-form-component', 'top', ToastStyle.DANGER);
                  break;
                }
                // Detect Email Duplication
                if(this.dynamicFormsService.storedLinkedEntityValues && this.dynamicFormsService.storedLinkedEntityValues.length>0) {
                  this.dynamicFormsService.storedLinkedEntityValues.forEach((value) => {
                    if(value.primaryText && this._currentFormValue[control.attributeName] && value.primaryText.toLowerCase() == this._currentFormValue[control.attributeName].toLowerCase()) {
                      flag = false;
                      this.notificationService.notify(this.translate.instant('DUPLICATE_DETECTED'), 'DynamicFormComponent', 'top', ToastStyle.DANGER, 3000);
                    }
                  });
                }
                if (this.formDetail && this.formDetail.linkedEntityValue && this.formDetail.linkedEntityValue.length>0) {
                  let enteredEmailAddress = this._currentFormValue[control.attributeName].toLowerCase();
                  if(this.editConfig == EditConfig.ADD_NEW) {
                    this.formDetail.linkedEntityValue.forEach((value) => {
                      if(this._currentFormValue[control.attributeName] &&
                        ((value['indskr_email_address.indskr_emailaddress'] && value['indskr_email_address.indskr_emailaddress'].toLowerCase() == enteredEmailAddress)
                        || (value['primaryText'] && value['primaryText'].toLowerCase() == enteredEmailAddress))) {
                        flag = false;
                        this.notificationService.notify(this.translate.instant('DUPLICATE_DETECTED'), 'DynamicFormComponent', 'top', ToastStyle.DANGER, 3000);
                      }
                    })
                  } else {
                    let isEditFromExistingData: boolean = true;
                     if(this.formDetail.formEntityObject) {
                       if(this.formDetail.formEntityObject.formValue) {
                       isEditFromExistingData = this.formDetail.formEntityObject.formValue['indskr_emailaddress'] && this._currentFormValue[control.attributeName] && this.formDetail.formEntityObject.formValue['indskr_emailaddress'].toLowerCase() == enteredEmailAddress ? true : false;
                     } else if(this.formDetail.formEntityObject['indskr_email_address.indskr_emailaddress']) {
                       isEditFromExistingData = this.formDetail.formEntityObject['indskr_email_address.indskr_emailaddress'] && this._currentFormValue[control.attributeName] && this.formDetail.formEntityObject['indskr_email_address.indskr_emailaddress'].toLowerCase() == enteredEmailAddress ? true : false;
                     }
                    }
                    this.formDetail.linkedEntityValue.forEach((value) => {
                      if(this.formDetail.isEditFormWithLinkedData) {
                        if(value.linkedEntityExistingDataId != this.formDetail.linkedEntityExistingDataId || !isEditFromExistingData) {
                          if(value.primaryText && this._currentFormValue[control.attributeName] && value.primaryText.toLowerCase() == enteredEmailAddress) {
                            flag = false;
                            this.notificationService.notify(this.translate.instant('DUPLICATE_DETECTED'), 'DynamicFormComponent', 'top', ToastStyle.DANGER, 3000);
                          }
                        }
                      } else {
                        if(value.primaryText && this._currentFormValue[control.attributeName] && value.primaryText.toLowerCase() == enteredEmailAddress) {
                          flag = false;
                          this.notificationService.notify(this.translate.instant('DUPLICATE_DETECTED'), 'DynamicFormComponent', 'top', ToastStyle.DANGER, 3000);
                        }
                      }
                    })
                  }
                  break;
                }
                if(this.dynamicFormsService.storedLinkedEntityValues && this.dynamicFormsService.storedLinkedEntityValues.length>0) {
                  this.dynamicFormsService.storedLinkedEntityValues.forEach((value) => {
                    if(value.primaryText == this._currentFormValue[control.attributeName]) {
                      flag = false;
                      this.notificationService.notify(this.translate.instant('DUPLICATE_DETECTED'), 'DynamicFormComponent', 'top', ToastStyle.DANGER, 3000);
                    }
                  });
                }
              }
            } else if (control.dataType == ControlDataType.DoubleType || control.dataType == ControlDataType.IntegerType || control.dataType == ControlDataType.DecimalType) {
              if (this._currentFormValue && this._currentFormValue[control.attributeName] && (this._currentFormValue[control.attributeName] < control.minValue || this._currentFormValue[control.attributeName] > control.maxValue)) {
                flag = false;
                let labelText: string = this._getDisplayText(control.displayNames, control.isCustom);
                this.notificationService.notify(this.translate.instant('DYNAMIC_FORM_INVALID_NUMBER_VALUE', { fieldname: labelText, maxvalue: control.maxValue, minvalue: control.minValue }), 'dynamic-form-component', 'top', ToastStyle.DANGER);
                break;
              }
            }
          }
        }
      }
    }
    return flag;
  }

  public callbackFromParentComponent(data: any, eventName: string) {
    if (data && eventName) {
      if (eventName == 'NEWDATACREATED') {
        this.formDetail.mainEntityObjectId = data;
        this._isFormSaved = true;
        this._isFormDirty = false;
        this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
        this._currentFormValue = {
          ...data,
          ...this._currentFormValue
        };
        if(this.MODE == 'CREATE_MODE' && this.formDetail && this.formDetail.formMetadata && this.formDetail.formMetadata.entityName == 'indskr_setbooking'){
          try {
            this.formDetail.rightHeaderBtnText = undefined;
            this.formDetail.rightHeaderImgSrcArray= ['assets/imgs/header_save.svg', 'assets/imgs/submit_white.svg'];
            this.formDetail.rightHeaderBtnTextArray = [this.translate.instant('Save'), this.translate.instant('Submit')];
            this.formDetail.rightHeaderBtnIdsArray = ['SAVEKITBOOKING','SUBMITKITBOOKING'];
            this.initFormPageTitleAndButtons(true);
          } catch (error) {}
        }
        this._initViewData();
      } else if (eventName == 'NEWDATAUPDATED') {
        this._isFormDirty = data == "ERROR";
        this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
        
        if(this.MODE == 'EDIT_MODE' && this.formDetail && this.formDetail.formMetadata && this.formDetail.formMetadata.entityName == 'indskr_setbooking'){
          this._parseRawObjectDataToValuesLinkedEntity();
          this._initViewData();
        } else {
          this._initViewData();
        }
      } else if(eventName === 'DISABLEFORM') {
        this.disableForm = true;
        this._isFormDirty = false;
        this._isFormSaved = true;
        this.isViewFromEditFormToAccordionForm = true;
        if (!_.isEmpty(data?.indskr_hcponeid)) {
          this._currentFormValue.indskr_hcponeid = data.indskr_hcponeid
          this.initFooter();
        }
        this._initViewData();
      } else if(eventName == 'ENABLEFORM') {
        this._isFormSaved = false;
        this._isFormDirty = true;
        this.dynamicFormsService.linkedEntityFormIsDirtyTracker = true;
        this._initViewData();
      }
    }
  }

  public async onSectionHeaderControlClick(id: string, data?: any) {
    let targetControl: Control;
    if (id) {
      for (let tabIdx = 0; tabIdx < this._dynamicForm.metadata.length; tabIdx++) {
        const tab = this._dynamicForm.metadata[tabIdx];
        if (tab && tab.controls && tab.controls.length > 0) {
          let foundControl = tab.controls.find(c => {
            if (!c.dataType) {
              const targetId = c.attributeName + '_' + 'ADD';
              return id == targetId;
            }
          });
          if (foundControl) {
            targetControl = foundControl;
            break;
          } else {
            foundControl = tab.controls.find(c => {
              if (!c.dataType) {
                const formattedId = id.toLowerCase();
                const formattedAttributName = c.attributeName.toLowerCase();
                return formattedId.includes(formattedAttributName);
              }
            });
            if (foundControl) {
              targetControl = foundControl;
              break;
            }
          }
          if(!targetControl) {
            let headerLabel: string = this.localizationService.getTranslatedString(this._getDisplayText(tab.displayNames));
            if(id == `${headerLabel}-collapsible`) this._expandCollapseLinkedEntitySection(id, data, true);
          }
        }
      };
      if (targetControl) {
        if (this.editConfig == EditConfig.UPDATE_EXISTING) return;
        if (id.includes('_SELECT')) {
        } else if (id.includes('_ADD')) {
          if (this.formDetail.formMetadata.entityName == 'account') {
            this.trackingService.tracking('AddSelected', TrackingEventNames.ACCOUNT_EDIT, true);
          } else if (this.formDetail.formMetadata.entityName == 'contact') {
            this.trackingService.tracking('AddSelected', TrackingEventNames.CONTACT_EDIT, true);
          }
          if (targetControl.subgrid) {
            if (targetControl.subgrid.targetEntityType) {
              let foundForm;
              let formType;
              const targetAttributeName: string = targetControl.attributeName;
              if (this.formDetail.dynamicFormType == DynamicFormType.CONFIGUREDFORM) {
                foundForm = await this.dynamicFormsService.getFormDefinitionForEntity(targetControl.subgrid.targetEntityType, FormType.CREATEFORM);
                formType = DynamicFormType.CONFIGUREDFORM;
              } else if (this.formDetail.dynamicFormType == DynamicFormType.DEFAULTFORM) {
                let ff = SHARED_CREATE_FORM.find(f => f.indskr_entityname == targetControl.subgrid.targetEntityType)
                if (ff) {
                  foundForm = new DynamicForm(ff)
                  formType = DynamicFormType.DEFAULTFORM;
                };
              }

              if (foundForm) {

                let rawFormEntityObject = null;
                //Account to Contact Affiliation - Prefill Account and make Contact as required field
                if (this.formDetail.formMetadata.entityName == 'account' && targetControl.subgrid.targetEntityType == 'indskr_accountcontactaffiliation') {
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    this.removeOldOptionSetIfLookupIsPresent(itab, foundForm, i, "omnione_role", "indskr_contactrole");
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundAccountControlIdx = itab.controls.findIndex(c => c.attributeName == 'indskr_accountid');
                      if (foundAccountControlIdx >= 0) {
                        itab.controls[foundAccountControlIdx].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundAccountControlIdx].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundAccountControlIdx].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        break;
                      }
                    }
                  };
                }

                //Account to Contact Affiliation change request - Prefill Account change request and make Contact as required field
                if (this.formDetail.formMetadata.entityName == 'indskr_accountcr' && targetControl.subgrid.targetEntityType == 'indskr_accountcontactaffiliationcr') {
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    this.removeOldOptionSetIfLookupIsPresent(itab, foundForm, i, "indskr_affiliationrole", "indskr_contactrole");
                    if (itab && itab.controls && itab.controls.length > 0) {
                      rawFormEntityObject = this.getFieldsToDisplayForContactAccountAff(itab, rawFormEntityObject, 'indskr_accountcr', 'indskr_contactid');
                    }
                  };
                }

                //Contact to Account Affiliation - Prefill Contact and make Account as required field
                if (this.formDetail.formMetadata.entityName == 'contact' && targetControl.subgrid.targetEntityType == 'indskr_accountcontactaffiliation') {
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    this.removeOldOptionSetIfLookupIsPresent(itab, foundForm, i, "omnione_role", "indskr_contactrole");
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIdx = itab.controls.findIndex(c => c.attributeName == 'indskr_contactid');
                      if (foundControlIdx >= 0) {
                        itab.controls[foundControlIdx].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        break;
                      }
                    }
                  };
                }

                //Contact to Account Affiliation change request - Prefill Contact change request and make Account as required field
                if (this.formDetail.formMetadata.entityName == 'indskr_contactcr' && targetControl.subgrid.targetEntityType == 'indskr_accountcontactaffiliationcr') {
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    this.removeOldOptionSetIfLookupIsPresent(itab, foundForm, i, "indskr_affiliationrole", "indskr_contactrole");
                    if (itab && itab.controls && itab.controls.length > 0) {
                      rawFormEntityObject = this.getFieldsToDisplayForContactAccountAff(itab, rawFormEntityObject, 'indskr_contactcr', 'indskr_accountid');
                    }
                  };
                }

                //Hide CustomerAddress
                if (targetControl.subgrid.targetEntityType == 'indskr_indskr_customeraddress_v2') {
                  // this.dynamicFormsService.mdmId = this._currentFormValue.indskr_mdmid;
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIndex = itab.controls.findIndex(c => c.attributeName == 'indskr_address');
                      if (foundControlIndex >= 0) {
                        itab.controls[foundControlIndex].forceHide = true;
                        break;
                      }
                    }
                  };
                }

                //Hide CustomerAddress ChangeRequest
                if (targetControl.subgrid.targetEntityType == 'indskr_customeraddresscr') {
                  // this.dynamicFormsService.mdmId = this._currentFormValue.indskr_mdmid;
                  this.dynamicFormsService.hasCityDataBasedOnCountry = false;
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    if (itab && itab.controls && itab.controls.length > 0) {
                      itab.controls.filter(c => c.attributeName == 'indskr_addresscr' || c.lookupEntitySetName == 'indskr_addresses' ||
                        c.lookupEntitySetName == 'indskr_contactcrs'
                        || c.lookupEntitySetName == 'indskr_accountcrs').forEach(c => {
                          if (c.lookupEntitySetName == this.formDetail.LookupEntitySetName) {
                            rawFormEntityObject = {
                              ['_' + c.attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                              ['_' + c.attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                            };
                          }
                          c.forceHide = true;
                        })
                    }
                  };
                }

                //Hide EmailAddress ChangeRequest "indskr_accountcrs", "indskr_contactcrs"
                if (targetControl.subgrid.targetEntityType == 'indskr_emailaddresscr') {
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIndex = itab.controls.findIndex(c => c.lookupEntitySetName == this.formDetail.LookupEntitySetName);
                      if (this.formDetail.LookupEntitySetName == 'indskr_accountcrs') {
                        const controlIndexToHide = itab.controls.findIndex(c => c.lookupEntitySetName == 'indskr_contactcrs');
                        if (controlIndexToHide >= 0) {
                          itab.controls[controlIndexToHide].forceHide = true;
                        }
                      }
                      if (this.formDetail.LookupEntitySetName == 'indskr_contactcrs') {
                        const controlIndexToHide = itab.controls.findIndex(c => c.lookupEntitySetName == 'indskr_accountcrs');
                        if (controlIndexToHide >= 0) {
                          itab.controls[controlIndexToHide].forceHide = true;
                        }
                      }
                      if (foundControlIndex >= 0) {
                        itab.controls[foundControlIndex].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundControlIndex].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundControlIndex].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        // break;
                      }
                    }
                  };
                }

                //Account to Account Affiliation - Prefill Contact and make Account as required field
                if (this.formDetail.formMetadata.entityName == 'account' && targetControl.subgrid.targetEntityType == 'indskr_accountaccountaffiliation') {
                  let attribName = targetControl.subgrid.referencingAttribute;
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    this.removeOldOptionSetIfLookupIsPresent(itab, foundForm, i, 'omnione_relationship', 'indskr_type');
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIdx = itab.controls.findIndex(c => c.attributeName == attribName);
                      if (foundControlIdx >= 0) {
                        itab.controls[foundControlIdx].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        break;
                      }
                    }
                  };
                }

                //Account to Account Affiliation Change request - Prefill Account From and make Account To as required field
                if (this.formDetail.formMetadata.entityName == 'indskr_accountcr' && targetControl.subgrid.targetEntityType == 'indskr_accountaccountaffiliationcr') {
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    this.removeOldOptionSetIfLookupIsPresent(itab, foundForm, i, 'indskr_affiliationtype', 'indskr_type');
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIdx = itab.controls.findIndex(c => c.attributeName == 'indskr_affiliatedfrom');
                      if (foundControlIdx >= 0) {
                        itab.controls[foundControlIdx].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        break;
                      }
                    }
                  };
                }

                //Contact to Contact Affiliation - Prefill Parent Contact and make Child contact as required field
                if ((this.formDetail.formMetadata.entityName == 'contact' && targetControl.subgrid.targetEntityType == 'indskr_contactrelationship')
                  || (this.formDetail.formMetadata.entityName == 'indskr_contactcr' && targetControl.subgrid.targetEntityType == 'indskr_contactrelationshipcr')) {
                  let attribName = targetControl.subgrid.referencingAttribute;
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    this.removeOldOptionSetIfLookupIsPresent(itab, foundForm, i, "omnione_relationship", this.formDetail.formMetadata.entityName == 'contact' ? "indskr_relationship" : "indskr_relationshiptype");
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIdx = itab.controls.findIndex(c => c.attributeName == attribName);
                      if (foundControlIdx >= 0) {
                        itab.controls[foundControlIdx].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        break;
                      }
                    }
                  };
                }

                //Set Booking to Product Association - Prefill Set Booking Guid
                if (this.formDetail.formMetadata.entityName == 'indskr_setbooking' && targetControl.subgrid.targetEntityType == 'indskr_setbookingproduct') {
                  let attribName = targetControl.subgrid.referencingAttribute;
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIdx = itab.controls.findIndex(c => c.attributeName == attribName);
                      if (foundControlIdx >= 0) {
                        itab.controls[foundControlIdx].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        break;
                      }
                    }
                  };
                  let surgeonaccountzsurValue = this._getAttributeValue('indskr_surgeonaccountzsur');
                  if(surgeonaccountzsurValue){
                    if(rawFormEntityObject){
                      rawFormEntityObject['surgeonaccountzsurValue'] = surgeonaccountzsurValue;
                    }else{
                      rawFormEntityObject = {
                        surgeonaccountzsurValue: surgeonaccountzsurValue,
                      };
                    }
                  }
                }

                //Set Booking to Team Member Association - Prefill Set Booking Guid
                if (this.formDetail.formMetadata.entityName == 'indskr_setbooking' && targetControl.subgrid.targetEntityType == 'indskr_setbookingteammember') {
                  let attribName = targetControl.subgrid.referencingAttribute;
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIdx = itab.controls.findIndex(c => c.attributeName == attribName);
                      if (foundControlIdx >= 0) {
                        itab.controls[foundControlIdx].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        break;
                      }
                    }
                  };
                }

                //linked entity values for checking the duplicate data
                const checkDuplicateDataInLookupFields = ["indskr_accountcontactaffiliation", "indskr_accountcontactaffiliationcr", "indskr_customerspecialty", "indskr_customerspecialtychangerequest", "indskr_accountaccountaffiliation", "indskr_accountaccountaffiliationcr", "indskr_contactrelationship", "indskr_contactrelationshipcr"];
                if (!_.isEmpty(this.linkedEntityValues)) {
                  if (targetControl.subgrid.targetEntityType == 'indskr_email_address') {
                    this.dynamicFormsService.storedLinkedEntityValues = this.linkedEntityValues.EMAIL ? this.linkedEntityValues.EMAIL : this.linkedEntityValues.Email ? this.linkedEntityValues.Email : this.linkedEntityValues.sgEmail ? this.linkedEntityValues.sgEmail : [];
                  } else if (checkDuplicateDataInLookupFields.includes(targetControl.subgrid.targetEntityType)) {
                    this.dynamicFormsService.storedLinkedEntityValues = this.linkedEntityValues[targetAttributeName] ? this.linkedEntityValues[targetAttributeName] : [];
                  }
                }
                let displayName: any = this._getDisplayText(targetControl.displayNames);
                let formDetailEntityName = this.formDetail.formMetadata.entityName;
                let referencingEntityName = targetControl.subgrid.referencingEntity;
                const enableSearch = targetControl.subgrid.referencingEntity == 'indskr_indskr_customeraddress_v2' || targetControl.subgrid.referencingEntity == 'indskr_customeraddresscr'
                  || (this.accountService.isOneKeyChangeRequest && formDetailEntityName == 'indskr_accountcr' && referencingEntityName == 'indskr_accountaccountaffiliationcr' && this.MODE == 'EDIT_MODE')
                  || (this.contactService.isOneKeyChangeRequest && formDetailEntityName == 'indskr_contactcr' && referencingEntityName == 'indskr_accountcontactaffiliationcr' && this.MODE == 'EDIT_MODE');
                let existingLinkedEntityValues = [];
                if (this.editConfig == EditConfig.ADD_NEW) {
                  existingLinkedEntityValues = this.formDetail.linkedEntityValue ? this.formDetail.linkedEntityValue : [];
                } else {
                  existingLinkedEntityValues = this.linkedEntityValues ? this.linkedEntityValues[targetControl.attributeName] ? this.linkedEntityValues[targetControl.attributeName] : [] : [];
                }
                let formDetail: DynamicFormComponentModel = {
                  title: _.startCase(displayName.toLowerCase()),
                  showLeftHeaderButton: true,
                  showSearchBar: enableSearch,
                  leftHeaderBtnText: this.translate.instant('CANCEL'),
                  LookupEntitySetName: foundForm.entitySetName,
                  showRightHeaderButton: true,
                  showClearButton: true,
                  linkedEntityAddedNewDataId: targetControl.attributeName + '_' + Math.random().toString(36).substring(7),
                  rightHeaderImgSrc: 'assets/imgs/header_complete.svg',
                  rightHeaderBtnText: this.translate.instant('DONE'),
                  formMetadata: foundForm,
                  formEntityObject: rawFormEntityObject,
                  mainEntityObjectId: null,
                  linkedEntityParentData: this.getLinkEntityParentData(foundForm.entitySetName, targetControl),
                  customFields: await this._getCustomFields(targetControl.subgrid.referencingEntity),
                  dynamicFormType: formType,
                  navOptions: { animate: false },
                  linkedEntityValue: existingLinkedEntityValues,
                  eventsHandler: (data: any, eventTarget: string, refData: DynamicFormComponentModel) => this._handleDynamicFormComponentEvent(data, eventTarget, refData),
                };
                this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
                if (this.formDetail.formMetadata.entityName == 'indskr_setbooking' && targetControl.subgrid.targetEntityType == 'indskr_setbookingproduct'){
                  this._fetchSetBookingProduct(formDetail);
                } else {
                  this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent, PageName.DynamicFormComponent, this.navService.getCurrentMasterPageName(), {
                    MODE: 'CREATE_MODE',
                    PREVIOUS_MODE: this._previousMode == 'EDIT_MODE' ? 'EDIT_MODE' : 'CREATE_MODE',
                    FORM_DETAIL: formDetail,
                    TYPE: this.TYPE ? this.TYPE : null,
                  });
                }
              }
            }
          }
        } else if (id.includes('collapsible')) {
          this._expandCollapseLinkedEntitySection(id, data);
        } else if (id.includes('SCRAP')) {
          this.alertService.showAlert({
            title: this.translate.instant('SCRAP'),
            message: this.translate.instant('LINKED_ENTITY_R_U_WANT_SCRAP_FROM_LIST', {linkedEntityName: this.localizationService.getTranslatedString(this._getDisplayText(targetControl.displayNames))})
          }, this.translate.instant('SCRAP')
          ).then(res => {
            if (res.role == 'ok') {
              if (this.linkedEntityValues && this.linkedEntityValues[targetControl.attributeName]) {
                // Remove selected linked entities
                this.linkedEntityValues[targetControl.attributeName].forEach(x => {
                  if(x.isCheckedForScrap) {
                    x.isDeactivate = true;
                    let values = {
                      guid: x.linkedEntityExistingDataId || x.linkedEntityAddedNewDataId || x.linkedEntityExistingDataAddressId,
                      statecode: 1,
                      statuscode: 2
                    }
                    this.linkedEntityValues[targetControl.attributeName] = this.linkedEntityValues[targetControl.attributeName].filter(x=>x.isDeactivate != true);
                    if (this.formDetail && this.formDetail.eventsHandler) {
                      let dataReq = {
                        eventsHandler: (data: any, eventName: string) => this.callbackFromParentComponent(data, eventName), //ind-dynamic-form:3349
                        displayConfirmation: this._isFormDirty,
                        linkedEntityExistingDataId: x.linkedEntityExistingDataId || '',
                        linkedEntityAddedNewDataId: x.linkedEntityAddedNewDataId || '',
                        "entity": targetControl.lookupEntitySetName,
                        "values": values,
                      }
                      this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
                      this.formDetail.eventsHandler(dataReq, 'SCRAPLINKEDENTITY');
                      this._isFormDirty = true;
                    }
                  }
                })
                this._initViewData();
              }
            }
          })
        }
      } else if (id.includes('_CLEAR')) {
        if (this.formDetail.linkedEntityParentData.entity && this.dynamicFormsService.okLookUpMapper) {
          if (!_.isEmpty(this._currentFormValue) && this._currentFormValue['lookupfields']) {
            this._currentFormValue['lookupfields'].forEach(field => { if (field && field.id) this.dynamicFormsService.clearMappedLookupOnekeyWithId(field.id); });
          }
          if (!_.isEmpty(this._customFormValue) && this._customFormValue['lookupfields']) {
            this._customFormValue['lookupfields'].forEach(field => { if (field && field.id) this.dynamicFormsService.clearMappedLookupOnekeyWithId(field.id); });
          }
        }
        this._currentFormValue = { lookupfields: [this.formDetail.linkedEntityParentData] };
        this._currentFormStringValues = {};
        this._customFormValue = {};
        this._isFormDirty = false;
        this._initViewData();
        this.isSelectedFromSearch = false;
        this.dynamicFormsService.hasCityDataBasedOnCountry = false;
        // this.dynamicFormsService.isSelectedCountry = false;
      } else if (id == 'attach_work_cards' || id =='attach_practice_certificate') {
        this.handleNoteAttachment(id);
      }
    }
  }

  private _expandCollapseLinkedEntitySection(id, data, isDefaultSection = false) {
    let viewField = this.currentFormView.find(a => a.id == data.id);
    if (isDefaultSection) {
      this.currentFormView.forEach(view => {
        if(view.type == 'form-field' && view.section == data.id) view.isCollapsed = !view.isCollapsed;        
      })
    }
    if (data && viewField) {
      viewField.isCollapsed = !data.isCollapsed;
      if(viewField.view && !_.isEmpty(viewField.view.controls)) {
        if(viewField.view.controls.length==1) viewField.view.controls[0].icon = viewField.isCollapsed ? 'assets/imgs/down-arrow.svg' : 'assets/imgs/up-arrow.svg';
        else if(viewField.view.controls.length>1) {
          let idx = viewField.view.controls.findIndex(ctrl => ctrl.id.includes('collapsible'));
          if(idx>-1) viewField.view.controls[idx].icon = viewField.isCollapsed ? 'assets/imgs/down-arrow.svg' : 'assets/imgs/up-arrow.svg';
        }
      }
    }
  }

  private getLinkEntityParentData(lookupEntitySetName: string, targetControl: Control): any {
    if(lookupEntitySetName == "indskr_accountaccountaffiliationcrs") {
      return { name: 'indskr_AffiliatedFrom', entity: this._dynamicForm.entitySetName, id: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]] }
    }
    return { name: targetControl.subgrid.referencingEntityNavigationPropertyName, entity: this._dynamicForm.entitySetName, id: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]] };
  }

  private getFieldsToDisplayForContactAccountAff(itab: any, rawFormEntityObject: any, attributeToPrefill: string, attributeToDisplay: string) {
    itab.controls.filter(c => (c.attributeName == 'indskr_contactcr' || c.attributeName == 'indskr_accountcr' || c.attributeName == 'indskr_contactid' || c.attributeName == 'indskr_accountid'))
      .forEach(control => {
        if (control.attributeName == attributeToPrefill) {
          control.forceHide = true;
          rawFormEntityObject = {
            ['_' + control.attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
            ['_' + control.attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
          };
        } else if (control.attributeName == attributeToDisplay) {
          control.forceHide = false;
          control.isRequired = true;
        } else {
          control.forceHide = true;
          control.isRequired = false;
        }
      });
    return rawFormEntityObject;
  }

  private removeOldOptionSetIfLookupIsPresent(itab: any, foundForm: any, i: number, newLookUpAttribute: string, oldOptionSetAttribute: string) {
    const affRoleIndex = itab.controls.findIndex(control => control.attributeName == newLookUpAttribute);
    if (affRoleIndex >= 0) {
      const roleIndex = itab.controls.findIndex(control => control.attributeName == oldOptionSetAttribute);
      if (roleIndex >= 0) {
        itab.controls.splice(roleIndex, 1);
        foundForm.metadata[i] = itab;
      }
    }
  }

  private async _handleDynamicFormComponentEvent(data, eventTarget, refData: DynamicFormComponentModel) {
    if (eventTarget) {
      if (eventTarget == 'DONEBUTTONCLICK' && refData) {

        await this.uiService.displayLoader();
        if (data.customFormValue && !_.isEmpty(data.customFormValue) && !data.isSelectedFromSearch || this.dynamicFormsService.isSelectedDataFromAddressOneKey) {
          let { logicalCollectionName, logicalName, schemaName } = this.getAddressEntity();
          this.dynamicFormsService.okAddressMapper.custom = data['customFormValue'];
          this.dynamicFormsService.okAddressMapper.formatted = data['formStringValue'];
          if (!refData.isEditFormWithLinkedData) {
            //For contact cr, no need to create address
            if (!(refData.LookupEntitySetName == "indskr_customeraddresscrs" && refData.linkedEntityParentData.entity == "indskr_contactcrs")) {
              let res = await this.dynamicFormsService.createLinkedEntity(data['customFormValue'], logicalCollectionName);
              if (res) {
                data['formValue'].lookupfields.push({
                  entity: logicalCollectionName,
                  id: res['guid'],
                  name: schemaName,
                });
              } else {
                return;
              }
            }
          } else if (refData.isEditFormWithLinkedData && logicalName == "indskr_address") {
            if (refData.linkedEntityExistingDataAddressId) {
              data['customFormValue']['guid'] = refData.linkedEntityExistingDataAddressId;
            }
            let res = await this.dynamicFormsService.createLinkedEntity(data['customFormValue'], logicalCollectionName);
            if (res) {
              data['formValue'].lookupfields.push({
                entity: logicalCollectionName,
                id: res['guid'],
                name: schemaName,
              });
            } else {
              return;
            }
          }

          this.dynamicFormsService.isSelectedDataFromAddressOneKey = false;
          this.dynamicFormsService.isSearchedAddress = true;
        }
        if (refData.linkedEntityParentData) {
          try {
            // Onekey
            if (this.changeRequest && !(this.TYPE == "OMNIPRESENCE" && this.MODE == 'EDIT_MODE')) {
              let dataReq = {
                "entity": refData.LookupEntitySetName,
                "values": [data['formValue']],
                eventsHandler: (data: any, eventName: string) => this.callbackFromParentComponent(data, eventName)
              }
              this._isFormDirty = true;
              this._isFormSaved = false;
              this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
              // check added linked data and store it - related to auto-populated
              if (this._mode == "EDIT_MODE" && this.isAutoPopulatedLinkedDataFlow) {
                let addressAttrName = this.dynamicFormsService.addressAttributeName || '';
                let accountAffiliationAttrName = this.dynamicFormsService.accountAffiliationAttributeName || '';
                // Add Account-Contact Affiliation in Contact CR
                if (refData.linkedEntityParentData.entity == 'indskr_contactcrs' && refData.LookupEntitySetName == 'indskr_accountcontactaffiliationcrs') {
                  this.addedNewACAffiliation = true;
                  if (accountAffiliationAttrName && !_.isEmpty(this.linkedEntityValues) && !_.isEmpty(this.linkedEntityValues[accountAffiliationAttrName])) {
                    delete this.linkedEntityValues[accountAffiliationAttrName]; //delete previous existing account view data
                    this.mdmService.deleteLinkEntities(dataReq.entity); //delete stored data in linkEntitiesReqPayload
                  }
                  if (addressAttrName && !_.isEmpty(this.linkedEntityValues) && !_.isEmpty(this.linkedEntityValues[addressAttrName])) {
                    delete this.linkedEntityValues[addressAttrName]; //delete previous existing address view data
                    this.mdmService.deleteLinkEntities(dataReq.entity); //delete stored data in linkEntitiesReqPayload
                  }
                  // update address along with changed account if there is address linked entity field
                  if (addressAttrName) {
                    await this.autoPopulatedLinkedData('contactAddress', true, data).then(() => {
                      this.formDetail.eventsHandler(dataReq, 'ADDEDLINKEDENTITY', this.formDetail);
                    });
                  } else {
                    this.formDetail.eventsHandler(dataReq, 'ADDEDLINKEDENTITY', this.formDetail);
                  }
                // Add address in Account CR
                } else if (refData.linkedEntityParentData.entity == 'indskr_accountcrs' && refData.LookupEntitySetName == 'indskr_customeraddresscrs') {
                  this.addedNewAddressForAccountCR = true;
                  if (addressAttrName && !_.isEmpty(this.linkedEntityValues) && !_.isEmpty(this.linkedEntityValues[addressAttrName])) {
                    delete this.linkedEntityValues[addressAttrName]; //delete previous existing address view data
                    this.mdmService.deleteLinkEntities(dataReq.entity); //delete stored data in linkEntitiesReqPayload
                  }
                  this.isViewFromEditFormToAccordionForm = true;
                  this.dynamicFormsService.isSelectedCountry = false;
                  this.formDetail.eventsHandler(dataReq, 'ADDEDLINKEDENTITY', this.formDetail);
                // the other linked entities flow
                } else {
                  this.formDetail.eventsHandler(dataReq, 'ADDEDLINKEDENTITY', this.formDetail);
                }
              // non auto-populated data flow
              } else {
                this.formDetail.eventsHandler(dataReq, 'ADDEDLINKEDENTITY', this.formDetail);
              }
            // Business
            } else {
              // let res = await this.dynamicFormsService.createLinkedEntity(data['formValue'], refData.LookupEntitySetName);
              // if (!res) {
              //   this.notificationService.notify('Falied creating linked entity', 'DynamicFormComponent', 'top', ToastStyle.INFO, 3000);
              // } else {
                //Added Linked Entity
                if (this.formDetail && this.formDetail.eventsHandler) {
                  if (refData.linkedEntityExistingDataId) data['formValue']['guid'] = refData.linkedEntityExistingDataId;
                  let dataReq = {
                    formValue: this._currentFormValue,
                    formStringValue: this._currentFormStringValues,
                    customFormValue: this._customFormValue,
                    eventsHandler: (data: any, eventName: string) => this.callbackFromParentComponent(data, eventName),
                    displayConfirmation: this._isFormDirty,
                    linkedEntityExistingDataId: refData.linkedEntityExistingDataId || '',
                    linkedEntityAddedNewDataId: refData.linkedEntityAddedNewDataId || '',
                    "entity": refData.LookupEntitySetName,
                    "values": data['formValue'],
                  }
                  // the error occurs with the primary field value as false in Dynamics: refer to OMNI-20325, OMNI-27081
                  if (refData.isEditFormWithLinkedData && dataReq.values && dataReq.values.hasOwnProperty('indskr_isprimary') && dataReq.values['indskr_isprimary'] != true) {
                    delete dataReq.values['indskr_isprimary'];
                  }
                  if (refData.isEditFormWithLinkedData && dataReq.values && dataReq.values.hasOwnProperty('indskr_isprimaryaccount') && dataReq.values['indskr_isprimaryaccount'] != true) {
                    delete dataReq.values['indskr_isprimaryaccount'];
                  }
                  this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
                  this.formDetail.eventsHandler(dataReq, 'ADDEDLINKEDENTITY', this.formDetail);
                  this._isFormDirty = true;
                }
              // }
            }

            if (data) {
              let foundControl: Control;
              for (let i = 0; i < this._dynamicForm.metadata.length; i++) {
                const tab = this._dynamicForm.metadata[i];
                if (tab && tab.controls && tab.controls.length > 0) {
                  for (let j = 0; j < tab.controls.length; j++) {
                    const control = tab.controls[j];
                    if (!control.dataType && control.subgrid && control.subgrid.targetEntityType == refData.formMetadata.entityName) {
                      if (control.subgrid.targetEntityType == "indskr_accountaccountaffiliation") {
                        if(control.subgrid.referencingAttribute == refData.linkedEntityParentData.name) {
                          foundControl = control;
                          break;
                        }
                      } else {
                        foundControl = control;
                        break;
                      }
                    }
                  }
                  if (foundControl) {
                    break;
                  }
                }
              }
              if (foundControl) {
                try {
                  let accordionView: OmniAccordionViewDataModel;
                  let childItems: Array<any> = [];
                  let formattedPrimaryText: string = '';
                  const referencingEntity = foundControl.subgrid.referencingEntity;
                  const referencingAttribute = foundControl.subgrid.referencingAttribute;
                  const lookupData = data && data.formValue && data.formValue.lookupfields ? data.formValue.lookupfields : '';
                  const lookupFieldActualValue: string = this._getLookupFieldActualValue(lookupData, referencingEntity, referencingAttribute, false);
                  let abilityToRemoveRecords = foundControl.linkedEntityConfig && foundControl.linkedEntityConfig.abilityToRemoveRecords == '548910000'
                  foundControl.subgrid.subgridLayout.forEach(attrib => {
                    let attribName;
                    let index = attrib.attribute.indexOf('.');
                    if (index !== -1) {
                      attribName = attrib.attribute.slice(index + 1);
                    } else {
                      attribName = attrib.attribute;
                    }
                    if (attribName && ((data.formStringValue && data.formStringValue[attribName]) || data.formValue[attribName] || (data.customFormValue && data.customFormValue[attribName]))) {
                      if (accordionView) {
                        let displayName: string = this._getDisplayText(attrib.displayNames);

                        childItems.push({
                          id: foundControl.attributeName + '_' + attribName,
                          label: displayName ? displayName : '',
                          value: ((data.formStringValue && data.formStringValue[attribName]) ? data.formStringValue[attribName] : (data.formValue[attribName] || data.customFormValue[attribName])),
                        });
                        accordionView.showExpandIcon = true;
                        accordionView.showCollapseIcon = true;
                        accordionView.lookupFieldActualValue = lookupFieldActualValue;
                      } else {
                        accordionView = {
                          displayType: 'Accordion',
                          id: foundControl.attributeName + '_' + attribName + Math.random().toString(36).substring(7),
                          linkedEntityExistingDataId: refData.linkedEntityExistingDataId || '',
                          linkedEntityExistingDataAddressId: refData.linkedEntityExistingDataAddressId || '',
                          linkedEntityAddedNewDataId: refData.linkedEntityAddedNewDataId || '',
                          primaryText: ((data.formStringValue && data.formStringValue[attribName]) ? data.formStringValue[attribName] : (data.formValue[attribName] || data.customFormValue[attribName])),
                          lookupFieldActualValue: lookupFieldActualValue,
                          showExpandIcon: false,
                          showCollapseIcon: false,
                          isExpanded: false,
                          childItems: [],
                          rawObjData: data,
                          isUpdatedFormData: true,
                          isDeactivate: false,
                          scrapFromList: abilityToRemoveRecords,
                          clickHandler: (id, event, specificTarget, dataRef) => this.handleAccordionView(event, foundControl, specificTarget, dataRef)
                        }
                      }
                    }
                  });
                  // set primary text value - Primary text in editable auto populated data does not have accordionView.
                  if (refData.isEditFormWithLinkedData || this.editConfig == EditConfig.BOTH || this.dynamicFormsService.isSelectedDataFromSearch) {
                    switch(foundControl.subgrid.referencingEntity) {
                      case 'indskr_email_address':
                        if (data.formValue && data.formValue.hasOwnProperty('indskr_emailaddress')) {
                          formattedPrimaryText = data.formValue.indskr_emailaddress;
                        }
                        break;
                      case 'indskr_indskr_customeraddress_v2':
                        if (data.formValue && data.formValue.hasOwnProperty('indskr_name')) {
                          formattedPrimaryText = data.formValue.indskr_name;
                        }
                        break;
                      case 'indskr_customerspecialty':
                        if (data.formStringValue && data.formStringValue.hasOwnProperty('indskr_specialtyid')) {
                          formattedPrimaryText = data.formStringValue.indskr_specialtyid;
                        }
                        break;
                      case 'indskr_contactrelationship':
                        if (data.formStringValue && data.formStringValue.hasOwnProperty('indskr_relatedcontactid')) {
                          formattedPrimaryText = data.formStringValue.indskr_relatedcontactid;
                        }
                        break;
                      case 'indskr_accountcontactaffiliation':
                        if (foundControl.subgrid.referencingAttribute == 'indskr_contactid') {
                          if (data.formStringValue && data.formStringValue.hasOwnProperty('indskr_accountid')) {
                            formattedPrimaryText = data.formStringValue.indskr_accountid;
                          }
                        } else if (foundControl.subgrid.referencingAttribute == 'indskr_accountid') {
                          if (data.formStringValue && data.formStringValue.hasOwnProperty('indskr_contactid')) {
                          formattedPrimaryText = data.formStringValue.indskr_contactid;
                          }
                        }
                        break;
                      case 'indskr_accountaccountaffiliation':
                        if (foundControl.subgrid.referencingAttribute == 'indskr_affiliatedtoaccountid') {
                          if (data.formStringValue && data.formStringValue.hasOwnProperty('indskr_affiliatedfromaccountid')) {
                            formattedPrimaryText = data.formStringValue.indskr_affiliatedfromaccountid;
                          }
                        } else if (foundControl.subgrid.referencingAttribute == 'indskr_affiliatedfromaccountid') {
                          if (data.formStringValue && data.formStringValue.hasOwnProperty('indskr_affiliatedtoaccountid')) {
                            formattedPrimaryText = data.formStringValue.indskr_affiliatedtoaccountid || '';
                          }
                        }
                        break;
                      case 'indskr_customerposition':
                        if (data.formStringValue && data.formStringValue.hasOwnProperty('indskr_customerposition')) {
                          formattedPrimaryText = data.formStringValue.indskr_customerposition;
                        }
                        break;
                      case 'indskr_accountcontactaffiliationcr':
                        if (foundControl.subgrid.referencingAttribute == 'indskr_contactcr') {
                          if (data.formStringValue && data.formStringValue.hasOwnProperty('indskr_accountid')) {
                            formattedPrimaryText = data.formStringValue.indskr_accountid;
                          }
                        } else if (foundControl.subgrid.referencingAttribute == 'indskr_accountcr') {
                          if (data.formStringValue && data.formStringValue.hasOwnProperty('indskr_contactid')) {
                          formattedPrimaryText = data.formStringValue.indskr_contactid;
                          }
                        }
                        break;
                      default:
                        formattedPrimaryText = '';
                        break;
                    }
                  }
                  if (this.dynamicFormsService.isSelectedDataFromSearch && !accordionView && formattedPrimaryText) {
                    accordionView = {
                      displayType: 'Accordion',
                      id: foundControl.attributeName + '_' + foundControl.subgrid.referencingEntity + Math.random().toString(36).substring(7),
                      linkedEntityExistingDataId: refData.linkedEntityExistingDataId || '',
                      linkedEntityExistingDataAddressId: refData.linkedEntityExistingDataAddressId || '',
                      linkedEntityAddedNewDataId: refData.linkedEntityAddedNewDataId || '',
                      primaryText: formattedPrimaryText,
                      showExpandIcon: false,
                      showCollapseIcon: false,
                      isExpanded: false,
                      childItems: [],
                      rawObjData: data,
                      isUpdatedFormData: true,
                      isDeactivate: false,
                      scrapFromList: abilityToRemoveRecords,
                      clickHandler: (id, event, specificTarget, dataRef) => this.handleAccordionView(event, foundControl, specificTarget, dataRef)
                    }
                  }
                  if (accordionView) {
                    if (formattedPrimaryText && this.editConfig !=EditConfig.ADD_NEW) accordionView.primaryText = formattedPrimaryText;
                    accordionView.childItems = childItems;
                    if (this.linkedEntityValues && this.linkedEntityValues[foundControl.attributeName]) {
                      if (refData.linkedEntityExistingDataId) {
                        let index = this.linkedEntityValues[foundControl.attributeName].findIndex(x => x.linkedEntityExistingDataId == refData.linkedEntityExistingDataId);
                        if (index != -1) {
                          this.linkedEntityValues[foundControl.attributeName][index] = accordionView;
                        } else {
                          this.linkedEntityValues[foundControl.attributeName].push(accordionView);
                        }
                      } else {
                        let index = this.linkedEntityValues[foundControl.attributeName].findIndex(x => x.linkedEntityAddedNewDataId == refData.linkedEntityAddedNewDataId);
                        if (index != -1) {
                          this.linkedEntityValues[foundControl.attributeName][index] = accordionView;
                        } else {
                          this.linkedEntityValues[foundControl.attributeName].push(accordionView);
                        }
                      }
                    } else if (this.linkedEntityValues) {
                      this.linkedEntityValues[foundControl.attributeName] = [accordionView];
                    } else {
                      this.linkedEntityValues = {
                        [foundControl.attributeName]: [accordionView]
                      };
                      this.linkedEntityValues[foundControl.attributeName] = this.linkedEntityValues[foundControl.attributeName].filter(x=>x.isDeactivate == false);
                    }
                    this._initViewData();
                  }
                  this.uiService.dismissLoader();
                } catch (error) {
                  this.uiService.dismissLoader();
                  console.log(error);
                }
              }
            }
            this._closepage();

          } catch (error) {
            console.log(error);
            if (error.error.errorCode == 'ERR_IO_500') {
              this.notificationService.notify('Duplicate Detected: This ' + refData.title + ' has already been added', 'DynamicFormComponent', 'top', ToastStyle.INFO, 3000);
            }

            await this.uiService.dismissLoader();
          }
        }
        await this.uiService.dismissLoader();

      } else if (eventTarget == 'CANCELBUTTONCLICK') {
        if (this.dynamicFormsService.linkedEntityFormIsDirtyTracker) {
          let cancelText = this.translate.instant("CANCEL");
          if(this.translate.currentLang == 'it') {
            cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
          }
          this.alertService.showAlert({
            title: this.translate.instant('DISCARD_CHANGES'),
            message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')
          }, this.translate.instant('DISCARD'), cancelText
          ).then(res => {
            if (res.role == 'ok') {
              //clear onekey code lookup data
              const currentMetaData = refData.formMetadata.metadata;
              for (let tabIdx = 0; tabIdx < currentMetaData.length; tabIdx++) {
                const tab = currentMetaData[tabIdx];
                if (tab && tab.controls && tab.controls.length > 0) {
                  const foundControl = tab.controls.find(c => c.dataType == ControlDataType.LookupType && c.lookupEntityPrimaryId == "omnione_onekeycodeslabelsid");
                  if (foundControl) {
                    const schemaName = foundControl.schemaName || '';
                    if (data && data.formValue.hasOwnProperty('lookupfields')) {
                      const idx = data.formValue['lookupfields'].findIndex(a => a.name == schemaName);
                      if (idx >= 0) {
                        const prevGuid = data.formValue['lookupfields'][idx].id || '';
                        if(prevGuid && this.dynamicFormsService.okLookUpMapper) this.dynamicFormsService.clearMappedLookupOnekeyWithId(prevGuid);
                      }
                    }
                  }
                }
              }
              this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
              this._closepage();
            }
          });
        } else {
          //clear onekey code lookup data
          const currentMetaData = refData.formMetadata.metadata;
          for (let tabIdx = 0; tabIdx < currentMetaData.length; tabIdx++) {
            const tab = currentMetaData[tabIdx];
            if (tab && tab.controls && tab.controls.length > 0) {
              const foundControl = tab.controls.find(c => c.dataType == ControlDataType.LookupType && c.lookupEntityPrimaryId == "omnione_onekeycodeslabelsid");
              if (foundControl) {
                const schemaName = foundControl.schemaName || '';
                if (data && data.formValue.hasOwnProperty('lookupfields')) {
                  const idx = data.formValue['lookupfields'].findIndex(a => a.name == schemaName);
                  if (idx >= 0) {
                    const prevGuid = data.formValue['lookupfields'][idx].id || '';
                    if(prevGuid && this.dynamicFormsService.okLookUpMapper) this.dynamicFormsService.clearMappedLookupOnekeyWithId(prevGuid);
                  }
                }
              }
            }
          }
          this._closepage();
        }
      } else if(eventTarget == 'SCARPBUTTONCLICK') {
        let foundControl: Control;
        for (let i = 0; i < this._dynamicForm.metadata.length; i++) {
          const tab = this._dynamicForm.metadata[i];
          if (tab && tab.controls && tab.controls.length > 0) {
            for (let j = 0; j < tab.controls.length; j++) {
              const control = tab.controls[j];
              if (!control.dataType && control.subgrid && control.subgrid.targetEntityType == refData.formMetadata.entityName) {
                if (control.subgrid.targetEntityType == "indskr_accountaccountaffiliation") {
                  if(control.subgrid.referencingAttribute == refData.linkedEntityParentData.name) {
                    foundControl = control;
                    break;
                  }
                } else {
                  foundControl = control;
                  break;
                }
              }
            }
            if (foundControl) {
              break;
            }
          }
        }
        // check the min number of linked entity before scrap the data
        let isValidMinNumber: boolean = true;
        if (foundControl.linkedEntityConfig && foundControl.linkedEntityConfig.minNumber) {
          if (this.linkedEntityValues && this.linkedEntityValues[foundControl.attributeName]) {
            isValidMinNumber = this.linkedEntityValues[foundControl.attributeName].length <= foundControl.linkedEntityConfig.minNumber ? false : true;
          }
        }
        if (isValidMinNumber) {
          let foundlinkedEntityName = this.getDisplayEntityNameInScrapMessage(foundControl);
          this.alertService.showAlert({
            title: this.translate.instant('LINKED_ENTITY_SCRAP', {linkedEntityName: foundlinkedEntityName}),
            message: this.translate.instant('LINKED_ENTITY_R_U_WANT_SCRAP', {linkedEntityName: foundlinkedEntityName})
          }, this.translate.instant('SCRAP')
          ).then(res => {
            if (res.role == 'ok') {
              let index;
              if(refData.LookupEntitySetName == 'indskr_setbookingproducts' && refData.linkedEntityAddedNewDataId) {
                index = this.linkedEntityValues[refData.targetControlAttribName].findIndex(x => x.linkedEntityAddedNewDataId == refData.linkedEntityAddedNewDataId);
              } else {
                index = this.linkedEntityValues[refData.targetControlAttribName].findIndex(x => x.linkedEntityExistingDataId == refData.linkedEntityExistingDataId);
              }
              if (index != -1) {
                this.linkedEntityValues[refData.targetControlAttribName][index].isDeactivate = true;
              }
              if (data['formValue']) {
                let tempFormValue = data['formValue'].lookupfields[0];
                delete data['formValue'];
                data['formValue'] = { lookupfields: [tempFormValue] };
              }
              if (refData.linkedEntityExistingDataId) {
                data['formValue'] = { ...data['formValue'],
                  guid: refData.linkedEntityExistingDataId,
                  statecode: 1,
                  statuscode: 2
                };
              } else if(refData.LookupEntitySetName == 'indskr_setbookingproducts' && refData.linkedEntityAddedNewDataId) {
                data['formValue'] = { ...data['formValue'],
                  guid: refData.linkedEntityAddedNewDataId,
                  statecode: 1,
                  statuscode: 2
                };
              }
              this.linkedEntityValues[refData.targetControlAttribName] = this.linkedEntityValues[refData.targetControlAttribName].filter(x=>x.isDeactivate != true);
              if (this.formDetail && this.formDetail.eventsHandler) {
                let dataReq = {
                  eventsHandler: (data: any, eventName: string) => this.callbackFromParentComponent(data, eventName),
                  displayConfirmation: this._isFormDirty,
                  linkedEntityExistingDataId: refData.linkedEntityExistingDataId || '',
                  linkedEntityAddedNewDataId: refData.linkedEntityAddedNewDataId || '',
                  "entity": refData.LookupEntitySetName,
                  "values": data['formValue'],
                }
                this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
                this.formDetail.eventsHandler(dataReq, 'SCRAPLINKEDENTITY', this.formDetail);
                this._isFormDirty = true;
              }
              this._initViewData();
              this._closepage();
            }
          });
        } else {
          let formEntityName = this.formDetail.formMetadata.entityName;
          formEntityName = formEntityName == 'indskr_setbooking' ? this.translate.instant('KIT_BOOKING') : formEntityName;
          this.notificationService.notify(this.translate.instant('LINKED_ENTITY_SCRAP_ERROR_MESSAGE', {entityName: formEntityName}), 'DynamicFormComponent', 'top', ToastStyle.DANGER, 3000);
          this._closepage();
        }
      }
    }
  }

  // private _handleLinkedEntityExpandClick(id,event,eventName,refData:MainCardViewDataModel){
  //     if(this.linkedEntityValues && this.linkedEntityValues.length != 0 && refData.controlAttributeName && Array.isArray(this.linkedEntityValues[refData.controlAttributeName])){
  //         let foundView = this.linkedEntityValues[refData.controlAttributeName].find(a=> a.id == refData.id);
  //         if(foundView){
  //             foundView.isExpanded = !foundView.isExpanded;
  //             if(foundView.isExpanded){
  //                 refData.arrowType = 'remove-outline';
  //               }else{
  //                 refData.arrowType = 'add-outline';
  //               }
  //         }
  //     }
  // }

  private _handleFormFieldEvent(id, event, eventName, dataRef?) {
    let targetControl: Control = this._getTargetControl(id);
    if (targetControl) {
      switch (targetControl.dataType) {
        case ControlDataType.MoneyType:
        case ControlDataType.DecimalType:
        case ControlDataType.DoubleType:
        case ControlDataType.IntegerType:
        case ControlDataType.StringType:
        case ControlDataType.MemoType:
          this._handleInlineInputFormField(id, event, eventName, targetControl);
          this._isClicked[targetControl.attributeName] = true;
          break;
        case ControlDataType.LookupType:
          if ((targetControl.lookupEntityPrimaryId == 'accountid' && targetControl.attributeName != 'indskr_surgeonaccountzsur') || targetControl.lookupEntityPrimaryId == 'contactid' || targetControl.lookupEntityPrimaryId == 'productid') {
            this._handleNewPageSelectFieldLookup(targetControl);
          } else if (targetControl.viewType && targetControl.viewType == ViewType.LISTVIEW_UI && targetControl.attributeName != 'indskr_surgeonaccountzsur') {
            this._handleNewPageSelectFieldLookup(targetControl);
          } else {
            this._displayLookupSearchPopover(id, event, eventName, targetControl);
          }
          this._isClicked[targetControl.attributeName] = true;
          break;
        case ControlDataType.BooleanType:
          this._handleBooleanFormField(id, event, eventName, targetControl);
          this._isClicked[targetControl.attributeName] = true;
          break;
        case ControlDataType.PicklistType:
          this._handleDropdownListFormField(id, event, eventName, targetControl);
          this._isClicked[targetControl.attributeName] = true;
          break;
        case ControlDataType.DateTimeType:
          // this._handleDateTimeFormField(id, event, eventName, targetControl);
          // if (targetControl.attributeName == 'omnione_yearofbirth' || targetControl.attributeName == 'omnione_dayandmonthofbirth') {
          //   this._handleDateTimeFormField(id, event, eventName, targetControl);
          //   this._isClicked[targetControl.attributeName] = true;
          // }
          if (targetControl.attributeName != 'indskr_enddate') {
            this.openStartDatePicker(id, event, eventName, targetControl);
            this._isClicked[targetControl.attributeName] = true;
          } else if (targetControl.attributeName == 'indskr_enddate') {
            this.openEndDatePicker(id, event, eventName, targetControl);
            this._isClicked[targetControl.attributeName] = true;
          }
          break;
        case ControlDataType.MultiSelectPicklistType:
          this._handleDropdownMultiSelectListFormField(id, event, eventName, targetControl);
          this._isClicked[targetControl.attributeName] = true;
          break;
      }
    }

  }
  // handler for address edit form - Account CR
  private _handleEditAddressFormFieldEvent(id, event, eventName) {
    let targetControl: Control;
    for (let tabIdx = 0; tabIdx < this._dynamicFormForAddressEdit.metadata.length; tabIdx++) {
      const tab = this._dynamicFormForAddressEdit.metadata[tabIdx];
      if (tab && tab.controls && tab.controls.length > 0) {
        let foundControl = tab.controls.find(c => c.attributeName == id);
        if (foundControl) {
          targetControl = foundControl;
          break;
        }
      }
    };
    // address edit form has type of _handleInlineInputFormField and _displayLookupSearchPopover. if the address form is changed, it should be updated.
    if (targetControl) {
      this.isHandleEditAddressFormField = true;
      switch (targetControl.dataType) {
        case ControlDataType.MoneyType:
        case ControlDataType.DecimalType:
        case ControlDataType.DoubleType:
        case ControlDataType.IntegerType:
        case ControlDataType.StringType:
        case ControlDataType.MemoType:
          this._handleInlineInputFormField(id, event, eventName, targetControl);
          break;
        case ControlDataType.LookupType:
          this._displayLookupSearchPopover(id, event, eventName, targetControl);
          break;
        case ControlDataType.BooleanType:
          // this._handleBooleanFormField(id, event, eventName, targetControl);
          break;
        case ControlDataType.PicklistType:
          // this._handleDropdownListFormField(id, event, eventName, targetControl);
          break;
        case ControlDataType.DateTimeType:
          // this._handleDateTimeFormField(id, event, eventName, targetControl);
          break;
        case ControlDataType.MultiSelectPicklistType:
          // this._handleDropdownMultiSelectListFormField(id, event, eventName, targetControl);
          break;
      }
    }

  }

  private _handleInlineInputFormField(id, event, eventName, control: Control) {
    if (eventName == 'input_value_confirm' && control) {
      let value;
      if (event.value) {
        value = event.value;
      } else if (event.target) {
        value = event.target.value;
      }
      if (control.dataType == ControlDataType.MoneyType || control.dataType == ControlDataType.DoubleType || control.dataType == ControlDataType.IntegerType) {
        value = parseInt(value);
      } else if (control.dataType == ControlDataType.DecimalType) {
        value = parseFloat(value);
      }
      if (!this.isHandleEditAddressFormField) {
        this._setAttributeValue(control, value, false);
      } else this._setExistingAttributeValue(control, value);
    }
    this.isHandleEditAddressFormField = false;
  }

  private async _displayLookupSearchPopover(id, event, eventName, targetControl: Control) {
    this._initLookupSearchList(targetControl);
    const comparisonValue = !this.isHandleEditAddressFormField ? (targetControl.isCustom ? this._customFormValue : this._currentFormValue) : (targetControl.isCustom ? this._existingCustomFormValue : this._existingFormValue);
    const objName = targetControl.isCustom ? targetControl.schemaName : (targetControl.isCustomAttribute ? targetControl.schemaName : targetControl.attributeName);
    let selectedValue = '';
    if (comparisonValue && comparisonValue.hasOwnProperty('lookupfields')) {
      let idx = comparisonValue['lookupfields'].findIndex(a => a.name == objName && !(a['isDeleted'] && a['isDeleted'] === true));
      if (idx >= 0) {
        selectedValue = comparisonValue['lookupfields'][idx].id;
      }
    }
    let labelText: string = this._getDisplayText(targetControl.displayNames, targetControl.isCustom);
    let filter;
    if (targetControl.dependsOn) {
      if (!this.isHandleEditAddressFormField) {
        if (this._customFormValue && this._customFormValue.hasOwnProperty('lookupfields')) {
          filter = this._customFormValue['lookupfields'].find(x => x.name == targetControl.dependsOn).id || '';
        }
      } else {
        if (this._existingCustomFormValue && this._existingCustomFormValue.hasOwnProperty('lookupfields')) {
          filter = this._existingCustomFormValue['lookupfields'].find(x => x.name == targetControl.dependsOn).id || '';
        }
      }
      if (this.changeRequest && targetControl.lookupEntityPrimaryId == "indskr_lu_districtid") {
        filter = this._stateIdBasedOnCity ? this._stateIdBasedOnCity : '';
      }
    }
    if (this.changeRequest && targetControl.lookupEntityPrimaryId == 'indskr_lu_cityid') {
      let idx;
      if (!this.isHandleEditAddressFormField) {
        if (this._customFormValue && this._customFormValue.hasOwnProperty('lookupfields')) {
          idx = this._customFormValue['lookupfields'].findIndex(a => a.name == "indskr_Country_LU");
          if (idx == -1) this.dynamicFormsService.hasCityDataBasedOnCountry = false;
        } else this.dynamicFormsService.hasCityDataBasedOnCountry = false;
      } else {
        if (this._existingCustomFormValue && this._existingCustomFormValue.hasOwnProperty('lookupfields')) {
          idx = this._existingCustomFormValue['lookupfields'].findIndex(a => a.name == "indskr_Country_LU");
          if (idx == -1) this.dynamicFormsService.hasCityDataBasedOnCountry = false;
        } else this.dynamicFormsService.hasCityDataBasedOnCountry = false;
      }
    }
    // if (targetControl.attributeName == 'indskr_shipto') {
    //   let selectedAccount;
    //   if (this._currentFormValue && this._currentFormValue.hasOwnProperty('lookupfields')) {
    //     let idx = this._currentFormValue['lookupfields'].findIndex(a => a.name == "indskr_accountnamesoldto");
    //     if (idx >= 0) {
    //       selectedAccount = this._currentFormValue['lookupfields'][idx].id;
    //     }
    //   }
    //   if(selectedAccount) {
    //     filter = `<filter type="and">
    //     <condition attribute="indskr_customer" operator="eq" value="${selectedAccount}"/>
    //     </filter>`
    //   }
    // }
    if(targetControl.dataType == ControlDataType.LookupType && targetControl.filterby && targetControl.filterFieldTargetLookupEntity){
      let selectedFilterBy;
      if (this._currentFormValue && this._currentFormValue.hasOwnProperty('lookupfields')) {
        let idx = this._currentFormValue['lookupfields'].findIndex(a => a.name == targetControl.filterby && !(a['isDeleted'] && a['isDeleted'] === true));
        if (idx >= 0) {
          selectedFilterBy = this._currentFormValue['lookupfields'][idx].id;
        }
      }
      // else if(this._currentFormValue){
      //   selectedFilterBy = this._currentFormValue[targetControl.filterby];
      // }
      if(selectedFilterBy){
        filter = `<filter type="and">
        <condition attribute="${targetControl.filterFieldTargetLookupEntity}" operator="eq" value="${selectedFilterBy}"/>
        </filter>`
      }
    }

    let popoverListDetail: IndDynamicFormLookupListDetailModel = {
      id: 'lookup_search_popover',
      title: labelText,
      targetControl: targetControl,
      searchFilter: filter,
      isSearchEnabled: true,
      selectedValue: selectedValue,
      searchInputPlaceholder: this.translate.instant('SEARCH'),
      showUnselectOption: true,
      unselectOptionText: this.translate.instant('SELECT'),
      isOneKeyChangeRequest: this.changeRequest,
      callbackEvent: (data: any) => this._handleLookupComponentCallback(data),
    };
    let cssClassName = 'lookup-popover-list-view';
    if (this.device.isNativeApp) {
      cssClassName = 'native-lookup-popover-list-view';
    }
    this._lookupPopover = await this.popoverCtrl.create({
      component: DynamicFormLookupPopoverComponent,
      componentProps: { viewData: popoverListDetail },
      cssClass: cssClassName,
      backdropDismiss: true,
      event: this.device.isNativeApp ? event : undefined
    });
    this._activeLookupSearchControl = targetControl;
    this._lookupPopover.onDidDismiss().then(async (data) => {
      if(targetControl.isRequired && this.dynamicFormsService.getFormFieldType(targetControl) !== FormFieldType.INLINE_INPUT){
        const comparisonValue = !this.isHandleEditAddressFormField ? (targetControl.isCustom ? this._customFormValue : this._currentFormValue) : (targetControl.isCustom ? this._existingCustomFormValue : this._existingFormValue);
        const objName = targetControl.isCustom ? targetControl.schemaName : (targetControl.isCustomAttribute ? targetControl.schemaName : targetControl.attributeName);
        let newSelectedValue = '';
        if (comparisonValue && comparisonValue.hasOwnProperty('lookupfields')) {
          let idx = comparisonValue['lookupfields'].findIndex(a => a.name == objName);
          if (idx >= 0) {
            newSelectedValue = comparisonValue['lookupfields'][idx].id;
          }
        }
        this._validateMandatoryField(id,null,{value: newSelectedValue},false);
      }
      this._lookupPopover = undefined;
      this._activeLookupSearchControl = undefined;
      this.dynamicFormsService.lookupSearchData = [];
      this.dynamicFormsService.lookupSearchInProgress = false;
    });
    this._lookupPopover.present();
  }

  private _handleLookupComponentCallback(data) {
    if (data && data.selectedItem && this._activeLookupSearchControl) {
      const comparisonValue = !this.isHandleEditAddressFormField ? (this._activeLookupSearchControl.isCustom ? this._customFormValue : this._currentFormValue) : (this._activeLookupSearchControl.isCustom ? this._existingCustomFormValue : this._existingFormValue);
      const objName = this._activeLookupSearchControl.isCustom ? this._activeLookupSearchControl.schemaName : (this._activeLookupSearchControl.isCustomAttribute ? this._activeLookupSearchControl.schemaName : this._activeLookupSearchControl.attributeName);
      const schemaName = this._activeLookupSearchControl.isCustomAttribute ? this._activeLookupSearchControl.schemaName : this._activeLookupSearchControl.attributeName;
      let codIdOnekey: string = data && data.selectedItem && data.selectedItem.codIdOnekey ? data.selectedItem.codIdOnekey || '' : '';
      if(schemaName == 'indskr_district') codIdOnekey = data && data.selectedItem && data.selectedItem.countyCode ? data.selectedItem.countyCode || '' : '';
      let selectedValue = '';
      let isDuplicateData: boolean = false;
      if (comparisonValue && comparisonValue.hasOwnProperty('lookupfields')) {
        let idx = comparisonValue['lookupfields'].findIndex(a => a.name == objName && !(a['isDeleted'] && a['isDeleted'] == true));
        if (idx >= 0) {
          selectedValue = comparisonValue['lookupfields'][idx].id;
        }
      }
      if (!(comparisonValue && selectedValue == data.selectedItem.id)) {
        // Keeping this clearing dependOn control part for custom control only for now but can be extended to all controls once dependsOn is implemented for configured controls
        if (this._activeLookupSearchControl.isCustom) {
          if (!this.isHandleEditAddressFormField) {
            this._clearAttributeValue(this._activeLookupSearchControl);
          } else this._clearEditAddressFormAttributeValue(this._activeLookupSearchControl);
        }
        //If the filterby field is reset, then clear the lookup fields depending on it
        if (this._currentFormValue && this._currentFormValue['lookupfields']) {
          this._currentFormValue['lookupfields'].forEach(lookup => {
            if(!(lookup['isDeleted'] && lookup['isDeleted']==true)){
              let lookupControl: Control = this._getTargetControl(lookup.name);
              if(!lookupControl)
                lookupControl = this._getTargetControlbySchemaName(lookup.name);
              if(lookupControl && lookupControl.filterby && lookupControl.filterby == objName){
                this._clearAttributeValue(lookupControl);
              }
            }
          });
        }
        
        if (this.dynamicFormsService.storedLinkedEntityValues.length == 0) {
          if (!this.isHandleEditAddressFormField) {
            this._setAttributeStringValue(this._activeLookupSearchControl.attributeName, data.selectedItem.name);
            this._setAttributeValue(this._activeLookupSearchControl, data.selectedItem.id, false, codIdOnekey, true, data.selectedItem.otherFieldsToInclude);

            if (Array.isArray(data.afterActions)) {
              for (const afterAction of data.afterActions) {
                if (afterAction.action === 'CLEAR' && afterAction.targetFieldName) {
                  this._clearField(afterAction.targetFieldName);
                }
              }
            }
          } else {
            this._setExistingAttributeStringValue(this._activeLookupSearchControl.attributeName, data.selectedItem.name);
            this._setExistingAttributeValue(this._activeLookupSearchControl, data.selectedItem.id, codIdOnekey);
          }
          // Auto-populated Country and State based on the selected City
          if (this.changeRequest && this._activeLookupSearchControl.lookupEntityPrimaryId == 'indskr_lu_cityid') {
            this._setAddressWithSelectedCity(data.selectedItem);
            this._initViewData();
            // To store city data based on selected country id when Country is selected
          } else if (this.changeRequest && this._activeLookupSearchControl.lookupEntityPrimaryId == 'indskr_lu_countryid') {
            let targetControl: Control = this._activeLookupSearchControl;
            let countryId = data.selectedItem.countryId || '';
            if (countryId) {
              this.dynamicFormsService.isSelectedCountry = true; // for checking mandatory field
              this.dynamicFormsService.getLookupResultsForCityBasedOnCountry(countryId, targetControl);
            }
          }
        } else {
          // check the duplicate data with stored linked entity values
          const formLookupEntitySetName = this.formDetail.LookupEntitySetName.toLowerCase() || '';
          const linkedEntityParentName = this.formDetail.linkedEntityParentData?.entity.toLowerCase() || '';
          const controlSchemaName = this._activeLookupSearchControl.schemaName.toLowerCase() || '';
          if(this.dynamicFormsService.storedLinkedEntityValues && this.dynamicFormsService.storedLinkedEntityValues.length > 0) {
            isDuplicateData = this._checkDuplicatData(linkedEntityParentName, formLookupEntitySetName, this.dynamicFormsService.storedLinkedEntityValues, data.selectedItem.id);
          }
          if(isDuplicateData){
            let displayName = this._getDisplayText(this._activeLookupSearchControl.displayNames).toLowerCase();
            if(controlSchemaName.includes('account')) {
              displayName = this.translate.instant('ACCOUNT').toLowerCase();
            } else if(controlSchemaName.includes('contact')) {
              displayName = this.translate.instant('CONTACT_FORM').toLowerCase();
            } else if(controlSchemaName.includes('role')) {
              displayName = this.translate.instant('AFFILIATION_ROLE').toLowerCase();
            } else if(controlSchemaName.includes('special')) {
              displayName = this.translate.instant('SPECIALITY').toLowerCase();
            }
            let notificationText = this.translate.instant('DUPLICATED_DETECTED_MESSAGE_LOOKUPFIELD', {lookupFieldName: displayName});
            this.notificationService.notify(notificationText,'ind-dynamic-form','top',ToastStyle.DANGER);
            if (this._activeLookupSearchControl.isCustom) {
              if (!this.isHandleEditAddressFormField) {
                this._clearAttributeValue(this._activeLookupSearchControl);
              } else this._clearEditAddressFormAttributeValue(this._activeLookupSearchControl);
            }
          } else {
            if (!this.isHandleEditAddressFormField) {
              this._setAttributeStringValue(this._activeLookupSearchControl.attributeName, data.selectedItem.name);
              this._setAttributeValue(this._activeLookupSearchControl, data.selectedItem.id, false, codIdOnekey);
            } else {
              this._setExistingAttributeStringValue(this._activeLookupSearchControl.attributeName, data.selectedItem.name);
              this._setExistingAttributeValue(this._activeLookupSearchControl, data.selectedItem.id, codIdOnekey);
            }
          }
        }
      }
    } else if (data && data.clearValue) {
      if (this._activeLookupSearchControl.isCustom) {
        if (!this.isHandleEditAddressFormField) {
          this._clearAttributeValue(this._activeLookupSearchControl);
        } else this._clearEditAddressFormAttributeValue(this._activeLookupSearchControl);
      }else{
        this._clearAttributeValue(this._activeLookupSearchControl);
      }

      if (Array.isArray(data.afterActions)) {
        for (const afterAction of data.afterActions) {
          if (afterAction.action === 'CLEAR' && afterAction.targetFieldName) {
            this._clearField(afterAction.targetFieldName);
          }
        }
      }

      this._initViewData();
    }
    this._activeLookupSearchControl = undefined;
    this.isHandleEditAddressFormField = false;
  }

  private _clearField(attributeName: string) {
    if (this._currentFormStringValues && this._currentFormStringValues[attributeName]) {
      delete this._currentFormStringValues[attributeName];
      const idxToDel = this._currentFormValue?.lookupfields?.findIndex(i => i.name?.toLowerCase() === attributeName.toLowerCase());
      if (idxToDel >= 0) {
        this._currentFormValue.lookupfields.splice(idxToDel, 1);
      }
    }
  }

  private _initLookupSearchList(targetControl: Control) {
    /*************Edit business information in offline - Non-OneKey contacts*************/
    let query = '';
    if (targetControl && targetControl.lookupQueries) {
      try {
        let filter;
        if (targetControl.dependsOn) {
          if (!this.isHandleEditAddressFormField) {
            if (this._customFormValue && this._customFormValue.hasOwnProperty('lookupfields')) {
              filter = this._customFormValue['lookupfields'].find(x => x.name == targetControl.dependsOn).id || '';
            }
          } else {
            if (this._existingCustomFormValue && this._existingCustomFormValue.hasOwnProperty('lookupfields')) {
              filter = this._existingCustomFormValue['lookupfields'].find(x => x.name == targetControl.dependsOn).id || '';
            }
          }
          if (this.changeRequest && targetControl.lookupEntityPrimaryId == "indskr_lu_districtid") {
            filter = this._stateIdBasedOnCity ? this._stateIdBasedOnCity : '';
          }
        }
        if (this.changeRequest && targetControl.lookupEntityPrimaryId == 'indskr_lu_cityid') {
          let idx;
          if (!this.isHandleEditAddressFormField) {
            if (this._customFormValue && this._customFormValue.hasOwnProperty('lookupfields')) {
              idx = this._customFormValue['lookupfields'].findIndex(a => a.name == "indskr_Country_LU");
              if (idx == -1) this.dynamicFormsService.hasCityDataBasedOnCountry = false;
            } else this.dynamicFormsService.hasCityDataBasedOnCountry = false;
          } else {
            if (this._existingCustomFormValue && this._existingCustomFormValue.hasOwnProperty('lookupfields')) {
              idx = this._existingCustomFormValue['lookupfields'].findIndex(a => a.name == "indskr_Country_LU");
              if (idx == -1) this.dynamicFormsService.hasCityDataBasedOnCountry = false;
            } else this.dynamicFormsService.hasCityDataBasedOnCountry = false;
          }
        }
        // if (targetControl.attributeName == 'indskr_shipto') {
        //   let selectedAccount;
        //   if (this._currentFormValue && this._currentFormValue.hasOwnProperty('lookupfields')) {
        //     let idx = this._currentFormValue['lookupfields'].findIndex(a => a.name == "indskr_accountnamesoldto");
        //     if (idx >= 0) {
        //       selectedAccount = this._currentFormValue['lookupfields'][idx].id;
        //     }
        //   }
        //   if(selectedAccount) {
        //     filter = `<filter type="and">
        //     <condition attribute="indskr_customer" operator="eq" value="${selectedAccount}"/>
        //     </filter>`
        //   }
        // }
        if(targetControl.dataType == ControlDataType.LookupType && targetControl.filterby && targetControl.filterFieldTargetLookupEntity){
          let selectedFilterBy;
          if (this._currentFormValue && this._currentFormValue.hasOwnProperty('lookupfields')) {
            let idx = this._currentFormValue['lookupfields'].findIndex(a => a.name == targetControl.filterby && !(a['isDeleted'] && a['isDeleted'] === true));
            if (idx >= 0) {
              selectedFilterBy = this._currentFormValue['lookupfields'][idx].id;
            }
          }
          // else if(this._currentFormValue){
          //   selectedFilterBy = this._currentFormValue[targetControl.filterby];
          // }
          if(selectedFilterBy){
            filter = `<filter type="and">
            <condition attribute="${targetControl.filterFieldTargetLookupEntity}" operator="eq" value="${selectedFilterBy}"/>
            </filter>`
          }
        } else if (targetControl.dataType == ControlDataType.LookupType && targetControl.filterby && targetControl.targetEntityAttribute) {
          let selectedFilterBy;

          if (this._currentFormValue?.hasOwnProperty('lookupfields')) {
            let idx = this._currentFormValue['lookupfields'].findIndex(a => a.name.toLowerCase() == targetControl.filterby.toLowerCase());
            if (idx >= 0 && this._currentFormValue['lookupfields'][idx].otherFieldsToInclude) {
              selectedFilterBy = this._currentFormValue['lookupfields'][idx].otherFieldsToInclude[targetControl.targetEntityAttribute];
            }
          }
          if (selectedFilterBy) {
            filter = `<filter type="and">
            <condition attribute="${targetControl.targetEntityAttribute}" operator="${targetControl.filterOperator && targetControl.filterOperator === FieldConfigFilterOperatorType.IN ? 'in' : 'eq'}" value="${selectedFilterBy}"/>
            </filter>`;
          }
        }

         if (!this.device.isOffline) {
          this.dynamicFormsService.getLookupResults(query, targetControl, filter, this.changeRequest);
         }
         /*************Edit business information in offline - Non-OneKey contacts*************/
         else if (this.dynamicFormsService.allLookUpFieldsForOfflineEdit.hasOwnProperty(targetControl.attributeName)
            && !_.isEmpty(this.dynamicFormsService.allLookUpFieldsForOfflineEdit[targetControl.attributeName])) {
          this.dynamicFormsService.lookupSearchData = this.dynamicFormsService.allLookUpFieldsForOfflineEdit[targetControl.attributeName];
         } else {
          this.dynamicFormsService.lookupSearchData = [];
         }
        /*************Edit business information in offline - Non-OneKey contacts*************/
      }
      catch (err) {
        console.error(`Error occured while searching lookup results: ${err}`);
      }
    }
    /*************Edit business information in offline - Non-OneKey contacts*************/
  }

  private _getFieldNamesToIncludeInSelectionData(curControl: Control) {
    let fieldNamesToIncludeInSelectionData = [];
    // Get all controls but current one
    // Map for "targetEntityAttribute" field
    if (Array.isArray(this.formDetail.formMetadata.metadata)) {
      for (const formTab of this.formDetail.formMetadata.metadata) {
        if (Array.isArray(formTab.controls)) {
          for (const control of formTab.controls) {
            // Filter for the ones trying to filter by current control value
            if (
              control.filterby && control.targetEntityAttribute
              && control.filterby === curControl.attributeName && control.attributeName !== curControl.attributeName
            ) {
              fieldNamesToIncludeInSelectionData.push(control.targetEntityAttribute);
            }
          }
        }
      }
    }
    return fieldNamesToIncludeInSelectionData.length > 0 ? fieldNamesToIncludeInSelectionData : null;
  }

  private _handleNewPageSelectFieldLookup(control: Control) {
    this._activeLookupSearchControl = control;
    const comparisonValue = control.isCustom ? this._customFormValue : this._currentFormValue;
    const objName = control.isCustom ? control.schemaName : (control.isCustomAttribute ? control.schemaName : control.attributeName);
    let selectedValue = '';
    this.accountService.isOneKeyAffiliatedToAccount = false;
    this.contactService.isOneKeyAffiliatedToContact = false;
    if (comparisonValue && comparisonValue.hasOwnProperty('lookupfields')) {
      let idx = comparisonValue['lookupfields'].findIndex(a => a.name == objName && !(a['isDeleted'] && a['isDeleted'] === true));
      if (idx >= 0) {
        selectedValue = comparisonValue['lookupfields'][idx].id;
      }
    }
    if (control.lookupEntityPrimaryId == 'accountid') {
      this.accountService.selectedFor = AccountSelectedFor.GENERAL_SINGLE_SELECTION;
      this.accountService.accessedAccountListFrom = PageName.DynamicFormComponent;
      if(this.accountService.isOneKeyChangeRequest && (control.attributeName === 'indskr_affiliatedtoaccountid' || control.attributeName === 'indskr_accountid')) {
        this.accountService.isOneKeyAffiliatedToAccount = true;
      }

      // Configs for selections
      let selectionConfigs: SelectionConfig;
      let fieldNamesToIncludeInSelectionData = this._getFieldNamesToIncludeInSelectionData(control);
      if (fieldNamesToIncludeInSelectionData) {
        selectionConfigs = {
          fieldNamesToInclude: fieldNamesToIncludeInSelectionData,
        }

        // Custom logic for Vx MDM flow.
        // TODO: It needs to be enhanced to get this configurations from dynamics
        if (this._DCR_isVxMDMFlow) {
          selectionConfigs.afterActions = [
            {
              selectionType: 'OK',
              action: 'CLEAR',
              targetFieldName: 'indskr_specialty',
            }
          ];
        }
      }

      this.navService.pushWithPageTracking(
        AccountPageComponent,
        PageName.AccountPageComponent,
        {
          'listMode': ComponentViewMode.ADDNEW,
          type: 'PushedAccountSelectionView',
          selectedAccountId: selectedValue,
          accountIdfromIndDynamic: comparisonValue ? comparisonValue.accountid : "",
          selectionConfigs,
          callbackEvent: (data: any) => this._handleLookupComponentCallback(data)
        },
        PageName.DynamicFormComponent, { animate: false }, null);
    } else if (control.lookupEntityPrimaryId == 'contactid') {
      // this.UIService.prevView = this.UIService.activeView;
      // this.UIService.activeView = '';
      this.contactService.contactPageMode = ComponentViewMode.SELECTION;
      this.contactService.accessedContactListFrom = PageName.DynamicFormComponent;
      if(this.contactService.isOneKeyChangeRequest && (control.attributeName === 'indskr_contactid' || control.attributeName === 'indskr_relatedcontactid')) {
        this.contactService.isOneKeyAffiliatedToContact = true;
      }
      this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, { type: 'PushedContactSelectionView', selectedContactId: selectedValue, callbackEvent: (data: any) => this._handleLookupComponentCallback(data) }, PageName.DynamicFormComponent, { animate: false }, null);
    } else if (control.lookupEntityPrimaryId == 'productid') {
      this._activeProductSelectControl = control;
      this._handleProductSelect(selectedValue);
    }
    else if(control.dataType == ControlDataType.LookupType && control.viewType){
      this._activeMainTemplateLookupSelectControl = control;
      this._handleMainTemplateSelectWithFilter(control, selectedValue);
    }
    this._isClicked[control.attributeName] = true;
  }

  private async getProductByLookupQueries(fetchXml) {
    let setBookingProducts = [];
    let maximumCount = 5000;
    fetchXml = fetchXml.replace(`count="{0}"`, `count="`+maximumCount+`"`);
    try {
      this.uiService.displayLoader();
      let data = await this.dynamics.executeFetchQueryWithPageNumber('products', fetchXml, 0);
      if(data) {
        data = data?.value ? data.value : data;
        data.map((rawJSON , index) => {
          let brand = new Brand(rawJSON , index);
          setBookingProducts.push(brand); //indexed because the data from the backend does not assign priorities / sequence!!!
        });
        this.uiService.dismissLoader();
      } else {
        console.error('No response from getProductByLookupQueries');
        this.uiService.dismissLoader();
      }
    } catch (error) {
      this.uiService.dismissLoader();
      console.error(error);
    }
    return setBookingProducts;
  }

   private async getSelectedSetBookingProduct() {
    let selectedProducts: MainCardViewDataModel[] = [];
    const foundControl = this._getTargetControlbyLookupEtity('indskr_setbookingproducts');
    if(this.linkedEntityValues && this.linkedEntityValues[foundControl.attributeName]) {
      let productId: string[] = [];
      let fetchXml = fetchQueries.setBooking.fetchSelectedKitBookingProducts;
      this.linkedEntityValues[foundControl.attributeName].forEach((brand) => {
        let id = '';
        if(brand.rawObjData) {
          if(brand.rawObjData['indskr_setbookingproduct.indskr_product']) id = brand.rawObjData['indskr_setbookingproduct.indskr_product'];
          else if(brand.rawObjData['formStringValue'] && brand.rawObjData['formValue']['lookupfields']) {
            id = brand.rawObjData['formValue']['lookupfields'].find(field => field.entity=='products')?.id;
          }
        }
        productId.push(id);
      })
      let productIDValues = ''
      productId.forEach(p => { productIDValues += '<value>' + p + '</value>' })
      fetchXml = fetchXml.replace('{productIDs}', productIDValues);
      let selectedProductsRaw = await this.getProductByLookupQueries(fetchXml);
      if(selectedProductsRaw && selectedProductsRaw.length>0) {
        selectedProductsRaw.forEach(raw => {
          let qty = 1;
          let isSelected: boolean = false;
          let selectedProduct
          if(this.linkedEntityValues && this.linkedEntityValues[foundControl.attributeName]) { 
            selectedProduct = this.linkedEntityValues[foundControl.attributeName].find(sp => {
              if(sp.rawObjData && sp.rawObjData['indskr_setbookingproduct.indskr_product'] == raw.ID) {
                qty = sp.rawObjData['indskr_setbookingproduct.indskr_quantity'] ?? 1;
                return sp;
              }
              else if(sp.rawObjData && sp.rawObjData['formValue'] && sp.rawObjData['formValue']['lookupfields']) {
                let data = sp.rawObjData['formValue']['lookupfields'].find((lf) => lf.entity == 'products');
                if(data && data.id == raw.ID) {
                  qty = sp.rawObjData['formValue']['indskr_quantity'] ?? 1;
                  return sp;
                }
              }
            });
            isSelected = !!selectedProduct;
          }
          let viewData: MainCardViewDataModel = {
            id: raw.ID,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: true,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isSelected,
            endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
            endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
            primaryTextRight: raw.name,
            showArrow: false,
            arrowType: '',
            isQtyControl: true,
            qty: qty
          }
          if(selectedProduct.linkedEntityExistingDataId) viewData['linkedEntityExistingDataId'] = selectedProduct.linkedEntityExistingDataId;
          if(selectedProduct.linkedEntityAddedNewDataId) viewData['linkedEntityAddedNewDataId'] = selectedProduct.linkedEntityAddedNewDataId;
          let secInfoParams = [];
          if(raw.productnumber) secInfoParams.push(`${raw.productnumber}`);
          if(raw.indskr_recordtypeoption != null && raw.indskr_recordtypeoption != undefined) {
            let type = '';
            switch(raw.indskr_recordtypeoption) {
              case ProductRecordType.BILL_OF_MATERIAL:
                type = 'Bill Of Material';
                break;
              case ProductRecordType.SURGEON_PREFERENCE:
                type = 'Surgeon Preference';
                break;
              default:
                type = ''
                break;
            }
            secInfoParams.push(`${type}`);
          }
          if(raw.itemCount) secInfoParams.push("Line items - "+raw.itemCount);
          if(raw.indskr_organisationcode) secInfoParams.push(`Org Code - ${raw.indskr_organisationcode}`);
          viewData.secondaryTextRight = secInfoParams.join(", ");
          selectedProducts.push(viewData);
        })
      }
    }
    
    return selectedProducts;
  }

  private async _getAllSetBookingProducts(productFetchXml, isBOM:boolean = false, searchKeyWord) {
    if(!productFetchXml) return;
    let allBrandProducts = this.brandService.brands;
    if(isBOM || searchKeyWord) {
      let filter = `<filter type="and">
          {bomFilter}
          {searchFilter}
        </filter>`;
      let bomFilter = `<condition attribute="indskr_recordtypeoption" operator="eq" value="548910000" />`
      let searchFilter = `
        <filter type="or">
          <condition attribute="name" operator="like" value="%${searchKeyWord}%" />
          <condition attribute="productnumber" operator="like" value="%${searchKeyWord}%" />
        </filter>
      `
      filter = isBOM ? filter.replace('{bomFilter}', bomFilter) : filter.replace('{bomFilter}', '');
      filter = searchKeyWord ? filter.replace('{searchFilter}', searchFilter) : filter.replace('{searchFilter}', '');
      const entityOpenTagIndex = productFetchXml.indexOf('<entity');
      const entityCloseTagIndex = productFetchXml.indexOf('>', entityOpenTagIndex);
      if (entityOpenTagIndex > -1) {
        const entityCloseTagIndex = productFetchXml.indexOf('>', entityOpenTagIndex);
        if (entityCloseTagIndex > -1) {
          productFetchXml = productFetchXml.slice(0, entityCloseTagIndex + 1) + filter + productFetchXml.slice(entityCloseTagIndex + 1);
        }
      }
    }   
    allBrandProducts = await this.getProductByLookupQueries(productFetchXml);
    return allBrandProducts;
  }

  private async _getSurgeonAccountPrefProducts(surgeonaccountzsurValue, searchKeyWord) {
    // let allBrandProducts = this.brandService.brands;
    let onlySurgeonProducts: Brand[] = [];
    if(surgeonaccountzsurValue) {
      let fetchXml = fetchQueries.setBooking.fetchAllProductsForProductTypeOnlySurgeon;
      let filter = `<filter type="and">
            <condition attribute="indskr_recordtypeoption" operator="eq" value="548910001" />
            <condition attribute='indskr_account' operator="eq" value='${surgeonaccountzsurValue}'/>
            {searchFilter}
            </filter>`;
      let searchFilter = `
        <filter type="or">
          <condition attribute="name" operator="like" value="%${searchKeyWord}%" />
          <condition attribute="productnumber" operator="like" value="%${searchKeyWord}%" />
        </filter>
      `
      filter = searchKeyWord ? filter.replace('{searchFilter}', searchFilter) : filter.replace('{searchFilter}', '');
      const entityOpenTagIndex = fetchXml.indexOf('<entity');
      const entityCloseTagIndex = fetchXml.indexOf('>', entityOpenTagIndex);
      if (entityOpenTagIndex > -1) {
        const entityCloseTagIndex = fetchXml.indexOf('>', entityOpenTagIndex);
        if (entityCloseTagIndex > -1) {
          fetchXml = fetchXml.slice(0, entityCloseTagIndex + 1) + filter + fetchXml.slice(entityCloseTagIndex + 1);
        }
      }
      onlySurgeonProducts = await this.getProductByLookupQueries(fetchXml);
      // if(!_.isEmpty(allBrandProducts) && !_.isEmpty(onlySurgeonProducts)) {
      //   const uniqueArr = onlySurgeonProducts.filter(surProd => !allBrandProducts.some(allP => allP.ID == surProd.ID));
      //   if(!_.isEmpty(uniqueArr)) allBrandProducts = [...allBrandProducts, ...uniqueArr];
      // }
    }
    return onlySurgeonProducts;
  }

  private async _fetchSetBookingProduct(setBookingProductformDetail) {
    let allBrandProducts = this.brandService.brands;
    // let onlySurgeonProducts: Brand[] = [];
    this.selectedFilterFromMainToolTemplate = '';
    this.searchKeyWordFromMainToolTemplate = '';
    let productDynamicForm: DynamicForm = setBookingProductformDetail.formMetadata;
    // let surgeonaccountzsurValue = setBookingProductformDetail.formEntityObject && setBookingProductformDetail.formEntityObject['surgeonaccountzsurValue'] ? setBookingProductformDetail.formEntityObject['surgeonaccountzsurValue'] : '';
    // let productFetchXml = '';
    if (productDynamicForm) {
      const productControl = this._getTargetControl('indskr_product', productDynamicForm);
      this._activeProductSelectControl = productControl;
      // if(!_.isEmpty(productControl) && productControl.lookupQueries && productControl.lookupQueries['fetchXml']) {
      //   productFetchXml = productControl.lookupQueries['fetchXml'];
      // }
    }
    // if(surgeonaccountzsurValue) {
    //   let fetchXml = fetchQueries.setBooking.fetchAllProductsForProductTypeOnlySurgeon;
    //   let filter = `<filter type="and">
    //         <condition attribute="indskr_recordtypeoption" operator="eq" value="548910001" />
    //         <condition attribute='indskr_account' operator="eq" value='${surgeonaccountzsurValue}'/>
    //         </filter>`;
    //   const entityOpenTagIndex = fetchXml.indexOf('<entity');
    //   const entityCloseTagIndex = fetchXml.indexOf('>', entityOpenTagIndex);
    //   if (entityOpenTagIndex > -1) {
    //     const entityCloseTagIndex = fetchXml.indexOf('>', entityOpenTagIndex);
    //     if (entityCloseTagIndex > -1) {
    //       fetchXml = fetchXml.slice(0, entityCloseTagIndex + 1) + filter + fetchXml.slice(entityCloseTagIndex + 1);
    //     }
    //   }
    //   onlySurgeonProducts = await this.getProductByLookupQueries(fetchXml);
    //   if(!_.isEmpty(allBrandProducts) && !_.isEmpty(onlySurgeonProducts)) {
    //     const uniqueArr = onlySurgeonProducts.filter(surProd => !allBrandProducts.some(allP => allP.ID == surProd.ID));
    //     if(!_.isEmpty(uniqueArr)) allBrandProducts = [...allBrandProducts, ...uniqueArr];
    //   }
    // }
      let popoverDataSetBookingProduct: { text: string; value: string; items: any; handler: (selectedItem: any, item: any, itemRef: any, keyword: string) => void; }[] = [];
      const productTypeControl = this._getTargetControl('indskr_producttype', productDynamicForm);
      let dropdownListDetail: IndDropdownListDetailModel = this.dynamicFormsService.getDropdownListViewFromOptionSet(productTypeControl.optionSet, '', this._activeLanguageCode);
      if(dropdownListDetail && dropdownListDetail.data && dropdownListDetail.data.length>0) {
        const values = Object.values(SetBookingProductFilter);
        dropdownListDetail.data = dropdownListDetail.data.map(item => {
          if(values.some(value => item.title.toLowerCase().includes(value.toLowerCase()))) return item;
        })        
      }
      dropdownListDetail.data = dropdownListDetail.data.filter(item => !_.isEmpty(item));
      popoverDataSetBookingProduct = [{
          text: '',
          value: '',
          items: dropdownListDetail.data.map((item) => {
            let option = {
              text: item.title,
              value: item.id
            }
            return option;
          }),
          handler: async (selectedItem, item, itemRef, keyword) => {
            this.searchKeyWordFromMainToolTemplate = keyword;
            itemRef.parent.items[0].value = '';
            item.value =
              item.value === selectedItem.value ? '' : selectedItem.value;
            if (item.value != '') {
              this.selectedFilterFromMainToolTemplate = item.value;
              
              await this._handleKitBookingComponentSearch(this.searchKeyWordFromMainToolTemplate, popoverDataSetBookingProduct, setBookingProductformDetail);;
            }
          },
        }
      ]
      let listDetail = await this._getListDefailForSetBookingProduct(allBrandProducts, popoverDataSetBookingProduct, setBookingProductformDetail);
      this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, formData: setBookingProductformDetail }, PageName.MainToolTemplateComponent);
  }

  private async _handleKitBookingComponentSearch(keyword, popoverDataSetBookingProduct, setBookingProductformDetail) {
    this.searchKeyWordFromMainToolTemplate = keyword;
    let filteredBrandProducts = [];
    let productDynamicForm: DynamicForm = setBookingProductformDetail.formMetadata;
    let surgeonaccountzsurValue = setBookingProductformDetail.formEntityObject && setBookingProductformDetail.formEntityObject['surgeonaccountzsurValue'] ? setBookingProductformDetail.formEntityObject['surgeonaccountzsurValue'] : '';
    let productFetchXml = '';
    if (productDynamicForm) {
      const productControl = this._getTargetControl('indskr_product', productDynamicForm);
      this._activeProductSelectControl = productControl;
      if(!_.isEmpty(productControl) && productControl.lookupQueries && productControl.lookupQueries['fetchXml']) {
        productFetchXml = productControl.lookupQueries['fetchXml'];
      }
    }
    if(this.selectedFilterFromMainToolTemplate == 548910000) {
      if(productFetchXml) filteredBrandProducts = await this._getAllSetBookingProducts(productFetchXml, true, keyword);
      else this.notificationService.notify('Product Condition is Not Set. Please contact the system administrator', 'DynamicFormComponent', 'top', ToastStyle.INFO, 3000);
    }
    else if(this.selectedFilterFromMainToolTemplate == 548910001) {
      if(!surgeonaccountzsurValue) this.notificationService.notify('Please select surgeon account', 'DynamicFormComponent', 'top', ToastStyle.INFO, 3000);
      else filteredBrandProducts = await this._getSurgeonAccountPrefProducts(surgeonaccountzsurValue, keyword);
    }
    else if(this.selectedFilterFromMainToolTemplate == 548910002 || (!this.selectedFilterFromMainToolTemplate && keyword)) {
      if(productFetchXml) filteredBrandProducts = await this._getAllSetBookingProducts(productFetchXml, false, keyword);
      else this.notificationService.notify('Product Condition is Not Set. Please contact the system administrator', 'DynamicFormComponent', 'top', ToastStyle.INFO, 3000);
    }
    await this._getListDefailForSetBookingProduct(filteredBrandProducts, popoverDataSetBookingProduct, setBookingProductformDetail, true).then((updatedListDetail) => {
      this.events.publish('updateMainToolTemplateList', updatedListDetail);
    });
  }

  private async _getListDefailForSetBookingProduct(allBrandProducts, popoverDataSetBookingProduct, setBookingProductformDetail, isFilter:boolean=false): Promise<MainToolTemplateDetail> {
    let selectedProducts:MainCardViewDataModel[] = []
    if(isFilter) {
      selectedProducts = allBrandProducts.map((brand: Brand) => {
          let isSelected = false;
          let selectedProduct;
          let qty = 1;
          const foundControl = this._getTargetControlbyLookupEtity('indskr_setbookingproducts');
          if(this.linkedEntityValues && this.linkedEntityValues[foundControl.attributeName]) {
            selectedProduct = this.linkedEntityValues[foundControl.attributeName].find(sp => {
              if(sp.rawObjData && sp.rawObjData['indskr_setbookingproduct.indskr_product'] == brand.ID) {
                qty = sp.rawObjData['indskr_setbookingproduct.indskr_quantity'] ?? 1;
                return sp;
              }
              else if(sp.rawObjData && sp.rawObjData['formValue'] && sp.rawObjData['formValue']['lookupfields']) {
                let data = sp.rawObjData['formValue']['lookupfields'].find((lf) => lf.entity == 'products');
                if(data && data.id == brand.ID) {
                  qty = sp.rawObjData['formValue']['indskr_quantity'] ?? 1;
                  return sp;
                }
              }
            });
            isSelected = !!selectedProduct;
          }
          let viewData: MainCardViewDataModel = {
            id: brand.ID,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: true,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isSelected,
            endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
            endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
            primaryTextRight: brand.name,
            showArrow: false,
            arrowType: '',
            isQtyControl: true,
            qty: qty
          }
          if(selectedProduct && selectedProduct.linkedEntityExistingDataId) viewData['linkedEntityExistingDataId'] = selectedProduct.linkedEntityExistingDataId;
          if(selectedProduct && selectedProduct.linkedEntityAddedNewDataId) viewData['linkedEntityAddedNewDataId'] = selectedProduct.linkedEntityAddedNewDataId;
          let secInfoParams = [];
          if(brand.productnumber) secInfoParams.push(`${brand.productnumber}`);
          if(brand.indskr_recordtypeoption != null && brand.indskr_recordtypeoption != undefined) {
            let type = '';
            switch(brand.indskr_recordtypeoption) {
              case ProductRecordType.BILL_OF_MATERIAL:
                type = 'Bill Of Material';
                break;
              case ProductRecordType.SURGEON_PREFERENCE:
                type = 'Surgeon Preference';
                break;
              default:
                type = ''
                break;
            }
            secInfoParams.push(`${type}`);
          }
          if(brand.itemCount) secInfoParams.push("Line items - "+brand.itemCount);
          if(brand.indskr_organisationcode) secInfoParams.push(`Org Code - ${brand.indskr_organisationcode}`);
          viewData.secondaryTextRight = secInfoParams.join(", ");
          return viewData;
        })
    } else selectedProducts = await this.getSelectedSetBookingProduct();
    let listForSetBookingProduct: MainToolTemplateDetail = {
      title: this.translate.instant('PRODUCTS'),
      dividerTitle: isFilter ? this.translate.instant('RESULTS_CAP') : this.translate.instant('ALL_PRODUCTS_CAP'),
      isSearchEnabled: true,
      showLeftHeaderButton: true,
      leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
      leftHeaderBtnText: this.translate.instant('CANCEL'),
      showRightHeaderButton: true,
      rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
      rightHeaderBtnText: this.translate.instant('DONE'),
      orderByPropertyName: 'primaryTextRight',
      searchTitle: "",
      isListSelectionEnabled: true,
      listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
      passOverLabelCssToMainCard: 'kitBookingSelection',
      isFilterPopoverActive: true,
      navOptions: { animate: false },
      filterPopoverData: popoverDataSetBookingProduct,
      isSelectedSectionCollapsible: true,
      isDetailPageEnabled: true,
      detailPageFetchingQuery: fetchQueries.setBooking.fetchSetBookingProductDetails,
      isFor: 'setBookingProducts',
      enableKeyControlArea: true,
      eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail, formDetail: DynamicFormComponentModel) => this._handleSetBookingProductComponentEvent(data, eventTarget, refData, setBookingProductformDetail),
      // searchHandler: (text: string) => this._handleProductComponentSearch(text, allBrandProducts),
      searchButtonHandler: (text: string) => this._handleKitBookingComponentSearch(text, popoverDataSetBookingProduct, setBookingProductformDetail),
      data: selectedProducts
      // data: allBrandProducts.map((brand: Brand) => {
      //   let isSelected = false;
      //   let selectedProduct;
      //   let qty = 1;
      //   const foundControl = this._getTargetControlbyLookupEtity('indskr_setbookingproducts');
      //   if(this.linkedEntityValues && this.linkedEntityValues[foundControl.attributeName]) {
      //     selectedProduct = this.linkedEntityValues[foundControl.attributeName].find(sp => {
      //       if(sp.rawObjData && sp.rawObjData['indskr_setbookingproduct.indskr_product'] == brand.ID) {
      //         qty = sp.rawObjData['indskr_setbookingproduct.indskr_quantity'] ?? 1;
      //         return sp;
      //       }
      //       else if(sp.rawObjData && sp.rawObjData['formValue'] && sp.rawObjData['formValue']['lookupfields']) {
      //         let data = sp.rawObjData['formValue']['lookupfields'].find((lf) => lf.entity == 'products');
      //         if(data && data.id == brand.ID) {
      //           qty = sp.rawObjData['formValue']['indskr_quantity'] ?? 1;
      //           return sp;
      //         }
      //       }
      //     });
      //     isSelected = !!selectedProduct;
      //   }
      //   let viewData: MainCardViewDataModel = {
      //     id: brand.ID,
      //     primaryTextLeft: '',
      //     secondaryTextLeft: '',
      //     showEndIcon: true,
      //     mainItemCssClass: 'selector-item',
      //     isItemSelectedForSelectionView: isSelected,
      //     endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
      //     endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
      //     primaryTextRight: brand.name,
      //     showArrow: false,
      //     arrowType: '',
      //     isQtyControl: true,
      //     qty: qty
      //   }
      //   if(selectedProduct && selectedProduct.linkedEntityExistingDataId) viewData['linkedEntityExistingDataId'] = selectedProduct.linkedEntityExistingDataId;
      //   if(selectedProduct && selectedProduct.linkedEntityAddedNewDataId) viewData['linkedEntityAddedNewDataId'] = selectedProduct.linkedEntityAddedNewDataId;
      //   let secInfoParams = [];
      //   if(brand.productnumber) secInfoParams.push(`${brand.productnumber}`);
      //   if(brand.indskr_recordtypeoption != null && brand.indskr_recordtypeoption != undefined) {
      //     let type = '';
      //     switch(brand.indskr_recordtypeoption) {
      //       case ProductRecordType.BILL_OF_MATERIAL:
      //         type = 'Bill Of Material';
      //         break;
      //       case ProductRecordType.SURGEON_PREFERENCE:
      //         type = 'Surgeon Preference';
      //         break;
      //       default:
      //         type = ''
      //         break;
      //     }
      //     secInfoParams.push(`${type}`);
      //   }
      //   if(brand.itemCount) secInfoParams.push("Line items - "+brand.itemCount);
      //   if(brand.indskr_organisationcode) secInfoParams.push(`Org Code - ${brand.indskr_organisationcode}`);
      //   viewData.secondaryTextRight = secInfoParams.join(", ");
      //   return viewData;
      // })
    };
    return listForSetBookingProduct;
  }

  private async _handleProductSelect(selectedId: string) {
    if (this.brandService.brands && this.brandService.brands.length != 0) {
      // const allBrandProducts = (surgeonaccountzsurValue) ? this.brandService.brands.filter(p=> p.indskr_account == surgeonaccountzsurValue) : this.brandService.brands;
      let allBrandProducts = this.brandService.brands;
      if (this.formDetail && this.formDetail.LookupEntitySetName == 'indskr_setbookingproducts' && this.formDetail.formMetadata && this.formDetail.formMetadata.metadata) {
        const productControl = this._getTargetControl('indskr_product');
        if(!_.isEmpty(productControl) && productControl.lookupQueries && productControl.lookupQueries['fetchXml']) {
          let fetchXml = productControl.lookupQueries['fetchXml'];
          let surgeonaccountzsurValue;
          let isValid: boolean = true;
          if((this.formDetail.formEntityObject && this.formDetail.formEntityObject['indskr_setbookingproduct.indskr_producttype'] && this.formDetail.formEntityObject['indskr_setbookingproduct.indskr_producttype'] != 548910003)
            || (this._currentFormStringValues && this._currentFormStringValues['indskr_producttype'] && this._currentFormStringValues['indskr_producttype'] != 'All Products')) {
              let filter = `<filter type="and">
              <condition attribute="indskr_recordtypeoption" operator="eq" value={attrValue} />
              {accountCondition}
              </filter>`;
              if(this.formDetail && this.formDetail.formEntityObject && this.formDetail.formEntityObject['surgeonaccountzsurValue']){
                surgeonaccountzsurValue = this.formDetail.formEntityObject['surgeonaccountzsurValue'];
              }
              // Do not shorten, or merge the if conditions. It is written based on order.
              // Eg. Even though the form has the already saved value (case 4~6), it should fall into case 1~3 if the user selects the new value.
              // If it is "only Surgeon", Ignore dynamics query, but Fetching all products and add the filter
              if(this._currentFormStringValues && this._currentFormStringValues['indskr_producttype'] == 'All Products') {
                // Case1: if selected product type is All
                filter = '';
              } else if(this._currentFormStringValues && this._currentFormStringValues['indskr_producttype'] == 'Only BOM') {
                // Case2: if selected product type is ONLY BOM
                filter = filter.replace(`{attrValue}`, '"548910000"').replace(`{accountCondition}`, '');
              } else if(this._currentFormStringValues && this._currentFormStringValues['indskr_producttype'] == 'Only Surgeon Preferences') {
                // Case3: if selected product type is ONLY Surgeon Preferences
                if(!surgeonaccountzsurValue) isValid = false;
                fetchXml = fetchQueries.setBooking.fetchAllProductsForProductTypeOnlySurgeon;
                filter = filter.replace(`{attrValue}`,'"548910001"');
                filter = surgeonaccountzsurValue ? filter.replace(`{accountCondition}`, `<condition attribute='indskr_account' operator="eq" value='${surgeonaccountzsurValue}'/>`)
                            : filter.replace(`{accountCondition}`, '');
              } else if(this.formDetail.formEntityObject['indskr_setbookingproduct.indskr_producttype'] == 548910002) {
                // Case4: if already saved product type is All
                filter = '';
              } else if(this.formDetail.formEntityObject['indskr_setbookingproduct.indskr_producttype'] == 548910000) {
                // Case5: if already saved product type is Only BOM
                filter = filter.replace(`{attrValue}`,'"548910000"').replace(`{accountCondition}`, '');
              } else if (this.formDetail.formEntityObject['indskr_setbookingproduct.indskr_producttype'] == 548910001) {
                // Case6: if already saved product type is ONLY Surgeon Preferences
                if(!surgeonaccountzsurValue) isValid = false;
                fetchXml = fetchQueries.setBooking.fetchAllProductsForProductTypeOnlySurgeon;
                filter = filter.replace(`{attrValue}`,'"548910001"');
                filter = surgeonaccountzsurValue ? filter.replace(`{accountCondition}`, `<condition attribute='indskr_account' operator="eq" value='${surgeonaccountzsurValue}'/>`)
                : filter.replace(`{accountCondition}`, '');
              }
              const entityOpenTagIndex = fetchXml.indexOf('<entity');
              const entityCloseTagIndex = fetchXml.indexOf('>', entityOpenTagIndex);
              if (entityOpenTagIndex > -1) {
                const entityCloseTagIndex = fetchXml.indexOf('>', entityOpenTagIndex);
                if (entityCloseTagIndex > -1) {
                  fetchXml = fetchXml.slice(0, entityCloseTagIndex + 1) + filter + fetchXml.slice(entityCloseTagIndex + 1);
                }
              }
            }
          if(isValid) allBrandProducts = await this.getProductByLookupQueries(fetchXml);
          else allBrandProducts = [];
        }
      }
      let popoverDataSetBookingProduct: { text: string; value: string; items: any; handler: (selectedItem: any, item: any, itemRef: any) => void; }[] = [];
      const productTypeControl = this._getTargetControl('indskr_producttype');
      let dropdownListDetail: IndDropdownListDetailModel = this.dynamicFormsService.getDropdownListViewFromOptionSet(productTypeControl.optionSet, '', this._activeLanguageCode);
      popoverDataSetBookingProduct = [{
          text: '',
          value: '',
          items: dropdownListDetail.data.map((item) => {
            let option = {
              text: item.title,
              value: item.id
            }
            return option;
          }),
          handler: (selectedItem, item, itemRef) => {
          },
        }
      ]
      let listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('PRODUCTS'),
        dividerTitle: this.translate.instant('ALL_PRODUCTS_CAP'),
        isSearchEnabled: true,
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: true,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        orderByPropertyName: 'primaryTextRight',
        searchTitle: this.formDetail && this.formDetail.LookupEntitySetName == 'indskr_setbookingproducts'? "" :this.translate.instant('SEARCH_PRODUCT'),
        isListSelectionEnabled: true,
        listSelectionType: this.formDetail && this.formDetail.LookupEntitySetName == 'indskr_setbookingproducts'? MainToolTemplateListSelectionType.MULTIPLESELECTION : MainToolTemplateListSelectionType.SINGLESELECTION,
        passOverLabelCssToMainCard: 'kitBookingSelection',
        isFilterPopoverActive: this.formDetail && this.formDetail.LookupEntitySetName == 'indskr_setbookingproducts',
        navOptions: { animate: false },
        filterPopoverData: this.formDetail && this.formDetail.LookupEntitySetName == 'indskr_setbookingproducts' ? popoverDataSetBookingProduct : [],
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProductComponentEvent(data, eventTarget, refData),
        searchHandler: (text: string) => this._handleProductComponentSearch(text, allBrandProducts),
        data: allBrandProducts.map((brand: Brand) => {
          let isSelected = false;
          if (selectedId && brand.ID == selectedId) {
            isSelected = true;
          }
          let viewData: MainCardViewDataModel = {
            id: brand.ID,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: true,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isSelected,
            endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
            endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
            primaryTextRight: brand.name,
            showArrow: false,
            arrowType: '',
            isQtyControl: this.formDetail && this.formDetail.LookupEntitySetName == 'indskr_setbookingproducts',
            qty: brand.itemCount ?? 1
          }
          let secInfoParams = [];
          if(brand.productnumber) secInfoParams.push(`${brand.productnumber}`);
          if(brand.indskr_recordtypeoption != null && brand.indskr_recordtypeoption != undefined) {
            let type = '';
            switch(brand.indskr_recordtypeoption) {
              case ProductRecordType.BILL_OF_MATERIAL:
                type = 'Bill Of Material';
                break;
              case ProductRecordType.SURGEON_PREFERENCE:
                type = 'Surgeon Preference';
                break;
              default:
                type = ''
                break;
            }
            secInfoParams.push(`${type}`);
          }
          if(brand.itemCount) secInfoParams.push("Line items - "+brand.itemCount)
          viewData.secondaryTextRight = secInfoParams.join(", ");
          return viewData;
        }),
      };
      this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail }, PageName.MainToolTemplateComponent);
    }
  }

  private async _handleMainTemplateSelectWithFilter(targetControl: Control, selectedId: string) {
    //based on configured viewType, any lookup search opens in mainTemplate with details view instead of popover
    //and the search results are also filtered based on configuration
    let allLookupSearchData;
    if(!_.isEmpty(targetControl) && targetControl.lookupQueries && targetControl.lookupQueries['fetchXml']) {
      //let fetchXml = targetControl.lookupQueries['fetchXml'];
      let filter;
      let query = '';
      try{
        if (targetControl.dependsOn) {
            if (!this.isHandleEditAddressFormField) {
              if (this._customFormValue && this._customFormValue.hasOwnProperty('lookupfields')) {
                filter = this._customFormValue['lookupfields'].find(x => x.name == targetControl.dependsOn).id || '';
              }
            } else {
              if (this._existingCustomFormValue && this._existingCustomFormValue.hasOwnProperty('lookupfields')) {
                filter = this._existingCustomFormValue['lookupfields'].find(x => x.name == targetControl.dependsOn).id || '';
              }
            }
            if (this.changeRequest && targetControl.lookupEntityPrimaryId == "indskr_lu_districtid") {
              filter = this._stateIdBasedOnCity ? this._stateIdBasedOnCity : '';
            }
        }
        if (this.changeRequest && targetControl.lookupEntityPrimaryId == 'indskr_lu_cityid') {
            let idx;
            if (!this.isHandleEditAddressFormField) {
              if (this._customFormValue && this._customFormValue.hasOwnProperty('lookupfields')) {
                idx = this._customFormValue['lookupfields'].findIndex(a => a.name == "indskr_Country_LU");
                if (idx == -1) this.dynamicFormsService.hasCityDataBasedOnCountry = false;
              } else this.dynamicFormsService.hasCityDataBasedOnCountry = false;
            } else {
              if (this._existingCustomFormValue && this._existingCustomFormValue.hasOwnProperty('lookupfields')) {
                idx = this._existingCustomFormValue['lookupfields'].findIndex(a => a.name == "indskr_Country_LU");
                if (idx == -1) this.dynamicFormsService.hasCityDataBasedOnCountry = false;
              } else this.dynamicFormsService.hasCityDataBasedOnCountry = false;
            }
        }
        if(targetControl.dataType == ControlDataType.LookupType && targetControl.filterby && targetControl.filterFieldTargetLookupEntity){
            let selectedFilterBy;
            if (this._currentFormValue && this._currentFormValue.hasOwnProperty('lookupfields')) {
              let idx = this._currentFormValue['lookupfields'].findIndex(a => a.name == targetControl.filterby && !(a['isDeleted'] && a['isDeleted'] === true));
              if (idx >= 0) {
                selectedFilterBy = this._currentFormValue['lookupfields'][idx].id;
              }
            }
            // else if(this._currentFormValue){
            //   selectedFilterBy = this._currentFormValue[targetControl.filterby];
            // }
            if(selectedFilterBy){
              filter = `<filter type="and">
              <condition attribute="${targetControl.filterFieldTargetLookupEntity}" operator="eq" value="${selectedFilterBy}"/>
              </filter>`
            }
          } else if (targetControl.dataType == ControlDataType.LookupType && targetControl.filterby && targetControl.targetEntityAttribute) {
            let selectedFilterBy;

            if (this._currentFormValue?.hasOwnProperty('lookupfields')) {
              let idx = this._currentFormValue['lookupfields'].findIndex(a => a.name.toLowerCase() == targetControl.filterby.toLowerCase());
              if (idx >= 0 && this._currentFormValue['lookupfields'][idx].otherFieldsToInclude) {
                selectedFilterBy = this._currentFormValue['lookupfields'][idx].otherFieldsToInclude[targetControl.targetEntityAttribute];
              }
            }
            if (selectedFilterBy) {
              if (targetControl.filterOperator === FieldConfigFilterOperatorType.CONTAIN_VALUES) {
                filter = `<filter type="and">
                <condition attribute="${targetControl.targetEntityAttribute}" operator="contain-values" >
                  <value>${selectedFilterBy}</value>
                </condition>
                </filter>`;
              } else {
                filter = `<filter type="and">
                <condition attribute="${targetControl.targetEntityAttribute}" operator="${targetControl.filterOperator && targetControl.filterOperator === FieldConfigFilterOperatorType.IN ? 'in' : 'eq'}" value="${selectedFilterBy}"/>
                </filter>`;
              }
            }
          }
        if (!this.device.isOffline) {
          await this.uiService.displayLoader();
            allLookupSearchData = await this.dynamicFormsService.getLookupResults(query, targetControl, filter, this.changeRequest);
          await this.uiService.dismissLoader();
        }
      }
      catch(err){
          console.error(`Error occured while searching lookup results: ${err}`);
      }
      // const entityOpenTagIndex = fetchXml.indexOf('<entity');
      // if (entityOpenTagIndex > -1) {
      //   const entityCloseTagIndex = fetchXml.indexOf('>', entityOpenTagIndex);
      //   if (entityCloseTagIndex > -1) {
      //     fetchXml = fetchXml.slice(0, entityCloseTagIndex + 1) + filter + fetchXml.slice(entityCloseTagIndex + 1);
      //   }
      // }
    }
    let labelText: string = this._getDisplayText(targetControl.displayNames, targetControl.isCustom);
    let listDetail: MainToolTemplateDetail = {
        title: labelText,
        dividerTitle: this.translate.instant('ALL_CAP') + ' ' + labelText,
        isSearchEnabled: true,
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: true,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        orderByPropertyName: 'primaryTextRight',
        searchTitle: "",
        isListSelectionEnabled: true,
        listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleMainTemplateSelectEvent(data, eventTarget, refData),
        searchHandler: (text: string) => this._handleMainTemplateSearch(text, allLookupSearchData),
        data: allLookupSearchData.map((lookupData: LookupSearchResult) => {
          let isSelected = false;
          if (selectedId && lookupData.id == selectedId) {
            isSelected = true;
          }
          let viewData: MainCardViewDataModel = {
            id: lookupData.id,
            primaryTextLeft: '',
            secondaryTextLeft: '',
            showEndIcon: true,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isSelected,
            endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
            endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
            primaryTextRight: lookupData.name,
            showArrow: false,
            arrowType: '',
          }
          let secInfoParams = [];
          // if(brand.productnumber) secInfoParams.push("ID - "+brand.productnumber)
          // if(brand.itemCount) secInfoParams.push("Line items - "+brand.itemCount)
          //viewData.secondaryTextRight = secInfoParams.join(", ");
          return viewData;
        }),
    };
    this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail }, PageName.MainToolTemplateComponent);
  }

  private _handleProductComponentSearch(text: string, products: Brand[]): string[] {
    let ids: Array<string> = [];
    if (text.length >= 1) {
      ids = products.filter(brand => {
        return brand.name.trim().toLowerCase().includes(text.trim().toLowerCase()) || (brand.productnumber && brand.productnumber.toString().trim().toLowerCase().includes(text.trim().toLowerCase()));
      }).map(brand => brand.ID);
    } else {
      ids = products.map(brand => brand.ID);
    }
    return ids;
  }

  private _handleProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
    if (eventTarget && eventTarget == 'RightHeaderButtonClick') {
      if (data && data.isDone && this._activeProductSelectControl) {
        const comparisonValue = this._activeProductSelectControl.isCustom ? this._customFormValue : this._currentFormValue;
        const objName = this._activeProductSelectControl.isCustom ? this._activeProductSelectControl.schemaName : (this._activeProductSelectControl.isCustomAttribute ? this._activeProductSelectControl.schemaName : this._activeProductSelectControl.attributeName);
        let selectedValue = '';
        if (comparisonValue && comparisonValue.hasOwnProperty('lookupfields')) {
          let idx = comparisonValue['lookupfields'].findIndex(a => a.name == objName);
          if (idx >= 0) {
            selectedValue = comparisonValue['lookupfields'][idx].id;
          }
        }
        if (data.removedItems && Array.isArray(data.removedItems) && data.removedItems, length >= 0) {
          data.removedItems.forEach(item => {
            // Remove product
            this._clearAttributeValue(this._activeLookupSearchControl);
          });
        }
        if (data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems, length >= 0) {
          data.selectedItems.forEach(item => {
            // Select product
            if (!(comparisonValue && selectedValue == item.id)) {
              this._setAttributeStringValue(this._activeProductSelectControl.attributeName, item.primaryTextRight);
              this._setAttributeValue(this._activeProductSelectControl, item.id);
            }
          });
        }
      }
    }
    if (eventTarget && (eventTarget == 'RightHeaderButtonClick' || eventTarget == 'LeftHeaderButtonClick')) {
      this._activeProductSelectControl = null;
    }
  }

  private _createCurrentFormValueForSetBookingProduct(attributeName:string) {
    let _currentFormValueForSetBooking = [];
    if(this.linkedEntityValues && this.linkedEntityValues[attributeName]) {
      this.linkedEntityValues[attributeName].forEach(sp => {
        if(sp.rawObjData && sp.rawObjData.formValue) {
          let productId = '';
          if(sp.rawObjData.formValue.lookupfields) productId = sp.rawObjData.formValue.lookupfields.find(field => field.entity == 'products')?.id ?? '';
          _currentFormValueForSetBooking[productId] = sp.rawObjData?.formValue ;
        } else {
          let productId = sp.rawObjData['indskr_setbookingproduct.indskr_product'];
          let _formValue = {
            "guid": sp.linkedEntityExistingDataId,
            "indskr_quantity": sp.rawObjData['indskr_setbookingproduct.indskr_quantity'],
            "lookupfields": [
              {
                  "name": "indskr_setBooking",
                  "entity": "indskr_setbookings",
                  "id": sp.rawObjData['indskr_setbookingid']
              },
              {
                  "name": "indskr_product",
                  "entity": "products",
                  "id": sp.rawObjData['indskr_setbookingproduct.indskr_product'] ?? ''
              }
            ]
          }
          if(sp.rawObjData['indskr_setbookingproduct.indskr_producttype']) _formValue['indskr_producttype'] = sp.rawObjData['indskr_setbookingproduct.indskr_producttype'];
          _currentFormValueForSetBooking[productId] = _formValue;
        }
      })
    }
    return _currentFormValueForSetBooking;
  }

  private _handleSetBookingProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail, formDetail: DynamicFormComponentModel) {
    if (eventTarget && eventTarget == 'RightHeaderButtonClick') {
      const foundControl = this._getTargetControlbyLookupEtity('indskr_setbookingproducts');
      if (data && data.isDone && this._activeProductSelectControl) {
        let _currentFormValueForSetBooking = this._createCurrentFormValueForSetBookingProduct(foundControl.attributeName);
        // Remove Unselected products
        if (data.removedItems && Array.isArray(data.removedItems) && data.removedItems, length >= 0) {
          data.removedItems.forEach(item => {
            if(_currentFormValueForSetBooking && _currentFormValueForSetBooking[item.id]){
              let index;
              if(item.linkedEntityAddedNewDataId) {
                index = this.linkedEntityValues[foundControl.attributeName].findIndex(x => x.linkedEntityAddedNewDataId == item.linkedEntityAddedNewDataId);
              } else {
                index = this.linkedEntityValues[foundControl.attributeName].findIndex(x => x.linkedEntityExistingDataId == item.linkedEntityExistingDataId);
              }
              if (index != -1) {
                this.linkedEntityValues[foundControl.attributeName][index].isDeactivate = true;
              }
              if (_currentFormValueForSetBooking[item.id]) {
                let tempFormValue = _currentFormValueForSetBooking[item.id].lookupfields[0];
                delete _currentFormValueForSetBooking[item.id];
                _currentFormValueForSetBooking[item.id] = { lookupfields: [tempFormValue] };
              }
              if (item.linkedEntityExistingDataId) {
                _currentFormValueForSetBooking[item.id] = { ..._currentFormValueForSetBooking[item.id],
                  guid: item.linkedEntityExistingDataId,
                  statecode: 1,
                  statuscode: 2
                };
              } else if(item.linkedEntityAddedNewDataId) {
                _currentFormValueForSetBooking[item.id] = { ..._currentFormValueForSetBooking[item.id],
                  guid: item.linkedEntityAddedNewDataId,
                  statecode: 1,
                  statuscode: 2
                };
              }
              this.linkedEntityValues[foundControl.attributeName] = this.linkedEntityValues[foundControl.attributeName].filter(x=>x.isDeactivate != true);
              if (this.formDetail && this.formDetail.eventsHandler) {
                let dataReq = {
                  eventsHandler: (data: any, eventName: string) => this.callbackFromParentComponent(data, eventName), //ind-dynamic-form:3349
                  displayConfirmation: this._isFormDirty,
                  linkedEntityExistingDataId: item.linkedEntityExistingDataId || '',
                  linkedEntityAddedNewDataId: item.linkedEntityAddedNewDataId || '',
                  "entity": 'indskr_setbookingproducts',
                  "values": _currentFormValueForSetBooking[item.id],
                }
                this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
                this.formDetail.eventsHandler(dataReq, 'SCRAPLINKEDENTITY', formDetail);
                this._isFormDirty = true;
              }
            }
          });
        }

        //Add Selected Products
        if (data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems, length >= 0) {
          let setBookingId = formDetail && formDetail.linkedEntityParentData && formDetail.linkedEntityParentData.entity == 'indskr_setbookings' ? formDetail.linkedEntityParentData.id : '';
          data.selectedItems.forEach(item => {
            this._setAttributeValueForSetBookingProduct(this._activeProductSelectControl, item, false, '', _currentFormValueForSetBooking, setBookingId);
          });
        }
        if (formDetail.linkedEntityParentData) {
          try {
            if (this.formDetail && this.formDetail.eventsHandler) {
              data.selectedItems.forEach(item => {
                if (!item.linkedEntityExistingDataId && !item.linkedEntityAddedNewDataId) item.linkedEntityAddedNewDataId = 'indskr_product_' + Math.random().toString(36).substring(7)
                let dataReq = {
                  linkedEntityExistingDataId: item.linkedEntityExistingDataId || '',
                  linkedEntityAddedNewDataId: item.linkedEntityAddedNewDataId || '',
                  "entity": formDetail.LookupEntitySetName,
                  "values": _currentFormValueForSetBooking[item.id],
                }
                this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
                this.formDetail.eventsHandler(dataReq, 'ADDEDLINKEDENTITY', formDetail);
                this._isFormDirty = true;
              })
            }

            if (data) {
              if (foundControl) {
                let abilityToRemoveRecords = foundControl.linkedEntityConfig && foundControl.linkedEntityConfig.abilityToRemoveRecords == '548910000'
                try {
                  if (data.removedItems && Array.isArray(data.removedItems) && data.removedItems.length > 0) {
                    data.removedItems.forEach(item => {
                      if(this.linkedEntityValues[foundControl.attributeName]) delete this.linkedEntityValues[foundControl.attributeName];
                    });
                  }
                  data.selectedItems.forEach(item => {
                    let accordionView: OmniAccordionViewDataModel;
                    let childItems: Array<any> = [];
                    let formattedPrimaryText: string = '';
                    let raw = {
                      "formValue":_currentFormValueForSetBooking[item.id],
                      "formStringValue": {
                          "indskr_product": item.primaryTextRight,
                      },
                      "isSelectedFromSearch": false,
                      "isFormSaved": false
                    }
                    foundControl.subgrid.subgridLayout.forEach(attrib => {
                      let attribName;
                      let index = attrib.attribute.indexOf('.');
                      if (index !== -1) {
                        attribName = attrib.attribute.slice(index + 1);
                      } else {
                        attribName = attrib.attribute;
                      }
                      if (attribName && item) {
                        if (accordionView) {
                          let displayName: string = this._getDisplayText(attrib.displayNames);

                          childItems.push({
                            id: foundControl.attributeName + '_' + attribName,
                            label: displayName ? displayName : '',
                            value: attribName == 'indskr_quantity' ? item.qty : (attribName == 'indskr_name' ? item.primaryTextRight: (attribName == 'indskr_name' ? '' : (attribName == 'indskr_product' ? item.id : ''))),
                          });
                          accordionView.showExpandIcon = true;
                          accordionView.showCollapseIcon = true;
                          accordionView.lookupFieldActualValue = '';
                        } else {
                          accordionView = {
                            displayType: 'Accordion',
                            id: foundControl.attributeName + '_' + attribName + Math.random().toString(36).substring(7),
                            linkedEntityExistingDataId: item.linkedEntityExistingDataId || '',
                            linkedEntityExistingDataAddressId: item.linkedEntityExistingDataAddressId || '',
                            linkedEntityAddedNewDataId: item.linkedEntityAddedNewDataId || '',
                            primaryText: item.primaryTextRight ? `${item.primaryTextRight} | ${this.translate.instant('Quantity')} - ${item.qty}` : '',
                            lookupFieldActualValue: '',
                            showExpandIcon: false,
                            showCollapseIcon: false,
                            isExpanded: false,
                            childItems: [],
                            rawObjData: raw,
                            isUpdatedFormData: true,
                            isDeactivate: false,
                            scrapFromList: abilityToRemoveRecords
                          }
                          if(foundControl.lookupEntitySetName == 'indskr_setbookingproducts') {
                            let ID: string = '';
                            if(item.secondaryTextRight && item.secondaryTextRight.length>0) {
                              let endIndex = item.secondaryTextRight.indexOf(',') > -1 ? item.secondaryTextRight.indexOf(',') : item.secondaryTextRight.length;
                              ID = item.secondaryTextRight.substring(0, endIndex);
                              accordionView.primaryText = `${item.primaryTextRight} | ${ID} | ${this.translate.instant('Quantity')} - ${item.qty}`
                            }
                            accordionView.disableEditArrow = true;
                          }
                        }
                      }
                    });
                    formattedPrimaryText = '';
                    if (accordionView) {
                      if (formattedPrimaryText && this.editConfig !=EditConfig.ADD_NEW) accordionView.primaryText = formattedPrimaryText;
                      accordionView.childItems = childItems;
                      if (this.linkedEntityValues && this.linkedEntityValues[foundControl.attributeName]) {
                        if (item.linkedEntityExistingDataId) {
                          let index = this.linkedEntityValues[foundControl.attributeName].findIndex(x => x.linkedEntityExistingDataId == item.linkedEntityExistingDataId);
                          if (index != -1) {
                            this.linkedEntityValues[foundControl.attributeName][index] = accordionView;
                          } else {
                            this.linkedEntityValues[foundControl.attributeName].push(accordionView);
                          }
                        } else {
                          let index = this.linkedEntityValues[foundControl.attributeName].findIndex(x => x.linkedEntityAddedNewDataId == item.linkedEntityAddedNewDataId);
                          if (index != -1) {
                            this.linkedEntityValues[foundControl.attributeName][index] = accordionView;
                          } else {
                            this.linkedEntityValues[foundControl.attributeName].push(accordionView);
                          }
                        }
                      } else if (this.linkedEntityValues) {
                        this.linkedEntityValues[foundControl.attributeName] = [accordionView];
                      } else {
                        this.linkedEntityValues = {
                          [foundControl.attributeName]: [accordionView]
                        };
                        this.linkedEntityValues[foundControl.attributeName] = this.linkedEntityValues[foundControl.attributeName].filter(x=>x.isDeactivate == false);
                      }

                    }
                  });
                  this.uiService.dismissLoader();
                } catch (error) {
                  this.uiService.dismissLoader();
                  console.log(error);
                }
              }
              this._initViewData();
            }
          } catch (error) {
            console.log(error);
            if (error.error.errorCode == 'ERR_IO_500') {
              this.notificationService.notify('Duplicate Detected: This ' + refData.title + ' has already been added', 'DynamicFormComponent', 'top', ToastStyle.INFO, 3000);
            }
          }
        }
      }
    }
    if (eventTarget && (eventTarget == 'RightHeaderButtonClick' || eventTarget == 'LeftHeaderButtonClick')) {
      this._activeProductSelectControl = null;
    }
  }

  private _handleMainTemplateSearch(text: string, allLookupSearchData: LookupSearchResult[]): string[] {
    let ids: Array<string> = [];
    if (text.length >= 1) {
      ids = allLookupSearchData.filter(lookupItem => {
        return lookupItem.name.trim().toLowerCase().includes(text.trim().toLowerCase());
      }).map(lookupItem => lookupItem.id);
    } else {
      ids = allLookupSearchData.map(lookupItem => lookupItem.id);
    }
    return ids;
  }

  private async _handleMainTemplateSelectEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
    if (eventTarget && eventTarget == 'RightHeaderButtonClick') {
      if (data && data.isDone && this._activeMainTemplateLookupSelectControl) {
        const comparisonValue = this._activeMainTemplateLookupSelectControl.isCustom ? this._customFormValue : this._currentFormValue;
        const objName = this._activeMainTemplateLookupSelectControl.isCustom ? this._activeMainTemplateLookupSelectControl.schemaName : (this._activeMainTemplateLookupSelectControl.isCustomAttribute ? this._activeMainTemplateLookupSelectControl.schemaName : this._activeMainTemplateLookupSelectControl.attributeName);
        let selectedValue = '';
        if (comparisonValue && comparisonValue.hasOwnProperty('lookupfields')) {
          let idx = comparisonValue['lookupfields'].findIndex(a => a.name == objName && !(a['isDeleted'] && a['isDeleted'] === true));
          if (idx >= 0) {
            selectedValue = comparisonValue['lookupfields'][idx].id;
          }
        }
        if (data.removedItems && Array.isArray(data.removedItems) && data.removedItems, length >= 0) {
          data.removedItems.forEach(item => {
            // Remove product
            this._clearAttributeValue(this._activeLookupSearchControl);
          });
        }
        if (data.selectedItems && Array.isArray(data.selectedItems) && data.selectedItems, length >= 0) {
          data.selectedItems.forEach(item => {
            // Select product
            if (!(comparisonValue && selectedValue == item.id)) {
              //If the filterby field is reset, then clear the lookup fields depending on it
              if (this._currentFormValue && this._currentFormValue['lookupfields']) {
                this._currentFormValue['lookupfields'].forEach(lookup => {
                  if(!(lookup['isDeleted'] && lookup['isDeleted']==true)){
                    let lookupControl: Control = this._getTargetControl(lookup.name);
                    if(!lookupControl)
                      lookupControl = this._getTargetControlbySchemaName(lookup.name);
                    if(lookupControl && lookupControl.filterby && lookupControl.filterby == objName){
                      this._clearAttributeValue(lookupControl);
                    }
                  }
                });
              }
              this._setAttributeStringValue(this._activeMainTemplateLookupSelectControl.attributeName, item.primaryTextRight);
              this._setAttributeValue(this._activeMainTemplateLookupSelectControl, item.id);
            }
          });
        }
      }
    }
    if (eventTarget && (eventTarget == 'RightHeaderButtonClick' || eventTarget == 'LeftHeaderButtonClick')) {
      this._activeMainTemplateLookupSelectControl = null;
    }
    // else if (eventTarget && eventTarget === 'LeftHeaderButtonClick') {
    //   await this.navService.popWithPageTracking();
    // }
    if (eventTarget && eventTarget === 'MainItemEvents-mainItem') {
      if(data && data.id && this._activeLookupSearchControl && this._activeLookupSearchControl.lookupEntitySetName) {
        //getFormDefinitionForEntity could have been used as well
        //const lookupEnityDisplayForm = await this.dynamicFormsService.getFormDefinitionForEntity(this._activeLookupSearchControl.lookupEntitySetName.slice(0,-1), FormType.DISPLAYFORM);
        let fetchXML = MOBILE_FORMS_DISPLAY_ENTITY_FILTER;
        fetchXML = fetchXML.replace('{entityName}', `${this._activeLookupSearchControl.lookupEntitySetName.slice(0,-1)}`);
        try{
          this.uiService.displayLoader();
          await this.dynamics.executeFetchQuery("indskr_mobileappforms", fetchXML).then(async (res)=>{
            if(!_.isEmpty(res) && res[0] != undefined){
              let lookupEnityDisplayForm =new DynamicForm(res[0]);
              let fetchXMLForLookupEntity = `
              <fetch distinct="true" mapping="logical" output-format="xml-platform" version="1.0" >
                <entity name="${this._activeLookupSearchControl.lookupEntitySetName.slice(0,-1)}">
                  <all-attributes />
                  <filter type="and">
                  <condition attribute="${this._activeLookupSearchControl.lookupEntityPrimaryId}" value="${data.id}" operator="eq"/>
                  </filter>
                </entity>
              </fetch>`
              await this.dynamics.executeFetchQuery(this._activeLookupSearchControl.lookupEntitySetName, fetchXMLForLookupEntity).then((rawData)=>{
                if(!_.isEmpty(rawData) && rawData[0] != undefined){
                  let detailViewHeaderData;
                  detailViewHeaderData = {
                    showLeftHeaderButton: this.device.isMobileDevice,
                    leftHeaderBtnIcon: 'chevron-back-outline',
                  }
                  this.navService.pushChildNavPageWithPageTracking(MainToolTemplateDetailsComponent, PageName.MainToolTemplateDetailsComponent, PageName.MainToolTemplateComponent, { formMetadata: lookupEnityDisplayForm, rawData: rawData[0] , isEditEnabled: false, for: "displayForm", viewData: detailViewHeaderData});
                  this.navService.setChildNavRightPaneView(true);
                  this.uiService.showRightPane = true;
                }
              })
            }
          });
          this.uiService.dismissLoader();
        } catch(e){
          this.uiService.dismissLoader();
          console.error("Error in fetching display form for lookup entity: " + e);
          this.notificationService.notify(this.translate.instant("SOMETHING_REALLY_BAD_HAPPENED"), 'Ind Dynamic Form', 'top', ToastStyle.DANGER);
          return;
        }
      }
    }
  }

  private async _handleBooleanFormField(id, event, eventName, control: Control) {
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'dynamic-form-boolean-field-select',
      showClearAllBtn: true,
      clearAllBtnText: this.translate.instant('CLEAR'),
      data: [
        {
          title: this.translate.instant('YES'),
          id: 'Yes',
          isSelected: (this._currentFormValue && this._currentFormValue[id] == true) ? true : false,
        },
        {
          title: this.translate.instant('NO'),
          id: 'No',
          isSelected: (this._currentFormValue && this._currentFormValue[id] == false) ? true : false,
        }
      ],
    };
    this._dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event
    });
    this._dropdownPopover.onDidDismiss().then(async (data: any) => {
      let newSelectedValue = ((this._currentFormValue && this._currentFormValue[id] == true) || (this._currentFormValue && this._currentFormValue[id] == false))?'yes':null;
      if (data.data) data = data.data;
      if (data && data.selectedItems && data.selectedItems.length == 1) {
        if (data.selectedItems[0].id == 'Yes') {
          this._setAttributeStringValue(control.attributeName, this.translate.instant('YES'));
          this._setAttributeValue(control, true, false);
        } else if (data.selectedItems[0].id == 'No') {
          this._setAttributeStringValue(control.attributeName, this.translate.instant('NO'));
          this._setAttributeValue(control, false, false);
        }
        newSelectedValue = 'yes';
      } else if (data && data.selectedItems && data.selectedItems.length == 0) {
        newSelectedValue = null;
        this._clearAttributeValue(control);
      }else{
        if(control.isRequired && this.dynamicFormsService.getFormFieldType(control) !== FormFieldType.INLINE_INPUT){
          this._validateMandatoryField(id,null,{value:newSelectedValue},false);
        }
      }
      this._dropdownPopover = undefined;
    })
    this._dropdownPopover.present();
  }

  private async _handleDropdownMultiSelectListFormField(id, event, eventName, targetControl: Control) {
    let selectedValue = [];
    if (this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.attributeName)) {
      selectedValue = this._currentFormValue[targetControl.attributeName].split(',');
    }
    let dropdownListDetail: IndDropdownListDetailModel = this.dynamicFormsService.getDropdownListViewFromOptionSet(targetControl.optionSet, selectedValue, this._activeLanguageCode);
    dropdownListDetail.showCancelandDoneBtn = true;
    dropdownListDetail.pageTitle = this._getDisplayText(targetControl.displayNames, targetControl.isCustom);
    dropdownListDetail.isMultipleSelectionEnabled = true;
    this._dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event
    });
    let newSelectedValue:any = selectedValue;
    this._dropdownPopover.onDidDismiss().then(async (data: any) => {
      data = data.data;
      if (data && !data.isDone) {
        this._dropdownPopover.dismiss();
      } else if (data && data.selectedItems && data.selectedItems.length == 1) {
        if (!(this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.attributeName) && this._currentFormValue[targetControl.attributeName] == data.selectedItems[0].id)) {
          this._setAttributeStringValue(targetControl.attributeName, data.selectedItems[0].title);
          this._setAttributeValue(targetControl, data.selectedItems[0].id, false);
          newSelectedValue = data.selectedItems[0].id;
        }
      } else if (data && data.selectedItems && data.selectedItems.length > 1) {
        data.selectedItems = data.selectedItems.sort((a,b) => {
          return (a.title > b.title) ? 1: -1;
        })
        let concatString = '';
        let concatID = '';
        for(let i = 0; i<data.selectedItems.length; i++){
          concatString += data.selectedItems[i].title;
          concatID += data.selectedItems[i].id;
          if(i<data.selectedItems.length-1) {
            concatString += ', ';
            concatID += ', ';
          }
        }
        this._setAttributeStringValue(targetControl.attributeName, concatString);
        this._setAttributeValue(targetControl, concatID, false);
        newSelectedValue = concatID;
      } else if (data && data.selectedItems && data.selectedItems.length == 0) {
        this._clearAttributeValue(targetControl);
        newSelectedValue = null;
      }
      if(targetControl.isRequired && this.dynamicFormsService.getFormFieldType(targetControl) !== FormFieldType.INLINE_INPUT){
        this._validateMandatoryField(id,null,{value:newSelectedValue},false);
      }
      this._dropdownPopover = undefined;
    });
    this._dropdownPopover.present();
  }

  private async _handleDropdownListFormField(id, event, eventName, targetControl: Control) {
    let selectedValue = '';
    if (this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.attributeName)) {
      selectedValue = this._currentFormValue[targetControl.attributeName];
    }
    let dropdownListDetail: IndDropdownListDetailModel = this.dynamicFormsService.getDropdownListViewFromOptionSet(targetControl.optionSet, selectedValue, this._activeLanguageCode);
    dropdownListDetail.showClearAllBtn = targetControl.attributeName =='indskr_producttype' ? false : true;
    dropdownListDetail.clearAllBtnText = this.translate.instant('CLEAR');
    // Cascading option sets for Kit Booking Operation Type/Operation Sub-Type/Assisted Technology
    if(targetControl && targetControl.attributeName == 'indskr_operationsubtype') {
      if(!(this._currentFormValue && this._currentFormValue['indskr_operationtype'])) {
        this.notificationService.notify('Please select Operation Type','ind-dynamic-form','top',ToastStyle.DANGER);
        return;
      }
      let typeControl: Control = this._getTargetControl('indskr_operationtype');
      let dropdownTypeListDetail: IndDropdownListDetailModel = this.dynamicFormsService.getDropdownListViewFromOptionSet(typeControl.optionSet, '', this._activeLanguageCode);
      let foundTypeOption = dropdownTypeListDetail.data.find(option => option.id ==  this._currentFormValue['indskr_operationtype']);
      let typeConfig =  this.authService.user.ioConfigurations?.find(o=>o.configName=="KitBooking.OperationTypes.SubTypes.Relation");
      if(typeConfig && typeConfig.configValue && foundTypeOption) {
        let selectedTypeConfig = JSON.parse(typeConfig.configValue).find(o=> o["operationType"].toLowerCase().trim() == foundTypeOption.title.toLowerCase().trim());
        if(selectedTypeConfig && selectedTypeConfig['operationSubTypes'] && dropdownListDetail && dropdownListDetail.data) {
          dropdownListDetail.data.forEach(d => {
            if(!selectedTypeConfig['operationSubTypes'].some(subtype => subtype.toLowerCase().trim() == d.title.toLowerCase().trim())) d.isForcehide = true;
          })
        } else {
          this.notificationService.notify('No Operation Sub-Type is available for the Selected Operation Type','ind-dynamic-form','top',ToastStyle.DANGER);
          return;
        }
      }
    } else if (targetControl && targetControl.attributeName == 'indskr_assistedtechnology') {
      if(!(this._currentFormValue && this._currentFormValue['indskr_operationsubtype'])) {
        this.notificationService.notify('Please select Operation Sub-Type','ind-dynamic-form','top',ToastStyle.DANGER);
        return;
      }
      let subTypeControl: Control = this._getTargetControl('indskr_operationsubtype');
      let dropdownSubTypeListDetail: IndDropdownListDetailModel = this.dynamicFormsService.getDropdownListViewFromOptionSet(subTypeControl.optionSet, '', this._activeLanguageCode);
      let foundSubTypeOption = dropdownSubTypeListDetail.data.find(option => option.id ==  this._currentFormValue['indskr_operationsubtype']);
      let techConfig =  this.authService.user.ioConfigurations?.find(o=>o.configName=="KitBooking.OperationSubTypes.Technology.Relation");
      if(techConfig && techConfig.configValue && foundSubTypeOption) {
        let selectedSubTypeConfig = JSON.parse(techConfig.configValue).find(o=> o["operationSubType"].toLowerCase().trim() == foundSubTypeOption.title.toLowerCase().trim());
        if(selectedSubTypeConfig && selectedSubTypeConfig['assistedTechnologies'] && dropdownListDetail && dropdownListDetail.data) {
          dropdownListDetail.data.forEach(d => {
            if(!selectedSubTypeConfig['assistedTechnologies'].some(tech => tech.toLowerCase().trim() == d.title.toLowerCase().trim())) d.isForcehide = true;
          })
        } else {
          this.notificationService.notify('No Assisted Technology is available for the Selected Operation Sub-Type','ind-dynamic-form','top',ToastStyle.DANGER);
          return;
        }
      }
    }
    this._dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event
    });
    this._dropdownPopover.onDidDismiss().then(async (data: any) => {
      data = data.data;
      let newSelectedValue = selectedValue;
      if (data && data.selectedItems && data.selectedItems.length == 1) {
        if (!(this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.attributeName) && this._currentFormValue[targetControl.attributeName] == data.selectedItems[0].id)) {
          this._setAttributeStringValue(targetControl.attributeName, data.selectedItems[0].title);
          this._setAttributeValue(targetControl, data.selectedItems[0].id, false);
          if (targetControl.attributeName == 'indskr_rolepharma'
          || targetControl.attributeName == 'indskr_rolevaccine'
          || targetControl.attributeName == 'indskr_departmentvaccine'
          || targetControl.attributeName == 'indskr_positionretail'
          || targetControl.attributeName == 'indskr_titleretail'
          || targetControl.attributeName == 'indskr_titlepharma') {
            let selectedOption = targetControl.optionSet.filter(o => o.value == data.selectedItems[0].id);
            if (selectedOption && selectedOption.length) {
              this._currentFormStringValues[targetControl.attributeName + '_Desc'] = selectedOption[0].descriptions[0].description;
            }
          }
          newSelectedValue =data.selectedItems[0].id;
        }
      } else if (data && data.selectedItems && data.selectedItems.length == 0) {
        this._clearAttributeValue(targetControl);
        newSelectedValue = null;
      }
      if(targetControl.isRequired && this.dynamicFormsService.getFormFieldType(targetControl) !== FormFieldType.INLINE_INPUT){
        this._validateMandatoryField(id,null,{value:newSelectedValue},false);
      }
      this._dropdownPopover = undefined;
    });
    this._dropdownPopover.present();
  }

  private _handleDateTimeFormField(id, event, eventName, targetControl: Control) {
    if (targetControl && this.dynamicFormDateTimePicker) {
      if (targetControl.dataType == ControlDataType.DateTimeType) {
        this._dateTimePickerActiveControl = undefined;
        if (targetControl.dateFormat == DateFormat.DateAndTime) {
          if (this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.attributeName) && this._currentFormValue[targetControl.attributeName]) {
            const date = new Date(this._currentFormValue[targetControl.attributeName]);
            var date_utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0);
            this.dynamicFormDateTimePicker.value = (new Date(date_utc).toISOString());
          } else {
            const date = new Date();
            var date_utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0);
            this.dynamicFormDateTimePicker.value = (new Date(date_utc).toISOString());
          }
        } else if (targetControl.dateFormat == DateFormat.DateOnly) {
          if (this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.attributeName) && this._currentFormValue[targetControl.attributeName]) {
            const date = new Date(this._currentFormValue[targetControl.attributeName]);
            var date_utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
            this.dynamicFormDateTimePicker.value = (new Date(date_utc).toISOString());
          } else {
            const date = new Date();
            var date_utc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
            this.dynamicFormDateTimePicker.value = (new Date(date_utc).toISOString());
          }
          //provision to select future years, setting upto next 50 years[OMNI-16297]
          this.dynamicFormDateTimePicker.max = (new Date().getFullYear() + 50).toString();
        }
        if (id == 'omnione_yearofbirth') {
          //this.dynamicFormDateTimePicker.displayFormat = 'YYYY';
          //this.dynamicFormDateTimePicker.pickerFormat = 'YYYY';
        } else if (id == 'omnione_dayandmonthofbirth') {
         //this.dynamicFormDateTimePicker.displayFormat = 'MMM DD';
          //this.dynamicFormDateTimePicker.pickerFormat = 'MMM DD';
        } else {
         // this.dynamicFormDateTimePicker.displayFormat = this.dateTimeFormatsService.dateToUpper;
         // this.dynamicFormDateTimePicker.pickerFormat = this.dateTimeFormatsService.dateToUpper;
        }
        //this.dynamicFormDateTimePicker.open();
        setTimeout(() => {
          this._dateTimePickerActiveControl = targetControl;
        }, 50);
      }
    }
  }

  public updateFormDate(event) {
    if (event && event.value && this._dateTimePickerActiveControl) {
      if (this._dateTimePickerActiveControl.dataType == ControlDataType.DateTimeType) {
        let valueDate = new Date(event.value);
        if (this._dateTimePickerActiveControl.dateFormat == DateFormat.DateAndTime) {
          this._setAttributeValueDate(this._dateTimePickerActiveControl, valueDate);
        } else if (this._dateTimePickerActiveControl.dateFormat == DateFormat.DateOnly) {
          valueDate.setHours(0, 0, 0, 0);
          if (this._dateTimePickerActiveControl && this._dateTimePickerActiveControl.attributeName == 'omnione_yearofbirth') {
            const targetControl: Control = this._dateTimePickerActiveControl;
            const isUserLocal: boolean = targetControl.dateBehavior == 'UserLocal';
            if (this._currentFormStringValues && this._currentFormStringValues['omnione_dayandmonthofbirth']) {
              const dateOfBirth: Date = new Date(this._currentFormStringValues['omnione_dayandmonthofbirth']);
              const dayAndMonthOfBirth: string = format(dateOfBirth, 'MM/DD');
              const selectedYear: string = format(valueDate, 'YYYY');
              const combinedYearMonthDay: string = selectedYear + '/' + dayAndMonthOfBirth;
              valueDate = new Date(combinedYearMonthDay);
              const selectedDateUTC = moment(valueDate).utc().format();
              const formattedDate = (new Date(selectedDateUTC).toISOString());
              const formattedDateValue = isUserLocal ? formattedDate : valueDate;
              this._setAttributeValueDate(targetControl, formattedDateValue);
              const targetControlDayAndMonth: Control = this._getTargetControl('omnione_dayandmonthofbirth');
              this._setAttributeValueDate(targetControlDayAndMonth, formattedDateValue);
            } else {
              const selectedDateUTC = moment(valueDate).utc().format();
              const formattedDate = (new Date(selectedDateUTC).toISOString());
              const formattedDateValue = isUserLocal ? formattedDate : valueDate;
              this._setAttributeValueDate(targetControl, formattedDateValue);
            }
          } else if (this._dateTimePickerActiveControl && this._dateTimePickerActiveControl.attributeName == 'omnione_dayandmonthofbirth') {
            const targetControl: Control = this._dateTimePickerActiveControl;
            const isUserLocal: boolean = targetControl.dateBehavior == 'UserLocal';
            if (this._currentFormStringValues && this._currentFormStringValues['omnione_yearofbirth']) {
              const dateOfBirth: Date = new Date(this._currentFormStringValues['omnione_yearofbirth']);
              const yearOfBirth: string = format(dateOfBirth, 'YYYY');
              const selectedDayAndMonthOfBirth: string = format(valueDate, 'MM/DD');
              const combinedYearMonthDay: string = yearOfBirth + '/' + selectedDayAndMonthOfBirth;
              valueDate = new Date(combinedYearMonthDay);
              const selectedDateUTC = moment(valueDate).utc().format();
              const formattedDate = (new Date(selectedDateUTC).toISOString());
              const formattedDateValue = isUserLocal ? formattedDate : valueDate;
              this._setAttributeValueDate(targetControl, formattedDateValue);
              const targetControlYear: Control = this._getTargetControl('omnione_yearofbirth');
              this._setAttributeValueDate(targetControlYear, formattedDateValue);
            } else {
              const selectedDateUTC = moment(valueDate).utc().format();
              const formattedDate = (new Date(selectedDateUTC).toISOString());
              const formattedDateValue = isUserLocal ? formattedDate : valueDate;
              this._setAttributeValueDate(targetControl, formattedDateValue);
            }
          } else {
            const targetControl: Control = this._dateTimePickerActiveControl;
            const isUserLocal: boolean = targetControl.dateBehavior == 'UserLocal';
            const selectedDateUTC = moment(valueDate).utc().format();
            const formattedDate = (new Date(selectedDateUTC).toISOString());
            const formattedDateValue = isUserLocal ? formattedDate : valueDate;
            this._setAttributeValueDate(targetControl, formattedDateValue);
          }
        }
      }
    }
    if(this._dateTimePickerActiveControl && this._dateTimePickerActiveControl.isRequired){
      let value;
      try {
        value = this._currentFormValue[this._dateTimePickerActiveControl.attributeName];
      } catch (error) {

      }
      this._validateMandatoryField(this._dateTimePickerActiveControl.attributeName,null,{value:value},false)
    }
  }

  private _setAttributeStringValue(attributeName: string, value: any) {
    if (this._currentFormStringValues) {
      if (attributeName === 'statecode' || attributeName === 'statuscode') {
        this._currentFormStringValues[attributeName] = this.dynamicFormsService.translatedValue(value);
      } else {
        this._currentFormStringValues[attributeName] = value;
      }
    } else {
      this._currentFormStringValues = {
        [attributeName]: value,
      }
    }
  }

  private _setAttributeValue(control: Control, value: any, initViewData: boolean = true, codIdOnekey: string = '', runBusinessRulesLogic:Boolean = true, otherFieldsToInclude?: any) {
    if (control.isCustom) {
      this._setCustomAttributeValue(control, value, initViewData, codIdOnekey);
      return;
    }
    let isSetValue: boolean = false;
    let objName = control.isCustomAttribute ? control.schemaName : control.attributeName;
    let cfvObj: any = {
      name: objName,
      entity: control.lookupEntitySetName,
      id: value
    };
    if (otherFieldsToInclude) {
      cfvObj.otherFieldsToInclude = otherFieldsToInclude;
    }
    if (this._currentFormValue) {
      if (control.dataType == ControlDataType.LookupType) {
        let crLookUp;
        if(codIdOnekey) {
          const entityName = control.isCustomAttribute ? control.schemaName : control.attributeName;
          crLookUp = {
            entity: entityName,
            codIdOnekey: codIdOnekey,
            guid: value
          };
        }
        if (this._currentFormValue.hasOwnProperty('lookupfields')) {
          const idx = this._currentFormValue['lookupfields'].findIndex(a => a.name == objName);
          if (idx >= 0) {
            const prevGuid = this._currentFormValue['lookupfields'][idx].id || '';
            this._currentFormValue['lookupfields'][idx] = cfvObj;
            if(prevGuid && codIdOnekey && this.dynamicFormsService.okLookUpMapper) this.dynamicFormsService.addLookupOnekeyData(prevGuid, crLookUp);
          } else {
            this._currentFormValue['lookupfields'].push(cfvObj);
            if(codIdOnekey) this.dynamicFormsService.okLookUpMapper.push(crLookUp);
          }
        } else {
          this._currentFormValue['lookupfields'] = [cfvObj];
          if(codIdOnekey) this.dynamicFormsService.okLookUpMapper = [crLookUp];
        }
        isSetValue = true;
      } else {
        if ((value === '' || value == undefined || value == null) && control.isRequired) {
          delete this._currentFormValue[control.attributeName];
        } else {
          this._currentFormValue[control.attributeName] = value;
          if (this.formDetail.LookupEntitySetName == 'indskr_customeraddresscrs' && control.attributeName == 'indskr_name') {
            if (this._customFormValue) {
              this._customFormValue[control.attributeName] = value;
            } else {
              this._customFormValue = { [control.attributeName]: value, }
            }
          }
          isSetValue = true;
        }
      }
    } else {

      if (control.dataType == ControlDataType.LookupType) {
        this._currentFormValue = { lookupfields: [cfvObj] };
        isSetValue = true;
      } else {
        if ((value === '' || value == undefined || value == null) && control.isRequired) return;
        this._currentFormValue = {
          [control.attributeName]: value,
        }
        isSetValue = true;
      }
    }
    if(isSetValue) {
      this._isFormDirty = true;
      this.dynamicFormsService.linkedEntityFormIsDirtyTracker = true;
      if (this.formDetail && this.formDetail.eventsHandler) {
        this.formDetail.eventsHandler(true, 'ISFORMDIRTY');
      }
    }
    if(runBusinessRulesLogic){
      this._runBusinessRulesLogic();
    }
    if (initViewData) {
      this._initViewData();
    } else {
      this._targetControlViewData(control);
    }
  }

  private _setAttributeValueForSetBookingProduct(control: Control, value: any, initViewData: boolean = true, codIdOnekey: string = '', _formValue, setBookingId) {
    let isSetValue: boolean = false;
    let objName = control.isCustomAttribute ? control.schemaName : control.attributeName;
    let cfvObj = {
      name: objName,
      entity: control.lookupEntitySetName,
      id: value.id
    }

    if (_formValue && _formValue[value.id]) {
      let selectedFormValue = _formValue[value.id];
      // update Product
      let crLookUp;
      if (selectedFormValue.hasOwnProperty('lookupfields')) {
        const idx = selectedFormValue['lookupfields'].findIndex(a => a.name == objName);
        if (idx >= 0) {
          const prevGuid = selectedFormValue['lookupfields'][idx].id || '';
          selectedFormValue['lookupfields'][idx] = cfvObj;
          if(prevGuid && codIdOnekey && this.dynamicFormsService.okLookUpMapper) this.dynamicFormsService.addLookupOnekeyData(prevGuid, crLookUp);
        } else {
          selectedFormValue['lookupfields'].push(cfvObj);
          if(codIdOnekey) this.dynamicFormsService.okLookUpMapper.push(crLookUp);
        }
      } else {
        selectedFormValue['lookupfields'] = [cfvObj];
        if(codIdOnekey) this.dynamicFormsService.okLookUpMapper = [crLookUp];
      }

      // update quantity and product type
      // selectedFormValue['indskr_producttype'] = '';
      selectedFormValue['indskr_quantity'] = value.qty ?? 1;
      isSetValue = true;
    } else {
      let _form = {
        // 'indskr_producttype': '',
        'indskr_quantity': value.qty ?? 1,
        "lookupfields": [
          {
              "name": "indskr_setBooking",
              "entity": "indskr_setbookings",
              "id": setBookingId
          },
          {
              "name": "indskr_product",
              "entity": "products",
              "id": value.id ?? ''
          }
        ]
      }
      if(value.linkedEntityExistingDataId) _form["guid"] = value.linkedEntityExistingDataId;
      else if(value.linkedEntityAddedNewDataId) _form["guid"] = value.linkedEntityAddedNewDataId;
      _formValue[value.id] = _form;
      isSetValue = true;
    }
    if(isSetValue) {
      this._isFormDirty = true;
      this.dynamicFormsService.linkedEntityFormIsDirtyTracker = true;
      if (this.formDetail && this.formDetail.eventsHandler) {
        this.formDetail.eventsHandler(true, 'ISFORMDIRTY');
      }
    }
  }

  private _clearAttributeValue(control: Control) {
    let recursiveControl: Control;
    this._isFormDirty = true;
    let controlsToReset : Control[]=[];
    if (control.dataType == ControlDataType.BooleanType) {
      if (control.isCustom) {
        if (this._customFormValue && this._customFormValue.hasOwnProperty(control.attributeName)) {
          // delete this._customFormValue[control.attributeName];
          this._customFormValue[control.attributeName] = null;
        }
      } else {
        if (this._currentFormValue && this._currentFormValue.hasOwnProperty(control.attributeName)) {
          // delete this._currentFormValue[control.attributeName];
          this._currentFormValue[control.attributeName] = null;
        }
      }
    } else if (control.dataType == ControlDataType.PicklistType || control.dataType == ControlDataType.MultiSelectPicklistType) {
      if (control.isCustom) {
        if (this._customFormValue && this._customFormValue[control.attributeName]) {
          // delete this._customFormValue[control.attributeName];
          this._customFormValue[control.attributeName] = null;
        }
      } else {
        if (this._currentFormValue && this._currentFormValue[control.attributeName]) {
          // delete this._currentFormValue[control.attributeName];
          this._currentFormValue[control.attributeName] = null;
        }
        if (this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) {
          delete this._currentFormStringValues[control.attributeName]
        }
      }
    } else if (control.dataType == ControlDataType.LookupType) {
      let objName = control.isCustomAttribute ? control.schemaName : control.attributeName;
      if (control.isCustom) {
        objName = control.schemaName;
        if (this._customFormValue && this._customFormValue.hasOwnProperty('lookupfields')) {
          let idx = this._customFormValue['lookupfields'].findIndex(a => a.name == objName);
          if (idx >= 0) {
            const prevGuid = this._customFormValue['lookupfields'][idx].id || '';
            // this._customFormValue['lookupfields'].splice(idx, 1);
            this._customFormValue['lookupfields'][idx]['isDeleted'] = true;
            if(prevGuid && this.dynamicFormsService.okLookUpMapper) this.dynamicFormsService.clearMappedLookupOnekeyWithId(prevGuid);
          }
          for (let i = 0; i < this._dynamicForm.metadata.length && !recursiveControl; i++) {
            const tab = this._dynamicForm.metadata[i];
            if (tab && tab.controls && tab.controls.length > 0) {
              for (let j = 0; j < tab.controls.length && !recursiveControl; j++) {
                const foundControl = tab.controls[j];
                if (foundControl && foundControl.dependsOn == control.schemaName) {
                  recursiveControl = foundControl;
                }
              }
            }
          }
          //Clear fields which are related to address because dependsOn is hard coded for onekey configured form
          //Selected Country,County/District,Postal Code,CityState are deleted When a city is selected
          if (this.changeRequest && control.lookupEntityPrimaryId == "indskr_lu_cityid") {
            idx = this._customFormValue['lookupfields'].findIndex(a => a.name == "indskr_Country_LU");
            if (idx >= 0) {
              // this._customFormValue['lookupfields'].splice(idx, 1);
              this._customFormValue['lookupfields'][idx]['isDeleted'] = true;
              if (this._currentFormStringValues && this._currentFormStringValues.hasOwnProperty('indskr_country_lu')) {
                delete this._currentFormStringValues['indskr_country_lu'];
              }
            }
            idx = this._customFormValue['lookupfields'].findIndex(a => a.name == "indskr_district");
            if (idx >= 0) {
              const prevGuid = this._customFormValue['lookupfields'][idx].id || '';
              // this._customFormValue['lookupfields'].splice(idx, 1);
              this._customFormValue['lookupfields'][idx]['isDeleted'] = true;
              if(prevGuid && this.dynamicFormsService.okLookUpMapper) this.dynamicFormsService.clearMappedLookupOnekeyWithId(prevGuid);
              if (this._currentFormStringValues && this._currentFormStringValues.hasOwnProperty('indskr_district')) {
                delete this._currentFormStringValues['indskr_district'];
              }
            }
            idx = this._customFormValue['lookupfields'].findIndex(a => a.name == "indskr_postalcode_lu");
            if (idx >= 0) {
              // this._customFormValue['lookupfields'].splice(idx, 1);
              this._customFormValue['lookupfields'][idx]['isDeleted'] = true;
              if (this._currentFormStringValues && this._currentFormStringValues.hasOwnProperty('indskr_postalcode_lu')) {
                delete this._currentFormStringValues['indskr_postalcode_lu'];
              }
            }
            if (this._customFormValue && this._customFormValue.hasOwnProperty('indskr_compositecitystate')) {
              // delete this._customFormValue['indskr_compositecitystate'];
              this._customFormValue['indskr_compositecitystate'] = null;
            }
          }
        }
      } else {
        if (this._currentFormValue && this._currentFormValue.hasOwnProperty('lookupfields')) {
          let idx = this._currentFormValue['lookupfields'].findIndex(a => a.name == objName);
          if (idx >= 0) {
            const prevGuid = this._currentFormValue['lookupfields'][idx].id || '';
            this._currentFormValue['lookupfields'][idx]['isDeleted'] = true;
            if(prevGuid && this.dynamicFormsService.okLookUpMapper) this.dynamicFormsService.clearMappedLookupOnekeyWithId(prevGuid);
          }
          //we need to clear the lookup fields that are configured to be dependent on the deselected lookupfield
          this._currentFormValue['lookupfields'].forEach(lookup => {
            if(!(lookup['isDeleted'] && lookup['isDeleted']==true)){
              let lookupControl: Control = this._getTargetControl(lookup.name);
              if(!lookupControl)
                lookupControl = this._getTargetControlbySchemaName(lookup.name);
              if(lookupControl && lookupControl.filterby && lookupControl.filterby == objName){
                controlsToReset.push(lookupControl);
              }
            }
          });
        }
      }
      if (this._currentFormStringValues && this._currentFormStringValues[control.attributeName]) {
        delete this._currentFormStringValues[control.attributeName];
      }
      if (this._activeLookupSearchControl.lookupEntityPrimaryId == "indskr_lu_countryid") {
        this.dynamicFormsService.hasCityDataBasedOnCountry = false;
      }
    }
    if (recursiveControl) {
      this._clearAttributeValue(recursiveControl);
    } else if(controlsToReset.length > 0){
      controlsToReset.forEach(control => {
        this._clearAttributeValue(control);
      });
    } else {
      this._initViewData();
    }
  }
  private _clearEditAddressFormAttributeValue(control: Control) {
    let recursiveControl: Control;
    if (control.dataType == ControlDataType.BooleanType) {
      if (control.isCustom) {
        if (this._existingCustomFormValue && this._existingCustomFormValue.hasOwnProperty(control.attributeName)) {
          // delete this._existingCustomFormValue[control.attributeName];
          this._existingCustomFormValue[control.attributeName] = null;
        }
      } else {
        if (this._existingFormValue && this._existingFormValue.hasOwnProperty(control.attributeName)) {
          // delete this._existingFormValue[control.attributeName];
          this._existingFormValue[control.attributeName] = null;
        }
      }
    } else if (control.dataType == ControlDataType.PicklistType || control.dataType == ControlDataType.MultiSelectPicklistType) {
      if (control.isCustom) {
        if (this._existingCustomFormValue && this._existingCustomFormValue[control.attributeName]) {
          // delete this._existingCustomFormValue[control.attributeName];
          this._existingCustomFormValue[control.attributeName] = null;
        }
      } else {
        if (this._existingFormValue && this._existingFormValue.hasOwnProperty(control.attributeName)) {
          // delete this._existingFormValue[control.attributeName];
          this._existingFormValue[control.attributeName] = null;
        }
      }
    } else if (control.dataType == ControlDataType.LookupType) {
      let objName = control.isCustomAttribute ? control.schemaName : control.attributeName;
      if (control.isCustom) {
        objName = control.schemaName;
        if (this._existingCustomFormValue && this._existingCustomFormValue.hasOwnProperty('lookupfields')) {
          let idx = this._existingCustomFormValue['lookupfields'].findIndex(a => a.name == objName);
          if (idx >= 0) {
            const prevGuid = this._existingCustomFormValue['lookupfields'][idx].id || '';
            // this._existingCustomFormValue['lookupfields'].splice(idx, 1);
            this._existingCustomFormValue['lookupfields'][idx]['isDeleted'] = true;
            if(prevGuid && this.dynamicFormsService.okLookUpMapper) this.dynamicFormsService.clearMappedLookupOnekeyWithId(prevGuid);
          }
          for (let i = 0; i < this._dynamicForm.metadata.length && !recursiveControl; i++) {
            const tab = this._dynamicForm.metadata[i];
            if (tab && tab.controls && tab.controls.length > 0) {
              for (let j = 0; j < tab.controls.length && !recursiveControl; j++) {
                const foundControl = tab.controls[j];
                if (foundControl && foundControl.dependsOn == control.schemaName) {
                  recursiveControl = foundControl;
                }
              }
            }
          }
          //Clear fields which are related to address because dependsOn is hard coded for onekey configured form
          //Selected Country,County/District,Postal Code,CityState are deleted When a city is selected
          if (this.changeRequest && control.lookupEntityPrimaryId == "indskr_lu_cityid") {
            idx = this._existingCustomFormValue['lookupfields'].findIndex(a => a.name == "indskr_Country_LU");
            if (idx >= 0) {
              // this._existingCustomFormValue['lookupfields'].splice(idx, 1);
              this._existingCustomFormValue['lookupfields'][idx]['isDeleted'] = true;
              if (this._existingFormStringValues && this._existingFormStringValues.hasOwnProperty('indskr_country_lu')) {
                delete this._existingFormStringValues['indskr_country_lu'];
              }
            }
            idx = this._existingCustomFormValue['lookupfields'].findIndex(a => a.name == "indskr_district");
            if (idx >= 0) {
              const prevGuid = this._existingCustomFormValue['lookupfields'][idx].id || '';
              // this._existingCustomFormValue['lookupfields'].splice(idx, 1);
              this._existingCustomFormValue['lookupfields'][idx]['isDeleted'] = true;
              if(prevGuid && this.dynamicFormsService.okLookUpMapper) this.dynamicFormsService.clearMappedLookupOnekeyWithId(prevGuid);
              if (this._existingFormStringValues && this._existingFormStringValues.hasOwnProperty('indskr_district')) {
                delete this._existingFormStringValues['indskr_district'];
              }
            }
            idx = this._existingCustomFormValue['lookupfields'].findIndex(a => a.name == "indskr_postalcode_lu");
            if (idx >= 0) {
              // this._existingCustomFormValue['lookupfields'].splice(idx, 1);
              this._existingCustomFormValue['lookupfields'][idx]['isDeleted'] = true;
              if (this._existingFormStringValues && this._existingFormStringValues.hasOwnProperty('indskr_postalcode_lu')) {
                delete this._existingFormStringValues['indskr_postalcode_lu'];
              }
            }
            if (this._existingFormStringValues && this._existingCustomFormValue.hasOwnProperty('indskr_compositecitystate')) {
              // delete this._existingCustomFormValue['indskr_compositecitystate'];
              this._existingCustomFormValue['indskr_compositecitystate'] = true;
            }
          }
        }
      } else {
        if (this._existingFormValue && this._existingFormValue.hasOwnProperty('lookupfields')) {
          let idx = this._existingFormValue['lookupfields'].findIndex(a => a.name == objName);
          if (idx >= 0) {
            const prevGuid = this._existingFormValue['lookupfields'][idx].id || '';
            // this._existingFormValue['lookupfields'].splice(idx, 1);
            this._existingFormValue['lookupfields'][idx]['isDeleted'] = true;
            if(prevGuid && this.dynamicFormsService.okLookUpMapper) this.dynamicFormsService.clearMappedLookupOnekeyWithId(prevGuid);
          }
        }
      }
      if (this._existingFormStringValues && this._existingFormStringValues[control.attributeName]) {
        delete this._existingFormStringValues[control.attributeName];
      }
      if (this._activeLookupSearchControl.lookupEntityPrimaryId == "indskr_lu_countryid") {
        this.dynamicFormsService.hasCityDataBasedOnCountry = false;
      }
    }
    if (recursiveControl) {
      this._clearEditAddressFormAttributeValue(recursiveControl);
    } else {
      this._initViewEditFormData();
    }
  }

  //#region methods for Custom Fields
  private _setCustomAttributeValue(control: Control, value: any, initViewData: boolean = true, codIdOnekey: string = '') {
    let cfvObj = {
      name: control.schemaName,
      entity: control.lookupEntitySetName,
      id: value
    };

    if (this._customFormValue) {
      let crLookUp;
      if (control.dataType == ControlDataType.LookupType) {
        if(codIdOnekey) {
          const entityName = control.isCustomAttribute ? control.schemaName : control.attributeName;
          crLookUp = {
            entity: entityName,
            codIdOnekey: codIdOnekey,
            guid: value
          };
        }
        if (this._customFormValue.hasOwnProperty('lookupfields')) {
          const idx = this._customFormValue['lookupfields'].findIndex(a => a.name == control.schemaName);
          if (idx >= 0) {
            const prevGuid = this._customFormValue['lookupfields'][idx].id || '';
            this._customFormValue['lookupfields'][idx] = cfvObj;
            if(prevGuid && codIdOnekey && this.dynamicFormsService.okLookUpMapper) this.dynamicFormsService.addLookupOnekeyData(prevGuid, crLookUp);
          } else {
            this._customFormValue['lookupfields'].push(cfvObj);
            if(codIdOnekey) this.dynamicFormsService.okLookUpMapper.push(crLookUp);
          }
        } else {
          this._customFormValue['lookupfields'] = [cfvObj];
          if(codIdOnekey) this.dynamicFormsService.okLookUpMapper = [crLookUp];
        }
      } else {
        this._customFormValue[control.attributeName] = value;
      }
    } else {

      if (control.dataType == ControlDataType.LookupType) {
        this._customFormValue = { lookupfields: [cfvObj] };
      } else {
        this._customFormValue = {
          [control.attributeName]: value,
        }
      }
    }
    this._isFormDirty = true;
    this.dynamicFormsService.linkedEntityFormIsDirtyTracker = true;
    if (initViewData) {
      this._initViewData();
    } else {
      this._targetControlViewData(control);
    }
  }

  private async _getCustomFields(referencingEntity: string) {
    let controls: Control[] = [];
    if (referencingEntity == 'indskr_indskr_customeraddress_v2') {
      CUSTOM_ADDRESS_FIELDS.forEach(con => controls.push(new Control(con)));
    } else if (referencingEntity == 'indskr_customeraddresscr') {
      const addressForm = await this.dynamicFormsService.getFormDefinitionForEntity('indskr_addresscr', FormType.CREATEFORM);
      if (addressForm && addressForm.metadata && addressForm.metadata.length == 1 && addressForm.metadata[0].controls) {
        addressForm.metadata[0].controls.map(control => control.isCustom = true);
        const index = addressForm.metadata[0].controls.findIndex(control => control.attributeName == "indskr_name");
        if (index >= 0) {
          addressForm.metadata[0].controls.splice(index, 1);
        }
        controls = [...addressForm.metadata[0].controls];
      }
    } else if (referencingEntity === 'indskr_accountaccountaffiliationcr') {
      const aaaForm = await this.dynamicFormsService.getFormDefinitionForEntity('indskr_accountaccountaffiliationcr', FormType.CREATEFORM);
      if (aaaForm && aaaForm.metadata && aaaForm.metadata.length == 1 && aaaForm.metadata[0].controls) {
        aaaForm.metadata[0].controls.map(control => control.isCustom = true);
        const index = aaaForm.metadata[0].controls.findIndex(control => control.attributeName == "indskr_name");
        if (index >= 0) {
          aaaForm.metadata[0].controls.splice(index, 1);
        }
        controls = [...aaaForm.metadata[0].controls];
      }
    } else if (referencingEntity === 'indskr_accountcontactaffiliationcr') {
      const acaForm = await this.dynamicFormsService.getFormDefinitionForEntity('indskr_accountcontactaffiliationcr', FormType.CREATEFORM);
      if (acaForm && acaForm.metadata && acaForm.metadata.length == 1 && acaForm.metadata[0].controls) {
        acaForm.metadata[0].controls.map(control => control.isCustom = true);
        const index = acaForm.metadata[0].controls.findIndex(control => control.attributeName == "indskr_name");
        if (index >= 0) {
          acaForm.metadata[0].controls.splice(index, 1);
        }
        controls = [...acaForm.metadata[0].controls];
      }
    } else {
      controls = null;
    }
    return controls;
  }

  //#endregion methods for custom fields

  //#region Search Bar
  public searchAddressGlobally(searchParams) {
    try {
      if (searchParams.length === 0) return;
      this.addressService.getGlobalAddresses(searchParams).then((result) => {
        this.searchPopoverOptions = this.addressService.mapAddress(result);
      }).catch(err => {
        console.log('Error searching: ', err);
      });
    } catch (error) {
      console.error(error);
    }
  }

  private async searchAddressForOneKey(searchParams) {
    try {
      if(searchParams.length === 0) return;
      let fetchXml = CONTACT_FETCH_QUERIES.searchAllAddressesForOneKey;
      // Remove omnione_account attribute from fetchXML as this is present in One Key env only
      if(this.TYPE == "OMNIPRESENCE"){
        fetchXml = fetchXml.replace('<attribute name="omnione_account" />',"")
        fetchXml = fetchXml.replace('{{linked-entities}}', '').split("{{query}}").join(searchParams);
      }else if(this.PREVIOUS_MODE === 'CREATE_MODE') {
        fetchXml = fetchXml.replace('{{linked-entities}}', '').split("{{query}}").join(searchParams);
      } else if(this.PREVIOUS_MODE === 'EDIT_MODE') {
        // let mdmId = (this.accountService.selected.indskr_mdmid) ? this.accountService.selected.indskr_mdmid : '';
        let mdmId = (this.dynamicFormsService.mdmId) ? this.dynamicFormsService.mdmId : '';
        if(this.formDetail.linkedEntityParentData.entity == 'indskr_accountcrs') {
          fetchXml = fetchXml.replace('{{linked-entities}}', CONTACT_FETCH_QUERIES.customerLinkedEntitiesAccountCR);
        } else {
          fetchXml = fetchXml.replace('{{linked-entities}}', CONTACT_FETCH_QUERIES.customerLinkedEntitiesContactCR);
        }
        fetchXml = fetchXml.split("{{query}}").join(searchParams).split("{mdmid}").join(mdmId);
      }
      let data = (await this.dynamics.executeFetchQuery('indskr_addresses', fetchXml));
      data = data?.value ? data.value : data;
      this.searchPopoverOptions = [];
      if (!_.isEmpty(data)) {
        this.searchPopoverOptions = this.dynamicFormsService.mapAddressOneKey(data);
      }
    } catch (error) {
      console.error(error);
    }
  }
  private async searchAccountAccountAffiliations(searchParams) {
    try {
      if(searchParams.length === 0) return;
      let fetchXml = CONTACT_FETCH_QUERIES.searchAccountAccountAffiliations;
      let accountId = this.accountService.selected.id;
      fetchXml = fetchXml.split("{{query}}").join(searchParams).split("{accountid}").join(accountId);
      let searchedData = (await this.dynamics.executeFetchQuery('indskr_accountaccountaffiliations', fetchXml));
      searchedData = searchedData?.value ? searchedData.value : searchedData;
      let searchedDataMap = []
      searchedData.forEach(sData => { searchedDataMap.push(sData); });
      this.searchPopoverOptions = searchedDataMap;
    } catch (error) {
      console.error(error);
    }
  }
  private async searchAccountContactAffiliations(searchParams) {
    try {
      if(searchParams.length === 0) return;
      let fetchXml = CONTACT_FETCH_QUERIES.searchAccountContactAffiliations;
      let contactId = this.contactService.contactInformation.ID;
      fetchXml = fetchXml.split("{{query}}").join(searchParams).split("{contactid}").join(contactId);
      let searchedData = (await this.dynamics.executeFetchQuery('indskr_accountcontactaffiliations', fetchXml));
      searchedData = searchedData?.value ? searchedData.value : searchedData;
      let searchedDataMap = []
      searchedData.forEach(sData => { searchedDataMap.push(sData); });
      this.searchPopoverOptions = searchedDataMap;
    } catch (error) {
      console.error(error);
    }
  }
  public onSearchInput(event) {
    let query = '';
    if (event && event.value) {
      query = event.value.trim();
    }
    if (query.length > 2) {
      this.isSearchPopoverActive = true;

      switch (this._dynamicForm.entitySetName) {
        case 'indskr_accountaccountaffiliationcrs':
          this.isAccountAccountAffiliationPopover = true;
          this.isAccountContactAffiliationPopover = false;
          this.isAddressOneKeyPopover = false;
          this.isAddressPopover = false;
          this.searchAccountAccountAffiliations(query);
          break;
        case 'indskr_accountcontactaffiliationcrs':
          this.isAccountAccountAffiliationPopover = false;
          this.isAccountContactAffiliationPopover = true;
          this.isAddressOneKeyPopover = false;
          this.isAddressPopover = false;
          this.searchAccountContactAffiliations(query);
          break;
        case 'indskr_customeraddresscrs':
          this.isAccountAccountAffiliationPopover = false;
          this.isAccountContactAffiliationPopover = false;
          this.isAddressOneKeyPopover = true;
          this.isAddressPopover = false;
          this.searchAddressForOneKey(query);
          break;
        default:
          this.isAccountAccountAffiliationPopover = false;
          this.isAccountContactAffiliationPopover = false;
          this.isAddressOneKeyPopover = false;
          this.isAddressPopover = true;
          this.searchAddressGlobally(query);
          break;
      }
    } else {
      this.isSearchPopoverActive = false;
      this.isAccountAccountAffiliationPopover = false;
      this.isAccountContactAffiliationPopover = false;
      this.isAddressOneKeyPopover = false;
      this.isAddressPopover = false;
      this.searchPopoverOptions = [];
    }
  }


  // data set for address-onekey from search results
  public setSearchedDataForAddressOneKey() {
    this.dynamicFormsService.isSelectedDataFromAddressOneKey = true;
    this._dynamicForm.metadata[0].controls.forEach(async control => {
      if (control.dataType == ControlDataType.LookupType) {
        const schemaFieldName = control.isCustomAttribute ? control.schemaName : control.attributeName;
        let countyCodeOneKey = '';
        const foundLookupId = this.selectedSearchOption ? this.selectedSearchOption[control.attributeName + '_value'] || '' : '';
        const foundName = this.selectedSearchOption ? this.selectedSearchOption[control.attributeName + '_value_Formatted'] || '' : '';
        this.dynamicFormsService.clearMappedLookupOnekeyWithName('indskr_district');
        if(schemaFieldName.toLowerCase() == 'indskr_district' && foundLookupId) {
          countyCodeOneKey = await this.dynamicFormsService.getLookupCountyCodeOneKey(control.lookupEntitySetName, foundLookupId) || '';
        } else if(schemaFieldName.toLowerCase() == 'indskr_city_lu' && foundLookupId) {
          this._stateIdBasedOnCity = await this.dynamicFormsService.getLookupStateIdBasedOnCity(control.lookupEntitySetName, foundLookupId) || '';
        }
        if (foundName) this._setAttributeStringValue(control.attributeName, foundName);
        if (foundLookupId) this._setAttributeValue(control, foundLookupId, true, countyCodeOneKey);
      } else if (control.dataType == "StringType" || control.dataType == "MemoType") {
        let foundName = this.selectedSearchOption ? this.selectedSearchOption[control.attributeName] || '' : '';
        if (control.attributeName == 'indskr_composite' || control.attributeName == 'indskr_compositecitystate') {
          // remove next line and make one space
          if (foundName) {
            foundName = foundName.replace(/(\r\n|\n|\r)/gm, "");
            foundName = foundName.split(',').join(', ').replace(/\s\s+/g, ' ');
          }
        }
        if (foundName) this._setAttributeValue(control, foundName);
      }
    });
  }

  // data set for existing data selected from search results
  public async setSearchedDataToView() {
    let referenceControls = this._dynamicForm.metadata[0].controls;
    referenceControls.forEach(async control => {
      switch (control.dataType) {
        case ControlDataType.MoneyType:
        case ControlDataType.IntegerType:
        case ControlDataType.DecimalType:
        case ControlDataType.DoubleType:
        case ControlDataType.MemoType:
        case ControlDataType.StringType:
          if (this.selectedSearchOption && this.selectedSearchOption[control.attributeName]) {
            if (control.isVisible && control.isRequired && !control.forceHide) {
              this._setAttributeValue(control, this.selectedSearchOption[control.attributeName]);
            }
          }
          break;
        case ControlDataType.LookupType:
          if (this.selectedSearchOption && this.selectedSearchOption['_' + control.attributeName + '_value']) {
            if (control.isVisible && control.isRequired && !control.forceHide) {
              if(control.lookupEntityPrimaryId && control.lookupEntityPrimaryId == "omnione_onekeycodeslabelsid"){
                if(this.selectedSearchOption['_' + control.attributeName  + '_value'] && this.localizationService.multiLingualFieldsData && this.localizationService.multiLingualFieldsData.length > 0){
                  let foundRecord = this.localizationService.multiLingualFieldsData.find(a=> a['omnione_onekeycodeslabelsid'] == this.selectedSearchOption['_' + control.attributeName  + '_value']);
                  let translatedLang: string = '';
                  // if(foundRecord) translatedLang = foundRecord[multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]] ? foundRecord[multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]] || '' : foundRecord['omnione_en_long_label'] || '';
                  let key = this.localizationService.getOneKeyTableAttributes();
                  if(foundRecord) translatedLang = foundRecord[key] ? foundRecord[key] || '' : foundRecord['omnione_en_long_label'] || '';
                  if(foundRecord && foundRecord['omnione_onekeycodeslabelsid'] && translatedLang){
                    const schemaFieldName = foundRecord['omnione_schemafieldname'] || '';
                    const onekeyCodesLabelsId = foundRecord['omnione_onekeycodeslabelsid'] || '';
                    const codIdOneKey = await this.dynamicFormsService.getLookupCodIdOnekey(schemaFieldName, onekeyCodesLabelsId) || '';
                    this._setAttributeValue(control, onekeyCodesLabelsId, true, codIdOneKey);
                    this._setAttributeStringValue(control.attributeName, translatedLang);
                  }
                }
              }else{
                this._setAttributeValue(control, this.selectedSearchOption['_' + control.attributeName + '_value']);
                this._setAttributeStringValue(control.attributeName, this.selectedSearchOption['_' + control.attributeName + '_value@OData.Community.Display.V1.FormattedValue']);
              }
            }
          }
          break;
        case ControlDataType.BooleanType:
          if (this.selectedSearchOption && this.selectedSearchOption.hasOwnProperty(control.attributeName)) {
            if (control.isVisible && control.isRequired && !control.forceHide) {
              (this.selectedSearchOption[control.attributeName]) ? this._setAttributeValue(control, true) : this._setAttributeValue(control, false);
            }
          }
          break;
        case ControlDataType.MultiSelectPicklistType:
          if (this.selectedSearchOption && this.selectedSearchOption[control.attributeName]) {
            this._setAttributeValue(control, this.selectedSearchOption[control.attributeName]);
            let str = this.selectedSearchOption[control.attributeName+'@OData.Community.Display.V1.FormattedValue'].replace(/;/g, ',');
            if (control.isVisible && control.isRequired && !control.forceHide) {
              this._setAttributeStringValue(control.attributeName,str);
            }
          }
          break;
        case ControlDataType.PicklistType:
          if (this.selectedSearchOption && this.selectedSearchOption[control.attributeName]) {
            if (control.isVisible && control.isRequired && !control.forceHide) {
              this._setAttributeValue(control, this.selectedSearchOption[control.attributeName]);
              this._setAttributeStringValue(control.attributeName, this.selectedSearchOption[control.attributeName + '@OData.Community.Display.V1.FormattedValue']);
            }
          }
          break;
        default:
          break;
      }
    });
  }

  public setSelectedSearchPopoverValue(option) {
    if (option) {
      this.selectedSearchOption = option;
      this.isSearchPopoverActive = false;
      this.searchPopoverOptions = [];
      this.isSelectedFromSearch = true;
      this.searchText = '';

      this._customFormValue = {
        lookupfields: [],
      }
      // Selected data from AccountAccount/AccountContact affiliation
      if (this.isAccountAccountAffiliationPopover || this.isAccountContactAffiliationPopover) {
        this.dynamicFormsService.isSelectedDataFromSearch = true;
        this.setSearchedDataToView();
      }
      // Selected data from Address-OneKey search
      if (this.isAddressOneKeyPopover) {
        this.setSearchedDataForAddressOneKey();
        this.dynamicFormsService.isSelectedDataFromSearch = true;
        if (this.formDetail.LookupEntitySetName == "indskr_customeraddresscrs" && this.formDetail.linkedEntityParentData.entity == "indskr_contactcrs") {
          let { logicalCollectionName, logicalName, schemaName } = this.getAddressEntity();
          let obj = {
            entity: logicalCollectionName,
            id: this.selectedSearchOption.indskr_addressid,
            name: schemaName,
          }
          if (this._currentFormValue['lookupfields']) {
            let idx = this._currentFormValue['lookupfields'].findIndex(a => a.name == schemaName);
            if (idx >= 0) {
              this._currentFormValue['lookupfields'][idx] = obj;
            } else {
              this._currentFormValue['lookupfields'].push(obj);
            }
          } else {
            this._currentFormValue['lookupfields'] = [obj];
          }
        }
        // this.dynamicFormsService.isSelectedDataFromAddressOneKey = true;
      }
      // Selected data from Address search
      if (this.isAddressPopover) {
        for (let con of CUSTOM_ADDRESS_FIELDS) {
          if (con.DataType == ControlDataType.LookupType) {
            this._customFormValue['lookupfields'].push({
              entity: con.LookupEntitySetName,
              id: this.selectedSearchOption[con.AttributeName],
              name: con.SchemaName
            });
            if (this._currentFormStringValues) {
              this._currentFormStringValues[con.AttributeName] = this.selectedSearchOption[con.AttributeName + '_value'];
            } else {
              this._currentFormStringValues = {
                [con.AttributeName]: this.selectedSearchOption[con.AttributeName + '_value']
              }
            }
          } else {
            this._customFormValue[con.AttributeName] = this.selectedSearchOption[con.AttributeName];
          }
        }
        let { logicalCollectionName, logicalName, schemaName } = this.getAddressEntity();
        let fv = this.currentFormView.find(x => x.id == logicalName)
        if (fv) {
          this._setAttributeStringValue(fv.id, this.selectedSearchOption.indskr_name);
          this._setAttributeValue(fv.control, this.selectedSearchOption.indskr_addressid);
        } else {
          let obj = {
            entity: logicalCollectionName,
            id: this.selectedSearchOption.indskr_addressid,
            name: schemaName,
          }
          if (this._currentFormValue) {
            if (this._currentFormValue['lookupfields']) {
              let idx = this._currentFormValue['lookupfields'].findIndex(a => a.name == schemaName);
              if (idx >= 0) {
                this._currentFormValue['lookupfields'][idx] = obj;
              } else {
                this._currentFormValue['lookupfields'].push(obj);
              }
            } else {
              this._currentFormValue['lookupfields'] = [obj];
            }
          } else {
            this._currentFormValue = {
              lookupfields: [obj],
            }
          }
          if (this._currentFormStringValues) {
            this._currentFormStringValues[logicalName] = this.selectedSearchOption.indskr_name;
          } else {
            this._currentFormStringValues = {
              [logicalName]: this.selectedSearchOption.indskr_name,
            }
          }
        }
        this.dynamicFormsService.isSearchedAddress = true;
      }

      this._isFormDirty = true;
      this._initViewData();
    }
  }

  private getAddressEntity() {
    let logicalName = 'indskr_address';
    let schemaName = 'indskr_Address';
    let logicalCollectionName = 'indskr_addresses';
    //For contact change request, we will associate address from account affiliated no need to create address change request
    if (this.changeRequest) {
      if ((this.formDetail.LookupEntitySetName == 'indskr_contactcrs' || this.formDetail.linkedEntityParentData?.entity == 'indskr_contactcrs')) {
        schemaName = 'indskr_Addressv2';
      } else {
        logicalName = 'indskr_addresscr';
        schemaName = 'indskr_AddressCR';
        logicalCollectionName = 'indskr_addresscrs';
      }
    }
    return { logicalCollectionName, logicalName, schemaName };
  }
  //#endregion Search Bar

  private _closepage(): void {
    this.dynamicFormsService.isSelectedDataFromSearch = false;
    this.dynamicFormsService.storedLinkedEntityValues = [];
    this.dynamicFormsService.hasCityDataBasedOnCountry = false;
    this.dynamicFormsService.isSelectedCountry = false;
    this.dynamicFormsService.isSelectedDataFromAddressOneKey = false;
    this.navService.popChildNavPageWithPageTracking().then(success => {
      if (this.navService.getActiveChildNavViewPageName() == PageName.NothingSelectedView) {
        this.navService.setChildNavRightPaneView(false);
      }
    });
  }

  // Auto-populated Existing Linked Data flow - #start
  private async autoPopulatedLinkedData(targetExistingLinkedData: string, isAdd: boolean, optionData?: any) {
    let result = false;
    let targetControl;
    if (this._dynamicForm && this._dynamicForm.metadata && this._dynamicForm.metadata.length > 0) {
      this._dynamicForm.metadata.forEach(tab => {
        if (tab && tab.controls && tab.controls.length > 0) {
          tab.controls.forEach( async control => {
            if (control.isVisible && !control.dataType && control.subgrid) {
              if (control.subgrid.referencingAttribute == "indskr_contactcr" && control.lookupEntityPrimaryId == "indskr_accountcontactaffiliationcrid" && control.isVisible) {
                if (targetExistingLinkedData == 'accountContactAffiliation') targetControl = control;
              } else if (control.subgrid.referencingAttribute == "indskr_contactcr" && control.lookupEntityPrimaryId == "indskr_customeraddresscrid" && control.isVisible) {
                if (targetExistingLinkedData == 'contactAddress') targetControl = control;
              } else if (control.subgrid.referencingAttribute == "indskr_accountcr" && control.lookupEntityPrimaryId == "indskr_customeraddresscrid" && control.isVisible) {
                if (targetExistingLinkedData == 'accountAddress') targetControl = control;
              }
            }
          });
        }
      });
    }
    if (targetControl) {
      switch (targetExistingLinkedData) {
        case 'accountContactAffiliation':
          this.dynamicFormsService.accountAffiliationAttributeName = targetControl.attributeName;
          await this._addExistingLinkedDataForACAffiliation(targetControl, isAdd);
          console.log("checked existing linked data - Account-Contact Affiliation");
          break;
        case 'contactAddress':
          this.dynamicFormsService.addressAttributeName = targetControl.attributeName;
          if (this.addedNewACAffiliation && optionData) {
            await this._addExistingLinkedDataForAddress(targetControl, isAdd, optionData);
            console.log("checked existing linked data - Contact-Address with new account affiliation");
          } else {
            await this._addExistingLinkedDataForAddress(targetControl, isAdd);
            console.log("checked existing linked data - Contact-Address");
          }
          break;
        case 'accountAddress':
          this.dynamicFormsService.addressAttributeName = targetControl.attributeName;
          await this._addExistingLinkedDataForAddress(targetControl, isAdd);
          console.log("checked existing linked data - Account-Address");
          break;
        default:
          break;
      }
      result = true;
    }
    return result;
  }
  // Add existing Linked Data for Account-Contact Affiliation in Contact CR
  private async _addExistingLinkedDataForACAffiliation(control: Control, isAdd: boolean) {
    let linkEntityData;
    let referenceControls: Control[];
    let dataReq;
    let foundForm;
    let dataReqEntity;
    if (control.subgrid.referencingAttribute == 'indskr_contactcr' && control.lookupEntityPrimaryId == 'indskr_accountcontactaffiliationcrid') {
      let filteredLEDataACAffiliation;
      let contactId = this.contactService.contactInformation.ID || '';
      if (contactId) {
        try {
          let fetchXml = CONTACT_FETCH_QUERIES.searchAccountContactAffiliations;
          fetchXml = fetchXml.split("{{query}}").join('').split("{contactid}").join(contactId);
          await this.dynamics.executeFetchQuery('indskr_accountcontactaffiliations', fetchXml).then((res) => {
            if (!_.isEmpty(res) && res[0] != undefined) {
              filteredLEDataACAffiliation = res;
            }}
          );
        } catch(error){
          console.log(error);
        }
        if (!_.isEmpty(filteredLEDataACAffiliation) && filteredLEDataACAffiliation[0] != undefined) {
          // filtered Account-Contact Affiliation Primary data
          let filteredACAffiliationPrimaryData = filteredLEDataACAffiliation.filter(x => x.hasOwnProperty('indskr_isprimaryaccount') && x['indskr_isprimaryaccount'] == true);
          // set linked Entity Data, if there is no primary Account-Contact Affiliation with OneKey, set non-primary Account-Contact Affiliation with OneKey
          linkEntityData = !_.isEmpty(filteredACAffiliationPrimaryData) ? filteredACAffiliationPrimaryData : filteredLEDataACAffiliation || '';
          if (!_.isEmpty(linkEntityData)) {
            this.isAutoPopulatedLinkedDataFlow = true;
            console.log("found existing OneKey linked data - Affiliated Account in Contact CR");
          }
        }
      }
    }
    // Common data set flow
    foundForm = this._foundFormForACAffiliationCR;
    if (foundForm && foundForm.metadata && foundForm.metadata[0].controls) {
      if (control.subgrid.referencingAttribute == 'indskr_contactcr' && control.lookupEntityPrimaryId == 'indskr_accountcontactaffiliationcrid') {
        let rawFormEntityObject = null;
        for (let i = 0; i < foundForm.metadata.length; i++) {
          const itab = foundForm.metadata[i];
          this.removeOldOptionSetIfLookupIsPresent(itab, foundForm, i, "indskr_affiliationrole", "indskr_contactrole");
          if (itab && itab.controls && itab.controls.length > 0) {
            rawFormEntityObject = this.getFieldsToDisplayForContactAccountAff(itab, rawFormEntityObject, 'indskr_contactcr', 'indskr_accountid');
          }
        };
      }
      referenceControls = foundForm.metadata[0].controls;
    }
    dataReqEntity = foundForm.entitySetName;
    if (referenceControls && !_.isEmpty(linkEntityData)) {
      let setData = await this.setExistingACAffiliationData(control, referenceControls, linkEntityData);
      // Data is transferred when the save button is clicked (isAdd == true)
      if (setData && isAdd) {
        // Add linkEntities - push to linkEntitiesReqPayload through mdm service
        dataReq = {
          "entity": dataReqEntity,
          "values": [this._existingFormValue],
        }
        if (dataReq) {
          this._isFormSaved = false;
          this._isFormDirty = true;
          this.formDetail.eventsHandler(dataReq, 'ADDEDLINKEDENTITY', this.formDetail);
        }
      }
    }
    // Set accordionView data
    let data = {
      customFormValue: this._existingCustomFormValue,
      formValue: this._existingFormValue,
      formStringValue: this._existingFormStringValues,
    }
    if (!this.addedNewACAffiliation && (!_.isEmpty(this._existingCustomFormValue) || !_.isEmpty(this._existingFormValue) || !_.isEmpty(this._existingFormStringValues))) {
      let foundControl: Control;
      foundControl = control;
      if (foundControl) {
        try {
          let accordionView: OmniAccordionViewDataModel;
          let childItems: Array<any> = [];
          let abilityToRemoveRecords = foundControl.linkedEntityConfig && foundControl.linkedEntityConfig.abilityToRemoveRecords == '548910000'
          foundControl.subgrid.subgridLayout.forEach(attrib => {
            let attribName;
            let index = attrib.attribute.indexOf('.');
            if (index !== -1) {
              attribName = attrib.attribute.slice(index + 1);
            } else {
              attribName = attrib.attribute;
            }
            if (attribName && ((data.formStringValue && data.formStringValue[attribName]) || (data.formValue && data.formValue[attribName]) || (data.customFormValue && data.customFormValue[attribName]))) {
              if (accordionView) {
                let displayName: string = this._getDisplayText(attrib.displayNames);
                childItems.push({
                  id: foundControl.attributeName + '_' + attribName,
                  label: displayName ? displayName : '',
                  value: data.formStringValue && data.formStringValue[attribName] ? data.formStringValue[attribName] : (data.formValue && data.formValue[attribName]) || (data.customFormValue && data.customFormValue[attribName]),
                });
                accordionView.showExpandIcon = true;
                accordionView.showCollapseIcon = true;
              } else {
                accordionView = {
                  displayType: 'Accordion',
                  id: foundControl.attributeName + '_' + attribName + Math.random().toString(36).substring(7),
                  primaryText: data.formStringValue && data.formStringValue[attribName] ? data.formStringValue[attribName] : (data.formValue && data.formValue[attribName]) || (data.customFormValue && data.customFormValue[attribName]),
                  showExpandIcon: false,
                  showCollapseIcon: false,
                  isExpanded: false,
                  childItems: [],
                  scrapFromList: abilityToRemoveRecords,
                  clickHandler: (id, event, specificTarget, dataRef) => this.handleAccordionView(event, foundControl, specificTarget, dataRef)
                }
              }
            }
          });
          if (accordionView && !isAdd) {
            accordionView.childItems = childItems;
            if (this.linkedEntityValues && this.linkedEntityValues[foundControl.attributeName]) {
              this.linkedEntityValues[foundControl.attributeName].push(accordionView);
            } else if (this.linkedEntityValues) {
              this.linkedEntityValues[foundControl.attributeName] = [accordionView];
            } else {
              this.linkedEntityValues = {
                [foundControl.attributeName]: [accordionView]
              };
            }
          }
        } catch (error) {
          console.log(error);
        }
        this._initViewData();
      }
    }
  }
  // Add existing Linked Data for Address in Contact CR or Account CR
  private async _addExistingLinkedDataForAddress(control: Control, isAdd: boolean, optionData?: any) {
    let linkEntityData;
    let referenceControls: Control[];
    let dataReq;
    let foundForm;
    let dataReqEntity;
    // #1 Contact CR - address
    if (control.subgrid.referencingAttribute == 'indskr_contactcr' && control.lookupEntityPrimaryId == "indskr_customeraddresscrid") {
      let mdmId = this.dynamicFormsService.mdmId || '';
      let contactId = this.contactService.contactInformation.ID || '';
      let filteredRawLEDataAddress;
      let filteredLEDataACAffiliation;
      if (mdmId) {
        try {
          let fetchXml = CONTACT_FETCH_QUERIES.searchAllAddressesForOneKey;
          fetchXml = fetchXml.replace('{{linked-entities}}', CONTACT_FETCH_QUERIES.customerLinkedEntitiesContactCR);
          fetchXml = fetchXml.split("{{query}}").join('').split("{mdmid}").join(mdmId);
          await this.dynamics.executeFetchQuery('indskr_addresses', fetchXml).then((res)=> {
              if (!_.isEmpty(res) && res[0] != undefined) {
                filteredRawLEDataAddress = res;
              }}
          );
        } catch(error){
          console.log(error);
        }
      }
      if (contactId) {
        let foundAccountContactAffiliationData;
        try {
          let fetchXml = CONTACT_FETCH_QUERIES.searchAccountContactAffiliations;
          fetchXml = fetchXml.split("{{query}}").join('').split("{contactid}").join(contactId);
          await this.dynamics.executeFetchQuery('indskr_accountcontactaffiliations', fetchXml).then((res) => {
            if (!_.isEmpty(res) && res[0] != undefined) {
              filteredLEDataACAffiliation = res;
            }}
            );
        } catch(error){
          console.log(error);
        }
        if (!_.isEmpty(filteredLEDataACAffiliation) && filteredLEDataACAffiliation[0] != undefined) {
          let filteredACAffiliationPrimaryData = filteredLEDataACAffiliation.filter(x => x.hasOwnProperty('indskr_isprimaryaccount') && x['indskr_isprimaryaccount'] == true);
          // set found Account-Contact Affilidation, if there is no primary Account-Contact Affiliation with OneKey, set non-primary Account-Contact Affiliation with OneKey
          foundAccountContactAffiliationData = !_.isEmpty(filteredACAffiliationPrimaryData) ? filteredACAffiliationPrimaryData : filteredLEDataACAffiliation || '';
        }
        if (!_.isEmpty(foundAccountContactAffiliationData) && foundAccountContactAffiliationData[0].hasOwnProperty('_indskr_accountid_value') || this.addedNewACAffiliation) {
          let selectedAccountId;
          if (!this.addedNewACAffiliation) {
            selectedAccountId = foundAccountContactAffiliationData[0]['_indskr_accountid_value'] || '';
          } else {
            selectedAccountId = optionData ? optionData.formValue['lookupfields'].filter(x => x.name =='indskr_accountid')[0].id || '' : '';
          }
          if (!_.isEmpty(selectedAccountId) && !_.isEmpty(filteredRawLEDataAddress) && filteredRawLEDataAddress[0] != undefined) {
            let accountIdAttrName: string = '';
            for (const rawObj of filteredRawLEDataAddress) {
              accountIdAttrName = Object.keys(rawObj).find(key=>rawObj[key]===selectedAccountId);
            }
            // filtered address depends on selected Affiliated Account ID
            let filteredRawLEDataAddressByAccountId = filteredRawLEDataAddress.filter(x => x.hasOwnProperty(accountIdAttrName) && x[accountIdAttrName] == selectedAccountId);
            // filtered primary address
            let filteredAddressPrimaryData = filteredRawLEDataAddressByAccountId.filter(x => x.hasOwnProperty('ao.indskr_isprimary') && x['ao.indskr_isprimary'] == true);
            // set linked Entity Data, if there is no primary address with OneKey, set non-primary address with OneKey
            linkEntityData = !_.isEmpty(filteredAddressPrimaryData) ? filteredAddressPrimaryData : filteredRawLEDataAddressByAccountId || '';
            if (!_.isEmpty(linkEntityData)) {
              this.isAutoPopulatedLinkedDataFlow = true;
              console.log("found existing OneKey linked data - address in Contact CR");
            }
          }
        }
      }
    }
    // #2 Account CR - address
    else if (control.subgrid.referencingAttribute == "indskr_accountcr" && control.lookupEntityPrimaryId == "indskr_customeraddresscrid") {
      let mdmId = (this.dynamicFormsService.mdmId) ? this.dynamicFormsService.mdmId : '';
      if (mdmId) {
        let filteredRawLEDataAddress;
        try {
          let fetchXml = CONTACT_FETCH_QUERIES.searchAllAddressesForOneKey;
          fetchXml = fetchXml.replace('{{linked-entities}}', CONTACT_FETCH_QUERIES.customerLinkedEntitiesAccountCR);
          fetchXml = fetchXml.split("{{query}}").join('').split("{mdmid}").join(mdmId);
          await this.dynamics.executeFetchQuery('indskr_addresses', fetchXml).then((res)=> {
              if (!_.isEmpty(res) && res[0] != undefined) {
                filteredRawLEDataAddress = res;
              }}
          );
        } catch(error){
            console.log(error);
        }
        if (!_.isEmpty(filteredRawLEDataAddress) && filteredRawLEDataAddress[0] != undefined) {
          // filtered primary address
          let filteredAddressPrimaryData = filteredRawLEDataAddress.filter(x => x.hasOwnProperty('ao.indskr_isprimary') && x['ao.indskr_isprimary'] == true);
          // set linked Entity Data, if there is no primary address with OneKey, set non-primary address with OneKey
          linkEntityData = !_.isEmpty(filteredAddressPrimaryData) ? filteredAddressPrimaryData : filteredRawLEDataAddress || '';
          if (!_.isEmpty(linkEntityData)) {
            this.hasExistingLinkedDataForAddressAccountCR = true;
            this.isAutoPopulatedLinkedDataFlow = true;
            console.log("found existing OneKey linked data - address in Account CR");
          }
        }
      }
    }
    // Set Address Edit form field if there is existing linked data for address - Account CR
    if (control.subgrid.referencingAttribute == 'indskr_accountcr' && control.lookupEntityPrimaryId == "indskr_customeraddresscrid"
      && !_.isEmpty(linkEntityData) && !this.addedNewAddressForAccountCR) {
      let targetControl: Control;
      for (let tabIdx = 0; tabIdx < this._dynamicForm.metadata.length; tabIdx++) {
        const tab = this._dynamicForm.metadata[tabIdx];
        if (tab && tab.controls && tab.controls.length > 0) {
          let foundControl = tab.controls.find(c => !c.dataType && c.lookupEntityPrimaryId == "indskr_customeraddresscrid");
          if (foundControl) {
            targetControl = foundControl;
          break;
          }
        }
      };
      if (targetControl) {
        if (targetControl.subgrid) {
          if (targetControl.subgrid.targetEntityType) {
            let foundFormForAddress = await this.dynamicFormsService.getFormDefinitionForEntity(targetControl.subgrid.targetEntityType, FormType.CREATEFORM);
            let foundCustomField = await this._getCustomFields(targetControl.subgrid.referencingEntity)
            if (foundFormForAddress && foundCustomField) {
              let rawFormEntityObject = null;
               // Hide CustomerAddress ChangeRequest
               if (targetControl.subgrid.targetEntityType == 'indskr_customeraddresscr') {
                for (let i = 0; i < this._dynamicFormForAddressEdit.metadata.length; i++) {
                  const itab = this._dynamicFormForAddressEdit.metadata[i];
                  if (itab && itab.controls && itab.controls.length > 0) {
                    itab.controls.filter(c => c.attributeName == 'indskr_addresscr' || c.lookupEntitySetName == 'indskr_contactcrs'
                      || c.lookupEntitySetName == 'indskr_accountcrs' ||  c.lookupEntitySetName == 'indskr_addresses').forEach(c => c.forceHide = true);
                  }
                };
              }
              // this.dynamicFormsService.mdmId = this._currentFormValue.indskr_mdmid;
              let displayName: any = this._getDisplayText(targetControl.displayNames);

              let formDetail: DynamicFormComponentModel = {
                title: _.startCase(displayName.toLowerCase()),
                showLeftHeaderButton: false,
                showSearchBar: false,
                leftHeaderBtnText: this.translate.instant('CANCEL'),
                LookupEntitySetName: foundFormForAddress.entitySetName,
                showRightHeaderButton: false,
                rightHeaderImgSrc: '',
                rightHeaderBtnText: '',
                formMetadata: foundFormForAddress,
                formEntityObject: rawFormEntityObject,
                mainEntityObjectId: null,
                linkedEntityParentData: this.getLinkEntityParentData(foundFormForAddress.entitySetName, targetControl),
                customFields: foundCustomField,
                dynamicFormType: DynamicFormType.CONFIGUREDFORM,
                navOptions: { animate: false },
                eventsHandler: (data: any, eventTarget: string, refData: DynamicFormComponentModel) => this._handleDynamicFormComponentEvent(data, eventTarget, refData),
              };
              this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
              if (formDetail) {
                this.formEditAddressDetail = formDetail;
              }
            }
          }
        }
      }
    }
    // Common data set flow
    foundForm = this._foundFormForAddressCR;
    if (foundForm && foundForm.metadata && foundForm.metadata[0].controls) {
      foundForm.metadata[0].controls.map(control => control.isCustom = true); // set as custom field
      const index = foundForm.metadata[0].controls.findIndex(control => control.attributeName == "indskr_name");
      if (index >= 0) {
        foundForm.metadata[0].controls.splice(index, 1);
      }
      referenceControls = [...foundForm.metadata[0].controls];
    }
    if (referenceControls && !_.isEmpty(linkEntityData)) {
      let setData;
      if (control.subgrid.referencingAttribute == "indskr_contactcr" || control.subgrid.referencingAttribute == "indskr_accountcr" && !isAdd) {
        setData = await this.setExistingAddressData(control, referenceControls, linkEntityData);
      }
      // Data is transferred when the save button is clicked (isAdd == true)
      if (isAdd && (control.subgrid.referencingAttribute == "indskr_contactcr" && setData || control.subgrid.referencingAttribute == "indskr_accountcr")) {
        let createLinkedEntity = await this.createLinkedEntityForAddress();
        if (createLinkedEntity) {
          this.dynamicFormsService.okAddressMapper.custom = this._existingCustomFormValue;
          this.dynamicFormsService.okAddressMapper.formatted = this._existingFormStringValues;
          // add linkEntities - push to linkEntitiesReqPayload through mdm service
          dataReqEntity = control.lookupEntitySetName;
          dataReq = {
          "entity": dataReqEntity,
          "values": [this._existingFormValue],
          }
          if (dataReq) {
            this._isFormSaved = false;
            this._isFormDirty = true;
            this.formDetail.eventsHandler(dataReq, 'ADDEDLINKEDENTITY', this.formDetail);
          }
        }
      }
    }

    // Set accordionView data
    let data = {
      customFormValue: this._existingCustomFormValue,
      formValue: this._existingFormValue,
      formStringValue: this._existingFormStringValues,
    }
    if (!_.isEmpty(linkEntityData) && (!_.isEmpty(this._existingCustomFormValue) || !_.isEmpty(this._existingFormValue) || !_.isEmpty(this._existingFormStringValues))) {
      let foundControl: Control;
      foundControl = control;
      if (foundControl) {
        try {
          let accordionView: OmniAccordionViewDataModel;
          let childItems: Array<any> = [];
          let abilityToRemoveRecords = foundControl.linkedEntityConfig && foundControl.linkedEntityConfig.abilityToRemoveRecords == '548910000'
          foundControl.subgrid.subgridLayout.forEach(attrib => {
            let attribName;
            let index = attrib.attribute.indexOf('.');
            if (index !== -1) {
              attribName = attrib.attribute.slice(index + 1);
            } else {
              attribName = attrib.attribute;
            }
            if (attribName && ((data.formStringValue && data.formStringValue[attribName]) || (data.formValue && data.formValue[attribName]) || (data.customFormValue && data.customFormValue[attribName]))) {
              if (accordionView) {
                let displayName: string = this._getDisplayText(attrib.displayNames);
                childItems.push({
                  id: foundControl.attributeName + '_' + attribName,
                  label: displayName ? displayName : '',
                  value: data.formStringValue && data.formStringValue[attribName] ? data.formStringValue[attribName] : (data.formValue && data.formValue[attribName]) || (data.customFormValue && data.customFormValue[attribName]),
                });
                accordionView.showExpandIcon = true;
                accordionView.showCollapseIcon = true;
              } else {
                accordionView = {
                  displayType: 'Accordion',
                  id: foundControl.attributeName + '_' + attribName + Math.random().toString(36).substring(7),
                  primaryText: data.formStringValue && data.formStringValue[attribName] ? data.formStringValue[attribName] : (data.formValue && data.formValue[attribName]) || (data.customFormValue && data.customFormValue[attribName]),
                  showExpandIcon: false,
                  showCollapseIcon: false,
                  isExpanded: false,
                  childItems: [],
                  scrapFromList: abilityToRemoveRecords,
                  clickHandler: (id, event, specificTarget, dataRef) => this.handleAccordionView(event, foundControl, specificTarget, dataRef)
                }
              }
            }
          });
          if (accordionView) {
            accordionView.childItems = childItems;
            if (this.linkedEntityValues && this.linkedEntityValues[foundControl.attributeName]) {
              if (foundControl.lookupEntitySetName == 'indskr_customeraddresscrs' && this.linkedEntityValues[foundControl.attributeName].length == 1) {
                delete this.linkedEntityValues[foundControl.attributeName] // delete duplicated data or update data
                this.linkedEntityValues[foundControl.attributeName] = [accordionView];
              } else this.linkedEntityValues[foundControl.attributeName].push(accordionView);
            } else if (this.linkedEntityValues) {
              this.linkedEntityValues[foundControl.attributeName] = [accordionView];
            } else {
              this.linkedEntityValues = {
                [foundControl.attributeName]: [accordionView]
              };
            }
          }
        } catch (error) {
          console.log(error);
        }
        this._initViewData(); // for updating add button
      }
    }
    if (!isAdd && control.subgrid.referencingAttribute == 'indskr_accountcr' && control.lookupEntityPrimaryId == "indskr_customeraddresscrid"
      && !_.isEmpty(linkEntityData) && !_.isEmpty(this.formEditAddressDetail)) {
        this._initViewEditFormData(true);
    }
    // Set label for editalbe existing address
    if (this._existingCustomFormValue && (this._existingCustomFormValue.indskr_composite || this._existingCustomFormValue.indskr_name)) {
      this.labelForExistingAddress = this._existingCustomFormValue.indskr_composite ? this._existingCustomFormValue.indskr_composite || '' : this._existingCustomFormValue.indskr_name || '';
    } else if (this._existingFormValue && (this._existingFormValue.indskr_composite || this._existingFormValue.indskr_name)) {
      this.labelForExistingAddress = this._existingFormValue.indskr_composite ? this._existingFormValue.indskr_composite || '' : this._existingFormValue.indskr_name || '';
    }
  }
  // Set Existing data for Account-Contact Affiliation
  private async setExistingACAffiliationData(selectedControl: Control, referenceControls: Control[], linkedData: any) {
    this._existingFormValue = {};
    this._existingCustomFormValue = {};
    this._existingFormStringValues = {};
    let formValueObj = {};
    if (selectedControl.subgrid.referencingAttribute == 'indskr_contactcr') {
      formValueObj = {
        name: 'indskr_ContactCR',
        entity: 'indskr_contactcrs',
        id: ''
      }
    }
    this._existingFormValue['lookupfields'] = [formValueObj];
    // Set account contact affiliation values for contact CR: _existingFormValue
    if (selectedControl.lookupEntityPrimaryId == 'indskr_accountcontactaffiliationcrid') {
      referenceControls.forEach(async control => {
        switch (control.dataType) {
          case ControlDataType.MoneyType:
          case ControlDataType.IntegerType:
          case ControlDataType.DecimalType:
          case ControlDataType.DoubleType:
          case ControlDataType.MemoType:
          case ControlDataType.StringType:
            if (linkedData[0][control.attributeName]) {
              if (control.isVisible && control.isRequired && !control.forceHide) {
                this._setExistingAttributeValue(control, linkedData[0][control.attributeName]);
              }
            }
            break;
          case ControlDataType.LookupType:
            if (linkedData[0]['_' + control.attributeName + '_value']) {
              if (control.isVisible && control.isRequired && !control.forceHide) {
                if(control.lookupEntityPrimaryId && control.lookupEntityPrimaryId == "omnione_onekeycodeslabelsid"){
                  if(linkedData[0]['_' + control.attributeName  + '_value'] && this.localizationService.multiLingualFieldsData && this.localizationService.multiLingualFieldsData.length > 0){
                    let foundRecord = this.localizationService.multiLingualFieldsData.find(a=> a['omnione_onekeycodeslabelsid'] == linkedData[0]['_' + control.attributeName  + '_value']);
                    let translatedLang: string = '';
                    // if(foundRecord) translatedLang = foundRecord[multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]] ? foundRecord[multilingualLanguageToAttributeMapping["dynamics_language_code_"+this.authService.user.localeId]] || '' : foundRecord['omnione_en_long_label'] || '';
                    let key = this.localizationService.getOneKeyTableAttributes();
                    if(foundRecord) translatedLang = foundRecord[key] ? foundRecord[key] || '' : foundRecord['omnione_en_long_label'] || '';
                    if(foundRecord && foundRecord['omnione_onekeycodeslabelsid'] && translatedLang){
                      const schemaFieldName = foundRecord['omnione_schemafieldname'] || '';
                      const onekeyCodesLabelsId = foundRecord['omnione_onekeycodeslabelsid'] || '';
                      const codIdOneKey = await this.dynamicFormsService.getLookupCodIdOnekey(schemaFieldName, onekeyCodesLabelsId) || '';
                      this._setExistingAttributeValue(control, onekeyCodesLabelsId, codIdOneKey);
                      this._setExistingAttributeStringValue(control.attributeName, translatedLang);
                    }
                  }
                }else{
                  this._setExistingAttributeValue(control, linkedData[0]['_' + control.attributeName + '_value']);
                  this._setExistingAttributeStringValue(control.attributeName, linkedData[0]['_' + control.attributeName + '_value@OData.Community.Display.V1.FormattedValue']);
                }
              }
            }
            break;
          case ControlDataType.BooleanType:
            if (linkedData[0] && linkedData[0].hasOwnProperty(control.attributeName)) {
              if (control.isVisible && control.isRequired && !control.forceHide) {
                (linkedData[0][control.attributeName]) ? this._setExistingAttributeValue(control, true) : this._setExistingAttributeValue(control, false);
              }
            }
            break;
          case ControlDataType.MultiSelectPicklistType:
            if (linkedData[0][control.attributeName]) {
              this._setExistingAttributeValue(control, linkedData[0][control.attributeName]);
              let str = linkedData[0][control.attributeName+'@OData.Community.Display.V1.FormattedValue'].replace(/;/g, ',');
              if (control.isVisible && control.isRequired && !control.forceHide) {
                this._setExistingAttributeStringValue(control.attributeName,str);
              }
            }
            break;
          case ControlDataType.PicklistType:
            if (linkedData[0][control.attributeName]) {
              if (control.isVisible && control.isRequired && !control.forceHide) {
                this._setExistingAttributeValue(control, linkedData[0][control.attributeName]);
                this._setExistingAttributeStringValue(control.attributeName, linkedData[0][control.attributeName + '@OData.Community.Display.V1.FormattedValue']);
              }
            }
            break;
          default:
            break;
        }
        if (control.attributeName == 'indskr_name') {
          let getName = linkedData[0]['_indskr_accountid_value_Formatted'] || '';
          if (getName) this._setExistingAttributeValue(control, getName);
        }
      });
    }
    return true;
  }
  // Set existing Address data
  private async setExistingAddressData(selectedControl: Control, referenceControls: Control[], linkedData: any) {
    this._existingFormValue = {};
    this._existingCustomFormValue = {};
    this._existingFormStringValues = {};
    let formValueObj = {};
    if (selectedControl.subgrid.referencingAttribute == 'indskr_contactcr') {
      formValueObj = {
        name: 'indskr_ContactCR',
        entity: 'indskr_contactcrs',
        id: ''
      }
    } else if (selectedControl.subgrid.referencingAttribute == 'indskr_accountcr') {
      formValueObj = {
        name: 'indskr_AccountCR',
        entity: 'indskr_accountcrs',
        id: ''
      }
    }
    this._existingFormValue['lookupfields'] = [formValueObj];
    // Set address values for account CR / contact CR: _existingCustomFormValue
    if (selectedControl.lookupEntityPrimaryId == 'indskr_customeraddresscrid') {
      referenceControls.forEach(control => {
        if (control.dataType == ControlDataType.LookupType) {
          let foundLookupId = linkedData[0]['_' + control.attributeName + '_value'] || '';
          let foundName = linkedData[0]['_' + control.attributeName + '_value@OData.Community.Display.V1.FormattedValue'] || '';
          if (control.attributeName == 'indskr_country_lu') {
            if (foundLookupId) {
              this.dynamicFormsService.getLookupResultsForCityBasedOnCountry(foundLookupId, control);
            }
          }
          if (foundLookupId) this._setExistingAttributeValue(control, foundLookupId);
          if (foundName) this._setExistingAttributeStringValue(control.attributeName, foundName);
        } else if (control.dataType == "StringType" || control.dataType == "MemoType") {
          let foundName = linkedData[0][control.attributeName] || '';
          if (control.attributeName == "indskr_composite" || control.attributeName == "indskr_compositecitystate") {
            if (foundName) {
              foundName = foundName.replace(/(\r\n|\n|\r)/gm, "");
              foundName = foundName.split(',').join(', ').replace(/\s\s+/g, ' ');
            }
          }
          if (foundName) {
            this._setExistingAttributeValue(control, foundName);
            if (control.attributeName == "indskr_composite" && selectedControl.subgrid.subgridLayout && selectedControl.subgrid.subgridLayout.filter(x=>x.attribute == 'indskr_name').length == 1) {
              this._existingFormValue = {
                ['indskr_name']: foundName,
              }
            }
          }
        }
      });
    }
    return true;
  }
  // Create LinkedEntity for Address which is Custom Form
  private async createLinkedEntityForAddress() {
    let { logicalCollectionName, logicalName, schemaName } = this.getAddressEntity();
    if (this._existingCustomFormValue) {
      const res = await this.dynamicFormsService.createLinkedEntity(this._existingCustomFormValue, logicalCollectionName);
      if (res) {
        const obj = {
          entity: logicalCollectionName,
          id: res['guid'],
          name: schemaName,
        };
        if (this._existingFormValue && this._existingFormValue['lookupfields']) {
          const idx = this._existingFormValue['lookupfields'].findIndex(x => x.entity == logicalCollectionName);
          if (idx >=0) {
            delete this._existingFormValue.lookupfields[idx];
            this._existingFormValue.lookupfields[idx] = obj;
          } else {
            this._existingFormValue['lookupfields'].push(obj);
          }
        } else {
          this._existingFormValue = {
            lookupfields: [obj],
          }
        }
        return true;
      }
    }
  }
  // Set existing values for Attribute
  private _setExistingAttributeValue(control: Control, value: any, codIdOnekey: string = '') {
    if (control.isCustom) {
      this._setExistingCustomAttributeValue(control, value, codIdOnekey);
      return;
    }
    let objName = control.isCustomAttribute ? control.schemaName : control.attributeName;
    let cfvObj = {
      name: objName,
      entity: control.lookupEntitySetName,
      id: value
    }
    if (this._existingFormValue) {
      if (control.dataType == ControlDataType.LookupType) {
        let crLookUp;
        if(codIdOnekey) {
          const entityName = control.isCustomAttribute ? control.schemaName : control.attributeName;
          crLookUp = {
            entity: entityName,
            codIdOnekey: codIdOnekey,
            guid: value
          };
        }
        if (this._existingFormValue.hasOwnProperty('lookupfields')) {
          const idx = this._existingFormValue['lookupfields'].findIndex(a => a.name == objName);
          if (idx >= 0) {
            const prevGuid = this._existingFormValue['lookupfields'][idx].id || '';
            this._existingFormValue['lookupfields'][idx] = cfvObj;
            if(prevGuid && codIdOnekey && this.dynamicFormsService.okLookUpMapper) this.dynamicFormsService.addLookupOnekeyData(prevGuid, crLookUp);
          } else {
            this._existingFormValue['lookupfields'].push(cfvObj);
            if(codIdOnekey) this.dynamicFormsService.okLookUpMapper.push(crLookUp);
          }
        } else {
          this._existingFormValue['lookupfields'] = [cfvObj];
          if(codIdOnekey) this.dynamicFormsService.okLookUpMapper = [crLookUp];
        }
      } else {
        if ((value === '' || value == undefined || value == null) && control.isRequired) {
          delete this._existingFormValue[control.attributeName];
        } else {
          this._existingFormValue[control.attributeName] = value;
        }
      }
    } else {
      if (control.dataType == ControlDataType.LookupType) {
        this._existingFormValue = { lookupfields: [cfvObj] };
      } else {
        if ((value === '' || value == undefined || value == null) && control.isRequired) return;
        this._existingFormValue = {
          [control.attributeName]: value,
        }
      }
    }
    if (!_.isEmpty(this.formEditAddressDetail)) {
      this._initViewEditFormData();
    }
    if (control.attributeName =='indskr_composite' || control.attributeName =='indskr_name') {
      this.labelForExistingAddress = this._existingFormValue.indskr_composite ? this._existingFormValue.indskr_composite || '' : this._existingFormValue.indskr_name || '';
    }
  }
  // Set existing values for Custom Attribute
  private _setExistingCustomAttributeValue(control: Control, value: any, codIdOnekey: string = '') {
    let cfvObj = {
      name: control.schemaName,
      entity: control.lookupEntitySetName,
      id: value
    };
    if (control.lookupEntitySetName == "indskr_lu_countries") {
      this.dynamicFormsService.selectedCountry = {};
      this.dynamicFormsService.selectedCountry = cfvObj;
    } else if (control.lookupEntitySetName == "indskr_lu_cities") {
      this.dynamicFormsService.selectedCity = {};
      this.dynamicFormsService.selectedCity = cfvObj;
    }
    if (this._existingCustomFormValue) {
      let crLookUp;
      if (control.dataType == ControlDataType.LookupType) {
        if(codIdOnekey) {
          const entityName = control.isCustomAttribute ? control.schemaName : control.attributeName;
          crLookUp = {
            entity: entityName,
            codIdOnekey: codIdOnekey,
            guid: value
          };
        }
        if (this._existingCustomFormValue.hasOwnProperty('lookupfields')) {
          const idx = this._existingCustomFormValue['lookupfields'].findIndex(a => a.name == control.schemaName);
          if (idx >= 0) {
            const prevGuid = this._existingCustomFormValue['lookupfields'][idx].id || '';
            this._existingCustomFormValue['lookupfields'][idx] = cfvObj;
            if(prevGuid && codIdOnekey && this.dynamicFormsService.okLookUpMapper) this.dynamicFormsService.addLookupOnekeyData(prevGuid, crLookUp);
          } else {
            this._existingCustomFormValue['lookupfields'].push(cfvObj);
            if(codIdOnekey) this.dynamicFormsService.okLookUpMapper.push(crLookUp);
          }
        } else {
          this._existingCustomFormValue['lookupfields'] = [cfvObj];
          if(codIdOnekey) this.dynamicFormsService.okLookUpMapper = [crLookUp];
        }
      } else {
        if ((value === '' || value == undefined || value == null) && control.isRequired) {
          delete this._existingCustomFormValue[control.attributeName];
        } else {
        this._existingCustomFormValue[control.attributeName] = value;
        // updated name on Dynamics
          if (control.attributeName == 'indskr_name') {
            this._existingFormValue['indskr_name'] = value;
          }
        }
      }
    } else {
      if (control.dataType == ControlDataType.LookupType) {
        this._existingCustomFormValue = { lookupfields: [cfvObj] };
      } else {
        this._existingCustomFormValue = {
          [control.attributeName]: value,
        }
      }
    }
    if (!_.isEmpty(this.formEditAddressDetail)) {
      this._initViewEditFormData();
    }
    if (control.attributeName =='indskr_composite' || control.attributeName =='indskr_name') {
      this.labelForExistingAddress = this._existingCustomFormValue.indskr_composite ? this._existingCustomFormValue.indskr_composite || '' : this._existingCustomFormValue.indskr_name || '';
    }
  }
  // Set existing values for Attribute String
  private _setExistingAttributeStringValue(attributeName: string, value: any) {
    if (this._existingFormStringValues) {
      if (attributeName === 'statecode' || attributeName === 'statuscode') {
        this._existingFormStringValues[attributeName] = this.dynamicFormsService.translatedValue(value);
      } else {
        this._existingFormStringValues[attributeName] = value;
      }
    } else {
      this._existingFormStringValues = {
        [attributeName]: value,
      }
    }
    if (!_.isEmpty(this.formEditAddressDetail)) {
      this._initViewEditFormData();
    }
  }
  private _setAddressWithSelectedCity(selectedItem: any) {
    let foundForm = this._foundFormForAddressCR;
    let referenceControls: Control[];
    this._stateIdBasedOnCity = selectedItem.stateId || '';
    if (foundForm && foundForm.metadata && foundForm.metadata[0].controls) {
      foundForm.metadata[0].controls.map(control => control.isCustom = true); // set as custom field
      const index = foundForm.metadata[0].controls.findIndex(control => control.attributeName == "indskr_name");
      if (index >= 0) {
        foundForm.metadata[0].controls.splice(index, 1);
      }
      referenceControls = [...foundForm.metadata[0].controls];
    }

    referenceControls.forEach(control => {
      if (control.lookupEntityPrimaryId == 'indskr_lu_countryid') {
        let countryId = selectedItem.countryId || '';
        let countryName = selectedItem.countryName || '';
        if (!this.isHandleEditAddressFormField) {
          if (countryId) this._setAttributeValue(control, countryId, false);
          if (countryName) {
            this._setAttributeStringValue(control.attributeName, countryName);
            this.dynamicFormsService.isSelectedCountry = true;
          }
        } else {
          if (countryId) this._setExistingAttributeValue(control, countryId);
          if (countryName) this._setExistingAttributeStringValue(control.attributeName, countryName);
        }
      }
      if (control.attributeName == 'indskr_compositecitystate') {
        let compositeCityState = selectedItem.stateName || '';
        if (!this.isHandleEditAddressFormField) {
          if (compositeCityState) this._setAttributeValue(control, compositeCityState);
        } else {
          if (compositeCityState) this._setExistingAttributeValue(control, compositeCityState);
        }
      }
    });
    if (!_.isEmpty(this.formEditAddressDetail)) {
      this._initViewEditFormData();
    }
  }
  // For address edit form view
  public expandChildItems(event,value){
    this.isExpandedCurrentEditFormView = value;
  }
  //Auto-populated Existing Linked Data flow - #end

  private _validateMandatoryField(id, value, event, isEditAddressForm?: boolean): boolean {
    let fieldValue;
    if (event.value) {
      fieldValue = event.value;
    } else if (event.target) {
      fieldValue = event.target.value;
    }
    const formViewList = !isEditAddressForm ? this.currentFormView : this.currentEditFormView;
    if (fieldValue === null || fieldValue === undefined) {
      formViewList.forEach(formView => {
        if(formView.id == id) {
          formView.view.isInvalid = true;
          formView.view.isEmptyRequiredField = true;
        }
      })
      this.errorMessage = this.translate.instant('THIS_IS_A_REQUIRED_FIELD');
      return false;
    } else {
      formViewList.forEach(formView => {
        if(formView.id == id) formView.view.isInvalid = false;
      })
      return true;
    }
  }

  // Edit - Bussiness - Linked Entity
  private async _initializeSubgridData(control: Control) {
    if (this.LINKED_ENTITY_DB_PREFIX) this.linkedEntityDBPrefix = this.LINKED_ENTITY_DB_PREFIX;
    if (this.RAW_DATA) this.rawData = this.RAW_DATA;
    if (this.REF_ENTITY_NAME) this.referencingEntityName = this.REF_ENTITY_NAME;
    let abilityToRemoveRecords = control.linkedEntityConfig && control.linkedEntityConfig.abilityToRemoveRecords == '548910000'
    const refEntityNamesAffiliations = [
      ACCOUNT_ACCOUNT_AFFILIATIONS_REF_ENTITY,
      CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY,
    ];
    const isThisControlAffiliations = refEntityNamesAffiliations.includes(control.subgrid.referencingEntity);
    const dbKey: string = isThisControlAffiliations ? control.subgrid.relationshipName : control.subgrid.referencingEntity;

    let rawLEData;
    if (this.rawData && this.rawData.hasOwnProperty('globalSearchedDataLinkEntities')) {
      let raw = this.rawData['globalSearchedDataLinkEntities'][this.linkedEntityDBPrefix + control.subgrid.referencingEntity]
              ?? this.rawData['globalSearchedDataLinkEntities'][this.linkedEntityDBPrefix + control.subgrid.relationshipName]
              ?? [];

      rawLEData = {
        raw
      };
    } else {
      rawLEData = await this.disk.retrieve(this.linkedEntityDBPrefix + dbKey);
    }

    let linkEntityData;
    if (rawLEData && rawLEData.raw) {
        linkEntityData = (rawLEData.raw).filter(x => x[this.referencingEntityName] == this.rawData[this.referencingEntityName] && (!x.hasOwnProperty(control.subgrid.referencingEntity + '.statecode') || (x.hasOwnProperty(control.subgrid.referencingEntity + '.statecode') && x[control.subgrid.referencingEntity + '.statecode'] == 0)));
    }
    if (linkEntityData) {
      /*************Remove Duplicates from Display form - Offline Edit*************/
      if(this.formDetail.formMetadata.entityName == 'contact'){
        if(dbKey === 'indskr_email_address') {
          linkEntityData = _.uniqBy(linkEntityData, function (e) {
            return  e['indskr_email_address.indskr_emailaddress'];
          });
        }
        else if(dbKey === 'indskr_accountcontactaffiliation') {
          linkEntityData = _.uniqBy(linkEntityData, function (e) {
            return  e['indskr_accountcontactaffiliation.indskr_accountid_Formatted'];
          });
        }
      }
      else if(this.formDetail.formMetadata.entityName == 'indskr_contactcr'){
        if(dbKey === 'indskr_emailaddresscr') {
          linkEntityData = _.uniqBy(linkEntityData, function (e) {
            return  e['indskr_emailaddresscr.indskr_name'];
          });
        }
        else if(dbKey === 'indskr_accountcontactaffiliationcr') {
          linkEntityData = _.uniqBy(linkEntityData, function (e) {
            return  e['indskr_accountcontactaffiliationcr.indskr_accountid_Formatted'];
          });
        }
        else if(dbKey === 'indskr_customerspecialtychangerequest') {
          linkEntityData = _.uniqBy(linkEntityData, function (e) {
            return  e['indskr_customerspecialtychangerequest.omnione_lu_specialties_value'];
          });
        }
      }
      /*************Remove Duplicates from Display form - Offline Edit*************/

      // START - sort
      const shouldAllowSorting: boolean = true;
      if (shouldAllowSorting) {
        let JSONQuery;
        let entityName: String = '';
        const sortOptions = [];
        XML2JS.parseString(control.subgrid.subgridQuery, (err, data) => {
          if (!err) {
            JSONQuery = data;
            if (JSONQuery) {
              entityName = JSONQuery.fetch.entity[0].$.name;
              if (JSONQuery.fetch.entity[0]['order'] && JSONQuery.fetch.entity[0]['order'].length) {
                JSONQuery.fetch.entity[0]['order'].forEach(sort => {
                  sortOptions.push(sort);
                });
              }
            }
          }
        });
        if (sortOptions.length) {
          let generatedSortingFields = [];
          sortOptions.forEach(field => {
            const fieldName = entityName + '.' + field.$.attribute;
            const formattedField = fieldName + '@OData.Community.Display.V1.FormattedValue';
            const hasFormattedField = (linkEntityData.filter(ret => ret[formattedField]).length > 0);
            generatedSortingFields.push(field.$.descending === 'true' ? (hasFormattedField ? '-' + formattedField : '-' + fieldName) : (hasFormattedField ? formattedField : fieldName));
          });
          linkEntityData.sort(compareBy(...generatedSortingFields));
        }
      }
      // END
      if (this.linkedEntityValues && this.linkedEntityValues[control.attributeName]) this.linkedEntityValues[control.attributeName] = [];
      linkEntityData.forEach(leData => {
        let editFormExistingDataViewCard: OmniAccordionViewDataModel;
        let childItems: Array<any> = [];
        let foundLinkedEntityIdAttribName: string;
        let foundAddressLinkedEntityIdAttribName: string;
        let formattedPrimaryText: string;
        let primaryTextindskrName: string;
        foundLinkedEntityIdAttribName = control.subgrid.referencingEntity + '.' + control.subgrid.referencingEntity + 'id';
        if (control.subgrid.referencingEntity == 'indskr_indskr_customeraddress_v2') {
          foundAddressLinkedEntityIdAttribName = control.subgrid.referencingEntity + '.indskr_address';
        }
        // set primary text value
        switch(control.subgrid.referencingEntity) {
          case 'indskr_email_address':
            formattedPrimaryText = leData['indskr_email_address.indskr_emailaddress'] || '';
            break;
          case 'indskr_indskr_customeraddress_v2':
            formattedPrimaryText = leData['indskr_address.indskr_composite'] || leData['indskr_indskr_customeraddress_v2.indskr_name'];
            break;
          case 'indskr_customerspecialty':
            formattedPrimaryText = leData['indskr_customerspecialty.indskr_specialtyid@OData.Community.Display.V1.FormattedValue'] || '';
            break;
          case 'indskr_contactrelationship':
              formattedPrimaryText = leData['indskr_contactrelationship.indskr_contactid@OData.Community.Display.V1.FormattedValue'] || '';
              break;
          case 'indskr_accountcontactaffiliation':
            if (control.subgrid.referencingAttribute == 'indskr_contactid') {
              formattedPrimaryText = leData['indskr_accountcontactaffiliation.indskr_accountid@OData.Community.Display.V1.FormattedValue'] || '';
            } else if (control.subgrid.referencingAttribute == 'indskr_accountid') {
              formattedPrimaryText = leData['indskr_accountcontactaffiliation.indskr_contactid@OData.Community.Display.V1.FormattedValue'] || '';
            }
            break;
          case 'indskr_accountaccountaffiliation':
            if (control.subgrid.referencingAttribute == 'indskr_affiliatedtoaccountid') {
              formattedPrimaryText = leData['indskr_accountaccountaffiliation.indskr_affiliatedfromaccountid@OData.Community.Display.V1.FormattedValue'] || '';
            } else if (control.subgrid.referencingAttribute == 'indskr_affiliatedfromaccountid')
            formattedPrimaryText = leData['indskr_accountaccountaffiliation.indskr_affiliatedtoaccountid@OData.Community.Display.V1.FormattedValue'] || '';
            break;
          case 'indskr_customerposition':
            formattedPrimaryText = leData['indskr_customerposition.indskr_positionid@OData.Community.Display.V1.FormattedValue'] || '';
            break;
          default:
            break;
        }
        control.subgrid.subgridLayout.forEach(attrib => {
          let attribName;
          let index = attrib.attribute.indexOf(".");
          if (index !== -1) {
            attribName = leData[attrib.attribute + "@OData.Community.Display.V1.FormattedValue"] ?
              attrib.attribute + "@OData.Community.Display.V1.FormattedValue" : attrib.attribute;
          } else {
            attribName = control.subgrid.referencingEntity + "." + attrib.attribute;
            if(leData[attribName + "@OData.Community.Display.V1.FormattedValue"]) {
              attribName += "@OData.Community.Display.V1.FormattedValue";
            }else if(leData[attribName + "_value"]) {
              attribName += "_value";
            }
            if(control.subgrid.referencingEntity == "indskr_indskr_customeraddress_v2") {
              let addressAttribName = "indskr_address" + "." + attrib.attribute;
              if(leData[addressAttribName +"@OData.Community.Display.V1.FormattedValue"]){
                attribName = addressAttribName;
                attribName += "@OData.Community.Display.V1.FormattedValue";
              }
            }
          }
          //As new requirement, New Lookup should be displayed instead of Role option set.
          if (attribName && !leData[attribName]) {
            //If display form is not updated, but create/edit form is updated
            switch (attribName) {
              case "indskr_accountcontactaffiliation.indskr_contactrole":
                attrib.attribute = "omnione_role";
                break;
              case "indskr_accountcontactaffiliation.omnione_role":
                attrib.attribute = "indskr_contactrole";
                break;
              case "indskr_contactrelationship.indskr_relationship":
                attrib.attribute = "omnione_relationship";
                break;
              case "indskr_contactrelationship.omnione_relationship":
                attrib.attribute = "indskr_relationship";
                break;
              case "indskr_accountaccountaffiliation.indskr_type":
                attrib.attribute = "omnione_relationship";
                break;
              case "indskr_accountaccountaffiliation.omnione_relationship":
                attrib.attribute = "indskr_type";
                break;
            }
            attribName = control.subgrid.referencingEntity + "." + attrib.attribute;
            attribName += leData[attribName + "@OData.Community.Display.V1.FormattedValue"] ? "@OData.Community.Display.V1.FormattedValue" : "";
          }
          if (attribName && leData[attribName]) {
            if (editFormExistingDataViewCard) {
              this._setViewCardData(childItems, attrib, control, attribName, leData);
              if (attrib.attribute == "indskr_name" && leData[attribName]) {
                primaryTextindskrName = leData[attribName];
              }
            } else {
              if (attrib.attribute == "indskr_name" && leData[attribName]) {
                primaryTextindskrName = leData[attribName];
              }
              let existingDataId;
              let existingAddressDataId;
              if (foundLinkedEntityIdAttribName) {
                existingDataId = leData[foundLinkedEntityIdAttribName] || '';
              }
              if (foundAddressLinkedEntityIdAttribName) {
                existingAddressDataId = leData[foundAddressLinkedEntityIdAttribName] || '';
              }
              const referencingEntity = control.subgrid.referencingEntity;
              const referencingAttribute = control.subgrid.referencingAttribute;
              const lookupFieldActualValue: string = this._getLookupFieldActualValue(leData, referencingEntity, referencingAttribute, true);
              editFormExistingDataViewCard = {
                displayType: 'Accordion',
                id: control.attributeName + '_' + attribName + Math.random().toString(36).substring(7),
                linkedEntityExistingDataId: existingDataId,
                linkedEntityExistingDataAddressId: existingAddressDataId,
                primaryText: formattedPrimaryText ? formattedPrimaryText : primaryTextindskrName ? primaryTextindskrName : leData[attribName],
                lookupFieldActualValue: lookupFieldActualValue,
                showExpandIcon: false,
                showCollapseIcon: false,
                isExpanded: false,
                childItems: [],
                rawObjData: leData,
                isDeactivate: false,
                scrapFromList: abilityToRemoveRecords,
                clickHandler: (id, event, specificTarget, dataRef) => this.handleAccordionView(event, control, specificTarget, dataRef)
              };
              if(control.subgrid.referencingEntity=='indskr_setbookingproduct'){
                if(leData['indskr_setbookingproduct.indskr_productid']){
                  editFormExistingDataViewCard.primaryText += ' | '+leData['indskr_setbookingproduct.indskr_productid'];
                }
                editFormExistingDataViewCard.primaryText += ' | '+this.translate.instant('Quantity')+' - '+leData['indskr_setbookingproduct.indskr_quantity'];
                editFormExistingDataViewCard.disableEditArrow = true;
              }
            }
          }
        });
        if (editFormExistingDataViewCard) {
          editFormExistingDataViewCard.childItems = childItems;
          if (this.linkedEntityValues && this.linkedEntityValues[control.attributeName]) {
            this.linkedEntityValues[control.attributeName].push(editFormExistingDataViewCard);
          } else if (this.linkedEntityValues) {
            this.linkedEntityValues[control.attributeName] = [editFormExistingDataViewCard];
          } else {
            this.linkedEntityValues = {
              [control.attributeName]: [editFormExistingDataViewCard]
            };
          }
        }
      });
    }
    return this.linkedEntityValues;
  }
  private _getLookupFieldActualValue(lookupData: any, referencingEntity: string, referencingAttribute: string, isExstingData: boolean): string {
    let actualValue: string = '';
    let targetName = [];
    if(referencingEntity.includes('accountcontact')) {
      if(referencingAttribute.includes('contact')) {
        targetName = ['indskr_accountid', 'accountid'];
      } else if(referencingAttribute.includes('account')) {
        targetName = ['indskr_contactid', 'contactid'];
      }
    } else if(referencingEntity.includes('special')) {
      targetName = ['omnione_lu_specialties', 'specialtyid'];
    } else if(referencingEntity.includes('contactrelation')) {
      targetName = ['indskr_relatedcontactid', 'relatedcontact'];
    } else if(referencingEntity.includes('accountaccount')) {
      if(referencingAttribute.includes('to')) {
        targetName = ['indskr_affiliatedfromaccountid', 'fromaccountid'];
      } else if(referencingAttribute.includes('from')) {
        targetName = ['indskr_affiliatedtoaccountid', 'toaccountid'];
      }
    }
    if(isExstingData) {
      actualValue = lookupData[referencingEntity + '.' + targetName[0]] || '';
      if(actualValue == '') actualValue = lookupData[referencingEntity + '.' + targetName[1]] || '';
    } else {
      let foundData = lookupData.find(ld=>ld.name == targetName[0]);
      if(foundData) {
        actualValue = foundData.id || '';
      } else {
        foundData = lookupData.find(ld=>ld.name.includes(targetName[1]));
        if(foundData) actualValue = foundData.id || '';
      }
    }
    return actualValue;
  }

  private _setViewCardData(childItems: any[], attrib: SubgridLayout, control: Control, attribName: string, leData: any) {
    let displayName: string = this._getDisplayText(attrib.displayNames);
    if (childItems.findIndex(item => item.id === control.attributeName + '_' + attribName) == -1) {
      childItems.push({
        id: control.attributeName + '_' + attribName,
        label: displayName ? displayName : '',
        value: leData[attribName],
      });
    }
  }

  public async openEditFormExistingData(ev, item) {
    let targetControl: Control;
    let id = item.id.toLowerCase();
    if (id) {
      for (let tabIdx = 0; tabIdx < this._dynamicForm.metadata.length; tabIdx++) {
        const tab = this._dynamicForm.metadata[tabIdx];
        if (tab && tab.controls && tab.controls.length > 0) {
          let foundControl = tab.controls.find(c => {
            if(!c.dataType) {
              const currAttributeName = c.attributeName.toLowerCase();
              const isAddress: boolean = currAttributeName.includes('address') && !currAttributeName.includes('email');
              if(!isAddress) {
                return id.includes(currAttributeName);
              }else {
                return id.includes(currAttributeName) && !id.includes('email');
              }
            }
          });
          if (foundControl) {
            targetControl = foundControl;
            break;
          } else {
            foundControl = tab.controls.find(c => {
              if(!c.dataType) {
                const currAttributeName = c.attributeName.toLowerCase();
                return id.includes(currAttributeName);
              }
            });
            if (foundControl) {
              targetControl = foundControl;
              break;
            }
          }
        }
      };
      if (targetControl) {
        if (this.editConfig == EditConfig.ADD_NEW && targetControl.lookupEntitySetName != 'indskr_setbookingproducts') return;
        if (this.formDetail.formMetadata.entityName == 'account') {
          this.trackingService.tracking('EditSelected', TrackingEventNames.ACCOUNT_EDIT, true);
        } else if (this.formDetail.formMetadata.entityName == 'contact') {
          this.trackingService.tracking('EditSelected', TrackingEventNames.CONTACT_EDIT, true);
        }
        if (targetControl.subgrid) {
            if (targetControl.subgrid.targetEntityType) {
              let foundForm;
              let formType;
              if (this.formDetail.dynamicFormType == DynamicFormType.CONFIGUREDFORM) {
                foundForm = await this.dynamicFormsService.getFormDefinitionForEntity(targetControl.subgrid.targetEntityType, FormType.CREATEFORM);
                formType = DynamicFormType.CONFIGUREDFORM;
              } else if (this.formDetail.dynamicFormType == DynamicFormType.DEFAULTFORM) {
                let ff = SHARED_CREATE_FORM.find(f => f.indskr_entityname == targetControl.subgrid.targetEntityType)
                if (ff) {
                  foundForm = new DynamicForm(ff)
                  formType = DynamicFormType.DEFAULTFORM;
                };
              }

              if (foundForm) {
                const targetAttributeName: string = targetControl.attributeName;
                let rawFormEntityObject = null;
                //Account to Contact Affiliation - Prefill Account and make Contact as required field
                if (this.formDetail.formMetadata.entityName == 'account' && targetControl.subgrid.targetEntityType == 'indskr_accountcontactaffiliation') {
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    this.removeOldOptionSetIfLookupIsPresent(itab, foundForm, i, "omnione_role", "indskr_contactrole");
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundAccountControlIdx = itab.controls.findIndex(c => c.attributeName == 'indskr_accountid');
                      if (foundAccountControlIdx >= 0) {
                        itab.controls[foundAccountControlIdx].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundAccountControlIdx].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundAccountControlIdx].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        break;
                      }
                    }
                  };
                }

                //Contact to Account Affiliation - Prefill Contact and make Account as required field
                if (this.formDetail.formMetadata.entityName == 'contact' && targetControl.subgrid.targetEntityType == 'indskr_accountcontactaffiliation') {
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    this.removeOldOptionSetIfLookupIsPresent(itab, foundForm, i, "omnione_role", "indskr_contactrole");
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIdx = itab.controls.findIndex(c => c.attributeName == 'indskr_contactid');
                      if (foundControlIdx >= 0) {
                        itab.controls[foundControlIdx].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        break;
                      }
                    }
                  };
                }

                //Hide CustomerAddress
                if (targetControl.subgrid.targetEntityType == 'indskr_indskr_customeraddress_v2') {
                  // this.dynamicFormsService.mdmId = this._currentFormValue.indskr_mdmid;
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIndex = itab.controls.findIndex(c => c.attributeName == 'indskr_address');
                      if (foundControlIndex >= 0) {
                        itab.controls[foundControlIndex].forceHide = true;
                        break;
                      }
                    }
                  };
                }

                //Hide EmailAddress
                if (targetControl.subgrid.targetEntityType == 'indskr_email_address') {
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIndex = itab.controls.findIndex(c => c.lookupEntitySetName == this.formDetail.LookupEntitySetName);
                      if (foundControlIndex >= 0) {
                        itab.controls[foundControlIndex].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundControlIndex].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundControlIndex].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        // break;
                      }
                    }
                  };
                }

                //Account to Account Affiliation - Prefill Contact and make Account as required field
                if (this.formDetail.formMetadata.entityName == 'account' && targetControl.subgrid.targetEntityType == 'indskr_accountaccountaffiliation') {
                  let attribName = targetControl.subgrid.referencingAttribute;
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    this.removeOldOptionSetIfLookupIsPresent(itab, foundForm, i, 'omnione_relationship', 'indskr_type');
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIdx = itab.controls.findIndex(c => c.attributeName == attribName);
                      if (foundControlIdx >= 0) {
                        itab.controls[foundControlIdx].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        break;
                      }
                    }
                  };
                }

                //Contact to Contact Affiliation - Prefill Parent Contact and make Child contact as required field
                if ((this.formDetail.formMetadata.entityName == 'contact' && targetControl.subgrid.targetEntityType == 'indskr_contactrelationship')
                || (this.formDetail.formMetadata.entityName == 'indskr_contactcr' && targetControl.subgrid.targetEntityType == 'indskr_contactrelationshipcr')) {
                  let attribName = targetControl.subgrid.referencingAttribute;
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    this.removeOldOptionSetIfLookupIsPresent(itab, foundForm, i, "omnione_relationship", this.formDetail.formMetadata.entityName == 'contact' ? "indskr_relationship" : "indskr_relationshiptype");
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIdx = itab.controls.findIndex(c => c.attributeName == attribName);
                      if (foundControlIdx >= 0) {
                        itab.controls[foundControlIdx].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        break;
                      }
                    }
                  };
                }

                //Customer Specialty
                if (targetControl.subgrid.targetEntityType == 'indskr_customerspecialty') {
                  let attribName = targetControl.subgrid.referencingAttribute;
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIdx = itab.controls.findIndex(c => c.attributeName == attribName);
                      if (foundControlIdx >= 0) {
                        itab.controls[foundControlIdx].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        break;
                      }
                    }
                  };
                }
                //Check linked entity existing data address Id
                let foundAddressLinkedEntityId;
                if (targetControl.subgrid.targetEntityType == 'indskr_indskr_customeraddress_v2') {
                  if (item.rawObjData && item.rawObjData.formValue && item.rawObjData.formValue.lookupfields) {
                    let checkedEntity = item.rawObjData.formValue.lookupfields.filter(x=>x.entity == "indskr_addresses");
                    if (!_.isEmpty(checkedEntity)) {
                      foundAddressLinkedEntityId = checkedEntity[0].id || '';
                    }
                  }
                }

                //Contact to Account Affiliation change request - Prefill Contact change request and make Account as required field
                if (this.formDetail.formMetadata.entityName == 'indskr_contactcr' && targetControl.subgrid.targetEntityType == 'indskr_accountcontactaffiliationcr') {
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    this.removeOldOptionSetIfLookupIsPresent(itab, foundForm, i, "indskr_affiliationrole", "indskr_contactrole");
                    if (itab && itab.controls && itab.controls.length > 0) {
                      rawFormEntityObject = this.getFieldsToDisplayForContactAccountAff(itab, rawFormEntityObject, 'indskr_contactcr', 'indskr_accountid');
                    }
                  };
                }

                //Hide CustomerAddress ChangeRequest
                if (targetControl.subgrid.targetEntityType == 'indskr_customeraddresscr') {
                  // this.dynamicFormsService.mdmId = this._currentFormValue.indskr_mdmid;
                  this.dynamicFormsService.hasCityDataBasedOnCountry = false;
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    if (itab && itab.controls && itab.controls.length > 0) {
                      itab.controls.filter(c => c.attributeName == 'indskr_addresscr' || c.lookupEntitySetName == 'indskr_addresses' ||
                        c.lookupEntitySetName == 'indskr_contactcrs'
                        || c.lookupEntitySetName == 'indskr_accountcrs').forEach(c => {
                          if (c.lookupEntitySetName == this.formDetail.LookupEntitySetName) {
                            rawFormEntityObject = {
                              ['_' + c.attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                              ['_' + c.attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                            };
                          }
                          c.forceHide = true;
                        })
                    }
                  };
                }

                //Hide EmailAddress ChangeRequest "indskr_accountcrs", "indskr_contactcrs"
                if (targetControl.subgrid.targetEntityType == 'indskr_emailaddresscr') {
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIndex = itab.controls.findIndex(c => c.lookupEntitySetName == this.formDetail.LookupEntitySetName);
                      if (this.formDetail.LookupEntitySetName == 'indskr_accountcrs') {
                        const controlIndexToHide = itab.controls.findIndex(c => c.lookupEntitySetName == 'indskr_contactcrs');
                        if (controlIndexToHide >= 0) {
                          itab.controls[controlIndexToHide].forceHide = true;
                        }
                      }
                      if (this.formDetail.LookupEntitySetName == 'indskr_contactcrs') {
                        const controlIndexToHide = itab.controls.findIndex(c => c.lookupEntitySetName == 'indskr_accountcrs');
                        if (controlIndexToHide >= 0) {
                          itab.controls[controlIndexToHide].forceHide = true;
                        }
                      }
                      if (foundControlIndex >= 0) {
                        itab.controls[foundControlIndex].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundControlIndex].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundControlIndex].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        // break;
                      }
                    }
                  };
                }

                //Set Booking to Product Association - Prefill Set Booking Guid
                if (this.formDetail.formMetadata.entityName == 'indskr_setbooking' && targetControl.subgrid.targetEntityType == 'indskr_setbookingproduct') {
                  let attribName = targetControl.subgrid.referencingAttribute;
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    const nonVisibleFormSection = this.setBookingDataService.formSectionConfigure?.filter(config => config['_indskr_mobileappform_value'] == this.formDetail.formMetadata.mobileFormId && !config['indskr_visibility']);
                    itab.controls.forEach(control => {
                      if (control.dataType && control.attributeName && !_.isEmpty(nonVisibleFormSection) && nonVisibleFormSection.some(sec => sec['indskr_name'].toLowerCase() == control.attributeName.toLowerCase())) {
                        control.isVisible = false;
                        control.forceHide = true;
                      } else if (!control.dataType && control.subgrid && !_.isEmpty(nonVisibleFormSection) && nonVisibleFormSection.some(sec => sec['indskr_name'].toLowerCase() == control.attributeName.toLowerCase())) {
                        control.isVisible = false;
                        control.forceHide = true;
                      }
                    });
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIdx = itab.controls.findIndex(c => c.attributeName == attribName);
                      if (foundControlIdx >= 0) {
                        itab.controls[foundControlIdx].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        break;
                      }
                    }
                  };
                  let surgeonaccountzsurValue = this._getAttributeValue('indskr_surgeonaccountzsur');
                  if(surgeonaccountzsurValue){
                    if(item.rawObjData){
                      item.rawObjData['surgeonaccountzsurValue'] = surgeonaccountzsurValue;
                    }else{
                      item.rawObjData = {
                        surgeonaccountzsurValue: surgeonaccountzsurValue,
                      };
                    }
                  }
                }

                //Set Booking to Team Member Association - Prefill Set Booking Guid
                if (this.formDetail.formMetadata.entityName == 'indskr_setbooking' && targetControl.subgrid.targetEntityType == 'indskr_setbookingteammember') {
                  let attribName = targetControl.subgrid.referencingAttribute;
                  for (let i = 0; i < foundForm.metadata.length; i++) {
                    const itab = foundForm.metadata[i];
                    const nonVisibleFormSection = this.setBookingDataService.formSectionConfigure?.filter(config => config['_indskr_mobileappform_value'] == this.formDetail.formMetadata.mobileFormId && !config['indskr_visibility']);
                    itab.controls.forEach(control => {
                      if (control.dataType && control.attributeName && !_.isEmpty(nonVisibleFormSection) && nonVisibleFormSection.some(sec => sec['indskr_name'].toLowerCase() == control.attributeName.toLowerCase())) {
                        control.isVisible = false;
                        control.forceHide = true;
                      } else if (!control.dataType && control.subgrid && !_.isEmpty(nonVisibleFormSection) && nonVisibleFormSection.some(sec => sec['indskr_name'].toLowerCase() == control.attributeName.toLowerCase())) {
                        control.isVisible = false;
                        control.forceHide = true;
                      }
                    });
                    if (itab && itab.controls && itab.controls.length > 0) {
                      let foundControlIdx = itab.controls.findIndex(c => c.attributeName == attribName);
                      if (foundControlIdx >= 0) {
                        itab.controls[foundControlIdx].forceHide = true;
                        rawFormEntityObject = {
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value']: this.formDetail.mainEntityObjectId && this.formDetail.mainEntityObjectId[Object.keys(this.formDetail.mainEntityObjectId)[0]],
                          ['_' + itab.controls[foundControlIdx].attributeName + '_value@OData.Community.Display.V1.FormattedValue']: ''
                        };
                        break;
                      }
                    }
                  };
                }

                //linked entity values for checking the duplicate data
                const checkDuplicateDataInLookupFields = ["indskr_accountcontactaffiliation", "indskr_accountcontactaffiliationcr", "indskr_customerspecialty", "indskr_customerspecialtychangerequest", "indskr_accountaccountaffiliation", "indskr_accountaccountaffiliationcr", "indskr_contactrelationship", "indskr_contactrelationshipcr"];
                if(!_.isEmpty(this.linkedEntityValues)) {
                  if(targetControl.subgrid.targetEntityType == 'indskr_email_address') {
                    this.dynamicFormsService.storedLinkedEntityValues = this.linkedEntityValues.EMAIL ? this.linkedEntityValues.EMAIL : this.linkedEntityValues.Email ? this.linkedEntityValues.Email : this.linkedEntityValues.sgEmail ? this.linkedEntityValues.sgEmail : [];
                  } else if(checkDuplicateDataInLookupFields.includes(targetControl.subgrid.targetEntityType)) {
                    this.dynamicFormsService.storedLinkedEntityValues = this.linkedEntityValues[targetAttributeName] ? this.linkedEntityValues[targetAttributeName] : [];
                  }
                }
                //check Feature Action
                let accountInfoDeactivateEnabled: boolean = false;
                let contactInfoDeactivateEnabled: boolean = false;
                let setBookingInfoDeactivateEnabled: boolean = false;
                if (!this.changeRequest && this._dynamicForm.entityName == "account" && targetControl.linkedEntityConfig && targetControl.linkedEntityConfig.abilityToRemoveRecords == '548910000') {
                  accountInfoDeactivateEnabled = true;
                } else if (!this.changeRequest && this._dynamicForm.entityName == "contact" && targetControl.linkedEntityConfig && targetControl.linkedEntityConfig.abilityToRemoveRecords == '548910000') {
                  contactInfoDeactivateEnabled = true;
                } else if (this._dynamicForm.entityName == "indskr_setbooking" && targetControl.linkedEntityConfig.abilityToRemoveRecords == '548910000') {
                  setBookingInfoDeactivateEnabled = true;
                }

                let displayName: any = this._getDisplayText(targetControl.displayNames);
                let formDetail: DynamicFormComponentModel = {
                  title: _.startCase(displayName.toLowerCase()),
                  showLeftHeaderButton: true,
                  showSearchBar: false,
                  showClearButton: true,
                  linkedEntityExistingDataId: item.linkedEntityExistingDataId,
                  linkedEntityExistingDataAddressId: item.linkedEntityExistingDataAddressId || foundAddressLinkedEntityId,
                  linkedEntityAddedNewDataId: item.linkedEntityAddedNewDataId,
                  linkedEntityValue: this.linkedEntityValues ? this.linkedEntityValues[targetControl.attributeName] ? this.linkedEntityValues[targetControl.attributeName] : [] : [],
                  targetControlAttribName: targetControl.attributeName,
                  leftHeaderBtnText: (accountInfoDeactivateEnabled || contactInfoDeactivateEnabled || setBookingInfoDeactivateEnabled || (this.changeRequest && this.TYPE == "OMNIPRESENCE" && this.MODE == 'EDIT_MODE')) ? this.translate.instant('SCRAP') : '',
                  leftHeaderBtnIcon: 'chevron-back-outline',
                  LookupEntitySetName: foundForm.entitySetName,
                  showRightHeaderButton: true,
                  rightHeaderImgSrc: 'assets/imgs/header_complete.svg',
                  rightHeaderBtnText: this.translate.instant('DONE'),
                  formMetadata: foundForm,
                  formEntityObject: item.rawObjData,
                  isEditFormWithLinkedData: true,
                  isUpdatedLinkedEnityFormData: item.isUpdatedFormData ? true : false,
                  mainEntityObjectId: null,
                  linkedEntityParentData: this.getLinkEntityParentData(foundForm.entitySetName, targetControl),
                  customFields: await this._getCustomFields(targetControl.subgrid.referencingEntity),
                  dynamicFormType: formType,
                  navOptions: { animate: false },
                  eventsHandler: (data: any, eventTarget: string, refData: DynamicFormComponentModel) => this._handleDynamicFormComponentEvent(data, eventTarget, refData),
                };
                this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
                this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent, PageName.DynamicFormComponent, this.navService.getCurrentMasterPageName(), {
                  MODE: 'CREATE_MODE',
                  EDIT_CONFIG: this.editConfig,
                  PREVIOUS_MODE: this._previousMode == 'EDIT_MODE' ? 'EDIT_MODE' : 'CREATE_MODE',
                  FORM_DETAIL: formDetail,
                  TYPE: this.TYPE,
                });
              }
            }
        }
      }
    }
  }
  private getDisplayEntityNameInScrapMessage(foundControl: Control) {
    let refEntity = foundControl.subgrid.referencingEntity;
    let displayName: string;
    let foundlinkedEntityName = _.capitalize(foundControl.displayNames[0].description.split('(')[0].trimEnd().toLowerCase());
    switch(refEntity) {
      case 'indskr_email_address':
        displayName = "Email";
        break;
      case 'indskr_indskr_customeraddress_v2':
        displayName = "Customer address";
        break;
      case 'indskr_customerspecialty':
        displayName = "Customer specialty";
        break;
      case 'indskr_contactrelationship':
        displayName = "Contact relationship";
        break;
      case 'indskr_accountcontactaffiliation':
        displayName = "Account contact affiliation";
        break;
      case 'indskr_accountaccountaffiliation':
        displayName = "Account account affiliation";
        break;
      case 'indskr_customerposition':
        displayName = "Customer position";
        break;
      default:
        displayName = foundlinkedEntityName || 'Data';
        break;
    }
    return displayName;
  }
  // Start/End Date picker
  async openStartDatePicker(id, event, eventName, targetControl: Control) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let currentStartDateTime;
    let currentEndDateTime;

    if (this._currentFormValue && this._currentFormValue.hasOwnProperty('indskr_enddate')) {
      currentEndDateTime = this._currentFormValue['indskr_enddate'];
    }
    if (this._currentFormValue && this._currentFormValue[targetControl.attributeName]) {
      currentStartDateTime = this._currentFormValue[targetControl.attributeName];
    }
    if (currentStartDateTime && !currentEndDateTime) {
      currentEndDateTime = currentStartDateTime;
    }

    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.ConfiguredForm,
          startDateTimeValue: currentStartDateTime ? moment(currentStartDateTime).format() : '',
          endDateTimeValue: currentEndDateTime ? moment(currentEndDateTime).format() : '',
          controlId: targetControl.attributeName,
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then((data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.startTime !='' && targetControl) {
        if (this.startTime && this.startTime.hasOwnProperty(targetControl.attributeName)) {
          this.startTime[targetControl.attributeName] = data.data.startTime;
        } else {
          this.startTime = {
            [targetControl.attributeName]: data.data.startTime,
          }
        }
        this._updateFormDateModernDatePicker(data.data.startTime, false, targetControl);
        // auto calcuated enddate field
        if (targetControl.attributeName == 'indskr_startdate') {
          let startTime = this.startTime['indskr_startdate'];
          let endTime = this.endTime['indskr_enddate'];
          const diffInMin = differenceInMinutes(endTime, startTime);
          let secondaryTargetControl: Control = this._getTargetControl('indskr_enddate');
          if (this._dynamicForm.entityName == 'indskr_setbooking') {
            let kigBookingDuration = _.cloneDeep(this.authService.user.buSettings['indskr_kitbookingduration']);
            if(kigBookingDuration) {
              let endTime = addMinutes(new Date(startTime), kigBookingDuration);
              this.endTime['indskr_enddate'] = moment(endTime).format();
              this._updateFormDateModernDatePicker(this.endTime['indskr_enddate'], true, secondaryTargetControl);
            }
          } else if (secondaryTargetControl && diffInMin <= 0) {
            this._updateFormDateModernDatePicker(data.data.endTime, false, secondaryTargetControl);
          }
        }
      }
    });
    popover.present();
  }
  async openEndDatePicker(id, event, eventName, targetControl: Control) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let currentStartDateTime;
    let currentEndDateTime;
    if (this._currentFormValue && this._currentFormValue.hasOwnProperty('indskr_startdate')) {
      currentStartDateTime = this._currentFormValue['indskr_startdate'];
    }
    if (this._currentFormValue && this._currentFormValue[targetControl.attributeName]) {
      currentEndDateTime = this._currentFormValue[targetControl.attributeName];
    }

    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.ConfiguredForm,
          startDateTimeValue: currentStartDateTime ? moment(currentStartDateTime).format() : '',
          endDateTimeValue: currentEndDateTime ? moment(currentEndDateTime).format() : '',
          controlId: targetControl.attributeName,
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then((data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.endTime !='' && targetControl) {
        if (this.endTime && this.endTime.hasOwnProperty(targetControl.attributeName)) {
          this.endTime[targetControl.attributeName] = data.data.endTime;
        } else {
          this.endTime = {
            [targetControl.attributeName]: data.data.endTime,
          }
        }
        this._updateFormDateModernDatePicker(data.data.endTime, true, targetControl);
      }
    });
    popover.present();
  }
  private _updateFormDateModernDatePicker(dateData: any, isUpdatedEndDateOnly: boolean, targetControl?: Control) {
    if (dateData == false) return;
    let selectedDate = (dateData && dateData?.value) ? new Date(dateData.value) : new Date(dateData);
    if (targetControl && targetControl.dateFormat == DateFormat.DateAndTime) {
      //TODO: selected time value - combined with date
      let selectedDateUTC = moment(selectedDate).utc().format();
      let formattedDate = (new Date(selectedDateUTC).toISOString());
      this._setAttributeValueDate(targetControl, formattedDate);
      if (targetControl.attributeName === 'indskr_startdate') {
        const dateTime = {startDateTime: this.startTime['indskr_startdate'], endDateTime: this.endTime['indskr_enddate']};
        this.dateTimeFormatsService.updateLimitedMinTime.next(dateTime);
      }
    } else if (targetControl && targetControl.dateFormat == DateFormat.DateOnly) {
      selectedDate.setHours(0, 0, 0, 0);
      let isTimeValid: boolean = true;
      let tempStartDate = (this.startDate) ? this.startDate : null;
      let tempEndDate = (this.endDate) ? this.endDate : null;
      if (this.formDetail.formMetadata.entityName == 'indskr_accountaccountaffiliationcr' || this.formDetail.formMetadata.entityName == 'indskr_accountcontactaffiliationcr' ) {
        if (targetControl.attributeName === 'indskr_startdate') {
          tempStartDate = selectedDate;
        } else if (targetControl.attributeName === 'indskr_enddate') {
          selectedDate.setHours(23, 59, 59, 59);
          tempEndDate = selectedDate;
          let currentDate = new Date().setHours(23,59,59,59);
          if (isUpdatedEndDateOnly && (new Date(tempEndDate).getTime() < new Date(currentDate).getTime())) {
            this.notificationService.notify(this.translate.instant('AFFILIATION_END_DATE_IN_FUTURE'),'ind-dynamic-form','top',ToastStyle.DANGER);
            isTimeValid = false;
          }
        }
        if(isUpdatedEndDateOnly && tempStartDate && tempEndDate) {
          if(new Date(tempStartDate).getTime() > new Date(tempEndDate).getTime()) {
            this.notificationService.notify(this.translate.instant('AFFILIATION_END_DATE_CAN_NOT_BEFORE_START_DATE'),'ind-dynamic-form','top',ToastStyle.DANGER);
            isTimeValid = false;
          }
        }
      }
      if(isTimeValid && targetControl) {
        this.startDate = (tempStartDate) ? tempStartDate : this.startDate;
        this.endDate = (tempEndDate) ? tempEndDate : this.endDate;
        const isUserLocal: boolean = targetControl.dateBehavior == 'UserLocal';
        const selectedDateUTC = moment(selectedDate).utc().format();
        const formattedDate = (new Date(selectedDateUTC).toISOString());
        const formattedDateValue = isUserLocal ? formattedDate : (dateData && dateData?.value) ? new Date(dateData.value) : new Date(dateData);
        this._setAttributeValueDate(targetControl, formattedDateValue);
        if (targetControl.attributeName === 'indskr_startdate') {
          const dateTime = {startDateTime: this.startTime['indskr_startdate'], endDateTime: this.endTime['indskr_enddate']};
          this.dateTimeFormatsService.updateLimitedMinTime.next(dateTime);
        }
      }
    }
    if(targetControl && targetControl.isRequired){
      let value;
      try {
        value = this._currentFormValue[targetControl.attributeName];
      } catch (error) {

      }
      this._validateMandatoryField(targetControl.attributeName,null,{value:value},false);
    }
  }

  private handleStartDateTimeValue(targetControl: Control) {
    if (!_.isEmpty(targetControl)) {
      let m = moment(this.startTime[targetControl.attributeName]);
      if (!m.isValid()) {
        this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'Configured Form', 'top', ToastStyle.DANGER);
        this.startTime[targetControl.attributeName] = this.prevStartTime[targetControl.attributeName];
      } else {
        this.prevStartTime[targetControl.attributeName] = this.startTime[targetControl.attributeName];
        let selectedDate = this.startTime[targetControl.attributeName];
        let selectedDateUTC = moment(selectedDate).utc().format();
        let formattedDate = (new Date(selectedDateUTC).toISOString());
        this._setAttributeStringValue(targetControl.attributeName, formattedDate);
        this._setAttributeValue(targetControl, formattedDate);
        if (targetControl.attributeName == 'indskr_startdate' && this.endTime['indskr_enddate']) {
          let currentStartTime = moment(this.startTime['indskr_startdate']).format();
          let currentEndTime = moment(this.endTime['indskr_enddate']).format();
          const dateTime = {startDateTime: currentStartTime, endDateTime: currentEndTime};
          this.dateTimeFormatsService.updateLimitedMinTime.next(dateTime);
        }
      }
    }
  }

  private handleEndDateTimeValue(targetControl: Control) {
    if (!_.isEmpty(targetControl)) {
      let m = moment(this.endTime[targetControl.attributeName]);
      if (!m.isValid()) {
        this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'Configured Form', 'top', ToastStyle.DANGER);
        this.endTime[targetControl.attributeName] = this.prevEndTime[targetControl.attributeName];
      } else {
        this.prevEndTime[targetControl.attributeName] = this.endTime[targetControl.attributeName];
        let selectedDate = this.endTime[targetControl.attributeName];
        let selectedDateUTC = moment(selectedDate).utc().format();
        let formattedDate = (new Date(selectedDateUTC).toISOString());
        this._setAttributeStringValue(targetControl.attributeName, formattedDate);
        this._setAttributeValue(targetControl, formattedDate);
      }
    }
  }

  private _getTargetControl(attrName: string, targetForm?: DynamicForm): Control {
    let targetControl: Control
    let form: DynamicForm = targetForm ? targetForm : this._dynamicForm
    for (let tabIdx = 0; tabIdx < form.metadata.length; tabIdx++) {
      const tab = form.metadata[tabIdx];
      if (tab && tab.controls && tab.controls.length > 0) {
        let foundControl = tab.controls.find(c => c.attributeName == attrName);
        if (foundControl) {
          targetControl = foundControl;
          break;
        }
      }
    };
    return targetControl;
  }

  private _getTargetControlbyLookupEtity(lookupEntitySetName: string, targetForm?: DynamicForm): Control {
    let targetControl: Control
    let form: DynamicForm = targetForm ? targetForm : this._dynamicForm
    for (let tabIdx = 0; tabIdx < form.metadata.length; tabIdx++) {
      const tab = form.metadata[tabIdx];
      if (tab && tab.controls && tab.controls.length > 0) {
        let foundControl = tab.controls.find(c => c.lookupEntitySetName == lookupEntitySetName);
        if (foundControl) {
          targetControl = foundControl;
          break;
        }
      }
    };
    return targetControl;
  }

  private _getTargetControlbySchemaName(schemaName: string, targetForm?: DynamicForm): Control {
    let targetControl: Control
    let form: DynamicForm = targetForm ? targetForm : this._dynamicForm
    for (let tabIdx = 0; tabIdx < form.metadata.length; tabIdx++) {
      const tab = form.metadata[tabIdx];
      if (tab && tab.controls && tab.controls.length > 0) {
        let foundControl = tab.controls.find(c => c.schemaName == schemaName);
        if (foundControl) {
          targetControl = foundControl;
          break;
        }
      }
    };
    return targetControl;
  }

  private _getTargetTabDisplayNamesByControl(attrName: string, targetForm?: DynamicForm): DisplayText[] {
    let headerLabel: DisplayText[];
    let form: DynamicForm = targetForm ? targetForm : this._dynamicForm
    for (let tabIdx = 0; tabIdx < form.metadata.length; tabIdx++) {
      const tab = form.metadata[tabIdx];
      if (tab && tab.controls && tab.controls.length > 0) {
        let foundControl = tab.controls.find(c => c.attributeName == attrName);
        if (foundControl) {
          if(!foundControl.dataType) {
            headerLabel = foundControl.displayNames;
          } else headerLabel = tab.displayNames;
          break;
        }
      }
    };
    return headerLabel;
  }
  /**
   * formatted date and time values are based on stored values in _currentFormStringValues
   * formatted raw data causes invalid date issue because some formats are not supported on iOS and moment format
   * set formatted date and time values in _getViewForControl()
  */
  private _setAttributeValueDate(targetControl: Control, dateValue: any) {
    if (targetControl.dateFormat === DateFormat.DateAndTime) {
      this._setAttributeStringValue(targetControl.attributeName, format(dateValue, 'YYYY/MM/DD HH:mm'));
      let dateValueStr = _.isString(dateValue) ? dateValue : dateValue.toISOString();
      this._setAttributeValue(targetControl, dateValueStr);
    } else if (targetControl.dateFormat === DateFormat.DateOnly) {
      this._setAttributeStringValue(targetControl.attributeName, format(dateValue, 'YYYY/MM/DD'));
      if (targetControl.dateBehavior == 'UserLocal') {
        let dateValueStr = _.isString(dateValue) ? dateValue : dateValue.toISOString();
        this._setAttributeValue(targetControl, dateValueStr);
      } else {
        this._setAttributeValue(targetControl, format(dateValue, 'YYYY-MM-DD'));
      }
    }
  }
  /**Check the duplicate data in the lookup field */
  private _checkDuplicatData(linkedEntityParentName: string, lookupEntitySetName: string, storedLinkedEntityValues: any[], selectedId: any): boolean {
    let isDuplicateData: boolean = false;
    storedLinkedEntityValues.forEach(le => {
      //account/contacts, account-contact affiliation: affiliation role makes an affiliation unique
      if(lookupEntitySetName == "indskr_accountcontactaffiliationcrs" || lookupEntitySetName == 'indskr_accountcontactaffiliations') {
        let existingAffiliationRoleId;
        let curFormSelAffiliationRoleId;
        let existingAccountId;
        let curFormSelAccountId;
        let existingContactId;
        let curFormSelContactId;
        const isAccountForm: boolean = linkedEntityParentName && linkedEntityParentName.includes('account');
        const isContactForm: boolean = linkedEntityParentName && linkedEntityParentName.includes('contact');
        //set existing account/contact id and affiliation role id
        if(le.rawObjData && le.rawObjData.formValue) {
          let foundAffiliationRole = le.rawObjData.formValue.lookupfields ? le.rawObjData.formValue.lookupfields.find(field=>field.name.toLowerCase() == 'omnione_lu_affiliationrole') || '' : '';
          if(foundAffiliationRole) existingAffiliationRoleId = foundAffiliationRole.id || '';
          let foundAccount = isContactForm && le.rawObjData.formValue.lookupfields ? le.rawObjData.formValue.lookupfields.find(field=>field.name.toLowerCase() == 'indskr_accountid') || '' : '';
          if(foundAccount) existingAccountId = foundAccount.id || '';
          let foundContact = isAccountForm && le.rawObjData.formValue.lookupfields ? le.rawObjData.formValue.lookupfields.find(field=>field.name.toLowerCase() == 'indskr_contactid') || '' : '';
          if(foundContact) existingContactId = foundContact.id || '';
        } else {
          existingAffiliationRoleId = le.rawObjData && le.rawObjData[this.formDetail.formMetadata.entityName + '.omnione_lu_affiliationrole'] || '';
          existingAccountId = isContactForm && le.rawObjData && le.rawObjData[this.formDetail.formMetadata.entityName + '.indskr_accountid'] || '';
          existingContactId = isAccountForm && le.rawObjData && le.rawObjData[this.formDetail.formMetadata.entityName + '.indskr_contactid'] || '';
        }
        //set current form selected account/contact id and affiliation role id
        if (this._currentFormValue && this._currentFormValue.lookupfields) {
          let foundCurFormAffiliationRole = this._currentFormValue && this._currentFormValue.lookupfields ? this._currentFormValue.lookupfields.find(x=>x.name.toLowerCase() == 'omnione_lu_affiliationrole') || '' : '';
          if(foundCurFormAffiliationRole) curFormSelAffiliationRoleId = foundCurFormAffiliationRole.id || '';
          let foundCurFormAccount = isContactForm && this._currentFormValue && this._currentFormValue.lookupfields ? this._currentFormValue.lookupfields.find(x=>x.name.toLowerCase() == 'indskr_accountid') || '' : '';
          if(foundCurFormAccount) curFormSelAccountId = foundCurFormAccount.id || '';
          let foundCurFormContact = isAccountForm && this._currentFormValue && this._currentFormValue.lookupfields ? this._currentFormValue.lookupfields.find(x=>x.name.toLowerCase() == 'indskr_contactid') || '' : '';
          if(foundCurFormContact) curFormSelContactId = foundCurFormContact.id || '';
        }
        // check the duplicate data
        if(le.lookupFieldActualValue === selectedId) {
          if(existingAffiliationRoleId === curFormSelAffiliationRoleId || (_.isEmpty(existingAffiliationRoleId) && _.isEmpty(curFormSelAffiliationRoleId))) {
            return isDuplicateData = true;
          }
        } else if(isContactForm && existingAccountId === curFormSelAccountId && existingAffiliationRoleId === selectedId) {
          return isDuplicateData = true;
        } else if(isAccountForm && existingContactId === curFormSelContactId && existingAffiliationRoleId === selectedId) {
          return isDuplicateData = true;
        }
      } else if(le.lookupFieldActualValue === selectedId) {
        return isDuplicateData = true;
      }
    });
    return isDuplicateData;
  }

  public initFooter() {
    this.footerService.updateButtons(['hopQrCode'], true);
  }

  public async onFooterButtonClicked(buttonId: string) {
    switch (buttonId) {
      case 'hopQrCode':
        if (!_.isEmpty(this._currentFormValue?.indskr_hcponeid)) {
          this.uiService.displayLoader();
          const qrResponse: any = await this.mdmService.getHopQrCode(this._currentFormValue?.indskr_hcponeid);
          this.uiService.dismissLoader();
          if (qrResponse?.status == 1000 && qrResponse?.data.url) {
            let popover = await this.popoverCtrl.create({
              component: HopQRCodeModal,
              componentProps: { qrCodeData: qrResponse?.data.url },
              cssClass: "hop-qr-code-modal",
              backdropDismiss: true
            });
            popover.present();
          }
        }
        break;
      default:
        break;
    }
  }

  async loadFileFromDevice(event, id) {
    if (event?.target?.files) {
      try {
        const file = event.target.files[0];
        if (file && (file.size / 1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE) {
          if (DCR_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(file.name)) {
            let b64Str: any = await toBase64(file);
            const dataURLPattern = /^data:[^;]+;base64,/;
            b64Str = b64Str.replace(dataURLPattern, '');
            const b64StrSplitArray = b64Str.split(',');
            const base64Str = Array.isArray(b64StrSplitArray) && b64StrSplitArray.length > 1 ? b64StrSplitArray[1] : b64Str || null;
            if (id == 'attach_practice_certificate') {
              this.practiceCertificateBase64str = base64Str;
              this.practiceCertificateFile = file;
              this.practiceCertificateTitle = file.name;
              this.isPracticeCertificateAdded = true;
            } else {
              this.workCardBase64str = base64Str;
              this.workCardFile = file;
              this.workCardTitle = file.name;
              this.isWorkCardAdded = true;
            }
            this._initViewData();
            // this._initHeaderView();
          } else {
            this.notificationService.notify(
              this.translate.instant('DCR_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'),
              'Contact Details',
              'top',
              ToastStyle.INFO,
            );
            this.removeAttachment(false, id);
          }
        } else {
          this.notificationService.notify(
            this.translate.instant(
              'MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION',
              { size: MAXIMUM_NOTE_ATTACHMENT_SIZE }
            ),
            'Event Notes',
            'top',
            ToastStyle.INFO,
          );
          this.removeAttachment(false, id);
        }
      } catch (error) {
        console.error('loadFileFromDevice: ', error);
      }
    }
  }

  removeAttachment(event, id: string) {
    if (id == 'attach_practice_certificate') {
      if (this.practiceCertificateAttachInput?.nativeElement?.value) {
        this.practiceCertificateAttachInput.nativeElement.value = null;
        this.practiceCertificateTitle = '';
        this.isPracticeCertificateAdded = false;
        this.practiceCertificateFile = null;
        this.practiceCertificateBase64str = null;
        // this._initHeaderView();
      }
    } else {
      if (this.workcardAttachInput?.nativeElement?.value) {
        this.workcardAttachInput.nativeElement.value = null;
        this.workCardTitle = '';
        this.isWorkCardAdded = false;
        this.workCardFile = null;
        this.workCardBase64str = null;
        // this._initHeaderView();
      }
    }
    this._initViewData();
  }

  private handleNoteAttachment(id: string) {
    if (this.TYPE=='SANOFI_CHINA') {
      try {
        if (id == 'attach_practice_certificate') this.practiceCertificateAttachInput.nativeElement.click();
        else this.workcardAttachInput.nativeElement.click();
      } catch (error) {
        console.log(error);
      }
    }
  }

  private _getFormContext(){
    return {
      data: {
        entity: {
          attributes: {
            get: (fieldName) => this._getAttribute(fieldName),
          }
        }
      }
    }
  }

  private _getFormType(){
    if(this._dynamicForm){
      return 1
    }
  }

  private _getAttribute(fieldName){
    return {
      getValue: () => this._getAttributeValue(fieldName),
      setValue: (value) => this._setBusinessRuleAttributeValue(value,fieldName),
      controls: [
        {
          setVisible: (visible) => this._setVisible(visible,fieldName),
          setDisabled: (disabled) => this._setDisabled(disabled,fieldName),
        },
      ],
      setRequiredLevel: (required) => this._setIsRequired(required,fieldName),
    }
  }

  private _setVisible(visible, fieldName) {
    //let viewField = this.currentFormView.find(a => a.id == fieldName);
    // if (viewField && viewField.view) {
    //   viewField.view.isHidden = !visible;
    //   this._cd.detectChanges();
    // }
    for (let tabIdx = 0; tabIdx < this._dynamicForm.metadata.length; tabIdx++) {
      const tab = this._dynamicForm.metadata[tabIdx];
      if (tab && tab.controls && tab.controls.length > 0) {
        let foundControl = tab.controls.find(c => c.attributeName == fieldName);
        if (foundControl) {
          foundControl.forceHide = !visible;
          this._runInitViewDataAfterBusinessRuleLogic = true;
          break;
        }
      }
    };
    // if(targetControl){
    //   targetControl.isVisible = visible;
    //   this._runInitViewDataAfterBusinessRuleLogic = true;
    // }
  }

  private _setDisabled(disabled, fieldName) {
    // let viewField = this.currentFormView.find(a => a.id == fieldName);
    // if (viewField && viewField.view) {
    //   viewField.view.isDisabled = disabled;
    //   this._cd.detectChanges();
    // }
    // let targetControl: Control = this._getTargetControl(fieldName);
    // if(targetControl){
    //   targetControl.isReadOnly = disabled;
    //   this._runInitViewDataAfterBusinessRuleLogic = true;
    // }
    for (let tabIdx = 0; tabIdx < this._dynamicForm.metadata.length; tabIdx++) {
      const tab = this._dynamicForm.metadata[tabIdx];
      if (tab && tab.controls && tab.controls.length > 0) {
        let foundControl = tab.controls.find(c => c.attributeName == fieldName);
        if (foundControl) {
          foundControl.isReadOnly = disabled;
          this._runInitViewDataAfterBusinessRuleLogic = true;
          break;
        }
      }
    };
  }

  private _setIsRequired(required,fieldName) {
    // let viewField = this.currentFormView.find(a => a.id == fieldName);
    // if (viewField && viewField.view) {
    //   viewField.view.isRequired = required == 'required';
    //   this._cd.detectChanges();
    // }
    // let targetControl: Control = this._getTargetControl(fieldName);
    // if(targetControl){
    //   targetControl.isRequired = required == 'required';
    //   this._runInitViewDataAfterBusinessRuleLogic = true;
    // }
    for (let tabIdx = 0; tabIdx < this._dynamicForm.metadata.length; tabIdx++) {
      const tab = this._dynamicForm.metadata[tabIdx];
      if (tab && tab.controls && tab.controls.length > 0) {
        let foundControl = tab.controls.find(c => c.attributeName == fieldName);
        if (foundControl) {
          foundControl.isRequired = required == 'required';
          this._runInitViewDataAfterBusinessRuleLogic = true;
          break;
        }
      }
    };
  }

  private _getAttributeValue(fieldName){
    let targetControl: Control = this._getTargetControl(fieldName);
    if(targetControl) {
      if(targetControl.dataType == ControlDataType.PicklistType){
        if (this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.attributeName)) {
          return parseInt(this._currentFormValue[targetControl.attributeName]);
        }else{
          return null;
        }
      }else if(targetControl.dataType == ControlDataType.LookupType){
        const comparisonValue = !this.isHandleEditAddressFormField ? (targetControl.isCustom ? this._customFormValue : this._currentFormValue) : (targetControl.isCustom ? this._existingCustomFormValue : this._existingFormValue);
        const objName = targetControl.isCustom ? targetControl.schemaName : (targetControl.isCustomAttribute ? targetControl.schemaName : targetControl.attributeName);
        if (comparisonValue && comparisonValue.hasOwnProperty('lookupfields')) {
          let idx = comparisonValue['lookupfields'].findIndex(a => a.name == objName);
          if (idx >= 0) {
            return comparisonValue['lookupfields'][idx].id;
          }
        }
        return null;
      }else if(targetControl.dataType == ControlDataType.BooleanType){
        const value = this._getInputTextForFormField(targetControl);
        return value=='Yes'? true : value =='No'?false:null;
      }else {
        return this._getInputTextForFormField(targetControl);
      }
    } else {
      return null;
    }

  }

  private _setBusinessRuleAttributeValue(value,fieldName){
    let targetControl: Control = this._getTargetControl(fieldName);
    let currentValue = this._getAttributeValue(fieldName);
    if(currentValue != value){
      if(targetControl && targetControl.dataType == ControlDataType.PicklistType){
        try {
          const optionValue = targetControl.optionSet.find(option => option.value == value);
          if(optionValue){
            const optionStr = optionValue.displayNames.find(a=> a.languagecode == this._activeLanguageCode);
            if(optionStr){
              this._setAttributeStringValue(targetControl.attributeName,optionStr.description);
            }
          }
        } catch (error) {console.log(error);}
      }
      if(targetControl) this._setAttributeValue(targetControl,value,true,'',false);
    }
  }

  private _sanitizeGuid(guid) {
    var guidRegex = /^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$/;
    // Check if the provided 'guid' matches the GUID format
    if (guid.match(guidRegex)) {
      // If it's a valid GUID, return it in the canonical format (lowercase with dashes)
      return guid.toLowerCase();
    } else {
      // If it's not a valid GUID, return null or handle the error as needed
      return null;
    }
  }
  private _runBusinessRulesLogic(){
    const businessRules = this.formDetail && this.formDetail.formMetadata && this.formDetail.formMetadata.businessRules ? this.formDetail.formMetadata.businessRules : [];
    const functionMap: Array<Function> = [];

    // Create a map of function names to actual functions
    businessRules.forEach((funcString) => {
      try {
        if(funcString && funcString.logicFunction){
          funcString.logicFunction = funcString.logicFunction.replace('(eventContext)','(eventContext,Mscrm,Xrm)')
          const func = new Function('return ' + funcString.logicFunction)();
          if (func) {
            functionMap.push(func);
          }
        }

      } catch (error) {
        console.error(`Error parsing function: ${error}`);
      }
    });

    for (const func of functionMap) {
      try {
        const Mscrm = {
          BusinessRules: {
            Utility: {
              isNull: (o) => o == null || o === undefined,
              sanitizeGuid: (guid) =>  this._sanitizeGuid(guid),
            },
            ErrorHandlerFactory: {
              getHandler: function (e, f) {
                return {
                  handleError: () => {
                    throw e;
                  },
                };
              },
            },
          },
        };

        const Xrm = {
          Page: {
            data: {
              entity: undefined,
            },
            ui: {
              getFormType:() => this._getFormType(),
            }
          },
        };
        const eventContext = {
          getFormContext: () => this._getFormContext()
        }
        func(eventContext,Mscrm,Xrm);
      } catch (error) {
        console.error('Error executing function:', error);
      }
    }
    if(this._runInitViewDataAfterBusinessRuleLogic){
      this._initViewData();
      this._runInitViewDataAfterBusinessRuleLogic = false;
    }

  }

  private _runHardcodedLogic(){
    if(this.MODE == 'CREATE_MODE' && this.formDetail && this.formDetail.formMetadata && this.formDetail.formMetadata.entityName == 'indskr_setbooking'){
      try {
        let control = this._getTargetControl('indskr_status');
        control.isReadOnly = true;
        this._setAttributeValue(control, SetBookingStatus.DRAFT, true);
        this._setAttributeStringValue(control.attributeName, 'Draft');
      } catch (error) {}
    }
  }

  public clickedForScrap(event, item:OmniAccordionViewDataModel, attriuteName) {
    item.isCheckedForScrap = !item.isCheckedForScrap;
    let targetControl = this._getTargetControl(attriuteName);
    this._targetControlViewData(targetControl);  // what the hell there is no logic for the linked entity section. let's add it
  }

  public handleAccordionView(event, targetControl: Control, target, item:OmniAccordionViewDataModel) {
    if(!targetControl) return;
    if(target=='scrapFromList') {
      this.clickedForScrap(event, item, targetControl.attributeName)
    }
  }

  private _setDefaultValue(control:Control) {
    if(control.dataType == ControlDataType.PicklistType && control.defaultValue>-1) {
      if(control.optionSet) {
        let defaultOption = control.optionSet.find(option => option.value == control.defaultValue);
        let defaultDisplayName = '';
        if(defaultOption && defaultOption.displayNames && defaultOption.displayNames.length>0) {
          let foundDisplayName = defaultOption.displayNames.find(a=> a.languagecode == this._activeLanguageCode) || defaultOption.displayNames.find(a=> a.languagecode == '1033')
          defaultDisplayName = foundDisplayName.description;
        }
        this._setAttributeStringValue(control.attributeName, defaultDisplayName);
        this._setAttributeValue(control, control.defaultValue, false);
        if (control.attributeName == 'indskr_rolepharma'
            || control.attributeName == 'indskr_rolevaccine'
            || control.attributeName == 'indskr_departmentvaccine'
            || control.attributeName == 'indskr_positionretail'
            || control.attributeName == 'indskr_titleretail'
            || control.attributeName == 'indskr_titlepharma') {
            this._currentFormStringValues[control.attributeName + '_Desc'] = defaultOption.descriptions[0].description;
        }
      }
    } else if(control.dataType == ControlDataType.BooleanType) {
      control.defaultValue ? this._setAttributeValue(control, true, false) : this._setAttributeValue(control, false, false)
    }
  }
}
