import { OpportunityManagementService } from './../../services/opportunity-management/opportunity-management.service';
import { OpportunityManagementDataService } from '@omni/data-services/opportunity-management/opportunity-management.data.service';
import { EntitySyncInfo } from '@omni/data-services/delta/delta.service';
import { EntityNames } from '@omni/data-services/delta/delta.service';
import { DeltaService } from '@omni/data-services/delta/delta.service';
import { debounceTime } from 'rxjs/operators';
import { IActivityFilterEvent } from './../../interfaces/activity/activity-event.interface';
import { MoreOptionsPopoverComponent } from './../more-options-popover/more-options-popover';
import { PopoverController, IonNav } from '@ionic/angular';
import { Channel, ChannelActivityType, ChannelType, EmailActivityChannelDetails } from './../../classes/consent/channel.class';
import { EventsService, EventName } from './../../services/events/events.service';
import { Component, Output, EventEmitter, Input, ChangeDetectorRef, Injector } from '@angular/core';
import { ContactPageComponent } from '../contact/contact-page/contact-page';
import { ContactOfflineService } from '../../services/contact/contact.service';
import { PresentationPageComponent } from '../../pages/presentation/presentation';
import { PresentationFullscreenComponent } from '../../pages/presentation/presentation-fullscreen';
import { PresentationMeetingComponent } from '../../pages/presentation/presentation-meeting';
import { ActivityService } from '../../services/activity/activity.service';
import { ActivityDataService } from '../../data-services/activity/activity.service';
import { ActivityType, EmbeddedInteraction, EmbeddedInteractionType } from '../../classes/activity/activity.class';
import { FooterService, FooterViews } from '../../services/footer/footer.service';
import { ComponentViewMode, PresentationView, UIService } from '../../services/ui/ui.service';
import { LoadingController, ModalController, MenuController } from '@ionic/angular';
import { WebsocketDataService } from '../../data-services/websocket/websocket.data.service';
import { AppointmentActivity } from '../../classes/activity/appointment.activity.class';
import { MeetingDataService, UpdateMeetingPayload } from '../../data-services/meeting/meeting.data.service';
import { PresentationService } from '../../services/presentation/presentation.service';
import { NativePageTransitions } from '@awesome-cordova-plugins/native-page-transitions/ngx';
import { DeviceService } from '../../services/device/device.service';
import { MyAssistantComponent } from '../my-assistant/my-assistant';
import { MyAssistantService } from '../../services/my-assistant/my-assistant.service';
import { TrackService, TrackingEventNames } from '../../services/logging/tracking.service';
import { NavigationService, PageName, ChildNavNames } from '../../services/navigation/navigation.service';
import { NotificationService, ToastStyle } from "../../services/notification/notification.service";
import { ResourcePageComponent } from '../resource/resource-page/resource-page';
import { ResourceView, ResourceService } from '../../services/resource/resource.service';
import { RepServices } from '../../data-services/rep/rep.services';
import { TimeOffUtilityService } from '../../services/time-off/time-off-utility.service';
import { TimeOffService } from "../../services/time-off/time-off.service";
import { format, addMinutes } from "date-fns";
import { CoachingReportService } from '../../services/coaching/coaching.report.service';
import { CreateSampleDropRequestBody, SampleActivity } from '../../classes/activity/sample.activity.class';
import { SampleDataService } from '../../data-services/sample/sample.data.service';
import { SampleService, SamplingDetailsViewMode } from '../../services/sample/sample.service';
import { InMeetingAllocationComponent } from '../in-meeting-allocation/in-meeting-allocation';
import { SampleDetailInSampleActivity } from '../../models/sample-model';
import { AuthenticationService } from '../../services/authentication.service';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { SpeechSDKService } from '../../services/speechskd.service';
import { CallPlanOfflineService } from '../../services/call-plan/call-plan.offline.service';
import { EmailActivity, EmailViewType, EmailActivityParty, EmailAddress } from '../../classes/activity/email.activity.class';
import { EmailService } from '../../services/email-templates/email.service';
import { FollowUpActivity, FOLLOW_UP_TYPE } from '../../classes/activity/follow-up-action.activity.class';
import { FollowUpActivityDataService } from '../../data-services/follow-up-activity/follow-up-activity.data.service';
import { NothingSelectedView } from '../shared/nothing-selected-view/nothing-selected-view';
import { CaseManagementService, AccesingMode } from '../../services/case-management/case-management.service';
import { CaseActivity } from '../../classes/case-intake/case-activity.class';
import { InMeetingCasesComponent } from '../case-management/in-meeting-cases/in-meeting-cases';
import { OrderActivity } from '../../classes/activity/order.activity.class';
import { OrderActivityDataService } from '../../data-services/order-activity/order-activity.data.service';
import { XperiencesService, IMPACT_SEGMENT } from '../../services/xperiences/xperiences.service';
import { CaseManagementDataService } from "../../data-services/case-management/case-management.data.service";
import { EmailTemplateType } from '../../classes/email-templates/email-template.class';
import { IoEventListener } from '../../services/io-event-listener.decorator';
import { GlobalUtilityService } from "../../services/global-utility.service";
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { TimeOffActivity } from '../../classes/activity/timeoff.class';
import { AlertService } from '../../services/alert/alert.service';
import { MeetingStructureService } from '../../services/meeting-structure/meeting-structure.service';
import { Presentation, Page, SwipeStatus } from '../../classes/presentation/presentation.class';
import { LogService } from '../../services/logging/log-service';
import { Resource } from '../../classes/resource/resource.class';
import { InboundMeetingControllerService } from '../../services/meeting/inbound-meeting.controller.service';
import { AgendaFooterService, AgendaFooterView } from '@omni/services/footer/agenda-footer.service';
import { ConsentService } from '@omni/services/consent/consent.service';
import { Subscription } from 'rxjs';
import { OpportunityManagementPageComponent } from '../opportunity-management/opportunity-management-page/opportunity-management-page';
import { PollService } from '@omni/services/poll/poll.service';
import { OrderManagementService } from '@omni/services/order-management/order-management.service';
import { ActivitiesDetailsPaneComponent } from '../activity/activities-details-pane/activities-details-pane';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { AllocationFeatureService } from '@omni/services/sample/allocation.feature.service';
import { KOLStatusPageComponent } from '../contact/kol-status-page/kol-status-page';
import { SetBookingDataService } from '@omni/data-services/set-booking/set-booking.data.service';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { LocationCoordinates } from '@omni/interfaces/shared/shared.interface';

const leftFlipNavTransOption = {
  direction: 'left',
  duration: 1000,
  slidePixels: 20,
};

/**
 * Generated class for the FooterToolbarComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'footer-toolbar',
  templateUrl: 'footer-toolbar.html',
  styleUrls: ['footer-toolbar.scss']
})
export class FooterToolbarComponent {
  subscriptions: ({ unsubscribe: Function })[] = [];
  private isAutoApprovalForOrdersEnabled: boolean = false;
  private lastOnSingleClick: number = 0;
  private clickTimeoutHandle: any = null;
  private DOUBLE_CLICK_THRESHOLD: number = 300;

  public hideGenie = false;

  @Input() selectedView: string;
  @Input() footerView: 'masterView' | 'detailView' | 'fullScreenView' | 'shortCallLauncherView';
  @Input() attachedComponent: PageName;
  @Input() fromWhichView: string
  @Output() cancelAllocation = new EventEmitter<any>();
  @Output() cancelReportReview = new EventEmitter<any>();
  @Output() sendReportForApproval = new EventEmitter<any>();
  @Output() notApproveReport = new EventEmitter<any>();
  @Output() approveReport = new EventEmitter<any>();
  @Output() scrapReport = new EventEmitter<any>();
  @Output() dislikeActivity = new EventEmitter<any>();
  @Output() likeActivity = new EventEmitter<any>();
  @Output() buttonClicked = new EventEmitter<any>();
  @Output() buttonDblClicked = new EventEmitter<{ buttonId: string, event: MouseEvent}>();

  private pageModal: HTMLIonModalElement;

  // transOptions: NativeTransitionOptions = {
  //   direction: 'up',
  //   duration: 500,
  //   slowdownfactor: 3,
  //   slidePixels: 20,
  //   iosdelay: 100,
  //   androiddelay: 150,
  //   fixedPixelsTop: 0,
  //   fixedPixelsBottom: 60
  //  };
  buttonsFilter = {
    master: { isvisible: true, shouldbeinmore: false },
    details: { isvisible: true, availableOnFullScreen: true },
    fullscreen: { isvisible: true, availableOnFullScreen: true }
  }
  otAudioSignal: any[] = [];
  navCtrl: IonNav;
  forceOpen = false;

  private isMasterPopoverVisible = false;
  private popoverObj = undefined;
  private interval: any = null;
  private isDeviceOrientation = 0;
  public shouldHideGeneeInDetails: boolean = false;
  private lastTeamViewActiveState: boolean = false;
  private _screenOrientation: Subscription;

  constructor(
    public injector: Injector,
    private contactService: ContactOfflineService,
    public activityService: ActivityService,
    private emailService: EmailService,
    private activityDataService: ActivityDataService,
    public uiService: UIService,
    public footerService: FooterService,
    public websocket: WebsocketDataService,
    private presentationService: PresentationService,
    private events: EventsService,
    private nativePageTrans: NativePageTransitions,
    public device: DeviceService,
    public assistantService: MyAssistantService,
    private trackingService: TrackService,
    private navService: NavigationService,
    private resourceService: ResourceService,
    private notificationService: NotificationService,
    private meetingDataService: MeetingDataService,
    private repService: RepServices,
    private timeOffUtilityService: TimeOffUtilityService,
    private timeOffService: TimeOffService,
    private coachingReportService: CoachingReportService,
    private loadingController: LoadingController,
    private sampleDataService: SampleDataService,
    private sampleService: SampleService,
    private modalCtrl: ModalController,
    public authService: AuthenticationService,
    private speechService: SpeechSDKService,
    private callPlanService: CallPlanOfflineService,
    private followUpDataService: FollowUpActivityDataService,
    private caseManagementService: CaseManagementService,
    private caseManagementDataService: CaseManagementDataService,
    private menuCtrl: MenuController,
    private orderActivityDataService: OrderActivityDataService,
    private xperiencesService: XperiencesService,
    private alertService: AlertService,
    private popoverCtrl: PopoverController,
    public utilityService: GlobalUtilityService,
    private translate: TranslateService,
    private meetingStructureService: MeetingStructureService,
    private log: LogService,
    private ref: ChangeDetectorRef,
    private inboundMeetingCtrl: InboundMeetingControllerService,
    public agendaFooterService: AgendaFooterService,
    private consentService: ConsentService,
    private pollService: PollService,
    public orderManagementService:OrderManagementService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private allocFeatureService: AllocationFeatureService,
    public deltaService: DeltaService,
    private readonly opportunityService: OpportunityManagementService,
    private readonly opportunityManagementDataService: OpportunityManagementDataService,
    private readonly setBookingDataService: SetBookingDataService,
    private faService: FeatureActionsService,
    private accountService: AccountOfflineService,
  ) {

    this.footerService.footerSelectedView = this.selectedView;
    this.footerService.footerView = this.footerView;
    this.subscriptions.push(...this.events.subscribe('presentation-play', () => {
      if (!this.websocket.meetingInProgress) {
        this.handlePlayButton();
      }
    }));

    this.subscriptions.push(...this.events.subscribe('voip-audio-status', (getStatus) => {
      let index = _.findIndex(this.otAudioSignal, { contactID: getStatus.contactID });
      if (index != -1) {
        this.otAudioSignal[index].voiceStatus = getStatus.voiceStatus
      }
      else {
        this.otAudioSignal.push(getStatus);
      }
    }));

    this.shouldHideGeneeInDetails = this.visibilityOfGeneeInDetails(this.isMobileDevicePortrait());

    this.device.detectHomeBar();

    this._screenOrientation = this.device.screenOrientation.subscribe((orientation: number) => {
      if (orientation > 0) {

        // Bottom space is not refreshing on orientation changes.
        this.events.publish('refresh-sci-callplan-activity');

        // 1 for portrait / 3/4 for landscape
        const isPortrait = orientation === 3 || orientation == 4 ? false : true;
        this.shouldHideGeneeInDetails = this.visibilityOfGeneeInDetails(isPortrait);
        //
        // This is require to refresh the same on orientation changes.
        const currentValue = this.footerService.shouldApplyBottomMargin();
        //
        setTimeout(() => {
          this.refresh();
        }, 500);
      }
    });

    this.subscriptions.push(...this.events.subscribe('footerButtonsUpdated', (targetPage: PageName) => {
      // Change(s) in footerService.actionButtons doesn't change a reference to the array and thus filter pipe doesn't run
      // For now, utilizing event to manually trigger
      if (this.selectedView === 'presentations' && this.attachedComponent === targetPage) {
        this.refresh();
      }
    }));



    this.events.observe("sync:completed")
      .subscribe(() => {
        // In case of no plans feature actions, remove plan tab through refreshTab, then go to the Act tab
        this.events.publish("refreshTab");
        if (!this.faService.isShortCallLauncherEnabledInMobileDevice && !this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT) && !this.authService.hasFeatureAction(FeatureActionsMap.CALL_PLANS) && !this.authService.hasFeatureAction(FeatureActionsMap.SCIENTIFIC_TOOL_PLAN)) {
          if (this.uiService.activitiesPageTab === 'plans') {
            this.uiService.activitiesPageTab = 'agenda';
            this.selectedView = 'activities';
          }
        }
      });

    // Team view active / de-active handler
    this.subscriptions.push(
      this.activityService.activityDataFilterEvent$
      .pipe(
        debounceTime(250),
      )
      .subscribe((event: IActivityFilterEvent) => {
        if (event?.filterType === 'teamView') {
          if (this.lastTeamViewActiveState === false) {
            // Team view turned on
            this.agendaFooterService.enableButtons(['scheduler'], false);
            this.lastTeamViewActiveState = true;
          } else if (this.lastTeamViewActiveState === true && this.activityService.teamViewActive === false) {
            // Not team view
            this.agendaFooterService.enableButtons(['scheduler'], true);
            this.lastTeamViewActiveState = false;
          }
        }
      })
    );
  }
  ngOnInit() {
    // if (this.selectedView === 'presentations' && this.device.isMobileDevicePortrait) {
    //   this.footerService.hideButton('fullscreen');
    // }
    this.initpresentation();
    this.isAutoApprovalForOrdersEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT_AUTO_APPROVAL) ? true : false;
    this.footerService.footerView = this.footerView;

    this.hideGenie = !this.authService.hasFeatureAction(FeatureActionsMap.ACTIVATE_GENIE, true);
  }

  ngAfterViewInit() {
    this.footerService.footerView = this.footerView;
  }

  initpresentation() {
    if ((this.footerView == 'detailView' || this.footerView == 'fullScreenView') && this.selectedView === 'presentations') {
      /*if (this.navService.getCurrentPageName() === PageName.PresentationMeetingComponent) {
        //
        if (!this.authService.hasFeatureAction(FeatureActionsMap.REMOTE_FEATURE)) {
          _.find(this.footerService.actionButtons, { id: 'video' }).featureActions[0] = FeatureActionsMap.REMOTE_FEATURE;
          _.find(this.footerService.actionButtons, { id: 'stop_video_active' }).featureActions[0] = FeatureActionsMap.REMOTE_FEATURE;
          _.find(this.footerService.actionButtons, { id: 'audioactive' }).featureActions[0] = FeatureActionsMap.REMOTE_FEATURE;
          _.find(this.footerService.actionButtons, { id: 'stop_audio' }).featureActions[0] = FeatureActionsMap.REMOTE_FEATURE;
        }
        else {
          _.find(this.footerService.actionButtons, { id: 'video' }).featureActions[0] = FeatureActionsMap.VIDEO_BUTTON;
          _.find(this.footerService.actionButtons, { id: 'stop_video_active' }).featureActions[0] = FeatureActionsMap.VIDEO_BUTTON;
          _.find(this.footerService.actionButtons, { id: 'audioactive' }).featureActions[0] = FeatureActionsMap.MEETING_VOIP;
          _.find(this.footerService.actionButtons, { id: 'stop_audio' }).featureActions[0] = FeatureActionsMap.MEETING_VOIP;
          this.footerService.hideButton('stop_audio');
          this.footerService.showButton('audioactive');
        }
        if (this.device.isOffline) {
          this.footerService.showButton('stop_audio');
          this.footerService.hideButton('audioactive');
        }
        this.footerService.hideButton('stop_video_active');
        this.footerService.showButton('video');
        this.setFooterIconOrder();
        if (this.footerView == 'detailView' && this.selectedView === 'presentations') {
          this.hideAllVoipIcons();
        }
        //
      }*/
      this.subscriptions.push(...this.events.subscribe('presentation-meeting::pause-clicked', () => {
        this._handlePauseButton();
      }))
      this.subscriptions.push(...this.events.subscribe('presentation-meeting::flip-clicked', () => {
        // this.videoCallState.state = 0;
        // this.videoCallState.audioState = 0;
        // this.videoCallManagerService.disconnectFromSession();
        this._handleFlipButton()
      }));
      this.subscriptions.push(...this.events.subscribe('presentation-meeting:audio-streaming', async (clicked) => {
        await this._handleVoipButton(!clicked);
      }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this._screenOrientation.unsubscribe();
  }
  /**
   * Start listening mic for voice input
   *
   * @memberof FooterToolbarComponent
   */
  async openMyAccount() {
    let flag = await this.setBookingDataService.checkSetBookingDiscardConfirmationRequired().catch(err=>{});
    if(!flag) return;
    //this.speechService.tryRecording();
    this.trackingService.tracking('AgendaGeneeClicked', TrackingEventNames.ACTIVITY);

    if (this.menuCtrl.isOpen()) {
      this.menuCtrl.close();
    }

    if (this.footerService.samplingInModalViewActive) {
      if (!this.navCtrl) {
        this.navCtrl = this.injector.get(IonNav)
      }
      this.navCtrl.push(MyAssistantComponent, {}, { progressAnimation: false });
      return;
    }
    else {
      let geeneeModal = this.modalCtrl.create({ component: MyAssistantComponent, componentProps: {}, cssClass: 'fullscreen' })
        .then((modal) => modal.present());
    }
    //if(this.navService.getCurrentPageName() == PageName.MyAssistantComponent){return}
    //this.navService.pushWithPageTracking(MyAssistantComponent, PageName.MyAssistantComponent);
  }
  /**
   * Opens activity screen
   *
   * @memberof FooterToolbarComponent
   */
  openActivities = (view) => {
    if (this.xperiencesService.unSavedChanges && !this.forceOpen) {
      this.alertService.showAlert({
        title: this.translate.instant('DISCARD_CHANGES'),
        message: this.translate.instant('CI_R_U_WANT_DISCARD_CHANGES')
      }, this.translate.instant('DISCARD')
      ).then(res => {
        if (res.role == "ok") {
          this.xperiencesService.dicardChangesForXperiences();
          this.forceOpen = false;
          this.selectedView = view;
          this.trackingService.tracking('AgendaActivitiesClicked', TrackingEventNames.ACTIVITY)
          this.footerService.isActivitiesButton = true;
          this.uiService.showNewActivity = null;
          this.activityService.selectedActivity = null;
          // this.activityService.selectedActivityAtHome = null;
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent).then(() => {
            setTimeout(() => {
              this.xperiencesService.newInsight = null;
              this.xperiencesService.selectedXpContact = null;
            }, 500);
          });
          this.events.publish('SegmentChnaged');// this is for selecting new Activity.
        } else {
          this.forceOpen = true;
          this.uiService.activitiesPageTab = 'Impact';
        }
      });
    } else if (!_.isEqual(this.xperiencesService.generalInsight, this.xperiencesService.tempInsight) && !this.forceOpen) {
      this.xperiencesService.discardChangesPopup().then(async (res) => {
        if (res.role === 'ok') {
          if (this.xperiencesService.tempInsight) this.xperiencesService.generalInsight = this.xperiencesService.tempInsight;
          this.selectedView = view;
          this.trackingService.tracking('AgendaActivitiesClicked', TrackingEventNames.ACTIVITY)
          this.footerService.isActivitiesButton = true;
          this.uiService.showNewActivity = null;
          this.activityService.selectedActivity = null;
          // this.activityService.selectedActivityAtHome = null;
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent).then(() => {
            setTimeout(() => {
              this.xperiencesService.newInsight = null;
              this.xperiencesService.selectedXpContact = null;
            }, 500);
          });
          this.events.publish('SegmentChnaged');// this is for selecting new Activity.
        } else {
          this.forceOpen = true;
          this.uiService.activitiesPageTab = 'Impact';
        }
      });
    } else {
      this.forceOpen = false;
      this.selectedView = view;
      this.trackingService.tracking('AgendaActivitiesClicked', TrackingEventNames.ACTIVITY)
      this.footerService.isActivitiesButton = true;
      this.uiService.showNewActivity = null;
      // this.activityService.selectedActivity = null;
      // this.activityService.selectedActivityAtHome = null;
      if (this.xperiencesService.selectedXpContact) {
        this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent).then(() => {
          this.xperiencesService.selectedXpContact = null;
        });
      }
      if (!this.xperiencesService.unSavedChanges && !this.activityService.selectedActivityOnGenee) {
        this.activityService.selectedActivityOnGenee = undefined;
        // setTimeout(() => {
          this.events.publish('SegmentChnaged');
        // }, 500);
      }
    }
  }
  /**
   * Opens Trending Customers component screen
   *
   * @memberof FooterToolbarComponent
   */
  public openTrendingCustomers = async (view) => {
    if (this.xperiencesService.unSavedChanges && !this.forceOpen) {
      this.alertService.showAlert({
        title: this.translate.instant('DISCARD_CHANGES'),
        message: this.translate.instant('CI_R_U_WANT_DISCARD_CHANGES')
      }, this.translate.instant('DISCARD')
      ).then(res => {
        if (res.role == "ok") {
          this.forceOpen = false;
          this.showXperinecesView(view);
          this.xperiencesService.dicardChangesForXperiences();
        } else {
          this.forceOpen = true;
          this.uiService.activitiesPageTab = 'agenda';
        }
      });
    } else {
      this.forceOpen = false;
      this.showXperinecesView(view);
    }
  }

  showXperinecesView(view: string) {
    this.trackingService.tracking('XperiencesStart', TrackingEventNames.XPERIENCES, true);
    this.selectedView = view;
    this.trackingService.tracking('AgendaTrendsClicked', TrackingEventNames.ACTIVITY)
    this.footerService.isActivitiesButton = false;
  }

  openEdgeAnalytics(view: string) {
    this.selectedView = view;
  }

  openPlans(view: string) {
    this.uiService.activeView = 'ActivitiesPageRightPaneNav';
    this.trackingService.tracking('ActivitiesPageRightPaneNav', TrackingEventNames.PLANS_LIST, true);
    this.selectedView = view;
    this.footerService.isActivitiesButton = true;
  }

  openNotifications(view: string){
    // this.uiService.activeView = 'ActivitiesPageRightPaneNav';
    // this.trackingService.tracking('ActivitiesPageRightPaneNav', TrackingEventNames.NOTIFICATIONS, true);
    this.selectedView = view;
    // this.footerService.isActivitiesButton = true;
  }

  setSelectedView(view: string) {
    this.selectedView = view;
  }

  triggerPreviewButton() {
    const prevBtn = this.footerService.actionButtons.find(b => b.id === 'preview');
    if (prevBtn) {
      this.flipToMeeting(prevBtn, true);
    }
  }

  private _actionButtonDblClickHandler = (button: any, event: MouseEvent) => {
    if (button.id === 'act-active') {
      this.agendaFooterService.publishBtnDblClickEvent(button.id);
    } else {
      this.buttonDblClicked.emit({ buttonId: button.id, event});
    }
  };
  // Not using dblclick event listener because
  // it's not working as expected on iPad OS
  private _onSingleClick(button: any, event) {
    const now = Date.now();
    const diff: number = Math.abs(now - this.lastOnSingleClick);

    if (diff <= this.DOUBLE_CLICK_THRESHOLD) {
      // Double click / tap
      if (this.clickTimeoutHandle !== null) {
        // Cancel single click
        clearTimeout(this.clickTimeoutHandle);
        this.clickTimeoutHandle = null;
      }
      this.lastOnSingleClick = 0;
      this._actionButtonDblClickHandler(button, event);
    } else {
      this.lastOnSingleClick = now;
      if (diff > this.DOUBLE_CLICK_THRESHOLD + 200 && this.clickTimeoutHandle) {
        // Reset handle in case the event slipped
        clearTimeout(this.clickTimeoutHandle);
        this.clickTimeoutHandle = null;
      }
      if (this.clickTimeoutHandle === null) {
        this.clickTimeoutHandle = setTimeout(() => {
          // Single click / tap
          this.buttonEventHandler(button, event);
        }, this.DOUBLE_CLICK_THRESHOLD);
      }
    }
  }
  private _hasDblClickSupport(button: any): boolean {
    let supportDblClick: boolean = false;
    if (button.id === 'act-active') {
      supportDblClick = true;
    }
    return supportDblClick;
  }
  async singleClickListener(button: any, event: MouseEvent) {
    // Only the buttons support double click event will trigger the button event handler
    if (this._hasDblClickSupport(button)) {
      this._onSingleClick(button, event);
    } else {
      let flag = await this.setBookingDataService.checkSetBookingDiscardConfirmationRequired().catch(err=>{});
      if(!flag) return;
      this.buttonEventHandler(button, event);
    }
  }

  async buttonEventHandler(button: any, event?: MouseEvent) {
    // console.log("### button is active at start : " + button['id']);
    if ((!button.isactive || (this.device.isOffline && button.disableWhenOffline))) return;
    switch (button['id']) {
      case 'contactsphonecall':
        this.trackingService.tracking('Selectcustomers', TrackingEventNames.PHONECALL)
        this.buttonClicked.emit(button['id']);
        break;
      case 'contactsMessage':
        this.emailService.isActionTriggeredFromFooter = true;
        this.buttonClicked.emit(button['id']);
        break;
      case 'contactspresentationpreview':
      case 'contacts':
        if (!this.contactService.loadedContacts) return;
        this.events.publish('updateMandatoryField_footer', 'contacts');
        //Tracking event on contacts from email details
          let insightEventName = this.selectedView == 'presentations' ? 'Add contact to a meeting - Meeting Presentations Action bar' : 'Add contact to a meeting - Meeting details Action bar'
          this.trackingService.tracking(insightEventName, TrackingEventNames.ACTIVITY);
          if ((this.activityService.selectedActivity['indskr_suggest'] || this.activityService.selectedActivity.isCompleted) && _.isEmpty(this.activityService.selectedActivity['contacts'])) return;
          if (this.activityService.teamViewActive || this.activityService.selectedActivity.isFromXperiences || ((this.activityService.selectedActivity.isCompleted 
            || this.activityService.selectedActivity['indskr_suggest'] || this.activityService.selectedActivityJointMeetingStatus)
            && this.activityService.selectedActivity['contacts'].length > 0) || (this.device.isBackgroundUploadInProgress && this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID))) {
            if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) {
              this.uiService.prevView = this.uiService.activeView;
              this.uiService.activeView = '';
            }
            this.contactService.accessedContactListFrom = PageName.ActivitiesDetailsPaneComponent;
            this.uiService.showNewActivity = false;
            this.contactService.contactPageMode = ComponentViewMode.READONLY;
            this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, null, PageName.ContactPageComponent);
          } else {
            if (this.navService.getCurrentPageName() === PageName.PresentationMeetingComponent) {
              this.events.publish("pauseResourceVideo");
              this.uiService.showNewActivity = false;
              this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
              this.contactService.accessedContactListFrom = PageName.PresentationMeetingComponent;
              this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, { voiceStatus: this.otAudioSignal });
            }
            else if (this.navService.getCurrentPageName() === PageName.NewSampleActivityComponent) {
              this.uiService.prevView = 'Sample';
              this.uiService.showNewActivity = false;
              this.uiService.activeView = 'contactDetails';
              this.contactService.contactPageMode = ComponentViewMode.SELECTION;
              this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, null, PageName.ContactPageComponent);
            } else {
              this.trackingService.tracking('MeetingAddContactActionBar', TrackingEventNames.ACTIVITY)
              if (this.navService.getCurrentPageName() == PageName.AccountPageComponent) {
                this.uiService.prevView = '';
                this.uiService.activeView = '';
              } else if (this.navService.getActiveChildNavViewPageName() === PageName.ActivitiesDetailsPaneComponent) {
                this.uiService.prevView = this.uiService.activeView;
                this.uiService.activeView = '';
              }
              this.uiService.showNewActivity = false;
              this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
              if (this.navService.getCurrentPageName() == PageName.ContactPageComponent) {
                this.contactService.accessedContactListFrom = PageName.ActivitiesDetailsPaneComponent;
              } else if (this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent) {
                this.contactService.accessedContactListFrom = PageName.ActivitiesDetailsPaneComponent;
              }
              if (this.uiService.activitiesPagePlanTabSelectedPlan == 'callPlans' || this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent) {
                this.contactService.contactInformation = undefined;
                this.uiService.contactDetailsSegment = undefined;
              }
              this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent);
            }
          }
        break;
      case 'resources':
        this.trackingService.tracking('PresentationResourcesActionBar', TrackingEventNames.DIGITAL);
        this.events.publish("pauseResourceVideo");
        this.resourceService.viewMode = ResourceView.PUSH;
        this.navService.pushWithPageTracking(ResourcePageComponent, PageName.ResourcePageComponent);
        break;

      case 'audioactive':
        this.trackingService.tracking('PresentationAudioActivatedActionBar', TrackingEventNames.DIGITAL, true);
        // this.footerService.enableButtons(['video', 'stop_video', 'audio', 'stop_audio', 'play', 'pause'], false);
        const stopAudio = this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_TENCENT_REMOTE_DETAILING) ? 'tencent-stop-audio' : 'opentok-stop-audio'
        this.events.publish(stopAudio);
        break;

      case 'stop_audio':
        this.trackingService.tracking('PresentationAudioDectivatedActionBar', TrackingEventNames.DIGITAL, null, true)
        // this.footerService.enableButtons(['video', 'stop_video', 'audio', 'stop_audio', 'play', 'pause'], false);
        const startAudio = this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_TENCENT_REMOTE_DETAILING) ? 'tencent-start-audio' : 'opentok-start-audio';
        this.events.publish(startAudio);
        break;

      case 'video':
        this.trackingService.tracking('PresentationVideoActivatedActionBar', TrackingEventNames.DIGITAL, true)
        // this.footerService.enableButtons(['video', 'stop_video', 'audio', 'stop_audio', 'play', 'pause'], false);
        const startVideo = this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_TENCENT_REMOTE_DETAILING) ? 'tencent-start-video' : 'opentok-start-video';
        this.events.publish(startVideo);
        break;

      case 'stop_video_active':
        this.trackingService.tracking('PresentationVideoDectivatedActionBar', TrackingEventNames.DIGITAL, null, true)
        // this.footerService.enableButtons(['video', 'stop_video', 'audio', 'stop_audio', 'play', 'pause'], false);
        const stopVideo = this.authService.hasFeatureAction(FeatureActionsMap.ENABLE_TENCENT_REMOTE_DETAILING) ? 'tencent-stop-video' : 'opentok-stop-video';
        this.events.publish(stopVideo);
        break;

      case 'share-resource':
        this.trackingService.tracking('PresentationResourcesPush', TrackingEventNames.DIGITAL, true)
        this.events.publish('share-resource');
        break;

      case 'presentationsrightalligned':
      case 'presentationsleftalligned':
      case 'presentations':
        //let insightEvent = this.selectedView == 'presentations'?'Add Presentation to a meeting -Meeting Presentations Action Bar':'MeetingAddPresentationActionBar'
        this.trackingService.tracking('MeetingAddPresentationActionBar', TrackingEventNames.ACTIVITY)
        if (this.uiService.activeView === 'Appointment') {
          this.meetingStructureService.setCurrentMeetingActivity(null);
        }
        this.events.publish("pauseResourceVideo");
        // this.uiService.prevView = this.navService.getCurrentPageName() == PageName.CallPlanComponent ? 'Appointment' : this.uiService.activeView;
        // this.uiService.activeView = "Presentation";
        this.presentationService.viewMode = PresentationView.ADDTOMEETING;
        this.navService.pushWithPageTracking(PresentationPageComponent, PageName.PresentationPageComponent);
        break;
      case 'presentaion_detail':
          this.events.publish(EventName.PRESENTATION_DETAILS);
        break;
      case 'geenie':
        this.navService.pushWithPageTracking(MyAssistantComponent, PageName.MyAssistantComponent);
        break;
      case 'preview':
        await this.flipToMeeting(button);
        break;
      case 'fullscreen':
        this.trackingService.tracking('PresentationsFullScreen', TrackingEventNames.PRESENTATIONS)
        this.presentationService.prevViewMode = this.presentationService.viewMode;
        this.presentationService.viewMode = PresentationView.FULLSCREEN;
        this.navService.pushWithPageTracking(PresentationFullscreenComponent, PageName.PresentationFullscreenComponent).then(() => {
          this.footerService.initButtons(FooterViews.PresentationFullscreen);
        })
        break;
      case 'exitfullscreen':
        this.trackingService.tracking('PresentationsFullScreenBack', TrackingEventNames.PRESENTATIONS);
        this.navService.popWithPageTracking().then(() => {
          this.events.publish('carousel:iframe-resize');
          this.presentationService.viewMode = this.presentationService.prevViewMode;
          this.footerService.initButtons(FooterViews.Presentations);
        });
        break;
      case 'presentationcarouselactive':
      case 'presentationcarousel':
        this.trackingService.tracking('PresentationsFullScreenCarousel', TrackingEventNames.PRESENTATIONS)
        this.presentationService.hideCarousel = !this.presentationService.hideCarousel;
        if (this.presentationService.hideCarousel) {
          this.footerService.showButton('presentationcarousel');
          this.footerService.hideButton('presentationcarouselactive');
        } else {
          this.footerService.showButton('presentationcarouselactive');
          this.footerService.hideButton('presentationcarousel');
        }
        this.events.publish('footer-toolbar:carousel-toggle');
        break;
      case 'carouselactive':
      case 'carousel':
        this.trackingService.tracking('PresentationsFullScreenCarousel', TrackingEventNames.PRESENTATIONS)
        // hide reference carousel if it is visible
        if (this.presentationService.isReferenceVisible) {
          this.presentationService.isReferenceVisible = false;
          this.footerService.showButton('references');
          this.footerService.hideButton('referencesactive');
        }

        this.presentationService.hideCarousel = !this.presentationService.hideCarousel;
        if (this.presentationService.hideCarousel) {
          this.footerService.showButton('carousel');
          this.footerService.hideButton('carouselactive');
        } else {
          this.footerService.showButton('carouselactive');
          this.footerService.hideButton('carousel');
        }
        // if (window.innerWidth > 500) {
          this.footerService.displayFullScreenButtons();
        // }
        this.events.publish('footer-toolbar:carousel-toggle');
        break;
      case 'references':
      case 'referencesactive': {
        this.trackingService.tracking('PresentationsReferenceCarousel', TrackingEventNames.PRESENTATIONS)
        // hide page carousel if it is visible
        if (!this.presentationService.hideCarousel) {
          this.presentationService.hideCarousel = true;
          this.footerService.showButton('carousel');
          this.footerService.hideButton('carouselactive');
        }

        this.presentationService.isReferenceVisible = !this.presentationService.isReferenceVisible;
        if (this.presentationService.isReferenceVisible) {
          this.footerService.showButton('referencesactive');
          this.footerService.hideButton('references');
        } else {
          this.footerService.showButton('references');
          this.footerService.hideButton('referencesactive');
        }

        this.footerService.displayFullScreenButtons();
        if (!this.presentationService.isReferenceVisible) {
          this.events.publish('footer-toolbar:carousel-toggle');
        }

        break;
      }
      case 'homepage': {
        this.trackingService.tracking('FlipButtonClicked', TrackingEventNames.DIGITAL, null, true);
        console.log("button is active" + button.isactive);
        this.uiService.isFlipButtonClicked = true;
        // button.isactive = !this.uiService.isFlipButtonClicked;
        // this.events.publish('presentation-meeting::flip-clicked');
        this._handleFlipButton();
        // this.handleStopButton(true);
        break;
      }
      case 'cancelmeeting':
      case 'cancelorscrapmeeting':
        this.trackingService.tracking('MeetingScrapActionBar', TrackingEventNames.ACTIVITY, null, true)
        this.events.publish('deleteMeeting');
        break;
      case 'scrapemail':
        this.trackingService.tracking('EmailScrapped', TrackingEventNames.ACTIVITY, null, true);
        this.buttonClicked.emit(button['id']);
        break;

      case 'cancelSamplingInMeeting':
        this.scrapInMeetingSampleDrop();
        break;

      case 'markcomplete':
        // button.isactive = false;
        const channel: Channel = this.consentService.savedChannels.find(sch => {
          if (this.emailService.selectedActivity) {
            return sch.indskr_consenttypeid === this.emailService.selectedActivity.indskr_channelid
          }
        });

        if (channel && channel.indskr_externalchannel === true) {
          this.emailService.isActionTriggeredFromFooter = true;
          this.buttonClicked.emit(button['id']);
        } else {
          this.trackingService.tracking('MeetingMarkCompleteActionBar', TrackingEventNames.ACTIVITY, null, true);
          this.events.publish('completeMeeting');
        }
        break;
      case 'markcompletephonecall':
        this.trackingService.tracking('SelectMarkCompleteicon', TrackingEventNames.PHONECALL, null, true);
        this.events.publish('completePhonecall');
        break;
      case 'play':
        // this.trackingService.tracking('Play button clicked')
        // this.websocket.meetingInProgress = true;
        // this.footerService.hideButton('play');
        // this.footerService.showButton('pause');
        // this.footerService.enableButtons(['homepage'], false);
        // this.updateStartTimeForSelectedActivity();
        // this.events.publish('presentation-meeting::play-clicked');
        this.handlePlayButton();
        break;

      case 'pause':
        this.events.publish('presentation-meeting::pause-clicked');
        break;

      case 'stop':
        this.handleStopButton();
        break;

      case 'send':
        this.events.publish('customer-call-plan-details:footerEvents', 'sendForApproval');
        break;
      case 'scrapphonecall':
        this.trackingService.tracking('SelectScrapicon', TrackingEventNames.PHONECALL)
        this.events.publish('deleteMeeting');
        break;
      case 'clear':
        this.events.publish('customer-call-plan-details:footerEvents', 'clear');
        break;
      case 'sendEmail':
        this.emailService.isActionTriggeredFromFooter = true;
        this.buttonClicked.emit(button['id']);
        break;
      case 'reject':
        this.events.publish('customer-call-plan-details:footerEvents', 'reject');
        this.buttonClicked.emit(button['id']);
        break;

      case 'approve':
        this.events.publish('customer-call-plan-details:footerEvents', 'approve');
        this.buttonClicked.emit(button['id']);
        break;
      case 'viewMeeting':
        this.buttonClicked.emit(button['id']);
        break;
      case 'savetimeoff':
      case 'sendtimeoff':
        this.trackingService.tracking('TimeOffSendforApprovalActionBar', TrackingEventNames.TIMEOFF)
        this.buttonClicked.emit(button['id']);
        break;
      case 'deletetimeoff':
        this.trackingService.tracking('TimeOffScrapTimeOffToolActionBar', TrackingEventNames.TIMEOFF)
        let view: string = this.timeOffService.getMobileTracker();
        this.timeOffUtilityService.scrapTimeOff(<TimeOffActivity>this.activityService.selectedActivity, view);
        break;
      case 'approvetimeoff':
        this.buttonClicked.emit(button['id']);
        break;
      case 'notapprovedtimeoff':
        this.buttonClicked.emit(button['id']);
        break;
      case 'sendcoaching':
        this.trackingService.tracking('CoachingSentActionBar', TrackingEventNames.COACHING);
        this.coachingReportService.submitReport();
        break;
      case 'closecoaching':
        this.trackingService.tracking('CoachingCloseActionBar', TrackingEventNames.COACHING);
        this.coachingReportService.closeReport();
        break;
      case 'deletecoaching':
        this.trackingService.tracking('Scrap coaching report from the coaching tool via scrap icon', TrackingEventNames.COACHING);
        this.coachingReportService.scrapReport();
        break;
      case 'ackcoaching':
        this.trackingService.tracking('CoachingAcknowledgedActionBar', TrackingEventNames.COACHING);
        this.coachingReportService.acknowledgeReport();
        break;
      case 'recallcoaching':
        this.trackingService.tracking('CoachingRecalldActionBar', TrackingEventNames.COACHING);
        this.coachingReportService.recallReport();
        break;
      case 'acceptcoaching':
        // this.trackingService.tracking('CoachingAcknowledgedActionBar', TrackingEventNames.COACHING);
        this.coachingReportService.acceptReport();
        break;
      case 'sharecoaching':
        // this.trackingService.tracking('CoachingAcknowledgedActionBar', TrackingEventNames.COACHING);
        this.coachingReportService.shareReport();
        break;
      case 'contactsAllocation':
        this.events.publish('updateMandatoryField_footer', 'contactsAllocation');
        this.contactService.contactPageMode = ComponentViewMode.SELECTION;
        this.contactService.accessedContactListFrom = PageName.NewSampleActivityComponent;
        this.uiService.showNewActivity = false;
        if (this.sampleService.samplingDetailsViewMode &&
          this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_MEETING || 
          this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_EVENTS) {
          if (!this.navCtrl) {
            this.navCtrl = this.injector.get(IonNav)
          }
          this.uiService.prevView = this.uiService.activeView;
          this.uiService.activeView = 'contactDetails';
          if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
            setTimeout(()=>{
              document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
            });
          }

          this.navCtrl.push(ContactPageComponent, {modalView:{}}, { progressAnimation: false })
        }
        else {
          this.uiService.prevView = 'Sample';
          this.uiService.activeView = 'contactDetails';
          this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, null, PageName.ContactPageComponent);
        }
        break;
      case 'samplingeligibilities':
        this.events.publish(EventName.SAMPLEELLIGIBILITESFOOTERBUTTON);
        // this.trackingService.tracking('AllocationOrderShowEligiblitiesActionBar', TrackingEventNames.ALLOCATIONS)
        // if (this.sampleService.samplingDetailsViewMode &&
        //   this.sampleService.samplingDetailsViewMode ==
        //   SamplingDetailsViewMode.CREATE_FROM_MEETING) {
        //   //@ts-ignore
        //   if (!this.navCtrl._elementRef.nativeElement.classList.contains('fullStretchView')) {
        //     //@ts-ignore
        //     this.navCtrl._elementRef.nativeElement.classList.add('fullStretchView')
        //   }
        //   this.navCtrl.push(ContactAllocationEligibilitiesComponent, {}, { animate: false })
        // }
        // else {
        //   this.navService.pushWithPageTracking(ContactAllocationEligibilitiesComponent, PageName.ContactAllocationEligibilitiesComponent, { initSource: 'ShowEligibilities' })
        // }
        break;
      case 'signature':
        this.events.publish(EventName.ALLOCATION_SIGNATURE_FOOTER_BUTTON);
        break;
      case 'flipToSampleDetails':
        this.trackingService.tracking('AllocationAcknowledgementBackActionBar', TrackingEventNames.ALLOCATIONS);
        if (this.sampleService.samplingDetailsViewMode &&
          this.sampleService.samplingDetailsViewMode ==
          SamplingDetailsViewMode.CREATE_FROM_MEETING || this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_EVENTS) {
          if (!this.navCtrl) {
            this.navCtrl = this.injector.get(IonNav)
          }
          this.navCtrl.pop({ progressAnimation: false }).then(() => {
            this.footerService.initButtons(FooterViews.SampleDropInMeetingDetails);
            this.events.publish('selectedActivityChangedInBackround')
          })
        }
        else {
          this.navService.popWithPageTracking().then(() => {
            this.footerService.initButtons(FooterViews.SampleDropDetails);
            this.events.publish('selectedActivityChangedInBackround')
          });
        }
        break;
      case 'createOrEditSampleOrderPresentationPreview':
        this.trackingService.tracking('AllocationOrderCreatedMeetingPresentation', TrackingEventNames.ALLOCATIONS)
        this.createOrEditSampleOrderFromMeeting();
        break;
      case 'createOrEditSampleOrder':
        //handle sample from meeting
        this.trackingService.tracking('AllocationOrderCreatedMeetingDetails', TrackingEventNames.ALLOCATIONS)
        this.createOrEditSampleOrderFromMeeting();
        break;
      case 'adjustShipment':
        console.log(button);
        this.events.publish('adjustment-detail:footerEvents', 'adjustShipment');
        break;
      case 'approveAdjustment':
        console.log(button);
        this.events.publish('shipment-detail:footerEvents', 'approveAdjustment');
        break;
      case 'notApproveAdjustment':
        console.log(button);
        this.events.publish('shipment-detail:footerEvents', 'notApproveAdjustment');
        break;
      case 'scrapAdjustment':
        this.events.publish('adjustment-detail:footerEvents', 'scrapAdjustment');
        break;
      case 'saveAllocationAdjustment':
        this.events.publish('adjustment-detail:footerEvents', 'adjustShipment');
        break;
      case 'savescheduler':
        this.trackingService.tracking('SchedulerPatternSavedActionBar', TrackingEventNames.SCHEDULER);
        this.events.publish('scheduler-detail:footerEvents', 'savescheduler');
        break;
      case 'scrapScheduler':
        this.events.publish('scheduler-detail:footerEvents', 'scrapScheduler');
        break;
      case 'suggestscheduler':
        this.trackingService.tracking('SchedulerSuggestScheduleActionBar', TrackingEventNames.SCHEDULER);
        this.events.publish('scheduler-detail:footerEvents', 'suggestscheduler');
        break;
      case 'copyscheduler':
        this.trackingService.tracking('SchedulerSuggestScheduleActionBar', TrackingEventNames.SCHEDULER);
        this.events.publish('scheduler-detail:footerEvents', 'copyscheduler');
        break;
      case 'editText':
        this.events.publish('editText:clicked');
        this.footerService.disableButton(['confirmChanges']);
        break;
      case 'confirmChanges':
        this.events.publish('confirm:sendForApproval');
        this.footerService.disableButton(['editText', 'confirmChanges']);
        break;
      case 'generateconsents':
        this.buttonClicked.emit(button['id']);
        break;
      case 'consentFlip':
        this.buttonClicked.emit(button['id']);
        break;
      case 'consentFlipDetails':
        this.events.publish('consentDetails:footerEvents', 'consentFlipDetails');
        break;
      case 'template':
        this.emailService.isActionTriggeredFromFooter = true;
        this.buttonClicked.emit(button['id']);
        break;
      case 'appsettingupload':
        this.events.publish('appsettings:upload');
        break;
      case 'createEmailInMeeting':
        this.createEmailInMeeting({ channelType: ChannelType.EMAIL }, EmailTemplateType.Resource);
        break;
      case 'cancelprogressreport':
        this.scrapReport.emit()
        break;
      case 'sendprogressreport':
        this.sendReportForApproval.emit()
        break;
      case 'notapproveprogressreport':
        this.notApproveReport.emit()
        break;
      case 'approveprogressreport':
        this.approveReport.emit()
        break;
      case 'cancelProgressReportReview':
        this.cancelReportReview.emit()
        break;
      case 'createEmailInPresentationPreview':
        this.createEmailInMeeting({ channelType: ChannelType.EMAIL }, EmailTemplateType.Resource);
        break;
      case "attachment": {
        this.emailService.isActionTriggeredFromFooter = true;
        this.buttonClicked.emit(button['id']);
        break;
      }
      case "personalize_template": {
        this.emailService.isActionTriggeredFromFooter = true;
        this.buttonClicked.emit(button['id']);
        break;
      }
      case "scrapfollowup": {
        this.handleFollowUpActivityDeletion();
        break;
      }
      case "markfollowupcancel": {
        this.events.publish('followup:cancelfollowup',this.activityService.selectedActivity.ID);
        break;
      }   
      case "markfollowupcomplete": {
        if (this.activityService.selectedActivity.type == ActivityType.FollowUp) {
          //this.footerService.disableButton(['markfollowupcomplete', 'scrapfollowup']);
          this.handleFollowUpActivityCompletion();
        }
        break;
      }
      case "scrapcase": {
        this.events.publish('case:footerEvents', 'scrapcase');
        break;
      }
      case "submitcase": {
        this.events.publish('case:footerEvents', 'submitcase');
        break;
      }
      case "meetingCustomerInquiry": {
        await this.createCustomerInquiryForMeeting(AccesingMode.INMEETING);
        break;
      }
      case "presentationCustomerInquiry": {
        await this.createCustomerInquiryForMeeting(AccesingMode.PRESENTATION);
        break;
      }
      case 'scrapfollowup': {
        this.handleButtonEventForOrderActivity('scrapfollowup');
        break;
      }
      case 'markfollowupcomplete': {
        this.handleButtonEventForOrderActivity('markfollowupcomplete');
        break;
      }
      case 'scraporderactivity': {
        this.handleButtonEventForOrderActivity('scraporderactivity');
        break;
      }
      case 'autoapproveorderactivity':
      case 'sendorderactivityforreview': {
        ``
        this.handleButtonEventForOrderActivity('sendorderactivityforreview');
        break;
      }
      case 'cancelorderactivityreview': {
        this.handleButtonEventForOrderActivity('cancelorderactivityreview');
        break;
      }
      case 'notapproveorderactivity': {
        this.handleButtonEventForOrderActivity('notapproveorderactivity');
        break;
      }
      case 'approveorderactivity': {
        this.handleButtonEventForOrderActivity('approveorderactivity');
        break;
      }
      case 'fulfillorderactivity': {
        this.handleButtonEventForOrderActivity('fulfillorderactivity');
        break;
      }
      case 'reopenorderactivity': {
        this.handleButtonEventForOrderActivity('reopenorderactivity');
        break;
      }
      case 'scrapSurgeryOrderActivity':
      case 'scraporcancelSurgeryOrderActivity': {
        this.events.publish('surgeryOrder:footerEvents', 'scrapsurgeryorderactivity');
        break;
      }
      case 'scrapProcedureTrackerActivity': {
        this.buttonClicked.emit('scrapproceduretrackeractivity');
        break;
      }
      case 'markCompleteProcedureTrackerActivity': {
        this.buttonClicked.emit('markcomplete');
        break;
      }
      case 'reopenproceduretrackeractivity': {
        this.buttonClicked.emit('reopenproceduretrackeractivity');
        break;
      }
      case 'markCompleteSurgeryOrder': {
        this.events.publish('surgeryOrder:footerEvents', 'markcomplete');
        break;
      }
      case 'reopensurgeryactivity': {
        this.events.publish('surgeryOrder:footerEvents', 'reopensurgeryactivity');
        break;
      }
      case 'assignOwnersurgeryactivity':
        this.events.publish('surgeryOrder:footerEvents', 'assignOwnersurgeryactivity');
        break;
      case 'meetingEvent':
        this.eventRegistrationsInMeeting();
        break;
      case 'meetingOpportunity':
        this.handleInMeetingOpportunity();
        break;
      case 'openmoreoptions':
        this.showMoreFooterOptions(event, button);
        break;
      case 'meetingswipeenabledactive':
        this.footerService.hideButton('meetingswipeenabledactive');
        this.footerService.showButton('meetingswipedisabled');
        this.presentationService.swipeStatus = SwipeStatus.DISABLED_BY_USER;
        // if (window.innerWidth > 500) {
          this.footerService.displayFullScreenButtons();
        // }
        this.ref.markForCheck();
        this.ref.detectChanges();
        break;
      case 'meetingswipedisabled':
        this.footerService.hideButton('meetingswipedisabled');
        this.footerService.showButton('meetingswipeenabledactive');
        this.presentationService.swipeStatus = SwipeStatus.ENABLED_BY_USER;
        // if (window.innerWidth > 500) {
          this.footerService.displayFullScreenButtons();
        // }
        this.ref.markForCheck();
        this.ref.detectChanges();
        break;
      case 'presentationswipeenabled':
        console.log("SWIPE ENABLE");
        this.footerService.hideButton('presentationswipeenabled');
        this.footerService.showButton('presentationswipedisabled');
        this.presentationService.swipeStatus = SwipeStatus.DISABLED_BY_USER;
        this.ref.markForCheck();
        this.ref.detectChanges();
        break;
      case 'presentationswipedisabled':
        console.log("SWIPE DISABLED");
        this.footerService.hideButton('presentationswipedisabled');
        this.footerService.showButton('presentationswipeenabled');
        this.presentationService.swipeStatus = SwipeStatus.ENABLED_BY_USER;
        this.ref.markForCheck();
        this.ref.detectChanges();
        break;
      case 'completeStoreCheck':
      case 'scrapStoreCheck':
          this.buttonClicked.emit(button['id']);
          break;
      case 'opportunityscrap':
        this.events.publish(EventName.OPPORTUNITYSCRAPBUTTON);
        break;
      case 'confirmopportunity':
        this.events.publish(EventName.OPPORTUNITYMARKCONFIRMED);
        break;
      case 'closeaswon':
        this.events.publish(EventName.OPPORTUNITYCLOSEASWON, event);
        break;
      case 'closeaslost':
        this.events.publish(EventName.OPPORTUNITYCLOSEASLOST, event);
        break;
      case 'closeasusercanceled':
        this.events.publish(EventName.OPPORTUNITYCLOSEASUSERCANCELED, event);
        break;
      case 'assignOwneropportunity':
        this.events.publish(EventName.ASSIGN_OPPORTUNITY_OWNER, event);
        break;
      case 'marketingEmailDislike':
        this.dislikeActivity.emit()
        break;
      case 'marketingEmailLike':
        this.likeActivity.emit()
        break;
      case 'flipToInquiryDetails':
        this.events.publish('Inquiryconsent:footerEvents', 'flipToInquiryDetails');
        break
      case 'flipToInquiryAcknowledgement':
        this.events.publish('InquiryAcknowledgement:footerEvents', 'flipToInquiry');
        break;
      case 'saveMarketScan':
      case 'updateMarketScan':
        this.events.publish('marketScan:footerEvents', 'saveMarketScan');
        break;
      case 'scrapMarketScan':
        this.events.publish('marketScan:footerEvents', 'scrapMarketScan');
        break;
      case 'editMarketScan':
        this.events.publish('marketScan:footerEvents', 'editMarketScan');
        break;
      case 'transferasset':
      case 'confirmtransfer':
      case 'cancelassetdeployment':
      case 'transfer-reject':
      case 'transfer-acknowledge':
      case 'transfer-complete':
      case 'transfer-cancel':
      case 'message':
      case 'pdfPreview':
      case 'exportPDF':
      case 'generalSave':
      case 'generalUpdate':
      case 'generalSend':
      case 'generalTemplate':
      case 'generalCancel':
      case 'acknowledgeShipment':
      case 'pharmacovigilance-contacts-us':
      case 'pharmacovigilance-special-situations':
      case 'pharmacovigilance-adverse-events':
      case 'QRcodeScan':
      case 'uploadPDF':
      case 'downloadPDF':
      case 'scrapsetbooking':
      case 'editsetbooking':
      case 'sendEmailContract':
        this.buttonClicked.emit(button['id']);
        break;
      case 'week':
        if (this.agendaFooterService.lastFooterView === AgendaFooterView.ShortCallLauncher && this.activityService.selectedActivityOnHomeScreen) {
          this.notificationService.notify(this.translate.instant('HOME_SCREEN_AC_ACTIVE_NOTIFICATION'), 'Short Call Home', 'top', ToastStyle.INFO);
          return;
        }
        if(this.agendaFooterService.actSegment === "scheduler") {
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        }
        if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
          // Work around for Short Call Launcher navigation
          this.uiService.setAgendaTab('agenda');
        }
        this.agendaFooterService.initButtons(AgendaFooterView.Week);
        break;
      case 'scheduler':
        if (this.agendaFooterService.lastFooterView === AgendaFooterView.ShortCallLauncher && this.activityService.selectedActivityOnHomeScreen) {
          this.notificationService.notify(this.translate.instant('HOME_SCREEN_AC_ACTIVE_NOTIFICATION'), 'Short Call Home', 'top', ToastStyle.INFO);
          return;
        }
        // sets rhs to nothing selected view
        this.uiService.showNewActivity = false;
        this.activityService.selectedActivity = undefined;
        this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        this.agendaFooterService.initButtons(AgendaFooterView.Scheduler);
        break;
      case 'approvals':
        if (this.agendaFooterService.lastFooterView === AgendaFooterView.ShortCallLauncher && this.activityService.selectedActivityOnHomeScreen) {
          this.notificationService.notify(this.translate.instant('HOME_SCREEN_AC_ACTIVE_NOTIFICATION'), 'Short Call Home', 'top', ToastStyle.INFO);
          return;
        }
        this.uiService.showNewActivity = false;
        this.activityService.selectedActivity = undefined;
        this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        this.uiService.showRightPane = true;
        this.agendaFooterService.initButtons(AgendaFooterView.Approvals);
        break;
      case 'act':
        if (this.agendaFooterService.lastFooterView === AgendaFooterView.ShortCallLauncher && this.activityService.selectedActivityOnHomeScreen) {
          this.notificationService.notify(this.translate.instant('HOME_SCREEN_AC_ACTIVE_NOTIFICATION'), 'Short Call Home', 'top', ToastStyle.INFO);
          return;
        }
        if(this.agendaFooterService.actSegment === "scheduler") {
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        }
        if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
          // Work around for Short Call Launcher navigation
          this.uiService.setAgendaTab('agenda');
          this.uiService.activitiesPageTab = 'agenda';
        }
        this.agendaFooterService.initButtons(AgendaFooterView.Act);
        break;
      case 'short-call':
        if (this.agendaFooterService.lastFooterView === AgendaFooterView.ShortCallLauncher && this.activityService.selectedActivityOnHomeScreen) {
          this.notificationService.notify(this.translate.instant('HOME_SCREEN_AC_ACTIVE_NOTIFICATION'), 'Short Call Home', 'top', ToastStyle.INFO);
          return;
        }
        // Short Call Launcher only
        this.uiService.showNewActivity = false;
        this.activityService.selectedActivity = undefined;
        this.uiService.showRightPane = false;
        this.agendaFooterService.initButtons(AgendaFooterView.ShortCallLauncher);
        this.uiService.setAgendaTab('shortCallHome');
        break;
      case 'call-plan-dashboard':
        if (this.agendaFooterService.lastFooterView === AgendaFooterView.ShortCallLauncher && this.activityService.selectedActivityOnHomeScreen) {
          this.notificationService.notify(this.translate.instant('HOME_SCREEN_AC_ACTIVE_NOTIFICATION'), 'Short Call Home', 'top', ToastStyle.INFO);
          return;
        }
        this.uiService.showNewActivity = false;
        this.uiService.showRightPane = false;
        this.agendaFooterService.initButtons(AgendaFooterView.CallPlanDashboard);
        this.uiService.setAgendaTab('callPlanDashboard');
        break;
      case 'notifications':
        if (this.agendaFooterService.lastFooterView === AgendaFooterView.ShortCallLauncher && this.activityService.selectedActivityOnHomeScreen) {
          this.notificationService.notify(this.translate.instant('HOME_SCREEN_AC_ACTIVE_NOTIFICATION'), 'Short Call Home', 'top', ToastStyle.INFO);
          return;
        }
        // Short Call Launcher only
        this.agendaFooterService.initButtons(AgendaFooterView.Notifications);
        this.uiService.setAgendaTab('notifications');
        break;
      case 'impact-feed':
        this.xperiencesService.segmentType = IMPACT_SEGMENT.FEED;
        this.checkForUnsavedChanges(button);
        break;
      case 'impact-contacts':
        this.xperiencesService.segmentType = IMPACT_SEGMENT.CONTACT;
        this.checkForUnsavedChanges(button);
        break;
      case 'impact-accounts':
        this.xperiencesService.segmentType = IMPACT_SEGMENT.ACCOUNT;
        this.checkForUnsavedChanges(button);
        break;
      case 'impact-saved-contact':
        this.xperiencesService.segmentType = IMPACT_SEGMENT.SAVED_CONTACT;
        this.checkForUnsavedChanges(button);
        break;
      case 'impact-location':
        this.xperiencesService.segmentType = IMPACT_SEGMENT.LOCATION;
        this.checkForUnsavedChanges(button);
        break;
      case 'plantab-goals-plan':
        if (this.agendaFooterService.lastFooterView === AgendaFooterView.ShortCallLauncher && this.activityService.selectedActivityOnHomeScreen) {
          this.notificationService.notify(this.translate.instant('HOME_SCREEN_AC_ACTIVE_NOTIFICATION'), 'Short Call Home', 'top', ToastStyle.INFO);
          return;
        }
        this.agendaFooterService.initButtons(AgendaFooterView.PlanGoalsPlan);
        this.resetValues();
        break;
      case 'plantab-goals':
        if (this.agendaFooterService.lastFooterView === AgendaFooterView.ShortCallLauncher && this.activityService.selectedActivityOnHomeScreen) {
          this.notificationService.notify(this.translate.instant('HOME_SCREEN_AC_ACTIVE_NOTIFICATION'), 'Short Call Home', 'top', ToastStyle.INFO);
          return;
        }
        this.agendaFooterService.initButtons(AgendaFooterView.PlanGoals);
        this.resetValues();
        break;
      case 'plantab-todo':
        if (this.agendaFooterService.lastFooterView === AgendaFooterView.ShortCallLauncher && this.activityService.selectedActivityOnHomeScreen) {
          this.notificationService.notify(this.translate.instant('HOME_SCREEN_AC_ACTIVE_NOTIFICATION'), 'Short Call Home', 'top', ToastStyle.INFO);
          return;
        }
        this.trackingService.tracking('ToDoIconClick', TrackingEventNames.PLAN_TAB_TO_DO);
        this.agendaFooterService.initButtons(AgendaFooterView.PlanTODO);
        break;
      case 'plantab-notification':
        if (this.agendaFooterService.lastFooterView === AgendaFooterView.ShortCallLauncher && this.activityService.selectedActivityOnHomeScreen) {
          this.notificationService.notify(this.translate.instant('HOME_SCREEN_AC_ACTIVE_NOTIFICATION'), 'Short Call Home', 'top', ToastStyle.INFO);
          return;
        }
        this.trackingService.tracking('NotificationIconClick', TrackingEventNames.NOTIFICATIONS);
        this.agendaFooterService.initButtons(AgendaFooterView.PlanNotifications);
        this.resetValues();
        break;
      case 'meetingReopen':
      case 'meetingAssets':
      case 'teams_meeting':
      case 'call-plan-current':
      case 'call-plan-past':
      case 'call-plan-future':
        this.buttonClicked.emit(button['id']);
        break;
      case 'phoneCallReopen':
        this.buttonClicked.emit(button['id']);
        break;
      case 'shareMessage':
        const channelType: Channel = this.consentService.savedChannels.find(sch => {
          if (this.emailService.selectedActivity) {
            return sch.indskr_consenttypeid === this.emailService.selectedActivity.indskr_channelid
          }
        });
        if (channelType && channelType.indskr_externalchannel === true) {
          this.emailService.isActionTriggeredFromFooter = true;
          this.buttonClicked.emit(button['id']);
        }
        break;
      case 'transferallocation':
        this.events.publish('openTransferConfirm');
        break;
      case 'emailTemplateFlipPreview':
        this.events.publish('replacement-back-clicked');
        break;
      case 'emailTemplateModalPreview':
        this.events.publish('template-preview-clicked');
        break;
      case 'saveMultipleAssess':
        this.events.publish('saveMultipleAssess');
        break;
      case 'savecontactkolstatus':
        this.buttonClicked.emit(button['id'])
        break;
      case 'approvecontactkolstatus':
        this.buttonClicked.emit(button['id'])
        break;
      case 'rejectcontactkolstatus':
        this.buttonClicked.emit(button['id'])
        break;
      // case 'kol-status-management':
      //   this.navService.pushWithPageTracking(KOLStatusPageComponent, PageName.KOLStatusPageComponent, {}, PageName.KOLStatusPageComponent);
      //   break;
      case 'checkin':
      case 'checkout':
        this.buttonClicked.emit(button['id']);
        break;
      case 'savecontactmedicalinsight':
        this.buttonClicked.emit(button['id']);
        break;
      case 'meetingassignOwner':
        this.buttonClicked.emit(button['id']);
        break;
      case 'rejectManageList':
      case 'approveManageList':
      case 'saveterritory':
      case 'sendTerritoryForApproval':
      case 'scrapterritory':
      case 'saveFilter':
      case 'startSurvey':
      case 'scrapSurvey':
      case 'saveSurvey':
      case 'submitSurvey':
      case 'completeShortCall':
      case 'remoteMeeting':
      case 'shareContent':
        this.buttonClicked.emit(button['id']);
        break;
      case 'hopQrCode':
        this.buttonClicked.emit(button['id']);
        break;
      case 'approveDCRRequest':
        this.buttonClicked.emit(button['id']);
        break;
      case 'rejectDCRRequest':
        this.buttonClicked.emit(button['id']);
        break;
      default:
        //do not remove this line
        this.footerService.publishButtonId(button['id']);
        break;
    }
  }

  private resetValues() {
    this.allocFeatureService.selectedShipment = null;
    this.allocFeatureService.selectedShipmentAtHome = null
    this.activityService.selectedActivity = null;
    this.activityService.selectedActivityAtHome = null;
  }


  private async eventRegistrationsInMeeting() {
    this.uiService.prevView = this.uiService.activeView;
    this.uiService.activeView = 'EventRegistration';
    let modal = await this.modalCtrl.create({ component: InMeetingAllocationComponent, componentProps: { type: ActivityType.EventRegistration }, backdropDismiss: false, cssClass: 'inMeetingAllocation' })
    modal.present();
    modal.onDidDismiss().then((data) => {
      if (this.uiService.activeView == 'Meeting' || this.navService.getCurrentPageName() == PageName.PresentationMeetingComponent) {
        this.footerService.initButtons(FooterViews.PreviewMeeting)
      } else {
        this.footerService.initButtons(FooterViews.Activities)
      }
    });
  }

  @IoEventListener('activities-details-pane:createEmail', function () { return this.footerView == 'detailView' && this.selectedView == FooterViews.Activities })
  private async createEmailInMeeting(channelDetails: Partial<EmailActivityChannelDetails>, type: EmailTemplateType, channelId = '', channelActivityType = null) {
    try {
      const channelType = channelDetails.channelType;
      this.trackingService.tracking('EmailActivityFromMeeting', TrackingEventNames.EMAIL, true);
      const isResourceEmail = type === EmailTemplateType.Resource;
      if (isResourceEmail) {
        const emailExist: EmailActivity = this.activityService.activities.find(activity =>
          activity instanceof EmailActivity && activity.channelType === channelType && activity.emailType == type
          && activity.appointmentid == this.activityService.selectedActivity.ID && activity.status == 1) as EmailActivity;
        if (emailExist) {
          this.createEmailModal(emailExist);
          return;
        }
      }
      const scheduleTime: number = new Date().getTime();
      const request: any = {
        scheduledstart: scheduleTime,
        scheduledend: scheduleTime,
        subject: `${this.translate.instant('NEW')} ${(isResourceEmail ? this.translate.instant('EMAIL') : this.translate.instant('MESSAGE'))}`,
        channelType: channelDetails.channelType,
        channelId: channelDetails.id,
        channelActivityType: channelDetails.activityType,
        statuscode: 1,
        appointmentid: this.activityService.selectedActivity.ID
      }

      const contatcs = (<AppointmentActivity>this.activityService.selectedActivity).contacts;
      let emailAddresses: string[] = [];
      let emailParties: EmailActivityParty[] = [];
      if (/*!this.authService.hasFeatureAction(FeatureActionsMap.CONSENT_TOOL) && */contatcs && contatcs.length > 0) {
        for (let contact of contatcs) {
          let emailAddress: EmailAddress[] = [];
          contact.emailAddressList = contact.emailAddressList || [];
          for (let ea of contact.emailAddressList) {
            if (ea.isPrimary) {
              ea.isSelected = true;
              emailAddresses.push(ea.emailAddressId);
              emailAddress.push(new EmailAddress(ea.emailAddressId, ea.emailAddress));
            } else {
              ea.isSelected = false;
            }
          }
          if (emailAddress.length === 0 && contact.emailAddressList.length > 0) {
            let ea = contact.emailAddressList[0];
            ea.isSelected = true;
            emailAddresses.push(ea.emailAddressId);
            emailAddress.push(new EmailAddress(ea.emailAddressId, ea.emailAddress));
          }

          if (channelType === ChannelType.EMAIL && emailAddress.length === 0) continue;
          if (channelType === ChannelType.FACEBOOK && (contact.indskr_facebookpsid || '') === '') continue;
          if ((channelType === ChannelType.WHATSAPP || channelType === ChannelType.SMS) && (contact.mobilePhone || '') === '') continue;
          let emailParty: EmailActivityParty = new EmailActivityParty();
          emailParty.allowToRemove = true;
          emailParty.contact_firstname = contact.firstName;
          emailParty.contact_lastname = contact.lastName;
          emailParty.contact_mobilephone = contact.mobilePhone;
          emailParty.contact_indskr_facebookpsid = contact.indskr_facebookpsid;
          emailParty.indskr_contactid = contact.ID;
          emailParty.emailAddresses = emailAddress;
          emailParties.push(emailParty);
        }
      }
      if (emailAddresses.length > 0) {
        request.emailAddresses = emailAddresses;
      }
      request.channelType = channelType;
      if (channelType != ChannelType.EMAIL) {
        request.emailActivityParties = emailParties;
      }
      if (channelId) {
        request.channelId = channelId;
      }
      if (channelActivityType) {
        request.channelActivityType = channelActivityType;
      }
      request.emailType = type;
      let activity: EmailActivity = await this.emailService.createNewEmailActivity(request);
      if (activity.isSocialChannel || activity.channelActivityType === ChannelActivityType.WECOM) {
        await this.emailService.updateEmailActivityContactParties(emailParties, channelType, activity.ID, channelActivityType);
      }
      this.emailService.updateEmailActivtyDetailsOnContactSelection(activity, emailParties);
      this.activityService.addActivity(activity);
      let data = {
        id: activity.ID, subject: activity.subject, info: activity.info, type: activity.type,
        scheduledStart: activity.scheduledStart, scheduledEnd: activity.scheduledEnd,
        messageType: type,
      };
      if (this.uiService.activitiesPageTab === 'day') {
        this.uiService.setUIServiceData({
          view: "new-activity", data
        });
      }
      if (!this.uiService.toolsActivityActive) {
        this.events.publish("refreshAgenda");
      } else this.uiService.agendaRefreshRequired = true;
      this.createEmailModal(activity);
      if (type == EmailTemplateType.RemoteURL && (<AppointmentActivity>this.activityService.selectedActivity).accompaniedUserList && (<AppointmentActivity>this.activityService.selectedActivity).accompaniedUserList.length > 0) {
        await this.emailService.addRemoveAccompaniedUsers((<AppointmentActivity>this.activityService.selectedActivity).accompaniedUserList);
      }
    } catch (ex) {
      console.log("Error creating email in meeting : " + ex);
    }
  }

  private async deviceOrientationChangeDetects() {
    if (this.isMasterPopoverVisible) {
      if (this.isDeviceOrientation !== this.getOrientationRelatedValue()) {
        if (this.popoverObj) {
          this.isDeviceOrientation = this.getOrientationRelatedValue();
          this.popoverObj.dismiss();
          this.removeTimer();
        }
      }
    }
  }

  private startTimer() {
    if (this.isMasterPopoverVisible) {
      this.interval = setInterval(() => {
        this.deviceOrientationChangeDetects();
      }, 1);
    }
  }

  private removeTimer() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  private getOrientationRelatedValue(): number {
    return (this.device.isMobileDevicePortrait === true ? 1 : 2);
  }

  private async showMoreFooterOptions(event, button) {
    this.removeTimer();
    this.isDeviceOrientation = this.getOrientationRelatedValue();
    this.footerService.footerView = this.footerView;
    // .extendedMoreOptionsPopoverMasterLandscape
    // .extendedMoreOptionsPopoverDetailsLandscape
    const isMasterView = (this.footerView === 'masterView');
    // const isMasterView = (this.footerView === 'masterView' && !this.device.isMobileDevice && !this.device.isMobileDevicePortrait);
    var className = 'moreOptionsPopover';
    if (isMasterView) {
      this.isMasterPopoverVisible = true;
      this.startTimer();
      if (this.device.isMobileDevice && this.device.isMobileDevicePortrait === false) {
        // Mobile > Master and Landscape
        className = 'extendedMoreOptionsPopoverMasterLandscape';
      } else if (this.device.isMobileDevice === false) {
        className = 'extendedMoreOptionsPopoverMasterLandscape';
      }
    } else {
      if (this.device.isMobileDevice && this.device.isMobileDevicePortrait === false) {
        // Mobile > Details and Landscape
        className = 'extendedMoreOptionsPopoverDetailsLandscape';
      }
    }
    // isMasterView ? '' : 'moreOptionsPopover'
    // isMasterView ? event : null
    if (!this.popoverObj) {
      this.popoverObj = await this.popoverCtrl.create({ component: MoreOptionsPopoverComponent, cssClass: className, event: null, backdropDismiss: true });
    }
    // let popover = await this.popoverCtrl.create({component: MoreOptionsPopoverComponent, cssClass: className , event: null, backdropDismiss: true});
    await this.popoverObj.present();
    await this.popoverObj.onDidDismiss().then((obj: any) => {
      this.isMasterPopoverVisible = false;
      this.popoverObj = undefined;
      this.removeTimer();
      const data = obj && obj.data;
      this.footerService.hideButton('openmoreoptionsactive');
      this.footerService.showButton('openmoreoptions');
      if (data && data.id) {
        // Invoke handler in the next tick so that
        // popover dismiss and footer icon redraw to happen
        // before the event handler
        setTimeout(() => {
          this.buttonEventHandler(data);
        }, 0);
      }
    })
  }

  private async createEmailModal(activity: EmailActivity) {
    this.uiService.showNewActivity = false;
    this.emailService.selectedActivity = activity;
    this.emailService.setCurrentEmail(activity);
    this.emailService.viewType = EmailViewType.CREATE_FROM_MEETING;
    const isMeetingFromContactTimeline: boolean = this.activityService.selectedActivity && this.activityService.selectedActivity instanceof AppointmentActivity && this.activityService.selectedActivity.accessedFrom == PageName.ContactTimelineComponent;
    const isModalWrapperExisting: boolean = !!document.getElementsByTagName('ion-modal')[0];
    if(isMeetingFromContactTimeline && isModalWrapperExisting) {
      console.log("Skipped the creating modal view");
    }else {
      let modal = await this.modalCtrl.create({ component: InMeetingAllocationComponent, componentProps: { type: ActivityType.Email }, backdropDismiss: false, cssClass: 'inMeetingAllocation' })
      modal.present();
      modal.onDidDismiss().then((data) => {
        if (this.uiService.activeView == 'Appointment') {
          this.footerService.initButtons(FooterViews.Activities)
        }
        if (this.uiService.activeView == 'Meeting' || this.navService.getCurrentPageName() == PageName.PresentationMeetingComponent) {
          this.footerService.initButtons(FooterViews.PreviewMeeting)
        }
        if (this.uiService.activeView == 'activityTimelineDetails') {
          this.footerService.initButtons(FooterViews.Activities);
        }
      });
    }
  }

  private async handlePlayButton() {

    if (this.navService.getCurrentPageName() == PageName.PresentationPageComponent || this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent || this.navService.getCurrentMasterPageName() === PageName.AccountPageComponent) {

      this.alertService.showAlert({
        title: this.translate.instant('START_MEETING'),
        message: this.translate.instant('START_MEETING_WITH') + " " + this.presentationService.activePresentation.name + '?'
      }, this.translate.instant('START')
      ).then(async res => {
        if (res.role == "ok") {
          
          let loader = await this.loadingController.create();
          loader.present();
         
          if (this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent || this.navService.getCurrentMasterPageName() === PageName.AccountPageComponent) {
            this.meetingDataService.createdNestedAccountVisitWithoutContact(this.activityService.selectedActivity as AppointmentActivity).then(() => {
              this.meetingStructureService.createEmbeddedInteractions(<AppointmentActivity>this.activityService.selectedActivity).then(() => {
                this.flipToMeeting(null, false , true);
                loader.dismiss();
              });
            });
            if(this.navService.getCurrentMasterPageName() === PageName.AccountPageComponent){
              await this.navService.popChildNavPageWithPageTracking();
            }
          } else {
            if(this.navService.getActiveChildNavName() === ChildNavNames.PresentationToolsNavigation){
              await this.navService.popChildNavCtrlFromStack(ChildNavNames.PresentationToolsNavigation);
            }
            if (this.presentationService.selPresentation.length > 0) {
              this.meetingDataService.createMeetingFromPresentation(<Presentation[]>this.presentationService.selPresentation).then(() => {
                this.meetingStructureService.createEmbeddedInteractions(<AppointmentActivity>this.activityService.selectedActivity).then(() => {
                  this.flipToMeeting(null);
                  this.presentationService.selPresentation = [];
                  loader.dismiss();
                });
              });
            } else if ([this.presentationService.activePresentation].length > 0) {
              this.meetingDataService.createMeetingFromPresentation([<Presentation>this.presentationService.activePresentation]).then(() => {
                this.meetingStructureService.createEmbeddedInteractions(<AppointmentActivity>this.activityService.selectedActivity).then(() => {
                  this.flipToMeeting(null);
                  loader.dismiss();
                });
              });
            }

          }
        

        }
      });

      return;
    }

    this.trackingService.tracking('PlayButtonClicked', TrackingEventNames.DIGITAL, true)
    this.websocket.meetingInProgress = true;
    this.footerService.hideButton('play');
    this.footerService.showButton('pause');
    this.footerService.showButton('homepage_disabled');
    this.footerService.hideButton('homepage');
    this.footerService.addOrRemoveOnFAB(false, 'homepage_disabled');
    //enable genie speech detection if feature enabled
    if (this.authService.hasFeatureAction(FeatureActionsMap.GENIE_CALL_NOTES)
      && !this.authService.hasFeatureAction(FeatureActionsMap.MEETING_VOIP)
      && !this.device.isOffline) {
      this.speechService.initRecognizer();
    }
    // this.footerService.enableButtons(['homepage'], false);
    this.updateSelectedActivityStartTime();
    this.events.publish('presentation-meeting::play-clicked');
    // if (window.innerWidth > 500) {
      this.footerService.displayFullScreenButtons();
    // }
    /*setTimeout(() => {
      this.updateStartTimeForSelectedActivity(); //Handled via websocket now
    }, 5000);*/
  }
  private handleStopButton(pop?: boolean): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.activityService.isThereConnectedContactsForCurrentMeeting(this.activityService.selectedActivity as AppointmentActivity, true)
        && !this.websocket.guestsAreConnected) {
        if (!this.navCtrl) {
          this.navCtrl = this.injector.get(IonNav)
        }
        if (this.navCtrl.canGoBack()) {
          this.nativePageTrans.flip(leftFlipNavTransOption);
          this.websocket.sendRemoteMeetingEnd(this.activityService.selectedActivity, true);
          this.websocket.meetingInProgress = false;

          (pop ? this.navService.popWithPageTracking() : undefined);

        }

        return;
      }

      this.alertService.showAlert({
        title: this.translate.instant('CONFIRM_MEETING'),
        message: this.translate.instant('DO_U_WANT_END_THE_MEETING', { globalCustomerText: this.utilityService.globalCustomerText })
      }
      ).then(async res => {
        if (res.role == "ok") {
          //Send shut down signal via WS
          this.websocket.sendRemoteMeetingEnd(this.activityService.selectedActivity, true);
          this.websocket.meetingInProgress = false;
          //Leave the page like we're sliding back to home
          //Check this lit ass page checkin'
          if (this.navService.getCurrentPageName() == PageName.PresentationMeetingComponent) {
            if (!this.navCtrl) {
              this.navCtrl = this.injector.get(IonNav)
            }
            if (await this.navCtrl.canGoBack) {
              this.nativePageTrans.flip(leftFlipNavTransOption);
              (pop ? this.navService.popWithPageTracking() : undefined);
            }
          }
        } else if (res.role == "cancel") {
          console.log('Cancel clicked');
          (pop ? this.navService.popWithPageTracking() : undefined);
        }
      });

    });
  }

  /**
   * Handle Filp button click,
   *
   * @memberof FooterToolbarComponent
   */
  private async _handleFlipButton() {
    //Upload events?
    // this.meetingDataService.uploadInteractiveHTMLEvent();
    //stop the recorder if recording was on
    if(this.pollService.isUnsavedPoll){
      const savePolls = await this.alertService.showAlert({
        title: this.translate.instant('POP_POLL_NOT_SAVED'),
        message:this.translate.instant('POP_R_U_SURE_NOT_SAVE_POLL'),
        backdropDismiss: false
      },"Yes", "No");
      if(savePolls.role !== "ok") {
        return;
      }else{
        this.pollService.clearSavedPolls();
      }
    }


    if (!this.activityService.isThereConnectedContactsForCurrentMeeting(this.activityService.selectedActivity as AppointmentActivity, true)
      && !this.websocket.guestsAreConnected) {
      // There's no one connected remotely.
      //OMNI-10795 Blank video thumbnail displayed.
      this.geneeCallNotesCheck();
      this._endRemoteMeeting();
      this.meetingDataService.updateContactJoinDetailsLeftDate(this.activityService.selectedActivity);
      this.updateCRMMeetingData();
      await this.updateStartTimeForSelectedActivity();
      this._popToHome();
    } else {
      // There's at least one person connected remotely.

      this.alertService.showAlert({
        title: this.translate.instant('LEAVE_MEETING'),
        message: this.translate.instant('THERE_R_REMOTE_CONNECTED_MEETING', { globalCustomerText: this.utilityService.globalCustomerText })
      },
        this.translate.instant('DISCONNECT'), this.translate.instant('KEEP')
      ).then(async res => {
        if (res.role == "ok") {

          //OMNI-10795 Blank video thumbnail displayed.
          this.geneeCallNotesCheck();
          this._endRemoteMeeting();
          this.updateCRMMeetingData();
          this.meetingDataService.updateContactJoinDetailsLeftDate(this.activityService.selectedActivity);
          await this.updateStartTimeForSelectedActivity();
          this._popToHome();

        } else if (res.role == "cancel") {
          this.geneeCallNotesCheck();
          await this.updateStartTimeForSelectedActivity();
          this._popToHome();
          // this.websocket.meetingInProgress = true;
        }
      });
    }
  }

  private geneeCallNotesCheck() {
    if (this.speechService.isRecording && this.authService.hasFeatureAction(FeatureActionsMap.GENIE_CALL_NOTES)) {
      this.speechService.stopRecording();
      this.speechService.getEntityAndIntentMatches(this.activityService.selectedActivity.ID, 'fromMeeting');
    }
  }

  /**
   * Handle VoIP button click,
   *
   * @memberof FooterToolbarComponent
   */
  async _handleVoipButton(audioStarted: boolean) {
    if (audioStarted) {
      this.footerService.hideButton('stop_audio');
      this.footerService.showButton('audioactive');
    }
    else {
      this.footerService.hideButton('audioactive');
      this.footerService.showButton('stop_audio');
    }
    this.disableVoipIcons();
    // if (window.innerWidth > 500) {
      this.footerService.displayFullScreenButtons();
    // }
    try {
    }
    catch (error) {
      this.log.logError('error is toggling audio', error);
    }
    this.enableVoipIcons();
    if (audioStarted) {
      //enable genie speech detection if feature enabled
      if (this.authService.hasFeatureAction(FeatureActionsMap.GENIE_CALL_NOTES) && !this.device.isOffline) {
        this.speechService.initRecognizer();
      }
    }
    else {
      //stop the recorder when mic is muted
      if (this.speechService.isRecording) {
        if (this.authService.hasFeatureAction(FeatureActionsMap.GENIE_CALL_NOTES)) {
          this.speechService.getEntityAndIntentMatches(this.activityService.selectedActivity.ID, 'fromMeeting');
        }
        this.speechService.stopRecording();
      }
    }
  }

  /**
   * Handle Video button click,
   *
   * @memberof FooterToolbarComponent
   */
  async _handleVideoButton(videoStarted: boolean) {
    // this.footerService.enableButtons(['homepage'], !(this.websocket.meetingInProgress || started));
    if (!videoStarted) {
      this.footerService.hideButton('stop_video_active');
      this.footerService.showButton('video');
    }
    else {
      this.footerService.hideButton('video');
      this.footerService.showButton('stop_video_active');
    }
    this.disableVoipIcons();
    // if (window.innerWidth > 500) {
      this.footerService.displayFullScreenButtons();
    // }
    this.enableVoipIcons();
  }

  disableVoipIcons() {
    let voipIcons: string[] = ['video', 'stop_video_active', 'audioactive', 'stop_audio', 'play', 'pause'];
    const disabledBtns: any[] = this.footerService.actionButtons.filter(btn => !btn.isactive);
    if (disabledBtns && disabledBtns.length > 0) {
      voipIcons.push(...disabledBtns.map(btn => btn.id));
    }
    this.footerService.disableButton(voipIcons);
    this.events.publish('disableVoipIconsFlag', true);
  }
  enableVoipIcons() {
    this.footerService.enableButtons(['video', 'stop_video_active', 'audioactive', 'stop_audio', 'play', 'pause']);
    this.events.publish('disableVoipIconsFlag', false);
  }
  hideAllVoipIcons() {
    this.footerService.hideButton('audioactive');
    this.footerService.hideButton('video');
    this.footerService.hideButton('stop_audio');
    this.footerService.hideButton('stop_video_active');
  }

  private async updateCRMMeetingData() {
    if (this.activityService.selectedActivity instanceof AppointmentActivity) {
      if (!this.device.isOffline && this.device.isNativeApp && !this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
        try {
          this.deltaService.startSyncInfoRecording(true, false);
          const syncDebugInfo: EntitySyncInfo = {
            entityName: EntityNames.syncDebug,
            totalFailed: 0,
            totalSynced: 0,
            errors: [],
            syncStatus: true
          };
          try {
            await this.activityDataService.uploadOfflineMeetingsTimeOffsAllocationsEmailsFollowupsConsents(syncDebugInfo);
          } catch (error) {
            this.deltaService.addSyncErrorToEntitySyncInfo(
              syncDebugInfo,
              '',
              {
                debug: {
                  error,
                  description: 'DEBUG: footer-toolbar: updateCRMMeetingData',
                }
              }
            );
          } finally {
            this.deltaService.addEntitySyncInfo(syncDebugInfo);
            this.deltaService.endSyncInfoRecording(true);
          }

        } catch (err) {
          console.error("updateCRMMeetingData : ", err);
        }
      }
    }
  }
  /**
   * Handle Pause button click,
   *
   * @memberof FooterToolbarComponent
   */
  private async _handlePauseButton() {
    this.trackingService.tracking('PauseButtonClicked', TrackingEventNames.DIGITAL)

    // Update the last slide end time before pause
    if (this.activityService.selectedActivity) {
      await this.websocket.updateLastSlideEndtime(this.activityService.selectedActivity);
      this.websocket.updateLastResourcePageEndtime(this.activityService.selectedActivity);
      if (this.activityService.selectedActivity instanceof AppointmentActivity && (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID))) {
        this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
      }
    }
    this.websocket.meetingInProgress = false;
    this.footerService.hideButton('pause');
    this.footerService.showButton('play');
    this.footerService.hideButton('homepage_disabled');
    this.footerService.showButton('homepage');
    this.footerService.addOrRemoveOnFAB(true, 'homepage_disabled');
    this.footerService.addOrRemoveOnFAB(false, 'homepage');
    // if (this.videoCallState.state == VideoCallStates.noCall)
    this.footerService.enableButtons(['homepage']);

    //stop the recorder if recording was on
    if (this.speechService.isRecording) {
      this.speechService.stopRecording();
    }
  }

  /**
   * Ends the remote meeting, remove subscription,
   * pop to home.
   *
   * @memberof FooterToolbarComponent
   */
  private _endRemoteMeeting() {
    //Send shut down signal via WS
    if (this.activityService.selectedActivity) {
      this.websocket.sendRemoteMeetingEnd(this.activityService.selectedActivity, true);
    } else {
      console.warn(`_endRemoteMeeting: There's no selected Activity`);
    }

    // Change status back to the previous state.
    if (!this.device.isOffline) {
      const newStateData = { userState: this.repService.getPreviousUserState() };
      if (!this.device.isNativeApp) {
        this.repService.setCurrentState(newStateData);
      }
    }
  }

  async createOrEditSampleOrderFromMeeting() {
    if (this.activityService.samplingToInMeetingActivityMapping.has(this.activityService.selectedActivity.ID)) {
      this.sampleService.samplingDetailsViewMode = SamplingDetailsViewMode.CREATE_FROM_MEETING;
      let activityID = this.activityService.selectedActivity.ID
      this.sampleService.inMeetingAllocationActivity = <SampleActivity>this.activityService.getActivityByID(this.activityService.samplingToInMeetingActivityMapping.get(activityID))
      this.activityService.activityDetailsLoaded = true;
      this.uiService.showNewActivity = false;
      let modal = await this.modalCtrl.create({ component: InMeetingAllocationComponent, componentProps: { type: ActivityType.Sample }, backdropDismiss: false, cssClass: 'inMeetingAllocation' })
      modal.present();
      modal.onDidDismiss().then((data?: any) => {
        this.sampleService.samplingDetailsViewMode = undefined;
        if (this.uiService.activeView == 'Appointment') {
          this.footerService.initButtons(FooterViews.Activities)
        }
        if (this.uiService.activeView == 'Meeting') {
          this.footerService.initButtons(FooterViews.PreviewMeeting)
        }
        if (this.uiService.activeView == 'activityTimelineDetails') {
          this.footerService.initButtons(FooterViews.Activities);
        }
        if (data && data.isCompleted) {
          this.activityService.samplingToInMeetingActivityMapping.delete(activityID)
        }
      })
      return;
    }
    //let loader = this.loadingController.create({});
    let start: any = new Date(), end, orderDate: string, orderTime: string, contactID: string, addressID;
    start = format(new Date());
    //start = format(Utility.getRoundedDownTime(new Date()));
    end = format(addMinutes(start, 30));
    orderTime = new Date(start).toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    let subject: string = 'Allocation Order';
    if (this.activityService.selectedActivity instanceof AppointmentActivity) {
      contactID = this.activityService.selectedActivity.contacts.length == 1 ?
        this.activityService.selectedActivity.contacts[0].ID : '';

      let thisContactHasAllocation = this.sampleService.contactCustomerSampleAllocations.some((o) => {
        if (o.contactId == contactID) {
          subject = 'Allocation Order with ' + o.contactName;
          return true;
        } else {
          return false;
        }
      })
      contactID = thisContactHasAllocation ? contactID : '';
      if (contactID) {
        let contact = this.contactService.getContactByID(contactID);
        if (contact.addressesList && contact.addressesList.length == 1) {
          addressID = contact.addressesList[0].customerAddressID;
        }
        else addressID = '';
      }
    }
    if (start) {
      let orderStartTime: any = new Date(start);
      let orderEndTime: any = new Date(end);
      let sampleDropPayload: CreateSampleDropRequestBody = new CreateSampleDropRequestBody({
        scheduledStart: orderStartTime,
        scheduledEnd: orderEndTime,
        contactID: contactID,
        appointmentID: this.activityService.selectedActivity.ID,
        offlineActivityId: 'offlineSampleOrder_' + new Date().getTime(),
        subject: subject,
        addressID: addressID
      });
      sampleDropPayload.indskr_ownerid = this.authService.user.systemUserID;
      let loader = await this.loadingController.create({})
      loader.present();
      let response = await this.sampleDataService.initiateSampleOrder(sampleDropPayload);
      if (response) {
        //this.activityService.selectedActivity = response
        let activity: SampleActivity = <SampleActivity>response;
        if (contactID) {
          activity.contactName = (this.activityService.selectedActivity as AppointmentActivity).contacts[0].fullName;
        }
        this.activityService.addActivity(activity);
        this.sampleService.samplingDetailsViewMode = SamplingDetailsViewMode.CREATE_FROM_MEETING;
        this.sampleService.inMeetingAllocationActivity = activity
        loader.dismiss();
        this.activityService.activityDetailsLoaded = true;
        if (!this.uiService.toolsActivityActive) {
          this.events.publish('refreshAgenda');
        } else this.uiService.agendaRefreshRequired = true;
        this.uiService.showNewActivity = false;
        //if(data && data.isCompleted){
        this.activityService.samplingToInMeetingActivityMapping.set(this.activityService.selectedActivity.ID, activity.ID)
        //}
        let modal = await this.modalCtrl.create({ component: InMeetingAllocationComponent, componentProps: { type: ActivityType.Sample }, backdropDismiss: false, cssClass: 'inMeetingAllocation' })
        modal.present();
        modal.onDidDismiss().then((data: any) => {
          this.sampleService.samplingDetailsViewMode = undefined;
          if (this.uiService.activeView == 'Appointment') {
            this.footerService.initButtons(FooterViews.Activities)
          }
          if (this.uiService.activeView == 'Meeting' || this.navService.getCurrentPageName() == PageName.PresentationMeetingComponent) {
            this.footerService.initButtons(FooterViews.PreviewMeeting)
          }
          if (this.uiService.activeView == 'activityTimelineDetails') {
            this.footerService.initButtons(FooterViews.Activities);
          }
          if (data && data.isCompleted) {
            this.activityService.samplingToInMeetingActivityMapping.delete(this.activityService.selectedActivity.ID)
          }
        })
      }
    }
  }

  async scrapInMeetingSampleDrop() {
    let activity = this.sampleService.inMeetingAllocationActivity;

    this.alertService.showAlert({
      title: this.translate.instant('SCRAP_ALLOCATION_ORDER'),
      message: this.translate.instant('POP_R_U_SURE_SCRAP_ALLOC_O')
    }, this.translate.instant('SCRAP')
    ).then(res => {
      if (res.role == "ok") {
        if (activity.type == ActivityType.Sample) {
          let payload: CreateSampleDropRequestBody = new CreateSampleDropRequestBody(activity);
          payload.statecode = 2;
          payload.statuscode = 3;
          (activity as SampleActivity).state = 2;
          (activity as SampleActivity).status = 3;
          this.sampleDataService.updateSampleActivity(payload, activity).then(() => {
            if (!this.uiService.toolsActivityActive) {
              this.events.publish('refreshAgenda');
            } else this.uiService.agendaRefreshRequired = true;
            this.sampleService.samplingDetailsViewMode = undefined;
            this.sampleService.inMeetingAllocationActivity = undefined;
            this.activityService.samplingToInMeetingActivityMapping.delete(this.activityService.selectedActivity.ID);
            this.cancelAllocation.emit();
            return;
          }).catch(() => {
            // this.notificationService.notify("Allocation scrap failed",'Footer Toolbar','top',ToastStyle.DANGER);
            return;
          })
        }
      }
    });
  }

  /**
   * Pop to home. (Activity pane);
   *
   * @memberof FooterToolbarComponent
   */
  private async _popToHome() {
    if (!this.navCtrl) {
      this.navCtrl = this.injector.get(IonNav)
    }

    if (this.navCtrl.canGoBack()) {
      // this.nativePageTrans.flip(leftFlipNavTransOption);
      this.footerService.initButtons(FooterViews.Activities);
      if (this.navService.getPreviousPageName() == PageName.PresentationPageComponent) {
        if (this.device.isMobileDevicePortrait) {
          if (this.agendaFooterService.actSegment === 'agenda' && (this.agendaFooterService.planTabSegment === 'planTabTodo' || this.agendaFooterService.planTabSegment === 'goalsPlansTab')) {
            this.uiService.showRightPane = false
          }
        }
        this.navService.popToRootWithPageTracking().then(() => {
          this.uiService.setAgendaTab('agenda');
          this.uiService.activitiesPageTab = 'agenda';
          if (this.activityService.selectedActivity) {
            this.events.publish('activity-pane:selectActivity', this.activityService.selectedActivity);
          }
        })
      } else {
        if (this.uiService.showNewActivity) {
          this.uiService.showNewActivity = false;
          await this.navService.popChildNavPageWithPageTracking();
        }
        await this.navService.popWithPageTracking();
      }
      if (this.navService.getCurrentPageName() == PageName.ContactPageComponent) {
        this.uiService.activeView = 'ContactPageRightPaneNav';
        this.footerService.initButtons(FooterViews.Activities);
      }
      else if (this.navService.getCurrentPageName() == PageName.CallPlanComponent) {
        this.uiService.activeView = 'Appointment';
        this.callPlanService.callPlanPageView = 'CallPlanNewMeetingDetails';
        this.footerService.initButtons(FooterViews.Activities);
      } else if (this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab == "agenda" && this.navService.getActiveChildNavViewPageName() != PageName.ActivitiesDetailsPaneComponent) {
        this.navService.setChildNavRoot(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ActivitiesPageComponent);
      } else if(this.navService.getCurrentPageName() === PageName.AccountPageComponent){
        this.uiService.activeView = 'AccountPageRightPaneNav';
        // this.footerService.initButtons(FooterViews.Accounts);
      } else {
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        this.events.publish('activity-detail:fieldRefreshRequired');
      }
      this.presentationService.selectedActivityId = '';
      this.events.publish('initActivityKeyMessages'); // To detect changes on a automatically mapped product key messages when flipped back to meeting details
    } else {
      console.warn(`_popToHome: Not able to go back.`);
    }
    this.events.publish('updateMandatoryField', PageName.PresentationMeetingComponent);
  }

  private _navPush(page: any, params: any = {}) {
    if (this.navService.getCurrentPageName() == page) {
      this.notificationService.notify(this.translate.instant('CURRENT_ACTIVE_PAGE'), 'Footer Toolbar', 'top', ToastStyle.DANGER);
      return;
    }

    // this.nativePageTrans.flip(this.transOptions);
    if (!this.navCtrl) {
      this.navCtrl = this.injector.get(IonNav)
    }
    this.navCtrl.push(page, params);
  }

  shouldShowBadge(button: any): boolean {
    if (button && button.isBadge) {
      if (this.getBadgeData(button) > 0) {
        return true;
      }
    }
    return false;
  }

  getBadgeData(buttonId): number {
    switch (buttonId.id) {
      case 'contactsMessage':
      case 'contactsphonecall':
      case 'contactspresentationpreview':
      case 'contacts':
        if (this.emailService.viewType != EmailViewType.EMAIL_FROM_MEETING_PRESENTATION && this.emailService.selectedActivity && this.emailService.selectedActivity.emailActivityParties) {
          return this.emailService.selectedActivity.emailActivityParties.length;
        }
        return (this.activityService.selectedActivity && this.activityService.selectedActivity['contacts']) ? this.activityService.selectedActivity['contacts'].length : 0;
      case 'presentationsrightalligned':
      case 'presentationsleftalligned':
      case 'presentations':
        if (this.activityService.selectedActivity instanceof AppointmentActivity) {
          return (this.activityService.selectedActivity.presentations ? this.activityService.selectedActivity.presentations.length : 0);
        }
      case 'geenie':
        return this.getCountUnreadNotifications();
      case 'contactsAllocation':
        if (this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_MEETING || 
          this.sampleService.samplingDetailsViewMode === SamplingDetailsViewMode.CREATE_FROM_EVENTS) {
          return (this.sampleService.inMeetingAllocationActivity
            && this.sampleService.inMeetingAllocationActivity.contactID) ? 1 : 0;
        }
        else {
          return (this.activityService.selectedActivity
            && this.activityService.selectedActivity.type == ActivityType.Sample
            && (this.activityService.selectedActivity as SampleActivity).contactID) ? 1 : 0;
        }
      case 'samplingeligibilities':
        if (this.sampleService.samplingDetailsViewMode == SamplingDetailsViewMode.CREATE_FROM_MEETING) {
          return (this.sampleService.inMeetingAllocationActivity
            && this.sampleService.inMeetingAllocationActivity.samples) ? this.calculateValidSampleDropsNumber(this.sampleService.inMeetingAllocationActivity.samples) : 0;
        }
        else {
          return (this.activityService.selectedActivity
            && this.activityService.selectedActivity.type == ActivityType.Sample
            && (this.activityService.selectedActivity as SampleActivity).samples) ? this.calculateValidSampleDropsNumber((this.activityService.selectedActivity as SampleActivity).samples) : 0;
        }
      case 'attachment': {
        if (this.emailService.selectedActivity && this.emailService.selectedActivity.emailAttachments) {
          return this.emailService.selectedActivity.emailAttachments.length;
        }
        else {
          return;
        }
      }

      default:
        break;
    }
  }

  private calculateValidSampleDropsNumber(samples: Array<SampleDetailInSampleActivity>): number {
    let num = 0;
    samples.forEach(sample => {
      if (!sample['deleted']) {
        num++;
      }
    })
    return num;
  }

  getCountUnreadNotifications() {
    return this.assistantService.countUnreadNotifications
  }

  updateSelectedActivityStartTime() {
    if (this.activityService.selectedActivity instanceof AppointmentActivity && (this.activityService.selectedActivity.activityPresentations || this.activityService.selectedActivity.presentations)) {
      // if(this.activityService.selectedActivity.activityPresentations.length>0) return;
      if (this.activityService.selectedActivity.isMeetingPresentationDetailed) return;
      let dateTimeNow = new Date();
      let diff = moment.duration(moment(this.activityService.selectedActivity.scheduledEnd).diff(moment(this.activityService.selectedActivity.scheduledStart))).minutes();
      // stored current start date and end date before updating the date with the presentation
      this.activityService.selectedActivity.prevScheduledStart = new Date(this.activityService.selectedActivity.scheduledStart);
      this.activityService.selectedActivity.prevScheduledEnd = new Date(this.activityService.selectedActivity.scheduledEnd);
      this.activityService.selectedActivity.scheduledStart = dateTimeNow;
      let meetingDuration = this.authService.user.meeting_duration || 30;
      //OMNI-3482: For meetings created through schedular, duration should be as per configuration
      let duration = this.activityService.selectedActivity.indskr_dnascheduler ? diff : meetingDuration;
      this.activityService.selectedActivity.scheduledEnd = new Date(format(addMinutes(dateTimeNow, duration)));
      this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledStart = dateTimeNow;
      this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledEnd = new Date(format(addMinutes(dateTimeNow, duration)));
      this.activityService.selectedActivity.resetScheduledStartLocale();
      // update limit min time for end-time picker due to updated start time.
      let dateTime = {startDateTime: this.activityService.selectedActivity.scheduledStart, endDateTime: this.activityService.selectedActivity.scheduledEnd};
      this.dateTimeFormatsService.updateLimitedMinTime.next(dateTime);
      this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
      this.refreshTODO();
    }
  }

  private refreshTODO() {
    if (this.agendaFooterService.actSegment === 'agenda' && this.agendaFooterService.planTabSegment === 'planTabTodo') {
      this.events.publish('aciviiyCreatedRefreshTODO', this.activityService.selectedActivity);
    }
  }

  async updateStartTimeForSelectedActivity() {
    if (this.activityService.selectedActivity instanceof AppointmentActivity) {


      if (this.device.isOffline || this.activityService.hasOfflineMeetingData(this.activityService.selectedActivity.ID)) {
        await this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity);
        if (!this.uiService.toolsActivityActive) {
          this.events.publish('refreshAgenda');
        } else this.uiService.agendaRefreshRequired = true;
        return;
      }
      let currentAppointmentActivity: AppointmentActivity = this.activityService.selectedActivity;
      let doNotUpdateApointmentTime = this.activityService.selectedActivity.isMeetingPresentationDetailed;

      if (this.authService.user.buSettings['indskr_showmeetingtimeascontentplaytime']) {
        const scheduleTime = this.activityService.getLastSlideTime();
        doNotUpdateApointmentTime = (new Date(scheduleTime.endTime).getTime() === currentAppointmentActivity.scheduledEnd.getTime() &&
          new Date(scheduleTime.startTime).getTime() === currentAppointmentActivity.scheduledStart.getTime()) ? doNotUpdateApointmentTime : false;
        if (!doNotUpdateApointmentTime) {
          currentAppointmentActivity.scheduledEnd = new Date(scheduleTime.endTime);
          currentAppointmentActivity.scheduledStart = new Date(scheduleTime.startTime);
        }
      }

      if (doNotUpdateApointmentTime) return;

      let payload = new UpdateMeetingPayload(
        currentAppointmentActivity.subject,
        currentAppointmentActivity.location,
        currentAppointmentActivity.scheduledStart,
        currentAppointmentActivity.scheduledEnd,
        currentAppointmentActivity.notes
      );
      this.meetingDataService.isErrorStartDateUpdateMeeting = false;
      await this.meetingDataService.updateMeeting(this.activityService.selectedActivity, payload, false, false, false, false, true)
        .catch((error) => {
          console.error('updateStartTimeForSelectedActivity: updateMeeting: ', error);
          this.activityService.upsertMeetingsOfflineData(this.activityService.selectedActivity as AppointmentActivity, false, true);
        })
        .finally(() => {
          if (this.activityService.selectedActivity instanceof AppointmentActivity && this.meetingDataService.isErrorStartDateUpdateMeeting) {
            this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledStart = new Date(this.activityService.selectedActivity.prevScheduledStart);
            this.activityService.getActivityByID(this.activityService.selectedActivity.ID).scheduledEnd = new Date(this.activityService.selectedActivity.prevScheduledEnd);
            this.activityService.selectedActivity.resetScheduledStartLocale();
            // update limit min time for end-time picker due to updated start time.
            let dateTime = {startDateTime: this.activityService.selectedActivity.scheduledStart, endDateTime: this.activityService.selectedActivity.scheduledEnd};
            this.dateTimeFormatsService.updateLimitedMinTime.next(dateTime);
          }
          if (!this.uiService.toolsActivityActive) {
            this.events.publish('refreshAgenda');
          } else this.uiService.agendaRefreshRequired = true;
            this.events.publish("updateMeetingDateFields");
        });
    }
  }

  private handleFollowUpActivityCompletion(): void {
    if ((this.activityService.selectedActivity as FollowUpActivity).planType == '' ||
      (this.activityService.selectedActivity as FollowUpActivity).planType == FOLLOW_UP_TYPE.ACCOUNT_PLAN) {
      this.trackingService.tracking('AccountPlanFollowupActionCompleteActionBar', TrackingEventNames.ACCOUNTPLANNING)
    } else if ((this.activityService.selectedActivity as FollowUpActivity).planType == FOLLOW_UP_TYPE.SCIENTIFIC_PLAN) {
      this.trackingService.tracking('ScientificPlanFollowupTaskCompleteActionBar', TrackingEventNames.SCIENTIFICACTIVITYPLAN)
    }
    if (() => {
      if (((this.activityService.selectedActivity as FollowUpActivity).ownerId == this.authService.user.systemUserID) || ((this.activityService.selectedActivity as FollowUpActivity).assignedTo.some(u => u.userId == this.authService.user.systemUserID))) {
        if (((this.activityService.selectedActivity as FollowUpActivity).state == 0 && this.activityService.selectedActivity as FollowUpActivity).subject && (this.activityService.selectedActivity as FollowUpActivity).contactId && ((this.activityService.selectedActivity as FollowUpActivity).planType === FOLLOW_UP_TYPE.NONE || (this.activityService.selectedActivity as FollowUpActivity).accountId)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }) {
      if ((<FollowUpActivity>this.activityService.selectedActivity).state == 0) {

        this.alertService.showAlert({
          title: this.translate.instant('FOLLOW_UP_ALERT_TITLE_COMPLETE_FOLLOW_UP'),
          message: this.translate.instant('FOLLOW_UP_ALERT_MESSGE_COMPLETE_FOLLOW_UP')
        }, this.translate.instant('COMPLETE')
        ).then(res => {
          if (res.role == "ok") {
            this.uiService.displayLoader();
            this.followUpDataService.markFollowUpActivityComplete({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true }, (this.activityService.selectedActivity as FollowUpActivity)).then(succ => {
              (this.activityService.selectedActivity as FollowUpActivity).state = 1;
              (this.activityService.selectedActivity as FollowUpActivity).status = 5;
              (this.activityService.selectedActivity as FollowUpActivity).statusString = 'Completed';
              this.footerService.disableButton(['markfollowupcomplete', 'scrapfollowup', 'markfollowupcancel']);
              this.events.publish("updateFollowupActivityHeader");
              if (this.navService.getCurrentMasterPageName() == PageName.AccountManagementPageComponent) {
                this.navService.popChildNavPageWithPageTracking().then(() => {
                  this.footerService.initButtons(FooterViews.None);
                });
              } else if (this.navService.getCurrentMasterPageName() == PageName.ContactPageComponent) {
                this.uiService.activeView = 'contactDetails';
                this.footerService.initButtons(FooterViews.Contacts);
                this.navService.popChildNavPageWithPageTracking();
              } else if (this.navService.getCurrentMasterPageName() == PageName.AccountPageComponent) {
                this.uiService.activeView = 'accountDetails';
                this.footerService.initButtons(FooterViews.Accounts);
                this.navService.popChildNavPageWithPageTracking();
              } else if (this.navService.getCurrentMasterPageName() == PageName.AccountPageComponent) {
                /**
                 * Handle Scientific plan
                 */
                this.uiService.activeView = 'ScientifcDetails';
                this.footerService.initButtons("");
                this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ScientificActivityPage);
              }
              this.events.publish('followupactioncompleted', this.activityService.selectedActivity);
              this.uiService.dismissLoader();
            }).catch(error => {
              if (error && error['errorCode'] && error['errorCode'] == 'ALREADYSCRAPPEDACTIVITY') {
                //Check from where activity is deleted
                this.closeFollowUpDetailPageAfterOperation();
                this.events.publish('followupActionDeleted', this.activityService.selectedActivity);

              } else {
                this.footerService.enableButtons(['markfollowupcomplete', 'scrapfollowup','markfollowupcancel']);
              }
              this.uiService.dismissLoader();
            })
          }
        });
      }
    }
  }

  private handleFollowUpActivityDeletion(): void {
    if ((this.activityService.selectedActivity as FollowUpActivity).planType == '' ||
      (this.activityService.selectedActivity as FollowUpActivity).planType == FOLLOW_UP_TYPE.ACCOUNT_PLAN) {
      this.trackingService.tracking('AccountPlanFollowupActionScrapActionBar', TrackingEventNames.ACCOUNTPLANNING)
    } else if ((this.activityService.selectedActivity as FollowUpActivity).planType == FOLLOW_UP_TYPE.SCIENTIFIC_PLAN) {
      this.trackingService.tracking('ScientificPlanFollowupTaskScrapActionBar', TrackingEventNames.SCIENTIFICACTIVITYPLAN)
    }

    if(this.followUpDataService.inMeetingFollowupActionActivity){
      this.events.publish(EventName.INMEETINGFOLLOWUPACTIONSCRAP);
    }else if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.FollowUp) {
      if ((<FollowUpActivity>this.activityService.selectedActivity).state == 0 && (<FollowUpActivity>this.activityService.selectedActivity).ownerId == this.authService.user.systemUserID) {
        this.alertService.showAlert({
          title: this.translate.instant('SCRAP_FOLLOW_UP'),
          message: this.translate.instant('POP_R_U_SURE_SCRAP_FOLLOW_UP')
        }, this.translate.instant('SCRAP')
        ).then(res => {
          if (res.role == "ok") {
            (<FollowUpActivity>this.activityService.selectedActivity).pendingPushToDynamics = true;
            this.uiService.displayLoader();
            this.followUpDataService.scrapFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true }, (<FollowUpActivity>this.activityService.selectedActivity)).then(async succ => {
              //Check from where activity is deleted
              this.events.publish('followupActionDeleted', this.activityService.selectedActivity);
              this.closeFollowUpDetailPageAfterOperation();
              this.events.publish("refreshFollowup");
              this.uiService.dismissLoader();
            }).catch(err => {
              //Handle error scenario
              this.uiService.dismissLoader();
            });
          }
        });
      }
    }
  }

  private closeFollowUpDetailPageAfterOperation(): void {
    let activChildNav: ChildNavNames = this.navService.getActiveChildNavName();
    switch (activChildNav) {
      case ChildNavNames.ActivitiesPageNavigation:
        this.activityService.publishActivityEvent({action: "Delete", activity: this.activityService.selectedActivity});
        this.activityService.selectedActivity = null;
        if (this.footerService.isActivitiesButton) {
          this.uiService.activeView = '';
          this.uiService.showRightPane = false;
          if (this.uiService.activitiesPageTab === 'day') {
            this.events.publish('activityDeleted', this.activityService.selectedActivity);
          }
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        } else {
          this.navService.popChildNavPageWithPageTracking();
          this.events.publish("go-back-to-xperiences", PageName.FollowUpActionDetailComponent);
        }
        break;
      case ChildNavNames.AccountManagementNavigation:
        this.navService.popChildNavPageWithPageTracking();
        if (this.navService.getActiveChildNavViewPageName() == PageName.NewActivityComponent) {
          this.navService.popChildNavPageWithPageTracking();
        }
        this.activityService.selectedActivity = null;
        this.footerService.initButtons(FooterViews.AccountPlanObjectives)
        break;
      case ChildNavNames.OpportunityManagementNavigation:
        this.navService.popChildNavPageWithPageTracking();
        this.activityService.selectedActivity = null;
        this.footerService.initButtons('OpportunityInfoComponent')
        break;
      case ChildNavNames.ContactPageNavigation:
        this.navService.popChildNavPageWithPageTracking();
        this.footerService.initButtons(FooterViews.Contacts)
        break;
      case ChildNavNames.AccountPageNavigation:
        this.navService.popChildNavPageWithPageTracking();
        this.footerService.initButtons(FooterViews.Accounts)
        break;
      case ChildNavNames.ScientificActivityNavigation:
        this.navService.popChildNavPageWithPageTracking();
        this.footerService.initButtons('');
        break;
      case ChildNavNames.MarketingPlanNavigation:
        this.navService.popChildNavPageWithPageTracking();
        this.footerService.initButtons('');
        break;
    }
    if (!this.uiService.toolsActivityActive) {
      this.events.publish('refreshAgenda');
    } else this.uiService.agendaRefreshRequired = true;
  }

  private async createCustomerInquiryForMeeting(source: AccesingMode) {
    let selectedActivity = this.activityService.selectedActivity;

    let meetingCases: CaseActivity[] = this.caseManagementService.findCasesById(selectedActivity.ID);

    if (!meetingCases || !meetingCases.length) {
      meetingCases = this.caseManagementService.findCasesById(this.activityService.selectedActivity['offlineMeetingId']);
    }

    let canCreateNewMeeting: boolean = meetingCases.every(e => String(e._case_status_value).toLowerCase() != 'open');

    if (source === AccesingMode.PRESENTATION) {
      this.trackingService.tracking('InquiryActivityFromMeetingPresentation', TrackingEventNames.CUSTOMER_INQUIRY);
    }
    else if (source === AccesingMode.INMEETING) {
      this.trackingService.tracking('InquiryActivityFromMeeting', TrackingEventNames.CUSTOMER_INQUIRY);
    }
    /* selected appointment has inquiries mapped */
    if (meetingCases.length) {
      if (selectedActivity.isCompleted) {

        /* For completed meeting just allow user to open existing case */
        let iCase: CaseActivity = meetingCases.find(e => String(e._case_status_value).toLowerCase() === 'open');
        if (!iCase) {
          iCase = meetingCases[0];

          if (!this.device.isOffline && !iCase.ID.includes('offline')) {
            this.fetchLatestCaseDetails(iCase).then(v => {
              this.openCaseDetailsView(v, source);
            });
          }
          else {
            this.openCaseDetailsView(iCase, source);
          }
        }
        else if (!this.device.isOffline && !iCase.ID.includes('offline')) {
          this.fetchLatestCaseDetails(iCase).then(v => {
            this.openCaseDetailsView(v, source);
          });
        }
        else {
          this.openCaseDetailsView(iCase, source);
        }
      }
      else {
        /*
          meeting cases exists, but none in draft state. User can create a new
          case
        */
        if (canCreateNewMeeting) {
          this.createNewCustomerInquiry(source);
        }
        else {
          //some case in draft stage, open the draft case.
          let iCase: CaseActivity = meetingCases.find(e => String(e._case_status_value).toLowerCase() === 'open');
          if (iCase) {
            this.openCaseDetailsView(iCase, source);
          }
          else {
            console.log("something bad happened while searching case");
            return;
          }
        }
      }
    }
    else {
      if (selectedActivity.isCompleted) {
        return;
      }
      else
        //No case for the selected meeting, create a new case for the user.
        this.createNewCustomerInquiry(source);
    }
  }


  async fetchLatestCaseDetails(iCase: CaseActivity) {
    return this.caseManagementDataService.fetchCaseById(iCase.ID).then(
      async res => {
        iCase._case_stage_value = res['_indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'] ? res['_indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'] : iCase._case_stage_value;

        iCase._case_status_value = res['statuscode@OData.Community.Display.V1.FormattedValue'] ? res['statuscode@OData.Community.Display.V1.FormattedValue'] : iCase._case_status_value;

        iCase._case_status_id = res['statuscode'] ? res['statuscode'] : iCase['statuscode'];

        iCase._case_assignee = res['_ownerid_value@OData.Community.Display.V1.FormattedValue'] ? res['_ownerid_value@OData.Community.Display.V1.FormattedValue'] : iCase._case_assignee;
        iCase._case_timeline = res['inquiryTimeline'] ? iCase.getTimeline(res['inquiryTimeline']) : iCase._case_timeline ? iCase._case_timeline : [];
        iCase.state = res['statecode'] ? res['statecode'] : iCase.state;
        iCase._case_overriddencreatedon = res['overriddencreatedon'] ? new Date(Number(res['overriddencreatedon'])) : res['overriddencreatedon'];
        iCase._indskr_intakecreated = res['indskr_intakecreated'] ? new Date(Number(res['indskr_intakecreated'])) : res['indskr_intakecreated'];
        this.caseManagementService.assignSelectedCase(iCase);
        await this.caseManagementService.upsertMyCases(iCase);
        await this.caseManagementService.updateCaseInPouch(iCase);
        return iCase;
      })
      .catch(err => {
        return iCase;
      });
  }

  createNewCustomerInquiry(source: AccesingMode) {
    this.caseManagementService.accessedFrom = source;
    this.modalCtrl.create({
      component: InMeetingCasesComponent, componentProps: {
        type: ActivityType.CaseIntake,
        view: 'new-case'
      }, backdropDismiss: false, cssClass: 'inMeetingAllocation'
    })
      .then((modal) => {
        modal.present();
        modal.onDidDismiss().then((data) => {
          if (data) {
            /* New Case created */
            this.openCaseDetailsView(data, source);
          }
          else {
            if (source === AccesingMode.PRESENTATION) {
              this.footerService.initButtons(FooterViews.PreviewMeeting);
            }
            else if (source === AccesingMode.INMEETING) {
              this.footerService.initButtons(FooterViews.Activities);
            }
          }
        });
      })
  }

  private handleButtonEventForOrderActivity(buttonId: string) {
    let popupTitle: string = '';
    let popupMessage: string = '';
    let popAction: string = '';
    let popupInputs = [];
    switch (buttonId) {
      case 'scraporderactivity': {
        popupTitle = this.translate.instant('OM_SCRAP_ORDER');
        popupMessage = this.translate.instant('R_U_SURE_SCRAP_O');
        popAction = this.translate.instant('SCRAP');
        break;
      }
      case 'sendorderactivityforreview': {
        if (this.authService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT_AUTO_FULFILL)) {
          popupTitle = this.translate.instant('OM_FULFILL_O');
          popupMessage = this.translate.instant('OM_ARE_SURE_FULFILL_O');
          popAction = this.translate.instant('OM_FULFILL');
          buttonId = 'autoapprovalflow'
        } else if (this.isAutoApprovalForOrdersEnabled) {
          popupTitle = this.translate.instant('OM_SET_O_APPROVED');
          popupMessage = this.translate.instant('OM_ARE_YOU_SURE_O_APPROVED');
          popAction = this.translate.instant('APPROVE');
          buttonId = 'autoapprovalflow'
        } else {
          popupTitle = this.translate.instant('SEND_APPROVAL_QUE');
          popupMessage = this.translate.instant('OM_ARE_YOU_SURE_SEND_FOR_APPRO');
          popAction = this.translate.instant('SEND');
        }
        break;
      }
      case 'notapproveorderactivity': {
        popupTitle = this.translate.instant('OM_NOT_APPRO_O');
        popupMessage = this.translate.instant('OM_ARE_YOU_SURE_NOT_APPRO_O');
        popAction = this.translate.instant('NOT_APPROVE');
        popupInputs = [{type:'text', name:"reason", placeholder:this.translate.instant('ENTER_REASON_FOR_REJECTION')}];
        break;
      }
      case 'approveorderactivity': {
        popupTitle = this.translate.instant('OM_APPROVE_ORDER');
        popupMessage = this.translate.instant('OM_ARE_YOU_SURE_APPRO_O');
        popAction = this.translate.instant('APPROVE');
        break;
      }
      case 'cancelorderactivityreview': {
        popupTitle = this.translate.instant('CANCEL_REVIEW_QUE');
        popupMessage = this.translate.instant('R_U_SURE_WANT_CANCEL_REVIEW');
        break;
      }
      case 'fulfillorderactivity': {
        popupTitle = this.translate.instant('OM_FULFILL_O');
        popupMessage = this.translate.instant('OM_ARE_SURE_FULFILL_O');
        popAction = this.translate.instant('OM_FULFILL');
        break;
      }
      case 'reopenorderactivity': {
        popupTitle = this.translate.instant('OM_RE_OPEN_O');
        popupMessage = this.translate.instant('OM_ARE_YOU_SURE_RE_OPEN_O');
        popAction = this.translate.instant('TIMEOFF_REOPEN');
        break;
      }
      default:
        return;
    }
    if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Order) {

      this.alertService.showAlert({
        title: popupTitle,
        message: popupMessage,
        inputs: popupInputs,
      }, popAction
      ).then(res => {
        if (res.role == "ok") {
          if(buttonId == 'notapproveorderactivity'){
            if(res.data && res.data.values.reason){
              (<OrderActivity>this.activityService.selectedActivity).rejectionReason = res.data.values.reason;
              this.handleButtonEventForOrderActivityAfterConfirmation(buttonId);
            }else{
              this.handleButtonEventForOrderActivity(buttonId);
            }
          }else{
            this.handleButtonEventForOrderActivityAfterConfirmation(buttonId);
          }
        }
      });
    }
  }

  private async handleButtonEventForOrderActivityAfterConfirmation(buttonId: string) {
    let action = {
      onDynamics: !this.device.isOffline,
      onLocalDatabase: this.orderManagementService.ordersListMode == 'my_orders',
      onLocalCopy: this.orderManagementService.ordersListMode == 'my_orders',
      operationDetail: {
        code: buttonId,
        message: this.translate.instant('OM_UPDATE_O_ACTIVITY'),
      }
    };
    (<OrderActivity>this.activityService.selectedActivity).pendingPushToDynamics = true;
    this.uiService.displayLoader();
    await this.orderActivityDataService.updateOrderActivityStatus(action, (<OrderActivity>this.activityService.selectedActivity)).then(async succ => {
      if(this.orderManagementService.ordersListMode != 'my_orders'){
        let idx = this.orderManagementService.teamOrder.findIndex(o=> o.ID == (<OrderActivity>this.activityService.selectedActivity).ID);
        if(idx >= 0){
          this.orderManagementService.teamOrder[idx] = (<OrderActivity>this.activityService.selectedActivity);
        }
        this.orderActivityDataService.isSelectedOrderActivityUpdated = true;
      }
      switch (buttonId) {
        case 'scraporderactivity': {
          //Check from where activity is deleted
          let activChildNav: ChildNavNames = this.navService.getActiveChildNavName();
          switch (activChildNav) {
            case ChildNavNames.ActivitiesPageNavigation:
              this.uiService.activeView = '';
              this.uiService.showRightPane = false;
              this.activityService.publishActivityEvent({action: "Delete", activity: this.activityService.selectedActivity});
              if (this.uiService.activitiesPageTab === 'day') {
                this.events.publish('activityDeleted', this.activityService.selectedActivity);
              }
              this.activityService.selectedActivity = null;
              this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
              break;
          }
          this.events.publish('updateOrdersList',{ activity : this.activityService.selectedActivity, mode: 'delete' });
          if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
            this.events.publish('refreshAgenda');
          }
          else {
            this.uiService.agendaRefreshRequired = true;
          }
          break;
        }
        case 'autoapprovalflow': {
          this.events.publish('updateOrdersList',{ activity : this.activityService.selectedActivity, mode : 'update' });
          break;
        }
        case 'autoapproveorderactivity':
        case 'sendorderactivityforreview': {
          this.events.publish('updateOrdersList',{ activity : this.activityService.selectedActivity, mode : 'update' });
          break;
        }
        case 'cancelorderactivityreview': {
          this.events.publish('updateOrdersList',{ activity : this.activityService.selectedActivity, mode : 'update' });
          break;
        }
        case 'notapproveorderactivity': {
          this.events.publish('updateOrdersList',{ activity : this.activityService.selectedActivity, mode : 'update' });
          this.trackingService.tracking('TeamOrderNotApprove', TrackingEventNames.ORDERMANAGEMENT);
          break;
        }
        case 'approveorderactivity': {
          this.events.publish('updateOrdersList',{ activity : this.activityService.selectedActivity, mode : 'update' });
          this.trackingService.tracking('TeamOrderApprove', TrackingEventNames.ORDERMANAGEMENT);
          break;
        }
        case 'autofulfillflow':
        case 'fulfillorderactivity': {
          this.events.publish('updateOrdersList',{ activity : this.activityService.selectedActivity, mode : 'update' });
          let currentOrderActivity = (<OrderActivity>this.activityService.selectedActivity);
          if (currentOrderActivity.opportunityId) {
            const opportunity = this.opportunityService.getOpportunityDetailsbyId(currentOrderActivity.opportunityId);
            if (opportunity && opportunity.transactionCurrencySymbol === this.authService.user.currencysymbol) {
              opportunity.closedValue += currentOrderActivity.total;
              opportunity.closedValueFormatted = this.dateTimeFormatsService.selectedSymbolPos.position === 'left'
                ? opportunity.transactionCurrencySymbol + this.dateTimeFormatsService.getFormattedNumber(opportunity.closedValue)
                : this.dateTimeFormatsService.getFormattedNumber(opportunity.closedValue) + opportunity.transactionCurrencySymbol;
              this.opportunityService.addUpdateOpportuntiy(opportunity).then(success => {
                this.opportunityManagementDataService.isSelectedOpportunityUpdated = true;
              }).catch(err => {
              });
            }
          }
          break;
        }
        case 'reopenorderactivity': {
          this.events.publish('updateOrdersList',{ activity : this.activityService.selectedActivity, mode : 'update' });
          break;
        }
        default:
          break;
      }
    }).catch(async err => {
      //Handle error scenario
      if (err && err.errorCode && err.errorCode == 'ONLINEONLYUPDATEFAILED') {
        this.notificationService.notify(this.translate.instant('OM_WAS_A_PROBLEM_CONTACT_ADMINISTRATOR'), 'Order Details', 'top', ToastStyle.DANGER)
        // Fetch realtime order details
        if (err.operationCode && (err.operationCode == 'approveorderactivity' || err.operationCode == 'notapproveorderactivity' || err.operationCode == 'sendorderactivityforreview' || err.operationCode == 'autoapproveorderactivity'
          || err.operationCode == 'cancelorderactivityreview' || err.operationCode == 'autoapprovalflow' || err.operationCode == 'fulfillorderactivity' || err.operationCode == 'reopenorderactivity' || err.operationCode == 'autofulfillflow')) {
          // Fetch realtime order details
          if (this.activityService.selectedActivity && this.activityService.selectedActivity.type == ActivityType.Order) {
            await this.activityDataService.updateActivityDetails((this.activityService.selectedActivity as OrderActivity));
          }
        }
      }
    });
    this.uiService.dismissLoader();
  }

  openCaseDetailsView(data, source: AccesingMode) {

    /* If modal is already opened then ignore request else open a new modal and destroy instance on*/
    if (this.pageModal) return;

    this.caseManagementService.accessedFrom = source;
    this.caseManagementService.assignSelectedCase(data);
    this.modalCtrl.create({
      component: InMeetingCasesComponent,
      componentProps: {
        type: ActivityType.CaseIntake,
        view: 'case-details'
      },
      backdropDismiss: false, cssClass: 'inMeetingCases'
    })
      .then((modal) => {
        this.pageModal = modal;
        modal.present();
        modal.onDidDismiss().then((data) => {
          if (source === AccesingMode.PRESENTATION) {
            this.footerService.initButtons(FooterViews.PreviewMeeting);
          }
          else if (source === AccesingMode.INMEETING) {
            this.footerService.initButtons(FooterViews.Activities);
          }
          //reset the modalHandler for next flow
          this.pageModal = undefined;
        });
      })
  }

  isOverlappedButton(i: number) {
    if (this.uiService.activeView != 'Meeting') return 0;
    var totalButtons: number = this.visibleFooterIcons().length;
    var totalWindowWidth: number = window.innerWidth;
    var totalAvailableOnRightSide: number = (totalWindowWidth - 73) / 2;
    var buttonCanFitOnRightSide: number = parseInt((totalAvailableOnRightSide / 48).toString());
    var buttonsWidthOnrightSide: number = 48.00 * buttonCanFitOnRightSide;
    if (buttonCanFitOnRightSide < totalButtons) {
      if ((totalButtons - buttonCanFitOnRightSide) == (i - 0 + 1)) {
        var margin: number = 84 + ((totalAvailableOnRightSide - buttonsWidthOnrightSide) * 2.00) - 0;
        return margin + 'px';
      } else {
        return 0
      }
    } else {
      return 0;
    }
  }

  sendMarketingEmailFeedback(feedback: string) {
    if (this.xperiencesService.marketingEmailFeedbackGiven) {
      return;
    }
    this.xperiencesService.sendMarketingEmailFeedback(feedback);
  }

  async flipToMeeting(button, isInboundFlow = false, accountVisiteCreation = false) {
    this.uiService.toolsActivityActive = true;

    //show loader on flip button click
    this.activityDataService.activityDetailsLoaded = false;
    this.uiService.isFlipButtonClicked = true;
    if (button) button.isactive = !this.uiService.isFlipButtonClicked;
    this.callPlanService.callPlanPageView = '';
    // There could be a better place for this
    // let presentationDownloaded = this.activityService.selectedActivity instanceof AppointmentActivity && this.activityService.selectedActivity.presentations && this.activityService.selectedActivity.presentations.some(pres => pres.downloaded)
    // let presentationDownloaded = this.presentationService.presentation.some(pres => pres.downloaded);
    // if (this.device.isOnAndroidWithCordova && (this.device.isOffline || this.device.isDeviceRealOffline) && !presentationDownloaded) {
    //   this.notificationService.notify(this.translate.instant('PLEASE_DOWNLOAD_THE_PRESENATION_TO_VIEW_WHILE_OFFLINE'), "activity-details", "top", ToastStyle.DANGER, 3000, true);
    //   this.activityDataService.activityDetailsLoaded = true;
    //   this.uiService.isFlipButtonClicked = false;
    //   return;
    // }

    this.trackingService.tracking('MeetingFlip', TrackingEventNames.ACTIVITY);
    if (this.activityService.selectedActivity instanceof AppointmentActivity) {
      this.activityService.selectedActivity.isMeetingPresentationDetailed = this.activityService.selectedActivity['activityPresentations'].length > 0;
      this.uiService.activeView = 'Meeting';
      this.presentationService.clearCurrentPresentation();
      this.presentationService.setCurrentSelectedPres(null);
      this.presentationService.setCarouselBriefcase(this.meetingStructureService.contents);
      let allInteractions: EmbeddedInteraction[] = this.meetingStructureService.embeddedIntreactions;
      if (!_.isEmpty(allInteractions)) {
        let firstActivity: EmbeddedInteraction = null;
        if (this.activityService.selectedActivity.indskr_shortcall) {
          allInteractions = allInteractions.filter(i => i.type == EmbeddedInteractionType.Content);
        }
        if (this.device.isNativeApp && this.device.isOffline) {
          firstActivity = _.find(allInteractions, (activity) => { return activity.type == EmbeddedInteractionType.Activity || (<Presentation | Resource>activity.interaction).downloaded });
        } else {
          firstActivity = allInteractions[0];
        }
        if (_.isEmpty(firstActivity)) {
          this.meetingStructureService.setCurrentMeetingActivity(null);
        } else {
          this.meetingStructureService.setCurrentMeetingActivity(firstActivity);
          if (firstActivity.type !== EmbeddedInteractionType.Activity) {
            this.presentationService.setCurrentSelectedPres(<Presentation | Resource>firstActivity.interaction);
            const page: Page = _.isEmpty(this.presentationService.presPages) ? null : this.presentationService.presPages[0];
            this.presentationService.setCurrentSelectedPresPage(page);
          }
        }
      } else {
        this.meetingStructureService.setCurrentMeetingActivity(null);
      }
      if(this.activityService.selectedActivity instanceof AppointmentActivity) {
        const selectedActivity = _.cloneDeep(this.activityService.selectedActivity);
        this.presentationService.selectedActivityId = selectedActivity.ID;

        await this.meetingDataService.appendMeetingLatAndLongForGpsDistanceCalculation(this.activityService.selectedActivity);
      }
    }

    // this._navPush(PresentationMeetingComponent, { 'viewMode': PresentationView.MEETING });
    this.presentationService.viewMode = PresentationView.MEETING;
    this.activityDataService.activityDetailsLoaded = true;
    this.footerService.initButtons(FooterViews.PreviewMeeting);
    this.navService.pushWithPageTracking(PresentationMeetingComponent, PageName.PresentationMeetingComponent,
      { from: this.navService.getCurrentPageName(), accountVisiteCreation }, PageName.PresentationMeetingComponent)
      .then(() => {
        // In case of inbound meeting flow, unset flags and stop the loading.
        if (isInboundFlow) {
          this.inboundMeetingCtrl.stopLoading();
        }
      });;
  }

  setFooterIconOrder() {
    let videoIconIndex = _.findIndex(this.footerService.actionButtons, { id: "video" });
    let stopvideoIconIndex = _.findIndex(this.footerService.actionButtons, { id: "stop_video_active" });
    if (videoIconIndex != -1) {
      if (!(this.authService.hasFeatureAction(FeatureActionsMap.REMOTE_FEATURE) && this.authService.hasFeatureAction(FeatureActionsMap.MEETING_VOIP))) {
        this.footerService.actionButtons[videoIconIndex].displayOrder = 12;
      }
      else {
        this.footerService.actionButtons[videoIconIndex].displayOrder = 8;
      }
    }
    if (stopvideoIconIndex != -1) {
      if (!(this.authService.hasFeatureAction(FeatureActionsMap.REMOTE_FEATURE) && this.authService.hasFeatureAction(FeatureActionsMap.MEETING_VOIP))) {
        this.footerService.actionButtons[stopvideoIconIndex].displayOrder = 12;
      }
      else {
        this.footerService.actionButtons[stopvideoIconIndex].displayOrder = 8;
      }
    }
    // this.footerService.actionButtons =  _.orderBy(this.footerService.actionButtons, ['displayOrder'], ['desc']);
  }

  public footerIconStyle(button: any) {
    if (this.footerView === 'masterView' || this.footerView === 'fullScreenView' || this.footerView === 'shortCallLauncherView') {
      return button.id.indexOf('active') > 0 ? 'fabActionItemIconActiveMaster' : 'fabActionItemIcon';
    } else {
      const avoidButtons = ['message',"closeasusercanceled"];
      if (!avoidButtons.includes(button.id)) {
        return 'fabActionItemIcon';
      }
    }
    return '';
  }

  public footerTextWidth(button: any) {
    // Add all the buttons which text are overlapped inside the footer button
    // simply add that button id and it will add width:50px so the text will be truncated and will show
    // ellipsis at the end of the text.
    // Ellipsis is not applied without the fixed width.
    // if (this.footerView === 'detailView' && this.footerService.footerForView === FooterViews.Activities) {
    // }
    let applyWidthCssToButtons = ['meetingOpportunity', 'savescheduler'];
    if (this.device.isMobileDevice) {
      applyWidthCssToButtons.push('impact-accounts')
      applyWidthCssToButtons.push('impact-location')
      applyWidthCssToButtons.push('plantab-notification')
      applyWidthCssToButtons.push('plantab-notification-active')
    }
    if (applyWidthCssToButtons.includes(button.id)) {
      return 'width: 50px';
    }
    return ''
  }

  public get footerIcons() {
    if ((this.footerView == 'detailView' || this.footerView == 'fullScreenView')  && this.selectedView === 'presentations') {
      if (this.applyAlignmentForRow()) {
        return _.orderBy(this.visibleFooterIcons(), ['displayOrder'], ['asc']);
      } else {
        return _.orderBy(this.visibleFooterIcons(), ['displayOrder'], ['desc']);
      }
    } else if (this.footerView == 'detailView' && (this.fromWhichView == 'Xperience' || this.fromWhichView === 'edge-analytics-detail')) {
      return null;
    } else {
      if (this.footerView == 'detailView') {
        if (this.applyAlignmentForRow()) {
          return _.orderBy(this.footerService.actionButtons, ['displayOrder'], ['asc']);
        } else {
          return _.orderBy(this.footerService.actionButtons, ['displayOrder'], ['desc']);
        }
      } else {
        return this.footerService.actionButtons;
      }
    }
  }

  private visibleFooterIcons() {
    return this.footerService.actionButtons.filter(btn => btn.isvisible);
  }

  public get applyRightPaddingWhenApplicable(): boolean {
    // In Consent Details screen, when there is only one option
    // It should look from the right most side.
    if (this.footerView === 'detailView' && this.footerService.footerForView) {
      const fv = this.footerService.footerForView;

      if (fv === FooterViews.ConsentDetails
        || fv === FooterViews.INQUIRY_CONSENT
        || fv === FooterViews.CaptureSampleOrder
        || fv === FooterViews.EmailTemplatesPreview) {
        const isMobileDevice = this.device.isMobileDevice;
        if (isMobileDevice === false) {
          let count = this.visibleFooterIcons().length;
          return count === 1;
        }
      }
    }
    return false;
  }

  public isMobileDevicePortrait(): boolean {
    return (this.device.isMobileDevice === true && this.device.isMobileDevicePortrait === true)
  }

  private visibilityOfGeneeInDetails(isPortrait: boolean): boolean {
    if (this.device.isMobileDevice) {
      // We shouldn't show the genee button inside the resource details as it overlapps the contents.
      // Add further screens when don't want to show the genee button as well.
      if (this.footerService.footerForView) {
        if ([FooterViews.Resources, 
            FooterViews.GenericRightPane, 
            FooterViews.SetBooking, 
            FooterViews.Activities,
            FooterViews.CUSTOMER_INQUIRY,
            FooterViews.Accounts,
            FooterViews.Contacts,
            FooterViews.OPPORTUNITY_INFO_OPEN,
            FooterViews.OPPORTUNITY_INFO_CONFIRMED,
            FooterViews.TimeOffDetails,
            FooterViews.ASSET_DETAILS,
            FooterViews.CoachingDetails,
            FooterViews.SURGERY_ORDER_ACTIVITY,
            FooterViews.PREVIEW_MEETING_ACTIVITY].includes(this.footerService.footerForView)) {
          return true;
        }
      }
      //
      if (isPortrait) {
        return false;
      } else {
        return (this.selectedView && this.selectedView === 'email-personalization') ? false : true;
      }
    } else {
      return true;
    }
  }

  public applyAdditionalCssForIonFab(): boolean {
    return (this.device.hasHomeBar === true && this.isMobileDevicePortrait() === true);
  }

  public applyAlignmentForRow(): boolean {
    return (this.isMobileDevicePortrait() === true);
  }

  public isDetailsViewInMobile(): boolean {
    return (this.isMobileDevicePortrait() === true && this.footerView === 'detailView');
  }

  public hasVisibleFooterIconsInMaster(): boolean {

    if (this.footerService.forceHideMasterFooter) {
      this.footerService.hasVisibleFooterIconsInMaster = false;
      return false;
    }

    const currentPage = this.navService.getCurrentPageName();
    if (currentPage === PageName.ActivitiesPageComponent || currentPage === PageName.CustomerCallPlanPage || this.uiService.isFilterActivated) {
      // return true/false based on the current tab
      const currentTab = this.uiService.activitiesPageTab;
      if (currentTab) {
        if ((currentTab === 'edgeAnalytics' || currentTab === 'notifications') && !this.uiService.isFilterActivated) {
          // Measure Tab doesn't have any button
          this.footerService.hasVisibleFooterIconsInMaster = false;
          return false;
        }
      }
      let result = (this.agendaFooterService.actionButtons && this.agendaFooterService.actionButtons.filter(button => button.isvisible).length > 0);
      this.footerService.hasVisibleFooterIconsInMaster = result;
      return result;
    } 
    // else if(currentPage == PageName.ContactPageComponent){
    //   if(this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_KOL_STATUS)){
    //     let result = (this.agendaFooterService.actionButtons && this.agendaFooterService.actionButtons.filter(button => button.isvisible).length > 0);
    //     this.footerService.hasVisibleFooterIconsInMaster = result;
    //     return result;
    //   }
    // }
    else {
      this.footerService.hasVisibleFooterIconsInMaster = false;
      return false;
    }
  }

  public get agendaFooterIcons() {
    return _.orderBy(this.agendaFooterService.actionButtons.filter(btn => btn.isvisible), ['order'], ['asc']);
  }

  public get hasVisibleFooterIcons() {
    if (this.footerView == 'detailView' && (this.fromWhichView == 'Xperience' || this.fromWhichView === 'edge-analytics-detail')) {
      return false;
    } else {
      return (this.visibleFooterIcons().length > 0);
    }
  }

  refresh() {
    this.ref.detectChanges();
    this.ref.markForCheck();
  }

  checkForUnsavedChanges(button) {
    if (this.xperiencesService.unSavedChanges) {
      this.agendaFooterService.showDiscardPopup().then((res) => {
        if (res.role === "ok") {
          this.setActiveButtons(button);
          this.xperiencesService.dicardChangesForXperiences();

        }
      });
    } else if (!_.isEqual(this.xperiencesService.generalInsight, this.xperiencesService.tempInsight)) {
      this.xperiencesService.discardChangesPopup().then(res => {
        if (res.role === 'ok') {
          this.setActiveButtons(button);
          if (this.xperiencesService.tempInsight) this.xperiencesService.generalInsight = this.xperiencesService.tempInsight;
          this.xperiencesService.enableInsight = true;
        }
      });
    } else {
      this.setActiveButtons(button);
    }
  }

  setActiveButtons(button: string) {
    this.xperiencesService.selectedTrendingFeed = null;
    this.xperiencesService.selectedXpContact = null;
    this.xperiencesService.enableInsight = true;
    this.buttonClicked.emit(button['id']);
    switch (button['id']) {
      case 'impact-feed':
        this.agendaFooterService.initButtons(AgendaFooterView.ImpactFeed);
        break;
      case 'impact-contacts':
        this.agendaFooterService.initButtons(AgendaFooterView.ImpactContacts);
        break;
      case 'impact-accounts':
        this.agendaFooterService.initButtons(AgendaFooterView.ImpactAccounts);
        break;
      case 'impact-saved-contact':
        this.agendaFooterService.initButtons(AgendaFooterView.ImpactSavedContacts);
        break;
      case 'impact-location':
        this.agendaFooterService.initButtons(AgendaFooterView.ImpactLocation);
        break;
      default:
        break;
    }
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ActivitiesPageComponent);
  }

  // if the selected view is master view and options are more than 5 we need to return true otherwise false.
  // Once we decie to return true, then we need to tweak the data set ==> we need to divide the data set after 4 so that options can be shown in more popover.
  public shouldShowMasterMore() {
    if (this.footerView === 'masterView') {
      if (this.agendaFooterIcons.length > 5) {
        return true;
      }
    }
    return false;
  }

  public insertMoreOption() {

    if (this.shouldShowMasterMore()) {

      // if option to show more option returns true, then tweak teh buttons Data set and remove the data set and add to more popover buttons data set.

      // this.agendaFooterIcons = this.agendaFooterIcons.slice(0,4)
      // this.agendaFooterIcons.push(this.agendaFooterIcons.)

    }
  }

  updateBackground() {
    const footer_row = document.getElementById('footer-row');
    const footerColmns = footer_row.children;
  }

  public getMasterScreenActionItemSize(): string {
    if (this.device.isMobileDevice) {
      if (this.device.isMobileDevicePortrait === false) {
        return '2.6';
      }
    }
    const options = this.agendaFooterService.actionButtons.filter(btn => btn.isvisible);
    if (options.length === 1) return "12";
    if (options.length === 2) return "6";
    if (options.length === 3) return "4";
    if (options.length === 4) return "3"
    return '2.3';
  }

  private updateFABWidth() {
    if (this.device.isMobileDevice) {
      if (this.footerService) {
        setTimeout(() => {
          this.footerService.updateFABWhenOrientationChanges();
        }, 1000);
      }
    }
  }

  getButtonValue(button: any): string {
    if (button) {
      if (button['external']) {
        return button['external'];
      } else {
        return this.translate.instant(button.name);
      }
    } else {
      return '';
    }
  }

  private async handleInMeetingOpportunity(){
    this.uiService.prevView = this.uiService.activeView;
    this.activityService.selectedActivityAtHome = this.activityService.selectedActivity;
    this.navService.pushWithPageTracking(OpportunityManagementPageComponent, PageName.OpportunityManagementPage, { 'listMode': ComponentViewMode.LISTVIEW, invokeCreateOpportunity: true }, PageName.OpportunityManagementPage);
  }

}
