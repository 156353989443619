<!-- procedure contract list -->
<ind-section-header *ngIf="procedureContractEnabled" [viewData]='procedureContractHeader'
  (onControlClick)='onProcedureContractSectionClick($event)'></ind-section-header>

<ion-list class="borderless-time-line-list" *ngIf="procedureContractEnabled">
  <ion-item class="placeholderEmptyDiv" *ngIf="timelineItems.length == 0"></ion-item>
  <timeline-item *ngFor="let item of timelineItems" [inputData]="item"
    (clickedOnHeader)="openProcedureContactPage(item)"></timeline-item>
</ion-list>
<!-- procedure contract list -->

<ind-section-header *ngIf="applicableOpportunities.length != 0" [viewData]='opportunitiesHeader'></ind-section-header>

<ion-list *ngIf="this.formattedOpportunities.Won.length>0">
  <div class="accordion-header" [ngClass]="expandedSection == 'won'?'expanded':'collapsed'">
    <main-card [viewData]="getOpportunityDataModel('won')"></main-card>
  </div>
  <expandable #expWrapper [viewType]="'opportunities'" [opportunities]="formattedOpportunities.Won" [expandHeight]="0"
    [expanded]="expandedSection == 'won'" (openOpportunity)="openOpportunityDetail($event)">
  </expandable>
</ion-list>

<ion-list *ngIf="this.formattedOpportunities.Open.length>0">
  <div class="accordion-header" [ngClass]="expandedSection == 'open'?'expanded':'collapsed'">
    <main-card [viewData]="getOpportunityDataModel('open')"></main-card>
  </div>
  <expandable #expWrapper [viewType]="'opportunities'" [opportunities]="formattedOpportunities.Open" [expandHeight]="0"
    [expanded]="expandedSection == 'open'" (openOpportunity)="openOpportunityDetail($event)">
  </expandable>
</ion-list>

<ion-list *ngIf="this.formattedOpportunities.Lost.length>0">
  <div class="accordion-header" [ngClass]="expandedSection == 'lost'?'expanded':'collapsed'">
    <main-card [viewData]="getOpportunityDataModel('lost')"></main-card>
  </div>
  <expandable #expWrapper [viewType]="'opportunities'" [opportunities]="formattedOpportunities.Lost" [expandHeight]="0"
    [expanded]="expandedSection == 'lost'" (openOpportunity)="openOpportunityDetail($event)">
  </expandable>
</ion-list>

<ion-list *ngIf="this.hasCanceledOpportunity && this.formattedOpportunities.Canceled.length>0">
  <div class="accordion-header" [ngClass]="expandedSection == 'canceled'?'expanded':'collapsed'">
    <main-card [viewData]="getOpportunityDataModel('canceled')"></main-card>
  </div>
  <expandable #expWrapper [viewType]="'opportunities'" [opportunities]="formattedOpportunities.Canceled"
    [expandHeight]="0" [expanded]="expandedSection == 'canceled'" (openOpportunity)="openOpportunityDetail($event)">
  </expandable>
</ion-list>


<!-- Quote list -->
<ind-section-header *ngIf="showQuoteSection" [viewData]='quoteHeader'
                    (onControlClick)='onQuoteSectionClick()'></ind-section-header>

<ion-list class="borderless-time-line-list" [ngClass]="{'empty-container': quoteList.length === 0}"
          *ngIf="showQuoteSection">
  <timeline-item *ngFor="let item of quoteList" [inputData]="item"
                 (clickedOnHeader)="openQuotePage(item)"></timeline-item>
</ion-list>
<!-- Quote list -->


<!-- Account Plan list -->
<ind-section-header *ngIf="showAccountPlanSection" [viewData]='accountPlanHeader'
  (onControlClick)='onAccountPlanSectionClick()'></ind-section-header>
<ion-list class="borderless-time-line-list" [ngClass]="{'empty-container': accountPlanList.length === 0}"
  *ngIf="showAccountPlanSection">
  <timeline-item *ngFor="let item of accountPlanList" [inputData]="item"
    (clickedOnHeader)="openAccountPlanPage(item)"></timeline-item>
</ion-list>
<!-- Account Plan list -->


<!-- Sales Order list -->
<ind-section-header *ngIf="showSalesOrderSection" [viewData]='salesOrderHeader'
  (onControlClick)='onSalesOrderSectionClick()'></ind-section-header>

<ion-list class="borderless-time-line-list" [ngClass]="{'empty-container': salesOrderList.length === 0}"
  *ngIf="showSalesOrderSection">
  <timeline-item *ngFor="let item of salesOrderList" [inputData]="item"
    (clickedOnHeader)="openSaleOrderPage(item)"></timeline-item>
</ion-list>
<!-- Sales Order list -->

<!--OffTake Collection-->
<ind-section-header *ngIf="offtakeFeatureEnabled" [viewData]='offtakeCheckHeader'
  (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
<ion-list [ngClass]="{'empty-container':!currentMonthOffTake}" *ngIf="offtakeFeatureEnabled">
  <ion-row *ngIf="currentMonthOffTake">
    <ion-col>
      <ind-form-field [viewData]="getModifiedDateFormField()"></ind-form-field>
    </ion-col>
    <ion-col>
      <ind-form-field [viewData]="getModifiedByFormField()"></ind-form-field>
    </ion-col>
  </ion-row>
</ion-list>

<!-- Available Survey -->
<ng-container *ngIf="isSurveyEnabled && filteredSurveyListToDisplay && filteredSurveyListToDisplay.length>0">
  <ind-section-header [viewData]="surveyListSectionHeader"
    (onControlClick)="onSectionHeaderControlClick($event)"></ind-section-header>
  <ion-list *ngIf="filteredSurveyListToDisplay" class='survey-list-grid'>
    <div class="survey-card" *ngFor="let survey of filteredSurveyListToDisplay">
      <sub-card [viewData]="survey" [id]="survey.id" (onControlClick)="onButtonControlClick($event)"></sub-card>
    </div>
  </ion-list>
</ng-container>

<!-- Assets / Field material management -->
<ind-section-header *ngIf="isAssetsEnabled"
                    [viewData]="assetManagementSectionHeader"
                    (onControlClick)="onAssetManagementSectionClick()"></ind-section-header>

<ion-list class="asset-list-grid" [ngClass]="{'empty-container': assetManagementList.length === 0}"
          *ngIf="isAssetsEnabled">
  <timeline-item *ngFor="let item of assetManagementList; trackBy: trackById" [inputData]="item"
                 (clickedOnHeader)="openAssetManagementPage(item)"></timeline-item>
</ion-list>
<!-- Assets / Field material management -->

<div class="no-data-message"
  *ngIf="isDataUnavailable">
  {{'NO_PROFILE_DATA' | translate}}</div>
<img
  *ngIf="isDataUnavailable"
  class="os_background-list" [ngClass]="{'os_background-list-ios' : device.deviceFlags.ios}" />
