<ion-header>
  <ind-page-title *ngIf="mainToolTemplateDetailsPageTitle || uiService.showRightPane" [viewData]='mainToolTemplateDetailsPageTitle' (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>
<ion-content no-padding>
  <ng-container *ngIf="for=='displayForm'">
    <ind-display-form 
      #scrollTop 
      [formMetadata]='formMetadata'
      [rawData]='rawData'
      [isEditEnabled]='isEditEnabled'>
    </ind-display-form>
  </ng-container>
  <ng-container *ngIf="viewData?.isGeneralSectionEnabled">
    <ind-section-header [viewData]='generalSectionHeader'></ind-section-header>
    <div *ngFor="let detail of generalSectionDetails">
      <ind-form-field [viewData]="detail"></ind-form-field>
    </div>
  </ng-container>
  <ng-container *ngIf="viewData?.isGridSectionEnabled">
    <ind-section-header [viewData]='gridSectionHeader'></ind-section-header>
    <ion-item-group *ngIf="gridSectionDetails && gridSectionDetails.length >0" >
    <ion-grid class="grid-wrapper">
      <ion-row class="header-row" justify-content-center>
        <ion-col [size]="getColumnSize()" *ngFor="let item of viewData.attributeNamesForGridSection;let i = index">
          <ion-label class="header-label">
            <p class="primary-label-text">
              {{item.label}}
            </p>
           </ion-label>
        </ion-col>
      </ion-row>
      <ion-row *ngFor="let details of gridSectionDetails" justify-content-center>
        <ion-col [size]="getColumnSize()" *ngFor="let item of details;let i = index">
          <ion-label class="item-text">
            <p class="primary-label-text">
              {{item.inputText}}
            </p>
          </ion-label>
        </ion-col>
      </ion-row>
    </ion-grid>
    </ion-item-group>
    <ion-item-group *ngIf="gridSectionDetails && gridSectionDetails.length == 0" >
      <p justify-content-center class="no-data-message">
          {{ 'NO' | translate}} {{((gridSectionHeader)?gridSectionHeader.title:'' )| lowercase}}
      </p>
    </ion-item-group>
  </ng-container>
</ion-content>