import { OpportunityManagementDataService } from './../../../data-services/opportunity-management/opportunity-management.data.service';
import { UIService } from './../../../services/ui/ui.service';
import { NewActivityComponent } from './../../activity/new-activity/new-activity';
import { Component, Input } from "@angular/core";
import { RepServices } from "../../../data-services/rep/rep.services";
import { DeviceService } from "../../../services/device/device.service";
import { NavigationService, PageName } from "../../../services/navigation/navigation.service";
import { AuthenticationService } from "../../../services/authentication.service";
import { TrackService, TrackingEventNames } from "../../../services/logging/tracking.service";
import { CollaboratorType, EstimatedClosedDateMandatory, Opportunity } from "../../../classes/opportunity-management/opportunity.class";
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { TranslateService } from '@ngx-translate/core';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { EventName, EventsService } from '@omni/services/events/events.service';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from '@omni/services/alert/alert.service';
import { Subject } from 'rxjs';
import { OpportunityManagementService } from '@omni/services/opportunity-management/opportunity-management.service';
import { FooterService } from '@omni/services/footer/footer.service';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { AccountManagementDataService } from '@omni/data-services/account-management/account-management.data.service';
import { SurveyService } from '@omni/services/survey/survey.service';
import _ from 'lodash';
import { AlertWithInput } from '@omni/components/shared/alert-with-input/alert-with-input'
import { ModalController, PopoverController } from '@ionic/angular';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { SelectListComponent } from '@omni/components/shared/select-list/select-list';
import { ActivityService } from '@omni/services/activity/activity.service';
import { UserTypes } from '@omni/classes/activity/procedure-tracker.activity.class';
import { ProcedureTrackerActivityDataService } from '@omni/data-services/procedure-tracker-activity/procedure-tracker-activity.data.service';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { Utility } from '@omni/utility/util';
import { MarketingPlansManagementOfflineService } from '@omni/services/marketing-management/marketing-management.service';


@Component({
  selector: 'opportunity-details',
  templateUrl: 'opportunity-details.html',
  styleUrls: ['opportunity-details.scss']
})
export class OpportunityDetailsComponent {

  public isLayoverPushView: boolean = false;
  public layoverViewPushedFrom: string = '';
  public opportunityDetailsSegment: string = 'info';
  public selectedOpportunity: Opportunity;
  public isPlusButtonDisabled: boolean = true;
  private isReadOnlyMode = true;
  private isConfirmFlowEnabled: boolean = false;
  private isSurveyValid: boolean = false;
  private _lookupPopover: HTMLIonPopoverElement;
  @Input() from: any
  @Input() opportunity: any;
  @Input() refreshOpportunityEvent: any;
  tabsData = [
    {
      displayText: this.translate.instant('INFO'),
      value: "info"
    },
    {
      displayText: this.translate.instant('OM_QOUTES'),
      value: "quotes",
    },
    {
      displayText: this.translate.instant('OM_AGREEMENTS'),
      value: "agreement",
    },
  ]
  public pageTitle: IndPageTitleViewDataModel;
  private readonly ngDestroy$ = new Subject<boolean>();
  private isCancelFlowEnabled: boolean = false;
  public actualValueFormFieldForPopover:IndFormFieldViewDataModel;
  public opportunityManagToolFA:boolean = false;
  constructor(
    public device: DeviceService,
    public repService: RepServices,
    public authService: AuthenticationService,
    private navService: NavigationService,
    public trackingService: TrackService,
    private uiService: UIService,
    private opportunityDataService: OpportunityManagementDataService,
    private translate: TranslateService,
    private opportunityService: OpportunityManagementService,
    private readonly eventService: EventsService,
    private readonly alertService: AlertService,
    public readonly footerService: FooterService,
    private readonly accountManagementDataService: AccountManagementDataService,
    private readonly surveyService: SurveyService,
    private readonly popoverCtrl: PopoverController,
    private notificationService: NotificationService,
    public modalCtrl: ModalController,
    public activityService: ActivityService,
    private procedureTrackerDataService: ProcedureTrackerActivityDataService,
    private readonly dateTimeFormatsService: DateTimeFormatsService,
    private readonly marketingPlansManagementOfflineService: MarketingPlansManagementOfflineService,
  ) {
    this.eventService.observe(EventName.OPPORTUNITYSCRAPBUTTON).pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.handleOpportunityReasonStatusChange(EventName.OPPORTUNITYSCRAPBUTTON);
    });
    this.eventService.observe(EventName.OPPORTUNITYMARKCONFIRMED).pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.handleOpportunityStatusChange(EventName.OPPORTUNITYMARKCONFIRMED);
    });
    this.eventService.observe(EventName.OPPORTUNITYCLOSEASWON).pipe(takeUntil(this.ngDestroy$)).subscribe((event) => {
      this.handleOpportunityReasonStatusChange(EventName.OPPORTUNITYCLOSEASWON, event);
    });
    this.eventService.observe(EventName.OPPORTUNITYCLOSEASLOST).pipe(takeUntil(this.ngDestroy$)).subscribe((event) => {
      this.handleOpportunityReasonStatusChange(EventName.OPPORTUNITYCLOSEASLOST, event);
    });
    this.eventService.observe(EventName.OPPORTUNITYCLOSEASUSERCANCELED).pipe(takeUntil(this.ngDestroy$)).subscribe((event) => {
      this.handleOpportunityReasonStatusChange(EventName.OPPORTUNITYCLOSEASUSERCANCELED, event);
    });
    this.eventService.observe(EventName.OPPORTUNITYERROR_CLOSE_TO_OPEN).pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.opportunityDataService.getOpportunitiesData(false, this.selectedOpportunity.accountID);
      this.onClosePage();
    });
    this.eventService.observe(EventName.ASSIGN_OPPORTUNITY_OWNER).pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
        this.openUsersList();
    });
  }

  ngOnInit() {
    this.opportunityManagToolFA = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT);
    if (this.opportunity) {
      this.initOpportunityDetails();
      let isOrdersTabEnabled = false;
      if (this.authService.hasFeatureAction(FeatureActionsMap.ORDER_MANAGEMENT)) {
        isOrdersTabEnabled = true;
      }
      if (!isOrdersTabEnabled) {
        const selectedOppurtunityType = this.opportunityService.opportunityTypes.find(ot => ot.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
        if (selectedOppurtunityType && selectedOppurtunityType.indskr_ordercreation) {
          isOrdersTabEnabled = true;
        }
      }
      if (isOrdersTabEnabled) {
        this.tabsData.push({
          displayText: this.translate.instant('ORDERS'),
          value: "orders",
        });
      }
      if (this.authService.hasFeatureAction(FeatureActionsMap.DISCONTINUE_OPPORTUNTIY)) this.isCancelFlowEnabled = true;
      if (this.authService.hasFeatureAction(FeatureActionsMap.CONFIRM_OPPORTUNTIY)) this.isConfirmFlowEnabled = true;
    }
    if (this.from) {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
    }
    if (this.opportunity) {
      this.selectedOpportunity = this.opportunity;
      // if (this.selectedOpportunity.collaboratorType == CollaboratorType.BEHOLDER) {
      //   this.isPlusButtonDisabled = true;
      // }else {
      //   this.isPlusButtonDisabled = false;
      // }
      this.isPlusButtonDisabled = false;
    }
    if (this.from == 'OpportunitiesSelectionPage') {
      this.isReadOnlyMode = true;
      this.isPlusButtonDisabled = true;
    }else if(this.from == "MarketingPlanInfo"){
      //this.isPlusButtonDisabled = true;
    }
    this.initPageTitle();

    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      if (!this.opportunity.isrevenuesystemcalculated && this.opportunity.collaboratorType != CollaboratorType.BEHOLDER && this.from != 'OpportunitiesSelectionPage') {
        if (this.device.isOffline) {
          this.isReadOnlyMode = true;
        } else {
          this.isReadOnlyMode = false;
        }
        this.initPageTitle();
      }
    });
  }

  private initOpportunityDetails() {
    if (this.opportunity.state == 'Open' && this.opportunity.collaboratorType != CollaboratorType.BEHOLDER) {
      this.isReadOnlyMode = false;
    }
    if (!this.opportunity.isrevenuesystemcalculated && this.opportunity.collaboratorType != CollaboratorType.BEHOLDER) {
      if (this.device.isOffline) {
        this.isReadOnlyMode = true;
      } else {
        this.isReadOnlyMode = false;
      }
    }
    if (this.from == 'OpportunitiesSelectionPage') {
      this.isReadOnlyMode = true;
      this.isPlusButtonDisabled = true;
    }
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
  }

  ionViewDidEnter() {
    if (this.selectedOpportunity) {
      this.opportunityDataService.isSelectedOpportunityUpdated = true;
      if (this.opportunityDetailsSegment != 'info') {
        this.footerService.hideAllButtons();
      }
    }
  }

  public segmentClicked(selectedTab: string) {
    switch (selectedTab) {
      case 'info':
        this.opportunityDataService.isSelectedOpportunityUpdated = true;
        this.trackingService.tracking('SelectInfoTab', TrackingEventNames.OPPORTUNITYMANAGEMENT)
        break;
      case 'quotes':
        this.trackingService.tracking('SelectQuotesTab', TrackingEventNames.OPPORTUNITYMANAGEMENT)
        break;
      case 'agreement':
        this.trackingService.tracking('SelectAgreementTab', TrackingEventNames.OPPORTUNITYMANAGEMENT)
        break;
      case 'orders':
        this.trackingService.tracking('SelectOrderTab', TrackingEventNames.OPPORTUNITYMANAGEMENT)
        break;
    }
  }

  public handlePlusButton(): void {
    this.trackingService.tracking('Select+button', TrackingEventNames.OPPORTUNITYMANAGEMENT)
    let fromIdentifier: string = (this.layoverViewPushedFrom == 'OpportunityTool') ? 'OpportunityManagementTool' :
      (this.layoverViewPushedFrom == 'AccountProfileOpportunities' ? 'AccountProfileOpportunities' : 
      this.from === 'MarketingPlanInfo' ? 'MarketingPlanInfoopportunities' :
      'AccountPlanOpportunities');
    let masterPage = this.navService.getCurrentMasterPageName();
    let opp = this.opportunityService.getOpportunityDetailsbyId(this.selectedOpportunity.ID);
    this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent,
      masterPage, { from: fromIdentifier, opportunity: opp });
  }

  public onClosePage(): void {
    if (this.from == 'OpportunitiesSelectionPage') {
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.OpportunitySelectionPage);
      this.navService.setChildNavRightPaneView(false);
    } else if(this.from == 'MarketingPlanInfo') {
      this.navService.popToRootChildNavPageWithPageTracking();
      this.marketingPlansManagementOfflineService.selectedMarketingPlan$.next(this.marketingPlansManagementOfflineService.selectedMarketingPlan$.getValue());
    } else {
      this.navService.popChildNavPageWithPageTracking();
    }
  }

  private initPageTitle(): void {
    this.opportunity = this.selectedOpportunity = this.opportunityService.getOpportunityDetailsbyId(this.opportunity.ID);
    let buttons = [];
    if (this.from != 'OpportunitiesSelectionPage') {
      buttons.push({
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      });
    }

    if(this.authService.hasFeatureAction(FeatureActionsMap.ASSIGN_OPPORTUNITY)){
      buttons.push({
          id: "assignOwneropprtunity",
          imgSrc: 'assets/imgs/assign_owner_white.svg',
          name: this.translate.instant('ASSIGN'),
          align: "right",
          isDisabled : this.device.isOffline || this.selectedOpportunity.state != "Open" || this.selectedOpportunity.collaboratorType === CollaboratorType.BEHOLDER || this.from == 'OpportunitiesSelectionPage' ||  !this.opportunityManagToolFA
      })
    }

    buttons.push({
      id: "refresh",
      imgSrc: 'assets/imgs/refreshIcon.svg',
      name: this.translate.instant('SYNC'),
      isDisabled: this.device.isOffline || this.from == 'OpportunitiesSelectionPage'|| !this.opportunityManagToolFA,
      align: "right"
    })
    if (!this.isPlusButtonDisabled) {
      buttons.push({
        id: "pluse-icon",
        imgSrc: 'assets/imgs/ios_add_3x.svg',
        name: this.translate.instant('CREATE'),
        isDisabled: this.isPlusButtonDisabled  || this.device.isOffline||  !this.opportunityManagToolFA ,//|| this.selectedOpportunity.state != "Open",
        align: "right"
      })
    }
    if (this.isConfirmFlowEnabled && !this.selectedOpportunity.isrevenuesystemcalculated && this.selectedOpportunity.statusCode == 1) {
      buttons.push({
        id: "mark_confirm_opportunity",
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('CONFIRM'),
        isDisabled: this.checkIsDisabledPageTitle("mark_confirm_opportunity") ||  !this.opportunityManagToolFA,
        align: "right"
      })
    }
    else {
      buttons.push({
        id: "closeaslost",
        imgSrc: 'assets/imgs/Lost.svg',
        name: this.translate.instant('OPPORTUNITY_STATE_LOST'),
        isDisabled: this.checkIsDisabledPageTitle("closeaslost") ||  !this.opportunityManagToolFA ,
        align: "right"
      })
      buttons.push({
        id: "closeaswon",
        imgSrc: 'assets/imgs/Won.svg',
        name: this.translate.instant('WON'),
        isDisabled: this.checkIsDisabledPageTitle("closeaswon") ||  !this.opportunityManagToolFA,
        align: "right"
      })
    }
    if (this.from == 'OpportunitiesSelectionPage' && !this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT)) {
      buttons = [];
    }
    this.pageTitle = {
      id: 'opportunity-details',
      title: this.selectedOpportunity ? this.selectedOpportunity.opportunityName : '',
      controls: buttons,
    };
  }

  public checkIsDisabledPageTitle(id): boolean {
    let selectedOpportunityType = this.opportunityService.opportunityTypes.find(ot => ot.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
    let isDisabled = this.isReadOnlyMode ||
     this.device.isOffline ||
     !this.opportunityManagToolFA ||
     !this.isSurveyValid ||
     (selectedOpportunityType['indskr_estimatedcloseddatemandatory'] == EstimatedClosedDateMandatory.MANDATORY && !this.selectedOpportunity.estimatedDate) ||
     (!(this.selectedOpportunity.isrevenuesystemcalculated || this.selectedOpportunity.isestimatedrevenuebasedonprocedureandproducts) && selectedOpportunityType['indskr_productsmandatory'] == EstimatedClosedDateMandatory.MANDATORY && !this.selectedOpportunity.products.length);
    if(id ==="mark_confirm_opportunity"){
      isDisabled = isDisabled ||
      !this.selectedOpportunity.estimatedValue ||
      !this.selectedOpportunity.stakeholders.length;
      }
    else{
      isDisabled = isDisabled ||
       this.selectedOpportunity.state != "Open" ||
       (!this.selectedOpportunity.isrevenuesystemcalculated && !this.selectedOpportunity.estimatedValue) ||
       (!this.selectedOpportunity.isrevenuesystemcalculated && !this.selectedOpportunity.stakeholders.length);
    }
    return isDisabled;
  }

  updateDescription(description) {
    if (description) this.selectedOpportunity.opportunityName = description;
    this.initPageTitle();
  }

  captureIsSurveyValidEvent(flag) {
    this.isSurveyValid = flag;
    this.initPageTitle();
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'refresh':
        this.refreshOpportunity();
        break;
      case 'close':
        this.onClosePage();
        break;
      case 'pluse-icon':
        this.handlePlusButton();
        break;
      case 'opportunity_scrap':
        this.handleOpportunityReasonStatusChange(id);
        break;
      case 'mark_confirm_opportunity':
        this.handleOpportunityStatusChange(id);
        break;
      case 'closeaswon':
      case 'closeaslost':
      case 'closeasusercanceled':
        this.handleOpportunityReasonStatusChange(id);
        break;
      case 'assignOwneropprtunity':
        this.openUsersList();
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }
  private async handleOpportunityReasonStatusChange(id: string, myEvent?) {
    this.opportunity = this.opportunityService.getOpportunityDetailsbyId(this.opportunity.ID);
    let popoverOptions: {}
    let altCancelText: string = this.translate.instant('CANCEL');
    
    let selectedOpportunityType = this.opportunityService.opportunityTypes.find(ot => ot.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
    if (!(selectedOpportunityType?.actualRevenueAutoCalculated && selectedOpportunityType.actualrevenuecalculationbasedon === 548910000) && !this.opportunity.isrevenuesystemcalculated) {
      this.actualValueFormFieldForPopover = {
        label: this.translate.instant('ACTUAL_REVENUE'),
        inputText: this.opportunity.closedValueFormatted ? this.dateTimeFormatsService.formattedCurrency(this.opportunity.closedValueFormatted) : '',
        inputType: 'number',
        inputValue: (this.opportunity.closedValue) ? this.opportunity.closedValue : 0,
        id: 'actual-value-field',
        isReadOnly: (!this.isReadOnlyMode && this.opportunity.collaboratorType == CollaboratorType.OWNER) ? false : true,
        isDisabled: (!this.isReadOnlyMode && this.opportunity.collaboratorType == CollaboratorType.OWNER) ? false : true,
        customPlaceholderLabel: this.translate.instant('ENTER_ACTUAL_REVENUE'),
        showArrow: !this.isReadOnlyMode && this.opportunity.collaboratorType == CollaboratorType.OWNER,
        formFieldType: FormFieldType.INLINE_INPUT,
        eventHandler: (id: string, event, eventName) => this.handleEditActualValue(id,event,eventName),
      };
    }
    switch (id) {
      case EventName.OPPORTUNITYCLOSEASWON:
        popoverOptions = {
          header: this.translate.instant('CLOSE_AS_WON'),
          message: this.translate.instant('CLOSE_OPPORTUNITY_SUB_TEXT'),
          inputs: this.fetchOpportunityReasonsWon(),
          cancelText: altCancelText,
          confirmText: this.translate.instant('CONFIRM'),
          Placeholder: this.translate.instant('ENTER_DESCRIPTION'),
          enableMultiSelect: true,
          customFormField: this.actualValueFormFieldForPopover,
        }
        break;
      case EventName.OPPORTUNITYCLOSEASLOST:
        popoverOptions = {
          header: this.translate.instant('CLOSE_AS_LOST'),
          message: this.translate.instant('CLOSE_OPPORTUNITY_SUB_TEXT'),
          inputs: this.fetchOpportunityReasonsLost(),
          cancelText: altCancelText,
          confirmText: this.translate.instant('CONFIRM'),
          Placeholder: this.translate.instant('ENTER_DESCRIPTION'),
          enableMultiSelect: true,
          customFormField: this.actualValueFormFieldForPopover,
        }
        break;
      case EventName.OPPORTUNITYCLOSEASUSERCANCELED:
        popoverOptions = {
          header: this.translate.instant('DISCONTINUE_OPPORTUNITY'),
          message: this.translate.instant('DISCONITNUE_OPPORTNUITY_MESAGE'),
          inputs: this.fetchOpportunityReasonsDiscontinue(),
          cancelText: altCancelText,
          confirmText: this.translate.instant('CONFIRM'),
          Placeholder: this.translate.instant('ENTER_DESCRIPTION'),
          enableMultiSelect: true,
        }
        // altConfirmText = this.translate.instant('CONFIRM')
        break;
      case EventName.OPPORTUNITYSCRAPBUTTON:
        popoverOptions = {
          header: this.translate.instant('OPPORTUNITY_MGMT_SCRAP_TITLE'),
          message: this.translate.instant('OPPORTUNITY_MGMT_SCRAP_MESSAGE'),
          inputs: this.fetchOpportunityReasonsScrap(),
          cancelText: altCancelText,
          confirmText: this.translate.instant('SCRAP'),
          enableMultiSelect: true,
        }
        break;
    }
    this._lookupPopover = await this.popoverCtrl.create({
      component: AlertWithInput,
      componentProps: popoverOptions,
      cssClass: this.device.isNativeApp ? 'native-alert-with-input-list-view' : 'alert-with-input-list-view',
      backdropDismiss: true,
      event: this.device.isNativeApp ? ((myEvent && myEvent.target && myEvent.target.getBoundingClientRect) ? myEvent : undefined) : undefined,
    });
    this._lookupPopover.onDidDismiss().then(async (res) => {
      if (res.data && res.data.role && res.data.role == 'ok') {
        if (id == EventName.OPPORTUNITYSCRAPBUTTON) {
          this.opportunity.stateCode = 2;
          this.opportunity.state = 'Cancelled';
          this.opportunity.statusCode = 548910000;
          this.opportunity.status = this.opportunity.state;
          this.opportunity.opportunityReasonID = null;
        }else {
          this.opportunity.closedDate = new Date().getTime().toString();
          this.opportunity.stateCode = (id == 'closeaslost' || id == 'closeasusercanceled') ? 2 : 1;
          this.opportunity.state = this.opportunity.stateCode === 0 ? 'Open' : this.opportunity.stateCode === 1 ? 'Won' : 'Lost'
          this.opportunity.statusCode = id == 'closeaslost' ? 4 : (id == 'closeasusercanceled') ? 548910002 : 3;
          this.opportunity.status = this.opportunity.state;
        }

        this.isReadOnlyMode = true;
        this.opportunity.pendingPushToDynamics = true;
        if(res.data && res.data.selectedInputs && res.data.selectedInputs.length > 0){
          if(res.data.selectedOtherItem){
            this.opportunity.closeDescriptionText = res.data.selectedOtherItem || '';
          }
          if(res.data.selectedInputs[0]){
            this.opportunity.opportunityReasonID = res.data.selectedInputs[0].value;
            this.opportunity.opportunityReason = res.data.selectedInputs[0].name;
          }
          this.opportunity.opportunityCloseReasons = [];
          res.data.selectedInputs.forEach(reason=> {
            this.opportunity.opportunityCloseReasons.push({
              reasonId: reason.value,
              reasonName: reason.name,
            });
          });
        }else{
          if (res.data && (res.data.inputs || res.data.selectedOtherItem)) {
            this.opportunity.closeDescriptionText = res.data.selectedOtherItem || '';
            this.opportunity.opportunityReasonID = res.data.inputs?.value;
            this.opportunity.opportunityReason = res.data.inputs?.name;
          } else {
            this.opportunity.closeDescriptionText = '';
            this.opportunity.opportunityReasonID = null;
            this.opportunity.opportunityReason = null;
          }
        }
        
        
        this.uiService.displayLoader();
        await this.opportunityDataService.updateOpportunity({
          onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true,
          operationDetail: { code: id, message: 'Update_opportunity_status' }
        }, [this.opportunity]).then(success => {
          this.uiService.dismissLoader();
          if (id == EventName.OPPORTUNITYSCRAPBUTTON) {
            this.onClosePage();
          }
          else this.initPageTitle();
        }).catch(async error => {
          // Handle error occured
          this.uiService.dismissLoader();
        });

      }

    });
    this._lookupPopover.present();
  }

  private async refreshOpportunity() {
    if (this.device.isOffline) return;
    await this.uiService.displayLoader();
    await this.opportunityDataService.getOpportunitiesData(false, this.opportunity.accountID, false, false, this.opportunity.ID);
    if (this.selectedOpportunity.opportunityTypeId && !this.device.isOffline) {
      const selectedOpportunityType = this.opportunityService.opportunityTypes.find(a => a.indskr_opportunitytypeid == this.selectedOpportunity.opportunityTypeId);
      if (selectedOpportunityType && selectedOpportunityType.surveyTemplateId) {
        const foundTemplate = this.surveyService.surveyForms.find(form => form.surveyFormId == selectedOpportunityType.surveyTemplateId);
        if (foundTemplate) {
          await this.opportunityDataService.fetchSurveyJSAtrributesForOpportunity(this.selectedOpportunity, foundTemplate);
          const idx = this.opportunityService.opportunities.findIndex(value => value.ID === this.selectedOpportunity.ID
            || (this.selectedOpportunity.offlineId
              && this.selectedOpportunity.offlineId == value.offlineId));
          if (idx >= 0) {
            this.opportunityService.opportunities[idx].surveyResponse = this.selectedOpportunity.surveyResponse;
            this.opportunityService.opportunities[idx].surveyResponseData = this.selectedOpportunity.surveyResponseData;
            this.opportunityService.opportunities[idx].surveyResponseAdditionalData = this.selectedOpportunity.surveyResponseAdditionalData;
            this.opportunityService.opportunities[idx].surveyResponseLookupData = this.selectedOpportunity.surveyResponseLookupData;
          }
        }
      }
    }
    this.opportunityDataService.isSelectedOpportunityUpdated = true;
    await this.uiService.dismissLoader();
    this.initPageTitle();
    this.initOpportunityDetails();
    this.refreshOpportunityEvent(this.opportunity);
  }

  private async handleOpportunityStatusChange(id) {
    this.opportunity = this.opportunityService.getOpportunityDetailsbyId(this.opportunity.ID);
    const captitalSalesType = this.opportunityService.opportunityTypes.find(ot => ot.indskr_description == 'CAPITAL_SALES');
    const isCapitalSalesType = this.opportunity.opportunityTypename == 'Capital Sales'
      || (captitalSalesType && captitalSalesType.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
    if (id === EventName.OPPORTUNITYMARKCONFIRMED && this.opportunity.stakeholders.length > 0
      && this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_OPTIONSET_FIELDS)
      && isCapitalSalesType) {
      await this.uiService.displayLoader();
      if (!await this.opportunityDataService.isCustomerAssessmentResponseAvailable(this.opportunity.stakeholders)) {
        await this.uiService.dismissLoader();
        this.notificationService.notify(this.translate.instant("FILL_PROCEDURE_ASSESSMENT"), ToastStyle.INFO);
        return;
      }
      await this.uiService.dismissLoader();
    }
    let alertOptions: {
      header?: string,
      cssClass?: string,
      message: string,
      inputs?: any[]
    }
    let altConfirmText: string;
    let altCancelText: string = this.translate.instant('CANCEL');
    switch (id) {
      case EventName.OPPORTUNITYSCRAPBUTTON:
        alertOptions = {
          header: this.translate.instant('OPPORTUNITY_MGMT_SCRAP_TITLE'),
          message: this.translate.instant('OPPORTUNITY_MGMT_SCRAP_MESSAGE'),
          inputs: this.fetchOpportunityReasonsScrap(),
        }
        altConfirmText = this.translate.instant('SCRAP')
        break;
      case EventName.OPPORTUNITYMARKCONFIRMED:
        alertOptions = {
          header: this.translate.instant('CONFIRM_OPPORTUNITY'),
          message: this.translate.instant('CONFIRM_OPPORTUNITY_SUB_TEXT'),
        }
        altConfirmText = this.translate.instant('CONFIRM')
        break;
    }
    this.alertService.showAlert(alertOptions, altConfirmText, altCancelText)
      .then(async (res) => {
        if (res.role == 'ok') {
          if (id == EventName.OPPORTUNITYSCRAPBUTTON) {
            this.opportunity.stateCode = 2;
            this.opportunity.state = 'Cancelled';
            this.opportunity.statusCode = 548910000;
            this.opportunity.status = this.opportunity.state;
            this.isReadOnlyMode = true;
            this.opportunity.opportunityReasonID = null;
            if (res.data && (res.data.inputs || res.data.selectedOtherItem)) {
              this.opportunity.closeDescriptionText = res.data.selectedOtherItem || '';
              this.opportunity.opportunityReasonID = res.data.inputs?.value;
              this.opportunity.opportunityReason = res.data.inputs?.name;
            } else {
              this.opportunity.closeDescriptionText = '';
              this.opportunity.opportunityReasonID = null;
              this.opportunity.opportunityReason = null;
            }
          }
          else if (id == EventName.OPPORTUNITYMARKCONFIRMED) {
            this.opportunity.statusCode = 548910001;
          }
          else {
            this.opportunity.closeDescriptionText = res.data?.values?.description || '';
            this.opportunity.closedDate = new Date().getTime().toString();
            this.opportunity.stateCode = (id == 'closeaslost' || id == 'closeasusercanceled') ? 2 : 1;
            this.opportunity.state = this.opportunity.stateCode === 0 ? 'Open' : this.opportunity.stateCode === 1 ? 'Won' : 'Lost'
            this.opportunity.statusCode = id == 'closeaslost' ? 4 : (id == 'closeasusercanceled') ? 548910002 : 3;
            this.opportunity.status = this.opportunity.state;
            this.isReadOnlyMode = true;
          }
          this.opportunity.pendingPushToDynamics = true;
          this.uiService.displayLoader();
          if (id == EventName.OPPORTUNITYMARKCONFIRMED) {
            const selectedOpportunityType = this.opportunityService.opportunityTypes.find(a => a.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
            if (selectedOpportunityType && selectedOpportunityType.surveyTemplateId) {
              const foundTemplate = this.surveyService.surveyForms.find(form => form.surveyFormId == selectedOpportunityType.surveyTemplateId);
              if (foundTemplate) {
                await this.opportunityDataService.fetchSurveyJSAtrributesForOpportunity(this.opportunity, foundTemplate);
              }
            }
          }
          await this.opportunityDataService.updateOpportunity({
            onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true,
            operationDetail: { code: id, message: 'Update_opportunity_status' }
          }, [this.opportunity]).then(success => {
            //Handle successfull response
            // Calculation not required on app side // Account Plan Actual Revenue Calculation For Offline View Details
            // if(this.opportunity.accountPlanID && id == EventName.OPPORTUNITYCLOSEASWON){
            //   this.accountManagementDataService.updateEstimatedAndActualRevenueOfAccountPlans();
            // }
            this.uiService.dismissLoader();
            if (id == EventName.OPPORTUNITYSCRAPBUTTON) {
              this.onClosePage();
            }
            else this.initPageTitle();
          }).catch(async error => {
            // Handle error occured
            this.uiService.dismissLoader();
          });
        }

      })
  }


  private fetchOpportunityReasonsWon() {
    const opportunityReasonWon = this.opportunityService.opportunityReasons.filter(res => res.indskr_statusreason === "Won" && res.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
    if (_.isEmpty(opportunityReasonWon)) return []
    return opportunityReasonWon.map(res => {
      return {
        type: 'radio',
        name: res.indskr_reason,
        label: res.indskr_reason,
        value: res.indskr_opportunityreasonid,
        isSelected: false,
      }
    })
  }

  private fetchOpportunityReasonsLost() {
    const opportunityReasonLost = this.opportunityService.opportunityReasons.filter(res => res.indskr_statusreason === "Canceled" && res.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
    if (_.isEmpty(opportunityReasonLost)) return []
    return opportunityReasonLost.map(res => {
      return {
        type: 'radio',
        name: res.indskr_reason,
        label: res.indskr_reason,
        value: res.indskr_opportunityreasonid,
        isSelected: false,
      }
    })
  }
  private fetchOpportunityReasonsDiscontinue() {
    const opportunityReasonDiscontinue = this.opportunityService.opportunityReasons.filter(res => res.indskr_statusreason === "Discontinued" && res.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
    if (_.isEmpty(opportunityReasonDiscontinue)) return []
    return opportunityReasonDiscontinue.map(res => {
      return {
        type: 'radio',
        name: res.indskr_reason,
        label: res.indskr_reason,
        value: res.indskr_opportunityreasonid,
        isSelected: false,
      }
    })
  }

  private fetchOpportunityReasonsScrap() {
    const opportunityReasonLost = this.opportunityService.opportunityReasons.filter(res => res.indskr_statusreason === "Scrapped" && res.indskr_opportunitytypeid == this.opportunity.opportunityTypeId);
    if (_.isEmpty(opportunityReasonLost)) return []
    return opportunityReasonLost.map(res => {
      return {
        type: 'radio',
        name: res.indskr_reason,
        label: res.indskr_reason,
        value: res.indskr_opportunityreasonid,
        isSelected: false,
      }
    })
  }

  private async openUsersList() {
    let data = [];
    if (this.procedureTrackerDataService.users.length) {
      data = this.procedureTrackerDataService.users.filter((user) => user.userId != this.authService.user.xSystemUserID && user.userType && user.userType.includes(UserTypes.SALES_REP)).map(user => {
        return {
          id: user.userId,
          title: user.userFullName
        }
      })
    }

    data = _.orderBy(data, 'title');
    
    const productManagerModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: data,
          isMultipleSelectionEnabled: false,
          title: this.translate.instant('USERS'),
          dividerTitle: this.translate.instant('ALL_USERS'),
          isSearchEnabled: true,
          confirmationCheckRequired: true,
          confirmationAlertData: {
            title: this.translate.instant('ARE_YOU_SURE'),
            message:this.translate.instant('ASSIGN_OWNER_WARNING'),
            confirmButtonText: this.translate.instant('ASSIGN'),
            cancelButtonText: this.translate.instant('CANCEL')
          },
          needcustomMessage: true
        }
      },
      backdropDismiss: false
    });
    productManagerModal.present();
    productManagerModal.onDidDismiss().then(async (obj) => {
      if (obj && obj.data && obj.data.isDone) {
        const data = obj.data;
        if (_.isEmpty(data.selectedItems)) return
        this.opportunity.ownerID = data.selectedItems[0].id;
        this.opportunity.opportunityName = this.setOpportunityDescription(this.opportunity,data)
        this.uiService.displayLoader();
        await this.opportunityDataService.updateOpportunity({
          onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true,
          operationDetail: { code: EventName.ASSIGN_OPPORTUNITY_OWNER, message: 'updateOwner' }
        }, [this.opportunity]).then(success => {
          this.opportunityService.removeOpportuntiy(this.opportunity);
          this.onClosePage();
          this.uiService.dismissLoader();
        }).catch(() => {
          this.uiService.dismissLoader();
        });
      }
    })
  }

  setOpportunityDescription(opportunity, data) {
    let opportunityName = '';
    if (opportunity.estimatedDate) {
      opportunityName = new Date(parseInt(opportunity.estimatedDate)).getFullYear().toString();
    }

    if (opportunity.products.length > 0) {
      opportunityName = opportunityName === '' ? opportunity.products[0].productName : `${opportunityName} - ${opportunity.products[0].productName}`;
    }

    if (opportunity.accountName) {
      opportunityName = opportunityName === '' ? opportunity.accountName : `${opportunityName} - ${opportunity.accountName}`;
    }

    if (opportunity.stakeholders.length > 0) {
      if(opportunity.stakeholders.length > 0){
        switch (opportunity.stakeholders.length) {
          case 0:
            break;
          case 1:
            opportunityName = opportunityName === '' ? opportunity.stakeholders[0].fullName  : `${opportunityName} - ${opportunity.stakeholders[0].fullName}`;
            break;
          default:
            if (opportunity.stakeholders.length > 1) {
              opportunity.stakeholders.sort((contactA, contactB): number => {
                let contactAFullName = (contactA.firstName.length > 0) ? contactA.firstName + " " + contactA.lastName : contactA.lastName;
                let contactBFullName = (contactB.firstName.length > 0) ? contactB.firstName + " " + contactB.lastName : contactB.lastName;
                if (contactAFullName.toLowerCase() > contactBFullName.toLowerCase()) return 1;
                if (contactAFullName.toLowerCase() < contactBFullName.toLowerCase()) return -1;
                return 0;
              });
              let customerName = `${opportunity.stakeholders[0].fullName} + ${opportunity.stakeholders.length - 1} `
              opportunityName =  opportunityName === '' ?  customerName : `${opportunityName} - ${customerName}`;
            }
        }
      }
    }

    opportunityName = opportunityName == '' ? data.selectedItems[0].title : `${opportunityName} - ${data.selectedItems[0].title}`;
    return opportunityName;
  }

  private async handleEditActualValue (id,data,eventName){
    if (!this.isReadOnlyMode && !this.opportunity.isrevenuesystemcalculated) {
      if (data && parseInt(data.target.value) != this.opportunity.closedValue && parseInt(data.target.value) >= 0 && !data.target.value.includes('.')) {
        this.opportunity.closedValue = parseInt(data.target.value);
        this.opportunity.closedValueFormatted = this.opportunity.transactionCurrencySymbol + Utility.getFormattedNumber(this.opportunity.closedValue);
        this.opportunity.pendingPushToDynamics = true;
        if(this.actualValueFormFieldForPopover){
          this.actualValueFormFieldForPopover.inputText = this.opportunity.closedValueFormatted;
        }
        // this.uiService.displayLoader();
        // await this.opportunityDataService.updateOpportunity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true, operationDetail: { code: 'updateactualvalue', message : 'Update Opportunity Actual Value'} }, [this.opportunity]).then(success => {
        //   //Handle successfull response
        //   this.uiService.dismissLoader();
        // }).catch(async error => {
        //   // Handle error occured
        //   this.uiService.dismissLoader();
        // });
      } else {
        //this.initOpportunityInfoFormFields();
      }
    }
  }

}
