import { IAllocationAdjustment } from './../../interfaces/allocation/allocation-adjustment.interface';
import { IAllocationTransfer } from './../../interfaces/allocation/allocation-transfer.interface';
import { IAllocationShipment, IAllocationShipmentAndTransferBase } from './../../interfaces/allocation/allocation-shipment.interface';
import { Injectable, NgZone, Injector } from '@angular/core';
import { SampleAllocation, SampleAllocationMetas } from '../../classes/sample/sample-allocation.class';
import { CustomerSampleAllocation,  } from '../../classes/sample/customer-sample-allocation.class';
import { Lot } from '../../classes/sample/lot.class';
import { SampleAllocationDTO, CustomerSampleAllocationDTO, LotDTO, ValidatedCustomerLicense } from '../../models/sample-model';
import { isWithinRange, isBefore, compareDesc } from 'date-fns';
import { DB_KEY_PREFIXES, DB_ALLDOCS_QUERY_OPTIONS, PREFIX_SEARCH_ENDKEY_UNICODE } from '../../config/pouch-db.config';
import { DiskService } from '../disk/disk.service';
import { AuthenticationService } from '../authentication.service';
import { ContactAddress } from '../../classes/contact/contact.class';
import { SampleActivity } from '../../classes/activity/sample.activity.class';
import { Utility } from '../../utility/util';
import { FeatureActionsMap } from '../../classes/authentication/user.class';
import { CustomerLicenseDTO, CustomerLicense, CUSTOMER_LICENSE_TYPE } from '../../classes/sampling/customer-license.class';
import { TrackAction, StatusCode } from './../../enums/shared-enums';
import { AllocationAdjustService } from './allocation-adjust.service';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SampleService {
    public contactTimelineFilter: string = "All_Activities";  //bug cwd-3450
    public selectedSampleSKU:SampleAllocationMetas;
    public allocationOrdersSearchStr:string = '';
    public selectedFilterList: { text: string, value: string }[] = [];

    sampleAllocations: Array<SampleAllocation>;
    contactCustomerSampleAllocations: Array<{
            contactId: string;
            contactName: string;
            currentCustomerSampleAllocations: CustomerSampleAllocation[]
        }>;
    lots: Array<Lot>;
    samplingDetailsViewMode: SamplingDetailsViewMode = 3;
    inMeetingAllocationActivity: SampleActivity;
    multiProductSelectionFilter = [];
    customerLicenses: CustomerLicense[];
    validatedLicenses: ValidatedCustomerLicense[];
    lotsLastSynTime: number;
    isHandleElligibleProductSelect = false;
    skusSampleUnitPrice: any[] = []

    constructor(
        private disk: DiskService,
        private _ngZone: NgZone,
        public authenticationService: AuthenticationService,
        private injector: Injector
    ) {
        this._reset();
    }

    public async _addToLotTotalQuantityDetail(shipment: IAllocationShipment | IAllocationTransfer | IAllocationAdjustment) {
      const lotId: string = shipment.indskr_lotid || shipment.at_indskr_lotid || null;
      const quantityReceived: number = (shipment as IAllocationShipmentAndTransferBase).indskr_quantityreceived
                                        || (shipment as IAllocationAdjustment).indskr_quantityadjusted || null;
        if (lotId && quantityReceived) {
            let foundLotDetail = this.lots.find(lot => lot.id == lotId);
            if(foundLotDetail){
                foundLotDetail.totalQuantityReceived = parseInt(foundLotDetail.totalQuantityReceived.toString()) + parseInt(quantityReceived.toString());

                if (foundLotDetail.totalQuantityRemaining) {
                   foundLotDetail.totalQuantityRemaining = parseInt(foundLotDetail.totalQuantityRemaining.toString()) + parseInt(quantityReceived.toString());
                await this.updateOrInsertLotQuantityDetailToOfflineDB(foundLotDetail);
                } else {
                  foundLotDetail.totalQuantityRemaining = 0;
                  foundLotDetail.totalQuantityRemaining = parseInt(foundLotDetail.totalQuantityRemaining.toString()) + parseInt(quantityReceived.toString());
                  await this.updateOrInsertLotQuantityDetailToOfflineDB(foundLotDetail);
                }

              if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT)) {
                const now = new Date().getTime();
                let adjustedDate = new Date(new Date((new Date().setDate(new Date().getDate() - this.authenticationService.user.allocationAdjustmentDuration))).setHours(0, 0, 0, 0))

                if ((isWithinRange(new Date(), foundLotDetail.validFrom, foundLotDetail.validTo) || isWithinRange(adjustedDate, foundLotDetail.validFrom, foundLotDetail.validTo) || compareDesc(adjustedDate, foundLotDetail.validTo) !== -1)&& foundLotDetail.status == 0) {
                  const adjustmentService = this.injector.get<AllocationAdjustService>(AllocationAdjustService);
                  let index = adjustmentService.lots.findIndex((adjustment) => adjustment.id === foundLotDetail.id)
                  if (index > -1) {
                    adjustmentService.lots[index] = foundLotDetail;
                  } else {
                    adjustmentService.lots.push(foundLotDetail);
                  }

                  index = adjustmentService.allLots.findIndex((adjustment) => adjustment.id === foundLotDetail.id)
                  if (index > -1) {
                    adjustmentService.allLots[index] = foundLotDetail;
                  } else {
                    adjustmentService.allLots.push(foundLotDetail);
                  }

                }
              }

            }else {
                // Probably transfer of product that the user doesn't have position mapping.
                // Temporary walk-around before the service changes.
                // Once service changes, not supposed to fall here theoretically.
                const newLot = new Lot({
                    indskr_lotid: lotId,
                    name: shipment.indskr_lotname || shipment.at_indskr_lotname,
                    indskr_lotnumber: shipment.indskr_lotname || shipment.at_indskr_lotname,
                    indskr_skuid: shipment.indskr_skuid || shipment.at_indskr_skuid,
                    indskr_skuname: shipment.indskr_skuname || shipment.at_indskr_skuname,
                    indskr_lotvalidfromdate: shipment.indskr_lotvalidfromdate || shipment.at_indskr_lotvalidfromdate,
                    indskr_lotvalidtodate: shipment.indskr_lotvalidtodate || shipment.at_indskr_lotvalidtodate,
                    indskr_totalquantitydropped: 0,
                    indskr_totalquantityrecieved: quantityReceived,
                    indskr_totalquantityremaining: quantityReceived,
                    statecode: 0
                });

                await this.updateOrInsertLotQuantityDetailToOfflineDB(newLot);
                if (isWithinRange(new Date(), newLot.validFrom, newLot.validTo)) {
                    this.lots.push(newLot);
                }
              if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT)) {
                const now = new Date().getTime();
                let adjustedDate = new Date(new Date((new Date().setDate(new Date().getDate() - this.authenticationService.user.allocationAdjustmentDuration))).setHours(0, 0, 0, 0))

                if ((isWithinRange(new Date(), newLot.validFrom, newLot.validTo) || isWithinRange(adjustedDate, newLot.validFrom, newLot.validTo) || compareDesc(adjustedDate, newLot.validTo) !== -1) && newLot.status == 0) {
                  const adjustmentService = this.injector.get<AllocationAdjustService>(AllocationAdjustService);
                  adjustmentService.lots.push(newLot);
                  adjustmentService.allLots.push(newLot);
                }
              }
            }
        }
    }

    private updateLotsForAdjustments(foundLot: Lot) {
        const adjustmentService = this.injector.get<AllocationAdjustService>(AllocationAdjustService);

        let index = adjustmentService.lots.findIndex(aLot => foundLot.id === aLot.id)
        if (index > -1) {
          adjustmentService.lots[index] = foundLot
        }

         index = adjustmentService.allLots.findIndex(aLot => foundLot.id === aLot.id)
        if (index > -1) {
          adjustmentService.allLots[index] = foundLot
        }
      }

    public async _addToLotDroppedQuantityDetail(sampleActivity: SampleActivity) {
        if(sampleActivity.getStatusString == 'Completed'){
            sampleActivity.samples.forEach(sampleDrop => {
                if(!sampleDrop.deleted && !sampleDrop.isInvalid){
                    sampleDrop.lots.forEach(async (lotValue) => {
                        if(!lotValue.deleted){
                            let foundLotDetail = this.lots.find(lot => lot.id == lotValue.indskr_lotid);
                            if(foundLotDetail && foundLotDetail.totalQuantityRemaining >= lotValue.indskr_quantity){
                                foundLotDetail.totalQuantityDropped = parseInt(foundLotDetail.totalQuantityDropped.toString()) + parseInt(lotValue.indskr_quantity.toString());
                                foundLotDetail.totalQuantityRemaining = foundLotDetail.totalQuantityRemaining - lotValue.indskr_quantity;
                                this.updateLotsForAdjustments(foundLotDetail);
                                await this.updateOrInsertLotQuantityDetailToOfflineDB(foundLotDetail);
                            }else {
                                console.log("Didnt found any lot to update dropped Quantity for "+lotValue.indskr_lotname);
                                // To Do error handling
                            }
                        }
                    })
                }
            })
        }
    }

    async updateOrInsertLotQuantityDetailToOfflineDB(lot: Lot, doNotUpdateLastUpdatedTime = false) {
        await this._ngZone.runOutsideAngular(async () => {
            let lotObj = lot.DTO;
            lot._id = DB_KEY_PREFIXES.LOT + lot.id
            if (!doNotUpdateLastUpdatedTime) {
                const newLastUpdatedTime = new Date().getTime();
                lot.lastUpdatedTime = newLastUpdatedTime;
            }

            try {
                const doc = await this.disk.retrieve(lot._id, true);
                if (doc) {
                    lot['_rev'] = doc['_rev'];
                }
            } catch (error) {
                console.error('errorRetreivingLotQuantityDetailsFromDB', error);
                //TODO: handle error..
            }

            //Save Lot Quantity Detail to db
            try {
                await this.disk.updateOrInsert(DB_KEY_PREFIXES.LOT + lot.id, (doc) => {
                    return lotObj;
                });
            } catch (error) {
                console.error('errorSavingLotQuantityDetailsToDB', error);
                //TODO: handle error..
            }
        });
    }

    public async updateSampleEligibilities(sampleActivity: SampleActivity, errorDetails): Promise<any> {

      let foundCustomerAllocations: Array<CustomerSampleAllocation> = this.contactCustomerSampleAllocations.find(o => o.contactId == sampleActivity.contactID).currentCustomerSampleAllocations;
      return new Promise<void>((resolve, reject) => {
        if (foundCustomerAllocations && foundCustomerAllocations.length > 0) {
          let dbPrefix: string = '';
          let dbKey: string = '';
          dbPrefix = DB_KEY_PREFIXES.CUST_SAMPLE_ALLOC;
          if (!errorDetails) {
            foundCustomerAllocations.map(async allocation => {
              let foundSampleDropList = sampleActivity.samples.filter(sample => sample.indskr_customersampleproductid == allocation.id);
              if (foundSampleDropList && foundSampleDropList.length > 0) {
                let foundSamplesTotalQuantity = 0;
                let totalSampleValues = 0;
                foundSampleDropList.forEach(foundSampleDrop => {
                  foundSamplesTotalQuantity += foundSampleDrop.totalQuantity ? foundSampleDrop.totalQuantity : 0;
                  if (!allocation.allowvaluelimitunblock && allocation.totalsamplevaluelimit > 0 && !_.isEmpty(foundSampleDrop.lots)) {
                    foundSampleDrop.lots.forEach(lot => {
                      if (lot.indskr_unitprice) {
                        lot.indskr_totalsamplevalue = lot.indskr_unitprice * lot.indskr_quantity;
                        totalSampleValues += lot.indskr_totalsamplevalue;
                      }
                    });
                  }
                })
                if (allocation.totalsamplevaluelimit > 0 || (!allocation.isUnlimited && allocation.remaining >= foundSamplesTotalQuantity)) {
                  let allocationDoc;
                  let isErrorOccured: boolean = false;
                  dbKey = dbPrefix + allocation.id;
                  try {
                    allocationDoc = await this.disk.retrieve(dbKey, true);
                  }
                  catch (err) {
                    console.log('Error occured while fetching eligibilities from offline doc' + err);
                    isErrorOccured = true;
                  }
                  if (!isErrorOccured && allocationDoc) {
                    if ((!allocation.isUnlimited && allocation.remaining >= foundSamplesTotalQuantity)) {
                      allocation.remaining = allocation.remaining - foundSamplesTotalQuantity;
                      allocation.dropped = allocation.dropped + foundSamplesTotalQuantity;
                      allocationDoc['indskr_totalsamplesremaining'] = allocation.remaining - foundSamplesTotalQuantity;
                      allocationDoc['indskr_totalsamplesdropped'] = parseInt(allocation.dropped.toString()) + parseInt(foundSamplesTotalQuantity.toString());
                    }
                    if (totalSampleValues > 0 && allocation.totalsamplevaluelimit > 0 && (allocation.allowvaluelimitunblock || (allocation.totalsamplevaluelimit - allocation.indskr_totalsamplevaluedelivered) >= totalSampleValues)) {
                      allocation.indskr_totalsamplevaluedelivered += totalSampleValues;
                      allocationDoc.indskr_totalsamplevaluedelivered = allocation.indskr_totalsamplevaluedelivered;
                    }
                    
                    try {
                      if (allocationDoc && allocationDoc._id && allocationDoc._rev) {
                        await this.disk.updateOrInsert(allocationDoc._id, (doc) => {
                          return allocationDoc;
                        });
                        resolve();
                      }
                    } catch (err) {
                      console.log('Error occured while saving eligibilities in offline doc' + err);
                      reject();
                    }
                  }
                }
              }
            });
          } else if (Array.isArray(errorDetails)) {
            errorDetails.map(value => {
              if (value['indskr_customersampleproductid'] && (value['indskr_totalsamplesremaining'] || value['indskr_totalsamplevaluedelivered'])) {
                foundCustomerAllocations.map(async allocation => {
                  if (allocation.id == value['indskr_customersampleproductid']) {
                    let allocationDoc;
                    let isErrorOccured: boolean = false;
                    dbKey = dbPrefix + allocation.id;
                    try {
                      allocationDoc = await this.disk.retrieve(dbKey, true);
                    }
                    catch (err) {
                      console.log('Error occured while fetching eligibilities from offline doc' + err);
                      isErrorOccured = true;
                    }
                    if (!isErrorOccured && allocationDoc) {
                      if (value['indskr_totalsamplesremaining']) {
                        allocation.remaining = value['indskr_totalsamplesremaining'];
                        allocationDoc['indskr_totalsamplesremaining'] = value['indskr_totalsamplesremaining']
                      }
                      if (value['indskr_totalsamplevaluedelivered']) {
                        allocation.indskr_totalsamplevaluedelivered = value['indskr_totalsamplevaluedelivered'];
                        allocationDoc['indskr_totalsamplevaluedelivered'] = value['indskr_totalsamplevaluedelivered']
                      }
                      try {
                        if (allocationDoc && allocationDoc._id && allocationDoc._rev) {
                          await this.disk.updateOrInsert(allocationDoc._id, (doc) => {
                            return allocationDoc;
                          });
                          resolve();
                        }
                      } catch (err) {
                        reject();
                        console.log('Error occured while saving eligibilities in offline doc' + err);
                      }
                    }
                  }
                });
              }
            });
          }
        }
      })
    }

    /*async saveFullSyncedSampleAllocations(rawSampleAllocations: SampleAllocationDTO[], forceFullSync = false) {
        await this._ngZone.runOutsideAngular(async () => {
            if (rawSampleAllocations && Array.isArray(rawSampleAllocations)) {
                const newLastUpdatedTime = new Date().getTime();

                for(let i = 0; i < rawSampleAllocations.length; i++) {
                    const rawSampleAllocation = rawSampleAllocations[i];
                    rawSampleAllocation._id = DB_KEY_PREFIXES.SAMPLE_ALLOCATION + rawSampleAllocation.indskr_skuid;
                    rawSampleAllocation.lastUpdatedTime = newLastUpdatedTime;
                }

                if (forceFullSync) {
                    try {
                        await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_SAMPLE_ALLOCATIONS);
                    } catch (error) {
                        console.error('saveFullSyncSampleAllocations: ', error);
                        // TODO: handle error..
                    }
                }

                // Save Sample Allocations to db.
                try {
                    await this.disk.bulk(rawSampleAllocations);
                } catch (error) {
                    console.error('saveFullSyncSampleAllocations: ', error);
                    // TODO: handle error..
                }
            } else {
                console.error('saveFullSyncSampleAllocations: Invalid raw data provided');
                // TODO: handle error..
            }
        });
    }*/

    async saveFullSyncedCustomerSampleAllocations(rawCustSampleAllocs: CustomerSampleAllocationDTO[], forceFullSync = false) {
        await this._ngZone.runOutsideAngular(async () => {
            if (rawCustSampleAllocs && Array.isArray(rawCustSampleAllocs)) {
                const newLastUpdatedTime = new Date().getTime();

                for (let i = 0; i < rawCustSampleAllocs.length; i++) {
                    const rawCustSampleAlloc = rawCustSampleAllocs[i];
                    rawCustSampleAlloc._id = DB_KEY_PREFIXES.CUST_SAMPLE_ALLOC + rawCustSampleAlloc.indskr_customersampleproductid;
                    rawCustSampleAlloc.lastUpdatedTime = newLastUpdatedTime;
                }

                if (forceFullSync) {
                    try {
                        await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUSTOMER_SAMPLE_ALLOCATIONS);
                    } catch (error) {
                        console.error('saveFullSyncCustomerSampleAllocations: ', error);
                        // TODO: handle error..
                    }
                }

                // Save Sample Allocations to db.
                try {
                    await this.disk.bulk(rawCustSampleAllocs);
                } catch (error) {
                    console.error('saveFullSyncCustomerSampleAllocations: ', error);
                    // TODO: handle error..
                }
                this.loadAllContactCustomerAllocationsFromDBAndMap(rawCustSampleAllocs);
            } else {
                console.error('saveFullSyncCustomerSampleAllocations: Invalid raw data provided');
                // TODO: handle error..
            }
        });
    }

    async saveSKUSampleUnitPrice(skusSampleUnitPrice) {
      this.skusSampleUnitPrice = skusSampleUnitPrice;
      if (!_.isEmpty(skusSampleUnitPrice)) {
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.SKU_SAMPLE_UNIT_PRICE, (doc) => {
          doc = {
            raw: []
          };
          doc.raw = skusSampleUnitPrice;
          return doc;
        });
      }
    }

    async loadSKUSampleUnitPrice() {
      await this.disk.retrieve(DB_KEY_PREFIXES.SKU_SAMPLE_UNIT_PRICE).then((doc) => {
        this.skusSampleUnitPrice = doc ? doc.raw : [];
      }).catch((er) => {
        console.error("Failed to fetch SKUSampleUnitPrice, ", er);
        this.skusSampleUnitPrice = [];
      })
    }

    async saveFullSyncedLots(rawLots: LotDTO[], forceFullSync = false) {
        await this._ngZone.runOutsideAngular(async () => {
            if (rawLots && Array.isArray(rawLots)) {
                const newLastUpdatedTime = new Date().getTime();

                rawLots.map(rawLot => {
                    rawLot._id = DB_KEY_PREFIXES.LOT + rawLot.indskr_lotid;
                    rawLot.lastUpdatedTime = newLastUpdatedTime;
                });

                if (forceFullSync) {
                    try {
                        await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_LOTS);
                    } catch (error) {
                        console.error('saveFullSyncedLots: ', error);
                        // TODO: handle error..
                    }
                }

                // Save Sample Allocations to db.
                try {
                    await this.disk.bulk(rawLots);
                } catch (error) {
                    console.error('saveFullSyncedLots: ', error);
                    // TODO: handle error..
                }
            } else {
                console.error('saveFullSyncedLots: Invalid raw data provided');
                // TODO: handle error..
            }
        });
    }

    async saveDeltaSyncedCustomerSampleAllocations(rawCustSampleAllocs: CustomerSampleAllocationDTO[], deltaIds: { addedOrUpdated: string[], deleted: Array<{ id: string, contactId: string }> }) {
        if (rawCustSampleAllocs && Array.isArray(rawCustSampleAllocs)) {
            const newLastUpdatedTime = new Date().getTime();
            const idxOfRecordNotToBeSaved = [];

            for (let i = 0; i < rawCustSampleAllocs.length; i++) {
              const rawCustSampleAlloc = rawCustSampleAllocs[i];
              // Overwrite if exists
              rawCustSampleAlloc._id = DB_KEY_PREFIXES.CUST_SAMPLE_ALLOC + rawCustSampleAlloc.indskr_customersampleproductid;
              rawCustSampleAlloc.lastUpdatedTime = newLastUpdatedTime;

              const doc = await this.disk.retrieve(rawCustSampleAlloc._id, true);
              if (doc) {
                rawCustSampleAlloc['_rev'] = doc['_rev'];
              }

              if (rawCustSampleAlloc.track_action && rawCustSampleAlloc.track_action === TrackAction.Deleted) {
                // Deleted
                if (rawCustSampleAlloc['_rev']) {
                  // If _rev exists, it means it's in our local db and we need to delete from it.
                  rawCustSampleAlloc['_deleted'] = true;
                  deltaIds.deleted.push({ id: rawCustSampleAlloc.indskr_customersampleproductid, contactId: doc ? doc['indskr_contactid'] : null });
                } else {
                  // If fell here, it means it's a repetitive data that we alerady deleted from our local db.
                  idxOfRecordNotToBeSaved.push(i);
                }
              } else {
                if (rawCustSampleAlloc.indskr_startdate && rawCustSampleAlloc.indskr_enddate) {
                  deltaIds.addedOrUpdated.push(rawCustSampleAlloc._id);
                }
              }
            }

            // Remove already deleted records from the raw data array. No need to save to local db.
            for (let i = idxOfRecordNotToBeSaved.length - 1; i >= 0; i--) {
                const idx = idxOfRecordNotToBeSaved[i];
                rawCustSampleAllocs.splice(idx, 1);
            }

            // Save Sample Allocations to db.
            try {
                await this.disk.bulk(rawCustSampleAllocs);
            } catch (error) {
                console.error('saveDeltaSyncCustomerSampleAllocations: ', error);
                // TODO: handle error..
            }
        } else {
            console.error('saveDeltaSyncCustomerSampleAllocations: Invalid raw data provided');
            // TODO: handle error..
        }
    }

    async saveDeltaSyncedLots(rawLots: LotDTO[], deltaIds: string[]) {
        if (rawLots && Array.isArray(rawLots)) {
            const newLastUpdatedTime = new Date().getTime();

            for (let i = 0; i < rawLots.length; i++) {
                const rawLot = rawLots[i];
                rawLot._id = DB_KEY_PREFIXES.LOT + rawLot.indskr_lotid;
                rawLot.lastUpdatedTime = newLastUpdatedTime;

                const doc = await this.disk.retrieve(rawLot._id, true);
                if (doc) {
                    rawLot['_rev'] = doc['_rev'];
                }

                deltaIds.push(rawLot._id);
            }

            // Save Sample Allocations to db.
            try {
              if (rawLots.length > 0) {
                await this.disk.bulk(rawLots);
              }
            } catch (error) {
                console.error('saveDeltaSyncedLots: ', error);
                // TODO: handle error..
            }
        } else {
            console.error('saveDeltaSyncedLots: Invalid raw data provided');
            // TODO: handle error..
        }
    }

  

    async loadAllContactCustomerAllocationsFromDBAndMap(rawCustomerSampleAllocations: CustomerSampleAllocationDTO[], loadFromDB = false) {
      // Map object structure of Contact -> CustomerSampleAllocations
      this.contactCustomerSampleAllocations = [];
      const option = {
        selector: {
          '_id': {
            $gte: DB_KEY_PREFIXES.CUST_SAMPLE_ALLOC,
            $lte: DB_KEY_PREFIXES.CUST_SAMPLE_ALLOC + PREFIX_SEARCH_ENDKEY_UNICODE
          },
        }
      };
      try {
        if(loadFromDB)
          rawCustomerSampleAllocations = await this.disk.find(option);

        if (rawCustomerSampleAllocations && Array.isArray(rawCustomerSampleAllocations)) {
          this.contactCustomerSampleAllocations = rawCustomerSampleAllocations.reduce((acc, rawCustomerSampleAllocation) => {
            const idx = acc.findIndex(contact => contact.contactId === rawCustomerSampleAllocation.indskr_contactid);
            let csa = new CustomerSampleAllocation(rawCustomerSampleAllocation);
            const now = new Date();
            try {
              if (isWithinRange(now, csa.startDate, csa.endDate)) {
                if (idx >= 0) {
                  //if(isWithinRange(now, csa.startDate,csa.endDate)){
                  acc[idx].currentCustomerSampleAllocations.push(csa);
                  //}
                } else {
                  acc.push({
                    contactId: rawCustomerSampleAllocation.indskr_contactid,
                    contactName: rawCustomerSampleAllocation.indskr_contactname,
                    currentCustomerSampleAllocations: [
                      csa
                    ]
                  });
                }
              }
            } catch (error) {
              console.error('loadContactCustomerAllocationsFromDBAndMap: ', error);
            }
            return acc;
          }, []);
        }
      } catch (error) {
        console.error('loadContactCustomerAllocationsFromDBAndMap: ', error);
        // TODO: handle error..
      }
    }

    async loadAllLotsFromDBAndMap(forceReload = false) {
        // if (this.lots.length !== 0 && !forceReload) {
        //     return;
        // }

        this.lots = [];

        const now = new Date().getTime();
        const option = {
            selector: {
                '_id': {
                    $gte: DB_KEY_PREFIXES.LOT,
                    $lte: DB_KEY_PREFIXES.LOT + PREFIX_SEARCH_ENDKEY_UNICODE
                },
            }
        };

        try {
            const rawLots: LotDTO[] = await this.disk.find(option);

            if (rawLots && Array.isArray(rawLots)) {
                rawLots.map(rawLot => {
                  let newLot = new Lot(rawLot);
                  if (isWithinRange(new Date(), newLot.validFrom, newLot.validTo)) {
                    this.lots.push(newLot);
                  }
                });
            }
        } catch (error) {
            console.error('loadLotsFromDBAndMap: ', error);
            // TODO: handle error..
        }
    }

    async loadAndMapNewDeltaToSampleAndCustomerAllocations(deltaSampleAllocIds: string[], deltaCustSampleAllocIds: string[]) {
        try {
            // Upsert delta sample allocations to 'sampleAllocations'
            if (deltaSampleAllocIds && Array.isArray(deltaSampleAllocIds) && deltaSampleAllocIds.length > 0) {
                const now = new Date().getTime();
                const option = {
                    selector: {
                        '_id': {
                            $in: deltaSampleAllocIds
                        },
                        indskr_startdate: {
                            $lte: '' + now
                        },
                        indskr_enddate: {
                            $gte: '' + now
                        },
                    }
                };
                const rawSampleAllocations: SampleAllocationDTO[] = await this.disk.find(option);
                if (rawSampleAllocations && Array.isArray(rawSampleAllocations)) {
                    for (let i = 0; i < rawSampleAllocations.length; i++) {
                        const rawSampleAllocation = rawSampleAllocations[i];
                        const newSampleAllocation = new SampleAllocation(rawSampleAllocation);

                        const findIndex = this.sampleAllocations.findIndex((sa) => sa._id === newSampleAllocation._id);
                        if (findIndex >= 0) {
                            // Existing record is updated. Update objects
                            newSampleAllocation.currentCustomerSampleAllocations = JSON.parse(JSON.stringify(this.sampleAllocations[findIndex].currentCustomerSampleAllocations));
                            newSampleAllocation.totalRemaining = this.sampleAllocations[findIndex].totalRemaining;
                            this.sampleAllocations[findIndex] = newSampleAllocation;
                        } else {
                            // New record. Add to the list
                            this.sampleAllocations.push(newSampleAllocation);
                        }
                    }
                } else {
                    console.error('loadAndMapNewDeltaToSampleAndCustomerAllocations: Invalid raw data provided for sample allocation');
                    // TODO: handle error..
                }
            }

            if (deltaCustSampleAllocIds && Array.isArray(deltaCustSampleAllocIds) && deltaCustSampleAllocIds.length > 0) {
                // Upsert delta customer sample allocations to 'sampleAllocations'
                const now = new Date().getTime();
                const queryOption = {
                    selector: {
                        '_id': {
                            $in: deltaCustSampleAllocIds
                        },
                        indskr_startdate: {
                            $lte: '' + now
                        },
                        indskr_enddate: {
                            $gte: '' + now
                        },
                    }
                };
                const rawCustSampleAllocations: CustomerSampleAllocationDTO[] = await this.disk.find(queryOption);
                if (rawCustSampleAllocations && Array.isArray(rawCustSampleAllocations)) {

                    const reducedBySkuId = rawCustSampleAllocations.reduce((acc, rawCustSampleAllocation) => {
                        acc[rawCustSampleAllocation.indskr_skuid] || (acc[rawCustSampleAllocation.indskr_skuid] = []);
                        acc[rawCustSampleAllocation.indskr_skuid].push(rawCustSampleAllocation);
                        return acc;
                    }, []);

                    for (const skuId in reducedBySkuId) {
                        if (reducedBySkuId.hasOwnProperty(skuId)) {

                            const sampleIndex = this.sampleAllocations.findIndex((sa) => sa.sampleSKUId === skuId);
                            if (sampleIndex >= 0) {
                                if (reducedBySkuId[skuId] && Array.isArray(reducedBySkuId[skuId])) {
                                    for (let i = 0; i < reducedBySkuId[skuId].length; i++) {
                                        const rawCustomerSampleAllocation: CustomerSampleAllocationDTO = reducedBySkuId[skuId][i];
                                        const custSampleIndex = this.sampleAllocations[sampleIndex].currentCustomerSampleAllocations
                                                                    .findIndex((csa) => csa.id === rawCustomerSampleAllocation.indskr_customersampleproductid);
                                        if (custSampleIndex >= 0) {
                                            // Replace existing customer allocation
                                            const oldRemaining = this.sampleAllocations[sampleIndex].currentCustomerSampleAllocations[custSampleIndex].remaining;
                                            this.sampleAllocations[sampleIndex].currentCustomerSampleAllocations[custSampleIndex] = new CustomerSampleAllocation(rawCustomerSampleAllocation);
                                            const remainingDifference = this.sampleAllocations[sampleIndex].currentCustomerSampleAllocations[custSampleIndex].remaining - oldRemaining;
                                            this.sampleAllocations[sampleIndex].totalRemaining + remainingDifference;
                                        } else {
                                            // Add new customer allocation
                                            const newCustSampleAlloc = new CustomerSampleAllocation(rawCustomerSampleAllocation);
                                            this.sampleAllocations[sampleIndex].currentCustomerSampleAllocations.push(newCustSampleAlloc);
                                            this.sampleAllocations[sampleIndex].totalRemaining + newCustSampleAlloc.remaining;
                                        }
                                    }
                                }
                            } else {
                                console.error('loadAndMapNewDeltaToSampleAndCustomerAllocations: No Sample Allocation found for id: ', reducedBySkuId[skuId].indskr_skuid);
                            }
                        }
                    }
                } else {
                    console.error('loadAndMapNewDeltaToSampleAndCustomerAllocations: Invalid raw data provided for customer sample allocation');
                    // TODO: handle error..
                }
            }
        } catch (error) {
            console.error('loadAndMapNewDeltaToSampleAndCustomerAllocations: ', error);
            // TODO: handle error..
        }
    }

    async loadAndMapNewDeltaToContactCustomerAllocations(deltaCustSampleAllocIds:
        { addedOrUpdated: string[], deleted: Array<{ id: string, contactId: string }> }
        , comingFromContactProfileService:boolean = false, rawData?:CustomerSampleAllocationDTO[]) {
        // Handle Added or Updated records
        if (deltaCustSampleAllocIds
            && Array.isArray(deltaCustSampleAllocIds.addedOrUpdated)
            && deltaCustSampleAllocIds.addedOrUpdated.length > 0) {
            try {
                let rawCustSampleAllocations : CustomerSampleAllocationDTO[] = rawData;
                if (rawCustSampleAllocations && Array.isArray(rawCustSampleAllocations)) {
                    const reducedByContactId = rawCustSampleAllocations.reduce((acc, rawCustSampleAllocation) => {
                        acc[rawCustSampleAllocation.indskr_contactid] || (acc[rawCustSampleAllocation.indskr_contactid] = []);
                        acc[rawCustSampleAllocation.indskr_contactid].push(rawCustSampleAllocation);
                        return acc;
                    }, []);

                    for (const contactId in reducedByContactId) {
                        if (reducedByContactId.hasOwnProperty(contactId)) {

                            const rawCustAllocs: CustomerSampleAllocationDTO[] = reducedByContactId[contactId];
                            if (rawCustAllocs && Array.isArray(rawCustAllocs)) {

                                const idx = this.contactCustomerSampleAllocations.findIndex(contact => contact.contactId === contactId);
                               //let csa = new CustomerSampleAllocation(rawCustSampleAllocations)
                                if (idx >= 0) {
                                    // Existing Contact
                                    if(comingFromContactProfileService){
                                        // real-time fetch. means latest info. lets rebuild the eligibilities for this contact
                                        this.contactCustomerSampleAllocations[idx].currentCustomerSampleAllocations = [];
                                    }
                                    for (let i = 0; i < rawCustAllocs.length; i++) {
                                      const rawCustAlloc = rawCustAllocs[i];
                                      const csaIdx = this.contactCustomerSampleAllocations[idx].currentCustomerSampleAllocations.findIndex(csa => csa.id === rawCustAlloc.indskr_customersampleproductid);
                                      let thisCustomerAllocation = new CustomerSampleAllocation(rawCustAlloc)
                                      const now = new Date();
                                      try {
                                        if (isWithinRange(now, thisCustomerAllocation.startDate, thisCustomerAllocation.endDate)) {
                                          if (csaIdx >= 0) {
                                            // Existing Customer Sample Allocation. Replace
                                            this.contactCustomerSampleAllocations[idx].currentCustomerSampleAllocations[csaIdx] = thisCustomerAllocation;
                                          } else {
                                            // New Customer Sample Allocation. Add
                                            this.contactCustomerSampleAllocations[idx].currentCustomerSampleAllocations.push(thisCustomerAllocation);
                                          }
                                        }
                                        else if (csaIdx >= 0) {
                                          this.contactCustomerSampleAllocations[idx].currentCustomerSampleAllocations.splice(csaIdx, 1)
                                        }
                                      } catch (error) {
                                      }
                                    }
                                } else {
                                    // New Contact
                                    const now = new Date();
                                    const newContact = {
                                        contactId: rawCustAllocs[0].indskr_contactid,
                                        contactName: rawCustAllocs[0].indskr_contactname,
                                        currentCustomerSampleAllocations: []
                                    };
                                    for (let i = 0; i < rawCustAllocs.length; i++) {
                                        let thisCustomerAllocation = new CustomerSampleAllocation(rawCustAllocs[i])
                                        const now = new Date();
                                        try {
                                             if(isWithinRange(now, thisCustomerAllocation.startDate, thisCustomerAllocation.endDate)){
                                                newContact.currentCustomerSampleAllocations.push(thisCustomerAllocation);
                                            }
                                        } catch (error) {

                                        }
                                    }
                                    this.contactCustomerSampleAllocations.push(newContact);
                                }
                            } else {
                                console.warn('loadAndMapNewDeltaToContactCustomerAllocations: Invalid rawCustAllocs');
                            }
                        }
                    }
                } else {
                    console.error('loadAndMapNewDeltaToContactCustomerAllocations: Invalid raw data provided for customer sample allocation');
                    // TODO: handle error..
                }
            } catch (error) {
                console.error('loadAndMapNewDeltaToContactCustomerAllocations: ', error);
                // TODO: handle error..
            }
        }

        // Handle deleted
        if (deltaCustSampleAllocIds && Array.isArray(deltaCustSampleAllocIds.deleted)) {

            for (let i = 0; i < deltaCustSampleAllocIds.deleted.length; i++) {
                let deleted = false;
                const deletedCSA = deltaCustSampleAllocIds.deleted[i];
                if (deletedCSA.id) {
                    if (deletedCSA.contactId) {
                        // If we have a contact id, it's easier
                        const idx = this.contactCustomerSampleAllocations.findIndex(contact => contact.contactId === deletedCSA.contactId);
                        if (idx >= 0) {
                            const csaIdx = this.contactCustomerSampleAllocations[idx].currentCustomerSampleAllocations.findIndex(csa => csa.id === deletedCSA.id);
                            if (csaIdx >= 0) {
                                // Remove
                                this.contactCustomerSampleAllocations[idx].currentCustomerSampleAllocations.splice(csaIdx, 1);
                            }
                        }
                    } else {
                        // Since no contact id, have to loop through
                        for (let i = 0; i < this.contactCustomerSampleAllocations.length; i++) {
                            const contactCustomerSampleAllocation = this.contactCustomerSampleAllocations[i];

                            const csaIdx = contactCustomerSampleAllocation.currentCustomerSampleAllocations.findIndex(csa => csa.id === deletedCSA.id);
                            if (csaIdx >= 0) {
                                // Remove
                                contactCustomerSampleAllocation.currentCustomerSampleAllocations.splice(csaIdx, 1);
                            }
                        }
                    }
                } else {
                    console.warn('loadAndMapNewDeltaToContactCustomerAllocations: deleted cusomter sample allocation id is not provided..');
                }
            }
        }
    }

    async loadAndMapNewDeltaToLots(deltaLotIds: Array<string>) {
        if (deltaLotIds && Array.isArray(deltaLotIds)) {
            try {
                const now = new Date().getTime();
                const option = {
                    selector: {
                        '_id': {
                            $in: deltaLotIds
                        },
                    }
                }

                const rawDeltaLots: LotDTO[] = await this.disk.find(option);
                if (rawDeltaLots && Array.isArray(rawDeltaLots)) {
                    for (let i = 0; i < rawDeltaLots.length; i++) {
                        const rawDeltaLot = rawDeltaLots[i];
                        const idx = this.lots.findIndex(lot => lot.id === rawDeltaLot.indskr_lotid);
                        if (idx >= 0) {
                            // Existing Lot. Replace
                            let newLot = new Lot(rawDeltaLot);
                            if(isWithinRange(now,newLot.validFrom,newLot.validTo) && newLot.status == 0){
                                this.lots[idx] = new Lot(rawDeltaLot);
                            }else{
                                this.lots.splice(idx,1);
                            }
                        } else {
                            // New Lot. Add
                            let newLot = new Lot(rawDeltaLot);
                            if(isWithinRange(now,newLot.validFrom,newLot.validTo) && newLot.status == 0){
                                this.lots.push(newLot);
                            }
                        }
                    }
                }
            } catch (error) {
                console.error('loadAndMapNewDeltaToLots: ', error);
                // TODO: handle error..
            }
        }
    }

    async mapFullSyncedCustomerLicenses(rawCustomerLicenses: CustomerLicenseDTO[], newLastSyncedTimestamp: number, forceFullSync = false) {
        if (rawCustomerLicenses && Array.isArray(rawCustomerLicenses)) {
            await this._ngZone.runOutsideAngular(async () => {
                if (forceFullSync) {
                    // Clean up existing data from memory & DB
                }

                for (let i = 0; i < rawCustomerLicenses.length; i++) {
                    const rawCustomerLicense = rawCustomerLicenses[i];
                    rawCustomerLicense.lastSyncedTimestamp = newLastSyncedTimestamp;

                    if (rawCustomerLicense.statuscode && rawCustomerLicense.statuscode === StatusCode.Active) {
                        const newLicense = new CustomerLicense(rawCustomerLicense);
                        // Validate expiry date
                        let isWithinRangeBool: boolean = CustomerLicense.isWithinExpiry(
                            newLicense.validFrom,
                            newLicense.validUntil,
                            newLicense.cutOffPeriodInDays,
                            'mapDeltaSyncedCustomerLicenses');
                        if (isWithinRangeBool) {
                            this.customerLicenses.push(newLicense);
                        }
                    }
                }

                try {
                    await this.disk.updateOrInsert(DB_KEY_PREFIXES.CUSTOMER_LICENSES, doc => ({ raw: rawCustomerLicenses }));
                } catch (error) {
                    console.error('mapFullSyncedCustomerLicenses: ', error);
                    // TODO: handle error..
                }
            });
        } else {
            console.error('mapFullSyncedCustomerLicenses: Invalid raw data provided');
            // TODO: handle error..
        }
    }

    async mapDeltaSyncedCustomerLicenses(rawCustomerLicenses: CustomerLicenseDTO[], dbCustomerLicenses: CustomerLicenseDTO[], newLastSyncedTimestamp: number) {
        if (rawCustomerLicenses && Array.isArray(rawCustomerLicenses)) {
            await this._ngZone.runOutsideAngular(async () => {
                for (let i = 0; i < rawCustomerLicenses.length; i++) {
                    const rawCustomerLicense = rawCustomerLicenses[i];
                    rawCustomerLicense.lastSyncedTimestamp = newLastSyncedTimestamp;

                    if (rawCustomerLicense.track_cation && rawCustomerLicense.track_cation === TrackAction.Deleted) {
                        // Removed from position or the customer is deactivated

                        const idx = this.customerLicenses.findIndex(l => l.id === rawCustomerLicense.indskr_customerlicenseid);
                        const dbIdx = dbCustomerLicenses.length > 0 ?
                                        dbCustomerLicenses.findIndex(l => l.indskr_customerlicenseid === rawCustomerLicense.indskr_customerlicenseid) :
                                        -1;

                        if (idx >= 0) {
                            this.customerLicenses.splice(idx, 1);
                        }
                        if (dbIdx >= 0) {
                            dbCustomerLicenses.splice(dbIdx, 1);
                        }
                    } else {
                        if (!isNaN(rawCustomerLicense.statuscode)) {
                            const idx = this.customerLicenses.findIndex(l => l.id === rawCustomerLicense.indskr_customerlicenseid);
                            const dbIdx = dbCustomerLicenses.length > 0 ?
                                            dbCustomerLicenses.findIndex(l => l.indskr_customerlicenseid === rawCustomerLicense.indskr_customerlicenseid) :
                                            -1;

                            if (idx >= 0) {
                                // Update
                                if (rawCustomerLicense.statuscode === StatusCode.Active) {
                                    // Active
                                    const newLicense = new CustomerLicense(rawCustomerLicense);
                                    if (dbIdx >= 0) {
                                        dbCustomerLicenses[dbIdx] = rawCustomerLicense;
                                    }

                                    // Validate expiry date
                                    let isWithinRangeBool: boolean = CustomerLicense.isWithinExpiry(
                                                                                        newLicense.validFrom,
                                                                                        newLicense.validUntil,
                                                                                        newLicense.cutOffPeriodInDays,
                                                                                        'mapDeltaSyncedCustomerLicenses');
                                    if (isWithinRangeBool) {
                                        this.customerLicenses[idx] = newLicense;
                                    } else {
                                        this.customerLicenses.splice(idx, 1);
                                    }
                                } else if (rawCustomerLicense.statuscode === StatusCode.Inactive) {
                                    // Deactivated => remove
                                    if (dbIdx >= 0) {
                                        dbCustomerLicenses.splice(dbIdx, 1);
                                    }
                                    this.customerLicenses.splice(idx, 1);
                                } else {
                                    console.warn(`mapDeltaSyncedCustomerLicenses: Invalid statuscode: ${rawCustomerLicense.statuscode}: `, rawCustomerLicense);
                                }
                            } else {
                                // New
                                // Status & validity check
                                if (rawCustomerLicense.statuscode === StatusCode.Active) {
                                    const newLicense = new CustomerLicense(rawCustomerLicense);

                                    // Validate expiry date
                                    let isWithinRangeBool: boolean = CustomerLicense.isWithinExpiry(
                                                                                        newLicense.validFrom,
                                                                                        newLicense.validUntil,
                                                                                        newLicense.cutOffPeriodInDays,
                                                                                        'mapDeltaSyncedCustomerLicenses');
                                    if (isWithinRangeBool) {
                                        this.customerLicenses.push(newLicense);
                                    }
                                }

                                if (dbIdx >= 0) {
                                    dbCustomerLicenses[dbIdx] = rawCustomerLicense;
                                } else {
                                    dbCustomerLicenses.push(rawCustomerLicense);
                                }
                            }
                        } else {
                            console.error('mapDeltaSyncedCustomerLicenses: statuscode NaN.. ' + rawCustomerLicense);
                        }
                    }
                }

                await this.disk.updateOrInsert(DB_KEY_PREFIXES.CUSTOMER_LICENSES, doc => ({ raw: dbCustomerLicenses }));
            });
        } else {
            console.error('mapDeltaSyncedCustomerLicenses: Invalid raw data provided');
            // TODO: handle error..
        }
    }

    async mapCustomerLicensesWithDbData(dbCustomerLicenses: CustomerLicenseDTO[], forceReload = false) {
        if ((this.customerLicenses.length !== 0 && !forceReload)
                || !Array.isArray(dbCustomerLicenses)
                || dbCustomerLicenses.length === 0)
            return;

        for (let i = 0; i < dbCustomerLicenses.length; i++) {
            const rawData = dbCustomerLicenses[i];

            if (rawData.statuscode && rawData.statuscode === StatusCode.Active) {
                const newLicense = new CustomerLicense(rawData);

                // Validate expiry date
                let isWithinRangeBool: boolean = CustomerLicense.isWithinExpiry(
                                                                    newLicense.validFrom,
                                                                    newLicense.validUntil,
                                                                    newLicense.cutOffPeriodInDays,
                                                                    'mapDeltaSyncedCustomerLicenses');
                if (isWithinRangeBool) {
                    this.customerLicenses.push(newLicense);
                }
            }
        }
    }

    async loadCustomerLicensesFromDB(): Promise<CustomerLicenseDTO[]> {
        let rawCustomerLicenses: CustomerLicenseDTO[] = [];
        try {
            let doc: { raw: CustomerLicenseDTO[] } = await this.disk.retrieve(DB_KEY_PREFIXES.CUSTOMER_LICENSES, true);
            if (doc && Array.isArray(doc.raw)) {
                rawCustomerLicenses = doc.raw;
            }
        } catch (error) {
            console.error('loadCustomerLicensesFromDB: ', error);
        }

        return rawCustomerLicenses;
    }

    // Validate if licenses provided (licenseIds) are ALL valid.
    // If no license Id is provided, we assume the allocation doesn't have license requirement and consider it as valid.
    validateLicenses(customerAllocId: string, licenseIds: string[], selectedCountry: string, selectedState: string, selectedAddressId: string): { isValid: boolean, validatedLicenses: ValidatedCustomerLicense[] } {
        // First fetch licenses.
        const licenses: CustomerLicense[] = licenseIds.length > 0 ? this.customerLicenses.filter(cl => licenseIds.includes(cl.id)) : [];
        const validatedLicenses: ValidatedCustomerLicense[] = [];
        let isValid = (licenseIds.length > 0 && licenses.length === 0) ? false : true;

        if (isValid) {
            // If multiple licenses are associated with allocation, all of them have to be valid
            for (let i = 0; i < licenses.length; i++) {
                const license = licenses[i];

                // Validate expiry date
                let isWithinRangeBool: boolean = CustomerLicense.isWithinExpiry(
                                                                    license.validFrom,
                                                                    license.validUntil,
                                                                    license.cutOffPeriodInDays,
                                                                    'validateCustomerAllocationWithLicense');
                if (!isWithinRangeBool) {
                    isValid = false;
                    break;
                }

                // Validate address
                if (license.licenseType === CUSTOMER_LICENSE_TYPE.Country) {
                    if (!this.validateLicenseAddress(license.country, selectedCountry, license.addressId, selectedAddressId)) {
                        isValid = false;
                        break;
                    }
                } else if (license.licenseType === CUSTOMER_LICENSE_TYPE.State) {
                    if (!this.validateLicenseAddress(license.state, selectedState, license.addressId, selectedAddressId)) {
                        isValid = false;
                        break;
                    }
                } else if (license.addressId) {
                    // If an address is tied to it. It has to be validated even if the type is not Country or State.
                    if (license.addressId !== selectedAddressId) {
                        isValid = false;
                        break;
                    }
                } else {
                    // Since country is a mandatory field, see if it matches at least.
                    if (license.country !== selectedCountry) {
                        isValid = false;
                        break;
                    }
                }

                validatedLicenses.push({ id: license.id, typeLabel: license.licenseTypeLabel, licenseNumber: license.licenseNumber, customerAllocId });
            }
        }

        return { isValid, validatedLicenses: isValid ? validatedLicenses : [] };
    }

    validateCustomerAllocationWithLicense(licenseIds: string[], contactAddresses: ContactAddress[]): boolean {
        const licenses: CustomerLicense[] = licenseIds.length > 0 ? this.customerLicenses.filter(cl => licenseIds.includes(cl.id)) : [];

        let isValid = (licenseIds.length !== licenses.length) ? false : true;

        if (isValid) {
            for (let i = 0; i < licenses.length; i++) {
                const license = licenses[i];

                // Validate expiry date
                let isWithinRangeBool: boolean = CustomerLicense.isWithinExpiry(
                                                                    license.validFrom,
                                                                    license.validUntil,
                                                                    license.cutOffPeriodInDays,
                                                                    'validateCustomerAllocationWithLicense');
                if (!isWithinRangeBool) {
                    isValid = false;
                    break;
                }

                // Validate address if type is country or state
                if (license.licenseType === CUSTOMER_LICENSE_TYPE.Country) {
                    if (Array.isArray(contactAddresses) && contactAddresses.length > 0) {
                        let hasAddressMatch = false;
                        for (let i = 0; i < contactAddresses.length; i++) {
                            const address = contactAddresses[i];
                            // First see if the address ID matches
                            let isMatch = (license.addressId === address.customerAddressID);

                            if (!isMatch && license.country && address.country) {
                                // If ID does not match, check if the field match by any chance
                                isMatch = (license.country === address.country);
                            }

                            hasAddressMatch = hasAddressMatch || isMatch;
                        }

                        if (!hasAddressMatch) {
                            isValid = false;
                            break;
                        }
                    } else {
                        isValid = false;
                        break;
                    }
                } else if (license.licenseType === CUSTOMER_LICENSE_TYPE.State) {
                    if (Array.isArray(contactAddresses) && contactAddresses.length > 0) {
                        let hasAddressMatch = false;
                        for (let i = 0; i < contactAddresses.length; i++) {
                            const address = contactAddresses[i];
                            // First see if the address ID matches
                            let isMatch = (license.addressId === address.customerAddressID);

                            if (!isMatch && license.state && address.state) {
                                // If ID does not match, check if the field match by any chance
                                isMatch = (license.state === address.state);
                            }

                            hasAddressMatch = hasAddressMatch || isMatch;
                        }

                        if (!hasAddressMatch) {
                            isValid = false;
                            break;
                        }
                    } else {
                        isValid = false;
                        break;
                    }
                } else if (license.addressId) {
                    if (Array.isArray(contactAddresses) && contactAddresses.length > 0) {
                        const hasAddressMatch = contactAddresses.some(a => a.customerAddressID === license.addressId);

                        if (!hasAddressMatch) {
                            isValid = false;
                            break;
                        }
                    }
                } else {
                    if (Array.isArray(contactAddresses) && contactAddresses.length > 0) {
                        const hasAddressMatch = contactAddresses.some(a => a.country === license.country);

                        if (!hasAddressMatch) {
                            isValid = false;
                            break;
                        }
                    }
                }
            }
        }

        return isValid;
    }

    private validateLicenseAddress(fieldValueToCompare: string, fieldValueToBeCompared: string, licenseAddressId: string, selectedAddressId: string): boolean {
        // No address selected
        if (!fieldValueToBeCompared) {
            return false;
        }

        // If full address is attached to the license, compare against it.
        // If not, check the string value of field (country or state: might change later to check using ID values) as a fallback
        if (licenseAddressId) {
            if (licenseAddressId !== selectedAddressId) {
                return false;
            }
        } else if (!fieldValueToCompare || fieldValueToCompare !== fieldValueToBeCompared) {
            return false;
        }
        return true;
    }

    async purgeData() {
        // Purge expired data
        await this.purgeCustomerSampleAllocation();
        await this.purgeLots();
        await this.purgeCustomerLicenses();
    }

    private async purgeCustomerSampleAllocation() {
        if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)) {
            return;
        }

        const now = new Date().getTime();
        const option = {
            selector: {
                '_id': {
                    $gte: DB_KEY_PREFIXES.CUST_SAMPLE_ALLOC,
                    $lte: DB_KEY_PREFIXES.CUST_SAMPLE_ALLOC + PREFIX_SEARCH_ENDKEY_UNICODE
                },
                // 'indskr_enddate': {
                //     $lt: now.toString()
                // },
            }
        };

        let deletedCSAs;
        try {
            const rawCustomerSampleAllocations: CustomerSampleAllocationDTO[] = await this.disk.find(option);

            if (rawCustomerSampleAllocations && Array.isArray(rawCustomerSampleAllocations)) {
                deletedCSAs = rawCustomerSampleAllocations.filter(rawCSA=> isBefore(Utility.changeUTCDateToLocalDateWith0Time(parseInt(rawCSA.indskr_enddate),true),new Date())).map(raw => ({ _id: raw._id, _rev: raw._rev, _deleted: true }));
            }
        } catch (error) {
            console.error('purgeCustomerSampleAllocation: ', error);
            // TODO: handle error..
        }

        try {
            // Bulk save/update docs to DB
            await this.disk.bulk(deletedCSAs);
        } catch (error) {
            // TODO: handle error..
            console.error('purgeCustomerSampleAllocation: ', error);
        }
    }


  private async purgeLots() {
    if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL)) {
      return;
    }
      let timeString = this.authenticationService.getTodayInUTCMillisecond().toString();

    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.ALLOCATION_ADJUSTMENT)) {
        timeString = (new Date((new Date().setDate(new Date().getDate() - this.authenticationService.user.allocationAdjustmentDuration))).setHours(0, 0, 0, 0)).toString();

    }

    const option = {
      selector: {
        '_id': {
          $gte: DB_KEY_PREFIXES.LOT,
          $lte: DB_KEY_PREFIXES.LOT + PREFIX_SEARCH_ENDKEY_UNICODE
        },
        'indskr_lotvalidtodate': {
          $lt: timeString
        },
      }
    };

    let deletedLots;
    try {
      const rawLots: LotDTO[] = await this.disk.find(option);

      if (rawLots && Array.isArray(rawLots)) {
        deletedLots = rawLots.map(raw => ({ _id: raw._id, _rev: raw._rev, _deleted: true }));
      }
    } catch (error) {
      console.error('purgeLots: ', error);
      // TODO: handle error..
    }

    try {
      // Bulk save/update docs to DB
      await this.disk.bulk(deletedLots);
    } catch (error) {
      // TODO: handle error..
      console.error('purgeLots: ', error);
    }
  }

    private async purgeCustomerLicenses() {
        const today: string = this.authenticationService.getTodayInUTCMillisecond().toString();

        const dbCustomerLicenses: CustomerLicenseDTO[] = await this.loadCustomerLicensesFromDB();
        if (Array.isArray(dbCustomerLicenses)) {
            let expiredLicensIDs: string[] = [];

            const filteredDbCustomerLicenses = dbCustomerLicenses.filter((rawLicense: CustomerLicenseDTO) => {
                if (rawLicense.indskr_validuntil < today) {
                    expiredLicensIDs.push(rawLicense.indskr_customerlicenseid);
                    return false;
                }
                return true;
            });

            // Purge from memory as well
            for (let i = 0; i < expiredLicensIDs.length; i++) {
                const expiredLicenseID = expiredLicensIDs[i];

                const idx = this.customerLicenses.findIndex(l => l.id === expiredLicenseID);
                if (idx >= 0) {
                    this.customerLicenses.splice(idx, 1);
                }
            }

            await this.disk.updateOrInsert(DB_KEY_PREFIXES.CUSTOMER_LICENSES, doc => ({ raw: filteredDbCustomerLicenses }));
        }
    }

    private _reset() {
        this.sampleAllocations = [];
        this.contactCustomerSampleAllocations = [];
        this.lots = [];
        this.customerLicenses = [];
        this.validatedLicenses = [];
    }


}

export enum SamplingDetailsViewMode{
    CREATE_FROM_AGENDA = 0,
    CREATE_FROM_CONTACT_PROFILE = 1,
    CREATE_FROM_MEETING = 2,
    VIEW_DETAILS = 3,
    CREATE_FROM_MEETING_PRESENTATION = 4,
    CREATE_FROM_EVENTS = 5
}
