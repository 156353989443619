import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AssessmentTemplate, SurveyType, SurveyStatus, PreviewMode, SurveyFrequency, InternalSurveyStatus, TemplateType, SurveyCategory } from "@omni/classes/customer-assessment/assessment-template.class";
import { IndPageTitleViewDataModel } from "@omni/models/indPageTitleDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { CustomerAssessService } from "@omni/services/customer-assess/customer-assess.service";
import { CustomerSurveyService } from "@omni/services/customer-survey/customer-survey.service";
import { FooterService, FooterViews } from "@omni/services/footer/footer.service";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { EventsService } from "@omni/services/events/events.service";
import { SubCardViewDataModel } from "@omni/models/SubCardViewDataModel";
import { ComponentViewMode, UIService } from "@omni/services/ui/ui.service";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { SurveyDetailsComponent } from "../survey-details/survey-details";
import { ContactOfflineService } from "@omni/services/contact/contact.service";
import { CustomerAssessment } from "@omni/classes/customer-assessment/customer-assessment.class";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { DatePipe } from "@angular/common";
import { Contact } from "@omni/classes/contact/contact.class";
import { Account } from "@omni/classes/account/account.class";
import { AccountOfflineService } from "@omni/services/account/account.offline.service";


@Component({
  selector: 'survey-list-cardview',
  templateUrl: 'survey-list-cardview.html',
  styleUrls:['survey-list-cardview.scss']
})
export class SurveyListCardviewComponent {
  
  @Input() from;
  surveyDetailsPageTitle: IndPageTitleViewDataModel;
  surveySectionHeaderData: IndSectionHeaderViewDataModel;
  public surveyListSectionHeader: IndSectionHeaderViewDataModel;
  private filteredSurveyList: AssessmentTemplate[] = [];
  public filteredSurveyListToDisplay: SubCardViewDataModel[] = [];
  selectedTemplate: AssessmentTemplate;
  private IcurrentSurveySubscription: Subscription;
  private IpreviewSurveyModeSubscription: Subscription;
  private ngDestroy$ = new Subject<boolean>();
  public previewMode: PreviewMode = PreviewMode.DEFAULT;
  private selectedContact: Contact;
  private selectedAccount: Account;

  
  constructor(
    public footerService: FooterService,
    private customerSurveyService: CustomerSurveyService,
    public translate: TranslateService,
    public customerAssessService: CustomerAssessService,
    public events: EventsService,
    public uiService: UIService,
    public navService :NavigationService,
    public contactService: ContactOfflineService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private datePipe: DatePipe,
    private accountService: AccountOfflineService
  ) {  }

  ngOnInit() {
    this.IcurrentSurveySubscription = this.customerSurveyService.currentSurveyObserver.pipe(takeUntil(this.ngDestroy$)).subscribe((value: AssessmentTemplate) => {
      if (value) {
        this.selectedTemplate = value;
        this._initSurveyListViewCardPageTitle();
        this._initFooterButtons();
      }
    });
    this.IpreviewSurveyModeSubscription = this.customerSurveyService.surveyPreviewObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(value => {
        if(!_.isEmpty(value[1])) {
          //Submitted Survey
          if(value[1].surveyStatus == SurveyStatus.SUBMITTED) {
            this._initSurveyList();
            this._initSurveySectionHeader();
          }
        }
    });
    
    this.selectedContact = this.from == PageName.ContactDetailsComponent ? this.contactService.contactInformation : null;
    this.selectedAccount = this.from == PageName.AccountDetailsComponent ? this.accountService.selected : null;
    this._initSurveyListViewCardPageTitle();
    this._initSurveyList();
    this._initSurveySectionHeader();
  }

  ngOnDestroy() {
    if (this.IcurrentSurveySubscription) this.IcurrentSurveySubscription.unsubscribe();
    if (this.IpreviewSurveyModeSubscription) this.IpreviewSurveyModeSubscription.unsubscribe();
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  private _initSurveyListViewCardPageTitle(): void {
    let accountOrContactName = this.from == PageName.ContactDetailsComponent ? this.contactService.contactInformation.fullName : this.accountService.selected.accountName;
    const selectedContactName = '- ' + accountOrContactName || '';
    const formattedTitle: string = `${this.translate.instant("SURVEY")} ${selectedContactName}`;
    
    this.surveyDetailsPageTitle = {
      id: 'survey-details-page-title',
      title: formattedTitle,
      controls: [
        {
          id: 'close',
          icon: 'chevron-back-outline',
          isDisabled: false,
          align: 'left'
        }
      ],
    };
  }

  private _initSurveyList() {
    this.filteredSurveyList = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY && template?.indskr_surveytype != SurveyType.INTERNAL && new Date(template.indskr_validfrom) <= new Date() && new Date(template.indskr_validto) >= new Date());
    if(this.from == PageName.ContactDetailsComponent) this.filteredSurveyList =  this.filteredSurveyList.filter(template => template.indskr_entity == SurveyCategory.CONTACT);
    else if(this.from == PageName.AccountDetailsComponent)this.filteredSurveyList =  this.filteredSurveyList.filter(template => template.indskr_entity == SurveyCategory.ACCOUNT);
    if(!_.isEmpty(this.filteredSurveyList)) {
      this._initViewData();
    }
  }

  private _initViewData() {
    this.filteredSurveyListToDisplay = [];
    this.filteredSurveyList = _.orderBy(this.filteredSurveyList, ['indskr_validto','indskr_name'], ['desc', 'asc']);
    this.filteredSurveyList.forEach( async(survey) => {
      const templateId: string = survey.indskr_assessmenttemplateid;
      // Template for Contact
      if(survey.indskr_entity == 'contact') {
        const selectedContactId = !_.isEmpty(this.selectedContact) ? this.selectedContact.ID : this.contactService.contactInformation.ID || '';
        let isEligibleCustomer: boolean = false;
        //check eligible customers
        if(survey.indskr_metadata.hasOwnProperty('EligibleCustomers') && !_.isEmpty(survey.indskr_metadata.EligibleCustomers) && !_.isEmpty(selectedContactId)) {
          let eligibleCustomersIDs: string[] = [];
          const eligibleCustomersFrommetaData = survey.indskr_metadata['EligibleCustomers'];
          eligibleCustomersIDs = eligibleCustomersFrommetaData.map(customer=>customer['ID']);
          isEligibleCustomer = eligibleCustomersIDs.some(contactId => contactId == selectedContactId);
        }else {
          isEligibleCustomer = true;
        }
        if(isEligibleCustomer) {
          //check captured one-time survey to disable start-survey button
          if(survey.indskr_surveyfrequency == SurveyFrequency.ONCE && !_.isEmpty(templateId) && !_.isEmpty(selectedContactId)) {
            let isCapturedOneTimeSurvey: boolean = this.customerAssessService.isCapturedOneTimeSurvey(templateId, selectedContactId, 'contact');
            if(!isCapturedOneTimeSurvey) {
              this.filteredSurveyListToDisplay.push(this._getSubCardDataModel(survey));
            }
          }else {
            this.filteredSurveyListToDisplay.push(this._getSubCardDataModel(survey));
          }
        }
      } 
      // Template for Account
      else {
        const selectedAccountId = !_.isEmpty(this.selectedAccount) ? this.selectedAccount.id : this.accountService.selectedAccounts[0].id || '';
        let isEligibleAccount: boolean = false;
        //check eligible customers
        if(survey.indskr_metadata.hasOwnProperty('EligibleCustomers') && !_.isEmpty(survey.indskr_metadata.EligibleCustomers) && !_.isEmpty(selectedAccountId)) {
          let eligibleAccountsIDs: string[] = [];
          const eligibleAcountsFrommetaData = survey.indskr_metadata['EligibleCustomers'];
          eligibleAccountsIDs = eligibleAcountsFrommetaData.map(account=>account['ID']);
          isEligibleAccount = eligibleAccountsIDs.some(accountId => accountId == selectedAccountId);
        }else {
          isEligibleAccount = true;
        }
        const surveyedAccountsByTemplate = this.customerAssessService.surveyedAccountsForOnetimeSurvey[templateId];
        let isCapturedOneTimeSurvey: boolean = false;
        if(!_.isEmpty(surveyedAccountsByTemplate)) {
          isCapturedOneTimeSurvey = surveyedAccountsByTemplate.some((template) => template['indskr_entityid'] == selectedAccountId );
        }
        if(isEligibleAccount) {
          //check captured one-time survey to disable start-survey button
          if(survey.indskr_surveyfrequency == SurveyFrequency.ONCE && !_.isEmpty(templateId) && !_.isEmpty(selectedAccountId)) {
            // let isCapturedOneTimeSurvey: boolean = this.customerAssessService.isCapturedOneTimeSurvey(templateId, selectedAccountId, 'account');
            if(!isCapturedOneTimeSurvey) {
              this.filteredSurveyListToDisplay.push(this._getSubCardDataModel(survey));
            }
          }else {
            this.filteredSurveyListToDisplay.push(this._getSubCardDataModel(survey));
          }
        }
      }
      
    });
  }

  private _initFooterButtons() {
    this.footerService.initButtons(FooterViews.None);
  }
  
  onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.onClosePage();
        break;  
      default:
        break;
    }
  }

  async onClosePage() {
    this.navService.popChildNavPageWithPageTracking();
    if(this.from == PageName.ContactDetailsComponent) {
      this.footerService.initButtons(FooterViews.Contacts);
      this.uiService.contactDetailsSegment = 'profile';
    } else {
      this.footerService.initButtons(FooterViews.Accounts);
      this.uiService.accountDataSegment = 'profile';
      this.events.publish('refershAccountDetailSegment');
    }

  }

  public onButtonControlClick(event) {
    if(!_.isEmpty(event) && !_.isEmpty(event.button)) {
      const buutonId = event.button.id;
      const surveyTemplate = event.viewData;
      console.log("clicked button ", buutonId);
      if(buutonId == 'preview') {
        this.openSurvey(surveyTemplate, true);
      }else if(buutonId == 'start-survey') {
        this.openSurvey(surveyTemplate, false);
      }
    }
  }

  async openSurvey(selectedTemplateFromCardView: any, isReadOnly: boolean) {
    if(!_.isEmpty(selectedTemplateFromCardView)) {
      await this.uiService.displayLoader(); 
      const rawSurveyTemplateList = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY && new Date(template.indskr_validfrom) <= new Date() && new Date(template.indskr_validto) >= new Date);
      const foundTemplate: AssessmentTemplate = rawSurveyTemplateList?.find(template=> template.indskr_assessmenttemplateid == selectedTemplateFromCardView.id);
      const surveyFor = foundTemplate.indskr_entity == SurveyCategory.CONTACT ? SurveyCategory.CONTACT : SurveyCategory.ACCOUNT
      const from = surveyFor == SurveyCategory.CONTACT ? PageName.ContactDetailsComponent : PageName.AccountDetailsComponent;
      if(!_.isEmpty(foundTemplate)) {
        this.customerSurveyService.setCurrentSurvey(foundTemplate);
        try {
          await this.navService.pushChildNavPageWithPageTracking(SurveyDetailsComponent, PageName.SurveyDetailsComponent, PageName.ActivitiesDetailsPaneComponent, { appointmentId: '', from: from }).then(async()=>{
            this.navService.setChildNavRightPaneView(true); 
            this.uiService.dismissLoader();
            if(surveyFor == SurveyCategory.CONTACT) {
              this.customerSurveyService.selectedContactForSurvey = this.contactService.contactInformation ?? null;
              this.customerSurveyService.selectedAccountForSurvey = null;
            } else {
              this.customerSurveyService.selectedContactForSurvey = null;
              this.customerSurveyService.selectedAccountForSurvey = this.accountService.selected ?? null;
            }

            if(!isReadOnly) {
              //check saved survey data from db before starting the survey
              // const contactId: string = this.customerSurveyService.selectedContactForSurvey.ID || '';
              const templateId: string = foundTemplate.indskr_assessmenttemplateid || '';
              const entityId: string = surveyFor == SurveyCategory.CONTACT ? this.customerSurveyService.selectedContactForSurvey.ID : this.customerSurveyService.selectedAccountForSurvey?.id ? this.customerSurveyService.selectedAccountForSurvey.id : '';
              if(!_.isEmpty(entityId) && !_.isEmpty(templateId)) {
                let surveysByTemplateId = this.customerAssessService.getSurveysByTemplateId(templateId, surveyFor, false);
                if(!_.isEmpty(surveysByTemplateId)) {
                  let surveyDataFromDB: CustomerAssessment;
                  let savedSurvey = surveysByTemplateId.find(survey => survey.surveyStatus === SurveyStatus.SAVED && survey.indskr_entityid === entityId);
                  if (savedSurvey) {
                      surveyDataFromDB = savedSurvey
                  }
                  // response update
                  if(!_.isEmpty(surveyDataFromDB)) {
                    const responseData = await this.customerAssessService.fetchContactAccountSurveyForDetails(surveyDataFromDB, surveyFor, true);
                    if(!_.isEmpty(responseData)) {
                      surveyDataFromDB.responses = responseData;  
                    }
                  }
                  this.customerSurveyService.setSurveyPreviewMode(false, surveyDataFromDB);
                }else {
                  //There is no saved data with the selected contact and survey template
                  this.customerSurveyService.setSurveyPreviewMode(false);  
                }
              }
            }else {
              //preview template
              this.customerSurveyService.setSurveyPreviewMode(true);
            }
          });
        } catch(error) {
          console.log("failed to open survey ", error);
          this.uiService.dismissLoader();
        }
      }else {
        console.log("There is no found template before opening the survey");
        this.uiService.dismissLoader();
      }
    }
  }

  private _initSurveySectionHeader() {
    const numOfList: number = this.filteredSurveyListToDisplay.length;
    this.surveyListSectionHeader = {
      id: 'survey_list_section_header',
      title: `${this.translate.instant("SURVEY")} (${numOfList})`,
      isShowSperationVerticalLine: true,
      controls: [],
    };
  }

  private _getSubCardDataModel(survey: AssessmentTemplate): SubCardViewDataModel {
    const viewData: SubCardViewDataModel = {
      id: survey.indskr_assessmenttemplateid,
      primaryTextRight: survey.indskr_name,
      isSubPrimaryTextRight: true,
      subPrimaryTextRight: this.translate.instant('EXPIRES_ON') + ' ' + this._getFormattedDate(survey['indskr_validto']),
      isSecondaryGrid: true,
      gridItems: [],
      startIconInline: "assets/imgs/survey_blue_bluedot.svg",
      buttonItems: [],
      clickHandler: (id: string, event, specificTarget) => {}
    };
    //grid items
    let gridItems: any[];

    if (survey.indskr_description) {
      gridItems = [
        {
          id: 'description',
          label: '',
          value: survey.indskr_description,
          descriptionField: true,
        },
        {
          id: 'type',
          label: this.translate.instant('SURVEY_TYPE'),
          value: survey.indskr_surveytype === SurveyType.IN_MEETING ? this.translate.instant('IN_MEETING') : this.from==PageName.ContactDetailsComponent ? this.translate.instant('CUSTOMER') : this.translate.instant('ACCOUNT'),
          rawValue: survey['indskr_surveytype'],
        },
        {
          id: 'use-type',
          label: this.translate.instant('USE_TYPE'),
          value: this.getSurveyFrequency(survey),
          rawValue: survey['indskr_surveyfrequency'],
        },
      ];
    } else {
      gridItems = [
        {
          id: 'type',
          label: this.translate.instant('SURVEY_TYPE'),
          value: survey.indskr_surveytype === SurveyType.IN_MEETING ? this.translate.instant('IN_MEETING') : this.from==PageName.ContactDetailsComponent ? this.translate.instant('CUSTOMER') : this.translate.instant('ACCOUNT'),
          rawValue: survey['indskr_surveytype'],
        },
        {
          id: 'use-type',
          label: this.translate.instant('USE_TYPE'),
          value: this.getSurveyFrequency(survey),
          rawValue: survey['indskr_surveyfrequency'],
        },
        {
          id: 'status',
          // label: this.from==PageName.ContactDetailsComponent ? this.translate.instant("SURVEY_CUSTOMER_SUVEYED") : this.translate.instant("SURVEY_ACCOUNT_SURVEYED"),
          label: this.from==PageName.ContactDetailsComponent ? this.translate.instant("CONTACT_ELIGIBLE") : this.translate.instant("ACCOUNT_ELIGIBLE"),
          value: this.from==PageName.ContactDetailsComponent ? this.getCustomerSurveyed(survey) : this.getAccountSurveyed(survey),
          rawValue: undefined,
        },
      ];
    }
    viewData.gridItems = gridItems;

    //buttons - If external type and one-time survey is captured, start-survey button is disabled, start-survey on preview is disabled
    viewData.buttonItems.push(
      {
        id: 'preview',
        name: this.translate.instant("PREVIEW"),
        isDisabled: this.from==PageName.ContactDetailsComponent ? this.contactService.contactPageMode != ComponentViewMode.LISTVIEW : this.accountService.accountPageMode != ComponentViewMode.LISTVIEW
      },
    );

    if (survey.indskr_surveytype !== SurveyType.IN_MEETING) {
      viewData.buttonItems.push(
        {
          id: 'start-survey',
          name: this.translate.instant("START_SURVEY"),
          isDisabled: this.from==PageName.ContactDetailsComponent ? this.contactService.contactPageMode != ComponentViewMode.LISTVIEW : this.accountService.accountPageMode != ComponentViewMode.LISTVIEW
        }
      );
    }
    return viewData;
  }

  private getCustomerSurveyed(survey: AssessmentTemplate): string {
    return this.customerAssessService.getNumOfCustomerSurveyed(survey);
  }

  private getAccountSurveyed(survey: AssessmentTemplate): string {
    return this.customerAssessService.getNumOfAccountSurveyed(survey);
  }

  private getSurveyFrequency(survey: AssessmentTemplate) {
    return survey.indskr_surveyfrequency == SurveyFrequency.ONCE ? this.translate.instant("ONE_TIME") : this.translate.instant("RECURRING");
  }

  private _getFormattedDate(date: any): string {
    const formattedDate = this.datePipe.transform(date, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    return formattedDate;
  }

}

