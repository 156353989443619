import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { DynamicsClientService } from './../../../data-services/dynamics-client/dynamics-client.service';
import { VoiceCallService } from '@omni/services/voice-call/voice-call.sertvice';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { CallPlanOfflineService } from '@omni/services/call-plan/call-plan.offline.service';
import { Component, OnInit, ChangeDetectorRef, Input, OnDestroy, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { TranslateService } from '@ngx-translate/core';
import { IndFormFieldViewDataModel, FormFieldType } from '../../../models/indFormFieldDataModel';
import { PopoverController, LoadingController } from '@ionic/angular';
import { ActivityService } from '../../../services/activity/activity.service';
import { format, differenceInDays } from "date-fns";
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { AccountOfflineService, AccountSelectedFor } from '../../../services/account/account.offline.service';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { UIService, ComponentViewMode } from '../../../services/ui/ui.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { AccountPageComponent } from '../../../components/account/account-page/account-page';
import { EventsService } from '../../../services/events/events.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { DeviceService } from '../../../services/device/device.service';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { ContactPageComponent } from '../../../components/contact/contact-page/contact-page';
import { FeatureActionsMap, User } from '../../../classes/authentication/user.class';
import { TherapeuticArea } from '../../../classes/therapeutic-area/therapeutic-area.class';
import { TherapeuticAreaDataService } from '../../../data-services/therapeutic-area/therapeutic-area.data.service';
import { PhoneActivity } from '../../../classes/activity/phone.activity.class';
import * as _ from 'lodash';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { PhoneCallMeetingStructureService } from '../../../services/phone-call-meeting-structure/phone-call-meeting-structure.service'
import { PhoneCallActivityState, MeetingActivityState, MeetingActivityType, MeetingSubActivityType, MeetingActivityTypeCode, ConfigFieldOptionValue, ActivityType, ActivityTypeCodeRaw } from '../../../classes/activity/activity.class';
import { PhoneCallDataService, PhonecallStatus } from '../../../data-services/phone-call/phonecall.data.service';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { ConfiguredFields } from '@omni/classes/authentication/configured.field.class';
import moment from 'moment';
import { MeetingDataService, UpdateTypeAndSubTypeActivityPayLoad } from '@omni/data-services/meeting/meeting.data.service';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { IOMultiSelect } from '@omni/components/io-component/io-multiselect/io-multiselect';
import { Subject, Subscription } from 'rxjs';
import { IoUserSelection } from '@omni/services/io-userselection/io-userselection.service';
import { AlertService } from '@omni/services/alert/alert.service';
import { NewActivityComponent } from '../../activity/new-activity/new-activity';
// import { UpdateTypeAndSubTypeActivityPayLoad } from '@omni/data-services/meeting/meeting.data.service';
import { cloneDeep } from 'lodash';
import { BusinessProcessViewDataModel } from '@omni/models/business-process-view-data.model';
import { CoachingReportService, COACHING_VIEW_TYPE } from '@omni/services/coaching/coaching.report.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { AccompainedUser } from '@omni/classes/activity/accompained-user.class';
import { Report } from '@omni/classes/coaching/report.class';
import { CoachingDetailsComponent } from '@omni/components/coaching/coaching-details/coaching-details';
import { EmailViewType } from '@omni/classes/activity/email.activity.class';
import { AccesingMode, CaseManagementService } from '@omni/services/case-management/case-management.service';
import { EmailService } from '@omni/services/email-templates/email.service';
import { DatePipe } from '@angular/common';
import { GlobalUtilityService } from '../../../services/global-utility.service';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';
import { checkTimeConflictWithOtherActivities, removeConflictIdFromOtherActivities } from '../../../utility/activity.utility';
import { VoiceCallStatus } from '@omni/types/state/state.type';
import { electronApi } from "@omni/services/electron-api";
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { CallPlanDataService } from '@omni/data-services/call-plan/call-plan.data.service';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { DiskService } from '@omni/services/disk/disk.service';
import { SelectListData } from '@omni/components/popover/popover';


/**
 * Generated class for the PhoneCallDetailsComponent component.
 * @description
 * This compponent helps in opening the details of the phone call in the right side pane
 * and creating a phone-call activity
 *
 */
@Component({
  selector: 'phone-call-details',
  templateUrl: 'phone-call-details.html',
  styleUrls: ['phone-call-details.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class PhoneCallDetailsComponent implements OnInit, OnDestroy {

  // meetingTime: string;
  //@ViewChild(Content, {static: true}) content: Content;
  isNotesEnabled: boolean = false;
  isCallObjectivesEnabled: boolean = false;
  @Input() showBackButton: boolean = false;
  phoneCallDetailsPanePageTitle: IndPageTitleViewDataModel;
  public isTherapeuticAreaEnabled: boolean = false;
  private layoverViewPushedFrom: string;
  public therapeuticAreas: TherapeuticArea[] = [];
  private isLayoverPushView: boolean = false;
  private eventSubscriptions: { unsubscribe: Function }[] = [];
  public phoneCallActivityState = PhoneCallActivityState;
  public meetingState = MeetingActivityState;
  public phonecallState = PhoneCallActivityState;
  private activitiesDetailsPanePageTitleText: string;
  public isNotesDirty: boolean = false;
  public preSavedNotes: string;
  public blockUI: boolean = false;
  public meetingActivityType: MeetingActivityType[] = [];
  public meetingSubActivityType: MeetingSubActivityType[] = [];
  public selectedMeetingSubActivityType: MeetingSubActivityType[] = [];
  public isMeetingActivityTypeConfigured: boolean = false;
  private selectedPhoneSubTypeName: string;
  public meetingActivityTypeCode = MeetingActivityTypeCode;
  public appConfigFieldDate: Date;
  public maxDate: string = moment(new Date()).endOf('month').format();
  public phoneCallActivityTypes: MeetingActivityType[] = [];
  public activityTypeFormField:IndFormFieldViewDataModel;
  public activitySubTypeFormField:IndFormFieldViewDataModel;



  //Config Fields
  private appConfigInputText: string;
  private appConfigInputBoolValue: string;
  private appConfigProcessValue: string;
  private appConfigIntegervalue: string;
  private appConfigDecimalvalue: string;
  private appConfigMemovalue: string;
  private appConfigLookupValue: string;
  private appConfigPickValue: ConfigFieldOptionValue;
  private appConfigCurrencyValue: string;
  private formattedDate: string;
  private appConfigDateValue: number;
  private dateEvent: any;
  private dateFieldName: string;
  private isValueUpdated: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  public isReadOnlyJointPhoneCall: boolean = false;


  // configMandatoryFields
  private configMandatoryField: string;
  public phoneCallConfigFields: ConfiguredFields[] = [];
  public lookupConfigFieldsData = [];
  private activityReopenedFromTimeline: boolean = false;

  @ViewChild('appConfigFieldDatePicker', { static: true }) appConfigFieldDatePicker;

  public callDetailsHeaderModel: IndSectionHeaderViewDataModel;
  public notesHeaderModel: IndSectionHeaderViewDataModel;
  @Input() from: any;
  private _isNewlyCreatedActivity: boolean = false;
  get isNewlyCreatedActivity(): boolean {
    return this._isNewlyCreatedActivity;
  }
  @Input() set isNewlyCreatedActivity(newValue: boolean) {
    this._isNewlyCreatedActivity = newValue;
  }
  shortMonth: string[] = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  public phonecallProcessView: Array<BusinessProcessViewDataModel> = [];
  public detailsSectionId: string = 'details';
  public callStSectionId: string = 'callstructure';
  public keyMessagesSectionId: string = 'callproductkeymessages';
  public therapeuticSectionId: string = 'calltherapeuticareas';
  public callNotesSectionId: string = 'phonecallnotes';
  public coachingSectionId: string = 'phonecallcoaching';
  public callObjectiveSectionId: string = 'phonecallobjectives';
  public hasJointPhoneCallFA: boolean = false;
  private configFieldsSubscription: Subscription;
  public isCoachingEnabled: boolean = false;
  private coachingActivitySubscription: Subscription;
  // date time picker
  public startDateField: IndDateTimeFormViewDataModel;
  public startTimeField: IndDateTimeFormViewDataModel;
  public endDateField: IndDateTimeFormViewDataModel;
  public endTimeField: IndDateTimeFormViewDataModel;
  // public durationField: IndDateTimeFormViewDataModel;
  private phoneCallStartDateTime: string;
  private phoneCallStartDate: string;
  private phoneCallStartTime: string;
  private phoneCallEndDateTime: string;
  private phoneCallEndDate: string;
  private phoneCallEndTime: string;
  private duration: string;
  dateFormatSubscription: Subscription;
  private ngUnsubscribe$ = new Subject<boolean>();
  public backgroundUploadInProgress: boolean = false;
  public displayConflictMsg: boolean = false;
  private shouldCheckConflict: boolean = false;
  private isClickedContacts: boolean = false;
  private isClickedFormat: boolean = false;
  private isClickedFormatDetails: boolean = false;
  public requiredFields: any;
  private voiceCallStatus: VoiceCallStatus = 'Null';
  private voiceCallEnabled: boolean = false;
  public callRecordings: [] = [];
  private refreshingCallRecords: boolean = false;
  public notesPlaceholder:string = '';

  constructor(public translate: TranslateService,
    public popoverCtrl: PopoverController,
    public activityService: ActivityService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public accountService: AccountOfflineService,
    public trackingService: TrackService,
    private meetingDataService: MeetingDataService,
    public uiService: UIService,
    public events: EventsService,
    public device: DeviceService,
    private authService: AuthenticationService,
    public footerService: FooterService,
    private authenticationOfflineService: AuthenticationService,
    private navService: NavigationService, public cdRef: ChangeDetectorRef,
    private contactService: ContactOfflineService,
    private theraputicService: TherapeuticAreaDataService,
    private notificationService: NotificationService,
    private phoneCallMeetingStructureService: PhoneCallMeetingStructureService,
    private phoneCallDataService: PhoneCallDataService,
    private loadingController: LoadingController,
    private ioUserSelection: IoUserSelection,
    private callPlanService: CallPlanOfflineService,
    private readonly alertService: AlertService,
    public coachingReportService: CoachingReportService,
    private emailService: EmailService,
    private caseManagementService: CaseManagementService,
    private datePipe: DatePipe,
    private utilityService: GlobalUtilityService,
    private activityDataService: ActivityDataService,
    private eventsToolService: EventsToolService,
    private voiceCallService: VoiceCallService,
    private dynamics: DynamicsClientService,
    private inAppBrowser: InAppBrowser,
    private callPlanDataService: CallPlanDataService,
    private readonly diskService: DiskService,
  ) {

    this.events.subscribe('detectChangesOnPhoneCallActivityDetails', (initButtons: boolean = false) => {

      this.phoneCallMeetingStructureService.createEmbeddedInteractions(<PhoneActivity>activityService.selectedActivity)
      if (!initButtons) {
        this.footerService.initButtons(FooterViews.PHONE_CALL);
      }
      this.cdRef.detectChanges();
      this.updateDetailPaneTitle();
      this.initActivitiesDetailsPageTitle();
      this.initFormFields();

    });

    this.events.subscribe('UpdatePhonecallActivity', ()=>{
      this.activityDataService.updateActivityDetails(this.activityService.selectedActivity, false);
    });

    this.events.subscribe('completePhonecall', () => {
      this.markComplete(true);
    });

    this.events.subscribe('scrollToTop', () => {
      this.scrollToTop();
    });

    this.eventSubscriptions.push(...this.events.subscribe('updateTherapeuticarea', () => {
      this.initTherapeuticAreas();
    }));

    this.events.subscribe('phoneactivity:appConfigFields', () => {
      this.updateAppConfigFieldsOnActivityChanges();
      if (this.device.isOffline) {
        this.setOfflineDataForConfigFields();
      }
    });

    this.events.subscribe('updateMandatoryField', (field) => {
      if(field == PageName.ContactPageComponent) {
        this.isClickedContacts = true;
      }
      this.initFormFields();
    })
    this.events.observe('updateMandatoryField_footer').pipe(takeUntil(this.destroy$)).subscribe((button) => {
      if(button == 'contactsphonecall')  this.isClickedContacts = true;
    });

    this.updateDetailPaneTitle();
    this.events.observe('device:deviceIsOffline').pipe(takeUntil(this.destroy$)).subscribe(() => this.initFooterAndHeaderForCompletedPhoneCall());
    this.events.observe("sync:completed").pipe(takeUntil(this.destroy$)).subscribe(() => this.initFooterAndHeaderForCompletedPhoneCall());
    this.events.observe('device:deviceIsOnline').pipe(takeUntil(this.destroy$)).subscribe(() => this.initFooterAndHeaderForCompletedPhoneCall());
    this.events.observe('userStateChanged').pipe(takeUntil(this.destroy$)).subscribe(() => this.initFooterAndHeaderForCompletedPhoneCall());

    this.initActivitiesDetailsPageTitle();
    this.dateFormatSubscription = this.dateTimeFormatsService.dateFormat.subscribe( df => {
      if (df) {
        this.initDateTimeFormFields();
      }
    });
  }

  private updateDetailPaneTitle() {
    switch (this.activityService.selectedActivity.subject) {
      case this.translate.instant('NEW_ACTIVITY_PHONECALL'):
        this.activitiesDetailsPanePageTitleText = this.translate.instant('NEW_ACTIVITY_PHONECALL');
        break;
      default:
        this.activitiesDetailsPanePageTitleText = this.activityService.getActivitysubject(this.activityService.selectedActivity);
        break;
    }
  }

  /**
   * ngOnInit().
   * @description
   *
   *
   *
   */
  ngOnInit() {
    this.displayConflictMsg = this.checkConflictMsgDisplay();
    this.activityService.signalRefreshConflictAlertMsg$.asObservable()
    .pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.displayConflictMsg = this.checkConflictMsgDisplay();
    });

    if (this.from) {
      this.isLayoverPushView = true;
      if (this.from == 'AccountManagementTool') {
        this.layoverViewPushedFrom = 'AccountManagementTool';
      }
      if (this.from == 'AccountTool') {
        this.layoverViewPushedFrom = 'AccountTool';
      }
      if (this.from == 'AccountTimeline') {
        this.layoverViewPushedFrom = 'AccountTimeline';
      }
    }
    this.configFieldsSubscription = this.ioUserSelection.additionalConfigObserver.subscribe(data => {
      if (data != undefined) {
        if (data[0].type != undefined) {
          this.updateAdditionalFields(data);
        }
      }
    });

    this.isTherapeuticAreaEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.THERAPEUTIC_AREA) ? true : false;
    this.isNotesEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_NOTES) ? true : false;
    this.voiceCallEnabled = !this.device.isOffline && this.authService.hasFeatureAction(FeatureActionsMap.VOICE_CALL);

    this.setCoachings();

    this.initTherapeuticAreas();
    //this.content.resize();
    //this.events.publish('detectChangesOnPhoneCallActivityDetails');
    // this.initTherapeuticAreas();
    //this.content.resize();
    this.events.unsubscribe('completePhonecall');
    this.events.subscribe('completePhonecall', () => {
      this.markComplete(true);
    });
    this.hasJointPhoneCallFA = this.authService.hasFeatureAction(FeatureActionsMap.JOINT_PHONE_CALL);
    this.initConfigFieldsSetup();
    this.initConfigFieldsLookupData();
    this.initializeSectionHeader();
    this.initActivitiesDetailsPageTitle();
    //On sync, reinitialize FA
    this.events.observe('sync:completed').pipe(
      takeUntil(this.destroy$))
      .subscribe(err => {
        this.hasJointPhoneCallFA = this.authService.hasFeatureAction(FeatureActionsMap.JOINT_PHONE_CALL);
        this.setCoachings();
      });
    this.phoneCallActivityTypes = this.activityService.configuredActivityTypes(MeetingActivityTypeCode.PHONE_CALL);
    this.initFormFields();
    this.translate.onLangChange.subscribe(data =>{
      this.initFormFields();
      this.initActivitiesDetailsPageTitle();
    });
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(inProgress => {
      if (this.activityService.selectedActivity && this.navService.getActiveChildNavViewPageName() === PageName.PhoneCallDetailsComponent) {
        this.backgroundUploadInProgress = inProgress && this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID);
        this.initActivitiesDetailsPageTitle();
        this.footerService.initButtons(FooterViews.PHONE_CALL);
        this.initFormFields();
      }
    });
    if(this.authenticationOfflineService.impersonatedUser){
      this.events.publish('detectChangesOnPhoneCallActivityDetails');
    }
  }

  private initFormFields() {
    this.requiredFields = this.activityService.getRequiredPhoneCallData();
    if(!this.therapeuticAreas || (this.therapeuticAreas && this.therapeuticAreas.length<1) ) {
      this.requiredFields['phone-call-therapeutic-area']['isRequired'] = false;
    }
    if(!_.isEmpty(this.phoneCallActivityTypes) || !_.isEmpty(this.activityService.selectedActivity.indskr_activitytype)){
      this.activityTypeFormField = {
        label: this.translate.instant('FORMAT'),
        inputText: this.activityService.selectedActivity.activityTypeName,
        inputValue: this.activityService.selectedActivity.activityTypeName,
        customPlaceholderLabel:  (this.activityService.selectedActivity && !this.isReadOnlyJointPhoneCall && !this.activityService.teamViewActive &&
          this.activityService.selectedActivity.state != PhoneCallActivityState.Completed) ? this.translate.instant('SELECT_FORMAT') : this.translate.instant('NO_FORMAT'),
        id: 'phone-activityType-field',
        isReadOnly: true,
        isDisabled: this.backgroundUploadInProgress || !(this.isNotReadOnly),
        showArrow: (this.isNotReadOnly && !_.isEmpty(this.phoneCallActivityTypes)),
        formFieldType: FormFieldType.POPOVER_SELECT,
        isRequired: this.isNotReadOnly && !_.isEmpty(this.phoneCallActivityTypes) && this.requiredFields['phonecall-activityType-field']['isRequired'],
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: (this.isNotReadOnly && !_.isEmpty(this.phoneCallActivityTypes) && this.requiredFields['phonecall-activityType-field']['isRequired']) && this.isClickedFormat && _.isEmpty(this.activityService.selectedActivity.activityTypeName),
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };
    }

    if(!_.isEmpty(this.phoneCallActivityTypes) || !_.isEmpty(this.activityService.selectedActivity.indskr_activitysubtype)){
      this.activitySubTypeFormField = {
        label: this.translate.instant('FORMAT_DETAILS'),
        inputText: this.activityService.selectedActivity.activitySubTypeName,
        inputValue: this.activityService.selectedActivity.activitySubTypeName,
        customPlaceholderLabel: (this.activityService.selectedActivity && !this.isReadOnlyJointPhoneCall && !this.activityService.teamViewActive && this.activityService.selectedActivity.state != PhoneCallActivityState.Completed)?this.translate.instant('SELECT_FORMAT_DETAILS'):this.translate.instant('NO_FORMAT_DETAILS'),
        id: 'phone-activitySubType-field',
        isReadOnly: true,
        isDisabled: this.backgroundUploadInProgress || !(this.isNotReadOnly),
        showArrow: (this.isNotReadOnly && !_.isEmpty(this.activityService.configuredActivitySubTypes(MeetingActivityTypeCode.MEETING))),
        formFieldType: FormFieldType.POPOVER_SELECT,
        isRequired: this.isNotReadOnly && !_.isEmpty(this.activityService.configuredActivitySubTypes(MeetingActivityTypeCode.MEETING)) && this.requiredFields['phonecall-activitySubType-field']['isRequired'],
        errorMessage: this.activityService.getErrorMessageRequiredField(),
        isEmptyRequiredField: (this.isNotReadOnly && !_.isEmpty(this.activityService.configuredActivitySubTypes(MeetingActivityTypeCode.MEETING)) && this.requiredFields['phonecall-activitySubType-field']['isRequired']) && this.isClickedFormatDetails && _.isEmpty(this.activityService.selectedActivity.activitySubTypeName),
        eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
      };
    }
    this.initDateTimeFormFields();
    if (this.voiceCallEnabled) {
      this.voiceCallService.voiceCallStatus$.pipe(takeUntil(this.destroy$)).subscribe(status => {
        console.log("VoiceCallStatus : ", status);
        if (status == "REFRESH_CALL_RECORDS") {
          this.loadVoiceCallRecords();
        } else {
          this.voiceCallStatus = status;
        }
      });
    }
  }

  get isNotReadOnly(): boolean {
    return this.activityService.selectedActivity && !this.activityService.selectedActivity.isFromXperiences && !this.isReadOnlyJointPhoneCall && !this.activityService.teamViewActive && this.activityService.selectedActivity.state != PhoneCallActivityState.Completed;
  }

  private setCoachings() {
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.COACHING_TOOL) && this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.COACHING_TEAM_VIEW)) {
      this.isCoachingEnabled = true;
      this.coachingReportService.setActivityCoaching(this.activityService.selectedActivity);
      this.coachingActivitySubscription = this.coachingReportService.activityCoachingObserver.subscribe(data => {
        this.coachingReportService.coachingReports = data;
      });
    } else {
      this.isCoachingEnabled = false;
    }
  }

  private initFooterAndHeaderForCompletedPhoneCall() {
    if (_.isEmpty(document.getElementsByTagName('ion-modal')) && this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_REOPEN) && this.activityService.selectedActivity && this.activityService.selectedActivity.isCompleted) {
      this.footerService.initButtons(FooterViews.PHONE_CALL);
      this.initActivitiesDetailsPageTitle();
    }
  }

  private async updateAccompaniedUsers(raw: AccompainedUser[]) {
    if (this.activityService.selectedActivity instanceof PhoneActivity) {
      await this.uiService.displayLoader();
      this.activityService.selectedActivity.accompaniedUserList = raw;
      if (raw.length == 0) {
        this.activityService.selectedActivity.accompaniedUserString = "";
        this.activityService.selectedActivity.jointphonecall = false;
      } else {
        const names: string[] = raw.map(e => e.name).sort(function (a, b) {
          let nameA = a.toLowerCase(), nameB = b.toLowerCase();
          if (nameA < nameB) //sort string ascending
            return -1;
          if (nameA > nameB)
            return 1;
          return 0; //default return value (no sorting)
        });
        this.activityService.selectedActivity.accompaniedUserString = names[0] + (names.length == 1 ? '' : (' +' + (names.length - 1)));
        this.activityService.selectedActivity.jointphonecall = true;
      }
      const phoneCallPayLoad = this.phoneCallDataService.setPhoneCallPayload(this.activityService.selectedActivity);
      if (!this.device.isOffline && !this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID)) {
        await this.phoneCallDataService.updatePhoneCall(this.activityService.selectedActivity as PhoneActivity, phoneCallPayLoad);
        // this.activityService.isActivityUpdate = true;
      }
      else {
        this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity);
      }
      if (!this.uiService.toolsActivityActive) {
        this.events.publish('refreshAgenda');
      } else {
        this.uiService.agendaRefreshRequired = true;
      }
      await this.uiService.dismissLoader();
    }
  }

  private initConfigFieldsSetup(fromOnActivityChanges: boolean = false) {
    this.phoneCallConfigFields = [];
    this.phoneCallConfigFields = this.authService.user.getSupportedPhoneCallConfiguredFields();
    // to prevent infinite call to the same function.
    // causing when creating a new phone call activity.
    this.updateAppConfigFieldsOnActivityChanges(fromOnActivityChanges);
    this.setOrderToConfigFields();
  }

  private updateAppConfigFieldsOnActivityChanges(fromInitConfigFieldsSetup: boolean = false) {

    if (fromInitConfigFieldsSetup) {

      // Reset all the config fields value everything since this is a fresh activity.
      this.phoneCallConfigFields.forEach(field => {
        field.value = '';
        field.selectedValues = '';
        field.hasMultipleValues = false;
        field.multipleLabels = [];
        field.multipleValues = [];
        field.isEmptyRequiredField = false;
      });

      return;
    }

    let serviceAppConfigFields = this.phoneCallConfigFields;
    let currentActivity = this.activityService.selectedActivity;
    let appConfigFieldsFromCurrentActivity = currentActivity.appConfigFields;
    let notReplacedFields: string[] = [];

    if (serviceAppConfigFields.length) {
      if (appConfigFieldsFromCurrentActivity.length) {

        let indexToFind = 0;
        serviceAppConfigFields.forEach(sacf => {
          let fieldName = sacf.fieldName;
          if (fieldName) {
            let findExistingConfigFieldFromCurrentActivity = appConfigFieldsFromCurrentActivity.filter(field => { return field.fieldName === fieldName });
            if (findExistingConfigFieldFromCurrentActivity.length) {
              let firstObject = findExistingConfigFieldFromCurrentActivity[0];
              this.phoneCallConfigFields[indexToFind] = firstObject;
            } else {
              notReplacedFields.push(fieldName);
            }
          }
          indexToFind++;
        });

        if (notReplacedFields.length) {
          // Reset values for all the other fields.
          // So we will not have the duplicate values.
          this.phoneCallConfigFields.forEach(field => {
            let fieldName = field.fieldName;
            if (notReplacedFields.includes(fieldName)) {
              field.value = '';
              field.selectedValues = '';
              field.hasMultipleValues = false;
              field.multipleLabels = [];
              field.multipleValues = [];
              field.isEmptyRequiredField = false;
            }
          });
        }

      } else {
        // Get the latest config fields from the service.
        this.initConfigFieldsSetup(true);
      }
    }
  }

  private setOrderToConfigFields() {
    // sort the appConfigFields according to the order
    this.phoneCallConfigFields.sort((a, b) => {
      if (a.order && b.order) {
        if (a.order > b.order) return 1;
        else if (a.order < b.order) return -1;
        else {
          return a.fieldLabel < b.fieldLabel ? -1 : 1;
        }
      } else if (a.order || b.order) {
        if (a.order === null) {
          return 1;
        } else if (b.order === null) {
          return -1;
        }
        if (a.order > b.order) return 1;
        else if (a.order < b.order) return -1;
        else {
          return a.fieldLabel < b.fieldLabel ? -1 : 1;
        }
      }
    });
  }

  initializeSectionHeader() {
    let buttons = [];
    buttons.push({
      id: 'save',
      text: this.translate.instant('SAVE'),
      isDisabled: !this.isNotesDirty || this.activityService.selectedActivity && this.activityService.selectedActivity?.isCompleted,
    });
    buttons.push({
      id: 'cancel',
      text: this.translate.instant('CANCEL'),
      isDisabled: !this.isNotesDirty || this.activityService.selectedActivity && this.activityService.selectedActivity?.isCompleted,
    });

    this.callDetailsHeaderModel = {
      id: 'callDetails',
      title: `${this.translate.instant('CALL_DETAILS_CAP')}`,
      controls: []
    },
      this.notesHeaderModel = {
        id: 'notes',
        title: `${this.translate.instant('ACTIVITY_DETAILS_NOTES')}`,
        controls: buttons,
      };
  }

  onSectionHeaderControlClick(id: string) {
    switch (id) {
      case "save":
        this.saveNotes();
        break;
      case "cancel":
        this.cancelNotes();
        break;
      default:
        console.info("Unhandled switch case");
    }
  }

  scrollToTop() {
    let scrollIntoViewOptions: object = {
      behaviour: "smooth"
    };
    //now the divider is tagged based on their given dates , get the id of each divider and call the MSDN-DOM routine on it
    //let ele = document.querySelector(currentDate);
    let ele = document.getElementById('listScroll');
    if (ele) {
      ele.scrollIntoView(scrollIntoViewOptions);
    }
  }

  /**
   * @description This method closes the view and navigates to its previews view if it is a mobile device
   * @param no params
   * @returns void
   */
  closePage(eventId: string = null): void {

    if (this.uiService.showCancelDoneOnActivityDetails) {
      if (eventId == 'done' || eventId == 'close') {
        this.scrapWithoutConfirmationAndGoBack(false);
      }
      else {
        this.scrapWithoutConfirmationAndGoBack(true);
      }
      this.uiService.showCancelDoneOnActivityDetails = false;
    }
    if (this.activityService.selectedActivity && this.activityService.selectedActivity.accessedFrom == PageName.ContactTimelineComponent)  {
      this.uiService.contactDetailsSegment = "timeline";
      this.uiService.agendaRefreshRequired = this.uiService.timelineRefreshRequired = this.activityReopenedFromTimeline;
    }
    if (this.activityService.selectedActivity && this.activityService.selectedActivity.accessedFrom == PageName.AccountTimelineComponent) {
      if (_.isEmpty(this.accountService.selected)) {
        this.accountService.selected = this.accountService.tempSelected;
        this.accountService.tempSelected = null;
      }
      this.uiService.agendaRefreshRequired = this.uiService.timelineRefreshRequired = this.activityReopenedFromTimeline;
    }

    if(this.activityService.selectedActivity && this.activityService.selectedActivity.accessedFrom == PageName.MarketingPlanManagementPageComponent){
      if (_.isEmpty(this.accountService.selected)) {
        this.accountService.selected = this.accountService.tempSelected;
        this.accountService.tempSelected = null;
      }
      this.uiService.agendaRefreshRequired = this.uiService.timelineRefreshRequired = this.activityReopenedFromTimeline;
    }

    if (this.isLayoverPushView && this.layoverViewPushedFrom) {
      if (this.layoverViewPushedFrom == 'AccountManagementTool'
        || this.layoverViewPushedFrom == 'AccountTool' ||
        this.layoverViewPushedFrom == 'AccountTimeline') {
        this.navService.popChildNavPageWithPageTracking();
        this.footerService.initButtons('');
        if (this.uiService.activitiesPagePlanTabSelectedPlan === 'accountPlansTab') {
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        } else {
          this.uiService.activeView = "AccountManagement";
        }
      }
      return;
    } else if (this.navService.getCurrentPageName() == PageName.ContactPageComponent) {
      this.uiService.showNewActivity = false;
      this.navService.popChildNavPageWithPageTracking();
      this.footerService.initButtons(FooterViews.Contacts);
    } else if (this.navService.getCurrentMasterPageName() === PageName.CallPlanComponent || this.navService.getCurrentMasterPageName() === PageName.CallPlanDetails) {
      this.uiService.showNewActivity = false;
      this.activityService.selectedActivity = undefined;
      this.uiService.activeView = 'callPlanDetails';
      this.callPlanService.callPlanPageView = 'CallPlanDetail';
      this.navService.popChildNavPageWithPageTracking();
      this.footerService.initButtons(FooterViews.CallPlanDetails);
    }else if (this.navService.getCurrentMasterPageName() === PageName.CustomerCallPlanPage || this.navService.getCurrentMasterPageName() === PageName.CustomerCallPlanDetails) {
      this.uiService.showNewActivity = false;
      this.activityService.selectedActivity = undefined;
      this.uiService.activeView = 'callPlanDetails';
      this.callPlanService.callPlanPageView = 'CallPlanDetail';
      this.navService.popChildNavPageWithPageTracking();
      this.footerService.initButtons(FooterViews.CustomerCallPlan);
    }
     else if (this.navService.getCurrentPageName() == PageName.ScientificActivityPage) {
      this.footerService.initButtons('');
      if (this.from === PageName.ContactDetailsComponent) {
        this.navService.popChildNavPageWithPageTracking();
      }
    } else if (this.navService.getCurrentMasterPageName() == PageName.CoachingActivitiesComponent || this.navService.getCurrentPageName() == PageName.AccountPlanActivities) {
      this.activityService.selectedActivity = undefined;
      this.coachingReportService.activityClick = false;
      this.navService.setChildNavRightPaneView(false);
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, this.navService.getCurrentMasterPageName());
    } else if(this.navService.getCurrentMasterPageName() == PageName.AccountPageComponent){
      this.navService.popChildNavPageWithPageTracking();
      this.footerService.initButtons('');
    }else if (this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPageTab == 'plans') {
      this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      this.uiService.showNewActivity = false;
      this.uiService.showRightPane = true;
      this.footerService.initButtons('');
      if (this.uiService.activitiesPagePlanTabSelectedPlan === 'planTabTodo') {
        if (this.device.shouldBeMobileView) {
          this.activityService.selectedActivity = undefined;
          this.uiService.showRightPane = false;
        }
        this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
      } else {
        this.navService.popChildNavPageWithPageTracking();
      }
      return;
    }else if ( this.navService.getCurrentMasterPageName() === PageName.MarketingPlanManagementPageComponent){
      this.activityService.selectedActivity = undefined;
      this.navService.popChildNavPageWithPageTracking();
      this.footerService.initButtons(FooterViews.AccountPlanObjectives);
      // this.navService.setChildNavRightPaneView(false);
    }
    if (this.device.isMobileDevice) {
      this.uiService.showRightPane = false;
      this.activityService.shouldScrollToPrevSelectedActivity = !this.activityService.didOpenDetailPageFromAgendaPage;
      if (this.navService.getActiveChildNavViewPageName() === PageName.PhoneCallDetailsComponent && this.navService.getCurrentPageName() == PageName.ActivitiesPageComponent) {
        this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
      }
    } else {
      this.uiService.showNewActivity = false;
      // this.uiService.activeView = this.uiService.prevView;
    }
    this.activityService.selectedActivity = undefined;
    this.activityService.didOpenDetailPageFromAgendaPage = false;
  }

  openCoachingReportDetails(report: Report) {
    if (this.enableCoachingView) return;
    this.trackingService.tracking('CoachingMeetingDetails', TrackingEventNames.COACHING);
    let selectedReport: Report = JSON.parse(JSON.stringify(report));
    this.coachingReportService.selectedReport.next(selectedReport);
    this.coachingReportService.coachingViewtype = COACHING_VIEW_TYPE.FROM_PHONECALL;
    this.coachingReportService.activeTab = "teamCoaching";
    this.uiService.prevView = this.uiService.activeView;
    if (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent) {
      this.uiService.activeView = 'ActivitiesPageRightPaneNav';
    } else if (this.navService.getCurrentMasterPageName() === PageName.ContactPageComponent) {
      this.uiService.activeView = 'ContactPageRightPaneNav';
    } else if (this.navService.getCurrentMasterPageName() === PageName.AccountPageComponent) {
      this.uiService.activeView = 'AccountPageRightPaneNav';
    }
    this.navService.pushChildNavPageWithPageTracking(CoachingDetailsComponent, PageName.CoachingDetailsComponent, PageName.ActivitiesDetailsPaneComponent);
    this.activityService.lastActivity = this.activityService.selectedActivity.ID;
    this.uiService.showFieldCoaching = true;
    this.footerService.initButtons(FooterViews.CoachingDetails);
    if ((report.statuscode === 548910001 || report.statuscode === 548910002 || report.statuscode === 548910003) && !this.coachingReportService.isOffline(report)) {
      this.coachingReportService.checkCoachingOnline(report).then(() => {
        this.setCoachings();// Reset status in coachings list
      });
    }
  }

  public get enableCoachingView() {
    return this.navService.getPreviousPageName() === PageName.CoachingComponent ||
      this.navService.getPreviousPageName() === PageName.CoachingActivitiesComponent;
  }

  /**
   *
   * @param shouldDelete
   * if shouldDelete is true, delete phone call without confirmation and go back to previous page
   * go back to previous page otherwise
   *
   */
  private scrapWithoutConfirmationAndGoBack(shouldDelete: boolean = false) {
    if (shouldDelete) {
      this.events.publish('deleteMeetingNoConfirm');
    }
    if (this.uiService.activeView == "phoneCallTimelineDetails" && this.navService.getCurrentPageName() == PageName.ContactPageComponent) {
      //this.events.publish('contact-details:confirmedNewActivity');
      this.activityService.selectedActivity = undefined;
      this.uiService.showCancelDoneOnActivityDetails = false;
      this.uiService.showNewActivity = false;
      this.uiService.activeView = "contactDetails";
      this.uiService.showRightPane = false;
      this.footerService.initButtons(FooterViews.Contacts);
    }
  }

  /**
   * @description This method uses to close the page based on condition in switch case.
   * @param {id=} takes param as id
   * @returns void
   */
  public onPageTitleControlClick(id: string): void {
    switch (id) {
      case 'scrap':
        this.handlePhoneCallScrap();
        break;
      case 'markComplete':
        this.markComplete(true);
        break;
      case 'cancel':
      // this.closePage();
      // break;
      case 'done':
      // this.markNewActivityOkay();
      // break;
      case 'close':
        this.closePage(id);
        break;
      case 'reopen':
        this.reopenPhoneCall();
        break;
    }
  }

  public get enableBackButton() {
    return ((
      (this.navService.getCurrentMasterPageName() !== PageName.ActivitiesPageComponent &&
      this.navService.getCurrentPageName() !== PageName.AccountPlanActivities &&
      this.navService.getCurrentMasterPageName() !== PageName.CoachingActivitiesComponent)
      || this.from === PageName.ContactTimelineComponent
      || (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent && !this.footerService.isActivitiesButton)))
      && !this.uiService.showCancelDoneOnActivityDetails
  }

  /**
   * @description This method uses to save the NewActivity and makes navigate backs to its previous page based on
   * the task selected.
   * @param none
   * @returns void
   */

  markNewActivityOkay(): void {
    this.uiService.showCancelDoneOnActivityDetails = false;
    if (this.isLayoverPushView) {
      if (this.layoverViewPushedFrom == 'AccountManagementTool' || this.layoverViewPushedFrom == 'AccountTool') {
        this.footerService.initButtons('');
        this.navService.popChildNavPageWithPageTracking();
      }
    }
  }

  /**
   * @description This method uses to save the therapeutic area to the dynamics and db
   * and it calls the activity service to accoumplish the task.
   * @param {ta=} takes therapeutic area object to save
   * @returns void
   */
  async onSaveTA(ta) {
    if (this.activityService.selectedActivity instanceof PhoneActivity) {
      await this.uiService.displayLoader();
      this.activityService.selectedActivity.activityTherapeuticAreas = this.activityService.selectedActivity.activityTherapeuticAreas = ta.filter(item => item.isSelected || item.isAutoSelected);
      let phoneCallPayLoad = this.phoneCallDataService.setPhoneCallPayload(this.activityService.selectedActivity);
      if (!this.device.isOffline && !this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID)) {
        await this.phoneCallDataService.updatePhoneCall(this.activityService.selectedActivity as PhoneActivity, phoneCallPayLoad);
        // this.activityService.isActivityUpdate = true;
      }
      else {
        await this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity);
      }
      this.initFormFields();
    }
    await this.uiService.dismissLoader();
  }

  /**
  * @description This method uses to initialise the therapeutic area and check for its completion
  * and pushes to therapeutic area array with the selected TA.
  * @param no params
  * @returns void
  */

  initTherapeuticAreas(): void {
    this.therapeuticAreas = [];
    if (this.activityService.selectedActivity instanceof PhoneActivity) {
      if (!this.activityService.selectedActivity.isCompleted && !this.isReadOnlyJointPhoneCall) {
        this.theraputicService.therapeuticAreas.forEach(ta => {
          let selectedTA = this.activityService.selectedActivity['activityTherapeuticAreas'].find(item => item.therapeuticareaId === ta.therapeuticareaId);
          if (selectedTA) {
            this.therapeuticAreas.push(selectedTA);
          } else {
            this.therapeuticAreas.push(new TherapeuticArea(ta.TherapeuticAreaDTO));
          }
        });
      } else {
        // this.therapeuticAreas = _.unionBy(this.activityService.selectedActivity.activityTherapeuticAreas, this.theraputicService.therapeuticAreas, 'therapeuticareaId');
        this.therapeuticAreas= this.activityService.selectedActivity.activityTherapeuticAreas;
      }
    } else {
      this.therapeuticAreas = JSON.parse(JSON.stringify(this.theraputicService.therapeuticAreas));
    }
  }

  ionViewWillEnter() {
    if(this.caseManagementService.accessedFrom != AccesingMode.IN_PHONE_CALL
    && this.emailService.viewType != EmailViewType.CREATE_FROM_PHONE_CALL) {
      if (this.navService.getActiveChildNavViewPageName() === PageName.PhoneCallDetailsComponent) {
        this.footerService.initButtons(FooterViews.PHONE_CALL);
      }
    }
  }

  ngOnDestroy(): void {
    //this.translate.onLangChange.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if (this.configFieldsSubscription) this.configFieldsSubscription.unsubscribe();
    this.events.unsubscribe('detectChangesOnPhoneCallActivityDetails');
    this.events.unsubscribe('completePhonecall');
    this.events.unsubscribe('UpdatePhonecallActivity');
    if (this.coachingActivitySubscription) this.coachingActivitySubscription.unsubscribe();
    this.dateFormatSubscription.unsubscribe();
    this.voiceCallService.logout();
  }

  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */
  private initActivitiesDetailsPageTitle(): void {
    let buttons = [];
    if (this.enableBackButton) {
      buttons.push({
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      });
    } else {

      if (this.uiService.showCancelDoneOnActivityDetails) {
        buttons.push(
          {
            id: "close",
            icon: "chevron-back-outline",
            isDisabled: false,
            align: "left"
          })
      }
    }
    if (this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_REOPEN)) {
      buttons.push({
        id: "reopen",
        imgSrc: 'assets/imgs/header_activity_reopen.svg',
        name: this.translate.instant('REOPEN'),
        isDisabled: !this.isSelectedActivityReopenable,
        align: "right"
      });
    }
    buttons.push(
      {
        id: "scrap",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('SCRAP'),
        isDisabled: this.backgroundUploadInProgress || this.isTeamViewEnabled() || !this.isScrapButtonEnabled,
        align: "right"
      },
      {
        id: "markComplete",
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('COMPLETE'),
        isDisabled: this.backgroundUploadInProgress || this.isTeamViewEnabled() || this.isReadOnlyJointPhoneCall || (this.activityService.selectedActivity && this.activityService.selectedActivity.state == this.phonecallState.Completed),
        align: "right"
      }
    );


    this.phoneCallDetailsPanePageTitle = {
      id: 'phone-call-details-page-title',
      title: this.activitiesDetailsPanePageTitleText,
      controls: buttons,
    };

    this.phonecallProcessView = [];

    this.detailsSectionId = 'details' + Math.random().toString(36).substring(7);
    this.phonecallProcessView.push({
      id: 'detailsprocess' + Math.random().toString(36).substring(7),
      title: this.translate.instant('ACTIVITY_CALL_DETAILS'),
      bindingElementId: this.detailsSectionId,
      isActive: true,
    });

    this.callStSectionId = 'callstructure' + Math.random().toString(36).substring(7);
    this.phonecallProcessView.push({
      id: 'callstructureprocess' + Math.random().toString(36).substring(7),
      title: this.translate.instant('CALL_ACTIVITIES'),
      bindingElementId: this.callStSectionId,
      isActive: false,
    });

    this.keyMessagesSectionId = 'callproductkeymessages' + Math.random().toString(36).substring(7);
    this.phonecallProcessView.push({
      id: 'callproductkeymessagesprocess' + Math.random().toString(36).substring(7),
      title: this.translate.instant('PRODUCT_KEY_MESSGES_CAMEL_CASE'),
      bindingElementId: this.keyMessagesSectionId,
      isActive: false,
    });

    if (this.isTherapeuticAreaEnabled) {
      this.therapeuticSectionId = 'calltherapeuticareas' + Math.random().toString(36).substring(7);
      this.phonecallProcessView.push({
        id: 'calltherapeuticareasprocess' + Math.random().toString(36).substring(7),
        title: this.translate.instant('THERAPEUTIC_AREAS'),
        bindingElementId: this.therapeuticSectionId,
        isActive: false,
      });
    }

    if (this.isNotesEnabled) {
      this.callNotesSectionId = 'phonecallnotes' + Math.random().toString(36).substring(7);
      this.phonecallProcessView.push({
        id: 'phonecallnotesprocess' + Math.random().toString(36).substring(7),
        title: this.translate.instant('NOTES'),
        bindingElementId: this.callNotesSectionId,
        isActive: false,
      });
      let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
       this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
    }

    if (this.isCoachingEnabled && !this.activityService.teamViewActive) {
      this.coachingSectionId = 'phonecallcoaching' + Math.random().toString(36).substring(7);
      this.phonecallProcessView.push({
        id: 'phonecallcoachingprocess' + Math.random().toString(36).substring(7),
        title: this.translate.instant('COACHING'),
        bindingElementId: this.coachingSectionId,
        isActive: false,
      });
    }

    if (this.isCallObjectivesEnabled) {
      this.callObjectiveSectionId = 'phonecallobjectives' + Math.random().toString(36).substring(7);
      this.phonecallProcessView.push({
        id: 'phonecallobjectivesprocess' + Math.random().toString(36).substring(7),
        title: this.translate.instant('CALL_OBJECTIVES'),
        bindingElementId: this.callObjectiveSectionId,
        isActive: false,
      });
    }
  }

  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */
  closepage(create: boolean) {

  }

  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */
  handlePhoneCallScrap() {
    if (this.isScrapButtonEnabled) {
      this.trackingService.tracking('Select Scrap', TrackingEventNames.PHONECALL)
      this.events.publish('deleteMeeting');
    }
  }

  /**
   * returns true if phone call activity is editable
   * fakse otherwise
   */
  isThisPhoneCallEditable() {
    let flag: boolean = true;

    if (this.activityService.selectedActivity) {
      let activity = this.activityService.selectedActivity
      if (activity instanceof PhoneActivity && (this.backgroundUploadInProgress || this.isReadOnlyJointPhoneCall || activity.state == this.phonecallState.Completed || activity.ownerId !== this.authService.user.systemUserID)) {
        flag = false
      }
    }
    return flag
  }

  isTeamViewEnabled(): boolean {
    return this.activityService.teamViewActive;
  }

  public get isScrapButtonEnabled(): boolean {
    let flag: boolean = false;
    if (this.activityService.selectedActivity) {
      if (this.activityService.selectedActivity.state == this.phonecallState.Open && !this.activityService.teamViewActive && (this.activityService.selectedActivity && this.activityService.selectedActivity.ownerId == this.authService.user.systemUserID)) {
        flag = true;
      }
    }
    return flag;
  }

  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */
  async markComplete(isFromActivityDetails?: boolean) {
    if (this.activityService.selectedActivity && this.activityService.selectedActivity.state == this.phoneCallActivityState.Completed) return;
    if (this.isTeamViewEnabled()) return;
    this.checkMandatoryFields();
    if (this.isCheckMandatoryFieldValidationIsFailed === true) {
      this.notificationService.notify(this.translate.instant('APP_CONFIG_FIELDS_MESSAGE_PHONE', { appConfigFieldName: this.configMandatoryField.toLowerCase() }), 'Meeting Details', 'top', ToastStyle.DANGER);
      return
    }
    if (this.activityService.phonecallActivityCanComplete && this.activityService.selectedActivity instanceof PhoneActivity && !this.blockUI) {
      // Blocking UI to prevent multi request
      // if (this.uiService.showCancelDoneOnActivityDetails) {
      //   this.uiService.showCancelDoneOnActivityDetails = false;
      // }
      if (isFromActivityDetails) this.trackingService.tracking('SelectMarkComplete', TrackingEventNames.PHONECALL, null, true)

      await this.alertService.showAlert(
        {
          title: this.translate.instant('MARK_COMPLETE'),
          message: this.authService.hasFeatureAction(FeatureActionsMap.PHONECALL_REOPEN) && this.authService.user.pastMeetingReopenPeriod != 0 ? this.translate.instant('PHONE_CALL_COMPLETE') : this.translate.instant('PHONE_NO_MODIFIED')
        }, this.translate.instant('SUBMIT')
      ).then(async res => {
        if (res.role === 'ok') {
          if (this.backgroundUploadInProgress) return;
          this.blockUI = true;
          const loader = await this.loadingController.create({});
          loader.present();
          this.activityService.selectedActivity.completed = new Date();

          await this.phoneCallDataService.updatePhonecallActivityStatus(<PhoneActivity>this.activityService.selectedActivity, PhonecallStatus.COMPLETED, new Date().getTime())
            .then(() => {
              // Remove conflict ID from other activities
              removeConflictIdFromOtherActivities(
                this.activityService.selectedActivity,
                this.activityService.activities,
                this.eventsToolService.eventsToolData,
              );
              this.displayConflictMsg = this.checkConflictMsgDisplay(true);
              this.notificationService.notify(this.translate.instant("PHONECALL_COMPLETED"), 'PhoneCall Details');
              this.footerService.initButtons(FooterViews.PHONE_CALL);
              this.blockUI = false;
              loader.dismiss();
              this.events.publish('detectChangesOnPhoneCallActivityDetails')
              this.events.publish('phone-call-activity-updated', {'eventType':'markcomplete'});
              this.events.publish('phoneCallActivityCompleted', this.activityService.selectedActivity);
              this.initTherapeuticAreas();
              this.callPlanDataService.syncCallPlanActivities();
            }, (error) => {
              console.error('markComplete: ', error);
              // this.notificationService.notify("Meeting complete action failed", undefined, 'red-toast-alert', 3000);
              this.blockUI = false;
              loader.dismiss();
            })
            .catch((error) => {
              console.error('markComplete: ', error);
              // this.notificationService.notify("Meeting complete action failed", undefined, 'red-toast-alert', 3000);
              this.blockUI = false;
              loader.dismiss();
            })
        }
      });
    }
  }


  async reopenPhoneCall() {
    if (!this.isSelectedActivityReopenable) return;
    await this.alertService.showAlert({
      title: this.translate.instant('REOPEN_PHONECALL'),
      message: this.translate.instant('PHONECALL_REOPEN_CONFIRMATION')
    }, this.translate.instant('REOPEN')
    ).then(async res => {
      if (res.role === 'ok') {
        if (this.backgroundUploadInProgress) return;
        this.uiService.displayLoader();
        await this.phoneCallDataService.updatePhonecallActivityStatus(<PhoneActivity>this.activityService.selectedActivity, PhonecallStatus.OPEN)
          .then(() => {
            // Check conflicts
            checkTimeConflictWithOtherActivities(
              this.activityService.selectedActivity,
              this.activityService.activities,
              this.activityService.conflictCheckStartIdx,
              this.eventsToolService.eventsToolData,
              this.eventsToolService.conflictCheckLastIdx,
              this.authService.user.xSystemUserID,
            );
            this.displayConflictMsg = this.checkConflictMsgDisplay(true);
            this.footerService.initButtons(FooterViews.PHONE_CALL);
            this.blockUI = false;
            if (this.activityService.selectedActivity.accessedFrom == PageName.AccountTimelineComponent) this.activityReopenedFromTimeline = true;
            this.callPlanDataService.syncCallPlanActivities();
            this.uiService.dismissLoader();
            this.events.publish('detectChangesOnPhoneCallActivityDetails')
            this.events.publish('phone-call-activity-updated', {'eventType':'reopen'});
            this.events.publish('phone-call-reopened',this.activityService.selectedActivity);
            this.initTherapeuticAreas();
          }, (error) => {
            console.error('reopenPhoneCall: ', error);
            this.blockUI = false;
            this.uiService.dismissLoader();
          })
          .catch((error) => {
            console.error('reopenPhoneCall: ', error);
            this.blockUI = false;
            this.uiService.dismissLoader();
          })
      }
    });
  }

  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */
  public getStatusFormField(): IndFormFieldViewDataModel {
    if (!this.activityService.selectedActivity) return;
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: false,
      label: this.translate.instant('STATUS'),
      inputText: this.phoneCallStatusString,
      id: 'status-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  /**
   * returns the status string
   */
  public get phoneCallStatusString(): string {
    let status: string = this.activityService.selectedActivity.getStatusString;
    switch (this.activityService.selectedActivity.getStatusString) {
      case 'Open':
        status = this.translate.instant('OPEN');
        break;
      case 'Completed':
        status = this.translate.instant('COMPLETED');
        break;
      case 'Canceled':
        status = this.translate.instant('CANCELLED');
        break;
      case 'Scheduled':
        status = this.translate.instant('SCHEDULED');
        break;
      default:
        status = this.translate.instant('N_A');
        break;
    }
    return status;
  }
  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */

  public getSubjectFormField(): IndFormFieldViewDataModel {
    let subjectText: string = '';
    if(!_.isEmpty(this.activityService.selectedActivity)) {
      subjectText= this.activityService.getActivitysubject(this.activityService.selectedActivity);
    }
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('SUBJECT'),
      inputText: subjectText,
      inputValue: subjectText,
      id: 'subject-field',
      isReadOnly: this.isReadOnlyJointPhoneCall || this.isTeamViewEnabled() || this.activityService.selectedActivity && this.activityService.selectedActivity.state == MeetingActivityState.Completed,
      isDisabled: this.backgroundUploadInProgress || this.isReadOnlyJointPhoneCall || this.isTeamViewEnabled() || this.activityService.selectedActivity && this.activityService.selectedActivity.state == MeetingActivityState.Completed,
      placeholderLabel: this.translate.instant('SUBJECT'),
      showArrow: !(this.isReadOnlyJointPhoneCall || this.isTeamViewEnabled() || this.activityService.selectedActivity && this.activityService.selectedActivity.state == MeetingActivityState.Completed),
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !(this.isReadOnlyJointPhoneCall || this.isTeamViewEnabled() || this.activityService.selectedActivity && this.activityService.selectedActivity.state == MeetingActivityState.Completed),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.phoneCallDetailsPanePageTitle = { ...this.phoneCallDetailsPanePageTitle, title: viewData.inputValue };
    return viewData;
  }

  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */
  public getAccountsField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNTS'),
      inputText: (this.activityService.selectedActivity && (this.activityService.selectedActivity as PhoneActivity).accounts && (this.activityService.selectedActivity as PhoneActivity).accounts.length != 0) ? (this.activityService.selectedActivity as PhoneActivity).accountString : '',
      customPlaceholderLabel: ((this.activityService.selectedActivity && !this.isReadOnlyJointPhoneCall && this.activityService.selectedActivity.state != PhoneCallActivityState.Completed)) ? this.translate.instant('SELECT_ACCOUNTS') : this.translate.instant('NO_ACCOUNT'),
      id: 'account-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || !(this.activityService.selectedActivity && !this.isReadOnlyJointPhoneCall && !this.isTeamViewEnabled() && (this.activityService.selectedActivity.state != PhoneCallActivityState.Completed || (this.activityService.selectedActivity.state == PhoneCallActivityState.Completed && !_.isEmpty((this.activityService.selectedActivity as PhoneActivity).accounts)))),
      showArrow: (this.activityService.selectedActivity && !this.isReadOnlyJointPhoneCall && !this.activityService.teamViewActive && (this.activityService.selectedActivity.state != PhoneCallActivityState.Completed || (this.activityService.selectedActivity.state == PhoneCallActivityState.Completed && !_.isEmpty((this.activityService.selectedActivity as PhoneActivity).accounts)))),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public openContactSelection() {
    if (this.isReadOnlyJointPhoneCall && this.activityService.selectedActivity instanceof PhoneActivity && _.isEmpty(this.activityService.selectedActivity.contacts)) return;
    this.trackingService.tracking('SelectCustomer', TrackingEventNames.PHONECALL);
    this.uiService.prevView = this.uiService.activeView;
    this.uiService.showNewActivity = false;
    this.uiService.activeView = 'contactDetails';
    this.accountService.selectedFor = AccountSelectedFor.PHONE_CALL_SELECTION;
    if (this.activityService.selectedActivity && this.activityService.selectedActivity.state == PhoneCallActivityState.Completed || this.isReadOnlyJointPhoneCall) {
      this.contactService.contactPageMode = ComponentViewMode.READONLY;
    } else {
      this.contactService.contactPageMode = ComponentViewMode.SELECTION;
    }
    this.contactService.accessedContactListFrom = PageName.PhoneCallDetailsComponent;
    this.footerService.initButtons(FooterViews.PHONE_CALL);
    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, null, PageName.ContactPageComponent);
    this.isClickedContacts = true;
  }

  /**
   * getCustomersField().
   * @description
   *
   *
   *
   */
  public getCustomersField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant(this.utilityService.globalCustomersText),
      inputText: ((this.activityService.selectedActivity as PhoneActivity) && (this.activityService.selectedActivity as PhoneActivity).contacts && (this.activityService.selectedActivity as PhoneActivity).contacts.length != 0) ? (this.activityService.selectedActivity as PhoneActivity).contactString : '',
      customPlaceholderLabel: (this.isTeamViewEnabled() || this.isReadOnlyJointPhoneCall) ? this.translate.instant('No '+this.utilityService.globalCustomerText): this.translate.instant('SELECT_WITH_GLOBALCUSTOMER', {globalCustomerText :this.utilityService.globalCustomersText}) ,
      id: 'contact-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || this.isTeamViewEnabled() || (this.isReadOnlyJointPhoneCall && _.isEmpty(this.activityService.selectedActivity['contacts'])),
      showArrow: !this.isTeamViewEnabled() && !(this.isReadOnlyJointPhoneCall && _.isEmpty(this.activityService.selectedActivity['contacts'])),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isRequired: !this.isTeamViewEnabled() && !this.isReadOnlyJointPhoneCall && this.activityService.selectedActivity && this.activityService.selectedActivity.state != MeetingActivityState.Completed,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this.isClickedContacts && (!this.requiredFields['contact-field']['isAdded'] && (this.activityService.selectedActivity as PhoneActivity).contacts?.length == 0),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getCovisitorField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: (this.activityService.selectedActivity && this.activityService.selectedActivity.isCompleted && (!this.activityService.selectedActivity['accompaniedUserList'])) || !(this.hasJointPhoneCallFA || (this.activityService.selectedActivity && !_.isEmpty(this.activityService.selectedActivity['accompaniedUserList']))),
      label: this.translate.instant('ACTIVITY_DETAILS_COVISITORS'),
      inputText: (this.activityService.selectedActivity && !_.isEmpty((this.activityService.selectedActivity as PhoneActivity).accompaniedUserList)) ? (this.activityService.selectedActivity as PhoneActivity).accompaniedUserString : '',
      customPlaceholderLabel: ((this.activityService.selectedActivity && !this.activityService.teamViewActive && this.activityService.selectedActivity.state != PhoneCallActivityState.Completed)) ? this.translate.instant('ACTIVITY_DETAILS_SELECT_COVISITORS') : this.translate.instant('ACTIVITY_DETAILS_NO_COVISITORS'),
      id: 'covisitor-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || this.isTeamViewEnabled() || (this.activityService.selectedActivity && this.activityService.selectedActivity.isCompleted && _.isEmpty(this.activityService.selectedActivity['accompaniedUserList'])),
      showArrow: !(this.isTeamViewEnabled() || (this.activityService.selectedActivity && this.activityService.selectedActivity.isCompleted && _.isEmpty(this.activityService.selectedActivity['accompaniedUserList']))),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getCallRecord(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: "medias_14-1675083999.101144",
      inputText: 'medias_14-1675083999.101144',
      id: 'call-record-field',
      isReadOnly: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }
  public getScheduledByField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: !this.isReadOnlyJointPhoneCall,
      label: this.translate.instant('ACTIVITY_DETAILS_SCHEDULED_BY'),
      inputText: ((this.activityService.selectedActivity && this.activityService.selectedActivity.meetingOwnerName) ? this.activityService.selectedActivity.meetingOwnerName : ''),
      id: 'covisitor-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false
    };
    return viewData;
  }

  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */
  public getContactNumberField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PHONE_NUMBER_PHONECALL'),
      inputText: (this.activityService.selectedActivity && (this.activityService.selectedActivity as PhoneActivity).contacts && (this.activityService.selectedActivity as PhoneActivity).contacts.length != 0) ? (this.activityService.selectedActivity as PhoneActivity).selectedMobileNumber : undefined,
      id: 'init-voice-call-field',
      customPlaceholderLabel: this.translate.instant('PHONE_NUMBER_PHONECALL'),
      isReadOnly: true,
      isDisabled: this.isThisPhoneCallEditable() ? false : true,
      showArrow: false,
      formFieldType: FormFieldType.POPOVER_SELECT,
      phoneIcon: (this.voiceCallEnabled && (this.voiceCallStatus == 'CALLING' || this.voiceCallStatus == 'CALL_STARTED' || this.voiceCallStatus == 'RINGING' || this.voiceCallStatus == 'CALL_ATTENDED'))  ? 'assets/imgs/phone_end_icon.svg' : 'assets/imgs/phone_icon.svg',
      isRequired: !this.isTeamViewEnabled() && !this.isReadOnlyJointPhoneCall && this.activityService.selectedActivity && this.activityService.selectedActivity.state != MeetingActivityState.Completed,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    if (this.voiceCallEnabled) {

      viewData.phoneStatus = this.voiceCallStatus == 'CALL_ATTENDED' ? this.voiceCallService.formattedTime : this.translate.instant(this.voiceCallStatus);
    }
    return viewData;
  }

  public get durationField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    const activity = (this.activityService.selectedActivity as PhoneActivity);
    let time = "";
    if (activity && activity.indskr_actualdurationminutesandseconds != "0") {
      const t = this.voiceCallService.convertSecondsToTime(_.toNumber(activity.indskr_actualdurationminutesandseconds));
      time = `${t.hours > 0 ? t.hours + ' ' + this.translate.instant("HOURS") : ''} ${t.minutes} ${this.translate.instant("MINUTES")} ${t.seconds} ${this.translate.instant("SECONDS")}`;
    }
    viewData = {
      label: this.translate.instant('VOICE_CALL_DURATION'),
      inputText: time,
      id: 'init-voice-call-field',
      customPlaceholderLabel: this.translate.instant('VOICE_CALL_DURATION'),
      isReadOnly: true,
      isHidden: time == "",
      formFieldType: FormFieldType.INLINE_INPUT
    };
    return viewData;
  }

  ngAfterViewInit() {
    try {
      this.voiceCallService.initClinkToolBar();
      this.voiceCallService.login();
    } catch (err) {
      console.error("Error occurred while login to ti-net, ", err);
    }
    this.loadVoiceCallRecords();
  }

  private loadVoiceCallRecords() {
    if (!this.device.isOffline && this.activityService.selectedActivity && !this.refreshingCallRecords) {
      let fetchXML = fetchQueries.fetchVoiceCallRecords;
      this.refreshingCallRecords = true;
      try {
        fetchXML = fetchXML.replace('{phoneActivityId}', `${this.activityService.selectedActivity?.ID}`);
        this.dynamics.executeFetchQuery('indskr_callactivities', fetchXML).then(async data => {
          this.refreshingCallRecords = false;
          if (!_.isEmpty(data)) {

            const ids = _.map(data, "indskr_voicecallid").join(",");
            const voiceCallLinks: any = await this.phoneCallDataService.getVoiceCallRecordLinks(ids);
            if (!_.isEmpty(voiceCallLinks)) {

              this.callRecordings = data.map(cr => {
                const voiceCall = voiceCallLinks.find(c => c.uniqueId == cr.indskr_voicecallid);
                return {
                  indskr_starttime: cr.indskr_starttime,
                  indskr_endtime: cr.indskr_endtime,
                  indskr_duration: _.toNumber(cr.indskr_duration),
                  indskr_voicecallid: cr.indskr_voicecallid,
                  link: voiceCall?.recordFileUrl ? voiceCall?.recordFileUrl : ''
                }
              });
            }
          }
        });
      } catch (error) {
        console.log("Error while fetching call records : ", error);
        this.refreshingCallRecords = false;
      }
    }

  }

  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */
  ngAfterContentChecked() {
    let dateTimeValue = this.getMeetingDateTimeText();
    if (dateTimeValue) {
      this.phoneCallStartDateTime = dateTimeValue.startDateTime;
      this.phoneCallStartDate = dateTimeValue.startDate;
      this.phoneCallStartTime = dateTimeValue.startTime;
      this.phoneCallEndDateTime = dateTimeValue.endDateTime;
      this.phoneCallEndDate = dateTimeValue.endDate;
      this.phoneCallEndTime = dateTimeValue.endTime;
      this.duration = dateTimeValue.duration;
    }

    this.isNotesEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEETING_NOTES);
    this.isTherapeuticAreaEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.THERAPEUTIC_AREA);
    this.isReadOnlyJointPhoneCall = this.jointPhoneCallStatus;
  }

  public get jointPhoneCallStatus(): boolean {
    if (this.activityService.selectedActivity instanceof PhoneActivity) {
      //If the phonecall has been marked as joint meeting
      //Handle case where joint phonecall flag is set as undefined.
      if (this.activityService.selectedActivity.jointphonecall || (!_.isEmpty(this.activityService.selectedActivity.accompaniedUserList))) {
        const meetingOwnerId = this.activityService.selectedActivity.meetingOwnerId;
        const userId = this.authService.user.systemUserID;
        //owner
        if (meetingOwnerId === userId) {
          return false;
        }
        else
          return true;
      }
      //owner is the phonecall creator
      else {
        return false;
      }
    }
    else
      return false;
  }

  private initDateTimeFormFields() {
    let dateTimeValue = this.getMeetingDateTimeText();
    if (dateTimeValue) {
      this.phoneCallStartDateTime = dateTimeValue.startDateTime;
      this.phoneCallStartDate = dateTimeValue.startDate;
      this.phoneCallStartTime = dateTimeValue.startTime;
      this.phoneCallEndDateTime = dateTimeValue.endDateTime;
      this.phoneCallEndDate = dateTimeValue.endDate;
      this.phoneCallEndTime = dateTimeValue.endTime;
      this.duration = dateTimeValue.duration;
    }
    this.startDateField = {
      isDateField: true,
      label: this.translate.instant('START_DATE'),
      inputText: this.phoneCallStartDate,
      startDateTimeValue: this.phoneCallStartDateTime,
      endDateTimeValue: this.phoneCallEndDateTime,
      fromViewPage: CurViewPageType.PhoneCall,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: false,
      showArrow: this.isThisPhoneCallEditable() ? true : false,
      isRequired: this.isThisPhoneCallEditable() ? true : false,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.startTimeField = {
      isTimeField: true,
      label: this.translate.instant('START_TIME'),
      inputText: this.phoneCallStartTime,
      startDateTimeValue: this.phoneCallStartDateTime,
      endDateTimeValue: this.phoneCallEndDateTime,
      fromViewPage: CurViewPageType.PhoneCall,
      id: DateTimeFieldType.StartTimeField,
      isReadOnly: true,
      isDisabled: this.isThisPhoneCallEditable() ? false : true,
      showArrow: this.isThisPhoneCallEditable() ? true : false,
      isRequired: this.isThisPhoneCallEditable() ? true : false,
    };
    this.endDateField = {
      isDateField: true,
      label: this.translate.instant('END_DATE'),
      inputText: this.phoneCallEndDate,
      startDateTimeValue: this.phoneCallStartDateTime,
      endDateTimeValue: this.phoneCallEndDateTime,
      fromViewPage: CurViewPageType.PhoneCall,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: false,
      showArrow: this.isThisPhoneCallEditable() ? true : false,
      isRequired: this.isThisPhoneCallEditable() ? true : false,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.endTimeField = {
      isTimeField: true,
      label: this.translate.instant('END_TIME'),
      inputText: this.phoneCallEndTime,
      startDateTimeValue: this.phoneCallStartDateTime,
      endDateTimeValue: this.phoneCallEndDateTime,
      fromViewPage: CurViewPageType.PhoneCall,
      id: DateTimeFieldType.EndTimeField,
      isReadOnly: true,
      isDisabled: this.isThisPhoneCallEditable() ? false : true,
      showArrow: this.isThisPhoneCallEditable() ? true : false,
      isRequired: this.isThisPhoneCallEditable() ? true : false,
    };
  }

  private async updateAppConfigInputValuesForMultiOptionSet(fieldName, dataType, multipleLabels, multipleValues) {
    let appConfigFieldsfromService: ConfiguredFields[] = this.phoneCallConfigFields.filter(reqField => reqField.fieldName === fieldName);
    let currentConfigField = appConfigFieldsfromService[0];

    var appConfigFields: ConfiguredFields = {
      fieldName: currentConfigField.fieldName,
      datatype: dataType,
      value: multipleValues,
      fieldType: currentConfigField.fieldType,
      fieldLabel: currentConfigField.fieldLabel,
      order: currentConfigField.order,
      mandatory: currentConfigField.mandatory,
      readOnly: currentConfigField.readOnly
    };

    appConfigFields.selectedValues = multipleLabels;
    appConfigFields.multipleLabels = multipleLabels.split(', ')
    appConfigFields.multipleValues = multipleValues.split(', ')
    appConfigFields.hasMultipleValues = true;

    currentConfigField.selectedValues = multipleLabels;
    currentConfigField.value = multipleValues;
    currentConfigField.multipleLabels = multipleLabels.split(', ')
    currentConfigField.multipleValues = multipleValues.split(', ')
    currentConfigField.hasMultipleValues = true;

    this.phoneCallConfigFields.map(obj => appConfigFieldsfromService.find(o => o.fieldName == obj.fieldName) || obj);

    this.saveAdditionalConfigFields(appConfigFields);
  }

  public async updateAppConfigInputValues(myEvent, fieldName, dataType, inputValue?, isOptionSet: boolean = false,stringValue:string = '') {
    let appConfigFieldsfromService: ConfiguredFields[] = this.phoneCallConfigFields.filter(reqField => reqField.fieldName === fieldName);
    let currentConfigField = appConfigFieldsfromService[0];
    var appConfigFields: ConfiguredFields = {
      fieldName: currentConfigField.fieldName,
      datatype: dataType,
      value: inputValue != undefined && inputValue != null ? inputValue : (myEvent && myEvent.target && myEvent.target.value && myEvent.target.value.length != 0) ? myEvent.target.value : '',
      stringValue: stringValue,
      fieldType: currentConfigField.fieldType,
      fieldLabel: currentConfigField.fieldLabel,
      order: currentConfigField.order,
      mandatory: currentConfigField.mandatory,
      readOnly: currentConfigField.readOnly,
      isEmptyRequiredField: false,
      indskr_optionsetdefaultext: currentConfigField.indskr_optionsetdefaultext,
      indskr_optionsetdefaultvalue: currentConfigField.indskr_optionsetdefaultvalue,
    };

    if(currentConfigField.datatype == 'Lookup' || currentConfigField.fieldType == 'Lookup'){
      appConfigFields['indskr_lookupentitysetname'] = currentConfigField.indskr_lookupentitysetname;
      appConfigFields['indskr_referencingentitynavigationpropertyname'] = currentConfigField.indskr_referencingentitynavigationpropertyname;
      appConfigFields['indskr_lookupentityprimaryidattribute'] = currentConfigField.indskr_lookupentityprimaryidattribute;
      appConfigFields['indskr_lookupentityprimarynameattribute'] = currentConfigField.indskr_lookupentityprimarynameattribute;
      appConfigFields['indskr_lookupquery'] = currentConfigField.indskr_lookupquery;
      appConfigFields['indskr_lookupentityname'] = currentConfigField.indskr_lookupentityname;
    }

    if (isOptionSet) {
      appConfigFields.selectedValues = (this.appConfigPickValue && this.appConfigPickValue.label) ?? '';
      currentConfigField.selectedValues = (this.appConfigPickValue && this.appConfigPickValue.label) ?? '';
    } else {
      appConfigFields.selectedValues = appConfigFields.value;
      currentConfigField.selectedValues = appConfigFields.value;
    }

    if(_.isEmpty(appConfigFields.selectedValues)) this.phoneCallConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = true;
    else this.phoneCallConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = false;

    appConfigFields.multipleLabels = [];
    appConfigFields.multipleValues = [];
    appConfigFields.hasMultipleValues = false;

    currentConfigField.multipleLabels = [];
    currentConfigField.multipleValues = [];
    currentConfigField.hasMultipleValues = false;

    currentConfigField.value = appConfigFields.value;
    this.phoneCallConfigFields.map(obj => appConfigFieldsfromService.find(o => o.fieldName == obj.fieldName) || obj);
    this.saveAdditionalConfigFields(appConfigFields);
  }

  public async updateAppConfigBoolValues(fieldName, dataType, inputValue?) {
    let appConfigFieldsfromService: ConfiguredFields[] = this.phoneCallConfigFields.filter(reqField => reqField.fieldName === fieldName);
    let currentConfigField = appConfigFieldsfromService[0];
    var appConfigFields: ConfiguredFields = {
      fieldName: currentConfigField.fieldName,
      datatype: dataType,
      value: inputValue ? inputValue : false,
      fieldType: currentConfigField.fieldType,
      fieldLabel: currentConfigField.fieldLabel,
      order: currentConfigField.order,
      mandatory: currentConfigField.mandatory,
      readOnly: currentConfigField.readOnly
    };
    currentConfigField.value = appConfigFields.value;
    this.phoneCallConfigFields.map(obj => appConfigFieldsfromService.find(o => o.fieldName == obj.fieldName) || obj);
    this.saveAdditionalConfigFields(appConfigFields);
  }

  public async updateConfigDate(myEvent, fieldName, dateValue) {
    let appConfigFieldsfromService: ConfiguredFields[] = this.phoneCallConfigFields.filter(reqField => reqField.fieldName === fieldName);
    let currentConfigField = appConfigFieldsfromService[0];
    var appConfigFields: ConfiguredFields = {
      fieldName: currentConfigField.fieldName,
      datatype: 'Date',
      value: dateValue,
      fieldType: currentConfigField.fieldType,
      fieldLabel: currentConfigField.fieldLabel,
      order: currentConfigField.order,
      mandatory: currentConfigField.mandatory,
      readOnly: currentConfigField.readOnly
    };
    currentConfigField.value = appConfigFields.value;
    this.phoneCallConfigFields.map(obj => appConfigFieldsfromService.find(o => o.fieldName == obj.fieldName) || obj);
    this.saveAdditionalConfigFields(appConfigFields);
  }

  public async saveAdditionalConfigFields(appConfigFields) {
    if (!appConfigFields.entityName) {
      // For later use.
      appConfigFields['entityName'] = 'phonecall';
    }
    if (appConfigFields.fieldName && appConfigFields.fieldName.length) {
      // API requires this key (fieldname).
      let fieldName = appConfigFields.fieldName;
      appConfigFields['fieldname'] = fieldName;
    }
    this.pushAdditionalConfigOffline(appConfigFields);
    if (!this.device.isOffline && !this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID)) {
      this.uiService.displayLoader();
      await this.phoneCallDataService.updateappConfigFields((this.activityService.selectedActivity as PhoneActivity), [appConfigFields], true).then(() => {
        this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity);
        // this.activityService.isActivityUpdate = true;
        this.uiService.dismissLoader();
      });
    }
    else {
      await this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity);
      this.uiService.dismissLoader();
    }
  }

  public async openBooleanPopover(event, fieldName) {
    let appConfigBooleanValues = ['Yes', 'No'];
    const prevSelectedValue = this.appConfigInputBoolValue;
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'appConfigBooleanValues-select',
      data: appConfigBooleanValues.map(value => {
        let obj = {
          title: value,
          id: value,
          isSelected: false,
        };
        if (prevSelectedValue === value) {
          obj['isSelected'] = true;
        }
        return obj;
      }),
    };
    let popover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event
    });
    await popover.present();
    await popover.onDidDismiss().then(async (data: any) => {
      if (data && data.data && data.data.selectedItems) {
        if (this.backgroundUploadInProgress) return;
        let value = data.data.selectedItems[0].title;
        let requestValue = value === 'Yes' ? true : false;
        this.appConfigInputBoolValue = value;
        this.phoneCallConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = false;
        this.updateAppConfigBoolValues(fieldName, 'boolean', requestValue);
      } else {
        let fieldValue = this.phoneCallConfigFields.find(reqField => reqField.fieldName === fieldName).value;
        if(!fieldValue) this.phoneCallConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = true;
      }
    });
  }

  private async updateAdditionalFields(raw: any[]) {
    this.appConfigLookupValue = raw[0].name;
    this.ioUserSelection.setAdditionalConfigData(undefined);
    var appConfigFields: ConfiguredFields = {
      fieldName: raw[0].fieldName,
      datatype: 'Lookup',
      value: raw[0].id,
      fieldType: 'Lookup'
    };
    this.saveAdditionalConfigFields(appConfigFields);
  }

  private lookupData(fieldLabel, fieldName): any[] {
    switch (fieldLabel) {
      case 'Activity Type':
        let typesArray = this.activityService.meetingActivityTypes.filter(at => at.indskr_omnipresenceactivity === this.meetingActivityTypeCode.MEETING);
        let activityTypeSource: any = typesArray.map((obj) => {
          let values = {
            "name": obj.indskr_name,
            "id": obj.indskr_activitytypeid,
            "type": "activity",
            'fieldName': fieldName
          }
          return values
        })
        return activityTypeSource;
      case 'Activity Sub Type':
        let SubTypesArray = this.activityService.meetingSubActivityTypes.filter(at => at.indskr_omnipresenceactivity === this.meetingActivityTypeCode.MEETING);
        let subActivityTypeSource: any = SubTypesArray.map((obj) => {
          let values = {
            "name": obj.indskr_name,
            "id": obj.indskr_activitysubtypeid,
            "type": "sub activity",
            'fieldName': fieldName
          }
          return values
        })
        return subActivityTypeSource;
    }
  }

  public async openLookup(event, fieldName) {
    const configField = this.phoneCallConfigFields.find(a=> a.fieldName == fieldName);
    if(configField){
      if (fieldName === 'indskr_activitytype' || fieldName === 'indskr_activitysubtype') {
        this.ioUserSelection.setCaseSelectorSource(configField.fieldLabel);
        let dataSource: any = this.lookupData(configField.fieldLabel, fieldName);
        this.ioUserSelection.setSelectionDataSource(dataSource);
        this.uiService.showNewActivity = false;
        setTimeout(() => {
          this.navService.pushWithPageTracking(IOMultiSelect, PageName.IOMultiSelect, { remoteMeetingConnectionMapping: true }, PageName.IOMultiSelect);
        }, 10);
      }else {
        let results = [];
        if(this.device.isOffline){
          if(this.lookupConfigFieldsData && this.lookupConfigFieldsData[configField.fieldName]){
            results = this.lookupConfigFieldsData[configField.fieldName]
          }
        }else{
          results = await this.activityDataService.getConfigFieldLookupResults(configField,null);
        }
        let currentSelectedValue:string;
        if(this.activityService.selectedActivity instanceof PhoneActivity && this.activityService.selectedActivity.appConfigFields && this.activityService.selectedActivity.appConfigFields.length > 0){
          const currentConfigField = this.activityService.selectedActivity.appConfigFields.find(a=> a.fieldName == fieldName);
          if(currentConfigField && currentConfigField.value){
            currentSelectedValue = currentConfigField.value;
          }
        }
        let lookupPopoverModel:IndDropdownListDetailModel = {
          id: 'config-fields-lookup-select',
          isSearchEnabled:true,
          searchInputPlaceholder: this.translate.instant('SEARCH'),
          data: results.map(item=> {
            let data:SelectListData = {
              title: item[configField.indskr_lookupentityprimarynameattribute],
              id: item[configField.indskr_lookupentityprimaryidattribute],
              isSelected: currentSelectedValue == item[configField.indskr_lookupentityprimaryidattribute] ? true : false,
            }
            return data;
          })
        };
        let lookupPopover = await this.popoverCtrl.create({
          component: IndDropdownListComponent,
          componentProps: { viewData: lookupPopoverModel },
          cssClass: 'dropdown-list-view',
          event: event
        });
        lookupPopover.onDidDismiss().then(async (data: any) => {
          data = data.data;
          if (data && data.selectedItems && data.selectedItems.length == 1) {
            let selectedPopOverValue = data.selectedItems[0].id;
            let selectedPopOverStringValue = data.selectedItems[0].title;
            this.phoneCallConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = false;
            // this.saveAdditionalConfigFields(appConfigFields);
            // let lookupConfigFieldsPayload:UpdateTypeAndSubTypeActivityPayLoad = new UpdateTypeAndSubTypeActivityPayLoad(
              // configField.fieldName,
              // configField.indskr_lookupentitysetname,
              // configField.entityName,
              // data.selectedItems[0].id,
            // );
            // await this.meetingDataService.updateActivityLookupFields(this.activityService.selectedActivity as AppointmentActivity,lookupConfigFieldsPayload);
            this.updateAppConfigInputValues(event, fieldName, 'Lookup', selectedPopOverValue, false,selectedPopOverStringValue);
          } else if (data && data.selectedItems && data.selectedItems.length == 0) {
            //this._clearAttributeValue(targetControl);
            let fieldValue = this.phoneCallConfigFields.find(reqField => reqField.fieldName === fieldName).value;
            if(!fieldValue) this.phoneCallConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = true;
          }
          //if(targetControl.isRequired && this.dynamicFormsService.getFormFieldType(targetControl) !== FormFieldType.INLINE_INPUT){
          lookupPopover = undefined;
        });
        lookupPopover.present();
      }
    }
    
  }

  public async updateConfigFieldDate(isDone: boolean) {
    if (isDone) {
      const m = moment(this.appConfigFieldDate);
      if (!m.isValid()) {
        this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'appconfigfields', 'top', ToastStyle.DANGER);
        return;
      }
      this.phoneCallConfigFields.find(reqField => reqField.fieldName === this.dateFieldName).isEmptyRequiredField = false;
      this.appConfigDateValue = new Date(this.appConfigFieldDate).getTime();
      this.formattedDate = format(new Date(parseInt(this.appConfigDateValue.toString())), this.dateTimeFormatsService.dateTimeToUpper);
      if (this.appConfigDateValue && this.formattedDate) {
        this.updateDateField();
      }
    } else {
      let fieldValue = this.phoneCallConfigFields.find(reqField => reqField.fieldName === this.dateFieldName).value;
      if(!fieldValue) this.phoneCallConfigFields.find(reqField => reqField.fieldName === this.dateFieldName).isEmptyRequiredField = true;
    }
  }

  private selectedCFOValues: ConfigFieldOptionValue[] = [];

  private handleMultiOptionSelectPopoverDismiss(data: any) {
    if (data && data.selectedItems && data.selectedItems.length && data.isDone) {
      let firstSelectedPopOverOptionValue = data.selectedItems[0].value;
      let optionsLabels = data.selectedItems.map(each => { return each.title });
      let optionsValues = data.selectedItems.map(each => { return each.value });
      let multipleLabels = optionsLabels.join(', ');
      let multipleValues = optionsValues.join(', ');
      let selectedValue = this.selectedCFOValues.filter(option => { return option.value === firstSelectedPopOverOptionValue });
      let fieldName = selectedValue.length ? selectedValue[0].fieldName : '';
      this.selectedCFOValues = [];
      this.updateAppConfigInputValuesForMultiOptionSet(fieldName, 'String', multipleLabels, multipleValues);
    }
  }

  public async openPicklist(event, fieldName, id) {
    const selectedConfigField = this.getOptionSetField(fieldName);
    const prevSelectedValue = this.getOptionSetValue(fieldName);
    const isMultipleSelectionCase = (id === 'appConfig-MultiSelectPicklist-field');
    let optionValues = this.activityService.getConfigFieldsValues(ActivityType.PhoneCall, fieldName);
    if (!optionValues.length) {
      console.error('No options available for this custom field in appointment.');
      return;
    }

    optionValues.sort((a, b) => (a.label && a.label.toLowerCase()) > (b.label && b.label.toLowerCase()) ? 1 : -1);

    this.selectedCFOValues = optionValues;
    let objects = optionValues.map(option => {
      let obj = {
        title: option.label,
        id: option.label,
        isSelected: false,
        value: option.value
      };
      if (isMultipleSelectionCase) {
        if (selectedConfigField && selectedConfigField.multipleLabels && selectedConfigField.multipleLabels.includes(option.label)) {
          obj['isSelected'] = true;
        }
      } else {
        if (prevSelectedValue && prevSelectedValue === option.label) {
          obj['isSelected'] = true;
        }
      }
      return obj;
    });
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'appConfigPickValues-select',
      data: objects,
      isMultipleSelectionEnabled: isMultipleSelectionCase,
      showCancelandDoneBtn: isMultipleSelectionCase
    };
    // if (isMultipleSelectionCase) {
    //   if (selectedConfigField && selectedConfigField.fieldLabel) {
    //     dropdownListDetail.pageTitle = selectedConfigField.fieldLabel;
    //   }
    // }
    let popover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event
    })
    await popover.present();
    await popover.onDidDismiss().then(async (data: any) => {
      if (this.backgroundUploadInProgress) return;
      if (isMultipleSelectionCase) {
        if (data && data.data && data.data.isDone) {
          this.handleMultiOptionSelectPopoverDismiss(data.data);
          this.phoneCallConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = false;
        } else {
          let fieldValue = this.phoneCallConfigFields.find(reqField => reqField.fieldName === fieldName).value;
          if(!fieldValue) this.phoneCallConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = true;
        }
      } else {
        if (data && data.data && data.data.selectedItems) {
          // This is for single selection.
          let selectedPopOverValue = data.data.selectedItems[0].value;
          let selectedValue = this.selectedCFOValues.filter(option => { return option.value === selectedPopOverValue });
          if (selectedValue && selectedValue.length) {
            this.appConfigPickValue = selectedValue[0];
          }
          this.selectedCFOValues = [];
          this.phoneCallConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = false;
          this.updateAppConfigInputValues(event, fieldName, 'String', selectedPopOverValue, true);
        } else {
          let fieldValue = this.phoneCallConfigFields.find(reqField => reqField.fieldName === fieldName).value;
          if(!fieldValue) this.phoneCallConfigFields.find(reqField => reqField.fieldName === fieldName).isEmptyRequiredField = true;
        }
      }
    });
  }

  public openOwner(event, fieldlabel) {
    if (event && event == 'input_value_confirm') {
      this.appConfigInputText = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : ''
      if (this.appConfigInputText != '') {
        this.phoneCallConfigFields.find(reqField => reqField.fieldName === fieldlabel).isEmptyRequiredField = false;
        this.updateAppConfigInputValues(event, fieldlabel, 'String');
      } else {
        this.phoneCallConfigFields.find(reqField => reqField.fieldName === fieldlabel).isEmptyRequiredField = true;
      }
    }
  }

  onPeriodEndDateFieldClick(event, fieldName) {
    this.appConfigFieldDatePicker.open();
    this.dateEvent = event;
    this.dateFieldName = fieldName;
  }

  private updateDateField() {
    if (this.appConfigDateValue && this.formattedDate) {
      this.updateConfigDate(this.dateEvent, this.dateFieldName, this.appConfigDateValue);
    }
  }
  /**
   * appConfigFields.
   * @description
   *
   *
   *
   */
  public getPhoneCallFormField(appConfigField: any): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: appConfigField.fieldLabel,
      inputText: this.getInputText(appConfigField.fieldType, appConfigField.value, appConfigField.fieldName),
      inputValue: this.getInputText(appConfigField.fieldType, appConfigField.value, appConfigField.fieldName),
      placeholderLabel: this.activityService.selectedActivity && this.activityService.selectedActivity.state != MeetingActivityState.Completed ? this.getplaceholderLabel(appConfigField.fieldType, appConfigField.fieldLabel) : this.getPlaceHolderForCompletedAct(appConfigField.fieldType, appConfigField.fieldLabel),
      id: this.getappId(appConfigField.fieldType),
      isReadOnly: this.getisReadOnly(appConfigField.fieldType, appConfigField.readOnly) && !this.isReadOnlyJointPhoneCall,
      isDisabled: this.backgroundUploadInProgress || !(this.activityService.selectedActivity && !this.isReadOnlyJointPhoneCall && this.activityService.selectedActivity.state != MeetingActivityState.Completed),
      showArrow: this.activityService.selectedActivity && !this.isReadOnlyJointPhoneCall && this.activityService.selectedActivity.state != MeetingActivityState.Completed,
      formFieldType: (appConfigField.fieldLabel == 'Activity Type' || appConfigField.fieldLabel == 'Activity Sub Type') ? FormFieldType.NEW_PAGE_SELECT : this.getFieldType(appConfigField.fieldType),
      isRequired: (!this.isReadOnlyJointPhoneCall && this.activityService.selectedActivity && this.activityService.selectedActivity.state != MeetingActivityState.Completed && appConfigField.mandatory == "true" && !this.backgroundUploadInProgress) ? true : false,
      errorMessage: appConfigField.mandatory == "true" ? this.activityService.getErrorMessageRequiredField() : undefined,
      isEmptyRequiredField: appConfigField.isEmptyRequiredField,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName, appConfigField.fieldName, appConfigField.fieldLabel),
    };
    return viewData;
  }

  private setOfflineDataForConfigFields() {
    if (this.phoneCallConfigFields.length > 0 && this.activityService.selectedActivity.appConfigFields.length > 0) {
      this.activityService.selectedActivity.appConfigFields.forEach(o => {
        let fieldName = o.fieldName
        this.phoneCallConfigFields.map(obj => {
          if (obj.fieldName === fieldName) {
            if (o.value !== undefined || o.value !== "") {
              obj.value = o.value;
              this.isValueUpdated = true;
            }
            if (o.selectedValues !== undefined || o.selectedValues !== "") {
              obj.selectedValues = o.selectedValues;
              obj.hasMultipleValues = o.hasMultipleValues;
              obj.multipleLabels = o.multipleLabels;
              obj.multipleValues = o.multipleValues;
              this.isValueUpdated = true;
            }
          }
        })
      })
    } else {
      this.isValueUpdated = false;
      this.initConfigFieldsSetup();
    }
  }

  private convertReadOnlyValue(readOnly): boolean {
    let returnValue = false;
    returnValue = (readOnly === "false" || readOnly === false || readOnly === undefined || readOnly === null) ? returnValue : true;
    return returnValue;
  }

  public get isCheckMandatoryFieldValidationIsFailed(): boolean {
    let fieldNames: string[] = [];
    let count = 0;
    let inputValues: any[] = [];
    let appConfigFields: ConfiguredFields[] = this.phoneCallConfigFields.filter(reqField => (reqField.mandatory === 'true' && reqField.readOnly === false));
    if (this.activityService.selectedActivity && this.activityService.selectedActivity.appConfigFields) {
      let inActivityAppConfigFields = this.activityService.selectedActivity.appConfigFields.filter(reqField => { return (reqField.mandatory === 'true' && reqField.readOnly === false) });
      if (inActivityAppConfigFields && inActivityAppConfigFields.length) {
        appConfigFields.forEach(cf => {
          let key = cf.fieldName;
          if (key) {
            let inActivityAppConfigFieldValue = inActivityAppConfigFields.filter(cf => { return cf.fieldName === key });
            if (inActivityAppConfigFieldValue && inActivityAppConfigFieldValue.length) {
              let first = inActivityAppConfigFieldValue[0];
              if (first.value && first.value !== '') {
                inputValues.push({
                  'key': key,
                  'value': first.value
                });
              } else if (first.selectedValues && first.selectedValues !== '') {
                inputValues.push({
                  'key': key,
                  'value': first.selectedValues
                });
              }
            }
          }
        });
      }
    }
    if (appConfigFields.length) {
      appConfigFields.forEach(configuredField => {
        let key = configuredField.fieldName;
        if (key) {
          let result = inputValues.filter(r => { return r.key === key });
          if (!result.length) {
            fieldNames.push(configuredField.fieldLabel.toLowerCase());
            count = count + 1;
          }
        }
      });
    }
    if (count > 0) {
      this.configMandatoryField = fieldNames.join(', ');
      return true;
    } else {
      return false;
    }
  }

  private getOptionSetValue(fieldName: string): string {
    if (fieldName && this.activityService.selectedActivity) {
      let activity = this.activityService.selectedActivity;
      if (activity.appConfigFields && activity.appConfigFields.length) {
        let fieldFilter = activity.appConfigFields.filter(o => { return o.fieldName === fieldName });
        if (fieldFilter && fieldFilter.length) {
          let field = fieldFilter[0];
          if (field.hasMultipleValues) {
            let count = (field.multipleLabels && field.multipleLabels.length) ?? 0;
            if (count > 1) {
              return (field.multipleLabels[0] + ' +' + (count - 1));
            } else {
              return field.selectedValues;
            }
          } else {
            return field.selectedValues;
          }
        }
      }
    }
    return '';
  }

  private getLookupValue(fieldName: string): string {
    if (fieldName && this.activityService.selectedActivity) {
      let activity = this.activityService.selectedActivity;
      if (activity.appConfigFields && activity.appConfigFields.length) {
        let fieldFilter = activity.appConfigFields.filter(o => { return o.fieldName === fieldName });
        if (fieldFilter && fieldFilter.length) {
          let field = fieldFilter[0];
          if (field.stringValue) {
            return field.stringValue;
          }
        }
      }
    }
    return '';
  }

  private getOptionSetField(fieldName: string): ConfiguredFields {
    if (fieldName && this.activityService.selectedActivity) {
      let activity = this.activityService.selectedActivity;
      if (activity.appConfigFields && activity.appConfigFields.length) {
        let fieldFilter = activity.appConfigFields.filter(o => { return o.fieldName === fieldName });
        if (fieldFilter && fieldFilter.length) {
          let field = fieldFilter[0];
          return field;
        }
      }
    }
    return null;
  }

  private getInputText(fieldType, fieldValue, fieldName?) {
    switch (fieldType) {
      case 'String':
        this.updateInputStringValue(fieldName, fieldValue);
        return this.appConfigInputText;
      case 'Uniqueidentifier':
        this.appConfigProcessValue = fieldValue;
        return this.appConfigProcessValue;
      case 'Boolean':
        if (fieldValue === undefined) {
          this.appConfigInputBoolValue = "";
        } else {
          this.appConfigInputBoolValue = (fieldValue === true) ? 'Yes' : 'No';
        }
        return this.appConfigInputBoolValue;
      case 'DateTime':
        if (fieldValue != undefined) {
          this.formattedDate = fieldValue ? format(new Date(parseInt(fieldValue.toString())), this.dateTimeFormatsService.dateTimeToUpper) : this.formattedDate;
        }
        return this.formattedDate;
      case 'Lookup':
        return this.getLookupValue(fieldName);
      case 'Picklist':
        return this.getOptionSetValue(fieldName);
      case 'Virtual':
        return this.getOptionSetValue(fieldName);
      case 'Owner':
        return this.activityService.selectedActivity.meetingOwnerName;
      case 'Currency':
        this.appConfigCurrencyValue = fieldValue;
        return this.appConfigCurrencyValue;
      case 'Integer':
        this.appConfigIntegervalue = fieldValue;
        return this.appConfigIntegervalue;
      case 'Decimal':
        this.appConfigDecimalvalue = fieldValue;
        return this.appConfigDecimalvalue;
      case 'Memo':
        if (fieldValue !== undefined) {
          this.appConfigMemovalue = fieldValue;
        } else {
          this.appConfigMemovalue = '';
        }
        return this.appConfigMemovalue;
      default:
        break;
    }
  }

  private updateInputStringValue(fieldName, fieldValue) {
    // const selectedActivity = this.activityService.selectedActivity as PhoneActivity;
    this.appConfigInputText = fieldValue;
  }

  private getplaceholderLabel(fieldType, fieldLabel) {
    let appConfigInputText
    switch (fieldType) {
      case 'String':
        appConfigInputText = fieldLabel;
        break;
      case 'Uniqueidentifier':
        appConfigInputText = fieldLabel;
        break;
      case 'Boolean':
        appConfigInputText = fieldLabel;
        break;
      case 'DateTime':
        appConfigInputText = fieldLabel
        break;
      case 'Lookup':
        appConfigInputText = fieldLabel
        break;
      case 'Picklist':
        appConfigInputText = fieldLabel
        break;
      case 'Virtual':
        appConfigInputText = fieldLabel
        break;
      case 'Owner':
        appConfigInputText = fieldLabel
        break;
      case 'Currency':
        appConfigInputText = fieldLabel
        break;
      case 'Integer':
        appConfigInputText = fieldLabel
        break;
      case 'Memo':
        appConfigInputText = fieldLabel
        break;
      case 'EntityName':
        appConfigInputText = fieldLabel
        break;
      case 'Decimal':
        appConfigInputText = fieldLabel
        break;
      default:
        break;
    }
    return appConfigInputText;
  }

  getPlaceHolderForCompletedAct(fieldType, fieldLabel) {
    let appConfigInputText
    switch (fieldType) {
      case 'String':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`;
        break;
      case 'Uniqueidentifier':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`;
        break;
      case 'Boolean':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`;
        break;
      case 'DateTime':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      case 'Lookup':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      case 'Picklist':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      case 'Virtual':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      case 'Owner':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      case 'Currency':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      case 'Integer':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      case 'Memo':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      case 'EntityName':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      case 'Decimal':
        appConfigInputText = `${this.translate.instant('NO')} ${fieldLabel}`
        break;
      default:
        break;
    }
    return appConfigInputText;
  }

  private getappId(fieldType) {
    let appId;
    switch (fieldType) {
      case 'String':
        appId = 'appConfig-text-field'
        break;
      case 'Uniqueidentifier':
        appId = 'appConfig-Uniqueidentifier-field'
        break;
      case 'Boolean':
        appId = 'appConfig-Boolean-field'
        break;
      case 'DateTime':
        appId = 'appConfig-DateTime-field'
        break;
      case 'Lookup':
        appId = 'appConfig-Lookup-field'
        break;
      case 'Picklist':
        appId = 'appConfig-Picklist-field'
        break;
      case 'Virtual':
        appId = 'appConfig-MultiSelectPicklist-field'
        break;
      case 'Owner':
        appId = 'appConfig-Owner-field'
        break;
      case 'Currency':
        appId = 'appConfig-Currency-field'
        break;
      case 'Integer':
        appId = 'appConfig-Integer-field'
        break;
      case 'Memo':
        appId = 'appConfig-Memo-field'
        break;
      case 'EntityName':
        appId = 'appConfig-EntityName-field'
        break;
      case 'Decimal':
        appId = 'appConfig-Decimal-field'
        break;
      default:
        console.error("Unhandled switch case statement");
        break;
    }
    return appId;
  }

  private getisReadOnly(fieldType, readOnly) {
    let isReadOrDisabled: boolean = false;
    switch (fieldType) {
      case 'String':
        isReadOrDisabled = this.convertReadOnlyValue(readOnly);
        break;
      case 'Uniqueidentifier':
        isReadOrDisabled = this.convertReadOnlyValue(readOnly);
        break;
      case 'Boolean':
        isReadOrDisabled = true;
        break;
      case 'DateTime':
        isReadOrDisabled = this.convertReadOnlyValue(readOnly);
        break;
      case 'Lookup':
        isReadOrDisabled = true;
        break;
      case 'Decimal':
        isReadOrDisabled = this.convertReadOnlyValue(readOnly);
        break;
      case 'Memo':
        isReadOrDisabled = this.convertReadOnlyValue(readOnly);
        break;
      case 'Picklist':
        isReadOrDisabled = true;
        break;
      case 'Virtual':
        isReadOrDisabled = true;
        break;
      case 'Owner':
        isReadOrDisabled = this.convertReadOnlyValue(readOnly);
        break;
      case 'Currency':
        isReadOrDisabled = this.convertReadOnlyValue(readOnly);
        break;
      case 'Integer':
        isReadOrDisabled = this.convertReadOnlyValue(readOnly);
        break;
      case 'EntityName':
        isReadOrDisabled = this.convertReadOnlyValue(readOnly);
        break;
      default:
        break;
    }
    return isReadOrDisabled;
  }

  private getFieldType(fieldType): any {
    switch (fieldType) {
      case 'String':
        return FormFieldType.INLINE_INPUT;
      case 'Uniqueidentifier':
        return FormFieldType.INLINE_INPUT;
      case 'Integer':
        return FormFieldType.INLINE_INPUT;
      case 'EntityName':
        return FormFieldType.INLINE_INPUT;
      case 'Memo':
        return FormFieldType.INLINE_INPUT;
      case 'Owner':
        return FormFieldType.INLINE_INPUT;
      case 'Boolean':
        return FormFieldType.POPOVER_SELECT;
      case 'DateTime':
        return FormFieldType.POPOVER_SELECT;
      case 'Lookup':
        return FormFieldType.POPOVER_SELECT;
      case 'Picklist':
        return FormFieldType.POPOVER_SELECT;
      case 'Virtual':
        return FormFieldType.POPOVER_SELECT;
      case 'Currency':
        return FormFieldType.INLINE_INPUT;
      case 'Decimal':
        return FormFieldType.INLINE_INPUT;
      default:
        break;
    }
  }

  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */
  openAccounts() {
    if (this.isReadOnlyJointPhoneCall && this.activityService.selectedActivity instanceof PhoneActivity && _.isEmpty(this.activityService.selectedActivity.accounts)) return;
    this.trackingService.tracking('PhoneCallAccountsClick', TrackingEventNames.PHONECALL);
    this.uiService.prevView = this.uiService.activeView;
    this.uiService.showNewActivity = false;
    this.uiService.activeView = 'accountDetails';
    this.accountService.selectedFor = AccountSelectedFor.PHONE_CALL_SELECTION;
    this.accountService.accessedAccountListFrom = PageName.PhoneCallDetailsComponent;
    this.footerService.initButtons(FooterViews.PHONE_CALL);
    this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { 'listMode': ComponentViewMode.ADDNEW }, PageName.AccountPageComponent);
  }

  /**
 * initActivitiesDetailsPageTitle().
 * @description
 *
 *
 *
 */
  getMeetingDateTimeText = (): any => {

    if (!this.activityService.selectedActivity) return;
    if (this.activityService.selectedActivity instanceof PhoneActivity || this.activityService.selectedActivity.type == ActivityType.PhoneCall) {
      let startDateTimeValue: Date = new Date(this.activityService.selectedActivity.scheduledStart);
      let endDateTimeValue: Date = new Date(this.activityService.selectedActivity.scheduledEnd);
      let phoneCallStartDay = this.datePipe.transform(startDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
      let phoneCallEndDay = this.datePipe.transform(endDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
      let phoneCallStartTime = startDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
      let phoneCallEndTime = endDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
      let formattedDuration: string = this.duration = this.dateTimeFormatsService.getFormattedTimeInterval(startDateTimeValue, endDateTimeValue);

      let dateTimeValue: any = {
        startDateTime: '',
        startDate: '',
        startTime: '',
        endDateTime: '',
        endDate: '',
        endTime: '',
        duration: '',
      };

      dateTimeValue = {
        startDateTime: startDateTimeValue,
        startDate: phoneCallStartDay,
        startTime: phoneCallStartTime,
        endDateTime: endDateTimeValue,
        endDate: phoneCallEndDay,
        endTime: phoneCallEndTime,
        duration: formattedDuration,
      };

      return dateTimeValue;
    }
  }
  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */
  openNewActivityPage() {
    this.uiService.showNewActivity = true;
    if (this.navService.getActiveChildNavViewMasterPageName() === PageName.CallPlanComponent) {
      this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.CallPlanComponent, { from: PageName.PhoneCallDetailsComponent });
    }else if(this.navService.getActiveChildNavViewMasterPageName() === PageName.CustomerCallPlanPage) {
      this.navService.pushChildNavPageWithPageTracking(NewActivityComponent, PageName.NewActivityComponent, PageName.CustomerCallPlanPage, { from: PageName.PhoneCallDetailsComponent });
    }
  }

  /**
 * initActivitiesDetailsPageTitle().
 * @description
 *
 *
 *
 */
  handleFormFieldEvent(id: string, event: any, eventName: string, fieldName?, fieldLabel?): void {
    console.log('another page opens here to select the details');
    if (id) {
      if (this.backgroundUploadInProgress) return;
      switch (id) {
        case 'subject-field':
          if (eventName && eventName == 'input_value_confirm') {
          this.trackingService.tracking('SelectSubject', TrackingEventNames.PHONECALL);
          let subject = (event && event.target.value && event.target.value.length != 0) ? event.target.value : (this.activityService.selectedActivity) ? this.activityService.selectedActivity.subject : "";
          if (this.activityService.selectedActivity) {
            if (subject.includes(this.translate.instant('NEW_ACTIVITY_PHONECALL'))) {
              subject = subject.replace(this.translate.instant('NEW_ACTIVITY_PHONECALL'), 'Phone Call') // to Avoid special Characters in Dynamics. It's just a work around teh actual fix would be provided in Dynamics.
            }
          }
          this.activityService.selectedActivity.subject = subject;
            this.trackingService.tracking('Edit Subject', TrackingEventNames.PHONECALL);
            this.updateSubject(event)
          }
          break;
        case 'start-date-field':
          if (this.isTeamViewEnabled()) return;
          if (!this.isThisPhoneCallEditable()) return
          this.openStartDatePicker(event);
          break;
        case 'end-date-field':
          if (this.isTeamViewEnabled()) return;
          if (!this.isThisPhoneCallEditable()) return
          this.openEndDatePicker(event);
          break;
        case 'account-field':
          if (this.isTeamViewEnabled()) return;
          this.trackingService.tracking('SelectAccount', TrackingEventNames.PHONECALL)
          this.openAccounts();
          break;
        case 'contact-field':
          if (this.isTeamViewEnabled()) return;
          this.openContactSelection();
          break;
        case 'appConfig-text-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.appConfigInputText = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : ''
            this.updateAppConfigInputValues(event, fieldName, 'String');
          }
          break;
        case 'appConfig-Lookup-field':
          this.openLookup(event, fieldName);
          break;
        case 'appConfig-Picklist-field':
          this.openPicklist(event, fieldName, id);
          break;
        case 'appConfig-MultiSelectPicklist-field':
          this.openPicklist(event, fieldName, id);
          break;
        case 'appConfig-Owner-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.openOwner(event, fieldName);
          }
          break;
        case 'appConfig-Currency-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.appConfigInputText = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : ''
            this.updateAppConfigInputValues(event, fieldName, 'String');
          }
          break;
        case 'appConfig-Integer-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.appConfigIntegervalue = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : ''
            this.updateAppConfigInputValues(event, fieldName, 'Integer');
          }
          break;
        case 'appConfig-Memo-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.appConfigMemovalue = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : ''
            this.updateAppConfigInputValues(event, fieldName, 'String');
          }
        case 'appConfig-EntityName-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.appConfigInputText = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : ''
            this.updateAppConfigInputValues(event, fieldName, 'String');
          }
          break;
        case 'appConfig-Uniqueidentifier-field':
          if (eventName && eventName == 'input_value_confirm') {
            this.appConfigProcessValue = (event && event.target && event.target.value && event.target.value.length != 0) ? event.target.value : ''
            this.updateAppConfigInputValues(event, fieldName, 'Number')
          }
          break;
        case 'appConfig-Boolean-field':
          this.openBooleanPopover(event, fieldName);
          break;
        case 'appConfig-DateTime-field':
          this.onPeriodEndDateFieldClick(event, fieldName);
          break;
        case 'phone-activityType-field':
          this.selectPhoneCallActivityType(event);
          break;
        case 'phone-activitySubType-field':
          this.selectPhoneCallSubActivityType(event);
          break;
        case 'covisitor-field':
          this.openAccompainedUsers();
          break;
        case 'init-voice-call-field':
          if (this.voiceCallEnabled) {
            if (this.voiceCallStatus == 'LOGGED_IN' || this.voiceCallStatus == 'CALL_ATTENDED' || this.voiceCallStatus == 'CALL_ENDED' || this.voiceCallStatus == 'CALL_STARTED'
              || this.voiceCallStatus == 'RINGING' || this.voiceCallStatus == 'IDLE' || this.voiceCallStatus == 'CALLING') {
              if (this.voiceCallStatus == 'CALLING' || this.voiceCallStatus == 'CALL_ATTENDED' || this.voiceCallStatus == 'CALL_STARTED' || this.voiceCallStatus == 'RINGING') {
                this.voiceCallService.endVoiceCall();
              } else {
                const phoneNumber = (this.activityService.selectedActivity as PhoneActivity).selectedMobileNumber;
                this.voiceCallService.startVoiceCall(this.activityService.selectedActivity as PhoneActivity);
              }
            } else {
              this.voiceCallService.initClinkToolBar();
            }
          }
          break;
        default: console.log('other services will be added soon');
          break;
      }
    }
  }

  openAccompainedUsers() {
    if (this.activityService.teamViewActive || (this.activityService.selectedActivity && this.activityService.selectedActivity.isCompleted && this.activityService.selectedActivity['accompaniedUserList'].length === 0)) return;
    this.ioUserSelection.setCaseSelectorSource("Team Members");
    this.ioUserSelection.setSelectedInputSource(this.activityService.selectedActivity["accompaniedUserList"]);
    this.ioUserSelection.setSelectionDataSource(this.activityService.repUserList);
    this.uiService.showNewActivity = false;
    setTimeout(() => {
      const onUserSelect = (data) => { if (data != undefined) this.updateAccompaniedUsers(data); };
      this.navService.pushWithPageTracking(IOMultiSelect, PageName.IOMultiSelect, { remoteMeetingConnectionMapping: true, onSelectDone: onUserSelect}, PageName.IOMultiSelect);
    }, 10);
  }

  private async selectPhoneCallActivityType(event) {
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'phone-activityType-field',
      data: this.phoneCallActivityTypes.map(option => {
        return { title: option.indskr_name, id: option.indskr_activitytypeid.toString(), isSelected: _.isEqual(option.indskr_activitytypeid, this.activityService.selectedActivity.indskr_activitytype) }
      })
    };
    const popover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: event });
    await popover.present();
    await popover.onDidDismiss().then(async (data: any) => {
      if (!_.isEmpty(data) && !_.isEmpty(data.data) && !_.isEmpty(data.data.selectedItems)) {
        if (this.backgroundUploadInProgress) return;
        const selectedActivityType = data.data.selectedItems[0];
        if (!_.isEqual(this.activityService.selectedActivity.indskr_activitytype, selectedActivityType.id)) {
          const defaultActivitySubType = this.activityService.getActivitySubTypesByActivityType(MeetingActivityTypeCode.PHONE_CALL, selectedActivityType.id).find(ast => ast.indskr_default);
          const payload = {
            indskr_activitytype: selectedActivityType.id,
            activityTypeName: selectedActivityType.title,
            indskr_activitysubtype: defaultActivitySubType ? defaultActivitySubType.indskr_activitysubtypeid : null,
            activitySubTypeName: defaultActivitySubType ? defaultActivitySubType.indskr_name : null
          }
          await this.meetingDataService.updateActivityTypeAndActivitySubType((this.activityService.selectedActivity as PhoneActivity), payload, ActivityTypeCodeRaw.PhoneCall, "indskr_activitytype");
          this.isClickedFormatDetails = false;
        }
      }
      this.isClickedFormat = true;
      this.initFormFields();
    });
  }

  private async selectPhoneCallSubActivityType(event) {
    if (_.isEmpty(this.activityService.selectedActivity.indskr_activitytype)) return;
    const phoneCallSubTypes = this.activityService.getActivitySubTypesByActivityType(MeetingActivityTypeCode.PHONE_CALL, this.activityService.selectedActivity.indskr_activitytype);
    if (_.isEmpty(phoneCallSubTypes)) {
      this.notificationService.notify(this.translate.instant("FORMAT_DETAILS_NOT_CONFIGURED"), 'Meeting Details');
      return;
    }
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'phone-activitySubType-field',
      data: phoneCallSubTypes.map(option => {
        return { title: option.indskr_name, id: option.indskr_activitysubtypeid.toString(), isSelected: _.isEqual(option.indskr_activitysubtypeid, this.activityService.selectedActivity.indskr_activitysubtype) }
      })
    };
    const popover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'subject-popover', event: event });
    await popover.present();
    await popover.onDidDismiss().then(async (data: any) => {
      if (!_.isEmpty(data) && !_.isEmpty(data.data) && !_.isEmpty(data.data.selectedItems)) {
        if (this.backgroundUploadInProgress) return;
        const selectedActivityType = data.data.selectedItems[0];
        if (!_.isEmpty(data) && !_.isEmpty(data.data) && !_.isEmpty(data.data.selectedItems)) {
          const selectedActivitySubType = data.data.selectedItems[0];
          if (!_.isEqual(this.activityService.selectedActivity.indskr_activitysubtype, selectedActivitySubType.id)) {
            const payload = { indskr_activitysubtype: selectedActivitySubType.id, activitySubTypeName: selectedActivitySubType.title };
            await this.meetingDataService.updateActivityTypeAndActivitySubType((this.activityService.selectedActivity as PhoneActivity), payload, ActivityTypeCodeRaw.PhoneCall, "indskr_activitysubtype");
          }
        }
      }
      this.isClickedFormatDetails = true;
      this.initFormFields();
    });
  }

  async updateSubject(myEvent) {
    if (this.activityService.selectedActivity && this.activityService.selectedActivity.state != MeetingActivityState.Completed && !this.isReadOnlyJointPhoneCall && !this.activityService.teamViewActive) {
      let phoneCallPayLoad = this.phoneCallDataService.setPhoneCallPayload(this.activityService.selectedActivity as PhoneActivity)
      this.uiService.displayLoader();
      if (!this.device.isOffline && !this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID)) {
        await this.phoneCallDataService.updatePhoneCall(this.activityService.selectedActivity as PhoneActivity, phoneCallPayLoad);
        // this.activityService.isActivityUpdate = true;
        this.uiService.dismissLoader();
      }
      else {
        await this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity);
        this.uiService.dismissLoader();
      }
    }
  }


  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */
  async saveNotes() {
    if (this.activityService.selectedActivity instanceof PhoneActivity) {
      if (_.isEqual(this.preSavedNotes, this.activityService.selectedActivity.notes)) return;
      await this.uiService.displayLoader();
      this.trackingService.tracking('PhoneCallNotesUpdate', TrackingEventNames.PHONECALL);
      this.activityService.getActivityByID(this.activityService.selectedActivity.ID).notes = this.activityService.selectedActivity.notes;
      this.activityService.getDisplayctivityByID(this.activityService.selectedActivity.ID).notes = this.activityService.selectedActivity.notes;
      if (this.device.isOffline) {
        await this.activityService.upsertPhoneCallOfflineData(this.activityService.selectedActivity as PhoneActivity);
      }
      let payload = this.phoneCallDataService.setPhoneCallPayload(this.activityService.selectedActivity);
      await this.phoneCallDataService.updatePhoneCall(this.activityService.selectedActivity, payload);
      this.preSavedNotes = this.activityService.selectedActivity.notes;
    }
    this.isNotesDirty = false;
    await this.uiService.dismissLoader();
  }

  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */
  cancelNotes() {
    if (this.preSavedNotes) {
      this.activityService.selectedActivity.notes = this.preSavedNotes;
    } else {
      this.activityService.selectedActivity.notes = '';
    }
    this.isNotesDirty = false;

  }

  /**
 * initActivitiesDetailsPageTitle().
 * @description
 *
 *
 *
 */
  public notesChanged() {
    this.isNotesDirty = true;
    this.initializeSectionHeader();
    this.initActivitiesDetailsPageTitle();
  }

  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */
  onNotesFocus() {
    this.trackingService.tracking('Selectcallnotes', TrackingEventNames.PHONECALL)
    this.preSavedNotes = this.activityService.selectedActivity.notes;

  }

  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */
  onCallObjectivesFocus() {

  }

  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */
  CallObjectivesChanged() {

  }

  /**
 * initActivitiesDetailsPageTitle().
 * @description
 *
 *
 *
 */
  cancelCallObjectives() {

  }

  /**
   * initActivitiesDetailsPageTitle().
   * @description
   *
   *
   *
   */
  saveCallObjectives() {
  }


  /**
   * @description This method will save additional Config
   *
  */
  private pushAdditionalConfigOffline(payload) {
    if (this.activityService.selectedActivity.appConfigFields !== undefined) {
      let removeIndex = this.activityService.selectedActivity.appConfigFields.map(function (item) { return item.fieldName; }).indexOf(payload.fieldName);
      if (removeIndex > -1) this.activityService.selectedActivity.appConfigFields.splice(removeIndex, 1);
      this.activityService.selectedActivity.appConfigFields.push(payload);
    }
  }

  get isSelectedActivityReopenable() {
    return this.from == PageName.AccountPlanActivities ? false : this.activityService.selectedActivity.omnip_datecompleted ? !(this.activityService.selectedActivity.ownerId != this.authService.user.systemUserID
      || this.backgroundUploadInProgress
      || this.activityService.teamViewActive || this.activityService.selectedActivity && !this.activityService.selectedActivity.isCompleted || this.device.isOffline || this.activityService.hasOfflinePhoneCallData(this.activityService.selectedActivity.ID)
      || moment().diff(moment(new Date(parseFloat(this.activityService.selectedActivity.omnip_datecompleted))), 'days') >= this.authenticationOfflineService.user.pastPhoneCallReopenPeriod) : false;
  }

  onFooterButtonClicked(buttonId: string) {
    switch (buttonId) {
      case 'phoneCallReopen':
        this.reopenPhoneCall();
        break;
      case 'contactsphonecall':
        this.openContactSelection();
        break;
      default:
        break;
    }
  }

  async openStartDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.PhoneCall,
          startDateTimeValue: this.activityService.selectedActivity.scheduledStart,
          endDateTimeValue: this.activityService.selectedActivity.scheduledEnd
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then((data: any) => {
      if (this.backgroundUploadInProgress) return;
      if (data !== null && !_.isEmpty(data.data) && data.data.startTime !='') {
        this.activityService.selectedActivity.scheduledStart = new Date(data.data.startTime);
        let dateTimeValue = this.activityService.getFormattedDateTimeText();
        if (dateTimeValue) this.phoneCallStartDate = dateTimeValue.startDate;
      }
      this.shouldCheckConflict = true;
      this.events.publish('detectChangesOnPhoneCallActivityDetails');
    });
    popover.present();
  }

  async openEndDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.PhoneCall,
          startDateTimeValue: this.activityService.selectedActivity.scheduledStart,
          endDateTimeValue: this.activityService.selectedActivity.scheduledEnd
        },
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then((data: any) => {
      if (this.backgroundUploadInProgress) return;
      if (data !== null && !_.isEmpty(data.data) && data.data.endTime !='') {
        this.activityService.selectedActivity.scheduledEnd = new Date(data.data.endTime);
        let dateTimeValue = this.activityService.getFormattedDateTimeText();
        if (dateTimeValue) {
          this.phoneCallEndTime = dateTimeValue.endTime;
        }
      }
      this.shouldCheckConflict = true;
      this.events.publish('detectChangesOnPhoneCallActivityDetails');
    });
    popover.present();
  }

  checkConflictMsgDisplay(shouldForceCheck = false): boolean {
    const flag = this.isNewlyCreatedActivity || this.shouldCheckConflict || shouldForceCheck;
    this.shouldCheckConflict = false;
    const activity = this.activityService.activities.find(a => a.ID === this.activityService.selectedActivity.ID);

    const shouldDisplayMsg = (
      flag
      && activity?.conflictingActivityIds?.size > 0
    ) ? true : false;
    return shouldDisplayMsg;
  }
  onTimeUpdated() {
    this.shouldCheckConflict = true;
  }

  private checkMandatoryFields() {
    if(this.requiredFields) {
      if(!this.activityService.teamViewActive && this.activityService.selectedActivity && this.activityService.selectedActivity.state != MeetingActivityState.Completed && !this.requiredFields['contact-field']['isAdded']) {
        this.isClickedContacts = true;
      }
      if(this.isNotReadOnly && !_.isEmpty(this.phoneCallActivityTypes) && this.requiredFields['phonecall-activityType-field']['isRequired'] && _.isEmpty(this.activityService.selectedActivity.activityTypeName)) {
        this.activityTypeFormField.isEmptyRequiredField = true;
        this.isClickedFormat = true;
      }
      if(this.isNotReadOnly && !_.isEmpty(this.activityService.configuredActivitySubTypes(MeetingActivityTypeCode.MEETING)) && this.requiredFields['phonecall-activitySubType-field']['isRequired'] && _.isEmpty(this.activityService.selectedActivity.activitySubTypeName)) {
        this.activitySubTypeFormField.isEmptyRequiredField = true;
        this.isClickedFormatDetails = true;
      }
    }
    this.phoneCallConfigFields.forEach(field => {
      if(field.mandatory == 'true') {
        if(!field.value) {
          field.isEmptyRequiredField = true;
        }
      }
    });
    this.cdRef.detectChanges();
  }

  public async openVoiceCallRecording(uniqueId) {
    if (this.device.isOffline) return;
    const callrecord: any = await this.phoneCallDataService.getVoiceCallRecordLink(uniqueId);
    if (!_.isEmpty(callrecord?.link)) {
      const url = callrecord.link;
      if (this.device.isNativeApp && !this.device.deviceFlags.electron) {
        if (this.device.deviceFlags.ios) {
          this.inAppBrowser.create(url, '_blank', { location: 'no', hideurlbar: 'no', zoom: 'no' });
        }
        else {
          this.inAppBrowser.create(url, '_system', { location: 'yes', toolbar: 'yes', zoom: 'no' });
        }
      } else {
        if (this.device.deviceFlags.electron) {
          electronApi.openExternal(url);
        } else {
          window.open(url, "_system");
        }
      }
    }
  }

  private async initConfigFieldsLookupData(){
    let lookupFields = [];
    if(this.phoneCallConfigFields && this.phoneCallConfigFields.length > 0){
      lookupFields = this.phoneCallConfigFields.filter(a=> a.datatype == 'Lookup' || a.fieldType == "Lookup");
    }
    if(lookupFields && lookupFields.length > 0){
      await this.diskService.retrieve(DB_KEY_PREFIXES.IO_CONFIG_ALL_LOOKUP_FIELDS, true).then((doc)=>{
        if(doc && doc.raw){
          this.lookupConfigFieldsData = doc.raw['phonecall']
        }
      })
    }
  }
}
