import { Utility } from '@omni/utility/util';
import { TranslateService } from '@ngx-translate/core';
import { CallPlanOfflineService } from '@omni/services/call-plan/call-plan.offline.service';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import {
  NavigationService,
  PageName,
} from '@omni/services/navigation/navigation.service';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { MultiSelectPopover } from '@omni/components/multi-select-popover/multi-select-popover';
import { PopoverController } from '@ionic/angular';
import { FooterService } from '@omni/services/footer/footer.service';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import _ from 'lodash';
import { DatePipe } from '@angular/common';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { RepCallPlanStatePipe } from '../../../pipes/rep-call-plan-state.pipe';
import { CustomerCallPlanSuggest } from '../customer-call-plan-suggest/customer-call-plan-suggest';
import { AuthenticationService } from '@omni/services/authentication.service';
import { CallPlanCustomerType, PlanManualChannel } from '@omni/classes/call-plan/rep-call-plan.class';
import { EventsService } from '@omni/services/events/events.service';
import { Subject, takeUntil } from 'rxjs';
import { DeviceService } from '@omni/services/device/device.service';

@Component({
  selector: 'customer-call-plan-overview',
  templateUrl: './customer-call-plan-overview.html',
  styleUrls: ['./customer-call-plan-overview.scss'],
  providers: [RepCallPlanStatePipe]
})

export class CustomerCallPlanOverView implements OnInit {
  @Output() closeDetails = new EventEmitter<boolean>(false);
  @Input() from: PageName;
  destroy$: Subject<boolean> = new Subject<boolean>();
  public overviewHeaderModel: IndSectionHeaderViewDataModel
  public goals: any[] = [];
  sortViewOverPopoverData: { text: string; expanded: boolean; value: string; items: { text: any; value: string; asc: boolean; }[]; handler: (selectedItem: any, item: any) => void; }[];
  sortBy: { text: string, value: string, asc: boolean };
  public allCustomerPlansHeaderModel: IndSectionHeaderViewDataModel;
  private customerCallPlans: any[] = [];
  formattedCallPlans: { past: any[], present: any[], future: any[] } = { past: [], present: [], future: [] };
  public plan;
  public callPlans: any[] = [];
  public hasCallPlanProgress: boolean = false;
  public displaygoalsbeyond100: boolean = false;
  public displayAllActProgress: boolean = false;

  constructor(
    public callPlanService: CallPlanOfflineService,
    public translate: TranslateService,
    public popover: PopoverController,
    public footerService: FooterService,
    public utilityService: GlobalUtilityService,
    private datePipe: DatePipe,
    private dateTimeFormatsService: DateTimeFormatsService,
    private statePipe: RepCallPlanStatePipe,
    private navService: NavigationService,
    private authService: AuthenticationService,
    private events: EventsService,
    public device: DeviceService,
  ) {
  }

  ngOnInit() {
    this._checkBUConfig();
    this._getFormattedCallPlans();
    
    let sortItems = [];
    if (!_.isEmpty(this.formattedCallPlans.present)) sortItems.push({ text: this.translate.instant('CP_CURRENT'), value: "call-plan-current", asc: true });
    if (!_.isEmpty(this.formattedCallPlans.past)) sortItems.push({ text: this.translate.instant('CP_PAST'), value: "call-plan-past", asc: true });
    if (!_.isEmpty(this.formattedCallPlans.future)) sortItems.push({ text: this.translate.instant('SMALL_FUTURE'), value: "call-plan-future", asc: true });
    this.plan = this.callPlanService.selectedCallPlan;
    this.initializeCallPlanDetails(this.plan);
    this.sortViewOverPopoverData = [
      {
        text: "",
        expanded: true,
        value: this.callPlanService.currentFooterFilter,
        items: sortItems,
        handler: (selectedItem, item) => {
          item.value = selectedItem.value;
          this.sortBy = selectedItem;
          this.getCallPlan();
          this.initializeSectionHeader();
        }
      }
    ];
      this.sortBy = sortItems.find(s => s.value == this.callPlanService.currentFooterFilter);
    this.initializeSectionHeader();
    this.events.observe('callPlanListUpdated').pipe(
      takeUntil(this.destroy$))
      .subscribe(async(error) => {
        this._getFormattedCallPlans();
        this.getCallPlan();
        this.initializeSectionHeader();
      });
    this.device.isOfflineObservable.pipe(takeUntil(this.destroy$)).subscribe(isOffline => {
        if(!isOffline) {
          this._getFormattedCallPlans();
          this.getCallPlan();
          this.initializeSectionHeader();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  private _getFormattedCallPlans() {
    const positionId = this.callPlanService.selectedCallPlan?.positionId;
    if(this.callPlanService.selectedCallPlan?.customerType == CallPlanCustomerType.CONTACT) {
      const selectedCallPlanId = this.callPlanService.selectedCallPlan?.contactId;
      this.customerCallPlans = this.callPlanService.segmentCallPlans.filter(cp => cp.contactId == selectedCallPlanId);
      this.formattedCallPlans = this.formatCallPlans;
      if (this.callPlanService.callPlanMode == "team") {
        this.customerCallPlans = this.callPlanService.teamSegmentCallPlans.filter(cp => cp.contactId == selectedCallPlanId && cp.positionId == positionId);
        this.formattedCallPlans = this.formatCallPlans;
      }
    }else {
      const selectedCallPlanId = this.callPlanService.selectedCallPlan?.accountId;
      this.customerCallPlans = this.callPlanService.segmentCallPlans.filter(cp => cp.accountId == selectedCallPlanId);
      this.formattedCallPlans = this.formatCallPlans;
      if (this.callPlanService.callPlanMode == "team") {
        this.customerCallPlans = this.callPlanService.teamSegmentCallPlans.filter(cp => cp.accountId == selectedCallPlanId && cp.positionId == positionId);
        this.formattedCallPlans = this.formatCallPlans;
      }
    }
  }

  initializeSectionHeader() {
    this.overviewHeaderModel = {
      id: 'overviewFilter',
      title: `${this.translate.instant('OVERVIEW')}`,
      isFilter: (!this.displaygoalsbeyond100 && (this.callPlanService.callPlanMode == "team" || this.callPlanService.callPlanMode == "mine")) ? true : false,
      showArrow: (!this.displaygoalsbeyond100 && (this.callPlanService.callPlanMode == "team" || this.callPlanService.callPlanMode == "mine")) ? true : false,
      filterText: (!this.displaygoalsbeyond100 && (this.callPlanService.callPlanMode == "team" || this.callPlanService.callPlanMode == "mine")) ? this.sortBy?.text : '',
      isCallPlanFilter: (!this.displaygoalsbeyond100 && (this.callPlanService.callPlanMode == "team" || this.callPlanService.callPlanMode == "mine")) ? true : false,
      arrowType: 'chevron-down-outline',
      rightLabelTxt: this.displaygoalsbeyond100 ? undefined : `${this.plan.percentage}%`,
      customRightLabelClass: 'call-plan-overview',
      customRightParams: this.displaygoalsbeyond100 ? undefined : { title: `${this.translate.instant('TOTAL')}`, completed: this.plan.completedGoals, defined: this.plan.definedGoals },
      controls: []
    }

    if(this.displaygoalsbeyond100) {
      this.overviewHeaderModel.controls.push({
        id: 'overview-filter',
        text: (this.callPlanService.callPlanMode == "team" || this.callPlanService.callPlanMode == "mine") ? this.sortBy?.text : '',
        isDisabled: false,
        iconName: 'chevron-down-outline',
        isVisible: true
      })
    }

    this.allCustomerPlansHeaderModel = {
      id: 'detailsPlans',
      title: `${this.translate.instant('DETAILS_CAP')}`,
      controls: []
    }
  }

  onFilterControlClick(id: string) {
    this.popover
      .create({ component: MultiSelectPopover, componentProps: { root: this.sortViewOverPopoverData }, event: event })
      .then((data) => {
        data.present();
      })
  }

  public onSectionHeaderControlClick(id: string) {
    if (id === 'overview-filter') {
      this.onFilterControlClick(id);
    }
  }

  private getCallPlan() {
    let aggregatedData;
    switch (this.sortBy?.value) {
      case 'call-plan-current':
        aggregatedData = this.callPlanService.aggregateRepCallPlans(this.formattedCallPlans.present);
        break;
      case 'call-plan-past':
        aggregatedData = this.callPlanService.aggregateRepCallPlans(this.formattedCallPlans.past);
        break;
      case 'call-plan-future':
        aggregatedData = this.callPlanService.aggregateRepCallPlans(this.formattedCallPlans.future);
        break;
    }
    if (!_.isEmpty(aggregatedData)) {
      this.plan = aggregatedData[0];
      this.initializeCallPlanDetails(this.plan);
    }
  }

  private initializeCallPlanDetails(plan) {
    this.goals = [];
    if (plan) {
      plan.repPlans.forEach((rp) => {
        rp.actualCalls = this.goalOrCompletedValue(rp.indskr_hocalls, rp.indskr_actualcalls);
        rp.totalGoalCalls = rp.indskr_hocalls ? rp.indskr_hocalls : 0;
        rp.actualEmails = this.goalOrCompletedValue(rp.indskr_hoemails, rp.indskr_actualemails);
        rp.totalGoalEmails = rp.indskr_hoemails ? rp.indskr_hoemails : 0;
        rp.actualF2FMeetings = this.goalOrCompletedValue(rp.indskr_f2fgoals, rp.totalf2fmeetingscompleted);
        rp.totalGoalF2FMeetings = rp.indskr_f2fgoals ? rp.indskr_f2fgoals : 0;
        rp.actualRemoteMeetings = this.goalOrCompletedValue(rp.indskr_remotemeetinggoals, rp.totalremotemeetingscompleted);
        rp.totalGoalRemoteMeetings = rp.indskr_remotemeetinggoals ? rp.indskr_remotemeetinggoals : 0;
        rp.actualPhoneCalls = this.goalOrCompletedValue(rp.indskr_phonecallgoals, rp.totalphonecallscompleted);
        rp.totalGoalPhoneCalls = rp.indskr_phonecallgoals ? rp.indskr_phonecallgoals : 0;
        rp.actualEvents = this.goalOrCompletedValue(rp.indskr_eventgoals, rp.indskr_completedevents);
        rp.totalGoalEvents = rp.indskr_eventgoals ? rp.indskr_eventgoals : 0;
        rp.allRepCallPlansApproved = rp.allRepCallPlansApproved == true && rp.statuscode == 2 ? true : false;
        rp.completedGoals = rp.actualCalls + rp.actualEmails + rp.actualF2FMeetings + rp.actualRemoteMeetings + rp.actualPhoneCalls + rp.actualEvents;
        rp.definedGoals = rp.totalGoalCalls + rp.totalGoalEmails + rp.totalGoalF2FMeetings + rp.totalGoalRemoteMeetings + rp.totalGoalPhoneCalls + rp.totalGoalEvents;
        rp.percentage = rp.completedGoals && rp.completedGoals > 0 ? _.toNumber(((rp.completedGoals / rp.definedGoals) * 100).toFixed(0)) : 0;
        rp.goals = this.getGoals(rp, true);
      });
      this.goals = this.getGoals(this.plan);
    }
  }

  private goalOrCompletedValue(goal, completed) {
    let value = 0;
    if (completed) {
      if(!this.displaygoalsbeyond100) value = completed > goal ? goal : completed;
      else value = completed;
    }
    return value;
  }

  private getGoals(plan, forRepCallPlan = false) {
    let goals = [];
    let cycleplanengagementcalculation;
    if (plan.cycleplanengagementcalculation) {
      cycleplanengagementcalculation = plan.cycleplanengagementcalculation
    } else {
      if (!_.isEmpty(plan.repPlans)) {        
        cycleplanengagementcalculation = plan.repPlans.some((plan) => plan.cycleplanengagementcalculation == 548910003) ? 548910003 : plan.repPlans[0].cycleplanengagementcalculation
      }
    }

    // Engagements
    if (plan.totalGoalCalls) {
      goals.push({
        title: 'ENGAGEMENTS',
        actual: plan.actualCalls,
        goal: plan.totalGoalCalls
      });
    }

    // Messages
    if (plan.totalGoalEmails) {
      goals.push({
        title: 'MESSAGES',
        actual: plan.actualEmails,
        goal: plan.totalGoalEmails
      });
    } else if (this.displayAllActProgress) {
      if (plan['indskr_hoemails']) {
        goals.push({
          title: 'MESSAGES',
          actual: plan.indskr_actualemails,
          goal: plan.indskr_hoemails
        });

      } else {
        if (plan.actualEmails) {
          goals.push({
            title: 'MESSAGES',
            actual: plan.actualEmails,
            goal: 'NA'
          })
        } else if(plan.cycleplanmanualchannels?.includes(PlanManualChannel.Message)){
          if (forRepCallPlan) {
            goals.push({
              title: 'MESSAGES',
              actual: plan.actualEmails,
              goal: 'NA'
            })
          }
        }
      }
    }

    // F2F for Manaual Selection Channel
    if (plan.totalGoalF2FMeetings) {
      goals.push({
        title: 'F2F_MEETINGS',
        actual: plan.actualF2FMeetings,
        goal: plan.totalGoalF2FMeetings
      });
    } else if (this.displayAllActProgress && cycleplanengagementcalculation == 548910003) {
      if(plan.cycleplanmanualchannels?.includes(PlanManualChannel.F2FMeetings)) {
        if (plan.actualF2FMeetings) {
          goals.push({
            title: 'F2F_MEETINGS',
            actual: plan.actualF2FMeetings,
            goal: 'NA'
          })
        } else if (forRepCallPlan) {
          goals.push({
            title: 'F2F_MEETINGS',
            actual: plan.actualF2FMeetings,
            goal: 'NA'
          })
        }
      } else if (!forRepCallPlan && plan.actualF2FMeetings){
        goals.push({
          title: 'F2F_MEETINGS',
          actual: plan.actualF2FMeetings,
          goal: 'NA'
        })
      }
      
    }
    if (plan.totalGoalRemoteMeetings) {
      goals.push({
        title: 'REMOTE_MEETINGS',
        actual: plan.actualRemoteMeetings,
        goal: plan.totalGoalRemoteMeetings
      });
    } else if (this.displayAllActProgress && cycleplanengagementcalculation == 548910003) {
      if(plan.cycleplanmanualchannels?.includes(PlanManualChannel.RemoteMeetings)) {
        if (plan.actualRemoteMeetings) {
          goals.push({
            title: 'REMOTE_MEETINGS',
            actual: plan.actualRemoteMeetings,
            goal: 'NA'
          })
        } else if (forRepCallPlan) {
          goals.push({
            title: 'REMOTE_MEETINGS',
            actual: plan.actualRemoteMeetings,
            goal: 'NA'
          })
        }
      } else if (!forRepCallPlan && plan.actualRemoteMeetings) {
        goals.push({
          title: 'REMOTE_MEETINGS',
          actual: plan.actualRemoteMeetings,
          goal: 'NA'
        })
      }     
    }
    // Phone Call
    if (plan.totalGoalPhoneCalls) {
      goals.push({
        title: 'PHONE_CALLS',
        actual: plan.actualPhoneCalls,
        goal: plan.totalGoalPhoneCalls
      });
    } else if (this.displayAllActProgress && cycleplanengagementcalculation == 548910003) {
      if(plan.cycleplanmanualchannels?.includes(PlanManualChannel.PhoneCalls)) {
        if (plan.actualPhoneCalls) {
          goals.push({
            title: 'PHONE_CALLS',
            actual: plan.actualPhoneCalls,
            goal: 'NA'
          })
        } else if (forRepCallPlan) {
          goals.push({
            title: 'PHONE_CALLS',
            actual: plan.actualPhoneCalls,
            goal: 'NA'
          })
        }
      } else if(!forRepCallPlan &&plan.actualPhoneCalls) {
        goals.push({
          title: 'PHONE_CALLS',
          actual: plan.actualPhoneCalls,
          goal: 'NA'
        })
      }      
    }

    // Events participation
    if (plan.totalGoalEvents) {
      goals.push({
        title: 'EVENTS',
        actual: plan.actualEvents,
        goal: plan.totalGoalEvents
      });
    } else if (this.displayAllActProgress && cycleplanengagementcalculation == 548910003) {
      if(plan.cycleplanmanualchannels?.includes(PlanManualChannel.EventsParticipation)) {
        if (plan.actualEvents) {
          goals.push({
            title: 'EVENTS',
            actual: plan.actualEvents,
            goal: 'NA'
          })
        } else if (forRepCallPlan) {
          goals.push({
            title: 'EVENTS',
            actual: plan.actualEvents,
            goal: 'NA'
          })
        }
      } else if(!forRepCallPlan && plan.actualEvents) {
        goals.push({
          title: 'EVENTS',
          actual: plan.actualEvents,
          goal: 'NA'
        })
      }      
    }
    return goals;
  }


  get formatCallPlans(): { past: any[], present: any[], future: any[] } {
    const currentDay: Date = new Date();
    let formattedCallPlanObj = { past: [], present: [], future: [] };
    this.customerCallPlans.map(plan => {
      const endDateTimeStampAtMidnight = Utility.changeUTCDateToLocalDateWith0Time(new Date(plan.indskr_enddate).getTime(), true).getTime();
      const startDateTimeStampAtMidnight = Utility.changeUTCDateToLocalDateWith0Time(new Date(plan.indskr_startdate).getTime()).getTime();
      plan.startDate = this.datePipe.transform(new Date(plan.indskr_startdate), this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
      plan.endDate = this.datePipe.transform(new Date(plan.indskr_enddate), this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
      plan.title = plan.cycleplanname;
      plan.duration = `${this.translate.instant('CALL_PLAN_DURATION', { startDate: plan.startDate, endDate: plan.endDate })} | ${ this.statePipe.transform(plan.statuscode) }`;
      if (plan.productName) plan.title = plan.title + ' - ' + plan.productName;
      if (endDateTimeStampAtMidnight < currentDay.getTime()) {
        formattedCallPlanObj.past.push(plan);
      } else if ((startDateTimeStampAtMidnight < currentDay.getTime()) && (endDateTimeStampAtMidnight > currentDay.getTime())) {
        formattedCallPlanObj.present.push(plan);
      } else {
        formattedCallPlanObj.future.push(plan);
      }
    });
    return formattedCallPlanObj;
  }

  openCustomerCallPlanSuggest(rp: any) {
    this.callPlanService.selectedRepCallPlan = rp;
    this.callPlanService.selectedCustomerCallPlanId = rp.indskr_customercallplanid;
    this.navService.pushChildNavPageWithPageTracking(CustomerCallPlanSuggest, PageName.CustomerCallPlanSuggest, PageName.CustomerCallPlanPage, { from: PageName.CustomerCallPlanDetails });
  }

  private _checkBUConfig() {
    if(this.authService.user.buConfigs['indskr_callplanprogress']) this.hasCallPlanProgress = true;
    if(this.authService.user.buConfigs['indskr_displaygoalsbeyond100']) this.displaygoalsbeyond100 = true;
    if(this.authService.user.buConfigs['indskr_displayallactivitiesprogress']) this.displayAllActProgress = true;
  }

}
