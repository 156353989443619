import { GlobalUtilityService } from './../../services/global-utility.service';
import { isValid, differenceInMinutes } from "date-fns";
import { Presentation } from '../presentation/presentation.class';
import { Resource } from '../resource/resource.class';
import { EventPresentations } from '../events-tool/event.class';
import { ConfiguredFields } from '../authentication/configured.field.class';
import { UpdateTypeAndSubTypeActivityPayLoad } from '@omni/data-services/meeting/meeting.data.service';
import _ from 'lodash';
import { PageName } from '@omni/services/navigation/navigation.service';
import { IONote } from '../io/io-note.class';
import { CustomerAssessment, InternalSurvey } from '../customer-assessment/customer-assessment.class';

export enum ActivityType {
    AllActivity='All Activities',
    Appointment= 'Appointment',
    Email = 'Email',
    TimeOff = 'TimeOff',
    WeChat = 'WeChat',
    TimeOffRequest = 'TimeOffRequest',
    Sample = 'Sample',
    JointMeeting= 'Joint Meeting',
    FollowUp = 'followuptask',
    CaseIntake = 'CaseIntake',
    Order = 'indskr_order',
    EventRegistration = 'EventRegistration',
    LiveMeet = 'LiveMeet',
    PhoneCall = 'PhoneCall',
    RemoteMeeting = 'RemoteMeeting',
    SurgeryOrder = 'SurgeryOrder',
    JointPhoneCall = 'JointPhoneCall',
    Event = 'Event',
    ProcedureTracker = "ProcedureTracker",
    ConsentActivity = 'ConsentActivity',
    Other = "Other",
    WebsiteAccess="WebsiteAccessLogs",
    MyActivity = 'MyActivity',
    CustomerSurvey = "CustomerSurvey",
    CustomerJourneyAppointment = "CustomerJourneyAppointment",
    CustomerJourneyPhoneCall = "CustomerJourneyPhoneCall",
    CustomerJourneyEmail = "CustomerJourneyEmail",
    StoreCheck = "StoreCheck",
    SetBooking = "SetBooking",
}

export enum ActivityTypeCode {
    Appointment = 100000000,
    TimeOff = 100000001,
    STORE_CHECK = 548910000
}

export enum FormatType {
  ACCOUNT_VISIT = "548910000",
  HCP_MEETING = "548910001"
}

export enum ActivityTypeCodeRaw {
    Appointment = 'appointment',
    Email = 'email',
    SampleDrop = 'indskr_sampledrop',
    FollowUpTask = 'followuptask',
    Order = 'indskr_order',
    CaseIntake = 'medical_inquiry',
    PhoneCall = 'phonecall'
}

export enum ActivityColorCode {
    ActivityCompleted = '#78C0FF',
    ActivityOpen = '#c2e6ff',
    TimeOffActivity = '#bf9aad',
    TimeOffRequest = '#bf9aad',
    FollowUpTask = '#c2e6ff',
    Order = '#c2e6ff',
    Sample = '#c2e6ff',
    ProcedureLog = '#00ff00',
    StoreCheck = '#c2e6ff',
    SetBookingCancelled = '#ff0000'
}


export enum ActivityPriority {
    Normal, Urgent
}

export enum MeetingActivityState{
    Open,Completed,Canceled,Scheduled,ReadOnly
}

export enum PhoneCallActivityState{
  Open,Completed,Cancelled
}
export enum ActivityTimes {
    Day = 86572800,
    Hour = 3607200,
    HalfHour = 1803600,
    Minute = 60120,
    Second = 1002

}

export interface ConfigFieldOptionResponse {
  LogicalName: string;
  MetadataId: string;
  OptionSet: ConfigFieldOptionSet;
}

export interface ConfigFieldOptionSet {
  MetadataId: string;
  HasChanged: any;
  IsCustomOptionSet: boolean;
  IsGlobal: boolean;
  IsManaged: boolean;
  Name: string,
  ExternalTypeName: string,
  OptionSetType: string,
  IntroducedVersion: any;
  ParentOptionSetName: any;
  Description: any;
  DisplayName: any;
  IsCustomizable: any;
  Options: ConfigFieldOption[];
}

export interface ConfigFieldOption {
  Value: number;
  Color: any;
  IsManaged: boolean;
  ExternalValue: any;
  MetadataId: any;
  HasChanged: any;
  Description: any;
  ParentValues: any;
  Label: ConfigFieldOptionLabel;
}

export interface ConfigFieldOptionLabel {
  LocalizedLabels: ConfigFieldOptionLabelValues;
  UserLocalizedLabel: ConfigFieldOptionLabelValues;
}

export interface ConfigFieldOptionLabelValues {
  Label: string;
  LanguageCode: number;
  IsManaged: boolean;
  MetadataId: string;
  HasChanged: any;
}

export interface ConfigFieldOptionValue {
  activity: string; // Meeting, Phone Call etc.
  fieldName: string; // Custom Field Name: indskr_my_field_name.
  fieldLabel: string; // Custom Field Title: My Field.
  label: string; // key: title to display.
  value: number; // value: actual value to pass in the API.
  valueType: string; // for which OptionSet we fetched the value. For example: Type, SubTypes, Time slots.
  optionSetType: string; // Picklist, Virtual etc.
  order: number; // For sorting.
}

export interface MeetingActivityType {
  indskr_activitytypeid: string;
  statuscode: number;
  statecode: number;
  indskr_name: string;
  indskr_displaylivetime: boolean;
  indskr_mandatory: boolean;
  indskr_default: boolean;
  indskr_remotechannel: boolean;
  indskr_omnipresenceactivity: number;
  indskr_contentrequired: boolean;
  hcpmandatory: boolean;
  indskr_type: string;
  indskr_defaultformatforkitbooking: boolean;
}

export interface MeetingSubActivityType {
  indskr_activitysubtypeid: string;
  statuscode: number;
  statecode: number;
  indskr_name: string;
  indskr_activitytype: string;
  indskr_productmandatory: boolean;
  indskr_displaylivetime: boolean;
  indskr_mandatory: boolean;
  indskr_default: boolean;
  indskr_remotechannel: boolean;
  indskr_omnipresenceactivity: number;
}

export enum  MeetingActivityTypeCode {
  PHONE_CALL = 548910001,
  MEETING = 548910000,
}

/**
 * Base class for activites, has common shared properties with Email, Phonecall, Appointment activities
 *
 * @export
 * @class Activity
 */
export class Activity {
    public _id: string;
    public _rev: string;
    public lastUpdatedTime: number;

    public meetingURL:string;
    public scheduledStart: Date;
    public scheduledEnd: Date;
    public durationInMinutes: number;
    public isBestTime: boolean;
    public prevScheduledStart?: Date;
    public prevScheduledEnd?: Date;

    public scheduledStartLocale: string;

    public instanceTypeCode: string;

    public allDayEvent: boolean;
    public teamRequest: boolean;

    public created: Date;
    public modified: Date;
    public completed: Date;

    public subject: string;
    public description: string;
    public reason: string;

    public meetingOwner: string;
    public ownerId:any;

    public type: ActivityType;
    public priority: ActivityPriority;

    public ID: string;
    public status: number;
    public statusString: string;
    public state: number;

    public crmDetailed: boolean;
    public expandedTimeText: string;

    public expanded:boolean;
    public expandIcon:string;
    public location:string;
    public notes:string;
    public meetingNotes: Array<IONote> = [];
    public products:Array<any>; //added to prevent the stupid reference error

    //Used for calendar
    public color: string;

    public indskr_type: number;
    public indskr_typeFormattedValue: string;
    public indskr_subtype: number;
    public indskr_subtypeFormattedValue: string;
    public isCallNotesAvailable: boolean = false;
    public info : string = '';

    public hasOfflineChange: boolean = false;
    public offlineUploadError: string;

    public meetingOwnerId: string;
    public meetingOwnerName: string;
    public isFromXperiences: boolean;

    public indskr_activitytype: string;
    public indskr_activitysubtype: string;
    public activityTypeName: string;
    public activitySubTypeName: string;
    
    public createdonbehalfby: string;
    public createdonbehalfbyName: string;
    public createdby: string;
    public createdbyName: string;

    // Additonal Config Fields
    public appConfigFields: ConfiguredFields[] = [];
    public appconfiglookupfields: UpdateTypeAndSubTypeActivityPayLoad[] = [];
    public omnip_datecompleted: string;
    public accessedFrom: PageName; // to retian contact details segment - it can be accessed by new  activity / time line.
    public conflictingActivityIds: Map<string, boolean>;
    public closeByActivityIds: Map<string, boolean>;
    public offlineId: string;
    public closeByCompletedActivityIds: Map<string, boolean>;

    public isDiffPosition: boolean = false;
    public meetingOwnerPositionIds?: any[] = [];
    public rejectionReason:string;

    // --------------------------------No Detailing in a Meeting-------------------------------- //
    public indskr_meetingcontentreasonsid:string;
    // --------------------------------No Detailing in a Meeting-------------------------------- //

    /**************************Customer Journey**************************/
    public journeyName: string;
    public journeyId: string;
    public journeyStart: Date;
    public journeyEnd: Date;
    public phonecallphonenumber: string;

    public marketing_email: string;
    public email_sent: number;
    public email_delivered: number;
    public email_opened: number;
    public total_opened: number;
    public email_clicked: number;
    public total_clicked: number;
    public journeyActivityContacts:Array<any> = [];
    public journeyActivityProducts:Array<any> = [];
    public journeyActivityPresentations:Array<any> = [];
    public journeyActivityAccounts:Array<any> = [];
    public journeyActivityCoVisitors:Array<any> = [];
    public isRealtimeMarketingEmail: boolean = false;
    public interactionEvent: string;
    public interactionTimestamp: Date;
    public templatePreviewHtml: string; 
    public templateName: string;
    public owningteam: string;
    /**************************Customer Journey**************************/

    /*<<<<<<<<<<<<<<<<<<<<<<<<<<<Call Plan Activities>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
    public customerCallPlanId: string;
    public customerCallPlanName: string;
    /*<<<<<<<<<<<<<<<<<<<<<<<<<<<Call Plan Activities>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/

    public indskr_reasonforcancellation : string
    public indskr_cancellationreasonother : string;
    public indskr_reasonforcancellationname : string;
    public cancellationreason:string;

    public accounts: Array<any> = [];

    //public activityState:Array<string> = ['Open','Completed','Canceled','Scheduled'];
    constructor (raw: object) {
        if (!raw) return;
        this.scheduledEnd = raw['scheduledend'];
        if (raw.hasOwnProperty('stateCode')) {
            this.state = parseInt(raw['stateCode']);
        }
        if (raw.hasOwnProperty('statecode')) {
            this.state = parseInt(raw['statecode']);
        }

        switch (raw['activitytypecode']) {
            case 'email':
                this.type = ActivityType.Email;
                break;

            case 'appointment':
            this.type = ActivityType.Appointment;
            // this is added as in timelien service state is not coming and statusString turns out to be N/A
            // So specially in case of account timeline, if state is not there and status is 3 that means state would be completed and which is why assigned this.state = 1 if status is 3. that too if state is not there
            if (!this.state || isNaN(this.state) || this.state === undefined) {
              if (raw.hasOwnProperty('statuscode')) {
                this.status = parseInt(raw['statuscode']);
              }
              if (raw.hasOwnProperty('statuscode')) {
                this.status = parseInt(raw['statuscode']);
              }
              if (this.status === 3) {

                this.state = 1;
              }
            }


            this.color = (this.state === 1 ? ActivityColorCode.ActivityCompleted : ActivityColorCode.ActivityOpen);
            if (raw["indskr_type"] == ActivityTypeCode.TimeOff) {
              this.type = ActivityType.TimeOff;
              this.reason = raw['indskr_totreasonFormattedValue'];
              this.color = ActivityColorCode.TimeOffActivity;
            } else if (raw['indskr_type'] === ActivityTypeCode.STORE_CHECK) {
              this.type = ActivityType.StoreCheck;
            }
            break;

            case 'phonecall':
                this.type = ActivityType.PhoneCall;
                break;

            case 'indskr_sampledrop':
                this.color = (this.state === 1 ? ActivityColorCode.ActivityCompleted : ActivityColorCode.ActivityOpen);
                this.type = ActivityType.Sample;
                break;

            case 'followuptask':
                this.type = ActivityType.FollowUp;
                break;

            case 'indskr_order':
                this.type = ActivityType.Order;
                break;
            case 'medical_inquiry':
                this.type = ActivityType.CaseIntake;
                break;
            case 'SurgeryOrder':
                this.type = ActivityType.SurgeryOrder;
                break;
            case 'ProcedureTracker':
                this.type = ActivityType.ProcedureTracker;
                break;
            default:
                this.type = ActivityType.Appointment;
                // console.error("Activity does not have type");
                break;
        }

        // Pouchdb id & revision
        this._id = raw['_id'];
        this._rev = raw['_rev'];
        this.lastUpdatedTime = raw['lastUpdatedTime'];

        this.subject = raw['subject'];

        this.ID = raw['activityid'] || raw['activityId'];
        this.modified = raw['modifiedon'];
        this.priority = raw['prioritycode'];
        this.instanceTypeCode = raw['instancetypecode'];
        this.status = raw['statuscode'];
        this.location = raw['app_location'] || 'No Location';
        this.notes = raw['indskr_notes'] || '';
        if (Array.isArray(raw['notes']) && raw['notes'].length > 0) {
          raw['notes'].forEach(note => {

            if (note.hasOwnProperty('filesize') && note['filesize'] > 0) { // check if File size is greter than 0 then it has valid document
              note['isdocument'] = true;
            }

            let filteredNotes = this.meetingNotes.filter(noteFromObject => noteFromObject.noteId === note['annotationid'])
            //Some how duplicate notes are coming from service, So filter out the notes which are already present in teh activity.
            if (_.isEmpty(filteredNotes))
              this.meetingNotes.push(new IONote(note));
          });
        }
        this.indskr_type = raw['indskr_type'] || undefined;
        this.indskr_typeFormattedValue = raw[''] || '';
        this.indskr_subtype = raw['indskr_subtype'] || undefined;
        this.indskr_subtypeFormattedValue = raw['indskr_subtypeFormattedValue'] || '';
        this.createdby = raw['createdby'] || '';
        this.createdbyName = raw['createdbyName'] || '';
        this.createdonbehalfby = raw['createdonbehalfby'] || '';
        this.createdonbehalfbyName = raw['createdonbehalfbyName'] || '';
        this.owningteam = raw['owningteam'] || '';
        this.meetingOwnerName = (raw['indskr_ownerfullname']) ? raw['indskr_ownerfullname'] : undefined;
        this.meetingOwnerId = (raw['indskr_ownerid']) ? raw['indskr_ownerid'] : undefined;
        this.meetingOwnerPositionIds = (raw['positionid']) ? [raw['positionid']] : [];

        this.appConfigFields = raw['appConfigFields'] || [];

        if(isValid(new Date(raw['scheduledstart']))){
            this.scheduledStart = new Date(raw['scheduledstart']);
        }else{
            this.scheduledStart = new Date(parseFloat(raw['scheduledstart']));
        }
        if(isValid(new Date(raw['scheduledend']))){
            this.scheduledEnd = new Date(raw['scheduledend']);
        }else{
            this.scheduledEnd = new Date(parseFloat(raw['scheduledend']));
        }
        this.durationInMinutes = this.scheduledStart && this.scheduledEnd ? differenceInMinutes(this.scheduledEnd, this.scheduledStart) : undefined;

        this.scheduledStartLocale = this.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute:'2-digit'});

        this.created = new Date(raw['createdon']);
        this.allDayEvent = raw['isalldayevent'];
        this.teamRequest = false; //raw['teamRequest'];
        this.ownerId = (raw['indskr_ownerid']) ? raw['indskr_ownerid'] : (this.ownerId) ? this.ownerId : '';
        // Temp fix for inbound. Need to come back later.
        if (!this.ownerId && raw['ownerid'] && (typeof raw['ownerid'] == 'string' || raw['ownerid'] instanceof String)) {
            this.ownerId = raw['ownerid'];
        }
        this.meetingOwner = (raw['indskr_ownerfullname']) ? raw['indskr_ownerfullname'] : '';

        if (raw['activitytypecode'] == 'medical_inquiry') {
          this.scheduledStart = new Date(parseFloat(raw['scheduledstart'].toString()));
          this.statusString = raw['caseStage'] ? raw['caseStage'] : '';
          this.ID = raw['caseId'];
          this.meetingOwner = (raw['caseAssignee']) ? raw['caseAssignee'] : (raw['indskr_ownerfullname']) ? raw['indskr_ownerfullname'] : '';
        }

        //this.completed = new Date(raw['omnip_datecompleted']);
        this.expanded = false;
        this.expandIcon = "accordion-add";

        this.expandedTimeText = this.timeToStart;

        this.crmDetailed = false;

        if (raw['activitytypecode'] !== 'medical_inquiry') {
          switch (this.state) {
            case 0:
              this.statusString = 'Open';
              break;

            case 1:
              this.statusString = 'Completed';
              break;

            case 2:
              this.statusString = 'Canceled';
              break;

            case 3:
              this.statusString = 'Scheduled';
              break;

            default:
              this.statusString = 'N/A';
              break;
          }
        }

        this.hasOfflineChange = !!raw['hasOfflineChange'];
        this.offlineUploadError = raw['offlineUploadError'] || undefined;

        /**************************Customer Journey**************************/
        this.journeyName = raw['journeyName'] || undefined;
        this.journeyId = raw['journeyId'] || undefined;
        if(isValid(new Date(raw['journeyStart']))){
          this.journeyStart = new Date(raw['journeyStart']);
        }
        else{
            this.journeyStart = new Date(parseFloat(raw['journeyStart']));
        }
        if(isValid(new Date(raw['journeyEnd']))){
        this.journeyEnd = new Date(raw['journeyEnd']);
        }
        else{
            this.journeyEnd = new Date(parseFloat(raw['journeyEnd']));
        }
        this.phonecallphonenumber = raw['phonecallphonenumber'] || undefined;

        this.marketing_email = raw['marketing_email'] || undefined;
        this.email_sent = parseInt(raw['email_sent']) || 0;
        this.email_delivered = parseInt(raw['email_delivered']) || 0;
        this.email_opened = parseInt(raw['email_opened']) || 0;
        this.total_opened = parseInt(raw['total_opened']) || 0;
        this.email_clicked = parseInt(raw['email_clicked']) || 0;
        this.total_clicked = parseInt(raw['total_clicked']) || 0;
        if(raw['journeyActivityContacts']){
          this.journeyActivityContacts = [];
          raw['journeyActivityContacts'].forEach(a=> {
            let obj:any = {
              contactId: a.contact,
              contactName: a.contactName,
            };
            this.journeyActivityContacts.push(obj);
          });
        }
        if(raw['journeyActivityProducts']){
          this.journeyActivityProducts = [];
          raw['journeyActivityProducts'].forEach(a=> {
            let obj:any = {
              id: a.product,
              name: a.productName,
            };
            this.journeyActivityProducts.push(obj);
          });
        }
        if(raw['journeyActivityPresentations']){
          this.journeyActivityPresentations = [];
          raw['journeyActivityPresentations'].forEach(a=> {
            let obj:any = {
              id: a.presentationId,
              name: a.presentationName,
              thumbnailURL: a.thumbnailUrl
            };
            this.journeyActivityPresentations.push(obj);
          });
        }
        if(raw['journeyActivityResources']){
          this.journeyActivityPresentations = [];
          raw['journeyActivityResources'].forEach(a=> {
            let obj:any = {
              id: a.resourceId,
              name: a.resourceName,
              thumbnailURL: a.thumbnailUrl
            };
            this.journeyActivityPresentations.push(obj);
          });
        }
        if(raw['journeyActivityAccounts']){
          this.journeyActivityAccounts = [];
          raw['journeyActivityAccounts'].forEach(a=> {
            let obj:any = {
              id: a.account,
              name: a.accountName,
            }
            this.journeyActivityAccounts.push(obj);
          });
        }
        if(raw['journeyActivityCoVisitors']){
          this.journeyActivityCoVisitors = [];
          raw['journeyActivityCoVisitors'].forEach(a=> {
            let obj:any = {
              id: a.userId,
              name: a.userName,
            };
            this.journeyActivityCoVisitors.push(obj);
          });
        }
        //realtime marketing email
        this.isRealtimeMarketingEmail = raw['isRealtimeMarketingEmail'] || false;
        this.interactionEvent = raw['interactionEvent'] || '';
        if (raw['timestamp']) {
          if (isValid(new Date(raw['timestamp']))){
            this.interactionTimestamp = new Date(raw['timestamp']);
          }
          else {
            this.interactionTimestamp = new Date(parseFloat(raw['timestamp']));
          }
        } else {
          this.interactionTimestamp = null;
        }
        this.templateName = raw['templateName'] || "";
        this.templatePreviewHtml = raw["templatePreviewHtml"] || "";
        /**************************Customer Journey**************************/

        /*<<<<<<<<<<<<<<<<<<<<<<<<<<<Call Plan Activities>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
        this.customerCallPlanId = raw['customerCallPlanId'] || undefined;
        this.customerCallPlanName = raw['customerCallPlanName'] || undefined;
        this.indskr_cancellationreasonother = raw['indskr_cancellationreasonother'] || '';
        this.indskr_reasonforcancellation = raw['indskr_reasonforcancellation'] || '';
        this.indskr_reasonforcancellationname = raw['indskr_reasonforcancellationname'] || '';
        this.cancellationreason = raw['cancellationreason'] || '';
        /*<<<<<<<<<<<<<<<<<<<<<<<<<<<Call Plan Activities>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>*/
    }

    get getStatusString():string{
        switch (this.state) {
            case 0:
            return 'Open';

            case 1:
            return 'Completed';

            case 2:
            return 'Canceled';

            case 3:
            return 'Scheduled';

            default:
                return 'N/A';

        }
    }

    public get isCompleted(): boolean {
        return this.state === 1 || Number(this.state) === 1;
    }

    public get isReopened(): boolean {
      return (this.state === 0 || Number(this.state) === 0) && !_.isEmpty(this.omnip_datecompleted);
    }

    public get startTime(): string {
        return this.scheduledStart.toLocaleTimeString();
    }

    public get endTime(): string {
      return this.scheduledEnd.toLocaleTimeString();
    }

    public get startDate(): Date {
        return this.scheduledStart;
    }

    public get lengthOfActivity(): string {
        return this._getMessageForTimeLength();
    }

    /**
     * Returns a message like 'Starts in x minutes' depending on the scheduledstart time
     *
     * @returns {string}
     * @memberof Activity
     */
    public get timeToStart(): string {
        let difference = this.scheduledStart.getTime() - new Date().getTime();
        if (difference === 0) return 'N/A';

        let totalMinutes = difference / ActivityTimes.Minute;
        // tslint:disable-next-line:no-magic-numbers
        let differenceInHours = Math.floor(totalMinutes / 60);
        // tslint:disable-next-line:no-magic-numbers
        let differenceInMinutes = totalMinutes % 60;

        if(differenceInHours > 0) {

            // tslint:disable-next-line:no-magic-numbers
            return `Starts in ${Math.floor(totalMinutes / 60)} hours and ${Math.floor(totalMinutes % 60)} minutes`;

        } else {
            // tslint:disable-next-line:no-magic-numbers
            return `${Math.abs(Math.floor(totalMinutes / 60))} hours and ${Math.abs(Math.floor(totalMinutes % 60))} minutes ago`;
        }

    }

    public get isOfflineCreatedButNotUploaded(): boolean {
        return this.ID.includes('offline');
    }

    resetScheduledStartLocale() {
        this.scheduledStartLocale = this.scheduledStart.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute:'2-digit'});
    }

    /**
     * Returns a string based off the time difference between now and when it is due to start
     * I now realized I just calculated how long the meeting is and that this is useless.
     */
    _getMessageForTimeLength(): string {
        let difference = this.scheduledEnd.getTime() - this.scheduledStart.getTime();

        if (difference === 0) return 'N/A';

        // let differenceInMinutes =  Math.round(difference / ActivityTimes.Minute);
        // let differenceInHours = Math.round(difference / ActivityTimes.Hour);

        var days, hours, mins, secs;
        secs = Math.floor(difference / 1000);
        mins = Math.floor(secs / 60);
        secs = secs % 60;
        hours = Math.floor(mins / 60);
        mins = mins % 60;
        days = Math.floor(hours / 24);
        hours = hours % 24;

        if(days > 0){
            if(hours > 0 && mins > 0){
                return `${days}d ${hours}h ${mins}m`
            }else if(hours > 0 && mins <= 0){
                return `${days}d ${hours}h`
            }else if(hours <= 0 && mins > 0){
                return `${days}d ${mins}m`
            }
        }else{
            if(hours > 0 && mins > 0){
                return `${hours}h ${mins}m`
            }else if(hours > 0 && mins <= 0){
                return `${hours}h`
            }else if(hours <= 0 && mins > 0){
                return `${mins}m`
            }
        }
        //If its less than 60 minutes, display 'X minutes'
        // tslint:disable-next-line:no-magic-numbers
        // if (difference <= ActivityTimes.Minute * 60) return `${Math.ceil(differenceInMinutes)}m`;

        //If its more than an hour and less than 12
        // tslint:disable-next-line:no-magic-numbers
        // if (difference <= ActivityTimes.Hour * 12) return `${Math.ceil(differenceInHours)}h`;

        return 'N/A';
    }

}

export enum ActivityTypeToFilterNameMap{
    'All Activities' = 'All Activities',
    'Appointment' = 'Meetings',
    'PhoneCall' = 'Phone Calls',
    'Email' = 'Messages',
    'TimeOff' = 'Time Off',
    'Joint Meeting' = 'Joint Meetings',
    'Sample' = 'Allocation Orders',
    'followuptask' = 'Follow-up Actions',
    'indskr_order' = 'Sales Orders',
    'SurgeryOrder' = 'Surgery Orders',
    'ProcedureTracker' = 'Bulk Procedure Logs',
    'CaseIntake' = 'Customer Inquiries',
    'LiveMeet' = 'LiveMeet',
    'RemoteMeeting'= 'LiveTime Meetings',
    'JointPhoneCall' = 'Joint Phone Calls',
    'StoreCheck' = 'StoreCheck',
    'SetBooking' = 'SetBooking'
}

export class EmbeddedInteraction {
    constructor(public id: string,
        public name: string,
        public thumbnailUrl: string,
        public type: EmbeddedInteractionType,
        public sequence: number,
        public interaction: Presentation | Activity | Resource | EventPresentations | CustomerAssessment | InternalSurvey,
        public iconName: string,
        public completed?: boolean,
        public disableRemoveButton?: boolean) {
    }
}

export enum EmbeddedInteractionType {
    Content = "Content",
    Activity = "Activity",
    Survey = "Survey"
}

export class ActivityOutcome {
  public indskr_activityoutcomeid:string;
  public indskr_name:string;
  public indskr_omnipresenceactivity:ActivityOutcomeType;
  public indskr_mandatory:boolean;

  constructor(raw:any){
    this.indskr_activityoutcomeid = raw['indskr_activityoutcomeid'];
    this.indskr_name = raw['indskr_name'];
    this.indskr_omnipresenceactivity = raw['indskr_omnipresenceactivity'];
    this.indskr_mandatory = raw['indskr_mandatory'];
  }
}

export enum ActivityOutcomeType {
  Meeting = 600000000,
  ProcedureLog = 600000001,
}

export class ActivityCancellationReson {
  indskr_activitytype: number;
  indskr_name: string;
  reasonId: string;
  indskr_reason: string;
  indskr_status: string;
  indskr_statusreason: string;
  statecode : number;
  constructor(raw) {
    this.indskr_activitytype = raw['indskr_activitytype'] || '';
    this.indskr_name = raw['indskr_name'] || '';
    this.reasonId = raw['indskr_opportunityreasonid'] || '';
    this.indskr_reason = raw['indskr_reason'] || '';
    this.indskr_status = raw['indskr_status'] || '';
    this.indskr_statusreason = raw['indskr_statusreason'] || '';
    this.statecode = 0
  }
}
