export enum RepCallPlanState {
    "New" = 100000002,
    "Open" = 1,
    "ForReview" = 100000003,
    "Approved" = 2,
    "Locked" = 100000001,
    "NotApproved" = 548910000
}

export enum CallPlanFilter {
    "ALL" = "ALL",
    "PAST" = "PAST",
    "CURRENT" = "CURRENT",
    "FUTURE" = "FUTURE"
}

export enum PlanManualChannel {
    "F2FMeetings" = 548910000,
    "RemoteMeetings" = 548910001,
    "PhoneCalls" = 548910002,
    "Message" = 600000000,
    "EventsParticipation" = 548910003
}

export enum CallPlanCustomerType {
  CONTACT = "contact",
  ACCOUNT = "account",
}

// export class RepCallPlan {
//     /**
//      *  Internal dynamics IDs for reference & filtering
//      *
//      * @private
//      * @type {string}
//      * @memberof RepCallPlan
//      */
//     private callPlanID: string;
//     private cyclePlanID: string;
//     public customerID: string;
//     private productID: string;
//     private segmentID: string;
//     private userID: string;
//     private positionID: string;
//     private parentPositionID: string;

//     public state: number;
//     public stateCode: number;
//     public statusCode: number;

//     public name: string;
//     public usersName: Array<string>;
//     public positionName: string;
//     public parentPositionName: string;
//     public segmentName:string;
//     public productName:string;
//     public firstName:string;
//     public lastName:string;
//     public customerName: string;

//     public startDate: Date;
//     public endDate: Date;
//     public createdOn: Date;

//     public actualEmails: number;
//     public actualCalls: number;

//     public suggestedEmails: number;
//     public suggestedCalls: number;

//     public emailCompletedPercentage: number;
//     public callCompletedPercentage: number;

//     public goalCalls: number;
//     public goalEmails: number;
//     public timeRemaining: number;

//     public accountIDs: Array<string>;

//     //Customer call plan
//     public customerCallPlan: RepCallPlan;

//     //Other rep call plans
//     public otherRepCallPlans: Array<RepCallPlan>;

//     get isInForReview(): boolean {
//         return this.statusCode === RepCallPlanState.ForReview;
//     }
//     get isOpen(): boolean {
//         return this.statusCode === RepCallPlanState.Open;
//     }
//     get ID(): string {
//         return this.callPlanID;
//     }

//     get DTO(): object {
//         return {
//             indskr_customercallplanid: this.callPlanID,
//             _indskr_cycleplanid_value: this.cyclePlanID,
//             _indskr_segment_v2_value:this.segmentID,
//             userid: this.userID,
//             indskr_positionid: this.positionID,
//             _indskr_productid_value: this.productID,
//             _indskr_customerid_value: this.customerID,
//             parentposid: this.parentPositionID,
//             indskr_hoemails: this.goalEmails,
//             indskr_hocalls: this.goalCalls,
//             indskr_suggestedemails: this.suggestedEmails,
//             indskr_suggestedcalls: this.suggestedCalls,
//             indskr_actualcalls: this.actualCalls,
//             indskr_actualemails: this.actualEmails,
//             indskr_meetingcompletionpercent: this.callCompletedPercentage,
//             indskr_emailcompletionpercent: this.emailCompletedPercentage,
//             createdon: this.createdOn.getTime(),
//             indskr_enddate: this.endDate.getTime(),
//             indskr_startdate: this.startDate.getTime(),
//             indskr_name: this.name,
//             positionname: this.positionName,
//             username: this.usersName,
//             parentpositionname: this.parentPositionName,
//             indskr_state: this.state,
//             statecode: this.stateCode,
//             statuscode: this.statusCode,
//             productName: this.productName,
//             segmentName: this.segmentName,
//             firstName: this.firstName,
//             lastName: this.lastName,
//             customerName: this.customerName
//         }
//     }

//     constructor(raw: object) {
//         //IDs
//         this.callPlanID = raw['indskr_customercallplanid'];
//         this.cyclePlanID = raw['_indskr_cycleplanid_value'];
//         this.segmentID = raw['_indskr_segment_v2_value'] || raw["marketSegmentId"];
//         this.userID = raw['userid'];
//         this.positionID = raw['indskr_positionid'];
//         this.productID = raw['_indskr_productid_value'];
//         this.customerID = raw['_indskr_customerid_value'];
//         this.parentPositionID = raw['parentposid'];


//         this.actualCalls = 0;
//         this.actualEmails = 0;
//         this.emailCompletedPercentage = 0;
//         this.callCompletedPercentage = 0;

//         //Email and call progress & goals
//         if (raw['indskr_hoemails']) this.goalEmails = parseInt(raw['indskr_hoemails']);
//         if (raw['indskr_hocalls']) this.goalCalls = parseInt(raw['indskr_hocalls']);

//         if (raw['indskr_actualcalls']) this.actualCalls = parseInt(raw['indskr_actualcalls']);
//         if (raw['indskr_actualemails']) this.actualEmails = parseInt(raw['indskr_actualemails']);

//         if (raw['indskr_emailcompletionpercent']) this.emailCompletedPercentage = raw['indskr_emailcompletionpercent'];
//         if (raw['indskr_meetingcompletionpercent']) this.callCompletedPercentage = raw['indskr_meetingcompletionpercent'];

//         //Dates
//         if (raw['createdon']) this.createdOn = (raw['createdon'] != "null" && raw['createdon'] != "") ? new Date(raw['createdon']) : undefined;
//         if (raw['indskr_enddate']) this.endDate = (raw['indskr_enddate'] != "null" && raw['indskr_enddate'] != "") ? new Date(parseInt(raw['indskr_enddate'])) : undefined;
//         if (raw['indskr_startdate']) this.startDate = (raw['indskr_startdate'] != "null" && raw['indskr_startdate'] != "") ?  new Date(parseInt(raw['indskr_startdate'])) : undefined;

//         //Other
//         this.name = raw['indskr_name'];
//         this.positionName = raw['positionname'];
//         this.parentPositionName = raw['parentpositionname'];
//         this.state = parseInt(raw['indskr_state']);
//         this.statusCode = parseInt(raw['statuscode']);
//         this.stateCode = parseInt(raw['statecode']);
//         this.productName= raw['productName'];
//         this.segmentName= raw['segmentName'] || raw["marketSegmentName"];
//         this.firstName = raw['firstName'];
//         this.lastName= raw['lastName'];
//         let  now = new Date().getTime();
//         this.customerName = this.firstName + " " + this.lastName;

//         this.accountIDs = [];

//         if (raw['callPlanAccounts'] && Array.isArray(raw['callPlanAccounts'])) {
//             this.accountIDs = raw['callPlanAccounts'];
//         }

//         if (this.startDate && this.endDate) {
//             let totalTime  = this.endDate.getTime() -this.startDate.getTime();
//             let diffFromNow = this.endDate.getTime() - now;
//             let timeLeft = Math.round((diffFromNow/totalTime)*100);
//             this.timeRemaining = timeLeft<0 ? 0: timeLeft>100 ? 100 :timeLeft;
//         }

//         this.otherRepCallPlans = [];

//         if (raw['customerCallPlan']) {
//             this.customerCallPlan = new RepCallPlan(raw['customerCallPlan']);
//         }

//         if (raw['otherPositionRepCallPlans'] && Array.isArray(raw['otherPositionRepCallPlans'])) {
//             raw['otherPositionRepCallPlans'].map(otherRepCallPlan => {
//                 this.otherRepCallPlans.push(new RepCallPlan(otherRepCallPlan));
//             });
//         }

//         if (raw['indskr_suggestedcalls']) this.suggestedCalls = raw['indskr_suggestedcalls'];
//         if (raw['indskr_suggestedemails']) this.suggestedEmails = raw['indskr_suggestedemails'];

//         //users name
//         this.usersName = [];
//         if(raw['username']) {
//             this.usersName.push(raw['username']);
//         }
//         if(raw['users'] && Array.isArray(raw['users'])) {
//             let usersArray = raw['users'];
//             usersArray.map(rawUser => {
//                 this.usersName.push(rawUser.username);
//             })
//         }
//     }
// }
