import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import _ from 'lodash';
@Pipe({
    name: "transformLinkableText"
})
export class TransformLinkableText implements PipeTransform{
  constructor(private readonly sanitizer: DomSanitizer){}

    transform(text: string): SafeHtml {
      const urlRegexp = new RegExp(/([^\S]|^)(((https?\:\/\/)|(www\.))(\S+)[^\s]+[^. ,])/,'gi');
      let checkedText = '';
      checkedText = (text.toString() || "").replace(urlRegexp, function(match, space, url) {
        return space + '<a id="' + url + '"' + ' style="color:#007AFF; text-decoration:underline">' + url + '</a>';
      });   
      let formattedText = this.sanitizer.bypassSecurityTrustHtml(checkedText);
      return formattedText;
    }

}
