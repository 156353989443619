import { childUser } from './../../classes/authentication/user.class';
import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from "../disk/disk.service";
import { CaseActivity } from "../../classes/case-intake/case-activity.class";
import * as moment from 'moment';
import {
    CaseResponsePreference,
    CaseType,
    RawCaseResponse,
    RawCaseType, RawTherapeuticArea,
    TherapeuticArea
} from "../../classes/case-intake/case-utility.class";
import { Brand } from "../../classes/brand/brand.class";
import { Account } from "../../classes/account/account.class";
import { Contact } from "../../classes/contact/contact.class";
import { DB_KEY_PREFIXES, PREFIX_SEARCH_ENDKEY_UNICODE } from "../../config/pouch-db.config";
import { ActivityType } from "../../classes/activity/activity.class";
import { AuthenticationService } from "../authentication.service";
import { ActivityService } from "../activity/activity.service";
import { EventsService } from "../events/events.service";
import { TrackingEventNames, TrackService } from "../logging/tracking.service";
import {MyAssistantService, NOTIFICATION} from "../my-assistant/my-assistant.service";
import { DeltaService } from "../../data-services/delta/delta.service";
import { UIService } from "../ui/ui.service";
import { FeatureActionsMap } from "../../classes/authentication/user.class";
import { GlobalUtilityService } from "../global-utility.service";
import { TranslateService } from '@ngx-translate/core';
import { OrderByPipe } from '../../pipes/orderby.pipe';
import {AccountOfflineService} from "../account/account.offline.service";
import {ContactOfflineService, getBucketId} from "../contact/contact.service";
import {NavigationService, PageName} from '../../services/navigation/navigation.service';
import {CallPlanOfflineService} from '../../services/call-plan/call-plan.offline.service';
import { CaseCategory } from '../../classes/case-intake/case-utility.class';
import { Endpoints } from 'src/config/endpoints.config';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { SurveyService } from '@omni/services/survey/survey.service';
import {IndNotificationDataModel} from "@omni/models/indNotificationDataModel";
import _ from 'lodash';
import { Utility } from '@omni/utility/util';

@Injectable({
  providedIn: 'root'
})
export class CaseManagementService {

    public myCases: CaseActivity[] = [];
    public teamCases: CaseActivity[] = [];

    caseFilterText: string = 'All';
    caseTypeList: CaseType[] = [];
    responsePreferenceList: CaseResponsePreference[] = [];

    caseProductSKU: Brand[] = [];
    currentCase: CaseActivity;

    caseTherapeuticAreas: TherapeuticArea[] = [];

    private currentSelectionSource = new BehaviorSubject<CaseActivity>(undefined);

    private detailOptionSource = new BehaviorSubject<Object>(undefined);
    public detailOptionObservable = this.detailOptionSource.asObservable();

    public filterObject: any;

    //Alternative for UIservice to avoid disrupting existing logic
    showRightPane: boolean = false;
    listMode: string = "myCases";

    /* Handling the new case screeen on right panel with this */
    showNewCase: boolean = false;
    searchText: string = "";

    accessedFrom: AccesingMode = AccesingMode.CASE_TOOL;

    case_contacts: Contact[] = [];
    case_accounts: Account[] = [];
    public casesSource$ = new BehaviorSubject<CaseActivity[]>([]);

    teamInquiriesFilter: {
        type: string,
        product: string,
        user: string
    } = { type: 'All', product: '', user: '' };
    filterUserObject: childUser;
    private orderBy = new OrderByPipe();
    isCaseSelected = false;
    private inquiryNotificationModel: IndNotificationDataModel;
    public isSurveyValid: boolean = true;

    constructor(
        private diskService: DiskService,
        private _ngZone: NgZone,
        private authenticationService: AuthenticationService,
        private activityService: ActivityService,
        private events: EventsService,
        private myAssistantService: MyAssistantService,
        private deltaService: DeltaService,
        private uiService: UIService,
        public utilityService: GlobalUtilityService,
        private translate: TranslateService,
        private accountOfflineService:AccountOfflineService,
        private contactOfflineService: ContactOfflineService,
        private navService: NavigationService,
        private callPlanService: CallPlanOfflineService,
        private surveyService: SurveyService,
    ) {
      this.events.observe("sync:completed").subscribe(() => {
        this.reMapDataWithValues(
          this.accountOfflineService.accounts,
          this.contactOfflineService.contacts
        ).then(r => console.log('Cases Data has been successfully mapped'));
      });
    }

    getMyCaseList(): CaseActivity[] {
        let filteredList = this.myCases;
        if (this.caseFilterText === 'All') {
            //this.searchText = '';
            // return filteredList;
        }
        else {
            if (this.caseFilterText) {
                try {
                    filteredList = filteredList.filter(e => {
                        if (e._case_type) {
                            return e._case_type.name === this.caseFilterText;
                        }
                        else
                            return false;
                    });
                } catch (error) { }
            }
        }

        if (this.searchText) {
            if (this.searchText.trim() === '') {
            }
            else {
                let filteredCase = filteredList.filter(e => {
                    if (e._case_trans_customer_name) {
                        return e._case_trans_customer_name.toLowerCase().includes(this.searchText.trim().toLowerCase())
                    }
                });
                if (!filteredCase.length) {
                    filteredCase = filteredList.filter(e => {
                        if (e._case_notes) {
                            return e._case_notes.toLowerCase().includes(this.searchText.trim().toLowerCase())
                        } else if (e._case_type && e._case_type.name) {
                            return e._case_type.name.toLowerCase().includes(this.searchText.trim().toLowerCase())
                        }
                    });
                }
                if (filteredCase) {
                    filteredList = filteredCase;
                }
            }
        }
        let result = Object.assign([], filteredList);
        result = this.orderBy.transform(result, "startDate", false);
        this.casesSource = result
        return result;
    }

    getTeamCaseList(): CaseActivity[] {
        let filteredList = this.teamCases;
        if (this.teamInquiriesFilter.type === 'All') {
            //return filteredList;
        }
        else {
            if (this.teamInquiriesFilter.type) {
                try {
                    filteredList = this.teamCases.filter(e => {
                        if (e._case_type) {
                            return e._case_type.name === this.teamInquiriesFilter.type;
                        }
                        else return false;
                    });
                } catch (error) { }
            }
        }
        if (this.teamInquiriesFilter.product) {
            if (this.filterObject) {
                filteredList = filteredList.filter(e => { return e._case_product_id == this.filterObject.ID });
            }
        }
        if (this.teamInquiriesFilter.user) {
            if (this.filterUserObject) {
                filteredList = filteredList.filter(e => { return e._case_created_by == this.filterUserObject.userId });
            }
        }
        if (this.searchText) {
            if (this.searchText.trim() === '') {
            }
            else {
                let filteredCase = filteredList.filter(e => {
                    if (e._case_trans_customer_name) {
                        return e._case_trans_customer_name.toLowerCase().includes(this.searchText.trim().toLowerCase())
                    }
                });
                if (!filteredCase.length) {
                    filteredCase = this.teamCases.filter(e => {
                        if (e._case_notes) {
                            return e._case_notes.toLowerCase().includes(this.searchText.trim().toLowerCase())
                        } else if (e._case_type && e._case_type.name) {
                            return e._case_type.name.toLowerCase().includes(this.searchText.trim().toLowerCase())
                        }
                    });
                }
                if (filteredCase) {
                    filteredList = filteredCase
                }
            }
        }
        filteredList = this.orderBy.transform(filteredList, "startDate", false);
        this.casesSource = filteredList;
        return filteredList
    }

    assignSelectedCase(input: CaseActivity) {
        this.currentSelectionSource.next(input);
        this.currentCase = input;
    }

    get currentSelectedCase(): Observable<any> {
        return this.currentSelectionSource.asObservable();
    }

    resetFilters() {
        this.filterObject = undefined;
        this.filterUserObject = undefined
        this.searchText = '';
        this.caseFilterText = "All";
        this.listMode = "myCases";
        this.teamInquiriesFilter.product = ''
        this.teamInquiriesFilter.user = '';
        this.teamInquiriesFilter.type = 'All'
    }

    async initNewCaseActivity(type: CaseType, opt?: boolean, preSelectedData?: Object) {
        this.showNewCase = true;
        this.activityService.selectedActivity
        let temp_id = "offline_case_" + Math.floor(new Date().getTime() + Math.random() * 900000);
        let contactid;
        let accountid;
        let meetingID;
        let startTime;
        if (preSelectedData) {
            if (preSelectedData['contacts']) {
                contactid = preSelectedData['contacts'][0].ID || preSelectedData['contacts'];
            }
            if (preSelectedData['accounts'] && !type.surveyTemplate) {
                accountid = preSelectedData['accounts'][0].id 
                || preSelectedData['accounts'][0].accountId 
                || preSelectedData['accounts'];
            }
            if (preSelectedData.hasOwnProperty('ID')) {
                meetingID = preSelectedData['ID'];
            }
            // if (preSelectedData.hasOwnProperty('startTime')) {
            //     startTime = preSelectedData['startTime'];
            // }
        }
        let ownerid = this.authenticationService.user.systemUserID;
        let ownerName = this.authenticationService.user.displayName;
      if (this.getCategoryUsers(type.id).length != 0 || this.getCategoryTeams(type.id).length != 0) {
          ownerid = ownerName = '';
        }
        let raw: any = {
            "productid_value": undefined,
            'indskr_account_value': accountid,
            "customerid_value": contactid,
            "statecode@OData.Community.Display.V1.FormattedValue": "Active",
            "statecode": 0,
            "statuscode@OData.Community.Display.V1.FormattedValue": "Open",
            'indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue': 'Open',
            "statuscode": 0,
            "indskr_expertcategory_value": type.id,
            "indskr_expertcategory": type,
            "indskr_expertcategorytype_value": undefined,
            "createdon": new Date().getTime(),
            "indskr_intakecreated": new Date().getTime(),
            "incidentid": temp_id,
            'indskr_externalid': temp_id,
            "ticketnumber": undefined,
            "indskr_responsepreference": undefined,
            'ownerid_value': ownerid,
            'ownerid_value@OData.Community.Display.V1.FormattedValue': ownerName,
            "isNew": true,
            //"indskr_meeting_value": meetingID,
            "createdby_value": this.authenticationService.user.systemUserID
        }
        if(this.activityService.selectedActivity && this.activityService.selectedActivity.type === ActivityType.PhoneCall){
            raw['indskr_phonecallid'] = meetingID
        }else if(this.activityService.selectedActivity && this.activityService.selectedActivity.type === ActivityType.SetBooking){
          raw['indskr_kitbooking_value'] = meetingID;
          raw['description'] = preSelectedData && preSelectedData['description']? preSelectedData['description'] : "";
        }else{
            raw['indskr_meeting_value'] = meetingID
        }
        let iCase = new CaseActivity(raw);

        if (!opt) {
            this.showNewCase = false;
        }

        this.surveyService.surveyLookupResults = [];
        this.surveyService.assignLookupResult(undefined);

        iCase._id = DB_KEY_PREFIXES.MY_CASES + iCase.ID;
        this.assignSelectedCase(iCase);
        await this.diskService.addCaseToCollection(iCase);
        this.upsertMyCases(iCase);

        let data = {
            id: iCase.ID,
            subject: iCase.caseName,
            location: iCase['state'] === 1 ? 'Resolve' : iCase['state'] === 2 ? 'Cancelled' : iCase._case_stage_value ? iCase._case_stage_value : 'Open',
            scheduledStart: iCase.scheduledStart,
            scheduledEnd: iCase.scheduledEnd,
        };


        try {
            this.uiService.setUIServiceData({
                view: "new-activity", data,
            });
        } catch (error) {
            console.log('mobiscroll calendar plugin issue detected, continue operation')
        }
        if (!this.uiService.toolsActivityActive)
        {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
      return iCase;
    }

    mapUserCases(raw: any[]) {
        this.myCases = raw.map(e => new CaseActivity(e));
        console.log(this.myCases);
    }

    // mapTeamCases(raw: any[]) {
    //     this.teamCases = raw.map(e => new CaseActivity(e));
    //     console.log(this.teamCases);
    // }

    setDetailsOption(input: Object) {
        this.detailOptionSource.next(input);
    }

    async storeCaseType(res: RawCaseType[]) {
        if (res && Array.isArray(res)) {
            this.caseTypeList = res.map(e => new CaseType(e))
        }
    }

    async storeResponsePreferences(res: RawCaseResponse) {
        if (res && Array.isArray(res)) {
            this.responsePreferenceList = res.map(e => new CaseResponsePreference(e));
        }
    }

    async storeTherapeuticAreas(res: RawTherapeuticArea[]) {
        if (res && Array.isArray(res)) {
            this.caseTherapeuticAreas = res.map(e => new TherapeuticArea(e))
        }
    }

    async addNewCase(res: any, data: CaseActivity) {

        /* Remove old entity */
        try {
            await this.diskService.remove(data._id);
        } catch (error) {
            console.log('error deleting old case entity');
        }


        /* update the new value and restore the data to pouch */
        data._case_number = res.caseNumber;
        data._case_status_value = res['status'];
        data._case_stage_value = res['caseStage'];
        data._case_status_id = res['statusCode'];
        data.ID = res.caseId;
        data.description = data._case_type + " " + res.caseNumber;
        data.isNew = false;
        data._case_overriddencreatedon = res['overriddencreatedon'] ? new Date(Number(res['overriddencreatedon'])) : res['overriddencreatedon'];
        data._indskr_intakecreated = res['indskr_intakecreated'] ? new Date(Number(res['indskr_intakecreated'])) : res['indskr_intakecreated'];
        data._case_timeline = res.inquiryTimeline ? data.getTimeline(res['inquiryTimeline']) : [];
        data._id = DB_KEY_PREFIXES.MY_CASES + data.ID;
        this.caseFilterText = "All";
        this.searchText = '';
        this.assignSelectedCase(data);
        try {
            await this.diskService.addCaseToCollection(data);
            await this.upsertMyCases(data);
        } catch (error) {
            console.log('Error storing Inquiry Data on device');
        }
        if (!this.uiService.toolsActivityActive)
        {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
        this.activityService.publishActivityEvent({action: "Update", activity: data});
        // this.events.publish('weekview:RefreshUI');
        return data;
    }

    async generateOfflineCase(data: CaseActivity) {
        console.log(data);
        data._case_status_id = 5;
        data._case_status_value = 'Pending Sync';
        data._case_stage_value = 'Pending Sync';
        data.indskr_casecategory = CaseCategory.MEDICAL;
        await this.diskService.addCaseToCollection(data);
        await this.diskService.insertOfflineCustomerInquiries(data);
        await this.upsertMyCases(data);
        if (!this.uiService.toolsActivityActive)
        {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }
        this.activityService.publishActivityEvent({action: "Update", activity: data});
        return data;
    }

    async storeAllCase(res: Object) {
        console.log(res);
        if (res && res.hasOwnProperty("myCases") && res['myCases']) {
            this.mapUserCases(res['myCases']);
        }
        // if (res && res.hasOwnProperty("teamCases") && res['teamCases']) {
        //     this.mapTeamCases(res['teamCases']);
        // }

    }

    async reMapDataWithValues(
        account: Account[],
        customer: Contact[],
    ) {
        this.case_accounts = account;
        this.case_contacts = customer;

        this.myCases.forEach(uc => {
            try {
                uc._case_product = undefined;

                this.caseProductSKU.map(e => {
                    if (e.ID === uc._case_product_id) {
                        uc._case_product = e;
                    }
                    else {
                        if (e.skus && Array.isArray(e.skus)) {
                            let innerProduct = e.skus.find(s => s.ID === uc._case_product_id);
                            if (innerProduct) {
                                uc._case_product = innerProduct;
                            }
                        }
                    }
                });

                uc._case_type = this.caseTypeList.find(e => { return e.id === uc._case_type_id });
                uc._case_response_preference = this.responsePreferenceList.find(e => { return e.id === uc._case_response_preference_id });
                uc._case_account = account.find(e => { return e.id === uc._case_account_id });
                //uc._case_product = this.caseProductSKU.find(e => { return e.ID === uc._case_product_id });
                uc._case_contact = customer.find(e => { return e.ID === uc._case_contact_id });
                uc._case_category = uc._case_type ? uc._case_type.categoryTypes.find(e => { return e.id === uc._case_category_id }) : undefined;
            } catch (error) {
                console.log("Error Remapping data");
                console.error(error);
            }
        });

        // this.teamCases.forEach(uc => {
        //     try {
        //         uc._case_product = undefined;

        //         this.caseProductSKU.map(e => {
        //             if (e.ID === uc._case_product_id) {
        //                 uc._case_product = e;
        //             }
        //             else {
        //                 if (e.skus && Array.isArray(e.skus)) {
        //                     let innerProduct = e.skus.find(s => s.ID === uc._case_product_id);
        //                     if (innerProduct) {
        //                         uc._case_product = innerProduct;
        //                     }
        //                 }
        //             }
        //         });

        //         uc._case_type = this.caseTypeList.find(e => { return e.id === uc._case_type_id });
        //         uc._case_response_preference = this.responsePreferenceList.find(e => { return e.id === uc._case_response_preference_id });
        //         uc._case_account = account.find(e => { return e.id === uc._case_account_id });
        //         //uc._case_product = this.caseProductSKU.find(e => { return e.ID === uc._case_product_id });
        //         uc._case_contact = customer.find(e => { return e.ID === uc._case_contact_id });
        //         uc._case_category = uc._case_type ? uc._case_type.categoryTypes.find(e => { return e.id === uc._case_category_id }) : undefined;
        //     } catch (error) {
        //         console.log("Error Remapping data");
        //         console.error(error);
        //     }
        // });

        return;
    }

    async reMapTeamDataWithValues(
      account: Account[],
      customer: Contact[],
      cases:CaseActivity[],
  ) {
      this.case_accounts = account;
      this.case_contacts = customer;

      cases.forEach(uc => {
          try {
              uc._case_product = undefined;

              this.caseProductSKU.map(e => {
                  if (e.ID === uc._case_product_id) {
                      uc._case_product = e;
                  }
                  else {
                      if (e.skus && Array.isArray(e.skus)) {
                          let innerProduct = e.skus.find(s => s.ID === uc._case_product_id);
                          if (innerProduct) {
                              uc._case_product = innerProduct;
                          }
                      }
                  }
              });

              uc._case_type = this.caseTypeList.find(e => { return e.id === uc._case_type_id });
              uc._case_response_preference = this.responsePreferenceList.find(e => { return e.id === uc._case_response_preference_id });
              uc._case_account = account.find(e => { return e.id === uc._case_account_id });
              //uc._case_product = this.caseProductSKU.find(e => { return e.ID === uc._case_product_id });
              uc._case_contact = customer.find(e => { return e.ID === uc._case_contact_id });
              uc._case_category = uc._case_type ? uc._case_type.categoryTypes.find(e => { return e.id === uc._case_category_id }) : undefined;
          } catch (error) {
              console.log("Error Remapping data");
              console.error(error);
          }
      });

      return;
  }


    async loadMyCaseFromDb(dataRange: { from: string, to: string }) {
        let option = {
            selector: {
                '_id': {
                    $gte: DB_KEY_PREFIXES.MY_CASES,
                    $lte: DB_KEY_PREFIXES.MY_CASES + PREFIX_SEARCH_ENDKEY_UNICODE
                },
            }
        };

        /*
            We don't have end time for the case intake
            purging data may have issue in offline

            Need to find a fix later
        */

        // if (dataRange && dataRange.from) {
        //     option.selector['createdon'] = {
        //         $gte: dataRange.from
        //     };
        // }
        // if (dataRange && dataRange.to) {
        //     option.selector['createdon'] = Object.assign({},
        //         option.selector['createdon'],
        //         { $lte: dataRange.to }
        //     );
        // }

        try {
            // Fetch from DB and do mapping
            const rawCases: any[] = await this.diskService.find(option);

            this.myCases = [];
            if (rawCases && Array.isArray(rawCases)) {
                for (let i = 0; i < rawCases.length; i++) {
                    const raw = rawCases[i];
                    let iCase;
                    try {
                        if (!raw.hasOwnProperty('_deleted') && raw.hasOwnProperty('indskr_externalid')) {
                            iCase = new CaseActivity(raw);
                        }
                    } catch (error) {
                        console.log("error converting to class entity");
                        console.log(raw.incidentid);
                    }

                    if (iCase) {
                        try {
                            await this.upsertMyCases(iCase,false);
                        } catch (error) {
                            console.log("error adding case to list")
                        }
                    }

                }
            }
        } catch (error) {
        }

        // Load offline created cases that are not uploaded yet
        /*
            This is critical as user is not able to load these to dynamics due to some issue
            Needs to be updated somewhere
        */
        const offlineDoc = await this.diskService.loadOfflineCases();

        if (offlineDoc && offlineDoc.hasOwnProperty('myCases') && Array.isArray(offlineDoc['myCases'])) {
            const rawOfflineCreatedTimeOffs = offlineDoc['myCases'].filter(a => (a.incidentid && a.incidentid.includes('offline')) || (a.indskr_local_timeoffrequestid && a.indskr_local_timeoffrequestid.includes('offline')));
            for (let i = 0; i < rawOfflineCreatedTimeOffs.length; i++) {
                let rawOfflineCreatedTimeOff = rawOfflineCreatedTimeOffs[i];
                const iCase = new CaseActivity(rawOfflineCreatedTimeOff);
                // Set ownerId
                iCase.ownerId = this.authenticationService.user.systemUserID;
                this.upsertMyCases(iCase);
            }
        }
    }

    // async loadTeamCaseFromDb(dataRange: { from: string, to: string }) {
    //     let option = {
    //         selector: {
    //             '_id': {
    //                 $gte: DB_KEY_PREFIXES.TEAM_CASES,
    //                 $lte: DB_KEY_PREFIXES.TEAM_CASES + PREFIX_SEARCH_ENDKEY_UNICODE
    //             },
    //         }
    //     };
    //     // if (dataRange && dataRange.from) {
    //     //     option.selector['createdon'] = {
    //     //         $gte: dataRange.from
    //     //     };
    //     // }
    //     // if (dataRange && dataRange.to) {
    //     //     option.selector['createdon'] = Object.assign({},
    //     //         option.selector['createdon'],
    //     //         { $lte: dataRange.to }
    //     //     );
    //     // }

    //     try {
    //         // Fetch from DB and do mapping
    //         const rawTeamCases: any[] = await this.diskService.find(option);

    //         this.teamCases = [];

    //         if (rawTeamCases && Array.isArray(rawTeamCases)) {
    //             for (let i = 0; i < rawTeamCases.length; i++) {
    //                 const raw = rawTeamCases[i];
    //                 const iCase = new CaseActivity(raw);
    //                 this.upsertTeamCases(iCase);
    //             }
    //         }
    //     } catch (error) {
    //     }
    // }

    async mapDeltaSyncedMyCases(raw, rawDeleted = [], newLastUpdatedTime: number) {

        if (Array.isArray(raw) && raw.length > 0) {
            for (let i = 0; i < raw.length; i++) {
                const rawCase = raw[i];
                rawCase._id = DB_KEY_PREFIXES.MY_CASES + rawCase.incidentid;
                rawCase.lastUpdatedTime = newLastUpdatedTime;
                const doc = await this.diskService.retrieve(rawCase._id, true);
                if (doc) {
                    rawCase['_rev'] = doc['_rev'];
                    let currentCaseStage = rawCase['_indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'];
                    let prevCaseStage = doc['indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'] ? doc['indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'] : doc['statuscode'] == 0 ? 'Open' : '';
                    currentCaseStage = rawCase['statecode'] === 1 ? 'Resolve' : rawCase['statecode'] === 2 ? 'Cancelled' : currentCaseStage;
                    if (prevCaseStage !== currentCaseStage) {
                      let caseTitle = rawCase['description'] ? rawCase['description'] : rawCase['_indskr_expertcategory_value@OData.Community.Display.V1.FormattedValue'];
                      this.inquiryNotificationModel = {
                        type: NOTIFICATION.INQUIRY_STATUS,
                        name: this.translate.instant("INQUIRY_STATUS", {prevStatus: prevCaseStage, currentStatus: currentCaseStage}),
                        DateTime: Date.now(),
                        id: NOTIFICATION.INQUIRY_STATUS + Date.now(),
                        data: rawCase,
                        icon: 'assets/imgs/case-intake.svg',
                        isRed: false,
                        params: {prevStatus: prevCaseStage, currentStatus: currentCaseStage}
                      };
                      this.myAssistantService.saveNotificationToDisk(this.inquiryNotificationModel);
                        // this.myAssistantService.addNewNotification('The status of the Inquiry ' + caseTitle + ' with ' + caseNumber + ' has been updated from ' + prevCaseStage + ' to ' + currentCaseStage);
                    }
                }

                for (let key in raw[i]) {
                    if (key.charAt(0) === "_") {
                        var a = key.substring(1, key.length);
                        raw[i][a] = rawCase[a] = raw[i][key];
                        delete raw[i][key];
                        delete rawCase[key];
                    }
                }

                const icase = new CaseActivity(rawCase);
                await this.diskService.addCaseToCollection(icase);
                this.upsertMyCases(icase,false);
            }
            await this.myAssistantService.loadAndMapNotificationsFromDB();
            try {
              await this.diskService.bulk(raw);
            } catch (error) {
            }
        }

        if (Array.isArray(rawDeleted) && rawDeleted.length > 0) {
            const delIds = rawDeleted.map(raw => DB_KEY_PREFIXES.MY_CASES + raw.indskr_entityid);
            const option = {
                selector: {
                    '_id': {
                        $in: delIds
                    }
                }
            };

            const rawDocs = await this.diskService.find(option);
            if (Array.isArray(rawDocs) && rawDocs.length > 0) {
                for (let i = 0; i < rawDocs.length; i++) {
                    const caseIdx: number = this.myCases.findIndex(t => t.ID === rawDocs[i]['incidentid']);
                    if (caseIdx >= 0) {
                        this.myCases.splice(caseIdx, 1);
                    }
                    rawDocs[i] = { _id: rawDocs[i]._id, _rev: rawDocs[i]._rev, _deleted: true };
                }

                try {
                    // Bulk save docs to DB
                    await this.diskService.bulk(rawDocs);
                } catch (error) {

                }
            }
        }
    }

    // async mapDeltaSyncedTeamCases(raw, newLastUpdatedTime: number) {
    //     if (raw && Array.isArray(raw)) {
    //         for (let i = 0; i < raw.length; i++) {
    //             const rawTeam = raw[i];
    //             rawTeam['_id'] = DB_KEY_PREFIXES.TEAM_CASES + rawTeam.incidentid;
    //             rawTeam['lastUpdatedTime'] = newLastUpdatedTime;
    //             const doc = await this.diskService.retrieve(rawTeam._id, true);
    //             if (doc) {
    //                 rawTeam['_rev'] = doc['_rev'];

    //                 let currentCaseStage = rawTeam['_indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'];
    //                 let prevCaseStage = doc['indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'] ? doc['indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'] : doc['statuscode'] == 0 ? 'Open' : '';
    //                 currentCaseStage = rawTeam['statecode'] === 1 ? 'Resolve' : rawTeam['statecode'] === 2 ? 'Cancelled' : currentCaseStage;
    //                 if (prevCaseStage !== currentCaseStage) {
    //                     let caseTitle = rawTeam['description'] ? rawTeam['description'] : rawTeam['_indskr_expertcategory_value@OData.Community.Display.V1.FormattedValue'];
    //                     let caseNumber = rawTeam['ticketnumber'] ? rawTeam['ticketnumber'] : doc['ticketnumber'];
    //                     this.myAssistantService.addNewNotification('The status of the Inquiry ' + caseTitle + ' with ' + caseNumber + ' has been updated from ' + prevCaseStage + ' to ' + currentCaseStage);
    //                 }
    //             }

    //             for (let key in raw[i]) {
    //                 if (key.charAt(0) === "_") {
    //                     var a = key.substring(1, key.length);
    //                     raw[i][a] = rawTeam[a] = raw[i][key];
    //                     delete raw[i][key];
    //                     delete rawTeam[key];
    //                 }
    //             }

    //             const iCase = new CaseActivity(rawTeam);

    //             const caseIdx = this.teamCases.findIndex(e => e.ID === iCase.ID);
    //             if (caseIdx >= 0) {
    //                 this.teamCases[caseIdx] = iCase;
    //                 this.upsertTeamCases(iCase);
    //             } else {
    //                 this.upsertTeamCases(iCase);
    //             }
    //         }

    //         try {
    //             // Bulk save docs to DB
    //             await this.diskService.bulk(raw);
    //         }
    //         catch (error) {
    //         }
    //     }
    // }

    public async mapFullSyncedMyCases(raw, newLastUpdatedTime: number) {
        return await this._ngZone.runOutsideAngular(async () => {
            this.myCases = [];
            if (raw && Array.isArray(raw)) {
                for (let i = 0; i < raw.length; i++) {
                    const rawCase = raw[i];
                    /* Pouch db doesnt allow to store keyword starting with _ */
                    for (let key in raw[i]) {
                        if (key.charAt(0) === "_") {
                            var a = key.substring(1, key.length);
                            raw[i][a] = rawCase[a] = raw[i][key];
                            delete raw[i][key];
                            delete rawCase[key];
                        }
                    }
                    rawCase._id = DB_KEY_PREFIXES.MY_CASES + rawCase['incidentid'];
                    rawCase.lastUpdatedTime = newLastUpdatedTime;
                    const newCase = new CaseActivity(rawCase);
                    this.upsertMyCases(newCase,false);
                }

                try {
                    await this.diskService.bulk(raw);
                } catch (error) {
                    console.log("error saving bulk raw", error);
                }
            } else {

            }
            return this.myCases;
        });
    }


    // async mapFullSyncedTeamCases(raw, newLastUpdatedTime: number) {
    //     return await this._ngZone.runOutsideAngular(async () => {
    //         this.teamCases = [];
    //         if (raw && Array.isArray(raw)) {
    //             for (let i = 0; i < raw.length; i++) {
    //                 const rawTeam = raw[i];
    //                 /* Pouch db doesnt allow to store keyword starting with _ */
    //                 for (let key in raw[i]) {
    //                     if (key.charAt(0) === "_") {
    //                         var a = key.substring(1, key.length);
    //                         raw[i][a] = rawTeam[a] = raw[i][key];
    //                         delete raw[i][key];
    //                         delete rawTeam[key];
    //                     }
    //                 }
    //                 rawTeam._id = DB_KEY_PREFIXES.TEAM_CASES + rawTeam.incidentid;
    //                 rawTeam.lastUpdatedTime = newLastUpdatedTime;
    //                 const teamC = new CaseActivity(rawTeam);
    //                 this.upsertTeamCases(teamC);
    //             }

    //             try {
    //                 await this.diskService.bulk(raw);

    //             } catch (error) {
    //                 console.log("Error Storing Raw cases", error);
    //             }
    //         } else {

    //         }
    //         return this.teamCases;
    //     });
    // }

    public async upsertMyCases(data: CaseActivity,callFilterActivity = true) {
        let idx: number = this.myCases.findIndex((e: CaseActivity) => data.ID === e.ID);
        if (idx > -1) {
            this.myCases[idx] = data;
        }
        else {
            let offIdx: number = this.myCases.findIndex((e: CaseActivity) => {
                if (e.offline_ID) {
                    return data.offline_ID === e.offline_ID
                }
                else
                    return false;
            });
            if (offIdx > -1) {
                this.myCases[offIdx] = data;
            }
            else {
                this.myCases.push(data);
            }
        }
        if (this.accessedFrom === AccesingMode.CASE_TOOL) this.getMyCaseList();
        await this.updateActivityService(data,callFilterActivity);
    }

    public async updateActivityService(data: CaseActivity,callFilterActivity = true) {

        let actIdx;
        let actCaseIdx;

        if (data.ID && !data.ID.includes('offline')) {
            actIdx = this.activityService.activities.findIndex(activity => activity.ID == data.ID);
            actCaseIdx = this.activityService.customerInquiries.findIndex(activity => activity.ID == data.ID);
        }
        else {
            actIdx = this.activityService.activities.findIndex(activity => activity['offline_ID'] == data.offline_ID);
            actCaseIdx = this.activityService.customerInquiries.findIndex(activity => activity.offline_ID == data.offline_ID);
        }

        if (actIdx > -1) {
            this.activityService.activities[actIdx] = data;
            this.activityService.customerInquiries[actCaseIdx] = data;
        }
        else {
          // In case App kill - ID is not updated, check the duplicated data with offline_ID and update
          let actOfflinIdx = this.activityService.activities.findIndex(a => a.type === ActivityType.CaseIntake && a['offline_ID'] == data.offline_ID);
          let actCaseOfflineIdx = this.activityService.customerInquiries.findIndex(ac => ac.offline_ID == data.offline_ID);
          if(actOfflinIdx > -1) {
            this.activityService.activities[actOfflinIdx] = data;
            this.activityService.customerInquiries[actCaseOfflineIdx] = data;
            await this.diskService.addCaseToCollection(data, true);
          } else {
            await this.activityService.addActivity(data, true,false,null,callFilterActivity);
          }
        }
    }

    public async upsertTeamCases(data: CaseActivity) {
        //checking for GUID
        let idx: number = this.teamCases.findIndex((e: CaseActivity) => data.ID === e.ID);
        //valid index detected then update the value
        if (idx > -1) {
            this.teamCases[idx] = data;
        }
        else {
            //GUID not found in record so we can create new or update the on basis of offline ID
            let offIdx: number = this.teamCases.findIndex((e: CaseActivity) => {
                if (e.offline_ID) {
                    return data.offline_ID === e.offline_ID
                }
                else
                    return false;
            });
            //valid offline ID detected so update the existing record
            if (offIdx > -1) {
                this.teamCases[offIdx] = data;
            }
            //Data is not registered on device so add record
            else {
                this.teamCases.push(data);
            }
        }
        if (this.accessedFrom === AccesingMode.CASE_TOOL) this.getTeamCaseList();
        this.activityService.addActivity(data, true);
    }

    /* Handles the offline case uploaded to dynamics with response */
    async mapOfflineCases(res: any, caseInqiurySyncInfo, offlineCaseDocs, url, casesToUpload) {
        let offlineCustomerInquiries;
        try {
            offlineCustomerInquiries = await this.diskService.retrieve('offlineCustomerInquiries', true);
        } catch (error) {
            console.log("error fetching offline cases")
        }

        if (offlineCustomerInquiries && Array.isArray(offlineCustomerInquiries['myCases'])) {

            let offCases: any[] = offlineCustomerInquiries['myCases'];

            for (const p of res) {
                if (p.hasOwnProperty('error')) {
                    if (p.error.hasOwnProperty('errorMessage')) {
                        let ix = offCases.findIndex(e => { return e.offlineCaseId === p.offlineCaseId });
                        let errorType: string = '';
                        //Remove from offline payload
                        if (ix > -1) {
                            offCases.splice(ix, 1);
                        }

                        /* Search on mycase list */
                        let currentCase = this.myCases.find(m => { return m.offline_ID === p.offlineCaseId });

                        let msg = String(p.error.errorMessage).toLowerCase();

                        if (msg.includes('indskr_expertcategorytype With Id')) {
                            errorType += ' category ';
                        }
                        if (msg.includes('contact')) {
                            errorType += ' ' + this.utilityService.globalCustomerText + ' ';
                        }
                        if (msg.includes('account')) {
                            errorType += ' account ';
                        }
                        if (msg.includes('response preference')) {
                            errorType += ' response preference ';
                        }
                        if (msg.includes('therapeutic')) {
                            errorType += ' therapeutic area ';
                        }
                        if (msg.includes('product')) {
                            errorType += ' product ';
                        }

                        /* if found then convert it back to status open for rediting and
                            update pouch with the new raw */
                        if (currentCase) {
                            currentCase._case_status_value = 'Open';
                            currentCase._case_stage_value = 'Open';
                            currentCase._case_status_id = 1;

                            if (msg.includes('indskr_expertcategorytype With Id')) {
                                currentCase._case_category = undefined;
                                currentCase._case_category_id = undefined;
                            }
                            if (msg.includes('contact')) {
                                currentCase._case_contact = undefined;
                                currentCase._case_contact_id = undefined;
                                currentCase._selected_address = undefined;
                                currentCase._selected_address_id = undefined;
                                currentCase._selected_email = undefined;
                                currentCase._selected_email_id = undefined;
                                currentCase._selected_phone = undefined;
                                currentCase._case_trans_customer_name = this.utilityService.globalCustomerText;
                            }
                            if (msg.includes('account')) {
                                currentCase._case_account = undefined;
                                currentCase._case_account_id = undefined;
                                currentCase._case_trans_account_name = undefined;
                            }
                            if (msg.includes('response preference')) {
                                currentCase._case_response_preference = undefined;
                                currentCase._case_response_preference_id = undefined;
                                currentCase._case_trans_response_name = undefined;
                            }
                            if (msg.includes('therapeutic')) {
                                currentCase._case_therapeutic_area = undefined;
                                currentCase._case_therapeutic_id = undefined;
                                currentCase._case_trans_therapeutic_name = undefined;
                            }
                            if (msg.includes('product')) {
                                currentCase._case_product = undefined;
                                currentCase._case_product_id = undefined;
                                currentCase._case_trans_product_name = undefined;
                            }

                            if (!currentCase.ID) currentCase.ID = currentCase.offline_ID;

                            await this.upsertMyCases(currentCase);
                            /*
                                Error generated so have to use the offline ID.
                            */
                            await this.diskService.addCaseToCollection(currentCase, true);
                        }

                        // this.myAssistantService.addNewNotification(this.translate.instant('THERE_WAS_A_PROBLEM_INQUIRY', { globalCustomerText: this.utilityService.globalCustomerText, errorType: errorType }));
                    }
                }
                else {
                    let ix = offCases.findIndex(e => { return e.offlineCaseId === p.offlineCaseId });

                    if (ix > -1) {
                        offCases.splice(ix, 1);
                    }

                    let currentCase = this.myCases.find(m => { return m.offline_ID === p.offlineCaseId });

                    if (currentCase) {
                        /* removing entries from activity service */
                        try {
                            let idx = this.activityService.customerInquiries.findIndex(e => e.ID === currentCase.offline_ID);
                            let activityIndex = this.activityService.activities.findIndex(a => a.ID == currentCase.offline_ID);

                            if (idx > -1) {
                                this.activityService.customerInquiries.splice(idx, 1);
                            }

                            if (activityIndex > -1) {
                                this.activityService.activities.splice(activityIndex, 1);
                            }

                            //this.activityService.filterActivities(this.activityService.activityFilter);

                            await this.scrapCustomerInquiry(currentCase, true, true);
                        }
                        catch (error) {
                            console.log(error);
                        }

                        /* Remove old entity from pouch*/
                        try {
                            await this.diskService.remove(currentCase._id);
                        } catch (error) {
                            console.log('error deleting old case entity');
                        }


                        /* update the new value and restore the data to pouch */
                        currentCase.ID = p.caseId;
                        currentCase._case_number = p.caseNumber;
                        currentCase._case_status_value = p.status;
                        currentCase._case_stage_value = p.caseStage;
                        currentCase._indskr_intakecreated = (p['indskr_intakecreated'] && !isNaN(new Date(Number(p['indskr_intakecreated'])).getTime()) ) ? new Date(Number(p['indskr_intakecreated'])) : undefined;
                        currentCase._id = DB_KEY_PREFIXES.MY_CASES + currentCase.ID;
                        currentCase._case_timeline = p['inquiryTimeline'] ? currentCase.getTimeline(p['inquiryTimeline']) : [];
                        currentCase._case_overriddencreatedon = (p['overriddencreatedon'] && !isNaN(new Date(Number(p['overriddencreatedon'])).getTime()) ) ? new Date(Number(p['overriddencreatedon'])) : undefined;
                        this.upsertMyCases(currentCase);
                        const agendaActivity = this.activityService.selectedActivity;
                        if (agendaActivity && agendaActivity instanceof AppointmentActivity &&
                          (agendaActivity.offlineMeetingId == currentCase._online_meetingID || agendaActivity.ID == currentCase._online_meetingID)) {
                          this.events.publish('updateEmbeddedActivity', currentCase);
                        }
                        //Selected activity in agenda should be refreshed
                        if (this.currentCase?.offline_ID === currentCase.offline_ID) {
                          this.assignSelectedCase(currentCase);
                          if (this.activityService.selectedActivity instanceof CaseActivity && this.activityService.selectedActivity.offline_ID === currentCase.offline_ID) {
                            this.activityService.selectedActivity = currentCase;
                          }
                        }
                        try {
                            await this.diskService.addCaseToCollection(currentCase);
                        } catch (error) {
                            console.log(error);
                        }
                        // updating account and contact for interaction
                        let dbAccounts = [];

                        let rawAccountDoc: any = await this.diskService.retrieve(DB_KEY_PREFIXES.ACCOUNT, true);
                        if (rawAccountDoc && rawAccountDoc.raw && Array.isArray(rawAccountDoc.raw)) {
                          dbAccounts = rawAccountDoc.raw;
                        }
                        dbAccounts = this.utilityService.updateInteractionAccount(currentCase._case_account, 'Inquiries', dbAccounts);

                        if (!_.isEmpty(dbAccounts)) {
                          await this.diskService.saveAccountsToDB(dbAccounts);
                        }                       
                      this.updateInteractionsForContacts(currentCase)  
                    }
                    else {
                        console.log(this.myCases);
                        console.log('current case not detected so delete the same from disk service as we will be getting the records from delta sync');
                        /* Remove old entity from pouch*/
                        try {
                            let id = DB_KEY_PREFIXES.MY_CASES + p.offlineCaseId;
                            await this.diskService.remove(id);
                        }
                        catch (error) {
                            console.log('error deleting old case entity');
                        }
                    }
                }
            }

            await this.deltaService.addEntitySyncInfo(caseInqiurySyncInfo);
            try {
                await this.diskService.updateOrInsert('offlineCustomerInquiries', doc => offlineCustomerInquiries);
                if (!this.uiService.toolsActivityActive){
                  this.events.publish('refreshAgenda');
                } else this.uiService.agendaRefreshRequired = true;
                // Track offline data count
                this.diskService.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CUSTOMER_INQUIRY, offlineCustomerInquiries.myCases.length);
            } catch (error) {
                console.log("mapOfflineCases: error storing issue", error);
            }
        }

    }


    private async updateInteractionsForContacts(data: CaseActivity) {
      const bucketID = getBucketId(data._case_contact.ID, this.authenticationService.get_num_buckets());
  
      let dbContacts = [];
  
      const dbKey = Utility.getDBBucketedKey(DB_KEY_PREFIXES.CONTACT, bucketID)
  
      let rawContactsDoc: any = await this.diskService.retrieve(dbKey);
  
      if (rawContactsDoc && rawContactsDoc.value && Array.isArray(rawContactsDoc.value)) {
        
        dbContacts = rawContactsDoc.value;
      }
  
      dbContacts = this.utilityService.updateInteractionContact(data._case_contact, 'Inquiries', dbContacts);
  
      if (!_.isEmpty(dbContacts)) {
        this.diskService.updateOrInsert(dbKey, doc => ({ raw: dbContacts }));
      }
  
      return dbContacts;
    }
  

    async mapSkuProduct(res: any[]) {
      if (Array.isArray(res)) {
        this.caseProductSKU = res.map(e => new Brand(e));
      }
    }

    public get validateCaseForReview(): boolean {
        let isNoProductSelection : boolean = false;
        let isHideCustomerField : boolean = false;
        let isAccountFieldMandatory : boolean = false;
        if(this.currentCase._case_category){
          //hide product selection if the flag is set in dynamics
          isNoProductSelection = this.currentCase._case_category["indskr_productselection"] === 548910000 ? true : false; 
          //make customer field hidden if the flag is set in dynamics(the field behaviour was altered and label renamed to Hide Customer on Dynamics)
          isHideCustomerField = this.currentCase._case_category["indskr_customermandatory"] === true ? true : false;
          isAccountFieldMandatory = this.currentCase._case_category["indskr_makeaccountmandatory"] === true ? true : false;  
        }
        if(this.currentCase._case_type.surveyTemplate) {
          let isAllDefaultFieldsVaid = this.currentCase._case_response_preference && this.currentCase._case_contact ? true : false;
          return this.isSurveyValid && isAllDefaultFieldsVaid;
        } else {
          return this.currentCase &&
          (this.currentCase._case_product || isNoProductSelection) &&
          (this.currentCase._case_contact || isHideCustomerField) &&
          (this.currentCase._case_account || !isAccountFieldMandatory) &&
          this.currentCase._case_response_preference &&
          this.currentCase._case_type &&
          this.currentCase._case_category &&
          (this.currentCase._case_notes && this.currentCase._case_notes.length >0);
        }
    }

    findCasesById(id: any): CaseActivity[] {
        return this.myCases.filter(e => e._online_meetingID === id);
    }

    async scrapCustomerInquiry(data: CaseActivity, ignoreOthers?: boolean, deltaUpdate?: boolean, fromParentActivity: boolean = false) {

        if (!ignoreOthers) {
            this.activityService.removeActivity(data);
        }

        let i = this.myCases.findIndex(e => e.ID === data.ID);
        this.myCases.splice(i, 1);
        //in case offline syn , no need to clear selected case
        if (!ignoreOthers && !deltaUpdate) {
          this.assignSelectedCase(undefined);
          this.surveyService.assignLookupResult(undefined);
          this.surveyService.surveyLookupResults = [];
        }


        /*
            data._id doesn't exist
        */
        if (!data._id) {
            data._id = DB_KEY_PREFIXES.MY_CASES + data.offline_ID;
        }

        try {
            await this.diskService.remove(data._id);
        } catch (error) {
            console.log(error);
        }

        if (this.accessedFrom === AccesingMode.AGENDA && !ignoreOthers && !deltaUpdate) {
            this.activityService.selectedActivity = undefined;
        } else if (this.accessedFrom === AccesingMode.CASE_TOOL) {
            this.getMyCaseList();
        }

        if (!this.uiService.toolsActivityActive)
        {
          this.events.publish("refreshAgenda");
        }
        else {
          this.uiService.agendaRefreshRequired = true;
        }

        if (this.uiService.activitiesPageTab === 'day') {
            this.events.publish('activityDeleted', data);
        }

        this.activityService.publishActivityEvent({action: "Delete", activity: data});

        this.events.publish("customerInquiryActivityDeleted", data, fromParentActivity);

        // this.events.publish('weekview:RefreshUI');
        if (String(data._case_status_value).toLocaleLowerCase().includes('pending sync')) {
            await this.removeOfflinePayload(data);
        }
        if (!deltaUpdate && (data._offline_meetingID || data._online_meetingID)) {
            this.events.publish('deletedEmbeddedActivity', data);
        }
        if(this.navService.getCurrentMasterPageName() == PageName.CallPlanComponent) {
          this.callPlanService.callPlanPageView = 'CallPlanPhoneCallDetails';
        }
        return data;
    }

    async reInitDataDependency(uc: CaseActivity) {
        try {
            uc._case_type = this.caseTypeList.find(e => { return e.id === uc._case_type_id });
            uc._case_response_preference = this.responsePreferenceList.find(e => { return e.id === uc._case_response_preference_id });
            uc._case_account = this.case_accounts.find(e => { return e.id === uc._case_account_id });
            uc._case_product = this.caseProductSKU.find(e => { return e.ID === uc._case_product_id });
            uc._case_contact = this.case_contacts.find(e => { return e.ID === uc._case_contact_id });
            uc._case_category = uc._case_type ? uc._case_type.categoryTypes.find(e => { return e.id === uc._case_category_id }) : undefined;
        } catch (error) {
            console.log("Error Remapping data");
            console.error(error);
        }
        return uc;
    }

    async removeOfflinePayload(c: CaseActivity) {
        return await this.diskService.removeCaseFromOfflinePayload(c);
    }

    async purgeData(maxEndDateUnixTimestamp: number) {
        if (!this.authenticationService.hasFeatureAction(FeatureActionsMap.CASE_INTAKE)) {
            return;
        }

        let option = {
            selector: {
                '_id': {
                    $gte: DB_KEY_PREFIXES.MY_CASES,
                    $lte: DB_KEY_PREFIXES.MY_CASES + PREFIX_SEARCH_ENDKEY_UNICODE
                },
                'caseResolutionDate': {
                    $lt: maxEndDateUnixTimestamp.toString()
                }
            }
        };

        let teamOption = {
            selector: {
                '_id': {
                    $gte: DB_KEY_PREFIXES.TEAM_CASES,
                    $lte: DB_KEY_PREFIXES.TEAM_CASES + PREFIX_SEARCH_ENDKEY_UNICODE
                },
                'caseResolutionDate': {
                    $lt: maxEndDateUnixTimestamp.toString()
                }
            }
        };

        let deletedCustomerInquiries;
        let deletedTeamInquiries;

        try {
            // Fetch from DB and delete
            const rawCustomerInquiries: any[] = await this.diskService.find(option);

            if (rawCustomerInquiries && Array.isArray(rawCustomerInquiries)) {
                //let wasThereTeamTotChange = false;
                deletedCustomerInquiries = rawCustomerInquiries.map(raw => {
                    //const isTeam = this.authenticationService.user.systemUserID !== raw['ownerid_value'];
                    const idx = this.myCases.findIndex(c => c.ID === raw['incidentid']);
                    if (idx >= 0) {
                        this.myCases.splice(idx, 1);
                        const iCase = { ID: raw['incidentid'], type: ActivityType.CaseIntake };
                        this.activityService.removeActivity(iCase as CaseActivity);
                    }
                    return { _id: raw._id, _rev: raw._rev, _deleted: true };
                });
            }
        } catch (error) {
            console.error('purgeData [myCases]:', error);
        }

        // try {
        //     // Fetch from DB and delete
        //     const rawTeamInquiries: any[] = await this.diskService.find(teamOption);

        //     if (rawTeamInquiries && Array.isArray(rawTeamInquiries)) {
        //         //let wasThereTeamTotChange = false;
        //         deletedTeamInquiries = rawTeamInquiries.map(raw => {
        //             const idx = this.teamCases.findIndex(c => c.ID === raw['incidentid']);
        //             if (idx >= 0) {
        //                 this.teamCases.splice(idx, 1);
        //                 const iCase = { ID: raw['incidentid'], type: ActivityType.CaseIntake };
        //                 this.activityService.removeActivity(iCase as CaseActivity);
        //             }
        //             return { _id: raw._id, _rev: raw._rev, _deleted: true };
        //         });
        //     }
        // } catch (error) {
        //     console.error('purgeData [teamCases]:', error);
        // }

        try {
            await this.diskService.bulk(deletedCustomerInquiries);
            //await this.diskService.bulk(deletedTeamInquiries);
        } catch (error) {
            console.error('purgeData [cases]: ', error);
        }


    }

    async updateCaseInPouch(currentCase: CaseActivity) {
        try {
            await this.diskService.addCaseToCollection(currentCase);
        } catch (error) {
            console.log(error);
        }
    }

    async updateTeamCaseInPouch(currentCase: CaseActivity) {
      try {
        await this.diskService.updateTeamCaseToCollection(currentCase);
      } catch (error) {
        console.log(error);
      }
    }

    showGeneeNotification(current: any, prev: any) {
        let currentCaseStage = current['_indskr_omnipresencestage_value@OData.Community.Display.V1.FormattedValue'];
        let prevCaseStage = prev._case_status_value ? prev._case_status_value : prev._case_status_id == 0 ? 'Open' : '';

        if (prevCaseStage !== currentCaseStage) {
            let caseTitle = current['description'] ? current['description'] : current['_indskr_expertcategory_value@OData.Community.Display.V1.FormattedValue'];
            let caseNumber = current['ticketnumber'] ? current['ticketnumber'] : prev._case_number;
            // this.myAssistantService.addNewNotification('The status of the Inquiry ' + caseTitle + ' with ' + caseNumber + ' has been updated from ' + prevCaseStage + ' to ' + currentCaseStage);
        }
    }

    getCurrentCaseCategoryTypes() {
        return this.currentCase ? this.caseTypeList.find(e => { return e.id === this.currentCase._case_type_id }).categoryTypes : undefined;
    }
  
    getCategoryUsers(caseTypeId: string) {
      return this.caseTypeList.find(e => { return e.id === caseTypeId })?.users ?? [];
    }
    getCategoryTeams(caseTypeId: string) {
      return this.caseTypeList.find(e => { return e.id === caseTypeId })?.teams ?? [];
    }

    get casesLength(): number {
        const currentCases: CaseActivity[] = this.casesSource$.getValue();
        return currentCases && currentCases.length > 0 ? currentCases.length : 0;
    }

    set casesSource(cases: CaseActivity[]) {
        this.casesSource$.next(cases);
    }

    public translateCaseStatus(status):string {
      let translatedString = '';
      switch(status) {
        case 'Inquiry Created':
          translatedString = this.translate.instant('INQUIRY_CREATED');
          break;
        case 'Inquiry Stage - Intake':
          translatedString = this.translate.instant('INQUIRY_STAGE') + ' - ' + this.translate.instant('INTAKE');
          break;
        default:
          translatedString = status;
      }

      return translatedString
    }
}


export enum DETAILS_OPTION {
    ACCOUNT_SELECTION,
    CONTACT_SELECTION,
    PRODUCT_SELECTION,
    RESET_SELECTION
}


export enum AccesingMode {
    'AGENDA' = 1,
    'CALENDAR',
    'CONTACT',
    'CASE_TOOL',
    'PRESENTATION',
    'LIVE_MEET',
    'INMEETING',
    'CONTACT_TIMELINE',
    'ACCOUNT_TIMELINE',
    'XPERIENCE_INSIGHTS',
    'MEETING_DETAIL',
    'IN_PHONE_CALL',
    'EVENT_TOOL',
    'IN_SET_BOOKING'
}
