import { FeatureActionsMap } from './../../../classes/authentication/user.class';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonNav, ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { format, getDate, getDay, isBefore, isFuture, isPast, isValid } from 'date-fns';
import * as moment from 'moment';
import { FollowUpActivity, FollowupActionType, FOLLOW_UP_PRIORITY, FOLLOWUP_ACTION_TYPE_STATUS, AssignmentHistory, FollowupActionStatusReason } from '../../../classes/activity/follow-up-action.activity.class';
import { IONote } from '../../../classes/io/io-note.class';
import { ActivityDataService } from '../../../data-services/activity/activity.service';
import { FollowUpActivityDataService, OperationDetail } from '../../../data-services/follow-up-activity/follow-up-activity.data.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { FormFieldType, IndFormFieldViewDataModel } from '../../../models/indFormFieldDataModel';
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { AccountManagementOfflineService } from '../../../services/account-management/account-management.service';
import { AccountOfflineService, AccountSelectedFor } from '../../../services/account/account.offline.service';
import { ActivityService } from '../../../services/activity/activity.service';
import { AlertService } from '../../../services/alert/alert.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { ContactOfflineService } from '../../../services/contact/contact.service';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { DeviceService } from '../../../services/device/device.service';
import { EventName, EventsService } from '../../../services/events/events.service';
import { FooterService, FooterViews } from '../../../services/footer/footer.service';
import { GlobalUtilityService } from '../../../services/global-utility.service';
import { TrackingEventNames, TrackService } from '../../../services/logging/tracking.service';
import { ChildNavNames, NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { OpportunityManagementService } from '../../../services/opportunity-management/opportunity-management.service';
import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { AccountPageComponent } from '../../account/account-page/account-page';
import { ChildUsersPageComponent } from '../../child-users-page/child-users-page';
import { ContactPageComponent } from '../../contact/contact-page/contact-page';
import { PopoverComponent } from '../../popover/popover';
import { NothingSelectedView } from '../../shared/nothing-selected-view/nothing-selected-view';
import * as _ from 'lodash';
import { IndDropdownListDetailModel } from '../../../models/indDropdownListModel';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { FOLLOW_UP_TYPE } from './../../../classes/activity/follow-up-action.activity.class';
import { FollowupService } from '../../../services/followup/followup.service';
import { takeUntil, timeout } from 'rxjs/operators';
import { Products } from '../../../classes/presentation/presentation.class';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '@omni/models/mainToolTemplateDetail.model';
import { MainToolTemplateComponent } from '@omni/components/shared/main-tool-template/main-tool-template';
import { Brand } from '@omni/classes/brand/brand.class';
import { BrandOfflineService } from '../../../services/brand/brand.service';
import { MainCardViewDataModel } from '@omni/models/MainCardViewDataModel';
import { Subject, Subscription } from 'rxjs';
import { MAXIMUM_NOTE_ATTACHMENT_SIZE, shortMonthArray, NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX } from '@omni/utility/util';
import { toBase64 } from '@omni/utility/util';
import { getPriority } from 'os';
import { DatePipe } from '@angular/common';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { MarketingPlansManagementOfflineService } from '@omni/services/marketing-management/marketing-management.service';
import { SelectListComponent } from '@omni/components/shared/select-list/select-list';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { EventsToolDataService } from '@omni/data-services/event/events-tool.data.service';
import { EventActivity } from '@omni/classes/events-tool/event.class';
import { EventDetailsComponent } from '@omni/components/events-tool/event-details/event-details';
import { MeetingStructureService } from '@omni/services/meeting-structure/meeting-structure.service';
import { ActivityType } from '@omni/classes/activity/activity.class';


@Component({
  selector: 'follow-up-action-detail',
  templateUrl: 'follow-up-action-detail.html',
  styleUrls: ['follow-up-action-detail.scss']
})
export class FollowUpActionDetailComponent implements OnInit {

  private isLayoverPushView = false;
  private layoverViewPushedFrom: string;
  public isSaveNotesEnabled = false;
  public currentFollowUpActivity: FollowUpActivity;
  public currentFollowUpActivityDueDateString: string;
  // public currentFollowUpActivityDueDate: string;
  public todayDate: string = moment(new Date()).format();
  public maxDate = new Date().getFullYear() - 0 + 3;
  public isReadOnlyView = false;
  private accountPlanPopover;
  private updateInProgress = false;
  public tempNoteText: string;
  public isScrapEnabled;
  public notesPlaceholder: string = '';
  public globalCustomerText: string;
  public taskDetailsHeaderModel: IndSectionHeaderViewDataModel;
  public notesHeaderModel: IndSectionHeaderViewDataModel;
  public timeLineHeaderModel: IndSectionHeaderViewDataModel;
  public pageTitle: IndPageTitleViewDataModel;
  private popover: HTMLIonPopoverElement;
  private followupActionTypes: FollowupActionType[];
  private followupActionOpenStatusReasons: FollowupActionStatusReason[] = [];
  ngDestroy$: any = new Subject<boolean>();
  public objectiveSectionHeader: IndSectionHeaderViewDataModel;

  public attachmentTitle: string = '';
  public isNotesAttachmentEnabled: boolean = false;
  public isAttachmentAdded: boolean = false;
  private attachmentFile: any;
  private base64str;
  public shortMonthArray = shortMonthArray;
  @ViewChild('attachInput') attachInput: ElementRef;
  @ViewChild('followUpDueDate', { static: false }) followUpDatePicker;
  isTaskWithinOpportunityContext = false;
  @Input() from: any;
  @Input() activity: FollowUpActivity;
  dateFormatSubscription: Subscription
  public timelineData = [];
  public backgroundUploadInProgress: boolean = false;

  public backButton = {
    id: 'close',
    icon: 'chevron-back-outline',
    isDisabled: false,
    align: 'left'
  };

  public pageTitleControls = [];
  private _isClickedAccount: boolean = false;
  private _isClickedActionType: boolean = false;
  private followupSubStatusString: string = '';
  private InMeetingPresentationFlow:boolean = false;

  isCancelEnabled = false;
  isFollowUpObjectiveSelectionFAEnabled = false;
  isFollowUpNotesFAEnabled =false;

  isEventFollowUp = false;

  constructor(
    public device: DeviceService,
    public repService: RepServices,
    public readonly UIService: UIService,
    private readonly accountManagementService: AccountManagementOfflineService,
    public activityService: ActivityService,
    private readonly activityDataService: ActivityDataService,
    private readonly navService: NavigationService,
    private readonly contactService: ContactOfflineService,
    private readonly accountService: AccountOfflineService,
    private readonly followUpActivityDataService: FollowUpActivityDataService,
    private readonly popoverCtrl: PopoverController,
    private readonly notificationService: NotificationService,
    public readonly footerService: FooterService,
    private readonly authService: AuthenticationService,
    private readonly events: EventsService,
    private readonly alertService: AlertService,
    public trackingService: TrackService,
    public readonly translate: TranslateService,
    public utilityService: GlobalUtilityService,
    private readonly opportunityService: OpportunityManagementService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private followupService: FollowupService,
    private brandService: BrandOfflineService,
    private cdRef: ChangeDetectorRef,
    private datePipe: DatePipe,
    public navCtrl: IonNav,
    public modalCtrl: ModalController,
    private readonly marketingPlansOfflineSerivce: MarketingPlansManagementOfflineService,
    private readonly eventsToolService: EventsToolService,
    private readonly eventsToolDataService: EventsToolDataService,
    private readonly meetingStructureService: MeetingStructureService,
  ) {
    let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
    this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
    this.events.subscribe("updateFollowupActivityHeader", () => {
      this.updateHeader();
      this.initTaskNotesheader();
      this._updateMandatoryField();
    })
    this.events.observe('followupTask:updateFollowupActivity').pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => {
        this.cdRef.detectChanges();
        this.timelineData = this.getGroupedActivities();
        this.initPageHeaderControls()
        if(!this.InMeetingPresentationFlow){
          this.footerService.initButtons(FooterViews.FollowUpDetails);
        }
        // this.footerService.updateButtons(['scrapfollowup', 'markfollowupcomplete'], false);
      });
    this.events.observe(EventName.INMEETINGFOLLOWUPACTIONSCRAP).pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => {
        this.scrapFollowup();
      });
      this.events.observe(EventName.BACK_TO_FOLLOW_UP_DETAIL_FROM_PUSHED_PAGE).pipe(
        takeUntil(this.ngDestroy$))
        .subscribe((from?) => {
          this.footerService.initButtons(FooterViews.FollowUpDetails);
          this.updateFooterButtonsStatus();
        })
  }

  ngOnInit() {
    if (this.from) {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
      switch (this.layoverViewPushedFrom) {
        case 'InMeetingFollowup':
          this.followUpActivityDataService.isInMeetingFollowupFlow = true;
          break;
        case 'PresentationMeetingComponent':
          this.InMeetingPresentationFlow = true;
          break;
        case 'OpportunityManagementTool':
        case 'AccountProfileOpportunities':
        case 'AccountPlanOpportunities':
        case 'MarketingPlanInfoopportunities':
          this.isTaskWithinOpportunityContext = true;
          break;
        case 'EventDetailsPane':
          break;
        default:
          break;
      }
    }
    if (this.activity) {
      this.currentFollowUpActivity = this.activity;
    } else {
      this.currentFollowUpActivity = <FollowUpActivity>(this.activityService.selectedActivity);
    }

    if (this.currentFollowUpActivity) {

      if (this.followupService.followupActionTypes && !_.isEmpty(this.followupService.followupActionTypes)) {
        this.followupActionTypes = this.followupService.followupActionTypes.filter((followup) => {
          return `${followup.planTypeID}` === this.currentFollowUpActivity.planType
        });
      }
      if (this.followupService.followupActionStatusReasons && this.followupService.followupActionStatusReasons.length > 0) {
        this.setSubStatusString();
        this.followupActionOpenStatusReasons = this.followupService.followupActionStatusReasons.filter((reason) => {
          return reason.stateCode == 0;
        });
      }

      this.isNotesAttachmentEnabled = this.authService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ATTACHMENT_UPLOAD);

      this.intializeAndFormatDueDate();

      this.timelineData = this.getGroupedActivities();

      this.isEventFollowUp = this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.EVENT_TASK;
    }

    if(!this.InMeetingPresentationFlow){
      this.footerService.initButtons(FooterViews.FollowUpDetails);
    }
    

    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        this.globalCustomerText = this.translate.instant('CUSTOMER');
        break;
      case 'Stakeholder':
        this.globalCustomerText = this.translate.instant('STAKEHOLDER');
        break;
      default:
        // this.globalCustomerText = this.utilityService.globalCustomerText;
        this.globalCustomerText = this.translate.instant('FOLLOW_UP_STAKEHOLDER');
        break;
    }
    this.updateButtonStatus();
    this.isFollowUpObjectiveSelectionFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.FOLLOW_UP_ACTION_OBJECTIVE_SELECTION);
    this.isFollowUpNotesFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.FOLLOWUP_ACTION_NOTES);
    this.getStatusString();
    this.initPageHeaderControls();
    this.initSectionHeaders();
    this.initTaskDetailPageTitle();
    this.initObjectivesSectionHeader();
    this.events.unsubscribe("followupTask:assignmentHistoryUpdated");
    this.events.observe("followupTask:assignmentHistoryUpdated").pipe(
      takeUntil(this.ngDestroy$))
      .subscribe(() => {
        this.timelineData = this.getGroupedActivities();
      })

    this.translate.onLangChange.subscribe(data => {
      this.initPageHeaderControls();
      this.initSectionHeaders();
      this.intializeAndFormatDueDate();
      let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
      this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
    });
    this.dateFormatSubscription = this.dateTimeFormatsService.dateFormat.subscribe(df => {
      if (df) {
        this.intializeAndFormatDueDate();
      }
    });
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      if (this.currentFollowUpActivity) {
        this.backgroundUploadInProgress = inProgress && this.currentFollowUpActivity.pendingPushToDynamics;
        this.initPageHeaderControls();
        this.initTaskNotesheader();
        this.updateFooterButtonsStatus();
        this.cdRef.detectChanges();
      }
    });
    this.events.observe("followup:cancelfollowup").pipe(
      takeUntil(this.ngDestroy$))
      .subscribe((id) => {
        if (this.currentFollowUpActivity.ID == id) {
          this.cancelFollowUp();
        }
      })
  }

  private updateButtonStatus(){
    this.isScrapEnabled = (this.currentFollowUpActivity.ownerId === this.authService.user.systemUserID) && this.currentFollowUpActivity.state === 0 && !this.activityService.teamViewActive && this.from !== PageName.AccountPlanActivities;
    this.isCancelEnabled = ((this.currentFollowUpActivity.ownerId === this.authService.user.systemUserID || this.currentFollowUpActivity.assignedTo.some(user => user.userId === this.authService.user.systemUserID))) && this.currentFollowUpActivity.state === 0
  }

  private initObjectivesSectionHeader() {
    this.objectiveSectionHeader = {
      id: 'objectives',
      title: this.translate.instant('OBJECTIVES'),
      // isRequired: true,
      controls: [
        {
          id: 'add-objectives',
          text: this.translate.instant('ADD'),
          isVisible: this.isFollowUpObjectiveSelectionFAEnabled,
          isDisabled: this.currentFollowUpActivity.state !== 0 || this.activityService.teamViewActive
        }
      ]
    };
  }

  private intializeAndFormatDueDate() {
    this.isReadOnlyView = (this.currentFollowUpActivity.state !== 0) ||
      (this.currentFollowUpActivity.ownerId !== this.authService.user.systemUserID && !this.currentFollowUpActivity.assignedTo?.some(au => au.userId === this.authService.user.systemUserID))
      || this.activityService.teamViewActive || this.from == PageName.AccountPlanActivities;
    if (isValid(this.currentFollowUpActivity.scheduledEnd)) {
      this.currentFollowUpActivityDueDateString = this.getFormattedAndLocalisedDate(this.currentFollowUpActivity.scheduledEnd);
    } else if (isValid(this.currentFollowUpActivity.scheduledStart)) {
      this.currentFollowUpActivityDueDateString = this.getFormattedAndLocalisedDate((new Date(this.currentFollowUpActivity.scheduledStart.setHours(23, 59, 59, 59))));
    } else {
      const currentDueDate = new Date().setHours(0, 0, 0, 0);
      this.currentFollowUpActivity.scheduledEnd = new Date(currentDueDate);
      this.currentFollowUpActivityDueDateString = (this.isReadOnlyView) ? this.translate.instant('NONE') : this.getFormattedAndLocalisedDate(this.currentFollowUpActivity.scheduledEnd);
    }
  }

  private getFormattedAndLocalisedDate(value: any) {
    return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  private initPageHeaderControls() {
    let _this = this;
    this.pageTitleControls = [
      {
        id: 'scrap',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('SCRAP'),
        isDisabled: this.backgroundUploadInProgress || !this.isScrapEnabled,
        align: 'right'
      },
      {
        id: 'cancel',
        imgSrc: 'assets/imgs/Lost.svg',
        name: this.translate.instant('CANCEL'),
        isDisabled: this.backgroundUploadInProgress || !this.isCancelEnabled,
        align: 'right'
      },
      {
        id: 'markComplete',
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('COMPLETED'),
        get isDisabled() { return this.backgroundUploadInProgress || !_this.isMarkCompleteEnabled },
        align: 'right'
      }
    ];
    if (this.isBackButtonEnabled) {
      this.pageTitleControls.push(this.backButton);
    }
    this.initTaskDetailPageTitle();
    if(this.InMeetingPresentationFlow){
      this.initTaskDetailsHeader();
    }
  }

  private initTaskDetailPageTitle(): void {
    this.pageTitle = {
      id: 'followup-details',
      title: this.getSubjectFormField().inputText,
      controls: this.pageTitleControls
    };
  }

  private initSectionHeaders() {
    this.initTaskDetailsHeader();
    this.initTaskNotesheader();
    this.initTimelineHeader();
  }

  private initTaskDetailsHeader() {
    let buttons = [];
    let _this = this;
    if (this.InMeetingPresentationFlow) {
      buttons.push(
        {
          id: "scrap",
          text: this.translate.instant('SCRAP'),
          isDisabled: this.backgroundUploadInProgress || !this.isScrapEnabled,
        },
        {
          id: 'cancel',
          text: this.translate.instant('CANCEL'),
          isDisabled: this.backgroundUploadInProgress || !this.isCancelEnabled,
        },
        {
          id: 'markComplete',
          text: this.translate.instant('COMPLETE'),
          get isDisabled() { return this.backgroundUploadInProgress || !_this.isMarkCompleteEnabled },
        });
    }
    this.taskDetailsHeaderModel = {
      id: 'task-detail-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: buttons
    };
  }

  private initTimelineHeader() {
    this.timeLineHeaderModel = {
      id: 'timeline-header',
      title: this.translate.instant('FOLLOW_UP_ACTION_TIMELINE'),
      controls: []
    };
  }

  private initTaskNotesheader() {

    let controls = [
      {
        id: 'attach_note',
        text: this.translate.instant('ATTACH'),
        isDisabled: this.from == PageName.AccountPlanActivities || this.backgroundUploadInProgress || this.currentFollowUpActivity.state !== 0,
        isVisible: this.isNotesAttachmentEnabled,
      }
    ]

    controls.push({
      id: 'task-notes-save',
      text: this.translate.instant('SAVE'),
      isDisabled: this.from == PageName.AccountPlanActivities || (this.backgroundUploadInProgress || !this.isSaveNotesEnabled) || this.currentFollowUpActivity.state !== 0,
      isVisible: true,
    })

    this.notesHeaderModel = {
      id: 'task-notes-header',
      title: this.translate.instant('NOTES'),
      controls: controls
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'scrap':
        this.scrapFollowup();
        break;
      case 'close':
        this.closePage();
        break;
      case 'markComplete':
        this.markFollowUpComplete();
        break;
      case 'cancel':
        this.cancelFollowUp();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  getStatusString() {
    switch (this.currentFollowUpActivity.statusString) {
      case 'Open':
        return this.translate.instant('OPEN');
        break;
      case 'Completed':
        return this.translate.instant('COMPLETED');
        break;
      case 'Canceled':
        return this.translate.instant('CANCELLED');
        break;
      case 'Scheduled':
        return this.translate.instant('SCHEDULED');
        break;
      case 'N/A':
        return this.translate.instant('N_A');
        break;
      default:
        // tslint:disable-next-line: no-self-assignment
        return this.currentFollowUpActivity.statusString;
        break;
    }
  }

  public setSubStatusString() {
    this.followupSubStatusString = '';
    if (this.followupService.followupActionStatusReasons.length > 0) {
      let idx = this.followupService.followupActionStatusReasons.findIndex(x => x.statusCode === this.currentFollowUpActivity.status);
      if (idx >= 0) {
        this.followupSubStatusString = this.followupService.followupActionStatusReasons[idx].name;
      }
    }
  }

  ngDoCheck() {
    if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
      if (!(this.UIService.activeView === 'ActivitiesPageRightPaneNav' && this.navService.getActiveChildNavViewPageName() === PageName.FollowUpActionDetailComponent)) {
        return;
      }
    } else if (this.navService.getActiveChildNavName() === ChildNavNames.ContactPageNavigation) {
      if (this.UIService.activeView !== 'ContactPageRightPaneNav') {
        return;
      }
    }
    if (!this.isReadOnlyView && this.currentFollowUpActivity.state === 1) {
      this.isReadOnlyView = true;
    }
    
    this.updateButtonStatus();
    // this.isScrapEnabled = (this.currentFollowUpActivity.ownerId === this.authService.user.systemUserID) && this.currentFollowUpActivity.state === 0 && !this.activityService.teamViewActive && this.from !== PageName.AccountPlanActivities;
    // this.isCancelEnabled = ((this.currentFollowUpActivity.ownerId === this.authService.user.systemUserID || this.currentFollowUpActivity.assignedTo.some(user => user.userId === this.authService.user.systemUserID))) && this.currentFollowUpActivity.state === 0
    
    if (this.navService.getActiveChildNavViewPageName() === PageName.FollowUpActionDetailComponent && !this.footerService.getActiveButtonsList().some(id => id === 'markfollowupcomplete' || id === 'scrapfollowup')) {
      if(!this.InMeetingPresentationFlow){
        this.footerService.initButtons(FooterViews.FollowUpDetails);
      }
    }
    // this.initPageHeaderControls();
    // this.updateFooterButtonsStatus();
  }

  private updateFooterButtonsStatus() {
    if(this.InMeetingPresentationFlow) return;
    this.footerService.disableButton(['scrapfollowup','markfollowupcancel','markfollowupcomplete']);
    if (this.from == PageName.AccountPlanActivities) return;
    if (this.isScrapEnabled && !this.backgroundUploadInProgress) {
      this.footerService.enableButtons(['scrapfollowup']);
    }
    if (this.isCancelEnabled && !this.backgroundUploadInProgress) {
      this.footerService.enableButtons(['markfollowupcancel']);
    }
    if (!this.backgroundUploadInProgress && ((this.currentFollowUpActivity.ownerId === this.authService.user.systemUserID) || (this.currentFollowUpActivity.assignedTo.some(u => u.userId === this.authService.user.systemUserID)))) {
      if (this.isMarkCompleteEnabled) {
        if (this.currentFollowUpActivity.state === 0) {
          this.footerService.enableButtons(['markfollowupcomplete']);
        };
      }
    } 
  }

  ngAfterViewInit() {
    this.updateHeader();
  }

  public get isBackButtonEnabled(): boolean {
    if (this.isLayoverPushView) {
      if (this.layoverViewPushedFrom === 'AccountManagementTool' || this.layoverViewPushedFrom === 'ContactTool'
        || this.layoverViewPushedFrom === 'AccountTool' || this.layoverViewPushedFrom === 'OpportunityManagementTool'
        || this.layoverViewPushedFrom === 'AccountProfileOpportunities' || this.layoverViewPushedFrom === 'AccountPlanOpportunities' || this.layoverViewPushedFrom === 'InMeetingFollowup'
        || this.layoverViewPushedFrom === 'EventDetailsPane' || this.layoverViewPushedFrom == 'MarketingPlanInfoopportunities') {
        return true;
      } else if (this.layoverViewPushedFrom === 'AgendaView' && (window.innerWidth < 620 || !this.footerService.isActivitiesButton)) {
        return true;
      }
      else {
        return false;
      }
    } else {
      //Handle if used
    }
  }

  public notesChanged(ev): void {
    if (this.backgroundUploadInProgress) return;
    if (ev && ev.target) {
      this.tempNoteText = ev.target.value;
      this.isSaveNotesEnabled = true;

    } else {
      this.tempNoteText = '';
      this.isSaveNotesEnabled = true;

    }
    this.initTaskNotesheader();
  }

  private getIsAccountFieldMandatoryForPlanType(planType: FOLLOW_UP_TYPE) {
    return planType !== FOLLOW_UP_TYPE.NONE
      && planType !== FOLLOW_UP_TYPE.EVENT_TASK;
  }

  public get isMarkCompleteEnabled(): boolean {
    if (this.from == PageName.AccountPlanActivities) return false;

    if (this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.NONE) {
      if (this.currentFollowUpActivity.state === 0 && !this.activityService.teamViewActive) {
        if (this.currentFollowUpActivity.subject && this.currentFollowUpActivity.followupActionTypeID) {
          return true;
        } else {
          return false;
        }
      }
    }
    const isAccountFieldMandatoryForPlanType = this.getIsAccountFieldMandatoryForPlanType(this.currentFollowUpActivity.planType as FOLLOW_UP_TYPE);
    if ((this.currentFollowUpActivity.ownerId === this.authService.user.systemUserID) ||
      (this.currentFollowUpActivity.assignedTo.some(u => u.userId === this.authService.user.systemUserID))) {
      if (
        this.currentFollowUpActivity.subject
        && (
          !isAccountFieldMandatoryForPlanType
          || (
            isAccountFieldMandatoryForPlanType
            && this.currentFollowUpActivity.accountId
          )
        )
        && !this.activityService.teamViewActive
      ) {
        if (this.currentFollowUpActivity.state === 0 && !this.activityService.teamViewActive) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public updateHeader() {
    this.pageTitleControls = [];
    this.initPageHeaderControls();
    this.initTaskDetailPageTitle();
  }

  public isNoteControlsEnabled(note: IONote): boolean {
    return (this.from !== PageName.AccountPlanActivities && note.ownerId == this.authService.user.systemUserID && this.currentFollowUpActivity.state === 0);
  }

  public getAssignedToString(): string {
    let str = (this.isReadOnlyView) ? this.translate.instant('NO_ASSIGNED_USER') : this.translate.instant('AL_SELECT_USER');
    if (!_.isEmpty(this.currentFollowUpActivity.assignedTo)) {
      if (this.currentFollowUpActivity.assignedTo.length === 1) {
        str = this.currentFollowUpActivity.assignedTo[0].userFullName;
      } else {
        str = this.currentFollowUpActivity.assignedTo[0].userFullName;
        str += ' +' + (this.currentFollowUpActivity.assignedTo.length - 1);
      }
    }
    return str;
  }

  public async openUserSelect() {
    if (this.isReadOnlyView && this.currentFollowUpActivity.assignedTo.length === 0) return;
    if ((this.currentFollowUpActivity.state === 1 && this.currentFollowUpActivity.status === 5) || (this.currentFollowUpActivity.state === 2 && this.currentFollowUpActivity.status === 6) || this.currentFollowUpActivity.ownerId !== this.authService.user.systemUserID) {
      //this.navService.pushWithPageTracking(ChildUsersPageComponent, PageName.ChildUsersPageComponent, { from: 'FollowUpTaskDetails', activity: this.currentFollowUpActivity }, PageName.ChildUsersPageComponent);
    } else {
      this._displayDismissLoader(true);
      await this.followUpActivityDataService.getSameLevelAndChildUsersListOnline().catch(err => {
        this.notificationService.notify(this.translate.instant('FOLLOW_UP_NOTIFY_ERROR_WHILE_LOADING'), 'Followup Detail', 'top', 'toast-danger');
        return;
      });
      this._displayDismissLoader(false);
      //this.navService.pushWithPageTracking(ChildUsersPageComponent, PageName.ChildUsersPageComponent, { from: 'FollowUpTaskDetails', activity: this.currentFollowUpActivity }, PageName.ChildUsersPageComponent);
    }
    if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingFollowup' || this.layoverViewPushedFrom === 'EventDetailsPane') {
      if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        setTimeout(()=>{
          document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
        });
      }
      this.navCtrl.push(ChildUsersPageComponent, { from: 'InMeetingFollowUpTaskDetails', activity: this.currentFollowUpActivity });
    }else if(this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'PresentationMeetingComponent'){
      this.navService.pushWithPageTracking(ChildUsersPageComponent, PageName.ChildUsersPageComponent, { from: 'InPresentationFollowUpTaskDetails', activity: this.currentFollowUpActivity }, PageName.ChildUsersPageComponent);
    } else {
      this.navService.pushWithPageTracking(ChildUsersPageComponent, PageName.ChildUsersPageComponent, { from: 'FollowUpTaskDetails', activity: this.currentFollowUpActivity }, PageName.ChildUsersPageComponent);
    }
  }

  private async openSubjectPopover(myEvent) {
    if (!this.isReadOnlyView) {

      if (myEvent.target.value == null || myEvent.target.value === '') {
        myEvent.target.value = 'Follow-up Action'
      }
      if (myEvent && myEvent.target.value && myEvent.target.value !== this.currentFollowUpActivity.subject) {
        this.currentFollowUpActivity.subject = myEvent.target.value;
        this.initTaskDetailPageTitle();
        this.currentFollowUpActivity.pendingPushToDynamics = true;
        await this.followUpActivityDataService.updateFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true }, [this.currentFollowUpActivity], new Date().getTime()).then(success => {
          //Handle successfull response
          if (!this.UIService.toolsActivityActive) {
            this.events.publish("refreshAgenda");
          }
          else {
            this.UIService.agendaRefreshRequired = true;
          }

          this.events.publish('activityUpdated', this.currentFollowUpActivity);
        }).catch(async error => {
          // Handle error occured during follow up activity updation
          if (error && error['errorCode'] && error['errorCode'] === 'ALREADYCOMPLETEDACTIVITY') {
            await this.activityDataService.updateActivityDetails(this.currentFollowUpActivity);
          }
        });
      }
    }
  }

  public async updateActivityDate(value) {
    if (value && value.value && !this.isReadOnlyView) {
      const valueDate = new Date(value.value);
      valueDate.setHours(0, 0, 0, 0);
      if (!(this.currentFollowUpActivity.scheduledEnd && this.currentFollowUpActivity.scheduledEnd.getDate() == valueDate.getDate() && this.currentFollowUpActivity.scheduledEnd.getMonth() == valueDate.getMonth() && this.currentFollowUpActivity.scheduledEnd.getFullYear() == valueDate.getFullYear())) {
        this.currentFollowUpActivity.scheduledStart = valueDate;
        this.currentFollowUpActivity.scheduledEnd = valueDate;
        this.currentFollowUpActivity.scheduledStartLocale = valueDate.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
        this.currentFollowUpActivity.pendingPushToDynamics = true;
        await this.followUpActivityDataService.updateFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true }, [this.currentFollowUpActivity], new Date().getTime()).then(success => {
          //Handle successfull response
          this.currentFollowUpActivityDueDateString = this.getFormattedAndLocalisedDate(this.currentFollowUpActivity.scheduledEnd);

          if (!this.UIService.toolsActivityActive) {
            this.events.publish("refreshAgenda");
          }
          else {
            this.UIService.agendaRefreshRequired = true;
          }
          this.events.publish('activityUpdated', this.currentFollowUpActivity);
        }).catch(async error => {
          // Handle error occured during follow up activity updation
          if (error && error['errorCode'] && error['errorCode'] === 'ALREADYCOMPLETEDACTIVITY') {
            await this.activityDataService.updateActivityDetails(this.currentFollowUpActivity);
          }
        });
      }
    }
  }

  public openCustomerSelect(): void {
    if (this.isReadOnlyView || this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.SUGGESTED) return;
    this.UIService.prevView = this.UIService.activeView;
    this.UIService.activeView = '';
    this.contactService.contactPageMode = ComponentViewMode.SELECTION;
    this.contactService.accessedContactListFrom = PageName.FollowUpActionDetailComponent;
    if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingFollowup' || this.layoverViewPushedFrom === 'EventDetailsPane') {
      if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        setTimeout(()=>{
          document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
        });
      }
      this.navCtrl.push(ContactPageComponent, { type: 'InMeetingFollowupPushedContactSelectionView'}, { progressAnimation: false });
      return;
    }else if(this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'PresentationMeetingComponent'){
      this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, { type: 'InPresentationFollowupPushedContactSelectionView' }, PageName.FollowUpActionDetailComponent, { animate: false }, null);
    }else{
      this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, { type: 'PushedContactSelectionView' }, PageName.FollowUpActionDetailComponent, { animate: false }, null);
    }
  }

  public openAccountSelect(): void {
    if (this.isReadOnlyView || this.isTaskWithinOpportunityContext || this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.SUGGESTED) return;
    this.accountService.accessedAccountListFrom = PageName.FollowUpActionDetailComponent;
    this.accountService.selectedFor = AccountSelectedFor.FOLLOW_UP_ACTION_SELECTION;
    if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingFollowup' || this.layoverViewPushedFrom === 'EventDetailsPane') {
      if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        setTimeout(()=>{
          document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
        });
      }
      this.navCtrl.push(AccountPageComponent, { 'listMode': ComponentViewMode.ADDNEW,type: 'InMeetingFollowupPushedAccountSelectionView'}, { progressAnimation: false });
    }else if(this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'PresentationMeetingComponent'){
      this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { 'listMode': ComponentViewMode.ADDNEW, type: 'InPresentationFollowupPushedAccountSelectionView' }, PageName.FollowUpActionDetailComponent, { animate: false }, null);
    }else{
      this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, { 'listMode': ComponentViewMode.ADDNEW, type: 'PushedAccountSelectionView' }, PageName.FollowUpActionDetailComponent, { animate: false }, null);
    } 
    this._isClickedAccount = true;
  }

  async openAccountPlanSelect(myEvent) {
    if (this.isReadOnlyView) return;
    const accountPlans = this.accountManagementService.accountPlans.filter(plan => {
      plan.accountPlanTimeFrame = isPast(parseInt(plan.endDate)) ? 'Past'
        : isFuture(parseInt(plan.startDate)) ? 'Future' : 'Present';
      return (plan.accountId == this.currentFollowUpActivity.accountId && plan.accountPlanTimeFrame != 'Past' && plan.status === 'Open');
    });

    if (_.isArray(accountPlans) && !_.isEmpty(accountPlans)) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'Opportunity-Accountplan-Select',
        data: accountPlans.map(plan => {
          let obj = {
            title: plan.accountPlanName,
            id: plan.ID,
            isSelected: false,
          };
          if (plan.ID == this.currentFollowUpActivity.accountPlanId) {
            obj['isSelected'] = true;
          }
          return obj;
        }),
      };
      this.accountPlanPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: myEvent });
      this.accountPlanPopover.onDidDismiss().then(async (data) => {
        if (this.backgroundUploadInProgress) return;
        data = data.data;
        if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id !== this.currentFollowUpActivity.accountPlanId) {
          this.currentFollowUpActivity.accountPlanId = data.selectedItems[0].id;
          this.currentFollowUpActivity.accountPlanNameString = data.selectedItems[0].title;
          this.currentFollowUpActivity.pendingPushToDynamics = true;
          this.followUpActivityDataService.updateFollowUpActivity({
            onDynamics: !this.device.isOffline,
            onLocalCopy: true,
            onLocalDatabase: true
          }, [this.currentFollowUpActivity], new Date().getTime()).then(succ => {
            // Successfull action
          }).catch(async error => {
            // Handle error occured during follow up activity updation
            if (error && error['errorCode'] && error['errorCode'] === 'ALREADYCOMPLETEDACTIVITY') {
              await this.activityDataService.updateActivityDetails(this.currentFollowUpActivity);
            }
          });
        }
        this.accountPlanPopover = undefined;
      });
      this.accountPlanPopover.present();
    }

  }

  async openMarketingBusinessPlan(myEvent) {
    if (this.isReadOnlyView) return;
    const marketingPlans = this.marketingPlansOfflineSerivce.marketingPlans.filter(plan => {
      plan.brandPlanTimeFrame = isPast(parseInt(plan.endDate)) ? 'Past'
        : isFuture(parseInt(plan.startDate)) ? 'Future' : 'Present';
      return (plan.accounts.map(acId => acId.id).includes(this.currentFollowUpActivity.accountId) && plan.brandPlanTimeFrame != 'Past');
    });

    if (_.isArray(marketingPlans) && !_.isEmpty(marketingPlans)) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'marketingplan-select',
        data: marketingPlans.map(plan => {
          let obj = {
            title: plan.brandPlanName,
            id: plan.ID,
            isSelected: false,
          };
          if (plan.ID == this.currentFollowUpActivity.marketingPlanId) {
            obj['isSelected'] = true;
          }
          return obj;
        }),
      };
      const marketingPlanPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: myEvent });
      marketingPlanPopover.onDidDismiss().then(async (data:any) => {
        if (this.backgroundUploadInProgress) return;
        data = data.data;
        if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id !== this.currentFollowUpActivity.marketingPlanId) {
          this.currentFollowUpActivity.marketingPlanId = data.selectedItems[0].id;
          this.currentFollowUpActivity.marketingPlanNameString = data.selectedItems[0].title;
          this.currentFollowUpActivity.pendingPushToDynamics = true;
          this.followUpActivityDataService.updateFollowUpActivity({
            onDynamics: !this.device.isOffline,
            onLocalCopy: true,
            onLocalDatabase: true
          }, [this.currentFollowUpActivity], new Date().getTime()).then(succ => {
            // Successfull action
          }).catch(async error => {
            // Handle error occured during follow up activity updation
            if (error && error['errorCode'] && error['errorCode'] === 'ALREADYCOMPLETEDACTIVITY') {
              await this.activityDataService.updateActivityDetails(this.currentFollowUpActivity);
            }
          });
        }
      });
      marketingPlanPopover.present();
    }

  }

  async openOpportunitySelect(myEvent) {

    if (this.isReadOnlyView || this.isTaskWithinOpportunityContext) return;

    const opportunities = this.opportunityService.opportunities.filter(oppo => {
      return (oppo.accountID === this.currentFollowUpActivity.accountId && oppo.stateCode === 0);
    });

    if (_.isArray(opportunities) && !_.isEmpty(opportunities)) {
      const mappedOpportunities = opportunities.map(oppo => {
        return {
          title: oppo.opportunityName,
          id: oppo.ID,
          isSelected: oppo.ID == this.currentFollowUpActivity.opportunityId
        };
      });

      this.accountPlanPopover = await this.popoverCtrl.create({
        component: PopoverComponent,
        componentProps: {
          selectListPopupData: mappedOpportunities,
          field: 'select-list-popup'
        },
        cssClass: 'account-plan-select',
        event: myEvent
      });

      this.accountPlanPopover.onDidDismiss().then((data) => {
        if (this.backgroundUploadInProgress) return;
        data = data.data;
        if (data && data.selectedItem && data.selectedItem.id !== this.currentFollowUpActivity.opportunityId) {
          this.currentFollowUpActivity.opportunityId = data.selectedItem.id;
          this.currentFollowUpActivity.offlineOpportunityId = this.currentFollowUpActivity.opportunityId.indexOf('offline') > -1 ?
            this.currentFollowUpActivity.opportunityId : '';
          this.currentFollowUpActivity.opportunityIdNameString = data.selectedItem.title;
          this.currentFollowUpActivity.pendingPushToDynamics = true;
          this.followUpActivityDataService.updateFollowUpActivity({
            onDynamics: !this.device.isOffline,
            onLocalCopy: true,
            onLocalDatabase: true
          }, [this.currentFollowUpActivity], new Date().getTime()).then(succ => {
            // Successfull action
          }).catch(async error => {
            // Handle error occured during follow up activity updation
            if (error && error['errorCode'] && error['errorCode'] == 'ALREADYCOMPLETEDACTIVITY') {
              await this.activityDataService.updateActivityDetails(this.currentFollowUpActivity);
            }
          });
        }
        this.accountPlanPopover = undefined;
      });
      this.accountPlanPopover.present();
    }
  }

  async openEventDetail() {
    if (
      !this.currentFollowUpActivity
      || !this.currentFollowUpActivity.indskr_event
      || this.layoverViewPushedFrom === 'EventDetailsPane'
    ) return;

    const event = this.eventsToolService.eventsToolData?.find(e =>
      e.ID === this.currentFollowUpActivity.indskr_event
      || e.offlineId === this.currentFollowUpActivity.indskr_event
    );
    if (!event) return;

    if (!this.device.isOffline) {
      await this.UIService.displayLoader();
      try {
        await this.eventsToolDataService.fetchEventRealTimeDetails(event as EventActivity);
      } catch (error) {
        console.error('openEventDetail: ', error);
      } finally {
        await this.UIService.dismissLoader();
      }
    }

    this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
    this.activityService.selectedActivity = event;
    this.navService.pushChildNavPageWithPageTracking(
      EventDetailsComponent,
      PageName.EventDetailsPageComponent,
      PageName.FollowUpActionDetailComponent,
      {
        from: 'FollowUpDetail',
        event,
      }
    );
  }

  public async updateNote(ev) {
    if (ev && ev.action) {
      if (this.backgroundUploadInProgress) return;
      const idx = this.currentFollowUpActivity.followUpNotes.findIndex(note => note.noteId === ev.noteId);
      let note = this.currentFollowUpActivity.followUpNotes[idx];

      if (ev.action === 'DELETE' && idx >= 0) {
        note.isDeleted = true;
        if (note.hasDocument)
          note = this._resetDocumentDetailsOfNote(note);
      } else if (ev.action === 'SAVE' && idx >= 0) {
        note.noteText = ev.updatedText;
        if (ev.attachmentFileUpdated) {
          note = this._updateAttachmentinNotes(note, ev);
        } else if (ev.attachmentFileUpdated) {
          note = this._updateAttachmentinNotes(note, ev)
        } else if (ev.isAttachmentRemoved) {
          note.hasDocument = false;
          note = this._resetDocumentDetailsOfNote(note);
        }
      }

      note.pendingPushForDynamics = true;
      this.currentFollowUpActivity.followUpNotes[idx] = note;
      this.currentFollowUpActivity.pendingPushToDynamics = true;
      await this._displayDismissLoader(true);

      await this.followUpActivityDataService.updateFollowUpActivity({
        onDynamics: !this.device.isOffline,
        onLocalCopy: true,
        onLocalDatabase: true,
        operationDetail: {
          code: (this.currentFollowUpActivity.status !== 5) ? 'FUAANT101' : '',
          message: this.translate.instant('FOLLOW_UP_NOTIFY_ADD_NEW_NOTES')
        }
      }, [this.currentFollowUpActivity], new Date().getTime()).then(succ => {
        // Successfull action
      }).catch(err => {
        // To do error handling
      });
      await this._displayDismissLoader(false);
      this.cdRef.detectChanges();
      this.cdRef.markForCheck();
    }
  }

  private _updateAttachmentinNotes(note: IONote, ev: any) {
    note.hasDocument = true;
    note.documentSize = ev.documentSize;
    note.documentName = ev.documentName;
    note.documentMimeType = ev.documentMimeType;
    note.documentDataURL = ev.attachmentFileDataUrl;
    return note;
  }

  private _resetDocumentDetailsOfNote(note: IONote) {
    note.documentSize = 0;
    note.documentName = '';
    note.documentMimeType = '';
    note.documentDataURL = '';
    note.hasDocument = false;
    return note;
  }

  public closePage(): void {
    if (this.isLayoverPushView) {
      if (this.layoverViewPushedFrom === 'AccountManagementTool') {
        this.footerService.initButtons('AccountPlanDetails');
        this.events.publish('followupCreated', this.currentFollowUpActivity);
        this.navService.popChildNavPageWithPageTracking();
      } else if (this.layoverViewPushedFrom === 'ContactTool') {
        this.UIService.activeView = 'contactDetails';
        this.footerService.initButtons(FooterViews.Contacts);
        this.navService.popChildNavPageWithPageTracking();
      } else if (this.layoverViewPushedFrom === 'AccountTool') {
        this.UIService.activeView = 'accountDetails';
        this.footerService.initButtons(FooterViews.Accounts);
        this.navService.popChildNavPageWithPageTracking();
      } else if(this.layoverViewPushedFrom === 'InMeetingFollowup') {
        this.modalCtrl.dismiss();
        if(this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
          this.UIService.activeView = 'ActivitiesPageRightPaneNav';
          this.events.publish("initTimePickerLimitedMinTime");
          this.footerService.initButtons(FooterViews.Activities);
        }
        this.followUpActivityDataService.inMeetingFollowupActionActivity = null;
        return;
      } else if(this.layoverViewPushedFrom == 'PresentationMeetingComponent'){
        this.UIService.activeView = 'Meeting';
        this.events.publish('deletedEmbeddedActivity', this.currentFollowUpActivity);
        this.events.publish("initTimePickerLimitedMinTime");
        this.footerService.initButtons(FooterViews.PreviewMeeting);
        this.followUpActivityDataService.inMeetingFollowupActionActivity = null;
      }
      if (this.layoverViewPushedFrom === 'AgendaView') {
        this.events.publish("initTimePickerLimitedMinTime");
        if (this.footerService.isActivitiesButton) {
          this.UIService.activeView = '';
          this.UIService.showRightPane = false;
          this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        } else {
          this.navService.popChildNavPageWithPageTracking();
          this.events.publish('go-back-to-xperiences', PageName.FollowUpActionDetailComponent);
        }
      }
      else if (this.layoverViewPushedFrom == 'OpportunityManagementTool'
        || this.layoverViewPushedFrom == 'AccountProfileOpportunities'
        || this.layoverViewPushedFrom == 'AccountPlanOpportunities' 
        || this.layoverViewPushedFrom == 'MarketingPlanInfoopportunities') {
        this.footerService.initButtons('opportunityDetails');
        this.navService.popChildNavPageWithPageTracking();
      } else if (this.from == PageName.AccountPlanActivities) {
        this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        this.footerService.initButtons(FooterViews.None);
        this.navService.setChildNavRightPaneView(false);
      } else if (this.layoverViewPushedFrom === 'EventDetailsPane') {
        this.modalCtrl.dismiss();
        this.followUpActivityDataService.inMeetingFollowupActionActivity = null;
      }
    }
  }

  public markFollowUpComplete(): void {
    this.trackingService.tracking('AccountPlanFollowupActionComplete', TrackingEventNames.ACCOUNTPLANNING)
    if (this.isMarkCompleteEnabled && !this.updateInProgress) {
      this.alertService.showAlert({
        header: this.translate.instant('FOLLOW_UP_ALERT_TITLE_COMPLETE_FOLLOW_UP'),
        message: this.translate.instant('FOLLOW_UP_ALERT_MESSGE_COMPLETE_FOLLOW_UP')
      }, this.translate.instant('SUBMIT')
      ).then(res => {
        if (res.role === 'ok') {
          if (this.backgroundUploadInProgress) return;
          this.updateInProgress = true;
          if(this.InMeetingPresentationFlow){
            this.footerService.disableButton(['markfollowupcomplete','markfollowupcancel','scrapfollowup']);
          }
          this.isScrapEnabled = false;
          this.isCancelEnabled=false;
          this.isReadOnlyView = true;
          this.initPageHeaderControls();
          this.events.publish("updateFollowupActivityHeader");
          this._displayDismissLoader(true);
          

          this.followUpActivityDataService.markFollowUpActivityComplete({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true }, this.currentFollowUpActivity).then(succ => {
            //Successfully marked complete
            this.currentFollowUpActivity.state = 1;
            this.currentFollowUpActivity.status = 5;
            this.currentFollowUpActivity.statusString = this.translate.instant('COMPLETED');
            this.setSubStatusString();
            this.updateInProgress = false;
            this.initTaskNotesheader();
            this.footerService.disableButton(['markfollowupcomplete','markfollowupcancel','scrapfollowup']);
            this.initObjectivesSectionHeader();
            this.events.publish('followupactioncompleted', this.currentFollowUpActivity);
            this.events.publish('updateEmbeddedActivity', this.currentFollowUpActivity);
            if (this.isLayoverPushView) {
              if (this.layoverViewPushedFrom === 'AccountManagementTool') {
                // this.footerService.initButtons('AccountPlanDetails');
                // this.navService.popChildNavPageWithPageTracking();
                this.navService.popChildNavPageWithPageTracking().then(() => {
                  this.footerService.initButtons(FooterViews.None);
                });
              } else if (this.layoverViewPushedFrom === 'ContactTool') {
                this.UIService.activeView = 'contactDetails';
                this.footerService.initButtons(FooterViews.Contacts);
                this.navService.popChildNavPageWithPageTracking();
              } else if (this.layoverViewPushedFrom === 'AccountTool') {
                this.UIService.activeView = 'accountDetails';
                this.footerService.initButtons(FooterViews.Accounts);
                this.navService.popChildNavPageWithPageTracking();
              }
              else if (this.layoverViewPushedFrom === 'OpportunityManagementTool'
                || this.layoverViewPushedFrom === 'AccountProfileOpportunities'
                || this.layoverViewPushedFrom === 'AccountPlanOpportunities'
                || this.layoverViewPushedFrom === 'MarketingPlanInfoopportunities') {
                this.footerService.initButtons('OpportunityDetails');
                this.navService.popChildNavPageWithPageTracking();
              }
            }
            this._displayDismissLoader(false);

          }).catch(error => {
            //To do error handling
            // Handle error occured during follow up activity updation
            if (error && error['errorCode'] && error['errorCode'] == 'ALREADYSCRAPPEDACTIVITY') {
              this.events.publish('followupactioncompleted', this.currentFollowUpActivity);
              this.closePage();
            } else {
              if(!this.InMeetingPresentationFlow){
                this.footerService.enableButtons(['markfollowupcomplete','markfollowupcancel','scrapfollowup']);
              }
              this.updateInProgress = false;
            }
            this._displayDismissLoader(false);

          })
        }
      });
    }
  }

  public cancelFollowUp(): void {
    if (this.isCancelEnabled && !this.updateInProgress) {
      this.alertService.showAlert({
        header: this.translate.instant('FOLLOW_UP_ALERT_TITLE_CANCEL_FOLLOW_UP'),
        message: this.translate.instant('FOLLOW_UP_ALERT_MESSGE_CANCEL_FOLLOW_UP'),
        inputs: [{ type: 'text', name: "reasonforcancellation", placeholder: this.translate.instant('ENTER_REASON_FOR_CANCELLATION') }],
      }, this.translate.instant('OK')
      ).then(res => {
        if (res.role === 'ok') {
          if(res.data && res.data.values && res.data.values.reasonforcancellation == '') {
            this.notificationService.notify(this.translate.instant("ENTER_REASON_FOR_CANCELLATION"), 'Follow up cancel', 'top', ToastStyle.INFO);
            return;
          }
          if (this.backgroundUploadInProgress) return;
          this.updateInProgress = true;
          if(this.InMeetingPresentationFlow){
            this.footerService.disableButton(['markfollowupcomplete','markfollowupcancel','scrapfollowup']);
          }
          this.isScrapEnabled = false;
          this.isCancelEnabled = false;
          this.initPageHeaderControls();
          this.events.publish("updateFollowupActivityHeader");
          this._displayDismissLoader(true);
          this.currentFollowUpActivity.state = 2;
          this.currentFollowUpActivity.status = 6;
          this.currentFollowUpActivity.pendingPushToDynamics = true;
          this.currentFollowUpActivity.reasonForCancellation = res.data.values.reasonforcancellation;
          this.updateFooterButtonsStatus();

          this.followUpActivityDataService.updateFollowUpActivity({
            onDynamics: !this.device.isOffline && !this.device.isDeviceRealOffline,
            onLocalCopy: true,
            onLocalDatabase: true,
            operationDetail: {
              code: 'CANCEL_FOLLOWUP',
              message: 'cancelled followup'
            }
          }, [this.currentFollowUpActivity], new Date().getTime()).then(succ => {
            this._displayDismissLoader(false);
            if (this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.SUGGESTED) {
              this.UIService.showRightPane = false;
              this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
              return;
            } 
            // Successfull action
            this.setSubStatusString();
            this.isReadOnlyView = true;
            this.updateInProgress = false;
            this.footerService.disableButton(['markfollowupcomplete','markfollowupcancel','scrapfollowup']);
            this.currentFollowUpActivity.statusString = this.translate.instant('CANCELLED');
            this.initObjectivesSectionHeader();
            if (this.layoverViewPushedFrom === 'EventDetailsPane') {
              this.events.publish('meeting-structure:refreshViewData');
            }
          }).catch(async (error) => {
            // Handle error occured during follow up activity updation
            // if (error && error['errorCode'] && error['errorCode'] === 'ALREADYCOMPLETEDACTIVITY') {
            //   await this.activityDataService.updateActivityDetails(this.currentFollowUpActivity);
            // }
            // this.notificationService.notify(this.translate.instant("ENTER_REASON_FOR_CANCELLATION"), 'Follow up cancel', 'top', ToastStyle.DANGER);
            this.updateInProgress = false;
            this._displayDismissLoader(false);
          });
        }
      });
    }
  }

  public async saveFollowUpNotes() {
    if (this.isSaveNotesEnabled && ((this.tempNoteText && !_.isEmpty(this.tempNoteText)) || !_.isEmpty(this.base64str))) {

      this.isSaveNotesEnabled = false;

      this.initTaskNotesheader();

      let action = {
        onDynamics: !this.device.isOffline,
        onLocalCopy: true,
        onLocalDatabase: true,
        operationDetail: {
          code: (this.currentFollowUpActivity.status !== 5) ? 'FUAANT101' : (this.currentFollowUpActivity.pendingPushToDynamics) ? '' : 'FUAANT101',
          message: this.translate.instant('FOLLOW_UP_NOTIFY_ADD_NEW_NOTES')
        }
      };
      let tempNote: IONote = new IONote({
        annotationid: 'offline_note_' + new Date().getTime(),
        activityid: this.currentFollowUpActivity.ID,
        createdon: new Date().getTime(),
        notetext: this.tempNoteText,
        ownerName: this.authService.user.displayName,
        ownerid: this.authService.user.systemUserID,
      });

      if (this.base64str) {
        tempNote.hasDocument = true;
        tempNote.documentDataURL = this.base64str;
        tempNote.documentName = this.attachmentFile.name;
        tempNote.documentSize = this.attachmentFile.size;
        tempNote.documentMimeType = this.attachmentFile.type;
      } else {
        tempNote.hasDocument = false;
      }
      tempNote.pendingPushForDynamics = true;
      this.currentFollowUpActivity.followUpNotes.push(tempNote);
      this.currentFollowUpActivity.pendingPushToDynamics = true;
      this.removeAttachment(null);

      this.tempNoteText = '';
      this._sortFollowupNotes();
      await this._displayDismissLoader(true);

      await this.followUpActivityDataService.updateFollowUpActivity(action, [this.currentFollowUpActivity], new Date().getTime()).then(succ => {
        // Successfull action
      }).catch(err => {
        // To do error handling
      });
      await this._displayDismissLoader(false);

    }
  }

  private _sortFollowupNotes(): void {
    if (this.currentFollowUpActivity.followUpNotes && this.currentFollowUpActivity.followUpNotes.length > 1) {
      this.currentFollowUpActivity.followUpNotes.sort((a, b) => {
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1)
      })
    }
  }

  public scrapFollowup(): void {
    if (this.isScrapEnabled) {
      if (this.layoverViewPushedFrom === 'InMeetingFollowup' || this.layoverViewPushedFrom === 'EventDetailsPane' || this.layoverViewPushedFrom === 'PresentationMeetingComponent') {
        this.alertService.showAlert({
          title: this.translate.instant('SCRAP_FOLLOW_UP'),
          message: this.translate.instant('POP_R_U_SURE_SCRAP_FOLLOW_UP'),
        }, this.translate.instant('SCRAP')
        ).then(async res => {
            if (res.role == "ok") {
              await this.UIService.displayLoader();
              if (this.device.isBackgroundUploadInProgress && this.currentFollowUpActivity.pendingPushToDynamics) {
                await this.UIService.dismissLoader();
                return;
              }

              this.currentFollowUpActivity.pendingPushToDynamics = true;

              try {
                await this.followUpActivityDataService.scrapFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true }, this.currentFollowUpActivity);

                this.activityService.publishActivityEvent({ action: "Delete", activity: this.currentFollowUpActivity });
                this.events.publish('followupActionDeleted', this.currentFollowUpActivity);

                if (this.layoverViewPushedFrom === 'EventDetailsPane') {
                  this.meetingStructureService.removeActivity(this.currentFollowUpActivity, ActivityType.Event);
                } else {
                  if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
                    this.events.publish('refreshAgenda');
                  }
                  this.events.publish('deletedEmbeddedActivity', this.currentFollowUpActivity);
                }
              } catch (error) {
                console.error('follow-up-action-detail: scrapFollowup: ', error);
              } finally {
                this.closePage();
                await this.UIService.dismissLoader();
              }
            }
          });
      }else{
        this.events.publish('deleteMeeting');
        this.events.publish("initTimePickerLimitedMinTime");
      }
    }
  }

  public getSubjectFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('SUBJECT'),
      inputText: (this.currentFollowUpActivity && this.currentFollowUpActivity.subject) ? (this.currentFollowUpActivity.subject === 'Follow-up Action' ? this.translate.instant('FOLLOW_UP_ACTION') : this.currentFollowUpActivity.subject) : this.translate.instant('FOLLOW_UP_ACTION'),
      inputValue: (this.currentFollowUpActivity && this.currentFollowUpActivity.subject) ? (this.currentFollowUpActivity.subject === 'Follow-up Action' ? this.translate.instant('FOLLOW_UP_ACTION') : this.currentFollowUpActivity.subject) : this.translate.instant('FOLLOW_UP_ACTION'),
      id: 'subject-field',
      isReadOnly: this.isReadOnlyView,
      isDisabled: this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.SUGGESTED || this.backgroundUploadInProgress || this.isReadOnlyView || !this.authService.user.buConfigs['indskr_followupactionsubjecteditable'] ,
      placeholderLabel: this.translate.instant('SUBJECT'),
      showArrow: !this.isReadOnlyView && this.authService.user.buConfigs['indskr_followupactionsubjecteditable'],
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: !this.isReadOnlyView,
      displayInfoText: this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.SUGGESTED,
      infoText: this.translate.instant('SUGGESTED_FOLLOWUP_ACTION'),
      infoTextCSSClass: 'orange-info-message-text',
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getStatusFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('STATUS'),
      inputText: !this.currentFollowUpActivity.pendingPushToDynamics ? this.getStatusString() : `${this.getStatusString()} - ${this.translate.instant('PENDING_SYNC')}`,
      id: 'status-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getReasonForCancellationFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: this.currentFollowUpActivity.status !== 6,
      label: this.translate.instant('REASON_FOR_CANCELLATION'),
      inputText: this.currentFollowUpActivity.reasonForCancellation || 'test reason',
      id: 'reason-for-cancellation-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getSubStatusFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('SUB_STATUS'),
      customPlaceholderLabel: this.translate.instant('SELECT_SUB_STATUS'),
      inputText: this.followupSubStatusString,
      id: 'sub-status-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || this.isReadOnlyView,
      showArrow: (!this.isReadOnlyView && (this.followupActionOpenStatusReasons && !_.isEmpty(this.followupActionOpenStatusReasons))),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getDueDateField(): IndDateTimeFormViewDataModel {
    let viewData: IndDateTimeFormViewDataModel;
    viewData = {
      isDateField: true,
      label: this.translate.instant('DUE_DATE'),
      inputText: (this.currentFollowUpActivityDueDateString !== 'Select Due Date' && this.currentFollowUpActivityDueDateString !== 'None') ? this.currentFollowUpActivityDueDateString : '',
      fromViewPage: CurViewPageType.FollowUp,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || this.isReadOnlyView,
      customPlaceholderLabel: (this.currentFollowUpActivityDueDateString == 'Select Due Date' || this.currentFollowUpActivityDueDateString == 'None') ? (this.currentFollowUpActivityDueDateString == 'Select Due Date') ? this.translate.instant('SELECT_DUE_DATE') : this.translate.instant('NO_DUE_DATE') : '',
      showArrow: !this.isReadOnlyView,
      isRequired: !this.isReadOnlyView,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getCreatedByFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CREATED_BY'),
      inputText: this.currentFollowUpActivity.ownerNameString,
      fromViewPage: CurViewPageType.FollowUp,
      id: 'created-by-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getMeetingFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('MEETING'),
      isHidden: this.currentFollowUpActivity.planType !== FOLLOW_UP_TYPE.MEETINGTASK,
      inputText: this.currentFollowUpActivity.appointmentSubject,
      fromViewPage: CurViewPageType.FollowUp,
      id: 'meeting-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
    };
    return viewData;
  }

  public getEventFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('EVENT'),
      isHidden: this.currentFollowUpActivity.planType !== FOLLOW_UP_TYPE.EVENT_TASK,
      inputText: this.currentFollowUpActivity.indskr_eventname || '',
      fromViewPage: CurViewPageType.FollowUp,
      id: 'event-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress,
      showArrow: false,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  private _getFollowupActiontypePlaceHolder() {
    if (this.isReadOnlyView) {
      if (!this.currentFollowUpActivity.followupActionTypeID) {
        return this.translate.instant('NO_TYPE')
      }
    } else {
      if (this.followupActionTypes && !_.isEmpty(this.followupActionTypes)) {
        return this.translate.instant('SELECT_TYPE')
      } else {
        return this.translate.instant('NO_TYPE')
      }
    }
  }

  public getActionTypeFormField(): IndFormFieldViewDataModel {
    return {
      label: this.translate.instant('TYPE'),
      customPlaceholderLabel: this._getFollowupActiontypePlaceHolder(),
      inputText: this.currentFollowUpActivity.followupActionTypeName,
      id: 'followup-type-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || this.isReadOnlyView,
      isRequired: !this.isReadOnlyView && this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.NONE,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedActionType && !this.currentFollowUpActivity.followupActionTypeID ? true : false,
      showArrow: (!this.isReadOnlyView && (this.followupActionTypes && !_.isEmpty(this.followupActionTypes))),
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

  private _getPriorityInputValue() {
    switch (this.currentFollowUpActivity.priorityID) {
      case FOLLOW_UP_PRIORITY.LOW:
        return this.translate.instant('LOW')
      case FOLLOW_UP_PRIORITY.MEDIUM:
        return this.translate.instant('MEDIUM');
      case FOLLOW_UP_PRIORITY.HIGH:
        return this.translate.instant('HIGH');
    }
  }

  public getPriorityFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('FOLLOW_UP_PRIORITY'),
      customPlaceholderLabel: !(this.assignedUsersCanEdit || this.OwnerCanEdit) ? (this.currentFollowUpActivity.priorityID ? '' : this.translate.instant('NO_PRIORITY')) : (this.currentFollowUpActivity.priorityID) ? '' : this.translate.instant('SCHEDULER_SELECT_PRIORITY'),
      inputText: this._getPriorityInputValue(),
      id: 'followup-priority-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || !(this.assignedUsersCanEdit || this.OwnerCanEdit),
      showArrow: this.assignedUsersCanEdit || this.OwnerCanEdit,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  get assignedUsersCanEdit() {
    let isAssignedUSer: boolean = false;
    if (this.currentFollowUpActivity) {
      if (!_.isEmpty(this.currentFollowUpActivity.assignedTo)) {
        isAssignedUSer = this.currentFollowUpActivity.assignedTo.findIndex(user => user.userId == this.authService.user.systemUserID) >= 0;
      }
      return isAssignedUSer && this.currentFollowUpActivity.state == 0
    }
    return isAssignedUSer;
  }

  get OwnerCanEdit() {
    let isEditable: boolean = false;
    if (this.currentFollowUpActivity && this.from != PageName.AccountPlanActivities) {
      isEditable = this.currentFollowUpActivity.ownerId === this.authService.user.systemUserID && this.currentFollowUpActivity.state == 0;
    }
    return isEditable;
  }

  //Owner / Assigned user should be able to add notes
  get canAddNotes() {
    if (this.from == PageName.AccountPlanActivities) return false;
    if (this.currentFollowUpActivity) {
      const isOwner = this.currentFollowUpActivity.ownerId === this.authService.user.systemUserID;
      if (!isOwner) {
        return this.currentFollowUpActivity.assignedTo.findIndex(user => user.userId == this.authService.user.systemUserID) >= 0
      }
      return isOwner && this.currentFollowUpActivity.state === 0;
    }
    return false;
  }

  private _selectedProductsInputText() {

    if (this.currentFollowUpActivity.products && !_.isEmpty(this.currentFollowUpActivity.products)) {
      if (this.currentFollowUpActivity.products.length === 1)
        return this.currentFollowUpActivity.products[0].productName;
      else {
        return `${this.currentFollowUpActivity.products[0].productName} + ${this.currentFollowUpActivity.products.length - 1}`;
      }
    }
  }

  private _productsPlaceholder() {
    if (this.isReadOnlyView) {
      if (!this.currentFollowUpActivity.products || this.currentFollowUpActivity.products.length <= 0) {
        return this.translate.instant('NO_PRODUCTS')
      }
    } else {
      if (!this.currentFollowUpActivity.products || this.currentFollowUpActivity.products.length <= 0) {
        return this.translate.instant('SELECT_PRODUCTS')
      }
    }
  }

  public getProductFormField(): IndFormFieldViewDataModel {
    return {
      label: this.translate.instant('PRODUCTS'),
      customPlaceholderLabel: this._productsPlaceholder(),
      inputText: this._selectedProductsInputText(),
      id: 'followup-products-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || _.isEmpty(this.brandService.brands) || (this.isReadOnlyView && _.isEmpty(this.currentFollowUpActivity.products)),
      showArrow: (!this.isReadOnlyView && !_.isEmpty(this.brandService.brands)) || (this.isReadOnlyView && !_.isEmpty(this.currentFollowUpActivity.products)),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
  }

  public getSuggestReasonFormField(): IndFormFieldViewDataModel {
    return {
      label: this.translate.instant('SUGGEST_REASON'),
      inputText: this.currentFollowUpActivity.description,
      inputValue: this.currentFollowUpActivity.description,
      id: 'suggest-reason-field',
      isReadOnly: true,
      isDisabled: true,
      placeholderLabel: this.translate.instant('SUGGEST_REASON'),
      showArrow: false,
      isHidden: !this.currentFollowUpActivity.description,
      isFieldNextIcon: true,
      imgSrc: 'assets/imgs/field_star.svg',
      formFieldType: FormFieldType.DATA_SUBMITTED
    }
  }

  public getAssignedToFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ASSIGNED_TO'),
      inputText: (this.currentFollowUpActivity.assignedTo.length !== 0) ? this.getAssignedToString() : '',
      id: 'assigned-to-field',
      isReadOnly: true,
      isDisabled: this.from == PageName.AccountPlanActivities || this.backgroundUploadInProgress || !(!this.isReadOnlyView || !_.isEmpty(this.currentFollowUpActivity.assignedTo)),
      customPlaceholderLabel: (this.currentFollowUpActivity.assignedTo.length === 0) ? this.getAssignedToString() : '',
      showArrow: (!this.isReadOnlyView || !_.isEmpty(this.currentFollowUpActivity.assignedTo)),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getCustomerFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.globalCustomerText,
      inputText: (this.currentFollowUpActivity.contactId) ? this.currentFollowUpActivity.contactNameString : '',
      id: 'customer-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || this.isReadOnlyView || this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.SUGGESTED,
      customPlaceholderLabel: (!this.currentFollowUpActivity.contactId) ? (this.isReadOnlyView) ? this.translate.instant('No ' + this.utilityService.globalCustomerText) : this.translate.instant('SELECT_WITH_GLOBALCUSTOMER', { globalCustomerText: this.globalCustomerText }) : '',
      showArrow: !this.isReadOnlyView,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getAccountFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.NONE,
      label: this.translate.instant('ACCOUNT'),
      inputText: (this.currentFollowUpActivity.accountNameString) ? this.currentFollowUpActivity.accountNameString : '',
      id: 'account-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || this.isReadOnlyView || this.isTaskWithinOpportunityContext || this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.SUGGESTED,
      placeholderLabel: (!this.currentFollowUpActivity.accountNameString) ? (this.isReadOnlyView || this.isTaskWithinOpportunityContext) ? this.translate.instant('NO_ACCOUNT') : this.translate.instant('ACCOUNT') : '',
      showArrow: !this.isReadOnlyView && !this.isTaskWithinOpportunityContext,
      isRequired: !this.isReadOnlyView && !this.isEventFollowUp,
      // isRequired: !this.isReadOnlyView && ( !this.currentFollowUpActivity.accountId || !this.isTaskWithinOpportunityContext && (this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.ACCOUNT_PLAN || this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.OPPORTUNITY_TASK)),

      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedAccount && !this.currentFollowUpActivity.accountId ? true : false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getAccountPlanFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    const accountPlans = this.accountManagementService.accountPlans?.filter(plan => plan.accountId == this.currentFollowUpActivity.accountId && plan.state === 0);
    const isAccountPlansData: boolean = accountPlans?.length > 0 ? true : false;
    viewData = {
      isHidden: this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.MARKETING_BUSINESS_PLAN || !this.authService.hasFeatureAction(FeatureActionsMap.ACCOUNT_MANAGEMENT) || this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.NONE,
      label: this.translate.instant('ACCOUNT_PLAN'),
      inputText: (this.currentFollowUpActivity.accountPlanNameString) ? this.currentFollowUpActivity.accountPlanNameString : '',
      id: 'account-plan-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || this.isReadOnlyView,
      placeholderLabel: (!this.currentFollowUpActivity.accountPlanNameString) ? (this.isReadOnlyView || !isAccountPlansData) ? this.translate.instant('NO_ACCOUNT_PLAN_ASSOCIATED') : this.translate.instant('ACCOUNT_PLAN') : '',
      showArrow: !this.isReadOnlyView && isAccountPlansData,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => {
        if (isAccountPlansData) {
          this.handleFormFieldEvent(id, event, eventName);
        }
      }
    };
    return viewData;
  }

  public getMarketingPlanFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      isHidden: !(this.authService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN) && this.currentFollowUpActivity.planType == FOLLOW_UP_TYPE.MARKETING_BUSINESS_PLAN),
      label: this.translate.instant('MARKETING_BUSINESS_PLAN'),
      inputText: (this.currentFollowUpActivity.marketingPlanNameString) ? this.currentFollowUpActivity.marketingPlanNameString : '',
      id: 'marketing-plan-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || this.isReadOnlyView,
      placeholderLabel: (!this.currentFollowUpActivity.marketingPlanNameString) ? this.isReadOnlyView ? this.translate.instant('NO_MARKETING_PLAN_ASSOCIATED') : this.translate.instant('MARKETING_BUSINESS_PLAN') : '',
      showArrow: !this.isReadOnlyView,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => {
        this.handleFormFieldEvent(id, event, eventName);
      }
    };
    return viewData;
  }

  public getOpportunityFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    const opportunities = this.opportunityService.opportunities.filter(oppo => oppo.accountID == this.currentFollowUpActivity.accountId && oppo.stateCode === 0);
    const isOpportunitiesData: boolean = opportunities.length > 0 ? true : false;
    viewData = {
      isHidden: this.currentFollowUpActivity.planType !== FOLLOW_UP_TYPE.OPPORTUNITY_TASK,
      label: this.translate.instant('OPPORTUNITY'),
      inputText: (this.currentFollowUpActivity.opportunityIdNameString) ? this.currentFollowUpActivity.opportunityIdNameString : '',
      id: 'opportunity-field',
      isReadOnly: true,
      isDisabled: this.backgroundUploadInProgress || this.isReadOnlyView,
      placeholderLabel: (!this.currentFollowUpActivity.opportunityIdNameString) ? (this.isReadOnlyView || !isOpportunitiesData) ? this.translate.instant('FOLLOW_UP_NO_OPPORTUNITY') : this.translate.instant('OPPORTUNITY') : '',
      showArrow: !this.isReadOnlyView && isOpportunitiesData,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => {
        if (isOpportunitiesData) {
          this.handleFormFieldEvent(id, event, eventName);
        }
      }
    };
    return viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      if (id != 'assigned-to-field' && this.backgroundUploadInProgress) return;
      switch (id) {
        case 'subject-field':
          if (eventName && eventName === 'input_value_confirm') {
            this.openSubjectPopover(event);
          }
          break;
        case 'end-date-field':
          this.openEndDatePicker(event);
          break;
        case 'assigned-to-field':
          this.openUserSelect();
          break;
        case 'customer-field':
          this.openCustomerSelect();
          break;
        case 'account-field':
          this.openAccountSelect();
          break;
        case 'account-plan-field':
          this.openAccountPlanSelect(event);
          break;
        case 'opportunity-field':
          this.openOpportunitySelect(event);
          break;
        case 'followup-type-field':
          this.openFollowupActionTypeSelect(event);
          break;
        case 'followup-priority-field':
          this.openPrioritySelect(event);
          break;
        case 'followup-products-field':
          this.openProductSelect();
          break;
        case 'sub-status-field':
          this.openSubStatusSelect(event);
          break;
        case 'marketing-plan-field':
          this.openMarketingBusinessPlan(event);
          break;
        case 'event-field':
          this.openEventDetail();
          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
  }

  openProductSelect() {
    if (this.brandService.brands) {
      const listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('PRODUCTS'),
        dividerTitle: this.translate.instant('ALL_PRODUCTS_CAP'),
        isSearchEnabled: true,
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: !this.isReadOnlyView,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        orderByPropertyName: 'primaryTextRight',
        // searchTitle: this.translate.instant('SEARCH_PRODUCTS'),
        hideAllItemsList: this.isReadOnlyView,
        isListSelectionEnabled: !this.isReadOnlyView,
        listSelectionType: MainToolTemplateListSelectionType.MULTIPLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleProductComponentEvent(data, eventTarget, refData),
        searchHandler: (text: string) => this._handleProductsComponentSearch(text),
        data: this.getProductDisplayData(),
      };
      if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingFollowup' || this.layoverViewPushedFrom === 'EventDetailsPane') {
        if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          setTimeout(()=>{
            document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
          });
        }
        this.navCtrl.push(MainToolTemplateComponent, { viewData: listDetail,isNavWithinModalView: true, isGroupedView: false });
      } else {
        this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isGroupedView: false }, PageName.MainToolTemplateComponent);
      }
    }
  }

  private getProductDisplayData(): MainCardViewDataModel[] {
    if (!this.isReadOnlyView) {
      // if Product is matched with the Products in Active Brand Products Pool

      let productDataModel = this.brandService.brands && this.brandService.brands.map(product => {
        let isSelected = false;
        if (this.currentFollowUpActivity.products) {
          if (this.currentFollowUpActivity.products.some(taskProduct => (taskProduct.productID === product.ID))) {
            isSelected = true;
          }
        }
        return this._getProductsMainToolDataItem({ id: product.ID, name: product.name }, !this.isReadOnlyView, isSelected);
      });

      return this.appendDeActivatedProductModel(productDataModel)
    } else {
      return this.currentFollowUpActivity.products && this.currentFollowUpActivity.products.map(product => {
        return this._getProductsMainToolDataItem({ id: product.productID, name: product.productName }, !this.isReadOnlyView, true);
      });
    }
  }

  private appendDeActivatedProductModel(productDataModel: MainCardViewDataModel[]) {

    let selectedProductsModel = productDataModel.filter(productModel => productModel.isItemSelectedForSelectionView);

    if (selectedProductsModel.length < this.currentFollowUpActivity.products.length) {

      let filteredDeactivatedproduct = this.currentFollowUpActivity.products.filter(taskProduct => {
        return !this.brandService.brands.some(brandProduct => (taskProduct.productID === brandProduct.ID));
      });

      let deactivatedDataModels = filteredDeactivatedproduct.map(filterProduct => {
        return this._getProductsMainToolDataItem({ id: filterProduct.productID, name: filterProduct.productName }, true, true);
      });

      if (!_.isEmpty(deactivatedDataModels)) {
        productDataModel = productDataModel.concat(deactivatedDataModels);
        productDataModel = productDataModel.sort((a, b) => {
          if (a.primaryTextRight.toLowerCase() > b.primaryTextRight.toLowerCase()) {
            return 1;
          }
          if (b.primaryTextRight.toLowerCase() > a.primaryTextRight.toLowerCase()) {
            return -1;
          }
        });
      }
    }
    return productDataModel;
  }

  private _getProductsMainToolDataItem(product: { id: string, name: string }, showEndIcon: boolean, isSelected: boolean) {
    return {
      id: product.id,
      primaryTextLeft: '',
      secondaryTextLeft: '',
      showEndIcon: showEndIcon,
      mainItemCssClass: 'selector-item',
      isItemSelectedForSelectionView: isSelected,
      endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
      endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
      primaryTextRight: product.name,
      showArrow: false,
      arrowType: '',
      eventOwnerId: this.activityService.selectedActivity.ownerId
    };
  }

  private _handleProductsComponentSearch(text: string): string[] {
    let ids: Array<string> = [];
    if (text.length >= 1) {
      ids = this.brandService.brands && this.brandService.brands.filter(pro => {
        return pro.name.trim().toLowerCase().includes(text.trim().toLowerCase());
      }).map(pro => pro.ID);
    } else {
      ids = this.brandService.brands && this.brandService.brands.map(pro => pro.ID);
    }
    return ids;
  }

  // this method Handles the selection of the Products after clicking of Tick(Done button on MainTool Template Page)
  private _handleProductComponentEvent(data: any, eventTarget: string, refData: MainToolTemplateDetail) {
    if (eventTarget && eventTarget === 'RightHeaderButtonClick') {
      if (!this.isReadOnlyView && !this.backgroundUploadInProgress) {

        this.updateFollowupActivityAfterSelection(data, 'followup-products');

      }
    }
  }

  private _getpriorityDropDownData() {

    let priorityData = []

    for (const [key, value] of Object.entries(FOLLOW_UP_PRIORITY)) {

      if (!Number.isNaN(Number(key))) {
        continue;
      }
      priorityData.push({ id: value, title: this.translate.instant(key), isSelected: value === this.currentFollowUpActivity.priorityID });
    }
    return priorityData
  }

  async openPrioritySelect(myEvent) {
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'followup-action-type',
      isMultipleSelectionEnabled: false,
      data: this._getpriorityDropDownData(),
    };

    this.accountPlanPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'account-plan-select', event: myEvent });

    await this.accountPlanPopover.present();

    await this.accountPlanPopover.onDidDismiss().then(async (data) => {
      if (this.backgroundUploadInProgress) return;
      data = data.data;
      this.updateFollowupActivityAfterSelection(data, 'followup-priority');
      this.accountPlanPopover = undefined;
    });

  }

  async openFollowupActionTypeSelect(myEvent) {

    if (_.isArray(this.followupActionTypes) && !_.isEmpty(this.followupActionTypes)) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'followup-action-type',
        isMultipleSelectionEnabled: false,
        data: this.followupActionTypes.map(type => {
          return {
            title: type.name,
            id: type.followupActionTypeID,
            isSelected: type.followupActionTypeID == this.currentFollowUpActivity.followupActionTypeID,
          }
        }),
      };

      this.accountPlanPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'account-plan-select', event: myEvent });

      await this.accountPlanPopover.present();

      await this.accountPlanPopover.onDidDismiss().then(async (data) => {
        if (this.backgroundUploadInProgress) return;
        data = data.data;
        this.updateFollowupActivityAfterSelection(data, 'followup-action-type');
        this.accountPlanPopover = undefined;
      });
    }
    this._isClickedActionType = true;
    this.getActionTypeFormField();
  }

  private _setFieldValue(data: any, type: string) {

    switch (type) {
      case 'followup-action-type':
        if (data.selectedItems.length == 1 && Array.isArray(data.selectedItems)) {
          this.currentFollowUpActivity.followupActionTypeID = data.selectedItems[0].id;;
          this.currentFollowUpActivity.followupActionTypeName = data.selectedItems[0].title;;
        }
        break;
      case 'followup-priority':
        if (data.selectedItems.length == 1 && Array.isArray(data.selectedItems)) {
          this.currentFollowUpActivity.priorityID = data.selectedItems[0].id;
          this.currentFollowUpActivity.priorityValue = this._getPriorityInputValue();
        }
        break

      case 'followup-products':

        if (Array.isArray(data.selectedItems)) {
          let followupProducts = []
          // Pick only those which are selecetd from the pool of the brand products
          data.selectedItems.forEach(item => {
            this.brandService.brands.forEach(product => {
              if (product.ID === item.id) { // If IDs match make that as a selected
                product.isSelected = true;
                followupProducts.push({ productID: product.ID, productName: product.name });
              }
            });
          });
          this.currentFollowUpActivity.products = followupProducts;
        }
        break;

      case 'followup-action-sub-status':
        if (data.selectedItems.length == 1 && Array.isArray(data.selectedItems)) {
          this.currentFollowUpActivity.status = parseInt(data.selectedItems[0].id);
          this.currentFollowUpActivity.state = 0;
        }
        this.setSubStatusString();
        break;
    }
  }

  private async updateFollowupActivityAfterSelection(data: any, type: string) {

    if (data && data.selectedItems && _.isArray(data.selectedItems)) {

      this._setFieldValue(data, type,)
      this.currentFollowUpActivity.pendingPushToDynamics = true;

      await this._displayDismissLoader(true);

      await this.followUpActivityDataService.updateFollowUpActivity({
        onDynamics: !this.device.isOffline && !this.device.isDeviceRealOffline,
        onLocalCopy: true,
        onLocalDatabase: true
      }, [this.currentFollowUpActivity], new Date().getTime()).then(succ => {
        // Successfull action
        if (type == 'followup-action-type') {
          this.updateFooterButtonsStatus();
        }
      }).catch(async (error) => {
        // Handle error occured during follow up activity updation
        if (error && error['errorCode'] && error['errorCode'] === 'ALREADYCOMPLETEDACTIVITY') {
          await this.activityDataService.updateActivityDetails(this.currentFollowUpActivity);
        }
      });

      await this._displayDismissLoader(false);
    }
  }

  onSectionHeaderControlClick(id: string) {
    if (this.backgroundUploadInProgress) return;
    switch(id) {
      case 'scrap':
        this.scrapFollowup();
        break;
      case 'cancel':
        this.cancelFollowUp();
        break;
      case 'markComplete':
        this.markFollowUpComplete();
        break;
      case 'task-notes-save':
        this.saveFollowUpNotes();
        break;
      case 'attach_note':
        this.handleNoteAttachment();
        break;
      case 'add-objectives':
        this.handleObjectivesAdd();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private async handleObjectivesAdd() {
    if(this.activityService.selectedActivity.isCompleted || this.backgroundUploadInProgress) return;
    const selectedObjIds = this.currentFollowUpActivity.objectives?.map(obj => obj.followUpObjectiveId);
    const options = this.followUpActivityDataService.followupActionObjectives.map(obj => {
      return {
        id: obj.indskr_followupactionobjectivesid,
        title: obj.indskr_name,
        isSelected: selectedObjIds.includes(obj.indskr_followupactionobjectivesid)
      }
    });
    const objectiveModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: options,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('OBJECTIVES'),
          dividerTitle: this.translate.instant('ALL_OBJECTIVES'),
          isSearchEnabled: true,
        }
      },
      backdropDismiss: false
    });
    objectiveModal.present();
    objectiveModal.onDidDismiss().then(async (obj: any) => {
      const data = obj.data;
      if (data && data.isDone) {
        let selectedObjectives = [];
        const selectedIds = []
        data.selectedItems.forEach(selectedItem => {
          selectedIds.push(selectedItem.id);
          selectedObjectives.push({followUpObjectiveId: selectedItem.id, followUpObjectiveName: selectedItem.title});
        });
        this.currentFollowUpActivity.objectives =  _.orderBy(selectedObjectives, 'followUpObjectiveName');
        // await this.followUpActivityDataService.updateFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true }, [this.currentFollowUpActivity], new Date().getTime()).then(success => {
          this.currentFollowUpActivity.pendingPushToDynamics = true;
        await this.UIService.displayLoader();
        await this.followUpActivityDataService.saveFollowupActionObjectives(selectedIds , this.currentFollowUpActivity);
        await this.UIService.dismissLoader();
      }
    });
  }

  private handleNoteAttachment() {
    if (this.isNotesAttachmentEnabled) {
      try {
        this.attachInput.nativeElement.click();
      } catch (error) {
        console.log(error);
      }
    }
  }

  onNotesFocus() {
  }

  onNotesBlur() {
    if (_.isEmpty(this.tempNoteText)) {
      this.isSaveNotesEnabled = false;
      this.initTaskNotesheader();
    }
  }

  public getGroupedActivities(): Array<Object> {
    const events: any[] = [];
    const groupedElements: any = {};
    let groupByKey = 'createdon';
    if (!_.isEmpty(this.currentFollowUpActivity.assignmentHistory)) {
      let assignmentHistory: AssignmentHistory[] = _.cloneDeep(this.currentFollowUpActivity.assignmentHistory);
      assignmentHistory = _.orderBy(assignmentHistory, v => v.createdon, "desc")
      assignmentHistory.forEach((obj: AssignmentHistory, index) => {
        const item = format(new Date(parseInt(obj[groupByKey])), 'MMMM YYYY');
        if (!(item in groupedElements)) {
          groupedElements[item] = [];
        }
        const createdOn: Date = new Date(parseInt(obj.createdon));
        const users = _.orderBy(obj.users, o => o.userFullName);
        const data = {
          day: getDate(createdOn),
          formmattedDate: format(createdOn, this.dateTimeFormatsService.timeToUpper),
          createdon: createdOn,
          secondaryText: this.translate.instant('FOLLOW_UP_ASSIGNED_TO', { users: (users.length === 1 ? users[0].userFullName : (users[0].userFullName + ' +' + (users.length - 1))) }),
          text: (index == (assignmentHistory.length - 1)) ? this.translate.instant("FOLLOW_UP_CREATED") : this.translate.instant("FOLLOW_UP_UPDATED")
        }
        groupedElements[item].push(data);
      });

      for (let prop in groupedElements) {
        if (groupedElements.hasOwnProperty(prop)) {
          events.push({
            key: prop,
            list: groupedElements[prop]
          });
        }
      }
    }
    return events;
  }

  public async loadImageFromDevice(event) {
    if (event && event.target && event.target.files) {
      try {
        if ((event.target.files[0].size / 1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE) {
          if (NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(event.target.files[0].name)) {
            this.attachmentFile = event.target.files[0];
            this.attachmentTitle = event.target.files[0].name;
            this.isAttachmentAdded = true;
            this.base64str = await toBase64(this.attachmentFile);
            const dataURLPattern = /^data:[^;]+;base64,/;
            this.base64str = this.base64str.replace(dataURLPattern, '');
            // this.notesChanged();
            this.isSaveNotesEnabled = true;
            this.cdRef.detectChanges();
            this.cdRef.markForCheck();
            this.initTaskNotesheader();
          } else {
            this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'), 'Note Attachment', 'top', 'info');
            this.removeAttachment(false);
          }
        } else {
          this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION', { size: MAXIMUM_NOTE_ATTACHMENT_SIZE }), 'Note Attachment', 'top', 'info');
          this.removeAttachment(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  public removeAttachment(event) {
    try {
      this.attachInput.nativeElement.value = null;
    } catch (error) {
      console.log(error);
    }
    this.attachmentTitle = '';
    this.isAttachmentAdded = false;
    this.attachmentFile = null;
    this.base64str = null;
  }

  get nonDeletedNotes() {
    return this.currentFollowUpActivity.followUpNotes.filter(note => !note.isDeleted)
  }

  private async _displayDismissLoader(displayLoader: boolean) {

    if (displayLoader) {
      if (!this.device.isDeviceRealOffline && !this.device.isOffline) {
        await this.UIService.displayLoader();
      }
    } else {
      await this.UIService.dismissLoader();
    }
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.dateFormatSubscription.unsubscribe();
    this.followUpActivityDataService.inMeetingFollowupActionActivity = null;
    this.followUpActivityDataService.isInMeetingFollowupFlow = false;
  }

  async openEndDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.FollowUp,
          startDateTimeValue: this.currentFollowUpActivity.scheduledStart,
          endDateTimeValue: this.currentFollowUpActivity.scheduledStart
        }, // send the same scheduledStart to display current date in the date picker
        cssClass: "datetime-popover"
      }
    );
    popover.onDidDismiss().then(async (data: any) => {
      if (data !== null && !_.isEmpty(data.data) && data.data.endTime != '') {
        if (this.backgroundUploadInProgress) return;
        const valueDate = new Date(data.data.endTime);
        valueDate.setHours(0, 0, 0, 0);
        if (!(this.currentFollowUpActivity.scheduledEnd && this.currentFollowUpActivity.scheduledEnd.getDate() == valueDate.getDate() && this.currentFollowUpActivity.scheduledEnd.getMonth() == valueDate.getMonth() && this.currentFollowUpActivity.scheduledEnd.getFullYear() == valueDate.getFullYear())) {
          this.currentFollowUpActivity.scheduledStart = valueDate;
          this.currentFollowUpActivity.scheduledEnd = valueDate;
          this.currentFollowUpActivity.scheduledStartLocale = valueDate.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
          this.currentFollowUpActivity.pendingPushToDynamics = true;
          await this.followUpActivityDataService.updateFollowUpActivity({ onDynamics: !this.device.isOffline, onLocalDatabase: true, onLocalCopy: true }, [this.currentFollowUpActivity], new Date().getTime()).then(success => {
            //Handle successfull response
            this.currentFollowUpActivityDueDateString = format(this.currentFollowUpActivity.scheduledEnd, this.dateTimeFormatsService.dateToUpper);
            if (!this.UIService.toolsActivityActive) {
              this.events.publish("refreshAgenda");
            } else {
              this.UIService.agendaRefreshRequired = true;
            }
            this.events.publish('activityUpdated', this.currentFollowUpActivity);
          }).catch(async error => {
            // Handle error occured during follow up activity updation
            if (error && error['errorCode'] && error['errorCode'] === 'ALREADYCOMPLETEDACTIVITY') {
              await this.activityDataService.updateActivityDetails(this.currentFollowUpActivity);
            }
          });
        }
      }
    });
    popover.present();
  }

  private _updateMandatoryField() {
    if (this._isClickedAccount && (this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.ACCOUNT_PLAN || this.currentFollowUpActivity.planType === FOLLOW_UP_TYPE.OPPORTUNITY_TASK)) {
      this.getAccountFormField();
    }
  }

  async openSubStatusSelect(myEvent) {

    if (_.isArray(this.followupActionOpenStatusReasons) && !_.isEmpty(this.followupActionOpenStatusReasons)) {
      let dropdownListDetail: IndDropdownListDetailModel = {
        id: 'followup-action-sub-status',
        isMultipleSelectionEnabled: false,
        data: this.followupActionOpenStatusReasons.map(reason => {
          return {
            title: reason.name,
            id: reason.statusCode.toString(),
            isSelected: reason.statusCode == this.currentFollowUpActivity.status,
          }
        }),
      };

      this.accountPlanPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'account-plan-select', event: myEvent });

      await this.accountPlanPopover.present();

      await this.accountPlanPopover.onDidDismiss().then(async (data) => {
        if (this.backgroundUploadInProgress) return;
        data = data.data;
        this.updateFollowupActivityAfterSelection(data, 'followup-action-sub-status');
        this.accountPlanPopover = undefined;
      });
    }
    this.getActionTypeFormField();
  }
}
