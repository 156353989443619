import { CRMInstance } from './../../classes/authentication/instance.class';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import {
  IonNav,
  LoadingController} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Events } from '@omni/events';
import { Subscription } from 'rxjs';
import { AboutDetailsComponent } from '../../components/app-settings/about-page/about-details/about-details';
import { AlertService } from '../../services/alert/alert.service';
import { SettingsService } from '../../services/app-settings/settings.service';
import { AuthenticationService } from '../../services/authentication.service';
import { decrypt } from '../../services/crypto';
import { DeviceService } from '../../services/device/device.service';
import { LogService } from '../../services/logging/log-service';
import { NotificationService } from '../../services/notification/notification.service';
import { HomePage } from '../home/home';
import { LoginService } from '@omni/services/msal/login.service';
import { InstanceSelectionComponent } from './../../components/instance-selection/instance-selection';
// import { LaunchDarklyProvider } from '@omni/providers/launch-darkly/launch-darkly';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { CordovaPlugin } from '@omni/services/cordova';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';
import { UIService } from '@omni/services/ui/ui.service';


/**
 * Generated class for the LoginPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-login',
  templateUrl: 'login.html',
  styleUrls:['login.scss']
})
export class LoginPage implements OnInit {
  
  loginErrorMessage: string;
  public displayLoginError: boolean;
  selectedInstance: CRMInstance;

  @ViewChild('username', {static: true})
  usernameInput: ElementRef;
  public usernameText: string;
  public passwordText: string;

  public rememberPassword: boolean;
  public rememberEmail: boolean;
  public rememberMeCheckbox: any;

  public loginStage: number;

  private readonly HTTP_ERR_500 = 500;
  private readonly HTTP_ERR_401 = 401;
  private readonly HTTP_ERR_400 = 400;
  private readonly HTTP_ERR_403 = 403;
  private readonly HTTP_NO_INTERNET = 0;

  private readonly INVALID_CREDENTIALS = 'ERR_IO_INVALID_INPUT_401';
  private INVALID_CREDENTIALS_MESSAGE: any;
  private readonly PWD_EXPIRED = 'ERR_IO_PWD_EXPIRED_400';
  private PWD_EXPIRED_MESSAGE: any;
  private readonly REFRESH_TOKEN_EXPIRED = 'ERR_IO_REFRESH_TOKEN_EXPIRED_400';
  private REFRESH_TOKEN_EXPIRED_MESSAGE: any;
  private readonly INVALID_EMAIL = 'ERR_IO_TNF_403';
  private INVALID_EMAIL_MESSAGE: any;
  private readonly ACCOUNT_LOCKED = 'ERR_IO_ACCOUNT_LOCKED_400';
  private ACCOUNT_LOCKED_MESSAGE: any;
  private NO_INSTANCES_MESSAGE: any;
  private OFFLINE_ERROR: any;

  private NO_DATABASE_OFFLINE_ERROR: any;
  disableLoginButton = false;
  deviceNetworkServiceReadySubscription: Subscription;
  public region: string = "Global";

  constructor(
    public navCtrl: IonNav,
    public loadingCtrl: LoadingController,
    private readonly logService: LogService,
    private readonly device: DeviceService,
    private readonly events: Events,
    public alertService: AlertService,
    public notificationService: NotificationService,
    public translate: TranslateService,
    private loginService: LoginService,
    private readonly statusBar: StatusBar,
    // private readonly launchDarkly: LaunchDarklyProvider,
    private readonly globalUtilityService: GlobalUtilityService,
    private faService: FeatureActionsService,
    private uiService: UIService,
  ) {
    this.initialMessagesTranslation();
    this.loginStage = 0;
    this.events.subscribe('login:noInstances', () => {
      this.loginErrorMessage = this.NO_INSTANCES_MESSAGE;
    });
    this.translate.instant('LOGIN_FETCH_OMNI_INSTANCES');
  }

  initialMessagesTranslation() {
    this.INVALID_EMAIL_MESSAGE = this.translate.instant('INVALID_EMAIL_MESSAGE');
    this.INVALID_CREDENTIALS_MESSAGE = this.translate.instant('LOGIN_WE_SIGN_YOU_IN');
    this.PWD_EXPIRED_MESSAGE = this.translate.instant('LOGIN_PASSWORD_EXPIRED');
    this.REFRESH_TOKEN_EXPIRED_MESSAGE = this.translate.instant('LOGIN_SESSION_TIMED_OUT');
    this.ACCOUNT_LOCKED_MESSAGE = this.translate.instant('LOGIN_UR_ACCOUNT_LOCKED');
    this.NO_INSTANCES_MESSAGE = this.translate.instant('LOGIN_NO_OMNI_INSTANCE_ASSIGNED');
    this.OFFLINE_ERROR = this.translate.instant('LOGIN_THERE_IS_NO_INTERNET_CONN_DETECTED');
    this.NO_DATABASE_OFFLINE_ERROR = this.translate.instant('LOGIN_NO_OFFLINE_DATA_AND_NO_INTERNET');
  }

  async ngOnInit() {

    localStorage.setItem('isGeneeSpeakerMuted', 'false');

    if (this.device.isNativeApp && this.device.deviceFlags.ios) {
      this.statusBar.styleDefault();
      this.statusBar.backgroundColorByHexString('#065A9B');
    }
    if (this.device.isNativeApp && this.device.deviceFlags.android) {
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#204B8F');
    }
    if (this.loginService.rememberMe) {
      this.usernameText = this.loginService.username;
      this.rememberMeCheckbox = true;
    }
  }

  ngOnDestroy() {
    if (this.deviceNetworkServiceReadySubscription) {
      this.deviceNetworkServiceReadySubscription.unsubscribe();
    }
  }

  /**
   * Calls our authentication data service and posts our login information for a token response
   * Retrieves user info via this authentication token
   *
   * @private
   * @returns {boolean}
   *
   * @memberOf HomePage
   */
  async login() {
    const loading = await this.loadingCtrl.create();
    try {
      this.disableLoginButton = true;
      //Loading spinner
      loading.present();
      //Create our AuthenticationUserRequest object from our Domain & login information
      if (!this.usernameText || this.usernameText.length === 0) {
        this.displayLoginError = true;
        this.disableLoginButton = false;
        this.loginErrorMessage = this.translate.instant('LOGIN_ENTER_USERNAME');
        return;
      }

      if (this.usernameText.toLowerCase() === this.loginService.username?.toLowerCase()) {
        if (await this.loginService.restoreSession()) {
          this.navCtrl.setRoot(HomePage);
          return;
        }
      }


        // this.authenticationOfflineService.shouldTrySyncingDuringAppLaunch = false;
      let loggedIn = await this.loginService.login(this.usernameText, this.rememberMeCheckbox);
if (loggedIn) {
        this.loginStage = 1;
      }
    }
    catch (error) {
      console.error(error);
      if (
        error.status === this.HTTP_ERR_403 &&
        error.error.errorCode === this.INVALID_EMAIL
      ) {
        this.displayLoginError = true;
        this.loginErrorMessage = this.translate.instant('INVALID_EMAIL_MESSAGE');
      }
      if (error.message == "LOGIN_NO_OMNI_INSTANCE_ASSIGNED") {
        this.displayLoginError = true;
        this.loginErrorMessage = this.translate.instant('LOGIN_NO_OMNI_INSTANCE_ASSIGNED');
      }
      if (error.message == "LOGIN_NO_NET_CONNECTIVITY") {
        this.displayLoginError = true;
        this.loginErrorMessage = this.translate.instant('LOGIN_WE_CANT_DETECT_NET');
      }
      if (error.message == "LOGIN_WE_SIGN_YOU_IN") {
        this.displayLoginError = true;
        this.loginErrorMessage = this.translate.instant('LOGIN_WE_SIGN_YOU_IN');
      }
      if (
        ["BrowserAuthError", "CordovaAuthError", "ElectronAuthError"].includes(error.name)
        && (error.errorMessage || error.message)
      ) {
        this.displayLoginError = true;
        this.loginErrorMessage = error.errorMessage || error.message;
      }
      // User canceled auth flow
      if (
        error === 'Canceled'  // Browser
        || ( // Electron
          error?.name.includes('CordovaAuthError')
          && (
            !error.errorMessage // Close auth window
            || error.errorMessage.includes('Token request cannot be made without authorization code or refresh token.') // Back arrow in the auth window
          )
        )
        || error?.errorMessage === 'User cancelled the flow'  // Android
        || error?.errorMessage?.includes('User cancelled the authorization session')  // iOS
      ) {
        this.displayLoginError = true;
        this.loginErrorMessage = this.translate.instant('LOGIN_CANCEL_TOAST_MESSAGE');
      }
    }
    finally {
      this.disableLoginButton = false;
      loading.dismiss();
        }
  }
  
  public async handleInstanceSelection(selection) {
    const loading = await this.loadingCtrl.create();
    try {
      //Loading spinner
      loading.present();
      await this.loginService.selectInstance(selection);
      this.navCtrl.setRoot(HomePage);
      return true;
        }
    catch (error) {
      console.log('error from refresh itself', error);
      this.logService.logError('Error refreshing token', error);
      this.notificationService.notify(this.translate.instant('LOGIN_UR_AUTHORIZATION_EXPIRED'),
        'Login Service', 'top', '', 3000, true);
      setTimeout(() => {
        this.loginService.logout();
                return false;
      }, 3000);
    } finally {
loading.dismiss();
    }
  }

  // public onLoginBlur() {
  //   this.usernameInput.nativeElement.value = this.usernameInput.nativeElement.value.trim();
  // }

  onErrorMsgClose() {
    this.displayLoginError = false;
  }

  async openPrivacyPopover() {
    this.alertService.showModal(AboutDetailsComponent,{ aboutTitle: 'PRIVACY_POLICY' });
   }
  openTerms() {
    this.alertService.showModal(AboutDetailsComponent,{ aboutTitle: 'TERMS_AND_CONDITIONS' });
  }
  openCopyright() {
    return;
    //this.alertService.showModal(AboutDetailsComponent,{ aboutTitle: 'COPYRIGHT_INFO' });
  }

}
