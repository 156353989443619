import { Injectable } from '@angular/core';
import { Endpoints } from '../../../config/endpoints.config';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME } from '../../services/disk/disk.service';
import { HttpClient } from '@angular/common/http';
import { PresentationService } from '../../services/presentation/presentation.service';
import { Presentation } from '../../classes/presentation/presentation.class';
import { fileState } from '../../store/application.state';
import { Store } from '@ngrx/store';
import * as FileAction from '../../store/io-file-service/actions/file.actions';
import { DeviceService } from '../../services/device/device.service';
import { LogService } from '../../services/logging/log-service';
import { AuthenticationService } from '../../services/authentication.service';
import { IoFileService } from '../../services/io-file-service/io-file-service';
import {MyAssistantService, NOTIFICATION} from '../../services/my-assistant/my-assistant.service';
import { NotificationService } from '../../services/notification/notification.service';
import { EntitySyncInfo, EntityNames, DeltaService } from '../delta/delta.service';
import { DB_SYNC_STATE_KEYS, DB_KEY_PREFIXES, DB_ALLDOCS_QUERY_OPTIONS } from '../../config/pouch-db.config';
import { TrackAction } from '../../utility/common-enums';
import { TranslateService } from '@ngx-translate/core';
import { SyncFeatureCategory } from '../../enums/delta-service/delta-service.enum';
import {IndNotificationDataModel} from "@omni/models/indNotificationDataModel";
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import { RetrieveMultipleResponse } from 'dynamics-web-api';
import { Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { EntityTag, TagEntityType, TagStateCode, UserTag, UserTagService } from '@omni/services/user-tag/user-tag.service';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { isEmpty, uniqBy } from 'lodash';
import moment from 'moment';
import { CONTACT_FETCH_QUERIES } from '@omni/config/fetch-xml/contact-fetchXMLs';
import { LocalizationService } from '@omni/services/localization/localization.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { PresentationUserTag } from '@omni/classes/short-call/short-call.class';
import { FeatureActionsService } from '@omni/services/feature-actions/feature-actions.service';

/**
 * Data service for Presentation
 *
 * @export
 * @class PresentationDataService
 */
@Injectable({
  providedIn: 'root'
})
export class PresentationDataService {

    private _isInitialMappingDone:boolean = false;
    private presentationsNotificationModel: IndNotificationDataModel;
    private referenceFetchSignal: Subject<any> = new Subject<any>();

    constructor(
        private disk: DiskService,
        private http: HttpClient,
        private presentationService: PresentationService,
        private store: Store<fileState>,
        private deviceService: DeviceService,
        private logService: LogService,
        private authService: AuthenticationService,
        private ioFileService: IoFileService,
        private assistantService: MyAssistantService,
        private notificationService: NotificationService,
        private authenticationService: AuthenticationService,
        private deltaService: DeltaService,
        private dynamicsService: DynamicsClientService,
        public translate:TranslateService,
        private readonly userTagService: UserTagService,
        public languageService:LocalizationService,
        private authenticationOfflineService: AuthenticationService,

        private faService: FeatureActionsService,
        
    ) {
      // Debounced reference fetch request
        // TODO: Wrap around with FA check?
        this.referenceFetchSignal.pipe(
          debounceTime(400),
          map(async ({ presentationId, pageIdx, pageId }) => {
            const references = await this.fetchPageReferences(pageId);
            this.presentationService.addReferencesToCache(presentationId, pageIdx, pageId, references);
            this.presentationService.setPageReferences(JSON.parse(JSON.stringify(references)));
            this.presentationService.currentReferenceChangedSignal.next({ presentationId, pageIdx, pageId, references, });
            this.presentationService.setIsReferenceFetching(false);
          })
        ).subscribe();
    }

    /**
     * Returns an array of presentation from either our disk or network depending on cache expiry time
     *
     * @memberof PresentationDataService
     */
    async getPresentation(forceNetwork?: boolean, loadFromDbOnly = false) {
      this.deltaService.pushSyncEntityName(SyncFeatureCategory.files);
        if (loadFromDbOnly) {
            await this.getOfflinePresentation();
        } else {
        let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_PRESENTATION);
        const isInitialSync = !syncState || !syncState.lastUpdatedTime || this.authService.shouldContentFullSync;

        let params = {positionIds: this.authService.user.positions.map((o)=>{
            return o.ID
        }), 
        buId : this.authService.user.buConfigs.indskr_bulevelconfigurationid
        }
        console.log( this.authService.user.buConfigs.indskr_bulevelconfigurationid);
        let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.presentations.GET_PRESENTATIONS + "?positionIds=" + params.positionIds + "&bulevelconfigurationIds=" + params.buId;
        // if(isInitialSync){
        //     url = url +"?positionIds="+params.positionIds;
        // }else{
        //     url = url +"?positionIds="+params.positionIds+ '&lastUpdatedTime=' + syncState.lastUpdatedTime;
        // }
        url += (isInitialSync) ? '' :'&lastUpdatedTime=' + syncState.lastUpdatedTime ;

        let response: any;
        let headers = Endpoints.presentations.GET_PRESENTATIONS_AGGREGATION_HEADERS;
        headers.headers.set('Sync-Service', 'true');
        const presentationSyncInfo: EntitySyncInfo = {
            entityName: EntityNames.presentation,
            totalFailed: 0,
            totalSynced: 0,
            errors: [],
            syncStatus: true
        };
        try {
            // response = await this.http.get(url, headers).toPromise();
            response = await this.http.get(url, headers).toPromise();
        } catch (error) {
            this.deltaService.addSyncErrorToEntitySyncInfo(presentationSyncInfo, url, error);
        }

        if (response) {
            if(!this._isInitialMappingDone){
                try{
                  let offlinepresentation = await this.disk.retrieve('offlinepresentation', true); // load this first to set downloaded status
                    if(offlinepresentation) {
                        this.mapOfflinePresentation(offlinepresentation.offlinepres)
                    };
                    let presentation = await this.disk.retrieve(DB_KEY_PREFIXES.PRESENTATION);
                    if(presentation) {
                        this.mapPresentation(presentation.raw, true);
                    }
                    this._isInitialMappingDone = true;
                } catch (error) {
                    console.warn('Error reading presentations data from offline db');
                }
            }
            const newLastUpdatedTime = new Date().getTime();
            if(isInitialSync){
                this.mapPresentation(response);
            }else{
                this.mapDeltaPresentation(response);
            }


            // Done sync. Update sync state.
            if (presentationSyncInfo.syncStatus) {
                syncState.lastUpdatedTime = newLastUpdatedTime;
                presentationSyncInfo.totalSynced = response.length;
                await this.disk.updateSyncState(syncState);
            }
            this.deltaService.addEntitySyncInfo(presentationSyncInfo);

            this.disk.retrieve(DB_KEY_PREFIXES.PRESENTATION_RECENT_SEARCHES, true).then((doc)=>{
                if(doc && doc.raw){
                  this.presentationService.recentSearches = doc.raw
                }
                else {
                  this.presentationService.recentSearches = [];
                }
              });
            this.disk.retrieve(DB_KEY_PREFIXES.CONTENT_RECENT_SEARCHES, true).then((doc)=>{
              if(doc && doc.raw){
                this.presentationService.contentRecentSearches = doc.raw
              }
              else {
                this.presentationService.contentRecentSearches = [];
              }
            });
        }
        }

        // For offline presentation favourite data count tracking...
        this.checkOfflineDataExists();
    }

    async getOfflinePresentation() {

        // if (this.disk.check('offlinepresentation')) {
            let offlinePresentation = await this.disk.retrieve('offlinepresentation');
            if(offlinePresentation) this.mapOfflinePresentation(offlinePresentation.offlinepres);
        // }

        //if (this.disk.check('presentations')) {
            let presentation = await this.disk.retrieve(DB_KEY_PREFIXES.PRESENTATION);
            if(presentation) this.mapPresentation(presentation.raw, true);

            this._isInitialMappingDone = true;
        //}

        // For offline presentation favourite data count tracking...
        this.checkOfflineDataExists();
    }

    async favouritePresentation(presArray: Presentation[]) {

        let payloadFav = [];
        let payloadDel = [];
        let presFav: Presentation[] = [];
        let presDel: Presentation[] = [];
        for (let pres of presArray) {
            if(!pres.favourite) {
                presFav.push(pres);
                payloadFav.push({'indskr_iopresentationid': pres.ioPresentationId});
            }
            else {
                presDel.push(pres);
                payloadDel.push({'indskr_iopresentationid': pres.ioPresentationId});
            }
        }

        let headers = Endpoints.presentations.FAVOURITE_PRESENTATION_HEADER;
        headers.headers = headers.headers.set('X-SystemUserId', this.authService.user.systemUserID);

        if(this.deviceService.isOffline) {
            /**
             * Favourite presentation offline then sync it when user goes online
             */
            let presentation = await this.disk.retrieve('offlinefavourites', true);
            let tempPres: Presentation[] = [];
            if(presentation) {
                for ( let x of presentation.raw) {
                    tempPres.push(x);
                }
                presArray = [...tempPres, ...presArray];
            }

            try {
                await this.disk.updateOrInsert('offlinefavourites', (doc) => {
                    return { raw: presArray };
                });

                // Track offlin data count
                this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PRESENTATION_FAVORITE, presArray.length);

                if(presFav && presFav.length > 0) {
                    this.presentationService.updatePresentationList(presFav, "favourite", true);
                }
                if(presDel && presDel.length > 0 && presFav.length == 0) {
                    this.presentationService.updatePresentationList(presDel, "favourite", false);
                }
            } catch (error) {
                console.log("favouritePresentation: error saving offline presentation", error);
            }
        } else {
            console.log("online stuff");
            if(payloadFav && payloadFav.length > 0) {
                this.presentationService.updatePresentationList(presFav, "favourite", true);
                await this.http.post(this.authenticationService.userConfig.activeInstance.entryPointUrl +
                                Endpoints.presentations.FAVOURITE_PRESENTATION, payloadFav,
                                headers)
                .toPromise()
                .then((res) => {
                    console.log('result', res)
                })
                .catch(() => {
                    this.presentationService.updatePresentationList(presFav, "favourite", false);
                });
            }
            if(payloadDel && payloadDel.length > 0) {

                this.presentationService.updatePresentationList(presDel, "favourite", false);
                await this.http.request('delete', this.authenticationService.userConfig.activeInstance.entryPointUrl +
                    Endpoints.presentations.FAVOURITE_PRESENTATION, {
                    body: payloadDel
                 })
                .toPromise()
                .then(() => {})
                .catch(() => {
                    this.presentationService.updatePresentationList(presDel, "favourite", true);
                });
            }
        }
    }

    public async mapDeltaPresentation(raw:any) {
       let dbPresentations = await this.getPresetationsFromDB();

       let notifPresentations: Presentation[] = [];

        if (raw && Array.isArray(raw)) {
            if(raw.length > 0) {
              for (let i = 0; i < raw.length; i++) {
                  const rawPresentation = raw[i];
                  if (rawPresentation.track_action && rawPresentation.track_action == TrackAction.Deleted) {
                      const idx = this.presentationService.initialPres.findIndex(a => a.ioPresentationId == rawPresentation.indskr_iopresentationid);
                      const ipdx = this.presentationService.presentation.findIndex(a => a.ioPresentationId == rawPresentation.indskr_iopresentationid);
                      const dbIdx = dbPresentations.length > 0 ? dbPresentations.findIndex(a => a.indskr_iopresentationid == rawPresentation.indskr_iopresentationid) : -1;
                      const offdbidx = this.presentationService.offlinePres.findIndex(a => a.ioPresentationId == rawPresentation.indskr_iopresentationid);
                      if(idx >= 0) {
                          this.presentationService.initialPres.splice(idx,1);
                      }
                      if(ipdx >= 0){
                          this.presentationService.presentation.splice(ipdx,1);
                      }
                      if (offdbidx >= 0) {
                          await this.deleteDownloadedPresentation(rawPresentation.indskr_iopresentationid);
                      }
                      if (dbIdx >= 0) {
                          dbPresentations.splice(dbIdx, 1);
                      }
                  }
                  else if (!isNaN(parseInt(rawPresentation.statecode)))
                  {


                      const idx = this.presentationService.initialPres.findIndex(a => a.ioPresentationId == rawPresentation.indskr_iopresentationid);
                      const ipdx = this.presentationService.presentation.findIndex(a => a.ioPresentationId == rawPresentation.indskr_iopresentationid);
                      const dbIdx = dbPresentations.length > 0 ? dbPresentations.findIndex(a => a.indskr_iopresentationid == rawPresentation.indskr_iopresentationid) : -1;
                      const offdbidx = this.presentationService.offlinePres.findIndex(a => a.ioPresentationId == rawPresentation.indskr_iopresentationid);
                      if (idx >= 0) {
                          // Update
                          if (parseInt(rawPresentation.statuscode) == 1) {
                              // Active
                              const presentation = new Presentation(rawPresentation);
                              this.presentationService.mapPresentationFieldsToSearchIndex(presentation);
                              if(rawPresentation['isNew']) {
                                this.deltaService.deltaRecordsDTO.presentations.push(rawPresentation.indskr_iopresentationid);
                              }
                              if(offdbidx >= 0){
                                  presentation.downloaded = true;
                              }
                              if (dbIdx >= 0) {
                                  dbPresentations[dbIdx] = rawPresentation;
                                  // To do
                                  // Updating the dowloaded presentation on disk if only some content of presentation is updated
                              } else {
                                  // Shouldn't fall here..
                                  console.warn('mapDeltaSyncedPresentation: Could not find a record from db.. Cannot Replace ', rawPresentation);
                              }
                              this.presentationService.initialPres[idx] = presentation;
                              if(ipdx === -1) {
                                this.presentationService.presentation.push(presentation);
                              } else {
                                this.presentationService.presentation[ipdx] = presentation;
                              }
                          } else if (parseInt(rawPresentation.statuscode) !== 1) {
                              // Removed / Inactive
                              if(idx >= 0) {
                                  this.presentationService.initialPres.splice(idx,1);
                              }
                              if(ipdx >= 0) {
                                  this.presentationService.presentation.splice(ipdx,1);
                              }
                              if (offdbidx >= 0) {
                                  await this.deleteDownloadedPresentation(rawPresentation.indskr_iopresentationid);
                              }
                              if (dbIdx >= 0) {
                                  dbPresentations.splice(dbIdx, 1);
                              } else {
                                  // Shouldn't fall here..
                                  console.warn('mapDeltaSyncedPresentation: Could not find a record from db.. Cannot Remove ', rawPresentation);
                              }
                          } else {
                              console.warn('mapDeltaSyncedPresentation: Invalid statuscode ' + parseInt(rawPresentation.statuscode), rawPresentation.indskr_ioresourceid);
                          }
                      } else {
                          // New
                          //If any presentation added, add these records for notification
                          if(rawPresentation.statecode == 0) {
                              // rawPresentation['isNew'] = true;
                              // this.deltaService.deltaRecordsDTO.presentations.push(rawPresentation.indskr_iopresentationid);
                          }
                          if (parseInt(rawPresentation.statuscode) == 1) {
                              //const presentation = new Presentation(rawPresentation);
                              dbPresentations.push(rawPresentation);
                              rawPresentation['isNew'] = true;
                              this.deltaService.deltaRecordsDTO.presentations.push(rawPresentation.indskr_iopresentationid);
                              this.presentationService.initialPres.push(new Presentation(rawPresentation));
                              this.presentationService.presentation.push(new Presentation(rawPresentation));
                              notifPresentations.push(new Presentation(rawPresentation));
                          }
                          this.presentationService.mapPresentationFieldsToSearchIndex(new Presentation(rawPresentation));
                      }
                  }

                  // Call the function
                  const result = this.findValue(this.userTagService.PresentationTags, rawPresentation.indskr_iopresentationid);

                  //remove the presentation id fom tag
                  result.forEach(v=> {
                    if (rawPresentation['userTags']) {
                      let index = rawPresentation.userTags.findIndex(c=>c.indskr_usertagid==v.indskr_usertagid)
                      if(index<0){
                        console.log("index",v);
                        let d = v.entityRecords.findIndex(c=>c.id ==rawPresentation.indskr_iopresentationid);
                        console.log("id index",d);
                        v.entityRecords.splice(d,1);
                        
                        console.log("v",v);
                      }
                    }
                   } )
              }

              this.presentationService.setDownloadedPres();
              //Save to DB or update
              await this.saveOrUpdatePresToDB(dbPresentations);
            } else {
               //display offline saved new accouns which were not visited bfr
              if(dbPresentations && Array.isArray(dbPresentations)) {
                let newPres = dbPresentations.filter((offlinePres) =>{
                    return offlinePres.isNew;
                });
                if(newPres && newPres.length > 0 ){
                  console.log("Not visited new presentations", newPres);
                  newPres.forEach(pres => {
                    this.deltaService.deltaRecordsDTO.presentations.push(pres['indskr_iopresentationid']);
                  });
                }
              }


            }
        }

      if (notifPresentations.length) {
        let displayName = notifPresentations.length == 1 ? this.translate.instant("PRESENTATION") : this.translate.instant("PRESENTATIONS");
        if (this.languageService.selectedLanguage.code == "ja") displayName = this.translate.instant("PRESENTATIONS");
        let showCount = notifPresentations.length == 1 ? (this.languageService.selectedLanguage.code == "ja" ? 1 : '') : notifPresentations.length;
        this.presentationsNotificationModel = {
          type: NOTIFICATION.NEW_PRESENTATIONS_NOTIFICATION,
          name: this.translate.instant("NEW_PRESENTATIONS_NOTIFICATION", {count: showCount, presentation: displayName}),
          DateTime: Date.now(),
          id: NOTIFICATION.NEW_PRESENTATIONS_NOTIFICATION + Date.now(),
          data: {
            data: notifPresentations
          },
          icon: 'assets/imgs/tools_presentations.svg',
          isRed: false,
          params: {count: showCount, presentation: displayName}
        }
        this.assistantService.saveNotificationToDisk(this.presentationsNotificationModel);
      }
    }
   // Function to find the value in the data structure
  findValue(data, targetId) {
    const foundEntity = data.filter(item =>
        item.entityRecords.some(entityRecord => entityRecord.id === targetId)
    );

    return foundEntity ? foundEntity : null;
  }
  public async getPresetationsFromDB() {
    let dbPresentations = [];
    try {
      let dbPresentationsDoc: any = await this.disk.retrieve(DB_KEY_PREFIXES.PRESENTATION);
      if (dbPresentationsDoc && dbPresentationsDoc.raw && Array.isArray(dbPresentationsDoc.raw)) {
        dbPresentations = dbPresentationsDoc.raw;
      }
    }
    catch (error) {
      console.warn('mapDeltaSyncedPresentations: No data to load');
    }
    return dbPresentations;
  }

  public async saveOrUpdatePresToDB(dbPresentations: any[]) {
    if (dbPresentations.length > 0) {
      try {
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.PRESENTATION, (doc) => {
          if (!doc || !doc.raw) {
            doc = {
              raw: []
            };
          }
          doc.raw = dbPresentations;
          return doc;
        });
      }
      catch (error) {
        console.error('mapFullSyncedResources: ', error);
      }
    }
  }

    public async mapPresentation(raw: any, doNotSave?: boolean) {
        if (Array.isArray(raw)) {
            raw = raw.filter(pre => pre.statecode === 0 && pre.statuscode === 1);
            this.presentationService.refreshPresentationList(raw);
            // for (let i = 0; i < downloadedPresIdsToBeRemoved.length; i++) {
            //     const id = downloadedPresIdsToBeRemoved[i];
            //     await this.deleteDownloadedPresentation(id);
            // }
        } else {
            console.error('Unsupported data format for presentations!: ', raw);
        }

        if (!doNotSave) {
            //Save to DB or update
            try {
                await this.disk.updateOrInsert(DB_KEY_PREFIXES.PRESENTATION, doc => ({raw: raw}));
                this.logService.logInfo("Successfully saved presentation");
            } catch (error) {
                this.logService.logError("Failed saving presentation");
            }
        }
    }

    public savePresForOffline(presId: string) {
        let presOffline: any = {};
        let pres = this.presentationService.getPresObject(presId);

        if(pres) {
            presOffline.ioPresentationId = pres.ioPresentationId;
            presOffline.name = pres.name;
            presOffline.downloaded = true;
            presOffline.downloadDate = new Date();
        }

        this.presentationService.offlinePres.push(presOffline);

        this.disk.retrieve('offlinepresentation').then(presentations =>{

            let presArray = [];
            if(presentations) {
               presentations.offlinepres.push(presOffline);
               presArray = presentations.offlinepres;
            } else {
                presArray = [presOffline];
            }

            this.disk.updateOrInsert('offlinepresentation', doc => ({ offlinepres: presArray }));
            this.store.dispatch(new FileAction.downloadFileDequeue());
            this.presentationService.updatePresentationList([pres], "downloaded", true);

        }).catch (diskError => {

            this.store.dispatch(new FileAction.downloadFileDequeue());
            console.error('Caught disk error trying to load presentations from disk', diskError);
        });


        // this.assistantService.addNewNotification(pres.name + this.translate.instant('PRESENTATION_DOWNLODING_FINISHED'));
        this.notificationService.notify(pres.name +this.translate.instant('PRESENTATION_DOWNLODING_FINISHED'), "Presentation Service")
    }

    public async deleteDownloadedPresentation(presId: string) {

        let pres = this.presentationService.getPresObject(presId);
        if (pres) {
        pres.downloaded = false;
        pres.downloadDate = null;
        }
        let tempPres = this.presentationService.getOfflinePresObject(presId);
        if (tempPres) {
            let index = this.presentationService.offlinePres.indexOf(tempPres);
            this.presentationService.offlinePres.splice(index, 1);
        }

        try {
            await this.ioFileService.deleteDownloadedPres(presId);
            await this.disk.updateOrInsert('offlinepresentation', doc => ({ offlinepres: this.presentationService.offlinePres }))
        } catch (error) {}
        if (pres) {
            this.presentationService.updatePresentationList([pres], "downloaded", false);
        }

    }

    public mapOfflinePresentation(raw: any) {
        if (Array.isArray(raw)) {
            this.presentationService.refreshOfflinePresentationList(raw);
        } else {
            console.error('Unsupported data format for presentations!');
        }
    }

    public async updatePresentationRecord(favPres: Presentation[]) {
        if(!favPres || !favPres.length) return;

        let presentation = await this.disk.retrieve(DB_KEY_PREFIXES.PRESENTATION, true);
        if(!presentation) return;

        let presList = presentation.raw;
        let fp: Presentation;
        if(presList && Array.isArray(presList)) {

            for (fp of favPres) {
                let foundPres = presList.find(pres => {
                    return pres.indskr_iopresentationid == fp.ioPresentationId;
                })
                foundPres.favourite = fp.favourite;
            }
        }

        try {
            await this.disk.updateOrInsert(DB_KEY_PREFIXES.PRESENTATION, doc => ({raw: presList}));
            this.logService.logInfo("Successfully saved presentation");
        } catch (error) {
            this.logService.logError("Failed saving presentation");
        }
    }

    public async syncOfflineFavourites():Promise<any> {
        try {
            let presentation = await this.disk.retrieve('offlinefavourites', true);
            if(!presentation || (!presentation.raw && presentation.raw.length < 0)) return 'done';
            await this.favouritePresentation(presentation.raw).then((res)=>{
                this.disk.wasThereOfflineDataUpload = true;
            })
            await this.disk.remove('offlinefavourites');
            this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PRESENTATION_FAVORITE, 0);
            return 'done'
        }
        catch {
            console.log("Error getting offline presentation favourites");
            return 'done with error';
        }
    }

    public async checkOfflineDataExists(): Promise<boolean> {
        const favs = await this.disk.retrieve('offlinefavourites', true);
        const offlineDataCount = favs && Array.isArray(favs.raw) ? favs.raw.length : 0;
        this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.PRESENTATION_FAVORITE, offlineDataCount);
        return offlineDataCount > 0 ? true: false;
    }

    // Signal reference fetch
    public getPageReferences(presentationId: string, pageIdx: number, pageId: string): Promise<any> {
      this.presentationService.setIsReferenceFetching(true);
      this.presentationService.setPageReferences([]);
      this.presentationService.currentReferenceChangedSignal.next({ presentationId, pageIdx, pageId, references: [], });

      const references = this.presentationService.getReferencesFromCache(presentationId, pageIdx)?.references || null;
      if (references === null) {
        this.referenceFetchSignal.next({ presentationId, pageIdx, pageId });
        return;
      }
      this.presentationService.setPageReferences(JSON.parse(JSON.stringify(references)));
      this.presentationService.currentReferenceChangedSignal.next({ presentationId, pageIdx, pageId, references, });
      this.presentationService.setIsReferenceFetching(false);
    }

    public async fetchPageReferences(pageId: string): Promise<any> {
      let response: RetrieveMultipleResponse<any>;
      const entityName = 'indskr_referenceses';
      const select = [
        'createdon',
        '_createdby_value',
        '_ownerid_value',
        'indskr_referencesid',
        'indskr_displayname',
        'indskr_name',
        'indskr_marker',
        '_indskr_document_value',
        'indskr_documentpagenumber',
        'indskr_url',
        'statecode',
      ];
      const filter = `_indskr_page_value eq ${pageId} and statecode eq 0`;
      const orderBy = [`createdon desc`];

      try {
        response = await this.dynamicsService.retrieveAllRequest({
          collection: entityName,
          select,
          filter,
          orderBy,
        });
      } catch (error) {
        console.error('getPageReferences: ', error);
      }

      return response?.value || [];
    }

  async syncPresentationTag(loadFromDbOnly = false) {
    let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
    if (offlineFallback) {
      await this.loadPresentationTagFromDB();
    } else {
      const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_PRESENTATION_TAG);
      const isInitialSync = !syncState || !syncState.lastUpdatedTime;
      let lastUpdatedTime = syncState?.lastUpdatedTime;
      const presentationTagsSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.presentation,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };
      if (isInitialSync) {
        // await this.presentationTagInitialSync();
      } else {
        // await this.presentationTagDeltaSync(lastUpdatedTime)
      }
      const newLastUpdatedTime = new Date().getTime().toString();
      syncState.lastUpdatedTime = newLastUpdatedTime;
      await this.disk.updateSyncState(syncState);
      if (Array.isArray(this.userTagService.PresentationTags)) {
        presentationTagsSyncInfo.totalSynced = this.userTagService.PresentationTags.length;
      }
      this.deltaService.addEntitySyncInfo(presentationTagsSyncInfo);
    }
  }

  // async presentationTagDeltaSync(lastUpdatedTime: any) {
  //   await this.loadPresentationTagFromDB();
  //   const positionIds = this.authService.user.positions.map(o => {
  //     return o.ID
  //   });
  //   let positionString = '';
  //   positionIds.forEach(p => {
  //     positionString += '<value>' + p + '</value>';
  //   })
  //   let PrivateTagfetchXml = fetchQueries.presentation.updatedPresentationPrivateTags;
  //   let publicTagfetchXml = fetchQueries.presentation.updatedPresentationPublicTags;
  //   let positionTag = fetchQueries.presentation.updatedTagsForPosition;
  //   publicTagfetchXml = publicTagfetchXml.replace('{busUnitID}', `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`);
  //   positionTag = positionTag.replace(/{positionIDs}/g, `${positionString}`);
    
  //   lastUpdatedTime = moment(parseInt(lastUpdatedTime)).format('YYYY-MM-DD HH:mm:ss');
  //   PrivateTagfetchXml = PrivateTagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime);
  //   publicTagfetchXml = publicTagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime);
  //   positionTag = positionTag.replace('{lastUpdatedTime}', lastUpdatedTime);
  //   let deletedUserTagsFetchXml = CONTACT_FETCH_QUERIES.deletedUserTagsFetchXml;
  //   deletedUserTagsFetchXml = deletedUserTagsFetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
  //   await Promise.all([
  //     this.dynamicsService.executeFetchQuery('indskr_usertags', PrivateTagfetchXml),
  //     this.dynamicsService.executeFetchQuery('indskr_usertags', publicTagfetchXml),
  //     this.dynamicsService.executeFetchQuery('indskr_usertags', positionTag),
  //     this.dynamicsService.executeFetchQuery('indskr_trackchanges', deletedUserTagsFetchXml)
  //   ]).then(response => {
  //     let privateTag = response[0];
  //     let publicTag = response[1];
  //     let buTag = response[2];
  //     let updatePresentationTags = privateTag.concat(publicTag,buTag);
  //     updatePresentationTags.forEach(record => {
  //       if (!record['indskr_externalid']) {
  //         record['indskr_externalid'] = record['indskr_usertagid'];
  //       }
  //     });
  //     let deletedPresentationTags = response[3];
  //     if (!isEmpty(deletedPresentationTags)) {
  //       deletedPresentationTags.forEach(async trackChangedTag => {
  //         let index = this.userTagService.accountTags
  //           .findIndex(tag => (tag.indskr_usertagid === trackChangedTag['indskr_usertagid']));
  //         if (index >= 0) {
  //           await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + this.userTagService.accountTags[index].indskr_externalid);
  //           this.userTagService.accountTags.splice(index, 1);
  //         }
  //       });
  //     }
  //     if (!isEmpty(updatePresentationTags)) {
  //       let presentationIdList = this.getpresentationIdsForTagIds(updatePresentationTags)
  //       let presentationTagDetails: UserTag[] = updatePresentationTags.map((tag) => {
  //         return new UserTag(tag.indskr_externalid, tag.indskr_usertagid, tag.deleted, tag.indskr_name, presentationIdList[tag.indskr_usertagid], false, tag.statecode, TagEntityType.PRESENTATION, tag.indskr_visibility,tag.indskr_filter)
  //       });
  //       presentationTagDetails = uniqBy(presentationTagDetails, 'indskr_usertagid');
  //       presentationTagDetails.forEach(async (tagDetails) => {

  //         const checkTagIndex = this.userTagService.PresentationTags.findIndex(tag => tag.indskr_externalid === tagDetails.indskr_externalid)
  //         // if (checkTagIndex > -1) {
  //         //   this.userTagService.PresentationTags.splice(checkTagIndex, 1);
  //         // }
  //         // if Tag data is updated with presentation
  //         this.userTagService.PresentationTags[checkTagIndex]= null
  //         if (tagDetails.stateCode === TagStateCode.Active) {
  //           // this.userTagService.PresentationTags.push(tagDetails);
  //           this.userTagService.PresentationTags[checkTagIndex] = tagDetails;
  //           await this.disk.upsertUserTag(tagDetails, TagEntityType.PRESENTATION);
  //         } else {
  //           await this.disk.remove(DB_KEY_PREFIXES.USER_TAG + tagDetails.indskr_externalid);
  //         }
  //       });
  //       this.userTagService.PresentationTags = this.userTagService.sortUserTags(this.userTagService.PresentationTags);
  //       this.userTagService.PresentationTags = uniqBy(this.userTagService.PresentationTags,"indskr_externalid")
  //     }
  //   })
  //     .catch((e) => { console.error('uploadOfflineData: ', e); return false; });
  // }

  // async presentationTagInitialSync() {
  //   try {
  //     const positionIds = this.authService.user.positions.map(o => {
  //       return o.ID
  //     });
  //     let positionString = '';
  //     positionIds.forEach(p => {
  //       positionString += '<value>' + p + '</value>';
  //     })
  //     await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_USER_TAG);
  //     let PrivateTagfetchXml = fetchQueries.presentation.fetchPresentationPrivateTags;
  //     let publicTagfetchXml = fetchQueries.presentation.fetchPresentationPublicTags;
  //     let buTagfetchXml = fetchQueries.presentation.fetchPresentationBuTags;
  //     let positonTagfetchXml = fetchQueries.presentation.fetchAssignedAccountTagsForPosition;
  //     publicTagfetchXml = publicTagfetchXml.replace('{busUnitID}', `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`);
  //     buTagfetchXml = buTagfetchXml.replace('{busUnitID}', `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`);
  //     positonTagfetchXml = positonTagfetchXml.replace(/{positionIDs}/g, `${positionString}`);
  //     await Promise.all([
  //       this.dynamicsService.executeFetchQuery('indskr_usertags', PrivateTagfetchXml),
  //       this.dynamicsService.executeFetchQuery('indskr_usertags', publicTagfetchXml),
  //       this.dynamicsService.executeFetchQuery('indskr_usertags', buTagfetchXml),
  //       this.dynamicsService.executeFetchQuery('indskr_usertags', positonTagfetchXml),
  //     ]).then(async response => {
  //       let privateTag = response[0];
  //       let publicTag = response[1];
  //       let buTag = response[2];
  //       let position = response[3];
  //       const presentationTagDetailsResponse = privateTag.concat(publicTag,buTag,position)
  //       presentationTagDetailsResponse.forEach(record => {
  //         if (!record['indskr_externalid']) {
  //           record['indskr_externalid'] = record['indskr_usertagid'];
  //         }
  //       });
  //       let presentationIdList = this.getpresentationIdsForTagIds(presentationTagDetailsResponse)
  //       let presentationTagDetails = presentationTagDetailsResponse.map((tag) => {
  //         return new UserTag(tag.indskr_externalid, tag.indskr_usertagid, tag.deleted, tag.indskr_name,
  //           presentationIdList[tag.indskr_usertagid], false, TagStateCode.Active, TagEntityType.PRESENTATION, tag.indskr_visibility , tag.indskr_filter)
  //       });
  //       presentationTagDetails = uniqBy(presentationTagDetails, 'indskr_usertagid');
  //       this.userTagService.PresentationTags = presentationTagDetails;
  //       this.userTagService.PresentationTags = this.userTagService.sortUserTags(this.userTagService.PresentationTags);
  //       this.userTagService.PresentationTags = uniqBy(this.userTagService.PresentationTags,"indskr_externalid")
  //       await this.disk.upsertUserTagListToDisk(presentationTagDetails)
  //     })
  //   }
  //   catch (e) {
  //     console.log(e);
  //   };
  // }

  private getpresentationIdsForTagIds(updatePresentationTags: any[]) {
    return updatePresentationTags.reduce((data, val) => {
      data[val.indskr_usertagid] = data[val.indskr_usertagid] || [];
      let presentationId = new EntityTag(val["presentationId"])
      if (presentationId) {
        if (presentationId.id) {
          data[val.indskr_usertagid].push(presentationId)
        }
      } else if (!data[val.indskr_usertagid].length) {
        data[val.indskr_usertagid] = []
      }
      return data;
    }, {});
  }

  private async loadPresentationTagFromDB() {
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_USER_TAG).then((data: UserTag[]) => {
      //check for delete true
      this.userTagService.PresentationTags = data.filter(a => a.entity == TagEntityType.PRESENTATION);
    });
  }

  public async getPresentaionUserTagHierarchy(loadFromDbOnly = false) {
    if (this.faService.isShortCallLauncherEnabledInMobileDevice) {
      this.presentationService.userTagData = { parentTags: [], presentationTags: [] };
      let offlineFallback: boolean = this.deviceService.isOffline || loadFromDbOnly;
      try {
        if (offlineFallback) {
          await this.presentationService.loadOfflinePresentaionUserTagHierarchy();
        } else {
          let userTagsfetchXml = fetchQueries.fetchPresentationUserTagsHierarchy;
          userTagsfetchXml = userTagsfetchXml.replace('{businessUnitId}', `${this.authService.user.xBusinessUnitId}`);
          await this.dynamicsService.executeFetchQuery('indskr_usertags', userTagsfetchXml).then(async (usertags: PresentationUserTag[]) => {
            console.log('User tags Response: ', usertags);
            this.presentationService.mapPresentationUserTagGgroupData(usertags);
          }), async (error) => {
            this.presentationService.loadOfflinePresentaionUserTagHierarchy();
          }
        }
      } catch (error) {
        console.log('Error occured while fetching UserTagHierarchy: ' + error);
      }
    }
  }


}
