<ind-section-header [viewData]="detailHeaderModel"></ind-section-header>
<ind-form-field [viewData]="getNameFormField()"></ind-form-field>
<ind-form-field [viewData]="getAccountsFormField()"></ind-form-field>
<ind-form-field [viewData]="getContactFormField()"></ind-form-field>
<ind-form-field [viewData]="getProductFormField()"></ind-form-field>
<ind-datetime-form [viewData]="getStartPeriodFormField()"></ind-datetime-form>
<ind-datetime-form [viewData]="getEndPeriodFormField()"></ind-datetime-form>
<ind-form-field [viewData]="getEstimatedRevenueFormField()"></ind-form-field>
<ind-form-field [viewData]="getActualRevenueFormField()"></ind-form-field>
<ind-form-field [viewData]="getCurrencyFormField()"></ind-form-field>
<ind-form-field [viewData]="getBudgetFormField()"></ind-form-field>
<ind-form-field [viewData]="getParentMarketingBusinessPlanField()"></ind-form-field>
<ind-form-field *ngIf="getPrimarySpecialtyFormField()" [viewData]="getPrimarySpecialtyFormField()"></ind-form-field>
<ind-form-field *ngIf="getSubSpecialtyFormField()" [viewData]="getSubSpecialtyFormField()"></ind-form-field>
<ind-form-field *ngIf="getFocusAreaFormField()" [viewData]="getFocusAreaFormField()"></ind-form-field>
<ind-form-field *ngIf="getTypeFormField()" [viewData]="getTypeFormField()"></ind-form-field>
<ind-form-field *ngIf="getPurposeFormField()" [viewData]="getPurposeFormField()"></ind-form-field>
<ind-form-field *ngIf="getHierarchyOpportunityConversionRateFormField()" [viewData]="getHierarchyOpportunityConversionRateFormField()"></ind-form-field>
<ind-form-field *ngIf="getOpportunityConversionRateFormField()" [viewData]="getOpportunityConversionRateFormField()"></ind-form-field>
<ind-form-field *ngIf="getHCPTrainingCompletedFormField()" [viewData]="getHCPTrainingCompletedFormField()"></ind-form-field>
<ind-form-field *ngIf="getMultiYearCampaignFormField()" [viewData]="getMultiYearCampaignFormField()"></ind-form-field>
<ind-form-field *ngIf="getNPIApprovedByAccountFormField()" [viewData]="getNPIApprovedByAccountFormField()"></ind-form-field>
<ind-form-field *ngIf="getNPIProcessStartedFormField()" [viewData]="getNPIProcessStartedFormField()"></ind-form-field>
<ind-form-field *ngIf="getBusinessUnitFormField()" [viewData]="getBusinessUnitFormField()"></ind-form-field>
<ind-form-field [viewData]="getOwnerFormField()"></ind-form-field>
<ind-form-field [viewData]="getCreatedByFormField()"></ind-form-field>
<ind-form-field [viewData]="getLastModifiedByFormField()"></ind-form-field>

<!-- events -->
<ng-container *ngIf="isEventsEnabled">
  <ind-section-header [viewData]="eventsSectionHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
  </ind-section-header>
  <ion-item class="placeholderEmptyDiv" *ngIf="associatedEvents.length=== 0"></ion-item>
  <div [ngClass]="{'events-container':!associatedEvents.length}" lines="none">
    <ion-item *ngFor=" let event of associatedEvents">
      <ion-label text-wrap>{{ event.name}}</ion-label>
    </ion-item>
  </div>
</ng-container>


<!-- opportunities -->
<ng-container *ngIf="isOpportunitiesEnabled">
<ind-section-header [viewData]="opportunitiesSectionHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
</ind-section-header>
<ion-item class="placeholderEmptyDiv" *ngIf="associatedOpportunities.length=== 0"></ion-item>
<!-- <div [ngClass]="{'opportunities-container':!associatedOpportunities.length}" lines="none">
  <ion-item *ngFor=" let opp of associatedOpportunities">
    <ion-label (click)="selctedOpport(opp)" text-wrap>{{opp.opportunityName}}</ion-label>

  </ion-item>
</div> -->
<main-card *ngFor="let opp of allModelOpportunities" [viewData]="opp"></main-card>
</ng-container>

<!-- note section -->
<ind-section-header [viewData]="notesHeaderModel" (onControlClick)='onSectionHeaderControlClick($event)'>
</ind-section-header>
<ind-text-area [placeHolder]="notesPlaceholder" [maxLength]="1000" [disabled]="isReadOnlyMode"
  [showAttachment]="isAttachmentAdded" [hasDownload]="isAttachmentAdded" [attachmentTitle]="attachmentTitle"
  (removeAttachment)="removeAttachment($event)" [value]="notes" (indChange)="notesChanged($event)" [rows]="4"
  [cols]="10">
</ind-text-area>
<input class="hide-input" #attachInput type="file" (change)="loadImageFromDevice($event)">
<io-note *ngFor="let note of brandnotes" [isNoteAttachmentEnabled]="true" [inputNote]="note" [editable]="!isReadOnlyMode"
  [readOnlyNote]="isReadOnlyMode" [readOnlyAttachment]="false " [deletable]="!isReadOnlyMode" (saveNoteOut)="updateNote($event)"
  [disableDownloadInOffline]="true" [from]="uiService.page.MarketingPlanDetails"></io-note>
