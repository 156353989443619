import { BusinessLine, BusinessLineVal, Control } from './../../../classes/dynamic-form/dynamic-form.class';
import {takeUntil} from 'rxjs/operators';
import {Component, ElementRef, ViewChild, Input, HostListener} from '@angular/core';
import {ContactOfflineService} from '../../../services/contact/contact.service';
import {ComponentViewMode, UIService} from '../../../services/ui/ui.service';
import {DeviceService} from '../../../services/device/device.service';
import {NavController, LoadingController, ModalController, PopoverController, NavParams} from '@ionic/angular';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {AuthenticationService} from '../../../services/authentication.service';
import {FeatureActionsMap} from '../../../classes/authentication/user.class';
import {TranslateService} from '@ngx-translate/core';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import {isBefore, isPast} from 'date-fns';
import {BusinessProcessType, ControlDataType, DynamicForm, FormType, OmniGender, OneCRMGender, OneCRMReqType, DCR_CATEGORY, DCR_SOURCE} from '../../../classes/dynamic-form/dynamic-form.class';
import {DynamicFormsService} from '../../../services/dynamic-forms/dynamic-forms-service';
import {DynamicFormComponentModel, DynamicFormType} from '../../../models/dynamic-form-component.model';
import {GlobalUtilityService} from '../../../services/global-utility.service';
import {NavigationService, PageName} from '../../../services/navigation/navigation.service';
import {DynamicFormComponent} from '../../shared/ind-dynamic-form/ind-dynamic-form';
import {ContactDataService} from '../../../data-services/contact/contact.data.service';
import {NotificationService, ToastStyle} from '../../../services/notification/notification.service';
import {AlertService} from '../../../services/alert/alert.service';
import {NothingSelectedView} from '../../shared/nothing-selected-view/nothing-selected-view';
import {
  DEFAULT_CONTACT_CREATE_FORM,
  DEFAULT_CONTACT_DISPLAY_FORM,
  TAG_LABEL_CONTROL,
} from '../../../config/dynamic-forms/default-contact/default-contact-create';
import { TrackService, TrackingEventNames } from '../../../services/logging/tracking.service';
import { EventsService } from '../../../services/events/events.service';
import { Subject, Observable } from 'rxjs';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { IONote } from '../../../classes/io/io-note.class';
import { DB_KEY_PREFIXES } from '../../../config/pouch-db.config';
import { DiskService, OFFLINE_DB_LINKED_ENTITY_NAME } from '../../../services/disk/disk.service';
import {MDMRequestType, MDMType, ChangeRequestType} from '@omni/classes/mdm/source-type-optionset.class';
import {MdmService} from "@omni/services/mdm/mdm.service";
import _ from 'lodash';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { ActivitySource } from '@omni/services/activity/activity.service';
import { NewActivityDataModel } from '@omni/models/indNewActivityDataModel';
import { NewChangeRequestComponent } from '@omni/components/mdm/new-change-request/new-change-request';
import { MAXIMUM_NOTE_ATTACHMENT_SIZE, NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX, toBase64 } from '@omni/utility/util';
import { NOTIFICATION } from '@omni/services/my-assistant/my-assistant.service';
import { IntegrationType } from '@omni/classes/onekey/ok-integration.class';
import { VeevaWidgetService } from '@omni/services/veeva-widget/veeva-widget.service';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { KOLStatus, KOLStatusCode } from '@omni/classes/contact/kol-status.class';
import { ContactMedicalInsight, MedicalInsightCategory } from '@omni/classes/contact/contact.class';
import { ContactMedicalInsightComponent } from '../contact-medical-insight/contact-medical-insight';
import { IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { DatePipe } from '@angular/common';
import { TerritoryManagementService } from '@omni/services/territory-management/territory-management.service';
export class ContactModel {
	id: string;
	name: string;
}

/**
 * Generated class for the ContactInfoComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'contact-info',
  templateUrl: 'contact-info.html',
  styleUrls:['contact-info.scss']
})
export class ContactInfoComponent {

  public isEditable: boolean = false;
  public compViewMode = ComponentViewMode;
  //public configuredFields:ConfiguredFields[]=[];
  public isEditFeatureEnabled:boolean = false;
  public mdmEditFeatureEnabled:boolean = false;
  public businessEditFeatureEnabled:boolean = false;
  public isEditCustomerForEventEnabled:boolean = false;
  public isCustomerNotesEnabled:boolean = false;
  public isCustomerNotesAttachmentEnabled = false;
  private unsubscribe$ = new Subject<void>();
  private isPreViewMode: boolean = false;

  public displayFormDetails;
  public rawFormData;
  public dynamicFormType:DynamicFormType;

  public notesSectionHeaderViewData:IndSectionHeaderViewDataModel;
  //public medicalInsightsSectionHeaderViewData:IndSectionHeaderViewDataModel;
  public tempNoteText: string = '';
  public notesPlaceholder:string = '';
  public isSaveNotesEnabled:boolean = false;
  public currentCustomerNotes:Array<IONote> = [];
  public currentMedicalInsights:Array<ContactMedicalInsight> = [];
  public isEditDisabled:boolean;
  public isInputDisabled: boolean = false;
  @ViewChild('scrollTop', { read: ElementRef, static: false }) scrollTop;
  //  tooltipEvent : string;
  //  tooltipDuration = 1000;
  @Input() scrollObservable: Observable<any>;
  private linkEntitiesReqPayload: any = [];
  @Input()
  public accessedFrom: string;

  @ViewChild('attachInput') attachInput: ElementRef;
  public attachmentTitle: string = '';
  public isAttachmentAdded: boolean = false;
  private attachmentFile: any;
  private base64str;
  public customerNotes: Array<IONote> = [];
  public kolStatusSectionHeaderViewData:IndSectionHeaderViewDataModel;
  public contactKOLStatuses: Array<KOLStatus> = [];
  private businessLine;
  @Input() enableEditBtn: boolean = false;


  constructor(
    public contactService: ContactOfflineService,
    private accountService: AccountOfflineService,
    public authService: AuthenticationService,
    private device: DeviceService,
    private translate: TranslateService,
    private dynamicFormsService: DynamicFormsService,
    private utilityService: GlobalUtilityService,
    private navService: NavigationService,
    private loadingCtrl: LoadingController,
    private contactDataService: ContactDataService,
    private notificationService: NotificationService,
    private alertService: AlertService,
    public uiService: UIService,
    private trackingService: TrackService,
    private events: EventsService,
    public popoverCtrl: PopoverController,
    private disk: DiskService,
    private modalCtrl: ModalController,
    public mdmService: MdmService,
    private footerService:FooterService,
    private iab: InAppBrowser,
    private veevaWidgetService: VeevaWidgetService,
    private dynamics: DynamicsClientService,
    private readonly dateTimeFormatsService: DateTimeFormatsService,
    private readonly datePipe: DatePipe,
    private navParams: NavParams,
    private readonly territoryService: TerritoryManagementService

  ) {
      // if(this.authService.hasFeatureAction(FeatureActionsMap.MEDICAL_PROFILE)){
      //   this.isMedProfileEnabled = true;
      // }
      this.events.observe('active-consent').pipe(takeUntil(this.unsubscribe$)).subscribe((val:boolean)=> {
        this.enableEditBtn = val;
        this.initFeatureActions();
      });

      this.contactService.contactInformationObservable.pipe(takeUntil(this.unsubscribe$)).subscribe(()=> {
        this.initFeatureActions();
        this.initContactInfo();
        //this._initMedicalInsightsViewData();
      })
    this.contactService.selectedOKContactObserver.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.initOKContactInfo();
    });

    // this.contactService.newMedicalInsightObservable.pipe(takeUntil(this.unsubscribe$)).subscribe(async (medicalInsight:ContactMedicalInsight)=> {
    //   if(medicalInsight){
    //     this._handledNewMedicalInsightCreation(medicalInsight);
    //   }
    // });

    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        break;
      case 'Stakeholder':
        break;
      default:
        break;
    }
  }

  ngOnInit(){
    this.initFeatureActions();
    this.contactService.contactUpdated.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.initFeatureActions(); 
      this.initCustomerNotesData();
      this.initKOLStatusViewData();
      //this._initMedicalInsightsData();
      this.initFeatureActions();
    });
  }

  initFeatureActions() {
    this.businessLine = this.authService.user?.buConfigs?.indskr_businessline;
    this.mdmEditFeatureEnabled = this.contactService.contactInformation && this.authService.hasFeatureAction(FeatureActionsMap.CONTACTS_EDIT_REQUEST)
      && (this.contactService.contactInformation.mdmType === MDMType.ONEKEY || this.enableEditBtn);
    this.businessEditFeatureEnabled = this.authService.hasFeatureAction(FeatureActionsMap.CONTACT_EDITOR);
    this.isEditFeatureEnabled = this.businessEditFeatureEnabled || this.mdmEditFeatureEnabled;
    this.isEditCustomerForEventEnabled = (this.authService.user.featureActions.indexOf(FeatureActionsMap.CONTACT_EDITOR_EVENTS) > -1);
    this.isCustomerNotesEnabled = this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_NOTES);
    //this.footerService.updateButtons([], true);
    this.isCustomerNotesAttachmentEnabled = this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_NOTES_ATTACHMENT_UPLOAD)
    const isContactfromContactToolEditable = this.isEditFeatureEnabled
      && (!this.contactService.isInGlobalSearch || this.businessLine === BusinessLineVal.PHARMA || this.businessLine === BusinessLineVal.RETAIL || this.businessLine === BusinessLineVal.VACCINE_MDM)
      && (this.contactService.contactPageMode === this.compViewMode.LISTVIEW || this.contactService.contactPageMode === this.compViewMode.ADDNEW)
      && !this.uiService.isConsentFromToolDrawer;
    const isContactfromEventsToolEditable = this.isEditCustomerForEventEnabled && !this.contactService.isInGlobalSearch && this.contactService.contactPageMode===this.compViewMode.ADDNEW && !this.uiService.isConsentFromToolDrawer;
    // if ((this.contactService.accessedContactListFrom === PageName.ActivitiesDetailsPaneComponent) && this.contactService.contactPageMode===this.compViewMode.ADDNEW) {
    //   this.isEditable = isContactfromContactToolEditable || isContactfromEventsToolEditable;
    // }
    if(this.contactService.accessedContactListFrom === PageName.EventDetailsPageComponent) {
      this.isEditable = isContactfromEventsToolEditable;
    }
    else if(this.contactService.accessedContactListFrom === PageName.PresentationMeetingComponent){
      this.isEditable = isContactfromContactToolEditable ? isContactfromContactToolEditable : false;
    } else{
      // this.isEditable = isContactfromContactToolEditable || isContactfromEventsToolEditable;
      this.isEditable = isContactfromContactToolEditable;
    }
    if (this.isEditable && this.contactService.contactInformation && this.contactService.contactInformation.mdmType == MDMType.VEEVA) {
      if (this.device.isOffline) this.isEditable = false;
      // this.veevaWidgetService.loadVeevaScripts();
    }
    if (this.contactService.contactPageMode === ComponentViewMode.PREVIEW) {
      this.isPreViewMode = true;
      this.isEditable = false;
      this.isEditDisabled = true;
    } else {
      this.isPreViewMode = false;
      this.isEditable = isContactfromContactToolEditable;
      this.isEditDisabled = false;
    }
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(){
    if (this.contactService.isOneKeySearching) {
      this.initOKContactInfo();
    } else {
      this.initContactInfo();
    }
    if(this.scrollTop) this.scrollTop.nativeElement.scrollIntoView(false);
  }

  private _initCustomerNotesViewData(){

     if (this.isCustomerNotesEnabled || this.isCustomerNotesAttachmentEnabled) {
      let controls: any[] = [];
      if (this.isCustomerNotesAttachmentEnabled) {
        controls.push({
          id:'attach_note',
          text: this.translate.instant('ATTACH'),
          isDisabled: this.isPreViewMode,
        });
      }
      controls.push({
        id: 'save_note',
        text: this.translate.instant('SAVE'),
        isDisabled: _.isEmpty(this.tempNoteText) && !this.isAttachmentAdded || this.isPreViewMode,
      });
      this.notesSectionHeaderViewData = {
        id: 'contact_notes_section_header',
        title: this.translate.instant('CUSTOMER_NOTES_CAP'),
        controls: controls
      };
      let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
      this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
    }
  }

  // private _initMedicalInsightsViewData(){
  //   if(this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_MEDICAL_INSIGHT)){
  //     this.medicalInsightsSectionHeaderViewData = {
  //       id: 'medical_insights_section_header',
  //       title: this.translate.instant('MEDICAL_INSIGHTS'),
  //       controls: [
  //         {
  //           id: 'create_medical_insight',
  //           text: this.translate.instant("CREATE"),
  //           isDisabled: this.device.isOffline,
  //           isVisible: this.contactService.accessedContactListFrom == PageName.ToolsDrawer && (this.navParams.data && this.navParams.data.from != PageName.TerritoryManagementDetailsComponent),
  //         },
  //       ]
  //     }
  //   }
  // }

  // private async _initMedicalInsightsData(){
  //   if(this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_MEDICAL_INSIGHT)){
  //     this.currentMedicalInsights = [];
  //     let dbMedicalInsights = await this.disk.retrieve(OFFLINE_DB_LINKED_ENTITY_NAME.CONTACT_MEDICAL_INSIGHTS);
  //     if(dbMedicalInsights && dbMedicalInsights.raw && Array.isArray(dbMedicalInsights.raw) && dbMedicalInsights.raw.length != 0){
  //       dbMedicalInsights.raw.forEach(item=>{
  //         if(this.contactService.contactInformation &&
  //         item['customerinsights.indskr_customer'] == this.contactService.contactInformation.ID &&
  //         item['customerinsights.indskr_customerinteractioninsightsid'] &&
  //         item['customerinsights.ownerid']){
  //           let currentInsight = new ContactMedicalInsight(item);
  //           let idx = this.currentMedicalInsights.findIndex(insight => insight.ID == currentInsight.ID);
  //           if(idx >= 0){
  //             this.currentMedicalInsights[idx] = currentInsight;
  //           }else{
  //             this.currentMedicalInsights.push(currentInsight);
  //           }
  //         }
  //       });
  //     }
  //     this._sortMedicalInsights();
  //   }
  // }

  public onSectionHeaderControlClick(id:string){
    switch(id){
      case 'save_note':
        this.handleSaveNote();
        break;
      case 'attach_note':
        this.handleNoteAttachment();
        break;
      // case 'create_medical_insight':
      //   this._createMedicalInsight();
      //   break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  private async initCustomerNotesData(){
    this.currentCustomerNotes = [];

    let dbNotes = await this.disk.retrieve(OFFLINE_DB_LINKED_ENTITY_NAME.CONTACT_NOTES);
    if(dbNotes && dbNotes.raw && Array.isArray(dbNotes.raw) && dbNotes.raw.length != 0){
      dbNotes.raw.forEach(item=>{
        if(this.contactService.contactInformation &&
        item['annotation.objectid'] == this.contactService.contactInformation.ID &&
        item['annotation.annotationid'] &&
        item['annotation.ownerid'] &&
        item['annotation.createdon']){
          const payload = {
            annotationid: item['annotation.annotationid'],
            contactid: item['annotation.objectid'],
            createdon: item['annotation.createdon'] ? new Date(item['annotation.createdon']).getTime().toString() : null,
            notetext: item['annotation.notetext'],
            ownerName: item['annotation.ownerid@OData.Community.Display.V1.FormattedValue'],
            ownerid: item['annotation.ownerid'],
            filename: item['annotation.filename'],
            filesize: item['annotation.filesize'],
            isdocument: !_.isEmpty(item['annotation.filename']),
            mimetype: item['annotation.mimetype']
          };
          let currentNote = new IONote(payload);
          let idx = this.currentCustomerNotes.findIndex(note => note.noteId == currentNote.noteId);
          if(idx >= 0){
            this.currentCustomerNotes[idx] = currentNote;
          }else{
            this.currentCustomerNotes.push(currentNote);
          }
        }
      });
    }
    let offlineNotes = await this.disk.retrieve(DB_KEY_PREFIXES.OFFLINE_CUSTOMER_NOTES);
    if(offlineNotes && offlineNotes.raw && Array.isArray(offlineNotes.raw) && offlineNotes.raw.length != 0){
      offlineNotes.raw.forEach(item => {
        if(item && item.deleted){
          let idx = this.currentCustomerNotes.findIndex(note => note.noteId == item.annotationid);
          if(idx >= 0){
            this.currentCustomerNotes.splice(idx,1);
          }
        }else if(this.contactService.contactInformation && item.contactid == this.contactService.contactInformation.ID){
          let currentNote = new IONote(item);
          let idx = this.currentCustomerNotes.findIndex(note => note.noteId == currentNote.noteId);
          if (idx >= 0) {
            this.currentCustomerNotes[idx] = currentNote;
          } else {
            this.currentCustomerNotes.push(currentNote);
          }
        }
      });
    }
    this._sortCustomerNotes();
  }

  private async initKOLStatusViewData(){
    if(this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_KOL_STATUS)){ 
      let offlineData = await this.disk.retrieve("indskr_kolstatus");
      if(offlineData && offlineData.raw && Array.isArray(offlineData.raw)){
        this.contactKOLStatuses = [];
        offlineData.raw.forEach(item => {
          if(item['indskr_kolstatus.statuscode'] == KOLStatusCode.Approved && item['indskr_kolstatus.indskr_customer'] == this.contactService.contactInformation.ID){
            let kol = new KOLStatus(
              {
                "indskr_kolstatusid": item['indskr_kolstatus.indskr_kolstatusid'],
                "indskr_name": item['indskr_kolstatus.indskr_name'],
                "indskr_koltype_Formatted": item['indskr_kolstatus.indskr_koltype_Formatted'],
                "indskr_koltype": item['indskr_kolstatus.indskr_koltype'],
                "_indskr_therapeuticarea_value": item['indskr_kolstatus.indskr_therapeuticarea'],
                "_indskr_therapeuticarea_value_Formatted": item['indskr_kolstatus.indskr_therapeuticarea_Formatted'],
                "_indskr_specialty_value": item['indskr_kolstatus.indskr_specialty'],
                "_indskr_specialty_value_Formatted": item['indskr_kolstatus.indskr_specialty_Formatted'],
                "_indskr_diseasearea_value": item['indskr_kolstatus.indskr_diseasearea'],
                "_indskr_diseasearea_value_Formatted": item['indskr_kolstatus.indskr_diseasearea_Formatted'],
                "indskr_comments": item['indskr_kolstatus.indskr_comments'],
                "_ownerid_value": item['indskr_kolstatus.ownerid'],
                "_indskr_customer_value_Formatted": item['indskr_kolstatus.indskr_customer_Formatted'],
                "_indskr_customer_value": item['indskr_kolstatus.indskr_customer'],
                "_ownerid_value_Formatted": item['indskr_kolstatus.ownerid_Formatted'],
                "createdon": item['indskr_kolstatus.createdon'],
                "statuscode": item['indskr_kolstatus.statuscode'],
                "statuscode_Formatted": item['indskr_kolstatus.statuscode_Formatted'],
                "statecode": item['indskr_kolstatus.statecode'],
                "aa.indskr_approvalactivityid": '',
                "aa.statuscode_Formatted": '',
                "isPendingPushToDynamics": false,
                "isApprovalRecord": false,
              }
            );
            this.contactKOLStatuses.push(kol);
          }
        });
        this.contactKOLStatuses.sort((a,b)=> a.createdOn.getTime() - b.createdOn.getTime());
      }
      this.kolStatusSectionHeaderViewData = {
        id: 'contact_kol_statuses_section_header',
        title: this.translate.instant('KOL_STATUS'),
        controls: []
      };
    }
  }

  public notesChanged(ev): void {
    if(ev && ev.target){
      this.tempNoteText = ev.target.value;
    }else{
      this.tempNoteText = '';
    }
    if(this.tempNoteText.length > 0){
      this.isSaveNotesEnabled = true;
    }else{
      this.isSaveNotesEnabled = false;
    }
    this._initCustomerNotesViewData();
  }

  @HostListener('window:veeva-network-dcr-submitted', ['$event'])
  async dcrSubmitted(event) {
    console.log(event);
    if (this.contactService.contactInformation.ID && event.detail.taskId) {
      await this.dynamics.dwa.create({
        omniveev_externalid: event.detail.taskId,
        omniveev_type: 200000001,
        'omniveev_customer_contact@odata.bind': `/contacts(${this.contactService.contactInformation.ID})`
      }, "omniveev_datachangerequests");
    }
  }

  async editContact(event){
    this.dynamicFormsService.okLookUpMapper = [];
    if (this.contactService.contactInformation.mdmType == MDMType.VEEVA) {
      await this.veevaWidgetService.openProfileWidget(
        this.contactService.contactInformation.appId,
        this.contactService.contactInformation.fullName);
      return;
    }

    if (this.contactService.contactInformation.mdmType == MDMType.ONEKEY) {
      this.accountService.isOneKeyChangeRequest = true;
      this.contactService.isOneKeyChangeRequest = true;
      if (this.businessEditFeatureEnabled && this.mdmEditFeatureEnabled) {
        const newActivityModel: NewActivityDataModel = {
          source: ActivitySource.ONE_KEY_CONTACT_EDIT,
          displayOptions: [
            {
              id: "business_information",
              formattedLabel: this.translate.instant('ONE_KEY_BUSINESS_INFORMATION'),
              isChecked: true,
              isDisabled:false
            },
            {
              id: "one_key_information",
              formattedLabel: this.translate.instant('ONE_KEY_MDM_INFORMATION'),
              isChecked: false,
              isDisabled:false
            }
          ],
          title: this.translate.instant('EDIT') + " " + this.contactService.contactInformation.fullName,
          eventHandler: (data) => this.handleSelectEvent(data)
        }
        this.navService.pushChildNavPageWithPageTracking(NewChangeRequestComponent, PageName.NewChangeRequestComponent, PageName.ContactPageComponent, {newActivityDataModel : newActivityModel}).then(()=>{
          this.uiService.prevView = this.uiService.activeView;
          this.uiService.activeView = 'ContactPageRightPaneNav';
          this.uiService.showNewActivity = false;
        });
      } else if (this.businessEditFeatureEnabled){
        this.accountService.isOneKeyChangeRequest = false;
        this.contactService.isOneKeyChangeRequest = false;
        this.oneKeyContactBusinessInformationEditFlow();
      } else {
        await this.mdmContactEditFlow();
      }
    } else if (this.mdmEditFeatureEnabled && this.businessLine === BusinessLineVal.VACCINE) {
      await this.mdmContactEditFlow();
    } else if (this.mdmEditFeatureEnabled && (this.businessLine === BusinessLineVal.PHARMA || this.businessLine === BusinessLineVal.RETAIL || this.businessLine === BusinessLineVal.VACCINE_MDM)) {
      this.contactEditOptions();
    } else {
      this.accountService.isOneKeyChangeRequest = false;
      this.contactService.isOneKeyChangeRequest = false;
      await this.defaultContactEditFlow();
    }

  }

  private contactEditOptions() {
    if (this.contactService.isInGlobalSearch) {
      this.mdmAddAccountAffiliation();
    } else {
      const newActivityModel: NewActivityDataModel = {
        source: ActivitySource.ONE_KEY_CONTACT_EDIT,
        displayOptions: [
          {
            id: "change_attribute",
            formattedLabel: this.translate.instant('CHANGE_ATTRIBUTE'),
            isChecked: true,
            isDisabled: false
          },
          {
            id: "add_account_affiliation",
            formattedLabel: this.translate.instant('ADD_ACCOUNT_AFFILIATION'),
            isChecked: false,
            isDisabled: false
          }
        ],
        title: this.translate.instant('EDIT') + " " + this.contactService.contactInformation.fullName,
        eventHandler: (data) => this.handleMDMEditSelected(data)
      }
      this.navService.pushChildNavPageWithPageTracking(NewChangeRequestComponent, PageName.NewChangeRequestComponent, PageName.ContactPageComponent, { newActivityDataModel: newActivityModel });
    }
  }

  private handleMDMEditSelected(data) {
    if (data?.id === 'change_attribute')
      this.mdmContactEditFlow();
    else 
      this.mdmAddAccountAffiliation();
  }

  private loadIOSWidget() {
    let activeInstance = this.authService.userConfig.activeInstance;
    const veevaConfig = activeInstance.veevaConfig;

    this.iab.create(`${activeInstance.OmnipresenceWfeUrl}/?veevaWidget=VEEVA_PROFILE&preset=contact&id=${this.contactService.contactInformation.appId}&title=${this.contactService.contactInformation.fullName}&widgetName=${veevaConfig.profileWidgetName}&manifestURL=${veevaConfig.manifestScriptUrl}&widgetURL=${veevaConfig.profileWidgetScriptUrl}&widgetID=${veevaConfig.profileWidgetId}&authDomain=${veevaConfig.authDomain}`, '_system', { location: 'yes', hideurlbar: 'no', hidenavigationbuttons: 'no' });

    return;
  }

  private async defaultContactEditFlow() {
    this.trackingService.tracking('EditSelected', TrackingEventNames.CONTACT_EDIT, true);

    let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("contact", FormType.EDITFORM);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if(!contactForm){
      contactForm = new DynamicForm(DEFAULT_CONTACT_CREATE_FORM['value'][0]);
      formType = DynamicFormType.DEFAULTFORM;
    }

    let contactRaw = await this.contactService.contactInformation.raw;
    let contactIdData = {
      contactid: this.contactService.contactInformation.ID,
    };
    let existingLinkedEntityData = [];
    if(this.contactService._defaultLinkedEntityMappingData) {
      existingLinkedEntityData = this.contactService._defaultLinkedEntityMappingData.filter(contact => contact.contactid === this.contactService.contactInformation.ID);
    }
    let formDetails: DynamicFormComponentModel = {
      title: this.translate.instant('EDIT') + " " + this.contactService.contactInformation.fullName,
      LookupEntitySetName: 'contacts',
      showLeftHeaderButton: true,
      leftHeaderBtnIcon: 'chevron-back-outline',
      showRightHeaderButton: true,
      rightHeaderImgSrc: 'assets/imgs/header_save.svg',
      rightHeaderBtnText: this.translate.instant('SAVE'),
      formMetadata: contactForm,
      formEntityObject: contactRaw,
      mainEntityObjectId: contactIdData,
      dynamicFormType: formType,
      linkedEntityValue: existingLinkedEntityData,
      navOptions: { animate: false },
      eventsHandler: (data:any, eventTarget:string, refData:DynamicFormComponentModel)=> this._handleContactFormComponentEvent(data,eventTarget),
    };

    this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent, PageName.DynamicFormComponent, PageName.ContactPageComponent, {
      MODE:'EDIT_MODE',
      FORM_DETAIL: formDetails,
      RAW_DATA: this.rawFormData,
      REF_ENTITY_NAME: 'contactid'
    }).then(()=>{
      if(this.uiService.activeView === 'ActivitiesPageRightPaneNav' && this.uiService.activitiesPageTab === 'plans') {
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      } else {
        this.uiService.activeView = 'ContactPageRightPaneNav';
      }
      this.uiService.showNewActivity = false;
    });
  }

  private async oneKeyContactBusinessInformationEditFlow() {
    this.trackingService.tracking('EditSelected', TrackingEventNames.CONTACT_EDIT, true);

    let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("contact", FormType.EDITFORM, BusinessProcessType.OneKey);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if(!contactForm){
      this.uiService.activeView = this.uiService.prevView;
      this.notificationService.notify(this.translate.instant('ONE_KEY_CREATE_FORM_ERROR'), 'DynamicFormComponent', "top", ToastStyle.DANGER, 3000);
      return;
    }

    let contactRaw = await this.contactService.contactInformation.raw;
    let contactIdData = {
      contactid: this.contactService.contactInformation.ID,
    };
    let existingLinkedEntityData = [];
    if(this.contactService._defaultLinkedEntityMappingData) {
      existingLinkedEntityData = this.contactService._defaultLinkedEntityMappingData.filter(contact => contact.contactid === this.contactService.contactInformation.ID);
    }

    let formDetails: DynamicFormComponentModel = {
      title: this.translate.instant('EDIT') + " " + this.contactService.contactInformation.fullName,
      LookupEntitySetName: 'contacts',
      showLeftHeaderButton: true,
      leftHeaderBtnIcon: 'chevron-back-outline',
      showRightHeaderButton: true,
      rightHeaderImgSrc: 'assets/imgs/header_save.svg',
      rightHeaderBtnText: this.translate.instant('SAVE'),
      formMetadata: contactForm,
      formEntityObject: contactRaw,
      mainEntityObjectId: contactIdData,
      dynamicFormType: formType,
      navOptions: { animate: false },
      linkedEntityValue: existingLinkedEntityData,
      eventsHandler: (data:any, eventTarget:string, refData:DynamicFormComponentModel)=> this._handleContactFormComponentEvent(data,eventTarget),
    };

    this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent, PageName.DynamicFormComponent, PageName.ContactPageComponent, {
      MODE:'EDIT_MODE',
      FORM_DETAIL: formDetails,
      RAW_DATA: this.rawFormData,
      REF_ENTITY_NAME: 'contactid'
    }).then(()=>{
      if(this.uiService.activeView === 'ActivitiesPageRightPaneNav' && this.uiService.activitiesPageTab === 'plans') {
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      } else {
        this.uiService.activeView = 'ContactPageRightPaneNav';
      }
      this.uiService.showNewActivity = false;
    });
  }

  private async handleSelectEvent(data) {
    switch (data.id) {
      case "one_key_information":
        await this.mdmContactEditFlow();
        break;
      case "business_information":
        this.oneKeyContactBusinessInformationEditFlow();
        break;
      default:
        break;
    }
  }

  private async mdmAddAccountAffiliation() {
    let contactForm: DynamicForm = null;
    let sanofiChinaForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_contactcr", FormType.EDITFORM, BusinessProcessType.SanofiChina);
    let oneKeyForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_contactcr", FormType.EDITFORM, BusinessProcessType.OneKey);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if (!oneKeyForm && !sanofiChinaForm) {
      this.notificationService.notify(this.translate.instant('ONE_KEY_CREATE_FORM_ERROR'), 'DynamicFormComponent', "top", ToastStyle.DANGER, 3000);
      return;
    }
    if (oneKeyForm && sanofiChinaForm) {
      this.notificationService.notify(this.translate.instant('MORE_THAN_ONE_FORM_CONFIGURED_ERR'), 'DynamicFormComponent', "top", ToastStyle.DANGER, 3000);
      return;
    }
    contactForm = oneKeyForm ? oneKeyForm : sanofiChinaForm;
    if(!contactForm){
      this.notificationService.notify(this.translate.instant('ONE_KEY_CREATE_FORM_ERROR'), 'DynamicFormComponent', "top", ToastStyle.DANGER, 3000);
      return;
    }

    // const businessProcess = contactForm.raw && contactForm.raw['indskr_businessprocess'] ? 'SANOFI_CHINA' : 'ONE_KEY';
    const businessProcess = contactForm.raw && contactForm.raw['indskr_businessprocess'] == BusinessProcessType.SanofiChina ? 'SANOFI_CHINA' : 'ONE_KEY';
    
    let rawObjectData = this.rawFormData;
    rawObjectData = {
      indskr_firstname: this.contactService.contactInformation.firstName,
      indskr_lastname: this.contactService.contactInformation.lastName,
      indskr_mdm: businessProcess == 'SANOFI_CHINA' ? 600000000 : this.contactService.contactInformation.mdmType,
      indskr_mdmrequesttype: MDMRequestType.ADD_AFFILIATION,
      ...rawObjectData
    }

    if (businessProcess == 'SANOFI_CHINA' && this.contactService.contactInformation.raw) {
      let buLine = this.authService.user?.buConfigs?.indskr_businessline;
      let attrName = '';
      rawObjectData['indskr_lastname'] = this.contactService.contactInformation.raw['fullname'];
      if (this.contactService.contactInformation.raw['gendercode']) {
        attrName = 'indskr_gendercode';
        rawObjectData[attrName] = this.contactService.contactInformation.raw['gendercode'];
        rawObjectData[attrName+'@OData.Community.Display.V1.FormattedValue'] = this.contactService.contactInformation.raw['gendercode@OData.Community.Display.V1.FormattedValue'];
      }
      if (!_.isEmpty(this.contactService.contactInformation.accountRelationships) && buLine) {
        const accAffiliations = this.contactService.contactInformation.accountRelationships;
        if (accAffiliations.length > 0) {
          let affiliation = accAffiliations.map(aff => aff['indskr_jobtitle']);
          if (!_.isEmpty(affiliation)) {
            let rControl = null;
            if (buLine === BusinessLineVal.PHARMA) {
              attrName = 'indskr_rolepharma';
              rControl = this.getFormControl(contactForm, attrName);
            } else if (buLine === BusinessLineVal.RETAIL) {
              attrName = 'indskr_positionretail';
              rControl = this.getFormControl(contactForm, attrName);
            } else {
              attrName = 'indskr_rolevaccine';
              rControl = this.getFormControl(contactForm, attrName);
            }
            if (rControl) {
              const option = this.getOptionSetMap(rControl, affiliation[0]);
              rawObjectData[attrName] = option.key;
              rawObjectData[attrName+'@OData.Community.Display.V1.FormattedValue'] = option.value;
            }
          }
          if (buLine === BusinessLineVal.VACCINE) {
            let dept_nm = accAffiliations.map(aff => aff['indskr_department'])[0];
            attrName = 'indskr_departmentvaccine';
            const dControl = this.getFormControl(contactForm, attrName);
            if (dControl) {
              const option = this.getOptionSetMap(dControl, dept_nm);
              rawObjectData[attrName] = option.key;
              rawObjectData[attrName+'@OData.Community.Display.V1.FormattedValue'] = option.value;
            }
          } 
        }
      }
      if (buLine === BusinessLineVal.PHARMA || buLine === BusinessLineVal.RETAIL || buLine === BusinessLineVal.VACCINE_MDM) {
        if (this.contactService.contactInformation.raw['indskr_title_Formatted']) {
          attrName = (buLine === BusinessLineVal.PHARMA || buLine === BusinessLineVal.VACCINE_MDM) ? 'indskr_titlepharma' : 'indskr_titleretail';
          const dControl = this.getFormControl(contactForm, attrName);
          if (dControl) {
            const option = this.getOptionSetMap(dControl, this.contactService.contactInformation.raw['indskr_title_Formatted']);
            rawObjectData[attrName] = option.key;
            rawObjectData[attrName + '@OData.Community.Display.V1.FormattedValue'] = option.value;
          }
        }
        if (buLine === BusinessLineVal.RETAIL && this.contactService.contactInformation.raw['indskr_position_Formatted']) {
          attrName = 'indskr_positionretail';
          const dControl = this.getFormControl(contactForm, attrName);
          if (dControl) {
            const option = this.getOptionSetMap(dControl, this.contactService.contactInformation.raw['indskr_position_Formatted']);
            rawObjectData[attrName] = option.key;
            rawObjectData[attrName + '@OData.Community.Display.V1.FormattedValue'] = option.value;
          }
        }
      }
      if (!_.isEmpty(this.contactService.contactInformation.accountRelationships) || !_.isEmpty(this.contactService.contactInformation.primaryAccount)) {
        if (!_.isEmpty(this.contactService.contactInformation.accountRelationships)) {
          rawObjectData['_indskr_account_value'] = this.contactService.contactInformation.accountRelationships[0].accountId;
          rawObjectData['_indskr_account_value@OData.Community.Display.V1.FormattedValue'] = this.contactService.contactInformation.accountRelationships[0].accountName;
        } else {
          rawObjectData['_indskr_account_value'] = this.contactService.contactInformation.primaryAccount.id;
          rawObjectData['_indskr_account_value@OData.Community.Display.V1.FormattedValue'] = this.contactService.contactInformation.primaryAccount.accountName;
        }

        if (BusinessLineVal.PHARMA || buLine === BusinessLineVal.VACCINE_MDM) {
          // Get account detail
          const account = this.accountService.getAccountById(rawObjectData['_indskr_account_value']);
          if (account?.raw && account.raw['indskr_hcotype']) {
            rawObjectData['_indskr_account_indskr_hcotype'] = account.raw['indskr_hcotype'];
          }
        }
      }
      if (this.contactService.contactInformation.primarySpecialty) {
        rawObjectData['_indskr_specialty_value'] = this.contactService.contactInformation.primarySpecialty.id;
        rawObjectData['_indskr_specialty_value@OData.Community.Display.V1.FormattedValue'] = this.contactService.contactInformation.primarySpecialty.name;
      }
    }

    // if (businessProcess == 'SANOFI_CHINA' && this.contactService.contactInformation) {
    //   rawObjectData['_indskr_accountid_value'] = this.contactService.contactInformation.primaryAccount.id;
    //   rawObjectData['_indskr_accountid_value@OData.Community.Display.V1.FormattedValue'] = this.contactService.contactInformation.primaryAccount.accountName;
    // }

    // for (let i = 0; i < contactForm.metadata.length; i++) {
    //   const itab = contactForm.metadata[i];
    //   this.removeOldOptionSetIfLookupIsPresent(itab, contactForm, i, "indskr_affiliationrole", "indskr_contactrole");
    //   if (itab && itab.controls && itab.controls.length > 0) {
    //     this.getFieldsToDisplayForContactAccountAff(itab, 'indskr_accountid');
    //   }
    // }

    for (let i = 0; i < contactForm.metadata.length; i++) {
      const itab = contactForm.metadata[i];
      // this.removeOldOptionSetIfLookupIsPresent(itab, contactForm, i, "indskr_affiliationrole", "indskr_contactrole");
      if (itab && itab.controls && itab.controls.length > 0) {
        this.getFieldsReadOnlyAccAffliation(itab);
      }
    }
    
    const contactRaw = rawObjectData;
    this.mdmService.addLinkEntities([]);
    let formDetails: DynamicFormComponentModel = {
      title: this.translate.instant('EDIT') + " " + this.contactService.contactInformation.fullName,
      LookupEntitySetName: 'indskr_contactcrs',
      showLeftHeaderButton: true,
      leftHeaderBtnIcon: 'chevron-back-outline',
      showRightHeaderButton: true,
      rightHeaderImgSrc: 'assets/imgs/header_save.svg',
      rightHeaderBtnText: this.translate.instant('SAVE'),
      formMetadata: contactForm,
      formEntityObject: contactRaw,
      mainEntityObjectId: null,
      dynamicFormType: formType,
      navOptions: { animate: false },
      eventsHandler: (data:any, eventTarget:string, refData:DynamicFormComponentModel)=> this._handleAddAccountAffiliationFormComponentEvent(data,eventTarget),
    };

    this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent, PageName.DynamicFormComponent, PageName.ContactPageComponent, {
      MODE:'EDIT_MODE',
      FORM_DETAIL: formDetails,
      TYPE: businessProcess
    }).then(()=>{
      this.uiService.activeView = 'ContactPageRightPaneNav';
      this.uiService.showNewActivity = false;
    });
  }

  private removeOldOptionSetIfLookupIsPresent(itab: any, foundForm: any, i: number, newLookUpAttribute: string, oldOptionSetAttribute: string) {
    const affRoleIndex = itab.controls.findIndex(control => control.attributeName == newLookUpAttribute);
    if (affRoleIndex >= 0) {
      const roleIndex = itab.controls.findIndex(control => control.attributeName == oldOptionSetAttribute);
      if (roleIndex >= 0) {
        itab.controls.splice(roleIndex, 1);
        foundForm.metadata[i] = itab;
      }
    }
  }

  private getFieldsToDisplayForContactAccountAff(itab: any, attributeToDisplay: string) {
    itab.controls.filter(c => (c.attributeName == 'indskr_contactcr' || c.attributeName == 'indskr_accountcr' || c.attributeName == 'indskr_contactid' || c.attributeName == 'indskr_accountid'))
      .forEach(control => {
         if (control.attributeName == attributeToDisplay) {
          control.forceHide = false;
          control.isRequired = true;
        } else {
          control.forceHide = true;
          control.isRequired = false;
        }
      });
  }

  private getFieldsToHideForChangeAAttributes(itab: any) {
    itab.controls.filter(c => (c.lookupEntitySetName == 'indskr_accountcontactaffiliationcrs'))
      .forEach(control => {
        control.forceHide = true;
        control.isRequired = false;
      });
  }

  private getFieldsReadOnlyAccAffliation(itab: any) {
    itab.controls.filter(c => (c.attributeName == 'indskr_lastname' || c.attributeName == 'indskr_gendercode' || c.attributeName == 'indskr_account' || c.attributeName == 'indskr_specialty' || c.attributeName == 'indskr_titlepharma'))
      .forEach(control => {
        control.isReadOnly = true;
      });
  }

  private async mdmContactEditFlow() {
    let contactForm: DynamicForm = null;
    let sanofiChinaForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_contactcr", FormType.EDITFORM, BusinessProcessType.SanofiChina);
    let oneKeyForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_contactcr", FormType.EDITFORM, BusinessProcessType.OneKey);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if (!oneKeyForm && !sanofiChinaForm) {
      this.notificationService.notify(this.translate.instant('ONE_KEY_CREATE_FORM_ERROR'), 'DynamicFormComponent', "top", ToastStyle.DANGER, 3000);
      return;
    }
    if (oneKeyForm && sanofiChinaForm) {
      this.notificationService.notify(this.translate.instant('MORE_THAN_ONE_FORM_CONFIGURED_ERR'), 'DynamicFormComponent', "top", ToastStyle.DANGER, 3000);
      return;
    }
    contactForm = oneKeyForm ? oneKeyForm : sanofiChinaForm;
    if(!contactForm){
      this.notificationService.notify(this.translate.instant('ONE_KEY_CREATE_FORM_ERROR'), 'DynamicFormComponent', "top", ToastStyle.DANGER, 3000);
      return;
    }

    // const businessProcess = contactForm.raw && contactForm.raw['indskr_businessprocess'] ? 'SANOFI_CHINA' : 'ONE_KEY';
    const businessProcess = contactForm.raw && contactForm.raw['indskr_businessprocess'] == BusinessProcessType.SanofiChina ? 'SANOFI_CHINA' : 'ONE_KEY';

    let rawObjectData = this.rawFormData;
    rawObjectData = {
      indskr_firstname: this.contactService.contactInformation.firstName,
      indskr_lastname: this.contactService.contactInformation.lastName,
      indskr_mdm: businessProcess == 'SANOFI_CHINA' ? 600000000 : this.contactService.contactInformation.mdmType,
      indskr_mdmrequesttype: MDMRequestType.UPDATE,
      ...rawObjectData
    }

    if (businessProcess == 'SANOFI_CHINA' && this.contactService.contactInformation.raw) {
      let buLine = this.authService.user?.buConfigs?.indskr_businessline;
      let attrName = '';
      rawObjectData['indskr_lastname'] = this.contactService.contactInformation.raw['fullname'];
      if (this.contactService.contactInformation.raw['gendercode']) {
        attrName = 'indskr_gendercode';
        rawObjectData[attrName] = this.contactService.contactInformation.raw['gendercode'];
        rawObjectData[attrName+'@OData.Community.Display.V1.FormattedValue'] = this.contactService.contactInformation.raw['gendercode@OData.Community.Display.V1.FormattedValue'];
      }
      if (!_.isEmpty(this.contactService.contactInformation.accountRelationships) && buLine) {
        const accAffiliations = this.contactService.contactInformation.accountRelationships;
        if (accAffiliations.length > 0) {
          let affiliation = accAffiliations.map(aff => aff['indskr_jobtitle']);
          if (!_.isEmpty(affiliation)) {
            let rControl = null;
            if (buLine === BusinessLineVal.PHARMA) {
              attrName = 'indskr_rolepharma';
              rControl = this.getFormControl(contactForm, attrName);
            } else {
              attrName = 'indskr_rolevaccine';
              rControl = this.getFormControl(contactForm, attrName);
            }
            if (rControl) {
              const option = this.getOptionSetMap(rControl, affiliation[0]);
              rawObjectData[attrName] = option.key;
              rawObjectData[attrName+'@OData.Community.Display.V1.FormattedValue'] = option.value;
            }
          }
          if (buLine === BusinessLineVal.VACCINE) {
            let dept_nm = accAffiliations.map(aff => aff['indskr_department'])[0];
            attrName = 'indskr_departmentvaccine';
            const dControl = this.getFormControl(contactForm, attrName);
            if (dControl) {
              const option = this.getOptionSetMap(dControl, dept_nm);
              rawObjectData[attrName] = option.key;
              rawObjectData[attrName+'@OData.Community.Display.V1.FormattedValue'] = option.value;
            }
          } 
        }
      }
      if (buLine === BusinessLineVal.PHARMA || buLine === BusinessLineVal.RETAIL || buLine === BusinessLineVal.VACCINE_MDM) {
        if (this.contactService.contactInformation.raw['indskr_title_Formatted']) {
          attrName = (buLine === BusinessLineVal.PHARMA || buLine === BusinessLineVal.VACCINE_MDM) ? 'indskr_titlepharma' : 'indskr_titleretail';
          const dControl = this.getFormControl(contactForm, attrName);
          if (dControl) {
            const option = this.getOptionSetMap(dControl, this.contactService.contactInformation.raw['indskr_title_Formatted']);
            rawObjectData[attrName] = option.key;
            rawObjectData[attrName + '@OData.Community.Display.V1.FormattedValue'] = option.value;
          }
        }
        if (buLine === BusinessLineVal.RETAIL && this.contactService.contactInformation.raw['indskr_position_Formatted']) {
          attrName = 'indskr_positionretail';
          const dControl = this.getFormControl(contactForm, attrName);
          if (dControl) {
            const option = this.getOptionSetMap(dControl, this.contactService.contactInformation.raw['indskr_position_Formatted']);
            rawObjectData[attrName] = option.key;
            rawObjectData[attrName + '@OData.Community.Display.V1.FormattedValue'] = option.value;
          }
        }
      }
      if (!_.isEmpty(this.contactService.contactInformation.accountRelationships) || !_.isEmpty(this.contactService.contactInformation.primaryAccount)) {
        if (!_.isEmpty(this.contactService.contactInformation.accountRelationships)) {
          rawObjectData['_indskr_account_value'] = this.contactService.contactInformation.accountRelationships[0].accountId;
          rawObjectData['_indskr_account_value@OData.Community.Display.V1.FormattedValue'] = this.contactService.contactInformation.accountRelationships[0].accountName;
        } else {
          rawObjectData['_indskr_account_value'] = this.contactService.contactInformation.primaryAccount.id;
          rawObjectData['_indskr_account_value@OData.Community.Display.V1.FormattedValue'] = this.contactService.contactInformation.primaryAccount.accountName;
        }

        if (buLine === BusinessLineVal.PHARMA || buLine === BusinessLineVal.VACCINE_MDM) {
          // Get account detail
          const account = this.accountService.getAccountById(rawObjectData['_indskr_account_value']);
          if (account?.raw && account.raw['indskr_hcotype']) {
            rawObjectData['_indskr_account_indskr_hcotype'] = account.raw['indskr_hcotype'];
          }
        }
      }
      if (this.contactService.contactInformation.primarySpecialty) {
        rawObjectData['_indskr_specialty_value'] = this.contactService.contactInformation.primarySpecialty.id;
        rawObjectData['_indskr_specialty_value@OData.Community.Display.V1.FormattedValue'] = this.contactService.contactInformation.primarySpecialty.name;
      }
      
      for (let i = 0; i < contactForm.metadata.length; i++) {
        const itab = contactForm.metadata[i];
        if (itab && itab.controls && itab.controls.length > 0) {
          this.getFieldsToHideForChangeAAttributes(itab);
        }
      }
    }
    
    const contactRaw = rawObjectData;
    this.mdmService.addLinkEntities([]);
    let formDetails: DynamicFormComponentModel = {
      title: this.translate.instant('EDIT') + " " + this.contactService.contactInformation.fullName,
      LookupEntitySetName: 'indskr_contactcrs',
      showLeftHeaderButton: true,
      leftHeaderBtnIcon: 'chevron-back-outline',
      showRightHeaderButton: true,
      rightHeaderImgSrc: 'assets/imgs/header_save.svg',
      rightHeaderBtnText: this.translate.instant('SAVE'),
      formMetadata: contactForm,
      formEntityObject: contactRaw,
      mainEntityObjectId: null,
      dynamicFormType: formType,
      navOptions: { animate: false },
      eventsHandler: (data:any, eventTarget:string, refData:DynamicFormComponentModel)=> this._handleOneKeyContactFormComponentEvent(data,eventTarget),
    };

    this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent, PageName.DynamicFormComponent, PageName.ContactPageComponent, {
      MODE:'EDIT_MODE',
      FORM_DETAIL: formDetails,
      TYPE: businessProcess
    }).then(()=>{
      this.uiService.activeView = 'ContactPageRightPaneNav';
      this.uiService.showNewActivity = false;
    });
  }

  private getFormControl(form: DynamicForm, key): Control {
    let control: Control;
    form.metadata.forEach(e => {
      control = e.controls.find(c => c.attributeName == key);
    })
    return control;
  }

  private getOptionSetMap(control, value) {
    const optionMap = {key:"", value:""};
    const options = control.optionSet;
    options.forEach(o => {
      if (o['displayNames'] && !_.isEmpty(o['displayNames'])) {
        o['displayNames'].forEach(d => {
          if (d['description'] && d['description'] == value) {
            optionMap['key'] = o['value'];
            optionMap['value'] = d['description'];
          }
        })
      }
    })
    return optionMap;
  }

  private async _handleContactFormComponentEvent(data, eventTarget){
    if(eventTarget){
      if(eventTarget == 'DONEBUTTONCLICK'){
        if(data && data.formValue) {
          this.trackingService.tracking('SaveSelected', TrackingEventNames.CONTACT_EDIT, true);
          let loader = await this.loadingCtrl.create();
          loader.present();
          if(this.linkEntitiesReqPayload && this.linkEntitiesReqPayload.length>0){
            const accountContactAffReqPayload = [];
            for (let reqPayload of this.linkEntitiesReqPayload) {
              /*************Edit business information in offline - Non-OneKey contacts*************/
              if(this.contactDataService.isOfflineForAddedNewData(reqPayload)) {
                this.disk.createOfflineLinkedEntity(reqPayload);
                  //To update linked entities for the contact saved
                await this._addOfflineLinkedEntityToDisk(reqPayload);
              }
              /*************Edit business information in offline - Non-OneKey contacts*************/
              else {
                await this.dynamicFormsService.createLinkedEntity(reqPayload.values, reqPayload.entity);
                if (reqPayload.entity === 'indskr_accountcontactaffiliations' && !reqPayload.values.statecode) {
                  accountContactAffReqPayload.push({
                    accountId: reqPayload.values['lookupfields']?.find(req => req['entity'] === 'accounts').id,
                    accountName: null,
                    contactId: reqPayload.values['lookupfields']?.find(req => req['entity'] === 'contacts').id,
                    indskr_accounttype: null
                  });
                }
              }
            }
            this.events.publish("marketScan:newAccountContactAff", accountContactAffReqPayload);
            this.linkEntitiesReqPayload = [];
          }
          
          /*************Edit business information in offline - Non-OneKey contacts*************/
          if (this.contactDataService.isOffline(data.formValue)) {
            this.contactDataService.addToOfflineContactsIds(data.formValue.contactid);
            await this.disk.createOfflineContact(data.formValue);
            //To update business information for the contact saved
            await this.contactDataService.getContactDetailsOffline(data, this.contactService.contactInformation);
            loader.dismiss();
            this.notificationService.notify(this.translate.instant('CONTACT_UPDATE_CREATED'), "ContactInfoComponent", "top", ToastStyle.INFO, 3000);
            data.eventsHandler(data.formValue,'NEWDATAUPDATED');
          } 
          /*************Edit business information in offline - Non-OneKey contacts*************/
          else {
            await this.contactDataService.createDynamicContact(data.formValue).then(async (res) => {
                if(res) {
                  console.log(res);

                  if(res && res['contactid']) {
                    let rawDetails = await this.contactDataService.getContactDetails(this.contactService.contactInformation, true);

                    if(rawDetails) {
                      if(data && data.eventsHandler) {
                        this.events.publish("affilition:updated")
                        loader.dismiss();
                        this.notificationService.notify( this.translate.instant('CONTACT_UPDATE_CREATED'), "ContactInfoComponent", "top", ToastStyle.INFO, 3000);
                        data.eventsHandler(res,'NEWDATAUPDATED');
                      }
                    }
                  }
                  if(loader) loader.dismiss();
                }
            })
            .catch(() => {
                loader.dismiss();
                data.eventsHandler("ERROR", 'NEWDATAUPDATED');
                console.log("Error creating Contact");
            });
          }
        }
      } else if(eventTarget == 'CANCELBUTTONCLICK') {
        this.closePage((data && data.displayConfirmation));
      } else if(eventTarget == 'ADDEDLINKEDENTITY' || eventTarget == 'SCRAPLINKEDENTITY') {
        this.trackingService.tracking('DoneSelected', TrackingEventNames.CONTACT_EDIT, true);
        if(data && data.entity && data.values) {
          this.addLinkEntitiesForContact(data);
          this.dynamicFormsService.linkedEntityFormIsDirtyTracker = true;
        }
        //this.contactDataService.getContactDetails(this.contactService.contactInformation, true);
      } else if (eventTarget == 'ISFORMDIRTY') {
        // this.events.publish('addNewContacts', data);
      }
    }
  }

  private showConfirmationPopup(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      return this.alertService.showAlert({
        title: this.translate.instant('POP_CONFIRM_SUBMIT_POLL'),
        message: this.translate.instant('R_U_SURE_SUBMIT_CR')
      }, this.translate.instant('CONFIRM'),  this.translate.instant('CANCEL')
      ).then(async res => {
        if (res.role === "ok") resolve(true);
        else reject();
      })
    })
  }

  private async _handleAddAccountAffiliationFormComponentEvent(data, eventTarget) {
    if (eventTarget) {
      if (eventTarget == 'DONEBUTTONCLICK') {
        if (data && data.formValue) {
          let payload = _.cloneDeep(data.formValue);
          const contact = this.contactService.contactInformation;
          payload['indskr_mdmrequesttype']= MDMRequestType.ADD_AFFILIATION;
          payload['indskr_mdmid'] = contact.indskr_mdmid;
          payload['indskr_lastname'] = contact.firstName;
          payload['indskr_gendercode'] = contact.gendercode === 1 ? OmniGender.MALE : OmniGender.FEMALE;          
          const linkEntities = this.mdmService.getReqPayloadLinkEntities();
          data.formValue['lookupfields'].push({
            "name": "indskr_ContactCR",
            "entity": "indskr_contactcrs",
            "id": null
          })
          
          const customerSpecialtyId = await this.contactService.getCustomerSpecialtyId(contact.ID, contact.primarySpecialty?.id);
          payload['indskr_mdm'] = data.formValue['indskr_mdm'] ? data.formValue['indskr_mdm'] : 548910000;
          if (payload['indskr_mdm'] != 548910000) {

            if (data.formValue['indskr_mdm']) {
              delete data.formValue['indskr_mdm'];
            }
            payload["linkEntities"] = linkEntities;

            let account = null;
            if (this.businessLine === BusinessLineVal.PHARMA || this.businessLine === BusinessLineVal.VACCINE_MDM) {
              payload["lookupfields"] = [
                {
                  "name": "indskr_Specialty",
                 "entity": "indskr_lu_specialties",
                 "id": contact.primarySpecialty?.id
                }
              ]
            }
            payload['indskr_businessline'] = this.businessLine;
            let mdm_payload: any = this.getDefaultMDMEditPayload(payload, account);
            let shouldThrowEtmsErr = false;
            if (!_.isEmpty(linkEntities)) {
              linkEntities.forEach(e => {
                if (e['entity'] == "indskr_accountcontactaffiliationcrs") {
                  let values: [] = e['values'];
                  if (!_.isEmpty(values)) {
                    values.forEach(v => {
                      let lookupfields: [] = v['lookupfields'];
                      if (!_.isEmpty(lookupfields)) {
                        let acc = lookupfields.find(l => l['entity'] == 'accounts');
                        if (acc) {
                          account = this.accountService.getAccountById(acc['id']);
                          if (!account || !account.extendedDetails || !account.extendedDetails['externalId']) {
                            shouldThrowEtmsErr = true;
                            return;
                          }
                          payload['lookupfields'].push({
                            "name": "indskr_Account",
                            "entity": "accounts",
                            "id": acc['id']
                          });
                        }
                      }
                    })
                  }
                }
              })
            }
            if (shouldThrowEtmsErr) {
              this.notificationService.notify(this.translate.instant('ACCOUNT_ETMS_ERR_NOTIFY', { accountName: account.accountName }), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
              return;
            }
            mdm_payload.new_hco_etms = account.raw['indskr_externalid'] ?account.raw['indskr_externalid'] : "";
            mdm_payload.new_hco_nm = account.accountName;
            mdm_payload.new_hco_vid = account.raw['indskr_hcovid'] ? account.raw['indskr_hcovid'] : "";
            this.mdmService.setNows(mdm_payload, this.businessLine);
            mdm_payload.new_hcp_first_nm = contact.firstName;
            mdm_payload.new_gender = contact.gendercode && contact.gendercode == 1 ? OneCRMGender.MALE : contact.gendercode == 2 ? OneCRMGender.FEMALE : '';
            mdm_payload.new_sa_dept_nm = "";
            mdm_payload.new_dept_nm = "";
            mdm_payload.dcr_type = OneCRMReqType.AFFILIATION;
            payload['mdm_payload'] = mdm_payload;
            payload['indskr_pharmarequesttype'] = 1;
            mdm_payload['new_hcp_etms'] = contact.indskr_externalid ? contact.indskr_externalid : "";
            if (this.businessLine === BusinessLineVal.PHARMA) {
              mdm_payload.dcr_business_line = BusinessLine.PHARMA;
              mdm_payload.new_title = contact.title ? contact.title : "";
              mdm_payload.new_position = data.formStringValue['indskr_rolepharma_Desc'] ? data.formStringValue['indskr_rolepharma_Desc'] : data.formStringValue['indskr_rolepharma'];
              mdm_payload.new_specialty = contact.primarySpecialty ? contact.primarySpecialty.name : "";
            } else if(this.businessLine === BusinessLineVal.VACCINE_MDM) {
              mdm_payload.dcr_business_line = BusinessLine.VACCINE;
              mdm_payload.new_title = contact.title ? contact.title : "";
              mdm_payload.new_position = data.formStringValue['indskr_rolepharma_Desc'] ? data.formStringValue['indskr_rolepharma_Desc'] : data.formStringValue['indskr_rolepharma'];
              mdm_payload.new_specialty = contact.primarySpecialty ? contact.primarySpecialty.name : "";
            } else {
              mdm_payload.dcr_business_line = BusinessLine.REATIL;
              mdm_payload.new_position = data.formStringValue['indskr_positionretail_Desc'] ? data.formStringValue['indskr_positionretail_Desc'] : data.formStringValue['indskr_positionretail'];
              mdm_payload.new_specialty = "";
              mdm_payload.new_title = data.formStringValue['indskr_titleretail_Desc'] ? data.formStringValue['indskr_titleretail_Desc'] : data.formStringValue['indskr_titleretail'];
            }
          }
          if (!await this.showConfirmationPopup()) return; 
          await this.uiService.displayLoader();
          if (payload) {
            await this.createOneKeyContactCR(payload, data).then(() => {
              if (payload['indskr_mdm'] == BusinessProcessType.SanofiChina) {
                this.notificationService.notify(this.translate.instant('ONECRM_DCR_CHANGE_REQUEST_IN_PROCESS'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
              }
            });
          }
          await this.uiService.dismissLoader();
        }
      } else if (eventTarget == 'CANCELBUTTONCLICK') {
        this.closePage((data && data.displayConfirmation));
      } else if (eventTarget == 'ADDEDLINKEDENTITY') {
        if (data) {
          this.mdmService.addLinkEntities(data);
          this.dynamicFormsService.linkedEntityFormIsDirtyTracker = true;
          if (data && data.eventsHandler) {
            data.eventsHandler(data, 'ENABLEFORM');
          }
        }
      } else if (eventTarget == 'ISFORMDIRTY') {
        // this.events.publish('addNewContacts', data);
      }
    }
  }

  private async _handleOneKeyContactFormComponentEvent(data, eventTarget){
    if(eventTarget){
      if(eventTarget == 'DONEBUTTONCLICK'){
        if (data && data.formValue) {

          let payload = _.cloneDeep(data.formValue);
          payload['indskr_mdmrequesttype'] = MDMRequestType.UPDATE;
          payload['indskr_mdmid'] = this.contactService.contactInformation.indskr_mdmid;
          payload["linkEntities"] = this.mdmService.getReqPayloadLinkEntities();
          
          if (!await this.showConfirmationPopup())  return; 
          payload['indskr_mdm'] = data.formValue['indskr_mdm'] ? data.formValue['indskr_mdm'] : 548910000;
          if (payload['indskr_mdm'] != 548910000) {

            let account = null;
            if (data.formValue.lookupfields && data.formValue.lookupfields.length) {
              let accountLookup = data.formValue.lookupfields.filter(e => e.name == "indskr_Account");
              if (!_.isEmpty(accountLookup)) {
                account = this.accountService.getAccountById(accountLookup[0].id);
              }
              if (!account || !account.extendedDetails || !account.extendedDetails['externalId']) {
                this.notificationService.notify(this.translate.instant('ACCOUNT_ETMS_ERR_NOTIFY', { accountName: (account && account.accountName) ? account.accountName : "" }), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
                return;
              }
            }

            payload['indskr_businessline'] = this.businessLine;
            let mdm_payload: any = this.getDefaultMDMEditPayload(payload, account);
            mdm_payload.new_hco_etms = account.raw['indskr_externalid'] ?account.raw['indskr_externalid'] : "";
            mdm_payload.new_hco_nm = account.accountName;
            mdm_payload.new_hco_vid = account.raw['indskr_hcovid'] ? account.raw['indskr_hcovid'] : "";
            this.mdmService.setNows(mdm_payload, this.businessLine);
            mdm_payload.new_hcp_first_nm = `${payload['indskr_lastname']}`;
            mdm_payload.new_gender = payload['indskr_gendercode'] == OmniGender.MALE ? OneCRMGender.MALE : OneCRMGender.FEMALE;
            mdm_payload.new_hcp_first_nm = `${payload['indskr_lastname']}`;
            if (this.businessLine === BusinessLineVal.PHARMA || this.businessLine === BusinessLineVal.RETAIL || this.businessLine === BusinessLineVal.VACCINE_MDM) {
              if (this.businessLine === BusinessLineVal.PHARMA) {
                mdm_payload.dcr_business_line = BusinessLine.PHARMA;
                mdm_payload.new_position = data.formStringValue['indskr_rolepharma_Desc'] ? data.formStringValue['indskr_rolepharma_Desc'] : data.formStringValue['indskr_rolepharma'];
                mdm_payload.new_specialty = data.formStringValue['indskr_specialty'];
                mdm_payload.new_title = data.formStringValue['indskr_titlepharma_Desc'] ? data.formStringValue['indskr_titlepharma_Desc'] : data.formStringValue['indskr_titlepharma'];
              } else if(this.businessLine === BusinessLineVal.VACCINE_MDM) {
                mdm_payload.dcr_business_line = BusinessLine.VACCINE;
                mdm_payload.new_position = data.formStringValue['indskr_rolepharma_Desc'] ? data.formStringValue['indskr_rolepharma_Desc'] : data.formStringValue['indskr_rolepharma'];
                mdm_payload.new_specialty = data.formStringValue['indskr_specialty'];
                mdm_payload.new_title = data.formStringValue['indskr_titlepharma_Desc'] ? data.formStringValue['indskr_titlepharma_Desc'] : data.formStringValue['indskr_titlepharma'];
              } else {
                mdm_payload.dcr_business_line = BusinessLine.REATIL;
                mdm_payload.new_position = data.formStringValue['indskr_positionretail_Desc'] ? data.formStringValue['indskr_positionretail_Desc'] : data.formStringValue['indskr_positionretail'];
                mdm_payload.new_specialty = "";
                mdm_payload.new_title = data.formStringValue['indskr_titleretail_Desc'] ? data.formStringValue['indskr_titleretail_Desc'] : data.formStringValue['indskr_titleretail'];
              }
              mdm_payload.new_dept_nm = "";
              mdm_payload['new_sa_dept_nm'] = "";
              if (data.formValue.lookupfields && data.formValue.lookupfields.length) {
                let specialty = data.formValue.lookupfields.find(e => e['entity'] == "indskr_lu_specialties");
                if (specialty) {
                  let sp = this.contactService.specialties.find(e => e.id == specialty.id);
                  if (sp) {
                    mdm_payload['new_sa_dept_nm'] = sp.indskr_sadeptnm ? sp.indskr_sadeptnm : "";
                  }
                }
              }
              payload['mdm_payload'] = mdm_payload;
              payload['indskr_pharmarequesttype'] = 2;
            } else {
              mdm_payload.dcr_business_line = BusinessLine.VACCINE;
              mdm_payload.new_position = data.formStringValue['indskr_rolevaccine_Desc'] ? data.formStringValue['indskr_rolevaccine_Desc'] : data.formStringValue['indskr_rolevaccine'];
              mdm_payload.new_dept_nm = data.formStringValue['indskr_departmentvaccine_Desc'] ? data.formStringValue['indskr_departmentvaccine_Desc'] : data.formStringValue['indskr_departmentvaccine'];
              mdm_payload.new_specialty = "";
              mdm_payload.new_sa_dept_nm = "";
              mdm_payload.new_title = "";
              mdm_payload.new_hcp_auth = true;
              mdm_payload.now_hcp_auth = true;
              payload['mdm_payload'] = mdm_payload;
              payload["lookupfields"].push(
                {
                  "name": "indskr_Contact",
                  "entity": "contacts",
                  "id": this.contactService.contactInformation.ID
                })
            }
          }
          await this.uiService.displayLoader();
          if (payload) {
            await this.createOneKeyContactCR(payload, data).then(() => {
              if (payload['indskr_mdm'] == BusinessProcessType.SanofiChina) {
                this.notificationService.notify(this.translate.instant('ONECRM_DCR_CHANGE_REQUEST_IN_PROCESS'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
              }
            });
          }
          await this.uiService.dismissLoader();
        }
      } else if(eventTarget == 'CANCELBUTTONCLICK') {
        this.closePage((data && data.displayConfirmation));
      } else if(eventTarget == 'ADDEDLINKEDENTITY') {
        if (data) {
          this.dynamicFormsService.linkedEntityFormIsDirtyTracker = true;
          this.mdmService.addLinkEntities(data);
          if (data && data.eventsHandler) {
            data.eventsHandler(data, 'ENABLEFORM');
          }
        }
      } else if (eventTarget == 'ISFORMDIRTY') {
        // this.events.publish('addNewContacts', data);
      }
    }
  }

  private async createOneKeyContactCR(payload: any, data: any) {
    await this.mdmService.createOneKeyContactCR(payload).then(async (res) => {
      try {
        if (res && res['indskr_contactcrid']) {
          if (res.hasOwnProperty('indskr_isduplicate') && res['indskr_isduplicate']) {
            this.notificationService.notify(this.translate.instant('CRM_DUP_CR_ERR'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
          }
          if (data && data.eventsHandler) {
            data.eventsHandler(res, 'DISABLEFORM');
            this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
            this.notificationService.notify(this.translate.instant('CR_UPDATE_SUBMIT_MESSAGE', { crType: this.utilityService.globalCustomerText.toLowerCase(), lastName: payload['indskr_lastname'] }), 'Contact-Form-Component');
            if (this.authService.okIntegrationSettings && this.authService.okIntegrationSettings.typeOfIntegration == IntegrationType.ONE_KEY) {

              await this.mdmService.addNotification(data, res, NOTIFICATION.ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED, this.translate.instant('ONE_KEY_CHNAGE_REQUEST_UPDATE_REQUEST_SUBMITTED',
                { fullname: data.formValue['indskr_firstname'] + " " + data.formValue['indskr_lastname'] }), 'Submitted');
            } else {
              if (payload['indskr_mdm'] == BusinessProcessType.SanofiChina) {
                await this.mdmService.addNotification(data, res, NOTIFICATION.ONECRM_UPDATE_DCR_REQUEST_STAGED, this.translate.instant('ONECRM_UPDATE_DCR_REQUEST_STAGED',
                { fullname: data.formValue['indskr_lastname'] }), 'Submitted');
              } else {
                await this.mdmService.addNotification(data, res, NOTIFICATION.ONE_KEY_CHANGE_REQUEST_UPDATE_STAGED, this.translate.instant('ONE_KEY_CHANGE_REQUEST_UPDATE_STAGED',
                { fullname: data.formValue['indskr_firstname'] + " " + data.formValue['indskr_lastname'] }), 'Submitted');
              }
            }
          }
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
      await this.uiService.dismissLoader();
    })
      .catch((err) => {
        if(err.error) {
          if (err.error.error && err.error.error == 'MDM_DCR_CREATION_FAILED') {
            this.notificationService.notify(this.translate.instant('ONECRM_DCR_CHANGE_REQUEST_FAILED'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
          } else if (err.error.errorMessage && err.error.errorMessage.includes('Contact CR already exists')) {
            this.notificationService.notify(this.translate.instant('CRM_DUP_CR_ERR'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
          } else if (err.error.message && err.error.message.includes('Contact CR already exists')) {
            this.notificationService.notify(this.translate.instant('CRM_DUP_CR_ERR'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
          } else if (err.error.errorMessage && err.error.errorMessage.includes('Contact already exists')) {
            this.notificationService.notify(this.translate.instant('CRM_DUP_CONTACT_ERR'), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
          }
        }
        this.uiService.dismissLoader();
        console.log("Error creating Contact change request");
        throw err;
      });
  }

  private getDefaultMDMEditPayload(payload: any, account: any): any {
    let userEmail = this.authService.user.mail;
    if (userEmail.includes('sanofi.net.cn'))
      userEmail = userEmail.replace('sanofi.net.cn', 'sanofi.com');
    return {
      "dcr_source": DCR_SOURCE.ONECRM,
      "dcr_requestor_staff_cd": null,
      "dcr_requestor_nm": this.authService.user.displayName,
      "dcr_requestor_email": userEmail,
      "dcr_category": DCR_CATEGORY.HCP,
      "dcr_type": OneCRMReqType.UPDATE,
      "new_hcp_status": "ACTV",
      "new_hcp_auth": ""
    };
  }

  closePage(isCancel?: boolean) {
    if(isCancel) {
        let cancelText = this.translate.instant("CANCEL");
        if(this.translate.currentLang == 'it') {
          cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
        }
        this.alertService.showAlert({
            title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
            message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')}, this.translate.instant('DISCARD_CONTACT_CR'), cancelText
        ).then (res => {
            if(res.role == "ok") {
                this.dynamicFormsService.okLookUpMapper = [];
                this.trackingService.tracking('DiscardSelected', TrackingEventNames.CONTACT_EDIT, true);
                this.uiService.showNewActivity= false;
                this.navService.popChildNavPageWithPageTracking();
            } else {
              this.trackingService.tracking('CancelSelected', TrackingEventNames.CONTACT_EDIT, true);
            }
        });
    } else {
        this.trackingService.tracking('CancelSelected', TrackingEventNames.CONTACT_EDIT, true);
        this.uiService.showNewActivity= false;
        this.navService.popChildNavPageWithPageTracking();

    }
  }

  public async updateNote(ev) {

    if (ev && ev.action) {
      const idx = this.currentCustomerNotes.findIndex(note => note.noteId == ev.noteId);
      if (idx < 0) {
        console.error("Error : coundn't find note id --> ", ev.noteId);
        return
      }
      let existingNote = this.currentCustomerNotes[idx];
      let offlinePayload: any = {
        annotationid: existingNote.noteId,
        contactid: existingNote.contactId,
        createdon: existingNote.createdTime.getTime().toString(),
        notetext: existingNote.noteText,
        ownerName: existingNote.ownerName,
        ownerid: existingNote.ownerId,
        isdocument: existingNote.hasDocument,
        filename: existingNote.documentName,
        mimetype: existingNote.documentMimeType,
        filesize: existingNote.documentSize,
        pendingPushOnDynamics: true
      };
      if (existingNote.noteId.includes("offlinecustomernote") && existingNote.hasDocument && !_.isEmpty(existingNote.documentDataURL)) {
        offlinePayload.documentbody = existingNote.documentDataURL;
      }
      if (ev.action == 'DELETE' && idx >= 0) {
        offlinePayload.deleted = true;
        this.currentCustomerNotes.splice(idx, 1);
      } else if (ev.action == 'SAVE' && idx >= 0) {
        this.currentCustomerNotes[idx].noteText = offlinePayload.notetext = ev.updatedText
        if (ev.attachmentFileUpdated) {
          this.currentCustomerNotes[idx].hasDocument = offlinePayload['isdocument'] = true;
          offlinePayload['documentbody'] = ev.attachmentFileDataUrl;
          this.currentCustomerNotes[idx].documentName = offlinePayload['filename'] = ev.documentName;
          this.currentCustomerNotes[idx].documentSize = offlinePayload['filesize'] = ev.documentSize;
          this.currentCustomerNotes[idx].documentMimeType = offlinePayload['mimetype'] = ev.documentMimeType;
          offlinePayload['fileupdated'] = true;
        }
        if (ev.isAttachmentRemoved) {
          this.currentCustomerNotes[idx].hasDocument = offlinePayload['isdocument'] = false;
          this.currentCustomerNotes[idx].documentMimeType = this.currentCustomerNotes[idx].documentName = '';
          offlinePayload['documentbody'] = offlinePayload['filename'] = offlinePayload['filesize'] = offlinePayload['mimetype'] = '';
          offlinePayload['fileremoved'] = true;
        }
      }
      const loader = await this.loadingCtrl.create();
      loader.present();
      // Update Service
      this._sortCustomerNotes();
      await this.contactDataService.updateCustomerNoteInOfflineDB(offlinePayload);
      if (!this.device.isOffline) {
        await this.contactDataService.uploadCustomerNotesOnline().then(res => {
          if (ev.action != 'DELETE' && res && Array.isArray(res) && res.length > 0 && res[0].noteid) {
            this.currentCustomerNotes[idx].noteId = res[0].noteid;
          }
        });
      }
      loader.dismiss();
    }
  }

  public isNoteControlsEnabled(note: IONote): boolean {
    return !!(note.ownerId == this.authService.user.systemUserID);
  }

  private initBusinessRules(contactForm: DynamicForm) {
    try {
      if (!this.rawFormData) return;
      const noConsent = this.rawFormData.omniveev_pdrpoptout === 200000002 && isPast(this.rawFormData.omniveev_pdrpoptoutdate);
      if (noConsent) {
        this.rawFormData.RxSalesData_NoConsentMessage = 'Rx/sales data cannot be displayed due to PDRP restrictions'
      }
      for (const tab of contactForm.metadata) {
        for (const control of tab.controls) {
          if (control.attributeName === 'SalesDataSubGrid') {
            control.isVisible = !noConsent;
            control.forceHide = noConsent;
          }
          if ((control.displayNames || [])[0]?.description === 'RxSalesData_NoConsentMessage') {
            control.isVisible = noConsent;
            if (control.isVisible) {
              control.displayNames[0].description = '';
              control.dataType = ControlDataType.StringType;
              control.attributeName = 'RxSalesData_NoConsentMessage';
              control.isReadOnly = true;
            }
          }
        }
      }
    } catch (ex) {
      console.error('Unable to init business rules', ex);
    }
  }

  private async initContactInfo() {
    if (this.contactService.contactInformation) {
      let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("contact", FormType.DISPLAYFORM);
      let formType = DynamicFormType.CONFIGUREDFORM;
      if (!contactForm) {
        contactForm = new DynamicForm(DEFAULT_CONTACT_DISPLAY_FORM['value'][0]);
        formType = DynamicFormType.DEFAULTFORM;
      }
      if (this.authService.hasFeatureAction(FeatureActionsMap.CUSTOMER_LIST_MANAGEMENT)) {
        const listPeriodTags = this.territoryService.getListPeriodTags();
        this.contactService.contactInformation.raw['tagLabels'] = this.contactService.contactInformation.raw['tagLabels'] ? this.contactService.contactInformation.raw['tagLabels'].filter(tag => listPeriodTags.includes(tag)) : [];
        const index = contactForm.metadata[0].controls.findIndex(md => md.subgrid);
        if (index >= 0) {
          //Add Tags at last field in contact fields
          contactForm.metadata[0].controls.splice(index, 0, new Control(TAG_LABEL_CONTROL));
        }
      }
      this.initBusinessRules(contactForm);
      this.dynamicFormType = formType;
      this.displayFormDetails = contactForm;
      this.rawFormData = this.contactService.contactInformation.raw;
      this.isSaveNotesEnabled = false;
      this.tempNoteText = '';
      setTimeout(() => {
        this._initCustomerNotesViewData();
        this.initCustomerNotesData();
        this.initKOLStatusViewData(); 
        //this._initMedicalInsightsViewData();
        //this._initMedicalInsightsData();
      }, 100);
      const isContactfromContactToolEditable = this.isEditFeatureEnabled
        && (!this.contactService.isInGlobalSearch || this.businessLine === BusinessLineVal.PHARMA || this.businessLine === BusinessLineVal.RETAIL || this.businessLine === BusinessLineVal.VACCINE_MDM)
        && (this.contactService.contactPageMode === this.compViewMode.LISTVIEW || this.contactService.contactPageMode === this.compViewMode.ADDNEW)
        && !this.uiService.isConsentFromToolDrawer;
      if (this.contactService.contactPageMode === ComponentViewMode.PREVIEW) {
        this.isPreViewMode = true;
        this.isEditable = false;
        this.isEditDisabled = true;
      } else {
        this.isPreViewMode = false;
        this.isEditable = isContactfromContactToolEditable;
        this.isEditDisabled = false;
      }
      this.events.publish('refreshContactDetailPageTitle');
    }
  }

  private addLinkEntitiesForContact(linkEntity) {
    if (_.isEmpty(linkEntity))
      this.linkEntitiesReqPayload = [];
    else {
      const linkEntityPayload = {
        "values": linkEntity["values"],
        "entity": linkEntity["entity"],
        "linkedEntityId": linkEntity["linkedEntityExistingDataId"],
        "addedNewDataId": linkEntity["linkedEntityAddedNewDataId"]
      }
      if (!_.isEmpty(this.linkEntitiesReqPayload)) {
        let index;
          if (linkEntity["linkedEntityExistingDataId"]) {
            index = this.linkEntitiesReqPayload.findIndex(x => x.linkedEntityId == linkEntity["linkedEntityExistingDataId"]);
          } else {
            index = this.linkEntitiesReqPayload.findIndex(x => x.addedNewDataId == linkEntity["linkedEntityAddedNewDataId"]);
          }
          if (index != -1) {
            this.linkEntitiesReqPayload[index] = linkEntityPayload;
          } else this.linkEntitiesReqPayload.push(linkEntityPayload);
        } else {
          this.linkEntitiesReqPayload.push(linkEntityPayload);
        }
    }
  }

  private async initOKContactInfo() {
    if (this.contactService.selectedOKContact.value) {
      let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("contact", FormType.DISPLAYFORM, BusinessProcessType.OneKey);
      let formType = DynamicFormType.CONFIGUREDFORM;
      if (!contactForm) {
        contactForm = new DynamicForm(DEFAULT_CONTACT_DISPLAY_FORM['value'][0]);
        formType = DynamicFormType.DEFAULTFORM;
      }
      this.initBusinessRules(contactForm);
      this.dynamicFormType = formType;
      this.displayFormDetails = contactForm;
      this.isEditable = false;
      this.rawFormData = this.rawOKContactObj;
      this.events.publish('refreshContactDetailPageTitle');
    }
  }

  get rawOKContactObj(): any {
    let contactObj = this.contactService.selectedOKContact.value;
    let rawContact = {
      "contactid": "",
      "firstname": contactObj['firstName'] ? contactObj['firstName'] : "",
      "indskr_title": contactObj['titleCode'] ? Number(contactObj['titleCode']) : 0,
      "title": contactObj['titleLabel'] ? contactObj['titleLabel'] : "",
      "indskr_title@OData.Community.Display.V1.FormattedValue": contactObj['titleCorporateLabel'] ? contactObj['titleCorporateLabel'] : "",
      "statuscode@OData.Community.Display.V1.FormattedValue": contactObj['statusCorporateLabel'],
      "statuscode": Number(contactObj['statusCode']),
      "fullname": this.contactService.getFullName(contactObj),
      "lastname": contactObj['lastName'] ? contactObj['lastName'] : "",
      "middlename": contactObj['middleName'] ? contactObj['middleName'] : '',
      "indskr_mdmid": contactObj['individualEid'],
      "indskr_contactsourcetype":ChangeRequestType.MDM,
      "indskr_contactsourcetype@OData.Community.Display.V1.FormattedValue":"MDM",
      "indskr_contactsourcetype_Formatted":"MDM",
      "indskr_mdm":MDMType.ONEKEY,
      "indskr_mdm@OData.Community.Display.V1.FormattedValue":"OneKey",
      "indskr_mdm_Formatted":"OneKey",
    };
    return rawContact;
  }

  private handleNoteAttachment(){
    if(this.isCustomerNotesAttachmentEnabled){
      try {
        this.attachInput.nativeElement.click();
      } catch (error) {
        console.log(error);
      }
    }
  }

  public removeAttachment(event){
    try {
      this.attachInput.nativeElement.value = null;
    } catch (error) {
      console.log(error);
    }
    this.attachmentTitle = '';
    this.isAttachmentAdded = false;
    this.attachmentFile = null;
    this.base64str = null;
    this._initCustomerNotesViewData();
  }

  public isNoteDeletable(note: IONote): boolean {
    return ((this.isCustomerNotesEnabled && this.isCustomerNotesAttachmentEnabled) || (this.isCustomerNotesEnabled && !note.hasDocument) || (this.isCustomerNotesAttachmentEnabled && _.isEmpty(note.noteText))) && _.isEqual(note.ownerId, this.authService.user.systemUserID);
  }

  public isNoteEditable(note: IONote): boolean {
    return (this.isCustomerNotesEnabled || this.isCustomerNotesAttachmentEnabled) && _.isEqual(note.ownerId, this.authService.user.systemUserID);
  }


  public async loadImageFromDevice(event) {
    if (event && event.target && event.target.files) {
      try {
        const file = event.target.files[0];
        if ((file.size / 1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE) {
          if (NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(file.name)) {
            this.base64str = await toBase64(file);
            const dataURLPattern = /^data:[^;]+;base64,/;
            this.base64str = this.base64str.replace(dataURLPattern, '');
            this.attachmentFile = file;
            this.attachmentTitle = file.name;
            this.isAttachmentAdded = true;
            this._initCustomerNotesViewData();
          } else {
            this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'), 'Account Info', 'top', 'info');
            this.removeAttachment(false);
          }
        } else {
          this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION', { size: MAXIMUM_NOTE_ATTACHMENT_SIZE }), 'Account Info', 'top', 'info');
          this.removeAttachment(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  public async handleSaveNote() {
    if (!_.isEmpty(this.tempNoteText) || this.isAttachmentAdded) {
      // this.uiService.displayLoader();

      const loader = await this.loadingCtrl.create();
      loader.present();

      this.isSaveNotesEnabled = false;
      const now = new Date().getTime().toString();
      const offlinePayload = {
        annotationid: 'offlinecustomernote' + now,
        contactid: this.contactService.contactInformation.ID,
        createdon: now,
        notetext: this.tempNoteText,
        ownerName: this.authService.user.displayName,
        ownerid: this.authService.user.systemUserID,
        deleted: false,
        pendingPushOnDynamics: true,
      };

      if (this.base64str) {
        offlinePayload['isdocument'] = true;
        offlinePayload['documentbody'] = this.base64str;
        offlinePayload['filename'] = this.attachmentFile.name;
        offlinePayload['filesize'] = this.attachmentFile.size;
        offlinePayload['mimetype'] = this.attachmentFile.type;
      }

      let currentNote = new IONote(offlinePayload);
      await this.contactDataService.updateCustomerNoteInOfflineDB(offlinePayload);
      if (!this.device.isOffline) {
        await this.contactDataService.uploadCustomerNotesOnline().then(res => {
          if (res && Array.isArray(res) && res.length > 0 && res[0].noteid) {
            currentNote.noteId = res[0].noteid;
          }
        });
      }
      this.currentCustomerNotes.push(currentNote);
      this._sortCustomerNotes();
      this.tempNoteText = '';
      this.removeAttachment(null);
      this.isSaveNotesEnabled = false;
      this._initCustomerNotesViewData();
      loader.dismiss();
    }
  }

  private _sortCustomerNotes(){
    if(this.currentCustomerNotes && this.currentCustomerNotes.length > 1){
      this.currentCustomerNotes = this.currentCustomerNotes.sort((a,b)=>{
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1);
      });
    }
  }
  private async _addOfflineLinkedEntityToDisk(linkedEntity){
    const linkedEntityDBPrefix = '';
    const selectedContact = this.contactService.contactInformation;
    let dbKey = linkedEntity.entity;
    
    let values = linkedEntity.values;

    if(linkedEntity.entity == 'indskr_email_addresses')
      dbKey = 'indskr_email_address';
    else if(linkedEntity.entity == 'indskr_accountcontactaffiliations') {
      dbKey = 'indskr_accountcontactaffiliation';
    }

    let rawLEData = await this.disk.retrieve(linkedEntityDBPrefix + dbKey);

    if(rawLEData && rawLEData.raw) {

        if(dbKey === 'indskr_email_address') {
          let existingLinkedEntity = rawLEData.raw.find(item => item['indskr_email_address.indskr_email_addressid'] === linkedEntity.linkedEntityId);
          let index = rawLEData.raw.indexOf(existingLinkedEntity);
          
          if(existingLinkedEntity) {
           
            for (const key in values) {
              let customKey = `${dbKey}.${key}`;
            
              if (existingLinkedEntity.hasOwnProperty(customKey)) {
                console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                console.warn(`${customKey} : ${values[key]}`);
            
                existingLinkedEntity[customKey] = values[key];
              }

              if(customKey === 'indskr_email_address.indskr_isprimary')
              {
                let value = values[key] === true ? 'Yes':'No' ;

                let newCustomKey = `${dbKey}.indskr_isprimary_Formatted`;

                if (existingLinkedEntity.hasOwnProperty(newCustomKey)) {
                  console.log(`${newCustomKey} : ${existingLinkedEntity[newCustomKey]}`);
                  console.warn(`${newCustomKey} : ${value}`);
              
                  existingLinkedEntity[newCustomKey] = value;
                }

                newCustomKey = `${dbKey}.indskr_isprimary@OData.Community.Display.V1.FormattedValue`;

                if (existingLinkedEntity.hasOwnProperty(newCustomKey)) {
                  console.log(`${newCustomKey} : ${existingLinkedEntity[newCustomKey]}`);
                  console.warn(`${newCustomKey} : ${value}`);
              
                  existingLinkedEntity[newCustomKey] = value;
                }
              }

              customKey = `contactid`;
              
              if (existingLinkedEntity.hasOwnProperty(customKey)) {
                console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                console.warn(`${customKey} : ${selectedContact.ID}`);
            
                existingLinkedEntity[customKey] = selectedContact.ID;
              }
            }

            rawLEData.raw[index] = existingLinkedEntity;
          }//end of if(existingLinkedEntity)
          else {
            let newLinkEntityData = Object.assign({}, rawLEData.raw[0]);

            for (const key in values) {
              let customKey = `${dbKey}.${key}`;
            
              if (newLinkEntityData.hasOwnProperty(customKey)) {
                console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                console.warn(`${customKey} : ${values[key]}`);
            
                newLinkEntityData[customKey] = values[key];
              }

              if(customKey === 'indskr_email_address.indskr_isprimary')
              {
                let value = values[key] === true ? 'Yes':'No' ;

                let newCustomKey = `${dbKey}.indskr_isprimary_Formatted`;

                if (newLinkEntityData.hasOwnProperty(newCustomKey)) {
                  console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                  console.warn(`${newCustomKey} : ${value}`);
              
                  newLinkEntityData[newCustomKey] = value;
                }

                newCustomKey = `${dbKey}.indskr_isprimary@OData.Community.Display.V1.FormattedValue`;

                if (newLinkEntityData.hasOwnProperty(newCustomKey)) {
                  console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                  console.warn(`${newCustomKey} : ${value}`);
              
                  newLinkEntityData[newCustomKey] = value;
                }
              }

              customKey = `contactid`;
              
              if (newLinkEntityData.hasOwnProperty(customKey)) {
                console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                console.warn(`${customKey} : ${selectedContact.ID}`);
            
                newLinkEntityData[customKey] = selectedContact.ID;
              }
            }

            rawLEData.raw.push(newLinkEntityData);
          }
        }//end of if(dbKey === 'indskr_email_address') {
        else if(dbKey === 'indskr_accountcontactaffiliation') {

          let existingLinkedEntity = rawLEData.raw.find(item => item['indskr_accountcontactaffiliation.indskr_accountcontactaffiliationid'] === linkedEntity.linkedEntityId);
          let index = rawLEData.raw.indexOf(existingLinkedEntity);

          if(existingLinkedEntity) {
            if(Object.keys(values).findIndex(key => key == 'statecode')>-1 && values['statecode'] == 1) {
              rawLEData.raw.splice(index,1);
            } else {
              for (const key in values) {
                if(key === 'lookupfields') {
                  const accounts = values[key].find(item => item['entity'] === 'accounts');
                  const omnione_lu_AffiliationRole = values[key].find(item => item['name'] === 'omnione_lu_AffiliationRole');
                  const affiliatedAccount = this.accountService.getAccountById(accounts['id']);
    
                  let customKey = `${dbKey}.indskr_accountid`;
    
                  console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                  console.warn(`${customKey} : ${accounts['id']}`);
              
                  existingLinkedEntity[customKey] = accounts['id'];
    
                  customKey = `${dbKey}.indskr_accountid@OData.Community.Display.V1.FormattedValue`;
                  
                  console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                  console.warn(`${customKey} : ${affiliatedAccount.accountName}`);
              
                  existingLinkedEntity[customKey] = affiliatedAccount.accountName;
    
                  customKey = `${dbKey}.indskr_accountid_Formatted`;
                  
                  console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                  console.warn(`${customKey} : ${affiliatedAccount.accountName}`);
              
                  existingLinkedEntity[customKey] = affiliatedAccount.accountName;
      
                  let value = values['indskr_isprimaryaccount'] === true ? 'Yes':'No' ;
    
                  let newCustomKey = `${dbKey}.indskr_isprimaryaccount`;
    
                  console.log(`${newCustomKey} : ${existingLinkedEntity[newCustomKey]}`);
                  console.warn(`${newCustomKey} : ${value}`);
              
                  existingLinkedEntity[newCustomKey] = value;
    
                  newCustomKey = `${dbKey}.indskr_isprimaryaccount@OData.Community.Display.V1.FormattedValue`;
    
                  console.log(`${newCustomKey} : ${existingLinkedEntity[newCustomKey]}`);
                  console.warn(`${newCustomKey} : ${value}`);
              
                  existingLinkedEntity[newCustomKey] = value;
    
                  customKey = `contactid`;
                  
                  console.log(`${customKey} : ${existingLinkedEntity[customKey]}`);
                  console.warn(`${customKey} : ${selectedContact.ID}`);
              
                  existingLinkedEntity[customKey] = selectedContact.ID;
    
                  if(omnione_lu_AffiliationRole) {
                    newCustomKey = `${dbKey}.omnione_lu_affiliationrole`;
    
                    console.log(`${newCustomKey} : ${existingLinkedEntity[newCustomKey]}`);
                    console.warn(`${newCustomKey} : ${omnione_lu_AffiliationRole['id']}`);
                
                    existingLinkedEntity[newCustomKey] = omnione_lu_AffiliationRole['id'];
                    
                    const selectedOmnione_lu_affiliationrole = this.dynamicFormsService.allLookUpFieldsForOfflineEdit['omnione_lu_affiliationrole'].find(item => item['id'] == omnione_lu_AffiliationRole['id']);
    
                    newCustomKey = `${dbKey}.omnione_lu_affiliationrole@OData.Community.Display.V1.FormattedValue`;
    
                    console.log(`${newCustomKey} : ${existingLinkedEntity[newCustomKey]}`);
                    console.warn(`${newCustomKey} : ${selectedOmnione_lu_affiliationrole['nameForSearch']}`);
                
                    existingLinkedEntity[newCustomKey] = selectedOmnione_lu_affiliationrole['nameForSearch'];
    
                    newCustomKey = `${dbKey}.omnione_lu_affiliationrole_Formatted`;
    
                    console.log(`${newCustomKey} : ${existingLinkedEntity[newCustomKey]}`);
                    console.warn(`${newCustomKey} : ${selectedOmnione_lu_affiliationrole['nameForSearch']}`);
                
                    existingLinkedEntity[newCustomKey] = selectedOmnione_lu_affiliationrole['nameForSearch'];
                  }
                  
                }
                else if(key === 'indskr_isprimaryaccount') {
                  let newCustomKey = `${dbKey}.indskr_isprimaryaccount`;
    
                  console.log(`${newCustomKey} : ${existingLinkedEntity[newCustomKey]}`);
                  console.warn(`${newCustomKey} : ${values[key]}`);
              
                  existingLinkedEntity[newCustomKey] = values[key];
    
                  let value = values[key] === true ? 'Yes':'No' ;
    
                  newCustomKey = `${dbKey}.indskr_isprimaryaccount_Formatted`;
    
                  console.log(`${newCustomKey} : ${existingLinkedEntity[newCustomKey]}`);
                  console.warn(`${newCustomKey} : ${value}`);
              
                  existingLinkedEntity[newCustomKey] = value;
    
                  newCustomKey = `${dbKey}.indskr_isprimaryaccount@OData.Community.Display.V1.FormattedValue`;
    
                  console.log(`${newCustomKey} : ${existingLinkedEntity[newCustomKey]}`);
                  console.warn(`${newCustomKey} : ${value}`);
              
                  existingLinkedEntity[newCustomKey] = value;
                } 
                else if(key === 'indskr_affiliationsourcetype') {
                  let newCustomKey = `${dbKey}.indskr_affiliationsourcetype`;
    
                  console.log(`${newCustomKey} : ${existingLinkedEntity[newCustomKey]}`);
                  console.warn(`${newCustomKey} : ${values[key]}`);
              
                  existingLinkedEntity[newCustomKey] = values[key];
    
                  let value = values[key] == '548910000' ? 'Business':'MDM' ;
    
                  newCustomKey = `${dbKey}.indskr_affiliationsourcetype_Formatted`;
    
                  console.log(`${newCustomKey} : ${existingLinkedEntity[newCustomKey]}`);
                  console.warn(`${newCustomKey} : ${value}`);
              
                  existingLinkedEntity[newCustomKey] = value;
    
                  newCustomKey = `${dbKey}.indskr_affiliationsourcetype@OData.Community.Display.V1.FormattedValue`;
    
                  console.log(`${newCustomKey} : ${existingLinkedEntity[newCustomKey]}`);
                  console.warn(`${newCustomKey} : ${value}`);
              
                  existingLinkedEntity[newCustomKey] = value;
               }            
              }//end of for (const key in values)
  
              rawLEData.raw[index] = existingLinkedEntity;
            }           
          }
          else {

            let newLinkEntityData = {
              "@odata.etag": "W/\"645798083\"",
              "contactid": "c27fbf73-8ba9-e911-a9a8-000d3a8210e3",
              "indskr_accountcontactaffiliation.indskr_affiliationsourcetype@OData.Community.Display.V1.AttributeName": "indskr_affiliationsourcetype",
              "indskr_accountcontactaffiliation.indskr_affiliationsourcetype@OData.Community.Display.V1.FormattedValue": "Business",
              "indskr_accountcontactaffiliation.indskr_affiliationsourcetype": 548910000,
              "indskr_accountcontactaffiliation.createdon@OData.Community.Display.V1.AttributeName": "createdon",
              "indskr_accountcontactaffiliation.createdon@OData.Community.Display.V1.FormattedValue": "06/18/2021 06:24 PM",
              "indskr_accountcontactaffiliation.createdon": "2021-06-18T12:54:23.000Z",
              "indskr_accountcontactaffiliation.statuscode@OData.Community.Display.V1.AttributeName": "statuscode",
              "indskr_accountcontactaffiliation.statuscode@OData.Community.Display.V1.FormattedValue": "Verified",
              "indskr_accountcontactaffiliation.statuscode": 1,
              "indskr_accountcontactaffiliation.statecode@OData.Community.Display.V1.AttributeName": "statecode",
              "indskr_accountcontactaffiliation.statecode@OData.Community.Display.V1.FormattedValue": "Active",
              "indskr_accountcontactaffiliation.statecode": 0,
              "indskr_accountcontactaffiliation.omnione_lu_affiliationrole@OData.Community.Display.V1.AttributeName": "omnione_lu_affiliationrole",
              "indskr_accountcontactaffiliation.omnione_lu_affiliationrole@OData.Community.Display.V1.FormattedValue": "Accounting and Finance",
              "indskr_accountcontactaffiliation.omnione_lu_affiliationrole@Microsoft.Dynamics.CRM.lookuplogicalname": "omnione_onekeycodeslabels",
              "indskr_accountcontactaffiliation.omnione_lu_affiliationrole": "ddb8df53-f0c9-ec11-a7b6-000d3a857cb6",
              "indskr_accountcontactaffiliation.indskr_isprimaryaccount@OData.Community.Display.V1.AttributeName": "indskr_isprimaryaccount",
              "indskr_accountcontactaffiliation.indskr_isprimaryaccount@OData.Community.Display.V1.FormattedValue": "No",
              "indskr_accountcontactaffiliation.indskr_isprimaryaccount": false,
              "indskr_accountcontactaffiliation.indskr_accountid@OData.Community.Display.V1.AttributeName": "indskr_accountid",
              "indskr_accountcontactaffiliation.indskr_accountid@OData.Community.Display.V1.FormattedValue": "0516_Leah Test AccountBān",
              "indskr_accountcontactaffiliation.indskr_accountid@Microsoft.Dynamics.CRM.lookuplogicalname": "account",
              "indskr_accountcontactaffiliation.indskr_accountid": "0a79759e-95b7-eb11-8236-002248173c8c",
              "indskr_accountcontactaffiliation.indskr_accountcontactaffiliationid@OData.Community.Display.V1.AttributeName": "indskr_accountcontactaffiliationid",
              "indskr_accountcontactaffiliation.indskr_accountcontactaffiliationid": "e5bc644b-34d0-eb11-bacc-00224816cd59",
              "indskr_accountcontactaffiliation.indskr_affiliationsourcetype_Formatted": "Business",
              "indskr_accountcontactaffiliation.createdon_Formatted": "06/18/2021 06:24 PM",
              "indskr_accountcontactaffiliation.statuscode_Formatted": "Verified",
              "indskr_accountcontactaffiliation.statecode_Formatted": "Active",
              "indskr_accountcontactaffiliation.omnione_lu_affiliationrole_Formatted": "Accounting and Finance",
              "indskr_accountcontactaffiliation.omnione_lu_affiliationrole_LogicalName": "omnione_onekeycodeslabels",
              "indskr_accountcontactaffiliation.indskr_isprimaryaccount_Formatted": "No",
              "indskr_accountcontactaffiliation.indskr_accountid_Formatted": "0516_Leah Test AccountBān",
              "indskr_accountcontactaffiliation.indskr_accountid_LogicalName": "account"
            }

            for (const key in values) {
              if(key === 'lookupfields') {
                const accounts = values[key].find(item => item['entity'] === 'accounts');
                const omnione_lu_AffiliationRole = values[key].find(item => item['name'] === 'omnione_lu_AffiliationRole');
                const affiliatedAccount = this.accountService.getAccountById(accounts['id']);

                let customKey = `${dbKey}.indskr_accountid`;

                console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                console.warn(`${customKey} : ${accounts['id']}`);
            
                newLinkEntityData[customKey] = accounts['id'];

                customKey = `${dbKey}.indskr_accountid@OData.Community.Display.V1.FormattedValue`;
                
                console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                console.warn(`${customKey} : ${affiliatedAccount.accountName}`);
            
                newLinkEntityData[customKey] = affiliatedAccount.accountName;

                customKey = `${dbKey}.indskr_accountid_Formatted`;
                
                console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                console.warn(`${customKey} : ${affiliatedAccount.accountName}`);
            
                newLinkEntityData[customKey] = affiliatedAccount.accountName;
    
                let value = values['indskr_isprimaryaccount'] === true ? 'Yes':'No' ;

                let newCustomKey = `${dbKey}.indskr_isprimaryaccount`;

                console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                console.warn(`${newCustomKey} : ${value}`);
            
                newLinkEntityData[newCustomKey] = value;

                newCustomKey = `${dbKey}.indskr_isprimaryaccount@OData.Community.Display.V1.FormattedValue`;

                console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                console.warn(`${newCustomKey} : ${value}`);
            
                newLinkEntityData[newCustomKey] = value;

                customKey = `contactid`;
                
                console.log(`${customKey} : ${newLinkEntityData[customKey]}`);
                console.warn(`${customKey} : ${selectedContact.ID}`);
            
                newLinkEntityData[customKey] = selectedContact.ID;

                if(omnione_lu_AffiliationRole) {
                  newCustomKey = `${dbKey}.omnione_lu_affiliationrole`;

                  console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                  console.warn(`${newCustomKey} : ${omnione_lu_AffiliationRole['id']}`);
              
                  newLinkEntityData[newCustomKey] = omnione_lu_AffiliationRole['id'];
                  
                  const selectedOmnione_lu_affiliationrole = this.dynamicFormsService.allLookUpFieldsForOfflineEdit['omnione_lu_affiliationrole'].find(item => item['id'] == omnione_lu_AffiliationRole['id']);

                  newCustomKey = `${dbKey}.omnione_lu_affiliationrole@OData.Community.Display.V1.FormattedValue`;

                  console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                  console.warn(`${newCustomKey} : ${selectedOmnione_lu_affiliationrole['nameForSearch']}`);
              
                  newLinkEntityData[newCustomKey] = selectedOmnione_lu_affiliationrole['nameForSearch'];

                  newCustomKey = `${dbKey}.omnione_lu_affiliationrole_Formatted`;

                  console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                  console.warn(`${newCustomKey} : ${selectedOmnione_lu_affiliationrole['nameForSearch']}`);
              
                  newLinkEntityData[newCustomKey] = selectedOmnione_lu_affiliationrole['nameForSearch'];
                }
                
              }
              else if(key === 'indskr_isprimaryaccount') {
                let newCustomKey = `${dbKey}.indskr_isprimaryaccount`;

                console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                console.warn(`${newCustomKey} : ${values[key]}`);
            
                newLinkEntityData[newCustomKey] = values[key];

                let value = values[key] === true ? 'Yes':'No' ;

                newCustomKey = `${dbKey}.indskr_isprimaryaccount_Formatted`;

                console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                console.warn(`${newCustomKey} : ${value}`);
            
                newLinkEntityData[newCustomKey] = value;

                newCustomKey = `${dbKey}.indskr_isprimaryaccount@OData.Community.Display.V1.FormattedValue`;

                console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                console.warn(`${newCustomKey} : ${value}`);
            
                newLinkEntityData[newCustomKey] = value;
              } 
              else if(key === 'indskr_affiliationsourcetype') {
                let newCustomKey = `${dbKey}.indskr_affiliationsourcetype`;

                console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                console.warn(`${newCustomKey} : ${values[key]}`);
            
                newLinkEntityData[newCustomKey] = values[key];

                let value = values[key] == '548910000' ? 'Business':'MDM' ;

                newCustomKey = `${dbKey}.indskr_affiliationsourcetype_Formatted`;

                console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                console.warn(`${newCustomKey} : ${value}`);
            
                newLinkEntityData[newCustomKey] = value;

                newCustomKey = `${dbKey}.indskr_affiliationsourcetype@OData.Community.Display.V1.FormattedValue`;

                console.log(`${newCustomKey} : ${newLinkEntityData[newCustomKey]}`);
                console.warn(`${newCustomKey} : ${value}`);
            
                newLinkEntityData[newCustomKey] = value;
              }            
            }//end of for (const key in values)

            rawLEData.raw.push(newLinkEntityData);
          }
        }//end of if(dbKey === 'indskr_accountcontactaffiliation')

        await this.disk.updateOrInsert(linkedEntityDBPrefix + dbKey, (doc)=>rawLEData)
          .catch(error => console.error('addOfflineLinkedEntityToDisk: ', error))
    } 
  }

  public getKOLViewModel(field, kol: KOLStatus): IndFormFieldViewDataModel {
    switch (field) {
      case 'disease_area':
        return {
          label: this.translate.instant('DISEASE_AREA'),
          inputText: kol.diseaseAreaName,
          customCssClass: 'kol-status-item',
          id: 'disease-area-field',
          isReadOnly: true,
          isDisabled: true,
        };
      case 'kol_type':
        return {
          label: this.translate.instant('KOL_TYPE'),
          inputText: kol.kolTypeString,
          customCssClass: 'kol-status-item',
          id: 'kol-type-field',
          isReadOnly: true,
          isDisabled: true,
        };
      case 'status':
        return {
          label: this.translate.instant('STATUS'),
          inputText: kol.statusString,
          customCssClass: 'kol-status-item',
          id: 'status-field',
          isReadOnly: true,
          isDisabled: true,
        };
      case 'date':
        return {
          label: this.translate.instant('DATE'),
          inputText: this.datePipe.transform(kol.createdOn, this.dateTimeFormatsService.date, undefined, this.translate.currentLang),
          customCssClass: 'kol-status-item',
          id: 'date-field',
          isReadOnly: true,
          isDisabled: true,
        };
      case 'owner':
        return {
          label: this.translate.instant('OM_OWNER'),
          inputText: kol.ownerName,
          customCssClass: 'kol-status-item',
          id: 'owner-field',
          isReadOnly: true,
          isDisabled: true,
        };
    }
  }

  // private _sortMedicalInsights(){
  //   if(this.currentMedicalInsights && this.currentMedicalInsights.length > 1){
  //     this.currentMedicalInsights = this.currentMedicalInsights.sort((a,b)=>{
  //       return (isBefore(a.dateCreated, b.dateCreated) ? 1 : -1);
  //     });
  //   }
  // }

  // private _createMedicalInsight(){
  //   if(!this.device.isOffline){
  //     let newMedicalInsight:ContactMedicalInsight = new ContactMedicalInsight({
  //       "customerinsights.indskr_customer" : this.contactService.contactInformation.ID,
  //       "customerinsights.indskr_customer_Formatted" : this.contactService.contactInformation.fullName,
  //       "customerinsights.indskr_datecreated" : new Date(),
  //       "customerinsights.ownerid" : this.authService.user.systemUserID,
  //       "customerinsights.ownerid_Formatted" : this.authService.user.displayName,
  //       "customerinsights.statecode" : 0,
  //       "customerinsights.indskr_priority": false,
  //     });
  //     this.navService.pushChildNavPageWithPageTracking(ContactMedicalInsightComponent,PageName.ContactMedicalInsightComponent,PageName.ContactPageComponent,{from:'ContactDetails',medicalInsight:newMedicalInsight});
  //   }
  // }

  // public getViewModel(field, insight: ContactMedicalInsight): IndFormFieldViewDataModel {
  //   switch (field) {
  //     case 'owner':
  //       return {
  //         label: this.translate.instant('OM_OWNER'),
  //         inputText: insight.ownerNameString,
  //         customCssClass: 'medical-insight-item',
  //         id: 'owner-field',
  //         isReadOnly: true,
  //         isDisabled: true,
  //       };
  //     case 'date':
  //       return {
  //         label: this.translate.instant('DATE'),
  //         inputText: this.datePipe.transform(insight.dateCreated, this.dateTimeFormatsService.date, undefined, this.translate.currentLang),
  //         customCssClass: 'medical-insight-item',
  //         id: 'date-field',
  //         isReadOnly: true,
  //         isDisabled: true,
  //       };
  //     case 'insight-category':
  //       return {
  //         label: this.translate.instant('INSIGHT_CATEGORY'),
  //         inputText: insight.insightCategoryNameString,
  //         customCssClass: 'medical-insight-item',
  //         customPlaceholderLabel: this.translate.instant('NO_INSIGHT_CATEGORY'),
  //         id: 'insight-category-field',
  //         isReadOnly: true,
  //         isDisabled: true,
  //       };
  //     case 'priority':
  //       return {
  //         label: this.translate.instant('FOLLOW_UP_PRIORITY'),
  //         inputText: insight.priority ? this.translate.instant('YES') : this.translate.instant('NO'),
  //         customCssClass: 'medical-insight-item',
  //         id: 'insight-priority-field',
  //         isReadOnly: true,
  //         isDisabled: true,
  //       };
  //     case 'specialty':
  //       return {
  //         label: this.translate.instant('SPECIALITY'),
  //         inputText: insight.specialityNameString,
  //         customCssClass: 'medical-insight-item',
  //         customPlaceholderLabel: this.translate.instant('NO_SPECIALTY'),
  //         id: 'specialty-field',
  //         isReadOnly: true,
  //         isDisabled: true,
  //       };
  //     case 'summary':
  //       return {
  //         label: this.translate.instant('SUMMARY'),
  //         inputText: insight.summary,
  //         customPlaceholderLabel: this.translate.instant('NO_SUMMARY'),
  //         id: 'summary-field',
  //         isReadOnly: true,
  //         isDisabled: true,
  //       };
  //   }
  // }

  // private async _handledNewMedicalInsightCreation(medicalInsight: ContactMedicalInsight) {
  //   // create in dynamics
  //   this.contactDataService.saveContactMedicalInsightsOnline(medicalInsight).then(async (res) => {
  //     if (res && res['indskr_customerinteractioninsightsid']) {
  //       medicalInsight.ID = res['indskr_customerinteractioninsightsid'];
  //       // Save in db
  //       let dbMedicalInsights = await this.disk.retrieve(OFFLINE_DB_LINKED_ENTITY_NAME.CONTACT_MEDICAL_INSIGHTS);
  //       if (dbMedicalInsights && dbMedicalInsights.raw && Array.isArray(dbMedicalInsights.raw) && dbMedicalInsights.raw.length != 0) {
  //         let idx = dbMedicalInsights.raw.findIndex(insight => insight['customerinsights.indskr_customerinteractioninsightsid'] == medicalInsight.ID);
  //         if (idx >= 0) {
  //           dbMedicalInsights.raw[idx] = medicalInsight.getOfflineDataDTO();
  //         } else {
  //           dbMedicalInsights.raw.push(medicalInsight.getOfflineDataDTO());
  //         }
  //       } else {
  //         dbMedicalInsights = {
  //           raw: [medicalInsight.getOfflineDataDTO()],
  //         };
  //       }
  //       await this.disk.updateOrInsert(OFFLINE_DB_LINKED_ENTITY_NAME.CONTACT_MEDICAL_INSIGHTS, doc => {
  //         doc = {
  //           raw: dbMedicalInsights.raw,
  //         };
  //         return doc;
  //       }).catch(error => console.error('Save Default LE to DB error: ', error));

  //       // Add to local
  //       let idx = this.currentMedicalInsights.findIndex(insight => insight.ID == medicalInsight.ID);
  //       if (idx >= 0) {
  //         this.currentMedicalInsights[idx] = medicalInsight;
  //       } else {
  //         this.currentMedicalInsights.push(medicalInsight);
  //       }
  //       this.navService.popChildNavPageWithPageTracking();
  //       this.footerService.initButtons(FooterViews.Contacts);
  //       this.contactService.newMedicalInsight = null;
  //       this._initMedicalInsightsViewData();
  //       this._initMedicalInsightsData();
  //     }
  //   }).catch(err => {
  //     console.log(err);
  //   })


  // }


}
