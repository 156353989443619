<div class="card-wrapper">
  <div class="message-wrapper">
    {{keyMessage}}
  </div>
  <div class="sentiment-wrapper">
    <sentiment-chip *ngFor="let senti of sentiments"
      [sentiment]="senti.sentiment"
      [iconSrc]="senti.iconSrc"
      [label]="senti.label"
      [selected]="senti.selected"
      (onSentimentClick)="onSentimentClick($event)"
    ></sentiment-chip>
  </div>
</div>
