import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Contact, ContactsModel } from '@omni/classes/contact/contact.class';
import { ApprovalStatus } from '@omni/classes/quotes/quotes.class';
import { CustomerPositionList, StatusCode } from '@omni/classes/territory-management-list/customer-position-list.class';
import { IndFilterMenuComponent } from '@omni/components/shared/ind-filter-menu/ind-filter-menu';
import { SelectedSuggestionPillDataModel, SuggestionPillType } from '@omni/models/search-config-data-model';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { TerritoryManagementService } from '@omni/services/territory-management/territory-management.service';
import { UIService } from '@omni/services/ui/ui.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TerritoryManagementTabs } from '../territory-management-list/territory-management-list';
import _, { isEmpty, update } from 'lodash';
import { PositionListTarget } from '@omni/classes/territory-management-list/position-list-target.class';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { SearchConfigService } from '@omni/services/search/search-config.service';
import { ControlDataType } from '@omni/classes/dynamic-form/dynamic-form.class';
import { CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY } from '@omni/config/dynamic-forms/affiliations-contants';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import fuse from 'fuse.js';
import { DiskService } from '@omni/services/disk/disk.service';
import { getSearchSuggestionsData } from '@omni/utility/global-search.utility';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { ContactDetailsComponent } from '@omni/components/contact/contact-details/contact-details';
import { ContactDataService } from '@omni/data-services/contact/contact.data.service';
import { AccountDetailsComponent } from '@omni/components/account/account-details/account-details';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { Account } from '@omni/classes/account/account.class';
import { AccountDataService } from '@omni/data-services/accounts/account.data.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { AssessmentAttributeType, EntityOptions } from '@omni/classes/customer-assessment/assessment-template.class';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';

@Component({
  selector: 'territory-manangement-list-details',
  templateUrl: './territory-manangement-list-details.html',
  styleUrls: ['./territory-manangement-list-details.scss'],
})
export class TerritoryManangementListDetailsComponent implements OnInit, OnDestroy {
  @Input() selectedTab: string;
  @Input() from: any;
  totalAdjusted: any;
  totalAdjustedPercentage: any;
  globalCustomerText: string;

  availableContacts: PositionListTarget[] = [];
  public filteredAvailableContacts: PositionListTarget[] = [];
  // visibleAvailabeContacts: PositionListTarget[] = []
  positionListTargets: PositionListTarget[] = [];
  public filteredPositionListTargets: PositionListTarget[] = [];
  // visiblePositionListTargets: PositionListTarget[] = [];


  ngDestroy$: any = new Subject<boolean>();
  unexpandedList: string[] = [];
  public selectedCustomerPositionList: CustomerPositionList;
  public isReadOnly: boolean = false;
  isModified: boolean = false;

  private selectedMyContactsFilterData: SelectedSuggestionPillDataModel[] = [];
  myContactsFilterCount: number = 0;

  private selectedAvailableContactsFilterData: SelectedSuggestionPillDataModel[] = [];
  availableContactsFilterCount: number = 0;

  filterDisabled: boolean = false;

  myContactsHeader: IndSectionHeaderViewDataModel;
  availableContactsHeader: IndSectionHeaderViewDataModel;
  public myContactSearchText: string = '';
  public availableContactSearchText: string = '';
  constructor(
    public territoryOfflineService: TerritoryManagementService,
    public translate: TranslateService,
    public utilityService: GlobalUtilityService,
    public footerService: FooterService,
    private uiService: UIService,
    private modalCtrl: ModalController,
    private contactService: ContactOfflineService,
    private readonly searchConfigService: SearchConfigService,
    private readonly disk: DiskService,
    private navService: NavigationService,
    private navParams: NavParams,
    private contactDataService: ContactDataService,
    private accountService: AccountOfflineService,
    private accountDataService: AccountDataService,
    private authService: AuthenticationService,
    private customerAssessService: CustomerAssessService
  ) { }

  ngOnInit() {
    this.globalCustomerText = this.territoryOfflineService.setCustomerText();
    this.initializeHeaders();


    this.territoryOfflineService.availableContactsSubject.pipe(takeUntil(this.ngDestroy$)).subscribe(async (contacts) => {
      if (contacts?.length) {
        this.filteredAvailableContacts = this.availableContacts = contacts;
        if (!_.isEmpty(this.selectedAvailableContactsFilterData)) {
          const ids = await this.filteredContactIds(this.selectedAvailableContactsFilterData);
          this.filteredAvailableContacts = this.availableContacts.filter(pos => ids.includes(pos.contact.id));
        }
        // this.setAvailableContacts();
        this.initAvailableHeader();
      }
      await this.uiService.dismissLoader();
    });

    this.territoryOfflineService.selectedCustomerPostionListSubject.pipe(takeUntil(this.ngDestroy$)).subscribe(async (customerPositionList) => {
      if (customerPositionList) {
        this.selectedCustomerPositionList = customerPositionList;
        if (this.selectedTab === TerritoryManagementTabs.MANAGE_LIST) {
          this.isReadOnly = new Date().setHours(0, 0, 0, 0) > new Date(this.selectedCustomerPositionList.listPeriod.indskr_listenddate).setHours(0, 0, 0, 0) || this.selectedCustomerPositionList.statuscode != StatusCode.OPEN;
          if (this.selectedCustomerPositionList.statuscode == StatusCode.OPEN) {
            this.isReadOnly = this.territoryOfflineService.currentListPeriod.indskr_nextlistperiod?.indskr_customerlistperiodid !== this.selectedCustomerPositionList.listPeriod.indskr_customerlistperiodid
              || (!this.authService.hasFeatureAction(FeatureActionsMap.LIST_MANAGEMENT_NO_WINDOW) && new Date().setHours(0, 0, 0, 0) > new Date(this.territoryOfflineService.currentListPeriod.indskr_nextlistmanagementwindowenddate).setHours(0, 0, 0, 0));
            
            // if(this.authService.hasFeatureAction(FeatureActionsMap.LIST_MANAGEMENT_CURRENT_UPDATE)){
            //   this.isReadOnly = this.territoryOfflineService.currentListPeriod.indskr_customerlistperiodid !== this.selectedCustomerPositionList.listPeriod.indskr_customerlistperiodid;
            // }
            if(this.territoryOfflineService.currentListPeriodCustomerList){
              this.isReadOnly = false;
            }
          }
          this.footerService.initButtons(FooterViews.MANAGE_LIST_CUSTOMER_POSITION_LIST);
        } else {
          this.isReadOnly = true;
          this.footerService.initButtons(FooterViews.APPROVAL_CUSTOMER_POSITION_LIST);
        }
        this.positionListTargets = customerPositionList.positionListTargets;
        if (this.isReadOnly) {
          this.positionListTargets.forEach(plt => plt.contact.accounts = plt.contact.accounts.filter(ac => ac['isSelected']));
          this.filteredAvailableContacts = this.availableContacts = this.positionListTargets;
          // this.setAvailableContacts();
        }
        this.filteredPositionListTargets = this.positionListTargets;
        if (!_.isEmpty(this.selectedMyContactsFilterData)) {
          const ids = await this.filteredContactIds(this.selectedMyContactsFilterData);
          this.filteredPositionListTargets = this.positionListTargets.filter(pos => ids.includes(pos.contact.id));
        }

        //The *cdkVirtualFor would only be updated if you update it immutably i.e. you cannot update the array after it is initialized. We use the spread operator
        const tempItems  = [...this.filteredPositionListTargets];
        this.filteredPositionListTargets = tempItems;


        // this.setMyContacts();
        this.filterDisabled = !(this.selectedCustomerPositionList.listPeriod.indskr_customerlistperiodid == this.territoryOfflineService.currentListPeriod?.indskr_customerlistperiodid ||
          this.selectedCustomerPositionList.listPeriod.indskr_customerlistperiodid == this.territoryOfflineService.currentListPeriod?.indskr_nextlistperiod?.indskr_customerlistperiodid) ||
          (this.navParams.data && this.navParams.data.from == PageName.NotificationDetailsComponent);
        this.updateFooterBtnStatus();
        this.initializeHeaders();
      }
    });
  }

  private initializeHeaders() {
    this.initMyContactHeader();
    this.initAvailableHeader();
  }

  private initAvailableHeader() {
    this.availableContactsHeader = {
      id: 'approvals',
      title: this.translate.instant('AVAILABLE') + ' (' + this.filteredAvailableContacts.length + ')',
      controls: [{
        id: 'name-sort',
        text: `${this.translate.instant('NAME')}`,
        isDisabled: false,
        img: "assets/imgs/sort_with_double_arrows.svg",
      }]
    };
  }

  private initMyContactHeader() {
    this.myContactsHeader = {
      id: 'mycontacts',
      title: this.translate.instant('MY_CONTACTS', { globalCustomerText: this.utilityService.globalCustomersText }) + ' (' + this.filteredPositionListTargets.length + ')',
      isRequired: true,
      controls: [{
        id: 'name-sort',
        text: `${this.translate.instant('NAME')}`,
        isDisabled: false,
        img: "assets/imgs/sort_with_double_arrows.svg",
      }]
    };
  }

  // setMyContacts() {
  //   if (this.visiblePositionListTargets.length == this.filteredPositionListTargets.length) return;
  //   this.visiblePositionListTargets = _.slice(this.filteredPositionListTargets, 0, this.filteredPositionListTargets.length + 5);
  // }

  // setAvailableContacts() {
  //   if (this.visibleAvailabeContacts.length == this.filteredAvailableContacts.length) return;
  //   this.visibleAvailabeContacts = _.slice(this.filteredAvailableContacts, 0, this.filteredAvailableContacts.length + 14);
  // }

  updateFooterBtnStatus() {
    if (this.selectedTab === TerritoryManagementTabs.MANAGE_LIST) {
      if (!this.selectedCustomerPositionList.indskr_customerpositionlistid) {
        this.footerService.disableButton(['sendTerritoryForApproval']);
      } else {
        if (this.selectedCustomerPositionList.statuscode != StatusCode.OPEN) {
          this.footerService.disableButton(['scrapterritory', 'saveterritory', 'sendTerritoryForApproval']);
        } else if (this.isReadOnly) {
          this.footerService.disableButton(['saveterritory', 'sendTerritoryForApproval']);
        } else if (!this.isModified) {
          this.footerService.disableButton(['saveterritory']);
        } else {
          this.footerService.enableButtons(['saveterritory']);
          this.footerService.disableButton(['sendTerritoryForApproval']);
        }
      }
    } else {
      if (this.selectedCustomerPositionList.approvalActivityStatusCode !== ApprovalStatus.PENDING
        || new Date().setHours(0, 0, 0, 0) > new Date(this.selectedCustomerPositionList.listPeriod.indskr_listenddate).setHours(0, 0, 0, 0)
        || (!this.authService.hasFeatureAction(FeatureActionsMap.LIST_MANAGEMENT_NO_WINDOW) && new Date().setHours(0, 0, 0, 0) > new Date(this.selectedCustomerPositionList.listPeriod.indskr_previouslistperiod?.indskr_nextlistmanagementwindowenddate).setHours(0, 0, 0, 0))) {
        this.footerService.disableButton(['approveManageList', 'rejectManageList']);
      } else {
        this.footerService.enableButtons(['approveManageList', 'rejectManageList']);
      }
    }
  }

  private initFooterOnRedirect() {
    if (this.selectedTab === TerritoryManagementTabs.MANAGE_LIST) {
      this.footerService.initButtons(FooterViews.MANAGE_LIST_CUSTOMER_POSITION_LIST);
    } else {
      this.footerService.initButtons(FooterViews.APPROVAL_CUSTOMER_POSITION_LIST);
    }
    this.updateFooterBtnStatus();
  }

  //** refactor :- one function base on mode call the function */

  addContact({ value, mode }) {
    this.isModified = true;
    value.isSelected = true;
    value.accounts[0].isSelected = true;
    this.territoryOfflineService.addContactToMyContact(value);
    //Default expand contacts
    const expandedIndex = this.unexpandedList.findIndex((listItem) => listItem === value.id);
    if (expandedIndex >= 0) this.unexpandedList.splice(expandedIndex, 1)
  }

  async highlightContact(event, contact, contactDetailsSegment = 'info') {
    if (event.target.type == "button") {
      return;
    }
    await this.uiService.displayLoader();
    let contactObj: Contact = new Contact({ contactid: contact.id, firstname: contact.name });
    contactObj.ID = contact.id;
    let rawDetails = await this.contactDataService.getContactDetails(contactObj, false);
    if (rawDetails) {
      contactObj = rawDetails;
    }
    this.uiService.contactDetailsSegment = contactDetailsSegment;
    this.contactService.isContactDetailsLoaded = false;
    this.contactService.contactInformation = contactObj;
    this.contactService.isContactDetailsLoaded = true;
    await this.uiService.dismissLoader();
    this.navService.pushChildNavPageWithPageTracking(ContactDetailsComponent, PageName.ContactDetailsComponent, PageName.TerritoryManagementPageComponent, { from: PageName.TerritoryManagementDetailsComponent, callbackEvent: () => this.initFooterOnRedirect() });
    this.navService.setChildNavRightPaneView(true);
  }

  async highlightAccount(account: Account) {
    await this.uiService.displayLoader();
    let accountObj = null;
    accountObj = this.accountService.getAccountById(account.id);
    if (!accountObj) {
      accountObj = await this.accountDataService.getRealTimeAccountDetailsOnline(account.id, true);
    }
    //this.uiService.accountDataSegment = 'info';
    this.accountService.selected = accountObj;
    await this.uiService.dismissLoader();
    this.navService.pushChildNavPageWithPageTracking(AccountDetailsComponent, PageName.AccountDetailsComponent, PageName.TerritoryManagementPageComponent, { from: PageName.TerritoryManagementDetailsComponent, 'listMode': 'AccountInfo', callbackEvent: () => this.initFooterOnRedirect() });
    this.footerService.initButtons(FooterViews.Accounts);
    this.navService.setChildNavRightPaneView(true);
  }

  removeContact({ value, mode }) {
    this.isModified = true;
    const selectedAccounts = value.accounts.filter(acc => acc['isSelected']);
    value.isSelected = false;
    value.accounts = value.accounts.map((account) => ({ ...account, isSelected: false }));
    // this.handleList(value);
    this.territoryOfflineService.removeContactFromMycontact(value, selectedAccounts);
  }

  accountUpdate({ value, mode }) {
    this.isModified = true;
    switch (mode) {
      case 'add':
        value.isSelected = true;
        this.territoryOfflineService.updateAccount(value);
        break;
      case 'remove':
        value.isSelected = false;
        this.territoryOfflineService.updateAccount(value);
        break;
      default:
        break;
    }
  }

  displayCustomerText() {
    return this.translate.instant('MY_CONTACTS', { globalCustomerText: this.translate.currentLang == 'en' ? this.globalCustomerText + "s" : this.globalCustomerText });
  }

  handleList(value) {
    const expandedIndex = this.unexpandedList.findIndex((listItem) => listItem === value.id);
    expandedIndex >= 0 ? this.unexpandedList.splice(expandedIndex, 1) : this.unexpandedList.push(value.id);
  }

  // onIonInfinite(ev, mode) {
  //   if (ev.target.offsetHeight + ev.target.scrollTop >= (ev.target.scrollHeight / 1.3)) {
  //     mode === 'my-contact' ? this.setMyContacts() : this.setAvailableContacts();
  //   }
  // }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  async openContactsFilterPopup(from) {
    let options;
    const filterMenuModal = await this.modalCtrl.create({
      component: IndFilterMenuComponent,
      componentProps: {
        viewData: {
          data: options,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('FILTERS'),
          isSearchEnabled: true,
        },
        selectedFilter: from == 'myContact' ? this.selectedMyContactsFilterData : this.selectedAvailableContactsFilterData,
        from: PageName.TerritoryManagementDetailsComponent,
        subFrom: ContactsModel.MY_CONTACTS_TAB
      },
      backdropDismiss: false
    });
    filterMenuModal.present();
    filterMenuModal.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          if (from === 'myContact') {
            this.selectedMyContactsFilterData = [];
            this.filteredPositionListTargets = this.positionListTargets;
          } else {
            this.selectedAvailableContactsFilterData = [];
            this.filteredAvailableContacts = this.availableContacts;
          }
        } else {
          if (from === 'myContact') {
            this.selectedMyContactsFilterData = [];
            data.selectedItems.forEach((selected) => {
              if (!this.selectedMyContactsFilterData.some(o => o.categoryName == selected.categoryName && o.selectedFacet == selected.selectedFacet))
                this.selectedMyContactsFilterData.unshift(selected);
            });
            const ids = await this.filteredContactIds(this.selectedMyContactsFilterData);
            this.filteredPositionListTargets = this.positionListTargets.filter(pos => ids.includes(pos.contact.id));
          }
          else {
            this.selectedAvailableContactsFilterData = [];
            data.selectedItems.forEach((selected) => {
              if (!this.selectedAvailableContactsFilterData.some(o => o.categoryName == selected.categoryName && o.selectedFacet == selected.selectedFacet))
                this.selectedAvailableContactsFilterData.unshift(selected);
            });
            const ids = await this.filteredContactIds(this.selectedAvailableContactsFilterData);
            this.filteredAvailableContacts = this.availableContacts.filter(pos => ids.includes(pos.contact.id));
          }
        }
        this.availableContactsFilterCount = this.selectedAvailableContactsFilterData.length;
        this.myContactsFilterCount = this.selectedMyContactsFilterData.length;
        if (from == 'myContact') {
          // this.visiblePositionListTargets = [];
          // this.setMyContacts();
          this.initMyContactHeader();
        } else {
          // this.visibleAvailabeContacts = [];
          // this.setAvailableContacts();
          this.initAvailableHeader();
        }
      }
    });
  }

  private async filteredContactIds(selectedFilterData) {
    let filteredContacts: any = [...this.contactService.contacts.filter(item => item.isguest === false && item.isActive && item.indskr_iseventparticipant != true), ...this.territoryOfflineService.contactsForNextPeriod];
    let groupedSelectedSuggestions: {
      category: string,
      suggestions: SelectedSuggestionPillDataModel[]
    }[] = [];
    selectedFilterData.forEach(data => {
      if (data && data.categoryName) {
        let groupIndex = groupedSelectedSuggestions.findIndex(group => group.category == data.categoryName);
        const suggestions = getSearchSuggestionsData(data);
        if (groupIndex > -1) {
          groupedSelectedSuggestions[groupIndex].suggestions.push(...suggestions);
        }
        else {
          groupedSelectedSuggestions.push({
            category: data.categoryName,
            suggestions: [...suggestions]
          })
        }
      }
    })
    for (let i = 0; i < groupedSelectedSuggestions.length; i++) {
      const groupConfig = groupedSelectedSuggestions[i];
      let tempContactsList: Contact[] = filteredContacts;
      let filteredContactsWithinSameCategory: any = [];
      let activeConsents, sciPlans, activeAssessments;

      for (let j = 0; j < groupConfig.suggestions.length; j++) {
        const config = groupConfig.suggestions[j];
        let searchConfig;
        if (config.configuredFrom == 'Assess') {
          searchConfig = this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig.find(a => a.categoryName == config.categoryName);
          config.categoryPath = searchConfig ? searchConfig.categoryRelativePath : config.categoryPath;
        } else if (config.categoryPath) {
          searchConfig = this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig.find(a => a.categoryRelativePath == config.categoryPath);
        } else {
          searchConfig = this.searchConfigService.customerPositionListContactsConfiguredSearchIndexConfig.find(a => a.categoryName == config.categoryName);
          config.categoryPath = searchConfig ? searchConfig.categoryRelativePath : config.categoryPath;
        }

        let key = [config.categoryPath];
        var options2 = {
          keys: key,
          threshold: 0,
          distance: 100,
          includeScore: true
        }
        let searchText: string;
        if (config.type && (config.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH || config.type == SuggestionPillType.BOOLEAN_FIELD)) {
          searchText = config.charSearchText;
          options2.threshold = 0.1;
          options2.distance = 1000;
        }
        else {
          searchText = config.selectedFacet;
        }
        if (searchConfig && searchConfig.controlDataType == 'DefaultConfig') {
          if (config.categoryPath == 'allPhoneNumbers') {
            options2.distance = 1000;
            options2.threshold = 0.05
          }
          let newFuse = new fuse(filteredContacts, options2);
          let results = newFuse.search(searchText);
          let exactMatchResults = [];
          //@ts-ignore
          results.forEach((result: any) => {
            if ((config.categoryPath == 'allPhoneNumbers') || (config.type && config.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH) || result.score < 0.1) {
              exactMatchResults.push(result.item)
            }
          })
          filteredContactsWithinSameCategory = _.uniq(_.concat(filteredContactsWithinSameCategory, exactMatchResults))
        } else if (searchConfig && searchConfig.controlDataType == 'LinkedEntity') {
          //options2.keys = [[config.categoryPath]];
          const dbKey = searchConfig.parentEntity !== CONTACT_CONTACT_AFFILIATIONS_REF_ENTITY ? searchConfig.parentEntity : searchConfig.relationshipName;
          let rawLEData = await this.disk.retrieve(dbKey);

          if (rawLEData && rawLEData.raw) {
            let suffix = '@OData.Community.Display.V1.FormattedValue';
            if (searchConfig.isMultilingualLookup && config.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
              suffix = '';
              let foundMappingValue;
              if (searchConfig.mappingValues && Array.isArray(searchConfig.mappingValues)) {
                foundMappingValue = searchConfig.mappingValues.find(a => a.formattedValue == searchText);
                if (foundMappingValue && foundMappingValue.actualValue) {
                  searchText = foundMappingValue.actualValue;
                }
              }
            }
            const linkedEntityData = rawLEData.raw;
            // if (dbKey === 'indskr_accountcontactaffiliation') {
            //   //Next quater affiliations
            //   const nextQuaterAff = await this.contactService.getAccountContactAffiliationsFromDB()
            //   if (nextQuaterAff) {
            //     nextQuaterAff.forEach(aff => {
            //       aff['contactid'] = aff['indskr_accountcontactaffiliation.indskr_contactid'];
            //       aff['indskr_accountcontactaffiliation.indskr_accountid@OData.Community.Display.V1.FormattedValue'] = aff['indskr_accountcontactaffiliation.indskr_accountid_Formatted'];
            //     })
            //     linkedEntityData.push(...nextQuaterAff);
            //   }
            // }
            let currentData;
            if (config.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
              currentData = linkedEntityData.filter(item => item[key[0] + suffix] == searchText || item[key[0]] == searchText);
            } else if (config.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH) {
              currentData = linkedEntityData.filter(item => {
                let formattedValueA = item[key[0] + suffix] ? this.searchConfigService.convertFormattedString(item[key[0] + suffix]).toLowerCase() : '';
                let formattedValueB = item[key[0]] ? this.searchConfigService.convertFormattedString(item[key[0]]).toLowerCase() : '';
                let formattedSearchText = searchText ? this.searchConfigService.convertFormattedString(searchText).toLowerCase() : '';
                return formattedValueA && formattedValueA.includes(formattedSearchText) || formattedValueB && formattedValueB.includes(formattedSearchText);
              });
            }
            currentData.forEach(linkedEntityObj => {
              let thisContact = tempContactsList.find(a => a.ID == linkedEntityObj.contactid);
              if (!thisContact) return;
              if (thisContact.isguest === false && thisContact.isActive && !filteredContactsWithinSameCategory.some(cont => cont.ID == thisContact.ID)) {
                filteredContactsWithinSameCategory.push(thisContact);
              }
            });
          }
        } else if (searchConfig && searchConfig.controlDataType == 'Assess') {
          if (searchConfig.categoryRelativePath == "indskr_choicelabel") {
            if (config.selectedFacetInEng) {
              searchText = config.selectedFacetInEng
            }
            options2.distance = 1000;
            options2.threshold = 0.2;
          }
          let isTypeChoice: boolean = searchConfig.categoryRelativePath == "indskr_choicelabel";
          let searchType =  isTypeChoice ? AssessmentAttributeType.CHOICE : AssessmentAttributeType.FLAG;
          let categoryName = searchConfig.categoryNameInEng ? searchConfig.categoryNameInEng : searchConfig.categoryName;
          let assessments = await this.customerAssessService.getAssessmentResponsesForOnlineSearch(EntityOptions.CONTACT, searchType, categoryName);
          
          if(assessments) {
            assessments = assessments.filter(assess => {
              return filteredContacts.some(contact => contact.ID == assess['ca.indskr_customer']);
            })
            const sortedActiveAssessments = _.orderBy(assessments, ['modifiedon','createdon'], ['desc','desc']);
            const latestRecords = _.uniqBy(sortedActiveAssessments, 'ca.indskr_customer');

            let results;
            if (searchConfig.categoryRelativePath == "indskr_choicelabel" && config.type && config.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
              results = latestRecords.filter(assess => {
                if (assess['indskr_choicelabel']) {
                  //check multiple choice
                  let isMatched: boolean = false;
                  let tempData = assess['indskr_choicelabel'].split(',') || [];
                  if (!_.isEmpty(tempData) && tempData.length > 1) {
                    tempData.forEach((d, idx) => {
                      if (tempData[idx] == searchText) isMatched = true;
                    });
                    return isMatched;
                  } else {
                    return assess['indskr_choicelabel'] == searchText;
                  }
                }
              });
              //@ts-ignore
              results.forEach((result: any) => {
                let thisContact = this.contactService.getContactByID(result['ca.indskr_customer']);
                if (!filteredContactsWithinSameCategory.some(cont => cont.ID == thisContact.ID)) filteredContactsWithinSameCategory.push(thisContact);
              })
            } else {
              let newFuse = new fuse(latestRecords, options2);
              results = newFuse.search(searchText);
              //@ts-ignore
              results.forEach((result: any) => {
                let thisContact = this.contactService.getContactByID(result.item['ca.indskr_customer']);
                if (!filteredContactsWithinSameCategory.some(cont => cont.ID == thisContact.ID)) filteredContactsWithinSameCategory.push(thisContact);
              })
            }
          }
        } else if (searchConfig && searchConfig.controlDataType != 'LinkedEntity' && searchConfig.controlDataType != 'DefaultConfig') {
          let suffix: string = '';
          let prefix: string = '';
          if (searchConfig.controlDataType) {
            if (searchConfig.controlDataType == ControlDataType.PicklistType || searchConfig.controlDataType == ControlDataType.BooleanType
              || searchConfig.controlDataType == ControlDataType.MultiSelectPicklistType
              || searchConfig.controlDataType == ControlDataType.StatusType || searchConfig.controlDataType == ControlDataType.StateType) {
              suffix = '@OData.Community.Display.V1.FormattedValue'
            } else if (searchConfig.controlDataType == ControlDataType.LookupType) {
              if (searchConfig.isMultilingualLookup && config.type == SuggestionPillType.CATEGORY_VALUESEARCH) {
                suffix = '_value';
                prefix = '_';
                let foundMappingValue;
                if (searchConfig.mappingValues && Array.isArray(searchConfig.mappingValues)) {
                  foundMappingValue = searchConfig.mappingValues.find(a => a.formattedValue == searchText);
                  if (foundMappingValue && foundMappingValue.actualValue) {
                    searchText = foundMappingValue.actualValue;
                  }
                }
              } else {
                suffix = '_value@OData.Community.Display.V1.FormattedValue'
                prefix = '_';
              }
            }
          }
          let searchKey = prefix + searchConfig.categoryRelativePath + suffix;
          let rawContactList = tempContactsList.map(a => a.raw);
          let results;
          if (config.type == SuggestionPillType.CATEGORY_VALUESEARCH || config.type == SuggestionPillType.BOOLEAN_FIELD) {
            results = rawContactList.filter(con => con.hasOwnProperty('contactid') && con.hasOwnProperty(searchKey) && con[searchKey] == searchText);
          } else if (config.type == SuggestionPillType.CATEGORY_CHARACTERSEARCH) {
            results = rawContactList.filter(con => {
              let formattedValue = con[searchKey] ? this.searchConfigService.convertFormattedString(con[searchKey]).toLowerCase() : '';
              let formattedSearchText = searchText ? this.searchConfigService.convertFormattedString(searchText).toLowerCase() : '';
              return con.hasOwnProperty('contactid') && formattedValue && formattedValue.includes(formattedSearchText);
            });
          }

          let exactMatchResults = [];
          //@ts-ignore
          results?.forEach((result: any) => {
            let contactObj = tempContactsList.find(a => a.ID == result.contactid);
            if (contactObj) {
              exactMatchResults.push(contactObj)
            }
          })
          filteredContactsWithinSameCategory = _.uniq(_.concat(filteredContactsWithinSameCategory, exactMatchResults))
        }
      }
      filteredContacts = filteredContactsWithinSameCategory;
    }
    return filteredContacts.map(con => con.ID);

  }

  onMyContactInput() {
    if (this.myContactSearchText && this.myContactSearchText.trim().length > 0) {
      this.selectedMyContactsFilterData = [];
      this.myContactsFilterCount = 0;
      this.filteredPositionListTargets = this.positionListTargets.filter(plt =>
        plt.contact.name?.toLowerCase().includes(this.myContactSearchText.toLowerCase())
        || plt.contact.accounts.filter(ac => ac.name.toLowerCase().includes(this.myContactSearchText.toLowerCase())).length > 0);
    } else {
      this.filteredPositionListTargets = this.positionListTargets;
    }
    // this.setMyContacts();
    this.initMyContactHeader();
  }

  onAvailableContactInput() {
    if (this.availableContactSearchText && this.availableContactSearchText.trim().length > 0) {
      this.selectedAvailableContactsFilterData = [];
      this.availableContactsFilterCount = 0;
      this.filteredAvailableContacts = this.availableContacts.filter(plt =>
        plt.contact.name?.toLowerCase().includes(this.availableContactSearchText.toLowerCase()));
    } else {
      this.filteredAvailableContacts = this.availableContacts;
    }
    // this.setAvailableContacts();
    this.initAvailableHeader();
  }
}
