<ion-list class='details-list' *ngIf="currentSurgeryOrderActivity" id="maindetailslist">
  <ind-section-header [viewData]='detailsHeader'
    (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
  <ind-form-field [viewData]="statusFormField"></ind-form-field>
  <ind-form-field [viewData]="subjectFormField"></ind-form-field>
  <ind-form-field *ngIf="currentSurgeryOrderActivity.cancellationreason"
    [viewData]="cancellationReasonFormField"></ind-form-field>
  <ind-form-field *ngIf="currentSurgeryOrderActivity.indskr_cancellationreasonother"
    [viewData]="cancellationReasonOtherFormField"></ind-form-field>

  <ion-row>
    <ion-col size="6"><ind-datetime-form *ngIf="startDateField"
        [viewData]="startDateField"></ind-datetime-form></ion-col>
    <ion-col size="6"><ind-datetime-form *ngIf="startTimeField"
        [viewData]="startTimeField"></ind-datetime-form></ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="6"><ind-datetime-form *ngIf="endDateField" [viewData]="endDateField"></ind-datetime-form></ion-col>
    <ion-col size="6"><ind-datetime-form *ngIf="endTimeField" [viewData]="endTimeField"></ind-datetime-form></ion-col>
  </ion-row>
  <ind-form-field *ngIf="!noProcedureDay" [viewData]="coachingPlanFormField"></ind-form-field>
  <ind-form-field *ngIf="!noProcedureDay" [viewData]="customerFormField"></ind-form-field>
  <ind-form-field *ngIf="!noProcedureDay" [viewData]="accountFormField"></ind-form-field>
  <ind-form-field *ngIf="!noProcedureDay" [viewData]="marketBusinessPlanFormField"></ind-form-field>
  <ind-form-field [viewData]="ownerFormField"></ind-form-field>
  <ind-form-field [viewData]="coOwnersFormField"></ind-form-field>
  <ind-form-field [viewData]="coVisitorsFormField"></ind-form-field>

  <ind-form-field [viewData]="lastUpdatedByUserFormField"></ind-form-field>
  <ind-form-field [viewData]="lastUpdatedAtFormField"></ind-form-field>
  <ind-form-field [viewData]="activityOutcomeFormField"></ind-form-field>
  <ind-form-field *ngIf="!noProcedureDay" [viewData]="surgeryFormField"></ind-form-field>
  <ind-form-field *ngIf="!noProcedureDay" [viewData]="productCategoyFormField"></ind-form-field>
  <ind-form-field [viewData]="shoreiMasterFormField"></ind-form-field>
  <ind-form-field *ngIf="!noProcedureDay" [viewData]="productFormField"
    (handleAdditionalControls)="scanProduct()"></ind-form-field>
  <ind-form-field *ngIf="!noProcedureDay" [viewData]="competitorProductFormField"></ind-form-field>
  <ind-form-field *ngIf="!noProcedureDay && isHideOpportunity && !associateMultipleOpportunities" [viewData]="OpportunityFormField"></ind-form-field>

  <!-- Procedure Contract -->
  <ind-form-field [viewData]="procedureTypeFormField"></ind-form-field>
  <ind-form-field *ngIf="isProcedureSubtypeAvailable" [viewData]="procedureSubTypeFormField"></ind-form-field>
  <ind-form-field [viewData]="procedureContractField"></ind-form-field>
  <!-- Procedure Contract -->
  <ng-container
    *ngIf="!noProcedureDay && !_hasTagAssetsSurveyJS && isSurveyConfigured && surveyService.surveyActiveFor == 'ProcedureLog'">
    <ind-section-header *ngIf="procedureSurveyHeaderModel" [viewData]="procedureSurveyHeaderModel">
    </ind-section-header>
    <ind-survey *ngIf="!device.isOffline" [surveyTemplate]="surveyTemplate" [surgeryOrder]="currentSurgeryOrderActivity"
                [tempLookupResult]="surveyLookupResults" (completeSurvey)="updateSurveyResult($event)"
                (surveyData)="updateSurveyData($event)"></ind-survey>
    <div class="no-data-message" *ngIf="device.isOffline"> {{ 'AVAILABLE_IN_ONLINE_ONLY' | translate}}</div>
  </ng-container>

  <ion-item-group *ngIf="!noProcedureDay && !hideQunatityField">
    <ind-section-header [viewData]='quantitiesHeader'></ind-section-header>
    <ind-form-field [biggerFontLabel]='true' *ngFor="let product of selectedProductsFormFields"
      [viewData]='product'></ind-form-field>
    <ion-item class='order-field-empty-label'
      *ngIf="(currentSurgeryOrderActivity.products|filterBy:{isDeleted:false}).length == 0">
      <ion-label></ion-label>
    </ion-item>
  </ion-item-group>
  <!-- opportunity grid -->
  <ion-list *ngIf="!noProcedureDay && isHideOpportunity && associateMultipleOpportunities">
      <ind-section-header id="{{meetingOpportunitiesSectionId}}" [viewData]="multipleOpportunitiesSectionTitle"
        (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
      <div [ngClass]="{'opportunities-container':!this.applicableOpportunities.length}" lines="none">
        <ion-item *ngFor=" let opportunity of applicableOpportunities" >
          <ion-label (click)="selectOpportunity(opportunity)" text-wrap>{{ opportunity.opportunityName ? opportunity.opportunityName : '' }}</ion-label>
          <button ion-button clear icon-start (click)="opportunityService.openOpportunityDynamicLink(opportunity)" style="background-color: transparent;" *ngIf="openOpportunityDynamic">
            <img class="popupImage" src="assets/imgs/popup-link-icon.png" alt="Link Icon">
          </button>
        </ion-item>
      </div>
    </ion-list>

  <!-- Survey grid -->


  <ion-item-group *ngIf="!noProcedureDay && hasTagAssets">
    <ind-section-header [viewData]='assetsHeader'
      (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
    <div [ngClass]="{'assets-container':!currentSurgeryOrderActivity.assets.length && !_hasTagAssetsSurveyJS}"
      lines="none">
      <ion-item *ngFor=" let asset of currentSurgeryOrderActivity.assets">
        <ion-label text-wrap>{{ asset.msdyn_name }}</ion-label>
      </ion-item>
    </div>

    <!-- Survey grid -->
    <div *ngIf="_hasTagAssetsSurveyJS && isSurveyConfigured && surveyService.surveyActiveFor == 'ProcedureLog'">
      <ind-survey *ngIf="!device.isOffline" [surveyTemplate]="surveyTemplate"
        [surgeryOrder]="currentSurgeryOrderActivity" [tempLookupResult]="surveyLookupResults"
        (completeSurvey)="updateSurveyResult($event)" (surveyData)="updateSurveyData($event)"></ind-survey>
      <div class="no-data-message" *ngIf="device.isOffline"> {{ 'AVAILABLE_IN_ONLINE_ONLY' | translate}}</div>
    </div>
  </ion-item-group>

  <div *ngIf="!hideFollowUpActivityField">
    <ind-section-header [viewData]='followUpActivityHeader'
      (onControlClick)="onPageTitleControlClick($event)"></ind-section-header>
    <ion-item class="placeholderEmptyDiv" *ngIf="!allModelActivities || allModelActivities.length==0"></ion-item>
    <main-card *ngFor="let activity of allModelActivities" [viewData]="activity"></main-card>

  </div>

  <ng-container *ngIf="this.currentSurgeryOrderActivity.indskr_procedurecontract">
    <ind-section-header [viewData]="contractDetailsHeader"></ind-section-header>
    <div class="wrapper">
      <ion-row>
        <ion-col size="6" size-sm="4"><ind-form-field [viewData]="proceduresAllocated"></ind-form-field></ion-col>
        <ng-container *ngIf="procedureContractType == 548910000">
          <ion-col size="6" size-sm="4"><ind-form-field [viewData]="proceduresUsed"></ind-form-field></ion-col>
          <ion-col size="6" size-sm="4"><ind-form-field [viewData]="proceduresAvailables"></ind-form-field></ion-col>
        </ng-container>
        <ion-col size="6" size-sm="4"><ind-form-field [viewData]="contractStartDateField"></ind-form-field></ion-col>
        <ion-col size="6" size-sm="4"><ind-form-field [viewData]="contactEndDateField"></ind-form-field></ion-col>
      </ion-row>
    </div>
  </ng-container>

  <ng-container *ngIf="noteSectionOnly">
    <ind-section-header [viewData]="notesHeaderModel"
      (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
    <ion-item class="placeholderEmptyDiv"
      *ngIf="(!currentSurgeryOrderActivity.procedureNotes || currentSurgeryOrderActivity.procedureNotes.length==0) && isReadOnlyMode"></ion-item>
    <ind-text-area *ngIf="!isReadOnlyMode" id="notesInput" [placeHolder]="notesPlaceholder" [maxLength]="1000"
      (indChange)="notesChanged($event)" [value]="tempNoteText" [rows]="4" [cols]="10"
      [disabled]="backgroundUploadInProgress || !isOwner && !isCoowner && (isCovisitor && covisitorAccess === 548910000)">
    </ind-text-area>
    <!-- <io-note *ngFor="let note of currentSurgeryOrderActivity.procedureNotes|filterBy:{isDeleted:false}" [inputNote]="note"
      [editable]="isNoteControlsEnabled(note)" [deletable]="isNoteControlsEnabled(note)"
    <io-note *ngFor="let note of currentSurgeryOrderActivity.procedureNotes|filterBy:{isDeleted:false}"
      [inputNote]="note" [editable]="isNoteControlsEnabled(note)" [deletable]="isNoteControlsEnabled(note)"
      (saveNoteOut)="updateNote($event)">
    </io-note> -->
    <io-note *ngFor="let note of nonDeletedNotes"
      [isNoteAttachmentEnabled]="!backgroundUploadInProgress && isMeetingAttachmentEnabled" [inputNote]="note"
      [editable]="!backgroundUploadInProgress && isNoteControlsEnabled(note)"
      [readOnlyNote]="backgroundUploadInProgress || !isNoteControlsEnabled(note)"
      [readOnlyAttachment]="backgroundUploadInProgress || !isMeetingAttachmentEnabled || device.isOffline"
      [deletable]="!backgroundUploadInProgress && isNoteControlsEnabled(note)" (saveNoteOut)="updateNote($event)"
      [disableDownloadInOffline]="true" [from]="uiService.page.ActivitiesDetailsPaneComponent">
    </io-note>


  </ng-container>
  <ion-list *ngIf="noteAndAttachSection">
    <!-- <ion-list *ngIf="true"> -->
    <ind-section-header [viewData]="notesAndAttachHeaderModel"
      (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
    <ind-text-area id="activity-detail-11" [placeHolder]="notesPlaceholder"
      [readonly]="activityService.selectedActivity?.isCompleted" [disabled]="noteDisabled" [maxLength]="1000"
      [showAttachment]="isMeetingAttachmentAdded" [hasDownload]="isAttachmentAdded"
      [attachmentTitle]="meetingNoteAttachmentTitle" (removeAttachment)="removeMeetingNoteAttachment($event)"
      (downloadNotesAttachment)="downloadAttachment($event)" (indChange)="notesChanged($event)" [value]="tempNoteText"
      [rows]="4" [cols]="10">
    </ind-text-area>
    <input class="hide-input" #attachInput type="file" (change)="loadImageFromDevice($event)">
    <io-note *ngFor="let note of nonDeletedNotes"
      [isNoteAttachmentEnabled]="!backgroundUploadInProgress " [inputNote]="note"
      [editable]="!backgroundUploadInProgress && isNoteControlsEnabled(note)"
      [readOnlyNote]="backgroundUploadInProgress || !isNoteControlsEnabled(note)|| noteDisabled"
      [readOnlyAttachment]="backgroundUploadInProgress || !isMeetingAttachmentEnabled || device.isOffline"
      [deletable]="!backgroundUploadInProgress && isNoteControlsEnabled(note)" (saveNoteOut)="updateNote($event)"
      [disableDownloadInOffline]="true" [from]="uiService.page.ActivitiesDetailsPaneComponent"></io-note>
  </ion-list>



</ion-list>
