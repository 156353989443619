import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { DeviceService } from '@omni/services/device/device.service';
import _, { String } from 'lodash';
import {
  Access,
  AssessmentAttributeType,
  AssessmentTemplate,
  AttributeSection,
  ChoiceOption,
  Control,
  EntityOptions,
  RatingScaleType
} from '@omni/classes/customer-assessment/assessment-template.class';
import { AuthenticationService } from '@omni/services/authentication.service';
import { Guid } from 'typescript-guid';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { format } from 'date-fns';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { IndDropdownListComponent } from '../../shared/ind-dropdown-list/ind-dropdown-list';
import { UIService } from '@omni/services/ui/ui.service';
import { AssessmentGrid, ColumnType, CustomerAssessment, EndSlotItemType, InputFieldType } from '@omni/classes/customer-assessment/customer-assessment.class';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { SectionSpecificComponent } from '@omni/components/assessment/section-specific/section-specific';
import { IndSectionSpecificAssessDataModel } from '@omni/models/indSectionSpecificAssessDataModel';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import moment from 'moment';
import {
  CurViewPageType,
  DateTimeFieldType,
  IndDatetimeFormComponent
} from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { DatePipe } from '@angular/common';
import { ActivityService } from '@omni/services/activity/activity.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { BrandOfflineService } from '@omni/services/brand/brand.service';
import { TherapeuticAreaDataService } from '@omni/data-services/therapeutic-area/therapeutic-area.data.service';
import { FeatureActionsMap, UserPosition } from '@omni/classes/authentication/user.class';
import { Brand } from '@omni/classes/brand/brand.class';
import { TherapeuticArea } from '@omni/classes/therapeutic-area/therapeutic-area.class';
import { PopoverComponent, SelectListData } from '@omni/components/popover/popover';
import { LocalizationService } from '@omni/services/localization/localization.service';
import { Contact, Specialty } from '@omni/classes/contact/contact.class';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { EventsService } from '@omni/services/events/events.service';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { IndFilterMenuModalComponent, IndFilterMenuModalDataModel } from '@omni/components/shared/ind-filter-menu-modal/ind-filter-menu-modal';
import { IIndTextInputPopoverViewData, IndTextInputPopupComponent } from '@omni/components/shared/ind-text-input-popup/ind-text-input-popup';

@Component({
  selector: 'customer-assess',
  templateUrl: 'customer-assess.html',
  styleUrls: ['customer-assess.scss']
})
export class CustomerAssessComponent {
  public currentFormView: Array<{
    id: string,
    type: string,
    isSharedSection: boolean,
    view: any,
    data?: string,
    control?: Control,
    sectionid?:string
  }> = [];
  @Input() isLoading: boolean;
  @Input() templates: AssessmentTemplate[];
  @Output() isFormDirty = new EventEmitter<boolean>();
  @Output() savedData = new EventEmitter<CustomerAssessment>();
  @Input() rawData: CustomerAssessment;
  @Input() templatesTeamview: AssessmentTemplate[];
  @Input() specialty: Specialty;
  private selectedTemplate: AssessmentTemplate;
  private _isClicked: { [x: string]: boolean } = {};
  private _currentFormValue: { [x: string]: any } = {};
  @ViewChild('customerAssessDate', { static: true }) dateTimePicker: HTMLIonDatetimeElement;
  private _dateTimePickerActiveControl: Control;
  private _dateTimePickerActiveSectionId: string;
  private _dropdownPopover: HTMLIonPopoverElement;
  private _deletedCurrentFormValue: { [x: string]: any } = {};
  private readonly $ngDestroy: any = new Subject<boolean>();
  public startTime: { [x: string]: any } = {};
  public prevStartTime: { [x: string]: any } = {};
  private readonly masterData: { positions: UserPosition[], ta: TherapeuticArea[], products: Brand[], procedures: Brand[], positionGroups: any[] };
  private _activeLanguageCode = '1033';

  //Added for multiple assessment
  shortMonth: string[] = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  @ViewChild('multiCustomerAssessDate', { static: true }) multiCustomerAssessDate: HTMLIonDatetimeElement;
  public multipleAssessPageTitle: IndPageTitleViewDataModel;
  private isMultipleAssessmentFormDirty: boolean = false;
  public isAssessmentLoading: boolean = false;
  public isMultipleUpdate: boolean = false;
  public selectedCustomers: any;
  public assessRawData: CustomerAssessment[] = [];
  public assessGrid: AssessmentGrid[] = [];
  public multipleFormView: Array<{
    contactId: string,
    rawData: CustomerAssessment,
    currentFormView: Array<{
      id: string,
      type: string,
      isSharedSection: boolean,
      view: any,
      data?: string,
      control?: Control,
    }>
  }> = [];
  private _multipleFormValue: Array<{
    contactId: string,
    sectionId: string,
    id: string,
    currentValue: { [x: string]: any }
  }> = [];
  private selectedProcedures: Array<{
    contactId: string,
    sectionId: string,
    section_general: string,
    specialty: string,
    selected: Array<{
      id: string,
      title: string,
      isSelected: boolean
    }>
  }> = [];
  private selectedProducts: Array<{
    contactId: string,
    sectionId: string,
    section_general: string,
    selected: Array<{
      id: string,
      title: string,
      isSelected: boolean
    }>
  }> = [];
  private selectedPositions: Array<{
    contactId: string,
    sectionId: string,
    section_general: string,
    selected: Array<{
      id: string,
      title: string,
      isSelected: boolean
    }>  }> = [];
  private selectedPositionGroups: Array<{
    contactId: string,
    sectionId: string,
    section_general: string,
    selected: Array<{
      id: string,
      title: string,
      isSelected: boolean
    }>  }> = [];
  private selectedTAs: Array<{
    contactId: string,
    sectionId: string,
    section_general: string,
    selected: Array<{
      id: string,
      title: string,
      isSelected: boolean
    }>
  }> = [];
  private sectionControlToReset: Array<{
    contactId: string,
    sectionId: string,
    section_general: string,
    preValueId: any,
    newValueId: any
  }> = [];
  private isDisabledSectionGeneral: Array<{
    contactId: string,
    sectionId: string,
    section_general: string,
  }> = [];
  private _tempInfoForDateTime: any;
  private _isGridValid: boolean = true;
  currentEventId: string = '';
  scrollToView: boolean = false;
  private isTeamViewEnabled: boolean = false;
  filterMenuModalData: IndFilterMenuModalDataModel[] = [];
  isAssessedContact: boolean = false;
  isContactAssessment: boolean = false;
  emailToolTip: any;
  toolTipdata: any[];
  @Input() readOnly: boolean;

  constructor(
    public customerAssessService: CustomerAssessService,
    public readonly device: DeviceService,
    public translate: TranslateService,
    private authService: AuthenticationService,
    public dateTimeFormatsService: DateTimeFormatsService,
    private readonly popoverCtrl: PopoverController,
    public navService: NavigationService,
    private uiService: UIService,
    private datePipe: DatePipe,
    private activityService: ActivityService,
    private notificationService: NotificationService,
    private readonly therapeuticService: TherapeuticAreaDataService,
    private readonly localizationService: LocalizationService,
    public navParams: NavParams,
    public readonly footerService: FooterService,
    private contactService: ContactOfflineService,
    private accountService: AccountOfflineService,
    private readonly events: EventsService,
    public _cd: ChangeDetectorRef,
    public modalCtrl: ModalController
  ) {
    this.masterData = {
      positions: this.authService.user.positions.filter(p => (p.status == 100000000 || p.status == 0)),
      ta: this.therapeuticService.therapeuticAreas,
      products: this.customerAssessService.products,
      procedures: [],
      positionGroups: this.authService.user.positionGroups
    };
    this._activeLanguageCode = this.localizationService.selectedLanguage.localeID;
  }

  ngOnInit() {
    if (this.templates?.length == 1) {
      this.selectedTemplate = _.cloneDeep(this.templates[0]);
      if(this.selectedTemplate.indskr_entity == 'contact') {
        this.isContactAssessment = true;
        if(this.contactService.contactInformation.ID) {
          const contactId = this.contactService.contactInformation.ID;
          this.isAssessedContact = this.customerAssessService.isAssessedContact(contactId);
        }
      }
      if (this.specialty) {
        this.masterData.procedures = this.customerAssessService.specialtyProceduresMap.has(this.specialty.id) ? this.customerAssessService.specialtyProceduresMap.get(this.specialty.id) : [];
      }
      this._initViewData();
      this.dateTimeFormatsService.updateSelectedTimeConfiguredForm$.pipe(takeUntil(this.$ngDestroy)).subscribe((timeData) => {
        if (timeData) {
          this._handleTimeFormField(timeData);
        }
      })
    }
    if(this.navParams && this.navParams.data && this.navParams.get('isMultipleUpdate')) {
      this.isMultipleUpdate = true;
      this.selectedCustomers =  [];
      this.selectedProcedures = [];
      this.selectedPositions = [];
      this.selectedPositionGroups = [];
      this.selectedProducts = [];
      this.selectedTAs = [];
      this.events.unsubscribe('saveMultipleAssess');
      this.events.subscribe('saveMultipleAssess', () => {
        if(!this.device.isOffline) {
          this.saveMultipleAssess();
        }
        // else {
        //   this.saveMultipleAssessOffline();
        // }
      });
      this._initMultipleAssessData();
    }
  }

  ngOnChanges() {
    if (!this.isLoading && this.templates?.length == 1) {
      this.selectedTemplate = _.cloneDeep(this.templates[0]);
      if (this.specialty) {
        this.masterData.procedures = this.customerAssessService.specialtyProceduresMap.has(this.specialty.id) ? this.customerAssessService.specialtyProceduresMap.get(this.specialty.id) : [];
      }
      this._initViewData();
    }
  }

  ngAfterViewChecked() {
    const currentViewFieldElement = document.getElementById(this.currentEventId);
    if(currentViewFieldElement && this.scrollToView) {
      setTimeout(() => {
        currentViewFieldElement.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
        this.scrollToView = false;
      }, 100);
    }
  }

  private _initViewData(subSectionIdToRefresh: string = null, sectionToRefresh?: AttributeSection, contactID?: string) {
    this.isTeamViewEnabled = this.authService.hasFeatureAction(FeatureActionsMap.ASSESSMENTS_TEAM_VIEW);
    if (this.selectedTemplate && !_.isEmpty(this.selectedTemplate.indskr_metadata)) {
      if (!sectionToRefresh) {
        this.currentFormView = [];
        this.currentFormView.push({
          id: this.translate.instant("ASSESSMENT").concat('_section_header'),
          type: 'section-header',
          isSharedSection: true,
          view: {
            id: this.translate.instant("ASSESSMENT"),
            title: this.translate.instant("ASSESSMENT"),
            controls: [],
          },
        });
        if(this.isTeamViewEnabled) {
          this.currentFormView[0].view.controls.push({
            id: 'assessment-teamView-filter',
            isVisible: true,
            isDisabled: this.device.isOffline,
            icon: 'assets/imgs/filter-blue.svg'
          });
        }
        let fieldView: IndFormFieldViewDataModel = {
          label: this.translate.instant('TEMPLATE'),
          inputText: this.selectedTemplate.indskr_name,
          id: this.selectedTemplate.indskr_name,
          isReadOnly: true,
          isDisabled: true,
          showArrow: false,
          
        }
        this.currentFormView.push({
          id: this.translate.instant("ASSESSMENT").concat('_section_header'),
          type: 'form-field',
          isSharedSection: true,
          view: fieldView,
          sectionid: this.translate.instant("ASSESSMENT").concat('_section_header')
        });
      }
      this.selectedTemplate.indskr_metadata.Sections.forEach(section => {
        if (section && !_.isEmpty(section.Controls) && (!sectionToRefresh || (sectionToRefresh.SectionProperties.GUID == section.SectionProperties.GUID))) {
          let secHeader = this._getSectionHeaderViewForTab(section);
          if (secHeader) {
            const index: number = this.currentFormView.findIndex(view => view.id == section.SectionProperties.GUID);
            if (index > - 1) {
              this.currentFormView[index] = secHeader;
            } else {
              this.currentFormView.push(secHeader);
            }
          }
          if (section.PositionGroup ||section.TherapetuicArea || section.Product || section.Position || section.Procedure) {
            const subSectionValues = this._getValuesForSubSectionView(section, subSectionIdToRefresh);
            if (_.isEmpty(Object.keys(subSectionValues))) {
              //If no controls/sub-sections available in section specific
              this.currentFormView.push({
                id: section.SectionProperties.GUID.concat("_empty-continer"),
                type: 'empty-container',
                isSharedSection: false,
                sectionid: section.SectionProperties.GUID,
                view: null
              })
            } else {
              //once some controls/subsections are available remove empty container
              const index = this.currentFormView.findIndex(view => view.id == section.SectionProperties.GUID.concat("_empty-continer"));
              if (index > -1) this.currentFormView.splice(index, 1);
              //Iterate subSectionValues to generate subSection
              Object.keys(subSectionValues).forEach(subSectionId => {
                let subSection = this._getSubSectionHeaderViewForTab(section, subSectionId);
                if (subSection) {
                  const index: number = this.currentFormView.findIndex(view => view.id == subSectionId);
                  if (index > - 1) {
                    this.currentFormView[index] = subSection;
                  } else {
                    //Add subsection just below Section
                    const index: number = this.currentFormView.findIndex(view => view.id == section.SectionProperties.GUID);
                    this.currentFormView.splice(index + 1, 0, subSection);
                  }
                  //Generate controls views for each subSection
                  for (let i = 0; i < Object.keys(subSectionValues[subSectionId]).length; i++) {
                    this._initCurrentSubSecControls(section, subSectionValues[subSectionId], subSectionId);
                  }
                }
              })
            }
          } else {
            //Shared attributes
            this._getCurrentFormViewForControl(section, section.Controls, contactID);
          }
        }
      })
//hide the sections if 
      _.cloneDeep(this.currentFormView).filter(item=>item.type==='section-header').forEach(formview => {
        const controls = this.currentFormView.filter(item=> item.type != "section-header" && (item.sectionid && item.sectionid ===  formview.id))
        if(_.isEmpty(controls)) {
          let index = this.currentFormView.findIndex(view => formview.id == view.id)
          this.currentFormView.splice(index,1)
        }
      })
    }
  }

  private _getValuesForSubSectionView(section: AttributeSection, sectionId: string, contactID?: string) {
    let responses = {};
    const sectionDetails = sectionId ? this.customerAssessService.parseSectionId(sectionId) : null;
    if (sectionDetails) {
      //Refreshing particular subsection only
      return this._getValueForGivenSubSectionView(sectionDetails, responses, sectionId);
    }
    //Get values for controls which are available in template
    const controlIds = section.Controls.map(control => control.Attribute.GUID);
    if (this.isMultipleUpdate && !_.isEmpty(this._multipleFormValue)) {
      let forms = this._multipleFormValue.filter((value) => value.contactId == contactID && value.sectionId == section.SectionProperties.GUID);
      if(!_.isEmpty(forms)) {
        forms?.forEach((form) => {
          for (let [, value] of Object.entries(form.currentValue)) {
            // for (let [, value1] of Object.entries(value)) {
              let response;
              if (value && controlIds.includes(value['indskr_attribute'])) {
                response = this._getResponseForSectionFromValue(section, value);
                if (response) {
                  const sectionId = this.getSectionId(response);
                  if (!responses.hasOwnProperty(sectionId)) {
                    responses[sectionId] = {};
                  }
                  responses[sectionId][value['indskr_attribute']] = value;
                }
              }
            // }
          }
      })
      }
    } else if (this._currentFormValue && !_.isEmpty(Object.keys(this._currentFormValue))) {
      for (let [, value] of Object.entries(this._currentFormValue)) {
        for (let [, value1] of Object.entries(value)) {
          let response;
          if (value1 && controlIds.includes(value1['indskr_attribute'])) {
            response = this._getResponseForSectionFromValue(section, value1);
            if (response) {
              const sectionId = this.getSectionId(response);
              if (!responses.hasOwnProperty(sectionId)) {
                responses[sectionId] = {};
              }
              responses[sectionId][value1['indskr_attribute']] = value1;
            }
          }
        }
      }
    }
    let sectionResponses = [];
    if (this.rawData) {
      sectionResponses = this._getResponsesForGivenSection(section, controlIds);
      sectionResponses.forEach(response => {
        const sectionId = this.getSectionId(response);
        if (!responses.hasOwnProperty(sectionId)) {
          responses[sectionId] = {};
        }
        if (!responses[sectionId].hasOwnProperty(response['indskr_attribute']))
          responses[sectionId][response['indskr_attribute']] = response;
      });
    }
    return responses;
  }

  private _getResponsesForGivenSection(section: AttributeSection, controlIds: string[]) {
    let sectionResponses = [];
    if (!section.PositionGroup && !section.Position && !section.TherapetuicArea && !section.Product && section.Procedure) {
      //Procedure only
      sectionResponses = this.rawData.responses.filter(resp => !resp.indskr_position && !resp.indskr_positiongroup && !resp.indskr_therapeuticarea && !resp.indskr_product && resp.indskr_procedures && controlIds.includes(resp.indskr_attribute));
    } else if (section.PositionGroup && !section.Position && !section.TherapetuicArea && !section.Product) {
      //PositionGroup only
      sectionResponses = this.rawData.responses.filter(resp => resp.indskr_positiongroup && !resp.indskr_position && !resp.indskr_therapeuticarea && !resp.indskr_product && controlIds.includes(resp.indskr_attribute));
    } else if (section.Position && !section.PositionGroup && !section.TherapetuicArea && !section.Product) {
      //Position only
      sectionResponses = this.rawData.responses.filter(resp => resp.indskr_position && !resp.indskr_positiongroup && !resp.indskr_therapeuticarea && !resp.indskr_product && controlIds.includes(resp.indskr_attribute));
    } else if (!section.Position && !section.PositionGroup && section.TherapetuicArea && !section.Product) {
      //TA only
      sectionResponses = this.rawData.responses.filter(resp => !resp.indskr_position && !resp.indskr_positiongroup && resp.indskr_therapeuticarea && !resp.indskr_product && controlIds.includes(resp.indskr_attribute));
    } else if (!section.TherapetuicArea && section.Product) {
      //Position and Product/PositionGroup and Product, Product only
      if (section.Position) {
        sectionResponses = this.rawData.responses.filter(resp => resp.indskr_position && !resp.indskr_positiongroup && !resp.indskr_therapeuticarea && resp.indskr_product && controlIds.includes(resp.indskr_attribute));
      } else if (section.PositionGroup) {
        sectionResponses = this.rawData.responses.filter(resp => !resp.indskr_position && resp.indskr_positiongroup && !resp.indskr_therapeuticarea && resp.indskr_product && controlIds.includes(resp.indskr_attribute));
      } else {
        //Product Only
        sectionResponses = this.rawData.responses.filter(resp => !resp.indskr_position && !resp.indskr_positiongroup && !resp.indskr_therapeuticarea && resp.indskr_product && controlIds.includes(resp.indskr_attribute));
      }
    }
     else if (section.Position && section.TherapetuicArea && !section.Product && !section.PositionGroup) {
      //Position and TA
      sectionResponses = this.rawData.responses.filter(resp => !resp.indskr_positiongroup && resp.indskr_position && resp.indskr_therapeuticarea && !resp.indskr_product && controlIds.includes(resp.indskr_attribute));
    } else if (!section.Position && section.TherapetuicArea && !section.Product && section.PositionGroup) {
      //PositionGroup and TA
      sectionResponses = this.rawData.responses.filter(resp => resp.indskr_positiongroup && !resp.indskr_position && resp.indskr_therapeuticarea && !resp.indskr_product && controlIds.includes(resp.indskr_attribute));
    } else if (!section.PositionGroup && !section.Position && section.TherapetuicArea && section.Product) {
      //Product and TA
      sectionResponses = this.rawData.responses.filter(resp => !resp.indskr_positiongroup && !resp.indskr_position && resp.indskr_therapeuticarea && resp.indskr_product && controlIds.includes(resp.indskr_attribute));
    } else if (!section.PositionGroup && section.TherapetuicArea && section.Product) {
      //Position and TA and Product, Product and TA
      sectionResponses = this.rawData.responses.filter(resp => !resp.indskr_positiongroup && resp.indskr_position && resp.indskr_therapeuticarea && resp.indskr_product && controlIds.includes(resp.indskr_attribute));
    } else if (section.PositionGroup && section.TherapetuicArea && section.Product) {
      //PositionGroup and TA and Product, Product and TA
      sectionResponses = this.rawData.responses.filter(resp => resp.indskr_positiongroup && !resp.indskr_position && resp.indskr_therapeuticarea && resp.indskr_product && controlIds.includes(resp.indskr_attribute));
    }
    return sectionResponses;
  }

  private _getResponseForSectionFromValue(section: AttributeSection, value: any) {
    let response;
    if (!section.Position && !section.PositionGroup && !section.TherapetuicArea && !section.Product && section.Procedure) {
      //Procedure only
      if (!value['indskr_position'] && !value['indskr_positiongroup'] && !value['indskr_therapeuticarea'] && !value['indskr_product'] && value['indskr_procedures']) {
        response = value;
      }
    } else if (section.PositionGroup && !section.Position && !section.TherapetuicArea && !section.Product) {
      //PositionGroup only
      if (value['indskr_positiongroup'] && !value['indskr_position'] && !value['indskr_therapeuticarea'] && !value['indskr_product']) {
        response = value;
      }
    } else if (!section.PositionGroup && section.Position && !section.TherapetuicArea && !section.Product) {
      //Position only
      if (!value['indskr_positiongroup'] && value['indskr_position'] && !value['indskr_therapeuticarea'] && !value['indskr_product']) {
        response = value;
      }
    } else if (!section.PositionGroup && !section.Position && section.TherapetuicArea && !section.Product) {
      //TA only
      if (!value['indskr_positiongroup'] && !value['indskr_position'] && value['indskr_therapeuticarea'] && !value['indskr_product']) {
        response = value;
      }
    } else if (!section.TherapetuicArea && section.Product) {
      //Product and Position, Positon only
      // if (!value['indskr_position'] && !value['indskr_therapeuticarea'] && value['indskr_product']) {
      if (!value['indskr_therapeuticarea'] && value['indskr_product']) {
        response = value;
      }
    } else if (!section.PositionGroup && section.Position && section.TherapetuicArea && !section.Product) {
      //Position and TA
      if (!value['indskr_positiongroup'] && value['indskr_position'] && value['indskr_therapeuticarea'] && !value['indskr_product']) {
        response = value;
      }
    }  else if (section.PositionGroup && !section.Position && section.TherapetuicArea && !section.Product) {
      //PositionGroup and TA
      if (value['indskr_positiongroup'] && !value['indskr_position'] && value['indskr_therapeuticarea'] && !value['indskr_product']) {
        response = value;
      }
    } else if (!section.PositionGroup && !section.Position && section.TherapetuicArea && section.Product) {
      //Product and TA
      if (!value['indskr_positiongroup'] && !value['indskr_position'] && value['indskr_therapeuticarea'] && value['indskr_product']) {
        response = value;
      }
    } else if (!section.PositionGroup && section.TherapetuicArea && section.Product) {
      //Position and TA  and Position, Product and TA
      if (!value['indskr_positiongroup'] && value['indskr_position'] && value['indskr_therapeuticarea'] && value['indskr_product']) {
        response = value;
      }
    } else if (section.PositionGroup && section.TherapetuicArea && section.Product) {
      //PositionGroup and TA , Product and TA
      if (value['indskr_positiongroup'] && !value['indskr_position'] && value['indskr_therapeuticarea'] && value['indskr_product']) {
        response = value;
      }
    }
    return response;
  }

  private _getValueForGivenSubSectionView(sectionDetails: { positionId: string; taId: string; productId: string; procedureId: string; positionGroupId: string; }, responses: {}, sectionId: string) {
    if (this._currentFormValue && !_.isEmpty(Object.keys(this._currentFormValue))) {
      for (let [, value] of Object.entries(this._currentFormValue)) {
        for (let [, value1] of Object.entries(value)) {
          if (value1 && value1['indskr_procedures'] == sectionDetails.procedureId && value1['indskr_position'] == sectionDetails.positionId && value1['indskr_positiongroup'] == sectionDetails.positionGroupId && value1['indskr_therapeuticarea'] == sectionDetails.taId && value1['indskr_product'] == sectionDetails.productId) {
            if (!responses.hasOwnProperty(sectionId)) {
              responses[sectionId] = {};
            }
            responses[sectionId][value1['indskr_attribute']] = value1;

          }
        }
      }
    }
    if (this.rawData) {
      this.rawData.responses.filter(response => response['indskr_procedures'] == sectionDetails.procedureId && response['indskr_position'] == sectionDetails.positionId && response['indskr_positiongroup'] == sectionDetails.positionGroupId && response['indskr_therapeuticarea'] == sectionDetails.taId && response['indskr_product'] == sectionDetails.productId).forEach(response => {
        if (!responses.hasOwnProperty(sectionId)) {
          responses[sectionId] = {};
        }
        if (!responses[sectionId].hasOwnProperty(response['indskr_attribute']))
          responses[sectionId][response['indskr_attribute']] = response;
      });
    }
    return responses;
  }

  private _initCurrentSubSecControls(section: AttributeSection, values, subSectionId: string) {
    const controlsWithOrders = _.orderBy(section.Controls.filter(control => control.Order != 0), ['Order', 'Attribute.DisplayName'], ["desc", "desc"]);
    const controlsWithoutOrders = _.orderBy(section.Controls.filter(control => control.Order == 0), ['Attribute.DisplayName'], "desc");
    const controls: Control[] = [...controlsWithoutOrders, ...controlsWithOrders]
    
    controls.forEach(control => {
      if (values.hasOwnProperty(control.Attribute.GUID)) {
        //Add view only if value is present
        let view = this._getViewForControl(control, section, subSectionId);
        if (view) {
          let index: number = this.currentFormView.findIndex(formView => formView.id == view.id);
          if (index > -1) {
            this.currentFormView.splice(index, 1);
            // this.currentFormView[index] = view;
          }
          //Add controls below its subsection
          index = this.currentFormView.findIndex(view => view.id == subSectionId);
          this.currentFormView.splice(index + 1, 0, view)
          // }
        }
      }
    })
  }

  private getFormValueForSubSectionControl(control: Control, section: AttributeSection, subSectionId: string, contactID?: string, sectionGeneralValue?: string, isGridLabel?) {
    let response;
    if(this.isMultipleUpdate) {
      let subSectionDetails;
      if(isGridLabel) subSectionDetails = subSectionId ? this.customerAssessService.parseSectionId(sectionGeneralValue) : null;
      else subSectionDetails = subSectionId ? this.customerAssessService.parseSectionId(subSectionId) : null;
      let _currentValue = this._multipleFormValue.find((value) => value.contactId == contactID && value.sectionId == section.SectionProperties.GUID && value.id == sectionGeneralValue);
      if(_currentValue && _currentValue.currentValue && _currentValue.currentValue[control.Attribute.GUID]) {
        return _currentValue.currentValue[control.Attribute.GUID];
      } else if (this.rawData) {
        if (!(this._deletedCurrentFormValue && this._deletedCurrentFormValue[control.Attribute.GUID])) {
          this.rawData.responses = !_.isEmpty(this.rawData.responses) ? _.orderBy(this.rawData.responses, ['modifiedon'], ['desc']) : [];
          return this.rawData.responses.find(resp => resp.indskr_procedures == subSectionDetails.procedureId && resp.indskr_position == subSectionDetails.positionId && resp.indskr_positiongroup == subSectionDetails.positionGroupId && resp.indskr_therapeuticarea == subSectionDetails.taId && resp.indskr_product == subSectionDetails.productId && resp.indskr_attribute === control.Attribute.GUID);
        }
      }
    } else {
      const sectionDetails = subSectionId ? this.customerAssessService.parseSectionId(subSectionId) : null;
      if (sectionDetails) {
        //Refreshing particular subsection only
        if (this._currentFormValue && !_.isEmpty(Object.keys(this._currentFormValue))) {
          for (let [, value] of Object.entries(this._currentFormValue)) {
            for (let [, value1] of Object.entries(value)) {
              if (value1 && value1['indskr_procedures'] == sectionDetails.procedureId && value1['indskr_position'] == sectionDetails.positionId && value1['indskr_positiongroup'] == sectionDetails.positionGroupId && value1['indskr_therapeuticarea'] == sectionDetails.taId && value1['indskr_product'] == sectionDetails.productId && value1['indskr_attribute'] === control.Attribute.GUID) {
                response = value1
                break;
              }
            }
            if (response) break;
          }
        }
        if (this.rawData && !response) {
          this.rawData.responses = !_.isEmpty(this.rawData.responses) ? _.orderBy(this.rawData.responses, ['modifiedon'], ['desc']) : [];
          response = this.rawData.responses.find(response => response.indskr_procedures == sectionDetails.procedureId && response.indskr_position == sectionDetails.positionId && response.indskr_positiongroup == sectionDetails.positionGroupId && response.indskr_therapeuticarea == sectionDetails.taId && response.indskr_product == sectionDetails.productId && response.indskr_attribute == control.Attribute.GUID);
        }
      }
    }
    return response;
  }

  private _getCurrentFormViewForControl(section: AttributeSection, controls: Control[], contactID?: string) {
    const userSecurityRoles = this.authService.user?.securityRoles || [];
    let roleIDs = userSecurityRoles.map(item => item['roleid'])

    controls.forEach(control => {

      const accessSecurityRoleAttributes = control.hasOwnProperty('Attribute') && control['Attribute'].hasOwnProperty('AccessRole') ? control['Attribute']['AccessRole'] : [];
      let attributeParentlevelAccess 
      if (control.hasOwnProperty('Access'))
        attributeParentlevelAccess = control['Access']

      if (!_.isEmpty(accessSecurityRoleAttributes)) {

        accessSecurityRoleAttributes.forEach(attributeRole => {
          if (attributeRole.hasOwnProperty('SecurityRole') && 
              _.includes(roleIDs, attributeRole['SecurityRole'])) {
            if (attributeRole.hasOwnProperty('Access')) {
              if (attributeRole['Access'] != Access.HIDDEN) {
                this._getControlview(control, section, null, contactID)
              }
            }
          } else {
            if  (attributeParentlevelAccess && attributeParentlevelAccess != Access.HIDDEN) {
              this._getControlview(control, section, null, contactID)
            }
          }
        });
      } else {
        if (attributeParentlevelAccess && attributeParentlevelAccess != Access.HIDDEN)
        this._getControlview(control, section, null, contactID)
      }
    });
  }

  private _getControlview(control,section,subsectionId,contactID){
    let view = this._getViewForControl(control, section, null, contactID);
    if (view) {
      const index: number = this.currentFormView.findIndex(view => view.id == control.Attribute.GUID);
      if (index > -1) {
        this.currentFormView[index] = view;
      } else {
        this.currentFormView.push(view);
      }
    }
  }

  private getSectionId(formValue) {
    return [formValue['indskr_position'], formValue['indskr_therapeuticarea'], formValue['indskr_product'], formValue['indskr_procedures'], formValue['indskr_positiongroup']].join("_")
  }

  private _getPlaceholdertext(control: Control) {
    let placeholderText = control.Description;

    if (control.hasOwnProperty('Descriptions')) {

      const descriptions = control['Descriptions'];

      if (!_.isEmpty(descriptions)) {
        const placeholder = descriptions.find(value => value.LanguageCode == this.localizationService.selectedLanguage.localeID);
        if (placeholder) {
          placeholderText = placeholder.Description;
        }
      }
    }
    return placeholderText;
  }

  private _getLabeltext(control: Control, isSetToEnglish:boolean = false) {

    let labelText = control.DisplayName;

    if (control.Attribute.hasOwnProperty('DisplayNames')) {

      const displayNames = control.Attribute['DisplayNames'];

      if (!_.isEmpty(displayNames)) {
        let displayName;
        if(!isSetToEnglish) {
          displayName = displayNames.find(value => value.LanguageCode == this.localizationService.selectedLanguage.localeID);
        }else {
          displayName = displayNames.find(value => value.LanguageCode == '1033');
        }
        if (displayName) {
          labelText = displayName.Description;
        } else {
          let foundTranslatedStr = this.localizationService.getTranslatedString(labelText, isSetToEnglish);
          if(!_.isEmpty(foundTranslatedStr)) labelText = foundTranslatedStr;
        }
      } else {
        let foundTranslatedStr = this.localizationService.getTranslatedString(labelText, isSetToEnglish);
        if(!_.isEmpty(foundTranslatedStr)) labelText = foundTranslatedStr;
      }
    }
    return labelText;
  }

  private getInputtext(control: Control) {
    let inputText = control.Description;

    if (control.Attribute.hasOwnProperty('Descriptions')) {

      const descriptions = control.Attribute['Descriptions'];

      if (!_.isEmpty(descriptions)) {
        const dt = descriptions.find(value => value.LanguageCode == this.localizationService.selectedLanguage.localeID);
      }
    }
    return inputText;
  }

  private _getViewForControl(control: Control, section: AttributeSection, subSectionId: string, contactID?: string) {
    if (control.Type) {
      try {
        //Account Contact Criteria match
        if (this.selectedTemplate.indskr_entity == 'contact') {
          if (this.contactService.contactInformation && (control.Attribute.ContactType || control.Attribute.Designation || control.Attribute.Specialty)) {
            if (control.Attribute.ContactType && !control.Attribute.Designation && !control.Attribute.Specialty) {
              if (!this.contactService.contactInformation.indskr_contacttype || control.Attribute.ContactType['SelectedValue'] != this.contactService.contactInformation.indskr_contacttype) {
                return;
              }
            } else if (control.Attribute.ContactType && control.Attribute.Designation && !control.Attribute.Specialty) {
              if ((!this.contactService.contactInformation.indskr_contacttype || control.Attribute.ContactType['SelectedValue'] != this.contactService.contactInformation.indskr_contacttype) || (!this.contactService.contactInformation.professionalDesignation || control.Attribute.Designation['Id'] != this.contactService.contactInformation.professionalDesignation.id)) {
                return;
              }
            } else if (control.Attribute.ContactType && control.Attribute.Designation && control.Attribute.Specialty) {
              if ((!this.contactService.contactInformation.indskr_contacttype || control.Attribute.ContactType['SelectedValue'] != this.contactService.contactInformation.indskr_contacttype) || (!this.contactService.contactInformation.professionalDesignation || control.Attribute.Designation['Id'] != this.contactService.contactInformation.professionalDesignation.id) || !this.contactService.contactInformation.specialties.some(e => control.Attribute.Specialty['Id'] == e.id)) {
                return;
              }
            } else if (!control.Attribute.ContactType && control.Attribute.Designation && !control.Attribute.Specialty) {
              if (!this.contactService.contactInformation.professionalDesignation || control.Attribute.Designation['Id'] != this.contactService.contactInformation.professionalDesignation.id) {
                return;
              }
            } else if (!control.Attribute.ContactType && control.Attribute.Designation && control.Attribute.Specialty) {
              if ((!this.contactService.contactInformation.professionalDesignation || control.Attribute.Designation['Id'] != this.contactService.contactInformation.professionalDesignation.id) || !this.contactService.contactInformation.specialties.some(e => control.Attribute.Specialty['Id'] == e.id)) {
                return;
              }
            } else if (!control.Attribute.ContactType && !control.Attribute.Designation && control.Attribute.Specialty) {
              if (!this.contactService.contactInformation.specialties.some(e => control.Attribute.Specialty['Id'] == e.id)) {
                return;
              }
            } else if (control.Attribute.ContactType && !control.Attribute.Designation && control.Attribute.Specialty) {
              if ((!this.contactService.contactInformation.indskr_contacttype || control.Attribute.ContactType['SelectedValue'] != this.contactService.contactInformation.indskr_contacttype) || !this.contactService.contactInformation.specialties.some(e => control.Attribute.Specialty['Id'] == e.id)) {
                return;
              }
            }
          }
        } else {
          const account = this.accountService.selected;
          if (control.Attribute.AccountType) {
            if (!account.extendedDetails.accountTypeOption || control.Attribute.AccountType['SelectedValue'] != account.extendedDetails.accountTypeOption) {
              return;
            }
          }
        }
        let labelText: string = this._getLabeltext(control);
        let placeholderText: string = this._getPlaceholdertext(control);
        const fieldType = this.customerAssessService.getFormFieldType(control);
        const inputText = this._getInputTextForFormField(control, section, subSectionId, false, contactID);
        const isNotEditable: boolean = this._isNotValidToEdit(control);
        const controlId = (section.PositionGroup || section.Position || section.Product || section.TherapetuicArea || section.Procedure) ? subSectionId.concat("_").concat(control.Attribute.GUID) : control.Attribute.GUID
        if (control.Type != AssessmentAttributeType.TIMESTAMP) {
          let fieldView: IndFormFieldViewDataModel = {
            label: labelText,
            inputText: inputText,
            inputValue: inputText,
            id: controlId,
            isReadOnly: fieldType != FormFieldType.INLINE_INPUT,
            isDisabled: this._isControlDisabled(control, section) || isNotEditable,
            showArrow: this._isShowArrow(control, section),
            ShowToolTipIcon :true,
            inputType: (control.Type == AssessmentAttributeType.NUMERIC || control.Type == AssessmentAttributeType.RANGE) ? 'number' : 'text',
            formFieldType: fieldType,
            isRequired: control.Mandatory,
            isInvalid: false,
            isEmptyRequiredField: control.Mandatory ? this._isClicked && this._isClicked[controlId] && _.isEmpty(inputText) : false,
            errorMessage: control.Mandatory ? this.translate.instant('THIS_IS_A_REQUIRED_FIELD') : undefined,
            inputValidator: (id: string, value: any, event) => control.Mandatory ? this.customerAssessService._validateMandatoryField(id, value, event, this.currentFormView) : true,
            eventHandler: (id: string, event, eventName) => this._handleFormFieldEvent(id, section.SectionProperties.GUID, event, eventName),
            infoText: this._validateAttributeAccess(control) === Access.READ_ONLY ? this.translate.instant('READ_ONLY_FIELD_MSG') : isNotEditable ? this.translate.instant('NON_EDITABLE_FIELD_MSG') : null,
            displayInfoText: this._isClicked[controlId],
            infoTextEventHandler: (id: string, value: any, event) => this.infoTextEventHandler(id, section.SectionProperties.GUID, value, event)
          };

          if (placeholderText && placeholderText.trim() != '') {
            fieldView.customPlaceholderLabel = placeholderText;
          } else {
            fieldView.placeholderLabel = labelText;
          }
          if (control.Type == AssessmentAttributeType.RANGE) {
            fieldView.inputMin = control.RangeMin;
            fieldView.inputMax = control.RangeMax;
            fieldView.customPlaceholderLabel = fieldView.placeholderLabel = this.translate.instant('ENTER_RANGE_BETWEEN', {min: control.RangeMin, max: control.RangeMax} );
          }
          return {
            id: fieldView.id,
            type: 'form-field',
            isSharedSection: !section.TherapetuicArea && !section.Product && !section.PositionGroup && !section.Position && !section.Procedure,
            view: fieldView,
            control: control,
            sectionid: section.SectionProperties.GUID
          };
        } else if (control.Type == AssessmentAttributeType.TIMESTAMP) {
          return this._getViewForTimeStampFormField(control, section, inputText, labelText, isNotEditable, controlId);
        }
      } catch (error) {
        console.log('Error occured while parsing controls view' + error);
      }
    }
  }

  private _getViewForTimeStampFormField(control: Control, section: AttributeSection, inputText: string, labelText: string, isNotEditable: boolean, controlId: string) {
    let dateFieldView: IndDateTimeFormViewDataModel;
    let timeFieldView: IndDateTimeFormViewDataModel;
    this._dateTimePickerActiveControl = control;
    this._dateTimePickerActiveSectionId = section.SectionProperties.GUID;
    let dateFieldValue: string = '';
    let timeFieldValue: string = '';
    if (!_.isEmpty(inputText)) {
      const formattedDateTimeValue = moment(inputText).format();
      if (!_.isEmpty(formattedDateTimeValue) && formattedDateTimeValue != 'Invalid Date') {
        let currentDateTimeValue = new Date(formattedDateTimeValue);
        dateFieldValue = this.datePipe.transform(currentDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
        timeFieldValue = currentDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
        if (!this.dateTimeFormatsService.is12HourFormat && timeFieldValue.includes("24:")) {
          timeFieldValue = timeFieldValue.replace("24:", "00:");
        }
      }
    }
    const dateTextTrans = this.translate.instant('DATE');
    let labelDateText: string = labelText;
    if (labelText.includes('Date') || labelText.includes('date') || labelText.includes(dateTextTrans) || labelText.includes(dateTextTrans.toLowerCase())) {
      labelDateText = labelText;
    } else {
      labelDateText = labelDateText + ' ' + dateTextTrans;
    }
    dateFieldView = {
      isDateField: true,
      label: labelDateText,
      customPlaceholderLabel: this.translate.instant('SELECT_DATE'),
      inputText: dateFieldValue,
      inputValue: dateFieldValue,
      startDateTimeValue: inputText,
      endDateTimeValue: inputText,
      fromViewPage: CurViewPageType.ConfiguredForm,
      id: DateTimeFieldType.StartDateField,
      controlId: controlId,
      isReadOnly: true,
      isDisabled: this._isControlDisabled(control, section) || isNotEditable,
      showArrow: this._isShowArrow(control, section),
      ShowToolTipIcon:true,
      isRequired: control.Mandatory,
      isInvalid: false,
      isEmptyRequiredField: control.Mandatory ? this._isClicked && this._isClicked[controlId] && _.isEmpty(dateFieldValue) : false,
      errorMessage: control.Mandatory ? this.translate.instant('THIS_IS_A_REQUIRED_FIELD') : undefined,
      inputValidator: (id: string, value: any, event) => control.Mandatory ? this.customerAssessService._validateMandatoryField(control.Attribute.GUID, value, event, false) : true,
      eventHandler: (id: string, event, eventName, dataRef) => this._handleFormFieldEvent(control.Attribute.GUID, section.SectionProperties.GUID, event, eventName),
      infoText: this._validateAttributeAccess(control) === Access.READ_ONLY ? this.translate.instant('READ_ONLY_FIELD_MSG') : isNotEditable ? this.translate.instant('NON_EDITABLE_FIELD_MSG') : null,
      displayInfoText: this._isClicked[controlId],
      infoTextEventHandler: (id: string, value: any, event) => this.infoTextEventHandler(id, section.SectionProperties.GUID, value, event)
    };
    const timeTextTrans = this.translate.instant('TIME');
    let labelTimeText: string = labelText;
    if (labelText.includes('Date')) {
      labelTimeText = labelText.replace('Date', 'Time');
    } else if (labelText.includes('date')) {
      labelTimeText = labelText.replace('date', 'time');
    } else if (labelText.includes(dateTextTrans)) {
      labelTimeText = labelText.replace(dateTextTrans, timeTextTrans);
    } else if (labelText.includes(dateTextTrans.toLowerCase())) {
      labelTimeText = labelText.replace(dateTextTrans.toLowerCase(), timeTextTrans.toLowerCase());
    } else {
      labelTimeText = labelTimeText + ' ' + timeTextTrans;
    }
    timeFieldView = {
      isTimeField: true,
      label: labelTimeText,
      customPlaceholderLabel: this.translate.instant('SELECT_TIME'),
      inputText: timeFieldValue,
      inputValue: timeFieldValue,
      startDateTimeValue: inputText,
      endDateTimeValue: inputText,
      fromViewPage: CurViewPageType.ConfiguredForm,
      id: DateTimeFieldType.StartTimeField,
      controlId: controlId,
      isReadOnly: true,
      isDisabled: _.isEmpty(dateFieldValue) ? true : this._isControlDisabled(control, section) || isNotEditable,
      showArrow: dateFieldView.inputText != '' && this._isShowArrow(control, section),
      ShowToolTipIcon:true,
      isRequired: control.Mandatory,
      isInvalid: false,
      isEmptyRequiredField: control.Mandatory ? this._isClicked && this._isClicked[controlId] && _.isEmpty(timeFieldValue) : false,
      errorMessage: control.Mandatory ? this.translate.instant('THIS_IS_A_REQUIRED_FIELD') : undefined,
      inputValidator: (id: string, value: any, event) => control.Mandatory ? this.customerAssessService._validateMandatoryField(control.Attribute.GUID, value, event, false) : true,
      infoText: this._validateAttributeAccess(control) === Access.READ_ONLY ? this.translate.instant('READ_ONLY_FIELD_MSG') : isNotEditable ? this.translate.instant('NON_EDITABLE_FIELD_MSG') : null,
      displayInfoText: this._isClicked[controlId],
      infoTextEventHandler: (id: string, value: any, event) => this.infoTextEventHandler(id, section.SectionProperties.GUID, value, event),
      eventHandler: (id: string, event, eventName, dataRef) => this._handleFormFieldEvent(control.Attribute.GUID, section.SectionProperties.GUID, event, eventName),
    };
    return {
      id: dateFieldView.controlId,
      type: 'form-field',
      isSharedSection: !section.TherapetuicArea && !section.Product && !section.PositionGroup && !section.Position && !section.Procedure,
      view: dateFieldView,
      timeView: timeFieldView,
      control: control,
    };
  }

  private infoTextEventHandler(controlId, sectionId, value, event) {
    this._isClicked[controlId] = true;
    const targetControl: Control = this.getTargetControl(controlId, sectionId);
    if (targetControl && targetControl.Type == AssessmentAttributeType.CHOICE && targetControl.Choice.Type == RatingScaleType.MULTI_SELECT)
      this._handleChoiceFormField(controlId, sectionId, event, this.getTargetControl(controlId, sectionId), true);
  }

  private _handleFormFieldEvent(controlId, sectionId, event, eventName) {
    const targetControl: Control = this.getTargetControl(controlId, sectionId);
    if (targetControl) {
      switch (targetControl.Type) {
        case AssessmentAttributeType.TEXT:
        case AssessmentAttributeType.MEMO:
        case AssessmentAttributeType.NUMERIC:
        case AssessmentAttributeType.RANGE:
          if (eventName == 'input_value_confirm') {
            this._handleInlineInputFormField(controlId, sectionId, event, eventName, targetControl);
            this._isClicked[targetControl.Attribute.GUID] = true;
          } else if (eventName == 'toolTipiconClick') {
            this.openToolTip(this.rawData, targetControl)
          }       
          break;
        case AssessmentAttributeType.TIMESTAMP:
          if (eventName == 'main_click_event') {
            this._handleDateTimeFormField(controlId, sectionId, event, eventName, targetControl);
            this._isClicked[targetControl.Attribute.GUID] = true;
          } else if (eventName == 'toolTipiconClick') {
            this.openToolTip(this.rawData, targetControl)
          }
          break;
        case AssessmentAttributeType.CHOICE:
          if(eventName == 'main_click_event'){
          this._handleChoiceFormField(controlId, sectionId, event, targetControl);
          this._isClicked[targetControl.Attribute.GUID] = true;
          }else if(eventName == 'toolTipiconClick'){
            this.openToolTip(this.rawData,targetControl)
          }
          break;
        case AssessmentAttributeType.FLAG:
          if (eventName == 'main_click_event') {
            this._handleFlagDropdownListFormField(controlId, sectionId, event, eventName, targetControl);
            this._isClicked[targetControl.Attribute.GUID] = true;
          } else if (eventName == 'toolTipiconClick') {
            this.openToolTip(this.rawData, targetControl)
          }
          break;
        default:
          console.error("unhanled type");
      }
    }
  }

  private getTargetControl(controlId: string, sectionId: string): Control {
    let targetControl: Control;
    for (let tabIdx = 0; tabIdx < this.selectedTemplate.indskr_metadata.Sections.length; tabIdx++) {
      const tab = this.selectedTemplate.indskr_metadata.Sections[tabIdx];
      if (tab && tab.SectionProperties.GUID === sectionId && tab.Controls && tab.Controls.length > 0) {
        let foundControl = tab.Controls.find(c => controlId.includes(c.Attribute.GUID));
        if (foundControl) {
          targetControl = foundControl;
          break;
        }
      }
    }
    return targetControl;
  }

  private _getChoiceLabel(choiceOption: ChoiceOption, isSetToEnglish: boolean = false) {
    let label = choiceOption.Label;
    if (choiceOption.displayNames && !_.isEmpty(choiceOption.displayNames)) {
      let multilingualLabel;
      if(!isSetToEnglish) {
        multilingualLabel = choiceOption.displayNames.find(value => value.LanguageCode == this.localizationService.selectedLanguage.localeID);
      }else {
        multilingualLabel = choiceOption.displayNames.find(value => value.LanguageCode == '1033');
      }
      if (multilingualLabel) {
        label = multilingualLabel.Description;
      } else {
        let foundTranslatedStr = this.localizationService.getTranslatedString(label, isSetToEnglish);
        if(!_.isEmpty(foundTranslatedStr)) label = foundTranslatedStr;
      }
    } else {
      let foundTranslatedStr = this.localizationService.getTranslatedString(label, isSetToEnglish);
      if(!_.isEmpty(foundTranslatedStr)) label = foundTranslatedStr;
    }
    return label
  }

  private async _handleChoiceFormField(id: string, sectionId: string, event, targetControl: Control, isReadOnly: boolean = false, contactID?: string | string[], sectionGeneralValue?: string) {
    if (this._dropdownPopover) return;
    let selectedValue: string[] = [];
    if (contactID === undefined || typeof contactID === 'string') {
    if(this.isMultipleUpdate) {
      let _currentValue = this._multipleFormValue.find((value) => value.contactId == contactID && value.sectionId == sectionId && value.id == sectionGeneralValue)?.currentValue;
      if(!_.isEmpty(_currentValue) && _currentValue.hasOwnProperty(targetControl.Attribute.GUID)) {
        selectedValue = _currentValue[targetControl.Attribute.GUID]['indskr_choicevalues'] ? _currentValue[targetControl.Attribute.GUID]['indskr_choicevalues'].split(",") : [];
      } else if (this.rawData) {
        this.rawData.responses = !_.isEmpty(this.rawData.responses) ? _.orderBy(this.rawData.responses, ['modifiedon'], ['desc']) : [];
        const resp = this.rawData.responses.find(resp => resp.indskr_attribute === targetControl.Attribute.GUID);
        selectedValue = resp ? resp['indskr_choicevalues'].split(",") : []
      }
    } else {
      if (id.includes("_")) {
        const subSectionIds = id.split("_");
        //[formValue['indskr_position'], formValue['indskr_therapeuticarea'], formValue['indskr_product']].join("_")
        const subSectionId = id.split("_").slice(0, 4).join("_");
        if (this._currentFormValue && this._currentFormValue.hasOwnProperty(subSectionId) && this._currentFormValue[subSectionId].hasOwnProperty(targetControl.Attribute.GUID)) {
          selectedValue = this._currentFormValue[subSectionId][targetControl.Attribute.GUID]['indskr_choicevalues'] ? this._currentFormValue[subSectionId][targetControl.Attribute.GUID]['indskr_choicevalues'].split(",") : [];
        } else if (this.rawData) {
          const positionId = subSectionIds[0] ? subSectionIds[0] : null, taId = subSectionIds[1] ? subSectionIds[1] : null, productId = subSectionIds[2] ? subSectionIds[2] : null, procedureId = subSectionIds[3] ? subSectionIds[3] : null, positionGroupId = subSectionIds[4] ? subSectionIds[4] : null;
          this.rawData.responses = !_.isEmpty(this.rawData.responses) ? _.orderBy(this.rawData.responses, ['modifiedon'], ['desc']) : [];
          const resp = this.rawData.responses.find(resp => resp.indskr_attribute === targetControl.Attribute.GUID && resp.indskr_position == positionId && resp.indskr_product == productId && resp.indskr_therapeuticarea == taId && resp.indskr_procedures == procedureId && resp.indskr_positiongroup == positionGroupId);
          selectedValue = resp ? resp['indskr_choicevalues'].split(",") : []
        }
      } else {
        if (this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.Attribute.GUID)) {
          selectedValue = this._currentFormValue[targetControl.Attribute.GUID]['indskr_choicevalues'] ? this._currentFormValue[targetControl.Attribute.GUID]['indskr_choicevalues'].split(",") : [];
        } else if (this.rawData) {
          this.rawData.responses = !_.isEmpty(this.rawData.responses) ? _.orderBy(this.rawData.responses, ['modifiedon'], ['desc']) : [];
          const resp = this.rawData.responses.find(resp => resp.indskr_attribute === targetControl.Attribute.GUID);
          selectedValue = resp ? resp['indskr_choicevalues'].split(",") : []
        }
      }
    }
    }
    //sort choice options by order value
    const targetChoiceOptions: ChoiceOption[] = targetControl.Choice.Options || [];
    const sortedOptionsWithOrders = targetChoiceOptions.filter(op => op.Order !='').sort((a,b) => {
      const rawOrderA: string = a.Order;
      const rawOrderB: string = b.Order;
      const numOrderA: number = parseFloat(rawOrderA);
      const numOrderB: number = parseFloat(rawOrderB);

      if(!isNaN(numOrderA) && !isNaN(numOrderB)) {
        if(numOrderA !== numOrderB) {
          return numOrderA - numOrderB;
        }
        // If 'Order' values are the same, compare 'Value'
        const rawValueA: string = a.Value;
        const rawValueB: string = b.Value;
        const numValueA: number = parseFloat(rawValueA);
        const numValueB: number = parseFloat(rawValueB);
  
        if(!isNaN(numValueA) && !isNaN(numValueB)) {
          if(numValueA !== numValueB) {
            return numValueA - numValueB;
          }
        }
      }
      // If 'Order' and 'Value' values are the same or there is no value, compare 'Label'
      const rawLabelA: string = a.Label;
      const rawLabelB: string = b.Label;
      return rawLabelA.localeCompare(rawLabelB);
    });

    const sortedOptionsWithoutOrders = targetChoiceOptions.filter(op => op.Order =='').sort((a,b) => {
      const rawValueA: string = a.Value;
      const rawValueB: string = b.Value;
      const numValueA: number = parseFloat(rawValueA);
      const numValueB: number = parseFloat(rawValueB);

      if(!isNaN(numValueA) && !isNaN(numValueB)) {
        if(numValueA !== numValueB) {
          return numValueA - numValueB;
        }
      }
      // If 'Value' values are the same or there is no value, compare 'Label'
      const rawLabelA: string = a.Label;
      const rawLabelB: string = b.Label;
      return rawLabelA.localeCompare(rawLabelB);
    });

    let sortedOptions = sortedOptionsWithOrders.concat(sortedOptionsWithoutOrders);
    let data: SelectListData[] = sortedOptions.map(op => {
      return {
        id: op.RatingProperties[0].GUID,
        title: this._getChoiceLabel(op),
        titleEnglish: this._getChoiceLabel(op, true),
        isSelected: selectedValue.includes(op.RatingProperties[0].GUID)
      }
    });
    if (isReadOnly) {
      if (_.isEmpty(selectedValue)) return;
      data = data.filter(d => d.isSelected);
    }
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'dynamic-form-choice-field-select',
      data: data,
      isMultipleSelectionEnabled: targetControl.Choice.Type == RatingScaleType.MULTI_SELECT && !isReadOnly,
      showCancelandDoneBtn: targetControl.Choice.Type == RatingScaleType.MULTI_SELECT && !isReadOnly,
      isReadOnly: isReadOnly
    };
    this._dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event,
    });
    await this._dropdownPopover.present();
    await this._dropdownPopover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        this.scrollToView = true;
        let data = obj.data;
        let newSelectedValue = {};
        if (!_.isEmpty(data.selectedItems)) {
          const choiceValues: string[] = [];
          const choiceLabels: string[] = [];
          const choiceLabelsEnglish: string[] = [];
          data.selectedItems.forEach(item => {
            choiceValues.push(item.id);
            choiceLabels.push(item.title);
            choiceLabelsEnglish.push(item.titleEnglish);
          })
          newSelectedValue = {
            indskr_choice: targetControl.Choice.RatingScale.GUID,
            indskr_choicevalues: choiceValues.join(","),
            indskr_choicelabel: choiceLabels.join(","),
            lang_en_choiceLabel: choiceLabelsEnglish.join(","),
          }
          if (Array.isArray(contactID)) {
            await this.uiService.displayLoader();
            for (let i = 0; i < contactID.length; i++) {
              const cId = contactID[i];
              if(this.isMultipleUpdate) this._setCurrentFormViewAndRawData(cId);
              const isLastRecord: boolean = i === contactID.length - 1;
              this._setAttributeValue(targetControl, sectionId, newSelectedValue, isLastRecord, false, cId, sectionGeneralValue, !isLastRecord);
            }
            await this.uiService.dismissLoader();
          } else if (contactID === undefined || typeof contactID === 'string') {
            if(this.isMultipleUpdate) this._setCurrentFormViewAndRawData(contactID);
            this._setAttributeValue(targetControl, sectionId, newSelectedValue, true, false, contactID, sectionGeneralValue);
          }
        } else if (data.selectedItems && data.selectedItems.length == 0) {
          if (Array.isArray(contactID)) {
            await this.uiService.displayLoader();
            for (let i = 0; i < contactID.length; i++) {
              const cId = contactID[i];
              const isLastRecord: boolean = i === contactID.length - 1;
              this._clearAttributeValue(targetControl, sectionId, cId, sectionGeneralValue, isLastRecord);
            }
            await this.uiService.dismissLoader();
          } else if (contactID === undefined || typeof contactID === 'string') {
            this._clearAttributeValue(targetControl, sectionId, contactID, sectionGeneralValue);
          }
          newSelectedValue = null;
        }
        if (targetControl.Mandatory && this.customerAssessService.getFormFieldType(targetControl) !== FormFieldType.INLINE_INPUT) {
          this.customerAssessService._validateMandatoryField(id, null, { value: newSelectedValue }, this.currentFormView);
        }
        this.validateForm();
      }
      this._dropdownPopover = undefined;
    });
  }

  private async _handleFlagDropdownListFormField(id: string, sectionId: string, event, eventName, targetControl: Control, contactID?: string | string[], sectionGeneralValue?: string) {
    if (this._dropdownPopover) return;
    let selectedValue: boolean = null;

    if (contactID === undefined || typeof contactID === 'string') {
    let _currentValueForMultipleUpdate = this._multipleFormValue.find((value) => value.contactId == contactID && value.sectionId == sectionId && value.id == sectionGeneralValue)?.currentValue;
    if(this.isMultipleUpdate && !_.isEmpty(_currentValueForMultipleUpdate) && _currentValueForMultipleUpdate.hasOwnProperty(targetControl.Attribute.GUID)){
      selectedValue = _currentValueForMultipleUpdate[targetControl.Attribute.GUID].indskr_flag;
    } else if (this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.Attribute.GUID)) {
      selectedValue = this._currentFormValue[targetControl.Attribute.GUID].indskr_flag;
    } else if (this.rawData && this.rawData.responses.some(resp => resp.indskr_attribute === targetControl.Attribute.GUID)) {
      this.rawData.responses = !_.isEmpty(this.rawData.responses) ? _.orderBy(this.rawData.responses, ['modifiedon'], ['desc']) : [];
      const resp = this.rawData.responses.find(resp => resp.indskr_attribute === targetControl.Attribute.GUID);
      selectedValue = resp ? resp.indskr_flag : null;
    } else {
      selectedValue = targetControl.FlagDeafultvalue;
    }
    }

    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'dynamic-form-boolean-field-select',
      data: [{
        id: 'Yes',
        title: this.translate.instant("YES"),
        isSelected: selectedValue !== null ? selectedValue : false
      },
      {
        id: 'No',
        title: this.translate.instant("NO"),
        isSelected: selectedValue !== null ? !selectedValue : false
      }]
    };
    this._dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event
    });
    await this._dropdownPopover.present();
    await this._dropdownPopover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data) {
        this.scrollToView = true;
        let data = obj.data;
        let newSelectedValue = selectedValue;
        if (data.selectedItems && data.selectedItems.length == 1) {
          // if (!(this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.Attribute.GUID) && this._currentFormValue[targetControl.Attribute.GUID] == data.selectedItems[0].id)) {
          newSelectedValue = data.selectedItems[0].id == 'Yes' ? true : false;
          if (Array.isArray(contactID)) {
            await this.uiService.displayLoader();
            for (let i = 0; i < contactID.length; i++) {
              const cId = contactID[i];
              if(this.isMultipleUpdate) this._setCurrentFormViewAndRawData(cId);
              const isLastRecord: boolean = i === contactID.length - 1;
              this._setAttributeValue(targetControl, sectionId, newSelectedValue, isLastRecord, false, cId, sectionGeneralValue, !isLastRecord);
            }
            await this.uiService.dismissLoader();
          } else if (contactID === undefined || typeof contactID === 'string') {
            if(this.isMultipleUpdate) this._setCurrentFormViewAndRawData(contactID);
            this._setAttributeValue(targetControl, sectionId, newSelectedValue, true, false, contactID, sectionGeneralValue);
          }
          // }
        } else if (data.selectedItems && data.selectedItems.length == 0) {
          if (Array.isArray(contactID)) {
            await this.uiService.displayLoader();
            for (let i = 0; i < contactID.length; i++) {
              const cId = contactID[i];
              const isLastRecord: boolean = i === contactID.length - 1;
              this._clearAttributeValue(targetControl, sectionId, cId, sectionGeneralValue, isLastRecord);
            }
            await this.uiService.dismissLoader();
          } else if (contactID === undefined || typeof contactID === 'string') {
            this._clearAttributeValue(targetControl, sectionId, contactID, sectionGeneralValue);
          }
          newSelectedValue = null;
        }

        if (targetControl.Mandatory && this.customerAssessService.getFormFieldType(targetControl) !== FormFieldType.INLINE_INPUT) {
          this.customerAssessService._validateMandatoryField(id, null, { value: newSelectedValue }, this.currentFormView);
        }
        this.validateForm();
      }
      this._dropdownPopover = undefined;
    });
  }

  private _clearAttributeValue(control: Control, sectionId: string, contactID?: string, sectionGeneralValue?: string, skipInit = false) {
    if(this.isMultipleUpdate) {
      let _currentValue;
      if(sectionGeneralValue) {
        _currentValue = this._multipleFormValue.find((value) => value.contactId == contactID && value.sectionId == sectionId && value.id == sectionGeneralValue);
        if(!_.isEmpty(_currentValue) && _currentValue[control.Attribute.GUID] && _currentValue[control.Attribute.GUID]['indskr_customerassessmentresponseid']) {
          delete _currentValue[control.Attribute.GUID];
        }
      } else {
        this._multipleFormValue.filter((value) => value.contactId == contactID).forEach((_value) => delete _value[control.Attribute.GUID]);
      }
      this.rawData = this.multipleFormView.find((view) => view.contactId == contactID).rawData;
    }
    if (this.rawData) {
      this.rawData.responses = !_.isEmpty(this.rawData.responses) ? _.orderBy(this.rawData.responses, ['modifiedon'], ['desc']) : [];
      const respSaved = this.rawData.responses.find(resp => resp.indskr_attribute === control.Attribute.GUID && resp.ownerid === this.authService.user.xSystemUserID);
      if (respSaved) {
        const responseCaptured = {
          statecode: 1,
          statuscode: 2
        };
        if (!this._deletedCurrentFormValue) this._deletedCurrentFormValue = {};
        responseCaptured['indskr_customerassessmentresponseid'] = respSaved.indskr_customerassessmentresponseid;
        this._deletedCurrentFormValue[control.Attribute.GUID] = responseCaptured;
        this.customerAssessService.isAssessmentDirty = true;
      }
    }
    if (this._currentFormValue && this._currentFormValue[control.Attribute.GUID] && this._currentFormValue[control.Attribute.GUID]['indskr_customerassessmentresponseid']) {
      delete this._currentFormValue[control.Attribute.GUID];
    }

    if (!skipInit) {
      this._initViewData(null, this.getTargetSectionById(sectionId), contactID);
      if(this.isMultipleUpdate) this.initGridForMultipleAssess(this.selectedCustomers);
    }
  }

  private async _handleDateTimeFormField(id: string, sectionId: string, event, eventName, targetControl: Control, contactID?: string, sectionGeneralValue?:string) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let currentStartDateTime;
    let currentEndDateTime;
    if (this._currentFormValue && this._currentFormValue.hasOwnProperty(targetControl.Attribute.GUID)) {
      currentStartDateTime = this._currentFormValue[targetControl.Attribute.GUID].indskr_timestamp
    } else if (this.rawData && this.rawData.responses.findIndex(resp => resp.indskr_attribute === targetControl.Attribute.GUID) > -1) {
      this.rawData.responses = !_.isEmpty(this.rawData.responses) ? _.orderBy(this.rawData.responses, ['modifiedon'], ['desc']) : [];
      currentStartDateTime = this.rawData.responses.find(resp => resp.indskr_attribute === targetControl.Attribute.GUID).indskr_timestamp;
    }
    if (currentStartDateTime && !currentEndDateTime) {
      currentEndDateTime = currentStartDateTime;
    }
    let popover = await this.popoverCtrl.create(
      {
        component: IndDatetimeFormComponent,
        componentProps: {
          currentViewPage: CurViewPageType.ConfiguredForm,
          startDateTimeValue: currentStartDateTime ? moment(currentStartDateTime).format() : '',
          endDateTimeValue: currentEndDateTime ? moment(currentEndDateTime).format() : '',
          controlId: targetControl.Attribute.GUID,
          sectionId,
        },
        cssClass: "datetime-popover"
      }
    );
    popover.present();
    popover.onDidDismiss().then((obj: any) => {
      if (obj && obj.data && !_.isEmpty(obj.data.startTime)) {
        const valueDate = new Date(obj.data.startTime);
        this._setAttributeValue(targetControl, sectionId, valueDate.toISOString());
        if (targetControl.Mandatory) {
          this.customerAssessService._validateMandatoryField(targetControl.Attribute.GUID, null, { value: this._currentFormValue[targetControl.Attribute.GUID] }, this.currentFormView);
          this.validateForm();
        }
      }
    })
  }

  private async _handleTimeFormField(timeData) {
    let targetControl: Control;
    if (timeData.controlId) {
      targetControl = this.getTargetControl(timeData.controlId, timeData.sectionId);
      if (timeData.id == DateTimeFieldType.StartTimeField) {
        if (this.startTime && this.startTime.hasOwnProperty(targetControl.Attribute.GUID)) {
          this.startTime[targetControl.Attribute.GUID] = timeData.time;
        } else {
          this.startTime = {
            [targetControl.Attribute.GUID]: timeData.time
          }
        }
        setTimeout(() => {
          this.handleStartDateTimeValue(targetControl, timeData.sectionId);
        }, 0);
      }
    }
  }

  private handleStartDateTimeValue(targetControl: Control, sectionId: string) {
    if (!_.isEmpty(targetControl)) {
      let m = moment(this.startTime[targetControl.Attribute.GUID]);
      if (!m.isValid()) {
        this.notificationService.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'Configured Form', 'top', ToastStyle.DANGER);
        this.startTime[targetControl.Attribute.GUID] = this.prevStartTime[targetControl.Attribute.GUID];
      } else {
        this.prevStartTime[targetControl.Attribute.GUID] = this.startTime[targetControl.Attribute.GUID];
        let selectedDate = this.startTime[targetControl.Attribute.GUID];
        let selectedDateUTC = moment(selectedDate).utc().format();
        let formattedDate = (new Date(selectedDateUTC).toISOString());
        this._setAttributeValue(targetControl, sectionId, formattedDate);
        this.validateForm();
      }
    }
  }

  private async _handleInlineInputFormField(id, sectionId, event, eventName, control: Control, contactID?: string | string[], sectionGeneralValue?: string) {
    if (eventName == 'input_value_confirm' && control) {
      this.scrollToView = true;
      let value;
      if (event.value) {
        if (control.Type == AssessmentAttributeType.RANGE && (Number(event.value) < control.RangeMin || Number(event.value) > control.RangeMax)) {
          value = '';
        } else {
          value = event.value;
        }
      } else if (event.target) {
        if (control.Type == AssessmentAttributeType.RANGE && (Number(event.target.value) < control.RangeMin || Number(event.target.value) > control.RangeMax)) {
          value = '';
        } else {
          value = event.target.value;
        }
      }
      if (value) {
        if(this.isMultipleUpdate) {
          if (Array.isArray(contactID)) {
            await this.uiService.displayLoader();
            for (let i = 0; i < contactID.length; i++) {
              const cId = contactID[i];
              const isLastRecord: boolean = i === contactID.length - 1;
              await this._setAttributeValue(control, sectionId, value, isLastRecord, false, cId, sectionGeneralValue, !isLastRecord);
            }
            await this.uiService.dismissLoader();
          } else if (contactID === undefined || typeof contactID === 'string') {
            this._setAttributeValue(control, sectionId, value, true, false, contactID, sectionGeneralValue);
          }
        }
        else this._setAttributeValue(control, sectionId, value);
      } else {
        if(this.isMultipleUpdate) {
          if (Array.isArray(contactID)) {
            await this.uiService.displayLoader();
            for (let i = 0; i < contactID.length; i++) {
              const cId = contactID[i];
              const isLastRecord: boolean = i === contactID.length - 1;
              this._clearAttributeValue(control, sectionId, cId, sectionGeneralValue, isLastRecord);
            }
            await this.uiService.dismissLoader();
          } else if (contactID === undefined || typeof contactID === 'string') {
            this._clearAttributeValue(control, sectionId, contactID, sectionGeneralValue);
          }
        }
        else this._clearAttributeValue(control, sectionId);
      }
      this.validateForm();
    }
  }
  async openToolTip(rawData, control) {
    if (rawData) {
      this.toolTipdata = rawData.responses.filter(resp => resp.indskr_attribute === control.Attribute.GUID);
    }
    let modifiedOn = (this.datePipe.transform(this.toolTipdata[0]['modifiedon_Formatted'], this.dateTimeFormatsService.date, undefined, this.translate.currentLang)) || (this.datePipe.transform(this.toolTipdata[0]['modifiedon'], this.dateTimeFormatsService.date, undefined, this.translate.currentLang));
    let modifiedBy = this.toolTipdata[0]['modifiedby'] == this.authService.user.xSystemUserID ? this.authService.user.displayName : this.toolTipdata[0]['_modifiedby_value_Formatted'];
    if (!this.emailToolTip) {
      this.emailToolTip = await this.popoverCtrl.create({
        component: PopoverComponent,
        componentProps: {
          tooltipData: {
            modifiedOn: `Last Modified Date : ${modifiedOn}`,
            modifiedBy: `Last Modified By : ${modifiedBy}`
          },
          field: 'CustomerAssessTooltip'
        },
        cssClass: 'customerAssess-popover',
        event
      });

      this.emailToolTip.onDidDismiss().then(() => {
        this.emailToolTip = undefined;
      });

      this.emailToolTip.present();
    }

  }

  private _setAttributeValue(control: Control, sectionId: string, value: any, initViewData: boolean = true, defaultValues: boolean = false, contactID?: string, sectionGeneralValue?: string, skipFormDirtyCheck = false) {
    let responseCaptured = {
      indskr_attribute: control.Attribute.GUID,
      indskr_type: control.Type,
      indskr_name: control.Attribute.DisplayName
    };
    responseCaptured['indskr_name'] = this._getLabeltext(control, true);
    if (this.device._isOffline) {
      responseCaptured['modifiedon'] = new Date().toISOString().toString(),
      responseCaptured['modifiedby'] = this.authService.user.xSystemUserID
    }

    this._setAttributeResponseValue(control, responseCaptured, value);
    const targetSection = this.getTargetSectionById(sectionId);
    if(this.isMultipleUpdate) {
      if(!_.isEmpty(responseCaptured['indskr_customerassessmentresponseid'])) delete responseCaptured['indskr_customerassessmentresponseid'];
      let capturedResponses = _.cloneDeep(responseCaptured);
      let _foundForm = this._multipleFormValue.find((formValue) => formValue.contactId == contactID && formValue.sectionId == sectionId && formValue.id == sectionGeneralValue);
      if(_.isEmpty(_foundForm)) this._multipleFormValue.push({
        contactId: contactID,
        sectionId,
        id: sectionGeneralValue,
        currentValue: {}
      });
      let _currentValues = this._multipleFormValue.find((formValue) => formValue.contactId == contactID && formValue.sectionId == sectionId && formValue.id == sectionGeneralValue)?.currentValue;
      this._updateSectionGeneralValuesForMultipleUpdate(targetSection, capturedResponses, sectionGeneralValue);
      // check section general value
      let isSectionValueSame = false;
      let _currentValue;
      if(!_.isEmpty(_currentValues)) {
        _currentValue = _currentValues[control.Attribute.GUID];
        if(!_.isEmpty(_currentValue)) {
          isSectionValueSame = _currentValue['indskr_procedures'] == capturedResponses['indskr_procedures'] &&
          _currentValue['indskr_product'] == capturedResponses['indskr_product'] &&
          _currentValue['indskr_position'] == capturedResponses['indskr_position'] &&
          _currentValue['indskr_positiongroup'] == capturedResponses['indskr_positiongroup'] &&
          _currentValue['indskr_therapeuticarea'] == capturedResponses['indskr_therapeuticarea'];
        }
      }
      if(!_.isEmpty(_currentValues) && isSectionValueSame) {
          if(_currentValues[control.Attribute.GUID] && _currentValues[control.Attribute.GUID]['indskr_customerassessmentresponseid'])
          // responseCaptured['indskr_customerassessmentresponseid'] = _currentValues[control.Attribute.GUID]['indskr_customerassessmentresponseid'];
          capturedResponses['indskr_customerassessmentresponseid'] = _currentValues[control.Attribute.GUID]['indskr_customerassessmentresponseid'];
      } else {
        let index = -1;
        if (this.rawData) {
          if (defaultValues) {
            index = this.rawData.responses.findIndex(resp => resp.indskr_attribute === control.Attribute.GUID);
          } else
            index = this.rawData.responses.findIndex(resp => resp.indskr_attribute === control.Attribute.GUID && resp.ownerid === this.authService.user.xSystemUserID);
        }
        // responseCaptured['indskr_customerassessmentresponseid'] = index >= 0 ? this.rawData.responses[index]['indskr_customerassessmentresponseid'] : Guid.create().toString();
        capturedResponses['indskr_customerassessmentresponseid'] = index >= 0 ? this.rawData.responses[index]['indskr_customerassessmentresponseid'] : Guid.create().toString();
      }
      this._multipleFormValue.find((value) => value.contactId == contactID && value.sectionId == sectionId && value.id == sectionGeneralValue).currentValue[control.Attribute.GUID] = capturedResponses;
    } else {
      if (!this._currentFormValue) this._currentFormValue = {};
      if (this._currentFormValue[control.Attribute.GUID] && this._currentFormValue[control.Attribute.GUID]['indskr_customerassessmentresponseid']) {
        responseCaptured['indskr_customerassessmentresponseid'] = this._currentFormValue[control.Attribute.GUID]['indskr_customerassessmentresponseid'];
      } else {
        let index = -1;
        if (this.rawData) {
          if (defaultValues) {
            index = this.rawData.responses.findIndex(resp => resp.indskr_attribute === control.Attribute.GUID);
          } else
            index = this.rawData.responses.findIndex(resp => resp.indskr_attribute === control.Attribute.GUID && resp.ownerid === this.authService.user.xSystemUserID);
        }
        responseCaptured['indskr_customerassessmentresponseid'] = index >= 0 ? this.rawData.responses[index]['indskr_customerassessmentresponseid'] : Guid.create().toString();
      }
      this._currentFormValue[control.Attribute.GUID] = responseCaptured;
    }

    if (!defaultValues)
      this.customerAssessService.isAssessmentDirty = true;
    if (initViewData && !defaultValues) {
      this._initViewData(null, targetSection, contactID);
      if(this.isMultipleUpdate) {
        this.initGridForMultipleAssess(this.selectedCustomers);
      }
    }
    if (!skipFormDirtyCheck && this.isMultipleUpdate) {
      this.checkMultipleUpdateFormDirty();
    }
  }

  private _updateSectionGeneralValuesForMultipleUpdate(section:AttributeSection, responseCaptured, sectionGeneralValue) {
    if(sectionGeneralValue) {
      const sectionGeneralValueIds = sectionGeneralValue.split("_");
      if (section.Procedure) {
        //Procedure section
        responseCaptured['indskr_procedures'] =  _.isEmpty(sectionGeneralValueIds[3]) ? undefined : sectionGeneralValueIds[3];
        responseCaptured['indskr_product'] = undefined;
        responseCaptured['indskr_position'] = undefined;
        responseCaptured['indskr_positiongroup'] = undefined;
        responseCaptured['indskr_therapeuticarea'] = undefined;
      } else if (section.Product) {
        responseCaptured['indskr_procedures'] = undefined;
        responseCaptured['indskr_product'] = _.isEmpty(sectionGeneralValueIds[2]) ? undefined : sectionGeneralValueIds[2];
        responseCaptured['indskr_position'] = _.isEmpty(sectionGeneralValueIds[0]) ? undefined : sectionGeneralValueIds[0];
        responseCaptured['indskr_positiongroup'] = _.isEmpty(sectionGeneralValueIds[4]) ? undefined : sectionGeneralValueIds[4];
        responseCaptured['indskr_therapeuticarea'] = _.isEmpty(sectionGeneralValueIds[1]) ? undefined : sectionGeneralValueIds[1];
      } else if (section.Position) {
        responseCaptured['indskr_procedures'] = undefined;
        responseCaptured['indskr_product'] = undefined;
        responseCaptured['indskr_position'] = _.isEmpty(sectionGeneralValueIds[0]) ? undefined : sectionGeneralValueIds[0];
        responseCaptured['indskr_therapeuticarea'] =_.isEmpty(sectionGeneralValueIds[1]) ? undefined : sectionGeneralValueIds[1];
        responseCaptured['indskr_positiongroup'] = undefined;
      } else if (section.PositionGroup) {
        responseCaptured['indskr_procedures'] = undefined;
        responseCaptured['indskr_product'] = _.isEmpty(sectionGeneralValueIds[2]) ? undefined : sectionGeneralValueIds[2];
        responseCaptured['indskr_position'] = undefined;
        responseCaptured['indskr_therapeuticarea'] =_.isEmpty(sectionGeneralValueIds[1]) ? undefined : sectionGeneralValueIds[1];
        responseCaptured['indskr_positiongroup'] = _.isEmpty(sectionGeneralValueIds[4]) ? undefined : sectionGeneralValueIds[4];
      } else if (section.TherapetuicArea) {
        responseCaptured['indskr_procedures'] = undefined;
        responseCaptured['indskr_product'] = undefined;
        responseCaptured['indskr_position'] = undefined;
        responseCaptured['indskr_therapeuticarea'] = _.isEmpty(sectionGeneralValueIds[1]) ? undefined : sectionGeneralValueIds[1];
        responseCaptured['indskr_positiongroup'] = _.isEmpty(sectionGeneralValueIds[4]) ? undefined : sectionGeneralValueIds[4];
      }
    }
  }

  private _setAttributeResponseValue(control: Control, responseCaptured: any, value: any) {
    switch (control.Type) {
      case AssessmentAttributeType.TEXT:
        responseCaptured['indskr_text'] = value;
        break;
      case AssessmentAttributeType.FLAG:
        responseCaptured['indskr_flag'] = value;
        break;
      case AssessmentAttributeType.MEMO:
        responseCaptured['indskr_memo'] = value;
        break;
      case AssessmentAttributeType.NUMERIC:
      case AssessmentAttributeType.RANGE:
        responseCaptured['indskr_numeric'] = value;
        break;
      case AssessmentAttributeType.TIMESTAMP:
        responseCaptured['indskr_timestamp'] = value;
        break;
      case AssessmentAttributeType.CHOICE:
        responseCaptured['indskr_choice'] = value['indskr_choice'];
        responseCaptured['indskr_choicevalues'] = value['indskr_choicevalues'];
        responseCaptured['indskr_choicelabel'] = value['lang_en_choiceLabel'] ? value['lang_en_choiceLabel'] : value['indskr_choicelabel'];
        break;
    }
  }

  private getTargetSectionByControlId(id: string, sectionId?: string): AttributeSection {
    let targetSection: AttributeSection;
    if(!_.isEmpty(sectionId)) {
      let foundTabIdx = this.selectedTemplate.indskr_metadata.Sections.findIndex(section=>section.SectionProperties && section.SectionProperties.GUID == sectionId);
      if(foundTabIdx > -1) {
        const tab = this.selectedTemplate.indskr_metadata.Sections[foundTabIdx];
        if (tab && tab.Controls && tab.Controls.length > 0) {
          let foundControl = tab.Controls.find(c => id.includes(c.Attribute.GUID));
          if (foundControl) {
            targetSection = tab;
          }
        }
      }else {
        for (let tabIdx = 0; tabIdx < this.selectedTemplate.indskr_metadata.Sections.length; tabIdx++) {
          const tab = this.selectedTemplate.indskr_metadata.Sections[tabIdx];
          if (tab && tab.Controls && tab.Controls.length > 0) {
            let foundControl = tab.Controls.find(c => id.includes(c.Attribute.GUID));
            if (foundControl) {
              targetSection = tab;
              break;
            }
          }
        }
      }
    }else {
      for (let tabIdx = 0; tabIdx < this.selectedTemplate.indskr_metadata.Sections.length; tabIdx++) {
        const tab = this.selectedTemplate.indskr_metadata.Sections[tabIdx];
        if (tab && tab.Controls && tab.Controls.length > 0) {
          let foundControl = tab.Controls.find(c => id.includes(c.Attribute.GUID));
          if (foundControl) {
            targetSection = tab;
            break;
          }
        }
      }
    }
    return targetSection;
  }
  private getTargetSectionById(sectionId: string): AttributeSection {
    let targetSection: AttributeSection;
    for (let tabIdx = 0; tabIdx < this.selectedTemplate.indskr_metadata.Sections.length; tabIdx++) {
      const tab = this.selectedTemplate.indskr_metadata.Sections[tabIdx];
      if (tab?.SectionProperties.GUID === sectionId && tab?.Controls?.length && tab.Controls.length > 0) {
        targetSection = tab;
        break;
      }
    }
    return targetSection;
  }

  private _isShowArrow(control: Control, section: AttributeSection) {
    return this._validateAttributeAccess(control) != Access.READ_ONLY && !section.PositionGroup && !section.Position && !section.Product && !section.TherapetuicArea && !section.Procedure;
  }

  private _isControlDisabled(control: Control, section: AttributeSection) {
    return this._validateAttributeAccess(control) == Access.READ_ONLY || section.PositionGroup || section.Position || section.Product || section.TherapetuicArea || section.Procedure;
  }

  private _isNotValidToEdit(control: Control) {
    let isValid: boolean = true;
    if (!_.isEmpty(control.FromDate)) {
      isValid = new Date(control.FromDate) <= new Date();
      if (!_.isEmpty(control.ToDate)) {
        isValid = isValid && new Date(control.ToDate) >= new Date();
      }
    }
    return !isValid;
  }

  private _getInputTextForFormField(control: Control, section: AttributeSection, subSectionId: string, isGridLabel: boolean = false, contactID?: string, sectionGeneralValue?: string): string {
    let value = '';
    if (control && control.Type) {
      let formValue;
      if (section.TherapetuicArea || section.Product || section.PositionGroup || section.Position || section.Procedure) {
        formValue = this.getFormValueForSubSectionControl(control, section, subSectionId, contactID, sectionGeneralValue, isGridLabel);
      } else {
        formValue = this.getFormValue(control, contactID, sectionGeneralValue, section.SectionProperties.GUID);
      }
      if (formValue || control.Type == AssessmentAttributeType.FLAG) {
        switch (control.Type) {
          case AssessmentAttributeType.TEXT:
            value = formValue['indskr_text'];
            break;
          case AssessmentAttributeType.FLAG:
            if (formValue && formValue.hasOwnProperty('indskr_flag')) {
              value = formValue['indskr_flag'] ? this.translate.instant("YES") : this.translate.instant("NO");
            } else {
              //default flag value
              value = control['FlagDeafultvalue'] ? this.translate.instant("YES") : this.translate.instant("NO")
              if (!section.TherapetuicArea && !section.PositionGroup && !section.Position && !section.Product && !section.Procedure)
                this._setAttributeValue(control, section.SectionProperties.GUID, control.FlagDeafultvalue, false, true, contactID);
            }
            break;
          case AssessmentAttributeType.MEMO:
            value = formValue['indskr_memo'];
            break;
          case AssessmentAttributeType.NUMERIC:
          case AssessmentAttributeType.RANGE:
            value = '' + formValue['indskr_numeric'];
            break;
          case AssessmentAttributeType.TIMESTAMP:
            value = formValue['indskr_timestamp'] ? format(formValue['indskr_timestamp'], this.dateTimeFormatsService.dateTimeToUpper) : '';
            break;
          case AssessmentAttributeType.CHOICE:
            const choiceValues: string[] = formValue['indskr_choicevalues'] ? formValue['indskr_choicevalues'].split(",") : [];


            const selectedOptions: ChoiceOption[] = control.Choice.Options.filter(option => choiceValues.includes(option.RatingProperties[0].GUID));
            if (selectedOptions.length)
              value = selectedOptions.length == 1 ? this._getChoiceLabel(selectedOptions[0]) : this._getChoiceLabel(selectedOptions[0]) + ' +' + (selectedOptions.length - 1);
            break;
        }
      }
      return value;
    }
  }

  private getFormValue(control: Control, contactID?: string, sectionGeneralValue?:string, sectionID?: string) {
    if(this.isMultipleUpdate) {
      let _currentValue = this._multipleFormValue.find((value) => value.contactId == contactID && value.sectionId == sectionID);
      if(_currentValue && _currentValue.currentValue && _currentValue.currentValue[control.Attribute.GUID]) {
        return _currentValue.currentValue[control.Attribute.GUID];
      } else if (this.rawData) {
        if (!(this._deletedCurrentFormValue && this._deletedCurrentFormValue[control.Attribute.GUID])) {
          this.rawData.responses = !_.isEmpty(this.rawData.responses) ? _.orderBy(this.rawData.responses, ['modifiedon'], ['desc']) : [];
          return this.rawData.responses.find(resp => resp.indskr_attribute === control.Attribute.GUID);
        }
      }
    } else {
      if (this._currentFormValue && this._currentFormValue[control.Attribute.GUID]) {
        return this._currentFormValue[control.Attribute.GUID];
      } else if (this.rawData) {
        if (!(this._deletedCurrentFormValue && this._deletedCurrentFormValue[control.Attribute.GUID])) {
          this.rawData.responses = !_.isEmpty(this.rawData.responses) ? _.orderBy(this.rawData.responses, ['modifiedon'], ['desc']) : [];
          return this.rawData.responses.find(resp => resp.indskr_attribute === control.Attribute.GUID);
        }
      }
    }
    return null;
  }

  private _getSubSectionHeaderViewForTab(section: AttributeSection, subSectionId: string) {
    try {
      const headerLabel = this.customerAssessService.getSelectionTemplate(this.customerAssessService.parseSectionId(subSectionId), this.masterData, null, this.specialty);
      let sectionHeader = {
        id: subSectionId,
        type: 'sub-section-header',
        isSharedSection: false,
        view: {
          id: subSectionId,
          title: headerLabel.name,
          secondaryText: headerLabel.secondaryText,
          controls: [],
        },
      };
      sectionHeader.view.controls.push({
        id: 'edit_'.concat(section.SectionProperties.GUID).concat(subSectionId),
        isDisabled: this._isEditDisabled(section),
        icon: 'assets/imgs/assess_header_edit.svg'
      });
      return sectionHeader;
    } catch (error) {
      console.log('Error parsing sub-section header view from tab : ' + error);
    }
  }

  private _isEditDisabled(section: AttributeSection): boolean {
    return section.Controls.every(control => this._isNotValidToEdit(control) || this._validateAttributeAccess(control) === Access.READ_ONLY) || this.readOnly;
  }

  private _getSectionHeaderViewForTab(section: AttributeSection) {
    try {
      // const headerLabel: string = this._getSectionHeader(section.SectionProperties);//section.SectionProperties.DisplayName;
      const headerLabel: string = this.customerAssessService.getSectionHeader(section.SectionProperties);//section.SectionProperties.DisplayName;
      let sectionHeader = {
        id: section.SectionProperties.GUID,
        type: 'section-header',
        isSharedSection: !section.TherapetuicArea && !section.Product && !section.PositionGroup && !section.Position && !section.Procedure,
        view: {
          id: headerLabel,
          title: headerLabel,
          controls: [],
          doNotModifyTitleCase: true
        },
      };
      sectionHeader.view.controls.push({
        id: 'add_'.concat(section.SectionProperties.GUID),
        isVisible: (section.Product || section.PositionGroup || section.Position || section.TherapetuicArea || section.Procedure),
        isDisabled: this.isSectionDisabled(section),
        iconClass: 'pluse-icon'
      });
      return sectionHeader;
    } catch (error) {
      console.log('Error parsing section header view from tab' + error);
    }
  }

  private isSectionDisabled(section: AttributeSection): boolean {
    try {
      if (this._isEditDisabled(section)) return true;
      const unSavedData = this.getUnSavedData(section);
      return unSavedData.products.length == 0 && unSavedData.ta.length == 0 && unSavedData.positions.length == 0 && unSavedData.procedures.length == 0 && unSavedData.positionGroups.length == 0;
    } catch (error) {
      console.error("Failed to get data for secton: ", error);
    }
  }

  public updateFormDate(event) {
    if (event && event.value && this._dateTimePickerActiveControl && this._dateTimePickerActiveSectionId) {
      if (this._dateTimePickerActiveControl.Type == AssessmentAttributeType.TIMESTAMP) {
        let valueDate = new Date(event.value);
        this._setAttributeValue(this._dateTimePickerActiveControl, this._dateTimePickerActiveSectionId, valueDate.toISOString());
      }
    }
    if (this._dateTimePickerActiveControl && this._dateTimePickerActiveControl.Mandatory) {
      let value;
      try {
        value = this._currentFormValue[this._dateTimePickerActiveControl.Attribute.GUID];
      } catch (error) {

      }
      this.customerAssessService._validateMandatoryField(this._dateTimePickerActiveControl.Attribute.GUID, null, { value: value }, this.currentFormView)
    }
  }

  private async _openInputApplyAllPopover(button, event, item, control: Control, inputOptions: any) {
    if (this._dropdownPopover) return;

    const viewData: IIndTextInputPopoverViewData = {
      showCancelAndDoneBtn: true,
    };
    const inputFieldAttributes = {
      autofocus: true,
      placeholder: item?.placeholder,
      ...inputOptions,
    };

    this._dropdownPopover = await this.popoverCtrl.create({
      component: IndTextInputPopupComponent,
      componentProps: { viewData, inputFieldAttributes },
      cssClass: 'dropdown-list-view',
      event,
    });

    await this._dropdownPopover.present();
    const response = await this._dropdownPopover.onDidDismiss();

    if (response?.data?.isDone === true) {
      button.eventHandler && button.eventHandler({ target: { id: event?.target?.id }, value: response.data.value }, 'input_value_confirm', item);
    }
    this._dropdownPopover = undefined;
  }

  private _handleApplyAllBtnClick(button, event, item) {
    const targetControl: Control = this.getTargetControl(item.columnItemId, button.sectionId);
    if (!targetControl) {
      console.error('_handleApplyAllBtnClick: no targetControl: ', button, item);
      return;
    }
    const inputOptions: any = {
      type: 'text'
    };
    switch (targetControl.Type) {
      case AssessmentAttributeType.RANGE:
        if (targetControl.RangeMin) {
          inputOptions.min = targetControl.RangeMin;
        }
        if (targetControl.RangeMax) {
          inputOptions.max = targetControl.RangeMax;
        }
      case AssessmentAttributeType.NUMERIC:
        inputOptions.type = 'number';
      case AssessmentAttributeType.TEXT:
      case AssessmentAttributeType.MEMO:
        this._openInputApplyAllPopover(button, event, item, targetControl, inputOptions);
        break;

      default:
        button.eventHandler && button.eventHandler(event, 'input_click_event', item);
        break;
    }
  }

  public onSectionHeaderControlClick(button: any, event?, item?: any) {
    const id = button?.id || button;

    if(this.isTeamViewEnabled && id && id == 'assessment-teamView-filter') {
      this._openTeamViewFilter();
    }else if(id) {
      if(this.isMultipleUpdate) this.currentEventId = event?.target?.id;
      if (button.isApplyAll === true) {
        this._handleApplyAllBtnClick(button, event, item);
        return;
      }
      const targetSection: AttributeSection = this.getTargetSection(id);
      if (targetSection) {
        //Product always mapped to Position
        //OMNI-30279: If position is not true then it will not be taking product flag
        targetSection.Controls = targetSection.Controls.filter(control => this._validateAttributeAccess(control) == Access.READ_WRITE && !this._isNotValidToEdit(control));
        let isEdit: boolean = false;
        // let title = targetSection.SectionProperties.DisplayName;
        let title = this.customerAssessService.getSectionHeader(targetSection.SectionProperties);
        let formView;
        let _currentFormValueForSection = {};
        if (id.includes("edit")) {
          //formView contains Controls: id = sectionId_controlGUID, SubSection: id = sectionId
          formView = this.currentFormView.find(formView => formView.id.includes(id.split("edit_" + targetSection.SectionProperties.GUID)[1]) && formView.type == 'sub-section-header');
          title = formView.view['title'];
          isEdit = true;
          if (this._currentFormValue.hasOwnProperty(formView['id'])) {
            _currentFormValueForSection[formView['id']] = this._currentFormValue[formView['id']];
          }
        }
        if (this.isMultipleUpdate) {
          if(!_.isEmpty(this._multipleFormValue)) {
            _currentFormValueForSection = this._multipleFormValue.filter((value) => value.sectionId == targetSection.SectionProperties.GUID);
          }
        }
        const sectionDetails: IndSectionSpecificAssessDataModel = {
          id: targetSection.SectionProperties.GUID,
          title: title,
          isEdit: isEdit,
          section: targetSection,
          eventHandler: (id: string, value) => this.sectionSpecificCallback(id, value)
        }
        this.openSectionView(sectionDetails, formView ? formView['id'] : null, _currentFormValueForSection);
      }
    }
  }

  private sectionSpecificCallback(id, value) {
    this.scrollToView = true;
    const targetSection: AttributeSection = this.getTargetSection(id);
    let sectionSpecificValue = value;
    if (targetSection) {
      if(this.isMultipleUpdate && !_.isEmpty(sectionSpecificValue)) {
        sectionSpecificValue.forEach((sectionVal) => {
          if(sectionVal.contactId && sectionVal.sectionId && sectionVal.id && !_.isEmpty(sectionVal.currentValue)) {
            let controlIds: string[] = Object.keys(sectionVal.currentValue)
            let _foundForm = this._multipleFormValue.find((formValue) => formValue.contactId == sectionVal.contactId && formValue.sectionId == sectionVal.sectionId && formValue.id == sectionVal.id);
            if(_.isEmpty(_foundForm)) this._multipleFormValue.push({
              contactId: sectionVal.contactId,
              sectionId: targetSection.SectionProperties.GUID,
              id: sectionVal.id,
              currentValue: {}
            });
            controlIds.forEach(conId => {
              // targetSection.Controls.filter(control => Object.keys(sectionVal.currentValue[conId]).includes(control.Attribute.GUID)).forEach(control => {
                this._multipleFormValue.forEach((val) => {
                  if(val['contactId'] == sectionVal.contactId && val['sectionId'] == sectionVal.sectionId && val['id'] == sectionVal.id) val.currentValue[conId] = sectionVal.currentValue[conId];
                })
              // })
            })
          }
        })
        this.initGridForMultipleAssess(this.selectedCustomers);
        this.checkMultipleUpdateFormDirty();
      } else {
        const sectionIds: string[] = Object.keys(value);
        sectionIds.forEach(sectionId => {
          targetSection.Controls.filter(control => Object.keys(sectionSpecificValue[sectionId]).includes(control.Attribute.GUID)).forEach(control => {
            if (!this._currentFormValue) this._currentFormValue = {};
            this._currentFormValue[sectionId] = sectionSpecificValue[sectionId];
          })
          this._initViewData(sectionId, targetSection);
        })
        this.validateForm();
      }
    }
  }

  private getTargetSection(id: string): AttributeSection {
    const section = this.selectedTemplate.indskr_metadata.Sections.find(section => id.includes(section.SectionProperties.GUID));
    if (section) {
      return _.cloneDeep(section);
    }
    return null;
  }

  private openSectionView(sectionDetails: IndSectionSpecificAssessDataModel, sectionId: string, _currentFormValueForSection) {
    if (this.navService.getActiveChildNavViewPageName() !== PageName.SectionSpecificComponent) {
      this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
      let unSavedPositions: UserPosition[] = [];
      let unSavedPositionGroups: any[] = [];
      let unSavedProducts: Brand[] = [];
      let unSavedTA: TherapeuticArea[] = [];
      let unSavedProcedures: Brand[] = [];
      if (sectionDetails.isEdit) {
        const parsedSectionId = this.customerAssessService.parseSectionId(sectionId);
        unSavedPositions = this.masterData.positions.filter(posi => posi.ID == parsedSectionId.positionId);
        unSavedPositionGroups = this.masterData.positionGroups.filter(posi => posi.positionGroupId == parsedSectionId.positionGroupId);
        unSavedTA = this.masterData.ta.filter(ta => ta.therapeuticareaId == parsedSectionId.taId);
        unSavedProducts = this.masterData.products.filter(prod => prod.ID == parsedSectionId.productId);
        unSavedProcedures = this.masterData.procedures.filter(procedure => procedure.ID == parsedSectionId.procedureId);
      } else {
        const unSavedData = this.getUnSavedData(sectionDetails.section);
        unSavedPositions = _.orderBy(unSavedData.positions, 'name');
        unSavedPositionGroups = _.orderBy(unSavedData.positionGroups, 'positionGroupName');
        unSavedProducts = _.orderBy(_.uniqBy(unSavedData.products, 'ID'), 'name');
        unSavedTA = _.orderBy(unSavedData.ta, 'name');
        unSavedProcedures = _.orderBy(unSavedData.procedures, 'name');
      }
      if(this.isMultipleUpdate) {
        this.navService.pushWithPageTracking(SectionSpecificComponent, PageName.SectionSpecificComponent,
          {
            template: sectionDetails,
            masterData: { positions: unSavedPositions, ta: unSavedTA, products: unSavedProducts, procedures: unSavedProcedures, positionGroups: unSavedPositionGroups },
            multipleFormView: this.multipleFormView,
            currentMultipleFormValue: _currentFormValueForSection,
            fromMultiUpdate: true,
            criteriaData: { entity: this.selectedTemplate.indskr_entity, refData: this.selectedTemplate.indskr_entity == 'contact' ? this.contactService.contactInformation : this.accountService.selected }
          }, PageName.CustomerAssessComponent,);
      } else {
        this.navService.pushChildNavPageWithPageTracking(SectionSpecificComponent, PageName.SectionSpecificComponent, PageName.CustomerAssessComponent, {
          template: sectionDetails,
          rawData: this.rawData,
          specialty: sectionDetails.section.Procedure ? this.specialty : null,
          masterData: { positions: unSavedPositions, ta: unSavedTA, products: unSavedProducts, procedures: unSavedProcedures, positionGroups: unSavedPositionGroups },
          currentFormValueForSection: _currentFormValueForSection,
          fromMultiUpdate: false,
          criteriaData: { entity: this.selectedTemplate.indskr_entity, refData: this.selectedTemplate.indskr_entity == 'contact' ? this.contactService.contactInformation : this.accountService.selected }
        });
      }
      this.navService.setChildNavRightPaneView(true);
    }
  }

  private getUnSavedData(section: AttributeSection) {
    const unSavedDatas: { products: Brand[], positions: UserPosition[], ta: TherapeuticArea[], procedures: Brand[], positionGroups: Array<any> } = { products: [], positions: [], ta: [], procedures: [], positionGroups: [] };
    if (!section.Product && !section.TherapetuicArea && !section.PositionGroup && !section.Position && section.Procedure) {
      //Procedure only
      if(this.isMultipleUpdate) {
        let savedProcIdsMultipeUpdate = [];
        this.multipleFormView.forEach((view) => {
          let ids = view.rawData?.responses ? view.rawData.responses.filter(resp => !resp['indskr_therapeuticarea'] && !resp['indskr_product'] && !resp['indskr_position'] && !resp['indskr_positiongroup'] && resp['indskr_procedures']).map(resp => resp['indskr_procedures']) : [];
          if(!_.isEmpty(ids)) savedProcIdsMultipeUpdate = _.cloneDeep(ids);
        })
        this._multipleFormValue?.forEach((formVal) => {
          for (let [, value] of Object.entries(formVal.currentValue)) {
            // for (let [, value1] of Object.entries(value)) {
              if (value && !value['indskr_position'] && !value['indskr_positiongroup'] && !value['indskr_therapeuticarea'] && !value['indskr_product'] && value['indskr_procedures']) {
                savedProcIdsMultipeUpdate.push(value['indskr_procedures']);
              // }
            }
          }
        });
        savedProcIdsMultipeUpdate = [...new Set(savedProcIdsMultipeUpdate)];
        unSavedDatas.procedures = this.masterData.procedures.filter(procedure => !savedProcIdsMultipeUpdate.includes(procedure.ID));
      } else {
        const savedProcedureIds = this.rawData?.responses ? this.rawData.responses.filter(resp => !resp['indskr_therapeuticarea'] && !resp['indskr_product'] && !resp['indskr_position'] && !resp['indskr_positiongroup'] && resp['indskr_procedures']).map(resp => resp['indskr_procedures']) : [];
        for (let [, value] of Object.entries(this._currentFormValue)) {
          for (let [, value1] of Object.entries(value)) {
            if (value1 && !value1['indskr_position'] && !value1['indskr_positiongroup'] && !value1['indskr_therapeuticarea'] && !value1['indskr_product'] && value1['indskr_procedures']) {
              savedProcedureIds.push(value1['indskr_procedures']);
            }
          }
        }
        unSavedDatas.procedures = this.masterData.procedures.filter(procedure => !savedProcedureIds.includes(procedure.ID));
      }
    }
    else if (!section.PositionGroup && !section.Product && !section.TherapetuicArea && section.Position) {
      //Position only
      if(this.isMultipleUpdate) {
        let savedPosIdsMultipeUpdate = [];
        this.multipleFormView.forEach((view) => {
          let ids = view.rawData?.responses ? view.rawData.responses.filter(resp => !resp['indskr_therapeuticarea'] && !resp['indskr_product'] && !resp['indskr_positiongroup'] && resp['indskr_position']).map(resp => resp['indskr_position']) : [];
          if(!_.isEmpty(ids)) savedPosIdsMultipeUpdate = _.cloneDeep(ids);
        })
        this._multipleFormValue?.forEach((formVal) => {
          for (let [, value] of Object.entries(formVal.currentValue)) {
            // for (let [, value1] of Object.entries(value)) {
              if (value && value['indskr_position'] && !value['indskr_positiongroup'] && !value['indskr_therapeuticarea'] && !value['indskr_product']) {
                savedPosIdsMultipeUpdate.push(value['indskr_position']);
              }
            // }
          }
        });
        savedPosIdsMultipeUpdate = [...new Set(savedPosIdsMultipeUpdate)];
        unSavedDatas.positions = this.masterData.positions.filter(position => !savedPosIdsMultipeUpdate.includes(position.ID));
      } else {
        const savedPositionIds = this.rawData?.responses ? this.rawData.responses.filter(resp => !resp['indskr_therapeuticarea'] && !resp['indskr_product'] && !resp['indskr_positiongroup'] && resp['indskr_position']).map(resp => resp['indskr_position']) : [];
      for (let [, value] of Object.entries(this._currentFormValue)) {
        for (let [, value1] of Object.entries(value)) {
          if (value1 && !value1['indskr_positiongroup'] && value1['indskr_position'] && !value1['indskr_therapeuticarea'] && !value1['indskr_product']) {
            savedPositionIds.push(value1['indskr_position']);
          }
        }
      }
      unSavedDatas.positions = this.masterData.positions.filter(position => !savedPositionIds.includes(position.ID));
      }
    }
    else if (section.PositionGroup && !section.Product && !section.TherapetuicArea && !section.Position) {
      //PositionGroup only
      if(this.isMultipleUpdate) {
        let savedPosGrpIdsMultipeUpdate = [];
        this.multipleFormView.forEach((view) => {
          let ids = view.rawData?.responses ? view.rawData.responses.filter(resp => !resp['indskr_therapeuticarea'] && !resp['indskr_product'] && resp['indskr_positiongroup'] && !resp['indskr_position']).map(resp => resp['indskr_positiongroup']) : [];
          if(!_.isEmpty(ids)) savedPosGrpIdsMultipeUpdate = _.cloneDeep(ids);
        })
        this._multipleFormValue?.forEach((formVal) => {
          for (let [, value] of Object.entries(formVal.currentValue)) {
            // for (let [, value1] of Object.entries(value)) {
              if (value && !value['indskr_position'] && value['indskr_positiongroup'] && !value['indskr_therapeuticarea'] && !value['indskr_product']) {
                savedPosGrpIdsMultipeUpdate.push(value['indskr_positiongroup']);
              }
            // }
          }
        });
        savedPosGrpIdsMultipeUpdate = [...new Set(savedPosGrpIdsMultipeUpdate)];
        unSavedDatas.positionGroups = this.masterData.positionGroups.filter(pg => !savedPosGrpIdsMultipeUpdate.includes(pg['positionGroupId']));
      } else {
        const savedPositionGroupIds = this.rawData?.responses ? this.rawData.responses.filter(resp => !resp['indskr_therapeuticarea'] && !resp['indskr_product'] && resp['indskr_positiongroup'] && !resp['indskr_position']).map(resp => resp['indskr_positiongroup']) : [];
      for (let [, value] of Object.entries(this._currentFormValue)) {
        for (let [, value1] of Object.entries(value)) {
          if (value1 && value1['indskr_positiongroup'] && !value1['indskr_position'] && !value1['indskr_therapeuticarea'] && !value1['indskr_product']) {
            savedPositionGroupIds.push(value1['indskr_position']);
          }
        }
      }
      unSavedDatas.positionGroups = this.masterData.positionGroups.filter(pg => !savedPositionGroupIds.includes(pg.positionGroupId));
      }
    }
    else if (!section.Product && section.TherapetuicArea && !section.Position && !section.PositionGroup) {
      //TA only
      if(this.isMultipleUpdate) {
        let savedTAIdsMultipeUpdate = [];
        this.multipleFormView.forEach((view) => {
          let ids = view.rawData?.responses ? view.rawData.responses.filter(resp => resp['indskr_therapeuticarea'] && !resp['indskr_product'] && !resp['indskr_position'] && !resp['indskr_positiongroup']).map(resp => resp['indskr_therapeuticarea']) : [];
          if(!_.isEmpty(ids)) savedTAIdsMultipeUpdate = _.cloneDeep(ids);
        })
        this._multipleFormValue?.forEach((formVal) => {
          for (let [, value] of Object.entries(formVal.currentValue)) {
              if (value && value['indskr_therapeuticarea'] && value['indskr_product'] == null && value['indskr_position'] == null && value['indskr_positiongroup'] == null) {
                savedTAIdsMultipeUpdate.push(value['indskr_therapeuticarea']);
            }
          }
        });
        savedTAIdsMultipeUpdate = [...new Set(savedTAIdsMultipeUpdate)];
        unSavedDatas.ta = this.therapeuticService.therapeuticAreas.filter(ta => !savedTAIdsMultipeUpdate.includes(ta.therapeuticareaId));
      } else {
        const savedTAIds = this.rawData?.responses ? this.rawData.responses.filter(resp => resp['indskr_therapeuticarea'] && !resp['indskr_product'] && !resp['indskr_position'] && !resp['indskr_positiongroup']).map(resp => resp['indskr_therapeuticarea']) : [];
      for (let [, value] of Object.entries(this._currentFormValue)) {
        for (let [, value1] of Object.entries(value)) {
          if (value1 && value1['indskr_therapeuticarea'] && value1['indskr_product'] == null && value1['indskr_position'] == null && value1['indskr_positiongroup'] == null) {
            savedTAIds.push(value1['indskr_therapeuticarea']);
          }
        }
      }
      unSavedDatas.ta = this.therapeuticService.therapeuticAreas.filter(ta => !savedTAIds.includes(ta.therapeuticareaId));
      }
    }
    else if (!section.Product && section.TherapetuicArea && section.Position && !section.PositionGroup) {
      //TA and Position
      let tApositionMap = [];
      if(this.isMultipleUpdate) {
        if(!_.isEmpty(this._multipleFormValue)) {
          this._multipleFormValue.forEach((formVal) => {
            for (let [, value] of Object.entries(formVal.currentValue)) {
              // for (let [, value1] of Object.entries(value)) {
                if (value && value['indskr_therapeuticarea'] && !value['indskr_product'] && value['indskr_position'] && !value['indskr_positiongroup']) {
                  const index = tApositionMap.findIndex(posiProd => posiProd.positionId == value['indskr_therapeuticarea']);
                  if (index > -1) {
                    tApositionMap[index].productIds.push(value['indskr_product']);
                  } else {
                    tApositionMap.push({
                      taId: value['indskr_therapeuticarea'],
                      positionIds: [value['indskr_product']]
                    })
                  }
                }
              // }
            }
          })
        }
        if(!_.isEmpty(this.multipleFormView)) {
          this.multipleFormView.forEach((view) => {
            if(view.rawData) {
              view.rawData.responses.filter(resp => resp['indskr_therapeuticarea'] && !resp['indskr_product'] && resp['indskr_position'] && !resp['indskr_positiongroup']).forEach(resp => {
                const index = tApositionMap.findIndex(posiProd => posiProd.positionId == resp['indskr_therapeuticarea']);
                if (index > -1) {
                  tApositionMap[index].productIds.push(resp['indskr_position']);
                } else {
                  tApositionMap.push({
                    taId: resp['indskr_therapeuticarea'],
                    positionIds: [resp['indskr_position']]
                  })
                }
              })
            }
          })
        }
      } else {
        if (this._currentFormValue) {
          for (let [, value] of Object.entries(this._currentFormValue)) {
            for (let [, value1] of Object.entries(value)) {
              if (value1 && value1['indskr_therapeuticarea'] && !value1['indskr_product'] && value1['indskr_position'] && !value1['indskr_positiongroup']) {
                const index = tApositionMap.findIndex(posiProd => posiProd.taId == value1['indskr_therapeuticarea']);
                if (index > -1) {
                  tApositionMap[index].positionIds.push(value1['indskr_position']);
                } else {
                  tApositionMap.push({
                    taId: value1['indskr_therapeuticarea'],
                    positionIds: [value1['indskr_position']]
                  })
                }
              }
            }
          }
        }
        if (this.rawData) {
          this.rawData.responses.filter(resp => resp['indskr_therapeuticarea'] && !resp['indskr_product'] && resp['indskr_position'] && !resp['indskr_positiongroup']).forEach(resp => {
            const index = tApositionMap.findIndex(posiProd => posiProd.taId == resp['indskr_therapeuticarea']);
            if (index > -1) {
              tApositionMap[index].positionIds.push(resp['indskr_position']);
            } else {
              tApositionMap.push({
                taId: resp['indskr_therapeuticarea'],
                positionIds: [resp['indskr_position']]
              })
            }
          })
        }
      }
      const savedTAIds = [];
      tApositionMap.forEach(tAposi => {
        if (this.masterData.positions.length == _.uniq(tAposi['positionIds']).length) {
          savedTAIds.push(tAposi.taId);
        }
      })
      unSavedDatas.ta = _.cloneDeep(this.therapeuticService.therapeuticAreas.filter(ta => !savedTAIds.includes(ta.therapeuticareaId)));
      if (unSavedDatas.ta.length > 0) {
        unSavedDatas.ta.forEach(ta => {
          const index = tApositionMap.findIndex(taPosi => ta.therapeuticareaId == taPosi.taId);
          let unSavedPositions = [];
          if (index > -1) {
            unSavedPositions = this.masterData.positions.filter(posi => !tApositionMap[index].positionIds.includes(posi.ID)).map(posi => posi.ID);
          } else {
            unSavedPositions = this.masterData.positions.map(posi => posi.ID);
          }
          ta['unSavedPositionIds'] = unSavedPositions;
        })
        unSavedDatas.positions = this.masterData.positions;
      }
    }
    else if (!section.Product && section.TherapetuicArea && !section.Position && section.PositionGroup) {
      //TA and PositionGroup
      let tApositionGroupMap = [];
      if(this.isMultipleUpdate) {
        if(!_.isEmpty(this._multipleFormValue)) {
          this._multipleFormValue.forEach((formVal) => {
            for (let [, value] of Object.entries(formVal.currentValue)) {
              // for (let [, value1] of Object.entries(value)) {
                if (value && value['indskr_therapeuticarea'] && !value['indskr_product'] && !value['indskr_position'] && value['indskr_positiongroup']) {
                  const index = tApositionGroupMap.findIndex(posiProd => posiProd.positionGroupId == value['indskr_therapeuticarea']);
                  if (index > -1) {
                    tApositionGroupMap[index].productIds.push(value['indskr_product']);
                  } else {
                    tApositionGroupMap.push({
                      taId: value['indskr_therapeuticarea'],
                      positionGroupIds: [value['indskr_product']]
                    })
                  }
                }
              // }
            }
          })
        }
        if(!_.isEmpty(this.multipleFormView)) {
          this.multipleFormView.forEach((view) => {
            if(view.rawData) {
              view.rawData.responses.filter(resp => resp['indskr_therapeuticarea'] && !resp['indskr_product'] && !resp['indskr_position'] && resp['indskr_positiongroup']).forEach(resp => {
                const index = tApositionGroupMap.findIndex(posiProd => posiProd.positionGroupId == resp['indskr_therapeuticarea']);
                if (index > -1) {
                  tApositionGroupMap[index].productIds.push(resp['indskr_positiongroup']);
                } else {
                  tApositionGroupMap.push({
                    taId: resp['indskr_therapeuticarea'],
                    positionGroupIds: [resp['indskr_positiongroup']]
                  })
                }
              })
            }
          })
        }
      } else {
        if (this._currentFormValue) {
          for (let [, value] of Object.entries(this._currentFormValue)) {
            for (let [, value1] of Object.entries(value)) {
              if (value1 && value1['indskr_therapeuticarea'] && !value1['indskr_product'] && !value1['indskr_position'] && value1['indskr_positiongroup']) {
                const index = tApositionGroupMap.findIndex(posiProd => posiProd.taId == value1['indskr_therapeuticarea']);
                if (index > -1) {
                  tApositionGroupMap[index].positionIds.push(value1['indskr_positiongroup']);
                } else {
                  tApositionGroupMap.push({
                    taId: value1['indskr_therapeuticarea'],
                    positionGroupIds: [value1['indskr_positiongroup']]
                  })
                }
              }
            }
          }
        }
        if (this.rawData) {
          this.rawData.responses.filter(resp => resp['indskr_therapeuticarea'] && !resp['indskr_product'] && !resp['indskr_position'] && resp['indskr_positiongroup']).forEach(resp => {
            const index = tApositionGroupMap.findIndex(posiProd => posiProd.taId == resp['indskr_therapeuticarea']);
            if (index > -1) {
              tApositionGroupMap[index].positionGrpIds.push(resp['indskr_positiongroup']);
            } else {
              tApositionGroupMap.push({
                taId: resp['indskr_therapeuticarea'],
                positionGroupIds: [resp['indskr_positiongroup']]
              })
            }
          })
        }
      }
      const savedTAIds = [];
      tApositionGroupMap.forEach(tAposi => {
        if (this.masterData.positionGroups.length == _.uniq(tAposi['positionGroupIds']).length) {
          savedTAIds.push(tAposi.taId);
        }
      })
      unSavedDatas.ta = _.cloneDeep(this.therapeuticService.therapeuticAreas.filter(ta => !savedTAIds.includes(ta.therapeuticareaId)));
      if (unSavedDatas.ta.length > 0) {
        unSavedDatas.ta.forEach(ta => {
          const index = tApositionGroupMap.findIndex(taPosi => ta.therapeuticareaId == taPosi.taId);
          let unSavedPositionGroups = [];
          if (index > -1) {
            unSavedPositionGroups = this.masterData.positionGroups.filter(posi => !tApositionGroupMap[index].positionGroupIds.includes(posi.positionGroupId)).map(posi => posi.positionGroupId);
          } else {
            unSavedPositionGroups = this.masterData.positionGroups.map(posi => posi.positionGroupId);
          }
          ta['unSavedPositionGroupIds'] = unSavedPositionGroups;
        })
        unSavedDatas.positionGroups = this.masterData.positionGroups;
      }
    }
    else if (section.Product && !section.TherapetuicArea && section.Position && !section.PositionGroup) {
      //Product and Position , Product only
      let positionProductMap = [];
      let productMapping = []
      if(this.isMultipleUpdate) {
        if(!_.isEmpty(this._multipleFormValue)) {
          this._multipleFormValue.forEach((formVal) => {
            for (let [, value] of Object.entries(formVal.currentValue)) {
              // for (let [, value1] of Object.entries(value)) {
                if (value && !value['indskr_therapeuticarea'] && !value['indskr_positiongroup']) {
                  if (value['indskr_product']) {
                    if (value['indskr_position']) {
                      const index = positionProductMap.findIndex(posiProd => posiProd.positionId == value['indskr_position']);
                      if (index > -1) {
                        positionProductMap[index].productIds.push(value['indskr_product']);
                      } else {
                        positionProductMap.push({
                          positionId: value['indskr_position'],
                          productIds: [value['indskr_product']]
                        })
                      }
                    } else {
                      //only product
                      const index = productMapping.findIndex(prod => prod == value['indskr_product']);
                      if (index == -1) {
                        productMapping.push(value['indskr_product'])
                      }
                    }
                  }
                }
              // }
            }
          })
        }
        if(!_.isEmpty(this.multipleFormView)) {
          this.multipleFormView.forEach((view) => {
            if(view.rawData) {
              view.rawData.responses.filter(resp => !resp['indskr_therapeuticarea'] && resp['indskr_product'] && resp['indskr_position'] && !resp['indskr_positiongroup']).forEach(resp => {
                const index = positionProductMap.findIndex(posiProd => posiProd.positionId == resp['indskr_position']);
                if (index > -1) {
                  positionProductMap[index].productIds.push(resp['indskr_product']);
                } else {
                  positionProductMap.push({
                    positionId: resp['indskr_position'],
                    productIds: [resp['indskr_product']]
                  })
                }
              })
            }
          })
        }
      } else {
        if (this._currentFormValue) {
          for (let [, value] of Object.entries(this._currentFormValue)) {
            for (let [, value1] of Object.entries(value)) {
              if (value1 && !value1['indskr_therapeuticarea'] && value1['indskr_product'] && value1['indskr_position'] && !value1['indskr_positiongroup']) {
                const index = positionProductMap.findIndex(posiProd => posiProd.positionId == value1['indskr_position']);
                if (index > -1) {
                  positionProductMap[index].productIds.push(value1['indskr_product']);
                } else {
                  positionProductMap.push({
                    positionId: value1['indskr_position'],
                    productIds: [value1['indskr_product']]
                  })
                }
              }
            }
          }
        }
        if (this.rawData) {
          this.rawData.responses.filter(resp => !resp['indskr_therapeuticarea'] && resp['indskr_product'] && resp['indskr_position'] && !resp['indskr_positiongroup']).forEach(resp => {
            const index = positionProductMap.findIndex(posiProd => posiProd.positionId == resp['indskr_position']);
            if (index > -1) {
              positionProductMap[index].productIds.push(resp['indskr_product']);
            } else {
              positionProductMap.push({
                positionId: resp['indskr_position'],
                productIds: [resp['indskr_product']]
              })
            }
          })
        }
      }
      const savedPositionIds = [];
      positionProductMap.forEach(prodPosi => {
        const products = this.customerAssessService.positionProductIdMap.filter(positionProductId => prodPosi.positionId == positionProductId['positionId']);
        if (products.length == _.uniq(prodPosi['productIds']).length) {
          savedPositionIds.push(prodPosi.positionId);
        }
      })
      unSavedDatas.positions = _.cloneDeep(this.masterData.positions.filter(position => !savedPositionIds.includes(position.ID)));
      if (unSavedDatas.positions.length > 0) {
        unSavedDatas.positions.forEach(position => {
          const index = positionProductMap.findIndex(prodPosi => prodPosi.positionId == position.ID);
          if (index > -1) {
            const unSavedProductIds = this.customerAssessService.positionProductIdMap
              .filter(positionProductId => position.ID == positionProductId['positionId'] && !positionProductMap[index].productIds.includes(positionProductId['productId']))
              .map(positionProductId => positionProductId['productId']);
            position['unSavedProductIds'] = unSavedProductIds;
          } else {
            position['unSavedProductIds'] = this.customerAssessService.positionProductIdMap
              .filter(positionProductId => position.ID == positionProductId['positionId'])
              .map(positionProductId => positionProductId['productId']);
          }
        });
        unSavedDatas.products = this.customerAssessService.products;
      }
    }
    else if (section.Product && !section.TherapetuicArea && !section.Position && section.PositionGroup) {
      //Product and PositionGroup , Product only
      let positionGroupProductMap = [];
      let productMapping = []
      if(this.isMultipleUpdate) {
        if(!_.isEmpty(this._multipleFormValue)) {
          this._multipleFormValue.forEach((formVal) => {
            for (let [, value] of Object.entries(formVal.currentValue)) {
              // for (let [, value1] of Object.entries(value)) {
                if (value && !value['indskr_therapeuticarea'] && !value['indskr_position']) {
                  if (value['indskr_product']) {
                    if (value['indskr_positiongroup']) {
                      const index = positionGroupProductMap.findIndex(posiProd => posiProd.positionGroupId == value['indskr_positiongroup']);
                      if (index > -1) {
                        positionGroupProductMap[index].productIds.push(value['indskr_product']);
                      } else {
                        positionGroupProductMap.push({
                          positionGroupId: value['indskr_positiongroup'],
                          productIds: [value['indskr_product']]
                        })
                      }
                    } else {
                      //only product
                      const index = productMapping.findIndex(prod => prod == value['indskr_product']);
                      if (index == -1) {
                        productMapping.push(value['indskr_product'])
                      }
                    }
                  }
                }
              // }
            }
          })
        }
        if(!_.isEmpty(this.multipleFormView)) {
          this.multipleFormView.forEach((view) => {
            if(view.rawData) {
              view.rawData.responses.filter(resp => !resp['indskr_therapeuticarea'] && resp['indskr_product'] && !resp['indskr_position'] && resp['indskr_positiongroup']).forEach(resp => {
                const index = positionGroupProductMap.findIndex(posiProd => posiProd.positionGroupId == resp['indskr_positiongroup']);
                if (index > -1) {
                  positionGroupProductMap[index].productIds.push(resp['indskr_product']);
                } else {
                  positionGroupProductMap.push({
                    positionGroupId: resp['indskr_positiongroup'],
                    productIds: [resp['indskr_product']]
                  })
                }
              })
            }
          })
        }
      } else {
        if (this._currentFormValue) {
          for (let [, value] of Object.entries(this._currentFormValue)) {
            for (let [, value1] of Object.entries(value)) {
              if (value1 && !value1['indskr_therapeuticarea'] && value1['indskr_product'] && !value1['indskr_position'] && value1['indskr_positiongroup']) {
                const index = positionGroupProductMap.findIndex(posiProd => posiProd.positionGroupId == value1['indskr_positiongroup']);
                if (index > -1) {
                  positionGroupProductMap[index].productIds.push(value1['indskr_product']);
                } else {
                  positionGroupProductMap.push({
                    positionGroupId: value1['indskr_positiongroup'],
                    productIds: [value1['indskr_product']]
                  })
                }
              }
            }
          }
        }
        if (this.rawData) {
          this.rawData.responses.filter(resp => !resp['indskr_therapeuticarea'] && resp['indskr_product'] && !resp['indskr_position'] && resp['indskr_positiongroup']).forEach(resp => {
            const index = positionGroupProductMap.findIndex(posiProd => posiProd.positionGroupId == resp['indskr_positiongroup']);
            if (index > -1) {
              positionGroupProductMap[index].productIds.push(resp['indskr_product']);
            } else {
              positionGroupProductMap.push({
                positionGroupId: resp['indskr_positiongroup'],
                productIds: [resp['indskr_product']]
              })
            }
          })
        }
      }
      const savedPositionGroupIds = [];
      positionGroupProductMap.forEach(prodPosi => {
        const products = this.customerAssessService.positionGroupProductIdMap.filter(positionGrpProductId => prodPosi.positionGroupId == positionGrpProductId['positionGroupId']);
        if (products.length == _.uniq(prodPosi['productIds']).length) {
          savedPositionGroupIds.push(prodPosi.positionGroupId);
        }
      })
      unSavedDatas.positionGroups = _.cloneDeep(this.masterData.positionGroups.filter(position => !savedPositionGroupIds.includes(position.positionGroupId)));
      if (unSavedDatas.positionGroups.length > 0) {
        unSavedDatas.positionGroups.forEach(position => {
          const index = positionGroupProductMap.findIndex(prodPosi => prodPosi.positionGroupId == position.positionGroupId);
          if (index > -1) {
            const unSavedProductIds = this.customerAssessService.positionGroupProductIdMap
              .filter(positionGrpProductId => position.positionGroupId == positionGrpProductId['positionGroupId'] && !positionGroupProductMap[index].productIds.includes(positionGrpProductId['productId']))
              .map(positionGrpProductId => positionGrpProductId['productId']);
            position['unSavedProductIds'] = unSavedProductIds;
          } else {
            position['unSavedProductIds'] = this.customerAssessService.positionGroupProductIdMap
              .filter(positionGrpProductId => position.positionGroupId == positionGrpProductId['positionGroupId'])
              .map(positionGrpProductId => positionGrpProductId['productId']);
          }
        });
        unSavedDatas.products = this.customerAssessService.products;
      }
    }
    else if (section.Product && !section.TherapetuicArea && !section.Position && !section.PositionGroup) {
      let productMapping = [];

      if (section.Product && !section.TherapetuicArea && !section.Position && !section.PositionGroup) {
        //only Product
        if (this._currentFormValue) {
          for (let [, value] of Object.entries(this._currentFormValue)) {
            for (let [, value1] of Object.entries(value)) {
              if (value1 && !value1['indskr_therapeuticarea'] && !value1['indskr_position'] && !value1['ndskr_positiongroup'] && value1['indskr_product']) {

                  const index = productMapping.findIndex(prod => prod == value1['indskr_product']);
                  if (index == -1) {
                    productMapping.push(value1['indskr_product'])
                  }
              }
            }
          }
        }
        if (this.rawData) {
          this.rawData.responses.filter(resp => !resp['indskr_therapeuticarea'] && !resp['indskr_position'] && !resp['ndskr_positiongroup'] && resp['indskr_product']).forEach(resp => {
            const index = productMapping.findIndex(prod => prod == resp['indskr_product']);
            if (index ==  -1) {
              productMapping.push(resp['indskr_product'])
            }
          })
        }

        const prodIDs = productMapping.join(',');
        unSavedDatas.products = this.customerAssessService.products.filter(prod => !prodIDs.includes(prod.ID));

      }


    }
    else if (section.Product && section.TherapetuicArea && !section.PositionGroup && !section.Position) {
      //TA and Product
      let taProductMap = [];
      if(this.isMultipleUpdate) {
        if(!_.isEmpty(this._multipleFormValue)) {
          this._multipleFormValue.forEach((formVal) => {
            for (let [, value] of Object.entries(formVal.currentValue)) {
              // for (let [, value1] of Object.entries(value)) {
                if (value && value['indskr_therapeuticarea'] && value['indskr_product'] && value['indskr_position'] && !value['indskr_positiongroup']) {
                  const index = taProductMap.findIndex(taProd => taProd.taId == value['indskr_therapeuticarea'] && taProd.positionId == value['indskr_position']);
                  if (index > -1) {
                    taProductMap[index].productIds.push(value['indskr_product']);
                  } else {
                    taProductMap.push({
                      taId: value['indskr_therapeuticarea'],
                      positionId: value['indskr_position'],
                      productIds: [value['indskr_product']]
                    })
                  }
                }
              // }
            }
          })
        }
        if(!_.isEmpty(this.multipleFormView)) {
          this.multipleFormView.forEach((view) => {
            if(view.rawData) {
              view.rawData.responses.filter(resp => resp['indskr_therapeuticarea'] && resp['indskr_product'] && resp['indskr_position'] && !resp['indskr_positiongroup']).forEach(resp => {
                const index = taProductMap.findIndex(taProd => taProd.taId == resp['indskr_therapeuticarea'] && taProd.positionId == resp['indskr_position']);
                if (index > -1) {
                  taProductMap[index].productIds.push(resp['indskr_product']);
                } else {
                  taProductMap.push({
                    taId: resp['indskr_therapeuticarea'],
                    positionId: resp['indskr_position'],
                    productIds: [resp['indskr_product']]
                  })
                }
              })
            }
          })
        }
      } else {
        if (this._currentFormValue) {
          for (let [, value] of Object.entries(this._currentFormValue)) {
            for (let [, value1] of Object.entries(value)) {
              if (value1 && value1['indskr_therapeuticarea'] && value1['indskr_product'] && value1['indskr_position'] && !value1['indskr_positiongroup']) {
                const index = taProductMap.findIndex(taProd => taProd.taId == value1['indskr_therapeuticarea'] && taProd.positionId == value1['indskr_position']);
                if (index > -1) {
                  taProductMap[index].productIds.push(value1['indskr_product']);
                } else {
                  taProductMap.push({
                    taId: value1['indskr_therapeuticarea'],
                    positionId: value1['indskr_position'],
                    productIds: [value1['indskr_product']]
                  })
                }
              }
            }
          }
        }
        if (this.rawData) {
          this.rawData.responses.filter(resp => resp['indskr_therapeuticarea'] && resp['indskr_product'] && resp['indskr_position'] && !resp['indskr_positiongroup']).forEach(resp => {
            const index = taProductMap.findIndex(taProd => taProd.taId == resp['indskr_therapeuticarea'] && taProd.positionId == resp['indskr_position']);
            if (index > -1) {
              taProductMap[index].productIds.push(resp['indskr_product']);
            } else {
              taProductMap.push({
                taId: resp['indskr_therapeuticarea'],
                positionId: resp['indskr_position'],
                productIds: [resp['indskr_product']]
              })
            }
          })
        }
      }
      const savedTaIds = [];
      taProductMap.forEach(taProdPosi => {
        const products = this.customerAssessService.tAProductsMap.find(taProd => taProd.therapeuticareaId == taProdPosi['taId'])['brands'];
        let unSavedPositions: string[] = this.customerAssessService.positionProductIdMap
          .filter(pp => products.map(prod => prod.ID).includes(pp['productId']) && pp['positionId'] != taProdPosi.positionId);
        // for given TA, if all Positions are captured
        if (!_.isEmpty(unSavedPositions)) {
          const otherTAProductMap = taProductMap.filter(taProdPosi1 => taProdPosi1.taId == taProdPosi.taId && taProdPosi1.positionId != taProdPosi.positionId && taProdPosi1.productIds.length == products.length);
          if (!_.isEmpty(otherTAProductMap)) {
            unSavedPositions = otherTAProductMap
              .map(taProdPosi1 => taProdPosi1['positionId'])
              .filter(positionId => unSavedPositions.includes(positionId));
          }
        }
        if (products.length == _.uniq(taProdPosi['productIds']).length && unSavedPositions.length == 0) {
          savedTaIds.push(taProdPosi.taId);
        }
      })
      unSavedDatas.ta = _.cloneDeep(this.customerAssessService.tAProductsMap.filter(ta => !savedTaIds.includes(ta.therapeuticareaId)));
      if (unSavedDatas.ta.length > 0) {
        unSavedDatas.ta.forEach(ta => {
          const productIds = this.customerAssessService.tAProductsMap.find(taProdId => taProdId.therapeuticareaId == ta.therapeuticareaId)['brands'].map(prod => prod.ID);
          let positionsIds = _.uniq(this.customerAssessService.positionProductIdMap
            .filter(posiProd => productIds.includes(posiProd['productId']) && this.masterData.positions.some(posi => posi.ID == posiProd['positionId']))
            .map(posiProd => posiProd['positionId']));
          positionsIds.forEach(positionId => {
            let unSavedProductIds = [];
            const index = taProductMap.findIndex(taProd => taProd.taId == ta.therapeuticareaId && taProd.positionId == positionId);
            if (index > -1) {
              //Get products not saved for TA
              unSavedProductIds = ta['brands']
                .filter(prod => !taProductMap[index].productIds.includes(prod.ID))
                .map(prod => prod.ID);
              //Check unSavedProductIds are mapped to given position
              unSavedProductIds = this.customerAssessService.positionProductIdMap
                .filter(posiProd => unSavedProductIds.includes(posiProd['productId']) && positionId == posiProd['positionId'])
                .map(posiProd => posiProd['productId']);;
            } else {
              unSavedProductIds = this.customerAssessService.positionProductIdMap
                .filter(posiProd => productIds.includes(posiProd['productId']) && posiProd['positionId'] == positionId)
                .map(posiProd => posiProd['productId']);
            }
            if (!_.isEmpty(unSavedProductIds)) {
              //If all products are saved for given position, no need to add Position and Positions
              const unSavedPositionId = {
                positionId: positionId,
                unSavedProductIds: _.uniq(unSavedProductIds)
              }
              if (!ta['unSavedPositionIds'])
                ta['unSavedPositionIds'] = []
              if (!ta['unSavedPositionIds'].some(posi => posi.positionId == positionId)) {
                ta['unSavedProductIds'] = unSavedPositionId.unSavedProductIds;
                ta['unSavedPositionIds'].push(unSavedPositionId);
              }
            }
          })
        })
        //If no products and position combination are there to save TA, filter TAs
        unSavedDatas.ta = unSavedDatas.ta.filter(ta => !_.isEmpty(ta['unSavedPositionIds']));
        unSavedDatas.products = this.customerAssessService.products;
      }
    }
    else if (section.Product && section.TherapetuicArea && !section.PositionGroup && section.Position) {
      //TA and Product and Position , TA and Product
      let taProductMap = [];
      if(this.isMultipleUpdate) {
        if(!_.isEmpty(this._multipleFormValue)) {
          this._multipleFormValue.forEach((formVal) => {
            for (let [, value] of Object.entries(formVal.currentValue)) {
              // for (let [, value1] of Object.entries(value)) {
                if (value && value['indskr_therapeuticarea'] && value['indskr_product'] && value['indskr_position'] && !value['indskr_positiongroup']) {
                  const index = taProductMap.findIndex(taProd => taProd.taId == value['indskr_therapeuticarea'] && taProd.positionId == value['indskr_position']);
                  if (index > -1) {
                    taProductMap[index].productIds.push(value['indskr_product']);
                  } else {
                    taProductMap.push({
                      taId: value['indskr_therapeuticarea'],
                      positionId: value['indskr_position'],
                      productIds: [value['indskr_product']]
                    })
                  }
                }
              // }
            }
          })
        }
        if(!_.isEmpty(this.multipleFormView)) {
          this.multipleFormView.forEach((view) => {
            if(view.rawData) {
              view.rawData.responses.filter(resp => resp['indskr_therapeuticarea'] && resp['indskr_product'] && resp['indskr_position'] && !resp['indskr_positiongroup']).forEach(resp => {
                const index = taProductMap.findIndex(taProd => taProd.taId == resp['indskr_therapeuticarea'] && taProd.positionId == resp['indskr_position']);
                if (index > -1) {
                  taProductMap[index].productIds.push(resp['indskr_product']);
                } else {
                  taProductMap.push({
                    taId: resp['indskr_therapeuticarea'],
                    positionId: resp['indskr_position'],
                    productIds: [resp['indskr_product']]
                  })
                }
              })
            }
          })
        }
      } else {
        if (this._currentFormValue) {
          for (let [, value] of Object.entries(this._currentFormValue)) {
            for (let [, value1] of Object.entries(value)) {
              if (value1 && value1['indskr_therapeuticarea'] && value1['indskr_product'] && value1['indskr_position'] && !value1['indskr_positiongroup']) {
                const index = taProductMap.findIndex(taProd => taProd.taId == value1['indskr_therapeuticarea'] && taProd.positionId == value1['indskr_position']);
                if (index > -1) {
                  taProductMap[index].productIds.push(value1['indskr_product']);
                } else {
                  taProductMap.push({
                    taId: value1['indskr_therapeuticarea'],
                    positionId: value1['indskr_position'],
                    productIds: [value1['indskr_product']]
                  })
                }
              }
            }
          }
        }
        if (this.rawData) {
          this.rawData.responses.filter(resp => resp['indskr_therapeuticarea'] && resp['indskr_product'] && resp['indskr_position'] && !resp['indskr_positiongroup']).forEach(resp => {
            const index = taProductMap.findIndex(taProd => taProd.taId == resp['indskr_therapeuticarea'] && taProd.positionId == resp['indskr_position']);
            if (index > -1) {
              taProductMap[index].productIds.push(resp['indskr_product']);
            } else {
              taProductMap.push({
                taId: resp['indskr_therapeuticarea'],
                positionId: resp['indskr_position'],
                productIds: [resp['indskr_product']]
              })
            }
          })
        }
      }
      const savedTaIds = [];
      taProductMap.forEach(taProdPosi => {
        const products = this.customerAssessService.tAProductsMap.find(taProd => taProd.therapeuticareaId == taProdPosi['taId'])['brands'];
        let unSavedPositions: string[] = this.customerAssessService.positionProductIdMap
          .filter(pp => products.map(prod => prod.ID).includes(pp['productId']) && pp['positionId'] != taProdPosi.positionId);
        // for given TA, if all Positions are captured
        if (!_.isEmpty(unSavedPositions)) {
          const otherTAProductMap = taProductMap.filter(taProdPosi1 => taProdPosi1.taId == taProdPosi.taId && taProdPosi1.positionId != taProdPosi.positionId && taProdPosi1.productIds.length == products.length);
          if (!_.isEmpty(otherTAProductMap)) {
            unSavedPositions = otherTAProductMap
              .map(taProdPosi1 => taProdPosi1['positionId'])
              .filter(positionId => unSavedPositions.includes(positionId));
          }
        }
        if (products.length == _.uniq(taProdPosi['productIds']).length && unSavedPositions.length == 0) {
          savedTaIds.push(taProdPosi.taId);
        }
      })
      unSavedDatas.ta = _.cloneDeep(this.customerAssessService.tAProductsMap.filter(ta => !savedTaIds.includes(ta.therapeuticareaId)));
      if (unSavedDatas.ta.length > 0) {
        unSavedDatas.ta.forEach(ta => {
          const productIds = this.customerAssessService.tAProductsMap.find(taProdId => taProdId.therapeuticareaId == ta.therapeuticareaId)['brands'].map(prod => prod.ID);
          let positionsIds = _.uniq(this.customerAssessService.positionProductIdMap
            .filter(posiProd => productIds.includes(posiProd['productId']) && this.masterData.positions.some(posi => posi.ID == posiProd['positionId']))
            .map(posiProd => posiProd['positionId']));
          positionsIds.forEach(positionId => {
            let unSavedProductIds = [];
            const index = taProductMap.findIndex(taProd => taProd.taId == ta.therapeuticareaId && taProd.positionId == positionId);
            if (index > -1) {
              //Get products not saved for TA
              unSavedProductIds = ta['brands']
                .filter(prod => !taProductMap[index].productIds.includes(prod.ID))
                .map(prod => prod.ID);
              //Check unSavedProductIds are mapped to given position
              unSavedProductIds = this.customerAssessService.positionProductIdMap
                .filter(posiProd => unSavedProductIds.includes(posiProd['productId']) && positionId == posiProd['positionId'])
                .map(posiProd => posiProd['productId']);;
            } else {
              unSavedProductIds = this.customerAssessService.positionProductIdMap
                .filter(posiProd => productIds.includes(posiProd['productId']) && posiProd['positionId'] == positionId)
                .map(posiProd => posiProd['productId']);
            }
            if (!_.isEmpty(unSavedProductIds)) {
              //If all products are saved for given position, no need to add Position and Positions
              const unSavedPositionId = {
                positionId: positionId,
                unSavedProductIds: _.uniq(unSavedProductIds)
              }
              if (!ta['unSavedPositionIds'])
                ta['unSavedPositionIds'] = []
              if (!ta['unSavedPositionIds'].some(posi => posi.positionId == positionId))
                ta['unSavedPositionIds'].push(unSavedPositionId);
            }
          })
        })
        //If no products and position combination are there to save TA, filter TAs
        unSavedDatas.ta = unSavedDatas.ta.filter(ta => !_.isEmpty(ta['unSavedPositionIds']));
        unSavedDatas.positions = this.masterData.positions;
        unSavedDatas.products = this.customerAssessService.products;
      }
    }
    else if (section.Product && section.TherapetuicArea && section.PositionGroup && !section.Position) {
      //TA and Product and PositionGroup , TA and Product
      let taProductMap = [];
      if(this.isMultipleUpdate) {
        if(!_.isEmpty(this._multipleFormValue)) {
          this._multipleFormValue.forEach((formVal) => {
            for (let [, value] of Object.entries(formVal.currentValue)) {
              // for (let [, value1] of Object.entries(value)) {
                if (value && value['indskr_therapeuticarea'] && value['indskr_product'] && !value['indskr_position'] && value['indskr_positiongroup']) {
                  const index = taProductMap.findIndex(taProd => taProd.taId == value['indskr_therapeuticarea'] && taProd.positionGroupId == value['indskr_positiongroup']);
                  if (index > -1) {
                    taProductMap[index].productIds.push(value['indskr_product']);
                  } else {
                    taProductMap.push({
                      taId: value['indskr_therapeuticarea'],
                      positionGroupId: value['indskr_positiongroup'],
                      productIds: [value['indskr_product']]
                    })
                  }
                }
              // }
            }
          })
        }
        if(!_.isEmpty(this.multipleFormView)) {
          this.multipleFormView.forEach((view) => {
            if(view.rawData) {
              view.rawData.responses.filter(resp => resp['indskr_therapeuticarea'] && resp['indskr_product'] && !resp['indskr_position'] && resp['indskr_positiongroup']).forEach(resp => {
                const index = taProductMap.findIndex(taProd => taProd.taId == resp['indskr_therapeuticarea'] && taProd.positionGroupId == resp['indskr_positiongroup']);
                if (index > -1) {
                  taProductMap[index].productIds.push(resp['indskr_product']);
                } else {
                  taProductMap.push({
                    taId: resp['indskr_therapeuticarea'],
                    positionGroupId: resp['indskr_positiongroup'],
                    productIds: [resp['indskr_product']]
                  })
                }
              })
            }
          })
        }
      } else {
        if (this._currentFormValue) {
          for (let [, value] of Object.entries(this._currentFormValue)) {
            for (let [, value1] of Object.entries(value)) {
              if (value1 && value1['indskr_therapeuticarea'] && value1['indskr_product'] && !value1['indskr_position'] && value1['indskr_positiongroup']) {
                const index = taProductMap.findIndex(taProd => taProd.taId == value1['indskr_therapeuticarea'] && taProd.positionGroupId == value1['indskr_positiongroup']);
                if (index > -1) {
                  taProductMap[index].productIds.push(value1['indskr_product']);
                } else {
                  taProductMap.push({
                    taId: value1['indskr_therapeuticarea'],
                    positionGroupId: value1['indskr_positiongroup'],
                    productIds: [value1['indskr_product']]
                  })
                }
              }
            }
          }
        }
        if (this.rawData) {
          this.rawData.responses.filter(resp => resp['indskr_therapeuticarea'] && resp['indskr_product'] && !resp['indskr_position'] && resp['indskr_positiongroup']).forEach(resp => {
            const index = taProductMap.findIndex(taProd => taProd.taId == resp['indskr_therapeuticarea'] && taProd.positionGroupId == resp['indskr_positiongroup']);
            if (index > -1) {
              taProductMap[index].productIds.push(resp['indskr_product']);
            } else {
              taProductMap.push({
                taId: resp['indskr_therapeuticarea'],
                positionGroupId: resp['indskr_positiongroup'],
                productIds: [resp['indskr_product']]
              })
            }
          })
        }
      }
      const savedTaIds = [];
      taProductMap.forEach(taProdPosi => {
        const products = this.customerAssessService.tAProductsMap.find(taProd => taProd.therapeuticareaId == taProdPosi['taId'])['brands'];
        let unSavedPositionGrps: string[] = this.customerAssessService.positionGroupProductIdMap
          .filter(pp => products.map(prod => prod.ID).includes(pp['productId']) && pp['positionGroupId'] != taProdPosi.positionGroupId);
        // for given TA, if all PositionGroups are captured
        if (!_.isEmpty(unSavedPositionGrps)) {
          const otherTAProductMap = taProductMap.filter(taProdPosi1 => taProdPosi1.taId == taProdPosi.taId && taProdPosi1.positionGroupId != taProdPosi.positionGroupId && taProdPosi1.productIds.length == products.length);
          if (!_.isEmpty(otherTAProductMap)) {
            unSavedPositionGrps = otherTAProductMap
              .map(taProdPosi1 => taProdPosi1['positionGroupId'])
              .filter(positionGroupId => unSavedPositionGrps.includes(positionGroupId));
          }
        }
        if (products.length == _.uniq(taProdPosi['productIds']).length && unSavedPositionGrps.length == 0) {
          savedTaIds.push(taProdPosi.taId);
        }
      })
      unSavedDatas.ta = _.cloneDeep(this.customerAssessService.tAProductsMap.filter(ta => !savedTaIds.includes(ta.therapeuticareaId)));
      if (unSavedDatas.ta.length > 0) {
        unSavedDatas.ta.forEach(ta => {
          const productIds = this.customerAssessService.tAProductsMap.find(taProdId => taProdId.therapeuticareaId == ta.therapeuticareaId)['brands'].map(prod => prod.ID);
          let positionGrpIds = _.uniq(this.customerAssessService.positionGroupProductIdMap
            .filter(posiProd => productIds.includes(posiProd['productId']) && this.masterData.positionGroups.some(posi => posi.positionGroupId == posiProd['positionGroupId']))
            .map(posiProd => posiProd['positionGroupId']));
          positionGrpIds.forEach(positionGroupId => {
            let unSavedProductIds = [];
            const index = taProductMap.findIndex(taProd => taProd.taId == ta.therapeuticareaId && taProd.positionGroupId == positionGroupId);
            if (index > -1) {
              //Get products not saved for TA
              unSavedProductIds = ta['brands']
                .filter(prod => !taProductMap[index].productIds.includes(prod.ID))
                .map(prod => prod.ID);
              //Check unSavedProductIds are mapped to given positionGrp
              unSavedProductIds = this.customerAssessService.positionGroupProductIdMap
                .filter(posiProd => unSavedProductIds.includes(posiProd['productId']) && positionGroupId == posiProd['positionGroupId'])
                .map(posiProd => posiProd['productId']);;
            } else {
              unSavedProductIds = this.customerAssessService.positionGroupProductIdMap
                .filter(posiProd => productIds.includes(posiProd['productId']) && posiProd['positionGroupId'] == positionGroupId)
                .map(posiProd => posiProd['productId']);
            }
            if (!_.isEmpty(unSavedProductIds)) {
              //If all products are saved for given position, no need to add Position and Positions
              const unSavedPositionGrpId = {
                positionGroupId: positionGroupId,
                unSavedProductIds: _.uniq(unSavedProductIds)
              }
              if (!ta['unSavedPositionGroupIds'])
                ta['unSavedPositionGroupIds'] = []
              if (!ta['unSavedPositionGroupIds'].some(posi => posi.positionGroupId == positionGroupId))
                ta['unSavedPositionGroupIds'].push(unSavedPositionGrpId);
            }
          })
        })
        //If no products and position combination are there to save TA, filter TAs
        unSavedDatas.ta = unSavedDatas.ta.filter(ta => !_.isEmpty(ta['unSavedPositionGroupIds']));
        unSavedDatas.positionGroups = this.masterData.positionGroups;
        unSavedDatas.products = this.customerAssessService.products;
      }
    }
    return unSavedDatas;
  }

  public async saveCustomerAssessment(entitydetails) {
    this.uiService.displayLoader();
    try {
      await this.customerAssessService.saveCustomerAssessment(entitydetails.entity, entitydetails.id, this._currentFormValue, this.selectedTemplate, this._deletedCurrentFormValue).then((data) => {
        if (!_.isEmpty(data)) {
          this.isFormDirty.emit(false);
          this.savedData.emit(data);
          this.rawData = _.cloneDeep(data);
          this.customerAssessService.isAssessmentDirty = false;
          this._currentFormValue = {};
          this._deletedCurrentFormValue = {};
          this._initViewData();
          // update assessment search data
          if (this.selectedTemplate.indskr_entity == 'contact') {
            this.customerAssessService.getAssessmentForSearch(false, EntityOptions.CONTACT);  
          } else {
            this.customerAssessService.getAssessmentForSearch(false, EntityOptions.ACCOUNT);  
          } 
        }
        this.uiService.dismissLoader();
      });
    } catch(error) {
      console.log("Error saving customer assessment ", error);
      this.uiService.dismissLoader();
    }
  }

  private validateForm() {
    let isValid: boolean = true;
    if (this.currentFormView) {
      //Check if all mandatory fields are filled
      for (let formView of this.currentFormView.filter(formView => formView.isSharedSection && formView.type === 'form-field' && formView.control?.Mandatory && !formView.view?.isDisabled)) {
        if (isValid) {
          if (this._currentFormValue && this._currentFormValue.hasOwnProperty(formView.id)) {
            isValid = true;
          } else if (this.rawData) {
            isValid = this.rawData.responses.some(resp => resp.indskr_attribute === formView.id);
          } else {
            isValid = false;
          }
        } else {
          break;
        }
      }
    }
    this.isFormDirty.emit(isValid);
  }

  ngOnDestroy() {
    this.$ngDestroy.next(true);
    this.$ngDestroy.complete();
    this.events.unsubscribe('saveMultipleAssess');
  }

  private initPageTitleForMultipleAssess(): void {
    let controls = [];
    controls.push({
        id: 'close',
        icon: "chevron-back-outline",
        isDisabled: false,
        align: 'left'
      },
      {
        id: 'save',
        imgSrc: 'assets/imgs/header_save.svg',
        name: this.translate.instant('SAVE'),
        isDisabled: !this.isMultipleAssessmentFormDirty || !this._isGridValid,
        align: 'right'
      }
    );

    this.multipleAssessPageTitle = {
      id: 'multipe-assess-title',
      title: this.translate.instant('ASSESSMENT'),
      controls: controls,
    };
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closePage();
        break;
      case 'save':
        if(!this.device.isOffline) {
          this.saveMultipleAssess();
        }
        // else {
        //   this.saveMultipleAssessOffline();
        // }
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
  }

  public async closePage(): Promise<void> {
    if(this.customerAssessService.isAssessmentDirty) {
      let isDiscard = await this.customerAssessService.discardChagesForAssess();
      if(!isDiscard) return;
    }
    this.selectedProcedures = this.selectedPositions = this.selectedProducts = this.selectedTAs = this.selectedPositionGroups = [];
    this.sectionControlToReset = [];
    this.events.publish('closeMultiAssessPage', this.contactService.contactInformation);
    this.navService.popWithPageTracking().then(()=>{
      this.footerService.initButtons(FooterViews.None);
    });
  }

  public async saveMultipleAssess() {
    this.uiService.displayLoader();
    await this.customerAssessService.saveMultiCustomerAssessment(this._multipleFormValue, this.selectedTemplate, this._deletedCurrentFormValue).then(async (data) => {
      if(!_.isEmpty(data)) {
        await Promise.all(data.map(async (value) => {
          let contactId = value.contactId || value.indskr_entityid;
          let idx = this.assessRawData.findIndex(raw => raw['indskr_entityid'] == contactId);
          // added modifiedon to display the latest response
          if (value.responses) {
            value.responses.map(r=>{
              r['modifiedon'] = new Date();
            });
          }
          if(idx>-1) {
            const prevResponses: any[] = _.cloneDeep(this.assessRawData[idx].responses);
            const newResponses: any[] = value?.responses || [];
            const combinedResponses = [...prevResponses, ...newResponses];
            this.assessRawData[idx] = _.cloneDeep(value);
            this.assessRawData[idx].responses = combinedResponses;
          }
          else this.assessRawData.push(_.cloneDeep(value));
          this.customerAssessService.isAssessmentDirty = false;
          this._deletedCurrentFormValue = {};
          if(this.contactService.contactInformation && this.contactService.contactInformation.ID == value.contactId) this._initViewData();
        }))
        .then(() => {
          // update assessment search data
          if (this.selectedTemplate.indskr_entity == 'contact') {
            this.customerAssessService.getAssessmentForSearch(false, EntityOptions.CONTACT);  
          } else {
            this.customerAssessService.getAssessmentForSearch(false, EntityOptions.ACCOUNT);  
          } 
          this._multipleFormValue = [];
          this.selectedProcedures = [];
          this.selectedPositions = [];
          this.selectedPositionGroups = [];
          this.selectedProducts = [];
          this.selectedTAs = [];
          this.sectionControlToReset = [];
          this.isMultipleAssessmentFormDirty = false;
          this._isGridValid = true;
          this.initGridForMultipleAssess(this.selectedCustomers);
          this.initPageTitleForMultipleAssess();
          this.footerService.initButtons(FooterViews.MULTIPLE_ASSESS);
          this.uiService.dismissLoader();
        })
      }
    })
    // await Promise.all(this._multipleFormValue.map(async (value) => {
    //   await this.customerAssessService.saveCustomerAssessment('contact', value.contactId, value.currentValue, this.selectedTemplate, this._deletedCurrentFormValue).then((data) => {
    //     if (data) {
    //       let idx = this.assessRawData.findIndex(raw => raw['indskr_entityid'] == value.contactId);
    //       if(idx>-1) this.assessRawData[idx] = _.cloneDeep(data);
    //       else this.assessRawData.push(_.cloneDeep(data));
    //       this.customerAssessService.isAssessmentDirty = false;
    //       value.currentValue = {};
    //       this._deletedCurrentFormValue = {};
    //       this._initViewData();
    //     }
    //   })
    // }))
    // .then(()=> {
    //     this.uiService.dismissLoader();
    //     this._multipleFormValue = [];
    //     this.selectedProcedures = [];
    //     this.selectedPositions = [];
    //     this.selectedPositionGroups = [];
    //     this.selectedProducts = [];
    //     this.selectedTAs = [];
    //     this.sectionControlToReset = [];
    //     this.isMultipleAssessmentFormDirty = false;
    //     this._isGridValid = true;
    //     this.initGridForMultipleAssess(this.selectedCustomers);
    //     this.initPageTitleForMultipleAssess();
    //     this.footerService.initButtons(FooterViews.MULTIPLE_ASSESS);
    // });
  }

  // public async saveMultipleAssessOffline() {
  //   this.uiService.displayLoader();
  //   try {
  //     await Promise.all(this.selectedCustomers.map(async(customer)=>{
  //       const formValueListById = this._multipleFormValue.filter( v => v.contactId == customer.ID);
  //       if(formValueListById) {
  //         await this.customerAssessService.saveMultipleAssessOffline('contact', customer.ID, formValueListById, this.selectedTemplate, this._deletedCurrentFormValue).then((data) => {
  //           if (data) {
  //             let idx = this.assessRawData.findIndex(raw => raw['indskr_entityid'] == customer.ID);
  //             if(idx>-1) this.assessRawData[idx] = _.cloneDeep(data);
  //             else this.assessRawData.push(_.cloneDeep(data));
  //             this.customerAssessService.isAssessmentDirty = false;
  //             this._deletedCurrentFormValue = {};
  //             this._initViewData();
  //           }
  //         })
  //       }
  //     }))
  //      .then(()=> {
  //         this.uiService.dismissLoader();
  //         this._multipleFormValue = [];
  //         this.selectedProcedures = [];
  //         this.selectedPositions = [];
  //         this.selectedPositionGroups = [];
  //         this.selectedProducts = [];
  //         this.selectedTAs = [];
  //         this.sectionControlToReset = [];
  //         this.isMultipleAssessmentFormDirty = false;
  //         this._isGridValid = true;
  //         this.initGridForMultipleAssess(this.selectedCustomers);
  //         this.initPageTitleForMultipleAssess();
  //         this.footerService.initButtons(FooterViews.MULTIPLE_ASSESS);
  //     });
  //   } catch(error) {
  //     console.log("Error saving multiple assess offline data", error);
  //   }
  // }

  private async _initMultipleAssessData() {
    // Get the Template
    if(this.navParams.get('template')) this.templates = this.navParams.get('template');
    if(this.templates.length == 1) this.selectedTemplate = _.cloneDeep(this.templates[0]);
    // if(this.navParams.get('isAssessmentLoading')) this.isAssessmentLoading = this.navParams.get('isAssessmentLoading');
    // if(this.navParams.get('assessRawData')) this.assessRawData = this.navParams.get('assessRawData');
    this.selectedCustomers = this.navParams.get('selectedCustomers');
    if(this.isMultipleUpdate && this.selectedCustomers && this.selectedCustomers.length>0 && this.templates.length == 1) {
      this.initMultipleCustomerAssessData();
      this.initGridForMultipleAssess(this.selectedCustomers);
    }
    this.initPageTitleForMultipleAssess();
    this.footerService.initButtons(FooterViews.MULTIPLE_ASSESS);
  }

  private initMultipleCustomerAssessData() {
    if(this.navParams && this.navParams.get('from')=='ContactPageComponent') {
      if(this.templates.length == 1) this.selectedTemplate = _.cloneDeep(this.templates[0]);
      else this.isAssessmentLoading = true;
    }  else {
      // Fetch the rawData for Account here
    }
  }


  private initGridForMultipleAssess(data: Contact[]) {
    this.assessGrid = [];
    this.multipleFormView = [];
    this._isGridValid = true;
    // First Column - Selected Customers & make a configured form for each customer
    this.assessGrid = [{
      columnId: 'first_column',
      columnSubId: 'first_column',
      columnType: ColumnType.CONTACT_LIST,
      columnItems: [
        {
          columnItemId: 'first_column_item_first',
          isHeaderFirst: true,
          isHeaderSecond: false,
          isHeaderThird: false,
          isInputField: false,
          inputFieldType: InputFieldType.NONE,
          endSlotItemType: 'none',
          columnItemLabel: ' ',
          columnItemSecondaryLabel: '',
          isRequired: false,
          isMoreHeight: false,
        },{
          columnItemId: 'first_column_item_second',
          isHeaderFirst: false,
          isHeaderSecond: true,
          isHeaderThird: false,
          isInputField: false,
          inputFieldType: InputFieldType.NONE,
          endSlotItemType: 'none',
          columnItemLabel: this.selectedTemplate?.indskr_name,
          columnItemSecondaryLabel: '',
          isRequired: false,
          isMoreHeight: false,
        },{
          columnItemId: 'first_column_item_third',
          isHeaderFirst: false,
          isHeaderSecond: false,
          isHeaderThird: true,
          isInputField: false,
          inputFieldType: InputFieldType.NONE,
          endSlotItemType: 'none',
          columnItemLabel: this.translate.instant('CONTACTS'),
          columnItemSecondaryLabel: '',
          isRequired: false,
          isMoreHeight: false,
        }
      ]
    }]
    data.forEach(con => {
      this.assessGrid.find(a => a['columnId']=='first_column').columnItems.push(
        {
          columnItemId: con.ID,
          isHeaderFirst: false,
          isHeaderSecond: false,
          isHeaderThird: false,
          isInputField: false,
          inputFieldType: InputFieldType.NONE,
          endSlotItemType: 'none',
          columnItemLabel: con.fullname,
          columnItemSecondaryLabel: '',
          isRequired: false,
          isMoreHeight: false,
        }
      )
      if(this.assessRawData && this.assessRawData.length>0) {
        this.rawData = this.assessRawData.find(raw => raw['indskr_entityid'] == con.ID);
      } else this.rawData = undefined;
      this.specialty = this.contactService.getContactByID(con.ID)?.primarySpecialty;
      this.masterData.procedures = [];
      if (this.specialty && this.customerAssessService.specialtyProceduresMap) {
        this.masterData.procedures = this.customerAssessService.specialtyProceduresMap.has(this.specialty.id)
        ? this.customerAssessService.specialtyProceduresMap.get(this.specialty.id)
        : [];
      }
      this._initViewData(null, undefined, con.ID);
      this.multipleFormView.push({
        contactId: con.ID,
        rawData: this.rawData,
        currentFormView: this.currentFormView
      })

    })
    // Second Column and after
    let gridColumnIdx = 1;
    this.selectedTemplate.indskr_metadata.Sections.forEach((section) => {
      let headerSecondForSection = [];
      let buttons = [];
      if(section.TherapetuicArea || section.Product || section.Position || section.PositionGroup || section.Procedure){
        if(section.Procedure) {
          let columnLabel = this.translate.instant('PROCEDURES');
          this._pushColumnToGrid(gridColumnIdx, section.SectionProperties.GUID, 'Procedure', columnLabel, section, data);
          gridColumnIdx++;
        }
        // if(section.TherapetuicArea) {
        //   let columnLabel = this.translate.instant('THERAPEUTIC_AREAS');
        //   this._pushColumnToGrid(gridColumnIdx, section.SectionProperties.GUID, 'TherapetuicArea', columnLabel, section, data);
        //   gridColumnIdx++;
        // }
        // if(section.Position || section.Product) {
        //   let columnLabel = this.translate.instant('POSITIONS');
        //   this._pushColumnToGrid(gridColumnIdx, section.SectionProperties.GUID, 'Position', columnLabel, section, data);
        //   gridColumnIdx++;
        // }
        // if(section.Product) {
        //   let columnLabel = this.translate.instant('PRODUCTS');
        //   this._pushColumnToGrid(gridColumnIdx, section.SectionProperties.GUID, 'Product', columnLabel, section, data);
        //   gridColumnIdx++;
        // }

        // If there are selected values for the general controls in the section, get the header for the second
        this.multipleFormView.forEach((view) => {
          this.rawData = view.rawData;
          this.specialty = this.contactService.getContactByID(view.contactId)?.primarySpecialty;
          // if(section.Procedure) this._updateSectionGeneralValueFromResponse(section, 'Procedure', view.contactId);
          if(section.TherapetuicArea) this._updateSectionGeneralValueFromResponse(section, 'TherapetuicArea', view.contactId);
          // if(section.Position || section.Product) this._updateSectionGeneralValueFromResponse(section, 'Position', view.contactId);
          if(section.Position) this._updateSectionGeneralValueFromResponse(section, 'Position', view.contactId);
          if(section.PositionGroup) this._updateSectionGeneralValueFromResponse(section, 'PositionGroup', view.contactId);
          if(section.Product) this._updateSectionGeneralValueFromResponse(section, 'Product', view.contactId);
          this.masterData.procedures = [];
          if (this.specialty) this.masterData.procedures = this.customerAssessService.specialtyProceduresMap.has(this.specialty.id) ? this.customerAssessService.specialtyProceduresMap.get(this.specialty.id) : [];
          let headerSecond = this._getHeaderSecondForSectionControl(section, view.contactId);
          let idx = -1;
          if(!_.isEmpty(headerSecond)) {
            headerSecond.forEach((header) => {
              if(!_.isEmpty(headerSecondForSection)) {
                idx = headerSecondForSection.findIndex((hs) => hs.id == header.id);
                if(idx>-1) headerSecondForSection[idx] = header;
                else headerSecondForSection.push(header);
              } else headerSecondForSection.push(header);
            })
          }
        })
        buttons.push({
          id: 'add_'.concat(section.SectionProperties.GUID),
          isVisible: (section.Product || section.Position || section.PositionGroup || section.TherapetuicArea),
          isDisabled: this.isSectionDisabled(section), // add a logic for the multi update (this.rawdata, this._currentFormView)
          iconClass: 'pluse-icon'
        })
        if(_.isEmpty(headerSecondForSection) && !section.Procedure) {
          this._pushEmptyColumnToGrid(gridColumnIdx, section.SectionProperties.GUID, 'Empty', section, data, buttons);
          gridColumnIdx++;
        }
      }
      let numberToIterate = 1;
      if(section.TherapetuicArea || section.Product || section.Position || section.PositionGroup || section.Procedure) numberToIterate = headerSecondForSection.length;
      //loop for the subsection controls
      for(let i=0; i<=(numberToIterate-1); i++) {
        let headerSecondLabel, headerSecondSecondaryLabel, headerSecondId;
        if(!_.isEmpty(headerSecondForSection)) {
          headerSecondLabel = headerSecondForSection[i].view.title;
          headerSecondSecondaryLabel =headerSecondForSection[i].view.secondaryText;
          headerSecondId = headerSecondForSection[i].view.id;
        }
        section.Controls = section.Controls.filter(control => !this._isNotValidToEdit(control));
        section.Controls.forEach((control) => {
          const columnLabel = this._getLabeltext(control);
          this.assessGrid[gridColumnIdx] = {
            columnId: section.SectionProperties.GUID,
            columnSubId: control.Attribute.GUID,
            columnType: (section.TherapetuicArea || section.Product || section.Position || section.PositionGroup || section.Procedure) ? ColumnType.COLUMN_SECTION : ColumnType.COLUMN_GENERAL,
            sectionIdx: i,
            columnItems: [
              {
                columnItemId: section.SectionProperties.GUID + '_' + control.Attribute.GUID + '_header_first',
                isHeaderFirst: true,
                isHeaderSecond: false,
                isHeaderThird: false,
                isInputField: false,
                inputFieldType: InputFieldType.NONE,
                endSlotItemType: 'none',
                columnItemLabel: this.assessGrid[gridColumnIdx-1].columnId != section.SectionProperties.GUID ? this.customerAssessService.getSectionHeader(section.SectionProperties) : '',
                columnItemSecondaryLabel: '',
                isRequired: false,
                isMoreHeight: false,
                controls: this.assessGrid[gridColumnIdx-1].columnId != section.SectionProperties.GUID ? buttons : undefined
              },{
                columnItemId: section.SectionProperties.GUID + '_' + control.Attribute.GUID + '_header_second',
                isHeaderFirst: false,
                isHeaderSecond: true,
                isHeaderThird: false,
                isInputField: false,
                inputFieldType: InputFieldType.NONE,
                endSlotItemType: 'none',
                columnItemLabel: headerSecondLabel,
                columnItemSecondaryLabel: headerSecondSecondaryLabel,
                isRequired: false,
                isMoreHeight: false,
              },{
                columnItemId: section.SectionProperties.GUID + '_' + control.Attribute.GUID + '_header_third',
                isHeaderFirst: false,
                isHeaderSecond: false,
                isHeaderThird: true,
                isInputField: false,
                inputFieldType: InputFieldType.NONE,
                endSlotItemType: columnLabel ? 'button' : 'none',
                columnItemLabel: columnLabel,
                columnItemSecondaryLabel: '',
                isRequired: control.Mandatory,
                isMoreHeight: false,
                controls: columnLabel ? [
                  {
                    id: 'add_'.concat(section.SectionProperties.GUID),
                    isApplyAll: true,
                    sectionId: section.SectionProperties.GUID,
                    isDisabled: this._validateAttributeAccess(control) === Access.READ_ONLY,
                    iconClass: 'indegene-copy_white',
                    tooltip: this.translate.instant('APPLY_TO_ALL'),
                    eventHandler: (event:any, eventName:string, item) => this._handleGridFieldEvent(control.Attribute.GUID, section.SectionProperties.GUID, undefined, event, eventName, headerSecondId, item, true),
                  },
                ] : undefined,
              }
            ]
          }
          data.forEach((con) => {
            this.rawData = this.multipleFormView.find((view) => view.contactId == con.ID).rawData;
            this.currentFormView = this.multipleFormView.find((view) => view.contactId == con.ID).currentFormView;
            if(section.Procedure) {
              this.masterData.procedures = [];
              this.specialty = this.contactService.getContactByID(con.ID)?.primarySpecialty;
              if (this.specialty) this.masterData.procedures = this.customerAssessService.specialtyProceduresMap.has(this.specialty.id) ? this.customerAssessService.specialtyProceduresMap.get(this.specialty.id) : [];
            }
            let itemLabel = this._getInputTextForFormField(control, section, headerSecondForSection[i]?.id, true, con.ID, headerSecondId);
            let isDisabled = false;
            isDisabled = this._isNotValidToEdit(control) || this._validateAttributeAccess(control) == Access.READ_ONLY;
            this._isGridValid = control.Mandatory ? !_.isEmpty(itemLabel) && this._isGridValid : this._isGridValid;
            this.assessGrid[gridColumnIdx].columnItems.push({
              columnItemId: section.SectionProperties.GUID + '_' + control.Attribute.GUID + '_' + con.ID,
              isHeaderFirst: false,
              isHeaderSecond: false,
              isHeaderThird: false,
              isInputField: true,
              inputFieldType: this._getFieldType(control),
              endSlotItemType: 'arrow',
              columnItemLabel: itemLabel,
              columnItemSecondaryLabel: '',
              placeholder: this.translate.instant('SELECT') + ' ' + this._getLabeltext(control),
              arrowType: this.getArrowType(control),
              isMoreHeight: false,
              isDisabled: isDisabled,
              isRequired: control.Mandatory,
              eventHandler:(id:string, event:any, eventName:string, item)=>this._handleGridFieldEvent(control.Attribute.GUID, section.SectionProperties.GUID, con.ID, event, eventName, headerSecondId, item),
            })
          });

          headerSecondLabel = '';
          headerSecondSecondaryLabel = '';
          gridColumnIdx++;
        })
      }
    })
  }

  private _pushColumnToGrid(gridColumnIdx, columnID, columnSubID, columnLabel, section, data) {
    let isDisabled = this.isSectionDisabled(section);
    this.assessGrid[gridColumnIdx] = {
      columnId: columnID,
      columnSubId: columnSubID,
      columnType: ColumnType.COLUMN_SECTION_GENERAL,
      columnItems: [
        {
          columnItemId: columnID + '_' + columnSubID + '_header_first',
          isHeaderFirst: true,
          isHeaderSecond: false,
          isHeaderThird: false,
          isInputField: false,
          inputFieldType: InputFieldType.NONE,
          endSlotItemType: 'none',
          columnItemLabel: this.assessGrid[gridColumnIdx-1].columnId != columnID ? this.customerAssessService.getSectionHeader(section.SectionProperties) : '',
          columnItemSecondaryLabel: '',
          isRequired: false,
          isMoreHeight: false,
        },{
          columnItemId: columnID + '_' + columnSubID + '_header_second',
          isHeaderFirst: false,
          isHeaderSecond: true,
          isHeaderThird: false,
          isInputField: false,
          inputFieldType: InputFieldType.NONE,
          endSlotItemType: 'none',
          columnItemLabel: ' ',
          columnItemSecondaryLabel: '',
          isRequired: false,
          isMoreHeight: false,
        },{
          columnItemId: section.SectionProperties.GUID + '_' + columnSubID + '_header_third',
          isHeaderFirst: false,
          isHeaderSecond: false,
          isHeaderThird: true,
          isInputField: false,
          inputFieldType: InputFieldType.NONE,
          endSlotItemType: columnLabel ? 'button' : 'none',
          columnItemLabel: columnLabel,
          columnItemSecondaryLabel: '',
          isRequired: false,
          isMoreHeight: false,
          controls: columnLabel ? [
            {
              id: 'add_'.concat(section.SectionProperties.GUID),
              isApplyAll: true,
              sectionId: section.SectionProperties.GUID,
              isDisabled,
              iconClass: 'indegene-copy_white',
              tooltip: this.translate.instant('APPLY_TO_ALL'),
              eventHandler: (event:any, eventName:string, item)=>this._handleSectionEvent(item.columnItemId, undefined, columnSubID, section, event, eventName, item, true),
            },
          ] : undefined,
        }]
    }
    data.forEach((con) => {
      this.rawData = this.multipleFormView.find((view) => view.contactId == con.ID).rawData;
      if(section.Procedure) {
        this.masterData.procedures = [];
        this.specialty = this.contactService.getContactByID(con.ID)?.primarySpecialty;
        if (this.specialty) this.masterData.procedures = this.customerAssessService.specialtyProceduresMap.has(this.specialty.id) ? this.customerAssessService.specialtyProceduresMap.get(this.specialty.id) : [];
      }
      let label = this._getLablesForSection(section, columnSubID, con.ID);
      isDisabled = this.isSectionDisabled(section);
      if(!_.isEmpty(this.isDisabledSectionGeneral)) {
        let idx = this.isDisabledSectionGeneral.findIndex((value) => value.contactId == con.ID && value.sectionId == section.SectionProperties.GUID && value.section_general == columnSubID);
        if(idx>-1) isDisabled = true;
      }
      this.assessGrid[gridColumnIdx].columnItems.push({
        columnItemId: section.SectionProperties.GUID + '_' + columnSubID + '_' + con.ID,
        isHeaderFirst: false,
        isHeaderSecond: false,
        isHeaderThird: false,
        isInputField: true,
        inputFieldType: InputFieldType.DROP_DOWN,
        endSlotItemType: 'arrow',
        columnItemLabel: label,
        columnItemSecondaryLabel: '',
        placeholder: this.translate.instant('SELECT') + columnLabel,
        arrowType: 'chevron-down-outline',
        isMoreHeight: false,
        isRequired: false,
        isDisabled,
        eventHandler:(id:string, event:any, eventName:string)=>this._handleSectionEvent(id, con.ID, columnSubID, section, event, eventName),
      })
    })
  }

  private _pushEmptyColumnToGrid(gridColumnIdx, columnID, columnSubID, section, data, controls) {
    this.assessGrid[gridColumnIdx] = {
      columnId: columnID,
      columnSubId: columnSubID,
      columnType: ColumnType.COLUMN_SECTION_EMPTY,
      columnItems: [
        {
          columnItemId: columnID + '_' + columnSubID + '_header_first',
          isHeaderFirst: true,
          isHeaderSecond: false,
          isHeaderThird: false,
          isInputField: false,
          inputFieldType: InputFieldType.NONE,
          endSlotItemType: 'none',
          columnItemLabel: this.assessGrid[gridColumnIdx-1].columnId != columnID ? this.customerAssessService.getSectionHeader(section.SectionProperties) : '',
          columnItemSecondaryLabel: '',
          isMoreHeight: false,
          isRequired: false,
          controls: controls
        },{
          columnItemId: columnID + '_' + columnSubID + '_header_second',
          isHeaderFirst: false,
          isHeaderSecond: true,
          isHeaderThird: false,
          isInputField: false,
          inputFieldType: InputFieldType.NONE,
          endSlotItemType: 'none',
          columnItemLabel: '',
          columnItemSecondaryLabel: '',
          isRequired: false,
          isMoreHeight: false,
        },{
          columnItemId: section.SectionProperties.GUID + '_' + columnSubID + '_header_third',
          isHeaderFirst: false,
          isHeaderSecond: false,
          isHeaderThird: true,
          isInputField: false,
          inputFieldType: InputFieldType.NONE,
          endSlotItemType: 'none',
          columnItemLabel: '',
          columnItemSecondaryLabel: '',
          isRequired: false,
          isMoreHeight: false,
        }]
    }
    data.forEach((con) => {
      this.assessGrid[gridColumnIdx].columnItems.push({
        columnItemId: section.SectionProperties.GUID + '_' + columnSubID + '_' + con.ID,
        isHeaderFirst: false,
        isHeaderSecond: false,
        isHeaderThird: false,
        isInputField: true,
        inputFieldType: InputFieldType.NONE,
        endSlotItemType: 'arrow',
        columnItemLabel: '',
        columnItemSecondaryLabel: '',
        placeholder: '',
        arrowType: '',
        isRequired: false,
        isMoreHeight: false,
        isDisabled: true,
      })
    })
  }

  private _enableDisableControlsForSection(section, contactID, headerSecondId): boolean {
    let isDisabled = false;
    if(section.Procedure) {
      let prod = this.selectedProcedures.find((proc) => proc.contactId == contactID && proc.sectionId  == section.SectionProperties.GUID && proc.section_general == 'Procedure')?.selected;
      // if(prod) headerSecondId.includes(prod.id)
    }
    if(!_.isEmpty(this._multipleFormValue)) {

    } else {
      let idx = this.currentFormView.findIndex((view) => view.id.includes(section.SectionProperties.GUID + '_empty-continer'));
      if(idx && idx>-1) isDisabled = true;
    }
    return isDisabled;
  }

  private _getFieldType(control: Control): InputFieldType {
    switch (control.Type) {
      case AssessmentAttributeType.TEXT:
      case AssessmentAttributeType.MEMO:
        return InputFieldType.TEXT;
      case AssessmentAttributeType.NUMERIC:
      case AssessmentAttributeType.RANGE:
        return InputFieldType.NUMERIC;
      case AssessmentAttributeType.TIMESTAMP:
        return InputFieldType.DATE_TIME;
      case AssessmentAttributeType.CHOICE:
      case AssessmentAttributeType.FLAG:
        return InputFieldType.DROP_DOWN;
      default:
        console.error("unhanled type");
        return InputFieldType.NONE;
    }
  }

  private getArrowType(control: Control): string {
    switch (control.Type) {
      case AssessmentAttributeType.TEXT:
      case AssessmentAttributeType.MEMO:
      case AssessmentAttributeType.NUMERIC:
      case AssessmentAttributeType.RANGE:
        return 'chevron-back-outline';
      case AssessmentAttributeType.TIMESTAMP:
      case AssessmentAttributeType.CHOICE:
      case AssessmentAttributeType.FLAG:
        return 'chevron-down-outline';
      default:
        console.error("unhanled type");
        return '';
    }
  }

  private checkMultipleUpdateFormDirty() {
    if(!_.isEmpty(this._multipleFormValue)) this.isMultipleAssessmentFormDirty = true;
    this._cd.detectChanges();
    this.initPageTitleForMultipleAssess();
    this._cd.detectChanges();
    if(this._isGridValid && this.isMultipleAssessmentFormDirty) this.footerService.enableButtons(['saveMultipleAssess'], true);
  }

  public onInputTextClick(event, item) {
    if(item.isDisabled) return;
    if(item.eventHandler) {
      item.eventHandler(item.columnItemId, event, 'input_click_event', item);
    } else return;
  }

  public handleInputValue(event, item) {
    if(item.isDisabled) return;
    if(item.eventHandler) {
      item.eventHandler(item.columnItemId, event, 'input_value_confirm');
    } else return;
  }

  private _setCurrentFormViewAndRawData(contactId: string) {
    if(!_.isEmpty(this.multipleFormView)) {
      const currentFormView = this.multipleFormView.find(view => view.contactId == contactId);
      this.currentFormView = currentFormView?.currentFormView;
      this.rawData = currentFormView?.rawData;
    }
  }

  private _handleGridFieldEvent(controlId: string, sectionId: string, contactId: string | string[], event, eventName, sectionGeneralValue?, item?, isApplyAll = false,) {
    const targetControl: Control = this.getTargetControl(controlId, sectionId);
    if (contactId === undefined && isApplyAll) {
      contactId = this.selectedCustomers?.map(c => c.ID) || [];
    } else if (contactId && !isApplyAll) {
      this.rawData = this.multipleFormView.find((view) => view.contactId == contactId).rawData;
    }
    if (targetControl) {
      this.currentEventId = event?.target?.id;
      switch (targetControl.Type) {
        case AssessmentAttributeType.TEXT:
        case AssessmentAttributeType.MEMO:
        case AssessmentAttributeType.NUMERIC:
        case AssessmentAttributeType.RANGE:
          this._handleInlineInputFormField(controlId, sectionId, event, eventName, targetControl, contactId, sectionGeneralValue);
          this._isClicked[targetControl.Attribute.GUID] = true;
          break;
        case AssessmentAttributeType.TIMESTAMP:
          this._handleDateTimeFormFieldForGrid(controlId, sectionId, event, eventName, targetControl, contactId, sectionGeneralValue, item);
          this._isClicked[targetControl.Attribute.GUID] = true;
          break;
        case AssessmentAttributeType.CHOICE:
          this._handleChoiceFormField(controlId, sectionId, event, targetControl, false, contactId, sectionGeneralValue);
          this._isClicked[targetControl.Attribute.GUID] = true;
          break;
        case AssessmentAttributeType.FLAG:
          this._handleFlagDropdownListFormField(controlId, sectionId, event, eventName, targetControl, contactId, sectionGeneralValue);
          this._isClicked[targetControl.Attribute.GUID] = true;
          break;
        default:
          console.error("unhanled type");
      }
    }
    // this.initPageTitleForMultipleAssess();
    this.checkMultipleUpdateFormDirty();
  }

  private _updateSectionGeneralValueFromResponse(section, columnSubID, contactID) {
    let selectedValue;
    switch(columnSubID) {
      case 'Procedure':
        selectedValue = this.selectedProcedures;
        break;
      case 'Position':
        selectedValue = this.selectedPositions;
        break;
      case 'PositionGroup':
        selectedValue = this.selectedPositionGroups;
        break;
      case 'Product' :
        selectedValue = this.selectedProducts;
        break;
      case 'TherapetuicArea':
        selectedValue = this.selectedTAs;
        break;
    }

    const subSectionValues = this._getValuesForSubSectionView(section, null, contactID);
    if (!_.isEmpty(Object.keys(subSectionValues))) {
      Object.keys(subSectionValues).forEach(subSectionId => {
        let labelText = this.customerAssessService.getLabelsForSection(this.customerAssessService.parseSectionId(subSectionId), this.masterData, null, this.specialty, columnSubID);
        this._updateSelectedSectionValue(selectedValue, section.SectionProperties.GUID, contactID, columnSubID, labelText);
      })
    }
  }

  private _getLablesForSection(section, columnSubID, contactID) {
    if (section.TherapetuicArea || section.Product || section.Position || section.PositionGroup || section.Procedure) {
      let labels= [];
      let skipUpdate = false;
      //update unsaved values
      switch(columnSubID) {
        case 'Procedure':
          this._getLabelTextForSectionFromSelected(this.selectedProcedures, section.SectionProperties.GUID, columnSubID, contactID, labels);
          break;
        case 'Position':
          this._getLabelTextForSectionFromSelected(this.selectedPositions, section.SectionProperties.GUID, columnSubID, contactID, labels);
          break;
        case 'PositionGroup':
          this._getLabelTextForSectionFromSelected(this.selectedPositionGroups, section.SectionProperties.GUID, columnSubID, contactID, labels);
          break;
        case 'Product' :
          this._getLabelTextForSectionFromSelected(this.selectedProducts, section.SectionProperties.GUID, columnSubID, contactID, labels);
          break;
        case 'TherapetuicArea':
          this._getLabelTextForSectionFromSelected(this.selectedTAs, section.SectionProperties.GUID, columnSubID, contactID, labels);
          break;
      }
      if(!_.isEmpty(this.sectionControlToReset)) {
        let idx = this.sectionControlToReset.findIndex((field) => field.contactId == contactID && field.sectionId == section.SectionProperties.GUID && field.section_general == columnSubID)
        if(idx>-1) skipUpdate = true;
      }
      if(_.isEmpty(labels) && !skipUpdate) {
        const subSectionValues = this._getValuesForSubSectionView(section, null);
        if (!_.isEmpty(Object.keys(subSectionValues))) {
          Object.keys(subSectionValues).forEach(subSectionId => {
            let label = this._getLabelTextForSectionFromResponse(section, subSectionId, columnSubID, contactID);
            if(!_.isEmpty(labels)) {
              let idx = labels.findIndex(la => la.id == label.id);
              if(idx>-1) labels[idx] = label;
              else labels.push(label);
            } else {
              labels.push(label);
            }
          })
        }
      }
      let labelText;
      if(labels && labels.length>1) labelText = `${labels[0].label} + ${labels.length-1}`;
      else if(labels && labels.length==1) labelText = labels[0].label;
      else labelText = '';
      return labelText;
    }
  }

  private _getLabelTextForSectionFromSelected(selectedValue, sectionId, columnSubID, contactID, labels) {
    if(!_.isEmpty(selectedValue)) {
      let foundLabel = selectedValue.find((val) => val.contactId == contactID && val.sectionId == sectionId && val.section_general == columnSubID)?.selected;
      if(!_.isEmpty(foundLabel)) {
        if(Array.isArray(foundLabel)) {
          foundLabel.forEach((lb) => {
            let label = { id: lb.id, label: lb.title }
            if(!_.isEmpty(labels)) {
              let idx = labels.findIndex(la => la.id == label.id);
              if(idx>-1) labels[idx] = label;
              else labels.push(label);
            } else {
              labels.push(label);
            }
          })
        } else {
          let label = { id: foundLabel.id, label: foundLabel.title }
          if(!_.isEmpty(labels)) {
            let idx = labels.findIndex(la => la?.id == label.id);
            if(idx>-1) labels[idx] = label;
            else labels.push(label);
          } else labels.push(label);
        }

      }
    }
  }

  private _getLabelTextForSectionFromResponse(section, subSectionId, columnSubID, contactID) {
    let labelText = this.customerAssessService.getLabelsForSection(this.customerAssessService.parseSectionId(subSectionId), this.masterData, null, this.specialty, columnSubID);
    switch(columnSubID) {
      case 'Procedure':
        this._updateSelectedSectionValue(this.selectedProcedures, section.SectionProperties.GUID, contactID, columnSubID, labelText);
        break;
      case 'TherapetuicArea':
        this._updateSelectedSectionValue(this.selectedTAs, section.SectionProperties.GUID, contactID, columnSubID, labelText);
        break;
      case 'Position':
        this._updateSelectedSectionValue(this.selectedPositions, section.SectionProperties.GUID, contactID, columnSubID, labelText);
        break;
      case 'PositionGroup':
        this._updateSelectedSectionValue(this.selectedPositionGroups, section.SectionProperties.GUID, contactID, columnSubID, labelText);
        break;
      case 'Product':
        this._updateSelectedSectionValue(this.selectedProducts, section.SectionProperties.GUID, contactID, columnSubID, labelText);
        break;
      default:
        console.log('no labels!!!')
    }
    if(!_.isEmpty(this.isDisabledSectionGeneral)) {
      let idx = this.isDisabledSectionGeneral.findIndex((value) => value.contactId == contactID && value.sectionId == section.SectionProperties.GUID && value.section_general == columnSubID);
      if(idx<0) {
        this.isDisabledSectionGeneral.push({
          contactId: contactID,
          sectionId: section.SectionProperties.GUID,
          section_general: columnSubID
        })
      }
    } else {
      this.isDisabledSectionGeneral.push({
        contactId: contactID,
        sectionId: section.SectionProperties.GUID,
        section_general: columnSubID
      })
    }
    return labelText;
  }

  private _updateSelectedSectionValue(existingData, sectionID, contactID, columnSubID, labelText) {
    if(!_.isEmpty(existingData)) {
      let idx = existingData.findIndex((data) => data.contactId == contactID && data.sectionId == sectionID && data.section_general == columnSubID);
      if(idx>-1) {
        let selIdx = existingData[idx].selected.findIndex((sel) => sel.id == labelText?.id);
        if(selIdx<0) {
          existingData[idx].selected.push({
            id: labelText?.id,
            title: labelText?.label,
            isSelected: true
          })
        }
      } else {
        existingData.push({
          contactId: contactID,
          sectionId: sectionID,
          section_general: columnSubID,
          selected: [{
            id: labelText?.id,
            title: labelText?.label,
            isSelected: true
          }]
        })
      }
    } else {
      existingData.push({
          contactId: contactID,
          sectionId: sectionID,
          section_general: columnSubID,
          selected: [{
            id: labelText?.id,
            title: labelText?.label,
            isSelected: true
          }]
      })
    }
  }

  private _getHeaderSecondForSectionControl(section, contactID) {
    if (section.TherapetuicArea || section.Product || section.Position ||  section.PositionGroup || section.Procedure) {
      let headers = [];
      let headerSecFromUnsaved = this._getUnsavedHeaderSecond(section, contactID);
      if(!_.isEmpty(headerSecFromUnsaved)) {
        headerSecFromUnsaved.forEach((headerSec) => {
          let idx = headers?.findIndex((hd) => headerSec.id == hd.id);
          if(idx && idx<0) headers.push(headerSec);
        })
      }
      return headers;
    }
  }

  // private _getHeaderText(section: AttributeSection, subSectionId: string) {
  //   try {
  //     const headerLabel = this.customerAssessService.getSelectionTemplate(this.customerAssessService.parseSectionId(subSectionId), this.masterData, null, this.specialty);
  //     let sectionHeaderSecond = {
  //       id: subSectionId,
  //       type: 'header-second',
  //       view: {
  //         id: subSectionId,
  //         title: headerLabel.name,
  //         secondaryText: headerLabel.secondaryText
  //       },
  //     };
  //     return sectionHeaderSecond;
  //   } catch (error) {
  //     console.log('Error parsing sub-section header view from tab : ' + error);
  //   }
  // }

  private _getUnsavedHeaderSecond(section:AttributeSection, contactID) {
    let sectionHeaderSecond = [];
    let secondaryText;
    if (section.Procedure) {
      //Procedure section
      let foundLabel = [];
      if(!_.isEmpty(this.selectedProcedures)) {
        let prod = this.selectedProcedures.find((proc) => proc.contactId == contactID && proc.sectionId  == section.SectionProperties.GUID && proc.section_general == 'Procedure')?.selected;
        if(!_.isEmpty(prod)) {
          prod.forEach(p => foundLabel.push(p));
          foundLabel.forEach((lb) => {
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '___' + lb.id,
              type: 'header-second',
              view: {
                id: '___' + lb.id,
                title: lb.title,
                secondaryText: '',
              }
            })
          })
        }
      }
    }
    else if (section.Product) {
      //Product section
      let foundProduct;
      let foundPosition = [];
      let foundPositionGroup = [];
      let foundTA = [];
      let _positionText = [];
      let _positionGroupText = [];
      let _taText = [];
      let _secondaryText= [];
      if(!_.isEmpty(this.selectedProducts)) foundProduct = this.selectedProducts.find((proc) => proc.contactId == contactID && proc.sectionId == section.SectionProperties.GUID && proc.section_general == 'Product')?.selected;
      if(!_.isEmpty(foundProduct)) {
        if (section.Position) {
          if (!_.isEmpty(this.selectedPositions)) foundPosition = this.selectedPositions.find((pos) => pos.contactId == contactID && pos.sectionId == section.SectionProperties.GUID && pos.section_general == 'Position')?.selected;
          if (!_.isEmpty(foundPosition)) {
            foundPosition.forEach((fp) => {
              _positionText.push({
                id: this.masterData.positions.find(pos => pos.ID == fp.id)?.ID + '_',
                name: this.masterData.positions.find(pos => pos.ID == fp.id)?.name
              })
            });
          }
        }

        if (section.PositionGroup) {
          if (!_.isEmpty(this.selectedPositionGroups)) foundPositionGroup = this.selectedPositionGroups.find((pos) => pos.contactId == contactID && pos.sectionId == section.SectionProperties.GUID && pos.section_general == 'PositionGroup')?.selected;
          if (!_.isEmpty(foundPositionGroup)) {
            foundPositionGroup.forEach((fp) => {
              _positionGroupText.push({
                id: this.masterData.positionGroups.find(pos => pos.positionGroupId == fp.id)?.positionGroupId + '_',
                name: this.masterData.positionGroups.find(pos => pos.positionGroupName == fp.id)?.positionGroupName
              })
            });
          }
        }

        if (section.TherapetuicArea) {
          //Product and TA section, Product and TA and Position
          if(!_.isEmpty(this.selectedTAs)) foundTA = this.selectedTAs.find((ta) => ta.contactId == contactID && ta.sectionId == section.SectionProperties.GUID && ta.section_general == 'TherapetuicArea')?.selected;
          if(!_.isEmpty(foundTA)){
            foundTA.forEach((ft) => {
              _taText.push({
                id: this.masterData.ta.find(prod => prod.therapeuticareaId == ft.id)?.therapeuticareaId,
                name: this.masterData.ta.find(prod => prod.therapeuticareaId == ft.id)?.name
              })
            })
          }
        }

        if (section.PositionGroup || section.Position || section.TherapetuicArea) {

          _positionText?.forEach((pt) => {
            if(!_.isEmpty(_taText)) {
              _taText.forEach((tt) => {
                _secondaryText.push({
                  id: pt.id + tt.id,
                  name: [pt.name,tt.name].filter(Boolean).join(", ")
                });
              })
            } else {
              _secondaryText.push(pt);
            }
          });

          _positionGroupText?.forEach((pt) => {
            if(!_.isEmpty(_taText)) {
              _taText.forEach((tt) => {
                _secondaryText.push({
                  id: pt.id + tt.id,
                  name: [pt.name,tt.name].filter(Boolean).join(", ")
                });
              })
            } else {
              _secondaryText.push(pt);
            }
          });
          //Case: TA-Product
          if(_.isEmpty(_positionText) && _.isEmpty(_positionGroupText) && !_.isEmpty(_taText)) {
            _taText.forEach((tt) => {
              _secondaryText.push({
                id: '_' + tt.id,
                name: tt.name
              });
            });
          }
        }


        if (section.PositionGroup || section.Position || section.TherapetuicArea) {
          foundProduct.forEach((fp) => {
            _secondaryText?.forEach((st) => {
              sectionHeaderSecond.push({
                id: section.SectionProperties.GUID + '_' + st['id'] + '_' + fp.id + '__',
                type: 'header-second',
                view: {
                  id: st['id'] + '_' + fp.id + '__',
                  title: this.masterData.products.find(prod => prod.ID == fp.id)?.name,
                  secondaryText: st['name'],
                }
              });
            });
          })
        } else {

          foundProduct.forEach((fp) => {
              sectionHeaderSecond.push({
                id: section.SectionProperties.GUID + '___' + fp.id + '__',
                type: 'header-second',
                view: {
                  id:  '__' + fp.id + '__',
                  title: this.masterData.products.find(prod => prod.ID == fp.id)?.name,
                }
              });
          })
        }

        if (section.Product && !section.TherapetuicArea && !section.Procedure) {
          let foundId = [];
          if(!_.isEmpty(this._multipleFormValue)) {
            this._multipleFormValue.filter((view) => view.sectionId == section.SectionProperties.GUID).forEach((form) => foundId.push(form.id));
          }
          if(!_.isEmpty(this.multipleFormView)) {
            this.multipleFormView.forEach((view) => {
              view.currentFormView.filter((formView) => formView.type == 'sub-section-header')?.forEach((form) => {
                let sectionIds = this.customerAssessService.parseSectionId(form.id);
                if(sectionIds['productId'] && !sectionIds['taId'] && !sectionIds['procedureId']) foundId.push(form.id);
              });
            });
          }
          let uniqueFoundId = [...new Set(foundId)];
          if(!_.isEmpty(uniqueFoundId)) sectionHeaderSecond = sectionHeaderSecond?.filter((secHeader) => uniqueFoundId.some(id => id == secHeader.view.id));
        }
      }
    }
    else if (section.Position) {
      //Positon section
      let foundPosition;
      let foundTA = [];
      let _taText = [];

      if(!_.isEmpty(this.selectedPositions)) foundPosition = this.selectedPositions.find((pos) => pos.contactId == contactID && pos.sectionId == section.SectionProperties.GUID && pos.section_general == 'Position')?.selected;
      if(!_.isEmpty(foundPosition)) {
        if (section.TherapetuicArea) {
          //Position and TA section
          if(!_.isEmpty(this.selectedTAs)) foundTA = this.selectedTAs.find((ta) => ta.contactId == contactID && ta.sectionId == section.SectionProperties.GUID && ta.section_general == 'TherapetuicArea')?.selected;
          if(!_.isEmpty(foundTA)){
            foundTA.forEach((ft) => {
              _taText.push({
                id: this.masterData.ta.find(prod => prod.therapeuticareaId == ft.id)?.therapeuticareaId,
                name: this.masterData.ta.find(prod => prod.therapeuticareaId == ft.id)?.name
              })
            })
          }
          foundPosition.forEach((fp) => {
            _taText?.forEach((st) => {
              sectionHeaderSecond.push({
                id: section.SectionProperties.GUID + '_' + fp.id + '_' + st.id + '___',
                type: 'header-second',
                view: {
                  id: fp.id + '_' + st.id + '___',
                  title: this.masterData.positions.find(pos => pos.ID == fp.id)?.name,
                  secondaryText: st['name'],
                }
              })
            })
          })
        } else {
          //Position only
          foundPosition.forEach((fp) => {
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + fp?.id + '____',
              type: 'header-second',
              view: {
                id: fp?.id + '____',
                title: this.masterData.positions.find(pos => pos.ID == fp.id)?.name,
                secondaryText: '',
              }
            })
          })
        }
        if (!section.Product && section.Position && section.TherapetuicArea && !section.Procedure) {
          let foundId = [];
          if(!_.isEmpty(this._multipleFormValue)) {
            this._multipleFormValue.filter((view) => view.sectionId == section.SectionProperties.GUID).forEach((form) => foundId.push(form.id));
          }
          if(!_.isEmpty(this.multipleFormView)) {
            this.multipleFormView.forEach((view) => {
              view.currentFormView.filter((formView) => formView.type == 'sub-section-header')?.forEach((form) => {
                let sectionIds = this.customerAssessService.parseSectionId(form.id);
                if(!sectionIds['productId'] && sectionIds['positionId'] && sectionIds['taId'] && !sectionIds['procedureId']) foundId.push(form.id);
              })
            })
          }
          let uniqueFoundId = [...new Set(foundId)];
          if(!_.isEmpty(uniqueFoundId)) sectionHeaderSecond = sectionHeaderSecond?.filter((secHeader) => uniqueFoundId.some(id => id == secHeader.view.id));
        }

      }
    }
    else if (section.PositionGroup) {
      //PositonGroup section
      let foundPositionGroup;
      let foundTA = [];
      let _taText = [];

      if(!_.isEmpty(this.selectedPositionGroups)) foundPositionGroup = this.selectedPositionGroups.find((pos) => pos.contactId == contactID && pos.sectionId == section.SectionProperties.GUID && pos.section_general == 'PositionGroup')?.selected;
      if(!_.isEmpty(foundPositionGroup)) {
        if (section.TherapetuicArea) {
          //PositionGroup and TA section
          if(!_.isEmpty(this.selectedTAs)) foundTA = this.selectedTAs.find((ta) => ta.contactId == contactID && ta.sectionId == section.SectionProperties.GUID && ta.section_general == 'TherapetuicArea')?.selected;
          if(!_.isEmpty(foundTA)){
            foundTA.forEach((ft) => {
              _taText.push({
                id: this.masterData.ta.find(prod => prod.therapeuticareaId == ft.id)?.therapeuticareaId,
                name: this.masterData.ta.find(prod => prod.therapeuticareaId == ft.id)?.name
              })
            })
          }
          foundPositionGroup.forEach((fp) => {
            _taText?.forEach((st) => {
              sectionHeaderSecond.push({
                id: section.SectionProperties.GUID + '_' + fp.id + '_' + st.id + '___',
                type: 'header-second',
                view: {
                  id: fp.id + '_' + st.id + '___',
                  title: this.masterData.positionGroups.find(pos => pos.positionGroupId == fp.id)?.name,
                  secondaryText: st['name'],
                }
              })
            })
          })
        } else {
          //PositionGroup only
          foundPositionGroup.forEach((fp) => {
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '_' + fp?.id + '____',
              type: 'header-second',
              view: {
                id: fp?.id + '____',
                title: this.masterData.positionGroups.find(pos => pos.positionGroupId == fp.id)?.name,
                secondaryText: '',
              }
            })
          })
        }
        if (!section.Product && !section.Position && section.PositionGroup && section.TherapetuicArea && !section.Procedure) {
          let foundId = [];
          if(!_.isEmpty(this._multipleFormValue)) {
            this._multipleFormValue.filter((view) => view.sectionId == section.SectionProperties.GUID).forEach((form) => foundId.push(form.id));
          }
          if(!_.isEmpty(this.multipleFormView)) {
            this.multipleFormView.forEach((view) => {
              view.currentFormView.filter((formView) => formView.type == 'sub-section-header')?.forEach((form) => {
                let sectionIds = this.customerAssessService.parseSectionId(form.id);
                if(!sectionIds['productId'] && !sectionIds['positionId'] && sectionIds['positionGroupId'] && sectionIds['taId'] && !sectionIds['procedureId']) foundId.push(form.id);
              })
            })
          }
          let uniqueFoundId = [...new Set(foundId)];
          if(!_.isEmpty(uniqueFoundId)) sectionHeaderSecond = sectionHeaderSecond?.filter((secHeader) => uniqueFoundId.some(id => id == secHeader.view.id));
        }

      }
    }
    else if (section.TherapetuicArea) {
      //TA section
      let foundTA;
      if(!_.isEmpty(this.selectedTAs)) {
        foundTA = this.selectedTAs.find((ta) => ta.contactId == contactID && ta.sectionId == section.SectionProperties.GUID && ta.section_general == 'TherapetuicArea')?.selected;
        if(!_.isEmpty(foundTA)) {
          foundTA.forEach((ft) => {
            sectionHeaderSecond.push({
              id: section.SectionProperties.GUID + '__' + ft?.id + '___',
              type: 'header-second',
              view: {
                id: '_' + ft?.id + '___',
                title: this.masterData.ta.find(ta => ta.therapeuticareaId == ft.id)?.name,
                secondaryText: '',
              }
            })
          })
        }
      }
    }
    return sectionHeaderSecond;
  }

  private _updateLabel(foundLabel, sectionHeaderSecond, sectionID) {
    foundLabel.forEach((lb) => {
      if(!_.isEmpty(sectionHeaderSecond)) {
        let idx = sectionHeaderSecond.find((sec) => sec.id == sectionID + '_' + lb.id,);
        if(idx>-1) sectionHeaderSecond[idx] = {
          id: sectionID + '_' + lb.id,
          type: 'header-second',
          view: {
            id: lb.id,
            title: lb.title,
            secondaryText: '',
          }
        };
        else {
          sectionHeaderSecond.push({
            id: sectionID + '_' + lb.id,
            type: 'header-second',
            view: {
              id: lb.id,
              title: lb.title,
              secondaryText: '',
            }
          })
        }
      } else {
        sectionHeaderSecond.push({
          id: sectionID + '_' + lb.id,
          type: 'header-second',
          view: {
            id: lb.id,
            title: lb.title,
            secondaryText: '',
          }
        })
      }
    })
    return sectionHeaderSecond;
  }

  private async _handleSectionEvent(id, contactID, columnSubID, section, event, eventName, item?, isApplyAll = false) {
    if (this._dropdownPopover) return;

    if (isApplyAll && contactID === undefined) {
      contactID = this.selectedCustomers?.map(c => c.ID) || [];
    }

    switch (columnSubID) {
      case 'Procedure':
        this._handleProcedureSelection(id, contactID, columnSubID, section, event, eventName, isApplyAll);
        break;
      case 'TherapetuicArea':
        this._handleTASelection(id, contactID, columnSubID, section, event, eventName, isApplyAll);
        break;
      case 'Position':
        this._handlePositionSelection(id, contactID, columnSubID, section, event, eventName, isApplyAll);
        break;
      case 'PositionGroup':
        this._handlePositionGroupSelection(id, contactID, columnSubID, section, event, eventName, isApplyAll);
        break;
      case 'Product':
        this._handleProductSelection(id, contactID, columnSubID, section, event, eventName, isApplyAll);
        break;
      default:
        return null;
    }
  }

  private _applySelectedProcedure(data, section, contactID) {
    let idx = this.selectedProcedures.findIndex((proc) => proc.contactId == contactID);
    if (idx > -1) {
        this.selectedProcedures[idx] = {
        contactId: contactID,
        sectionId: section.SectionProperties.GUID,
        section_general: 'Procedure',
        specialty: this.specialty.id,
        selected: data.selectedItems
      };
    } else {
      this.selectedProcedures.push({
        contactId: contactID,
        sectionId: section.SectionProperties.GUID,
        section_general: 'Procedure',
        specialty: this.specialty.id,
        selected: data.selectedItems
      });
    }
  }

  private async _handleProcedureSelection(id, contactID, columnSubID, section, event, eventName, isApplyAll = false) {
    this.specialty = this.contactService.getContactByID(contactID)?.primarySpecialty;
    if (this.specialty) this.masterData.procedures = this.customerAssessService.specialtyProceduresMap.has(this.specialty.id) ? this.customerAssessService.specialtyProceduresMap.get(this.specialty.id) : [];
    if (section.Procedure && _.isEmpty(this.masterData.procedures)) return;
    let data: SelectListData[] = []
    if (section.Procedure) {
      //Product only
      data = this.masterData.procedures.map(prod => {
        return {
          id: prod.ID,
          title: prod.name,
          isSelected: isApplyAll ? false : !_.isEmpty(this.selectedProcedures?.find((proc) => proc.contactId == contactID)?.selected.find(se => se.id == prod.ID))
        }
      })
    }

    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'multi-assessment-procedure-select',
      data: data,
      isMultipleSelectionEnabled: true,
      showCancelandDoneBtn: true,
    };
    this._dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event,
    });
    await this._dropdownPopover.present();
    await this._dropdownPopover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (!_.isEmpty(data.selectedItems)) {
          if (isApplyAll && Array.isArray(contactID)) {
            for (const cId of contactID) {
              this._applySelectedProcedure(data, section, cId);
            }
          } else {
            this._applySelectedProcedure(data, section, contactID);
          }
          this.initGridForMultipleAssess(this.selectedCustomers);
        }
      }
      this._dropdownPopover = undefined;
    })
  }

  private async _handlePositionSelection(id, contactID: string | string[], columnSubID, section, event, eventName, isApplyAll = false) {
    if ((section.Position || section.Product) && _.isEmpty(this.masterData.positions)) return;
    let data: SelectListData[] = []
    if (section.Position || section.Product) {
      //Product only
      data = this.masterData.positions.map(pos => {
        return {
          id: pos.ID,
          title: pos.name,
          isSelected: isApplyAll || Array.isArray(contactID)
            ? false
            : !_.isEmpty(this.selectedPositions?.find((proc) => proc.contactId == contactID && proc.sectionId == section.SectionProperties.GUID && proc.section_general == columnSubID)?.selected.find(s => s.id == pos.ID))
        }
      })
    }
    let seleted = data.filter(da => da.isSelected);
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'multi-assessment-position-select',
      data: data,
      isMultipleSelectionEnabled: false,
      showCancelandDoneBtn: false,
    };
    this._dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event,
    });
    await this._dropdownPopover.present();
    await this._dropdownPopover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (!_.isEmpty(data.selectedItems)) {
          //if the section general value is changed, need to reset the value for controls
          if(seleted && !_.isEqual(seleted, data.selectedItems)) {
            let preValue = [];
            if(Array.isArray(seleted)) seleted?.forEach((da) => preValue.push(da.id));
            else preValue.push(seleted);
            if (section.Product) {
              if (isApplyAll && Array.isArray(contactID)) {
                for (const cId of contactID) {
                  let idx = this.selectedProducts?.findIndex((proc) => proc.contactId == cId && proc.sectionId == section.SectionProperties.GUID && proc.section_general == 'Product');
                  if (idx >-1) this.selectedProducts.splice(idx,1);

                  this.sectionControlToReset.push({
                    contactId: cId,
                    sectionId:section.SectionProperties.GUID,
                    section_general: 'Product',
                    preValueId: preValue,
                    newValueId: data.selectedItems
                  });
                }
              } else {
                let idx = this.selectedProducts?.findIndex((proc) => proc.contactId == contactID && proc.sectionId == section.SectionProperties.GUID && proc.section_general == 'Product');
                if (idx >- 1) this.selectedProducts.splice(idx,1);

                this.sectionControlToReset.push({
                  contactId: contactID as string,
                  sectionId:section.SectionProperties.GUID,
                  section_general: 'Product',
                  preValueId: preValue,
                  newValueId: data.selectedItems
                });
              }
            }
            if(section.TherapetuicArea) {
              if (isApplyAll && Array.isArray(contactID)) {
                for (const cId of contactID) {
                  let idx = this.selectedTAs.findIndex((ta) => ta.contactId == cId && ta.sectionId == section.SectionProperties.GUID && ta.section_general == 'TherapetuicArea');
                  if (idx >- 1) this.selectedTAs.splice(idx,1);

                  this.sectionControlToReset.push({
                    contactId: cId,
                    sectionId:section.SectionProperties.GUID,
                    section_general: 'TherapetuicArea',
                    preValueId: preValue,
                    newValueId: data.selectedItems
                  });
                }
              } else {
                let idx = this.selectedTAs.findIndex((ta) => ta.contactId == contactID && ta.sectionId == section.SectionProperties.GUID && ta.section_general == 'TherapetuicArea');
                if (idx >- 1) this.selectedTAs.splice(idx,1);

                this.sectionControlToReset.push({
                  contactId: contactID as string,
                  sectionId:section.SectionProperties.GUID,
                  section_general: 'TherapetuicArea',
                  preValueId: preValue,
                  newValueId: data.selectedItems
                });
              }
            }
            section.Controls.forEach((control) => {
              if (isApplyAll && Array.isArray(contactID)) {
                for (const cId of contactID) {
                  this._clearAttributeValue(control, cId);
                }
              } else {
                this._clearAttributeValue(control, contactID as string);
              }
            });
          }

          if (isApplyAll && Array.isArray(contactID)) {
            for (const cId of contactID) {
              let idx = this.selectedPositions?.findIndex((proc) => proc.contactId == cId && proc.sectionId == section.SectionProperties.GUID && proc.section_general == columnSubID);
              if (idx >- 1) {
                this.selectedPositions[idx] = {
                  contactId: cId,
                  sectionId: section.SectionProperties.GUID,
                  section_general: 'Position',
                  selected: data.selectedItems
                };
              } else {
                this.selectedPositions.push({
                  contactId: cId,
                  sectionId: section.SectionProperties.GUID,
                  section_general: 'Position',
                  selected: data.selectedItems
                });
              }
            }
          } else {
            let idx = this.selectedPositions?.findIndex((proc) => proc.contactId == contactID && proc.sectionId == section.SectionProperties.GUID && proc.section_general == columnSubID);
            if (idx >- 1) {
              this.selectedPositions[idx] = {
                contactId: contactID as string,
                sectionId: section.SectionProperties.GUID,
                section_general: 'Position',
                selected: data.selectedItems
              };
            } else {
              this.selectedPositions.push({
                contactId: contactID as string,
                sectionId: section.SectionProperties.GUID,
                section_general: 'Position',
                selected: data.selectedItems
              });
            }
          }

          this.initGridForMultipleAssess(this.selectedCustomers);
        }
      }
      this._dropdownPopover = undefined;
    })
  }

  private async _handlePositionGroupSelection(id, contactID: string | string[], columnSubID, section, event, eventName, isApplyAll = false) {
    if (section.PositionGroup && _.isEmpty(this.masterData.positionGroups)) return;
    let data: SelectListData[] = []
    if (section.PositionGroup || section.Product) {
      //PositionGroup only
      data = this.masterData.positionGroups.map(pos => {
        return {
          id: pos.positionGroupId,
          title: pos.positionGroupName,
          isSelected: isApplyAll || Array.isArray(contactID)
          ? false
          : !_.isEmpty(this.selectedPositionGroups?.find((proc) => proc.contactId == contactID && proc.sectionId == section.SectionProperties.GUID && proc.section_general == columnSubID)?.selected.find(s => s.id == pos.positionGroupId))
        }
      })
    }
    let seleted = data.filter(da => da.isSelected);
    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'multi-assessment-positiongroup-select',
      data: data,
      isMultipleSelectionEnabled: false,
      showCancelandDoneBtn: false,
    };
    this._dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event,
    });
    await this._dropdownPopover.present();
    await this._dropdownPopover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (!_.isEmpty(data.selectedItems)) {
          //if the section general value is changed, need to reset the value for controls
          if(seleted && !_.isEqual(seleted, data.selectedItems)) {
            let preValue = [];
            if(Array.isArray(seleted)) seleted?.forEach((da) => preValue.push(da.id));
            else preValue.push(seleted);
            if(section.Product) {
              if (isApplyAll && Array.isArray(contactID)) {
                for (const cId of contactID) {
                  const idx = this.selectedProducts.findIndex((proc) => proc.contactId == cId && proc.sectionId == section.SectionProperties.GUID && proc.section_general == 'Product');
                  if(idx >- 1) this.selectedProducts.splice(idx, 1);
                  this.sectionControlToReset.push({
                    contactId: cId,
                    sectionId:section.SectionProperties.GUID,
                    section_general: 'Product',
                    preValueId: preValue,
                    newValueId: data.selectedItems
                  })
                }
              } else {
                const idx = this.selectedProducts.findIndex((proc) => proc.contactId == contactID && proc.sectionId == section.SectionProperties.GUID && proc.section_general == 'Product');
                if (idx >- 1) this.selectedProducts.splice(idx, 1);
                this.sectionControlToReset.push({
                  contactId: contactID as string,
                  sectionId:section.SectionProperties.GUID,
                  section_general: 'Product',
                  preValueId: preValue,
                  newValueId: data.selectedItems
                })
              }
            }
            if (section.TherapetuicArea) {
              if (isApplyAll && Array.isArray(contactID)) {
                for (const cId of contactID) {
                  const idx = this.selectedTAs.findIndex((ta) => ta.contactId === cId && ta.sectionId === section.SectionProperties.GUID && ta.section_general === 'TherapetuicArea');
                  if(idx>-1) this.selectedTAs.splice(idx, 1);

                  this.sectionControlToReset.push({
                    contactId: cId,
                    sectionId:section.SectionProperties.GUID,
                    section_general: 'TherapetuicArea',
                    preValueId: preValue,
                    newValueId: data.selectedItems
                  });
                }
              } else {
                const idx = this.selectedTAs.findIndex((ta) => ta.contactId === contactID && ta.sectionId === section.SectionProperties.GUID && ta.section_general === 'TherapetuicArea');
                if(idx>-1) this.selectedTAs.splice(idx,1);
                this.sectionControlToReset.push({
                  contactId: contactID as string,
                  sectionId:section.SectionProperties.GUID,
                  section_general: 'TherapetuicArea',
                  preValueId: preValue,
                  newValueId: data.selectedItems
                })
              }
            }
            section.Controls.forEach((control) => {
              if (isApplyAll && Array.isArray(contactID)) {
                for (const cId of contactID) {
                  this._clearAttributeValue(control, cId);
                }
              } else {
                this._clearAttributeValue(control, contactID as string);
              }
            });
          }

          if (isApplyAll && Array.isArray(contactID)) {
            for (const cId of contactID) {
              const idx = this.selectedPositionGroups.findIndex((proc) => proc.contactId === cId && proc.sectionId === section.SectionProperties.GUID && proc.section_general === columnSubID);
              if (idx >- 1) {
                this.selectedPositionGroups[idx] = {
                  contactId: cId,
                  sectionId: section.SectionProperties.GUID,
                  section_general: 'PositionGroup',
                  selected: data.selectedItems
                };
              } else {
                this.selectedPositionGroups.push({
                  contactId: cId,
                  sectionId: section.SectionProperties.GUID,
                  section_general: 'PositionGroup',
                  selected: data.selectedItems
                });
              }
            }
          } else {
            const idx = this.selectedPositionGroups.findIndex((proc) => proc.contactId == contactID && proc.sectionId == section.SectionProperties.GUID && proc.section_general == columnSubID);
            if (idx >- 1) {
              this.selectedPositionGroups[idx] = {
                contactId: contactID as string,
                sectionId: section.SectionProperties.GUID,
                section_general: 'PositionGroup',
                selected: data.selectedItems
              };
            } else {
              this.selectedPositionGroups.push({
                contactId: contactID as string,
                sectionId: section.SectionProperties.GUID,
                section_general: 'PositionGroup',
                selected: data.selectedItems
              });
            }
          }

          this.initGridForMultipleAssess(this.selectedCustomers);
        }
      }
      this._dropdownPopover = undefined;
    })
  }

  private async _handleProductSelection(id, contactID: string | string[], columnSubID, section, event, eventName, isApplyAll = false) {
    if (section.Product && _.isEmpty(this.masterData.products)) return;
    let data: SelectListData[] = []
    if (section.PositionGroup || section.Position || section.Product) {
      //Product only
      data = this.masterData.products.map(pos => {
        return {
          id: pos.ID,
          title: pos.name,
          isSelected: isApplyAll || Array.isArray(contactID)
          ? false
          : !_.isEmpty(this.selectedProducts?.find((proc) => proc.contactId == contactID && proc.sectionId == section.SectionProperties.GUID && proc.section_general == columnSubID)?.selected.find(se => se.id == pos.ID))
        }
      })
    }

    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'multi-assessment-product-select',
      data: data,
      isMultipleSelectionEnabled: true,
      showCancelandDoneBtn: true,
    };
    this._dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event,
    });
    await this._dropdownPopover.present();
    await this._dropdownPopover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (!_.isEmpty(data.selectedItems)) {
          if (isApplyAll && Array.isArray(contactID)) {
            for (const cId of contactID) {
              const idx = this.selectedProducts.findIndex((proc) => proc.contactId === cId && proc.sectionId === section.SectionProperties.GUID && proc.section_general === columnSubID);
              if (idx >- 1) {
                this.selectedProducts[idx] = {
                  contactId: cId,
                  sectionId: section.SectionProperties.GUID,
                  section_general: 'Product',
                  selected: data.selectedItems
                };
              }
              else {
                this.selectedProducts.push({
                  contactId: cId,
                  sectionId: section.SectionProperties.GUID,
                  section_general: 'Product',
                  selected: data.selectedItems
                });
              }
            }
          } else {
            const idx = this.selectedProducts.findIndex((proc) => proc.contactId === contactID && proc.sectionId === section.SectionProperties.GUID && proc.section_general === columnSubID);
            if (idx >- 1) {
              this.selectedProducts[idx] = {
                contactId: contactID as string,
                sectionId: section.SectionProperties.GUID,
                section_general: 'Product',
                selected: data.selectedItems
              };
            }
            else {
              this.selectedProducts.push({
                contactId: contactID as string,
                sectionId: section.SectionProperties.GUID,
                section_general: 'Product',
                selected: data.selectedItems
              });
            }
          }

          if (isApplyAll && Array.isArray(contactID)) {
            for (const cId of contactID) {
              const idx = this.sectionControlToReset.findIndex((con) => con.contactId === cId && con.sectionId === section.SectionProperties.GUID && con.section_general === columnSubID);
              if(idx>-1) this.sectionControlToReset.splice(idx,1);
            }
          } else {
            const idx = this.sectionControlToReset.findIndex((con) => con.contactId === contactID && con.sectionId === section.SectionProperties.GUID && con.section_general === columnSubID);
            if(idx>-1) this.sectionControlToReset.splice(idx,1);
          }

          this.initGridForMultipleAssess(this.selectedCustomers);
        }
      }
      this._dropdownPopover = undefined;
    })
  }

  private _applyTASelection(data, section, contactID) {
    let idx = this.selectedTAs.findIndex((proc) => proc.contactId == contactID);
    if (idx >- 1) {
      this.selectedTAs[idx] = {
        contactId: contactID,
        sectionId: section.SectionProperties.GUID,
        section_general: 'TherapetuicArea',
        selected: data.selectedItems
      };
    } else {
      this.selectedTAs.push({
        contactId: contactID,
        sectionId: section.SectionProperties.GUID,
        section_general: 'TherapetuicArea',
        selected: data.selectedItems
      });
    }
  }

  private async _handleTASelection(id, contactID: string | string[], columnSubID, section, event, eventName, isApplyAll = false) {
    if (section.TherapetuicArea && _.isEmpty(this.masterData.ta)) return;
    let data: SelectListData[] = [];
    let isMultipleEnabled = !section.Product && !section.Procedure && !section.Position && section.TherapetuicArea;
    if (section.TherapetuicArea) {
      //Product only
      data = this.masterData.ta.map(t => {
        return {
          id: t.therapeuticareaId,
          title: t.name,
          isSelected: isApplyAll ? false : !_.isEmpty(this.selectedTAs?.find((proc) => proc.contactId == contactID)?.selected.find(se => se.id == t.therapeuticareaId))
          // isMultipleEnabled ? !_.isEmpty(this.selectedTAs?.find((proc) => proc.contactId == contactID)?.selected.find(se => se.id == t.therapeuticareaId)) : !_.isEmpty(this.selectedTAs?.find((proc) => proc.contactId == contactID)?.selected.id == t.therapeuticareaId)
        }
      })
    }

    const dropdownListDetail: IndDropdownListDetailModel = {
      id: 'multi-assessment-TA-select',
      data: data,
      isMultipleSelectionEnabled: isMultipleEnabled,
      showCancelandDoneBtn: isMultipleEnabled,
    };
    this._dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: { viewData: dropdownListDetail },
      cssClass: 'dropdown-list-view',
      event: event,
    });
    await this._dropdownPopover.present();
    await this._dropdownPopover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && obj.data.isDone) {
        let data = obj.data;
        if (!_.isEmpty(data.selectedItems)) {
          if (isApplyAll && Array.isArray(contactID)) {
            for (const cId of contactID) {
              this._applyTASelection(data, section, cId);
            }
          } else {
            this._applyTASelection(data, section, contactID);
          }
          this.initGridForMultipleAssess(this.selectedCustomers);
        }
      }
      this._dropdownPopover = undefined;
    })
  }

  private _handleDateTimeFormFieldForGrid(id: string, sectionId: string, event, eventName, targetControl, contactID: string | string[], sectionGeneralValue, item) {
    this.scrollToView = true;
    //this.multiCustomerAssessDate?.open();
    this._tempInfoForDateTime = {
      control: targetControl,
      contactId: contactID,
      sectionId,
      sectionGeneralValue: sectionGeneralValue
    }
  }

  public async updateMultiAssessDate(event) {
    if(!event) {
      this._tempInfoForDateTime = {};
      return;
    }
    let valueDate = new Date(event.detail.value);
    if(!_.isEmpty(this._tempInfoForDateTime)) {
      if (Array.isArray(this._tempInfoForDateTime.contactId)) {
        await this.uiService.displayLoader();
        for (let i = 0; i < this._tempInfoForDateTime.contactId.length; i++) {
          const cId = this._tempInfoForDateTime.contactId[i];
          if(this.isMultipleUpdate) this._setCurrentFormViewAndRawData(cId);
          const isLastRecord: boolean = i === this._tempInfoForDateTime.contactId.length - 1;
          this._setAttributeValue(this._tempInfoForDateTime['control'], this._tempInfoForDateTime.sectionId, valueDate.toISOString(), isLastRecord, false, cId, this._tempInfoForDateTime['sectionGeneralValue'], !isLastRecord);
        }
        await this.uiService.dismissLoader();
      } else {
        if(this.isMultipleUpdate) this._setCurrentFormViewAndRawData(this._tempInfoForDateTime.contactId);
        this._setAttributeValue(this._tempInfoForDateTime['control'], this._tempInfoForDateTime.sectionId, valueDate.toISOString(), true, false, this._tempInfoForDateTime['contactId'], this._tempInfoForDateTime['sectionGeneralValue']);
      }
    }
    this._tempInfoForDateTime = {}
  }

  private _validateAttributeAccess(control: Control): Access {
    //1. check main Access field value
    let checkedAttributeAccess: Access = control.Access == Access.READ_ONLY ? Access.READ_ONLY : Access.READ_WRITE;
    //2. check attributes access roles and user's security roles
    const accessSecurityRoleAttributes = control.hasOwnProperty('Attribute') && control.Attribute.hasOwnProperty('AccessRole') ? control.Attribute['AccessRole'] : [];
    const userBusinessUnit = this.authService.user?.xBusinessUnitId || '';
    const userSecurityRoles = this.authService.user?.securityRoles || [];
    //3. validate access security roles with a user's security roles
    if(!_.isEmpty(accessSecurityRoleAttributes) && !_.isEmpty(userBusinessUnit) && !_.isEmpty(userSecurityRoles)) {
      let isAccessReadOnly: boolean = false;
      let isAccessReadWrite: boolean = false;
      accessSecurityRoleAttributes.forEach(attr => {
        if(attr.BusinessUnit == userBusinessUnit) {
          let foundSecurityRole = userSecurityRoles.some(sr=>sr.roleid == attr.SecurityRole);
          if(foundSecurityRole) {
            if(attr.Access == Access.READ_ONLY) {
              isAccessReadOnly = true;
            }else if(attr.Access == Access.READ_WRITE) {
              isAccessReadWrite = true;
            }
          }
        }
      });
      //Read & Write take precedence over Read Only
      if(isAccessReadWrite) {
        checkedAttributeAccess = Access.READ_WRITE;
      }else if(isAccessReadOnly){
        checkedAttributeAccess = Access.READ_ONLY;
      }
    }
    return checkedAttributeAccess;
  }
  //Validate the manager's templates which are shared with child positions
  private async _validateTeamview(selectedTemplateId: string): Promise<boolean> {
    let isValidToOpenSelectedTemplate: boolean = false;
    let childPositionTemplateIds = [];
    if(this.authService.user && !_.isEmpty(this.authService.user.childPositions)) {
      childPositionTemplateIds = await this.customerAssessService.getTeamViewTemplatesFromChildPositions();
      if(!_.isEmpty(childPositionTemplateIds)) {
        let idx = childPositionTemplateIds.findIndex(tId=>tId == selectedTemplateId);
        if(idx > -1) {
          isValidToOpenSelectedTemplate = true;
        }else {
          isValidToOpenSelectedTemplate = false;
        }
      }
    }
    return isValidToOpenSelectedTemplate;
  }

  private _mapFilterMenuDataFromTemplate(template: AssessmentTemplate, isValidToOpenSelectedTemplate: boolean) {

    if(!_.isEmpty(this.filterMenuModalData)) {
      //Update template options
      const foundTemplateList = this.filterMenuModalData.find(d=>d.id == 'teamview-template-field');
      if(foundTemplateList) {
        const templateIdx = foundTemplateList.options.findIndex(o=>o.id == template.indskr_assessmenttemplateid);
        if(templateIdx == -1) {
          let option = {
            id: template.indskr_assessmenttemplateid,
            displayName: template.indskr_name,
            isSelected: false,
          }
          if(isValidToOpenSelectedTemplate) {
            if(!_.isEmpty(this.selectedTemplate) && this.selectedTemplate.indskr_assessmenttemplateid == template.indskr_assessmenttemplateid) {
              option.isSelected = true;
            }
          }
          foundTemplateList.options.push(option);
        }
      }
    }else {
      let templateData = {
        id: 'teamview-template-field',
        displayName: this.translate.instant("TEMPLATE"),
        isMultiSelect: false,
        options: [{
          id: template.indskr_assessmenttemplateid,
          displayName: template.indskr_name,
          isSelected: false,
        }],
      }
      if(isValidToOpenSelectedTemplate) {
        if(!_.isEmpty(this.selectedTemplate) && this.selectedTemplate.indskr_assessmenttemplateid == template.indskr_assessmenttemplateid) {
          templateData.options[0].isSelected = true;
        }
      }else {
        templateData.options[0].isSelected = true;
      }
      this.filterMenuModalData.push(templateData);
    }
    this.customerAssessService.assessmentTeamViewFielterMenuData = this.filterMenuModalData;
  }

  private async _openTeamViewFilter(){
    if(this.device.isOffline) return;
    const entity = this.selectedTemplate.indskr_entity;
    const entityId = entity == 'contact' ? this.contactService.contactInformation.ID : this.accountService.selected.id;
    const selectedTemplatedId = this.selectedTemplate.indskr_assessmenttemplateid;
    this.uiService.displayLoader();
    let teamViewTemplates: AssessmentTemplate[] = _.cloneDeep(this.templatesTeamview);

    if(!_.isEmpty(teamViewTemplates)) {
      this.customerAssessService.assessmentTeamViewFielterMenuData = this.filterMenuModalData = [];
      let isValidToOpenSelectedTemplate: boolean = await this._validateTeamview(selectedTemplatedId);
      if(teamViewTemplates.length == 1) {
        if(isValidToOpenSelectedTemplate) {
          //update filter list
          await teamViewTemplates.forEach((template) => {
            this._mapFilterMenuDataFromTemplate(template, isValidToOpenSelectedTemplate);
          });
        }else {
          //case: manager has one template which is not shared with child users, display message
          const assessTargetName = entity == 'contact' ? this.translate.instant("CONTACT_SINGULAR") : this.translate.instant("ACCOUNT");
          this.notificationService.notify(this.translate.instant("NO_TEAM_ASSESSMENTS", {assessTarget: assessTargetName}), 'Customer Assessments', 'top', ToastStyle.INFO);
          this.uiService.dismissLoader();
          return;
        }
      }else {
        if(isValidToOpenSelectedTemplate) {
          await teamViewTemplates.forEach((template) => {
            this._mapFilterMenuDataFromTemplate(template, isValidToOpenSelectedTemplate);
          });
        }else {
          let foundIdx = teamViewTemplates.findIndex(t=>t.indskr_assessmenttemplateid == selectedTemplatedId);
          if(foundIdx > -1) {
            //case: remove the template from the filter list because no other user has responded to the login user's template
            teamViewTemplates.splice(foundIdx, 1);
            await teamViewTemplates.forEach((template) => {
              this._mapFilterMenuDataFromTemplate(template, isValidToOpenSelectedTemplate);
            });
          }
        }
      }
    }else {
      this.uiService.dismissLoader();
      console.log("there is no valid tempalte to open the filter menu");
      return;
    }
    this.uiService.dismissLoader();
    //open filter menu modal
    const modal = await this.modalCtrl.create({
      component: IndFilterMenuModalComponent,
      cssClass: 'filter-menu-right-modal',
      backdropDismiss: true,
      componentProps: {
        viewData: {
          from: PageName.CustomerAssessComponent,
          filterTitle: this.translate.instant('FILTER'),
          data: this.filterMenuModalData,
          entity: entity,
          entityId: entityId,
        },
      }
    });
    await modal.present().then(() => {});
    modal.onDidDismiss().then(async(res)=>{
      if(res && res.data && res.data.isDone) {
        //show result
        if(!_.isEmpty(res.data.selectedItems)) {
          this.customerAssessService.assessmentTeamViewFielterMenuData = res.data.selectedItems;
        }
        if(entity == 'contact') {
          this.uiService.contactDetailsSegment = "customer-assess-teamview";
        }else {
          this.uiService.accountDataSegment = "customer-assess-teamview";
        }
      }
    });
  }

}
