<ion-toolbar class="left-header-toolbar" [ngClass]="{ 'pad34BatteryTimer': device.isNativeApp, 'padding': readonly, 'toolbar-android': isAndroid,'impersontated-view': authenticationOfflineService.impersonatedUser }">
  <ion-buttons *ngIf="viewData?.isOnActPage; else viewdatamode" class="start-slot" slot="start">
    <ion-menu-toggle *ngIf="!authenticationOfflineService.impersonatedUser">
        <ion-icon class="darkMenuToggle bar-button-menutoggle-ios" [ngClass]="{'whiteFont':activityService.teamViewActive}" src="assets/imgs/menu-toggle.svg"></ion-icon>
        <span *ngIf="deltaService.deltaRecordsDTO.contacts.length ||  deltaService.deltaRecordsDTO.accounts.length ||
              deltaService.deltaRecordsDTO.presentations.length || deltaService.deltaRecordsDTO.resources.length" class="notify-bubble">
        </span>
    </ion-menu-toggle>
  </ion-buttons>
  <ng-template #viewdatamode>
    <ion-buttons class="start-slot" *ngIf="viewData?.mode; else hascancel" slot="start">
      <ion-button *ngFor="let button of viewData?.controls | filterBy: buttonsFilter.left" icon-only
                  (click)="onButtonClick(button)" [disabled]="button.isDisabled">
        <ion-icon *ngIf="button.icon || button.imgSrc" name="{{button.icon}}" class="{{button.cssClass}}" src="{{button.imgSrc}}"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ng-template>
  <ng-template #hascancel>
    <ion-buttons class="start-slot" slot="start">
      <ion-button *ngIf="viewData?.controls[0]?.icon || viewData?.controls[0]?.imgSrc; else left_nonIcon" icon-only (click)="onButtonClick(viewData?.controls[0])" [disabled]="viewData?.controls[0]?.isDisabled">
        <ion-icon name="{{viewData?.controls[0]?.icon}}" class="{{viewData?.controls[0]?.cssClass}}" src="{{viewData?.controls[0]?.imgSrc}}"></ion-icon>
      </ion-button>
      <ng-template #left_nonIcon>
        <ion-button (click)="onButtonClick(viewData?.controls[0])" *ngIf="viewData?.customHeaderProps && viewData?.customHeaderProps.hasCancel; else header_noncancel"
                    [disabled]="viewData?.controls[0]?.isDisabled">
          {{viewData?.controls[0]?.name}}
        </ion-button>
      </ng-template>
      <ng-template #header_noncancel>
        <button [ngClass]="{'back-to-home-btn':!viewData?.customHeaderProps?.hasCancel}" (click)="onButtonClick(viewData?.controls[0])">
          <ion-icon *ngIf="!viewData?.customHeaderProps?.hasCancel" class = "icon-back-home" name="indegene-back-to-home-btn"></ion-icon>
        </button>
      </ng-template>
    </ion-buttons>
  </ng-template>

  <div class="searchbar-wrapper" *ngIf="!searchHidden">
    <ion-searchbar  [(ngModel)]="searchText"
                    (ionChange)="_onSearchChange($event)"
                    (ionFocus)="_onSearchFocus($event)"
                    (ionBlur)="_onSearchBlur($event)"
                    (ionCancel)="_onSearchCancel($event)"
                    (ionClear)="_onSearchClear($event)"
                    (ionInput)="_onSearchInput($event)"
                    (click)="_onSearchBarClick($event)"
                    (search)="_onSearch($event)"
                    [disabled]="searchDisabled"
                    [debounce]="searchDebounce" placeholder="{{viewData?.title}}"
                    (keypress)="onKeyPressEventHandler($event.keyCode)"
                    show-clear-button="focus">
    </ion-searchbar>
    <div class="searchbar-overlay" *ngIf="shouldShowSearchBarFilterButton && !hideFilterButton">
      <ion-button tooltip="{{'FILTERS' | translate}}" class="searchbar-overlay-button" fill="clear" [disabled]="searchDisabled" (click)="_onFilterClick($event)">
        <ion-icon class="searchbar-overlay-button-filter-icon" slot="icon-only" src="assets/imgs/header_filter.svg"></ion-icon>
        <span *ngIf="filterButtonBadgeCount > 0" class="searchbar-filter-badge">{{ filterButtonBadgeCount }}</span>
      </ion-button>
    </div>
  </div>

  <!-- when searchbar is hidden, only title should display. -->
  <div *ngIf="searchHidden">
    <p class="ion-header-left-title">{{viewData?.title}}</p>
  </div>

  <ion-buttons class="end-slot" *ngIf="viewData?.mode; else hasdone" slot="end">
    <ion-button *ngFor="let button of viewData?.controls | filterBy: buttonsFilter.right" icon-only
                (click)="onButtonClick(button)" [disabled]="button.isDisabled"
                [tooltip]="button.tooltip" [display]="button.tooltip ? true : false">
      <ion-icon *ngIf="button.icon || button.imgSrc; else rightTextBtn1" name="{{button.icon}}" class="{{button.cssClass}}" src="{{button.imgSrc}}"></ion-icon>
      <ng-template #rightTextBtn1>
        {{button.name}}
      </ng-template>
    </ion-button>
  </ion-buttons>
  <ng-template #hasdone>
    <ion-buttons class="end-slot" slot="end">
      <ion-button *ngIf="viewData?.controls?.length > 1" (click)="onButtonClick(viewData?.controls[1])" [disabled]="viewData?.controls[1]?.isDisabled">
        <ion-icon *ngIf="viewData?.controls[1]?.icon || viewData?.controls[1]?.imgSrc; else rightTextBtn2" name="{{viewData?.controls[1]?.icon}}" class="{{viewData?.controls[1]?.cssClass}}" src="{{viewData?.controls[1]?.imgSrc}}"></ion-icon>
        <ng-template #rightTextBtn2>
          {{viewData?.controls[1]?.name}}
        </ng-template>
      </ion-button>
    </ion-buttons>
  </ng-template>
</ion-toolbar>
<ng-container *ngIf="backgroundUploadInProgress || syncInProgress">
  <ion-progress-bar mode="ios" class="left-progress-bar" type="indeterminate"></ion-progress-bar>
  <div class="in-progress">
    <ion-spinner></ion-spinner>
    <span class="secondary-text">{{'UPLOAD_IN_PROGRESS' | translate}}</span>
  </div>
</ng-container>
