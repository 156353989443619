
import { ConfirmationAlertData } from "../classes/shared/confirmation-alert-data.class";
import { MainCardViewDataModel } from "./MainCardViewDataModel";
import { DynamicFormComponentModel } from "./dynamic-form-component.model";
import { IndTabsDataModel } from "./ind-tabs-data-model";

export enum MainToolTemplateListSelectionType {
    SINGLESELECTION = 'single-selection',
    MULTIPLESELECTION = 'multiple-selection',
}

/**
 * Interface representing metadata for the main tool template detail.
 * @interface
 */
export interface MainToolTemplateDetail {
  /** Array of data for the main card view */
  data?: Array<MainCardViewDataModel>;

  /** Title for the main tool template */
  title: string;

  /** Title for the divider */
  dividerTitle: string;

  /** Flag indicating whether to show left header button */
  showLeftHeaderButton?: boolean;

  /** Property name for ordering */
  orderByPropertyName?: string;

  /** Text for the left header button */
  leftHeaderBtnText?: string;

  /** Source URL for the left header button image */
  leftHeaderBtnImgSrc?: string;

  /** Flag indicating whether to show right header button */
  showRightHeaderButton?: boolean;

  /** Text for the right header button */
  rightHeaderBtnText?: string;

  /** Source URL for the right header button image */
  rightHeaderBtnImgSrc?: string;

  /** Flag indicating whether search is enabled */
  isSearchEnabled?: boolean;

  /** Title for the search */
  searchTitle?: string;

  /** Minimum length for search */
  minSearchLength?: number;

  /** Flag indicating whether list selection is enabled */
  isListSelectionEnabled?: boolean;

  /** Flag indicating whether to hide all items list */
  hideAllItemsList?: boolean;

  /** Type of list selection */
  listSelectionType?: MainToolTemplateListSelectionType;

  /** Flag indicating whether confirmation check is required */
  confirmationCheckRequired?: boolean;

  /** Data for confirmation alert */
  confirmationAlertData?: ConfirmationAlertData;

  /** Navigation options */
  navOptions?: any;

  /** Flag indicating whether to update option for team member */
  updateOptionForTeamMember?: boolean;

  /** Flag indicating whether to prevent deselect */
  preventDeSelect?: boolean;
  
  /** Flag indication whether to show popover filter */
  isFilterPopoverActive?: boolean;

  /** data for popover filter */
  filterPopoverData?: { text: string; value: string; items: any; handler: (selectedItem: any, item: any, itemRef: any) => void; }[];
  
  /** Flag indication whether to show ind-filter-menu filter */
  isFilterMenuActive?: boolean;

  /** Flag indication whether the selected section is collapsable */
  isSelectedSectionCollapsible?: boolean;

  /** Flag indication whether to show detail screen on the right side*/
  isDetailPageEnabled?: boolean;

  /** fetching query for the detail page */
  detailPageFetchingQuery?: string;

  /** indication what list this is for */
  isFor?: string

  /** indication what css to be used in MainCard Component */
  passOverLabelCssToMainCard?: string

  /** Flag indication whether to show key control area*/
  enableKeyControlArea?: boolean;

  /** Event handler function */
  eventsHandler?: (data: any, eventTarget: string, refData?: MainToolTemplateDetail, formDetail?: DynamicFormComponentModel) => void;

  /** Search handler function */
  searchHandler?: (searchText: string) => string[];

  /** Search button handler function in Key control area*/
  searchButtonHandler?: (searchText: string) => void;

  /** tab change handler function */
  tabChangeHandler?: (changedTab: string) => MainToolTemplateDetail;
  isTabEnabled?: boolean;
  tabData?: IndTabsDataModel[];
}

