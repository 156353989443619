import { ProcedureTracker } from './../../../classes/activity/procedure-tracker.activity.class';
import { OrderContact } from '@omni/classes/activity/surgery-order.activity.class';
import { ProcedureTrackerActivity } from './../../../classes/activity/procedure-tracker.activity.class';
import { ProcedureTrackerActivityDataService } from './../../../data-services/procedure-tracker-activity/procedure-tracker-activity.data.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { ProcedureTrackerComponent } from './../procedure-tracker/procedure-tracker';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { IonNav, LoadingController, ModalController, PopoverController } from "@ionic/angular";
import { TranslateService } from '@ngx-translate/core';
import { Account } from '@omni/classes/account/account.class';
import { Contact } from '@omni/classes/contact/contact.class';
import { IONote } from '@omni/classes/io/io-note.class';
import { AccountPageComponent } from "@omni/components/account/account-page/account-page";
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { OperationDetail } from '@omni/data-services/follow-up-activity/follow-up-activity.data.service';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { FormFieldType, IndFormFieldViewDataModel } from "@omni/models/indFormFieldDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { AccountOfflineService, AccountSelectedFor } from "@omni/services/account/account.offline.service";
import { ActivityService } from "@omni/services/activity/activity.service";
import { AlertService } from "@omni/services/alert/alert.service";
import { AuthenticationService } from "@omni/services/authentication.service";
import { DateTimeFormatsService } from "@omni/services/date-time-formats/date-time-formats.service";
import { DeviceService } from '@omni/services/device/device.service';
import { FooterService, FooterViews } from "@omni/services/footer/footer.service";
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { TrackingEventNames, TrackService } from "@omni/services/logging/tracking.service";
import { ChildNavNames, NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { ComponentViewMode, UIService } from "@omni/services/ui/ui.service";
import { addMinutes, format, isBefore, isFuture, isToday, subDays } from "date-fns";
import _ from 'lodash';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IndPageTitleViewDataModel } from '../../../models/indPageTitleDataModel';
import { EventsService } from '../../../services/events/events.service';
import { AgendaFooterService } from '../../../services/footer/agenda-footer.service';
import { NotificationService, ToastStyle } from '../../../services/notification/notification.service';
import { DynamicsClientService } from '@omni/data-services/dynamics-client/dynamics-client.service';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { SelectListComponent } from '@omni/components/shared/select-list/select-list';
import { Guid } from 'typescript-guid';
import { OpportunityManagementService } from '@omni/services/opportunity-management/opportunity-management.service';
import { OpportunitySelectionViewDataModel } from '@omni/models/opportunities-selection-page-model';
import { Opportunity } from '@omni/classes/opportunity-management/opportunity.class';
import { OpportunitySelectionPageComponent } from '@omni/components/opportunity-management/opportunity-selection-master-page/opportunity-selection-master-page';

@Component({
  selector: 'procedure-tracker-details',
  templateUrl: 'procedure-tracker-details.html',
  styleUrls: ['procedure-tracker-details.scss']
})
export class ProcedureTrackerDetailsComponent {

  @Input() from: any;
  @Input() activity: any;
  public currentProcedureTrackerActivity: ProcedureTrackerActivity;
  private isLayoverPushView: boolean;
  private layoverViewPushedFrom: string;
  public isReadOnlyMode = true;
  public isCompleteEnabled = false;
  public procedureTrackerDetailsPanePageTitle: IndPageTitleViewDataModel;
  public detailsHeader: IndSectionHeaderViewDataModel;
  public quantitiesHeader: IndSectionHeaderViewDataModel;
  public orderNumberFormField: IndFormFieldViewDataModel;
  public createdByFormField: IndFormFieldViewDataModel;
  public modifiedDateFormField: IndFormFieldViewDataModel;
  public modifiedByFormField: IndFormFieldViewDataModel;
  public divisionFormField: IndFormFieldViewDataModel;
  public accountFormField: IndFormFieldViewDataModel;
  public coOwnersFormField: IndFormFieldViewDataModel;
  public isInMeetingProcedureTrackerFlow: boolean = false;
  public notesHeaderModel: IndSectionHeaderViewDataModel;
  public procedureTrackerHeaderModel: IndSectionHeaderViewDataModel;
  public notesPlaceholder: string = '';
  public isSaveNotesEnabled: boolean = false;
  public tempNoteText: string = '';
  public OpportunityFormField: IndFormFieldViewDataModel;
  // date time picker
  public startTime: string;
  public endTime: string;
  public prevStartTime: string;
  public prevEndTime: string;
  public startDateField: IndDateTimeFormViewDataModel;
  public startTimeField: IndDateTimeFormViewDataModel;
  public endDateField: IndDateTimeFormViewDataModel;
  public endTimeField: IndDateTimeFormViewDataModel;
  public durationField: IndDateTimeFormViewDataModel;
  private startDateTimeValue: string;
  private startDateValue: string;
  private startTimeValue: string;
  private endDateTimeValue: string;
  private endDateValue: string;
  private endTimeValue: string;
  private duration: string;
  public minStartDate: string;
  private ngDestroy$ = new Subject<boolean>();
  dateFormatSubscription: Subscription;
  public backgroundUploadInProgress: boolean = false;
  public globalCustomerText: string;
  private _isClickedAccount: boolean = false;
  public isWeeklyFlag: boolean = false;
  private isTeamMember: boolean = false;
  public applicableOpportunities: Opportunity[] = [];
  public isHideOpportunity : boolean = false;
  public openOpportunityDynamic : boolean = false;
  public associateMultipleOpportunities : boolean = false;
  public opportunityDynamicUrl : string ;
  public meetingOpportunitiesSectionId : string = 'procedureOpportunitiesSectionId';
  public multipleOpportunitiesSectionTitle : IndSectionHeaderViewDataModel ;
  constructor(
    private readonly authService: AuthenticationService,
    public readonly footerService: FooterService,
    private readonly alertService: AlertService,
    private readonly accountService: AccountOfflineService,
    private readonly agendaFooterService: AgendaFooterService,
    public activityService: ActivityService,
    public translate: TranslateService,
    private trackingService: TrackService,
    private _CD: ChangeDetectorRef,
    public navService: NavigationService,
    private popoverCtrl: PopoverController,
    public dateTimeFormatsService: DateTimeFormatsService,
    public uiService: UIService,
    public device: DeviceService,
    private procedureTrackerDataService: ProcedureTrackerActivityDataService,
    private events: EventsService,
    private notifications: NotificationService,
    private loadingController: LoadingController,
    public modalCtrl: ModalController,
    public navCtrl: IonNav,
    private datePipe: DatePipe,
    private utilityService: GlobalUtilityService,
    public opportunityService: OpportunityManagementService,
  ) {
    this.dateFormatSubscription = this.dateTimeFormatsService.dateFormat.subscribe(df => {
      if (df) {
        this.initDateTimeFormFields();
      }
    });

    this.events.observe('updateMandatoryField').pipe(takeUntil(this.ngDestroy$)).subscribe((field: PageName) => {
      this._updateMandatoryField(field);
    });
  }

  ngOnInit() {
    this.isWeeklyFlag = (this.authService.user.procedureLogDuration === 'Weekly');
    this.isHideOpportunity = this.authService.user.buSettings["indskr_displayopportunity"];
    this.associateMultipleOpportunities = this.authService.user.buSettings['indskr_associatemultipleopportunitiestoprocedure'] ;
    this.openOpportunityDynamic = this.authService.user.buSettings['indskr_openopportunityindynamics'] ;
    this.opportunityDynamicUrl = this.authService.user.buSettings['indskr_opportunityurl'] ;
    this.associateMultipleOpportunities = this.authService.user.buSettings['indskr_associatemultipleopportunitiestoprocedure'] ;
    if (this.from) {
      this.isLayoverPushView = true;
      this.layoverViewPushedFrom = this.from;
      if (this.layoverViewPushedFrom === 'InMeetingProcedure') {
        this.procedureTrackerDataService.isInMeetingProcedureTrackerFlow = true;
      }
      if (this.layoverViewPushedFrom === 'PresentationMeetingComponent') {
        this.isInMeetingProcedureTrackerFlow = true;
      } else {
        this.procedureTrackerDataService.inMeetingProcedureTrackerActivity = null;
      }
    }
    this.currentProcedureTrackerActivity = this.activityService.selectedActivity as ProcedureTrackerActivity
    if (this.activity) {
      this.currentProcedureTrackerActivity = this.activity;
    }
    if(!_.isEmpty(this.currentProcedureTrackerActivity.opportunities)){
      this.applicableOpportunities =[];
      this.currentProcedureTrackerActivity.opportunities.forEach(x=>{
        if(!x['deleted'] || !x.hasOwnProperty('deleted')){
          let foundOpportunity = this.opportunityService.getOpportunityDetailsbyId(x.indskr_opportunity || x['opportunityId']);
          this.applicableOpportunities.push(foundOpportunity); 
        }      
      })
    }

    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        this.globalCustomerText = this.translate.instant('CUSTOMER');
        break;
      case 'Stakeholder':
        this.globalCustomerText = this.translate.instant('STAKEHOLDER');
        break;
      default:
        this.globalCustomerText = this.utilityService.globalCustomerText;
        break;
    }
    let dt = new Date().setHours(0, 0);
    this.minStartDate = format(subDays(dt, this.authService.user.maxMeetingStartDays));

    this.startTime = moment(this.currentProcedureTrackerActivity.scheduledStart).format();
    this.endTime = moment(this.currentProcedureTrackerActivity.scheduledEnd).format();

    // Update start/end time field with selected time from the time picker
    this.dateTimeFormatsService.updateSelectedTimeSurgeryOrder$.pipe(takeUntil(this.ngDestroy$)).subscribe((timeData) => {
      if (timeData) {
        if (timeData.id == DateTimeFieldType.StartTimeField) {
          this.startTime = timeData.time;
          setTimeout(() => {
            this.handleStartDateTimeValue();
          }, 0);
        } else if (timeData.id == DateTimeFieldType.EndTimeField) {
          this.endTime = timeData.time;
          setTimeout(() => {
            this.handleEndDateTimeValue();
          }, 0);
        }
      }
    });
    this.isTeamMember = this.currentProcedureTrackerActivity.coOwners?.some(coOwner => coOwner.userId === this.authService.user.systemUserID) && this.authService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG_CO_OWNER);
    this.initAllDataModels();
    this.trackingService.tracking('OrderInfo', TrackingEventNames.SURGERY_ORDER);
    this.device.isOfflineObservable.pipe(takeUntil(this.ngDestroy$)).subscribe((data) => {
      this.getPageTitle();
      this.initFooterViewButtons();
    });
    this.device.isBackgroundUploadInProgressObservable.pipe(takeUntil(this.ngDestroy$)).subscribe(inProgress => {
      if (this.currentProcedureTrackerActivity) {
        this.backgroundUploadInProgress = inProgress && this.currentProcedureTrackerActivity.pendingPushToDynamics;
        this.initFooterViewButtons();
        this.initAllDataModels();
        this.getPageTitle();
        this.getDetailsHeader();
        this.initProcedureNotesModel();
        this.initPeriodTrackerHeaderModel();
      }
    });
    this.events.observe('sync:completed').pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      this.isTeamMember = this.currentProcedureTrackerActivity.coOwners?.some(coOwner => coOwner.userId === this.authService.user.systemUserID) && this.authService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG_CO_OWNER);
      this.initFooterViewButtons();
        this.initAllDataModels();
    })
  }

  ngDoCheck() {
    if(!_.isEmpty(this.currentProcedureTrackerActivity.opportunities)){
      this.applicableOpportunities =[];
      this.currentProcedureTrackerActivity.opportunities.forEach(x=>{
        if(!x['deleted'] || !x.hasOwnProperty('deleted')){
          let foundOpportunity = this.opportunityService.getOpportunityDetailsbyId(x.indskr_opportunity || x['opportunityId']);
          this.applicableOpportunities.push(foundOpportunity); 
        }      
      })
    }
    if (this.procedureTrackerDataService.isSelectedProcedureTrackerActivityUpdated) {
      if (!this.activityService.selectedActivity) {
        this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView);
        this.procedureTrackerDataService.isSelectedProcedureTrackerActivityUpdated = false;
        return;
      }
      this.currentProcedureTrackerActivity = this.activityService.selectedActivity as ProcedureTrackerActivity;
      this.initAllDataModels();
      this.procedureTrackerDataService.isSelectedProcedureTrackerActivityUpdated = false;
    }
    if (this.activityService.selectedActivity instanceof ProcedureTrackerActivity) {
      if (this.navService.getCurrentMasterPageName() === PageName.ActivitiesPageComponent && this.navService.getActiveChildNavViewPageName() === PageName.ProcedureTrackerDetailsComponent) {
        this.footerService.initButtons(FooterViews.PROCEDURE_TRACKER_ACTIVITY);
        this.initFooterViewButtons();
      }
    }
    if (this.from && this.from == 'InMeetingProcedure' && this.procedureTrackerDataService.isInMeetingProcedureTrackerFlow
      && this.navService.getActiveChildNavViewPageName() == PageName.ActivitiesDetailsPaneComponent) {
      this.initFooterViewButtons();
    }
  }

  ngAfterContentChecked() {
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.dateFormatSubscription.unsubscribe();
  }

  public get isScrapButtonEnabled(): boolean {
    let flag: boolean = false;
    if (!this.device.isOffline && this.currentProcedureTrackerActivity.procedureTrackerStatusString === 'Draft'
      && (this.isTeamMember || this.activityService.selectedActivity.ownerId == this.authService.user.systemUserID)) {
      flag = true;
    }
    return flag;
  }

  public get isReopenButtonEnabled(): boolean {
    let flag: boolean = false;
    if (!this.device.isOffline && this.currentProcedureTrackerActivity.procedureTrackerStatusString === 'Completed'
      && (this.isTeamMember || this.activityService.selectedActivity.ownerId == this.authService.user.systemUserID)
    ) {
      flag = true;
    }
    return flag;
  }

  public get isCompleteButtonEnabled(): boolean {
    let flag: boolean = false;
    if (this.currentProcedureTrackerActivity.procedureTrackerStatusString === 'Draft'
      && (this.isTeamMember || this.activityService.selectedActivity.ownerId == this.authService.user.systemUserID)
      && this.currentProcedureTrackerActivity.accountId
      && !this.device.isOffline
      && this.currentProcedureTrackerActivity.procedureTrackers.length > 0) {
      flag = true;
    }
    return flag;
  }

  private get _enableBackButton(): boolean {
    return window.innerWidth < 500 || (this.from && (this.from === 'AccountTimeline' ||
      this.from === 'ContactTimeline' ||
      this.from === 'InMeetingProcedure' ||
      this.from === 'ProcedureLogFromContactTool' ||
      this.from === 'ProcedureLogFromAccountTool' ||
      this.from === 'OpportunityManagementTool' || 
      this.from === 'MarketingPlanOpportunities'
    ));
  }

  initAllDataModels() {
    this.initFooterViewButtons();
    this.getPageTitle();
    this.getDetailsHeader();
    this.getOrderNumberFormField();
    this.getCreatedByFormField();
    this.getModifiedDateFormField();
    this.getModifiedByFormField();
    this.getDivisionFormField();
    this.getAccountFormField();
    this.getCoOwnersFormField();
    this.initDateTimeFormFields();
    this._CD.detectChanges();
    this.initProcedureNotesModel();
    this.initPeriodTrackerHeaderModel();
    this.getOpportunityFormField();
    this.multipleOpportunitiesSectionHeader();
  }

  public getPageTitle() {
    let buttons = [];
    if (this._enableBackButton) {
      buttons = [{
        id: 'close',
        icon: 'chevron-back-outline',
        isDisabled: false,
        align: 'left'
      }];
    }
    buttons.push(
      {
        id: "reopenproceduretrackeractivity",
        imgSrc: 'assets/imgs/header_activity_reopen.svg',
        name: this.translate.instant('REOPEN'),
        isDisabled: this.backgroundUploadInProgress ? true : !this.isReopenButtonEnabled,
        align: "right"
      },
      {
        id: "scrapproceduretrackeractivity",
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('SCRAP'),
        isDisabled: this.backgroundUploadInProgress ? true : !this.isScrapButtonEnabled,
        align: "right"
      },
      {
        id: "markcomplete",
        imgSrc: 'assets/imgs/header_complete.svg',
        name: this.translate.instant('COMPLETE'),
        isDisabled: this.backgroundUploadInProgress ? true : !this.isCompleteButtonEnabled,
        align: "right"
      },
    );
    this.procedureTrackerDetailsPanePageTitle = {
      id: 'activities-details-page-title',
      title: this.currentProcedureTrackerActivity.subject,
      controls: buttons
    };
  }

  public getDetailsHeader() {
    let buttons = [];
    if (this.isInMeetingProcedureTrackerFlow) {
      buttons.push(
        {
          id: "reopenproceduretrackeractivity",
          text: this.translate.instant('REOPEN'),
          isDisabled: this.backgroundUploadInProgress ? true : !this.isReopenButtonEnabled,
        },
        {
          id: 'scrapproceduretrackeractivity',
          text: this.translate.instant('SCRAP'),
          isDisabled: this.backgroundUploadInProgress ? true : !this.isScrapButtonEnabled,
        },
        {
          id: 'markcomplete',
          text: this.translate.instant('COMPLETE'),
          isDisabled: this.backgroundUploadInProgress ? true : !this.isCompleteButtonEnabled,
        });
    }
    this.detailsHeader = {
      id: 'details-header',
      title: this.translate.instant('DETAILS'),
      controls: buttons,
    };
  }

  private initFooterViewButtons(): boolean {
    if (!this.currentProcedureTrackerActivity) return;
    if (this.isInMeetingProcedureTrackerFlow) {
      this.footerService.initButtons(FooterViews.PREVIEW_MEETING_ACTIVITY);
    }

    switch (this.currentProcedureTrackerActivity.procedureTrackerStatusString) {
      case 'Draft':
        if (!this.isInMeetingProcedureTrackerFlow) {
          this.footerService.initButtons(FooterViews.PROCEDURE_TRACKER_ACTIVITY);
          let btnsArr = [];
          if (!this.isCompleteButtonEnabled || this.backgroundUploadInProgress) btnsArr.push('markCompleteProcedureTrackerActivity');
          if (!this.isScrapButtonEnabled || this.backgroundUploadInProgress) btnsArr.push('scrapProcedureTrackerActivity');
          if (!this.isReopenButtonEnabled || this.backgroundUploadInProgress) btnsArr.push('reopenproceduretrackeractivity');
          this.footerService.disableButton(btnsArr);
        }
        this.isReadOnlyMode = false;
        break;
      case 'Completed':
        if (!this.isInMeetingProcedureTrackerFlow) {
          this.footerService.initButtons(FooterViews.PROCEDURE_TRACKER_ACTIVITY);
          let btnsArr = ['scrapProcedureTrackerActivity', 'markCompleteProcedureTrackerActivity'];
          if (!this.isReopenButtonEnabled || this.backgroundUploadInProgress) btnsArr.push('reopenproceduretrackeractivity');
          this.footerService.disableButton(btnsArr);
        }
        this.isReadOnlyMode = true;
        break;
      default:
        if (!this.isInMeetingProcedureTrackerFlow) {
          this.footerService.initButtons(FooterViews.PROCEDURE_TRACKER_ACTIVITY);
        }
        break;
    }
    if (!(this.isTeamMember || this.currentProcedureTrackerActivity.ownerId === this.authService.user.systemUserID) || this.device.isOffline) {
      this.isReadOnlyMode = true;
    }
  }

  public getOrderNumberFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('RECORD_ID'),
      inputText: this.currentProcedureTrackerActivity.ordernumber,
      id: 'order-number-field',
      isReadOnly: true,
      isDisabled: true,
    };
    this.orderNumberFormField = viewData;
  }

  public getCreatedByFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('CREATED_BY'),
      inputText: this.currentProcedureTrackerActivity.ownerNameString,
      id: 'created-by-field',
      isReadOnly: true,
      isDisabled: true,
    };
    this.createdByFormField = viewData;
  }
  private multipleOpportunitiesSectionHeader() {
    this.multipleOpportunitiesSectionTitle = {
      id: 'multipleopportunities',
      title: this.translate.instant('OPPORTUNITIES'),
      controls: [
        {
          id: 'add-opportunities',
          text: this.translate.instant('ADD'),
          isDisabled: this.isReadOnlyMode//this.isReadOnlyMode//!(!this.activityService.selectedActivity.isCothis.isReadOnlyModempleted && !this.isReadOnlyRecommendedMeeting && !this.enableReadonlyViewForMeetings  && this.authService.hasFeatureAction(FeatureActionsMap.ASSOCIATE_OPPORTUNITIES_TO_MEETINGS)) ||  this.activityService.selectedActivity.state == MeetingActivityState.Canceled
        }
      ]
    };
  }

  public getModifiedDateFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('LAST_MODIFIED_DATE'),
      inputText: this.getFormattedDateTime(this.currentProcedureTrackerActivity.modifiedon),
      id: 'modified-date-field',
      isReadOnly: true,
      isDisabled: true,
    };
    this.modifiedDateFormField = viewData;
  }

  public getModifiedByFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('LAST_MODIFIED_BY'),
      inputText: this.currentProcedureTrackerActivity.modifiedByName,
      id: 'modified-by-field',
      isReadOnly: true,
      isDisabled: true,
    };
    this.modifiedByFormField = viewData;
  }

  public getDivisionFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('DIVISION'),
      inputText: this.currentProcedureTrackerActivity.divisionName,
      id: 'division-field',
      isReadOnly: true,
      isDisabled: true,
    };
    this.divisionFormField = viewData;
  }

  private initDateTimeFormFields() {
    let dateTimeValue = this.getSurgeryOrderTimeText();
    if (dateTimeValue) {
      this.startDateTimeValue = dateTimeValue.startDateTime;
      this.startDateValue = dateTimeValue.startDate;
      this.startTimeValue = dateTimeValue.startTime;
      this.endDateTimeValue = dateTimeValue.endDateTime;
      this.endDateValue = dateTimeValue.endDate;
      this.endTimeValue = dateTimeValue.endTime;
      this.duration = dateTimeValue.duration;
    }
    this.startDateField = {
      isDateField: true,
      label: this.translate.instant('START_DATE'),
      inputText: this.startDateValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.SurgeryOrder,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: !this.isReadOnlyMode,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.startTimeField = {
      isTimeField: true,
      label: this.translate.instant('START_TIME'),
      inputText: this.startTimeValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.SurgeryOrder,
      id: DateTimeFieldType.StartTimeField,
      isReadOnly: true,
      isDisabled: (this.backgroundUploadInProgress || this.isWeeklyFlag) ? true : this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: !this.isReadOnlyMode,
    };
    this.endDateField = {
      isDateField: true,
      label: this.translate.instant('END_DATE'),
      inputText: this.endDateValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.SurgeryOrder,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: (this.isReadOnlyMode || this.isWeeklyFlag),
      showArrow: !this.isReadOnlyMode,
      isRequired: !this.isReadOnlyMode,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.endTimeField = {
      isTimeField: true,
      label: this.translate.instant('END_TIME'),
      inputText: this.endTimeValue,
      startDateTimeValue: this.startDateTimeValue,
      endDateTimeValue: this.endDateTimeValue,
      fromViewPage: CurViewPageType.SurgeryOrder,
      id: DateTimeFieldType.EndTimeField,
      isReadOnly: true,
      isDisabled: (this.backgroundUploadInProgress || this.isWeeklyFlag) ? true : this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: !this.isReadOnlyMode,
    };
  }

  private getSurgeryOrderTimeText = (): any => {
    if (!this.currentProcedureTrackerActivity) return;
    let startDateTimeValue: Date = new Date(this.currentProcedureTrackerActivity.scheduledStart);
    let endDateTimeValue: Date = new Date(this.currentProcedureTrackerActivity.scheduledEnd);
    let startDayValue = this.datePipe.transform(startDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let endDayValue = this.datePipe.transform(endDateTimeValue, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
    let startTimeValue = startDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    let endTimeValue = endDateTimeValue.toLocaleTimeString('en-US', { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    let formattedDuration: string = this.dateTimeFormatsService.getFormattedTimeInterval(startDateTimeValue, endDateTimeValue);

    let dateTimeValue: any = {
      startDateTime: '',
      startDate: '',
      startTime: '',
      endDateTime: '',
      endDate: '',
      endTime: '',
      duration: '',
    };

    dateTimeValue = {
      startDateTime: startDateTimeValue,
      startDate: startDayValue,
      startTime: startTimeValue,
      endDateTime: endDateTimeValue,
      endDate: endDayValue,
      endTime: endTimeValue,
      duration: formattedDuration,
    };
    return dateTimeValue;
  }

  public _customerString(customers): string {
    let str = '';
    if (customers) {
      if (customers.length === 1) {
        str = `${customers[0].fullname}`;
        if (customers[0].statusCode !== 1 && customers[0].statusCode !== 548910000) {
          str += ' (Inactive)';
        }
      } else if (customers.length >= 2) {
        customers.sort((a, b): number => {
          if (a.fullname > b.fullname) return 1;
          if (a.fullname < b.fullname) return -1;
          return 0;
        });
        let frstStr = '';
        let scndStr = '';
        if (customers[0].statusCode !== 1 && customers[0].statusCode !== 548910000) {
          frstStr = ' (Inactive)';
        } else if (customers.some(a => a.statusCode !== 1 && a.statusCode !== 548910000)) {
          scndStr = ' (Inactive)';
        }
        str = `${customers[0].fullname}${frstStr} +${customers.length - 1}${scndStr}`;
      }
    }
    return str;
  }

  public getAccountFormField() {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNT'),
      inputText: (this.currentProcedureTrackerActivity.accountNameString) ? this.currentProcedureTrackerActivity.accountNameString + (this.currentProcedureTrackerActivity.isAccountActive ? '' : ' (Inactive)') : '',
      placeholderLabel: (this.isReadOnlyMode && !this.currentProcedureTrackerActivity.accountId) ? this.translate.instant('NO_ACCOUNT') : this.translate.instant('ACCOUNT'),
      id: 'account-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode || (!_.isEmpty(this.currentProcedureTrackerActivity.procedureTrackers) && this.currentProcedureTrackerActivity.accountId !== ""),
      showArrow: !this.isReadOnlyMode && _.isEmpty(this.currentProcedureTrackerActivity.procedureTrackers) ||  (!_.isEmpty(this.currentProcedureTrackerActivity.procedureTrackers) && this.currentProcedureTrackerActivity.accountId == ""),
      isRequired: !this.isReadOnlyMode,
      errorMessage: this.activityService.getErrorMessageRequiredField(),
      isEmptyRequiredField: this._isClickedAccount && !this.currentProcedureTrackerActivity.accountId ? true : false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.accountFormField = viewData;
  }

  public getCoOwnersFormField() {
    const userNames = _.orderBy(this.currentProcedureTrackerActivity.coOwners?.map(coOwner => coOwner.userFullName));
    let viewData: IndFormFieldViewDataModel;
    const isDisabled = this.device.isOffline && _.isEmpty(userNames);
    viewData = {
      label: this.translate.instant('CO_OWNERS'),
      inputText: _.isEmpty(userNames) ? '' : userNames[0] + (userNames.length > 1 ? ' +' + (userNames.length - 1) : ''),
      customPlaceholderLabel: !isDisabled ? this.translate.instant('SELECT_CO_OWNERS') : this.translate.instant("NO_CO_OWNERS"),
      id: 'co-owner-field',
      isReadOnly: true,
      isDisabled: isDisabled,
      showArrow: !isDisabled,
      isHidden: _.isEmpty(userNames) && !this.authService.hasFeatureAction(FeatureActionsMap.PROCEDURE_LOG_CO_OWNER),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    this.coOwnersFormField = viewData;
  }

  private handleFormFieldEvent(id, event, eventName) {
    if (id) {
      if (this.backgroundUploadInProgress) return;
      switch (id) {
        case 'start-date-field':
          this.openStartDatePicker(event);
          break;
        case 'end-date-field':
          this.openEndDatePicker(event);
          break;
        case 'account-field':
          this._isClickedAccount = true;
          this.openAccountSelect();
          break;
        case 'co-owner-field':
          this.openCoOwnerSelectionPage();
          break;
        case 'opportunity-field':
          this.handleOpportunityField();
          break;
        default:
          console.log('Unhandled switch case statement');
          break;
      }
    }
  }

  private async openCoOwnerSelectionPage() {
    let data = [];
    if (this.procedureTrackerDataService.users) {
      data = this.procedureTrackerDataService.users.map(user => {
        // if(user.userId != this.authService.user.xSystemUserID){
          return {
            id: user.userId,
            title: user.userFullName,
            isSelected: this.currentProcedureTrackerActivity.coOwners?.some(coOwner => coOwner.userId === user.userId)
          }
        // }
      })
    }
    if (this.currentProcedureTrackerActivity.coOwners?.some(coOwner => coOwner.userId === this.authService.user.systemUserID)) {
      data.push({
        id: this.authService.user.systemUserID,
        title: this.authService.user.displayName,
        isSelected: true,
        isReadOnly: true
      });
      data = _.orderBy(data, 'title');
    }
    data = data.filter((user) => user.id !== this.authService.user.systemUserID)
    const coOwnersModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: data,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('USERS'),
          dividerTitle: this.translate.instant('ALL_USERS'),
          isSearchEnabled: true,
          isReadOnly: this.isReadOnlyMode
        }
      },
      backdropDismiss: false
    });
    coOwnersModal.present();
    coOwnersModal.onDidDismiss().then(async (obj) => {
      if(this.device.isOffline || this.backgroundUploadInProgress) return;
      if (obj && obj.data && obj.data.isDone) {
        const data = obj.data;
        if (_.isEmpty(data.selectedItems)) {
          if (!_.isEmpty(this.currentProcedureTrackerActivity.coOwners)) {
            this.currentProcedureTrackerActivity.coOwners.forEach(coOwner => {
              coOwner['deleted'] = true;
            });
          }
        } else {
          this.currentProcedureTrackerActivity.coOwners?.forEach(coOwner => {
            const index = data.selectedItems.findIndex(item => item.id == coOwner.userId);
            if (index == -1) coOwner['deleted'] = true;
          });
          data.selectedItems.forEach(item => {
            const index = this.currentProcedureTrackerActivity.coOwners?.findIndex(coOwner => item.id == coOwner.userId);
            if(index == -1) {
              this.currentProcedureTrackerActivity.coOwners.push({
                userId: item.id,
                userFullName: item.title,
                indskr_procedurelogcoownerid: Guid.create().toString()
              })
            }
          })
        }
        if (!_.isEmpty(this.currentProcedureTrackerActivity.coOwners)) {
          const action = {
            onDynamics: !this.device.isOffline,
            onLocalDatabase: true,
            onLocalCopy: true,
            operationDetail: {
              code: 'coOwnerUpdate',
              message: 'coOwnerUpdate',
            }
          };
          await this.uiService.displayLoader();
          let hasOfflineChanges = this.currentProcedureTrackerActivity.pendingPushToDynamics || false;
          this.currentProcedureTrackerActivity.pendingPushToDynamics = true;
          await this.procedureTrackerDataService.updateOrderActivity(action, [this.currentProcedureTrackerActivity], new Date().getTime(), hasOfflineChanges);
          await this.uiService.dismissLoader();
        }
      }
    })
  }

  private openAccountSelect(): void {
    if (!this.isReadOnlyMode || this.currentProcedureTrackerActivity.accountId) {
      let accountId = '';
      const contactIds = [];
      if (this.currentProcedureTrackerActivity.accountId) accountId = this.currentProcedureTrackerActivity.accountId;

      let mode: any;
      if (!this.isReadOnlyMode) mode = ComponentViewMode.ADDNEW;
      else mode = ComponentViewMode.READONLY;
      this.accountService.selectedFor = AccountSelectedFor.GENERAL_SINGLE_SELECTION;
      this.accountService.accessedAccountListFrom = PageName.SurgeryOrderDetailsComponent;
      if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
        if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
          setTimeout(()=>{
            document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
          });
        }
        this.navCtrl.push(AccountPageComponent, {
          listMode: mode, type: 'InMeetingProcedurePushedAccountSelectionView',
          selectedAccountId: accountId, selectedContactIds: contactIds,
          callbackEvent: (data: any) => this._handleAccountComponentCallback(data)
        }, { progressAnimation: false });
      } else {
        this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent,
          {
            listMode: mode, type: 'PushedAccountSelectionView',
            selectedAccountId: accountId, selectedContactIds: contactIds,
            callbackEvent: (data: any) => this._handleAccountComponentCallback(data)
          },
          PageName.SurgeryOrderDetailsComponent, { animate: false }, null);
      }
    }
  }

  private async _handleAccountComponentCallback(data) {
    if (!this.isReadOnlyMode && data && (data.selectedItem || data.clearValue)) {
      if (data.clearValue) {
        data.selectedItem = [];
      } else {
        const procedureTrackerExists = await this.isDuplicateProceureTrackerExists(data.selectedItem, this.currentProcedureTrackerActivity.scheduledStart, this.currentProcedureTrackerActivity.scheduledEnd);
        if (procedureTrackerExists) return;
      }

      // To Add/Remove affiliated contacts for event
      let updateContactsForEventFlag = false;
      let contactsToUpdate: Contact[] = [];
      const alreadySelectedAccounts: Account[] = [];
      const foundAcc = this.accountService.getAccountById(this.currentProcedureTrackerActivity.accountId);
      if (foundAcc) alreadySelectedAccounts.push(foundAcc);

      this.currentProcedureTrackerActivity.accountId = data.selectedItem.id;
      this.currentProcedureTrackerActivity.accountNameString = data.selectedItem.name;
      if (this.authService.hasFeatureAction(FeatureActionsMap.AUTO_SUBJECT_PROCEDURE_TRACKER)) {
        this.currentProcedureTrackerActivity.subject = (data.selectedItem?.name) ? moment(this.currentProcedureTrackerActivity.scheduledStart).format('YYYY-MM-DD') + ' ' + data.selectedItem.name : 'Bulk Procedure Log';
      }
      this.currentProcedureTrackerActivity.isAccountActive = (data.statecode === 2 || data.statecode == 548910003 || data.statecode == 548910010 || data.statecode == 548910011 || data.statecode == 548910012 || data.statecode == 548910013) ? false : true;
      let operationDetailCode = 'updateaccounts';
      this._updateProcedureTrackerActivity(operationDetailCode);
      this.initFooterViewButtons();
      this.getAccountFormField();
      this.getPageTitle();
      this.initPeriodTrackerHeaderModel();
      if (this.from == "InMeetingProcedure" || this.isInMeetingProcedureTrackerFlow) {
        this.events.publish('updateEmbeddedActivity', this.currentProcedureTrackerActivity);
      }
    }else{
      this.initFooterViewButtons();
    }
  }

  public handleButtonClick(buttonId: string): void {
    let popupTitle = '';
    let popupMessage = '';
    let popupAction = '';
    if (buttonId != 'close' && this.backgroundUploadInProgress) return;
    switch (buttonId) {
      case 'close':
        this.onClosePage();
        return;
      case 'scrapproceduretrackeractivity': {
        if (!this.isScrapButtonEnabled) return;
        popupTitle = this.translate.instant('OM_SCRAP_TRACKER');
        popupMessage = this.translate.instant('R_U_SURE_SCRAP_TRACKER');
        popupAction = this.translate.instant('SCRAP');
        break;
      }
      case 'markcomplete': {
        if (!this.isCompleteButtonEnabled) return;
        popupTitle = this.translate.instant('ACTIVITY_COMPLETE_TRACKER');
        popupMessage = this.translate.instant('OM_ARE_SURE_COMPLETE_PL');
        popupAction = this.translate.instant('COMPLETE');
        break;
      }
      case 'save_note': {
        this.handleSaveNote();
        return;
      }
      case 'reopenproceduretrackeractivity': {
        if (!this.isReopenButtonEnabled) return;
        popupTitle = this.translate.instant('REOPEN_PROCDURE_TRACKER');
        popupMessage = this.translate.instant('OM_ARE_SURE_REOPEN_PT');
        popupAction = this.translate.instant('REOPEN');
        break;
      }
      case 'add-opportunities' : {
        this._handleAddMultipleOpportunities();
        return;
      }
      case 'add_procedure_tracker': {
        if (this.procedureTrackerDataService.isInMeetingProcedureTrackerFlow) {
          this.navCtrl.push(ProcedureTrackerComponent, { from: this.from, activity: this.currentProcedureTrackerActivity, callbackEvent: (data: any) => this._handleNewTrackerCallback(data) })
        } else {
          this.navService.pushChildNavPageWithPageTracking(ProcedureTrackerComponent, PageName.ProcedureTrackerComponent, PageName.ActivitiesPageComponent, { from: this.from, activity: this.currentProcedureTrackerActivity, callbackEvent: (data: any) => this._handleNewTrackerCallback(data) });
        }
        return;
      }
      default:
        return;
    }
    if (this.currentProcedureTrackerActivity) {
      this.alertService.showAlert({
        title: popupTitle,
        message: popupMessage
      }, popupAction
      ).then(res => {
        if (res.role === 'ok') {
          this.handleButtonEventForProcedureTrackerActivityAfterConfirmation(buttonId);
        }
      });
    }
  }

  private async _handleNewTrackerCallback(data: ProcedureTracker) {
    if (!this.device.isOffline) {
      this.uiService.displayLoader();
    }
    const res = await this.procedureTrackerDataService.updateProcedureTracker(data, this.currentProcedureTrackerActivity.ID);
    data.indskr_proceduretrackerid = res.indskr_proceduretrackerid;
    data.createdon = new Date().getTime() + '';
    let procedureTrackers = this.currentProcedureTrackerActivity.procedureTrackers;
    procedureTrackers.push(data);
    this.currentProcedureTrackerActivity.procedureTrackers = _.orderBy(procedureTrackers, ['createdon'], ["desc"]);
    this._CD.detectChanges();
    this._CD.markForCheck();
    await this._updateProcedureTrackerActivity('add_tracker');
  }

  private async _handleEditTrackerCallback(data: ProcedureTracker, deleted?: boolean){
    if (!this.device.isOffline) {
      await this.uiService.displayLoader();
    }
    if (deleted) {
      await this.scrapProcedureTracker(data);
    } else {
      await this.updateProcedureTracker(data);
    }
  }

  private async scrapProcedureTracker(data: ProcedureTracker) {
    this.procedureTrackerDataService.scrapProcedureTracker(data.indskr_proceduretrackerid).then(async () => {
      let idx = this.currentProcedureTrackerActivity.procedureTrackers.findIndex(e => e.indskr_proceduretrackerid == data.indskr_proceduretrackerid);
      if (idx > -1) {
        this.currentProcedureTrackerActivity.procedureTrackers.splice(idx, 1);
      }
      this._CD.detectChanges();
      this._CD.markForCheck();
      await this._updateProcedureTrackerActivity('delete_tracker');
    }).catch(e => {
      console.log("error occurred while scrapping bulk procedure log", e);
      this.uiService.dismissLoader();
    })
  }

  private async updateProcedureTracker(data: ProcedureTracker) {
    const payload = data.dto;
    payload['indskr_proceduretrackerid'] = data.indskr_proceduretrackerid;
    if (_.isEmpty(data.trackerContacts))
      payload['trackerContacts'] = [];
    this.procedureTrackerDataService.updateProcedureTrackerOnline(payload).then(async (res: ProcedureTracker) => {
      let idx = this.currentProcedureTrackerActivity.procedureTrackers.findIndex(e => e.indskr_proceduretrackerid == data.indskr_proceduretrackerid);
      if (idx > -1) {
        this.currentProcedureTrackerActivity.procedureTrackers[idx] = data;
      }
      this._CD.detectChanges();
      this._CD.markForCheck();
      await this._updateProcedureTrackerActivity('update_tracker');
    }).catch(e => {
      console.log("error occurred while updating bulk procedure log", e);
      this.uiService.dismissLoader();
    })
  }

  public onSectionHeaderControlClick(id: string) {
    this.handleButtonClick(id);
  }

  private async handleButtonEventForProcedureTrackerActivityAfterConfirmation(buttonId: string) {
    if (this.backgroundUploadInProgress) return;
    const action = {
      onDynamics: !this.device.isOffline,
      onLocalDatabase: true,
      onLocalCopy: true,
      operationDetail: {
        code: buttonId,
        message: this.translate.instant('OM_UPDATE_O_ACTIVITY'),
      }
    };
    const loader = await this.loadingController.create({});
    loader.present();
    this.currentProcedureTrackerActivity.pendingPushToDynamics = true;
    this.procedureTrackerDataService.updateOrderActivityStatus(action, this.currentProcedureTrackerActivity).then((succ) => {
      switch (buttonId) {
        case 'scrapproceduretrackeractivity': {
          this.activityService.publishActivityEvent({ action: "Delete", activity: this.currentProcedureTrackerActivity });
          const isLayovered = (this.layoverViewPushedFrom === 'InMeetingProcedure');
          this.events.publish('procedureTrackerActivityDeleted', this.currentProcedureTrackerActivity, isLayovered);
          if (this.layoverViewPushedFrom === 'InMeetingProcedure') {
            this.events.publish('deletedEmbeddedActivity', this.currentProcedureTrackerActivity);
            this.isInMeetingProcedureTrackerFlow = true;
          }
          if (this.from == 'AccountTimeline') {
            this.uiService.timelineRefreshRequired = true;
            this.uiService.agendaRefreshRequired = true;
          }
          if (this.isInMeetingProcedureTrackerFlow) {
            this.onClosePage();
          } else {
            if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
              this.onClosePage();
              this.activityService.selectedActivity = null;
              this.activityService.selectedActivityAtHome = null;
              this.events.publish('refreshAgenda');
            } else if (this.navService.getActiveChildNavName() === ChildNavNames.ContactPageNavigation) {
              this.onClosePage();
            } else if (this.navService.getActiveChildNavName() === ChildNavNames.AccountPageNavigation) {
              this.onClosePage();
            } else if (this.navService.getActiveChildNavName() === ChildNavNames.MarketingPlanNavigation) {
              this.onClosePage();
            }
            else {
              this.uiService.agendaRefreshRequired = true;
            }
            this.uiService.showRightPane = false;
          }
          break;
        }
        case 'markcomplete': {
          this.currentProcedureTrackerActivity.setYearMonthWeek();
          if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
            this.events.publish('refreshAgenda');
          }
          else {
            this.uiService.agendaRefreshRequired = true;
          }
          this.initAllDataModels();
          this.events.publish('updateEmbeddedActivity', this.currentProcedureTrackerActivity);
          this.events.publish('ProcedureTrackerActivityCompleted', this.currentProcedureTrackerActivity);
          break;
        }
        case 'reopenproceduretrackeractivity': {
          if (this.layoverViewPushedFrom === 'InMeetingProcedure' || this.layoverViewPushedFrom === 'PresentationMeetingComponent') {
            this.events.publish('updateEmbeddedActivity', this.currentProcedureTrackerActivity);
            this.initAllDataModels();
          } else if (this.layoverViewPushedFrom && (this.layoverViewPushedFrom === 'AccountTimeline' || this.layoverViewPushedFrom === 'ContactTimeline')) {
            this.initAllDataModels();
          }
          break;
        }
        default:
          break;
      }
      loader.dismiss();

    }).catch(async err => {
      if (err && err.errorCode && err.errorCode === 'ONLINEONLYUPDATEFAILED') {
        this.notifications.notify(this.translate.instant('OM_WAS_A_PROBLEM_CONTACT_ADMINISTRATOR'), 'Order Details', 'top', ToastStyle.DANGER);
      }
      loader.dismiss();
    });
  }

  editProcedureTracker(pt) {
    if (this.isReadOnlyMode) return;
    const selectedTracker = pt
    if (this.procedureTrackerDataService.isInMeetingProcedureTrackerFlow) {
      this.navCtrl.push(ProcedureTrackerComponent, {
        from: this.from,
        isEditMode: true,
        procedureTracker: selectedTracker,
        activity:this.currentProcedureTrackerActivity,
        callbackEvent: (data: any, deleted?: boolean) => this._handleEditTrackerCallback(data, deleted)
      })
    } else {
      this.navService.pushChildNavPageWithPageTracking(ProcedureTrackerComponent, PageName.ProcedureTrackerComponent, PageName.ActivitiesPageComponent, {
        from: this.from,
        isEditMode: true,
        procedureTracker: selectedTracker,
        activity:this.currentProcedureTrackerActivity,
        callbackEvent: (data: any, deleted?: boolean) => this._handleEditTrackerCallback(data, deleted)
      });
    }
    return;
  }

  private onClosePage() {
    const currentPage: PageName = this.navService.getCurrentPageName();
    this.procedureTrackerDataService.isInMeetingProcedureTrackerFlow = false;
    if (this.isLayoverPushView) {
      if (this.layoverViewPushedFrom === 'AccountTimeline') {
        if (this.currentProcedureTrackerActivity.status === 1) {
          this.uiService.timelineRefreshRequired = true;
        }
        this.navService.popChildNavPageWithPageTracking();
        this.footerService.initButtons(FooterViews.Contacts);
        this.activityService.selectedActivity = null;
        return;
      } else if (this.layoverViewPushedFrom === 'ContactTimeline') {
        this.activityService.selectedActivity = null;
        this.uiService.activeView = 'contactDetails';
        this.footerService.initButtons(FooterViews.Contacts);
        this.navService.popChildNavPageWithPageTracking();
        return;
      } else if (this.layoverViewPushedFrom === 'InMeetingProcedure') {
        this.modalCtrl.dismiss();
        if (currentPage === PageName.ActivitiesPageComponent) {
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          this.events.publish("initTimePickerLimitedMinTime");
          this.footerService.initButtons(FooterViews.Activities);
        }
        this.procedureTrackerDataService.inMeetingProcedureTrackerActivity = null;
        return;
      } else if (this.layoverViewPushedFrom === 'PresentationMeetingComponent') {
        this.uiService.activeView = 'Meeting';
        this.events.publish('deletedEmbeddedActivity', this.currentProcedureTrackerActivity);
        this.events.publish("initTimePickerLimitedMinTime");
        this.footerService.initButtons(FooterViews.PreviewMeeting);
        this.procedureTrackerDataService.inMeetingProcedureTrackerActivity = null;
      } else if (this.layoverViewPushedFrom === 'ProcedureLogFromContactTool') {
        this.uiService.activeView = 'contactDetails';
        this.footerService.initButtons(FooterViews.Contacts);
        this.navService.popChildNavPageWithPageTracking();
        return;
      } else if (this.layoverViewPushedFrom === 'ProcedureLogFromAccountTool') {
        this.uiService.activeView = 'accountDetails';
        this.footerService.initButtons(FooterViews.Accounts);
        this.navService.popChildNavPageWithPageTracking();
        return;
      } else if (this.layoverViewPushedFrom === 'OpportunityManagementTool') {
        // this.uiService.activeView = 'accountDetails';
        this.footerService.initButtons('opportunityDetails');
        this.navService.popChildNavPageWithPageTracking();
        return;
      } else if (this.layoverViewPushedFrom === 'MarketingPlanOpportunities') {
        this.footerService.initButtons('opportunityDetails');
        this.navService.popChildNavPageWithPageTracking();
        return;
      }
    }
    if (this.device.shouldBeMobileView) {
      if (this.agendaFooterService.actSegment === "week") {
        this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
        this.activityService.selectedActivity = undefined;
        this.uiService.showRightPane = false;
        this.uiService.activeView = '';
        this.activityService.shouldScrollToPrevSelectedActivity = !this.activityService.didOpenDetailPageFromAgendaPage;
        this.activityService.didOpenDetailPageFromAgendaPage = false;
        return;
      } else if (this.agendaFooterService.actSegment === "agenda") {
        if (this.uiService.activitiesPagePlanTabSelectedPlan === 'planTabTodo') {
          this.activityService.selectedActivity = undefined;
        }
      }
    }
    this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.NothingSelectedView).then(() => {
      this.navService.setChildNavRightPaneView(false);
      this.uiService.updateNothingSelectedScreenMessageFor(1);
      this.uiService.showRightPane = false;
    });
  }

  private async _updateProcedureTrackerActivity(operationDetailCode: string) {
    let action: OperationDetail = {
      onDynamics: !this.device.isOffline,
      onLocalDatabase: true,
      onLocalCopy: true,
      operationDetail: {
        code: operationDetailCode,
        message: this.translate.instant('DELTA_SYNC_ORDERS_UPDATE')
      }
    };
    if (
      operationDetailCode == 'add_tracker' ||
      operationDetailCode == 'delete_tracker' ||
      operationDetailCode == 'update_tracker'
    )
      action.onDynamics = false;
    const newLastUpdatedTime = new Date().getTime();
    let hasOfflineChanges = this.currentProcedureTrackerActivity.pendingPushToDynamics || false;
    this.currentProcedureTrackerActivity.pendingPushToDynamics = true;
    if (!this.device.isOffline) {
      this.uiService.displayLoader();
    }
    await this.procedureTrackerDataService.updateOrderActivity(action, [this.currentProcedureTrackerActivity], newLastUpdatedTime, hasOfflineChanges);
    if (!this.device.isOffline) {
      this.uiService.dismissLoader();
    }
  }

  private initProcedureNotesModel() {
    this.notesHeaderModel = {
      id: 'task-notes-header',
      title: this.translate.instant('NOTES'),
      controls: [
        {
          id: 'save_note',
          text: this.translate.instant('SAVE'),
          isDisabled: this.backgroundUploadInProgress ? true : (!this.isSaveNotesEnabled || this.isReadOnlyMode),
          isVisible: !this.isReadOnlyMode,
        }
      ]
    };
    let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
    this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
  }

  private initPeriodTrackerHeaderModel() {
    this.procedureTrackerHeaderModel = {
      id: 'procedure-tracker-header',
      title: this.translate.instant('BULK_PROCEDURE_LOG'),
      controls: [
        {
          id: 'add_procedure_tracker',
          text: this.translate.instant('ADD'),
          isDisabled: this.backgroundUploadInProgress ? true : (this.isReadOnlyMode || _.isEmpty(this.currentProcedureTrackerActivity.accountId)),
          isVisible: !this.isReadOnlyMode,
        }
      ]
    };
  }

  private async handleSaveNote() {
    if (this.isSaveNotesEnabled && !this.backgroundUploadInProgress) {
      this.isSaveNotesEnabled = false;
      this.currentProcedureTrackerActivity.procedureNotes.push(new IONote({
        annotationid: "offline_" + new Date().getTime().toString(),
        notetext: this.tempNoteText ? this.tempNoteText : "",
        ownerid: this.authService.user.systemUserID,
        ownerName: this.authService.user.displayName,
        createdon: new Date().getTime().toString(),
        deleted: false,
      }))
      let operationDetailCode = 'updatenotes';
      await this._updateProcedureTrackerActivity(operationDetailCode);
      this._sortProcedureNotes();
      this.tempNoteText = '';
      this.isSaveNotesEnabled = false;
      this.initProcedureNotesModel();
    }
  }

  async openStartDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let currEventStr = 'GENERAL_START_AND_END_TIME_SELECTION';
    if (!this.isReadOnlyMode) {
      if (this.currentProcedureTrackerActivity.procedureTrackerStatusString !== 'Completed') {
        let popover = await this.popoverCtrl.create(
          {
            component: IndDatetimeFormComponent,
            componentProps: {
              currentViewPage: CurViewPageType.SurgeryOrder,
              currentEvent: currEventStr,
              startDateTimeValue: this.currentProcedureTrackerActivity.scheduledStart,
              endDateTimeValue: this.currentProcedureTrackerActivity.scheduledEnd
            },
            cssClass: "datetime-popover"
          }
        );
        popover.onDidDismiss().then(async (data: any) => {
          if (data !== null && !_.isEmpty(data.data) && data.data.startTime != '') {
            if(this.isWeeklyFlag){
              const currentWeek: Date =  moment(new Date()).startOf('week').toDate();
              const startOfWeek: Date = moment(data.data.startTime).startOf('week').toDate();
              if(moment(startOfWeek).isAfter(currentWeek)){
                this.notifications.notify(this.translate.instant('WEEKLY_PROCEDURE_TRACKER_FUTURE'), 'Bulk Procedure Log', 'top', ToastStyle.DANGER);
                return;
              }
            }else{
              if (isFuture(data.data.startTime) && !isToday(data.data.startTime)){
                this.notifications.notify(this.translate.instant('DAILY_PROCEDURE_TRACKER_FUTURE'), 'Bulk Procedure Log', 'top', ToastStyle.DANGER);
                return;
              }
            }

            if (this.currentProcedureTrackerActivity.accountId) {
              const params = { id: this.currentProcedureTrackerActivity.accountId, name: this.currentProcedureTrackerActivity.accountNameString }
              const procedureTrackerExists = await this.isDuplicateProceureTrackerExists(params, data.data.startTime, data.data.endTime)
              if (procedureTrackerExists) return;
            }

            this.startTime = data.data.startTime;
            this.endTime = data.data.endTime;
            this.currentProcedureTrackerActivity.scheduledStart = new Date(this.startTime);
            this.currentProcedureTrackerActivity.scheduledEnd = new Date(this.endTime);
            this.currentProcedureTrackerActivity.resetScheduledStartLocale();
            this._updateDateTimeValue();
          }
        });
        popover.present();
      }
    }
  }

  private async isDuplicateProceureTrackerExists(account, scheduledStart, scheduledEnd) {
    this.uiService.displayLoader();
    const procedureTrackerExists = await this.procedureTrackerDataService.isDuplicateProceureTrackerExists(account.id, { scheduledStart, scheduledEnd }).catch(err => {
      this.notifications.notify(this.translate.instant('ADDING_ACCOUNTS_TO_PROCEDURE_TRACKER_FAILED'), 'Order Details', 'top', ToastStyle.DANGER);
    });

    this.uiService.dismissLoader();
    if (procedureTrackerExists === undefined) return true; // if there is dynamics error

    if (procedureTrackerExists) {
      this.notifications.notify(this.translate.instant('DUPLICATE_PROCEDURE_TRACKER', {
        accountName: account.name,
        startDate: format(scheduledStart, 'DD MMM YYYY'),
        endDate: format(scheduledEnd, 'DD MMM YYYY')
      }),
        'Order Details', 'top', ToastStyle.INFO, 10000);
      return true;
    }

    return false;
  }

  private _sortProcedureNotes() {
    if (this.currentProcedureTrackerActivity.procedureNotes && this.currentProcedureTrackerActivity.procedureNotes.length > 1) {
      this.currentProcedureTrackerActivity.procedureNotes = this.currentProcedureTrackerActivity.procedureNotes.sort((a, b) => {
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1);
      });
    }
  }

  public notesChanged(ev): void {
    if (ev && ev.target) {
      this.tempNoteText = ev.target.value;
    } else {
      this.tempNoteText = '';
    }
    if (this.tempNoteText.length > 0) {
      this.isSaveNotesEnabled = true;
    } else {
      this.isSaveNotesEnabled = false;
    }
    this.initProcedureNotesModel();
  }

  public isNoteControlsEnabled(note: IONote): boolean {
    return !!(note.ownerId == this.authService.user.systemUserID) && note.noteId != 'description-note' && !this.isReadOnlyMode && !this.backgroundUploadInProgress;
  }

  public async updateNote(ev) {
    if (this.backgroundUploadInProgress) return;
    if (ev && ev.action) {
      let idx = this.currentProcedureTrackerActivity.procedureNotes.findIndex(note => note.noteId == ev.noteId);
      if (ev.action == 'DELETE' && idx >= 0) {
        this.currentProcedureTrackerActivity.procedureNotes[idx].isDeleted = true;
      } else if (ev.action == 'SAVE' && idx >= 0) {
        this.currentProcedureTrackerActivity.procedureNotes[idx].noteText = ev.updatedText;
        this.currentProcedureTrackerActivity.procedureNotes[idx].updated = true;
      }
      // Update Service
      let operationDetailCode = 'updatenotes';
      await this._updateProcedureTrackerActivity(operationDetailCode);
      this._sortProcedureNotes();
    }
  }

  async openEndDatePicker(myEvent) {
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    if (!this.isReadOnlyMode) {
      if (this.currentProcedureTrackerActivity.procedureTrackerStatusString !== 'Completed') {
        let popover = await this.popoverCtrl.create(
          {
            component: IndDatetimeFormComponent,
            componentProps: {
              currentViewPage: CurViewPageType.SurgeryOrder,
              currentEvent: 'GENERAL_START_AND_END_TIME_SELECTION',
              startDateTimeValue: this.currentProcedureTrackerActivity.scheduledStart,
              endDateTimeValue: this.currentProcedureTrackerActivity.scheduledEnd
            },
            cssClass: "datetime-popover"
          }
        );
        popover.onDidDismiss().then((data: any) => {
          if (data !== null && !_.isEmpty(data.data) && data.data.endTime != '') {
            this.startTime = data.data.startTime;
            this.endTime = data.data.endTime;
            this.currentProcedureTrackerActivity.scheduledStart = new Date(this.startTime);
            this.currentProcedureTrackerActivity.scheduledEnd = new Date(this.endTime);
            this.currentProcedureTrackerActivity.resetScheduledStartLocale();
            this._updateDateTimeValue();
          }
        });
        popover.present();
      }
    }
  }

  private handleStartDateTimeValue() {
    let m = moment(this.startTime);
    if (!m.isValid()) {
      this.notifications.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'New Activity', 'top', ToastStyle.DANGER);
      this.startTime = this.prevStartTime;
    } else {
      this.currentProcedureTrackerActivity.scheduledStart = new Date(this.startTime);
      this.endTime = format(addMinutes(this.startTime, 30));
      this.currentProcedureTrackerActivity.scheduledEnd = new Date(this.endTime);
      this.prevStartTime = this.startTime;
      this._updateDateTimeValue();
    }
  }

  private handleEndDateTimeValue() {
    let m = moment(this.endTime);
    if (!m.isValid()) {
      this.notifications.notify(this.translate.instant('NEW_ACTIVITY_TOAST_INVALID_DATE'), 'New Activity', 'top', ToastStyle.DANGER);
      this.endTime = this.prevEndTime;
    } else {
      this.prevEndTime = this.endTime;
      this.currentProcedureTrackerActivity.scheduledEnd = new Date(this.endTime);
      this._updateDateTimeValue();
    }
  }

  private _updateDateTimeValue() {
    this._updateProcedureTrackerActivity('updatetime');
    this.getPageTitle();
    this.getDetailsHeader();
    this.initFooterViewButtons();
    this.initDateTimeFormFields();
    this._CD.detectChanges();
    if (this.navService.getActiveChildNavName() === ChildNavNames.ActivitiesPageNavigation) {
      this.events.publish('refreshAgenda');
    }
    this.events.publish('procedureTrackerActivityTimeIsUpdated', this.currentProcedureTrackerActivity);
  }

  // Validate the Products against the retired Bundled Products

  private _updateMandatoryField(field: PageName) {
    if (field == PageName.AccountPageComponent) {
      this.getAccountFormField();
    }
  }

  public getFormattedDateTime(value): string {
    if (_.isEmpty(value)) return '';
    const day = this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang)
    const time = (new Date(_.toNumber(value))).toLocaleTimeString(this.translate.currentLang, { hour12: this.dateTimeFormatsService.is12HourFormat, hour: '2-digit', minute: '2-digit' });
    return day + " " + time;
  }

  public getOpportunityFormField() {
    let viewData: IndFormFieldViewDataModel;
    const opportunities = this.opportunityService.opportunities.filter(opp => opp.opportunityName);
    const isOpportunitiesData: boolean = opportunities.length > 0;
    // const oppFA = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT);
    if(this.applicableOpportunities.length>0){
      var opp1 = this.applicableOpportunities[0].opportunityName
    }
    viewData = {
      label: this.translate.instant('OPPORTUNITY'),
      // inputText: (this.currentProcedureTrackerActivity.opportunityNameString) ? this.currentProcedureTrackerActivity.opportunityNameString : '',
      inputText: opp1 ? opp1 : '',
      customPlaceholderLabel: (this.isReadOnlyMode || !isOpportunitiesData) ? this.translate.instant('FOLLOW_UP_NO_OPPORTUNITY') : this.translate.instant('OM_SELECT_OPPORTUNITY'),
      id: 'opportunity-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      isRequired: false,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => {
        if (isOpportunitiesData) {
          this.handleFormFieldEvent(id, event, eventName);
        }
      }
    };
    this.OpportunityFormField = viewData;

  }

  handleOpportunityField() {
    // const opportunitiesDataSet = this.opportunityService.opportunities.filter(oppo => {
    //   return (oppo.accountID === this.currentSurgeryOrderActivity.accountId && oppo.stateCode === 0);
    // });
    // const contactIds = this.currentProcedureTrackerActivity.cusz.map(el => el.ID)
    let opportunitiesDataSet = this.opportunityService.opportunities.filter(item => item.opportunityName);
    let relatedOpportunitiesDataSet = opportunitiesDataSet.filter(item => item.accountID && (item.accountID === this.currentProcedureTrackerActivity.accountId ));//|| this._checkIfOppHasTheseContacts(contactIds, item));
    const selectedOpp = opportunitiesDataSet.find(a => a.ID == this.currentProcedureTrackerActivity.opportunityId);
    const opportuntiesSelectionViewData: OpportunitySelectionViewDataModel = {
      isReadOnlyMode: this.currentProcedureTrackerActivity.isCompleted ? true : false,
      opportunitiesDataSet: opportunitiesDataSet,
      relatedOpportunitiesData: relatedOpportunitiesDataSet,
      selectedOpportunities: selectedOpp ? [selectedOpp] : [],
      disableDoneButtonInOffline: false,
      callbackFromSelectionComponent: (event: string, newSelectedOpportunities: Array<Opportunity>, data: any) => this._handledOpportunitiesSelection(event, newSelectedOpportunities, data),
    };

    if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
      if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        setTimeout(() => {
          document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
        });
      }

      this.navCtrl.push(OpportunitySelectionPageComponent, {
        type: 'InMeetingProcedurePushedAccountSelectionView',
        viewData: opportuntiesSelectionViewData,
      }, { progressAnimation: false });
    } else {
      this.navService.pushWithPageTracking(OpportunitySelectionPageComponent, PageName.OpportunitySelectionPage,
        { viewData: opportuntiesSelectionViewData ,clickedOpportunity: opportuntiesSelectionViewData.selectedOpportunities[0] }, PageName.OpportunitySelectionPage);
    }
    //   this.navService.pushWithPageTracking(OpportunitySelectionPageComponent, PageName.OpportunitySelectionPage, 
    //     { viewData: opportuntiesSelectionViewData }, PageName.OpportunitySelectionPage);
  }
  _handleAddMultipleOpportunities() {
    let opportunitiesDataSet = this.opportunityService.opportunities.filter(item => item.opportunityName);
    let relatedOpportunitiesDataSet = opportunitiesDataSet.filter(item => item.accountID && (item.accountID === this.currentProcedureTrackerActivity.accountId ));//|| this._checkIfOppHasTheseContacts(contactIds, item));
    const opportuntiesSelectionViewData: OpportunitySelectionViewDataModel = {
      isReadOnlyMode: this.currentProcedureTrackerActivity.isCompleted ? true : false,
      opportunitiesDataSet: opportunitiesDataSet,
      relatedOpportunitiesData: relatedOpportunitiesDataSet,
      selectedOpportunities:this.applicableOpportunities,
      disableDoneButtonInOffline: false,
      isMultiSelection : true,
      callbackFromSelectionComponent: (event: string, newSelectedOpportunities: Array<Opportunity>, data: any) => this._handledOpportunitiesSelection(event, newSelectedOpportunities, data),
    };

    if (this.layoverViewPushedFrom && this.layoverViewPushedFrom === 'InMeetingProcedure') {
      if (!document.getElementsByTagName('ion-modal')[0].classList.contains('fullStretchView')) {
        setTimeout(() => {
          document.getElementsByTagName('ion-modal')[0].classList.add('fullStretchView');
        });
      }

      this.navCtrl.push(OpportunitySelectionPageComponent, {
        type: 'InMeetingProcedurePushedAccountSelectionView',
        viewData: opportuntiesSelectionViewData,
      }, { progressAnimation: false });
    } else {
      this.navService.pushWithPageTracking(OpportunitySelectionPageComponent, PageName.OpportunitySelectionPage,
        { viewData: opportuntiesSelectionViewData }, PageName.OpportunitySelectionPage);

    }
  }
  private _checkIfOppHasTheseContacts(contactIds: Array<string>, opp: Opportunity): boolean {
    let flag = false;
    for (let i = 0; i < opp.stakeholders.length; i++) {
      const stakeholder = opp.stakeholders[i];
      if (contactIds.includes(stakeholder.ID)) {
        flag = true;
        break;
      }
    }
    return flag;
  }
  private async _handledOpportunitiesSelection(event: string, newSelectedOpportunities: Opportunity[], data: any): Promise<void> {
    if (event && event == 'DONEBUTTONCLICK') {
      let opportuntiesPayload = [];
      newSelectedOpportunities.forEach(a => {
        opportuntiesPayload.push(a.ID);
      });
      const payload = opportuntiesPayload;
      if (_.isEmpty(newSelectedOpportunities)) {
        if (!_.isEmpty(this.currentProcedureTrackerActivity.opportunities) && !this.currentProcedureTrackerActivity.ID.includes('offline')) {
          this.currentProcedureTrackerActivity.opportunities.forEach(opp => {
            opp['deleted'] = true;
          });
        } else {
          this.currentProcedureTrackerActivity.opportunities = [];
        }
      } else {
        this.currentProcedureTrackerActivity.opportunities?.forEach(opp => {
          const index = newSelectedOpportunities.findIndex(item => item.ID == opp.indskr_opportunity || item.ID == opp['opportunityId']);
          if (index == -1 && !this.currentProcedureTrackerActivity.ID.includes('offline')) {
            opp['deleted'] = true;
          } else if (index == -1 && this.currentProcedureTrackerActivity.ID.includes('offline')) {
            this.currentProcedureTrackerActivity.opportunities.splice(index, 1);
          }
        });
      }
      newSelectedOpportunities.forEach(item => {
        const index = this.currentProcedureTrackerActivity.opportunities?.findIndex(opp => item.ID == opp.indskr_opportunity ||item.ID == opp['opportunityId']);
        if (index == -1) {
          this.currentProcedureTrackerActivity.opportunities.push({
            indskr_opportunity: item.ID,
            indskr_procedurelogopportunityid: Guid.create().toString(),
            deleted :false
          })
        } else {
          this.currentProcedureTrackerActivity.opportunities[index]['deleted'] = false;
        }
      })
      this.applicableOpportunities = [];
      opportuntiesPayload.forEach(item => {
        let foundOpportunity = this.opportunityService.getOpportunityDetailsbyId(item);
        this.applicableOpportunities.push(foundOpportunity);
      });
      let operationDetailCode = 'updateOpportunity'
      // await this._updateSurgeryOrderActivity(operationDetailCode);
      this._updateProcedureTrackerActivity(operationDetailCode);
    }
  }
  selectOpportunity(selected){
    let opportunitiesDataSet = this.opportunityService.opportunities.filter(item => item.opportunityName);
    let relatedOpportunitiesDataSet = opportunitiesDataSet.filter(item => item.accountID && (item.accountID === this.currentProcedureTrackerActivity.accountId ));//|| this._checkIfOppHasTheseContacts(contactIds, item));
    const opportuntiesSelectionViewData: OpportunitySelectionViewDataModel = {
      isReadOnlyMode: this.currentProcedureTrackerActivity.isCompleted ? true : false,
      opportunitiesDataSet: opportunitiesDataSet,
      relatedOpportunitiesData: relatedOpportunitiesDataSet,
      selectedOpportunities:this.applicableOpportunities,
      disableDoneButtonInOffline: false,
      isMultiSelection : true,
      callbackFromSelectionComponent: (event: string, newSelectedOpportunities: Array<Opportunity>, data: any) => this._handledOpportunitiesSelection(event, newSelectedOpportunities, data),
    };
    let clickedOpp = selected;
    this.navService.pushWithPageTracking(OpportunitySelectionPageComponent, PageName.OpportunitySelectionPage, { viewData: opportuntiesSelectionViewData , clickedOpportunity:clickedOpp }, PageName.OpportunitySelectionPage);

  }

}
