import { Component } from "@angular/core";
import { ContactAddress } from "../../../../classes/contact/contact.class";
import { DynamicsClientService } from "../../../../data-services/dynamics-client/dynamics-client.service";
import { AddressDataService } from "../../../../data-services/address/address.data.service";
import { NavigationService, ChildNavNames, PageName } from "../../../../services/navigation/navigation.service";
import { IonNav, NavParams } from "@ionic/angular";
import { ContactOfflineService } from "../../../../services/contact/contact.service";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "../../../../services/alert/alert.service";
import { IndHeaderLeftDataModel } from '@omni/models/indHeaderLeftDataModel';
import { EmailViewType } from '@omni/classes/activity/email.activity.class';
import { EmailService } from '@omni/services/email-templates/email.service';


@Component({
    selector: 'add-contact-address',
    templateUrl: 'contact-address-add.html',
  styleUrls:['contact-address-add.scss']
})
export class ContactNewAddressComponent {

    public contactAddress: ContactAddress[] = [];
    public dynamics: DynamicsClientService;
    public query: string = '';
    public selectedaddress: ContactAddress;
    public loading:boolean = false;
    public noResult:boolean = false;
    private from: PageName;
    indHeaderLeftModel: IndHeaderLeftDataModel;

    constructor(
        private contactService: ContactOfflineService,
        private addressService: AddressDataService,
        private alertService: AlertService,
        private navService: NavigationService,
        private navParams: NavParams,
        private translate:TranslateService,
        private navCtrl: IonNav,
        private emailService: EmailService
    ) {
        this.from = (this.navParams.data && this.navParams.data.from);
    }

    ngOnInit(){
      this.initHeader();
    }

    public initHeader(){
      this.indHeaderLeftModel = {
        id: 'contact-address-header-left',
        title: this.translate.instant('CHOOSE_ADDRESS'),
        controls: [
          {
            id: "close",
            imgSrc: 'assets/imgs/header_cancel.svg',
            name: this.translate.instant('CANCEL'),
            isDisabled: false,
            align: "right"
          },
          {
            id: "done",
            imgSrc: 'assets/imgs/header_complete.svg',
            name: this.translate.instant('DONE'),
            isDisabled: !this.selectedaddress,
            align: "right"
          }
        ],
      };
    }

    public onPageTitleControlClick(id){
      if(id==='close'){
        this.closepage(true);
      }
      if(id==='done'){
        this.closepage(false);
      }
    }

    continueClose() {
      if (this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING ||
        this.emailService.viewType === EmailViewType.CREATE_FROM_PHONE_CALL) {
          this.navCtrl.pop();
      } else {
        this.navService.popWithPageTracking();
      }
    }

    closepage(isCancel:boolean) {
        if(!isCancel){
            this.addressService.selectAddress(this.selectedaddress);
            if (this.from && this.from === PageName.ConsentDetailsComponent) {
              // this.navService.popWithPageTracking();
              this.continueClose();
            } else {
              this.navService.popChildNavPageWithPageTracking();
            }
            // this.viewCtrl.dismiss();
        }else{
            if(this.selectedaddress){
              let cancelText = this.translate.instant("CANCEL");
              if(this.translate.currentLang == 'it') {
                cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
              }
              this.alertService.showAlert({
                title: this.translate.instant('DISCARD_CHANGES'),
                message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')}, this.translate.instant('DISCARD_CONTACT_CR'), cancelText
              ).then (res => {
                if(res.role == "ok") {
                  this.addressService.selectAddress(null);
                  if (this.from && this.from === PageName.ConsentDetailsComponent) {
                    // this.navService.popWithPageTracking();
                    this.continueClose();
                  } else {
                    this.navService.popChildNavPageWithPageTracking();
                  }
                }
              });

            } else {
                this.addressService.selectAddress(null);
                if (this.from && this.from === PageName.ConsentDetailsComponent) {
                  // this.navService.popWithPageTracking();
                  this.continueClose();
                } else {
                  this.navService.popChildNavPageWithPageTracking();
                }
                // this.viewCtrl.dismiss();
            }
        }
    }

    async searchAddressGlobally(event) {
        console.log("SEARCHING ADDRESSES GLOBALLY");
        let query = '';
        if (event?.target?.value) {
          query = event.target.value.trim();
        }
        if (query.length > 2) {
          this.loading = true;
          try {
              const result = await this.addressService.getGlobalAddresses(query);
              if (result) {
                this.noResult = result.length === 0;
                this.mapAddress(result);
              }
          } catch (error) {
                console.log('searchAddressGlobally: ', error);
          } finally {
            this.loading = false;
          }
        }
    }

    clearSearch(ev){
        this.noResult = false;
        this.contactAddress = [];
        this.selectedaddress = undefined;
        this.initHeader();
    }

    mapAddress(result) {
        this.contactAddress = [];
        result.forEach(address => {
            let addObj: ContactAddress = {
                city: address['_indskr_city_lu_value@OData.Community.Display.V1.FormattedValue'] || '',
                state: address['_indskr_state_lu_value@OData.Community.Display.V1.FormattedValue'] || '',
                postal: address['_indskr_postalcode_lu_value@OData.Community.Display.V1.FormattedValue'] || '',
                country: address['_indskr_country_lu_value@OData.Community.Display.V1.FormattedValue'] || '',
                countryCode: '',
                street: address['indskr_line1'] || '',
                street2: address['indskr_line2'] || '',
                street3: address['indskr_line3'] || '',
                postOfficeBox: address['indskr_postofficebox'] || '',
                isPrimary: false,
                addressId: address['indskr_addressid'] || '',
                compositeAdd: address['indskr_composite'],
                primaryContact: '',
                fax: '',
                fax2: '',
                telephone1: '',
                telephone2: '',
                isSampleEligible: false,
                customerAddressID: '',
                isNew: true,
                concatanatedAddress: ''
            };
            const isExists = this.contactService.tempContactInformation.addressesList.some(acc => acc.addressId === addObj.addressId);
            if(!isExists) this.contactAddress.push(addObj);
        });
    }
}
