import { SelectedSuggestionPillDataModel, SuggestionPillType } from './../../models/search-config-data-model';
import { Injectable } from '@angular/core';
import { Contact, ContactMeetingState, ContactDTO, MEDICAL_FEATURES, ProfessionalDesignation, Specialty, ContactMedicalInsight, DCRApprovalReq } from '../../classes/contact/contact.class';
import { Endpoints } from '../../../config/endpoints.config';
import { DiskService, OFFLINE_DATA_COUNT_ENTITY_NAME, OFFLINE_DB_LINKED_ENTITY_NAME } from '../../services/disk/disk.service';
import { HttpClient } from '@angular/common/http';
import { ContactOfflineService, ContactTag, UserTagForContact, CustomerTagStateCode, getBucketId } from '../../services/contact/contact.service';
import { AuthenticationService } from '../../services/authentication.service';
import { DeviceService } from '../../services/device/device.service';
import { DB_SYNC_STATE_KEYS, DB_KEY_PREFIXES, DB_ALLDOCS_QUERY_OPTIONS, PREFIX_SEARCH_ENDKEY_UNICODE } from '../../config/pouch-db.config';
import { DeltaService, EntityNames, EntitySyncInfo } from '../delta/delta.service';
import {FeatureActionsMap} from "../../classes/authentication/user.class";
import {PublicationPreview} from "../../classes/contact/scientific-info.class";
import { CONTACT_FETCH_QUERIES, FETCH_MARKETING_JOURNEYS, FETCH_REALTIME_MARKETING_EMAILS_INFO, FETCH_WEBSITE_ACCESS_LOGS } from '../../config/fetch-xml/contact-fetchXMLs';
import { DynamicsClientService } from '../dynamics-client/dynamics-client.service';
import { SearchConfigService } from '../../services/search/search-config.service';
import { SyncFeatureCategory } from '../../enums/delta-service/delta-service.enum';
import { getSearchSuggestionsData } from '../../utility/global-search.utility';
import { xorBy, intersectionBy } from 'lodash';
import _ from 'lodash';
import * as moment from 'moment';
import { Activity } from '@omni/classes/activity/activity.class';
import { ActivityService } from '@omni/services/activity/activity.service';
import { UIService } from '@omni/services/ui/ui.service';
import { MdmService } from '@omni/services/mdm/mdm.service';
import { TagEntityType } from '@omni/services/user-tag/user-tag.service';
import { ApprovalStatus } from '@omni/classes/quotes/quotes.class';
import { MyAssistantService, NOTIFICATION } from '@omni/services/my-assistant/my-assistant.service';
import { TranslateService } from '@ngx-translate/core';
import { BusinessLineVal, BusinessProcessType } from '@omni/classes/dynamic-form/dynamic-form.class';
import { differenceInHours } from 'date-fns';
import { fetchQueries } from '@omni/config/dynamics-fetchQueries';
import { SecondaryInfoEntityName } from '@omni/classes/sec-info-config/sec-info.class';
import { SecInfoConfigDataService } from '../sec-info-config/sec-info-config-data-service';
import { MarketingJourney, RealtimeMarketingEmailInfo } from '@omni/classes/xperiences/trending.customer.class';
import { Utility } from '@omni/utility/util';

/**
 * Data service for contacts
 *
 * @export
 * @class ContactDataService
 */
@Injectable({
  providedIn: 'root'
})
export class ContactDataService {
    public _isInitialMappingDone: boolean = false;
    public isMedicalProfileEnabled: boolean = false;
    public isMedicalMarketingEnabled: boolean = false;

    // --------------------------------Contacts Tool Settings-------------------------------- //
    public logoforContactsTool = '';
    public labelforContactsTool = '';
    public baseURLforContactsTool = '';
    // --------------------------------Contacts Tool Settings-------------------------------- //

    /*************Edit business information in offline - Non-OneKey contacts*************/
    public offlineContactIds = new Map(); // Hashmap to keep track offline contact edit data in local db
    
    public offlineAddedNewDataIds = new Map(); // Hashmap to keep track offline contact edit data in local db
    /*************Edit business information in offline - Non-OneKey contacts*************/

    /*************Edit business Change Request in offline*************/
    public offlineBusinessCRIds = new Map(); // Hashmap to keep track offline business CR edit data in local db
    /*************Edit business Change Request in offline*************/

    /*************Create OneKey Change Request in offline*************/
    public offlineOneKeyCRIds = new Map(); // Hashmap to keep track offline business CR edit data in local db
    /*************Create OneKey Change Request in offline*************/
    private isCustomerJourneyTimelineEnabled: boolean = false;

    constructor(
        private disk: DiskService,
        private http: HttpClient,
        private contactOfflineService: ContactOfflineService,
        private authenticationOfflineService: AuthenticationService,
        private device: DeviceService,
        private deltaService: DeltaService,
        private dynamics: DynamicsClientService,
        private searchConfigService: SearchConfigService,
        private contactService: ContactOfflineService,
        private activityService: ActivityService,
        private uiService:UIService,
        public mdmService: MdmService,
        private myAssistantService: MyAssistantService,
        private translate: TranslateService,
        private secondaryInfoService: SecInfoConfigDataService,
        private authService: AuthenticationService,
    ) {
    }

    // public async syncContacts(forceFullSync: boolean = false, loadFromDbOnly = false): Promise<void> {
    //     if (loadFromDbOnly) {
    //         if (!this._isInitialMappingDone) {
    //             await this.contactOfflineService.loadContactsFromDBAndMap();
    //             this.contactOfflineService.sortContactsBySelectedSortOption();
    //             this._isInitialMappingDone = true;
    //         }
    //     } else {
    //         this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEDICAL_PROFILE) ? this.isMedicalProfileEnabled = true : this.isMedicalProfileEnabled = false;
    //         this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EVENT_INFORMATION) ? this.isMedicalMarketingEnabled = true : this.isMedicalMarketingEnabled = false;

    //         let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CONTACT);
    //         let syncStateMedical = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_MEDICAL_PROFILE);
    //         let syncStateMarketing = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_MARKETING_INFO);

    //         const isInitialSync = !syncState || !syncState.lastUpdatedTime;
    //         const doFullSync = forceFullSync || isInitialSync;
    //         let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.GET_BULKCONTACTS;
    //         const positions = this.authenticationOfflineService.user.positions.map((o)=>{
    //             return o.ID
    //         });

    //         if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEDICAL_PROFILE)){
    //         this.isMedicalProfileEnabled = true;
    //         }
    //         if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EVENT_INFORMATION)){
    //         this.isMedicalMarketingEnabled = true;
    //         }

    //         url = url.replace('{{positionIDs}}',positions.toString())
    //         url = !doFullSync ? url + '&lastUpdatedTime=' + syncState.lastUpdatedTime : url;
    //         if (this.isMedicalProfileEnabled || this.isMedicalMarketingEnabled) {
    //         let featureParams: string[] = [];
    //         if (doFullSync) {
    //             if (this.isMedicalProfileEnabled)
    //             featureParams.push(MEDICAL_FEATURES.M_PROFILE);
    //             if (this.isMedicalMarketingEnabled)
    //             featureParams.push(MEDICAL_FEATURES.M_MARKETING);
    //         } else {
    //             if (this.isMedicalProfileEnabled && syncStateMedical.lastUpdatedTime)
    //             featureParams.push(MEDICAL_FEATURES.M_PROFILE);
    //             if (this.isMedicalMarketingEnabled && syncStateMarketing.lastUpdatedTime)
    //             featureParams.push(MEDICAL_FEATURES.M_MARKETING);
    //             if (this.isMedicalProfileEnabled && !syncStateMedical.lastUpdatedTime)
    //             featureParams.push(MEDICAL_FEATURES.FM_PROFILE);
    //             if (this.isMedicalMarketingEnabled && !syncStateMarketing.lastUpdatedTime)
    //             featureParams.push(MEDICAL_FEATURES.FM_MARKETING);
    //         }
    //         url = url + '&medicalFeatures=' + featureParams.toString();
    //         }

    //         let appConfigFields = this.authenticationOfflineService.getConfiguredFieldList('contact') || [];
    //         let headers = Endpoints.contacts.GET_BULKCONTACTS_APPCONFIGFIELD_HEADERS;
    //         headers.headers = headers.headers.set('Sync-Service', 'true');
    //         if (appConfigFields.length > 0) {
    //             headers.headers = headers.headers.set('appConfigFields', appConfigFields.toString());
    //         } else {
    //             headers.headers = headers.headers.set('appConfigFields', '');
    //         }
    //         const contactsSyncInfo: EntitySyncInfo = {
    //             entityName: EntityNames.contact,
    //             totalFailed: 0,
    //             totalSynced: 0,
    //             errors: [],
    //             syncStatus: true
    //         };

    //         let response: any;
    //         try {
    //             response = await this.http.get(url, headers).toPromise();
    //         } catch (error) {
    //             console.error('syncContacts: ', response);
    //             //contactsSyncInfo.errorMessage = '[contact][error]' + error ? error.errorMessage : '';
    //             this.deltaService.addSyncErrorToEntitySyncInfo(contactsSyncInfo, url, error);
    //         }
    //         // get interaction with contacts from dynamcis crm
    //         let interactionResponse = await this.syncInteractionDataForContacts(doFullSync, syncState.lastUpdatedTime);
    //         if (response && Array.isArray(response)) {
    //             const newLastUpdatedTime = new Date().getTime();
    //             if (doFullSync) {
    //                 // full sync flow
    //                 await this.contactOfflineService.mapFullSyncedContacts(response, newLastUpdatedTime, forceFullSync, interactionResponse);
    //             } else {
    //                 // delta sync flow

    //                 // In case of app load, load data from local db and map
    //                 // Won't do anything if alrady loaded
    //                 if (!this._isInitialMappingDone) {
    //                     await this.contactOfflineService.loadContactsFromDBAndMap();
    //                     this._isInitialMappingDone = true;
    //                 }

    //                 // Sync the deltas
    //                 await this.contactOfflineService.mapDeltaSyncedContacts(response, newLastUpdatedTime, interactionResponse);
    //             }

    //             // Sort contacts
    //             this.contactOfflineService.sortContactsBySelectedSortOption();

    //             // Done sync. Update sync state.
    //             if (contactsSyncInfo.syncStatus) {

    //                 this.isMedicalProfileEnabled ? syncStateMedical.lastUpdatedTime = newLastUpdatedTime : syncStateMedical.lastUpdatedTime = undefined;
    //                 this.isMedicalMarketingEnabled ? syncStateMarketing.lastUpdatedTime = newLastUpdatedTime : syncStateMarketing.lastUpdatedTime = undefined;

    //                 syncState.lastUpdatedTime = newLastUpdatedTime;
    //                 contactsSyncInfo.totalSynced = response.length;
    //                 await this.disk.updateSyncState(syncState);
    //                 await this.disk.updateSyncState(syncStateMedical);
    //                 await this.disk.updateSyncState(syncStateMarketing);
    //             }
    //         }

    //         this.deltaService.addEntitySyncInfo(contactsSyncInfo);
    //     }

    //     this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_RECENT_SEARCHES, true).then((doc)=>{
    //       if(doc && doc.raw){
    //         this.contactOfflineService.recentSearches = doc.raw
    //       }
    //       else {
    //         this.contactOfflineService.recentSearches = [];
    //       }
    //     })
    //     this.disk.retrieve(DB_KEY_PREFIXES.CONSENT_RECENT_SEARCHES, true).then((doc)=>{
    //       if(doc && doc.raw){
    //         this.contactOfflineService.consentRecentSearches = doc.raw
    //       }
    //       else {
    //         this.contactOfflineService.consentRecentSearches = [];
    //       }
    //     })
    // }

    public async syncContactProfileData(doFullSync = false, loadFromDBOnly = false) {
      if (!loadFromDBOnly) {

      if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.MEDICAL_PROFILE)){
        this.isMedicalProfileEnabled = true;
      }
      if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EVENT_INFORMATION)){
        this.isMedicalMarketingEnabled = true;
      }
        let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_PROFILE_OFFLINE_DATA);
        let lastUpdatedTime;
        if(offlineData && !doFullSync) {
            lastUpdatedTime = offlineData.lastUpdatedTime;
        }
        const positions = this.authenticationOfflineService.user.positions.map((o) => {
            return o.ID
        });
        let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.GET_BULK_PROFILE_DATA;
        url = url.replace('{{positionIDs}}', positions.toString());
        url = url.replace('{{lastupdatedTime}}', lastUpdatedTime?lastUpdatedTime:0);


        let syncStateMedical = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_MEDICAL_PROFILE);
        let syncStateMarketing = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_MARKETING_INFO);

        if (this.isMedicalProfileEnabled || this.isMedicalMarketingEnabled) {
            let featureParams: string[] = [];
            if (doFullSync) {
                if (this.isMedicalProfileEnabled)
                    featureParams.push(MEDICAL_FEATURES.M_PROFILE);
                if (this.isMedicalMarketingEnabled)
                    featureParams.push(MEDICAL_FEATURES.M_MARKETING);
            } else {
                if (this.isMedicalProfileEnabled && syncStateMedical.lastUpdatedTime)
                    featureParams.push(MEDICAL_FEATURES.M_PROFILE);
                if (this.isMedicalMarketingEnabled && syncStateMarketing.lastUpdatedTime)
                    featureParams.push(MEDICAL_FEATURES.M_MARKETING);
                if (this.isMedicalProfileEnabled && !syncStateMedical.lastUpdatedTime)
                    featureParams.push(MEDICAL_FEATURES.FM_PROFILE);
                if (this.isMedicalMarketingEnabled && !syncStateMarketing.lastUpdatedTime)
                    featureParams.push(MEDICAL_FEATURES.FM_MARKETING);
            }
            url = url + '&medicalFeatures=' + featureParams.toString();
        }

        const bulkProfileSyncInfo: EntitySyncInfo = {
            entityName: EntityNames.bulk_profile,
            totalFailed: 0,
            totalSynced: 0,
            errors: [],
            syncStatus: true
        };
        try {
            let response;
            response = await this.http.get(url).toPromise();
            if (response) {
                this.searchConfigService.isContactProfileDataMappedToContacts = false;
                let allProductSegmentation:Array<any> = [];
                //let allRepCallPlans:Array<any> = [];
                let allScientificData = {
                    eventHistory : [],
                    publications: [],
                    researches: [],
                    sessionHistory: [],
                    speakerEngagements: []
                };
                if(lastUpdatedTime){
                    if(offlineData && offlineData.raw){
                        if(offlineData.raw['productSegmentations']){
                            allProductSegmentation = offlineData.raw['productSegmentations'];
                        }
                        // if(offlineData.raw['repCallPlans']){
                        //     allRepCallPlans = offlineData.raw['repCallPlans'];
                        // }
                        if(offlineData.raw['scientificInformation']){
                            allScientificData = offlineData.raw['scientificInformation'];
                        }
                    }
                    if(response['productSegmentations']){
                        bulkProfileSyncInfo.totalSynced += response['productSegmentations'].length;
                        response['productSegmentations'].forEach(item => {
                            let idx = allProductSegmentation.findIndex(a=> a.indskr_productratingid == item.indskr_productratingid);
                            if(idx >= 0){
                                // if(item['statecode'] == '0' && item['statuscode'] == '1'){
                                    allProductSegmentation[idx] = item;
                                // }else{
                                //     allProductSegmentation.splice(idx,1);
                                // }
                            }else{
                                // if(item['statecode'] == '0' && item['statuscode'] == '1'){
                                    allProductSegmentation.push(item);
                                // }
                            }
                        });
                    }
                    // if(response['repCallPlans']){
                    //     bulkProfileSyncInfo.totalSynced += response['repCallPlans'].length;
                    //     response['repCallPlans'].forEach(item => {
                    //         let idx = allRepCallPlans.findIndex(a=> a.indskr_customercallplanid == item.indskr_customercallplanid);
                    //         if(idx >= 0){
                    //             if(item['statecode'] == '0' && item['statuscode'] == '1'){
                    //                 allRepCallPlans[idx] = item;
                    //             }else{
                    //                 allRepCallPlans.splice(idx,1);
                    //             }
                    //         }else{
                    //             if(item['statecode'] == '0' && item['statuscode'] == '1'){
                    //                 allRepCallPlans.push(item);
                    //             }
                    //         }
                    //     });
                    // }
                    if (response['scientificInformation']) {
                      let scientificData = response['scientificInformation'];

                      if (scientificData['publications'] && Array.isArray(scientificData['publications'])) {
                        scientificData['publications'].forEach(item => {
                          let idx = allScientificData.publications.findIndex(a => a.indskr_publicationsid == item.indskr_publicationsid)
                          if (idx >= 0) {
                            if (item['statecode'] == 0) {
                              allScientificData.publications[idx] = item;
                            } else {
                              allScientificData.publications.splice(idx, 1);
                            }
                          } else {
                            if (item['statecode'] == 0) {
                              allScientificData.publications.push(item);
                            }
                          }
                        });
                      }
                      if (scientificData['researches'] && Array.isArray(scientificData['researches'])) {
                        scientificData['researches'].forEach(item => {
                          let idx = allScientificData.researches.findIndex(a => a.ind_researchworkid == item.ind_researchworkid)
                          if (idx >= 0) {
                            if (item['statecode'] == 0) {
                              allScientificData.researches[idx] = item;
                            } else {
                              allScientificData.researches.splice(idx, 1);
                            }
                          } else {
                            if (item['statecode'] == 0) {
                              allScientificData.researches.push(item);
                            }
                          }
                        });
                      }

                      if (scientificData['eventHistory'] && Array.isArray(scientificData['eventHistory'])) {
                        scientificData['eventHistory'].forEach(item => {
                          let idx = allScientificData.eventHistory.findIndex(a => a.indskr_eventid == item.indskr_eventid)
                          if (idx >= 0) {
                            allScientificData.eventHistory[idx] = item;
                          } else {
                            allScientificData.eventHistory.push(item);
                          }
                        });
                      }

                      if (Array.isArray(scientificData['speakerEngagements'])) {
                        scientificData['speakerEngagements'].forEach(item => {
                          let idx = allScientificData.speakerEngagements.findIndex(a => a.indskr_speakerengagementid == item.indskr_speakerengagementid)
                          if (idx >= 0) {
                            if (item['statecode'] == 0) {
                              allScientificData.speakerEngagements[idx] = item;
                            } else {
                              allScientificData.speakerEngagements.splice(idx, 1);
                            }
                          } else {
                            if (item['statecode'] == 0) {
                              allScientificData.speakerEngagements.push(item);
                            }
                          }
                        });
                      }
                      else {
                        allScientificData.speakerEngagements = []
                      }

                      if (Array.isArray(scientificData['sessionHistory'])) {
                        allScientificData.sessionHistory = scientificData['sessionHistory'];
                      }
                      else {
                        allScientificData.sessionHistory = []
                      }


                    }
                    offlineData = {
                        raw: {
                            productSegmentations: allProductSegmentation,
                            //repCallPlans: allRepCallPlans,
                            scientificInformation: allScientificData,
                        },
                        lastUpdatedTime: new Date().getTime(),
                    }
                }else{


                    if(!response['scientificInformation'].speakerEngagements) {
                        response['scientificInformation'].speakerEngagements = [];
                    }

                    if(!response['scientificInformation'].sessionHistory) {
                        response['scientificInformation'].sessionHistory = [];
                    }

                    offlineData = {
                        raw: response,
                        lastUpdatedTime: new Date().getTime(),
                    };
                }

                this.isMedicalProfileEnabled ? syncStateMedical.lastUpdatedTime = lastUpdatedTime : syncStateMedical.lastUpdatedTime = undefined;
                this.isMedicalMarketingEnabled ? syncStateMarketing.lastUpdatedTime = lastUpdatedTime : syncStateMarketing.lastUpdatedTime = undefined;
                await this.disk.updateSyncState(syncStateMedical);
                await this.disk.updateSyncState(syncStateMarketing);
            }
            await this.disk.updateOrInsert(DB_KEY_PREFIXES.CONTACT_PROFILE_OFFLINE_DATA, doc => {
                doc = offlineData;
                return doc;
            }).catch(error => console.error('Save contact profile data in offline db error: ', error));

        } catch (err) {
            this.deltaService.addSyncErrorToEntitySyncInfo(bulkProfileSyncInfo, url, err);
        }
      }
      this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_RECENT_SEARCHES, true).then((doc)=>{
        if(doc && doc.raw){
          this.contactOfflineService.recentSearches = doc.raw
        }
        else {
          this.contactOfflineService.recentSearches = [];
        }
      });
      this.disk.retrieve(DB_KEY_PREFIXES.CONSENT_RECENT_SEARCHES, true).then((doc)=>{
        if(doc && doc.raw){
          this.contactOfflineService.consentRecentSearches = doc.raw
        }
        else {
          this.contactOfflineService.consentRecentSearches = [];
        }
      });
    }

    public async getDCRApprovalRequestsOnline(forceFullSync:boolean=false, loadFromDBOnly = false) {
      if (this.device.isOffline || loadFromDBOnly || !this.authenticationOfflineService.user?.buConfigs?.indskr_businessline || this.authenticationOfflineService.user?.buConfigs?.indskr_businessline !== BusinessLineVal.VACCINE) return;
      let fetchXML = CONTACT_FETCH_QUERIES.approveDCRRequests;
      let raw = await this.disk.retrieve(DB_KEY_PREFIXES.DCR_APPROVAL_REQ);
      if (raw?.data) {
        raw.data.forEach(d => {
          if (d.createdOn)
            d.createdOn = new Date(d.createdOn);
        });
        this.contactService.approvableDCRRequests = raw.data;
      }
      const isInitialSync = !raw || !raw.lastModified || forceFullSync;
      const newLastUpdatedTime = new Date().getTime().toString();
      if (isInitialSync) {
        raw = {
          data: [],
          lastModified: newLastUpdatedTime
        }
        fetchXML = fetchXML.replace('{lastUpdatedTimeCondition}', '');
        this.contactService.approvableDCRRequests = [];
      } else {
        fetchXML = fetchXML.replace('{lastUpdatedTimeCondition}', `<condition attribute="modifiedon" operator="ge" value="`.concat(moment(parseInt(raw.lastModified)).format('YYYY-MM-DD')).concat(`" />`));
      }
      fetchXML = fetchXML.replace("{userId}", this.authenticationOfflineService.user.xSystemUserID);
      await this.dynamics.executeFetchQuery('indskr_contactcrs', fetchXML)
        .then(async (res) => {
          if (res) {
            if (isInitialSync) {
              this.contactService.approvableDCRRequests = [];
              res.forEach(record => {
                let dcrReq = new DCRApprovalReq(record);
                dcrReq.isApprovalRecord = true;
                this.contactService.approvableDCRRequests.push(dcrReq);
              });
              
            } else {
              res.forEach(record => {
                let dcrReq = new DCRApprovalReq(record);
                dcrReq.isApprovalRecord = true;
                const index = this.contactService.approvableDCRRequests.findIndex(cr => cr.approvalActivityId === record['aa.indskr_approvalactivityid']);
                if (index == -1) {
                  dcrReq['indskr_contactcrid'] = dcrReq.ID;
                  dcrReq['indskr_mdm'] = BusinessProcessType.SanofiChina;
                  const notification = {
                    type: NOTIFICATION.DCR_FOR_APPROVAL,
                    name: this.translate.instant("DCR_FOR_APPROVAL", { DcrName: `${dcrReq.name}` }),
                    DateTime: Date.now(),
                    id: NOTIFICATION.DCR_FOR_APPROVAL + dcrReq.ID,
                    data: {
                      data: dcrReq
                    },
                    icon: '',
                    isRed: false,
                    params: { DcrName: dcrReq.name }
                  }
                  this.myAssistantService.saveNotificationToDisk(notification);
                  this.contactService.approvableDCRRequests.push(dcrReq);
                } else {
                  this.contactService.approvableDCRRequests[index] = dcrReq;
                }
              })
            }
            await this.disk.updateOrInsert(DB_KEY_PREFIXES.DCR_APPROVAL_REQ, (doc) => {
              doc = {
                data: [],
                lastModified: newLastUpdatedTime
              };
              doc.data = this.contactService.approvableDCRRequests;
              return doc;
            });
          }
        })
        .catch(async (err) => {
          this.contactService.approvableDCRRequests = [];
          console.error('Error while fetching Approvable DCR requuests' + err);
        });
    }
  

    public async syncContacts(forceFullSync: boolean = false, loadFromDbOnly = false): Promise<void> {
      this.deltaService.pushSyncEntityName(SyncFeatureCategory.profiles);
        // if (loadFromDbOnly) {
        //     if (!this._isInitialMappingDone) {
        //         await this.contactOfflineService.loadContactsFromDBAndMap();
        //         this.contactOfflineService.sortContactsBySelectedSortOption();
        //         this._isInitialMappingDone = true;
        //     }
        // } else {

        // }

        await this.contactOfflineService.fetchContactsForConfiguredDisplay(forceFullSync, loadFromDbOnly);

        // this.disk.retrieve(DB_KEY_PREFIXES.CONTACT_RECENT_SEARCHES, true).then((doc)=>{
        //   if(doc && doc.raw){
        //     this.contactOfflineService.recentSearches = doc.raw
        //   }
        //   else {
        //     this.contactOfflineService.recentSearches = [];
        //   }
        // })
        // this.disk.retrieve(DB_KEY_PREFIXES.CONSENT_RECENT_SEARCHES, true).then((doc)=>{
        //   if(doc && doc.raw){
        //     this.contactOfflineService.consentRecentSearches = doc.raw
        //   }
        //   else {
        //     this.contactOfflineService.consentRecentSearches = [];
        //   }
        // })
    }

    /**
     *  This will perform an initial sync and map it standard.
     *     Easy bb
     *
     * @private
     * @memberof AccountDataService
     */
    /*private async _doInitialSync() {
        await this.getContacts(true);
        await this.getBulkContacts(true);
    }*/

    /**
     *    This is only going to give me entities that have changed, we need to combine this result set with our offline data set.
     *
     * @private
     * @param {number} lastUpdated
     * @memberof AccountDataService
     */
    /*private async _doDeltaSync(lastUpdated: number) {
        await this.getContacts(false);

        const url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl +
            Endpoints.contacts.GET_BULK_CONTACTS_DELTA.replace('{lastUpdatedTime}', lastUpdated.toString());

        let headers = Endpoints.contacts.GET_BULKCONTACTS_APPCONFIGFIELD_HEADERS;

        let appConfigFields = [];
        let response;
        appConfigFields = this.authenticationOfflineService.getConfiguredFieldList('contact');
        if (appConfigFields.length > 0) {
            headers.headers = headers.headers.set('appConfigFields', appConfigFields.toString());
            response = await this.http.get(url,headers).toPromise();
        }else{
            response = await this.http.get(url).toPromise();
        }

        //We have the new data
        // console.log(response);


        //Contacts are saved with bulk via pouchdb, no point loading em.
        //Simply overwrite the ones we got from delta, simple.
        //Pass delta result to mapContactBulkDetails, it will overwrite the documents automatically, fancy pants
        if (Array.isArray(response) && response.length > 0) {
            this.contactOfflineService._mapContactBulkDetails(response);
        }


    }*/

    /**
     * Returns an array of contacts from either our disk or network depending on cache expiry time
     *
     * @memberof ContactDataService
     */
    /*async getContacts(forceNetwork?: boolean) {
        let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.GET_CONTACTS.replace('{page}', '1');
        url = url.replace('{size}', '5000');

        if (this.disk.check('contacts') && !forceNetwork) {
            await this.disk.retrieve('contacts').then(contacts => {
                this.contactOfflineService.mapContacts(contacts.raw, true);
            });
        } else {

            let headers = Endpoints.contacts.GET_CONTACTS_AGGREGATION_HEADERS;
            headers.headers = headers.headers.set('X-SystemUserId', this.authenticationOfflineService.user.systemUserID);

            let response = await this.http.get(url, headers)
                .toPromise();
            this.contactOfflineService.mapContacts(response);
        }
    }*/

    /*public async getBulkContacts(forceNetwork?: boolean) {
        if (this.disk.check('bulkContactDetails') && !forceNetwork) {
            //They're already saved on disk, we're okay
            return;
        }

        const url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.GET_BULKCONTACTS;

        let headers = Endpoints.contacts.GET_BULKCONTACTS_APPCONFIGFIELD_HEADERS;

        // let configuredFields: Array<ConfiguredFields> = (this.authenticationOfflineService.user.configuredFields) ? this.authenticationOfflineService.user.configuredFields.filter(field => field['entityName'] === "contact") : [];
        let appConfigFields = [];
        appConfigFields = this.authenticationOfflineService.getConfiguredFieldList('contact');
        if (appConfigFields.length > 0) {
            // configuredFields.forEach((field) => {
            //     appConfigFields.push(field['fieldName']);
            // });

            headers.headers = headers.headers.set('appConfigFields', appConfigFields.toString());

            let response = await this.http.get(url, headers).subscribe(
                (bulkResult: any) => {
                    this.contactOfflineService._mapContactBulkDetails(bulkResult)
                },
                (error) => {
                    console.log('bulk contacts errored', error)
                }
            );
        } else {
            let response = await this.http.get(url).subscribe(
                (bulkResult: any) => {
                    this.contactOfflineService._mapContactBulkDetails(bulkResult)
                },
                (error) => {
                    console.log('bulk contacts errored', error)
                }
            );
        }
    }*/

    /*async fetchGuestContact(id: string): Promise<Contact> {
        const url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.GET_CONTACT_DETAILS.replace('{contactid}', id);

        let response: any = await this.http.get(url).toPromise();
        console.log(response);

        return new Contact(response);
    }*/

    /*async getContactById(contact: Contact) {

        //get contact details from disk to be displayed first. Then get fresh details by call API.

        if (this.device.isOffline) {
            const rawResponse = await this.contactOfflineService.getContactDetailsByID(contact.ID);
            if (rawResponse) {
                // Object.assign(contact.rowDTO,rawResponse);
                // this.contactOfflineService.contactInformation = rawResponse;
                let contactDetail = (Array.isArray(rawResponse))? rawResponse[0] : rawResponse;
                if (this.contactOfflineService.contactInformation && contactDetail['contactid'] == this.contactOfflineService.contactInformation.ID) {
                    this.contactOfflineService.mapContactDetails([rawResponse]);
                    this.contactOfflineService.isContactDetailsLoaded = true;
                }
            }
            // else{
            //     this.contactOfflineService.contactInformation = contact;
            //     // this.contactOfflineService.mapContactDetails([contact]);
            // }

            return;
        }

        let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.GET_CONTACT_DETAILS.replace('{contactid}', contact.ID);
        let configuredFields: Array<ConfiguredFields> = (this.authenticationOfflineService.user.configuredFields) ? this.authenticationOfflineService.user.configuredFields.filter(field => field['entityName'] === "contact") : [];
        let appConfigFields = [];
        let headers = Endpoints.contacts.GET_BULKCONTACTS_APPCONFIGFIELD_HEADERS;

        if (configuredFields.length > 0) {
            configuredFields.forEach((field) => {
                appConfigFields.push(field['fieldName']);
            });
            headers.headers = headers.headers.set('appConfigFields', appConfigFields.toString());
            await this.http.get(url, headers).toPromise().then(
                response => {
                    // if (response != rawResponse) {
                    let contactDetail = response[0];
                    if (this.contactOfflineService.contactInformation && contactDetail['contactid'] == this.contactOfflineService.contactInformation.ID) {
                        this.contactOfflineService.mapContactDetails(response);
                        this.contactOfflineService.isContactDetailsLoaded = true;
                    }

                    // }
                },
                error => {
                    //console.error("Caught error trying to update activity details", error);
                    //this.contactOfflineService.globalErrorHandler.handleError(error);
                }
            );
        }else{
            await this.http.get(url).toPromise().then(
                response => {
                    // if (response != rawResponse) {
                    let contactDetail = response[0];
                    if (this.contactOfflineService.contactInformation && contactDetail['contactid'] == this.contactOfflineService.contactInformation.ID) {
                        this.contactOfflineService.mapContactDetails(response);
                        this.contactOfflineService.isContactDetailsLoaded = true;
                    }

                    // }
                },
                error => {
                    //console.error("Caught error trying to update activity details", error);
                    //this.contactOfflineService.globalErrorHandler.handleError(error);
                }
            );
        }
        return
    }*/

    /**
     * REPLACED BY NEW REALTIME FETCH
     *
     *
     */
    // async getContactDetail(contact: Contact, contactInPosition = true): Promise<Contact | undefined> {
    //     // First use local data
    //     this.contactOfflineService.contactInformation = contact;
    //     this.contactOfflineService.isContactDetailsLoaded = true;

    //     if (this.device.isOffline) {
    //         return;
    //     }

    //     let nonDeltaUrl: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.GET_FULL_CONTACT_DETAILS.replace('{contact_id}', contact.ID);

    //     let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl
    //                         + Endpoints.contacts.GET_DELTA_CONTACT_DETAILS
    //                             .replace('{contact_id}', contact.ID)
    //                             .replace('{last_updated_time}', contact.lastSyncedTime.toString());

    //     const positions = this.authenticationOfflineService.user.positions.map((o)=>{
    //                                 return o.ID
    //                             });
    //     url = url.replace('{{positionIDs}}',positions.toString());
    //     if (this.isMedicalProfileEnabled || this.isMedicalMarketingEnabled) {
    //       let featureParams: string[] = [];
    //       if (this.isMedicalProfileEnabled) featureParams.push(MEDICAL_FEATURES.M_PROFILE);
    //       if (this.isMedicalMarketingEnabled) featureParams.push(MEDICAL_FEATURES.M_MARKETING);
    //       url = url + '&medicalFeatures=' + featureParams.toString();
    //     }
    //     let appConfigFields = this.authenticationOfflineService.getConfiguredFieldList('contact') || [];
    //     let headers = Endpoints.contacts.GET_BULKCONTACTS_APPCONFIGFIELD_HEADERS;
    //     if (appConfigFields.length > 0) {
    //         headers.headers = headers.headers.set('appConfigFields', appConfigFields.toString());
    //     } else {
    //         headers.headers = headers.headers.set('appConfigFields', '');
    //     }

    //     let newContact: Contact;
    //     try {
    //         const newLastSyncedTime = new Date().getTime();
    //         let response;
    //         if(contactInPosition) {
    //             response = await this.http.get(url, headers).toPromise();
    //         } else {
    //             response = await this.http.get(nonDeltaUrl, headers).toPromise();
    //         }
    //         if (response && Array.isArray(response)) {
    //             // It seems it's returning other contact delta even thought we specify contact id in the request..
    //             const rawContact = response.find(c => c.contactid === contact.ID);
    //             if (rawContact) {
    //                 // TODO: what if state changed to inactive? what to do then?
    //                 rawContact.lastSyncedTime = newLastSyncedTime;

    //                 newContact = new Contact(rawContact);
    //                 newContact.configuredFields = this.authenticationOfflineService.updateConfiguredFieldValues(rawContact, 'contact');
    //                 if(contactInPosition || this.contactOfflineService.getContactByID(newContact.ID)) {
    //                     this.contactOfflineService.replaceContact(newContact);
    //                     this.contactOfflineService.contactInformation = newContact;
    //                     await this.contactOfflineService.upsertRawContactToLocalDB(rawContact, true);
    //                 } else {
    //                     this.contactOfflineService.raw = rawContact;
    //                     this.contactOfflineService.replaceGlobalSearchContact(newContact);
    //                 }
    //                 //this.contactOfflineService.mapContactFieldsToSearchIndex(newContact);
    //                 this.contactOfflineService.isContactDetailsLoaded = true;
    //             }
    //         }
    //         // this.searchConfigService.contactsSearchIndexesConfig = this.searchConfigService.contactsSearchIndexesConfig.filter(config=>{
    //         //   return config.values.length>=1
    //         // })
    //     } catch (httpError) {
    //         console.error('getContactDetail: ', httpError);
    //         // TODO: handle error..
    //     }

    //     this.contactOfflineService.isContactDetailsLoaded = true;
    //     return newContact;
    // }

    //Using FetchXML
    async getContactDetails(contact: Contact, contactInPosition = true,isGlobalSearchAddedContact:boolean = false): Promise<Contact | undefined> {

        if (this.device.isOffline) {
            return;
        }

        let contactDeets = await this.contactOfflineService.fetchContactsForConfiguredDisplay(!contactInPosition, false, contact.ID,false,isGlobalSearchAddedContact)

        if(Array.isArray(contactDeets) && contactDeets[0]) {
            let temp = new Contact(contactDeets[0])
            this.contactOfflineService.contactInformation = temp;
            this.contactOfflineService.isContactDetailsLoaded = true;
            return temp;
        } else{
          return contact.raw;
        }
    }

    async getContactDetailsOffline(data:any, contact: Contact): Promise<Contact | undefined> {
      let contactDeets = await this.contactOfflineService.fetchContactsForConfiguredDisplayOffline(data, contact)

      if(Array.isArray(contactDeets) && contactDeets[0]) {
        let temp = new Contact(contactDeets[0])
        this.contactOfflineService.contactInformation = temp;
        this.contactOfflineService.isContactDetailsLoaded = true;
        return temp;
      }
      return null;
    }

    async getContactDetailsByContactId(contactId: string, contactInPosition = true,isGlobalSearchAddedContact:boolean = false): Promise<Contact | undefined> {
      if (this.device.isOffline) return;
      const contactDeets = await this.contactOfflineService.fetchContactsForConfiguredDisplay(!contactInPosition, false, contactId, false, isGlobalSearchAddedContact)
      if(Array.isArray(contactDeets) && contactDeets[0]) {
          let temp = new Contact(contactDeets[0])
          this.contactOfflineService.contactInformation = temp;
          this.contactOfflineService.isContactDetailsLoaded = true;
          return temp;
      }
      return null;
    }

    async getContactProfile(contact: Contact) {
        if (this.device.isOffline) {
            return;
        }
        const isSampleRequestEnabled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.ALLOCATION_TOOL);
        const isEventRegEnabaled = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.EVENT_REGISTRATION);
        let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl
                            + Endpoints.contacts.GET_CONTACT_PROFILE.replace('{contactid}', contact.ID);

        url = url.replace('{{positionIDs}}', this.authenticationOfflineService.getLoggedInUserPositions().toString());
        if (this.isMedicalProfileEnabled || this.isMedicalMarketingEnabled) {
          let featureParams: string[] = [];
          if (this.isMedicalProfileEnabled) featureParams.push(MEDICAL_FEATURES.M_PROFILE);
          if (this.isMedicalMarketingEnabled) featureParams.push(MEDICAL_FEATURES.M_MARKETING);
          url = url + '&medicalFeatures=' + featureParams.toString();
        }
        const features: string[] = [];
        if (isEventRegEnabaled) {
          features.push("customerEvents");
        }
        if (isSampleRequestEnabled) {
          features.push("allocationOrderTool");
          url += '&endDate=' + new Date(moment(new Date()).add(1, 'month').endOf('month').format('YYYY-MM-DD')).getTime();
        }
        if (!_.isEmpty(features)) {
          url += '&features=' + features.join(',');
        }
        url += (isSampleRequestEnabled || isEventRegEnabaled) ? '&startDate=' + new Date().getTime() : '';
        try {
            const response: any = await this.http.get(url).toPromise();
            if (response) {
                console.log("Contact profile successful response");
                await this.contactOfflineService.mapContactProfile(contact, response);
            }
        } catch (httpError) {
            console.error('getContactProfile: ', httpError);
            // TODO: handle error..
            return Promise.reject('');
        }
    }

    public async getContactActivitiesForTimeline(contact: Contact){
        if(!contact) return;
        if(!this.device.isOffline) {
          let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.GET_CONTACT_ACTIVITIES.replace('{contactid}',contact.ID);
          url = url.replace('{{businessUnitId}}', this.authenticationOfflineService.user.xBusinessUnitId);
          let timelineActivities: number = 451680002; // Organisation
          if(this.authenticationOfflineService.user.timelineActivites) {
            timelineActivities = this.authenticationOfflineService.user.timelineActivites;
          }
          url = url.replace('{{timelineActivities}}', timelineActivities.toString());
          if(this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.LIVEMEET,true)){
              url += '&features=liveMeet';
          }
          try {
            await this.http.get(url).toPromise().then(response => {
              this.contactOfflineService.mapTimelineActivities(response, contact);
            });
          }catch(error) { console.error('Error occured while fetching contact timeline', error); }
          return;
        }
        //Fetch offline activities for contact timeline when the tab is on the timeline.
        else {
          if(this.uiService.contactDetailsSegment != "timeline") return;
          const dbKeyAllActivitesTimeline: string = DB_KEY_PREFIXES.CONTACT_ACTIVITIES_TIMELINE + contact.ID;
          const dbKeyUpdateActivitiesByContact: string = DB_KEY_PREFIXES.CONTACT_TIMELINE_OFFLINE_UPDATE_ACTIVITIES_BY_CONTACT;
          const dbKeyDeleteActivitiesByContact: string = DB_KEY_PREFIXES.CONTACT_TIMELINE_OFFLINE_DELETE_ACTIVITIES_BY_CONTACT;
          const dbKeyUpdateContactsByActivity: string = DB_KEY_PREFIXES.CONTACT_TIMELINE_OFFLINE_UPDATE_CONTACTS_BY_ACTIVITY;
          let contactActivities: Activity[] = [];
          //Initial mapping for offline activities due to offlineline data duration
          contact.mapActivities(contactActivities);
          
          this.uiService.displayLoader;
          //Retrieve contact timeline, update/scrap/track activities from DB
          try{
            await Promise.all([
              this.disk.retrieve(dbKeyAllActivitesTimeline).then((rawData)=>{
                contactActivities = !_.isEmpty(rawData) ? rawData.raw : [];
                console.log(`Retrieved - offline activities for contact timeline from DB: ${contactActivities.length}`);  
              }),
              this.disk.retrieve(dbKeyUpdateActivitiesByContact).then((rawData)=>{
                this.contactService.updateActivityIdsForOfflineTimelineByContactId = !_.isEmpty(rawData) ? rawData.raw : [];
                console.log(`Retrieved - Offline contact timeline for updating activities: ${this.contactService.updateActivityIdsForOfflineTimelineByContactId.length}`);  
              }),
              this.disk.retrieve(dbKeyDeleteActivitiesByContact).then((rawData)=>{
                this.contactService.scrapActivityIdsForOfflineTimelineByContactId = !_.isEmpty(rawData) ? rawData.raw : [];
                console.log(`Retrieved - Offline contact timeline for deleting activities: ${this.contactService.scrapActivityIdsForOfflineTimelineByContactId.length}`);  
              }),
              this.disk.retrieve(dbKeyUpdateContactsByActivity).then((rawData)=>{
                this.contactService.updateContactIdsForOfflineTimelineByActivityId = !_.isEmpty(rawData) ? rawData.raw : [];
                console.log(`Retrieved - Offline contact timeline for tracking activities: ${this.contactService.updateContactIdsForOfflineTimelineByActivityId.length}`);  
              }),
            ]);
          }catch(error) { console.log(error); }

          //offline updated meeting activities check before mapping
          if(!_.isEmpty(contactActivities)) {
            if(!_.isEmpty(this.contactService.updateActivityIdsForOfflineTimelineByContactId)) {
              const updateConIdx = this.contactService.updateActivityIdsForOfflineTimelineByContactId.findIndex(o=>o['contactId'] == contact.ID);
              if(updateConIdx > -1) {
                let updatedActivityIdsList: any[] = this.contactService.updateActivityIdsForOfflineTimelineByContactId[updateConIdx]['activityIds'] || [];
                if(!_.isEmpty(updatedActivityIdsList)) {
                  let isUpdatedActivity: boolean = false;
                  updatedActivityIdsList.forEach((actId) => {
                    const actIdx = contactActivities.findIndex(conAct => conAct.ID == actId);
                    let foundActivity = this.activityService.getActivityByID(actId);
                    if(!_.isEmpty(foundActivity)) {
                      isUpdatedActivity = true;
                      if(actIdx > -1) {
                        contactActivities[actIdx] = foundActivity;
                      }else {
                        contactActivities.push(foundActivity);
                      }
                    }
                  });
                  if(isUpdatedActivity) {
                    this.contactService.updateActivityIdsForOfflineTimelineByContactId.splice(updateConIdx, 1);
                    try{
                      await Promise.all([
                        this.disk.updateOrInsert(dbKeyUpdateActivitiesByContact, (doc) => { 
                          if(!doc || !doc.raw) { doc= { raw:[] }}
                          doc.raw = this.contactService.updateActivityIdsForOfflineTimelineByContactId;
                          return doc;
                        }),
                        this.disk.updateOrInsert(dbKeyAllActivitesTimeline, (doc) => { 
                          if(!doc || !doc.raw) { doc= { raw:[] }}
                          doc.raw = contactActivities;
                          console.log(`Updated offline activities for contact timeline from DB: ${contactActivities.length}`);  
                          return doc;
                        }),
                      ]);
                    }catch(error) {
                      console.log(error);
                    }
                  }
                } 
              }
              //No contact selected: if all selected contacts are deleted
              const nonConIdx = this.contactService.updateActivityIdsForOfflineTimelineByContactId.findIndex(o=>o['contactId'] == '');
              if(nonConIdx > -1) {
                let deletedContactsFromActivityIdsList: any[] = this.contactService.updateActivityIdsForOfflineTimelineByContactId[nonConIdx]['activityIds'] || [];
                if(!_.isEmpty(deletedContactsFromActivityIdsList)) {
                  let isNonContactsActivity: boolean = false;
                  deletedContactsFromActivityIdsList.forEach((actId) => {
                    const actIdx = contactActivities.findIndex(conAct => conAct.ID == actId);
                    if(actIdx > -1) {
                      isNonContactsActivity = true;
                      contactActivities.splice(actIdx, 1);
                    }
                  });
                  if(isNonContactsActivity) {
                    try{
                      await this.disk.updateOrInsert(dbKeyAllActivitesTimeline, (doc) => { 
                        if(!doc || !doc.raw) { doc= { raw:[] }}
                        doc.raw = contactActivities;
                        console.log(`Updated offline activities for contact timeline from DB: ${contactActivities.length}`);  
                        return doc;
                      });
                    }catch(error) { console.log(error); }
                  }
                }
              }
            }
            //update scrapped activity
            if(!_.isEmpty(this.contactService.scrapActivityIdsForOfflineTimelineByContactId)) {
              const deleteConIdx = this.contactService.scrapActivityIdsForOfflineTimelineByContactId.findIndex(o=>o['contactId'] == contact.ID);
              if(deleteConIdx > -1) {
                let isScrappedActivity: boolean = false;
                this.contactService.scrapActivityIdsForOfflineTimelineByContactId[deleteConIdx]['activityIds'].forEach(raId => {
                  const actIdx = contactActivities.findIndex(conAct => conAct.ID == raId);
                  if(actIdx > -1) {
                    isScrappedActivity = true;
                    contactActivities.splice(actIdx, 1);
                  }
                });
                if(isScrappedActivity) {
                  this.contactService.scrapActivityIdsForOfflineTimelineByContactId.splice(deleteConIdx, 1);
                  try{
                    await Promise.all([
                      this.disk.updateOrInsert(dbKeyDeleteActivitiesByContact, (doc) => { 
                        if(!doc || !doc.raw) { doc= { raw:[] }}
                        doc.raw = this.contactService.scrapActivityIdsForOfflineTimelineByContactId;
                        return doc;
                      }),
                      this.disk.updateOrInsert(dbKeyAllActivitesTimeline, (doc) => { 
                        if(!doc || !doc.raw) { doc= { raw:[] }}
                        doc.raw = contactActivities;
                        console.log(`Updated offline activities for contact timeline from DB: ${contactActivities.length}`);  
                        return doc;
                      }),
                    ]);
                  }catch(error) { console.log(error); }
                }
              }
            }
            //recheck - update deleted contact from activity
            if(!_.isEmpty(this.contactService.updateContactIdsForOfflineTimelineByActivityId)) {
              let isDeletedContact: boolean = false;
              this.contactService.updateContactIdsForOfflineTimelineByActivityId.forEach(async (o) => {
                const actIdx = contactActivities.findIndex(conAct => conAct.ID == o['activityId']);
                if(actIdx > -1) {
                  let foundContact = o['contactIds'].includes(contact.ID);
                  if(!foundContact) {
                    isDeletedContact = true;
                    contactActivities.splice(actIdx, 1);
                  }
                }
              });
              if(isDeletedContact) {
                try{
                  await this.disk.updateOrInsert(dbKeyAllActivitesTimeline, (doc) => { 
                    if(!doc || !doc.raw) { doc= { raw:[] }}
                    doc.raw = contactActivities;
                    console.log(`Updated offline activities for contact timeline from DB: ${contactActivities.length}`);  
                    return doc;
                  });
                }catch (error) { console.log(error); }
              }
            }
            //Final mapping for offline activities
            this.contactOfflineService.mapOfflineTimelineActivities(contactActivities, contact);
          }else {
            //In this case, no update is required if there is no local DB data.
          }
          this.uiService.dismissLoader;
          return;
        }
    }

    /**************************Customer Journey**************************/
    public async getCustomerJourneysForTimeline(contact: Contact){
      if(!contact) return;
      if(!this.device.isOffline) {
        let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.GET_CUSTOMER_JOURNEY.replace('{contactid}',contact.ID);
        try {
          const contactCustomerJourneysResponse:any = await this.http.get(url).toPromise();
          console.log(contactCustomerJourneysResponse);
          contact.mapCustomerJourneys(contactCustomerJourneysResponse)
        }catch(error) { console.error('Error occured while fetching customer journey for timeline', error); }
        return;
      }
      //Fetch offline activities for contact timeline when the tab is on the timeline.
    }
    
    public async getCustomerJourneysRealtimeMarketingEmailForTimeline(contact: Contact){
      if(!contact) return;
      const contactId = contact.ID;
      if(!this.device.isOffline) {
        const url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.GET_CUSTOMER_JOURNEY_REALTIME_EMAILS.replace('{contactid}', contactId);
        try {
          let realtimeEmailResponse:any = await this.http.get(url).toPromise();
          if (realtimeEmailResponse && Array.isArray(realtimeEmailResponse.customerJourneyRealtimeEmails)) {
            const customerJourneyRealtimeEmails = realtimeEmailResponse.customerJourneyRealtimeEmails.filter((e: any) => {
              return e.interactionEvent && (e.interactionEvent == "msdynmkt_emaildelivered" || e.interactionEvent == "msdynmkt_emailopened");
            });
            if (customerJourneyRealtimeEmails) {
              const groupedEmails = _.groupBy(customerJourneyRealtimeEmails, email => `${email.journeyId}_${email.messageid}_${email.interactionEvent}`);
              const reducedEmails = _.map(groupedEmails, emails => emails[0]);
              const filteredResponse = reducedEmails;
              //Save offline data in local DB
              await this.saveRealTimeEmailsForTimelinInLocalDB(filteredResponse, contactId);
              let realtimeEmailActivities = [];
              realtimeEmailActivities = await this.contactService.mapRealtimeEmailsToActivity(filteredResponse);
              if (!_.isEmpty(realtimeEmailActivities)) {
                contact.mapRealtimeEmailToTimeline(realtimeEmailActivities);
              }
            }
          }          
        }catch(error) { 
          console.error('Error occured while fetching customer journey realtime email for timeline', error); 
          let offlineData = await this.loadOfflineRealTimeEmailsForTimeline(contactId);
          if (offlineData && offlineData.raw) {
            let realtimeEmailActivities = [];
            realtimeEmailActivities = await this.contactService.mapRealtimeEmailsToActivity(offlineData.raw);
            if (!_.isEmpty(realtimeEmailActivities)) {
              contact.mapRealtimeEmailToTimeline(realtimeEmailActivities);
            }
          }
        }
        return;
      }
    }

    public mapEmptyCustomerJourneysTimeline(contact: Contact) {
      contact.customerJourneysTimeline = [];
    }
    /**************************Customer Journey**************************/

    public async postContactToPosition(contact: Contact, payload): Promise<boolean> {
        const targetPositionId = this.authService.user.xPositionID;
        let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl
        + Endpoints.contacts.ASSOCIATE_CONTACT_TO_POSITION
            .replace('{contactid}', contact.ID)
            .replace('{positionid}', targetPositionId);
        
        let mapSuccess: boolean = false;
        await this.http.post(url, payload )
        .toPromise()
        .then(async (res) => {
          mapSuccess = true;
          const isVisibilityApprovalBased = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_VISIBILITY_APPROVAL_BASED);
          if(payload['indskr_skipapproval'] !== false || !isVisibilityApprovalBased){
            if(!this.contactOfflineService.rawContactInformation ||
              (this.contactOfflineService.rawContactInformation && this.contactOfflineService.rawContactInformation.contactid != contact.ID)) {
              await this.getContactDetails(contact, false,true)
              .then(con => {
                  if(con){
                    return con;
                  }
                  console.log("getting contact details global contact");
                  // console.log(con);
              })
              .catch(() => {
                  console.log("Failed to get details of contact being added to positon");
              });
            }
          }
        })
        .catch((error) => {
            console.error("postContactToPosition: ", error);
            if (
              error?.error?.errorMessage?.includes('Duplicate Detected')
            ) {
              throw error;
            }
        });
        return mapSuccess;
    }

    public async postContactToPositionByContactId(contactId: string, payload: any): Promise<boolean> {
      const targetPositionId = this.authService.user.xPositionID;
      let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl
      + Endpoints.contacts.ASSOCIATE_CONTACT_TO_POSITION
          .replace('{contactid}', contactId)
          .replace('{positionid}', targetPositionId);
      
      let mapSuccess: boolean = false;
      await this.http.post(url, payload)
      .toPromise()
      .then(async (res) => {
        mapSuccess = true;
        const isVisibilityApprovalBased = this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_VISIBILITY_APPROVAL_BASED);
        if(payload['indskr_skipapproval'] !== false || !isVisibilityApprovalBased) {
          await this.getContactDetailsByContactId(contactId, false,true)
          .then(con => {
            if(con) {
              return con;
            }
            console.log("getting contact details global contact");
          })
          .catch(() => {
            console.log("Failed to get details of contact being added to positon");
          });
        }
      })
      .catch((error) => {
        console.error("postContactToPosition: ", error);
        if (
          error?.error?.errorMessage?.includes('Duplicate Detected')
        ) {
          throw error;
        }
      });
      return mapSuccess;
    }

    public async fetchSpecialties(loadFromDbOnly = false) {
        if (loadFromDbOnly) {
            await this.fetchOfflineSpecialties();
        } else {
        let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.GET_SPECIALTIES;

        await this.http.get(url)
        .toPromise()
        .then((res) => {
            const lastUpdatedTime = new Date().getTime();
            this.contactOfflineService.mapSpecialties(res, lastUpdatedTime);
        })
        .catch(() => {
            console.log("error Getting Specialties")
        });
        }
    }

  private fetchOfflineSpecialties() {
    this.disk.retrieve(DB_KEY_PREFIXES.SPECIALTY).then(doc => {
      if (doc && doc.raw && doc.raw.length > 0) {
        this.contactOfflineService.specialties = doc.raw;
        // for (let i = 0; i < doc.raw.length; i++) {
        //   const raw = doc.raw[i];
        //   let specialty: Specialty = {
        //       id: raw.indskr_lu_specialtyid,
        //       name: raw.indskr_specialty,
        //       code: raw.indskr_specialtycode,
        //       createdon: raw.createdon,
        //       indskr_sadeptnm: raw.indskr_sadeptnm
        //   }
        //   this.contactOfflineService.specialties.push(specialty);
        // }
      }
      else {
        this.contactOfflineService.specialties = [];
      }
    })
  }

    //#region / NOT NEEDED BECAUSE OF NEW DYNAMIC FORMS
    //TO DELETE (071221)

    // async fetchProfessionalDesignation(loadFromDbOnly = false) {
    //     if (!(this.device.isOffline || loadFromDbOnly)) {
    //         try{
    //             let response = await this.dynamics.executeFetchQuery('indskr_lu_professional_designations', CONTACT_FETCH_QUERIES.professionalDesignation);
    //             if(response) {
    //                 this.mapProfessionalDesignation(response);
    //                 this.disk.updateOrInsert(DB_KEY_PREFIXES.PROFESSIONAL_DESIGNATION, doc => ({ raw: response }));
    //             }
    //         } catch(err) {
    //             console.error("Online Professional Designation Fetch : " + err);
    //         }
    //     } else {
    //         const dbData = await this.disk.retrieve(DB_KEY_PREFIXES.PROFESSIONAL_DESIGNATION, true);
    //         if (dbData && Array.isArray(dbData.raw)) {
    //             this.mapProfessionalDesignation(dbData.raw);
    //         }
    //     }
    // }


    // public mapProfessionalDesignation(response) {
    //     let proDesignation: ProfessionalDesignation[] = [];
    //     if(response && Array.isArray(response)){
    //         response.forEach( el => {
    //             let proDesig: ProfessionalDesignation;
    //             proDesig = {
    //                 id: el['indskr_lu_professional_designationid'],
    //                 designation: el['indskr_professionaldesignation']
    //             }
    //             proDesignation.push(proDesig);
    //         });
    //         this.contactOfflineService.professionalDesignation = proDesignation;
    //     }

    // }

    //#endregion TO DELETE

    public createContacts(contacts: ContactDTO[]) {
      let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.CREATE_CONTACT_META;

      return this.http.patch(url, contacts).toPromise();
    }

    public createDynamicContact(contact) {
        let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.CREATE_DYNAMIC_CONTACT;

        return this.http.post(url, contact).toPromise();

    }

    /*public async getOfflineContacts() {
        await this.disk.retrieve('contacts').then(contacts => {
            this.contactOfflineService.mapContacts(contacts.raw, true);
        }).catch(diskError => {
            console.error('Caught disk error trying to load contacts from disk', diskError);
        });
    }*/

    /*public async addContactToOfflineContacts(contact: object) {
        await this.disk.retrieve('contacts').then(contacts => {
            if (Array.isArray(contacts.raw.contacts)) {
                contacts.raw.contacts.push(contact);
                this.disk.save('contacts', { raw: contacts['raw'] });
            }
        }).catch(diskError => {
            console.error('Caught disk error trying to load contacts from disk', diskError);
        });
    }*/

    /*public getContactsFromAccessToken(): Observable<any> {
        let accessToken = this.contactOfflineService.getRemoteMeetingAccessToken();
        let url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.GET_CONTACTS_FROM_ACCESS_TOKEN_RAW.replace('{access_token}', accessToken);
        let headers = new HttpHeaders();
        headers = headers.set('X-Tenant-Id', accessToken);
        headers = headers.set('Content-Type', 'application/json');
        // let response = await this.http.get(url, {headers, observe: 'response'}).toPromise();

        return this.http.get(url, { headers, observe: 'response' })
            .pipe(
                map(response => {
                    this._mapRemoteContact(response);
                }),
                catchError((e: any, caught: any) =>
                    Observable.throw("403"))
            );
    }*/
    _mapRemoteContact(response: any) {
        if (response.body.contactAttendees && Array.isArray(response.body.contactAttendees)) {
            response.body.contactAttendees.map(attendee => {
                let remoteContact = this.contactOfflineService.contacts.find(contact => contact.ID === attendee.indskr_contactid);
                remoteContact.connectionState = attendee['indskr_joinstatus'] || ContactMeetingState.NOTJOINED;
                // if(remoteContact){

                //     switch (attendee['indskr_joinstatus']) {
                //         case 100000000:
                //         remoteContact.conectionState = ContactMeetingState.JOINED;
                //         // this.joinStatus = ContactJoinStatus.JOINED;
                //         break;

                //         case 100000002:
                //         remoteContact.conectionState = ContactMeetingState.NOTJOINED;
                //         // this.joinStatus = ContactJoinStatus.NOTJOINED;
                //         break;

                //         case 100000003:
                //         remoteContact.conectionState = ContactMeetingState.LEFT;
                //         // this.joinStatus = ContactJoinStatus.LEFT;
                //         break;

                //         default:
                //         remoteContact.conectionState = '';
                //         break;
                //     }
                // }
            });
        }
    }

    public getPublicationPreviewById(publicationId: string, attachmentId: string): Promise<PublicationPreview> {
      const url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl +
        Endpoints.contacts.GET_PREVIEW_PUBLICATION
          .replace('{publicationId}', publicationId)
          .replace('{attachmentId}', attachmentId);
      return this.http.get<PublicationPreview>(url).toPromise();
    }

    /**
    * get interaction date for contacts http call to get intaraction dates for all contacts
    * @param forceFullSync
    */

    public updateCustomerNoteInOfflineDB(payload):Promise<void> {
        return new Promise(async (resolve,reject)=> {
            let offlineNotes = await this.disk.retrieve(DB_KEY_PREFIXES.OFFLINE_CUSTOMER_NOTES);
            let data:Array<any> = [];
            if(offlineNotes && offlineNotes.raw){
                data = offlineNotes.raw;
                if (payload) {
                    let idx = data.findIndex(a => a && a.hasOwnProperty('annotationid') && a.annotationid == payload.annotationid);
                    if (idx >= 0) {
                        data[idx] = payload;
                        if(data[idx].annotationid.includes('offlinecustomernote') && data[idx].deleted == true){
                            data.splice(idx,1);
                        }
                    } else {
                        data.push(payload);
                    }
                }
                offlineNotes.raw = data;
                offlineNotes.count = data.length;
            }else{
                data.push(payload);
                offlineNotes = {
                    raw: data,
                    count: data.length,
                }
            }
            this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_NOTES, offlineNotes.count);
            await this.disk.updateOrInsert(DB_KEY_PREFIXES.OFFLINE_CUSTOMER_NOTES, doc => {
                doc = offlineNotes;
                return doc;
            }).catch(error => console.error('Save Customer Notes to DB error: ', error));
            resolve();
        });
    }

    public uploadCustomerNotesOnline():Promise<any> {
        return new Promise(async (resolve,reject)=> {
            let offlineNotes = await this.disk.retrieve(DB_KEY_PREFIXES.OFFLINE_CUSTOMER_NOTES);
            let dbNotes = await this.disk.retrieve(OFFLINE_DB_LINKED_ENTITY_NAME.CONTACT_NOTES);
            if(offlineNotes && offlineNotes.raw && Array.isArray(offlineNotes.raw) && offlineNotes.raw.length != 0){
                const payload = offlineNotes.raw.filter(item => item.pendingPushOnDynamics).map(item=>{
                    if(item.annotationid.includes('offlinecustomernote')){
                        return {
                          contactid: item.contactid,
                          createdon: item.createdon,
                          notetext: item.notetext,
                          ownerid: item.ownerid,
                          filename: item.filename,
                          filesize: item.filesize,
                          documentbody: item.documentbody,
                          mimetype: item.mimetype
                        };
                    }else {
                        if(item.deleted){
                            return {
                              deleted: true,
                              noteid: item.annotationid
                            };
                        }else if (item.fileupdated){
                            return {
                              notetext: item.notetext,
                              noteid: item.annotationid,
                              filename: item.filename,
                              filesize: item.filesize,
                              documentbody: item.documentbody,
                              mimetype: item.mimetypeated
                            };
                        } else if (item.fileremoved) {
                          return {
                            notetext: item.notetext,
                            noteid: item.annotationid,
                            filename: item.filename,
                            filesize: item.filesize,
                            documentbody: item.documentbody,
                            mimetype: item.mimetype
                          };
                        } else {
                          return {
                            notetext: item.notetext,
                            noteid: item.annotationid
                          };
                        }
                    }
                });
                let headers = Endpoints.headers.content_type.json;
                let url:string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.UPLOAD_CONTACT_NOTES;
                let response = await this.http.post(url, payload,headers).toPromise();
                if(response && Array.isArray(response) && response.length != 0){

                    response.forEach((note,idx) => {
                        if(note.hasOwnProperty('noteid')){
                            // Add to real offline db for customer notes
                            try {
                                if(dbNotes && dbNotes.raw && Array.isArray(dbNotes.raw) && dbNotes.raw.length != 0){
                                    let dbIdx = dbNotes.raw.findIndex(item=>item['annotation.annotationid']==note['noteid']);
                                    if(payload[idx].deleted == true){
                                        dbNotes.raw.splice(dbIdx,1);
                                    }else{
                                        if(dbIdx >= 0){
                                          dbNotes.raw[dbIdx]['annotation.annotationid'] = note['noteid'];
                                          dbNotes.raw[dbIdx]['annotation.notetext'] = offlineNotes.raw[idx]['notetext'];
                                          dbNotes.raw[dbIdx]['annotation.filename'] = offlineNotes.raw[idx]['filename'];
                                          dbNotes.raw[dbIdx]['annotation.mimetype'] = offlineNotes.raw[idx]['mimetype'];
                                        }else{
                                            dbNotes.raw.push({
                                              'annotation.annotationid': note['noteid'],
                                              'annotation.objectid': offlineNotes.raw[idx]['contactid'],
                                              'annotation.createdon': new Date(parseInt(offlineNotes.raw[idx]['createdon'])),
                                              'annotation.notetext': offlineNotes.raw[idx]['notetext'],
                                              'annotation.ownerid@OData.Community.Display.V1.FormattedValue': offlineNotes.raw[idx]['ownerName'],
                                              'annotation.ownerid': offlineNotes.raw[idx]['ownerid'],
                                              'annotation.filename': offlineNotes.raw[idx]['filename'],
                                              "annotation.mimetype": offlineNotes.raw[idx]['mimetype']
                                            });
                                        }
                                    }
                                }else if(payload[idx].deleted != true){
                                    dbNotes = {
                                        raw : [{
                                          'annotation.annotationid': note['noteid'],
                                          'annotation.objectid': offlineNotes.raw[idx]['contactid'],
                                          'annotation.createdon': new Date(offlineNotes.raw[idx]['createdon']),
                                          'annotation.notetext': offlineNotes.raw[idx]['notetext'],
                                          'annotation.ownerid@OData.Community.Display.V1.FormattedValue': offlineNotes.raw[idx]['ownerName'],
                                          'annotation.ownerid': offlineNotes.raw[idx]['ownerid'],
                                          'annotation.filename': offlineNotes.raw[idx]['filename'],
                                          "annotation.mimetype": offlineNotes.raw[idx]['mimetype']
                                        }],
                                    }
                                }
                                offlineNotes.raw[idx].pendingPushOnDynamics = false;
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    });
                    offlineNotes.raw = offlineNotes.raw.filter(item => item.pendingPushOnDynamics);
                    offlineNotes.count = offlineNotes.raw.length;
                    this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_NOTES, offlineNotes.count);
                    await this.disk.updateOrInsert(DB_KEY_PREFIXES.OFFLINE_CUSTOMER_NOTES, doc => {
                        doc = offlineNotes;
                        return doc;
                    }).catch(error => console.error('Save Customer Notes to DB error: ', error));

                    if(dbNotes){
                        await this.disk.updateOrInsert(OFFLINE_DB_LINKED_ENTITY_NAME.CONTACT_NOTES, doc => {
                            doc = dbNotes;
                            return doc;
                        }).catch(error => console.error('Save Customer Notes Linked Entity to DB error: ', error));
                    }
                }
                resolve(response);
            }
            else {
              resolve(null);
            }
        });
    }

    async purgeContactFromDatabase(id){

        const bucketID = getBucketId(id,this.authService.get_num_buckets())

        let isContactFound = false;

        let conIdx = this.contactOfflineService.contacts.findIndex(x => x.ID == id);

        if(conIdx >= 0) {
            this.contactOfflineService.contacts.splice(conIdx, 1);
        }

        const dbKey = Utility.getDBBucketedKey(DB_KEY_PREFIXES.CONTACT, bucketID)

        let tempContacts = await this.disk.retrieve(dbKey);

        if(tempContacts && tempContacts.raw) {

            let idx = tempContacts.findIndex(x => x.contactid == id);

            if(idx >= 0) {
                isContactFound = true;
                tempContacts.raw.splice(idx,1);
                this.disk.updateOrInsert(dbKey, doc => {
                    doc = {
                      raw: tempContacts,
                    };
                    return doc;
                })
                .catch(error => console.error('Saving Contact to DB error after purge: ', error));
            }
        }

        if(isContactFound) {

            this.contactOfflineService.contactLinkEntities.forEach(async le => {
                let tempLE = await this.disk.retrieve(le);

                if(tempLE && tempLE.raw) {
                    let idx = tempLE.raw.findIndex(x => x.contactid == id);

                    if(idx >= 0) {
                        tempLE.raw.splice(idx,1);
                        this.disk.updateOrInsert(le, doc => {
                            doc = {
                              raw: tempLE.raw,
                            };
                            return doc;
                        })
                        .catch(error => console.error('Save conact LE error after purge: ', error));
                    }
                }
            });
        }


    }

    async globalSearch(selectedSuggestions: SelectedSuggestionPillDataModel[]): Promise<any[]> {
      let finalSearchResult = [];

      if (Array.isArray(selectedSuggestions)) {
        let nonAggregatedCategorySuggestions: SelectedSuggestionPillDataModel[] = [];
        let aggregatedCategorySuggestions: SelectedSuggestionPillDataModel[] = [];

        // Split aggregated & non-aggregated categories
        for (let i = 0; i < selectedSuggestions.length; i++) {
          const suggestion = selectedSuggestions[i];
          if (suggestion.isAggregatedSection) {
            aggregatedCategorySuggestions.push(suggestion);
          } else {
            nonAggregatedCategorySuggestions.push(suggestion);
          }
        }

        // Non-aggregated
        // const nonAggregatedCategorySearchResult = await this.runGlobalSearchTask(nonAggregatedCategorySuggestions);
        let nonAggregatedCategorySearchResult;
        for (let i = 0; i < nonAggregatedCategorySuggestions.length; i++) {
          const selectedSuggestion = nonAggregatedCategorySuggestions[i];
          let donotAddBUFilter = this.authenticationOfflineService.user.globalCategorySearchLimit == 548910001 || selectedSuggestion.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH;
          //override global search limit at BU level if buline settings is set at globalsearchlimittobulevel
          if (this.authenticationOfflineService.user?.buConfigs?.indskr_globalsearchlimittobulevel) {
            donotAddBUFilter = false;
          }
          const nonAggregatedCategorySearchFetchXMLs = this.contactOfflineService.createGlobalSearchFetchXML([selectedSuggestion], donotAddBUFilter);
          let categorySearchResult = [];

          for (let j = 0; j < nonAggregatedCategorySearchFetchXMLs.length; j++) {
            const fetchXML = nonAggregatedCategorySearchFetchXMLs[j];

            if (fetchXML) {
              const searchResult = await this.runGlobalSearchTask(fetchXML);

              categorySearchResult = xorBy(categorySearchResult, searchResult, 'contactid');
            }
          }

          if (categorySearchResult.length > 0) {
            if (!nonAggregatedCategorySearchResult) {
              nonAggregatedCategorySearchResult = [
                ...categorySearchResult
              ];
            } else {
              nonAggregatedCategorySearchResult = intersectionBy(nonAggregatedCategorySearchResult, categorySearchResult, 'contactid');
            }
          } else {
            nonAggregatedCategorySearchResult = [];
            break;
          }
        }

        if (nonAggregatedCategorySuggestions.length > 0 && aggregatedCategorySuggestions.length === 0) {
          // Only need non aggregated search result
          finalSearchResult = [
            ...nonAggregatedCategorySearchResult ?? []
          ];
        } else {
          // Aggregated category search
          let aggregatedCategorySearchResult;
          for (let i = 0; i < aggregatedCategorySuggestions.length; i++) {
            const selectedSuggestion = aggregatedCategorySuggestions[i];
            const splitSuggestions = getSearchSuggestionsData(selectedSuggestion);

            let categorySearchResult: any[] = [];
            for (let j = 0; j < splitSuggestions.length; j++) {
              const suggestion = splitSuggestions[j];
              let donotAddBUFilter = this.authenticationOfflineService.user.globalCategorySearchLimit == 548910001 || selectedSuggestion.type == SuggestionPillType.ENTITY_LEVEL_CHARACTERSEARCH
              //override global search limit at BU level if buline settings is set at globalsearchlimittobulevel
              if (this.authenticationOfflineService.user?.buConfigs?.indskr_globalsearchlimittobulevel) {
                donotAddBUFilter = false;
              }
              const partialCategorySearchFetchXML = this.contactOfflineService.createGlobalSearchFetchXML([suggestion], donotAddBUFilter);
              for (let k = 0; k < partialCategorySearchFetchXML.length; k++) {
                const fetchXML = partialCategorySearchFetchXML[k];
                const partialCategorySearchResult = await this.runGlobalSearchTask(fetchXML);

                // xor each partial search result
                categorySearchResult = xorBy(categorySearchResult, partialCategorySearchResult, 'contactid');
              }
            }

            // AND each non-aggregated category search result
            if (categorySearchResult.length > 0) {
              if (!aggregatedCategorySearchResult) {
                aggregatedCategorySearchResult = [
                  ...categorySearchResult
                ];
              } else {
                aggregatedCategorySearchResult = intersectionBy(finalSearchResult, categorySearchResult, 'contactid');
              }
            } else {
              aggregatedCategorySearchResult = [];
              break;
            }
          }

          if (nonAggregatedCategorySuggestions.length === 0) {
            // Only did aggregated search
            finalSearchResult = [
              ...aggregatedCategorySearchResult ?? []
            ];
          } else if (nonAggregatedCategorySuggestions.length > 0) {
            // AND non-aggregated & aggregated search result
            finalSearchResult = intersectionBy(nonAggregatedCategorySearchResult, aggregatedCategorySearchResult, 'contactid');
          }
        }
      }
      return finalSearchResult;
    }

    private async runGlobalSearchTask(fetchXML: string): Promise<any[]> {
      let response: any[] = [];
      try {
        if (fetchXML) {
          response = await this.dynamics.executeFetchQueryWithPageNumber('contacts', fetchXML, 0);
        }
      } catch (error) {
        console.error('contact data service: runGlobalSearchTask: ', error);
      }
      return response;
    }

  async syncCustomerTag(loadFromDbOnly = false) {
    let offlineFallback: boolean = this.device.isOffline || loadFromDbOnly;
    if (offlineFallback) {
      await this.loadContactTagFromDB();
    } else {
      const syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_CUSTOMER_TAG);
      const isInitialSync = !syncState || !syncState.lastUpdatedTime;
      let lastUpdatedTime = syncState?.lastUpdatedTime;
      const contactTagsSyncInfo: EntitySyncInfo = {
        entityName: EntityNames.customer_tag,
        totalFailed: 0,
        totalSynced: 0,
        errors: [],
        syncStatus: true
      };
      if (isInitialSync) {
      //  await this.contactTagInitialSync();
      } else {
      //  await this.contactTagDeltaSync(lastUpdatedTime)
      }
      const newLastUpdatedTime = new Date().getTime().toString();
      syncState.lastUpdatedTime = newLastUpdatedTime;
      await this.disk.updateSyncState(syncState);
      if (Array.isArray(this.contactOfflineService.contactTags)) {
        contactTagsSyncInfo.totalSynced = this.contactOfflineService.contactTags.length;
      }
      this.deltaService.addEntitySyncInfo(contactTagsSyncInfo);
    }
  }

  // private async contactTagInitialSync() {
  //   try {
  //     await this.disk.deleteAllFromDbUsingAlldocsQuery(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUSTOMER_TAG);
  //     const positionIds = this.authService.user.positions.map(o => {
  //       return o.ID
  //     });
  //     let positionString = '';
  //     positionIds.forEach(p => {
  //       positionString += '<value>' + p + '</value>';
  //     })
  //     let PrivateTagfetchXml = CONTACT_FETCH_QUERIES.fetchContactPraviteTags;
  //     let publicTagfetchXml = CONTACT_FETCH_QUERIES.fetchContactPublicTags;
  //     let positionTagfetchXml = CONTACT_FETCH_QUERIES.fetchPositionTag;
  //     let assignTagfetchXml = CONTACT_FETCH_QUERIES.fetchAssignedContactTagsForBU;
  //     publicTagfetchXml = publicTagfetchXml.replace('{busUnitID}', `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`)
  //     assignTagfetchXml = assignTagfetchXml.replace(/{buId}/g, `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`).replace(/{positionIDs}/g, `${positionString}`);
  //     positionTagfetchXml = positionTagfetchXml.replace(/{positionIDs}/g, `${positionString}`);
  //     await Promise.all([
  //       this.dynamics.executeFetchQuery('indskr_usertags', PrivateTagfetchXml),
  //       this.dynamics.executeFetchQuery('indskr_usertags', publicTagfetchXml),
  //       this.dynamics.executeFetchQuery('indskr_usertags', positionTagfetchXml),
  //       this.dynamics.executeFetchQuery('indskr_usertags', assignTagfetchXml),
  //     ]).then(async response => {
  //       let privateTag = response[0];
  //       let publicTag = response[1];
  //       let positionTag = response[2];
  //       let assignTag = response[3];
  //       // let positionTag = response[3];
  //       const contactTagDetailsResponse = privateTag.concat(publicTag,assignTag,positionTag);
  //       contactTagDetailsResponse.forEach(record=> {
  //         if(!record['indskr_externalid']){
  //           record['indskr_externalid'] = record['indskr_usertagid'];
  //         }
  //       });
  //       let contactIdList = this.getContractIdsForTagIds(contactTagDetailsResponse)
  //       console.log(contactIdList);
  //       let contactTagDetails = contactTagDetailsResponse.map((tag) => {
  //         return new UserTagForContact(tag.indskr_externalid, tag.indskr_usertagid, tag.deleted, tag.indskr_name,
  //           contactIdList[tag.indskr_usertagid], false, tag.statecode,tag.indskr_visibility,tag.indskr_filter)
  //       });
  //       contactTagDetails = _.uniqBy(contactTagDetails, 'indskr_usertagid');
  //       this.contactOfflineService.contactTags = contactTagDetails;
  //       console.log("contactTagDetails", contactTagDetails);
  //       await this.disk.upsertUserTagListToDisk(contactTagDetails, TagEntityType.CONTACT)
  //     })
  //   }
  //   catch (e) {
  //     console.log(e);
  //   }
  // }

  // private async contactTagDeltaSync(lastUpdatedTime) {
  //   await this.loadContactTagFromDB();
  //   const positionIds = this.authService.user.positions.map(o => {
  //     return o.ID
  //   });
  //   let positionString = '';
  //   positionIds.forEach(p => {
  //     positionString += '<value>' + p + '</value>';
  //   })
  //   let PrivateTagfetchXml = CONTACT_FETCH_QUERIES.updatedContactPrivateTags;
  //   let publicTagfetchXml = CONTACT_FETCH_QUERIES.updatedContactPublicTags;
  //   let assignTagfetchXml = CONTACT_FETCH_QUERIES.updatedAssignedContactTags; 
  //   let positionTagfetchXml = CONTACT_FETCH_QUERIES.updatedContactPosTags; 
  //   assignTagfetchXml = assignTagfetchXml.replace(/{buId}/g, `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`).replace(/{positionIDs}/g, `${positionString}`);
  //   publicTagfetchXml = publicTagfetchXml.replace('{busUnitID}', `${this.authService.user.buConfigs.indskr_bulevelconfigurationid}`);
  //   positionTagfetchXml = positionTagfetchXml.replace(/{positionIDs}/g, `${positionString}`);
  //   lastUpdatedTime = moment(parseInt(lastUpdatedTime)).format('YYYY-MM-DD HH:mm:ss');
  //   console.log("lastUpdatedTime", lastUpdatedTime)
  //   PrivateTagfetchXml = PrivateTagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
  //   publicTagfetchXml = publicTagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
  //   assignTagfetchXml = assignTagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
  //   positionTagfetchXml = positionTagfetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
  //   let deletedUserTagsFetchXml = CONTACT_FETCH_QUERIES.deletedUserTagsFetchXml;
  //   deletedUserTagsFetchXml = deletedUserTagsFetchXml.replace('{lastUpdatedTime}', lastUpdatedTime)
  //   await Promise.all([
  //     this.dynamics.executeFetchQuery('indskr_usertags', PrivateTagfetchXml),
  //     this.dynamics.executeFetchQuery('indskr_usertags', publicTagfetchXml),
  //     this.dynamics.executeFetchQuery('indskr_usertags', assignTagfetchXml),
  //     this.dynamics.executeFetchQuery('indskr_usertags', positionTagfetchXml),
  //     this.dynamics.executeFetchQuery('indskr_trackchanges', deletedUserTagsFetchXml)
  //   ]).then(response => {
  //     let privateTag = response[0];
  //     let publicTag = response[1];
  //     let positionTag = response[2];
  //     let assignTag = response[3];
  //     let updateContactTags = privateTag.concat(publicTag,assignTag,positionTag);
  //     updateContactTags.forEach(record=> {
  //       if(!record['indskr_externalid']){
  //         record['indskr_externalid'] = record['indskr_usertagid'];
  //       }
  //     });
  //     let deletedContactTags = response[2];
  //     if (!_.isEmpty(deletedContactTags)) {
  //       deletedContactTags.forEach(async trackChangedTag => {

  //         let index = this.contactOfflineService.contactTags
  //           .findIndex(tag => (tag.indskr_usertagid === trackChangedTag['indskr_usertagid']));

  //         if (index >= 0) {
  //           await this.disk.remove(DB_KEY_PREFIXES.CONTACT_TAG + this.contactOfflineService.contactTags[index].indskr_externalid);
  //           this.contactOfflineService.contactTags.splice(index, 1);
  //         }
  //       });
  //     }
  //     if (!_.isEmpty(updateContactTags)) {
  //       let contactIdList = this.getContractIdsForTagIds(updateContactTags)
  //       console.log("contactIdList", contactIdList);
  //       let contactTagDetails: UserTagForContact[] = updateContactTags.map((tag) => {
  //         return new UserTagForContact(tag.indskr_externalid, tag.indskr_usertagid, tag.deleted, tag.indskr_name, contactIdList[tag.indskr_usertagid], false, tag.statecode,tag.indskr_visibility,tag.indskr_filter)
  //       });
  //       contactTagDetails = _.uniqBy(contactTagDetails, 'indskr_usertagid');
  //       console.log(" contactTagDetails", contactTagDetails)
  //       contactTagDetails.forEach(async (tagDetails) => {

  //         const checkTagIndex = this.contactOfflineService.contactTags.findIndex(tag => tag.indskr_externalid === tagDetails.indskr_externalid)
  //         if (checkTagIndex > -1) {
  //           this.contactOfflineService.contactTags.splice(checkTagIndex, 1);
  //         }
  //         // if Tag data is updated with contacts
  //         if (tagDetails.stateCode === CustomerTagStateCode.Active) {
  //           this.contactOfflineService.contactTags.push(tagDetails);
  //           await this.disk.upsertUserTag(tagDetails, TagEntityType.CONTACT);
  //         } else {
  //           await this.disk.remove(DB_KEY_PREFIXES.CONTACT_TAG + tagDetails.indskr_externalid);
  //         }
  //       });
  //       this.contactOfflineService.contactTags = _.sortBy(this.contactOfflineService.contactTags, "indskr_name");
  //       this.contactOfflineService.contactTags = _.uniqBy(this.contactOfflineService.contactTags, "indskr_usertagid");
  //     }
  //     console.log(this.contactOfflineService.contactTags, " this.contactOfflineService.contactTags");
  //   })
  //     .catch((e) => { console.error('uploadOfflineData: ', e); return false; });
  // }

 async uploadOfflineContactTag(isPartialUpload = false, maxRecordCountForPartialUpload = 10) {
  if (!isPartialUpload && this.device.isOffline) return;
  let option = {
    selector: {
      '_id': {
        $gte: DB_KEY_PREFIXES.CONTACT_TAG,
        $lte: DB_KEY_PREFIXES.CONTACT_TAG + PREFIX_SEARCH_ENDKEY_UNICODE
      },
      'pendingPushToDynamics': { $eq: true }
    }
  };

  try {
    let userTags: UserTagForContact[] = await this.disk.find(option);
    if(userTags){
      userTags.forEach(x=>{
        let tagid = this.contactService.contactTags.find(v=>v.indskr_externalid==x.indskr_externalid)
        if(tagid){
          x.indskr_usertagid=this.contactService.contactTags.find(v=>v.indskr_externalid==x.indskr_externalid).indskr_usertagid
        }
      })
    }
    if (userTags && userTags.length) {
      if (isPartialUpload) {
        if (userTags.length > maxRecordCountForPartialUpload) {
          userTags = userTags.splice(0, maxRecordCountForPartialUpload);
        }
      }
      // userTags = userTags.filter(tag => tag.stateCode === CustomerTagStateCode.Active);
      const uploadTime = new Date().getTime().toString();
    await  this.contactOfflineService.uploadOfflineData(userTags).subscribe(response =>{
         if(response){
          response.forEach(async res => {
            let data = res;
            const indskr_externalid = data['indskr_externalid'];
            const index = userTags.findIndex(tag => tag.indskr_externalid === indskr_externalid);
            if(index > -1){
              userTags[index].pendingPushToDynamics = false;
              userTags[index].indskr_usertagid = data['indskr_usertagid'];
              if(userTags[index].deleted){
                await this.disk.remove(DB_KEY_PREFIXES.CONTACT_TAG + userTags[index].indskr_externalid);
              }
            }
            let tagCont = this.contactService.contactTags.find(x=>x.indskr_externalid == indskr_externalid )
            if(tagCont) tagCont.indskr_usertagid = data['indskr_usertagid'];
          });
         }
      }, error =>{
        console.error(error);
      });
      const newCount = userTags.filter(userTag => userTag.pendingPushToDynamics);
      this.disk.subtractOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT_TAG, newCount ? newCount.length : 0);
    }

  } catch (err) {
    console.error("Failed to upload offline contact tag: ", err);
  }

}


  private async loadContactTagFromDB() {
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUSTOMER_TAG).then((data: UserTagForContact[]) => {
      //check for delete true
      this.contactOfflineService.contactTags = data;
    });
  }
  private getContractIdsForTagIds(updateContactTags: any[]) {
    return updateContactTags.reduce((data, val) => {
      data[val.indskr_usertagid] = data[val.indskr_usertagid] || [];
      let contactId = new ContactTag(val["contactId"])
      if (contactId) {
        data[val.indskr_usertagid].push(contactId)
      } else if (!data[val.indskr_usertagid].length) {
        data[val.indskr_usertagid] = []
      }
      return data;
    }, {});
  }

   /*************Edit business information in offline - Non-OneKey contacts*************/
   public isOffline(contact) {
    return this.device.isOffline ||
      this.hasOfflineContactsData(contact.contactid);
  }

  addToOfflineContactsIds(id: String) {
    this.offlineContactIds.set(id, true);
  }

  deleteFromOfflineContactsIds(id: String) {
    this.offlineContactIds.delete(id);
  }

  hasOfflineContactsData(id: String) {
    return this.offlineContactIds.has(id);
  }

  public async uploadOfflineContacts(isPartialUpload = false, maxRecordCountForPartialUpload = 10) {
    if (!isPartialUpload && this.device.isOffline) return;
    const offlineContacts = await this.disk.loadOfflineContacts();
    const contactsSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.contact,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    if (offlineContacts && offlineContacts['contacts'] && Array.isArray(offlineContacts['contacts']) && offlineContacts['contacts'].length > 0) {
      try {
        let updatedRequestBody = offlineContacts['contacts'];

        if (isPartialUpload) {
          if (offlineContacts['eventRegResponse'].length > maxRecordCountForPartialUpload) {
            updatedRequestBody = JSON.parse(JSON.stringify(offlineContacts['eventRegResponse']));
            updatedRequestBody = updatedRequestBody.splice(0, maxRecordCountForPartialUpload);
          }
        }
        const offlineContactsResponse = await this.uploadOfflineContactsToDynamics(updatedRequestBody);
        for (let index = 0; index < offlineContactsResponse.length; index++) {
          let cResponse = offlineContactsResponse[index];
          if (!cResponse['errorId']) {
            let idx;
            offlineContacts['contacts'].findIndex((a, index) => {
              if (a.contactid && a.contactid == cResponse['contactid']) {
                idx = index;
              }
            });
            if (idx >= 0) {
              let coaching = offlineContacts['contacts'][idx];
              let idToBeUpdated: String = coaching.contactid; //To support old records
              this.deleteFromOfflineContactsIds(idToBeUpdated);
              // await this.createOfflineContactReportInDB(updatedRequestBody, cResponse, coaching);
              offlineContacts['contacts'].splice(idx, 1);
              offlineContacts['count']--
            }
            else {
              console.warn(`uploadOfflineContact: ID ${cResponse.contactid} is not found from local db.`);
            }
            contactsSyncInfo.totalSynced++;
          }
        }
      } catch (httpError) {
        this.deltaService.addSyncErrorToEntitySyncInfo(contactsSyncInfo, this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.UPLOAD_OFFLINE_CONTACTS, httpError);
        contactsSyncInfo.totalFailed += contactsSyncInfo['count'];
      }
      this.deltaService.addEntitySyncInfo(contactsSyncInfo, true);
      await this.disk.updateDocWithIdAndRev(offlineContacts);

      // Track offline data count
      const newCount = offlineContacts['contacts'].length - contactsSyncInfo.totalSynced + contactsSyncInfo.totalFailed
      this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.CONTACT, newCount > 0 ? newCount : 0);
    }
  }

  public async uploadOfflineContactsToDynamics(offlineContacts: any[]) : Promise<any[]>{
    const url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl +
      Endpoints.contacts.UPLOAD_OFFLINE_CONTACTS;
    return this.http.post<any[]>(url, offlineContacts).toPromise();
  }

  public isOfflineForAddedNewData(linkEntity) {
    return this.device.isOffline ||
      this.hasOfflineAddedNewData(linkEntity.addedNewDataId);
  }

  addToOfflineAddedNewDataIds(id: String) {
    this.offlineAddedNewDataIds.set(id, true);
  }

  deleteFromOfflineAddedNewDataIds(id: String) {
    this.offlineAddedNewDataIds.delete(id);
  }

  hasOfflineAddedNewData(id: String) {
    return this.offlineAddedNewDataIds.has(id);
  }

  public async uploadOfflineLinkedEntities(isPartialUpload = false, maxRecordCountForPartialUpload = 10) {
    // if (!isPartialUpload && this.device.isOffline) return;
    
    const offlineLinkedEntities = await this.disk.loadOfflineLinkedEntities();
    const linkenEntitiesSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.linkedEntities,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    if (offlineLinkedEntities && offlineLinkedEntities['linkedEntities'] && Array.isArray(offlineLinkedEntities['linkedEntities']) && offlineLinkedEntities['linkedEntities'].length > 0) {
      try {
        let updatedRequestBody = offlineLinkedEntities['linkedEntities'];

        if (isPartialUpload) {
          if (offlineLinkedEntities['eventRegResponse'].length > maxRecordCountForPartialUpload) {
            updatedRequestBody = JSON.parse(JSON.stringify(offlineLinkedEntities['eventRegResponse']));
            updatedRequestBody = updatedRequestBody.splice(0, maxRecordCountForPartialUpload);
          }
        }

        const offlineLinkedEntitiesResponse = await this.uploadOfflineLinkedEntitiesToDynamics(updatedRequestBody);
        for (let index = 0; index < offlineLinkedEntitiesResponse.length; index++) {
          let cResponse = offlineLinkedEntitiesResponse[index];
          if (!cResponse['errorId']) {
            let idx;
            offlineLinkedEntities['linkedEntities'].findIndex((a, index) => {
              if (a.addedNewDataId && a.addedNewDataId == cResponse['addedNewDataId']) {
                idx = index;
              } else if (a.guid && a.guid == cResponse['guid']) {
                idx = index;
              }
            });
            if (idx >= 0) {
              let coaching = offlineLinkedEntities['linkedEntities'][idx];
              let idToBeUpdated: String = coaching.addedNewDataId; //To support old records
              this.deleteFromOfflineAddedNewDataIds(idToBeUpdated);
              offlineLinkedEntities['linkedEntities'].splice(idx, 1);
              offlineLinkedEntities['count']--
            }
            else {
              console.warn(`uploadLinkedEntity: ID ${cResponse.addedNewDataId} is not found from local db.`);
            }
            linkenEntitiesSyncInfo.totalSynced++;
          }
        }
      } catch (httpError) {
        this.deltaService.addSyncErrorToEntitySyncInfo(linkenEntitiesSyncInfo, this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.contacts.UPLOAD_OFFLINE_CONTACTS, httpError);
        linkenEntitiesSyncInfo.totalFailed += linkenEntitiesSyncInfo['count'];
      }
      this.deltaService.addEntitySyncInfo(linkenEntitiesSyncInfo, true);
      await this.disk.updateDocWithIdAndRev(offlineLinkedEntities);

      // Track offline data count
      const newCount = offlineLinkedEntities['linkedEntities'].length - linkenEntitiesSyncInfo.totalSynced + linkenEntitiesSyncInfo.totalFailed
      this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.LINKED_ENTITIES, newCount > 0 ? newCount : 0);
    }
  }

  public async uploadOfflineLinkedEntitiesToDynamics(offlineLinkedEntites: any[]) : Promise<any[]>{
    const url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl +
      Endpoints.dynamic_forms.OFFLINE_CREATE_LINKED_ENTITY;
    return this.http.post<any[]>(url, offlineLinkedEntites).toPromise();
  }
  /*************Edit business information in offline - Non-OneKey contacts*************/

  /*************Edit business Change Request in offline*************/
  public isOfflineBusinessCR(contactcr) {
    return this.device.isOffline ||
      this.hasOfflineBusinessCRsData(contactcr.indskr_contactcrid);
  }

  addToOfflineBusinessCRIds(id: String) {
    this.offlineBusinessCRIds.set(id, true);
  }

  deleteFromOfflineBusinessCRIds(id: String) {
    this.offlineBusinessCRIds.delete(id);
  }

  hasOfflineBusinessCRsData(id: String) {
    return this.offlineBusinessCRIds.has(id);
  }

  public async uploadOfflineBusinessCRs(isPartialUpload = false, maxRecordCountForPartialUpload = 10) {
    if (!isPartialUpload && this.device.isOffline) return;
    const offlineBusinessCRs = await this.disk.loadOfflineBusinessCRs();
    const contactsSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.contactCR,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    if (offlineBusinessCRs && offlineBusinessCRs['businessCRs'] && Array.isArray(offlineBusinessCRs['businessCRs']) && offlineBusinessCRs['businessCRs'].length > 0) {
      try {
        let updatedRequestBody = offlineBusinessCRs['businessCRs'];

        if (isPartialUpload) {
          if (offlineBusinessCRs['eventRegResponse'].length > maxRecordCountForPartialUpload) {
            updatedRequestBody = JSON.parse(JSON.stringify(offlineBusinessCRs['eventRegResponse']));
            updatedRequestBody = updatedRequestBody.splice(0, maxRecordCountForPartialUpload);
          }
        }
        const offlineBusinessCRsResponse = await this.uploadOfflineBusinessAndOneKeyCRsToDynamics(updatedRequestBody);
        for (let index = 0; index < offlineBusinessCRsResponse.length; index++) {
          let cResponse = offlineBusinessCRsResponse[index];
          if (!cResponse['errorId']) {
            let idx;
            offlineBusinessCRs['businessCRs'].findIndex((a, index) => {
              if (a.indskr_contactcrid && a.indskr_contactcrid == cResponse['contactChangeRequestId']) {
                idx = index;
              }
            });
            if (idx >= 0) {
              let businessCR = offlineBusinessCRs['businessCRs'][idx];
              let idToBeUpdated: String = businessCR.contactid; //To support old records
              this.deleteFromOfflineBusinessCRIds(idToBeUpdated);

              offlineBusinessCRs['businessCRs'].splice(idx, 1);
              offlineBusinessCRs['count']--
            }
            else {
              console.warn(`uploadOfflineBusinessCRs: ID ${cResponse.contactChangeRequestId} is not found from local db.`);
            }
            contactsSyncInfo.totalSynced++;
          }
        }
      } catch (httpError) {
        this.deltaService.addSyncErrorToEntitySyncInfo(contactsSyncInfo, this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.UPLOAD_OFFLINE_CR, httpError);
        contactsSyncInfo.totalFailed += contactsSyncInfo['count'];
      }
      this.deltaService.addEntitySyncInfo(contactsSyncInfo, true);
      await this.disk.updateDocWithIdAndRev(offlineBusinessCRs);

      // Track offline data count
      const newCount = offlineBusinessCRs['businessCRs'].length - contactsSyncInfo.totalSynced + contactsSyncInfo.totalFailed
      this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.BUSINESS_CHANGE_REQUEST, newCount > 0 ? newCount : 0);
    }
  }

  public async uploadOfflineBusinessAndOneKeyCRsToDynamics(offlineBusinessCRs: any[]) : Promise<any[]>{
    const url: string = this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl +
      Endpoints.mdm.UPLOAD_OFFLINE_CR;
    return this.http.put<any[]>(url, offlineBusinessCRs).toPromise();
  }
  /*************Edit business Change Request in offline*************/

  /*************Create OneKey Change Request in offline*************/
  public isOfflineOneKeyCR(addedNewOneKeyCRId) {
    return this.device.isOffline ||
      this.hasOfflineOneKeyCRsData(addedNewOneKeyCRId);
  }

  addToOfflineOneKeyCRIds(id: String) {
    this.offlineOneKeyCRIds.set(id, true);
  }

  deleteFromOfflineOneKeyCRIds(id: String) {
    this.offlineOneKeyCRIds.delete(id);
  }

  hasOfflineOneKeyCRsData(id: String) {
    return this.offlineOneKeyCRIds.has(id);
  }

  public async uploadOfflineOneKeyCRs(isPartialUpload = false, maxRecordCountForPartialUpload = 10) {
    if (!isPartialUpload && this.device.isOffline) return;
    const offlineOneKeyCRs = await this.disk.loadOfflineOneKeyCRs();
    const contactsSyncInfo: EntitySyncInfo = {
      entityName: EntityNames.contactCR,
      totalFailed: 0,
      totalSynced: 0,
      errors: [],
      syncStatus: true
    };
    if (offlineOneKeyCRs && offlineOneKeyCRs['oneKeyCRs'] && Array.isArray(offlineOneKeyCRs['oneKeyCRs']) && offlineOneKeyCRs['oneKeyCRs'].length > 0) {
      try {
        let updatedRequestBody = offlineOneKeyCRs['oneKeyCRs'];

        if (isPartialUpload) {
          if (offlineOneKeyCRs['eventRegResponse'].length > maxRecordCountForPartialUpload) {
            updatedRequestBody = JSON.parse(JSON.stringify(offlineOneKeyCRs['eventRegResponse']));
            updatedRequestBody = updatedRequestBody.splice(0, maxRecordCountForPartialUpload);
          }
        }
        const offlineOneKeyCRsResponse = await this.uploadOfflineBusinessAndOneKeyCRsToDynamics(updatedRequestBody);
        for (let index = 0; index < offlineOneKeyCRsResponse.length; index++) {
          let cResponse = offlineOneKeyCRsResponse[index];
          if (!cResponse['errorId']) {
            let idx;
            offlineOneKeyCRs['oneKeyCRs'].findIndex((a, index) => {
              if (a.addedNewOneKeyCRId && a.addedNewOneKeyCRId == cResponse['addedNewOneKeyCRId']) {
                idx = index;
              }
            });
            if (idx >= 0) {
              let oneKeyCR = offlineOneKeyCRs['oneKeyCRs'][idx];
              let idToBeUpdated: String = oneKeyCR.addedNewOneKeyCRId; //To support old records
              this.deleteFromOfflineOneKeyCRIds(idToBeUpdated);

              //***To update ContactCRInPouchDB with newly created indskr_contactcrid after sync***//
              await this.mdmService.updateContactCRInPouchDBWithContactCRId(cResponse)

              let contactCRs = this.mdmService.contactCRs.getValue();
              let newIdx;
              if (Array.isArray(contactCRs) && contactCRs.length) {
                newIdx = contactCRs.findIndex(a => a['addedNewOneKeyCRId'] == cResponse['addedNewOneKeyCRId']);
              }
              if (newIdx >= 0) {
                let selectedContactCR = contactCRs[newIdx];
                selectedContactCR['indskr_contactcrid'] = cResponse['contactChangeRequestId'];

                contactCRs[newIdx] = selectedContactCR;
              }
              this.mdmService.contactCRs.next(contactCRs);
              //***To update ContactCRInPouchDB with newly created indskr_contactcrid after sync***//

              offlineOneKeyCRs['oneKeyCRs'].splice(idx, 1);
              offlineOneKeyCRs['count']--
            }
            else {
              console.warn(`uploadOfflineOneKeyCRs: ID ${cResponse.addedNewOneKeyCRId} is not found from local db.`);
            }
            contactsSyncInfo.totalSynced++;
          }
        }
      } catch (httpError) {
        this.deltaService.addSyncErrorToEntitySyncInfo(contactsSyncInfo, this.authenticationOfflineService.userConfig.activeInstance.entryPointUrl + Endpoints.mdm.UPLOAD_OFFLINE_CR, httpError);
        contactsSyncInfo.totalFailed += contactsSyncInfo['count'];
      }
      this.deltaService.addEntitySyncInfo(contactsSyncInfo, true);
      await this.disk.updateDocWithIdAndRev(offlineOneKeyCRs);

      // Track offline data count
      const newCount = offlineOneKeyCRs['oneKeyCRs'].length - contactsSyncInfo.totalSynced + contactsSyncInfo.totalFailed
      this.disk.setOfflineDataCount(OFFLINE_DATA_COUNT_ENTITY_NAME.ONE_KEY_CHANGE_REQUEST, newCount > 0 ? newCount : 0);
    }
  }
  /*************Create OneKey Change Request in offline*************/


  public async getContactWebsiteAccessLogsTimeline(contact: Contact) {
    if (!this.device.isOffline) {
      try {
        const fetchXml = FETCH_WEBSITE_ACCESS_LOGS.fetchWebsiteAcessLogsForContact.replace("{contactid}", contact.ID);
        const contactWebsiteAccessLogsResponse = await this.dynamics.executeFetchQuery('indskr_websiteaccesslogses', fetchXml);
        console.log(contactWebsiteAccessLogsResponse);
        contact.mapWebsiteAccessLogs(contactWebsiteAccessLogsResponse)
      }
      catch (error) {
        console.log("Invaild Customer Timeline Website Access Logs data", error);
      }
    }
  }

  public async syncSubSpecialtiesRecords(fullSync?: boolean, loadFromDBOnly = false) {
    if (!this.device.isOffline && this.authenticationOfflineService.user.buConfigs['indskr_primarysubspecialty']) {
      let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.SUB_SPECIALTIES);
      let lastUpdatedTime;
      if (offlineData && offlineData.raw && !fullSync) {
        if (offlineData.lastUpdatedTime) {
          lastUpdatedTime = offlineData.lastUpdatedTime;
        }
      }
      if (!loadFromDBOnly) {
        let syncState = await this.disk.getSyncState(DB_SYNC_STATE_KEYS.SYNC_SUB_SPECIALTIES);
        const bulkSubSpecialtiesSyncInfo: EntitySyncInfo = {
          entityName: EntityNames.sub_specialties,
          totalFailed: 0,
          totalSynced: 0,
          errors: [],
          syncStatus: true
        };
        const now = new Date().getTime();
        let fetchXML = CONTACT_FETCH_QUERIES.fetchSubSpecialties;

        if (lastUpdatedTime && !fullSync) {
          let hourDifference = differenceInHours(now, new Date(lastUpdatedTime));
          hourDifference += 1
          fetchXML = fetchXML.replace('{hourDifference}', `${hourDifference}`);
        } else {
          fetchXML = fetchXML.replace('<condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" />', '');
        }

        let response = await this.dynamics.executeFetchQuery('indskr_lu_specialties', fetchXML);

        lastUpdatedTime = new Date().getTime();
        if (response && Array.isArray(response) && response.length != 0 && response[0]) {
          bulkSubSpecialtiesSyncInfo.totalSynced += response.length;
          if (offlineData && offlineData.raw && !fullSync) {
            response.forEach(item => {
              try {
                if(item['indskr_lu_specialtyid']){
                  let idx = offlineData.raw.findIndex(a => a.indskr_lu_specialtyid == item.indskr_lu_specialtyid);
                  if(idx >= 0){
                    let indIdx = offlineData.raw[idx].subSpecialties.findIndex(b => b.indskr_subspecialtiesid == item['a.indskr_subspecialtiesid']);
                    if(indIdx >= 0){
                      if(item['a.indskr_subspecialtiesid'] && item['subSpecialties.statecode'] == 0){
                        // Already Present
                      }else if(item['a.indskr_subspecialtiesid']){
                        offlineData.raw[idx].subSpecialties.splice(indIdx,1);
                      }
                    }else{
                      if(item['a.indskr_subspecialtiesid'] && item['a.statecode'] == 0){
                        let obj = {
                          'indskr_subspecialtiesid': item['a.indskr_subspecialtiesid'],
                          'indskr_name': item['a.indskr_name'],
                        }
                        offlineData.raw[idx].subSpecialties.push(obj);
                      }
                    }
                  }else{
                    let obj = {
                      'indskr_lu_specialtyid': item['indskr_lu_specialtyid'],
                      'indskr_specialty': item['indskr_specialty'],
                      'subSpecialties': [],
                    }
                    if(item['a.indskr_subspecialtiesid'] && item['a.statecode'] == 0){
                      obj.subSpecialties.push({
                        'indskr_subspecialtiesid': item['a.indskr_subspecialtiesid'],
                        'indskr_name': item['a.indskr_name'],
                      });
                      offlineData.raw.push(obj);
                    }
                  }
                }
              } catch (error) {
                console.log(error);
              }
            });
            offlineData.lastUpdatedTime = lastUpdatedTime;
          } else {
            response = this.aggregateSubSpecialties(response);
            offlineData = {
              raw: response,
              lastUpdatedTime: lastUpdatedTime,
            }
          }
          syncState.lastUpdatedTime = lastUpdatedTime;
          await this.disk.updateSyncState(syncState);
          await this.deltaService.addEntitySyncInfo(bulkSubSpecialtiesSyncInfo);
          await this.disk.updateOrInsert(DB_KEY_PREFIXES.SUB_SPECIALTIES, doc => {
            doc = offlineData;
            return doc;
          }).catch(error => console.error('Save sub specialties data in offline db error: ', error));
        }
      }
    }
  }

  aggregateSubSpecialties(data):Array<any>{
    let result = [];
    if(data && data.length){
      data.forEach(record=>{
        let idx = result.findIndex(a=> a['indskr_lu_specialtyid'] == record['indskr_lu_specialtyid']);
        if(idx >= 0 && record['a.indskr_subspecialtiesid']){
          if(record['a.statecode'] == 0){
            let idx2 = result[idx]['subSpecialties'].findIndex(b=> b.indskr_subspecialtiesid == record['a.indskr_subspecialtiesid']);
            if(idx2 >= 0 ){
              // Already Present
            }else{
              let obj = {
                'indskr_subspecialtiesid': record['a.indskr_subspecialtiesid'],
                'indskr_name': record['a.indskr_name'],
              }
              result[idx]['subSpecialties'].push(obj);
            }
          }
        }else{
          let obj = {
            'indskr_lu_specialtyid': record['indskr_lu_specialtyid'],
            'indskr_specialty': record['indskr_specialty'],
            'subSpecialties': [],
          }
          if(record['a.indskr_subspecialtiesid'] && record['a.statecode'] == 0){
            obj.subSpecialties.push({
              'indskr_subspecialtiesid': record['a.indskr_subspecialtiesid'],
              'indskr_name': record['a.indskr_name'],
            });
          }
          result.push(obj);
        }
      })
    }
    return result;
  }

  private _escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
  
   replaceAll(str, find, replace) {
    return str.replace(new RegExp(this._escapeRegExp(find), 'g'), replace);
  }

  // --------------------------------Team Users - Filter levels-------------------------------- //
  public async getTargetContactsBasedOnCurrentCustomerListPeriod(positionId: string, currentCustomerListPeriodId: string): Promise<string[]> {
    console.log(`getTargetContactsBasedOnCurrentCustomerListPeriod`);
    let arr = [];

    let fetchXML = fetchQueries.fetchTargetContactsBasedOnCurrentCustomerListPeriod;

    try {

      let positionIDs = /\{positionID}/gi;
      fetchXML = fetchXML.replace(positionIDs, `${positionId}`);
      // fetchXML = fetchXML.replace('{userId}', `${this.authenticationOfflineService.user.xSystemUserID}`);
      fetchXML = fetchXML.replace('{currentCustomerListPeriodId}', `${currentCustomerListPeriodId}`);
      let response = await this.dynamics.executeFetchQuery('contacts', fetchXML);
      console.warn(`getTargetContactsBasedOnCurrentCustomerListPeriod: executeFetchQuery`);
      console.log(response);

      if (!_.isEmpty(response)) {
        response = response.map((contact)=> {
          contact['ID'] = contact['contactid'];
          return contact;
        }); 
        arr = response;
      }
    } catch (error) {
      console.error('getTargetContactsBasedOnCurrentCustomerListPeriod: error:', error);
    }
    return arr;
  }

  public async getTargetContactsBasedOnPositionId(positionId: string): Promise<string[]> {
    console.log(`getTargetContactsBasedOnPositionId`);
    let response = [];

    try {
      const todayDate = moment().format("YYYY-MM-DD");
      let fetchXML = fetchQueries.fetchTargetContactsBasedOnPositionId
       .replace('{date}', todayDate)
      .replace('{date}', todayDate)
      .split('{positionID}').join(positionId);
      fetchXML = fetchXML.replace('{secondaryinfoFetchXml}', this.secondaryInfoService.SecondaryInfoFetchXML(SecondaryInfoEntityName.Contact));

      response = await this.dynamics.executeFetchQuery('contacts', fetchXML);
      console.warn(`getTargetContactsBasedOnPositionId: executeFetchQuery`);
      console.log(response);
      //Incase of linkEntity in secondary info cartisias product is available in response, hence iterate res and take which has all values in res
      let dataToSave = [];
      for(let val of response) {
        const index = dataToSave.findIndex(acc => acc.contactid === val.contactid);
        if(index >= 0) {
          if(_.entries(val).length > _.entries(dataToSave[index]).length) {
            dataToSave[index] = val;
          }
        } else {
          dataToSave.push(val);
        }
      }
      response = dataToSave;
      console.warn(`Contact: After linkEntity in secondary info cartisias product:`);
      console.log(response);
    } catch (error) {
      console.error('getTargetContactsBasedOnPositionId: error:', error);
    }
    return response;
  }

  public async getContactsBasedOnPositionId(positionId: string): Promise<string[]> {
    console.log(`getContactsBasedOnPositionId`);
    let response = [];

    let fetchXML = fetchQueries.fetchContactsBasedOnPositionId;
    fetchXML = fetchXML.replace('{secondaryinfoFetchXml}', this.secondaryInfoService.SecondaryInfoFetchXML(SecondaryInfoEntityName.Contact));

    try {
      fetchXML = fetchXML.replace('{positionID}', `${positionId}`);
      
      response = await this.dynamics.executeFetchQuery('contacts', fetchXML);
      console.warn(`getContactsBasedOnPositionId: executeFetchQuery`);
      console.log(response);
      //Incase of linkEntity in secondary info cartisias product is available in response, hence iterate res and take which has all values in res
      let dataToSave = [];
      for(let val of response) {
        const index = dataToSave.findIndex(acc => acc.contactid === val.contactid);
        if(index >= 0) {
          if(_.entries(val).length > _.entries(dataToSave[index]).length) {
            dataToSave[index] = val;
          }
        } else {
          dataToSave.push(val);
        }
      }
      response = dataToSave;
      console.warn(`Contact: After linkEntity in secondary info cartisias product:`);
      console.log(response);
    } catch (error) {
      console.error('getContactsBasedOnPositionId: error:', error);
    }
    return response;
  }

  public async getAllChildUsersBasedOnPositionId(positionId: string): Promise<string[]> {
    console.log(`getAllChildUsersBasedOnPositionId`);
    let arr = [];

    try {
      let fetchXML = fetchQueries.fetchAllChildUsersBasedOnPositionId;
      fetchXML = fetchXML.replace('{positionID}', `${positionId}`);
      let responseUserpositions = await this.dynamics.executeFetchQuery('indskr_userpositions', fetchXML);
      console.warn(`getAllChildUsersBasedOnPositionId >> responseUserpositions: executeFetchQuery`);
      console.log(responseUserpositions);

      if (!_.isEmpty(responseUserpositions)) {
        arr = responseUserpositions;
      }

      fetchXML = fetchQueries.fetchAllChildPositionsBasedOnPositionId;
      fetchXML = fetchXML.replace('{positionID}', `${positionId}`);
      let responsePositions = await this.dynamics.executeFetchQuery('positions', fetchXML);
      console.warn(`getAllChildUsersBasedOnPositionId >> responsePositions: executeFetchQuery`);
      console.log(responsePositions);

      if (!_.isEmpty(responsePositions)) {
        arr = Object.assign([], responseUserpositions);
        
        responsePositions.forEach((position) => {
          const foundItem = this.findById(
            responseUserpositions,
            position.childpositionid
          );
    
          if(!foundItem) {
            arr.push(position);
          }
        });

      }
    } catch (error) {
      console.error('getAllChildUsersBasedOnPositionId: error:', error);
    }
    return arr;
  }

  public findById(array, id) {
    for (const item of array) {
      if (item.childpositionid === id) return item;
    }
  }

  public async getActiveConsents(fullSync?: boolean, loadFromDBOnly = false) {
    if (!this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CONSENT_TOOL)) {
      let offlineData = await this.disk.retrieve(DB_KEY_PREFIXES.ACTIVE_CONSENTS);
      if(loadFromDBOnly){
        this.contactService.activeConsents = offlineData ? offlineData.raw : [];
      } else {
        let fetchXML = fetchQueries.fetchConsents;
        let positionIds = this.authenticationOfflineService.user.positions.map(o => {
          return o.ID
        });
        let positionString = '';
        positionIds.forEach(p => {
          positionString += '<value>' + p + '</value>'
        })
        fetchXML = fetchXML.split('{PositionIds1}').join(positionString);
        fetchXML = fetchXML.split('{PositionIds2}').join(positionString);
        const now = new Date().getTime();
        if (fullSync) {
          fetchXML = fetchXML.replace('<filter type="and"><condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" /></filter>', '');
        } else if (offlineData?.lastUpdatedTime) {
          let hourDifference = differenceInHours(now, new Date(offlineData?.lastUpdatedTime));
          hourDifference += 1
          fetchXML = fetchXML.replace('{hourDifference}', `${hourDifference}`);
          fetchXML = fetchXML.replace('<filter type="and"><condition attribute="statecode" operator="eq" value="0" /></filter>', '');
        } else {
          fetchXML = fetchXML.replace('<filter type="and"><condition attribute="modifiedon" operator="last-x-hours" value="{hourDifference}" /></filter>', '');
        }
        const response = await this.dynamics.executeFetchQuery('indskr_consents', fetchXML);
        if (response && Array.isArray(response) && response.length > 0) {
          let consents = response;
          if (!fullSync && offlineData?.raw?.length > 0) {
            const nonActiveConsents = consents.filter(consent => consent.statecode != 0);
            const nonActiveConsentsId = new Set(nonActiveConsents.map(consent => consent.indskr_consentid));
            offlineData.raw = offlineData.raw.filter(consent => !nonActiveConsentsId.has(consent.indskr_consentid));
            consents = consents.filter(c => c.statecode == 0);
          }
          offlineData = {
            raw: [...(offlineData?.raw || []), ...consents],
            lastUpdatedTime: now
          }
        }
        this.contactService.activeConsents = offlineData.raw;
        await this.disk.updateOrInsert(DB_KEY_PREFIXES.ACTIVE_CONSENTS, doc => {
          doc = offlineData;
          return doc;
        }).catch(error => console.error('Save active sonsents with contact.daba.service in offline db error: ', error));
      }
    }
  }

  //Journeys - real time marketing journeys
  public async fetchMarketingJourneys(forceFullSync: boolean = false, loadFromDBOnly = false) {
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_JOURNEY_TIMELINE)) {
      this.isCustomerJourneyTimelineEnabled = true;
    }
    if (!this.isCustomerJourneyTimelineEnabled) return;
    try {
      let offlineData = await this.loadOfflineMarkeingJourneys();
      if (offlineData && offlineData.raw && offlineData.raw.length > 0) {
        this.contactOfflineService.marketingJourneys = [];
        offlineData.raw.forEach(data => {
          const journey = new MarketingJourney(data);
          this.contactOfflineService.marketingJourneys.push(journey);
        });
      }
      let deltaFilter = '';
      if (!loadFromDBOnly) {
        let fetchXML = FETCH_MARKETING_JOURNEYS;
        const isInitialSync = !offlineData || !offlineData.lastModified || forceFullSync;
        const newLastUpdatedTime = new Date().getTime().toString();

        if (isInitialSync) {
          offlineData = {
            data: [],
            lastModified: newLastUpdatedTime
          }
          fetchXML = fetchXML.replace('{lastUpdatedTimeCondition}', '');
        } else {
          if (offlineData && offlineData.lastModified) {
            const modifiedon = moment(parseInt(offlineData.lastModified)).format('YYYY-MM-DD');
            deltaFilter = `<condition attribute="modifiedon" operator="ge" value="` + modifiedon + `"/>`;
          }
          fetchXML = fetchXML.replace('{lastUpdatedTimeCondition}', deltaFilter);
        }
    
        await this.dynamics.executeFetchQuery('msdynmkt_journeys', fetchXML)
        .then(async (res) => {
          if (res && res.length > 0) {
            if (isInitialSync) {
              this.contactOfflineService.marketingJourneys = [];
              res.forEach(data => {
                const journey = new MarketingJourney(data);
                this.contactOfflineService.marketingJourneys.push(journey);
              });
            } else {
              if (!_.isEmpty(this.contactOfflineService.marketingJourneys)) {
                res.forEach((data : any) => {
                  const journey = new MarketingJourney(data);
                  const index = this.contactOfflineService.marketingJourneys.findIndex(j => j.ID == journey.ID);
                  if (index < 0) {
                    this.contactOfflineService.marketingJourneys.push(journey);
                  } else {
                    this.contactOfflineService.marketingJourneys[index] = journey;
                  }
                });
              } else {
                res.forEach(data => {
                  const journey = new MarketingJourney(data);
                  this.contactOfflineService.marketingJourneys.push(journey);
                });
              }
            }
            this.saveMarkeingJourneysInLocalDB(res, newLastUpdatedTime);
          }
        }).catch((err) => {
          console.log(err); 
        })
      }
    } catch (error) {
      console.error('fetchMarketingJourneys: ', error);
    }
  }

  public async fetchRealTimeMarketingEmailsInfo(forceFullSync: boolean = false, loadFromDBOnly = false) {
    if (this.authenticationOfflineService.hasFeatureAction(FeatureActionsMap.CUSTOMER_JOURNEY_TIMELINE)) {
      this.isCustomerJourneyTimelineEnabled = true;
    }
    if (!this.isCustomerJourneyTimelineEnabled) return;
    try {
      let offlineData = await this.loadOfflineRealTimeMarkeingEmailsInfo();
      if (offlineData && offlineData.raw && offlineData.raw.length > 0) {
        this.contactOfflineService.realtimeMarketingEmailsInfo = [];
        offlineData.raw.forEach(data => {
          const email = new RealtimeMarketingEmailInfo(data);
          this.contactOfflineService.realtimeMarketingEmailsInfo.push(email);
        });
      }
      let deltaFilter = '';
      if (!loadFromDBOnly) {
        let fetchXML = FETCH_REALTIME_MARKETING_EMAILS_INFO;
        const isInitialSync = !offlineData || !offlineData.lastModified || forceFullSync;
        const newLastUpdatedTime = new Date().getTime().toString();

        if (isInitialSync) {
          offlineData = {
            data: [],
            lastModified: newLastUpdatedTime
          }
          fetchXML = fetchXML.replace('{lastUpdatedTimeCondition}', '');
        } else {
          if (offlineData && offlineData.lastModified) {
            const modifiedon = moment(parseInt(offlineData.lastModified)).format('YYYY-MM-DD');
            deltaFilter = `<condition attribute="modifiedon" operator="ge" value="` + modifiedon + `"/>`;
          }
          fetchXML = fetchXML.replace('{lastUpdatedTimeCondition}', deltaFilter);
        }
    
        await this.dynamics.executeFetchQuery('msdynmkt_emails', fetchXML)
        .then(async (res) => {
          if (res && res.length > 0) {
            if (isInitialSync) {
              this.contactOfflineService.realtimeMarketingEmailsInfo = [];
              res.forEach(data => {
                const email = new RealtimeMarketingEmailInfo(data);
                this.contactOfflineService.realtimeMarketingEmailsInfo.push(email);
              });
            } else {
              if (_.isEmpty(this.contactOfflineService.realtimeMarketingEmailsInfo)) {
                res.forEach((data : any) => {
                  const email = new RealtimeMarketingEmailInfo(data);
                  const index = this.contactOfflineService.realtimeMarketingEmailsInfo.findIndex(j => j.ID == email.ID);
                  if (index < 0) {
                    this.contactOfflineService.realtimeMarketingEmailsInfo.push(email);
                  } else {
                    this.contactOfflineService.realtimeMarketingEmailsInfo[index] = email;
                  }
                });
              } else {
                res.forEach(data => {
                  const email = new RealtimeMarketingEmailInfo(data);
                  this.contactOfflineService.realtimeMarketingEmailsInfo.push(email);
                });
              }
            }
            this.saveRealTimeMarkeingEmailsInfoInLocalDB(res, newLastUpdatedTime);
          }
        }).catch((err) => {
          console.log(err); 
        })
      }
    } catch (error) {
      console.error('fetchRealTimeMarketingEmailsInfo: ', error);
    }
  }

  public async saveMarkeingJourneysInLocalDB(marketingJourneys: any, newLastUpdatedTime: string) {
    try {
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.MARKETING_JOURNEYS, (doc) => {
        doc = {
          raw: [],
          lastModified: newLastUpdatedTime
        };
        doc.raw = marketingJourneys;
        return doc;
      });
    } catch (error) {
      console.error("Failed to save marketing journeys in local db: ", error)
    }
  }

  public async loadOfflineMarkeingJourneys() {
    let offlineData;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.MARKETING_JOURNEYS, true).then((doc) => {
        offlineData = doc?.raw ? doc : [];
      });
    }
    catch (error) {
      console.error("Failed to load marketing journeys from local db: ", error)
      offlineData = [];
    }
    return offlineData;
  }

  public async saveRealTimeMarkeingEmailsInfoInLocalDB(realtimeMarketingEmailsInfo: any, newLastUpdatedTime: string) {
    try {
      await this.disk.updateOrInsert(DB_KEY_PREFIXES.REALTIME_MARKETING_EMAILS_INFO, (doc) => {
        doc = {
          raw: [],
          lastModified: newLastUpdatedTime
        };
        doc.raw = realtimeMarketingEmailsInfo;
        return doc;
      });
    } catch (error) {
      console.error("Failed to save realtime marketing emails info in local db: ", error)
    }
  }

  public async loadOfflineRealTimeMarkeingEmailsInfo() {
    let offlineData;
    try {
      await this.disk.retrieve(DB_KEY_PREFIXES.REALTIME_MARKETING_EMAILS_INFO, true).then((doc) => {
        offlineData = doc?.raw ? doc : [];
      });
    }
    catch (error) {
      console.error("Failed to load realtime marketing emails info from local db: ", error)
      offlineData = [];
    }
    return offlineData;
  }

  public async saveRealTimeEmailsForTimelinInLocalDB(realtimeMarketingEmails: any, contactId: string) {
    const id = DB_KEY_PREFIXES.REALTIME_EMAILS_TIMELINE + contactId;
    try {
      await this.disk.updateOrInsert(id, (doc) => {
        if (!doc || !doc.raw) {
          doc = {
            raw: []
          }
        }
        doc.raw = realtimeMarketingEmails;
        return doc;
      });
    } catch (error) {
      console.log("Error realtime marketing emails for timeline in DB", error);
    }
  }

  public async loadOfflineRealTimeEmailsForTimeline(contactId: string) {
    const id = DB_KEY_PREFIXES.REALTIME_EMAILS_TIMELINE + contactId;
    let offlineData;
    try {
      await this.disk.retrieve(id, true).then((doc) => {
        offlineData = doc?.raw ? doc : [];
      });
    }
    catch (error) {
      console.error("Failed to load realtime marketing emails for timeline from local db: ", error)
      offlineData = [];
    }
    return offlineData;
  }

} 