import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ActivityType } from '@omni/classes/activity/activity.class';
import { OrderActivity } from '@omni/classes/activity/order.activity.class';
import { IONote } from '@omni/classes/io/io-note.class';
import { Currency } from '@omni/classes/marketing-management/marketing-plan.class';
import { Opportunity } from '@omni/classes/opportunity-management/opportunity.class';
import { ApprovalStatus, QuotePriceList, Quotes, QuoteStatus, QuotesToolTab } from '@omni/classes/quotes/quotes.class';
import { AccountPageComponent } from '@omni/components/account/account-page/account-page';
import { OpportunitySelectionPageComponent } from '@omni/components/opportunity-management/opportunity-selection-master-page/opportunity-selection-master-page';
import { OrderManagementPageComponent } from '@omni/components/order-management/order-management-page/order-management-page';
import { AlertWithInput } from '@omni/components/shared/alert-with-input/alert-with-input';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { NothingSelectedView } from '@omni/components/shared/nothing-selected-view/nothing-selected-view';
import { SelectListComponent } from '@omni/components/shared/select-list/select-list';
import { QuoteToolDataService } from '@omni/data-services/quote-tool/quote-tool-data.service';
import { SharedDataService } from '@omni/data-services/shared-data/shared.data.service';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { FormFieldType, IndFormFieldViewDataModel } from '@omni/models/indFormFieldDataModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { OpportunitySelectionViewDataModel } from '@omni/models/opportunities-selection-page-model';
import { OrderSelectionViewDataModel } from '@omni/models/order-selection-page-models';
import { SelectListDetail } from '@omni/models/select-list-detail-model';
import { AccountOfflineService, AccountSelectedFor } from '@omni/services/account/account.offline.service';
import { ActivityService } from '@omni/services/activity/activity.service';
import { AlertService } from '@omni/services/alert/alert.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { DeviceService } from '@omni/services/device/device.service';
import { EventsService } from '@omni/services/events/events.service';
import { MarketingPlansManagementOfflineService } from '@omni/services/marketing-management/marketing-management.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { OpportunityManagementService } from '@omni/services/opportunity-management/opportunity-management.service';
import { OrderManagementService } from '@omni/services/order-management/order-management.service';
import { QuotesService } from '@omni/services/quote/quote-tool.service';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import { MAXIMUM_NOTE_ATTACHMENT_SIZE, NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX, toBase64, Utility } from '@omni/utility/util';
import { isBefore } from 'date-fns';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Guid } from 'typescript-guid';
import { QuoteProductsComponent } from '../quote-products/quote-products.component';
import { OpportunityManagementDataService } from '@omni/data-services/opportunity-management/opportunity-management.data.service';

@Component({
  selector: 'app-quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.scss'],
})
export class QuoteDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('attachInput') attachInput: ElementRef;
  @Input() selectedQuote: Quotes;
  @Input() selectedTab: string;
  @Input() nestedRoute = false;
  pageTitle: IndPageTitleViewDataModel;
  detailHeaderModel: IndSectionHeaderViewDataModel;
  productsHeader: IndSectionHeaderViewDataModel;
  notesHeaderModel: IndSectionHeaderViewDataModel;
  currentQuote: Quotes;
  priceLists: QuotePriceList[] = [];
  ngUnSubscibe$ = new Subject<boolean>();
  isReadOnlyMode = false;
  isNotesDisabled: boolean = false;
  _isClicked = [];
  opportunities = [];
  salesOrders = [];
  rawSalesOrders = [];
  isAttachmentAdded = false;
  attachmentTitle = '';
  quotenotes: any;
  notes: string;
  private isSaveNotesEnabled: boolean = false;
  private attachmentFile: any;
  private base64str: any;
  public notesPlaceholder:string = '';
  constructor(
    private translate: TranslateService,
    private quoteOfflineService: QuotesService,
    private sharedDataService: SharedDataService,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private accountService: AccountOfflineService,
    private navService: NavigationService,
    public uiService: UIService,
    public opportunityService: OpportunityManagementService,
    private orderManagementService: OrderManagementService,
    private datePipe: DatePipe,
    public dateTimeFormatsService: DateTimeFormatsService,
    public activityService: ActivityService,
    private notificationService: NotificationService,
    public authService: AuthenticationService,
    public device: DeviceService,
    private quoteService: QuoteToolDataService,
    public marketingPlansManagementOfflineService: MarketingPlansManagementOfflineService,
    private events: EventsService,
    private alertService: AlertService,
    private opportunityDataService : OpportunityManagementDataService
  ) { }

  ngOnInit() {
    this.currentQuote = this.selectedQuote;
    this.priceLists = this.quoteOfflineService.priceLists;
    this.rawSalesOrders = this.activityService.activities.filter(order => order instanceof OrderActivity && order.type === ActivityType.Order && !order.isHardDeleted) as OrderActivity[];
    this.setOpportunities(this.currentQuote?.accountId);
    this.setSalesOrders(this.currentQuote?.accountId);
    this.isReadOnlyMode = (this.currentQuote.statuscode != QuoteStatus.DRAFT && this.currentQuote.statuscode != QuoteStatus.IN_PROGRESS) || this.currentQuote.ownerId != this.authService.user.systemUserID || this.device.isOffline;
    this.isNotesDisabled = this.selectedTab === QuotesToolTab.APPROVALS ? !(this.currentQuote.approvalStatus !== ApprovalStatus.APPROVED && this.currentQuote.approvalStatus !== ApprovalStatus.NOT_APPROVED) : this.isReadOnlyMode;
    this.initializeHeaders();
    this.quoteOfflineService.selectedQuotes$.pipe(takeUntil(this.ngUnSubscibe$)).subscribe((selectedQuotes) => {
      if (selectedQuotes) {
        this.currentQuote = selectedQuotes;
        this.initializeHeaders();
      }
    });

    this.quotenotes = this.currentQuote.quoteNotes.sort((a, b) => {
      return (isBefore(a.createdTime, b.createdTime) ? 1 : -1)
    });
    this.device.isOfflineObservable.pipe(takeUntil(this.ngUnSubscibe$)).subscribe(isOffline => {
      this.initPageTitle();
    });
  }

  private initializeHeaders() {
    this.initPageTitle();
    this.initializeAPDetailHeader();
    this.initializeProductHeader();
    this.initializeNotesHeader();
  }

  private initPageTitle(): void {
    const controls = [];
    if (this.selectedTab == QuotesToolTab.APPROVALS) {
      controls.push({
        id: "reject",
        imgSrc: 'assets/imgs/header_cancel.svg',
        isDisabled: this.currentQuote.approvalStatus != ApprovalStatus.PENDING || this.device.isOffline,
        name: this.translate.instant('REJECT'),
        align: "right",
      },
        {
          id: "approve",
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('APPROVE'),
          isDisabled: this.currentQuote.approvalStatus != ApprovalStatus.PENDING || this.device.isOffline,
          align: "right",
        });
    } else {
      controls.push({
        id: 'scrap',
        imgSrc: 'assets/imgs/header_cancel.svg',
        name: this.translate.instant('SCRAP'),
        isDisabled: this.device.isOffline || this.currentQuote.ownerId != this.authService.user.systemUserID || (this.currentQuote.statuscode != QuoteStatus.DRAFT && this.currentQuote.statuscode != QuoteStatus.PENDING_APPROVAL),
        align: 'right'
      },
        {
          id: 'sendForApproval',
          imgSrc: 'assets/imgs/header_send.svg',
          name: this.translate.instant('SEND_APPROVAL_QUE'),
          isDisabled: this.device.isOffline || _.isEmpty(this.currentQuote.transactionCurrencyId)
            || (this.currentQuote.effectiveto && new Date(this.currentQuote.effectiveto).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0))
            || _.isEmpty(this.currentQuote.accountId) || _.isEmpty(this.currentQuote.priceLevelId)
            || this.currentQuote.statuscode == QuoteStatus.APPROVED
            || this.currentQuote.statuscode == QuoteStatus.CANCELED
            || this.currentQuote.statuscode == QuoteStatus.DYNAMICS_CANCELED
            || this.currentQuote.statuscode == QuoteStatus.PENDING_APPROVAL,
          align: 'right'
        })
    }

    controls.push({
      id: "close",
      icon: "chevron-back-outline",
      isDisabled: false,
      align: "left"
    });

    this.pageTitle = {
      id: 'quotes-details',
      title: this.currentQuote?.name ?? this.translate.instant('QUOTES'),
      controls: controls,
    };
  }

  private initializeAPDetailHeader() {
    this.detailHeaderModel = {
      id: 'quotes-detail-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: [],
    };
  }

  private initializeProductHeader() {
    this.productsHeader = {
      id: 'products-header',
      title: this.translate.instant('PRODUCTS'),
      controls: [{
        id: 'selectProduct',
        text: this.translate.instant('ADD'),
        isDisabled: _.isEmpty(this.currentQuote.priceLevelId) || this.isReadOnlyMode,
      }]
    };
  }

  get statusDisplayValue() {
    switch (this.currentQuote.statuscode) {
      case QuoteStatus.APPROVED:
        return this.translate.instant("APPROVED");
      case QuoteStatus.DRAFT:
        return this.translate.instant("OM_DRAFT");
      case QuoteStatus.REJECTED:
        return this.translate.instant("REJECTED");
      case QuoteStatus.DYNAMICS_CANCELED:
      case QuoteStatus.CANCELED:
        return this.translate.instant("CANCELLED");
      case QuoteStatus.PENDING_APPROVAL:
        return this.translate.instant("POP_IN_REVIEV");
      case QuoteStatus.IN_PROGRESS:
        return this.translate.instant("IN_PROGRESS");
      case QuoteStatus.DYNAMICS_IN_PROGRESS:
      case QuoteStatus.IN_PROGRESS:
        return this.translate.instant("IN_PROGRESS");
      case QuoteStatus.WON:
        return this.translate.instant("WON");
      case QuoteStatus.OPEN:
        return this.translate.instant("AL_SHIPMENT_FILTERLIST_OPEN");
      case QuoteStatus.LOST:
        return this.translate.instant("OPPORTUNITY_STATE_LOST");
    }
  }

  private get selectedOpportunity() {
    const selectedOpportunity = this.opportunities.find((opportunity) => opportunity.ID === this.currentQuote.opportunityId);
    return (selectedOpportunity ? [selectedOpportunity] : []);
  }

  private get selectedOrder() {
    const selectedOrder = this.rawSalesOrders.find((order) => order.ID === this.currentQuote.orderId);
    return (selectedOrder ? [selectedOrder] : []);
  }

  private get startPeriod() {
    return this._getFormattedDate(this.currentQuote.effectivefrom);
  }

  private get endPeriod() {
    return this._getFormattedDate(this.currentQuote.effectiveto);
  }

  onPageTitleControlClick(id: string) {
    switch (id) {
      case 'approve':
        this.approveQuote();
        break;
      case 'reject':
        this.notApproveQuote();
        break;
      case 'close':
        this.backToQuoteList();
        break;
      case 'scrap':
        this.scrapQuote();
        break;
      case 'sendForApproval':
        this.sendForApproval();
        break;
    }
  }

  getQuoteIdFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('QUOTE_ID'),
      inputText: this.currentQuote.quoteNumber,
      inputValue: this.currentQuote.quoteNumber,
      id: 'quoteId-field',
      isDisabled: true,
      placeholderLabel: this.translate.instant('QUOTE_ID'),
      showArrow: false,
      formFieldType: FormFieldType.INLINE_INPUT,
    };
    return viewData;
  }

  getStatusFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('STATUS'),
      inputText: this.statusDisplayValue,
      inputValue: this.statusDisplayValue,
      id: 'status-field',
      isDisabled: true,
      placeholderLabel: this.translate.instant('STATUS'),
      showArrow: false,
      formFieldType: FormFieldType.INLINE_INPUT,
    };
    return viewData;
  }

  getNameFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('NAME'),
      inputText: this.currentQuote.name,
      inputValue: this.currentQuote.name,
      id: 'name-field',
      isDisabled: this.isReadOnlyMode,
      placeholderLabel: this.translate.instant('NAME'),
      showArrow: !this.isReadOnlyMode,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: true,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked && this._isClicked['name-field'] && _.isEmpty(this.currentQuote.name),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName)
    };
    return viewData;
  }

  getCountryFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('COUNTRY'),
      inputText: this.currentQuote.countryName ?? '',
      customPlaceholderLabel: (this.isReadOnlyMode) ? this.translate.instant('NO_COUNTRY') : this.translate.instant('SELECT_COUNTRY'),
      id: 'country-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  getCurrencyFormField(): IndFormFieldViewDataModel {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('CURRENCY'),
      inputText: this.currentQuote.currencyName ?? null,
      customPlaceholderLabel: !this.isReadOnlyMode ? this.translate.instant('SELECT_CURRENCY') : this.translate.instant('NO') + " " + this.translate.instant('CURRENCY'),
      id: 'currency',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: true,
      isRequired: true,
      formFieldType: FormFieldType.POPOVER_SELECT,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked && this._isClicked['currency'] && _.isEmpty(this.currentQuote.currencyName),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    return viewData;
  }

  getAccountsFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNT'),
      inputText: this.currentQuote.accountName,
      inputValue: this.currentQuote.accountName,
      customPlaceholderLabel: this.translate.instant('SELECT_ACCOUNT'),
      id: 'account-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      isRequired: true,
      showArrow: true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked && this._isClicked['account-field'] && _.isEmpty(this.currentQuote.accountName),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  getDiscountFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('DISCOUNT') + ' %',
      inputText: this.currentQuote.discountPercentage.toString(),
      inputType: 'number',
      inputValue: this.currentQuote.discountPercentage ?? '',
      formattingSymbol: '%',
      id: 'discount-field',
      isReadOnly: this.isReadOnlyMode,
      isDisabled: this.isReadOnlyMode,
      placeholderLabel: this.translate.instant('DISCOUNT'),
      showArrow: true,
      formFieldType: FormFieldType.INLINE_INPUT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  getDetailAmountFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('DETAIL_AMOUNT'),
      inputText: this.currentQuote.totalAmount ? this.quoteOfflineService.getFormattedValue(parseInt(this.currentQuote.totalAmount), this.currentQuote) : '0.00',
      placeholderLabel: '',
      id: 'actual-revenue-field',
      isReadOnly: true,
      isDisabled: true,
    };
    return viewData;
  }

  getSuggestedAmountFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('SUGGESTED_AMOUNT'),
      inputText: this.currentQuote.suggestedAmount ? this.quoteOfflineService.getFormattedValue(parseInt(this.currentQuote.suggestedAmount), this.currentQuote) : '',
      id: 'suggested-amount-field',
      isReadOnly: this.isReadOnlyMode,
      isDisabled: this.isReadOnlyMode,
      formFieldType: FormFieldType.INLINE_INPUT,
      inputType: 'number',
      customPlaceholderLabel: this.isReadOnlyMode ? this.translate.instant('NO') + ' ' + this.translate.instant('SUGGESTED_AMOUNT') : this.translate.instant('ENTER_SUGGESTED_AMOUNT'),
      showArrow: !this.isReadOnlyMode,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  getPriceListFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PRICE_LIST'),
      inputText: this.currentQuote.priceLevelName ?? '',
      customPlaceholderLabel: this.translate.instant('SELECT_PRICE_LIST'),
      id: 'price-list-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: true,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isRequired: true,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked && this._isClicked['price-list-field'] && _.isEmpty(this.currentQuote.priceLevelName),
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  getOpportunityFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('OPPORTUNITY'),
      inputText: this.currentQuote.opportunityName,
      inputValue: this.currentQuote.opportunityName,
      customPlaceholderLabel: (this.isReadOnlyMode || this.opportunities.length === 0) ? this.translate.instant('FOLLOW_UP_NO_OPPORTUNITY') : this.translate.instant('SELECT_OPPORTUNITY'),
      id: 'opportunity-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode || this.opportunities.length === 0,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      showArrow: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  getSalesOrderFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('SALES_ORDER'),
      inputText: this.currentQuote.orderName,
      inputValue: this.currentQuote.orderName,
      customPlaceholderLabel: (this.isReadOnlyMode || this.salesOrders.length === 0) ? this.translate.instant('NO_SALE_ORDER') : this.translate.instant('SELECT_SALES_ORDER'),
      id: 'order-field',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode || this.salesOrders.length === 0,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      showArrow: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  getEffectiveFromFormField(): IndDateTimeFormViewDataModel {
    let viewData: IndDateTimeFormViewDataModel;
    viewData = {
      isDateField: true,
      label: this.translate.instant('EFFECTIVE_FROM'),
      inputText: this.currentQuote.effectivefrom ? this.startPeriod : '',
      customPlaceholderLabel: this.isReadOnlyMode ? '-' : this.translate.instant('SELECT_EFFECTIVE_FROM'),
      startDateTimeValue: this.currentQuote?.effectivefrom,
      endDateTimeValue: this.currentQuote?.effectiveto,
      fromViewPage: CurViewPageType.Quote,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  getEffectiveToFormField(): IndDateTimeFormViewDataModel {
    let viewData: IndDateTimeFormViewDataModel;
    viewData = {
      isDateField: true,
      label: this.translate.instant('EFFECTIVE_TO'),
      inputText: this.currentQuote.effectiveto ? this.endPeriod : '',
      customPlaceholderLabel: this.isReadOnlyMode ? '-' : this.translate.instant('SELECTE_EFFECTIVE_TO'),
      startDateTimeValue: this.currentQuote?.effectivefrom,
      endDateTimeValue: this.currentQuote?.effectiveto,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  private initializeNotesHeader() {
    let controls = [{
      id: "attach",
      text: this.translate.instant('ATTACH'),
      isDisabled: this.device.isOffline || this.isNotesDisabled,
      isVisible: !this.isNotesDisabled,
    }, {
      id: 'save-notes',
      text: this.translate.instant('SAVE'),
      isDisabled: !this.isSaveNotesEnabled || this.isNotesDisabled,
      isVisible: !this.isNotesDisabled,
    }
    ]
    this.notesHeaderModel = {
      id: 'notes-header',
      title: this.translate.instant('NOTES'),
      controls: controls
    }
    let isNotesOnBU = this.authService.user.buConfigs["indskr_notessectionhelptext"];
    this.notesPlaceholder = isNotesOnBU? isNotesOnBU : this.translate.instant('ENTER_NOTES');
  }

  private handleFormFieldEvent(id, event, eventName) {
    switch (id) {
      case 'name-field':
        if (eventName && eventName == 'input_value_confirm') {
          this._handleNameFieldChange(event);
        }
        break;
      case "currency":
        this.openCurrencySelect(event);
        break;
      case 'country-field':
        this.openCountrySelect();
        break;
      case "account-field":
        this.openAccountsSection();
        break;
      case "price-list-field":
        this.openPriceListSelect();
        break;
      case "suggested-amount-field":
        if (eventName && eventName == 'input_value_confirm') {
          this._handleSuggestedFormField(event);
        }
        break;
      case 'opportunity-field':
        this.openOpportunities();
        break;
      case 'order-field':
        this.openOrder();
        break;
      case 'discount-field':
        if (eventName && eventName == 'input_value_confirm') {
          this._handleDiscountFormField(event);
        }
        break;
      case DateTimeFieldType.StartDateField:
        this.openEffectiveFromDatePicker();
        break;
      case DateTimeFieldType.EndDateField:
        this.openEffectiveToPicker();
        break;
      default:
        console.log('Unhandled switch case statement');
        break;
    }
    this._isClicked[id] = true;
  }

  async _handleNameFieldChange(event) {
    if (!event || event.target.value === undefined || event.target.value === "") return;
    const payload = { name: event.target.value };
    this.updateQuote(payload);
    this.initPageTitle();
  }

  async openCountrySelect() {
    const countriesList = this.sharedDataService.allCountriesList;
    if (countriesList && countriesList.length > 0) {
      const selectListDetail: SelectListDetail = {
        isMultipleSelectionEnabled: false,
        isSearchEnabled: true,
        isReadOnly: this.isReadOnlyMode,
        title: this.translate.instant('COUNTRIES'),
        dividerTitle: this.translate.instant('OM_ALL_COUNTRIES'),
        data: countriesList.map(({ countryId, countryName }) =>
          ({ id: countryId, title: countryName, isSelected: countryId === this.currentQuote.countryId }))
      };

      let selectCountryModal = await this.modalCtrl.create({
        component: SelectListComponent,
        componentProps: { viewData: selectListDetail },
        cssClass: 'select_country',
        backdropDismiss: false
      });
      selectCountryModal.present();
      selectCountryModal.onDidDismiss().then(async ({ data }: any) => {
        if (!data || !data.isDone || !data.selectedItems) return;
        const payload = { countryId: data.selectedItems[0].id, countryName: data.selectedItems[0].title };
        this.updateQuote(payload);
      });
    }
  }

  async openCurrencySelect(event) {
    let options = [];

    const currencies: Currency[] = _.cloneDeep(this.marketingPlansManagementOfflineService.currencies);
    currencies.forEach(({ id, name }: Currency) => {
      let isSelected: boolean = this.currentQuote.transactionCurrencyId && this.currentQuote.transactionCurrencyId === id;
      options.push({ id, title: name, isSelected: isSelected });
    });
    options = this.marketingPlansManagementOfflineService.sortListByFielName(options, 'title');
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'currency-select',
      data: options,
      isMultipleSelectionEnabled: false,
    };

    let dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: dropdownListDetail
      },
      cssClass: 'dropdown-list-view',
      event
    });

    dropdownPopover.onDidDismiss().then(async ({ data }) => {
      if (!data || !data.selectedItems.length) return;
      if (this.currentQuote.transactionCurrencyId && data.selectedItems[0].id === this.currentQuote.transactionCurrencyId) return;

      const currency: Currency = currencies.find(currency => currency.id === data.selectedItems[0].id);
      let payload: any = {}

      if (this.currentQuote.priceLevelId && this.isPriceListOfDifferentCurrency(currency.id)) {
        payload = { priceLevelId: "", priceLevelName: "" };
      }


      if (this.currentQuote.products.length) {
        payload = {
          ...payload,
          products: this.currentQuote.products.map(({ quoteDetailId }) => ({ quoteDetailId, deleted: true }))
        }
      }

      if (!_.isEmpty(this.currentQuote.opportunityId)) {
        payload = { ...payload, opportunityId: "", opportunityName: "" }
      }

      await this.updateQuote(payload);

      payload = { transactionCurrencyId: currency.id, currencyName: currency.name };
      await this.updateQuote(payload);

      this.initPageTitle();
    });
    dropdownPopover.present();
  }

  private openAccountsSection() {
    this.accountService.accessedAccountListFrom = PageName.QuoteDetailsComponent;
    this.accountService.accountPageMode = this.isReadOnlyMode ? ComponentViewMode.READONLY : ComponentViewMode.SELECTION;
    this.accountService.selectedFor = AccountSelectedFor.GENERAL_SINGLE_SELECTION
    this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, {
      'listMode': this.isReadOnlyMode ? ComponentViewMode.READONLY : ComponentViewMode.SELECTION,
      selectedAccountId: this.currentQuote.accountId,
      callbackEvent: (data: any) => this._handleAccountComponentCallback(data)
    }, PageName.AccountPageComponent).then(() => { });
  }

  private async _handleAccountComponentCallback(data) {
    if (data.clearValue) {
      this.currentQuote.accountId = null;
      this.currentQuote.accountName = null;
    }

    if (data && data.isDone) {
      const { selectedItem } = data;

      let payload: any = { accountId: selectedItem.id, accountName: selectedItem.name };

      if (!_.isEmpty(this.currentQuote.opportunityId)) { // to remove opportunity
        const selectedOpportunity = this.opportunityService.opportunities.find(({ ID }) => ID === this.currentQuote.opportunityId);
        if (selectedOpportunity.accountID !== selectedItem.id) {
          payload = { ...payload, opportunityId: "", opportunityName: "" }
        }
      }

      if (!_.isEmpty(this.currentQuote.orderId)) { // to remove sales orders
        const selectedOrder = this.rawSalesOrders.find(({ ID }) => ID === this.currentQuote.orderId);
        if (selectedOrder.accountID !== selectedItem.id) {
          payload = { ...payload, orderId: "", orderName: "" }
        }
      }

      this.updateQuote(payload);
    }

    this.setOpportunities(data.selectedItem.id);
    this.setSalesOrders(data.selectedItem.id);
    this.initPageTitle();
  }

  async openPriceListSelect() {
    if (!this.priceLists.length) return;

    let priceLists: any = this.priceLists;
    if (!_.isEmpty(this.currentQuote.transactionCurrencyId)) {
      priceLists = priceLists.filter(({ currencyId }) => currencyId === this.currentQuote.transactionCurrencyId);
    }
    priceLists = priceLists.map(({ id, name }) => ({ id, title: name, isSelected: id === this.currentQuote.priceLevelId }));

    const selectListDetail: SelectListDetail = {
      isMultipleSelectionEnabled: false,
      isSearchEnabled: !this.isReadOnlyMode,
      isReadOnly: this.isReadOnlyMode,
      title: this.translate.instant('PRICE_LIST'),
      dividerTitle: this.translate.instant('ALL_PRICE_LIST'),
      data: priceLists
    };

    let selectCountryModal = await this.modalCtrl.create({
      component: SelectListComponent, componentProps: {
        viewData: selectListDetail
      }, cssClass: 'select_country', backdropDismiss: false
    });

    selectCountryModal.present();

    selectCountryModal.onDidDismiss().then(async ({ data }: any) => {
      if (!data || !data.isDone || _.isEmpty(data.selectedItems)) return;

      let payload: any = {};
      payload = {
        priceLevelId: data.selectedItems[0].id,
        priceLevelName: data.selectedItems[0].title
      }

      if (_.isEmpty(this.currentQuote.transactionCurrencyId)) {
        const selectedPriceList = this.priceLists.find(priceList => priceList.id === data.selectedItems[0].id);
        payload = { ...payload, transactionCurrencyId: selectedPriceList.currencyId, currencyName: selectedPriceList.currencyName }
      }

      this.updateQuote(payload);
      this.initPageTitle();
    });
  }

  private openOpportunities() {
    const opportuntiesSelectionViewData: OpportunitySelectionViewDataModel = {
      isReadOnlyMode: this.isReadOnlyMode,
      opportunitiesDataSet: this.opportunities,
      selectedOpportunities: this.selectedOpportunity,
      disableDoneButtonInOffline: true,
      isMultiSelection: false,
      callbackFromSelectionComponent: (event: string, newSelectedOpportunities: Array<Opportunity>) => this._handledOpportunitiesSelection(event, newSelectedOpportunities[0]),
    };
    this.navService.pushWithPageTracking(OpportunitySelectionPageComponent, PageName.OpportunitySelectionPage, { viewData: opportuntiesSelectionViewData }, PageName.OpportunitySelectionPage);
  }

  private async _handledOpportunitiesSelection(event, newSelectedOpportunity: Opportunity) {
    if (event && event == 'DONEBUTTONCLICK') {
      let payload: any;
      if (newSelectedOpportunity && this.currentQuote.opportunityId === newSelectedOpportunity.ID) return;

      if (!newSelectedOpportunity) { // removing opportunity
        payload = { opportunityId: "", opportunityName: "" };
      } else if (this.currentQuote.opportunityId !== newSelectedOpportunity.ID) {
        payload = { opportunityId: newSelectedOpportunity.ID, opportunityName: newSelectedOpportunity.opportunityName };
      }
      this.updateQuote(payload);
    }
  }

  private async openOrder() {
    this.orderManagementService.isSelectionPane = true;
    const orderSelectionViewData: OrderSelectionViewDataModel = {
      isReadOnlyMode: this.isReadOnlyMode,
      selectedOrders: this.selectedOrder,
      disableDoneButtonInOffline: true,
      viewMode: this.isReadOnlyMode ? ComponentViewMode.READONLY : ComponentViewMode.ADDNEW,
      callbackFromSelectionComponent: (event: string, newSelectedOrders: OrderActivity[]) => this._handledOrderSelection(event, newSelectedOrders[0]),
    };
    this.navService.pushWithPageTracking(OrderManagementPageComponent, PageName.OrderManagementPageComponent, { viewData: orderSelectionViewData, from: PageName.QuoteDetailsComponent }, PageName.QuotePageComponent);
  }

  private _handledOrderSelection(event, newSelectedOrder) {
    if (event && event == 'DONEBUTTONCLICK') {
      let payload: any;
      if (newSelectedOrder && this.currentQuote.opportunityId === newSelectedOrder.ID) return;

      if (!newSelectedOrder) { // removing order
        payload = { orderId: "", orderName: "" };
      } else if (this.currentQuote.opportunityId !== newSelectedOrder.ID) {
        payload = { orderId: newSelectedOrder.ID, orderName: newSelectedOrder.name };
      }
      this.updateQuote(payload);
    }
  }

  private _handleDiscountFormField(event) {
    if (!event || event.target.value === undefined) return;
    let payload = { discountPercentage: event.target.value };
    this.updateQuote(payload);
  }

  private _handleSuggestedFormField(event) {
    if (!event || event.target.value === undefined) return;
    let payload = { "indskr_suggestedamount": event.target.value, suggestedAmount: event.target.value };
    this.updateQuote(payload);
  }

  isPriceListOfDifferentCurrency(currecnyId) {
    const currentPriceList = this.priceLists.find(priceList => priceList.id === this.currentQuote.priceLevelId);
    return currentPriceList && currentPriceList.currencyId !== currecnyId;
  }

  private _getFormattedDate(value: any) {
    return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  private async openEffectiveFromDatePicker() {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.getDateTimePopover();
    popover.present();
    popover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && !_.isEmpty(obj.data.startTime)) {
        const valueDate = new Date(obj.data.startTime).getTime();
        if (this.currentQuote.effectiveto && valueDate > new Date(this.currentQuote.effectiveto).getTime()) {
          this.notificationService.notify(this.translate.instant('EFFECTIVE_TO_CAN_NOT_BEFORE_EFFECTIVE_FROM'), this.translate.instant('QUOTES'), 'top', ToastStyle.DANGER);
        } else {
          const payload = { effectivefrom: valueDate };
          this.updateQuote(payload);
        }
      }
    })
  }

  private async openEffectiveToPicker() {
    if (!this.currentQuote.effectivefrom) return;
    const today = new Date().getTime();
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let popover = await this.getDateTimePopover();
    popover.present();
    popover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && !_.isEmpty(obj.data.endTime)) {
        const valueDate = new Date(obj.data.endTime).getTime();
        if (new Date(this.currentQuote.effectivefrom).getTime() > valueDate) {
          this.notificationService.notify(this.translate.instant('EFFECTIVE_TO_CAN_NOT_BEFORE_EFFECTIVE_FROM'), this.translate.instant('QUOTES'), 'top', ToastStyle.DANGER);
        } else if (valueDate < today) {
          this.notificationService.notify(this.translate.instant('QUOTES_CAN_NOT_BE_PAST'), this.translate.instant('QUOTES'), 'top', ToastStyle.DANGER);
        } else {
          const payload = { effectiveto: valueDate };
          this.updateQuote(payload);
          this.initPageTitle();
        }
      }
    })
  }

  private async getDateTimePopover() {
    return await this.popoverCtrl.create({
      component: IndDatetimeFormComponent,
      componentProps: {
        currentViewPage: CurViewPageType.Quote,
        startDateTimeValue: this.currentQuote?.effectivefrom ? new Date(this.currentQuote.effectivefrom) : null,
        endDateTimeValue: this.currentQuote?.effectiveto ? new Date(this.currentQuote.effectiveto) : null,
      },
      cssClass: "datetime-popover"
    });
  }

  private setOpportunities(accountId) {
    if (!_.isEmpty(this.currentQuote.accountId)) {
      this.opportunities = this.opportunityService.opportunities.filter((Opportunity) => Opportunity.accountID === accountId);
    } else {
      this.opportunities = this.opportunityService.opportunities
    }
  }

  private setSalesOrders(accountId) {
    if (!_.isEmpty(this.currentQuote.accountId)) {
      this.salesOrders = this.rawSalesOrders.filter((salesOrder) => salesOrder.accountId === accountId);
    } else {
      this.salesOrders = this.rawSalesOrders;
    }
  }

  openProduct(product?) {
    if (this.isReadOnlyMode) return;
    const inputProducts = this.quoteOfflineService.priceLists.find((productList) => productList.id === this.currentQuote.priceLevelId)?.products;
    this.navService.pushChildNavPageWithPageTracking(QuoteProductsComponent, PageName.QuoteProductsComponent, PageName.QuoteDetailsComponent, {
      inputProducts: inputProducts,
      selectedProduct: product ?? {},
      isEditMode: !this.isReadOnlyMode && product,
      isDuplicateAllowed: false,
      selectedProducts: _.cloneDeep(this.currentQuote.products),
      callbackEvent: (data: any, deleteStatus) => this._handleProductSelect(data, deleteStatus)
    });
  }

  async _handleProductSelect(data, deleteStatus) {
    const index = this.currentQuote.products.findIndex(({ id }) => id === data.id);
    let payload: any = {}
    if (index >= 0 && !deleteStatus) {
      payload = {
        quantity: data.quantity,
        quoteDetailId: this.currentQuote.products[index].quoteDetailId
      }
    } else if (index >= 0 && deleteStatus) {
      payload = {
        quoteDetailId: this.currentQuote.products[index].quoteDetailId,
        deleted: true
      }
    } else {
      payload = {
        quantity: data.quantity,
        productId: data.id,
        uomId: data.uomId,
        name: data.name
      }
    }

    await this.uiService.displayLoader();
    await this.quoteService.addProductsToQuote(payload, this.currentQuote.ID);
    this.uiService.dismissLoader();
  }

  onSectionHeaderControlClick(id: string) {
    switch (id) {
      case "attach":
        this.handleNoteAttachment();
        break;
      case "save-notes":
        this.handleNotesSave();
        break;
      default:
        console.error("unhandled error");
    }
  }

  // note section
  private handleNoteAttachment() {
    try {
      this.attachInput.nativeElement.click();
    } catch (error) {
      console.log(error);
    }
  }

  private async handleNotesSave() {
    if (this.isSaveNotesEnabled && ((this.notes && !_.isEmpty(this.notes)) || !_.isEmpty(this.base64str))) {
      this.isSaveNotesEnabled = false;
      this.initializeNotesHeader();
      this.uiService.displayLoader();
      let tempNote: any = {
        annotationid: Guid.create().toString(),
        createdon: new Date().getTime(),
        notetext: this.notes,
        ownerName: this.authService.user.displayName,
        ownerid: this.authService.user.systemUserID,
      };
      if (this.base64str) {
        tempNote['isdocument'] = true;
        tempNote['documentbody'] = this.base64str;
        tempNote['filename'] = this.attachmentFile.name;
        tempNote['mimetype'] = this.attachmentFile.type;
      } else {
        tempNote['hasDocument'] = false;
      }
      if (!this.currentQuote.quoteNotes) {
        this.currentQuote.quoteNotes = [];
      }

      const response: any = await this.quoteService.saveAttachments([tempNote], this.currentQuote.ID);
      tempNote.annotationid = response.noteid;
      tempNote = new IONote(tempNote);
      this.currentQuote.quoteNotes.push(tempNote);
      this.currentQuote.quoteNotes.sort((a, b) => {
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1)
      })
      this.quotenotes = this.currentQuote.quoteNotes;
      await this.quoteOfflineService.updateQuoteLocal({ quotenotes: this.currentQuote.quoteNotes }, this.currentQuote.ID);
      await this.quoteService.saveSingleQuoteInDB(this.quoteOfflineService.selectedQuotes$.getValue());
      this.removeAttachment(null);
      this.notes = '';
      // this.updateSaveBtn();
      this._sortNotes();
      this.uiService.dismissLoader();
    }
  }

  get nonDeletedNotes() {
    return this.currentQuote?.quoteNotes?.filter(note => !note.isDeleted)
  }

  private _sortNotes(): void {
    if (this.currentQuote.quoteNotes && this.currentQuote.quoteNotes.length > 1) {
      this.currentQuote.quoteNotes.sort((a, b) => {
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1)
      })
    }
  }

  removeAttachment(event) {
    try {
      this.attachInput.nativeElement.value = null;
    } catch (error) {
      console.log(error);
    }
    this.attachmentTitle = '';
    this.isAttachmentAdded = false;
    this.attachmentFile = null;
    this.base64str = null;
    this.isSaveNotesEnabled = this.notes !== undefined && this.notes !== '';
  }

  async updateNote(ev) {
    if (ev && ev.action) {
      if (this.device.isOffline) return;
      const idx = this.currentQuote.quoteNotes.findIndex(note => note.noteId === ev.noteId);
      let note = this.currentQuote.quoteNotes[idx];

      if (ev.action === 'DELETE' && idx >= 0) {
        note.isDeleted = true;
        if (note.hasDocument)
          note = this._resetDocumentDetailsOfNote(note);
      } else if (ev.action === 'SAVE' && idx >= 0) {
        note.noteText = ev.updatedText;
        if (ev.attachmentFileUpdated) {
          note = this._updateAttachmentinNotes(note, ev);
        }
        //  else if (ev.attachmentFileUpdated) {
        // note = this._updateAttachmentinNotes(note, ev)
        // } 
        else if (ev.isAttachmentRemoved) {
          note.hasDocument = false;
          note = this._resetDocumentDetailsOfNote(note);
        }
      }

      let payload: any = { ...note, notetext: note.noteText, noteid: note.noteId }
      if (ev.action === 'DELETE' && idx >= 0) {
        payload = { ...payload, deleted: true }
      }

      if (note.hasDocument) {
        payload = {
          ...payload,
          isdocument: true,
          documentbody: ev.attachmentFileDataUrl,
          filename: ev.documentName,
          mimetype: ev.documentMimeType
        }
      }

      if (ev.isAttachmentRemoved) {
        payload = {
          ...payload,
          isdocument: false,
          documentbody: '',
          filename: '',
          mimetype: ''
        }
      }

      await this.uiService.displayLoader();
      await this.quoteService.saveAttachments([payload], this.currentQuote.ID);
      if (ev.action === 'DELETE' && idx >= 0) {
        this.currentQuote.quoteNotes.splice(idx, 1);
      } else {
        this.currentQuote.quoteNotes[idx] = note;
      }
      this.quotenotes = this.currentQuote.quoteNotes;
      await this.quoteService.saveSingleQuoteInDB(this.currentQuote);
      this.removeAttachment(null);
      this.notes = '';
      this._sortNotes();
      this.uiService.dismissLoader();
    }
  }

  private _resetDocumentDetailsOfNote(note: IONote) {
    note.documentSize = 0;
    note.documentName = '';
    note.documentMimeType = '';
    note.documentDataURL = '';
    note.hasDocument = false;
    return note;
  }

  private _updateAttachmentinNotes(note: any, ev: any) {
    note.hasDocument = true;
    note.documentSize = ev.documentSize;
    note.documentName = ev.documentName;
    note.documentMimeType = ev.documentMimeType;
    note.documentDataURL = ev.attachmentFileDataUrl;
    return note;
  }

  public async loadImageFromDevice(event) {
    if (event && event.target && event.target.files) {
      try {
        if ((event.target.files[0].size / 1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE) {
          if (NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(event.target.files[0].name)) {
            this.attachmentFile = event.target.files[0];
            this.attachmentTitle = event.target.files[0].name;
            this.isAttachmentAdded = true;
            this.base64str = await toBase64(this.attachmentFile);
            const dataURLPattern = /^data:[^;]+;base64,/;
            this.base64str = this.base64str.replace(dataURLPattern, '');
            this.isSaveNotesEnabled = true;
            this.initializeNotesHeader();
          } else {
            this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'), 'Note Attachment', 'top', 'info');
            this.removeAttachment(false);
          }
        } else {
          this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION', { size: MAXIMUM_NOTE_ATTACHMENT_SIZE }), 'Note Attachment', 'top', 'info');
          this.removeAttachment(false);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  public notesChanged(ev): void {
    if (this.device.isOffline) return;
    if (ev && ev.target?.value) {
      this.notes = ev.target.value;
      this.isSaveNotesEnabled = true;

    } else {
      this.notes = '';
      this.isSaveNotesEnabled = false;

    }
    this.initializeNotesHeader();
  }

  private async updateQuote(payload) {
    this.uiService.displayLoader();
    await this.quoteService.updateQuote(payload, this.currentQuote.ID);
    this.uiService.dismissLoader();
  }

  private async approveQuote() {
    const payload = {
      statuscode: ApprovalStatus.APPROVED
    }
    await this.uiService.displayLoader();
    await this.quoteService.updateApprovalActivity(payload, this.currentQuote.approvalActivityId).then(async () => {
      this.currentQuote.approvalStatus = payload.statuscode;
      this.currentQuote.statuscode = await this.quoteService.getLatestQuoteStatus(this.currentQuote.ID);
      this.events.publish("updatePendingItem", this.currentQuote);
      this.initPageTitle();
      this.isNotesDisabled = true;
    }).finally(() => this.uiService.dismissLoader());
  }

  private async notApproveQuote() {
    const popover = await this.popoverCtrl.create({
      component: AlertWithInput,
      componentProps: {
        header: this.translate.instant("REASON_FOR_REJECTION"),
        message: this.translate.instant("PLEASE_ENTER_REASON_FOR_REJECTION"),
        inputs: [],
        cancelText: this.translate.instant('CANCEL'),
        confirmText: this.translate.instant('CONFIRM'),
        Placeholder: this.translate.instant('ENTER_REASON_FOR_REJECTION'),
        isMandatory: true
      },
      cssClass: this.device.isNativeApp ? 'native-alert-with-input-list-view' : 'alert-with-input-list-view',
      backdropDismiss: true,
      event: this.device.isNativeApp ? event : null
    });
    popover.present();
    popover.onDidDismiss().then(async (res) => {
      if (res.data && res.data.role && res.data.role == 'ok' && res.data.selectedOtherItem) {
        const payload = {
          indskr_reason: res.data.selectedOtherItem,
          statuscode: ApprovalStatus.NOT_APPROVED
        }
        await this.uiService.displayLoader();
        await this.quoteService.updateApprovalActivity(payload, this.currentQuote.approvalActivityId).then(async () => {
          this.currentQuote.approvalStatus = payload.statuscode;
          this.currentQuote.statuscode = await this.quoteService.getLatestQuoteStatus(this.currentQuote.ID);
          this.events.publish("updatePendingItem", this.currentQuote);
          this.isNotesDisabled = true;
          this.initPageTitle();
        }).finally(() => this.uiService.dismissLoader());
      }
    });
  }

  public backToQuoteList() {
    if (this.nestedRoute) {
      if (this.quoteOfflineService.createdFrom == PageName.OpportunityManagementPage) {
        this.refreshOpportunities();
      }
      this.navService.popChildNavPageWithPageTracking();
    } else {
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.QuotePageComponent);
      this.uiService.showRightPane = false;
      this.navService.setChildNavRightPaneView(false);
    }
    this.quoteOfflineService.selectedQuotes$.next(null);
    this.quoteOfflineService.createdFrom = null;
  }

  private scrapQuote() {
    this.alertService.showAlert({
      title: this.translate.instant('SCRAP_QUOTE'),
      message: this.translate.instant('POP_R_U_SURE_SCRAP_QUOTE')
    }, this.translate.instant('SCRAP')
    ).then(async res => {
      if (res.role == "ok") {
        if (this.device.isOffline) return;
        await this.updateQuote({ statuscode: QuoteStatus.CANCELED });
        this.isReadOnlyMode = this.isNotesDisabled = true;
        this.isSaveNotesEnabled = false;
        this.initializeHeaders();
      }
    })
  }

  private async sendForApproval() {
    if (this.device.isOffline) return;
    await this.updateQuote({ statuscode: QuoteStatus.PENDING_APPROVAL });
    this.isReadOnlyMode = this.isNotesDisabled = true;
    this.isSaveNotesEnabled = false;
    this.initializeHeaders();
  }

  ngOnDestroy() {
    this.ngUnSubscibe$.next(true);
    this.ngUnSubscibe$.complete();
  }

  async refreshOpportunities(){
    this.opportunityDataService.refreshOpportunityFromQuote(this.currentQuote);
  }

}
