<ion-header>
  <ion-toolbar>
    <label>{{'PLEASE_SELECT_CONTACT_FOR_ACCOUNT_VISIT' | translate}}</label>
  </ion-toolbar>
</ion-header>
<ion-content #content>
  <div #contentWrapper class="wrapper">
    <ion-list class="fields-contatiner">
      <ion-item lines="none" class="sc-field" *ngFor="let contact of contacts" (click)="selectCustomer(contact)">
        <ion-label class="text-wrapper">
          <ion-text>
            <ion-icon *ngIf="contact.isTargetContact" name="target-contact-icon" class='target-contact-icon'></ion-icon>
            <span class='primary-text'>{{contact.fullname}}</span> 
            <span *ngIf="contact.secondaryText" class="secondary-text"> | {{contact.secondaryText}} </span>
          </ion-text>
        </ion-label>
        <ion-checkbox [disabled]="selectedContactIds?.includes(contact.ID) || !contact.hasConsent" slot="end" [checked]="contact.selected"></ion-checkbox>
      </ion-item>
    </ion-list>
  </div>
</ion-content>
<div class="action-bar">
  <ion-button slot="end" (click)="closeModal(true)" [disabled]="isSelectDisabled">
    <ion-icon class="submit-icon" src="assets/imgs/white-select-icon.svg" alt="Confirm"></ion-icon>
    {{'SELECT' | translate}} <span *ngIf="selectedContactCount > 0"> ({{selectedContactCount}}) </span>
  </ion-button>
  <ion-button class="cncl-btn" (click)="closeModal(false)" fill="clear" slot="end">
    <ion-icon class="submit-icon" src="assets/imgs/blue-close-icon.svg" alt="Cancel"></ion-icon>
    {{'CLOSE' | translate}}
  </ion-button>
</div>