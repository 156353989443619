import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DeviceService } from "../device/device.service";
import { AuthenticationService } from "../authentication.service";
import { AzureStorageDetails } from "../../classes/app-settings/azure-storage-details";
import { Endpoints } from "../../../config/endpoints.config";
import {
  BlockBlobURL,
  Aborter,
  ContainerURL,
  ServiceURL,
  StorageURL,
  TokenCredential,
  AnonymousCredential
} from '@azure/storage-blob';
import { DiskService } from "../disk/disk.service";
import { TransferProgressEvent } from '@azure/ms-rest-js';
import { FooterService } from "../footer/footer.service";
import { DB_KEY_PREFIXES, DB_ALLDOCS_QUERY_OPTIONS, DB_SYNC_STATE_KEYS, PREFIX_SEARCH_ENDKEY_UNICODE } from "../../config/pouch-db.config";
import { format } from 'date-fns';
import _ from "lodash";


@Injectable({
  providedIn: 'root'
})
export class AzureStorageService {

  public progress: any = 0;
  public contentLength: string;
  public dataSize: any = 0;
  public isUploadDisabled: boolean = false;
  private anonymousCredentials: AnonymousCredential;
  private tokenCredentials: TokenCredential;
  private serviceURL: ServiceURL;
  private _azureStorageDetails: AzureStorageDetails;

  constructor(
    private http: HttpClient,
    private device: DeviceService,
    private authenticationService: AuthenticationService,
    private disk: DiskService,
    private footerService: FooterService
  ) {

  }

  async getSASTokenDetails() {
    if (this.device.isOffline) {
      return;
    }
    let url: string = this.authenticationService.userConfig.activeInstance.entryPointUrl + Endpoints.appsetting.GET_SAS_TOKEN;
    try {
      let response = await this.http.get(url).toPromise();
      if (response) {
        this._azureStorageDetails = new AzureStorageDetails(response);
        if (this._azureStorageDetails.serviceURL)
          return this._azureStorageDetails;
        else return;
      }
    } catch (httpError) {
      console.error('fetching the storage details: ', httpError);
      return;
    }
    return;
  }

  async upload() {
    this.progress = 0;
    this.dataSize = 0;
    this.footerService.disableButton(['appsettingupload']);
    this.isUploadDisabled = true;
    await this.getSASTokenDetails()
      .then(async azureStorageDet => {
        if (azureStorageDet) {
          this._azureStorageDetails = azureStorageDet;
          await this.uploadDiagnosticData().then(() => {
            this.progress = 100;
            this.isUploadDisabled = false;
            this.footerService.enableButtons(['appsettingupload']);
            console.log('Blob uploaded successfully');
          }).catch(err => {
            this.progress = 'error';
            this.isUploadDisabled = false;
            console.log('Blob  is NOT uploaded' + err);
          });
          return this.progress;
        }
      })
      .catch(err => {
        this.progress = 'error';
        this.isUploadDisabled = false;
        console.log('Error in fetching the SAS token ' + err);
      });
  }

  async uploadDiagnosticData() {
    this.progress = 10;
    this.anonymousCredentials = new AnonymousCredential();
    var pipeline = StorageURL.newPipeline(this.anonymousCredentials);
    this.serviceURL = new ServiceURL(this._azureStorageDetails.serviceURL, pipeline);
    return Promise.all([
      await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACTIVITIES).then(async (data: any[]) => {
        await this.uploadDataChunks(data, "all-activities");
      }),
      this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_SYNC_STATES).then(async (data: any[]) => {
        await this.uploadDataChunks(data, "sync-data");
      }),
      this.dumpMasterData().then(async (data: any[]) => {
        await this.uploadDataChunks(data, "master-data");
      }),
      this.dumpTimeOffsAndCoachingData().then(async (data: any[]) => {
        await this.uploadDataChunks(data, "timeoff-coaching-data");
      }),
      this.dumpCallPlanAndSchedulerData().then(async (data: any[]) => {
        await this.uploadDataChunks(data, "callplan-caoching-data");
      }),
      this.dumpCustomerSampleAllocationAndConsentData().then(async (data: any[]) => {
        await this.uploadDataChunks(data, "sample-allocation-consent-data");
      }),
      this.dumpOfflineData().then(async (data: any[]) => {
        await this.uploadDataChunks(data, "offline-data");
      })
    ]).then(() => true).catch(() => false);
  }


  async uploadDataChunks(data: any, filename: string) {
    const ONE_MINUTE = 60 * 1000;
    var containerURL = ContainerURL.fromServiceURL(this.serviceURL, this._azureStorageDetails.containerName);
    var timestamp = format(new Date(), 'YYYY-MM-DD');
    var blockBlobURL = BlockBlobURL.fromContainerURL(containerURL, this.authenticationService.user.givenName + '-' + timestamp + "-db/" + filename);
    var aborter = Aborter.timeout(30 * ONE_MINUTE);
    var content = this.sliceArray(data);
    this.dataSize = this.dataSize + content.length;
    this.contentLength = this.formatBytes(this.dataSize);
    await blockBlobURL.upload(aborter, content, content.length, {
      progress: (progressEvent: TransferProgressEvent) => {
        if (progressEvent.loadedBytes < content.length) {
          let currentProgress = Math.round((progressEvent.loadedBytes * 100) / content.length);
          if (currentProgress > this.progress) this.progress = currentProgress;
        }
      }
    });
  }

  formatBytes(bytes) {
    if (bytes < 1024) return bytes + " Bytes";
    else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + " KB";
    else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + " MB";
    else return (bytes / 1073741824).toFixed(3) + " GB";
  }

  sliceArray(data: any[]) {
    var content = "";
    if (data) {
      if (data.length > 100) {
        content = JSON.stringify(data.slice(0, data.length / 2));
        content = content + JSON.stringify(data.slice(data.length / 2, data.length));
      } else {
        content = JSON.stringify(data);
      }
    }
    return content;
  }

  async dumpMasterData() {
    let dbdata: any[] = [];
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACCOUNTS).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONTACTS).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_PRESENTATIONS).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_RESOURCES).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_EMAIL_TEMPLATES).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONSENT_TERMS).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONSENT_TERMS_ALLOCATION_ORDERS).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACCOMPANIED_USERS).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CONSENT_CHANNELS).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_LOTS).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    return dbdata;
  }

  async dumpTimeOffsAndCoachingData() {
    let dbdata: any[] = [];
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_MY_TIMEOFFS).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_TEAM_TIMEOFFS).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_MY_COACHING).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_TEAM_COACHING).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    return dbdata;
  }

  async dumpCallPlanAndSchedulerData() {
    let dbdata: any[] = [];
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_MY_POSITION_CALLPLANS).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_OTHER_POSITION_CALLPLANS).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_USER_POSITION_EDGE_ANALYTICS_METRICS).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_SCHEDULER_RELATED_DATA).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    return dbdata;
  }

  async dumpCustomerSampleAllocationAndConsentData() {
    let dbdata: any[] = [];
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_CUSTOMER_SAMPLE_ALLOCATIONS).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ALLOCATION_SHIPMENTS).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    await this.disk.batchFetch(DB_ALLDOCS_QUERY_OPTIONS.GET_ALL_ACTIVE_CONSENTS).then((data: any[]) => {
      dbdata = dbdata.concat(data);
    });
    return dbdata;
  }

  async dumpOfflineData() {
    let dbdata: any[] = [];
    await this.disk.retrieve('offlineMeetings', true).then((offlineData: any[]) => {
      if (offlineData && offlineData['meetings']) {
        dbdata.push(...offlineData['meetings']);
      }
    });
    await this.disk.retrieve('offlineMeetingPresentations', true).then((offlineData: any[]) => {
      if (offlineData && offlineData['meetings']) {
        dbdata.push(...offlineData['meetings']);
      }
    });
    await this.disk.retrieve('offlinePhoneCalls', true).then((offlineData: any[]) => {
      if (offlineData && offlineData['meetings']) {
        dbdata.push(...offlineData['meetings']);
      }
    });
    await this.disk.retrieve('offlineTimeOffs', true).then((offlineData: any[]) => {
      if (offlineData && offlineData['myTos']) {
        dbdata.push(...offlineData['myTos']);
      }
    });
    await this.disk.retrieve('offlineSampleOrders', true).then((offlineData: any[]) => {
      if (offlineData && offlineData['orders']) {
        dbdata.push(...offlineData['orders']);
      }
    });
    await this.disk.retrieve('offlineEmails', true).then((offlineData: any[]) => {
      if (offlineData && offlineData['emails']) {
        dbdata.push(...offlineData['emails']);
      }
    });
    await this.disk.retrieve(DB_KEY_PREFIXES.ACCOUNT_PLAN_PROGRESS_REPORT).then((data) => {
      if (data && data.raw) {
        dbdata.push(...data.raw.filter(o => o.syncPending));
      }
    });
    await this.disk.retrieve('offlineCustomerInquiries', true).then((offlineData: any[]) => {
      if (offlineData && offlineData['myCases']) {
        dbdata.push(...offlineData['myCases']);
      }
    });
    let option = {
      selector: {
        '_id': {
          $gte: DB_KEY_PREFIXES.POLL_RESULTS + "_",
          $lte: DB_KEY_PREFIXES.POLL_RESULTS + "_" + PREFIX_SEARCH_ENDKEY_UNICODE
        },
        'submitted': { $eq: false }
      }
    };
    await this.disk.find(option).then((data: []) => {
      if(data) {
        dbdata.push(...data);
      }
    });
    return dbdata;
  }

}