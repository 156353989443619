import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AffiliationExplorerComponent } from '@omni/components/shared/affiliation-explorer/affiliation-explorer';
import { TimeLineItem } from '@omni/components/timeline-item/timeline-item.component';
import { GlanceCardViewDataModel } from '@omni/models/glanceCardViewDataModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { ActivityService } from '@omni/services/activity/activity.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { DeviceService } from '@omni/services/device/device.service';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { Customer360UIService } from '@omni/services/ui/customer360ui.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { LoadingController, ModalController } from '@ionic/angular';
import { IndFilterMenuModalComponent, IndFilterMenuModalDataModel } from '@omni/components/shared/ind-filter-menu-modal/ind-filter-menu-modal';
import { Opportunity } from '@omni/classes/opportunity-management/opportunity.class';
import { OpportunityManagementService } from '@omni/services/opportunity-management/opportunity-management.service';
import { OpportunityDetailsComponent } from '@omni/components/opportunity-management/opportunity-details/opportunity-details';
import { EventName, EventsService } from '@omni/services/events/events.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { UIService } from '@omni/services/ui/ui.service';
import { OpportunityManagementDataService } from '@omni/data-services/opportunity-management/opportunity-management.data.service';

/**
 * Generated class for the ContactOmnichannelEventsComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'contact-omnichannel-opportunities',
  templateUrl: 'contact-omnichannel-opportunities.html',
  styleUrls: ['contact-omnichannel-opportunities.scss']
})
export class ContactOmnichannelOpportunitiesComponent {

  public pageTitleViewData: IndPageTitleViewDataModel;
  public contactGlanceViewData: GlanceCardViewDataModel;
  public opportuitiesSectionHeader: IndSectionHeaderViewDataModel;
  public viewOpportunitiesItems: TimeLineItem[] = [];
  private currentStartIndex: number = 30;
  private _filteredOpportunities: Opportunity[]
  private holdActivityDetailsPage: PageName;
  private ngDestroy$ = new Subject<boolean>();
  public searchPlaceholder: string;
  public filterButtonBadgeCount: number = 0;
  public opportunitiesFilterFormView: IndFilterMenuModalDataModel[] = [];
  public selectedFilters: any = [];
  private selectedDate: { startDate: string, endDate: string } = { startDate: '', endDate: '' };
  ngUnSubscribe$ = new Subject<boolean>();
  public searchInput: string = '';
  public allOpportunities: Array<any> = [];
  private filteredOpportunities: Array<any> = [];
  public filteredViewOpportunities: Array<TimeLineItem> = [];
  public timelineItems: TimeLineItem[] = [];

  constructor(
    private readonly contactService: ContactOfflineService,
    private readonly navService: NavigationService,
    private readonly translate: TranslateService,
    private readonly customer360UIservice: Customer360UIService,
    private readonly device: DeviceService,
    public footerService: FooterService,
    public activityService: ActivityService,
    private readonly modalCtrl: ModalController,
    private opportunityService: OpportunityManagementService,
    public events: EventsService,
    private readonly authenticationService: AuthenticationService,
    private uiService: UIService,
    private loadingCtrl: LoadingController,
    private readonly opportunityDataService: OpportunityManagementDataService,
  ) {

  }

  ngOnInit() {
    this.initPageTitle();
    this.initGlanceCardViewData();
    this.initList();
    this.initSectionHeadersView();
    this.navService.childNavPopObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(page => {
      if (page && page == this.holdActivityDetailsPage && this.navService.getActiveChildNavViewPageName() == PageName.ContactDetailsComponent) {
        this.initOpportunitiesItemsViewData();
      }
    })
    this.opportunityService.opportunities$.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((data) => {
      if (data?.length) {
        this.getOpportunities();
        this.initOpportunitiesItemsViewData();
      }
    });
  }

  private initList() {
    this.allOpportunities = [];
    this.filteredOpportunities = [];
    if (!_.isEmpty(this.contactService.contactInformation)) {
      this.getOpportunities();
    } else {
      this.allOpportunities = [];
      this.filteredOpportunities = [];
      this.initOpportunitiesItemsViewData();
    }
  }
  async getOpportunities() {
    let items: any = [];
    let loader = await this.loadingCtrl.create();
    let Opportunities = this.opportunityService.opportunities.filter((oppo) => {
      const isContact = oppo.contactID && oppo.contactID == this.contactService.contactInformation.ID;
      const isStakeholder = oppo.stakeholders?.length > 0 && oppo.stakeholders.some(opp => opp.ID == this.contactService.contactInformation.ID);
      if (isContact || isStakeholder) return true
    })
    items = Opportunities.sort((a, b) => {
      if (a.createdOn && b.createdOn) {
        return parseInt(b.createdOn) - parseInt(a.createdOn)
      } else {
        return 0;
      }
    });
    await loader.present();
    try {
      for (let item of items) {
        let res = await this.opportunityDataService.getOpportunityActiveStageIdByProcessInstanceId(item.opportunityBusinessProcessInstanceId, item.opportunityBusinessProcessSchemaName);
        if (res && Array.isArray(res)) {
          if (res[0] && res[0]['_activestageid_value']) {
            item.opportunityStage = res[0]['_activestageid_value']
            item.opportunityStageValue = res[0]['_activestageid_value@OData.Community.Display.V1.FormattedValue']
          }
        }
      }
      await loader.dismiss();
    } catch (err) {
      await loader.dismiss();
    }
    this.filteredOpportunities = this.allOpportunities = items;
    this.initOpportunitiesItemsViewData();
    this.initFilterFormViews();
  }


  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
    this.ngUnSubscribe$.next(true);
    this.ngUnSubscribe$.complete();
  }


  private initPageTitle() {
    this.pageTitleViewData = {
      id: "contact-timeline-details",
      title: this.contactService?.contactInformation?.fullName,
      controls: [{
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      }]
    };
    this.searchPlaceholder = this.translate.instant('SEARCH_OPPORTUNITIES');
  }

  private async initGlanceCardViewData() {
    let glanceCardButtons = [];
    let contact = this.contactService.getContactByID(this.contactService.contactInformation['ID']);
    if (contact) {
      if (this.authenticationService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)) {
        glanceCardButtons.push({
          id: "phone-call",
          name: this.translate.instant('NEW_ACTIVITY_PHONECALL'),
          isDisabled: !contact.mobilePhone,
          imgSrc: contact.mobilePhone ? 'assets/imgs/omni_quick_glance_call.svg' : 'assets/imgs/omni_quick_glance_call_disabled.svg',
          align: "right"
        });
      }
      if (this.authenticationService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY)) {
        glanceCardButtons.push({
          id: "quick-message",
          imgSrc: 'assets/imgs/omni_quick_glance_email.svg',
          name: this.translate.instant('MESSAGE'),
          isDisabled: false,
          align: "right"
        });
      }
      glanceCardButtons.push({
        id: "quick-meeting",
        imgSrc: 'assets/imgs/omni_quick_glance_meeting.svg',
        name: this.translate.instant('MEETING'),
        isDisabled: false,
        align: "right"
      });
    }
    if (this.contactService.isAffiliationEnabled) {
      glanceCardButtons.push({
        id: "affiliation-explorer",
        imgSrc: 'assets/imgs/glance-affiliation-explorer.svg',
        name: this.translate.instant('AFFILIATION_EXPLORER'),
        isDisabled: !((this.contactService.linkEntityContactTo && this.contactService.linkEntityContactTo.length) || (this.contactService.linkEntityContactFrom && this.contactService.linkEntityContactFrom.length)
          || (this.contactService.affiliationAccount && this.contactService.affiliationAccount.length)),
        align: "right"
      });
    }
    const values = await this.contactService._getQuickGlanceValues();

    if (!this.device.isOffline) {
      this.contactService.getEntityImageStringForContact(this.contactService.contactInformation).then((entityImageString => {
        if (entityImageString) {
          this.contactService.contactInformation.entityImage = entityImageString;
          if (this.contactGlanceViewData) {
            this.contactGlanceViewData.avatarURL = this.contactService.contactInformation.entityImage
          }
        }
      }));
    }
    this.contactGlanceViewData = {
      name: this.contactService.contactInformation.fullName,
      locationText: this.contactService.contactInformation.getPrimaryAddress,
      avatarURL: this.contactService.contactInformation.entityImage,
      thirdText: (values && values.length && values[0]) ? values[0].value : '',
      fourthText: (values && values.length && values[1]) ? values[1].value : '',
      fifthText: (values && values.length && values[2]) ? values[2].value : '',
      sixthText: (values && values.length && values[3]) ? values[3].value : '',
      thirdTextLabel: (values && values.length && values[0]) ? values[0].label : '',
      fourthTextLabel: (values && values.length && values[1]) ? values[1].label : '',
      fifthTextLabel: (values && values.length && values[2]) ? values[2].label : '',
      sixthTextLabel: (values && values.length && values[3]) ? values[3].label : '',
      buttons: glanceCardButtons,
      enableExpand: true,
      isExpanded: false,
    };
  }

  public async onQuickGlanceControlClick(data) {
    if (data.id) {
      switch (data.id) {
        case 'affiliation-explorer':
          this.handleAffiliationExplorer();
          break;
        case 'phone-call':
          this.events.publish(EventName.QUICKGLANCECCONTACTREATEPHONECALL, true);
          break;
        case 'quick-message':
          this.events.publish(EventName.QUICKGLANCECCONTACTREATEMESSAGE, true);
          break;
        case 'quick-meeting':
          this.events.publish(EventName.QUICKGLANCECCONTACTREATEMEETING, true);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  private async handleAffiliationExplorer() {
    let params = {
      from: 'ContactPageComponent',
      parent: this.contactService.contactInformation,
      relatedTo: await this.contactService.getContactRelatedToByContactId(this.contactService.contactInformation.ID),
      contactAccountAffiliation: await this.contactService.getContactToAccountByContactId(this.contactService.contactInformation.ID),
      relatedFrom: await this.contactService.getContactRelatedFromByContactId(this.contactService.contactInformation.ID),
    };
    this.navService.pushChildNavPageWithPageTracking(AffiliationExplorerComponent, PageName.AffiliationExplorerComponent, PageName.ContactPageComponent, params);
  }

  private initSectionHeadersView() {
    this.opportuitiesSectionHeader = {
      id: 'contact-opportunities-list-section',
      title: this.translate.instant('ALL_OPPORTUNITIES') + ' (' + this.filteredViewOpportunities.length + ')',
      controls: []
    }
  }

  public async onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closePage();
    }
  }

  private closePage() {
    this.navService.popChildNavPageWithPageTracking();
  }

  private async initOpportunitiesItemsViewData() {
    this.filteredViewOpportunities = [];
    if (this.contactService.contactInformation.ID) {
      if (this.filteredOpportunities && this.filteredOpportunities.length > 0) {
        for (let item of this.filteredOpportunities) {
          let view = await this.customer360UIservice._getProfileItemViewForOpportunities(item);
          if (view) {
            this.filteredViewOpportunities.push(view);
          }
        }
      } else {
        this.filteredViewOpportunities = [];
      }
      //  Search Logic
      if (this.searchInput && this.searchInput.length >= 2) {
        this.filteredViewOpportunities = this.initSearch();
      }
      this.viewOpportunitiesItems = this.filteredViewOpportunities.slice(0, this.currentStartIndex);
      this.initSectionHeadersView();
    }

  }

  public async doInfinite(event) {
    this.viewOpportunitiesItems.push(...this.sliceActivities(this.currentStartIndex + 1, this.currentStartIndex + 30));
    this.currentStartIndex += 30;
    event.target.complete();
  }


  private sliceActivities(startIndex: number, count: number) {
    return this.filteredViewOpportunities.length < count ? this.filteredViewOpportunities.slice(startIndex) : this.filteredViewOpportunities.slice(startIndex, count);
  }


  openOpportunityDetail(ev) {
    let item: any = this.viewOpportunitiesItems.find(a => ev && a.id == ev.value);
    if (item) {
      let opportunityId = item.refObject.ID
      this.navService.pushChildNavPageWithPageTracking(OpportunityDetailsComponent,
        PageName.OpportunityDetailsComponent, PageName.ContactPageComponent, { from: 'ContactTimeline', opportunity: this.opportunityService.getOpportunityDetailsbyId(opportunityId) })
    }
  }

  private initFilterFormViews() {
    this.opportunitiesFilterFormView = [];
    if (!_.isEmpty(this.allOpportunities)) {
      let filterFormViewMain: IndFilterMenuModalDataModel = {
        id: 'filter-main',
        displayName: this.translate.instant('STATUS'),
        isMultiSelect: true,
        isExpanded: true,
        options: []
      }
      this.opportunitiesFilterFormView.push(filterFormViewMain);
      filterFormViewMain.options.push({ id: 'status-open', group: 'Open', isSelected: false, displayName: this.translate.instant('OPEN') });
      filterFormViewMain.options.push({ id: 'status-won', group: 'Won', isSelected: false, displayName: this.translate.instant('WON') });
      filterFormViewMain.options.push({ id: 'status-lost', group: 'Lost', isSelected: false, displayName: this.translate.instant('OPPORTUNITY_STATE_LOST') });
    }
  }

  public onFilterClick(ev) {
    this.openFilter(ev);
  }

  private async openFilter(event) {
    const modal = await this.modalCtrl.create({
      component: IndFilterMenuModalComponent,
      cssClass: 'filter-menu-right-modal',
      backdropDismiss: true,
      componentProps: {
        viewData: {
          from: PageName.ContactOmnichannelOpportunitiesComponent,
          data: this.opportunitiesFilterFormView,
          filterTitle: this.translate.instant('ALL_FILTERS'),
          selectedFilters: this.selectedFilters,
          selectedDate: this.selectedDate
        },
      }
    });
    await modal.present().then(() => { });
    modal.onDidDismiss().then(async (res) => {
      //Show Result
      if (res && res.data && res.data.isDone) {
        if (!_.isEmpty(res.data.selectedItems) || !_.isEmpty(res.data.selectedDate)) {
          this.opportunitiesFilterFormView = res.data.selectedItems;
          this.selectedFilters = res.data.selectedItemsAccordion;
          this.applyAdvancedFilterOnOpportunities();
        }
      }
    })
  }

  private async applyAdvancedFilterOnOpportunities() {
    if (!_.isEmpty(this.opportunitiesFilterFormView)) {
      this.filterButtonBadgeCount = 0;
      this.filteredOpportunities = [];
      this.filterButtonBadgeCount = this.selectedFilters.length;
      if (!_.isEmpty(this.selectedDate) && (!_.isEmpty(this.selectedDate.startDate) || !_.isEmpty(this.selectedDate.endDate))) {
        this.filterButtonBadgeCount = this.filterButtonBadgeCount + 1;
      }
      let selectedActivitiesFromMainFilter = [];
      this.selectedFilters.forEach(item => {
        if (item.type == 'filter-main') {
          selectedActivitiesFromMainFilter.push(item.category);
        }
      });
      let targetActivities = _.cloneDeep(this.allOpportunities);
      if (!_.isEmpty(selectedActivitiesFromMainFilter)) {
        let filteredTimelineOpportunities = [];
        selectedActivitiesFromMainFilter.forEach(mainFilterValue => {
          targetActivities.forEach(filterOpp => {
            if (filterOpp.status == mainFilterValue) {
              filteredTimelineOpportunities.push(filterOpp);
            }
          });
        });
        targetActivities = filteredTimelineOpportunities;
      }
      /******* grouped filtered opportunities *******/
      this.filteredOpportunities = targetActivities;
      this.initOpportunitiesItemsViewData();
      this.initSectionHeadersView();
    } else {
      this.filteredOpportunities = this.allOpportunities;
      this.initOpportunitiesItemsViewData();
      this.initSectionHeadersView();
    }
  }

  handleSearch() {
    this.initOpportunitiesItemsViewData();
  }

  initSearch() {
    return this.filteredViewOpportunities.filter(item => {
      let flag: boolean = false;
      if (item.headerText) {
        flag = (item.headerText.toLocaleLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1);
      }
      if (!flag && item.subheaderText) {
        flag = (item.subheaderText.toLocaleLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1);
      }
      if (!flag && item.subheaderText2) {
        flag = (item.subheaderText2.toLocaleLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1);
      }
      if (!flag && item.dataToDisplay && item.dataToDisplay.length > 0) {
        for (let i = 0; i < item.dataToDisplay.length; i++) {
          const dtdItem = item.dataToDisplay[i];
          if (dtdItem && dtdItem.value) {
            flag = (dtdItem.value.toLocaleLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1);
          }
          if (flag) {
            break;
          }
        }
      }
      return flag;
    });
  }

}
