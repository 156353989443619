<ion-header mode="md">
  <ion-toolbar class="header">
    <ion-title>
      <div class="ion-text-wrap">{{ header }}</div>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button fill="icon-only" (click)="modalController.dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>
</ion-header>

<div *ngIf="alertType === 0">
  <omni-list-item cssClass="omni-border-bottom ion-padding" label="{{ topLabel }}"
    value="{{ topValue }}"></omni-list-item>
  <omni-list-item cssClass="ion-padding" label="{{ bottomLabel }}" value="{{ bottomValue }}"></omni-list-item>
</div>

<div class="notification-wrapper">
  <ion-item *ngIf="alertType === 1" class="notification-item" lines="none">
    <ion-icon class="notification-icon" [src]="notificationIcon" *ngIf="notificationIcon"></ion-icon>
    <ion-label class="notification-label">{{ notificationMessage }}</ion-label>
  </ion-item>

  <ion-note class="notification-subtext" color="dark" *ngIf="note"><span class="bold-text"> {{ 'NOTE' | translate }} : </span><ion-note color="medium">{{ note }}</ion-note></ion-note>
  
  <div *ngIf="buttons.length > 0">
    <ion-button *ngFor="let customButton of buttons" fill="clear"  class="alert-btn ion-float-right"
      (click)="onButtonClick(customButton)">{{ customButton.label }}</ion-button>
  </div>

</div>
