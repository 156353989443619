import { Component, Input } from '@angular/core';
import { ActivityService } from '../../../services/activity/activity.service';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { TranslateService } from '@ngx-translate/core';
import { PopoverController } from '@ionic/angular';
import { ListPreviewComponent } from '@omni/components/list-preview/list-preview.component';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { ConsentService } from '@omni/services/consent/consent.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { TimeLineItem } from '@omni/components/timeline-item/timeline-item.component';
import { AppointmentActivity } from '@omni/classes/activity/appointment.activity.class';
import { format, distanceInWordsToNow, isPast, isValid } from 'date-fns';
import { DateTimeFormatsService } from '@omni/services/date-time-formats/date-time-formats.service';
import { AuthenticationService } from '@omni/services/authentication.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { SampleActivity } from '@omni/classes/activity/sample.activity.class';
import { EmailActivity, EmailViewType } from '@omni/classes/activity/email.activity.class';
import { EmailService } from '@omni/services/email-templates/email.service';
import { PhoneActivity } from '@omni/classes/activity/phone.activity.class';
import { SurgeryOrderActivity } from '@omni/classes/activity/surgery-order.activity.class';
import { CaseActivity } from '@omni/classes/case-intake/case-activity.class';
import { EventActivity } from '@omni/classes/events-tool/event.class';
import { ConsentSource, ConsentTerm } from '@omni/classes/consent/consent-term.class';
import { ConsentActivity } from '@omni/classes/consent/consent-activity.class';
import { Activity, ActivityType, MeetingActivityState } from '@omni/classes/activity/activity.class';
import _ from 'lodash';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { AccesingMode, CaseManagementService } from '@omni/services/case-management/case-management.service';
import { SampleService, SamplingDetailsViewMode } from '@omni/services/sample/sample.service';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';
import { DeviceService } from '@omni/services/device/device.service';
import { EventsService } from '@omni/services/events/events.service';
import { ActivitiesDetailsPaneComponent } from '@omni/components/activity/activities-details-pane/activities-details-pane';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { NewSampleActivityComponent } from '@omni/components/activity/new-sample-activity/new-sample-activity';
import { EmailActivityDetailComponent } from '@omni/components/activity/email-activity-detail/email-activity-detail';
import { CaseManagementDataService } from '@omni/data-services/case-management/case-management.data.service';
import { CaseManagementDetailComponent } from '@omni/components/case-management/case-management-detail/case-management-detail';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { PhoneCallDetailsComponent } from '@omni/components/phonecall/phone-call-details/phone-call-details';
import { SurgeryOrderDetailsComponent } from '@omni/components/surgery-order/surgery-order-details/surgery-order-details';
import { EventRegistration } from '@omni/classes/customer-event/customer-event.class';
import { EventsToolDataService } from '@omni/data-services/event/events-tool.data.service';
import { EventDetailsComponent } from '@omni/components/events-tool/event-details/event-details';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { AssessmentTemplate, SurveyFrequency, SurveyStatus, TemplateType } from '@omni/classes/customer-assessment/assessment-template.class';
import { SurveyDetailsComponent } from '@omni/components/survey/survey-details/survey-details';
import { CustomerSurveyService } from '@omni/services/customer-survey/customer-survey.service';
import { CustomerAssessment } from '@omni/classes/customer-assessment/customer-assessment.class';
import { ContactTimelinePageComponent } from '../contact-timeline-page/contact-timeline-page';
import { Customer360UIService } from '@omni/services/ui/customer360ui.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EventRegistrationStatus } from '@omni/enums/event/event.enum';

/**
 * Generated class for the ContactFeedComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'contact-feed',
  templateUrl: 'contact-feed.html',
  styleUrls: ['contact-feed.scss']
})
export class ContactFeedComponent {

  public timelineSectionHeader: IndSectionHeaderViewDataModel;
  public timelineItems: Array<TimeLineItem> = [];
  @Input () modalView: any

  private isMeetingHideSubTypeFAEnabled: boolean = false;
  private ngDestroy$ = new Subject<boolean>();
  @Input() readOnly: boolean;

  constructor(
    private readonly contactService: ContactOfflineService,
    private readonly activityService: ActivityService,
    private readonly eventsToolService: EventsToolService,
    private readonly customerAssessService: CustomerAssessService,
    private readonly consentService: ConsentService,
    private readonly translate: TranslateService,
    private readonly popoverController: PopoverController,
    private readonly dateTimeFormatService: DateTimeFormatsService,
    private readonly authService: AuthenticationService,
    private readonly emailService: EmailService,
    private readonly dynamicFormService: DynamicFormsService,
    private readonly caseService: CaseManagementService,
    private readonly sampleService: SampleService,
    public uiService: UIService,
    private readonly activityDataService: ActivityDataService,
    private readonly device: DeviceService,
    private readonly eventService: EventsService,
    private readonly navService: NavigationService,
    private readonly footerService: FooterService,
    private readonly caseDataService: CaseManagementDataService,
    private readonly eventsToolDataService: EventsToolDataService,
    private readonly notificationService: NotificationService,
    private readonly customerSurveyService: CustomerSurveyService,
    private readonly customer360UIService: Customer360UIService,
  ) {

  }

  ngOnInit() {
    this._initFA();
    this._initSectionHeadersView();
    this._initTimelineItemsData();

    this.navService.childNavPopObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(page=> {
      if(page && this.navService.getActiveChildNavViewPageName() == PageName.ContactDetailsComponent && (page == PageName.ActivitiesDetailsPaneComponent || page == PageName.PhoneCallDetailsComponent || page == PageName.SurgeryOrderDetailsComponent || page == PageName.NewSampleActivityComponent || page == PageName.EmailDetailsPageComponent || page == PageName.CaseManagementDetailComponent || page == PageName.SurveyDetailsComponent || page == PageName.EventDetailsPageComponent || page == PageName.ContactTimelinePageComponent)){
        this._initTimelineItemsData();
      }
    })
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  private _initFA() {
    this.isMeetingHideSubTypeFAEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MEETING_HIDE_SUBTYPE);
  }

  private _initSectionHeadersView() {
    this.timelineSectionHeader = {
      id: 'contact-timeline-section',
      title: this.translate.instant('TIMELINE'),
      controls: [{
        id: 'opentimelinedetails',
        isDisabled: this.readOnly,
        iconName: "chevron-forward-outline",
        //icon: 'assets/imgs/arrow-right-grey.svg',
      }]
    }
  }

  onSectionHeaderControlClick(id: string) {
    if (id === 'opentimelinedetails') {
      this.uiService.timelineRefreshRequired = true;
      this.navService.pushChildNavPageWithPageTracking(ContactTimelinePageComponent,PageName.ContactTimelinePageComponent,PageName.ContactPageComponent);
    }
  }

  private async _initTimelineItemsData() {
    if(this.contactService.contactInformation.ID){
      this.timelineItems = [];
      let activities: Array<any> = await this._getLatestActivitiesForTimeline(3);
      if (activities && activities.length > 0) {
        activities.forEach(item => {
          //const newActivity = await this.activityDataService.getNewDetailAppendedAppointmentActivityInstanceFromLocalDB(item.ID) as AppointmentActivity;
          let view = this.customer360UIService.getViewForJourneyItem(true, item, this.contactService.contactInformation.ID);
          if (view) {
            this.timelineItems.push(view);
          }
        })
      }
    }
  }

  private async _getLatestActivitiesForTimeline(sliceEnd?: number): Promise<Array<any>> {
    let allItems = [];
    let result = [];
    // Appointment
    // Sample
    // Email
    // Phone Call
    // Surgery Order
    // Case Intake
    const contactID = this.contactService.contactInformation.ID;
    try {
      for(let i=0; i<this.activityService.activities.length ;i++){
        const a = this.activityService.activities[i];
        if(a instanceof AppointmentActivity){
          if(a.contacts && a.contacts.some(b=> b.ID == contactID) && a.status != 4){
            allItems.push(a);
          }
        }else if(a instanceof SampleActivity){
          if(a.contactID == contactID){
            allItems.push(a);
          }
        }else if(a instanceof EmailActivity){
          if(a.emailActivityParties && a.emailActivityParties.some(b=> b.indskr_contactid== contactID)){
            allItems.push(a);
          }
        }else if(a instanceof PhoneActivity){
          if(a.contacts && a.contacts.length > 0 && a.contacts.some(b=> b.ID == contactID)){
            allItems.push(a);
          }else if(a.rawContactAttendees && a.rawContactAttendees.some((b:any)=> b.indskr_contactid == contactID || b.ID == contactID)){
            allItems.push(a);
          }
        }else if(a instanceof SurgeryOrderActivity){
          if(a.customers && a.customers.some(b=> b.ID == contactID)  && a.status != 548910005){
            allItems.push(a);
          }
        }else if(a instanceof CaseActivity){
          if(a._case_contact_id == contactID){
            allItems.push(a);
          }
        }
      }
      allItems = allItems.sort((a, b) => {
        if (a.scheduledStart > b.scheduledStart) return -1;
        if (a.scheduledStart < b.scheduledStart) return 1;
        return 0;
      }).slice(0, sliceEnd);
    } catch (error) {
      console.log('Error occured while getting activities for journey section' + error);
    }

    // Event
    try {
      if (this.eventsToolService.eventsToolData && this.eventsToolService.eventsToolData.length > 0) {
        let eventItems = this.eventsToolService.eventsToolData.filter(a=> a.participants && a.participants.some(b=> b.id == contactID  &&
          !(
            b.isDeleted
            || b.indskr_reasons === EventRegistrationStatus.Cancelled
            || b.indskr_reasons === EventRegistrationStatus.Declined
            || b.indskr_reasons === EventRegistrationStatus['Cancelled late']
            || b.indskr_reasons === EventRegistrationStatus['No show']
          )) ).map(a=> {
          if(!isValid(a.scheduledStart)){
            a.scheduledStart = a.startDate;
          }
          if(!isValid(a.scheduledEnd)){
            a.scheduledEnd = a.endDate;
          }
          return a;
        }).sort((a, b) => {
          if (a.scheduledStart > b.scheduledStart) return -1;
          if (a.scheduledStart < b.scheduledStart) return 1;
          return 0;
        }).slice(0, sliceEnd);
        if (eventItems && eventItems.length > 0) {
          allItems.push(...eventItems);
        }
      }
    } catch (error) {
      console.log('Error occured while getting events for journey section' + error);
    }

    // Customer Survey
    this.customerAssessService.allContactSurveys;
    try {
      let surveyItems = [];
      // if(this.customerAssessService.allContactSurveys && this.customerAssessService.allContactSurveys.length > 0){
      //   surveyItems = this.customerAssessService.allContactSurveys.sort((a,b)=> {
      //     if (a.indskr_fromdate > b.indskr_fromdate) return 1;
      //     if (a.indskr_fromdate < b.indskr_fromdate) return -1;
      //     return 0;
      //   }).slice(0, sliceEnd).map(a=> {
      //     if(a.indskr_fromdate){
      //       a['scheduledStart'] = new Date(a.indskr_fromdate);
      //     }
      //     return a;
      //   });
      // }
      let surveysByContactId: any = this.customerAssessService.contactSurveysForTimeline.filter(survey => survey.surveyStatus != SurveyStatus.SAVED) || [];
      if (!_.isEmpty(surveysByContactId)) {
        let groupedResponsesByDate: any = [];
        groupedResponsesByDate = _.groupBy(surveysByContactId, 'indskr_assessmentdate');
        groupedResponsesByDate = _.values(groupedResponsesByDate);

        groupedResponsesByDate.forEach((resp) => {
          let activity: any = {};

          const templateId: string = resp[0]['_indskr_template_value'];
          const foundTemplate: AssessmentTemplate = this.customerAssessService.surveytemplates.find(survey => survey.indskr_assessmenttemplateid == templateId);

          let surveyFrequency: string = foundTemplate['indskr_surveyfrequency'] ? foundTemplate['indskr_surveyfrequency'] == SurveyFrequency.ONCE ? this.translate.instant("ONE_TIME") : this.translate.instant("RECURRING") : '';
          activity['type'] = ActivityType.CustomerSurvey;
          activity['subject'] = resp[0]['indskr_name'] || '';
          activity['assessmentId'] = resp[0]['indskr_customerassessmentid'];
          activity['scheduledStart'] = resp[0]['indskr_assessmentdate'];
          activity['surveyFrequency'] = surveyFrequency;
          activity['surveyedBy'] = resp[0]['_modifiedby_value_Formatted'] || '';
          activity['templateId'] = templateId;
          // activity['rawData'] = resp;
          surveyItems.push(activity);
        });
      }
      surveyItems = surveyItems.sort((a, b) => {
        if (a.scheduledStart > b.scheduledStart) return -1;
        if (a.scheduledStart < b.scheduledStart) return 1;
        return 0;
      }).slice(0, sliceEnd);
      allItems.push(...surveyItems);
    } catch (error) {
      console.log('Error occured while getting survey for journey section'+error);
    }

    // Consent Activity
    try {
      let consenctActivities = this.consentService.allConsentActivitiesWithProductsAndEmails;
      if (consenctActivities && consenctActivities.length > 0 && this.contactService.contactInformation) {
        consenctActivities = consenctActivities.filter((activity) => activity.contactId == contactID);
        let consentItems = [];
        consenctActivities?.forEach((conAct) => {
          let activity: any = _.cloneDeep(conAct);
          let secondaryText = [conAct.indskr_productname, conAct.emailAddress, conAct.indskr_phone, conAct.indskr_addressname].filter(Boolean).join(', ');
          let optInOutSourceName: string = '';
          
          if (conAct.indskr_type == 100000000 && conAct.indskr_optinsource_Formatted) {
            optInOutSourceName = conAct.indskr_optinsource_Formatted;
          } else if (conAct.indskr_type == 100000001 && conAct.indskr_optoutsource_Formatted) {
            optInOutSourceName = conAct.indskr_optoutsource_Formatted
          }
          
          const consentSourceTypeName = conAct.consentSourceName || '';
          let consentSourceText: string = '';

          if (optInOutSourceName && consentSourceTypeName) {
            consentSourceText = optInOutSourceName + ':' + consentSourceTypeName;
          } else if (optInOutSourceName) {
            consentSourceText = optInOutSourceName;
          } else if (consentSourceTypeName) {
            consentSourceText = consentSourceTypeName;
          }

          activity['type'] = ActivityType.ConsentActivity;
          activity['subject'] = conAct.indskr_name;
          activity['source'] = consentSourceText;
          activity['scheduledStart'] = new Date(conAct.indskr_activitydate ?? conAct.createdon);
          activity['ownerId'] = conAct._createdby_value;
          activity['secondaryText'] = secondaryText;
          consentItems.push(activity);
        })
        if (consentItems && consentItems.length > 0) {
          consentItems = consentItems.sort((a, b) => {
            if (a.scheduledStart > b.scheduledStart) return -1;
            if (a.scheduledStart < b.scheduledStart) return 1;
            return 0;
          }).slice(0, sliceEnd);
          allItems.push(...consentItems);
        }
      }
    } catch (error) {
      console.log('Error occured while getting events for journey section' + error);
    }

    try {
      if (allItems && allItems.length > 0) {
        allItems = allItems.sort((a, b) => {
          if (a.scheduledStart > b.scheduledStart) return -1;
          if (a.scheduledStart < b.scheduledStart) return 1;
          return 0;
        }).slice(0, sliceEnd);
      }
    } catch (error) {
      console.log("Error occured while sorting and getting latest activities for journey" + error);
    }

    // Were getting fetched through online calls only
    // Website Access Logs
    // Customer Journey Appointment
    // Customer Journey Phonecalls
    if(allItems.length > 0){
      for(let i=0;i<allItems.length;i++){
        const item = allItems[i];
        if(item instanceof AppointmentActivity){
          const activity = await this.activityDataService.getNewDetailAppendedAppointmentActivityInstanceFromLocalDB(item.ID);
          result.push(activity);
        }else if(item instanceof PhoneActivity){
          const activity = await this.activityDataService.getNewDetailAppendedPhoneCallActivityInstanceFromLocalDB(item.ID);
          result.push(activity);
        }else{
          result.push(item);
        }
      }
    }
    return allItems;
  }

  public openDetails(ev) {
    if(this.readOnly) return;
    let item:any = this.timelineItems.find(a=> ev && a.id == ev.value);
    if (item) {
      let selActivity = item.refObject;
      //prevent navigation issue
      if ((this.contactService.accessedContactListFrom === PageName.AccountDetailsComponent && this.contactService.contactPageMode === ComponentViewMode.READONLY)
        || this.contactService.contactPageMode === ComponentViewMode.PREVIEW
        || this.dynamicFormService.isOpenedAffiliatedContactOnAccount
        || this.dynamicFormService.isOpenedAffiliatedContactOnContact
        || this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING
        || this.caseService.accessedFrom == AccesingMode.INMEETING
        || (this.modalView && this.sampleService.inMeetingAllocationActivity)
        || selActivity.type == ActivityType.ConsentActivity) {
        console.log("The current activity cannot be opened to avoid possible problems with navigation loop");
        return;
      }
      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'ActivitySkeleton';
      setTimeout(() => {
        this.displayActivityDetails(selActivity as Activity);
      }, 0);
    }
  }

  async displayActivityDetails(activity: Activity) {
    let foundActivity = this.activityService.getActivityByID(activity.ID);
    if (activity.type == ActivityType.Sample) {
      if (foundActivity) {
        this.activityService.selected = foundActivity;
      } else {
        await this.activityDataService.updateActivityDetails(activity);
      }
      this.sampleService.samplingDetailsViewMode = SamplingDetailsViewMode.VIEW_DETAILS;
      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'Sample';
      this.uiService.prevView = 'contactDetails';
      if(this.navService.getCurrentMasterPageName() === PageName.ScientificActivityPage){
        this.uiService.showCancelDoneOnActivityDetails = false;
      } else if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan ==='scientificPlans') {
        this.uiService.showCancelDoneOnActivityDetails = false;
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      }
      this.navService.pushChildNavPageWithPageTracking(NewSampleActivityComponent, PageName.NewSampleActivityComponent, PageName.ContactPageComponent, { from: PageName.ContactDetailsComponent });
    } else if (activity.type == ActivityType.Email) {
      if(this.device.isOffline) {
        this.activityService.selectedActivity = (foundActivity) ? foundActivity : activity;
      }
      await this.activityDataService.updateActivityDetails(foundActivity ? foundActivity : activity).then(() => {
        this.emailService.viewType = EmailViewType.FROM_CONTACT_TIMELINE;
        this.uiService.showNewActivity = false;
        this.emailService.setCurrentEmail(this.emailService.selectedActivity);
        if(this.navService.getCurrentMasterPageName() !== PageName.ContactPageComponent){
          this.uiService.activeView = 'Email';
        }
        this.uiService.prevView = 'contactDetails';
        if(this.navService.getCurrentMasterPageName() === PageName.ScientificActivityPage){
          this.uiService.showCancelDoneOnActivityDetails = false;
        }
        if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan ==='scientificPlans') {
          this.uiService.showCancelDoneOnActivityDetails = false;
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        }
        if(!isValid(this.emailService.selectedActivity.scheduledStart) || !isValid(this.emailService.selectedActivity.scheduledEnd)){
          // OMNI-27162: Currently contact timeline activities fetch service is returning activities created on dynamics for customer enquiry
          // Those activities doesn't have any scheduled start and end time attribute value so added this check to prevent the UI to break
          // In future it will be handled on service end to omit those activities from response
            return;
        }
        this.navService.pushChildNavPageWithPageTracking(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ContactPageComponent, { from: PageName.ContactDetailsComponent });

      }).catch(() => {
        console.log("Failed to load email activity:" + activity.ID);
      });
    } else if (activity.type == ActivityType.CaseIntake) {
      let iCase: CaseActivity;
      if (!this.device.isOffline && !activity.ID.includes('offline')) {
        this.uiService.displayLoader();
        await this.caseDataService.findCaseById(activity.ID).then(
          async res => {
            if (res) {
              for (let key in res) {
                if (key.charAt(0) === "_") {
                  var a = key.substring(1, key.length);
                  res[a] = res[key];
                  delete res[key];
                }
              }
              iCase = new CaseActivity(res);
            }
            this.uiService.dismissLoader();
          }).catch(() => {
            console.log("Failed to load customer inquiry:" + activity.ID);
            this.uiService.dismissLoader();
            iCase = activity as CaseActivity;
          });
      } else {
        iCase = activity as CaseActivity;
      }
      if(!_.isEmpty(iCase)) {
        iCase = await this.caseService.reInitDataDependency(iCase);
        this.activityService.selectedActivity = iCase;
        this.caseService.assignSelectedCase(iCase);
        this.caseService.accessedFrom = AccesingMode.CONTACT_TIMELINE;
        this.uiService.showNewActivity = false;
        this.uiService.activeView = 'ContactPageRightPaneNav';
        this.uiService.prevView = 'contactDetails';
        if (this.navService.getCurrentMasterPageName() === PageName.ScientificActivityPage) {
          this.uiService.showCancelDoneOnActivityDetails = false;
        }
        else if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan === 'scientificPlans') {
          this.uiService.showCancelDoneOnActivityDetails = false;
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        }
        this.navService.pushChildNavPageWithPageTracking(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent, PageName.ContactPageComponent, { from: PageName.ContactDetailsComponent });
      } else {
        this.notificationService.notify(this.translate.instant('OM_WAS_A_PROBLEM_CONTACT_ADMINISTRATOR'), 'Account Feed', 'top', ToastStyle.DANGER);
      }
    } else if (activity.type == ActivityType.PhoneCall) {
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
      // await this.activityDataService.updateActivityDetails(foundActivity ? foundActivity : activity);
      await this.uiService.displayLoader();
      if(!this.device.isOffline) {
        await this.activityDataService.getPhonecallActivityByIdOnline(activity.ID, activity.scheduledStart.getTime().toString(), activity.scheduledEnd.getTime().toString(), activity.scheduledStart.getTime().toString()).then(async (response) => {
          activity = new PhoneActivity(response);
          await this.activityDataService.updateActivityForConfigField(activity, response, ActivityType.PhoneCall);
          await this.activityDataService._appendPhoneCallDetailsToActivity(activity, response, false)
          this.activityDataService.activityDetailsLoaded = true;
        })
      }else {
        const activityDoc = await this.activityDataService.getOfflineActivityDocByKey(DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY+activity.ID);
        if(activityDoc){
          activity = new PhoneActivity(activityDoc);
          await this.activityDataService.updateActivityForConfigField(activity, activityDoc, ActivityType.PhoneCall);
          await this.activityDataService._appendPhoneCallDetailsToActivity(activity, activityDoc);
          this.activityDataService.activityDetailsLoaded = true;
        }
      }
      //In case of date value as string type, need to set date type. it happened in offline mode
      if(!_.isEmpty(activity.scheduledStart)) activity.scheduledStart = new Date(activity.scheduledStart);
      if(!_.isEmpty(activity.scheduledEnd)) activity.scheduledEnd = new Date(activity.scheduledEnd);

      if(!isValid(activity.scheduledStart) || !isValid(activity.scheduledEnd)){
      // OMNI-27162: Currently contact timeline activities fetch service is returning activities created on dynamics for customer enquiry
      // Those activities doesn't have any scheduled start and end time attribute value so added this check to prevent the UI to break
      // In future it will be handled on service end to omit those activities from response
        await this.uiService.dismissLoader();
        return;
      }
      await this.uiService.dismissLoader();
      this.activityService.selected = activity;
      this.uiService.activeView = 'phoneCallTimelineDetails';
      this.uiService.prevView = 'contactDetails';
      if (this.activityService.selectedActivity) {
        if(this.navService.getCurrentMasterPageName() === PageName.ScientificActivityPage){
          this.uiService.showCancelDoneOnActivityDetails = false;
        }
        if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan ==='scientificPlans') {
          this.uiService.showCancelDoneOnActivityDetails = false;
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        }
        this.navService.pushChildNavPageWithPageTracking(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.ContactPageComponent, { from: PageName.ContactDetailsComponent });
        this.footerService.initButtons(FooterViews.PHONE_CALL);
      }
    } else if (activity.type == ActivityType.SurgeryOrder) {
      let foundActivity = this.activityService.getActivityByID(activity.ID);
      if(foundActivity){
        this.activityService.selectedActivity = foundActivity;
      }else{
        this.uiService.displayLoader();
        foundActivity = await this.activityDataService.getRealTimeActivityDetails(activity);
        this.uiService.dismissLoader();
      }
      this.activityService.selectedActivity = (foundActivity) ? foundActivity : activity;
      this.uiService.activeView = 'ContactPageRightPaneNav';
      this.uiService.showNewActivity = false;
      this.navService.pushChildNavPageWithPageTracking(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent, PageName.ContactPageComponent, { from: 'ContactTimeline', activity: this.activityService.selectedActivity });
      this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
    } else if (activity.type == ActivityType.Event || activity instanceof EventRegistration || activity instanceof EventActivity) {
      if (activity instanceof EventRegistration) {
        activity.ID = activity.eventId;
      }
      const eventsToolData = this.eventsToolService.eventsToolData;
      const foundEvent = eventsToolData.find(e=> {
        if(e.ID) {
          return e.ID === activity.ID;
        }else if (e.offlineId) {
          return e.offlineId === activity.offlineId;
        }else {
          return false;
        }
      });
      if(!_.isEmpty(foundEvent)) {
        activity = foundEvent;
        this.eventsToolService.selectedEventOnEventsTool = foundEvent;
      }else {
        return;
      }
      if(!this.device.isOffline) {
        this.uiService.displayLoader();
        await this.eventsToolDataService.fetchEventRealTimeDetails(activity as EventActivity).then((updatedEvent: EventActivity) => {
          activity = updatedEvent;
          this.uiService.dismissLoader();
        }).catch(err => {
          console.log(err);
          this.uiService.dismissLoader();
        });
      }
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
      this.activityService.selectedActivity = activity;
      this.navService.pushChildNavPageWithPageTracking(EventDetailsComponent, PageName.EventDetailsPageComponent, PageName.ContactPageComponent, {
        from: 'ContactTimeline', event: this.activityService.selectedActivity,
      });
      this.eventsToolService.setSelectedEventUpdates(this.activityService.selectedActivity as EventActivity);
      this.footerService.initButtons(FooterViews.EVENTS_DETAILS);
    } else if (activity.type == ActivityType.CustomerSurvey) {
      if(this.device.isOffline) {
        this.notificationService.notify(this.translate.instant("SURVEY_ACTIVITIES_NOTIFY_ONLINE"), "survey-activities", "top", ToastStyle.DANGER, 3000, true);
        return;
      }
      if(!_.isEmpty(activity)) {
        this.openSubmittedSurveyDetails(activity);
      }
    } else if (activity.type == ActivityType.Appointment) {
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
      this.activityService.selected = (foundActivity) ? foundActivity : activity;
      this.uiService.showNewActivity = false;
      this.activityService.prevSelectedActivityOnTimeline = _.cloneDeep(this.activityService.selectedActivity);
      this.uiService.prevView = 'contactDetails';
      this.uiService.showCancelDoneOnActivityDetails = false;
      await this.activityDataService.updateActivityDetails(foundActivity ? foundActivity : activity);
      this.uiService.activeView = 'ContactPageRightPaneNav';
      if(!_.isEmpty(this.contactService.contactInformation)) {
        this.contactService.selectedContactOnTimeline = this.contactService.contactInformation;
      }
      //meeting owner position check to determin read-only
      if(activity.meetingOwnerId == this.authService.user.systemUserID) {
        this.activityService.selectedActivity.isDiffPosition = false;
      }else {
        if(activity.state != MeetingActivityState.Completed) {
          let allPositionByOwner: any[] = [];
          if(!this.device.isOffline) {
            allPositionByOwner = await this.contactService.getAllPositionByOwnerId(activity.meetingOwnerId);
          }else {
            allPositionByOwner = activity.meetingOwnerPositionIds;
          }
          const userPosition = this.authService.user.positions;
          if(userPosition && userPosition.length > 0 && allPositionByOwner && allPositionByOwner.length > 0) {
            this.activityService.selectedActivity.isDiffPosition = true;
            userPosition.forEach(p=> {
              let foundPosition = allPositionByOwner.find(o=>o.positionid == p.ID);
              if(foundPosition) this.activityService.selectedActivity.isDiffPosition = false;
            });
          }
          if(this.activityService.selectedActivity.isDiffPosition) {
            console.log("The current meeting owner's position is different");
          }else {
            console.log("The current meeting owner is in the same position");
          }
        }
      }
      this.eventService.publish('activities:appConfigFields');
      if (this.activityService.selectedActivity) {
        if(this.navService.getCurrentMasterPageName() === PageName.ScientificActivityPage){
          await this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ContactPageComponent, { from: 'ScientificActivityPage', childFrom: PageName.ContactDetailsComponent });
        }
        else if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan ==='scientificPlans') {
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          await this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ContactPageComponent, { from: 'ScientificActivityPage', childFrom: PageName.ContactDetailsComponent });
        } else {
          await this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ContactPageComponent, { from: PageName.ContactTimelineComponent });
        }
        this.eventService.publish('detectChangesOnActivityDetails', true); // To triger angular change detection manually
        this.activityService.selectedActivity.accessedFrom = PageName.ContactTimelineComponent;
        this.footerService.initButtons(FooterViews.Activities);
      }
    }
  }

  public async openSubmittedSurveyDetails(survey: any) {
    if(this.device.isOffline) {
      this.notificationService.notify(this.translate.instant("SURVEY_ACTIVITIES_NOTIFY_ONLINE"), "contact-timeline", "top", ToastStyle.DANGER, 3000, true);
      return;
    }
    //check selected survey tempalte id
    const filteredSurveyList = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY);
    const templateId: string = survey['templateId'];
    const foundTemplate: AssessmentTemplate = filteredSurveyList.find(survey=> survey.indskr_assessmenttemplateid == templateId);
    this.customerSurveyService.setCurrentSurvey(foundTemplate);
    try {
      await this.navService.pushChildNavPageWithPageTracking(SurveyDetailsComponent, PageName.SurveyDetailsComponent, PageName.ContactDetailsComponent, { appointmentId: '', from: PageName.ContactDetailsComponent }).then(async()=>{
        this.navService.setChildNavRightPaneView(true);
        this.uiService.dismissLoader();
        this.customerSurveyService.selectedContactForSurvey = this.contactService.contactInformation;
        try {
          //map survey based on survey responses
          this.uiService.displayLoader;
          const responseData = await this.customerAssessService.fetchContactSurveyForDetails(survey, 'contact');
          if(!_.isEmpty(responseData)) {
            const formattedSurvey: CustomerAssessment = await this.customerAssessService.mapCustomerSurveyRespForTimeline(responseData);
            if (!_.isEmpty(formattedSurvey)) {
              this.customerSurveyService.setSurveyPreviewMode(true, formattedSurvey, 'timeline');
            }
          }
          this.uiService.dismissLoader();
        } catch (error) {
          console.log("Error open survey details from surve-timeline ", error);
          this.uiService.dismissLoader();
        }
      });
    } catch(error) {
      console.log("failed to open survey ", error);
      this.uiService.dismissLoader();
    }
  }

}
