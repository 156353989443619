import { CallPlanOfflineService } from '@omni/services/call-plan/call-plan.offline.service';
import {
  Component,
} from '@angular/core';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { ComponentViewMode, UIService } from '@omni/services/ui/ui.service';
import { DeviceService } from '@omni/services/device/device.service';
import { TrackService } from '@omni/services/logging/tracking.service';
import { NavigationService, PageName } from '@omni/services/navigation/navigation.service';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { RepServices } from '../../../data-services/rep/rep.services';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { CustomerEventsDataService } from '../../../data-services/customer-event/customer-events.data.service';
import { EventName, EventsService } from '@omni/services/events/events.service';
import { AffiliationExplorerComponent } from '@omni/components/shared/affiliation-explorer/affiliation-explorer';
import { CustomerAssessment } from '@omni/classes/customer-assessment/customer-assessment.class';
import _ from 'lodash';
import { GlanceCardViewDataModel } from '@omni/models/glanceCardViewDataModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { TranslateService } from '@ngx-translate/core';
import { IndSectionHeaderViewDataModel } from '@omni/models/indSectionHeaderDataModel';
import { TimeLineItem } from '@omni/components/timeline-item/timeline-item.component';
import { Customer360UIService } from '@omni/services/ui/customer360ui.service';
import { ContactDataService } from '@omni/data-services/contact/contact.data.service';
import { FeatureActionsMap } from '@omni/classes/authentication/user.class';
import { AuthenticationService } from '@omni/services/authentication.service';
import { CustomerAssessService } from '@omni/services/customer-assess/customer-assess.service';
import { Activity, ActivityType, MeetingActivityState } from '@omni/classes/activity/activity.class';
import { ConsentService } from '@omni/services/consent/consent.service';
import { AssessmentTemplate, SurveyCategory, SurveyFrequency, SurveyStatus, TemplateType } from '@omni/classes/customer-assessment/assessment-template.class';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { SampleService, SamplingDetailsViewMode } from '@omni/services/sample/sample.service';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { ModalController, PopoverController } from '@ionic/angular';
import { IndFilterMenuModalComponent, IndFilterMenuModalDataModel } from '@omni/components/shared/ind-filter-menu-modal/ind-filter-menu-modal';
import { ActivityService } from '@omni/services/activity/activity.service';
import { ActivityDataService } from '@omni/data-services/activity/activity.service';
import { ActivitiesDetailsPaneComponent } from '@omni/components/activity/activities-details-pane/activities-details-pane';
import { NewSampleActivityComponent } from '@omni/components/activity/new-sample-activity/new-sample-activity';
import { EmailViewType } from '@omni/classes/activity/email.activity.class';
import { EmailService } from '@omni/services/email-templates/email.service';
import { isValid } from 'date-fns';
import { EmailActivityDetailComponent } from '@omni/components/activity/email-activity-detail/email-activity-detail';
import { CaseManagementDataService } from '@omni/data-services/case-management/case-management.data.service';
import { CaseActivity } from '@omni/classes/case-intake/case-activity.class';
import { AccesingMode, CaseManagementService } from '@omni/services/case-management/case-management.service';
import { CaseManagementDetailComponent } from '@omni/components/case-management/case-management-detail/case-management-detail';
import { PhoneActivity } from '@omni/classes/activity/phone.activity.class';
import { DB_KEY_PREFIXES } from '@omni/config/pouch-db.config';
import { PhoneCallDetailsComponent } from '@omni/components/phonecall/phone-call-details/phone-call-details';
import { SurgeryOrderDetailsComponent } from '@omni/components/surgery-order/surgery-order-details/surgery-order-details';
import { EventRegistration } from '@omni/classes/customer-event/customer-event.class';
import { EventActivity } from '@omni/classes/events-tool/event.class';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { EventsToolDataService } from '@omni/data-services/event/events-tool.data.service';
import { EventDetailsComponent } from '@omni/components/events-tool/event-details/event-details';
import { SurveyDetailsComponent } from '@omni/components/survey/survey-details/survey-details';
import { CustomerSurveyService } from '@omni/services/customer-survey/customer-survey.service';
import { DynamicFormsService } from '@omni/services/dynamic-forms/dynamic-forms-service';
import { SearchConfigService } from '@omni/services/search/search-config.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ConsentSource } from '@omni/classes/consent/consent-term.class';
import { SecInfoConfigDataService } from '@omni/data-services/sec-info-config/sec-info-config-data-service';
import { SecondaryInfoEntityName } from '@omni/classes/sec-info-config/sec-info.class';

/**
 * Generated class for the ContactTimelinePageComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'contact-timeline-page',
  templateUrl: 'contact-timeline-page.html',
  styleUrls: ['contact-timeline-page.scss']
})
export class ContactTimelinePageComponent {

  public pageTitleViewData: IndPageTitleViewDataModel;
  public contactGlanceViewData: GlanceCardViewDataModel;
  public contactDetailsSegment: string;
  public searchPlaceholder: string;
  public searchInput:string = '';
  public timelineSectionHeader: IndSectionHeaderViewDataModel;
  public _allTimelineActivities: Array<any> = [];
  private _filteredTimelineActivities: Array<any> = [];
  private _filteredTimelineViewActivities: Array<TimeLineItem> = [];
  public viewTimelineItems: Array<TimeLineItem> = [];
  private currentStartIndex: number = 30;
  public filterOptions: Array<{ value: string, isSelected: boolean, displayText: string }> = [];
  public contactTimelineFilter: string;

  private _formattedSurveysList: any = [];
  public contactTimelineFilterFormView: IndFilterMenuModalDataModel[] = [];
  public selectedFilters: any = [];
  public filterButtonBadgeCount: number = 0;
  private selectedDate: { startDate: string, endDate: string } = { startDate: '', endDate: '' };
  public isTimelineFiltered: boolean = false;
  public userName: string;
  public isJourney: boolean;
  private ngDestroy$ = new Subject<boolean>();

  private isCustomerJourneyTimelineEnabled: boolean;
  private isCustomerEventsEnabled: boolean;
  private isEventToolfeatureAction: boolean;
  private hasCustomerSurveyEnabled: boolean;

  constructor(
    public contactService: ContactOfflineService,
    public device: DeviceService,
    public repService: RepServices,
    public uiService: UIService,
    public footerService: FooterService,
    public trackingService: TrackService,
    private navService: NavigationService,
    public notificationService: NotificationService,
    public callPlanService: CallPlanOfflineService,
    public customerEventDataService: CustomerEventsDataService,
    public events: EventsService,
    private readonly translate: TranslateService,
    private readonly customer360UIService: Customer360UIService,
    private readonly contactDataService: ContactDataService,
    private readonly authenticationService: AuthenticationService,
    private readonly customerAssessService: CustomerAssessService,
    private readonly consentService: ConsentService,
    private readonly utilityService: GlobalUtilityService,
    private readonly sampleService: SampleService,
    private readonly popoverCtrl: PopoverController,
    private readonly modalCtrl: ModalController,
    private readonly activityService: ActivityService,
    private readonly activityDataService: ActivityDataService,
    private readonly emailService: EmailService,
    private readonly caseDataService: CaseManagementDataService,
    private readonly caseService: CaseManagementService,
    private readonly eventsToolService: EventsToolService,
    private readonly eventsToolDataService: EventsToolDataService,
    private readonly customerSurveyService: CustomerSurveyService,
    private readonly dynamicFormService: DynamicFormsService,
    private readonly searchConfigService: SearchConfigService,
    private secondaryInfoService: SecInfoConfigDataService,
  ) {
  }

  ngOnInit() {
    if(this.uiService.isConsentFromToolDrawer) this.sampleService.contactTimelineFilter = ActivityType.ConsentActivity;
    this.contactTimelineFilter = this.sampleService.contactTimelineFilter;
    this.uiService.timelineRefreshRequired = true;
    this._initFeatureActions();
    this._initPageTitle();
    this._initSectionHeadersView();
    this._initGlanceCardViewData();
    this.initList();

    this.navService.childNavPopObserver.pipe(takeUntil(this.ngDestroy$)).subscribe(page=> {
      if(page && this.navService.getActiveChildNavViewPageName() == PageName.ContactTimelinePageComponent && (page == PageName.ActivitiesDetailsPaneComponent || page == PageName.NewSampleActivityComponent || page == PageName.EmailDetailsPageComponent || page == PageName.CaseManagementDetailComponent || page == PageName.SurgeryOrderDetailsComponent || page == PageName.PhoneCallDetailsComponent || page == PageName.SurveyDetailsComponent || page == PageName.EventDetailsPageComponent)){
        this._initTimelineItemsViewData();
      }
    })
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }

  // private get activities(): Array<any> {
  //   return this._filteredTimelineActivities.sort((a,b)=> {
  //     if (a.scheduledStart > b.scheduledStart) return -1;
  //     if (a.scheduledStart < b.scheduledStart) return 1;
  //     return 0;
  //   });
  // }

  private _initFeatureActions() {
    this.isCustomerJourneyTimelineEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_JOURNEY_TIMELINE);
    this.isCustomerEventsEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_REGISTRATION) || this.authenticationService.user.eventTimeLine;
    this.isEventToolfeatureAction = this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL) || this.authenticationService.user.eventTimeLine
    this.hasCustomerSurveyEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SURVEY);
  }

  private _initPageTitle() {
    this.pageTitleViewData = {
      id: "contact-timeline-details",
      title: this.contactService?.contactInformation?.fullName,
      controls: [{
        id: "close",
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      }]
    };
    this.searchPlaceholder = this.translate.instant('SEARCH_TIMELINE');
  }

  private _initSectionHeadersView() {
    this.timelineSectionHeader = {
      id: 'contact-timeline-page-section',
      title: this.translate.instant('TIMELINE'),
      controls: [{
        id: 'contact-timeline-page-filter',
        text: this.getSelectedText(this.contactTimelineFilter),
        isDisabled: this._allTimelineActivities.length == 0 || false,
        bubbleUpEvent: true,
        icon: this.filterButtonBadgeCount && this.filterButtonBadgeCount > 0 ? '' : 'assets/imgs/customer_360_activity_type_filter.svg'
      }]
    }
  }

  private async _initGlanceCardViewData() {
    let glanceCardButtons = [];
    let contact = this.contactService.getContactByID(this.contactService.contactInformation['ID']);
      if (contact) {
        if(this.authenticationService.hasFeatureAction(FeatureActionsMap.PHONECALL_ACTIVITY)){
          glanceCardButtons.push({
            id: "phone-call",
            name: this.translate.instant('NEW_ACTIVITY_PHONECALL'),
            isDisabled: !contact.mobilePhone,
            imgSrc: contact.mobilePhone ? 'assets/imgs/omni_quick_glance_call.svg' : 'assets/imgs/omni_quick_glance_call_disabled.svg',
            align: "right"
          });
        }
        if(this.authenticationService.hasFeatureAction(FeatureActionsMap.MESSAGE_ACTIVITY)){
          glanceCardButtons.push({
            id: "quick-message",
            imgSrc: 'assets/imgs/omni_quick_glance_email.svg',
            name: this.translate.instant('MESSAGE'),
            isDisabled: false,
            align: "right"
          });
        }
        glanceCardButtons.push({
          id: "quick-meeting",
          imgSrc: 'assets/imgs/omni_quick_glance_meeting.svg',
          name: this.translate.instant('MEETING'),
          isDisabled: false,
          align: "right"
        });
      }
    if (this.contactService.isAffiliationEnabled) {
      glanceCardButtons.push({
        id: "affiliation-explorer",
        imgSrc: 'assets/imgs/glance-affiliation-explorer.svg',
        name: this.translate.instant('AFFILIATION_EXPLORER'),
        isDisabled: !((this.contactService.linkEntityContactTo && this.contactService.linkEntityContactTo.length) || (this.contactService.linkEntityContactFrom && this.contactService.linkEntityContactFrom.length)
          || (this.contactService.affiliationAccount && this.contactService.affiliationAccount.length)),
        align: "right"
      });
    }
    const values = await this.contactService._getQuickGlanceValues();

    if(!this.device.isOffline){
      this.contactService.getEntityImageStringForContact(this.contactService.contactInformation).then((entityImageString => {
        if(entityImageString){ 
          this.contactService.contactInformation.entityImage = entityImageString;
          if(this.contactGlanceViewData){
            this.contactGlanceViewData.avatarURL = this.contactService.contactInformation.entityImage
          }
        }
      }));
    }
    this.contactGlanceViewData = {
      name: this.contactService.contactInformation.fullName,
      locationText: this.contactService.contactInformation.getPrimaryAddress,
      avatarURL: this.contactService.contactInformation.entityImage,
      thirdText: (values && values.length && values[0]) ? values[0].value : '',
      fourthText: (values && values.length && values[1]) ? values[1].value : '',
      fifthText: (values && values.length && values[2]) ? values[2].value : '',
      sixthText: (values && values.length && values[3]) ? values[3].value : '',
      thirdTextLabel: (values && values.length && values[0]) ? values[0].label : '',
      fourthTextLabel: (values && values.length && values[1]) ? values[1].label : '',
      fifthTextLabel: (values && values.length && values[2]) ? values[2].label : '',
      sixthTextLabel: (values && values.length && values[3]) ? values[3].label : '',
      buttons: glanceCardButtons,
      enableExpand: true,
      isExpanded: false,
    };
  }

  public async onQuickGlanceControlClick(data) {
    if (data.id) {
      switch (data.id) {
        case 'affiliation-explorer':
          this._handleAffiliationExplorer();
          break;
        case 'phone-call':
          this.events.publish(EventName.QUICKGLANCECCONTACTREATEPHONECALL,true);
          break;
        case 'quick-message':
          this.events.publish(EventName.QUICKGLANCECCONTACTREATEMESSAGE,true);
          break;
        case 'quick-meeting': 
          this.events.publish(EventName.QUICKGLANCECCONTACTREATEMEETING,true);
          break;
        default:
          console.log("Unhandled switch case statement");
          break;
      }
    }
  }

  public onSectionHeaderControlClick(data: any) {
    if (data && data.button && data.button.id === 'contact-timeline-page-filter') {
      if(this.filterButtonBadgeCount && this.filterButtonBadgeCount > 0) return;
      this._handleActivityTypeFilter(data.event);
    }
  }

  private async _handleAffiliationExplorer() {
    let params = {
      from: 'ContactPageComponent',
      parent: this.contactService.contactInformation,
      relatedTo: await this.contactService.getContactRelatedToByContactId(this.contactService.contactInformation.ID),
      contactAccountAffiliation: await this.contactService.getContactToAccountByContactId(this.contactService.contactInformation.ID),
      relatedFrom: await this.contactService.getContactRelatedFromByContactId(this.contactService.contactInformation.ID),
    };
    this.navService.pushChildNavPageWithPageTracking(AffiliationExplorerComponent, PageName.AffiliationExplorerComponent, PageName.ContactPageComponent, params);
  }

  public async onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this._closePage();
    }
  }

  private _closePage() {
    this.navService.popChildNavPageWithPageTracking();
  }

  private async _initTimelineItemsViewData() {
    if (this.contactService.contactInformation.ID) {
      this._filteredTimelineActivities = this._filteredTimelineActivities.filter(a=> isValid(a.scheduledStart)).sort((a,b)=> {
        if (a.scheduledStart > b.scheduledStart) return -1;
        if (a.scheduledStart < b.scheduledStart) return 1;
        return 0;
      });
      this._filteredTimelineViewActivities = await this._getTimelineItemViewForActivities(this._filteredTimelineActivities, this.contactService.contactInformation.ID);
      // Search Logic
      if(this.searchInput && this.searchInput.length >= 2){
        this._filteredTimelineViewActivities = this._filteredTimelineViewActivities.filter(item => {
          let flag:boolean = false;
          if(item.headerText){
            flag = (item.headerText.toLocaleLowerCase().toLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1);
          }
          if(!flag && item.subheaderText){
            flag = (item.subheaderText.toLocaleLowerCase().toLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1);
          }
          if(!flag && item.subheaderText2){
            flag = (item.subheaderText2.toLocaleLowerCase().toLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1);
          }
          if(!flag && item.dataToDisplay && item.dataToDisplay.length > 0){
            for (let i=0;i<item.dataToDisplay.length;i++){
              const dtdItem = item.dataToDisplay[i];
              if(dtdItem && dtdItem.value){
                flag = (dtdItem.value.toLocaleLowerCase().toLowerCase().indexOf(this.searchInput.toLocaleLowerCase()) > -1);
              }
              if(flag){
                break;
              }
            }
            
          }
          return flag;
        })
      }
      this.viewTimelineItems = this._filteredTimelineViewActivities.slice(0, this.currentStartIndex);
      this._initSectionHeadersView();
    }
  }

  private async _getTimelineItemViewForActivities(activities: Array<any>, id?): Promise<TimeLineItem[]> {
    let itemsView: Array<TimeLineItem> = [];
    if (activities && activities.length > 0) {
      activities.forEach(item => {
        let activity;
        // if(item.type == ActivityType.Appointment || item.type == ActivityType.PhoneCall || item.type == ActivityType.Sample || item.type == ActivityType.CaseIntake || item.type == ActivityType.Email || item.type == ActivityType.SurgeryOrder){
        //   activity = this.activityService.getActivityByID(item.ID)
        // }else 
        if (item.type == ActivityType.Event){
          activity = this.eventsToolService.getEventDetailsbyId(item.eventId);
          if(activity){
            activity.type = ActivityType.Event;
            if(!isValid(activity.scheduledStart)){
              activity.scheduledStart = item.scheduledStart;
            }
          }
        }
        let view = this.customer360UIService.getViewForJourneyItem(false, activity ? activity : item, id);
        if (view) {
          if(item.type == ActivityType.Event && ! activity){
            view.disableEndArrow = true;
          }
          itemsView.push(view);
        }
      })
    }
    return itemsView;
  }

  public doInfinite(event) {
    this.viewTimelineItems.push(...this.sliceActivities(this.currentStartIndex + 1, this.currentStartIndex + 30));
    this.currentStartIndex += 30;
    event.target.complete();
  }

  private sliceActivities(startIndex: number, count: number) {
    return this._filteredTimelineViewActivities.length < count ? this._filteredTimelineViewActivities.slice(startIndex) : this._filteredTimelineViewActivities.slice(startIndex, count);
  }

  private async _handleActivityTypeFilter(ev) {
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'contact-timeline-activity-type-filter-select',
      data: this.filterOptions.map(a => {
        return {
          title: a.displayText,
          id: a.value,
          isSelected: a.value == this.contactTimelineFilter,
        }
      }),
    };
    let dropdownPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: ev });
    dropdownPopover.onDidDismiss().then(async (data: any) => {
      data = data.data;
      if (data && data.selectedItems && data.selectedItems.length == 1 && (!this.contactTimelineFilter || (this.contactTimelineFilter && data.selectedItems[0].title != this.contactTimelineFilter))) {
        this.contactTimelineFilter = this.sampleService.contactTimelineFilter = data.selectedItems[0].id;
        this._applyAdvancedFilterOnTimelineActivities();
      }
      dropdownPopover = undefined;
    });
    dropdownPopover.present();
  }

  private _initFilterOptions(): void {
    this.filterOptions = [];
    if (this.contactService.contactInformation 
      && (!_.isEmpty(this.contactService.contactInformation.activitesTimeline) 
      || !_.isEmpty(this.contactService.contactInformation.eventsAndRegistrationsByContact) 
      || !_.isEmpty(this.contactService.contactInformation.eventsCheckInByContact) 
      || !_.isEmpty(this.contactService.contactInformation.eventsCompletedByContact)
      || !_.isEmpty(this.contactService.contactInformation.customerJourneysTimeline)
    )) {
      this.filterOptions.push({ value: 'All_Activities', isSelected: true, displayText: this.translate.instant('ALL_ACTIVITIES') });
      let myActivitesDisplayText = this.translate.instant('MY_ACTIVITIES');
      let meetingsDisplayText = this.translate.instant('MEETINGS');
      if (this.translate.currentLang == 'it') {
        myActivitesDisplayText = this.translate.instant('MY_ACTIVITIES_TIMELINE');
        meetingsDisplayText = this.translate.instant('MEETINGS_TIMELINE');
      }
      this.filterOptions.push({ value: 'MY_ACTIVITIES', isSelected: false, displayText: myActivitesDisplayText });
      if (this.contactService.contactInformation.activitesTimeline.some(activity => (activity.type == ActivityType.Appointment && activity.location != 'LiveMeet'))) {
        this.filterOptions.push({ value: 'Appointment', isSelected: false, displayText: meetingsDisplayText })
      }
      if (this.contactService.contactInformation.activitesTimeline.some(activity => activity.type == ActivityType.Email)) {
        this.filterOptions.push({ value: 'Email', isSelected: false, displayText: this.translate.instant('MESSAGES') })
      }
      if (this.contactService.contactInformation.activitesTimeline.some(activity => activity.type == ActivityType.Sample)) {
        this.filterOptions.push({ value: 'Allocation_Order', isSelected: false, displayText: this.translate.instant('ALLOWCATION_ORDERS') })
      }
      if (this.contactService.contactInformation.activitesTimeline.some(activity => activity.type == ActivityType.CaseIntake)) {
        this.filterOptions.push({ value: 'Customer_Inquiry', isSelected: false, displayText: this.translate.instant('CUSTOMER_INQUIRIES', { globalCustomerText: this.utilityService.globalCustomerText }) })
      }
      if (!_.isEmpty(this.contactService.contactInformation.eventsAndRegistrationsByContact) || !_.isEmpty(this.contactService.contactInformation.eventsCheckInByContact) || !_.isEmpty(this.contactService.contactInformation.eventsCompletedByContact)) {
        this.filterOptions.push({ value: 'Contact_Event', isSelected: false, displayText: this.translate.instant('EVENTS') })
      }
      if (this.contactService.contactInformation.activitesTimeline.some(activity => (activity.type == 'Appointment' && activity.location === 'LiveMeet'))) {
        this.filterOptions.push({ value: 'Live_Meet', isSelected: false, displayText: this.translate.instant('LIVE_MEETING') })
      }
      if (this.contactService.contactInformation.activitesTimeline.some(activity =>
        (activity.type == ActivityType.PhoneCall))) {
        this.filterOptions.push({ value: ActivityType.PhoneCall, isSelected: false, displayText: this.translate.instant('PHONE_CALLS') })
      }
      if (this.contactService.contactInformation.activitesTimeline.some(activity =>
        (activity.type == ActivityType.SurgeryOrder))) {
        this.filterOptions.push({ value: ActivityType.SurgeryOrder, isSelected: false, displayText: this.translate.instant('PROCEDURE_LOGS') })
      }
      if (this.consentService.allConsentActivitiesWithProductsAndEmails.some(con => con.contactId == this.contactService.contactInformation.ID)) {
        this.filterOptions.push({ value: ActivityType.ConsentActivity, isSelected: false, displayText: this.translate.instant('CONSENTS') })
      }
      if (this.authenticationService.user.customNotifications) {
        if (!_.isEmpty(this.contactService.contactInformation.websiteAccessLogs)) {
          this.filterOptions.push({ value: 'Website_Access_Logs', isSelected: false, displayText: this.translate.instant('WEBSITE_ACCESS_LOGS') })
        }
      }
      if (this.hasCustomerSurveyEnabled && !_.isEmpty(this._formattedSurveysList)) {
        this.filterOptions.push({ value: ActivityType.CustomerSurvey, isSelected: false, displayText: this.translate.instant('SURVEY') })
      }
      /**************************Customer Journey**************************/
      if (this.contactService.contactInformation.customerJourneysTimeline.some(activity =>
        (activity.type == ActivityType.CustomerJourneyAppointment))) {
        this.filterOptions.push({ value: ActivityType.CustomerJourneyAppointment, isSelected: false, displayText: `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('MEETINGS')}` })
      }
      if (this.contactService.contactInformation.customerJourneysTimeline.some(activity =>
        (activity.type == ActivityType.CustomerJourneyPhoneCall))) {
        this.filterOptions.push({ value: ActivityType.CustomerJourneyPhoneCall, isSelected: false, displayText: `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('PHONE_CALLS')}` })
      }
      if (this.contactService.contactInformation.customerJourneysTimeline.some(activity =>
        (activity.type == ActivityType.CustomerJourneyEmail))) {
        this.filterOptions.push({ value: ActivityType.CustomerJourneyEmail, isSelected: false, displayText: `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('EMAILS')}` })
      }
      /**************************Customer Journey**************************/
    }

    // if (this.sampleService.contactTimelineFilter && !this.filterOptions.some(filter => filter.value == this.sampleService.contactTimelineFilter)) {
    //   this.contactTimelineFilter = this.sampleService.contactTimelineFilter = 'All_Activities';
    //   this.onTimelineTypeChange(this.sampleService.contactTimelineFilter);
    // }
  }

  public onFilterClick(ev) {
    if(this._allTimelineActivities.length == 0) return;
    this.openTimelineFilter(ev);
  }

  public openDetails(ev, data) {
    if (data && data.refObject) {
      if ((this.contactService.accessedContactListFrom === PageName.AccountDetailsComponent && this.contactService.contactPageMode === ComponentViewMode.READONLY)
        || this.contactService.contactPageMode === ComponentViewMode.PREVIEW
        || this.dynamicFormService.isOpenedAffiliatedContactOnAccount
        || this.dynamicFormService.isOpenedAffiliatedContactOnContact
        || this.emailService.viewType === EmailViewType.CREATE_FROM_MEETING
        || this.caseService.accessedFrom == AccesingMode.INMEETING
        || (this.sampleService.inMeetingAllocationActivity)
        || data.refObject.type == ActivityType.ConsentActivity) {
        console.log("The current activity cannot be opened to avoid possible problems with navigation loop");
        return;
      }
      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'ActivitySkeleton';
      this.displayActivityDetails(data.refObject);
    }
  }

  public handleSearch(){
    this._initTimelineItemsViewData();
  }

  // Logic From Existing Timeline Component To fetch different activities (Keeping it same)

  initList() {
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_REGISTRATION) || this.authenticationService.user.eventTimeLine) {
      this.isCustomerEventsEnabled = true;
    }
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL) || this.authenticationService.user.eventTimeLine) {
      this.isEventToolfeatureAction = true;
    }
    if (this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_JOURNEY_TIMELINE)) {
      this.isCustomerJourneyTimelineEnabled = true;
    }

    this._allTimelineActivities = [];
    this._filteredTimelineActivities = [];
    this._filteredTimelineViewActivities = [];
    if (!_.isEmpty(this.contactService.contactInformation)) {
      this.contactService.isContactDetailsLoaded = false;
      if (!this.footerService.isActivitiesButton && this.navService.getCurrentPageName() === PageName.ActivitiesPageComponent) {
        this.isJourney = true;
      }
      this._getTimelineActivities();
      if (this.uiService.isConsentFromToolDrawer) this.sampleService.contactTimelineFilter = ActivityType.ConsentActivity;
      this.onTimelineTypeChange(this.sampleService.contactTimelineFilter);
      this.contactService.isContactDetailsLoaded = true;
      this._initFilterOptions();  // filter on the sub section title
      this._initFilterFormViews(); // for the filter button
      this.authenticationService.getOfflineUser().then((user) => {
        if (user) {
          this.userName = user.displayName;
        }
        return;
      });
      //Filter pre-selection based on survey, filter menu modal is not implemented yet
      this.hasCustomerSurveyEnabled = this.authenticationService.hasFeatureAction(FeatureActionsMap.CUSTOMER_SURVEY);
      if (this.hasCustomerSurveyEnabled && this.contactService.isOpenedSurveyTimelineFromContactProfile && !_.isEmpty(this.filterOptions)) {
        this.contactService.isOpenedSurveyTimelineFromContactProfile = false;
        this.filterOptions.map(option => {
          option.isSelected = option.value == ActivityType.CustomerSurvey ? true : false;
        });
        this.contactTimelineFilter = ActivityType.CustomerSurvey;
        this.onTimelineTypeChange(ActivityType.CustomerSurvey);
      } else {
        //Filter pre-selection based on the selected actiities from Contact List Filter 
        let refDataFilterMenuList = this.searchConfigService.timelineFilterMenuList;
        if (!_.isEmpty(this.contactService.selectedActivitiesFromContactFilter) && !_.isEmpty(refDataFilterMenuList)) {
          this.isTimelineFiltered = true;
          let targetTimelineActivities = _.cloneDeep(this._allTimelineActivities);
          let activities = [];
          let filterBadgeCount: number = 0;
          let isSelectedMeetingStatus: boolean = false;

          this.contactService.selectedActivitiesFromContactFilter.forEach(mainFilterValue => {
            //Update the value of the selected Activity displayed in the timeline filter menu(RHS)
            let foundFilterActivityValue = refDataFilterMenuList.find(d => d.category == mainFilterValue);
            if (!_.isEmpty(foundFilterActivityValue) && !this.selectedFilters.some(d => d.category == mainFilterValue)) {
              this.selectedFilters.push(foundFilterActivityValue);
            }
            if (!_.isEmpty(this.contactTimelineFilterFormView)) {
              this.contactTimelineFilterFormView.map(d => {
                if (d.id == "filter-main" && !_.isEmpty(d.options)) {
                  let idx = d.options.findIndex(o => o.group == mainFilterValue);
                  if (idx > -1) {
                    d.options[idx].isSelected = true;
                    filterBadgeCount++;
                  }
                }
              });
            }
            //Update Filtered activities
            targetTimelineActivities.forEach(activity => {
              if (mainFilterValue == ActivityType.LiveMeet && activity.type == ActivityType.Appointment && activity.location == "LiveMeet") {
                activities.push(activity);
              } else if (mainFilterValue == ActivityType.Appointment && activity.type == ActivityType.Appointment && activity.location != "LiveMeet") {
                //Check Meeting activity option value
                if (!_.isEmpty(this.contactService.selectedSubOptionsFromContactFilter.meetingStatus)) {
                  if (this.contactService.selectedSubOptionsFromContactFilter.meetingStatus.includes(activity.status)) {
                    //Update the value of the selected Option displayed in the timeline filter menu(RHS)
                    let formattedOptionId = ActivityType.Appointment + '_' + activity.statusString;
                    let foundFilterOptionValue = refDataFilterMenuList.find(d => d.parentId == mainFilterValue && d.id == formattedOptionId);
                    if (!_.isEmpty(foundFilterOptionValue) && !this.selectedFilters.some(d => d.id == formattedOptionId)) {
                      this.selectedFilters.push(foundFilterOptionValue);
                    }
                    if (!_.isEmpty(this.contactTimelineFilterFormView)) {
                      this.contactTimelineFilterFormView.map(d => {
                        if (d.id == "appointment-status" && !_.isEmpty(d.options)) {
                          let idx = d.options.findIndex(o => o.id == formattedOptionId);
                          if (idx > -1) {
                            d.options[idx].isSelected = true;
                            isSelectedMeetingStatus = true;
                          }
                        }
                      });
                    }
                    activities.push(activity);
                  }
                } else {
                  activities.push(activity);
                }
              } else if (mainFilterValue == activity.type) {
                activities.push(activity);
              }
            });
          });
          //Update filter badge count from selected option
          if (isSelectedMeetingStatus) {
            let idx = this.contactTimelineFilterFormView.findIndex(d => d.id == "appointment-status");
            if (idx > -1) {
              this.contactTimelineFilterFormView[idx].options.forEach(o => {
                if (o.isSelected) filterBadgeCount++;
              });
            }
          }

          targetTimelineActivities = activities;
          this.filterButtonBadgeCount = filterBadgeCount;
          /******* grouped filtered activities *******/
          this._allTimelineActivities = targetTimelineActivities
          this._filteredTimelineActivities = this._allTimelineActivities;
          this._initTimelineItemsViewData();
          console.log("Applied filter pre-selection");
        }
      }
    } else {
      this._allTimelineActivities = [];
      this._filteredTimelineActivities = [];
      this._initTimelineItemsViewData();
    }
  }

  async ngDoCheck() {
    if (this.uiService.timelineRefreshRequired && !_.isEmpty(this.contactService.contactInformation)) {
      this.uiService.timelineRefreshRequired = false;
      const contactId = this.contactService.contactInformation.ID;
      try {
        this.uiService.displayLoader();
        if (!this.device.isOffline) {
          await this.contactService.removeContactActivitiesTimelineDB(contactId);
          await this.fetchContactTimeline().then(() => {
            this.initList();
            this.uiService.dismissLoader();
          });
        } else {
          await this.fetchContactTimeline().then(() => {
            this.initList();
            this.uiService.dismissLoader();
          });
        }
      } catch (error) {
        console.log("Error getting contact activities for timeline", error);
        this.uiService.dismissLoader();
      }
      this.uiService.displayLoader();
    }
  }

  async fetchContactTimeline() {
    await this.contactDataService.getContactActivitiesForTimeline(this.contactService.contactInformation).then(async () => {
      const contactId = this.contactService.contactInformation.ID || '';
      //fetching event registrations before combining with activities
      if (!this.device.isOffline) {
        /**************************Customer Journey**************************/
        if (this.contactService.contactInformation) {
          if (this.isCustomerJourneyTimelineEnabled) {
            await this.contactDataService.getCustomerJourneysForTimeline(this.contactService.contactInformation);
            await this.contactDataService.getCustomerJourneysRealtimeMarketingEmailForTimeline(this.contactService.contactInformation);
          } else {
            this.contactDataService.mapEmptyCustomerJourneysTimeline(this.contactService.contactInformation);
          }
        }
        /**************************Customer Journey**************************/
        await this.contactDataService.getContactWebsiteAccessLogsTimeline(this.contactService.contactInformation);
      }
      if (!this.device.isOffline) {
        if (this.isCustomerEventsEnabled && !_.isEmpty(this.contactService.contactInformation)) {
          await this.customerEventDataService.getContactEventTimeline(this.contactService.contactInformation).then(() => {
            if (this.contactService.contactInformation.eventsAndRegistrationsByContact) {
              this.contactService.isContactDetailsLoaded = true;
            }
          }).catch((err) => {
            console.log('error while fetching timeline events', err);
          });
        } else {
          this.contactService.isContactDetailsLoaded = true;
        }
        if (this.isEventToolfeatureAction && !_.isEmpty(this.contactService.contactInformation)) {
          try {
            await this.customerEventDataService.getContactCheckedInEventTimeline(this.contactService.contactInformation);

            if (this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL)) { // ? call only if FA is enabled
              await this.customerEventDataService.getContactCompletedEventTimeline(this.contactService.contactInformation)
            }
          } catch (err) {
            console.log('error while fetching timeline events', err);
          }

          // await Promise.all([
          //   this.customerEventDataService.getContactCheckedInEventTimeline(this.contactService.contactInformation),
          //   this.customerEventDataService.getContactCompletedEventTimeline(this.contactService.contactInformation) 
          // ]).catch((err) => {
          //   console.log('error while fetching timeline events', err);
          // });
        }
        /**************************Customer Survey**************************/
        if (this.hasCustomerSurveyEnabled && !_.isEmpty(this.contactService.contactInformation)) {
          await this.customerAssessService.getSurveysForTimeline(this.contactService.contactInformation.ID, SurveyCategory.CONTACT);
        }
        return;
      } else {
        if (this.isCustomerEventsEnabled && !_.isEmpty(this.contactService.contactInformation)) {
          this.contactService.contactInformation.eventsAndRegistrationsByContact = [];
          await this.customerEventDataService.getOfflineContactEventTimeline(this.contactService.contactInformation).then(() => {
            if (this.contactService.contactInformation.eventsAndRegistrationsByContact) {
              this.contactService.isContactDetailsLoaded = true;
            }
          }).catch((err) => {
            console.log('error while fetching timeline events', err);
          });
        } else {
          this.contactService.isContactDetailsLoaded = true;
        }
        if (this.isEventToolfeatureAction && !_.isEmpty(this.contactService.contactInformation)) {
          this.contactService.contactInformation.eventsCheckInByContact = [];
          this.contactService.contactInformation.eventsCompletedByContact = [];

          try {
            await this.customerEventDataService.getOfflineContactCheckedInEventTimeline(this.contactService.contactInformation);

            if (this.authenticationService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL)) { // ? call only if FA is enabled
              await this.customerEventDataService.getOfflineContactCompletedEventTimeline(this.contactService.contactInformation)
            }
          } catch (err) {
            console.log('error while fetching timeline events', err);
          }
        }
        /**************************Customer Survey**************************/
        if (this.hasCustomerSurveyEnabled && !_.isEmpty(this.contactService.contactInformation)) {
          this.customerAssessService.loadSurveysByContactIdForTimeline(this.contactService.contactInformation.ID);
        }
        if (this.isCustomerJourneyTimelineEnabled) {
          let offlineData = await this.contactDataService.loadOfflineRealTimeEmailsForTimeline(contactId);
          if (offlineData && offlineData.raw) {
            let realtimeEmailActivities = [];
            realtimeEmailActivities = await this.contactService.mapRealtimeEmailsToActivity(offlineData.raw);
            if (!_.isEmpty(realtimeEmailActivities)) {
              this.contactService.contactInformation.mapRealtimeEmailToTimeline(realtimeEmailActivities);
            }
          }
        }
        return;
      }
    }).catch((err) => {
      console.log("error : ", err);
    });
  }

  private _getTimelineActivities() {
    let items: any = [];
    items = this.combineEventsAndActivities(items);
    items = this.combineConsentsAndActivities(items);
    if (this.hasCustomerSurveyEnabled) {
      items = this.combineSurveysAndActivities(items);
    }
    /**************************Customer Journey**************************/
    if (this.isCustomerJourneyTimelineEnabled) {
      items = this.combineCustomerJourneyAndActivities(items);
    }
    /**************************Customer Journey**************************/
    this._allTimelineActivities = items;
    this.onTimelineTypeChange(this.contactTimelineFilter || ActivityType.AllActivity)
  }

  private combineEventsAndActivities(items: any) {
    if (this.contactService.contactInformation.activitesTimeline) {
      items = [...this.contactService.contactInformation.activitesTimeline];
    }
    if (this.contactService.contactInformation.websiteAccessLogs) {
      this.contactService.contactInformation.websiteAccessLogs.forEach(er => {
        er.type = ActivityType.WebsiteAccess;
        er['scheduledStart'] = er.accessDateTime;
        if (this.authenticationService.user.customNotifications) {
          items.push(er);
        }
      });
    }
    //Combining events with activities
    if (this.isCustomerEventsEnabled) {
      if (this.contactService.contactInformation.eventsAndRegistrationsByContact) {
        this.contactService.contactInformation.eventsAndRegistrationsByContact.forEach(er => {
          er.type = ActivityType.Event;
          items.push(er);
        });
      }
    } if (this.isEventToolfeatureAction) {
      if (this.contactService.contactInformation.eventsCheckInByContact) {
        this.contactService.contactInformation.eventsCheckInByContact.forEach(er => {
          er.type = ActivityType.Event;
          er['scheduledStart'] = er.checkinTime ? er['scheduledStart'] = er.checkinTime : er['scheduledStart'] = er.createdOn;
          items.push(er);
        });
      }
      if (this.contactService.contactInformation.eventsCompletedByContact) {
        this.contactService.contactInformation.eventsCompletedByContact.forEach(er => {
          er.type = ActivityType.Event;
          er['scheduledStart'] = er.startDate;
          items.push(er);
        });
      }
    }
    return items;
  }

  private combineConsentsAndActivities(items: any) {
    // let activeConsents = this.consentService.allActiveConsentSubject.value;
    // if(!_.isEmpty(activeConsents) && this.contactService.contactInformation) {
    //   activeConsents = activeConsents.filter(actCons => actCons.indskr_contactid.toString() === this.contactService.contactInformation.ID.toString());
    // }
    // if(!_.isEmpty(activeConsents)) {
    //   activeConsents[0]['activeConsents'].forEach((consent) => {
    //     consent.consentActivity?.forEach((conAct) => {
    //       let activity:any = _.cloneDeep(conAct);
    //       let activityType = conAct.indskr_consentactivitytype == 100000000 ? this.translate.instant('OPT_IN') : this.translate.instant('OPT_OUT');
    //       let secondaryText = [conAct.indskr_productname, conAct.indskr_emailaddress, conAct.indskr_phone, conAct.indskr_addressname].filter(Boolean).join(', ');
    //       activity['type'] = ActivityType.ConsentActivity;
    //       activity['subject'] = `${consent.indskr_consentTermName} - ${activityType}`;
    //       // activity['source'] = conAct.indskr_consentactivitysourcetype;
    //       activity['source'] = 'omnipresence';
    //       // activity['scheduledStart'] = conAct.createdon;
    //       activity['scheduledStart'] = 1667008303000;
    //       activity['secondaryText'] = secondaryText;
    //       items.push(activity);
    //     })
    //   });
    // }
    let consenctActivities = this.consentService.allConsentActivitiesWithProductsAndEmails;
    if (!_.isEmpty(consenctActivities) && this.contactService.contactInformation) {
      consenctActivities = consenctActivities.filter((activity) => activity.contactId == this.contactService.contactInformation.ID);
    }
    consenctActivities?.forEach((conAct) => {
      let activity: any = _.cloneDeep(conAct);
      let activityType = conAct.indskr_type == 100000000 ? this.translate.instant('OPT_IN') : this.translate.instant('OPT_OUT');
      let secondaryText = [conAct.indskr_productname, conAct.emailAddress, conAct.indskr_phone, conAct.indskr_addressname].filter(Boolean).join(', ');
      const consentSourceType = conAct.indskr_consentactivitysourcetype == 600000001 ? this.translate.instant('PORTAL') : conAct.indskr_consentactivitysourcetype == 600000002 ? this.translate.instant('EXTERNAL') : conAct.indskr_optinsource == ConsentSource.PAPER_CONSENT ? `Omnipresence ${this.translate.instant('PAPER_CONSENTS')}`: 'Omnipresence Go';
      const consentSourceTypeName = conAct.consentSourceName || '';
      const consentSourceText = consentSourceTypeName ? consentSourceType + ': ' + consentSourceTypeName : consentSourceType;
      activity['type'] = ActivityType.ConsentActivity;
      activity['subject'] = conAct.indskr_name;
      activity['source'] = consentSourceText;
      activity['scheduledStart'] = new Date(conAct.indskr_activitydate ?? conAct.createdon);
      activity['ownerId'] = conAct._createdby_value;
      activity['secondaryText'] = secondaryText;
      items.push(activity);
    })
    return items;
  }

  private combineSurveysAndActivities(items: any) {
    const contactId: string = this.contactService.contactInformation.ID;
    this._formattedSurveysList = [];
    let surveysByContactId: any = this.customerAssessService.contactSurveysForTimeline.filter(survey => survey.surveyStatus != SurveyStatus.SAVED) || [];
    if (!_.isEmpty(surveysByContactId)) {
      let groupedResponsesByDate: any = [];
      groupedResponsesByDate = _.groupBy(surveysByContactId, 'indskr_assessmentdate');
      groupedResponsesByDate = _.values(groupedResponsesByDate);

      groupedResponsesByDate.forEach((resp) => {
        let activity: any = {};

        const templateId: string = resp[0]['_indskr_template_value'];
        const foundTemplate: AssessmentTemplate = this.customerAssessService.surveytemplates.find(survey => survey.indskr_assessmenttemplateid == templateId);

        let surveyFrequency: string = foundTemplate['indskr_surveyfrequency'] ? foundTemplate['indskr_surveyfrequency'] == SurveyFrequency.ONCE ? this.translate.instant("ONE_TIME") : this.translate.instant("RECURRING") : '';
        activity['type'] = ActivityType.CustomerSurvey;
        activity['subject'] = resp[0]['indskr_name'] || '';
        activity['assessmentId'] = resp[0]['indskr_customerassessmentid'];
        activity['scheduledStart'] = resp[0]['indskr_assessmentdate'];
        activity['surveyFrequency'] = surveyFrequency;
        activity['surveyedBy'] = resp[0]['_modifiedby_value_Formatted'] || '';
        activity['templateId'] = templateId;
        // activity['rawData'] = resp;
        items.push(activity);
        this._formattedSurveysList.push(activity);
      });
    }
    return items;
  }

  /**************************Customer Journey**************************/
  private combineCustomerJourneyAndActivities(items: any) {
    if (this.contactService.contactInformation.customerJourneysTimeline) {
      this.contactService.contactInformation.customerJourneysTimeline.forEach((activity: Activity) => {
        if (activity.type === ActivityType.Appointment) {
          activity.type = ActivityType.CustomerJourneyAppointment;
          activity.scheduledStart = activity.journeyStart;
          activity.scheduledEnd = activity.journeyEnd;
        }
        else if (activity.type === ActivityType.PhoneCall) {
          activity.type = ActivityType.CustomerJourneyPhoneCall;
          activity.scheduledStart = activity.journeyStart;
          activity.scheduledEnd = activity.journeyEnd;
        }
        else if (activity.type === ActivityType.Email) {
          activity.type = ActivityType.CustomerJourneyEmail;
          activity.scheduledStart = activity.journeyStart;
          activity.scheduledEnd = activity.journeyEnd;
        }
        items.push(activity);
      });
    }
    return items;
  }

  /**************************Customer Journey**************************/

  public onTimelineTypeChange(activityType) {
    if (!this.contactService.contactInformation.eventsAndRegistrationsByContact && !this.contactService.contactInformation.activitesTimeline && !this.contactService.contactInformation.eventsCheckInByContact && !this.contactService.contactInformation.eventsCompletedByContact) return;
    switch (activityType) {
      case 'All_Activities': {
        this._filteredTimelineActivities = this._allTimelineActivities;
        break;
      }
      case 'MY_ACTIVITIES': {
        let items: any = [];
        this._filteredTimelineActivities = this._allTimelineActivities.filter(item => item.ownerId == this.authenticationService.user.systemUserID);
        break;
      }
      case 'Appointment': {
        this._filteredTimelineActivities = this._allTimelineActivities.filter(activity => activity.type == 'Appointment' && activity.location != 'LiveMeet')
        break;
      }
      case 'Email': {
        this._filteredTimelineActivities = this._allTimelineActivities.filter(activity => activity.type == 'Email');
        break;
      }
      case 'Allocation_Order': {
        this._filteredTimelineActivities = this._allTimelineActivities.filter(activity => activity.type == 'Sample');
        break;
      }
      case 'Customer_Inquiry': {
        this._filteredTimelineActivities = this._allTimelineActivities.filter(activity => activity.type == 'CaseIntake')
        break;
      }
      case 'Contact_Event': {
        let filterEvents: any = [];
        filterEvents.push(...this.contactService.contactInformation.eventsAndRegistrationsByContact);
        if (this.isEventToolfeatureAction) {
          filterEvents.push(...this.contactService.contactInformation.eventsCheckInByContact);
          filterEvents.push(...this.contactService.contactInformation.eventsCompletedByContact);
        }
        this._filteredTimelineActivities = filterEvents
        break;
      }
      case 'Live_Meet': {
        this._filteredTimelineActivities = this._allTimelineActivities.filter(activity => (activity.type == 'Appointment' && activity.location === 'LiveMeet'));
        break;
      }
      case 'Website_Access_Logs': {
        let filterEvents: any = [];
        filterEvents.push(...this.contactService.contactInformation.websiteAccessLogs);
        this._filteredTimelineActivities = filterEvents;
        break;
      }
      case ActivityType.PhoneCall: {
        this._filteredTimelineActivities = this._allTimelineActivities.filter(activity => (activity.type == ActivityType.PhoneCall));
        break;
      }
      case ActivityType.SurgeryOrder: {
        this._filteredTimelineActivities = this._allTimelineActivities.filter(activity => (activity.type == ActivityType.SurgeryOrder));
        break;
      }
      case ActivityType.ConsentActivity: {
        let filterEvents: any = [];
        filterEvents = this.combineConsentsAndActivities(filterEvents);
        this._filteredTimelineActivities = filterEvents
        break;
      }
      case ActivityType.CustomerSurvey: {
        let filterEvents: any = [];
        filterEvents = this._formattedSurveysList;
        this._filteredTimelineActivities = filterEvents
        break;
      }
      /**************************Customer Journey**************************/
      case ActivityType.CustomerJourneyAppointment: {
        let filterEvents: any = [];
        filterEvents.push(...this.contactService.contactInformation.customerJourneysTimeline.filter(activity => (activity.type == ActivityType.CustomerJourneyAppointment)));
        this._filteredTimelineActivities = filterEvents
        break;
      }
      case ActivityType.CustomerJourneyPhoneCall: {
        let filterEvents: any = [];
        filterEvents.push(...this.contactService.contactInformation.customerJourneysTimeline.filter(activity => (activity.type == ActivityType.CustomerJourneyPhoneCall)));
        this._filteredTimelineActivities = filterEvents
        break;
      }
      case ActivityType.CustomerJourneyEmail: {
        let filterEvents: any = [];
        filterEvents.push(...this.contactService.contactInformation.customerJourneysTimeline.filter(activity => (activity.type == ActivityType.CustomerJourneyEmail)));
        this._filteredTimelineActivities = filterEvents
        break;
      }
      /**************************Customer Journey**************************/
      default: {
        this._filteredTimelineActivities = this._allTimelineActivities;
      }
    }
    this._initTimelineItemsViewData();
  }

  public async openTimelineFilter(event) {
    const modal = await this.modalCtrl.create({
      component: IndFilterMenuModalComponent,
      cssClass: 'filter-menu-right-modal',
      backdropDismiss: true,
      componentProps: {
        viewData: {
          from: PageName.ContactTimelineComponent,
          data: this.contactTimelineFilterFormView,
          filterTitle: this.translate.instant('ALL_FILTERS'),
          selectedFilters: this.selectedFilters,
          selectedDate: this.selectedDate
        },
      }
    });
    await modal.present().then(() => { });
    modal.onDidDismiss().then(async (res) => {
      //Show Result
      if (res && res.data && res.data.isDone) {
        if (!_.isEmpty(res.data.selectedItems) || !_.isEmpty(res.data.selectedDate)) {
          //this.filterButtonBadgeCount = 0;
          //this._filteredTimelineActivities = [];
          this.contactTimelineFilterFormView = res.data.selectedItems;
          //this.isTimelineFiltered = true;
          this.selectedFilters = res.data.selectedItemsAccordion;
          //this.filterButtonBadgeCount = res.data.selectedItemsAccordion.length;
          // if (!_.isEmpty(res.data.selectedDate) && (!_.isEmpty(res.data.selectedDate.startDate) || !_.isEmpty(res.data.selectedDate.endDate))) {
          //   this.filterButtonBadgeCount = this.filterButtonBadgeCount + 1;
          // }
          // let selectedActivitiesFromMainFilter = [];
          // res.data.selectedItemsAccordion.forEach(item => {
          //   if (item.type == 'filter-main') {
          //     selectedActivitiesFromMainFilter.push(item.category);
          //   }
          // });

          /******* filtered activities by duration *******/
          let isFilteredByDate: boolean = !_.isEmpty(res.data.selectedDate) && !_.isEmpty(res.data.selectedDate.startDate) && !_.isEmpty(res.data.selectedDate.endDate) || false;
          //this.onTimelineTypeChange(this.contactTimelineFilter);
          //let targetTimelineActivities = _.cloneDeep(this._filteredTimelineActivities);
          if (isFilteredByDate) {
            this.selectedDate = {
              startDate: res.data.selectedDate.startDate,
              endDate: res.data.selectedDate.endDate,
            };
            //targetTimelineActivities = this._filterTimelineActivitiesByDate(targetTimelineActivities, this.selectedDate);
          } else {
            this.selectedDate = {
              startDate: '',
              endDate: '',
            };
          }
          this._applyAdvancedFilterOnTimelineActivities();
        }
        console.log(res);
      }
    })
  }

  private _applyAdvancedFilterOnTimelineActivities(){
    if (!_.isEmpty(this.contactTimelineFilterFormView) || !_.isEmpty(this.selectedDate)) {
      this.filterButtonBadgeCount = 0;
      this._filteredTimelineActivities = [];
      //this.contactTimelineFilterFormView = res.data.selectedItems;
      this.isTimelineFiltered = true;
      //this.selectedFilters = res.data.selectedItemsAccordion;
      this.filterButtonBadgeCount = this.selectedFilters.length;
      if (!_.isEmpty(this.selectedDate) && (!_.isEmpty(this.selectedDate.startDate) || !_.isEmpty(this.selectedDate.endDate))) {
        this.filterButtonBadgeCount = this.filterButtonBadgeCount + 1;
      }
      let selectedActivitiesFromMainFilter = [];
      this.selectedFilters.forEach(item => {
        if (item.type == 'filter-main') {
          selectedActivitiesFromMainFilter.push(item.category);
        }
      });

      /******* filtered activities by duration *******/
      let isFilteredByDate: boolean = !_.isEmpty(this.selectedDate) && !_.isEmpty(this.selectedDate.startDate) && !_.isEmpty(this.selectedDate.endDate) || false;
      this.onTimelineTypeChange(this.contactTimelineFilter);
      let targetTimelineActivities = _.cloneDeep(this._filteredTimelineActivities);
      if (isFilteredByDate) {
        // this.selectedDate = {
        //   startDate: this.selectedDate.startDate,
        //   endDate: this.selectedDate.endDate,
        // };
        targetTimelineActivities = this._filterTimelineActivitiesByDate(targetTimelineActivities, this.selectedDate);
      } else {
        // this.selectedDate = {
        //   startDate: '',
        //   endDate: '',
        // };
      }

      /******* filtered activities by selected activity and options *******/
      const isSelectedMyActivity: boolean = !_.isEmpty(selectedActivitiesFromMainFilter) && selectedActivitiesFromMainFilter.includes(ActivityType.MyActivity);
      if (isSelectedMyActivity) targetTimelineActivities = targetTimelineActivities.filter(activity => activity.ownerId == this.authenticationService.user.systemUserID);
      if (!_.isEmpty(selectedActivitiesFromMainFilter)) {
        let activities = [];
        selectedActivitiesFromMainFilter.forEach(mainFilterValue => {
          if (mainFilterValue != ActivityType.MyActivity) {
            let filteredTargetTimelineActivity = [];
            if (mainFilterValue == ActivityType.LiveMeet) {
              filteredTargetTimelineActivity = targetTimelineActivities.filter(activity => activity.type == ActivityType.Appointment && activity.location == 'LiveMeet');
            } else {
              filteredTargetTimelineActivity = targetTimelineActivities.filter(activity => activity.type == mainFilterValue);
            }
            const filteredSelectedItemsByActivity = this.selectedFilters.filter(item => item.parentId == mainFilterValue);
            const groupedCategory = _.groupBy(filteredSelectedItemsByActivity, 'category');
            const groupedCategoryList = _.values(groupedCategory);
            if (!_.isEmpty(filteredSelectedItemsByActivity)) {
              filteredTargetTimelineActivity.forEach(act => {
                let isMatchedData: boolean = true;
                groupedCategoryList.forEach(category => {
                  if (mainFilterValue == ActivityType.Appointment && act.location != 'LiveMeet') {
                    if (category[0]['type'] == 'appointment-status') {
                      isMatchedData = category.some(item => item.id == ActivityType.Appointment + '_' + act.statusString) && isMatchedData;
                    } else if (category[0]['type'] == 'appointment-address') {
                      const formattedAddressId = act.location != 'No Location' ? ActivityType.Appointment + '_' + act.location : ActivityType.Appointment + '_' + 'noAddress';
                      isMatchedData = category.some(item => item.id == formattedAddressId) && isMatchedData;
                    } else if (category[0]['type'] == 'appointment-product') {
                      isMatchedData = this._matchedProduct(ActivityType.Appointment, category, act) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.Email) {
                    if (category[0]['type'] == 'messages-channelType') {
                      isMatchedData = category.some(item => item.id == ActivityType.Email + '_' + act.channelTypeName) && isMatchedData;
                    } else if (category[0]['type'] == 'messages-status') {
                      let formattedStatus: string = '';
                      if (act.status == 548910000) {
                        if (act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp') {
                          formattedStatus = act.meetingOwnerName == this.userName ? this.translate.instant('CONTACT_SHARED_BY_ME') : this.translate.instant('CONTACT_SHARED_BY_OWNER', { meetingOwnerName: act.meetingOwnerName });
                        }
                      } else {
                        if (act.channelTypeName == 'Email' || act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp' || act.channelTypeName == undefined) {
                          formattedStatus = act.meetingOwnerName == this.userName ? this.translate.instant('CONTACT_SENT_BY_ME') : this.translate.instant('CONTACT_SENT_BY_OWNER', { meetingOwnerName: act.meetingOwnerName });
                        }
                      }
                      isMatchedData = category.some(item => item.id == ActivityType.Email + '_' + formattedStatus) && isMatchedData;
                    } else if (category[0]['type'] == 'messages-transfer-status') {
                      let formattedTransferStatus: string = '';
                      if (act.status == 548910000) {
                        if (act.actualEnd && (act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp')) {
                          formattedTransferStatus = this.translate.instant('SHARED');
                        }
                      } else {
                        if (act.channelTypeName == 'Email' || act.channelTypeName == undefined) {
                          if (act.senton && !act.readOn) {
                            formattedTransferStatus = this.translate.instant('DELIVERED');
                          } else if (act.readOn) {
                            formattedTransferStatus = this.translate.instant('READ');
                          }
                        } else if (act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp') {
                          if (act.senton && !act.readOn) {
                            formattedTransferStatus = this.translate.instant('SENT');
                          } else if (act.readOn) {
                            formattedTransferStatus = this.translate.instant('DELIVERED');
                          }
                        }
                      }
                      isMatchedData = category.some(item => item.id == ActivityType.Email + '_' + formattedTransferStatus) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.Sample) {
                    if (category[0]['type'] == 'sample-address') {
                      isMatchedData = category.some(item => item.id == ActivityType.Sample + '_' + act.addressID) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.CaseIntake) {
                    if (category[0]['type'] == 'caseIntake-status') {
                      isMatchedData = category.some(item => item.id == ActivityType.CaseIntake + '_' + act._case_status_value) && isMatchedData;
                    } else if (category[0]['type'] == 'caseIntake-stage') {
                      isMatchedData = category.some(item => item.id == ActivityType.CaseIntake + '_' + act._case_stage_value) && isMatchedData;
                    } else if (category[0]['type'] == 'caseIntake-assigned-to') {
                      isMatchedData = category.some(item => item.id == ActivityType.CaseIntake + '_' + act.meetingOwnerId) && isMatchedData;
                    } else if (category[0]['type'] == 'caseIntake-product') {
                      isMatchedData = category.some(item => item.id == ActivityType.CaseIntake + '_' + act._case_trans_product_name) && isMatchedData;
                    } 
                  }
                  else if (mainFilterValue == ActivityType.Event) {
                    if (category[0]['type'] == 'event-status') {
                      let formattedStatus: string = '';
                      if (!_.isEmpty(act.completedby)) {
                        formattedStatus = act.completedby == this.userName ? this.translate.instant('EVENT_COMPLETED_BY_ME') : this.translate.instant('EVENT_COMPLETED_BY_OWNER', { text: act.completedby });
                      } else if (!_.isEmpty(act.createdby)) {
                        formattedStatus = act.completedby == this.userName ? this.translate.instant('EVENT_CHECKED_BY_ME') : this.translate.instant('EVENT_CHECKED_BY_OWNER', { text: act.createdby });
                      }
                      if (this.isCustomerEventsEnabled) {
                        if (_.isEmpty(act.registrationresponseId) && !_.isEmpty(act.eventRegistrationId) && !_.isEmpty(act.registeredBy)) {
                          formattedStatus = act.registeredBy == this.userName ? this.translate.instant('CONTACT_REGISTERED_BY_ME') : this.translate.instant('CONTACT_REGISTERED_BY_OWNER', { text: act.registeredBy });
                        } else if (!_.isEmpty(act.registrationresponseId) && !_.isEmpty(act.indskr_ownername)) {
                          formattedStatus = act.indskr_ownername == this.userName ? this.translate.instant('CONTACT_SIGNEDUP_BY_ME') : this.translate.instant('CONTACT_SIGNEDUP_BY_OWNER', { text: act.indskr_ownername });
                        }
                      }
                      isMatchedData = category.some(item => item.id == ActivityType.Event + '_' + formattedStatus) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.LiveMeet) {
                    if (category[0]['type'] == 'liveMeeting-status') {
                      isMatchedData = category.some(item => item.id == ActivityType.LiveMeet + '_' + act.statusString) && isMatchedData;
                    } else if (category[0]['type'] == 'liveMeeting-product') {
                      isMatchedData = this._matchedProduct(ActivityType.LiveMeet, category, act) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.PhoneCall) {
                    if (category[0]['type'] == 'phoneCall-phoneNumber') {
                      isMatchedData = category.some(item => item.id == ActivityType.PhoneCall + '_' + act.phonecallphonenumber) && isMatchedData;
                    } else if (category[0]['type'] == 'phoneCall-product') {
                      isMatchedData = this._matchedProduct(ActivityType.PhoneCall, category, act) && isMatchedData;
                    } 
                  }
                  else if (mainFilterValue == ActivityType.SurgeryOrder) {
                    if (category[0]['type'] == 'surgeryOrder-account') {
                      isMatchedData = category.some(item => item.id == ActivityType.SurgeryOrder + '_' + act.accountNameString) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.ConsentActivity) {
                    if (category[0]['type'] == 'consent-term') {
                      isMatchedData = category.some(item => item.id == act.consentTermId) && isMatchedData;
                    } else if (category[0]['type'] == 'Product') {
                      isMatchedData = category.some(item => item.label == act.indskr_productname) && isMatchedData;
                    } else if (category[0]['type'] == 'Phone') {
                      isMatchedData = category.some(item => item.label == act.indskr_phone) && isMatchedData;
                    } else if (category[0]['type'] == 'Address') {
                      isMatchedData = category.some(item => item.label == act.indskr_addressname) && isMatchedData;
                    } else if (category[0]['type'] == 'Email') {
                      isMatchedData = category.some(item => item.label == act.emailAddress) && isMatchedData;
                    } else if (category[0]['type'] == 'Source') {
                      const formattedSourceType: string = act.indskr_consentactivitysourcetype == 600000001 ? this.translate.instant('PORTAL') : act.indskr_consentactivitysourcetype == 600000002 ? this.translate.instant('EXTERNAL') : act.indskr_optinsource == ConsentSource.PAPER_CONSENT ? `Omnipresence ${this.translate.instant('PAPER_CONSENTS')}`: 'Omnipresence Go';
                      isMatchedData = category.some(item => item.label == formattedSourceType) && isMatchedData;
                    } else if (category[0]['type'] == 'Type') {
                      isMatchedData = category.some(item => item.id == 'Type' + '_' + act.indskr_type) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.WebsiteAccess) {
                    if (category[0]['type'] == 'websiteAccessLogs-channels') {
                      isMatchedData = category.some(item => item.id == ActivityType.WebsiteAccess + '_' + act.channel) && isMatchedData;
                    } else if (category[0]['type'] == 'websiteAccessLogs-department') {
                      isMatchedData = category.some(item => item.id == ActivityType.WebsiteAccess + '_' + act.department) && isMatchedData;
                    }
                  }
                  /**************************Customer Journey**************************/
                  else if (mainFilterValue == ActivityType.CustomerJourneyAppointment) {
                    if (category[0]['type'] == 'customerJourney-appointment-status') {
                      isMatchedData = category.some(item => item.id == ActivityType.CustomerJourneyAppointment + '_' + act.statusString) && isMatchedData;
                    } else if (category[0]['type'] == 'customerJourney-appointment-address') {
                      const formattedAddressId = act.location != 'No Location' ? ActivityType.CustomerJourneyAppointment + '_' + act.location : ActivityType.Appointment + '_' + 'noAddress';
                      isMatchedData = category.some(item => item.id == formattedAddressId) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.CustomerJourneyPhoneCall) {
                    if (category[0]['type'] == 'customerJourney-phoneCall-phoneNumber') {
                      isMatchedData = category.some(item => item.id == ActivityType.CustomerJourneyPhoneCall + '_' + act.phonecallphonenumber) && isMatchedData;
                    }
                  }
                  else if (mainFilterValue == ActivityType.CustomerJourneyEmail) {
                    if (category[0]['type'] == 'customerJourney-email-emailDelivered') {
                      isMatchedData = category.some(item => item.id == ActivityType.CustomerJourneyEmail + '_' + act.email_delivered) && isMatchedData;
                    }
                  }
                  /**************************Customer Journey**************************/
                  else if (mainFilterValue == ActivityType.CustomerSurvey) {
                    if (category[0]['type'] == 'customerSurvey-surveyType') {
                      isMatchedData = category.some(item => item.id == ActivityType.CustomerSurvey + '_' + act.type) && isMatchedData;
                    } else if (category[0]['type'] == 'customerSurvey-surveyFrequency') {
                      isMatchedData = category.some(item => item.id == ActivityType.CustomerSurvey + '_' + act.surveyFrequency) && isMatchedData;
                    } else if (category[0]['type'] == 'customerSurvey-surveyedBy') {
                      isMatchedData = category.some(item => item.id == ActivityType.CustomerSurvey + '_' + act.surveyedBy) && isMatchedData;
                    }
                  }
                });
                if (isMatchedData) activities.push(act);
              });
            } else {
              let filteredActivityMainOnly = targetTimelineActivities.filter(activity => activity.type == mainFilterValue);
              filteredActivityMainOnly.forEach(act => {
                activities.push(act);
              });
            }
          }
        });
        if (!isSelectedMyActivity) targetTimelineActivities = activities;
      }

      /******* grouped filtered activities *******/
      this._filteredTimelineActivities = targetTimelineActivities;
      this._initTimelineItemsViewData();
    }else{
      this.onTimelineTypeChange(this.contactTimelineFilter);
    }
  }

  private _filterTimelineActivitiesByDate(targetTimelineActivities: any, selectedDate: any) {
    const startDate: Date = new Date(selectedDate.startDate);
    const endDate: Date = new Date(selectedDate.endDate);
    targetTimelineActivities.map(activity => {
      if (activity.hasOwnProperty('scheduledStart') && activity['scheduledStart'] != '' && activity['scheduledStart'] != "Invalid Date" && activity['scheduledStart'] != undefined) {
        activity['isValidStartDate'] = true;
        activity['scheduledStart'] = new Date(activity['scheduledStart']);
      }
      if (activity.hasOwnProperty('scheduledEnd') && activity['scheduledEnd'] != '' && activity['scheduledEnd'] != "Invalid Date" && activity['scheduledEnd'] != undefined) {
        activity['isValidEndDate'] = true;
        activity['scheduledEnd'] = new Date(activity['scheduledEnd']);
      }
    });
    let filteredTimelineActivities = [];
    targetTimelineActivities.forEach(activity => {
      if (activity.hasOwnProperty('scheduledStart') && activity['isValidStartDate'] && activity.hasOwnProperty('scheduledEnd') && activity['isValidEndDate']) {
        if (activity['scheduledStart'] >= startDate && activity['scheduledEnd'] <= endDate) {
          filteredTimelineActivities.push(activity);
        }
      } else if (activity.hasOwnProperty('scheduledStart') && activity['isValidStartDate']) {
        if (activity['scheduledStart'] >= startDate && activity['scheduledStart'] <= endDate) {
          filteredTimelineActivities.push(activity);
        }
      } else if (activity.hasOwnProperty('scheduledEnd') && activity['isValidEndDate']) {
        if (activity['scheduledEnd'] <= endDate) {
          filteredTimelineActivities.push(activity);
        }
      }
    });
    // targetTimelineActivities = filteredTimelineActivities;
    return filteredTimelineActivities;
  }

  private _initFilterFormViews() {
    this.contactTimelineFilterFormView = [];
    if (!_.isEmpty(this._allTimelineActivities)) {
      let filterFormViewMain: IndFilterMenuModalDataModel = {
        id: 'filter-main',
        displayName: this.translate.instant('ACTIVITY'),
        isMultiSelect: true,
        options: []
      }
      this.contactTimelineFilterFormView.push(filterFormViewMain);

      let myActivitesDisplayText = this.translate.instant('MY_ACTIVITIES');
      let meetingsDisplayText = this.translate.instant('MEETINGS');
      if (this.translate.currentLang == 'it') {
        myActivitesDisplayText = this.translate.instant('MY_ACTIVITIES_TIMELINE');
        meetingsDisplayText = this.translate.instant('MEETINGS_TIMELINE');
      }
      //default filter options - my activity
      filterFormViewMain.options.push({ id: 'myActivities-main', group: ActivityType.MyActivity, isSelected: false, displayName: myActivitesDisplayText });
      //add filter options based on the timelineActivities
      if (this._allTimelineActivities.some(activity => (activity.type == ActivityType.Appointment && activity.location != 'LiveMeet'))) {
        filterFormViewMain.options.push({ id: 'meeting-main', group: ActivityType.Appointment, isSelected: false, displayName: meetingsDisplayText });
        let appointmentActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.Appointment && activity.location != 'LiveMeet');
        appointmentActs.forEach((act) => {
          //status
          if (!_.isEmpty(act.statusString)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'appointment-status');
            let option = {
              group: this.translate.instant('STATUS'),
              id: ActivityType.Appointment + '_' + act.statusString,
              displayName: act.statusString,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Appointment + '_' + act.statusString);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'appointment-status',
                parentId: ActivityType.Appointment,
                displayName: this.translate.instant('MEETINGS') + ' - ' + this.translate.instant('STATUS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
          //address
          if (!_.isEmpty(act.location)) {
            const formattedAddressId = act.location != 'No Location' ? ActivityType.Appointment + '_' + act.location : ActivityType.Appointment + '_' + 'noAddress';
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'appointment-address');
            let option = {
              group: this.translate.instant('ADDRESS'),
              id: formattedAddressId,
              displayName: act.location,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == formattedAddressId);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'appointment-address',
                parentId: ActivityType.Appointment,
                displayName: this.translate.instant('MEETINGS') + ' - ' + this.translate.instant('ADDRESS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
          //Products
          if (!_.isEmpty(act.products)) {
            act.products.forEach(product => {
              const idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'appointment-product');
              let option = {
                group: this.translate.instant('PRODUCT'),
                id: ActivityType.Appointment + '_' + product.ID,
                displayName: product.name,
                isSelected: false,
              }
              if (idx > -1) {
                let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Appointment + '_' + product.ID);
                if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
              } else {
                let viewData = {
                  id: 'appointment-product',
                  parentId: ActivityType.Appointment,
                  displayName: this.translate.instant('MEETINGS') + ' - ' + this.translate.instant('PRODUCT'),
                  isMultiSelect: true,
                  options: []
                }
                viewData.options.push(option);
                this.contactTimelineFilterFormView.push(viewData);
              }
            })
          }
        });
      }
      if (this._allTimelineActivities.some(activity => activity.type == ActivityType.Email)) {
        filterFormViewMain.options.push({ id: 'email-main', group: ActivityType.Email, isSelected: false, displayName: this.translate.instant('MESSAGES') });
        let messagesActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.Email);
        messagesActs.forEach((act) => {
          //channel type
          if (!_.isEmpty(act.channelTypeName)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'messages-channelType');
            let option = {
              group: this.translate.instant('CHANNELS'),
              id: ActivityType.Email + '_' + act.channelTypeName,
              displayName: act.channelTypeName,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Email + '_' + act.channelTypeName);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'messages-channelType',
                parentId: ActivityType.Email,
                displayName: this.translate.instant('MESSAGES') + ' - ' + this.translate.instant('CHANNELS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
          //status
          if (act.status != '' || act.status != undefined || act.status != null) {
            let formattedStatus: string = '';
            if (act.status == 548910000) {
              if (act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp') {
                formattedStatus = act.meetingOwnerName == this.userName ? this.translate.instant('CONTACT_SHARED_BY_ME') : this.translate.instant('CONTACT_SHARED_BY_OWNER', { meetingOwnerName: act.meetingOwnerName });
              }
            } else {
              if (act.channelTypeName == 'Email' || act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp' || act.channelTypeName == undefined) {
                formattedStatus = act.meetingOwnerName == this.userName ? this.translate.instant('CONTACT_SENT_BY_ME') : this.translate.instant('CONTACT_SENT_BY_OWNER', { meetingOwnerName: act.meetingOwnerName });
              }
            }
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'messages-status');
            let option = {
              group: this.translate.instant('STATUS'),
              id: ActivityType.Email + '_' + formattedStatus,
              displayName: formattedStatus,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Email + '_' + formattedStatus);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'messages-status',
                parentId: ActivityType.Email,
                displayName: this.translate.instant('MESSAGES') + ' - ' + this.translate.instant('STATUS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
          //transfer status
          if (act.status != '' || act.status != undefined || act.status != null) {
            let formattedTransferStatus: string = '';
            if (act.status == 548910000) {
              if (act.actualEnd && (act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp')) {
                formattedTransferStatus = this.translate.instant('SHARED');
              }
            } else {
              if (act.channelTypeName == 'Email' || act.channelTypeName == undefined) {
                if (act.senton && !act.readOn) {
                  formattedTransferStatus = this.translate.instant('DELIVERED');
                } else if (act.readOn) {
                  formattedTransferStatus = this.translate.instant('READ');
                }
              } else if (act.channelTypeName == 'SMS' || act.channelTypeName == 'Whatsapp') {
                if (act.senton && !act.readOn) {
                  formattedTransferStatus = this.translate.instant('SENT');
                } else if (act.readOn) {
                  formattedTransferStatus = this.translate.instant('DELIVERED');
                }
              }
            }
            if(formattedTransferStatus){
              let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'messages-transfer-status');
              let option = {
                group: this.translate.instant('TRANSFER_STATUS'),
                id: ActivityType.Email + '_' + formattedTransferStatus,
                displayName: formattedTransferStatus,
                isSelected: false,
              }
              if (idx > -1) {
                let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Email + '_' + formattedTransferStatus);
                if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
              } else {
                let viewData = {
                  id: 'messages-transfer-status',
                  parentId: ActivityType.Email,
                  displayName: this.translate.instant('MESSAGES') + ' - ' + this.translate.instant('TRANSFER_STATUS'),
                  isMultiSelect: true,
                  options: []
                }
                viewData.options.push(option);
                this.contactTimelineFilterFormView.push(viewData);
              }
            }
          }
        });
      }
      if (this._allTimelineActivities.some(activity => activity.type == ActivityType.Sample)) {
        filterFormViewMain.options.push({ id: 'sample-main', group: ActivityType.Sample, isSelected: false, displayName: this.translate.instant('ALLOWCATION_ORDERS') });
        let sampleActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.Sample);
        sampleActs.forEach((act) => {
          //address
          if (!_.isEmpty(act.location)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'sample-address');
            let option = {
              group: this.translate.instant('ADDRESS'),
              id: ActivityType.Sample + '_' + act.addressID,
              displayName: act.location,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Sample + '_' + act.addressID);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'sample-address',
                parentId: ActivityType.Sample,
                displayName: this.translate.instant('ALLOWCATION_ORDERS') + ' - ' + this.translate.instant('ADDRESS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
        });
      }
      if (this._allTimelineActivities.some(activity => activity.type == ActivityType.CaseIntake)) {
        filterFormViewMain.options.push({ id: 'customerInquiries-main', group: ActivityType.CaseIntake, isSelected: false, displayName: this.translate.instant('CUSTOMER_INQUIRIES', { globalCustomerText: this.utilityService.globalCustomerText }) });
        let caseIntakeActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.CaseIntake);
        caseIntakeActs.forEach((act) => {
          //status
          if (!_.isEmpty(act._case_status_value)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'caseIntake-status');
            let option = {
              group: this.translate.instant('STATUS'),
              id: ActivityType.CaseIntake + '_' + act._case_status_value,
              displayName: act._case_status_value,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CaseIntake + '_' + act._case_status_value);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'caseIntake-status',
                parentId: ActivityType.CaseIntake,
                displayName: this.translate.instant('INQUIRY') + ' - ' + this.translate.instant('STATUS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
          //stage
          if (!_.isEmpty(act._case_stage_value)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'caseIntake-stage');
            let option = {
              group: this.translate.instant('STAGE'),
              id: ActivityType.CaseIntake + '_' + act._case_stage_value,
              displayName: act._case_stage_value,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CaseIntake + '_' + act._case_stage_value);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'caseIntake-stage',
                parentId: ActivityType.CaseIntake,
                displayName: this.translate.instant('INQUIRY') + ' - ' + this.translate.instant('STAGE'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
          //assign to
          if (!_.isEmpty(act.meetingOwnerId)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'caseIntake-assigned-to');
            let option = {
              group: this.translate.instant('ASSIGNED_TO'),
              id: ActivityType.CaseIntake + '_' + act.meetingOwnerId,
              displayName: act.meetingOwnerName,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CaseIntake + '_' + act.meetingOwnerId);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'caseIntake-assigned-to',
                parentId: ActivityType.CaseIntake,
                displayName: this.translate.instant('INQUIRY') + ' - ' + this.translate.instant('ASSIGNED_TO'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
          //Products
          if (!_.isEmpty(act._case_trans_product_name) && act._case_trans_product_name != 'Product') {
            const idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'caseIntake-product');
            let option = {
              group: this.translate.instant('PRODUCT'),
              id: ActivityType.CaseIntake + '_' + act._case_trans_product_name,
              displayName: act._case_trans_product_name,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CaseIntake + '_' + act._case_trans_product_name);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'caseIntake-product',
                parentId: ActivityType.CaseIntake,
                displayName: this.translate.instant('INQUIRY') + ' - ' + this.translate.instant('PRODUCT'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
        });
      }
      if (this._allTimelineActivities.some(activity => activity.type == ActivityType.Event)) {
        filterFormViewMain.options.push({ id: 'event-main', group: ActivityType.Event, isSelected: false, displayName: this.translate.instant('EVENTS') });
        let eventActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.Event);
        eventActs.forEach((act) => {
          //status
          if (!_.isEmpty(act.createdby) || !_.isEmpty(act.completedby) || (_.isEmpty(act.registrationresponseId) && !_.isEmpty(act.eventRegistrationId) && !_.isEmpty(act.registeredBy)) || (!_.isEmpty(act.registrationresponseId) && !_.isEmpty(act.indskr_ownername))) {
            let formattedStatus: string = '';
            if (!_.isEmpty(act.completedby)) {
              formattedStatus = act.completedby == this.userName ? this.translate.instant('EVENT_COMPLETED_BY_ME') : this.translate.instant('EVENT_COMPLETED_BY_OWNER', { text: act.completedby });
            } else if (!_.isEmpty(act.createdby)) {
              formattedStatus = act.completedby == this.userName ? this.translate.instant('EVENT_CHECKED_BY_ME') : this.translate.instant('EVENT_CHECKED_BY_OWNER', { text: act.createdby });
            }
            if (this.isCustomerEventsEnabled) {
              if (_.isEmpty(act.registrationresponseId) && !_.isEmpty(act.eventRegistrationId) && !_.isEmpty(act.registeredBy)) {
                formattedStatus = act.registeredBy == this.userName ? this.translate.instant('CONTACT_REGISTERED_BY_ME') : this.translate.instant('CONTACT_REGISTERED_BY_OWNER', { text: act.registeredBy });
              } else if (!_.isEmpty(act.registrationresponseId) && !_.isEmpty(act.indskr_ownername)) {
                formattedStatus = act.indskr_ownername == this.userName ? this.translate.instant('CONTACT_SIGNEDUP_BY_ME') : this.translate.instant('CONTACT_SIGNEDUP_BY_OWNER', { text: act.indskr_ownername });
              }
            }
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'event-status');
            let option = {
              group: this.translate.instant('STATUS'),
              id: ActivityType.Event + '_' + formattedStatus,
              displayName: formattedStatus,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.Event + '_' + formattedStatus);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'event-status',
                parentId: ActivityType.Event,
                displayName: this.translate.instant('Event') + ' - ' + this.translate.instant('STATUS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
        });
      }
      if (this._allTimelineActivities.some(activity => (activity.type == ActivityType.Appointment && activity.location === 'LiveMeet'))) {
        filterFormViewMain.options.push({ id: 'liveMeeting-main', group: ActivityType.LiveMeet, isSelected: false, displayName: this.translate.instant('LIVE_MEETING') });
        let liveMeetingActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.Appointment && activity.location == 'LiveMeet');
        liveMeetingActs.forEach((act) => {
          //status
          if (!_.isEmpty(act.statusString)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'liveMeeting-status');
            let option = {
              group: this.translate.instant('STATUS'),
              id: ActivityType.LiveMeet + '_' + act.statusString,
              displayName: act.statusString,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.LiveMeet + '_' + act.statusString);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'liveMeeting-status',
                parentId: ActivityType.LiveMeet,
                displayName: this.translate.instant('LIVE_MEETING') + ' - ' + this.translate.instant('STATUS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
          //Products
          if (!_.isEmpty(act.products)) {
            act.products.forEach(product => {
              const idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'liveMeeting-product');
              let option = {
                group: this.translate.instant('PRODUCT'),
                id: ActivityType.LiveMeet + '_' + product.ID,
                displayName: product.name,
                isSelected: false,
              }
              if (idx > -1) {
                let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.LiveMeet + '_' + product.ID);
                if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
              } else {
                let viewData = {
                  id: 'liveMeeting-product',
                  parentId: ActivityType.LiveMeet,
                  displayName: this.translate.instant('LIVE_MEETING') + ' - ' + this.translate.instant('PRODUCT'),
                  isMultiSelect: true,
                  options: []
                }
                viewData.options.push(option);
                this.contactTimelineFilterFormView.push(viewData);
              }
            })
          }
        });
      }
      if (this._allTimelineActivities.some(activity => (activity.type == ActivityType.PhoneCall))) {
        filterFormViewMain.options.push({ id: 'phoneCall-main', group: ActivityType.PhoneCall, isSelected: false, displayName: this.translate.instant('PHONE_CALLS') });
        let phoneCallActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.PhoneCall);
        phoneCallActs.forEach((act) => {
          //phone number
          if (!_.isEmpty(act.phonecallphonenumber)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'phoneCall-phoneNumber');
            let option = {
              group: this.translate.instant('PHONE_NUMBER'),
              id: ActivityType.PhoneCall + '_' + act.phonecallphonenumber,
              displayName: act.phonecallphonenumber,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.PhoneCall + '_' + act.phonecallphonenumber);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'phoneCall-phoneNumber',
                parentId: ActivityType.PhoneCall,
                displayName: this.translate.instant('PHONE_CALLS') + ' - ' + this.translate.instant('PHONE_NUMBER'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
          //Products
          if (!_.isEmpty(act.products)) {
            act.products.forEach(product => {
              const idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'phoneCall-product');
              let option = {
                group: this.translate.instant('PRODUCT'),
                id: ActivityType.PhoneCall + '_' + product.ID,
                displayName: product.name,
                isSelected: false,
              }
              if (idx > -1) {
                let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.PhoneCall + '_' + product.ID);
                if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
              } else {
                let viewData = {
                  id: 'phoneCall-product',
                  parentId: ActivityType.PhoneCall,
                  displayName: this.translate.instant('PHONE_CALLS') + ' - ' + this.translate.instant('PRODUCT'),
                  isMultiSelect: true,
                  options: []
                }
                viewData.options.push(option);
                this.contactTimelineFilterFormView.push(viewData);
              }
            })
          }
        });
      }
      if (this._allTimelineActivities.some(activity => (activity.type == ActivityType.SurgeryOrder))) {
        filterFormViewMain.options.push({ id: 'surgeryOrder-main', group: ActivityType.SurgeryOrder, isSelected: false, displayName: this.translate.instant('PROCEDURE_LOGS') });
        let surgeryOrderActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.SurgeryOrder);
        surgeryOrderActs.forEach((act) => {
          //account
          if (!_.isEmpty(act.accountNameString)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'surgeryOrder-account');
            let option = {
              group: this.translate.instant('ACCOUNT'),
              id: ActivityType.SurgeryOrder + '_' + act.accountNameString,
              displayName: act.accountNameString,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.SurgeryOrder + '_' + act.accountNameString);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'surgeryOrder-account',
                parentId: ActivityType.SurgeryOrder,
                displayName: this.translate.instant('PROCEDURE_LOGS') + ' - ' + this.translate.instant('ACCOUNT'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
        });
      }
      if (this._allTimelineActivities.some(activity => (activity.type == ActivityType.ConsentActivity))) {
        filterFormViewMain.options.push({ id: 'consent-main', group: ActivityType.ConsentActivity, isSelected: false, displayName: this.translate.instant('CONSENTS') });
        let conActs = this._allTimelineActivities.filter(activity => (activity.type == ActivityType.ConsentActivity));
        conActs.forEach((act) => {
          //consent term
          let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'consent-term');
          let option = {
            group: this.translate.instant('CONTACT_CONSENT_TERMS'),
            id: act.consentTermId,
            displayName: act.consentTermName,
            isSelected: false,
          }
          if (idx > -1) {
            let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == act.consentTermId);
            if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
          } else {
            let consentTermView = {
              id: 'consent-term',
              parentId: ActivityType.ConsentActivity,
              displayName: this.translate.instant('CONSENTS') + ' - ' + this.translate.instant('CONTACT_CONSENT_TERMS'),
              isMultiSelect: true,
              options: []
            }
            consentTermView.options.push(option);
            this.contactTimelineFilterFormView.push(consentTermView);
          }
          //product
          if (!_.isEmpty(act.indskr_productname) && this.authenticationService.user.isProductConsent) {
            let option = {
              group: this.translate.instant('PRODUCTS'),
              id: `Product_${act.indskr_productname}`,
              displayName: act.indskr_productname,
              isSelected: false,
            };
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'Product');
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == `Product_${act.indskr_productname}`);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let productView = {
                id: 'Product',
                parentId: ActivityType.ConsentActivity,
                displayName: this.translate.instant('CONSENTS') + ' - ' + this.translate.instant('PRODUCTS'),
                isMultiSelect: true,
                options: []
              }
              productView.options.push(option);
              this.contactTimelineFilterFormView.push(productView);
            }
          }
          //Email
          if (!_.isEmpty(act.emailAddress)) {
            let option = {
              group: this.translate.instant('EMAIL'),
              id: `Email_${act.emailAddress}`,
              displayName: act.emailAddress,
              isSelected: false,
            };
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'Email');
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == `Email_${act.emailAddress}`);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let emailView = {
                id: 'Email',
                parentId: ActivityType.ConsentActivity,
                displayName: this.translate.instant('CONSENTS') + ' - ' + this.translate.instant('EMAIL'),
                isMultiSelect: true,
                options: []
              }
              emailView.options.push(option);
              this.contactTimelineFilterFormView.push(emailView);
            }
          }
          //Address
          if (!_.isEmpty(act.indskr_addressname)) {
            let option = {
              group: this.translate.instant('ADDRESS'),
              id: `Address_${act.indskr_addressname}`,
              displayName: act.indskr_addressname,
              isSelected: false,
            };
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'Address');
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == `Address_${act.indskr_addressname}`);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let addressView = {
                id: 'Address',
                parentId: ActivityType.ConsentActivity,
                displayName: this.translate.instant('CONSENTS') + ' - ' + this.translate.instant('ADDRESS'),
                isMultiSelect: true,
                options: []
              }
              addressView.options.push(option);
              this.contactTimelineFilterFormView.push(addressView);
            }
          }
          //Phone
          if (!_.isEmpty(act.indskr_phone)) {
            let option = {
              group: this.translate.instant('PHONE'),
              id: `Phone_${act.indskr_phone}`,
              displayName: act.indskr_phone,
              isSelected: false,
            };
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'Phone');
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == `Phone_${act.indskr_phone}`);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let phoneView = {
                id: 'Phone',
                parentId: ActivityType.ConsentActivity,
                displayName: this.translate.instant('CONSENTS') + ' - ' + this.translate.instant('PHONE_NUMBER'),
                isMultiSelect: true,
                options: []
              }
              phoneView.options.push(option);
              this.contactTimelineFilterFormView.push(phoneView);
            }
          }
          //Source
          const formattedSourceType: string = act.indskr_consentactivitysourcetype == 600000001 ? this.translate.instant('PORTAL') : act.indskr_consentactivitysourcetype == 600000002 ? this.translate.instant('EXTERNAL') : act.indskr_optinsource == ConsentSource.PAPER_CONSENT ? `Omnipresence ${this.translate.instant('PAPER_CONSENTS')}`: 'Omnipresence Go';
          if (formattedSourceType) {
            let option = {
              group: this.translate.instant('SOURCE'),
              id: `Source_${formattedSourceType}`,
              displayName: formattedSourceType,
              isSelected: false,
            };
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'Source');
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == `Source_${formattedSourceType}`);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let sourceView = {
                id: 'Source',
                parentId: ActivityType.ConsentActivity,
                displayName: this.translate.instant('CONSENTS') + ' - ' + this.translate.instant('SOURCE'),
                isMultiSelect: true,
                options: []
              }
              sourceView.options.push(option);
              this.contactTimelineFilterFormView.push(sourceView);
            }
          }
          //Type - Opt In, Opt Out number
          if (act.indskr_type != null && act.indskr_type != undefined) {
            let option = {
              group: this.translate.instant('TYPE'),
              id: `Type_${act.indskr_type}`,
              displayName: act.indskr_type == 100000001 ? this.translate.instant('OPT_OUT') : this.translate.instant('OPT_IN'),
              isSelected: false,
            };
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'Type');
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == `Type_${act.indskr_type}`);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let typeView = {
                id: 'Type',
                parentId: ActivityType.ConsentActivity,
                displayName: this.translate.instant('CONSENTS') + ' - ' + this.translate.instant('TYPE'),
                isMultiSelect: true,
                options: []
              }
              typeView.options.push(option);
              this.contactTimelineFilterFormView.push(typeView);
            }
          }
        });
        // let sourceIdx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'Source')
        // if (sourceIdx > -1) {
        //   this.contactTimelineFilterFormView[sourceIdx].options.push({
        //     group: this.translate.instant('SOURCE'),
        //     id: `Source_`,
        //     displayName: 'Omnipresence Go',
        //     isSelected: false,
        //   })
        // } else {
        //   this.contactTimelineFilterFormView.push(
        //     {
        //       id: 'Source',
        //       parentId: ActivityType.ConsentActivity,
        //       displayName: this.translate.instant('CONSENTS') + ' - ' + this.translate.instant('SOURCE'),
        //       isMultiSelect: true,
        //       options: [{
        //         group: this.translate.instant('SOURCE'),
        //         id: `Source_`,
        //         displayName: 'Omnipresence Go',
        //         isSelected: false,
        //       }]
        //     }
        //   )
        // }
      }
      /**************************Customer Journey**************************/
      if (this._allTimelineActivities.some(activity => (activity.type == ActivityType.CustomerJourneyAppointment))) {
        filterFormViewMain.options.push({ id: 'customerJourney-meeting-main', group: ActivityType.CustomerJourneyAppointment, isSelected: false, displayName: `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('MEETINGS')}` });
        let appointmentActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.CustomerJourneyAppointment);
        appointmentActs.forEach((act) => {
          //status
          if (!_.isEmpty(act.statusString)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'customerJourney-appointment-status');
            let option = {
              group: this.translate.instant('STATUS'),
              id: ActivityType.CustomerJourneyAppointment + '_' + act.statusString,
              displayName: act.statusString,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CustomerJourneyAppointment + '_' + act.statusString);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'customerJourney-appointment-status',
                parentId: ActivityType.CustomerJourneyAppointment,
                displayName: `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('MEETINGS')}` + ' - ' + this.translate.instant('STATUS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
          //address
          if (!_.isEmpty(act.location)) {
            const formattedAddressId = act.location != 'No Location' ? ActivityType.CustomerJourneyAppointment + '_' + act.location : ActivityType.CustomerJourneyAppointment + '_' + 'noAddress';
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'customerJourney-appointment-address');
            let option = {
              group: this.translate.instant('ADDRESS'),
              id: formattedAddressId,
              displayName: act.location,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == formattedAddressId);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'customerJourney-appointment-address',
                parentId: ActivityType.CustomerJourneyAppointment,
                displayName: `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('MEETINGS')}` + ' - ' + this.translate.instant('ADDRESS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
        });
      }

      if (this._allTimelineActivities.some(activity => (activity.type == ActivityType.CustomerJourneyPhoneCall))) {
        filterFormViewMain.options.push({ id: 'customerJourney-phoneCall-main', group: ActivityType.CustomerJourneyPhoneCall, isSelected: false, displayName: `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('PHONE_CALLS')}` });
        let phoneCallActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.CustomerJourneyPhoneCall);
        phoneCallActs.forEach((act) => {
          //phone number
          if (!_.isEmpty(act.phonecallphonenumber)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'customerJourney-phoneCall-phoneNumber');
            let option = {
              group: this.translate.instant('PHONE_NUMBER'),
              id: ActivityType.CustomerJourneyPhoneCall + '_' + act.phonecallphonenumber,
              displayName: act.phonecallphonenumber,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CustomerJourneyPhoneCall + '_' + act.phonecallphonenumber);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'customerJourney-phoneCall-phoneNumber',
                parentId: ActivityType.CustomerJourneyPhoneCall,
                displayName: `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('PHONE_CALLS')}` + ' - ' + this.translate.instant('PHONE_NUMBER'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
        });
      }

      if (this._allTimelineActivities.some(activity => (activity.type == ActivityType.CustomerJourneyEmail))) {
        filterFormViewMain.options.push({ id: 'customerJourney-email-main', group: ActivityType.CustomerJourneyEmail, isSelected: false, displayName: `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('EMAILS')}` });
        let emailActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.CustomerJourneyEmail);
        emailActs.forEach((act) => {
          //email delivered
          if (!_.isEmpty(act.email_delivered)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'customerJourney-email-emailDelivered');
            let option = {
              group: 'Email Delivered',
              id: ActivityType.CustomerJourneyEmail + '_' + act.email_delivered,
              displayName: act.email_delivered,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CustomerJourneyEmail + '_' + act.email_delivered);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'customerJourney-email-emailDelivered',
                parentId: ActivityType.CustomerJourneyEmail,
                displayName: `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('EMAILS')}` + ' - ' + 'Email Delivered',
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
        });
      }
      /**************************Customer Journey**************************/
      if (this.authenticationService.user.customNotifications && !_.isEmpty(this.contactService.contactInformation.websiteAccessLogs)) {
        filterFormViewMain.options.push({ id: 'WebsiteAccessLogs-main', group: ActivityType.WebsiteAccess, isSelected: false, displayName: this.translate.instant('WEBSITE_ACCESS_LOGS') });
        let webSiteAccessActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.WebsiteAccess);
        webSiteAccessActs.forEach((act) => {
          //channels
          if (!_.isEmpty(act.channel)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'websiteAccessLogs-channels');
            let option = {
              group: this.translate.instant('CHANNELS'),
              id: ActivityType.WebsiteAccess + '_' + act.channel,
              displayName: act.channel,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.WebsiteAccess + '_' + act.channel);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'websiteAccessLogs-channels',
                parentId: ActivityType.WebsiteAccess,
                displayName: this.translate.instant('WEBSITE_ACCESS_LOGS') + ' - ' + this.translate.instant('CHANNELS'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
          //department
          if (!_.isEmpty(act.department)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'websiteAccessLogs-department');
            let option = {
              group: this.translate.instant('DEPARTMENT'),
              id: ActivityType.WebsiteAccess + '_' + act.department,
              displayName: act.department,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.WebsiteAccess + '_' + act.department);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'websiteAccessLogs-department',
                parentId: ActivityType.WebsiteAccess,
                displayName: this.translate.instant('WEBSITE_ACCESS_LOGS') + ' - ' + this.translate.instant('DEPARTMENT'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
        });
      }
      if (this._allTimelineActivities.some(activity => (activity.type == ActivityType.CustomerSurvey))) {
        filterFormViewMain.options.push({ id: 'customerSurvey-main', group: ActivityType.CustomerSurvey, isSelected: false, displayName: this.translate.instant('SURVEY') });
        let surveyActs = this._allTimelineActivities.filter(activity => activity.type == ActivityType.CustomerSurvey);
        surveyActs.forEach((act) => {
          //Survey type
          if (!_.isEmpty(act.type)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'customerSurvey-surveyType');
            let option = {
              group: this.translate.instant('SURVEY_TYPE'),
              id: ActivityType.CustomerSurvey + '_' + act.type,
              displayName: act.type,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CustomerSurvey + '_' + act.type);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'customerSurvey-surveyType',
                parentId: ActivityType.CustomerSurvey,
                displayName: this.translate.instant('SURVEY') + ' - ' + this.translate.instant('SURVEY_TYPE'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
          //Type
          if (!_.isEmpty(act.surveyFrequency)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'customerSurvey-surveyFrequency');
            let option = {
              group: this.translate.instant('TYPE'),
              id: ActivityType.CustomerSurvey + '_' + act.surveyFrequency,
              displayName: act.surveyFrequency,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CustomerSurvey + '_' + act.surveyFrequency);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'customerSurvey-surveyFrequency',
                parentId: ActivityType.CustomerSurvey,
                displayName: this.translate.instant('SURVEY') + ' - ' + this.translate.instant('TYPE'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
          //Surveyed By
          if (!_.isEmpty(act.surveyedBy)) {
            let idx = this.contactTimelineFilterFormView.findIndex((view) => view.id == 'customerSurvey-surveyedBy');
            let option = {
              group: this.translate.instant('SURVEYED_BY'),
              id: ActivityType.CustomerSurvey + '_' + act.surveyedBy,
              displayName: act.surveyedBy,
              isSelected: false,
            }
            if (idx > -1) {
              let optionIdx = this.contactTimelineFilterFormView[idx].options.findIndex((opt) => opt.id == ActivityType.CustomerSurvey + '_' + act.surveyedBy);
              if (optionIdx < 0) this.contactTimelineFilterFormView[idx].options.push(option);
            } else {
              let viewData = {
                id: 'customerSurvey-surveyedBy',
                parentId: ActivityType.CustomerSurvey,
                displayName: this.translate.instant('SURVEY') + ' - ' + this.translate.instant('SURVEYED_BY'),
                isMultiSelect: true,
                options: []
              }
              viewData.options.push(option);
              this.contactTimelineFilterFormView.push(viewData);
            }
          }
        });
      }
    }
  }

  public clearFilterResults() {
    this.isTimelineFiltered = false;
    this.selectedFilters = [];
    this._filteredTimelineActivities = this._allTimelineActivities;
    if (this.uiService.isConsentFromToolDrawer) {
      this.sampleService.contactTimelineFilter = ActivityType.ConsentActivity;
      this.contactTimelineFilter = this.sampleService.contactTimelineFilter;
    }
    this.selectedDate = { startDate: '', endDate: '' };
    this.onTimelineTypeChange(this.sampleService.contactTimelineFilter);
    this._initFilterFormViews();
  }

  getSelectedText(value) {
    const tot = this._filteredTimelineViewActivities.length;
    if((this.filterButtonBadgeCount && this.filterButtonBadgeCount > 0) || (this.searchInput && this.searchInput.length >= 2)){
      return this.translate.instant('ACCOUNT_RESULTS') + ` ` + `(${tot})`;
    }
    switch (value) {
      case 'All_Activities':
        return this.translate.instant('ALL_ACTIVITIES') + ` ` + `(${tot})`;
      case 'MY_ACTIVITIES':
        let myActivitiesText = this.translate.instant('MY_ACTIVITIES');
        if (this.translate.currentLang == 'it') {
          myActivitiesText = this.translate.instant('MY_ACTIVITIES_TIMELINE');
        }
        return myActivitiesText + ` ` + `(${tot})`;
      case 'Appointment':
        let meetingsText = this.translate.instant('MEETINGS');
        if (this.translate.currentLang == 'it') {
          meetingsText = this.translate.instant('MEETINGS_TIMELINE');
        }
        return meetingsText + ` ` + `(${tot})`;
      case 'Email':
        return this.translate.instant('MESSAGES') + ` ` + `(${tot})`;
      case 'Allocation_Order':
        return this.translate.instant('ALLOWCATION_ORDERS') + ` ` + `(${tot})`;
      case 'Customer_Inquiry':
        return this.translate.instant('CUSTOMERINQUIRIES') + ` ` + `(${tot})`;
      case 'Contact_Event':
        return this.translate.instant('EVENTS') + ` ` + `(${tot})`;
      case 'Live_Meet':
        return this.translate.instant('LIVE_MEETING') + ` ` + `(${tot})`;
      case ActivityType.PhoneCall:
        return this.translate.instant('PHONE_CALLS') + ` ` + `(${tot})`;
      case ActivityType.SurgeryOrder:
        return `${this.translate.instant('PROCEDURE_LOGS')} (${tot})`;
      case ActivityType.ConsentActivity:
        return `${this.translate.instant('CONSENTS')} (${tot})`;
      case ActivityType.CustomerSurvey:
        return `${this.translate.instant('SURVEY')} (${tot})`;
      case 'Website_Access_Logs':
        return `${this.translate.instant('WEBSITE_ACCESS_LOGS')} (${tot})`;
      case ActivityType.CustomerJourneyAppointment:
        return `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('MEETINGS')} (${tot})`;
      case ActivityType.CustomerJourneyPhoneCall:
        return `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('PHONE_CALLS')} (${tot})`;
      case ActivityType.CustomerJourneyEmail:
        return `${this.translate.instant('CUSTOMER_JOURNEY')} - ${this.translate.instant('EMAILS')} (${tot})`;
      default:
        return this.translate.instant('ALL_ACTIVITIES') + ` ` + `(${tot})`;
    }
  }

  async displayActivityDetails(activity: Activity) {
    let foundActivity = this.activityService.getActivityByID(activity.ID);
    if (activity.type == ActivityType.Appointment) {
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
      this.activityService.selected = (foundActivity) ? foundActivity : activity;
      this.uiService.showNewActivity = false;
      this.activityService.prevSelectedActivityOnTimeline = _.cloneDeep(this.activityService.selectedActivity);
      this.uiService.prevView = 'contactDetails';
      this.uiService.showCancelDoneOnActivityDetails = false;
      await this.activityDataService.updateActivityDetails(foundActivity ? foundActivity : activity);
      this.uiService.activeView = 'ContactPageRightPaneNav';
      if (!_.isEmpty(this.contactService.contactInformation)) {
        this.contactService.selectedContactOnTimeline = this.contactService.contactInformation;
      }
      //meeting owner position check to determin read-only
      if (activity.meetingOwnerId == this.authenticationService.user.systemUserID) {
        this.activityService.selectedActivity.isDiffPosition = false;
      } else {
        if (activity.state != MeetingActivityState.Completed) {
          let allPositionByOwner: any[] = [];
          if (!this.device.isOffline) {
            allPositionByOwner = await this.contactService.getAllPositionByOwnerId(activity.meetingOwnerId);
          } else {
            allPositionByOwner = activity.meetingOwnerPositionIds;
          }
          const userPosition = this.authenticationService.user.positions;
          if (userPosition && userPosition.length > 0 && allPositionByOwner && allPositionByOwner.length > 0) {
            this.activityService.selectedActivity.isDiffPosition = true;
            userPosition.forEach(p => {
              let foundPosition = allPositionByOwner.find(o => o.positionid == p.ID);
              if (foundPosition) this.activityService.selectedActivity.isDiffPosition = false;
            });
          }
          if (this.activityService.selectedActivity.isDiffPosition) {
            console.log("The current meeting owner's position is different");
          } else {
            console.log("The current meeting owner is in the same position");
          }
        }
      }
      this.events.publish('activities:appConfigFields');
      if (this.activityService.selectedActivity) {
        if (this.navService.getCurrentMasterPageName() === PageName.ScientificActivityPage) {
          await this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ContactPageComponent, { from: 'ScientificActivityPage', childFrom: PageName.ContactDetailsComponent });
        }
        else if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan === 'scientificPlans') {
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
          await this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ContactPageComponent, { from: 'ScientificActivityPage', childFrom: PageName.ContactDetailsComponent });
        } else {
          await this.navService.pushChildNavPageWithPageTracking(ActivitiesDetailsPaneComponent, PageName.ActivitiesDetailsPaneComponent, PageName.ContactPageComponent, { from: PageName.ContactTimelineComponent });
        }
        this.events.publish('detectChangesOnActivityDetails', true); // To triger angular change detection manually
        this.activityService.selectedActivity.accessedFrom = PageName.ContactTimelineComponent;
        this.footerService.initButtons(FooterViews.Activities);
      }
    } else if (activity.type == ActivityType.Sample) {
      if (foundActivity) {
        this.activityService.selected = foundActivity;
      } else {
        await this.activityDataService.updateActivityDetails(activity);
      }
      this.sampleService.samplingDetailsViewMode = SamplingDetailsViewMode.VIEW_DETAILS;
      this.uiService.showNewActivity = false;
      this.uiService.activeView = 'Sample';
      this.uiService.prevView = 'contactDetails';
      if (this.navService.getCurrentMasterPageName() === PageName.ScientificActivityPage) {
        this.uiService.showCancelDoneOnActivityDetails = false;
      } else if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan === 'scientificPlans') {
        this.uiService.showCancelDoneOnActivityDetails = false;
        this.uiService.activeView = 'ActivitiesPageRightPaneNav';
      }
      this.navService.pushChildNavPageWithPageTracking(NewSampleActivityComponent, PageName.NewSampleActivityComponent, PageName.ContactPageComponent, { from: PageName.ContactDetailsComponent });
    } else if (activity.type == ActivityType.Email) {
      if (this.device.isOffline) {
        this.activityService.selectedActivity = (foundActivity) ? foundActivity : activity;
      }
      await this.activityDataService.updateActivityDetails(foundActivity ? foundActivity : activity).then(() => {
        this.emailService.viewType = EmailViewType.FROM_CONTACT_TIMELINE;
        this.uiService.showNewActivity = false;
        this.emailService.setCurrentEmail(this.emailService.selectedActivity);
        if (this.navService.getCurrentMasterPageName() !== PageName.ContactPageComponent) {
          this.uiService.activeView = 'Email';
        }
        this.uiService.prevView = 'contactDetails';
        if (this.navService.getCurrentMasterPageName() === PageName.ScientificActivityPage) {
          this.uiService.showCancelDoneOnActivityDetails = false;
        }
        if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan === 'scientificPlans') {
          this.uiService.showCancelDoneOnActivityDetails = false;
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        }
        if (!isValid(this.emailService.selectedActivity.scheduledStart) || !isValid(this.emailService.selectedActivity.scheduledEnd)) {
          // OMNI-27162: Currently contact timeline activities fetch service is returning activities created on dynamics for customer enquiry
          // Those activities doesn't have any scheduled start and end time attribute value so added this check to prevent the UI to break
          // In future it will be handled on service end to omit those activities from response
          return;
        }
        this.navService.pushChildNavPageWithPageTracking(EmailActivityDetailComponent, PageName.EmailDetailsPageComponent, PageName.ContactPageComponent, { from: PageName.ContactDetailsComponent });

      }).catch(() => {
        console.log("Failed to load email activity:" + activity.ID);
      });
    } else if (activity.type == ActivityType.CaseIntake) {
      const loader = await this.uiService.displayLoader();
      await this.caseDataService.findCaseById(activity.ID).then(
        async res => {
          if (res) {
            for (let key in res) {
              if (key.charAt(0) === "_") {
                var a = key.substring(1, key.length);
                res[a] = res[key];
                delete res[key];
              }
            }
            let iCase: CaseActivity;
            iCase = new CaseActivity(res);
            iCase = await this.caseService.reInitDataDependency(iCase);
            this.activityService.selectedActivity = iCase;
            this.caseService.assignSelectedCase(iCase);
            this.caseService.accessedFrom = AccesingMode.CONTACT_TIMELINE;
            this.uiService.showNewActivity = false;
            this.uiService.activeView = 'ContactPageRightPaneNav';
            this.uiService.prevView = 'contactDetails';
            if (this.navService.getCurrentMasterPageName() === PageName.ScientificActivityPage) {
              this.uiService.showCancelDoneOnActivityDetails = false;
            }
            else if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan === 'scientificPlans') {
              this.uiService.showCancelDoneOnActivityDetails = false;
              this.uiService.activeView = 'ActivitiesPageRightPaneNav';
            }
            this.navService.pushChildNavPageWithPageTracking(CaseManagementDetailComponent, PageName.CaseManagementDetailComponent, PageName.ContactPageComponent, { from: PageName.ContactDetailsComponent });
          }
          this.uiService.dismissLoader();
        }).catch(() => {
          console.log("Failed to load customer inquiry:" + activity.ID);
          this.uiService.dismissLoader();
        });
    } else if (activity.type == ActivityType.PhoneCall) {
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
      // await this.activityDataService.updateActivityDetails(foundActivity ? foundActivity : activity);
      await this.uiService.displayLoader();
      if (!this.device.isOffline) {
        await this.activityDataService.getPhonecallActivityByIdOnline(activity.ID, activity.scheduledStart.getTime().toString(), activity.scheduledEnd.getTime().toString(), activity.scheduledStart.getTime().toString()).then(async (response) => {
          activity = new PhoneActivity(response);
          await this.activityDataService.updateActivityForConfigField(activity, response, ActivityType.PhoneCall);
          await this.activityDataService._appendPhoneCallDetailsToActivity(activity, response, false)
          this.activityDataService.activityDetailsLoaded = true;
        })
      } else {
        const activityDoc = await this.activityDataService.getOfflineActivityDocByKey(DB_KEY_PREFIXES.PHONE_CALL_ACTIVITY + activity.ID);
        if (activityDoc) {
          activity = new PhoneActivity(activityDoc);
          await this.activityDataService.updateActivityForConfigField(activity, activityDoc, ActivityType.PhoneCall);
          await this.activityDataService._appendPhoneCallDetailsToActivity(activity, activityDoc);
          this.activityDataService.activityDetailsLoaded = true;
        }
      }
      //In case of date value as string type, need to set date type. it happened in offline mode
      if (!_.isEmpty(activity.scheduledStart)) activity.scheduledStart = new Date(activity.scheduledStart);
      if (!_.isEmpty(activity.scheduledEnd)) activity.scheduledEnd = new Date(activity.scheduledEnd);

      if (!isValid(activity.scheduledStart) || !isValid(activity.scheduledEnd)) {
        // OMNI-27162: Currently contact timeline activities fetch service is returning activities created on dynamics for customer enquiry
        // Those activities doesn't have any scheduled start and end time attribute value so added this check to prevent the UI to break
        // In future it will be handled on service end to omit those activities from response
        await this.uiService.dismissLoader();
        return;
      }
      await this.uiService.dismissLoader();
      this.activityService.selected = activity;
      this.uiService.activeView = 'phoneCallTimelineDetails';
      this.uiService.prevView = 'contactDetails';
      if (this.activityService.selectedActivity) {
        if (this.navService.getCurrentMasterPageName() === PageName.ScientificActivityPage) {
          this.uiService.showCancelDoneOnActivityDetails = false;
        }
        if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan === 'scientificPlans') {
          this.uiService.showCancelDoneOnActivityDetails = false;
          this.uiService.activeView = 'ActivitiesPageRightPaneNav';
        }
        this.navService.pushChildNavPageWithPageTracking(PhoneCallDetailsComponent, PageName.PhoneCallDetailsComponent, PageName.ContactPageComponent, { from: PageName.ContactDetailsComponent });
        this.footerService.initButtons(FooterViews.PHONE_CALL);
      }
    } else if (activity.type == ActivityType.SurgeryOrder) {
      let foundActivity = this.activityService.getActivityByID(activity.ID);
      if (foundActivity) {
        this.activityService.selectedActivity = foundActivity;
      } else {
        this.uiService.displayLoader();
        foundActivity = await this.activityDataService.getRealTimeActivityDetails(activity);
        this.uiService.dismissLoader();
      }
      this.activityService.selectedActivity = (foundActivity) ? foundActivity : activity;
      this.uiService.activeView = 'ContactPageRightPaneNav';
      this.uiService.showNewActivity = false;
      this.navService.pushChildNavPageWithPageTracking(SurgeryOrderDetailsComponent, PageName.SurgeryOrderDetailsComponent, PageName.ContactPageComponent, { from: 'ContactTimeline', activity: this.activityService.selectedActivity });
      this.footerService.initButtons(FooterViews.SURGERY_ORDER_ACTIVITY);
    } else if (activity.type == ActivityType.Event || activity instanceof EventRegistration || activity instanceof EventActivity) {
      if (activity instanceof EventRegistration) {
        activity.ID = activity.eventId;
      }
      const eventsToolData = this.eventsToolService.eventsToolData;
      const foundEvent = eventsToolData.find(e => {
        if (e.ID) {
          return e.ID === activity.ID;
        } else if (e.offlineId) {
          return e.offlineId === activity.ID;
        } else {
          return false;
        }
      });
      if (!_.isEmpty(foundEvent)) {
        activity = foundEvent;
        this.eventsToolService.selectedEventOnEventsTool = foundEvent;
      } else {
        return;
      }
      if (!this.device.isOffline) {
        this.uiService.displayLoader();
        await this.eventsToolDataService.fetchEventRealTimeDetails(activity as EventActivity).then((updatedEvent: EventActivity) => {
          activity = updatedEvent;
          this.uiService.dismissLoader();
        }).catch(err => {
          console.log(err);
          this.uiService.dismissLoader();
        });
      }
      this.activityService.prevSelectedActivity = this.activityService.selectedActivity;
      this.activityService.selectedActivity = activity;
      this.navService.pushChildNavPageWithPageTracking(EventDetailsComponent, PageName.EventDetailsPageComponent, PageName.ContactPageComponent, {
        from: 'ContactTimeline', event: this.activityService.selectedActivity,
      });
      this.eventsToolService.setSelectedEventUpdates(this.activityService.selectedActivity as EventActivity);
      this.footerService.initButtons(FooterViews.EVENTS_DETAILS);
    } else if (activity.type == ActivityType.CustomerSurvey) {
      if (this.device.isOffline) {
        this.notificationService.notify(this.translate.instant("SURVEY_ACTIVITIES_NOTIFY_ONLINE"), "survey-activities", "top", ToastStyle.DANGER, 3000, true);
        return;
      }
      if (!_.isEmpty(activity)) {
        this.openSubmittedSurveyDetails(activity);
      }
    }
  }

  public async openSubmittedSurveyDetails(survey: any) {
    if (this.device.isOffline) {
      this.notificationService.notify(this.translate.instant("SURVEY_ACTIVITIES_NOTIFY_ONLINE"), "contact-timeline", "top", ToastStyle.DANGER, 3000, true);
      return;
    }
    //check selected survey tempalte id
    const filteredSurveyList = this.customerAssessService.surveytemplates.filter(template => template.indskr_type == TemplateType.SURVEY);
    const templateId: string = survey['templateId'];
    const foundTemplate: AssessmentTemplate = filteredSurveyList.find(survey => survey.indskr_assessmenttemplateid == templateId);
    this.customerSurveyService.setCurrentSurvey(foundTemplate);
    try {
      await this.navService.pushChildNavPageWithPageTracking(SurveyDetailsComponent, PageName.SurveyDetailsComponent, PageName.ContactDetailsComponent, { appointmentId: '', from: PageName.ContactDetailsComponent }).then(async () => {
        this.navService.setChildNavRightPaneView(true);
        this.uiService.dismissLoader();
        this.customerSurveyService.selectedContactForSurvey = this.contactService.contactInformation;
        try {
          //map survey based on survey responses
          this.uiService.displayLoader();
          const responseData = await this.customerAssessService.fetchContactSurveyForDetails(survey, 'contact');
          if (!_.isEmpty(responseData)) {
            const formattedSurvey: CustomerAssessment = await this.customerAssessService.mapCustomerSurveyRespForTimeline(responseData);
            if (!_.isEmpty(formattedSurvey)) {
              this.customerSurveyService.setSurveyPreviewMode(true, formattedSurvey, 'timeline');
            }
          }
          this.uiService.dismissLoader();
        } catch (error) {
          console.log("Error open survey details from surve-timeline ", error);
          this.uiService.dismissLoader();
        }
      });
    } catch (error) {
      console.log("failed to open survey ", error);
      this.uiService.dismissLoader();
    }
  }

  onFooterButtonClicked(ev){
    console.log(ev)
  }

  private _matchedProduct(activityType: ActivityType, category: any[], act: any): boolean {
    let isMatchedData: boolean = false;
    isMatchedData = category.some(item => {
      let isFoundProduct: boolean = false;
      if(!_.isEmpty(act.products)) {
        act.products.forEach(product => {
          const temId: string = product.productId || product.ID;
          const formattedId = activityType + '_' + temId;
          if(item.id == formattedId) return isFoundProduct = true;
        });
      }
      return isFoundProduct
    })
    return isMatchedData;
  }
}
