<ion-header>
  <ind-page-title *ngIf="setBookingDetailsPageTitle" [viewData]='setBookingDetailsPageTitle' (onControlClick)='onButtonClicked($event)'></ind-page-title>
</ion-header>
<ion-content no-padding>
  <ind-display-form #scrollTop [formMetadata]='displayFormDetails' [linkedEntityDBPrefix]='setBookingLinkedEntityDBPrefix' [rawData]='rawFormData' [referencingEntityName]='"indskr_setbookingid"' [dynamicFormType]='dynamicFormType' [isEditEnabled]="false" [isEditDisabled]="true"
    [displayFormAccessedFrom]="'setBookingDetails'" [isTeamKitBooking]="currentSetBookingActivity?.isTeamKitBooking">
  </ind-display-form>
  <ind-section-header [viewData]='kitBookingActivitiesHeader' (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
  <ion-item [ngClass]="!isActivitiesSectionExpanded ? 'collapsed-item': 'placeholderEmptyDiv'" *ngIf="!activitiesDataModel || activitiesDataModel.length==0 || !isActivitiesSectionExpanded"></ion-item>
  <ng-container *ngIf="isActivitiesSectionExpanded">
    <main-card *ngFor="let activity of activitiesDataModel" [viewData]="activity"></main-card>
  </ng-container>
  <ng-container *ngIf="notesSectionHeaderViewData">
    <ind-section-header [viewData]="notesSectionHeaderViewData" (onControlClick)='onSectionHeaderControlClick($event)'></ind-section-header>
    <ind-text-area id="notesInput" [ngClass]="{'hide-notes': !isKitBookingNotesEnabled}" [disabled]="currentSetBookingActivity?.isTeamKitBooking" [placeHolder]="notesPlaceholder" [showAttachment]="isAttachmentAdded" [attachmentTitle]="attachmentTitle" [maxLength]="1000" (indChange)="notesChanged($event)" [value]="tempNoteText"
        (removeAttachment)="removeAttachment($event)" [rows]="4" [cols]="10"> </ind-text-area>
    <input class="hide-input" #attachInput type="file" (change)="loadImageFromDevice($event)">
    <io-note *ngFor="let note of currentKitBookingNotes" [isNoteAttachmentEnabled]="isKitBookingNotesAttachmentEnabled || isKitBookingNotesEnabled" [inputNote]="note" [editable]="isNoteEditable(note)" [readOnlyNote]="!isKitBookingNotesEnabled" [readOnlyAttachment]="!isKitBookingNotesAttachmentEnabled"
        [deletable]="isNoteDeletable(note)" (saveNoteOut)="updateNote($event)" [disableDownloadInOffline]="true" [from]="uiService.page.ContactDetailsComponent"></io-note>
  </ng-container>
</ion-content>
<footer-toolbar *ngIf="footerService.shouldShowFooter()" (buttonClicked)="onButtonClicked($event)" [footerView]="'detailView'" [selectedView]="'SetBooking'"></footer-toolbar>