<ion-header>
  <ind-page-title [viewData]="procedureContractPageTitle"
    (onControlClick)='onPageTitleControlClick($event)'></ind-page-title>
</ion-header>

<ion-content>
  <ion-list>
    <!-- contract details section -->
    <ind-section-header [viewData]="contractDetailsHeader"></ind-section-header>
    <div class="wrapper">
      <ion-row>
        <ion-col size="6" size-sm="4" *ngFor="let formField of groupedFields['Contract Details']">
          <ind-form-field [viewData]="formField"></ind-form-field>
        </ion-col>
      </ion-row>

      <!-- Procedure details section -->
      <ind-section-header [viewData]="procedureDeatialsHeader"></ind-section-header>
      <ion-row>
        <ion-col size="6" size-sm="4" *ngFor="let formField of groupedFields['Procedure Details']">
          <ind-form-field [viewData]="formField"></ind-form-field>
        </ion-col>
      </ion-row>
    </div>

    <!-- child contract section -->

    <ind-section-header [viewData]="childContractHeader" (onControlClick)='onChildContractHeaderClick($event)'
      *ngIf="procedureContract && shouldShowChildContractSection && procedureContract.indskr_contracttypes != null">
    </ind-section-header>

    <ion-item class="placeholderEmptyDiv"
      *ngIf="(!displayChildContracts || displayChildContracts.length==0) && shouldShowChildContractSection">
    </ion-item>

    <ng-container *ngIf="displayChildContracts.length > 0">
      <div class="wrapper" *ngFor="let childContract of displayChildContracts;let i=index"
        (click)="viewChildContract(i)">
        <div class="primary-content">
          <ion-text class="primary-text">{{childContract.headerText}}</ion-text>
          <ion-button fill="clear" slot="end" class="end-button">
            <ion-icon slot="icon-only" name="chevron-forward-outline" class="end-icon"></ion-icon>
          </ion-button>
          <ion-row class="bordered-col">
            <ion-col size="6" size-md="6" *ngFor="let item of childContract.values">
              <display-value [inputData]="item"></display-value>
            </ion-col>
          </ion-row>

        </div>
      </div>
    </ng-container>

    <!-- pre signature section -->

    <ng-container *ngIf="procedureContract.statuscode !== 548910000 && shouldShowPresurgerySection">
      <ind-section-header [viewData]="preSignatureHeader"></ind-section-header>
      <div>
        <ion-row class="ion-padding-bottom">
          <ion-col size="6" size-sm="6">
            <ind-form-field [viewData]="preSignModeField"></ind-form-field>
          </ion-col>
          
          <ion-col size="6" size-sm="6" class="upload-col">
            <ion-button fill="clear" (click)="uploadButtonClicked('presign')"
              *ngIf="procedureContract.indskr_signaturecapturemode == 548910000 && isPreSectionMandatoryFieldsFilled"
              [ngClass]="{ 'disabled' : shouldEnableUploadButton('presign') }">
              <ion-icon slot="start" src="assets/imgs/omni_upload_blue.svg"></ion-icon>
              {{ ('UPLOAD') | translate }}
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row class="ion-padding-bottom">
          <ion-col size="6" size-sm="6" *ngFor="let formField of groupedFields['Pre Surgery Signature']">
            <ind-form-field [viewData]="formField"></ind-form-field>
          </ion-col>
        </ion-row>

        <div *ngIf="presurgerytext" class="ion-padding-top">
          <p class="presurgery-text" *ngFor="let text of presurgerytext">
            {{ text }}
          </p>
        </div>

        <ion-item class="accordian-expanded" style="padding-top: 16px;"
          *ngIf="procedureContract.indskr_signaturecapturemode == 548910001">
          <div class="signatureImageContainer" *ngIf="!procedureContract.indskr_presurgerysignature">
            <img [src]="'assets/imgs/omni-sign-icon.svg'" class="redSignFlag" alt="pre signature">
            <div class="signatureWrapper ion-text-center">
              <p text-center [ngClass]="{ 'disabled' : procedureContract.statecode == 1 }"
                (click)="launchSignaturePad('preSignature')">{{('CONSENT_TAP_TO_SIGN') | translate}}</p>
            </div>
          </div>
          <div class="signatureImageContainer" *ngIf=" procedureContract.indskr_presurgerysignature">
            <img [src]="'assets/imgs/omni-sign-icon.svg'" class="redSignFlag" alt="pre signature">
            <div class="capturedImage">
              <img [src]="procedureContract.indskr_presurgerysignature" alt="signature">
            </div>
          </div>
        </ion-item>

        <ion-row class="ion-padding-bottom">
          <ion-col size="12" size-md="6">
            <ind-form-field [viewData]="customerFormField"></ind-form-field>
          </ion-col>
          <ion-col size="12" size-md="6">
            <ind-form-field [viewData]="preValidatorFormField"></ind-form-field>
          </ion-col>
          <ion-col size="12" size-md="6">
            <ind-form-field [viewData]="preSurgeryDateField"></ind-form-field>
          </ion-col>
        </ion-row>

        <div class="ion-margin-top">
          <ion-buttons slot="primary" *ngIf="procedureContract.indskr_presurgerysignaturedocument">
            <ion-button fill="outline" class="SignedDocumentButton" (click)="downloadContracts('presign')">
              <ion-title class="SignedDocumenTitle">{{ getSignatureFileName('Presign') }}.pdf</ion-title>
              <ion-icon slot="end" class="SignedDocumenIcon" src="assets/imgs/omni_download_blue.svg"></ion-icon>
            </ion-button>
          </ion-buttons>
        </div>
      </div>
    </ng-container>

    <!-- procedure contract convered -->

    <ind-section-header [viewData]="procedureCoveredHeader"
      *ngIf="isProcedureSectionVisible && procedureContract.statuscode !== 548910000"></ind-section-header>
    <ng-container *ngIf="isProcedureSectionVisible && procedureContract.statuscode !== 548910000">
      <ion-item class="placeholderEmptyDiv"
        *ngIf="!procedureLogItems || procedureLogItems.length==0 && isProcedureSectionVisible"></ion-item>
      <div class="wrapper" *ngFor="let procedurelog of procedureLogItems ">
        <div class="primary-content">
          <div>
            <p class="primary-text">{{procedurelog.headerText}}
            </p>
          </div>
          <div>
            <ion-row class="bordered-col">
              <ion-col size="6" size-md="4" *ngFor="let item of procedurelog.values; let i=index"
                [ngClass]="{'ion-hide-sm-down' : i > 3}">
                <display-value [inputData]="item"></display-value>
              </ion-col>
            </ion-row>
          </div>
        </div>
      </div>

      <ion-row>
        <ion-col size="6" size-sm="6" *ngFor="let formField of groupedFields['Procedure Covered in This Contract']">
          <ind-form-field [viewData]="formField"></ind-form-field>
        </ion-col>
      </ion-row>
    </ng-container>

    <!-- Post surgery signature -->

    <ng-container *ngIf="shouldShowPostsurgerySection && procedureContract.statuscode !== 548910000">
      <ind-section-header [viewData]="postSignatureHeader"></ind-section-header>

      <ion-row class="ion-padding-bottom">
        <ion-col size="6" size-sm="6">
          <ind-form-field [viewData]="postSignModeField"></ind-form-field>
        </ion-col>
        <ion-col size="6" size-sm="6" class="upload-col">
          <ion-button fill="clear" (click)="uploadButtonClicked('postsign')"
            *ngIf="procedureContract.indskr_postsignaturecapturemode == 548910000 && isPostSectionMandatoryFieldsFilled"
            [ngClass]="{ 'disabled' : shouldEnableUploadButton('postsign') }">
            <ion-icon slot="start" src="assets/imgs/omni_upload_blue.svg"></ion-icon>
            {{ ('UPLOAD') | translate }}
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="6" size-sm="6" *ngFor="let formField of groupedFields['Post Surgery Signature']">
          <ind-form-field [viewData]="formField"></ind-form-field>
        </ion-col>
      </ion-row>

      <div *ngIf="postsurgerytext" class="ion-padding-top">
        <p class="presurgery-text" *ngFor="let text of postsurgerytext">
          {{ text }}
        </p>
      </div>
     
      <ion-item class="accordian-expanded" style="padding-top: 16px;"
        *ngIf="procedureContract.indskr_postsignaturecapturemode == 548910001">
        <div class="signatureImageContainer" *ngIf="!procedureContract.indskr_postsurgerysignature">
          <img [src]="'assets/imgs/omni-sign-icon.svg'" class="redSignFlag" alt="post signature">
          <div class="signatureWrapper ion-text-center">
            <p text-center
              [ngClass]="{ 'disabled' : procedureContract.statuscode === 548910008 || procedureContract.statuscode === 548910005 
              || (contractType && contractType.indskr_presignature && !procedureContract.indskr_presurgerysignaturecaptured)}"
              (click)="launchSignaturePad('postSignature')">{{('CONSENT_TAP_TO_SIGN') | translate}}</p>
          </div>
        </div>
        <div class="signatureImageContainer" *ngIf="procedureContract.indskr_postsurgerysignature">
          <img [src]="'assets/imgs/omni-sign-icon.svg'" class="redSignFlag" alt="post signature">
          <div class="capturedImage">
            <img [src]="procedureContract.indskr_postsurgerysignature" alt="signature">
          </div>
        </div>
      </ion-item>

      <ion-row>
        <ion-col size="12" size-md="6">
          <ind-form-field [viewData]="postValidatorFormField"></ind-form-field>
        </ion-col>
        <ion-col size="12" size-md="6">
          <ind-form-field [viewData]="postSurgeryDateField"></ind-form-field>
        </ion-col>
      </ion-row>

      <div class="ion-margin-top">
        <ion-buttons slot="primary" *ngIf="procedureContract.indskr_postsurgerysignaturedocument">
          <ion-button fill="outline" class="SignedDocumentButton" (click)="downloadContracts('postsign')">
            <ion-title class="SignedDocumenTitle">{{ getSignatureFileName('Postsign') }}.pdf</ion-title>
            <ion-icon slot="end" class="SignedDocumenIcon" src="assets/imgs/omni_download_blue.svg"></ion-icon>
          </ion-button>
        </ion-buttons>
      </div>
    </ng-container>

  </ion-list>
</ion-content>

<input class="hide-input" #attachInput type="file" (change)="loadContractFromDevice($event)"
  (click)="$event.target.value = ''">
<footer-toolbar *ngIf="footerService.shouldShowFooter()" [footerView]="'detailView'"
  [selectedView]="'procedureContract'" (buttonClicked)="footerButtonClicked($event)"></footer-toolbar>