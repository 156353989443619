import { Component, ElementRef, HostBinding, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ShortCallField, ShortCallFilterField } from '@omni/classes/short-call/short-call.class';
import _ from 'lodash';

const CARD_FULL_HEIGHT_PADDING_AT_TOP = 24;
const CARD_HEADER_FOOTER_HEIGHT_COMBINED = 44 + 64;

@Component({
  selector: 'io-content-filter',
  templateUrl: './io-content-filter.html',
  styleUrls: ['./io-content-filter.scss'],
})
export class IoContentFilter implements OnInit {
  @HostBinding('class.ion-page') pageClass = false;
  @ViewChild('content', { read: ElementRef }) content;
  @ViewChild('contentWrapper', { read: ElementRef }) contentWrapper;
  @Input()
  public tags: ShortCallFilterField[] = [];
  private clonedInputTags: ShortCallFilterField[];
  public confirmDisabled: boolean = true;
  public resetDisabled: boolean = false;
  constructor(
    private modalCtrl: ModalController,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.clonedInputTags = _.cloneDeep(this.tags);
  }

  ngAfterViewInit() {
    setTimeout(async () => {
      // Check for the modal margin & padding
      const modalEl = await this.modalCtrl.getTop();
      const isDynamicModal = modalEl && modalEl.classList.contains('dynamic-height-modal');
      const modalWrapperEl = isDynamicModal ? modalEl.querySelector('.modal-wrapper') : null;
      const parsedParentMarginTop = modalWrapperEl ? parseFloat(window.getComputedStyle(modalWrapperEl, null)?.getPropertyValue('margin-top')) : 0;
      const parentMarginTop = !isNaN(parsedParentMarginTop) ? parsedParentMarginTop : 0;
      const parsedParentPaddingBottom = modalWrapperEl ? parseFloat(window.getComputedStyle(modalWrapperEl, null)?.getPropertyValue('padding-bottom')) : 0;
      const parentPaddingBottom = !isNaN(parsedParentPaddingBottom) ? parsedParentPaddingBottom : 0;

      // Calculate max content div height
      const maxContentHeight = window.innerHeight - CARD_FULL_HEIGHT_PADDING_AT_TOP - CARD_HEADER_FOOTER_HEIGHT_COMBINED - parentMarginTop - parentPaddingBottom;
      const contentHeight = Math.min(maxContentHeight, this.contentWrapper.nativeElement.offsetHeight);
      this.setContentHeight(contentHeight);
    }, 40);
  }


  public closeModal(type: string) {
    if (type == 'reset') {
      this.tags.forEach(p => {
        p.selected = false;
        p.tags.forEach(t => t.selected = false);
      })
    }
    this.modalCtrl.dismiss({ type: type, tags: this.tags, modified: !_.isEqual(this.tags, this.clonedInputTags) });
  }

  public selectParentTag(event: Event, parent: ShortCallField) {
    event.stopPropagation();
    const selectedParent = this.tags.find(field => field.value === parent.value);
    selectedParent.selected = !selectedParent.selected;
    if (selectedParent) {
      selectedParent.tags.forEach(tag => {
        tag.selected = selectedParent.selected;
      });
    }
    this.confirmDisabled = _.isEqual(this.tags, this.clonedInputTags);
  }

  public selectChildTag(event: Event, parent: ShortCallFilterField, child: ShortCallField) {
    event.stopPropagation();
    child.selected = !child.selected;
    parent.selected = parent.tags.filter(t => !t.selected).length == 0;
    this.confirmDisabled = _.isEqual(this.tags, this.clonedInputTags);
  }

  private setContentHeight(height: number) {
    if (height && this.content?.nativeElement) {
      this.renderer.setStyle(
        this.content.nativeElement,
        'height',
        `${height}px`,
      );
    }
  }

}
