<div class="donut-chart-container">
  <svg id="gauge-svg" [attr.height]="diameter" [attr.width]="diameter">
    <circle
      [attr.cx]="center"
      [attr.cy]="center"
      [attr.r]="radius"
      [attr.stroke]="backgroundColor"
      [attr.stroke-width]="strokeWidth"
      fill="none"
    ></circle>
    <circle
      [attr.cx]="center"
      [attr.cy]="center"
      [attr.r]="radius"
      [attr.stroke]="color"
      [attr.stroke-width]="strokeWidth"
      fill="none"
      [attr.stroke-dasharray]="circumference"
      [attr.stroke-dashoffset]="dashoffset"
      stroke-linecap="round"
    ></circle>
  </svg>
</div>