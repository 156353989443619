import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SENTIMENT } from "@omni/data-services/websocket/payloads/sentiment.payload";

export interface SentimentChipInterface {
  sentiment: SENTIMENT;
  iconSrc: string;
  label: string;
  selected: boolean;
}

@Component({
  selector: 'sentiment-chip',
  templateUrl: 'sentiment-chip.html',
  styleUrls: ['sentiment-chip.scss'],
})
export class SentimentChipComponent {
  @Input() sentiment: SENTIMENT;
  @Input() iconSrc: string;
  @Input() label: string;
  @Input() selected: boolean;
  @Output() onSentimentClick: EventEmitter<SENTIMENT> = new EventEmitter();

  onClick() {
    this.onSentimentClick.emit(this.sentiment);
  }
}
