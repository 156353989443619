import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ActivityType } from '@omni/classes/activity/activity.class';
import { EventActivity } from '@omni/classes/events-tool/event.class';
import { IONote } from "@omni/classes/io/io-note.class";
import { Currency, MarketingPlan, MarketingPlanPurpose } from '@omni/classes/marketing-management/marketing-plan.class';
import { Opportunity } from '@omni/classes/opportunity-management/opportunity.class';
import { AccountPageComponent } from '@omni/components/account/account-page/account-page';
import { ContactPageComponent } from '@omni/components/contact/contact-page/contact-page';
import { EventsToolPageComponent } from '@omni/components/events-tool/event-tool-page/events-tools-page';
import { OpportunitySelectionPageComponent } from '@omni/components/opportunity-management/opportunity-selection-master-page/opportunity-selection-master-page';
import { CurViewPageType, DateTimeFieldType, IndDatetimeFormComponent } from '@omni/components/shared/ind-datetime-form/ind-datetime-form';
import { IndDropdownListComponent } from '@omni/components/shared/ind-dropdown-list/ind-dropdown-list';
import { SelectListComponent } from '@omni/components/shared/select-list/select-list';
import { MarketingPlanManagementDataService } from '@omni/data-services/marketing-plan/marketing-plan-management.data.service';
import { SurgeryOrderActivityDataService } from '@omni/data-services/surgery-order-activity/surgery-order-activity.data.service';
import { EventsSelectionViewDataModel } from '@omni/models/events-selection-page-model';
import { IndDateTimeFormViewDataModel } from '@omni/models/indDateTimeFormDataModel';
import { IndDropdownListDetailModel } from '@omni/models/indDropdownListModel';
import { IndPageTitleViewDataModel } from '@omni/models/indPageTitleDataModel';
import { OpportunitySelectionViewDataModel } from '@omni/models/opportunities-selection-page-model';
import { SelectListData } from '@omni/models/select-list-data-model';
import { AccountOfflineService } from '@omni/services/account/account.offline.service';
import { BrandOfflineService } from '@omni/services/brand/brand.service';
import { ContactOfflineService } from '@omni/services/contact/contact.service';
import { EventsToolService } from '@omni/services/events-tool/events-tool.service';
import { EventName, EventsService } from '@omni/services/events/events.service';
import { FooterService, FooterViews } from '@omni/services/footer/footer.service';
import { GlobalUtilityService } from '@omni/services/global-utility.service';
import { MarketingPlansManagementOfflineService } from '@omni/services/marketing-management/marketing-management.service';
import { NotificationService, ToastStyle } from '@omni/services/notification/notification.service';
import { MAXIMUM_NOTE_ATTACHMENT_SIZE, NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX, toBase64 } from '@omni/utility/util';
import { isBefore, isPast, isValid } from 'date-fns';
import * as _ from 'lodash';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Guid } from 'typescript-guid';
import { FollowUpActivity } from '../../../classes/activity/follow-up-action.activity.class';
import { FeatureActionsMap } from '../../../classes/authentication/user.class';
import { FormFieldType, IndFormFieldViewDataModel } from '../../../models/indFormFieldDataModel';
import { IndSectionHeaderViewDataModel } from '../../../models/indSectionHeaderDataModel';
import { MainCardViewDataModel } from '../../../models/MainCardViewDataModel';
import { ActivityService } from '../../../services/activity/activity.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { DateTimeFormatsService } from '../../../services/date-time-formats/date-time-formats.service';
import { DeviceService } from '../../../services/device/device.service';
import { NavigationService, PageName } from '../../../services/navigation/navigation.service';
import { OpportunityManagementService } from '../../../services/opportunity-management/opportunity-management.service';
import { ComponentViewMode, UIService } from '../../../services/ui/ui.service';
import { MainToolTemplateDetail, MainToolTemplateListSelectionType } from '@omni/models/mainToolTemplateDetail.model';
import { MainToolTemplateComponent } from '@omni/components/shared/main-tool-template/main-tool-template';
import { TrackService, TrackingEventNames } from '@omni/services/logging/tracking.service';
import { NewOpportunityComponent } from '@omni/components/opportunity-management/new-opportunity/new-opportunity';
import { SharedDataService } from '@omni/data-services/shared-data/shared.data.service';
import { Specialty } from '@omni/classes/contact/contact.class';

@Component({
  selector: 'marketing-plan-info',
  templateUrl: 'marketing-plan-info.html',
  styleUrls: ['marketing-plan-info.scss']
})
export class MarketingPlanInfo implements OnInit, OnDestroy {
  @Input() data: MarketingPlan;
  @Input() selectedMode;
  @ViewChild('attachInput') attachInput: ElementRef;
  public isOpportunitiesEnabled = false;
  //calling it a reference as its a copy by reference and not by value
  public planDataSetReference: MarketingPlan;
  public allModelObjectives: Array<MainCardViewDataModel> = [];
  public expensesMainCardModel: Array<MainCardViewDataModel> = [];
  public checkIfInvalidDocuments = false;
  // formattedPeriod: string;
  productsString: string;
  followUpTasksForPlan: FollowUpActivity[];
  ngUnSubscibe$ = new Subject<boolean>();
  showMore = false;
  strategy_Text_default_length = 150;
  private contactsString: string;
  public pageTitle: IndPageTitleViewDataModel;
  public detailHeaderModel: IndSectionHeaderViewDataModel;
  public notesHeaderModel: IndSectionHeaderViewDataModel;
  private isSaveNotesEnabled: boolean = false;
  public notes: string;
  private attachmentFile: any;
  private base64str: any;
  public isAttachmentAdded: boolean = false;
  public attachmentTitle: string = '';
  public eventsSectionHeaderModel: IndSectionHeaderViewDataModel;
  public associatedEvents: EventActivity[] = [];
  public associatedOpportunities: Opportunity[] = [];
  public deviceOffline: boolean = false;
  public isEventsEnabled = false;
  public accountsString: string;
  private _isClicked: { [x: string]: boolean } = {};
  public opportunitiesSectionHeaderModel: IndSectionHeaderViewDataModel;
  public brandnotes: any;
  public isReadOnlyMode = false;
  public isAutoNameEnabled = false;
  applicableOpportunities: Opportunity[];
  private businessUnitsString: string;
  private isAutoSubjectEnabled: boolean = false;
  private isMBPfieldsEnabled: boolean = false; //FA will be added
  private currentSpecialties:Array<any> = [];
  private currentSubSpecialties:Array<any> = [];

  constructor(
    public device: DeviceService,
    public activityService: ActivityService,
    public authService: AuthenticationService,
    public dateTimeFormatsService: DateTimeFormatsService,
    public opportunityService: OpportunityManagementService,
    public footerService: FooterService,
    public uiService: UIService,
    private datePipe: DatePipe,
    private utilityService: GlobalUtilityService,
    private contactService: ContactOfflineService,
    private events: EventsService,
    public readonly translate: TranslateService,
    private readonly popoverCtrl: PopoverController,
    private readonly navService: NavigationService,
    private accountService: AccountOfflineService,
    private notificationService: NotificationService,
    public marketingPlansManagementOfflineService: MarketingPlansManagementOfflineService,
    public marketingPlanManagementDataService: MarketingPlanManagementDataService,
    private readonly brandService: BrandOfflineService,
    private modalCtrl: ModalController,
    private eventToolService: EventsToolService,
    private surgeryOrderActivityDataService: SurgeryOrderActivityDataService,
    public trackingService: TrackService,
    private sharedDataService: SharedDataService,
  ) { }

  ngOnInit() {
    this.isOpportunitiesEnabled = this.authService.hasFeatureAction(FeatureActionsMap.OPPORTUNITY_MANAGEMENT);
    this.isEventsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.EVENTS_IN_ACCOUNT_PLAN) && this.authService.hasFeatureAction(FeatureActionsMap.EVENT_TOOL);
    this.isMBPfieldsEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MBP_FIELDS);
    this.isAutoSubjectEnabled = this.authService.hasFeatureAction(FeatureActionsMap.MARKETING_BUSINESS_PLAN_AUTO_SUBJECT) && this.isMBPfieldsEnabled;

    this.marketingPlansManagementOfflineService.selectedMarketingPlan$.pipe(takeUntil(this.ngUnSubscibe$)).subscribe((selectedMarketingPlan) => {
      if (selectedMarketingPlan) {
        this.planDataSetReference = _.cloneDeep(this.marketingPlansManagementOfflineService.marketingPlans.find(({ ID }) => ID === selectedMarketingPlan.ID));
        if (this.planDataSetReference) {
          let endDate = new Date(parseInt(this.planDataSetReference.endDate))
          this.isReadOnlyMode = this.navService.getPreviousPageName() === PageName.OpportunityManagementPage
          || this.selectedMode === ComponentViewMode.READONLY
          || this.authService.user.systemUserID !== this.planDataSetReference.ownerID || this.device.isOffline || isPast(endDate);
          this.setViewdata();
          this.initializeHeaders();
          let marketPlanAutoName = this.generateMarketPlanName(this.planDataSetReference.accounts, this.planDataSetReference.products);
          this.isAutoNameEnabled = !this.isAutoSubjectEnabled && (marketPlanAutoName === this.planDataSetReference.brandPlanName || this.planDataSetReference.brandPlanName === this.translate.instant('MARKETING_BUSINESS_PLAN'));
        }
      }
    });

    this.device.isOfflineObservable.pipe(takeUntil(this.ngUnSubscibe$)).subscribe(isOffline => {
      // if the device offlice diisable events add button and account plan cancel button
      this.deviceOffline = isOffline;
      this.initializeHeaders();
    });

    if (this.navService.getCurrentMasterPageName() == PageName.ActivitiesPageComponent && this.uiService.activitiesPagePlanTabSelectedPlan === 'goalsPlansTab') {
      this.footerService.initButtons(FooterViews.AccountPlanObjectives);
    }

    this.events.observe(EventName.OPPORTUNITY_CREATED_FROM_MARKETING_PLAN).pipe(takeUntil(this.ngUnSubscibe$)).subscribe((data: Opportunity) =>{
      let opportunityData = this.planDataSetReference.opportunities || [];
      opportunityData.push(data);
      this._handledOpportunitiesSelection('created_opportunity', opportunityData, null);
    })

    this.initializeHeaders();

    this.initSpecialtiesData();
  }

  private initializeHeaders() {
    this.initializeAPDetailHeader();
    this.initializeEventsHeader();
    this.initializeNotesHeader();
    this.initializeopportunitiesHeader()
  }

  private initializeAPDetailHeader() {
    this.detailHeaderModel = {
      id: 'account-plan-detail-header',
      title: this.translate.instant('DETAILS_CAP'),
      controls: [],
    };
  }

  private initializeEventsHeader() {
    const planExpiredOrCompleted = this.planDataSetReference.status === 'Completed';
    this.eventsSectionHeaderModel = {
      id: 'account-plan-events-header',
      title: this.translate.instant('EVENTS'),
      controls: [{
        id: 'add-marketing-plan-events',
        text: this.translate.instant('ADD'),
        isDisabled: this.navService.getPreviousPageName() === PageName.OpportunityManagementPage || this.navService.getPreviousPageName() === PageName.ActivitiesPageComponent || this.deviceOffline || isPast(parseInt(this.planDataSetReference.endDate)),
        isVisible: this.isEventsEnabled
      }]
    };
  }

  private initializeopportunitiesHeader() {
    let buttons = [];
    buttons.push({
      id: 'add-opportunities',
      text: this.translate.instant('ADD'),
      isDisabled: this.navService.getPreviousPageName() === PageName.OpportunityManagementPage || this.deviceOffline || isPast(parseInt(this.planDataSetReference.endDate)),
      isVisible: this.isOpportunitiesEnabled
    },
    //   {
    //   id: 'create-opportunity',
    //   isDisabled: this.navService.getPreviousPageName() === PageName.OpportunityManagementPage || this.deviceOffline || isPast(parseInt(this.planDataSetReference.endDate)),
    //   isVisible: this.isOpportunitiesEnabled,
    //   iconClass: this.navService.getPreviousPageName() === PageName.OpportunityManagementPage || this.deviceOffline || isPast(parseInt(this.planDataSetReference.endDate))?'pluse-icon-disabled':'pluse-icon',
    //   icon: '',
    //   tooltip: 'Create Opportunity'
    // }
    );
    this.opportunitiesSectionHeaderModel = {
      id: 'opportunities',
      title: this.translate.instant('OPPORTUNITIES'),
      controls: buttons
    };
  }

  public selctedOpport(selected){
    console.log("Selected Opportunity in marketing plan info: " + selected);
    const opportuntiesSelectionViewData: OpportunitySelectionViewDataModel = {
      isReadOnlyMode: false,
      isMultiSelection: true,
      opportunitiesDataSet: this.opportunityService.opportunities,
      selectedOpportunities: this.associatedOpportunities,
      disableDoneButtonInOffline: false,
      callbackFromSelectionComponent: (event: string, newSelectedOpportunities: Array<Opportunity>, data: any) => this._handledOpportunitiesSelection(event, newSelectedOpportunities, data),
    };
    let clickedOpp = selected;
    this.navService.pushWithPageTracking(OpportunitySelectionPageComponent, PageName.OpportunitySelectionPage, { viewData: opportuntiesSelectionViewData , clickedOpportunity:clickedOpp }, PageName.OpportunitySelectionPage);
  }

  private initializeNotesHeader() {
    let controls = [
      {
        id: "attach",
        text: this.translate.instant('ATTACH'),
        isDisabled: this.device.isOffline || this.isReadOnlyMode,
        // isVisible: !this.isReadOnlyMode,
      },
      {
        id: 'save-notes',
        text: this.translate.instant('SAVE'),
        isDisabled: !this.isSaveNotesEnabled || this.isReadOnlyMode,
        // isVisible: !this.isReadOnlyMode,
      }
    ]
    this.notesHeaderModel = {
      id: 'notes-header',
      title: this.translate.instant('NOTES'),
      controls: controls
    }
  }

  private get startPeriod() {
    return this._getFormattedDate(this.planDataSetReference.startDate);
  }

  private get endPeriod() {
    return this._getFormattedDate(this.planDataSetReference.endDate);
  }

  private _getFormattedDate(value: any) {
    return this.datePipe.transform(value, this.dateTimeFormatsService.date, undefined, this.translate.currentLang);
  }

  private async setViewdata(data?: MarketingPlan) {
    this.getStartPeriodFormField();
    this.getEndPeriodFormField();
    this.productsString = this.getProductsString;
    this.contactsString = this.getContactString;
    this.accountsString = this.getAccountsString;
    this.associatedEvents = this.planDataSetReference.events ? _.orderBy(this.planDataSetReference.events.filter(event => event.statuscode !== 548910006), "name") : [];
    this.associatedOpportunities = _.orderBy(this.planDataSetReference.opportunities, "opportunityName");
    this.brandnotes = this.planDataSetReference.MarketingPlansNotes.sort((a, b) => {
      return (isBefore(a.createdTime, b.createdTime) ? 1 : -1)
    })
    this.businessUnitsString = this.getBusinessUnitsString;
  }

  private get getBusinessUnitsString() {
    if(!_.isEmpty(this.planDataSetReference.associationBusinessUnits)) {
      let numberOfBusinessUnitss = this.planDataSetReference.associationBusinessUnits.length;
      if (numberOfBusinessUnitss === 0) return '';
      return this.planDataSetReference.associationBusinessUnits[0].associationBuName + (numberOfBusinessUnitss > 1 ? ` +${numberOfBusinessUnitss - 1}` : '');
    }else {
      return '';
    }

  }

  private get getContactString() {
    let numberOfContacts = this.planDataSetReference.contacts.length;
    if (numberOfContacts === 0) return '';
    return this.planDataSetReference.contacts[0].contactFullName + (numberOfContacts > 1 ? ` +${numberOfContacts - 1}` : '');
  }

  private get getProductsString() {
    let numberOfProducts = this.planDataSetReference.products.length;
    if (numberOfProducts === 0) return '';
    return this.planDataSetReference.products[0].productName + (numberOfProducts > 1 ? ` +${numberOfProducts - 1}` : '');
  }

  private get getAccountsString() {
    let numberOfAccounts = this.planDataSetReference.accounts.length;
    if (numberOfAccounts === 0) return '';
    return this.planDataSetReference.accounts[0].name + (numberOfAccounts > 1 ? ` +${numberOfAccounts - 1}` : '');
  }

  public getFormattedDueDate(value): string {
    if (isValid(value)) {
      return `${this.translate.instant('DUE')} ${this._getFormattedDate(value)}`;
    } else {
      return '';
    }
  }

  public backToAccountPlanList(): void {
    if (this.uiService.activitiesPagePlanTabSelectedPlan == 'accountPlansTab') {
      this.navService.popToRootWithPageTracking();
    }
    this.navService.popChildNavPageWithPageTracking();
    this.uiService.showRightPane = false;
    // this.accountManagementService.isRightPaneNavActive = false;
  }

  public getProductFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('PRODUCTS'),
      inputText: this.productsString,
      customPlaceholderLabel: !this.isReadOnlyMode ? this.translate.instant('SELECT_PRODUCTS') : `${this.translate.instant('NO')} ${this.translate.instant('PRODUCTS')}`,
      id: 'products-field',
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isReadOnly: true,
      isDisabled: _.isEmpty(this.planDataSetReference.products) && this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getNameFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('NAME'),
      inputText: this.planDataSetReference?.brandPlanName,
      inputValue: this.planDataSetReference?.brandPlanName,
      id: 'name-field',
      isDisabled: this.isReadOnlyMode || this.isAutoSubjectEnabled,
      placeholderLabel: this.translate.instant('NAME'),
      showArrow: !this.isReadOnlyMode && !this.isAutoSubjectEnabled,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: true,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked && this._isClicked['name-field'] && _.isEmpty(this.planDataSetReference.brandPlanName),
      eventHandler: (id: string, event, eventName) => {
        this.handleFormFieldEvent(id, event, eventName);
        this._isClicked['name-field'] = true;
      }
    };
    return viewData;
  }

  public getAccountsFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant('ACCOUNTS'),
      inputText: this.accountsString,
      inputValue: this.accountsString,
      customPlaceholderLabel: this.isReadOnlyMode ? this.translate.instant('NO_ACCOUNTS') : this.translate.instant('SELECT_ACCOUNTS'),
      id: 'account-field',
      isReadOnly: true,
      isDisabled: _.isEmpty(this.planDataSetReference.accounts) && this.isReadOnlyMode,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      showArrow: !this.isReadOnlyMode,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getCurrencyFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('CURRENCY'),
      inputText: this.planDataSetReference ? this.planDataSetReference?.currencyName : "",
      customPlaceholderLabel: !this.isReadOnlyMode ? this.translate.instant('SELECT_CURRENCY') : this.translate.instant('NO') + " " + this.translate.instant('CURRENCY'),
      id: 'currency',
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: !this.isReadOnlyMode,
      formFieldType: FormFieldType.POPOVER_SELECT,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    }
    return viewData;
  }

  public getBudgetFormField() {
    const isDisabled = this.isReadOnlyMode || this.planDataSetReference?.status === 'Completed' || !this.planDataSetReference?.currencyId;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('BUDGET'),
      inputText: this.planDataSetReference?.indskr_totalbudget ? this.getFormattedValue(this.planDataSetReference.indskr_totalbudget) : '',
      inputValue: this.planDataSetReference?.indskr_totalbudget,
      id: 'budget-field',
      isDisabled: isDisabled,
      customPlaceholderLabel: isDisabled ? this.translate.instant('NO_BUDGET') : this.translate.instant('ENTER_BUDGET'),
      showArrow: !isDisabled,
      formFieldType: FormFieldType.INLINE_INPUT,
      inputType: 'number',
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getParentMarketingBusinessPlanField(){
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PARENT_MARKETING_BUSINESS_PLAN'),
      inputText: this.planDataSetReference?.parentMarketingBusinessPlanName ? this.planDataSetReference.parentMarketingBusinessPlanName : '',
      inputValue: this.planDataSetReference?.parentMarketingBusinessPlanName,
      customPlaceholderLabel: this.isReadOnlyMode ? this.translate.instant('NO_MARKETING_PLAN_ASSOCIATED') : this.translate.instant('SELECT_MARKETING_BUSINESS_PLAN'),
      id: 'parent-marketing-businesss-plan-field',
      isReadOnly: true,
      isDisabled: _.isEmpty(this.planDataSetReference.parentMarketingBusinessPlanName) && this.isReadOnlyMode,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      showArrow: !this.isReadOnlyMode,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getContactFormField(): IndFormFieldViewDataModel {
    let viewData: IndFormFieldViewDataModel;
    viewData = {
      label: this.translate.instant(this.utilityService.globalCustomersText),
      inputText: this.contactsString,
      inputValue: this.contactsString,
      customPlaceholderLabel: !this.isReadOnlyMode ? this.translate.instant('SELECT_WITH_GLOBALCUSTOMER', { globalCustomerText: this.utilityService.globalCustomersText}) : this.translate.instant('No ' + this.utilityService.globalCustomersText),
      id: 'contact-field',
      isReadOnly: true,
      isDisabled: _.isEmpty(this.planDataSetReference.accounts) || (_.isEmpty(this.planDataSetReference.contacts) && this.isReadOnlyMode),
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      showArrow: !this.isReadOnlyMode,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getEstimatedRevenueFormField(): IndFormFieldViewDataModel {
    if (this.planDataSetReference) { // when marketing plan is cancelled planDataSetReference will be null
      let viewData: IndFormFieldViewDataModel;
      viewData = {
        label: this.translate.instant('EXPECTED_REVENUE'),
        inputText: this.dateTimeFormatsService.formattedCurrency((this.planDataSetReference.estimatedRevenureFormatted ? this.planDataSetReference.estimatedRevenureFormatted : '0.00')),
        placeholderLabel: '',
        id: 'estimated-revenue-field',
        isReadOnly: true,
        isDisabled: true,
      };
      return viewData;
    }
  }

  public getActualRevenueFormField(): IndFormFieldViewDataModel {
    if (this.planDataSetReference) { // when marketing plan is cancelled planDataSetReference will be null
      let viewData: IndFormFieldViewDataModel;
      viewData = {
        label: this.translate.instant('ACTUAL_REVENUE'),
        inputText: this.dateTimeFormatsService.formattedCurrency((this.planDataSetReference.actualRevenueFormatted ? this.planDataSetReference.actualRevenueFormatted : '0.00')),
        placeholderLabel: '',
        id: 'actual-revenue-field',
        isReadOnly: true,
        isDisabled: true,
      };
      return viewData;
    }
  }

  public getStartPeriodFormField(): IndDateTimeFormViewDataModel {
    let viewData: IndDateTimeFormViewDataModel;
    viewData = {
      isDateField: true,
      label: this.translate.instant('START_DATE'),
      inputText: this.planDataSetReference && this.planDataSetReference.startDate ? this.startPeriod : '',
      customPlaceholderLabel: this.isReadOnlyMode ? '-' : this.translate.instant('SELECT_START_DATE'),
      startDateTimeValue: this.planDataSetReference?.startDate,
      endDateTimeValue: this.planDataSetReference?.endDate,
      fromViewPage: CurViewPageType.AccountPlan,
      id: DateTimeFieldType.StartDateField,
      isReadOnly: true,
      isDisabled: this.isReadOnlyMode,
      showArrow: true,
      isRequired: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getEndPeriodFormField(): IndDateTimeFormViewDataModel {
    let viewData: IndDateTimeFormViewDataModel;
    viewData = {
      isDateField: true,
      label: this.translate.instant('END_DATE'),
      inputText: this.planDataSetReference && this.planDataSetReference.endDate ? this.endPeriod : '',
      customPlaceholderLabel: this.isReadOnlyMode ? '-' : this.translate.instant('SELECT_END_DATE'),
      startDateTimeValue: this.planDataSetReference?.startDate,
      endDateTimeValue: this.planDataSetReference?.endDate,
      id: DateTimeFieldType.EndDateField,
      isReadOnly: true,
      isDisabled: this.device.isOffline || this.isReadOnlyMode,
      showArrow: true,
      isRequired: true,
      eventHandler: (id: string, event, eventName) => this.handleFormFieldEvent(id, event, eventName),
    };
    return viewData;
  }

  public getBusinessUnitFormField() {
    if(!this.isMBPfieldsEnabled) return null;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('BUSINESS_UNIT'),
      inputText: this.businessUnitsString,
      customPlaceholderLabel: this.businessUnitsString == '' ? this.translate.instant('NO_BUSINESS_UNIT') : this.translate.instant('SELECT_BUSINESS_UNIT'),
      id: 'bu-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: !this.isReadOnlyMode,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: true,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked && this._isClicked['bu-field'] && _.isEmpty(this.businessUnitsString),
      eventHandler: (id: string, event, eventName) => {
        this.handleFormFieldEvent(id, event, eventName);
        this._isClicked['bu-field'] = true;
      }
    }
    return viewData;
  }

  public getFocusAreaFormField() {
    if(!this.isMBPfieldsEnabled) return null;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('FOCUS_AREA'),
      inputText: this.planDataSetReference?.focusArea,
      inputValue: this.planDataSetReference?.focusArea,
      customPlaceholderLabel: !this.isReadOnlyMode ? this.translate.instant('ENTER_FOCUS_AREA') : this.translate.instant('NO_FOCUS_AREA'),
      id: 'focusArea-field',
      isDisabled: this.isReadOnlyMode,
      formFieldType: FormFieldType.INLINE_INPUT,
      showArrow: !this.isReadOnlyMode,
      isRequired: true,
      errorMessage: this.translate.instant('THIS_IS_A_REQUIRED_FIELD'),
      isEmptyRequiredField: this._isClicked && this._isClicked['focusArea-field'] && _.isEmpty(this.planDataSetReference.focusArea),
      eventHandler: (id: string, event, eventName) => {
        this.handleFormFieldEvent(id, event, eventName);
        this._isClicked['focusArea-field'] = true;
      }
    };
    return viewData;
  }

  public getPrimarySpecialtyFormField() {
    if(!this.isMBPfieldsEnabled) return null;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('CONTACT_PRIMARY_SPECIALTY'),
      inputText: this.planDataSetReference?.primarySpecialtyName,
      customPlaceholderLabel:  !this.planDataSetReference?.primarySpecialtyName && !this.isReadOnlyMode ? this.translate.instant('SELECT_PRIMARY_SPECIALTY'): this.translate.instant('NO_PRIMARY_SPECIALTY'),
      id: 'primary-specialty-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: !this.isReadOnlyMode,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => {
        this.handleFormFieldEvent(id, event, eventName);
      }
    };
    return viewData;
  }

  public getSubSpecialtyFormField() {
    if(!this.isMBPfieldsEnabled) return null;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('SUB_SPECIALTY'),
      inputText: this.planDataSetReference?.subSpecialtyName,
      customPlaceholderLabel: !this.planDataSetReference?.subSpecialtyName && !this.isReadOnlyMode ? this.translate.instant('SELECT_SUB_SPECIALTY') : this.translate.instant('NO_SUB_SPECIALTY'),
      id: 'sub-specialty-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: !this.isReadOnlyMode && this.currentSubSpecialties.length > 0,
      formFieldType: FormFieldType.NEW_PAGE_SELECT,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => {
        this.handleFormFieldEvent(id, event, eventName);
      }
    };
    return viewData;
  }

  public getTypeFormField() {
    if(!this.isMBPfieldsEnabled) return null;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('TYPE'),
      inputText: this.planDataSetReference.typeFormatted,
      customPlaceholderLabel: !this.planDataSetReference.typeFormatted && !this.isReadOnlyMode ?  this.translate.instant('SELECT_TYPE') : this.translate.instant('NO_TYPE'),
      id: 'type-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: !this.isReadOnlyMode,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => {
        this.handleFormFieldEvent(id, event, eventName);
      }
    }
    return viewData;
  }

  public getPurposeFormField() {
    if(!this.isMBPfieldsEnabled) return null;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('PURPOSE'),
      inputText: this.planDataSetReference.purposeFormatted,
      customPlaceholderLabel: !this.planDataSetReference.purposeFormatted && !this.isReadOnlyMode? this.translate.instant('SELECT_PURPOSE') : this.translate.instant('NO_PURPOSE'),
      id: 'purpose-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: !this.isReadOnlyMode,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => {
        this.handleFormFieldEvent(id, event, eventName);
      }
    }
    return viewData;
  }

  public getHierarchyOpportunityConversionRateFormField() {
    if(!this.isMBPfieldsEnabled || this.planDataSetReference.purpose != MarketingPlanPurpose.SALES) return null;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('HIERARCHY_OPPORTUNITY_CONVERSION_RATE'),
      inputText: this.planDataSetReference?.hierarchyOpportunityConversionRate ? this.planDataSetReference?.hierarchyOpportunityConversionRate.toString() : '',
      inputValue: this.planDataSetReference?.hierarchyOpportunityConversionRate,
      customPlaceholderLabel: this.translate.instant('NO_HIERARCHY_OPPORTUNITY_CONVERSION_RATE'),
      id: 'hierarchy-opportunity-conversion-rate-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: false,
    }
    return viewData;
  }

  public getOpportunityConversionRateFormField() {
    if(!this.isMBPfieldsEnabled || this.planDataSetReference.purpose != MarketingPlanPurpose.SALES) return null;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('OPPORTUNITY_CONVERSION_RATE'),
      inputText: this.planDataSetReference?.opportunityConversionRate ? this.planDataSetReference?.opportunityConversionRate.toString() : '',
      inputValue: this.planDataSetReference?.opportunityConversionRate,
      customPlaceholderLabel: this.translate.instant('NO_OPPORTUNITY_CONVERSION_RATE'),
      id: 'opportunity-conversion-rate-field',
      isReadOnly: true,
      isDisabled: true,
      showArrow: false,
      formFieldType: FormFieldType.INLINE_INPUT,
      isRequired: false,
    }
    return viewData;
  }

  public getHCPTrainingCompletedFormField() {
    if(!this.isMBPfieldsEnabled || this.planDataSetReference.purpose != MarketingPlanPurpose.SALES) return null;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('HCP_TRAINING_COMPLETED'),
      inputText: this.planDataSetReference.hcpTrainingCompleted != null ? this.planDataSetReference.hcpTrainingCompleted ? this.translate.instant('YES') : this.translate.instant('NO') : '',
      customPlaceholderLabel: this.planDataSetReference.hcpTrainingCompleted == null && !this.isReadOnlyMode ? this.translate.instant('SELECT_HCP_TRAINING_COMPLETED') : this.translate.instant('NO_HCP_TRAINING_COMPLETED'),
      id: 'hcp-training-completed-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: !this.isReadOnlyMode,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => {
        this.handleFormFieldEvent(id, event, eventName);
      }
    }
    return viewData;
  }

  public getMultiYearCampaignFormField() {
    if(!this.isMBPfieldsEnabled || this.planDataSetReference.purpose != MarketingPlanPurpose.SALES) return null;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('MULTIYEAR_CAMPAIGN'),
      inputText: this.planDataSetReference.multiyearCampaign != null ? this.planDataSetReference.multiyearCampaign ? this.translate.instant('YES') : this.translate.instant('NO') : '',
      customPlaceholderLabel: this.planDataSetReference.multiyearCampaign == null && !this.isReadOnlyMode ? this.translate.instant('SELECT_MULTIYEAR_CAMPAIGN') : this.translate.instant('NO_MULTIYEAR_CAMPAIGN'),
      id: 'multiyear-campaign-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: !this.isReadOnlyMode,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => {
        this.handleFormFieldEvent(id, event, eventName);
      }
    }
    return viewData;
  }

  public getNPIApprovedByAccountFormField() {
    if(!this.isMBPfieldsEnabled || this.planDataSetReference.purpose != MarketingPlanPurpose.SALES) return null;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('NPI_APPROVED_BY_ACCOUNT'),
      inputText: this.planDataSetReference.npiApprovedByAccount != null ? this.planDataSetReference.npiApprovedByAccount ? this.translate.instant('YES') : this.translate.instant('NO') : '',
      customPlaceholderLabel: this.planDataSetReference.npiApprovedByAccount == null && !this.isReadOnlyMode ? this.translate.instant('SELECT_NPI_APPROVED_BY_ACCOUNT') : this.translate.instant('NO_NPI_APPROVED_BY_ACCOUNT'),
      id: 'npi-approved-by-account-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: !this.isReadOnlyMode,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => {
        this.handleFormFieldEvent(id, event, eventName);
      }
    }
    return viewData;
  }

  public getNPIProcessStartedFormField() {
    if(!this.isMBPfieldsEnabled || this.planDataSetReference.purpose != MarketingPlanPurpose.SALES) return null;
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('NPI_PROCESS_STARTED'),
      inputText: this.planDataSetReference.npiProcessStarted != null ? this.planDataSetReference.npiProcessStarted ? this.translate.instant('YES') : this.translate.instant('NO') : '',
      customPlaceholderLabel: this.planDataSetReference.npiProcessStarted == null && !this.isReadOnlyMode ? this.translate.instant('SELECT_NPI_PROCESS_STARTED') : this.translate.instant('NO_NPI_PROCESS_STARTED'),
      id: 'npi-process-started-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: !this.isReadOnlyMode,
      formFieldType: FormFieldType.POPOVER_SELECT,
      isRequired: false,
      eventHandler: (id: string, event, eventName) => {
        this.handleFormFieldEvent(id, event, eventName);
      }
    }
    return viewData;
  }

  public getOwnerFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('OM_OWNER'),
      inputText: this.planDataSetReference.ownerName,
      id: 'owner-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: false,
      formFieldType: FormFieldType.DATA_SUBMITTED,
      isRequired: false,
    };
    return viewData;
  }

  public getCreatedByFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('CREATED_BY'),
      inputText: this.planDataSetReference.createdByString,
      id: 'created-by-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: false,
      formFieldType: FormFieldType.DATA_SUBMITTED,
      isRequired: false,
    };
    return viewData;
  }

  public getLastModifiedByFormField() {
    const viewData: IndFormFieldViewDataModel = {
      label: this.translate.instant('LAST_MODIFIED_BY'),
      inputText: this.planDataSetReference.lastModifiedByString,
      id: 'last-modified-by-field',
      isReadOnly: true,
      isDisabled: false,
      showArrow: false,
      formFieldType: FormFieldType.DATA_SUBMITTED,
      isRequired: false,
    };
    return viewData;
  }

  setShowMore(event) {
    this.showMore = !this.showMore;
  }

  private handleFormFieldEvent(id, event, eventName) {
    // if (this.device.isOffline) return;
    switch (id) {
      case "products-field":
        this.gotoProductSelection();
        break;
      case "contact-field":
        this.gotoContactSelection();
        break;
      case "account-field":
        this.openAccountsSection();
        break;
      case DateTimeFieldType.StartDateField:
        this.openStartDatePicker();
        break;
      case DateTimeFieldType.EndDateField:
        this.openEndDatePicker();
        break;
      case 'name-field':
        if (eventName && eventName == 'input_value_confirm') {
          this.handleMarketingPlanNameUpdate(event);
        }
        break;
      case "budget-field":
        if (eventName && eventName == 'input_value_confirm') {
          this.updatePlannedBudgetField(event);
        }
        break;
      case "currency":
        this.openCurrencySelect(event);
        break;
      case "parent-marketing-businesss-plan-field":
        this.openMarketingPlans();
        break;
      case "bu-field":
        this.openBusinessUnitSelection();
        break;
      case "focusArea-field":
        if (eventName && eventName == 'input_value_confirm') {
          this.updateFocusArea(event);
        }
        break;
      case 'primary-specialty-field':
        this.handleSpecialtySelect(event);
        break;
      case 'sub-specialty-field':
        this.handleSubSpecialtySelect(event);
        break;
      case 'type-field':
        this.handleTypeField(event);
        break;
      case 'purpose-field':
        this.handlePurposeSelect(event);
        break;
      case 'hcp-training-completed-field':
      case 'multiyear-campaign-field':
      case 'npi-approved-by-account-field':
      case 'npi-process-started-field':
        this.handleBooleanField(id,event);
        break;
      default:
        console.log("unhandled case");
    }
  }

  public async openCurrencySelect(event) {
    let options = [];
    if (this.isReadOnlyMode) return;
    const currencies: Currency[] = _.cloneDeep(this.marketingPlansManagementOfflineService.currencies);
    currencies.forEach(({ id, name }: Currency) => {
      let isSelected: boolean = this.planDataSetReference && this.planDataSetReference.currencyId && this.planDataSetReference.currencyId === id;
      options.push({ id, title: name, isSelected: isSelected });
    });
    options = this.marketingPlansManagementOfflineService.sortListByFielName(options, 'title');
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'currency-select',
      data: options,
      isMultipleSelectionEnabled: false,
      // isReadOnly: this.isReadOnlyView
    };

    let dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: dropdownListDetail
      },
      cssClass: 'dropdown-list-view',
      event: event
    });
    dropdownPopover.onDidDismiss().then(async (obj) => {
      const data = obj.data;
      if (data && data.selectedItems && data.selectedItems.length == 1) {
        if (this.planDataSetReference.currencyId && data.selectedItems[0].id === this.planDataSetReference.currencyId) return;
        const currency: Currency = currencies.find(currency => currency.id === data.selectedItems[0].id);
        await this.uiService.displayLoader();
        await this.marketingPlanManagementDataService.saveMarketPlan({ transactionCurrencyId: currency.id }, this.planDataSetReference.ID);
        this.planDataSetReference.currencyId = currency.id;
        this.planDataSetReference.currencyName = currency.name;
        this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
        await this.uiService.dismissLoader();
      }
    });
    dropdownPopover.present();
  }

  private handleMarketingPlanNameUpdate(event) {
    if (event && event.target.value !== undefined && event.target.value !== "") {
      this.uiService.displayLoader();
      this.marketingPlanManagementDataService.saveMarketPlan({ indskr_name: event.target.value }, this.planDataSetReference.ID).then(async () => {
        this.planDataSetReference.brandPlanName = event.target.value;
        this.isAutoNameEnabled = false;
        await this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
        this.marketingPlansManagementOfflineService.marketingPlans$.next(this.marketingPlansManagementOfflineService.marketingPlans);
        this.uiService.dismissLoader();
      });
    }
  }

  private generateMarketPlanName(accounts, products): string {
    let productString = '';
    let accountString = '';

    const numberOfProducts = products.length;
    if (numberOfProducts > 0) {
      productString = ' - ' + products[0].productName + (numberOfProducts > 1 ? ` +${numberOfProducts - 1}` : '');
    }

    const numberOfAccounts = accounts.length;
    if (numberOfAccounts > 0) {
      accountString = ' - ' + accounts[0].name + (numberOfAccounts > 1 ? ` +${numberOfAccounts - 1}` : '');
    }

    const marketPlanName = this.translate.instant('MARKETING_BUSINESS_PLAN') + accountString + productString;
    return marketPlanName;
  }

  private async updatePlannedBudgetField(event) {
    if (event && event.target.value !== undefined && this.planDataSetReference.indskr_totalbudget != event.target.value) {
      this.planDataSetReference.indskr_totalbudget = isNaN(parseInt(event.target.value)) ? null : parseInt(event.target.value);
      this.planDataSetReference.plannedBudgetFormatted = null;
      if (this.planDataSetReference.indskr_totalbudget != null) {
        this.planDataSetReference.plannedBudgetFormatted = this.getFormattedValue(this.planDataSetReference.indskr_totalbudget);
      }
      await this.uiService.displayLoader();
      await this.marketingPlanManagementDataService.saveMarketPlan({ indskr_totalbudget: this.planDataSetReference.indskr_totalbudget }, this.planDataSetReference.ID);
      this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
      await this.uiService.dismissLoader();
    }
  }

  private getFormattedValue(value: number): string {
    const currency: Currency = this.marketingPlansManagementOfflineService.currencies.find(currency => currency.id === this.planDataSetReference.currencyId);
    if (!currency) return null;
    return this.dateTimeFormatsService.selectedSymbolPos.position == 'left'
      ? currency.symbol + value.toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      : value.toFixed(this.dateTimeFormatsService.selectedDecimalPos.toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + currency.symbol;
  }

  private openAccountsSection() {
    this.accountService.accessedAccountListFrom = PageName.MarketingPlanDetails;
    this.navService.pushWithPageTracking(AccountPageComponent, PageName.AccountPageComponent, {
      'listMode': this.isReadOnlyMode ? ComponentViewMode.READONLY : ComponentViewMode.ADDNEW,
      selectedAccountIds: this.planDataSetReference.accounts,
      callbackEvent: (data: any) => this._handleAccountComponentCallback(data)
    }, PageName.AccountPageComponent).then(() => {
      if (this.planDataSetReference.accounts?.length > 0 && !this.device.isMobileDevice) {
        this.events.publish(EventName.OPEN_SELECTED_ACCOUNT_DETAILS, this.planDataSetReference.accounts[0]);
      }
    });
  }

  private async _handleAccountComponentCallback(selectedAccounts) {
    let reqPayload = {
      accounts: [],
      contacts: [],
      indskr_name: this.planDataSetReference.brandPlanName
    }
    await this.uiService.displayLoader();
    if (!_.isArray(selectedAccounts)) selectedAccounts = [];
    const removedAccounts = this.planDataSetReference.accounts.filter((account) => !_.find(selectedAccounts, account));
    if (removedAccounts.length) {
      reqPayload.contacts = await this.removeAccountAffliated(removedAccounts);
    }

    if (this.isAutoNameEnabled) {
      let newMarketPlanName = this.generateMarketPlanName(selectedAccounts, this.planDataSetReference.products);
      if (newMarketPlanName !== this.planDataSetReference.brandPlanName) {
        reqPayload.indskr_name = newMarketPlanName;
      }
    }

    selectedAccounts.forEach(element => {
      reqPayload.accounts.push({ id: element.id });
    });

    this.planDataSetReference.accounts.filter((item) => !_.find(selectedAccounts, item)).forEach(item => {
      reqPayload.accounts.push({ id: item.id, deleted: true });
    });

    this.marketingPlanManagementDataService.saveMarketPlan(reqPayload, this.planDataSetReference.ID).then(async () => {
      selectedAccounts = _.orderBy(selectedAccounts, 'name');
      this.planDataSetReference.accounts = selectedAccounts;
      this.planDataSetReference.brandPlanName = reqPayload.indskr_name;
      await this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
      this.accountsString = this.getAccountsString;
      this.marketingPlansManagementOfflineService.marketingPlans$.next(this.marketingPlansManagementOfflineService.marketingPlans);
      this.marketingPlansManagementOfflineService.selectedMarketingPlan$.next(this.planDataSetReference);
      this.uiService.dismissLoader();
    });
  }

  async removeAccountAffliated(removedAccounts) {
    if (!this.planDataSetReference.contacts || this.planDataSetReference.contacts.length === 0) return;
    const removeContactIds = [];
    const removeContactPayload = [];
    const removeAccIds = removedAccounts.map(({ id }) => id);
    const contactAccAffs = await this.contactService.getAccountContactAffiliationsFromDB();
    const accountsExceptRemove = this.planDataSetReference.accounts.filter(({ id }) => !removeAccIds.includes(id)).map(({ id }) => id);

    this.planDataSetReference.contacts.forEach(contactAccountAff => {
      const affliation = contactAccAffs.find(item => item['indskr_accountcontactaffiliation.indskr_contactid'] === contactAccountAff.contactId && removeAccIds.includes(item.accountid));
      if (affliation) {
        const otheraffliation = contactAccAffs.find(item => item['indskr_accountcontactaffiliation.indskr_contactid'] === contactAccountAff.contactId && accountsExceptRemove.includes(item.accountid));
        if (otheraffliation) {
          contactAccountAff['indskr_accountcontactaffiliationid'] = otheraffliation['indskr_accountcontactaffiliation.indskr_accountcontactaffiliationid']
        } else {
          removeContactIds.push(contactAccountAff.contactId);
        }
      }
    });

    if (!_.isEmpty(this.planDataSetReference.contacts)) {
      this.planDataSetReference.contacts.filter(contact => removeContactIds.includes(contact.contactId)).map(contact => {
        removeContactPayload.push({ id: contact.contactId, deleted: true });
      });
    }

    this.planDataSetReference.contacts = this.planDataSetReference.contacts.filter((item) => !removeContactIds.includes(item.contactId));
    this.contactsString = this.getContactString;
    return removeContactPayload;
  }

  private gotoContactSelection() {
    if (!this.isReadOnlyMode) {
      if (this.deviceOffline) return;
      this.contactService.contactPageMode = ComponentViewMode.ADDNEW;
    } else {
      this.contactService.contactPageMode = ComponentViewMode.READONLY;
    }
    this.uiService.showNewActivity = false;
    this.contactService.accessedContactListFrom = PageName.MarketingPlanDetails;
    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent, {
      selectedContactIds: this.planDataSetReference.contacts ? this.planDataSetReference.contacts.map(con => con.contactId) : [],
      selectedAccountIds: this.planDataSetReference.accounts.map(ac => ac.id),
      callbackEvent: (data: any) => this._handleContactComponentCallback(data)
    }, PageName.MarketingPlanDetails, { animate: false }, null);
  }

  private async _handleContactComponentCallback(selectedData: any[]) {
    const reqPayload = [];
    await this.uiService.displayLoader();
    selectedData.forEach(data => reqPayload.push({ id: data['contactId'] }));
    if (!_.isEmpty(this.planDataSetReference.contacts)) {
      this.planDataSetReference.contacts.filter(contact => !selectedData.map(data => data['contactId'])
        .includes(contact.contactId)).map(contact => {
          reqPayload.push({ id: contact.contactId, deleted: true });
        });
    }

    this.marketingPlanManagementDataService.saveMarketPlan({ contacts: reqPayload }, this.planDataSetReference.ID).then(() => {
      selectedData = _.orderBy(selectedData, 'contactFullName');
      this.planDataSetReference.contacts = selectedData;
      this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
      this.contactsString = this.getContactString;
      this.uiService.dismissLoader();
    });
  }

  private async gotoProductSelection() {
    const selectedProductIds = [];
    if (!_.isEmpty(this.planDataSetReference.products)) {
      this.planDataSetReference.products.map(prod => selectedProductIds.push(prod.productID));
    }

    // products
    let options: SelectListData[] = this.brandService.brands.map(({ ID, name }) => {
      return {
        id: ID,
        title: name,
        isSelected: selectedProductIds.includes(ID)
      }
    });

    // product bundle
    if (this.surgeryOrderActivityDataService.procedureTrackerProductHierarchies.length > 0) {
      this.surgeryOrderActivityDataService.procedureTrackerProductHierarchies.forEach(({ surgeryId, surgeryName }) => {
        options.push({ id: surgeryId, title: surgeryName, isSelected: selectedProductIds.includes(surgeryId) })
      })
    }

    // to populate unmapped products
    selectedProductIds.forEach((productid) => {
      if (options.every((option) => option.id !== productid)) {
        let product = this.planDataSetReference.products.find((pro) => pro.productID === productid)
        options.push({ id: productid, title: product.productName, isSelected: selectedProductIds.includes(productid) })
      }
    });

    options = _.orderBy(_.uniqBy(options, 'id'), 'title');
    const productsModal = await this.modalCtrl.create({
      component: SelectListComponent,
      componentProps: {
        viewData: {
          data: options,
          isReadOnly: this.isReadOnlyMode,
          isMultipleSelectionEnabled: true,
          title: this.translate.instant('PRODUCTS'),
          dividerTitle: this.translate.instant('ALL_PRODUCTS_CAP'),
          isSearchEnabled: true,
        }
      },
      backdropDismiss: false
    });
    productsModal.present();
    productsModal.onDidDismiss().then(async (obj: any) => {
      const data = obj.data;
      if (data && data.isDone) {
        const reqPayload: any = {
          products: [],
          indskr_name: this.planDataSetReference.brandPlanName
        };
        let productsRef = [];
        data.selectedItems.forEach(selectedItem => {
          reqPayload.products.push({ id: selectedItem.id });
          productsRef.push({ productID: selectedItem.id, productName: selectedItem.title });
        });

        if (!_.isEmpty(this.planDataSetReference.products)) {
          this.planDataSetReference.products.filter(prod => !data.selectedItems.map(selectedItem => selectedItem.id)
            .includes(prod.productID)).forEach(prod => reqPayload.products.push({ id: prod.productID, deleted : true }));
        }

        if (this.isAutoNameEnabled) {
          let newMarketPlanName = this.generateMarketPlanName(this.planDataSetReference.accounts, productsRef);
          if (newMarketPlanName !== this.planDataSetReference.brandPlanName) {
            reqPayload.indskr_name = newMarketPlanName;
          }
        }

        await this.uiService.displayLoader();
        await this.marketingPlanManagementDataService.saveMarketPlan(reqPayload, this.planDataSetReference.ID).then(async (newMarketPlan) => {
          productsRef = _.orderBy(productsRef, 'productName');
          this.planDataSetReference.products = productsRef;
          this.planDataSetReference.brandPlanName = reqPayload.indskr_name;
          this.productsString = this.getProductsString;
          await this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
          this.marketingPlansManagementOfflineService.marketingPlans$.next(this.marketingPlansManagementOfflineService.marketingPlans);
          this.uiService.dismissLoader();
        });
      }
    })
  }

  onSectionHeaderControlClick(id: string) {
    switch (id) {
      case 'add-marketing-plan-events':
        this.openEvents();
        break;
      case "attach":
        this.handleNoteAttachment();
        break;
      case "save-notes":
        this.handleNotesSave();
        break;
      case 'add-opportunities':
        this.openOpportunities();
        break;
      case 'create-opportunity':
        this.createOpportunities();
        break;
      default:
        console.error("unhandled error");
    }
  }

  private openOpportunities() {
    // const accountIds = this.data.accounts.map(el => el.id);
    // const opportunitiesDataSet = this.opportunityService.opportunities.filter(item => item.status == "Open");
    const opportuntiesSelectionViewData: OpportunitySelectionViewDataModel = {
      isReadOnlyMode: false,
      isMultiSelection: true,
      opportunitiesDataSet: this.opportunityService.opportunities,
      selectedOpportunities: this.associatedOpportunities,
      disableDoneButtonInOffline: false,
      callbackFromSelectionComponent: (event: string, newSelectedOpportunities: Array<Opportunity>, data: any) => this._handledOpportunitiesSelection(event, newSelectedOpportunities, data),
    };
    this.navService.pushWithPageTracking(OpportunitySelectionPageComponent, PageName.OpportunitySelectionPage, { viewData: opportuntiesSelectionViewData }, PageName.OpportunitySelectionPage);
  }

  private createOpportunities() {
    if(this.navService.getActiveChildNavViewPageName() != PageName.NewOpportunityComponent){
      this.trackingService.tracking('CreateClicked', TrackingEventNames.OPPORTUNITYMANAGEMENT);
      this.navService.pushChildNavPageWithPageTracking(NewOpportunityComponent,PageName.NewOpportunityComponent,PageName.OpportunityManagementPage, {from: 'MarketingPlanInfo', selectableOpportunityAccounts: this.planDataSetReference.accounts, mbp: this.planDataSetReference}).then(() => {
        this.navService.setChildNavRightPaneView(true);
        if(!_.isEmpty(this.planDataSetReference.accounts) && this.planDataSetReference.accounts.length == 1){
          let opportunity = this.opportunityService.newOpportunity$.value;
          opportunity.accountID = this.planDataSetReference.accounts[0].id
          opportunity.accountName = this.planDataSetReference.accounts[0].name;
          this.opportunityService.newOpportunity$.next(opportunity);
        }
      });
      console.log("create opportunity from marketing business plan");
    }
  }

  private async _handledOpportunitiesSelection(event, newSelectedOpportunities: Array<Opportunity>, data: any) {
    if (event && (event == 'DONEBUTTONCLICK' || event == 'created_opportunity')) {
      let opportuntiesPayload = [];
      if (_.isEmpty(this.associatedOpportunities) && !_.isEmpty(newSelectedOpportunities)) {
        opportuntiesPayload = newSelectedOpportunities.map(opp => {
          return {
            id: opp.ID
          }
        });
      } else if (!_.isEmpty(this.associatedOpportunities) && _.isEmpty(newSelectedOpportunities)) {
        opportuntiesPayload = this.associatedOpportunities.map(opp => {
          return {
            id: opp.ID,
            deleted: true
          }
        });
      } else {
        this.associatedOpportunities.forEach(opp => {
          if (newSelectedOpportunities.findIndex(newOpp => newOpp.ID === opp.ID) == -1) {
            opportuntiesPayload.push({
              id: opp.ID,
              deleted: true
            })
          }
        })
        newSelectedOpportunities.forEach(opp => {
          if (this.associatedOpportunities.findIndex(newOpp => newOpp.ID === opp.ID) == -1) {
            opportuntiesPayload.push({
              id: opp.ID
            })
          }
        })
      }
      const accountsToAutoMapPayload = [];
      let accountsToAutoMap = [];
      opportuntiesPayload.filter(op => !op.deleted).forEach(opp => {
        const foundOpportunity = this.opportunityService.getOpportunityDetailsbyId(opp.id);
        if (foundOpportunity && !this.planDataSetReference.accounts.map(acc => acc.id).includes(foundOpportunity.accountID) && !accountsToAutoMapPayload.map(acc => acc.id).includes(foundOpportunity.accountID)) {
          accountsToAutoMapPayload.push({ id: foundOpportunity.accountID });
          accountsToAutoMap.push({ id: foundOpportunity.accountID, name: foundOpportunity.accountName, statecode: 1 });
        }
      });
      const payload = { opportunities: opportuntiesPayload, accounts: accountsToAutoMapPayload };
      accountsToAutoMap.push(...this.planDataSetReference.accounts);
      accountsToAutoMap = _.orderBy(accountsToAutoMap, 'name');
      if (this.isAutoNameEnabled) {
        const newMarketPlanName = this.generateMarketPlanName(accountsToAutoMap, this.planDataSetReference.products);
        if (newMarketPlanName !== this.planDataSetReference.brandPlanName) {
          payload['indskr_name'] = newMarketPlanName;
        }
      }
      await this.uiService.displayLoader();
      await this.marketingPlanManagementDataService.saveMarketPlan(payload, this.planDataSetReference.ID);
      opportuntiesPayload.forEach(opp => {
        const index = this.associatedOpportunities.findIndex(aOpp => aOpp.ID === opp.id);
        if (opp.deleted) {
          this.associatedOpportunities.splice(index, 1);
        } else {
          const foundOpportunity = this.opportunityService.getOpportunityDetailsbyId(opp.id);
          if (foundOpportunity && index == -1) {
            this.associatedOpportunities.push(foundOpportunity);
          }
        }
      });
      this.planDataSetReference.accounts = accountsToAutoMap;
      this.accountsString = this.getAccountsString;
      if (payload['indskr_name'])
        this.planDataSetReference.brandPlanName = payload['indskr_name'];
      this.associatedOpportunities = _.orderBy(this.associatedOpportunities, 'opportunityName');
      this.planDataSetReference.opportunities = this.associatedOpportunities;
      this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
      this.marketingPlansManagementOfflineService.selectedMarketingPlan$.next(this.planDataSetReference);
      this.marketingPlansManagementOfflineService.marketingPlans$.next(this.marketingPlansManagementOfflineService.marketingPlans);
      await this.uiService.dismissLoader();
    }
  }

  private handleNoteAttachment() {
    try {
      this.attachInput.nativeElement.click();
    } catch (error) {
      console.log(error);
    }
  }

  private async handleNotesSave() {
    if (this.isSaveNotesEnabled && ((this.notes && !_.isEmpty(this.notes)) || !_.isEmpty(this.base64str))) {
      this.isSaveNotesEnabled = false;
      this.initializeNotesHeader();
      this.uiService.displayLoader();
      let tempNote: any = {
        annotationid: Guid.create().toString(),
        createdon: new Date().getTime(),
        notetext: this.notes,
        ownerName: this.authService.user.displayName,
        ownerid: this.authService.user.systemUserID,
      };
      if (this.base64str) {
        tempNote['isdocument'] = true;
        tempNote['documentbody'] = this.base64str;
        tempNote['filename'] = this.attachmentFile.name;
        tempNote['mimetype'] = this.attachmentFile.type;
      } else {
        tempNote['hasDocument'] = false;
      }
      if (!this.planDataSetReference.MarketingPlansNotes) {
        this.planDataSetReference.MarketingPlansNotes = [];
      }

      const response: any = await this.marketingPlanManagementDataService.saveAttachments([tempNote], this.planDataSetReference.ID);
      tempNote.annotationid = response.noteid;
      tempNote = new IONote(tempNote);
      this.planDataSetReference.MarketingPlansNotes.push(tempNote);
      this.planDataSetReference.MarketingPlansNotes.sort((a, b) => {
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1)
      })
      this.brandnotes = this.planDataSetReference.MarketingPlansNotes;
      // this.planDataSetReference.MarketingPlansNotes = this.data.MarketingPlansNotes;
      await this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
      this.removeAttachment(null);
      this.notes = '';
      // this.updateSaveBtn();
      this._sortNotes();
      this.uiService.dismissLoader();
    }
  }

  get nonDeletedNotes() {
    return this.planDataSetReference?.MarketingPlansNotes?.filter(note => !note.isDeleted)
  }

  private _sortNotes(): void {
    if (this.planDataSetReference.MarketingPlansNotes && this.planDataSetReference.MarketingPlansNotes.length > 1) {
      this.planDataSetReference.MarketingPlansNotes.sort((a, b) => {
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1)
      })
    }
  }

  public removeAttachment(event) {
    try {
      this.attachInput.nativeElement.value = null;
    } catch (error) {
      console.log(error);
    }
    this.attachmentTitle = '';
    this.isAttachmentAdded = false;
    this.attachmentFile = null;
    this.base64str = null;
  }

  public async updateNote(ev) {
    if (ev && ev.action) {
      if (this.device.isOffline) return;
      const idx = this.planDataSetReference.MarketingPlansNotes.findIndex(note => note.noteId === ev.noteId);
      let note = this.planDataSetReference.MarketingPlansNotes[idx];

      if (ev.action === 'DELETE' && idx >= 0) {
        note.isDeleted = true;
        if (note.hasDocument)
          note = this._resetDocumentDetailsOfNote(note);
      } else if (ev.action === 'SAVE' && idx >= 0) {
        note.noteText = ev.updatedText;
        if (ev.attachmentFileUpdated) {
          note = this._updateAttachmentinNotes(note, ev);
        }
        //  else if (ev.attachmentFileUpdated) {
        // note = this._updateAttachmentinNotes(note, ev)
        // }
        else if (ev.isAttachmentRemoved) {
          note.hasDocument = false;
          note = this._resetDocumentDetailsOfNote(note);
        }
      }

      let payload: any = { ...note, notetext: note.noteText, noteid: note.noteId }
      if (ev.action === 'DELETE' && idx >= 0) {
        payload = { ...payload, deleted: true }
      }

      if (note.hasDocument) {
        payload = {
          ...payload,
          isdocument: true,
          documentbody: ev.attachmentFileDataUrl,
          filename: ev.documentName,
          mimetype: ev.documentMimeType
        }
      }

      if (ev.isAttachmentRemoved) {
        payload = {
          ...payload,
          isdocument: false,
          documentbody: '',
          filename: '',
          mimetype: ''
        }
      }

      await this.uiService.displayLoader();
      await this.marketingPlanManagementDataService.saveAttachments([payload], this.planDataSetReference.ID);
      if (ev.action === 'DELETE' && idx >= 0) {
        this.planDataSetReference.MarketingPlansNotes.splice(idx, 1);
      } else {
        this.planDataSetReference.MarketingPlansNotes[idx] = note;
      }
      this.brandnotes = this.planDataSetReference.MarketingPlansNotes;
      await this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
      this.removeAttachment(null);
      this.notes = '';
      // this.updateSaveBtn();
      this._sortNotes();
      this.uiService.dismissLoader();
    }
  }

  private _resetDocumentDetailsOfNote(note: IONote) {
    note.documentSize = 0;
    note.documentName = '';
    note.documentMimeType = '';
    note.documentDataURL = '';
    note.hasDocument = false;
    return note;
  }

  private _updateAttachmentinNotes(note: any, ev: any) {
    note.hasDocument = true;
    note.documentSize = ev.documentSize;
    note.documentName = ev.documentName;
    note.documentMimeType = ev.documentMimeType;
    note.documentDataURL = ev.attachmentFileDataUrl;
    return note;
  }

  public async loadImageFromDevice(event) {
    if (event && event.target && event.target.files) {
      try {
        if ((event.target.files[0].size / 1000) < MAXIMUM_NOTE_ATTACHMENT_SIZE) {
          if (NOTE_ATTACHMENT_MIME_TYPES_SUPPORTED_REGEX.test(event.target.files[0].name)) {
            this.attachmentFile = event.target.files[0];
            this.attachmentTitle = event.target.files[0].name;
            this.isAttachmentAdded = true;
            this.base64str = await toBase64(this.attachmentFile);
            const dataURLPattern = /^data:[^;]+;base64,/;
            this.base64str = this.base64str.replace(dataURLPattern, '');
            this.isSaveNotesEnabled = true;
            this.initializeNotesHeader();
          } else {
            this.notificationService.notify(this.translate.instant('NOTE_ATTACHMENT_MIME_TYPE_NOT_SUPPORTED_NOTIFICATION'), 'Note Attachment', 'top', 'info');
            this.removeAttachment(false);
          }
        } else {
          this.notificationService.notify(this.translate.instant('MAXIMUM_NOTE_ATTACHMENT_SIZE_NOTIFICATION', { size: MAXIMUM_NOTE_ATTACHMENT_SIZE }), 'Note Attachment', 'top', 'info');
          this.removeAttachment(false);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  public notesChanged(ev): void {
    if (this.device.isOffline) return;
    if (ev && ev.target) {
      this.notes = ev.target.value;
      this.isSaveNotesEnabled = true;

    } else {
      this.notes = '';
      this.isSaveNotesEnabled = true;

    }
    this.initializeNotesHeader();
  }

  private openEvents() {
    const eventsSelectionViewData: EventsSelectionViewDataModel = {
      isReadOnlyMode: false,
      eventsDataSet: [],
      selectedEvents: this.associatedEvents,
      disableDoneButtonInOffline: true,
      viewMode: ComponentViewMode.ADDNEW,
      callbackFromSelectionComponent: (event: string, newSelectedEvents: Array<EventActivity>, data: any) => this._handledEventsSelection(event, newSelectedEvents, data),
    };
    const startDate = this.planDataSetReference.startDate, endDate = this.planDataSetReference.endDate;

    this.navService.pushWithPageTracking(EventsToolPageComponent, PageName.EventsToolPageComponent, { viewData: eventsSelectionViewData, startDate, endDate, from: 'brand-plan' }, PageName.EventsToolPageComponent);
  }

  private async _handledEventsSelection(ev, newSelectedEvents: Array<EventActivity>, data: any) {
    if (ev && ev == 'DONEBUTTONCLICK') {
      let eventsPayload = [];
      if (_.isEmpty(this.associatedEvents) && !_.isEmpty(newSelectedEvents)) {
        eventsPayload = newSelectedEvents.map(opp => {
          return {
            id: opp.ID
          }
        });
      } else if (!_.isEmpty(this.associatedEvents) && _.isEmpty(newSelectedEvents)) {
        eventsPayload = this.associatedEvents.map(opp => {
          return {
            id: opp.ID,
            deleted: true
          }
        });
      } else {
        this.associatedEvents.forEach(opp => {
          if (newSelectedEvents.findIndex(newOpp => newOpp.ID === opp.ID) == -1) {
            eventsPayload.push({
              id: opp.ID,
              deleted: true
            })
          }
        })
        newSelectedEvents.forEach(opp => {
          if (this.associatedEvents.findIndex(newOpp => newOpp.ID === opp.ID) == -1) {
            eventsPayload.push({
              id: opp.ID
            })
          }
        })
      }
      await this.uiService.displayLoader();
      await this.marketingPlanManagementDataService.saveMarketPlan({ events: eventsPayload }, this.planDataSetReference.ID);
      eventsPayload.forEach(event => {
        const index = this.associatedEvents.findIndex(aOpp => aOpp.ID === event.id);
        if (event.deleted) {
          this.associatedEvents.splice(index, 1);
        } else {
          const foundEvent = this.eventToolService.getEventDetailsbyId(event.id);
          if (foundEvent && index == -1) {
            this.associatedEvents.push(foundEvent);
          }
        }
      });
      this.associatedEvents = _.orderBy(this.associatedEvents, 'name');
      this.planDataSetReference.events = this.associatedEvents;
      this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
      await this.uiService.dismissLoader();
    }
  }

  onTextAreaFocus(value) {
    if (this.deviceOffline) return;
  }

  private async openStartDatePicker() {
    this.activityService.dateTimePickerType = DateTimeFieldType.StartDateField;
    let popover = await this.getDateTimePopover();
    popover.present();
    popover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && !_.isEmpty(obj.data.startTime)) {
        const valueDate = new Date(obj.data.startTime).getTime();
        if (this.planDataSetReference.endDate && valueDate > parseInt(this.planDataSetReference.endDate)) {
          this.notificationService.notify(this.translate.instant('AFFILIATION_END_DATE_CAN_NOT_BEFORE_START_DATE'), this.translate.instant('MARKETING_BUSINESS_PLAN'), 'top', ToastStyle.DANGER);
        } else {
          await this.uiService.displayLoader();
          if (await this.isActivityInPast(obj.data.startTime)) {
            this.uiService.dismissLoader();
            this.notificationService.notify(this.translate.instant('MARKETING__BUSINESS_PLAN_ACTIVITY_VALIDATION'), 'Marketing business Plan', 'top', ToastStyle.DANGER);
            return;
          }
          this.marketingPlanManagementDataService.saveMarketPlan({ indskr_validfrom: valueDate }, this.planDataSetReference.ID).then(() => {
            this.planDataSetReference.startDate = valueDate.toString();
            this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
            this.uiService.dismissLoader();
          });

        }
      }
    })
  }

  private async openEndDatePicker() {
    if (!this.planDataSetReference.startDate) return;
    const today = new Date().getTime();
    this.activityService.dateTimePickerType = DateTimeFieldType.EndDateField;
    let popover = await this.getDateTimePopover();
    popover.present();
    popover.onDidDismiss().then(async (obj: any) => {
      if (obj && obj.data && !_.isEmpty(obj.data.endTime)) {
        const valueDate = new Date(obj.data.endTime).getTime();
        if (parseInt(this.planDataSetReference.startDate) > valueDate) {
          this.notificationService.notify(this.translate.instant('AFFILIATION_END_DATE_CAN_NOT_BEFORE_START_DATE'), this.translate.instant('MARKETING_BUSINESS_PLAN'), 'top', ToastStyle.DANGER);
        } else if (valueDate < today) {
          this.notificationService.notify(this.translate.instant('MARKETING_BUSINESS_PLAN_CAN_NOT_BE_PAST'), this.translate.instant('MARKETING_BUSINESS_PLAN'), 'top', ToastStyle.DANGER);
        } else {
          if (this.isSameDateWithoutTime(new Date(obj.data.endTime), new Date(parseInt(this.planDataSetReference.endDate)))) return;
          await this.uiService.displayLoader();
          this.marketingPlanManagementDataService.saveMarketPlan({ indskr_validto: valueDate }, this.planDataSetReference.ID).then(() => {
            this.planDataSetReference.endDate = valueDate.toString();
            this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
            this.uiService.dismissLoader();
          });
        }
      }
    })
  }

  isSameDateWithoutTime(firstDate: Date, secondDate: Date) {
    return firstDate.setHours(0, 0, 0, 0) === secondDate.setHours(0, 0, 0, 0);
  }

  private async getDateTimePopover() {
    return await this.popoverCtrl.create({
      component: IndDatetimeFormComponent,
      componentProps: {
        currentViewPage: CurViewPageType.MarketingBusinessPlan,
        startDateTimeValue: this.planDataSetReference?.startDate ? moment(new Date(parseInt(this.planDataSetReference.startDate))).format() : null,
        endDateTimeValue: this.planDataSetReference?.endDate ? moment(new Date(parseInt(this.planDataSetReference.endDate))).format() : null,
      },
      cssClass: "datetime-popover"
    });
  }

  private async isActivityInPast(newStartDate) {
    const allActivities = await this.getActivitiesByAccountPlan();
    const activityFollowup = this.marketingPlanManagementDataService.getFollowupActivity(this.planDataSetReference);
    const linkedMeeting = await this.marketingPlanManagementDataService.fetchMeetingsTimeline(this.planDataSetReference.ID);
    const linkedTimeline = await this.marketingPlanManagementDataService.fetchPhoneCallsTimeline(this.planDataSetReference.ID);

    console.log(linkedMeeting, linkedTimeline);


    allActivities.push(...activityFollowup)
    allActivities.push(...linkedMeeting)
    allActivities.push(...linkedTimeline)

    const isConflict = allActivities.some(actvity => {
      actvity.scheduledStart.setHours(0, 0, 0, 0);
      return actvity.scheduledStart < new Date(newStartDate).setHours(0, 0, 0, 0);
    });

    return isConflict;
  }

  private async getActivitiesByAccountPlan() {
    let allActivities = [];
    // allActivities = this.activityService.activities.filter((item: any) => {
    //   if (item.type === ActivityType.Appointment) {
    // //     return item.associatedAccountPlans.some((it) => it.accountPlanId === this.planDataSetReference.ID);
    // //   } else if (item.type === ActivityType.PhoneCall) {
    // //     return item.accountPlanId === this.planDataSetReference.ID;
    // //   } else if (item.type === ActivityType.FollowUp) {
    // //     return item.accountPlanId === this.planDataSetReference.ID;
    //   }
    // });
    return allActivities;
  }

  private async openMarketingPlans() {
    if (this.marketingPlansManagementOfflineService.marketingPlans.length > 0) {
      let listData = [];
      for (let i = 0; i < this.marketingPlansManagementOfflineService.marketingPlans.length; i++) {
        const marketingPlan = this.marketingPlansManagementOfflineService.marketingPlans[i];
        if (marketingPlan.ID && marketingPlan.brandPlanName && marketingPlan.ID != this.planDataSetReference.ID) {
          let isSelected: boolean = false;
          if (marketingPlan.ID == this.planDataSetReference.parentMarketingBusinessPlanId) {
            isSelected = true;
          }
          let obj = {
            title: marketingPlan.brandPlanName,
            id: marketingPlan.ID,
            primaryTextRight: marketingPlan.brandPlanName ? marketingPlan.brandPlanName : '',
            secondaryTextLeft: '',
            showEndIcon: !this.isReadOnlyMode,
            mainItemCssClass: 'selector-item',
            isItemSelectedForSelectionView: isSelected,
            endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
            endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
            showArrow: false,
            arrowType: '',
            eventOwnerId: this.planDataSetReference.ownerID,
          }
          listData.push(obj);
        }
      }

      const listDetail: MainToolTemplateDetail = {
        title: this.translate.instant('MARKETING_BUSINESS_PLANS'),
        dividerTitle: this.translate.instant('ALL_MARKETING_BUSINESS_PLANS'),
        isSearchEnabled: !this.isReadOnlyMode,
        showLeftHeaderButton: true,
        leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
        leftHeaderBtnText: this.translate.instant('CANCEL'),
        showRightHeaderButton: !this.isReadOnlyMode,
        rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
        rightHeaderBtnText: this.translate.instant('DONE'),
        orderByPropertyName: 'primaryTextRight',
        searchTitle: '',
        hideAllItemsList: this.isReadOnlyMode,
        isListSelectionEnabled: !this.isReadOnlyMode,
        listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
        navOptions: { animate: false },
        eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handledMarketingPlansSelection(data, eventTarget, refData),
        searchHandler: (text: string) => this._handleParentoppComponentSearch(text),
        data: listData
      };

      this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isGroupedView: false }, PageName.MainToolTemplateComponent);
    }
  }

  private _handleParentoppComponentSearch(text: string): string[] {
    return this.marketingPlansManagementOfflineService.marketingPlans.filter(marketingPlan => {
      return marketingPlan.brandPlanName && marketingPlan.ID && marketingPlan.ID != this.planDataSetReference.ID && marketingPlan.brandPlanName.trim().toLowerCase().includes(text.trim().toLowerCase());
    }).map(opp1 => opp1.ID);
  }

  async _handledMarketingPlansSelection(newSelectedMarketingPlan, event, data) {
    if(event !== 'RightHeaderButtonClick') return;

    newSelectedMarketingPlan = newSelectedMarketingPlan.selectedItems.length > 0 ? newSelectedMarketingPlan.selectedItems[0] : null;

    let reqPayload = {
      parentMarketingBusinessPlan : newSelectedMarketingPlan ? newSelectedMarketingPlan.id : ""
    }

    await this.uiService.displayLoader();
    await this.marketingPlanManagementDataService.saveMarketPlan(reqPayload, this.planDataSetReference.ID).then(async (newMarketPlan) => {
      if (newSelectedMarketingPlan) {
        this.planDataSetReference.parentMarketingBusinessPlanId = newSelectedMarketingPlan.id
        this.planDataSetReference.parentMarketingBusinessPlanName = newSelectedMarketingPlan.primaryTextRight;
      } else {
        this.planDataSetReference.parentMarketingBusinessPlanId = ""
        this.planDataSetReference.parentMarketingBusinessPlanName = "";
      }
      await this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
      this.marketingPlansManagementOfflineService.marketingPlans$.next(this.marketingPlansManagementOfflineService.marketingPlans);
      this.uiService.dismissLoader();
    });
  }

  ngOnDestroy() {
    this.accountService.isMarketingBusinessPlanInvoked = false;
    this.ngUnSubscibe$.next(true);
    this.ngUnSubscibe$.complete();
  }

  private updateFocusArea(event) {
    if (event && event.target.value !== undefined && event.target.value !== "") {
      this.uiService.displayLoader();
      this.marketingPlanManagementDataService.saveMarketPlan({ indskr_focusarea: event.target.value }, this.planDataSetReference.ID).then(async () => {
        this.planDataSetReference.focusArea = event.target.value;
        if(this.isAutoSubjectEnabled) {
          await this.marketingPlanManagementDataService.getMarketingBusinessPlans(false, this.planDataSetReference.ID).then(()=>{
            const newMarketPlanSubject = this.updateAutoSubject();
            this.planDataSetReference.brandPlanName = newMarketPlanSubject;
            this.marketingPlanManagementDataService.saveMarketPlan({ indskr_name: newMarketPlanSubject }, this.planDataSetReference.ID)
          });
        }
        await this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
        this.marketingPlansManagementOfflineService.marketingPlans$.next(this.marketingPlansManagementOfflineService.marketingPlans);
        this.uiService.dismissLoader();
      });
    }
  }

  private updateAutoSubject() {
    const yearOfCreation = this.planDataSetReference?.createdOnString ? ' - ' + this.planDataSetReference.createdOnString : '';
    const businessUnitsString = this.businessUnitsString ?  ' - ' + this.businessUnitsString :  '';
    const focusAreaString = this.planDataSetReference?.focusArea ?  ' - ' + this.planDataSetReference?.focusArea : '';

    return this.translate.instant('MARKETING_BUSINESS_PLAN') + yearOfCreation + businessUnitsString + focusAreaString;
  }

  private async openBusinessUnitSelection() {
    let options = [];

    if(this.isReadOnlyMode) {
      if(!_.isEmpty(this.planDataSetReference.associationBusinessUnits)) {
        this.planDataSetReference.associationBusinessUnits.forEach(bu=>{
          let optionData = {id: bu.associationBuID, title: bu.associationBuName}
          options.push(optionData)
        });
      }else {
        return;
      }
    }else {
      //selectable BU options
      if(!_.isEmpty(this.sharedDataService.businessUnits)) {
        const selectableBUs = _.cloneDeep(this.sharedDataService.businessUnits);
        selectableBUs.forEach(bu=>{
          if(bu.businessunitid) {
            let isSelected: boolean = false;
            if(!_.isEmpty(this.planDataSetReference.associationBusinessUnits)) {
              const index = this.planDataSetReference.associationBusinessUnits.findIndex(x=>x.associationBuID == bu.businessunitid);
              if(index > -1) {
                isSelected = true;
              }
            }
            let optionData = { id: bu.businessunitid, title: bu.businessunitname, isSelected: isSelected }
            options.push(optionData);
          }
        });
        options = this.marketingPlansManagementOfflineService.sortListByFielName(options, 'title');
      }
    }

    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'business-unit-select',
      data: options,
      isMultipleSelectionEnabled: !this.isReadOnlyMode,
      showCancelandDoneBtn: !this.isReadOnlyMode,
      isReadOnly: this.isReadOnlyMode
    };

    let dropdownPopover = await this.popoverCtrl.create({
      component: IndDropdownListComponent,
      componentProps: {
        viewData: dropdownListDetail
      },
      cssClass: 'dropdown-list-view',
      event: event
    });

    dropdownPopover.onDidDismiss().then(async (obj) => {
      if (obj && obj.data && obj.data.isDone) {
        const data = obj.data;
        let reqPayload = [];
        let selectedBuData = [];
        //check existing assoication bu marketing plan ids - deleted all existing association bu
        if(!_.isEmpty(this.planDataSetReference.associationBusinessUnits)) {
          this.planDataSetReference.associationBusinessUnits.forEach(item=>{
            if(item.associationBuMarketingPlanID) {
              let tempPayload = { id: item.associationBuMarketingPlanID, deleted: true }
              reqPayload.push(tempPayload);
            }
          });
        }

        if(!_.isEmpty(data.selectedItems)) {
          data.selectedItems.forEach(item=>{
            if(item.isSelected) {
              let tempPayload = { buId: item.id }
              reqPayload.push(tempPayload);
              selectedBuData.push(item);
            }
          });
        }
        this.marketingPlanManagementDataService.saveMarketPlan({ businessunits: reqPayload }, this.planDataSetReference.ID).then(async () => {
          //To update association bu marketing plan ids
          await this.marketingPlanManagementDataService.getMarketingBusinessPlans(false, this.planDataSetReference.ID).then(()=>{
            if(this.isAutoSubjectEnabled) {
              const newMarketPlanSubject = this.updateAutoSubject();
              this.planDataSetReference.brandPlanName = newMarketPlanSubject;
              this.marketingPlanManagementDataService.saveMarketPlan({ indskr_name: newMarketPlanSubject }, this.planDataSetReference.ID)
            }
          });
          await this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
          this.marketingPlansManagementOfflineService.marketingPlans$.next(this.marketingPlansManagementOfflineService.marketingPlans);
          this.uiService.dismissLoader();
        });
      }
    });
    dropdownPopover.present();
  }

  private _updateMarketPlan(){

  }

  private async initSpecialtiesData(){
    await this.contactService.getOfflineSpecialtiesData().then(data=> {
      if(data && Array.isArray(data)){
        this.currentSpecialties = data;
        this.initSubSpecialtiesData();
      }
    });
  }

  private initSubSpecialtiesData(){
    this.currentSubSpecialties = [];
    if(this.planDataSetReference && this.planDataSetReference.primarySpecialtyId){
      let idx = this.currentSpecialties.findIndex(a=> a.indskr_lu_specialtyid == this.planDataSetReference.primarySpecialtyId);
      if(idx >= 0 && this.currentSpecialties[idx].subSpecialties && Array.isArray(this.currentSpecialties[idx].subSpecialties)){
        this.currentSubSpecialties = this.currentSpecialties[idx].subSpecialties;
      }
    }
  }

  private async handleSpecialtySelect(data) {
    if (this.isReadOnlyMode) return;
    setTimeout(() => {
      if(Array.isArray(this.contactService.specialties) && this.contactService.specialties.length > 0){
        this.uiService.displayLoader();
        let listData = [];
        for(let i=0;i< this.contactService.specialties.length;i++){
          const spec = this.contactService.specialties[i];
          if(spec.id && spec.name){
            let isSelected:boolean = false;
            if(spec.id == this.planDataSetReference.primarySpecialtyId){
              isSelected = true;
            }
            let obj = {
              title: spec.name,
              id: spec.id,
              primaryTextRight: spec.name ? spec.name : '',
              secondaryTextLeft: '',
              showEndIcon: !this.isReadOnlyMode,
              mainItemCssClass: 'selector-item',
              isItemSelectedForSelectionView: isSelected,
              endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
              endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
              showArrow: false,
              arrowType: '',
              eventOwnerId: this.planDataSetReference.ownerID,
            }
            listData.push(obj);
          }
        }

        const listDetail: MainToolTemplateDetail = {
          title: this.translate.instant('SPECIALTIES'),
          dividerTitle: this.translate.instant('ALL_SPECIALTIES'),
          isSearchEnabled: !this.isReadOnlyMode,
          showLeftHeaderButton: true,
          leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
          leftHeaderBtnText: this.translate.instant('CANCEL'),
          showRightHeaderButton: !this.isReadOnlyMode,
          rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
          rightHeaderBtnText: this.translate.instant('DONE'),
          orderByPropertyName: 'primaryTextRight',
          searchTitle: '',
          hideAllItemsList: this.isReadOnlyMode,
          isListSelectionEnabled: !this.isReadOnlyMode,
          listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
          navOptions: { animate: false },
          eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handlePrimarySpecialtyComponentEvent(data, eventTarget, refData, 'primaryspecialty'),
          searchHandler: (text: string) => this._handlePrimarySpecialtyComponentSearch(text, 'primaryspecialty'),
          data: listData
        };
        this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isGroupedView: false }, PageName.MainToolTemplateComponent);
      }
    }, 10);
  }

  private async _handlePrimarySpecialtyComponentEvent(data:any,eventTarget:string, refData:MainToolTemplateDetail, fieldName:string){
    if (!this.isReadOnlyMode && eventTarget && eventTarget === 'RightHeaderButtonClick' && data.isDone) {
      let payload;
      if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.planDataSetReference.primarySpecialtyId) {
        payload = {
          indskr_primaryspecialty:  data.selectedItems[0].id,
          indskr_subspecialty: "",
        };
      }else if(data && data.hasOwnProperty('selectedItems') && data.selectedItems.length == 0 && this.planDataSetReference.primarySpecialtyId){
        payload = {
          indskr_primaryspecialty: "",
          indskr_subspecialty: ""
        };
      }
      this.uiService.displayLoader();
      this.marketingPlanManagementDataService.saveMarketPlan(payload, this.planDataSetReference.ID).then(async () => {
        if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.planDataSetReference.primarySpecialtyId) {
          this.planDataSetReference.primarySpecialtyId = data.selectedItems[0].id;
          this.planDataSetReference.primarySpecialtyName = data.selectedItems[0].title;
          this.planDataSetReference.subSpecialtyId = '';
          this.planDataSetReference.subSpecialtyName = '';
        } else if (data && data.hasOwnProperty('selectedItems') && data.selectedItems.length == 0 && this.planDataSetReference.primarySpecialtyId) {
          this.planDataSetReference.primarySpecialtyId = '';
          this.planDataSetReference.primarySpecialtyName = '';
          this.planDataSetReference.subSpecialtyId = '';
          this.planDataSetReference.subSpecialtyName = '';
        }
        this.initSubSpecialtiesData();
        await this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
        this.marketingPlansManagementOfflineService.marketingPlans$.next(this.marketingPlansManagementOfflineService.marketingPlans);
        this.uiService.dismissLoader();
      });
    }
  }

  private _handlePrimarySpecialtyComponentSearch(text: string, formField: string): string[] {
    return this.contactService.specialties.filter(spec => {
      return spec.name && spec.id && spec.name.trim().toLowerCase().includes(text.trim().toLowerCase());
    }).map(spec1 => spec1.id);
  }

  private async handleSubSpecialtySelect(data) {
    if (this.isReadOnlyMode) return;
    setTimeout(() => {
      if(Array.isArray(this.currentSubSpecialties) && this.currentSubSpecialties.length > 0){
        this.uiService.displayLoader();
        let listData = [];
        for(let i=0;i< this.currentSubSpecialties.length;i++){
          const spec = this.currentSubSpecialties[i];
          if(spec.indskr_subspecialtiesid && spec.indskr_name){
            let isSelected:boolean = false;
            if(spec.indskr_subspecialtiesid == this.planDataSetReference.subSpecialtyId){
              isSelected = true;
            }
            let obj = {
              title: spec.indskr_name,
              id: spec.indskr_subspecialtiesid,
              primaryTextRight: spec.indskr_name ? spec.indskr_name : '',
              secondaryTextLeft: '',
              showEndIcon: !this.isReadOnlyMode,
              mainItemCssClass: 'selector-item',
              isItemSelectedForSelectionView: isSelected,
              endIconType: isSelected ? 'indegene-selectors-checkmark-icon' : 'indegene-selectors-add-icon',
              endIconCssClass: isSelected ? 'checkmark-icon' : 'add-icon',
              showArrow: false,
              arrowType: '',
              eventOwnerId: this.planDataSetReference.ownerID,
            }
            listData.push(obj);
          }
        }

        const listDetail: MainToolTemplateDetail = {
          title: this.translate.instant('SUB_SPECIALTIES'),
          dividerTitle: this.translate.instant('ALL_SUB_SPECIALTIES'),
          isSearchEnabled: !this.isReadOnlyMode,
          showLeftHeaderButton: true,
          leftHeaderBtnImgSrc: 'assets/imgs/header_cancel.svg',
          leftHeaderBtnText: this.translate.instant('CANCEL'),
          showRightHeaderButton: !this.isReadOnlyMode,
          rightHeaderBtnImgSrc: 'assets/imgs/header_complete.svg',
          rightHeaderBtnText: this.translate.instant('DONE'),
          orderByPropertyName: 'primaryTextRight',
          searchTitle: '',
          hideAllItemsList: this.isReadOnlyMode,
          isListSelectionEnabled: !this.isReadOnlyMode,
          listSelectionType: MainToolTemplateListSelectionType.SINGLESELECTION,
          navOptions: { animate: false },
          eventsHandler: (data: any, eventTarget: string, refData: MainToolTemplateDetail) => this._handleSubSpecialtyComponentEvent(data, eventTarget, refData, 'subspecialty'),
          searchHandler: (text: string) => this._handleSubSpecialtyComponentSearch(text, 'subspecialty'),
          data: listData
        };
        this.navService.pushWithPageTracking(MainToolTemplateComponent, PageName.NothingSelectedView, { viewData: listDetail, isGroupedView: false }, PageName.MainToolTemplateComponent);
      }
    }, 10);
  }

  private async _handleSubSpecialtyComponentEvent(data:any,eventTarget:string, refData:MainToolTemplateDetail, fieldName:string){
    if (!this.isReadOnlyMode && eventTarget && eventTarget === 'RightHeaderButtonClick' && data.isDone) {
      let payload;
      if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.planDataSetReference.subSpecialtyId) {
        payload = {
          indskr_subspecialty:  data.selectedItems[0].id
        };
      }else if(data && data.hasOwnProperty('selectedItems') && data.selectedItems.length == 0 && this.planDataSetReference.subSpecialtyId){
        payload = {
          indskr_subspecialty: "",
        };
      }
      this.uiService.displayLoader();
      this.marketingPlanManagementDataService.saveMarketPlan(payload, this.planDataSetReference.ID).then(async () => {
        if (data && data.selectedItems && data.selectedItems.length == 1 && data.selectedItems[0].id != this.planDataSetReference.subSpecialtyId) {
          this.planDataSetReference.subSpecialtyId = data.selectedItems[0].id;
          this.planDataSetReference.subSpecialtyName = data.selectedItems[0].title;
        }else if(data && data.hasOwnProperty('selectedItems') && data.selectedItems.length == 0 && this.planDataSetReference.subSpecialtyId){
          this.planDataSetReference.subSpecialtyId = '';
          this.planDataSetReference.subSpecialtyName = '';
        }
        await this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
        this.marketingPlansManagementOfflineService.marketingPlans$.next(this.marketingPlansManagementOfflineService.marketingPlans);
        this.uiService.dismissLoader();
      });
    }
  }

  private _handleSubSpecialtyComponentSearch(text: string, formField: string): string[] {
    return this.currentSubSpecialties.filter(spec => {
      return spec.indskr_name && spec.indskr_subspecialtiesid && spec.indskr_name.trim().toLowerCase().includes(text.trim().toLowerCase());
    }).map(spec1 => spec1.indskr_subspecialtiesid);
  }

  private async handleTypeField(data) {
    if (this.isReadOnlyMode) return;
    const typesData = this.marketingPlansManagementOfflineService.marketingBusinessPlanTypes;
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'Marketing-Plan-Type-Select',
      data: typesData && typesData.length ? typesData.map(a=> {
        return {
          id: a.id,
          title: a.name,
          isSelected: a.id == this.planDataSetReference.type,
        }
      }) : [],
    };
    let _dropdownPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data });
    _dropdownPopover.onDidDismiss().then(async (data:any) => {
      data = data.data;
      if (data && data.selectedItems && data.selectedItems.length == 1 && (!this.planDataSetReference.type || (this.planDataSetReference.type && data.selectedItems[0].id != this.planDataSetReference.type) ) ) {
        const payload = {
          indskr_type:  data.selectedItems[0].id
        };
        this.uiService.displayLoader();
        this.marketingPlanManagementDataService.saveMarketPlan(payload, this.planDataSetReference.ID).then(async () => {
          this.planDataSetReference.type = data.selectedItems[0].id;
          this.planDataSetReference.typeFormatted = data.selectedItems[0].title
          await this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
          this.marketingPlansManagementOfflineService.marketingPlans$.next(this.marketingPlansManagementOfflineService.marketingPlans);
          this.uiService.dismissLoader();
        });
      }
      _dropdownPopover = undefined;
    });
    _dropdownPopover.present();
  }

  private async handlePurposeSelect(data) {
    if (this.isReadOnlyMode) return;
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'Marketing-Plan-Purpose-Select',
      data: [
        {
          title: "Sales",
          id: '548910000',
          isSelected: this.planDataSetReference.purpose && '548910000' == this.planDataSetReference.purpose.toString(),
        },
        {
          title: "Education",
          id: '548910001',
          isSelected: this.planDataSetReference.purpose && '548910001' == this.planDataSetReference.purpose.toString(),
        },
        {
          title: "Risk",
          id: '548910002',
          isSelected: this.planDataSetReference.purpose && '548910002' == this.planDataSetReference.purpose.toString(),
        },
      ],
    };
    let _dropdownPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data });
    _dropdownPopover.onDidDismiss().then(async (data:any) => {
      data = data.data;
      if (data && data.selectedItems && data.selectedItems.length == 1 && (!this.planDataSetReference.purpose || (this.planDataSetReference.purpose && data.selectedItems[0].id != this.planDataSetReference.purpose.toString()) ) ) {
        const payload = {
          indskr_purpose:  parseInt(data.selectedItems[0].id),
        };
        this.uiService.displayLoader();
        this.marketingPlanManagementDataService.saveMarketPlan(payload, this.planDataSetReference.ID).then(async () => {
          this.planDataSetReference.purpose = parseInt(data.selectedItems[0].id);
          this.planDataSetReference.purposeFormatted = data.selectedItems[0].title
          await this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
          this.marketingPlansManagementOfflineService.marketingPlans$.next(this.marketingPlansManagementOfflineService.marketingPlans);
          this.uiService.dismissLoader();
        });
      }
      _dropdownPopover = undefined;
    });
    _dropdownPopover.present();
  }

  private async handleBooleanField(id,data){
    if (this.isReadOnlyMode) return;
    let isFieldSelected:string;
    switch (id) {
      case 'hcp-training-completed-field':
        if(this.planDataSetReference.hcpTrainingCompleted != null){
          if(this.planDataSetReference.hcpTrainingCompleted) {
            isFieldSelected = "YES";
          }else {
            isFieldSelected = "NO";
          }
        }
        break;
      case 'multiyear-campaign-field':
        if(this.planDataSetReference.multiyearCampaign != null){
          if(this.planDataSetReference.multiyearCampaign) {
            isFieldSelected = "YES";
          }else {
            isFieldSelected = "NO";
          }
        }
        break;
      case 'npi-approved-by-account-field':
        if(this.planDataSetReference.npiApprovedByAccount != null){
          if(this.planDataSetReference.npiApprovedByAccount) {
            isFieldSelected = "YES";
          }else {
            isFieldSelected = "NO";
          }
        }
        break;
      case 'npi-process-started-field':
        if(this.planDataSetReference.npiProcessStarted != null){
          if(this.planDataSetReference.npiProcessStarted) {
            isFieldSelected = "YES";
          }else {
            isFieldSelected = "NO";
          }
        }
        break;
    }
    let dropdownListDetail: IndDropdownListDetailModel = {
      id: 'Marketing-Plan-Boolean-Field-Select',
      data: [
        {
          title: this.translate.instant("YES"),
          id: 'YES',
          isSelected: isFieldSelected == "YES",
        },
        {
          title: this.translate.instant("NO"),
          id: 'NO',
          isSelected: isFieldSelected == "NO",
        },
      ],
    };
    let _dropdownPopover = await this.popoverCtrl.create({ component: IndDropdownListComponent, componentProps: { viewData: dropdownListDetail }, cssClass: 'dropdown-list-view', event: data });
    _dropdownPopover.onDidDismiss().then(async (data:any) => {
      data = data.data;
      if (data && data.selectedItems && data.selectedItems.length == 1 && (!isFieldSelected || (isFieldSelected && data.selectedItems[0].id != isFieldSelected) ) ) {
        let payload;
        switch (id) {
          case 'hcp-training-completed-field':
            payload = {
              indskr_hcptrainingcompleted:  data.selectedItems[0].id == "YES" ? true : false,
            };
            break;
          case 'multiyear-campaign-field':
            payload = {
              indskr_multiyearcampaign:  data.selectedItems[0].id == "YES" ? true : false,
            };
            break;
          case 'npi-approved-by-account-field':
            payload = {
              indskr_npiapprovedbyaccount:  data.selectedItems[0].id == "YES" ? true : false,
            };
            break;
          case 'npi-process-started-field':
            payload = {
              indskr_npiprocessstarted:  data.selectedItems[0].id == "YES" ? true : false,
            };
            break;
        }
        this.uiService.displayLoader();
        this.marketingPlanManagementDataService.saveMarketPlan(payload, this.planDataSetReference.ID).then(async () => {
          switch (id) {
            case 'hcp-training-completed-field':
                this.planDataSetReference.hcpTrainingCompleted = data.selectedItems[0].id == "YES" ? true : false;
              break;
            case 'multiyear-campaign-field':
                this.planDataSetReference.multiyearCampaign = data.selectedItems[0].id == "YES" ? true : false;
              break;
            case 'npi-approved-by-account-field':
                this.planDataSetReference.npiApprovedByAccount = data.selectedItems[0].id == "YES" ? true : false;
              break;
            case 'npi-process-started-field':
                this.planDataSetReference.npiProcessStarted =  data.selectedItems[0].id == "YES" ? true : false;
              break;
          }
          await this.marketingPlanManagementDataService.saveMarketingPlanInLocal(this.planDataSetReference);
          this.marketingPlansManagementOfflineService.marketingPlans$.next(this.marketingPlansManagementOfflineService.marketingPlans);
          this.uiService.dismissLoader();
        });
      }
      _dropdownPopover = undefined;
    });
    _dropdownPopover.present();
  }

}
