import {Component, ElementRef, Input, ViewChild} from "@angular/core";
import {ComponentViewMode, UIService} from "@omni/services/ui/ui.service";
import {Subject} from "rxjs";
import {DynamicFormType, DynamicFormComponentModel} from "@omni/models/dynamic-form-component.model";
import {ContactOfflineService} from "@omni/services/contact/contact.service";
import {AuthenticationService} from "@omni/services/authentication.service";
import {DeviceService} from "@omni/services/device/device.service";
import {TranslateService} from "@ngx-translate/core";
import {DynamicFormsService} from "@omni/services/dynamic-forms/dynamic-forms-service";
import {GlobalUtilityService} from "@omni/services/global-utility.service";
import {NavigationService, PageName} from "@omni/services/navigation/navigation.service";
import {LoadingController, ModalController, NavParams, PopoverController} from "@ionic/angular";
import {ContactDataService} from "@omni/data-services/contact/contact.data.service";
import {NotificationService, ToastStyle} from "@omni/services/notification/notification.service";
import {AlertService} from "@omni/services/alert/alert.service";
import {TrackingEventNames, TrackService} from "@omni/services/logging/tracking.service";
import {EventName, EventsService} from "@omni/services/events/events.service";
import {DiskService} from "@omni/services/disk/disk.service";
import {MdmService} from "@omni/services/mdm/mdm.service";
import {FooterService, FooterViews} from "@omni/services/footer/footer.service";
import {takeUntil} from "rxjs/operators";
import {DynamicForm, FormType, BusinessProcessType, EditConfig} from "@omni/classes/dynamic-form/dynamic-form.class";
import {DynamicFormComponent} from "@omni/components/shared/ind-dynamic-form/ind-dynamic-form";
import {NothingSelectedView} from "@omni/components/shared/nothing-selected-view/nothing-selected-view";
import {DB_KEY_PREFIXES} from "@omni/config/pouch-db.config";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { ChangeRequestType } from '@omni/classes/mdm/source-type-optionset.class';
import _, {isEmpty} from 'lodash';
import { IONote } from "@omni/classes/io/io-note.class";
import { isBefore } from "date-fns";
import { ContactPageComponent } from "@omni/components/contact/contact-page/contact-page";
import { HopQRCodeModal } from '@omni/components/contact/hop-qr-code-modal/hop-qr-code-modal';
import { AlertWithInput } from "@omni/components/shared/alert-with-input/alert-with-input";

@Component({
  selector: 'cr-details',
  templateUrl: 'cr-details.html',
  styleUrls:['cr-details.scss']
})
export class CrDetailsComponent {

  public compViewMode = ComponentViewMode;
  //public configuredFields:ConfiguredFields[]=[];
  public isEditFeatureEnabled:boolean = false;
  public isEditCustomerForEventEnabled:boolean = false;
  public isCustomerNotesEnabled:boolean = false;
  private unsubscribe$ = new Subject<void>();
  public notesSectionHeaderViewData:IndSectionHeaderViewDataModel;
  public noteTextHistory:Array<IONote> = [];
  public isScrapEnabled: boolean = false;
  private globalCustomerText:string = '';
  private editContactCrRecordLinkedEntities = [];

  @Input()
  public displayFormDetails;
  public approvalRequest;
  public rawFormData;
  public accessedFrom;
  @Input()
  public dynamicFormType:DynamicFormType;
  public pageTitleControls = [];
  public contactCRLinkedEntityDBPrefix: string = DB_KEY_PREFIXES.CONTACT_CR_LINKED_ENTITY;
  public type: BusinessProcessType = BusinessProcessType.OneKey;

  @ViewChild('scrollTop', { read: ElementRef, static: false }) scrollTop;

  private selectedChangeRequest: any = undefined;

  constructor(
    public contactService: ContactOfflineService,
    public contactDataService: ContactDataService,
    public authService: AuthenticationService,
    private utilityService: GlobalUtilityService,
    private navService: NavigationService,
    public uiService: UIService,
    public popoverCtrl: PopoverController,
    public mdmService: MdmService,
    public footerService:FooterService,
    private device: DeviceService,
    private dynamicFormsService: DynamicFormsService,
    private translate: TranslateService,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private disk: DiskService,
    private navParams: NavParams,
    private readonly events: EventsService,
  ) {

    this.mdmService.currentSelectedObs$.pipe(takeUntil(this.unsubscribe$)).subscribe(()=> {
      this.initContactInfo();
    })

    switch (this.utilityService.globalCustomerText) {
      case 'Customer':
        this.globalCustomerText = this.translate.instant('CUSTOMER');
        break;
      case 'Stakeholder':
        this.globalCustomerText = this.translate.instant('STAKEHOLDER');
        break;
      default:
        this.globalCustomerText = this.utilityService.globalCustomerText;
        break;
    }

  }

  ngOnInit() {
    this.footerService.updateButtons([], true);
  }


  ngOnDestroy() {
    this.mdmService.currentSelected.next(undefined);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(){
    this.initContactInfo();
    if(this.scrollTop) this.scrollTop.nativeElement.scrollIntoView(false);
  }

  private async initContactInfo() {
    let selectedCR = this.mdmService.currentSelected.getValue();
    if (selectedCR) {
      this.selectedChangeRequest = selectedCR;
      if(selectedCR['indskr_contactsourcetype'] && selectedCR['indskr_contactsourcetype'] == ChangeRequestType.BUSINESS && selectedCR['statecode'] == 0 && selectedCR['statuscode'] == 548910009){
        this.isScrapEnabled = this.isEditFeatureEnabled = true;
      }else{
        this.isScrapEnabled = this.isEditFeatureEnabled = false;
      }
      if (this.approvalRequest) this.isScrapEnabled = this.isEditFeatureEnabled = false;
      this.rawFormData = selectedCR;
      if (this.rawFormData?.indskr_mdm == BusinessProcessType.SanofiChina) {
        this.type = BusinessProcessType.SanofiChina;
        if (!_.isEmpty(this.rawFormData?.indskr_hcponeid)) this.footerService.updateButtons(['hopQrCode'], true);
        if (this.approvalRequest) {
          this.footerService.initButtons(FooterViews.DCR_APPROVALS);
          if (this.approvalRequest.approvalStatus == this.translate.instant("PENDING") && !this.device.isOffline){
            this.footerService.enableButtons(['approveDCRRequest','rejectDCRRequest']);
          }else{
            this.footerService.disableButton(['approveDCRRequest','rejectDCRRequest']);
          }
        }
      }
      this.initNotes();
      this.initPageTitleControls();
      await this._initCustomerNotesViewData();
    }
  }

  private async initNotes() {
    this.noteTextHistory = [];
    let dbNotes = await this.disk.retrieve('indskr_approvalactivity');
    if(dbNotes && dbNotes.raw && Array.isArray(dbNotes.raw) && dbNotes.raw.length != 0){
      dbNotes.raw.forEach(item=>{
        if(this.rawFormData &&
        item['indskr_contactcrid'] == this.rawFormData['indskr_contactcrid'] &&
        item['indskr_approvalactivity.indskr_approvalactivityid'] &&
        item['indskr_approvalactivity.indskr_approver'] &&
        item['indskr_approvalactivity.indskr_reason'] &&
        item['indskr_approvalactivity.createdon']){
          const payload = {
            annotationid: item['indskr_approvalactivity.indskr_approvalactivityid'],
            createdon: item['indskr_approvalactivity.createdon'] ? new Date(item['indskr_approvalactivity.createdon']).getTime().toString() : null,
            notetext: item['indskr_approvalactivity.indskr_reason'],
            ownerName: item['indskr_approvalactivity.indskr_approver@OData.Community.Display.V1.FormattedValue'],
            ownerid: item['indskr_approvalactivity.indskr_approver'],
          };
          let currentNote = new IONote(payload);
          let idx = this.noteTextHistory.findIndex(note => note.noteId == currentNote.noteId);
          if(idx >= 0){
            this.noteTextHistory[idx] = currentNote;
          }else{
            this.noteTextHistory.push(currentNote);
          }
        }
      });
      this._sortCustomerNotes();
    }
  }

  private _sortCustomerNotes(){
    if(this.noteTextHistory && this.noteTextHistory.length > 1){
      this.noteTextHistory = this.noteTextHistory.sort((a,b)=>{
        return (isBefore(a.createdTime, b.createdTime) ? 1 : -1);
      });
    }
  }

  private initPageTitleControls(): void {
    let buttons = [];

     if (this.accessedFrom === 'NotificationDetails') {
      buttons.push({
        id: 'close',
        icon: "chevron-back-outline",
        isDisabled: false,
        align: "left"
      });

      buttons.push({
        id: "goToContactsTool",
        imgSrc: 'assets/imgs/open_in_new_white.svg',
        name: this.translate.instant('OPEN'),
        isDisabled: false,
        align: "right"
      });
     }
     if (this.approvalRequest) {
      buttons.push(
        {
          id: "reject",
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('REJECT'),
          isDisabled: this.device.isOffline || this.approvalRequest.approvalStatus !== this.translate.instant("PENDING"),
          align: "right"
        }
      );
      buttons.push(
        {
          id: "approve",
          imgSrc: 'assets/imgs/header_complete.svg',
          name: this.translate.instant('APPROVE'),
          isDisabled: this.device.isOffline || this.approvalRequest.approvalStatus !== this.translate.instant("PENDING"),
          align: "right"
        }
      );
     }
    if(this.isScrapEnabled && !this.approvalRequest) {
      buttons.push({
          id: 'scrap',
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.translate.instant('SCRAP'),
          isDisabled: false,
          align: "right"
        },
        // {
        //   id: 'submit',
        //   imgSrc: 'assets/imgs/header_send.svg',
        //   name: this.translate.instant('SUBMIT'),
        //   isDisabled: false,
        //   align: "right"
        // }
      )
    }
    this.pageTitleControls = buttons
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'close':
        this.closepage();
        break;
      case 'goToContactsTool':
        this.handleGoToContactsTool();
        break;
      case 'scrap':
        this.scrapChangeRequest();
        break;
      case 'approve':
        this.handleStatusUpdate(id);
        break;
      case 'reject':
        this.handleStatusUpdate(id);
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }

  private async handleStatusUpdate(id) {
    const popover = await this.popoverCtrl.create({
      component: AlertWithInput,
      componentProps: {
        header: (id == 'approve' ? this.translate.instant("CONFIRM_APPROVE") : this.translate.instant("CONFIRM_REJECT") ),
        message: this.translate.instant("ENTER_COMMENTS"),
        inputs: [],
        cancelText: this.translate.instant('CANCEL'),
        confirmText: (id == 'approve' ? this.translate.instant("APPROVE") : this.translate.instant("REJECT") ),
        Placeholder: this.translate.instant('Comments'),
        isMandatory: (id == 'approve' ? false : true ),
      },
      cssClass: this.device.isNativeApp ? 'native-alert-with-input-list-view' : 'alert-with-input-list-view',
      backdropDismiss: true,
      event: this.device.isNativeApp ? event : null
    });
    popover.present();
    popover.onDidDismiss().then(async (res) => {
      if (res.data && res.data.role && res.data.role == 'ok') {
        const payload = {
          indskr_reason: res.data.selectedOtherItem,
          statuscode: (id == 'approve' ? 548910001 : 548910002),
        }
        this.uiService.displayLoader();
        this.contactService.updateDCRApprovalStatusOnline(payload,this.approvalRequest).then(a=> {
          this.approvalRequest.approvalStatus = (id == 'approve' ? 'Approved' : 'Rejected');
          this.initContactInfo();
          this.events.publish(EventName.DCR_APPROVAL_SUBMITTED);
          this.uiService.dismissLoader();
        }).catch(err=>{
          this.uiService.dismissLoader();
          console.log(err);
        });
      }
    });
  }

  closepage() {
    if (this.accessedFrom === 'NotificationDetails') {
      this.footerService.initButtons(FooterViews.None);
      this.navService.popChildNavPageWithPageTracking();

    } else {
      this.mdmService.currentSelected.next(undefined);
      this.selectedChangeRequest = undefined;
      this.uiService.activeView = "";
      this.navService.setChildNavRightPaneView(false);
      this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ContactPageComponent);
    }
  }

  private scrapChangeRequest() {
    if(this.rawFormData) {
      this.alertService.showAlert({
        header: this.translate.instant('SCRAP_CHANGE_REQUEST'),
        message: this.translate.instant('R_U_SURE_SCRAP_CR')
      }, this.translate.instant('SCRAP')
      ).then(async res => {
        if (res.role == "ok") {
          this.uiService.displayLoader();
          
          //add mandatory fields
          let data = {
            'statecode' : 1,
            'statuscode': 548910007,
            "indskr_contactcrid": this.rawFormData['indskr_contactcrid']
          }

          /*************Edit business Change Request in offline*************/
           if (this.contactDataService.isOfflineBusinessCR(data)) {
            this.contactDataService.addToOfflineBusinessCRIds(data.indskr_contactcrid);
            await this.disk.createOfflineBusinessCR(data);
               
            //Clear the entry of Contact Change Request from PouchDB and Service layer
            await this.mdmService.deleteContactCRInPouchDB(data.indskr_contactcrid);
            let contactCRs = this.mdmService.contactCRs.getValue();
            let idx;
            if (Array.isArray(contactCRs) && contactCRs.length) {
              idx = contactCRs.findIndex(a => a['indskr_contactcrid'] == data.indskr_contactcrid);
            }
            if (idx >= 0) {
              contactCRs.splice(idx,1);
            }
            this.mdmService.contactCRs.next(contactCRs);
            this.mdmService.currentSelected.next(undefined);
            this.selectedChangeRequest = undefined;
          
            //Set UI to NothingSelectedView
            this.navService.setChildNavRightPaneView(false);
            this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ContactPageComponent);
            this.uiService.dismissLoader();
          } 
          /*************Edit business Change Request in offline*************/
          else {  
            await this.mdmService.scrapBusinessContactDataCR(data,this.rawFormData['indskr_contactcrid']).then((res) => {
              if(res) {
                this.navService.setChildNavRightPaneView(false);
                this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.ContactPageComponent);
              }
              this.uiService.dismissLoader();
            }).catch((err) => {
              this.uiService.dismissLoader();
              console.log("Error scraping Contact change request");
            });
          }
        }
      });
    }
  }

  private async _initCustomerNotesViewData(){
    this.notesSectionHeaderViewData = {
      id: 'contact_notes_section_header',
      title: this.translate.instant('NOTES'),
      controls: [],
    }
  }

  public async editContactCR(event){
    this.dynamicFormsService.okLookUpMapper = [];
    let contactForm: DynamicForm = await this.dynamicFormsService.getFormDefinitionForEntity("indskr_contactcr", FormType.CREATEFORM, BusinessProcessType.Omnipresence);
    let formType = DynamicFormType.CONFIGUREDFORM;
    if(!contactForm || ! this.rawFormData){
     return;
    }
    const contactRaw = this.rawFormData;
    this.contactService.contactInformation = null;
    this.mdmService.addLinkEntities([]);
    for(let i = 0; i < contactForm.metadata.length; i++) {
      const tab = contactForm.metadata[i];
      if(tab && tab.controls && tab.controls.length > 0) {
        for(let j = 0; j < tab.controls.length; j++) {
          let control = tab.controls[j];
          if(control.attributeName && (control.attributeName == "indskr_mdm" || control.attributeName == 'indskr_mdmid')) {
            control.forceHide = true;
          }
        }
      }
    }
    let formDetails: DynamicFormComponentModel = {
      title: this.translate.instant('EDIT') + " " + (this.rawFormData['indskr_firstname']?this.rawFormData['indskr_firstname']:'') + ' ' + (this.rawFormData['indskr_lastname']?this.rawFormData['indskr_lastname']:''),
      LookupEntitySetName: 'contacts',
      showLeftHeaderButton: true,
      enableSubgridDataEditing: true,
      leftHeaderBtnIcon: 'chevron-back-outline',
      showRightHeaderButton: true,
      rightHeaderImgSrc: 'assets/imgs/header_save.svg',
      rightHeaderBtnText: this.translate.instant('CR_SAVE'),
      formMetadata: contactForm,
      formEntityObject: contactRaw,
      mainEntityObjectId: { indskr_contactcrid: this.rawFormData['indskr_contactcrid'],},
      dynamicFormType: formType,
      linkedEntityValue: [],
      navOptions: { animate: false },
      eventsHandler: (data:any, eventTarget:string, refData:DynamicFormComponentModel)=> this._handleDataEditCRFormComponentEvent(data,eventTarget,refData),
    };

    await this.navService.pushChildNavPageWithPageTracking(DynamicFormComponent, PageName.DynamicFormComponent, PageName.ContactPageComponent, {
      TYPE: "OMNIPRESENCE",
      MODE:'EDIT_MODE',
      FORM_DETAIL: formDetails,
      RAW_DATA: this.rawFormData,
      EDIT_CONFIG: EditConfig.BOTH,
      LINKED_ENTITY_DB_PREFIX: "contact_cr_linked_entity",
      REF_ENTITY_NAME: 'indskr_contactcrid'
    });
  }

  private async _handleDataEditCRFormComponentEvent(data, eventTarget, refData:DynamicFormComponentModel){
    if(eventTarget){
      if(eventTarget == 'DONEBUTTONCLICK'){
        let selectedCR = this.mdmService.currentSelected.getValue() || this.selectedChangeRequest;
        if(data && data.formValue && selectedCR) {
          this.uiService.displayLoader();
          let payload = _.cloneDeep(data.formValue);
          payload['indskr_contactcrid'] = selectedCR['indskr_contactcrid'];
          payload["linkEntities"] = this.getBusinessCREditReqPayloadLinkEntities();

          //add mandatory fields
          payload['indskr_contactsourcetype'] = BusinessProcessType.Omnipresence;
          if (payload['statecode']) delete payload.statecode;
          if (payload['statuscode']) delete payload.statuscode;
          
          /*************Edit business Change Request in offline*************/
          if (this.contactDataService.isOfflineBusinessCR(payload)) {

            // To update business information for the business cr saved
            selectedCR = await this.mdmService.fetchContactCRConfiguredDisplayOffline(data, selectedCR, payload['linkEntities']);

            this.contactDataService.addToOfflineBusinessCRIds(payload.indskr_contactcrid);
            await this.disk.createOfflineBusinessCR(payload);
               
            //Clear the entry of Contact Change Request from PouchDB and Service layer
            payload['modifiedon'] = new Date().getTime();
            payload['createdon'] = new Date().getTime();
            //To update status from Pending Approval to Submitted in Offline mode
            payload['statuscode'] = 548910005;

            await this.mdmService.upsertContactCRInPouchDB(payload);
            // await this.diskService.upsertContactCRs(data);
            let contactCRs = this.mdmService.contactCRs.getValue();
            let idx;
            if (Array.isArray(contactCRs) && contactCRs.length) {
              idx = contactCRs.findIndex(a => a['indskr_contactcrid'] == payload['indskr_contactcrid']);
            }
            if (idx >= 0) {
              contactCRs[idx] = payload;
            } else {
              contactCRs.push(payload);
            }
            this.mdmService.contactCRs.next(contactCRs);
            
            this.editContactCrRecordLinkedEntities = [];
            this.mdmService.currentSelected.next(selectedCR);

            let resok = {
              "indskr_contactcrid": payload['indskr_contactcrid']
            };
            data.eventsHandler(resok, 'DISABLEFORM');
            this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
            this.notificationService.notify(this.translate.instant('CR_UPDATE_SUBMIT_MESSAGE', { crType: this.globalCustomerText.toLowerCase(), lastName: payload['indskr_lastname'] }), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
            this.navService.popChildNavPageWithPageTracking();
            await this.uiService.dismissLoader();
          } 
          /*************Edit business Change Request in offline*************/
          else {  
            await this.mdmService.editBusinessContactDataCR(payload,selectedCR['indskr_contactcrid']).then(async (resok: any) => {
              try {
                await this.mdmService.fetchContactCRConfiguredDisplay(false, false, selectedCR).then((res) => {
                  if (res && Array.isArray(res)) {
                    selectedCR = res[0];
                  } else {
                    selectedCR = res;
                  }
                  this.uiService.dismissLoader();
                });
              } catch (e) {
                console.log("error occurred fetching contactCR online: ", e);
                await this.uiService.dismissLoader();
              }
              this.editContactCrRecordLinkedEntities = [];
              this.mdmService.currentSelected.next(selectedCR);
              data.eventsHandler(resok, 'DISABLEFORM');
              this.dynamicFormsService.linkedEntityFormIsDirtyTracker = false;
              this.notificationService.notify(this.translate.instant('CR_UPDATE_SUBMIT_MESSAGE', { crType: this.globalCustomerText.toLowerCase(), lastName: data.formValue['indskr_lastname'] }), 'DynamicFormComponent', "top", ToastStyle.INFO, 3000);
              this.navService.popChildNavPageWithPageTracking();
              await this.uiService.dismissLoader();
            }).catch((err) => {
              this.uiService.dismissLoader();
              console.log("Error creating Contact change request");
            });
          }
        }
      } else if(eventTarget == 'CANCELBUTTONCLICK') {
        if (data && data.displayConfirmation) {
          let cancelText = this.translate.instant("CANCEL");
          if(this.translate.currentLang == 'it') {
            cancelText = this.translate.instant("CANCEL_BUTTON_DISCARD_CHANGE");
          }
          this.alertService.showAlert({
            title: this.translate.instant('SCHEDULER_LIST_ALERT_TITLE'),
            message: this.translate.instant('CONTACT_R_U_WANT_CANCEL_LOSE_CHANGES')
          }, this.translate.instant('DISCARD_CONTACT_CR'), cancelText
          ).then(res => {
            if (res.role == "ok") {
              this.editContactCrRecordLinkedEntities = [];
              this.navService.popChildNavPageWithPageTracking();
            }
          });
        } else {
          this.editContactCrRecordLinkedEntities = [];
          this.navService.popChildNavPageWithPageTracking();
        }
      } else if(eventTarget == 'ADDEDLINKEDENTITY' || eventTarget == 'SCRAPLINKEDENTITY') {
        if (data) {
          if (_.isEmpty(data))
            this.editContactCrRecordLinkedEntities = [];
          else {
            if(eventTarget == 'SCRAPLINKEDENTITY'){
              if(data['linkedEntityAddedNewDataId'] && !data['linkedEntityExistingDataId']){
                let idx = this.editContactCrRecordLinkedEntities.findIndex(a=> a['linkedEntityAddedNewDataId'] == data['linkedEntityAddedNewDataId']);
                this.editContactCrRecordLinkedEntities.splice(idx,1);
              }else if(data['linkedEntityExistingDataId']){
                let idx = this.editContactCrRecordLinkedEntities.findIndex(a=> a['linkedEntityExistingDataId'] == data['linkedEntityExistingDataId']);
                if(idx >= 0){
                  this.editContactCrRecordLinkedEntities[idx] = data;
                }else{
                  this.editContactCrRecordLinkedEntities.push(data);
                }
              }
            }else if(eventTarget == 'ADDEDLINKEDENTITY'){
              if(data['linkedEntityAddedNewDataId'] && !data['linkedEntityExistingDataId']){
                let idx = this.editContactCrRecordLinkedEntities.findIndex(a=> a['linkedEntityAddedNewDataId'] == data['linkedEntityAddedNewDataId']);
                if(idx >= 0){
                  this.editContactCrRecordLinkedEntities[idx] = data;
                }else{
                  this.editContactCrRecordLinkedEntities.push(data);
                }
              }else if(data['linkedEntityExistingDataId']){
                let idx = this.editContactCrRecordLinkedEntities.findIndex(a=> a['linkedEntityExistingDataId'] == data['linkedEntityExistingDataId']);
                if(idx >= 0){
                  this.editContactCrRecordLinkedEntities[idx] = data;
                }else{
                  this.editContactCrRecordLinkedEntities.push(data);
                }
              }
            }
          }
          if (data && data.eventsHandler) {
            data.eventsHandler(data, 'ENABLEFORM');
          }
        }
      }
    }
  }

  private getBusinessCREditReqPayloadLinkEntities(){
    let payload = [];
    this.editContactCrRecordLinkedEntities.forEach(linkEntity=> {
      if(linkEntity['values']){
        let tempItem = linkEntity['values'];
        if(tempItem['guid']){
          tempItem['id'] = tempItem['guid'];
          delete tempItem.guid;
        }
        if(tempItem['statecode'] == 1 && tempItem['statuscode'] == 2){
          // Mark the status as Cancelled 
          switch(linkEntity['entity']){
            case "indskr_accountcontactaffiliationcrs":
              tempItem['statecode'] = 1;
              tempItem['statuscode'] = 548910000;
              break;
            case "indskr_customerspecialtychangerequests":
              tempItem['statecode'] = 1;
              tempItem['statuscode'] = 2;
              break;
            case "indskr_contactrelationshipcrs":
              tempItem['statecode'] = 1;
              tempItem['statuscode'] = 548910003;
              break;
            case "indskr_customeraddresscrs":
              tempItem['statecode'] = 1;
              tempItem['statuscode'] = 548910000;
              break;
          }
        }
        payload.push({
          values: tempItem,
          entity: linkEntity['entity']
        });
      }
    });
    let reduced = [];
    payload.forEach((cur)=> {
      let idx = reduced.findIndex(a=> a.entity == cur.entity);
      if(idx >= 0){
        reduced[idx].values.push(cur.values);
      }else{
        reduced.push({
          values: [cur.values],
          entity: cur.entity,
        });
      }
    });
    return reduced;
  }

  handleGoToContactsTool() {
    this.contactService.isClickedGoToContactsTool = true;
    const selectedContactCrId: string = this.navParams && this.navParams.data && this.navParams.get('contactCrId') ? this.navParams.get('contactCrId') : '';
    this.navService.popChildNavPageWithPageTracking();
    this.navService.pushWithPageTracking(ContactPageComponent, PageName.ContactPageComponent,
      {
        from: 'ContactTool',
        openedToolFromCrDetails: true,
        contactCrId: selectedContactCrId,
        approvalRequest: this.approvalRequest
      }, PageName.ContactPageComponent).then(() => {
        this.navService.setChildNavRightPaneView(true);
      });
     
  }

  public async onFooterButtonClicked(buttonId: string) {
    switch (buttonId) {
      case 'hopQrCode':
        if (!_.isEmpty(this.rawFormData?.indskr_hcponeid)) {
          this.uiService.displayLoader();
          const qrResponse: any = await this.mdmService.getHopQrCode(this.rawFormData?.indskr_hcponeid);
          this.uiService.dismissLoader();
          if (qrResponse?.status == 1000 && qrResponse?.data.url) {
            let popover = await this.popoverCtrl.create({
              component: HopQRCodeModal,
              componentProps: { qrCodeData: qrResponse?.data.url},
              cssClass: "hop-qr-code-modal",
              backdropDismiss: true
            });
            popover.present();
          }
        }
        break;
      case 'approveDCRRequest':
        this.onPageTitleControlClick('approve');
        break;
      case 'rejectDCRRequest':
        this.onPageTitleControlClick('reject');
        break;
      default:
        break;
    }
  }
}
