import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IndFormFieldViewDataModel } from "@omni/models/indFormFieldDataModel";
import { IndPageTitleViewDataModel } from "@omni/models/indPageTitleDataModel";
import { IndSectionHeaderViewDataModel } from "@omni/models/indSectionHeaderDataModel";
import { MainToolTemplateDeatilData } from "@omni/models/mainToolTemplateDetailData.model";
import { NavigationService, PageName } from "@omni/services/navigation/navigation.service";
import { UIService } from "@omni/services/ui/ui.service";
import { DynamicForm } from '@omni/classes/dynamic-form/dynamic-form.class';
import { NothingSelectedView } from "../nothing-selected-view/nothing-selected-view";

@Component({
  selector: 'main-tool-template-details',
  templateUrl: 'main-tool-template-details.html',
  styleUrls:['main-tool-template-details.scss']
})

export class MainToolTemplateDetailsComponent {
  @Input() for: any;
  // Input for Display Form
  @Input() formMetadata: DynamicForm;
  @Input() rawData: any;
  @Input() isEditEnabled : boolean;
  // Input for General/Grid Sections
  @Input() viewData;
  public detailData: MainToolTemplateDeatilData;
  public detailHeaderData: any;
  public mainToolTemplateDetailsPageTitle: IndPageTitleViewDataModel;
  public generalSectionHeader: IndSectionHeaderViewDataModel;
  public gridSectionHeader: IndSectionHeaderViewDataModel;
  public generalSectionDetails: Array<IndFormFieldViewDataModel> = [];
  public gridSectionDetails: Array<any> = [];

  constructor(
    public translate: TranslateService,
    private navService: NavigationService,
    public uiService: UIService,
  ) { }

  ngOnInit() {
    if(this.for == 'displayForm') {
      if(this.viewData) this.detailHeaderData = _.cloneDeep(this.viewData);
      if(this.detailHeaderData?.showLeftHeaderButton && this.detailHeaderData?.leftHeaderBtnIcon)
        this._initPageTitle();
    }
    else{
      if(this.viewData) this.detailData = _.cloneDeep(this.viewData);
      this._initPageTitle();
      this._initSectionHeaders();
      this._initDetailData();
    }
  }

  ngOnDestroy() {
  }

  private _initPageTitle() {
    const buttons = [];
    if(this.for == 'displayForm' ){
      buttons.push({
        id: 'close',
        icon: this.detailHeaderData.leftHeaderBtnIcon,
        isDisabled: false,
        align: 'left',
      });
      this.mainToolTemplateDetailsPageTitle = {
        id: 'main-tool-detail-page-title',
        title: "",
        controls: buttons,
      };
    }
    else{
      if (this.detailData.leftHeaderBtnIcon) {
        buttons.push({
          id: 'close',
          icon: this.detailData.leftHeaderBtnIcon,
          isDisabled: false,
          align: 'left',
        });
      }
      if (this.detailData.leftHeaderBtnText && this.detailData.isEditEnabled) {
        buttons.push({
          id: 'scrap',
          imgSrc: 'assets/imgs/header_cancel.svg',
          name: this.detailData.leftHeaderBtnText,
          isDisabled: false,
          align: 'right',
        });
      }
      if (this.detailData.rightHeaderBtnText) {
        buttons.push({
          id: 'done',
          imgSrc: this.detailData.rightHeaderImgSrc,
          name: this.detailData.rightHeaderBtnText,
          isDisabled: false,
          align: 'right'
        }); 
      }
      this.mainToolTemplateDetailsPageTitle = {
        id: 'main-tool-detail-page-title',
        title: this.detailData.title,
        controls: buttons,
      };
    }
  }

  private _initSectionHeaders() {
    this._initGeneralSectionHeader();    
    this._initGridSectionHeader();
  }

  private _initGeneralSectionHeader() {
    if(this.detailData.isGeneralSectionEnabled) {
      this.generalSectionHeader = {
        id: 'general-section-header',
        title: this.detailData.generalSectionTitle ?? this.translate.instant('GENERAL'),
        controls: []
      }
    }
  }

  private _initGridSectionHeader() {
    if(this.detailData.isGridSectionEnabled) {
      this.gridSectionHeader = {
        id: 'grid-section-header',
        title: this.detailData.gridSectionTitle ?? this.translate.instant('DETAILS'),
        controls: []
      }
    }
  }

  private _initDetailData() {
    if(this.detailData.isGeneralSectionEnabled) this._initGeneralSectionFormFields();
    if(this.detailData.isGridSectionEnabled) this._initGridSection();
  }

  private _initGeneralSectionFormFields() {
    if(!this.detailData.rawData) return;
    if(!_.isEmpty(this.detailData.attributeNamesForGeneralSection)){
      this.detailData.rawData.forEach((data) => {
        this.detailData.attributeNamesForGeneralSection.forEach((at) => {
          if(data[0][at.attr]) {
            let generalFormFieldData = {
              label: at['label'],
              inputText: data[0][at.attr] ?? this.translate.instant('N_A'),
              id: `${at['label']}-field`,
              isReadOnly: true,
              isDisabled: true,
              showArrow: false,
            }
            this.generalSectionDetails.push(generalFormFieldData);
          }
        });
      });
    }
  }

  public getColumnSize() {
    if(this.detailData && this.detailData.rawData && this.detailData.rawData[0] && this.detailData.rawData[0].length>0 && this.detailData.attributeNamesForGridSection && this.detailData.attributeNamesForGridSection.length>0) 
      return 12/this.detailData.attributeNamesForGridSection.length;
    else return 12;
  }

  private _initGridSection() {
    if(!this.detailData.rawData) return;
    if(!_.isEmpty(this.detailData.attributeNamesForGridSection)){
      let isGridValid = false;
      this.detailData.rawData[0].forEach((data) => {
        let rowData = [];
        this.detailData.attributeNamesForGridSection.forEach((at) => {
          isGridValid = isGridValid || !_.isEmpty(data[at.attr]);
          let gridFieldsData = {
            columnItemLabel: at['label'],
            inputText: data[at.attr] ?? this.translate.instant('N_A'),
            id: `${at['label']}-field`,
            isReadOnly: true,
            isDisabled: true,
          }
          rowData.push(gridFieldsData);
        });
        this.gridSectionDetails.push(rowData);
      })
      if(!isGridValid) this.gridSectionDetails = [];
    }
  }

  public onPageTitleControlClick(id: string) {
    switch (id) {
      case 'cancel':
      case 'close':
        this.navService.setChildNavRoot(NothingSelectedView, PageName.NothingSelectedView, PageName.MainToolTemplateDetailsComponent);
        this.uiService.showRightPane = false;
        this.navService.setChildNavRightPaneView(false);
        break;
      default:
        console.log("Unhandled switch case statement");
        break;
    }
  }
}